import _ from 'lodash'
import useQuery from "../../../../../../../hooks/custom/useQuery";

const GET_GROUP_COMPANY = `
  query getGroupCompanyProfile($startupId: ID!, $groupCompanyId: ID!) {
    getGroupCompany(startupId: $startupId, groupCompanyId: $groupCompanyId) {
    id
    name
    type
    kind
    isHoldingCompany
    jurisdiction
    country {
      name
      code
    }
    transactionCurrency
    registeredNumber
    registeredAddress {
      street
      city
      state
      country {
        name
        code
        alpha3
      }
      zipCode
    }
    dissolution_date
    incorporation_date
    linkedinURL
   formationData{
        specialMeaning
        affiliated
        arabicTranslation
        specialMeaningYN
        arabicTranslationYN
        affiliatedYN
        }
  }
}
`;
const useGroupCompany = ({ variables, ...props }) => {
    const { loading, error, data, refetch, ...propsDesc } = useQuery(GET_GROUP_COMPANY, variables, null, props);
    return { loading, error, data: _.get(data, 'getGroupCompany'), refetch, ...propsDesc };
};

export default useGroupCompany;

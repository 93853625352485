import { useMemo } from 'react';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import ReviewDetailsCorporateStakeholder from '../steps/ReviewCorporateStakeholderDetailsStep';
import ReviewCorporateStakeholderAddressStep from '../steps/ReviewCorporateStakeholderAddressStep';
import ReviewCorporateStakeholderDirectorsStep from "../steps/ReviewCorporateStakeholderDirectorsStep";
import ReviewCorporateStakeholderSignatoryStep from "../steps/ReviewCorporateStakeholderSignatoryStep";
import ReviewPEP from '../steps/ReviewCorporateStakeholderPepFormStep';
import ReviewSourceOfFunds from '../steps/ReviewCorporateStakeholderSofFormStep';

const useSteps = () => {
  return useMemo(() => ({
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    ReviewDetails: {
      render: ReviewDetailsCorporateStakeholder,
    },
    ReviewAddress: {
      render: ReviewCorporateStakeholderAddressStep,
    },
    ReviewDirectors: {
      render: ReviewCorporateStakeholderDirectorsStep,
    },
    ReviewSignatory: {
      render: ReviewCorporateStakeholderSignatoryStep,
    },
    ReviewPEP: {
      render: ReviewPEP,
    },
    ReviewSourceOfFunds: {
      render: ReviewSourceOfFunds,
    },
    SaveStep: {
      render: TemplateWizardSkeletonMachine,
    },
  }), []);
}

export default useSteps;
import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import React from "react";
import DetailsTabTitle from "src/components/templates/DetailsTabTitle";
import Button from "src/modules/generic/components/Buttons/Button";
import Typography from "src/modules/generic/components/Texts/Typography";
import TwoColumnsData from "src/modules/generic/components/TwoColumnsData";
import useModal from "src/modules/generic/hooks/useModal";
import { RoleAccess } from "src/v1/components";
import { Constants } from "src/v1/utils/constants";
import AddUltimateBeneficialOwnerModal from "src/views/modules/startups/renewal/cayman/modals/AddUltimateBeneficialOwner";
import EditUltimateBeneficialOwnerModal from "src/views/modules/startups/renewal/cayman/modals/EditUltimateBeneficialOwner";
import classes from "../styles.module.scss";
import _ from "lodash";
import ModalDeleteUltimateBeneficialOwner from "src/views/modules/startups/renewal/cayman/modals/DeleteUltimateBeneficialOwner";

const UltimateBeneficialOwnerPanel = ({ data, isLock, renewalData }) => {
  const { openModal, closeModal } = useModal();
  const ultimateBeneficialOwners = _.get(renewalData, "getRenewalData")
    ?.ultimateBeneficialOwners;

  const buttonsUltimateBeneficialOwners = (
    <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
      <Button
        variant="card"
        disabled={isLock}
        children={"Add"}
        onClick={() => {
          openModal(
            AddUltimateBeneficialOwnerModal,
            {
              handleCloseModal: closeModal,
              initialValues: { groupCompanyId: data?.id },
            },
            "MODAL_ADD_ULTIMATE_BENEFICIAL_OWNER"
          );
        }}
      />
    </RoleAccess>
  );

  const itemsColumn1 = [
    {
      label: "Name",
      custom: (
        <Stack
          direction="column"
          spacing={2}
          sx={{
            width: "100%",
          }}
        >
          {_.isEmpty(ultimateBeneficialOwners) ? (
            <>
              <Stack
                direction="row"
                spacing={0}
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography variant="m">-</Typography>
              </Stack>
            </>
          ) : (
            <>
              {ultimateBeneficialOwners?.map((parent) => (
                <Stack
                  direction="row"
                  spacing={0}
                  sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography variant="m">{parent?.name ?? "-"}</Typography>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
                      <Button
                        variant="cardWarning"
                        disabled={isLock}
                        children={"Delete"}
                        onClick={() => {
                          openModal(
                            ModalDeleteUltimateBeneficialOwner,
                            {
                              handleCloseModal: closeModal,
                              groupCompanyId: data?.id,
                              ultimateBeneficialOwnerId: parent?.id,
                              name: parent?.name
                            },
                            "MODAL_EDIT_ULTIMATE_BENEFICIAL_OWNER"
                          );
                        }}
                      />
                    </RoleAccess>
                    <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
                      <Button
                        variant="card"
                        disabled={isLock}
                        children={"Edit"}
                        onClick={() => {
                          openModal(
                            EditUltimateBeneficialOwnerModal,
                            {
                              handleCloseModal: closeModal,
                              initialValues: {
                                groupCompanyId: data?.id,
                                ultimateBeneficialOwnerId: parent?.id,
                              },
                            },
                            "MODAL_EDIT_ULTIMATE_BENEFICIAL_OWNER"
                          );
                        }}
                      />
                    </RoleAccess>
                  </Stack>
                </Stack>
              ))}
            </>
          )}
        </Stack>
      ),
    },
  ];

  return (
    <>
      <Box sx={{ paddingRight: "0.4615rem" }}>
        <Divider className={classes.divider} />
        <DetailsTabTitle
          title="Ultimate beneficial owners"
          buttons={buttonsUltimateBeneficialOwners}
        />
        <TwoColumnsData
          items={itemsColumn1}
          customValueStyle={{
            marginRight: "0px !important",
            width: "100% !important",
          }}
        />
      </Box>
    </>
  );
};

export default UltimateBeneficialOwnerPanel;

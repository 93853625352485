import useMutation from "src/hooks/custom/useMutation";

export const UPDATE_STAKEHOLDER = `
mutation updateStakeholder(
    $startupId: ID!
    $stakeholderId: ID!
    $stakeholderData: StakeholderInput!
) {
    updateStakeholder(
        startupId: $startupId
        stakeholderId: $stakeholderId
        stakeholderData: $stakeholderData
    ) {
        id
        fullName
    }
  }
`
const useUpdateStakeholder = (config, props) => {
    const [ updateStakeholder ] = useMutation(UPDATE_STAKEHOLDER, config,{ ...props });
    return { updateStakeholder };
};
export default useUpdateStakeholder;
import React, { useEffect, useState } from 'react';

const SVGCurveLine = ({ 
  fromX, 
  fromY, 
  toX, 
  toY, 
  curve = 66, 
  id, 
  className, 
  dotter,
  useOpacity = false 
}) => {
  const [d, setD] = useState()

  const getPath = (fromX, fromY, toX, toY, curve) => {
    const c = {
      c1: fromX + curve,
      c2: fromY,
      c3: toX - curve,
      c4: toY
    }
    return "M " + fromX + " " + fromY + " C " + c.c1 + " " + c.c2 + " " + c.c3 + " " + c.c4 + " " + toX + " " + toY;
  }

  useEffect(() => {
    if (fromX && fromY && toX && toY) {
      const newPath = getPath(fromX, fromY, toX, toY, curve);
      setD(newPath);
    }
  }, [fromX, fromY, toX, toY, curve])


  return (
    <React.Fragment>
      {d && (
        <path id={id} d={d} stroke-dasharray={dotter ? "10,10" : null} className={className} style={{ opacity: useOpacity ? "0.2" : "1"}}></path>
      )}
    </React.Fragment>
  )
};
export default SVGCurveLine;

import { useMemo } from 'react';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import ADGMSPVEligibility from '../steps/ADGMSPVEligibility';
import CaymanIsBetter from '../steps/CaymanIsBetter';
import FormSPVInADGM from "../steps/FormSPVInADGM";
import FormInADGMSuccess from "../steps/FormInADGMSuccess";
import AddADGMCompany from "../steps/AddADGMCompany";
import ADGMSPVAuthorisedSignatory from "../steps/ADGMSPVAuthorisedSignatory";
import AddWithClaraConciergeSubMachineStep from "../steps/AddWithClaraConciergeSubMachineStep";
import BetterFitADGMTL from "src/modules/startup/onBoarding/CreateCompanyModal/wizard/submachines/AddFormationCompanyModal/wizard/submachines/FormAdgmSPVTypeModal/wizard/steps/BetterFitADGMTL";
import AdgmTechStartupSubMachineStep from "src/modules/startup/onBoarding/CreateCompanyModal/wizard/submachines/AddFormationCompanyModal/wizard/steps/AdgmTechStartupSubMachineStep";

const useSteps = () => {
  return useMemo(() => ({
    ADGMSPVEligibility: {
      render: ADGMSPVEligibility,
    },
    CaymanIsBetter: {
      render: CaymanIsBetter,
    },
    FormSPVInADGM: {
      render: FormSPVInADGM
    },
    BetterFitADGMTL: {
      render: BetterFitADGMTL
    },
    AdgmTechStartupSubMachineStep: {
      render: AdgmTechStartupSubMachineStep
    },
    FormInADGMSuccess: {
      render: FormInADGMSuccess
    },
    AddADGMCompany: {
      render: AddADGMCompany
    },
    AddWithClaraConciergeSubMachineStep: {
      render: AddWithClaraConciergeSubMachineStep
    },
    ADGMSPVAuthorisedSignatory: {
      render: ADGMSPVAuthorisedSignatory
    },
    SaveStep_ADGM: {
      render: TemplateWizardSkeletonMachine,
    },
  }), []);
}

export default useSteps;

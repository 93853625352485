import useMapperDataProtectionContactDeclaration from "./useMapperDataProtectionContactDeclaration";
import useUpdateGroupCompany from "./graphql/useUpdateGroupCompany";
import useUpsertRenewalData from "./graphql/useUpsertRenewalData";
import useGetGroupCompany from "./graphql/useGetGroupCompany";

const useServices = ({ groupCompanyId, isEdit }) => {
  const {
    getSubmitData,
    getInitialData,
  } = useMapperDataProtectionContactDeclaration();
  const submitRenewal = useUpsertRenewalData();
  const submitGroupCompany = useUpdateGroupCompany();
  const { query } = useGetGroupCompany();

  const initialData = async () => {
    const { groupCompany } = await query({ groupCompanyId });
    const dataProtectionDeclaration = getInitialData(
      groupCompany?.dataProtectionDeclaration
    );
    return {
      groupCompanyId,
      isEdit,
      dataProtectionDeclaration,
    };
  };

  const saveDeclaration = async (context) => {
    const {
      DataProtectionDeclarationPart1,
      DataProtectionDeclarationPart2,
      DataProtectionDeclarationPart3,
    } = context;

    const dataProtectionDeclaration =
      context?.initialData?.dataProtectionDeclaration;

    const data = getSubmitData(
      {
        ...DataProtectionDeclarationPart1,
        ...DataProtectionDeclarationPart2,
        ...DataProtectionDeclarationPart3,
      },
      dataProtectionDeclaration
    );

    await submitGroupCompany(data, groupCompanyId);

    if (isEdit) return data;

    await submitRenewal(groupCompanyId);
    return data;
  };

  return {
    initialData,
    saveDeclaration,
  };
};
export default useServices;

import React, { FC, useEffect } from 'react'
import _, { get } from 'lodash'
import { addAssistantText, clearAssistatText } from '../../../../generic/store/action'
import Button from '../../../../generic/components/Buttons/Button'
import Form from "../../ADGM/components/Forms/FormPassportData";
import { StepProps } from "../../../../generic/components/Wizard/Step"
import SubmitButton from '../../../../generic/components/Buttons/SubmitButton'
import TemplateWizardPreview from '../../../../generic/templates/Modal/TemplateWizard/TemplateWizardPreview'
import useConfirmProofAddress from "../hooks/useUpsertRenewalData";
import useCustomMachine from '../../../../generic/hooks/useCustomMachine'
import { useDispatch } from 'react-redux'
import useGetStakeholder from "../hooks/useGetStakeholder";
import { useParams } from 'react-router-dom'
import useSession from '../../../../session/hooks/useSession'
import useUpsertTwoDocumentFormation from "../../../../../hooks/services/modules/individualStakeholder/useUpsertTwoDocumentFormation";

const Step2: FC<StepProps> = () => {

  const { next, send, state, ...props } = useCustomMachine();
  const [updateDocument] = useUpsertTwoDocumentFormation();
  const { id: stakeholderIdParamId } = useParams<any>()
  const { startupId } = useSession();
  const dispatch = useDispatch();
  const isRenewal = props?.machine?.config?.context?.data?.isRenewal
  const submitRenewal = useConfirmProofAddress()
  const { query } = useGetStakeholder({});
  const stakeholderId = state?.context?.paramsData?.stakeholderId ?? stakeholderIdParamId;

  const stakeholder = async () => {
    const stakeholderData = await query({ startupId, stakeholderId });
    return stakeholderData.stakeholder
  }

  const handleCompleteSubmit = async values => {
    const stakeholderData = await stakeholder()
    const identityDocumentData = get(state, 'context.data.upload_proof_address_party', {})
    const variables = {
      stakeholderId,
      startupId,
      identityDocumentData: { ...identityDocumentData, "type": "THIRDPARTY_ADDRESS_FORM_DOCUMENT" },
      identityDocumentDataOLD: { ...values, "type": "THIRDPARTY_ADDRESS_PASSPORT_DOCUMENT" }
    }
    await updateDocument({ variables });
    let groupCompanyFiltered
    if (!_.isEmpty(stakeholderData) && isRenewal) {
      groupCompanyFiltered = stakeholderData.managedByClaraConnections.filter((sh) =>
        sh.jurisdictionType === 'ADGM' &&
        sh.statusOnMatters.isRenewal === true
      )
      const groupCompanyId = groupCompanyFiltered[0].id
      await submitRenewal(groupCompanyId, stakeholderId)
    }
    next(values);
  }

  const handleCancel = async values => {
    send("CANCEL");
  }

  useEffect(() => {
    dispatch(addAssistantText(`Upload a double page scan of the third party passport clearly showing their photo and passport number.`));
    return () => {
      dispatch(clearAssistatText());
    }
  }, [])

  return (
    <Form
      initialValues={get(state, 'context.data.party_passport', {})}
      onCompleteSubmit={handleCompleteSubmit}
      Template={TemplateWizardPreview}
      propsTemplate={{
        title: 'Add third party passport',
        subTitle: 'Upload passport'
      }}
      buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button>, submit: <SubmitButton >Add</SubmitButton> }}>
    </Form>
  )
}

export default Step2;

import { Field, FieldArray, Formik } from "formik";
import _ from 'lodash';
import moment from "moment";
import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from 'yup';
import Title from '../../../../../../components/text/Title/Title';
import Icon from '../../../../../../modules/generic/components/Icon';
import ClaraSelect from "../../../../../../v1/components/Form/ClaraSelect/ClaraSelect";
import AddInput from "../../../../../../v1/containers/Forms/Inputs/AddInput";
import TextInput from "../../../../../../v1/containers/Forms/Inputs/TextInput";
import AddStakeHolder from "../../../../../../v1/containers/Forms/subForms/AddStakeHolder";
import { Constants } from "../../../../../../v1/utils";
import FormikHelper from "../../../../../../v1/utils/formikHelper";
import PrimaryButton from "../../../../../generic/Buttons/PrimaryButton/PrimaryButton";
import SecondaryButton from "../../../../../generic/Buttons/SecondaryButton/SecondaryButton";
import DocumentPreviewFile from "../../../../../generic/Documents/DocumentPreview/DocumentPreviewFile";
import FileUploaderDropzone from '../../../../../generic/Dropzone/FileUploaderDropzone';
import Line from "../../../../../generic/Line/Line";
import ContainerModal from '../../../../../generic/Modal/ModalScroll/ContainerModal';
import Scrollbar from '../../../../../generic/Scrollbar/Scrollbar';
import Checkbox from "../../../../../inputs/Checkbox/Checkbox";
import Text from '../../../../../text/Text/Text';
import Botonera from '../../../../contents/Botonera/Botonera';
import classes from "./DocumentUploadForm.module.scss";
import uploadedAgreementValidations from './Validate';
import ClaraCountrySelector from '../../../../../../v1/components/Form/ClaraCountrySelector/ClaraCountrySelector';
import FieldsByDocumentType from "./components/FieldsByDocumentType";

const validationSchema = Yup.object().shape(uploadedAgreementValidations);

class Step1 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      parties: [],
      executionDate: undefined,
      expirationDate: undefined,
      docType: null,
      categories: this.getAllCategories() || [],
      individualStakeholders: _.map(_.filter(props.stakeholders, { isAnEntity: false }), st => ({ label: st.fullName, id: st.id })),
      subcategories: this.getAllSubcategories() || [],
      documentTypes: this.getAllDocumentTypes() || [],
      agreementStatus: Constants.AGREEMENT_STATUS.UPLOADED,
      isTeam: false,
      shouldClearDocTypeValue: false,
      shouldClearSubCategoryValue: false,
      initialValues: {},
      groupCompanies: _.map(props.groupCompanies, gc => ({ label: gc.name, id: gc.id, type: "groupCompany" })),
      stakeholders: _.map(props.stakeholders, st => ({ label: st.fullName, id: st.id, type: "stakeholder" }))
    }

  }

  handleDisable(errors, values) {
    return Object.keys(values).length === 0 || Object.keys(errors).length !== 0
  }

  componentDidMount() {
    console.log('aca estoy')
    if (this.props.c === 0) {
      this.addEmptyParties(1);
    }
  }

  componentWillUpdate(nextProps, nextState, snapshot) {
    if (nextProps.tooltips && !this.props.tooltips && this.state.parties.length === 1) {
      this.setState({
        parties: []
      }, () => {
        this.addEmptyParties(1)
      })
    }
  }

  componentWillMount() {
    const { data } = this.props;
    this.setState({
      initialValues: data ? this.transformInitialData(data) : { parties: [{}] }
    }, () => {
      if (!_.get(data, 'parties')) this.addEmptyParties(1)
    })

  }

  // Get all

  mapParties = (values) => {
    const parties = [];
    const { stakeholders, groupCompanies } = values;

    _.forEach(stakeholders, st => {
      parties.push({ party: { id: st.id, type: 'stakeholder' } });
      this.addParty()
    })

    _.forEach(groupCompanies, ({ groupCompany }) => {
      parties.push({ party: { id: groupCompany.id, type: 'groupCompany' } })
      this.addParty()
    })

    return parties;
  }

  mapParty(values) {
    const { stakeholders } = values
    console.log(stakeholders)
    return stakeholders[0]
  }

  transformInitialData = (values) => {
    const category = _.find(this.props.categories, cat => {
      return _.some(cat.subCategories, sub => {
        return _.some(sub.documentTypes, doc => {
          return doc.key === "IP_ASSIGNMENT"
        }
        )
      })
    });
    const subCategory = _.find(_.get(category, 'subCategories', []), sub => _.some(sub.documentTypes, type => type.key === 'IP_ASSIGNMENT'))
    if (_.includes(Constants.DOCUMENT_TYPE_IDENTITY_DOCUMENTS, _.get(values, 'type.code')))
      return {
        ...values,
        title: _.get(values, 'name'),
        type: _.get(values, 'type.code'),
        category: _.get(this.getCategoryFromSubcategory(_.get(values, 'subCategories[0]')), 'key'),
        subCategory: _.get(values, 'subCategories[0]'),
        party: this.mapParty(values),
        IPAssignmentProvisions: _.some(values.subCategories, sub => sub === subCategory.key)
      }
    return {
      ...values,
      title: _.get(values, 'name'),
      type: _.get(values, 'type.code'),
      category: _.get(this.getCategoryFromSubcategory(_.get(values, 'subCategories[0]')), 'key'),
      subCategory: _.get(values, 'subCategories[0]'),
      parties: this.mapParties(values),
      IPAssignmentProvisions: _.some(values.subCategories, sub => sub === _.get(subCategory, 'key'))
    }

  }
  
  getFieldsForIdentityDocuments(docType) {
    return (<>
      <Field name="party">
        {({ field, form }) => {
          return (
            <div className={`${classes.row} ${classes.Select}`}>
              <ClaraSelect
                label={`Party: `}
                field={field}
                required={true}
                form={form}
                placeholder={"Search or select from a list..."}
                adds={this.getAddsPartyList()}
                lists={[{ list: this.state.individualStakeholders }]}
              />
            </div>)
        }}
      </Field>
      <Line className={classes.Separator} />
      {_.includes(Constants.PASSPORT_DOCUMENT_TYPES, docType) ? (<>
        <Field name='passport.number'>
          {({ field, form }) => (
            <TextInput
              required={true}
              classNameInput={{ root: classes.row }}
              type='text'
              label='Passport Number: '
              field={field}
              form={form}
              placeholder={"Type here..."}
            />
          )}
        </Field>
        <Field name="passport.nationality">
          {({ field, form }) => {
            return (
              <div className={` ${classes.SelectCountry}`}>
                <ClaraCountrySelector
                  required={true}
                  isRequired={{ country: true }}
                  type='text'
                  countryField={"passport.nationality"}
                  label='Nationality: '
                  returnCountryAsString={true}
                  field={field}
                  additionalStyle={'ClaraCountrySelectorModal'}
                  form={form}
                  placeholder={"Type here..."}
                />
              </div>)
          }
          }
        </Field>
        <Field name="passport.expirationDate">
          {({ field, form }) => {
            return (
              <TextInput
                required={true}
                classNameInput={{ input: classes.Calendars, root: classes.labelCalendar }}
                type='date'
                messageErrorClassName={classes.MessageErrorDate}
                label='Expiration Date: '
                field={field}
                form={form}
                placeholder={"dd/mm/yyyy"}
              />)
          }
          }
        </Field>
      </>)
        : _.includes(Constants.VISA_STAMPS_RESIDENCE_ID_DOCUMENTS_TYPES, docType) ? (<>
          <Field name="visaStamp.expirationDate">
            {({ field, form }) => {
              return (
                <TextInput
                  required={true}
                  classNameInput={{ input: classes.Calendars, root: classes.labelCalendar }}
                  type='date'
                  messageErrorClassName={classes.MessageErrorDate}
                  label='Expiration Date: '
                  field={field}
                  form={form}
                  placeholder={"dd/mm/yyyy"}
                />)
            }
            }
          </Field>
        </>) : null
      }

    </>)
  }

  getAllCategories() {
    const constantCategories = this.props.categories
    const categories = [];

    for (let key in constantCategories) {
      const category = constantCategories[key];
      categories.push({
        id: category.key,
        label: category.title
      })
    }

    return categories;
  }

  getAllSubcategories() {
    const constantCategories = this.props.categories;
    let subcategories = [];

    for (let key in constantCategories) {
      const category = constantCategories[key];
      if (category) {
        category.subCategories.forEach(subcategory => {
          subcategories = this.getSubcategoriesForComponent(subcategory, subcategories);
        });
      }
    }

    return subcategories;
  }

  getAllDocumentTypes() {
    const constantCategories = this.props.categories;
    let documentTypes = [];

    for (let key in constantCategories) {
      const category = constantCategories[key];
      if (category) {
        category.subCategories.forEach(subcategory => {
          documentTypes = this.getDocTypesForComponent(subcategory, documentTypes);
        });
      }
    }

    return documentTypes;
  }

  // Find Category Constant

  findCategoryConstant(category) {
    const constantCategories = this.props.categories
    let constant = '';

    for (let key in constantCategories) {
      if (constantCategories[key].key === category) {
        constant = key;
      }
    }

    return constant;
  }

  findCategoryConstantFromSubcategory(subcategory) {
    const constantCategories = this.props.categories;
    let constant = '';

    for (let key in constantCategories) {
      if (constantCategories[key].subCategories && constantCategories[key].subCategories.some(sc => sc.key === subcategory)) {
        constant = key;
      }
    }

    return constant;
  }

  findCategoryConstantFromDocType(docType) {
    const constantCategories = this.props.categories;
    let constant = '';

    for (let key in constantCategories) {
      if (constantCategories[key].subCategories) {
        constantCategories[key].subCategories.forEach(sc => {
          if (sc.documentTypes && sc.documentTypes.some(dt => dt.key === docType)) {
            constant = key;
          }
        })
      }
    }

    return constant;
  }

  // Find Category - Subcategory

  getCategoryFromSubcategory(subcategory) {
    const categories = this.props.categories;
    const categoryIndex = this.findCategoryConstantFromSubcategory(subcategory);

    return categories[categoryIndex];
  }

  getCategoryFromDocType(docType) {
    const categories = this.props.categories;
    const categoryIndex = this.findCategoryConstantFromDocType(docType);

    return categories[categoryIndex];
  }

  getSubcategoryFromDocType(docType) {
    const categories = this.props.categories;
    const categoryIndex = this.findCategoryConstantFromDocType(docType);
    let subCategory = {};

    if (categories[categoryIndex] && categories[categoryIndex].subCategories) {
      categories[categoryIndex].subCategories.forEach(sc => {
        if (sc.documentTypes && sc.documentTypes.some(dc => dc.key === docType)) {
          subCategory = sc;
        }
      });
    }

    return subCategory;
  }

  getCategoryFromConstant(categoryConstant) {
    const categories = this.props.categories;
    let category;

    for (let key in categories) {
      if (categories[key] && categories[key].key === categoryConstant) {
        category = categories[key];
      }
    }

    return category;

  }

  // Get Lists for Components

  getDocTypesForComponent(subcategory, documentTypes) {

    if (!subcategory.hide) {

      subcategory.documentTypes.forEach(documentType => {
        documentTypes.push({
          id: documentType.key,
          label: documentType.title
        })
      });
    }

    return documentTypes;
  }

  getSubcategoriesForComponent(subcategory, subcategories) {

    if (!subcategory.hide && subcategory.documentTypes.length >= 1) {
      subcategories.push({
        id: subcategory.key,
        label: subcategory.title
      })
    }

    return subcategories;
  }

  // Find Lists By category

  getSubcategoriesFromCategory(category) {
    const categories = this.props.categories;
    const categoryIndex = this.findCategoryConstant(category.key ? category.key : category);
    let subcategories = [];

    if (categories[categoryIndex] && categories[categoryIndex].subCategories) {
      categories[categoryIndex].subCategories.forEach(subcategory => {
        subcategories = this.getSubcategoriesForComponent(subcategory, subcategories);
      });
    }

    return subcategories;
  }

  getDocumentTypesFromCategory(category, subcategoryKey, docType) {
    const categories = this.props.categories;
    const categoryIndex = this.findCategoryConstant(category.key ? category.key : category);
    let documentTypes = [];

    if (categories[categoryIndex] && categories[categoryIndex].subCategories) {
      if (subcategoryKey) {
        const subcategory = categories[categoryIndex].subCategories.filter(sc => sc.key === subcategoryKey)[0];
        documentTypes = this.getDocTypesForComponent(subcategory, documentTypes);
      } else {
        if (docType) {
          const subcategory = categories[categoryIndex].subCategories.filter(sc =>
            sc.documentTypes.filter(dt => dt.key === docType).length > 0
          )[0];
          documentTypes = this.getDocTypesForComponent(subcategory, documentTypes);
        } else {
          categories[categoryIndex].subCategories.forEach(subcategory => {
            documentTypes = this.getDocTypesForComponent(subcategory, documentTypes);
          });
        }
      }
    }

    return documentTypes;
  }

  // Parties

  addParty = (arrayHelpers) => {
    const newParties = this.state.parties;
    const i = newParties.length;
    newParties.push(
      this.getNewPartyStructure(i)
    );
    arrayHelpers && arrayHelpers.push({})
    this.setState({
      parties: newParties
    })
  };

  removeParty = (i, form, field) => {
    let newParties = this.state.parties;
    newParties.splice(i, 1);

    if (form.values.parties) {
      form.values.parties.splice(i, 1);
      const errors = form.errors;
      delete errors.parties;
      form.setErrors({
        ...errors
      });
    }

    this.addPartiesFromData(newParties)
  };

  addPartiesFromData(parties) {
    const newParties = [];
    parties.forEach((party, i) => {
      newParties.push(
        this.getNewPartyStructure(i, party)
      );
    });

    this.setState({
      parties: newParties
    })
  }

  getNewPartyStructure(i, party) {

    const { tooltips = {} } = this.props;
    return (
      <React.Fragment>
        {i > 0 && <Field>
          {({ field, form }) => (
            <div className={classes.remove} onClick={() => this.removeParty(i, form, field)}>Remove</div>
          )}
        </Field>}
        <div className={`${classes.row} ${classes.Select}`}>
          <Field name={`parties[${i}].party`}>
            {({ field, form }) => {
              return (
                <ClaraSelect
                  label={`Party ${i + 1}: `}
                  field={field}
                  required={true}
                  form={form}
                  limitedModalSize={true}
                  placeholder={"Search or select from a list..."}
                  adds={this.getPartyAdds()}
                  lists={this.getPartyLists()}
                  tooltip={tooltips['Party']}
                  callbacks={{ updateValues: this.handlePartyChange }}
                  paramsToCallbacks={{ index: i }}
                />
              )
            }
            }
          </Field>
        </div>

      </React.Fragment>
    );
  }

  getAddsPartyList() {
    return [
      {
        type: "stakeholder",
        label: "Add New Individual Stakeholder",
        textWarning: "This will create a new stakeholder for your startup. You can add more details later from the stakeholder's profile page.",
        placeholder: "Enter Stakeholder's Name",
        startupId: this.props.startupId,
        form: AddStakeHolder,
        formProps: { groupCompanies: this.state.groupCompanies },
        textToSave: 'fullName',
        params: {
          groupCompanyId: this.state.groupCompanyId,
          isAnEntity: false
        }
      }
    ]
  }
  getPartyLists() {
    return [
      {
        type: "groupCompany",
        list: this.state.groupCompanies
      },
      {
        type: "stakeholder",
        list: this.state.stakeholders
      }
    ]
  }

  getPartyAdds() {
    return [
      {
        type: "groupCompany",
        label: "Add New Company",
        textWarning: "This will create a new company for your startup. You can add more details later from the company's profile page.",
        placeholder: "Enter Company Name",
        startupId: this.props.startupId,
        params: null,
      },
      {
        type: "stakeholder",
        label: "Add New Corporate Stakeholder",
        textWarning: "This will create a new stakeholder for your startup. You can add more details later from the stakeholder's profile page.",
        placeholder: "Enter Stakeholder's Name",
        startupId: this.props.startupId,
        form: AddStakeHolder,
        textToSave: 'fullName',
        formProps: { groupCompanies: this.state.groupCompanies },
        params: {
          groupCompanyId: this.state.groupCompanyId,
          isAnEntity: true
        }
      },
      {
        type: "stakeholder",
        label: "Add New Individual Stakeholder",
        textWarning: "This will create a new stakeholder for your startup. You can add more details later from the stakeholder's profile page.",
        placeholder: "Enter Stakeholder's Name",
        startupId: this.props.startupId,
        form: AddStakeHolder,
        formProps: { groupCompanies: this.state.groupCompanies },
        textToSave: 'fullName',
        params: {
          groupCompanyId: this.state.groupCompanyId,
          isAnEntity: false
        }
      },
    ]
  }

  addEmptyParties(cant) {
    const newParties = this.state.parties;

    for (let i = 0; i < cant; i++) {
      newParties.push(
        this.getNewPartyStructure(i)
      );

      this.setState({
        parties: newParties
      })
    }
  }

  // Handle changes

  handlePartyChange = (value, _, form, paramsToCallbacks) => {
    if (value) {
      const type = value.type;
      const id = value.id;
      const index = paramsToCallbacks.index; // TODO: See how to pass the index here.

      if (type === 'groupCompany') {

        const groupCompany = this.state.groupCompanies.find(gc => gc.id === id);

        if (groupCompany) {
          this.setState(prevState => ({
            ...prevState,
          }));

        }
      } else {
        this.setState(prevState => ({ ...prevState }))
      }
    }
  };

  handleChange(e, field, form, agreementStatus) {

    const date = e === null ? null : moment(e).format("YYYY-MM-DD");

    if (agreementStatus) {

      let newAgreementStatus = '';

      const today = moment(new Date());
      const eventDate = moment(date);

      if (agreementStatus && date && today && today > eventDate) {
        newAgreementStatus = Constants.AGREEMENT_STATUS.EXPIRED;
      } else {
        newAgreementStatus = Constants.AGREEMENT_STATUS.UPLOADED;
      }


      FormikHelper.setValueInTheCorrectPosition('agreementStatus', form, newAgreementStatus);

      this.setState({
        agreementStatus: newAgreementStatus
      });
    }

    FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

    this.setState({
      [field.name]: e
    });
  }

  handleCategoryChange = (value, extraParams, form, cb) => {
    const category = value;
    let shouldClear = false

    let subcategories = [];
    let documentTypes = [];

    if (category) {
      subcategories = this.getSubcategoriesFromCategory(category);
      documentTypes = this.getDocumentTypesFromCategory(category, null, null);
      form.setValues({
        ...form.values,
        category: category,
        //subCategory: null,
        //type: null
      });
    } else {
      shouldClear = true
      subcategories = this.getAllSubcategories();
      documentTypes = this.getAllDocumentTypes();
      form.setValues({
        ...form.values,
        category: category,
        subCategory: null,
        type: null
      });
    }
    this.setState({
      subcategories,
      documentTypes,
      shouldClearDocTypeValue: shouldClear,
      shouldClearSubCategoryValue: shouldClear
    });
  };

  handleSubCategoryChange = (value, extraParams, form, cb) => {
    const subcategory = value;
    let shouldClear = false;

    let category = {};
    let subcategories = [];
    let documentTypes = [];
    if (subcategory) {
      category = this.getCategoryFromSubcategory(subcategory);
      subcategories = this.getSubcategoriesFromCategory(category);
      documentTypes = this.getDocumentTypesFromCategory(category, subcategory);
      form.setValues({
        ...form.values,
        category: category.key,
        subCategory: subcategory,
        //type: null
      });
    } else {
      shouldClear = true;
      if (form.values.category) {
        category = this.getCategoryFromConstant(form.values.category);
        subcategories = this.getSubcategoriesFromCategory(category);
        documentTypes = this.getDocumentTypesFromCategory(category);
      } else {
        subcategories = this.getAllSubcategories();
        documentTypes = this.getAllDocumentTypes();
      }
      form.setValues({
        ...form.values,
        category: category.key,
        subCategory: subcategory,
        type: null
      });
    }
    this.setState({
      documentTypes,
      subcategories,
      shouldClearDocTypeValue: shouldClear
    });
    if (category.key === "TEAM") {
      this.setState({
        isTeam: true,
        haveIPAssignment: false
      });

    } else {
      this.setState({
        isTeam: false,
        haveIPAssignment: false
      });
    }
  };

  handleDocTypeChange = (value, extraParams, form, cb) => {
    const docType = value;

    let category = {};
    let subcategory = {};
    let documentTypes = [];
    let subcategories = [];

    if (docType) {
      category = this.getCategoryFromDocType(docType);
      subcategory = this.getSubcategoryFromDocType(docType);

      documentTypes = this.getDocumentTypesFromCategory(category, subcategory.key, value);
      subcategories = this.getSubcategoriesFromCategory(category);
    } else {
      if (form.values.subCategory) {
        subcategory = { key: form.values.subCategory };
        category = { key: form.values.category };
        subcategories = this.getSubcategoriesFromCategory(category);
        documentTypes = this.getDocumentTypesFromCategory(category, subcategory.key)

      } else {
        subcategories = this.getAllSubcategories();
        documentTypes = this.getAllDocumentTypes();
      }
    }

    if (category.key === "TEAM") {
      this.setState({
        isTeam: true,
        haveIPAssignment: false
      });

    } else {
      this.setState({
        isTeam: false,
        haveIPAssignment: false
      });
    }

    this.setState({
      documentTypes,
      subcategories,
      docType
    });

    form.setValues({
      ...form.values,
      category: category.key,
      subCategory: subcategory.key,
      type: docType
    });

  };

  handleCheckboxChange = (value, name, form) => {

    this.setState({
      haveIPAssignment: value
    }, () => {
      const subcategories = this.getSubcategoriesFromCategory(this.props.categories.TEAM);
      const documentTypes = this.getDocumentTypesFromCategory(this.props.categories.TEAM);

      this.setState({
        subcategories,
        documentTypes
      })
    });

    form.setFieldValue(name, value);
  };

  // Get Lists

  getCategoriesList() {
    return this.state.categories;
  }

  getSubcategoriesList() {
    return this.state.subcategories;
  }

  getDocTypesList() {
    return this.state.documentTypes;
  }

  render() {
    const { tooltips = {}, c } = this.props;

    return (
      <Formik
        initialValues={this.state.initialValues}
        onSubmit={this.props.handleSubmit}
        validationSchema={validationSchema}
        validateOnMount={false}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          values,
          errors,
          dirty,
          handleSubmit,
          isSubmitting,
          handleReset,
          setFieldValue,
          isValid,
          validateForm
        }) => {
          //console.log('values-->',values)
          //console.log('errors-->',errors)
          return (
            <React.Fragment>
              <div className={classes.gridContainer}>
                <div className={classes.leftArea}>
                  <ContainerModal dividers={false}>
                    <div className={`${classes.containerForm}`}>
                      <Scrollbar overflowX={false} havePadding={true}>
                        <h1 className={classes.title} >{this.props.addDocument ? 'Categorise document' : 'Edit Document'}</h1>
                        <div className={`${classes.row} ${classes.Input}`}>
                          <Field name='title'>
                            {({ field, form }) => (
                              <TextInput
                                required={true}
                                classNameInput={{ root: classes.row }}
                                type='text'
                                label='Document Name: '
                                field={field}
                                form={form}
                                placeholder={"Type here..."}
                                tooltip={tooltips['Document Name']}
                              />
                            )}
                          </Field>
                        </div>
                        <Line className={classes.Separator} />
                        <div className={`${classes.row} ${classes.Select}`}>
                          <Field name={`category`}>
                            {({ field, form }) => (
                              <ClaraSelect
                                required={true}
                                field={field}
                                form={form}
                                label={"Category: "}
                                placeholder={"Search or select from a list..."}
                                tooltip={tooltips['Category']}
                                lists={this.getCategoriesList()}
                                mode={"classic"}
                                callbacks={{
                                  updateValues: this.handleCategoryChange
                                }}
                              />
                            )}
                          </Field>
                        </div>
                        <div className={`${classes.row} ${classes.Select}`}>
                          <Field name={`subCategory`}>
                            {({ field, form }) => (
                              <ClaraSelect
                                required={true}
                                field={field}
                                form={form}
                                label={"Sub-Category: "}
                                limitedModalSize={true}
                                placeholder={"Search or select from a list..."}
                                tooltip={tooltips['Sub-Category']}
                                lists={this.getSubcategoriesList()}
                                mode={"classic"}
                                clearSelectAndFilter={this.state.shouldClearSubCategoryValue}
                                callbacks={{
                                  updateValues: this.handleSubCategoryChange
                                }}
                              />
                            )}
                          </Field>
                        </div>
                        <div className={`${classes.row} ${classes.Select}`}>
                          <Field name='type'>
                            {({ field, form }) => (
                              <ClaraSelect
                                required={true}
                                field={field}
                                form={form}
                                label={"Document Type: "}
                                placeholder={"Search or select from a list..."}
                                tooltip={tooltips['Document Type']}
                                lists={this.getDocTypesList()}
                                limitedModalSize={true}
                                mode={"classic"}
                                clearSelectAndFilter={this.state.shouldClearDocTypeValue}
                                callbacks={{
                                  updateValues: this.handleDocTypeChange
                                }}
                              />
                            )}
                          </Field>
                        </div>
                        <Field name='IPAssignmentProvisions'>
                          {({ field, form }) => {
                            return (<>
                              {_.get(form.values, 'type') && this.state.isTeam && !_.includes(Constants.DOCUMENT_TYPE_IDENTITY_DOCUMENTS, _.get(form.values, 'type')) ?
                                <>
                                  <hr className={classes.Separator} />
                                  <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-evenly',
                                    marginTop: '1.5rem'
                                  }}>
                                    <label style={{ width: '14.3rem' }}> </label>
                                    <Checkbox
                                      onChange={(value) => {
                                        form.setFieldValue(field.name, value)
                                      }}
                                      value={field.value}
                                      className={{ label: classes.checkBoxWizard }}
                                      isMultiple={false}
                                      label={'This agreement has an IP provision'}
                                    />
                                  </div><hr className={classes.Separator} /></> : null
                              }
                            </>)
                          }}
                        </Field>
                        <Field name='fieldsByDocumentType'>
                          {({ field, form }) => {
                            console.log(form.values,'form.values')
                            return (<>
                        <FieldsByDocumentType
                          addParty={this.addParty} 
                          getAddsPartyList={this.getAddsPartyList} 
                          tooltips={tooltips}
                          documentType={_.get(form.values, 'type')} 
                          parties={this.state.parties} 
                          individualStakeholders={this.state.individualStakeholders} 
                          handleChange={this.handleChange}/>
                        </>)}}
                        </Field>
                      </Scrollbar>
                    </div>
                  </ContainerModal>
                </div>
                <div className={`${classes.rightArea} ${_.get(values, 'file') && classes.previewContentContainer}`}>
                  <div className={`${classes.rightAreaContent}`}>
                    {_.get(values, 'file') ? (
                      <React.Fragment>
                        <div className={`${classes.titleContent}`}>
                          <Title>
                            <Text uuid={_.get(values, 'file.name')} />
                          </Title>
                          <span className={classes.deleteButton}>
                            <Icon onClick={() => { setFieldValue('file', null) }} isClara={true} icon={'Delete'} size={'1.5rem'} />
                          </span>
                        </div>
                        <DocumentPreviewFile
                          fileId={_.get(values, 'file.id')}
                          fileType={_.get(values, 'file.name').substr(_.get(values, 'file.name').lastIndexOf('.') + 1)}
                          fileName={_.get(values, 'file.name')}
                          className={`${classes.documentSpecialHeight}`}
                          defaultScale={0.8}
                        />
                      </React.Fragment>
                    ) : (
                      <div className={`${classes.dropCenter}`}>
                        <Field name={`file`}>
                          {({ field, form }) => (
                            <FileUploaderDropzone
                              field={field}
                              form={form}
                              required={true}
                            />
                          )}
                        </Field>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={classes.containerButtons}>
                <Botonera >
                  <SecondaryButton onClick={() => this.props.handleCloseModal()}><Text uuid={'BUTTON_CANCEL'} /></SecondaryButton>
                  <PrimaryButton
                    // disabled={!_.isEmpty(Object.keys(errors)) || this.props.isSubmitting}
                    loading={this.props.isSubmitting}
                    labelLoading={"Saving..."}

                    onClick={() => this.props.handleSubmit(values, validateForm)} type={"button"}  ><Text uuid={"MY_ACCOUNT_MODAL_UPDATEPASSWORD_SAVE"} /></PrimaryButton>
                </Botonera>
              </div>
              {/*/ <pre>{JSON.stringify(values, null, 2)}</pre> /*/}
              {/*/<pre>{JSON.stringify(errors, null, 2)}</pre>/*/}

            </React.Fragment>


          )

        }
        }


      </Formik >


    );
  }
}

export default Step1;

import React, { FC, useEffect, useState } from 'react'
import Wizard from '../../../generic/components/Wizard'
import ModalAssistant, { ModalAssistantProps } from '../../../generic/components/Modal/ModalAssistant'
import MachineProfessionalHistory from './machine'
import { clearAssistatText } from '../../../generic/store/action'
import { useDispatch } from 'react-redux'
import useModal from "../../../generic/hooks/useModal";

interface ModalNoticesProps extends ModalAssistantProps {
  paramsMutation: any,
  onClose(): void
}

const ModalPEP: FC<ModalNoticesProps> = ({ children, open: openParam = false, paramsMutation, ...props }: ModalNoticesProps) => {

  const [open, setOpen] = useState(openParam);
  const { closeModal: onClose } = useModal();
  const dispatch = useDispatch();

  useEffect(() => {
    setOpen(openParam);
  }, [openParam]);

  const handleClose = (values) => {
    onClose();
    setOpen(false);
  }

  const handleInitModal = () => {
    dispatch(clearAssistatText());
  }

  useEffect(() => {
    handleInitModal()
    return () => {
      dispatch(clearAssistatText());
    }
  }, []);

  return (
    <ModalAssistant open={open}>
      <Wizard machine={MachineProfessionalHistory} onComplete={handleClose} initialValues={paramsMutation}/>
    </ModalAssistant>
  )
}

export default ModalPEP;





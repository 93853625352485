import React, { useEffect } from "react";
import {withOrientationChange} from 'react-device-detect'


const Mobile = (props)=>{

    useEffect(() => {
        window.location= "https://clara.co/mobile";
    }, [])

    return (
        <React.Fragment>
          
        </React.Fragment>
    )
}

export default withOrientationChange(Mobile);
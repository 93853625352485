import React from 'react';
import classes from './classes.module.scss';
import Button from '../Button/Button'

const BetaButton = (props) => {
    return (
        <Button type={'button'} {...props} className={`${classes.BetaButton} ${props.className}`} > {props.children} </Button>
    )
};

export default BetaButton

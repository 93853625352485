import useGetStakeholders from "src/hooks/services/modules/stakeholders/useGetStakeholders";
import useSession from "src/modules/session/hooks/useSession";
import useUpsertRenewalData from 'src/views/modules/startups/renewal/adgm/modals/ReviewDirectorsModal/wizard/graphql/useUpsertRenewalData';

const useServices = ({ initialValues }) => {

  const { startupId } = useSession();
  const { manualQuery: getStakeholders } = useGetStakeholders({ variables: { 
    startupId,
    groupCompanyId: initialValues?.groupCompanyId,
    filters: {
      role: ["DIRECTOR"],
      checkIsFounder: false,
      rolesInProgress: true,
      onlyActiveRoles: true
    }
  } });
  const submitRenewal = useUpsertRenewalData();

  return {

    initialData: async (context, event) => {
      const stakeholders = await getStakeholders();
      return stakeholders?.data;
    },

    SaveStep: async (context, event) => {
      if (context?.ReviewDirectors?.accept) {
        const dataRenewal = await submitRenewal(initialValues?.groupCompanyId);
        return dataRenewal;
      }
      return null;
    },

  };
}

export default useServices;

import React from 'react';
import {useParams} from 'react-router-dom';
import DocumentPreview from "../../../../../components/generic/Documents/DocumentPreview/DocumentPreview"
import SecondaryButton from "../../../../../components/generic/Buttons/SecondaryButton/SecondaryButton"
import Text from "../../../../../components/text/Text/Text";
import {useDispatch,useSelector} from "react-redux";
import _ from "lodash";
import {Constants} from "../../../../../v1/utils";

const IncorporationDocumentView = ( ) => {
    const params = useParams();
    const historyPage = useSelector(store => _.get(store, `page.historyPage`));
    const lastPage=historyPage[historyPage.length-1]
    const { fileId} = params;
    const dispatch = useDispatch();
    const closeForm = () => {
        if(lastPage.params.tab) {
            dispatch({type: "GO_TO",page:Constants.PAGES.viewMatter,params:{tab:lastPage.params.tab,id:lastPage.params.matterId}});
        }else{
            dispatch({type: "GO_TO_PREV"});
        }
    };

    return (
        <React.Fragment>
            <DocumentPreview fileId={fileId}>
                <SecondaryButton onClick={closeForm} > <Text uuid={"GENERIC_BUTTON_CLOSE"} /> </SecondaryButton>
            </DocumentPreview>
        </React.Fragment>
    )
}

export default IncorporationDocumentView

import { createMachine } from 'xstate';

const machine =
  createMachine({
    id: 'ConfirmDocuments',
    initial: 'GetLink',
    states: {
      GetLink: {
        invoke: {
          src: 'getLink',
          id: 'GetLink',
          onDone: [
            {
              target: 'ConfirmDocumentsForm',
              actions: ['setContextAfterInvoke']
            }
          ],
          onError: [{
            target: 'cancel'
          }]
        }
      },
      ConfirmDocumentsForm: {
        on: {
          NEXT: {
            target: 'Save',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      Save: {
        invoke: {
          src: 'Save',
          id: 'Save',
          onDone: [
            {
              target: 'finish',
            }
          ],
          onError: [{
            target: 'cancel'
          }]
        }
      },
      cancel: {
        entry: 'cancelMachine',
        type: 'final'
      },
      finish: {
        entry: 'stopMachine',
        type: 'final'
      }
    }
  });

export default machine;

import useGetGroupCompany from './graphql/useGetGroupCompany';
import useSession from 'src/modules/session/hooks/useSession';
import useUpdateGroupCompany from './graphql/useUpsertGroupCompany';

const useServices = ({ initialValues }) => {

  const { query } = useGetGroupCompany({});
  const { startupId } = useSession();
  const groupCompanyId = initialValues.groupCompanyId;
  const mutation = useUpdateGroupCompany({groupCompanyId: groupCompanyId});

  const initialData = async (context, event) => {
    const data = await query({ startupId, groupCompanyId });
    return {
      groupCompany: data,
    }
  }

  const SaveStep = async (context, event) => {
    const variables = {
      startupId,
      groupCompanyId,
      groupCompany: context.EditAddresses
    }
    return await mutation(variables);
  }

  return { initialData, SaveStep }
}

export default useServices;

export default {
    WIZARD_ADD_TITLE: "Does your startup have advisors?",
    WIZARD_ADD_SUBTITLE: "Your advisors",
    WIZARD_CONFIRM_ADD: "Yes, add an advisor",
    WIZARD_ADD_INDIVIDUAL_OR_COMPANY_SUBTITLE: "Add an advisor",
    WIZARD_ADDED_SUBTITLE: "Advisor added!",
    FIRST_STEP_TOOLTIPS:'<b>Advisors</b> are your go-to people or companies for specialist knowledge and tailored introductions.',
    FIRST_STEP_IS_NOT_EXIST_TOOLTIPS:'If and when you take on advisors, add them here. ',
    THIRD_STEP_NOT_ANOTHER_TOOLTIPS:'Add more advisors to your map at any time. '
}

import useGetDelete from './useGetDelete'
import useDeleteStakeholderIndividual from "src/hooks/services/modules/individualStakeholder/useDeleteStakeholderIndividual";
import useNavigation from "src/modules/generic/hooks/useNavigation";
import { useParams } from "react-router-dom";
import useSession from "src/modules/session/hooks/useSession";
import { Constants } from "src/v1/utils";
import useGetConnections from './graphql/useGetConnections'
import useGetDeleteVerification from './graphql/useGetDeleteVerification'

const useServices = ()=>{
    const { updateCache,mapConnections } = useGetDelete()
    const queryVerification = useGetDeleteVerification({})
    const queryConnections = useGetConnections({})
    const [mutation] = useDeleteStakeholderIndividual({updateCache})
    const { startupId } = useSession()
    const { goTo } = useNavigation()
    const { id } = useParams<{ id: string }>()

    const deleteVerification = async (context) => {
        const data = await queryVerification({variables:{startupId, stakeholderId: id}})
        return {
            ableToDelete: data?.data?.getStakeholder?.allowedActions?.toDelete,
            stakeholder:{
            fullName:data?.data?.getStakeholder?.fullName,
        }
    }
}

    const getConnections = async (context) => {
        const data =await queryConnections({variables:{startupId, stakeholderId: id}})
        return mapConnections(data.data)
    }

    const invalidateStakeholder = async (context) =>{
        await mutation({ variables: { startupId, stakeholderId: id, setInactive: true }});
            goTo(Constants.PAGES.MODULES.MAP.maps);
    }

    const deleteStakeholder = async (context) => {
        await mutation({ variables: { startupId, stakeholderId: id, setInactive: false }});
            goTo(Constants.PAGES.MODULES.MAP.maps);
    }

    return {
        deleteVerification,
        getConnections,
        invalidateStakeholder,
        deleteStakeholder
    }
}

export default useServices;
import React from "react";
import cls from "./classes.module.scss";
import { CircularProgress, Button } from "@mui/material";
import ClassNames from "classnames";
import Icon from "../../../Icon";
import TypographyLocal from "../../../../../../modules/generic/components/Texts/Typography";
import Typography from 'src/modules//generic/components/Texts/Typography';


interface ProgressUploadProps {
  progress: number;
  uploading: boolean;
  failedUpload: boolean;
  nameFile?: string;
  uploadFileAgain?: any;
  stopUpload?: any;
}

const ProgressUpload: React.FC<ProgressUploadProps> = ({
  progress,
  nameFile = "",
  failedUpload,
  uploadFileAgain,
  successUpload,
  stopUpload,
}) => {

  const labelError = React.useMemo(() => {
    const extension = nameFile.split(".").pop();
    const name = nameFile.split(`.${extension}`)[0];
    const prefix = "Failed: ";
    if (`${prefix}${name}`.length >= 18) {
      return `${prefix}${name}`.substring(0, 18) + "..." + extension;
    }
    return `${prefix}${name}.${extension}`;
  }, [nameFile]);

  const labelUploading = React.useMemo(() => {
    const extension = nameFile.split(".").pop();
    const name = nameFile.split(`.${extension}`)[0];
    const prefix = "Uploading: ";
    if (`${prefix}${name}`.length >= 18) {
      return `${prefix}${name}`.substring(0, 18) + "..." + extension;
    }
    return `${prefix}${name}.${extension}`;
  }, [nameFile]);

  return (
    <React.Fragment>
      {failedUpload ? (
        <div className={cls.container}>
          <div
            className={ClassNames(
              cls.loadingContainer,
              cls.errorUploadedContainer
            )}
          >
            <Icon icon={"Delete"} isClara size={"20px"} color={"#ff606f"} />
            
              <Typography variant="body" color="error" weight="semibold">
                  {labelError}
              </Typography>
            <ButtonFile
              onClick={uploadFileAgain}
              className={cls.button}
              size={"small"}
              variant="text"
            >
              <TypographyLocal
                weight={"bold"}
                variant={"xs"}
                color={"error"}
              >
                {'Try Again'}
              </TypographyLocal>
            </ButtonFile>
          </div>
        </div>
      ) : (
        

<div className={cls.container}>
<div
  style={{ width: `${progress}%` }}
  className={cls.progressContainer}
/>
<div className={cls.loadingContainer}>
  <CircularProgress
    className={cls.loading}
    thickness={7}
    size={"20px"}
  />
   <Typography variant="body" color="blue" weight="semibold">
    {labelUploading}
   </Typography>
  <ButtonFile className={cls.button} size={"small"} variant="text" onClick={stopUpload}>
    <Typography variant="xs" color="blue" weight="bold">
      {"Cancel"}
    </Typography>
  </ButtonFile>
</div>
</div>

       

      )}
    </React.Fragment>
  );
};

export default ProgressUpload;

const ButtonFile: React.FC<any> = ({ children, onClick, ...props }) => {

  const handleOnClick = React.useCallback(
    (e: any) => {
      e.stopPropagation();
      onClick();
    },
    [onClick]
  );

  return (
    <Button {...props} type="button" onClick={handleOnClick}>
      {children}
    </Button>
  );
};
import { useMemo } from 'react';
import { assign } from 'xstate';

const useActions = ({ onPreviousMachine, goToChooseJurisdiction, goToCayman, goToADGMTS }) => {

  return useMemo(() => {

    const exampleAction = assign((context: any = {}, event: any = null) => {
      return {
        ...context,
        newValue: true,
      };
    });

    /** this action is online used to return of chidlren machine to parent machine. If yo need other type of comunication you can add more actions here */
    const previousMachine = assign((context: any = {}, event: any = null) => {
      onPreviousMachine && onPreviousMachine();
      return context;
    });

    const chooseJurisdiction = assign((context: any = {}, event: any = null) => {
      goToChooseJurisdiction && goToChooseJurisdiction();
      return context;
    });
    
    const caymanStep = assign((context: any = {}, event: any = null) => {
      goToCayman && goToCayman();
      return context;
    });

    const adgmTechStartup = assign((context: any = {}, event: any = null) => {
      goToADGMTS && goToADGMTS();
      return context;
    });

    return {
      exampleAction,
      previousMachine,
      chooseJurisdiction,
      adgmTechStartup,
      caymanStep
    }
  }, [onPreviousMachine, goToChooseJurisdiction, goToCayman]);

}

export default useActions;

import { Grid } from '@material-ui/core';
import _ from "lodash";
import PropTypes from "prop-types";
import React from 'react';
import { Date } from './../../../../../../v1/components';
import Typography from '../../../../../../modules/generic/components/Texts/Typography';
import useTranslate from "../../../../../../modules/generic/hooks/useTranslate";
import classesModule from './DetailsTab.module.scss';
function DetailsTab({ data }) {
  const { translate } = useTranslate();
  return <div className={` ${classesModule.DetailsTab} `}>
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography color='dark-grey' variant='m'>
              {translate("MY_ACCOUNT_VIEW_TAB_DETAILS_LABEL_EMAIL")}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography color='black' variant='m'>{_.get(data, "email")}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography color='dark-grey' variant='m'>{translate("MY_ACCOUNT_VIEW_TAB_DETAILS_LABEL_MARKETING_PREFERENCES")}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography color='black' variant='m'>
              {_.get(data, "acceptMarketingEmailOn") ? translate("SUBSCRIBE") : (translate("UNSUBSCRIBE"))}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography color='dark-grey' variant='m'>{translate("MY_ACCOUNT_VIEW_TAB_DETAILS_LABEL_ACCOUNT_CREATED")}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography color='black' variant='m'>
              <Date value={_.get(data, "createdAt")} />
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography color='dark-grey' variant='m'>Phone number</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography color='black' variant='m'>
              {_.get(data, "personalInfo.phoneNumber", "-")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
      </Grid>
    </Grid>
  </div>;
}

DetailsTab.propTypes = {
  data: PropTypes.any
}
export default DetailsTab;
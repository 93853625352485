import React, { useEffect } from 'react';
import { addAssistantText, clearAssistatText } from 'src/modules/generic/store/action';

import Button from 'src/modules/generic/components/Buttons/Button';
import FormBusinessActivityCayman from 'src/modules/startup/GroupCompany/Forms/FormBusinessActivityCayman';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import { useDispatch } from 'react-redux';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';

function EditBusinessActivity() {
  const { next, cancel,context } = useMachine();
  const dispatch = useDispatch();
  const isManagedByClara = !!context?.initialValues?.isManagedByClara

  const handleCancel = () => {
    cancel();
  }

  const handleSubmit = async (values) => {
    next(values);
  }



  useEffect(() => {
    isManagedByClara && dispatch(addAssistantText("<strong>Additional fees</strong> might apply if you need to update some of the details with the Cayman registrar.<div style='margin-top:1rem;'> Click on the field to update to view the corresponding fees. To make a change, just edit the content and proceed. The change request will be added to your cart.</div>",'alert'));
    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch]);

  return (
    <FormBusinessActivityCayman
      initialValues={context?.initialData}
      onCompleteSubmit={handleSubmit}
      Template={TemplateWizard}
      pincas={context?.pincasData?.pincasData}
      propsTemplate={{
        title:'Edit business activity',
      }}
      buttons={{
        cancel: <Button variant='secondary' onClick={handleCancel}>Cancel</Button>
      }}
    />
  );
}

export default EditBusinessActivity;
import React from 'react';
import classesModule from './NewModalForm.module.scss';
import Scrollbar from "../../generic/Scrollbar/Scrollbar";
import Botonera from "../../../components/forms/contents/Botonera/Botonera";
import _ from 'lodash';
import { useFormContext } from 'react-hook-form'


const NewModalForm = ({ header, content, botonera, type = 'standard', classes, ...props }) => {
    const methods = useFormContext();
    return (
        <React.Fragment>
            <Scrollbar className={`${classesModule.ScrollForm} ${_.get(classesModule, 'type')} ${_.get(classes, 'scrollbar')} `}>
                {header && <div className={`${classesModule.header} ${_.get(classes, 'header')}`}>
                    {header}
                </div>}
                {content}

            </Scrollbar>
            <Botonera className={classesModule.containerButtons}>
                {botonera}
            </Botonera>
        </React.Fragment>

    )
}

export default NewModalForm;

import classnames from "classnames";
import _ from "lodash";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import KeyboardEventHandler from "react-keyboard-event-handler";
import useTextLang from "../../../../hooks/custom/useTextLang";
import Icon from "./../../../../modules/generic/components/Icon";
import classes from "./SelectInput.module.scss";
import ErrorInput from "../../../../modules/generic/components/Inputs/ErrorInput";

const InputSelect = forwardRef(
  (
    {
      innerRef,
      compare = "",
      disableTyping = false,
      search = !disableTyping,
      filterWithValue = true,
      clear = false,
      component,
      ComponentMenu = null,
      menuProps = {},
      ComponentNotFound = null,
      value,
      list,
      onChange,
      onBlur,
      isSubmitting,
      disabled,
      type,
      placeholder,
      callbakcs,
      name,
      handleFilterList = null,
      mode,
      defaultPathValue = null,
      errorMessage,
      classNameError,
      showErrorIcon,
      error,
      onFocus,
      onClear = null,
      autoClean = true,
      expandDouble = false,
      customEmptyListMessage = "",
      triggerChangeOnAutoClean = true,
    },
    ref
  ) => {
    const valueCheck = (value) => {
      return typeof value == "object" ? "" : value;
    };

    const placeholderTranslate = useTextLang(placeholder);
    const [filter, setFilter] = useState(
      defaultPathValue ? _.get(value, defaultPathValue, "") : valueCheck(value)
    );
    const [open, setOpen] = useState(false);
    const [positionLink, setPositionLink] = useState(-1);
    const [hasMouse, setHasMouse] = useState(false);
    const refModal = useRef();

    const getFilterList = (list) => {
      if (!list) {
        list = [];
      }
      if (filterWithValue === false && value) {
        return list;
      }
      if (!filter || filter === "") {
        return list;
      }
      return _.filter(list, (element) => {
        return (
          (!element?.id && !element?.value) ||
          element?.label?.toLowerCase()?.indexOf(filter.toLowerCase()) >= 0
        );
      });
    };

    useImperativeHandle(
      innerRef,
      () => ({
        clearValue: handleClearValue,
        handleSelected,
      }),
      []
    );

    const handleKeyPress = (key, e) => {
      if (!disabled) {
        let listFilter = search ? getFilterList(list) : list;
        switch (key) {
          case "up":
            if (!open) {
              setOpen(true);
              setPositionLink(0);
              break;
            }
            if (positionLink - 1 < 0) {
              setPositionLink(listFilter.length - 1);
            } else {
              setPositionLink(positionLink - 1);
            }
            break;
          case "down":
            if (!open) {
              setOpen(true);
              setPositionLink(0);
              break;
            }
            if (positionLink + 1 >= listFilter.length) {
              setPositionLink(0);
            } else {
              setPositionLink(positionLink + 1);
            }
            break;
          case "enter":
            if (positionLink >= 0) {
              handleSelected(listFilter[positionLink]);
            }
            break;
          case "esc":
            setOpen(false);
            break;
          case "tab":
          case "shift+tab":
            setOpen(false);
            break;
          default:
            break;
        }
      }
    };

    const conditionOfFind = (element, value) => {
      if (compare) {
        return (
          value &&
          _.isEqual(_.get(element.value, compare), _.get(value, compare))
        );
      } else {
        return (
          (element.id && element.id === value) ||
          (_.isEqual(element.value, value) && value)
        );
      }
    };

    useEffect(() => {
      const element = _.find(list, (element) =>
        conditionOfFind(element, value)
      );
      if (element) {
        handleSelected(element);
      } else if ((value || filter) && list && autoClean) {
        handleClearValue(triggerChangeOnAutoClean);
      }
    }, [JSON.stringify(value), JSON.stringify(list)]);

    const handleFocus = (event) => {
      onFocus && onFocus(event);
      setOpen(true);
    };

    const handleChange = (e) => {
      setFilter(e.target.value);
      if (onChange && e.target.value === "") {
        onChange(e.target.value);
      }
    };

    const handleBlur = () => {
      if (!hasMouse && !disableTyping) {
        setOpen(false);
      }
      if (onBlur) {
        onBlur();
      }
    };

    const handleMouseOverModal = () => {
      if (!hasMouse) {
        setHasMouse(true);
      }
    };

    const handleMouseOutModal = () => {
      if (hasMouse) {
        setHasMouse(false);
      }
    };

    const handleSelected = (element) => {
      if (element) {
        setOpen(false);
        setFilter(_.get(element, "label", ""));
        onChange(element.id ? element.id : element.value);
        if (_.has(callbakcs, "updateValues")) {
          const updateValues = _.get(callbakcs, "updateValues");
          updateValues(element);
        }
      }
    };

    const handleClearValue = (triggerChange = true) => {
      setFilter("");
      if (triggerChange) onChange(null);
      if (_.has(callbakcs, "updateValues")) {
        const updateValues = _.get(callbakcs, "updateValues");
        updateValues(null);
      }
      onClear && onClear();
    };

    const NotFound = ComponentNotFound;

    const Menu = ComponentMenu;

    const listFilter = useMemo(() => {
      if (typeof filter == "string" || _.isNil(filter)) {
        let listFilter = search ? getFilterList(list) : list;
        if (handleFilterList) {
          listFilter = handleFilterList(listFilter);
        }
        return listFilter;
      } else {
        return [];
      }
    }, [filter, JSON.stringify(list), search, handleFilterList]);

    const customNoData = (
      <div className={classes.customMessage}>
        <Icon isClara icon={"no-comp"} size={"1.5385rem"} color={"#2C74FF"} />
        {customEmptyListMessage}
      </div>
    );

    const renderList = useCallback(() => {
      return (
        <React.Fragment>
          {listFilter.length > 0 || mode ? (
            <React.Fragment>
              {Menu ? (
                <Menu
                  list={listFilter}
                  handleClearValue={handleClearValue}
                  openMenu={open}
                  handleShowMenu={setOpen}
                  handleSelectedItem={handleSelected}
                  {...menuProps}
                />
              ) : (
                <div className={classes.zoneList}>
                  {listFilter.map((element, i) => {
                    return (
                      <div
                        className={classnames(classes.classic, {
                          [classes.selectedKey]: positionLink === i,
                        })}
                        onMouseOver={() => {
                          setPositionLink(i);
                        }}
                        onClick={() => {
                          handleSelected(element);
                        }}
                        key={_.get(element, "id", i)}
                      >
                        {element.component
                          ? element.component
                          : component
                          ? React.cloneElement(component, element)
                          : element.label}
                      </div>
                    );
                  })}
                </div>
              )}
              <hr className={classes.Separator} />
            </React.Fragment>
          ) : (
            <>
              {NotFound ? (
                <NotFound />
              ) : customEmptyListMessage !== "" ? (
                customNoData
              ) : (
                <span>Not Found</span>
              )}
            </>
          )}
        </React.Fragment>
      );
    }, [JSON.stringify(listFilter), positionLink]);

    const showModal = open ? classes.openModal : "";

    return (
      <div className={classes.ClaraSelectContainer}>
        <span>
          <KeyboardEventHandler
            handleKeys={["esc", "up", "down", "enter", "tab", "shift+tab"]}
            onKeyEvent={handleKeyPress}
          >
            <input
              className={classnames(
                {
                  [classes.borderErrorInput]: errorMessage || error,
                },
                `${classes.claraSelectInput} ${
                  filter ? classes.claraSelectSelected : ""
                }`
              )}
              type={type}
              readOnly={disableTyping}
              disabled={isSubmitting || disabled}
              value={filter ? filter : ""}
              onChange={handleChange}
              placeholder={placeholderTranslate}
              onFocus={handleFocus}
              ref={ref}
              onBlur={handleBlur}
              autocomplete="nope"
              name={name}
            />
            <div className={`${classes.claraSelectArrow} `} />
            {clear && filter && !disabled && (
              <div
                onClick={() => {
                  handleClearValue();
                }}
                className={`${classes.claraButtonClearValue}`}
              >
                <Icon icon={"x_symbol"} size={"0.75rem"} />
              </div>
            )}
            {open && (
              <div
                className={`${classes.Modal} ${showModal} scrollbar ${
                  expandDouble && classes.ModalDouble
                }`}
                onMouseOver={handleMouseOverModal}
                onMouseOut={handleMouseOutModal}
                ref={refModal}
              >
                {renderList()}
              </div>
            )}
            {errorMessage && (
              <ErrorInput
                message={errorMessage}
                classNameError={classNameError}
                showErrorIcon={showErrorIcon}
                component={error}
              />
            )}
          </KeyboardEventHandler>
        </span>
      </div>
    );
  }
);

export default InputSelect;

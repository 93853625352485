import Machine from "../../../generic/components/Machine/MachineClass";
import { StepPersonalInfo } from './Steps';

const machineDefinitionUpdate = {
  id: 'personal_information',
  initial: 'personal_information',
  states: {
    personal_information: {
      on: {
        NEXT: { target: 'finish_stakeholder', actions: 'setContext' },
        CANCEL: 'finish_stakeholder',
      },
    },
    finish_stakeholder: {
      type: 'final',
      entry: 'final',
    },
    onDone: {
      actions: 'stopMachine'
    }
  }
}

const steps = {
  personal_information: StepPersonalInfo,
}

const options = {}

export default class MachineProfileStakeholder extends Machine {
  constructor() {
    super(machineDefinitionUpdate, options, steps);
  }
  getParamsData(values) {
    return values;
  }
}

import React from 'react'
import SubscriptionFree from '../SubscriptionFree/SubscriptionFree'

const CardProductStart = ({ ...props }) => {

  return (
    <SubscriptionFree
      saveUpTitle="Free"
      detailTitle="Start"
      detailSubtitle="Business-building basics"
      scaleItemList={[
        {
          icon: 'document-stack',
          title: 'Generate documents',
        },
        // {
        //   icon: 'Sharing',
        //   title: 'Profile shares',
        // },
        {
          icon: 'Document-edit',
          title: 'Document upload',
        },
        {
          icon: 'Heart',
          title: 'Health Check',
        }
      ]}
      columTags={['3 documents', '50 uploads', '3 checks']}
    />
  )
}


export default CardProductStart
import React, { useState, useEffect } from 'react';

import classes from './Styles.module.scss';
import Form from '../../../../../../components/forms/Form/Form';

import TextInput from "../../../../../../components/inputs/Text/TextInput"
import ControllerInput from "../../../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput"
import * as Yup from 'yup';
import _ from 'lodash';

import { useSelector } from 'react-redux'

import useMutation from '../../../../../../hooks/custom/useMutation'
import SubmitButtonSubForm from '../../../../../../components/generic/Buttons/SubmitButtonSubForm/SubmitButtonSubForm';
import {GET_GROUP_COMPANIES} from "../graphql/useGetGroupCompanies";
import {gql} from "@apollo/client";
import {COMPANY_CORPORATE_MAP} from "../../../../../../modules/map/hooks/services/useCompanyCorporateMap";

const UPSERT_GROUP_COMPANY = `
mutation upsertComponentGroupCompany(
    $startupId: ID!
    $groupCompany: String!
  ) {
    upsertComponentGroupCompany(startupId: $startupId, groupCompany: $groupCompany) {
    	id
        name
      	transactionCurrency
      	isHoldingCompany
    }
  }
`


const FormAddGroupCompany = ({ startupId, handleCloseForm, handleCloseMessage, handleAddValueList, ...props }) => {
    const handleOnCompletedMutation = ({ upsertComponentGroupCompany }) => {
        handleCloseMessage()
        handleCloseForm()
        handleAddValueList(upsertComponentGroupCompany)
    }

    const [upsertCompany] = useMutation(UPSERT_GROUP_COMPANY, { onCompleted: handleOnCompletedMutation },{refetchQueries:[ {query: gql`${GET_GROUP_COMPANIES}`, variables: { startupId: startupId }}]});


    const handleSubmit =async (values) => {
      await  upsertCompany({
            variables: {
                startupId,
                groupCompany: values.name
            }
        })
    }


    useEffect(() => {
        return () => {
            handleCloseMessage()
        }
    }, [])

    return (
        <Form initialValues={{ data: 'asdasdasd' }}
            schema={Yup.object().shape({
                name: Yup.string().required('This field is required'),

            })} >
            <div className={classes.containerForm}>
                <div className={classes.conteninerText}>

                    <ControllerInput
                        as={TextInput}
                        name={'name'}
                        classNameError={classes.errorForm}
                        placeholder={"Enter Company Name..."}

                    />


                    <SubmitButtonSubForm onClick={handleSubmit}  >
                        Save
                    </SubmitButtonSubForm>
                </div>
                {/*messageError && <span className={classes.error}>
                        {messageError}
                        </span>*/}



            </div>
        </Form>

    )

}


export default FormAddGroupCompany

import { useMemo } from "react";
import TemplateWizardSkeletonMachine from "src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine";
import ClaraNomineeStep from "../steps/ClaraNomineeStep";
import ReviewAndConfirmStep from "../steps/ReviewAndConfirmStep";
import AssignSignatoryStep from "../steps/AssignSignatoryStep";
import AssignOtherSignatoriesStep from "../steps/AssignOtherSignatoriesStep";
import CreateStakeholderStep from "../steps/CreateStakeholderStep";
import UAEInfoStep from "../steps/UAEInfoStep";
import IsThereSecondarySignatoryStep from '../steps/IsThereSecondaryAuthorised'

const useSteps = () => {
  return useMemo(
    () => ({
      initialData: {
        render: TemplateWizardSkeletonMachine,
      },
      IsThereSecondarySignatoryStep:{
        render: IsThereSecondarySignatoryStep
      },
      ReviewAndConfirmStep: {
        render: ReviewAndConfirmStep,
      },
      ClaraNomineeStep: {
        render: ClaraNomineeStep,
      },
      AssignSignatoryStep: {
        render: AssignSignatoryStep,
      },
      AssignOtherSignatoriesStep: {
        render: AssignOtherSignatoriesStep,
      },
      CreateStakeholderStep: {
        render: CreateStakeholderStep,
      },
      UAEInfoStep: {
        render: UAEInfoStep,
      },
      SaveStep: {
        render: TemplateWizardSkeletonMachine,
      },
      SaveFormationStep: {
        render: TemplateWizardSkeletonMachine,
      },
    }),
    []
  );
};

export default useSteps;

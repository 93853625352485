import useQuery from "src/hooks/custom/useQuery";
import _ from 'lodash'

const GET_STATES_BY_COUNTRY=`
query getStates ($language: String!, $code: String ) {
     statesByCountryList(code:$code, language: $language) 
}
`
const useGetStatesByCountry=(variables,config)=>{
    const {data,loading}=useQuery(GET_STATES_BY_COUNTRY,variables,config)
    return{data:_.get(data,'statesByCountryList'),loading}
}
export default useGetStatesByCountry

import { LoadingProvider } from "../../../../../../../modules/generic/hooks/useLoading";
import ModalAssistant from "../../../../../../../modules/generic/components/Modal/ModalAssistant";
import NoticesForm from "../../../../../../forms/modules/startups/groupCompany/NoticesForm";
import React from "react";
import SkeletonInput from "../../../../../../../modules/generic/components/Skeletons/Overlays/SkeletonInput";
import TemplateWizard from "../../../../../../../modules/generic/templates/Modal/TemplateWizard";
import useGetGroupCompanyNotices from "../../graphql/queries/useGetGroupCompanyNotices";
import { useParams } from "react-router-dom";
import useSession from "./../../../../../../../modules/session/hooks/useSession";
import useTranslate from "../../../../../../../modules/generic/hooks/useTranslate";
import useUpdateGroupCompany from "../../../../../../../hooks/services/modules/groupCompany/useUpdateGroupCompany";
import useUpdateStakeholderCorporateNotices from '../../graphql/mutations/useUpdateStakeholderCorporateNotices'
import useGetStakeholderCorporateNotices from '../../graphql/queries/useGetStakeholderNotices'
const EditNotices = ({
  showModal,
  open,
  handleCloseModal,
  refresh,
  isStakeholder
}) => {
  const { translate } = useTranslate();
  const overlayName = translate("FORM_NOTICES_TITLE_LABEL");
  const params = useParams();

  const handleCloseForm = () => {
    refresh && refresh();
  };

  const updateStakeholderCorporateNotices = useUpdateStakeholderCorporateNotices()

  const [upsertGroupCompany] = useUpdateGroupCompany({
    onCompleted: handleCloseForm,
    successMessage: "NOTIFICATION_OVERLAY_SAVE_SUCCESS",
    paramsSuccessMessage: { overlayName },
  });

  
  const { startupId } = useSession();
  
  const { data:recipientNotice } = useGetStakeholderCorporateNotices({
    skip:!isStakeholder,
    variables: { startupId, stakeholderId: params.id },
  })
  
  const updateEntity =async (values)=>{
    if(isStakeholder) return updateStakeholderCorporateNotices(values, params.id)
    const groupCompany = {
      ...values,
      id: params.id
    };
    return upsertGroupCompany({
      variables: { startupId, groupCompanyId: params.id, groupCompany },
    });
  }

  const handleSubmit = async (values) => {
    await updateEntity(values)
    handleCloseModal()
  };

  const { data } = useGetGroupCompanyNotices({
    skip:isStakeholder,
    variables: { startupId, groupCompanyId: params.id },
  });

  return (
    <>
      <ModalAssistant open={showModal || open}>
        <LoadingProvider>
          <NoticesForm
            initialValues={data || recipientNotice}
            handleCloseModal={handleCloseModal}
            handleSubmit={handleSubmit}
            Template={TemplateWizard}
            propsTemplate={{
              title: translate(`FORM_NOTICES_TITLE_LABEL`),
              skeleton: (
                <>
                  <SkeletonInput
                    count={2}
                  />
                </>
              ),
            }}
          />
        </LoadingProvider>
      </ModalAssistant>
    </>
  );
};

export default EditNotices;

import React, {useEffect, useState} from 'react';
import {Constants} from '../../../../../../utils/constants'
import {useDispatch} from "react-redux";
import _ from "lodash";
import notification from "../../../../../../utils/notification";
import Mustache from 'mustache';
import ServerConnect from "../../../../../../utils/ServerConnect";
import Loading from '../../../../../Loading/Loading';
import {LayoutForm} from '../../../../../../containers';
import {stripTypenames} from "../../../../../../utils/graphql-util";
import BeneficialOwnersForm
    from '../../../../../../containers/Forms/Questionniare/BeneficialOwners/BeneficialOwnersForm';
import {getBeneficialOwners, getEligibleBeneficialOwners, upsertQuestionnaireBeneficialOwners} from '../config'


const EditBeneficialOwners = (props) => {
    const dispatch = useDispatch();

    const closeForm = () => {
        dispatch({ type: 'GO_TO', page: Constants.PAGES.viewMatter, params: { id: props.params.matterId, tab:'questionnaire' } })
    }

    const [data, setData] = useState(null)
    const [eligibleData, setEligibleData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [list, setList] = useState([])

    const [initialValues, setInitialValues] = useState(null)

    const getData = () => {
        const query_params = {
            matterId: props.params.matterId
        };

        const query = Mustache.render(getBeneficialOwners, query_params)
        ServerConnect.graphQlQuery(query).then(( result ) => {
            let beneficialOwners = []
            _.forEach(_.get(result, 'getMattersList[0].questionnaire.beneficialOwners', []), comp => {
                beneficialOwners.push(comp.id)
                return comp.id
            })
            setData({beneficialOwners: beneficialOwners})
            
        });
    }

    const getEligibleData = () => {
        const query_params = {
            matterId: props.params.matterId
        };

        const query = Mustache.render(getEligibleBeneficialOwners, query_params)
        ServerConnect.graphQlQuery(query).then(({ getEligibleBeneficialOwners}) => {
            setEligibleData({beneficialOwners:stripTypenames(getEligibleBeneficialOwners)})
        });
    }


    


    const submitData = (values) => {
        let message = 'Beneficial Owners Updated';
        ServerConnect.graphQlMutation(upsertQuestionnaireBeneficialOwners, { matterId: props.params.matterId, beneficialOwners: [...values.beneficialOwners] })
            .then(result => {
                notification.sendNotification(message, "success", 4990);
                dispatch({ type: 'GO_TO', page: Constants.PAGES.viewMatter, params: { id: props.params.matterId, tab:'questionnaire' } })
            }).catch(err => {
                console.log(err);
                notification.sendNotification("ERROR", "error", 4990);
            });
    }

    const modifyDataFile = (values) => {
        var newValues = _.forOwn(values, (val, key) => {
            if (_.hasIn(val, 'file')) {
                return values[key] = val.file
            }
        })
        return newValues

    }

    const getVariablesMutation = (matterId, values) => {
        let companyDetailsData = { ...modifyDataFile(values) };
        return {
            matterId,
            companyDetailsData
        }
    }

    useEffect(() => {
        let unmounted= false
        if (!data && !eligibleData && props.mode == 'edit' && !unmounted) {
            setLoading(true)
            getData();
            getEligibleData()
        } else {
            setLoading(false)
        }

        return () => { unmounted = true };
    }, [data, loading, eligibleData]);



    return (
        <React.Fragment>
            
            <Loading show={loading} showChildren={false}>
                <LayoutForm
                    title={_.capitalize(props.params.type)}
                    leftEducationId={Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM[`${_.eq(props.mode, "edit") ? "EDIT" : "ADD"}`].LEFT_EDUCATION_ID}
                    handleClose={closeForm}>
                    <BeneficialOwnersForm eligibleData={eligibleData} initialValues={data}  onSubmit={submitData} list={list} closeForm={closeForm} />
                </LayoutForm>
            </Loading>
        </React.Fragment>
    )
}

export default EditBeneficialOwners;

import React, {useState} from 'react';
import {Modal, Text} from '../index';

import classes from "./Chat.module.scss";
import {Constants} from '../../utils/constants'
import {useDispatch} from "react-redux";

const ChatDeclareProvideGenuineInformation = ({ responseKeyName, submitQuestion }) => {
  const nameField = responseKeyName ? responseKeyName + "." : "";
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const closeFlow = () => {
    
    // dispatch({ type: 'GO_TO', page: Constants.PAGES.submitCanceled})
  }
  const [optionsButtons, setOptionsButtons] = useState([
    { 'label': 'TEXT_YES', 'value': true },
    { 'label': 'TEXT_NO', 'value': false }
  ])
  const handleChange = (value) => {
    setOpen(!open);

  }
  return (
    <React.Fragment>
      <div className={`${classes.ChatQuestionnaire} ${classes.ChatDeclareProvideGenuineInformation}`}>

        <div>
          <button className={`${classes.buttonOption} ${classes.width160}`} type="button" onClick={() => {submitQuestion ({},null) }}>
            <Text uuid={`TEXT_YES`} />
          </button>
          <button className={`${classes.buttonOption} ${classes.width160} `} type="button" onClick={() => { setOpen(true) }}>
            <Text uuid={`TEXT_NO`} />
          </button>
        </div>
        <Modal className={{ modal: classes.ModalChat }}
          open={open} handleClose={() => setOpen(false)} showHeader={false}>
          <div className={`${classes.ChatForm} ${classes.ChatFormDeclaration}`}>
            <h1><Text uuid={"CHAT_DECLARE_PROVIDE_GENUINE_INFORMATION_MODAL_TITLE"} /></h1>
            <p><Text uuid={"CHAT_DECLARE_PROVIDE_GENUINE_INFORMATION_MODAL_TEXT"} /></p>
            <div className={classes.botonera}>
              <button onClick={() => closeFlow()}><Text uuid={"CHAT_DECLARE_PROVIDE_GENUINE_INFORMATION_MODAL_BUTTON_CANCEL"} /></button>
              <button onClick={() => setOpen(false)} className={classes.nextButton} ><Text uuid={"CHAT_DECLARE_PROVIDE_GENUINE_INFORMATION_MODAL_BUTTON_OK"} /></button>
            </div>
          </div>
        </Modal>
      </div>
    </React.Fragment >
  );
}
export default ChatDeclareProvideGenuineInformation;

const useGetPartiesType = () =>{
    const getPartiesType = (documentType) => {
		switch (documentType) {
			case "WEBSITE_TERMS_OF_USE":
			case "WEBSITE_PRIVACY_POLICY":
			case "WEBSITE_ACCEPTABLE_USE_POLICY":
			case "WEBSITE_COOKIE_NOTICE":
			return {
				partyGroupCompany: {
					party: 'company',
					title: 'Company'
				}
			};
			case "IP_ASSIGNMENT":
				return {
					partyStakeholder: {
						party: 'assignor',
						title: 'Assignor'
					},
					partyGroupCompany: {
						party: 'assignee',
						title: 'Assignee'
					}
				};
			case "SAFE":
				return {
					partyStakeholder: {
						party: 'investor',
						title: 'Investor'
					},
					partyGroupCompany: {
						party: 'company',
						title: 'Company'
					}
				};
			case "CONVERTIBLE_NOTE":
				return {
					partyStakeholder: {
						party: 'investor',
						title: 'Investor'
					},
					partyGroupCompany: {
						party: 'company',
						title: 'Company'
					}
				};
			case "ADVISOR_AGREEMENT":
				return {
					partyStakeholder: {
						party: 'advisor',
						title: 'Advisor'
					},
					partyGroupCompany: {
						party: 'company',
						title: 'Company'
					}
				};
			case "USA_CONSULTING_AGREEMENT":
			case "CONSULTANT_AGREEMENT":
				return {
					partyStakeholder: {
						party: 'consultant',
						title: 'Consultant'
					},
					partyGroupCompany: {
						party: 'company',
						title: 'Company'
					}
				};
			case "EMPLOYMENT_AGREEMENT_ADGM":
			case "EMPLOYMENT_AGREEMENT_DIFC":
			case "EMPLOYMENT_AGREEMENT_UK":
				return {
					partyStakeholder: {
						party: 'employee',
						title: 'Employee'
					},
					partyGroupCompany: {
						party: 'company',
						title: 'Company'
					}
				};
			case "SHARE_INCENTIVE_PLAN_FAQ":
			case "BOARD_RESOLUTION":
			case "SHARE_INCENTIVE_PLAN":
				return {
					partyGroupCompany: {
						party: 'company',
						title: 'Company'
					}
				};
			case "FOUNDER_SERVICES_AGREEMENT":
				return {
					partyStakeholder: {
						party: 'founder',
						title: 'Founder'
					},
					partyGroupCompany: {
						party: 'company',
						title: 'Company'
					}
				};
			case "USA_CAL_EMPLOYEE_INFORMATION_AND_INVENTIONS_AGREEMENT":
				return {
					partyStakeholder: {
						party: 'employee',
						title: 'Employee'
					},
					partyGroupCompany: {
						party: 'company',
						title: 'Company'
					}
				};
			case "USA_CONSULTANT_INFORMATION_AND_INVENTIONS_AGREEMENT":
				return {
					partyStakeholder: {
						party: 'consultant',
						title: 'Consultant'
					},
					partyGroupCompany: {
						party: 'company',
						title: 'Company'
					}
				};
			case "USA_STRATEGIC_ADVISORY_BOARD_LETTER":
				return {
					partyStakeholder: {
						party: 'advisor',
						title: 'Advisor'
					},
					partyGroupCompany: {
						party: 'company',
						title: 'Company'
					}
				};
			case "GRANT_AGREEMENT":
				return {
					partyStakeholder: {
						party: 'participant',
						title: 'Participant'
					},
					partyGroupCompany: {
						party: 'company',
						title: 'Company'
					}
				};
			case "USA_OPTION_GRANT_NOTICE":
				return {
					partyStakeholder: {
						party: 'participant',
						title: 'Participant'
					},
					partyGroupCompany: {
						party: 'company',
						title: 'Company'
					}
				};
			case "RESTRICTIVE_COVENANT_AGREEMENT":
				return {
					partyStakeholder: {
						party: 'teamMember',
						title: 'Team Member'//Ver si debo poner consultant
					},
					partyGroupCompany: {
						party: 'company',
						title: 'Company'
					}
				};
			case "EXERCISE_NOTICE":
				return {
					partyStakeholder: {
						party: 'participant',
						title: 'Participant'
					}
				};
			case "NDA_MUTUAL":
				return {
					partyStakeholder: {
						party: 'stakeholder',
						title: 'Party One'
					},
					partyGroupCompany: {
						party: 'company',
						title: 'Party Two'
					}
				};
			case "FOUNDERS_AGREEMENT":
				return {
					partyStakeholder: {
						party: 'founders',
						title: 'Founders',
						isIndividual: false
					},
					partyGroupCompany: {
						party: 'company',
						title: 'Company'
					}
				};
			case "BOARD_RESOLUTION_CONVERTIBLE_LOAN_NOTE":
			case "BOARD_RESOLUTION_CONVERTIBLE_SAFE":
			case "BOARD_RESOLUTION_CONVERTIBLE_KISS":
			case "BOARD_RESOLUTION_CONVERTIBLE_FIXED_EQUITY":
			case "BOARD_RESOLUTION_CONVERTIBLE_OTHER":
				return {
					partyGroupCompany: {
						party: 'company',
						title: 'Company'
					}
				};
			case "USA_BOARD_CONSENT_SAFE_CONVERTIBLE_LOAN_NOTE":
			case "USA_BOARD_CONSENT_SAFE_SAFE":
			case "USA_BOARD_CONSENT_SAFE_KISS":
			case "USA_BOARD_CONSENT_SAFE_FIXED_EQUITY":
			case "USA_BOARD_CONSENT_SAFE_OTHER":
			case "USA_BOARD_CONSENT_SAFE":
				return {
					partyGroupCompany: {
						party: 'company',
						title: 'Company'
					}
				};
			case "USA_NDA":
				return {
					partyGroupCompany: {
						party: 'company',
						title: 'Company'
					},
					partyStakeholder: {
						party: 'stakeholder',
						title: 'Party One'
					},
				};

			//CP-5321
			case "USA_CAL_OFFER_LETTER_SALARY_EMPLOYEE":
				return {
					partyGroupCompany: {
						party: 'company',
						title: 'Company'
					},
					partyStakeholder: {
						party: 'stakeholder',
						title: 'Employee'
					},
				};
			case "USA_BOARD_APPROVING_OPTION_GRANT":
				return {
					partyGroupCompany: {
						party: 'company',
						title: 'Company'
					}
				};
			case "USA_EQUITY_INCENTIVE_PLAN":
			case "USA_STAKEHOLDERS_DIRECTORS_EQUITY_INCENTIVE_PLAN":
				return {
					partyGroupCompany: {
						party: 'company',
						title: 'Company'
					},
					partyStakeholder: {
						party: 'stakeholders',
						isIndividual: false,
						title: 'Stockholders'
					},
				};
			default:
				break;
		}
	};
    return getPartiesType
}
export default useGetPartiesType;
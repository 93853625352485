import {TypePolicy} from "@apollo/client";



const StakeholderRole: TypePolicy = {
    keyFields: ["id", "role",["id"]],
    fields: {
        position: {
            merge: false
        }
    }
}

const Goal: TypePolicy = {
    keyFields: ["code"],
    fields: {
        position: {
            merge: false
        }
    }
}
const StartupUserProfile: TypePolicy = {
    keyFields: ["startup",["id"]],
    fields: {
        position: {
            merge: false
        }
    }
}

const TaskEntityFragment = `fragment TaskEntityFragment on TaskEntityData{
    entityId
    entityType
}`

const TasksRequiredFieldFragment = `
    ${TaskEntityFragment}
    fragment TasksRequiredFieldFragment on Task {
    code
    additionalParams {...TaskEntityFragment} 
}`

export {
    StartupUserProfile,
    StakeholderRole,
    TasksRequiredFieldFragment,
    TaskEntityFragment,
    Goal,
}
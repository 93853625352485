import React from 'react';
import _ from 'lodash'
import useQuery from '../../hooks/custom/useQuery'
const QUERY = `query getEntityDirectorsAndShareholdersDraft($draftId : ID!){
  getEntityDirectorsAndShareholdersDraft(draftId:$draftId){
    individuals
    companies
    entity
  }
  
}`;


const mapIndividuals = (data, loading) => {

    const {individuals:newIndividuals = [],...rest} = data

    const individuals =  newIndividuals.map(indi => {

        const {passports: passportWith_id } = indi;
        const passports = _.map(passportWith_id, pass => (_.omit({...pass, id: pass._id},['_id'])))
        return {...indi, passports}

    });

    return {individuals, ...rest}

}
export default function useCompanyDirectoresShareHolders(refresh, draftId) { 

    const variables = {draftId};
    const {loading, error, data,refetch} = useQuery(`${QUERY}`, variables);

    const result = mapIndividuals(_.get(data,"getEntityDirectorsAndShareholdersDraft",[]));

    return {result, loading, error,refetch};
}
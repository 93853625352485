import _ from "lodash";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../../../../components/generic/Buttons/PrimaryButton/PrimaryButton";
import BusinessRuleAccess from "../../../../modules/clara/components/BusinessRuleAccess/BusinessRuleAccess";
import RoleAccess from "../../../../modules/security/components/RoleAccess/RoleAccess";
import Date from "../../../../components/text/Text/Date";
import Text from "../../../../components/text/Text/Text";
import useDoAHealthCheck from "../../../../hooks/services/modules/subscription/useDoAHealthCheck";
import {useGetLastHealthCheckRun} from "../../../../modules/startup/graphql/startup/useGetStartup"
import event from "../../../../v1/utils/event";
import { Constants } from './../../../../v1/utils/constants';
import classes from "./Overview.module.scss";
import useSession from "../../../../modules/session/hooks/useSession";

const ZoneCharRun = (props) => {
    const dispatch = useDispatch();
    const {startup,updateStartup} = useSession();
    const {data:healthCheckRunData, loading:healthCheckRunLoading, error} = useGetLastHealthCheckRun({startupId:startup.id });

    const left = useSelector(store => {
        const feature = _.find(_.get(store, `session.startup.activeSubscriptionPlan.features`, []), (feature) => feature.code == Constants.PRODUCTS.HEALTH_CHECK)
        if (!feature) return null;
        const limit = _.get(feature, "limit.amount", 0);
        const usages = _.get(store, "session.startup.subscriptionFeaturesUsage");
        const usage = _.find(usages, (feature) => feature.code == Constants.PRODUCTS.HEALTH_CHECK);
        const actual = usage ? usage.amount : 0;
        const left = limit - actual;
        return left < 0 ? 0 : left;
    });

    const compleHealthCheck = (data) => {
        updateStartup({lastHealthCheckRun:data.doAHealthCheck});
        event.emmit(Constants.EVENTS.HEALTH_CHECK_REFRESH, {});
    }
    const [runHealthCheck, loading] = useDoAHealthCheck({ successMessage: "ASSISTANCE_HEALTHCHECK_NOTIFICATION_SUCCESS", onCompleted: compleHealthCheck });
    
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        setRefresh(refresh + 1);
    }, [startup.lastHealthCheckRun]);

    const handleRunHealthCheck = () => {
        const variables = {
            startupId: startup.id
        }
        runHealthCheck(variables);
    }

    return (
        <div className={classes.AssistanceContentContainer}>

            <div className={classes.Botonera} >
                <BusinessRuleAccess conditions={[Constants.BUSINESS_RULES.IS_START_SUBSCRIPTION]}>
                    {left > 0 && (
                        <React.Fragment>
                            <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
                                <PrimaryButton onClick={handleRunHealthCheck} loading={loading}>
                                    <Text uuid={"BUTTON_RUN_HEALTH_CHECK"} />
                                </PrimaryButton>
                            </RoleAccess>
                        </React.Fragment>

                    )}
                </BusinessRuleAccess>
                <div className={classes.TextLastRun}>
                    <Text uuid={"DUEDILIGENCE_LAST_RUN"} /> <Date value={_.get(healthCheckRunData,"lastHealthCheckRun")} />
                </div>
            </div>


        </div >

    )
}

export default ZoneCharRun;


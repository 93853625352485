import React, { FC, useMemo } from 'react';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import AdgmTechStartupWizard from './../../submachines/AdgmTechStartupModal/wizard';

interface Props {

}
/**
* Description Step: Description ADGM tech startup 
*/
const AdgmTechStartupSubMachineStep: FC<Props> = () => {

  const { next, context, prev, cancel, send } = useMachine();
  const initialValues = useMemo(() => {
    return context?.StepForm ?? {}
  }, [context]);

  const handleStopMachine = () => {
    next(context)
  }
  const handleCancelMachine = () => {
    cancel();
  }
  const handlePrevMachine = () => {
    prev();
  }
  const handleChooseJusisdiction = () => {
    send({ type: 'CHOOSE_JURISDICTION', payload: {} });
  }

  return (
    <AdgmTechStartupWizard
      initialValues={initialValues}
      onFinish={handleStopMachine}
      onCancel={handleCancelMachine}
      onPreviousMachine={handlePrevMachine}
      goToChooseJurisdiction={handleChooseJusisdiction}
    />
  );
};

export default AdgmTechStartupSubMachineStep;

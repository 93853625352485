/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import IcoMoon from "react-icomoon";
import PropTypes from "prop-types";
const iconSet = require("./icons/selection.json");
const iconClaraSet = require("./clara/selection.json");

const Icon = ({ isClara, isClara2023, ...props }) => {
  const icoset = isClara ? iconClaraSet : iconSet;
  const iconsPrefix = ["clara2023"];
  const icon = props.icon;

  const useCustom = useMemo(() => {
    let result = false;
    if (icon) {
      result = icon.includes(iconsPrefix);
    }
    return result;
  }, [icon, iconsPrefix]);

  return useCustom ? <span {...props} className={`${icon}`}/> : <IcoMoon iconSet={icoset} disableFill={true} {...props} />;
};
Icon.propTypes = {
  /**  Icon */
  icon: PropTypes.string.isRequired,
  /**  Size */
  size: PropTypes.string,
  /**  Type Button */
  isClara: PropTypes.bool,

};
Icon.defaultProps = {
  icon: "plus",
  size: "1rem",
  isClara: false,
};


export default Icon;
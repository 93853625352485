import React from "react";
import * as Yup from "yup";
import Form from "../../../../../components/forms/Form/Form";
import ControllerInput from "./../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import SubmitButton from "./../../../../generic/components/Buttons/SubmitButton";
import FormProps from "./../../../../generic/forms/interfaces/FormProps";
import TemplateFormDefault from "./../../../../generic/templates/Modal";
import Typography from "./../../../../generic/components/Texts/Typography";
import CheckboxButtonSimple from "./../../../../generic/components/Inputs/CheckboxButtonSimple";
import { HiddenFieldForm } from "../../../../../components/forms/contents";
import Button from "../../../../generic/components/Buttons/Button";
import useDownloadDocProofAddress from "../hooks/useDownloadDocProofAddress";
import classes from "./Form.module.scss";
import useCustomMachine from "./../../../../generic/hooks/useCustomMachine";
import _ from "lodash";

const schema = Yup.object().shape({
  typeProofAddress: Yup.string().required("Required"),
  files: Yup.array().min(1),
});

interface FormPropsCustom extends FormProps {
  text: string;
}

const FormCurrentSelect: React.FC<FormPropsCustom> = ({
  initialValues = {},
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  text = "",
  propsTemplate,
}) => {

  const { downloadTemplateProofAddress } = useDownloadDocProofAddress();
  const { state } = useCustomMachine();

  const handleSubmit = async (values) => {
    onCompleteSubmit && (await onCompleteSubmit(values));
  };

  const options = React.useMemo(() => {
    return [
      { 
        label: "No, not right now", 
        value: "NO_PROOF_ADDRESS" 
      },
      { 
        label: "Yes, upload it now", 
        value: "UPLOAD_PROOF_ADDRESS"
      },
      {
        label: "Upload third party address form and passports",
        value: "UPLOAD_PROOF_ADDRESS_PARTY",
      },
      {
        label: "Download third party address form",
        value: "DOWNLOAD_PROOF_ADDRESS",
      },
    ];
  }, []);

  const optionsCay = React.useMemo(() => {
    return [
      { 
        label: "No, not right now", 
        value: "NO_PROOF_ADDRESS" 
      },
      { 
        label: "Yes, upload it now", 
        value: "UPLOAD_PROOF_ADDRESS"
      },
    ];
  }, []);

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <Template
        buttons={{
          ...buttons,
          submit: <SubmitButton {...buttonSubmitProps}>Next</SubmitButton>,
        }}
        props={propsTemplate}
      >
        <div>
          <Typography variant="body">{text}</Typography>
          <ControllerInput
            render={CheckboxButtonSimple}
            options={_.get(state, "context.data.jurisdictionType") === "CAYMAN" ? optionsCay : options}
            name="typeProofAddress"
            defaultlabel={""}
            className={classes.CheckboxButton}
            classNameButton={classes.buttonCheckBox}
          />
          <HiddenFieldForm
            fieldsWatch={"typeProofAddress"}
            conditionHideCallback={(value) =>
              value === "DOWNLOAD_PROOF_ADDRESS"
            }
          >
            <div className={classes.downloadContainer}>
              <Button
                onClick={downloadTemplateProofAddress}
                className={"mt-4"}
                type={"button"}
                variant={"primary"}
                data-cy="submit"
              >
                Download
              </Button>
            </div>
          </HiddenFieldForm>
        </div>
      </Template>
    </Form>
  );
};

export default FormCurrentSelect;

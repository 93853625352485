import { useMemo } from "react";
import TemplateWizardSkeletonMachine from "src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import { CorporatePosition } from "../Steps";
const useSteps = ({edit}) => {
    const { translate } = useTranslate();
    return  useMemo(() => ({
      serviceGetStakeholders:{
        render: TemplateWizardSkeletonMachine,
        renderProps:{
          title:  translate('MODULES.EQUITY.ADD_EQUITY.TITLE'),
          subTitle: translate('MODULES.EQUITY.ADD_EQUITY.SUBTITLE'),
        },
        stepperPosition: 0
      },
      setCorporatePosition: {
          render: TemplateWizardSkeletonMachine,
          renderProps:{
            title:  translate('MODULES.EQUITY.ADD_EQUITY.TITLE'),
            subTitle: translate('MODULES.EQUITY.ADD_EQUITY.SUBTITLE'),
          },
          stepperPosition: 0
        },
        selectCorporatePosition: {
          render: CorporatePosition,
          stepperPosition: 0,
          renderProps:{ 
            edit,
          }
        },
      }),[edit, translate]);
}
export default useSteps;
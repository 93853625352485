import React, { useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment/moment";
import _ from "lodash";
import * as numeral from "numeral";
import Button from "src/modules/generic/components/Buttons/Button";
import { Constants } from "src/v1/utils/constants";
import DetailsTabTitle from "src/components/templates/DetailsTabTitle";
import EditGeneralDetails from "src/modules/startup/GroupCompany/ADGM/EditGeneralDetails";
import Link from "src/components/text/Link/Link.js";
import { RoleAccess } from "src/v1/components";
import TwoColumnsViewer from "src/modules/generic/components/TwoColumsViewer";
import useModal from "src/modules/generic/hooks/useModal";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import PincasIcon from "src/modules/generic/components/Icon/PincasIcon";
import GroupCompany from "src/components/modules/clara/startups/entities/classes/GroupCompany";
import { EditProfile as ModalEditProfile } from "src/components/modules/clara/startups/groupCompany/Modals";

function GeneralDetailsPanel({ data, isLock, isFormation }) {
  const { translate } = useTranslate();
  const { openModal, closeModal } = useModal();

  const buttonsManager = (
    <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
      <Button
        variant="card"
        disabled={isLock}
        children={translate("VIEW_COMPANY_TAB_BUTTON_DETAILS_EDIT")}
        onClick={() => {
          if (_.get(data, "kind") === "FORMATION") {
            openModal(ModalEditProfile, { handleCloseModal: closeModal });
          } else {
            openModal(EditGeneralDetails, {
              handleCloseModal: closeModal,
              initialValues: { groupCompanyId: data?.id },
            });
          }
        }}
      />
    </RoleAccess>
  );

  const handleName = (type) => {
    switch (type) {
      case "SPV":
        return "Special Purpose Vehicles (SPV)";
      case "TSL":
        return "Tech Licence";
      default:
        return "ADGM Tech Licence";
    }
  };

  const groupCompanyClass = useMemo(() => {
    if (!data) return null;
    return new GroupCompany(data);
  }, [data]);

  const showRenewalDate = useMemo(() => {
    let result = _.get(data, "postIncorporation.nextRenewalDate") === null;
    if (data?.dissolution_date !== null) {
      result = true;
    }
    return result;
  }, [data]);

  return (
    <>
      <DetailsTabTitle title="General details" buttons={buttonsManager} />
      <TwoColumnsViewer
        itemsCol1={[
          {
            label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_COUNTRY"),
            value: _.get(data, "country.name"),
          },
          {
            label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_JURISDICTION"),
            value: _.get(data, "jurisdiction"),
          },
          {
            label: "Company name became effective on",
            value: _.get(data, "nameBecameEffectiveOn")
              ? moment(_.get(data, "nameBecameEffectiveOn")).format(
                  "D MMM YYYY"
                )
              : "-",
            isHidden: _.get(data, "nameBecameEffectiveOn") === null,
          },
          {
            label: "Previous company name (if applicable)",
            value: data?.previousName ?? "-",
          },
          {
            label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_COMPANY_TYPE"),
            value: data?.type ?? "",
          },
          {
            label: "Licence type",
            value: handleName(_.get(data, "licenseType")),
            isHidden: _.get(data, "licenseType") === null,
          },
        ]}
        itemsCol2={[
          {
            label: "Company status",
            value: _.get(data, "companyStatus")
              ? _.startCase(_.toLower(_.get(data, "companyStatus")))
              : "-",
            isHidden: _.get(data, "companyStatus") === null,
          },
          {
            label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_COMPANY_NUMBER"),
            value: _.get(data, "registeredNumber"),
            isHidden:
              _.get(data, "registeredNumber") === null ||
              _.get(data, "registeredNumber") === "",
          },
          {
            label: translate(
              "VIEW_COMPANY_TAB_LABEL_DETAILS_INCORPORATION_DATE"
            ),
            value: _.get(data, "incorporation_date")
              ? moment(_.get(data, "incorporation_date")).format("D MMM YYYY")
              : "-",
            isHidden: _.get(data, "incorporation_date") === null,
          },
          {
            label: "Next renewal date",
            value: data?.postIncorporation?.nextRenewalDate
              ? moment(data?.postIncorporation?.nextRenewalDate).format(
                  "D MMM YYYY"
                )
              : "-",
            isHidden: showRenewalDate,
          },
          {
            label: "Dissolution date",
            value: data?.dissolution_date
              ? moment(data?.dissolution_date).format(
                  "D MMM YYYY"
                )
              : "-",
            isHidden: _.get(data, "dissolution_date") === null,
          },
          {
            label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_CURRENCY"),
            value: isFormation ? "USD" : _.get(data, "transactionCurrency"),
          },
          {
            label: "Authorised share capital",
            value:
              !_.isNil(_.get(data, "authorisedShareCapital")) &&
              `USD ${numeral(_.get(data, "authorisedShareCapital")).format(
                "0,0"
              )}`,
            valuePrefix: groupCompanyClass?.getFieldInMatter(
              "authorisedShareCapital"
            ) ? (
              <PincasIcon />
            ) : null,
            disabledField: groupCompanyClass?.getFieldInMatter(
              "authorisedShareCapital"
            ),
            isHidden: _.get(data, "authorisedShareCapital") === null,
          },
          {
            label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_WEBSITE"),
            value: _.get(data, "linkedinURL") ? (
              <Link target={"_blank"} href={_.get(data, "linkedinURL")}>
                {_.get(data, "linkedinURL")}{" "}
              </Link>
            ) : (
              "-"
            ),
          },
        ]}
      />
    </>
  );
}

GeneralDetailsPanel.propTypes = {
  data: PropTypes.any,
  isLock: PropTypes.bool,
  isFormation: PropTypes.bool,
};

export default GeneralDetailsPanel;

import React, {FC, useMemo} from 'react'
import {StepProps} from "../../../../../../../../generic/components/Wizard/Step";
import SelectCompaniesStep from "../../../../../../wizard/steps/SelectCompaniesStepForm";
import useCustomMachine from "../../../../../../../../generic/hooks/useCustomMachine";
import _ from 'lodash'
const SelectCompaniesWarrantStep:FC<StepProps>=({})=>{
    return(<>
        <SelectCompaniesStep
            type={'WARRANT'}/>
    </>)
}
export default SelectCompaniesWarrantStep
import React, { FC, useMemo } from "react";
import DocumentUploadedActions from "../../../../modules/assistanceV4/components/AssistanceAreas/DocumentUploaded/Actions";
import DocumentUploadTasks from "../../../../modules/assistanceV4/components/AssistanceAreas/DocumentUploaded/Tasks";
import AssistanceHelp from "../../../../modules/assistanceV4/components/AssistanceAreas/Help";
import AssistanceFrame2 from "./"
import { useParams } from 'react-router-dom';
import useSession from "../../../../../src/modules/session/hooks/useSession";
import useGetDocument from "../../../../../src/hooks/services/modules/Document/useGetDocument";
import _ from "lodash";
import useTabStatus from "../../hooks/useTabStatus";

/**
 * Assistance Frame for DocumentsUploaded
 */

export interface AssistanceDocumentUploadedProps {

}

const AssistanceDocumentUploaded: FC<AssistanceDocumentUploadedProps> = ({
  ...props
}: AssistanceDocumentUploadedProps) => {

  const params = useParams();
  const { startupId } = useSession();

  const { data } = useGetDocument({
    agreementId: _.get(params, "id"),
    startupId,
  });

  const lockActions = useMemo(() => {
    return _.get(data, "isLocked", false);
  }, [JSON.stringify(data)]);

  const { showTask, showAction, showHelper, activeTab } = useTabStatus("completed", null, lockActions);

  return (
    <>
      <AssistanceFrame2
        tasksComponent={lockActions ? DocumentUploadTasks : null}
        showTask={showTask}
      />
    </>
  );
};

export default AssistanceDocumentUploaded;

import get from 'lodash/get'
import { Field } from 'formik'
import classes from '../styles.module.scss'
import React from 'react'
const NoShares = ({entityLabel}) =>{
    return (<div className={classes.shareholdingTerms}>
                <Field name={`${entityLabel}.no_shares_error`}>
                    {({ field, form }) => (
                        <span hasError={get(form.errors, `${entityLabel}.share`) ? "true" : "false"}>
                            <p style={get(form.errors, `${entityLabel}.share`) ? { color: '#FF606F' } : null}>This founder does not have any share positions yet. Please go to “Equity” to add a share issuance for this founder.</p>
                        </span>)}
                </Field>
            </div>
    )
}
export default NoShares
import React, {useEffect, useMemo} from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import Form from 'src/components/forms/Form/Form';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import Button from 'src/modules/generic/components/Buttons/Button';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { addAssistantText, clearAssistatText } from 'src/modules/generic/store/action';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import AddressInput from "src/components/forms/modules/startups/individualStakeholder/AddressInput";
import SelectInput from 'src/components/inputs/Select/SelectInput/SelectInput';

const schema = Yup.object().shape({
  mailingAddressType: Yup.string().required('This field is required').typeError('This field is required'),
  mailingAddress: Yup.object().shape({
    country: Yup.string().required('This field is required').typeError('This field is required'),
    street: Yup.string().required('This field is required'),
    city: Yup.string().required('This field is required'),
    state: Yup.string().nullable(),
    zipCode: Yup.string().nullable(),
  }),
});

function ReviewCompanyAddressStep() {
  const { next, context, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    const mailingAddress = context?.initialData?.groupCompany?.mailingAddress ?? {};
    return {
      mailingAddressType: mailingAddress?.type?.toLowerCase(),
      mailingAddress: {
        country: mailingAddress?.country?.code,
        street: mailingAddress?.street,
        city: mailingAddress?.city,
        state: mailingAddress?.state,
        zipCode: mailingAddress?.zipCode,
      }
    }
  }, [context]);

  const handleCancel = () => {
    cancel();
  }

  const handlePrev = () => {
    prev();
  }

  const handleSubmit = async (values) => {
    next(values);
  }

  useEffect(() => {
    dispatch(
      addAssistantText(
        translate(
          "MODULES.RENEWALS.ECONOMIC_SUBSTANCE.COMPANY_ADDRESS.TOOLTIP"
        )
      )
    );
    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch]);

  const mailingAddressTypeOptions = useMemo(() => {
    return [
      { value: 'business', label: 'Business' },
      { value: 'residential', label: 'Residential' }
    ];
  }, []);

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: translate('MODULES.RENEWALS.ECONOMIC_SUBSTANCE.COMPANY_ADDRESS.TITLE'),
          skeleton: null,
          subTitle: translate('MODULES.RENEWALS.ECONOMIC_SUBSTANCE.GENERIC.SUBTITLE'),
          variant: 'simple'
        }}
        buttons={{
          previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton>Next</SubmitButton>,
        }}
      >
        <TemplateLabel label="Mailing address type" isRequired>
          <ControllerInput
            render={SelectInput}
            placeholder={translate("SELECT_INPUT_PLACEHOLDER")}
            list={mailingAddressTypeOptions}
            name="mailingAddressType"
            clear={true}
          />
        </TemplateLabel>
        <AddressInput
          name="mailingAddress"
          label={{ country: "Mailing address" }}
          zipCodeOptional
          isRequired
        />
        <div>&nbsp;</div>
      </TemplateWizard>
    </Form>
  );
}

export default ReviewCompanyAddressStep;
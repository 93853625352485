import React from "react";
import loadings from "./index";

const Loading = ({ type = "circular", ...props }) => {
  return (
    <React.Fragment>
      {React.createElement(loadings[type], props)}
    </React.Fragment>
  );
};
export default Loading;

import React, { FC } from "react";
import Button from "src/modules/generic/components/Buttons/Button";
import { CheckboxOptions } from "src/modules/generic/components/Inputs/Checkbox";
import Typography from "src/modules/generic/components/Texts/Typography";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import DeleteForm from "src/modules/generic/forms/Delete";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import TemplateDelete from "src/modules/generic/templates/Modal/TemplateDelete";
import classes from '../../classes.module.scss';

const Delete: FC<{}> = () => {

  const { translate } = useTranslate()
  const { context, next, send } = useMachine();
  const { stakeholder } = context?.deleteVerification

  const handleCancel = () => {
    send('CANCEL')
  }

  const onSubmit = async (values) => {
    next(values)
  }

  const options: Array<CheckboxOptions<string>> = [{ value: 'delete', label: translate('MODULES.STAKEHOLDER.DELETE.DELETE') }];
  const title = translate('MODULES.STAKEHOLDER.DELETE.TITLE_NO_CONNECTIONS', { stakeholder });

  return (
    <DeleteForm
      propsTemplate={{
        title,
        description: <Typography component={'p'} color={'error'}>{translate('MODULES.STAKEHOLDER.DELETE.DESCRIPTION')}</Typography>
      }}
      onCompleteSubmit={onSubmit}
      checkBoxProps={{ options }}
      isThereConnections={false}
      buttons={{
        cancel: <Button type={'button'} className={classes.cancelButton}
          onClick={handleCancel}>Cancel</Button>
      }}
      Template={TemplateDelete}
    />
  )
}

export default Delete
import React, {Component} from "react";
import {connect} from "react-redux";
import {Loading} from "../../index";


import Mustache from 'mustache'
import ServerConnect from '../../../utils/ServerConnect';
import {Constants} from '../../../utils/constants';
import _ from "lodash";

import EditForm from "./EditForm";
import EditDetails from "../../Forms/Edit/Equity/Details";
import ViewDetails from "../../Forms/View/Equity/Details";
import FormikHelper from "../../../utils/formikHelper";
import {renameKey} from "../../../utils/graphql-util";

import {getGroupCompaniesWrong} from '../../../graphql/mutations/addStakeholder';
import {upsertConvertibles, upsertEquity, upsertWarrants} from '../../../graphql/mutations/equity';

class EquityForm extends Component {
    state = {
        data: {},
        dataEdit:{},
        refresh: false,
        showLoading: false,
        initialValues: {},
        stakeholder: [],
        documents: [],
        groupCompanies: [],
        wasUpdated: false,
        sharentIncentive: [],
        subTitle:null,
        c: 0,
    };


    componentWillMount() {
        let { data } = this.props;
        let values = data.fromView.__typename === "GroupCompany" ? { fromView: "company", holder: "owner"} : { fromView: "owner", holder: "company"};
        data =renameKey(data, values);
        data.company.type = "groupCompany"; 
        data.owner.type= data.owner.__typename === "GroupCompany" ? "groupCompany":"stakeholder";
        data.associatedDocuments = _.get(_.head(data.documents),"id","");
      

        this.setState({
            showLoading: true,
            subTitle: `For ${data.owner.fullName || data.owner.name}`
        });

        Promise.all([
            this.callServerToGetGroupCompanies(),
            this.callServerToGetStakeholders(),
        ]).then((result) => {
            this.setState({
                groupCompanies: result[0],
                stakeholders: result[1],
                data:data,
                documents: this.mapDocumentList(result[0], data.company.id, data.associatedDocuments)
            }, () => {
                this.setState({
                    refresh: !this.state.refresh,
                    showLoading: false
                })
            })
        })
       
        
    }

    mapDocumentList(groupCompanies, groupCompanyId, documentId){
         let documents = _.get( _.find(groupCompanies, { id: groupCompanyId}),"documents" ,[]);
            return _.compact([_.find(documents,{id: documentId})]); //changed maped when new component
    }

    callServerToGetStakeholders = () => {
        const stakeholderList = `{
                stakeholderList(startupId: "{{startupId}}") {
                    id
                    fullName
                    avatar
            }
        }`;

        return new Promise((resolve, reject) => {
            const queryParams = { startupId: this.props.session.startup.id };
            const query = Mustache.render(stakeholderList, queryParams);

            ServerConnect.graphQlQuery(query).then(result => {

                if (result && result.stakeholderList) {
                    resolve(result.stakeholderList.map(st => ({ ...st, label: st.fullName })));

                }
            });
        })
    };


    callServerToGetGroupCompanies = () => {
        return new Promise((resolve, reject) => {
            const queryParams = { startupId: this.props.session.startup.id };
            //const query = Mustache.render(getGroupCompanies, queryParams);

            ServerConnect.graphQlQuery(getGroupCompaniesWrong, queryParams).then(result => {
                if (result && result.getGroupCompanies) {
                    resolve(_.orderBy(result.getGroupCompanies, ['isHoldingCompany'], ['desc']).map(gc => ({ ...gc, label: gc.name })))
                }
            });
        })
    };

    getTitleEditForm = () => {
        return this.state.data && this.state.data.agreementName ? this.state.data.agreementName : "Shared";
    };

    transformInitialData = (data) => {
       
    };

    selectedMapValuesAndMutation(dataForm){
        switch (dataForm.type)
        {
            case "SHARE":
                return  { 
                    values: _.assign(FormikHelper.mapEquityPositionDataForMutation(dataForm,dataForm.type),{shareType: dataForm.type}),
                    mutation:upsertEquity };
            case "OPTION":
                return {
                    values: _.assign(FormikHelper.mapEquityPositionDataForMutation(dataForm,dataForm.type),{shareType: dataForm.type}),
                     mutation:upsertEquity};
            case "WARRANT":
                return  {values: FormikHelper.mapWarrantDataForMutation(dataForm), mutation:upsertWarrants };   
            case "CONVERTIBLE":
                return {values: FormikHelper.mapConvertibleDataForMutation(dataForm), mutation: upsertConvertibles}; 
                
                default:
                    break;    
        }
    }

    transformFinishData = (value, classes) => {

       
     //  console.log(JSON.stringify(value,null,2))
        this.setState({
            showLoading: true
        });
      
        value.owner = _.pick(value.owner, "type","id");
        const startupId = this.props.session.startup.id;
        const { mutation, values } = this.selectedMapValuesAndMutation(value);
        if ((value.type === "SHARE" || value.type === "OPTION" ) && !values.equityData.shareClass  ){

            let shareClass = _.find(classes,(classe) => {
                return classe.default===true && value.company.id === classe.groupCompanyId
            });
            
            if (shareClass){
                values.equityData.shareClass=shareClass.id;
            }
        }
        

        
        
        const data = {
            startupId,
            ...values
        };


       console.log(JSON.stringify(data,null,2))
        ServerConnect.graphQlMutation(mutation, data).then((result) => {
            this.props.handleClose();
           return this.setState({
                showLoading: false
            });

        });

    };

    componentDidMount() {
        
        ServerConnect.requestApi(
            `/contentful/tooltips/${Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.ADD.TOOLTIP}`,
            {
                method: 'GET', headers: { "Content-Type": "application/json" },
            }).then(res => {
                console.log('response', res.response);
                this.setState({
                    tooltips: res.response
                })
            }).catch(err => {
                console.log('Tooltips from contentful error: ', err);
            });
    }

    render() {
        let { mode } = this.props;
        let { data,subTitle ,dataEdit, groupCompanies, wasUpdated, stakeholders, documents } = this.state;

        return (
            <Loading show={this.state.showLoading}>
                <EditForm
                    formType={Constants.FORMS.EQUITY}
                    accessDelete={Constants.ACTIONS.EDIT_PROFILE}
                    title={data.title}
                    subTitle={subTitle}
                    mode={mode}
                    data={data}
                    wasUpdated={wasUpdated}
                >
                    <EditForm.Container tab={{ name: "", key: "" }}>
                        <EditForm.View isView={true} >
                            <ViewDetails
                                data={data} documents={ documents }
                            />
                        </EditForm.View>
                        <EditForm.Edit>
                            <EditDetails
                                data={data}
                                updateForm={this.transformFinishData}
                                stakeholders={stakeholders}
                                groupCompanies={groupCompanies}
                                refresh={() => { this.setState(prevState => ({ c: prevState.c += 1 })) }}
                                cancelData={this.state.originData}
                                c={this.state.c}
                                tooltips={this.state.tooltips}
                            />
                        </EditForm.Edit>
                    </EditForm.Container>
                </EditForm>
            </Loading>
        )
    }
}

const mapStateToProps = state => ({
    session: state.session
});

const mapDispatchToProps = (dispatch) => ({
   
});

export default connect(mapStateToProps, mapDispatchToProps)(EquityForm)

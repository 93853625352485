import React, {Component} from "react";

import classes from "./IconButton.module.scss"


class IconButton extends Component {
    state = {
      
    }

    handleClick = (e) => {
        this.props.onClick(e);
    }
   
    render() {
        const {className,disabled} = this.props;
        return (
            <button disabled={disabled} onClick={this.handleClick} className={`${classes.IconButton} ${className}`}>
               
            </button>
        );
    }
}

export default (IconButton)

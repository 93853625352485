import { Field } from "formik"
import get from 'lodash/get'
import React, { FC, useState } from "react"
import SelectVestingTerms from "src/components/inputs/Select/SelectVestingTerms"
import TextInput from "src/v1/containers/Forms/Inputs/TextInput"
import classes from "../styles.module.scss"
import ShareClassFields from "./ShareClassFields"
import VestingTermsFields from "./VestingTermsFields"
interface Props {
    tooltips:{[label:string]:any};
    entityLabel:string
    groupCompany:any;
    form:any;
}
    const  COmpnayNotEstablishedShares:FC<Props> = ({tooltips,entityLabel, groupCompany,form}) =>{
    
        const [isAddVestingTerms,setIsAddVestingTerms] = useState(get(form.values,`${entityLabel}.share[0].vestingTerms.id`) === 'ADD')
        const [showVestingTerms,setShowVestingTerms] = useState(get(form.values,`${entityLabel}.share[0].vestingTerms.id`) === 'ADD')

        return (<>

<Field name={`${entityLabel}.share[0].shareClass.name`}>
            {({ field, form }) => (
                <div className={`${classes.ContainerRow}`}>
                    <TextInput
                        field={field}
                        form={form}
                        label={'Share class'}
                        required={true}
                        placeholder={'E.g. Ordinary, Ordinary A or Class A Common'}
                    /></div>
            )}
        </Field>
         <div className={classes.ContainerRow}>
    <Field name={`${entityLabel}.share[0].amountOfShares`}>
        {({ field, form }) => (
            <TextInput
                required={true}
                type="number"
                label="Number of shares "
                field={field}
                form={form}
                placeholder={`e.g. 100.000`}
            />
        )}
    </Field>
    </div>
    
                            <div className={classes.containerFounderName}>
            <h1>Vesting </h1>
            <p>The vesting of the shares granted to the founders.</p>
        </div>
     <Field name={`${entityLabel}.share[0].vestingTerms.id`}>
     {({ field, form }) => {
        return field.value === 'ADD'?null:(
    <SelectVestingTerms
    value={field.value}
    groupCompanyId={groupCompany?.id}
    list={[{id:'NONE',name:'None'}]}
    onChange={(value)=>{
        if(value?.id === 'NONE') {
            setShowVestingTerms(false)
            form.setFieldValue(`${entityLabel}.share[0].vestingTerms`,value);
            return;
        };
     if(value === `ADD`){
         form.setFieldValue(`${entityLabel}.share[0].vestingTerms`,{id:value})
           setShowVestingTerms(true)
         setIsAddVestingTerms(true)
        return;
    }
     form.setFieldValue(`${entityLabel}.share[0].vestingTerms`,{...value?.standardTerm,isThereCliff:!!value?.standardTerm?.isThereCliff,id:value?.id,vestingStartDate:get(form,`values.${entityLabel}.share[0].vestingTerms.vestingStartDate`)})
     setShowVestingTerms(!!value)
    }}
    errorMessage={get(form.errors,`${entityLabel}.share[0].vestingTerms.id`) || get(form.errors,`${entityLabel}.share[0].vestingTerms`)}
    />
     )}}
     </Field>
     { showVestingTerms &&
             <VestingTermsFields entityLabel={entityLabel} isDisabled={!isAddVestingTerms} tooltips={tooltips} />
             }
              <Field name={`${entityLabel}.share[0].vestingTerms.vestingStartDate`}>
     {({ field, form }) => 
    get(form, `values.${entityLabel}.share[0].vestingTerms.id`) !== 'NONE' ?(
         <div className={classes.ContainerRow}>
             <TextInput
                 required={true}
                 type="date"
                 label="Vesting start date  "
                 field={field}
                 form={form}
                 placeholder={`dd/mm/yyyy`}
                 />
         </div>
     ):null}
    </Field>
    </>)
    }
export default COmpnayNotEstablishedShares
import _ from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';
import { useParams } from "react-router-dom";
import useSession from "src/modules/session/hooks/useSession";
import useAddAllDocumentsAndConfirmTask from './graphql/useAddAllDocumentsAndConfirmTask';
import useGetManagedByClaraConnectionsStakeholder from 'src/modules/startup/Stakeholder/graphql/queries/useGetManagedByClaraConnectionsStakeholder';

const useServices = ({ initialValues }) => {
  const { manualQuery } = useGetManagedByClaraConnectionsStakeholder()
  const  uploadDocuments  = useAddAllDocumentsAndConfirmTask();
  const { startupId } = useSession();
  const params = useParams();
  const stakeholderId = initialValues?.idToSave || params?.id;

  return useMemo(() => ({

    initialData: async () => {
      
      const { data } = await manualQuery({variables:{stakeholderId,startupId}})
      const groupCompany = (data?.getStakeholder?.managedByClaraConnections || []).find((item) => item?.statusOnMatters.isRenewal && item?.jurisdictionType === 'ADGM');
      return {...initialValues,groupCompany};
    },

    SaveStep: async (context, event) => {
      const hoIsSelected = _.get(context, "CertificateOfIncumbency.documentIncumbency", false);
      const isRegDirAndMembers = _.get(context, "RegisterDirectorsAndMembers.documentDirectors", false)
      const isRegistryExtract = _.get(context, "RegistryExtract.document", false)
      const documents = [];

      if (hoIsSelected) {
        const file = _.get(context, "CertificateOfIncumbency.documentIncumbency", "");
        documents.push({
          title: file.name,
          file: file,
          type: "ADGM_CERTIFICATE_OF_INCUMBENCY",
          parties: [stakeholderId],
          status: "Uploaded",
          subCategories: ["constitutionalDocuments"],
          executedOnDate: moment(_.get(context, 'CertificateOfIncumbency.dateIncumbency')).format('YYYY-MM-DD'),
        })
      }

      if (isRegDirAndMembers) {
      const fileDocumentDirectors = _.get(context, "RegisterDirectorsAndMembers.documentDirectors", "");
      const fileDocumentMembers = _.get(context, "RegisterDirectorsAndMembers.documentMembers", "");
      documents.push({
        title: fileDocumentDirectors.name,
        file: fileDocumentDirectors,
        type: "REGISTER_OF_DIRECTORS",
        parties: [stakeholderId],
        status: "Uploaded",
        subCategories: ["constitutionalDocuments"],
        executedOnDate: moment(_.get(context, 'RegisterDirectorsAndMembers.dateDirectors')).format('YYYY-MM-DD'),
      });
      documents.push({
        title: fileDocumentMembers.name,
        file: fileDocumentMembers,
        type: "REGISTER_OF_MEMBERS",
        parties: [stakeholderId],
        status: "Uploaded",
        subCategories: ["constitutionalDocuments"],
        executedOnDate: moment(_.get(context, 'RegisterDirectorsAndMembers.dateMembers')).format('YYYY-MM-DD'),
      });
    }

    if (isRegistryExtract) {
      const file = _.get(context, "RegistryExtract.document", "");
      documents.push({
        title: file.name,
        file: file,
        type: "REGISTRY_EXTRACT",
        parties: [stakeholderId],
        status: "Uploaded",
        subCategories: ["constitutionalDocuments"],
        executedOnDate: moment(_.get(context, 'RegistryExtract.issueDate')).format('YYYY-MM-DD'),
      })
    }

    // const stakeholderId = context?.initialData?.idToSave || params?.id;
    const groupCompanyId = context?.initialData?.groupCompany?.id
    
    return await uploadDocuments({ variables: {startupId, documents: documents,stakeholderId,groupCompanyId}})
    },

  }), [initialValues]);
}

export default useServices;

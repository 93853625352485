import React, { FC } from "react";
import { Stack } from "@mui/material";
import { StepProps } from "src/modules/generic/components/Wizard/Step";
import Button from "src/modules/generic/components/Buttons/Button";
import Typography from "src/modules/generic/components/Texts/Typography";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import cls from "./classes.module.scss";
import Lottie from "src/modules/generic/components/Animations/Lottie/Lottie";
import animationData from "src/modules/generic/animations/journey-finish.json";

/**
 - Pre Submit ADGM Form Step
 **/
const PreSubmitADGMFormStep: FC<StepProps> = () => {
  const { next, cancel } = useMachine();

  const handleSubmit = () => {
    next();
  };

  const handleCancel = () => {
    cancel();
  };

  return (
    <TemplateWizard
      props={{
        skeleton: null,
        variant: "twoColumns",
      }}
      buttons={{
        submit: (
          <Button onClick={handleSubmit} variant="primary">
            Yes, I'm sure
          </Button>
        ),
        cancel: (
          <Button onClick={handleCancel} variant="secondary">
            No, let's check
          </Button>
        ),
      }}
    >
      <Stack spacing={4}>
        <Typography className={cls["cartTitle"]} weight="bold">
          Before you take the leap...
        </Typography>
        <Typography variant="xxl" color={"black"} component={"p"}>
          Have you checked that all your added info is correct? Here’s your
          chance to give the details one last overview.
        </Typography>
      </Stack>
      <div className={cls["rightContentImage"]}>
        <Lottie
          height={470}
          width={450}
          options={{
            loop: true,
            autoplay: true,
          }}
          animation={animationData}
        />
      </div>
    </TemplateWizard>
  );
};

export default PreSubmitADGMFormStep;

export default {
  SCALE_ANNUAL_SUBSCRIPTION: "SCALE_ANNUAL_SUBSCRIPTION",
  SCALE_ANNUAL_SUBSCRIPTION_MONTHLY: "SCALE_ANNUAL_SUBSCRIPTION_MONTHLY",
  FORMATION_ADGM_PAYMENT: "FORMATION_ADGM_PAYMENT",
  FORMATION_CAYMAN_PAYMENT: "FORMATION_CAYMAN_PAYMENT",
  FORMATION_DELAWARE_PAYMENT: "FORMATION_DELAWARE_PAYMENT",
  SCALE_FORMATION_ADGM_BUNDLE: "SCALE_FORMATION_ADGM_BUNDLE",
  SCALE_FORMATION_CAYMAN_BUNDLE: "SCALE_FORMATION_CAYMAN_BUNDLE",
  SCALE_FORMATION_DELAWARE_BUNDLE: "SCALE_FORMATION_DELAWARE_BUNDLE",
  FORMATION_ADGM_SPV_PAYMENT:"FORMATION_ADGM_SPV_PAYMENT",
  CAYMAN_SHOPPING_CART:"CAYMAN_SHOPPING_CART",
  COMPANY_RENEWAL:"COMPANY_RENEWAL",
  ADGM_SHOPPING_CART:"ADGM_SHOPPING_CART",
  DELAWARE_SHOPPING_CART:"DELAWARE_SHOPPING_CART",
  FORMATION_ADGM_TSL_SEED_PAYMENT:"FORMATION_ADGM_TSL_SEED_PAYMENT",
  FORMATION_ADGM_TSL_EMERGENT_PAYMENT:"FORMATION_ADGM_TSL_EMERGENT_PAYMENT"
}
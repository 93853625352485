import _ from "lodash";
import { useMemo } from "react";
import { assign } from 'xstate';

const useActions = () => {
  return useMemo(() => ({
    setStep1SelectResult: assign((context, event) => {
      const resultOption = _.get(event, 'payload.values.response', "");
      const region = _.get(event, 'payload.values.region', "");
      return {
        ...context,
        resultChoice: resultOption,
        region
      };
    }),
    setStep2FileResult: assign((context, event) => {
      const resultFile = _.get(event, 'payload.values.file', {});
      const resultName = _.get(event, 'payload.values.name', "");
      return {
        ...context,
        resultFile: resultFile,
        resultName: resultName,
      };
    }),
    setStep3FileResult: assign((context, event) => {
      const allGroupCompanies = _.get(event, 'data.inicialGroupCompanies', []);
      return {
        ...context,
        allGroupCompanies: allGroupCompanies,
      };
    }),
  }), []);
}

export default useActions;
import React, {Component} from "react";
import {connect} from "react-redux";
import {Loading} from "../../index";

import Mustache from 'mustache'
import ServerConnect from '../../../utils/ServerConnect';
import {Constants} from '../../../utils/constants';
import _ from "lodash";
import FormikHelper from "../../../utils/formikHelper";
import EditForm from "./EditForm";

import ViewDetails from "../../Forms/View/GroupCompany/Details"
import EditDetails from "../../Forms/Edit/GroupCompany/Details"
import ViewDocument from "../../Forms/View/Document"
import EditDocument from "../../Forms/Edit/Document"

import ViewEquity from "../../Forms/View/Tabs/Equity/Equity";
import EditEquity from "../../Forms/Edit/GroupCompany/Equity";
import {mutation, query, queryGetGroupCompanies} from '../../../graphql/mutations/addGroupCompany';
import {getGroupCompaniesWrong} from "../../../graphql/mutations/addStakeholder";

import {Config as QueryProfile} from "../../Onboarding/OnboardingContent/config";

class GroupCompanyForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            refresh: false,
            showLoading: false,
            groupCompanies: props.session.groupCompanies,
            wasUpdated: false,
        }
    }

    refreshGroupCompanies(cb, ) {
        const query_params = {
            startup_id: this.props.session.startup.id
        };
        const queryGC = Mustache.render(queryGetGroupCompanies, query_params);

        ServerConnect.graphQlQuery(queryGC, {}).then((resultGC) => {
            // this.props.setGroupCompanies(resultGC.stakeholderMap.groupCompanies)
            if (cb) {
                cb()
            }

        });
    }

    callServerToGetData = () => {
        return new Promise((resolve, reject) => {
            const query_params = {
                startupId: this.props.session.startup.id,
                groupCompanyId: this.props.params.id
            };
            const queryExecute = Mustache.render(query, query_params);
            ServerConnect.graphQlQuery(queryExecute).then(result => {
                let data = {
                    ...result.getGroupCompany
                };
                resolve(data);
            });

        })
    };

    getTitleEditForm = () => {
        return this.state.data ? this.state.data.name : "";
    };

    getGraphQlConfig = () => {

    };

    getGroupCompany = (groupCompany_id) => {
        return this.props.session.groupCompanies.find(function (element) {
            return element.id === groupCompany_id;
        });

    };
    transformInitialData = (data) => {
        let newData = _.clone(data);
        newData.address = data.registeredAddress;
        newData.equity = [];
        newData.shares = []
        newData.shareOptions = [];
        newData.convertibles = [];
        newData.warrants = [];

        _.forEach(data.issuedEquity, eq => {
            switch (eq.equity.type) {

                case "SHARE":
                    newData.shares.push({
                        "id": eq.equity.id,
                        "holder": eq.holder,
                        "shareClass": _.assign(eq.equity.shareClass,{type:"shareClass"} ),//Class
                        "amount": eq.equity.amount,//No. of Shares
                        "price": eq.equity.price ,// Price per Share,
                        "currency": newData.transactionCurrency, // eq.equity.currency
                        "issuedDate": eq.equity.issuedDate,
                        "title": "Share Position",
                        "type": eq.equity.type,
                        "documents": eq.equity.documents,
                        "filterField":eq.holder.name || eq.holder.fullName
                      });
                    break;
                case "OPTION":
                    newData.shareOptions.push({
                        "id": eq.equity.id,
                        "holder": eq.holder,
                        "shareIncentivePlan": _.assign(eq.equity.shareIncentivePlan,{ type: "shareIncentivePlan" }),
                        "amount": eq.equity.amount,//No. of Share Options
                        "price": eq.equity.price ,//Exercise Price
                        "shareClass":_.assign(eq.equity.shareClass,{type:"shareClass"} ),
                        "currency": eq.equity.currency,
                        "title": "Share Option Grant",
                        "type":eq.equity.type,
                        "issuedDate": eq.equity.issuedDate,
                        "documents": eq.equity.documents,
                        "filterField":eq.holder.name || eq.holder.fullName
                    });
                    break;
                default:
                    break;

            }
     
        })

        _.forEach(data.issuedConvertibles, c =>{

            let {convertible ,holder} = c
            newData.convertibles.push({
                ...convertible,
                holder:_.assign(holder, {type: "stakeholder"}),
                "title":"Convertible",
                "type": "CONVERTIBLE",
                "filterField":holder.fullName 
            })
        })
        _.forEach(data.issuedWarrants, w =>{

            let {warrant , holder} = w;
            newData.warrants.push({
                ...warrant,
                holder:_.assign( holder,{type: "stakeholder"}),
                "title2":"Warrant Grant",
                "type":"WARRANT",
                "filterField":holder.fullName
            })
        })

        return newData;
    };

    transformFinishData = (value) => {
        this.setState({
            showLoading: true
        });

        const startupId = this.props.session.startup.id;
        const structuredResponse = FormikHelper.mapGroupCompanyDataForMutation(value);
        const data = {
            startupId,
            groupCompanyId: this.props.data.id,
            ...structuredResponse
        };
        delete data.groupCompany.documents;
        ServerConnect.graphQlMutation(mutation, data).then((result) => {

            ServerConnect.graphQlQuery(QueryProfile.profile).then(result => {
                if (result && result.getMe) {
                    // this.props.setStartUpAndUser(result.getMe.startups[0], result.getMe);
                }
                this.props.changeModeForm(Constants.FORMS_MODE.VIEW);
                this.callServerToGetData().then(dataResult => {
                    const cb = () => {
                        this.setState({
                            showLoading: false,
                            data: this.transformInitialData(dataResult),
                            refresh: !this.state.refresh,
                            wasUpdated: true,
                        });
                    };
                    this.refreshGroupCompanies(cb);
                });
            });
        });
    };


    callServerToGetGroupCompanies = () => {
        return new Promise((resolve, reject) => {
            const queryParams = { startupId: this.props.session.startup.id };
            //const query = Mustache.render(getGroupCompanies, queryParams);
            ServerConnect.graphQlQuery(getGroupCompaniesWrong,queryParams).then(result => {
                if (result && result.getGroupCompanies) {
                    resolve(_.orderBy(result.getGroupCompanies, ['isHoldingCompany'], ['desc']))
                }
            });
        })
    };


    componentDidMount() {
        this.setState({
            showLoading: true
        });
        Promise.all([this.callServerToGetData(), this.callServerToGetGroupCompanies()])
            .then((result) => {
                this.setState({
                    data: this.transformInitialData(result[0]),
                    refresh: !this.state.refresh,
                    showLoading: false,
                    groupCompanies: result[1],
                })
            })

        ServerConnect.requestApi(
            `/contentful/tooltips/${Constants.CONTENTFUL.ENTRIES.FORMS.GROUPCOMPANY.TOOLTIP}`,
            {
                method: 'GET', headers: { "Content-Type": "application/json" },
            }).then(res => {
                console.log('response', res.response);
                this.setState({
                    tooltips: res.response
                })
            }).catch(err => {
                console.log('Tooltips from contentful error: ', err);
            });
    }

    render() {
        let { mode } = this.props;
        let { data, groupCompanies, wasUpdated } = this.state;
        return (
            <Loading show={this.state.showLoading}>
                <EditForm
                    formType={Constants.FORMS.GROUP_COMPANIES}
                    accessDelete={Constants.ACTIONS.EDIT_PROFILE}
                    title={this.getTitleEditForm()} mode={mode} data={data} wasUpdated={wasUpdated}
                >
                    <EditForm.Container tab={{ name: "Details", key: "details" }}>
                        <EditForm.View isView={true}>
                            <ViewDetails data={data} />
                        </EditForm.View>
                        <EditForm.Edit>
                            <EditDetails
                                tooltips={this.state.tooltips}
                                data={data}
                                updateForm={this.transformFinishData}
                            />
                        </EditForm.Edit>
                    </EditForm.Container>
                    <EditForm.Container tab={{ name: "Documents", key: "documents" }}>
                        <EditForm.View isView={true}>
                            <ViewDocument
                                data={data}
                                title={"All Company Documents"}
                                updateForm={this.transformFinishData}
                                accessToAdd={Constants.ACTIONS.EDIT_PROFILE}
                            />
                        </EditForm.View>
                        <EditForm.Edit>
                            <EditDocument
                                data={data}
                                updateForm={this.transformFinishData}
                                documentTypes={Constants.DOCUMENTS_TYPES.GROUPCOMPANY}
                            />
                        </EditForm.Edit>
                    </EditForm.Container>
                    <EditForm.Container tab={{ name: "Equity", key: "equity" }}>
                        <EditForm.View isView={true}>
                            <ViewEquity isGroupCompany={true} data={data} updateForm={this.transformFinishData} goTo={this.props.goTo} />
                        </EditForm.View>
                        <EditForm.Edit>
                            <EditEquity
                                data={data}
                                updateForm={this.transformFinishData}
                                groupCompanies={groupCompanies}
                            />
                        </EditForm.Edit>
                    </EditForm.Container>
                </EditForm>
            </Loading>
        )
    }
}

const mapStateToProps = state => ({
    session: state.session
});
const mapDispatchToProps = (dispatch) => ({
  
 
});
export default connect(mapStateToProps, mapDispatchToProps)(GroupCompanyForm)

import _ from 'lodash';
import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import ModalTour from '../../../../modules/generic/components/Modal/ModalSteps';
import useContenful from "../../../../modules/generic/hooks/useContenful";
import useFetch from "../../../../modules/generic/hooks/useFetch";
import { LeftContentTour, RightContentTour } from '../../../../modules/Onboarding/components';
import { Constants } from "../../../../v1/utils/constants";
import useFinishTour from "../../../../modules/Onboarding/graphql/useFinishTour";
import useSession from "../../../../modules/session/hooks/useSession";


export default function Tour() {
  const {updateUser} = useSession()
  const { response } = useFetch({ url: `/contentful/tour/1q1v3oMqrbtSSRqxXk904P` });

  const { getDataTour } = useContenful();
  const {mutation: introductionFinished } = useFinishTour()

  const pages = useMemo(() => {
    return getDataTour(_.get(response, 'content.page', []));
  }, [JSON.stringify(response)])

  const dispatch = useDispatch();

  const handleFinish = () => {
    introductionFinished();
    dispatch({ type: "GO_TO", page: Constants.PAGES.MODULES.MAP.maps });
  }

  return (
    <>
      <ModalTour handleLastStep={handleFinish} open={true} pages={pages} componentLeftContend={LeftContentTour} componentRightContend={RightContentTour} />
    </>
  );
}


import { useMemo } from 'react';
import { assign } from 'xstate';
import _ from 'lodash';

const useActions = ({ onPreviousMachine }) => {

  return useMemo(() => {

    const setDelawareJurisdiction = assign((context: any = {}, event: any = null) => {
      const filterData = {
        ...context,
        AddDelawareCompany :  {
          country: "US",
          jurisdiction: "Delaware",
        }
      };
      return filterData;
    });

    /** this action is online used to return of chidlren machine to parent machine. If yo need other type of comunication you can add more actions here */
    const previousMachine = assign((context: any = {}, event: any = null) => {
      onPreviousMachine && onPreviousMachine();
      return context;
    });
    
    return {
      setDelawareJurisdiction,
      previousMachine
    }
  }, [onPreviousMachine]);
}

export default useActions;

import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import _ from "lodash";
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import { CheckboxButtonOptions } from "src/modules/generic/components/Inputs/CheckboxButton";
import classModule from "./classes.module.scss";

function SelectSeparateLegal() {
  const { next, context, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    if(!_.isEmpty(context?.SelectSeparateLegal)) {
      return context?.SelectSeparateLegal;
    } else {
      return context?.initialData?.stakeholder;
    }
  }, [context]);

  const handleCancel = () => {
    cancel();
  }

  const handlePrev = () => {
    prev();
  }

  const handleSubmit = async (values) => {
    next(values);
  }

  useEffect(() => {
    dispatch(addAssistantText(translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.LIMITED_PARTNERSHIP_DETAILS.STEP_3.TOOLTIP")));
    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch]);

  const schema = Yup.object().shape({
    isSeparateLegalEntity: Yup.boolean().required()
  });

  const options: Array<CheckboxButtonOptions<boolean>> = [
    {
      value: false,
      label: translate(
        "MODULES.STAKEHOLDER.ENTITY_STRUCTURES.LIMITED_PARTNERSHIP_DETAILS.STEP_3.OPTION_2"
      ),
    },
    {
      value: true,
      label: translate(
        "MODULES.STAKEHOLDER.ENTITY_STRUCTURES.LIMITED_PARTNERSHIP_DETAILS.STEP_3.OPTION_1"
      ),
    },
  ];

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.LIMITED_PARTNERSHIP_DETAILS.STEP_3.TITLE"),
          skeleton: null,
          subTitle: translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.LIMITED_PARTNERSHIP_DETAILS.SUBTITLE"),
          variant: 'simple'
        }}
        buttons={{
          previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>{translate('GENERIC_BUTTON_ADD_GROUP')}</SubmitButton>,
        }}
      >
        <>
          <ControllerInput
            render={CheckboxButtonSimple}
            className={classModule.CheckboxButton}
            classNameButton={classModule.buttonCheckBox}
            name="isSeparateLegalEntity"
            options={options}
          />
        </>
      </TemplateWizard>
    </Form>
  );
}

export default SelectSeparateLegal;


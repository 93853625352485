import { default as React, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import Typography from "src/modules/generic/components/Texts/Typography";
import classes from "./classes.module.scss";

function ADGMSPVAuthorisedSignatory() {
  const { next, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  
  const handleCancel = () => {
    cancel();
  }

  const handlePrev = () => {
    prev();
  }

  const handleSubmit = async (values) => {
    next(values);
  }

  const options = [
    {
      value: 'director_or_shareholder',
      component: ButtonText,
      componentProps: {
        title: "A director or shareholder is a UAE resident or GCC National",
        text: "This individual will be appointed as the company's ADGM Authorised Signatory",
      }
    },
    {
      value: 'third_party',
      component: ButtonText,
      componentProps: {
        title: "A Third Party",
        text: "You will appoint someone who is not a director or shareholder but who is a UAE resident or GCC National as the company's ADGM Authorised Signatory",
      }
    },
    {
      value: 'claras_nominee',
      component: ButtonText,
      componentProps: {
        title: "Clara's Nominee Authorised Signatory",
        text: "Clara can provide this service for USD 700 + VAT annually. To use this service, you will need to appoint a second Authorised Signatory, but this person only needs to have entered the UAE in the past two years.",
      }
    },
  ];
  
  useEffect( ()=>{
    dispatch(addAssistantText('<strong>Why do I need this?</strong><br/>Every ADGM company must have at least one UAE resident or GCC National appointed as the Authorised Signatory. This person will be named on the commercial Licence of the company but they do not have the authority to sign commercial documents or company resolutions without the company issuing them a POA or other specific authority. <br/><br/>For further information about the role of an ADGM Authorised Signatory read more <a rel=\'noopener noreferrer\' target=\'_blank\' href=\'https://help.clara.co/en/articles/6385804-what-is-an-adgm-authorised-signatory\' >here</a>.'));
    dispatch(addAssistantText('<strong>What if I don\'t have one?</strong><br/>If any of your directors or shareholders cannot fulfil this role, then you can only proceed with ADGM if you have a third party who can be appointed or if you use Clara\'s nominee service. <br/> <br/> For more information about our nominee service, click <a rel=\'noopener noreferrer\' target=\'_blank\' href=\'https://help.clara.co/en/articles/6230840-can-i-use-a-nominee-to-be-my-adgm-authorised-signatory\' >here</a>.'));
    return () => {
      dispatch(clearAssistatText());
    }
  },[dispatch]);

  const schema = Yup.object().shape({
    authorisedSignatory: Yup.string().nullable().required("Required")
  });

  return (
    <Form
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: 'ADGM Authorised Signatory',
          skeleton: null,
          subTitle: 'ADGM eligibility',
          variant: 'simple'
        }}
        buttons={{
          previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('BUTTON_NEXT')}</SubmitButton>,
        }}
      >
        <Typography component='h4'>
          To form a company in ADGM, you need to have at least one GCC National or UAE resident appointed as your ADGM Qualifying Authorised Signatory. Select who will be appointed to this role:
        </Typography>
        <div>
        <ControllerInput
            as={CheckboxButtonSimple}
            className={classes.ADGMAuthorisedSignatoryCheckboxContainer}
            classNameButton={classes.ADGMAuthorisedSignatoryCheckboxButton}
            name="authorisedSignatory"
            variant="col_1"
            options={options}
        />
        </div>
      </TemplateWizard>
    </Form>
  );
}

export default ADGMSPVAuthorisedSignatory;

const ButtonText = ({ title, text }) => {
  return (
      <div className={classes.ADGMAuthorisedSignatoryCheckboxButtonText}>
        <Typography weight="semibold" variant="h4" className="mb-2">
          {title}
        </Typography>
        <Typography variant="body">{text}</Typography>
      </div>
  );
};

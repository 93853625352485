import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import * as Yup from 'yup';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText,addAssistantText } from 'src/modules/generic/store/action';
import CheckboxButtonSimple from 'src/modules/generic/components/Inputs/CheckboxButtonSimple';
import Typography from 'src/modules/generic/components/Texts/Typography';
import classes from './classes.module.scss';
import useDownloadBusinessPlanTemplate
  from "../../../../../GroupCompany/BusinessPlan/hooks/useDownloadBusinessPlanTemplate";

function LetsPreApproveFormStep() {
  const { next, context, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  
  const initialValues = useMemo(() => {
    return context?.LetsPreApproveFormStep ?? {}
  }, [context]);

  const { downloadBusinessPlanTemplate } = useDownloadBusinessPlanTemplate();
  
  const handleCancel = () => {
    cancel();
  }

  const handlePrev = () => {
    prev();
  }

  const handleSubmit = async (values) => {
    next(values);
  }
  
  useEffect( ()=>{
    dispatch(addAssistantText('Clara’s business plan template is the preferred template by ADGM and should be followed closely to avoid delays from ADGM requesting further information.'));

    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch]);
  
  const schema = Yup.object().shape({
    uploadNow: Yup.string().nullable().required("Required")
  });

  const options = [
    {
      value: 'now',
      component: ButtonText,
      componentProps: {
        title: 'Upload now',
      },
    },
    {
      value: 'later',
      component: ButtonText,
      componentProps: {
        title: 'Upload later',
      },
    },
  ];

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: 'Let\'s get you pre-approved',
          skeleton: null,
          subTitle: 'Business plan',
          variant: 'simple'
        }}
        buttons={{
          previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>{translate('BUTTON_CONFIRM')}</SubmitButton>,
        }}
      >
        <Typography weight='regular' variant='xbody'>
          The first step would be for us to submit your business plan and pitch deck to ADGM for their pre-approval.
        </Typography>

        <Typography weight='regular' variant='xbody'>
          Download our <strong>business plan template</strong> <span onClick={() => downloadBusinessPlanTemplate()} className={classes.fakeLink}>here</span> and upload it below when completed, together with your <strong>pitch deck</strong>. You can upload it later, just come back to this task when you are ready.
        </Typography>

        <ControllerInput
          render={CheckboxButtonSimple}
          classNameButton={classes.LetsPreApproveCheckboxButton}
          name='uploadNow'
          variant='col_1'
          options={options}
        />
      </TemplateWizard>
    </Form>
  );
}

export default LetsPreApproveFormStep;

const ButtonText = ({ title }) => {
  return (
    <div className={classes.LetsPreApproveCheckboxOption}>
      <Typography variant='body'>
        {title}
      </Typography>
    </div>
  );
};
import { useMemo } from 'react';
import { assign } from 'xstate';

const useActions = () => {

  return useMemo(() => {

    return {
      setInitialContext: assign((context, event: any) => {
        return {
          ...event?.data
        }
      }),
      setContextAfterForm: assign((context: any, event: any) => {
        const immediateParentIndex = context?.immediateParentIndex
        const newImmediateParents = context?.immediateParents?.map((item, index) => {
          if (index === immediateParentIndex) {
            return {
              ...item,
              ...event?.payload?.values
            }
          }
          return item
        }
        )
        return { ...context, immediateParents: newImmediateParents }
      }),
      setContextAfterEvaluation: assign((context: any, event) => {
        const immediateParentIndex = context?.immediateParentIndex
        const isLastImmediateParent = immediateParentIndex === context?.immediateParents?.length - 1
        const newImmediateParentIndex = isLastImmediateParent ? immediateParentIndex : immediateParentIndex + 1
        return { ...context, immediateParentIndex: newImmediateParentIndex }
      })
    }
  }, []);
}

export default useActions;

import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "../../../../../generic/components/Buttons/Button";
import { StepProps } from "../../../../../generic/components/Wizard/Step";
import useCustomMachine from "../../../../../generic/hooks/useCustomMachine";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import {addAssistantText, clearAssistatText} from "../../../../../generic/store/action";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import useSession from "../../../../../session/hooks/useSession";
import Form from "../../components/Forms/FormDataProtectionContact";
import useUpsertDataProtectionContact from "../../../../../../hooks/services/modules/individualStakeholder/useUpsertDataProtectionContact";

const Step1: FC<StepProps> = () => {
  const { translate } = useTranslate();
  const { next, send, state } = useCustomMachine();
  const [setDataProtectionContact] = useUpsertDataProtectionContact();
  const { id: groupCompanyId } = useParams<any>();
  const { startupId } = useSession();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      addAssistantText(
        translate('MODULES.STAKEHOLDER.ADGM.DATA_PROTECTION_CONTACT.FIRST_STEP.TOOLTIP')
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, []);

  const handleCompleteSubmit = async (values) => {
    const variables = {
      startupId,
      groupCompanyId: state?.context?.data?.id ?? groupCompanyId,
      groupCompanyData: {
        formationData: {
          dataProtectionContact: values.dataProtectionContact,
        }
      }
    };
    await setDataProtectionContact({ variables });
    next(values);
  };

  const handleCancel = async () => {
    send("CANCEL");
  };

  return (
    <>
      <Form
        initialValues={{}}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title:translate('MODULES.STAKEHOLDER.ADGM.DATA_PROTECTION_CONTACT.FIRST_STEP.TITLE'),
          subTitle:translate('MODULES.STAKEHOLDER.ADGM.DATA_PROTECTION_CONTACT.FIRST_STEP.SUBTITLE'),
        }}
        groupCompanyId={groupCompanyId}
        buttons={{
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate('MODULES.STAKEHOLDER.ADGM.CANCEL')}
            </Button>
          ),
        }}
      />
    </>
  );
};

export default Step1;
import React, {useEffect, useRef, useState} from 'react'
import {Field, FieldArray} from "formik";
import {ClaraSelect} from "../../index";
import classes from './styles.module.scss'
import _ from "lodash";
import TextInput from "../../../containers/Forms/Inputs/TextInput";
import Classes from "../../../../components/inputs/Select/SelectFounder/styles.module.scss";
import IconSecondaryButtonContent
    from "../../../../components/generic/Buttons/IconSecondaryButtonContent/IconSecondaryButtonContent";
import TextAreaInput from "../../../containers/Forms/Inputs/TextAreaInput";

const ClaraAddInputs=({fields,textInputOther,selectOther,isOtherBy,fieldArrayName,isModal,listSelect,listOther,renderOther,fieldOtherName={},...props})=>{
    return (<>

        <FieldArray
            name={fieldArrayName}
            render={(arrayHelpers) => {
                const handleDeleteElement=(index)=>{
                    arrayHelpers.remove(index)
                }
                return(<div style={isModal?{marginTop:'1.5rem'}:null}>
                    <button onClick={()=>arrayHelpers.push({})} className={'genericBlue'}>Add</button>
                    {_.get(arrayHelpers.form.values, `${fieldArrayName}`,[]).map((item, index) => {
                        return (<>

                            <ClaraInputs
                                fields={[
                                    {fieldName:`${fieldArrayName}[${index}].${fields[0].fieldName}`,label:fields[0].label,isRequired:fields[0].isRequired},
                                    {fieldName:`${fieldArrayName}[${index}].${fields[1].fieldName}`,label:fields[1].label,isRequired:fields[1].isRequired},
                                    {fieldName:`${fieldArrayName}[${index}].${fields[2].fieldName}`,label:fields[2].label,isRequired:fields[2].isRequired}]}
                                index={index}
                                listOther={listOther||[]}
                                textInputOther={textInputOther}
                                selectOther={selectOther}
                                fieldNameOther={{inputName:`${fieldArrayName}[${index}].${fieldOtherName.inputName}`,selectName:`${fieldArrayName}[${index}].${fieldOtherName.selectName}`}}
                                isOtherBy={isOtherBy}
                                renderOther={renderOther}
                                listSelect={listSelect}
                                isThereDelete={true}
                                isModal={isModal}
                                handleDeleteElement={handleDeleteElement}
                            />
                        </>)
                    })}
                </div>)
            }}/>
    </>)
}
export const ClaraInputs=({fieldNameOther,textInputOther,listOther,isOtherBy=()=>{return false},isModal,listSelect,isDisabled,renderOther,index,fields,shareClassesList,className,isThereDelete,...props})=>{
    const [isRenderOther,setIsRenderOther]=useState(false)
    const updateCallback=(id)=>{
        if(id){
            setIsRenderOther(isOtherBy(id))
        }else{
            setIsRenderOther(false)
        }
    }
    return(<>
        <div className={`${className} ${classes.containerEquity} `}>
            {isThereDelete?
                <div className={classes.deleteButton} onClick={() =>{
                   props.handleDeleteElement && props.handleDeleteElement(index)
                }}>
                    <IconSecondaryButtonContent icon={'Delete-bin'}/></div>:<div style={{width:'53.13px'}}></div>
            }
            <div className={isModal?classes.inputModal:classes.inputEquity}>
                <Field name={fields[0].fieldName}>
                    {({ field, form }) => (<>
                        <label>{fields[0].label}{fields[0].isRequired?<span style={{color:'red'}}>{' '}*</span>:null}</label>
                        <TextAreaInput
                            field={field}
                            form={form}
                            disabled={isDisabled}
                            className={classes.textArea}
                        />
                    </>)}
                </Field>
            </div>
            <div className={isModal?classes.inputModal:classes.inputEquity}>
                <Field name={fields[1].fieldName}>
                    {({ field, form }) => (<>
                        <label>{fields[1].label}{fields[1].isRequired?<span style={{color:'red'}}>{' '}*</span>:null}</label>
                        <TextAreaInput
                            field={field}
                            form={form}
                            className={classes.textArea}

                        />
                    </>)}
                </Field>
            </div>
            <div className={classes.inputCode}>
                <Field name={fields[2].fieldName}>
                    {({ field, form }) => (<>
                        <label>{fields[2].label}{fields[2].isRequired?<span style={{color:'red'}}>{' '}*</span>:null}</label>
                        <ClaraSelect
                            field={field}
                            lists={listSelect}
                            form={form}
                            callbacks={{
                                updateValues:updateCallback
                            }}
                            disabled={isDisabled}
                            mode={"classic"}
                        />
                    </>)}
                </Field>
                {isRenderOther?
                    (<div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'20rem'}}>
                        <div className={classes.inputCodeOtherSelect}>
                        <Field name={fieldNameOther.selectName}>
                        {({field,form})=>(
                            <ClaraSelect
                                field={field}
                                lists={listOther || []}
                                form={form}
                                disabled={isDisabled}
                                mode={"classic"}
                            />
                        )
                        }
                    </Field></div>
                        <div className={classes.inputOtherModal}>
                    <Field name={fieldNameOther.inputName}>
                        {({field,form})=>(_.get(form,`values.${fieldNameOther.selectName}`)?
                            <TextInput
                                field={field}
                                form={form}
                                {...textInputOther}
                            />:null
                        )
                        }
                    </Field></div></div>):null
                }
            </div>

        </div>
    </>)
}
export default ClaraAddInputs;
import { default as React, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText } from 'src/modules/generic/store/action';
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import classes from '../../styles/classes.module.scss';

function AddOtherDirector() {
  const { next, context } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const [isContinue, setIsContinue] = useState(false);

  const initialValues = useMemo(() => {
    return context?.initialData ?? {}
  }, [context]);

  const { stakeholderName, formValues } = initialValues;

  const handleSubmit = async (values) => {
    next(values);
  }

  useEffect(() => {
    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch]);

  const options = [
    {
      value: false,
      label: translate(`MODULES.STAKEHOLDER.ENTITY_STRUCTURES.COMPANY_DIRECTORS.OPTION_1`),
    },
    {
      value: true,
      label: translate(`MODULES.STAKEHOLDER.ENTITY_STRUCTURES.COMPANY_DIRECTORS.OPTION_2`),
    },
  ];

  const schema = Yup.object().shape({
    addAnother: Yup.boolean().required(translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.COMPANY_DIRECTORS.FIELD_REQUIRED")).nullable(),
  });

  return (
    <Form
      defaultValues={formValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.COMPANY_DIRECTORS.CONDITION_TITLE", { stakeholderName }),
          skeleton: null,
          subTitle: translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.COMPANY_DIRECTORS.SUBTITLE"),
          variant: 'simple'
        }}
        buttons={{
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>{translate(isContinue ? 'STARTUP_VIEW_SUBSCRIPTION_CANCEL_CONTINUE' : 'GENERIC_BUTTON_CLOSE')}</SubmitButton>,
        }}
      >
        <ControllerInput
          render={CheckboxButtonSimple}
          name="addAnother"
          size="size1"
          options={options}
          classNameButton={classes.heightCheckboxButtonSimple}
          onChange={(value) => setIsContinue(!!value)}
        />
      </TemplateWizard>
    </Form>
  );
}

export default AddOtherDirector;
import _ from 'lodash';
import React from 'react';
import SkeletonRepeat from '../../../generic/components/Loading/SkeletonRepeat';
import GroupCompanyCard from '../Cards/GroupCompanyCard';
import RolCard from '../Cards/RolCard';
import StakeholderCard from '../Cards/StakeholderCard';
import classesModule from './classes.module.scss';

/**
 * Component to draw the cards with html
 * @param param0 
 * @returns 
 */
export function HTMLMap({
  groupCompanies,
  handleClickCard,
  handleExpandCard,
  isGroupCompanyInFormation,
  getStatusCard,
  totals,
  getGroupCompanyShowingStatus,
  statusLogin,
  roles,
  handleDeleteRol,
  getStatusCardRol,
  dataPropsRol,
  stakeholdersOrders,
  getStakeholderShowingStatus,
  widthMap,
  heightMap,
  groupCompaniesRef,
  rolesRef,
  stakeholdersRef,
  dataPropsStakeholder,
  dataPropsGroupCompany,
}) {

  return (
    <div className={classesModule.ContentGraph} style={{
      width: widthMap,
      minHeight: heightMap
    }}>
      <div className={classesModule.ColumnGroupCompany}>
        {_.map(groupCompanies, (groupCompany, id) => {
          const item = (
            <GroupCompanyCard 
              key={id} 
              id={id}
              ref={el => groupCompaniesRef.current[id] = el}
              onClick={value => {
                handleClickCard(value, id, 'groupCompany');
              }} 
              onExpandCard={handleExpandCard} 
              groupCompany={groupCompany} 
              level={groupCompany.level} 
              isFormation={isGroupCompanyInFormation(groupCompany)} 
              isHoldingCompany={groupCompany.isHoldingCompany} 
              status={getStatusCard(dataPropsGroupCompany[id])} 
              lock={groupCompany.isLocked} 
              totals={_.get(totals, `groupCompanies.${id}`, {})} 
              visible={getGroupCompanyShowingStatus(groupCompany)} 
            />
          );
          return item;
        })}
        <SkeletonRepeat 
          show={statusLogin === 'loading'} 
          cant={3} 
          id={'skelGroupCompany'} 
          skeletor={<GroupCompanyCard></GroupCompanyCard>}
        />
      </div>
      <div className={classesModule.ColumnRol}>
        {_.map(roles, rol => {
          return (
            <RolCard 
              key={rol.id} 
              id={rol.id} 
              ref={el => rolesRef.current[rol.id] = el}
              onClick={value => {
                handleClickCard(value, rol.id, 'rol');
              }} 
              onExpandCard={handleExpandCard}
              onCompleteDelete={handleDeleteRol}
              rol={rol}
              show={!(!rol.showEmpty && rol.count === 0)}
              enabledLink={rol.showButton}
              status={getStatusCardRol(dataPropsRol[rol.id], rol.id)} 
              blockLines={_.get(dataPropsRol, `${rol.id}.data-blockLines`)}
              totals={_.get(totals, `roles.${rol.id}`, {})}
            />
          );
        })}
        <SkeletonRepeat 
          show={statusLogin === 'loading'}
          cant={4}
          id={'skelRolCard'}
          skeletor={<RolCard></RolCard>}
        />
      </div>
      <div className={classesModule.ColumnStakeholder}>
        {_.map(stakeholdersOrders, (stakeholder, key) => {
          return (
            <StakeholderCard 
              key={stakeholder.id}
              id={stakeholder.id}
              visible={_.get(dataPropsStakeholder[stakeholder.id], 'data-visible') && getStakeholderShowingStatus(stakeholder)} 
              ref={el => stakeholdersRef.current[stakeholder.id] = el} 
              onClick={value => {
                handleClickCard(value, stakeholder.id, 'stakeholder');
              }} 
              onExpandCard={handleExpandCard}
              stakeholder={stakeholder}
              lock={stakeholder.isLocked}
              status={getStatusCard(dataPropsStakeholder[stakeholder.id])} 
            />
          );
        })}
        <SkeletonRepeat 
          show={statusLogin === 'loading'}
          cant={5}
          id={'skelIndividual'}
          skeletor={<StakeholderCard></StakeholderCard>}
        />
      </div>
    </div>
  )
}

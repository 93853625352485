import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ModalShareProfile from "../../../../../components/modules/clara/startups/shareProfile/modalShareProfile/ModalShareProfile";
import ShareProfileTable from "../../../../../components/modules/clara/startups/shareProfile/shareProfile/ShareProfileTableNew";
import FoundersProfileTable from "../../../../../components/modules/clara/startups/shareProfile/tabs/founders";
import CancelSubscription from "../../../../../components/modules/clara/startups/startups/CancelSubscription/CancelSubscription";
import EditProfile from "../../../../../components/modules/clara/startups/startups/EditProfile/EditProfile";
import EditSubscription from "../../../../../components/modules/clara/startups/startups/EditSubscription/EditSubscription";
import Details from "../../../../../components/templates/Details/Details";
import Text from "../../../../../components/text/Text/Text";
import useShareProfilesLazy from "../../../../../hooks/services/modules/startups/shareManagement/useShareProfilesLazy";
import useUpsertStartup from "../../../../../hooks/services/modules/startups/startups/useUpsertStartup";
import useTranslate from "../../../../../modules/generic/hooks/useTranslate";
import RoleAccess from "../../../../../modules/security/components/RoleAccess/RoleAccess";
import useRoleAccess from "../../../../../modules/security/hooks/useRoleAccess";
import useSession from "../../../../../modules/session/hooks/useSession";
import useGetStartup from "../../../../../modules/startup/graphql/startup/useGetStartup";
import { Constants, ServerConnect } from "../../../../../v1/utils";
import event from "../../../../../v1/utils/event";
import { updateParamsBreadCrumbs } from "./../../../../../store/actions/page";
import DetailsTab from "./components/DetailsTab";
import Button from "../../../../../modules/generic/components/Buttons/Button";
import useModal from "../../../../../modules/generic/hooks/useModal";
import useGetStartupFounders from "src/modules/startup/graphql/startup/useGetStartupFounders";

const StartupView = (props) => {

  const accessManagerSharing = useRoleAccess(Constants.ACTIONS.MANAGE_SHARING);
  const dispatch = useDispatch();
  const { openModal, closeModal } = useModal();
  const [avatar, setAvatar] = useState();
  const [showModal, setShowModal] = useState(false);
  const [initModalVals, setInitModalVals] = useState(null);
  const [userStartupRole, setUserStartupRole] = useState(null);
  const [tableData, setTableData] = useState([]);
  const { startup, updateStartup: updateStartUpSession } = useSession();
  const { data: founders } = useGetStartupFounders({ startupId: startup.id});
  const acceleratorName = _.get(startup, "acceleratorDef.name", "");

  const {
    manualQuery: dataShareManualQuery,
    data: dataShareProfiles,
    loading: loadingShareProfile,
    error: errorShareProfiles,
  } = useShareProfilesLazy(
    { startupId: startup.id },
    { showErrorNotification: false, showSuccessNotification: false }
  );

  const {
    data: startupFull,
    loading: loadingStartup,
    error: errorStartup,
    refetch,
  } = useGetStartup({ startupId: startup.id });

  const [startUpUpdated, setStartUpUpdated] = useState(null);
  const [updateStartup] = useUpsertStartup({});
  const { translate } = useTranslate();

  const callbackUpdateStartup = (newStartup) => {
    setStartUpUpdated({ ...startUpUpdated, ...newStartup });
  };

  const callbackUpdateBanks = (data, dataForm, operation) => {
    refetch();
  };

  const callbackCancelSubscription = (data, dataForm, operation) => {
    //TODO REDUX: Cambiar subscripcion
    // changeStatusSubscription(dispatch, Constants.STATUS_PLANS.INACTIVE);
    refetch();
  };

  useEffect(() => {
    if (accessManagerSharing) {
      dataShareManualQuery();
    }
  }, [accessManagerSharing]);

  useEffect(() => {
    setStartUpUpdated(startupFull);
  }, [JSON.stringify(startupFull)]);

  useEffect(() => {
    updateParamsBreadCrumbs(dispatch, { fullName: _.get(startup, "name") });
  }, [startup.name]);

  useEffect(() => {
    //TODO REDUX: Ver que hace estos invoices
    // updateDataInfo(dispatch, "invoices", _.get(startupFull, "invoices"));
  }, [_.get(startupFull, "invoices")]);

  const handleChangeAvatar = async (value) => {
    
    const response = await ServerConnect.requestApi(
      `/avatars/startups/${startup.id}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          avatarData:value
        })
      },
      null,
      { showNotification: false }
    )
    if (response){
      const newAvatar = response?.url ?? value; 
      await setAvatar(newAvatar);
      updateStartUpSession({ avatar: newAvatar });
    }
    
    
  };

  useEffect(() => {
    if (dataShareProfiles)
      setTableData(_.get(dataShareProfiles, "getStartupUsersProfiles", []));
  }, [JSON.stringify(dataShareProfiles)]);

  useEffect(() => {
    event.on(Constants.EVENTS.SHARE_PROFILE_REFRESH, (params) => {
      setShowModal(true);
    });
    return () => {
      event.remove(Constants.EVENTS.SHARE_PROFILE_REFRESH, () => { });
    };
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
    setInitModalVals(null);
  };

  const updateTableData = (newData) => {
    setTableData(newData);
  };

  const hasFounderInStartup = () => {
    return _.get(founders, "founders", []).length > 0;
  };

  const getTabsContent = () => {
    const tabs = [];
    tabs.push(<DetailsTab data={startupFull} />);
    if (accessManagerSharing) {
      tabs.push(<ShareProfileTable sharingData={tableData} />);
    }
    if (hasFounderInStartup()) {
      tabs.push(<FoundersProfileTable foundersList={_.get(founders, "founders", [])}/>);
    }
    return tabs;
  };

  const getTabs = () => {
    const tabs = [];
    tabs.push(
      {
        show: true,
        disabled: false,
        component: (
          <label>
            <Text uuid={"STARTUP_VIEW_LABEL_DETAILS"} />
          </label>
        ),
        tabName: translate("STARTUP_VIEW_LABEL_DETAILS"),
        key: "details",
      },
    );
    if (accessManagerSharing) {
      tabs.push(
        {
          show: true,
          disabled: false,
          component: (
            <label>
              <Text uuid={"STARTUP_VIEW_LABEL_SHARING"} />
            </label>
          ),
          tabName: translate("STARTUP_VIEW_LABEL_SHARING"),
        },
      );
    }
    if (hasFounderInStartup()) {
      tabs.push(
        {
          show: true,
          disabled: false,
          component: (
            <label>
              <Text uuid={"STARTUP_VIEW_LABEL_FOUNDERS"} />
            </label>
          ),
          tabName: translate("STARTUP_VIEW_LABEL_FOUNDERS"),
        },       
      );
    }
    return tabs;
  };

  return (
    <React.Fragment>
      <EditProfile
        callbackUpdateStartup={callbackUpdateStartup}
        startup={startUpUpdated}
      />
      <CancelSubscription
        cards={_.get(startupFull, "bankCards", [])}
        callbackUpdate={callbackCancelSubscription}
      />
      <EditSubscription
        cards={_.get(startupFull, "bankCards", [])}
        callbackUpdate={callbackUpdateBanks}
      />
      <Details
        lastUpdated={_.get(startup, "updatedAt")}
        collapseButton={true}
        title={_.get(startup, "name")}
        buttonsTabs={{
          details: [
            <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
              <Button
                variant='card'
                children={translate(
                  'STARTUP_VIEW_TAB_BUTTON_EDIT'
                )}
                onClick={() => {
                  openModal(EditProfile, { handleCloseModal: closeModal })
                }}
              ></Button>
            </RoleAccess>,
          ]
        }}
        avatarName={_.get(startup, "name")}
        avatarImg={_.get(startup, "avatar")}
        onChangeAvatar={handleChangeAvatar}
        tabContents={getTabsContent()}
        tabs={getTabs()}
      ></Details>
      <RoleAccess action={Constants.ACTIONS.MANAGE_SHARING}>
        {showModal ? (
          <ModalShareProfile
            tableData={tableData}
            updateTableData={updateTableData}
            initVals={initModalVals ? initModalVals : null}
            userStartupRole={userStartupRole}
            showModal={showModal}
            handleCloseModal={() => {
              handleCloseModal();
            }}
          />
        ) : null}
      </RoleAccess>
    </React.Fragment>
  );
};

export default StartupView;

import React, { FC, useRef, useEffect } from "react";
import Form from "src/components/forms/Form/FormL";
import SubmitButton from "src/components/generic/Buttons/SubmitButton/SubmitButton";
import FormProps from "src/modules/generic/forms/interfaces/FormProps";
import TemplateFormDefault from 'src/modules/generic/templates/Modal';
import useGetFields from "../wizard/hooks/useGetFields";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import TextInput from "src/components/inputs/Text/TextInput";
import CalendarInputDate from "src/components/inputs/Calendars/CalendarInputDate";
import Typography from 'src/modules/generic/components/Texts/Typography';
import { useDispatch } from 'react-redux';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';

const FormDataProtectionDeclarationPart4: FC<FormProps> = ({
  initialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
}) => {

  const refForm = useRef();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const { getFieldsForm, getSchema } = useGetFields(3);
  const { context } = useMachine();

  useEffect(() => {
    dispatch(addAssistantText("<strong>Additional fees</strong> might apply if you need to update some of the details with the ADGM Registrar. Click <a href=\"https://clara.co/terms-of-use/\" target=\"_blank\">here</a> to learn more about fees.</br><div style='margin-top:1rem;'>Click on the field to update to view the corresponding fees. To make a change, just edit the content and proceed. The change request will be added to your cart.</div>",'alert'));
    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch]);

  const labelLines = [
    "Copy of the contract (e.g. Server Agreement with Microsoft Azure)",
  ];

  const labelLines1 = [
    "Latest invoice (e.g. invoice for using Amazon Servers)",
  ];

  return (
    <>
      <Form
        initialValues={initialValues || {}}
        schema={getSchema()}
        onlySendDirty={false}
        onSubmit={onCompleteSubmit}
        ref={refForm}
        optionsForm={{ mode: "onChange" }}
      >
        {" "}
        <Template
          buttons={{
            ...buttons,
            submit: <SubmitButton {...buttonSubmitProps}>Confirm</SubmitButton>,
          }}
          props={propsTemplate}
        >
          <TemplateLabel 
            label="Entity name" 
            isRequired
          >
          <ControllerInput
            name="entityName"
            placeholder={translate("MODULES.CLARA.TYPE_HERE_HIT")}
            render={TextInput}
          />
        </TemplateLabel>
        <TemplateLabel 
          label={"Appointment date"} 
          isRequired={true}
        >
          <ControllerInput
            name="appointmentDate"
            placeholder="dd/mm/yyyy"
            render={CalendarInputDate}
          />
        </TemplateLabel>
        <TemplateLabel
          isRequired={true}
          multilineTexts={labelLines}
          label={''}
        >
          <Typography variant="body">
            Data-Limited-contract.pdf
          </Typography>
        </TemplateLabel>
        <TemplateLabel 
          isRequired={true}
          multilineTexts={labelLines1}
          label={''}
        >
          <Typography variant="body">
            Data-Limited-contract.pdf
          </Typography>
        </TemplateLabel>
        </Template>
      </Form>
    </>
  );
};

export default FormDataProtectionDeclarationPart4;

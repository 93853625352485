import React, {Component} from "react";
import {connect} from "react-redux";
import {Field, Formik} from "formik";
import TextInput from "../../../containers/Forms/Inputs/TextInput.js";
import {Constants} from '../../../utils/constants'
import DocumentInput from "../../../containers/Forms/Inputs/DocumentInput";
import ClaraInputSelect from "../../Form/ClaraInputSelect/ClaraInputSelect";
import classes from './EditForm.module.scss';
import {documentValidations} from '../../../containers/Forms/validations';
import * as Yup from "yup";


const DocumentValidationSchema = Yup.object().shape(documentValidations);

class Document extends Component {
    state = {};
    changeMode = (data) => {
        let i = this.props.form.form.params.index;
        if (data.documents[i]) {
            data.documents.splice(i, 1);
        }
        this.props.changeModeForm(Constants.FORMS_MODE.VIEW, this.props.form.form.params);
    }

    onSubmit = (values, bag) => {

        this.props.updateForm(values);
        this.props.changeModeForm(Constants.FORMS_MODE.VIEW, this.props.form.form.params)
    };

    isDisabled(errors, dirty) {

        if (dirty)
            return Object.keys(errors).length;
        else {
            return dirty
        }
    }

    render() {
        let i = this.props.form.form.params.index;
        let {data, documentTypes} = this.props;

        return (
            <div className={classes.ContainerEdit}>
                <Formik
                    initialValues={{...data}}
                    enableReinitialize={false}
                    validationSchema={DocumentValidationSchema}
                    onSubmit={this.onSubmit}
                    render={({values, handleSubmit, isSubmitting, dirty, errors, touched, handleReset, setFieldValue}) => {
                        return (
                            <div>
                            <form onSubmit={handleSubmit} className={classes.Form}>
                                <h2>Document</h2>
                                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                                    <Field name={`documents[${i}].type`}>
                                        {({field, form}) => (
                                            <ClaraInputSelect
                                                value={field.value}
                                                label={"Document Category: "}
                                                field={field}
                                                form={form}
                                                placeholder={"Select from a list.."}
                                                tooltipText={"The document category"}
                                                updateListFunction={null}
                                                callbackUpdate={null}
                                                decorator={null}
                                                list={documentTypes}
                                                search={true}
                                                modalTitle={null}
                                            />
                                        )}
                                    </Field>
                                </div>
                                <div className={classes.ContainerRow}>
                                    <Field name={`documents[${i}].name`}>
                                        {({field, form}) => (
                                            <TextInput
                                                label='Document Name: '
                                                field={field}
                                                form={form}
                                                placeholder={"Type here..."}
                                            />
                                        )}
                                    </Field>
                                </div>
                                <div className={classes.ContainerRow}>
                                    <Field name={`documents[${i}].file`}>
                                        {({field, form}) => (
                                            <DocumentInput
                                                updateData={this.updateFileData}
                                                name={`filepond`}
                                                label='Upload your document: '
                                                field={field}
                                                form={form}
                                            />
                                        )}
                                    </Field>
                                </div>
                                <div className={classes.Botonera}>
                                    <button type='submit' data-cy="submit" disabled={isSubmitting && this.isDisabled(errors, dirty)}>
                                        Save Changes
                                    </button>
                                    <button
                                        type='button' onClick={() => {
                                        this.changeMode(data)
                                    }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                            </div>
                        )
                    }}
                >

                </Formik>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    session: state.session
});
const mapDispatchToProps = (dispatch) => ({
   
});
export default connect(mapStateToProps, mapDispatchToProps)(Document)

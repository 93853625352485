import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import * as Yup from "yup";
import Button from "src/modules/generic/components/Buttons/Button";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import TemplateFormDefault from "src/modules/generic/templates/Modal";
import {addAssistantText, clearAssistatText} from "src/modules/generic/store/action";
import SubmitButton from "src/components/generic/Buttons/SubmitButton/SubmitButton";
import Form from "src/components/forms/Form/Form";
import AddressInput from "./AddressInput";

const AddressesForm = ({
  initialValues = {},
  handleSubmit,
  handleCloseModal,
  propsTemplate,
  Template = TemplateFormDefault,
  isRenewal = false
}) => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();

  useEffect(() => {
    isRenewal && dispatch(addAssistantText("<strong>Additional fees</strong> might apply if you need to update some of the details with the Cayman registrar.</br><div style='margin-top:1rem;'>To make a change, just edit the content and proceed. Once a change is made, a request will be sent to Clara and a service agent will get back to you with the related invoice to pay and next steps to legally file the changes for your company.</div>",'alert'));
    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch]);

  //TODO no se por que anda como el tuje
  const schema = Yup.object().shape({
    registeredAddress: Yup.object().shape({
      country: Yup.string().required('This field is required').nullable(),
      street: Yup.string().required('This field is required').nullable(),
      city: Yup.string().required('This field is required').nullable(),
      state: Yup.string().nullable(),
      zipCode: Yup.string().nullable(),
    })
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onSubmit={handleSubmit}
    >
      <Template
        buttons={
          {
            submit: (
              <SubmitButton>
                {translate("MODULES.CLARA.BTN_SAVE_CHANGES")}
              </SubmitButton>
            ),
            cancel: (
              <Button onClick={() => handleCloseModal()} variant="secondary">
                {translate("MODULES.CLARA.BTN_CANCEL")}
              </Button>
            ),
          }
        }
        props={propsTemplate}
      >
        <AddressInput
          name="registeredAddress"
          label={{ country: translate('MODULES.CLARA.ADDRESS_REGISTERED_LABEL') }}
          zipCodeOptional={true}
          isRequired
        />
      </Template>
    </Form>
  );
};

export default AddressesForm;
import { createMachine } from 'xstate';

const machine =
  createMachine({
    id: 'ThirdParty',
    initial: 'initialData',
    states: {
      initialData: {
        invoke: {
          src: 'initialData',
          id: 'initialData',
          onDone: [
            {
              target: 'WhoIsGoingToBeYourAuthorisedSignatoryFormStep',
              actions: ['setContextAfterInvoke']
            }
          ],
        }
      },
      WhoIsGoingToBeYourAuthorisedSignatoryFormStep: {
        on: {
          NEXT: {
            target: 'EvaluateCond',
            actions: ['setContextAfterStep']
          },
          CANCEL:{
            target: 'cancel'
          }
        }
      },
      EvaluateCond: {
        always: [
          {
            cond: 'isThirdParty',
            target: 'AddThirdPartyFormStep'
          },
          {
            cond:'isNominee',
            target:'SelectSecondAuthorisedSignatoryFormStep'
          },
          {
            target: 'SaveOnWhoIsGoingToBeYourAuthorisedSignatoryFormStep'
          },
        ]
      },
      SelectSecondAuthorisedSignatoryFormStep: {
        on: {
          PREV: {
            target: 'WhoIsGoingToBeYourAuthorisedSignatoryFormStep',
            actions: ['setContextAfterStep']
          },
          NEXT: {
            target: 'EvaluateCondSecondary',
            actions: ['setContextAfterStep']
          },
          CANCEL:{
            target: 'cancel'
          }
        }
      },
      EvaluateCondSecondary: {
        always:[{
          cond: 'isThirdParty',
          target: 'AddThirdPartyFormStep'
        },
        {
          target: 'EntryTypeFormStep'
        }]
      },
      EntryTypeFormStep: {
        on: {
          PREV: {
            target: 'SelectSecondAuthorisedSignatoryFormStep',
            actions: ['setContextAfterStep']
          },
          NEXT: {
            target: 'SaveAddClaraNominee',
            actions: ['setContextAfterStep']
          },
          CANCEL:{
            target: 'cancel'
          }
        }
      },
      EvaluateBeforeCond:{
        always:[{
          cond: 'isThirdPartySecondary',
          target: 'SelectSecondAuthorisedSignatoryFormStep'
        },
        {
          cond: 'isThirdPartyFirst',
          target: 'WhoIsGoingToBeYourAuthorisedSignatoryFormStep'
        }]
      },
      AddThirdPartyFormStep: {
        on: {
          PREV: {
            target: 'EvaluateBeforeCond',
            actions: ['setContextAfterStep']
          },
          NEXT: {
            target: 'SaveOnAddThirdPartyFormStep',
            actions: ['setContextAfterStep']
          },
          CANCEL:{
            target: 'cancel'
          }
        }
      },
      SaveOnWhoIsGoingToBeYourAuthorisedSignatoryFormStep: {
        invoke: {
          src: 'SaveOnWhoIsGoingToBeYourAuthorisedSignatoryFormStep',
          id: 'SaveOnWhoIsGoingToBeYourAuthorisedSignatoryFormStep',
          onDone: [
            {
              target: 'finish',
            }
          ],
        }
      },
      SaveAddClaraNominee: {
        invoke: {
          src: 'SaveAddClaraNominee',
          id: 'SaveAddClaraNominee',
          onDone: [
            {
              target: 'finish',
            }
          ],
        }
      },
      cancel: {
        entry: 'cancelMachine',
        type: 'final'
      },
      SaveOnAddThirdPartyFormStep: {
        invoke: {
          src: 'SaveOnAddThirdPartyFormStep',
          id: 'SaveOnAddThirdPartyFormStep',
          onDone: [
            {
              target: 'finish',
            }
          ],
        }
      },
      finish: {
        entry: 'stopMachine',
        type: 'final'
      }
    }
  });
 
export default machine;

import { Field } from "formik";
import _ from "lodash";
import React from "react";
import RowAdd from "../../../forms/contents/RowAdd/RowAdd";
import TextInput from "../../Text/TextInput";
import TextAreaInput from "../../Text/TextInput";
import Classes from "../AuthorizedSignatories.module.scss";
import { TooltipInput } from "src/v1/components";
import CountrySelector from '../../Select/CountrySelector/CountrySelector';
const AuthorisedSignatory = (
	{
		fieldArrayName,
        tooltips,
		index,
		handleAddElement,
		handleDeleteElement,
		showAddAnother,
		modal,
		showSelect,
		showEmailInput = true,
		showTitle,
        showRegisteredAddress,
	}) => {
    return (<div className={Classes.containerForm}>
        <Field
            name={`${fieldArrayName}[${index}].fullName`}>
            {({ field, form }) => (

                <RowAdd
                    label={"Name"}
                    asterisk={true}
                    classNameInput={modal ? Classes.inputModal : Classes.input}
                    input={
                        <TextInput
                            name={`fullName`}
                            disabled={true}
                            value={field.value}
                            classNameError={Classes.errorForm}
                            placeholder={"Enter corporate signatory name"}
                        />
                    }
                />
            )}
        </Field>
		{showEmailInput &&
		<Field
			name={`${fieldArrayName}[${index}].email`}>
			{({ field, form }) => (

				<RowAdd
					label={"Email address"}
					asterisk={true}
					classNameInput={modal ? Classes.inputModal : Classes.input}
					input={
						<TextInput
							name={`email`}
							type={`email`}
							value={field.value}
							onChange={(value) => form.setFieldValue(field.name, value)}
							classNameError={Classes.errorForm}
							errorMessage={_.get(form.errors, `${fieldArrayName}[${index}].email`)}
							placeholder={"Email address"}
						/>
					}
				/>
			)}
		</Field>

		}
        {showTitle && 
                    <TooltipInput label={tooltips?.title}>
            <Field
                name={`${fieldArrayName}[${index}].title`}>
                {({ field, form }) => (
                    <RowAdd
                    label={"Title"}
                    asterisk={true}
                    classNameInput={modal ? Classes.inputModal : Classes.input}
                    input={
                        <TextInput
                        name={`title`}
                        value={field.value}
                        onChange={(value) => form.setFieldValue(field.name, value)}
                        classNameError={Classes.errorForm}
                        errorMessage={_.get(form.errors, `${fieldArrayName}[${index}].title`)}
                        placeholder={"Title"}
                        />
                    }
                    />
                    )}
            </Field>
                    </TooltipInput>
    
            }
            {showRegisteredAddress && <>
            <Field name={`${fieldArrayName}[${index}].address.country`}>
                {({field, form}) =>{
                    return (
                        <RowAdd
                    label={"Address"}
                    asterisk={true}
                    classNameInput={modal ? Classes.inputModal : Classes.input}
                    input={
                        <CountrySelector
                        name='address.country'
                        placeholder='Country'
                        value={field.value?.code || field.value}
                        onChange={(value) => form.setFieldValue(field.name, value)}
                        />
                    }/>
                    )
                }}
            </Field>
            <Field name={`${fieldArrayName}[${index}].address.street`}>
                {({ field, form }) => (
                    <RowAdd
                    label={""}
                    classNameInput={modal ? Classes.inputModal : Classes.input}
                    input={
                    <TextAreaInput
                    name={`street`}
                    value={field.value}
                    onChange={(value) => form.setFieldValue(field.name, value)}
                    classNameError={Classes.errorForm}
                    errorMessage={_.get(form.errors, `${fieldArrayName}[${index}].address.street`)}
                    placeholder={"Address"}
                    />
                    }/>
                )}
            </Field>
            <Field name={`${fieldArrayName}[${index}].address.city`}>
                {({ field, form }) => (
                    <RowAdd
                    label={""}
                    classNameInput={modal ? Classes.inputModal : Classes.input}
                    input={
                        <TextInput
                        name={`city`}
                        value={field.value}
                        onChange={(value) => form.setFieldValue(field.name, value)}
                        classNameError={Classes.errorForm}
                        errorMessage={_.get(form.errors, `${fieldArrayName}[${index}].address.city`)}
                        placeholder={"City"}
                    />
                    }/>
                )}
            </Field>
            <Field name={`${fieldArrayName}[${index}].address.state`}>
                {({ field, form }) => (
                    <RowAdd
                    label={""}
                    classNameInput={modal ? Classes.inputModal : Classes.input}
                    input={
                    <TextInput
                        name={`state`}
                        value={field.value}
                        onChange={(value) => form.setFieldValue(field.name, value)}
                        classNameError={Classes.errorForm}
                        errorMessage={_.get(form.errors, `${fieldArrayName}[${index}].address.state`)}
                        placeholder={"State / Region"}
                />
                    }/>
                )}
            </Field>
            <Field name={`${fieldArrayName}[${index}].address.zipCode`}>
                {({ field, form }) => (
                    <RowAdd
                    label={""}
                    classNameInput={modal ? Classes.inputModal : Classes.input}
                    input={
                    <TextInput
                        name={`zipCode`}
                        value={field.value}
                        onChange={(value) => form.setFieldValue(field.name, value)}
                        classNameError={Classes.errorForm}
                        errorMessage={_.get(form.errors, `${fieldArrayName}[${index}].address.zipCode`)}
                        placeholder={"Post code"}
                />
                    }/>
                )}
            </Field>
            </>}
        <div className={Classes.containerButtons}>
            <button
                className={`genericWhite`} style={{ marginRight: `1rem` }}
                onClick={handleDeleteElement}>Remove
            </button>
            {handleAddElement && showAddAnother && !showSelect &&
                <button className={`genericBlue`}
                    onClick={handleAddElement}>Add
                Another</button>}
        </div>
    </div>)
};
export default AuthorisedSignatory
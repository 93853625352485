import React, {FC} from 'react';
import MachineConsultant from "../MachineConsultant";
import Wizard from "../../../../../generic/components/Wizard";
import ModalAssistant, {ModalAssistantProps} from '../../../../../generic/components/Modal/ModalAssistant';
import useModal from "../../../../../generic/hooks/useModal";

const ModalConsultant: FC<ModalAssistantProps> = ({open = false}) => {
    const {closeModal} = useModal();

    const handleClose = () => {
        closeModal();
    }

    return (
        <ModalAssistant open={open} onClose={handleClose}> 
            <Wizard machine={MachineConsultant} onComplete={handleClose}/>
        </ModalAssistant>
    );
}

export default ModalConsultant;

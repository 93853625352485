import _ from "lodash";
import React,{ FC } from 'react';
import { useSelector } from 'react-redux';
import useSessionDataManager from './../../../modules/session/hooks/useSessionDataManager';
import SessionDataManagerWithToken from "./SessionDataManagerWithToken"
const SessionDataManager = () => {
  const { startupId } = useSessionDataManager();
  

  const session = useSelector((state) => _.get(state, "session"));
  
  return (
    <span>
      {_.get(session, "token") ? <SessionDataManagerWithToken /> : null}
    </span>
  )
}

export default SessionDataManager

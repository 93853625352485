

export const actions = {
    GO_TO: "GO_TO",
    GO_TO_PREV: "GO_TO_PREV",
    SET_PAGE: "SET_PAGE",
    NOT_PREV: "NOT_PREV",
    CLEAR: "CLEAR",
    ADD_BREADCRUMBS: "ADD_BREADCRUMBS",
    START_BREADCRUMBS: "START_BREADCRUMBS",
    UPDATE_PARAMS_BREADCRUMBS: "UPDATE_PARAMS_BREADCRUMBS",
    UPDATE_LABEL_BREADCRUMBS: "UPDATE_LABEL_BREADCRUMBS"
};
export const clearPages = (dispatch, path, params) => {
    dispatch({
        type: actions.CLEAR,

    });
};
export const setPage = (dispatch, path, params) => {
    dispatch({
        type: actions.SET_PAGE,
        path: path,
        params: params
    });
};
export const goToPage = (dispatch, page, params) => {
    dispatch({
        type: actions.GO_TO,
        page: page,
        params: params
    });
};
export const goToNextPage = (page, params) => ({
    type: actions.GO_TO,
    page: page,
    params: params
});
export const goToPrevPage = (dispatch, reload, defaultPage, params) => {
    dispatch({
        type: actions.GO_TO_PREV,
        defaultPage: defaultPage,
        reload: reload,
        params: params
    });
};
export const setNotPrev = (dispatch) => {
    dispatch({
        type: actions.NOT_PREV
    });
};
export const addBreadCrumbs = (dispatch, label, path, params, paramsLabel) => {
    dispatch({
        type: actions.ADD_BREADCRUMBS,
        label,
        path,
        params,
        paramsLabel
    });
};
export const startBreadCrumbs = (dispatch, label, path, params, paramsLabel) => {
    dispatch({
        type: actions.START_BREADCRUMBS,
        label,
        path,
        params,
        paramsLabel
    });
};
export const updateParamsBreadCrumbs = (dispatch, paramsLabel) => {
    dispatch({
        type: actions.UPDATE_PARAMS_BREADCRUMBS,
        paramsLabel,
    });
};
export const updateLabelBreadCrumbs = (dispatch, label, paramsLabel, path, params) => {
    dispatch({
        type: actions.UPDATE_LABEL_BREADCRUMBS,
        label,
        paramsLabel,
        path,
        params
    });
};



import _ from "lodash";
import React from "react";
import useBusinessRules from "../../../clara/hooks/useBusinessRules";

const BusinessRuleAccess = ({ children, type = "and", conditions = [] }) => {

  const businesRules = useBusinessRules()

  const hasBusinessRuleAccess = () => {
    let access = type === "and";
    _.forEach(conditions, (rule) => {
      if (businesRules[rule]) {
        if (type === "and" && access) {
          access = access && businesRules[rule]();
        } else {
          access = access || businesRules[rule]();
        }
      } else {
        access = false;
        console.log(`------> Rule ${rule} no exist`)
      }
    });
    return access;
  };

  return (
    <React.Fragment>
      {hasBusinessRuleAccess() && children}
    </React.Fragment>
  )
};

export default BusinessRuleAccess;

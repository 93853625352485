import _ from "lodash";

const useGuards = () => {
  return {
    renewed: (context) => {
      return _.get(context, "HasBeenRenewedStep.renewed");
    },
    notRenewed: (context) => {
      return _.get(context, "HasBeenRenewedStep.renewed") === false;
    },
    askForPassportRenewed: (context) => {
      if (context?.initialData?.additionalParams[0].isSecundaryNationality ?? false) {
        return true;
      } else {
        let result;
        const stDataNacionality = context?.initialData?.stakeholderData?.nationality?.code;
        const taskNacionality = context?.initialData?.additionalParams[0].nationalityInProgress ?? null;
        if (taskNacionality === null) {
          result = false;
        } else {
          result = (stDataNacionality !== taskNacionality);
        }
        return result;
      }
    },
    askForUploadPassport: (context) => {
      let result;
      const stDataNacionality = context?.initialData?.stakeholderData?.nationality?.code;
      const taskNacionality = context?.initialData?.additionalParams[0].nationalityInProgress ?? null;
      if (taskNacionality === null) {
        result = true;
      } else {
        result = (stDataNacionality === taskNacionality);
      }
      return result;
    },
  };
};

export default useGuards;

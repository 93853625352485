import _ from "lodash";
import React, {Component} from 'react';
import {Field, FieldArray} from "formik";
import moment from "moment";

import classes from "../Styles.module.scss";
import ClaraSelect from "../../../../../../../v1/components/Form/ClaraSelect/ClaraSelect";
import Checkbox from '../../../../../../../v1/containers/Forms/Inputs/Checkbox';
import TextInput from "../../../../../../../v1/containers/Forms/Inputs/TextInput";
import FormikHelper from '../../../../../../../v1/utils/formikHelper';
import {ClaraRadioWithButton, ClaraUpload, TextAreaInput} from "../../../../../../../v1/components";
import ClaraCountrySelector from '../../../../../../../v1/components/Form/ClaraCountrySelector/ClaraCountrySelector'
import AuthorisedSignatories from "../../../../../../inputs/AuthorizedSignatories/AuthorizedSignatories";
import VerifyDetails from '../../../../../../../components/inputs/VerifyDetails/VerifyDetails'
import SelectFounder from '../../../../../../inputs/Select/SelectFounder/SelectFounder'
import SelectCompany from '../../../../../../inputs/Select/SelectCompany/SelectCompany';
import Line from "../../../../../../generic/Line/Line";
import ClaraAddVestingEquities
	from '../../../../../../../v1/components/Form/ClaraAddVestingEquities/ClaraAddVestingEquities';
import {
	groupCompanyRecipientNotice, stakeholderAuthorizedSignatory
} from '../../../../../../../v1/containers/Forms/generateDocumentForm/FormsGenerate/helper';

class FoundersAgreement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            typeSelected: undefined,
            initialValuesStated: false,
            groupCompanySelected: undefined,
            authorizedSelected: undefined,
            authorizedSignatories: [],
            authorizedSignatoriesList: [],
            documentTypes: [],
            documentTypesList: [],
            dynamicParams: [],
            date: undefined,
            firstPage: true
        }
    };

    firstPage = (e)=> {

        this.setState({
            firstPage:true
        })
    }

    secondPage = (e)=> {
        this.setState({
            firstPage:false
        })
    }
	mapShareToForm(sh){
        return {
            ...sh,
            amountOfShares:sh.quantity,
            pricePerShare:sh.sharePrice.amount,
            areTheSharesNumbered:!!sh.shareNumbersIssued,
            numberedShares:sh.shareNumbersIssued?{
            startingShareNumber:sh.shareNumbersIssued?.startingShareNumber,
        endingShareNumber:sh.shareNumbersIssued?.endingShareNumber
            }:null,
            vestingTerms:sh.vestingSchedule?{
                ...sh.vestingSchedule?.vestingTerm?.standardTerm,
                isThereCliff:!!sh.vestingSchedule?.vestingTerm?.isThereCliff,
                id:sh.vestingSchedule?.vestingTerm?.id || 'NONE',
                vestingStartDate:sh.vestingSchedule.vestingStartDate
            }:null
        }
    }

    componentDidMount() {
        if (this.props.initialValues.companyNotEstablished) {
            this.props.setFieldValue('company', {...this.props.initialValues.companyNotEstablished, id: 'groupCompanyNotSelected'})
            this.setState({
                groupCompanySelected: this.props.initialValues.company ? this.props.initialValues.company : {}
            })
        } else {
            this.props.setFieldValue('company', {...this.props.initialValues.company, verified1: true,verified2: true})
        }
        _.forEach(this.props.initialValues.founders,(founder, index) => {

			const { position: founderPosition } = founder;

			const position = this.getFounderPositionMapped(founder, founderPosition);
			const groupCompany = this.findGroupCompany(this.props.initialValues.company?.id);
			const shares = _.flatMap(groupCompany?.advanceCapTable?.shareClasses,sh=>sh.shareIssuances)
			const sharesFiltered = (shares || []).filter(si=>si.holder.id===founder.id && si.status.state !== 'CANCELLED' && si.status.state !== 'TRANSFERRED')
			this.props.setFieldValue(`founders[${index}]`, {
                ...founder,
				position: position,
                verified1: true,
                verified2: true,
				startupPosition: _.get(_.find(this.props.stakeholderList,{id: founder.id}),'startupPosition',[])
            })
        })
        if (this.props.initialValues.terms.transferShares.transferSharesGroupCompanies) {
            _.forEach(this.props.initialValues.terms.transferShares.companies,(company,index) => {
                this.props.setFieldValue(`terms.transferShares.companies[${index}]`, {
                    ...company,
                    verified:true
                })
            })
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
            if(!_.isEmpty(nextProps.stakeholderList) && !this.state.initialValuesStated) {
                _.forEach(this.props.initialValues.founders,(founder, index) => {
                    this.props.setFieldValue(`founders[${index}]`, {
                        ...founder,
                        verified1: true,
                        verified2: true,
                        startupPosition: _.get(_.find(nextProps.stakeholderList,{id: founder.id}),'startupPosition',[])
					})
                })
                if(this.props.initialValues.terms.transferShares.transferSharesGroupCompanies) {
                    _.forEach(this.props.initialValues.terms.transferShares.companies,(company, index) => {
                        this.props.setFieldValue(`terms.transferShares.companies[${index}]`,{
                            ...company,
                            verified: true
                        })
                    })
                }
                this.setState({
                    initialValuesStated: true
                })
            }
    }

    selectType = (documentType, extraParams, form) => {
        this.setState({
            typeSelected: documentType,
            dynamicParams: this.getDynamicParamsByType(documentType)
        }, () => {
            FormikHelper.setValueInTheCorrectPosition('terms', form, []);
            FormikHelper.setValueInTheCorrectPosition('documentType', form, documentType);
            this.state.dynamicParams.forEach((dynamicParam, i) => {
                FormikHelper.setValueInTheCorrectPosition(
                    `terms[${i}].key`,
                    form,
                    dynamicParam.key || 'NO_KEY'
                );
            })
        });
    };

    fieldHasError(form,field) {
        return FormikHelper.returnErrorsForValidation(field.name, form.errors);
    }

    getDynamicParamsByType(documentType) {
        const type = this.props.documentTypes.filter(agrType => agrType.code === documentType)[0] || {};
        return type.fields || [];
    }

    getGroupCompanies = () => {
        let authorizedSignatories = this.props.authorizedSignatories;
        let groupCompanies = this.props.groupCompanies;
        let companies = [];
        if (groupCompanies && authorizedSignatories) {
            companies = _.filter(groupCompanies, (groupCompany) => {
                let fined = _.filter(authorizedSignatories, (authorized) => {
                    if (groupCompany.authorizedSignatories) {
                        return groupCompany.authorizedSignatories.some(au => au.stakeholder.id === authorized.id);
                    }
                    return false;
                });
                if (fined) {
                    return true;
                }
                return false;
            });
        }
        return companies;
    };

    selectGroupCompany = (gcId, extraParams, form) => {
        const groupCompany = this.findGroupCompany(gcId);
        if (groupCompany) {
            // this is for a weird behaviour when hit edit button after preview a document
            if (this.state.groupCompanySelected && groupCompany) {
                return null;
            }



            if (!groupCompany.registeredAddress) {
                groupCompany.registeredAddress = {}
            }
            let authorizedSignatories = _.get(form, 'values.company.authorizedSignatories', []);

            const newFormValues = {
                ...form.values,
                company: {
                    id: gcId,
                    name: groupCompany.name || '',
                    country: _.get(groupCompany, 'country.code', null) || _.get(groupCompany, 'country', null),
                    jurisdiction: _.get(groupCompany, 'jurisdiction'),
                    registeredNumber: groupCompany.registeredNumber || '',
                    type: groupCompany.type || '',
                    isHoldingCompany:_.get(groupCompany,'isHoldingCompany',false),
                    registeredAddress: {
                        ...groupCompany.registeredAddress,
                        country: _.get(groupCompany, 'registeredAddress.country.code', null) || _.get(groupCompany, 'registeredAddress.country', null)
                    },
                    verified1: true,
                    verified2: true,
                    authorizedSignatories: authorizedSignatories,
					recipientNotice: groupCompanyRecipientNotice(form.values.company, groupCompany)
                },
                terms:{
                    ...form.values.terms,
                    business:{
                        description:_.get(form.values,'terms.business.description') || _.get(groupCompany,'description','')
                    }
                }
            };
            this.setState({
                groupCompanySelected: groupCompany,
                // authorizedSignatoriesList: authorizedSignatories.map(as => ({
                // 	id: as.id,
                // 	label: as.label
                // }))
            })

            form.setValues(newFormValues);

        }else if(gcId==='groupCompanyNotSelected'){
            // form.setValues({
            //    ...form.values,
            //   company: {}
            //});
            this.setState({
                groupCompanySelected:{}
            })
        } else {
            form.setValues({
                ...form.values,
                company: {},
                founders:[]
            });
            this.setState({
                groupCompanySelected:null
            })
        }
    };

    findGroupCompany(gcId) {
        return this.props.groupCompaniesList.find(gc => gc.id === gcId);
    };

    selectAddCompany = (company,arrayHelpers) => {
        if (company) {
            if (!company.registeredAddress) {
                company.registeredAddress = {}
            }
            arrayHelpers.push({
                id: company.id,
                verified:true,
                name: company.name || '',
				managedByClara:company.managedByClara,
                country: _.get(company, 'country.code', null) || _.get(company, 'country', null),
                jurisdiction: _.get(company, 'jurisdiction'),
                registeredNumber: company.registeredNumber || '',
                type: company.type || '',
                isHoldingCompany: _.get(company, 'isHoldingCompany', false),
                registeredAddress: {
                    ...company.registeredAddress,
                    country: _.get(company, 'registeredAddress.country.code', null) || _.get(company, 'registeredAddress.country', null)
                },
				showTopLevelOption: false
            })
        } else {
            arrayHelpers.push({});
        }
    };

    selectFounder = async (founder, arrayHelpers, groupCompany) => {
        if (founder) {
            let authorizedSignatory = {};

            if (!founder.address) {
                founder.address = {}
            }

            if (founder.isAnEntity) {
				authorizedSignatory = stakeholderAuthorizedSignatory(founder);
                // authorizedSignatory = {
                //     fullName: typeof founder.authorizedSignatory === 'object'
                //         ? _.get(founder, 'authorizedSignatory.fullName', '')
                //         : _.get(founder, 'authorizedSignatory', ''),
                //     email: founder.email || ''
                // }
            }

			const { startupPosition } = founder;

			const position = this.getFounderPositionMapped(founder, _.get(startupPosition, 'position'));

			// const position = startupPosition && startupPosition.position ? {
			// 	...startupPosition.position,
			// 	..._.get(founder,'position',{}),
			// 	responsibilities: startupPosition.position.isDefault
			// 		? _.get(startupPosition,'position.responsibilities', [])
			// 		: _.get(startupPosition,'position.responsibilities[0]',null),
			// 	otherResponsibilities: startupPosition.position.isDefault
			// 		? null
			// 		: _.get(startupPosition,'position.responsibilities[0].text',''),
			// 	otherResponsibilitiesCode: startupPosition.position.isDefault
			// 		? null
			// 		: _.get(startupPosition,'position.responsibilities[0].code',null),
			// 	otherResponsibility: startupPosition.position.isDefault
			// 		? _.get(_.find(startupPosition.position.responsibilities,responsibility => !responsibility.isDefault), 'text', null)
			// 		: null
			// }:{};

            const equities=groupCompany.shareClass
				? _.filter(founder.equity,equity => equity.type === 'SHARE' &&
					_.some(groupCompany.shareClass,sh => sh.id === _.get(equity,'shareClass.id')))
				: [{shareClass: {name: 'Ordinary', id: 'defaultShareClass'}, amount: null}];

            arrayHelpers.push({
                id: founder.id,
                fullName: founder.fullName || '',
                email: founder.email || '',
                passportNumber: founder.passportNumber || '',
                phoneNumber:_.get(founder,'phoneNumber', null),
                registeredNumber: founder.registeredNumber || '',
                nationality: _.get(founder, 'nationality.code', null) || _.get(founder, 'nationality', null),
                jurisdiction: _.get(founder, 'jurisdiction'),
                otherJurisdiction: _.get(founder, 'otherJurisdiction', ''),
                type: founder.type || '',
                equity:_.isEmpty(equities)?[{amount:null}]:equities,
                address: {
                    ...founder.address,
                    country: _.get(founder, 'address.country.code', null) || _.get(founder, 'address.country', null)
                },
                isAnEntity: founder.isAnEntity,
                authorizedSignatory: authorizedSignatory,
                position: position,
                startupPosition:_.get(founder,'startupPosition',[])
            })
        } else {
            arrayHelpers.push({});
        }
    };

    handleAuthorizedSignatoryUpdate = (value) => {


    };

	handleStakeholderCreate = (value) => {
		let { stakeholders, stakeholderList } = this.props;
		stakeholderList.push({ id: value.id, fullName: value.fullName, ...value });

		stakeholders.push({ id: value.id, label: value.fullName });
	};

	handleStakeholderUpdate = (value) => {
		let { stakeholders, stakeholderList } = this.props;

		const index = stakeholders.findIndex(sh => sh.id === value.id);

		if (index !== -1) {
			const stakeholder = stakeholderList[index];

			stakeholder.fullName = value.fullName;
			stakeholder.nationality = value.nationality;
			stakeholder.passportNumber = value.passportNumber;
			stakeholder.email = value.email;
			stakeholder.phoneNumber = value.phoneNumber;
			_.set(stakeholder, 'address.country', _.get(value, 'address.country', ''));
			_.set(stakeholder, 'address.state', _.get(value, 'address.state', ''));
			_.set(stakeholder, 'address.city', _.get(value, 'address.city', ''));
			_.set(stakeholder, 'address.zipCode', _.get(value, 'address.zipCode', ''));
			_.set(stakeholder, 'address.street', _.get(value, 'address.street', ''));

			stakeholders[index] = {id: value.id, label: value.fullName};
			stakeholderList[index] = stakeholder;
		}
	};

	findStakeholder(roleId) {
        return this.props.stakeholderList.find(stakeholder => stakeholder.id === roleId);
    };

    findStakeholderAuthorized(roleId) {
        return this.props.stakeholderList.filter(stakeholder => {
            return stakeholder.roles.some(role => role.stakeholder.id === roleId);
        })[0] || null;
    };

    filterAuthorizedByGroupCompany(groupCompany) {
        if (groupCompany) {
            let gc = this.findGroupCompany(groupCompany.id);
            return this.props.authorizedSignatories.filter(authorized => {
                return authorized.groupCompanyId === gc.id
            });
        }
        return [];
    }

    selectAuthorized = (value, form, field, replace) => {
        const stakeholder = this.props.authorizedSignatories.find(e => e.id === value);
        const email = stakeholder ? stakeholder.email : '';
        replace(0, { id: value, email })
    };

    getDateForPickerFromData(date) {
        if (!date) return undefined;
        return moment(date).toDate();
    }

    handleChange(e, field, form) {

        const date = e === null ? null : moment(e).format("YYYY-MM-DD");

        FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

        this.setState({
            [field.name]: e
        })
    }

    getAuthorizedSignatoriesList = (index, form) => {
        if (form.values && form.values.company) {
            return this.filterAuthorizedByGroupCompany(form.values.company).map(as => ({
                id: as.id,
                label: as.label
            }))
        } else {
            return [];
        }
    };

    getStakeholderAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add New Corporate Stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add more details later on the stakeholder's profile page.",
                placeholder: "Stakeholder's Name...",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: true,
                    // roles : [{
                    // 	role: "SHAREHOLDER",
                    // 	groupCompany: this.state.groupCompanySelected.id,
                    // }],
                },
                paramsReturn: 'isAnEntity'
            },
            {
                type: "stakeholder",
                label: "Add New Individual Stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add more details later on the stakeholder's profile page.",
                placeholder: "Stakeholder's Name...",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: false,
                    // roles : [{
                    // 	role:"SHAREHOLDER",
                    // 	groupCompany: this.state.groupCompanySelected.id,
                    // }]
                },
                paramsReturn: 'isAnEntity'
            },
        ]
    };

    handleValidateCompany=(form,values,type)=> {
        if (type === 'companyDetails') {
            _.get(values,'company.recipientNotice.useRegisteredAddress')
				? form.setFieldValue('company', {
					...values.company,
					recipientNotice: {...values.company.recipientNotice, useRegisteredAddress: true, address:{...values.company.registeredAddress}}
				})
				: form.setFieldValue('company',{
					...values.company,
					recipientNotice: {...values.company.recipientNotice, useRegisteredAddress: false}
				})
            form.setFieldValue('company.verified1',true)
        } else {
            _.get(values,'company.recipientNotice.useRegisteredAddress')
				? form.setFieldValue('company', {
					...values.company,
					recipientNotice:{...values.company.recipientNotice, useRegisteredAddress: true, address:{...values.company.registeredAddress}}
				})
				: form.setFieldValue('company', {
					...values.company,
					recipientNotice:{...values.company.recipientNotice, useRegisteredAddress: false}
				})
            form.setFieldValue('company.verified2',true)
        }
    }

    getCompanyAdds = () => {
        return [
            {
                notAdd: true,
                type: 'no-comp',
                icon: 'no-comp',
                label: ' The holding company has not been established',
                id: 'groupCompanyNotSelected',
            }
		]
    };

    getAuthorizedSignatoryAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add New Individual Stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add more details later from the stakeholder's profile page.",
                placeholder: "Enter Stakeholder's Name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: false,
                    roles: [{
                        role: "DIRECTOR",
                        groupCompany: this.state.groupCompanySelected.id,
                    }]
                }
            },
        ]
    };

    handleGroupCompanyCreate = (value, name, additionalValue, form) => {

        let { groupCompanies, groupCompaniesList } = this.props;
        let newGc = { id: value.id, name, ...additionalValue };
        groupCompaniesList.push(newGc);

        groupCompanies.push({ id: value.id, label: name });
        // this.setState({ groupCompaniesList, groupCompanies });

    };

    handleAuthorizedSignatoryCreate = (value, name, additionalValue, form) => {

        let { authorizedSignatories } = this.props;
        const { groupCompanySelected } = this.state;

        authorizedSignatories.push({
            id: value.id,
            label: name,
            role: 'DIRECTOR',
            groupCompanyId: groupCompanySelected.id
        });

    };

	getFounderPositionMapped = (founder, founderPosition) => {
		return founderPosition ? {
			...founderPosition,
			..._.get(founder,'position',{}),
			responsibilities: founderPosition.isDefault
				? _.get(founderPosition,'responsibilities', [])
				: _.get(founderPosition,'responsibilities[0]',null),
			otherResponsibilities: founderPosition.isDefault
				? null
				: _.get(founderPosition,'responsibilities[0].text',''),
			otherResponsibilitiesCode: founderPosition.isDefault
				? null
				: _.get(founderPosition,'responsibilities[0].code',null),
			otherResponsibility: founderPosition.isDefault
				? _.get(_.find(founderPosition.responsibilities,responsibility => !responsibility.isDefault), 'text', null)
				: null
		}:{};
	}

    render() {
        const isRequired = message => value => (!!value ? undefined : message);
        const { tooltips = {}, formType } = this.props;

        return (
            <div>
                <h1 className={classes.title}>
                    Edit Document
                </h1>
                {this.state.firstPage ? (
                	<>
                        {formType == 'uploadToEdit' ? (<div>
                            <Field name={`file`}>
                                {({field, form}) => (
                                    <ClaraUpload
                                        required={true}
                                        name={`filepond`}
                                        label='Upload your document: '
                                        field={field}
                                        form={form}
                                    />
                                )}
                            </Field>
                        </div>) : null}
                        <Field name='agreementName'>
                            {({field, form}) => (
                                <TextInput
                                    required={true}
                                    classNameInput={{ root: classes.ContainerRow }}
                                    type='text'
                                    label='Document name '
                                    field={field}
                                    form={form}
                                    placeholder={"Document name"}
                                    tooltip={tooltips['Document Name']}
                                />
                            )}
                        </Field>

						<Line className={classes.line} />
						<div className={classes.ZoneTitle}>
							<h1>{'Company'}</h1>
						</div>
						<div className={`${classes.ContainerRow} ${classes.Select}`}>
							<Field name='company.id'>
								{({field, form}) => (
									<ClaraSelect
										required={true}

										field={field}
										form={form}
										label={"Select company "}
										lists={this.getGroupCompanies()}
										adds={this.getCompanyAdds()}
										mode={"classic"}
										callbacks={{
											createValues: this.handleGroupCompanyCreate,
											updateValues: this.selectGroupCompany
										}}
										placeholder={"Select from the list"}
										tooltip={tooltips['Select Company']}
									/>
								)}
							</Field>
						</div>
						{
							this.state.groupCompanySelected ?
								<>
									{this.state.groupCompanySelected.id ?
										(<>
											<Line className={classes.line} />
											<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
												<h1>Holding Company</h1>
												<p>Please ensure the Holding Company details are correct and complete by clicking on the “Please Verify” buttons below.</p>
											</div>
											<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
												<h1>{this.state.groupCompanySelected.name}</h1>
											</div>
											<Field name={"company.verified_error"}>
												{({field, form}) => (<>
														<React.Fragment>
															<VerifyDetails
																initialValues={{company:{
																		..._.get(form.values,'company'),
																		general:{
																			..._.get(form.values,'company')
																		}
																	}}}
																	groupCompany={this.state.groupCompanySelected}	
                                                                nameHandle={'topco'}
																handleSubmit={(values,type)=>this.handleValidateCompany(form,values,type)}
																title={'Company'}
																tooltips={tooltips}
																type={'company'}
																fieldName={'company'}
															/>
														</React.Fragment>
													</>
												)}
											</Field></>):
										(<>
											<Line className={classes.line} />
											<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
												<h1>Holding Company not established</h1>
												<p>Please choose the country and jurisdiction your future holding company is going to be established in.</p>
											</div>
											<div className={`${classes.Select}`}>
												<Field name='company.country'>
													{({ field, form }) => (
														<ClaraCountrySelector
															isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
															jurisdictionsEnabled={true}
															countryField={'company.country'}
															label={'Country of Incorporation: '}
															jurisdictionField={'company.jurisdiction'}
															jurisdictionLabel={'Jurisdiction: '}
															returnCountryAsString={true}
															otherJurisdictionField={'company.otherJurisdiction'}
															setFieldValue={form.setFieldValue}
															initialValues={{
																jurisdiction: _.get(form.values, 'company.jurisdiction')
															}}
															additionalStyle={'ClaraCountrySelectorModal'}
															tooltips={{
																country: tooltips["Company's Country"],
																jurisdiction: tooltips["Company's Jurisdiction"],
																other: tooltips['Other Jurisdiction']
															}}
														/>
													)}
												</Field>
											</div>
										</>)
									}
									<Line className={classes.line} />
									<div className={classes.ZoneTitle}>
										<h1>{'Founders'}</h1>
									</div>
									<Field name='founders.error'>
										{({field, form}) => (
											<React.Fragment>
						<span hasError={_.get(form.errors, "founders") && !_.some(_.get(form.errors,"founders",[]),founder=>{
							return _.get(founder,'verified1',null) || _.get(founder,'verified2',null)
						}) && _.size(_.get(form.values,'founders',[]))<2? "true" : "false"}>
						<SelectFounder
							label={'Add founder '}
							list={this.props.stakeholderList}
							tooltips={tooltips}
							groupCompany={this.state.groupCompanySelected}
							onChange={this.selectFounder}
							// handleAddListStakeholder={this.handleStakeholderCreate}
							// handleOnRemoveStakeholder={this.handleStakeholderUpdate}
							fieldArrayName={'founders'}
							errorMessage={_.get(form.errors, "founders")}
						/>
							 </span>
											</React.Fragment>
										)}
									</Field>
									{ this.state.groupCompanySelected.id?(<>
										<Line className={classes.line} />
										<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
											<h1>Corporate Signatories</h1>
											<p>Please add all the signatories required to sign this document on behalf of the
												company.</p>
										</div>
										<Field name='company.authorizedSignatories_error'>
											{({field, form}) => (
												<React.Fragment>
													<span hasError={_.get(form.errors, "company.authorizedSignatories") ? "true" : "false"}>
													<AuthorisedSignatories
                                            list={this.props.stakeholderList}
											fieldArrayName={'company.authorizedSignatories'}
														idGroupCompany={this.state.groupCompanySelected.id}
														handleAddListStakeholder={this.handleAuthorizedSignatoryUpdate}
														errorMessage={_.get(form.errors, "company.authorizedSignatories")}
													/>
													</span>
												</React.Fragment>
											)}
										</Field></>):null
									}
									<Line className={classes.line} />
									<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
										<h1>Transfer Shares</h1>
										<p>Please add any companies where Founders have shares that need to be transferred to the Holding Company (e.g. an operating company held in a Founder's name that needs to be brought under the Holding Company).</p>
									</div>
									<div className={classes.ContainerRow}>
										<Field name={'terms.transferShares.transferSharesGroupCompanies'}>
											{({field, form}) => (
												<ClaraRadioWithButton
													label={'Are there any companies whose shares need to be transferred to the Holding Company? '}
													field={field}
													required={true}
													form={form}
													changeParams={field.name}
													options={[{value: true, label: 'Yes'}, {value: false, label: 'No'}]}
													// onChange={(v) => { onChange(v,form) }}
												/>
											)}
										</Field>
									</div>
									<Field name={'terms.transferShares.companies'}>
										{({field, form}) =>_.get(form,'values.terms.transferShares.transferSharesGroupCompanies')? (<>
										<span hasError={_.get(form.errors, "terms.transferShares.companies")&& _.isEmpty(_.get(form.values,'terms.transferShares.companies'))? "true" : "false"}>
									<SelectCompany
										label={'Select company '}
										list={this.props.groupCompaniesList}
										tooltips={tooltips}
										onChange={this.selectAddCompany}
										fieldArrayName={'terms.transferShares.companies'}
										groupCompanySelected={this.state.groupCompanySelected}
										errorMessage={_.get(form.error,'terms.transferShares.companies')}
									/>
										</span>
										</>):null}
									</Field>
									<Line className={classes.line} />
									<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
										<h1>Business Description</h1>
									</div>
									<div className={classes.ContainerRow}>
										<Field name='terms.business.description'>
											{({field, form}) => (
												<TextAreaInput
													required={true}
													type='text'
													trim={true}
													label='The business of the Group is'
													field={field}
													form={form}
													placeholder={"Complete the sentence 'The business of the group is...'"}
                                                    tooltip={tooltips['Bussiness Description']}

                                                />
											)}
										</Field>
									</div>
									<Line className={classes.line} />
									<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
										<h1>Board of Directors</h1>
										<p>Let's select the requirements for appointing Directors.</p>
									</div>
										<Field name='terms.directors.amount'>
											{({field, form}) => (
												<TextInput
													required={true}
													classNameInput={{ root: classes.ContainerRow }}
													type='text'
													label='Number of directors '
													field={field}
													form={form}
													placeholder={'e.g. 1,2,5'}
													tooltip={tooltips['Response Time Other']}
												/>
											)}
										</Field>
									<FieldArray
										name={'terms.directors.founders'}
										render={(arrayHelpers) => {
											return (<>
												{!_.isEmpty(_.get(arrayHelpers.form.values, `founders`,[]))?(<>
													<div className={`${classes.labelToStakeholders}`} style={{paddingBottom:'1.5rem',paddingTop:'1.5rem'}}>
														<label>{'How many directors will each founder appoint? '}<span style={{color:'red'}}>{' '}*</span> </label>
													</div>
													{_.get(arrayHelpers.form.values, `founders`,[]).map((founder, index) => {
														return (<>
															<div className={`${classes.StakeholderName}`}>
																<Field name={`terms.directors.founders[${index}].amount`}>
																	{({field, form}) => (
																		<TextInput
																			classNameInput={{ root: classes.ContainerRow }}
																			type='text'
																			label={`${founder.fullName} `}
																			field={field}
																			form={form}
																			placeholder={'e.g. 1,2,5'}
																			tooltip={tooltips['Response Time Other']}
																		/>
																	)}
																</Field>
															</div>
															<Field name={`terms.directors.founders[${index}].id`}>
																{({field, form}) => (<></>
																)}
															</Field>
														</>)
													})
													}
												</>):null}</>)
										}
										}/>
										<div className={classes.ContainerRow}>
										<Field name={'terms.directors.hasCastingVoteCP'}>
											{({field, form}) => (
												<ClaraRadioWithButton
													label={'Will the Chairperson have a casting vote?'}
													field={field}
													required={true}
													form={form}
													changeParams={field.name}
													options={[{value: true, label: 'Yes'}, {value: false, label: 'No'}]}
													// onChange={(v) => { onChange(v,form) }}
												/>
											)}
										</Field>
									</div>
									<Line className={classes.line} />
									<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
										<h1>Lead Founder</h1>
										<p>A Lead Founder is a Founder who will be given preferential treatment over other Founders (e.g. appointing the Board chairperson, Lead Founder must be present for Shareholder meetings to proceed).</p>
									</div>
									<div className={classes.ContainerRow}>
										<Field name={'terms.leadFounder.havesLeadFounder'}>
											{({field, form}) => (
												<ClaraRadioWithButton
													label={'Is there a Lead Founder? '}
													field={field}
													required={true}
													form={form}
                                                    tooltip={tooltips['Lead Founder']}
													changeParams={field.name}
													options={[{value: true, label: 'Yes'}, {value: false, label: 'No'}]}
													// onChange={(v) => { onChange(v,form) }}
												/>
											)}
										</Field>
									</div>
									<Field>
										{({form}) =>_.get(form,'values.terms.leadFounder.havesLeadFounder')? (<>
											<div className={`${classes.ContainerRow} ${classes.Select}`}>
												<Field name='terms.leadFounder.id'>
													{({field, form}) =>(
														<ClaraSelect
															required={true}
															field={field}
															form={form}
															label={"Lead Founder "}
															lists={_.map(_.get(form,'values.founders',[]),founder=>{return {label:founder.fullName,id:founder.id}})}
															mode={"classic"}
															placeholder={"Select from the list"}
														/>)}
												</Field>
											</div>
												<Field name={`terms.leadFounder.minimumToAppointCP`}>
													{({field, form}) => (
														<TextInput
															required={true}
															classNameInput={{ root: classes.ContainerRow }}
															type='text'
															label='Minimum shareholding the Lead Founder must hold to appoint chairperson '
															field={field}
															form={form}
															rightLabel={'%'}
															placeholder={'e.g. 10,20'}
															tooltip={tooltips['Response Time Other']}
														/>
													)}
												</Field>
										</>):null}
									</Field>
									<Line className={classes.line} />
									<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
										<h1>Shareholders’ Meetings</h1>
										<p>The minimum number of Shareholders who must be present (quorum) for a Shareholder meeting to proceed. If you have selected a Lead Founder, then the Lead Founder will also be required to be one of the Shareholders present at the meeting.</p>
									</div>
									<Field name={`terms.meetings.quorum`}>
										{({field, form}) => (
											<TextInput
												required={true}
												type='text'
												classNameInput={{ root: classes.ContainerRow }}
												label='Quorum '
												field={field}
												form={form}
												placeholder={'e.g. 2,3,4'}
												tooltip={tooltips['Quorum']}
											/>
										)}
									</Field>
									<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
										<h1>Matters requiring prior written approval of all founders</h1>
									</div>
									<div className={`${classes.checkBoxWizard}`}>
										<Field name='terms.matterList.option_a'>
											{({ field, form }) => (
												<CheckboxInput
													id='terms.matterList.option_a'
													label={'Any amendment or revision to the Articles or the articles of association of any Group Company.'}
													field={field}
													form={form}
													className={classes.checkBoxWizardIndividual}
												/>
											)}
										</Field>
									</div>
									<div className={`${classes.checkBoxWizard}`}>
										<Field name='terms.matterList.option_b'>
											{({ field, form }) => (
												<CheckboxInput
													id='terms.matterList.option_b'
													label={'Any variation of the rights attached to the Shares.'}
													field={field}
													form={form}
													className={classes.checkBoxWizardIndividual}
												/>
											)}
										</Field>
									</div>
									<div className={`${classes.checkBoxWizard}`}>
										<Field name='terms.matterList.option_c'>
											{({ field, form }) => (
												<CheckboxInput
													id='terms.matterList.option_c'
													label={'Any authorisation for or creation of (by reclassification or otherwise) any new class of Shares.'}
													field={field}
													form={form}
													className={classes.checkBoxWizardIndividual}
												/>
											)}
										</Field>
									</div>
									<div className={`${classes.checkBoxWizard}`}>
										<Field name='terms.matterList.option_d'>
											{({ field, form }) => (
												<CheckboxInput
													id='terms.matterList.option_d'
													label={'Raising any equity capital or other additional investment (including by way of loan) in any Group Company in any amount from any person (including a Founder).'}
													field={field}
													form={form}
													className={classes.checkBoxWizardIndividual}
												/>
											)}
										</Field>
									</div>
									<div className={`${classes.checkBoxWizard}`}>
										<Field name='terms.matterList.option_e'>
											{({ field, form }) => (
												<CheckboxInput
													id='terms.matterList.option_e'
													label={'The establishment of, or amendment to, any share incentive plan adopted by any Group Company.'}
													field={field}
													form={form}
													className={classes.checkBoxWizardIndividual}
												/>
											)}
										</Field>
									</div>
									<div className={`${classes.checkBoxWizard}`}>
										<Field name='terms.matterList.option_f'>
											{({ field, form }) => (
												<CheckboxInput
													id='terms.matterList.option_f'
													label={'The making or declaring of any dividend or distribution of by any Group Company.'}
													field={field}
													form={form}
													className={classes.checkBoxWizard}
												/>
											)}
										</Field>
									</div>
									<div className={`${classes.checkBoxWizard}`}>
										<Field name='terms.matterList.option_g'>
											{({ field, form }) => (
												<CheckboxInput
													id='terms.matterList.option_g'
													label={'Any reduction of the share capital, restructuring of the share capital of by any Group Company or the establishment of any Subsidiary of the Company.'}
													field={field}
													form={form}
													className={classes.checkBoxWizard}
												/>
											)}
										</Field>
									</div>
									<div className={`${classes.checkBoxWizard}`}>
										<Field name='terms.matterList.option_h'>
											{({ field, form }) => (
												<CheckboxInput
													id='terms.matterList.option_h'
													label={'Any IPO, Sale, merger, acquisition, consolidation, or reorganisation by the Group.'}
													field={field}
													form={form}
													className={classes.checkBoxWizard}
												/>
											)}
										</Field>
									</div>
									<div className={`${classes.checkBoxWizard}`}>
										<Field name='terms.matterList.option_i'>
											{({ field, form }) => (
												<CheckboxInput
													id='terms.matterList.option_i'
													label={'The adoption of, or material change to, any budget or business plan by any Group Company.'}
													field={field}
													form={form}
													className={classes.checkBoxWizard}
												/>
											)}
										</Field>
									</div>
									<div className={`${classes.checkBoxWizard}`}>
										<Field name='terms.matterList.option_j'>
											{({ field, form }) => (
												<CheckboxInput
													id='terms.matterList.option_j'
													label={'The making of any loan or advance, or the giving of any guarantee or indemnity, by any Group Company to any person.'}
													field={field}
													form={form}
													className={classes.checkBoxWizard}
												/>
											)}
										</Field>
									</div>
									<div className={`${classes.checkBoxWizard}`}>
										<Field name='terms.matterList.option_k'>
											{({ field, form }) => (
												<CheckboxInput
													id='terms.matterList.option_k'
													label={'The incurrence of any indebtedness or other financial obligation or the issuance of any guarantee for borrowed money by any Group Company, unless such liability is incurred pursuant to the then current business plan or the budget.'}
													field={field}
													form={form}
													className={classes.checkBoxWizard}
												/>
											)}
										</Field>
									</div>
									<div className={`${classes.checkBoxWizard}`}>
										<Field name='terms.matterList.option_l'>
											{({ field, form }) => (
												<CheckboxInput
													id='terms.matterList.option_l'
													label={'Any transaction, commitment or other expenditure of a capital nature (whether as part of a single transaction or series of transactions) by any Group Company in excess of USD 25,000 (unless such expenditure is made pursuant to the then current business plan or the budget or otherwise approved by the Board).'}
													field={field}
													form={form}
													className={classes.checkBoxWizard}
												/>
											)}
										</Field>
									</div>
									<div className={`${classes.checkBoxWizard}`}>
										<Field name='terms.matterList.option_m'>
											{({ field, form }) => (
												<CheckboxInput
													id='terms.matterList.option_m'
													label={'The entry by any Group Company into any transaction with an Affiliate.'}
													field={field}
													form={form}
													className={classes.checkBoxWizard}
												/>
											)}
										</Field>
									</div>
									<div className={`${classes.checkBoxWizard}`}>
										<Field name='terms.matterList.option_n'>
											{({ field, form }) => (
												<CheckboxInput
													id='terms.matterList.option_n'
													label={'Any sale, mortgage, pledge, lease, transfer or other disposal of any of the assets of any Group Company.'}
													field={field}
													form={form}
													className={classes.checkBoxWizard}
												/>
											)}
										</Field>
									</div>
									<div className={`${classes.checkBoxWizard}`}>
										<Field name='terms.matterList.option_o'>
											{({ field, form }) => (
												<CheckboxInput
													id='terms.matterList.option_o'
													label={'The entry by any Group Company into any licence, transfer, or assignment of Intellectual Property Rights used in the Business.'}
													field={field}
													form={form}
													className={classes.checkBoxWizard}
												/>
											)}
										</Field>
									</div>
									<div className={`${classes.checkBoxWizard}`}>
										<Field name='terms.matterList.option_p'>
											{({ field, form }) => (
												<CheckboxInput
													id='terms.matterList.option_p'
													label={'Any amendment to or termination of any Relevant Agreement or any increase in the remuneration or benefits of any Founder.'}
													field={field}
													form={form}
													className={classes.checkBoxWizard}
												/>
											)}
										</Field>
									</div>
									<div className={`${classes.checkBoxWizard}`}>
										<Field name='terms.matterList.option_q'>
										{({ field, form }) => (
											<CheckboxInput
												id='terms.matterList.option_q'
												label={'The passing of a resolution for the winding up of any Group Company.'}
												field={field}
												form={form}
												className={classes.checkBoxWizard}
											/>
										)}
									</Field>
									</div>
									<Line className={classes.line} />
									<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
										<h1>Place of Arbitration</h1>
										<p>This contract’s dispute resolution mechanism uses arbitration under expedited ICC rules. Please select the place where you would like the arbitration to take place (please enter the name of the city and country and, if applicable, state or freezone), e.g. "London, United Kingdom" or "New York City, New York, United States of America” or "Dubai International Financial Centre, Dubai, United Arab Emirates”. Please note that the place of arbitration that you select can have a material impact on the proceedings and enforcement of the arbitration and arbitral award. Please seek legal advice.</p>
									</div>
									<Field name={`terms.placeOfArbitration`}>
										{({ field, form }) => (
											<TextInput
												required={true}
												type='text'
												label='Place of arbitration '
												field={field}
												form={form}
												placeholder={"Place of arbitration"}
												classNameInput={{ root: classes.ContainerRow }}
											/>
										)}
									</Field>
								</>
								:null
						}
						<div className={classes.buttonPages}>
						<button style={{'width': '190px', 'height': '40px'}} onClick={this.secondPage} className={`genericBlue`}>Next</button>
						</div>
                	</>
				):(<>
                    <React.Fragment>
                        {/*<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>*/}
                        {/*    <h1>Shareholding</h1>*/}
                        {/*    <p>Please select each Founder's shareholding in the Holding Company and the vesting terms (e.g. 100% if all shares subject to vesting or 75% if 25% will be immediately vested and 75% will be subject to vesting).</p>*/}
                        {/*    <p>Any shares that are subject to vesting will automatically include a one year cliff period. This means that a portion of the unvested shares equal to 12 divided by the total vesting period in months will vest in one lump sum after the first year and the rest on a straight line monthly basis over the remaining vesting period (e.g. if you select a vesting period of 48 months, one quarter (25%) of your unvested shares will vest in one lump sum after one year and the remaining unvested shares will vest monthly over the remaining 3 years).</p>                        */}
                        {/*</div>*/}
						{/*<Field name={`terms.shareholding.vestingPeriod`}>*/}
						{/*	{({field, form}) => (*/}
						{/*		<TextInput*/}
						{/*			required={true}*/}
						{/*			type='text'*/}
						{/*			classNameInput={{ root: classes.ContainerRow }}*/}
						{/*			label='For shares subject to vesting, what is the vesting period? '*/}
						{/*			field={field}*/}
						{/*			form={form}*/}
						{/*			rightLabel={'Months'}*/}
						{/*			placeholder={'e.g. 12, 24, 36,48'}*/}
						{/*			tooltip={tooltips['Vesting Period']}*/}
						{/*		/>*/}
						{/*	)}*/}
						{/*</Field>*/}

                        <ClaraAddVestingEquities
                             groupCompany={this.findGroupCompany(this.state.groupCompanySelected?.id)}
							 fieldArrayName={'founders'}
							 tooltips={tooltips}/>
                        <div className={classes.buttonPages}>
                        <button style={{'width': '190px', 'height': '40px'}} onClick={this.firstPage} className={`genericWhite`}>Back</button>
                        </div>
                    </React.Fragment>
                </>)}
                {formType === 'uploadToEdit' ? <div>
                    <Field name='confirmSameData'>
                        {({field, form}) => (
                            <Checkbox
                                className={classes.sameDataCheckbox}
                                field={field}
                                validate={isRequired('This field is required')}
                                form={form}
                                required={true}
                                label='All document details are verified and correct.'
                            />
                        )}
                    </Field>
                </div> : null}
            </div>

        );
    }

}

const CheckboxInput_ = (props) => {

	const { label, options, required = false, form, field, tooltips, input, hasError } = props;
	const { value } = field;
	const [selection, setSelection] = React.useState(value ? value.id : 0);

	const handleInput = () => {
		if (value && value.id !== selection) {
			setSelection(value.id);
			form.setFieldValue(`${field.name}.message`, '');
			if (!input) form.setFieldValue(`${field.name}.message`, label);
		}
	};

	if (value && value.id !== selection) {
		form.setFieldValue(`${field.name}.message`, label);
		setSelection(value.id);
	}

	handleInput();

	return (

		<>

			<Field name={`${field.name}.id`}>
				{({ field, form }) => (
					<Checkbox
						id={`${field.name}.id`}
						label={label}
						field={field}
						form={form}
						className={classes.checkBoxWizard}
						hasError={hasError}
					/>
				)}
			</Field>

			{
				input && value && value.id &&
				<>
					<Field name={`${field.name}.message`}>

						{({ field, form }) => (
							<TextInput
								type='text'
								field={field}
								form={form}
								className={{
									root: classes.InputContainer,
									input: classes.TextInput
								}}
								{...props.TextInput}
							/>
						)}
					</Field>
					<Line className={classes.line} />
				</>
			}
		</>
	)

};

const CheckboxInput = (props) => {

	const { label, options, required = false, form, field, tooltips, input } = props;
	const { value } = field;
	const [selection, setSelection] = React.useState(value ? value.id : 0);
	const [labelTextInput, setlLabelTextInput] = React.useState('');
	const [textInput, setTextInput] = React.useState({})
	const handleInput = () => {
		if (value && value.id !== selection) {
			setSelection(value.id);
			form.setFieldValue(`${field.name}.message`, '');
			if (!input) form.setFieldValue(`${field.name}.message`, label);
		}
	};

	if (value && value.id !== selection) {
		form.setFieldValue(`${field.name}.message`, label);
		setSelection(value.id);
	}
	React.useEffect(() => {
		if (props.TextInput && props.TextInput.label) {
			setlLabelTextInput(props.TextInput.label)
			setTextInput(_.omit(props.TextInput, ['label']))
		}
	}, [])

	handleInput();

	return (

		<div className={classes.inputCheckboxFounder}>

			<Field name={`${field.name}.id`}>
				{({ field, form }) => (
					<Checkbox
						id={`${field.name}.id`}
						label={label}
						field={field}
						form={form}
						className={classes.checkBoxWizardFounder}
					/>
				)}
			</Field>

			{
				input && value && value.id &&
				<>
					<Field name={`${field.name}.message`}>

						{({ field, form }) => (
							<div className={classes.InputContainer}>
								<label>{labelTextInput}</label>
								<TextInput
									type='text'
									field={field}
									form={form}
									{...textInput}
								/>
							</div>)}
					</Field>
					<Line className={classes.line} />
				</>
			}
		</div>
	)

};

export default FoundersAgreement;

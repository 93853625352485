import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import AddressInput from "src/components/forms/modules/startups/individualStakeholder/AddressInput";
import SelectMailingAddressTypeInput from 'src/components/inputs/Select/MultiSelectInput';
import Button from 'src/modules/generic/components/Buttons/Button';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateLabel from 'src/modules/generic/components/Inputs/LabelInput';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { addAssistantText, clearAssistatText } from 'src/modules/generic/store/action';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import * as Yup from 'yup';

function MailingAddressInfo() {
  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return {};
  }, [context]);

  const handleCancel = () => {
    cancel();
  }

  const handleSubmit = async (values) => {
    next(values);
  }

  useEffect(() => {
    dispatch(addAssistantText(translate('MODULES.ASSISTANCEV4.ASSISTANCE_MAP_HIT')));
    return () => {
      dispatch(clearAssistatText());
    }
  }, []);

  

  const schema = Yup.object().shape({
    mailingAddress: Yup.object().shape({
      type: Yup.string().required('This field is required').typeError('This field is required'),
      country: Yup.string().required('This field is required'),
      street: Yup.string().required('This field is required'),
      city: Yup.string().required('This field is required'),
      zipCode: Yup.string().nullable(),
      state: Yup.string().nullable(),
    }),
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: 'What is the company mailing address?',
          skeleton: null,
          subTitle: 'Mailing address',
          variant: 'simple'
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton>Add</SubmitButton>,
        }}
      >
        <TemplateLabel label="Mailing address type" isRequired>
          <ControllerInput
            render={SelectMailingAddressTypeInput}
            name="mailingAddress.type"
          />
        </TemplateLabel>
        <AddressInput
          name="mailingAddress"
          label={{
            country: "Mailing address",
          }}
          disabled={false}
          banner={false}
          zipCodeOptional
          isRequired
        />
        <div>&nbsp;</div>
      </TemplateWizard>
    </Form>
  );
}

export default MailingAddressInfo;
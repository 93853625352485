import React, { FC, useRef, useState } from "react";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import Typography from "src/modules/generic/components/Texts/Typography";
import Form from "../../../../components/forms/Form/FormL";
import SubmitButton from "../../../generic/components/Buttons/SubmitButton";
import ControllerInput from "../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import FormProps from "../../../generic/forms/interfaces/FormProps";
import TemplateFormDefault from "../../../generic/templates/Modal";
import classes from "./Form.module.scss";
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import useValidations from "src/modules/generic/forms/useValidations";
import { HiddenFieldForm } from "src/components/forms/contents";
import ClaraList from "src/modules/generic/components/Lists/ClaraList";

interface Props extends FormProps {
  dataProtectionContact: any;
}

const FormDataProtectionContact: FC<Props> = ({
  initialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  dataProtectionContact = {},
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
}) => {
  const refForm = useRef();
  const { addField, fieldType, fieldConfigurations } = useValidations();
  const { object, boolean } = fieldType;
  const { isRequired, nullable } = fieldConfigurations;
  const schema = addField(
    object(),
    "isChange",
    nullable(isRequired(boolean()))
  );

  const [isChange, setIsChange] = useState();

  const options = [
    {
      value: true,
      label: "No",
    },
    {
      value: false,
      label: "Yes",
    },
  ];

  return (
    <>
      <Form
        defaulValues={initialValues || {}}
        schema={schema}
        onlySendDirty={false}
        onSubmit={onCompleteSubmit}
        ref={refForm}
        optionsForm={{ mode: "onChange" }}
      >
        <Template
          buttons={{
            ...buttons,
            submit: (
              <SubmitButton {...buttonSubmitProps}>
                {isChange ? "Next" : "Confirm"}
              </SubmitButton>
            ),
          }}
          props={propsTemplate}
        >
          <Typography component="p" variant="h5">
            <span
              dangerouslySetInnerHTML={{
                __html:
                  "Your current data protection contact is listed below for your confirmation:",
              }}
            />
          </Typography>
          <ClaraList
            items={dataProtectionContact ? [{name: dataProtectionContact?.fullName ?? ""}] : []}
          ></ClaraList>
          <TemplateLabel
            label="Please confirm this is correct"
            isRequired
            variant="top"
          >
            <ControllerInput
              render={CheckboxButtonSimple}
              name="isChange"
              options={options}
              onChange={(value) => {
                setIsChange(value);
              }}
              isMultiple={false}
              classNameButton={classes.buttonCheckBox}
            />
          </TemplateLabel>
          <TemplateLabel label="">
            <HiddenFieldForm
              fieldsWatch="isChange"
              conditionHideCallback={(value) => {
                if (typeof value === "boolean") {
                  return value;
                }
              }}
            >
              <Typography component="p" variant="h4" fontWeight="semibold">
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      "You will be able to select a different stakeholder in the next screen.",
                  }}
                />
              </Typography>
            </HiddenFieldForm>
          </TemplateLabel>
          <div>&nbsp;</div>
        </Template>
      </Form>
    </>
  );
};

export default FormDataProtectionContact;

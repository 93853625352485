import useMutation from "../../../../../hooks/custom/useMutation";

export const UPSERT_COMPONENT_POSITION = `
mutation upsertComponentPosition(
    $startupId: ID!
    $position: ComponentPositionInputType!
  ) {
    upsertComponentPosition(startupId: $startupId, position: $position) {
		id
		code
		name
		isDefault
		groupCompany{
		id
		}
		responsibilities {
			id
			name
			isDefault
			code
			text
		}
    }
  }
`;

const useUpsertComponentPosition = () => {
    const [mutation, loading] = useMutation(
        UPSERT_COMPONENT_POSITION,
        { showSuccessNotification: false }
    ); 
    return { mutation, loading };
};

export default useUpsertComponentPosition;

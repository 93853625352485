import  TemplateWizardSkeletonMachine  from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import { GeneralDetails } from "../steps";

const useSteps = () => {
    return {
        InitialData:{
            render: TemplateWizardSkeletonMachine
        },
        PincasData:{
            render: TemplateWizardSkeletonMachine
        },
        GeneralDetails:{
            render: GeneralDetails
        },
        SaveStep:{
            render: TemplateWizardSkeletonMachine
        }
    };
}

export default useSteps;
import { get } from 'lodash';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../../../generic/components/Buttons/Button';
import { StepProps } from "../../../../../generic/components/Wizard/Step";
import useCustomMachine from '../../../../../generic/hooks/useCustomMachine';
import { addAssistantText, clearAssistatText } from '../../../../../generic/store/action';
import TemplateWizard from '../../../../../generic/templates/Modal/TemplateWizard';
import Form from "../../components/Forms/FormVisa";
import GCCResidentID from '../../public/GCC_resident_ID.svg';
import BackOfIDCard from '../../public/Back_of_ID_card.svg';

const Step2: FC<StepProps> = () => {

  const { next, send, state } = useCustomMachine();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(addAssistantText(`Upload clean scans of the front and back of this stakeholder's National ID.`));
    return () => {
      dispatch(clearAssistatText());
    }
  }, [])

  const handleCompleteSubmit = async values => {
    next(values);
  }

  const handleCancel = async values => {
    send("CANCEL");
  }

  return (
    <React.Fragment>
      <Form
        initialValues={get(state, 'context.data.uploadDocument', {})}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: 'Add their National ID',
          subTitle: 'National ID'
        }}
        image={<><img src={GCCResidentID} alt="" /> <img src={BackOfIDCard} alt=""/></>}
        buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }}>
      </Form>
    </React.Fragment>
  )
}

export default Step2;

import useLazyQuery from "src/hooks/custom/useLazyQuery";
import useSession from 'src/modules/session/hooks/useSession';

const GET_GROUP_COMPANY_AND_STAKEHOLDERS = `
query getGroupCompanyDataProtectionDeclaration($startupId: ID!, $groupCompanyId: ID!) {
  getGroupCompany(startupId: $startupId, groupCompanyId: $groupCompanyId) {
    id
    dataProtectionDeclaration{
      dataBeingProcessed
        countriesPersonalDataTransferred
        conditionsApplyingTransfer
        reasonsDataBeingProcessed
        mechanismInPlaceForProtection
        reasonsProcessingSensitiveData
        sensitiveDataBeingProcessed
        isPersonalDataTransferred{question}
        isSensitiveDataProcessed{question}
        othersSensitivePersonalData
        otherDataProcessed
        registrationExpiryDate
    }
  }
}`

const useGetGroupCompanyAndStakeholders =(variables) => {
    const { manualQuery, loading, error, data, refetch } = useLazyQuery(GET_GROUP_COMPANY_AND_STAKEHOLDERS, variables);
    const {startupId} = useSession()
    const query = async (variables) => {
        const { data } = await manualQuery({variables:{startupId,...variables}})
        return {
                groupCompany: data['getGroupCompany'],
        }
    }

    return {
        query,
        loading,
        error,
        data,
        refetch
    }
}
export default useGetGroupCompanyAndStakeholders

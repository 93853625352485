import get from 'lodash/get'
import useLazyQuery from "../../../../../../hooks/custom/useLazyQuery";
const VERIFICATION=`
query getStakeholderVerification($startupId:ID!, $stakeholderId:ID!){
getStakeholder(startupId:$startupId, stakeholderId:$stakeholderId){
id 
fullName
allowedActions{
    toDelete{
        value 
        reasonCode
        reasonText
    }
}
}
}
`
const useGetDeleteVerification=(variables,config?,props?)=>{
    const {manualQuery} =useLazyQuery(VERIFICATION,variables,config,props)
    return manualQuery
}
export default useGetDeleteVerification
import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import classes from "./Document.module.scss";
import { goToPage } from '../../../../../store/actions/page'
import { Constants } from '../../../../utils/constants'
import { ClaraSearch, RoleAccess } from '../../../index';
import DocumentTable from "./DocumentTable";
import { useDispatch } from 'react-redux';
import useCategories from '../../../../../hooks/common/useCategories'


const Document = ({ title, ...props }) => {

    const dispatch = useDispatch();
    const [documents, setDocuments] = useState([])
    const [listCategories, setListCategories] = useState([]);
    const [selectCategory, setSelectCategory] = useState(null);

    const { categories, loading } = useCategories("FORMATION");

    const labelByCategory = (category) => {
        switch (category.key) {
            case "ADGM_BUNDLE":
                return "ADGM Documents";

            case "GENERAL":
                return "General Docs";

            case "INVOICES":
                return "Invoices";

            case "WELCOME_PACK":
                return "Welcome Pack";

            case "KYC":
                return "KYC";

            case "QUESTIONNAIRE":
                return "Questionnaire";

            case "UNCATEGORIZED":
                return "Uncategorized";


            default:
                return _.get(category, "label", '');
        }

    }


    const orderSubCategory = useMemo(() => ({
        ADGM_BUNDLE: 4,
        QUESTIONNAIRE: 3,
        GENERAL: 0,
        KYC: 2,
        INVOICES: 1,
        WELCOME_PACK: 5,
        NOT_RELATED: 6
    }), []);


    const filterDocumentsByType = (documents, subcategory) => {
        const dataFilter = _.filter(documents, doc => {
            if (doc.entity) {
                if (!doc.populatedEntity && subcategory.key === "UNCATEGORIZED") {
                    return doc;
                }
                return _.some(subcategory.documentTypes, type => (doc.type.code === type.key) && doc.entityId === subcategory.id);
            }
            return _.some(subcategory.documentTypes, type => doc.type.code === type.key);
        });

        return dataFilter;
    }
    useEffect(() => {

        !_.isEmpty(selectCategory) && setDocuments(filterDocumentsByType(props.data.documents, selectCategory));

    }, [JSON.stringify(selectCategory)])


    const mapEntitiesType = (documents, subcategory) => {

        const kycSubCategories = [];
        const questionnaireSubCategories = [];

        _.forEach(documents, doc => {
            if (doc.populatedEntity) {
                const type = doc.entity === "individual" ? "INDIVIDUAL" : "CORPORATE_ENTITY";
                const sc = _.find(subcategory, subCat => subCat.key === type);
                if (doc.entitySource === "KYC") {
                    kycSubCategories.push({
                        ...sc,
                        label: doc.entity === "individual" ? `${_.get(doc, 'populatedEntity.firstName')} ${_.get(doc, 'populatedEntity.lastName')}`
                            : _.get(doc, 'populatedEntity.companyName', 'Not Name'),
                        id: doc.entityId
                    })
                }
                else {
                    questionnaireSubCategories.push({
                        ...sc,
                        label: doc.entity === "individual" ? `${_.get(doc, 'populatedEntity.firstName')} ${_.get(doc, 'populatedEntity.lastName')}`
                            : _.get(doc, 'populatedEntity.companyName', 'Not Name'),
                        id: doc.entityId
                    })
                }

            }
        })

        return [
            { key: "KYC", subCategories: _.uniqBy(kycSubCategories, 'id') },
            { key: "QUESTIONNAIRE", subCategories: _.uniqBy(questionnaireSubCategories, 'id') },
            { key: "UNCATEGORIZED", show: !_.isEmpty(filterDocumentsByType(documents, { key: "UNCATEGORIZED" })) }
        ];
    }


    useEffect(() => {

        if (!_.isEmpty(categories)) {
            const subCategories = categories[0].subCategories;

            const sortCategories = _.sortBy([...subCategories, ...mapEntitiesType(props.data.documents, subCategories)], cat => orderSubCategory[cat.key])

            setListCategories(sortCategories);
            !selectCategory && setSelectCategory(sortCategories[0])
        }

    }, [JSON.stringify(categories)])




    const handleAddDocument = () => {
        dispatch({ type: "GO_TO", page: Constants.PAGES.addDocumentKYC, params: { matterId: props.data.id, type: "addDocument" } })
    }

    const handleSelectCategory = (selectCategory) => {
        setSelectCategory(selectCategory)
    }


    const { data } = props;
    if (!data) {
        return null;
    }

    const conditionalSubcategory = ["INDIVIDUAL", "CORPORATE_ENTITY", "UNCATEGORIZED"]


    return (

        <div className={classes.ContainerViewDocument} >
            <div className={classes.ContainerSearchAndTitle}>
                <h2 className={classes.titleTable}>{title}</h2>
                <RoleAccess action={Constants.ACTIONS.MANAGE_MATTER}>
                    <div className={`${classes.ContainerAdd} ${classes.uploadDocButton}`} onClick={handleAddDocument}>
                        Upload Documents
                            </div>
                </RoleAccess>
            </div>

            <div>
                Categories

                    <ul className={classes.listCategories}>
                    {listCategories.map((category, index) => {

                        if (_.some(conditionalSubcategory, conSub => conSub === category.key) && !category.show) {
                            return null
                        }

                        return (
                            <>
                                {_.has(category, 'subCategories') ?
                                    <>
                                        <li key={`${category} ${index}`}>
                                            <span
                                                className={`${classes.elementList}`}
                                            >
                                                {labelByCategory(category)}
                                            </span>
                                        </li>
                                        <ul key={`${category}-${index}-sub-list`} className={classes.listSubCategories}>
                                            {category.subCategories.map((sc, indexSub) => (
                                                <li key={`${sc} ${indexSub}`}>
                                                    <span
                                                        className={`${classes.elementList} ${_.eq(sc, selectCategory) && classes.elementListSelected}`}
                                                        onClick={() => handleSelectCategory(sc)}
                                                    >
                                                        {labelByCategory(sc)}
                                                    </span>
                                                </li>)
                                            )

                                            }
                                        </ul>
                                    </>
                                    : <li key={`${category} ${index}`}>
                                        <span
                                            className={`${classes.elementList} ${_.eq(category, selectCategory) && classes.elementListSelected}`}
                                            onClick={() => handleSelectCategory(category)}
                                        >
                                            {labelByCategory(category)}
                                        </span>
                                    </li>
                                }
                            </>
                        )
                    })}

                </ul>

            </div>
            <div className={`${classes.ContainerData} ${classes.ContainerDataTable}`}>
                <DocumentTable
                    data={documents}
                    matterId={props.data.id}

                />

            </div>
        </div>
    )
}


export default Document;

import { useMemo } from 'react';
import { assign } from 'xstate';

const useActions = () => {

  return useMemo(() => {
    const addIndividualStakeholder = assign((context: any = {}, event: any = null) => {
      const newContext = { ...context };
      const newData = event?.data?.data?.createStakeholder;
      if (newData !== undefined) {
        const oldStakeholders = [ ...newContext?.initialData?.stakeholders ];
        oldStakeholders.push(newData);
        newContext.initialData.stakeholders = oldStakeholders;
      }
      if (newContext?.AddCorporate && newContext?.AddCorporate?.stakeholder) {
        delete newContext?.AddCorporate?.stakeholder;
      }
      return newContext;
    });
    return {
      addIndividualStakeholder,
    }
  }, []);
}

export default useActions;

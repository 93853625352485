import { createMachine } from 'xstate';

const machine =
  createMachine({
    id: 'AuhorisedSignatory',
    initial: 'initialData',
    states: {
      initialData: {
        invoke: {
          src: 'initialData',
          id: 'initialData',
          onDone: [
            {
              target: 'EvaluateCond',
              actions: ['setContextAfterInvoke']
            }
          ],
        }
      },
      EvaluateCond: {
        always: [
          {
            cond: 'isValidStakeholder',
            target: 'UaEorGccSubMachineStep'
          },
          {
            cond: 'isNominee',
            target: 'NomineeServiceSubMachineStep'
          },
          {
            cond: 'isThirdParty',
            target: 'ThirdPartySubMachineStep'
          },
        ]
      },
      UaEorGccSubMachineStep: {
        on: {
          NEXT: {
            target: 'finish',
          },
          CANCEL:{
            target: 'cancel'
          }
        }
      },
      NomineeServiceSubMachineStep: {
        on: {        
          NEXT:{
            target: 'finish',
          },
          CANCEL:{
            target: 'cancel'
          }
        }
      },
      ThirdPartySubMachineStep: {
        on: {
          NEXT:{
            target: 'finish',
          },
          CANCEL:{
            target: 'cancel'
          }
        }
      },
    cancel: {
        entry: 'cancelMachine',
        type: 'final'
      },
      finish: {
        entry: 'stopMachine',
        type: 'final'
      }
    }
  });
 
export default machine;

import _ from "lodash";
import React from 'react';
import { useSelector } from 'react-redux';
import AddEquityModal from 'src/modules/startup/equity/AddEquity';
import Button from '../../../../../../modules/generic/components/Buttons/Button';
import Typography from '../../../../../../modules/generic/components/Texts/Typography';
import useModal from '../../../../../../modules/generic/hooks/useModal';
import useTranslate from '../../../../../../modules/generic/hooks/useTranslate';
import { RoleAccess } from '../../../../../../v1/components';
import ModalDownload from "../../../../../../v1/containers/Dashboard/CapTable/Modals/ModalDownload";
import { Constants } from "../../../../../utils/constants";
import classesModule from './TitleCapTable.module.scss';

function TitleCapTable() {
  const { translate } = useTranslate();
  const { openModal } = useModal();
  const selectedCompany = useSelector((store) => _.get(store, "capTable.selectedCompany"));

  return (
    <div className={` ${classesModule.TitleCapTable} `}>
      <Typography variant='h2' color='blue'>{translate("CAP_TABLE_TITLE")}</Typography>
      <div>
        <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
          <Button variant="card" onClick={() => { openModal(AddEquityModal, {}) }}>{translate("CAP_TABLE_BUTTON_ADD_EQUITY_POSITION")}</Button>
          <Button variant="card" disabled={!selectedCompany || !selectedCompany.enabled} onClick={() => {
            openModal(ModalDownload, { open: true });
          }}>
            {translate("CAP_TABLE_BUTTON_DOWLOAD_CAP_TABLE")}
          </Button>
        </RoleAccess>
      </div>
    </div>
  );
}

export default TitleCapTable;
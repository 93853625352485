import React, { useEffect } from "react";
import classesModal from "./PaymentErrorView.module.scss";
import * as QueryString from "query-string";
import _ from "lodash";
const PaymentSuccessView = (props) => {
  useEffect(() => {
    const ckoSessionId = _.get(
      QueryString.parse(window.location.search, { parseBooleans: true }),
      "cko-session-id"
    );

    window.top.postMessage(
      JSON.stringify({
        status: "error",
        ckoSessionId,
        claraPayment: true,
      })
    );
  }, []); // empty array => run only once

  return (
    <React.Fragment>
      <div className={classesModal.PaymentSuccessView}></div>
    </React.Fragment>
  );
};

export default PaymentSuccessView;

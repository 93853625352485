import React, { FC, useState } from "react";
import ModalAssistant from "src/modules/generic/components/Modal/ModalAssistant";
import Wizard from "src/modules/generic/components/Wizard/v2";
import useModal from "src/modules/generic/hooks/useModal";
import { clearAssistatText as clearAssistantText } from "src/modules/generic/store/action";
import useActions from "./hooks/useActions";
import useGuards from "./hooks/useGuards";
import useServices from "./hooks/useServices";
import useSteps from "./hooks/useSteps";
import machine from "./machine";
import {Constants} from "src/v1/utils";
import event from "src/v1/utils/event";

interface Props {
  open: boolean;
  initialValues: any;
}

/* To Call this modal execute: openModal( PayFormationBundleModal, {},'MODAL_PAY_FORMATION_BUNDLE' ); */
const PayFormationBundleModal: FC<Props> = ({
  open: openParam = false,
  initialValues = {},
}) => {
  const [open, setOpen] = useState(openParam);
  const { closeModal: onClose } = useModal();
  const guards = useGuards();
  const actions = useActions();
  const steps = useSteps();
  const services = useServices({ initialValues });

  const handleStopMachine = () => {
    onClose();
    setOpen(false);
    clearAssistantText();
  };

  const handleCancelMachine = () => {
    event.emmit(Constants.EVENTS.REFRESH_DASHBOARD, {});
    onClose();
    setOpen(false);
    clearAssistantText();
  };

  return (
    <ModalAssistant open={open} variant="fullLg">
      <Wizard
        guards={guards}
        machine={machine}
        steps={steps}
        services={services}
        actions={actions}
        onStopMachine={handleStopMachine}
        onCancelMachine={handleCancelMachine}
      />
    </ModalAssistant>
  );
};

export default PayFormationBundleModal;

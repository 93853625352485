import _ from 'lodash'
import { actions as actionTypes } from "./action";

const initialState = {
    tooltips: [],
    assistantTexts: []
};

export default function genericReducer(state = initialState, { type, payload }) {
    switch (type) {
        case actionTypes.ADD_TOOLTIP:
            return {
                ...state,
                tooltips: [...state.tooltips, payload]
            };

        case actionTypes.ADD_TEXT_ASSISTANT:

            return {
                ...state,
                assistantTexts: _.concat(state.assistantTexts, payload)
            };
        case actionTypes.REMOVE_ASSISTANT_TEXT: {
            return {
                ...state,
                assistantTexts: _.dropRight(state.assistantTexts)
            }
        }
        case actionTypes.CLEAR_ASSISTANTS_TEXT:

            return {
                ...state,
                assistantTexts: []
            };

        default:
            return state;
    }

};


import _ from 'lodash'


export interface UseDocumentBusinessRulesReturn {
  canRenameDocument:( ) => boolean,
  isFreemiumValid:( ) => boolean,
  isNotFreemiumValid:( ) => boolean,
  canRenameDocumentWithDocument: ( any ) => {},
  values: any,
}

function useDocumentBusinessRules ( document? ): UseDocumentBusinessRulesReturn {
  const renamemingRules = {
    UPLOADED: ['Uploaded'],
    GENERATED: ['Draft','Signed','DRAFT_COMPLETED']
  }
  
  const values = ( ) => {
    return document;
  }

  const isGenerated = ( ) => {
    return !!_.find(_.get(document, 'kind'), (kind) => Object.values( 'GENERATED' ).includes(kind));
  }

  const isNotGenerated = (  ) => {
    return !isGenerated( );
  }

  const canRenameDocument = () => {
    if(isFreemiumValid()){
        const kind = _.get(renamemingRules, _.get(document, 'kind'))
        return !!_.find(kind, (o)=> { return o == _.get(document, `status`) })
    }
    return false;
  }

  const canRenameDocumentWithDocument = ( documentInfo) => {
    document = documentInfo;
    return canRenameDocument();
  }

  const isFreemiumValid = () => {
    return _.get(document, 'freemiumValid');
  }

  const isNotFreemiumValid = () => {
    return !isFreemiumValid;
  }
 
  return {
    canRenameDocument,
    canRenameDocumentWithDocument,
    isFreemiumValid,
    isNotFreemiumValid,
    values
  }
}

export default useDocumentBusinessRules

import MachineStakeholder from "../../core/wizards/machine/MachineStakeholder";
import FirstStep from './steps/FirstStep'
import LastStep from './steps/LastStep'
import IndividualOrCompanyStep from './steps/IndividualOrCompanyStep';
import IndividualOrCompanyStepFormInvestor from "./steps/IndividualOrCompanyStepForm";

export default class MachineInvestor extends MachineStakeholder{
    constructor() {
        super('investor',FirstStep,LastStep,IndividualOrCompanyStep,IndividualOrCompanyStepFormInvestor)
    }
}

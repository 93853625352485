import React from 'react';
import useTextLang from "../../../hooks/custom/useTextLang";
import classes from './Typography.module.scss';
import _ from "lodash"
import Typography from '@material-ui/core/Typography';
const TypographyLocal = ({ children, variant, component, content, ...props }) => {
    return (
        <Typography variant={variant} component={component} {...props}> {children} </Typography>
    );
}



export default TypographyLocal;

const templates = {
    
    MESSAGE_HEADER_VESTING_TERM_CREATED:
        "{{userName}} {{entityAction}} a  {{entityName}} for {{companyName}}.",
    MESSAGE_SUB_HEADER_VESTING_TERM_CREATED:
        "{{userName}} {{entityAction}} a  {{entityName}} for {{companyName}}.",
    MESSAGE_TEMPLATE_VESTING_TERM_CREATED: `
    <label> {{userName}} {{entityAction}} a {{entityName}} {{equityContainerName}} for {{companyName}}</label> 
    <ul>
        <li>{{equityContainerName}} has been {{entityAction}} for {{companyName}} by {{userName}} at {{activityTime}} on {{activityDate}}.</li>
    </ul> `,

    MESSAGE_HEADER_VESTING_TERM_EDITED:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_SUB_HEADER_VESTING_TERM_EDITED:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_TEMPLATE_VESTING_TERM_EDITED: `
    <label> {{userName}} {{entityAction}} the details of {{entityName}} {{equityContainerName}} in {{companyName}}</label> 
    <ul>
        <li>The details of {{equityContainerName}} in {{companyName}} have been edited by {{userName}} at {{activityTime}} on {{activityDate}}.</li>
    </ul> `,


    MESSAGE_HEADER_VESTING_TERM_DELETED:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_SUB_HEADER_VESTING_TERM_DELETED:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_TEMPLATE_VESTING_TERM_DELETED: `
    <label> {{userName}} {{entityAction}} the {{entityName}} {{equityContainerName}} in {{companyName}}</label> 
    <ul>
        <li>{{equityContainerName}} {{entityName}} has been {{entityAction}} in {{companyName}} by {{userName}} at {{activityTime}} on {{activityDate}}.</li>
    </ul> `,

    MESSAGE_HEADER_VESTING_TERM_PUBLISHED:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_SUB_HEADER_VESTING_TERM_PUBLISHED:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_TEMPLATE_VESTING_TERM_PUBLISHED: `
    <label> {{userName}} {{entityAction}} the {{entityName}} {{equityContainerName}} for {{companyName}}</label> 
    <ul>
        <li>{{equityContainerName}} has been {{entityAction}} for {{companyName}} by {{userName}} at {{activityTime}} on {{activityDate}}.</li>
    </ul> `,


    MESSAGE_HEADER_VESTING_TERM_PUBLISH_AND_SHARING:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_SUB_HEADER_VESTING_TERM_PUBLISH_AND_SHARING:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_TEMPLATE_VESTING_TERM_PUBLISH_AND_SHARING: `
    <label> {{userName}} {{entityAction}} a {{entityName}} for {{companyName}}</label> 
    <ul>
        <li>{{entityName}} has been {{entityAction}} for {{companyName}} by {{userName}} at {{activityTime}} on {{activityDate}}.</li>
    </ul> `,

    MESSAGE_BUTTON_REDIRECT_GO_TO_VESTING_TERM: `View equity details`,
}

export default templates

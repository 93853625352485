import { Field } from "formik";
import _ from "lodash";
import moment from "moment";
import React, { Component } from 'react';
import { ClaraInputDate, TextAreaInput, TextInput,TextType } from '../../../../../../../v1/components';
import ClaraCountrySelector from "../../../../../../../v1/components/Form/ClaraCountrySelector/ClaraCountrySelector";
import ClaraInputSelect from "../../../../../../../v1/components/Form/ClaraInputSelect/ClaraInputSelect";
import ClaraRadioWithButton from '../../../../../../../v1/components/Form/ClaraRadioWithButton/ClaraRadioWithButton';
import ClaraSelect from "../../../../../../../v1/components/Form/ClaraSelect/ClaraSelect";
import ClaraUpload from "../../../../../../../v1/components/Form/ClaraUpload/ClaraUpload";
import Checkbox from "../../../../../../../v1/containers/Forms/Inputs/Checkbox";
import { Constants } from "../../../../../../../v1/utils";
import FormikHelper from "../../../../../../../v1/utils/formikHelper";
import Line from "../../../../../../generic/Line/Line";
import AuthorisedSignatories from "../../../../../../inputs/AuthorizedSignatories/AuthorizedSignatories";
import CheckBox from '../../../../../../inputs/Checkbox/Checkbox';
import classes from "../Styles.module.scss";
import {
	groupCompanyRecipientNotice, stakeholderAuthorizedSignatory, stakeholderRecipientNotice
} from '../../../../../../../v1/containers/Forms/generateDocumentForm/FormsGenerate/helper';
class EmploymentAgreementADGMDoc extends Component {

    constructor(props) {
        super(props);
        this.state = {
            typeSelected: undefined,
            consultantSelected: undefined,
            groupCompanySelected: undefined,
            authorizedSelected: undefined,
            authorizedSignatories: [],
            authorizedSignatoriesList: [],
            documentTypes: [],
            documentTypesList: [],
            dynamicParams: [],
            date: undefined,

        }
    };
    componentWillMount() {
        if (this.props.values) {
            const values = this.props.values.values ? this.props.values.values : this.props.values;

            this.setState({
                typeSelected: values.documentType,
                employeeSelected: values.employee,
                groupCompanySelected: values.company,
                authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.company).map(as => ({
                    id: as.id,
                    label: as.label
                }))
            });
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.values) {
            const values = nextProps.values.values ? nextProps.values.values : nextProps.values;
            this.setState({
                authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.company)
                    .map(as => ({
                        id: as.id,
                        label: as.label
                    }))
            });
        }
    }

    selectType = (documentType, extraParams, form) => {
        this.setState({
            typeSelected: documentType,
            dynamicParams: this.getDynamicParamsByType(documentType)
        }, () => {
            FormikHelper.setValueInTheCorrectPosition('terms', form, []);
            FormikHelper.setValueInTheCorrectPosition('documentType', form, documentType);
            this.state.dynamicParams.forEach((dynamicParam, i) => {
                FormikHelper.setValueInTheCorrectPosition(
                    `terms[${i}].key`,
                    form,
                    dynamicParam.key || 'NO_KEY'
                );
            })
        });
    };

    getBenefitOptionsByType(tooltips){
		const { formType } = this.props;
        const options = {
            "EMPLOYMENT_AGREEMENT_ADGM": [
                {
                    value: 'housingAllowance',
                    label: 'Housing allowance',
                    tooltip: tooltips['Housing Allowance'],
                    renderElement: () => (
                        <Field name='terms.salaryAndBenefits.housingAllowance'>
                            {({ field, form }) => (
                                <div className={classes.InputContainer}>
                                    <label style={{ marginTop: '0.5rem',color:'gray' }}>Amount <span style={{ color: 'red' }}>*</span></label>
                                    <TextInput
                                        placeholder="Amount"
                                        required={true}
                                        rightLabel={this.getDefaultCurrency()}
                                        field={field}
                                        form={form}
                                    />
                                </div>)}
                        </Field>
                    )
                },
                {
                    value: 'transportAllowance',
                    label: 'Transport allowance',
                    tooltip: tooltips['Transport Allowance'],
                    renderElement: () => (
                        <Field name='terms.salaryAndBenefits.transportAllowance'>
                            {({ field, form }) => (
                                <div className={classes.InputContainer}>
                                    <label style={{ marginTop: '0.5rem',color:'gray' }}>Amount <span style={{ color: 'red' }}>*</span></label>
                                    <TextInput
                                        type='text'
                                        field={field}
                                        form={form}
                                        placeholder='Amount'
                                        required={true}
                                        rightLabel={this.getDefaultCurrency()}
                                    />
                                </div>)}
                        </Field>
                    )
                },
                {
                    value: 'discretionaryBonus',
                    label: 'Discretionary bonus',
                    tooltip: tooltips['Bonus']

                },
                {
                    value: 'lifeInsurance',
                    label: 'Life insurance',
                    tooltip: tooltips['Life Insurance'],
                    renderElement: () => (
                        <Field name='terms.salaryAndBenefits.lifeInsurance'>
                            {({ field, form }) => (<div className={classes.InputContainer}>
                                <label style={{ marginTop: '0.5rem',color:'gray' }}>Amount <span style={{ color: 'red' }}>*</span></label>
                                <TextInput
                                    type='text'
                                    field={field}
                                    form={form}
                                    placeholder='Amount'
                                    required={true}
                                    rightLabel={this.getDefaultCurrency()}
                                />
                            </div>)}
                        </Field>
                    )
                },
                {
                    value: 'incomeProtection',
                    label: 'Income protection',
                    tooltip: tooltips['Income Protection']
                },
                {
                    value: 'medicalCare',
                    label: 'Medical Care for family and dependents',
                    tooltip: tooltips['Medical Insurance'],
                    renderElement: () => (
                        <Field name='terms.salaryAndBenefits.medicalCare'>
                            {({ field, form }) => (
                                <div className={`${classes.SelectContainer}`}>
                                    <label style={{ marginTop: '0.5rem', marginBottom: '1rem', color:'gray' }}>Family or dependents <span style={{ color: 'red' }}>*</span></label>
                                    <ClaraSelect
                                        field={field}
                                        form={form}
                                        mode={'classic'}
                                        placeholder={'Select from the list'}
                                        lists={[
                                            {
                                                id: 'family',
                                                label: 'Family',
                                            },
                                            {
                                                id: 'dependents',
                                                label: 'Dependents',
                                            }

                                        ]}
                                    />
                                </div>
                            )}
                        </Field>
                    )
                }
            ],
            "EMPLOYMENT_AGREEMENT_DIFC": [
                {
                    value: 'housingAllowance',
                    label: 'Housing allowance',
                    tooltip: tooltips['Housing Allowance'],
                    renderElement: () => (
                        <Field name='terms.salaryAndBenefits.housingAllowance'>
                            {({ field, form }) => (
                                <div className={classes.InputContainer}>
                                    <label style={{ marginTop: '0.5rem',color:'gray' }}>Amount <span style={{ color: 'red' }}>*</span></label>
                                    <TextInput
                                        placeholder="Amount"
                                        required={true}
                                        rightLabel={this.getDefaultCurrency()}
                                        field={field}
                                        form={form}
                                    />
                                </div>)}
                        </Field>
                    )
                },
                {
                    value: 'transportAllowance',
                    label: 'Transport allowance',
                    tooltip: tooltips['Transport Allowance'],
                    renderElement: () => (
                        <Field name='terms.salaryAndBenefits.transportAllowance'>
                            {({ field, form }) => (
                                <div className={classes.InputContainer}>
                                    <label style={{ marginTop: '0.5rem',color:'gray' }}>Amount <span style={{ color: 'red' }}>*</span></label>
                                    <TextInput
                                        type='text'
                                        field={field}
                                        form={form}
                                        placeholder='Amount'
                                        required={true}
                                        rightLabel={this.getDefaultCurrency()}
                                    />
                                </div>)}
                        </Field>
                    )
                },
                {
                    value: 'discretionaryBonus',
                    label: 'Discretionary bonus',
                    tooltip: tooltips['Bonus']

                },
                {
                    value: 'lifeInsurance',
                    label: 'Life insurance',
                    tooltip: tooltips['Life Insurance'],
                    renderElement: () => (
                        <Field name='terms.salaryAndBenefits.lifeInsurance'>
                            {({ field, form }) => (<div className={classes.InputContainer}>
                                <label style={{ marginTop: '0.5rem',color:'gray' }}>Amount <span style={{ color: 'red' }}>*</span></label>
                                <TextInput
                                    type='text'
                                    field={field}
                                    form={form}
                                    placeholder='Amount'
                                    required={true}
                                    rightLabel={this.getDefaultCurrency()}
                                />
                            </div>)}
                        </Field>
                    )
                },
                {
                    value: 'incomeProtection',
                    label: 'Income protection',
                    tooltip: tooltips['Income Protection']
                },
                {
                    value: 'medicalCare',
                    label: 'Medical care for family and dependents',
                    tooltip: tooltips['Medical Insurance'],
                    renderElement: () => (
                        <Field name='terms.salaryAndBenefits.medicalCare'>
                            {({ field, form }) => (
                                <div className={`${classes.SelectContainer}`}>
                                    <label style={{ marginTop: '0.5rem', marginBottom: '1rem',color:'gray' }}>Family or dependents <span style={{ color: 'red' }}>*</span></label>
                                    <ClaraSelect
                                        field={field}
                                        form={form}
                                        mode={'classic'}
                                        placeholder={'Select from the list'}
                                        lists={[
                                            {
                                                id: 'family',
                                                label: 'Family',
                                            },
                                            {
                                                id: 'dependents',
                                                label: 'Dependents',
                                            }

                                        ]}
                                    />
                                </div>
                            )}
                        </Field>
                    )
                }
            ],
            "EMPLOYMENT_AGREEMENT_UK":[
                {
                    value: 'discretionaryBonus',
                    label: 'Discretionary bonus',
                    tooltip: tooltips['Bonus']

                },
                {
                    value: 'lifeInsurance',
                    label: 'Life insurance',
                    tooltip: tooltips['Life Insurance'],
                    renderElement: () => (
                        <Field name='terms.salaryAndBenefits.lifeInsurance'>
                            {({ field, form }) => (<div className={classes.InputContainer}>
                                <label style={{ marginTop: '0.5rem',color:'gray' }}>Amount <span style={{ color: 'red' }}>*</span></label>
                                <TextInput
                                    type='text'
                                    field={field}
                                    form={form}
                                    placeholder='Amount'
                                    required={true}
                                    rightLabel={this.getDefaultCurrency()}
                                />
                            </div>)}
                        </Field>
                    )
                },
                {
                    value: 'incomeProtection',
                    label: 'Income protection',
                    tooltip: tooltips['Income Protection']
                },
                {
                    value: 'medicalCare',
                    label: 'Medical care',
                    tooltip: tooltips['Medical Insurance'],
                    renderElement: () => (
                        <Field name='terms.salaryAndBenefits.medicalCare'>
                            {({ field, form }) => (
                                <div className={`${classes.SelectContainer}`}>
                                    <label style={{ marginTop: '0.5rem', marginBottom: '1rem',color:'gray' }}>Include dependants? <span style={{ color: 'red' }}>*</span></label>
                                    <ClaraRadioWithButton
                                            required={true}
                                            field={field}
                                            form={form}
                                            changeParams={field.name}
                                            options={[{ value: "true", label: 'Yes' }, { value: "false", label: 'No' }]}
                                        // onChange={(v) => { onChange(v,form) }}
                                        />
                                </div>
                            )}
                        </Field>
                    )
                }
            ]
        }
        return options[formType] || options["EMPLOYMENT_AGREEMENT_ADGM"]
    }

    getDynamicParamsByType(documentType) {
        const type = this.props.documentTypes.filter(agrType => agrType.code === documentType)[0] || {};
        return type.fields || [];
    }

    getGroupCompanies = () => {
        let authorizedSignatories = this.props.authorizedSignatories;
        let groupCompanies = this.props.groupCompanies;
        let companies = [];
        if (groupCompanies && authorizedSignatories) {
            companies = _.filter(groupCompanies, (groupCompany) => {
                let fined = _.filter(authorizedSignatories, (authorized) => {
                    if (groupCompany.authorizedSignatories) {
                        return groupCompany.authorizedSignatories.some(au => au.stakeholder.id === authorized.id);
                    }
                    return false;
                });
                if (fined) {
                    return true;
                }
                return false;
            });
        }
        return companies;
    };

    handleAuthorizedSignatoryUpdate = (value) => {



    };

    selectGroupCompany = (gcId, extraParams, form) => {
        const groupCompany = this.findGroupCompany(gcId);
        this.setState({
            groupCompanySelected: groupCompany
        });

        if (groupCompany) {

            if (!groupCompany.registeredAddress) {
                groupCompany.registeredAddress = {}
            }
            let authorizedSignatories = _.get(form, 'values.company.authorizedSignatories', []);
 
            const newFormValues = {
                ...form.values,
                company: {
                    id: gcId,
                    name: groupCompany.name || '',
                    country: _.get(groupCompany, 'country.code', null) || _.get(groupCompany, 'country', null),
                    jurisdiction: _.get(groupCompany, 'jurisdiction'),
                    registeredNumber: groupCompany.registeredNumber || '',
                    type: groupCompany.type || '',
                    registeredAddress: {
                        ...groupCompany.registeredAddress, country: _.get(groupCompany, 'registeredAddress.country.code', null) || _.get(groupCompany, 'registeredAddress.country', null)
                    },
                    authorizedSignatories: authorizedSignatories,
					recipientNotice: groupCompanyRecipientNotice(form.values.company, groupCompany)
                },
                terms: {
                    ...(form.values.terms ? {
                        ...form.values.terms,
                        positionAndLocation: {
                            ...form.values.terms.positionAndLocation,
                        }
                    }
                        : {
                            positionAndLocation: {
                                workingAddress: {
                                    country: _.get(groupCompany, 'registeredAddress.country.code', null) || _.get(groupCompany, 'registeredAddress.country', null),
                                    street: _.get(groupCompany, 'registeredAddress.street', ''),
                                    city: _.get(groupCompany, 'registeredAddress.city', ''),
                                    state: _.get(groupCompany, 'registeredAddress.state', ''),
                                    zipCode: _.get(groupCompany, 'registeredAddress.zipCode', '')
                                }
                            }
                        })
                }
            };

            form.setValues(newFormValues);

            this.setState({
                groupCompanySelected: groupCompany,
            })
        } else {
            form.setValues({
                ...form.values,
                company: {}
            });
        }
    };

    findGroupCompany(gcId) {
        return this.props.groupCompaniesList.find(gc => gc.id === gcId);
    };

    renderNewRegister(tooltips) {
        return (
            <div className={`${classes.ContainerRow}`}>
                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                    <Field name='company.registeredAddress.country'>
                        {({ field, form }) => (
                            <ClaraInputSelect
                                required={true}
                                label={'Registered address '}
                                field={field}
                                form={form}
                                placeholder={'Select from the list'}
                                tooltipText={'Country for registered address of the company'}
                                updateListFunction={null}
                                callbackUpdate={null}
                                decorator={null}
                                list={Constants.COUNTRIES}
                                areCountries={true}
                                search={true}
                                modalTitle={null}
                                tooltip={tooltips["Company's Registered Address - Country"]}
                            />
                        )}
                    </Field>
                </div>
                <div className={classes.ContainerRow}>
                    <Field name='company.registeredAddress.street'>
                        {({ field, form }) => (
                            <TextAreaInput
                                type='text'
                                label={' '}
                                field={field}
                                form={form}
                                placeholder={"Address"}
                                tooltip={tooltips["Company's Registered Address - Address"]}
                            />
                        )}
                    </Field>
                </div>
                <Field name='company.registeredAddress.city'>
                    {({ field, form }) => (
                        <TextInput
                            classNameInput={{ root: classes.ContainerRow }}
                            type='text'
                            label=' '
                            field={field}
                            form={form}
                            placeholder={'City'}
                            tooltip={tooltips["Company's Registered Address - City"]}
                        />
                    )}
                </Field>
                <Field name='company.registeredAddress.state'>
                    {({ field, form }) => (
                        <TextInput
                            classNameInput={{ root: classes.ContainerRow }}
                            type='text'
                            label=' '
                            field={field}
                            form={form}
                            placeholder={'State / Region'}
                            tooltip={tooltips["Company's Registered Address - State"]}
                        />
                    )}
                </Field>
                <Field name='company.registeredAddress.zipCode'>
                    {({ field, form }) => (
                        <TextInput
                            type='text'
                            label=' '
                            classNameInput={{ root: classes.ContainerRow }}
                            field={field}
                            form={form}
                            placeholder={"Post code"}
                            tooltip={tooltips["Company's Registered Address - Post Code"]}
                        />
                    )}
                </Field>
            </div>)
    }

    selectEmployee = (employee, extraParams, form, cb) => {
        const stakeholder = this.findStakeholder(employee);

        this.setState({
            employeeSelected: stakeholder,
        });


        if (stakeholder) {
            let authorizedSignatory = {};

            if (!stakeholder.address) {
                _.set(stakeholder,'address', {})
            }

            const _employee = !_.isEmpty(form.values.employee) ? form.values.employee : stakeholder;

            if (stakeholder.isAnEntity) {
				authorizedSignatory = stakeholderAuthorizedSignatory(_employee);
                // authorizedSignatory = {
                //     fullName: typeof _employee.authorizedSignatory === 'object'
                //         ? _.get(_employee, 'authorizedSignatory.fullName', '')
                //         : _.get(_employee, 'authorizedSignatory', ''),
                //     email: _employee.email || ''
                // }
            }

            form.setValues({
                ...form.values,
                employee: {
                    id: employee,
                    fullName: _employee.fullName || '',
                    email: _employee.email || '',
                    passportNumber: _employee.passportNumber || '',
                    registeredNumber: _employee.registeredNumber || '',
                    nationality: _.get(_employee, 'nationality.code', null) || _.get(_employee, 'nationality', null),
                    jurisdiction: _employee.jurisdiction,
                    otherJurisdiction: _.get(_employee, 'otherJurisdiction', ''),
                    type: _employee.type || '',
                    address: {
                        ..._employee.address,
                        country: _.get(_employee, 'address.country.code', null) || _.get(_employee, 'address.country', null)
                    },
                    isAnEntity: _employee.isAnEntity,
                    authorizedSignatory: authorizedSignatory,
					recipientNotice: stakeholderRecipientNotice(form.values.employee, stakeholder)
                }
            });
        } else {
            form.setValues({
                ...form.values,
                employee: {}
            });
        }
    };

    findStakeholder(roleId) {
        return this.props.stakeholderList.find(stakeholder => stakeholder.id === roleId);
    };

    findStakeholderAuthorized(roleId) {
        return this.props.stakeholderList.filter(stakeholder => {
            return stakeholder.roles.some(role => role.stakeholder.id === roleId);
        })[0] || null;
    };

    filterAuthorizedByGroupCompany(groupCompany) {
        if (groupCompany) {
            let gc = this.findGroupCompany(groupCompany.id);
            return this.props.authorizedSignatories.filter(authorized => {
                return authorized.groupCompanyId === gc.id
            });
        }
        return [];
    }

    selectAuthorized = (value, form, field, replace) => {
        const stakeholder = this.props.authorizedSignatories.find(e => e.id === value);
        const email = stakeholder ? stakeholder.email : '';
        replace(0, { id: value, email })
    };

    renderEmployeeAuthorizedSignatory(field) {
        const { tooltips = {} } = this.props;

        if (this.state.employeeSelected.isAnEntity) {
            return (
                <React.Fragment>
                    <Field name='employee.authorizedSignatory.fullName'>
                        {({ field, form }) => (
                            <TextInput
                                classNameInput={{ root: classes.ContainerRow }}
                                required={true}
                                type='text'
                                label='Corporate signatory '
                                field={field}
                                form={form}
                                placeholder={"Corporate signatory"}
                                tooltip={tooltips["Authorised Signatory"]}
                            />
                        )}
                    </Field>
                    <Field name='employee.email'>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                classNameInput={{ root: classes.ContainerRow }}
                                type='email'
                                label="Corporate signatory's email address "
                                field={field}
                                form={form}
                                onChange={(e) => this.updateEmailValue(e, form)}
                                placeholder={"Email address"}
                                tooltip={tooltips['Authorised Signatory Email']}
                            />
                        )}
                    </Field>
                </React.Fragment>
            )
        }
    };

    updateEmailValue(e, form) {
        if (this.state.employeeSelected.isAnEntity) {
            form.setFieldValue('employee.authorizedSignatory.email', e.target.value, false)
        }
    }

    getDateForPickerFromData(date) {
        if (!date) return undefined;
        return moment(date).toDate();
    }

    handleChange(e, field, form) {

        const date = e === null ? null : moment(e).format("YYYY-MM-DD");

        FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

        this.setState({
            [field.name]: e
        })
    }

    getAuthorizedSignatoriesList = (index, form) => {
        if (form.values && form.values.company) {
            return this.filterAuthorizedByGroupCompany(form.values.company).map(as => ({
                id: as.id,
                label: as.label
            }))
        } else {
            return [];
        }
    };

    getLabel = (key) => {
        switch (key) {
            case "fullName":
                if (this.state.employeeSelected.isAnEntity) {
                    return "Company name";
                } else {
                    return "Name";
                }
            case "country":
                if (this.state.employeeSelected.isAnEntity) {
                    return "Country of incorporation";
                } else {
                    return "Nationality";
                }
            case "address":
                if (this.state.employeeSelected.isAnEntity) {
                    return "Registered address";
                } else {
                    return "Residential address";
                }
            case "passportOrRegistered":
                if (this.state.employeeSelected.isAnEntity) {
                    return "Company number";
                } else {
                    return "Passport number";
                }
        }
    };

    getField = (key) => {
        switch (key) {
            case "passportOrRegistered":
                if (this.state.employeeSelected.isAnEntity) {
                    return "employee.registeredNumber";
                } else {
                    return "employee.passportNumber";
                }
        }
    };

    getTooltip = (key, fieldName) => {
        switch (key) {
            case "fullName":
                if (this.state.employeeSelected.isAnEntity) {
                    return "Employee Company's Name";
                } else {
                    return "Employee's Name";
                }
            case "passportOrRegistered":
                if (this.state.employeeSelected.isAnEntity) {
                    return "Company Number";
                } else {
                    return "Passport Number";
                }
            case "country":
                if (this.state.employeeSelected.isAnEntity) {
                    return "Country";
                } else {
                    return "Nationality";
                }
            case "addressLine":
                if (this.state.employeeSelected.isAnEntity) {
                    return "Registered Address - Address";
                } else {
                    return "Residential Address - Address";
                }
            case "addressCity":
                if (this.state.employeeSelected.isAnEntity) {
                    return "Registered Address - City";
                } else {
                    return "Residential Address - City";
                }
            case "addressState":
                if (this.state.employeeSelected.isAnEntity) {
                    return "Registered Address - State";
                } else {
                    return "Residential Address - State";
                }
            case "addressCountry":
                if (this.state.employeeSelected.isAnEntity) {
                    return "Registered Address - Country";
                } else {
                    return "Residential Address - Country";
                }
            case "addressPostCode":
                if (this.state.employeeSelected.isAnEntity) {
                    return "Registered Address - Post Code";
                } else {
                    return "Residential Address - Post Code";
                }
            case "recipientNotice":
                if (fieldName === 'company') {
                    return "Company's";
                } else {
                    return "Employee's";
                }
        }
    };

    getPlaceholder = (key) => {
        switch (key) {
            case "passportOrRegistered":
                if (this.state.employeeSelected.isAnEntity) {
                    return "Company number";
                } else {
                    return "Passport number";
                }
        }
    };

    renderRecipientNotice(fieldName, isAnEntity) {
        const { tooltips = {} } = this.props;

        if (isAnEntity) {
            return (
                <React.Fragment>
                    <Field name={`${fieldName}.recipientNotice.fullName`}>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                classNameInput={{ root: classes.ContainerRow }}
                                type='text'
                                label='Recipient for notices '
                                field={field}
                                form={form}
                                placeholder={"Recipient's name"}
                                tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices"]}
                            />
                        )}
                    </Field>
                    <div className={`${classes.Select}`}>
                        <Field name={`${fieldName}.recipientNotice.address.country`}>
                            {({ field, form }) => (
                                <ClaraCountrySelector
                                    isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                    jurisdictionsEnabled={false}
                                    countryField={`${fieldName}.recipientNotice.address.country`}
                                    label={"Recipient's address "}
                                    setFieldValue={form.setFieldValue}
                                    initialValues={{ country: _.get(form.values, `${fieldName}.recipientNotice.address.country`, null) }}
                                    returnCountryAsString={_.isEmpty(_.get(form.values, `${fieldName}.recipientNotice.address.country.code`, null))}
                                    additionalStyle={'ClaraCountrySelectorModal'}
                                    tooltips={{
                                        country: tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Country"]
                                    }}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.address.street`}>
                            {({ field, form }) => (
                                <TextAreaInput
                                    type='text'
                                    label={' '}
                                    field={field}
                                    form={form} placeholder={"Address"}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Address"]}
                                />
                            )}
                        </Field>
                    </div>
                    <Field name={`${fieldName}.recipientNotice.address.city`}>
                        {({ field, form }) => (
                            <TextInput
                                classNameInput={{ root: classes.ContainerRow }}
                                type='text'
                                field={field}
                                form={form}
                                placeholder={"City"}
                                label={' '}
                                tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - City"]}
                            />
                        )}
                    </Field>
                    <Field name={`${fieldName}.recipientNotice.address.state`}>
                        {({ field, form }) => (
                            <TextInput
                                type='text'
                                classNameInput={{ root: classes.ContainerRow }}
                                field={field}
                                form={form}
                                placeholder={"State / Region"}
                                label={' '}
                                tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - State"]}
                            />
                        )}
                    </Field>
                    <Field name={`${fieldName}.recipientNotice.address.zipCode`}>
                        {({ field, form }) => (
                            <TextInput
                                classNameInput={{ root: classes.ContainerRow }}
                                type='text'
                                field={field} form={form}
                                label={' '}
                                placeholder={"Post code"}
                                tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Post Code"]}
                            />

                        )}
                    </Field>
                    <Field name={`${fieldName}.recipientNotice.email`}>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                classNameInput={{ root: classes.ContainerRow }}
                                type='email'
                                label="Recipient's email address "
                                field={field}
                                form={form}
                                placeholder={"Email address"}
                                tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Email Address"]}
                            />
                        )}
                    </Field>
                </React.Fragment>
            )
        }
    };

    renderAgreementDurationFields(form) {
        const { tooltips = {} } = this.props;
        if (!_.get(form, 'values.terms.agreementDuration')) {
            return null
        }

        return (
            <React.Fragment>
                {
                    form.values.terms.agreementDuration === 'otherDuration' ?
                        <div className={classes.ContainerRow}>
                            <Field name='terms.otherDuration'>
                                {({ field, form }) => (
                                    <ClaraInputDate
                                        required={true}
                                        field={field}
                                        form={form}
                                        label={'Duration '}
                                        tooltips={tooltips}

                                    />
                                )}
                            </Field>

                        </div>
                        : null
                }
            </React.Fragment>
        );

    }

    getStakeholderAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add new individual stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
                placeholder: "Stakeholder's name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: false,
                    // roles : [{
                    // 	role:"SHAREHOLDER",
                    // 	groupCompany: this.state.groupCompanySelected.id,
                    // }]
                },
                paramsReturn: 'isAnEntity'
            },
        ]
    };

    getCompanyAdds = () => {
        return [{
            type: "groupCompany",
            label: "Add new company",
            textWarning: "This will create a new group company for your startup. You can add or amend the details of this group company on its profile page.",
            placeholder: "Company name",
            startupId: this.props.startup.id,
            params: null,
            paramsReturn: `
				name
				authorizedSignatories {
					id
					stakeholder {
						id
						email
						fullName
						roles {   id
        name
        groupCompany { id name}
        stakeholder { id } }
					}
				}
			`
        }]
    };

    getAuthorizedSignatoryAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add new individual stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
                placeholder: "Enter stakeholders name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: false,
                    roles: [{
                        role: "DIRECTOR",
                        groupCompany: this.state.groupCompanySelected.id,
                    }]
                }
            },
        ]
    };

    handleStakeholderCreate = (value, name, additionalValue, form) => {

        let { stakeholders, stakeholderList } = this.props;
        let newSh = { id: value.id, fullName: name, ...additionalValue };
        stakeholderList.push(newSh);

        stakeholders.push({ id: value.id, label: name });
        // this.setState({ groupCompaniesList, groupCompanies });

    };

    handleGroupCompanyCreate = (value, name, additionalValue, form) => {

        let { groupCompanies, groupCompaniesList } = this.props;
        let newGc = { id: value.id, name, ...additionalValue };
        groupCompaniesList.push(newGc);

        groupCompanies.push({ id: value.id, label: name });
        // this.setState({ groupCompaniesList, groupCompanies });

    };

    handleAuthorizedSignatoryCreate = (value, name, additionalValue, form) => {

        let { authorizedSignatories } = this.props;
        const { groupCompanySelected } = this.state;

        authorizedSignatories.push({
            id: value.id,
            label: name,
            role: 'DIRECTOR',
            groupCompanyId: groupCompanySelected.id
        });

    };

	getDefaultCurrency = () => {
		const { formType } = this.props;

		switch (formType) {
			case "EMPLOYMENT_AGREEMENT_ADGM":
			case "EMPLOYMENT_AGREEMENT_DIFC":
				return 'درهم(AED)';
			case "EMPLOYMENT_AGREEMENT_UK":
				return '£(GBP)';
			default:
				return '$(USD)';
		}
	};

	render() {
        const isRequired = message => value => (!!value ? undefined : message);
        const { tooltips = {}, formType } = this.props;

        return (
            <React.Fragment>
                <h1 className={classes.title}>
                    Edit Document
                </h1>
                {formType === 'uploadToEdit' ? (<div>
                    <Field name={`file`}>
                        {({ field, form }) => (
                            <ClaraUpload
                                required={true}
                                name={`filepond`}
                                label='Upload your document '
                                field={field}
                                form={form}
                            />
                        )}
                    </Field>
                </div>) : null}
                <Field name='agreementName'>
                    {({ field, form }) => (
                        <TextInput
                            classNameInput={{ root: classes.ContainerRow }}
                            required={true}
                            type='text'
                            label='Document name '
                            field={field}
                            form={form}
                            placeholder={"Document name"}
                            tooltip={tooltips['Document Name']}
                        />
                    )}
                </Field>

                <Line className={classes.line} />
                <div className={classes.ZoneTitle}>
                    <h1>{'Employer'}</h1>
                </div>
                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                    <Field name='company.id'>
                        {({ field, form }) => (
                            <ClaraSelect
                                required={true}

                                field={field}
                                form={form}
                                label={" Employer "}
                                lists={this.getGroupCompanies()}
                                mode={"classic"}
                                callbacks={{
                                    createValues: this.handleGroupCompanyCreate,
                                    updateValues: this.selectGroupCompany
                                }}
                                placeholder={"Select from the list"}
                                tooltip={tooltips['Select Company']}
                            />
                        )}
                    </Field>
                </div>
                {
                    this.state.groupCompanySelected ?
                        <React.Fragment>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Employer Details</h1>
                                <p>Please verify or update the employer's information below</p>
                            </div>
                            <Field name='company.name'>
                                {({ field, form }) => (
                                    <TextInput
                                        classNameInput={{ root: classes.ContainerRow }}
                                        required={true}
                                        type='text'
                                        label='Company name '
                                        field={field}
                                        form={form}
                                        placeholder={"Company name"}
                                        tooltip={tooltips["Company's Name"]}
                                    />
                                )}
                            </Field>
                            <div className={` ${classes.Select}`}>
                                <Field name='company.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={true}
                                            countryField={'company.country'}
                                            label={'Country of incorporation '}
                                            jurisdictionField={'company.jurisdiction'}
                                            jurisdictionLabel={'Jurisdiction '}
                                            returnCountryAsString={true}
                                            otherJurisdictionField={'company.otherJurisdiction'}
                                            setFieldValue={form.setFieldValue}
                                            initialValues={{
                                                jurisdiction: _.get(form.values, 'company.jurisdiction')
                                            }}
                                            additionalStyle={'ClaraCountrySelectorModal'}
                                            tooltips={{
                                                country: tooltips["Company's Country"],
                                                jurisdiction: tooltips["Company's Jurisdiction"],
                                                other: tooltips['Other Jurisdiction']
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <Field name='company.type'>
                                {({ field, form }) => (
                                    <TextType
                                        groupCompany={this.state.groupCompanySelected}
                                        required={true}
                                        classNameInput={{ root: classes.ContainerRow }}
                                        type='text'
                                        label='Company type '
                                        field={field}
                                        form={form}
                                        placeholder={'Company type'}
                                        tooltip={tooltips["Company's Company Type"]}
                                    />
                                )}
                            </Field>
                            <Field name='company.registeredNumber'>
                                {({ field, form }) => (
                                    <TextInput
                                        required={true}
                                        type='text'
                                        classNameInput={{ root: classes.ContainerRow }}
                                        label='Company number '
                                        field={field}
                                        form={form}
                                        placeholder={'Company number'}
                                        tooltip={tooltips["Company's Company Number"]}
                                    />
                                )}
                            </Field>
                            <div className={` ${classes.Select}`}>
                                <Field name='company.registeredAddress.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={false}
                                            countryField={'company.registeredAddress.country'}
                                            returnCountryAsString={true}
                                            label={'Registered address '}
                                            setFieldValue={form.setFieldValue}
                                            additionalStyle={'ClaraCountrySelectorModal'}
                                            tooltips={{
                                                country: tooltips["Company's Registered Address - Country"]
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.registeredAddress.street'>
                                    {({ field, form }) => (
                                        <TextAreaInput
                                            type='text'
                                            label={' '}
                                            field={field}
                                            form={form}
                                            placeholder={"Address"}
                                            tooltip={tooltips["Company's Registered Address - Address"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <Field name='company.registeredAddress.city'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        label=' '
                                        classNameInput={{ root: classes.ContainerRow }}
                                        field={field}
                                        form={form}
                                        placeholder={'City'}
                                        tooltip={tooltips["Company's Registered Address - City"]}
                                    />
                                )}
                            </Field>
                            <Field name='company.registeredAddress.state'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        label=' '
                                        classNameInput={{ root: classes.ContainerRow }}
                                        field={field}
                                        form={form}
                                        placeholder={'State / Region'}
                                        tooltip={tooltips["Company's Registered Address - State"]}
                                    />
                                )}
                            </Field>
                            <Field name='company.registeredAddress.zipCode'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        label=' '
                                        classNameInput={{ root: classes.ContainerRow }}
                                        field={field}
                                        form={form}
                                        placeholder={"Post code"}
                                        tooltip={tooltips["Company's Registered Address - Post Code"]}
                                    />
                                )}
                            </Field>
                            {this.renderRecipientNotice('company', true)}
                            <Line className={classes.line} />
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Corporate Signatories</h1>
                                <p>Please add all the signatories required to sign this document on behalf of the company.</p>
                            </div>
                            <Field name='company.authorizedSignatories_error'>
                                {({ field, form }) => (
                                    <React.Fragment>
                                        <AuthorisedSignatories
                                            list={this.props.stakeholderList}
                                            fieldArrayName={'company.authorizedSignatories'}
                                            idGroupCompany={this.state.groupCompanySelected.id}
                                            handleAddListStakeholder={this.handleAuthorizedSignatoryUpdate}
                                            modal={true}
                                            errorMessage={_.get(form.errors, "adviceAreasFieldName")}
                                        />
                                    </React.Fragment>
                                )}
                            </Field>
                        </React.Fragment>
                        :
                        null
                }
                <Line className={classes.line} />
                <div className={classes.ZoneTitle}>
                    <h1>{'Employee'}</h1>
                </div>
                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                    <Field name='employee.id'>
                        {({ field, form }) => (
                            <ClaraSelect
                                required={true}
                                field={field}
                                form={form}
                                label={"Employee "}
                                lists={this.props.stakeholders}
                                adds={this.getStakeholderAdds()}
                                mode={"classic"}
                                callbacks={{
                                    updateValues: this.selectEmployee,
                                    createValues: this.handleStakeholderCreate
                                }}
                                placeholder={"Select from the list"}
                                tooltip={tooltips["Select Employee"]}
                            />

                        )}
                    </Field>
                </div>
                {
                    this.state.employeeSelected ?
                        <React.Fragment>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                {
                                    this.state.employeeSelected.isAnEntity ?
                                        <>
                                            <h1>Employee Company Details</h1>
                                            <p>Please verify or update the employee's company's information below</p>
                                        </>
                                        :
                                        <>
                                            <h1>Employee Details</h1>
                                            <p>Please verify or update the employee’s information below</p>
                                        </>
                                }
                            </div>

                            <Field name='employee.fullName'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        label={this.getLabel("fullName") + ' '}
                                        field={field}
                                        classNameInput={{ root: classes.ContainerRow }}
                                        form={form}
                                        placeholder={'Name'}
                                        required={true}
                                        tooltip={tooltips[this.getTooltip('fullName')]}
                                    />
                                )}
                            </Field>
                            <div className={` ${classes.Select}`}>
                                <Field name='employee.nationality'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={this.state.employeeSelected.isAnEntity}
                                            countryField={'employee.nationality'}
                                            label={this.getLabel('country') + ''}
                                            jurisdictionField={'employee.jurisdiction'}
                                            jurisdictionLabel={'Jurisdiction '}
                                            otherJurisdictionField={'employee.otherJurisdiction'}
                                            setFieldValue={form.setFieldValue}
                                            initialValues={{
                                                jurisdiction: _.get(form.values, 'employee.jurisdiction'),
                                                // country:_.get(form.values,'employee.nationality')
                                            }}
                                            returnCountryAsString={true}
                                            additionalStyle={'ClaraCountrySelectorModal'}
                                            tooltips={{
                                                country: tooltips[this.getTooltip('country')],
                                                jurisdiction: tooltips['Jurisdiction'],
                                                other: tooltips['Other Jurisdiction']
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            {
                                this.state.employeeSelected.isAnEntity ?
                                    <React.Fragment>
                                        <Field name='employee.type'>
                                            {({ field, form }) => (
                                                <TextInput
                                                    classNameInput={{ root: classes.ContainerRow }}
                                                    required={true}
                                                    type='text'
                                                    label='Company type '
                                                    field={field}
                                                    form={form}
                                                    placeholder={'Company type'}
                                                    tooltip={tooltips['Company Type']}
                                                />
                                            )}
                                        </Field>
                                    </React.Fragment>
                                    :
                                    null
                            }
                            <Field name={this.getField("passportOrRegistered")}>
                                {({ field, form }) => (
                                    <TextInput
                                        required={true}
                                        classNameInput={{ root: classes.ContainerRow }}
                                        type='text'
                                        label={this.getLabel("passportOrRegistered") + ' '}
                                        field={field}
                                        form={form}
                                        placeholder={this.getPlaceholder("passportOrRegistered")}
                                        tooltip={tooltips[this.getTooltip("passportOrRegistered")]}
                                    />
                                )}
                            </Field>
                            {
                                !this.state.employeeSelected.isAnEntity ?
                                    <React.Fragment>
                                        <Field name='employee.email'>
                                            {({ field, form }) => (
                                                <TextInput
                                                    type='email'
                                                    classNameInput={{ root: classes.ContainerRow }}
                                                    label='Email address '
                                                    field={field}
                                                    form={form}
                                                    placeholder={'Email address'}
                                                    required={true}
                                                    tooltip={tooltips["Employee's Email Address"]}
                                                />
                                            )}
                                        </Field>
                                    </React.Fragment>
                                    :
                                    null
                            }
                            <div className={` ${classes.Select}`}>
                                <Field name='employee.address.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={false}
                                            countryField={'employee.address.country'}
                                            label={this.getLabel('address') + ''}
                                            setFieldValue={form.setFieldValue}
                                            returnCountryAsString={true}
                                            additionalStyle={'ClaraCountrySelectorModal'}
                                            tooltips={{
                                                country: tooltips[this.getTooltip('addressCountry')]
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='employee.address.street'>
                                    {({ field, form }) => (
                                        <TextAreaInput
                                            type='text'
                                            label={' '}
                                            field={field}
                                            form={form}
                                            placeholder={'Address'}
                                            tooltip={tooltips[this.getTooltip('addressLine')]}

                                        />
                                    )}
                                </Field>
                            </div>
                            <Field name='employee.address.city'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        label=' '
                                        classNameInput={{ root: classes.ContainerRow }}
                                        field={field}
                                        form={form}
                                        placeholder={'City'}
                                        tooltip={tooltips[this.getTooltip('addressCity')]}
                                    />
                                )}
                            </Field>
                            <Field name='employee.address.state'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        label=' '
                                        classNameInput={{ root: classes.ContainerRow }}
                                        field={field}
                                        form={form}
                                        placeholder={'State / Region'}
                                        tooltip={tooltips[this.getTooltip('addressState')]}
                                    />
                                )}
                            </Field>
                            <Field name='employee.address.zipCode'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        label=' '
                                        classNameInput={{ root: classes.ContainerRow }}
                                        field={field}
                                        form={form}
                                        placeholder={"Post code"}
                                        tooltip={tooltips[this.getTooltip('addressPostCode')]}
                                    />
                                )}
                            </Field>
                            {this.renderRecipientNotice('employee', this.state.employeeSelected.isAnEntity)}
                            {this.renderEmployeeAuthorizedSignatory('employee')}
                        </React.Fragment>
                        :
                        null
                }
                {
                    this.state.employeeSelected && this.state.groupCompanySelected ?
                        <React.Fragment>
                            <Line className={classes.line} />
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Position and Location</h1>
                                <p>The employee’s job title and primary location when working for your startup.</p>
                            </div>
                            <Field name='terms.positionAndLocation.position'>
                                {({ field, form }) => (
                                    <TextInput
                                        required={true}
                                        type='text'
                                        classNameInput={{ root: classes.ContainerRow }}
                                        label='Position '
                                        field={field}
                                        form={form}
                                        placeholder={'Job Title'}
                                        tooltip={tooltips['Job Title - POSITION']}
                                    />
                                )}
                            </Field>
                            <div className={classes.ContainerRow}>
                                <Field>
                                    {({ field, form }) => (
                                            <>
                                                <div className={` ${classes.Select}`}>
                                                    <Field name='terms.positionAndLocation.workingAddress.country'>
                                                        {({ field, form }) => (
                                                            <ClaraCountrySelector
                                                                isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                                                jurisdictionsEnabled={false}
                                                                required={true}
                                                                returnCountryAsString={true}
                                                                countryField={'terms.positionAndLocation.workingAddress.country'}
                                                                label={'Address '}
                                                                setFieldValue={form.setFieldValue}
                                                                additionalStyle={'ClaraCountrySelectorModal'}
                                                            />
                                                        )}
                                                    </Field>
                                                </div>
                                                <div className={classes.ContainerRow}>
                                                    <Field name='terms.positionAndLocation.workingAddress.street'>
                                                        {({ field, form }) => (
                                                            <TextAreaInput
                                                                type='text'
                                                                label={' '}
                                                                field={field}
                                                                form={form}
                                                                placeholder={"Address"}
                                                            />
                                                        )}
                                                    </Field>
                                                </div>
                                                <div className={classes.ContainerRow}>
                                                    <Field name='terms.positionAndLocation.workingAddress.city'>
                                                        {({ field, form }) => (
                                                            <TextInput
                                                                type='text'
                                                                label=' '
                                                                field={field}
                                                                form={form}
                                                                placeholder={'City'}
                                                            />
                                                        )}
                                                    </Field>
                                                </div>
                                                <div className={classes.ContainerRow}>
                                                    <Field name='terms.positionAndLocation.workingAddress.state'>
                                                        {({ field, form }) => (
                                                            <TextInput
                                                                type='text'
                                                                label=' '
                                                                field={field}
                                                                form={form}
                                                                placeholder={'State / Region'}
                                                            />
                                                        )}
                                                    </Field>
                                                </div>
                                                <div className={classes.ContainerRow}>
                                                    <Field name='terms.positionAndLocation.workingAddress.zipCode'>
                                                        {({ field, form }) => (
                                                            <TextInput
                                                                type='text'
                                                                label=' '
                                                                field={field}
                                                                form={form}
                                                                placeholder={"Post code"}
                                                            />
                                                        )}
                                                    </Field>
                                                </div>
                                            </>
                                    )}
                                </Field>
                            </div>
                            
                            <div className={` ${classes.Select}`}>
                                <Field name={`terms.positionAndLocation.homeCountry`}>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            jurisdictionsEnabled={false}
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            countryField={field.name}
                                            returnCountryAsString={true}
                                            initialValues={{ country: _.get(form.values, 'employee.nationality', null) }}
                                            label={'Home country '}
                                            required={true}
                                            setFieldValue={form.setFieldValue}
                                            additionalStyle={'ClaraCountrySelectorModal'}
                                            tooltips={{ country: tooltips['Home Country'] }}
                                        />
                                    )}
                                </Field>
                            </div>

                            <Line className={classes.line} />
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Hours</h1>
                                <p>The employee's hours of work and overtime arrangement.</p>
                            </div>
                            <Field name='terms.workTime.start'>
                                {({ field, form }) => (
                                    <TextInput
                                        required={true}
                                        type='time'
                                        label='Start time '
                                        field={field}
                                        form={form}
                                        classNameInput={{ input: classes.Calendars, root: classes.labelCalendar }}
                                        placeholder={'hh:mm'}
                                        tooltip={tooltips['Work Time - Start']}
                                    />
                                )}
                            </Field>
                            <Field name='terms.workTime.finish'>
                                {({ field, form }) => (
                                    <TextInput
                                        required={true}
                                        type='time'
                                        label='Finish time '
                                        field={field}
                                        classNameInput={{ input: classes.Calendars, root: classes.labelCalendar }}
                                        form={form}
                                        placeholder={'hh:mm'}
                                        tooltip={tooltips['Work Time - Finish']}
                                    />
                                )}
                            </Field>
                            <div className={classes.alignWithInputDateButton}>
                                <Field name={'terms.workTime.isOvertimePermitted'}>
                                    {({ field, form }) => (
                                        <ClaraRadioWithButton
                                            label={'Overtime permitted? '}
                                            field={field}
                                            required={true}
                                            form={form}
                                            tooltip={tooltips['Overtime Permitted']}
                                            changeParams={field.name}
                                            options={[{ value: true, label: 'Yes' }, { value: false, label: 'No' }]}
                                        // onChange={(v) => { onChange(v,form) }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <Line className={classes.line} />
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Commencement and Probation</h1>
                                <p>The start of employment and any probation period. </p>
                            </div>
                            <Field name='terms.commencementAndProbation.date'>
                                {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='date'
                                            label='Employment commencement date '
                                            field={field}
                                            form={form}
                                            classNameInput={{ input: classes.Calendars, root: classes.labelCalendar }}
                                            placeholder={"dd/mm/yyyy"}
                                        />
                                        
                                )}
                            </Field>
                            <Field name='terms.commencementAndProbation.probationPeriod'>
                                {({ field, form }) => (
                                    <TextInput
                                        classNameInput={{ root: classes.ContainerRow }}
                                        type='text'
                                        label='Probation period '
                                        field={field}
                                        form={form}
                                        placeholder={'e.g 1,2,3,4'}
                                        rightLabel={'Months'}
                                        tooltip={tooltips['Probation Period']}
                                    />
                                )}
                            </Field>
                            <Field name='terms.commencementAndProbation.employerNoticePeriod'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        classNameInput={{ root: classes.ContainerRow }}
                                        label='Employer probation notice period '
                                        field={field}
                                        form={form}
                                        placeholder={'e.g 1,2,3,4'}
                                        rightLabel={'Weeks'}
                                        tooltip={tooltips['Notice Period During Probation - EMPLOYER']}
                                    />
                                )}
                            </Field>
                            <Field name='terms.commencementAndProbation.employeeNoticePeriod'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        label='Employee probation notice period '
                                        field={field}
                                        classNameInput={{ root: classes.ContainerRow }}
                                        form={form}
                                        placeholder={'e.g 1,2,3,4'}
                                        rightLabel={'Weeks'}
                                        tooltip={tooltips['Notice Period During Probation - EMPLOYEE']}
                                    />
                                )}
                            </Field>
                            <Line className={classes.line} />
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Salary and Benefits</h1>
                                <p> The employee's salary and other contractual benefits.</p>
                            </div>
                            <Field name='terms.salaryAndBenefits.annualSalary'>
                                {({ field, form }) => (
                                    <TextInput
                                        required={true}
                                        classNameInput={{ root: classes.ContainerRow }}
                                        type='number'
                                        label='Annual salary '
                                        field={field}
                                        form={form}
                                        rightLabel={this.getDefaultCurrency()}
                                        placeholder={'Amount'}
                                        tooltip={tooltips['Annual Salary']}
                                    />
                                )}
                            </Field>
                            <Field name='terms.salaryAndBenefits.baseSalary'>
                                {({ field, form }) => (
                                    <TextInput
                                        required={true}
                                        type='number'
                                        classNameInput={{ root: classes.ContainerRow }}
                                        label='Base salary '
                                        field={field}
                                        form={form}
                                        rightLabel={this.getDefaultCurrency()}
                                        placeholder={'Amount'}
                                        tooltip={tooltips['Base Salary']}
                                    />
                                )}
                            </Field>
                            <div className={`${classes.labelGroup}`}>
								<label>Benefits & allowances</label>
							</div>
							<Field name='terms.salaryAndBenefits.benefits'>
								{({ field, form }) => (
									<CheckBox
										onChange={(value) => form.setFieldValue(field.name, value)}
										options={this.getBenefitOptionsByType(tooltips)}
										values={field.value}
										className={{ root: `${classes.groupCheckBoxWizard} ${classes.groupCheckboxContainer}`, checkbox: classes.checkBoxWizard }}
									/>
								)}
							</Field>
                            <Line className={classes.line} />
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Annual Leave</h1>
                                <p>The employee's holiday entitlement and the Company's holiday year.</p>
                            </div>
                            <Field name='terms.annualLeave.days'>
                                {({ field, form }) => (
                                    <TextInput
                                        required={true}
                                        type='text'
                                        classNameInput={{ root: classes.ContainerRow }}
                                        label='Annual leave '
                                        field={field}
                                        form={form}
                                        placeholder={'Working days'}
                                        rightLabel={'per year'}
                                        tooltip={tooltips['Annual Leave']}
                                    />
                                )}
                            </Field>
                            <Field name='terms.annualLeave.startDate'>
                                {({ field, form }) => (
                                    <TextInput
                                        required={true}
                                        field={field}
                                        form={form}
                                        type={'date'}
                                        classNameInput={{ input: classes.Calendars, root: classes.labelCalendar }}
                                        placeholder={'dd/mm'}
                                        label={'Annual leave start date '}
                                        calendarInputProps={{
                                            type: 'dayMonth'
                                        }}
                                    />
                                )}
                            </Field>
                            <Line className={classes.line} />
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Termination and Variation</h1>
                                <p>Ending the employment relationship between the employee and the company after the end of the probation period. </p>
                            </div>
                            <Field name='terms.terminationAndVariation.terminationNoticeTime'>
                                {({ field, form }) => (
                                    <TextInput
                                        required={true}
                                        type='text'
                                        label='Termination period'
                                        classNameInput={{ root: classes.ContainerRow }}
                                        field={field}
                                        form={form}
                                        placeholder={'e.g 1,2,3,4'}
                                        rightLabel={'Months'}
                                        tooltip={tooltips['Termination Period']}
                                    />
                                )}
                            </Field>
                            <div className={classes.alignWithInputDateButton}>
                                <Field name={'terms.terminationAndVariation.withoutNoticeAndPayment'}>
                                    {({ field, form }) => (
                                        <ClaraRadioWithButton
                                            label={' Without notice and payment in place of notice? '}
                                            field={field}
                                            form={form}
                                            required={true}
                                            tooltip={tooltips['Lieu Notice']}
                                            changeParams={field.name}
                                            options={[{ value: true, label: 'Yes' }, { value: false, label: 'No' }]}
                                        // onChange={(v) => { onChange(v,form) }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <Field>
                                {({ field, form }) => (
                                    _.get(form, 'values.terms.terminationAndVariation.withoutNoticeAndPayment') ?
                                        <div className={classes.alignWithInputDateButton}>
                                            <Field name={'terms.terminationAndVariation.isPayingContractualNotice'}>
                                                {({ field, form }) => (
                                                    <ClaraRadioWithButton
                                                        label={' Contractual benefits paid in place of notice? '}
                                                        field={field}
                                                        form={form}
                                                        required={true}
                                                        tooltip={tooltips['Contractual Benefits']}
                                                        changeParams={field.name}
                                                        options={[{ value: true, label: 'Yes' }, { value: false, label: 'No' }]}
                                                    // onChange={(v) => { onChange(v,form) }}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                        : null
                                )}
                            </Field>
                            <div className={`${classes.labelGroup} `}>
                                <label>Variations to agreement<span style={{ color: 'red' }}>*</span></label>
							</div>
                                <Field name='terms.terminationAndVariation.variations'>
                                    {({ field, form }) => (
                                        <CheckBox
                                            isMultiple={false}
                                            onChange={(value) => form.setFieldValue(field.name, value)}
                                            options={[
                                                {
                                                    value: 'noticeEmployerToEmployee',
                                                    label: 'Notice from the employer to the employee',
                                                    tooltip: tooltips['Notice']
                                                },
                                                {
                                                    value: 'agreementBetweenParties',
                                                    label: 'Agreement between the parties in writing',
                                                }

                                            ]}
                                            values={field.value}
											className={{ root: `${classes.groupCheckBoxWizard} ${classes.groupCheckboxContainer}`, checkbox: classes.checkBoxWizard }}
                                        />
                                    )}
                                </Field>

                            <Line className={classes.line} />
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Garden Leave</h1>
                                <p>During the notice period, the employee is not required to attend the company's offices.</p>
                                <div className={classes.alignWithInputDateButton}>
                                    <Field name={'terms.gardenLeave.havesGardenLeave'}>
                                        {({ field, form }) => (
                                            <ClaraRadioWithButton
                                                label={'Garden leave required? '}
                                                field={field}
                                                form={form}
                                                required={true}
                                                tooltip={tooltips['Garden Leave']}
                                                changeParams={field.name}
                                                options={[{ value: true, label: 'Yes' }, { value: false, label: 'No' }]}
                                            // onChange={(v) => { onChange(v,form) }}
                                            />
                                        )}
                                    </Field>
                                </div>
                            </div>
                        </React.Fragment>
                        : null
                }

                {formType === 'uploadToEdit' ? <div>
                    <Field name='confirmSameData'>
                        {({ field, form }) => (
                            <Checkbox
                                className={classes.sameDataCheckbox}
                                field={field}
                                validate={isRequired('This field is required')}
                                form={form}
                                required={true}
                                label='All document details are verified and correct.'
                            />
                        )}
                    </Field>
                </div>
                    : null}
            </React.Fragment>

        );
    }

}

const CheckboxInput = (props) => {

    const { label, options, required = false, form, field, tooltips, input } = props;
    const { value } = field;
    const [selection, setSelection] = React.useState(value ? value.id : 0);

    const handleInput = () => {
        if (value && value.id !== selection) {
            setSelection(value.id);
            form.setFieldValue(`${field.name}.message`, '');
            if (!input) form.setFieldValue(`${field.name}.message`, label);
        }
    };

    if (value && value.id !== selection) {
        form.setFieldValue(`${field.name}.message`, label);
        setSelection(value.id);
    }

    handleInput();
    return (

        <>

            <Field name={`${field.name}.id`}>
                {({ field, form }) => (
                    <Checkbox
                        id={`${field.name}.id`}
                        label={label}
                        field={field}
                        form={form}
                        className={classes.checkBoxWizard}
                    />
                )}
            </Field>

            {
                input && value && value.id &&
                <>
                    <Field name={`${field.name}.message`}>

                        {({ field, form }) => (
                            <TextInput
                                type='text'
                                field={field}
                                form={form}
                                className={{
                                    root: classes.InputContainer,
                                    input: classes.TextInput
                                }}
                                {...props.TextInput}
                            />
                        )}
                    </Field>
                </>
            }
        </>
    )


}

export default EmploymentAgreementADGMDoc;

import { useMemo } from 'react';
// import _ from "lodash";

const useGuards = () => {
  return useMemo(() => ({
    isFinishProcess: (context) => {

      return context?.CheckoutPayment?.finishProcess;
    },
    hasErrorCard: (context) => {
      
      return context?.CheckoutPayment?.errorCard;
    },
    noHasErrorCard: (context) => {
      
      return !context?.CheckoutPayment?.errorCard;
    },
    hasFinishErrorCard: (context) => {
      
      return context?.CheckoutStep?.status==="error";
    },
    noHasFinishErrorCard: (context) => {
      
      return context?.CheckoutStep?.status!=="error";
    }
    
  }), []);
}

export default useGuards;
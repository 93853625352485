import React, {useEffect, useState} from 'react';
import ServerConnect from "../../../../utils/ServerConnect";
import notification from "../../../../utils/notification";
import _ from "lodash";
import Mustache from 'mustache'

const QUERY = `{
    getEngagementDocument(matterId: "{{matterId}}", type: "{{type}}") {
      file {
        id
        name
        size
      } 
    }
  }`;


export default function useEngagementDocument(matterId, type) {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const query_params = {
            matterId,
            type
        };
        const query = Mustache.render(QUERY, query_params);
        setLoading(true);

        ServerConnect.graphQlQuery(query).then(result => {
            let newData = _.get(result, "getEngagementDocument.file", {});
            setData(newData)
            setLoading(false);
        }).catch(err=>{
            notification.sendNotification( err.message, "error", 5000);
            setLoading(false);
        });
    }, [matterId, type]);

    return { data,loading };
}
import { useCallback, useMemo, useState } from 'react';
import Form from "src/components/forms/Form/FormL";
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import TemplateOnboarding2Wizard from 'src/modules/generic/templates/Modal/TemplateOnboarding2Wizard';
import Typography from "src/modules/generic/components/Texts/Typography";
import ControllerInputClara from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import cls from "./classes.module.scss";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import { Stack } from '@mui/material';
import Assets from 'src/modules/Onboarding/views/Wizard/assets';

/**
 * Step to add the name of startup in the onboarding
 * @param {*} param0
 * @returns
 */
function StartupNameStep({ submitButonProps, actualStep, totalSteps }) {
  const { next, context, cancel } = useMachine();
  const contextStartupName = context?.StartupNameStep?.name ?? "";
  const { translate } = useTranslate();
  const user = useSelector((state) => state.session.user);
  const [startupName, setStartupName] = useState(contextStartupName);

  const schema = Yup.object().shape({
    name: Yup.string().nullable().required('Required'),
  });

  const handleNext = useCallback(
    (values) => {
      next(values);
    },
    [next]
  );

  const handleCancel = () => {
    cancel();
  }  

  const leftComponent = useMemo(() => {
    return (
      <>
        <Stack spacing={4} pt={11} sx={{width: "100%", height: "100%"}}>
          <Typography color={"black"} variant={"onboarding-titles"}  >
            <span dangerouslySetInnerHTML={{ __html: translate("MODULES.ONBOARDINGCOMPANY.ONBOARDING_STARTUP_NAME_STEP_TITLE", { name: user.firstName }) }}>
            </span>
          </Typography>
          <Typography variant="h2" color={"black"} component={"p"} >
            <span dangerouslySetInnerHTML={{ __html: translate("MODULES.ONBOARDINGCOMPANY.ONBOARDING_STARTUP_NAME_STEP_LABEL") }}>
            </span>
          </Typography>
          <ControllerInputClara
            render={({ onChange, value }) => (
              <input
                value={value}
                onChange={(e) => { onChange(e.target.value); setStartupName(e.target.value) }}
                placeholder={"For example, Uber or Google"}
                className={cls["text_input_no_outline"]}
                autoFocus={true}
              />
            )}
            name={"name"}
          />
          <Typography
            color={"black"}
            component={"div"}
            variant={"body"}
            weight="regular"
          >
            {translate("MODULES.ONBOARDINGCOMPANY.ONBOARDING_STARTUP_NAME_STEP_NO_SAME_NAME")}
          </Typography>
        </Stack>
      </>
    )
  }, []);

  const rightComponent = useMemo(() => {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={0}
        sx={{ width: "100%", height: "100%" }}
      >
        <img src={Assets.STEP_1} alt="Clara" style={{ width: "285px", height: "auto", marginTop: "60px" }} />
      </Stack>
    )
  }, []);

  return (
    <Form
      onlySendDirty={false}
      defaultValues={{ name: contextStartupName }}
      template={TemplateOnboarding2Wizard}
      schema={schema}
      onSubmit={handleNext}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateOnboarding2Wizard
        buttons={{
          submit: <SubmitButton {...submitButonProps} disabled={startupName === ""} />
        }}
        cancel={handleCancel}
        leftComponent={leftComponent}
        rightComponent={rightComponent}
        actualStep= {actualStep}
        totalSteps={totalSteps}
      >
      </TemplateOnboarding2Wizard>
    </Form>
  );
}
export default StartupNameStep;

import { useEffect } from 'react';
import useLazyQuery from "../../../hooks/custom/useLazyQuery";


const query = `query validatePaymentCoupon($couponCode: String!) {
  validatePaymentCoupon(couponCode:$couponCode){
   type
   discountAmount
   discountPercentage
}
}`

const useValidatePaymentCoupon = (variables,config) => {

  const { manualQuery,loading, error, data, refetch } = useLazyQuery(query, variables,{fetchPolicy: "no-cache", nextFetchPolicy: "no-cache", notifyOnNetworkStatusChange:true,...config});
  useEffect(() => {

  }, []);

  return { manualQuery,loading, error, data, refetch };
}
export default useValidatePaymentCoupon;

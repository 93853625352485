import React, { FC } from "react";
import useTranslate from "../../../../../../generic/hooks/useTranslate";
import Bubble from "../../../../Bubble";
import imagePay from "../../../../../../../images/Payment.svg";
import classes from "./payInvoice.module.scss";
import classnames from "classnames";
import NextIcon from "../../../../../../../modules/generic/components/NextIcon";
import Button from "../../../../../../../modules/generic/components/Buttons/Button";
import useModal from "src/modules/generic/hooks/useModal";
import ModalPayManualInvoice from "src/modules/startup/PayManualInvoiceModal";
import _ from "lodash"

export interface PayInvoiceCardProps {
  invoice?: any;
}

const PayInvoiceCard: FC<PayInvoiceCardProps> = ({
  invoice,
  ...props
}: PayInvoiceCardProps) => {

  const { translate } = useTranslate();
  const { openModal } = useModal();

  const handleManualInvoice = () => {
    openModal(
      ModalPayManualInvoice,
      {initialValues: {invoice: invoice}},
      "MODAL_PAY_MANUAL_INVOICE"
    );
  };

  return (
    <>
      <Bubble
        color={"pay"}
      >
        <div className={classnames(classes.BubbleContentsNext)}>
          <NextIcon
            title={translate("MODULES.CHECKOUT.PAYMENT_REQUIRED")}
            subTitle={translate("MODULES.CHECKOUT.PAY_INVOICE_REQUIRED_SUBTITLE", {invoiceName: _.get(invoice, "type", "")})}
            imageIcon={imagePay}
          />
          <div className={classnames(classes.buttonSeparator)}>
            <Button
              variant={"pay"}
              children={translate("MODULES.CHECKOUT.PAY_INVOICE_REQUIRED_GOTO_PAY")}
              onClick={handleManualInvoice}
            ></Button>
          </div>
        </div>
      </Bubble>
    </>
  );
};

export default PayInvoiceCard;

import React, { useState, useEffect } from 'react';
import _ from 'lodash'



const Subcategory = ({ data, categories, ...props }) => {

    const [labelSubcategory, setLabelSubcategory] = useState('');

    const findCategoryLabel = (categories, subCategory) => {
        let subcategoryLabel = '';

        _.forEach(categories, cat => {
            const subcategory = _.find(cat.subCategories, subCat => subCat.key === subCategory);
            if (subcategory) {
                subcategoryLabel = subcategory.title;
            }

        });

        return subcategoryLabel;
    }

    useEffect(() => {
        if (categories.length) {
            setLabelSubcategory(findCategoryLabel(categories, _.get(data, "original.subCategories[0]")))
        }

    }, [JSON.stringify(categories)])

    return (
        <div>
            <span>{labelSubcategory}</span>
        </div>
    )
}


export default Subcategory;
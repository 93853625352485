import { useMemo } from "react";
import { assign } from 'xstate';
import { every, get, isEmpty, pick } from 'lodash'
import { Constants } from "src/v1/utils";
import useSession from 'src/modules/session/hooks/useSession';
import useUpdateGroupCompany from '../../core/graphql/mutations/useUpdateGroupCompany';
import { $CODE_CAYMAN_GOAL } from "../../company/steps/Step8A";

const useActions = () => {
  const { startupId } = useSession();
  const mutation = useUpdateGroupCompany();

  return useMemo(() => ({
    setContextAfterStep: assign((context, event) => {
      const values = get(event, 'payload.values');
      return {...context, ...values};
    }),
    setHasPincas : assign((context, event) => {
      const hasPincas = (context.getStakeholdersGroupCompanies.groupCompanies || []).some(gc => gc.managedByClara && gc.kind === 'INCORPORATED')
      return { ...context, hasPincas }
    }),
    addIterations: assign((context, event) => {
      const iterations = get(context, 'iterations', 0);
      return {...context, iterations: iterations + 1};
    }),
    setEntity: assign((context, event) => {
      return {...context, isEntity: false};
    }),
    setGroupCompaniesWithoutFormation: assign((context, event) => {
      return {...context, groupCompanies: (context?.groupCompanies || []).filter(gc => gc.kind !== 'FORMATION')}
    }),
    resetIterations: assign((context, event) => {
      return { ...context, iterations: 0, hasLegalEntityStructure: false }
    }),
    setRolesByContext: assign((context: any, event) => {
      const roleKeys = Object.keys(Constants.ROLES_FROM_BACKEND)
      const filteredKeys = roleKeys.filter(key => {
        if (!["FOUNDER", "ADVISOR", "EMPLOYEE", "CONSULTANT", "INVESTOR", "THIRDPARTY", "SHAREHOLDER", "DIRECTOR"].includes(key)) return false
        if (context.isEntity && (key === Constants.ROLES_FROM_BACKEND.FOUNDER.id || key === Constants.ROLES_FROM_BACKEND.EMPLOYEE.id)) return false
        return !(every(context.getStakeholdersGroupCompanies.groupCompanies, {kind: 'FORMATION'}) && context.getStakeholdersGroupCompanies.groupCompanies.length && (key !== Constants.ROLES_FROM_BACKEND.SHAREHOLDER.id && key !== Constants.ROLES_FROM_BACKEND.DIRECTOR.id && key !== Constants.ROLES_FROM_BACKEND.FOUNDER.id&& key !== Constants.ROLES_FROM_BACKEND.THIRDPARTY.id));
      })
      return {
        ...context,
        isInFormation: every(context.getStakeholdersGroupCompanies.groupCompanies, { kind: 'FORMATION' }),
        roles: isEmpty(context.getStakeholdersGroupCompanies.groupCompanies) ?
          pick(Constants.ROLES_FROM_BACKEND, [Constants.ROLES_FROM_BACKEND.FOUNDER.id]) :
          pick(Constants.ROLES_FROM_BACKEND, filteredKeys)
      }
    }),
    hasLegalEntityStructure: assign((context) => {
      return { ...context, hasLegalEntityStructure: true };
    }),
    setRolesAndIsFounder: assign((context, event) => {
      const values = get(event, 'payload.values');
      return {...context, isFounder: values.roles.includes('FOUNDER'), roles: values.roles.filter(r => r !== 'FOUNDER')};
    }),
    setStakeholder: assign((context, event) => {
      const createdStakeholder = event?.data?.data?.createStakeholder || event?.data?.data?.upsertStakeholderRoles;
      return { ...context, createdStakeholder };
    }),
    addGoal: async (context, event) => {
      const groupCompanies = (context.groupCompanies || context.getStakeholdersGroupCompanies.groupCompanies).filter(gc => gc.kind === 'FORMATION')
      return Promise.all(groupCompanies.map(gc => mutation({
        groupCompanyId: gc.id,
        startupId,
        goal: $CODE_CAYMAN_GOAL,
      })))
    }
  }), []);
}
export default useActions;

import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import classesModule from './classes.module.scss';
import Typography from '../../../modules/generic/components/Texts/Typography';
// import { DialogProps } from "@material-ui/core/Dialog";

interface ModalTourTextlessTemplateProps  {
    image: React.ReactNode;
    buttons?: any;
    className?: string;
}


const ModalTourTextlessTemplate: React.FC<ModalTourTextlessTemplateProps> = ({
    image,
    buttons,
    className
   }) => {

    return (
        <React.Fragment >
            <div className={classesModule.containerTour} >
                <div className={classesModule.containerBlocksModalTour} >
                  <div className={classesModule.rightContentBlockModalTour}>
                    {image}
                  </div>
                </div>
                <div className={classesModule.containerButtonsModalTour}>
                  <div className={classesModule.containerStepsDots} >
                  </div>
                  <div className={classesModule.containerButtons}>
                    {_.map(buttons, button => {
                    
                      return (
                        <div>{button}</div>
                      )
                     } )} 
                  </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ModalTourTextlessTemplate;

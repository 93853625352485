import get from 'lodash/get';
import { assign } from 'xstate';

const useActions = () => {
  const setContextAfterStep = assign((context, event) => {
    const values = get(event, 'payload.values');
    return {...context, ...values};
  });

  const setDefault = assign((context) => {
    const sharesData = context?.initialData?.groupCompanyData?.formationData?.sharesData;
    const defaultShares = sharesData?.defaultShareCapital?.authorisedShares;
    const defaultParValue = sharesData?.defaultShareCapital?.shareNominalPrice;
    const shares = sharesData?.authorisedShares || sharesData?.defaultShareCapital?.authorisedShares;
    const parValue = sharesData?.shareNominalPrice || sharesData?.defaultShareCapital?.shareNominalPrice;
    const currency = sharesData?.currency || sharesData?.defaultShareCapital?.currency || 'USD';
    const shareClass = sharesData?.shareClass || sharesData?.defaultShareCapital?.shareClass || '';
    const articlesOfAssociationAmended = sharesData?.articlesOfAssociationAmended;

    return {
      ...context,
      defaultShares,
      defaultParValue,
      shares,
      parValue,
      currency,
      shareClass,
      articlesOfAssociationAmended
    };
  });

  return{
    setContextAfterStep,
    setDefault
  }
}
export default useActions;

import { useMemo } from 'react';
import { Constants } from "../../../../../v1/utils";

const ROLES_HAVES_AN =[
    Constants.ROLES_CODE.ADVISOR,Constants.ROLES_CODE.EMPLOYEE,Constants.ROLES_CODE.INVESTOR
  ]
const useIsAn = (role:string)=>{
    return useMemo(() => {
        return ROLES_HAVES_AN.includes(role)
      }, [role]);
}
export default useIsAn
import React from 'react';
import Modal from '../../generic/components/Modal/Default';
import './classes.scss';
import Wizard from "./Wizard";
import Box from '@mui/material/Box';

const Onboarding = () => {

  return (
    <>
      <Box
        sx={{
          with: "100%",
          height: "100%",
          backgroundColor: "#fbfcff"
        }}
      >
      </Box>
      <Modal hideBackdrop={true} open={true} className={'containerModalOnboarding'}  >
        <Wizard></Wizard>
      </Modal>
    </>
  )
}

export default Onboarding

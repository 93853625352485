import React, { Component } from 'react';
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import classes from './Modal.module.scss';
import { Text } from "../../index";
import  Title from "../../../../components/text/Title/Title";
import TagManager from 'react-gtm-module';
import _ from "lodash";
import helper from "./../../../../v1/utils/helper"
import logger from  "./../../../../v1/utils/logger"
import {Constants} from "./../../../../v1/utils/constants"
/*
    Properties:
        open: modal is open
        handleClose: function to execute when close de modal
        title: modal title
        children: content to render
        showHeader: show or no show header line. DEfault (true)
*/
class Modal extends Component {
    constructor(props) {
        super(props);
        this.state = {

            title: undefined
        }
    }
    callTagManager = () => {
        if (this.props.open) {
            const userID = _.get(this.props,"session.user._id");
            const firstName = _.get(this.props,"session.user.firstName");
            const overlayName = _.get(this.props, "overlayName");
            const email = _.get(this.props,"session.user.email");
            if (overlayName) {
                const tagManagerArgs = {
                    dataLayer: {
                        event: "OverlayView",
                        userId: userID,
                        firstName: firstName,
                        path: window.location.pathname,
                        pageName: document.title,
                        overlayName: overlayName,
                        userType: helper.getTypeUserByEmail(email),
                    },
                    dataLayerName: "PageDataLayer",
                };
                TagManager.dataLayer(tagManagerArgs);
                // console.log(tagManagerArgs)
            }
        }

    };

    handleClose = () => {
        if (this.props.handleClose) {
            this.props.handleClose();
        }
    };
    log = () => {
        const overlayName = _.get(this.props, "overlayName");
        logger.info(Constants.LOGGER.ACTIONS.MODAL,{overlayName: overlayName,path:_.get(window,"location.href"),title:this.props.title})
    }
  
    componentWillMount() {
        let { title,open } = this.props;
        if (open){
            this.callTagManager();
            this.log();
        }
        this.setState({
            title: title
        })
    }
    componentWillUpdate(nextProps, nextState) {
        let { title } = nextProps;
        if (title !== this.props.title) {
            this.setState({
                title: title
            })
        }

    }
    componentDidUpdate(prevProps, precState) {

        if (prevProps.open !== this.props.open && this.props.open) {
            this.callTagManager();
            this.log();
        }

    }
    render() {
        const alertClass = this.props.alertClass ? classes.alertModalClass : null;
        const className = {
            modal: this.props.className && this.props.className.modal ? this.props.className.modal : null,
            header: this.props.className && this.props.className.header ? this.props.className.header : null,
            dialog: _.get(this.props,"className.dialog")
        };
        return (
            <Dialog
                open={this.props.open}
                disableEnforceFocus
                onClose={this.props.handleClose}
                maxWidth={"md"}
                classes={className.dialog}
            >
                <div style={this.props.extraStyle || {}} className={`${classes.Modal}  ${className.modal} ${alertClass}`}>
                    {this.props.showHeader !== false ?
                        <div className={`${classes.Header} ${className.header}` }>
                            <Title><Text uuid={this.state.title} /></Title>
                            <a onClick={this.handleClose}></a>
                        </div>
                        : null}
                    {this.props.children}
                </div>
            </Dialog>
        );
    }
}
const mapStateToProps = state => {
    return {
        session: state.session,
        pages: state.pages
    };
};
const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);

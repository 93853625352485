import React, { useEffect, useState } from 'react';
import classes from '../ChooseDocumentsView.module.scss';
import TextInput from "../../../../../../components/inputs/Text/TextInput";
import classesModule from "../../../../../../modules/map/components/Header/classes.module.scss";
import useFilter from '../../hooks/useFilter';
import Icon from 'src/modules/generic/components/Icon';
const SearchInput = ({onChange,value}) => {
    const [search, setSearch] = useState('');
    const handleSearch = (value) => {
        setSearch(value);
        onChange && onChange(value);
    }
    useEffect(() => {
        setSearch(value);
    }, [value]);
    return <div className={classes.searchTextContainer}>
    <TextInput
      onChange={handleSearch}
      value={search}
      className={classesModule.TextInput}
      placeholder={"DOCUMENT_SEARCH_INPUT"}
      leftLabel={
        <span className={classesModule.arrowColor}>
          <Icon icon="Search" isClara size={"1.5385rem"} />
        </span>
      }
    />
  </div>
}
export default SearchInput;
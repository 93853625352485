import isEmpty from 'lodash/isEmpty';
import useMachine from '../../../../../generic/context/MachineContext/useMachine';
import React, { useEffect } from 'react';
import Button from "../../../../../generic/components/Buttons/Button";
import useTranslate from '../../../../../generic/hooks/useTranslate';
import { useDispatch } from "react-redux";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import { addAssistantText, clearAssistatText } from "../../../../../generic/store/action";
import useModal from "../../../../../generic/hooks/useModal";
import StakeholderNameForm from '../../../core/forms/StakeholderNameForm';
/**
 * Step para editar los datos de SOF OTHER
 * @param {*} param0
 * @returns
 */
function AddStakeholderName({ openParam }) {

  const { next, context, prev } = useMachine();
  const { translate } = useTranslate()
  const dispatch = useDispatch();
  const { closeModal: onClose } = useModal();

  const handleCancel = (values) => {
    onClose();
    dispatch(clearAssistatText());
  }


  const handlePrevious = () => {
    prev()
  }

  const handleInitModal = () => {
    dispatch(clearAssistatText());
    const groupCompanies = context.getStakeholdersGroupCompanies.groupCompanies
    if(isEmpty(groupCompanies)) dispatch(addAssistantText(translate('MODAL_ADD_STAKEHOLDER_SELECT_ROLES_TOOLTIP_NO_GROUPCOMPANIES')))
  }

  useEffect(() => {
    handleInitModal()
  }, []);

  const handleCompleteSubmit = async (values) => {
    next(values)
  }
  return (
    <StakeholderNameForm
      isIndividual={!context.isEntity}
      stakeholders={context.getStakeholdersGroupCompanies.stakeholders}
      buttons={{
        previous:isEmpty(context.getStakeholdersGroupCompanies.groupCompanies)?null:(
          <Button onClick={handlePrevious}>Previous</Button>
        ),
        cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
      }}
      onCompleteSubmit={handleCompleteSubmit}
      propsTemplate={{
        title: 'Add their basic information',
        subTitle: 'Add a stakeholder'
      }} 
      Template={TemplateWizard}
    />
  );
}
export default AddStakeholderName;

import useNavigation from "src/modules/generic/hooks/useNavigation";
import MyProfilesViewMf from "./MyProfilesViewMf";
import useSession from "src/modules/session/hooks/useSession";
import { Constants } from "src/v1/utils";
import React, { useState } from "react";
import { Modal } from "src/v1/components";
import Text from "src/v1/components/Lang/Text/Text";
import SecondaryButton from "src/components/generic/Buttons/SecondaryButton/SecondaryButton";
import classes from "./MyProfilesViewMf.module.scss";
import ServerConnect from "src/v1/utils/ServerConnect";
import notification from "src/v1/utils/notification";

const MyProfilesView = (props) => {
  const { goToMF } = useNavigation();
  const { selectStartup } = useSession();
  const [selectedStartup, setSelectedStartup] = useState({});
  const [showReactivateModal, setShowReactivateModal] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const handleCloseReactivateModal = () => setShowReactivateModal(false);

  const handleOpenProfile = async (startup, role) => {
    await selectStartup(
      startup.id,
      role === "USER_VIEWER"
        ? Constants.PAGES.MODULES.MAP.maps
        : Constants.PAGES.MODULES.MAP.dashboard
    );
  }
  const handleOpenModal = async (startup) => {
    setSelectedStartup(startup);
    setShowReactivateModal(true);
  }

  const submitReactivateModal = () => {
    const mutationId = selectedStartup.id;
    const mutation = `mutation userRequestAccessToAStartup($startupId: String!) {
      userRequestAccessToAStartup(startupId: $startupId)
    }`;
    const variables = {
      startupId: mutationId,
    };
    setIsSubmiting(true);
    ServerConnect.graphQlMutation(mutation, variables)
      .then(() => {
        notification.sendNotification(
          `Your request for access has been sent to ${selectedStartup.name}`,
          "success",
          4990
        );
        handleCloseReactivateModal();
        setIsSubmiting(false);
      })
      .catch((err) => {
        setIsSubmiting(false);
        console.log(err);
        notification.sendNotification(
          "You don’t have permissions to execute that action",
          "error",
          4990
        );
      });
  };

  return (
    <React.Fragment>
      {showReactivateModal && (
        <Modal
          className={{ modal: classes.ModalAdd }}
          open={showReactivateModal}
          showHeader={false}
        >
          <div className={classes.DeleteForm}>
            <h1>{"Request access to " + selectedStartup?.name ?? "Statup"}</h1>
            <div>
              <Text uuid={"REQUEST_ACCESS_MODAL_SUBTITLE"} />
            </div>
            <div className={classes.botonera}>
              <SecondaryButton
                type="button"
                onClick={handleCloseReactivateModal}
                disabled={isSubmiting}
              >
                <Text uuid={"BUTTON_CANCEL"} />
              </SecondaryButton>
              <button
                className={`genericBlue`}
                onClick={submitReactivateModal}
                disabled={isSubmiting}
              >
                {isSubmiting ? (
                  <>
                    Sending...
                  </>
                ) : (
                  <>
                    Send Request
                  </>
                )}
              </button>
            </div>
          </div>
        </Modal>
      )}
      <MyProfilesViewMf goToMF={goToMF} handleOpenProfile={handleOpenProfile} handleOpenModal={handleOpenModal}/>
    </React.Fragment>
  );
};

export default MyProfilesView;

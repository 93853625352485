

import * as Yup from 'yup'
import _ from 'lodash'
import { Constants } from "../../../../../../v1/utils/constants";

const uploadedAgreementValidations = {
    type: Yup.string().nullable().required('Document Type is required.'),
    title: Yup.string().nullable().required('Document name is required.'),
    file: Yup.object().nullable().required('A file is required.'),
    executedOnDate: Yup.date().nullable().typeError('Must be a date'),
    expirationDate: Yup.date().nullable().typeError('Must be a date'),
    parties: Yup.array().when(['type'],{
        is:(value)=>!_.includes(Constants.DOCUMENT_TYPE_IDENTITY_DOCUMENTS,value),
        then: Yup.array().of(
        Yup.object().shape({
            party: Yup.object().nullable().required('Party is required.'),
        })
        ).required('Required').min(1, 'At least one party is required.'),
        otherwise:Yup.array().notRequired()
    }),
    party:Yup.object().when(['type'],{
        is:(value)=>_.includes(Constants.DOCUMENT_TYPE_IDENTITY_DOCUMENTS,value),
        then: Yup.object().nullable().required('Party is required.'),
        otherwise:Yup.object().notRequired()
    }),
    passport:Yup.object().when(['type'],{
        is:(value)=>_.includes(Constants.PASSPORT_DOCUMENT_TYPES,value),
        then: Yup.object().nullable().shape({
            nationality:Yup.string().nullable().required('Required'),
            number:Yup.string().nullable().required('Required'),
            expirationDate:Yup.date().nullable().typeError('Is not a date').required('Required'),
        }).required('Passport is required.'),
        otherwise:Yup.object().notRequired()
    }),
    visaStamp:Yup.object().when(['type'],{
        is:(value)=>_.includes(Constants.VISA_STAMPS_RESIDENCE_ID_DOCUMENTS_TYPES,value),
        then: Yup.object().shape({
            expirationDate:Yup.date().nullable().typeError('Is not date').required('Required'),
        }).nullable().required('Visa or Stamp is required.'),
        otherwise:Yup.object().notRequired()
    }),
    category: Yup.string().required('Category is required.').nullable(),
    subCategory: Yup.string().nullable().required('Sub-category is required.')
};

export default uploadedAgreementValidations;
import React, {useEffect} from 'react';
import useQuery from "../../../../custom/useQuery"

const query = `query callServerData($startupId:ID!) {
    documentList(startupId: $startupId) {
      id
      name
    }
  	getGroupCompanies(startupId: $startupId) {
      id
      name
      isHoldingCompany
    }
  	stakeholderList(startupId: $startupId) {
      id
      fullName
    }
}`;
const useCallServerData = (variables) =>{
    
    const {loading, error, data} = useQuery(query,variables);
    useEffect(() => {
        
    }, []);

    return  {loading,error,data} ;
}
export default useCallServerData;
import React, { FC, useRef } from 'react';
import * as Yup from 'yup';
import Form from "../../../../../components/forms/Form/FormL";
import TextareaInput from "../../../../../components/inputs/Textarea/TextareaInput";
import SubmitButton from "../../../../generic/components/Buttons/SubmitButton";
import { CheckboxButtonOptions } from "../../../../generic/components/Inputs/CheckboxButton";
import CheckboxButtonSimple from "../../../../generic/components/Inputs/CheckboxButtonSimple";
import TemplateLabel from "../../../../generic/components/Inputs/LabelInput";
import ControllerInput from "../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import FormProps from "../../../../generic/forms/interfaces/FormProps";
import useTranslate from "../../../../generic/hooks/useTranslate";
import TemplateFormDefault from "../../../../generic/templates/Modal";
import classes from './styles.module.scss';

interface SourceOfFoundsKYFormProps extends FormProps {
  isEdit: boolean,
  hasChanged:()=>{void}

}

const schema = Yup.object().shape({
  sourceOfFunds: Yup.string().required("Required"),
  sourceOfFundsYesNo: Yup.string().required("Required"),
})

const SourceOfFoundsKYForm: FC<SourceOfFoundsKYFormProps> = ({
  initialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
  isEdit = false,
  hasChanged
}: SourceOfFoundsKYFormProps) => {

  const { translate } = useTranslate();
  const refForm = useRef();

  const options: Array<CheckboxButtonOptions<boolean, any>> = [
    {
      value: false,
      label: "No, they don't"
    },
    {
      value: true,
      label: "Yes, they do",
    }
  ]

  return (
    <>
      <Form
        initialValues={initialValues || {}}
        schema={schema}
        onlySendDirty={false}
        onSubmit={onCompleteSubmit}
        ref={refForm} 
        optionsForm={{ mode: "onChange" }}
      >
        <Template
          buttons={{ ...buttons, submit: <SubmitButton {...buttonSubmitProps}>{isEdit ? 'Save changes' : 'Add'}</SubmitButton> }}
          props={propsTemplate}
        >
          <TemplateLabel
            isColumn
            label={'What is the source of funds/wealth with which this stakeholder intends to fund their subscription to the company and capitalise or finance the company?'}
            isRequired>
            <ControllerInput
              name={'sourceOfFunds'}
              render={TextareaInput}
              className={classes.TextArea}
              placeholder={'For example, “Personal savings from 10 years of employment at Uber.'}
              defaultValue={''}
              onChange={hasChanged}
            />
          </TemplateLabel>
          <TemplateLabel
            isColumn
            label={'Does this stakeholder have a source of wealth resulting from any material receipt of cryptocurrencies, or material receipt of funds stemming from businesses involved with the issuance of receipt of cryptocurrencies, gambling, the sale or distribution of narcotics, and/or predominantly a “cash-only” business?'}
          >
            <ControllerInput
              name={'sourceOfFundsYesNo'}
              render={CheckboxButtonSimple}
              options={options}
              className={classes.CheckboxButton}
              classNameButton={classes.buttonCheckBox}
              onChange={hasChanged}
            />
          </TemplateLabel>
        </Template>
      </Form>
    </>
  )
}

export default SourceOfFoundsKYForm

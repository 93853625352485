import { useMemo } from 'react';

const useGuards = () => {

  return useMemo(() => ({

    isAddMore: (context) => {
      return context?.AddMoreStep?.another
    },
    isNotAddMore: (context) => {
      return !context?.AddMoreStep?.another
    },
    
  }), []);
}

export default useGuards;

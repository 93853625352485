import { useMemo } from 'react';
// import useBusinessRules from 'src/modules/clara/hooks/useBusinessRules';
// import useSession from 'src/modules/session/hooks/useSession';

const useGuards = () => {

  // const { isOwner } = useBusinessRules();
  // const { session } = useSession();

  return useMemo(() => ({

    // isCond_1: (context) => {
    //   // Evaluate context, session or bussiness rules
    //   return isOwner() && !session
    // },
    // isCond_2: (context) => {
    //   return true
    // },

  // }), [isOwner, session]);
  }), []);
}

export default useGuards;

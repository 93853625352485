export default {
    CONNECTIONS:{
        EQUITY_TITLE:'Equity',
        CONVERTIBLES_TITLE:'Convertibles',
        WARRANTS_TITLE:'Warrants',
        SHAREISSUANCES_TITLE: 'Share Positions',
        OPTIONGRANTS_TITLE:'Option Grants',
        DOCUMENTS_TITLE:'Documents',
        DUEDILIGENCES_TITLE:'Remarks',
        PRIMARYCONTACT_TITLE:'Primary Contact:',
        DATAPROTECTIONCONTACT_TITLE:'Data Protection Contact:',
        AUTHORISEDSIGNATORIES_TITLE:'Authorised Signatory:',
        AUTHORISEDSIGNATORIESBANK_TITLE:'Authorised Signatory for Banking Transactions:',
        SHAREHOLDERS_TITLE:'Shareholder:',
        DIRECTORS_TITLE:'Director:',
        ROLES_TITLE:'Roles',
        PRODUCTITEMS_TITLE:'Products',
        PRODUCTITEMS:'this stakeholder has change requests you need to go to cart to delete them',
        ROLES:'stakeholder has key role in {{{roles.entity.name}}} as {{role.name}}',
        DATAPROTECTIONCONTACT:'Is data protection contact for {{{dataProtectionContact.name}}}',
        AUTHORISEDSIGNATORIES:'Is authorised signatory for {{{authorisedSignatories.name}}}',
        AUTHORISEDSIGNATORIESBANK:'Is authorised signatory for banking transactions for {{{authorisedSignatoriesBank.name}}}',
        SHAREHOLDERS:'Is director or shareholder for {{{shareholders.name}}}',
        DIRECTORS:'Is director or shareholder for {{{directors.name}}}',
        PRIMARYCONTACT:'Is primary contact for {{{primaryContact.name}}}',
        DOCUMENTS:'{{{documents.name}}} ',
        EQUITY:'This stakeholder has equity positions in the startup that must be deleted first',
        WARRANTS:'Warrant',
        CONVERTIBLES:'Convertible',
        SHAREISSUANCES:'Share positions of {{shareIssuances.sharePrice.currency}} {{shareIssuances.sharePrice.amount}}, with {{{shareIssuances.groupCompany.name}}} emitter and {{{shareIssuances.holder.fullName}}} holder',
        OPTIONGRANTS:'Option grants of amount {{optionGrants.exercisePrice.currency}} {{optionGrants.exercisePrice.amount}}, with {{{optionGrants.groupCompany.name}}} emitter and {{{optionGrants.holder.fullName}}} holder',
        DUEDILIGENCES:'Issue {{dueDiligences.title}}'
    },
    INVALIDATE:'Deactivate this stakeholder instead. They’ll still be visible on the platform',
    RESOLVE:'Solve these issues and return here to remove',
    TITLE_NO_CONNECTIONS:'Are you sure you want to remove {{{stakeholder.fullName}}}? ',
    TITLE_CONNECTIONS:'It’s not possible to remove {{{stakeholder.fullName}}} right now',
    DESCRIPTION:'You won’t be able to undo this',
    REASONS:'Here\'s why',
    DELETE:'Permanently remove this stakeholder',
    CHECKBOX:{
        SET_INACTIVE:'inactivate',
        DELETE_STAKEHOLDER:'delete'
    }
}
import useCustomMachine from "../../../../../../generic/hooks/useCustomMachine";
import React, { FC, useEffect } from 'react'
import { StepProps } from '../../../../../../generic/components/Wizard/Step'
import useTranslate from '../../../../../../generic/hooks/useTranslate'
import AreThereMoreEntity from "../../../forms/AreThereMoreEntity";
import TemplateWizard from "../../../../../../generic/templates/Modal/TemplateWizard";
import _ from 'lodash';
import { useDispatch } from "react-redux";
import { clearAssistatText } from "../../../../../../generic/store/action";
interface LastStepProps extends StepProps {
  role: string,
  tooltips?: object
}

const LastStep: FC<LastStepProps> = ({ role, tooltips }: LastStepProps) => {

  const { translate } = useTranslate()
  const { next, send, stepData } = useCustomMachine();
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clearAssistatText())
  }, []);

  const handleCompleteSubmit = async (values: any) => {
    next(values);
  }

  return (
    <AreThereMoreEntity
      onCompleteSubmit={handleCompleteSubmit}
      Template={TemplateWizard}
      propsTemplate={{
        title: translate('MODULES.ONBOARDING_FORMS.ARE_THERE_MORE_ENTITY.ARE_MORE_ENTITY', { role: _.lowerFirst(translate(`MODULES.ONBOARDING_STEPS.ROLES_LABEL_PLURAL.${role}`)) }),
        subTitle: translate('MODULES.ONBOARDING_FORMS.ARE_THERE_MORE_ENTITY.ADDED_ENTITY', { role: translate(`MODULES.ONBOARDING_STEPS.ROLES_LABEL.${role}`) })
      }}
      tooltips={tooltips}
      initialValues={stepData}
    />
  )
}

export default LastStep

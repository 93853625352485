import React, { FC, useEffect } from "react";
import FirstStep from "../../../core/wizards/steps/FirstStep";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import useCustomMachine from "../../../../../generic/hooks/useCustomMachine";
import { StepProps } from "../../../../../generic/components/Wizard/Step";
import { useDispatch } from "react-redux";
import useRemovePendingEntity from "../../../../../../hooks/services/modules/assistance/useRemovePendingEntity";
import {
  addAssistantText,
  clearAssistatText,
} from "../../../../../generic/store/action";
import useSession from "../../../../../session/hooks/useSession";

const FirstStepDirector: FC<StepProps> = ({}: StepProps) => {

  const { translate } = useTranslate();
  const { startup } = useSession();
  const { next, send } = useCustomMachine();
  const dispatch = useDispatch();

  const [removePendingEntity] = useRemovePendingEntity({
    showSuccessNotification: false,
  });

  const handleCompleteSubmit = async () => {
    await removePendingEntity({
      entityType: "DIRECTOR",
      startupId: startup.id,
    });
  };

  useEffect(() => {
    dispatch(
      addAssistantText(
        translate("MODULES.ONBOARDINGROLES.DIRECTOR.FIRST_STEP_1_TOOLTIPS"),
        "text"
      )
    );

    return ()=>  dispatch(clearAssistatText());
  }, []);

  return (
    <>
      <FirstStep
        role={"DIRECTOR"}
        entity="a director"
        onCompleteSubmit={handleCompleteSubmit}
      />
    </>
  );
};

export default FirstStepDirector;

import react, { useReducer } from 'react'


export interface StateModalSteps {
    buttonPrev: Button;
    buttonNext: Button;
    propsPage: any;
}

export interface Button {
    disabled: boolean;
    label: string;
    onClick(): void;
}


interface ActionState {
    type: string,
    payload: any
};

const TYPES_STATE_BUTTONS = {
    SET_DISABLED_PREV: "SET_DISABLED_PREV",
    SET_DISABLED_NEXT: "SET_DISABLED_NEXT",
    SET_LABEL_PREV: "SET_LABEL_PREV",
    SET_LABEL_NEXT: "SET_LABEL_NEXT",
    SET_ON_CLIK_NEXT: "SET_ON_CLIK_NEXT",
    SET_PROPS_PAGE: "SET_PROPS_PAGE"
}

const reducer = (state: StateModalSteps, action: ActionState) => {
    switch (action.type) {
        case TYPES_STATE_BUTTONS.SET_DISABLED_PREV:
            return {
                ...state,
                buttonPrev: {
                    ...state.buttonPrev,
                    disabled: action.payload
                }
            }
        case TYPES_STATE_BUTTONS.SET_DISABLED_NEXT:
            return {
                ...state,
                buttonNext: {
                    ...state.buttonNext,
                    disabled: action.payload
                }
            }

        case TYPES_STATE_BUTTONS.SET_LABEL_PREV:
            return {
                ...state,
                buttonPrev: {
                    ...state.buttonPrev,
                    label: action.payload
                }
            }
        case TYPES_STATE_BUTTONS.SET_LABEL_NEXT:
            return {
                ...state,
                buttonNext: {
                    ...state.buttonNext,
                    label: action.payload
                }
            }
        case TYPES_STATE_BUTTONS.SET_ON_CLIK_NEXT:
            return {
                ...state,
                buttonNext: {
                    ...state.buttonNext,
                    onClick: action.payload
                }
            }

        case TYPES_STATE_BUTTONS.SET_PROPS_PAGE:
            return {
                ...state,
                propsPage: {
                    ...action.payload,

                }
            }


        default:
            return state;
    }
}

const initialState = {
    buttonPrev: {
        disabled: false,
        label: "Previous"
    },
    buttonNext: {
        disabled: false,
        label: "Next"
    },
    propsPage: {},

};


export default function useReducerModalSteps() {


    const [state, dispatch] = useReducer(reducer, initialState);

    const setDisabledButtonPrev = (value: boolean) => dispatch({ type: TYPES_STATE_BUTTONS.SET_DISABLED_PREV, payload: value });

    const setDisabledButtonNext = (value: boolean) => dispatch({ type: TYPES_STATE_BUTTONS.SET_DISABLED_NEXT, payload: value });

    const setLabelButtonPrev = (value: string) => dispatch({ type: TYPES_STATE_BUTTONS.SET_LABEL_PREV, payload: value });

    const setLabelButtonNext = (value: string) => dispatch({ type: TYPES_STATE_BUTTONS.SET_LABEL_NEXT, payload: value });

    const setOnClickButtonNext = (value: string) => dispatch({ type: TYPES_STATE_BUTTONS.SET_ON_CLIK_NEXT, payload: value });

    const setPropsPage = (value: string) => dispatch({ type: TYPES_STATE_BUTTONS.SET_PROPS_PAGE, payload: value });

    return { state, setDisabledButtonPrev, setDisabledButtonNext, setLabelButtonPrev, setLabelButtonNext, setOnClickButtonNext, setPropsPage }

}


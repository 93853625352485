/* eslint-disable default-case */
import React, { Component } from "react";
import classes from './Progress.module.scss'

class Progress extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getClass() {
    switch (this.props.state) {
      case "error":
        return classes.ProgressError;
      case "pending":
        return classes.ProgressPending;
      case "done":
        return classes.ProgressDone;
    }
    return null;
  }

  tryAgain = () => {
    this.props.reload(this.props.file);
  }

  getInfo() {
    switch (this.props.state) {
      case "error":
        if (this.props.error) {
          return <span className={classes.tryAgain}>{this.props.message}</span>
        } else {
          return <a className={classes.tryAgain} onClick={this.tryAgain}>Try Again</a>;
        }
      case "pending":
        return <div className={classes.percent}>{this.props.progress} %</div>;
      case "done":
        return <a className={`linkPlus ${classes.remove}`} onClick={this.props.remove}></a>;;
    }
    return null;
  }

  getIcon() {
    switch (this.props.state) {
      case "error":
        return <div className={`${classes.icon}`} ><img src="/img/icons/upload_error.svg" /></div>;
      case "pending":
        return <a className={`${classes.icon}`} onClick={this.tryAgain}><img src="/img/icons/upload_refresh.svg" /></a>;
      case "done":
        return <div className={`${classes.icon}`} ><img src="/img/icons/upload_done.svg" /></div>;;
    }
    return null;
  }

  render() {
    return (
      <div className={`${classes.Progress} ${this.getClass()}`}>
        <div
          className={classes.ProgressPercent}
          style={{ width: this.props.progress + "%" }}
        />
        <div className={classes.ProgressData}>
          {this.getIcon()}
          <div className={classes.fileName}>{this.props.file.name}</div>
          {this.getInfo()}
        </div>
      </div>
    );
  }
}

export default Progress;



import PropTypes from "prop-types";
import React, { memo } from "react";
import MicroFrontend from "src/mf";
/* `MyTasksViewMf` is a React functional component that serves as a view component for displaying
tasks. It utilizes React hooks such as `useState`, `useEffect`, and `useDispatch` from
`react-redux`. The component also uses the `memo` function from React to optimize performance
by memoizing the component. */
const  MyTasksViewMf = ({goToMF,taskActions,open,setOpen,dataModal,idMf,mfComponent}) => {
  
  
  return (
    <React.Fragment>      
      <MicroFrontend id="MF_MyTasks_" component={"./MyTasks"} mf="dashboard" params={{
        go: goToMF,
        
        taskActions
      }} loader={<></>}/>
      <div>
        {open && <MicroFrontend id={idMf}
          component={mfComponent}  mf="capTable" params={{
          ...dataModal,
          open, 
          onCancel: () => { setOpen(false) }, 
          onClose: () => { setOpen(false) },
          onComplete: () => { setOpen(false) },
        }} loader={<></>} />}
      </div> 
    </React.Fragment>
  );
};
MyTasksViewMf.propTypes = {
  idMf: PropTypes.string,
  mfComponent: PropTypes.string,
  goToMF: PropTypes.func,
  taskActions: PropTypes.array,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  dataModal: PropTypes.object,
  
};
export default memo(MyTasksViewMf);

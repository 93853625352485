import Table from '../../../../../../components/Table/Table';
import React from 'react';
import classesModal from "../../MyAccountView.module.scss";
import _ from "lodash";
import Date from "./../../../../../../components/text/Text/Date";
import Button from 'src/modules/generic/components/Buttons/Button';
import classesModule from "./AuthorisedDevicesTable.module.scss";


const AuthorisedDevicesTable = ({ data, handleDeauthorise, handleRenameDevice }) => {


    const columns = React.useMemo(
        () => [
            {
                Header: ' ',
                columns: [
                    {
                        Header: 'Device',
                        accessor: 'device',
                        Cell: ({ row }) => {
                            return (
                                <div>
                                    {_.get(row, "original.name")}
                                </div>)
                        }
                    },
                    {
                        Header: 'State',
                        accessor: 'state',
                        Cell: ({ row }) => {
                            let state = _.get(row, "original.state") === 'DEAUTHORISED' ? 'De-authorised' : _.get(row, "original.state")
                            return <div>
                                <i
                                className={`${classesModal[_.get(row, "original.state", "-")]} ${
                                    classesModal.circle
                                } `}
                                />
                                <span>{" " + _.capitalize(state)}</span>
                            </div>
                        }
                    },
                    {
                        Header: 'Date authorised',
                        accessor: 'dateAuthorised',
                        Cell: ({ row }) => {
                            return <div>
                                <Date value={_.get(row, "original.date")} />
                            </div>

                        }
                    },
                    {
                        Header: 'Last log in',
                        accessor: 'lastLogin',
                        Cell: ({ row }) => {
                            return <div>
                                <Date value={_.get(row, "original.lastLogin")} />
                            </div>

                        }
                    },
                    {
                        Header: ' ',
                        accessor: 'buttons',
                        Cell: ({ row }) => {
                            return <div className={classesModule.ContainerButtons}>
                                <Button variant="card" onClick={()=>handleRenameDevice(row.original)}>Rename</Button>
                                <Button visible={!(_.get(row, "original.state") === 'DEAUTHORISED')} variant="card" onClick={()=>handleDeauthorise(row.original)}>De-authorise</Button>
                                
                            </div>

                        }
                    }
                ],
            }
        ],
        [handleDeauthorise, handleRenameDevice]
    )


    return (
        <React.Fragment>
            <Table
                columns={columns}
                data={data}
                className={{table: classesModal.Table }}
            />
        </React.Fragment>
    )
}

export default AuthorisedDevicesTable;
import useCustomMachine from '../../../../generic/hooks/useCustomMachine'
import React, { FC } from 'react'
import Button from '../../../../generic/components/Buttons/Button'
import { StepProps } from "../../../../generic/components/Wizard/Step"
import TemplateWizard from '../../../../generic/templates/Modal/TemplateWizard'
import useTranslate from '../../../../generic/hooks/useTranslate'
import Form from "../../Forms/FormNotices";
import { get } from 'lodash'

const Step1: FC<StepProps> = () => {

  const { translate } = useTranslate();
  const { next, send, state } = useCustomMachine();

  const handleCompleteSubmit = async values => {
    next(values);
  }

  const handleCancel = async values => {
    send("CANCEL");
  }

  return (
    <React.Fragment>
      <Form 
        onCompleteSubmit={handleCompleteSubmit} 
        initialValues={get(state, 'context.data.add_notices', {})} 
        Template={TemplateWizard}
        propsTemplate={{
          title: translate(`MODULES.MAP.WIZARD_COMPANY_NOTICES_STEP_1_FORM_TITLE`),
          subTitle: translate(`MODULES.MAP.WIZARD_COMPANY_NOTICES_FORM_SUBTITLE`)
        }}
        buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }}>
      </Form>
    </React.Fragment>
  )
}

export default Step1;

import React, { FC } from "react";

import AssistanceContents from "../../../AssistanceContents";
import BubbleTask from "../../../BubbleTask";
import TabBubblesContainer from "../../../TabBubblesContainer";
import _ from "lodash";

export interface GroupCompanyTasksProps {
  tasks: any,
  entity: any,
  companiesCorporateData: any,
  resetCounter: any,
  showGJ: boolean,
  countCompleted: any,
}

const GroupCompanyTasks: FC<GroupCompanyTasksProps> = ({
  tasks: dataTasks,
  entity,
  companiesCorporateData = "",
  resetCounter,
  showGJ,
  countCompleted,
  ...props
}: GroupCompanyTasksProps) => {

  return (
    <AssistanceContents>
      <TabBubblesContainer>
        <BubbleTask variant={'groupCompany'}/>
      </TabBubblesContainer>
    </AssistanceContents>
  );
};

export default GroupCompanyTasks;

import classes from "./classes.module.scss";
import React, { FC } from "react";

/**
 * Graphs legends component
 */

export interface GraphsLegendsProps {
  /** Property labels */
  labels: any,
  /** Property colors */
  colors: any,
} 

const GraphsLegends: FC<GraphsLegendsProps> = ({
  labels,
  colors,
  ...props
}:GraphsLegendsProps) => {

  return (
    <>
      {labels.map((label, index) => (
        <div key={index} className={classes.LegendDiv}>
          <div className={classes.dotContainer}>
            <div
              style={{
                backgroundColor: colors[index],
              }}
            />
          </div>
          <label>{label}</label>
        </div>
      ))}
    </>
  );
};

export default GraphsLegends;
import React, { useState, useEffect, useMemo } from "react";
import classes from "./ZoneMessagesPanel.module.scss";
import _ from "lodash";
import Icon from "../../../../../../modules/generic/components/Icon";
import Message from "./Message";
import { useSelector, useDispatch } from "react-redux";
import useMutation from "../../../../../../hooks/custom/useMutation";
import useQuery from "../../../../../../hooks/custom/useQuery";
import useSession from "./../../../../../../modules/session/hooks/useSession";
import Scrollbar from "../../../../../../components/generic/Scrollbar/Scrollbar";
import ZoneMessagesPanelSkeleton from "./ZoneMessagesPanelSkeleton";

const TOGGLE_MESSAGE_THREAD = `
mutation toggleThreadFlag($startupId: ID, $threadId: ID) {
    toggleThreadFlag(startupId: $startupId, threadId: $threadId)
  }  
`;

const MARK_MESSAGE_AS_READ = `mutation markThreadAsRead($startupId: ID, $threadId: ID) {
    markThreadAsRead(startupId: $startupId, threadId: $threadId)
  }  
`;

const GET_MESSAGES_THREAD = `
query  getMessagesThread(
    $startupId: ID
      $filter:[MessageThreadFilter]
  ) {
    getMessagesThread(startupId: $startupId,filter:$filter) {
      type
      id
      important
      messages {
        read
        template
        
        createdAt
        createdBy {
          _id
          fullName
          avatar
        }
        createdAt
      }
      actions {
        entityId
      type
      }
      createdAt
    }
  }  
`;

const ZoneMessagesPanel = ({
  setThreadMessages,
  filtersValues,
  totalNewMessages,
  ...props
}) => {

  const [messageSelect, setMessageSelect] = useState(-1);
  const [threadSelect, setThreadSelect] = useState(null);
  const [messagesThread, setMessagesThread] = useState([]);
  const dispatch = useDispatch();
  const { startupId } = useSession();
  const [refreshCompletedThread, setRefreshCompletedThread] = useState(true);

  const handleSelectMessage = (value) => {
    setMessageSelect(value);
  };

  const threadIdSubscription = useSelector(
    (state) => state.notifications.notifications.threadId
  );

  const threadId = useMemo(
    () => _.get(messagesThread, `[${messageSelect}].id`),
    [messageSelect]
  );

  const {
    data: dataThreads,
    loading: loadingMessagesThread,
    error,
    refetch: refetchGetMessageThreadsQuery,
  } = useQuery(GET_MESSAGES_THREAD, { startupId, filter: filtersValues });

  const refetchGetMessageThreads = () => {
    if (!loadingMessagesThread) {
      refetchGetMessageThreadsQuery();
    }
  };

  const [markThreadAsRead] = useMutation(MARK_MESSAGE_AS_READ, {
    showSuccessNotification: false,
  });

  const [toggleMessageFlag] = useMutation(
    TOGGLE_MESSAGE_THREAD,
    {
      onCompleted: (data) => {
        setMessagesThread((pre) =>
          pre.map((tread) =>
            tread.id !== threadId
              ? tread
              : { ...tread, important: !tread.important }
          )
        );
      },
    },
    {
      variables: { startupId, threadId },
      //   refetchQueries: [{ query: GET_MESSAGES_THREAD, variables: { startupId, filter: filtersValues } }]
    }
  );

  useEffect(() => {
    if (0 <= messageSelect && threadSelect) {
      const newIndex = _.findIndex(
        messagesThread,
        (thread) => threadSelect.id === thread.id
      );
      messageSelect !== newIndex && setMessageSelect(newIndex);
    }
  }, [JSON.stringify(messagesThread)]);

  useEffect(() => {
    if (0 <= messageSelect) {
      const threadFind = messagesThread[messageSelect];

      if (!_.isEqual(threadFind, threadSelect)) {
        setThreadSelect(threadFind);
        handleMarkReadThread(threadFind);
        setThreadMessages(threadFind);

        dispatch({
          type: "DECREMENT_NOTIFICATIONS",
          payload: { type: threadFind.type },
        });
      }
    }
  }, [messageSelect]);

  const haveMessagesUnread = ({ messages }) => {
    return _.some(messages, (message) => message.read === false);
  };

  const refreshThread = () => {
    const thread = _.find(
      dataThreads.getMessagesThread,
      (t) => t.id === threadIdSubscription
    );
    if (thread && haveMessagesUnread(thread)) {
      thread.messages[thread.messages.length - 1].read = true;
      if (totalNewMessages > 0) {
        markThreadAsRead();
        markThreadAsRead({ variables: { startupId, threadId } });
        dispatch({
          type: "DECREMENT_NOTIFICATIONS",
          payload: { type: threadSelect.type },
        });
      }
      setThreadMessages(thread);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(dataThreads)) {
      const { getMessagesThread } = dataThreads;
      if (refreshCompletedThread) {
        setMessagesThread(getMessagesThread);
        setRefreshCompletedThread(false);
      } else {
        handleUpdateMessagesThread(getMessagesThread);
      }
      if (threadSelect && threadSelect.id === threadIdSubscription) {
        refreshThread();
      }
    } else {
      setMessagesThread([]);
    }
  }, [JSON.stringify(dataThreads)]);

  useEffect(() => {
    if (filtersValues.length) {
      setRefreshCompletedThread(true);
      setThreadMessages(null);
      setMessageSelect(-1);
      refetchGetMessageThreads();
      setThreadSelect(null);
    }
  }, [JSON.stringify(filtersValues)]);

  const handleUpdateMessagesThread = (newThreads) => {
    const newMessagesThread = _.unionBy(newThreads, messagesThread, "id");
    setMessagesThread(newMessagesThread);
  };

  const handleMarkReadThread = (value) => {
    if (value && _.some(value.messages, (message) => message.read === false)) {
      const copyTreads = _.map(messagesThread, (thread) => {
        if (thread.id === value.id) {
          return {
            ...thread,
            messages: thread.messages.map((message) => ({
              ...message,
              read: true,
            })),
          };
        } else {
          return thread;
        }
      });
      setMessagesThread(copyTreads);
    }
  };

  return (
    <>
      {loadingMessagesThread ? (
        <ZoneMessagesPanelSkeleton></ZoneMessagesPanelSkeleton>
      ) : (

        <div className={`${classes.containerZoneMessagesPanel}`}>
          <Scrollbar>
            {_.isEmpty(messagesThread) ? (
              <div className={classes.containerNotMessages}>
                <span>
                  <Icon
                    color={"#2C74FF"}
                    icon={"No-Messages"}
                    size={"5rem"}
                    isClara
                  />
                  <label>You currently have no messages</label>
                </span>
              </div>
            ) : (
              <>
                {messagesThread.map((thread, index) => {
                  return (
                    <Message
                      thread={thread}
                      isSelected={_.get(thread, "id") === thread.id}
                      selectMessage={handleSelectMessage}
                      key={JSON.stringify(thread) + index}
                      message={_.last(thread.messages)}
                      index={index}
                      handleMarkImportant={toggleMessageFlag}
                      event={_.get(thread, "action.type", "")}
                      important={thread.important}
                      typeMessage={_.get(thread, "type")}
                    />
                  );
                })}
              </>
            )}
          </Scrollbar>
        </div>
      )}
    </>
  );
};

export default ZoneMessagesPanel;

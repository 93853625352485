import React, { FC } from "react";
import Wizard from "../../../../generic/components/Wizard";
import ModalAssistant, {
  ModalAssistantProps,
} from "../../../../generic/components/Modal/ModalAssistant";
import MachineAuthorisedSignatories from "./machine";
import { useDispatch } from "react-redux";
import useTranslate from "../../../../generic/hooks/useTranslate";
import useModal from "../../../../generic/hooks/useModal";

interface ModalAddressAndFamilyContactProps extends ModalAssistantProps {
  paramsMutation: object;
  onClose(): void;
}

const ModalAddressAndFamilyContact: FC<ModalAddressAndFamilyContactProps> = ({
  children,
  open = false,
  paramsMutation,
  ...props
}: ModalAddressAndFamilyContactProps) => {

  //   const dispatch = useDispatch();
  const { translate } = useTranslate();
  const { closeModal: onClose } = useModal();

  const handleClose = (values) => {
    onClose();
  };

  return (
    <ModalAssistant open={open}>
      <Wizard
        machine={MachineAuthorisedSignatories}
        initialValues={paramsMutation}
        onComplete={handleClose}
      />
    </ModalAssistant>
  );
};

export default ModalAddressAndFamilyContact;

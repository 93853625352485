import React from 'react';
import Typography from "./../../../../src/modules/generic/components/Texts/Typography";
const Title = ({ children, ...props }) => {
    
    return (
        <Typography  component="h1" variant="h2" color="blue" {...props}> {children} </Typography>
    );
}



export default Title;

import React from 'react';
import { Box, Divider } from '@mui/material';
import Typography from 'src/modules/generic/components/Texts/Typography';
import TwoColumnsData from "src/modules/generic/components/TwoColumnsData";
import useFormat from 'src/modules/generic/hooks/useFormat';

const DomesticTransfer = ({ defineJurisdiction, bankInformation }) => {
  const { moneyFormat } = useFormat();
  const domestic = bankInformation?.product?.bankInformation?.find(info => info?.wireDetails?.type === "DOMESTIC");

  return (
    <>
      <Box mr={2} mb={3} sx={{ width: '100%' }}>
        {defineJurisdiction === "ADGM" && (
          <>
            <Box mb={1}>
              <Typography variant={'h5'} color={'black'} >
                <b>Payment amount USD {moneyFormat(bankInformation?.product?.value)} / AED {moneyFormat(bankInformation?.rateConversion?.inclusiveOfVAT)} </b>
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography variant={'h5'} color={'black'} >
                <b>Reference number {bankInformation.reference}</b>
              </Typography>
            </Box>
          </>
        )}
        {defineJurisdiction === "CAYMAN" && (
          <>
            <Box mb={1}>
              <Typography variant={'h5'} color={'black'} >
                <b>Payment amount USD {moneyFormat(bankInformation?.product?.value)}</b>
              </Typography>
            </Box>
          </>
        )}
        <Divider />
      </Box>
      <TwoColumnsData
        variant='simple'
        items={[
          {
            label: 'Routing no.',
            value: domestic?.beneficiary?.routingNumber
          },
          {
            label: 'Account no.',
            value: domestic?.beneficiary?.accountNo
          },
          {
            label: 'Account type',
            value: 'Business Checking'
          },
          {
            label: 'Beneficiary',
            value: `${domestic?.beneficiary?.name} ${domestic?.beneficiary?.address}` ,
          },
          {
            label: 'Receiving bank',
            value: `${domestic?.receivingBank?.name} ${domestic?.receivingBank?.address}`

          }
        ]}
      />
    </>
  )
}

export default DomesticTransfer;

import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateLabel from 'src/modules/generic/components/Inputs/LabelInput';
import * as Yup from 'yup';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import FileUploaderInput from 'src/components/inputs/FileUploader/FileUploaderInput';
import classes from './classes.module.scss';

function AlreadyApprovedUploadDocumentsFormStep() {
  const { next, context, prev,cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  
  const initialValues = useMemo(() => {
    return context?.AlreadyApprovedUploadDocumentsFormStep ?? {}
  }, [context]);
  
  const handleCancel = () => {
    cancel();
  }

  const handlePrev = () => {
    prev();  
  }

  const handleSubmit = async (values) => {
    next(values);
  }
  
  useEffect(() =>{
    dispatch(addAssistantText('If you have already received pre-approval from ADGM for your Tech Startup License, you will need to provide us with the business plan and pitch deck shared with ADGM and written confirmation from ADGM confirming pre-approval. These documents need to be included when we submit your incorporation application to ADGM.'));

    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch]);
  
  const schema = Yup.object().shape({
    businessTemplate: Yup.object().nullable().required('Required'),
    confirmationEmail: Yup.object().nullable().required('Required'),
    pitchDeck: Yup.object().nullable().required('Required'),
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={ { mode: 'onChange' } }
    >
      <TemplateWizard
        props={{
          title: 'Upload your documents',
          skeleton: null,
          subTitle: 'Business plan',
          variant: 'simple'
        }}
        buttons={{
          previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>{translate('BUTTON_CONFIRM')}</SubmitButton>,
        }}
      >
          <TemplateLabel label='Upload your business plan approved by ADGM' isRequired={true}>
            <ControllerInput
              className={classes.documentUploaderFullLength}
              render={FileUploaderInput}
              defaultValue=""
              name="businessTemplate"
            />
          </TemplateLabel>

          <TemplateLabel label='Upload ADGM confirmation email' isRequired={true}>
            <ControllerInput
              className={classes.documentUploaderFullLength}
              render={FileUploaderInput}
              defaultValue=""
              name="confirmationEmail"
            />
          </TemplateLabel>
        
          <TemplateLabel label='Upload your pitch deck' isRequired={true}>
            <ControllerInput
              className={classes.documentUploaderFullLength}
              render={FileUploaderInput}
              defaultValue=""
              name="pitchDeck"
            />
          </TemplateLabel>
      </TemplateWizard>
    </Form>
  );
}

export default AlreadyApprovedUploadDocumentsFormStep;

import React, { FC, forwardRef, useImperativeHandle } from 'react';

interface HiddenValueSetterProps {
    name: string;
    onChange: any;
    defaultValue: any;
}

const HiddenValueSetter: FC<HiddenValueSetterProps> = forwardRef(
    ({ name, onChange, defaultValue }, ref) => {

        useImperativeHandle(ref, () => {
            return { onChange }
        }, []);

        return (
            <input
                className="d-none"
                type={'text'}
                name={name}
                onChange={onChange}
                value={defaultValue}
            />
        );
    }
);

export default HiddenValueSetter;
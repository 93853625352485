import useGetGroupCompanyAssetsAndRevenue from './graphql/useGetGroupCompanyAssetsAndRevenue';
import useGetPINCAPrices from "src/views/modules/startups/renewal/pincasPrice/queries/useGetPINCAPrices";
import useSession from 'src/modules/session/hooks/useSession';
import useUpdateGroupCompany from './graphql/useUpdateGroupCompany';
import useAssetsAndRevenueMapper from './useAssetsAndRevenueMapper';
const useServices = ({groupCompanyId})=>{
    const { query } = useGetGroupCompanyAssetsAndRevenue({});
    const { startupId } = useSession();
    const update = useUpdateGroupCompany()
    const { getInitialData, getSubmitData } = useAssetsAndRevenueMapper();
    const { manualQuery: getPincasPrices } = useGetPINCAPrices({
        variables: {
          filters: {
            jurisdictions: ["ADGM"],
          },
        },
      });
      
    const pincasData = async () => {
    const pincasData = await getPincasPrices();
    return { pincasData };
    }
    const initialData = async () => {
        const data = await query({startupId,groupCompanyId});
        
        return getInitialData(data);
    }
    
    const save = (context) => {
        return update(groupCompanyId,getSubmitData(context))
    }

    return {
        initialData,
        save,
        pincasData
    }
}
export default useServices;
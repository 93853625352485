import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import _ from "lodash";

const ObserverField = ({
  nameObserver,
  show = false,
  callbackObserver = (value) => value,
  name,
  ...props
}) => {

  const { setValue, watch, register } = useFormContext();
  const observerValue = watch(nameObserver);
  const valueOnchange = watch(name);

  useEffect(() => {
    if (!_.isEmpty(observerValue)) {
      let value;
      if (typeof observerValue === "object") {
        value = callbackObserver({ ...observerValue, oldValue: valueOnchange });
      } else {
        value = callbackObserver({ observerValue, oldValue: valueOnchange });
      }
      try {
        !valueOnchange && register({ name });
      } catch (e) {
        console.log(e);
      }
      setValue(name, value);
    }
  }, [JSON.stringify(observerValue)]);

  return <>{show && <pre>{JSON.stringify(observerValue, null, 1)}</pre>}</>;
};

export default ObserverField;

import _ from "lodash";
import React, { Component } from 'react';
import { Field } from "formik";
import moment from "moment";

import classes from "../Styles.module.scss";
import ClaraSelect from "../../../../../../../v1/components/Form/ClaraSelect/ClaraSelect";
import { ClaraRadioWithButton, TextAreaInput, TextInput, TextInputDate, ClaraInputDate,TextType } from '../../../../../../../v1/components';
import ClaraInputRadioButton from "../../../../../../../v1/components/Form/ClaraInputRadioButton/ClaraInputRadioButton";
import Checkbox from "../../../../../../../v1/containers/Forms/Inputs/Checkbox";
import FormikHelper from "../../../../../../../v1/utils/formikHelper";
import ClaraUpload from "../../../../../../../v1/components/Form/ClaraUpload/ClaraUpload";
import ClaraCountrySelector from "../../../../../../../v1/components/Form/ClaraCountrySelector/ClaraCountrySelector";
import AuthorisedSignatories from "../../../../../../inputs/AuthorizedSignatories/AuthorizedSignatories";
import Line from "../../../../../../generic/Line/Line";
import classNames from "classnames";
import {
	groupCompanyRecipientNotice, stakeholderAuthorizedSignatory, stakeholderRecipientNotice
} from '../../../../../../../v1/containers/Forms/generateDocumentForm/FormsGenerate/helper';

class AdvisorAgreementDoc extends Component {

    constructor(props) {
        super(props);
        this.state = {
            typeSelected: undefined,
            advisorSelected: undefined,
            groupCompanySelected: undefined,
            authorizedSelected: undefined,
            authorizedSignatories: [],
            authorizedSignatoriesList: [],
            documentTypes: [],
            documentTypesList: [],
            dynamicParams: [],
            date: undefined
        }
    };

    componentWillMount() {
        if (this.props.values) {
            const values = this.props.values.values ? this.props.values.values : this.props.values;

            this.setState({
                typeSelected: values.documentType,
                advisorSelected: values.advisor,
                groupCompanySelected: values.company,
                authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.company).map(as => ({
                    id: as.id,
                    label: as.label
                }))
            });
        }

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.values) {
            const values = nextProps.values.values ? nextProps.values.values : nextProps.values;
            this.setState({
                authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.company)
                    .map(as => ({
                        id: as.id,
                        label: as.label
                    }))
            });
        }
    }

    selectType = (documentType, extraParams, form) => {
        this.setState({
            typeSelected: documentType,
            dynamicParams: this.getDynamicParamsByType(documentType)
        }, () => {
            FormikHelper.setValueInTheCorrectPosition('terms', form, []);
            FormikHelper.setValueInTheCorrectPosition('documentType', form, documentType);
            this.state.dynamicParams.forEach((dynamicParam, i) => {
                FormikHelper.setValueInTheCorrectPosition(
                    `terms[${i}].key`,
                    form,
                    dynamicParam.key || 'NO_KEY'
                );
            })
        });
    };

    getDynamicParamsByType(documentType) {
        const type = this.props.documentTypes.filter(agrType => agrType.code === documentType)[0] || {};
        return type.fields || [];
    }

    getGroupCompanies = () => {
        let authorizedSignatories = this.props.authorizedSignatories;
        let groupCompanies = this.props.groupCompanies;
        let companies = [];
        if (groupCompanies && authorizedSignatories) {
            companies = _.filter(groupCompanies, (groupCompany) => {
                let fined = _.filter(authorizedSignatories, (authorized) => {
                    if (groupCompany.authorizedSignatories) {
                        return groupCompany.authorizedSignatories.some(au => au.stakeholder.id === authorized.id);
                    }
                    return false;
                });
                if (fined) {
                    return true;
                }
                return false;
            });
        }
        return companies;
    };

    selectGroupCompany = (gcId, extraParams, form) => {
        const groupCompany = this.findGroupCompany(gcId);

        // this is for a weird behaviour when hit edit button after preview a document
        if (this.state.groupCompanySelected && groupCompany) {
            return null;
        }

        this.setState({
            groupCompanySelected: groupCompany
        });

        if (groupCompany) {

            if (!groupCompany.registeredAddress) {
                groupCompany.registeredAddress = {}
            }

            let authorizedSignatories = _.get(form, 'values.company.authorizedSignatories', []);

            form.setValues({
                ...form.values,
                company: {
                    id: gcId,
                    name: groupCompany.name || '',
                    country: _.get(groupCompany, 'country.code', null) || _.get(groupCompany, 'country', null),
                    jurisdiction: groupCompany.jurisdiction,
                    registeredNumber: groupCompany.registeredNumber || '',
                    type: groupCompany.type || '',
                    registeredAddress: {
                        ...groupCompany.registeredAddress, country: _.get(groupCompany, 'registeredAddress.country.code', null) || _.get(groupCompany, 'registeredAddress.country', null)
                    },
                    authorizedSignatories: authorizedSignatories,
					recipientNotice: groupCompanyRecipientNotice(form.values.company, groupCompany)
                }
            });

            this.setState({
                groupCompanySelected: groupCompany,
            })
        } else {
            form.setValues({
                ...form.values,
                company: {}
            });
        }
    };

    findGroupCompany(gcId) {
        return this.props.groupCompaniesList.find(gc => gc.id === gcId);
    };

    selectAdvisor = (advisor, extraParams, form, cb) => {
        const stakeholder = this.findStakeholder(advisor);

        this.setState({
            advisorSelected: stakeholder,
        });


        if (stakeholder) {
            let authorizedSignatory = {};

            if (!stakeholder.address) {
                _.set(stakeholder,'address', {})
            }

            const _advisor = !_.isEmpty(form.values.advisor) ? form.values.advisor : stakeholder;

            if (stakeholder.isAnEntity) {
				authorizedSignatory = stakeholderAuthorizedSignatory(_advisor);
                // authorizedSignatory = {
                //     fullName: typeof _advisor.authorizedSignatory === 'object'
                //         ? _.get(_advisor, 'authorizedSignatory.fullName', '')
                //         : _.get(_advisor, 'authorizedSignatory', ''),
                //     email: _advisor.email || ''
                // }
            }

            form.setValues({
                ...form.values,
                advisor: {
                    id: advisor,
                    fullName: _advisor.fullName || '',
                    email: _advisor.email || '',
                    passportNumber: _advisor.passportNumber || '',
                    registeredNumber: _advisor.registeredNumber || '',
                    nationality: _.get(_advisor, 'nationality.code', null) || _.get(_advisor, 'nationality', null),
                    jurisdiction: _advisor.jurisdiction,
                    otherJurisdiction: _.get(_advisor, 'otherJurisdiction', ''),
                    type: _advisor.type || '',
                    address: { ..._advisor.address, country: _.get(_advisor, 'address.country.code', null) || _.get(_advisor, 'address.country', null) },
                    isAnEntity: _advisor.isAnEntity,
                    authorizedSignatory: authorizedSignatory,
					recipientNotice: stakeholderRecipientNotice(form.values.advisor, stakeholder)
                }
            });
        } else {
            form.setValues({
                ...form.values,
                advisor: {}
            });
        }
    };

    findStakeholder(roleId) {
        return this.props.stakeholderList.find(stakeholder => stakeholder.id === roleId);
    };

    findStakeholderAuthorized(roleId) {
        return this.props.stakeholderList.filter(stakeholder => {
            return stakeholder.roles.some(role => role.stakeholder.id === roleId);
        })[0] || null;
    };

    filterAuthorizedByGroupCompany(groupCompany) {
        if (groupCompany) {
            let gc = this.findGroupCompany(groupCompany.id);
            return this.props.authorizedSignatories.filter(authorized => {
                return authorized.groupCompanyId === gc.id
            });
        }
    }

    selectAuthorized = (value, form, field, replace) => {
        const stakeholder = this.props.authorizedSignatories.find(e => e.id === value);
        const email = stakeholder ? stakeholder.email : '';
        replace(0, { id: value, email })
    };

    renderAdvisorAuthorizedSignatory(field) {
        const { tooltips = {} } = this.props;

        if (this.state.advisorSelected.isAnEntity) {
            return (
                <React.Fragment>
                    <Field name='advisor.authorizedSignatory.fullName'>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='text'
                                classNameInput={{ root: classes.ContainerRow }}
                                label='Corporate signatory '
                                field={field}
                                form={form}
                                placeholder={"Corporate signatory"}
                                tooltip={tooltips["Authorised Signatory"]}
                            />
                        )}
                    </Field>
                    <Field name='advisor.authorizedSignatory.email'>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='email'
                                label="Corporate signatory's email address "
                                field={field}
                                classNameInput={{ root: classes.ContainerRow }}
                                form={form}
                                onChange={(e) => this.updateEmailValue(e, form)}
                                placeholder={"Email address"}
                                tooltip={tooltips['Authorised Signatory Email']}
                            />
                        )}
                    </Field>
                </React.Fragment>
            )
        }
    };

    updateEmailValue(e, form) {
        if (this.state.advisorSelected.isAnEntity) {
            form.setFieldValue('advisor.authorizedSignatory.email', e.target.value, false)
        }
    }

    getDateForPickerFromData(date) {
        if (!date) return undefined;
        return moment(date).toDate();
    }

    handleChange(e, field, form) {

        const date = e === null ? null : moment(e).format("YYYY-MM-DD");

        FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

        this.setState({
            [field.name]: e
        })
    }

    getAuthorizedSignatoriesList = (index, form) => {
        if (form.values && form.values.company) {
            return this.filterAuthorizedByGroupCompany(form.values.company).map(as => ({
                id: as.id,
                label: as.label
            }))
        } else {
            return [];
        }
    };

    getLabel = (key) => {
        switch (key) {
            case "fullName":
                if (this.state.advisorSelected.isAnEntity) {
                    return "Company name";
                } else {
                    return "Name";
                }
            case "country":
                if (this.state.advisorSelected.isAnEntity) {
                    return "Country of incorporation";
                } else {
                    return "Nationality";
                }
            case "address":
                if (this.state.advisorSelected.isAnEntity) {
                    return "Registered address";
                } else {
                    return "Residential address";
                }
            case "passportOrRegistered":
                if (this.state.advisorSelected.isAnEntity) {
                    return "Company number";
                } else {
                    return "Passport number";
                }
        }
    };

    getField = (key) => {
        switch (key) {
            case "passportOrRegistered":
                if (this.state.advisorSelected.isAnEntity) {
                    return "advisor.registeredNumber";
                } else {
                    return "advisor.passportNumber";
                }
        }
    };

    getTooltip = (key, fieldName) => {
        switch (key) {
            case "fullName":
                if (this.state.advisorSelected.isAnEntity) {
                    return "Advisor Company's Name";
                } else {
                    return "Advisor's Name";
                }
            case "passportOrRegistered":
                if (this.state.advisorSelected.isAnEntity) {
                    return "Company Number";
                } else {
                    return "Passport Number";
                }
            case "country":
                if (this.state.advisorSelected.isAnEntity) {
                    return "Country";
                } else {
                    return "Nationality";
                }
            case "addressLine":
                if (this.state.advisorSelected.isAnEntity) {
                    return "Registered Address - Address";
                } else {
                    return "Residential Address - Address";
                }
            case "addressCity":
                if (this.state.advisorSelected.isAnEntity) {
                    return "Registered Address - City";
                } else {
                    return "Residential Address - City";
                }
            case "addressState":
                if (this.state.advisorSelected.isAnEntity) {
                    return "Registered Address - State";
                } else {
                    return "Residential Address - State";
                }
            case "addressCountry":
                if (this.state.advisorSelected.isAnEntity) {
                    return "Registered Address - Country";
                } else {
                    return "Residential Address - Country";
                }
            case "addressPostCode":
                if (this.state.advisorSelected.isAnEntity) {
                    return "Registered Address - Post Code";
                } else {
                    return "Residential Address - Post Code";
                }
            case "recipientNotice":
                if (fieldName === 'company') {
                    return "Company's";
                } else {
                    return "Advisor's";
                }
        }
    };

    getPlaceholder = (key) => {
        switch (key) {
            case "passportOrRegistered":
                if (this.state.advisorSelected.isAnEntity) {
                    return "Company number";
                } else {
                    return "Passport number";
                }
        }
    };

    renderRecipientNotice(fieldName, isAnEntity) {
        const { tooltips = {} } = this.props;

        if (isAnEntity) {
            return (
                <React.Fragment>
                    <Field name={`${fieldName}.recipientNotice.fullName`}>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                classNameInput={{ root: classes.ContainerRow }}
                                type='text'
                                label='Recipient for notices '
                                field={field}
                                form={form}
                                placeholder={"Recipient's name"}
                                tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices"]}
                            />
                        )}
                    </Field>
                    <div className={` ${classes.Select}`}>
                        <Field name={`${fieldName}.recipientNotice.address.country`}>
                            {({ field, form }) => (
                                <ClaraCountrySelector
                                    jurisdictionsEnabled={false}
                                    isRequired={{ country: true }}
                                    countryField={`${fieldName}.recipientNotice.address.country`}
                                    label={"Recipient's address "}
                                    setFieldValue={form.setFieldValue}
                                    initialValues={{ country: _.get(form.values, `${fieldName}.recipientNotice.address.country`, '') }}
                                    returnCountryAsString={_.isEmpty(_.get(form.values, `${fieldName}.recipientNotice.address.country.code`, ''))}
                                    additionalStyle={'ClaraCountrySelectorModal'}
                                    tooltips={{
                                        country: tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Country"]
                                    }}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.address.street`}>
                            {({ field, form }) => (
                                <TextAreaInput
                                    type='text'
                                    label={' '}
                                    field={field}
                                    form={form}
                                    placeholder={"Address"}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Address"]}
                                />
                            )}
                        </Field>
                    </div>
                    <Field name={`${fieldName}.recipientNotice.address.city`}>
                        {({ field, form }) => (
                            <TextInput
                                type='text'
                                classNameInput={{ root: classes.ContainerRow }}
                                field={field}
                                form={form}
                                placeholder={"City"}
                                label={' '}
                                tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - City"]}
                            />
                        )}
                    </Field>
                    <Field name={`${fieldName}.recipientNotice.address.state`}>
                        {({ field, form }) => (
                            <TextInput
                                type='text'
                                classNameInput={{ root: classes.ContainerRow }}
                                field={field}
                                form={form}
                                placeholder={"State / Region"}
                                label={' '}
                                tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - State"]}
                            />
                        )}
                    </Field>
                    <Field name={`${fieldName}.recipientNotice.address.zipCode`}>
                        {({ field, form }) => (
                            <TextInput
                                classNameInput={{ root: classes.ContainerRow }}
                                type='text'
                                field={field} form={form}
                                label={' '}
                                placeholder={"Post code"}
                                tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Post Code"]}
                            />

                        )}
                    </Field>
                    <Field name={`${fieldName}.recipientNotice.email`}>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                classNameInput={{ root: classes.ContainerRow }}
                                type='email'
                                label="Recipient's email address "
                                field={field}
                                form={form}
                                placeholder={"Email address"}
                                tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Email Address"]}
                            />
                        )}
                    </Field>
                </React.Fragment>
            )
        }
    };

    renderAgreementDurationFields(form) {
        const { tooltips = {} } = this.props;
        if (!_.get(form, 'values.terms.agreementDuration')) {
            return null
        }

        return (
            <React.Fragment>
                {
                    form.values.terms.agreementDuration === 'otherDuration' ?
                        <Field name='terms.otherDuration'>
                            {({ field, form }) => (
                                <ClaraInputDate
                                    start={{ month: 0, year: 0 }}
                                    stop={{ month: 11, year: 99 }}
                                    required={true}
                                    field={field}
                                    form={form}
                                    label={'Duration '}
                                    tooltips={{ years: tooltips["Duration Years"], months: tooltips["Duration Months"] }}

                                />
                            )}
                        </Field>

                        : null
                }
            </React.Fragment>
        );

    }

    getStakeholderAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add new individual stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
                placeholder: "Stakeholder's name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: false,
                    // roles : [{
                    // 	role:"SHAREHOLDER",
                    // 	groupCompany: this.state.groupCompanySelected.id,
                    // }]
                },
                paramsReturn: 'isAnEntity'
            },
        ]
    };

    getCompanyAdds = () => {
        return [{
            type: "groupCompany",
            label: "Add new company",
            textWarning: "This will create a new group company for your startup. You can add or amend the details of this group company on its profile page.",
            placeholder: "Company name",
            startupId: this.props.startup.id,
            params: null,
            paramsReturn: `
				name
				authorizedSignatories {
					id
					stakeholder {
						id
						email
						fullName
						roles {   id
        name
        groupCompany { id name}
        stakeholder { id } }
					}
				}
			`
        }]
    };

    getAuthorizedSignatoryAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add new individual stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
                placeholder: "Enter stakeholder's name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: false,
                    roles: [{
                        role: "DIRECTOR",
                        groupCompany: this.state.groupCompanySelected.id,
                    }]
                }
            },
        ]
    };

    handleStakeholderCreate = (value, name, additionalValue, form) => {

        let { stakeholders, stakeholderList } = this.props;
        let newSh = { id: value.id, fullName: name, ...additionalValue };
        stakeholderList.push(newSh);

        stakeholders.push({ id: value.id, label: name });
        // this.setState({ groupCompaniesList, groupCompanies });

    };

    handleGroupCompanyCreate = (value, name, additionalValue, form) => {

        let { groupCompanies, groupCompaniesList } = this.props;
        let newGc = { id: value.id, name, ...additionalValue };
        groupCompaniesList.push(newGc);

        groupCompanies.push({ id: value.id, label: name });
        // this.setState({ groupCompaniesList, groupCompanies });

    };

    handleAuthorizedSignatoryUpdate = (value) => {

    };

    render() {
        const isRequired = message => value => (!!value ? undefined : message);
        const { tooltips = {}, formType } = this.props;

        return (
            <React.Fragment>
                <h1 className={classes.title}>
                    Edit Document
                </h1>
                {formType === 'uploadToEdit' ? (<div>
                    <Field name={`file`}>
                        {({ field, form }) => (
                            <ClaraUpload
                                required={true}
                                name={`filepond`}
                                label='Upload your document '
                                field={field}
                                form={form}
                            />
                        )}
                    </Field>
                </div>) : null}
                <Field name='agreementName'>
                    {({ field, form }) => (
                        <TextInput
                            classNameInput={{ root: classes.ContainerRow }}
                            required={true}
                            type='text'
                            label='Document name '
                            field={field}
                            form={form}
                            placeholder={"Document name"}
                            tooltip={tooltips['Document Name']}
                        />
                    )}
                </Field>

                <Line className={classes.line} />
                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                    <Field name='advisor.id'>
                        {({ field, form }) => (
                            <ClaraSelect
                                required={true}
                                field={field}
                                form={form}
                                label={"Select advisor "}
                                adds={this.getStakeholderAdds()}
                                lists={this.props.stakeholders}
                                mode={"classic"}
                                callbacks={{
                                    createValues: this.handleStakeholderCreate,
                                    updateValues: this.selectAdvisor
                                }}
                                placeholder={"Select from the list"}
                                tooltip={tooltips["Select Advisor"]}
                            />

                        )}
                    </Field>
                </div>
                {
                    this.state.advisorSelected ?
                        <React.Fragment>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                {
                                    this.state.advisorSelected.isAnEntity ?
                                        <>
                                            <h1>Advisor Company Details</h1>
                                            <p>Please verify or update the advisor company's information below</p>
                                        </>
                                        :
                                        <>
                                            <h1>Advisor Details</h1>
                                            <p>Please verify or update the advisor's information below</p>
                                        </>
                                }
                            </div>

                            <Field name='advisor.fullName'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        classNameInput={{ root: classes.ContainerRow }}
                                        label={this.getLabel("fullName") + ' '}
                                        field={field}
                                        form={form}
                                        placeholder={'Name'}
                                        required={true}
                                        tooltip={tooltips[this.getTooltip('fullName')]}
                                    />
                                )}
                            </Field>
                            <div className={`${classes.Select}`}>
                                <Field name='advisor.nationality'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={this.state.advisorSelected.isAnEntity}
                                            countryField={'advisor.nationality'}
                                            label={this.getLabel('country') + ''}
                                            jurisdictionField={'advisor.jurisdiction'}
                                            jurisdictionLabel={'Jurisdiction '}
                                            returnCountryAsString={true}
                                            otherJurisdictionField={'advisor.otherJurisdiction'}
                                            setFieldValue={form.setFieldValue}
                                            initialValues={{ jurisdiction: _.get(form.values, 'advisor.jurisdiction') }}
                                            additionalStyle={'ClaraCountrySelectorModal'}
                                            tooltips={{
                                                country: tooltips[this.getTooltip('country')],
                                                jurisdiction: tooltips['Jurisdiction'],
                                                other: tooltips['Other Jurisdiction']
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            {
                                this.state.advisorSelected.isAnEntity ?
                                    <React.Fragment>
                                        {/*
                                        <div className={classes.ContainerRow}>
                                            <Field name='advisor.jurisdiction'>
                                                {({ field, form }) => (
                                                    <TextInput
                                                        required={true}
                                                        type='text'
                                                        label='Jurisdiction: '
                                                        field={field}
                                                        form={form}
                                                        placeholder={'Jurisdiction...'}
                                                        tooltip={tooltips['Jurisdiction']}
                                                    />
                                                )}
                                            </Field>
                                        </div>
*/}
                                        <Field name='advisor.type'>
                                            {({ field, form }) => (
                                                <TextInput
                                                    classNameInput={{ root: classes.ContainerRow }}
                                                    required={true}
                                                    type='text'
                                                    label='Company type '
                                                    field={field}
                                                    form={form}
                                                    placeholder={'Company type'}
                                                    tooltip={tooltips['Company Type']}
                                                />
                                            )}
                                        </Field>
                                    </React.Fragment>
                                    :
                                    null
                            }
                            <Field name={this.getField("passportOrRegistered")}>
                                {({ field, form }) => (
                                    <TextInput
                                        required={true}
                                        type='text'
                                        classNameInput={{ root: classes.ContainerRow }}
                                        label={this.getLabel("passportOrRegistered") + ' '}
                                        field={field}
                                        form={form}
                                        placeholder={this.getPlaceholder("passportOrRegistered")}
                                        tooltip={tooltips[this.getTooltip("passportOrRegistered")]}
                                    />
                                )}
                            </Field>
                            {
                                !this.state.advisorSelected.isAnEntity ?
                                    <React.Fragment>
                                        <Field name='advisor.email'>
                                            {({ field, form }) => (
                                                <TextInput
                                                    type='email'
                                                    classNameInput={{ root: classes.ContainerRow }}
                                                    label='Email address '
                                                    field={field}
                                                    form={form}
                                                    placeholder={'Email address'}
                                                    required={true}
                                                    tooltip={tooltips["Advisor's Email Address"]}
                                                />
                                            )}
                                        </Field>
                                    </React.Fragment>
                                    :
                                    null
                            }
                            <div className={` ${classes.Select}`}>
                                <Field name='advisor.address.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true }}
                                            jurisdictionsEnabled={false}
                                            countryField={'advisor.address.country'}
                                            returnCountryAsString={true}
                                            label={this.getLabel('address') + ''}
                                            setFieldValue={form.setFieldValue}
                                            additionalStyle={'ClaraCountrySelectorModal'}
                                            tooltips={{
                                                country: tooltips[this.getTooltip('addressCountry')]
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='advisor.address.street'>
                                    {({ field, form }) => (
                                        <TextAreaInput
                                            type='text'
                                            label={' '}
                                            field={field}
                                            form={form}
                                            placeholder={'Address'}
                                            tooltip={tooltips[this.getTooltip('addressLine')]}

                                        />
                                    )}
                                </Field>
                            </div>
                            <Field name='advisor.address.city'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        classNameInput={{ root: classes.ContainerRow }}
                                        label=' '
                                        field={field}
                                        form={form}
                                        placeholder={'City'}
                                        tooltip={tooltips[this.getTooltip('addressCity')]}
                                    />
                                )}
                            </Field>
                            <Field name='advisor.address.state'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        classNameInput={{ root: classes.ContainerRow }}
                                        label=' '
                                        field={field}
                                        form={form}
                                        placeholder={'State / Region'}
                                        tooltip={tooltips[this.getTooltip('addressState')]}
                                    />
                                )}
                            </Field>
                            <Field name='advisor.address.zipCode'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        label=' '
                                        classNameInput={{ root: classes.ContainerRow }}
                                        field={field}
                                        form={form}
                                        placeholder={"Post code"}
                                        tooltip={tooltips[this.getTooltip('addressPostCode')]}
                                    />
                                )}
                            </Field>
                            {this.renderRecipientNotice('advisor', this.state.advisorSelected.isAnEntity)}
                            {this.renderAdvisorAuthorizedSignatory('advisor')}
                        </React.Fragment>
                        :
                        null
                }
                <Line className={classes.line} />
                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                    <Field name='company.id'>
                        {({ field, form }) => (
                            <ClaraSelect
                                required={true}

                                field={field}
                                form={form}

                                label={"Select company "}
                                lists={this.getGroupCompanies()}
                                mode={"classic"}
                                callbacks={{
                                    createValues: this.handleGroupCompanyCreate,
                                    updateValues: this.selectGroupCompany
                                }}
                                placeholder={"Select from the list"}
                                tooltip={tooltips['Select Company']}
                            />
                        )}
                    </Field>
                </div>
                {
                    this.state.groupCompanySelected ?
                        <React.Fragment>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Company Details</h1>
                                <p>Please verify or update the company's information below</p>
                            </div>
                            <Field name='company.name'>
                                {({ field, form }) => (
                                    <TextInput
                                        required={true}
                                        type='text'
                                        classNameInput={{ root: classes.ContainerRow }}
                                        label='Company name '
                                        field={field}
                                        form={form}
                                        placeholder={"Company name"}
                                        tooltip={tooltips["Company's Name"]}
                                    />
                                )}
                            </Field>
                            <div className={` ${classes.Select}`}>
                                <Field name='company.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={true}
                                            countryField={'company.country'}
                                            label={'Country of incorporation '}
                                            jurisdictionField={'company.jurisdiction'}
                                            returnCountryAsString={true}
                                            jurisdictionLabel={'Jurisdiction '}
                                            otherJurisdictionField={'company.otherJurisdiction'}
                                            setFieldValue={form.setFieldValue}
                                            initialValues={{ jurisdiction: _.get(form.values, 'company.jurisdiction') }}
                                            additionalStyle={'ClaraCountrySelectorModal'}
                                            tooltips={{
                                                country: tooltips["Company's Country"],
                                                jurisdiction: tooltips["Company's Jurisdiction"],
                                                other: tooltips['Other Jurisdiction']
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <Field name='company.type'>
                                {({ field, form }) => (
                                    <TextType
                                        required={true}
                                        type='text'
                                        groupCompany={this.state.groupCompanySelected}
                                        classNameInput={{ root: classes.ContainerRow }}
                                        label='Company type '
                                        field={field}
                                        form={form}
                                        placeholder={'Company type'}
                                        tooltip={tooltips["Company's Company Type"]}
                                    />
                                )}
                            </Field>
                            <Field name='company.registeredNumber'>
                                {({ field, form }) => (
                                    <TextInput
                                        required={true}
                                        classNameInput={{ root: classes.ContainerRow }}
                                        type='text'
                                        label='Company number '
                                        field={field}
                                        form={form}
                                        placeholder={'Company number'}
                                        tooltip={tooltips["Company's Company Number"]}
                                    />
                                )}
                            </Field>
                            <div className={`${classes.Select}`}>
                                <Field name='company.registeredAddress.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={false}
                                            countryField={'company.registeredAddress.country'}
                                            label={'Registered address '}
                                            returnCountryAsString={true}
                                            setFieldValue={form.setFieldValue}
                                            additionalStyle={'ClaraCountrySelectorModal'}
                                            tooltips={{
                                                country: tooltips["Company's Registered Address - Country"]
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.registeredAddress.street'>
                                    {({ field, form }) => (
                                        <TextAreaInput
                                            type='text'
                                            label={' '}
                                            field={field}
                                            form={form}
                                            placeholder={"Address"}
                                            tooltip={tooltips["Company's Registered Address - Address"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <Field name='company.registeredAddress.city'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        classNameInput={{ root: classes.ContainerRow }}
                                        label=' '
                                        field={field}
                                        form={form}
                                        placeholder={'City'}
                                        tooltip={tooltips["Company's Registered Address - City"]}
                                    />
                                )}
                            </Field>
                            <Field name='company.registeredAddress.state'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        label=' '
                                        classNameInput={{ root: classes.ContainerRow }}
                                        field={field}
                                        form={form}
                                        placeholder={'State / Region'}
                                        tooltip={tooltips["Company's Registered Address - State"]}
                                    />
                                )}
                            </Field>
                            <Field name='company.registeredAddress.zipCode'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        classNameInput={{ root: classes.ContainerRow }}
                                        label=' '
                                        field={field}
                                        form={form}
                                        placeholder={"Post code"}
                                        tooltip={tooltips["Company's Registered Address - Post Code"]}
                                    />
                                )}
                            </Field>
                            {this.renderRecipientNotice('company', true)}
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Authorised Signatory</h1>
                                <p>Please add all the signatories required to sign this document on behalf of the company.</p>
                            </div>

							<Field name='company.authorizedSignatories_error'>
								{({ field, form }) => (
									<React.Fragment>
										<span hasError={_.get(form.errors, "company.authorizedSignatories") ? "true" : "false"}>
											<AuthorisedSignatories
												modal={true}
                                                list={this.props.stakeholderList}
												fieldArrayName={'company.authorizedSignatories'}
												idGroupCompany={this.state.groupCompanySelected.id}
												handleAddListStakeholder={this.handleAuthorizedSignatoryUpdate}
												errorMessage={_.get(form.errors, "company.authorizedSignatories")}
											/>
										</span>
									</React.Fragment>
								)}
							</Field>

							<AuthorisedSignatories
                                list={this.state.authorizedSignatories}
                                fieldArrayName={'company.authorizedSignatories'}
                                idGroupCompany={this.state.groupCompanySelected.id}
                                handleAddListStakeholder={this.handleAuthorizedSignatoryUpdate}
                                modal={true}
                            />
                        </React.Fragment>
                        :
                        null
                }

                {
                    this.state.advisorSelected && this.state.groupCompanySelected ?
                        <React.Fragment>
                            <Line className={classes.line} />
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Services</h1>
                                <p>The activities the advisor will perform for your startup.</p>
                            </div>
                            <Field name='terms.timeCommitment'>
                                {({ field, form }) => (
                                    <TextInput
                                        required={true}
                                        type='text'
                                        classNameInput={{ root: classes.ContainerRow }}
                                        label='Time commitment '
                                        field={field}
                                        form={form}
                                        placeholder={'e.g. 5, 10, 20'}
                                        rightLabel={'Hours'}
                                        tooltip={tooltips['Time Commitment']}
                                    />
                                )}
                            </Field>
                            <div className={classes.RadioButton}>
                                <Field name='terms.timeCommitmentFrequency'>
                                    {({ field, form }) => (
                                        <ClaraInputRadioButton
                                            label={' '}
                                            field={field}
                                            form={form}
                                            classNameInput={classes.RadioButtonClass}
                                            value={field.value}
                                            options={[
                                                {
                                                    id: 'perWeek',
                                                    label: 'per week',
                                                    className: classes.RadioButtonIndividual,
                                                    classNameSelected: classes.SelectedBLUE
                                                },
                                                {
                                                    id: 'perMonth',
                                                    label: 'per month',
                                                    className: classes.RadioButtonIndividual,
                                                    classNameSelected: classes.SelectedBLUE
                                                },

                                            ]}
                                        />
                                    )}
                                </Field>
                            </div>

                            <div className={classes.ContainerRow}>
                                <Field name={'terms.meetingAttendance.isAdvisoryBoard'}>
                                    {({ field, form }) => (
                                        <ClaraRadioWithButton
                                            required={true}
                                            label={'Does the company have an advisory board? '}
                                            field={field}
                                            form={form}
                                            changeParams={field.name}
                                            options={[{ value: true, label: 'Yes' }, { value: false, label: 'No' }]}
                                            link={'https://academy.clara.co/glossary/a'}
                                            tooltip={tooltips['Advisory Board']}
                                        // onChange={(v) => { onChange(v,form) }}
                                        />
                                    )}
                                </Field>
                                <Field>
                                    {({ field, form }) => (
                                        _.get(form, 'values.terms.meetingAttendance.isAdvisoryBoard') ?
                                            <Field name='terms.meetingAttendance.advisoryBoardMeetingFrequency'>
                                                {({ field, form }) => (
                                                    <TextInput
                                                        required={true}
                                                        type='text'
                                                        classNameInput={{ root: classes.ContainerRow }}
                                                        label='Advisory board meeting frequency '
                                                        field={field}
                                                        form={form}
                                                        placeholder={'e.g. quarterly'}
                                                        tooltip={tooltips['Advisory Board Meeting Frequency']}
                                                    />
                                                )}
                                            </Field>
                                            : null
                                    )}
                                </Field>
                            </div>



                            <div className={`${classes.labelGroup}`}>
                                <label>Areas of advice<span style={{color:'red'}}>*</span></label>
                                <Field name='terms.adviceAreas.option_a'>
                                    {({ field, form }) => (
                                        <CheckboxInput
                                            id='terms.adviceAreas.option_a'
                                            label={'Business operations'}
                                            field={field}
                                            form={form}
                                            className={classes.checkBoxWizardIndividualInFlex }
                                            // className={{ root: `${classes.groupCheckBoxWizard} ${classes.groupCheckboxContainer}`, checkbox: classes.checkBoxWizard }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={`${classes.checkBoxWizard}`}>
                                <label>{' '}</label>
                                <Field name='terms.adviceAreas.option_b'>
                                    {({ field, form }) => (
                                        <CheckboxInput
                                            id='terms.adviceAreas.option_b'
                                            label={'Strategic planning'}
                                            field={field}
                                            form={form}
                                            // classNameInput={{ root: classes.checkBoxWizardIndividualInFlex }}
                                            className={classes.checkBoxWizardIndividualInFlex}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={`${classes.checkBoxWizard}`}>
                                <label>{' '}</label>
                                <Field name='terms.adviceAreas.option_c'>
                                    {({ field, form }) => (
                                        <CheckboxInput
                                            id='terms.adviceAreas.option_c'
                                            label={'Networking opportunities'}
                                            field={field}
                                            form={form}
                                            className={classes.checkBoxWizardIndividualInFlex}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={`${classes.checkBoxWizard}`}>
                                <label>{' '}</label>
                                <Field name='terms.adviceAreas.option_d'>
                                    {({ field, form }) => (
                                        <CheckboxInput
                                            id='advisor.services.option_d'
                                            label={'Business development'}
                                            field={field}
                                            form={form}
                                            className={classes.checkBoxWizardIndividualInFlex}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={`${classes.checkBoxWizard}`}>
                                <label>{' '}</label>
                                <Field name='terms.adviceAreas.option_e'>
                                    {({ field, form }) => (
                                        <CheckboxInput
                                            id='advisor.services.option_e'
                                            label={'Market environment'}
                                            field={field}
                                            form={form}
                                            className={classes.checkBoxWizardIndividualInFlex}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={`${classes.checkBoxWizard}`}>
                                <label>{' '}</label>
                                <Field name='terms.adviceAreas.option_f'>
                                    {({ field, form }) => (
                                        <CheckboxInput
                                            id='advisor.services.option_f'
                                            label={'Solutions to innovation challenges'}
                                            field={field}
                                            form={form}
                                            className={classes.checkBoxWizardIndividualInFlex}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={`${classes.checkBoxWizard}`}>
                                <label>{' '}</label>
                                <Field name='terms.adviceAreas.option_g'>
                                    {({ field, form }) => (
                                        <CheckboxInput
                                            id='advisor.services.option_g'
                                            label={'Overall business performance'}
                                            field={field}
                                            form={form}
                                            className={classes.checkBoxWizardIndividualInFlex}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={`${classes.checkBoxWizard}`}>
                                <label>{' '}</label>
                                <Field name='terms.adviceAreas.option_h'>
                                    {({ field, form }) => (
                                            <CheckboxInput
                                                id='terms.adviceAreas.option_h'
                                                label='Other'
                                                field={field}
                                                form={form}
                                                input={true}
                                                className={classes.checkBoxWizardIndividualInFlex}
                                                TextInput={{
                                                    placeholder: 'Describe other activities here'
                                                }}
                                            />
                                    )}
                                </Field>
                            </div>
                            <div className={`${classes.checkBoxWizard}`}>
                                <label>{' '}</label>
                                <Field name='terms.adviceAreas.option_error'>
                                    {({ field, form }) => (
                                        <React.Fragment>
                                            {/* {console.log("Form Error",form)} */}
                                            {/* {form.errors && <ErrorInput message={errorMessage}   />} */}
                                        </React.Fragment>
                                    )}
                                </Field>
                            </div>



                            <Field name='terms.meetingAttendance.duration'>
                                {({ field, form }) => (
                                    <TextInput
                                        required={true}
                                        type='text'
                                        classNameInput={{ root: classes.ContainerRow }}
                                        label='Meeting attendance '
                                        field={field}
                                        form={form}
                                        placeholder={'Meeting duration'}
                                        rightLabel={'Hours'}
                                        tooltip={tooltips['Meeting Attendance Duration']}
                                    />
                                )}
                            </Field>
                            <div className={`${classes.RadioButton} `}>
                                <Field name='terms.meetingAttendance.frequency'>
                                    {({ field, form }) => (
                                        <ClaraInputRadioButton
                                            label={' '}
                                            classNameInput={classes.RadioButtonClass}
                                            //classNameInput={classes.margin}
                                            field={field}
                                            form={form}
                                            value={field.value}
                                            options={[
                                                {
                                                    id: 'weekly',
                                                    label: 'Weekly',
                                                    className: classes.RadioButtonIndividual,
                                                    classNameSelected: classes.SelectedBLUE
                                                },
                                                {
                                                    id: 'monthly',
                                                    label: 'Monthly',
                                                    className: classes.RadioButtonIndividual,
                                                    classNameSelected: classes.SelectedBLUE
                                                },

                                            ]}
                                        //className={classes.radioButtonWizard}
                                        />
                                    )}
                                </Field>
                            </div>
                            <Line className={classes.line} />
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Advisory Services</h1>
                                <p>Select any that apply from the below:</p>
                            </div>
                            <div className={`${classes.labelGroup}`}>
                                <label>Promoting the company</label>
                                <Field name='terms.otherServices.option_a'>
                                    {({ field, form }) => (
                                        <CheckboxInput
                                            id='terms.otherServices.option_a'
                                            label={'The advisor shall actively promote the company, including by making introductions on behalf of the company to the advisor\'s overall network of business contacts and circulating the company\'s business plan, pitch deck and other materials as requested by the company to such contacts.'}
                                            field={field}
                                            form={form}
                                            // className={classes.checkBoxWizardIndividual}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={`${classes.labelGroup} `}>
                                <label>Introductions</label>

                                <Field name='terms.otherServices.option_b'>
                                    {({ field, form }) => (
                                        <CheckboxInput
                                            id='terms.otherServices.option_b'
                                            label={'The advisor shall help the company to find additional, potential founding team members and employees, including by making introductions on behalf of the company to the advisor\'s overall network of business contacts.'}
                                            field={field}
                                            form={form}
                                            // className={classes.checkBoxWizardIndividual}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={`${classes.labelGroup} `}>
                                <label>Fundraising</label>
                                <Field name='terms.otherServices.option_c'>
                                    {({ field, form }) => (
                                        <CheckboxInput
                                            id='terms.otherServices.option_c'
                                            label={'The advisor shall help the company to raise financing, including by making introductions on behalf of the company to the advisor\'s overall network of business contacts.'}
                                            field={field}
                                            form={form}
                                            // className={classes.checkBoxWizardIndividual}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={`${classes.labelGroup} `}>
                                <label>Strategic projects</label>
                                <Field name='terms.otherServices.option_d'>
                                    {({ field, form }) => (
                                        <CheckboxInput
                                            id='terms.otherServices.option_d'
                                            label={'The advisor shall help the company on at least one strategic project as requested by the company.'}
                                            field={field}
                                            form={form}
                                            className={classes.checkBoxWizardIndividual}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='terms.otherServices.option_e'>
                                    {({ field, form }) => (
                                        <TextAreaInput
                                            type='text'
                                            label={'Other'}
                                            field={field}
                                            form={form}
                                            placeholder={"Describe other activities here"}
                                        />
                                    )}
                                </Field>
                            </div>
                            <Line className={classes.line} />
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Compensation</h1>
                                <p>The grant of options to the advisor as compensation for the services.<a onClick={() => window.open("https://academy.clara.co/article/2LQfCK5jV3kwneL0bGYICv")}>Learn more...</a></p>
                            </div>
                            <>
                                <Field name='terms.compensation.numberShares'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='number'
                                            classNameInput={{ root: classes.ContainerRow }}
                                            label={'Number of shares '}
                                            field={field}
                                            form={form}
                                            placeholder={'Number'}
                                            tooltip={tooltips['Number Of Shares']}
                                        />
                                    )}
                                </Field>
                                <Field name='terms.compensation.percentageOwnership'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            classNameInput={{ root: classes.ContainerRow }}
                                            label={'Percentage ownership'}
                                            field={field}
                                            form={form}
                                            placeholder={'e.g. 1, 2, 2.5'}
                                            rightLabel={'%'}
                                            tooltip={tooltips['Percentage Ownership Of Shares']}
                                        />
                                    )}
                                </Field>
                            </>
                            <Line className={classes.line} />
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Place of Arbitration</h1>
                                <p>This contract’s dispute resolution mechanism uses arbitration under expedited ICC rules. Please select the place where you would like the arbitration to take place (please enter the name of the city and country and, if applicable, state or freezone), e.g. "London, United Kingdom" or "New York City, New York, United States of America” or "Dubai International Financial Centre, Dubai, United Arab Emirates”. Please note that the place of arbitration that you select can have a material impact on the proceedings and enforcement of the arbitration and arbitral award. Please seek legal advice.</p>
</div>
<Field name={`terms.placeOfArbitration`}>
    {({ field, form }) => (
        <TextInput
            required={true}
            type='text'
            label='Place of arbitration '
            field={field}
            classNameInput={{ root: classes.ContainerRow }}

            form={form}
            placeholder={"Place of arbitration"}
        />
    )}
</Field>
                            

                        </React.Fragment>
                        : null
                }

                {formType === 'uploadToEdit' ? <div>
                    <Field name='confirmSameData'>
                        {({ field, form }) => (
                            <Checkbox
                                className={classes.sameDataCheckbox}
                                field={field}
                                validate={isRequired('This field is required')}
                                form={form}
                                required={true}
                                label='All document details are verified and correct.'
                            />
                        )}
                    </Field>
                </div> : null}
            </React.Fragment>

        );
    }

}

const CheckboxInput = (props) => {

    const { label, options, required = false, form, field, tooltips, input } = props;
    const { value } = field;
    const [selection, setSelection] = React.useState(value ? value.id : 0);
    const [labelTextInput, setlLabelTextInput] = React.useState('');
    const [textInput, setTextInput] = React.useState({})
    const handleInput = () => {
        if (value && value.id !== selection) {
            setSelection(value.id);
            form.setFieldValue(`${field.name}.message`, '');
            if (!input) form.setFieldValue(`${field.name}.message`, label);
        }
    };

    if (value && value.id !== selection) {
        form.setFieldValue(`${field.name}.message`, label);
        setSelection(value.id);
    }
    React.useEffect(() => {
        if (props.TextInput && props.TextInput.label) {
            setlLabelTextInput(props.TextInput.label)
            setTextInput(_.omit(props.TextInput, ['label']))
        }
    }, [])

    handleInput();

    return (

        <div className={classes.inputCheckbox}>

            <Field name={`${field.name}.id`}>
                {({ field, form }) => (
                    <Checkbox
                        id={`${field.name}.id`}
                        label={label}
                        field={field}
                        form={form}
                        className={classNames(classes.checkBoxWizardIndividual, props.className )}
                        // className={classes.checkBoxWizardIndividual}
                    />
                )}
            </Field>

            {
                input && value && value.id &&
                <>
                    <Field name={`${field.name}.message`}>

                        {({ field, form }) => (
                            <div className={classes.InputContainer}>
                                <label>{labelTextInput}</label>
                                <TextInput
                                    type='text'
                                    field={field}
                                    form={form}
                                    {...textInput}
                                />
                            </div>)}
                    </Field>
                    <Line className={classes.line} />
                </>
            }
        </div>
    )

};

export default AdvisorAgreementDoc;

import React, { useEffect, useState } from "react";
import classesModule from "./classes.module.scss";
import ButtonArrow from "../../Buttons/ButtonArrow";
import Collapse from "@material-ui/core/Collapse";
import PropTypes from "prop-types";

const ExpandContent = ({
  header,
  children,
  open: openProps = false,
  preventIcon,
  onFinishAnimation,
  alternativeArrow,
}) => {

  const [open, setOpen] = useState(openProps);

  useEffect(() => {
    setOpen(openProps);
  }, [openProps]);

  const handleClick = (e, value) => {
    setOpen(value);
    if (e && e.stopPropagation) e.stopPropagation();
  };

  const handledExited = () => {
    onFinishAnimation && onFinishAnimation(true);
  };

  const handledEntered = () => {
    onFinishAnimation && onFinishAnimation(false);
  };

  return (
    <React.Fragment>
      <div className={classesModule.ExpandContent}>
        <div className={classesModule.Header}>
          {preventIcon ? (
            <div className={classesModule.headerContainer}>{header}</div>
          ) : header}
          <ButtonArrow
            className={alternativeArrow ? classesModule.ButtonArrowAlternative : classesModule.ButtonArrow}
            onClick={handleClick}
            open={open}
            alternativeArrow={alternativeArrow}
          ></ButtonArrow>
        </div>
        <Collapse in={open} onExited={handledExited} onEntered={handledEntered}>
          <div className={classesModule.Content}>
            <div>{children}</div>
          </div>
        </Collapse>
      </div>
    </React.Fragment>
  );
};

ExpandContent.propTypes = {
  /**  If true show the content of the component */
  open: PropTypes.bool.isRequired,
  /**  Does not allow the icon over the header */
  preventIcon: PropTypes.bool,
};

ExpandContent.defaultProps = {
  open: false,
  preventIcon: false,
};

export default ExpandContent;

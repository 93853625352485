import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import Popover from "@material-ui/core/Popover";
// import Popover from '@mui/material/Popover';
import _ from "lodash";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import ButtonMaterial from "../../../../components/generic/Buttons/ButtonMaterial/ButtonMaterial";
import Icon from "../../../../modules/generic/components/Icon";
//TODO: apuntar a lo nuevo cuando se migre
import { RoleAccess } from "../../../../v1/components";
import Scrollbar from "./../../../../components/generic/Scrollbar/Scrollbar";
import classesModule from "./DropDown.module.scss";
import classnames from "classnames";

const DropDown = ({
  children,
  options,
  buttonClass,
  classes = {},
  anchorOrigin = {},
  transformOrigin = {},
  arrowSpace = 0,
  noArrow = false,
  noDropdownArrow = false,
  addSpace = false,
  selectedClassName
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const [classesDropDown, setClassesDropDown] = useState(
    _.merge(
      {
        paper: addSpace ? classesModule.papperSpace : classesModule.papper,
      },
      classes
    )
  );

  const handleClosePopover = () => {
    handleClose()
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const dafaultAnchorOriginLocal = {
    vertical: "bottom",
    horizontal: "right",
  };

  const anchorOriginLocal = { ...dafaultAnchorOriginLocal, ...anchorOrigin };

  const defaultTransformOrigin = {
    vertical: "top",
    horizontal: "right",
  };

  const buttonRef = useRef();
  const menuListRef = useRef();

  const transformOriginLocal = {
    ...defaultTransformOrigin,
    ...transformOrigin,
  };

  const handleEntered = () => {
    if (menuListRef.current) {
      var node = document.createElement("span");
      var buttonWith =
        buttonRef.current.clientWidth -
        parseInt(getComputedStyle(buttonRef.current).paddingLeft) -
        parseInt(getComputedStyle(buttonRef.current).paddingRight);
      var popperWith = menuListRef.current.querySelector("div:nth-child(3)")
        .clientWidth;
      if (
        menuListRef.current.querySelector("div:nth-child(3)").offsetLeft >
        buttonRef.current.offsetLeft
      ) {
        if (popperWith >= buttonWith) {
          node.style.right = `${popperWith - buttonWith - 20}px`;
        } else {
          var buttonPaddingRight = parseInt(
            getComputedStyle(buttonRef.current).paddingRight
          );
          node.style.right = `${buttonPaddingRight + 6}px`;
        }
      } else {
        const space = 1.2 + arrowSpace;
        node.style.right = `${space}rem`;
      }
      if (!noArrow) {
        menuListRef.current.querySelector("div:nth-child(3)").append(node);
      }
      window.menuListRef = menuListRef;
    }
  };

  return (
    <div className={`${classesModule.DropDown}`}>
      <span ref={buttonRef}>
        <ButtonMaterial
          className={classnames(`${classesModule.Button} ${buttonClass} ${classesModule.arrow}`, {[classesModule.open] : open, [selectedClassName] : open})}
          onClick={handleClick}
        >
          {children}
          {!noDropdownArrow && <Icon icon={"Dropdown"} size={"0.9231rem"} isClara={true} />}
        </ButtonMaterial>
      </span>
      <Popover
        open={open}
        onClick={handleClosePopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        TransitionProps={
          { onEntered: handleEntered }
        }
        anchorOrigin={anchorOriginLocal}
        transformOrigin={transformOriginLocal}
        classes={classesDropDown}
        disableEnforceFocus={true}
        ref={menuListRef}
      >
        <Scrollbar className={classesModule.onlyvertical}>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList className={classesModule.ListMenu}>
              {options.map((option, i) => (
                <RoleAccess key={i} action={option.props.action}>
                  {React.cloneElement(option, {
                    ...option.props,
                    onClick: (e) => {
                      _.get(option, "props.onClick", () => { })(e);
                      handleClose();
                    },
                  })}
                </RoleAccess>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Scrollbar>
      </Popover>
    </div>
  );
};

export default DropDown;

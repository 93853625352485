import useMutation from '../../../../hooks/custom/useMutation'

import {updateCompanyParties} from "../../../../modules/generic/graphql/cache";

const UPSERT_DOCUMENT = `mutation upsertDocument($startupId: String, $agreementId: String, $draftDocumentId: String, $agreement: AgreementInputType) {
  upsertDocument(
    startupId: $startupId
    agreementId: $agreementId
    draftDocumentId: $draftDocumentId
    agreement: $agreement
  ) {
    id
    parentDocument {
      id
    }
    name
    freemiumValid
    kind
    status
    type {
      label
      code
    }
    groupCompanies {
      groupCompany {
        id
        name
      }
      signedBy{
        id
      }
    }
    stakeholders {
      id
      fullName
    }
    subCategories
    file {
      name
      size
      type
      id
    }
  }
}
`

const useUpsertDocument = ({ ...props }) => {
  
  // const refetchQueries= [{query:gql`${DOCUMENT_LIST_QUERY}`, variables:{startupId:startupId}}];
  const mutation = useMutation(UPSERT_DOCUMENT, { ...props }, { update: updateCompanyParties, })
  return mutation;
}

export default useUpsertDocument;
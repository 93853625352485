import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TextInput from 'src/components/inputs/Text/TextInput';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateLabel from 'src/modules/generic/components/Inputs/LabelInput';
import * as Yup from 'yup';
import CountrySelector from "src/components/inputs/Select/CountrySelector/CountrySelector";
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import SelectInput from 'src/components/inputs/Select/SelectInput/SelectInput';
import AddressInput from "src/components/forms/modules/startups/individualStakeholder/AddressInput";

function AddForm() {
  const { next, cancel, context } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const formRef = React.useRef(null);

  const initialValues = useMemo(() => {
    return context?.immediateParents?.[context?.immediateParentIndex]
  }, [context]);

  useEffect(() => {
    if (formRef.current) {
      //Reset completely for start with other entity
      formRef.current.reset(initialValues, {
        keepErrors: false,
        keepDirtyValues: false,
        keepValues: false,
        keepIsSubmitted: false,
        keepTouched: false,
        keepIsValid: false,
        keepSubmitCount: false,
        keepIsValidating: false,
      });
   
    }
  }, [initialValues])


  const handleCancel = (values) => {
    cancel();
  }

  const handleSubmit = async (values) => {
    next(values)
  }

  useEffect(() => {
    dispatch(addAssistantText(translate('MODULES.RENEWALS.IMMEDIATE_PARENTS.ADD_FORM.TOOLTIP')))
    return () => {
      dispatch(clearAssistatText())
    }
  }, [dispatch])

  const schema = Yup.object().shape({
    immediateParentName: Yup.string().required('This field is required'),
    mailingAddressType: Yup.string().required('This field is required').typeError('This field is required'),
    mailingAddress: Yup.object().shape({
      country: Yup.string().required('This field is required').typeError('This field is required'),
      street: Yup.string().required('This field is required'),
      city: Yup.string().required('This field is required'),
      state: Yup.string().nullable(),
      zipCode: Yup.string().nullable(),
    }),
    taxRegistrationNumber: Yup.string().nullable().optional(),
    taxJurisdiction: Yup.string().required('This field is required').typeError('This field is required'),
  });

  const mailingAddressTypeOptions = useMemo(() => {
    return [
      { value: 'BUSINESS', label: 'Business' },
      { value: 'RESIDENTIAL', label: 'Residential' }
    ];
  }, []);

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
      ref={formRef}
    >
      <TemplateWizard
        props={{
          title: translate('MODULES.RENEWALS.IMMEDIATE_PARENTS.ADD_FORM.TITLE', { 
            count: context?.immediateParentIndex + 1,
            total: context?.immediateParents?.length 
          }),
          skeleton: null,
          subTitle: translate('MODULES.RENEWALS.IMMEDIATE_PARENTS.GENERIC.SUBTITLE'),
          variant: 'simple',
          scrollTop: true
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('BUTTON_NEXT')}</SubmitButton>,
        }}
      >
        <>
          <TemplateLabel label={translate('MODULES.RENEWALS.IMMEDIATE_PARENTS.ADD_FORM.LABEL1')} isRequired>
            <ControllerInput
              render={TextInput}
              name='immediateParentName'
              placeholder={'Type here'}
              disabled
            />
          </TemplateLabel>
          <TemplateLabel label={translate('MODULES.RENEWALS.IMMEDIATE_PARENTS.ADD_FORM.LABEL2')} isRequired>
            <ControllerInput
              render={SelectInput}
              placeholder={translate("SELECT_INPUT_PLACEHOLDER")}
              list={mailingAddressTypeOptions}
              name="mailingAddressType"
              clear={true}
              autoClean={true}
              triggerChangeOnAutoClean={false}
            />
          </TemplateLabel>
          <AddressInput
            name="mailingAddress"
            label={{ country: translate('MODULES.RENEWALS.IMMEDIATE_PARENTS.ADD_FORM.LABEL3') }}
            zipCodeOptional
            isRequired
            onChange={() => { }}
            triggerChangeOnAutoClean={false}
            autoClean={true}
          />
          <TemplateLabel label={translate('MODULES.RENEWALS.IMMEDIATE_PARENTS.ADD_FORM.LABEL4')} isRequired>
            <ControllerInput
              render={CountrySelector}
              placeholder={translate("SELECT_INPUT_PLACEHOLDER")}
              name="taxJurisdiction"
              autoClean={true}
              triggerChangeOnAutoClean={false}
              clear={true}
            />
          </TemplateLabel>
          <TemplateLabel label={translate('MODULES.RENEWALS.IMMEDIATE_PARENTS.ADD_FORM.LABEL5')}>
            <ControllerInput
              render={TextInput}
              name='taxRegistrationNumber'
              placeholder={'Type here'}
            />
          </TemplateLabel>
        </>
        <div>&nbsp;</div>
      </TemplateWizard>
    </Form>
  );
}

export default AddForm;


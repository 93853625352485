import { useMemo } from 'react';
import useBusinessRules from 'src/modules/clara/hooks/useBusinessRules';
import useSession from 'src/modules/session/hooks/useSession';

const useGuards = () => {

  const { isOwner } = useBusinessRules();
  const { session } = useSession();

  return useMemo(() => ({

  }), [isOwner, session]);
}

export default useGuards;

import React from "react";
import { useFormContext } from "react-hook-form";
import { DevTool, PLACEMENT } from "@hookform/devtools";
import _ from "lodash";
const DevtoolForm = ({ placement = 'top-left' }: { placement?: PLACEMENT }) => {
    const { control } = useFormContext()
    return (
        <React.Fragment>
        {_.get(window, 'env.devTools.form', false) && <DevTool control={control} placement={placement} />}
        </React.Fragment>
    )
}

export default DevtoolForm

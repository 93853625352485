import React, { useEffect } from 'react';
import { Field, Formik } from 'formik';
import { useDispatch } from "react-redux";
import { ChatCompanyName, ChatPrimaryAsset, ClaraRadioWithButton, ClaraUpload } from '../../../../components';
import TextInput from "../../Inputs/TextInput";
import Checkbox from "../../Inputs/Checkbox";
import classesForm from '../Form.module.scss';
import { Constants } from "../../../../utils";
import _ from 'lodash'
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import CompanyDetailsSelector from './CompanyDetailsSelector'

const CompanyQuestionniareForm = React.memo(({ initialValues, onSubmit, companiesData, eligibleData, removeCompany, redirect }) => {

    const dispatch = useDispatch();
    const params = useParams();

    useEffect(() => {

    }, [companiesData])


    const openCompanyForm = () => {
        dispatch({ type: 'GO_TO', page: Constants.PAGES.addQuestionnaireCompany, params: { matterId: params.matterId } })
    }

    const openEditCompanyForm = (id) => {
        dispatch({ type: 'GO_TO', page: Constants.PAGES.editQuestionnaireCompany, params: { matterId: params.matterId, id } })
    }

    let reqText = 'This field is required';
    let shortText = "Must have 5 characters";
    let largerText = "Must be shorter than 255 characters"

    return (
        <React.Fragment>

            {
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={Yup.object().shape({
                        "arabicName": Yup.string().nullable().when(['haveArabicName'], {
                            is: true,
                            then: Yup.string().required(reqText).min(5, shortText).nullable().max(255, largerText)
                        }),
                        "shortenedName": Yup.string().nullable().when(['haveAcronym'], {
                            is: true,
                            then: Yup.string().required(reqText).min(5, shortText).nullable().max(255, largerText)
                        }),
                        "fullLegalName": Yup.string().nullable().when(['haveAffiliation'], {
                            is: true,
                            then: Yup.string().required(reqText).min(5, shortText).nullable().max(255, largerText)
                        })
                    })}
                    onSubmit={onSubmit}
                    isInitialValid
                >

                    {({
                        values,
                        errors,
                        dirty,
                        handleSubmit,
                        isSubmitting,
                        handleReset,
                        setFieldValue,
                        initialValues,
                        isValid,
                        setFieldError,
                        validateForm,
                    }) => {
                        return (

                            <React.Fragment>
                                <div className={classesForm.containerData} >
                                    <Field name={`companyNameForm`}>
                                        {({ field, form }) => (
                                            <ChatCompanyName
                                                // responseKeyName={"companySuffixes"}
                                                firstLabel={"Company Name:"}
                                                secondLabel={"Company Suffix:"}
                                                placeholder={"Company Name..."}
                                                type={_.get(values, 'companyType', '')}
                                                text={_.get(values, 'holdingName', '') + ' ' + _.get(values, 'companyName', '') + ' ' + _.get(values, 'limitedName', '')}
                                                form={form}
                                                field={field}
                                                isForm
                                                className={{ OptionButtonZone: classesForm.OptionButtonZone, labelOptionButton: classesForm.labelOptionButton }}
                                            />
                                        )}
                                    </Field>


                                    <Field name={"haveAcronym"}>
                                        {({ field, form }) => (

                                            <ClaraRadioWithButton
                                                field={field}
                                                form={form}
                                                changeParams={field.name}
                                                label={`Does the ${values.companyName} company have a special meaning or is it a shortening, abbreviation or acronym?`}
                                                options={
                                                    [{
                                                        "value": true,
                                                        "label": "Yes"
                                                    },
                                                    {
                                                        "value": false,
                                                        "label": "No"
                                                    }]
                                                }
                                                className={{ input: classesForm.containerButton }}
                                            />
                                        )}
                                    </Field>

                                    {_.get(values, "haveAcronym") &&
                                        <Field name={"shortenedName"}>
                                            {({ field, form }) => (
                                                <TextInput
                                                    type='text'
                                                    field={field}
                                                    label={" "}
                                                    form={form}
                                                    placeholder={"Type here..."}
                                                />
                                            )}
                                        </Field>
                                    }

                                    <Field name={"haveArabicName"}>
                                        {({ field, form }) => (

                                            <ClaraRadioWithButton
                                                field={field}
                                                form={form}
                                                changeParams={field.name}
                                                label={`Does the ${values.companyName} company include an Arabic name?`}
                                                options={
                                                    [{
                                                        "value": true,
                                                        "label": "Yes"
                                                    },
                                                    {
                                                        "value": false,
                                                        "label": "No"
                                                    }]
                                                }
                                                className={{ input: classesForm.containerButton }}
                                            />
                                        )}
                                    </Field>

                                    {_.get(values, "haveArabicName") &&
                                        <Field name={"arabicName"}>
                                            {({ field, form }) => (
                                                <TextInput
                                                    type='text'
                                                    field={field}
                                                    label={" "}
                                                    form={form}
                                                    placeholder={"Please enter name..."}
                                                />
                                            )}
                                        </Field>
                                    }

                                    <Field name={"haveAffiliation"}>
                                        {({ field, form }) => (

                                            <ClaraRadioWithButton
                                                field={field}
                                                form={form}
                                                changeParams={field.name}
                                                label={"Affiliation with another company in any jurisdiction that uses a similar name"}
                                                options={
                                                    [{
                                                        "value": true,
                                                        "label": "Yes"
                                                    },
                                                    {
                                                        "value": false,
                                                        "label": "No"
                                                    }]
                                                }
                                                className={{ input: classesForm.containerButton }}
                                            />
                                        )}
                                    </Field>

                                    {_.get(values, "haveAffiliation") &&
                                        <Field name={"fullLegalName"}>
                                            {({ field, form }) => (
                                                <TextInput
                                                    type='text'
                                                    field={field}
                                                    label={" "}
                                                    form={form}
                                                    placeholder={"Full legal name of the company..."}
                                                />
                                            )}
                                        </Field>
                                    }

                                    {_.get(values, "companyType") === "SPV" &&
                                        <Field name={""} >
                                            {({ field, form }) => (
                                                <ChatPrimaryAsset
                                                    isForm
                                                    responseKeyName={"assetsHeld"}
                                                    className={{
                                                        ChatInputTextarea: classesForm.ChatInputTextarea,
                                                        containerButtonsOptions: classesForm.containerButtonsOptions,
                                                        showOptionAssetsHeld: classesForm.showOptionAssetsHeld,
                                                        input: classesForm.containerButton,
                                                        optionsButtons: {
                                                            input: classesForm.containerButton
                                                        }
                                                    }}
                                                    initialValueStart={{ matterId: params.matterId }}
                                                />
                                            )}
                                        </Field>
                                    }
                                    {_.get(values, "intellectualProperty") ? <React.Fragment><div className={`${classesForm.assetBlock}`}><label>Assets Held:</label> <span className={classesForm.intellectualPropBlock}>
                                        <p>Intellectual Property</p>
                                    </span></div></React.Fragment> : null}
                                    {_.get(values, "intellectualProperty") ?
                                        <Field name={`intellectualPropertyDoc`}>
                                            {({ field, form }) => (
                                                <ClaraUpload

                                                    name={`filepond`}
                                                    label={`IP holding explanation:`}
                                                    field={field}
                                                    form={form}
                                                    className={classesForm.fileUpload}
                                                // tooltip={tooltips['Upload your document']}
                                                />
                                            )}
                                        </Field>
                                        : null}
                                    {_.get(values, "intellectualProperty") ?
                                        <Field name={"isRevenueGenerating"}>
                                            {({ field, form }) => (
                                                <Checkbox
                                                    label="Revenue Generation"
                                                    field={field}
                                                    form={form}
                                                    placeholder={""} />
                                            )}
                                        </Field>
                                        : null}
                                    {_.get(values, "intellectualProperty") ?
                                        <Field name={"isProtection"}>
                                            {({ field, form }) => (
                                                <Checkbox
                                                    label="Protection"
                                                    field={field}
                                                    form={form}
                                                    placeholder={""} />
                                            )}
                                        </Field>
                                        : null}
                                    {_.get(values, "alreadyIncorporated", null) ? <React.Fragment><div className={`${classesForm.assetBlock}`}><label>{'          '}</label> <span className={`${classesForm.intellectualPropBlock} ${classesForm.sharesBlock}`}>
                                        <p>Shares in a company already incorporated</p>
                                    </span></div></React.Fragment> : null}
                                    {_.get(values, 'alreadyIncorporated', null) && _.get(eligibleData, 'alreadyCompanies') ? <React.Fragment>
                                        <div className={classesForm.containerData} >
                                            <CompanyDetailsSelector
                                                name={"alreadyCompaniesIds"}
                                                eligibleData={eligibleData}
                                                titleButtons={`QUESTIONARY_SHAREHOLDER_BUTTON_COMPANY_EDIT`}
                                                titleEntyties={`QUESTIONARY_SHAREHOLDER_DIRECTORS_TEXT_COMPANIES`}
                                                initialValueStart={{ matterId: params.matterId }}
                                                showButtonAdd={true}
                                            />
                                        </div>

                                    </React.Fragment> : null}

                                    <hr />
                                    <div className={`${classesForm.containerButtons}`}>
                                        <button disabled={!isValid} className={`genericBlue`} onClick={redirect} > Cancel </button>
                                        <button disabled={!isValid || (_.get(values, 'alreadyIncorporated', null) ? _.get(values, 'alreadyCompaniesIds.length', []) < 1 : false)} className={`genericBlue`} onClick={handleSubmit} > Update </button>

                                    </div>


                                </div>

                            </React.Fragment>
                        )

                    }
                    }
                </Formik>
            }
        </React.Fragment>
    )
})

export default CompanyQuestionniareForm;



import _, { compact, get } from 'lodash';
import moment from "moment";
import Mustache from "mustache";
import React, { Component } from "react";
import { connect } from "react-redux";
import us from "../../../../langs/us";
import { goToPage } from "../../../../store/actions/page";

import { Date, DueDiligenceDeleteForm, Loading } from "../../../components";
import EditForm from "../../../components/Form/ClaraForm/EditForm";
import EditDetails from "../../../components/Forms/Edit/DueDiligence/Details";
import ViewDetails from "../../../components/Forms/View/DueDiligence/Details";
import { getSubcategories } from '../../../graphql/mutations/addIssues';
import { Constants } from '../../../utils/constants';
import { stripTypenames } from '../../../utils/graphql-util';
import ServerConnect from '../../../utils/ServerConnect';
import server_helper from '../../../utils/server_helper';
import classes from './Form.module.scss';







const { CATEGORIES_TITLES } = us;

const addIssue = `mutation upsertIssue($startupId:String, $issue: IssueInputType, $issueId: String ) {
     upsertIssue(startupId: $startupId, issue: $issue, issueId: $issueId) {
        corporateStructure {
            name
         }
        }
    }`

const callServerData = `query callServerData {
    documentList(startupId: "{{startupId}}") {
      id
      name
    }
    getGroupCompanies(startupId: "{{startupId}}") {
      id
      name
      avatar
      isHoldingCompany
    }
    stakeholderList(startupId: "{{startupId}}") {
      id
      fullName
      avatar
    }
    getComments( startupId: "{{startupId}}", issueId: "{{issueId}}"){
        id
        content
        createdAt
        author{
            _id
          firstName
          lastName
          avatar    
        }
    
      }
    getNotifications{
        id
        event
        data
      }
    
  }`
class EditDueDiligenceForm extends Component {
    state = {
        data: {},
        users: {},
        refresh: false,
        showLoading: false,
        groupCompanies: {},
        wasUpdated: false,
        documentList: [],
        groupCompanyList: [],
        stakeholderList: [],
        comments: [],
        notifications: []
    }

    callServerToGetData = () => {
        const getDueDiligence = `{ 
        getIssue( startupId: "{{startupId}}", issueId: "{{issueId}}"){
                        description
                        title
                        status
                        createdAt
                        updatedAt
                        subCategory
                        category
                        updatedBy{ 
                            firstName
                            lastName
                         }
                        createdBy {
                        _id
                            firstName
                            lastName
                        }
                        groupCompanies{
                             avatar 
                             name 
                             id
                        }
                        stakeholders{
                             avatar
                             fullName
                             id
                        }
                        documents{
                            id 
                            name
                        }
            }
        }   
        `

        return new Promise((resolve, reject) => {
            const query_params = {
                startupId: this.props.session.startup.id,
                issueId: this.props.params.id
            };

            const queryExecute = Mustache.render(getDueDiligence, query_params);
            ServerConnect.graphQlQuery(queryExecute).then(result => {
                let data = {
                    ...result.getIssue
                };
                resolve(data);
            });

        })

        return new Promise((resolve, reject) => {
            resolve(this.props.data);
        })
    };


    callServerData = () => {
        return new Promise((resolve, reject) => {
            const queryParams = { startupId: this.props.session.startup.id, issueId: this.props.params.id };
            const query = Mustache.render(callServerData, queryParams);

            ServerConnect.graphQlQuery(query).then(result => {
                this.setState({
                    groupCompanyList: _.orderBy(result.getGroupCompanies, ['isHoldingCompany'], ['desc']).map(gc => { return { ...gc, label: gc.name } }),
                    stakeholderList: result.stakeholderList.map(st => { return { ...st, label: st.fullName } }),
                    documentsList: result.documentList.map(doc => { return { ...doc, label: doc.name } }),
                    comments: result.getComments,
                    notifications: result.getNotifications
                })
                resolve(result)
            });
        })
    };




    getCategories() {
        return new Promise((resolve, reject) => {
            ServerConnect.graphQlQuery(getSubcategories).then(result => {
                let categories = result.getDueDiligenceCategories;
                resolve(categories);

            });
        });

    }

    getTitleEditForm = () => {
        return this.state.title ? this.state.data.title : "";
    };




    transformInitialData = (data) => {

        this.setState({
            users: {
                updatedAt: data.updatedAt,
                createdAt: data.createdAt,
                createdBy: [get(data.createdBy, "firstName", ""), get(data.createdBy, "lastName", "")].join(" "),
                updatedBy: [get(data.updatedBy, "firstName", ""), get(data.updatedBy, "lastName", "")].join(" ")

            }
        });

        return {
            id: this.props.params.id,
            title: data.title,
            urgency: data.status,
            category: data.category,
            subCategory: data.subCategory,
            description: data.description,
            groupCompanies: compact(data.groupCompanies),
            stakeholders: compact(data.stakeholders),
            documents: compact(data.documents)
        };

    };

    getStakeHoldersOrGroupCompanies = (list, keyName) => {
        return this.state[keyName].filter(element => {
            return list.find(e => e === element.id);
        })


    }

    transformFinishData = (data) => {
        this.setState({
            showLoading: true
        });


        let issue = stripTypenames(data);
        const variables = {
            "startupId": this.props.session.startup.id,
            "issueId": this.props.params.id,
            issue: {
                ...data,
                stakeholders: _.compact(data.stakeholders),
                groupCompanies: _.compact(data.groupCompanies),
                documents: _.compact(data.documents)
            }

        }
        delete variables.issue.id;
        delete variables.issue.__typename;
        ServerConnect.graphQlMutation(addIssue, variables).then(async (result) => {
            


            this.setState({
                showLoading: false,
                data: {
                    ...data,
                    stakeholders: this.getStakeHoldersOrGroupCompanies(data.stakeholders, "stakeholderList"),
                    groupCompanies: this.getStakeHoldersOrGroupCompanies(data.groupCompanies, "groupCompanyList"),
                    documents: this.state.documentsList.filter(e => data.documents.find(doc => doc === e.id))
                },
                refresh: !this.state.refresh,
                wasUpdated: true,
                users: {
                    ...this.state.users,
                    updatedBy: this.props.session.user.fullName,
                    updatedAt: moment(new Date()).format("YYYY-MM-DD")
                }

            });

        });
    };

    componentDidMount() {
        this.setState({
            showLoading: true
        });



        Promise.all([
            this.callServerToGetData(),
            this.getCategories(),
            this.callServerData(),
        ]).then((result) => {
            this.setState({

                data: this.transformInitialData({ ...result[0] }),
                categories: result[1].map(cat => ({ ...cat, title: CATEGORIES_TITLES[cat.key] })),
                refresh: !this.state.refresh,
                showLoading: false
            })

        })

        ServerConnect.requestApi(
            `/contentful/tooltips/${Constants.CONTENTFUL.ENTRIES.FORMS.DUEDILIGENCE.TOOLTIP}`,
            {
                method: 'GET', headers: { "Content-Type": "application/json" },
            }).then(res => {
                console.log('response', res.response);
                this.setState({
                    tooltips: res.response
                })
            }).catch(err => {
                console.log('Tooltips from contentful error: ', err);
            });
    }

    headerInIssue = () => {

        const { users, data } = this.state;

        const createdBy = users.createdBy === " " ? "Clara Platform" : users.createdBy;
        const updatedBy = users.updatedBy === " " ? createdBy : users.updatedBy;

        return (
            <div className={classes.containerHeader}>

                <div className={classes.title} >{data.title}</div>

                <div className={classes.containerUsers} >
                    <label>Remark created: <Date value={users.createdAt} /> by </label> <p>{createdBy}</p>
                    {users.updatedAt && <>
                        <label>Last updated: <Date value={users.updatedAt} /> by </label> <p>{updatedBy}</p> </>}
                </div>

            </div>
        )
    }

    render() {

        let { mode } = this.props;
        let { data, categories, wasUpdated, comments, notifications } = this.state;
        const roleAccessDelete = this.props.hasRoleAccess(Constants.ACTIONS.EDIT_FREEMIUM_STARTUP_REMARK)
        
        
        return (
            <Loading show={this.state.showLoading}>
                <EditForm
                    formType={Constants.FORMS.DUE_DILIGENCE}
                    accessDelete={Constants.ACTIONS.DELETE_DUEDILIGENCE}
                    conditionToDelete={!this.props.businessRules.isStartSubscription() || roleAccessDelete}
                    title={this.getTitleEditForm()} mode={mode} data={data} wasUpdated={wasUpdated}
                    headerDecorator={this.headerInIssue}
                    positionOption={{ right: "80px" }}

                >
                    <EditForm.Container tab={{ name: "", key: "details" }}>
                        <EditForm.View isView={true}>
                            <ViewDetails data={data} notifications={notifications} categories={categories} users={this.state.users} comments={comments} />
                        </EditForm.View>
                        <EditForm.Edit>
                            <EditDetails
                                tooltips={this.state.tooltips}
                                data={data}
                                updateForm={this.transformFinishData}
                                categories={categories}
                                documentList={this.state.documentsList}
                                stakeholderList={this.state.stakeholderList}
                                groupCompanyList={this.state.groupCompanyList}
                            />
                        </EditForm.Edit>
                    </EditForm.Container>
                    <EditForm.Delete isDelete={true}>
                        <DueDiligenceDeleteForm handleClose={this.props.handleClose} show={true} data={data} >
                        </DueDiligenceDeleteForm>
                    </EditForm.Delete>
                </EditForm>
            </Loading>
        )
    }
}

const mapStateToProps = state => ({
    session: state.session,
    session: state.session,
    store: state
});
const mapDispatchToProps = (dispatch) => ({
  
  
  
    goToPage: (page, params) => {
        goToPage(dispatch, page, params)
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(EditDueDiligenceForm)

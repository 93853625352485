import React, {useEffect, useState} from 'react';
import {ServerConnect} from "../utils";
import _ from "lodash";
import Mustache from 'mustache'
import {individualType} from "../graphql/mutations/matters";

const QUERY_MATTER = `query{
  getEligibleBankingSigningAuthority(matterId:"{{matterId}}")
  ${individualType}
}
`;
const QUERY_DRAFT = `query{
  getEligibleBankingSigningAuthorityDraft(draftId:"{{draftId}}")
}
`;

export default function useEligibleBankingSigningAuthority(matterId,draftId) {
  const [data, setData] = useState(null);
  useEffect(() => {
    const query_params = {
      matterId,
      draftId
    };
    const query = Mustache.render(draftId ? QUERY_DRAFT : QUERY_MATTER, query_params);
    ServerConnect.graphQlQuery(query).then(result => {
      let newData = _.get(result, draftId ? "getEligibleBankingSigningAuthorityDraft" : "getEligibleBankingSigningAuthority", {});
      setData(newData)
    });
  }, [matterId,draftId]);

  return { data };
}
import React, { FC } from "react";
import classes from './DocumentPreviewFileSkeleton.module.scss';
import Skeleton from '@material-ui/lab/Skeleton';
import Icon from "../../../../modules/generic/components/Icon";

/**
 * Template DocumentPreviewFile Skeleton
 */

export interface DocumentPreviewFileSkeletonProps {

} 

const DocumentPreviewFileSkeleton: FC<DocumentPreviewFileSkeletonProps> = ({
  ...props
}:DocumentPreviewFileSkeletonProps) => {

  return (
    <>
      <div className={classes.skeletonDocument}>
        <div className={classes.SkelPos}>
          <Icon color={"#e4e4e4"} icon={"Upload-doc"} size={"380px"} isClara />
          <div className={classes.skeletoncap}>
            {Array(3).fill(1).map((el, i) =>
              <Skeleton variant="text" height={"1.3rem"} width={"94%"} key={i}/>
            )}
          </div>
          <div className={classes.skeletoncap2}>
            {Array(7).fill(1).map((el, i) =>
              <Skeleton variant="text" height={"1.3rem"} width={"96%"} key={i}/>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentPreviewFileSkeleton;

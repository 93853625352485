import TextInput from "src/v1/containers/Forms/Inputs/TextInput"
import React, {FC} from "react"
import classes from '../styles.module.scss'
import Notifications from "src/v1/components/Messages/Notification/Notification"
import { Field } from "formik"
interface Props {
    groupCompanyName:string;
    entityLabel:string;
}
const ShareClassFields:FC<Props> = ({groupCompanyName,entityLabel}) => {
    return (<>
        <Field name={`${entityLabel}.share[0].shareClass.name`}>
            {({ field, form }) => (
                <div className={`${classes.ContainerRow}`}>
                    <TextInput
                        field={field}
                        form={form}
                        label={'Share class name'}
                        required={true}
                        placeholder={'E.g. Ordinary, Ordinary A or Class A Common'}
                        tooltip={'Share class name'}
                    /></div>
            )}
        </Field>
        {groupCompanyName && <div style={{ marginTop: '1rem' }}>
            <Notifications show={true} type={"success"}>
                This will create and publish a new share class for {groupCompanyName}. The share class created will be non-preferred and will carry 1 vote per share. You can add or amend the details of this share class from inside Equity.
            </Notifications>
        </div>}
    </>)
}
export default ShareClassFields
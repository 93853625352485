import React from 'react';
import ACTIONS from "../../../../constants/actions";
import PAGES from "../../../../constants/pages";
import ClaraLayout from "../../../../layouts/Clara/ClaraLayout";
import Text from "./../../../../components/text/Text/Text";
import Messages from "./Messages/Messages";

const moduleRouter = [
  {
    path: PAGES.messages.path,
    exact: true,
    breadCrumbs: {
      reset: true,
      add: false,
      label: <Text uuid={"BREADCRUMS_MESSAGES"} />,
    },
    component: <Messages />,
    layout: ClaraLayout,
    propsLayout: {
      cantBack: true,
      startupMenu: true,
      userMenu: true,
      fullMenu: true,
      showAssistance: false,
      showBreadCrumbs: true,
      linkMenu: "LinkMessages",
    },
    action: ACTIONS.VIEW_PROFILE,

  },
];
export default moduleRouter;

import * as Yup from 'yup';
import {Constants} from '../../../../utils/constants';

let reqText = 'This field is required';
let shortText = "Must have 5 characters";
let largerText = "Must be shorter than 255 characters"

const validationSchema = Yup.object().shape({
    "incorporationJurisdiction": Yup.string().min(5, shortText).max(255, largerText).nullable().required(reqText),
    "companyType": Yup.string().min(5, shortText).max(255, largerText).nullable().required(reqText),
    // "isIncorporated": Yup.string().nullable().when(['companyType'], {
    //     is: Constants.COMPANY_TYPES.HOLDING_COMPANY,
    //     then: Yup.string().required(reqText).min(5, shortText).nullable().max(255, largerText),
    //     otherwise: Yup.string().nullable()
    // }),
    // "heldCompLocation": Yup.string().nullable().when(['companyType'], {
    //     is: Constants.COMPANY_TYPES.HOLDING_COMPANY,
    //     then: Yup.string().required(reqText).min(5, shortText).nullable().max(255, largerText),
    //     otherwise: Yup.string().nullable()
    // }),
    "onlyRevenueDividendsOrCapital": Yup.bool().nullable().when(['companyType'], {
        is: Constants.COMPANY_TYPES.HOLDING_COMPANY,
        then: Yup.bool().nullable().required(reqText)
    }),
    "anotherIncomeForm": Yup.string().nullable().when(['onlyRevenueDividendsOrCapital'], {
        is: false,
        then: Yup.string().required(reqText).min(5, shortText).nullable().max(255, largerText)
    }),
    // "businessPlanDescription": Yup.string().nullable().when(['companyType'], {
    //     is: Constants.COMPANY_TYPES.FINTECH,
    //     then: Yup.string().required(reqText).nullable().min(5, shortText).max(255, largerText),
    //     otherwise: Yup.string().nullable()
    // }),
    "relevantTechnologyDescription": Yup.string().nullable().when(['companyType'], {
        is: Constants.COMPANY_TYPES.TECH_STARTUP,
        then: Yup.string().required(reqText).nullable().min(5, shortText).max(255, largerText),
        otherwise: Yup.string().nullable()
    }),
    "companyActivitiesDescription": Yup.string().nullable().when(['companyType'], {
        is: Constants.COMPANY_TYPES.NONFINANCIAL_COMPANY,
        then: Yup.string().required(reqText).nullable().min(5, shortText).max(255, largerText),
        otherwise: Yup.string().nullable()
    }),
    "detailsOtherIncorporation": Yup.string().nullable().when(['companyType'], {
        is: Constants.COMPANY_TYPES.OTHER,
        then: Yup.string().required(reqText).nullable().min(5, shortText).max(255, largerText),
        otherwise: Yup.string().nullable()
    }),
    "shareNumbers": Yup.number('This field must be a number').typeError('This field must be a number').integer("This field must be a integer").positive('This field must be a positive number').required(reqText),
    "boardMembersMajorityResident": Yup.bool().nullable().required(reqText),
    "bankAccountRequire": Yup.bool().nullable().required(reqText),
    "engagementType": Yup.string().nullable().min(5, shortText).max(255, largerText).required(reqText),
    "whereTheMajorityBoardMembersReside": Yup.string().nullable().when(['boardMembersMajorityResident'], {
        is: false,
        then: Yup.string().required(reqText).nullable().min(1, shortText).max(255, largerText),
        otherwise: Yup.string().nullable()
    })
});

export default validationSchema;




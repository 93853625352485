import { useEffect } from 'react';
import { isNilCustom } from './utils';

interface UseAutomaticEventProps {
    [key: string]: any
}

function useAutomaticEvent({ value, inputValue, onChange }: UseAutomaticEventProps) {
    useEffect(() => {        
        if (inputValue === "" && !isNilCustom(value)) {
            typeof value === "object" ? onChange(null) : onChange("");
        }
    }, [inputValue]);
}

export default useAutomaticEvent;
import React from 'react'
import * as Yup from 'yup'
import Form from '../../../../components/forms/Form/Form'
import useTranslate from '../../../generic/hooks/useTranslate'
import TextInput from '../../../../components/inputs/Text/TextInput'
import ControllerInput from '../../../generic/components/Inputs/ReactHookForm/ControllerInput'
import TemplateLabel from '../../../generic/templates/Label'
import SubmitButton from '../../../generic/components/Buttons/SubmitButton'
import FormProps from '../../../generic/forms/interfaces/FormProps';
import TemplateFormDefault from "../../../generic/templates/Modal";
import cls from './Form.module.scss';
import FileUploaderInput from '../../../../components/inputs/FileUploader/FileUploaderInput'
import useCustomMachine from '../../../generic/hooks/useCustomMachine'

const schema = Yup.object().shape({
    uploadedFiles:Yup.array().of(Yup.object().shape({
     file:Yup.object().required('Required'),
     title:Yup.string().nullable().required('Required')
    })).required('Required')
})

const FormProfessionalHistory: React.FC<FormProps> = ({
    initialValues: defaultValues = {},
    onCompleteSubmit,
    Template = TemplateFormDefault,
    buttons = {},
    buttonSubmitProps,
    propsTemplate }) => {


    const { translate } = useTranslate();

    const handleSubmit = async values => {
        onCompleteSubmit && await onCompleteSubmit(values);
    }

    return (
        <Form
            defaultValues={defaultValues}
            schema={schema}
            onSubmit={handleSubmit}
            onlySendDirty={false}
            optionsForm = {{mode:"onChange"}}
        >

            <Template buttons={{ ...buttons, submit: <SubmitButton  {...buttonSubmitProps}>Add</SubmitButton> }} props={propsTemplate}>

                <TemplateLabel
                    label={'Document name'}
                    isRequired
                    className={cls.TemplateLabel}
                >
                    <ControllerInput
                        render={TextInput}
                        name='uploadedFiles[0].title'
                        defaultlabel={''}
                        placeholder='Give this document a name'

                    />
                </TemplateLabel>


                <TemplateLabel
                    label={'Upload Grant'}
                    isRequired
                    className={cls.TemplateLabel}
                >
                    <ControllerInput
                        render={FileUploaderInput}
                        name='uploadedFiles[0].file'
                        defaultlabel={''}
                        placeholder='Include numbers only'

                    />
                </TemplateLabel>


            </Template>

        </Form>
    )

}

export default FormProfessionalHistory;

import useMutation from "src/hooks/custom/useMutation";

export const UPSERT_COMPANY_ADDRESSES = `
  mutation upsertGroupCompany(
    $startupId: String
    $groupCompanyId: String
    $groupCompany: GroupCompanyInputType
  ) {
    upsertGroupCompany(
      startupId: $startupId
      groupCompanyId: $groupCompanyId
      groupCompany: $groupCompany
    ) {
      id
      mailingAddress {
        street
        city
        state
        country {
          name
          code
          alpha3
        }
        zipCode
      }
      registeredAddress {
        street
        city
        state
        country {
          name
          code
          alpha3
        }
        zipCode
      }
      locationAccountingRecordsAddress {
        street
        city
        state
        country {
          name
          code
          alpha3
        }
        zipCode
      }
    }
  }
`;

const useUpdateGroupCompanyAddresses = ({ groupCompanyId, ...props }) => {
  const [mutation] = useMutation(
    UPSERT_COMPANY_ADDRESSES,
    { showSuccessNotification: true },
  )
  return mutation;
};

export default useUpdateGroupCompanyAddresses;

import React from 'react';

import SystemLayout from "../../layouts/System/SystemLayout"
import ClaraLayout from "../../layouts/Clara/ClaraLayout"
import MissingView from "./Missing/MissingView"
import ErrorView from "./Error/ErrorView"
import ForbiddenView from "./Forbidden/ForbiddenView"
import RedirectView from "./Redirect/RedirectView"
import PAGES from "../../constants/pages";

const moduleRouter = [
  {
    path:"/",
    exact: true,
    component: <RedirectView />,
    layout: SystemLayout,
  },
  {
    path:PAGES.error.path,
    exact: true,
    component: <ErrorView />,
    layout: SystemLayout,
  },
  {
    path:PAGES.forbidden.path,
    exact: true,
    head:{
      title: "META_HEAD_TITLE_403"
    },
    component: <ForbiddenView/>,
    layout: ClaraLayout,
    propsLayout: {
      startupMenu:true,
      userMenu:true,
      fullMenu:false,
      showAssistance:false,
      cantBack: false
    },
  },
  {
    path:PAGES["404"].path,
    exact: true,
    head:{
      title: "META_HEAD_TITLE_404"
    },
    component: <MissingView/>,
    layout: SystemLayout,
  },
  {
    exact: true,
    head:{
      title: "META_HEAD_TITLE_404"
    },
    component: <MissingView/>,
    layout: SystemLayout,
  },
];
export default moduleRouter;

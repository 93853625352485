import _ from 'lodash'

export const isNilCustom = (value) => {
    return _.isNull(value) || _.isUndefined(value) || value === '';
}

export function debounce(callback, wait: number) {
    let timerId;
    return (...args) => {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
            callback(...args);
        }, wait);
    };
}
import React, { useEffect } from "react";
import useModal from "../../../../../generic/hooks/useModal";
import { goToPage } from "../../../../../../store/actions/page";
import { useDispatch } from "react-redux";
import { Constants } from "../../../../../../v1/utils/constants";
import useMachine from "../../../../../generic/context/MachineContext/useMachine";

/**
 * Step to redirect to generate document flow
 * @param {*} param0
 * @returns
 */
function GotoGenerateDocumentFlow() {
  const { context } = useMachine();
  const { closeModal: onClose } = useModal();
  const dispatch = useDispatch();
  const region = context?.region;

  const goTo = (page) => {
    goToPage(dispatch, page, { previewDocument: region === "US" ? "USA_BOARD_CONSENT_SAFE" : "BOARD_RESOLUTION_CONVERTIBLE" });
  };

  const hendleRedirectToGenerate = () => {
    goTo(Constants.PAGES.chooseDocumentType);
  };

  useEffect(() => {
    onClose();
    hendleRedirectToGenerate();
  }, []);

  return <></>;
}

export default GotoGenerateDocumentFlow;

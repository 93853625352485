import React, { useEffect } from 'react';
import useQuery from "../../custom/useQuery"

const query = `query countryList(
  $code: String
  $name: String
  $language: String!
  $orderBy: OrderFlagsType
) {
  countryList(
    code: $code
    name: $name
    language: $language
    orderBy: $orderBy
  ) {
    code
    alpha3
    name
    jurisdictions {
      name
    }
  }
}
`;

const useCountryList = (variables, options = {}) => {

  const { loading, error, data, refetch } = useQuery(query, variables, null, { fetchPolicy: "cache", ...options });


  return { loading, error, data, refetch };
}
export default useCountryList;
import React, { FC } from 'react';
import Wizard from '../../../../generic/components/Wizard';
import ModalAssistant, { ModalAssistantProps } from '../../../../generic/components/Modal/ModalAssistant';
import MachineAuthorisedSignatories from './machine';
import useModal from '../../../../generic/hooks/useModal';

interface ModalProfileStakeholderProps extends ModalAssistantProps {
  paramsMutation: object,
  onClose(): void;
}

const ModalProfileStakeholder: FC<ModalProfileStakeholderProps> = ({ children, open = false, paramsMutation, ...props }: ModalProfileStakeholderProps) => {

  const { closeModal: onClose } = useModal()

  const handleClose = (values) => {
    onClose()
  }

  return (
    <ModalAssistant open={open}>
      <Wizard machine={MachineAuthorisedSignatories} initialValues={paramsMutation} onComplete={handleClose} />
    </ModalAssistant>

  )
}

export default ModalProfileStakeholder;





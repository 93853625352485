import { useEffect } from 'react';
import _ from 'lodash';
import useQuery from "../../../custom/useQuery"

const query = `query getStartup($startupId: ID!){
  getStartup(startupId: $startupId){
    bankCards{
      _id
      inUse
      bankCardType
      last4
      expiryMonth
      expiryYear
      cardHolderName
      lastUsed
      billingAddress
    }
  }
}`;
const useGetCards = (variables, config) => {
	
  const { loading, error, data, refetch } = useQuery(query, variables, config);
  useEffect(() => {

  }, []);
  return { loading, error, data };
};

export default useGetCards;

import classes from "./FileUploaderAndSelector.module.scss";
import React, { useEffect, useState } from 'react';
import FileUploaderInput from '../FileUploader/FileUploaderInput';
import UploadedFilesSelectorComponent from '../../generic/UploadedFilesSelector/UploadedFilesSelectorComponent';

const FileUploaderAndSelector = ({ onChange, multipleFiles, documents, value }) => {

    const [selectedFiles, setSelectedFiles] = useState(value || documents || []);
    const [uploadedFile, setUploadedFile] = useState([]);

    useEffect(() => {
        onChange({ documentsUploaded: [...selectedFiles], uploadedFiles: [...uploadedFile] })
    }, [JSON.stringify(selectedFiles), JSON.stringify(uploadedFile)]);

    const handleChangeSelector = (files) => {
        setSelectedFiles(files);
        return files
    };

    const handleChangeUploader = (file) => {
        setUploadedFile(file);
        return file
    };

    return (
        <React.Fragment>
            <div className={classes.uploader}>
                <FileUploaderInput
					multipleFiles={multipleFiles}
					isArrayValue={true}
					required={false}
					onChange={handleChangeUploader}
				/>
            </div>
            <UploadedFilesSelectorComponent
				documents={selectedFiles ? selectedFiles : null}
				onChange={handleChangeSelector}
			/>
        </React.Fragment>
    )
};

export default FileUploaderAndSelector;

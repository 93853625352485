import React, { FC } from "react";
import classes from "./classes.module.scss";
import Link from "../../../../components/text/Link/Link";
import Text from "../../../../components/text/Text/Text";

export interface ReadMoreProps {
  /** Property link */
  link: string,
  /** Property text */
  text: string,
  /** Property useBlueColor */
  useBlueColor: boolean,
} 

const ReadMore: FC<ReadMoreProps> = ({
  link,
  text = "MODULES.ASSISTANCEV4.ASSISTANCE_READ_MORE",
  useBlueColor = false,
  ...props
}:ReadMoreProps) => {
  return (
    <Link href={link} target={"_blank"} className={` ${useBlueColor ? classes.ReadMoreBlue : classes.ReadMore} `}>
      <Text uuid={text} />
    </Link>
  );
};

export default ReadMore;

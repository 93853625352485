import React from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";

const EditExerciseManual = ({ go, ...props }) => {
  const params = useParams();

  return (
    <MicroFrontend id="MF_Edit_Exercise_Manual" component={"./EditExerciseManual"} mf="capTable" params={{ go, ...props, ...params }} />
  );
};

export default EditExerciseManual;

import useMutation from "src/hooks/custom/useMutation";

export const UPSERT_ROLE = `
mutation upsertStakeholderRoles($startupId: ID!, $stakeholderId: ID!, $stakeholderRolesData: StakeholderRolesInput!) {
  upsertStakeholderRoles(
    startupId: $startupId
    stakeholderId: $stakeholderId
    stakeholderRolesData: $stakeholderRolesData
  ) {
    id
    fullName
    roles {
      id
      name
      entity {
        ... on StakeHolder {
          id
          fullName
        }
        ... on GroupCompany {
          id
          name
        }
      }
    }
  }
}
`
const useUpsertComponentRole = (config, props) => {
  const [upsertComponentRole] = useMutation(UPSERT_ROLE, config, { refetchQueries: ["companyCorporateMap"], ...props })
  return { upsertComponentRole }
}
export default useUpsertComponentRole
import React, { useEffect } from "react";
import useModal from "../../../../../modules/generic/hooks/useModal/index";
import { goToPage } from "src/store/actions/page.js";
import { useDispatch } from "react-redux";
import { Constants } from "src/v1/utils/constants.js";

/**
 * Step to redirect to map
 * @param {*} param0
 * @returns
 */
function GotoTheMap({ openParam }) {

  const { closeModal: onClose } = useModal();
  const dispatch = useDispatch();

  const hendleRedirectToGenerate = () => {
    goToPage(dispatch, Constants.PAGES.MODULES.MAP.maps);
  };  

  useEffect(() => {
    onClose();
    hendleRedirectToGenerate();
  }, []);  

  return (
    <></>
  );
}

export default GotoTheMap;

import useLazyQuery from 'src/hooks/custom/useLazyQuery';

const GET_RELEVANT_ACTIVITIES = `
query getRelevantActivities {
  getRelevantActivities {
    code
    description
  }
}`;

export const useGetRelevantActivities = (variables, config) => {
  const { manualQuery, loading, error, data, refetch } = useLazyQuery(GET_RELEVANT_ACTIVITIES, variables, config);
  return { loading, error, data, refetch, manualQuery };
};

export default useGetRelevantActivities;

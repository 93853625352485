import React, { useEffect, useState } from "react";
import _ from "lodash";
import ErrorInput from "../ErrorInput";
import Icon from "../../Icon";
import Typography from "../../Texts/Typography";
import classesModules from "./classes.module.scss";
import InputInterface from "./../Input/InputInterface";

export class CheckboxOptions<Value = any> {
  public value: Value;
  public label?: string;
  public component?: React.ReactNode;
  public disabled?: boolean;
  public visible?: boolean;
}

export interface CheckboxProps extends InputInterface {
  /**  Option's List */
  options: Array<CheckboxOptions>;
  /** List of variants  */
  variant: "col_1" | "col_2" | "col_3" | "col_4";
  /**  Array value component */
  value: Array<any>;
  /**  Default component */
  defaultValue: Array<any>;
  /*To set to being able to select multiple */
  isMultiple: boolean;
  /*type of checkboxes*/
  buttonType: "Delete" | "Default";
  /*To compare checkbox values */
  functionCompare?(valueA: any, valueB: any): boolean;
}
/**
- Checkbox component to show labels with inputs
**/
const CheckboxInput = ({
  isMultiple = true,
  errorMessage,
  defaultValue = [],
  disabled,
  onChange,
  onFocus,
  onBlur,
  functionCompare,
  value,
  variant = "col_1",
  options = [],
  className,
  buttonType = "Default",
}: CheckboxProps) => {
  const [localValues, setLocalValues] = useState(value || defaultValue || []);

  useEffect(() => {
    if (!_.isEqual(value, localValues) && value) {
      setLocalValues(value);
    }
  }, [JSON.stringify(value)]);

  const isSelected = (option: CheckboxOptions) => {
    if (functionCompare)
      return !!_.find(localValues, (value) =>
        functionCompare(value, option.value)
      );
    return localValues.indexOf(option.value) >= 0;
  };

  const handleClick = (option: CheckboxOptions, isSelected: boolean) => {
    let auxLocalValues: Array<string> = [...localValues];
    if (!isMultiple) {
      !_.isEmpty(auxLocalValues) && auxLocalValues.pop();
      auxLocalValues.push(option.value);
    } else {
      if (isSelected) {
        auxLocalValues = _.filter(auxLocalValues, (value) => {
          if (functionCompare) return !functionCompare(value, option.value);
          return value !== option.value;
        });
      } else {
        auxLocalValues.push(option.value);
      }
    }
    setLocalValues([...auxLocalValues]);
    onChange && onChange(auxLocalValues);
  };

  return (
    <>
      <div
        className={`${classesModules.CheckboxInput} ${classesModules[variant]} ${className}`}
      >
        <div className={`${classesModules.Columns} ${classesModules[variant]}`}>
          {_.map(options, (option, index) => {
            const selected = isSelected(option);

            return (
              <div className={`${classesModules.CheckboxOption}`} key={index}>
                <button
                  type="button"
                  disabled={disabled || option.disabled}
                  data-checked={selected}
                  className={classesModules[`buttonCheckbox${buttonType}`]}
                  onClick={() => handleClick(option, selected)}
                  onFocus={() => onFocus && onFocus(option)}
                  onBlur={() => onBlur && onBlur(option)}
                >
                  <Icon icon="minitick" isClara={true} size={"0.6rem"} />
                </button>
                {option.component
                  ? option.component
                  : option.label && (
                      <Typography variant="body" component={"label"}>
                        {option.label}
                      </Typography>
                    )}
              </div>
            );
          })}
        </div>
        <ErrorInput
          message={errorMessage}
          classNameError={classesModules.error}
        />
      </div>
    </>
  );
};

export default CheckboxInput;

import React, { Component } from "react";
import { connect } from "react-redux";
import classes from "./Header.module.scss"
import DropDownMenu from "./DropDownMenu/DropDownMenu"
import { Constants } from '../../utils/constants';
import { Avatar, ModalMenu } from "../index"
import BusinessRuleAccess from "../../../modules/clara/components/BusinessRuleAccess/BusinessRuleAccess"
class Header extends Component {
    state = {
        user: null,
        startup: null
    };

    goTo = (page) => {
        this.props.goTo(page);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.session !== this.props.session) {

        }
    }

    componentJSX_getDropDownMenu() {
        if (this.props.type !== 'Getstarted' && (this.props.type !== 'forgotPass') && (this.props.type !== 'login')) {
            return <DropDownMenu showName={false} logout={this.props.logout} ></DropDownMenu>;
        }

    }
    componentJSX_getDropDownCompany() {
        if (this.props.type === 'dashboard') {
            let links = [
                { label: "View Startup Profile", className: classes.EditStartUp, onClick: this.props.callbacks.editStartUp, action: Constants.ACTIONS.VIEW_PROFILE },
                { label: "Switch Startup", className: classes.ChangeStartUp, separator: true, onClick: this.props.callbacks.changeStartUp },

            ];


            let { startup } = this.props.session;
            return (
                <div className={classes.ZoneDropDownCompany}>
                    <div className={` ${classes.ZoneDropDownCompanyContent} centerY`}>
                        <ModalMenu links={links}>
                            <Avatar avatar={startup.avatar} name={startup.name}></Avatar>
                        </ModalMenu>
                    </div>
                </div>
            )
        }

    }

    componentJSX_getMenu() {
        if (this.props.type == 'onboarding') {
            return (
                <nav className={classes.NavMenu} >
                    <a href="#" className={classes.LinkAcademy}>
                        <span>Academy</span>
                    </a>
                </nav>

            );
        }
        if ((this.props.type == 'login') || (this.props.type == 'forgotPass')) {
            if (this.props.isSignUp) {
                return (
                    <nav className={`${classes.NavMenu} ${classes.NavMenuLogin}`} >
                        <span className={classes.textHeaderSignUp}>Already have an account?</span>
                        <button onClick={() => this.goTo('/invite')} className={classes.buttonLoginHeader} >Login</button>
                    </nav>
                )
            }
            if (this.props.isSignIn || this.props.isForgotPass) {
                return (
                    <nav className={`${classes.NavMenu} ${classes.NavMenuLogin}`} >
                        <span className={classes.textHeaderSignUp}>Don't have an account?</span>
                        <button onClick={() => this.goTo(Constants.PAGES.register)} className={classes.buttonLoginHeader} >Sign up</button>
                    </nav>
                )
            }
            // return (
            //     <nav className={`${classes.NavMenu} ${classes.NavMenuLogin}`} >
            //         <a href="#" onClick={() => this.goTo('/invite')} className={classes.LinkSignUp}>
            //             <span>Sign Up</span>
            //         </a>
            //     </nav>

            // );
        }
        if (this.props.type == 'dashboard') {
            return (
                <nav className={classes.NavMenu} >
                    {this.props.links.map((link, i) => {
                        let selected = this.props.page == link.page ? classes.LinkSelect : null;
                        return (
                            <a key={i} href="#" className={`${classes[link.key]} ${selected}`} onClick={(e) => { this.goTo(link.page) }}>
                                <span>{link.text}</span>
                            </a>
                        )
                    })}

                </nav>

            );
        }

    }

    render() {
        const { claraImg, startupName, startup, session } = this.props;
        const classProps = this.props.className ? this.props.className : "";
        return (
            <div className={`${classes.Header} ${classProps}`}>

                {(this.props.isSignIn || this.props.isSignUp || this.props.isGetstarted || this.props.isForgotPass) ? (
                    <React.Fragment><img src={claraImg ? claraImg : "/img/claraIconLogin.svg"} className={classes.headerLogo} alt="Clara" /></React.Fragment>
                ) : (
                        /*<img src="/img/clara_logo.svg" alt="clara"></img>*/
                        ""
                    )}
                {startupName ?
                    <div className={classes.ZoneDropDownCompany}>
                        <div className={` ${classes.ZoneDropDownCompanyContent} centerY`}>
                            <Avatar name={startupName} />
                        </div>
                    </div>
                    : null}
                {!startupName && startup && (
                        <BusinessRuleAccess conditions={[Constants.BUSINESS_RULES.IS_NOT_FORMATION, Constants.BUSINESS_RULES.IS_NOT_SERVICE_AGENT]}>
                            <div className={classes.ZoneDropDownCompany}>
                                <div className={` ${classes.ZoneDropDownCompanyContent} centerY`}>
                                    <Avatar name={startup.name} />
                                </div>
                            </div>
                        </BusinessRuleAccess>
                )}
                {this.componentJSX_getDropDownCompany()}
                {this.componentJSX_getDropDownMenu()}
                {this.componentJSX_getMenu()}

            </div>
        );
    }
}

const mapStateToProps = state => ({
    session: state.session
});
const mapDispatchToProps = (dispatch) => ({

});
export default connect(mapStateToProps, mapDispatchToProps)(Header)

import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import ModalAssistant, { ModalAssistantProps } from '../../../generic/components/Modal/ModalAssistant'
import Wizard from '../../../generic/components/Wizard'
import useModal from "../../../generic/hooks/useModal"
import useTranslate from '../../../generic/hooks/useTranslate'
import { addAssistantText, clearAssistatText } from '../../../generic/store/action'
import MachineAddressAndCurrency from './machine'

interface ModalAddressAndCurrencyProps extends ModalAssistantProps {
    onClose(): void,
    paramsMutation: any
}

const ModalAddressAndCurrency: FC<ModalAddressAndCurrencyProps> = ({ children, open: openParam = false, paramsMutation, onClose, ...props }: ModalAddressAndCurrencyProps) => {

    const [open, setOpen] = useState(openParam);

    const {closeModal} =useModal();
    const dispatch = useDispatch();

    const { translate } = useTranslate();

    useEffect(() => {
        setOpen(openParam);
    }, [openParam])
    const handleClose = (values) => {
        closeModal();
        setOpen(false);
    }

    const handleInitModal = () => {
        dispatch(clearAssistatText());
        dispatch(addAssistantText(translate('MODULES.MAP.WIZARD_COMPANY_DETAIL_INIT_TEXT_ASSISTANCE'), "text"));
    }


    useEffect(() => {
        handleInitModal()
        return () => {
            dispatch(clearAssistatText());
        }
    }, []);


    return (
        <ModalAssistant open={open} onClose={handleClose}>
            <Wizard machine={MachineAddressAndCurrency} initialValues={paramsMutation} onComplete={handleClose} />
        </ModalAssistant>

    )
}

export default ModalAddressAndCurrency;





import { gql } from '@apollo/client';
import useMutation from "src/hooks/custom/useMutation";
import useSession from "src/modules/session/hooks/useSession";
import { COMPANY_CORPORATE_MAP } from 'src/modules/map/hooks/services/useCompanyCorporateMap'; 
export const ADD_COMPONENT_ROLE = `
mutation addComponentRole(
    $startupId: ID!
    $stakeholder: ComponentStakeholderInputType!
    $roles: [RolesArrayInputAddType]
) {
    addComponentStakeholderRole(
    startupId: $startupId
    stakeholder: $stakeholder
    roles: $roles
    ) {
        id
        fullName
        isFounder
        roles{
          id
          name
          entity{
            ... on StakeHolder {
              id
              fullName
            }
            ... on GroupCompany {
              id
              name
            }
          }
        }
    }
  }
`
const useAddComponentRole=(config,props)=>{
  const { startupId } = useSession();
    const [addComponentRole] = useMutation(ADD_COMPONENT_ROLE, config,{ refetchQueries:[{query:gql`${COMPANY_CORPORATE_MAP}`, variables:{startupId:startupId}}], ...props })
    return {addComponentRole}
}
export default useAddComponentRole
import { useCallback, useMemo } from "react";
import useModal from 'src/modules/generic/hooks/useModal';
import { useDispatch } from "react-redux";
import { goToPage } from "src/store/actions/page";
import { Constants } from "src/v1/utils/constants.js";
import ModalMarkTaskCompleted from "src/modules/map/modals/ModalMarkTaskCompleted";
import _ from "lodash";

const useFunctionsTasksEquity = ({openPublish}) => {
  const { openModal } = useModal();
  const dispatch = useDispatch();

  const handleDeleteTask = useCallback(async (params, task) => {
    openModal(
      ModalMarkTaskCompleted,
      {
        groupCompanyId: params?.additionalParams[0]?.entityId,
        taskCode: task
      },
      "MODAL_MARK_TASK_COMPLETED"
    );
  }, [openModal]);

  const dictionary = useMemo(() =>({
    "TASK_SHARE_CLASSES_ADD": {
      onClick: (params) => {
        goToPage(
          dispatch,
          Constants.PAGES["capTable.mf.shareClass.list"],
          {
            groupCompanyId: params?.additionalParams[0]?.entityId
          }
        );
      },
    },
    "TASK_SHARE_ISSUANCES_ADD": {
      onClick: (params) => {
        goToPage(
          dispatch,
          Constants.PAGES["capTable.mf.shares.list"],
          {
            groupCompanyId: params?.additionalParams[0]?.entityId
          }
        );
      },
    },
    "TASK_OPTION_PLANS_OPTION_GRANTS_ADD": {
      onClick: (params) => {
        goToPage(
          dispatch,
          Constants.PAGES["capTable.mf.options.list"],
          {
            groupCompanyId: params?.additionalParams[0]?.entityId
          }
        );
      },
      deleteClick: (params) => {
        handleDeleteTask(params, "TASK_OPTION_PLANS_OPTION_GRANTS_ADD");
      },
    },
    "TASK_OPTION_PLAN_ADD": {
      onClick: (params) => {
        goToPage(
          dispatch,
          Constants.PAGES["capTable.mf.options.list"],
          {
            groupCompanyId: params?.additionalParams[0]?.entityId
          }
        );
      },
      deleteClick: (params) => {
        handleDeleteTask(params, "TASK_OPTION_PLAN_ADD");
      },
    },
    "TASK_CONVERTIBLES_ADD": {
      onClick: (params) => {
        goToPage(
          dispatch,
          Constants.PAGES["capTable.mf.convertibles.list"],
          {
            groupCompanyId: params?.additionalParams[0]?.entityId
          }
        );
      },
      deleteClick: (params) => {
        handleDeleteTask(params, "TASK_CONVERTIBLES_ADD");
      },
    },
    "TASK_WARRANTS_ADD": {
      onClick: (params) => {
        goToPage(
          dispatch,
          Constants.PAGES["capTable.mf.warrants.list"],
          {
            groupCompanyId: params?.additionalParams[0]?.entityId
          }
        );
      },
      deleteClick: (params) => {
        handleDeleteTask(params, "TASK_WARRANTS_ADD");
      },
    },
    "TASK_SHARE_CLASS_PUBLISH": {
      onClick: (params) => {
        const data = {
          initialValues: {
            groupCompanyName: params?.stakeholderName,
            shareClassName:params?.additionalParams[0]?.name,
          },
          groupCompanyId: params?.groupCompanyId,
          shareClassId: params?.additionalParams[0]?.entityId,
        }
        openPublish(data, "MF_ShareClass_Modal_Publish", "./ShareClassPubishModal");
      },
    },
    "TASK_CONVERTIBLE_PUBLISH": {
      onClick: (params) => {
        const data = {
          initialValues: {
            groupCompanyName:  params?.stakeholderName,
          },
          groupCompanyId: params?.groupCompanyId,
          convertibleId: params?.additionalParams[0]?.entityId,
        }
        openPublish(data, "MF_Convertible_Modal_Publish", "./ConvertiblesPubishModal");
      },
    },
    "TASK_CONVERTIBLE_CANCEL_TRANSACTION_PUBLISH": {
      onClick: (params) => {
        const transactionData = _.find(params?.additionalParams, {entityType: "TransactionACT"});
        const convertibleData = _.find(params?.additionalParams, {entityType: "ConvertibleACT"});
        const data = {
          groupCompanyId: params?.groupCompanyId,
          convertibleId: convertibleData?.entityId,
          transactionId: transactionData?.entityId,
        }
        openPublish(data, "MF_Convertible_Transaction_Publish_Modal", "./ConvertiblesTransactionPublishModal");
      },
    },
    "TASK_CONVERTIBLE_CONVERT_TRANSACTION_PUBLISH": {
      onClick: (params) => {
        const transactionData = _.find(params?.additionalParams, {entityType: "TransactionACT"});
        const convertibleData = _.find(params?.additionalParams, {entityType: "ConvertibleACT"});
        const data = {
          groupCompanyId: params?.groupCompanyId,
          convertibleId: convertibleData?.entityId,
          transactionId: transactionData?.entityId,
        }
        openPublish(data, "MF_Convertible_Transaction_Publish_Modal", "./ConvertiblesTransactionPublishModal");
      },
    },
    "TASK_WARRANT_PUBLISH": {
      onClick: (params) => {
        const data = {
          groupCompanyId: params?.groupCompanyId,
          warrantId: params?.additionalParams[0]?.entityId,
        }
        openPublish(data, "MF_Warrant_Modal_Publish", "./WarrantsPubishModal");
      },
    },
    "TASK_WARRANT_CANCEL_TRANSACTION_PUBLISH": {
      onClick: (params) => {
        const transactionData = _.find(params?.additionalParams, {entityType: "TransactionACT"});
        const warrantData = _.find(params?.additionalParams, {entityType: "WarrantACT"});
        const data = {
          groupCompanyId: params?.groupCompanyId,
          warrantId: warrantData?.entityId,
          transactionId: transactionData?.entityId,
        }
        openPublish(data, "MF_Warrant_Transaction_Publish_Modal", "./WarrantsTransactionPublishModal");
      },
    },
    "TASK_WARRANT_EXERCISE_TRANSACTION_PUBLISH": {
      onClick: (params) => {
        const transactionData = _.find(params?.additionalParams, {entityType: "TransactionACT"});
        const warrantData = _.find(params?.additionalParams, {entityType: "WarrantACT"});
        const data = {
          groupCompanyId: params?.groupCompanyId,
          warrantId: warrantData?.entityId,
          transactionId: transactionData?.entityId,
        }
        openPublish(data, "MF_Warrant_Transaction_Publish_Modal", "./WarrantsTransactionPublishModal");
      },
    },
    "TASK_OPTION_PLAN_PUBLISH": {
      onClick: (params) => {
        const optionPlanData = _.find(params?.additionalParams, {entityType: "OptionPlan"});
        const shareClassData = _.find(params?.additionalParams, {entityType: "ShareClassACT"});
        const data = {
          initialValues: {
            groupCompanyName: params?.stakeholderName,
            optionPlanName: optionPlanData?.name,
          },
          groupCompanyId: params?.groupCompanyId,
          shareClassId: shareClassData?.entityId,
          optionPlanId: optionPlanData?.entityId,
        }
        openPublish(data, "MF_Option_Plan_Modal_Publish", "./OptionsPlanPubishModal");
      },
    },
    "TASK_SHARE_ISSUANCE_PUBLISH": {
      onClick: (params) => {
        const shareInsuanceData = _.find(params?.additionalParams, {entityType: "ShareIssuance"});
        const shareClassData = _.find(params?.additionalParams, {entityType: "ShareClassACT"});
        const data = {
          initialValues: {
            groupCompanyName: params?.stakeholderName,
          },
          groupCompanyId: params?.groupCompanyId,
          shareClassId: shareClassData?.entityId,
          shareIssuanceId: shareInsuanceData?.entityId,
        }
        openPublish(data, "MF_Share_Modal_Publish", "./SharePubishModal");
      },
    },
    "TASK_OPTION_GRANT_ADD": {
      onClick: (params) => {
        goToPage(
          dispatch,
          Constants.PAGES["capTable.mf.options.list"],
          {
            groupCompanyId: params?.company?.id
          }
        );
      }
    },
    "TASK_OPTION_GRANT_PUBLISH": {
      onClick: (params) => {
        const optionGrantData = _.find(params?.additionalParams, {entityType: "OptionGrant"});
        const shareClassData = _.find(params?.additionalParams, {entityType: "ShareClassACT"});
        const optionPlanData = _.find(params?.additionalParams, {entityType: "OptionPlan"});
        const data = {
          initialValues: {
            groupCompanyName: params?.stakeholderName,
            optionGrantName: `Option-${optionGrantData.data.customId}`,
          },
          groupCompanyId: params?.groupCompanyId,
          shareClassId: shareClassData?.entityId,
          optionPlanId: optionPlanData?.entityId,
          optionGrantId: optionGrantData?.entityId,
        }
        openPublish(data, "MF_OptionGrant_Modal_Publish", "./OptionsGrantPubishModal");
      },
    },
    "TASK_OPTION_PLAN_AMEND_TRANSACTION_PUBLISH": {
      onClick: (params) => {
        const transactionData = _.find(params?.additionalParams, {entityType: "TransactionACT"});
        const shareClassData = _.find(params?.additionalParams, {entityType: "ShareClassACT"});
        const optionPlanData = _.find(params?.additionalParams, {entityType: "OptionPlan"});
          const data = {
          initialValues: {
            groupCompanyName: params?.stakeholderName,
          },
          groupCompanyId: params?.groupCompanyId,
          shareClassId: shareClassData?.entityId,
          optionPlanId: optionPlanData?.entityId,
          transactionId: transactionData?.entityId,
        }
        openPublish(data, "MF_PublishAmendOptionPlan_Modal_Publish", "./PublishAmendOptionPlan");
      },
    },
    "TASK_OPTION_GRANT_LAPSE_TRANSACTION_PUBLISH": {
      onClick: (params) => {
        const transactionData = _.find(params?.additionalParams, {entityType: "TransactionACT"});
        const shareClassData = _.find(params?.additionalParams, {entityType: "ShareClassACT"});
        const optionPlanData = _.find(params?.additionalParams, {entityType: "OptionPlan"});
        const optionGrantData = _.find(params?.additionalParams, {entityType: "OptionGrant"});
        const data = {
          initialValues: {
            groupCompanyName: params?.stakeholderName,
          },
          groupCompanyId: params?.groupCompanyId,
          shareClassId: shareClassData?.entityId,
          optionPlanId: optionPlanData?.entityId,
          transactionId: transactionData?.entityId,
          optionGrantId: optionGrantData?.entityId,
        }
        openPublish(data, "MF_PublishLapseOptionGrant_Modal_Publish", "./OptionsGrantLapsePublishModal");
      },
    },
    "TASK_OPTION_GRANT_EXERCISE_TRANSACTION_PUBLISH": {
      onClick: (params) => {
        const transactionData = _.find(params?.additionalParams, {entityType: "TransactionACT"});
        const shareClassData = _.find(params?.additionalParams, {entityType: "ShareClassACT"});
        const optionPlanData = _.find(params?.additionalParams, {entityType: "OptionPlan"});
        const optionGrantData = _.find(params?.additionalParams, {entityType: "OptionGrant"});
        const data = {
          initialValues: {
            groupCompanyName: params?.stakeholderName,
            optionGrantName: `Option-${optionGrantData?.data?.customId}` || 'Option grant',
          },
          groupCompanyId: params?.groupCompanyId,
          shareClassId: shareClassData?.entityId,
          optionPlanId: optionPlanData?.entityId,
          optionGrantId: optionGrantData?.entityId,
          transactionId: transactionData?.entityId,
        }
        openPublish(data, "MF_PublishExerciseOptionGrant_Modal_Publish", "./OptionsGrantExercisePublishModal");
      },
    },
    "TASK_OPTION_GRANT_VESTING_STOP_TRANSACTION_PUBLISH": {
      onClick: (params) => {
        const transactionData = _.find(params?.additionalParams, {entityType: "TransactionACT"});
        const shareClassData = _.find(params?.additionalParams, {entityType: "ShareClassACT"});
        const optionPlanData = _.find(params?.additionalParams, {entityType: "OptionPlan"});
        const optionGrantData = _.find(params?.additionalParams, {entityType: "OptionGrant"});
        const data = {
          initialValues: {
            groupCompanyName: params?.stakeholderName,
            optionGrantName: `Option-${optionGrantData?.data?.customId}` || 'Option grant',
          },
          groupCompanyId: params?.groupCompanyId,
          shareClassId: shareClassData?.entityId,
          optionPlanId: optionPlanData?.entityId,
          optionGrantId: optionGrantData?.entityId,
          transactionId: transactionData?.entityId,
        }
        openPublish(data, "MF_PublishStopVestingOptionGrant_Modal_Publish", "./OptionsGrantStopVestingPublishModal");
      },
    },
    "TASK_SHARE_ISSUANCE_CANCEL_TRANSACTION_PUBLISH": {
      onClick: (params) => {
        const transactionData = _.find(params?.additionalParams, {entityType: "TransactionACT"});
        const shareClassData = _.find(params?.additionalParams, {entityType: "ShareClassACT"});
        const shareInsuanceData = _.find(params?.additionalParams, {entityType: "ShareIssuance"});
        const data = {
          initialValues: {
            groupCompanyName: params?.stakeholderName,
            shareName: `${shareClassData?.data?.defaultIdPrefix}-${shareInsuanceData?.data?.customId}` || 'Share',
          },
          groupCompanyId: params?.groupCompanyId,
          shareClassId: shareClassData?.entityId,
          shareIssuanceId: shareInsuanceData?.entityId,
          transactionId: transactionData?.entityId,
        }
        openPublish(data, "MF_PublishCancelShareIssuance_Modal_Publish", "./ShareCancelPublishModal");
      },
    },
    "TASK_OPTION_GRANT_EXERCISE_NOTICE_TRANSACTION_CREATE": {
      onClick: (params) => {
        const transactionData = _.find(params?.additionalParams, {entityType: "TransactionACT"});
        const shareClassData = _.find(params?.additionalParams, {entityType: "ShareClassACT"});
        const optionPlanData = _.find(params?.additionalParams, {entityType: "OptionPlan"});
        const optionGrantData = _.find(params?.additionalParams, {entityType: "OptionGrant"});
        const data = {
          initialValues: {
            groupCompanyName: params?.stakeholderName,
          },
          groupCompanyId: params?.groupCompanyId,
          shareClassId: shareClassData?.entityId,
          optionGrantId: optionGrantData?.entityId,
          transactionId: transactionData?.entityId,
          optionPlanId: optionPlanData?.entityId,
          amount: transactionData?.data?.quantity,
        }
        openPublish(data, "MF_PublishGrantExerciseNotice_Modal_Publish", "./OptionsGrantExerciseNoticePublishModal");
      },
    },
    "TASK_SHARE_CLASS_AMEND_TRANSACTION_PUBLISH": {
      onClick: (params) => {
        const transactionData = _.find(params?.additionalParams, {entityType: "TransactionACT"});
        const shareClassData = _.find(params?.additionalParams, {entityType: "ShareClassACT"});
        const data = {
          initialValues: {
            groupCompanyName: params?.stakeholderName,
          },
          groupCompanyId: params?.groupCompanyId,
          shareClassId: shareClassData?.entityId,
          transactionId: transactionData?.entityId,
        }
        openPublish(data, "MF_PublishAmendShareClass_Modal_Publish", "./PublishAmendShareClass");
      },
    },
    "TASK_SHARE_ISSUANCE_TRANSFER_TRANSACTION_ADD_TO_CART": {
      onClick: (params) => {
        const transactionData = _.find(params?.additionalParams, {entityType: "TransactionACT"});
        const shareClassData = _.find(params?.additionalParams, {entityType: "ShareClassACT"});
        const shareInsuanceData = _.find(params?.additionalParams, {entityType: "ShareIssuance"});
        const data = {
          initialValues: {
            groupCompanyName: params?.stakeholderName,
          },
          groupCompanyId: params?.groupCompanyId,
          shareClassId: shareClassData?.entityId,
          transactionId: transactionData?.entityId,
          shareIssuanceId: shareInsuanceData?.entityId,
        }
        openPublish(data, "MF_PublishTransferTransactionIssuance_Modal_Publish", "./TransferTransactionShareIssuanceAddToCart");
      },
    },
    "TASK_SHARE_ISSUANCE_CANCEL_TRANSACTION_ADD_TO_CART": {
      onClick: (params) => {
        const transactionData = _.find(params?.additionalParams, {entityType: "TransactionACT"});
        const shareClassData = _.find(params?.additionalParams, {entityType: "ShareClassACT"});
        const shareInsuanceData = _.find(params?.additionalParams, {entityType: "ShareIssuance"});
        const data = {
          initialValues: {
            groupCompanyName: params?.stakeholderName,
          },
          groupCompanyId: params?.groupCompanyId,
          shareClassId: shareClassData?.entityId,
          shareIssuanceId: shareInsuanceData?.entityId,
          transactionId: transactionData?.entityId,
        }
        openPublish(data, "MF_PublishTransferCancelIssuance_Modal_Publish", "./CancerTransactionShareIssuanceAddToCart");
      },
    },
    "TASK_WARRANT_EXERCISE_TRANSACTION_ADD_TO_CART": {
      onClick: (params) => {
        const transactionData = _.find(params?.additionalParams, {entityType: "TransactionACT"});
        const warrantData = _.find(params?.additionalParams, {entityType: "WarrantACT"});
        const data = {
          initialValues: {
            groupCompanyName: params?.stakeholderName,
          },
          groupCompanyId: params?.groupCompanyId,
          warrantId: warrantData?.entityId,
          transactionId: transactionData?.entityId,
        }
        openPublish(data, "MF_TransactionExerciseWarrantAddToCart_Modal_Publish", "./TransactionExerciseWarrantsAddToCart");
      },
    },
    "TASK_CONVERTIBLE_CONVERT_TRANSACTION_ADD_TO_CART": {
      onClick: (params) => {
        const convertibleData = _.find(params?.additionalParams, {entityType: "ConvertibleACT"});
        const transactionData = _.find(params?.additionalParams, {entityType: "TransactionACT"});
        const data = {
          initialValues: {
            groupCompanyName: params?.stakeholderName,
          },
          groupCompanyId: params?.groupCompanyId,
          convertibleId: convertibleData?.entityId,
          transactionId: transactionData?.entityId, 
        }
        openPublish(data, "MF_TransactionConvertiblesConvertAddToCart_Modal_Publish", "./ConvertiblesConvertTransactionAddToCartModal");
      },
    },
    "TASK_OPTION_GRANT_CONVERT_TRANSACTION_ADD_TO_CART": {
      onClick: (params) => {
        const transactionData = _.find(params?.additionalParams, {entityType: "TransactionACT"});
        const shareClassData = _.find(params?.additionalParams, {entityType: "ShareClassACT"});
        const optionPlanData = _.find(params?.additionalParams, {entityType: "OptionPlan"});
        const optionGrantData = _.find(params?.additionalParams, {entityType: "OptionGrant"});
        const data = {
          initialValues: {
            groupCompanyName: params?.stakeholderName,
          },
          groupCompanyId: params?.groupCompanyId,
          shareClassId: shareClassData?.entityId,
          optionGrantId: optionGrantData?.entityId,
          transactionId: transactionData?.entityId,
          optionPlanId: optionPlanData?.entityId,
        }
        openPublish(data, "MF_GrantTransactionConvert_Modal_addToCart", "./OptionsGrantConvertTransactionAddToCartModal");
      },
    },
    "TASK_SHARE_ISSUANCE_ADD_TO_CART": {
      onClick: (params) => {
        const shareClassData = _.find(params?.additionalParams, {entityType: "ShareClassACT"});
        const shareInsuanceData = _.find(params?.additionalParams, {entityType: "ShareIssuance"});
        const data = {
          initialValues: {
            groupCompanyName: params?.stakeholderName,
            shareName: `${shareClassData?.data?.defaultIdPrefix}-${shareInsuanceData?.data?.customId}` || 'Share',
          },
          groupCompanyId: params?.groupCompanyId,
          shareClassId: shareClassData?.entityId,
          shareIssuanceId: shareInsuanceData?.entityId,
        }
        openPublish(data, "MF_ShareIssuance_Modal_addToCart", "./ShareIssuanceAddToCart");
      },
    },
    "TASK_SHARE_ISSUANCE_TRANSFER_TRANSACTION_PUBLISH": {
      onClick: (params) => {
        const shareClassData = _.find(params?.additionalParams, {entityType: "ShareClassACT"});
        const shareInsuanceData = _.find(params?.additionalParams, {entityType: "ShareIssuance"});
        const transactionData = _.find(params?.additionalParams, {entityType: "TransactionACT"});
        const data = {
          initialValues: {
            groupCompanyName: params?.stakeholderName,
            shareName: `${shareClassData?.data?.defaultIdPrefix}-${shareInsuanceData?.data?.customId}` || 'Share',
          },
          groupCompanyId: params?.groupCompanyId,
          shareClassId: shareClassData?.entityId,
          shareIssuanceId: shareInsuanceData?.entityId,
          transactionId: transactionData?.entityId,
        }
        openPublish(data, "MF_ShareIssuanceTransferTransaction_Modal_publish", "./ShareIssuanceTransferTransactionPublish");
      },
    },
    "TASK_OPTION_GRANT_CONVERT_TRANSACTION_PUBLISH": {
      onClick: (params) => {
        const transactionData = _.find(params?.additionalParams, {entityType: "TransactionACT"});
        const shareClassData = _.find(params?.additionalParams, {entityType: "ShareClassACT"});
        const optionPlanData = _.find(params?.additionalParams, {entityType: "OptionPlan"});
        const optionGrantData = _.find(params?.additionalParams, {entityType: "OptionGrant"});
        const data = {
          initialValues: {
            groupCompanyName: params?.stakeholderName,
            optionGrantName: `Option-${optionGrantData?.data?.customId}` || 'Option grant',
          },
          groupCompanyId: params?.groupCompanyId,
          shareClassId: shareClassData?.entityId,
          optionGrantId: optionGrantData?.entityId,
          transactionId: transactionData?.entityId,
          optionPlanId: optionPlanData?.entityId,
        }
        openPublish(data, "MF_OptionGrantConvertTransaction_Modal_publish", "./OptionsGrantConvertTransactionPublish");
      },
    },
    "TASK_SHARE_CLASS_AMEND_PUBLISH": {
      onClick: (params) => {
        // console.log("**** data", params);
        // const data ={
        //   initialValues: {
        //     groupCompanyName: params?.stakeholderName,
        //     shareClassName: params?.additionalParams?.name,
        //     shareClassId: params?.additionalParams?.entityId,
        //   },
        //   transactionId: t?.additionalParams[0].entityId
        // }
      },
    },
  }), [dispatch, openPublish, handleDeleteTask]);
  return dictionary
}
export default useFunctionsTasksEquity

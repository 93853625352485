import React from "react";
import TypographyLocal from "src/modules/generic/components/Texts/Typography";
import classes from "../styles.module.scss";
import DetailsTabTitle from "src/components/templates/DetailsTabTitle";
import Box from "@mui/material/Box";
import { RoleAccess } from "src/v1/components";
import { Constants } from "src/v1/utils/constants";
import Button from "src/modules/generic/components/Buttons/Button";
import useModal from "src/modules/generic/hooks/useModal";
import EditEconomicSubstanceInformationModal from "src/views/modules/startups/renewal/cayman/modals/EditEconomicSubstanceInformationModal";
import _ from "lodash";

const InformationPanel = ({ data, isLock, renewalData }) => {
  const taxInformation = _.get(renewalData, "getRenewalData")?.taxInformation;
  const { openModal, closeModal } = useModal();

  const buttonEditInformation = (
    <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
      <Button
        variant="card"
        disabled={isLock}
        children={"Edit"}
        onClick={() => {
          openModal(EditEconomicSubstanceInformationModal, {
            handleCloseModal: closeModal,
            initialValues: { groupCompanyId: data?.id ,ultimateBeneficialOwnerId: data?.ultimateBeneficialOwner?.id},
          }, 'MODAL_ADD_ULTIMATE_BENEFICIAL_OWNER');
        }}
      />
    </RoleAccess>
  );

  return (
    <Box sx={{ paddingRight: "0.4615rem" }}>
      <DetailsTabTitle title="Economic substance information" buttons={buttonEditInformation}/>
      <ol type="a">
        <li className={classes.questionContainer}>
          <div>
            <TypographyLocal variant="m" color="dark-grey">
              Is the company tax resident outside of the Cayman Islands?
            </TypographyLocal>
          </div>
          <div>
            <TypographyLocal variant="m">{`${taxInformation?.isTaxResidentsAreFromCayman?.question ? 'Yes' : 'No'} ${taxInformation?.isTaxResidentsAreFromCayman?.description ? `- ${taxInformation?.isTaxResidentsAreFromCayman?.description}` : ''} `}</TypographyLocal>
          </div>
        </li>
        <li className={classes.questionContainer}>
          <div>
            <TypographyLocal variant="m" color="dark-grey">
              {`Is the company an Investment Fund (as defined in the Economic
            Substance Act)?`}
            </TypographyLocal>
          </div>
          <div>
            <TypographyLocal variant="m">{`${taxInformation?.isEntityInvestmentFund?.question ? 'Yes' : 'No'} ${taxInformation?.isEntityInvestmentFund?.description ? `- ${taxInformation?.isEntityInvestmentFund?.description}` : ''} `}</TypographyLocal>
          </div>
        </li>
        <li className={classes.questionContainer}>
          <div>
            <TypographyLocal variant="m" color="dark-grey">
              {`If the company carried on a relevant activity, was it a domestic
            company or local partnership (as defined in the Economic Substance
            Act)?`}
            </TypographyLocal>
          </div>
          <div>
          <TypographyLocal variant="m">{`${taxInformation?.isCompanyCarriedOnRelevantActivity?.question ? 'Yes' : 'No'} ${taxInformation?.isCompanyCarriedOnRelevantActivity?.description ? `- ${taxInformation?.isCompanyCarriedOnRelevantActivity?.description}` : ''} `}</TypographyLocal>
          </div>
        </li>
        <li className={classes.questionContainer}>
          <div>
            <TypographyLocal variant="m" color="dark-grey">
              Does the company have an FI Number issued by the Cayman Islands
              Department for International Tax Cooperation?
            </TypographyLocal>
          </div>
          <div>
            <TypographyLocal variant="m">{`${taxInformation?.doesHaveFINumber?.question ? 'Yes' : 'No'} ${taxInformation?.doesHaveFINumber?.description ? `- ${taxInformation?.doesHaveFINumber?.description}` : ''} `}</TypographyLocal>
          </div>
        </li>
        <li className={classes.questionContainer}>
          <div>
            <TypographyLocal variant="m" color="dark-grey">
              Does the company have an IRS GIIN?
            </TypographyLocal>
          </div>
          <div>
          <TypographyLocal variant="m">{`${taxInformation?.doesHaveIRSGIN?.question ? 'Yes' : 'No'} ${taxInformation?.doesHaveIRSGIN?.description ? `- ${taxInformation?.doesHaveIRSGIN?.description}` : ''} `}</TypographyLocal>
          </div>
        </li>
      </ol>
    </Box>
  );
};

export default InformationPanel;

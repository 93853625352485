import React from "react";

import AssistanceContents from "../../AssistanceContents";
import TabBubblesContainer from "../../TabBubblesContainer";


const MyAccountTaskComponent=  () => {

  

  return (
    <AssistanceContents>
      <TabBubblesContainer>
        

      </TabBubblesContainer>
    </AssistanceContents>
  );
};

export default MyAccountTaskComponent;

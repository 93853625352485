import React from 'react';
import { Modal } from '../../../../../../../v1/components';
import { Constants } from "../../../../../../../v1/utils";
import CancelGetSignatures from "./CancelGetSignatures";

const CancelGetSignaturesModal = ({ open, handleCloseForm, initialValues, goTo, redirect,onComplete, ...props }) => {

    return (
        <Modal
            open={open} 
            overlayName={Constants.FORM_TYPES.DOCUMENT_GENERATED.VOID_ENVELOPE} handleClose={() => handleCloseForm()} showHeader={false}>
            <CancelGetSignatures  handleCloseModal={handleCloseForm} initialValues={initialValues} goTo={goTo} redirect={redirect} onComplete={onComplete}/>
        </Modal>
    )
}

export default CancelGetSignaturesModal;

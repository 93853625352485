import { createMachine } from 'xstate';

const machine =
  createMachine({
    id: 'EditBusinessActivity',
    initial: 'initialData',
    states: {
      initialData: {
        invoke: {
          src: 'initialData',
          id: 'initialData',
          onDone: [
            {
              target: 'pincasData',
              actions: ['setContextAfterInvoke']
            },
          ],
            onError:[{
                target:'cancel'
              }
          ],
        }
      },
      pincasData: {
        invoke: {
          src: 'pincasData',
          id: 'pincasData',
          onDone: [
            {
              target: 'EditBusinessActivityForm',
              actions: ['setContextAfterInvoke']
            }
          ],
        }
      },
      EditBusinessActivityForm : {
        on: {
          NEXT: {
            target: 'SaveBusinessActivity',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      SaveBusinessActivity: {
        invoke: {
          src: 'SaveBusinessActivity',
          id: 'SaveBusinessActivity',
          onDone: [
            {
              target: 'finish',
            }
          ],
          onError: [{
              target: 'cancel'
            }]
        }
      },
      cancel: {
        entry: 'cancelMachine',
        type: 'final'
      },
      finish: {
        entry: 'stopMachine',
        type: 'final'
      }
    }
  });

export default machine;

import { useMemo } from "react";
import { assign } from 'xstate';
import useUpsertEquity from "src/modules/startup/equity/graphql/mutation/upsertEquity";
import useUpsertWarrant from "../../graphql/mutation/upsertWarrant";
import useUpsertConvertible from "src/modules/startup/equity/graphql/mutation/upsertConvertible";
import { get, omit, find, some } from 'lodash'
import { stripTypenames } from "src/v1/utils/graphql-util";
import useSession from 'src/modules/session/hooks/useSession';
import { useParams } from "react-router";
import moment from "moment";
import { Constants } from "src/v1/utils";
import useNavigation from 'src/modules/generic/hooks/useNavigation';
import _ from "lodash"
import  useRegisterAsCheckedTask from "src/modules/generic/graphql/mutation/useRegisterAsCheckedTask"
const useActions = () => {

  const { mutation } = useUpsertEquity({ showSuccessNotification: false });
  const {mutation: registerAsCheckedTask} = useRegisterAsCheckedTask()
  const { upsertWarrant } = useUpsertWarrant({ showSuccessNotification: false });
  const { upsertConvertible } = useUpsertConvertible({ showSuccessNotification: false });
  const { startupId } = useSession();
  const { goTo } = useNavigation();
  const { id } = useParams<{ id: string }>();

  const getDocuments = (values) => {
    if (values) {
      if (values.file || values.document) {
        if (values.file) return { uploadedFiles: [{ ...values.file, title: values.name, documentType: values.documentType }] }
        return { documentsUploaded: [{ ...values.document }] }
      }
    }
    return {}
  }

  const getCurrency = (id, groupCompanies) => {
    const company = find(groupCompanies, { id })
    return company.transactionCurrency || 'USD'
  }

  const getType = (id, stakeholdersGroupCompanies) => {
    return some(stakeholdersGroupCompanies.stakeholders, { id }) ? 'stakeholder' : 'groupCompany'
  }

  return useMemo(() => ({
    setContextAfterStepOriginal: assign((context, event) => {
      const idState = _.get(event, 'payload.idState');
      const values = _.get(event, 'payload.values');
      let newValueState = {};
      if (values && idState) newValueState = { [idState]: values };
      return { ...context, ...newValueState };
    }),
    setContextAfterStep: assign((context, event) => {
      const values = get(event, 'payload.values');
      const solution = { ...context, ...values };
      return solution;
    }),
    setIterations: assign((context, event) => {
      const iterations = get(context, 'iterations', 0);
      const solution = { ...context, iterations: iterations + 1 };
      return solution;
    }),
    resetIterations: assign((context, event) => {
      const solution = { ...context, iterations: 0 };
      return solution;
    }),
    setGroupCompanies: assign((context, event) => {
      const groupCompanies = get(event, 'payload.values.companies');
      const solution = { ...context, groupCompanies };
      return solution;
    }),
    setEquity: assign((context, event) => {
      const equity = get(event, 'payload.values');
      const solution = { ...context, equity };
      return solution;
    }),
    upsertEquity: async (context, event) => {
      const values = get(event, 'payload.values');
      const { equity } = context;
      const { groupCompanies } = context
      const groupCompany = groupCompanies[get(context, 'iterations', 1) - 1]
      const documents = getDocuments(values)
      const variables = {
        startupId,
        shareType: context.type,
        isThereSIP: context.type === 'OPTION',
        groupCompanyId: groupCompany.id,
        equityData: {
          ...equity,
          isThereAgreement: true, // context.document === 'agreement',
          issuedDate: equity.issuedDate ? moment(equity.issuedDate).format('YYYY-MM-DD') : null,
          currency: getCurrency(groupCompany.id, context.getStakeholdersGroupCompanies.groupCompanies),
          price: equity.price ? equity.price : null,
          shareIncentivePlan: context.type === 'OPTION' ? get(equity, "shareIncentivePlan.id") : null,
          documents
        },
        owner: { id, type: getType(id, context.getStakeholdersGroupCompanies) },
      };
      await mutation({ ...stripTypenames(omit(variables, 'equityData.type')) });
      return {}
    },
    
    upsertWarrant: async (context, event) => {
      const values = get(event, 'payload.values');
      const { equity } = context;
      const { groupCompanies } = context
      const groupCompany = groupCompanies[get(context, 'iterations', 1) - 1]
      const documents = getDocuments(values)
      const variables = {
        startupId,
        owner: { id, type: "stakeholder" },
        warrantData: {
          ...equity,
          issuedDate: equity.issuedDate ? moment(equity.issuedDate).format('YYYY-MM-DD') : null,
          currency: getCurrency(groupCompany.id, context.getStakeholdersGroupCompanies.groupCompanies),
          groupCompany: groupCompany.id,
          documents,
        },
      };
      await upsertWarrant({ ...stripTypenames(variables) });
    },
    upsertConvertible: async (context, event) => {
      const values = get(event, 'payload.values');
      const { equity } = context;
      const { groupCompanies } = context
      const groupCompany = groupCompanies[get(context, 'iterations', 1) - 1]
      const documents = getDocuments(values)
      
      const variables = {
        startupId,

        owner: { id, type: "stakeholder" },
        convertibleData: {
          ...equity,
          issuedDate: equity.issuedDate ? moment(equity.issuedDate).format('YYYY-MM-DD') : null,
          currency: getCurrency(groupCompany.id, context.getStakeholdersGroupCompanies.groupCompanies),
          groupCompany: groupCompany.id,
          documents,
        },
        groupCompanyId: groupCompany.id,
      };
      if (context.documents === "agreement") {
        variables.convertibleData.isThereAgreement = true
      }
      const resultConvertible = await upsertConvertible({ variables });
      const register = await registerAsCheckedTask({
        startupId,
        registeredTaskData:{
          entityId:resultConvertible.data.upsertConvertible.id,
          taskCode:'TASK_ADD_CONVERTIBLE_AGREEMENT'
        }
      })

    },
    goTo: (context, event) => {
      const values = get(event, 'payload.values');
      if (context.type === 'CONVERTIBLE') {
        if (values.generate === 'safe' || values.generate === 'convertible') return goTo(Constants.PAGES.chooseDocumentType, { previewDocument: values.generate === 'safe' ? 'SAFE' : 'CONVERTIBLE_NOTE' })
        if (values.generate === 'true') return goTo(Constants.PAGES.upgrade)
      }
      if (values.generate === "SCALE_SUSCRIPTION_PAYMENT") {
        return goTo(Constants.PAGES.upgrade);
      }
    }
  }), [])
}

export default useActions;

import React, { useState, useEffect } from "react";
import classes from "./PanelMessages.module.scss";
import Search from "../../../../../generic/Search/Search";
import Icon from "../../../../../../modules/generic/components/Icon";
import { PopoverOption } from "../../../../../../v1/components/index";
import TabsMenu from "../../../../../../modules/generic/components/Tab";
import TabMenu from "../../../../../../modules/generic/components/Tab/TabMenu";
import FilterMenuMessages from "./FilterMenuMessages";
import ZoneMessagesPanel from "./ZoneMessagesPanel";
import _ from "lodash";
import { useSelector } from "react-redux";
import useDebounce from "../../../../../../hooks/custom/useDebounce";
import Checkbox from "../../../../../inputs/Checkbox/Checkbox";

const PanelMessages = ({ setThreadMessages, ...props }) => {

  const unread_messages = useSelector((state) => state.notifications.notifications.unread_messages);

  const [filtersValues, setFilterValues] = useState([
    { key: "ACTION", value: "NEWEST" },
    { key: "ENTITY", value: "ALL" },
  ]);

  const [search, setSearch] = useState("");

  const debouncedSearchTerm = useDebounce(search, 500);

  const handleAddSearchValue = (value) => {
    if (value) {
      setFilterValues((pre) => {
        if (_.some(pre, (filterValue) => filterValue.key === "SEARCH")) {
          return _.map(pre, (f) => (f.key === "SEARCH" ? { ...f, value } : f));
        }
        return [...pre, { key: "SEARCH", value }];
      });
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      handleAddSearchValue(debouncedSearchTerm);
    } else {
      filtersValues.length && setFilterValues((pre) => _.filter(pre, (f) => f.key !== "SEARCH"));
    }
  }, [debouncedSearchTerm]);

  const handleSelectEntity = (value) => {
    setFilterValues((pre) => {
      return _.map(pre, (f) => (f.key === "ENTITY" ? { ...f, value } : f));
    });
  };

  return (
    <div className={classes.containterPanelMessages}>
      <div className={classes.containerSearchAndButtonFilter}>
        <Search onChange={setSearch} value={search} />
        <ButtonFilter filters={filtersValues} handleFilters={setFilterValues} />
      </div>
      <TabsMenu
        initValue={0}
        tabs={[
          <TabMenu
            classes={{ root: classes.tabElement }}
            onClick={() => handleSelectEntity("ALL")}
          >
            <div className={classes.Link}>
              <label>All - {unread_messages.all}</label>
            </div>
          </TabMenu>,
          <TabMenu
            onClick={() => handleSelectEntity("REMARKS")}
            classes={{ root: classes.tabElement }}
          >
            <div className={classes.Link}>
              <label>Remarks - {unread_messages.remarks}</label>
            </div>
          </TabMenu>,
          <TabMenu
            onClick={() => handleSelectEntity("EVENT")}
            classes={{ root: classes.tabElement }}
          >
            <div className={classes.Link}>
              <label>Events - {unread_messages.events}</label>
            </div>
          </TabMenu>,
        ]}
      />
      <div className={classes.containerZonaMessages}>
        <ZoneMessagesPanel
          setThreadMessages={setThreadMessages}
          filtersValues={filtersValues}
          totalNewMessages={unread_messages.all}
        />
      </div>
    </div>
  );
};

export default PanelMessages;

const ButtonFilter = ({ filters, handleFilters, ...props }) => {
  const handleChangeFilter = (value) => {
    if (value) {
      handleFilters((pre) => {
        return _.map(pre, (f) => (f.key === "ACTION" ? { ...f, value } : f));
      });
    }
  };

  const filterOptions = [
    {
      key: "unread",
      label: "Unread",
      value: "UNREAD",
    },
    {
      key: "dateNewest",
      label: "Date (Newest)",
      value: "NEWEST",
    },
    {
      key: "dateOldest",
      label: "Date (Oldest)",
      value: "OLDEST",
    },
    {
      key: "flagged",
      label: "Flagged",
      value: "FLAGGED",
    },
    {
      key: "action",
      label: "Action",
      RenderElement: ({ handleSelectItem, filterSelect, ...props }) => {
        const filterValue = _.get(filterSelect, "filterType");

        const [openActions, setOpenActions] = useState(
          filterValue.key === "action" || false
        );

        const options = [
          {
            label: "All Actions",
            value: "ALL_ACTIONS",
          },
          {
            label: "Added",
            value: "ADDING",
          },
          {
            label: "Commented",
            value: "COMMENTING",
          },
          {
            label: "Deleted",
            value: "DELETING",
          },
          {
            label: "Edited",
            value: "EDITING",
          },
          {
            label: "Generated",
            value: "GENERATING",
          },
          {
            label: "Shared",
            value: "SHARING",
          },
          {
            label: "Uploaded",
            value: "UPLOADING",
          },
        ];

        return (
          <div className={classes.containerItemOptions}>
            <div
              className={`${classes.itemFilterMenuMessages} ${
                openActions ? classes.Open : ""
              }`}
              onClick={() => setOpenActions((pre) => !pre)}
            >
              <div
                className={`${classes.iconArrow} ${
                  openActions ? classes.iconArrowOpen : classes.iconArrowClose
                }`}
              >
                <Icon
                  isClara
                  icon={"Dropdown"}
                  size={"1rem"}
                  color={"#2C74FF"}
                />
              </div>
              Action
            </div>
            <div className={`${classes.containerOptions}  `}>
              <Checkbox
                options={options}
                isMultiple={false}
                className={{
                  root: `${
                    openActions ? classes.openOptions : classes.closeOptions
                  }`,
                }}
                values={filterValue.value}
                onChange={(value) =>
                  handleSelectItem({
                    key: "action",
                    label: _.startCase(_.camelCase(value)),
                    value,
                  })
                }
              />
            </div>
          </div>
        );
      },
    },
    {
      key: "entityType",
      label: "Entity Type",
      RenderElement: ({
        handleSelectItem,
        filterSelect,
        handleChangeType,
        ...props
      }) => {
        const filterValue = _.get(filterSelect, "filterType");
        const [openActions, setOpenActions] = useState(
          filterValue.key === "entityType" || false
        );

        const options = [
          {
            label: "All Entities",
            value: "ENTITY_ALL",
          },
          {
            label: "Companies",
            value: "COMPANIES",
          },
          {
            label: "Stakeholders",
            value: "STAKEHOLDERS",
          },
          {
            label: "Equity Positions",
            value: "EQUITYPOSITIONS",
          },
          {
            label: "Documents",
            value: "DOCUMENTS",
          },
          {
            label: "Remarks",
            value: "REMARKS",
          },
        ];

        return (
          <div className={classes.containerItemOptions}>
            <div
              className={`${classes.itemFilterMenuMessages} ${
                openActions ? classes.Open : ""
              }`}
              onClick={() => setOpenActions((pre) => !pre)}
            >
              <div
                className={`${classes.iconArrow} ${
                  openActions ? classes.iconArrowOpen : classes.iconArrowClose
                }`}
              >
                <Icon
                  isClara
                  icon={"Dropdown"}
                  size={"1rem"}
                  color={"#2C74FF"}
                />
              </div>
              Entity Type
            </div>
            <div className={`${classes.containerOptions}  `}>
              <Checkbox
                options={options}
                isMultiple={false}
                className={{
                  root: `${
                    openActions ? classes.openOptions : classes.closeOptions
                  }`,
                }}
                values={filterValue.value}
                onChange={(value) =>
                  handleSelectItem({
                    key: "entityType",
                    label: _.startCase(_.camelCase(value)),
                    value,
                  })
                }
              />
            </div>
          </div>
        );
      },
    },
  ];

  const [filterType, setFilterType] = useState(filterOptions[1]);

  const [openMenuOptions, setOpenMenuOptions] = useState(false);

  useEffect(() => {
    if (filterType) {
      handleChangeFilter(filterType.value);
    }
  }, [_.get(filterType, "value")]);

  return (
    <PopoverOption
      style={{ width: "100%", height: "100%" }}
      classicStyle={true}
      renderElement={FilterMenuMessages}
      propsRenderElement={{
        itemsMenu: filterOptions,
        handleSelectFilter: setFilterType,
        filterSelect: { filterType },
      }}
      callbacks={{close: () => setOpenMenuOptions((pre) => !pre)}}
    >
      <div
        className={classes.containerButtonFilter}
        onClick={() => setOpenMenuOptions((pre) => !pre)}
      >
        <label>
          By:
          <span className={classes.filterLabelSelectButtonFilter}>
            {filterType.label}
          </span>
        </label>
        <span
          className={`${
            openMenuOptions
              ? classes.openModalButtonFilter
              : classes.closeModalButtonFilter
          } `}
        >
          <Icon icon={"Dropdown"} size={"1.3077rem"} isClara />
        </span>
      </div>
    </PopoverOption>
  );
};

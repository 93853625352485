import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment'
import Handlebars from 'handlebars';
import TRANSLATES from "../../langs/us";
import { Constants } from '../../v1/utils/constants';

export default function useHandlebars(key, params, defaultKey) {
    const [text, setText] = useState("");

    Handlebars.registerHelper('getCountryByCode', function (code) {
        const countries = _.invert(Constants.COUNTRYCODES);
        return countries[code];
    });

    Handlebars.registerHelper('getDate', function (date) {
        return moment(date).format("DD/MM/YYYY");
    });

    Handlebars.registerHelper('isEqual', function (valueA, valueB, options) {
        if (_.isEqual(valueA, valueB)) {
            return options.fn(this)
        }
        return options.inverse(this)
    });

    const getTranslateTextByKey = (key, params, defaultKey) => {
        if (!key) {
            return null;
        }
        const label = TRANSLATES[key.toUpperCase()] ? TRANSLATES[key.toUpperCase()] : getTranslateTextByKey(defaultKey, params);
        if (!label) {
            return key;
        }
        const template = Handlebars.compile(label);
        return template(params);

    };

    useEffect(() => {
        const newText = getTranslateTextByKey(key, params, defaultKey);
        setText(newText);
    }, [key, params, defaultKey]);

    return text;
}

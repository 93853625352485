import React, { FC, useEffect } from 'react'
import * as Yup from 'yup'
import ModalAssistant, { ModalAssistantProps } from "../../../../generic/components/Modal/ModalAssistant";
import { useDispatch } from 'react-redux'
import useTranslate from '../../../../generic/hooks/useTranslate'
import useModal from "../../../../generic/hooks/useModal";
import Button from "../../../../generic/components/Buttons/Button";
import { addAssistantText, clearAssistatText } from "../../../../generic/store/action";
import { useParams } from "react-router-dom";
import useSession from "../../../../session/hooks/useSession";
import Form from "../../../../../components/forms/Form/FormL";
import Template from "./Template";
import { IsPEP, IsPEPCloseAssociate, IsPEPFamilyMember } from "../../Forms/FormEditPEP";
import SubmitButton from "../../../../generic/components/Buttons/SubmitButton";
import useGetStakeholderPEP from "../graphql/useGetStakeholderADGM";
import useUpdateStakeholder from "src/modules/startup/onBoarding/core/graphql/mutations/useUpdateStakeholder";

interface ModalNoticesProps extends ModalAssistantProps {
  paramsMutation: any,
  onClose(): void
}

const schema = Yup.object().shape({
  isPEP: Yup.boolean().nullable(),
  descriptionOfPEP: Yup.string().when(['isPEP'], {
    is: true,
    then: Yup.string().nullable().required('Required'),
    otherwise: Yup.string().optional()
  }),
  isCloseAssociateOfAPEP: Yup.boolean().nullable(),
  closeAssociateOfAPEP: Yup.string().when(['isCloseAssociateOfAPEP'], {
    is: true,
    then: Yup.string().nullable().required('Required'),
    otherwise: Yup.string().optional()
  }),
  isFamilyMemberOfAPEP: Yup.boolean().nullable(),
  familyMemberOfAPEPDescription: Yup.string().when(['isFamilyMemberOfAPEP'], {
    is: true,
    then: Yup.string().nullable().required('Required'),
    otherwise: Yup.string().optional()
  })
})
const ModalNotices: FC<ModalNoticesProps> = ({ children, open = false, paramsMutation, ...props }: ModalNoticesProps) => {

  const { closeModal: onClose } = useModal();
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const { id } = useParams();
  const { startupId } = useSession();

  const { updateStakeholder } = useUpdateStakeholder({}, {});

  const { data } = useGetStakeholderPEP({ startupId, stakeholderId: id })

  const handleCancel = () => {
    onClose();
    clearAssistatText()
  }

  const handleInitModal = () => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText(translate('MODULES.STAKEHOLDER.PEP.FIRST_STEP.TOOLTIP')))
  }

  useEffect(() => {
    handleInitModal()
    return () => {
      dispatch(clearAssistatText());
    }
  }, [])

  const onCompleteSubmit = async (values) => {
    const variables = {
      startupId,
      stakeholderId: id,
      stakeholderData: {
        stakeholder: {
          pep: {
            ...values
          }
        }
      }
    }
    await updateStakeholder({ ...variables })
    onClose()
  }

  return (
    <ModalAssistant open={open}>
      {data && <Form
        defaultValues={data.pep}
        schema={schema}
        onlySendDirty={false}
        onSubmit={onCompleteSubmit}
        optionsForm={{ mode: "onChange" }}
      >
        <Template
          buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button>, submit: <SubmitButton>Save changes</SubmitButton> }}
          props={
            {
              forms: [{
                content: IsPEP,
                title: translate('MODULES.STAKEHOLDER.PEP.FIRST_STEP.TITLE'),
                subTitle: translate('MODULES.STAKEHOLDER.PEP.FIRST_STEP.SUBTITLE')
              },
              {
                content: IsPEPFamilyMember,
                title: translate('MODULES.STAKEHOLDER.PEP.SECOND_STEP.TITLE'),
              },
              {
                content: IsPEPCloseAssociate,
                title: translate('MODULES.STAKEHOLDER.PEP.THIRD_STEP.TITLE')
              }]
            }}>
        </Template>
      </Form>}
    </ModalAssistant>
  )
}

export default ModalNotices;
import _ from "lodash";
import React, {Component} from 'react';
import {Field} from "formik";
import ClaraSelect from "../../../../components/Form/ClaraSelect/ClaraSelect";
import classes from "../../SteperForm.module.scss";
import TextInput from "../../Inputs/TextInput";
import Checkbox from '../../Inputs/Checkbox'
import FormikHelper from "../../../../utils/formikHelper";
import moment from "moment";
import ClaraUpload from "../../../../components/Form/ClaraUpload/ClaraUpload";
import {ClaraInputDate, ClaraInputYearsMonths, ClaraRadioWithButton, TextAreaInput,TextType} from "../../../../components";
import ClaraCountrySelector from "../../../../components/Form/ClaraCountrySelector/ClaraCountrySelector";
import ClaraCurrencySelector from "../../../../components/Form/ClaraCurrencySelector/ClaraCurrencySelector";
import CheckBox from "../../../../../components/inputs/Checkbox/Checkbox"
import AuthorisedSignatories from "../../../../../components/inputs/AuthorizedSignatories/AuthorizedSignatories";
import {groupCompanyRecipientNotice, stakeholderAuthorizedSignatory, stakeholderRecipientNotice} from './helper';

class ConsultantAgreementDoc extends Component {

    constructor(props) {
        super(props);
        this.state = {
            typeSelected: undefined,
            cosultantSelected: undefined,
            groupCompanySelected: undefined,
            authorizedSelected: undefined,
            authorizedSignatories: [],
            authorizedSignatoriesList: [],
            documentTypes: [],
            documentTypesList: [],
            dynamicParams: [],
            date: undefined,
        }
    };

    componentWillMount() {
        if (this.props.values) {
            const values = this.props.values.values ? this.props.values.values : this.props.values;
            this.setState({
                typeSelected: values.documentType,
                consultantSelected: values.consultant,
                groupCompanySelected: values.company,
                authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.company).map(as => ({
                    id: as.id,
                    label: as.label
                }))
            });
        }

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.values) {
            const values = nextProps.values.values ? nextProps.values.values : nextProps.values;
            this.setState({
                authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.company)
                    .map(as => ({
                        id: as.id,
                        label: as.label
                    }))
            });
        }
    }

    selectType = (documentType, extraParams, form) => {
        this.setState({
            typeSelected: documentType,
            dynamicParams: this.getDynamicParamsByType(documentType)
        }, () => {
            FormikHelper.setValueInTheCorrectPosition('terms', form, []);
            FormikHelper.setValueInTheCorrectPosition('documentType', form, documentType);
            this.state.dynamicParams.forEach((dynamicParam, i) => {
                FormikHelper.setValueInTheCorrectPosition(
                    `terms[${i}].key`,
                    form,
                    dynamicParam.key || 'NO_KEY'
                );
            })
        });
    };
    fieldHasError(form,field) {
        return FormikHelper.returnErrorsForValidation(field.name, form.errors);
    }
    getDynamicParamsByType(documentType) {
        const type = this.props.documentTypes.filter(agrType => agrType.code === documentType)[0] || {};
        return type.fields || [];
    }

    getGroupCompanies = () => {
        let authorizedSignatories = this.props.authorizedSignatories;
        let groupCompanies = this.props.groupCompanies;
        let companies = [];
        if (groupCompanies && authorizedSignatories) {
            companies = _.filter(groupCompanies, (groupCompany) => {
                let fined = _.filter(authorizedSignatories, (authorized) => {
                    if (groupCompany.authorizedSignatories) {
                        return groupCompany.authorizedSignatories.some(au => au.stakeholder.id === authorized.id);
                    }
                    return false;
                });
                if (fined) {
                    return true;
                }
                return false;
            });
        }
        return companies;
    };

    selectGroupCompany = (gcId, extraParams, form) => {
        const groupCompany = this.findGroupCompany(gcId);

        // this is for a weird behaviour when hit edit button after preview a document
        if (this.state.groupCompanySelected && groupCompany) {
            return null;
        }

        this.setState({
            groupCompanySelected: groupCompany
        });

        if (groupCompany) {

            if (!groupCompany.registeredAddress) {
                groupCompany.registeredAddress = {}
            }
            let authorizedSignatories = _.get(form, 'values.company.authorizedSignatories', []);
            
            const newFormValues = {
                ...form.values,
                company: {
                    id: gcId,
                    name: groupCompany.name || '',
                    country:typeof  _.get(groupCompany, 'country', null) ==='object'? _.get(groupCompany, 'country.code', null) : _.get(groupCompany, 'country', null),
                    jurisdiction: _.get(groupCompany, 'jurisdiction'),
                    registeredNumber: groupCompany.registeredNumber || '',
                    type: groupCompany.type || '',
                    registeredAddress: {
                        ...groupCompany.registeredAddress, country:typeof  _.get(groupCompany, 'registeredAddress.country', null) ==='object'? _.get(groupCompany, 'registeredAddress.country.code', null) : _.get(groupCompany, 'registeredAddress.country', null)
                    },
                    authorizedSignatories: authorizedSignatories,
					recipientNotice: groupCompanyRecipientNotice(form.values.company, groupCompany)
                }
            };

            form.setValues(newFormValues);

            this.setState({
                groupCompanySelected: groupCompany,
                // authorizedSignatoriesList: authorizedSignatories.map(as => ({
                // 	id: as.id,
                // 	label: as.label
                // }))
            })
        } else {
            form.setValues({
                ...form.values,
                company: {}
            });
        }
    };

	findGroupCompany(gcId) {
        const gc = _.find(this.props.groupCompaniesList,{id:gcId});
        return gc?{...gc}:null
    };

    selectConsultant = (consultant, extraParams, form, cb) => {
        const stakeholder = this.findStakeholder(consultant);

        this.setState({
            consultantSelected: stakeholder,
        });

        if (stakeholder) {
            let authorizedSignatory = {};

            if (!stakeholder.address) {
                _.set(stakeholder,'address', {})
            }

            const _consultant = !_.isEmpty(form.values.consultant) ? form.values.consultant : stakeholder;

            if (stakeholder.isAnEntity) {
				authorizedSignatory = stakeholderAuthorizedSignatory(_consultant);
                // authorizedSignatory = {
                //     fullName: typeof _consultant.authorizedSignatory === 'object'
                //         ? _.get(_consultant, 'authorizedSignatory.fullName', '')
                //         : _.get(_consultant, 'authorizedSignatory', ''),
                //     email: _consultant.email || ''
                // }
            }

            form.setValues({
                ...form.values,
                consultant: {
                    id: consultant,
                    fullName: _consultant.fullName || '',
                    email: _consultant.email || '',
                    passportNumber: _consultant.passportNumber || '',
                    registeredNumber: _consultant.registeredNumber || '',
                    nationality: _.get(_consultant, 'nationality.code', null) || _.get(_consultant, 'nationality', null),
                    jurisdiction: _.get(_consultant, 'jurisdiction'),
                    otherJurisdiction: _.get(_consultant, 'otherJurisdiction', ''),
                    type: _consultant.type || '',
                    address: { ..._consultant.address, country:typeof  _.get(_consultant, 'address.country', null) ==='object'? _.get(_consultant, 'address.country.code', null) : _.get(_consultant, 'address.country', null) },
                    isAnEntity: _consultant.isAnEntity,
                    authorizedSignatory: authorizedSignatory,
					recipientNotice: stakeholderRecipientNotice(form.values.consultant, stakeholder)
                }
            });
        } else {
            form.setValues({
                ...form.values,
                consultant: {}
            });
        }
    };

    handleAuthorizedSignatoryUpdate = (value) => {

        const { authorizedSignatories, groupCompanySelected } = this.state;
        const groupCompany = this.findGroupCompany(groupCompanySelected.id);

        _.set(groupCompany,'authorizedSignatories',_.map(groupCompany.authorizedSignatories,auth=>({...auth})))
        groupCompany.authorizedSignatories.push({ stakeholder: value });
        authorizedSignatories.push(value);

    };

    findStakeholder(roleId) {
        return this.props.stakeholderList.find(stakeholder => stakeholder.id === roleId);
    };

    findStakeholderAuthorized(roleId) {
        return this.props.stakeholderList.filter(stakeholder => {
            return stakeholder.roles.some(role => role.stakeholder.id === roleId);
        })[0] || null;
    };

    filterAuthorizedByGroupCompany(groupCompany) {
        if (groupCompany) {
            let gc = this.findGroupCompany(groupCompany.id);
            return this.props.authorizedSignatories.filter(authorized => {
                return authorized.groupCompanyId === gc.id
            });
        }
        return [];
    }

    selectAuthorized = (value, form, field, replace) => {
        const stakeholder = this.props.authorizedSignatories.find(e => e.id === value);
        const email = stakeholder ? stakeholder.email : '';
        replace(0, { id: value, email })
    };

    renderConsultantAuthorizedSignatory(field) {
        const { tooltips = {} } = this.props;

        if (this.state.consultantSelected.isAnEntity) {
            return (
                <React.Fragment>
                    <div className={classes.ClaraInputSelect}>
                        <Field name='consultant.authorizedSignatory.fullName'>
                            {({ field, form }) => (
                                <TextInput
                                    required={true}
                                    type='text'
                                    label='Corporate signatory '
                                    field={field}
                                    form={form}
                                    placeholder={"Corporate signatory"}
                                    tooltip={tooltips["Authorised Signatory"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name='consultant.authorizedSignatory.email'>
                            {({ field, form }) => (
                                <TextInput
                                    required={true}
                                    type='text'
                                    label="Corporate signatory's email address "
                                    field={field}
                                    form={form}
                                    onChange={(e) => this.updateEmailValue(e, form)}
                                    placeholder={"Email address"}
                                    tooltip={tooltips['Authorised Signatory Email']}
                                />
                            )}
                        </Field>
                    </div>
                </React.Fragment>
            )
        }
    };

    updateEmailValue(e, form) {
        if (this.state.consultantSelected.isAnEntity) {
            form.setFieldValue('consultant.authorizedSignatory.email', e.target.value, false)
        }
    }

    getDateForPickerFromData(date) {
        if (!date) return undefined;
        return moment(date).toDate();
    }

    handleChange(e, field, form) {

        const date = e === null ? null : moment(e).format("YYYY-MM-DD");

        FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

        this.setState({
            [field.name]: e
        })
    }

    getAuthorizedSignatoriesList = (index, form) => {
        if (form.values && form.values.company) {
            return this.filterAuthorizedByGroupCompany(form.values.company).map(as => ({
                id: as.id,
                label: as.label
            }))
        } else {
            return [];
        }
    };

    getLabel = (key) => {
        switch (key) {
            case "fullName":
                if (this.state.consultantSelected.isAnEntity) {
                    return "Company name";
                } else {
                    return "Name";
                }
            case "country":
                if (this.state.consultantSelected.isAnEntity) {
                    return "Country of incorporation";
                } else {
                    return "Nationality";
                }
            case "address":
                if (this.state.consultantSelected.isAnEntity) {
                    return "Registered address";
                } else {
                    return "Residential address";
                }
            case "passportOrRegistered":
                if (this.state.consultantSelected.isAnEntity) {
                    return "Company number";
                } else {
                    return "Passport number";
                }
        }
    };

    getField = (key) => {
        switch (key) {
            case "passportOrRegistered":
                if (this.state.consultantSelected.isAnEntity) {
                    return "consultant.registeredNumber";
                } else {
                    return "consultant.passportNumber";
                }
        }
    };

    getTooltip = (key, fieldName) => {
        switch (key) {
            case "fullName":
                if (this.state.consultantSelected.isAnEntity) {
                    return "Consultant Company's Name";
                } else {
                    return "Consultant's Name";
                }
            case "passportOrRegistered":
                if (this.state.consultantSelected.isAnEntity) {
                    return "Company Number";
                } else {
                    return "Passport Number";
                }
            case "country":
                if (this.state.consultantSelected.isAnEntity) {
                    return "Country";
                } else {
                    return "Nationality";
                }
            case "addressLine":
                if (this.state.consultantSelected.isAnEntity) {
                    return "Registered Address - Address";
                } else {
                    return "Residential Address - Address";
                }
            case "addressCity":
                if (this.state.consultantSelected.isAnEntity) {
                    return "Registered Address - City";
                } else {
                    return "Residential Address - City";
                }
            case "addressState":
                if (this.state.consultantSelected.isAnEntity) {
                    return "Registered Address - State";
                } else {
                    return "Residential Address - State";
                }
            case "addressCountry":
                if (this.state.consultantSelected.isAnEntity) {
                    return "Registered Address - Country";
                } else {
                    return "Residential Address - Country";
                }
            case "addressPostCode":
                if (this.state.consultantSelected.isAnEntity) {
                    return "Registered Address - Post Code";
                } else {
                    return "Residential Address - Post Code";
                }
            case "recipientNotice":
                if (fieldName === 'company') {
                    return "Company's";
                } else {
                    return "Consultant's";
                }
        }
    };

    getPlaceholder = (key) => {
        switch (key) {
            case "passportOrRegistered":
                if (this.state.consultantSelected.isAnEntity) {
                    return "Company number";
                } else {
                    return "Passport number";
                }
        }
    };

    renderRecipientNotice(fieldName, isAnEntity) {
        const { tooltips = {} } = this.props;

        if (isAnEntity) {
            return (
                <React.Fragment>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.fullName`}>
                            {({ field, form }) => (
                                <TextInput
                                    required={true}
                                    type='text'
                                    label='Recipient for notices '
                                    field={field}
                                    form={form}
                                    placeholder={"Recipient's name"}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={`${classes.ContainerRow} ${classes.Select}`}>
                        <Field name={`${fieldName}.recipientNotice.address.country`}>
                            {({ field, form }) => (
                                <ClaraCountrySelector
                                    isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                    jurisdictionsEnabled={false}
                                    countryField={`${fieldName}.recipientNotice.address.country`}
                                    label={"Recipient's address "}
                                    setFieldValue={form.setFieldValue}
                                    initialValues={{ country: _.get(form.values, `${fieldName}.recipientNotice.address.country`, null) }}
                                    returnCountryAsString={_.isEmpty(_.get(form.values, `${fieldName}.recipientNotice.address.country.code`, null))}
                                    additionalStyle={'ClaraCountrySelectorWizard'}
                                    tooltips={{
                                        country: tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Country"]
                                    }}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.address.street`}>
                            {({ field, form }) => (
                                <TextAreaInput
                                    type='text'
                                    label={' '}
                                    field={field}
                                    form={form} placeholder={"Address"}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Address"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.address.city`}>
                            {({ field, form }) => (
                                <TextInput
                                    type='text'
                                    field={field}
                                    form={form}
                                    placeholder={"City"}
                                    label={' '}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - City"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.address.state`}>
                            {({ field, form }) => (
                                <TextInput
                                    type='text'
                                    field={field}
                                    form={form}
                                    placeholder={"State / Region"}
                                    label={' '}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - State"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.address.zipCode`}>
                            {({ field, form }) => (
                                <TextInput
                                    type='text'
                                    field={field} form={form}
                                    label={' '}
                                    placeholder={"Post code"}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Post Code"]}
                                />

                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.email`}>
                            {({ field, form }) => (
                                <TextInput
                                    required={true}
                                    type='text'
                                    label="Recipient's email address "
                                    field={field}
                                    form={form}
                                    placeholder={"Email address"}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Email Address"]}
                                />
                            )}
                        </Field>
                    </div>
                </React.Fragment>
            )
        }
    };

    // renderAgreementDurationFields(form) {
    //     const { tooltips = {} } = this.props;
    //     if (!_.get(form, 'values.terms.agreementDuration')) {
    //         return null
    //     }
	//
    //     return (
    //         <React.Fragment>
    //             {
    //                 form.values.terms.agreementDuration === 'otherDuration' ?
    //                     <div className={classes.ContainerRow}>
    //                         <Field name='terms.otherDuration'>
    //                             {({ field, form }) => (
    //                                 <ClaraInputYearsMonths
	//
    //                                     required={true}
    //                                     field={field}
    //                                     form={form}
    //                                     label={'Duration '}
    //                                     tooltips={tooltips}
	//
    //                                 />
    //                             )}
    //                         </Field>
	//
    //                     </div>
    //                     : null
    //             }
    //         </React.Fragment>
    //     );
	//
    // }

    getStakeholderAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add new corporate stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
                placeholder: "Stakeholder's name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: true,
                    // roles : [{
                    // 	role: "SHAREHOLDER",
                    // 	groupCompany: this.state.groupCompanySelected.id,
                    // }],
                },
                paramsReturn: 'isAnEntity'
            },
            {
                type: "stakeholder",
                label: "Add new individual stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
                placeholder: "Stakeholder's name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: false,
                    // roles : [{
                    // 	role:"SHAREHOLDER",
                    // 	groupCompany: this.state.groupCompanySelected.id,
                    // }]
                },
                paramsReturn: 'isAnEntity'
            },
        ]
    };

    getCompanyAdds = () => {
        return [{
            type: "groupCompany",
            label: "Add new company",
            textWarning: "This will create a new group company for your startup. You can add or amend the details of this group company on its profile page.",
            placeholder: "Company name",
            startupId: this.props.startup.id,
            params: null,
            paramsReturn: `
				name
				authorizedSignatories {
					id
					stakeholder {
						id
						email
						fullName
						roles {   id
        name
        groupCompany { id name}
        stakeholder { id }}
					}
				}
			`
        }]
    };

    getAuthorizedSignatoryAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add new individual stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add more details later from the stakeholder's profile page.",
                placeholder: "Enter Stakeholder's name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: false,
                    roles: [{
                        role: "DIRECTOR",
                        groupCompany: this.state.groupCompanySelected.id,
                    }]
                }
            },
        ]
    };

    handleStakeholderCreate = (value, name, additionalValue, form) => {

        let { stakeholders, stakeholderList } = this.props;
        let newSh = { id: value.id, fullName: name, ...additionalValue };
        stakeholderList.push(newSh);

        stakeholders.push({ id: value.id, label: name });
        // this.setState({ groupCompaniesList, groupCompanies });

    };

    handleGroupCompanyCreate = (value, name, additionalValue, form) => {

        let { groupCompanies, groupCompaniesList } = this.props;
        let newGc = { id: value.id, name, ...additionalValue };
        groupCompaniesList.push(newGc);

        groupCompanies.push({ id: value.id, label: name });
        // this.setState({ groupCompaniesList, groupCompanies });

    };

    handleAuthorizedSignatoryCreate = (value, name, additionalValue, form) => {

        let { authorizedSignatories } = this.props;
        const { groupCompanySelected } = this.state;

        authorizedSignatories.push({
            id: value.id,
            label: name,
            role: 'DIRECTOR',
            groupCompanyId: groupCompanySelected.id
        });

    };

    render() {
        const isRequired = message => value => (!!value ? undefined : message);
        const { tooltips = {}, formType } = this.props;

        return (
            <div>
                <div className={classes.ContainerRow}>
                    {formType == 'uploadToEdit' ? (<div>
                        <Field name={`file`}>
                            {({ field, form }) => (
                                <ClaraUpload
                                    required={true}
                                    name={`filepond`}
                                    label='Upload your document '
                                    field={field}
                                    form={form}
                                />
                            )}
                        </Field>
                    </div>) : null}
                    <Field name='agreementName'>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='text'
                                label='Document name '
                                field={field}
                                form={form}
                                placeholder={"Document name"}
                                tooltip={tooltips['Document Name']}
                            />
                        )}
                    </Field>
                </div>

                <hr className={classes.Separator} />
                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                    <Field name='consultant.id'>
                        {({ field, form }) => (
                            <ClaraSelect
                                required={true}
                                field={field}
                                form={form}
                                label={"Consultant "}
                                lists={this.props.stakeholders}
                                adds={this.getStakeholderAdds()}
                                mode={"classic"}
                                callbacks={{
                                    updateValues: this.selectConsultant,
                                    createValues: this.handleStakeholderCreate
                                }}
                                placeholder={"Select from the list"}
                                tooltip={tooltips["Select Consultant"]}
                            />

                        )}
                    </Field>
                </div>
                {
                    this.state.consultantSelected ?
                        <React.Fragment>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                {
                                    this.state.consultantSelected.isAnEntity ?
                                        <>
                                            <h1>Consultant Company Details</h1>
                                            <p>Please verify or update the consultant's company's information below</p>
                                        </>
                                        :
                                        <>
                                            <h1>Consultant Details</h1>
                                            <p>Please verify or update the consultant’s information below</p>
                                        </>
                                }
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='consultant.fullName'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label={this.getLabel("fullName") + ' '}
                                            field={field}
                                            form={form}
                                            placeholder={'Name'}
                                            required={true}
                                            tooltip={tooltips[this.getTooltip('fullName')]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={`${classes.ContainerRow} ${classes.Select}`}>
                                <Field name='consultant.nationality'>
                                    {({ field, form }) => {
                                        return (
                                            <ClaraCountrySelector
                                                isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                                jurisdictionsEnabled={this.state.consultantSelected.isAnEntity}
                                                countryField={'consultant.nationality'}
                                                label={this.getLabel('country') + ''}
                                                jurisdictionField={'consultant.jurisdiction'}
                                                jurisdictionLabel={'Jurisdiction '}
                                                returnCountryAsString={true}
                                                otherJurisdictionField={'consultant.otherJurisdiction'}
                                                setFieldValue={form.setFieldValue}
                                                initialValues={{ jurisdiction: _.get(form.values, 'consultant.jurisdiction') }}
                                                additionalStyle={'ClaraCountrySelectorWizard'}
                                                tooltips={{
                                                    country: tooltips[this.getTooltip('country')],
                                                    jurisdiction: tooltips['Jurisdiction'],
                                                    other: tooltips['Other Jurisdiction']
                                                }}
                                            />
                                        )
                                    }
                                    }
                                </Field>
                            </div>
                            {
                                this.state.consultantSelected.isAnEntity ?
                                    <React.Fragment>
                                        <div className={classes.ContainerRow}>
                                            <Field name='consultant.type'>
                                                {({ field, form }) => (
                                                    <TextInput
                                                        required={true}
                                                        type='text'
                                                        label='Company type '
                                                        field={field}
                                                        form={form}
                                                        placeholder={'Company type'}
                                                        tooltip={tooltips['Company Type']}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                    </React.Fragment>
                                    :
                                    null
                            }
                            <div className={classes.ContainerRow}>
                                <Field name={this.getField("passportOrRegistered")}>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label={this.getLabel("passportOrRegistered") + ' '}
                                            field={field}
                                            form={form}
                                            placeholder={this.getPlaceholder("passportOrRegistered")}
                                            tooltip={tooltips[this.getTooltip("passportOrRegistered")]}
                                        />
                                    )}
                                </Field>
                            </div>
                            {
                                !this.state.consultantSelected.isAnEntity ?
                                    <React.Fragment>
                                        <div className={classes.ContainerRow}>
                                            <Field name='consultant.email'>
                                                {({ field, form }) => (
                                                    <TextInput
                                                        type='text'
                                                        label='Email address '
                                                        field={field}
                                                        form={form}
                                                        placeholder={'Email address'}
                                                        required={true}
                                                        tooltip={tooltips["Consultant's Email Address"]}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                    </React.Fragment>
                                    :
                                    null
                            }
                            <div className={`${classes.ContainerRow} ${classes.Select}`}>
                                <Field name='consultant.address.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={false}
                                            countryField={'consultant.address.country'}
                                            label={this.getLabel('address') + ''}
                                            setFieldValue={form.setFieldValue}
                                            returnCountryAsString={true}
                                            additionalStyle={'ClaraCountrySelectorWizard'}
                                            tooltips={{
                                                country: tooltips[this.getTooltip('addressCountry')]
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='consultant.address.street'>
                                    {({ field, form }) => (
                                        <TextAreaInput
                                            type='text'
                                            label={' '}
                                            field={field}
                                            form={form}
                                            placeholder={'Address'}
                                            tooltip={tooltips[this.getTooltip('addressLine')]}

                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='consultant.address.city'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={'City'}
                                            tooltip={tooltips[this.getTooltip('addressCity')]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='consultant.address.state'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={'State / Region'}
                                            tooltip={tooltips[this.getTooltip('addressState')]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='consultant.address.zipCode'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={"Post code"}
                                            tooltip={tooltips[this.getTooltip('addressPostCode')]}
                                        />
                                    )}
                                </Field>
                            </div>
                            {this.renderRecipientNotice('consultant', this.state.consultantSelected.isAnEntity)}
                            {this.renderConsultantAuthorizedSignatory('consultant')}
                        </React.Fragment>
                        :
                        null
                }
                <hr className={classes.Separator} />
                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                    <Field name='company.id'>
                        {({ field, form }) => (
                            <ClaraSelect
                                required={true}

                                field={field}
                                form={form}
                                label={"Company "}
                                lists={this.getGroupCompanies()}
                                mode={"classic"}
                                callbacks={{
                                    createValues: this.handleGroupCompanyCreate,
                                    updateValues: this.selectGroupCompany
                                }}
                                placeholder={"Select from the list"}
                                tooltip={tooltips['Select Company']}
                            />
                        )}
                    </Field>
                </div>
                {
                    this.state.groupCompanySelected ?
                        <React.Fragment>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Company Details</h1>
                                <p>Please verify or update the company's information below</p>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.name'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label='Company name '
                                            field={field}
                                            form={form}
                                            placeholder={"Company name"}
                                            tooltip={tooltips["Company's Name"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={`${classes.ContainerRow} ${classes.Select}`}>
                                <Field name='company.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={true}
                                            countryField={'company.country'}
                                            label={'Country of incorporation '}
                                            jurisdictionField={'company.jurisdiction'}
                                            jurisdictionLabel={'Jurisdiction '}
                                            returnCountryAsString={true}
                                            otherJurisdictionField={'company.otherJurisdiction'}
                                            setFieldValue={form.setFieldValue}
                                            initialValues={{ jurisdiction:_.get(form.values, 'company.otherJurisdiction') || _.get(form.values, 'company.jurisdiction') }}

                                            additionalStyle={'ClaraCountrySelectorWizard'}
                                            tooltips={{
                                                country: tooltips["Company's Country"],
                                                jurisdiction: tooltips["Company's Jurisdiction"],
                                                other: tooltips['Other Jurisdiction']
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            {/*
                            <div className={classes.ContainerRow}>
                                <Field name='company.jurisdiction'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label='Jurisdiction: '
                                            field={field}
                                            form={form}
                                            placeholder={"Jurisdiction"}
                                            tooltip={tooltips["Company's Jurisdiction"]}
                                        />
                                    )}
                                </Field>
                            </div>
							*/}
                            <div className={classes.ContainerRow}>
                                <Field name='company.type'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            groupCompany={this.state.groupCompanySelected}
                                            label='Company type '
                                            field={field}
                                            form={form}
                                            placeholder={'Company type'}
                                            tooltip={tooltips["Company's Company Type"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.registeredNumber'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label='Company number '
                                            field={field}
                                            form={form}
                                            placeholder={'Company number'}
                                            tooltip={tooltips["Company's Company Number"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={`${classes.ContainerRow} ${classes.Select}`}>
                                <Field name='company.registeredAddress.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            jurisdictionsEnabled={false}
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            countryField={'company.registeredAddress.country'}
                                            label={'Registered address '}
                                            returnCountryAsString={true}
                                            setFieldValue={form.setFieldValue}
                                            additionalStyle={'ClaraCountrySelectorWizard'}
                                            tooltips={{
                                                country: tooltips["Company's Registered Address - Country"]
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.registeredAddress.street'>
                                    {({ field, form }) => (
                                        <TextAreaInput
                                            type='text'
                                            label={' '}
                                            field={field}
                                            form={form}
                                            placeholder={"Address"}
                                            tooltip={tooltips["Company's Registered Address - Address"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.registeredAddress.city'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={'City'}
                                            tooltip={tooltips["Company's Registered Address - City"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.registeredAddress.state'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={'State / Region'}
                                            tooltip={tooltips["Company's Registered Address - State"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.registeredAddress.zipCode'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={"Post code"}
                                            tooltip={tooltips["Company's Registered Address - Post Code"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            {this.renderRecipientNotice('company', true)}
                            <hr className={classes.Separator}></hr>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Corporate Signatories</h1>
                                <p>Please add all the signatories required to sign this document on behalf of the company.</p>
                            </div>
                            <Field name='company.authorizedSignatories_error'>
                                {({ field, form }) => (
                                    <React.Fragment>
                                        <span hasError={_.get(form.errors, "company.authorizedSignatories") ? "true" : "false"}>
                                            <AuthorisedSignatories
                                                list={this.props.stakeholderList}
                                                fieldArrayName={'company.authorizedSignatories'}
                                                idGroupCompany={this.state.groupCompanySelected.id}
                                                handleAddListStakeholder={this.handleAuthorizedSignatoryUpdate}
                                                errorMessage={_.get(form.errors, "company.authorizedSignatories")}
                                            />
                                        </span>
                                    </React.Fragment>
                                )}
                            </Field>
                        </React.Fragment>
                        :
                        null
                }

                {
                    this.state.consultantSelected && this.state.groupCompanySelected ?
                        <React.Fragment>
                            <hr className={classes.Separator}></hr>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Fees</h1>
                                <p>The fees the consultant will be paid as compensation for the services.</p>
                            </div>
                            <div className={classes.ContainerRowAgreement}>
                                <div className={classes.ContainerRow}>
                                    <Field name='terms.fees.currency'>
                                        {({ field, form }) => (
                                            <ClaraCurrencySelector
                                                required={true}
                                                currencyField={'terms.fees.currency'}
                                                setFieldValue={field.setFieldValue}
                                                type={'One'}
                                                label={"Select currency "}
                                                placeholder={"Select from the list"}
                                                tooltip={tooltips["Currency"]}
                                            />
                                        )}
                                    </Field>
                                </div>
                                </div>
                                <Field name='terms.fees.feesFrequency'>
                                {({ field, form }) => (
                                    <div>
                                        <div className={`${classes.labelGroup} ${classes.labelGroupWizard}`}>
                                            <label>Frequency <span style={{color:'red'}}>*</span></label>
                                        </div>
                                        <CheckBox
                                            label={' '}
                                            values={field.value}
                                            errorMessage={this.fieldHasError(form,field)}
                                            onChange={(value) => { form.setFieldValue(field.name, value);form.validateField(field.name) }}
                                            isMultiple={false}
                                            options={[
                                                {
                                                    value: `perInvoice`,
                                                    label: 'Payment against invoices',
                                                },
                                                {
                                                    value: `perWeek`,
                                                    label: 'Weekly',
                                                    renderElement: () => (<>
                                                        <label style={{ 'marginLeft': '3.5rem', 'color': '#828C98' }}>Amount <span style={{ color: 'red' }}>*</span></label>
                                                        <Field name='terms.fees.amount'>
                                                            {({ field, form }) => (
                                                                <TextInput
                                                                    type='number'
                                                                    trim={true}
                                                                    field={field}
                                                                    className={{
                                                                        root: classes.InputContainer,
                                                                        input: classes.TextInput
                                                                    }}
                                                                    form={form}
                                                                    placeholder={'Amount'}
                                                                />
                                                            )}
                                                        </Field>
                                                    </>)
                                                },
                                                {
                                                    value: `perMonth`,
                                                    label: 'Monthly',
                                                    renderElement: () => (<>
                                                        <label style={{ 'marginLeft': '3.5rem', 'color': '#828C98' }}>Amount <span style={{ color: 'red' }}>*</span></label>
                                                        <Field name='terms.fees.amount'>
                                                            {({ field, form }) => (
                                                                <TextInput
                                                                    type='number'
                                                                    trim={true}
                                                                    field={field}
                                                                    className={{
                                                                        root: classes.InputContainer,
                                                                        input: classes.TextInput
                                                                    }}
                                                                    form={form}
                                                                    placeholder={'Amount'}
                                                                />
                                                            )}
                                                        </Field>
                                                    </>)
                                                }
                                            ]}
                                            className={{ root: classes.groupCheckBoxWizard, checkbox: classes.checkBoxWizard }}
                                        />
                                    </div>)}
                            </Field>
                            <hr className={classes.Separator}></hr>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Place of Arbitration</h1>
                                <p>This contract’s dispute resolution mechanism uses arbitration under expedited ICC rules. Please select the place where you would like the arbitration to take place (please enter the name of the city and country and, if applicable, state or freezone), e.g. "London, United Kingdom" or "New York City, New York, United States of America” or "Dubai International Financial Centre, Dubai, United Arab Emirates”. Please note that the place of arbitration that you select can have a material impact on the proceedings and enforcement of the arbitration and arbitral award. Please seek legal advice.</p>
                                </div>
                                <div className={classes.ContainerRow}>

<Field name={`terms.placeOfArbitration`}>
    {({ field, form }) => (
        <TextInput
            required={true}
            type='text'
            label='Place of arbitration '
            field={field}
            form={form}
            placeholder={"Place of arbitration"}
        />
    )}
</Field>
</div>                      
                        </React.Fragment>
                        : null
                }

                {formType === 'uploadToEdit' ? <div>
                    <Field name='confirmSameData'>
                        {({ field, form }) => (
                            <Checkbox
                                className={classes.sameDataCheckbox}
                                field={field}
                                validate={isRequired('This field is required')}
                                form={form}
                                required={true}
                                label='All document details are verified and correct.'
                            />
                        )}
                    </Field>
                </div> : null}

            </div>

        );
    }

}


export default ConsultantAgreementDoc;

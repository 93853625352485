import React from "react";
import {Redirect} from "react-router-dom";

import moduleMatter from "./modules/matter";
import moduleIncorporation from "./modules/incorporation";
import moduleStartups from "./modules/startups";
import moduleAdmin from "./modules/admin";
import moduleUser from "./modules/user";
import moduleCheckout from "../modules/checkout/views";
import moduleMap from "../modules/map/views";
import moduleGlobal from "./global";
import moduleSession from "./modules/session";
import moduleTest from "../modules/test/views";
import moduleFormation from "../modules/formation/views";

const redirectRoute = [
  {
    
    path: "/",
    exact: true,
    component: () => <Redirect to="/session/signin" />
  },
  {
    path: "/login",
    exact: true,
    component: () => <Redirect to="/session/signin" />
  }
];


const routes = [
  ...moduleTest,
  ...moduleMatter,
  ...moduleIncorporation,
  ...moduleStartups,
  ...moduleAdmin,
  ...moduleUser,
  ...moduleCheckout,
  ...moduleSession,
  ...moduleMap,
  ...moduleFormation,
  //moduleGlobal should always go at the end of the array because global contains the 404 page
  ...moduleGlobal
];

export default routes;

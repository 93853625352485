import { useMemo } from 'react';
import FormDelawareAgree from "src/modules/startup/onBoarding/CreateCompanyModal/wizard/submachines/AddFormationCompanyModal/wizard/submachines/AddFormationCompanyInDelawareModal/wizard/steps/FormDelawareAgree";
import FormInDelawareInfo from "src/modules/startup/onBoarding/CreateCompanyModal/wizard/submachines/AddFormationCompanyModal/wizard/submachines/AddFormationCompanyInDelawareModal/wizard/steps/FormInDelawareInfo";
import AddDelawareCompany from "src/modules/startup/onBoarding/CreateCompanyModal/wizard/submachines/AddFormationCompanyModal/wizard/submachines/AddFormationCompanyInDelawareModal/wizard/steps/AddDelawareCompany";
import EngageConciergeDelaware from "src/modules/startup/onBoarding/CreateCompanyModal/wizard/submachines/AddFormationCompanyModal/wizard/submachines/AddFormationCompanyInDelawareModal/wizard/steps/EngageConciergeDelaware";
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';

const useSteps = () => {

  return useMemo(() => ({
    FormDelawareAgree: {
      render: FormDelawareAgree,
    },
    FormInDelawareInfo: {
      render: FormInDelawareInfo,
    },
    AddDelawareCompany: {
      render: AddDelawareCompany,
    },
    EngageConciergeDelaware: {
      render: EngageConciergeDelaware,
    },
    SaveStep: {
      render: TemplateWizardSkeletonMachine,
    },
    SendMailNow: {
      render: TemplateWizardSkeletonMachine,
    },
  }), []);
}

export default useSteps;

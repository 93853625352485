import React from 'react';
import {ClaraSelect, TooltipInput} from '../../../index';
import {Field} from 'formik';
import {range} from '../../../../utils/servicePrint';
import FormikHelper from "../../../../utils/formikHelper";
import classesInputDate from './ClaraInputDate.module.scss';
import ErrorInput from "../../../../../modules/generic/components/Inputs/ErrorInput";
import _ from "lodash"


const ClaraInputDate = ({tooltip,isModal, field, form, label ,errorName,required,tooltips,stop,start,className={}}) => {

    const  nameField = field.name;
    const getError = ()=> {
        return FormikHelper.returnErrorsForValidation(errorName?errorName:nameField, form.errors);

    }
    const updateValues =()=>{
        if (getError()){
            form.validateForm();
        }
        
    }
    return (            <TooltipInput label={_.get(tooltip,'text')} link={_.get(tooltip,'link')} >
        <div className={`${className} ${classesInputDate.containerInputDate} `}>

        <label>{label}{required ?
                <span style={{color: 'red'}}>*</span> : null}</label>

            <div className={classesInputDate.inputNumber}>
            <Field name={`${nameField}.year`}>
                {({ field, form }) => (
                    <ClaraSelect
                        field={field}
                        form={form}
                        placeholder={"Years"}
                        modalClassName={isModal?classesInputDate.limitSize:null}
                        lists={range(start.year,  stop.year,1)}
                        mode={"classic"}
                        tooltip={_.get(tooltips,'years')}
                        hasError ={getError()}
                        
                    //      disabled={this.state.claraSelect.disabled}
                       callbacks={{
                        updateValues: updateValues
                       }}
                    //  tooltip={this.state.claraSelect.tooltip}
                    />
                )}
            </Field>
            </div>
            <div className={classesInputDate.inputCode}>
            <Field name={`${nameField}.month`}>
                {({ field, form }) => (
                    <ClaraSelect
                        field={field}
                        form={form}
                        placeholder={"Months"}
                        lists={range(start.month, stop.month, 1)}
                        mode={"classic"}
                        modalClassName={isModal?classesInputDate.limitSize:null}
                        tooltip={_.get(tooltips,'months')}
                        hasError ={getError()}
                        callbacks={{
                            updateValues: updateValues
                           }}
                        
                    //      disabled={this.state.claraSelect.disabled}
                    //    callbacks={this.state.claraSelect.callbacks}
                    //  tooltip={this.state.claraSelect.tooltip}
                    />
                )}
            </Field>
            </div>
            {getError() && <ErrorInput message={getError()} classNameError={classesInputDate.ErrorMessage} showErrorIcon={false}  />}
        </div></TooltipInput>
    )
}


export default ClaraInputDate;
import React, { useState, useEffect, FC } from 'react';
import classes from './classes.module.scss';
import classNames from "classnames";
import TextLoading from "../../../../../components/generic/Loading/TextLoading/TextLoading";

export type VariantButton = "primary" | "secondary" | "card" | "transparent" | "dashed" | "success" | "flat" | "submitDelete" | "cancelSubmit" | "upgrade" | "pay" | "cardWarning" | "white" | "primaryCard" | "none" | "paySecondary" | "upgradeOutlined" | "error";
export type ButtonSize = "small" | "big" | "medium" | "expanded";
export interface ButtonProps {
  /**  Status loading button */
  loading?: boolean,
  /**  Status disabled button */
  disabled?: boolean,
  /**  Status visible button */
  visible?: boolean,
  /**  default status active   */
  active?: boolean,
  /**  enabled active Button  */
  enabledActive?: boolean,
  /**  enabled Loading Button  */
  enabledLoading?: boolean,
  /**  Text to show in loading */
  labelLoading?: string,
  /**  Button Content  */
  children?: React.ReactNode,
  /** variant button */
  variant?: VariantButton,
  /** size button */
  size?: ButtonSize,
  /** Component OuterHTML Class when status is Loading */
  classNameLoading?: string,
  /** Component InnerHTML Class when status is Loading */
  classLoading?: string,
  /** Component  Class */
  className?: string,
  /** Type  Component button */
  type?: 'button' | 'submit',
  /** Component onClick */
  onClick: (event: React.MouseEvent<HTMLInputElement>) => void
}
/**
 * Button application
 */
const Button: FC<ButtonProps> = ({
  active,
  visible = true,
  variant = "secondary",
  enabledActive,
  loading,
  disabled,
  classNameLoading,
  onClick,
  labelLoading,
  enabledLoading,
  classLoading,
  className,
  type = 'button',
  size = "medium",
  ...props
}: ButtonProps) => {

  const [isLoading, setIsLoading] = useState(false);
  const [localActive, setActive] = useState(false);

  useEffect(() => {
    setIsLoading(loading)
  }, [loading]);

  useEffect(() => {
    setActive(active)
  }, [active]);

  const handleLoadingOnClick = async (e) => {
    setActive(!localActive);
    if (onClick) {
      if (enabledLoading) {
        setIsLoading(true);
        await onClick(e)
        setIsLoading(false);
        return e
      }
      onClick(e)
    }
  };

  return (
    <> {visible &&
      <button disabled={disabled || loading || isLoading} onClick={handleLoadingOnClick} type={type}
        data-active={enabledActive && localActive}
        className={classNames(`${classes.Button} ${classes[variant]} ${className} ${classes[size]}`, {
          [classes.EnabledActive]: enabledActive,
          [classNameLoading]: loading,
        })} {...props} >
        {isLoading || loading ? (
          <TextLoading loading={true} label={labelLoading} className={classLoading} />
        ) : props.children}
      </button>
    } </>
  )
};

export default Button;

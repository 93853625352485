import { useMemo } from "react";
import TemplateWizardSkeletonMachine from "src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine";
import AddEntityStep from "../steps/AddEntityStep";
import EligibleStakeholders from "../steps/EligibleStakeholders";
import NoEligibleStakeholders from "../steps/NoEligibleStakeholders";
import WhoIsSecondAuthorisedSignatoryFormStep from "../steps/WhoIsSecondAuthorisedSignatoryFormStep";
import AddAnotherAuthorizedSignatoryFormStep from "../steps/AddAnotherAuthorizedSignatoryFormStep";
import SelectAddAnotherStakeholderStep from "../steps/SelectAddAnotherStakeholderStep";
import HowDidTheyEnterFormStep from "../steps/HowDidTheyEnterFormStep";
import ThirdPartyWithNomineeFormStep from "../steps/ThirdPartyWithNomineeFormStep";
import ThirdPartyWithoutNomineeFormStep from "../steps/ThirdPartyWithoutNomineeFormStep";

const useSteps = () => {
  return useMemo(
    () => ({
      initialData: {
        render: TemplateWizardSkeletonMachine,
      },
      AddEntityStep: {
        render: AddEntityStep,
      },
      EligibleStakeholders: {
        render: EligibleStakeholders,
      },
      NoEligibleStakeholders: {
        render: NoEligibleStakeholders,
      },
      HowDidTheyEnterFormStep: {
        render: HowDidTheyEnterFormStep,
      },
      ThirdPartyWithNomineeFormStep: {
        render: ThirdPartyWithNomineeFormStep,
      },
      ThirdPartyWithoutNomineeFormStep: {
        render: ThirdPartyWithoutNomineeFormStep,
      },
      AddAnotherAuthorizedSignatoryFormStep: {
        render: AddAnotherAuthorizedSignatoryFormStep,
      },
      WhoIsSecondAuthorisedSignatoryFormStep: {
        render: WhoIsSecondAuthorisedSignatoryFormStep,
      },
      SelectAddAnotherStakeholderStep: {
        render: SelectAddAnotherStakeholderStep,
      },
      SaveThirdPartyWithNomineeFormStep: {
        render: TemplateWizardSkeletonMachine,
      },
      SaveThirdPartyWithoutNomineeFormStep: {
        render: TemplateWizardSkeletonMachine,
      },
      SaveHowDidTheyEnterFormStep: {
        render: TemplateWizardSkeletonMachine,
      },
      SaveStep: {
        render: TemplateWizardSkeletonMachine,
      },
    }),
    []
  );
};

export default useSteps;

import _ from 'lodash';
import React, { FC, useMemo } from 'react';
import Button from "../../../../../generic/components/Buttons/Button";
import useCustomMachine from '../../../../../generic/context/MachineContext/useMachine';
import useTranslate from '../../../../../generic/hooks/useTranslate';
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import ConvertibleForm from "../../../forms/ConvertibleForm";
const ConvertibleFormStep: FC<{}> = ({ }) => {
    const { stepData, next, send, context } = useCustomMachine()
    const { translate } = useTranslate()
    const handleCompleteSubmit = (values) => {
        next(values)
    }
    const handleCancel = () => {
        send("CANCEL")
    }
    const handlePrev = (): void => {
        send("PREVIOUS")
    }
    const companyName = useMemo(() => {
        return _.get(context, `groupCompanies[${_.get(context, 'iterations', 0)}].name`, '')
    }, [JSON.stringify(context)])
    const currency = useMemo(() => {
        return _.get(context, `groupCompanies[${_.get(context, 'iterations', 0)}].transactionCurrency`, '') ? _.get(context, `groupCompanies[${_.get(context, 'numbersOfIteration', 0)}].transactionCurrency`, '') : ''
    }, [JSON.stringify(context)])
    return (<>
        <ConvertibleForm
            initialValues={stepData}
            currency={currency}
            propsTemplate={{
                title: translate('MODULES.EQUITY.STEPS_CONVERTIBLE.CONVERTIBLE_FORM_STEP.TITLE'),
                subTitle: translate('MODULES.EQUITY.STEPS_CONVERTIBLE.CONVERTIBLE_FORM_STEP.SUBTITLE', { type: translate(`MODULES.EQUITY.TYPES_PLURAL.CONVERTIBLE`) })
            }}
            companyName={companyName}
            onCompleteSubmit={handleCompleteSubmit}
            Template={TemplateWizard}
            buttons={{
                cancel: <Button onClick={handleCancel}>Cancel</Button>,
                // previous: <Button onClick={handlePrev}>Previous</Button>
            }}
        />
    </>)
}
export default ConvertibleFormStep
import { Stack } from "@mui/material";
import map from "lodash/map";
import React, { useEffect } from 'react';
import Scrollbar from "src/components/generic/Scrollbar/Scrollbar";
import CalendarInputDate from "src/components/inputs/Calendars/CalendarInputDate";
import Checkbox from 'src/components/inputs/Checkbox/Checkbox';
import CountrySelector from 'src/components/inputs/Select/CountrySelector/CountrySelector';
import SelectInput from 'src/components/inputs/Select/SelectInput/SelectInput';
import TextInput from 'src/components/inputs/Text/TextInput';
import Avatar from "src/modules/generic/components/Avatars/AvatarLabel";
import Icon from "src/modules/generic/components/Icon";
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import TypographyLocal from "src/modules/generic/components/Texts/Typography";
import TemplateLabel from 'src/modules/generic/templates/Label';
import cls from './forms/classes.module.scss';
import { useFormContext } from "react-hook-form";
import useTranslate from 'src/modules/generic/hooks/useTranslate'

export const Party = ({ listParty, isDisabled, defaultValue }) => {
  return <TemplateLabel
    label={'Party'}
    className={cls.TemplateTwoLines}
    isRequired
  >
    <ControllerInput
      render={SelectInput}
      placeholder={"Search or select from list"}
      list={listParty}
      name={"party"}
      disabled={isDisabled}
      defaultValue={defaultValue}
      clear={true}
      onChange={(value) => { }}
    />
  </TemplateLabel>
}

export const PassportNumber = ({ defaultValue }) => {
  return <TemplateLabel
    label={'Passport number'}
    className={cls.TemplateTwoLines}
    isRequired
  >
    <ControllerInput
      placeholder={"Type here"}
      render={TextInput}
      name='number'
      defaultValue={defaultValue}
    />
  </TemplateLabel>
}

export const Nationality = ({ defaultValue }) => {
  return <TemplateLabel
    label={'Nationality'}
    className={cls.TemplateTwoLines}
    isRequired
  >
    <ControllerInput
      placeholder={"Type here"}
      render={CountrySelector}
      name='nationality'
      defaultValue={defaultValue ? defaultValue : undefined}
    />
  </TemplateLabel>
}

export const ExpiryDate = ({ defaultValue }) => {
  return <TemplateLabel
    label={"Expiry date"}
    className={cls.TemplateTwoLines}
    isRequired
  >
    <ControllerInput
      render={CalendarInputDate}
      placeholder={"dd/mm/yyyy"}
      name="expirationDate"
      defaultValue={defaultValue}
      onChange={(e) => { }}
    />
  </TemplateLabel>
}

export const Date = ({ defaultValue }) => {
  return <TemplateLabel
    label={"Expiration date"}
    className={cls.TemplateTwoLines}
    isRequired
  >
    <ControllerInput
      render={CalendarInputDate}
      placeholder={"dd/mm/yyyy"}
      name="date"
      defaultValue={defaultValue}
      onChange={(e) => { }}
    />
  </TemplateLabel>
}

export const IpProvision = ({ defaultValue }) => {
  return <ControllerInput
    render={Checkbox}
    isMultiple={false}
    defaultValue={defaultValue}
    label={'This agreement has an IP provision'}
    name={'ipProvision'}
    onChange={(value) => { }}
  />
}

//TODO: refactor this component to use controller input and remove the use of useFormContext and simplify the code
export const Parties = ({ listParty, isDisabled, handleAddToParties, parties, handleRemoveParty }) => {
  const { translate } = useTranslate()
  const { setValue, trigger } = useFormContext()
  const handleDelete = (value) => {
    setValue('parties', parties.filter((p) => p.id !== value.id))
    trigger('parties')
    handleRemoveParty(value);
  }
  const handleAdd = (value) => {
    if (value) {
      setValue('parties', [...parties, listParty.find((p) => p.id === value)])
      trigger('parties')
      handleAddToParties(value)
    }
  }

  useEffect(() => {
    if (parties) setValue('parties', parties, { shouldValidate: true, shouldDirty: true })
  }, []);

  const selectedParties = () => {
    return (
      <div className={cls.partiesContent}>
        <Scrollbar>
          <div className={cls.tagsContent}>
            {map(parties, (partie, index) => {
              return (
                <Stack key={index} direction="row" spacing={1} alignItems="center">
                  <TypographyLocal
                    className={cls.tagText}
                  >
                    {translate(
                      "MODAL_CATEGORISE_DOCUMENT_DYNAMIC_PARTIES", { number: index + 1 }
                    )}
                  </TypographyLocal>
                  <Stack key={index} direction="row" spacing={2} className={cls.tag}>
                    <Avatar
                      name={partie.label}
                      showLabel={true}
                    />
                    <Icon icon="Delete-bin" size={"1.5385rem"} isClara={true} onClick={() => handleDelete(partie)} />
                  </Stack>
                </Stack>
              )
            })}
          </div>
        </Scrollbar>
      </div>
    )
  }

  return <>
    <TemplateLabel
      label={'Parties'}
      className={cls.TemplateTwoLines}
      isRequired
    >
      <SelectInput
        placeholder={"Search or select from list"}
        list={listParty}
        name={"listParties"}
        clear={true}
        onChange={handleAdd}
        disabled={isDisabled}
      />
    </TemplateLabel>
    {selectedParties()}
  </>
}

export const ExecutionDate = ({ defaultValue }) => {
  return <TemplateLabel
    label={"Execution date"}
    className={cls.TemplateTwoLines}
    isRequired
  >
    <ControllerInput
      render={CalendarInputDate}
      placeholder={"dd/mm/yyyy"}
      name="executedOnDate"
      onChangeGetDate={true}
      defaultValue={defaultValue}
      onChange={(e) => { }}
    />
  </TemplateLabel>
}

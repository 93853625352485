import useMutation from "src/hooks/custom/useMutation";

const UPDATE_MAIN_IDENTITY_DOCUMENT_STAKEHOLDER = `
mutation updateMainIdentityDocumentStakeholder(
    $startupId: ID!
    $stakeholderId: ID!
	$identityDocumentData: IdentityDocumentInput
  ) {
    updateMainIdentityDocumentStakeholder(
      startupId: $startupId
      stakeholderId: $stakeholderId
      identityDocumentData: $identityDocumentData
    ) {
      id
  }
}
`;

const useUpdateMainIdentityDocumentStakeholder = () => {
  const [mutation] = useMutation(UPDATE_MAIN_IDENTITY_DOCUMENT_STAKEHOLDER, {
    showSuccessNotification: true,
  });
  return mutation;
};

export default useUpdateMainIdentityDocumentStakeholder;

import useMutation from "src/hooks/custom/useMutation";
import useSession from "src/modules/session/hooks/useSession";

export const UPSERT_DATA_PROTECTION_DECLARATION = `
mutation reviewGeneralDetailsDone(
    $startupId: ID!
    $groupCompanyId: ID!
    $stakeholderId: ID!
	$renewalData: RenewalDataInput
    $stakeholderData: StakeholderInput!
  ) {
    upsertRenewalData(
      startupId: $startupId
      groupCompanyId: $groupCompanyId
      renewalData: $renewalData
    ) {
      id
  }
  updateStakeholder(
    startupId: $startupId
    stakeholderId: $stakeholderId
    stakeholderData: $stakeholderData
) {
    id
    fullName
}
}
`;
const useReviewPersonalDetails = () => {
    const { startupId } = useSession()
    const [mutation] = useMutation(
        UPSERT_DATA_PROTECTION_DECLARATION,
        { showSuccessNotification: true }
    );

    const save = async (groupCompanyId: string, stakeholderId: string, values: any) => {
        const data = {
            variables: {
                startupId,
                groupCompanyId,
                stakeholderId,
                renewalData: {
                    stakeholderTasksDetails: {
                        isStakeholderDetailsConfirmed: true,
                        stakeholderId
                    }
                },
                stakeholderData: {
                    stakeholder: {
                        ...values
                    }
                }
            }
        }
        const submitData = await mutation(data)

        return submitData
    }

    return save;
};

export default useReviewPersonalDetails;

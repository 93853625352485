import React, { useEffect, useState } from "react";
import { DueDiligence } from "../../../../../v1/containers";
import GenericHOCHook from "../../../../../components/generic/GenericHOCHook";
import event from "../../../../../v1/utils/event";
import { Constants } from "../../../../../v1/utils/constants";
import moment from "moment";
import useLoading from "../../../../../modules/generic/hooks/useLoading";
import DueDiligenceSkeleton from "../../../../../v1/containers/Dashboard/DueDiligence/DueDiligenceSkeleton";

const MapView = (props) => {

  const [reload, setReload] = useState(0);
  const {isLoading, addLoading, removeLoading} = useLoading();
  
  useEffect(() => {
    event.on(Constants.EVENTS.HEALTH_CHECK_REFRESH, (params) => {
      setReload(moment().unix());
    });
    return () => {
      event.remove(Constants.EVENTS.HEALTH_CHECK_REFRESH, () => {});
    };
  }, []);

  return (
    <React.Fragment>
      <DueDiligenceSkeleton
        isLoading={isLoading}
      />
      <GenericHOCHook withCategories={{ kind: "STARTUP" }}>
        <DueDiligence
          reload={reload}
          addLoading={addLoading}
          removeLoading={removeLoading}
        />
      </GenericHOCHook>
    </React.Fragment>
  );
};

export default MapView;

import _ from "lodash";
import {Field} from "formik";
import React from "react";

const HiddenField = ({className, fieldName, hiddenCondition, conditionValue, children}) => {
    return (
        <Field name={fieldName}>
            {({field, form}) => {
                // console.log(form);
                // console.log(_.get(form, hiddenCondition));
                let evaluatedCondition;
                if (conditionValue) {
                    evaluatedCondition = _.get(form, hiddenCondition) === conditionValue;
                } else {
                    evaluatedCondition = _.get(form, hiddenCondition)
                }
                return (
                    evaluatedCondition ?
                        (
                            <div className={className}>
                                {React.cloneElement(children, {field, form, changeParams: field.name, ...children.props})}
                            </div>
                        )
                        : null
                )}}
        </Field>
    )
}

export default HiddenField;

import _ from "lodash";
import { useMemo } from "react";
import { assign } from 'xstate';

const useActions = () => {
  return useMemo(() => ({
    setContextCompleteQuestionStep: assign((context, event) => {
      let currentValues = _.get(event, 'payload.values');
      return {
        ...context,
        newResponse: currentValues,
      };
    }),
  }), []);
}

export default useActions;
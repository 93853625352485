const getAddAgreementInfo = `{
  getAddAgreementInfo(startupId: "{{startupId}}") {
    agreements {
      id
      label
      params {
        key
        fields {
          key
        }
      }
    }
    groupCompanies {
      id
      name
      avatar
    }
    roles {
      id
      groupCompany{
        id
      }
      stakeholder{
        id
      }
      titleForAgreement
      }
  }
}
`;
const addAgreement = `mutation upsertDocument($startupId: String, $agreementId: String, $draftDocumentId: String, $agreement: AgreementInputType) {
  upsertDocument(startupId: $startupId, agreementId: $agreementId, draftDocumentId: $draftDocumentId, agreement: $agreement){
    signedAs
    id
    status
    groupId
    date
    name
    verified
    file {
      id
      name
      type
      size
    }
    terms {
      key
      value
    }
  }
}`;

const agreementQuery = `{
  getDocument(startupId:"{{startupId}}",agreementId:"{{agreementId}}"){
    id
    freemiumValid
    status
    isReplaced
    groupCompanies  { 
      groupCompany {id name avatar}
      signedBy { id fullName avatar }
    }
    verified
    date
    name
    file {
      id
      name
      type
      size
    }
    terms {
      key
      value
    }
    type {
      code
      label
      allowedActions
    }
    stakeholders {
        fullName
        avatar
        id
    }
    showInMaps
    subCategories
    termNotes {title description}
    executedOnDate
    expirationDate
    kind
    parties
    parentDocument {
    	id
    	type { code }
    }
    relatedDocuments {
    	id
		file {id name size}
    	type {code}
	}
  }
}`;
const deleteDocumentQuery = `
mutation deleteDocument($startupId: String!,$agreementId: String!) {
  deleteDocument(startupId: $startupId,agreementId: $agreementId) {
    id
  }
}`;
const deleteDocumentZipQuery = `
mutation deleteZipDocument($startupId: ID!) {
  deleteZipDocument(startupId: $startupId) {
    id
    }
}
`;
const deleteDocumentZipADGMQuery = `
mutation deleteAdgmZipDocument($startupId: ID!,$zipDocumentId: String!) {
  deleteAdgmZipDocument(startupId: $startupId,zipDocumentId: $zipDocumentId) {
  id
  }
}
`;
const deleteDraftDocumentQuery = `
mutation deleteDraftDocument($startupId: String!,$draftDocumentId: String!) {
  deleteDraftDocument(startupId: $startupId,draftDocumentId: $draftDocumentId) {
    id
  }
}`;

const previewAgreement = `
mutation getPreviewAgreementDocument($agreementData: PreviewAgreementInputType) {
  getPreviewAgreementDocument(agreementData: $agreementData) {
    file { id name size }
    generatedDocuments
  }
}`;

const generateAgreement =  `mutation generateAgreement(
  $startupId: String
  $agreementId: String
  $agreement: GenerateAgreementInputType
) {
  generateAgreement(
    startupId: $startupId
    agreementId: $agreementId
    agreement: $agreement
  ) {
    signedAs
    id
    status
    groupId
    date
    name
    relatedDocuments{
      id
    }
    file {
      id
      name
      type
      size
    }
    terms {
      key
      value
    }
  }
}`;


const requestSignatures = `
mutation requestSignatures($startupId: String!, $agreement: GenerateAgreementInputType!, $agreementId : String) {
  requestSignatures(startupId: $startupId, agreement: $agreement, agreementId: $agreementId) {
    id
  }
}`;

const resendRequestSignatures = `
mutation resendRequestSignatures($startupId: String!, $agreement: GenerateAgreementInputType!, $agreementId : String) {
  resendRequestSignatures(startupId: $startupId, agreement: $agreement, agreementId: $agreementId) {
    id
  }
}`;

const getAgreementTypes = `{
  getDocumentTypes {
    code
    label
    relatedDocuments
    allowedActions
    fields {
      key
      label
      placeholder
      tooltip
      required
      type
      options {
        id
        label
      }
    }
    
    contentConfig
  }
}`;

const agreementGetSignatures = `{
  getDocument( startupId: "{{startupId}}" agreementId:"{{agreementId}}")
  {
  id
  	isReplaced
    file {
      id
      name
      type
      size
    }
    verified
    type{
      code
    }
    name
	stakeholders {
      id
      email
      address {
        city
        country {name code alpha3}
        zipCode
        street
      }
      passportNumber
      nationality {name code}
    }
    
    groupCompanies{
    groupCompany{
      id
      country {name code alpha3}
      registeredNumber
      type
      registeredAddress{
        city
        country {name code alpha3}
        zipCode
        street
      }
      
      }
      signedBy{
        id 
        email
      }      
            
    }
    
    expirationDate
    status
    params
    parties
    fileHTML
	parentDocument {
    	id
    	type { code }
    }
    relatedDocuments {
    	id 
		file {id name size}
    	type {code}
	}
  }
}`;

const deleteEquity =`
mutation deleteEquity(
  $startupId:  String!
  $ownerId:  String!
  $ownerType: String!
  $equityId: String!
  $equityType: String!

 

) {
deleteEquity(
  startupId: $startupId
  ownerId: $ownerId
  ownerType: $ownerType
  equityId: $equityId
  equityType: $equityType
  
) 
}
`

export {
  getAddAgreementInfo,
  addAgreement,
  agreementQuery,
  deleteDocumentQuery,
  deleteDocumentZipQuery,
  generateAgreement,
  requestSignatures,
  resendRequestSignatures,
  getAgreementTypes,
  previewAgreement,
  agreementGetSignatures,
  deleteDraftDocumentQuery,
  deleteEquity
}

import { useMemo } from 'react';
import useSession from "src/modules/session/hooks/useSession";
import useCreateUltimateParent from "src/views/modules/startups/renewal/cayman/modals/ConfirmUltimateParentModal/wizard/graphql/useCreateUltimateParent";

const useServices = ({ initialValues }) => {
  const { groupCompanyId } = initialValues;
  const { startupId } = useSession();
  const mutation = useCreateUltimateParent();

  return useMemo(() => ({

    initialData: async (context, event) => {
      return initialValues;
    },

    SaveStep: async (context, event) => {
      const variables = {
        startupId,
        groupCompanyId: groupCompanyId,
        entityData: {
          name: context?.AddFormStep?.immediateParentName,
          taxJurisdiction: context?.AddFormStep?.taxJurisdiction,
          taxRegistrationNumber: context?.AddFormStep?.tin,
          mailingAddress: {
            street: context?.AddFormStep?.mailingAddress?.street,
            city: context?.AddFormStep?.mailingAddress?.city,
            state: context?.AddFormStep?.mailingAddress?.state,
            country: context?.AddFormStep?.mailingAddress?.country,
            zipCode: context?.AddFormStep?.mailingAddress?.zipCode,
            type: context?.AddFormStep?.mailingAddressType?.toUpperCase()
          }
        },
      };
      return await mutation({ variables });
    },

  }), [initialValues]);
}

export default useServices;

import { useMemo } from 'react';
import useSession from "src/modules/session/hooks/useSession";
import _ from "lodash";
import useUpdateGroupCompany from "src/hooks/services/modules/groupCompany/useUpdateGroupCompany";

const useServices = ({initialValues}) => {

  const { startupId, selectStartup } = useSession();
  const [upsertCompany] = useUpdateGroupCompany();

  return useMemo(() => ({

    initialData: async (context, event) => {
      return initialValues;
    },

    SaveStep: async (context, event) => {
      const activeJurisdiction =  _.get(context, "AddIncorporateCompany", {});
      let variables;
      if (activeJurisdiction?.jurisdiction === "OTHER") {
        variables = {
          startupId,
          groupCompany: {
            country: activeJurisdiction?.country,
            isHoldingCompany: activeJurisdiction?.isHoldingCompany,
            jurisdiction: activeJurisdiction?.otherJurisdiction,
            name: activeJurisdiction?.name,
            transactionCurrency: activeJurisdiction?.transactionCurrency,
          },
        };
      } else {
        variables = {
          startupId,
          groupCompany: activeJurisdiction,
        };
      }
      const result = await upsertCompany(variables);
      await selectStartup(startupId,null);
      return result;
    },

  }), []);
}

export default useServices;

import React from 'react';
import cls from './BackgroundHoops.module.scss'

const BackgroundHoops: React.FC = ({ children }) => {
    return (
        <div className={cls['container-onboarding-layout']} >
            <i className={`${cls['circle-icon-onboarding']}  position-absolute  ${cls['first-circle']}`} />
            <i className={`${cls['circle-icon-onboarding']}  position-absolute ${cls['second-circle']}`} />
            {children}
        </div>
    )
}

export default BackgroundHoops;

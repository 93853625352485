import _ from "lodash";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import AddEntity from "src/components/forms/modules/startups/Equity/Components/AddEntity";
import FormAddCompany from "src/components/forms/modules/startups/Equity/Components/FormAddCompany";
import FormAddStakeholders from "src/components/forms/modules/startups/Equity/Components/FormAddStakeholders";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import Line from "src/components/generic/Line/Line";
import CalendarInputDate from "src/components/inputs/Calendars/CalendarInputDate";
import FileUploaderAndSelector from "src/components/inputs/FileUploaderAndSelector/FileUploaderAndSelector";
import TextInput from "src/components/inputs/Text/TextInput";
import Text from "src/components/text/Text/Text";
import { editShare } from "src/forms/schemas/modules/startups/Equity/schema";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import FormProps from "src/modules/generic/forms/interfaces/FormProps";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import TemplateFormDefault from "src/modules/generic/templates/Modal";
import useSession from "src/modules/session/hooks/useSession";
import Form from "src/components/forms/Form/FormL";
import classes from "./styles.module.scss"
import SelectShareClass  from 'src/components/forms/modules/startups/Equity/Components/SelectShareClass';
import Currency from "src/components/inputs/Currency/Currency";

interface Props extends FormProps{
    dataGroupCompanies: any[];
    dataStakeholders: any[];
    onAdd:(value:any)=>any
  }
const AddShareForm:FC<Props> = ({
  initialValues,
  onCompleteSubmit,
  propsTemplate,
  dataStakeholders,
  dataGroupCompanies,
  onAdd,
  buttonSubmitProps,
  buttons,
  Template = TemplateFormDefault,
}) => {

  const [isAnEntity, setAnEntity] = useState(false);
  const { translate } = useTranslate();
  const refSelectShareholders = useRef();
  const refSelectCompany = useRef();

  const [messageAddCopmany, setMessageAddCompany] = useState({
    open: false,
    text: "",
  });

  const [currencyData, setCurrencyData] = useState();

  const [messageAddShareHolder, setMessageAddShareholder] = useState({
    open: false,
    text: "",
  });

  const { startupId } = useSession();

  const stakeholderList = useMemo(
    () =>
      _.map(dataStakeholders, (st) => ({
        value: st,
        label: st.fullName,
        type: "stakeholder",
      })),
    [JSON.stringify(dataStakeholders)]
  );

  const groupCompaniesList = useMemo(
    () =>
      _.map(dataGroupCompanies, (gc) => ({
        value: gc,
        label: gc.name,
        type: "groupCompany",
      })),
    [JSON.stringify(dataGroupCompanies)]
  );

  const handleCloseMessageAddCompany = () => {
    setMessageAddCompany({ open: false, text: "" });
  };

  const handleOpenFormCompany = () => {
    setMessageAddCompany({ open: true, text: "MESSAGE_ADD_COMPANY_COMPONENT" });
  };

  const handleOpenFormStakeholderShareholder = (value) => {
    setAnEntity(value);
    setMessageAddShareholder({
      open: true,
      text: "MESSAGE_ADD_STAKEHOLDER_COMPONENT",
    });
  };

  const handleOpenFormCompanyShareholders = () => {
    setMessageAddShareholder({
      open: true,
      text: "MESSAGE_ADD_COMPANY_COMPONENT",
    });
  };

  const handleCloseMessageAddShareholder = () => {
    setMessageAddShareholder({ open: false, text: "" });
  };

  const hadleAddShareHolders = (value, type) => {
    onAdd && onAdd(value)
  };

  const formsAddCompany = useMemo(() => {
    return [
      {
        type: "groupCompany",
        form: FormAddCompany,
        handleAddValueList: hadleAddShareHolders,
        propsForm: {
          startupId,
          handleCloseMessage: handleCloseMessageAddCompany,
        },
      },
    ];
  }, [startupId]);

  const menuPropsCompany = {
    addList: [
      {
        label: "Add New Company",
        type: "groupCompany",
        onClick: handleOpenFormCompany,
      },
    ],
    propsList: [{ type: "groupCompany", title: "Set Group Company" }],
  };

  const menuPropsShareholder = {
    addList: [
      {
        label: "Add New Individual Stakeholder", // label == title
        type: "stakeholder",
        onClick: () => handleOpenFormStakeholderShareholder(false),
      },
      {
        label: "Add New Corporate Stakeholder",
        type: "stakeholder",
        onClick: () => handleOpenFormStakeholderShareholder(true),
      },
      {
        label: "Add New Company",
        type: "groupCompany",
        onClick: handleOpenFormCompanyShareholders,
      },
    ],
    propsList: [
      { type: "stakeholder", title: "Stakeholders" },
      { type: "groupCompany", title: "Group Companies" },
    ],
  };

  const formsAddShareholders = useMemo(() => {
    return [
      {
        type: "stakeholder",
        form: FormAddStakeholders,
        handleAddValueList: hadleAddShareHolders,
        propsForm: {
          startupId,
          isAnEntity,
          handleCloseMessage: handleCloseMessageAddShareholder,
        },
      },
      {
        type: "groupCompany",
        form: FormAddCompany,
        handleAddValueList: hadleAddShareHolders,
        propsForm: {
          startupId,
          handleCloseMessage: handleCloseMessageAddShareholder,
        },
      },
    ];
  }, [startupId,isAnEntity]);

  const getCurrencyData =useCallback( (val) => {
    setCurrencyData(val.transactionCurrency ? val.transactionCurrency : "");
  },[setCurrencyData]);

  useEffect(()=>{
    setCurrencyData( _.get(initialValues, "groupCompany.transactionCurrency")
        ? _.get(initialValues.groupCompany, "transactionCurrency"):'')
  },[JSON.stringify(initialValues)])

  return (
    <Form
      defaultValues={initialValues}
      schema={editShare}
      onSubmit={onCompleteSubmit}
      optionsForm={{mode: "onChange"}}
      onlySendDirty={false}
    >
        <Template props={propsTemplate} buttons={{submit:<SubmitButton{...buttonSubmitProps}>Add</SubmitButton>,...buttons}}>

        <AddEntity
          callback={getCurrencyData}
          asterisk
          formsAdd={formsAddCompany}
          refSelect={refSelectCompany}
          list={groupCompaniesList}
          menuProps={menuPropsCompany}
          message={messageAddCopmany}
          name={"groupCompany"}
          label={"COMPANY_NAME_FIELD"}
        />
        <AddEntity
          asterisk
          formsAdd={formsAddShareholders}
          label={"FORM_EQUITY_SHARE_SHAREHOLDER_LABEL"}
          refSelect={refSelectShareholders}
          list={[...stakeholderList, ...groupCompaniesList]}
          menuProps={menuPropsShareholder}
          message={messageAddShareHolder}
          name={"owner"}
        />
        <Line />
        <TemplateLabel
          label={translate('NUMBER_SHARES_FIELD')}
          isRequired={true}
        >
          <ControllerInput
            as={TextInput}
            name="equity.amount"
            type={"number"}
            placeholder={translate('MODULES.CLARA.ENTER_NUMBER')}
            onChange={(e) => {}}
          />
        </TemplateLabel>
        <TemplateLabel
          label={translate('PRICE_SHARES_FIELD')}
        >
          <ControllerInput
            as={Currency}
            name="equity.price"
            currency={
              currencyData
            }
            type="share"
            onChange={(e) => {
            }}
          />
        </TemplateLabel>
        <Line />
        <SelectShareClass
          name={"equity.shareClass"}
          startupId={startupId}
          list={dataGroupCompanies}
          observerField={"groupCompany"}
        />
        <Line />
        <TemplateLabel
          label={translate('ISSUED_DATE_FIELD')}
        >
          <ControllerInput
            as={CalendarInputDate}
            placeholder={translate('MODULES.CLARA.ROLE_DATE_INPUT_HIT')}
            name="equity.issuedDate"
            onChange={(e) => {}}
          />
        </TemplateLabel>
        <Line />
        <div className={classes.fileUploader}>
          <label>
            <Text uuid={"ASSOCIATED_DOCUMENTS_FIELD"} />
          </label>
          <div>
            <ControllerInput
              as={FileUploaderAndSelector}
              name="equity.documents"
              multipleFiles={true}
              onChange={(e) => {}}
            />
          </div>
        </div>
      </Template>
    </Form>
  );
};

export default AddShareForm;

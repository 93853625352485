import React, { FC, useState } from 'react';
import ModalAssistant from 'src/modules/generic/components/Modal/ModalAssistant';
import useModal from 'src/modules/generic/hooks/useModal';
import { clearAssistatText as clearAssistantText } from 'src/modules/generic/store/action';
import EditImmediateParentWizard from './wizard';

interface Props {
  open: boolean
  initialValues: any
}

/* To Call this modal execute: openModal( EditImmediateParentModal, {},'MODAL_EDIT_IMMEDIATE_PARENT' ); */
const EditImmediateParentModal: FC<Props> = ({
  open: openParam = false,
  initialValues = {}
}) => {

  const [open, setOpen] = useState(openParam);
  const { closeModal: onClose } = useModal();

  const handleStopMachine = () => {
    onClose();
    setOpen(false);
    clearAssistantText();
  }

  const handleCancelMachine = () => {
    onClose();
    setOpen(false);
    clearAssistantText();
  }

  return (
    <ModalAssistant open={open} variant='assistance'>
      <EditImmediateParentWizard initialValues={initialValues} onFinish={handleStopMachine} onCancel={handleCancelMachine} />
    </ModalAssistant>
  );
};

export default EditImmediateParentModal;

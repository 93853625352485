import React from "react";
import PAGES from "../../../../constants/pages";
import ClaraLayout from "../../../../layouts/Clara/ClaraLayout";
import AssistanceGroupCompanyV4 from "../../../../modules/assistanceV4/components/AssistanceFrame2/assistanceGroupCompany2";
import CompanyPage from "./companyView/CompanyPage";
import Text from "./../../../../components/text/Text/Text";

const routes = [
  {
    path: PAGES.viewGroupCompany.path,
    exact: true,
    breadCrumbs: {
      reset: false,
      add: true,
      label: (
        <Text
          uuid={"BREADCRUMS_VIEW_GROUP_COMPANY"}
          params={{ fullName: "" }}
        />
      ),
    },
    component: <CompanyPage />,
    layout: ClaraLayout,
    propsLayout: {
      startupMenu: true,
      userMenu: true,
      fullMenu: true,
      showAssistance: true,
      cantBack: true,
      showBreadCrumbs: true,
      assistance: AssistanceGroupCompanyV4,
    },
  },
];

export default routes;

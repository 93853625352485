import { Stack } from '@mui/material';
import React, { useMemo } from 'react';
import Typography from 'src/modules/generic/components/Texts/Typography';
import cls from "./classes.module.scss";
import useFormat from "src/modules/generic/hooks/useFormat.jsx"
import { BankInformationApi, RateConversionApi } from 'src/modules/checkout/hooks/useGetBankInformation/interfaces';

export enum JURISDICTION_TYPE {
  ADGM = 'ADGM',
  CAYMAN = 'CAYMAN',
  DELAWARE = 'DELAWARE'
}

export interface CardTransferDetailsProps {
  bankInformation: BankInformationApi;
  referenceNumber: string;
  product: any;
  jurisdictionType: JURISDICTION_TYPE;
  rateConversion?: RateConversionApi;
}



function CardTransferDetails({ referenceNumber, bankInformation, product, jurisdictionType, rateConversion }: CardTransferDetailsProps) {

  const { moneyFormat } = useFormat();

  const fields = useMemo(() => ({
    CAYMAN: [
      {
        label: 'Account number',
        key: 'accountNumber'
      },
      {
        label: 'Currency of account',
        key: 'accountCurrency'
      },
      {
        label: 'Name of Account Holder',
        key: 'nameOfAccountHolder'
      },
      {
        label: 'SWIFT code',
        key: 'swift_bic'
      },
      {
        label: 'ABA No',
        key: 'ABA'
      }],
    ADGM: [
      {
        label: 'Account number',
        key: 'accountNumber'
      },
      {
        label: 'Currency',
        key: 'accountCurrency'
      },

      {
        label: 'Name of Account Holder',
        key: 'nameOfAccountHolder'
      },
      {
        label: 'IBAN',
        key: 'IBAN'
      },
      {
        label: 'SWIFT code',
        key: 'swift_bic'
      }],
    DELAWARE: [{
      label: 'Currency',
      key: 'accountCurrency'
    },
    {
      label: 'Account number',
      key: 'accountNumber'
    },
    {
      label: 'Account name',
      key: 'nameOfAccountHolder'
    },
    {
      label: 'IBAN',
      key: 'IBAN'
    },
    {
      label: 'SWIFT code',
      key: 'swift_bic'
    }]

  }[jurisdictionType]), [jurisdictionType]);



  return (
    <>
      <Stack direction={"column"} justifyContent={""} alignItems="" sx={{ width: "100%", paddingLeft: "1.8462rem" }} className={cls.containerCard}>
        <Stack direction={"column"} justifyContent="flex-start" alignItems={"flex-start"} textAlign="left" sx={{ width: "100% ", paddingBottom: '16px' }}>
          <Typography variant={"h3"} className={cls["text"]}>
            {bankInformation.accountCurrency} {moneyFormat(bankInformation.accountCurrency !== 'USD' ? rateConversion?.inclusiveOfVAT : product.value)}
          </Typography>
        </Stack>
        {fields?.map((field, index) => (
          <Stack
            direction={"row"}
            gap={1}
            key={field.key}
            sx={{ gridTemplateColumns: "1fr 1fr", display: 'grid' }}
            textAlign="left"
          >
            <Typography variant={"h5"} className={cls.labelText}>
              {field.label}
            </Typography>

            <Typography weight='bold' variant={"h5"} >
              {bankInformation[field.key]}
            </Typography>
          </Stack>))}

      </Stack >
    </>
  );
}
export default CardTransferDetails;
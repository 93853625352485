import Machine from "../../../generic/components/Machine/MachineClass";
import FirstStep from "./Steps/FirstStep";
import SecondStepA from "./Steps/SecondStepA";
import SecondStepB from "./Steps/SecondStepB";

const existsLinkedInURL = ({ data }) => data.is_there_linkedInURL.isThereLikedInURL
const notExistsLikedInURL = ({ data }) => !data.is_there_linkedInURL.isThereLikedInURL

const machineDefinition = {
  id: 'professional_history',
  initial: 'is_there_linkedInURL',
  states: {
    is_there_linkedInURL: {
      on: {
        NEXT: { target: 'set_exist_linkedInURL', actions: 'setContext' },
        CANCEL: 'finish_professional_history'
      }
    },
    set_exist_linkedInURL: {
      exit: '',
      on: {
        '': [
          { target: 'likedInURL_form', cond: 'existsLinkedInURL' },
          { target: 'cv_form', cond: 'notExistsLikedInURL' }
        ]
      }
    },
    likedInURL_form: {
      on: {
        NEXT: { target: 'finish_professional_history', actions: 'setContext' },
        PREVIOUS: 'is_there_linkedInURL',
        CANCEL: 'finish_professional_history',
      }
    },
    cv_form: {
      on: {
        NEXT: { target: 'finish_professional_history', actions: 'setContext' },
        PREVIOUS: 'is_there_linkedInURL',
        CANCEL: 'finish_professional_history',
      }
    },
    finish_professional_history: {
      type: 'final',
      entry: 'final',
    },
    onDone: {
      actions: 'stopMachine'
    }
  }
}

const steps = {
  is_there_linkedInURL: FirstStep,
  likedInURL_form: SecondStepA,
  cv_form: SecondStepB
}

const options = {
  guards: {
    existsLinkedInURL,
    notExistsLikedInURL
  }
}

class MachineProfessionalHistory extends Machine {
  constructor() {
    super(machineDefinition, options, steps);
  }

}
export default MachineProfessionalHistory
import Machine from "../../../generic/components/Machine/MachineClass";
import { StepCompanyInfo, StepCompanyMoreInfo } from './Steps';


const machineDefinition = {
    id: 'personalInfoCorporate',
    initial: 'company_info',
    states: {
        company_info: {
            on: {
                NEXT: { target: 'more_details', actions: 'setContext' },
                CANCEL: 'finish_company',
            },

        },
        more_details: {
            on: {
                PREVIOUS: { target: 'company_info'},
                NEXT: { target: 'finish_company', actions: 'setContext' },
                CANCEL: 'finish_company',
            },

        },
        finish_company: {
            type: 'final',
            entry: 'final',
        },
        onDone: {
            actions: 'stopMachine'
        }
    },
}
const steps = {
    company_info: StepCompanyInfo,
    more_details: StepCompanyMoreInfo
}

const options = {}



export default class MachineCompany extends Machine {
    constructor() {
        super(machineDefinition, options, steps);
    }
}

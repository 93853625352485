import useQuery from "src/hooks/custom/useQuery";
import useSession from "src/modules/session/hooks/useSession";

const GET_VESTING_TERMS=`
query getVestingTerms($startupId: ID!, $groupCompanyId: ID!) {
     getVestingTerms(startupId: $startupId, groupCompanyId: $groupCompanyId) {
        id
  name
  description
  vestingType
  allocationType
  standardTerm{
    vestingPeriod
    vestingFrequency
    isThereCliff
    cliffPeriod
  }
    }
}`;
const useGetVestingTerms = (groupCompanyId,config)=>{
    const {startupId} = useSession() 
    const {data,loading}=useQuery(GET_VESTING_TERMS,{startupId,groupCompanyId},config)
    return{data:data?.getVestingTerms,loading}
}
export default useGetVestingTerms

import React from 'react';
import cls from './classes.module.scss';
import Icon from '../../../generic/components/Icon';
import Typography from '../../../generic/components/Texts/Typography/index';
import classNames from 'classnames'

export interface CardOptionHtmlProps {
  title: string;
  icon: any;
}


const CardOptionHtml: React.FC<CardOptionHtmlProps> = ({ title, icon }) => {

  return (
    <div className={`d-flex container-card-clara `} >
      {icon && <Icon isClara icon={icon} size={"6rem"} color={"#2c74ff"} />}
      <div className={classNames(cls['container-card-option-onbording'], { 'box-only-text': !icon })}>
        <Typography variant='body1'>
          <span dangerouslySetInnerHTML={{__html: title}}></span>
        </Typography>
      </div>
    </div>
  )
}

export default CardOptionHtml;

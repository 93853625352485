import React, { useEffect, useMemo, useRef, useState} from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import _ from 'lodash';
import Form from 'src/components/forms/Form/Form';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import Button from 'src/modules/generic/components/Buttons/Button';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import Checkbox from "src/components/inputs/Checkbox/Checkbox";
import classes from "src/modules/startup/onBoarding/roles/DirectorV2Modal/wizard/styles/director.module.scss";
import { RELEVANT_ACTIVITIES } from '../../../interfaces';

const schema = Yup.object().shape({
  activities: Yup.array().required('Required').min(1, 'Please select at least one option'),
});

function ReviewRelevantActivitiesStep() {
  const { next, prev, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const [showBanner, setShowBanner] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const ref = useRef(null);

  const initialValues = context?.ReviewRelevantActivitiesStep ?? {};

  const handleCancel = () => {
    cancel();
  }

  const handlePrev = () => {
    prev();
  }

  const handleSubmit = async (values) => {
    next(values);
  }

  useEffect(() => {
    dispatch(addAssistantText(translate('MODULES.RENEWALS.ECONOMIC_SUBSTANCE.RELEVANT_ACTIVITIES.TOOLTIP_1'), 'alert'));
    dispatch(addAssistantText(translate('MODULES.RENEWALS.ECONOMIC_SUBSTANCE.RELEVANT_ACTIVITIES.TOOLTIP_2')));
    dispatch(addAssistantText(translate('MODULES.RENEWALS.ECONOMIC_SUBSTANCE.RELEVANT_ACTIVITIES.TOOLTIP_3')));
    dispatch(addAssistantText(translate('MODULES.RENEWALS.ECONOMIC_SUBSTANCE.RELEVANT_ACTIVITIES.TOOLTIP_4')));
    
    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch]);

  const options = useMemo(() => {
    return _.map(
      context?.getRelevantActivities?.relevantActivitiesData?.getRelevantActivities, 
      (ra) => ra?.code === RELEVANT_ACTIVITIES?.NONE_OF_ABOVE
        ? {
            value: ra?.code,
            label: ra?.description,
          }
        : {
            value: ra?.code,
            label: ra?.description,
            disabled: isDisabled,
          }
    );
  }, [context, isDisabled]);

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      ref={ref}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
          props={{
            title: translate('MODULES.RENEWALS.ECONOMIC_SUBSTANCE.RELEVANT_ACTIVITIES.TITLE'),
            skeleton: null,
            subTitle: translate('MODULES.RENEWALS.ECONOMIC_SUBSTANCE.GENERIC.SUBTITLE'),
            variant: 'simple'
          }}
          buttons={{
            previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
            cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
            submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>Next</SubmitButton>,
          }}
      >
        <TemplateLabel
            label={translate('MODULES.RENEWALS.ECONOMIC_SUBSTANCE.RELEVANT_ACTIVITIES.ACTIVITIES_LABEL')}
            isRequired
            variant="bannerLabel"
            bannerTitle={translate('MODULES.RENEWALS.ECONOMIC_SUBSTANCE.RELEVANT_ACTIVITIES.ACTIVITIES_BANNER')}
            showBanner={showBanner}
        >
          <ControllerInput
            render={Checkbox}
            name='activities'
            options={options}
            defaultValue={[RELEVANT_ACTIVITIES.ACTING_AS_HOLDING_COMPANY]}
            onChange={(val: string[]) => {
              if((val || []).length === 0) {
                setIsDisabled(false);
                return setShowBanner(false);
              }
              if((val || []).length === 1 && _.includes(val, RELEVANT_ACTIVITIES.ACTING_AS_HOLDING_COMPANY)){
                setIsDisabled(false);
                return setShowBanner(false);
              }
              if(_.includes(val, RELEVANT_ACTIVITIES.NONE_OF_ABOVE)){
                setIsDisabled(true);
                ref.current.setValue('activities', [RELEVANT_ACTIVITIES.NONE_OF_ABOVE]);
                return setShowBanner(false);
              }
              setIsDisabled(false);
              return setShowBanner(true);
            }}
            classCheckboxLine={classes.CompanyCheckboxButton}
          />
        </TemplateLabel>
      </TemplateWizard>
    </Form>
  );
}

export default ReviewRelevantActivitiesStep;
import { Checkbox } from "@material-ui/core";
import _ from 'lodash';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {  Modal, Table, Text } from '../../../../../../v1/components';
import { Config as QueryProfile } from "../../../../../../v1/components/Onboarding/OnboardingContent/config";
import { Constants } from '../../../../../../v1/utils';
import helper from "../../../../../../v1/utils/helper";
import notification from "../../../../../../v1/utils/notification";
import ServerConnect from '../../../../../../v1/utils/ServerConnect';
import SecondaryButton from "../../../../../generic/Buttons/SecondaryButton/SecondaryButton";
import ModalShareProfile from "../modalShareProfile/ModalShareProfile";
import PopoverShareProfile from '../popoverShareProfile/PopoverShareProfile';
import classes from "./ShareProfileTable.module.scss";
import useSession from "./../../../../../../modules/session/hooks/useSession"
import { GET_STARTUP_USERS_PROFILE } from "../../../../../../hooks/services/modules/startups/shareManagement/useShareProfilesLazy";
import { gql } from "@apollo/client";
import useMutation from '../../../../../../hooks/custom/useMutation'
import Button from "../../../../../../modules/generic/components/Buttons/Button";
import NameSharedWithTag from '../components/NameSharedWithTag';

const SET_ACCESS_TO_USER = `mutation setUserAccessToAStartup($startupId:String!, $sharingProfile: SharingProfileInput! ) {
    setUserAccessToAStartup(startupId: $startupId, sharingProfile: $sharingProfile) {
        _id
        userId
        role
        fullName
        avatar
        email
        lastLogin
        lastInvitationSent
    }
  }`;

const ShareProfileTable = ({ sharingData }) => {
  const [showModal, setShowModal] = useState(false);
  const [prevRole, setPrevRole] = useState(null);
  const [showChangeRoleModal, setShowChangeRoleModal] = useState(false);
  const [showDeactivateRoleModal, setShowDeactivateRoleModal] = useState(false);
  const [showReactivateRoleModal, setShowReactivateRoleModal] = useState(false);
  const [showDeactivateSelfRoleModal, setShowDeactivateSelfRoleModal] = useState(false);
  const [fullNameLabel, setFullNameLabel] = useState(null);
  const [emailLabel, setEmailLabel] = useState(null);
  const [idLabel, setIdLabel] = useState(null);
  const [roleLabel, setRoleLabel] = useState(null);
  const [changeRoleAccepted, setChangeRoleAccepted] = useState(false);
  const [userStartupRole, setUserStartupRole] = useState(null);
  const [tableData, setTableData] = useState(sharingData);
  const [initModalVals, setInitModalVals] = useState(null);
  const params = useParams();

  const handleOnCompletedMutation = ({ setUserAccessToAStartup }) => {
    const sharingProfile = setUserAccessToAStartup;
    let roleMessage = `ROL_USER_${sharingProfile.role}`;

    if (prevRole !== 'USER_INACTIVE') {
      notification.sendNotification(`An email has been sent to ${sharingProfile.fullName} informing them of the Role change to ${helper.getTranslateTextByKey(roleMessage)}`, "success", 4990);
      handleCloseChangeRoleModal();
    } else {
      notification.sendNotification(`An email has been sent to ${sharingProfile.fullName} to reactivate them with the assigned Role`, "success", 4990);
      handleCloseReactiveRoleModal();
    }
  }

  const [setUserAccess] = useMutation(SET_ACCESS_TO_USER, {
    onCompleted: handleOnCompletedMutation,
    showErrorNotification: false,
    showSuccessNotification: false
  }, {
    refetchQueries: [{
      query: gql`${GET_STARTUP_USERS_PROFILE}`,
      variables: { startupId: params.id }
    }]
  });

  const dispatch = useDispatch();
  const { user: profile, startup } = useSession();
  const profiles = useSelector(state => state.session.profiles);

  useEffect(() => {
    setTableData(sharingData);
  }, [JSON.stringify(sharingData)])

  useEffect(() => {
    if (!userStartupRole) {
      _.find(profiles, prof => {
        if (prof.startup.id === params.id) {
          setUserStartupRole(prof.role)
        }
      })
    }
  }, []);

  const handleResendOpenModal = (vals) => {
    vals.resend = true;
    setShowModal(true);
    setInitModalVals(vals);
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setInitModalVals(null);
  }

  const handleOpenChangeRoleModal = (name, email) => {
    setShowChangeRoleModal(true);
    setFullNameLabel(name);
    setEmailLabel(email);
  }

  const handleOpenReactiveRoleModal = (name, email, id, rol) => {
    setShowChangeRoleModal(true);
    setShowReactivateRoleModal(true);
    setFullNameLabel(name);
    setEmailLabel(email);
    setIdLabel(id);
    setPrevRole(rol);
  }

  const handleCloseReactiveRoleModal = () => {
    setShowChangeRoleModal(false);
    setShowReactivateRoleModal(false);
    setChangeRoleAccepted(false);
    setFullNameLabel(null);
    setRoleLabel(null);
    setIdLabel(null);
    setEmailLabel(null);
    setPrevRole(null);
  }

  const handleOpenDeactivateRoleModal = (name, email, id) => {
    setShowDeactivateRoleModal(true);
    setFullNameLabel(name);
    setEmailLabel(email);
    setIdLabel(id);
  }

  const handleOpenDeactivateSelfRoleModal = (name, email, id) => {
    setShowDeactivateRoleModal(true);
    setShowDeactivateSelfRoleModal(true);
    setFullNameLabel(name);
    setEmailLabel(email);
    setIdLabel(id);
  }

  const handleCloseChangeRoleModal = () => {
    setShowChangeRoleModal(false);
    setChangeRoleAccepted(false);
    setFullNameLabel(null);
    setRoleLabel(null);
    setIdLabel(null);
    setEmailLabel(null);
  }

  const handleCloseDeactivateSelfRoleModal = () => {
    setShowDeactivateRoleModal(false);
    setShowDeactivateSelfRoleModal(false);
    setChangeRoleAccepted(false);
    setFullNameLabel(null);
    setRoleLabel(null);
    setIdLabel(null);
    setEmailLabel(null);
  }

  const handleCloseDeactivateRoleModal = () => {
    setShowDeactivateRoleModal(false);
    setChangeRoleAccepted(false);
    setFullNameLabel(null);
    setRoleLabel(null);
    setEmailLabel(null);
  }

  const getRoleName = (rol) => {
    setRoleLabel(rol);
  }

  const updateTableData = (newData) => {
    setTableData(newData);
  }

  const handleClickRoleModal = (val) => {
    setChangeRoleAccepted(!val);
  }

  const submitChangeRole = async () => {
    const mutationId = params.id;

    let variables = {
      "startupId": mutationId,
      "sharingProfile": { email: emailLabel, fullName: fullNameLabel, role: roleLabel }
    }
    await setUserAccess(variables);
  }

  const submitDeactivateUser = () => {
    const mutationId = params.id
    const mutation = `mutation removeUserAccessToAStartup($startupId:String!, $sharingProfile: RemoveSharingProfileInput! ) {
            removeUserAccessToAStartup(startupId: $startupId, sharingProfile: $sharingProfile) 
              }`;

    let variables = {
      "startupId": mutationId,
      "sharingProfile": { email: emailLabel }
    }

    let updatedData = tableData;
    _.find(updatedData, prof => {
      if (prof.email === emailLabel) {
        prof.role = 'USER_INACTIVE'
        return prof
      }
    });

    ServerConnect.graphQlMutation(mutation, variables)
      .then(result => {
        if (showDeactivateSelfRoleModal) {
          handleCloseDeactivateSelfRoleModal()
          ServerConnect.graphQlQuery(QueryProfile.profile).then(result => {
            if (result && result.getMe) {
              dispatch({ type: 'SET_STARTUP_USER', startup: _.find(result.getMe.startups, (s) => { return s.id == startup.id }), user: result.getMe })
              dispatch({ type: 'GO_TO', page: Constants.PAGES.switchStartup })
            }
          });
        } else {
          setTableData(updatedData)
          notification.sendNotification(`An email has been sent to ${fullNameLabel} informing them that their access to ${startup.name} has been removed`, "success", 4990);
          handleCloseDeactivateRoleModal();
        }
      }).catch(err => {
        console.log(err);
        notification.sendNotification("You don’t have permissions to execute that action", "error", 4990);
      });
  }

  const checkEditSelf = (email, rowEmail) => {
    return email === rowEmail;
  }

  const fields = [
    {
      label: "Name",
      key: "name",
      order: false,
      "decorator": (props) => {
        return (
          <NameSharedWithTag
            fullName={props.row.fullName}
            avatar={props.row.avatar}
            lastLogin={props.row.lastLogin}
            role={props.row.role}
            email={props.row.email}
          />
        )
      },
      className: classes.Col2
    },
    {
      label: "Roles",
      key: "roles",
      order: false,
      "decorator": (props) => (
        <div className={classes.rolesColumn}>
          <div className={classes.rolesLabelContainer}>
            <PopoverShareProfile
              initVal={props.row.role}
              userStartupRole={userStartupRole}
              userEditSelf={checkEditSelf(profile.email, props.row.email)}
              singleOwner={_.filter(tableData, prof => { return prof.role === Constants.USER_ROLES.USER_OWNER && prof.lastLogin }).length < 2}
              getRoleName={getRoleName} handleOpenChangeRoleModal={() => { handleOpenChangeRoleModal(props.row.fullName, props.row.email) }}
              id={props.row.email}
              handleOpenReactiveRoleModal={() => { handleOpenReactiveRoleModal(props.row.fullName, props.row.email, _.get(props, 'row._id'), props.row.role) }}
              handleOpenDeactivateSelfRoleModal={() => { handleOpenDeactivateSelfRoleModal(props.row.fullName, props.row.email, _.get(props, 'row._id')) }}
              handleOpenDeactivateRoleModal={() => { handleOpenDeactivateRoleModal(props.row.fullName, props.row.email, _.get(props, 'row._id')) }}>
              <Text uuid={`ROL_USER_${props.row.role}`} />
            </PopoverShareProfile>
          </div>
        </div>
      ),
      className: classes.Col2
    },
    {
      label: "Share Date",
      key: "shareDate",
      order: false,
      "decorator": (props) => (
        <div className={classes.shareDateColumn}>
          <label>{!props.row.lastInvitationSent ? '-' : moment(props.row.lastInvitationSent).format("DD-MMM-YYYY").replace(/-/g, ' ')}</label>
        </div>
      ),
      className: classes.Col2
    },
    {
      label: "",
      key: "resendInvite",
      order: false,
      "decorator": (props) => (
        <div className={classes.resendInvite}>
          {!props.row.lastLogin ? <Button
            variant='card'
            children={
              Constants.TRANSLATES.RESEND_INVITE
            }
            onClick={!props.row.lastLogin ? () => { handleResendOpenModal({ email: props.row.email, role: props.row.role, fullName: props.row.fullName, _id: props.row._id }) } : null}
          ></Button> : null}
        </div>
      ),
      className: classes.Col2
    },
  ];

  return (
    <>
      <div className={classes.contentView}>
        <>
          <Table
            fields={fields}
            data={tableData}
            className={classes.Table}
          />
          {showModal ? <ModalShareProfile
            tableData={tableData}
            updateTableData={updateTableData}
            initVals={initModalVals ? initModalVals : null}
            userStartupRole={userStartupRole}
            showModal={showModal}
            handleCloseModal={() => { handleCloseModal() }} /> : null}

          {/* CHANGE ROLE MODAL */}
          {showChangeRoleModal && <Modal className={{ modal: classes.ModalAdd }} open={showChangeRoleModal} showHeader={false}  >
            <div className={classes.DeleteForm}>
              <h1>{showReactivateRoleModal ? 'Reactivate Role on ' + startup.name : 'Change Role on ' + fullNameLabel}</h1>
              <div className={classes.zoneCheckBox}>
                <div className={classes.CheckBox}>
                  <Checkbox style={{ color: "#2C74FF", left: "0px" }} onChange={(e) => handleClickRoleModal(changeRoleAccepted)} />
                  <label>{showReactivateRoleModal ? "Reactivate " + fullNameLabel + "'s Role as " + helper.getTranslateTextByKey(`ROL_USER_${roleLabel}`) : 'Change ' + fullNameLabel + ' Role to ' + helper.getTranslateTextByKey(`ROL_USER_${roleLabel}`)} </label>
                </div>
              </div>
              <div className={classes.botonera}>
                <SecondaryButton type="button" onClick={showReactivateRoleModal ? handleCloseReactiveRoleModal : handleCloseChangeRoleModal}><Text uuid={"BUTTON_CANCEL"} /></SecondaryButton>
                <button className={`genericBlue`} disabled={!changeRoleAccepted} onClick={submitChangeRole}>Confirm </button>
              </div>
            </div>

          </Modal>}

          {/* DEACTIVATE MODAL */}

          {showDeactivateRoleModal && <Modal className={{ modal: classes.ModalDelete }}
            open={showDeactivateRoleModal} showHeader={false}>
            <div className={classes.DeleteForm}>
              <h1>{showDeactivateSelfRoleModal ? "Deactivate your access to " + startup.name : "Deactivate access to " + startup.name}</h1>
              <div className={classes.zoneCheckBox}>
                <div className={classes.CheckBox}>
                  <Checkbox style={{ color: "#FF606F", left: "0px" }} onChange={(e) => handleClickRoleModal(changeRoleAccepted)} />
                  <label>Remove {fullNameLabel}'s access to {startup.name}</label>
                </div>
                {showDeactivateSelfRoleModal ? <div className={classes.alertDeleteContainer}>
                  <span className={classes.alertDeleteSelfMessage}>Please note you will be instantly removed from this Profile on pressing confirm.</span>
                </div> : null}
              </div>
              <div className={`${classes.botonera} ${showDeactivateSelfRoleModal ? classes.alertModalBotonera : null}`}>
                <button className={classes.cancelButton} onClick={showDeactivateSelfRoleModal ? handleCloseDeactivateSelfRoleModal : handleCloseDeactivateRoleModal}><Text uuid={"BUTTON_CANCEL"} /></button>
                <button className={classes.submitButton} disabled={!changeRoleAccepted} onClick={submitDeactivateUser}>Confirm </button>
              </div>
            </div>
          </Modal>}
        </>
      </div>
    </>
  );
};

export default ShareProfileTable;
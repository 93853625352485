import PropTypes from 'prop-types';
// import _ from 'lodash';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import useCheckoutApi from '../hooks/useCheckoutApi';

/**
 * Component to wrap the stripe element key
 * @param {*} param0
 * @returns
 */
function StripeElementKey({ children, source = "STARTUP" }) {
  const { keys } = useCheckoutApi(source)
  const publicKeyStripe = keys?.token;
  let stripePromise ;
  if (publicKeyStripe) {
     stripePromise = loadStripe(publicKeyStripe);
  }
  return (
    <>
      {stripePromise && (
        <Elements stripe={stripePromise} options={{
          locale: 'en',
        }}>
          {children}
        </Elements>
      )}
    </>
  );
}

StripeElementKey.propTypes = {

  children: PropTypes.any.isRequired,
  source: PropTypes.string,

};

export default StripeElementKey;

import React, {useMemo, useState} from 'react'
import {Field} from "formik";
import {TextAreaInput, TextInput} from "../../../../../../../v1/components";
import classes from "../Styles.module.scss";
import ClaraCountrySelector from "../../../../../../../v1/components/Form/ClaraCountrySelector/ClaraCountrySelector";
import _ from "lodash";
import Line from "../../../../../../generic/Line/Line";
import Checkbox from "../../../../../../inputs/Checkbox/Checkbox";
import Mustache from "mustache";

const EditRecipientNotice=({nameHandle,companyField,companyTitle,tooltips,company})=>{
    const [useRegisteredAddress,setUseRegisteredAddress]=useState(company.useRegisteredAddress)
    const tooltipHandleBars=(tooltip)=>{
        return Mustache.render(tooltip,{company:nameHandle})
    }
    const tooltipsRendered=useMemo(()=>{
        return _.mapValues(tooltips,(value,key)=>{
            const regex=new RegExp(companyTitle,'g')
            if(key.match(companyTitle)) {
                return tooltipHandleBars(value)
            }
            else return value
        })
    },[tooltips])
    return (<>
        <h1 className={classes.title}>
            Edit Recipient
        </h1>
        <Field name={`company.recipientNotice.fullName`}>
            {({ field, form }) => (
                <TextInput
                    required={true}
                    classNameInput={{ root: classes.ContainerRow }}
                    type='text'
                    label='Full legal name '
                    field={field}
                    form={form}
                    placeholder={"Recipient's name"}
                    tooltip={tooltipsRendered[`${companyTitle}'s Recipient Notices`]}
                />
            )}
        </Field>
        <Field name={`company.recipientNotice.email`}>
            {({ field, form }) => (
                <TextInput
                    required={true}
                    type='email'
                    classNameInput={{ root: classes.ContainerRow }}
                    label="Email "
                    field={field}
                    form={form}
                    placeholder={"Email address"}
                    tooltip={tooltipsRendered[`${companyTitle}'s Recipient Email Address`]}
                />
            )}
        </Field>
        <Line className={classes.line}/>
        <Field name='company.useRegisteredAddress'>
            {({ field, form }) => {
                return (<>
                    <div style={{
                        marginTop: '1.5rem'
                    }}>
                        <Checkbox
                            onChange={(value) => {
                                form.setFieldValue(field.name,value)
                                setUseRegisteredAddress(value)
                            }}
                            value={field.value}
                            className={{checkbox: classes.checkBoxEdit}}
                            isMultiple={false}
                            label={'The address for the recipient is the same as the registered address.'}
                        />
                    </div>
                </>)
            }}
        </Field>
        <Line className={classes.line}/>
            {!useRegisteredAddress?(<>
                <div className={` ${classes.Select}`}>
                    <Field name={`company.recipientNotice.address.country`}>
                        {({field, form}) => (
                            <ClaraCountrySelector
								required={true}
                                jurisdictionsEnabled={false}
                                countryField={`company.recipientNotice.address.country`}
                                label={"Recipient's address "}
                                setFieldValue={form.setFieldValue}
                                initialValues={{country: _.get(form.values, `company.recipientNotice.address.country`, null)}}
                                returnCountryAsString={_.isEmpty(_.get(form.values, `company.recipientNotice.address.country.code`, null))}
                                additionalStyle={'ClaraCountrySelectorModal'}
                                tooltips={{
                                    country: tooltipsRendered[`${companyTitle}'s Recipient Notices Address - Country`]
                                }}
                            />
                        )}
                    </Field>
                </div>
                <div className={classes.ContainerRow}>
					<Field name={`company.recipientNotice.address.street`}>
						{({field, form}) => (
							<TextAreaInput
								type='text'
								label={' '}
								field={field}
								form={form} placeholder={"Address"}
								tooltip={tooltipsRendered[`${companyTitle}'s Recipient Notices Address - Address`]}
							/>
						)}
					</Field>
                </div>
                <Field name={`company.recipientNotice.address.city`}>
					{({field, form}) => (
						<TextInput
							classNameInput={{root: classes.ContainerRow}}
							type='text'
							field={field}
							form={form}
							placeholder={"City"}
							label={' '}
							tooltip={tooltipsRendered[`${companyTitle}'s Recipient Notices Address - City`]}
						/>
					)}
                </Field>
                <Field name={`company.recipientNotice.address.state`}>
					{({field, form}) => (
						<TextInput
							type='text'
							classNameInput={{root: classes.ContainerRow}}
							field={field}
							form={form}
							placeholder={"State / Region"}
							label={' '}
							tooltip={tooltipsRendered[`${companyTitle}'s Recipient Notices Address - State`]}
						/>
					)}
                </Field>
                <Field name={`company.recipientNotice.address.zipCode`}>
					{({field, form}) => (
						<TextInput
							type='text'
							classNameInput={{root: classes.ContainerRow}}
							field={field} form={form}
							label={' '}
							placeholder={"Post code"}
							tooltip={tooltipsRendered[`${companyTitle}'s Recipient Notices Address - Post Code`]}
						/>
					)}
                </Field>
                </>):null
            }
    </>)
}
export default EditRecipientNotice;

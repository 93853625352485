import useLazyQuery from 'src/hooks/custom/useLazyQuery';

const GET_IMMEDIATE_PARENT = `
query getImmediateParent($startupId: ID!, $groupCompanyId: ID!, $entityId: ID!) {
  getImmediateParent(startupId: $startupId, groupCompanyId: $groupCompanyId, entityId: $entityId) {
    id
    name
    mailingAddress {
      street
      city
      state
      country {
        code
      }
      zipCode
      type
    }
    taxJurisdiction {
      code
    }
    taxRegistrationNumber
  }
}`;

export const useGetImmediateParent = (variables: any, config?: any) => {
  const { manualQuery, loading, error, data, refetch } = useLazyQuery(GET_IMMEDIATE_PARENT, variables, config);
  return { loading, error, data, refetch, manualQuery };

}

export default useGetImmediateParent;

import { useMemo } from 'react';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import LimitedPartnershipInfo from '../steps/LimitedPartnershipInfo';
import RegisteredAddress from '../steps/RegisteredAddress';
import SelectSeparateLegal from '../steps/SelectSeparateLegal';

const useSteps = () => {
  return useMemo(() => ({
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    LimitedPartnershipInfo: {
      render: LimitedPartnershipInfo,
    },
    RegisteredAddress: {
      render: RegisteredAddress,
    },
    SelectSeparateLegal: {
      render: SelectSeparateLegal,
    },
    SaveStep: {
      render: TemplateWizardSkeletonMachine,
    },
  }), []);
}

export default useSteps;

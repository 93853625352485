import { Stack } from "@mui/material";
import { useCallback, useMemo } from 'react';
import Form from "src/components/forms/Form/FormL";
import CountrySelector from "src/components/inputs/Select/CountrySelector/CountrySelector";
import TextInput from "src/components/inputs/Text/TextInput";
import TemplateOnboarding2Wizard from 'src/modules/generic/templates/Modal/TemplateOnboarding2Wizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import Typography from "src/modules/generic/components/Texts/Typography";
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from "src/modules/generic/hooks/useTranslate";
import * as Yup from 'yup';
import { Constants } from "src/v1/utils/constants";
import Assets from 'src/modules/Onboarding/views/Wizard/assets';

/**
 * Step tell about your business
 * @param {*} param0
 * @returns
*/

const schema = Yup.object().shape({
  location: Yup.string().required("This field is required.").typeError("This field is required."),
  website: Yup.string().matches(Constants.REGEXP.URL_PROTOCOL, {
    message: "Please enter a valid URL. eg:'https://...' or 'http://... '",
    excludeEmptyString: true,
  }).nullable()
});

function TellUsAboutStep({ submitButonProps, actualStep, totalSteps }) {
  const { next, prev, context, cancel } = useMachine();
  const { translate } = useTranslate();

  const contextValues = context?.TellUsAboutStep ?? {
    location: "",
    website: ""
  };

  const handleNext = useCallback(
    (values) => {
      next(values);
    },
    [next]
  );

  const handleCancel = () => {
    cancel();
  }    

  const leftComponent = useMemo(() => {
    return (
      <Stack spacing={4} pt={11} sx={{width: "100%", height: "100%"}}>
        <Typography color={"black"} variant={"onboarding-titles-bold"} >
          <span dangerouslySetInnerHTML={{ __html: translate("MODULES.ONBOARDINGCOMPANY.ONBOARDING_TELL_US_ABOUT_STEP_TITLE") }}>
          </span>
        </Typography>
        <Typography variant="h2" color={"black"} component={"p"} >
          <span dangerouslySetInnerHTML={{ __html: translate("MODULES.ONBOARDINGCOMPANY.ONBOARDING_TELL_US_ABOUT_STEP_TEXT") }}></span>
        </Typography>
        <Stack spacing={2} pr={"120px"}>
          <TemplateLabel
            label={translate('MODULES.ONBOARDINGCOMPANY.ONBOARDING_TELL_US_LABEL_PRIMARY_LOCATION')}
            isRequired={true}
          >
            <ControllerInput
              render={CountrySelector}
              name="location"
              defaultValue={""}
              placeholder={translate('MODULES.ONBOARDINGCOMPANY.ONBOARDING_TELL_US_PLACEHOLDER_PRIMARY_LOCATION')}
              showStrong={true}
              clear
              onChange={(e) => { }}
            />
          </TemplateLabel>
          <TemplateLabel
            label={translate('MODULES.ONBOARDINGCOMPANY.ONBOARDING_TELL_US_LABEL_WEBSITE')}
          >
            <ControllerInput
              render={TextInput}
              name="website"
              defaultValue={""}
              placeholder={translate('MODULES.CLARA.TYPE_HERE_HIT')}
              onChange={(e) => { }}
            />
          </TemplateLabel>
        </Stack>
      </Stack>
    )
  }, [translate]);

  const rightComponent = useMemo(() => {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={0}
        sx={{ width: "100%", height: "100%" }}
      >
        <img src={Assets.STEP_2} alt="Clara" style={{ width: "285px", height: "auto", marginTop: "60px"}} />
      </Stack>
    )
  }, []);

  return (
    <Form
      onlySendDirty={false}
      defaultValues={contextValues}
      template={TemplateOnboarding2Wizard}
      schema={schema}
      onSubmit={handleNext}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateOnboarding2Wizard
        buttons={{
          submit: <SubmitButton {...submitButonProps} />
        }}
        cancel={handleCancel}
        previousClick={prev}
        leftComponent={leftComponent}
        rightComponent={rightComponent}
        actualStep= {actualStep}
        totalSteps={totalSteps}
      >
      </TemplateOnboarding2Wizard>
    </Form>
  );
}
export default TellUsAboutStep;
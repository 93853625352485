import React from 'react';
import classes from './PendingDraft.module.scss';
import image from '../../../../images/pending.svg'
import {useDispatch} from "react-redux";
import Text from '../../../text/Text/Text';
import {useParams} from 'react-router-dom';
import { goToPage, } from './../../../../store/actions/page';
import { Constants } from './../../../../v1/utils/constants';
const PendingDraft = (props) => {
    const dispatch = useDispatch();
    const params = useParams();
    const handleClose = (restart)=>{
        dispatch({ type: 'SET_RESTART_FLOW', restart:restart })
        props.handleClose(restart)
    }
    const goToSwitch = ()=>{
        goToPage(dispatch,Constants.PAGES.switchMatters)
    }
    return (
        <React.Fragment>
            <div className={classes.PendingDraft}>
                <div className={classes.PendingDraftContent}>
                    <h2>{props.title}</h2>
                    <h1>{<Text uuid={"TEXT_SCOPING_CONTINUE_TITLE"} />}</h1>
                    <p><Text uuid={"TEXT_SCOPING_CONTINUE_TEXT"} /></p>
                    <div className={classes.Botonera}>
                        <button className={`genericWhiteBorder`} onClick={()=>handleClose(true)}>
                            <Text uuid={"BUTTON_RESTART_OPERATION"} params={{ operation: props.operationButton }} />
                        </button>
                        <button className={`genericWhiteBorder ${classes.middleButton}`} onClick={()=>goToSwitch()}>
                            <Text uuid={"BUTTON_SWITCH_STARTUP"} />
                        </button>
                        <button className={`genericBlue`} onClick={()=>handleClose(false)}>
                            <Text uuid={"BUTTON_CONTINUE_OPERATION"} params={{ operation: props.operationButton }} />
                        </button>
                    </div>
                </div>
                <div className={classes.PendingDraftImg}>
                    <img src={image} />
                </div>

            </div>
        </React.Fragment >
    )
}

export default PendingDraft

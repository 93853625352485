import React from "react";
// import classnames from "classnames";
import Button from '../../../../../generic/components/Buttons/Button';
import Typography from "../../../../../generic/components/Texts/Typography";
import useMachine from '../../../../../generic/context/MachineContext/useMachine';
import useTranslate from '../../../../../generic/hooks/useTranslate';
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";

const TimeOutStep = () => {
  const {  next,prev } = useMachine();
  const { translate } = useTranslate();
  const handlePrev = () => {
    prev();
  }
  const handleNext = () => {
    next();
  }

  return (
    <React.Fragment>

      <TemplateWizard
        props={{
          title: translate('MODULES.CHECKOUT.PAYMENT_TIMEOUT_TITLE'),
          subTitle: translate('MODULES.CHECKOUT.MODALS.CHANGE_CARD.STEP_SUCCESS.SUB_TITLE')
        }}
        buttons={
          {
            cancel: <Button variant="secondary" onClick={() => handlePrev()}>
              {translate('BUTTON_CANCEL')} 
            </Button>,
            submit:  <Button variant="primary" onClick={() => handleNext()}>
            {translate('MODULES.CHECKOUT.PAYMENT_TIMEOUT_BUTTON')} 
          </Button>
          }}
      >
        <Typography variant={"h5"}>
          {translate('MODULES.CHECKOUT.PAYMENT_TIMEOUT_TEXT')}
        </Typography>
      </TemplateWizard>
    </React.Fragment>

  );
};

export default TimeOutStep;
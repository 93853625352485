import React, {Component} from 'react';
import {ClaraSelect, Modal} from '../../index';
import classes from './ModalShare.module.scss';
import TextInput from "../../../containers/Forms/Inputs/TextInput";
import * as Yup from "yup";

import {Field, Formik} from 'formik';


class ModalShare extends Component {
    constructor(props){
        super(props);
        this.state={
            isOpenModal:true,
            value:"",
            extraParams:{},
            initialValues : props.values || {}
        }
    }

    handleOnChange= event =>{
        this.setState({
            value: event.target.value
        })
    }

    handleClearValue= (event) =>{
        this.setState({
            value:""
        })
    }
    handleSubmit = (values) =>{
        this.props.handleSubmit(values)
    }

    onSubmit= (values) =>{
        this.handleSubmit(values)
    }
    isDisabled = (errors, touched, values) =>{
        return Object.keys(errors).length > 0 || Object.keys(values).length === 0 ;
    }

 

    render() {
        let {isOpenModal}= this.state;
        const {tooltips={}}=this.props;

        const field ={
            name: Yup.string().required('This field is required.').nullable(),
            shareClass: this.props.isShareIncentivePlan ? Yup.string().required('This field is required.').nullable() :""
        } 
        
        const validationSchema = Yup.object().shape(field);
         

        let { initialValues } = this.state
        return (


            <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            render={({values,handleSubmit, errors, touched})=>{
            return(
                <form onSubmit={handleSubmit}>
                {this.props.show &&
                
                        <Modal
                            handleClose={() => this.props.handleClose({ "showEdit": false })}
                            open={isOpenModal}
                            showHeader={false}
                            extraStyle={{ borderTopStyle: "hidden" }}
                        >
                            <div className={classes.containerShareForm} >
                                <h1> {this.props.title}</h1>
                           

                                <div className={classes.containerTextField} >
                                {/*
                                            <TextField
                                                classes={{ root: classes.TextField }}
                                                value={this.state.value}
                                                variant="outlined"
                                                label={this.props.placeholder}
                                                fullWidth={true}
                                                size="small"
                                                onChange={event => this.handleOnChange(event)}
                                            />
                                            {this.state.value.length !== 0 &&
                                                <button className={classes.clearButton} onClick={(event) => this.handleClearValue(event)} />

                                            }
                                        */}

                                {
                                    this.props.isShareIncentivePlan &&
                                    <div className={classes.ContainerClaraSelect}>
                                        <Field name="shareClass">
                                            {({ field, form }) => (

                                                <ClaraSelect
                                                    required={true}
                                                    field={field}
                                                    form={form}
                                                    tooltip={tooltips['Share Class']}
                                                    placeholder={"Search or select from a list..."}
                                                    lists={this.props.list}
                                                    mode={"classic"}

                                                />
                                            )}

                                        </Field>
                                    </div>
                                }
                                <Field name="name">
                                    {({ field, form }) => (
                                        <TextInput
                                            type="text"
                                            label=""
                                            field={field}
                                            form={form}
                                            placeholder={this.props.placeholder}
                                            tooltip={tooltips["Document Name"]}
                                            clear={true}
                                        />
                                    )}
                                </Field>      
                               
                        

                                    
                                </div>

                                <div className={classes.botonera}>

                                    <button onClick={()=> this.onSubmit(values)} disabled={this.isDisabled(errors, touched,values)} >
                                        {this.props.labelButton}
                                    </button>
                                    <button className={classes.nextButton} onClick={() => this.props.handleClose({ "showEdit": false })}>
                                        Cancel
                                     </button>

                                </div>
                            </div>
                        </Modal>
                }
            </form>
                
            )}}
        >
           </Formik>
        )
    
        
        
    }
}


export default ModalShare;
import _ from "lodash";
import React, { useEffect, useState } from "react";
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import TabsMenu from "../../../modules/generic/components/Tab";
import TabMenu from "../../../modules/generic/components/Tab/TabMenu";
import { Constants } from '../../utils/constants';
import useSecurity from "./../../../modules/security/hooks/useSecurity";
import helper from "./../../utils/helper";
import logger from "./../../utils/logger";
import classes from "./Tabs.module.scss";
const Tabs = ({ initValue, children, mode,...props }) => {
    const store = useSelector(store => store);
    const callTagManager = (tabName) => {
        if (tabName) {
            const userID = _.get(store,"session.user._id");
            const firstName = _.get(store, "session.user.firstName");
            const email = _.get(store, "session.user.email");
            const tagManagerArgs = {
                dataLayer: {
                    event: "TabView",
                    userId: userID,
                    firstName: firstName,
                    path: window.location.pathname,
                    pageName: document.title,
                    tabName: tabName,
                    userType: helper.getTypeUserByEmail(email),
                },
                dataLayerName: "PageDataLayer",
            };
            TagManager.dataLayer(tagManagerArgs);
        }
      };
      const log = (tabName) => {
        logger.info(Constants.LOGGER.ACTIONS.TAB,{tab: tabName})
        callTagManager(tabName);
      }
    const goTo = (tabKey) => {
        if (props.disabled && props.disabled()) {
            return false;
        } else if (props.changeTabParam) {
            props.goToPageTab(Constants.PAGES.viewStartup, { id: props.id, tab: tabKey })
        }
        log(tabKey);
        props.goTo(tabKey)
    };

    const onClickRight = () => {
        if (props.onClickRight) {
            props.onClickRight();
        }
    }

    const onClickLeft = () => {
        if (props.onClickLeft) {
            props.onClickLeft();
        }
    }
    const [tabs, setTabs] = useState([]);
    const [indexTab, setIndexTab] = useState(initValue);
    const {hasRoleAccess} = useSecurity()
    useEffect(() => {
        let indexTabAux = 0;
        let tabsAux = []
        props.tabs.forEach((tab, i) => {
            if (props.selected === tab.key) {
                indexTabAux = i
            }
            const classDisabled = props.disabled && props.disabled() ? classes.disabled : null;
            if (hasRoleAccess( tab.action ? tab.action : null, null)) {
                tabsAux.push(
                    <TabMenu disabled={props.disabled && props.disabled()} onClick={() => { goTo(tab.key) }} classes={{ root: classes.MuiTabRoot }}>
                        {tab.name}
                    </TabMenu>
                )
            }
        })
        setTabs(tabsAux)
        setIndexTab(indexTabAux);
    }, [mode])
    useEffect(() => {
        let indexTabAux = 0;
        props.tabs.forEach((tab, i) => {
            if (props.selected === tab.key) {
                indexTabAux = i
            }
           
        })
        setIndexTab(indexTabAux);
    }, [props.selected])
    
    return (

        <div className={`${classes.Tabs} ${props.classesTabs}`}>
            <TabsMenu initValue={indexTab} tabs={tabs} />
            {props.showButtons && (
                <div className={classes.ScrollButtons}>
                    <div id={props.leftId} onClick={() => onClickLeft()} className={`${classes.Button} ${classes.Left} ${classes.Deactive}`}>{'<'}</div>
                    <div id={props.rightId} onClick={() => onClickRight()} className={`${classes.Button} ${classes.Right} ${classes.Active}`}>{'>'}</div>
                </div>
            )}
        </div>

    );

}

export default Tabs

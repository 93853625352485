import React, { Component } from "react";
import { connect } from "react-redux";
import classes from "./EditForm.module.scss"
import { ButtonModalOptions, DocumentAction, RoleAccess, Tabs } from "../../index";

import { goToPage, goToPrevPage } from '../../../../store/actions/page'
import { Constants } from '../../../utils/constants';
import _ from "lodash";
import ServerConnect from "../../../utils/ServerConnect";
import { addAgreement as upsertDocument } from "../../../graphql/mutations/addAgreement";
import FormikHelper from "../../../utils/formikHelper";
import Loading from "../../Loading/Loading";
import Title from "./../../../../components/text/Title/Title"
class EditForm extends Component {

    state = {
        deleteOption: { label: "Delete", type: "delete" },
        data: undefined,
        forms: null,
        views: null,
        tabs: [],
        tab: this.props.tab || null,
        dataEdit: {},
        mode: Constants.FORMS_MODE.VIEW,
        verified: false,
        loading: false,
        showDelete: false,
    };

    static Edit = ({ children }) => <div className='edit'>{children}</div>;

    static View = ({ children }) => <div className='view'>{children}</div>;

    static Delete = ({ children }) => <div className='delete'>{children}</div>;

    static Container = ({ children }) => <div className='container'>{children}</div>;

    goTo = (tab) => {
        this.setState({
            tab: tab
        });
    };

    handleBack = () => {
        if (this.props.pageBackButton) {
            this.props.goToPage(this.props.pageBackButton);
        }
        else {
            this.props.goToPrevPage();
        }
    };

    entityDelete = () => {
        this.setState({
            showDelete: true
        })
    };

    setData = (setTab) => {

        const childrens = React.Children.toArray(this.props.children);
        let tabs = [];
        let forms = {};
        let views = {};
        let tab = this.state.tab;
        let deleteForm = null;
        _.forEach(childrens, (form, k) => {
            if (form.props.tab) {

                if (tab == null) {
                    tab = form.props.tab.key;
                }
                tabs.push(form.props.tab)

                var containerChildrens = React.Children.toArray(form.props.children);
                _.forEach(containerChildrens, (content, k) => {
                    const grandchild = React.cloneElement(content.props.children, { goToTab: this.goTo });
                    var newContent = React.cloneElement(content, { ...content.props }, [grandchild]);
                    if (content.props.isView) {
                        views[form.props.tab.key] = newContent;
                    } else {
                        forms[form.props.tab.key] = newContent;
                    }
                });

            } else {
                deleteForm = React.cloneElement(form.props.children, { ...form.props.children.props, handleClose: this.handleClose });
            }
        })
        this.setState({
            options: [{ ...this.state.deleteOption, action: this.entityDelete, className: classes.DeleteIcon }],
            forms,
            views,
            tabs,
            tab,
            deleteForm
        })
    };

    disabled = () => {
        let { mode } = this.props;
        return mode !== Constants.FORMS_MODE.VIEW;
    };

    componentWillMount() {
        this.setData();
    }

    componentWillUpdate(newProps, newState, nextContext) {
        if (newProps !== this.props) {
            this.setData();
            if (newProps.tab && newProps.tab !== this.props.tab) {
                this.goTo(newProps.tab)
            }
        }
    }

    handleClose = (redirect) => {

        this.setState({
            showDelete: false
        })
        if (redirect) {
            this.props.goToPage(Constants.PAGES.MODULES.MAP.maps)
        }
    }

    changeVerifiedState(data, verifiedState) {
        this.setState({
            loading: true
        });

        const mappedData = FormikHelper.mapAgreementDataForMutation({ ...data, verified: verifiedState });

        const variables = {
            startupId: this.props.startupId,
            agreementId: data.agreementId,
            ...mappedData
        };

        ServerConnect.graphQlMutation(upsertDocument, variables)
            .then(_ => {
                this.setState({
                    verified: verifiedState,
                    loading: false
                }, () => (console.log(verifiedState)))
            }).catch(err => {

                this.setState({
                    loading: false
                })
            });
    }

    renderVerifiedState(data) {
        if (data.agreementId) {
            if ((data && data.verified) || this.state.verified) {
                return (
                    <span className={classes.VerifyDiv}>
                        <RoleAccess action={Constants.ACTIONS.VERIFY_DOCUMENT}>
                            <div className={classes.VerifyButton} onClick={() => this.changeVerifiedState(data, false)}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                                        fill="#18C5A8"
                                        stroke="#18C5A8"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M15 9L11.5 14L9 11.933"
                                        stroke="white"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </div>
                        </RoleAccess>
                        <p>Verified Document</p>
                    </span>
                );
            } else {
                return (
                    <div className={classes.VerifyDiv}>
                        <span>
                            <RoleAccess action={Constants.ACTIONS.VERIFY_DOCUMENT}>
                                <div className={classes.VerifyButton} onClick={() => this.changeVerifiedState(data, true)}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="4.5" y="4.5" width="15" height="15" rx="7.5" stroke="#BEC9D7" />
                                    </svg>
                                </div>
                            </RoleAccess>
                            <p>Unverified Document</p>
                        </span>
                    </div>
                );
            }
        }
    }

    getAllowedActions = (document) => {
        return _.get(document, 'documentType.allowedActions', []);

        // I dont remember why do I try to use this logic, please don't delete it
        // return allowedActions.map(e =>
        //     e.key === 'deleteDocument'
        //         ? { ...e, value: !(document.parentDocument && document.relatedDocuments.length > 0) }
        //         : e
        // );
    };

    render() {
        let { forms, views, tab, showDelete, deleteForm } = this.state;
        let { mode, data } = this.props;

        const documentAllowedActions = this.getAllowedActions(data);
        // console.log('this.props.accessDelete',this.props.accessDelete)
        // console.log('this.props.changeDeleteOption',this.props.changeDeleteOption)

        return (
            <Loading show={this.state.loading}>
                <div className={classes.EditForm}>
                    <div className={classes.ContentEdit}>
                        {mode === Constants.FORMS_MODE.VIEW ?
                            <a
                                className={classes.icon}
                                variant='contained'
                                onClick={(e) => {
                                    this.handleBack(e)
                                }}
                            >
                            </a>
                            : null}
                        {this.props.accessDelete &&
                            <div className={`${classes.optionButton} `} style={this.props.positionOption}>
                                {this.props.changeDeleteOption ? null :
                                    <DocumentAction actions={documentAllowedActions} action='deleteDocument'>
                                        <RoleAccess action={this.props.accessDelete}>
                                            {this.props.conditionToDelete !== false && (
                                                <ButtonModalOptions title={null} options={this.state.options} />
                                            )}
                                        </RoleAccess>
                                    </DocumentAction>
                                }

                            </div>
                        }
                        <div className={classes.ContentView}>
                            <Title>

                                {this.props.headerDecorator ? this.props.headerDecorator() : <span>{this.props.title}</span>}
                                {/*this.renderVerifiedState(this.props.data)*/}
                            </Title>
                            {this.props.subTitle && <h3>{this.props.subTitle} </h3>}
                            <div className={classes.ContentTabs}>
                                <div className={classes.ContentTabsMenu}>
                                    <Tabs
                                        id={this.props.session.user.id}
                                        goToPageTab={this.props.goToPage}
                                        changeTabParam={this.props.changeTabParam ? true : false}
                                        tabs={this.state.tabs}
                                        goTo={this.goTo}
                                        selected={this.state.tab}
                                        disabled={this.disabled}
                                        ref={this.props.editFormRef}
                                        mode={this.props.mode}
                                    > </Tabs>
                                </div>
                                <div>
                                    {(mode === Constants.FORMS_MODE.VIEW) ? views[tab] : forms[tab]}

                                </div>

                            </div>
                        </div>
                    </div>
                    <RoleAccess action={this.props.accessDelete}>
                        {showDelete ? deleteForm : null}
                    </RoleAccess>
                </div>
            </Loading>
        );
    }
}

const mapStateToProps = state => ({
    session: state.session
});
const mapDispatchToProps = (dispatch) => ({
    goToPage: (page, params) => {
        goToPage(dispatch, page, params)
    },
    goToPrevPage: (reload, defaultPage, params) => {
        goToPrevPage(dispatch, reload, defaultPage, params)
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(EditForm)

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import Form from 'src/components/forms/Form/Form';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import Button from 'src/modules/generic/components/Buttons/Button';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { addAssistantText, clearAssistatText } from 'src/modules/generic/store/action';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import TextInput from 'src/components/inputs/Text/TextInput';
import CurrencySelector from 'src/components/inputs/Select/CurrencySelector/CurrencySelector';

const schema = Yup.object().shape({
  dividendIncome: Yup.number().required('This field is required').typeError('This field is required'),
  capitalGainsIncome: Yup.number().required('This field is required').typeError('This field is required'),
  relevantIncome: Yup.number().required('This field is required').typeError('This field is required'),
  incidentalIncome: Yup.number().required('This field is required').typeError('This field is required'),
  totalIncome: Yup.number().required('This field is required').typeError('This field is required'),
});

function RelevantIncomeStep() {
  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    const incomeData = context?.initialData?.groupCompany?.requestBasket?.requestData?.companyIncome;

    return  {
      currency: incomeData?.currency,
      dividendIncome: incomeData?.dividend,
      capitalGainsIncome: incomeData?.capitalGains,
      relevantIncome: incomeData?.relevant,
      incidentalIncome: incomeData?.incidental,
      totalIncome: incomeData?.total,
    }
  }, [context]);
  
  const [currency, setCurrency] = useState(initialValues?.currency || '');

  const handleCancel = () => {
    cancel();
  }

  const handleSubmit = async (values) => {
    next(values);
  }

  useEffect(() => {
    dispatch(
      addAssistantText(
        translate(
          "MODULES.RENEWALS.ECONOMIC_SUBSTANCE.COMPANY_INCOME.TOOLTIP_1"
        )
      )
    );
    dispatch(
      addAssistantText(
        translate(
          "MODULES.RENEWALS.ECONOMIC_SUBSTANCE.COMPANY_INCOME.TOOLTIP_2"
        )
      )
    );
    dispatch(
      addAssistantText(
        translate(
          "MODULES.RENEWALS.ECONOMIC_SUBSTANCE.COMPANY_INCOME.TOOLTIP_3"
        )
      )
    );
    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch]);

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: "Edit relevant income",
          skeleton: null,
          variant: 'simple'
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton>{translate('BUTTON_SAVE_CHANGES')}</SubmitButton>,
        }}
      >
        <TemplateLabel label="Currency used to complete this return" isRequired>
          <ControllerInput
            render={CurrencySelector}
            name="currency"
            defaultValue={context?.initialData?.groupCompany?.requestBasket?.requestData?.companyIncome?.currency}
            onChange={(val: string) => {setCurrency(val)}}
            disabled
          />
        </TemplateLabel>
        <TemplateLabel label="Dividend income" isRequired>
          <ControllerInput
            render={TextInput}
            placeholder="e.g. 100"
            name="dividendIncome"
            type="number"
            leftLabel={currency}
          />
        </TemplateLabel>
        <TemplateLabel label="Capital gains income" isRequired>
          <ControllerInput
            render={TextInput}
            placeholder="e.g. 100"
            name="capitalGainsIncome"
            type="number"
            leftLabel={currency}
          />
        </TemplateLabel>
        <TemplateLabel label="Relevant income" isRequired>
          <ControllerInput
            render={TextInput}
            placeholder="e.g. 100"
            name="relevantIncome"
            type="number"
            leftLabel={currency}
          />
        </TemplateLabel>
        <TemplateLabel label="Incidental income" isRequired>
          <ControllerInput
            render={TextInput}
            placeholder="e.g. 100"
            name="incidentalIncome"
            type="number"
            leftLabel={currency}
          />
        </TemplateLabel>
        <TemplateLabel label="Total income" isRequired>
          <ControllerInput
            render={TextInput}
            placeholder="e.g. 100"
            name="totalIncome"
            type="number"
            leftLabel={currency}
          />
        </TemplateLabel>
      </TemplateWizard>
    </Form>
  );
}

export default RelevantIncomeStep;
import React, { FC, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { gql, useQuery } from "@apollo/client";
import _ from "lodash";
import useCustomMachine from '../../../../generic/hooks/useCustomMachine'
import useSession from "src/modules/session/hooks/useSession";
import Button from '../../../../generic/components/Buttons/Button'
import { StepProps } from "../../../../generic/components/Wizard/Step"
import TemplateWizard from '../../../../generic/templates/Modal/TemplateWizard'
import useTranslate from '../../../../generic/hooks/useTranslate'
import Form from "../../Forms/FormSelectCompany";
import { goToNextPage } from '../../../../../store/actions/page'
import { Constants } from '../../../../../v1/utils'
import { addAssistantText, clearAssistatText } from "../../../../generic/store/action";

const GROUP_COMPANIES_QUERY = gql`
  query getGroupCompanies($startupId: ID!) {
    getGroupCompanies(startupId: $startupId) {
      id
      name
      country { code }
      isHoldingCompany
    }
  }
`;

const StepIpAgreement: FC<StepProps> = () => {
  const { translate } = useTranslate();
  const { startupId } = useSession();
  const dispatch = useDispatch();
  const { next, send, state } = useCustomMachine();
  const { type } = state.context.paramsData;
  const { data: companiesData } = useQuery(GROUP_COMPANIES_QUERY, { variables: { startupId } });

  const topCoCompanyCountry = (
    _.find(companiesData?.getGroupCompanies, { isHoldingCompany: true })?.country?.code ||
    companiesData?.getGroupCompanies[0]?.country?.code
  );

  const documentType = useMemo(() => {
    if (type === 'IP_ASSIGNMENT') {
      return topCoCompanyCountry === 'US' ? 'USA_CONSULTANT_INFORMATION_AND_INVENTIONS_AGREEMENT' : type;
    } else {
      return type;
    }
  }, [topCoCompanyCountry, type]);

  const handleCompleteSubmit = async (values) => {
    if (values.typeFormCreation === 'GENERATE_DOCUMENT') {
      return dispatch(goToNextPage(Constants.PAGES.chooseDocumentType, { previewDocument: documentType }));
    }
    next(values);
  }

  const handleCancel = () => {
    send("CANCEL");
  }

  useEffect(() => {
    dispatch(addAssistantText(translate('MODULES.DOCUMENTS_STARTUP.IP_AND_GENERATE.IP_STEP.TOOLTIP')));
    return () => {
      dispatch(clearAssistatText());
    }
  }, []);

  return (
    <>
      <Form onCompleteSubmit={handleCompleteSubmit} Template={TemplateWizard} propsTemplate={{
        title: translate('MODULES.DOCUMENTS_STARTUP.IP_AND_GENERATE.IP_STEP.TITLE'),
        subTitle: translate(`MODULES.DOCUMENTS_STARTUP.IP_AND_GENERATE.IP_STEP.SUBTITLE`)
      }}
        buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }}>
      </Form>
    </>
  );
}

export default StepIpAgreement;
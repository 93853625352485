import React, {Component} from "react";
import {connect} from "react-redux";
import {DeleteForm, Loading} from "../../../index";

import {Constants} from '../../../../utils/constants'
import ServerConnect from "../../../../utils/ServerConnect";
import _ from "lodash";
import helper from "../../../../utils/helper";

class DeleteEntityKYC extends Component {
    state = {
        data: {},
        showLoading: false,
        options: [],
    }
    /* Particular data */
    getTitle() {
        return this.props.data.name;
    }

    getOptions() {
        let options = [];
        options.push({ label: "Delete this Entity?" });
        return options;
    }
    getWarning() {
        const active = _.get(this, "props.data.deactivated", true);
        if (!active) {
            return helper.getTranslateTextByKey("KYC_TABLE_DOCUMENT_DELETE_ENABLED_WARNING_USER")
        }
        return helper.getTranslateTextByKey("KYC_TABLE_DOCUMENT_DELETE_ENABLED_WARNING_USER")
    }

    hasAllData() {
        return true;
    }
    /*  Call Server*/
    callServerToGetData = () => {
        this.showLoading(true);
        //Get data if exist this posibility
    };
    deleteEntity = () => {
        return new Promise((resolve, reject) => {
            let variableId = this.props.data.type == Constants.ENTITY_TYPES.COMPANY ? { KYCCompanyId: this.props.data.entityId } : { KYCIndividualId: this.props.data.entityId }
            var data = {
                ...variableId,
                matterId: this.props.data.matterId
            };
            this.showLoading(true);
            ServerConnect.graphQlMutation(this.props.data.query, data).then((result) => {
                this.showLoading(false);
                if (this.props.callbacks) {
                    this.props.callbacks();
                }
                resolve();
            });
        });
    }
    /* Change status */
    showLoading = (status) => {
        this.setState({
            showLoading: status
        });
    }
    /* REac functions */
    componentWillMount() {
        if (this.hasAllData()) {
            this.setState({
                data: this.props.data
            })


        } else {
            this.callServerToGetData();
        }
    }


    render() {
        if (this.props.show) {
            return (
                <Loading show={this.state.showLoading}>
                    <DeleteForm handleClose={this.props.handleClose} show={true}
                        deleteEntity={this.deleteEntity} showLoading={this.showLoading}
                        title={this.getTitle()} options={this.getOptions()} warning={this.getWarning()}
                        nextButtonText={"Accept"}
                    >
                    </DeleteForm>
                </Loading>
            )
        } else {
            return null
        }

    }
}

const mapStateToProps = state => ({
    session: state.session,
    form: state.form
});
const mapDispatchToProps = (dispatch) => ({

});
export default connect(mapStateToProps, mapDispatchToProps)(DeleteEntityKYC)

import { gql, useQuery } from '@apollo/client';
import _ from "lodash";
import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import useLoading from "../../modules/generic/hooks/useLoading";
import { isInvalidToken } from "../../utils/errorHelper";
import notification from "../../v1/utils/notification";
import { Constants } from "./../../v1/utils/constants";
import logger from "./../../v1/utils/logger";
import useTextLang from "./useTextLang";
import { goToPage } from '../../store/actions/page';
import ServerConnect from '../../v1/utils/ServerConnect'
export default (query, variables, config, props) => {

    const {addLoading, removeLoading} = useLoading();
    const [isExecute, setIsExecute] = useState(false);
    const dispatch = useDispatch();
    const messageError = useTextLang(_.get(config, "errorMessage", "GENERIC_NOTIFICATION_ERROR"));
    const messageSuccess = useTextLang(_.get(config, "successMessage", "GENERIC_NOTIFICATION_SUCCESS"));
    const skip = _.get(config, 'skip', false);
    const variablesQuery = _.get(variables, "variables", variables);
    const client = ServerConnect.getUniqueApolloClient();
    const log = () => {
        logger.info(Constants.LOGGER.ACTIONS.QUERY, { query: query, variables: variables })
    };

    const logError = (error) => {
        logger.error(Constants.LOGGER.ACTIONS.QUERY, { error, query: query, variables: variables })
    };

    const logSuccess = (result) => {
        logger.success(Constants.LOGGER.ACTIONS.QUERY, { result, query: query, variables: variables })
    };

    useEffect(() => {
        addLoading && addLoading();
    }, []);

    const onError = (error) => {
        removeLoading && removeLoading();
        if (error) {
            logError(error);
            if (process.env && process.env.NODE_ENV === "development") {
                console.log(error);
            }
            // avoid notification on invalid token, just redirect
            if(!isInvalidToken(error)) {
                const showNotification = config ? config.showErrorNotification : true;
                if (_.get(window, "globalNotification") && showNotification !== false) {
                    notification.sendNotification(messageError, "error", 2500);
                }
                if (config && config.onError) {
                    config.onError(error)
                }
            }else{
                goToPage(dispatch,Constants.PAGES.logout)  ;
            }
        }
    }
   

    const onCompleted = (data) => {
        removeLoading && removeLoading();
        if (data) {
            logSuccess(data)
            const showNotification = config && config.showSuccessNotification ? config.showSuccessNotification : false;
            if (_.get(window, "globalNotification") && showNotification !== false) {
                notification.sendNotification(messageSuccess, "success", 2500);
            }
        }
        if (config && config.onCompleted) {
            config.onCompleted(data)
        }
    }
    //TODO: Pach to fix problem with ticket CP-813, in the next release remplace fetchPolicy813 by fetchPolicy and send to retest all queries
    const { loading, error, data, refetch,fetchMore, propsDesc } = useQuery(gql`${query}`, {
        // errorPolicy: 'all',fetchPolicy:_.get(config,"fetchPolicy813","cache-first"), nextFetchPolicy: _.get(config,"fetchPolicy813","cache-first"),notifyOnNetworkStatusChange: true,client,
        errorPolicy: 'all',fetchPolicy:"cache-and-network", nextFetchPolicy: "cache-first",
        variables: variablesQuery, skip: skip, ...props, onError, onCompleted
    });

    return { loading, error, data, refetch,fetchMore, ...propsDesc };
}

import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import _ from "lodash";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import Button from "src/modules/generic/components/Buttons/Button";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import {
  addAssistantText,
  clearAssistatText,
} from "src/modules/generic/store/action";
import FormIsThereCompaniesFormationsShares from "src/modules/startup/GroupCompany/Forms/FormIsThereCompaniesFormationsShares";

function WillHaveSharesNotYetFormedStep() {
  const { translate } = useTranslate();
  const { next, cancel, prev, context } = useMachine();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.WillHaveSharesNotYetFormedStep ?? {};
  }, [context]);

  useEffect(() => {
    dispatch(clearAssistatText());
  }, []);

  // const formatData = (values) => {
  //   const sharesInIncorporatedCompanies = [];
  //   _.forEach(state.context.globalValues, (data) => {
  //     if (data.create_company_1)
  //       sharesInIncorporatedCompanies.push(data.create_company_1);
  //     if (data.create_company_2)
  //       sharesInIncorporatedCompanies.push(data.create_company_2);
  //     if (data.create_company_3)
  //       sharesInIncorporatedCompanies.push(data.create_company_3);
  //     if (_.get(data, "select_companies.companies", null))
  //       sharesInIncorporatedCompanies.push([
  //         ...data.select_companies.companies,
  //       ]);
  //   });
  //   if (_.get(state.context, "data.select_companies.companies", null))
  //     sharesInIncorporatedCompanies.push(
  //       state.context.data.select_companies.companies
  //     );
  //   return {
  //     startupId,
  //     groupCompanyId: id,
  //     groupCompanyData: {
  //       formationData: {
  //         sharesInIncorporatedCompanies: _.remove(
  //           _.flatten(sharesInIncorporatedCompanies),
  //           (gc) => gc !== "ADD"
  //         ).map((gc) => {
  //           return {
  //             id: gc.id,
  //           };
  //         }),
  //         notYetFormedCompanyName: values.isThereCompaniesFormationsShares
  //           ? values.formationCompaniesHavesShares
  //           : null,
  //         hasShareInIncorporated: _.get(
  //           state.context,
  //           "data.is_there_shares_in_companies.isThereCompaniesShares"
  //         ),
  //         hasShareInCompanyFormed: values.isThereCompaniesFormationsShares,
  //       },
  //     },
  //   };
  // };
  //
  // const handleCompleteSubmit = async (values) => {
  //   await mutation(stripTypenames(formatData(values)));
  //   next(values);
  // };

  const handleSubmit = async (values) => {
    next(values);
  };

  const handleCancel = () => {
    cancel();
  };

  const handlePrev = () => {
    prev();
  };

  return (
    <>
      <FormIsThereCompaniesFormationsShares
        initialValues={initialValues}
        onCompleteSubmit={handleSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: 'Will the company have shares in any companies not yet formed?',
          subTitle: "Review company details",
        }}
        buttons={{
          previous: (
            <Button onClick={handlePrev} variant="secondary">
              Previous
            </Button>
          ),
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              Cancel
            </Button>
          ),
        }}
      />
    </>
  );
}

export default WillHaveSharesNotYetFormedStep;

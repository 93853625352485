import { useMemo } from "react";
import _, { get, isEmpty,uniq } from 'lodash'
import useSession from 'src/modules/session/hooks/useSession';
import useUpsertComponentRole from 'src/modules/startup/Stakeholder/graphql/mutation/useUpsertRole';
import useGetStakeholdersGroupCompaniesLazy from 'src/modules/startup/onBoarding/core/graphql/queries/useGetStakeholdersGroupCompaniesLazy';
import useCreateStakeholder from "src/modules/startup/onBoarding/core/graphql/mutations/useCreateStakeholder";
import useGetPINCAPrices from "src/views/modules/startups/renewal/pincasPrice/queries/useGetPINCAPrices";

const useServices = () => {
  const { manualQuery } = useGetStakeholdersGroupCompaniesLazy();
  const { startupId } = useSession();
  const {manualQuery: getPINCAPrices} = useGetPINCAPrices()

  const { upsertComponentRole } = useUpsertComponentRole({
    showSuccessNotification: false,
  }, {});

  const { createStakeholder } = useCreateStakeholder({
    showSuccessNotification: true,
    successMessage: 'STAKEHOLDER_ADD_SUCCESS'
  }, {});

  const getRolesData = (roles, entity) => {
    const roleData = _.map(roles, (r) => {
      return {
        type: r
      }
    });
    return {
      entityId: entity?.id,
      entityType: entity?.__typename === 'GroupCompany' ? 'GROUP_COMPANY' : 'STAKEHOLDER' || 'GROUP_COMPANY',
      role: roleData || []
    }
  };

  const getRolesDataMultipleEntities = (roles, entities) => {
    return _.map(entities, (entity) => {
      const roleData = _.map(roles, (r) => {
        return {
          type: r
        }
      });
      return {
        entityId: entity?.id,
        entityType: entity?.__typename === 'GroupCompany' ? 'GROUP_COMPANY' : 'STAKEHOLDER' || 'GROUP_COMPANY',
        role: roleData || []
      }
    });
  };

  return useMemo(() => ({
    getStakeholdersGroupCompanies: async () => {
      const stakeholdersGroupCompanies = await manualQuery();
      const groupCompaniesWithPincas =get(stakeholdersGroupCompanies.data, 'getGroupCompanies', []).filter(g=>g.managedByClara && g.kind === 'INCORPORATED') 
      const jurisdictions = uniq(groupCompaniesWithPincas.map(g => g.jurisdictionType));
      const requestItems = await getPINCAPrices({
        variables: {
          filters: {
            jurisdictionTypes: jurisdictions
          }
        }
      })
      return {
        requestItems: requestItems || {},
        stakeholders: get(stakeholdersGroupCompanies.data, 'stakeholderList', []),
        groupCompanies: get(stakeholdersGroupCompanies.data, 'getGroupCompanies', [])
      }
    },
    SaveStep: async (context, event) => {
      const values = get(event, 'payload.values');
      const roles = get(context, 'roles');
      const role = roles[context?.iterations - 1 || 0];
      let groupCompanies = get(context, 'groupCompanies') || get(values, 'groupCompanies');
      const hasLegalEntityStructure = get(context, 'hasLegalEntityStructure');
      if (isEmpty(context?.getStakeholdersGroupCompanies?.groupCompanies) || isEmpty(context?.roles)) {
        const variables = {
          startupId,
          stakeholderData: {
            stakeholder: {
              fullName: context?.stakeholder?.fullName,
              isAnEntity: context?.isEntity,
              isFounder: context?.isFounder,
              companyType: hasLegalEntityStructure ? context?.legalEntityStructure : null
            },
          }
        }
        return await createStakeholder({ variables });
      }
      if (context?.getStakeholdersGroupCompanies?.groupCompanies?.length === 1) {
        const variables = {
          startupId,
          stakeholderData: {
            stakeholder: {
              fullName: context?.stakeholder?.fullName,
              isAnEntity: context?.isEntity,
              isFounder: context?.isFounder,
              companyType: hasLegalEntityStructure ? context?.legalEntityStructure : null
            },
            roles: getRolesData(roles, context?.getStakeholdersGroupCompanies?.groupCompanies[0])
          }
        }
        return await createStakeholder({ variables });
      }
      if (context?.iterations === 1) {
        const role = roles[0];
        const variables = {
          startupId,
          stakeholderData: {
            stakeholder: {
              fullName: context?.stakeholder?.fullName,
              isAnEntity: context?.isEntity,
              isFounder: context?.isFounder,
              companyType: hasLegalEntityStructure ? context?.legalEntityStructure : null
            },
            roles: getRolesDataMultipleEntities([role], groupCompanies)
          }
        };
        return await createStakeholder({ variables });
      }
      const createdStakeholder = context?.createdStakeholder;
      const rolesToAdd = [];
      groupCompanies.forEach(gc => rolesToAdd.push({
        entityId: gc?.id,
        entityType: "GROUP_COMPANY",
        role: [
          {
            type: role
          }
        ]
      }));
      const variables = {
        startupId,
        stakeholderId: createdStakeholder?.id,
        stakeholderRolesData: {
          isFounder: context.isFounder,
          roles: rolesToAdd
        }
      };
      return await upsertComponentRole({ variables });
    },

  }), [createStakeholder, manualQuery, startupId, upsertComponentRole]);

}
export default useServices;

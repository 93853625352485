import Parser from "html-react-parser";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Scrollbar from "../../../../../components/generic/Scrollbar/Scrollbar";
import Text from "../../../../../components/text/Text/Text";
import useSearch from "../../../../../hooks/custom/useSearch";
import { useAnswerAcademyQuestionLazy } from "../../../../../hooks/services/modules/assistance/useAnswerAcademyQuestion";
import AssistanceHelpAsk from "../../../../assistanceV4/components/AssistanceHelpAsk/";
import Bubble from "../../../../assistanceV4/components/Bubble";
import BubbleContents from "../../../../assistanceV4/components/BubbleContents";
import useSession from "../../../../session/hooks/useSession";
import {
  addAssistantText,
  BubbleAcademy
} from "../../../store/action";
import cls from "./ChatAssistant.module.scss";

const ChatAssistant = () => {

  const [query, onChangeQuery] = useSearch();
  const dispatch = useDispatch();
  const { user:userData } = useSession();

  const assistantTexts = useSelector((state) =>
    _.get(state, "generic.assistantTexts", [])
  );

  const handleCompletedSendQuestion = ({ askAnythingContentful }) => {
    let responseType;
    if (askAnythingContentful.length === 0) {
      responseType = "noResult";
      askAnythingContentful = [{ description: "No articles found" }];
    } else {
      responseType = "academy";
    }
    const response: BubbleAcademy[] = _.map(
      askAnythingContentful,
      ({ description, link: linkAcademy }) => ({ description, linkAcademy })
    );
    dispatch(addAssistantText(response, responseType));
  };

  const { manualQuery } = useAnswerAcademyQuestionLazy({
    fetchPolicy:"no-cache",
    onCompleted: handleCompletedSendQuestion,
  });

  const sendAssistantQuestion = async () => {
    const user = _.pick(userData, ["avatar", "fullName"]);
    dispatch(addAssistantText({ description: query, user }, "question"));
    if (query.length > 0) {
      await manualQuery({ variables: { ask: query } });
    }
  };

  return (
    <div className={cls["ChatAssistant-container"]}>
      <Scrollbar
        scrollBottom={true}
        id={"Assistand_Chat"}
        className={`${cls["container-bubbles"]} `}
      >
        {assistantTexts.map(({ value, id, type }, index) => {
          if (type === "custom-child") {
            return <Bubble key={id} > {value.component}</Bubble>;
          }
          if (type === "custom-bubble") {
            return value.component;
          }
          return (
            <React.Fragment key={id}>
              {type === "noResult" ? (
                <Bubble
                  link={""}
                  disabledLink={true}
                  iconType={"Academy"}
                  color={"academy"}
                >
                  <BubbleContents
                    color="dark-grey"
                    variant={"card-content"}
                  >
                    <p><Text uuid={"ASSISTANCE_ACADEMY_NOT_FOUND"} /></p>
                  </BubbleContents>
                </Bubble>
              ) : type === "question" ? (
                <AssistanceHelpAsk
                  text={_.get(value, "description", value)}
                  userName={userData.fullName}
                  avatar={userData.avatar}
                ></AssistanceHelpAsk>
              ) : type === 'alert'?(
                <Bubble key={id} variant='alert'>
                  { Parser(value)}
                </Bubble>
              ) :
              (
                <Bubble key={id} link={_.get(value, "linkAcademy")}>
                  { Parser(_.get(value, "description", value))}
                </Bubble>
              )}
            </React.Fragment>
          );
        })}
      </Scrollbar>
    </div>
  );
};

export default ChatAssistant;

import useLazyQuery from "src/hooks/custom/useLazyQuery";
import useSession from "src/modules/session/hooks/useSession";
import { get } from "lodash";

const STAKEHOLDERS_GROUP_COMPANIES_QUERY = `
  query stakeholdersWithRecNoticesCompleted($startupId: ID!, $groupCompanyId: ID!) {
    stakeholdersWithRecNoticesCompleted(startupId: $startupId, groupCompanyId: $groupCompanyId) {
      fullName
      id
      address {
        city
        country {
          code
        }
        state
        street
        zipCode
      }
      email
    }
  }
`;

const useGetStakeholdersGroupCompaniesLazy = () => {
  const { startupId } = useSession();
  const { manualQuery, loading, data, refetch, error } = useLazyQuery(STAKEHOLDERS_GROUP_COMPANIES_QUERY, {
    startupId
  });
  return { data: { stakeholders: get(data, 'stakeholderList', []), groupCompanies: get(data, 'getGroupCompanies', []) }, loading, error, refetch, manualQuery };
}

export default useGetStakeholdersGroupCompaniesLazy;
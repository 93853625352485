import filters from "./filters";
import aproved from "./aproved";
import links from "./links";

const COMPANIES_TYPE ={
    ADGM_SPV: "SPV",
    ADGM_TSL: "TSL",
    CAYMAN_EXEMPTED_COMPANY: "EXEMPTED_COMPANY",
    DELAWARE_C_CORP: "C_CORP",
}
const TLS_TYPE ={
    SEED: "SEED",
    EMERGENT: "EMERGENT"
}
const ITEMS = {
    ADGM_NOMENEE_FEE:  "ADGM_NOMENEE_FEE",
}
const constants = {
    COUNTRY_JURISDICTIONS_FILTERS: filters,
    APROVED_FILTERS: aproved,
    JURISDICTION_LINKS: links,
    COMPANIES_TYPE: COMPANIES_TYPE,
    TLS_TYPE: TLS_TYPE,
    ITEMS: ITEMS,
}
export default constants;
import React from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";

const AddExerciseGenerate = ({ go, ...props }) => {
  const params = useParams();

  return (
    <MicroFrontend id="MF_Add_Exercise_Generate_Content_View" component={"./AddExerciseGenerate"} mf="capTable" params={{ go, ...props, ...params }} />
  );
};

export default AddExerciseGenerate;

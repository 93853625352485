import useQuery from "../../../../../../../hooks/custom/useQuery";
import {NoticesFragment} from "../../../../../../../modules/startup/Stakeholder/graphql/fragments";
import _ from 'lodash'
export const GET_STAKEHOLDER = `
  ${NoticesFragment}
  query getStakeholderNotices($startupId: ID! $stakeholderId: ID!) {
  getStakeholder(startupId: $startupId stakeholderId: $stakeholderId) {
    id
   recipientNotice {...NoticesFragment}
    }
  }`;

const useStakeholderNotices = (variables) =>{

    const {loading, error, data} = useQuery(GET_STAKEHOLDER,variables);

    return  {loading,error,data:_.get(data,'getStakeholder')} ;
}
export default useStakeholderNotices;

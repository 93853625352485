import * as Yup from 'yup';
import moment from 'moment';
type ObjectSchema = Yup.ObjectSchema<any>;
type StringSchema = Yup.StringSchema<any>;
type NumberSchema = Yup.NumberSchema<any>;
type BooleanSchema = Yup.BooleanSchema<any>;
type ArraySchema = Yup.ArraySchema<any>;
type DateSchema = Yup.DateSchema<any>;
type ValueSchema = StringSchema | NumberSchema | BooleanSchema | ArraySchema | DateSchema | ObjectSchema;
type ObjectCostructor = {
  [key: string]: {
    type: string,
    validations: {
      name: string,
      args: any[]
    }[]
  }
}
//TODO: CONTINUE HERE WITH THE REST OF THE VALIDATIONS
const useValidations = () => {
  // ADD METHODS TO VALIDATION SCHEMAS
  Yup.addMethod(Yup.date, 'isAfter', function (message: string, date: Date) {
    return this.test('isAfter', message, function (value: any) {
      return moment(moment(value, 'YYYY-MM-DD')).isAfter(moment(date), "day");
    });
  })
  Yup.addMethod(Yup.date, 'isBefore', function (message: string, date: Date) {
    return this.test('isBefore', message, function (value: any) {
      return moment(moment(value, 'YYYY-MM-DD')).isBefore(moment(date), "day");
    });
  })
  // VALIDATION CONFIGURATIONS
  const isRequired = (validation: ValueSchema) => validation.required('Required');
  const isOptional = (validation: ValueSchema) => validation.optional().nullable();
  const when = (validation: any, when: string, then: ValueSchema, otherwise: ValueSchema, is: (value: any) => boolean) => validation.when(when, {
    is,
    then,
    otherwise
  });
  const concat = (validation: ObjectSchema, schema: ObjectSchema) => validation.concat(schema);
  const min = (validation: NumberSchema, min: number) => validation.min(min);
  const max = (validation: NumberSchema, max: number) => validation.max(max);
  const typeError = (validation: NumberSchema, message: string) => validation.typeError(message);
  const nullable = (validation: ValueSchema) => validation.nullable();
  const amount = () => min(typeError(Yup.number(), 'Needs to be a number'), 0);
  const of = (validation: ArraySchema, of: ValueSchema) => validation.of(of);
  const isAfter = (validation: DateSchema, date: Date) => validation.isAfter(date, `Must be after ${moment(date).format('YYYY-MM-DD')}`);
  const isBefore = (validation: DateSchema, date: Date) => validation.isBefore(date, `Must be before ${moment(date).format('YYYY-MM-DD')}`);
  const isAfterToday = (validation: DateSchema) => validation.isAfter(new Date(), 'Must be after today');
  const isBeforeToday = (validation: DateSchema) => validation.isBefore(new Date(), 'Must be before today');

  const fieldType = {
    string: Yup.string,
    number: Yup.number,
    boolean: Yup.boolean,
    array: Yup.array,
    date: Yup.date,
    object: Yup.object,
    lazy: Yup.lazy,
    amount
  }
  const fieldConfigurations = {
    isRequired,
    isOptional,
    when,
    concat,
    of,
    min,
    max,
    typeError,
    nullable,
    isAfter,
    isBefore,
    isAfterToday,
    isBeforeToday
  }
  // VALIDATION UTILS
  const addField = (validation: ObjectSchema, field: string, schema: ValueSchema) => concat(validation, Yup.object().shape({ [field]: schema }));
  const schemaContructor = (objectContructor: ObjectCostructor) => {
    const keys = Object.keys(objectContructor);
    const schema = fieldType.object();
    keys.forEach(key => {
      const field = fieldType[objectContructor[key].type]();
      const validations = objectContructor[key].validations;
      validations.forEach(validation => {
        const validationFunction = fieldConfigurations[validation.name];
        const args = validation.args;
        validationFunction(field, ...args);
      })
      addField(schema, key, field);
    });
    return schema;
  }

  // VALIDATION SCHEMAS
  const schemaQuestion = () => schemaContructor({ question: { type: 'boolean', validations: [{ name: 'isRequired', args: [] }] } });
  const schemaQuestionWithDescription = () => addField(schemaQuestion(), 'description', when(isRequired(fieldType.string()), 'question', isRequired(fieldType.string()), isOptional(fieldType.string()), (value: any) => value));
  const schemaQuestionWithDescriptionAmount = () => addField(schemaQuestion(), 'description', when(isRequired(fieldType.string()), 'question', isRequired(fieldType.string()), isOptional(fieldType.string()), (value: any) => value));

  return {
    schemaContructor,
    addField,
    fieldType,
    fieldConfigurations,
    schemaQuestion,
    schemaQuestionWithDescription,
    schemaQuestionWithDescriptionAmount
  }
}
export default useValidations;
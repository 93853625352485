import _ from "lodash";
import React from "react";
import Button from "src/modules/generic/components/Buttons/Button";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import Form from "src/components/forms/modules/startups/individualStakeholder/PositionForm";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useModal from "src/modules/generic/hooks/useModal";

/**
 * Step edit subscriptions
 * @returns
 */

function EditYourSubscription() {
  const { next, context } = useMachine();
  const { translate } = useTranslate();
  const { closeModal: onClose } = useModal();

  const handleCancel = () => {
    onClose();
  };

  const handleCompleteSubmit = async (values) => {
    const newValues = {
      ...values,
      department: values?.department,
      responsibilities: values?.responsibilities,
    };

    const other = _.find(
      newValues?.responsibilities,
      (res) => _.get(res, "name") === "Other" || _.get(res, "label") === "Other"
    );

    if (other) {
      other.label = "Other";
      other.text = values?.other;
    }

    next(newValues);
  };

  return (
    <>
      <Form
        initialValues={context?.initialData ?? {}}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: "Position",
          subTitle: "Edit position",
        }}
        buttonSubmitProps={{
          children: translate("BUTTON_SAVE_CHANGES"),
        }}
        buttons={{
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              Cancel
            </Button>
          ),
        }}
      />
    </>
  );
}

export default EditYourSubscription;

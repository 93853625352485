import { fieldsPart1, fieldsPart2, fieldsPart3 } from "../../constants";
import React from "react";
import { FieldRadioInput, FieldTextInput } from "../../forms/FieldsForm";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import useValidations from 'src/modules/generic/forms/useValidations';
import _ from 'lodash';

interface Field {
  name: string,
  component: string,
  label: string,
  notRequired?: boolean,
}

const useGetFields = (part: 1 | 2 | 3) => {
  const { addField, fieldConfigurations, fieldType } = useValidations();
  const { isRequired, nullable, isOptional } = fieldConfigurations;
  const { string, object, boolean } = fieldType;
  const formField = {
    radioInput: (args) => <FieldRadioInput {...args} />,
    textInput: (args) => <FieldTextInput {...args} />,
    text: (args) => {
      const newObj = _.omit(args, 'label');
      const result = <TemplateLabel {...newObj} isRequired isColumn multilineTexts={[args?.label]} blackText></TemplateLabel>;
      return result;
    }
    
  }
  const fieldByType = {
    radioInput: boolean(),
    textInput: string(),
  }
  const getFieldValidation = (field: Field) => {
    const fieldValidation = nullable(fieldByType[field.component])
    if (field.notRequired) return isOptional(fieldValidation);
    return isRequired(fieldValidation);
  }
  const getFields = () => {
    if (part === 1) return fieldsPart1;
    if (part === 2) return fieldsPart2;
    if (part === 3) return fieldsPart3;
  }
  const getFieldsForm = () => {
    return getFields().map((field) => ({
      mainItem: formField[field.title.component](field.title),
      subItems: field.subItems.map((subField, index) => {
        return formField[subField.component](subField)
      })
    }))
  }
  const getSchema = () => {
    const fields = getFields().flatMap(f => [f.title, ...f.subItems]).filter(f => f.component !== 'text')
    let schema = object()
    fields.forEach((field: Field) => {
      schema = addField(schema, field.name, getFieldValidation(field))
    })
    return schema;
  }
  return { getFieldsForm, getSchema };
}

export default useGetFields;
import Machine from "../../../../../generic/components/Machine/MachineClass";
import { PreSubmittedStep, WhatsNextStep, FormingStep } from "./Steps";
import { assign } from 'xstate'
import _ from 'lodash'


const setViewStep = assign((contex: any) => {
  const { steps, actualStep } = contex.paramsData;
  const newSteps = steps.map((step, index) => index === actualStep && !step.viewed ? { ...step, viewed: true } : step);
  return { ...contex, paramsData: { actualStep, steps: newSteps } };
});

const setStep = assign((contex: any, payload) => {
  const { actualStep, ...rest } = contex.paramsData;
  return { ...contex, paramsData: { ...rest, actualStep: actualStep + 1 } };
});

const incrementStep = assign((contex: any, { payload }: any) => {
  const count = _.get(payload, 'values.count', 1);
  const { actualStep, ...rest } = contex.paramsData;
  return { ...contex, paramsData: { ...rest, actualStep: actualStep + count } };
});

const decrementStep = assign((contex: any, { payload }: any) => {
  // debugger

  const count = _.get(payload, 'values.count', 1);
  const { actualStep, ...rest } = contex.paramsData;
  return { ...contex, paramsData: { ...rest, actualStep: actualStep - count } };
});

const machineDefinition = {
  id: "ADGM_submitted_formation",
  initial: "preSubmitted",
  states: {
    step_handler: {

    },

    preSubmitted: {
      id: 0,
      entry: 'setViewStep',
      on: {
        NEXT: { target: "whatsNext", actions: ['setContext', 'incrementStep'] },
        CANCEL: "onDone",
      },
    },
    whatsNext: {
      id: 1,
      entry: 'setViewStep',
      on: {
        NEXT: { target: "forming", actions: ['setContext', 'incrementStep'] },
        PREVIOUS: { target: "preSubmitted", actions: 'decrementStep' },
        CANCEL: "onDone",
      },
    },
    forming: {
      on: {
        NEXT: { target: "finish_submitted_formation", actions: "setContext" },
        CANCEL: "onDone",
      },
    },
    finish_submitted_formation: {
      type: "final",
      entry: "final",
    },
    onDone: {
      type: 'final',
      entry: 'close',
    },
  },
};

const steps = {
  preSubmitted: PreSubmittedStep,
  whatsNext: WhatsNextStep,
  forming: FormingStep,
};

const options = {
  actions: {
      setViewStep,
      setStep,
      decrementStep,
      incrementStep,
  }
}

export default class MachineSumittedFormation extends Machine {
  constructor() {
    super(machineDefinition, options, steps);
}
  getParamsData(values ) {
    return {...values.params,groupCompanyId: null};
  }
  
}

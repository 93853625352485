import React,{FC,useState,useEffect} from 'react'
import Modal from '../Default'
import classesModule from './classes.module.scss'
import {ModalProps} from "./../Default"

export interface ModalOverlayFullProps extends ModalProps {
 className?: string;
}

const ModalOverlayFull: FC<ModalOverlayFullProps>= ({children,open:openParam,className,onClose,...props}:ModalOverlayFullProps) => {
 
  const [open, setOpen] = useState(openParam);

  useEffect(()=>{
    setOpen(openParam);
  },[openParam])
  const handleClose = (e,r)=>{
    if (r == "backdropClick"){
      return false;
    }
    setOpen(false)
    onClose && onClose(e,r);
  }


  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown={true}
      // className={className}
      maxWidth={'xl'}
    >
      {children}
    </Modal>
  )
}

export default ModalOverlayFull

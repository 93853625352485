import { MenuItem } from '@mui/material';
import React, { useEffect, useState, FC } from 'react';
import { DropDown } from 'src/components/generic/Menues';
import ClaraAvatar from 'src/modules/generic/components/Avatars/AvatarLabel';
import classes from '../ChooseDocumentsView.module.scss';
import useRegion from '../../hooks/useRegion';

interface Props {
    onChange: (option: any) => void;
    filterSelected: any;
}
// TODO: FIX PROP TYPES
const DropdownRegionFilter:FC<Props> = ({ onChange, filterSelected }) => {
        const [selected, setSelected] = useState();
        const {regions,getAvatarByCode,getRegion,loadingRegions} = useRegion()
      
        const handleChange = (option) => {
          setSelected(option.code);
          if (onChange) {
            onChange(option.code);
          }
        }
 
        const regionFilterOptions = regions.map(r=>(
            <MenuItem onClick={() => handleChange(r)}>
              <ClaraAvatar name={r.name} size='1.8rem' avatar={getAvatarByCode(r.code)} showLabel={false} />
              <label className={classes.labelDropDown}>{r.name}</label>
            </MenuItem>
        ))

        useEffect(() => {
          setSelected(filterSelected);
        }, [filterSelected]);
        
        const region = getRegion(selected)
        
      
        return (
          <div className={classes.DropDownArea}>
            <DropDown options={regionFilterOptions} addSpace={true}>
              <ClaraAvatar name={region?.name} size='1.8rem' avatar={getAvatarByCode(region?.code)} showLabel={false} className={classes.centerFilterAvatar} />
              <label className={classes.labelDropDown}>{region?.name}</label>
            </DropDown>
          </div>
        );
};

export default DropdownRegionFilter;
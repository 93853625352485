import _ from 'lodash';
import useMachine from '../../../../../generic/context/MachineContext/useMachine';
import FormSourceOfFunds from "../../../Forms/FormSourceOfFunds";
import React, { useEffect, useState } from 'react';
import Button from "../../../../../generic/components/Buttons/Button";
import useTranslate from '../../../../../generic/hooks/useTranslate';
import { useDispatch } from "react-redux";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import { addAssistantText, clearAssistatText } from "../../../../../generic/store/action";
import useModal from "../../../../../generic/hooks/useModal";

/**
 * Step para editar los datos de SOF ADGM
 * @param {*} param0
 * @returns
 */
function SelectJurisdiction({ openParam }) {

  const { next, send, context, prev } = useMachine();
  const { translate } = useTranslate()
  const dispatch = useDispatch();
  const { closeModal: onClose } = useModal();
  const [open, setOpen] = useState(openParam);
  const defaultData = _.find(context.getJurisdiction.sourcesOfFunds, (o) => { return o.jurisdictionType === 'ADGM' })
  const [changed, setChanged] = useState(false);
  const [hasPrev, setHasPrev] = useState(false);

  const handleCancel = (values) => {
    onClose();
    setOpen(false);
    clearAssistatText()
  }

  useEffect(() => {
    setOpen(openParam);
  }, [openParam]);

  useEffect(() => {
    let countValids = 0;
    _.forEach(_.get(context, 'getJurisdiction.jurisdiction'), (jurisdiction) => {
      if (jurisdiction) {
        countValids++;
      }
    });
    const result = countValids >= 2;
    setHasPrev(result);
  }, []);

  const handlePrevious = () => {
    prev()
  }

  const handleInitModal = () => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText(translate('MODULES.STAKEHOLDER.SOURCE_OF_FUNDS.TOOLTIP')))
  }

  useEffect(() => {
    handleInitModal()
    return () => {
      dispatch(clearAssistatText());
    }
  }, []);

  const handleCompleteSubmit = async (values) => {
    values.jurisdictionType = 'ADGM';
    next(values)
  }

  return (
    <FormSourceOfFunds
      buttonSubmitProps={{ handleDisabled: () => { return !changed } }}
      initialValues={defaultData}
      isEdit={true}
      buttons={{
        previous: (hasPrev && <Button onClick={handlePrevious} variant="secondary">{translate('GENERIC_BUTTON_PREVIOUS')}</Button>),
        cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
      }}
      onCompleteSubmit={handleCompleteSubmit}
      propsTemplate={{
        title: translate('MODULES.STAKEHOLDER.SOURCE_OF_FUNDS.TITLE'),
        subTitle: translate('MODULES.STAKEHOLDER.SOURCE_OF_FUNDS.SUBTITLE')
      }} Template={TemplateWizard}
      hasChanged={() => { setChanged(true) }}
    />
  );
}
export default SelectJurisdiction;

import useCustomMachine from "../../../../../generic/hooks/useCustomMachine";
import React, { FC, useEffect } from "react";
import Button from "../../../../../generic/components/Buttons/Button";
import { StepProps } from "../../../../../generic/components/Wizard/Step";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import { useDispatch } from "react-redux";
import {
  addAssistantText,
  clearAssistatText,
} from "../../../../../generic/store/action";
import FormIsThereCompaniesShares from "../../../Forms/FormIsThereCompaniesShares";

const Step1: FC<StepProps> = () => {
  const { translate } = useTranslate();
  const { next, send, state, stepData } = useCustomMachine();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearAssistatText());
    dispatch(
      addAssistantText(
        translate(
          "MODULES.GROUP_COMPANY.ASSETS_AND_REVENUES.FIRST_STEP.TOOLTIP_1"
        )
      )
    );
    dispatch(
      addAssistantText(
        translate(
          "MODULES.GROUP_COMPANY.ASSETS_AND_REVENUES.FIRST_STEP.TOOLTIP_2"
        )
      )
    );
  }, []);

  const handleCompleteSubmit = async (values) => next(values);

  const handleCancel = async () => send("CANCEL");

  return (
    <FormIsThereCompaniesShares
      initialValues={stepData}
      onCompleteSubmit={handleCompleteSubmit}
      Template={TemplateWizard}
      propsTemplate={{
        title: translate(
          "MODULES.GROUP_COMPANY.ASSETS_AND_REVENUES.FIRST_STEP.TITLE"
        ),
        subTitle: translate(
          "MODULES.GROUP_COMPANY.ASSETS_AND_REVENUES.FIRST_STEP.SUBTITLE"
        ),
      }}
      buttons={{
        cancel: (
          <Button onClick={handleCancel} variant="secondary">
            Cancel
          </Button>
        ),
      }}
    />
  );
};

export default Step1;

import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import _ from "lodash";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import Button from "src/modules/generic/components/Buttons/Button";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import {
  addAssistantText,
  clearAssistatText,
} from "src/modules/generic/store/action";
import FormIsThereCompaniesFormationsShares from "src/modules/startup/GroupCompany/Forms/FormIsThereCompaniesFormationsShares";

function SharesInCompaniesNotIncorporatedForm() {
  const { translate } = useTranslate();
  const { next, cancel, prev, context } = useMachine();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.SharesInCompaniesNotIncorporatedForm ?? {};
  }, [context]);

  useEffect(() => {
    dispatch(clearAssistatText());
    
  }, []);


  const handleSubmit = async (values) => {
    next(values);
  };

  const handleCancel = () => {
    cancel();
  };

  const handlePrev = () => {
    prev();
  };

  return (
    <>
      <FormIsThereCompaniesFormationsShares
        initialValues={initialValues}
        onCompleteSubmit={handleSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: translate(
            "MODULES.GROUP_COMPANY.ASSETS_AND_REVENUES.FIFTH_STEP.TITLE"
          ),
          subTitle: "Review company details",
        }}
        buttons={{
          previous: (
            <Button onClick={handlePrev} variant="secondary">
              Previous
            </Button>
          ),
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              Cancel
            </Button>
          ),
        }}
      />
    </>
  );
}

export default SharesInCompaniesNotIncorporatedForm;

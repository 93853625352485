import download from 'downloadjs'
import { Constants, ServerConnect } from '../../../../../v1/utils';

function useDownloadBusinessPlanTemplate() {
    const downloadBusinessPlanTemplate = async () => {
        const query = `{ getCDNAccess(assetId:"${Constants.CONTENTFUL.ASSETS.ADGM_TSL_BUSINESS_PLAN_TEMPLATE}") }`
        let { getCDNAccess } = await ServerConnect.graphQlQuery(query);
        let contentfulJson = await (await fetch(getCDNAccess)).json();
        let file = await (await fetch(`https:${contentfulJson.fields.file.url}`)).blob();
        download(file, contentfulJson.fields.file.fileName, contentfulJson.fields.file.contentType);
    }

    return ({ downloadBusinessPlanTemplate });
}

export default useDownloadBusinessPlanTemplate;

import { assign } from "xstate";
import Machine from "../../../generic/components/Machine/MachineClass";
import { StepSelectShareOptions, StepSelectCompanyShareOptions, StepShareOptions, StepCreateGrantAgreement, StepUploadGrantAgreement, StepSelectGrantAgreement, StepGenerateGrantAgreement, } from './Steps';

const cancelCreation = ({ data }) => data.select_share_option.mode === 'CANCEL';

const isCreatedShareOption = ({ data }) => data.select_share_option.mode === 'YES_CREATED';

const isUploadLaterGrantAgreement = ({ data }) => data.document_grant_agreement.mode === 'UPLOAD_LATER';

const isUploadNowGrantAgreement = ({ data }) => data.document_grant_agreement.mode === 'YES_UPLOAD';

const isSelectGrantAgreement = ({ data }) => data.document_grant_agreement.mode === 'SELECT_DOCUMENT';

const isMoreShareOptions = ({ numbersOfIteration, companies }) => {
    return numbersOfIteration !== companies.length
};

const isGenerateGrantAgreement = ({ data }) => {
    return  data.select_share_option.mode === 'GENERATE_GRANT_AGREEMENT'
}

const finishCreation = (value) => !isMoreShareOptions(value);

const setCompanies = assign((context: any) => {
    const { data } = context;
    return {
        ...context,
        companies: data.select_companies.company
    }
})



const machineDefinition = {
    id: 'created_share_option',
    initial: 'select_share_option',
    states: {
        select_share_option: {
            on: {
                NEXT: { target: 'creating', actions: 'setContext' },
                CANCEL: 'finish_created_share_option',
            },

        },
        creating: {
            always: [
                { cond: 'cancelCreation', target: 'finish_created_share_option' },
                { target: 'select_companies', cond: 'isCreatedShareOption' },
                { cond: 'isGenerateGrantAgreement', target: 'has_sips_share_options' },
            ]
        },
        has_sips_share_options: {
            on: {
                NEXT: { target: 'finish_created_share_option' },
                CANCEL: 'finish_created_share_option',
            },
        },
        select_companies: {
            on: {
                NEXT: { target: 'details_share_options', actions: ['setContext', 'setCompanies'] },
                CANCEL: 'finish_created_share_option',
            }
        },
        details_share_options: {
            on: {
                NEXT: { target: 'document_grant_agreement', actions: 'setContext' },
                CANCEL: 'finish_created_share_option',
            }
        },
        document_grant_agreement: {
            on: {
                NEXT: { target: 'processing_document_agreement', actions: 'setContext' },
                CANCEL: 'finish_created_share_option',
            }
        },
        processing_document_agreement: {
            always: [
                { cond: 'isUploadLaterGrantAgreement', target: 'is_more_share_options' },
                { target: 'upload_grant_agreement', cond: 'isUploadNowGrantAgreement' },
                { cond: 'isSelectGrantAgreement', target: 'select_grant_agreement' },
            ]
        },
        upload_grant_agreement: {
            on: {
                NEXT: { target: 'is_more_share_options', actions: 'setContext' },
                CANCEL: 'finish_created_share_option',
                PREVIOUS: 'document_grant_agreement'
            }
        },
        select_grant_agreement: {
            on: {
                NEXT: { target: 'is_more_share_options', actions: 'setContext' },
                CANCEL: 'finish_created_share_option',
                PREVIOUS: 'document_grant_agreement'
            }
        },

        is_more_share_options: {
            entry: 'setGlobal',
            always: [
                { cond: 'isMoreShareOptions', target: 'details_share_options' },
                { cond: 'finishCreation', target: 'finish_created_share_option' },
            ]
        },
        finish_created_share_option: {
            type: 'final',
            entry: 'final',
        },
        onDone: {
            actions: 'stopMachine'
        }
    },
}
const steps = {
    select_share_option: StepSelectShareOptions,
    select_companies: StepSelectCompanyShareOptions,
    details_share_options: StepShareOptions,
    document_grant_agreement: StepCreateGrantAgreement,
    upload_grant_agreement: StepUploadGrantAgreement,
    select_grant_agreement: StepSelectGrantAgreement,
    has_sips_share_options: StepGenerateGrantAgreement
}

const options = {
    guards: {
        cancelCreation,
        isCreatedShareOption,
        isUploadLaterGrantAgreement,
        isUploadNowGrantAgreement,
        isSelectGrantAgreement,
        isMoreShareOptions,
        finishCreation,
        isGenerateGrantAgreement
    },
    actions: {
        setCompanies
    }
}

export default class MachineProfileStakeholder extends Machine {
    constructor() {
        super(machineDefinition, options, steps);
    }

    getParamsData(values) {
        return values;
    }
}

import useSession from "src/modules/session/hooks/useSession";

const useGroupCompanyMapper = () => {
  const { startupId } = useSession();

  const getSubmitData = (context, groupCompanyId) => {
    const {
      ReviewCompanyDetailsStep,
      WillHaveSharesIncorporatedStep,
      SharesInCompaniesNotIncorporatedForm,
      ReviewSharesInCompaniesNotIncorporated,
      ReviewSharesInIncorporatedCompanies,
    } = context;

    const {
      name,
      licenseType,
      type,
      businessActivities,
    } = ReviewCompanyDetailsStep;

    const variables = {
      groupCompanyId,
      startupId,
      groupCompany: {
        name,
        licenseType,
        type,
        businessActivities,
        assetsAndRevenue: {
          ...context.initialData.groupCompany.assetsAndRevenue,
          sharesInIncorporatedCompanies: context.initialData.groupCompanies.map(
            (gc) => gc.id
          ),
        },
      },
    };

    const changeSharesIncorporatedCompanies = ReviewSharesInIncorporatedCompanies ? (!ReviewSharesInIncorporatedCompanies.accept) : false;
    const changeSharesNotInCompanies = ReviewSharesInCompaniesNotIncorporated ? (!ReviewSharesInCompaniesNotIncorporated.accept) : false;

    if (changeSharesIncorporatedCompanies) {
      if (WillHaveSharesIncorporatedStep.isThereCompaniesShares) {
        variables.groupCompany[
          "assetsAndRevenue"
        ].sharesInIncorporatedCompanies = context.incorporatedCompanies;
      } else {
        variables.groupCompany[
          "assetsAndRevenue"
        ].sharesInIncorporatedCompanies = [];
      }
    }

    if (changeSharesNotInCompanies) {
      variables.groupCompany[
        "assetsAndRevenue"
      ].isSharesInNotYetFormedCompanies = {
        question:
          SharesInCompaniesNotIncorporatedForm?.isThereCompaniesFormationsShares,
        description:
          SharesInCompaniesNotIncorporatedForm?.formationCompaniesHavesShares,
      };
    }

    return variables;
  };

  return { getSubmitData };
};

export default useGroupCompanyMapper;

import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import Form from 'src/components/forms/Form/Form';
import TextInput from 'src/components/inputs/Text/TextInput';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateLabel from 'src/modules/generic/components/Inputs/LabelInput';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText,addAssistantText } from 'src/modules/generic/store/action';
import CountryJurisdiction from "src/modules/generic/components/CountryJurisdictionSelector";

function ReviewCorporateStakeholderDetailsStep() {
  const { next, context,cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  
  const initialValues = context?.ReviewDetails ?? context.initialData.stakeholder
  
  const handleCancel = () => {
    cancel();
  }

  const handleSubmit = async (values) => {
    next(values);
  }
  
  useEffect( () => {
    dispatch(addAssistantText(translate("MODULES.RENEWALS_ADGM.REVIEW_STAKEHOLDER_DETAILS.CORPORATE.COMMON.TOOLTIP"),'alert'));
    return () => {
      dispatch(clearAssistatText());
    }
  },[dispatch]);
  
  const schema = Yup.object().shape({
    fullName: Yup.string().nullable().required('This field is required'),
    previousName: Yup.string().nullable(),
    nationality: Yup.string().required("This field is required.").nullable(),
    jurisdiction: Yup.string().required('This field is required.').typeError('This field is required.'),
    registeredNumber: Yup.string().nullable().required('This field is required'),
  });

  const stakeholderName: string = initialValues.fullName;

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={ {
          title: translate("MODULES.RENEWALS_ADGM.REVIEW_STAKEHOLDER_DETAILS.CORPORATE.DETAILS.TITLE"),
          subTitle: translate("MODULES.RENEWALS_ADGM.REVIEW_STAKEHOLDER_DETAILS.CORPORATE.COMMON.SUBTITLE", { stakeholderName }),
          skeleton: null,
          variant: 'simple'
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton>{translate('BUTTON_NEXT')}</SubmitButton>,
        }}
      >
        <TemplateLabel label="Company name" isRequired>
          <ControllerInput
            name="fullName"
            render={TextInput}
            placeholder={translate('MODULES.CLARA.TYPE_HERE_HIT')}
          />
        </TemplateLabel>
        <TemplateLabel label="Previous names (if applicable)" >
          <ControllerInput
            name="previousName"
            render={TextInput}
            placeholder={translate('MODULES.CLARA.TYPE_HERE_HIT')}
          />
        </TemplateLabel>
        <CountryJurisdiction name="nationality" />
        <TemplateLabel label="Company number" isRequired>
          <ControllerInput
            name="registeredNumber"
            render={TextInput}
            placeholder={translate('MODULES.CLARA.TYPE_HERE_HIT')}
          />
        </TemplateLabel>
        <div>&nbsp;</div>
      </TemplateWizard>
    </Form>
  );
}

export default ReviewCorporateStakeholderDetailsStep;
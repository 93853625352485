import React from 'react';
import { ClaraUpload } from '../../../v1/components';

const FileUploaderInput = ({ name = 'filepond', initVal, required, isArrayValue, onChange, multipleFiles, ...props }) => {

  return (
    <React.Fragment>
      <ClaraUpload
        isArrayValue={isArrayValue}
        required={required}
        initVal={initVal}
        name={name}
        onChange={onChange}
        multipleFiles={multipleFiles}
        {...props}
      />
    </React.Fragment >
  )
}

export default FileUploaderInput;
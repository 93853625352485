import React from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";
const EditEWarrant = ({go,...props}) => {
  const params = useParams();
  

  return (

    <MicroFrontend id="MF_Warrants_Content_EDIT" component={"./EditWarrants"} mf="capTable" params={{ go, ...props,...params }} />
  );
};

export default EditEWarrant;

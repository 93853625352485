import React, { Component } from "react";
import { connect } from "react-redux";
import { Constants } from '../../../../utils/constants';

import ShareEdit from "./ShareEdit";
import OptionEdit from "./OptionEdit";
import WarrantsEdit from "./WarantsEdit";
import ConvertiblesEdit from "./ConvertiblesEdit";

import classes from '../EditForm.module.scss';
import { Formik } from "formik";
import _ from "lodash";
import "react-datepicker/dist/react-datepicker.css";
import FormikHelper from "../../../../utils/formikHelper";
import moment from "moment";
import { goToPage } from "../../../../../store/actions/page";


class Document extends Component {

    constructor(props) {
        super(props);
        this.state = {
            c: 0,
            documents: [],
            classes: [],
            shareIncentivePlans: [],
            issuedDate: null,

        }
    }

    componentWillMount() {
        const { data, groupCompanies } = this.props;
        if (data.type === "SHARE" || data.type === "OPTION") {
            this.setState({
                classes: this.mapClassesFromGroupCompanies(groupCompanies),
                shareIncentivePlans: this.mapShareIncentivePlansFromGroupCompanies(groupCompanies)
            })
        }
        this.setState({
            documents: this.mapDocumentsFromGroupCompanies(groupCompanies),
            issuedDate: this.getDateForPickerFromData(data.issuedDate)
        })
    }

    mapDocumentsFromGroupCompanies(groupCompanies) {

        const documents = [];

        groupCompanies.forEach(gc => {
            if (gc.documents) {
                gc.documents.forEach(doc => {
                    if (_.findIndex(documents, document => document.id === doc.id) < 0) {
                        documents.push({
                            id: doc.id,
                            label: doc.name
                        })
                    }
                });
            }
        });

        return documents;
    }

    mapClassesFromGroupCompanies(groupCompanies) {
        const classes = [];

        groupCompanies.forEach(gc => {
            if (gc.shareClass) {
                gc.shareClass.forEach(sc => {
                    classes.push({
                        id: sc.id,
                        label: sc.name,
                        default: sc.defaultShare,
                        groupCompanyId: gc.id
                    })
                });
            }
        });

        return classes;
    }

    getDateForPickerFromData(date) {
        if (!date) return null;
        return moment(date).toDate();
    }

    //-------SHARE OPTION
    mapShareIncentivePlansFromGroupCompanies(groupCompanies) {
        const shareIncentivePlans = [];
        groupCompanies.forEach(gc => {
            if (gc.shareClass) {
                _.forEach(gc.shareClass, share => {
                    _.forEach(share.shareIncentivePlans, s => {
                        shareIncentivePlans.push({
                            id: s.id,
                            label: s.name,
                            shareClassId: share.id,
                            shareClassName: share.name,
                            gc: gc.name
                        })
                    })
                });
            }
        });

        return shareIncentivePlans;
    }

    //--------------------------

    selectForm(type) {

        switch (type) {
            case "SHARE":
                return (
                    <React.Fragment>
                        <ShareEdit
                            groupCompanies={this.props.groupCompanies}
                            stakeholders={this.props.stakeholders}
                            documents={this.state.documents}
                            shareIncentivePlans={this.state.shareIncentivePlans}
                            classes={this.state.classes}
                            startupId={this.props.session.startup.id}
                            issuedDate={this.state.issuedDate}

                        />
                    </React.Fragment>
                )
            case "OPTION":
                return (
                    <React.Fragment>
                        <OptionEdit
                            groupCompanies={this.props.groupCompanies}
                            stakeholders={this.props.stakeholders}
                            documents={this.state.documents}
                            classes={this.state.classes}
                            shareIncentivePlans={this.state.shareIncentivePlans}
                            startupId={this.props.session.startup.id}
                            issuedDate={this.state.issuedDate}
                        />
                    </React.Fragment>
                );
                break;
            case "WARRANT":
                return (
                    <React.Fragment>
                        <WarrantsEdit
                            groupCompanies={this.props.groupCompanies}
                            stakeholders={this.props.stakeholders}
                            documents={this.state.documents}
                            startupId={this.props.session.startup.id}
                            issuedDate={this.state.issuedDate}
                        />
                    </React.Fragment>
                );
                break;
            case "CONVERTIBLE":
                return (
                    <React.Fragment>
                        <ConvertiblesEdit
                            groupCompanies={this.props.groupCompanies}
                            stakeholders={this.props.stakeholders}
                            documents={this.state.documents}
                            startupId={this.props.session.startup.id}
                            issuedDate={this.state.issuedDate}
                        />

                    </React.Fragment>
                );
                break;

            default:
                break;
        }
    }

    changeMode = () => {
        this.props.goToPage(Constants.PAGES.viewEquity, { ...this.props.data, type: this.props.type });
    }
    onSubmit = (values) => {
        this.props.updateForm({ ...values, equityData: { ...values.equityData, documents: values.documents[0] } }, this.state.classes);
    };

    isDisabled(errors, touched) {
        return Object.keys(errors).length > 0;
    }

    handleChange(e, field, form) {

        const date = e === null ? null : moment(e).format("YYYY-MM-DD");

        FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

        this.setState({
            [field.name]: e
        })
    }


    render() {
        const { data } = this.props;


        return (
            <div className={classes.ContainerEdit}>
                <Formik
                    initialValues={data}
                    onSubmit={this.onSubmit}
                    render={({ values, handleSubmit, dirty, isSubmitting, errors, touched, handleReset, setFieldValue }) => {
                        return (
                            <form onSubmit={handleSubmit} className={classes.Form}>
                                <React.Fragment>
                                    {this.selectForm(this.props.type)}
                                </React.Fragment>
                                <div className={classes.Botonera}>
                                    <button type='submit' data-cy="submit" disabled={this.isDisabled(errors, touched)}>
                                        Save Changes
                                    </button>
                                    <button
                                        type='button' onClick={() => {
                                            this.changeMode()
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        )

                    }}
                >
                </Formik>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    session: state.session,

});
const mapDispatchToProps = (dispatch) => ({

    goToPage: (page, params) => {
        goToPage(dispatch, page, params)
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(Document)

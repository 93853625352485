import useMutation from "../../../../hooks/custom/useMutation";

export const UPSERT_COMPANY_FORMATION = `
mutation upsertFormationCompany(
    $startupId: ID!
    $groupCompanyId: ID
    $groupCompanyData: FormationCompanyInputType
    $goal: String

  ) {
    upsertFormationCompany(
      startupId: $startupId
      groupCompanyId: $groupCompanyId
      groupCompanyData: $groupCompanyData
      goal:$goal
    ) {
      id
      name
    }
  }
  
`;

const useUpsertCompanyFormation = () => {
    const [mutation, loaging] = useMutation(
        UPSERT_COMPANY_FORMATION,
        { showSuccessNotification: false }
    ); 
    return mutation;
};

export default useUpsertCompanyFormation;

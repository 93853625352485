
import Machine from "../../../../generic/components/Machine/MachineClass";
import FirstStep from "./steps/FirstStep";
import SecondStep from "./steps/SecondStep";

const confirmDirectorsShareholders = (data): boolean => {
  return data.confirm_directors_shareholderes_form.isAllShareholdersAndDirectorsConfirmed;
}

const notUniqueDirectorAndJurisditionADGM = (paramsData): boolean => {
  return  paramsData.useThisJurisdiction === "ADGM";
}

const notUniqueDirectorAndJurisditionCAY = (paramsData): boolean => {
  return  paramsData.useThisJurisdiction === "CAYMAN";
}

const continueConfirm = ({ data, paramsData }): boolean => {
  if (paramsData.useThisJurisdiction === "ADGM") {
    return confirmDirectorsShareholders(data) && notUniqueDirectorAndJurisditionADGM(paramsData);
  }
  if (paramsData.useThisJurisdiction === "CAYMAN") {
    return confirmDirectorsShareholders(data) && notUniqueDirectorAndJurisditionCAY(paramsData);
  }
};

const notContinueConfirm = ({ data, paramsData }): boolean => {
  return !continueConfirm({ data, paramsData });
};

const machineDefinition = {
  initial: 'confirm_directors_shareholderes_form',
  states: {
    confirm_directors_shareholderes_form: {
      on: {
        NEXT: { target: 'loading_confirm', actions: 'setContext' },
        CANCEL: 'finish_confirm_directors_shareholders'
      }
    },
    loading_confirm: {
      always: [
        { cond: 'notContinueConfirm', target: 'finish_confirm_directors_shareholders'},
        { cond: 'continueConfirm', target: 'select_director_share_certificate' },
      ]
    },
    select_director_share_certificate: {
      on: {
        NEXT: { target: 'finish_confirm_directors_shareholders', actions: 'setContext' },
        PREVIOUS: 'confirm_directors_shareholderes_form',
        CANCEL: 'finish_confirm_directors_shareholders'
      }
    },
    finish_confirm_directors_shareholders: {
      type: 'final',
      entry: 'final',
    },
    onDone: {
      actions: 'stopMachine'
    }
  }
}
const options = {
  guards: {
    continueConfirm,
    notContinueConfirm,
  }
}

const steps = {
  confirm_directors_shareholderes_form: FirstStep,
  select_director_share_certificate: SecondStep,
}
class MachineConfirmShareholdersDirectors extends Machine {
  constructor() {
    super(machineDefinition, options, steps);
  }
  getParamsData({ paramsMutation }: { paramsMutation: object }) {
    return { ...paramsMutation }
  }
  getInitialValues({ initialValues = {} }: { initialValues: unknown }) {
    return initialValues
  }
}

export default MachineConfirmShareholdersDirectors
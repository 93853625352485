/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import _ from "lodash";
import useBusinessRules from "./../../../modules/clara/hooks/useBusinessRules"
import GroupCompany from 'src/components/modules/clara/startups/entities/classes/GroupCompany';
import Stakeholder from 'src/components/modules/clara/startups/entities/classes/Stakeholder';

//TODO: 
// - remove classesModule
// - remove reload
// - read dataProps by ref
// - replace setTimeout

const useMapLines = ({
  groupCompanies,
  stakeholders,
  roles,
  dataPropsGroupCompany,
  dataPropsRol,
  dataPropsStakeholder,
  groupCompaniesRef,
  rolesRef,
  stakeholdersRef,
  maxLevels,
  classesModule,
  filters,
  finishToLoadCards,
}) => {

  const [reload, setReload] = useState(0);
  const [aux, setAux] = useState(1);
  const [lines, setLines] = useState({});
  const { isGroupCompanyInFormation } = useBusinessRules();

  const hasEnabledStakeholder = (stakeholdes) => {
    const stakeholder = _.find(stakeholdes, (value, keySH) => {
      const stakeholder = stakeholders[keySH];
      if (stakeholder && filters.stakeholder && stakeholder.id !== filters.stakeholder) {
        return false;
      }
      if (!filters.text || filters.text === "") {
        return true;
      }
      return stakeholder.fullName.toLowerCase().indexOf(filters.text.toLowerCase()) >= 0;
    })
    return !!stakeholder;
  }

  const determineStatusByRole = (shareholderData, rolId) => {
    let result = "ACTIVE";
    const allRoles = shareholderData?.roles?.[rolId];
    const allInactive = shareholderData?.inactiveRoles?.[rolId]
    result = _.isEqual(allRoles, allInactive) ? "INACTIVE" : "ACTIVE";
    return result;
  }

  const determineStatus = (rol, stakeholders, gcId, newLines) => {
    let stakeholdersToEvaluate = [];
    const hasFilter = filters?.stakeholder || filters.rol || filters.groupCompany || filters.text;
    if (newLines && hasFilter) {
      let allGc = [];
      let allRoles = [];
      let allStakeholders = [];
      _.forEach(newLines, (line, key) => {
        if (line?.visible) {
          const gc = line?.params?.groupCompanies;
          const roles = line?.params?.rol;
          const sh = line?.params?.stakeholders;
          allGc = _.union(allGc, gc);
          allRoles = _.union(allRoles, roles);
          allStakeholders = _.union(allStakeholders, sh);
        }
      });
      _.forEach(stakeholders, (stk, stkId) => {
        if (allStakeholders.includes(stkId)) {
          stakeholdersToEvaluate.push(stk);
        }
      });
    } else {
      stakeholdersToEvaluate = stakeholders;
    }
    let stakeholderCount = 0;
    let inactiveCount = 0;
    _.forEach(stakeholdersToEvaluate, (stk, stkId) => {
      const hasRole = stk?.roles?.[rol]?.[gcId] ?? false;
      const hasInactive = stk?.inactiveRoles?.[rol]?.[gcId] ?? false;
      if (hasRole) {
        stakeholderCount++;
        if (hasInactive) {
          inactiveCount++;
        }
      }
    });
    return stakeholderCount === inactiveCount ? "INACTIVE" : "ACTIVE";
  }

  const firstFunction = useCallback(() => {

    if (
      (!_.isEmpty(groupCompanies) || !_.isEmpty(stakeholders))
    ) {
      if (finishToLoadCards) {
        let newLines = {};
        _.forEach(groupCompanies, (gc, gcId) => {
          _.forEach(gc.roles, (rol, rolId) => {
            const generalStatus = determineStatus(rolId, stakeholders, gcId);
            newLines[`gc_${gcId}_rol_${rolId}`] = ({
              from: groupCompaniesRef.current[gcId],
              to: rolesRef.current[rolId],
              classId: rolId,
              visible: false,
              type: "curve",
              dotter: isGroupCompanyInFormation(gc),
              status: generalStatus
            });
            newLines[`gc_${gcId}_rol_${rolId}`]["params"] = {
              groupCompanies: [gcId],
              rol: [rolId],
              stakeholders: []
            }
            _.forEach(rol, (sh, shId) => {
              const shareholderData = stakeholders[shId];
              if (shareholderData) {
                newLines[`rol_${rolId}_sh_${shId}`] = ({
                  from: rolesRef.current[rolId],
                  to: stakeholdersRef.current[shId],
                  classId: rolId,
                  visible: false,
                  type: "curve",
                  status: determineStatusByRole(shareholderData, rolId),
                });
                if (newLines[`rol_${rolId}_sh_${shId}`]['dotter'] === undefined) {
                  newLines[`rol_${rolId}_sh_${shId}`]['dotter'] = true;
                }
                if (newLines[`rol_${rolId}_sh_${shId}`]['dotter'] === true && !isGroupCompanyInFormation(gc)) {
                  newLines[`rol_${rolId}_sh_${shId}`]['dotter'] = false;
                }
                if (!newLines[`rol_${rolId}_sh_${shId}`]['dotter']) {
                  const gcClass = new GroupCompany(gc);
                  const shClass = new Stakeholder(stakeholders[shId]);
                  if (gcClass.isManagedByClara() && shClass.isRolInPincas(rolId)) {
                    newLines[`rol_${rolId}_sh_${shId}`]['dotter'] = true;
                  }
                }
                newLines[`rol_${rolId}_sh_${shId}`]["params"] = {
                  groupCompanies: [],
                  rol: [rolId],
                  stakeholders: [shId]
                }
              }
            })
          })
          _.forEach(gc.childrens, (children, key) => {
            const status = (groupCompanies && groupCompanies[children.id] && groupCompanies[children.id].isActive) ? "ACTIVE" : "INACTIVE";
            newLines[`gc_${gcId}_gc_${children.id}`] = ({
              isFirst: key === 0 && groupCompanies[gcId].level !== groupCompanies[gcId].level,
              percent: parseInt(children.percent * 10000) / 100,
              from: groupCompaniesRef.current[gcId], to: groupCompaniesRef.current[children.id], classId: "groupCompany", visible: true, type: "l",
              dotter: isGroupCompanyInFormation(gc) || isGroupCompanyInFormation(children),
              level: {
                from: groupCompanies[gcId].level > maxLevels ? maxLevels : groupCompanies[gcId].level,
                to: groupCompanies[children.id].level > maxLevels ? maxLevels : groupCompanies[children.id].level,
              },
              drawLines: groupCompanies[gcId].level <= maxLevels && groupCompanies[children.id].level <= maxLevels,
              status,
            });
            newLines[`gc_${gcId}_gc_${children.id}`]["params"] = {
              groupCompanies: [gcId, children.id],
              rol: [],
              stakeholders: []
            }
          });
        })
        setReload(reload + 1);
        setLines(newLines);
      }
    } else {
      setLines({})
    }
  }, [
    JSON.stringify(groupCompanies),
    JSON.stringify(stakeholders),
    JSON.stringify(roles),
    groupCompaniesRef.current,
    stakeholdersRef.current,
    rolesRef.current,
    finishToLoadCards
  ]);

  useEffect(() => {
    firstFunction();
  }, [
    JSON.stringify(groupCompanies),
    JSON.stringify(stakeholders),
    JSON.stringify(roles),
    groupCompaniesRef.current,
    stakeholdersRef.current,
    rolesRef.current,
    finishToLoadCards
  ]);

  const hiddenLines = () => {
    const newLines = lines;
    _.forEach(lines, (line, key) => {
      newLines[key]["visible"] = false;
    })
    return newLines;
  }

  const reloadLines = () => {
    if (
      finishToLoadCards
    ) {
      let newLines = hiddenLines();
      _.forEach(groupCompanies, (groupCompany, keyGC) => {
        _.forEach(groupCompany.roles, (rol, keyRol) => {
          newLines[`gc_${keyGC}_rol_${keyRol}`] = ({ from: groupCompaniesRef.current[keyGC], to: rolesRef.current[keyRol], classId: keyRol, visible: false, type: "curve" });
          newLines[`gc_${keyGC}_rol_${keyRol}`]["dotter"] = isGroupCompanyInFormation(groupCompany);
          newLines[`gc_${keyGC}_rol_${keyRol}`]["params"] = {
            groupCompanies: [keyGC],
            rol: [keyRol],
            stakeholders: []
          }
          if (
            (
              dataPropsGroupCompany[keyGC] &&
              !dataPropsGroupCompany[keyGC]["data-unavailable"] &&
              (
                dataPropsGroupCompany[keyGC]["data-suggested"] ||
                dataPropsGroupCompany[keyGC]["data-selected"]
              )
            ) &&
            (
              dataPropsRol[keyRol] &&
              !dataPropsRol[keyRol]["data-unavailable"] &&
              (
                dataPropsRol[keyRol]["data-suggested"] ||
                dataPropsRol[keyRol]["data-selected"]
              )
            ) &&
            (
              dataPropsGroupCompany[keyGC]["data-blockLines"] === false &&
              dataPropsRol[keyRol]["data-blockLines"] === false) &&
            newLines[`gc_${keyGC}_rol_${keyRol}`] &&
            hasEnabledStakeholder(_.get(groupCompanies, `${keyGC}.roles.${keyRol}`, [])
            )
          ) {
            newLines[`gc_${keyGC}_rol_${keyRol}`]["visible"] = true;
          }
        });
        if (!filters.rol && !filters.stakeholder && filters.groupCompany) {
          _.forEach(groupCompany.childrens, (groupCompanyChild) => {
            const keyGCChild = groupCompanyChild.id;
            if (
              (dataPropsGroupCompany[keyGC] && !dataPropsGroupCompany[keyGC]["data-unavailable"] && (dataPropsGroupCompany[keyGC]["data-suggested"] || dataPropsGroupCompany[keyGC]["data-selected"])) &&
              (dataPropsGroupCompany[keyGCChild] && !dataPropsGroupCompany[keyGCChild]["data-unavailable"] && (dataPropsGroupCompany[keyGCChild]["data-suggested"] || dataPropsGroupCompany[keyGCChild]["data-selected"])) &&
              newLines[`gc_${keyGC}_gc_${keyGCChild}`]
            ) {
              newLines[`gc_${keyGC}_gc_${keyGCChild}`]["visible"] = true;
            }
            if (newLines[`gc_${keyGC}_gc_${keyGCChild}`]) {
              newLines[`gc_${keyGC}_gc_${keyGCChild}`]["dotter"] = isGroupCompanyInFormation(groupCompany) || isGroupCompanyInFormation(groupCompanyChild);
              newLines[`gc_${keyGC}_gc_${keyGCChild}`]["params"] = {
                groupCompanies: [keyGC, keyGCChild],
                rol: [],
                stakeholders: []
              }
            }
          });
        }
        if ((!filters.rol && !filters.stakeholder && !filters.groupCompany)) {
          _.forEach(groupCompany.childrens, (groupCompanyChild) => {
            const keyGCChild = groupCompanyChild.id;
            if (
              newLines[`gc_${keyGC}_gc_${keyGCChild}`]
            ) {
              newLines[`gc_${keyGC}_gc_${keyGCChild}`]["visible"] = true;
              newLines[`gc_${keyGC}_gc_${keyGCChild}`]["dotter"] = isGroupCompanyInFormation(dataPropsGroupCompany[keyGC]) || isGroupCompanyInFormation(dataPropsGroupCompany[keyGCChild]);
              newLines[`gc_${keyGC}_gc_${keyGCChild}`]["params"] = {
                groupCompanies: [keyGC, keyGCChild],
                rol: [],
                stakeholders: []
              }
            }
          });
        }
      });
      _.forEach(stakeholders, (stakeholder, keySH) => {
        _.forEach(stakeholder.roles, (rol, keyRol) => {
          newLines[`rol_${keyRol}_sh_${keySH}`] = ({ from: rolesRef.current[keyRol], to: stakeholdersRef.current[keySH], classId: keyRol, visible: false, type: "curve" });
          if (
            (dataPropsRol[keyRol] && !dataPropsRol[keyRol]["data-unavailable"] && (dataPropsRol[keyRol]["data-suggested"] || dataPropsRol[keyRol]["data-selected"])) &&
            (dataPropsStakeholder[keySH] && !dataPropsStakeholder[keySH]["data-unavailable"] && (dataPropsStakeholder[keySH]["data-suggested"] || dataPropsStakeholder[keySH]["data-selected"]) && dataPropsStakeholder[keySH]["data-visible"]) &&
            (dataPropsRol[keyRol]["data-blockLines"] === false && dataPropsStakeholder[keySH]["data-blockLines"] === false) &&
            newLines[`rol_${keyRol}_sh_${keySH}`]
          ) {
            newLines[`rol_${keyRol}_sh_${keySH}`]["visible"] = true;
          }
          newLines[`rol_${keyRol}_sh_${keySH}`]["params"] = {
            groupCompanies: [],
            rol: [keyRol],
            stakeholders: [keySH]
          }
          if (newLines[`rol_${keyRol}_sh_${keySH}`]["dotter"] === undefined) {
            newLines[`rol_${keyRol}_sh_${keySH}`]["dotter"] = true;
          }
          newLines[`rol_${keyRol}_sh_${keySH}`]["status"] = determineStatusByRole(stakeholder, keyRol);
          if (newLines[`rol_${keyRol}_sh_${keySH}`]["dotter"] === true) {
            _.forEach(rol, (value, rol_gc_key) => {
              const isFormation = isGroupCompanyInFormation(groupCompanies[rol_gc_key])
              if (!isFormation) {
                newLines[`rol_${keyRol}_sh_${keySH}`]["dotter"] = false;
              }
            });
          }
          if (!newLines[`rol_${keyRol}_sh_${keySH}`]['dotter']) {
            const shClass = new Stakeholder(stakeholders[keySH]);
            if (shClass.isRolInPincas(keyRol)) {
              newLines[`rol_${keyRol}_sh_${keySH}`]['dotter'] = true;
            }
          }
        })
      });
      _.forEach(groupCompanies, (groupCompany, keyGC) => {
        _.forEach(groupCompany.roles, (rol, keyRol) => {
          newLines[`gc_${keyGC}_rol_${keyRol}`]["status"] = determineStatus(keyRol, stakeholders, keyGC, newLines);
        });
      });
      setLines(newLines);
      setAux(aux + 1)
    }
  }

  useEffect(() => {
    reloadLines()
  }, [
    JSON.stringify(dataPropsRol),
    JSON.stringify(dataPropsGroupCompany),
    JSON.stringify(dataPropsStakeholder)
  ])

  return { lines: { ...lines }, reloadLines, reload, aux };
}
export default useMapLines;
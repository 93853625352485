import MenuItem from "@material-ui/core/MenuItem";
import React, { useEffect, useState } from 'react';
import DropDown from "../../../../components/generic/Menues/DropDown/DropDown";
import useTextLang from "../../../../hooks/custom/useTextLang";
import { useSelector } from 'react-redux';
import Icon from "../../../../modules/generic/components/Icon";
import classes from './DropDownDocuments.module.scss';
import _ from "lodash";
import helper from "../../../../v1/utils/helper";
import useGetDocumentTypes from "../../../../hooks/services/modules/startups/documents/useGetDocumentTypes";
import Loading from "../../../generic/Loading/Circular/Circular";

const DropDownDocuments = ({documents, className,  tooltip, errorMessage, isForm, name, currency, onChange, classNameError, ...props }) => {
    const {loading, error, data: documentTypes} = useGetDocumentTypes();
    const [docs, setDocs] = useState([]);
    const [selected, setSelected] = useState(null);
	// console.log(documents);

        const handleChange = (option) => {
            setSelected(option);
            if (onChange) {
                onChange(option)
            }
        };
        useEffect(() => {
            if(documentTypes) {
                const docs = _.map(documents, document => {
                    const doc = _.find(documentTypes.getDocumentTypes, {code: document.documentType});
                    const cardIcon = helper.getValueFromKey(doc.contentConfig, 'cardIcon', 'SAFE');
                    return ({
                        file: document.file,
						name: doc.label,
                        cardIcon
                    })
                });
                const doc = [];
                _.forEach(docs, (document, i) => {
                    if (doc.length === 0) {
                        setSelected(document)
                    }
                    doc.push(
                        <MenuItem style={{textOverflow:'ellipsis'}} onClick={() => {
                            handleChange(document)
                        }}>
                            <Icon isClara={true} icon={document.cardIcon} size={"2.3077rem"}/>
                            <label style={{whiteSpace:'initial'}}>{document.name}</label>
                        </MenuItem>);
                });
				// console.log(doc);
                setDocs(doc)
            }
        }, [documentTypes]);

        return (
            <div className={`${classes.DropDownStartup} ${className}`}>
                <Loading showChildren={true} loading={loading || error}>
                    <DropDown buttonClass={classes.DropDownStartupButton} options={docs}
                              anchorOrigin={{vertical: 'bottom', horizontal: 'left',}}
                              transformOrigin={{vertical: 'top', horizontal: 'left',}}>
                        <Icon isClara={true} icon={selected?selected.cardIcon:null}
                              size={"2.3077rem"}/>
                        <label>{selected?selected.name:null}</label>
                    </DropDown>
                </Loading>
            </div>
        )
    };

export default DropDownDocuments

import React, { useState } from 'react';
import useModal from '../../../../../../../../modules/generic/hooks/useModal';
import useSession from "./../../../../../../../../modules/session/hooks/useSession";
import ModalDelete from "../../../../../../../../modules/generic/components/Modal/ModalDelete";
import Button from "../../../../../../../../modules/generic/components/Buttons/Button";
import Typography from "../../../../../../../text/Typography/Typography";
import DeleteForm from "../../../../../../../../modules/generic/forms/Delete";
import TemplateDeleteSmall from "../../../../../../../../modules/generic/templates/Modal/TemplateDeleteSmall";
import useDeleteStakeholderRoles from "../../../graphql/mutation/deleteRole";
import useTranslate from "../../../../../../../../modules/generic/hooks/useTranslate";
import classes from "./Styles.module.scss";
import UnableToRemoveOneRole from "./UnableToRemoveOneRole";

const DeleteRoleModal = ({ open: openParam = false, data, stakeholderName, onlyOneRole }) => {
  const { startupId } = useSession();
  const {closeModal} = useModal();
  const {translate} = useTranslate();
  const [open, setOpen] = useState(openParam);
  const { mutation } = useDeleteStakeholderRoles({
      showSuccessNotification: true,
      successMessage: 'MODULES.STAKEHOLDER.EDIT_ROLE.ROLE_DELETED_SUCCESS'
  });

  const onSubmit = async () => {
    const variables = {
      startupId,
      stakeholderId: data?.stakeholder?.id,
      stakeholderRolesData: {
        roles: [
          {
            entityId: data?.entity?.id || data?.groupCompany?.id,
            entityType: data?.entity?.__typename === 'GroupCompany' ? 'GROUP_COMPANY' : 'STAKEHOLDER',
            role: [
              {
                type: data?.name,
                startDate: data?.startDate,
                endDate: data?.endDate
              }
            ]
          }
        ]
      }
    }
    await mutation({ variables });
    closeModal();
  }

  const handleCancel = () => {
      closeModal();
      setOpen(false);
  }

  const options = [{ value: 'delete', label: translate('MODULES.STAKEHOLDER.EDIT_ROLE.DELETE_ROLE_QUESTION') }];

  const entityIsCompany = data?.entity?.__typename === 'GroupCompany';

  return (
      <ModalDelete open={open}>
          {onlyOneRole && entityIsCompany ?
              <UnableToRemoveOneRole
                  propsTemplate={{
                      title: translate('MODULES.STAKEHOLDER.EDIT_ROLE.UNABLE.TITLE')
                  }}
                  className={classes.container}
                  onCompleteSubmit={handleCancel}
                  buttons={{}}
                  Template={TemplateDeleteSmall}
              >
                  <Typography variant='body' weight='bold' className={classes.cannotDeleteDescription}>
                      <p>Here's why:</p>
                      <li>{translate('MODULES.STAKEHOLDER.EDIT_ROLE.UNABLE.REASON_1')}</li>
                      <li>{translate('MODULES.STAKEHOLDER.EDIT_ROLE.UNABLE.REASON_2')}</li>
                      <li>{translate('MODULES.STAKEHOLDER.EDIT_ROLE.UNABLE.REASON_3')}</li>
                  </Typography>
              </UnableToRemoveOneRole>
          :
              <DeleteForm
                  propsTemplate={{
                      title: translate('MODULES.STAKEHOLDER.EDIT_ROLE.DELETE_ROLE_TITLE', {stakeholderName}),
                      description: <Typography component={'p'} color={'error'} variant={'body'}>{translate('MODULES.STAKEHOLDER.EDIT_ROLE.DELETE_ROLE_DESCRIPTION')}</Typography>
                  }}
                  onCompleteSubmit={onSubmit}
                  checkBoxProps={{options}}
                  buttons={{
                      cancel: <Button type={'button'} className={classes.cancelButton} onClick={handleCancel}>Cancel</Button>
                  }}
                  Template={TemplateDeleteSmall}
              />
          }
      </ModalDelete>
  );
};

export default DeleteRoleModal;
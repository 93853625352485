import React, { useEffect, useState, useMemo } from 'react';
import useQuery from '../../../../hooks/custom/useQuery';
import _, { isEmpty } from 'lodash';
import SelectInput from '../SelectInput/SelectInput';

const GET_CURRENCIES = `
  query countryList($code:String, $name:String, $language:String!){
    countryList(code:$code, name:$name, language:$language){
      code
      name
      currency {currencyCode currencyName currencySymbol
    }
  }
}
`

const CurrencySelector = ({ onChange, name, handleFilterList = null, value, placeholder = 'Select currency', ...props }) => {

  const { loading, error, data = [] } = useQuery(GET_CURRENCIES, { code: "", name: "", language: 'en' });
  const [CurrencyList, setCurrencyList] = useState([]);

  const Currencies = useMemo(() => _.uniqBy(_.map(data.countryList, ((item) => (
    {
      label: item.currency.currencyCode + " " + item.currency.currencySymbol + " " + item.currency.currencyName,
      id: item.currency.currencyCode
    }
  ))), 'id'), [JSON.stringify(data)]);

  useEffect(() => {
    setCurrencyList(Currencies);
  }, [JSON.stringify(Currencies)]);

  const handleOnChange = (newValue) => {
    onChange(newValue)
  }

  return (
    <>
      {isEmpty(CurrencyList) ?
        <div>Loading Currency...</div>
        : <SelectInput
          {...props}
          list={CurrencyList}
          defaultValue=""
          placeholder={placeholder}
          onChange={handleOnChange}
          clear={true}
          value={value}
          search={true}
          handleFilterList={handleFilterList}
        />
      }
    </>
  )
}

export default CurrencySelector;
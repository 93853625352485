import React from "react";
import classes from "../styles.module.scss";
import TwoColumnsViewer from "src/modules/generic/components/TwoColumsViewer";
import DetailsTabTitle from "src/components/templates/DetailsTabTitle";
import { Divider } from "@mui/material";
import { RoleAccess } from "src/v1/components";
import Button from "src/modules/generic/components/Buttons/Button";
import { Constants } from "src/v1/utils/constants";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import useModal from "src/modules/generic/hooks/useModal";
import EditRelevantIncomeModal from "src/views/modules/startups/renewal/cayman/modals/EditRelevantIncomeModal";
import Box from "@mui/material/Box";
import _ from "lodash";
import useFormat from "src/modules/generic/hooks/useFormat";

const RelevantIncomePanel = ({ data, isLock, renewalData }) => {
  const { translate } = useTranslate();
  const { openModal, closeModal } = useModal();
  const { numberFormat } = useFormat();

  const companyIncome = _.get(renewalData, 'getRenewalData')?.companyIncome;

  const buttonEditRelevantIncome = (
    <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
      <Button
        variant="card"
        disabled={isLock}
        children={translate("VIEW_COMPANY_TAB_BUTTON_DETAILS_EDIT")}
        onClick={() => {
          openModal(EditRelevantIncomeModal, {
            handleCloseModal: closeModal,
            initialValues: { groupCompanyId: data?.id },
          });
        }}
      />
    </RoleAccess>
  );

  const itemsColumn1 = [
    {
      label: "Currency used to complete this return",
      value: companyIncome?.currency,
    },
    {
      label: "Dividend income",
      value: `${companyIncome?.currency} ${numberFormat(companyIncome?.dividend)}`,
    },
    {
      label: "Capital gains income",
      value: `${companyIncome?.currency} ${numberFormat(companyIncome?.capitalGains)}`,
    },
  ];

  const itemsColumn2 = [
    {
      label: "Relevant Income",
      value: `${companyIncome?.currency} ${numberFormat(companyIncome?.relevant)}`,
    },
    {
      label: "Incidental income",
      value: `${companyIncome?.currency} ${numberFormat(companyIncome?.incidental)}`,
    },
    {
      label: "Total income",
      value: `${companyIncome?.currency} ${numberFormat(companyIncome?.total)}`,
    },
  ];

  return (
    <>
      <Box sx={{ paddingRight: "0.4615rem" }}>
        <Divider className={classes.divider} />
        <DetailsTabTitle title="Relevant income" buttons={buttonEditRelevantIncome} />
        <TwoColumnsViewer
          itemsCol1={itemsColumn1}
          itemsCol2={itemsColumn2}
        />
      </Box>
    </>
  );
};

export default RelevantIncomePanel;
import React, { Component } from 'react';
import classes from "../EditForm.module.scss";
import { Field } from "formik";
import TextInput from "../../../../containers/Forms/Inputs/TextInput";
import ClaraInputSelect from "../../../Form/ClaraInputSelect/ClaraInputSelect";
import ClaraSelect from "../../../Form/ClaraSelect/ClaraSelect";
import moment from "moment";
import FormikHelper from "../../../../utils/formikHelper";
import ChatFileUploaderAndSelector from '../../../../../components/inputs/ChatFileUploaderAndSelector/ChatFileUploaderAndSelector';
import _ from "lodash";


class OptionEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            issuedDate: props.issuedDate,
            selectedGroupCompany: {},
            documents: [],
            optionHolders: [],
            classes: [],
            shareIncentivePlans: [],
            groupCompanySelected: {},
            shareClassId: null
        };
    }

    handleDateChange(e, field, form) {

        const date = e === null ? null : moment(e).format("YYYY-MM-DD");

        FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

        this.setState({
            [field.name]: e
        })
    }



    getOrdinaryClass() {
        const ordinary = this.state.classes.filter(sc => sc.label === 'Ordinary')[0];
        if (ordinary) {
            return ordinary.id;
        }
        return '';
    }

    filterShareIncentivePlans(value) {
        return this.state.shareIncentivePlans.filter(propIncentivePlan => {
            return value && value.id === propIncentivePlan.shareClassId
        });
    }
    filterClasses(gc) {
        if (!gc || !gc.shareClass) {
            return []
        }
        return gc.shareClass.map(sc => {
            return { id: sc.id, label: sc.name }
        })
    }

    filterOptionHolders(groupCompany) {
        return this.props.stakeholders
    }

    filterDocuments(gc) {
        return this.props.documents.filter(propDocuments => {
            return gc.documents && gc.documents.some(doc => doc.id === propDocuments.id)
        });
    }

    findGroupCompany(gcId) {
        return this.state.groupCompanies.filter(gc => gc.id === gcId)[0] || {};
    }
    /*Config Compoenents Methods*/
    componentWillMount() {
        this.setState({
            groupCompanies: this.props.groupCompanies,
            shareIncentivePlans: this.props.shareIncentivePlans
        })
    }
    getCompanyAdds = () => {
        return [{
            type: "groupCompany",
            label: "Add New Company",
            textWarning: "This will create a new company for your startup. You can add more details later from the company's profile page.",
            placeholder: "Enter Company Name",
            startupId: this.props.startupId,
            params: null,
            paramsReturn: 'shareClass{ id name defaultShare }'
        }]
    }
    getCompanyLists = () => {
        return [
            {
                type: "groupCompany",
                list: this.state.groupCompanies
            }
        ]
    }
    getOwnerAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add New Corporate Stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add more details later from the stakeholder's profile page.",
                placeholder: "Enter Stakeholder's Name",
                startupId: this.props.startupId,
                textToSave: 'fullName',
                params: {
                    isAnEntity: true,
                    roles: [{
                        role: "OPTIONHOLDER",
                        groupCompany: this.state.groupCompanyId
                    }],
                }
            },
            {
                type: "stakeholder",
                label: "Add New Individual Stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add more details later from the stakeholder's profile page.",
                placeholder: "Enter Stakeholder's Name",
                startupId: this.props.startupId,
                textToSave: 'fullName',
                params: {
                    isAnEntity: false,
                    roles: [{
                        role: "OPTIONHOLDER",
                        groupCompany: this.state.groupCompanyId
                    }],
                }
            },
        ]
    }
    getOwnerLists = () => {
        return [
            {
                type: "groupCompany",
                list: _.filter(this.state.groupCompanies, (element) => {
                    return element.id !== this.state.groupCompanyId
                })
            },
            {
                type: "stakeholder",
                list: this.props.stakeholders
            }
        ]
    }
    getShareClassAdds = () => {
        return [{
            type: "shareClass",
            label: "Add New Share Class",
            placeholder: "Enter Share Class Name",
            startupId: this.props.startupId,
            params: {
                groupCompanyId: this.state.groupCompanyId,
            }
        }]
    }
    getShareClassLists = (form) => {
        var gcId = form.values.company && form.values.company.id ? form.values.company.id : null;
        const gc = this.findGroupCompany(gcId);
        const list = this.filterClasses(gc);
        return [
            {
                type: "shareClass",
                list: list
            }
        ]
    }
    getIncentiveClassAdds = () => {
        return [{
            type: "shareIncentivePlan",
            label: "Add New Incentive Plan",
            placeholder: "Enter Incentive Plan Name",
            startupId: this.props.startupId,
            params: {
                shareClassId: this.state.shareClassId,
            }
        }]
    }
    getIncentiveClassLists = (form) => {
        let value = [
            {
                type: "shareIncentivePlan",
                list: []
            }
        ]
        value[0].list = this.filterShareIncentivePlans(form.values.shareClass);
        return value;
    }
    handleGroupCompanyChange = (value, aditionalData, form) => {
        const gcId = value ? value.id : null;
        const gc = this.findGroupCompany(gcId);
        this.setState({
            groupCompanyId: gcId,
            groupCompanySelected: gc,
            documents: this.filterDocuments(gc),
            optionHolders: this.filterOptionHolders(gc),
        });
        form.setFieldValue('defaultShareClass', _.filter(gc.shareClass, sc => (sc.defaultShare))[0])
    }
    handleShareClassCreate = (value, name, additionalValue, form) => {

        var gcId = form.values.company && form.values.company.id ? form.values.company.id : null;

        let { groupCompaniesList } = this.state;
        const gcIndex = _.findIndex(groupCompaniesList, (gc) => {
            return gc.id == gcId;
        });
        if (groupCompaniesList[gcIndex] && value) {
            groupCompaniesList[gcIndex].shareClass.push({ id: value.id, name: name });
            this.setState({
                groupCompaniesList
            })
        }
    }

    handleShareClassChanged = (value, name, additionalValue, form) => {

        const shareClass = value;

        if(shareClass){
            this.setState({shareClassId: shareClass.id})
        }
    }

    handleGroupCompanyCreate = (value, name, additionalValue, form) => {

        let { groupCompanies, groupCompaniesList } = this.state;
        let newGc = { id: value.id, name, ...additionalValue }
        if (newGc.shareClass) {
            newGc.shareClass[0].defaultShare = true
        }
        groupCompaniesList.push(newGc);

        groupCompanies.push({ id: value.id, label: name });

        this.setState({ groupCompaniesList, groupCompanies });

    }

    handleShareIncentivePlanCreate = (value, name, additionalValue, form) => {
        const { shareIncentivePlans, shareClassId } = this.state;

        shareIncentivePlans.push({ id: value.id, label: name, shareClassId });

        this.setState({ shareIncentivePlans });

    }
    render() {

        const { tooltips = {} } = this.props;
        const { groupCompanySelected } = this.state;
        return (
            <form className={classes.Form}>

                <div className={classes.ContainerRowAgreement}>
                    <Field name='company'>
                        {({ field, form }) => (
                            <ClaraSelect
                                required={true}

                                field={field}
                                form={form}

                                label={"Company"}
                                placeholder={"Search or select from a list..."}
                                adds={this.getCompanyAdds()}
                                lists={this.getCompanyLists()}
                                callbacks={{ createValues: this.handleGroupCompanyCreate, updateValues: this.handleGroupCompanyChange }}
                                tooltip={tooltips['Company']}
                            />
                        )}
                    </Field>
                </div>
                <div className={classes.ContainerRowAgreement}>
                    <Field name='owner'>
                        {({ field, form }) => (
                            <ClaraSelect
                                required={true}

                                field={field}
                                form={form}
                                disabled={this.state.groupCompanyId ? false : true}
                                label={"Who holds the options?"}
                                placeholder={"Search or select from a list..."}
                                adds={this.getOwnerAdds()}
                                lists={this.getOwnerLists()}
                                tooltip={tooltips['Who holds the options']}
                            />
                        )}
                    </Field>
                </div>
                <hr className={classes.Separator} />
                <div className={classes.ContainerRowAgreement}>
                    <Field name='amount'>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='text'
                                label='Number of Share Options: '
                                field={field}
                                form={form}
                                placeholder={"Enter number..."}
                                tooltip={tooltips['Number of Share Options']}
                            />
                        )}
                    </Field>
                </div>
                <div className={classes.ContainerRowAgreement}>
                    <Field name='price'>
                        {({ field, form }) => (
                            <TextInput
                                required={false}
                                type='text'
                                label='Exercise Price: '
                                field={field}
                                form={form}
                                placeholder={"Enter number..."}
                                tooltip={tooltips['Exercise Price']}
                                rightLabel={groupCompanySelected.transactionCurrency ? groupCompanySelected.transactionCurrency : 'USD'}
                            />
                        )}
                    </Field>
                </div>
                <hr className={classes.Separator} />
                <div className={classes.ContainerRowAgreement}>
                    <Field name='shareClass'>
                        {({ field, form }) => (
                            <ClaraSelect
                                field={field}
                                form={form}
                                disabled={this.state.groupCompanyId ? false : true}
                                label={"Class:"}
                                placeholder={"Search or select from a list..."}
                                adds={this.getShareClassAdds()}
                                lists={this.getShareClassLists(form)}
                                tooltip={tooltips['Class']}
                                callbacks={{ createValues: this.handleShareClassCreate , updateValues: this.handleShareClassChanged}}
                            />
                        )}
                    </Field>
                </div>
                <hr className={classes.Separator} />
                <div className={classes.ContainerRowAgreement}>
                    <Field name='shareIncentivePlan'>
                        {({ field, form }) => (
                            <ClaraSelect
                                field={field}
                                form={form}
                                disabled={form.values['shareClass'] ? false : true}
                                label={"Share Incentive Plan:"}
                                placeholder={"Search or select from a list..."}
                                adds={this.getIncentiveClassAdds()}
                                lists={this.getIncentiveClassLists(form)}
                                callbacks={{ createValues: this.handleShareIncentivePlanCreate }}
                                tooltip={tooltips['Share Incentive Plan']}
                            />
                        )}
                    </Field>
                </div>
                <hr className={classes.Separator} />
                <div className={classes.ContainerRowAgreement}>
                    <Field name='issuedDate'>
                        {({ field, form }) => (
                            <TextInput
                                type='date'
                                label='Issued Date'
                                field={field}
                                form={form}
                                placeholder={"Date"}
                                tooltip={tooltips['Issued Date']}
                                prevComponentChange={e =>
                                    this.handleDateChange(e, field, form)
                                }
                            >
                            </TextInput>
                        )}
                    </Field>
                </div>
                <hr className={classes.Separator} />
                <div className={classes.ContainerRowAgreement}>
                    <span className={classes.fileUploaderAndSelectorLabel}><label className={classes.labelShareEdit}>Associated Documents:</label></span><span className={classes.fileUploaderAndSelectorContainer}><Field name='associatedDocuments'>
                        {({ field, form }) => (
                            <ChatFileUploaderAndSelector
                                required={false}
                                field={field}
                                name='associatedDocuments'
                                multipleFiles={true}
                                form={form}
                                label={"Associated Documents"}
                                documents={this.state.documents}
                            />
                        )}
                    </Field></span>
                </div>
            </form>
        );
    }
}

export default OptionEdit;

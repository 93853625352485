import { default as React, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import * as Yup from 'yup';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import Typography from "src/modules/generic/components/Texts/Typography";
import classes from "./classes.module.scss";

function CaymanIsBetter() {
  const { next, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  
  const handleCancel = () => {
    cancel();
  }

  const handlePrev = () => {
    prev();
  }

  const handleSubmit = async (values) => {
    next(values);
  }

  const options = [
    {
      value: true,
      component: ButtonList,
      componentProps:{
        title: "Yes, form in the Cayman Islands",
        text: "A popular, common law jurisdiction for new companies and venture capitalists:",
        list: ['Tax neutral', 'Legal framework based on English law','Many Cayman-based VCs are keen to invest here, due to their familiarity with its law']
      }
    },
    {
      value: false,
      component: ButtonText,
      componentProps:{
        title: "No, choose a different location to form a company"
      }
    },
  ];
  
  useEffect( ()=>{
    dispatch(addAssistantText('The company hasn\'t met all the requirements for ADGM formation, so Clara recommends forming in the Cayman Islands instead. <br/> <br/> The Cayman Islands is the best alternative to ADGM. A popular, common law jurisdiction for new companies and venture capitalists, it has tax neutral status and a legal framework based on English law.'));
    return () => {
      dispatch(clearAssistatText());
    }
  },[dispatch]);
  
  const schema = Yup.object().shape({
    formInCayman: Yup.boolean().nullable().required("Required"),
  });

  return (
    <Form
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: 'The Cayman Islands is a better fit',
          skeleton: null,
          subTitle: 'ADGM eligibility',
          variant: 'simple'
        }}
        buttons={{
          previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('BUTTON_NEXT')}</SubmitButton>,
        }}
      >
        <Typography component='h4'>
          The new company doesn't meet all the ADGM requirements, but the Cayman Islands fit your needs. Form there instead?
        </Typography>
        <div>
        <ControllerInput
            as={CheckboxButtonSimple}
            className={classes.CaymanIsBetterCheckboxContainer}
            classNameButton={classes.CaymanIsBetterCheckboxButton}
            name="formInCayman"
            variant="col_1"
            options={options}
        />
        </div>
      </TemplateWizard>
    </Form>
  );
}

export default CaymanIsBetter;

const ButtonText = ({ title, text }) => {
  return (
      <div className={classes.CaymanIsBetterCheckboxButtonText}>
        <Typography weight="semibold" variant="h4" className="mb-2">
          {title}
        </Typography>
        <Typography variant="body">{text}</Typography>
      </div>
  );
};

const ButtonList = ({ title, text, list }) => {
  return (
      <div className={classes.CaymanIsBetterCheckboxButtonText}>
        <Typography weight="semibold" variant="h4" className="mb-2">
          {title}
        </Typography>
        <Typography variant="body">{text}</Typography>
        <Typography variant="body" className={classes.CaymanIsBetterCheckboxButtonList}>
          {_.map(list, option => {
            return (
                <div className={classes.CaymanIsBetterCheckboxButtonListOption}>
                  <div>•</div>
                  <span>{option}</span>
                </div>
            );
          })}
        </Typography>
      </div>
  );
};

import { default as React, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateLabel from 'src/modules/generic/components/Inputs/LabelInput';
import * as Yup from 'yup';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import classes from '../classes.module.scss';
import CheckboxButtonSimple, {
  CheckboxButtonOptions,
} from 'src/modules/generic/components/Inputs/CheckboxButtonSimple';
import CalendarInputDate from 'src/components/inputs/Calendars/CalendarInputDate';

/**
* Description Step: you have to enter how the stakeholder entered the UAE and the date of entry if they entered via E-Gate.
*/
function HowDidTheyEnterFormStep() {

  const { next, context, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const [eGate, setEGate] = useState(false)

  const initialValues = useMemo(() => {
    return context?.HowDidTheyEnterFormStep ?? {}
  }, [context]);

  const handleCancel = (values) => {
    cancel();
  }

  const handlePrev = (values) => {
    prev();
  }
  const handleSubmit = async (values) => {
    next(values)
  }

  const options: Array<CheckboxButtonOptions<string, any>> = [
    {
      value: 'E_GATE',
      label: 'E-Gate'
    },
    {
      value: 'UAE_ENTRY_STAMP',
      label: 'UAE entry stamp'
    },
  ];

  useEffect(() => {
    dispatch(addAssistantText('If this stakeholder entered via E-Gate, we will need to know the date of entry. Alternatively, we will need a copy of the UAE entry stamp in their passport.'))
    return () => {
      dispatch(clearAssistatText())
    }
  }, [dispatch])

  const schema = Yup.object().shape({
    howDidTheyEnter: Yup.string().nullable().required('Required'),
    entryDate: Yup.date().nullable().typeError('Must be a date').when(['howDidTheyEnter'], {
      is: (value) => {
        return value === 'E_GATE'
      },
      then: Yup.date().nullable().required('Required').typeError('Add a valid date'),
      otherwise: Yup.date().nullable().typeError('Must be a date').optional()
    }),
  })

  const handleChangeSelection = (value) => {
    if (value.includes('E_GATE')) {
      setEGate(true)
    } else {
      setEGate(false)
    }
  }
  return (

    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: 'How did they enter the UAE?',
          skeleton: null,
          subTitle: 'ADGM Authorised Signatory',
          variant: 'simple'
        }}

        buttons={{
          previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('BUTTON_NEXT')}</SubmitButton>,
        }}
      >
        <ControllerInput
          as={CheckboxButtonSimple}
          isMultiple={false}
          defaultValue={[]}
          name="howDidTheyEnter"
          options={options}
          classNameButton={classes.buttonCheckBox}
          size="lg"
          onChange={handleChangeSelection}
        />
        {eGate &&
          <TemplateLabel
            label={'Entry date'}
            isRequired={true}
          >
            <ControllerInput
              render={CalendarInputDate}
              name='entryDate'
              placeholder={'dd/mm/yyyy'}
            />
          </TemplateLabel>
        }
      </TemplateWizard>
    </Form>
  );
}

export default HowDidTheyEnterFormStep;


import { Field } from "formik";
import _ from "lodash";
import moment from "moment";
import React, { Component } from 'react';
import { ClaraInputDate, TextAreaInput, TextInput } from '../../../../../../../v1/components';
import ClaraCountrySelector from "../../../../../../../v1/components/Form/ClaraCountrySelector/ClaraCountrySelector";
import ClaraInputSelect from "../../../../../../../v1/components/Form/ClaraInputSelect/ClaraInputSelect";
import ClaraRadioWithButton from '../../../../../../../v1/components/Form/ClaraRadioWithButton/ClaraRadioWithButton';
import ClaraSelect from "../../../../../../../v1/components/Form/ClaraSelect/ClaraSelect";
import ClaraUpload from "../../../../../../../v1/components/Form/ClaraUpload/ClaraUpload";
import Checkbox from "../../../../../../../v1/containers/Forms/Inputs/Checkbox";
import { Constants } from "../../../../../../../v1/utils";
import FormikHelper from "../../../../../../../v1/utils/formikHelper";
import Line from "../../../../../../generic/Line/Line";
import AuthorisedSignatories from "../../../../../../inputs/AuthorizedSignatories/AuthorizedSignatories";
import CheckBox from '../../../../../../inputs/Checkbox/Checkbox';
import SelectStateCity from "../../../../../../inputs/Select/SelectStateCity";
import classes from "../Styles.module.scss";
import HiddenField from '../../../../../../../v1/components/Form/ClaraHiddenField/ClaraHiddenField';

class CPIIAgreementDoc extends Component {

    constructor(props) {
        super(props);
        this.state = {
            typeSelected: undefined,
            employeeSelected: undefined,
            groupCompanySelected: undefined,
            authorizedSelected: undefined,
            authorizedSignatories: [],
            authorizedSignatoriesList: [],
            documentTypes: [],
            documentTypesList: [],
            dynamicParams: [],
            date: undefined,
            expirationDate: undefined,
            firstSetCity:true,
            stateValue:null
        }
    };

   
    fieldHasError(form,field) {
        return FormikHelper.returnErrorsForValidation(field.name, form.errors);
    }

  

    selectType = (documentType, extraParams, form) => {
        this.setState({
            typeSelected: documentType,
            dynamicParams: this.getDynamicParamsByType(documentType)
        }, () => {
            FormikHelper.setValueInTheCorrectPosition('terms', form, []);
            FormikHelper.setValueInTheCorrectPosition('documentType', form, documentType);
            this.state.dynamicParams.forEach((dynamicParam, i) => {
                FormikHelper.setValueInTheCorrectPosition(
                    `terms[${i}].key`,
                    form,
                    dynamicParam.key || 'NO_KEY'
                );
            })
        });
    };

    getDynamicParamsByType(documentType) {
        const type = this.props.documentTypes.filter(agrType => agrType.code === documentType)[0] || {};
        return type.fields || [];
    }

    getGroupCompaniesToCompany = () => {
        let authorizedSignatories = this.props.authorizedSignatories;
        let groupCompanies = this.props.groupCompanies;
        let companies = [];
        if (groupCompanies && authorizedSignatories) {
            companies = _.filter(groupCompanies, (groupCompany) => {
                let fined = _.filter(authorizedSignatories, (authorized) => {
                    if (groupCompany.authorizedSignatories) {
                        return groupCompany.authorizedSignatories.some(au => au.stakeholder.id === authorized.id);
                    }
                    return false;
                });
                if (fined) {
                    return true;
                }
                return false;
            });
        }
        return companies;
    };

    selectGroupCompany = (gcId, extraParams, form) => {

        const groupCompany = this.findGroupCompany(gcId);

        // this is for a weird behaviour when hit edit button after preview a document
        if (this.state.groupCompanySelected && groupCompany) {
            return null;
        }

        this.setState({
            groupCompanySelected: groupCompany,
            firstSetCity:true,
            stateValue:null
        });

        if (groupCompany) {

            if (!groupCompany.registeredAddress) {
                _.set(groupCompany, "registerAddress", {})
            }

            let authorizedSignatories = _.get(form, 'values.company.authorizedSignatories', []);

            form.setValues({
                ...form.values,
                company: {
                    id: gcId,
                    name: groupCompany.name || '',
                    country: _.get(groupCompany, 'country.code', null) || _.get(groupCompany, 'country', null),
                    jurisdiction: groupCompany.jurisdiction,
                    placeOfBusiness:groupCompany.placeOfBusiness,
                    authorizedSignatories: authorizedSignatories,
            }
        });
            this.setState({
                groupCompanySelected: { ...groupCompany },
            })
        } else {
            form.setValues({
                ...form.values,
                company: {}
            });
        }
    };

    findGroupCompany(gcId) {
        const gc = _.find(this.props.groupCompaniesList, { id: gcId });
        return gc ? { ...gc } : null
    };

    selectEmployee = (consultant, extraParams, form, cb) => {
        const stakeholder = this.findStakeholder(consultant);
        this.setState({
            employeeSelected: stakeholder,
        });
        console.log(stakeholder)
        if (stakeholder) {
            let authorizedSignatory = {};

            if (!stakeholder.address) {
                _.set(stakeholder, 'address', {})
            }

            const _employee = !_.isEmpty(form.values.consultant) ? form.values.consultant : stakeholder;

            if (stakeholder.isAnEntity) {
                authorizedSignatory = {
                    fullName: typeof _employee.authorizedSignatory === 'object'
                        ? _.get(_employee, 'authorizedSignatory.fullName', '')
                        : _.get(_employee, 'authorizedSignatory', ''),
                    email: _employee.email || ''
                }
            }

            form.setValues({
                ...form.values,
                 consultant: {
                    id: consultant,
                    fullName: _employee.fullName || '',
                    email: _employee.email || '',
                }
            });
        } else {
            form.setValues({
                ...form.values,
                consultant: {}
            });
        }

    };

    findStakeholder(roleId) {
        return this.props.stakeholderList.find(stakeholder => stakeholder.id === roleId)
    };

    findStakeholderAuthorized(roleId) {
        return this.props.stakeholderList.filter(stakeholder => {
            return stakeholder.roles.some(role => role.stakeholder.id === roleId);
        })[0] || null;
    };

    filterAuthorizedByGroupCompany(groupCompany) {
        if (groupCompany) {
            let gc = this.findGroupCompany(groupCompany.id);
            return this.props.authorizedSignatories.filter(authorized => {
                return authorized.groupCompanyId === gc.id
            });
        }
        return [];
    }

    selectAuthorized = (value, form, field, replace) => {
        // const stakeholder = this.findStakeholder(value);
        const stakeholder = this.props.authorizedSignatories.find(e => e.id === value);
        const email = stakeholder ? stakeholder.email : '';
        replace(0, { id: value, email })
    };

    getAuthorizedSignatoriesList = (index, form) => {
        if (form.values && form.values.company) {
            return this.filterAuthorizedByGroupCompany(form.values.company).map(as => ({
                id: as.id,
                label: as.label
            }))
        } else {
            return [];
        }
    };

    getLabel = (key) => {
        switch (key) {
            case "fullName":
                if (this.state.employeeSelected.isAnEntity) {
                    return "Company name";
                } else {
                    return "Name";
                }
            case "country":
                if (this.state.employeeSelected.isAnEntity) {
                    return "Country of incorporation";
                } else {
                    return "Nationality";
                }
            case "address":
                if (this.state.employeeSelected.isAnEntity) {
                    return "Registered address";
                } else {
                    return "Residential address";
                }
            case "passportOrRegistered":
                if (this.state.employeeSelected.isAnEntity) {
                    return "Company number";
                } else {
                    return "Passport number";
                }
        }
    };

    getField = (key) => {
        switch (key) {
            case "passportOrRegistered":
                if (this.state.employeeSelected.isAnEntity) {
                    return "consultant.registeredNumber";
                } else {
                    return "consultant.passportNumber";
                }
        }
    };

    getTooltip = (key, fieldName) => {
        switch (key) {
            case "fullName":
                if (this.state.employeeSelected.isAnEntity) {
                    return "Company Name";
                } else {
                    return "Employee's Name";
                }
            case "passportOrRegistered":
                if (this.state.employeeSelected.isAnEntity) {
                    return "Company Number";
                } else {
                    return "Passport Number";
                }
            case "country":
                if (this.state.employeeSelected.isAnEntity) {
                    return "Country";
                } else {
                    return "Nationality";
                }
            case "addressLine1":
                if (this.state.employeeSelected.isAnEntity) {
                    return "Registered Address - Address Line 1";
                } else {
                    return "Residential Address - Address Line 1";
                }
            case "addressCity":
                if (this.state.employeeSelected.isAnEntity) {
                    return "Registered Address - City";
                } else {
                    return "Residential Address - City";
                }
            case "addressState":
                if (this.state.employeeSelected.isAnEntity) {
                    return "Registered Address - State";
                } else {
                    return "Residential Address - State";
                }
            case "addressCountry":
                if (this.state.employeeSelected.isAnEntity) {
                    return "Registered Address - Country";
                } else {
                    return "Residential Address - Country";
                }
            case "addressPostCode":
                if (this.state.employeeSelected.isAnEntity) {
                    return "Registered Address - Post Code";
                } else {
                    return "Residential Address - Post Code";
                }
            case "recipientNotice":
                if (fieldName === 'company') {
                    return "Group company";
                } else {
                    return "Employee's";
                }
        }
    };

    getPlaceholder = (key) => {
        switch (key) {
            case "passportOrRegistered":
                if (this.state.employeeSelected.isAnEntity) {
                    return "Company number";
                } else {
                    return "Passport number";
                }
        }
    };

    getStakeholderAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add new corporate stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
                placeholder: "Stakeholder's name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: true,
                    // roles : [{
                    // 	role: "SHAREHOLDER",
                    // 	groupCompany: this.state.groupCompanySelected.id,
                    // }],
                },
                paramsReturn: 'isAnEntity'
            },
            {
                type: "stakeholder",
                label: "Add new individual stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
                placeholder: "Stakeholder's name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: false,
                    // roles : [{
                    // 	role:"SHAREHOLDER",
                    // 	groupCompany: this.state.groupCompanySelected.id,
                    // }]
                },
                paramsReturn: 'isAnEntity'
            },
        ]
    };

    getCompanyAdds = () => {
        return [{
            type: "groupCompany",
            label: "Add new company",
            textWarning: "This will create a new group company for your startup. You can add or amend the details of this group company on its profile page.",
            placeholder: "Company name",
            startupId: this.props.startup.id,
            params: null,
            paramsReturn: `
				name
				authorizedSignatories {
					id
					stakeholder {
						id
						email
						fullName
						roles {   id
        name
        groupCompany { id name}
        stakeholder { id } }
					}
				}
			`
        }]
    };

    getAuthorizedSignatoryAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add new individual stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
                placeholder: "Enter stakeholder's name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: false,
                    roles: [{
                        role: "DIRECTOR",
                        groupCompany: this.state.groupCompanySelected.id,
                    }]
                }
            },
        ]
    };

    handleStakeholderCreate = (value, name, additionalValue, form) => {

        let { stakeholders, stakeholderList } = this.props;
        let newSh = { id: value.id, fullName: name, ...additionalValue };
        stakeholderList.push(newSh);

        stakeholders.push({ id: value.id, label: name });

    };

    handleGroupCompanyCreate = (value, name, additionalValue, form) => {

        let { groupCompanies, groupCompaniesList } = this.props;

        let newGc = { id: value.id, name, ...additionalValue };
        groupCompaniesList.push(newGc);

        groupCompanies.push({ id: value.id, label: name });

    };

    handleAuthorizedSignatoryCreate = (value, name, additionalValue, form) => {

        let { authorizedSignatories } = this.props;
        const { groupCompanySelected } = this.state;

        authorizedSignatories.push({
            id: value.id,
            label: name,
            role: 'DIRECTOR',
            groupCompanyId: groupCompanySelected.id
        });

    };

    handleAuthorizedSignatoryUpdate = (value) => {

        const { authorizedSignatories, groupCompanySelected } = this.state;
        const groupCompany = this.findGroupCompany(groupCompanySelected.id);
        _.set(groupCompany, 'authorizedSignatories', _.map(groupCompany.authorizedSignatories, auth => ({ ...auth })))
        groupCompany.authorizedSignatories.push({ stakeholder: value });
        authorizedSignatories.push(value);
    };

    render() {
        const isRequired = message => value => (!!value ? undefined : message);
        const { tooltips = {}, formType } = this.props;

        return (
                <div className={classes.ContainerRow}>
                    {formType === 'uploadToEdit' ? (<div>
                        <Field name={`file`}>
                            {({ field, form }) => (
                                <ClaraUpload
                                    required={true}
                                    name={`filepond`}
                                    label='Upload your document '
                                    field={field}
                                    form={form}
                                />
                            )}
                        </Field>
                    </div>) : null}
                    <Field name='agreementName'>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='text'
                                label='Document name '
                                field={field}
                                classNameInput={{ root: classes.ContainerRow }}
                                form={form}
                                placeholder={"Document name"}
                                tooltip={tooltips['Document Name']}
                            />
                        )}
                    </Field>
                <Line className={classes.line} />
                <div className={classes.ZoneTitle}>
                    <h1>{'Consultant'}</h1>
                </div>
                <div className={`${classes.Select}`}>
                    <Field name='consultant.id'>
                        {({ field, form }) => (
                            <ClaraSelect
                                required={true}

                                field={field}
                                form={form}

                                label={"Select consultant"}
                                adds={this.getStakeholderAdds()}
                                lists={this.props.stakeholders}
                                mode={"classic"}
                                callbacks={{
                                    createValues: this.handleStakeholderCreate,
                                    updateValues: this.selectEmployee
                                }}
                                placeholder={"Select from the list"}
                                tooltip={tooltips["Select Employee"]}
                            />

                        )}
                    </Field>
                </div>
                {
                    this.state.employeeSelected ?
                        <React.Fragment>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                {
                                    this.state.employeeSelected.isAnEntity ?
                                        <>
                                            <h1>Consultant Details</h1>
                                            <p>Please verify or update the consultant's company's information below</p>
                                        </>
                                        :
                                        <>
                                            <h1>Consultant Details</h1>
                                            <p>Please verify or update the consultant's information below</p>
                                        </>
                                }
                            </div>
                                <Field name='consultant.fullName'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label={this.getLabel("fullName") + ' '}
                                            field={field}
                                            form={form}
                                            classNameInput={{ root: classes.ContainerRow }}
                                            placeholder={'Name'}
                                            required={true}
                                            tooltip={tooltips[this.getTooltip('fullName')]}
                                        />
                                    )}
                                </Field>
                            <div className={classes.ContainerRow}>
                                <Field name='consultant.email'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='email'
                                            label='Email address '
                                            field={field}
                                            form={form}
                                            classNameInput={{ root: classes.ContainerRow }}
                                            placeholder={'Email address'}
                                            required={true}
                                            tooltip={tooltips['Email Address']}
                                        />
                                    )}
                                </Field>
                            </div>
                        </React.Fragment>
                        :
                        null
                }
                <Line className={classes.line} />
                <div className={classes.ZoneTitle}>
                    <h1>{'Company'}</h1>
                </div>
                <div className={`${classes.Select}`}>
                    <Field name='company.id'>
                        {({ field, form }) => (
                            <ClaraSelect
                                required={true}

                                field={field}
                                form={form}

                                label={"Select company "}
                                lists={this.getGroupCompaniesToCompany()}
                                mode={"classic"}
                                callbacks={{
                                    createValues: this.handleGroupCompanyCreate,
                                    updateValues: this.selectGroupCompany
                                }}
                                placeholder={"Select from the list"}
                                tooltip={tooltips['Select Company']}
                            />
                        )}
                    </Field>
                </div>
                {
                    this.state.groupCompanySelected ?
                        <React.Fragment>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Company Details</h1>
                                <p>Please verify or update the company's information below</p>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.name'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            classNameInput={{ root: classes.ContainerRow }}
                                            label='Company name '
                                            field={field}
                                            form={form}
                                            placeholder={"Company name"}
                                            tooltip={tooltips["IP company's Name"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={`${classes.Select}`}>
                                <Field name='company.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={true}
                                            countryField={'company.country'}
                                            label={'Country of incorporation '}
                                            jurisdictionField={'company.jurisdiction'}
                                            jurisdictionLabel={'Jurisdiction '}
                                            returnCountryAsString={true}
                                            otherJurisdictionField={'company.otherJurisdiction'}
                                            setFieldValue={form.setFieldValue}
                                            initialValues={{
                                                jurisdiction: _.get(form.values, 'company.jurisdiction')
                                            }}
                                            additionalStyle={'ClaraCountrySelectorModal'}
                                            tooltips={{
                                                country: tooltips["Company's Country"],
                                                jurisdiction: tooltips["Company's Jurisdiction"],
                                                other: tooltips['Other Jurisdiction']
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                                <Field name='company.placeOfBusiness_error'>
                                    {({ field, form }) => (
                                        <React.Fragment>
                                            <span hasError={_.get(form.errors, "company.placeOfBusiness.city") || _.get(form.errors, "company.placeOfBusiness.state") ? "true" : "false"}>
                                                <SelectStateCity
                                                    selectCityLabel={'City of principal office'}
                                                    selectStateLabel={'State of principal office'}
                                                    showState={true}
                                                    modal={true}
                                                    defaultState={form?.values?.company?.placeOfBusiness?.state}
                                                    tooltips={{city:tooltips["Company's City Of Principal Office"],state:tooltips["Company's State Of Principal Office"]}}
                                                    defaultCity={form?.values?.company?.placeOfBusiness?.city}
                                                    onChangeState={(value) =>{
                                                        form.setFieldValue('company.placeOfBusiness.state',value)
                                                        if(this.state.stateValue !== value){
                                                             return this.setState({
                                                            stateValue:value
                                                        })
                                                        form.setFieldValue('company.placeOfBusiness.city',null)
                                                    }
                                                    }}
                                                    onChangeCity={(value) => form.setFieldValue('company.placeOfBusiness.city',value)}
                                                    errorMessage={_.get(form.errors, "company.placeOfBusiness.city") || _.get(form.errors, "company.placeOfBusiness.state")}
                                                />
                                            </span>
                                        </React.Fragment>
                                    )}
                                </Field>
                            <Line className={classes.line}/>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Corporate Signatory</h1>
                                <p>Please add the corporate signatory required to sign this document on behalf of the company.</p>
                            </div>
                            <Field name='company.authorizedSignatories_error'>
                                {({ field, form }) => (
                                    <React.Fragment>
                                        <span hasError={_.get(form.errors, "company.authorizedSignatories") ? "true" : "false"}>
                                            <AuthorisedSignatories
                                            list={this.props.stakeholderList}
                                            showTitle={true}
                                                limit={1}
                                                modal={true}
                                                fieldArrayName={'company.authorizedSignatories'}
                                                idGroupCompany={this.state.groupCompanySelected.id}
                                                handleAddListStakeholder={this.handleAuthorizedSignatoryUpdate}
                                                errorMessage={_.get(form.errors, "company.authorizedSignatories")}
                                            />
                                        </span>
                                    </React.Fragment>
                                )}
                            </Field>
                        </React.Fragment>
                        :
                        null
                }
                {this.state.employeeSelected && this.state.groupCompanySelected && <>
                    <Line className={classes.line}/>
                    <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                        <h1>Previous inventions</h1>
                        <p>Does the consultant have any previous inventions prior to the commencement of their emplyment that they wish to exclude from this agreement?</p>
                    </div>
					<Field name='terms.previousInventions.isTherePreviousInventions'>
						{({field, form}) => (
							<div className={classes.ContainerRow}>
								<ClaraRadioWithButton
									required={true}
									label={'Do you have previous inventions? '}
									field={field}
									form={form}
									// tooltip={tooltips['Cliff Period']}
									changeParams={field.name}
									options={[{value: true, label: 'Yes'}, {value: false, label: 'No'}]}
								/>
							</div>)}
					</Field>
					<HiddenField
						fieldName={'terms.previousInventions.description'}
						hiddenCondition={'values.terms.previousInventions.isTherePreviousInventions'}
						className={classes.ContainerRow}
					>
						<TextAreaInput
							type='text'
							trim={true}
							className={{
								root: classes.InputContainer,
								input: classes.TextInput
							}}
							tooltip={tooltips['Previous Inventions']}
							placeholder={"List of consultant's previous inventions"}
						/>
					</HiddenField>

{/*                    <Field name='terms.previousInventions.isTherePreviousInventions'>
                        {({ field, form }) => (
                            <div>
                                <div className={`${classes.labelGroup}`}>
                                    <label>Previous inventions <span style={{ color: 'red' }}>*</span></label>
                                </div>
                                <CheckBox
                                    label={' '}
                                    values={field.value}
                                    errorMessage={this.fieldHasError(form, field)}
                                    onChange={(value) => { form.setFieldValue(field.name, value); form.validateField(field.name) }}
                                    isMultiple={false}
                                    options={[
                                        {
                                            value:'no',
                                            label: 'No',
                                        },
                                        {
                                            value: 'yes',
                                            label: 'Yes',
                                            renderElement: () => (<>
                                                <Field name='terms.previousInventions.description'>
                                                    {({ field, form }) => (
                                                        <TextAreaInput
                                                            type='text'
                                                            trim={true}
                                                            field={field}
                                                            className={{
                                                                root: classes.InputContainer,
                                                                input: classes.TextInput
                                                            }}
                                                            form={form}
                                                            tooltip={tooltips['Previous Inventions']}
                                                            placeholder={"List of consultant's previous inventions"}
                                                        />
                                                    )}
                                                </Field>
                                            </>)
                                        }
                                    ]}
                                    className={{ root: `${classes.groupCheckBoxWizard} ${classes.groupCheckboxContainer}`, checkbox: classes.checkBoxWizard }}
                                />
                            </div>)}
                    </Field>*/}
                </>}

                {formType === 'uploadToEdit' ? <div>
                    <Field name='confirmSameData'>
                        {({ field, form }) => (
                            <Checkbox
                                className={classes.sameDataCheckbox}
                                field={field}
                                validate={isRequired('This field is required')}
                                form={form}
                                required={true}
                                label='All document details are verified and correct.'
                            />
                        )}
                    </Field>
                </div> : null}
            </div>

        );
    }

}

export default CPIIAgreementDoc;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DocumentPreview from "../../../../../components/generic/Documents/DocumentPreview/DocumentPreview";
import { Loading } from "../../../../../v1/components";
import BotoneraEngagementV1 from "../../../../../v1/V2/Components/Clara/matter/proposal/BotoneraEngagement/V1/BotoneraEngagement";
import useEngagementDocument from "../../../../../v1/V2/Hooks/services/matter/useEngagementDocument";
import classes from "./EngagementDocumentView.module.scss";

const EngagementDocumentView = () => {
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const { matterId } = params;
    const { data: file, loading: loadingHooks } = useEngagementDocument(matterId, "engagement");
    useEffect(() => {
        setLoading(loadingHooks);
    }, [loadingHooks]);

    const fileType = file && file.name ? file.name.split('.').pop() : '';

    return (
        <React.Fragment>
            <div className={classes.EngagementDocumentView}>
                <Loading showChildren={false} show={loading || !file}>
                    <DocumentPreview fileId={file.id} fileType={fileType}>

                        <BotoneraEngagementV1 matterId={matterId} file={file} setLoading={setLoading} />

                    </DocumentPreview>
                </Loading>
            </div>
        </React.Fragment>
    )
};

export default EngagementDocumentView

import useMutation from 'src/hooks/custom/useMutation';

const UPSERT_STARTUP = `mutation upsertStartupProfile(
  $id: ID
  $startupData: StartupInput!        
) {
  upsertStartupProfile(
    id: $id
    startupData: $startupData
  ) {
    id
    name
    description
    avatar
    website
    accelerator
    linkedInURL
    primaryLocation {
      name
      code
    }
  }
}`;

const useUpsertStartup = ({ ...props }) => {
  const [mutation, rest] = useMutation(UPSERT_STARTUP, { ...props });
  return {mutation, ...rest};
}

export default useUpsertStartup;
import React from "react";
import { ProductBO } from "../../../type/products";
import _ from "lodash";
import Items from "./Items";
import Product from "./Product";

export interface PaymentItemsProps {
  product: ProductBO
  company: any,
  variant: 'vat' | 'description',
  useTotals: boolean,
  totalGovernmentFee?: number,
  feeText: string,
  totalPricingDiscount?: number,
}
const PaymentItems: React.FC<PaymentItemsProps> = ({ 
  product, 
  variant, 
  company, 
  useTotals = false,
  totalGovernmentFee,
  feeText,
  totalPricingDiscount,
  ...props 
}: PaymentItemsProps) => {

  return (
    <>
      {_.get(product, "items") && <Items product={product} totalPricingDiscount={totalPricingDiscount} variant={variant} company={company} useTotals={useTotals} totalGovernmentFee={totalGovernmentFee} feeText={feeText}></Items>}
      {!_.get(product, "items") && <Product product={product}></Product>}
    </>
  );
};
export default PaymentItems;

export default {
    GENERIC_TOOLTIP: "A <a href=\"https://academy.clara.co/article/2fvaE5tVF7ywn7izaON0PR\" target=\"_blank\">director</a> is a member of the company's board, and can be responsible for the day-to-day management of the company and for setting the company's strategy.",
    INDIVIDUAL_OR_COMPANY_TITLE: "Add their basic info",
    INDIVIDUAL_TOOLTIP: "You must add at least one individual director.",
    CORP_SELECTED_TOOLTIP: "Please be aware that adding a company as a director might cause potential delays with the incorporation due to increased compliance requirements.<br/>If speed of incorporation is a priority, you might consider incorporating with individuals as the directors and adding in the corporate director following incorporation. Additional fees do apply.",
    INDIVIDUAL_NAME_FIELD: "Director name",
    INDIVIDUAL_NAME_PLACEHOLDER: "As it appears in their passport",
    INDIVIDUAL_OR_COMPANY_COMPANY_FIELD: "Company",
    COMPANY_NAME_FIELD: "Corporate director name",
    COMPANY_NAME_PLACEHOLDER: "As shown on the Certificate of Incorporation",
    COMPANY_LEGAL_ENTITY_STRUCTURE_FIELD: "Legal entity structure",
    COMPANY_LEGAL_ENTITY_STRUCTURE_SELECT: "Select from the list",
    DIRECTOR_ADDED_TITLE: "Do you have more directors to add?",
    DIRECTOR_ADDED_SUBTITLE: "Director added!",
    ADD_DIRECTOR_TITLE: "Are they an individual or a corporate stakeholder?",
    ADD_DIRECTOR_SUBTITLE: "Add a director",
    DIRECTOR_LEGAL_ENTITY_STRUCTURE_TITLE: "What is the legal entity structure of this corporate stakeholder?",
    CORPORATE_SUBTITLE: "Add a corporate director",
    LEGAL_ENTITY_STRUCTURE_TOOLTIP: "Shares held by a Corporate Stakeholder can be held in the name of a Company, a Partnership or a Trust. The KYC information and documents required will differ depending on the type of Corporate Stakeholder being added. <br/><br/>" +
        "For a <strong>Company</strong>, we need the registration documents, statutory registers and information about the shareholders and directors. <br/><br/>" +
        "For a <strong>Partnership</strong>, we need the registration documents, Register of Partners and information regarding the General Partner, including its directors and shareholders. <br/><br/>" +
        "For a <strong>Trust</strong>, we need a recently certified copy of the Trust Deed and information about the Trustee, Settlor and Beneficiaries.<br/><br/>" +
        "The above is indicative and the full list will be requested once you confirm the type of Corporate Shareholder you are adding.",
    FIELD_REQUIRED: "This field is required",
}

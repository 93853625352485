import React from "react"
import { Field } from "formik"
import classes from '../DocumentUploadForm.module.scss'
import Line from "src/components/generic/Line/Line"
import { TextInput } from 'src/v1/components';
import { ClaraSelect } from "src/v1/components";
import ClaraCountrySelector from 'src/v1/components/Form/ClaraCountrySelector/ClaraCountrySelector';
const PassportFields = ({ getAddsPartyList,individualStakeholders }) => {
    return (<>
        <Field name="party">
        {({ field, form }) => {
          return (
            <div className={`${classes.row} ${classes.Select}`}>
              <ClaraSelect
                label={`Party: `}
                field={field}
                required={true}
                form={form}
                placeholder={"Search or select from a list..."}
                adds={getAddsPartyList()}
                lists={[{ list: individualStakeholders }]}
              />
            </div>)
        }}
      </Field>
      <Line className={classes.Separator} />
        <Field name='passport.number'>
          {({ field, form }) => (
            <TextInput
              required={true}
              classNameInput={{ root: classes.row }}
              type='text'
              label='Passport Number: '
              field={field}
              form={form}
              placeholder={"Type here..."}
            />
          )}
        </Field>
        <Field name="passport.nationality">
          {({ field, form }) => {
            return (
              <div className={` ${classes.SelectCountry}`}>
                <ClaraCountrySelector
                  required={true}
                  isRequired={{ country: true }}
                  type='text'
                  countryField={"passport.nationality"}
                  label='Nationality: '
                  returnCountryAsString={true}
                  field={field}
                  additionalStyle={'ClaraCountrySelectorModal'}
                  form={form}
                  placeholder={"Type here..."}
                />
              </div>)
          }
          }
        </Field>
        <Field name="passport.expirationDate">
          {({ field, form }) => {
            return (
              <TextInput
                required={true}
                classNameInput={{ input: classes.Calendars, root: classes.labelCalendar }}
                type='date'
                messageErrorClassName={classes.MessageErrorDate}
                label='Expiration Date: '
                field={field}
                form={form}
                placeholder={"dd/mm/yyyy"}
              />)
          }}
        </Field>
    </>)
}
export default PassportFields;
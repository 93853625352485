import React, { FC, useState, useEffect } from 'react'
import Button from '../../../generic/components/Buttons/Button'
import ModalDelete from '../../../generic/components/Modal/ModalDelete/index';
import TemplateDelete from '../../../../modules/generic/templates/Modal/TemplateDelete';
import useSession from '../../../../modules/session/hooks/useSession';
import useTranslate from '../../../../modules/generic/hooks/useTranslate';
import useRemovePendingEntity from '../../../../hooks/services/modules/assistance/useRemovePendingEntity';
import Typography from '../../../../modules/generic/components/Texts/Typography';
import useModal from '../../../../modules/generic/hooks/useModal';
import classModule from './class.module.scss'
//TODO:Type Role
interface ModalRemoveEssentialProps {
  open: boolean
  role: string
}
const ModalRemoveEssential: FC<ModalRemoveEssentialProps> = ({ open, role }) => {

  const [isOpen, setOpen] = useState(open);
  const [removePendingEntity, loading] = useRemovePendingEntity();
  const { startupId } = useSession();
  const { translate } = useTranslate();
  const { closeModal } = useModal();

  const handleClose = () => {
    closeModal()
  }

  const handleSubmit = async () => {
    await removePendingEntity({ startupId, entityType: role })
    handleClose()
  }

  useEffect(() => {
    setOpen(open)
  }, [open])
  
  return <ModalDelete open={isOpen}>
    <TemplateDelete
      className={classModule.template}
      props={{
        title: translate('MODULES.MAP.MODAL_REMOVE_ESSENTIAL.TITLE', { role: role.toLowerCase() }),
        description: <Typography component='p' color='error'>{translate('MODULES.MAP.MODAL_REMOVE_ESSENTIAL.SUBTITLE', { role: role.toLowerCase() })}</Typography>,
      }}
      buttons={{
        submit: <Button variant='submitDelete' loading={!!loading} onClick={handleSubmit}>{translate('MODULES.MAP.MODAL_REMOVE_ESSENTIAL.CONFIRM')}</Button>,
        cancel: <Button variant='cancelSubmit' onClick={handleClose}>{translate('MODULES.MAP.MODAL_REMOVE_ESSENTIAL.CANCEL')}</Button>
      }}
    ></TemplateDelete>
  </ModalDelete>
}
export default ModalRemoveEssential

import { gql, useQuery } from '@apollo/client';
const GET_DOCUMENT_REGIONS = gql`query {
    getDocumentRegions  {
          code
          name
      }
    }`;
  const useGetDocumentRegions = (config)=>{
    const {data,loading}= useQuery(GET_DOCUMENT_REGIONS,config)
    return {data:data?.getDocumentRegions ?? [] ,loading}
  }
  export default useGetDocumentRegions;
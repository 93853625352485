import { get } from "lodash";
import { useMemo } from 'react';

const useGuards = () => {
  
  return useMemo(() => ({
    formSPV: (context) => {
      return (get(context, 'ADGMSPVEligibility.shareOwnership_firstQuestion', true) === true && get(context, 'ADGMSPVEligibility.shareOwnership_secondQuestion', false) === false);
    },
    formADGMAuthorisedSignatory: (context) => {
      return (get(context, 'ADGMSPVEligibility.shareOwnership_firstQuestion', false) === false && get(context, 'ADGMSPVEligibility.shareOwnership_secondQuestion', true) === true);
    },
    formInCayman: (context) => {
      return (get(context, 'ADGMSPVEligibility.shareOwnership_firstQuestion', false) === false && get(context, 'ADGMSPVEligibility.shareOwnership_secondQuestion', false) === false);
    },
    formWithConcierge: (context) => {
      return get(context, 'FormSPVInADGM.agreeSPVRules', false) === false;
    },
    formWithADGM: (context) => {
      return get(context, 'FormSPVInADGM.agreeSPVRules', true) === true;
    },
    isChooseJurisdiction: (context) => {
      return !get(context, 'CaymanIsBetter.formInCayman')
    },
    isCayman: (context) => {
      return get(context, 'CaymanIsBetter.formInCayman')
    },
    formWithADGMTechLicence: (context) => {
      return get(context, 'BetterFitADGMTL.selectOrChangeLocation')
    },
    changeLocation: (context) => {
      return !get(context, 'BetterFitADGMTL.selectOrChangeLocation')
    }
  }), []);
}

export default useGuards;

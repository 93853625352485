import React, { useEffect, useState } from "react";
import SelectInput from "src/components/inputs/Select/SelectInput/SelectInput";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import { HiddenFieldForm } from "src/components/forms/contents";
import TextareaInput from "src/components/inputs/Textarea/TextareaInput";

interface PurposeOfEntitySelectProps {
  initialValue?: any;
}

function PurposeOfEntitySelect({ initialValue = null }: PurposeOfEntitySelectProps) {
  const [selectPurposeOfEntityValue, setSelectPurposeOfEntityValue] = useState(initialValue?.code);

  useEffect(() => {
    setSelectPurposeOfEntityValue(initialValue);
  }, [initialValue]);


  const purposeOfEntityOptions = [
    {
      id: "HOLDING_COMPANY_UNDERLYING_BUSINESS",
      label: "Holding Company for an underlying business",
    },
    {
      id: "PERSONAL_ASSETS_HOLDING_COMPANY",
      label: "Personal Assets Holding Company",
    },
    {
      id: "OTHER",
      label: "Other"
    },
  ];

  return (
    <>
      <TemplateLabel label="Purpose of entity" isRequired={true}>
        <ControllerInput
          render={SelectInput}
          name="purposeOfEntity"
          placeholder="Select from the list"
          list={purposeOfEntityOptions}
          clear={true}
          value={initialValue?.code}
        />
      </TemplateLabel>

      <HiddenFieldForm
        fieldsWatch="purposeOfEntity"
        conditionHideCallback={(value) => {
          setSelectPurposeOfEntityValue(value);
          if (
            value === "OTHER" ||
            value === "PERSONAL_ASSETS_HOLDING_COMPANY"
          ) {
            return true;
          } else {
            return false;
          }
        }}
      >
        <TemplateLabel
          label="Please describe the purpose of the new company"
          isRequired={true}
          isColumn
        >
          <ControllerInput
            name="purposeOfEntityDescription"
            render={TextareaInput}
            defaultValue=""
            value={initialValue?.description}
            placeholder={selectPurposeOfEntityValue === "OTHER" ? "Type here" : "E.g. the company will own a marine vessel registered with the Cayman Islands Shipping Registry."}
          />
        </TemplateLabel>
      </HiddenFieldForm>
    </>
  );
}

export default PurposeOfEntitySelect;
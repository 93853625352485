import React, { useEffect } from 'react';
import useQuery from "../../../../custom/useQuery"

const query = `query getStartupUsersProfiles($startupId:ID!) {
    getStartupUsersProfiles(startupId: $startupId) {
        _id
        userId
        role
        fullName
        avatar
        email
        lastLogin
        lastInvitationSent
    }
}`;
const useShareProfiles = (variables,config) => {
  const configDefault = {errorMessage: 'GENERIC_PERMISSION_ERROR', showErrorNotification: true, showSuccessNotification: false}
  const { loading, error, data ,refetch} = useQuery(query, variables, {...configDefault,...config});
  useEffect(() => {

  }, []);
  return { loading, error, data,refetch };
}
export default useShareProfiles;

import React, { FC, useEffect, useRef } from 'react';
import { useDispatch } from "react-redux";
import * as Yup from 'yup';
import Form from "../../../../../components/forms/Form/Form";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import FormProps from "../../../../../modules/generic/forms/interfaces/FormProps";
import { addAssistantText, clearAssistatText } from "../../../../../modules/generic/store/action";
import TemplateFormDefault from "../../../../../modules/generic/templates/Modal";
import CheckboxButtonSimple from "../../../../generic/components/Inputs/CheckboxButtonSimple";
import ControllerInput from "../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import classes from './classes.module.scss';
import useTranslate from '../../../../generic/hooks/useTranslate';
import useSession from '../../../../../modules/session/hooks/useSession';
import _ from 'lodash';

const schema = Yup.object().shape({
  response: Yup.boolean().nullable().required("Required"),
})

interface Props extends FormProps {
  initialValues: any,
  onCompleteSubmit: any,
  key: string,
  handlePrev: any,
}

const FormBoardResolutionTypeDocument: FC<Props> = ({
  initialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
  handlePrev,
  key,
}) => {

  const refForm = useRef();
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const { startup } = useSession();
  const acceleratorName = _.get(startup, "acceleratorDef.name", "");

  useEffect(() => {
    handeAddTooltips();
    return () => {
      dispatch(clearAssistatText());
    }
  }, []);

  const handeAddTooltips = () => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText(translate('BOARD_RESOLUTION_STEP1_TOOLTIP1', { acceleratorName: acceleratorName})));
    dispatch(addAssistantText(translate('BOARD_RESOLUTION_STEP1_TOOLTIP2', { acceleratorName: acceleratorName})));
  }

  const options = [
    {
      value: false,
      label: translate(
        `BOARD_RESOLUTION_STEP1_OPTION1`
      )
    },
    {
      value: true,
      label: translate(
        `BOARD_RESOLUTION_STEP1_OPTION2`
      )
    }
  ]

  return (
    <>
      <Form
        key={key}
        defaultValues={initialValues || {}}
        schema={schema}
        onSubmit={onCompleteSubmit}
        ref={refForm} optionsForm={{ mode: "onChange" }}
        onlySendDirty={false}
      >
        <Template 
          props={propsTemplate} 
          buttons={{
            ...buttons,
            submit: <SubmitButton {...buttonSubmitProps}>Next</SubmitButton>,
          }}
        >
          <ControllerInput
            render={CheckboxButtonSimple}
            name='response'
            classNameButton={classes.buttonCheckBox}
            options={options}
            defaultValue={[]}
            onChange={(value) => {}}
            isMultiple={false}
          />
        </Template>
      </Form>
    </>
  )
}

export default FormBoardResolutionTypeDocument
import React, { FC } from "react";
import classesModule from './SubscriptionDetails.module.scss';
import TypographyLocal from "../../../generic/components/Texts/Typography";
import ScaleItem from "../ScaleItem";

/**
 * SubscriptionDetails
 */

 export interface SubscriptionDetailsProps {
  /** Property children */
  children?: any,
  /** Property saveUpTitle */
  saveUpTitle?: string,
  /** Property saveUpSubtitle */
  saveUpSubtitle?: string,
  /** Property detailTitle */
  detailTitle?: string,
  /** Property detailSubtitle */
  detailSubtitle?: string,
  /** Property scaleItemList */
  scaleItemList?: any,
}

const SubscriptionDetails: FC<SubscriptionDetailsProps> = ({
  children,
  saveUpTitle,
  saveUpSubtitle,
  detailTitle,
  detailSubtitle,
  scaleItemList,
}:SubscriptionDetailsProps) => {

  return (
    <div className={` ${classesModule.SubscriptionDetails} `}>
      {!!saveUpTitle && (
        <div className={` ${classesModule.cornerContainer} `}>
          <div className={` ${classesModule.cornerRounder} `}>
            <div className={` ${classesModule.cornerAngle} `}></div>
          </div>
          <div className={` ${classesModule.cornerDetail} `}>
            <TypographyLocal
              className={` ${classesModule.textCorner} `}
              color={"white"}
            >
              {saveUpTitle}
            </TypographyLocal>
            {!!saveUpSubtitle && (
              <TypographyLocal
                color={"white"}
              >
                {saveUpSubtitle}
              </TypographyLocal>
            )}
          </div>
        </div>
      )}
      <div className={` ${classesModule.dataSpace} `}>
        <div className={` ${classesModule.titleSpace} `}>
          <TypographyLocal
            className={` ${classesModule.textTitle} `}
            color={"pink"}
          >
            {detailTitle}
          </TypographyLocal>
          <TypographyLocal
            className={` ${classesModule.textSubTitle} `}
          >
            {detailSubtitle}
          </TypographyLocal>
        </div>
        <div className={` ${classesModule.listSpace} `}>
          {scaleItemList.map((element, i) => {
            return <ScaleItem
              icon={element.icon}
              title={element.title}
              key={i}
            ></ScaleItem>
          })}
          {/* <div className={classesModule.button}> */}
            {children}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}
export default SubscriptionDetails;
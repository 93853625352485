import React, { Component } from 'react';
import ModalLoading from "../../../../../components/generic/Loading/ModalLoading/ModalLoading";
import { helper } from "../../../../utils";
import classes from "./Step2.module.scss";
class Step2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoading: true
        }
    };

    getTextByType = (type) => {
        let docType = helper.getTranslateTextByKey(type);
        return helper.getTranslateTextByKey('DOCTYPE_LOADING_TEXT', { docType })
    }



    render() {
        const { label } = this.props;
        return (
            <div className={classes.Step2}>
                <ModalLoading
                    loading={this.state.showLoading}
                    label={this.getTextByType(label)}
                ></ModalLoading>
            </div>
        );
    }
}

export default Step2;

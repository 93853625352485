import { useContext,useEffect } from 'react'
import { RegionContext } from '../ChooseDocuments/contexts/Region';
import avatarInternational from 'src/images/documents_region_international.svg';
import avatarUnitedStates from 'src/images/documents_region_us.svg';
import avatarYcombinator from 'src/images/documents_region_Y_combinator.svg';
import useSession from 'src/modules/session/hooks/useSession';
import get from 'lodash/get'
import { useDispatch } from 'react-redux';
import useUpsertStartupOptions from 'src/hooks/services/modules/startups/optionsSelected/useUpsertStartupOptions';
export enum DOCUMENT_REGIONS {
  INTERNATIONAL_DOCUMENTS_TYPES = "INTERNATIONAL_DOCUMENTS_TYPES",
  UNITED_STATES_DOCUMENTS_TYPES = "UNITED_STATES_DOCUMENTS_TYPES",
  Y_COMBINATOR_DOCUMENTS_TYPES = "Y_COMBINATOR_DOCUMENTS_TYPES"
}
const avatars = {
    [DOCUMENT_REGIONS.INTERNATIONAL_DOCUMENTS_TYPES]: avatarInternational,
    [DOCUMENT_REGIONS.UNITED_STATES_DOCUMENTS_TYPES]: avatarUnitedStates,
    [DOCUMENT_REGIONS.Y_COMBINATOR_DOCUMENTS_TYPES]:avatarYcombinator
  }
  const useRegion = ()=>{
    const {setRegion,region,regions,loading} = useContext(RegionContext)
    const { startup } = useSession()
    const dispatch = useDispatch();
    const [mutation] = useUpsertStartupOptions({ showSuccessNotification: false });
    const optionsSelectedFromState = startup.optionsSelected;

    const getAvatarByCode = (r) =>{
      return avatars[r]
    }

  
    const handleChangeRegionMutation = async (regionFilter) => {
      if(regionFilter){
        const variables = {
          id: startup.id,
          startupData: {
            optionsSelected: {
              regionFilter
            }
          }
        }
  
        await mutation(variables);
  
        const dispatchStartup = {
          optionsSelected: {
            ...optionsSelectedFromState,
            regionFilter
          }
        }
  
        dispatch({ type: "SESSION_UPDATE_STARTUP", startup: dispatchStartup });
      }
    }
  
    const getRegion = (reg) => {
      return regions.find(r => r.code === reg)
    }

    useEffect(() => {
      if (!get(optionsSelectedFromState, 'regionFilter')) return setRegion(DOCUMENT_REGIONS.INTERNATIONAL_DOCUMENTS_TYPES)
      setRegion(get(optionsSelectedFromState, 'regionFilter'))
    }, [optionsSelectedFromState]);

    return {
      setRegion,
      region,
      regions,
      getRegion,
      handleChangeRegionMutation,
      getAvatarByCode,
      loadingRegions:loading
    }
  }
  export default useRegion;
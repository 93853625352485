import React, { useEffect, useState } from 'react';
import ServerConnect from '../../../../utils/ServerConnect';
import Mustache from 'mustache';
import _ from 'lodash'
import { useParams, useLocation } from 'react-router-dom'

export const useGetDirectorsAndShareholders = ({ matterId, isDraft, isShareholder, idShareholder, draftId }) => {

  const [list, setList] = useState([]);
  const location = useLocation();

  const getPossibleDirectorsAndShareholders = `
    {
        getPossibleDirectorsAndShareholders(
          matterId: "{{matterId}}",
          isDraft: {{isDraft}},
          draftId: "{{draftId}}"
        ) {
          individuals {
            id
            firstName
            lastName
            email
          }
          companies {
           companyName
            id
            signatory{
                firstName:firstName
                lastName:lastName
                email:email
            }
          }
        }
      }
    `

  const getPossibleQuestionnaireDirectors = `
    {
      getPossibleQuestionnaireDirectors(
          matterId: "{{matterId}}",
          isDraft: {{isDraft}},
          draftId: "{{draftId}}"
        ) {
          individuals {
            id
            firstName
            lastName
            email
          }
          companies {
           companyName
            id
            signatory{
                firstName:firstName
                lastName:lastName
                email:email
            }
          }
        }
      }
    `




  useEffect(() => {
    if (_.isEmpty(list)) {

      const isQuestionnaire = _.includes(location.pathname, 'questionnaire');

      const query = Mustache.render(isQuestionnaire ? getPossibleQuestionnaireDirectors : getPossibleDirectorsAndShareholders, { matterId, isDraft, draftId });
      ServerConnect.graphQlQuery(query).then(result => {
        let { individuals, companies } = _.get(result, isQuestionnaire ? `getPossibleQuestionnaireDirectors` : `getPossibleDirectorsAndShareholders`, [])
        setList([...individuals, ..._.map(companies, com => {
          const { id, companyName, signatory } = com;
          return { id, companyName, ...signatory }
        }).filter(com => com.id !== idShareholder)])
      });
    }
  }, [matterId, isDraft])


  return { list }
}


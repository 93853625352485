import TagManager from 'react-gtm-module';
import _ from "lodash"

const pendingMembersCount = (entities) => {
    let members = []
    let count = 0
    if (entities) {
        _.forEach(entities, (entity) => {
            if (entity.type == "FOUNDER" || entity.type == "ADVISOR"  || entity.type == "CONSULTANT"  || entity.type == "EMPLOYEE"  ) {
                count += entity.remains
            } 
        })

    }
    return count
}
export default (result) => {
    const countPendings = pendingMembersCount(_.get(result, "startUp.pendingEntities"));
    if (countPendings == 0) {
        const tagManagerArgsFinish = {
            dataLayer: {
                event: "PageView",
                path: "maps/?status=addInvestors",
                pageName: "Stakeholder Map - Add Investors"
            },
            dataLayerName: "PageDataLayer",
        };
        TagManager.dataLayer(tagManagerArgsFinish);
    }else{
        const tagManagerArgs = {
            dataLayer: {
                event: "PageView",
                path: "maps/?status=afterTeamMemberCreated",
                pageName: "Stakeholder Map - After Each Team Member Added"
            },
            dataLayerName: "PageDataLayer",
        };
        TagManager.dataLayer(tagManagerArgs);   
    }
}
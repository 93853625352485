import _ from 'lodash'
import { useSelector } from 'react-redux'
import { Constants } from 'src/v1/utils/constants';
export interface UseBusinessRulesReturn {
  isItemEnabled: (product: any, company: any) => boolean,
  isUsingNominee: (company: any) => boolean
}

function useBusinessRules(): UseBusinessRulesReturn {
  const session = useSelector(state => _.get(state, 'session'))

  const isItemEnabled = (item,company) => {
    if (!company?.formationData?.isUsingNominee && item?.code === Constants.MODULES.FORMATION.ITEMS.ADGM_NOMENEE_FEE) {
      return false
    }
    return true
  }
 
  const isUsingNominee = (company) => {
    const companyUsingNominee = _.get(company, 'formationData.isUsingNominee');
    return (companyUsingNominee === null ? false : companyUsingNominee)
  }

  return {
    isItemEnabled,
    isUsingNominee
  }
}

export default useBusinessRules

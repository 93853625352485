import React from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";

const AddLapse = ({ go, ...props }) => {
  const params = useParams();

  return (
    <MicroFrontend id="MF_Add_Lapse_Content_View" component={"./AddLapse"} mf="capTable" params={{ go, ...props, ...params }} />
  );
};

export default AddLapse;

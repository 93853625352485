import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import _ from "lodash";

const HiddenFieldForm = ({
  fieldsWatch,
  conditionHideCallback = (value) => value,
  children,
  renderChildren = false,
}) => {
  const { watch } = useFormContext();
  const valuesWatch = watch(fieldsWatch);

  const hide = useMemo(() => {
    if (typeof fieldsWatch == "object") {
      const fieldValues = {};
      if (valuesWatch) {
        _.forEach(fieldsWatch, (field, index) => {
          fieldValues[field] = valuesWatch[index];
        });
      }
      return conditionHideCallback(fieldValues);
    } else {
      return conditionHideCallback(valuesWatch);
    }
  }, [JSON.stringify(valuesWatch)]);

  return (
    <>
      {renderChildren ? (
        <div style={{ display: hide ? "block" : "none" }}>{children}</div>
      ) : (
        <>{hide && children}</>
      )}
    </>
  );
};

export default HiddenFieldForm;

import React, {useState} from 'react';
import {ClaraRadioWithButton, Text, TextInput} from '../index';

import classes from "./Chat.module.scss";
import {Field} from "formik";

const ChatKYCEntityHowManyEntities = ({ responseKeyName, submitQuestion }) => {
  const nameField = responseKeyName ? responseKeyName + "." : "";
  const [singleJount, setSingleJount] = useState(false);


  const [optionsButtons, setOptionsButtons] = useState([
    { 'label': 'TEXT_BUTTON_SINGLE', 'value': "single" },
    { 'label': 'TEXT_BUTTON_JOINT', 'value': "joint" }
  ]);

  const validate = (value)=>{
    let errorMesage;
    if( value <= 0 || value == "" ){ 
      errorMesage = "Invalid Value";
    }
    return errorMesage;
      
     }

  return (
    <React.Fragment>
      <div className={`${classes.ChatQuestionnaire} ${classes.ChatKYCEntityHowManyEntities}`}>

        <Field name={nameField + "singleJount"}>
          {({ field, form }) => (
            <React.Fragment>
              <ClaraRadioWithButton
                field={field}
                form={form}
                changeParams={field.name}
                options={optionsButtons}
                className={{
                  root: `${classes.ChatClaraRadioButton} ${classes.ChatClaraRadioButtonMany}`,
                  input: classes.ChatClaraRadioButtonInput,
                  button: classes.ChatClaraRadioButtonOption
                }}
                onChange={(v) => { setSingleJount(v) }}

              />
            </React.Fragment>
          )}
        </Field>
        {singleJount === "joint" && (
          <React.Fragment>
            <div className={classes.NumerOfCompany}>
              <label><Text uuid={"TEXT_NUMBER_OF_COMPANIES"}/></label>
              <div>
                <Field name={nameField + "entitiesAmount"} validate={validate} >
                  {({ field, form }) => (
                    <React.Fragment>
                      <TextInput
                        showRedStar={false}
                        required={true}
                        type='number'
                        field={field}
                        label={'    '}
                        form={form}
                        placeholder={""}
                        className={classes.ChatInputNumberOfCompanyText}
                      />
                    </React.Fragment>
                  )}
                </Field>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment >
  );
}
export default ChatKYCEntityHowManyEntities;

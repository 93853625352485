import { useMemo } from 'react';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import ReviewAndConfirmCapTableFormStep from '../steps/ReviewAndConfirmCapTableFormStep';

const useSteps = () => {

  return useMemo(() => ({
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    ReviewAndConfirmCapTable: {
      render: ReviewAndConfirmCapTableFormStep,
    },
    SaveStep: {
      render: TemplateWizardSkeletonMachine,
    },
  }), []);
}

export default useSteps;

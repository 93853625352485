import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import classes from './Circular.module.scss'

const Loading = ({ loading, containerLoadingStyle={}, children, label=null, showChildren = false, loadingDataComplete = true }) => {


    const classesShowLoading = showChildren ? classes.showChildren : "";

    return (
        <>
            {loading &&
                <div style={ containerLoadingStyle} className={`${classes.Circular} ${classesShowLoading}`}>
                    <CircularProgress />
                    {label && <span>{label}</span>}
                </div>
            }

            {loadingDataComplete && !loading && <>{children}</>}
        </>

    )
}
export default Loading;
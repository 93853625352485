import React, { useState, useEffect } from 'react';

import classes from './Styles.module.scss';
import Form from '../../../../../../components/forms/Form/Form';
import RowAdd from '../../../../contents/RowAdd/RowAdd';
import TextInput from "../../../../../../components/inputs/Text/TextInput"
import ControllerInput from "../../../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput"
import * as Yup from 'yup';
import _ from 'lodash';

import { useSelector } from 'react-redux'
import useMutation from '../../../../../../hooks/custom/useMutation'

import SubmitButtonSubForm from '../../../../../../components/generic/Buttons/SubmitButtonSubForm/SubmitButtonSubForm';

const UPSERT_SHARE_CLASS = `
mutation upsertComponentShareIncentivePlan(
    $startupId: ID!
    $shareIncentivePlan: ComponentShareIncentivePlanInputType! 
  ) {
    upsertComponentShareIncentivePlan(
      startupId: $startupId
      shareIncentivePlan: $shareIncentivePlan
      
    ) {
      id
      name
      totalShares
      shareClass{
      id
      shareIncentivePlans{
      id
      name
      totalShares
      }
      }
    }
  }
`




const FormAddGroupCompany = ({ startupId, handleCloseForm, handleAddValueList, shareClassId, groupCompanyId, ...props }) => {

    const handleOnCompletedMutation = ({ upsertComponentShareIncentivePlan }) => {
        handleCloseForm()
        handleAddValueList(upsertComponentShareIncentivePlan)
    }

    const [upsertCompany] = useMutation(UPSERT_SHARE_CLASS, { onCompleted: handleOnCompletedMutation });


    const handleSubmit =async ({ name, totalShares }) => {
      await  upsertCompany({
            variables: {
                startupId,
                shareIncentivePlan: {
                    name,
                    shareClassId,
                    groupCompanyId,
                    totalShares
                }
            }
        })

    }



    return (
        <Form initialValues={{ data: 'asdasdasd' }}
            schema={Yup.object().shape({
                name: Yup.string().required('This field is required'),
                totalShares:Yup.number().nullable().required('This field is required').typeError('Must be a number'),

            })} >
            <div className={classes.containerSipForm}>
                <div className={classes.containerTextSipForm}>
                    <RowAdd
                        label={"STARTUP_VIEW_SHARING_TABLE_TITLE_NAME"}
                        asterisk
                        input={
                            <ControllerInput
                                as={TextInput}
                                name={'name'}
                                placeholder={"Enter SIP Name..."}
                                onChange={(e) => {

                                }}
                            />
                        } />

                    <RowAdd
                        label={"RESERVED_SHARES_FIELD"}
                        asterisk
                        input={
                            <ControllerInput
                                as={TextInput}
                                type={'number'}
                                name={'totalShares'}
                                placeholder={"Enter number..."}
                                onChange={(e) => {

                                }}
                            />
                        } />







                    <SubmitButtonSubForm onClick={handleSubmit}  >
                        Save
                    </SubmitButtonSubForm>
                </div>
                {/*messageError && <span className={classes.error}>
                        {messageError}
                        </span>*/}



            </div>
        </Form>

    )

}


export default FormAddGroupCompany

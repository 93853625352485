import _ from "lodash"
import  moment  from 'moment';
import { mapEntityData } from "./mapEntityData";
const mapData = (message:any) => {
    //console.log(message)
    if (!_.get(message,"template.type")){
        return message;
    }



    switch (message.template.type) {

        case "STAKEHOLDER_EDITED":
            return { userName: _.get(message, "createdBy.fullName", ""), stakeholderName: _.get(message, "template.stakeholder.fullName", "") };

        case "STAKEHOLDER_CREATED":
            return { userName: _.get(message, "createdBy.fullName", ""), stakeholderName: _.get(message, "template.stakeholder.fullName", "") };

        case "STAKEHOLDER_DELETED":
            return { userName: _.get(message, "createdBy.fullName", ""), stakeholderName: _.get(message, "template.stakeholder.fullName", "") };

        case "EQUITY_POSITION_CREATED":
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template.equityPosition };

        case "EQUITY_POSITION_DELETED":
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template.equityPosition };

        case "EQUITY_POSITION_EDITED":
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template.equityPosition };

        // ACT
        case "ACT_EQUITY_CREATED":
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template, ...mapEntityData({ ...message.template }), entityAction: "added" };
        case "ACT_EQUITY_EDITED":
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template, ...mapEntityData({ ...message.template }), entityAction: "edited" };
        case "ACT_EQUITY_DELETED":
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template, ...mapEntityData({ ...message.template }), entityAction: "deleted" };
        case "ACT_EQUITY_PUBLISHED":
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template, ...mapEntityData({ ...message.template }), entityAction: "published" };

        case "ACT_EQUITY_PUBLISH_AND_SHARING":
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template, ...mapEntityData({ ...message.template }), entityAction: "published and shared" };

        // Option Plan
        case "OPTION_PLAN_CREATED":
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template, ...mapEntityData({ ...message.template }), entityAction: "added" };
        case "OPTION_PLAN_EDITED":
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template, ...mapEntityData({ ...message.template }), entityAction: "edited" };
        case "OPTION_PLAN_DELETED":
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template, ...mapEntityData({ ...message.template }), entityAction: "deleted" };
        case "OPTION_PLAN_PUBLISHED":
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template, ...mapEntityData({ ...message.template }), entityAction: "published" };

        case "OPTION_PLAN_PUBLISH_AND_SHARING":
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template, ...mapEntityData({ ...message.template }), entityAction: "published" };

        // ShareClass
        case "SHARE_CLASS_CREATED":
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template, ...mapEntityData({ ...message.template }), entityAction: "added" };
        case "SHARE_CLASS_EDITED":
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template, ...mapEntityData({ ...message.template }), entityAction: "edited" };
        case "SHARE_CLASS_DELETED":
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template, ...mapEntityData({ ...message.template }), entityAction: "deleted" };
        case "SHARE_CLASS_PUBLISHED":
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template, ...mapEntityData({ ...message.template }), entityAction: "published" };

        case "SHARE_CLASS_PUBLISH_AND_SHARING":
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template, ...mapEntityData({ ...message.template }), entityAction: "published and sharing" };

        // Follow-on Transactions
        case "FOT_CREATED":
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template, ...mapEntityData({ ...message.template }), entityAction: "added" };
        case "FOT_EDITED":
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template, ...mapEntityData({ ...message.template }), entityAction: "edited" };
        case "FOT_DELETED":
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template, ...mapEntityData({ ...message.template }), entityAction: "deleted" };
        case "FOT_PUBLISHED":
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template, ...mapEntityData({ ...message.template }), entityAction: "published" };

        //  Vesting Term
        case "VESTING_TERM_CREATED":
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template, ...mapEntityData({ ...message.template }), entityAction: "added" };
        case "VESTING_TERM_EDITED":
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template, ...mapEntityData({ ...message.template }), entityAction: "edited" };
        case "VESTING_TERM_DELETED":
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template, ...mapEntityData({ ...message.template }), entityAction: "deleted" };
        case "VESTING_TERM_PUBLISHED":
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template, ...mapEntityData({ ...message.template }), entityAction: "published" };

        // Valuation
        case "VALUATION_CREATED":
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template, ...mapEntityData({ ...message.template }), entityAction: "added" };
        case "VALUATION_EDITED":
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template, ...mapEntityData({ ...message.template }), entityAction: "edited" };
        case "VALUATION_DELETED":
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template, ...mapEntityData({ ...message.template }), entityAction: "deleted" };
        case "VALUATION_PUBLISHED":
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template, ...mapEntityData({ ...message.template }), entityAction: "published" };

        // Modelling
        case "SCENARIO_MODELLING_CREATED":
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template, ...mapEntityData({ ...message.template }), entityAction: "added" };
        case "SCENARIO_MODELLING_DELETED":
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template, ...mapEntityData({ ...message.template }), entityAction: "deleted" };




        case "COMPANY_CREATED":
            return { userName: _.get(message, "createdBy.fullName", ""), companyName: _.get(message, "template.groupCompany.name", "") };

        case "COMPANY_EDITED":
            return { userName: _.get(message, "createdBy.fullName", ""), companyName: _.get(message, "template.groupCompany.name", "") };

        case "COMPANY_DELETED":
            return { userName: _.get(message, "createdBy.fullName", ""), companyName: _.get(message, "template.groupCompany.name", "") };

        case "PROFILE_CREATED":
            return { userName: _.get(message, "createdBy.fullName", ""), startupName: message.template.startupName };

        case "PROFILE_EDITED":
            return { userName: _.get(message, "createdBy.fullName", ""), startupName: message.template.startupName };


        case "DOCUMENT_CREATED":
            return { documentName: message.template.agreement.title, userName: _.get(message, "createdBy.fullName", "") };

        case "DOCUMENT_DELETED": {
            const documentName = message.template.agreement.title ? message.template.agreement.title : message.template.agreement.name;
            return { documentName, userName: _.get(message, "createdBy.fullName", "") };
        }
        case "DOCUMENT_EDITED":
            return { userName: _.get(message, "createdBy.fullName", ""), documentName: message.template.agreement.title, ...message.template };

        case "DOCUMENT_CATEGORIZED":
            return {
                userName: _.get(message, "createdBy.fullName", ""),
                documentName: message.template.agreement.title
            };



        case "GENERATED_DOCUMENT_CREATED":
            return { documentName: message.template.agreement.title, userName: _.get(message, "createdBy.fullName", "") };

        case "GENERATED_DOCUMENT_SENT":
            return {
                documentName: _.get(message, "template.agreement.title", ""),
                userName: _.get(message, "createdBy.fullName", "")
            };

        case "GENERATED_DOCUMENT_VOID":
            return {
                documentName: _.get(message, "template.agreement.title", ""),
                userName: _.get(message, "createdBy.fullName", "")
            };

        case "GENERATED_DOCUMENT_RESEND":
            return {
                documentName: _.get(message, "template.agreement.title", ""),
                userName: _.get(message, "createdBy.fullName", "")
            };

        case "GENERATED_DOCUMENT_SIGNED":
            return { documentName: "Ip assingment", userName: _.get(message, "createdBy.fullName", "") };

        case "DUE_DILIGENCE_CREATED":
            {
                const { title: remarkName, urgency } = message.template.remark;

                return { userName: _.get(message, "createdBy.fullName", ""), remarkName, urgency };
            }
        case "DUE_DILIGENCE_DELETED":
            {
                const remarkName = message.template.remark.title;

                return { userName: _.get(message, "createdBy.fullName", ""), remarkName };
            }

        case "DUE_DILIGENCE_EDITED":
            {
                //  const remarkName = message.template.remark.title;

                return { userName: _.get(message, "createdBy.fullName", "") };
            }

        case "DUE_DILIGENCE_COMMENTED":
            {
                return { userName: _.get(message, "createdBy.fullName", ""), remarkName: message.template.remarkName };
            }


        case "PROFILE_GRANTED": {
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template.sharingProfile };
        }
        case "PROFILE_GRANT_REMOVED": {
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template.sharingProfile };
        }
        case "PROFILE_GRANT_CHANGED": {
            return { userName: _.get(message, "createdBy.fullName", ""), ...message.template.sharingProfile };
        }

        case "REPORT_DOWNLOADED":
            return { userName: _.get(message, "createdBy.fullName", "") }

        case "UNCATEGORIZED_DOCUMENT_CREATED":
            return { userName: _.get(message, "createdBy.fullName", ""), documentName: message.template.file.name }

        case "UNCATEGORIZED_DOCUMENT_DELETED":
            return { userName: _.get(message, "createdBy.fullName", ""), documentName: message.template.file.name }
   
        case "DOCUMENT_VESTING_DATE_TODAY":{
            return { userName: _.get(message, "createdBy.fullName", ""),
            grantName:_.get(message, "template.document.title") || _.get(message, "template.document.file.name", ""),
                     stakeholderName: _.get(message, "template.stakeholder.fullName", ""),
                     numberOfOptionsInVestingPeriod: _.get(message, "template.vestingDate.numberOfOptions", ""),
                    }
        }
        case "DOCUMENT_VESTING_DATE_UPCOMING":{
            return { 
            userName: _.get(message, "createdBy.fullName", ""),
            grantName:_.get(message, "template.document.title") || _.get(message, "template.document.file.name", ""),
            stakeholderName: _.get(message, "template.stakeholder.fullName", ""),
            numberOfOptionsInVestingPeriod: _.get(message, "template.vestingDate.numberOfOptions", ""),
                    }
        }
       case  "GENERATED_DOCUMENT_TERMINATED":
            return {
                userName: _.get(message, "createdBy.fullName", ""),
                grantName:_.get(message, "template.agreement.title") || _.get(message, "template.agreement.file.name", ""),
                date:moment(_.get(message,'template.agreement.updatedAt')).format('DD MMM YYYY'),
                time:moment(_.get(message,'template.agreement.updatedAt')).format('hh:mm')
            }
            case  "DOCUMENT_CHANGED_NAME":
                return {
                    documentName:_.get(message, "template.oldAgreementName"),
                    userName: _.get(message, "createdBy.fullName", ""),
                    newDocumentName:_.get(message, "template.agreement.title") || _.get(message, "template.agreement.file.name", ""),
                    date:moment(_.get(message,'template.agreement.updatedAt')).format('DD MMM YYYY'),
                    time:moment(_.get(message,'template.agreement.updatedAt')).format('hh:mm')
                }    
        default:
            return {};
    }
}
export {
    mapData
}
import useSession from "src/modules/session/hooks/useSession";
import updateStakeholderExpiredDocument from "../graphql/useUpdateMainIdentityDocumentStakeholder";
import useGetStakeholder from 'src/modules/startup/Stakeholder/PersonalInfoModal/wizard/hooks/graphql/useGetStakeholder';

const useServices = ({ initialValues }) => {
  const { stakeholderId, documentId, documentType, country } = initialValues;
  const { startupId } = useSession();
  const mutation = updateStakeholderExpiredDocument();
  const { query } = useGetStakeholder({});

  return {
    initialData: async () => {
      const data = await query({ startupId, stakeholderId });
      return {
        ...initialValues,
        stakeholderData: data?.stakeholder,
      };
    },
    SaveStep: async (context) => {
      let identityDocumentData: {
        id: string;
        type: string;
        nationality: string;
        notRenewed?: boolean;
        notRenewedReason?: string;
        files?: any;
        number?: string;
        expiryDate?: string;
      } = { id: documentId, type: documentType, nationality: country?.code };
      if (context?.isSameNacionality) {
        const {
          DocumentDetailsStep,
          UploadDocumentStep,
        } = context;
        const { files } = UploadDocumentStep;
        const { passportNumber, expiryDate } = DocumentDetailsStep;
        identityDocumentData = {
          ...identityDocumentData,
          files,
          number: passportNumber,
          expiryDate,
        };
      } else {
        const {
          HasBeenRenewedStep,
          DocumentDetailsStep,
          UploadDocumentStep,
        } = context;
        const { renewed, description } = HasBeenRenewedStep;
        if (renewed === false) {
          identityDocumentData = {
            ...identityDocumentData,
            notRenewed: true,
            notRenewedReason: description,
          };
        } else {
          const { files } = UploadDocumentStep;
          const { passportNumber, expiryDate } = DocumentDetailsStep;
          identityDocumentData = {
            ...identityDocumentData,
            files,
            number: passportNumber,
            expiryDate,
          };
        }
      }
      const variables = {
        stakeholderId,
        startupId,
        identityDocumentData,
      };
      return await mutation({ variables });
    },
  };
};

export default useServices;

import React from "react";
import MicroFrontend from "src/mf";

const MenuCaptable = ({ go, groupCompanyId, ...props }) => {

  return (
    <MicroFrontend id="MF_ShareClass_Menu_View" component={"./MenuCaptable"} mf="capTable" params={{ go, groupCompanyId, ...props }} />
  );
};

function areEqual(prevProps, nextProps) {
  // Compara si groupCompanyId era null o undefined en ambas props
  const prevIsNullUndefined = prevProps.groupCompanyId === null || prevProps.groupCompanyId === undefined;
  const nextIsNullUndefined = nextProps.groupCompanyId === null || nextProps.groupCompanyId === undefined;
  // Devuelve true si groupCompanyId era null o undefined en ambas props, false en caso contrario
  return prevIsNullUndefined === nextIsNullUndefined;
}

export default React.memo(MenuCaptable, areEqual);
// export default MenuCaptable;

import { ReviewDataProtectionContact, SelectNewDataProtectionContact } from "../steps"
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';

const useSteps = () => {
  return {
    InitialData: {
      render: TemplateWizardSkeletonMachine
    },
    pincasData: {
      render: TemplateWizardSkeletonMachine
    },
    SaveNewDataProtectionContact: {
      render: TemplateWizardSkeletonMachine
    },
    SaveNoNewDataProtectionContact: {
      render: TemplateWizardSkeletonMachine
    },
    ReviewDataProtectionContact: {
      render: ReviewDataProtectionContact
    },
    SelectDataProtectionContact: {
      render: SelectNewDataProtectionContact
    }
  }
}
export default useSteps;
import useSession from "src/modules/session/hooks/useSession";
import useGetGroupCompanyEconomicSubstance from "./graphql/useGetGroupCompany";
import useGetRelevantActivities from "./graphql/useGetRelevantActivities";
import useUpdateEconomicSubstanceInformation from "./graphql/useUpdateEconomicSubstanceInformation";
import useGetMapEconomicSubstance from './useGetMapEconomicSubstance';
import useGetPINCAPrices from 'src/views/modules/startups/renewal/pincasPrice/queries/useGetPINCAPrices';
import { JURISDICTION_TYPE } from "src/modules/generic/types";

const useServices = ({ initialValues }) => {
  const { groupCompanyId } = initialValues;
  const { startupId } = useSession();
  const { manualQuery: getGroupCompany } = useGetGroupCompanyEconomicSubstance({ groupCompanyId, startupId });
  const { manualQuery: getRelevantActivitiesQuery } = useGetRelevantActivities({},{});
  const mutation = useUpdateEconomicSubstanceInformation();
  const economicSubstanceMapper = useGetMapEconomicSubstance();

  const { manualQuery: getPINCAPrices } = useGetPINCAPrices(
    {
      variables: {
        filters: {
          jurisdictionTypes: [JURISDICTION_TYPE.CAYMAN]
        }
      }
    }
  );

  const initialData = async () => {
    const { data: groupCompanyData } = await getGroupCompany();
    return { initialValues, groupCompany: groupCompanyData?.getGroupCompany };
  }

  const pincasData = async () => {
    const pincasData = await getPINCAPrices();
    return { pincasData };
  }  

  const getRelevantActivities = async () => {
    const { data: relevantActivitiesData } = await getRelevantActivitiesQuery();
    return { relevantActivitiesData };
  }

  const SaveStep = async (context) => {    
    const data = economicSubstanceMapper(context);
    
    const variables ={
      startupId,
      upsertStartupId:startupId,
      groupCompanyId,
      groupCompany: data?.groupCompany,
      groupCompanyUpsert: groupCompanyId,
      renewalData: {
        taxInformation: data?.taxInformation,
        companyIncome: data?.companyIncome,
        isDeclarationConfirmed: context?.DeclarationsStep?.declarations,
        businessActivities: data?.businessActivities,
        companyDetails: {
          ...data?.companyDetails,
        }
      },
    };
    
    return await mutation({variables});
  }

  return { initialData, SaveStep, getRelevantActivities, pincasData };
}

export default useServices;

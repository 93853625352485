import React from 'react'
import _ from 'lodash';
import Lottie from 'react-lottie';
import './RightContentTour.scss';
const RightContentTour = ({ pages, actualStep }) => {

    const page = React.useMemo(() => {
        return _.get(pages, `[${actualStep}]`);
    }, [JSON.stringify(actualStep), JSON.stringify(pages)]);
    return (
        <div className={'container-RightContentTour'}>
            {_.get(page, `topMedia`,null) && <img className={'image_tour'} src={_.get(pages, `[${actualStep}].topMedia`)} />}
            {_.get(page, `lottieAnimation`,null) && <Lottie
                height={"80%"}
                width={"80%"}
                options={{
                    loop: true,
                    autoplay: true,
                    animationData: _.get(page, 'lottieAnimation', {}),


                }} />}

        </div>
    )
}

export default RightContentTour

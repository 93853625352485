import {Constants} from './constants';

const getGTMtag = () => {
    switch(window.location.hostname){
      case Constants.CONFIG.DEVELOPMENT_HOSTNAME:
        return null
      case Constants.CONFIG.TEST_HOSTNAME:
        return 'GTM-KBK6MSG'
      case Constants.CONFIG.DEMO_HOSTNAME:
        return 'GTM-TBV2VXM'
      case Constants.CONFIG.STAGING_HOSTNAME:
        return 'GTM-WGCWP7X'
      case Constants.CONFIG.PROD_HOSTNAME:
        return 'GTM-KWPRPDK'
      case Constants.CONFIG.PRE_HOSTNAME:
        return 'GTM-MJMK6SQ'
    }
  }
 
  export default getGTMtag
export const BOARD_RESOLUTION_CONVERTIBLE = [
    "BOARD_RESOLUTION_CONVERTIBLE_LOAN_NOTE",
    "BOARD_RESOLUTION_CONVERTIBLE_SAFE",
    "BOARD_RESOLUTION_CONVERTIBLE_KISS",
    "BOARD_RESOLUTION_CONVERTIBLE_FIXED_EQUITY",
    "BOARD_RESOLUTION_CONVERTIBLE_OTHER",
    "BOARD_RESOLUTION_CONVERTIBLE"
  ];
  
export  const BOARD_RESOLUTION_CONVERTIBLE_DOCUMENT_TYPES_LIST = [
    "BOARD_RESOLUTION_CONVERTIBLE_LOAN_NOTE",
    "BOARD_RESOLUTION_CONVERTIBLE_SAFE",
    "BOARD_RESOLUTION_CONVERTIBLE_KISS",
    "BOARD_RESOLUTION_CONVERTIBLE_FIXED_EQUITY",
    "BOARD_RESOLUTION_CONVERTIBLE_OTHER",
   ]
  
export  const BOARD_RESOLUTION_CONVERTIBLE_LABEL = {
    "BOARD_RESOLUTION_CONVERTIBLE_LOAN_NOTE":"Convertible Loan Note",
    "BOARD_RESOLUTION_CONVERTIBLE_SAFE":"SAFE",
    "BOARD_RESOLUTION_CONVERTIBLE_KISS":"KISS",
    "BOARD_RESOLUTION_CONVERTIBLE_FIXED_EQUITY":"Fixed Equity",
    "BOARD_RESOLUTION_CONVERTIBLE_OTHER":"Other",
  }
  export const USA_BOARD_CONSENT_SAFE = [
    "USA_BOARD_CONSENT_SAFE_CONVERTIBLE_LOAN_NOTE",
    "USA_BOARD_CONSENT_SAFE_SAFE",
    "USA_BOARD_CONSENT_SAFE_KISS",
    "USA_BOARD_CONSENT_SAFE_FIXED_EQUITY",
    "USA_BOARD_CONSENT_SAFE_OTHER",
    "USA_BOARD_CONSENT_SAFE"
  ];
  
export  const USA_BOARD_CONSENT_SAFE_DOCUMENT_TYPES_LIST = [
    "USA_BOARD_CONSENT_SAFE_CONVERTIBLE_LOAN_NOTE",
    "USA_BOARD_CONSENT_SAFE_SAFE",
    "USA_BOARD_CONSENT_SAFE_KISS",
    "USA_BOARD_CONSENT_SAFE_FIXED_EQUITY",
    "USA_BOARD_CONSENT_SAFE_OTHER",
   ]
  
export  const USA_BOARD_CONSENT_SAFE_LABEL = {
    "USA_BOARD_CONSENT_SAFE_CONVERTIBLE_LOAN_NOTE":"Convertible Loan Note",
    "USA_BOARD_CONSENT_SAFE_SAFE":"SAFE",
    "USA_BOARD_CONSENT_SAFE_KISS":"KISS",
    "USA_BOARD_CONSENT_SAFE_FIXED_EQUITY":"Fixed Equity",
    "USA_BOARD_CONSENT_SAFE_OTHER":"Other",
  }
export const ADD_NEW_BANK_ACCOUNT = ` mutation addNewBankAccount($startupId: ID!,$groupCompanyId:ID!, $bankAccount: BankAccountInput) {
    addNewBankAccount(startupId: $startupId,groupCompanyId:$groupCompanyId, bankAccount: $bankAccount) {
            id
            code
            number
            additionalInfo
            ownerFullName 
            bankName
            country{
                code
                name
            }
    }
}`
export const ADD_NEW_VESTING_TERMS = `
mutation createVestingTerm ($startupId: ID!, $groupCompanyId: ID!, $vestingTermData: VestingTermInput!) {
    createVestingTerm (startupId: $startupId, groupCompanyId: $groupCompanyId,  vestingTermData: $vestingTermData) {
        id
        name
        description
        vestingType
        allocationType
        standardTerm{
          vestingPeriod
          vestingFrequency
          isThereCliff
          cliffPeriod
        }
    }
}`;
export const ADD_NEW_SHARE_CLASS = `
mutation createShareClass ($startupId: ID!, $groupCompanyId: ID!, $shareClassData: ShareClassInput!) {
    createShareClass (startupId: $startupId, groupCompanyId: $groupCompanyId,  shareClassData: $shareClassData) {
        id
        name
    }
}`;
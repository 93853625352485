import React, {useEffect} from 'react';
import useMutation from "../../../custom/useMutation"

const mutation = `mutation upsertMe($userData:UserProfileInput) {
  upsertMe(userData: $userData) {
    id
    {{#if userData.avatar}}avatar {{/if}}
    allowedActions
    pendingActions
    hasMatters
    hasStartup
    hasEquities
    fullName
    firstName
    register
    userRole
  }
}`;
const useUpsertMe = (options) =>{
    
    const [send,data] = useMutation(mutation,options);
    const sendForm= async (values)=>{
      return send({userData:values});
    }
    useEffect(() => {
        
    }, []);

    return  [sendForm,data] ;
}
export default useUpsertMe;
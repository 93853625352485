import useMutation from '../../../../../hooks/custom/useMutation'

export const END_ACCELERATOR_PROGRAM = `mutation endProgram(
  $id: ID!
  $startupData: StartupInput!        
) {
  endProgram(
    id: $id
    startupData: $startupData
  ) {
    accelerator
    acceleratorData {
      acceleratorCode
      isSubmitted
    }
    acceleratorDef {
      code
      name
      questionnaire
      startDate
      endDate
    }
  }
}`;

const useEndProgram = ({ ...props }) => {
  return useMutation(END_ACCELERATOR_PROGRAM, {...props});
}
export default useEndProgram;
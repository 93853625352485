import { useMemo } from "react";
import AcceleratorStep from "../Steps/AcceleratorStep";
import AddFounderStep from "../Steps/AddFounderStep";
import GoalStep from "../Steps/GoalStep";
import StartupNameStep from "../Steps/StartupNameStep";
import TellUsAboutStep from "../Steps/TellUsAboutStep";
import WhatHappensNextAcceleratorStep from "../Steps/WhatHappensNextAcceleratorStep";
import WhatHappensNextFormationStep from "../Steps/WhatHappensNextFormationStep";
import WhatHappensNextGenerateDocumentStep from '../Steps/WhatHappensNextGenerateDocumentStep';
import WhatHappensNextStepCapTable from "../Steps/WhatHappensNextStepCapTable";
import useTranslate from "src/modules/generic/hooks/useTranslate";

const useSteps = () => {
  const { translate } = useTranslate();
  return useMemo(() => ({
    StartupNameStep: {
      render: StartupNameStep,
      renderProps: {
        actualStep: 1,
        totalSteps: 5,
        submitButonProps: {
          children: translate("MODULES.ONBOARDINGCOMPANY.ONBOARDING_STARTUP_BUTTON_NEXT")
        }
      }
    },
    TellUsAboutStep: {
      render: TellUsAboutStep,
      renderProps: {
        actualStep: 2,
        totalSteps: 5,
        submitButonProps: {
          children: translate("MODULES.ONBOARDINGCOMPANY.ONBOARDING_STARTUP_BUTTON_NEXT")
        }
      }
    },
    AddFounderStep: {
      render: AddFounderStep,
      renderProps: {
        actualStep: 3,
        totalSteps: 5,
        submitButonProps: {
          children: translate("MODULES.ONBOARDINGCOMPANY.ONBOARDING_STARTUP_BUTTON_NEXT")
        }
      }
    },
    GoalStep: {
      render: GoalStep,
      renderProps: {
        actualStep: 4,
        totalSteps: 5,
        submitButonProps: {
          children: translate("MODULES.ONBOARDINGCOMPANY.ONBOARDING_STARTUP_BUTTON_NEXT")
        }
      }
    },
    WhatHappensNextFormationStep: {
      render: WhatHappensNextFormationStep,
      renderProps: {
        actualStep: 5,
        totalSteps: 5,
        submitButonProps: {
          children: translate("MODULES.ONBOARDINGCOMPANY.ONBOARDING_STARTUP_BUTTON_NEXT"),
        }
      }
    },
    WhatHappensNextGenerateDocumentStep: {
      render: WhatHappensNextGenerateDocumentStep,
      renderProps: {
        actualStep: 5,
        totalSteps: 5,
        submitButonProps: {
          children: translate("MODULES.ONBOARDINGCOMPANY.ONBOARDING_STARTUP_BUTTON_NEXT"),
        }
      }
    },
    WhatHappensNextStepCapTable: {
      render: WhatHappensNextStepCapTable,
      renderProps: {
        actualStep: 5,
        totalSteps: 5,
        submitButonProps: {
          children: translate("MODULES.ONBOARDINGCOMPANY.ONBOARDING_STARTUP_BUTTON_NEXT")
        }
      }
    },
    AcceleratorStep: {
      render: AcceleratorStep,
      renderProps: {
        actualStep: 5,
        totalSteps: 6,
        submitButonProps: {
          children: translate("MODULES.ONBOARDINGCOMPANY.ONBOARDING_STARTUP_BUTTON_NEXT"),
        },
      }
    },
    verifyCode: {
      render: AcceleratorStep,
      renderProps: {
        isLoading: true,
        actualStep: 5,
        totalSteps: 6,
      }
    },
    WhatHappensNextAcceleratorStep: {
      render: WhatHappensNextAcceleratorStep,
      renderProps: {
        actualStep: 6,
        totalSteps: 6,
        submitButonProps: {
          children: translate("MODULES.ONBOARDINGCOMPANY.ONBOARDING_STARTUP_BUTTON_NEXT"),
        },
        showPrev: true
      }
    },
    createStartupFormation: {
      render: WhatHappensNextFormationStep,
      renderProps: {
        isLoading: true,
        actualStep: 5,
        totalSteps: 5,
      }
    },
    createStartupDocuments: {
      render: WhatHappensNextGenerateDocumentStep,
      renderProps: {
        isLoading: true,
        actualStep: 5,
        totalSteps: 5,
      }
    },
    createStartupCapTable: {
      render: WhatHappensNextStepCapTable,
      renderProps: {
        isLoading: true,
        actualStep: 5,
        totalSteps: 5,
      }
    },
    createStartupAccelerator: {
      render: WhatHappensNextAcceleratorStep,
      renderProps: {
        isLoading: true,
        actualStep: 5,
        totalSteps: 5,
      }
    },
  }), [translate]);
}
export default useSteps;
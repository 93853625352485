import React from 'react';
import BlueScreenLayout from "../layouts/BlueScreen/BlueScreenLayout"

export default {

    VIEW_FORM: "view_form",
    EDIT_FORM: "edit_form",
    ADD_FORM: "add_form",
    SELECT_FORM: "select_form",
    DASHBOARD: "dashboard",
    LOGIN: "login",
    GRAPH_VIEW: "graphView",
    SWITCH_STARTUP: "switch_startup",
    INVITE: "invite",
    BLUE_SCREEN: BlueScreenLayout
}


import React from "react";
import classesModal from "src/modules/checkout/components/PaymentTotal/PaymentTotal.module.scss";
import PositivMoney from "src/components/text/Text/PositivMoney";
import Stack from '@mui/material/Stack';
import Text from "src/components/text/Text/Text";
import _ from "lodash";

export interface ResumePaymentProps {
  haveDiscount: boolean;
  discountValue: number;
  haveVAT: boolean;
  vatValue: number;
  totalValue: number;
  totalCurrency: string;
  vatCurrency: string;
  freeVat?: boolean;
  vat?: any;
  vatPercent?: any;
}

const ResumePayment: React.FC<ResumePaymentProps> = ({ 
  haveDiscount, 
  discountValue, 
  haveVAT, 
  vatValue, 
  totalValue, 
  totalCurrency, 
  vatCurrency, 
  freeVat, 
  vat,
  vatPercent,
}) => {
  
  return (
    <>
      {haveDiscount && (
        <div className={`${classesModal.item} ${classesModal.bundleDiscount}`}>
          <div className={classesModal.product}>Bundle discount</div>
          <div className={classesModal.amount}> - <span>{vatCurrency}</span>
            <b>
              <PositivMoney value={discountValue} params={{ prefix: " " }} />
            </b>
          </div>
        </div>
      )}
      {haveVAT ? (
        <div className={`${classesModal.item} ${classesModal.itemVat} ${classesModal.borderBottom}`}>
          <>
            <div className={classesModal.product}>
              {!vatPercent && (
                <Text 
                  uuid={"MODULES.CHECKOUT.PAYMENT_VAT"} 
                  params={{ percent: freeVat ? _.get(vat, "valueWithDiscount", 0) : _.get(vat, "value", 0) }} 
                />
              )}
              {vatPercent && (
                <Text 
                  uuid={"MODULES.CHECKOUT.PAYMENT_VAT"} 
                  params={{ percent: vatPercent }} 
                />
              )}
            </div>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              spacing={0.5}
            >
              <span style={{ fontSize: "10px", marginBottom: "1px"}}>{vatCurrency}</span>
              <b><PositivMoney value={vatValue} params={{ prefix: " " }} showDecimals={true} /> </b>
            </Stack>
          </>
        </div>
      ) : (
        <div className={`${classesModal.borderBottom}`}></div>
      )}
      <div className={`${classesModal.item} ${classesModal.itemTotal} `}>
        <div className={classesModal.product}>
          <Text uuid={"MODULES.CHECKOUT.PAYMENT_TOTAL"} />
        </div>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          spacing={0.5}
        >
          <span style={{ fontSize: "12px", marginBottom: "1px"}}>{totalCurrency}</span>
          <b style={{ fontSize: "16px"}}>
            <PositivMoney value={totalValue} params={{ prefix: " " }} />
          </b>
        </Stack>
      </div>
    </>
  );
};
export default ResumePayment;

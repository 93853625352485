import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { assign } from 'xstate';
import MachineProvider from '../../../context/MachineContext/MachineProvider';
import Step from './components/Step';


/**
 * machine: state machine
 * steps: map whith the steps of the state machine
 */
function Wizard({
  machine,
  steps,
  stepper,
  actions: _actions = {},
  services: _services = {},
  guards: _guards = {},
  onStopMachine,
  onCancelMachine
}) {
 

  const actions = useMemo(
    () => ({
      setContextAfterStep: assign((context, event) => {
        const idState = _.get(event, 'payload.idState');
        const values = _.get(event, 'payload.values');
        let newValueState = {};
        if (values && idState) newValueState = { [idState]: values };
        return { ...context, ...newValueState };
      }),
      setContextAfterInvoke: assign((context, event) => {
        const type = _.get(event, 'type', '');
        const values = _.get(event, 'data');
        const typeSplit = type.split('.');
        const indexType = typeSplit[typeSplit.length - 1];

        let newValueState = {};
        if (values && indexType) newValueState = { [indexType]: values };
        return { ...context, ...newValueState };
      }),
      ..._actions,
      setErrorsAfterInvoke: assign((context, event) => {
        console.log('setErrorsAfterInvoke', event,context);
        return { ...context };
      }),
      stopMachine: async (context, event) => {
        if (onStopMachine) {
          onStopMachine(context, event);
        }
      },
      cancelMachine: async (context, event) => {

        if (onCancelMachine) {
          onCancelMachine(context, event);
        }
      }
      
    }),
    [_actions, onStopMachine,onCancelMachine]
  );

  return (
    <MachineProvider
      guards={_guards}
      machine={machine}
      steps={steps}
      stepper={stepper}
      actions={actions}
      services={_services}
    >
      <Step />
    </MachineProvider>
  );
}

Wizard.propTypes = {
  actions: PropTypes.object,
  guards: PropTypes.any,
  machine: PropTypes.any,
  onStopMachine: PropTypes.func,
  onCancelMachine: PropTypes.func,
  services: PropTypes.object,
  stepper: PropTypes.array,
  steps: PropTypes.any.isRequired
};

export default Wizard;

import useCustomMachine from "../../../../generic/hooks/useCustomMachine";
import React, { FC, useEffect } from "react";
import Button from "../../../../generic/components/Buttons/Button";
import { StepProps } from "../../../../generic/components/Wizard/Step";
import TemplateWizard from "../../../../generic/templates/Modal/TemplateWizard";
import useTranslate from "../../../../generic/hooks/useTranslate";
import FormPEP from "../../Forms/FormPEP";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import useSession from "../../../../session/hooks/useSession";
import { addAssistantText, clearAssistatText } from "../../../../generic/store/action";
import useUpdateStakeholder from "src/modules/startup/onBoarding/core/graphql/mutations/useUpdateStakeholder";

const Step1: FC<StepProps> = () => {

  const { translate } = useTranslate();
  const { next, send, stepData, state } = useCustomMachine();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { startupId } = useSession();
  const { updateStakeholder } = useUpdateStakeholder({}, {});

  useEffect(() => {
    dispatch(clearAssistatText());
    dispatch(
      addAssistantText(translate("MODULES.STAKEHOLDER.PEP.FIRST_STEP.TOOLTIP"))
    );
  }, []);

  const handleCompleteSubmit = async (values) => {
    if (values.isPEP) {
      const variables = {
        startupId,
        stakeholderId: state?.context?.data?.stakeholderId ?? id,
        stakeholderData: {
          stakeholder: {
            pep: {
              ...values,
            }
          },
        }
      };
      await updateStakeholder(variables);
    }
    next(values);
  };

  const handleCancel = async (values) => {
    send("CANCEL");
  };

  return (
    <React.Fragment>
      <FormPEP
        initialValues={stepData}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: translate("MODULES.STAKEHOLDER.PEP.FIRST_STEP.TITLE"),
          subTitle: translate("MODULES.STAKEHOLDER.PEP.FIRST_STEP.SUBTITLE"),
        }}
        buttons={{
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              Cancel
            </Button>
          ),
        }}
      ></FormPEP>
    </React.Fragment>
  );
};

export default Step1;

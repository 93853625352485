import React, { FC, useState } from 'react';
import ModalAssistant from 'src/modules/generic/components/Modal/ModalAssistant';
import useModal from 'src/modules/generic/hooks/useModal';
import { clearAssistatText as clearAssistantText } from 'src/modules/generic/store/action';
import AddFormationCompanyMachineWizard from './wizard';
import {Constants} from "src/v1/utils";
import event from "src/v1/utils/event";

interface Props {
  open: boolean
  initialValues: any
}

/* To Call this modal execute: openModal( AddFormationCompanyModal, {},'MODAL_ADD_FORMATION_COMPANY' ); */
const AddFormationCompanyModal: FC<Props> = ({
  open: openParam = false,
  initialValues = {}
}) => {

  const [open, setOpen] = useState(openParam);
  const { closeModal: onClose } = useModal();

  const handleStopMachine = () => {
    event.emmit(Constants.EVENTS.REFRESH_DASHBOARD, {});
    onClose();
    setOpen(false);
    clearAssistantText();
  }

  const handleCancelMachine = () => {
    onClose();
    setOpen(false);
    clearAssistantText();
  }

  return (
    <ModalAssistant open={open} variant='assistance'>
      <AddFormationCompanyMachineWizard initialValues={initialValues} onFinish={handleStopMachine} onCancel={handleCancelMachine} />
    </ModalAssistant>
  );
};

export default AddFormationCompanyModal;

export const HOST_NAME_DEPLOY = ['dev.clara.co', 'staging.clara.co', 'pre.clara.co', 'test.clara.co', 'demo.clara.co', 'app.clara.co', 'corporate.clara.co']

export const HOST_NAME_PRODUCTION = ['pre.clara.co', 'demo.clara.co', 'app.clara.co', 'corporate.clara.co']

export const URL_API = {
    'dev.clara.co': 'https://api-dev.clara.co',
    'staging.clara.co': 'https://api-staging.clara.co',
    'pre.clara.co': 'https://api-pre.clara.co',
    'test.clara.co': 'https://api-test.clara.co',
    'demo.clara.co': 'https://api-demo.clara.co',
    'corporate.clara.co': 'https://api-corporate.clara.co',
    'app.clara.co': 'https://api.clara.co'
};

export const URL_API_SOCKET = {
    'dev.clara.co': 'wss://api-dev.clara.co',
    'staging.clara.co': 'wss://api-staging.clara.co',
    'pre.clara.co': 'wss://api-pre.clara.co',
    'test.clara.co': 'wss://api-test.clara.co',
    'demo.clara.co': 'wss://api-demo.clara.co',
    'corporate.clara.co' : 'wss://api-corporate.clara.co',
    'app.clara.co': 'wss://api.clara.co'    
}

export const CDNHots = "https://images.clara.co/data";
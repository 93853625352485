import {Reference, TypePolicy} from "@apollo/client";
// TODO: ADD TYPE TRANSFORMER IN GROUPCOMPANY
const GroupCompany:TypePolicy={
    fields: {
        documents(existingEquity: Reference[], {canRead,cache}) {
            return existingEquity
                ? existingEquity.filter(canRead)
                : [];
        },
        equity(existingEquity: Reference[], {canRead,cache,readField}) {
            return existingEquity
                ? existingEquity.filter(canRead)
                : [];
        },
        tasks:  {
            merge(existing = [], incoming: any[]) {
              return [...existing, ...incoming];
            },
        },
    }
}
const FormationData: TypePolicy = {
    keyFields:['formationCompany']
}

const IssuedEquity:TypePolicy={
    keyFields:['equity']
}
const IssuedWarrant:TypePolicy={
    keyFields:['warrant']
}
const IssuedConvertibles:TypePolicy={
    keyFields:['convertible']
}
const Position:TypePolicy= {
        keyFields: ["code"],
        fields: {
            responsibilities: {
                merge: false
            }
        }
    }
const Responsibility:TypePolicy={
    keyFields:['code','text'],
}
export {
    IssuedEquity,
    IssuedConvertibles,
    IssuedWarrant,
    Position,
    GroupCompany,
    FormationData,
    Responsibility
}
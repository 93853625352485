import React, { FC, useState, useEffect, useRef } from "react";
import { Text } from "../../../../../v1/components";
import classesModules from "./classes.module.scss";
import MuiPhoneNumber from 'mui-phone-input-ssr';
import FormikHelper from "../../../../../v1/utils/formikHelper"
import _, { values } from "lodash";

export interface PhoneInputFormikProps {
  defaultCountry?: string;
  disableDropdown?: boolean;
  autoFormat?: boolean;
  disableCountryCode?: boolean;
  enableLongNumbers?: boolean;
  countryCodeEditable?: boolean;
  value?: string;
  field?:any;
  form?:any;
  /** Onchange method */
  onChange: (Array) => void;
  /** onFocus method */
  onFocus?: (any) => void;
  /** onBlur method */
  onBlur?: (any) => void;
}

/**
- Template to show Phone input
this component should be removed after forms with formik are changed
**/

const PhoneInputFormik: FC<PhoneInputFormikProps> = ({
  field,
  form,
  defaultCountry,
  disableDropdown=true,
  autoFormat=true,
  disableCountryCode=false,
  enableLongNumbers=true,
  countryCodeEditable=true,
  onChange,
  onFocus,
  onBlur,
  value,
  ...props
}: PhoneInputFormikProps) => {

  const [countryCode, setCountryCode] = useState(false)
  const [defaultCountryValue,setVefaultCountryValue] = useState(defaultCountry)
  const [hasFocus, setHasFocus] = useState(false)

  const inputRef = useRef();
  
  /**
   * 
   *  It is used this way because otherwise the component leaves the "+" character in front of the text,
   *  That's why the code of the countrycode set is changed to edit the number and be able to clear the field. 
   *  must add : useCallback
   * 
   */
  const handleChange = (value) => {
    
    if(value.toString().length > 1){
      setCountryCode(false)
    } else if ( value.toString().length == 1){
      setCountryCode(true)
    } else if (value.toString().length < 1){
      setCountryCode(false)
    }
    
    form.setFieldValue(field.name,value)
    form.validateField(field.name)
    return value;
  }

  const hasError = () => {
    return FormikHelper.returnErrorsForValidation(field.name, form.errors);
  }

  const getError = () => {
      return FormikHelper.returnErrorsForValidation(field.name, form.errors);
  }

  const handleOnFocus = () => {
    setHasFocus(true)
  }

  const handleOnBlur = () => {
    setHasFocus(false)
  }

  useEffect(()=>{
    setCountryCode(false)
    setVefaultCountryValue(defaultCountry)
  },[defaultCountry])

  const className = _.get(props,"className")
  const classNameInput = _.get(props,"classNameInput")

  const classInputContainer = _.get(props, "className.root") ? _.get(props, "className.root") : `${classesModules.InputContainer} ${className}`;
  const classPhoneInputFormik = _.get(props, "className.input") ? _.get(props, "className.input") : classesModules.TextInput;
  return (
    < div className={classInputContainer} >
      <div className={`${classPhoneInputFormik } ${_.get(classNameInput, 'root', null)} `}>
        <label>
            <Text uuid={_.get(props,'label') || ""}/>
            {' '}
            {_.get(props,'required') ? <span style={{ color: 'red' }}>*</span> : null}
        </label>
        <div className={`${classesModules.contentOnlyInput} ${_.get(props, 'classNameInput.input', null)} ${disableDropdown ? classesModules.containerDropdownOff : classesModules.containerDropdownOn} ${hasFocus?classesModules.hasFocus : classesModules.notHasFocus}`}>
          <MuiPhoneNumber 
            className={hasError() ? classesModules.Error : classesModules.NoError}
            defaultCountry={defaultCountryValue}
            onChange={handleChange}  
            disableDropdown={disableDropdown}
            disableAreaCodes={true}
            autoFormat={autoFormat}
            disableCountryCode={countryCode}
            enableLongNumbers={enableLongNumbers}
            countryCodeEditable={countryCodeEditable}
            onFocus={onFocus}
            value={value}
            inputRef={inputRef}
            variant={'outlined'}
          />
          
          {hasError() ? (
              <div className={classesModules.MessageError}>
                  {getError()}
              </div>
          ) : null}
        </div>
      </div>
    </div>

  );
};

export default PhoneInputFormik;

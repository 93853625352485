import _ from "lodash";
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ChangePasswordModal from "../../../../components/modules/clara/user/modal/ChangePasswordModal/ChangePasswordModal";
import DeauthoriseDeviceModal from '../../../../components/modules/clara/user/modal/DeauthoriseDeviceModal/DeauthoriseDeviceModal';
import DeleteProfileModal from "../../../../components/modules/clara/user/modal/DeleteProfileModal/DeleteProfileModal";
import EditProfileModal from "../../../../components/modules/clara/user/modal/EditProfileModal/EditProfileModal";
import RenameDeviceModal from '../../../../components/modules/clara/user/modal/RenameDeviceModal/RenameDeviceModal';
import Details from '../../../../components/templates/Details/Details';
import useGetMe from "../../../../hooks/services/modules/user/useGetMe";
import useGetProfileAccess from "../../../../hooks/services/modules/user/useGetProfileAccess";
import Button from "../../../../modules/generic/components/Buttons/Button";
import useModal from "../../../../modules/generic/hooks/useModal";
import useTranslate from "../../../../modules/generic/hooks/useTranslate";
import useSession from "../../../../modules/session/hooks/useSession";
import Text from "./../../../../components/text/Text/Text";
import useTextLang from "./../../../../hooks/custom/useTextLang";
import useUpsertMe from "./../../../../hooks/services/modules/user/useUpsertMe";
import { updateParamsBreadCrumbs } from './../../../../store/actions/page';
import { AuthorisedDevicesTable, ProfileAccessTable } from './components';
import DetailsTab from "./components/DetailsTab";
import { ServerConnect } from "src/v1/utils";

const UserView = (props) => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const titleTranslate = useTextLang("MY_ACCOUNT_VIEW_LABEL_PROFILE", null);
  const { user: userSession, updateUser: updateUserServer } = useSession();
  const { data: userData, loadingUser, errorUser, refetch } = useGetMe();
  const { data: userStartups, loadingStartup, errorStartup } = useGetProfileAccess();
  const [user, setUser] = useState(null)
  const [startups, setStartups] = useState([]);
  const [devices, setDevices] = useState([]);
  const [avatar, setAvatar] = useState();

  useEffect(() => {
    refetch();
    updateParamsBreadCrumbs(dispatch, { fullName: _.get(userSession, "fullName") });
  }, [JSON.stringify(userSession)])

  const [upsertMe, loadingMe] = useUpsertMe({ successMessage: "MY_ACCOUNTNOTIFICATION_SUCCESS_UPDATE_IMAGE" });

  const handleChangeAvatar = async (value) => {
    const response = await ServerConnect.requestApi(
      `/avatars/users/${userData?._id}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          avatarData: value
        })
      },
      null,
      { showNotification: false }
    )
    if (response) {
      const newAvatar = response?.url ?? value;
      await setAvatar(newAvatar);
      updateUserServer({ avatar: newAvatar })
      await upsertMe({ avatar: newAvatar })
    }
  }

  const { openModal, closeModal } = useModal();

  const handlCloseModal = useCallback(() => {
    refetch();
    closeModal();
  }, [refetch, closeModal]);

  const handleDeauthorise = (data) => {
    openModal(DeauthoriseDeviceModal, { initialValues: data, handleCloseModal: handlCloseModal })
  };

  const handleRenameDevice = (data) => {
    openModal(RenameDeviceModal, { initialValues: data, handleCloseModal: handlCloseModal })
  }

  const tabs = [
    {
      show: true,
      disabled: false,
      component: <label><Text uuid={"MY_ACCOUNT_VIEW_LABEL_DETAILS"} /></label>,
      tabName: translate("MY_ACCOUNT_VIEW_LABEL_DETAILS"),
      key: "details"
    },
    // {
    //     show: true,
    //     disabled: false,
    //     component: <label><Text uuid={"MY_ACCOUNT_VIEW_LABEL_PROFILE"} /></label>,
    //     tabName: translate("MY_ACCOUNT_VIEW_LABEL_PROFILE")
    // },
    {
      show: true,
      disabled: false,
      component: <label><Text uuid={"MY_ACCOUNT_VIEW_LABEL_DEVICES"} /></label>,
      tabName: translate("MY_ACCOUNT_VIEW_LABEL_DEVICES")
    }
  ];

  const getTabs = () => {
    let filteredTabs = [];
    _.forEach(tabs, tab => {
      if (tab.show) {
        filteredTabs.push(tab)
      }
    })
    return filteredTabs

  }
  let contents = [
    { show: true, component: <DetailsTab data={user} /> },
    // { show: true, component: <ProfileAccessTable data={startups} /> },
    { show: true, component: <AuthorisedDevicesTable data={devices} handleDeauthorise={handleDeauthorise} handleRenameDevice={handleRenameDevice} /> },
  ];

  const getTabContents = () => {
    let filteredContents = [];
    _.forEach(contents, content => {
      if (content.show) {
        filteredContents.push(content.component)
      }
    })
    return filteredContents
  }

  useEffect(() => {
    setUser(_.get(userData, "getMe"));
    setDevices(_.get(userData, "getMe.devices", []))
  }, [userData]);

  useEffect(() => {
    setStartups(_.get(userStartups, "getProfileAccess", []));
  }, [userStartups]);

  return (
    <React.Fragment>
      <Details
        lastUpdated={_.get(user, "updatedAt")}
        title={_.get(user, "fullName")}
        avatarName={_.get(user, "fullName")}
        avatarImg={_.get(user, "avatar")}
        onChangeAvatar={handleChangeAvatar}
        buttons={[
          <Button variant="card" onClick={() => {
            openModal(ChangePasswordModal, { handleCloseModal: closeModal });
          }}>
            {translate("MY_ACCOUNT_VIEW_BUTTON_UPDATE_PASSWORD")}
          </Button>
          ,
          <Button variant="cardWarning" onClick={() => {
            openModal(DeleteProfileModal, { handleCloseModal: closeModal });
          }}>
            {translate("MY_ACCOUNT_VIEW_BUTTON_DELETE")}
          </Button>
          ,
        ]}
        buttonsTabs={{
          details: [
            <Button
              variant='card'
              children={translate(
                'MY_ACCOUNT_VIEW_TAB_BUTTON_DETAILS_EDIT'
              )}
              onClick={() => {
                openModal(EditProfileModal, { handleCloseModal: closeModal })
              }}
            ></Button>
          ]
        }}
        tabContents={getTabContents()}
        tabs={getTabs()}
      ></Details>
    </React.Fragment >
  )
}

export default UserView;

import React, { FC } from 'react'
import { Fade } from '@material-ui/core';
import _ from 'lodash';
//-------------------Review SkeletonRepeat and change Loading Skeleton -----------------

interface LoadingSkeletonProps {
    cant: number;
    skeletor: any;
    loading?: boolean;
    id?: string
}



const LoadingSkeleton: FC<LoadingSkeletonProps> = ({ cant = 1, skeletor, loading, children , id=''}) => {
    const getSkeletor = () => {
        return _.map(new Array(cant), (a, key) => <div key={`${key} ${id}`}>{skeletor}</div>)
    }

    return (
        <React.Fragment>
            {!loading ?
                <>
                    {children}
                </> :

                <div>
                    {getSkeletor()}
                </div>
            }

        </React.Fragment >
    )
};

//---------------------------------------------------------------------
export default LoadingSkeleton

import forEach from 'lodash/forEach';
import PropTypes from 'prop-types';
import {useCallback, useMemo} from 'react';
import useLazyQuery from 'src/hooks/custom/useLazyQuery';

const PINCAS_KEYS = {
	CHANGE_EQUITY_CREATE_SHARE_CLASS: 'CHANGE_EQUITY_CREATE_SHARE_CLASS',

	CHANGE_EQUITY_CREATE_SHARE_ISSUANCE_ADD_SHAREHOLDER: 'CHANGE_EQUITY_CREATE_SHARE_ISSUANCE_ADD_SHAREHOLDER',
	CHANGE_EQUITY_CREATE_SHARE_ISSUANCE_UPDATE_SHAREHOLDER: 'CHANGE_EQUITY_CREATE_SHARE_ISSUANCE_UPDATE_SHAREHOLDER',
	CHANGE_EQUITY_FULL_TRANSFER_SHARE_ISSUANCE_ADD_SHAREHOLDER: 'CHANGE_EQUITY_FULL_TRANSFER_SHARE_ISSUANCE_ADD_SHAREHOLDER',
	CHANGE_EQUITY_FULL_TRANSFER_SHARE_ISSUANCE_UPDATE_SHAREHOLDER: 'CHANGE_EQUITY_FULL_TRANSFER_SHARE_ISSUANCE_UPDATE_SHAREHOLDER',
	CHANGE_EQUITY_PARTIAL_TRANSFER_SHARE_ISSUANCE_ADD_SHAREHOLDER: 'CHANGE_EQUITY_PARTIAL_TRANSFER_SHARE_ISSUANCE_ADD_SHAREHOLDER',
	CHANGE_EQUITY_PARTIAL_TRANSFER_SHARE_ISSUANCE_UPDATE_SHAREHOLDER: 'CHANGE_EQUITY_PARTIAL_TRANSFER_SHARE_ISSUANCE_UPDATE_SHAREHOLDER',
	CHANGE_EQUITY_CANCEL_SHARE_ISSUANCE_REMOVE_SHAREHOLDER: 'CHANGE_EQUITY_CANCEL_SHARE_ISSUANCE_REMOVE_SHAREHOLDER',
	CHANGE_EQUITY_CONVERT_OPTION_GRANT_ADD_SHAREHOLDER: 'CHANGE_EQUITY_CONVERT_OPTION_GRANT_ADD_SHAREHOLDER',
	CHANGE_EQUITY_CONVERT_OPTION_GRANT_UPDATE_SHAREHOLDER: 'CHANGE_EQUITY_CONVERT_OPTION_GRANT_UPDATE_SHAREHOLDER',
	CHANGE_EQUITY_CONVERT_CONVERTIBLE_ADD_SHAREHOLDER: 'CHANGE_EQUITY_CONVERT_CONVERTIBLE_ADD_SHAREHOLDER',
	CHANGE_EQUITY_CONVERT_CONVERTIBLE_UPDATE_SHAREHOLDER: 'CHANGE_EQUITY_CONVERT_CONVERTIBLE_UPDATE_SHAREHOLDER',
	CHANGE_EQUITY_EXERCISE_WARRANT_ADD_SHAREHOLDER: 'CHANGE_EQUITY_EXERCISE_WARRANT_ADD_SHAREHOLDER',
	CHANGE_EQUITY_EXERCISE_WARRANT_UPDATE_SHAREHOLDER: 'CHANGE_EQUITY_EXERCISE_WARRANT_UPDATE_SHAREHOLDER',

	CHANGE_ACCOUNTING_REFERENCE_DATE: 'CHANGE_ACCOUNTING_REFERENCE_DATE',
	CHANGE_ADVANCED_CERTIFICATE_OF_INCUMBENCY: 'CHANGE_ADVANCED_CERTIFICATE_OF_INCUMBENCY',
	CHANGE_AUDITOR: 'CHANGE_AUDITOR',
	CHANGE_AUTHORISED_SIGNATORY_MANDATE: 'CHANGE_AUTHORISED_SIGNATORY_MANDATE',
	CHANGE_BUSINESS_EXTRACT: 'CHANGE_BUSINESS_EXTRACT',
	CHANGE_CERTIFICATE_OF_EXISTENCE: 'CHANGE_CERTIFICATE_OF_EXISTENCE',
	CHANGE_CERTIFICATE_OF_GOOD_STANDING: 'CHANGE_CERTIFICATE_OF_GOOD_STANDING',
	CHANGE_CERTIFICATE_OF_INCUMBENCY: 'CHANGE_CERTIFICATE_OF_INCUMBENCY',
	CHANGE_CERTIFIED_COPY_OF_A_DOCUMENT: 'CHANGE_CERTIFIED_COPY_OF_A_DOCUMENT',
	CHANGE_COMPANY_ARTICLES_ASSOCIATION: 'CHANGE_COMPANY_ARTICLES_ASSOCIATION',
	CHANGE_COMPANY_AUTHORIZED_SHARE_CAPITAL: 'CHANGE_COMPANY_AUTHORIZED_SHARE_CAPITAL',
	CHANGE_COMPANY_BUSINESS_ACTIVITIES: 'CHANGE_COMPANY_BUSINESS_ACTIVITIES',
	CHANGE_COMPANY_CURRENCY: 'CHANGE_COMPANY_CURRENCY',
	CHANGE_COMPANY_DELETE_AUTHORIZED_SHARE_CAPITAL: 'CHANGE_COMPANY_DELETE_AUTHORIZED_SHARE_CAPITAL',
	CHANGE_COMPANY_NAME: 'CHANGE_COMPANY_NAME',
	CHANGE_LEASE: 'CHANGE_LEASE',
	CHANGE_NO_OBJECTION_CERTIFICATE: 'CHANGE_NO_OBJECTION_CERTIFICATE',
	CHANGE_PARTIAL_SHARE_CLASS_RIGHTS: 'CHANGE_PARTIAL_SHARE_CLASS_RIGHTS',
	CHANGE_PAR_VALUE_OF_SHARES_DECREASE: 'CHANGE_PAR_VALUE_OF_SHARES_DECREASE',
	CHANGE_PAR_VALUE_OF_SHARES_INCREASE: 'CHANGE_PAR_VALUE_OF_SHARES_INCREASE',
	CHANGE_REGISTERED_OFFICE_ADDRESS: 'CHANGE_REGISTERED_OFFICE_ADDRESS',
	CHANGE_SHARE_CERTIFICATE: 'CHANGE_SHARE_CERTIFICATE',
	CHANGE_SHARE_CLASS: 'CHANGE_SHARE_CLASS',
	CHANGE_STAKEHOLDER_ADD_ROLE_DIRECTOR: 'CHANGE_STAKEHOLDER_ADD_ROLE_DIRECTOR',
	CHANGE_STAKEHOLDER_ON_ROLE_DIRECTOR: 'CHANGE_STAKEHOLDER_ON_ROLE_DIRECTOR',
	CHANGE_STAKEHOLDER_ON_ROLE_SHAREHOLDER: 'CHANGE_STAKEHOLDER_ON_ROLE_SHAREHOLDER',
	CHANGE_STAKEHOLDER_ON_ROLE_UBO: 'CHANGE_STAKEHOLDER_ON_ROLE_UBO',
	CHANGE_STAKEHOLDER_ON_DATA_PROTECTION_CONTACT: 'CHANGE_STAKEHOLDER_ON_DATA_PROTECTION_CONTACT',
	CHANGE_STAKEHOLDER_REMOVE_ROLE_DIRECTOR: 'CHANGE_STAKEHOLDER_REMOVE_ROLE_DIRECTOR',
	CHANGE_UBO: 'CHANGE_UBO',
	CHANGE_UNDER_FORMATION_CERTIFICATE: 'CHANGE_UNDER_FORMATION_CERTIFICATE',
	CHANGE_UPDATED_COMPANY_REGISTERS: 'CHANGE_UPDATED_COMPANY_REGISTERS',
	CHANGE_UPDATED_MEMORANDUM_OF_ASSOCIATION: 'CHANGE_UPDATED_MEMORANDUM_OF_ASSOCIATION',
	CHANGE_UPDATED_REGISTER_OF_DIRECTORS: 'CHANGE_UPDATED_REGISTER_OF_DIRECTORS',
	CHANGE_UPDATED_REGISTER_OF_MEMBERS: 'CHANGE_UPDATED_REGISTER_OF_MEMBERS',
	CHANGE_ZERO_TAX_CERTIFICATE: 'CHANGE_ZERO_TAX_CERTIFICATE',
	COMPANY_RENEWAL: 'COMPANY_RENEWAL',
	COMPANY_STRIKE_OFF: 'COMPANY_STRIKE_OFF'
};
const QUERY = `
query getPINCAPrices($filters: PINCAPriceFilterInput) {
  getPINCAPrices(filters: $filters) {
    code
    name
    value
    vat
    product {
      code
    }
  }
}`;

/**
 * Call to the query useGetShareClass
 * @returns
 */
function useGetPINCAPrices(options) {
	const newOptions = useMemo(
		() => ({
			...options,
			variables: {
				...options?.variables
			},
			fetchPolicy: 'cache-first',
			nextFetchPolicy: 'cache-first'
		}),
		[options]
	);
	const {manualQuery, loading, error, data, refetch} = useLazyQuery(QUERY, null, newOptions);
	const aux = useCallback(async (props) => {
		const {data} = await manualQuery(props);
		const di = {};
		forEach(data?.getPINCAPrices ?? [], (item) => {
			di[item.code] = item;
		});
		return di;
	}, [manualQuery]);
	return {loading, error, data, refetch, manualQuery: aux};
}

useGetPINCAPrices.propTypes = {
	variant: PropTypes.string,
	definition: PropTypes.string,
	query: PropTypes.string
};

export default useGetPINCAPrices;
export {PINCAS_KEYS};

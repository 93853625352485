const TEXTS = {
    TITLE_COMPANY: 'Is there a company Shareholder Register listing this share position?',
    TITLE:'Upload Shareholder Register',
    TITLE_SHARE: 'Upload Shareholder Register',
    TITLE_CONVERTIBLE: 'Upload an agreement',
    TITLE_OPTION: 'Upload Grant Agreement',
    TITLE_OPTION_SIP: 'Upload a Share Incentive Plan (SIP)',
    TITLE_WARRANT: 'Upload a Warrant Agreement',
    SUBTITLE: 'Company equity',
    SUBTITLE_COMPANY: 'Company equity',
    SUBTITLE_SHARE: 'Equity positions - Add share position',
    SUBTITLE_CONVERTIBLE: 'Equity positions - Add convertible',
    SUBTITLE_OPTION: 'Equity positions - Add option grant',
    SUBTITLE_WARRANT: 'Equity positions - Add warrant',
    TOOLTIP_2: "This will be safely stored in Documents, your Clara data room"
}
export default  TEXTS
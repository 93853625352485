import useQuery from "../../../../../hooks/custom/useQuery";
import {FormationData} from '../fragments'
import _ from "lodash";
const GET_COMPANY_DETAILS_QUERY = `
${FormationData}
query companyFormationCompanyDetails($startupId: ID!, $groupCompanyId: ID!) {
  getGroupCompany(startupId: $startupId, groupCompanyId: $groupCompanyId) {
    id
    formationData {
      ...FormationDataFields
    }
  }
}`

const useGetFormationCompany =(variables) => {
    const { loading, error, data, refetch } = useQuery(GET_COMPANY_DETAILS_QUERY, variables);
    return { loading, error, data: _.get(data, "getGroupCompany", []), refetch };
}
export default useGetFormationCompany
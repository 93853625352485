import React, { FC, useState, useEffect } from "react";
import Wizard from "../../../generic/components/Wizard";
import ModalAssistant, { ModalAssistantProps } from "../../../generic/components/Modal/ModalAssistant";
import MachineAddressAndCurrency from "./machine";
import {
  addAssistantText,
  clearAssistatText,
} from "../../../generic/store/action";
import { useDispatch } from "react-redux";
import useTranslate from "../../../generic/hooks/useTranslate";
import useModal from "../../../generic/hooks/useModal";

interface ModalDocumentReviewProps extends ModalAssistantProps {
  onClose(): void;
  paramsMutation: any;
}

const ModalDocumentReview: FC<ModalDocumentReviewProps> = ({
  children,
  open: openParam = false,
  paramsMutation,
  onClose,
  ...props
}: ModalDocumentReviewProps) => {

  const [open, setOpen] = useState(openParam);
  const { closeModal } = useModal();
  const dispatch = useDispatch();
  const { translate } = useTranslate();

  useEffect(() => {
    setOpen(openParam);
  }, [openParam]);

  const handleClose = (values) => {
    closeModal();
    setOpen(false);
  };

  const handleInitModal = () => {
    dispatch(clearAssistatText());
    dispatch(
      addAssistantText(
        translate('MODULES.GROUP_COMPANY.DOCUMENT_REVIEW.FIRST_STEP.TOOLTIP')
      )
    );
  };

  useEffect(() => {
    handleInitModal();
  }, []);

  return (
    <ModalAssistant open={open} onClose={handleClose}>
      <Wizard
        machine={MachineAddressAndCurrency}
        initialValues={paramsMutation}
        onComplete={handleClose}
      />
    </ModalAssistant>
  );
};

export default ModalDocumentReview;

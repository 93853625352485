import React, {Component} from "react";
import {connect} from "react-redux";
import classes from "./ClaraInputTextArea.module.scss"
import Tooltip from '@material-ui/core/Tooltip';
import {Constants} from "../../../utils/constants";

class ClaraInputTextArea extends Component {
    state = {
        tooltipText : null,
        placeHolder: null,
        validation: {},
        label: null,
        defaultValue: null,
        errorMessage: null,
        showError: false,
        value: null,
    };
    /* React Methods */
    componentWillUpdate(prevProps, prevState, snapshot) {
        if (prevProps.session !== this.props.session) {

        }
    }
    componentWillMount() {
        var { tooltipText, placeHolder, validation, label, defaultValue, name, value } = this.props;
        this.setState({
            tooltipText,
            placeHolder,
            validation,
            label,
            defaultValue,
            name,
            value
        })
    }
    componentDidMount() {
        this.managerValidation(this.state.value);
    }
    /* Logic */
    getError(type, validation) {
        validation = this.state.validation[type]
        if (validation.errorMessage) {
            return validation.errorMessage
        }
        return Constants.DEFAULT_MESSAGES.VALIDATION[type];
    }
    /* Functionality */
    validate(input) {
        if (this.state.validation && this.state.validation.required) {
            if (this.state.validation.required.value === true && (!input || input === "")) {
                return this.getError("required")
            }

        }
        if (this.state.validation && this.state.validation.pattern) {
            const pattern = new RegExp(this.state.validation.pattern.value);
            if (pattern.test(input) === false) {
                return this.getError("pattern")
            }
        }
        return null;
    }
    managerValidation(value){
        const error = this.validate(value);
        if (error) {
            this.setState({
                errorMessage: error,
                value:value
            })
            this.props.updateData(this.state.name, null, false,this.props.index);
        } else {
            this.setState({
                errorMessage: null,
                value:value
            })
            this.props.updateData(this.state.name, value, true,this.props.index);
        }
    }
    /* Managaer Events*/
    handleChange = (e) => {
        this.managerValidation(e.target.value);
        
    }
    handleBlur = (e) => {
        this.setState({
            showError: true
        })
        this.managerValidation(this.state.value);
    }
    /* Section to get dinamic components*/
    componentJSX_getTooltip() {
        if (this.state.tooltipText
        ) {
            return (
                <Tooltip title={this.state.tooltipText} aria-label={this.state.tooltipText}
                    placement="right"
                    classes={{ 
                        tooltip: classes.tooltip,
                        tooltipPlacementRight: classes.tooltipPlacementRight 
                    }}
                > 
                    <img src="/img/icons/tooltip.svg"></img>
                </Tooltip>
            )
        }
        return null;
    }
    componentJSX_getErrorMessage() {
        if (this.state.errorMessage && this.state.showError) {
            return (
                <div className='errorClaraInput'>
                    {this.state.errorMessage}
                </div>
            )
        }
        return null;
    }
    render() {
        const {value} = this.state
        //console.log(' value ',value)
        return (
            <div className={classes.ClaraInputTextArea}>

                <label className={classes.label}>
                    {this.state.label}
                    {this.componentJSX_getTooltip()}
                </label>
                <textarea
                    value={value}
                    disabled={this.props.disabled}
                    placeholder={this.state.placeHolder}
                    className={classes.input}
                    // onChange={(e) => this.handleChange(e)}
                    onBlur={(e) => this.handleBlur(e)}

                >
                    
                </textarea>
                {this.componentJSX_getErrorMessage()}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    session: state.session
});
const mapDispatchToProps = (dispatch) => ({

});
export default connect(mapStateToProps, mapDispatchToProps)(ClaraInputTextArea)

import useMutation from "../../../hooks/custom/useMutation";

const mutation = `mutation finishNewCreditCardPayment($ckoSessionId: String!, $paymentSource: String!){
  finishNewCreditCardPayment(ckoSessionId: $ckoSessionId, paymentSource: $paymentSource) {
    ...on MatterLegacy{
      id
      name
    }
    ...on Startup{
      id
      name
      hasScaleSubscription
      subscriptions{
        id
        startDate
        renewalDate
        paymentMethod {
          ... on BankCard {
              bankCardType
              last4
              _id
            }
        }
        status
        subscriptionPlan {
          id
          code
          name
          description
          price
          features {
            id
            name
            code
          }
        }
      }
      activeSubscriptionPlan{
        id
        code
        name
        features{
          code
          id
          name
          limit {
            ...on SubscriptionFeatureAmountLimit{
                amount
            }
            ...on SubscriptionFeatureAllowedLimit{
                allowed
            }
            ...on SubscriptionFeatureAllowedFileExtensionLimit{
                allowedExtensions
            }
          }
        }
      }
      subscriptionFeaturesUsage{
        code
        amount
      }

    }
  }
} `
const useFinish3DSPaymentToUpgradeStartup = (options = {}) => {
  const shouldRefetchQuery = (observer) => {
    switch (observer.queryName) {
        case "validatePaymentCoupon":
            return false;
        default:
            return true;
    }
};
  const [send, data] = useMutation(mutation, {shouldRefetchQuery,...options});


  return [send, data];
}
export default useFinish3DSPaymentToUpgradeStartup;

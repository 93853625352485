

const getIssueQuery = `{
  getAgreement(startupId:"{{startupId}}",agreementId:"{{agreementId}}"){
    signedAs
    id
    status
    groupId
    executedOnDate
    name
    verified
    file{
      name
      type
    }
    terms{
      key
      value
    }
   type
    parties{
      stakeholder{
        fullName
        avatar
        id
      }
      role
    }
  }
}`;
const deleteIssueQuery = `
mutation deleteIssue($startupId: String, $issueId: String) {
  deleteIssue(startupId: $startupId, issueId: $issueId)
 
 }
`;
const getSubcategories =`
{
  getDueDiligenceCategories{
    key
    subCategories{
      key
      name
    }
  }
}
`;
export {
  getIssueQuery,
  deleteIssueQuery,
  getSubcategories
}

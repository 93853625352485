import { useMemo } from "react";
import useSession from "src/modules/session/hooks/useSession";
import _ from "lodash";
import useUpsertFormationCompany from "src/modules/startup/GroupCompany/graphql/mutations/useUpsertFormationCompany";
import moment from "moment/moment";
import useUpsertComponentStakeholder from "../../../NomineeServiceModal/wizard/hooks/services/useUpsertComponentStakeholder";
import useAddComponentRole from "../../../../../../../../onBoarding/core/graphql/mutations/useAddComponentRole";

const useServices = ({ initialValues }) => {
  const { startupId } = useSession();
  const [upsertFormationCompany] = useUpsertFormationCompany({}, {});
  const { addComponentRole } = useAddComponentRole(
    {
      showSuccessNotification: true,
      successMessage: "STAKEHOLDER_ADD_SUCCESS",
    },
    {}
  );
  const [mutation] = useUpsertComponentStakeholder(
    {
      showSuccessNotification: false,
    },
    {}
  );

  // updates the formation data with the authorised signatories
  const upsertFormation = async (
    authorisedSignatoriesIds,
    companyId,
    useThird
  ) => {
    return await upsertFormationCompany({
      startupId,
      groupCompanyId: companyId,
      groupCompanyData: {
        formationData: {
          authorisedSignatories: authorisedSignatoriesIds,
          isUsingNominee: !useThird,
          isUsingThirdParty: useThird,
        },
      },
    });
  };

  // adds a new stakeholder to the company
  const addStakeholder = async (stakeholderValues, companyId) => {
    let values = {
      startupId,
      stakeholder: {
        ...stakeholderValues,
      },
      roles: [
        {
          role: [{ type: "THIRDPARTY" }],
          groupCompany: companyId,
        },
      ],
    };

    return await addComponentRole({ ...values });
  };

  // updates the stakeholder with the enterType and entryDate values
  const updateStakeholder = async (stakeholderId, enterType, entryDate) => {
    const stakeholderToUpdate = {
      startupId,
      stakeholderId: stakeholderId,
      stakeholder: {
        enterType: enterType,
        entryDate: entryDate,
      },
    };

    return await mutation({
      ...stakeholderToUpdate,
    });
  };

  return useMemo(
    () => ({
      initialData: async () => {
        return {
          ...initialValues,
        };
      },
      SaveHowDidTheyEnterFormStep: async (context) => {
        await updateStakeholder(
          context?.WhoIsSecondAuthorisedSignatoryFormStep
            .secondAuthorisedSignatory.id,
          context?.HowDidTheyEnterFormStep?.howDidTheyEnter ?? null,
          context?.HowDidTheyEnterFormStep?.entryDate
            ? moment(context?.HowDidTheyEnterFormStep?.entryDate).format(
                "YYYY-MM-DD"
              )
            : null
        );

        return await upsertFormation(
          [
            context?.WhoIsSecondAuthorisedSignatoryFormStep
              .secondAuthorisedSignatory.id,
          ],
          context?.initialData.idGroupCompany,
          false
        );
      },

      // You have selected to use a Clara nominee service, and selected a third party as your second signatory
      SaveThirdPartyWithNomineeFormStep: async (context) => {
        const st = await addStakeholder(
          {
            fullName: context?.ThirdPartyWithNomineeFormStep?.fullName,
            enterType:
              context?.ThirdPartyWithNomineeFormStep?.howDidTheyEnter ?? null,
            entryDate: context?.ThirdPartyWithNomineeFormStep?.entryDate
              ? moment(
                  context?.ThirdPartyWithNomineeFormStep?.entryDate
                ).format("YYYY-MM-DD")
              : null,
          },
          context?.initialData.idGroupCompany
        );

        return await upsertFormation(
          [st?.data.addComponentStakeholderRole?.id],
          context?.initialData.idGroupCompany,
          false
        );
      },

      // You have NOT selected to use a Clara nominee service, and selected a third party as your signatory
      SaveThirdPartyWithoutNomineeFormStep: async (context) => {
        const st = await addStakeholder(
          {
            fullName: context?.ThirdPartyWithoutNomineeFormStep?.nameField,
          },
          context?.initialData.idGroupCompany
        );

        return await upsertFormation(
          [st?.data.addComponentStakeholderRole?.id],
          context?.initialData.idGroupCompany,
          true
        );
      },

      SaveStep: async (context) => {
        const authorisedSignatories = _.get(
          context,
          "EligibleStakeholders.authorisedSignatories",
          []
        );
        const additionalStakeHolders = _.get(
          context,
          "AddAnotherAuthorizedSignatoryFormStep.authorisedSignatories",
          []
        );
        let authorisedSignatoriesIds = _.map(
          authorisedSignatories,
          (stakeholder) => {
            return stakeholder.id;
          }
        );
        const additionalAuthorisedSignatoriesIds = _.map(
          additionalStakeHolders,
          (stakeholder) => {
            return stakeholder.id;
          }
        );
        authorisedSignatoriesIds = authorisedSignatoriesIds.concat(
          additionalAuthorisedSignatoriesIds
        );

        await upsertFormationCompany({
          startupId,
          groupCompanyId: _.get(context, "initialData.idGroupCompany"),
          groupCompanyData: {
            formationData: {
              authorisedSignatories: authorisedSignatoriesIds,
            },
          },
        });
      },
    }),
    [initialValues]
  );
};

export default useServices;

import React from "react";
import classes from "./Styles.module.scss";
import ControllerInput from "../../../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import SelectAddInput from "../../../../../inputs/Select/SelectAddInput/SelectAddInput";
import Text from "../../../../../text/Text/Text";
import useTranslate from "../../../../../../modules/generic/hooks/useTranslate";
import TemplateLabel from '../../../../../../modules/generic/components/Inputs/LabelInput';
const AddEntity = ({
  label,
  name,
  message,
  callback,
  formsAdd,
  menuProps,
  asterisk,
  list,
  refSelect,
  disabled = false,
  ...props
}) => {

  const { translate } = useTranslate();

  return (
    <React.Fragment>
      <TemplateLabel
        label={translate(label)}
        isRequired={true}
      >
        <ControllerInput
          render={SelectAddInput}
          list={list}
          refSelect={refSelect}
          mode={"add"}
          placeholder={translate('MODULES.CLARA.SELECT_FROM_LIST')}
          formsAdd={formsAdd}
          compare="id"
          onChange={(value) => {
            if (callback && value) callback(value);
          }}
          menuProps={menuProps}
          name={name}
          disabled={disabled}
        />
      </TemplateLabel>
      {message.open && (
        <div className={classes.containerMessage}>
          <Text uuid={message.text} />
        </div>
      )}
    </React.Fragment>
  );
};

export default AddEntity;

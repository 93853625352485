import React, {Component} from "react";
import {connect} from "react-redux";
import {goToPage, goToPrevPage, setNotPrev, setPage} from '../../../store/actions/page'
import _ from "lodash";

class ManagerPages extends Component {

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.page.reloadPage) {
            if (
                nextProps.page.historyPage[nextProps.page.historyPage.length - 1].url !== _.get(nextProps, 'match.url')) {
                this.props.history.push(nextProps.page.historyPage[nextProps.page.historyPage.length - 1].url);
            }

        }

    }
    componentDidUpdate() {

    }
    componentWillMount() {
        this.props.setNotPrev(_.get(this.props, 'match.url'));
        window.onpopstate = (e) => {
            e.preventDefault();
            this.props.goToPrevPage();
        }
    }

    render() {

        return (
            <React.Fragment>
                {this.props.children}
            </React.Fragment>
        )

    }
}

const mapStateToProps = state => {
    return {
        page: state.page
    };
};
const mapDispatchToProps = dispatch => ({
    setPage: (page, params) => {
        setPage(dispatch, page, params)
    },
    goToPage: (page, params) => {
        goToPage(dispatch, page, params)
    },
    goToPrevPage: (reload, defaultPage, params) => {
        goToPrevPage(dispatch, reload, defaultPage, params)
    },
    setNotPrev: (url) => {
        setNotPrev(dispatch, url)
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManagerPages);

export default {
    MONEY: "MONEY",
    SHARE: "SHARE",
    NUMBER: "NUMBER",
    PERCENT: "PERCENT",
    DATE_SHOW:"DATE_SHOW",
    DATE:"DATE",
    DATE_ASSISTANCE:"DATE_ASSISTANCE",
    DATE_TIME:"DATE_TIME",
    TIME:"TIME",
	DATE_DAY_MONTH:"DATE_DAY_MONTH",
    LAST_BY_COMA: "",
    PERCENTAGE:"PERCENTAGE"
};

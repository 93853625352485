export default {
  FIRST_STEP: {
    TITLE: 'Do you need banking services in ADGM?',
    SUBTITLE: 'Banking authority',
    TOOLTIP: 'The company will need an ADGM-based bank account if you want to get started straight away.'
  },
  SECOND_STEP: {
    TITLE: 'Select authorised signatories for banking purposes ',
    SUBTITLE: 'Banking authority',
    TOOLTIP: 'Choose one or more individual to sign banking-related documents.'
  },
  THIRD_STEP: {
    TITLE: 'Will they be able to sign singly or jointly?',
    SUBTITLE: 'Banking authority',
    TOOLTIP: 'Select \'singly\' if the selected individuals can sign documents on behalf of other signatories. Select \'jointly\' if all signatories should sign every required document. '
  },
}
import React from 'react';
import SignInView from "../session/signInView/SignInView";
import SignUpView from "../session/signUpView/SignUpView";
import ForgotPasswordView from "../session/forgotPasswordView/ForgotPasswordView";
import UpdatePasswordView from "../session/updatePasswordView/UpdatePasswordView";
import BlueWhiteScreenLayout from '../../../layouts/BlueWhiteScreen/BlueWhiteScreenLayout';
import { LeftEducation } from "../../../v1/components";
import SliderContentful from '../../../components/generic/SliderContentful/SliderContentful';
import { BrowserRouter as Redirect } from "react-router-dom";
import ACTIONS from "../../../constants/actions"
import PAGES from "../../../constants/pages";
import LogInLinkedin from "../../../components/inputs/ButtonLinkedin/LogInLinkedin"
import BackgroundHoops from '../../../layouts/BackgroundHoops';
import RedirectBackOffice from './RedirectView';
import EmptyScreenLayout from 'src/layouts/EmptyScreenLayout/EmptyScreenLayout';
import LoginView from './LoginView/LoginView';


const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const entryId = urlParams.get('entryId') ? urlParams.get('entryId') : 'default'
const registerContentfulId = {
  agreement: '6IQVw48Qcuc7V2MklhL45R',
  formation: '7aBZUImcBx731czw6jbiuZ',
  default: '1eLdm2PkiPdpjjSr6jrfjc'
}

const routes = [
  {
    path: PAGES.account.path,
    exact: false,
    component: <LoginView />,
    layout: EmptyScreenLayout,

  },
  {
    path: PAGES.linkedinLogin.path,
    exact: true,
    component: <LogInLinkedin />,
    layout: EmptyScreenLayout,
    propsLayout: {
      showClose: false,
    },
  },
 

  {
    path: PAGES.redirectBackOffice.path,
    exact: true,
    component: <RedirectBackOffice />,
    layout: BackgroundHoops,
    propsLayout: {
      showClose: false,
    },
  }
];

export default routes;

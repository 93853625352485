import { useEffect } from 'react';
import useMutation from "../../../hooks/custom/useMutation";


const mutation = `mutation initNewCreditCardPayment($creditCardToken: String!,$billingAddress: BillingAddressInput!,$couponCode: String, $productCode:String!,$relatedItemId:ID!, $cardHolderName: String, $source: String!){
    initNewCreditCardPayment(
      creditCardToken: $creditCardToken,
      billingAddress: $billingAddress,
      couponCode: $couponCode,
      cardHolderName: $cardHolderName,
      productCode: $productCode,
      relatedItemId: $relatedItemId,
      source: $source
      ) 
  } `
const useInit3DSPaymentToUpgradeStartup = (options={}) => {

  const [send, data] = useMutation(mutation, options);

  return [send, data];
}
export default useInit3DSPaymentToUpgradeStartup;

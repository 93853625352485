import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import _ from 'lodash';

const GET_DOCUMENT_ASSOCIATED = gql`
  query DocumentList($startupId: ID!) {
  documentList(startupId: $startupId) {  
      name
      id
  }
}
`
function useGetDocumentAssociated(options = {}) {

  const { data, ...rest } = useQuery(GET_DOCUMENT_ASSOCIATED, options);

  return { ...rest, data: _.get(data, 'documentList', []) };
}

export default useGetDocumentAssociated;

import useBusinessRules from 'src/modules/clara/hooks/useBusinessRules';
import { useMemo } from 'react';
import useSession from 'src/modules/session/hooks/useSession';

const useGuards = () => {
  const { isOwner } = useBusinessRules();
  const { session } = useSession();

  return useMemo(() => ({
    detail: (context) => {
      return true;
      // return false;
    },
    nationality: (context) => {
      // return true;
      return false;
    },
    addresses: (context) => {
      return false;
      // return true;
    },
    fitAndProperStatement: (context) => {
      return false;
      // return true;
    },
    pep: (context) => {
      return false;
      // return true;
    },
    sof: (context) => {
      return false;
      // return true;
    },

  }), [isOwner, session]);
}

export default useGuards;

import React, { useMemo, useState } from 'react';
import classes from './Styles.module.scss'
import Text from '../../../../text/Text/Text'
import useCategories from '../../../../../hooks/common/useCategories';
import _ from 'lodash'
import IconBlock from '../../../../../components/templates/IconBlock/IconBlock';
import Date from "../../../../../components/text/Text/Date";
import Line from '../../../../generic/Line/Line';
import TabsMenu from "../../../../../modules/generic/components/Tab";
import TabMenu from "../../../../../modules/generic/components/Tab/TabMenu";
import { PartiesUploaded, History, Details } from './Tabs';
import { Constants } from "../../../../../v1/utils";


const PanelUploaded = ({ data, ...props }) => {

  const { categories = [], loading } = useCategories("STARTUP");

  const category = useMemo(() => {
    return _.find(categories, cat => _.some(cat.subCategories, subCat => subCat.key === _.get(data, "subCategories.0")));
  }, [loading, _.get(data, "subCategories")]);

  const isIdentityDocumentPassport = useMemo(() => data ? _.includes(Constants.PASSPORT_DOCUMENT_TYPES, data?.type?.code) : false, [JSON.stringify(data)])

  const isIdentityDocumentVisaStampsResidence = useMemo(() => data ? _.includes(Constants.VISA_STAMPS_RESIDENCE_ID_DOCUMENTS_TYPES, data?.type?.code) : false, [JSON.stringify(data)])

  const subCategory = useMemo(() => {
    if (loading) {
      return "";
    }
    const category = _.find(categories, cat => _.some(cat.subCategories, subCat => subCat.key === _.get(data, "subCategories.0")));
    return _.find(_.get(category, "subCategories"), subCat => subCat.key === _.get(data, "subCategories.0"));
  }, [loading, _.get(data, "subCategories")]);


  const [tabValue, setTabValue] = useState(0);

  const handleOnChangeTab = (event, value) => {
    setTabValue(value);
  };

  const tabs = useMemo(() => (isIdentityDocumentPassport || isIdentityDocumentVisaStampsResidence) ? [
    <TabMenu classes={{ root: classes.tabElement }}  >
      Parties
    </TabMenu>,
    (<TabMenu classes={{ root: classes.tabElement }}  >
      Details
    </TabMenu>),
    <TabMenu classes={{ root: classes.tabElement }} >
      History
    </TabMenu>
  ] : [
    <TabMenu classes={{ root: classes.tabElement }}  >
      Parties
    </TabMenu>,
    <TabMenu classes={{ root: classes.tabElement }} >
      History
    </TabMenu>
  ], [isIdentityDocumentPassport, isIdentityDocumentVisaStampsResidence])

  return (
    <div className={classes.containerPanel} >
      <div className={classes.containerCategories}>
        <div className={classes.containerLabelText}>
          <label><Text uuid={"DOCUMENT_DETAIL_LABEL_TYPE"} /></label>
          <span>{_.get(data, 'type.label', '-')}</span>
        </div>
        <div className={classes.containerLabelText}>
          <label><Text uuid={"DOCUMENT_DETAIL_LABEL_CATEGORY"} /></label>
          <span>{_.get(category, 'title', '-')}</span>
        </div>
        <div className={classes.containerLabelText}>
          <label><Text uuid={"DOCUMENT_DETAIL_LABEL_SUBCATEGORY"} /></label>
          <span> {_.get(subCategory, 'title')}</span>
        </div>
      </div>
      <Line />
      <div className={classes.containerDates}>
        <IconBlock icon={"Date"} title={<Date value={_.get(data, "executedOnDate")} />} subTitle={<Text uuid={"DOCUMENT_DETAIL_LABEL_EXECUTION_DATE"} />} />
        <IconBlock icon={"Date"} title={<Date value={_.get(data, "expirationDate")} />} subTitle={<Text uuid={"DOCUMENT_DETAIL_LABEL_EXPIRATION_DATE"} />} />
      </div>
      <div className={classes.containerTabsMenu}>
        <TabsMenu
          classes={{
            root: classes.rootTabs
          }}
          onChange={handleOnChangeTab}
          initValue={tabValue} tabs={tabs} 
        />
        {_.isEqual(0, tabValue) && <PartiesUploaded data={data} />}
        {(isIdentityDocumentPassport || isIdentityDocumentVisaStampsResidence) && _.isEqual(1, tabValue) && <Details isIdentityDocumentDetails={isIdentityDocumentPassport || isIdentityDocumentVisaStampsResidence} data={data} />}
        {_.isEqual(2 - !(isIdentityDocumentPassport || isIdentityDocumentVisaStampsResidence), tabValue) && <History data={data} />}
      </div>
    </div>
  )
};

export default PanelUploaded


import { gql, useQuery } from '@apollo/client';
const GET_CUSTOM_TEMPLATES_BY_STARTUP_ID = gql`
query getCustomTemplatesByStartupId($startupId: ID!) {
  customTemplates: getCustomTemplatesByStartupId(startupId: $startupId) {
    id
    version
    name
    allowedActions{
      toDelete{
        value
		    reasonCode
		    reasonText
      }
    }
    documentType {
      id
      code
      entryId
      region {
        id
        name
        code  

      }

    }
    status
  }
}
`;;
  const useGetCustomTemplatesByStartupId = (config={})=>{
    const {data,loading}= useQuery(GET_CUSTOM_TEMPLATES_BY_STARTUP_ID,{fetchPolicy:'cache-and-network', nextFetchPolicy:'cache-and-network',...config})
    return {data:data?.customTemplates ??[],loading}
  }
  export default useGetCustomTemplatesByStartupId;
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import Button from "src/modules/generic/components/Buttons/Button";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import { clearAssistatText } from "src/modules/generic/store/action";
import FormCompany from "src/modules/startup/onBoarding/company/forms/CompanyForm/CompanyForm";
import useSession from "src/modules/session/hooks/useSession";

function AddCompanyBasicInfoStep() {
  const { translate } = useTranslate();
  const { next, prev, cancel, state, context } = useMachine();
  const dispatch = useDispatch();
  const { startupId, selectStartup } = useSession();

  const initialValues = {};

  useEffect(() => {
    dispatch(clearAssistatText());
  }, []);

  const handleSubmit = async (values) => {
    await selectStartup(startupId, null);
    next(values);
  };

  const handleCancel = () => {
    cancel();
  };

  const handlePrev = () => {
    prev();
  };

  return (
    <>
      <FormCompany
        initialValues={initialValues}
        hasTopCompany={_.some(
          _.get(state, "context.paramsData.startup.groupCompanies", []),
          { isHoldingCompany: true }
        )}
        onCompleteSubmit={handleSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: translate(
            "MODULES.GROUP_COMPANY.ASSETS_AND_REVENUES.FOURTH_STEP.TITLE"
          ),
          subTitle: "Review company details",
        }}
        buttons={{
          previous: (
            <Button onClick={handlePrev} variant="secondary">
              Previous
            </Button>
          ),
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              Cancel
            </Button>
          ),
        }}
      />
    </>
  );
}

export default AddCompanyBasicInfoStep;

import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useModal from '../../../../modules/generic/hooks/useModal';
import { Modal } from '../../../../v1/components';
import { Constants } from '../../../../v1/utils';
import ServerConnect from '../../../../v1/utils/ServerConnect';
import Botonera from '../../../forms/contents/Botonera/Botonera';
import ModalLoading from '../../../generic/Loading/ModalLoading/ModalLoading';
import GetSignatures from '../../../modules/clara/startups/Document/Modals/EditDocumentGenerate/GetSignatures';
import PrimaryButton from '../../Buttons/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../Buttons/SecondaryButton/SecondaryButton';
import DocumentPreviewFile from "../../Documents/DocumentPreview/DocumentPreviewFile";
import classes from './DocumentPreviewModalStyle.module.scss';

const DocumentPreviewModalStyle = ({ loading = false, open: showModal, showDownload = true, showGetSignatures, showDetails, document, handleClose, title }) => {
	
	const dispatch = useDispatch();
	const imageTypeSupported = ['jpg', 'jpeg', 'gif', 'png', 'png', 'svg', 'bmp'];
	const { file, name: documentName } = document;
	const fileType = file.name.split('.').pop(); // path.parse(file.name).ext;
	const [open, setOpen] = useState(false);

	useEffect(() => { setOpen(showModal) }, [showModal]);
	const handleCloseModal = () => {
		setOpen(false);
		handleClose && handleClose();
	};

	const handleCancel = () => {
		handleClose && handleClose();
	};

	const { openModal, closeModal } = useModal();

	const handleGetSignatures = () => {
		openModal(GetSignatures, {
			configMutation: {
				successMessage: "NOTIFICATION_SUCCESS_EXERCISE_NOTICE",
				paramsSuccessMessage: document
			},
			saveAndClose: false,
			data: document,
			handleClose: closeModal
		});
	};

	const handleDownload = () => {
		handleDownloadDocument(file.id, file.name);
	};

	const handleDownloadDocument = (fileId, fileName) => {
		let url = ServerConnect.getUrlFile(fileId);
		ServerConnect.downloadFile(url, fileName);
	};

	const handleViewDetailDocument = () => {

		dispatch({ type: 'GO_TO', page: Constants.PAGES[`viewDocument${_.upperFirst(_.toLower(document.kind))}`], params: { id: document.id, status: document.status } })
	};

	const getTypeClass = (fileType) => {
		if (imageTypeSupported.indexOf(fileType) > -1) {
			return "image";
		}
		return "pdf";
	};

	return (
		<>
			<ModalLoading loading={open && loading} label={`Your ${title} is being created`} />
			{!loading &&
				<Modal
					className={{
						modal: classes.dialogModal,
						header: classes.dialogHeader,
						dialog: {
							paper: classes.dialogPaper
						}
					}}
					overlayName={title ? title : documentName}
					open={open} handleClose={handleCloseModal} showHeader={true} title={title ? title : documentName}
				>
					<div className={classes.ModalContainer}>
						<div className={classes.DocumentPreview}>
							<DocumentPreviewFile
								fileId={file.id}
								fileType={fileType}
								fileName={file.name}
								defaultScale={0.5}
							/>
						</div>
						<div className={classes.DocumentPreviewBotonera}>
							<Botonera >
								<React.Fragment>
									{showDownload ? <SecondaryButton onClick={() => handleDownload()}>Download</SecondaryButton> : null}
									{showDetails ? <PrimaryButton onClick={handleViewDetailDocument}>More Details</PrimaryButton> : null}
									{showGetSignatures ? (<><SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
										<PrimaryButton onClick={handleGetSignatures}>Get Signatures</PrimaryButton></>) : null}
								</React.Fragment>
							</Botonera>
						</div>
					</div>
				</Modal>
			}
		</>
	)
};

export default DocumentPreviewModalStyle;

import useLazyQuery from "src/hooks/custom/useLazyQuery";
import useSession from "src/modules/session/hooks/useSession";
import {get} from "lodash";

const STAKEHOLDERS_QUERY =`
    query stakeholderList($startupId: ID!) {
        stakeholderList(startupId: $startupId) {
            id 
            fullName
            isAnEntity
        }
    }
`;
const useGetStakeholdersLazy = () => {
    const { startupId } = useSession();
    const { manualQuery, loading, data, refetch, error } = useLazyQuery(STAKEHOLDERS_QUERY, {
        startupId
    });
    return { data: { stakeholders: get(data,'stakeholderList',[]) }, loading, error, refetch, manualQuery };
}
export default useGetStakeholdersLazy;
import React from "react";
import _ from "lodash"
import Bubble from "../../Bubble";
import BubbleContents from "../../BubbleContents";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import AssistanceContents from "../../AssistanceContents";
import TabBubblesContainer from "../../TabBubblesContainer";
import moment from "moment";
import { useSelector } from 'react-redux';
import PaymentCard from "../../AssistanceAreas/Map2/Cards/PaymentCard";
import ReadMore from "../../../../../modules/assistanceV4/components/ReadMore";
import useBusinessRules from '../../../../../modules/clara/hooks/useBusinessRules';
import classes from "./styles.module.scss";
import classnames from "classnames";

const SubscriptionsTaskComponent = ({
  assistanceTasks,
  nextTask,
  stakeholders,
  allTasks
}) => {
  const {translate} = useTranslate();
  const session = useSelector(state => _.get(state, 'session'));
  const activeSubscriptionData = _.get(session ,"startup.activeSubscriptionPlan.activeSubscriptionData", {});
  const needPay = _.get(activeSubscriptionData, "mustPayInvoice", false);
  const { isOwner } = useBusinessRules();

  return (
    <AssistanceContents>
      <TabBubblesContainer>
        {(needPay && isOwner()) && (
          <PaymentCard
            isSubscriptionPage={true}
            date={moment(_.get(activeSubscriptionData, "downgradedScheduleDate", "")).format("DD MMM YYYY")}
          />
        )}
        <Bubble>
          <BubbleContents>
            <span className={classnames(classes.bubbleSpecialFont)}>
              {translate("TOOLTIPS_STAKEHOLDER_DETAILS_VIEWER")}
            </span>
          </BubbleContents>
        </Bubble>
      </TabBubblesContainer>
    </AssistanceContents>
  );
};

export default SubscriptionsTaskComponent;

import useLazyQuery from "src/hooks/custom/useLazyQuery";
import useSession from "src/modules/session/hooks/useSession";

const COMPANY_ROLES_STAKEHOLDERS =`
  query getCompanyRolesAndStakeholders($startupId: ID!) {
    getGroupCompanies(startupId: $startupId) {
      id
      name
      roles{
        entity {
          ... on StakeHolder {
            id
            fullName
          }
        }
        id
        name
        stakeholder{
          id
          fullName
          isFounder
        }
      }
    }
    stakeholderList(
      startupId: $startupId
    ) {
      id
      fullName
      isFounder
    }
  }
`;


const useGetCompanyRolesAndStakeholders = () => {
    const { startupId } = useSession();
    const { manualQuery,loading,data,refetch,error } = useLazyQuery(COMPANY_ROLES_STAKEHOLDERS, {
        startupId 
    });

    return { data, loading, error, refetch, manualQuery };
}
export default useGetCompanyRolesAndStakeholders;
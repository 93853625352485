import React from 'react';
import PAGES from "../../../../constants/pages";
import ClaraLayout from "../../../../layouts/Clara/ClaraLayout";
// import StartupListView from "./StartupList/StartupListView";
import MyProfilesView from "src/views/modules/startups/myProfiles/views"

const moduleRouter = [
  {
    path: PAGES.switchStartup.path,
    exact: true,
    component: <MyProfilesView />,
    layout: ClaraLayout,
    propsLayout: {
      startupMenu: false,
      userMenu: true,
      fullMenu: false,
      firstMenu: true,
      linkMenu: "LinkSwitchStartup"
    },
  },
];
export default moduleRouter;

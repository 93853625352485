import React, { FC, useState, useEffect } from 'react'
import Wizard from '../../../generic/components/Wizard'
import ModalAssistant, { ModalAssistantProps } from '../../../generic/components/Modal/ModalAssistant'
import MachineAddressAndCurrency from './machine'
import { clearAssistatText } from '../../../generic/store/action'
import { useDispatch } from 'react-redux'
import useTranslate from '../../../generic/hooks/useTranslate'
import useModal from "../../../generic/hooks/useModal";

interface ModalNoticesProps extends ModalAssistantProps {
    paramsMutation: any,
    onClose(): void
}

const ModalNotices: FC<ModalNoticesProps> = ({ children, open: openParam = false, paramsMutation, ...props }: ModalNoticesProps) => {

    const [open, setOpen] = useState(openParam);

    const { closeModal: onClose } = useModal();

    const dispatch = useDispatch();

    const { translate } = useTranslate();

    useEffect(() => {
        setOpen(openParam);
    }, [openParam])
    const handleClose = (values) => {
        onClose();
        setOpen(false);

    }

    const handleInitModal = () => {
        dispatch(clearAssistatText());
    }


    useEffect(() => {
        handleInitModal()
        return () => {
            dispatch(clearAssistatText());
        }
    }, []);


    return (
        <ModalAssistant open={open}>
            <Wizard machine={MachineAddressAndCurrency} initialValues={paramsMutation} onComplete={handleClose} />
        </ModalAssistant>

    )
}

export default ModalNotices;





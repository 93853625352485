import React from 'react';
import useHanldebars from "../../../hooks/custom/useHanldebars";
import Parser from "html-react-parser";
import PropTypes from 'prop-types'
/*
    Properties:
        uuid: key to get Text
        params: params to parse data
        lang: lang to use (not enabled now)
*/
const TextHandlebars = ({ uuid, params, defaultKey }) => {

    const text = useHanldebars(uuid, params, defaultKey);
    
    return (
        <React.Fragment>
            {text && Parser(text)}
        </React.Fragment>
    );
}


Text.propTypes = {
    uuid: PropTypes.string.isRequired,
    params: PropTypes.object,
};


export default TextHandlebars;

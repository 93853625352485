import React, { useEffect, useState } from 'react';
import classes from './TextareaInput.module.scss';
import ErrorInput from "../../../modules/generic/components/Inputs/ErrorInput";
import TooltipInput from "../../generic/Tooltips/TooltipIput/TooltipInput";
import useTextLang from "../../../hooks/custom/useTextLang";
import classnames from 'classnames';

const TextareaInput = ({ placeholder = "", value = '', trim, onChange, rows = 4, cols = 47, tooltip, error, errorMessage, name, className = null, ...props }) => {
  
  const placeholderTranslate = useTextLang(placeholder);
  const [stateValue, setStateValue] = useState(value);

  const handleChange = (newValue) => {
    setStateValue(newValue)
    onChange(newValue);
  };

  useEffect(() => {
    if (stateValue !== value) {
      setStateValue(value)
    }
  }, [value])

  return (
    <div className={classes.TextareaInput} >
      <TooltipInput label={tooltip}>
        <textarea
          onChange={(e) => {
            if (trim !== false) {
              e.target.value = e.target.value.trimStart();
            }
            handleChange(e.target.value);
          }}
          rows={rows}
          cols={cols}
          style={{ resize: 'none' }}
          autoComplete={`autoComplete-input-${name}`}
          name={name}
          placeholder={placeholderTranslate}
          className={classnames({
            [classes.borderErrorInput]: errorMessage
          }, classes.textarea, className)}
          value={stateValue}
          {...props}
        >
          {stateValue}
        </textarea>
      </TooltipInput>
      <ErrorInput message={errorMessage} component={error} />
    </div>
  )
}


export default TextareaInput
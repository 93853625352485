
import { useEffect } from 'react';
import useQuery from "../../../hooks/custom/useQuery";

const query = `query getProduct($productCode: String!,$relatedItemId: ID) {
  getProduct(productCode:$productCode,relatedItemId: $relatedItemId){
  code
  bankInformation {
    swift_bic
    accountNumber
    accountCurrency
    nameOfAccountHolder
    address
    IBAN
    key
  }
}
  

}`;

const useGetProductBanks = (variables) =>{
    
    const {loading, error, data,refetch} = useQuery(query,variables);
    useEffect(() => {
        
    }, []);

    return  {loading,error,data,refetch} ;
}
export default useGetProductBanks;
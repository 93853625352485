import IS_THERE_EQUITY from '../IsThereEquity/langs'
import SELECT_COMPANIES from '../SelectCompanies/langs'
import WARRANT_FORM from '../WarrantsForm/langs'
import IS_THERE_DOCUMENTS from '../IsThereDocuments/langs'
import IS_THERE_DOCUMENTS_CONVERTIBLE from '../IsThereDocumentsConvertible/langs'
import IS_THERE_DOCUMENTS_OPTION from '../IsThereDocumentsOption/langs'
import UPLOAD_DOCUMENT from '../UploadForm/langs'
import SELECT_DOCUMENT from '../SelectDocument/langs'
import CONVERTIBLE_FORM from '../ConvertibleForm/langs'
import SHARE_FORM from '../ShareForm/langs'
import IS_THERE_MORE_EQUITY from '../IsThereMoreEquityForm/langs'
export default {
    IS_THERE_EQUITY:IS_THERE_EQUITY.US,
    SELECT_COMPANIES:SELECT_COMPANIES.US,
    WARRANT_FORM:WARRANT_FORM.US,
    IS_THERE_DOCUMENTS:IS_THERE_DOCUMENTS.US,
    IS_THERE_DOCUMENTS_CONVERTIBLE:IS_THERE_DOCUMENTS_CONVERTIBLE.US,
    IS_THERE_DOCUMENTS_OPTION:IS_THERE_DOCUMENTS_OPTION.US,
    UPLOAD_DOCUMENT:UPLOAD_DOCUMENT.US,
    SELECT_DOCUMENT:SELECT_DOCUMENT.US,
    CONVERTIBLE_FORM:CONVERTIBLE_FORM.US,
    SHARE_FORM:SHARE_FORM.US,
    IS_THERE_MORE_EQUITY:IS_THERE_MORE_EQUITY.US,
}
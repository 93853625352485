

import React from 'react'
import * as Yup from 'yup'
import Form from '../../../../components/forms/Form/Form'
import useTranslate from '../../../generic/hooks/useTranslate'
import TextInput from '../../../../components/inputs/Text/TextInput'
import ControllerInput from '../../../generic/components/Inputs/ReactHookForm/ControllerInput'
import TemplateLabel from '../../../generic/templates/Label'
import SubmitButton from '../../../generic/components/Buttons/SubmitButton'
import FormProps from '../../../generic/forms/interfaces/FormProps';
import TemplateFormDefault from "../../../generic/templates/Modal";
import cls from './Form.module.scss';
import useGetGroupCompanies from '../../graphql/groupCompany/query/useGetGroupCompanies'
import CheckboxInput from '../../../generic/components/Inputs/Checkbox'
import { useSelector } from 'react-redux'
import _ from 'lodash';
import CheckboxButtonSimple from '../../../generic/components/Inputs/CheckboxButtonSimple'
import useSession from '../../../session/hooks/useSession'

const schema = Yup.object().shape({
    mode: Yup.string().required('This field is required').nullable()
})

const FormSelectCompany: React.FC<FormProps> = ({
    initialValues: defaultValues = { company: [] },
    onCompleteSubmit,
    Template = TemplateFormDefault,
    buttons = {},
    buttonSubmitProps,
    propsTemplate }) => {


    const { translate } = useTranslate();

    const handleSubmit = async values => {
        onCompleteSubmit && await onCompleteSubmit(values);
    }


    const { startupId } = useSession();



    const { data } = useGetGroupCompanies({ variables: { startupId } })


    const options = React.useMemo(() => {
        return [{ label: "Maybe later", value: 'CANCEL' },
        { label: "Scale up now", value: 'SCALE_SUSCRIPTION_PAYMENT' }]
    }, [])




    return (
        <Form
            defaultValues={defaultValues}
            schema={schema}
            onSubmit={handleSubmit}
            onlySendDirty={false}
            optionsForm={{ mode: "onChange" }}
        >

            <Template buttons={{ ...buttons, submit: <SubmitButton  {...buttonSubmitProps}>Next</SubmitButton> }} props={propsTemplate}>


                <ControllerInput
                    render={CheckboxButtonSimple}
                    options={options}
                    name='mode'
                    defaultlabel={''}
                    size={"md"}
                    className={'pt-5'}

                />

            </Template>

        </Form>
    )

}

export default FormSelectCompany




import * as _ from 'lodash';
import React, { Component } from "react";
import { connect } from "react-redux";
import { clearMessageChat, restartFlow, setInitialFlow } from '../../../store/actions/chatbot';
import { goToPage, goToPrevPage } from '../../../store/actions/page';
import Chat from "../../components/Chat/Chat";
import Guide from "../../components/Chat/Guide";
import { initializeFlow, nextQuestion, prevQuestion } from "../../graphql/mutations/chat";
import { Constants } from "../../utils";
import { stripTypenames } from "../../utils/graphql-util";
import ServerConnect from "../../utils/ServerConnect";
import classes from "./ChatContainer.module.scss";






class ChatContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            finished: false,
            activeStep: {},
            steps: [],
            activeQuestion: {},
            loading: true,
            data: {},
            shareClass: {},
            stakeholders: [],
            groupCompanies: [],
            countryList: [],
            users: [],
            showChat: true,
            draftId: ""
        }
    }

    async componentWillMount() {

        const { initialFlowName, params } = this.props;
        let   { initialValueStart: data = {} } = this.props;
        const {matterId, startupId} = params;

        const kind  = (matterId) ? "MATTER_KIND" : "STARTUP_KIND";
        const id = matterId || this.props.session.startup.id;


        // HotFix bad request, data = matterId 
        // the component is sending a string instead of an object
        // https://dev.azure.com/claraTechnologies/Clara%20Application/_workitems/edit/5895
        if(kind ===  "MATTER_KIND" &&  typeof data !== 'object' && matterId === data){
            data = {}
         }

        //  initially we are calling to initializeFlow
        //  then we call to nextQuestion with a draftFlowId
        await this.initializeFlow(kind, id, initialFlowName, data );

    }

    orderSteps(steps) {
        return _.orderBy(steps, ['order'], ['asc']);
    }

    initializeFlow = async (kind, id, flowName, data = {}) => {

        try {

            const initialData = {kind, id, data,fromScratch:this.props.chatBot.restartFlow};
            const result = await ServerConnect.graphQlMutation(initializeFlow, {initialData, flowName}, {cacheDisabled: true})
            this.props.restartFlow(false);
            const {bullets, updateBullets, draftId} = result.initializeFlow;

            this.setState(prevState => {

                    const steps = updateBullets ? this.orderSteps(bullets) : prevState.steps;

                    return {
                        steps,
                        activeStep: this.getActiveStep(steps, result.initializeFlow),
                        activeQuestion: result.initializeFlow,
                        loading: false,
                        draftId
                    };
                }
            );

            return
        } catch (err) {
            return err;
        }

    }

    nextQuestion = async (state, event, value, key) => {

        const {draftId} = this.state;
        const variables = {
            draftId,
            response: {
                state, answer: {
                    event,
                    data: {
                        key,
                        value
                    }
                }
            }
        };

        return new Promise((resolve, reject) => {
            return ServerConnect.graphQlMutation(nextQuestion, variables, { cacheDisabled: true })
                .then(result => {

                    const {bullets, updateBullets, draftId} = result.nextQuestion;
                    

                    this.setState(prevState => {
                            const steps = updateBullets ? this.orderSteps(bullets) : prevState.steps;
                            const prevActiveStep = prevState.activeStep;
                            return {
                                steps,
                                activeStep: this.getActiveStep(steps, result.nextQuestion) || prevActiveStep,
                                activeQuestion: result.nextQuestion,
                                loading: false,
                                draftId
                            };
                        }
                    );

                    resolve(result.nextQuestion);
                }).catch(err => {
                    reject(err);
                });
        })
    };

    getActiveStep(steps, activeQuestion) {
        const bot_message = _.get(activeQuestion, "bot_message", {});
        return steps.filter(step => step.code === bot_message.bulletCode)[0] || {};
    }
    prevQuestion = (prevAnswer) => {

        const {state, ...answer} = prevAnswer;

        ;

        const variables = {
            draftId: _.get(this.state, "activeQuestion.draftId"),
            response: {
                state,
                answer: stripTypenames(answer)
            }
        };

        return new Promise((resolve, reject) => {
            return ServerConnect.graphQlMutation(prevQuestion, variables, { cacheDisabled: true })
                .then(result => {
                    resolve(result.prevQuestion);
                }).catch(err => {
                    reject(err);
                });
        })
    };
    submitPrevQuestion = (previousAnswer) => {
        this.setState({
            loading: true
        });

        this.prevQuestion(previousAnswer)
            .then(activeQuestion => {

                this.setState((prevState) => {
                    const prevActiveStep = prevState.activeStep;
                    return {
                        activeStep: this.getActiveStep(this.state.steps, activeQuestion) || prevActiveStep,
                        activeQuestion,
                        finished: this.isLastQuestion(activeQuestion),
                        loading: false,
                    }
                })
            });
    };

    submitLastStep = () => {

        this.setState({
            loading: false
        });


        this.props.actionAfterFinished();


    }

    submitQuestion = (state, event, value, key, cb) => {

        let newValue = undefined;

        if (key === 'equityOwner' ||
            key === 'investor' ||
            key === 'stakeholder') {
            const st = this.state.stakeholders.filter(st => st.id === value.id);
            newValue = st[0];
        }

       this.setState(prevState => ({
            data: {
                ...prevState.data,
                [key]: newValue ? newValue : value,
            },
            loading: true
        }));
        const {initialEvent} = this.props;
        // If the previous question was the final one
        if (this.isLastQuestion(this.state.activeQuestion)) {

            this.submitLastStep()

        } else {

            this.nextQuestion(state, event, value, key)
                .then(activeQuestion => {
                    if (this.isCloseStep(activeQuestion)) {
                        this.setState({
                            loading: false,
                            showChat: false,
                        })
                        
                        this.props.actionAfterFinished();

                    } else {
                        this.setState((prevState) => {
                            const prevActiveStep = prevState.activeStep;
                            return {
                                activeStep: this.getActiveStep(this.state.steps, activeQuestion) || prevActiveStep,
                                activeQuestion,
                                finished: this.isLastQuestion(activeQuestion),
                                loading: false,
                                showChat: true
                            };
                        });
                        if (cb) { cb() };
                    }

                });
        }
    };
    isCloseStep(activeQuestion) {
        return _.get(activeQuestion, "bot_message.type") === 'SPECIFIC_STEP_COMPONENT'
            && _.get(activeQuestion, "bot_message.inputConfig.specificStepComponentName") === "LAST_STEP"
    }

    isLastQuestion(activeQuestion) {
        const resp = activeQuestion &&
            activeQuestion.bot_message && (activeQuestion.bot_message.type === 'STOP_FLOW') ;
        return resp;
    }


    isLastQuestionBullet(activeQuestion) {
        const resp = activeQuestion && activeQuestion.bot_message
            && (activeQuestion.bot_message.type === 'STOP_FLOW' ||
                (activeQuestion.bot_message.type === 'SPECIFIC_STEP_COMPONENT'
                    && activeQuestion.bot_message.inputConfig.specificStepComponentName === 'SUBMIT_LAST_STEP')
            );
        return resp;
    }

    logout = () => {
        this.props.goToPage(Constants.PAGES.logout);
    };

    goBack = (step) => {
        //alert('Go back to: ' + JSON.stringify(step));
        /*
          // TODO: Define this with BE
          cons state = '';
          const event = '';
          const value = '';
          const key = '';
          this.submitQuestion(state, event, value, key);
        */
    };

    setGroupCompanies = (gcs) => {
        this.setState({
            groupCompanies: gcs
        })
    }

    setStakeholders = (sts) => {
        this.setState({
            stakeholders: sts
        })
    }

    setUsers = (usrs) => {
        this.setState({
            users: usrs
        })
    }

    setShareClass = (sc) => {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                shareClass: sc
            }
        }));
    }

    render() {
        const { showChat , activeQuestion, steps, activeStep, data } = this.state;
        const {session,loading} = this.props;
        const {startup} = session;
        return (
            <div className={classes.ContainerGeneralChat}>
                <div className={classes.ChatContainer}>
                    <Guide
                        steps={steps}
                        activeStep={activeStep}
                        finished={this.isLastQuestionBullet(activeQuestion)}
                        activeQuestion={activeQuestion}
                        goBack={this.goBack}
                        submitPrevQuestion = {this.submitPrevQuestion}
                        showChat={showChat}
                        loading={loading}
                    />
                    {showChat && (
                        <Chat
                            onCloseChat={this.props.onCloseChat}
                            data={{...data, activeQuestion}}
                            steps={steps}
                            submitQuestion={this.submitQuestion}
                            submitPrevQuestion = {this.submitPrevQuestion}
                            submitLastStep = {this.submitLastStep}
                            finished={this.isLastQuestion(activeQuestion)}
                            startupName={data.startupName}
                            activeQuestion={activeQuestion}
                            refresh={() =>
                                this.setState(prevState => ({ c: (prevState.c += 1) }))
                            }
                            loading={this.state.loading}
                            startupId={startup ? startup.id : ''}
                            setGroupCompanies={this.setGroupCompanies}
                            setStakeholders={this.setStakeholders}
                            setUsers={this.setUsers}
                            setShareClass={this.setShareClass}
                            initialValueStart={this.props.initialValueFromParams ? _.get(this.props.params, this.props.initialKey) : this.props.initialValueStart}
                            initialKey={this.props.initialKey}
                        />
                    )}
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        chatBot: state.chatBot,
        session: state.session
    };
};
const mapDispatchToProps = (dispatch) => ({
    restartFlow: (value)=>{
        restartFlow(dispatch, value);
    },
 
  
   
    setInitialFlow: () => setInitialFlow(dispatch),
    clearMessageChat: () => clearMessageChat(dispatch),
    goToPage: (page, params) => {
        goToPage(dispatch, page, params)
    },
    goToPrevPage: (reload, defaultPage, params) => {
        goToPrevPage(dispatch, reload, defaultPage, params)
    },

});

export default connect(mapStateToProps, mapDispatchToProps)(ChatContainer);

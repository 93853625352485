import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useRegion, { DOCUMENT_REGIONS } from './useRegion'

const useFilter = () => {
  const [filterText, setFilterText] = useState('');
  const { setRegion, handleChangeRegionMutation } = useRegion()
  const { region } = useParams();
  const clearFilters = () => {
    setFilterText('');
     // setRegion('');
  }

  const handleSelectedRegionFilter = async (selectedValue) => {
    setRegion(selectedValue);
    setFilterText('');
    await handleChangeRegionMutation(selectedValue);
  }

  const handleChangeTextFilter = (value) => {
    setFilterText(value);
  };
  useEffect(() => {
    // TODO: KEYS SHOULD BE TYPED AND MOVED TO A CONSTANT FILE THIS OBJECT
    if (region) {
      const regionToDocumentRegion = {
        unitedStates: DOCUMENT_REGIONS.UNITED_STATES_DOCUMENTS_TYPES,
        international: DOCUMENT_REGIONS.INTERNATIONAL_DOCUMENTS_TYPES,
        y_combinator: DOCUMENT_REGIONS.Y_COMBINATOR_DOCUMENTS_TYPES
      }
      handleSelectedRegionFilter(regionToDocumentRegion[region]);
    }
  }, [region]);

  const changeRegionFilter = async (selectedValue) => {
    setRegion(selectedValue);
    setFilterText('');
    await handleChangeRegionMutation(selectedValue);
  }

  return {
    clearFilters,
    filterText,
    handleChangeTextFilter,
    changeRegionFilter,
  }
}
export default useFilter
import { useMemo } from 'react';
import { assign } from 'xstate';

const useActions = ({ onPreviousMachine, goToChooseJurisdiction }) => {

  return useMemo(() => {

    /** this action is online used to return of chidlren machine to parent machine. If yo need other type of comunication you can add more actions here */
    const previousMachine = assign((context: any = {}, event: any = null) => {
      onPreviousMachine && onPreviousMachine();
      return context;
    });

    const chooseJurisdiction = assign((context: any = {}, event: any = null) => {
      return goToChooseJurisdiction && goToChooseJurisdiction();
    });

    return {
      previousMachine,
      chooseJurisdiction
    }
  }, [onPreviousMachine, goToChooseJurisdiction]);

}

export default useActions;

import { useMemo } from 'react';
import useConfirmRenewalInformation from './graphql/useConfirmRenewalInformation';
import useSession from 'src/modules/session/hooks/useSession';

const useServices = ({ initialValues }) => {
  const { startupId } = useSession();
  const mutation = useConfirmRenewalInformation()
  return useMemo(() => ({

    initialData: async (context, event) => {
      return initialValues;
    },

    SaveStep: async (context, event) => {
      const groupCompanyId = context?.initialData?.groupCompanyId;
      const variables = {
        startupId,
        groupCompanyId,
        renewalData: {
          isRenewalInformationConfirmed: context?.ConfirmForm?.confirmation,
        }, 
      }
      const result = await mutation({variables});
      return result;
    },

  }), [initialValues]);
}

export default useServices;

import useCustomMachine from '../../../../generic/hooks/useCustomMachine'
import React, { FC } from 'react'
import Button from '../../../../generic/components/Buttons/Button'
import { StepProps } from "../../../../generic/components/Wizard/Step"
import TemplateWizard from '../../../../generic/templates/Modal/TemplateWizard'
import useTranslate from '../../../../generic/hooks/useTranslate'
import Form from "../../Forms/FormSelectCompanyShareOptions";
import { get } from 'lodash';
import {clearAssistatText} from "../../../../generic/store/action";
import {useDispatch} from "react-redux";
const Step2: FC<StepProps> = () => {

    const { translate } = useTranslate();
    const dispatch=useDispatch()
    /*Function*/


    const { next, send, state } = useCustomMachine();


    const handleCompleteSubmit = async values => {
        dispatch(clearAssistatText())
        next(values);
    }
    const handleCancel = async values => {
        send("CANCEL");
    }

    return (
        <React.Fragment>
            <Form
                initialValues={get(state, 'context.data.personal_information', {})}
                onCompleteSubmit={handleCompleteSubmit}
                Template={TemplateWizard}
                propsTemplate={{
                    title: translate('MODULES.STAKEHOLDER.SHARE_OPTIONS.SECOND_STEP_A.TITLE'),
                    subTitle: translate('MODULES.STAKEHOLDER.SHARE_OPTIONS.SECOND_STEP_A.SUBTITLE')
                }}
                buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }}>
            </Form>

        </React.Fragment>
    )
}

export default Step2;

import React from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";
const CaptableReport = ({ go, ...props }) => {
  const params = useParams()
  return (
    <MicroFrontend key={`${props.groupCompanyId}`} id={`MF_Captable_${props.groupCompanyId}`} component={"./CaptableView"} mf="capTable" params={{ go, ...props, ...params }} />
  );
};

export default CaptableReport;

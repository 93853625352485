import { get } from 'lodash';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Button from '../../../../../generic/components/Buttons/Button';
import { StepProps } from "../../../../../generic/components/Wizard/Step";
import useCustomMachine from '../../../../../generic/hooks/useCustomMachine';
import { addAssistantText, clearAssistatText } from '../../../../../generic/store/action';
import TemplateWizard from '../../../../../generic/templates/Modal/TemplateWizard';
import useSession from '../../../../../session/hooks/useSession';
import Form from "../../components/Forms/FormVisa";
import useAddDocumentFormation from "../../../../../../hooks/services/modules/individualStakeholder/useAddDocumentFormation";
import UAEEntryStamp from '../../public/UAEEntryStamp.svg';

const Step2: FC<StepProps> = () => {
  const { next, send, state } = useCustomMachine();
  const [updateDocument] = useAddDocumentFormation();
  const { id: _id } = useParams<any>()
  const id = state?.context?.data.stakeholderId ?? _id;
  const { startupId } = useSession();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(addAssistantText(`A copy of a UAE Entry Stamp is required for all ADGM Authourised Signatories that are neither GCC nationals nor GCC residents`));
    return () => {
      dispatch(clearAssistatText());
    }
  }, [])

  const handleCompleteSubmit = async values => {
    if (!state.context.data.select_current.haveOldPassposrt) {
      const variables = {
        stakeholderId: id,
        startupId,
        identityDocumentData: { ...values, "type": "UAE_ENTRY_STAMP_DOCUMENT", option: false }
      }
      await updateDocument({ variables });
    }
    next(values);
  }

  const handleCancel = async values => {
    send("CANCEL");
  }

  const handlePrevious = async values => {
    send("PREVIOUS");
  }

  return (
    <React.Fragment>
      <Form
        initialValues={get(state, 'context.data.uploadDocument', {})}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: 'Add their UAE entry stamp',
          subTitle: 'UAE entry stamp'
        }}
        image={<img src={UAEEntryStamp} alt=""/>}
        buttons={{ previous: <Button onClick={handlePrevious} >Previous</Button>, cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }}>
      </Form>
    </React.Fragment>
  )
}

export default Step2;

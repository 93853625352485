import useQuery from "../../../custom/useQuery";
import _ from "lodash";
import { RoleFields } from "../../../../modules/stakeholder/graphql/typePolicies";
import useLazyQuery from "src/hooks/custom/useLazyQuery";

export const GET_STAKEHOLDERS = `
  ${RoleFields}
  query stakeholderList($startupId: ID!, $groupCompanyId: ID, $filters: StakeholderFilterInput){
    stakeholderList(startupId:$startupId, groupCompanyId: $groupCompanyId, filters: $filters){
      id
      isFounder
      avatar
      fullName
      roles { ...RoleFields }
      isAnEntity
      statusOnMatters {
        isLocked
        isRenewal
        onFields
      },
    }
  }
`;

const useGetStakeholders = ({ variables, ...props }, config) => {
  const { loading, error, data, refetch, ...propsDes } = useQuery(
    GET_STAKEHOLDERS,
    { ...variables, ...(config?.isADGM ? { filters: { forADGM: true } } : {}) },
    config,
    props
  );
  const { manualQuery } = useLazyQuery(
    GET_STAKEHOLDERS,
    { ...variables, ...(config?.isADGM ? { filters: { forADGM: true } } : {}) },
    config,
    props
  );
  return {
    manualQuery,
    loading,
    error,
    data: _.get(data, "stakeholderList", []),
    refetch,
    ...propsDes,
  };
};

export default useGetStakeholders;

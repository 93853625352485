import React, { FC, useState, useEffect } from 'react'
import Wizard from '../../../generic/components/Wizard'
import ModalAssistant, { ModalAssistantProps } from '../../../generic/components/Modal/ModalAssistant'
import MachineIpAndGenerate from './machine'
import useModal from "../../../generic/hooks/useModal";

interface ModalAddressAndCurrencyProps extends ModalAssistantProps {
    onClose(): void,
    paramsMutation: any
}

const ModalConvertibleDoc: FC<ModalAddressAndCurrencyProps> = ({ children, open: openParam = false, paramsMutation, onClose, ...props }: ModalAddressAndCurrencyProps) => {


    const [open, setOpen] = useState(openParam);

    const {closeModal} =useModal();

    useEffect(() => {
        setOpen(openParam);
    }, [openParam])
    const handleClose = (values) => {
        closeModal();
        setOpen(false);
    }



    useEffect(() => {
    }, []);


    return (
        <ModalAssistant open={open} onClose={handleClose}>
            <Wizard machine={MachineIpAndGenerate} initialValues={{paramsMutation}} onComplete={handleClose} />
        </ModalAssistant>

    )
}

export default ModalConvertibleDoc;





import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import Form from "src/components/forms/Form/Form";
import CalendarInputDate from "src/components/inputs/Calendars/CalendarInputDate";
import CountrySelector from "src/components/inputs/Select/CountrySelector/CountrySelector";
import TextInput from "src/components/inputs/Text/TextInput";
import Button from "src/modules/generic/components/Buttons/Button";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import { ICONS } from "src/modules/generic/components/Inputs/FileUpload/NoFilesUploaded";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import { addAssistantText, clearAssistatText } from "src/modules/generic/store/action";
import TemplateWizardPreview from "src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardPreview";
import { Constants } from "src/v1/utils";
import * as Yup from "yup";
import classes from "./classes.module.scss";

const filesSchema = {
  image_pdf: Yup.object().shape({
    files: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string()
            .matches(/(.jpg)|(.png)|(.jpeg)$/, "Invalid file type")
            .transform((original) => {
              return original.replace(/(\.[^.]+)$/, (match) => match.toLowerCase());
            }),
        })
      )
      .min(1),
  }),
  all: Yup.object().shape({
    files: Yup.array().of(
      Yup.object().shape({
        name: Yup.string(),
      })
    ).min(1),
  }),
};

const schema = Yup.object().shape({
  nationality: Yup.string().nullable().required("This Field Required"),
  number: Yup.string().required("This Field Required").nullable(),
  expiryDate: Yup.string()
    .test({
      test: (expiryDate) => {
        if (!expiryDate) {
          return true;
        }
        return moment(expiryDate, "YYYY-MM-DD").isAfter(moment(), "day");
      },
      message: "Expiration date must be greater than today",
    })
    .nullable()
    .required("This Field Required"),
});

function ConfigurePassport() {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const { next, cancel, prev, context } = useMachine(); // Extract 'prev' from context
  const country = context?.initialData?.country?.name;

  const initialValues = useMemo(() => {    
    return context?.UploadPassport ?? {};
  }, [context]);

  useEffect(() => {
    dispatch(clearAssistatText());
    dispatch(
      addAssistantText(
        `Upload a double page scan of the passport clearly showing their photo and passport number.`
      )
    );

    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const handleCancel = () => {
    cancel();
  };

  const handleSubmit = (values) => {
    next(values);
  };

  const fileUploadProps = {
    accept: "image_pdf",
    icons: [ICONS.JPG, ICONS.PNG],
    name: "files",
    limit: null,
    defaultValue: [],
  };

  const getSchema = () => {
    const def = fileUploadProps?.accept
      ? filesSchema[fileUploadProps?.accept]
      : Yup.object().shape({});
    return def.concat(schema);
  };

  const handlePrev = () => {
    prev(); // Define handlePrev to call prev
  };

  return (
    <Form
      defaultValues={initialValues}
      schema={getSchema()}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
      onlySendDirty={false}
    >
      <TemplateWizardPreview
        buttons={{
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("BUTTON_CANCEL")}
            </Button>
          ),
          previous: ( // Add the previous button
            <Button onClick={handlePrev} variant="secondary">
              {translate("BUTTON_PREVIOUS")}
            </Button>
          ),
          submit: <SubmitButton>Add</SubmitButton>,
        }}
        props={{
          title: "Add passport details",
          subTitle: `Passport for ${country} nationality`,
        }}
      >
        <TemplateLabel label="Nationality" className={classes.TemplateLabelMin} isRequired>
          <ControllerInput
            render={CountrySelector}
            placeholder="Select from the list"
            name="nationality"
            disabled={true}
            defaultValue={context?.initialData?.country}
            expandDouble
          />
        </TemplateLabel>
        <TemplateLabel label="Passport number" className={classes.TemplateLabelMin} isRequired>
          <ControllerInput
            placeholder="Passport number"
            render={TextInput}
            name="number"
            defaultValue={null}
          />
        </TemplateLabel>
        <TemplateLabel label="Passport expiry" className={classes.TemplateLabelMin} isRequired>
          <ControllerInput
            render={CalendarInputDate}
            className={classes.CalendarInputDate}
            name="expiryDate"
            placeholder="dd/mm/yyyy"
            defaultValue={null}
          />
        </TemplateLabel>
      </TemplateWizardPreview>
    </Form>
  );
}

export default ConfigurePassport;

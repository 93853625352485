import { get } from 'lodash'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import Button from '../../../../../generic/components/Buttons/Button'
import { StepProps } from "../../../../../generic/components/Wizard/Step"
import useCustomMachine from '../../../../../generic/hooks/useCustomMachine'
import useTranslate from '../../../../../generic/hooks/useTranslate'
import { addAssistantText, clearAssistatText } from '../../../../../generic/store/action'
import TemplateWizard from '../../../../../generic/templates/Modal/TemplateWizard'
import useSession from '../../../../../session/hooks/useSession'
import Form from "../../components/Forms/FormVisa";
import useAddDocumentFormation from "../../../../../../hooks/services/modules/individualStakeholder/useAddDocumentFormation";
import AddressAndFamilyContact from '../../public/Address_&_family_contact.svg'
const Step2: FC<StepProps> = () => {

    const { translate } = useTranslate();

    /*Function*/


    const { next, send, state } = useCustomMachine();

    const [updateDocument] = useAddDocumentFormation();


    const { id: _id } = useParams<any>()
    const id = state?.context?.data.stakeholderId ?? _id;

    const { startupId } = useSession();

    const dispatch = useDispatch();
    React.useEffect(() => {

        dispatch(addAssistantText(`The address and emergency contact page is inside the passport.`));
        return () => {
            dispatch(clearAssistatText());
        }
    }, [])


    const handleCompleteSubmit = async values => {
        if (!get(state, 'context.data.select_current.haveOldPassposrt')) {

            const variables = {
                stakeholderId: id,
                startupId,
                identityDocumentData: { ...values, "type": "ADDRESS_FAMILY_CONTACT_PAGE_DOCUMENT" }
            }


            await updateDocument({ variables });
        }


        next(values);
    }
    const handleCancel = async values => {
        send("CANCEL");
    }

    return (
        <React.Fragment>
            <Form
                initialValues={get(state, 'context.data.uploadDocument', {})}

                onCompleteSubmit={handleCompleteSubmit}
                Template={TemplateWizard}
                propsTemplate={{
                    title: 'Add scan from passport',
                    subTitle: 'Address and family contact page'
                }}
                image={<img src={AddressAndFamilyContact} />}
                buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }}>
            </Form>

        </React.Fragment>
    )
}

export default Step2;

import React, {useState} from "react";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import classes from "./tooltip.module.scss";
import Avatar from '../../../modules/generic/components/Avatars/AvatarLabel';
import {get} from 'lodash';

const  ToolTipTable = (props)=> {
  const { direction="top", type, data ,index,timeShow=500, className="" ,limit=0 } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [timer, setTimer] = useState(null);
  const time= timeShow;
  

  let show = type === "text" ? limit < get(data, 'length', 0) : true;

  function handlePopoverOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handlePopoverClose() {
    setAnchorEl(null);
  }

  function handleLeaveMouse(event) {
    
    setTimer(setTimeout(() => handlePopoverClose(), time ));
  }

  const handleType = () => {
    
    switch (type) {
      case "parties":
        return <p className={classes.containerNames}> + {data.length} more</p>;

      case "avatar":
        return <Avatar  avatar={data.imageUrl} showLabel={false} name={data.name}/>;

      case "text":
        return <p className={className}>{data ? data : " "} </p>;

      default:
        return null;
    }
  };

  const handleContent = () => {
  
    return type === "parties" ? (
      data.map((t, index) => {
        return <p key={index} className={classes.nameHover}> {t.name} </p>;
      })
    ) : (
      <span> { data.name || data } </span>
    );
  };

  const open = Boolean(anchorEl);
  const id = open ? "ToolTipTable" : undefined;

  return (
    <div className={classes.containerToolTip} >
      <div className={classes.containerContend}
         aria-describedby={id}
        variant="contained"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handleLeaveMouse}
        className={`${classes['child'+index]}`}
      >
        {handleType(data)}
      </div>
      <Popper style={{zIndex:1000}}
        placement={direction}
        disablePortal={false}
        modifiers={{
          flip: {
            enabled: false
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: "undefined"
          }
        }}
        open={open}
        anchorEl={anchorEl}
        id={id}
      >
        {show && <Paper 
          className={classes[direction]}
          onMouseEnter={() =>
            { clearTimeout(timer)
            }}
          onMouseLeave={handlePopoverClose}
        >
          {handleContent()}
        </Paper>}
      </Popper>
    </div>
  );
}


export default ToolTipTable

import useMutation from "../../../../../hooks/custom/useMutation";
import useSession from "../../../../session/hooks/useSession";
export const UPDATE_IDENTITY_DOCUMENT = `
mutation updateIdentityDocumentPageStakeholder(
    $startupId: ID
     $stakeholderId: ID
     $pageId: ID
     $identityDocumentData: IdentityDocumentPageInput) {
    updateIdentityDocumentPageStakeholder(
     startupId: $startupId
     stakeholderId:$stakeholderId
     pageId:$pageId 
     identityDocumentData:$identityDocumentData 
      ) {
       id
      number
      nationality
      expiryDate
      current
      documents{
          name
      id
      file{
      id 
      name
      type
      }
      }
      }
}
`
const useUpdateIdentityDocumentPageStakeholder = (config, props = {}) => {
    const [mutation, loading] = useMutation(UPDATE_IDENTITY_DOCUMENT, config, {  ...props })
    return { mutation, loading }
}

export default useUpdateIdentityDocumentPageStakeholder
import _, { get } from 'lodash'
import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Button from '../../../../generic/components/Buttons/Button'
import { StepProps } from "../../../../generic/components/Wizard/Step"
import useCustomMachine from '../../../../generic/hooks/useCustomMachine'
import useTranslate from '../../../../generic/hooks/useTranslate'
import { addAssistantText, clearAssistatText } from "../../../../generic/store/action"
import TemplateWizard from '../../../../generic/templates/Modal/TemplateWizard'
import useSession from '../../../../session/hooks/useSession'
import useUpsertEquity from '../../../graphql/equity/mutation/useUpsertEquity'
import Form from "../../Forms/FormCreatedGrantAgreement"
import moment from "moment";

const Step2: FC<StepProps> = () => {

    const { translate } = useTranslate();
    const dispatch = useDispatch()
    /*Function*/
    const { next, send, state } = useCustomMachine();

    const { mutation: upsertEquityOption } = useUpsertEquity();


    const { startupId } = useSession();



    const { numbersOfIteration, companies, data } = state.context;

    const groupCompanyId = React.useMemo(() => {
        return get(companies, `[${numbersOfIteration}].id`);
    }, []);

    const parseValuesToEquityData = (_value, state) => {
        const { details_share_options } = state.context.data;
        const documents = { uploadedFiles: [], documentsUploaded: [] };
        return { ...details_share_options,issuedDate:moment(_.get(details_share_options,'issuedDate')).format('YYYY-MM-DD'), documents };
    }
    useEffect(() => {
        dispatch(addAssistantText(translate('MODULES.STAKEHOLDER.SHARE_OPTIONS.FOURTH_STEP.TOOLTIP'), 'text'))
        return () => {
            dispatch(clearAssistatText());
        }
    }, [])
    const handleCompleteSubmit = async values => {

        if (values.mode === "UPLOAD_LATER") {
            const variables = {
                startupId,
                groupCompanyId,
                owner: { type: 'stakeholder', id: get(state, "context.paramsData.stakeholderId") },
                shareType: 'OPTION',
                isThereSIP: false,
                equityData: parseValuesToEquityData(values, state)
            };
            await upsertEquityOption({ variables });

        }
        dispatch(clearAssistatText())
        next(values);
    }



    const handleCancel = async values => {
        send("CANCEL");
    }

    return (
        <React.Fragment>
            <Form
                initialValues={get(state, 'context.data.personal_information', {})}
                onCompleteSubmit={handleCompleteSubmit}
                Template={TemplateWizard}
                propsTemplate={{
                    title: translate('MODULES.STAKEHOLDER.SHARE_OPTIONS.FOURTH_STEP.TITLE'),
                    subTitle: translate('MODULES.STAKEHOLDER.SHARE_OPTIONS.FOURTH_STEP.SUBTITLE')
                }}
                buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }}>
            </Form>

        </React.Fragment>
    )
}

export default Step2;

import _ from 'lodash';

export const isInvalidToken = (error) => {
    try {
        const { networkError: { result: { errors = [] } = {} } = {} } = error;
        return _.some(errors, err => err.statusCode === "401");
    } catch (e) {
        console.log(e);
        return false
    }
}

export const isForbidden = (error) => {
    try {
        const { networkError: { result: { errors } } } = error;
        return _.some(errors, err => err.statusCode === "500");
    } catch (e) {
        console.log(e);
        return false
    }
}

export const isBadRequest= (error) => {
    try {
        return error.statusCode === "400" || error.statusCode === 400;
    } catch (e) {
        console.log(e);
        return false
    }
}

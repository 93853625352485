import useLazyQuery from 'src/hooks/custom/useLazyQuery';

const VERIFY_ACCELERATOR_CODE = `
query verifyAcceleratorCode($code: String!){
  verifyAcceleratorCode(code: $code){
    code
    name
    questionnaire
    startDate
    endDate
    shareEmail
  }
}
`;

export const useVerifyAcceleratorCode = (variables, config) => {
  const { manualQuery, loading, error, data, refetch } = useLazyQuery(VERIFY_ACCELERATOR_CODE, variables, {showErrorNotification:true,errorMessage:'Verification failed. Check the code and try again.'});
  return { loading, error, data, refetch, manualQuery };
};

export default useVerifyAcceleratorCode;

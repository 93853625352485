import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, Formik, FieldArray } from "formik";
import { Constants } from '../../../../utils/constants'
import { goToPage, goToPrevPage } from '../../../../../store/actions/page'
import { TextAreaInput, TextInput, AddInputFull } from '../../../index';
import classes from '../EditForm.module.scss';
import Checkbox from "../../../../containers/Forms/Inputs/Checkbox";
import PhotoInput from "../../../../containers/Forms/Inputs/PhotoInput";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import FormikHelper from "../../../../utils/formikHelper";
import * as Yup from "yup";
import * as _ from "lodash";
import { getCountries } from "../../../../utils/graphql-util";
import ClaraCountrySelector from "../../../Form/ClaraCountrySelector/ClaraCountrySelector";

import CurrencySelector from '../../../../../components/inputs/Select/CurrencySelector/CurrencySelector';
import { element } from "prop-types";

const DetailSchema = Yup.object().shape({
    name: Yup.string({ message: 'Only Letters allowed', excludeEmptyString: true }).required("Name is required")
});

class Details extends Component {
    constructor(props) {
        super(props);
        this.state = {
            incorporation_date: null,
            jurisdictions: [],
            countryList: []
        };
    }

    async componentWillMount() {
        let { data } = this.props;
        const countryList = await getCountries();

        if (data) {
            this.setState({
                countryList,
                incorporation_date: this.getDateForPickerFromData(data.incorporation_date)
            })
        }

    }

    getDateForPickerFromData(date) {
        if (date === undefined || date === null) return null;
        return moment(date).toDate();
    }

    handleChange(e, field, form, agreementStatus) {

        const date = e === null ? null : moment(e).format("YYYY-MM-DD");
        FormikHelper.setValueInTheCorrectPosition(field.name, form, date);
        this.setState({
            [field.name]: e
        });
    }

    changeMode = () => {
        this.props.goToPage(Constants.PAGES.viewGroupCompany, this.props.data);
    };

    getJurisdictions = (value) => {

        if (value) {
            const jurisdictions = _.flatten(this.state.countryList
                .filter(country => (country.id === value))
                .map(country => (country.jurisdictions)))
                .map(jur => ({ id: jur.name.toUpperCase(), label: jur.name }))

            this.setState({ jurisdictions })

        }
    };

    onSubmit = async (values, bag) => {
    	await this.props.updateForm(values);
        this.changeMode();
    };

    isDisabled(errors, touched) {
        return Object.keys(errors).length > 0;
    }

    render() {
        const { data, tooltips = {} } = this.props;
        const { jurisdictions, countryList } = this.state;

        return (
            <div className={classes.ContainerEdit}>
                <Formik
                    initialValues={data}
                    enableReinitialize={false}
                    onSubmit={this.onSubmit}
                    validationSchema={DetailSchema}
                    render={({ values, handleSubmit, isSubmitting, errors, touched, handleReset, setFieldValue }) => {

                        return (
                            <form onSubmit={handleSubmit} className={classes.Form}>
                                <div className={`${classes.ContainerRow} ${classes.ContainerRowAvatar}`}>
                                    <Field name='avatar'>
                                        {({ field, form }) => (
                                            <PhotoInput
                                                label='Company Logo: '
                                                field={field}
                                                form={form}
                                                tooltip={tooltips['Company Logo']}
                                            />
                                        )}
                                    </Field>
                                </div>
                                <div className={classes.ContainerRow}>
                                    <Field name='name'>
                                        {({ field, form }) => (
                                            <TextInput
                                                required={true}
                                                type='text'
                                                label='Company Name: '
                                                field={field}
                                                form={form}
                                                placeholder={"Type here..."}
                                                tooltip={tooltips['Company Name']}
                                            />
                                        )}
                                    </Field>

                                </div>
                                <div className={`${classes.ContainerRow}`}>
                                    <ClaraCountrySelector
                                        jurisdictionsEnabled={true}
                                        label={"Country"}
                                        //returnCountryAsString={true}
                                        countryField={"country"}
                                        setFieldValue={setFieldValue}
                                        initialValues={{ jurisdiction: _.get(values, "jurisdiction") }}
                                        tooltips={{
                                            country: tooltips['Country'],
                                            jurisdiction: tooltips['Jurisdiction'],
                                            other: tooltips['Other Jurisdiction']
                                        }}
                                    />

                                </div>
                                <div className={classes.ContainerRow}>
                                    <Field name='type'>
                                        {({ field, form }) => (
                                            <TextInput
                                                type='text'
                                                label='Company Type:'
                                                field={field}
                                                form={form}
                                                placeholder={"Type here..."}
                                                tooltip={tooltips['Company Type']}
                                            />
                                        )}
                                    </Field>
                                </div>
                                <div className={classes.ContainerRow}>
                                    <Field name='registeredNumber'>
                                        {({ field, form }) => (
                                            <TextInput
                                                type='Registered Number:'
                                                label='Company Number:'
                                                field={field}
                                                form={form}
                                                placeholder={"Enter number here..."}
                                                tooltip={tooltips['Company Number']}
                                            />
                                        )}
                                    </Field>
                                </div>
                                <hr className={classes.Separator} />
                                <div className={`${classes.ContainerRow} ${classes.ContainerSwitchRow}`}>
                                    <Field
                                        component={Checkbox}
                                        name={"isHoldingCompany"}
                                        label={"This Group Company is the top level Holding Company for the Startup."}
                                        tooltip={tooltips['This company is the top level holding company for the startup']}
                                    />
                                </div>
                                <hr className={classes.Separator} />

                                <div className={`${classes.ContainerRow}`}>
                                    <ClaraCountrySelector
                                        jurisdictionsEnabled={false}
                                        label={'Registered Address: '}
                                        prefix = {'address.'}
                                        countryField = {'country'}
                                        setFieldValue={setFieldValue}
                                        tooltips={{
                                            country: tooltips['Registered Country']
                                        }}
                                    />
                                </div>





                                <div className={classes.ContainerRow}>
                                    <Field name='address.street'>
                                        {({ field, form }) => (
                                            <TextAreaInput
                                                type='text'
                                                label={' '}
                                                field={field}
                                                form={form}
                                                placeholder={'Address'}
                                                tooltip={tooltips['Registered Address']}
                                            />
                                        )}
                                    </Field>
                                </div>
                                <div className={classes.ContainerRow}>
                                    <Field name='address.city'>
                                        {({ field, form }) => (
                                            <TextInput
                                                type='text'
                                                field={field}
                                                form={form}
                                                label={' '}
                                                placeholder={"City"}
                                                tooltip={tooltips['Registered City']}
                                            />
                                        )}
                                    </Field>
                                </div>
                                <div className={classes.ContainerRow}>
                                    <Field name='address.state'>
                                        {({ field, form }) => (
                                            <TextInput
                                                type='text'
                                                field={field}
                                                form={form}
                                                placeholder={"State / Region"}
                                                label={' '}
                                                tooltip={tooltips['Registered State']}
                                            />
                                        )}
                                    </Field>
                                </div>
                                <div className={classes.ContainerRow}>
                                    <Field name='address.zipCode'>
                                        {({ field, form }) => (
                                            //aca va el checkbox de GC group
                                            <TextInput
                                                type='text'
                                                field={field}
                                                form={form}
                                                label={'    '}
                                                placeholder={"Post Code"}
                                                tooltip={tooltips['Registered Post Code']}
                                            />
                                        )}
                                    </Field>
                                </div>
                                <hr className={classes.Separator} />
                                <div className={`${classes.ContainerRow} ${classes.ContainerSwitchRow}`}>
                                    <Field name='hasSameWorkingAddress'>
                                        {({ field, form }) => (
                                            <Checkbox
                                                label={"The company address is the same as the registered address"}
                                                field={field}
                                                form={form}
                                                onChange={() => form.setFieldValue('hasSameWorkingAddress', values.hasSameWorkingAddress)}
                                            />
                                        )}
                                    </Field>
                                </div>
                                {!values.hasSameWorkingAddress ?
                                    <React.Fragment>
                                        <div className={classes.ContainerRow}>
                                            <ClaraCountrySelector
                                                label={"Company Address"}
                                                countryField={"workingAddress.country"}
                                                setFieldValue={setFieldValue}
                                            />

                                        </div>
                                        <div className={classes.ContainerRow}>
                                            <Field name='workingAddress.street'>
                                                {({ field, form }) => (
                                                    <TextAreaInput
                                                        type='text'
                                                        label={' '}
                                                        field={field}
                                                        form={form} placeholder={"Address"}
                                                        tooltip={tooltips['Recipient Notice Address']}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                        <div className={classes.ContainerRow}>
                                            <Field name='workingAddress.city'>
                                                {({ field, form }) => (
                                                    <TextInput
                                                        type='text'
                                                        field={field}
                                                        form={form}
                                                        placeholder={"City"}
                                                        label={' '}
                                                        tooltip={tooltips['Recipient Notice City']}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                        <div className={classes.ContainerRow}>
                                            <Field name='workingAddress.state'>
                                                {({ field, form }) => (
                                                    <TextInput
                                                        type='text'
                                                        field={field}
                                                        form={form}
                                                        placeholder={"State / Region"}
                                                        label={' '}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                        <div className={classes.ContainerRow}>
                                            <Field name='workingAddress.zipCode'>
                                                {({ field, form }) => (
                                                    <TextInput
                                                        type='text'
                                                        field={field} form={form}
                                                        label={' '}
                                                        placeholder={"Zip Code / Post Code"}
                                                        tooltip={tooltips['Recipient Notice Post Code']}
                                                    />

                                                )}
                                            </Field>
                                        </div>
                                    </React.Fragment>
                                    :
                                    null
                                }
                                <hr className={classes.Separator} />
                                <div className={`${classes.ContainerRow} ${classes.ContainerSwitchRow}`}>
                                    <Field name='useRegisteredAddress'>
                                        {({ field, form }) => (
                                            <Checkbox
                                                label={'The address for the recipient is the same as the registered address'}
                                                field={field}
                                                form={form}
                                                onChange={() => form.setFieldValue('useRegisteredAddress', !values.useRegisteredAddress)}
                                            />
                                        )}
                                    </Field>
                                </div>
                                <div className={classes.ContainerRow}>
                                    <Field name='recipientNotice.fullName'>
                                        {({ field, form }) => (
                                            <TextInput
                                                type='text'
                                                label={'Recipient for Notices: '}
                                                field={field}
                                                form={form}
                                                placeholder={"Type here..."}
                                                tooltip={tooltips['Recipient Notice FullName']}
                                            />
                                        )}
                                    </Field>
                                </div>
                                {!values.useRegisteredAddress ?
                                    <React.Fragment>
                                        <div className={`${classes.ContainerRow}`}>
                                            <Field name='recipientNotice.address.country'>
                                                {({ field, form }) => (
                                                    <ClaraCountrySelector
                                                        jurisdictionsEnabled={false}
                                                        countryField={'recipientNotice.address.country'}
                                                        label={"Recipient's Address: "}
                                                        setFieldValue={setFieldValue}
                                                        tooltips={{
                                                            country: tooltips['Recipient Notice Country']
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                        <div className={classes.ContainerRow}>
                                            <Field name='recipientNotice.address.street'>
                                                {({ field, form }) => (
                                                    <TextAreaInput
                                                        type='text'
                                                        label={' '}
                                                        field={field}
                                                        form={form}
                                                        placeholder={"Address"}
                                                        tooltip={tooltips['Recipient Notice Address']}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                        <div className={classes.ContainerRow}>
                                            <Field name='recipientNotice.address.city'>
                                                {({ field, form }) => (
                                                    <TextInput
                                                        type='text'
                                                        field={field}
                                                        form={form}
                                                        placeholder={"City"}
                                                        label={' '}
                                                        tooltip={tooltips['Recipient Notice City']}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                        <div className={classes.ContainerRow}>
                                            <Field name='recipientNotice.address.state'>
                                                {({ field, form }) => (
                                                    <TextInput
                                                        type='text'
                                                        field={field}
                                                        form={form}
                                                        placeholder={"State / Region"}
                                                        label={' '}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                        <div className={classes.ContainerRow}>
                                            <Field name='recipientNotice.address.zipCode'>
                                                {({ field, form }) => (
                                                    <TextInput
                                                        type='text'
                                                        field={field} form={form}
                                                        label={' '}
                                                        placeholder={"Zip Code / Post Code"}
                                                        tooltip={tooltips['Recipient Notice Post Code']}
                                                    />

                                                )}
                                            </Field>
                                        </div>
                                    </React.Fragment>
                                    :
                                    null
                                }
                                <div className={classes.ContainerRow}>
                                    <Field name='recipientNotice.email'>
                                        {({ field, form }) => (
                                            <TextInput
                                                type='text'
                                                field={field} form={form}
                                                label="Recipient's Email Address: "
                                                placeholder={"Type here..."}
                                                tooltip={tooltips['Recipient Notice Email']}
                                            />
                                        )}
                                    </Field>
                                </div>


                                <div className={`${classes.ContainerRow}`}>
                                    <div className={classes.ClaraInputSelect}>
                                        <Field name={`transactionCurrency`}>
                                            {({ field, form }) => {

                                                return (
                                                    <>
                                                        <label> Transaction Currencies:</label>
                                                        <CurrencySelector
                                                            onChange={(value) => form.setFieldValue(field.name, value)}
                                                            value={field.value}
                                                        />

                                                    </>
                                                )
                                            }}
                                        </Field>
                                    </div>

                                </div>


                                <div className={`${classes.ContainerRow}`}>

                                    <FieldArray
                                        name={`extraCurrencies`}
                                        render={(formikProps) => {
                                            const handleFilterValuesList = (list) => {
                                                return _.filter(list, element => !_.some([..._.get(values, 'extraCurrencies', []), values.transactionCurrency],
                                                    currency => currency === element.id))
                                            }

                                            return (
                                                <React.Fragment>
                                                    {_.get(formikProps, 'form.values.extraCurrencies', []).map((currency, index) => (
                                                        <div className={classes.ClaraInputSelect}>
                                                            <Field name={`extraCurrencies[${index}]`}>
                                                                {({ field, form }) => {

                                                                    return (
                                                                        <>
                                                                            <label> Additional Currencies</label>
                                                                            <CurrencySelector
                                                                                onChange={(value) => form.setFieldValue(field.name, value)}
                                                                                value={field.value}
                                                                                handleFilterList={handleFilterValuesList}
                                                                            />

                                                                        </>
                                                                    )
                                                                }}
                                                            </Field>
                                                        </div>))}

                                                    <button type={'button'} onClick={() => formikProps.push(null)} className={`genericBlue`} >Add Currency</button>
                                                </React.Fragment>


                                            )
                                        }} />


                                </div>

                                <div>

                                </div>
                                <hr className={classes.Separator} />
                                <div className={classes.ContainerRowAgreement}>
                                    <Field name="incorporation_date">
                                        {({ field, form }) => (
                                            <TextInput
                                                type="date"
                                                label="Incorporation Date: "
                                                field={field}
                                                form={form}
                                                placeholder={"Date"}
                                                tooltip={tooltips["Incorporation Date"]}
                                                prevComponentChange={e =>
                                                    this.handleChange(e, field, form, true)
                                                }
                                            >
                                            </TextInput>
                                        )}
                                    </Field>
                                </div>
                                <div className={classes.Botonera}>
                                    <button type='submit' data-cy="submit"disabled={this.isDisabled(errors, touched)}>
                                        Save Changes
                                    </button>
                                    <button
                                        type='button' onClick={() => {
                                            this.changeMode()
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                                {/*<pre>{JSON.stringify(values, null, 2)}</pre>}*/}
                            </form>
                        )
                    }}
                >


                </Formik>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    session: state.session,

});
const mapDispatchToProps = (dispatch) => ({
    goToPage: (page, params) => {
        goToPage(dispatch, page, params)
    },
    goToPrevPage: (reload, defaultPage, params) => {
        goToPrevPage(dispatch, reload, defaultPage, params)
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Details);

import { assign } from 'xstate';
import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import machine from './machine';
import { useSelector } from 'react-redux';
import TemplateWizardSkeletonMachine from '../../../../../modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import StepCompleteStakeholders from './Steps/CompleteStakeholders';
import useTranslate from "../../../../../modules/generic/hooks/useTranslate";
import Wizard from '../../../../../modules/generic/components/Wizard/v2';
import ModalAssistant from '../../../../../modules/generic/components/Modal/ModalAssistant'
import useModal from "../../../../../modules/generic/hooks/useModal";
import { clearAssistatText } from "../../../../../modules/generic/store/action";
import useCompleteStakeholders from "./hooks/useCompleteStakeholders";
import useGetCompanyRolesAndStakeholders from "./hooks/UseGetCompanyRolesAndStakeholders";

/**
 * Wizard to Complete Stakeholders
 * @returns
 */

const ModalCompleteStakeholders = ({
  initialValues,
  open: openParam = false,
  props
}) => {

  const [open, setOpen] = useState(openParam);
  const { translate } = useTranslate();
  const session = useSelector(state => _.get(state, 'session'));
  const { closeModal: onClose } = useModal();
  const { mutation } = useCompleteStakeholders();
  const { manualQuery: getRolesQuery } = useGetCompanyRolesAndStakeholders();

  const steps = useMemo(() => ({
    confirmCompleStakeholders: {
      render: TemplateWizardSkeletonMachine,
      renderProps: {
        title: translate('MODULES.STAKEHOLDER.EDIT_SOURCE_OF_FUNDS.TITLE'),
        subTitle: translate('MODULES.STAKEHOLDER.EDIT_SOURCE_OF_FUNDS.SUBTITLE'),
      },
      stepperPosition: 0
    },
    initialStatus: {
      render: TemplateWizardSkeletonMachine,
      renderProps: {
        title: translate('MODULES.STAKEHOLDER.EDIT_SOURCE_OF_FUNDS.TITLE'),
        subTitle: translate('MODULES.STAKEHOLDER.EDIT_SOURCE_OF_FUNDS.SUBTITLE'),
      },
      stepperPosition: 0
    },
    stepConfirmStakeholders: {
      render: StepCompleteStakeholders,
      stepperPosition: 0
    },
  }), []);

  const guards = {
  };

  const services = {
    ConfirmCompleStakeholders: async (context, event) => {
      const confirmation = _.get(event, 'payload.values.confirm');
      if (confirmation) {
        let result = await mutation();
        return result;
      } else {
        return true;
      }
    },
    RetrieveStakeholders: async (context, event) => {
      let dataStakeholders = await getRolesQuery()
      return dataStakeholders.data
    }
  };

  const actions = {
    setContextAfterSelect: assign((context, event) => {
      const values = _.get(event, 'payload');
      const solution = { ...context, ...values };
      return solution;
    }),
  };

  const handleStopMachine = () => {
    onClose();
    setOpen(false);
    clearAssistatText()
  }

  return (
    <ModalAssistant open={open}>
      <Wizard
        guards={guards}
        machine={machine}
        steps={steps}
        services={services}
        actions={actions}
        onStopMachine={handleStopMachine}
      />
    </ModalAssistant>
  );
}

export default ModalCompleteStakeholders;

import React, { useEffect } from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";
const ShareClassList = ({ go, ...props }) => {
  const params = useParams();
  
  


  return (

    <MicroFrontend id="MF_ShareClass_Content_View" component={"./ShareClassView"} mf="capTable" params={{ go, ...props, ...params }} />

  );
};

export default ShareClassList;

import {gql} from "@apollo/client";
import useMutation from "../../../../hooks/custom/useMutation";
import useSession from "../../../../modules/session/hooks/useSession";
import {
  GET_GROUP_COMPANIES
} from "./../../../../modules/startup/onBoarding/core/forms/IndividualOrCompanyStakeholderForm/graphql/useGetGroupCompanies";
import {useMemo} from "react";

export const UPSERT_GROUP_COMPANY = `
  mutation upsertGroupCompany(
    $startupId: String
    $groupCompanyId: String
    $groupCompany: GroupCompanyInputType
  ) {
    upsertGroupCompany(
      startupId: $startupId
      groupCompanyId: $groupCompanyId
      groupCompany: $groupCompany
    ) {
      id
       {{#if groupCompany.transactionCurrency}}
       transactionCurrency
       {{/if}}
       extraCurrencies
      {{#if groupCompany.avatar}}avatar {{/if}}
      name
      isHoldingCompany
      incorporation_date
      dissolution_date
      hasSameWorkingAddress
      linkedinURL
      jurisdiction
      country{
        code
        name
      }
       {{#if groupCompany.registeredAddress}}
        registeredAddress {
          city  
          country {
            code
            alpha3
            name
          }
          state 
          street 
          zipCode
        }
        {{/if}}
        {{#if groupCompany.formationData}}
        formationData{
          specialMeaning
          affiliated
          arabicTranslation
        }
        {{/if}}
    }
  }`;

const useUpdateGroupCompany = (config, props) => {
  const { startupId } = useSession();

  const handleSuccessMessage = useMemo(() => {
    return (config && config.successMessage) || "ADD_COMPANY_NOTIFICATION_SUCCESS";
  }, [config]);

  return useMutation(
    UPSERT_GROUP_COMPANY,
    {
      ...config,
      successMessage: handleSuccessMessage
    },
    {
      refetchQueries: [
        "companyCorporateMap",
        {
          query: gql`
              ${GET_GROUP_COMPANIES}
          `,
          variables: { startupId: startupId },
        },
      ],
      ...props,
    }
  );
};

export default useUpdateGroupCompany;

export const loadState = (key) => {
  try {
    const serializedData = localStorage.getItem(key);
    if (serializedData === null) {
      return undefined;
    }
    return JSON.parse(serializedData);
  } catch (error) {
    console.log("Load Error", error);
    return undefined;
  }
};
export const saveState = (key, state) => {
  try {
    let newState = state;
    if (typeof state === "object") {
      newState = { ...state, hash: Date.now() };
    }
    let serializedData = JSON.stringify(newState);
    localStorage.setItem(key, serializedData);
    window.dispatchEvent(
      new StorageEvent("storage", {
        key,
        newValue: JSON.stringify(newState),
      })
    );
  } catch (error) {
    console.log("Save Error", error);
  }
};

export const loadSessionState = (key) => {
  try {
    const serializedData = sessionStorage.getItem(key);
    if (serializedData === null) {
      return undefined;
    }
    return JSON.parse(serializedData);
  } catch (error) {
    console.log("Load Error", error);
    return undefined;
  }
};
export const saveSessionState = (key, state) => {
  try {
    let serializedData = JSON.stringify(state);
    sessionStorage.setItem(key, serializedData);
  } catch (error) {
    console.log("Save Error", error);
    throw error;
  }
};

import React from "react";
import * as Yup from "yup";
import Form from "../../../../../../components/forms/Form/Form";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import ControllerInput from "../../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import TemplateLabel from "../../../../../generic/templates/Label";
import SubmitButton from "../../../../../generic/components/Buttons/SubmitButton";
import FormProps from "../../../../../generic/forms/interfaces/FormProps";
import TemplateFormDefault from "../../../../../generic/templates/Modal";
import cls from "./classes.module.scss";
import FormDevTools from "../../../../../devTools/Form";
import FileUpload from "../../../../../generic/components/Inputs/FileUpload";
import LeftContentBlock from "../../components/LeftContentBlock";
import CalendarInputDate from "../../../../../../components/inputs/Calendars/CalendarInputDate";
import { Constants } from "../../../../../../v1/utils";

const schema = Yup.object().shape({
  files: Yup.array().of(Yup.object()).min(1).required("This Field is Required"),
  expiryDate: Yup.string()
    .required("This Field Required")
    .nullable()
    .matches(Constants.REGEXP.DATE, {
      message: "This field is invalid",
      excludeEmptyString: true,
    }),
});

const FormEmiratesID: React.FC<FormProps> = ({
  initialValues = {},
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
}: FormProps) => {

  const { translate } = useTranslate();

  const handleSubmit = async (values) => {
    onCompleteSubmit && (await onCompleteSubmit(values));
  };

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
      onlySendDirty={false}
    >
      <FormDevTools />
      <Template
        buttons={{
          ...buttons,
          submit: <SubmitButton {...buttonSubmitProps}>Add</SubmitButton>,
        }}
        props={propsTemplate}
      >
        <div className={cls.ContainerForm}>
          <LeftContentBlock>
            <TemplateLabel
              label={"ID expiry"}
              className={cls.TemplateLabel}
              isRequired
            >
              <ControllerInput
                render={CalendarInputDate}
                name="expiryDate"
                defaultValue={null}
                placeholder={translate("MODULES.CLARA.ROLE_DATE_INPUT_HIT")}
              />
            </TemplateLabel>
          </LeftContentBlock>
          <ControllerInput render={FileUpload} name="files" defaultValue={[]} />
        </div>
      </Template>
    </Form>
  );
};

export default FormEmiratesID;

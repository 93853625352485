import React, { FC, useRef } from 'react';
import * as Yup from 'yup';
import Form from "../../../../components/forms/Form/FormL";
import SubmitButton from "../../../generic/components/Buttons/SubmitButton";
import { CheckboxButtonOptions } from "../../../generic/components/Inputs/CheckboxButton";
import CheckboxButtonSimple from "../../../generic/components/Inputs/CheckboxButtonSimple";
import ControllerInput from "../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import TemplateFormDefault from "../../../generic/templates/Modal";
import cls from "./Form.module.scss";
import Typography from 'src/modules/generic/components/Texts/Typography';
import useFormat from 'src/modules/generic/hooks/useFormat';
import useTranslate from "../../../generic/hooks/useTranslate";
interface IsDefaultValuesArticlesOfAssociationProps {
  shares:number
  parValue:number
  currency: string
  initialValues?: any
  onCompleteSubmit?: (values: any) => void
  Template?: any
  buttons?: any
  buttonSubmitProps?: any
  propsTemplate?: any
}
const schema = Yup.object().shape({
  isDefaultValues: Yup.boolean().nullable().required('Required'),
})

const IsDefaultValuesArticlesOfAssociation: FC<IsDefaultValuesArticlesOfAssociationProps> = ({ 
  initialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
  shares,
  parValue,
  currency
}: IsDefaultValuesArticlesOfAssociationProps) => {
  const { numberFormat } = useFormat();
  const { translate } = useTranslate();

  const options: Array<CheckboxButtonOptions<boolean, string>> = [
    {
      value: true,
      label: "Use recommended approach"
    },
    {
      value: false,
      label: "Select my own amount"
    }
  ];

  const refForm = useRef();

  return (
    <>
      <Form
        initialValues={initialValues || {}}
        schema={schema}
        onlySendDirty={false}
        onSubmit={onCompleteSubmit}
        ref={refForm} optionsForm={{ mode: "onChange" }}
      >
        <Template
          buttons={{ ...buttons, submit: <SubmitButton {...buttonSubmitProps}>Next</SubmitButton> }}
          props={propsTemplate}
        >
        <Typography component="p" color="black" variant="body">
          {translate('MODULES.GROUP_COMPANY.SHARE_CAPITAL_ADGM_CAYMAN.AUTHORISE_SHARES_STEP.DESCRIPTION', { parValue, authorisedShares: numberFormat(shares), currency })}
        </Typography>
          <ControllerInput
            render={CheckboxButtonSimple}
            name='isDefaultValues'
            options={options}
            className={cls.CheckboxButton}
            classNameButton={cls.buttonCheckBox}
          />
        </Template>
      </Form>
    </>
  )
}

export default IsDefaultValuesArticlesOfAssociation

import classnames from "classnames";
import PropTypes from 'prop-types';
import React, { forwardRef, useImperativeHandle, useRef, useCallback } from "react";
import { Constants } from "../../../../../v1/utils/constants";
import ButtonIcon from "../../../../generic/components/Buttons/ButtonIcon";
import Card, { STATUS_CARDS } from "../../../../generic/components/Contents/Card";
import AddStakeholderModal from "../../../../startup/onBoarding/stakeholder"
import useModal from './../../../../../modules/generic/hooks/useModal';
import classesModule from "./classes.module.scss";
import ContentCardRol from "./subcomponents/ContentCardRol";
import ContentCardRolSkeletor from "./subcomponents/ContentCardRolSkeletor";
import { RoleAccess } from "../../../../../v1/components";
/** 
* Rol Card
*
*/
const RolCard = forwardRef(({ id, tabIndex, onClick, status, rol, show, enabledLink, totals, onCompleteDelete, ...props }, ref) => {
  const inputRef = useRef();
  const handleFilters = useCallback(() => {
    if (onClick) {
      onClick(onClick && onClick(status !== STATUS_CARDS.SELECTED))
    }
  }, [onClick, status])
  useImperativeHandle(ref, () => ({
    current: inputRef.current,
    focus: () => {
      inputRef.current.focus();
    }
  }));
  if (!show) {
    return (
      <React.Fragment></React.Fragment>
    )
  }

  if (!rol) {
    return (
      <Card className={classnames(classesModule.RolCard)}>
        <ContentCardRolSkeletor />
      </Card>
    )
  }

  return (
    <React.Fragment>
      <Card
        key={id}
        id={id}
        ref={inputRef}
        tabIndex={0}
        className={`${classesModule.RolCard} ${classesModule[rol.id]}`}
        status={status}
        showHoverEffect={true}
      >
        <span>
          <ButtonIcon className={classesModule.iconFilter} variant={'secondary'} icon={"Map-lines"} onClick={handleFilters} />
        </span>

        <ContentCardRol
          onCompleteDelete={onCompleteDelete}
          filter={rol.filter}
          rolName={rol.label}
          rolId={rol.id}
          enabledLink={enabledLink}
          current={totals.current}
          totals={totals.total}
        ></ContentCardRol>
      </Card>
    </React.Fragment>
  )
});

RolCard.propTypes = {
  /**  Id or Key to the component */
  id: PropTypes.string,
  /** Tab index */
  tabIndex: PropTypes.number,
  /** Status Card */
  status: PropTypes.oneOf(["default", "suggested", "selected", "innactive"]),
  /** stakeholder Data */
  rol: PropTypes.object,
  /** Show Card */
  show: PropTypes.bool,
  /** Enbabled Link Card */
  enabledLink: PropTypes.bool,

};
RolCard.defaultProps = {
  tabIndex: 0,
  status: "default",
  show: true,
  enabledLink: true,
};


export default RolCard;
const setClaraScore = `
  mutation setClaraScore(
    $startupId: String
    $score: Int
  ) {
    setClaraScore(
      startupId: $startupId
      score: $score
    )
  }
  `;
  
  export {
    setClaraScore
  }
  
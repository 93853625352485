import useLazyQuery from "src/hooks/custom/useLazyQuery";
import useSession from "src/modules/session/hooks/useSession";

const GET_GROUP_COMPANY = `
query getTechLisenceQuestionnaire($startupId: ID!, $groupCompanyId: ID!) {
  getRenewalData(startupId: $startupId, groupCompanyId: $groupCompanyId) {
    id
    techLicense {
      isEmployeeShareholder {
        question
        description
      }
            isThereEmployeeWithOtherVisas {
              question
              description
            }
            isThereFutureEmployees {
              description
              question
            }
            isCompanyOperational {
              question
              description
            }
            employeeAmountStart
            employeeAmountNow
            uaeVisasIssuedAmount
            companyRevenue
            futurePlans
    }
  }
}`;

export const useGetTechLisenceQuestionnaire = (variables, config) => {
  const { manualQuery, loading, error, data, refetch } = useLazyQuery(
    GET_GROUP_COMPANY,
    variables,
    config
  );
  const { startupId } = useSession();
  const query = async (groupCompanyId) => {
    const { data } = await manualQuery({
      variables: { startupId, groupCompanyId },
    });
    return {
      techLicense: data["getRenewalData"].techLicense,
    };
  };
  return { loading, error, data, refetch, query };
};

export default useGetTechLisenceQuestionnaire;

import { useState, useMemo, useEffect } from "react";
import Axios from "axios";
import { getURLApi } from "../../../../../../utils/urlHelper";
import useSession from "../../../../../session/hooks/useSession";

const getData = (response: any) => {
  return response.data;
};

function useUploadedFile({
  onCompleted,
  parseCustomResponse = getData,
  url,
}: any) {
  
  const [progressUpload, setProgressUpload] = useState(0);
  const [cancelToken, setCancelToken] = useState();
  const [error, setError] = useState(false);
  const [response, setResponse] = useState(null);
  const { session } = useSession();
  const token = useMemo(() => session.token, [JSON.stringify(session)]);
  const urlApi = useMemo(() => url || `${getURLApi()}/uploadFile`, []);

  const stopUpload = () => {
    if (cancelToken) {
      cancelToken.cancel();
    }
  }

  const onUploadFile = (file: File) => {
    let cancelTokenSource = Axios.CancelToken.source();
    setCancelToken(cancelTokenSource);
    error && setError(false);
    const data = new FormData();
    data.append("file", file, file.name);
    Axios.post(urlApi, data, {
      headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
      cancelToken: cancelTokenSource.token,
      onUploadProgress: (progressEvent) => {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgressUpload(percentCompleted);
      },
    })
      .then((res: any) => {
        const data = parseCustomResponse(res);
        setResponse(data);
        onCompleted(data);
      })
      .catch((error) => {
        if(Axios.isCancel(error)) {
        } else {
        }
        setError(true);
      });
  };
  return { progressUpload, onUploadFile, error, response, stopUpload };
}

export default useUploadedFile;

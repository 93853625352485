import { useMemo } from 'react';
import { assign } from 'xstate';


const useActions = () => { 

  return useMemo(() => {
    //No actions were defined

    return {

    }
     
      }, []);
}

export default useActions;

import React, { useEffect, useState } from "react";

import Button from "src/modules/generic/components/Buttons/Button";
import { Constants } from "src/v1/utils";
import DetailsTabTitle from "src/components/templates/DetailsTabTitle";
import Divider from "@mui/material/Divider";
import ModalADGMAssetsAndRevenueIncorporated from "src/modules/startup/GroupCompany/ADGM/EditAssetsAndRevenue";
import ReactAvatar from "react-avatar";
import { RoleAccess } from "src/v1/components";
import Skeleton from "@material-ui/lab/Skeleton";
import TypographyLocal from "src/modules/generic/components/Texts/Typography";
import _ from "lodash";
import classes from "./AssetsAndRevenuePanel.module.scss";
import classnames from "classnames";
import useGetGroupCompanies from "src/hooks/services/modules/groupCompany/useGetGroupCompanies";
import useModal from "src/modules/generic/hooks/useModal";
import useSession from "src/modules/session/hooks/useSession";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import useUpdateGroupCompany from "src/hooks/services/modules/groupCompany/useUpdateGroupCompany";

function AssetsAndRevenuePanel({ data, isLock }) {
  const { translate } = useTranslate();
  const { startupId } = useSession();
  const { openModal, closeModal } = useModal();

  const [upsertGroupCompany] = useUpdateGroupCompany({
    successMessage: "NOTIFICATION_OVERLAY_SAVE_SUCCESS",
    paramsSuccessMessage: { overlayName: "Assets and Revenue" },
  });

  const { data: dataGroupCompanies } = useGetGroupCompanies({
    variables: { startupId },
  });

  const groupCompanyId = data?.id;

  const assetsAndRevenue = data?.assetsAndRevenue;

  const formationCompaniesData =
    data?.assetsAndRevenue?.isSharesInNotYetFormedCompanies;

  const [isLoading, setIsLoading] = useState(true);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [filteredCompaniesIds, setFilteredCompaniesIds] = useState([]);

  const handleChangeCompany = async (idFind) => {
    setIsLoading(true);

    let copyData = _.cloneDeep(filteredCompanies);

    _.forEach(copyData, (company) => {
      if (company.id === idFind) {
        company.valid = !company.valid;
      }
    });

    setFilteredCompanies([...copyData]);

    let sendData = _.chain(copyData).filter("valid").map("id").value();

    await upsertGroupCompany({
      startupId,
      groupCompanyId,
      groupCompany: {
        assetsAndRevenue: {
          ...assetsAndRevenue,
          sharesInIncorporatedCompanies: sendData
        },
      },
    });

    setIsLoading(false);
  };

  useEffect(() => {
    if(data  &&  dataGroupCompanies){
      const filterGroupCompanies = (dataGroupCompanies || []).filter(gc=>gc.kind === "INCORPORATED" && gc?.id !== groupCompanyId)
      const groupCompanies = filterGroupCompanies.map(gc=>({...gc,valid:(data?.assetsAndRevenue?.sharesInIncorporatedCompanies || []).some(sh=>sh.id === gc.id)}))
      setFilteredCompanies(groupCompanies);
      setFilteredCompaniesIds(_.map(groupCompanies, "id"));
      setIsLoading(false);
    }
  }, [JSON.stringify(dataGroupCompanies), JSON.stringify(data)]);

  const buttonsManager = (
    <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
      <Button
        variant="card"
        disabled={isLock}
        children={translate("VIEW_COMPANY_TAB_BUTTON_DETAILS_EDIT")}
        onClick={() => {
          openModal(ModalADGMAssetsAndRevenueIncorporated, {
            handleCloseModal: closeModal,
            initialValues: {
              groupCompanyId:data.id
            },
          });
        }}
      />
    </RoleAccess>
  );

  return (
    <>
      <Divider className={classes.divider} />
      <div className={classes.sourceContainer}>
        <DetailsTabTitle title="Assets and revenue" buttons={buttonsManager} />
        {isLoading ? (
          <>
            <div className={classes.row}>
              <div className={classes.titleArea}>
                <TypographyLocal
                  variant="s"
                  weight="light"
                  className={classes.text}
                >
                  {translate("TAB_OTHER_HIT_COMPANIES")}
                </TypographyLocal>
              </div>
              {filteredCompanies && (
                <>
                  {Array(filteredCompanies.length)
                    .fill(1)
                    .map((el, i) => (
                      <div className={classes.containerSkeleton}>
                        <Skeleton
                          variant="rect"
                          height="3.5rem"
                          width="3.5rem"
                          className={classes.SkeletonRounder}
                        />
                        <p>
                          <Skeleton
                            variant="text"
                            height="0.9231rem"
                            width="4.6154rem"
                          />
                        </p>
                      </div>
                    ))}
                </>
              )}
            </div>
          </>
        ) : (
          <>
            {filteredCompanies && filteredCompanies.length > 0 && (
              <div className={classes.row}>
                <div className={classes.titleArea}>
                  <TypographyLocal
                    variant="s"
                    weight="light"
                    className={classes.text}
                  >
                    {translate("TAB_OTHER_HIT_COMPANIES")}
                  </TypographyLocal>
                </div>
                {_.map(filteredCompanies, (company) => {
                  return (
                    <div
                      className={classnames({
                        [classes.containerCompany]: company?.valid,
                        [classes.opaque]: !company?.valid,
                        [classes.locked]: data?.isLocked,
                      })}
                      onClick={() =>
                        !data?.isLocked && handleChangeCompany(company.id)
                      }
                    >
                      <ReactAvatar
                        name={company?.name}
                        round={true}
                        size="3.5rem"
                        src={company?.avatar}
                      />
                      <p>{company?.name}</p>
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
        <div className={classes.row}>
          <div className={classes.titleArea}>
            <TypographyLocal
              variant="s"
              weight="light"
              className={classes.text}
            >
              {translate("TAB_OTHER_HIT")}
            </TypographyLocal>
          </div>
          {_.get(formationCompaniesData, "question", "false") === true ? (
            <TypographyLocal
              variant="s"
              weight="semibold"
              className={classes.contain}
            >
              {translate("TAB_OTHER_LABEL_AFIRMATIVE", {
                description: _.get(formationCompaniesData, "description", ""),
              })}
            </TypographyLocal>
          ) : (
            <TypographyLocal
              variant="s"
              weight="semibold"
              className={classes.contain}
            >
              {translate("TAB_OTHER_LABEL_NEGATIVE")}
            </TypographyLocal>
          )}
        </div>
      </div>
    </>
  );
}

export default AssetsAndRevenuePanel;

import _ from 'lodash'
import {FC} from 'react'
import Machine from "../../../../generic/components/Machine/MachineClass";
//Guards for add_equity state


const isThereEquity=(context)=>{
    return context.data.add_equity.equity === 'isExist'
}
const toCreate=(context)=>{
    return context.data.add_equity.equity === 'toCreate'
}

const noEquity=({data})=>data.add_equity.equity ==='notAdd'

//Guards for set_documents
const uploadDocuments=({data})=>data.add_documents.documents==='upload'

const generatedDocuments=({data})=>data.add_documents.documents==='generated'

const noDocuments=({data})=>data.add_documents.documents ==='later'

//Guards for is_there_more_equity

const thereIsMoreEquity=({data,globalData,numbersOfIteration})=>!_.isEmpty(globalData)?_.size(globalData[0].select_companies.companies) !== numbersOfIteration + 1:_.size(data.select_companies.companies)>1

const noMoreEquity=({data,globalData,numbersOfIteration})=>!_.isEmpty(globalData)?_.size(globalData[0].select_companies.companies) === numbersOfIteration + 1:_.size(data.select_companies.companies)===1
interface addNextStep{
    next:string
    stepsAdded:object
    guardsAdded:object
    actionsAdded:object
    statesAdded:object
}
export default class MachineEquity extends Machine {
    constructor(id:string,firstStepComponent:FC,secondStepComponent:FC,thirdStepComponent:FC,fourthStepComponent:FC,fifthStepUploadComponent:FC,fifthStepGenerateComponent:FC,toCreateStep:FC,nextStep:addNextStep={guardsAdded:{},statesAdded:{},actionsAdded:{},next:null,stepsAdded:{}}) {
        const machineDefinition = {
            id: id,
            initial: 'add_equity',
            states: {
                add_equity: {
                    on: {
                        NEXT: {target: 'is_there_add_equity', actions: 'setContext'},
                        CANCEL: 'finish_add_equity',
                    }
                },
                is_there_add_equity: {
                    exit: '',
                    on: {
                        '': [
                            {target: 'select_companies', cond: 'isThereEquity'},
                            {target:toCreateStep?'generate_document':'select_companies',cond:'toCreate'},
                            {target: 'finish_add_equity', cond: 'noEquity'},
                        ]
                    }
                },
                generate_document:{
                    on:{
                        CANCEL:'finish_add_equity',
                        PREVIOUS:'add_equity'
                    }
                },
                select_companies: {
                    on: {
                        NEXT: {target: 'add_equity_form', actions: 'setContext'},
                        CANCEL: 'finish_add_equity',
                        PREVIOUS: 'add_equity'
                    }
                },
                add_equity_form: {
                    on: {
                        NEXT: {target:'add_documents', actions: 'setContext'},
                        CANCEL: 'finish_add_equity',
                        PREVIOUS: 'select_companies'
                    }
                },
                add_documents: {
                    on: {
                        NEXT: {target: 'is_there_documents', actions: 'setContext'},
                        CANCEL: 'finish_add_equity',
                        PREVIOUS: 'add_equity_form'
                    }
                },
                is_there_documents: {
                    exit: '',
                    on: {
                        '': [
                            {target: 'upload_documents', cond: 'uploadDocuments'},
                            {target:'is_there_more_equity', cond: 'noDocuments'},
                            {target: 'generated_documents', cond: 'generatedDocuments'}
                        ]
                    }
                },
                upload_documents: {
                    on: {
                        NEXT: {target:nextStep.next?nextStep.next:'is_there_more_equity',actions:'setContext'},
                        CANCEL: 'finish_add_equity',
                        PREVIOUS: 'add_documents'
                    }
                },
                generated_documents: {
                    on: {
                        NEXT: {target:nextStep.next?nextStep.next:'is_there_more_equity',actions:'setContext'},
                        CANCEL: 'finish_add_equity',
                        PREVIOUS: 'add_documents'
                    }
                },
                is_there_more_equity:{
                    exit: 'setGlobal',
                    on: {
                        '': [
                            {target: 'add_equity_form', cond: 'thereIsMoreEquity'},
                            {target: 'finish_add_equity', cond: 'noMoreEquity'},
                        ]
                    }
                },
                finish_add_equity: {
                    type: 'final',
                    entry: 'final',
                },
                onDone: {
                    actions: 'stopMachine'
                },
                ...nextStep.statesAdded
            }
        }
        const options= {
            guards: {
                isThereEquity,
                noEquity,
                generatedDocuments,
                uploadDocuments,
                noDocuments,
                thereIsMoreEquity,
                noMoreEquity,
                toCreate,
                ...nextStep.guardsAdded,
            },
            actions:{
                ...nextStep.actionsAdded
            }
        }
        const steps = {
            add_equity: firstStepComponent,
            add_equity_form: thirdStepComponent,
            select_companies:secondStepComponent,
            upload_documents: fifthStepUploadComponent,
            generated_documents: fifthStepGenerateComponent,
            add_documents: fourthStepComponent,
            generate_document:toCreateStep,
            ...nextStep.stepsAdded
        }
        super(machineDefinition,options,steps);
    }
}
import React from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";
const DetailsShares = ({go,...props}) => {
 const params = useParams() ;
 const keyValue = `${params?.groupCompanyId}_${params?.shareClassId}_${params?.shareIssuanceId}`;

  return (
          <MicroFrontend  key={`${keyValue}`} id="MF_Share_Content_Details" component={"./DetailsShares"} mf="capTable" params={{go,...props,...params}} />
  );
};

export default DetailsShares;

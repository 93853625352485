import React, { Component } from "react";
import { connect } from "react-redux";

import { goToPage, goToPrevPage } from '../../../../../store/actions/page'
import { Constants } from '../../../../utils/constants'
import classes from "../ViewForms.module.scss";
import { Avatar, Date, RoleAccess } from '../../../index';
import _ from 'lodash';

class Details extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  changeMode = () => {
    this.props.goToPage(Constants.PAGES.editGroupCompany, this.props.data);
  };

  render() {
    let { data } = this.props;

    if (!data) {
      return null;
    }


    return (
      <div className={classes.ContainerView}>

        <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
          <button className={classes.buttonEditFloat} onClick={(e) => { this.changeMode() }}>
            <span>Edit Details</span>
          </button>
        </RoleAccess>
        <div className={classes.ContainerData}>
          <div className={`${classes.ContainerRow} ${classes.ContainerDataPhoto}`}>
            <label>Company Logo:</label>
            <span><Avatar avatar={data.avatar} name={data.name} showLabel={false} /></span>
          </div>
          <div className={`${classes.ContainerRow}`}>
            <label>Company name</label>
            <span>{data.name}</span>
          </div>
          <div className={`${classes.ContainerRow}`}>
            <label>Country</label>
            <span>{_.get(data, "country.name")}</span>
          </div>
          <div className={`${classes.ContainerRow}`}>
            <label>Jurisdiction:</label>
            <span>{data.jurisdiction}</span>
          </div>
          <div className={`${classes.ContainerRow}`}>
            <label>Company Type:</label>
            <span>{data.type}</span>
          </div>
          <div className={`${classes.ContainerRow}`}>
            <label>Company Number:</label>
            <span>{data.registeredNumber}</span>
          </div>
          <hr className={classes.Separator} />
          {data.isHoldingCompany ? (
            <React.Fragment>

              <div className={`${classes.ContainerRow} ${classes.ContainerCheckOk}`}>
                <span>This Group Company is the top level Holding Company for the Startup.</span>
              </div>
              <hr className={classes.Separator} />
            </React.Fragment>
          ) : null}

          <div className={`${classes.ContainerRow}`}>
            <label>Registered Address:</label>
            <span>
              {data.address ?
                <ul>
                  <li>{_.get(data, "address.country.name")}</li>
                  <li>{data.address.street}</li>
                  <li />
                  <li>{data.address.city}</li>
                  <li>{data.address.state}</li>
                  <li>{data.address.zipCode}</li>
                </ul>
                :
                null}
            </span>
          </div>
          <hr className={classes.Separator} />
          {data.hasSameWorkingAddress ? (
            <React.Fragment>
              <div className={`${classes.ContainerRow} ${classes.ContainerCheckOk}`}>
                <span>The Company address is the same as the <b>registered</b> address</span>
              </div>
            </React.Fragment>
          ) :
            <React.Fragment>

              <div className={`${classes.ContainerRow}`}>
                <label>Company Address:</label>
                <span>
                  {data.workingAddress ?
                    <ul>
                      <li>{data.workingAddress.country.name}</li>
                      <li>{data.workingAddress.street}</li>
                      <li>{data.workingAddress.city}</li>
                      <li>{data.workingAddress.state}</li>
                      <li>{data.workingAddress.zipCode}</li>
                      <li> </li>
                    </ul>
                    : null}
                </span>
              </div>
            </React.Fragment>
          }
          <hr className={classes.Separator} />
          <div className={`${classes.ContainerRow}`}>
            <label>Recipient for Notices:</label>
            <span>{data.recipientNotice.fullName}</span>
          </div>
          <div className={`${classes.ContainerRow}`}>
            <label>Recipient's Email Address:</label>
            <span>{data.recipientNotice.email}</span>
          </div>
          {data.useRegisteredAddress ? (
            <React.Fragment>
              <div className={`${classes.ContainerRow} ${classes.ContainerCheckOk}`}>
                <span>The address for the <b>recipient</b> is the same as the <b>registered</b> address</span>
              </div>
            </React.Fragment>
          ) :
            <React.Fragment>
              <div className={`${classes.ContainerRow}`}>
                <label>Recipient's Address:</label>
                <span>
                  {data.recipientNotice.address ?
                    <ul>
                      <li>{data.recipientNotice.address.country.name}</li>
                      <li>{data.recipientNotice.address.street}</li>
                      <li>{data.recipientNotice.address.city}</li>
                      <li>{data.recipientNotice.address.state}</li>
                      <li>{data.recipientNotice.address.zipCode}</li>
                    </ul>
                    : null}
                </span>
              </div>
            </React.Fragment>
          }
          <hr className={classes.Separator} />
          <div className={`${classes.ContainerRow}`}>
            <label>Transaction Currency:</label>
            <span>{data.transactionCurrency}</span>
          </div>

          {data.extraCurrencies && data.extraCurrencies.map(currency => {
            return (
              <div className={`${classes.ContainerRow}`}>
                <label>Additional Currency:</label>
                <span>{currency}</span>
              </div>
            )
          })}
          <hr className={classes.Separator} />
          {data.incorporation_date && <div className={`${classes.ContainerRow}`}>
            <label>Incorporation Date</label>
            <span><Date value={data.incorporation_date} /></span>
          </div>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  session: state.session,

});
const mapDispatchToProps = (dispatch) => ({
  goToPrevPage: () => {
    goToPrevPage(dispatch)
  },
  goToPage: (page, params) => {
    goToPage(dispatch, page, params)
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Details)

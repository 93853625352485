import { Box } from '@mui/material';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import useModal from 'src/modules/generic/hooks/useModal';
import useMachine from '../../../../generic/context/MachineContext/useMachine';
import IframeComponent from "./IframeComponent"
const STATUS = {
  success: "success",
  error: "error",
  process: "process",
  input: "input",
};
var CheckoutStep_NextStep = null
/**
 * Step to set pay data for manual invoice
 * @param {*} param0
 * @returns
 */
function CheckoutStep({ openParam }) {

  const { next, send,context } = useMachine();
  
  const handleNext = (values)=>{
    next(values)
  }
  const [counter, setCounter] = useState(-1);
  useEffect(() => {
    if (counter===0){
      send({
        type: 'TIMEOUT',
        payload: {}
      });
    }
  }, [counter, send])
  React.useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);
  const handleOnload = (data) => {
    setCounter(window?.env?.checkout?.timeToExpiredInSeconds ?? 60)
  }
  
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <IframeComponent onComplete={handleNext}  onLoad={handleOnload} url={context?.CheckoutPayment?.url} />

    </Box>
  );
}

export default CheckoutStep;

import React, { Component } from "react";
import FormikHelper from "../../../utils/formikHelper";
import { TooltipInput, Upload } from "../../index";
import classes from './ClaraUpload.module.scss';
import _ from "lodash";
import Dropzone from 'react-dropzone'
import { Constants } from "src/v1/utils/constants.js";
import { Stack, Box } from '@mui/material';
import Icon from 'src/modules/generic/components/Icon'

const dropzoneRef = React.createRef();

class ClaraUpload extends Component {

  constructor(props) {
    super(props);
    this.state = {
      acceptedFiles: [],
      cantFiles: null,
      showTooltip: false,
      multipleFiles: false,
      typeFileAllowed: props.typeFileAllowed || '',
      icons: props.icons || ["DOC", "JPG", "PDF", "PNG"],
    };
  }

  hasError() {
    const { form, field, errorMessage } = this.props;
    if (form) {
      return FormikHelper.returnErrorsForValidation(field.name, form.errors);
    } return errorMessage
  }

  hasErrorForm() {
    const { form, field, errorMessage } = this.props;
    if (form) {
      return FormikHelper.returnErrorsForValidation(field.name, form.errors);
    } return errorMessage
  }

  hasErrorComponent() {
    return false
  }

  getError() {
    const { form, field, errorMessage } = this.props;
    if (form) {
      if (this.hasErrorComponent()) {
        return "Upload Error"
      }
      return FormikHelper.returnErrorsForValidation(field.name, form.errors);
    } return errorMessage
  }

  handleDropFiles = (acceptedFiles) => {
    this.setState({
      acceptedFiles: acceptedFiles,
      cantFiles: acceptedFiles.length
    })
  }

  handleDone = async (files) => {
    const { form, field, onChange } = this.props;
    let fileUpdate = null;
    _.forEach(files, (file) => {
      fileUpdate = file;
    });
    if (form) {
      await form.setFieldValue(field.name, fileUpdate);
      try {
        await form.validateField(field.name)
      } catch (e) {
        console.log("**** error", e);
      }
    }
    if (onChange) {
      if (this.props.multipleFiles && this.props.isArrayValue) {
        let list = []
        _.forEach(files, (file) => {
          list.push(file)
        })
        onChange(list)
      } else {
        onChange(fileUpdate)
      }
    }
    if (!fileUpdate) {
      this.setState({
        acceptedFiles: [],
        cantFiles: 0
      });
    }
  }

  changeTooltipVisibility = (showTooltip) => {
    this.setState(prevState => ({
      showTooltip
    }));
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!_.get(this.props, "field.value") && _.get(this.props, "field.value") !== _.get(prevProps, "field.value")) {
      this.setState({
        acceptedFiles: []
      })
    }
  }

  componentDidMount = () => {
    const { form, field, value, initVal } = this.props;
    let acceptedFiles = []
    if (value) {
      if (_.get(value, 'id')) {
        acceptedFiles.push(value)
      }
    } else if (form) {
      if (_.get(form.values, field.name)) {
        const fieldName = _.has(form.values, `${field.name}.file`) ? `${field.name}.file` : field.name;
        acceptedFiles.push(_.get(form.values, fieldName))
      }
    } this.setState({
      acceptedFiles
    })
  }

  render() {
    let { tooltip, label, required, showRedStar, className, dropClassName } = this.props;
    let { acceptedFiles, showTooltip, multipleFiles, typeFileAllowed, icons } = this.state;
    return (
      <div className={`${classes.ClaraUploadContainer} ${className}`}>
        {(label || "") && (
          <label>{label || ""}
            {required && showRedStar !== false &&
              <span style={{ color: 'red' }}>&nbsp;*</span>}
          </label>
        )}
        <TooltipInput label={tooltip}>
          <div className={`${classes.conentDrop}`}>
            <Dropzone
              accept={typeFileAllowed}
              onDrop={acceptedFiles => {
                const filterFiles = _.filter(acceptedFiles, (file) => {
                  const extension = file.name.split(".").pop();
                  return Constants.BLOCK_EXTENSIONS.indexOf(extension);
                });
                if (filterFiles.length > 0) {
                  this.handleDropFiles(filterFiles);
                }
              }} 
              multiple={this.props.multipleFiles ? this.props.multipleFiles : multipleFiles}
            >
              {({ getRootProps, getInputProps, isDragActive }) => {
                return (
                  <React.Fragment>
                    <div className={`${classes.conentDrop} ${(this.hasErrorForm() || this.hasErrorComponent()) ? classes.Error : null} ${dropClassName}`}{...getRootProps({})}>
                      {acceptedFiles.length === 0 && (
                        <React.Fragment>
                          <div className={classes.DropZone}>
                            <input inputFile="true" hasError={this.hasErrorForm() ? "true" : "false"} ref={dropzoneRef}  {...getInputProps()} multiple={this.props.multipleFiles ? this.props.multipleFiles : multipleFiles} />
                            <Stack
                              direction="column"
                              justifyContent="flex-start"
                              alignItems="center"
                              mt={1}
                            >
                              <Box>
                                {icons.map((icon) => (
                                  <Icon color={"#2c74ff"} icon={icon} size={"32px"} isClara />
                                ))}
                              </Box>
                              <Box>
                                <a className={"linkPlus"}>{isDragActive} Drag and drop file here to upload, or<span className={classes.browserText}>&nbsp;browse</span></a>
                              </Box>
                            </Stack>
                          </div>
                        </React.Fragment>
                      )}
                      {isDragActive && (
                        <div className={classes.DragActive}></div>
                      )}
                      {acceptedFiles.length > 0 && (
                        <div className={classes.uploadZone}>
                          <Upload files={acceptedFiles} handleDone={this.handleDone} automaticDone={true} {...this.props} />
                        </div>
                      )}
                    </div>
                    {this.props.allowNullResponse && (
                      <div className={classes.NullResponse}>
                        <a onClick={() => this.props.submitNullResponse()}>I don't have it with me now</a>
                      </div>
                    )}
                  </React.Fragment>
                )
              }}
            </Dropzone>
          </div>
          {(this.hasErrorForm() || this.hasErrorComponent()) && (
            <div className={classes.MessageError}>
              {this.getError()}
            </div>
          )}
        </TooltipInput>
      </div>
    )
  }
}


export default ClaraUpload;

import { useRef, useCallback, useMemo, useEffect, useState } from 'react'
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import { debounce, isNilCustom } from './utils';
import useDebounce from '../../../../../hooks/custom/useDebounce';


function getOptionLabelDefault({ label }: { label: string }) {
    return label;
}

function useFilterAutocompleted({ configFilter = {}, timeCheckValue, getOptionLabel = getOptionLabelDefault, inputValue, options,selectedValue,functionCompare = (value1,value2)=>value1.id===value2.id }) {

    const filterMaterialUI = useMemo(() => createFilterOptions(configFilter), [JSON.stringify(configFilter)]);


    //const [inputValue, setInputValue] = React.useState('');

    //const query = useDebounce(inputValue, 200);


    const isExistingFunction = useCallback(
        (options = [], searchValue,selectedValue) => {
            return selectedValue? _.some(options,(op)=>functionCompare(op,selectedValue)): _.some(options, op => searchValue === getOptionLabel(op));
        },
        [getOptionLabel,functionCompare]);



    const filterFunction = useCallback(
        (options, params) => {
            const filtered = filterMaterialUI(options, params);
            return filtered;
        },
        [filterMaterialUI]
    );

    const isValueExisting = useMemo(() => {
        return  isExistingFunction(options,inputValue,selectedValue);
    }, [inputValue, isExistingFunction,selectedValue]);

    const isHadOptions = useMemo(() => {
        return !!filterMaterialUI(options, { inputValue, getOptionLabel }).length;
    }, [inputValue, filterMaterialUI])

    return { filterOptions: filterFunction, isValueExisting, isHadOptions };
}

export default useFilterAutocompleted

import { useMemo } from 'react';
import useModal from 'src/modules/generic/hooks/useModal';
import { Constants } from "src/v1/utils";
import ReviewPersonalDetailUBOModal from "src/views/modules/startups/renewal/adgm/modals/ReviewDetailsUboModal";
import ConfirmationsOfShareholdersAndDirectorsModal
  from "src/views/modules/startups/renewal/adgm/modals/ConfirmationsOfShareholdersAndDirectorsModal";
import ReviewPersonalDetailShareholderModal
  from "src/views/modules/startups/renewal/adgm/modals/ReviewPersonalDetailShareholderModal";
import ReviewDetailsCorporateStakeholderModal
  from 'src/views/modules/startups/renewal/adgm/modals/ReviewDetailsCorporateStakeholderModal';
import ModalProofAddress from "src/modules/startup/Stakeholder/ProofOfAddress/ModalProofAddress";
import TechLicenceModal from "src/views/modules/startups/renewal/adgm/modals/TechLicenceModal";
import PayFormationBundleModal from "src/modules/startup/PayFormationBundleModal";
import ModalPassport from "src/modules/startup/Stakeholder/Passport";
import ReviewPersonalDetailsModalCayman from "src/views/modules/startups/renewal/cayman/modals/ReviewPersonalDetailsModal";
import ReviewPersonalDetailsModalADGM from "src/views/modules/startups/renewal/adgm/modals/ReviewPersonalDetailModal";
import ReviewCompanyDetailsModal from "src/views/modules/startups/renewal/cayman/modals/ReviewCompanyDetailsModal";

const useFunctionsTaskRenewal = () => {
  const { openModal } = useModal();

  const getProductCodeByJurisdictionType = (jurisdictionType) => {
    switch (jurisdictionType) {
      case "CAYMAN":
        return Constants.MODULES.CHECKOUT.PRODUCTS.CAYMAN_SHOPPING_CART;
      case "DELAWARE":
        return Constants.MODULES.CHECKOUT.PRODUCTS.DELAWARE_SHOPPING_CART;
      case "ADGM":
        return Constants.MODULES.CHECKOUT.PRODUCTS.ADGM_SHOPPING_CART;
      default:
        return null;
    }
  };

  const dictionary = useMemo(() => ({
    TASK_RENEWAL_ADGM_REVIEW_CONFIRMATION_DIRECTORS_SHAREHOLDERS: {
      onClick: (params) => {
        openModal(ConfirmationsOfShareholdersAndDirectorsModal, {
          initialValues: {
            idToSave: params?.stakeholderId,
          }
        }, 'TASK_RENEWAL_ADGM_REVIEW_CONFIRMATION_DIRECTORS_SHAREHOLDERS');
      },
    },
    TASK_RENEWAL_ADGM_REVIEW_CORPORATE_COMPANY_DETAILS: {
      onClick: (params) => {
        if (params?.jurisdiction === "CAYMAN") {
          openModal(
            ReviewCompanyDetailsModal,
            {
              initialValues: {
                groupCompanyId: params?.groupCompanyId,
                stakeholderId: params?.stakeholderId,
              },
            },
            "MODAL_TASK_RENEWAL_KY_REVIEW_DETAILS_DIRECTOR"
          );
        } else {
          openModal(
            ReviewDetailsCorporateStakeholderModal, { 
              initialValues: { 
                stakeholderId: params?.stakeholderId 
              } 
            }, 'TASK_RENEWAL_ADGM_REVIEW_CORPORATE_COMPANY_DETAILS'
          );
        }
      },
    },
    "TASK_RENEWAL_ADGM_REVIEW_DETAILS_DIRECTOR": {
      onClick: (params) => {
        if (params?.jurisdiction === "CAYMAN") {
          openModal(
            ReviewPersonalDetailsModalCayman,
            {
              initialValues: {
                groupCompanyId: params?.groupCompanyId,
                stakeholderId: params?.stakeholderId,
              },
            },
            "MODAL_TASK_RENEWAL_KY_REVIEW_DETAILS_DIRECTOR"
          );
        } else {
          openModal(
            ReviewPersonalDetailsModalADGM,
            {
              initialValues: {
                groupCompanyId: params?.groupCompanyId,
                stakeholderId: params?.stakeholderId,
              },
            },
            "MODAL_TASK_RENEWAL_ADGM_REVIEW_DETAILS_DIRECTOR"
          );
        }
      },
    },
    "TASK_RENEWAL_ADGM_REVIEW_DETAILS_SHAREHOLDER": {
      onClick: (params) => {
        if (params?.jurisdiction === "CAYMAN") {
          openModal(
            ReviewPersonalDetailsModalCayman,
            {
              initialValues: {
                groupCompanyId: params?.groupCompanyId,
                stakeholderId: params?.stakeholderId,
              },
            },
            "MODAL_TASK_RENEWAL_KY_REVIEW_DETAILS_SHAREHOLDER"
          );
        } else {
          openModal(
            ReviewPersonalDetailShareholderModal,
            {
              initialValues: {
                groupCompanyId: params?.groupCompanyId,
                stakeholderId: params?.stakeholderId,
              },
            },
            "MODAL_TASK_RENEWAL_ADGM_REVIEW_DETAILS_SHAREHOLDER"
          );
        }
      },
    },
    "TASK_RENEWAL_ADGM_REVIEW_DETAILS_DATA_PROTECTION_CONTACT": {
      onClick: (params) => {
        if (params?.jurisdiction === "CAYMAN") {
          openModal(
            ReviewPersonalDetailsModalCayman,
            {
              initialValues: {
                groupCompanyId: params?.groupCompanyId,
                stakeholderId: params?.stakeholderId,
              },
            },
            "MODAL_TASK_RENEWAL_KY_REVIEW_DETAILS_DIRECTOR"
          );
        } else {
          openModal(
            ReviewPersonalDetailShareholderModal,
            {
              initialValues: {
                stakeholderId: params?.stakeholderId,
                groupCompanyId: params?.groupCompanyId,
              },
            },
            "MODAL_TASK_RENEWAL_ADGM_REVIEW_DETAILS_DATA_PROTECTION_CONTACT"
          );
        }
      },
    },
    "TASK_RENEWAL_ADGM_PASSPORT_REVIEW": {
      onClick: (params) => {
        openModal(
          ModalPassport,
          {
            initialValues: {
              idToSave: params?.stakeholderId,
              jurisdictionType: params?.jurisdiction,
              isForNationalityPassport: params?.additionalParams ? (params?.additionalParams[0]?.jurisdictionType || params?.jurisdiction) : params?.jurisdiction
            },
          },
          "MODAL_TASK_PASSPORT"
        );
      },
    },
    "TASK_RENEWAL_ADGM_REVIEW_DETAILS_AUTHORISED_SIGNATORY": {
      onClick: (params) => {
        if (params?.jurisdiction === "CAYMAN") {
          openModal(
            ReviewPersonalDetailsModalCayman,
            {
              initialValues: {
                groupCompanyId: params?.groupCompanyId,
                stakeholderId: params?.stakeholderId,
              },
            },
            "MODAL_TASK_RENEWAL_KY_REVIEW_DETAILS_DIRECTOR"
          );
        } else {
          openModal(
            ReviewPersonalDetailsModalADGM,
            {
              initialValues: {
                groupCompanyId: params?.groupCompanyId,
                stakeholderId: params?.stakeholderId,
              },
            },
            "MODAL_TASK_RENEWAL_ADGM_REVIEW_DETAILS_AUTHORISED_SIGNATORY"
          );
        }
      },
    },
    "TASK_RENEWAL_ADGM_REVIEW_DETAILS_UBO": {
      onClick: (params) => {
        if (params?.jurisdiction === "CAYMAN") {
          openModal(
            ReviewPersonalDetailsModalCayman,
            {
              initialValues: {
                groupCompanyId: params?.groupCompanyId,
                stakeholderId: params?.stakeholderId,
              },
            },
            "MODAL_TASK_RENEWAL_KY_REVIEW_DETAILS_DIRECTOR"
          );
        } else {
          openModal(
            ReviewPersonalDetailUBOModal,
            {
              initialValues: {
                stakeholderId: params?.stakeholderId,
              },
            },
            "MODAL_TASK_RENEWAL_ADGM_REVIEW_DETAILS_UBO"
          );
        }
      },

    },
    "TASK_RENEWAL_ADGM_PROOF_OF_ADDRESS": {
      onClick: (params) => {
        openModal(
          ModalProofAddress,
          {
            initialValues: {
              stakeholderId: params?.stakeholderId,
              stakeholderName: params?.stakeholderName,
              isRenewal: true,
            },
          },
          "MODAL_TASK_RENEWAL_ADGM_PROOF_OF_ADDRESS"
        );
      },
    },
    "TASK_RENEWAL_ADGM_COMPLETE_TECH_LICENCE_QUESTIONNAIRE": {
      onClick: (params) => {
        openModal(
          TechLicenceModal,
          {
            ...params,
            initialValues: {
              groupCompanyId: params?.company?.id,
              groupCompanyName: params?.company?.name,
            },
          },
          "MODAL_TASK_RENEWAL_ADGM_COMPLETE_TECH_LICENCE_QUESTIONNAIRE"
        );
      },
    },
    TASK_RENEWAL_KY_STRIKE_OFF_PAY: {
      onClick: (params, callback) => {
        openModal(PayFormationBundleModal, {
          initialValues: {
            groupCompanyId: params?.id,
            productCode: getProductCodeByJurisdictionType(params?.jurisdictionType),
            needFirstContact: true,
            disabledPayCart: false,
            isPincasSubmit: false,
            callback
          }
        }, 'MODAL_PAY_FORMATION_BUNDLE');
      },
    },
    TASK_RENEWAL_ADGM_STRIKE_OFF_PAY: {
      onClick: (params, callback) => {
        openModal(PayFormationBundleModal, {
          initialValues: {
            groupCompanyId: params?.id,
            productCode: getProductCodeByJurisdictionType(params?.jurisdictionType),
            needFirstContact: true,
            disabledPayCart: false,
            isPincasSubmit: false,
            callback
          }
        }, 'MODAL_PAY_FORMATION_BUNDLE');
      },
    },
  }), [openModal]);

  return dictionary
}
export default useFunctionsTaskRenewal

import useCustomMachine from '../../../../generic/hooks/useCustomMachine';
import React, { FC, useEffect } from 'react';
import Button from '../../../../generic/components/Buttons/Button';
import { StepProps } from "../../../../generic/components/Wizard/Step";
import TemplateWizard from '../../../../generic/templates/Modal/TemplateWizard';
import useTranslate from '../../../../generic/hooks/useTranslate';
import Form from "../../../GroupCompany/Forms/FormNoticesAddress";
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addAssistantText, clearAssistatText } from '../../../../generic/store/action';
import useSession from '../../../../session/hooks/useSession';
import useUpdateStakeholder from "src/modules/startup/onBoarding/core/graphql/mutations/useUpdateStakeholder";

const Step2: FC<StepProps> = () => {

  const { translate } = useTranslate();
  const { next, send, state, prev } = useCustomMachine();
  const { startupId } = useSession();
  const { id: _stakeholderId } = useParams<any>();
  
  const stakeholderId = state?.context?.data?.stakeholderId ?? _stakeholderId;
  const dispatch = useDispatch();

  const { updateStakeholder } = useUpdateStakeholder({}, {});

  const getValuesForMutation = (state, valuesStep) => {
    const { context } = state;
    const result = {
      ...valuesStep.recipientNotice,
      ...context.data.add_notices.recipientNotice
    }
    return result;
  };

  const handleSubmit = React.useCallback(async (values) => {
    const variables = {
      startupId,
      stakeholderId,
      stakeholderData: {
        stakeholder: {
          recipientNotice: getValuesForMutation(state, values)
        }
      }
    }
    await updateStakeholder({ variables });
  }, [updateStakeholder, startupId, state, stakeholderId]);

  const handleCompleteSubmit = async values => {
    await handleSubmit(values);
    next(values);
  }

  const handleCancel = async values => {
    send("CANCEL");
  }

  const handlePrevious = () => {
    prev();
  }

  useEffect(() => {
    dispatch(addAssistantText('A recipent is the person specified in agreements to receive notices on your company`s behalf.'))
    return () => {
      dispatch(clearAssistatText())
    }
  }, [])

  return (
    <React.Fragment>
      <Form onCompleteSubmit={handleCompleteSubmit} Template={TemplateWizard} propsTemplate={{
        title: translate(`MODULES.MAP.WIZARD_COMPANY_NOTICES_STEP_2_FORM_TITLE`),
        subTitle: "Notices"
      }}
        buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button>, previous: <Button onClick={handlePrevious} variant="secondary">Previous</Button> }}>
      </Form>
    </React.Fragment>
  )
}

export default Step2;

import React, { FC } from "react";
import { DeleteButton } from "src/components/generic/Buttons/DeleteButton";
import TemplateDelete from "src/modules/generic/templates/Modal/TemplateDelete";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";


const StakeConnectManagedByClara: FC<{}> = () => {

  const { context, next } = useMachine();

  const handleCancel = () => {
    next()
  }

  return (
    <TemplateDelete 
      buttons={{ submit: <DeleteButton onClick={handleCancel}>Acknowledge</DeleteButton> }} 
      props={{
        title: "It's not possible to delete this stakeholder right now",
        description: (
          <>
            <p style={{ fontSize: "14px"}}>
              This stakeholder is a shareholder and/or a director of a company managed by Clara. 
            </p>
            <p style={{ lineHeight: "1.4", fontSize: "14px"}}>
              {context?.deleteVerification?.ableToDelete?.reasonText}
            </p>
          </>
        )
      }}
    >
      <></>
    </TemplateDelete>

  )
}

export default StakeConnectManagedByClara
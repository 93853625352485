import * as Yup from "yup";
import * as numeral from "numeral";

import React, { FC, useCallback, useMemo, useRef, useState } from "react";

import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import Form from "src/components/forms/Form/FormL";
import FormProps from "src/modules/generic/forms/interfaces/FormProps";
import Parser from "html-react-parser";
import SelectInput from "src/components/inputs/Select/SelectInput/SelectInput";
import { StakeholderBO } from "src/modules/startup/core/type/startup/stakeholder";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import TemplateFormDefault from "src/modules/generic/templates/Modal";
import TextInput from "src/components/inputs/Text/TextInput";
import Typography from "src/modules/generic/components/Texts/Typography";
import _ from "lodash";
import classnames from "classnames";
import shareCapitalClasses from "../ShareCapital/styles/ShareCapital.module.scss";
import { useForm } from "react-hook-form";
import useTranslate from "src/modules/generic/hooks/useTranslate";

interface ShareCapitalFormProps extends FormProps {
  sharesData: {
    authorisedShares: number;
    shareNominalPrice: number;
    shareClass: string;
    isUpload: boolean;
    currency: string;
  };
  shareholders: StakeholderBO[];
  shareholdersInitialValues?: any;
}

interface Value {
  shares: number;
}

const ShareCapitalForm: FC<ShareCapitalFormProps> = ({
  initialValues,
  sharesData,
  shareholders,
  shareholdersInitialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
}: ShareCapitalFormProps) => {
  const { translate } = useTranslate();
  const {
    authorisedShares,
    shareNominalPrice,
    shareClass,
    isUpload,
    currency,
  } = sharesData;
  const [remaining, setRemaining] = useState(authorisedShares);
  const [showError, setShowError] = useState(false);

  const schema = Yup.object().shape({
    stakeholders: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().nullable().required("Required"),
          shares: Yup.number()
            .typeError("Must be a number")
            .required("Required")
            .min(0.01, "Must be greater than 0.01"),
          isPaid: Yup.string().nullable().required("Required"),
          shareClass: Yup.lazy((value) => {
            if (isUpload) {
              return Yup.string().required("Required");
            }
            return Yup.string().nullable();
          }),
        })
      )
      .test("stakeholders", (obj) => {
        setShowError(false);
        if (!obj || obj.length === 0) return false;
        try {
          const totalShares = _.sumBy(obj, (sh: Value) => +sh?.shares);
          if (totalShares > authorisedShares) setShowError(true);
          return totalShares <= authorisedShares;
        } catch (e) {
          setShowError(false);
          return false;
        }
      }),
  });

  const amountIssued = useMemo(() => {
    return authorisedShares - remaining;
  }, [authorisedShares, remaining]);

  const getPercentage = useCallback(
    (shares: number): string => {
      if (shares) {
        if (shares === 0 || shares === "0") return "0%";
        const percentage = (+shares * 100) / amountIssued;
        if (Number.isInteger(percentage)) {
          return `${percentage.toFixed(0)}%`; // Remove decimals for integers
        } else {
          return `${percentage.toFixed(2)}%`; // Keep two decimals for non-integers
        }
      }
      return "0%";
    },
    [amountIssued]
  );

  const refForm = useRef();

  const { watch, setValue } = useForm();

  const renderShareholderData = (shareholders) => {
    return _.map(shareholders, (sh, index: number) => {
      return (
        <div className={shareCapitalClasses.shareholderContainer}>
          <div className={shareCapitalClasses.id}>
            <ControllerInput
              name={`stakeholders[${index}].id`}
              defaultValue={sh?.id}
              disabled={true}
              render={TextInput}
            />
          </div>
          <div className={shareCapitalClasses.name}>
            <Typography
              component="p"
              variant="body"
              className={shareCapitalClasses.nameTitle}
            >
              {" "}
            </Typography>
            <Typography
              component="p"
              variant="body"
              className={shareCapitalClasses.nameDescription}
            >
              {sh.fullName}
            </Typography>
          </div>
          <div
            className={classnames(
              shareCapitalClasses.percentage,
              shareCapitalClasses.disabled
            )}
          >
            <Typography component="p" variant="body">
              Percentage
            </Typography>
            <ControllerInput
              name={`stakeholders[${index}].percentage`}
              value={getPercentage(watch(`stakeholders[${index}].shares`))}
              disabled={true}
              render={TextInput}
            />
          </div>
          <div className={shareCapitalClasses.shares}>
            <Typography component="p" variant="body">
              No. of shares
            </Typography>
            <ControllerInput
              name={`stakeholders[${index}].shares`}
              defaultValue={
                sh?.shares || shareholdersInitialValues[index]?.shares
              }
              render={TextInput}
              type="number"
              placeholder="Enter an amount"
              decimals={false}
              onChange={(value) => {
                setValue(`stakeholders[${index}].shares`, value);
                setRemaining(
                  authorisedShares -
                    _.sumBy(watch(`stakeholders`), (sh: Value) => sh?.shares ? +sh?.shares : 0)
                );
              }}
            />
          </div>
          <div className={shareCapitalClasses.paidUnpaid}>
            <Typography component="p" variant="body">
              Paid / Unpaid
            </Typography>
            <ControllerInput
              name={`stakeholders[${index}].isPaid`}
              defaultValue={
                shareholdersInitialValues[index]?.isPaid !== undefined
                  ? shareholdersInitialValues[index]?.isPaid?.toString()
                  : "true"
              }
              disableTyping={true}
              render={SelectInput}
              list={[
                { value: "true", label: "Paid" },
                { value: "false", label: "Unpaid" },
              ]}
            />
          </div>
          <div
            className={classnames(shareCapitalClasses.shareClass, {
              [shareCapitalClasses.disabled]: !isUpload,
            })}
          >
            <Typography component="p" variant="body">
              Share class
            </Typography>
            <ControllerInput
              name={`stakeholders[${index}].shareClass`}
              render={TextInput}
              defaultValue={
                shareholdersInitialValues[index]?.shareClass
                  ? shareholdersInitialValues[index].shareClass
                  : isUpload
                  ? ""
                  : shareClass
              }
              disabled={!isUpload}
            />
          </div>
        </div>
      );
    });
  };

  const showAmountIssued = useMemo(() => {
    const decimals = amountIssued.toString().length;

    if (decimals >= 20) {
      return "-";
    }

    return numeral(amountIssued).format("0,0");
  }, [amountIssued]);

  return (
    <>
      <Form
        initialValues={initialValues || {}}
        schema={schema}
        onlySendDirty={false}
        onSubmit={onCompleteSubmit}
        ref={refForm}
        optionsForm={{ mode: "onChange" }}
      >
        <Template
          buttons={{
            ...buttons,
            submit: <SubmitButton {...buttonSubmitProps}>Save</SubmitButton>,
          }}
          props={propsTemplate}
        >
          <Typography component={"p"} variant="body">
            {Parser(
              translate(
                "MODULES.GROUP_COMPANY.SHARE_CAPITAL_ADGM_CAYMAN.SHARE_STEP.DESCRIPTION",
                {
                  currency,
                  parValue: numeral(shareNominalPrice).format("0,0.0000"),
                  authorisedShares: numeral(authorisedShares).format("0,0"),
                }
              )
            )}
          </Typography>
          <Typography component={"p"} variant="body">
            {Parser(
              translate(
                "MODULES.GROUP_COMPANY.SHARE_CAPITAL_ADGM_CAYMAN.SHARE_STEP.ISSUED",
                { amount: showAmountIssued }
              )
            )}
          </Typography>
          {showError && (
            <Typography
              variant="xs"
              className={shareCapitalClasses.errorMessage}
            >
              The amount issued cannot be greater than the authorised shares
              available
            </Typography>
          )}
          <div>{renderShareholderData(shareholders)}</div>
        </Template>
      </Form>
    </>
  );
};

export default ShareCapitalForm;

import React, { FC, useEffect } from 'react';
import { useDispatch } from "react-redux";
import useRemovePendingEntity from "../../../../../../hooks/services/modules/assistance/useRemovePendingEntity";
import { StepProps } from '../../../../../generic/components/Wizard/Step';
import useTranslate from "../../../../../generic/hooks/useTranslate";
import { addAssistantText, clearAssistatText } from "../../../../../generic/store/action";
import useSession from "../../../../../session/hooks/useSession";
import FirstStepForm from "../../../core/wizards/steps/FirstStep";

const FirstStep: FC<StepProps> = ({ }: StepProps) => {

  const { startup } = useSession();
  const { translate } = useTranslate()
  const dispatch = useDispatch()
  const [removePendingEntity] = useRemovePendingEntity({ showSuccessNotification: false });

  const handleCompleteSubmit = async () => {
    await removePendingEntity({ entityType: 'EMPLOYEE', startupId: startup.id })
    dispatch(clearAssistatText());
  }

  useEffect(() => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText(translate('MODULES.ONBOARDINGROLES.EMPLOYEE.FIRST_STEP_TOOLTIPS')));
    return () => {
      dispatch(clearAssistatText());
    }
  }, [])

  return (
    <React.Fragment>
      <FirstStepForm tooltips={{ isNotExist: translate('MODULES.ONBOARDINGROLES.EMPLOYEE.FIRST_STEP_IS_NOT_EXIST_TOOLTIPS') }} onCompleteSubmit={handleCompleteSubmit} entity={'an employee'} role={'EMPLOYEE'} />
    </React.Fragment>
  )
}

export default FirstStep;

import { default as React, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import Button from "src/modules/generic/components/Buttons/Button";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import {
  addAssistantText,
  clearAssistatText,
} from "src/modules/generic/store/action";
import Passport from "src/modules/startup/Stakeholder/ADGM/public/Passport.svg";
import AddPassportForm from "src/modules/startup/Stakeholder/ADGM/components/Forms/FormVisa";
import cls from "src/modules/startup/Stakeholder/ADGM/EmiratesID/Forms/classes.module.scss";
import EmiratesID from "src/modules/startup/Stakeholder/ADGM/public/Emirates_ID.svg";
import BlackID from "src/modules/startup/Stakeholder/ADGM/public/Back_of_ID_card.svg";
import UAE_Visa from "src/modules/startup/Stakeholder/ADGM/public/UAE_Visa.svg";
import GCCResidentID from "src/modules/startup/Stakeholder/ADGM/public/GCC_resident_ID.svg";
import BackOfIDCard from "src/modules/startup/Stakeholder/ADGM/public/Back_of_ID_card.svg";

function UploadDocumentStep() {
  const { next, context, cancel, prev } = useMachine();
  const dispatch = useDispatch();
  const document = context?.initialData?.document;
  const documentType = context?.initialData?.documentType;

  const initialValues = useMemo(() => {
    return context?.UploadDocumentStep ?? {};
  }, [context]);

  const handleCancel = () => cancel();

  const handleSubmit = async (values) => next(values);

  const handlePrev = () => prev();

  useEffect(() => {
    dispatch(
      addAssistantText(
        "Upload clear scans of the front and back of this document."
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

	const disablePreviousButton = useMemo(() => {
    return context?.isSameNacionality ?? false;
	},[context]);

  const renderImage = () => {
    switch (documentType) {
      case "PASSPORT_DOCUMENT":
        return <img alt="Passport" src={Passport} />;
      case "EID_DOCUMENT":
        return (
          <div className={cls.containerImages}>
            <img
              src={EmiratesID}
              style={{ height: "12.6154rem" }}
              alt="Emirates ID"
            />
            &nbsp;
            <img
              src={BlackID}
              style={{ height: "12.6154rem" }}
              alt="Emirates ID"
            />
          </div>
        );
      case "NATIONAL_ID_DOCUMENT":
        return (
          <>
            <img src={GCCResidentID} alt="National ID Document" />{" "}
            <img src={BackOfIDCard} alt="National ID Document" />
          </>
        );
      case "UAE_RESIDENCE_VISA_DOCUMENT":
        return <img alt="UAE Visa" src={UAE_Visa} />;
      default:
        return null;
    }
  };

  return (
    <AddPassportForm
      initialValues={initialValues}
      onCompleteSubmit={handleSubmit}
      Template={TemplateWizard}
      propsTemplate={{
        title: `Upload new ${document}`,
        skeleton: null,
        subTitle: `Expired ${document}`,
      }}
      image={renderImage()}
      accept="image_pdf"
      buttons={{
        cancel: (
          <Button onClick={handleCancel} variant="secondary">
            Cancel
          </Button>
        ),
        previous: (
          <>
            {!disablePreviousButton && (
              <Button onClick={handlePrev} variant="secondary">
                Previous
              </Button>
            )}
          </>
        ),
      }}
    />
  );
}

export default UploadDocumentStep;

import _ from "lodash";
import {Constants, ServerConnect} from "../../../../../utils";
import download from "downloadjs";
import RegistedAddressInput from "../../RegisteredAddressInput/RegistedAddressInput";
import {Field} from "formik";
import {ClaraUpload, OptionsButtonInput} from "../../../../../components";
import classesForm from "../../Form.module.scss";
import React from "react";

const ResidentialAddress = ({ values }) => {


    const handleOptionsButtonClick = (field, form, value) => {
        form.setFieldValue(field.name, value);
    };

    const getLabelAddressDoc = () => {
        return _.get(values, "proofOfAddress") === "yes" ? "Upload proof of address here:" : "Upload completed address letter and supporting documents.";
    }



    const handleDowload = async () => {
        const query = `{ getCDNAccess(assetId:"${Constants.CONTENTFUL.ASSETS.RESIDENTIAL_ADDRESS_TEMPLATE}") }`
        let { getCDNAccess } = await ServerConnect.graphQlQuery(query);
        let contenfulJson = await (await fetch(getCDNAccess)).json();
        let file = await (await fetch(`https:${contenfulJson.fields.file.url}`)).blob();
        download(file, contenfulJson.fields.file.fileName, contenfulJson.fields.file.contentType);
    }


    return (
        <>
            <h1>Residential Address</h1>

            <RegistedAddressInput name={"address"} label={"Address:"} />



            {"AE" === _.get(values, "address.country", null) &&


            <Field name={`UAEVisaDoc`}>
                {({ field, form }) => (
                    <ClaraUpload

                        name={`filepond`}
                        label={`Upload your UAE VISA here:`}
                        field={field}
                        form={form}
                        className={classesForm.fileUpload}
                        data-zuko-name='Upload your UAE VISA here'
                        // tooltip={tooltips['Upload your document']}
                    />
                )}
            </Field>


            }



            <Field name={"proofOfAddress"}>
                {({ field, form }) => (
                    <OptionsButtonInput chatform={false}
                                        field={field}
                                        form={form}
                                        className={{ root: classesForm.buttonOptions }}
                                        label={"Do you have current proof of address in your name?"}
                                        options={[
                                            {
                                                "id": true,
                                                "text": "Yes"
                                            },
                                            {
                                                "id": false,
                                                "text": "No"
                                            }
                                        ]}
                                        onClick={handleOptionsButtonClick}
                                        data-zuko-name='Do you have current proof of address in your name?'
                        //  params={this.props.params}
                        //  functions={this.props.functions}
                    />

                )}
            </Field>
            {_.has(values, "proofOfAddress") && <>

                <h2>{values.proofOfAddress ? `We accept the following:` : `Third Party Confirmation:`}</h2>
                <article>
                    {!values.proofOfAddress ? <>Please <a href={null} onClick={handleDowload}>click here</a> to download the third party address from, that you will need to get filled out.</> :
                        `A utility bill or bank statement with your name and residential address clearly shown will be accepted as proof of your address. It must be dated within the past two months.`
                    }
                </article>

                {
                    !values.proofOfAddress ?
                        <>
                            <Field name="thirdPartyAddressFormDoc">
                                {({ field, form }) => (
                                    <ClaraUpload

                                        name={`filepond`}
                                        label={`Upload completed and signed third party address form:`}
                                        data-zuko-name='Upload completed and signed third party address form'
                                        field={field}
                                        form={form}
                                        className={classesForm.fileUpload}
                                        // tooltip={tooltips['Upload your document']}
                                    />
                                )}
                            </Field>

                            <Field name="thirdPartyAddressPassportDoc">
                                {({ field, form }) => (
                                    <ClaraUpload

                                        name={`filepond`}
                                        label={`Upload passport copy of person who signed the third party address form:`}
                                        data-zuko-name='Upload passport copy of person who signed the third party address form'
                                        field={field}
                                        form={form}
                                        className={classesForm.fileUpload}
                                        // tooltip={tooltips['Upload your document']}
                                    />
                                )}
                            </Field>

                        </>
                        : <Field name="proofOfAddressDoc">
                            {({ field, form }) => (
                                <ClaraUpload

                                    name={`filepond`}
                                    label={`Upload proof of address here:`}
                                    data-zuko-name='Upload proof of address here'
                                    field={field}
                                    form={form}
                                    className={classesForm.fileUpload}
                                    // tooltip={tooltips['Upload your document']}
                                />
                            )}
                        </Field>
                }
            </>
            }



        </>
    )
}
export default ResidentialAddress;
import React from 'react';
import classes from './Toolbar.module.scss';
import Botonera from './../../../../../modules/generic/components/Buttons/Botonera';
import ButtonIcon from "./../../../../../modules/generic/components/Buttons/ButtonIcon";

const ToolbarRight = ({ scale, setScale, defaultScale }) => {

  const isMinZoom = scale < 0.6;
  const isMaxZoom = scale >= 2.0;

  const zoomOut = () => {
    if (!isMinZoom) setScale(scale - 0.1);
  }
  const zoomIn = () => {
    if (!isMaxZoom) setScale(scale + 0.1);
  }

  const handleReset = () => {
    if (!!defaultScale) {
      setScale(defaultScale);
    } else {
      setScale(1);
    }
  }

  return (
    <React.Fragment>
      <div className={`${classes.ToolbarRight}`}>
        <Botonera
          buttons={[
            <ButtonIcon key={3} size={"55%"} icon="focus" variant="transparent" onClick={handleReset} />,
            <ButtonIcon key={1} size={"55%"} icon="Plus" disabled={isMaxZoom} variant="transparent" onClick={zoomIn} />,
            <ButtonIcon key={2} size={"55%"} icon="Minus" disabled={isMinZoom} variant="transparent" onClick={zoomOut} />,
          ]}
          className={undefined}
        />
      </div>
    </React.Fragment>
  )
}

export default ToolbarRight

import { default as React, useEffect, useState, useMemo } from 'react';
// import { useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import _ from "lodash";
import * as Yup from 'yup';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import AutocompleteClara from "../../../../../../../../../generic/components/Inputs/Autocomplete";
import TextInput from "../../../../../../../../../../components/inputs/Text/TextInput";
import { Constants } from "../../../../../../../../../../v1/utils";
import CheckboxButtonSimple from "../../../../../../../../../generic/components/Inputs/CheckboxButtonSimple";
import classes from '../../styles/classes.module.scss';
import { HiddenFieldForm } from "src/components/forms/contents";

function AddDirector() {
  // const params = useParams();
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const { next, context, cancel } = useMachine();
  const [showWarning, setShowWarning] = useState(false);
  const [isCorporate, setIsCorporate] = useState(false);

  const initialValues = useMemo(() => {
    return context?.initialData ?? {}
  }, [context]);

  
  const { stakeholderName, formValues, stakeholders, stakeholderId,directors } = initialValues;

  const actualStakeholder = useMemo(() => {
    return _.filter(stakeholders, (stakeholder) => stakeholder?.id === stakeholderId);
  }, [stakeholderId, stakeholders]);

  const stakeholdersListIndividual = useMemo(() => {
    const filteredStakeholders = _.filter(stakeholders, (stakeholder) => stakeholder?.id !== stakeholderId && stakeholder?.isAnEntity === false);
    return _.map(filteredStakeholders, (stakeholder) => ({
      value: stakeholder,
      label: stakeholder?.fullName
    }));
  }, [JSON.stringify(stakeholders)]);

  const stakeholdersListCorporate = useMemo(() => {
    const filteredStakeholders = _.filter(stakeholders, (stakeholder) => stakeholder?.id !== stakeholderId && stakeholder?.isAnEntity === true);
    return _.map(filteredStakeholders, (stakeholder) => ({
      value: stakeholder,
      label: stakeholder?.fullName
    }));
  }, [JSON.stringify(stakeholders)]);

  const directorNumber = directors?.length + 1;

  const handleCancel = () => {
    cancel();
  }

  const handleSubmit = async (values) => {
    next(values);
  }

  useEffect(() => {
    dispatch(addAssistantText(translate('MODULES.STAKEHOLDER.ENTITY_STRUCTURES.COMPANY_DIRECTORS.FIRST_ADD_TOOLTIP')));
    if (isCorporate) {
      dispatch(addAssistantText(translate('MODULES.STAKEHOLDER.ENTITY_STRUCTURES.COMPANY_DIRECTORS.CORP_SELECTED_TOOLTIP'), "alert"));
    }
    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch, isCorporate]);

  const options = [
    {
      value: false,
      label: translate(`MODULES.STAKEHOLDER.ENTITY_STRUCTURES.COMPANY_DIRECTORS.STAKEHOLDER_OPTION_1`),
    },
    {
      value: true,
      label: translate(`MODULES.STAKEHOLDER.ENTITY_STRUCTURES.COMPANY_DIRECTORS.STAKEHOLDER_OPTION_2`),
    },
  ];

  const createStakeholder = (value: string) => {
    return { fullName: value };
  }

  const handeAddTooltips = () => {
    dispatch(clearAssistatText());
    dispatch(
      addAssistantText(
        translate("MODAL_ADD_SHAREHOLDER_ADD_NAME_EXISTING_2"),
        "alert"
      )
    );
  };

  const schema = Yup.object().shape({
    isCorporate: Yup.boolean().required(translate('MODULES.STAKEHOLDER.ENTITY_STRUCTURES.COMPANY_DIRECTORS.FIELD_REQUIRED')).nullable(),
    stakeholderIndividual: Yup.lazy(() => {
      if (isCorporate) return Yup.object().nullable();
      return Yup.object().nullable().required(translate('MODULES.STAKEHOLDER.ENTITY_STRUCTURES.COMPANY_DIRECTORS.FIELD_REQUIRED'));
    }),
    stakeholderCorporate: Yup.lazy(() => {
      if (isCorporate) return Yup.object().nullable().required(translate('MODULES.STAKEHOLDER.ENTITY_STRUCTURES.COMPANY_DIRECTORS.FIELD_REQUIRED'));
      return Yup.object().nullable();
    }),
    email: Yup.lazy(() => {
      if (isCorporate) return Yup.string().nullable();
      return Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true }).required(translate('MODULES.STAKEHOLDER.ENTITY_STRUCTURES.COMPANY_DIRECTORS.FIELD_REQUIRED'));
    })
  }).test('stakeholderCorporate', null, (obj) => {
    if (obj === undefined) {
      return true;
    }
    if (_.get(obj, 'stakeholderCorporate.fullName', "") === _.get(actualStakeholder, '[0].fullName', "")) {
      handeAddTooltips(obj);
      setShowWarning(true);
      return new Yup.ValidationError(
        `Stakeholder already added`,
        null,
        `stakeholderCorporate`
      );
    } else {
      if (showWarning) {
        setShowWarning(false);
        dispatch(clearAssistatText());
        dispatch(addAssistantText(translate('MODULES.STAKEHOLDER.ENTITY_STRUCTURES.COMPANY_DIRECTORS.FIRST_ADD_TOOLTIP')));
      }
    }
    return true;
  });

  return (
    <Form
      defaultValues={formValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.COMPANY_DIRECTORS.ADD_TITLE", { stakeholderName, directorNumber }),
          skeleton: null,
          subTitle: translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.COMPANY_DIRECTORS.SUBTITLE"),
          variant: 'simple'
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>{translate(isCorporate ? 'BUTTON_NEXT' : 'GENERIC_BUTTON_ADD_GROUP')}</SubmitButton>,
        }}
      >
        <>
          <TemplateLabel label={translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.COMPANY_DIRECTORS.IS_CORPORATE_FIELD_LABEL")} isRequired>
            <ControllerInput
              render={CheckboxButtonSimple}
              name="isCorporate"
              size="size1"
              options={options}
              classNameButton={classes.heightCheckboxButtonSimple}
              onChange={(value) => {
                setIsCorporate(!!value);
              }}
            />
          </TemplateLabel>
          <HiddenFieldForm
            fieldsWatch={"isCorporate"}
            conditionHideCallback={(value) => value === true}
          >
            <TemplateLabel label={translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.COMPANY_DIRECTORS.CORPORATE_NAME_FIELD_LABEL")} isRequired>
              <ControllerInput
                render={AutocompleteClara}
                getCreateValue={createStakeholder}
                name='stakeholderCorporate'
                options={stakeholdersListCorporate}
                noNewTagForThisInputs={[_.get(actualStakeholder, '[0].fullName', "")]}
                placeholder={translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.COMPANY_DIRECTORS.COMPANY_FIELD_PLACEHOLDER")}
              />
            </TemplateLabel>
          </HiddenFieldForm>
          <HiddenFieldForm
            fieldsWatch={"isCorporate"}
            conditionHideCallback={(value) => value === false}
          >
            <TemplateLabel label={translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.COMPANY_DIRECTORS.NAME_FIELD_LABEL")} isRequired>
              <ControllerInput
                render={AutocompleteClara}
                getCreateValue={createStakeholder}
                name='stakeholderIndividual'
                options={stakeholdersListIndividual}
                noNewTagForThisInputs={[_.get(actualStakeholder, '[0].fullName', "")]}
                placeholder={translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.COMPANY_DIRECTORS.NAME_FIELD_PLACEHOLDER")}
              />
            </TemplateLabel>
            <TemplateLabel label={translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.COMPANY_DIRECTORS.EMAIL_FIELD_LABEL")} isRequired>
              <ControllerInput
                render={TextInput}
                name="email"
                placeholder="MODULES.STAKEHOLDER.ENTITY_STRUCTURES.COMPANY_DIRECTORS.EMAIL_FIELD_PLACEHOLDER"
              />
            </TemplateLabel>
          </HiddenFieldForm>
        </>
      </TemplateWizard>
    </Form>
  );
}

export default AddDirector;
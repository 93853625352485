import useLazyQuery from "src/hooks/custom/useLazyQuery";

const GET_DOCUMENTS_LINK_FROM_ACCELERATOR = `
query getAcceleratorDocumentsLink($code: String) {
  getAcceleratorDef(code: $code) {
    documentListCode
  }
}
`;

const useGetAcceleratorDocumentsLink = (variables, config) => {
  const { manualQuery } = useLazyQuery(GET_DOCUMENTS_LINK_FROM_ACCELERATOR, variables, { ...config });
  return manualQuery;
}

export default useGetAcceleratorDocumentsLink;
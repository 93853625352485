import _ from 'lodash';
import React from 'react';
import Icon from 'src/modules/generic/components/Icon';
import classes from './DocumentInvice.module.scss';

const DocumentInvoice = ({
  data,
  handleOpenViewer
}) => {
  const handleOpenPreview = async () => {
    handleOpenViewer(data);
  }

  return (
    <div className={classes.containerDocumentInvoice} onClick={handleOpenPreview}>
      <Icon isClara icon="Dcument-Money" size="1.5rem" />
      <label className={classes.labelInvoice}>{_.get(data, 'name').replace(".pdf", "")}</label>
    </div>
  )
}

export default DocumentInvoice;
import React, {Component} from 'react';
import TextInput from "./TextInput";
import SwitchInput from "./SwitchInput";
import ClaraInputSelect from "../../../components/Form/ClaraInputSelect/ClaraInputSelect";
import DocumentInput from "./DocumentInput";
import {Constants} from "../../../utils/constants";
import "react-datepicker/dist/react-datepicker.css";

class DinamicInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    renderInput(config) {
        switch(config.type) {
            case Constants.INPUT_TYPES.SELECT:
                if(!config.options) config.options = [];
                config = {
                    type: 'SELECT',
                    label: 'Some Label',
                    placeholder: 'Some placeholder',
                    tooltip: 'Some tooltip',
                    options: [
                        {
                            id: 'SOME_ID',
                            label: 'Label'
                        }
                    ]
                }
                return (
                    <ClaraInputSelect
                        label={config.label}
                        field={this.props.field}
                        value={this.props.field.value}
                        form={this.props.form}
                        placeholder={config.placeholder}
                        tooltipText={config.tooltip}
                        updateListFunction={null}
                        callbackUpdate={null}
                        decorator={null}
                        list={config.options}
                        search={true}
                        modalTitle={null}
                        required={config.required}
                    />
                );
            case Constants.INPUT_TYPES.TEXT:
                config = {
                    type: 'TEXT',
                    label: 'Some Label',
                    placeholder: 'Some placeholder',
                    tooltip: 'Some tooltip',
                    options: [
                        {
                            id: 'SOME_ID',
                            label: 'Label'
                        }
                    ],
                    required: true
                }
                return (
                    <TextInput
                        type="text"
                        field={this.props.field}
                        form={this.props.form}
                        placeholder={config.placeholder}
                        label={config.label}
                        tooltip={config.tooltip}
                        required={config.required}
                    />
                );
            case Constants.INPUT_TYPES.DATE:
                config = {
                    type: 'DATE',
                    label: 'Some Label',
                    placeholder: 'Some placeholder',
                    tooltip: 'Some tooltip',
                    options: [
                        {
                            id: 'SOME_ID',
                            label: 'Label'
                        }
                    ]
                }
                return(
                    <TextInput
                        type="date"
                        label={config.label}
                        field={this.props.field}
                        form={this.props.form}
                        placeholder={config.placeholder}
                        prevComponentChange = {e =>
                            this.handleChange(e, this.props.field, this.props.form)
                        }
                    >
                        
                    </TextInput>
                );
            case Constants.INPUT_TYPES.CHECKBOX:
                config = {
                    type: 'CHECKBOX',
                    label: 'Some Label',
                    placeholder: 'Some placeholder',
                    tooltip: 'Some tooltip',
                    options: [
                        {
                            id: 'SOME_ID',
                            label: 'Label'
                        }
                    ],
                    key: 'KEY'
                }
                return(
                    <SwitchInput
                        field={this.props.field}
                        form={this.props.form}
                        id={config.key}
                        label={config.label}
                     />
                );
            case Constants.INPUT_TYPES.FILE:
            config ={
                type: 'FILE',
                    label: 'Some Label',
                placeholder: 'Some placeholder',
                tooltip: 'Some tooltip',
                options: [
                {
                    id: 'SOME_ID',
                    label: 'Label'
                }
            ]
            }
                return(
                    <DocumentInput
                        name={`filepond`}
                        label={config.label}
                        field={this.props.field}
                        form={this.props.form}
                    />
                );
            default:
                return null;
        }
    }

    render() {
        return (
            <div>
                {this.renderInput(this.props.config)}
            </div>
        )
    }
}

export default DinamicInput;

import * as Yup from 'yup';
import _ from 'lodash';
import { useMemo } from "react";

const validateNumber = (schema) =>
  Yup.lazy((value) => {
    if (!value) {
      return Yup.mixed();
    }
    return schema;
  });

export const editShare = Yup.object().shape({
  equity: Yup.object().shape({
    amount: Yup.number().typeError('This field must be a number').required('Only numbers are allowed.'),
    shareClass: Yup.object().required('This field is required').nullable(),
    associatedDocuments: Yup.object(),
    price: validateNumber(Yup.number().typeError('This field must be a number').test(
      "maxDigitsAfterDecimal",
      "number field must have 5 digits after decimal or less",
      (number) => Number.isInteger(number * (10 ** 6))
    )),
  }),
  groupCompany: Yup.object().required('Required').nullable(),
  owner: Yup.object().nullable('Required').required('Required').when('groupCompany', (groupCompany, schema) => {
    return schema.test({
      test: owner => {
        return _.get(owner, 'id') !== _.get(groupCompany, 'id')
      },
      message: "A company cannot own shares of itself. Please select another shareholder."
    })
  }),
  // Yup.object().required('Required').nullable(),
});

const getRemainingShares = (initialValues, shareIncentivePlan) => {
  if (shareIncentivePlan && _.get(initialValues, 'equity.shareIncentivePlan.id', '') === _.get(shareIncentivePlan, 'id', '')) return (_.get(shareIncentivePlan, 'totalShares', 0) - _.get(shareIncentivePlan, 'allocatedShares', 0)) + initialValues.equity.amount;
  return _.get(shareIncentivePlan, 'totalShares', 0) - _.get(shareIncentivePlan, 'allocatedShares', 0);
}

export const useGetEditShareOption = (initialValues) => {
  return useMemo(() => Yup.object().shape({
    equity: Yup.object().shape({
      amount: Yup.number().typeError('This field must be a number')
        .required('Only numbers are allowed.'),
      price: validateNumber(Yup.number().typeError('This field must be a number')),
      shareClass: Yup.object().required('This field is required').nullable(),
      associatedDocuments: Yup.object(),
    }).test('Remaining Shares must be less amount',
    null,
    (obj) => {
      if (obj === undefined) return true;
        if (_.get(obj.shareIncentivePlan, 'totalShares', null) === null) return true;
        const remainingShares = getRemainingShares(initialValues, obj.shareIncentivePlan)
        if (obj.amount > remainingShares) {
          return new Yup.ValidationError(
            `There are only ${(getRemainingShares(initialValues, obj.shareIncentivePlan) < 0 ? 0 : new Intl.NumberFormat("en-US").format(getRemainingShares(initialValues, obj.shareIncentivePlan)))} unallocated shares available to use. `,
            null,
            `equity.amount`
          );
        }
        return true;
      }
    ),
    // groupCompany: Yup.object().required('Required').nullable().shape({
    //     id:Yup.string().nullable().required('Required')
    // }),
    groupCompany: Yup.object().required('Required').nullable(),
    owner: Yup.object().required('Required').nullable(),
  }), [JSON.stringify(initialValues)]);
}

export const editConvertible = Yup.object().shape({
  convertible: Yup.object().shape({
    amount: Yup.number().typeError('This field must be a number').required('Only numbers are allowed.'),
    discountRate: validateNumber(Yup.number().typeError('This field must be a number').min(0).max(100, 'Only numbers are allowed between 0 and 100.')),
    interestRate: validateNumber(Yup.number().typeError('This field must be a number').min(0).max(100, 'Only numbers are allowed between 0 and 100.')),
    valuationCap: validateNumber(Yup.number().typeError('This field must be a number')),
    associatedDocuments: Yup.string(),
  }),
  groupCompany: Yup.object().required('Required').nullable(),
  owner: Yup.object().required('Required').nullable(),
});

export const editWarrant = Yup.object().shape({
  warrant: Yup.object().shape({
    id: Yup.string().required(),
    amount: Yup.number().typeError('This field must be a number').required('Only numbers are allowed.'),
    valuation: validateNumber(Yup.number().typeError('This field must be a number')),
    associatedDocuments: Yup.string(),
  }),
  owner: Yup.object().required('Required').nullable(),
  groupCompany: Yup.object().required('Required').nullable(),
});

export const addWarrant = Yup.object().shape({
  warrant: Yup.object().shape({
    amount: Yup.number().typeError('This field must be a number').required('Only numbers are allowed.'),
    valuation: validateNumber(Yup.number().typeError('This field must be a number')),
    associatedDocuments: Yup.string(),
  }),
  owner: Yup.object().required('Required').nullable(),
  groupCompany: Yup.object().required('Required').nullable(),
});
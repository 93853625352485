import React from 'react'
import cls from './MissingInformation.module.scss';
import classNames from 'classnames'


interface MissingInformationProps {
    icon?: React.ComponentType;
    title?: string;
    subtitle?: string;
    className?: string;
}



const MissingInformation: React.FC<MissingInformationProps> = ({ icon: Icon, title, subtitle,className }) => {


    return (
        <div className={`${cls.MissingInformation} ${className}`}>
            <div className={classNames(cls.Icon)}><Icon /></div>
            <p className={cls.Title}>{title}</p>
            <p className={cls.SubTitle}>{subtitle}</p>

        </div>
    )
}

export default MissingInformation;

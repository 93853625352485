import { useDispatch } from "react-redux";
import useSession from '../../../../modules/session/hooks/useSession';
import useMutation from "../../../custom/useMutation";
// import { updateLastHealthCheckRun } from "./../../../../store/actions/session";
const mutation = `mutation doAHealthCheck($startupId: String! ){
  doAHealthCheck(
    startupId: $startupId,
      ) 
  } `
const useDoAHealthCheck = (options = {}) => {
  const dispatch = useDispatch();
  const { startup } = useSession();
  const completeHealthCheck = (data)=>{
    // updateLastHealthCheckRun(dispatch,startup.id,data.doAHealthCheck)
    if (options.onCompleted){
      options.onCompleted(data);
    }
    
  }
  const [send, data] = useMutation(mutation, {...options,onCompleted: completeHealthCheck}, {fetchPolicy:'no-cache'});


  return [send, data];
}
export default useDoAHealthCheck;

const useGetDocumentTypesLogic = ()=>{
    const onlyForNewDocumentGeneration = [
        'POST_MONEY_VALUATION_CAP',
        'POST_MONEY_VALUATION_CAP_USA',
        'SAFE_DISCOUNT_ONLY_USA',
        'SAFE_MFN_ONLY_USA',
        'POST_MONEY_VALUATION_CAP_SINGAPORE',
        'PRO_RATA_SIDE_LETTER_USA',
        'PRO_RATA_SIDE_LETTER_CAYMAN',
        'PRO_RATA_SIDE_LETTER_SINGAPORE'
    ]
    const parentOfDocType = {
        GRANT_AGREEMENT:"SHARE_INCENTIVE_PLAN",
        USA_BOARD_APPROVING_OPTION_GRANT:'USA_EQUITY_INCENTIVE_PLAN',
        USA_OPTION_GRANT_NOTICE:"USA_BOARD_APPROVING_OPTION_GRANT"
      }
    const getParentDocumentType =(docType:string):string=>{
     return parentOfDocType[docType]
    }
    const getFamilyDocumentType =(docType:string):string[]=>{
        let docParent = parentOfDocType[docType]
        const family = []
        while(docParent){
            family.push(docParent)
            docParent = parentOfDocType[docParent]
        }
        return family
    }
    const isOnlyForNewDocumentGeneration = (code)=>onlyForNewDocumentGeneration.some(docType=>docType===code)
    return { getParentDocumentType, getFamilyDocumentType, isOnlyForNewDocumentGeneration }
}
export default useGetDocumentTypesLogic
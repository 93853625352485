import React, { FC } from "react";
import InFormationCard from "../../../AssistanceCards/InFormationCard";
import AssistanceContents from "../../../AssistanceContents";
import TabBubblesContainer from "../../../TabBubblesContainer";
export interface DocumentUploadTasksProps {
  
}

const DocumentUploadTasks: FC<DocumentUploadTasksProps> = ({ 
  
  entity,
  companiesCorporateData = "",
  ...props 
}: DocumentUploadTasksProps) => {

  return (
    <AssistanceContents>
      <TabBubblesContainer>
        <InFormationCard type="document" />
      </TabBubblesContainer>
    </AssistanceContents>
  );
};

export default DocumentUploadTasks;

import React, { FC, useRef } from 'react'
import _ from 'lodash'
// import useTranslate from "../../../../../generic/hooks/useTranslate";
import Form from "../../../../../../components/forms/Form/Form";
import ControllerInput from "../../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import SubmitButton from "../../../../../generic/components/Buttons/SubmitButton";
import TemplateFormDefault from "../../../../../generic/templates/Modal";
import FormProps from "../../../../../generic/forms/interfaces/FormProps";
import { CheckboxButtonOptions } from "../../../../../generic/components/Inputs/CheckboxButton";
import schema from './schema'
import CheckboxButtonSimple from "../../../../../generic/components/Inputs/CheckboxButtonSimple";
import { addAssistantText, clearAssistatText } from "../../../../../generic/store/action";
import { useDispatch } from "react-redux";
import classes from './classes.module.scss'
import Typography from '../../../../../generic/components/Texts/Typography';

const AreThereMoreEntity: FC<FormProps> = ({
  initialValues,
  tooltips,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate
}: FormProps) => {

  // const { translate } = useTranslate();
  const dispatch = useDispatch();
  const refForm = useRef();

  const options: Array<CheckboxButtonOptions<boolean, string>> = [
    {
      value: false,
      label: 'No, not right now'
    },
    {
      value: true,
      label: 'Yes, add anyway'
    }
  ]
  
  return (
    <React.Fragment>
      <Form
        initialValues={initialValues || {}}
        schema={schema}
        onSubmit={onCompleteSubmit}
        ref={refForm} optionsForm={{ mode: "onChange" }}
      >
        <Template props={propsTemplate} buttons={{ submit: <SubmitButton{...buttonSubmitProps}>Next</SubmitButton>, ...buttons }}>
          <Typography variant='s'>
            Adding a corporate stakeholder means you can only form this company using Clara’s concierge service. Would you like to add this stakeholder anyway?
          </Typography>
          <ControllerInput
            as={CheckboxButtonSimple}
            name='enable_clara_concierge'
            variant='col_1'
            classNameButton={classes.buttonCheckBox}
            options={options}
            onChange={value => {
              if (tooltips) {
                dispatch(clearAssistatText());
                if (value) {
                  _.get(tooltips, 'isAnother') && dispatch(addAssistantText(_.get(tooltips, 'isAnother', "text")));
                } else {
                  _.get(tooltips, 'isNotAnother') && dispatch(addAssistantText(_.get(tooltips, 'isNotAnother', "text")));
                }
              }
            }}
          />
          <Typography weight={"bold"} variant='h5'>
            What is Clara’s concierge service?
          </Typography>
          <Typography color='dark-grey' variant='s'>
            Clara’s concierge service provides tailored formation services for companies with corporate stakeholders. Once you’ve completed your Essentials, engage the concierge to send your formation for review.
          </Typography>
        </Template>
      </Form>
    </React.Fragment>
  )
}

export default AreThereMoreEntity

export const actions = {
    SET_SELECTED_COMPANY: "SET_SELECTED_COMPANY"
};

export const setSelectedCompany = (dispatch, id, name, enabled) => {
    dispatch({
        type: actions.SET_SELECTED_COMPANY,
		selectedCompany: {
			id,
			name,
			enabled
		}
    })
};

import _ from "lodash";
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import EditSubscriptionForm from "../../../../../../components/forms/modules/startups/startup/EditSubscriptionForm/EditSubscriptionForm";
import useTextLang from "../../../../../../hooks/custom/useTextLang";
import useEditSubscription from "../../../../../../modules/checkout/hooks/useEditSubscription";
import useSession from "../../../../../../modules/session/hooks/useSession";
import { Modal } from '../../../../../../v1/components';
import { Constants } from "../../../../../../v1/utils";
const EditSubscription = ({ cards,callbackUpdate,open,handleCloseModal:handleCloseModalProp,...props }) => {
    
    const dispatch = useDispatch();
    
    const { startup:startupLanding } = useSession();
    

    const [showDelete, setShowDelete] = useState(false);

    useEffect(() => {

    }, [])
   

    const params = useParams();


    const handleCloseModal = () => {

        
        handleCloseModalProp && handleCloseModalProp();
    }

    const handleFinishUpdate = (values) => {
        handleCloseModal();
        callbackUpdate && callbackUpdate(values)
    }

    const handleSubmit = async (values) => {
        await updateStartup({
            startupId:startupLanding.id ,
            subscriptionData:{
                paymentMethod: _.get(values,"card")
            }
        })
        handleCloseModal();
    }

    const overlayName = useTextLang("EDIT_SUBSCRIPTION_MODAL_TITLE");
    const [updateStartup] = useEditSubscription({
        onCompleted: handleFinishUpdate ,
        successMessage: "NOTIFICATION_OVERLAY_SAVE_SUCCESS",
        paramsSuccessMessage: {overlayName},
    });
    return (
        <>
            <Modal
                open={open } handleClose={() => handleCloseModal()} showHeader={false}
                overlayName={overlayName}
                >
                    <EditSubscriptionForm cards={cards} handleCloseModal={handleCloseModal}  handleSubmit={handleSubmit} />
            </Modal>
        </>

    )
};





export default EditSubscription;

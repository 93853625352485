import { useMemo } from 'react';

const useGuards = () => {
  return useMemo(() => ({
    between0and50k: (context) => {
      // TODO IF the company is an Exempt Company with Authorised Capital between USD 0 and USD 50,000 (majority of Clara’s clients)
      return true
    },
    greater50k: (context) => {
      // TODO NEDD EVALUATE IF the company is an Exempt Company with Authorised Capital greater than USD 50,000
      return false;
    },
  }), []);
}

export default useGuards;

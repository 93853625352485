import { get } from 'lodash';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Button from '../../../../../generic/components/Buttons/Button';
import { StepProps } from "../../../../../generic/components/Wizard/Step";
import useCustomMachine from '../../../../../generic/hooks/useCustomMachine';
import { addAssistantText, clearAssistatText } from '../../../../../generic/store/action';
import TemplateWizard from '../../../../../generic/templates/Modal/TemplateWizard';
import useSession from '../../../../../session/hooks/useSession';
import Form from "../../components/Forms/FormVisaExpiryDate";
import useAddDocumentFormation from "../../../../../../hooks/services/modules/individualStakeholder/useAddDocumentFormation";

const Step2: FC<StepProps> = () => {
  const { next, send, state } = useCustomMachine();
  const [updateDocument] = useAddDocumentFormation();
  const { id: _id } = useParams<any>()
  const id = state?.context?.data.stakeholderId ?? _id;
  const { startupId } = useSession();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(addAssistantText(`Some regions require additional national identification.`));
    dispatch(addAssistantText(`Upload two seperate scans of the front and back of this stakeholder's GCC ID.`));
    return () => {
      dispatch(clearAssistatText());
    }
  }, [])


  const handleCompleteSubmit = async values => {
    const variables = {
      stakeholderId: id,
      startupId,
      identityDocumentData: { ...values, "type": "GCC_RESIDENT_ID_DOCUMENT" }
    }
    await updateDocument({ variables });
    next(values);
  }

  const handleCancel = async values => {
    send("CANCEL");
  }

  return (
    <React.Fragment>
      <Form
        initialValues={get(state, 'context.data.upload_file', {})}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: 'Add their GCC Resident ID',
          subTitle: 'GCC Resident ID'
        }}
        buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }}>
      </Form>
    </React.Fragment>
  )
}

export default Step2;

import React, { FC } from "react";
import { DeleteButton } from "src/components/generic/Buttons/DeleteButton";
import { ModalProps } from "src/modules/generic/components/Modal/Default";
import useModal from "src/modules/generic/hooks/useModal";
import TemplateDelete from "src/modules/generic/templates/Modal/TemplateDelete";
import { StakeholderBO } from "src/modules/startup/core/type/startup/stakeholder";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";

interface ManagedByClaraCompanyProps {
}

const ManagedByClaraCompany: FC<ManagedByClaraCompanyProps> = () => {

  const { context, next } = useMachine();

  const handleCancel = () => {
    next()
  }

  return (
<TemplateDelete buttons={{ submit:<DeleteButton onClick={handleCancel}>Acknowledge</DeleteButton>}} props={{
    title:"It’s not possible to delete this company right now",
    description:context?.deleteVerification?.ableToDelete?.reasonText
}}>
    <></>
</TemplateDelete>
      
  )
}

export default ManagedByClaraCompany
import * as Yup from 'yup';

import { default as React, useEffect, useMemo, useState } from 'react';
import { addAssistantText, clearAssistatText } from 'src/modules/generic/store/action';

import Button from 'src/modules/generic/components/Buttons/Button';
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import Form from 'src/components/forms/Form/Form';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateLabel from 'src/modules/generic/components/Inputs/LabelInput';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import TextAreaInput from "src/components/inputs/Textarea/TextareaInput";
import classes from './classes.module.scss';
import { useDispatch } from 'react-redux';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';

export default function ReviewPersonalDetailPEPStepForm() {

  const { next, context, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues =  context?.ReviewPersonalDetailPEPStepForm || context?.initialData.PEP

  const [isPEP, setIsPEP] = useState(context?.initialData.isPEP ?? false);

  const directorName = context?.initialData.stakeholder.fullName;

  const handleCancel = (values) => {
    cancel();
  }

  const handlePrev = (values) => {
    prev();
  }

  const handleSubmit = async (values) => {
    next(values)
  }

  useEffect(() => {
    // TODO LINK
    dispatch(addAssistantText("Click <a href=\"https://help.clara.co/en/articles/6394611-what-is-a-pep\" target=\"_blank\">here</a> to learn more about who qualifies as a PEP, a family member or a close associate of a PEP."))
    return () => {
      dispatch(clearAssistatText())
    }
  }, [dispatch])

  const options = [
    {
      value: false,
      label: "No, they aren't",
    },
    {
      value: true,
      label: "Yes, they are",
    },
  ];
  
  const labelLines = [
    "Is this stakeholder a Politically Exposed Person (PEP), a family member or a close associate of a PEP?",
  ];

  const schema = Yup.object().shape({
    isPEP: Yup.boolean()
      .required("This field is required")
      .nullable(),
    descriptionOfPEP: Yup.lazy(() => {
      if (isPEP) return Yup.string().required("This field is required");
      return Yup.string().nullable();
    })
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: 'Review and confirm PEP',
          skeleton: null,
          subTitle: 'Review details for ' + directorName,
          variant: 'simple'
        }}
        buttons={{
          previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('BUTTON_NEXT')}</SubmitButton>,
        }}
      >
        <TemplateLabel
          isRequired={true}
          renderPosition="row"
          multilineTexts={labelLines}
        >
          <ControllerInput
            render={CheckboxButtonSimple}
            name="isPEP"
            size="size1"
            options={options}
            classNameButton={classes.heightCheckboxButtonSimple}
            onChange={(value) => setIsPEP(value)}
          />
        </TemplateLabel>
        {isPEP && (
          <TemplateLabel
            isRequired={true}
            renderPosition="row"
            multilineTexts={["What makes this stakeholder a PEP?"]}
          >
            <ControllerInput
              render={TextAreaInput}
              name="descriptionOfPEP"
              placeholder="Type here"
              rows={8}
            />
          </TemplateLabel>
        )}
        <div>&nbsp;</div>
      </TemplateWizard>
    </Form>
  );
}


import MachineStakeholder from "../../core/wizards/machine/MachineStakeholder";
import FirstStep from "./steps/FirstStep";
import LastStep from "./steps/LastStep";
import IndividualOrCompanyStep from "./steps/IndividualOrCompanyStep";
import IndividualOrCompanyStepDirectorForm from "./steps/IndividualOrCompanyStepForm";
import StepClaraConcierge from "./steps/StepClaraConcierge";
import StepClaraConciergeDirector from './steps/StepClaraConciergeDirector';

export default class MachineDirector extends MachineStakeholder {
  constructor() {
    super(
      "director",
      FirstStep,
      LastStep,
      IndividualOrCompanyStep,
      IndividualOrCompanyStepDirectorForm,
      StepClaraConcierge,
      StepClaraConciergeDirector
    );
  }
}

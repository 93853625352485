import React from 'react';
import {LeftEducation} from '../../components';

import classesModal from "./LayoutForm.module.scss"
import PropTypes from 'prop-types';
import _ from "lodash";

const LayoutBlueScreen =({ children,title,leftEducationId,handleClose,classes })=> {

  return (
    <React.Fragment>
      <div className={`${classesModal.LayoutForm}`}>
        <div className={classesModal.LeftPanel}>
          <div className={classesModal.IconClara}>
            <img src='/img/claraIconLogin.svg' alt='Clara' />
            <div className={classesModal.LeftEducation}>
              {leftEducationId && <LeftEducation leftEducationId={leftEducationId} />}
            </div>
          </div>
        </div>
        <div className={`${classesModal.RightPanel} ${_.get(classes,"rightPanel")} scrollbar` }>
          <a className={classesModal.CloseIcon} onClick={handleClose}>
            <i className={`icon-x_symbol`} />
          </a>
            {children}
        </div>
      </div>
    </React.Fragment>
  );
}
LayoutBlueScreen.propTypes = {
  children: PropTypes.element.isRequired, /* Componentn to show */
  leftEducationId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired
};
export default LayoutBlueScreen;
import { Menu, MenuItem, Stack, Typography, Button as MuiButton } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

/**
 * Filter menu component
 */
function FilterMenu({ 
  options,
  disabled,
  icon,
  defaultValueIndex,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  /**
   * Handle button click
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  /**
   * Handle menu close
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  const optionsToShow = useMemo(() => options.filter((option) => !option.conditionToHide), [options]);
  const [label, setLabel] = useState(optionsToShow[defaultValueIndex]?.label || '');

  return (
    <>
      <MuiButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        color={'primary'}
        onClick={handleClick}
        variant="text"
        disableElevation
        disabled={disabled}
        sx={{
          textTransform: 'none !important',
          paddingRight: "16px",
          border: disabled ? `1px solid #e2e2e2 !important` : `1px solid #eaf1fe !important`,
          '&:hover': {
            backgroundColor: '#eaf1fe',
          }
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%" sx={{ gap: '0.6em' }}>
          {icon}
          <Typography sx={{ color: "#192a3e" }}>{label}</Typography>
        </Stack>
      </MuiButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        sx={{
          ul: {
            padding: "5px !important",
            borderRadius: "6px !important",
            border: "1px solid #eaf1fe !important",
            marginTop: "2px !important",
          }
        }}
      >
        {_.map(optionsToShow, (option, index) => (
          <MenuItem
            sx={{
              display: 'flex !important',
              paddingLeft: '16px !important',
              paddingRight: '16px !important',
              justifyContent: 'flex-start !important',
              fontSize: '13px !important',
              fontFamily: 'cl-regular',
              fontWeight: 400,
              paddingTop: '4px !important',
              paddingBottom: '4px !important',
              minHeight: '32px !important',
              borderRadius: '4px !important',
              '&:hover': {
                backgroundColor: '#eaf1fe !important',
                color: "#2c74ff !important"
              },
              '& .MuiTouchRipple-root': {
                color: "#2c74ff !important"
              }
            }}
            key={`option--${index}`}
            onClick={(e) => {
              handleClose();
              setLabel(option?.label);
              if (option?.onClick) option?.onClick();
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <Typography variant="body1">{option?.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

FilterMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
      onClick: PropTypes.func.isRequired
    })
  ),
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  defaultValueIndex: PropTypes.number,
};

FilterMenu.defaultProps = {
  disabled: false,
  defaultValueIndex: 0,
};

export default FilterMenu;

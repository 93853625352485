import React, { FC } from "react";
import classesModule from './TagsUnLocked.module.scss';
import Icon from '../../../generic/components/Icon';

/**
 * Scale tag
 */

export interface TagsUnLockedProps {
  renderItem?: string,
  color?: string,
  key?: number,
  icon?: string
}

const TagsUnLocked: FC<TagsUnLockedProps> = ({ renderItem, color, icon }: TagsUnLockedProps) => {

  return (
    <>
      {icon ? (
        <div style={{ background: `${color}` }} className={classesModule.tagsOnly} >
          <Icon isClara color={'#FFFFFF'} size={"1.0769rem"} icon={`${icon}`} />
          <span>
            {renderItem}
          </span>
        </div>
      ) : (
        <div style={{ background: `${color}` }} className={classesModule.tagsOnlyText} >
          <span>
            {renderItem}
          </span>
        </div>
      )}
    </>
  )
};

export default TagsUnLocked;
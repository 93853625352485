import React, { useMemo } from 'react';
import Icon from '../../../../../../../../modules/generic/components/Icon'
import classes from './Styles.module.scss';
import { PopoverOption } from '../../../../../../../../v1/components';
import PopperClara from '../../../../../../../generic/PopperClara/PopperClara';

import _ from 'lodash'
import { useDispatch } from 'react-redux';
import { Constants, ServerConnect } from '../../../../../../../../v1/utils';
const SubcategoryHeader = ({ data, className, typesList, handler, handleOpenViewer, ...props }) => {

    const dispatch = useDispatch()

    const optionsSubcategories = useMemo(() => {
        let opts = []
        opts = _.map(typesList, cat => {
            return ({
                label: cat.label,
                onClick: () => handler(cat.id, 'SUBCATEGORY')
            })
        })
        return opts

    }, [])


    return (
        <div className={`${classes.containerDocumentName} ${optionsSubcategories && optionsSubcategories.length == 0 ? classes.notClickable : null} ${className} ${classes.documentTabFilterHeader}`}>


           {optionsSubcategories && optionsSubcategories.length !== 0 ? <PopoverOption elevation={0} classicStyle={true} renderElement={SubcategoryOptions} propsRenderElement={{ options: optionsSubcategories }}>
                <span><label>Subcategory</label><Icon isClara icon={'Dropdown'} size={'1rem'} /></span>
            </PopoverOption> :  <span><label>Subcategory</label></span>}
        </div>

    )
}

export default SubcategoryHeader


const SubcategoryOptions = ({ options = [], closePopover, ...props }) => {

    const handleClose = ({ onClick }) => {
        onClick();
        closePopover()
    }

    return (
        <div className={classes.containerMenuOptionDocument}>
            {options && options.map((option, index) => {
                return (
                    <div key={`${JSON.stringify(option)}-${index}`} className={classes.containerOption} onClick={() => handleClose(option)}>
                        {option.label}
                    </div>
                )
            })
            }

        </div>
    )

} 

export default {
    COMPANY_DETAILS: 'companyDetails',
    DIRECTORS: 'directors',
    DIRECTORS_CATEGORY: 'directorsCategory',
    SHAREHOLDERS: 'shareholders',
    SHAREHOLDEREDITABLE: 'shareholderEditable',
    SIGNATORIES: 'signatories',
    SIGNATORIES_CATEGORY: 'signatoriesCategory',
    AGREEMENTSDATA: 'agreementsData',
    BENEFICIAL_OWNERS_CATEGORY: 'beneficialOwnersCategory',
    BENEFICIAL_OWNERS: 'beneficialOwners',
    DATA_CONTROLLER: 'dataController',
    DATA_CONTROLLER_ENTITY: 'dataControllerEntity',
    AGREEMENTSDATA_ENTITY: 'agreementsDataEntity',
    BANKING_SERVICES: 'bankingServices',
    SHARESDATA: 'sharesData',
    INTELLECTUAL_PROPERTY: 'INTELLECTUAL_PROPERTY',
    SHARES_ALREADY_COMPANY: 'SHARES_ALREADY_COMPANY',
    SHARES_COMPANY: 'SHARES_COMPANY',
    OTHER: 'OTHER',
    JOINTLY: 'JOINTLY',
    SINGLY: 'SINGLY',
    CUSTOM: 'CUSTOM',
    DEFAULT: 'DEFAULT',
    NAS:'NAS'

};
import React, { forwardRef } from "react";
import Loading from "../../generic/Loading/Loading";
import classes from "./Form.module.scss";
import FormL from "./FormL";
export interface FormProps {
  /**@deprecated: use defaultValues, to initialize the form and use ref with ref.current.reset(newValue) to asign new values to the form.  */
  initialValues?: any;
  defaultValues?: any;
  loading?: boolean;
  loadingComponent?: React.ReactElement;
  onlySendDirty?: boolean;
  className?: string;
  [key: string]: any;
}
const Form: React.FC<FormProps> = forwardRef(
  ({ loading, loadingComponent, onlySendDirty, ...props }: FormProps, ref) => {
    if (loading) {
      return (
        <div className={classes.LoadingContent}>
          {loadingComponent ? (
            loadingComponent
          ) : (
            <Loading showChildren={false} loading={true}/>
          )}
        </div>
      );
    } else {
      return <FormL onlySendDirty={onlySendDirty} {...props} ref={ref}></FormL>;
    }
  }
);

export default Form;

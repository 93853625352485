import React, { FC } from "react";
import classes from "./classes.module.scss";
import Icon from "../../../generic/components/Icon";
import classnames from "classnames";

export enum colorTypes{
  academy = "academy",
  blue = "blue",
  success = "success",
  pay = "pay",
  upgrade = "upgrade",
};

export interface DialogItemProps {
  /** Property children */
  children?: React.ReactNode,
  /** Property iconType */
  iconType?: string,
  /** Property color */
  color?: colorTypes,
  /** Property disabled */
  disabled?: boolean,
  /** Property noIcon */
  noIcon: boolean,
  /** Property noTopMargin */
  noTopMargin: boolean,
  /** Property alert */
  alert?: boolean,
} 

const DialogItem: FC<DialogItemProps> = ({
  children,
  iconType = "Assistant",
  color = colorTypes.blue,
  disabled = false,
  noIcon = false,
  noTopMargin= false,
  alert,
  ...props
}:DialogItemProps) => {

  return (
    <div className={classnames(classes.DialogContainer)}>
      <div className={classnames(classes.DialogIconContainer)}>
        <div
          className={classnames(`${classes.Icon} ${classes[color]}`, {
            [classes.IconDisabled]: disabled,
            [classes.IconAlert]: alert,
          })}
        >
          {!disabled && (
            !noIcon && <Icon icon={iconType} size={"100%"} isClara={true} />
          )}
        </div>
      </div>
      <div 
        className={classnames(`${classes.DialogWithBtn}`, {
          [classes.addTopMargin]: !noTopMargin,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default DialogItem;

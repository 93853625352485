import _ from 'lodash';
import useMachine from '../../../../../generic/context/MachineContext/useMachine';
import SelectEquityForm from '../../../forms/SelectEquityForm';
import React, { useEffect, useState } from 'react';
import Button from "../../../../../generic/components/Buttons/Button";
import useTranslate from '../../../../../generic/hooks/useTranslate';
import { useDispatch } from "react-redux";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import { addAssistantText, clearAssistatText } from "../../../../../generic/store/action";
import useModal from "../../../../../generic/hooks/useModal";

function SelectEquityType({ openParam }) {

  const { next, send, context, prev } = useMachine();
  const { translate } = useTranslate()
  const dispatch = useDispatch();
  const { closeModal: onClose } = useModal();
  const [open, setOpen] = useState(openParam);

  const handleCancel = (values) => {
    onClose();
    setOpen(false);
    clearAssistatText()
  }

  useEffect(() => {
    setOpen(openParam);
  }, [openParam]);

  const handleInitModal = () => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText("If this stakeholder has multiple equity positions, here you’ll be able to add them one after the other. You will also be asked to upload the related documents or generate them with Clara if they are new equity positions."));
    return () => {
      dispatch(clearAssistatText());
    }
  }

  useEffect(() => {
    handleInitModal()
  }, []);

  const handleCompleteSubmit = async (values) => {
    next(values)
  }

  return (
    <SelectEquityForm
      initialValues={{}}
      buttons={{
        cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
      }}
      onCompleteSubmit={handleCompleteSubmit}
      propsTemplate={{
        title: translate('MODULES.EQUITY.ADD_EQUITY.STEP_1.TITLE'),
        subTitle: translate('MODULES.EQUITY.ADD_EQUITY.STEP_1.SUBTITLE')
      }} 
      Template={TemplateWizard}
    />
  );
}
export default SelectEquityType;

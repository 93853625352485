import React from "react";
import * as Yup from "yup";
import Form from "../../../../components/forms/Form/Form";
import useTranslate from "../../../generic/hooks/useTranslate";
import SubmitButton from "../../../generic/components/Buttons/SubmitButton";
import FormProps from "../../../generic/forms/interfaces/FormProps";
import TemplateFormDefault from "../../../generic/templates/Modal";
import Typography from "../../../../modules/generic/components/Texts/Typography";
import ControllerInput from "../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import TextareaInput from "../../../../components/inputs/Textarea/TextareaInput";
import classes from './Form.module.scss'

const schema = Yup.object().shape({
  declineReason: Yup.string()
    .required("This field is required")
    .nullable(),
});

const StepCompanyInfo: React.FC<FormProps> = ({
  initialValues = {},
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
}: FormProps) => {

  const { translate } = useTranslate();
  const refForm = React.useRef(null);

  const handleSubmit = async (values) => {
    onCompleteSubmit && (await onCompleteSubmit(values));
  };

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onSubmit={handleSubmit}
      ref={refForm}
      optionsForm={{ mode: "onChange" }}
    >
      <Template
        buttons={{
          ...buttons,
          submit: <SubmitButton {...buttonSubmitProps}>{translate('MODULES.GROUP_COMPANY.DOCUMENT_REVIEW.SECOND_STEP.DECLINE_BTN')}</SubmitButton>,
        }}
        props={propsTemplate}
      >
        <Typography>
          {translate('MODULES.GROUP_COMPANY.DOCUMENT_REVIEW.SECOND_STEP.DECLINE_HIT')}
        </Typography>
        <ControllerInput
          render={TextareaInput}
          defaultValue={""}
          className={classes.TextAreaBig}
          name="declineReason"
          onChange={(e) => {}}
          placeholder={translate('MODULES.CLARA.TYPE_HERE_HIT')}
        />
      </Template>
    </Form>
  );
};

export default StepCompanyInfo;

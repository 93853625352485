import _ from "lodash";

const getDataByDetails = (type, initialValues, values, startupId, groupCompany) => {
	switch (type) {
		case "companyDetails":
			case "recipientNoticeDetails":
				const companyOtherJurisdiction = values.company.otherJurisdiction;
				delete values.company.otherJurisdiction;
				// delete values.company.verified1;
			// delete values.company.verified2;
			return {
				startupId,
				groupCompanyId: initialValues.company.id,
				groupCompany: {
					name: values.company.name,
					isHoldingCompany: values.company.isHoldingCompany,
					type:groupCompany.managedByClara?groupCompany.type:values.company.type,
					registeredNumber: values.company.registeredNumber,
					country:typeof _.get(values.company, 'country') ==='object'
						? _.get(values.company, 'country.code')
						: values.company.country,
					jurisdiction: values.company.jurisdiction === "OTHER"
						? companyOtherJurisdiction
						: values.company.jurisdiction,
					registeredAddress: {
						...values.company.registeredAddress,
						country: !_.isEmpty(_.get(values.company, 'registeredAddress.country.code', []))
							? _.get(values.company, 'registeredAddress.country.code')
							: _.get(values.company, 'registeredAddress.country')
					},
					recipientNotice: {
						...values.company.recipientNotice,
						address: {
							..._.get(values.company.recipientNotice, 'address', {}),
							country: !_.isEmpty(_.get(values.company.recipientNotice, 'address.country.code'))
								? _.get(values.company.recipientNotice, 'address.country.code','')
								: _.get(values.company, 'recipientNotice.address.country','')
						}
					}
				}
			};
		case "stakeholderDetails":
			const stakeholderOtherJurisdiction = values.stakeholder.otherJurisdiction;
			delete values.stakeholder.otherJurisdiction;
			return {
				startupId,
				stakeholderId: initialValues.stakeholder.id,
				stakeholder: {
					fullName: values.stakeholder.fullName,
					passportNumber: values.stakeholder.passportNumber,
					phoneNumber: values.stakeholder.phoneNumber,
					email: values.stakeholder.isAnEntity
						? values.stakeholder.authorizedSignatory.email
						: values.stakeholder.email,
					nationality: !_.isEmpty(_.get(values.stakeholder, 'nationality.code', []))
						? values.stakeholder.nationality.code
						: values.stakeholder.nationality,
					jurisdiction: values.stakeholder.jurisdiction === "OTHER"
						? stakeholderOtherJurisdiction
						: values.stakeholder.jurisdiction,
					address: {
						...values.stakeholder.address,
						country: !_.isEmpty(_.get(values.stakeholder, 'address.country.code', []))
							? values.stakeholder.address.country.code
							: values.stakeholder.address.country
					}
				}
			};
		case "stakeholderRoleAndServices": {
			// console.log('useGetDataByDetails->initialValues.stakeholder.position', initialValues.stakeholder.position);
			// console.log('useGetDataByDetails->values.stakeholder.position', values.stakeholder.position);

			// TODO return and object with a property updated, to perform mutation if this property if true
			// this property will validate if position isDefault is false and responsibilities text change

			let position;
			if (initialValues.stakeholder.position) {
				position = {
					..._.pick(values.stakeholder.position, ['name', 'id', 'isDefault', 'code']),
					responsibilities: values.stakeholder.position.isDefault ?
						_.map(values.stakeholder.position.responsibilities, responsibility => {
							if (responsibility) {
								return {
									..._.omit(responsibility, ['name', 'otherResponsibility']),
									name: responsibility.isDefault ? responsibility.text : values.stakeholder.position.otherResponsibility,
									text: responsibility.isDefault ? responsibility.text : values.stakeholder.position.otherResponsibility
								}
							} else {
								return null;
							}
						}) :
						[{
							code: _.get(values.stakeholder.position,'otherResponsibilitiesCode',null),
							name: values.stakeholder.position.otherResponsibilities,
							text: values.stakeholder.position.otherResponsibilities
						}]
				}
			}

			return {
				startupId,
				position
			}
		}
	}
};

export default getDataByDetails;

import React, { useEffect } from 'react';
import Button from 'src/modules/generic/components/Buttons/Button';
import Typography from 'src/modules/generic/components/Texts/Typography';
import TemplateDelete from 'src/modules/generic/templates/Modal/TemplateDelete';
import classModule from '../ChooseDocumentsView.module.scss';
import ModalDelete from 'src/modules/generic/components/Modal/ModalDelete';
import useModal from 'src/modules/generic/hooks/useModal';

const CustomTemplateWarningModal = ({ description, open }) => {
    const [isOpen, setOpen] = React.useState(open);
    const {closeModal} = useModal();
    useEffect(()=>{
        setOpen(open)
    },[open])
    const handleClose = () => {
        closeModal()
    }
    return ( 
        <ModalDelete open={isOpen}>
        <TemplateDelete
      className={classModule.template}
      props={{
        title: 'Cannot delete this template.',
        description: <Typography component='p' className={classModule.templateText} color='error'>{description}</Typography>,
      }}
      buttons={{
        submit: <Button variant='submitDelete' onClick={handleClose}>Acknowledge</Button>,
      }}
    ><></></TemplateDelete>
    </ModalDelete>
    );
    }

export default CustomTemplateWarningModal
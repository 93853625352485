import React, { useState } from "react";
import useTranslate from "../../../generic/hooks/useTranslate";
import TemplateWarningModal from "../../../../modules/generic/templates/Modal/TemplateWarningModal";
import SubmitButton from '../../../../components/generic/Buttons/SubmitButton/SubmitButton';
import Form from '../../../../components/forms/Form/FormL';
import * as Yup from 'yup';
import Modal from '../../../generic/components/Modal/Default'
import cls from './HowToUse.module.scss';
import './classes.scss';
import classnames from "classnames";
import Lottie from "react-lottie";
import initOnbording from "../../../../modules/Onboarding/images/LottieElement/InitOnbording.json";
import TypographyLocal from "../../../generic/components/Texts/Typography";
import Link from "../../../../components/text/Link/Link";
import Text from "../../../../components/text/Text/Text";

const schema = Yup.object().shape({

});

const ModalHowToUse = ({
  ...props
}) => {

  const [open, setOpen] = useState(true);
  const { translate } = useTranslate();

  const onSubmit = async (values) => {
    setOpen(!open);
  }

  return (
    <Modal open={open} className={'containerModalHowToUse'}>
      <Form defaultValues={{}} schema={schema} onSubmit={onSubmit}>
        <TemplateWarningModal
          actualStep={0}
          warning={translate("MODULES.DOCUMENTS.WARNING")}
          setActualStep={() => { }}
          buttons={{ submit: <SubmitButton>{translate("GENERIC_BUTTON_CLOSE")}</SubmitButton> }}>
          <div className={classnames(`${cls.leftPannel}`)} >

            <TypographyLocal
              className={classnames(`${cls.titleOverlay}`)}
            >
              {translate("MODULES.DOCUMENTS.HTU_TITLE")}
            </TypographyLocal>

            <TypographyLocal
              className={classnames(`${cls.legalContent}`)}
            >
              <span dangerouslySetInnerHTML={{ __html: translate("MODULES.DOCUMENTS.HTU_LEGAL") }}></span>
            </TypographyLocal>
            <TypographyLocal
              className={classnames(`${cls.legalContent}`)}
            >
              {translate("MODULES.DOCUMENTS.HTU_LEGAL_LINK_P1")}
            </TypographyLocal>
            <Link href={'https://clara.co/terms-of-use/'} target={"_blank"} className={classnames(`${cls.legalContent}`)}>
              <Text uuid={"MODULES.DOCUMENTS.HTU_LEGAL_LINK_P2"} />
            </Link>
            <TypographyLocal
              className={classnames(`${cls.legalContent}`)}
            >
              {translate("MODULES.DOCUMENTS.HTU_LEGAL_LINK_P3")}
            </TypographyLocal>
          </div>
          <div className={classnames(`${cls.rightPannel}`)} >
            <Lottie
              width={"100%"}
              height={"85%"}
              options={{
                loop: true,
                autoplay: true,
                animationData: initOnbording,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
            />
          </div>
        </TemplateWarningModal>
      </Form>
    </Modal>

  )
}

export default ModalHowToUse;

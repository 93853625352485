
import React, { useEffect, useState } from 'react';


import { useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import IntroSubmitFlow from "../../../../../components/modules/incorporation/InformationFlow/InformationFlow"
import image from '../../../../../images/introFlowImg.svg';
import PAGES from "../../../../../constants/pages";
import PendingDraft from "../../../../../components/modules/incorporation/PendingDraft/PendingDraft"
import { Constants } from '../../../../../v1/utils/constants';
import Loading from "../../../../../components/generic/Loading/Loading";
//TODO:  V1
import useHasDraftPending from '../../../../../hooks/common/useHasDraftPending';
const KycCompanyIntroView = (props) => {

    const dispatch = useDispatch();
    const params = useParams();
    const { isPending: isDraftPending, loading } = useHasDraftPending("AddKYCToAMatter", params.matterId, "MATTER_KIND");
    const [showPending, setShowPending] = useState(false);
    const handleClosePending = (restart) => {
        if (restart) {
            setShowPending(false)
        } else {
            dispatch({ type: 'GO_TO', page: Constants.PAGES.addKYCToAMatter, params: { ...params } })
        }
    }
    useEffect(() => {
        setShowPending(isDraftPending);
    }, [isDraftPending])


    return (
        <React.Fragment>
            <Loading showChildren={false} loading={loading}>
                {showPending && (<PendingDraft operationButton={"KYC"} title={"Company KYC"} handleClose={handleClosePending} />)}
                {!showPending && (
                    <IntroSubmitFlow
                        image={image}
                        isIntro={true}
                        formTitle={"KYC_FORM_COMPANY_TITLE"}
                        firstTitle={"KYC_FIRST_TITLE"}
                        firstText={"KYC_FIRST_TEXT"}
                        secondText={"KYC_SECOND_TEXT"}
                        buttonText={"BUTTON_GET_STARTED"}
                        secondButtonText={"BUTTON_REMIND_LATER"}
                        handleNext={() => dispatch({ type: 'GO_TO', page: PAGES.addKYCToAMatter, params: { matterId: params.matterId } })}
                        handleClose={() => dispatch({ type: 'GO_TO', page: PAGES.switchStartup })}
                    />

                )}
            </Loading>
        </React.Fragment >
    )
}

export default KycCompanyIntroView;

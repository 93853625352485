import { default as React, FC, useEffect, useMemo } from 'react';
import { useDispatch } from "react-redux";
import { addAssistantText, clearAssistatText } from "src/modules/generic/store/action";
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import Button from "../../../../../generic/components/Buttons/Button";
import useCustomMachine from '../../../../../generic/context/MachineContext/useMachine';
import useTranslate from '../../../../../generic/hooks/useTranslate';
import IsThereDocumentsOptionSIP from "../../../forms/IsThereDocumentsOptionSIP";
interface StepProps {
  withAgreement?: boolean;
}


const IsThereDocumentsOptionSIPStep: FC<StepProps> = ({withAgreement}:StepProps) => {
    const {  send, next,context} = useCustomMachine()
    const dispatch = useDispatch()
    const {translate} = useTranslate()
    useEffect(() => {
        dispatch(clearAssistatText())
        dispatch(addAssistantText(translate('MODAL_EQUITY_POSITIONS_INTRUDUCTION_SIP_TOOLTIP'))) 
    }, [])
    const handlePrev = () => {
        send("PREVIOUS")
    }
    const handleCancel = () => {
        send('CANCEL')
    }
    const handleCompleteSubmit = async (values): Promise<void> => {
       
        next(values)
    }
    const manualSips = useMemo(()=>{
        return context?.getDocumentListOptionSIP?.manualSips ?? []
    },[context])
    return (<>
        <IsThereDocumentsOptionSIP
            manualSips = {manualSips}
            propsTemplate={{
                title: translate(`MODULES.EQUITY.STEPS_EQUITY_POSITIONS.IS_THERE_DOCUMENTS_STEP.TITLE_${context.type.toUpperCase()}_SIP`),
                subTitle: translate(`MODULES.EQUITY.STEPS_EQUITY_POSITIONS.IS_THERE_DOCUMENTS_STEP.SUBTITLE_${context.type.toUpperCase()}`),
            }}
            onCompleteSubmit={handleCompleteSubmit}
            Template={TemplateWizard}
            withAgreement={withAgreement}
            buttons={{cancel: <Button onClick={handleCancel}>Cancel</Button>}}/>
            
    </>)
}
export default IsThereDocumentsOptionSIPStep

import useLazyQuery from "src/hooks/custom/useLazyQuery";
import useSession from "src/modules/session/hooks/useSession";
import {get} from "lodash";
export const DOCUMENT_LIST=`
query getDocumentList($startupId:ID!){
        documentList(startupId:$startupId) {
        name
        id
    }
    }
`
const useGetDocumentsList = () => {
    const { startupId } = useSession();
    const { manualQuery,loading,data,refetch,error } = useLazyQuery(DOCUMENT_LIST, {
        startupId 
    });
    return { data:get(data,'documentList',[]), loading, error,refetch,manualQuery };
}
export default useGetDocumentsList;
import React, {Fragment} from 'react';
import ScopingIntroView from "./ScopingIntro/ScopingIntroView"
import ScopingDisagreeView from "./ScopingDisagree/ScopingDisagreeView"
import ScopingSubmittedView from "./ScopingSubmitted/ScopingSubmittedView"
import ScopingProposalIntroView from "./ScopingProposalIntro/ScopingProposalIntroView"
import ScopingProposalApproveView from "./ScopingProposalApprove/ScopingProposalApproveView"
import ScopingProposalDeclineView from "./ScopingProposalDecline/ScopingProposalDeclineView"
import ScopingProposalDocument from "./ScopingProposalDocument/ScopingProposalDocument"
import layout from "../../../../layouts/Clara/ClaraLayout"
import BlueScreenLayout from "../../../../layouts/BlueScreen/BlueScreenLayout"
import ACTIONS from "../../../../constants/actions"
import PAGES from "../../../../constants/pages";
import ChatContainer from "../../../../v1/containers/Chat/ChatContainer";
import {Constants} from "../../../../v1/utils";
import IntroView from "../../../../components/modules/clara/startups/pendingDraft/PendingDraft";
import FLOWS from "../../../../constants/flows";

const moduleRouter = [
  // {
  //   path: PAGES.scoping.path,
  //   exact: true,
  //   component: <ScopingIntroView />,
  //   layout: layout,
  //   propsLayout : {},
  //   action: ACTIONS.COMPLETE_MATTER_FLOW,
  // },
  {
    path: PAGES.scopingDisagree.path,
    exact: true,
    component: <ScopingDisagreeView />,
    layout: layout,
    propsLayout : {},
    action: ACTIONS.COMPLETE_MATTER_FLOW,
  },
  {
    path: PAGES.scopingSubmitted.path,
    exact: true,
    component: <ScopingSubmittedView />,
    layout: layout,
    propsLayout : {},
    action: ACTIONS.COMPLETE_MATTER_FLOW,
  },
  {
    path: PAGES.scopingProposalIntro.path,
    exact: true,
    component: <ScopingProposalIntroView />,
    layout: layout,
    propsLayout : {},
    action: ACTIONS.COMPLETE_MATTER_FLOW,
  },
  {
    path: PAGES.scopingProposalApprove.path,
    exact: true,
    component: <ScopingProposalApproveView />,
    layout: layout,
    propsLayout : {},
    action: ACTIONS.COMPLETE_MATTER_FLOW,
  },
  {
    path: PAGES.scopingProposalDecline.path,
    exact: true,
    component: <ScopingProposalDeclineView />,
    layout: layout,
    propsLayout : {},
    action: ACTIONS.COMPLETE_MATTER_FLOW,
  },
  {
    path: PAGES.matterProposalView.path,
    exact: true,
    component: <ScopingProposalDocument />,
    layout: BlueScreenLayout,
    propsLayout : {
      showClose:false
    },
    action: ACTIONS.COMPLETE_MATTER_FLOW,
  },
];
export default moduleRouter;

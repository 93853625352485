import React, { FC, useRef, useState } from 'react'
import _ from 'lodash'
import useTranslate from "../../../../../generic/hooks/useTranslate";
import Form from "../../../../../../components/forms/Form/Form";
import ControllerInput from "../../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import SubmitButton from "../../../../../generic/components/Buttons/SubmitButton";
import TemplateFormDefault from "../../../../../generic/templates/Modal";
import FormProps from "../../../../../generic/forms/interfaces/FormProps";
import { CheckboxButtonOptions } from "../../../../../generic/components/Inputs/CheckboxButton";
import schema from './schema'
import CheckboxButtonSimple from "../../../../../generic/components/Inputs/CheckboxButtonSimple";
import { addAssistantText, clearAssistatText } from "../../../../../generic/store/action";
import { useDispatch } from "react-redux";
import classes from './classes.module.scss';

const AreThereMoreEntity: FC<FormProps> = ({
    initialValues,
    tooltips,
    onCompleteSubmit,
    Template = TemplateFormDefault,
    buttons = {},
    buttonSubmitProps,
    propsTemplate
}: FormProps) => {

  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const [defaultBtnText, setDefaultBtnText] = useState("Next");
  const refForm = useRef();

  const options: Array<CheckboxButtonOptions<boolean, string>> = [
    {
      value: false,
      label: translate(
        `MODULES.MAP.LABEL_ANOTHER_NO`
      )
    },
    {
      value: true,
      label: translate(
        `MODULES.MAP.LABEL_ANOTHER_YES`
      ),
    }
  ];

  return (
    <React.Fragment>
      <Form
        defaultValues={initialValues || {}}
        schema={schema}
        onSubmit={onCompleteSubmit}
        ref={refForm} optionsForm={{ mode: "onChange" }}
      >
        <Template props={propsTemplate} buttons={{ 
          ...buttons,
          submit: <SubmitButton {...buttonSubmitProps}>{defaultBtnText}</SubmitButton>
        }}>
          <ControllerInput
            render={CheckboxButtonSimple}
            name='another'
            variant='col_1'
            classNameButton={classes.buttonCheckBox}
            options={options}
            onChange={value => {
              if (tooltips) {
                dispatch(clearAssistatText());
                if (value) {
                  if (_.get(tooltips, 'isAnother')) {
                    dispatch(addAssistantText(_.get(tooltips, 'isAnother', "text")));
                  }
                } else {
                  if (_.get(tooltips, 'isNotAnother')) {
                    dispatch(addAssistantText(_.get(tooltips, 'isNotAnother', "text")));
                  }
                }
              }
              if (value) {
                setDefaultBtnText("Next");
              } else {
                setDefaultBtnText("Continue");
              }
            }}
          />
        </Template>
      </Form>
    </React.Fragment>
  )
}

export default AreThereMoreEntity

import React from "react";
import _ from 'lodash';
import helper from '../../v1/utils/helper';


const DocumentAction = ({ children, actions, action }) => {

	const hasAccess = (actions, actionKey) => {
		// Its only validate document who has listed actions
		if (_.isEmpty(actions)) {
			return true
		}

		return helper.getValueFromKey(actions, actionKey)
	};


	return (
        <React.Fragment>
            {hasAccess(actions, action) && children}
        </React.Fragment>
    )
};

export default DocumentAction;

import moduleRenewalAndBilling from "../../../modules/checkout/modals/RenewalAndBilling/langs";
import moduleChangeCArd from "../../../modules/checkout/modals/ChangeCard/langs";
import moduleOverduePayment from "../modals/PaymentOverdue/langs";

const texts = {
  YEARLY: "Yearly",
  MONTHLY: "Monthly",
  YEARLY_AMOUNT:
    "<span>USD</span> <b>{{price}}</b><span> p/a</span><span>(Billed annually)</span>",
  MONTHLY_AMOUNT:
    "<span>USD</span> <b>{{price}}</b><span> p/m</span><span>(12 month commitment required)</span>",
  UPGRADE_SCALE_BUTTON_MONTHLY: "USD {{price}} | p/m",
  UPGRADE_SCALE_BUTTON_YEARLY: "USD {{price}} | p/a",
  SCALE_TITLE: "Scale",
  UPGRADE_SCALE_TEXT_CONTENT: `Startups looking to grow and communicate efficiently with multiple investors plus the ability to manage and share numerous legal documents across business entities and stakeholders.`,
  UPGRADE_SCALE_PRICE_BUTTON_YEARLY: `Upgrade (Yearly)`,
  UPGRADE_SCALE_PRICE_BUTTON_MONTHLY: `Upgrade (Monthly)`,
  START_TITLE: "Start",
  UPGRADE_START_TEXT_CONTENT: `All the features you need to prepare for initial seed funding. Or, if you are getting your idea off the ground, you can create a profile and grow your startup on Clara.`,
  UPGRADE_START_PRICE_TEXT: `<span>USD</span> <b>0</b><span> / month</span>`,
  UPGRADE_START_PRICE_SUB_TEXT: `Active`,
  PAYMENT_SUBLINE_ITEM_SCALE_ANNUAL_SUBSCRIPTION: "Billed annually {{date}}",
  PAYMENT_SUBLINE_ITEM_SCALE_ANNUAL_SUBSCRIPTION_MONTHLY: `Billed on the <b>{{ordinal}}</b> of every month {{#more}}or the last day, whichever comes first,{{/more}} {{^more}}<br/>{{/more}}starting from the <b>{{nextOrdinal}}</b> of <b>{{nextMonth}}</b> on a <b>12 month commitment</b>`,
  PAYMENT_LABEL_BACK: "Back",
  PAYMENT_SUBSCRIBE: "<span>Subscribe to <b>{{{product}}}</b></span>",
  PAYMENT_SUBSCRIBE_AMOUNT: " per year?",
  PAYMENT_BILLED_ANNAULLY: "Billed annually {{date}}",
  PAYMENT_LINE1_SCALE_ANNUAL_SUBSCRIPTION:
    "<span>Subscribe to <b>{{{name}}}</b></span>",
  PAYMENT_LINE2_SCALE_ANNUAL_SUBSCRIPTION: " per year",
  PAYMENT_LINE1_SCALE_ANNUAL_SUBSCRIPTION_MONTHLY:
    "Subscription to <b>{{{name}}} Monthly</b> with <b>12 month commitment</b>",
  PAYMENT_LINE2_SCALE_ANNUAL_SUBSCRIPTION_MONTHLY: " per month",
  PAYMENT_LINE1_FORMATION_ENGAGEMENT:
    "<span>Pay your <b>Clara Formations</b> invoice</span>",
  PAYMENT_LINE2_FORMATION_ENGAGEMENT: " ",
  PAYMENT_PRODUCT_NAME_FORMATION_ADGM_PAYMENT: "{{{name}}} formation",
  PAYMENT_BUTTON_UPGRADE_FORMATION_DELAWARE_PAYMENT: "Buy now",
  PAYMENT_LINE1_FORMATION_ADGM_PAYMENT:
    "<span>Pay <b>{{{startup.name}}}</b> formation</span>",
  PAYMENT_LINE2_FORMATION_ADGM_PAYMENT: " ",
  PAYMENT_LINE1_FORMATION_CAYMAN_PAYMENT:
    "<span>Pay <b>{{{startup.name}}} formation</b> </span>",
  PAYMENT_LINE1_FORMATION_DELAWARE_PAYMENT:
    "<span>Pay <b>{{{startup.name}}} formation</b> </span>",
  PAYMENT_LINE2_FORMATION_CAYMAN_PAYMENT: " ",
  PAYMENT_LINE2_FORMATION_DELAWARE_PAYMENT: " ",
  PAYMENT_SUBLINE_ITEM_FORMATION_ENGAGEMENT: " ",
  PAYMENT_SUBLINE_ITEM_FORMATION_ADGM_PAYMENT:
    "(Includes 3 shareholders/ directors)",
  PAYMENT_VAT: "VAT ({{percent}}%)",
  PAYMENT_TRANSACTION_OFFER: "Multiple transactions discount",
  PAYMENT_VAT_INLINE: "<span>VAT</span> <b>{{percent}}%</b>",
  PAYMENY_SUBTOTAL: "Subtotal",
  PAYMENY_ADGM_FEE: "ADGM Fee",
  PAYMENT_TOTAL: "Total due today",
  PAYMENT_LABEL_TERMS: "Terms",
  PAYMENT_LABEL_PRIVACY: "Privacy",
  PAYMENT_COUPON_PLACEHOLDER: "Add your coupon code",
  PAYMENT_BUTTON_UPGRADE_SCALE_ANNUAL_SUBSCRIPTION_MONTHLY: "Upgrade",
  PAYMENT_BUTTON_UPGRADE_SCALE_ANNUAL_SUBSCRIPTION: "Upgrade",
  PAYMENT_BUTTON_UPGRADE_FORMATION_ENGAGEMENT: "Pay now",
  PAYMENT_TIMEOUT_TITLE: "The transaction has expired",
  PAYMENT_TIMEOUT_TEXT:
    "The time to complete the payment has expired. Please try again.",
  PAYMENT_TIMEOUT_BUTTON: "Retry",
  PAYMENT_SUCCESS_TITLE_SCALE_ANNUAL_SUBSCRIPTION: "You are ready to go!",
  PAYMENT_SUCCESS_TEXT_SCALE_ANNUAL_SUBSCRIPTION: `<p>We have successfully processed your payment.</p>
  <p>You have added Clara {{product}} to the profile {{startup}}, all limitations have been removed.</p>
  <p>You will find the receipt for this transaction on the View Invoices page.</p>`,
  PAYMENT_SUCCESS_BUTTON_SCALE_ANNUAL_SUBSCRIPTION_MONTHLY: "Return to profile",
  PAYMENT_SUCCESS_TITLE_SCALE_ANNUAL_SUBSCRIPTION_MONTHLY:
    "You are ready to go!",
  PAYMENT_SUCCESS_TEXT_SCALE_ANNUAL_SUBSCRIPTION_MONTHLY: `<p>We have successfully processed your payment.</p>
  <p>You have added Clara {{product}} to the profile {{startup}}, all limitations have been removed.</p>
  <p>You will find the receipt for this transaction on the View Invoices page.</p>`,
  PAYMENT_SUCCESS_BUTTON_SCALE_ANNUAL_SUBSCRIPTION: "Return to Profile",
  PAYMENT_SUCCESS_TITLE_FORMATION_ENGAGEMENT: "You are ready to go!",
  PAYMENT_SUCCESS_TEXT_FORMATION_ENGAGEMENT: `<p>We have successfully processed your payment.</p>
  <p>You can now proceed to the questionnaire.</p>
  <p>A confirmation of payment with your receipt has been emailed to you.</p>`,
  PAYMENT_SUCCESS_BUTTON_FORMATION_ENGAGEMENT: "Continue to Questionnaire",
  PAYMENT_SUCCESS_TITLE_FORMATION_ADGM_PAYMENT: `Thanks for your payment`,
  PAYMENT_SUCCESS_TITLE_FORMATION_DELAWARE_PAYMENT: `Thanks for your payment`,
  PAYMENT_SUCCESS_TEXT_FORMATION_ADGM_PAYMENT: `<p>The receipt for this transaction can be found on the View Invoices page.</p>
  <p><b>What happens next?</b></p>
  <p>Now complete the Company Formation Details. Once you’re done, submit your formation to Clara and we will create your ADGM incorporation.</p>`,
  PAYMENT_SUCCESS_TEXT_FORMATION_DELAWARE_PAYMENT: `<p>The receipt for this transaction can be found on the View Invoices page.</p>
  <p><b>What happens next?</b></p>
  <p>Now complete the Company Formation Details. Once you’re done, submit your formation to Clara and we will create your Delaware incorporation.</p>`,
  PAYMENT_SUCCESS_BUTTON_FORMATION_ADGM_PAYMENT: `Complete details`,
  PAYMENT_CONFIRM_SUBSCRIPTION:
    "By confirming your subscription, you allow Clara to charge your card for this payment and future payments in accordance with their terms.",
  PAYMENT_COUPON_SAVE_PERCENT: "Save ({{value}}%)",
  PAYMENT_COUPON_SAVE_AMOUNT: "Save (USD {{value}})",
  PAYMENT_CANCEL_SUBSCRIPTION_SCALE_INSTALLMENT_SUBSCRIPTION:
    " Please note that when you cancel a monthly plan with a 12-month commitment you will still have to pay the remainder of your plan. Once you have finished the 12 monthly plan you will no longer be billed.",
  PAYMENT_CANCEL_SUBSCRIPTION_SCALE_SUBSCRIPTION:
    "Please note that when you cancel a 12 month plan, you will still have full access to all Clara's features until your plan finishes.",
  PAYMENT_ADGM_PRIMARY_CONTACT_TITLE: "Choose a payee",
  PAYMENT_ADGM_PRIMARY_CONTACT_SUBTITLE:
    "Which of {{{startup.name}}}’s stakeholders will be paying with their own funds for this package?",
  PAYMENT_ADGM_BANK_TRANSFER_SUBTITLE:
    "Please select the wire type for your payment. You can pay the amount either in <strong>USD</strong> or <strong>AED</strong>. Then quote the <strong>reference number</strong> as your payment reference and make sure the <strong>payee</strong> of this transaction is the one you previously selected. Once you’ve made the transfer, upload a proof of payment and submit your application. Payment must be received in full and you must pay any fees or charges for the transfer. ",
  PAYMENT_CAYMAN_BANK_TRANSFER_SUBTITLE: "Please select the wire type for your payment. Once you’ve made the transfer, upload a proof of payment and submit your application. Payment must be received in full and you must pay any fees or charges for the transfer.",
  PAYMENT_BUTTON_UPGRADE_FORMATION_ADGM_PAYMENT: "Buy now",
  PAYMENT_BUTTON_UPGRADE_FORMATION_CAYMAN_PAYMENT: "Buy now",
  PAYMENT_SUCCESS_BUTTON_FORMATION_CAYMAN_PAYMENT: "Return to map",
  PAYMENT_SUCCESS_BUTTON_FORMATION_DELAWARE_PAYMENT: "Return to map",
  PAYMENT_ADGM_FOOTER:
    "By confirming your purchase, you allow Clara to charge your card for this payment and future payments in accordance with their terms.",
  PAYMENT_SUCCESS_KY_TITTLE: "Thanks for your payment",
  PAYMENT_SUCCESS_KY_HIT_1:
    "The receipt for this transaction can be found on the View Invoices page.",
  PAYMENT_SUCCESS_KY_HIT_2: "What happens next?",
  PAYMENT_SUCCESS_KY_HIT_3:
    "Now go back to the map and complete any missing task using the assistant on the right hand side. Once you’re done, submit your formation to Clara and we will create your Cayman incorporation.",
  PAGE_TITLE_INVOICE: "Invoice History",
  NOT_INVOICES: "Sorry, you don´t have invoices to view yet.",
  PAGE_TITLE: "Manage Subscription",
  YOUR_PLAN: "Your plan:",
  START_PLAN: "Start",
  SCALE_PLAN: "Scale",
  START_PLAN_SUBTITLE: "Free subscription",
  DOCUMENTS_GENERATED: "Documents generated",
  DOCUMENTS_UPLOADED: "Documents uploaded",
  PROFILE_SHARES: "Profile shares",
  HEALTH_CHECK: "Health check",
  SAVE_TITLE: "Save up to USD 20,000",
  SAVE_SUBTITLE: "on software & legal fees (est.)",
  DETAILS_TITLE: "Scale",
  DETAILS_SUBTITLE: "Grow, manage and share your profile online",
  DETAILS_ITEM_DU: "Document uploads",
  DETAILS_ITEM_PS: "Profile shares",
  DETAILS_ITEM_FA: "Founder´s Agreement",
  DETAILS_ITEM_GD: "Generate documents",
  DETAILS_ITEM_SIP: "Share incentive Plan",
  DETAILS_ITEM_HC: "Health Check",
  DETAILS_ITEM_CO: "Concierge onboarding",
  BTN_BENEFITS: "See the benefits",
  ASSISTANCE_UPGRADE_BUBBLE:
    "Grant options to team members, agree terms with your co-founders and generate unlimited contracts to help you raise, run and protect your business - plus many more great features!",
  ASSISTANCE_UPGRADE_BTN_TEXT: "Upgrade",
  ASSISTANCE_EDIT_SUBSCRIPTIONS_ITEMS: "Edit subscription items",
  ASSISTANCE_CHANGE_CARD: "Change card",
  ASSISTANCE_VIEW_INVOICES: "View invoices",
  ASSISTANCE_HERE: "Here are your ",
  ASSISTANCE_AND_YOUR: " and your ",
  ASSISTANCE_TERMS: "Terms & Conditions",
  ASSISTANCE_PRIVACY: "Privacy policy.",
  BILLING_CYCLE: "Billing cycle",
  SWITCH_MONTHLY_TO_ANNUAL:
    "You switched to Annually starting from {{{renewalDate}}}.",
  SWITCH_ANNUAL_TO_MONTHLY:
    "You switched to Monthly starting from {{{renewalDate}}}.",
  SUBSCRIPTION_EXPIRATION: "Your subscription expires on {{{renewalDate}}}.",
  MONTHLY_12_SUBSCRIPTIONS: "12 month annual subscription",
  SCALE_PLAN_ANNUAL: "Annually",
  SCALE_PLAN_MONTHLY: "Monthly",
  BTN_EDIT: "Edit",
  AUTO_RENEWAL_DATE: "Auto-renewal date",
  PAYMENT_METHOD: "Payment method",
  BTN_CHANGE_CARD: "Change card",
  // CARD_DATA: "{{card}} **** **** **** {{last}}</br>Exp. date {{exp}}",
  CARD_DATA_ONLY: "{{card}} **** **** **** {{last}}",
  CARD_DATA: "**** **** **** {{last}}</br>Exp. date {{exp}}",
  NEXT_PAYMENT: "Next payment due",
  LAST_PAYMENT: "Last payment due",
  AUTO_PAYMENT: "Automatic payment scheduled for {{date}}.",
  AUTO_RENEWAL_OFF: "Auto-renewal has been switched off.",
  PAYMENT_DATA: "{{money}} {{paid}}",
  USE_VAT: "+ VAT",
  DEFAULT_MONEY: "USD",
  PAYMENT_OVERDUE: "Payment overdue",
  PAYMENT_SUBTITLE:
    "Please review your payment method and pay before {{date}} to avoid losing access to your Scale benefits.",
  PAYMENT_OVERDUE_SECOND: "The payment was due on {{{date}}}.",
  BUTTON_MANAGE_SUBSCRIPTION: "Manage subscription",
  BUTTON_STARTUP_MAKE_PAYMENT: "Make a payment",
  PAY_INVOICE: "Pay invoice",
  BUTTON_STARTUP_EDIT_SUBSCRIPTION: "Edit subscription",
  BUTTON_STARTUP_UPDATE_CARD: "Update card",

  PAYMENT_REQUIRED: "Payment required",
  PAYMENT_REQUIRED_SUBTITLE: "You have a new invoice to pay.",
  PAY_INVOICE_REQUIRED_SUBTITLE:
    "You have a new invoice to pay.<br>{{{invoiceName}}}",
  PAYMENT_REQUIRED_GOTO_INVOICES: "Go to invoice page",
  PAY_INVOICE_REQUIRED_GOTO_PAY: "Pay invoice",

  MODALS: {
    RENEWAL_AND_BILLING: moduleRenewalAndBilling.US,
    CHANGE_CARD: moduleChangeCArd.US,
    OVERDUE_PAYMENT: moduleOverduePayment.US,
  },
};
export default texts;

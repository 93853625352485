import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import _ from "lodash";
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import Typography from "src/modules/generic/components/Texts/Typography";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import TextInput from "src/components/inputs/Text/TextInput";
import CalendarInputDate from "src/components/inputs/Calendars/CalendarInputDate";
import CountrySelector from "src/components/inputs/Select/CountrySelector/CountrySelector";
import PhoneInput from "src/modules/generic/components/Inputs/PhoneInput";
import { Constants } from "src/v1/utils";
import FileUploaderInput from 'src/components/inputs/FileUploader/FileUploaderInput';
import RadioInput from 'src/components/inputs/Radio/RadioInput';
import { HiddenFieldForm } from 'src/components/forms/contents';
import ClaraCountryAutocomplete from 'src/v1/components/Form/ClaraCountryAutocomplete/ClaraCountrySelector';

function ConfirmForm() {
  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const schema = Yup.object().shape({
    fullName: Yup.string().required(
      translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")
    ),
    dateOfBirth: Yup.string()
      .matches(Constants.REGEXP.DATE, {
        message: translate("MODULES.CLARA.VALIDATION_ENTER_DATE"),
        excludeEmptyString: true,
      })
      .notRequired()
      .nullable(),
    nationality: Yup.string().required(
      translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")
    ),
    hasNationalities: Yup.boolean().required("This field is required").typeError("This field is required"),
    nationalities: Yup.mixed().when('hasNationalities', {
      is: true,
      then: Yup.array().of(Yup.string()).min(1, translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")).required(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")),
      otherwise: Yup.array().of(Yup.string()).nullable(),
    }),
    countryOfBirth: Yup.string().required("This field is required").typeError("This field is required").nullable(),
    cityOfBirth: Yup.string().required("This field is required").nullable(),
    profession: Yup.string().required(
      translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")
    ),
    email: Yup.string()
      .lowercase()
      .matches(Constants.REGEXP.EMAIL, {
        message: translate("MODULES.CLARA.VALIDATION_ENTER_EMAIL"),
        excludeEmptyString: true,
      })
      .required(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED"))
      .typeError(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")),
    phoneNumber: Yup.string()
      .nullable()
      .required("This field is required")
      .typeError(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")),
      linkedinURL: Yup.string().matches(Constants.REGEXP.URL_PROTOCOL, {
        message: "Please enter a valid URL. eg:'https://...' or 'http://... '",
        excludeEmptyString: true,
      }).nullable(),
    curriculumVitae: Yup.object()
      .nullable(),
  });

  const initialValues = useMemo(() => {
    return context?.ConfirmForm ?? context?.initialData?.stakeholder ?? {};
  }, [context]);
    

  const handleCancel = () => {
    cancel();
  }

  const handleSubmit = async (values) => {
    next(values);
  }

  useEffect(() => {
    dispatch(addAssistantText('To change the full legal name <strong>additional fees will apply</strong>.', "alert"));
    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch])

  const Paragraph = () => {
    return (
      <div>
        <Typography component="p" variant="m" weight="semibold">
          Please review the details in the following screens. By clicking Next, you confirm that the details are correct.
        </Typography>
      </div>
    );
  };

  const residenceCountry = _.get(initialValues, "address.country.code")
    ? _.get(initialValues, "address.country.code").toLowerCase()
    : "";

  const labelLines = [
    "Do they have other<br />nationalities?",
  ];

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: "Review their details",
          skeleton: null,
          subTitle: `Review details for ${context?.initialData?.stakeholder?.fullName}`,
          variant: 'simple'
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton>Next</SubmitButton>,
        }}
      >
        <Paragraph />
        <TemplateLabel label="Full legal name" isRequired={true}>
          <ControllerInput
            name="fullName"
            placeholder="Type here"
            render={TextInput}
          />
        </TemplateLabel>
        <TemplateLabel label="Date of birth" isRequired={true}>
          <ControllerInput
            name="dateOfBirth"
            placeholder="dd/mm/yyyy"
            render={CalendarInputDate}
          />
        </TemplateLabel>
        <TemplateLabel label="Nationality" isRequired={true}>
          <ControllerInput
            name="nationality"
            placeholder="Type here"
            render={CountrySelector}
          />
        </TemplateLabel>
        <TemplateLabel
          multilineTexts={labelLines}
          isRequired
        >
          <ControllerInput
            render={RadioInput}
            name='hasNationalities'
          />
        </TemplateLabel>
        <HiddenFieldForm
          fieldsWatch="hasNationalities"
          conditionHideCallback={(value) => value}
        >
          <TemplateLabel label="Other nationalities" isRequired>
            <ControllerInput
              placeholder="Select all that apply"
              render={ClaraCountryAutocomplete}
              name='nationalities'
            />
          </TemplateLabel>
        </HiddenFieldForm>
        <TemplateLabel label="Country of birth" isRequired>
          <ControllerInput
            placeholder="Select country"
            render={CountrySelector}
            name='countryOfBirth'
          />
        </TemplateLabel>
        <TemplateLabel label="City of birth" isRequired>
          <ControllerInput
            render={TextInput}
            name="cityOfBirth"
            placeholder="Type here"
          />
        </TemplateLabel>
        <TemplateLabel label={translate("MODULES.CLARA.FORM_PROFESSION")} isRequired>
          <ControllerInput
            render={TextInput}
            name="profession"
            placeholder={translate("MODULES.CLARA.EXAMPLE_PROFESSION")}
          />
        </TemplateLabel>
        <TemplateLabel label="Email address" isRequired>
          <ControllerInput
            name="email"
            placeholder="Type here"
            render={TextInput}
          />
        </TemplateLabel>
        <TemplateLabel
          label={translate("MODULES.CLARA.FORM_TELEPHONE_NUMBER")}
          isRequired
        >
          <ControllerInput
            render={PhoneInput}
            name="phoneNumber"
            defaultCountry={residenceCountry}
            disableDropdown={false}
            placeholder={translate("MODULES.CLARA.TYPE_HERE_HIT")}
          />
        </TemplateLabel>
        <TemplateLabel label={translate('VIEW_COMPANY_TAB_LABEL_DETAILS_WEBSITE')}>
          <ControllerInput
            name="linkedinURL"
            placeholder={translate('VIEW_COMPANY_TAB_LABEL_DETAILS_WEBSITE')}
            render={TextInput}
          />
        </TemplateLabel>
        <TemplateLabel
          label="Curriculum vitae"
          variant="top"
        >
          <ControllerInput
            render={FileUploaderInput}
            name="curriculumVitae"
            defaultlabel={""}
            typeFileAllowed={["application/pdf"]}
            icons={["PDF"]}
          />
        </TemplateLabel>
        <div>&nbsp;</div>
      </TemplateWizard>
    </Form>
  );
}

export default ConfirmForm;


import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useMachine from '../../../../../../../modules/generic/context/MachineContext/useMachine';
import Button from "../../../../../../../modules/generic/components/Buttons/Button";
import useTranslate from '../../../../../../../modules/generic/hooks/useTranslate';
import TemplateWizard from "../../../../../../../modules/generic/templates/Modal/TemplateWizard";
import { clearAssistatText,addAssistantText } from "../../../../../../../modules/generic/store/action";
import useModal from "../../../../../../../modules/generic/hooks/useModal";
import FormApplicationSummary from "../../forms/FormApplicationSummary";

/**
 * Step accelerator application summary
 * @param {*} param0
 * @returns
 */
function ApplicationSummary({ openParam }) {
  const { next } = useMachine();
  const { translate } = useTranslate()
  const { closeModal: onClose } = useModal();
  const dispatch = useDispatch()

  const handleCancel = (values) => {
    onClose();
    clearAssistatText();
  }
 
  useEffect(() => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText(translate('MODULES.SUBMIT_APPLICATION.STEP_2.TOOLTIP')));

    return () => {
      dispatch(clearAssistatText());
    }
  }, [openParam]);

  const handleCompleteSubmit = async (values) => {
    next(values);
  }

  return (
    <FormApplicationSummary
      buttons={{
        cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
      }}
      onCompleteSubmit={handleCompleteSubmit}
      propsTemplate={{
        title: translate('MODULES.SUBMIT_APPLICATION.STEP_2.TITLE'),
        subTitle: translate('MODULES.SUBMIT_APPLICATION.STEP_2.SUBTITLE')
      }} 
      Template={TemplateWizard}
    />
  );
}

export default ApplicationSummary;
import React from 'react';
import ACTIONS from "../../../../constants/actions";
import PAGES from "../../../../constants/pages";
import ClaraLayout from "../../../../layouts/Clara/ClaraLayout";
import Text from "./../../../../components/text/Text/Text";
import DashboardView from "src/views/modules/startups/dashboard/views";

const moduleRouter = [
  {
    path: PAGES.dashboard.path,
    exact: false,
      breadCrumbs: {
      reset: true,
      add: false,
      label: <Text uuid={"BREADCRUMS_DASHBOARD"} />,
    },
    component: <DashboardView />,
    layout: ClaraLayout,
    propsLayout: {
      cantBack: true,
      startupMenu: true,
      userMenu: true,
      fullMenu: true,
      showBreadCrumbs: false,
      linkMenu: "LinkDashboard",
    },
    action: ACTIONS.VIEW_PROFILE,
  },

];
export default moduleRouter;

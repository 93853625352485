import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import _ from "lodash";
import moment from "moment";
import Form from "src/components/forms/Form/Form";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import Button from "src/modules/generic/components/Buttons/Button";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import {
  clearAssistatText,
  addAssistantText,
} from "src/modules/generic/store/action";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import Typography from "src/modules/generic/components/Texts/Typography";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import TextInput from "src/components/inputs/Text/TextInput";
import SelectInput from "src/components/inputs/Select/SelectInput/SelectInput";
import CalendarInputDate from "src/components/inputs/Calendars/CalendarInputDate";
import classes from "./classes.module.scss";
import { InputLabelWithBanner } from "src/components/inputs/InputWithBanner";

const schema = Yup.object().shape({
  name: Yup.string().required("Required"),
  registeredNumber: Yup.string().required("Required"),
  authorizedSignatory: Yup.string().required("Required"),
  type: Yup.string().required("Required"),
  endOfFinancialYear: Yup.string().required("Required"),
});

function ReviewCompanyDetailsStep() {
  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const currentDate = moment();
  const currentYear = currentDate.year();

  const initialValues = useMemo(() => {
    return (
      context?.ReviewCompanyDetailsStep ??
      context?.initialData?.groupCompany ??
      {}
    );
  }, [context]);

  const Paragraph = () => {
    return (
      <div className={classes.Paragraph}>
        <Typography component="p" variant="h5">
          {translate(
            "MODULES.RENEWALS.ECONOMIC_SUBSTANCE.COMPANY_DETAILS.DESCRIPTION"
          )}
        </Typography>
      </div>
    );
  };

  const stakeholderList = useMemo(() => {
    return _.map(context?.initialData?.groupCompany?.directors, (director) => {
      return { value: director?.id, label: director?.fullName };
    });
  }, [context]);

  const typeList = [
    {
      value: initialValues?.type,
      label: _.startCase(_.toLower(initialValues?.type)),
    },
  ];

  const handleCancel = () => {
    cancel();
  };

  const handleSubmit = async (values) => {
    next(values);
  };

  useEffect(() => {
    dispatch(
      addAssistantText(
        translate(
          "MODULES.RENEWALS.ECONOMIC_SUBSTANCE.COMPANY_DETAILS.TOOLTIP_1"
        ),
        "alert"
      )
    );
    dispatch(
      addAssistantText(
        translate(
          "MODULES.RENEWALS.ECONOMIC_SUBSTANCE.COMPANY_DETAILS.TOOLTIP_2"
        )
      )
    );

    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const economicSubstanceChangeNamePincasValue = context?.pincasData?.pincasData?.CHANGE_COMPANY_NAME.value;

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: translate(
            "MODULES.RENEWALS.ECONOMIC_SUBSTANCE.COMPANY_DETAILS.TITLE"
          ),
          skeleton: null,
          subTitle: translate(
            "MODULES.RENEWALS.ECONOMIC_SUBSTANCE.GENERIC.SUBTITLE"
          ),
          variant: "simple",
        }}
        buttons={{
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("BUTTON_CANCEL")}
            </Button>
          ),
          submit: (
            <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>
              Next
            </SubmitButton>
          ),
        }}
      >
        <Paragraph />
        <InputLabelWithBanner
          bannerLogicType="touched"
          component={TextInput}
          controllerInputProps={{ name: "name", placeholder: "Company name" }}
          templateLabelProps={{
            bannerTitle: translate(
              "MODULES.RENEWALS.ECONOMIC_SUBSTANCE.COMPANY_DETAILS.NAME_BANNER",
              { currency: "USD", price: economicSubstanceChangeNamePincasValue }
            ),
            label: "Company name",
            isRequired: true,
          }}
        />
        <TemplateLabel label="Company number" isRequired>
          <ControllerInput
            name="registeredNumber"
            render={TextInput}
            className={classes.disabled}
            disabled
          />
        </TemplateLabel>
        <TemplateLabel label="Signatory" isRequired>
          <ControllerInput
            name="authorizedSignatory"
            render={SelectInput}
            placeholder={translate("SELECT_INPUT_PLACEHOLDER_NORMAL")}
            list={stakeholderList}
          />
        </TemplateLabel>
        <TemplateLabel label="Company type" isRequired>
          <ControllerInput
            name="type"
            render={SelectInput}
            placeholder={translate("MODULES.CLARA.SELECT_FROM_LIST")}
            list={typeList}
            className={classes.disabled}
            disabled
          />
        </TemplateLabel>
        <TemplateLabel
          label="End of financial year"
          isRequired
          className={classes.lastInput}
        >
          <ControllerInput
            name="endOfFinancialYear"
            render={CalendarInputDate}
            placeholder={translate("MODULES.CLARA.ROLE_DATE_INPUT_HIT")}
            defaultValue={`${currentYear}-12-31`}
            disabled
          />
        </TemplateLabel>
      </TemplateWizard>
    </Form>
  );
}

export default ReviewCompanyDetailsStep;

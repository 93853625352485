import React from 'react';
import MatterListView from "./MatterList/MatterListView"
import ClaraLayout from "../../../layouts/Clara/ClaraLayout"
import ACTIONS from "../../../constants/actions"
import PAGES from "../../../constants/pages";

const moduleRouter = [
  {
    path: PAGES.switchMatters.path,
    exact: true,
   
    component: <MatterListView/>,
    layout: ClaraLayout,
    propsLayout : {},
    action: ACTIONS.MANAGE_MATTER
  },
];
export default moduleRouter;

import React, { useCallback } from 'react';

import Button from "src/modules/generic/components/Buttons/Button";
import { Constants } from 'src/v1/utils';
import DeleteRoleModal from './DeleteRoleModal';
import EditRoleModal from "src/modules/startup/Stakeholder/Roles";
import Rol from '../../../../entities/classes/Rol';
import { RoleAccess } from 'src/v1/components';
import classes from './Styles.module.scss';
import useModal from "src/modules/generic/hooks/useModal";
import useRolEntity from '../../../../entities/useRolEntity';

const Source = ({
  data,
  lock,
  isManagedByClara,
  onlyOneRole,
  disabled,
  jurisdictions
}) => {
  const { openModal, closeModal } = useModal();

  const handleEditRole = () => {
    openModal(EditRoleModal, { initialValues: { ...data?.original, isManagedByClara, jurisdictions }, handleCloseModal: closeModal });
  }

  const handleDeleteRole = () => {
    openModal(DeleteRoleModal, {
      data: data?.original,
      handleCloseModal: closeModal,
      stakeholderName: data?.original?.entity?.fullName || data?.original?.entity?.name,
      onlyOneRole
    });
  }

  const showDeleteButton = useCallback((rol) => {
    const rolClass = new Rol(rol);
    return rolClass.isRolWithCompanyInManagerByClaraWitRolDirectorOrShareholder();
  },[]);

  return (
    <div className={classes.buttonsContainer}>
      <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
        <Button disabled={lock || disabled} variant="card" onClick={() => handleEditRole()}>Edit</Button>
        {!(showDeleteButton(data?.original)) && (
          <>
            <Button disabled={lock || disabled} variant="cardWarning" onClick={handleDeleteRole}>Delete</Button>
          </>
        )}
      </RoleAccess>
    </div>
  );
}

export default Source;

import CorporateStakeholderGeneralDetailsForm from "src/components/forms/modules/startups/individualStakeholder/CorporateStakeholderGeneralDetailsForm"
import Line from "../../../../../../generic/Line/Line";
import { LoadingProvider } from "../../../../../../../modules/generic/hooks/useLoading";
import ModalAssistant from "../../../../../../../modules/generic/components/Modal/ModalAssistant";
import React, { useMemo } from "react";
import SkeletonArea from "../../../../../../../modules/generic/components/Skeletons/Overlays/SkeletonArea";
import SkeletonInput from "../../../../../../../modules/generic/components/Skeletons/Overlays/SkeletonInput";
import TemplateWizard from "../../../../../../../modules/generic/templates/Modal/TemplateWizard";
import _ from "lodash";
import useIndividualStakeholderData from "../../../../../../../hooks/services/modules/startups/individual/useIndividualStakeholderData";
import { useParams } from "react-router-dom";
import useSession from "../../../../../../../modules/session/hooks/useSession";
import useTranslate from "../../../../../../../modules/generic/hooks/useTranslate";
import useUpdateStakeholder from "src/modules/startup/onBoarding/core/graphql/mutations/useUpdateStakeholder";

const EditGeneralDetails = ({
  showModal,
  open,
  refresh,
  handleCloseModal,
  isManagedByClara
}) => {

  const { translate } = useTranslate();
  const { startupId } = useSession();
  const params = useParams();

  const handleCloseForm = () => {
    refresh && refresh();
    handleCloseModal && handleCloseModal();
  };

  const { updateStakeholder: upsertIndividualStakeholder } = useUpdateStakeholder({
    onCompleted: handleCloseForm,
  });

  const handleSubmit = async (values) => {
    const { otherJurisdiction, jurisdiction } = values;
    let stakeholder = {
      ...values,
      id: params.id,
      jurisdiction: otherJurisdiction || jurisdiction
    };
    jurisdiction === "OTHER" &&
      !otherJurisdiction &&
      delete stakeholder.jurisdiction;
    delete stakeholder.otherJurisdiction;
    await upsertIndividualStakeholder({
      variables: { startupId, stakeholderId: params.id, stakeholderData: { stakeholder } },
    }, {
      paramsSuccessMessage: { overlayName: stakeholder?.fullName ?? "" }
    }
    );
  };

  const { data: userData, loading } = useIndividualStakeholderData({
    startupId,
    stakeholderId: params.id,
  });

  const showByJurisdictions = useMemo(() => {
    return _.some(_.get(userData, "managedByClaraOnJurisdictionType", []), (item) => item !== "DELAWARE");
  }, [userData]);

  return (
    <>
      <ModalAssistant open={showModal || open}>
        {!loading &&
          <LoadingProvider>
            <CorporateStakeholderGeneralDetailsForm
              initialValues={_.get(userData, "getStakeholder", {})}
              loading={loading}
              handleCloseModal={handleCloseModal}
              handleSubmit={handleSubmit}
              Template={TemplateWizard}
              banners={isManagedByClara && showByJurisdictions}
              propsTemplate={{
                title: translate("EDIT_GENERAL_DETAILS"),
                skeleton: (
                  <>
                    <SkeletonInput count={3} />
                    <Line />
                    <SkeletonInput />
                    <SkeletonArea noLabel={true} />
                  </>
                ),
              }}
            />
          </LoadingProvider>
        }
      </ModalAssistant>
    </>
  );
};

export default EditGeneralDetails;

import useMutation from "../../../hooks/custom/useMutation";

const mutation = `mutation payWithExistingCreditCard($relatedItemId: ID!, $productCode: String!, $creditCardId: String!, $billingAddress: BillingAddressInput!, $couponCode: String, $source:String!){
  payWithExistingCreditCard(
    relatedItemId: $relatedItemId
    productCode: $productCode
    creditCardId: $creditCardId
    billingAddress: $billingAddress
    couponCode: $couponCode,
    source: $source
      ) 
  } `;

const usePayWithExistingCreditCard = (options = {}) => {

  const [send, data] = useMutation(mutation, options);


  return [send, data];
};

export default usePayWithExistingCreditCard;

import useCustomMachine from '../../../../generic/hooks/useCustomMachine';
import React, { FC } from 'react';
import Button from '../../../../generic/components/Buttons/Button';
import { StepProps } from "../../../../generic/components/Wizard/Step";
import TemplateWizard from '../../../../generic/templates/Modal/TemplateWizard';
import Form from "../../Forms/FormMoreDetails";
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import { addAssistantText, clearAssistatText } from '../../../../generic/store/action';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { getValuesMutation, hasRolesPosition } from '../utils';
import useSession from '../../../../session/hooks/useSession';
import useUpdateStakeholder from "src/modules/startup/onBoarding/core/graphql/mutations/useUpdateStakeholder";

const Step2: FC<StepProps> = () => {

  const { id: stakeholderId } = useParams<any>();
  const { startupId } = useSession();
  const { next, send, state, prev } = useCustomMachine();
  const { numbersOfIteration, companies, data } = state.context;

  const { updateStakeholder } = useUpdateStakeholder({}, {});

  const handleCompleteSubmit = async values => {
    if (companies.length === (numbersOfIteration + 1) && !hasRolesPosition(values.roles)) {
      const lastValues = {
        ...data,
        more_details: values
      };
      const variables = {
        startupId,
        stakeholderId,
        stakeholderData: {
          stakeholder: getValuesMutation(lastValues, state)
        }
      }
      await updateStakeholder({ variables })
      send("CANCEL");
    }
    next(values);
  }

  const handleCancel = async values => {
    send("CANCEL");
  }

  const handlePrev = () => {
    prev(get(state, 'context.data.more_details', {}));
  }

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(addAssistantText("Roles are tasks and responsibilities that a stakeholder has within a startup, often formalised with a legal agreement."));
    dispatch(addAssistantText("Positions refer to job titles, departments and responsibilities."));
    return () => {
      dispatch(clearAssistatText());
    }
  }, []);

  const initialValues = React.useMemo(() => {
    const company = companies[numbersOfIteration];
    const data = get(state, 'context.data.more_details.roles', []);
    return { roles: _.concat(data, company.roles) };
  }, []);

  return (
    <React.Fragment key={numbersOfIteration}>
      <Form onCompleteSubmit={handleCompleteSubmit} Template={TemplateWizard} propsTemplate={{
        title: 'Now for a few more details...',
        subTitle: "Roles and Positions"
      }}
        initialValues={initialValues}
        buttons={{
          cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button>,
          previous: <Button onClick={handlePrev} disabled={!!numbersOfIteration} variant="secondary">Previous</Button>
        }}>
      </Form>
    </React.Fragment>
  )
}

export default Step2;

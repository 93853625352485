import { AddPassport, ConfigurePassport, MoreToAdd, SelectOtherNationalities } from "../Steps";
import TemplateWizardSkeletonMachine from "src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine";

const useSteps = () => {
  return {
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    uploadDocument: {
      render: TemplateWizardSkeletonMachine,
      renderProps: {
        title: "",
        subTitle: ""
      }
    },
    ConfirmNationalitiesDone: {
      render: TemplateWizardSkeletonMachine,
      renderProps: {
        title: "",
        subTitle: ""
      }
    },
    addPassport: {
      render: AddPassport,
    },
    configurePassport: {
      render: ConfigurePassport,
    },
    moreToAdd: {
      render: MoreToAdd,
    },
    otherNationalities: {
      render: SelectOtherNationalities,
    }
  };
}

export default useSteps;
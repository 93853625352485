import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import {
  addAssistantText,
  clearAssistatText,
} from "src/modules/generic/store/action";
import Button from "src/modules/generic/components/Buttons/Button";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import CountryJurisdictionWithBanner from "src/modules/generic/components/CountryJurisdictionSelectorWithBanner";
import Form from "src/components/forms/Form/Form";
import SelectInput from "src/components/inputs/Select/SelectInput/SelectInput";
import SubmitButton from "src/components/generic/Buttons/SubmitButton/SubmitButton";
import TemplateFormDefault from "src/modules/generic/templates/Modal";
import TemplateLabel from "src/modules/generic/templates/Label";
import TextInput from "src/components/inputs/Text/TextInput";
import { editGeneralDetailsCorporate } from "src/forms/schemas/modules/startups/stakeholder/schema";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import CountryJurisdiction from "src/modules/generic/components/CountryJurisdictionSelector";
import useStakeholderFieldsAnalizer from "../hooks/useStakeholderFieldsAnalizer";
import { AnalizerInputWithBanner } from "src/components/inputs/AnalizerInputWithBanner";
import Stakeholder from "src/components/modules/clara/startups/entities/classes/Stakeholder";
import { Constants } from "src/v1/utils/constants";

const CorporateStakeholderGeneralDetailsForm = ({
  initialValues,
  handleSubmit,
  handleCloseModal,
  propsTemplate,
  Template = TemplateFormDefault,
  banners = false,
}) => {
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const legalEntityStructureList = [
    {
      label: "Company Limited by Shares",
      value: "LIMITED_BY_SHARES",
    },
    {
      label: "Limited Partnership",
      value: "LIMITED_PARTNERSHIP",
    },
    {
      label: "Trust",
      value: "TRUST",
    },
  ];

  const formattedValues = useMemo(() => {
    const values = _.cloneDeep(initialValues);
    if (values?.address?.country) {
      values.address.country = values?.address?.country?.code;
    }
    if (values?.nationality) {
      values.nationality = values?.nationality?.code;
    }
    return values;
  }, [initialValues]);

  const { config, schema } = useStakeholderFieldsAnalizer(
    initialValues,
    editGeneralDetailsCorporate
  );

  useEffect(() => {
    banners &&
      dispatch(
        addAssistantText(
          "<strong>Additional fees</strong> might apply if you need to update some of the details with the Cayman registrar.</br><div style='margin-top:1rem;'>Click on the field to update to view the corresponding fees. To make a change, just edit the content and proceed. The change request will be added to your cart.</div></br> Please note: if the stakeholder is a shareholder or director in multiple companies, the change must updated and paid for all companies.",
          "alert"
        )
      );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const bannerCountry = useMemo(() => {
    const stakeholderClass = new Stakeholder(initialValues);

    return (
      stakeholderClass.getIsCorporate() &&
      stakeholderClass.getIsManagedByClaraInSomeJurisdiction([
        Constants.JURISDICTIONS.ADGM,
        Constants.JURISDICTIONS.CAYMAN,
      ])
    );
  }, [initialValues]);

  if (!schema) return null;

  return (
    <Form
      defaultValues={formattedValues}
      schema={schema}
      onSubmit={handleSubmit}
    >
      <Template
        buttons={{
          submit: (
            <SubmitButton>
              {translate("MODULES.CLARA.BTN_SAVE_CHANGES")}
            </SubmitButton>
          ),
          cancel: (
            <Button onClick={() => handleCloseModal()} variant="secondary">
              {translate("MODULES.CLARA.BTN_CANCEL")}
            </Button>
          ),
        }}
        props={propsTemplate}
      >
        <AnalizerInputWithBanner
          component={TextInput}
          controllerInputProps={{
            name: "fullName",
            placeholder: translate("MODULES.CLARA.TYPE_HERE_HIT"),
          }}
          field={config.fullName}
        />

        <AnalizerInputWithBanner
          component={TextInput}
          controllerInputProps={{
            name: "previousName",
            placeholder: translate("MODULES.CLARA.TYPE_HERE_HIT"),
          }}
          field={config.previousName}
        />

        {bannerCountry ? (
          <CountryJurisdictionWithBanner name="nationality" />
        ) : (
          <CountryJurisdiction name="nationality" />
        )}

        <TemplateLabel label={translate("MODULES.CLARA.COMPANY_TYPE_LABEL")}>
          <ControllerInput
            render={TextInput}
            name="type"
            placeholder={translate("MODULES.CLARA.TYPE_HERE_HIT")}
          />
        </TemplateLabel>

        <AnalizerInputWithBanner
          component={SelectInput}
          controllerInputProps={{
            name: "companyType",
            placeholder: translate("SELECT_INPUT_PLACEHOLDER"),
            list: legalEntityStructureList,
            clear: true,
          }}
          field={config.companyType}
        />

        <AnalizerInputWithBanner
          component={TextInput}
          controllerInputProps={{
            name: "registeredNumber",
            placeholder: translate("MODULES.CLARA.TYPE_HERE_HIT"),
          }}
          field={config.registeredNumber}
        />

        <AnalizerInputWithBanner
          component={TextInput}
          controllerInputProps={{
            name: "taxRegistrationNumber",
            placeholder: translate("MODULES.CLARA.TYPE_HERE_HIT"),
          }}
          field={config.taxRegistrationNumber}
        />

        <TemplateLabel label={translate('VIEW_COMPANY_TAB_LABEL_DETAILS_WEBSITE')}>
          <ControllerInput
            render={TextInput}
            name="linkedinURL"
            placeholder={translate('VIEW_COMPANY_TAB_LABEL_DETAILS_WEBSITE')}
          />
        </TemplateLabel>
        <div>&nbsp;</div>
      </Template>
    </Form>
  );
};

export default CorporateStakeholderGeneralDetailsForm;

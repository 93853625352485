import React, { useEffect, useState,useMemo } from 'react';
import _ from "lodash";
import { CalendarInputDate, ClaraButton, ClaraSelect, Text } from '../../index';
import helper from "../../../utils/helper"
import { Constants } from '../../../utils/constants';
import classes from "./TimeLine.module.scss"
import { Field, Formik } from "formik";
import {TextInput} from "../../index";

export default function ({ updateFilters, session, onlyDateFilter, filterButtonText }) {
    const [valuesState, setValues] = useState({});
    const [groupCompaniesAndStakeholders,setGroupCompaniesAndStakeholders]=useState([])
    const [clearValue,setClearValue]=useState(false)
    const transactions = [
        {
            listName: "filter",
            subListName: "transactions",
            id: "",
            label: helper.getTranslateTextByKey("AUDITLOGS_TRANSACTIONS_ACTIONS"),
            showTitle: "false",
            list: Object.keys(Constants.AUDITLOGS_TRANSACTIONS.ACTIONS).map(element => {
                return { id: element, label: helper.getTranslateTextByKey("AUDITLOGS_TRANSACTIONS_" + element + "_LIST") }
            })
        }
    ];
    const handleSelectStartups=(value)=>{
        if(value){
            let stakeholders=[]
            let groupCompanies=[]
                _.forEach(value, val => {
                    _.forEach(val.startups, startup => {
                        const start = _.find(_.get(session, "getMe.startups", []), st => {
                            return st.id === startup
                        })
                        stakeholders = _.concat(stakeholders, start.stakeholders)
                        groupCompanies = _.concat(groupCompanies, start.groupCompanies)
                    })
                })
            const lists= [
                {
                    listName: "filterGroupCompanies",
                    subListName: "groupCompanies",
                    type: "groupCompany",
                    id: "group",
                    label: helper.getTranslateTextByKey("AUDITLOGS_TRANSACTIONS_GROUP_COMPANIES"),
                    showTitle: "true",
                    list: groupCompanies.map((element) => {
                        return {id: element.id, label: element.name, type: 'groupCompany'}
                    })
                },
                {
                    listName: "filterStakeholders",
                    subListName: "stakeholders",
                    id: "stake",
                    type: "stakeholder",
                    label: helper.getTranslateTextByKey("AUDITLOGS_TRANSACTIONS_STAKEHOLDERS"),
                    showTitle: "true",
                    list: stakeholders.map((element) => {
                        return {id: element.id, label: element.fullName, type: 'stakeholder'}
                    })
                },
            ]
            setGroupCompaniesAndStakeholders(lists)
        }
    }
    const startups =useMemo(()=> [
        {
            listName: "filter",
            subListName: "startups",
            id: "",
            label: helper.getTranslateTextByKey("AUDITLOGS_TRANSACTIONS_STARTUPS"),
            showTitle: "true",
            list: _.get(session, "getMe.startups", []).map((element) => {
                return { id: element.id, label: element.name }
            })
        }

    ],[session]);
    var handleUpdate = (values) => {
        setValues(values);
        updateFilters(values)
    }

    return (
        <React.Fragment>
            <Formik
                initialValues={{}}
                enableReinitialize={false}
                validate={null}
                onSubmit={null}
                validationSchema={null}
                render={({
                    values,
                    errors,
                    dirty,
                    handleSubmit,
                    isSubmitting,
                    handleReset,
                    setFieldValue,
                }) => {
                    console.log(values)
                    return (
                        <React.Fragment>
                            <div className={classes.container}>
                            {onlyDateFilter ? null : <React.Fragment>
                                <Field name='startups'>
                                    {({ field, form }) => (
                                        <ClaraSelect
                                            field={field}
                                            form={form}
                                            label={<Text uuid="AUDITLOGS_FILTERS_LABEL_STARTUPS"/>}
                                            placeholder={"AUDITLOGS_TRANSACTIONS_PLACEHOLDER_STARTUPS"}
                                            lists={startups}
                                            callbacks={{
                                                updateValues: handleSelectStartups
                                            }}
                                            mode={"multiselect"}
                                            className={classes.FilterWith}
                                        />
                                    )}
                                </Field>
                                <Field name='stakeholdersOrGroupCompanies'>
                                    {({ field, form }) => (<>
                                    {form.values.startups && !_.isEmpty(form.values.startups) &&
                                    <ClaraSelect
                                        label={<Text uuid="AUDITLOGS_FILTERS_LABEL_STAKEHOLDERS"/>}
                                        field={field}
                                        form={form}
                                        shouldClearValue={clearValue}
                                        modalClassName={classes.limitSize}
                                        placeholder={"AUDITLOGS_TRANSACTIONS_PLACEHOLDER_STARTUPS"}
                                        lists={groupCompaniesAndStakeholders}
                                        mode={"multiselect"}
                                        adds={[
                                            {
                                                notAdd:true,
                                                type:'all',
                                                label: 'All',
                                                id:'selectAll',
                                                onClick:()=>{
                                                    setClearValue(true)
                                                    const stakeholdersAndGroupCompanies=[];
                                                        stakeholdersAndGroupCompanies.push({
                                                            groupCompanies: _.map(groupCompaniesAndStakeholders[0].list, gc => gc.id),
                                                            filterGroupCompanies: 'group'
                                                        })
                                                        stakeholdersAndGroupCompanies.push({
                                                            stakeholders: _.map(groupCompaniesAndStakeholders[1].list, st => st.id),
                                                            filterStakeholders: 'stake'
                                                        })
                                                        form.setFieldValue('stakeholdersOrGroupCompanies', stakeholdersAndGroupCompanies)
                                                    setClearValue(false)
                                                }
                                            }
                                        ]}
                                        className={classes.FilterWith}
                                    />
                                    }</>)}
                                </Field></React.Fragment>}
                            {/* {onlyDateFilter ? null : <React.Fragment><label className={classes.labelFilter}><Text uuid="AUDITLOGS_FILTERS_LABEL_ACTIONS" /></label>
                                <Field name='transactions'>
                                    {({ field, form }) => (
                                        <ClaraSelect
                                            field={field}
                                            form={form}
                                            placeholder={"AUDITLOGS_TRANSACTIONS_PLACEHOLDER_ACTIONS"}
                                            lists={transactions}
                                            mode={"multiselect"}
                                            className={classes.FilterWith}
                                        />
                                    )}
                                </Field></React.Fragment>} */}

                            <Field name='dateFrom'>
                                {({ field, form }) => (
                                    <TextInput classNameInput={{root:classes.calendarRoot,input:classes.calendarInput}} type={'date'} label={<Text uuid="AUDITLOGS_FILTERS_LABEL_DATEFROM" />} placeholder={"dd/mm/yyyy"} field={field} form={form} />
                                )}
                            </Field>
                            <Field name='dateTo'>
                                {({ field, form }) => (
                                    <TextInput classNameInput={{root:classes.calendarRoot,input:classes.calendarInput}}  type={'date'} label={<Text uuid="AUDITLOGS_FILTERS_LABEL_DATETO" />} placeholder={"dd/mm/yyyy"} field={field} form={form} />
                                )}
                            </Field>
                            <div className={classes.botonera}>
                                <ClaraButton className={classes.buttonFilter} onClick={() => { handleUpdate(values) }} disabled={_.isEqual(valuesState, values)}>
                                    <Text uuid={filterButtonText ? filterButtonText :"AUDITLOGS_FILTERS_LABEL_FILTER"} />
                                </ClaraButton>
                            </div></div>
                        </React.Fragment>
                    )
                }}
            />

        </React.Fragment>
    );
}
import { useMemo } from 'react';
import useUpsertMailingAddress from '../graphql/useUpsertMailingAddress';
import useSession from 'src/modules/session/hooks/useSession';

const useServices = ({ initialValues }) => {

  const { mutation: upsertPurpose } = useUpsertMailingAddress();
  const { startupId } = useSession();

  return useMemo(() => ({
    initialData: async () => {
      return { ...initialValues };
    },
    SaveStep: async (context, event) => {
      try {
        const { MailingAddressInfo: { mailingAddress } } = context;


        return await upsertPurpose({
          variables: {
            startupId,
            groupCompanyId: initialValues?.id,
            groupCompany: {
              mailingAddress,
            },
          },
        });
      } catch (error) {
        console.log("Error:", error);
      }
    },
  }), [initialValues, startupId, upsertPurpose]);
}

export default useServices;

import React, { useEffect, useState } from 'react';
import { Text, TextInput } from '../index';
import _ from "lodash";
import PropTypes from 'prop-types';
import { Field } from "formik";
import classes from "./Chat.module.scss";
import ClaraRadioWithButton from "../Form/ClaraRadioWithButton/ClaraRadioWithButton";

const ChatShareCaptial = ({ responseKeyName, isForm, form, field, initialValueStart, isEditForm, className }) => {
  const nameField = responseKeyName ? responseKeyName + "." : "";
  const [selectedOption, setSelectedOption] = useState(null);
  const defaultAmount = "10000000";
  const defaultShares = "0.0001";
  const optionsNominee = [{ label: 'Yes', value: true }, { label: 'No', value: false }]
  const options = {
    DEFAULT: { keySubmit: "default", key: "DEFAULT" },
    CUSTOM: { keySubmit: "custom", key: "CUSTOM" },
  }
  const publicValues = (value, field, form) => {
    form.setFieldValue(field.name, value);
  }


  const selectButton = (op, field, form) => {
    setSelectedOption(op.key);
    publicValues(op.key, field, form);
  }
  const getParamsQuestionary = () => {
    const sharesAuthorized = _.get(form, `values.${nameField}sharesAuthorized`);
    const shares = _.get(form, `values.${nameField}shareNominalPrice`);
    if (sharesAuthorized && shares) {
      return { amount: sharesAuthorized * shares };
    }
    return { amount: 0 };
  }
  const isCustom = (field) => {

    return field.value === "CUSTOM";
  }
  useEffect(() => {

  })
  return (
    <React.Fragment>
      <div className={`${classes.ChatQuestionnaire} ${isForm ? className.ChatShareCaptial : classes.ChatShareCaptial} `}>
        {isForm && <> <h1>Update Proposed Authorised share capital</h1> <hr /> </>}

        <React.Fragment>
          {_.map(options, (option, i) => {
            const classSelected = option.key === field.value ? classes.buttonOptionSelected : null;
            const showContent = option.key === field.value;
            return (
              <React.Fragment key={i}>
                <button className={`${classes.buttonOption} ${classSelected}`} type="button" onClick={() => { selectButton(option, field, form) }}>
                  <Text uuid={`QUESTIONARY_SHARE_CAPITAL_BUTTON_${option.key}`} />
                </button>
              </React.Fragment>
            )
          })}
          {isCustom(field) ? (
            <div className={classes.ChatShareCaptialOptions}>
              <div className={classes.contentField}>
                <Field name={`${nameField}sharesAuthorized`}>
                  {({ field, form }) => (
                    <React.Fragment>
                      <TextInput
                        showRedStar={false}
                        required={true}
                        type='number'
                        field={field}
                        label={"QUESTIONARY_SHARE_CAPITAL_BUTTON_AUTHORISED_SHARES"}
                        form={form}
                        placeholder={defaultAmount}
                      // onChange={e => {
                      //   onChangeValue(e);
                      // }}
                      />
                    </React.Fragment>
                  )}
                </Field>
              </div>
              <div className={classes.contentFieldNote}>
                <Text uuid={"QUESTIONARY_SHARE_CAPITAL_BUTTON_LIMIT_AUTH_SHARES"} />
              </div>
              <div className={classes.contentField}>
                <Field name={`${nameField}shareNominalPrice`}>
                  {({ field, form }) => (
                    <React.Fragment>
                      <TextInput
                        showRedStar={false}
                        required={true}
                        type='number'
                        field={field}
                        label={"QUESTIONARY_SHARE_CAPITAL_BUTTON_SHARES"}
                        form={form}
                        placeholder={defaultShares}
                      // onChange={e => {
                      //   onChangeValue(e);
                      // }}
                      />
                    </React.Fragment>
                  )}
                </Field>
                <label></label>
              </div>

              <div className={classes.contentFieldNote}>
                <Text uuid={"QUESTIONARY_SHARE_CAPITAL_BUTTON_LIMIT_PAR_VALUES"} />
              </div>
              <div className={classes.contentFieldLabel}>
                <Text uuid={"QUESTIONARY_SHARE_CAPITAL_BUTTON_REQUIERED_SHARE_CAPITAL"} params={getParamsQuestionary()} />
              </div>

            </div>
          ) : null}
          {isEditForm ? <React.Fragment><hr />
            <h1>Nominee Shareholders</h1> <hr />
            <div className={classes.contentField}>
              <Field name={`${nameField}nomineeShareholders`}>
                {({ field, form }) => (
                  <React.Fragment>
                    <ClaraRadioWithButton
                      field={field}
                      form={form}
                      label={"Does any of the shareholders act as a nominee shareholder? (i.e.person holding shares as nominee for another"}
                      changeParams={field.name}
                      options={optionsNominee}
                      placeholder={""}
                      className={{
                        root: classes.ChatClaraRadioButton,
                        input: classes.ChatClaraRadioButtonInputNominee
                      }}
                    />
                  </React.Fragment>
                )}
              </Field>
              <label></label>
            </div></React.Fragment>: null}

        </React.Fragment>
      </div>
    </React.Fragment >
  );
}
ChatShareCaptial.propTypes = {
  responseKeyName: PropTypes.string.isRequired,
  initialValueStart: PropTypes.object.isRequired,

};
export default ChatShareCaptial;

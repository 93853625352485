import _ from "lodash";
import { useMemo } from "react";
import { assign } from 'xstate';

const useActions = () => {
  return useMemo(() => ({
    setContextAfterFirstInvoke: assign((context, event) => {
      const values = _.get(event, 'data');
      const result = {
        ...context,
        currentQuestionnarie: 0,
        questionnarie: values,
      };
      return result;
    }),
    setContextCompleteQuestionStep: assign((context, event) => {
      let currentValues = _.get(event, 'payload.values');
      const prevResult = _.get(context, 'results');
      let newValues;
      if (prevResult !== undefined) {
        const addPosition =  _.get(context, 'currentQuestionnarie');
        _.set(prevResult, `${addPosition}`, currentValues);
        newValues = [...prevResult];
      } else {
        newValues = [];
        newValues.push(currentValues);
      }
      return {
        ...context,
        results: newValues,
      };
    }),
    setAddCount: assign((context, event) => {
      const actualCount = _.get(context, 'currentQuestionnarie');
      return {
        ...context,
        currentQuestionnarie: actualCount + 1,
      };
    }),
    setRemoveCount: assign((context, event) => {
      const actualCount = _.get(context, 'currentQuestionnarie');
      return {
        ...context,
        currentQuestionnarie: actualCount - 1,
      };
    }),
  }), []);
}

export default useActions;
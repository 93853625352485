import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Stack, Link } from "@mui/material";
import {
  addAssistantText,
  clearAssistatText,
} from "src/modules/generic/store/action";
import Button from "src/modules/generic/components/Buttons/Button";
import Form from "src/components/forms/Form/Form";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import TemplateWizardInfo from "src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardInfo";
import Typography from "src/modules/generic/components/Texts/Typography";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import reactImage from "src/images/Group2022.png";
import classModule from "../classes.module.scss";

function WhatHappensTechLicenceStepForm() {
  const { next, context, cancel, setAction } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const Paragraph = () => {
    return (
      <div className={classModule.Paragraph}>
        <Typography component="p" weight="bold" variant="body">
          {translate(
            "MODULES.RENEWALS_ADGM.WHAT_HAPPENS_TECH_LICENCE.STEP_1.CONTENT_1"
          )}
        </Typography>
        <Typography component="p" variant="body">
          <span
            dangerouslySetInnerHTML={{
              __html: translate(
                "MODULES.RENEWALS_ADGM.WHAT_HAPPENS_TECH_LICENCE.STEP_1.CONTENT_2"
              ),
            }}
          />
        </Typography>
        <Typography component="p" weight="bold" variant="body">
          {translate(
            "MODULES.RENEWALS_ADGM.WHAT_HAPPENS_TECH_LICENCE.STEP_1.CONTENT_3"
          )}
        </Typography>
        <Typography component="p" variant="body">
          {translate(
            "MODULES.RENEWALS_ADGM.WHAT_HAPPENS_TECH_LICENCE.STEP_1.CONTENT_4"
          )}
        </Typography>
      </div>
    );
  };

  const initialValues = useMemo(() => {
    return context?.TechLicenceStepForm ?? {};
  }, [context]);

  const handleCancel = () => cancel();

  const handleSubmit = async (values) => next(values);

  const handleCloseCompany = (values = {}) => {
    setAction({ action: "GO_CLOSE_COMPANY", payload: values });
  };

  useEffect(() => {
    dispatch(
      addAssistantText(
        translate(
          "MODULES.RENEWALS_ADGM.WHAT_HAPPENS_TECH_LICENCE.STEP_1.TOOLTIP_1"
        )
      )
    );
    dispatch(
      addAssistantText(
        translate(
          "MODULES.RENEWALS_ADGM.WHAT_HAPPENS_TECH_LICENCE.STEP_1.TOOLTIP_2"
        )
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const schema = Yup.object().shape({});

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizardInfo
        props={{
          title: translate("MODULES.RENEWALS_ADGM.COMMON.TITLE"),
          subTitle: translate("MODULES.RENEWALS_ADGM.COMMON.SUBTITLE"),
          image: reactImage,
          isRenewalImage: true,
        }}
        buttons={{
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("BUTTON_CANCEL")}
            </Button>
          ),
          submit: (
            <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>
              {translate("MODULES.RENEWALS_ADGM.COMMON.BTN_START")}
            </SubmitButton>
          ),
        }}
      >
        <>
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
          >
            <Paragraph />
            <Typography component="p" variant="s">
              {translate("MODULES.RENEWALS_ADGM.COMMON.CLICK")}
              <Link onClick={handleCloseCompany}>
                {translate("MODULES.RENEWALS_ADGM.COMMON.HERE")}
              </Link>
              {translate("MODULES.RENEWALS_ADGM.COMMON.WANT_CLOSE")}
            </Typography>
          </Stack>
        </>
        <div>&nbsp;</div>
      </TemplateWizardInfo>
    </Form>
  );
}

export default WhatHappensTechLicenceStepForm;

import React, {Component} from "react";
import {connect} from "react-redux";
import * as Yup from "yup";
import _ from "lodash";

import {Loading} from "../../../components";
import Wizard from "../wizard";
import Step1 from "./Steps/Step1";
import {uploadedAgreementValidations} from "../validations";
import FormikHelper from "../../../utils/formikHelper";

import {Constants, ServerConnect} from "../../../utils";
import {addAgreement as upsertAgreement} from "../../../graphql/mutations/addAgreement";
import Mustache from "mustache";
import {getGroupCompaniesWrong} from "../../../graphql/mutations/addStakeholder";
import {stripTypenames} from "../../../utils/graphql-util";
import {goToPage, goToPrevPage} from '../../../../store/actions/page'
import GenericHOCHook from "../../../../components/generic/GenericHOCHook";

const stakeholderList = `{
        stakeholderList(startupId: "{{startupId}}") {
            id
            fullName
            roles {
                id
                name
                groupCompany{
                    id
                  }
                  stakeholder{
                    id
                  }
            }
    }
}`;

const documentTypeList = `{
  getDocumentTypes {
    code
    label
    fields {
      key
      label
      placeholder
      tooltip
      required
      type
      options {
        id
        label
      }
    }
  }
}`;

const validationSchema = Yup.object().shape(uploadedAgreementValidations);

class DocumentForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            showChildren: true,
            groupCompanies: [],
            stakeholders: [],
            authorizedSignatories: [],
            c: 0,
            data: {},
        };
    }

    handleClose = () => {
        this.props.goToPrevPage();
    }
    getDraftDocument = () => {
        return new Promise(async (resolve, reject) => {
            var query_params = {
                startup_id: this.props.session.startup.id
            };
            let query = `{ draftDocumentList(startupId: "{{startup_id}}") { id 
        file{
            id
            name
          }
        }
      }
      
    `;
            var queryDraft = Mustache.render(query, query_params);
            try {
                let files = await ServerConnect.graphQlQuery(queryDraft);
                let file = _.find(files.draftDocumentList, (document) => {
                    return document.id == this.props.params.id
                });
                console.log(file);
                this.setState(prevState => ({
                    data: {...prevState, file: file.file, draftDocumentId: file.id},
                    showLoading: false,
                    showChildren: false
                }))
            } catch (e) {
                return {}
            }
        });

    }

    componentDidMount() {
        // For test in case the query fails. Remove or replace later.
        console.log("this.props.params", this.props.params)
        let initStateData = {
            stakeholders: [],
            authorizedSignatories: [],
            groupCompanies: [],
            documentTypes: [],
        }
        if (this.props.isEditDraft) {
            initStateData.showLoading = true;
            initStateData.showChildren = false
            this.getDraftDocument().then(result => {
                console.log("Tengo la data")
            });
        }

        this.setState(initStateData);

        const queryParams = {startupId: this.props.session.startup.id};
        // const query = Mustache.render(getGroupCompanies, queryParams);

        ServerConnect.graphQlQuery(getGroupCompaniesWrong,queryParams).then(result => {
            if (result && result.getGroupCompanies) {
                this.setState({
                    groupCompanies: _.orderBy(result.getGroupCompanies, ['isHoldingCompany'], ['desc']).map(gc => ({
                        id: gc.id,
                        label: gc.name,
                        authorizedSignatories: gc.authorizedSignatories
                    })),
                    authorizedSignatories: _.flatten(
                        result.getGroupCompanies.map(gc =>
                            gc.authorizedSignatories.map(as => ({...as.stakeholder}))
                        )
                    ).map(item => ({
                        id: item.id,
                        label: item.fullName,
                        email: item.email
                    }))
                });
            }
        });

        const stakeholdersQuery = Mustache.render(stakeholderList, queryParams);

        ServerConnect.graphQlQuery(stakeholdersQuery).then(result2 => {
            if (result2 && result2.stakeholderList) {
                this.setState({
                    stakeholders: this.mapStakeholders(result2.stakeholderList)
                });
            }
        });
        ServerConnect.graphQlQuery(documentTypeList).then(result => {
            if (result && result.getDocumentTypes) {
                this.setState({
                    documentTypes: result.getDocumentTypes.map(at => ({
                        id: at.code,
                        label: at.label
                    }))
                });
            }
        });

        ServerConnect.requestApi(
            `/contentful/tooltips/${Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.ADD.TOOLTIP}`,
            {method: "GET", headers: {"Content-Type": "application/json"}}
        )
            .then(res => {
                console.log("response", res.response);
                this.setState({
                    tooltips: res.response
                });
            })
            .catch(err => {
                console.log("Tooltips from contentful error: ", err);
            });
    }

    mapAuthorized(stakeholders) {
        const authorized = [];

        stakeholders.forEach(st => {
            if (st.roles.some(rol => rol.name === "AUTHORIZEDSIGNATORY")) {
                authorized.push({
                    id: st.id,
                    label: st.fullName
                });
            }
        });

        return authorized;
    }

    mapStakeholders(stakeholderList) {
        return stakeholderList.map(st => ({
            id: st.id,
            label: st.fullName
        }));
    }


    submitForm(values, actions) {
        this.setState({
            showLoading: true
        });
        const draftDocumentId = values.draftDocumentId;
        const startupId = this.props.session.startup.id;

        const structuredResponse = FormikHelper.mapAgreementDataForMutation(values);

        let data = {
            startupId,
            draftDocumentId,
            ...structuredResponse
        };

        data = stripTypenames(data);

        ServerConnect.graphQlMutation(upsertAgreement, data).then(result => {
            
            this.props.goToPrevPage();
        });
    }

    getInitialValues() {
        let data = this.props.data;
        let initialValues = {};
        if (data) {
            let file = data.file;
            delete file.__typename;
            initialValues = {
                file: file,
                draftDocumentId: data.id,
                agreementStatus: "UPLOADED"
            };
        }

        return initialValues;
    }

    render() {
        const initialValues = this.getInitialValues();
        return (
            <div>
                <Loading show={this.state.showLoading} showChildren={false}>
                    <div>
                        <Wizard
                            formTitle={"Add Document"}
                            formSubTitle={
                                "Lorem ipsum dolor description that goes within every step"
                            }
                            leftEducationId={
                                Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.ADD
                                    .LEFT_EDUCATION_ID
                            }
                            onSubmit={(values, actions) => {
                                this.submitForm(values, actions);
                            }}
                            handleClose={this.handleClose}
                            validationSchema={validationSchema}
                            initialValues={{
                                agreementStatus: Constants.AGREEMENT_STATUS.UPLOADED,
                                ...this.state.data
                            }}
                            isInitialValid
                        >
                            <Wizard.Page>
                                <GenericHOCHook withCategories={{avoidEmptyDocs: true, kind: "STARTUP"}}>
                                    <Step1
                                        groupCompanies={this.state.groupCompanies}
                                        stakeholders={this.state.stakeholders}
                                        documentTypes={this.state.documentTypes}
                                        authorizedSignatories={this.state.authorizedSignatories}
                                        refresh={() =>
                                            this.setState(prevState => ({c: (prevState.c += 1)}))
                                        }
                                        c={this.state.c}
                                        tooltips={this.state.tooltips}
                                        startupId={this.props.session.startup.id}
                                        data={this.props.data}
                                    />
                                </GenericHOCHook>
                            </Wizard.Page>
                        </Wizard>
                    </div>
                </Loading>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    session: state.session
});
const mapDispatchToProps = dispatch => ({
    goToPage: (page, params) => {
        goToPage(dispatch, page, params)
    },
    goToPrevPage: (reload, defaultPage, params) => {
        goToPrevPage(dispatch, reload, defaultPage, params)
    },
  
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DocumentForm);

import React from "react";
import _ from "lodash";
import Text from "../../../../../text/Text/Text";
import moment from "moment";
import classes from "./PartiesUploaded.module.scss";

const History = ({ data, ...props }) => {

  return (
    <div className={classes.containerTabHistory}>
      <div className={classes.containerFileName}>
        <i className={classes.initialIcon}></i>
        <label className={classes.textFileName}>
          {_.get(data, "file.name", "-")}
        </label>
      </div>
      {_.reverse(
        _.get(data, "historyTimeLine").map((element) => {
          return (
            <div className={classes.containerHistoryText}>
              <label>
                <Text
                  uuid={`DOCUMENT_HISTORY_${element.event}`}
                  params={{
                    date: moment(element.createdAt).format("DD MMM YYYY"),
                    user: `${_.get(element, "createdBy.firstName", "")}  ${_.get(element,"createdBy.lastName","")}`,
                  }}
                />
              </label>
            </div>
          );
        })
      )}
    </div>
  );
};

export default History;
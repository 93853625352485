import React, { FC, useEffect } from 'react'
import Button from "../../../../../generic/components/Buttons/Button";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import _ from "lodash";
import UploadForm from "../../../forms/UploadForm";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import useCustomMachine from '../../../../../generic/context/MachineContext/useMachine';
import { addAssistantText, clearAssistatText } from "src/modules/generic/store/action";
import { useDispatch } from "react-redux";

const UploadDocumentFormStep: FC<{}> = () => {
  const { send, next, context } = useCustomMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch()

  const handlePrev = () => {
    send("PREVIOUS")
  }

  const handleCancel = () => {
    send('CANCEL')
  }
  const handleCompleteSubmit = async (values: object) => {
    next(values);
  }

  useEffect(() => {
    dispatch(clearAssistatText())
    dispatch(addAssistantText(translate('MODULES.EQUITY.STEPS.UPLOAD_DOCUMENTS_STEP.TOOLTIP_2')))
  }, [])

  return (
    <>
      <UploadForm
        propsTemplate={{
          title: translate(`MODULES.EQUITY.STEPS.UPLOAD_DOCUMENTS_STEP.TITLE_${context.type.toUpperCase()}`, { agreementType: translate(`MODULES.EQUITY.AGREEMENT_TYPE.${context.type}`) }),
          subTitle: translate(`MODULES.EQUITY.STEPS.UPLOAD_DOCUMENTS_STEP.SUBTITLE_${context.type.toUpperCase()}`)
        }}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        buttons={{ cancel: <Button onClick={handleCancel}>Cancel</Button>, previous: <Button onClick={handlePrev}>Previous</Button> }}
      />
    </>
  )
}
export default UploadDocumentFormStep
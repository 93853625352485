import React, { useEffect } from 'react';
import { Constants } from '../../../../utils/constants'
import classes from "../ViewForms.module.scss";
import { useDispatch } from "react-redux";
import _ from "lodash";
import ServerConnect from "../../../../utils/ServerConnect";
import { RoleAccess } from '../../../index';


const ShareholdersQuestionnaireView = ({ isDraftData, ...props }) => {

    const dispatch = useDispatch();
    const { mode, data } = props;

    const downloadHandler = (file) => {
        let url = ServerConnect.getUrlFile(file.file.id);
        ServerConnect.downloadFileAsDoc(url, file.file.name);
    }


    useEffect(() => {
    }, [,]);

    let renderPaidOrNot = (value) => {
        return value == false ? 'Unpaid' : 'Paid'
    }
    let renderYesOrNo = (value) => {
        return value ? 'Yes' : 'No'
    }

    let renderSharesTypeAnswer = (value) => {
        switch (value) {
            case Constants.QUESTIONNAIRE_KEYS.CUSTOM:
                return 'Custom Setup';
            case Constants.QUESTIONNAIRE_KEYS.DEFAULT:
                return 'Recommended Setup'
        }
    }


    return (
        <React.Fragment>
            <div className={classes.tableRow}>
                <div className={classes.tableColumn}>
                    <label>Name Of Shareholders</label>
                </div>
                <div className={classes.tableColumn}>
                    <label>Shares Allocated</label>
                </div>
                <div className={classes.tableColumn}>
                    <label>Paid/Unpaid</label>
                </div>
                < RoleAccess action={Constants.ACTIONS.MANAGE_MATTER}>
                    <button
                        onClick={() => { dispatch({ type: 'GO_TO', page: Constants.PAGES.questionnaireShareholders, params: { matterId: props.matterId, type: "shareholdersIssues", shareholdersAmount: props.data.shareholdersAmount } }) }}
                        className={`${classes.buttonEditFloat} ${classes.firstSharesEditButton}`} disabled={isDraftData}>
                        <span >Edit Details</span>
                    </button>
                </RoleAccess>
            </div>
            <hr className={`${classes.Separator} ${classes.modifiedSeparatorShares}`} />
            {data.shareholders.map(shareholder => {
                return (
                    <div className={classes.tableRow}>
                        <div className={classes.tableColumn}>
                            <label>{shareholder.fullName}</label>
                        </div>
                        <div className={classes.tableColumn}>
                            <label>{Number(parseFloat(shareholder.shares).toFixed(2)).toLocaleString('en', {
                                minimumFractionDigits: 2
                            })}</label>
                        </div>
                        <div className={classes.tableColumn}>
                            <label>{renderPaidOrNot(shareholder.paid)}</label>
                        </div>
                    </div>
                )
            })}
            {_.get(data, 'shareType') && <>

                < RoleAccess action={Constants.ACTIONS.MANAGE_MATTER}>
                    <button className={`${classes.buttonEditFloat} ${classes.secondSharesEditButton}`}>
                        <span onClick={() => { dispatch({ type: 'GO_TO', page: Constants.PAGES.questionnaireShareholders, params: { matterId: props.matterId } }) }}>Edit Details</span>
                    </button>
                </RoleAccess>
                <hr className={`${classes.Separator} ${classes.modifiedSeparatorShares} ${classes.spacedSeparator}`} />
                <div className={`${classes.ContainerRow}`}>
                    <label>Share type:</label>
                    <span>{renderSharesTypeAnswer(_.get(data, 'shareType', ''))}</span>
                </div>
                <div className={`${classes.ContainerRow}`}>
                    <label>Share amount:</label>

                    <span>{Number(parseFloat(_.get(data, 'sharesAuthorized', '')).toFixed(2)).toLocaleString('en', {
                        minimumFractionDigits: 2
                    })}</span>
                </div>
                <div className={`${classes.ContainerRow}`}>
                    <label>Share remaining:</label>
                    <span>{Number(parseFloat(_.get(data, 'shareRemaining', '')).toFixed(2)).toLocaleString('en', {
                        minimumFractionDigits: 2
                    })}</span>
                </div>
                <div className={`${classes.ContainerRow}`}>
                    <label>Nominee Shareholders:</label>
                    <span>{renderYesOrNo(_.get(data, 'nomineeShareholders', ''))}</span>
                </div>
            </>
            }

        </React.Fragment >
    )
}

export default ShareholdersQuestionnaireView;

import { useEffect } from 'react';
import useQuery from "../../../custom/useQuery";

const query =`query {
 getMe {
    _id
    startups {
      id
      name
      groupCompanies{
      id
      name
      }
      stakeholders{
      id
      fullName
      }
 }
 }
}`;
const useGetMe = (variables) =>{

    const {loading, error, data,refetch} = useQuery(query,variables);

    return  {loading,error,data,refetch} ;
}
export default useGetMe;
import useMutation from 'src/hooks/custom/useMutation';

export const SEND_JURISDICTION_MAIL = `
  mutation userChooseAJurisdiction($startupId:ID!)
  {
    userChooseAJurisdiction(startupId:$startupId)
  }
`;

const useUpsertJurisdictionMail = () => {
  const [mutation, loading] = useMutation(
    SEND_JURISDICTION_MAIL,
    { showSuccessNotification: false }
  ); 
  return { mutation, loading };
};

export default useUpsertJurisdictionMail;
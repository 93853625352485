import FirstStep from "./Steps/FirstStep";
import SecondStep from "./Steps/SecondStep";
import Machine from "../../../../generic/components/Machine/MachineClass";

const isAllShareholdersAndDirectorsConfirmed = ({ data }) => data.confirm_directors_shareholders.isAllShareholdersAndDirectorsConfirmed
const isNotAllShareholdersAndDirectorsConfirmed = ({ data }) => !data.confirm_directors_shareholders.isAllShareholdersAndDirectorsConfirmed

const machineDefinition = {
  initial: 'InitStep',
  states: {
    InitStep: {
      always: [{
        cond: 'isEdit',
        target: 'share_capital_form',
      },
      {
        cond: 'isNoEdit',
        target: 'confirm_directors_shareholders'
      },
      
      ]
    },
    confirm_directors_shareholders: {
      on: {
        NEXT: { target: 'set_confirm_directors_shareholders', actions: 'setContext' },
        CANCEL: 'finish_share_capital'
      }
    },
    set_confirm_directors_shareholders: {
      exit: '',
      on: {
        '': [
          { target: 'share_capital_form', cond: 'isAllShareholdersAndDirectorsConfirmed' },
          { target: 'finish_share_capital', cond: 'isNotAllShareholdersAndDirectorsConfirmed' },
        ]
      }
    },
    share_capital_form: {
      on: {
        NEXT: { target: 'finish_share_capital', actions: 'setContext' },
        PREVIOUS: 'confirm_directors_shareholders',
        CANCEL: 'finish_share_capital'
      }
    },
    finish_share_capital: {
      type: 'final',
      entry: 'final',
    },
    onDone: {
      actions: 'stopMachine'
    }
  }
}
const options = {
  guards: {
    isEdit: ({ data }) => {
      return data?.isEdit
    },
    isNoEdit: ({ data }) => {
      return !data?.isEdit
    },
    isAllShareholdersAndDirectorsConfirmed,
    isNotAllShareholdersAndDirectorsConfirmed
  }
}

const steps = {
  confirm_directors_shareholders: FirstStep,
  share_capital_form: SecondStep,
}

class MachineShareCapital extends Machine {
  constructor() {
    super(machineDefinition, options, steps);
  }
}

export default MachineShareCapital
import { useMemo } from 'react';
import useSession from 'src/modules/session/hooks/useSession';
import useUpsertSipDocument from 'src/modules/startup/equity/graphql/mutation/upsertSipDocument';

const useServices = (initialData) => {
  const { startupId } = useSession();
  const { upsertSipDocument } = useUpsertSipDocument({ showSuccessNotification: false })
  const getDocuments = (values) => {
    if (values) {
      if (values.file || values.document) {
        if (values.file) return { uploadedFiles: [{ ...values.file, title: values.name, documentType: values.documentType }] }
        return { documentsUploaded: [{ ...values.document }] }
      }
    }
    return {}
  }
  return useMemo(() => ({
    getInitialData: async () => {
      return initialData

    },
    useUpsertSipDocument: async (context, event) => {
      const variables = {
        startupId,
        shareIncentivePlan: {
          id: context?.getInitialData?.id,
          shareClassId: context?.getInitialData?.shareClassId,
          groupCompanyId: context?.getInitialData?.groupCompanyId,
          documents: getDocuments(context?.SipStep)

        },
      }
      
      const result = await upsertSipDocument({ variables })

      return result;
    },

  }), [initialData, upsertSipDocument, startupId]);
}
export default useServices;
import React from 'react';
import PrimaryButton from "../../../../components/generic/Buttons/PrimaryButton/PrimaryButton";
import Text from "../../../../components/text/Text/Text";
import Title from "../../../../components/text/Title/Title";
import classesModal from "./PaymentTimeOutMessage.module.scss";


const PaymentTimeOutMessage = ({ onRetry }) => {




  return (
    <React.Fragment>
      <div className={classesModal.PaymentTimeOutMessageGlobal}>
        <div className={classesModal.PaymentTimeOutMessage}>
          <div>
            <Title><Text uuid={`MODULES.CHECKOUT.PAYMENT_TIMEOUT_TITLE`} /></Title>
            <Text uuid={`MODULES.CHECKOUT.PAYMENT_TIMEOUT_TEXT`} />
            <PrimaryButton onClick={onRetry} className={classesModal.Button}>
              <Text uuid={`MODULES.CHECKOUT.PAYMENT_TIMEOUT_BUTTON`} />
            </PrimaryButton>
          </div>
        </div>
      </div>
    </React.Fragment >
  )
}

export default PaymentTimeOutMessage;

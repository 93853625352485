import { createMachine } from 'xstate';

const machine =
  createMachine({
    id: 'EditGeneralDetails',
    initial: 'initialData',
    states: {
      initialData: {
        invoke: {
          src: 'initialData',
          id: 'initialData',
          onDone: [
            {
              target: 'pincasData',
              actions: ['setContextAfterInvoke']
            }
          ],
        }
      },
      pincasData: {
        invoke: {
          src: 'pincasData',
          id: 'pincasData',
          onDone: [
            {
              target: 'EvaluateIsManagedByClara',
              actions: ['setContextAfterInvoke']
            }
          ],
        }
      },
      EvaluateIsManagedByClara: {
        always: [
          {
            cond: 'managedByClara',
            target: 'EditGeneralDetailsManagedByClaraForm',
          },
          {
            cond: 'notManagedByClara',
            target: 'EditGeneralDetailsForm',
          },
        ],
      },
      EditGeneralDetailsForm: {
        on: {
          NEXT: {
            target: 'SaveGeneralDetails',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      EditGeneralDetailsManagedByClaraForm: {
        on: {
          NEXT: {
            target: 'SaveGeneralDetails',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      SaveGeneralDetails: {
        invoke: {
          src: 'SaveGeneralDetails',
          id: 'SaveGeneralDetails',
          onDone: [
            {
              target: 'finish',
            }
          ],
          onError: [
            {
              target: 'EditGeneralDetailsForm'
            }
          ]
        }
      },
      cancel: {
        entry: 'cancelMachine',
        type: 'final'
      },
      finish: {
        entry: 'stopMachine',
        type: 'final'
      }
    }
  });

export default machine;

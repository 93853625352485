import React from 'react';
import cls from "./classes.module.scss";
import rocketImage from "src/images/lets-form-company.svg";
import cloudsImage from "src/images/clouds.svg";
import ScaleItemFree from 'src/modules/checkout/components/ScaleItemFree';
import TagsUnLocked from 'src/modules/checkout/components/TagsUnLocked';

function CardBenefits({...props }) {

  const scaleItemList = [
    {
      icon: 'Legal',
      title: 'Share incentive Plan',
      tags: {
        icon: "Unlocked",
        text: 'Unlocked',
        color: '#E96199'
      }
    },
    {
      icon: 'Document',
      title: 'Founder´s Agreement',
      tags: {
        icon: "Unlocked",
        text: 'Unlocked',
        color: '#E96199'
      }
    },
    {
      icon: 'document-stack',
      title: 'Generate documents',
      tags: {
        icon: "Switch-around",
        text: 'Unlimited',
        color: '#E96199'
      }
    },
    // {
    //   icon: 'Sharing',
    //   title: 'Profile shares',
    //   tags: {
    //     icon: "Switch-around",
    //     text: 'Unlimited',
    //     color: '#E96199'
    //   }
    // },
    {
      icon: 'Document-edit',
      title: 'Document uploads',
      tags: {
        icon: "Switch-around",
        text: 'Unlimited',
        color: '#E96199'
      }
    },
    {
      icon: 'Heart',
      title: 'Health Check',
      tags: {
        icon: "Switch-around",
        text: 'Unlimited',
        color: '#E96199'
      }
    }
  ];

  return (
    <div className={cls.containerListData}>
      <img src={rocketImage} className={cls.rocket} alt="rocket" />
      <img src={cloudsImage} className={cls.clouds} alt="clouds" />
      <div className={cls.content}>
        {scaleItemList.map((element, i) => {
          return <div className={cls.listItems} >
            <ScaleItemFree
              icon={element.icon}
              title={element.title}
              key={i}
            ></ScaleItemFree>
            <TagsUnLocked icon={element.tags.icon} renderItem={element.tags.text} color={element.tags.color} />
          </div>
        })}
      </div>
    </div>
  );
}
export default CardBenefits;
import React, {useEffect, useState} from 'react';
import ServerConnect from "../../../../utils/ServerConnect";
import notification from "../../../../utils/notification";
import _ from "lodash";
import Mustache from 'mustache'

const QUERY = `{
  getMattersList(matterId: "{{matterId}}") {
    id
    companyType
    KYC {
      engagement {
        id
        paymentLink
		invoiceNumber
      }
    }
  }
}
`;


export default function useEngagementLinkPaid(matterId) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const query_params = {
      matterId,
    };
    const query = Mustache.render(QUERY, query_params);
    setLoading(true);

    ServerConnect.graphQlQuery(query).then(result => {
      let newData = _.get(result, "getMattersList.0", null);
      setData(newData)
      setLoading(false);
    }).catch(err => {
      notification.sendNotification(err.message, "error", 5000);
      setLoading(false);
    });
  }, [matterId]);

  return { data, loading };
}

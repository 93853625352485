import React, { Component } from "react";
import _ from "lodash";
import classes from "./Table.module.scss"
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Constants } from "../../utils/constants";
import ColumnHeader from "./columnHeader";

class ClaraTable extends Component {
  state = {
    groups: null,
    data: null,
    order: {
      field: null,
      orderBy: null
    }
  };

  /* React Methods */
  componentWillUpdate(nextProps, nextState, snapshot) {
    if (nextProps.data !== this.props.data) {
      this.updateData(nextProps, nextState);
      return;
    }
    if (nextProps.filter !== this.props.filter) {
      this.updateData(nextProps, nextState);
      return;
    }
    if (nextState.order !== this.state.order) {
      this.updateData(nextProps, nextState);
      return;
    }
  }

  componentWillMount() {
    if (this.props.defaultOrder) {
      this.setState({
        order: this.props.defaultOrder
      })
    }
    let state = this.state;
    state.order = this.props.defaultOrder;
    this.updateData(this.props, state);
  }

  /* Update data */
  filterData = (data, filter, fields) => {
    if (filter && filter !== "") {
      let dataFilter = _.filter(data, (element) => {
        var find = _.find(fields, field => {
          if (field.key && element[field.key]) {
            return element[field.key].toString().toLowerCase().indexOf(filter.toLowerCase()) >= 0;
          }
          return false
        })
        return find;
      });
      return dataFilter;
    }
    return data
  }

  orderData = (data, order) => {
    if (order && order.field) {
      return data.sort((a, b) => {
        if (order.field.order && order.field.order.action) {
          if (typeof a == "string") {
            return order.field.order.action(a, b);
          } else {
            return order.field.order.action(a[order.field.key], b[order.field.key], order.orderBy);
          }
        }
        if (order.orderBy.key == Constants.ORDERS_KEYS.DESC) {
          return (a[order.field.key] < b[order.field.key]) ? 1 : (b[order.field.key] === a[order.field.key]) ? 0 : -1
        }
        return (a[order.field.key] > b[order.field.key]) ? 1 : (b[order.field.key] === a[order.field.key]) ? 0 : -1
      })
    }
    return data;
  }

  updateData = (props, state) => {
    if (props.groups) {
      let groups = [];
      let groupsPostion = {};
      _.forEach(props.groups, (group) => {
        groupsPostion[group.key] = groups.length;
        groups.push({
          options: {
            label: group.label,
            open: true,
          },
          data: []
        });
      });
      _.forEach(props.data, (data) => {
        const group = props.getGroup(data);
        groups[groupsPostion[group]].data.push(data);
      })
      _.forEach(groups, (group) => {
        group.data = this.filterData(group.data, props.filter, props.fields);
        group.data = this.orderData(group.data, state.order);
      });
      this.setState({
        groups,
        data: null
      })
    } else {
      let data = this.filterData(props.data, props.filter, props.fields);
      data = this.orderData(data, state.order);
      this.setState({
        data: data,
        groups: null
      })
    }
  }

  /* Section to get dinamic components*/
  sortAgreement = (name, element) => {
    alert("Order");
  }

  /* Action pages */
  toogleGroup = (group, i) => {
    let { groups } = this.state;
    groups[i].options.open = !groups[i].options.open;
    this.setState({
      groups
    })
  }

  /* Render Functions */
  getRows = (data) => {
    let { fields, openRow = null } = this.props;
    return data.map((row, i) => {
      return (<React.Fragment key={i}>
        <TableRow key={i}>
          {fields.map((field, j) => {
            let value = "";
            if (field.key) {
              value = row[field.key];
            }
            if (field.decorator) {
              value = React.createElement(field.decorator, { value, row });
            }
            return (
              <TableCell key={j} classes={{ root: field.className }}>
                {value}
              </TableCell>
            )
          })}
        </TableRow>
        {row.showRowExtra && <tr> < TableCell key={row.id + "extraRow"} style={{ borderTop: "hidden" }} colSpan={fields.length}>{this.props.renderRow(row)}</TableCell></tr>}
      </React.Fragment>
      )
    });
  }

  setOrder = (field, orderBy) => {
    let order = {
      field: field,
      orderBy: orderBy
    }
    this.setState({
      order
    })
  }

  getColumnHeader = (field) => {
    return (
      <>
        {field.order
          ? <span>
              <ColumnHeader
                displayName={field.label}
                list={field.order}
                field={field}
                setOrder={this.setOrder}
                class={`${classes.arrow}`}
              />
            </span>
          : <span class={`${classes.simpleHeader}`}>
              {field.label}
            </span>
          }
      </>
    )
  }

  getContent = () => {
    let { data, groups } = this.state;
    let { fields } = this.props;
    const cantFields = Object.keys(fields).length;
    if (data) {
      return this.getRows(data);
    } else if (groups) {
      return groups.map((group, i) => {
        const classOpen = group.options.open ? classes.Open : classes.Close;
        return (
          <React.Fragment key={i} >
            <TableRow className={classes.TitleGroup}>
              <TableCell colSpan={cantFields} >
                <a className={`maskAfter ${classes.Arrow} ${classOpen}`} onClick={(e) => { this.toogleGroup(group, i) }}>
                  <span>{group.options.label} ({group.data.length})</span>
                </a>
              </TableCell>
            </TableRow>
            {group.options.open ? this.getRows(group.data) : null}
          </React.Fragment>
        )
      })
    } else {
      return null;
    }
  }

  render() {
    let { fields, className } = this.props;
    return (
      <Table className={className}>
        <TableHead>
          <TableRow>
            {fields.map((field, i) => {
              if (field.order === true) {
                field.order = {
                  title: "Show First",
                  formOrder: [{ key: Constants.ORDERS_KEYS.ASC, label: "Ascending" }, { key: Constants.ORDERS_KEYS.DESC, label: "Descending" }]
                }
              }
              return (
                <TableCell key={i} className={field.className}>
                  {this.getColumnHeader(field)}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {this.getContent()}
        </TableBody>
      </Table>
    );
  }
}

export default (ClaraTable)

import React from 'react'
import * as Yup from 'yup'
import Form from '../../../../components/forms/Form/Form'
import useTranslate from '../../../generic/hooks/useTranslate'

import ControllerInput from '../../../generic/components/Inputs/ReactHookForm/ControllerInput'
import TemplateLabel from '../../../generic/templates/Label'
import SubmitButton from '../../../generic/components/Buttons/SubmitButton'
import FormProps from '../../../generic/forms/interfaces/FormProps';
import TemplateFormDefault from "../../../generic/templates/Modal";
import cls from './Form.module.scss';
import { Constants } from '../../../../v1/utils'
import CheckboxInput from '../../../generic/components/Inputs/Checkbox'
import _ from 'lodash'
import Typography from '../../../generic/components/Texts/Typography'
import useCustomMachine from '../../../generic/hooks/useCustomMachine'
import ClassNames from 'classnames';
import SelectInput from '../../../../components/inputs/Select/SelectInput/SelectInput'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import useGetDocumentAssociated from '../../graphql/equity/query/useGetDocumentsAssociated'
import useSession from '../../../session/hooks/useSession'



const schema = Yup.object().shape({
    documentsUploaded:Yup.object().nullable().required('Required')
})

const FormMoreDetails: React.FC<FormProps> = ({
    initialValues: defaultValues = {},
    onCompleteSubmit,
    Template = TemplateFormDefault,
    buttons = {},
    buttonSubmitProps,
    propsTemplate }) => {


    const { translate } = useTranslate();

    const handleSubmit = async values => {
        onCompleteSubmit && await onCompleteSubmit(values);
    }

    const { startupId } = useSession();

    const { state } = useCustomMachine();

    const variables = React.useMemo(() => {
        const { numbersOfIteration, companies,paramsData } = state.context;
        const {stakeholderId}=paramsData
        const groupCompanyId = _.get(companies, `[${numbersOfIteration}].id`)
        return { stakeholderId:stakeholderId , groupCompanyId, startupId };
    }, [])

    const { data: documents } = useGetDocumentAssociated({ variables })


    const listDocuments = React.useMemo(() => {
        return documents.map(doc => ({ value: doc, label: doc.name }));
    }, [JSON.stringify(documents)]);




    return (
        <Form
            defaultValues={defaultValues}
            schema={schema}
            onSubmit={handleSubmit}
            onlySendDirty={false}
            optionsForm={{ mode: "onChange" }}
        >

            <Template buttons={{ ...buttons, submit: <SubmitButton  {...buttonSubmitProps}>Next</SubmitButton> }} props={propsTemplate}>


                <TemplateLabel
                    label={'Document'}
                    className={ClassNames(cls.TemplateLabel)}
                    isRequired
                >
                    <ControllerInput
                        render={SelectInput}
                        name='documentsUploaded'
                        list={listDocuments}
                        defaultlabel={''}
                        placeholder='Search or select from list'
                        clear

                    />
                </TemplateLabel>

            </Template>

        </Form>
    )

}

export default FormMoreDetails

import React, { FC, useMemo, useRef, useState } from 'react';
import { useDispatch } from "react-redux";
import * as Yup from 'yup';
import Form from "../../../../../../../../../components/forms/Form/FormL";
import SubmitButton from "../../../../../../../../generic/components/Buttons/SubmitButton";
import { CheckboxButtonOptions } from "../../../../../../../../generic/components/Inputs/CheckboxButton";
import CheckboxButtonSimple from "../../../../../../../../generic/components/Inputs/CheckboxButtonSimple";
import ControllerInput from "../../../../../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import FormProps from "../../../../../../../../generic/forms/interfaces/FormProps";
import useTranslate from "../../../../../../../../generic/hooks/useTranslate";
import TemplateFormDefault from "../../../../../../../../generic/templates/Modal";
import useBusinessRules from "../../../../../../../../clara/hooks/useBusinessRules";
import classes from './classes.module.scss';
const schema=Yup.object().shape({
    generate:Yup.string().required()
})
interface Props extends FormProps{
    type:string
}
const GenerateOrScaleUp: FC<Props> = ({initialValues, tooltips, onCompleteSubmit, Template = TemplateFormDefault, buttons = {}, buttonSubmitProps, propsTemplate,type}) => {
    const { translate } = useTranslate();
    const dispatch=useDispatch()
    const [first,setFirst]=useState()
    const {hasSubscriptionAccessGenerateDocuments} = useBusinessRules()
    const canGenerateDocuments = hasSubscriptionAccessGenerateDocuments();
    const getOptionsOption = () => {
        return [{ label: "Maybe later", value: 'CANCEL' },
        { label: "Scale up now", value: 'SCALE_SUSCRIPTION_PAYMENT' }]
    }
    const getOptionsConvertibles = ()=>{
        return canGenerateDocuments?[
            {
                value: 'notGenerate',
                label: translate(
                    'MODULES.EQUITY.FORMS_CONVERTIBLE.GENERATE_OR_SCALE.LATER'
                )
            },
            {
                value: 'convertible',
                label: translate(
                    `MODULES.EQUITY.FORMS_CONVERTIBLE.GENERATE_OR_SCALE.CONVERTIBLE`
                )
            },
            {
            value: 'safe',
            label: translate(
                `MODULES.EQUITY.FORMS_CONVERTIBLE.GENERATE_OR_SCALE.SAFE`
            )
            },
        ]:[
            {
                value: true,
                label: translate(
                    `MODULES.EQUITY.FORMS_CONVERTIBLE.GENERATE_OR_SCALE.UPGRADE`
                )
            },
            {
                value: false,
                label: translate(
                    `MODULES.EQUITY.FORMS_CONVERTIBLE.GENERATE_OR_SCALE.NOT_UPGRADE`
                )
            }
        ]
    }
    const options: Array<CheckboxButtonOptions<string | boolean, any>> =useMemo(()=>{
        return type==='CONVERTIBLE'?getOptionsConvertibles():getOptionsOption()
    },[canGenerateDocuments])
    const refForm=useRef()
    return (
        <React.Fragment>
            <Form
                initialValues={initialValues || {}}
                schema={schema}
                onlySendDirty={false}
                onSubmit={onCompleteSubmit}
                ref={refForm} optionsForm = {{mode:"onChange"}}
            >
                <Template props={propsTemplate} buttons={{submit:<SubmitButton{...buttonSubmitProps}>Next</SubmitButton>,...buttons}}>
                    <ControllerInput
                        as={CheckboxButtonSimple}
                        name='generate'
                        className={classes.checkBox}
                        classNameButton={classes.buttonCheckBox}
                        options={options}
                        onChange={value => {
                            /*if(tooltips) {
                                if(first) {
                                    dispatch(removeAssistantText());
                                    setFirst(false)
                                }
                                if (value) {
                                    _.get(tooltips,'isEquity')  && dispatch(addAssistantText(_.get(tooltips, 'isExist', "text")));
                                    _.get(tooltips,'isEquity')  && setFirst(true)
                                } else {
                                    _.get(tooltips,'isNotEquity') && dispatch(addAssistantText(_.get(tooltips, 'isNotExist', "text")));
                                    _.get(tooltips,'isNotEquity') && setFirst(true)
                                }
                            }*/
                        }}
                    />
                </Template>
            </Form>
        </React.Fragment>
    )
}

export default GenerateOrScaleUp

export const fieldsPart1 = [
  {
    title: {
      label: "Why will the company process Personal Data?",
      component: "text",
      name: "reasonsDataBeingProcessed",
    },
    subItems: [
      {
        name: "isGivenConsent",
        label:
          "The Data Subject has given his written consent to the Processing of that Personal data",
        component: "radioInput",
      },
      {
        name: "isNecessaryDataProcessing",
        label:
          "Data processing is necessary for the performance of a contract to which the Data Subject is party or in order to take steps at the request of the Data Subject prior to entering into a contract",
        component: "radioInput",
      },
      {
        name: "isNecessaryDataProcessingCompliance",
        label:
          "Processing is necessary for compliance with any regulatory or legal obligation to which the Data Controller is subject.",
        component: "radioInput",
      },
      {
        name: "isNecessaryDataProcessingVital",
        label:
          "Processing is necessary in order to protect the vital interests of the Data Subject.",
        component: "radioInput",
      },
      {
        name: "isNecessaryDataProcessingADGM",
        label:
          "Processing is necessary for the performance of a task carried out in the interests of the Abu Dhabi Global Market or in the exercise of the Board's, the Court's, the Registrar's or the Regulator's functions or powers vested in the Data Controller or in a Third Party to whom the Personal Data are disclosed.",
        component: "radioInput",
      },
      {
        name: "isNecessaryDataProcessingThirdParty",
        label:
          "Processing is necessary for the purposes of the legitimate interests pursued by the Data Controller or by the Third Party to whom the Personal Data are disclosed, except where such interests are overridden by compelling legitimate interests of the Data Subject relating to the Data Subject's particular situation.",
        component: "radioInput",
      },
    ],
  },
  {
    title: {
      label: "What are the types of data processed?",
      component: "text",
      name: "dataBeingProcessed",
    },
    subItems: [
      {
        name: "name",
        label: "Name",
        component: "radioInput",
        notRequired: true,
      },
      {
        name: "address",
        label: "Address",
        component: "radioInput",
        notRequired: true,
      },
      {
        name: "dateOfBirth",
        label: "Date of Birth",
        component: "radioInput",
        notRequired: true,
      },
      {
        name: "email",
        label: "Email address",
        component: "radioInput",
        notRequired: true,
      },
      {
        name: "staffIDProcessed",
        label: "Staff ID",
        component: "radioInput",
        notRequired: true,
      },
      {
        name: "otherDataProcessed",
        label: "Others",
        component: "textInput",
        notRequired: true,
        placeholder: "eg. Passport, Visa, Emirates ID",
      },
    ],
  },
];
export const fieldsPart2 = [
  {
    title: {
      label:
        "Is personal data transferred to other jurisdictions (including the UAE)?",
      component: "radioInput",
      name: "isPersonalDataTransferred",
    },
    subItems: [],
  },
  {
    title: {
      label: "What are the counties that personal data is transferred to?",
      component: "textInput",
      name: "countriesPersonalDataTransferred",
      placeholder: "Please specify",
    },
    subItems: [],
  },
  {
    title: {
      label: "What are the conditions applying to transfer?",
      component: "text",
      name: "conditionsApplyingTransfer",
    },
    subItems: [
      {
        name: "isRequestPermit",
        label:
          "We, the Data Controller, hereby request the Registrar to grant a permit for the transfer or the set of transfers. The Data Controller confirms that they will apply adequate safeguards with respect to the protection of such Personal Data.",
        component: "radioInput",
      },
      {
        name: "isConsented",
        label:
          "The Data Subject has given his written consent to the proposed transfer.",
        component: "radioInput",
      },
      {
        name: "isNecessaryTransfer",
        label:
          "The transfer is/will be necessary for the performance of a contract between the Data Subject and the Data Controller or the implementation of pre-contractual measures taken in response to the Data Subject's request.",
        component: "radioInput",
      },
      {
        name: "isNecessaryTransferLegal",
        label:
          "The transfer is/will be necessary to comply with any regulatory, auditing, accounting, anti-money laundering or counter terrorist financing obligations that apply to a Data Controller which is established in the Abu Dhabi Global Market, or for the prevention or detection of any crime.",
        component: "radioInput",
      },
      {
        name: "isNecessaryTransferThirdParty",
        label:
          "The transfer is/will be made to a person established outside the Abu Dhabi Global Market who would be a Data Controller or who is a Data Processor, if, prior to the transfer, a legally binding agreement in the form set out in Schedule 1 or Schedule 2 respectively to the Data Protection Regulations 2015 has been entered into between the transferor and Recipient.",
        component: "radioInput",
      },
      {
        name: "isNecessaryTransferWithinOrganization",
        label:
          "The transfer is/will be made between one or more members of a Group of Companies in accordance with a global data protection compliance policy of that Group, under which all the members of such Group that are or will be transferring or receiving the Personal Data are bound to comply with all the provisions of the Data Protection Regulations 2015 containing restrictions on the use of Personal Data and Sensitive Personal Data in the same way as if they would be if established in the Abu Dhabi Global Market.",
        component: "radioInput",
      },
    ],
  },
  {
    title: {
      label:
        "What mechanisms are in place to cover the transfer of Personal Data to countries without adequate levels of protection?",
      component: "text",
      name: "mechanismInPlaceForProtection",
    },
    subItems: [
      {
        label: "Ordinary Business Contract",
        name: "hasOrdinaryBusinessContract",
        component: "radioInput",
        notRequired: true,
      },
      {
        label: "Binding Corporate Rules",
        name: "hasBindingCorporateRules",
        component: "radioInput",
        notRequired: true,
      },
      {
        label:
          "Data Transfer Agreement (Data Controller to Data Processor transfers)",
        name: "hasDataTransferAgreement",
        component: "radioInput",
        notRequired: true,
      },
      {
        label:
          "Data Transfer Agreement (Data Controller to Data Controller transfers)",
        name: "hasDataTransferAgreementController",
        component: "radioInput",
        notRequired: true,
      },
    ],
  },
];
export const fieldsPart3 = [
  {
    title: {
      label: " Does the entity process sensitive personal data?",
      component: "radioInput",
      name: "isSensitiveDataProcessed",
    },
    subItems: [],
  },
  {
    title: {
      label: "What are the reasons for processing sensitive personal data?",
      component: "text",
      name: "reasonsProcessingSensitiveData",
    },
    subItems: [
      {
        label:
          "The Data Subject has given his additional written consent to the Processing of such Personal Data.",
        name: "hasDataSubjectConsent",
        component: "radioInput",
      },
      {
        label:
          "Processing is necessary for the purposes of carrying out the obligations and specific rights of the Data Controller.",
        name: "isProcessingNecessary",
        component: "radioInput",
      },
      {
        label:
          "Processing is necessary to protect the vital interests of the Data Subject or of another person where the Data Subject is physically or legally incapable of giving their consent.",
        name: "isProcessingNecessaryWhenIncapable",
        component: "radioInput",
      },
      {
        label:
          "Processing is carried out in the course of its legitimate activities with appropriate guarantees by a foundation, association or any other non-profit-seeking body on condition that the Processing relates solely to the members of the body or to persons who have regular contact with it in connection with its purposes and that the Personal Data are not disclosed to a Third Party without the consent of the Data Subjects.",
        name: "isProcessingNecessaryWhenNonProfit",
        component: "radioInput",
      },
      {
        label:
          "The Processing relates to Personal Data which are manifestly made public by the Data Subject, or is necessary for the establishment, exercise or defence of legal claims.",
        name: "isProcessingNecessaryWhenLegalClaims",
        component: "radioInput",
      },
      {
        label:
          "Processing is necessary for compliance with any regulatory or legal obligation to which the Data Controller is subject.",
        name: "isProcessingNecessaryWhenLegalObligation",
        component: "radioInput",
      },
      {
        label:
          "Processing is necessary to uphold the legitimate interests of the Data Controller recognised in the international financial markets, provided the Processing is undertaken in accordance with applicable standards and except where such interests are overridden by compelling legitimate interests of the Data Subject relating to the Data Subject's particular situation.",
        name: "isProcessingNecessaryWhenLegitimateFinancialInterests",
        component: "radioInput",
      },
      {
        label:
          "Processing is necessary to comply with any regulatory, auditing, accounting, anti-money laundering or counter terrorist financing obligations that apply to a Data Controller or for the prevention or detection of any crime.",
        name: "isProcessingNecessaryForCompliance",
        component: "radioInput",
      },
      {
        label:
          "Processing is required for the purposes of preventive medicine, medical diagnosis, the provision of care or treatment or the management of healthcare services, and where those Personal Data are Processed by a health professional subject under law or rules established by competent bodies to the obligation of confidence or by another person subject to an equivalent obligation.",
        name: "isProcessingNecessaryForHealth",
        component: "radioInput",
      },
    ],
  },
  {
    title: {
      label: "What are the types of sensitive personal data processed?",
      component: "text",
      name: "sensitiveDataBeingProcessed",
    },
    subItems: [
      {
        label: "Racial origin",
        name: "racialOrigin",
        component: "radioInput",
        notRequired: true,
      },
      {
        label: "Political opinion",
        name: "politicalOpinion",
        component: "radioInput",
        notRequired: true,
      },
      {
        label: "Religious beliefs",
        name: "religiousBeliefs",
        component: "radioInput",
        notRequired: true,
      },
      {
        label: "Others beliefs",
        name: "othersBeliefs",
        component: "radioInput",
        notRequired: true,
      },
      {
        label:
          "Physical or mental health (other than as kept in respect of your employees in the normal course of personnel administration and not to be used or disclosed for any of their purpose)",
        name: "physicalOrMentalHealth",
        component: "radioInput",
        notRequired: true,
      },
      {
        label: "Criminal convictions",
        name: "criminalConvictions",
        component: "radioInput",
        notRequired: true,
      },
      {
        label: "Others",
        name: "othersSensitivePersonalData",
        component: "textInput",
        placeholder: "Please specify",
        notRequired: true,
      },
    ],
  },
];

import React from "react";
import CalendarInput from "./CalendarInput";

function CalendarInputDate({  ...props }) {

	const type = props && props.calendarInputProps && props.calendarInputProps.type
		? props.calendarInputProps.type : 'date';

    return (

        <CalendarInput {...props} type={type} />

    );
}

export default CalendarInputDate;

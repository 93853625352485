import React, { useEffect, useState } from 'react';
import classesModule from "./ErrorView.module.scss"
import image from "../../../images/error.svg"
import Text from "../../../components/text/Text/Text"
import _ from "lodash"

const ErrorView = ({ ...props }) => {
  const [leftTime, setLeftTime] = useState(_.get(window, "env.loggerConfig.timeToRedirectFromErrorToHomeInSeconds", 0) );
  useEffect(() => {
    if (_.get(window, "env.loggerConfig.timeToRedirectFromErrorToHomeInSeconds", false)) {
      setTimeout(()=>{
        window.location = "/"
      },_.get(window, "env.loggerConfig.timeToRedirectFromErrorToHomeInSeconds")*1000)
    }
  }, [])
  
  useEffect(() => {
    setTimeout(() => {
      if (leftTime>0){
        setLeftTime(leftTime - 1)
      }
    }, 1000)
  }, [leftTime])
  return (
    <React.Fragment>
      <div className={`${classesModule.ErrorView}`}>
        <img src={image} />
        <div className={classesModule.Title}><Text uuid={"VIEW_ERROR_PAGE_TITLE"} /></div>
        <div className={classesModule.SubTitle}><Text uuid={"VIEW_ERROR_PAGE_MESSAGE"} params={{leftTime:leftTime}}/></div>
      </div>
    </React.Fragment>
  );
}

export default ErrorView;
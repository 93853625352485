import { FieldArray,Field } from "formik";
import React from 'react';
import { ShareForm, ShareholdingDetails, NoShares,CompanyNotEstablishedSharesForm } from "./components";
import classes from './styles.module.scss';
import get from 'lodash/get';
import flatMap from "lodash/flatMap";
import { Checkbox } from "../..";
import FormikHelper from "src/v1/utils/formikHelper";
const ClaraAddVestingEquities = ({fieldArrayName, groupCompany,tooltips, ...props}) => {

	const isRequired = message => value => (!!value ? undefined : message);

    return (<>
        <FieldArray
            name={fieldArrayName}
            render={(arrayHelpers) => {
				const founders = get(arrayHelpers.form.values, `${fieldArrayName}`,[])
                return(<>
                    {founders.map((founder, indexF) => {
                    const isLast = founders.length === indexF + 1
                    return (<>
                        <div className={classes.containerFounderName}>
            <h1>{founder.fullName} </h1>
            </div>
        <FieldArray
            name={`${fieldArrayName}[${indexF}].share`}
            render={(arrayHelpers) => {
						const shares = flatMap((groupCompany?.advanceCapTable?.shareClasses || []),sh=>sh.shareIssuances)
            			const sharesFiltered = (shares || []).filter(si=>si.holder.id===founder.id && si.status.state !== 'CANCELLED' && si.status.state !== 'TRANSFERRED')
						const foundersNoShares = (shares || []).filter(si=>founders.some(f=>f.id === si.holder.id) && si.status.state !== 'CANCELLED' && si.status.state !== 'TRANSFERRED').length > 0
                        return (<>
                            <ShareholdingSection
                             tooltips={tooltips} 
                             foundersNoShares={foundersNoShares}  
                             share={sharesFiltered} 
                             form={arrayHelpers.form}
                             entityLabel={`${fieldArrayName}[${indexF}]`} 
                             groupCompany={groupCompany} />
                        </>)
            }}/>
            {!isLast && <hr className={classes.Separator} />}
                    </>)
                    })}
						{get(arrayHelpers.form.values, `${fieldArrayName}`,[]).every(f=>(f.share || []).some(s => s.vestingSchedule)) &&
            <Field name={`confirmShares`}>
                {({ field, form }) => (
                    <Checkbox
                    className={classes.sameDataCheckbox}
                        field={field}
                        validate={isRequired('This field is required')}
                        form={form}
                        required={true}
                        label='I confirm the share positions listed above reflect the shareholding of each founder.'
                        errorMessage={FormikHelper.returnErrorsForValidation(field.name, form.errors)}
                        onChange={(value) => { form.validateField(field.name) }}
                        />
                        )}
            </Field>}
                </>)
            }}/>
        </>)
}

const  ShareholdingSection =  ({tooltips,entityLabel,groupCompany,share,foundersNoShares,form}) =>{
	if(share && share.length > 0) return <ShareholdingDetails shares={share} entityLabel={entityLabel} form={form} />
	if(foundersNoShares) return <NoShares entityLabel={entityLabel} />
    if(groupCompany?.id) return <ShareForm tooltips={tooltips} entityLabel={entityLabel} groupCompany={groupCompany} />
    return <CompanyNotEstablishedSharesForm tooltips={tooltips} entityLabel={entityLabel} groupCompany={groupCompany} form={form} />
}


export default ClaraAddVestingEquities;

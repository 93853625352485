import classnames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import classes from "./classes.module.scss";
import TypographyLocal from "../../Texts/Typography";

/**
 * Block content
 */

const Block = ({
  title,
  children,
  ...props
}) => {
  return (
    <React.Fragment>
        <div className={classnames(classes.blockContainer)}>
          <div className={classnames(classes.titleMargin)}>
            <TypographyLocal
              component={"span"}
              color={"black"}
              variant={"h4"}
            >
              {title}
            </TypographyLocal>
          </div>
          {children}
        </div>
    </React.Fragment>
  );
};

Block.propTypes = {
  /** Property title */
  title: PropTypes.string.isRequired,
  /** Property children */
  children: PropTypes.string.isRequired,
};
Block.defaultProps = {
  title: "Test title"
};

export default Block;

import React, { useMemo } from "react";
import _ from "lodash";
import * as Yup from "yup";
import Form from "src/components/forms/Form/Form";
import Button from "src/modules/generic/components/Buttons/Button";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import TableCost from "src/modules/generic/components/Tables/TableCost";
import Typography from "src/modules/generic/components/Texts/Typography";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import { HiddenFieldForm } from "src/components/forms/contents";
import classes from "./classes.module.scss";

/**
 * Description Step: Review and confirm cap table
 */
function ReviewAndConfirmCapTableFormStep() {
  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();

  const initialValues = useMemo(() => {
    return context?.ReviewAndConfirmCapTableFormStep ?? {};
  }, [context]);

  const handleCancel = () => cancel();

  const handleSubmit = async (values) => next(values);

  const schema = Yup.object().shape({
    confirm: Yup.boolean()
      .required("Required")
      .test("is-true", "", (value) => value === true),
  });

  const capitalizeFirstLetter = (str: string) => {
    return (
      str.toLowerCase().charAt(0).toUpperCase() + str.slice(1).toLowerCase()
    );
  };

  const options = [
    {
      value: false,
      label: "No",
    },
    {
      value: true,
      label: "Yes",
    },
  ];

  const tableParams = useMemo(() => {
    const result = [];
    _.forEach(context?.initialData?.shareCapitalRow, (capital) => {
      const items = [];
      _.forEach(capital?.shareholders, (shareholderData) => {
        items.push({
          name:
            shareholderData?.holder?.fullName || shareholderData?.holder?.name,
          avatar: shareholderData?.holder?.avatar,
          issued: shareholderData?.issuedShares,
          status: capitalizeFirstLetter(_.get(shareholderData, "status", "")),
          inProgress: shareholderData?.isAddedToCart ?? false,
        });
      });
      const unpaidMultiplicator = capital?.shareClass?.parValue?.amount ?? 0;
      const base = {
        title: _.get(
          capital,
          "shareClass.name",
          ""
        ).replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()),
        value: capital?.shareClass?.parValue?.amount,
        valueCurrency: capital?.shareClass?.parValue?.currency,
        total: capital?.total,
        amountPaid:
          capital?.amountPaid?.amount === 0
            ? 0
            : capital?.amountPaid?.amount *
              capital?.shareClass?.parValue?.amount,
        amountPaidCurrency: capital?.amountPaid?.currency,
        amountUnpaid: capital?.amountUnpaid.amount * unpaidMultiplicator,
        amountUnpaidCurrency: capital?.amountUnpaid?.currency,
        items: items,
      };
      result.push(base);
    });
    return result;
  }, [context]);

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: "Review and confirm cap table",
          subTitle: "Review cap table",
          skeleton: null,
          variant: "simple",
        }}
        buttons={{
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("BUTTON_CANCEL")}
            </Button>
          ),
          submit: <SubmitButton>Confirm</SubmitButton>,
        }}
      >
        <Typography component="p" weight="regular" variant="body">
          Your current share capital and shareholders are listed below for your
          confirmation:
        </Typography>
        {_.map(tableParams, (param) => {
          return <TableCost {...param}></TableCost>;
        })}
        <TemplateLabel
          label="Please confirm this is correct"
          isRequired
          variant="top"
        >
          <ControllerInput
            render={CheckboxButtonSimple}
            name="confirm"
            options={options}
            isMultiple={false}
            classNameButton={classes.buttonCheckBox}
          />
        </TemplateLabel>
        <HiddenFieldForm
          fieldsWatch="confirm"
          conditionHideCallback={(value) => {
            if (typeof value === "boolean") {
              return value === false;
            }
          }}
        >
          <TemplateLabel label="">
            <Typography component="p" variant="h4" fontWeight="semibold">
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    "Please go to the Equity page to make changes. Fees apply.",
                }}
              />
            </Typography>
          </TemplateLabel>
        </HiddenFieldForm>
        <div>&nbsp;</div>
      </TemplateWizard>
    </Form>
  );
}

export default ReviewAndConfirmCapTableFormStep;

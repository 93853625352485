import React, { useEffect, useRef, useState } from "react";
import ControllerInput from "../../../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import SelectAddInput from "../../../../../inputs/Select/SelectAddInput/SelectAddInput";
import FormAddShareClass from "./FormAddShareClass";
import { useFormContext } from "react-hook-form";
import _ from "lodash";
import TemplateLabel from '../../../../../../modules/generic/components/Inputs/LabelInput';
import useTranslate from "../../../../../../modules/generic/hooks/useTranslate";

const SelectShareClass = ({
  name,
  formsAdd,
  initialValue,
  asterisk = false,
  notObserver,
  observerField,
  menuProps,
  list,
  startupId,
  refreshGC,
  ...props
}) => {

  const { watch } = useFormContext();
  const valueObserver = notObserver ? list[0] : watch(observerField);
  const value = watch(name);
  const refSelect = useRef();
  const { translate } = useTranslate();
  
  const getListShareClass = () => {
    if (_.isEmpty(valueObserver)) {
      return [];
    }
    const findList = _.find(list, (gc) => valueObserver.id === gc.id);
    return _.map(_.get(findList, "shareClass", []), ({ name, ...props }) => ({
      label: name,
      value: { ...props, name },
      type: "shareClass",
    }));
  };

  const [listShareClass, setlistShareClass] = useState(getListShareClass());

  const handleAddShareClass = (value) => {
    setlistShareClass((pre) => [
      ...pre,
      { value: value, label: value.name, type: "shareClass" },
    ]);
    refSelect.current.clearValue();
    refSelect.current.handleSelected({ value: value, label: value.name });
  };

  useEffect(() => {
    setlistShareClass(getListShareClass());
  }, [JSON.stringify(valueObserver)]);

  useEffect(() => {
    if (initialValue) {
      refSelect.current.handleSelected({
        label: initialValue.name,
        value: { ...initialValue },
      });
    } else if (_.isEmpty(value) && listShareClass.length) {
      const shareClassDefault = _.find(listShareClass, (sc) => sc.value.defaultShare);
      refSelect.current.handleSelected(shareClassDefault);
    }
  }, [JSON.stringify(listShareClass)]);

  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    if (_.isEmpty(valueObserver)) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    if (value && _.isEmpty(valueObserver)) {
      refSelect.current.clearValue();
    }
  }, [JSON.stringify(valueObserver)]);

  const formsAddShareClass = [
    {
      type: "shareClass",
      form: FormAddShareClass,
      handleAddValueList: handleAddShareClass,
      propsForm: {
        startupId,
        groupCompanyId: _.get(valueObserver, "id"),
      },
    },
  ];

  const menuPropsShareClass = {
    addList: [
      {
        label: "Add New Share Class",
        type: "shareClass",
      },
    ],
    propsList: [{ type: "shareClass" }],
  };

  return (
    <React.Fragment>
      <TemplateLabel
        label={"Class:"}
        isRequired={true}
      >
        <ControllerInput
          as={SelectAddInput}
          list={listShareClass}
          refSelect={refSelect}
          disabled={disabled}
          mode={"add"}
          placeholder={translate('MODULES.CLARA.SELECT_FROM_LIST')}
          formsAdd={formsAddShareClass}
          compare="id"
          onChange={(value) => {}}
          menuProps={menuPropsShareClass}
          name={name}
        />
      </TemplateLabel>
    </React.Fragment>
  );
};

export default SelectShareClass;

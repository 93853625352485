import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import _ from "lodash";
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateLabel from 'src/modules/generic/components/Inputs/LabelInput';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import TextAreaInput from "src/components/inputs/Textarea/TextareaInput";
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';

function SourceOfFundsForm() {
  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.initialData ?? {}
  }, [context]);

  const { formValues, stakeholderName } = initialValues;
  const isEdit = !_.isEmpty(formValues);

  const handleCancel = () => {
    cancel();
  }

  const handleSubmit = async (values) => {
    next(values);
  }

  useEffect(() => {
    dispatch(addAssistantText(translate('MODULES.STAKEHOLDER.ENTITY_STRUCTURES.SOURCE_OF_FUNDS.ASSISTANT')));
    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch]);

  const schema = Yup.object().shape({
    sourceOfFunds: Yup.string().required(
      "Required"
    ),
    sourceOfWealth: Yup.string().required(
      "Required"
    ),
  });

  return (
    <Form
      defaultValues={formValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.SOURCE_OF_FUNDS.TITLE", { stakeholderName }),
          skeleton: null,
          subTitle: translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.SOURCE_OF_FUNDS.SUBTITLE"),
          variant: 'simple'
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>{isEdit ? translate('BUTTON_SAVE_CHANGES') : translate('GENERIC_BUTTON_ADD_GROUP')}</SubmitButton>,
        }}
      >
        <>
          <TemplateLabel isRequired label="Source of funds" variant="top">
            <ControllerInput
              render={TextAreaInput}
              name="sourceOfFunds"
              placeholder="Please specify where payments by this Corporate Stakeholder come from (e.g., a UAE bank account in the name of the Corporate Stakeholder)."
              rows={8}
            />
          </TemplateLabel>
          <TemplateLabel isRequired label="Source of wealth" variant="top">
            <ControllerInput
              render={TextAreaInput}
              name="sourceOfWealth"
              placeholder="Please specify how this company generates its revenue."
              rows={8}
            />
          </TemplateLabel>
        </>
      </TemplateWizard>
    </Form>
  );
}

export default SourceOfFundsForm;
import Machine from "../../../generic/components/Machine/MachineClass";
import { StepAssignAuthorisedSignatories } from './Steps';


const machineDefinition = {
    id: 'assing_authorised_signatories',
    initial: 'assing_authorised_signatories',
    states: {
        assing_authorised_signatories: {
            on: {
                NEXT: { target: 'finish_stakeholder_assign', actions: 'setContext' },
                CANCEL: 'finish_stakeholder_assign',
            },

        },
        finish_stakeholder_assign: {
            type: 'final',
            entry: 'final',
        },
        onDone: {
            actions: 'stopMachine'
        }
    },
}
const steps = {
    assing_authorised_signatories: StepAssignAuthorisedSignatories
}

const options = {}



export default class MachineProfileStakeholder extends Machine {
    constructor() {
        super(machineDefinition, options, steps);
    }

    getParamsData(values) {
        return values;
    }
}

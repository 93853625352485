import { useMemo } from 'react';

const useServices = ({ initialValues }) => {

  return useMemo(() => ({

    initialData: async (context, event) => {
      return initialValues;
    },

  }), [initialValues]);
}

export default useServices;

import React from "react";
import ResumePayment from "src/modules/checkout/components/PaymentTotal/PaymentItems/Resume";

export interface ResumeLogicProps {
  product: any;
  useVat: boolean;
  vatAmount: any;
  totalAmount: any;
  freeVat?: boolean;
  vat: any;
  activePincasSumary?: boolean;
}

const ResumeLogic: React.FC<ResumeLogicProps> = ({ 
  product,
  useVat,
  vatAmount,
  totalAmount,
  freeVat,
  vat,
  activePincasSumary = false,
}) => {

  return (  
    <>
      {activePincasSumary ? (
        <ResumePayment
          haveDiscount={!!product?.discount}
          discountValue ={product?.discount}
          haveVAT={(useVat && product?.totals?.vat > 0)}
          vatPercent={product?.totals?.vatPercent ?? 0}
          vatValue={product?.totals?.vat ?? 0}
          vatCurrency={product?.currency ??  "USD"}
          totalValue={totalAmount.amount}
          totalCurrency={totalAmount.currency}
        />
      ) : (
        <ResumePayment
          haveDiscount={!!product?.discount}
          discountValue ={product?.discount}
          haveVAT={(useVat && !!vatAmount.amount)}
          vatValue={vatAmount.amount}
          vatCurrency={vatAmount.currency}
          totalValue={totalAmount.amount}
          totalCurrency={totalAmount.currency}
          freeVat={freeVat}
          vat={vat}
        />
      )}
    </>
  );
};
export default ResumeLogic;

import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import * as Yup from 'yup';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import Typography from "src/modules/generic/components/Texts/Typography";
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import classesModule from "../classes.module.scss";
import _ from 'lodash';
import userVersion from "src/modules/versions/hooks/useVersion";
import CountryFlag from 'src/modules/generic/components/CountryFlag';
import useBusinessRules from 'src/modules/clara/hooks/useBusinessRules';

function ChooseJurisdiction() {

  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const { getVersion } = userVersion();
  const initialValues = useMemo(() => {
    return {};
  }, [context]);

  const handleCancel = (values) => {
    cancel();
  }

  const handleSubmit = async (values) => {
    next(values)
  }

  useEffect(() => {
    
    dispatch(
      addAssistantText(
        translate('MODULES.ONBOARDINGCOMPANY.CHOOSE_JURISDICTION_TOOLTIP_DELAWARE')
      )
    );
    
    return () => {
      dispatch(clearAssistatText())
    }
  }, [dispatch])

  const schema = Yup.object().shape({
    jurisdiction: Yup.object().required('Required'),
  })

  const handleLabel = (country) => {
    let result = null;
    result = country.jurisdictionType;
    return result;
  };

  const optionsList = useMemo(() => {
    const data = context?.ChooseJurisdiction_data ?? [];
    const filterData = _.orderBy(
      _.map(data, country => {
        return {
          component: ButtonOptions,
          componentProps: {
            icon: country.country.alpha3,
            label: handleLabel(country),
            country: country,
            disabled:true,
          },
          value: {
            ...country.country,
            jurisdictionType: handleLabel(country),
          },
          isFlag: true,
        }
      })
    );
    return filterData;
  }, [context?.ChooseJurisdiction_data, handleLabel]);

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: "Where do you want to form?",
          subTitle: "Company jurisdiction​",
          skeleton: null,
          variant: 'simple'
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('BUTTON_NEXT')}</SubmitButton>,
        }}
      >
        <>
          <Typography variant={"h5"}>
            {translate('MODULES.ONBOARDINGCOMPANY.CHOOSE_JURISDICTION_HIT')}
          </Typography>
          <ControllerInput
            render={CheckboxButtonSimple}
            name="jurisdiction"
            variant="col_1"
            size={"size2"}
            options={optionsList}
            onChange={value => { }}
            classNameButton={classesModule.buttonCheckBoxFlag}
            className={classesModule.smallSelect}
          />
        </>
      </TemplateWizard>
    </Form>
  );
}

export default ChooseJurisdiction;

const ButtonOptions = ({ icon, label, country }) => {

  const { translate } = useTranslate();

  return (
    <>
      <div className={classesModule.newButtonJurisdiction}>
        <CountryFlag alpha3={icon} size={"4.5385rem"} />
        <div className={classesModule.inactiveTitle}>
          {label === "ADGM" && translate('MODULES.ONBOARDINGCOMPANY.CHOOSE_JURISDICTION_ADGM')}
          {label === "CAYMAN" && translate('MODULES.ONBOARDINGCOMPANY.CHOOSE_JURISDICTION_CAYMAN')}
          {country.country.name === "United States" && translate('MODULES.ONBOARDINGCOMPANY.CHOOSE_JURISDICTION_UE')}
          {country.country.name === "United Kingdom" && translate('MODULES.ONBOARDINGCOMPANY.CHOOSE_JURISDICTION_UK')}
          {country.country.name === "Singapore" && translate('MODULES.ONBOARDINGCOMPANY.CHOOSE_JURISDICTION_SING')}
        </div>
        {label === null && (
          <small className={classesModule.textComing}>{'(coming soon)'}</small>
        )}
      </div>
    </>
  );
};
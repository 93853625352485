import classnames from "classnames";
import classes from "./classes.module.scss";
import React, { FC } from "react";
import TypographyLocal from "../../../generic/components/Texts/Typography";
import { Stack, Box } from '@mui/material';
import Fade from '@mui/material/Fade';

/**
 * Field Banner
 */

export interface FiledBannerProps {
  title: string,
  paddingRight?: string,
}

const FiledBanner: FC<FiledBannerProps> = ({
  title,
  paddingRight = "0px"
}:FiledBannerProps) => {
  return (
    <Box sx={{ display: 'flex', width: "100%", paddingRight: paddingRight }}>
      <Fade in={true}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={0}
          width={'100%'}
          className={classnames(classes.bannerBackground)}
          sx={{
            borderRadius: '3px',
            marginTop: '22px',
          }}
        >
          <Box
            className={classnames(classes.bannerBackgroundDetail)}
            sx={{
              width: "5px",
              height: "100%",
              borderTopLeftRadius: '3px',
              borderBottomLeftRadius: '3px',
            }}
          />
          <Box
            sx={{
              width: "100%",
              padding: '8px',
              borderTopRightRadius: '3px',
              borderBottomRightRadius: '3px',
              display: 'flex',
            }}
          >
            <TypographyLocal
              variant={"s"}
            >
              <span dangerouslySetInnerHTML={{__html: title}}></span>
            </TypographyLocal>
          </Box>
        </Stack>
      </Fade>
    </Box>
  );
};

export default FiledBanner;

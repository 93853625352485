import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useDispatch } from "react-redux";
import Form from "src/components/forms/Form/Form";
import TemplateWizard from "src/modules//generic/templates/Modal/TemplateWizard";
import PaymentFormContent from 'src/modules/checkout/forms/Payment/PaymentFormContent';
import usePaymentMethodStripe from 'src/modules/checkout/forms/Payment/usePaymentMethods/usePaymentMethodStripe';
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import Typography from "src/modules/generic/components/Texts/Typography";
import * as Yup from 'yup';
import Button from "../../../../generic/components/Buttons/Button";
import useMachine from '../../../../generic/context/MachineContext/useMachine';
import useModal from "../../../../generic/hooks/useModal";
import useTranslate from '../../../../generic/hooks/useTranslate';
import { clearAssistatText } from "../../../../generic/store/action";
import StripeElementKey from 'src/modules/checkout/components/StripeElementKey';

/**
 * Step to set pay data for manual invoice
 * @param {*} param0
 * @returns
 */
function SetPayDatapChildren({ openParam }) {

  const { next, context, prev } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const { closeModal: onClose } = useModal();

  const handleCancel = (values) => {
    dispatch(clearAssistatText())
    onClose();
  }
  const [card, setCard] = useState(null);
  const { paymentFull } = usePaymentMethodStripe({});
  const [errorMessageCard, setErrorMessageCard] = useState(null);
  const handleCompleteSubmit = async (_values) => {
    try {
      const { cardSelect, ...values } = _values;
      const source = "STARTUP_CUSTOM_PAYMENT";
      const couponCode = null;
      const product = {
        code: context?.initialData?.productCode ?? "FORMATION_ADGM_CUSTOM_PAYMENT"
      }
      const id = context?.initialData?.id;
      const cardId = cardSelect === -1 ? null : cardSelect;

      await paymentFull(values, { source, couponCode, product, id, cardId });
      const newCard = { ...card, ...values?.card ?? {} };
      next({ ...values, card: newCard })
    } catch (e) {
      setErrorMessageCard(e.message);
      console.error("***** Error paymentWithNewCard", e);
    }
  }


  const schema = Yup.object().shape({})

  const payAmount = useMemo(() => {


    return _.get(context, "initialData.currency", "") + " " + _.get(context, "initialData.amount", "")
  }, [context]);
  
  return (
    <Form
      defaultValues={{}}
      schema={schema}
      onSubmit={handleCompleteSubmit}
      // ref={refForm}
      optionsForm={{ mode: "onChange" }}
      onlySendDirty={false}
    >
      <TemplateWizard
        props={{
          title: "Payment",
          skeleton: null
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton >Pay {payAmount}</SubmitButton>,
          // previous: <Button onClick={handlePrev}>Previous</Button>,
        }}
      >
        <div style={{ gridTemplateColumns: "1fr 1fr", gap: "3rem", display: "grid" }}>
          <Typography variant="h2" color={"black"} component={"p"} >
            Card details
          </Typography>
          <Typography variant="h2" color={"black"} component={"p"} >
            Billing address
          </Typography>
        </div>
        <div style={{ gridTemplateColumns: "1fr 1fr", gap: "3rem", display: "grid" }}>
          <PaymentFormContent
            showTitle={false}
            defaultVat={{}}
            useCard={true}
            selectCard={card}
            errorCard={errorMessageCard ? "error" : null}
            errorMessageCard={errorMessageCard}
            setIsZipCodeRequired={() => { }}
            setCard={setCard}
            enabledVat={false}
          // terms={"By confirming your subscription, you allow Clara to charge your card for this payment and future payments in accordance with their terms."}
          ></PaymentFormContent>
        </div>
      </TemplateWizard>
    </Form>


  );
}
function SetPayData({ openParam }) {
  const {  context } = useMachine();
  const source = context?.initialData?.source;
  return (
    <StripeElementKey source={source}>
      <SetPayDatapChildren openParam={openParam} />
    </StripeElementKey>
  )
}
export default SetPayData;

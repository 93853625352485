const templates = {

    MESSAGE_HEADER_ACT_EQUITY_CREATED:
        "{{userName}} {{entityAction}} a  {{entityName}} for {{companyName}}.",
    MESSAGE_SUB_HEADER_ACT_EQUITY_CREATED:
        "{{userName}} {{entityAction}} a  {{entityName}} for {{companyName}}.",
    MESSAGE_TEMPLATE_ACT_EQUITY_CREATED: `
        <label> {{userName}} {{entityAction}} a {{entityName}} {{customId}} for {{companyName}}</label> 
        <ul>
        <li>{{customId}} has been {{entityAction}} for {{holderName}} in {{companyName}} by {{userName}} at {{activityTime}} on {{activityDate}}</li>
        </ul>
    `,
    MESSAGE_HEADER_ACT_EQUITY_EDITED:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_SUB_HEADER_ACT_EQUITY_EDITED:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_TEMPLATE_ACT_EQUITY_EDITED: `
        <label> {{userName}} {{entityAction}} the details of {{customId}} {{entityName}} in {{companyName}}</label>
        <ul>
        <li>The details of {{customId}} in {{companyName}} has been {{entityAction}} by {{userName}} at {{activityTime}} on {{activityDate}}</li>
        </ul>
        `,
    MESSAGE_HEADER_ACT_EQUITY_DELETED:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_SUB_HEADER_ACT_EQUITY_DELETED:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_TEMPLATE_ACT_EQUITY_DELETED: `
        <label> {{userName}} {{entityAction}} the {{entityName}} {{customId}} in {{companyName}}</label>
        <ul>
        <li>{{customId}} has been {{entityAction}} in {{companyName}} by {{userName}} at {{activityTime}} on {{activityDate}}</li>
        </ul>
    `,
    MESSAGE_HEADER_ACT_EQUITY_PUBLISHED:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_SUB_HEADER_ACT_EQUITY_PUBLISHED:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_TEMPLATE_ACT_EQUITY_PUBLISHED: `
        <label> {{userName}} {{entityAction}} the {{entityName}} {{customId}} for {{companyName}}</label>
        <ul>
        <li>{{customId}} has been {{entityAction}} for {{companyName}} by {{userName}} at {{activityTime}} on {{activityDate}}</li>
        </ul>
        `,
    MESSAGE_HEADER_ACT_EQUITY_PUBLISH_AND_SHARING:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_SUB_HEADER_ACT_EQUITY_PUBLISH_AND_SHARING:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_TEMPLATE_ACT_EQUITY_PUBLISH_AND_SHARING: `
        <label> {{userName}} granted a {{holderName}} access to the details of  {{customId}}</label>
        <ul>
        <li> Access to the details of {{customId}} has been granted to {{holderName}} by {{userName}} at {{activityTime}} on {{activityDate}}. </li>
       
        </ul>
    `,
    MESSAGE_BUTTON_REDIRECT_GO_TO_CONVERTIBLE: `View equity details`,
    MESSAGE_BUTTON_REDIRECT_GO_TO_SHARE_ISSUANCE: `View equity details`,
    MESSAGE_BUTTON_REDIRECT_GO_TO_OPTION_GRANT: `View equity details`,
    MESSAGE_BUTTON_REDIRECT_GO_TO_WARRANT: `View equity details`,


}

export default templates

import _ from "lodash";
import { actions as actionTypes } from "./action";
import { loadState, saveState } from "./../../../session";

const defaultInitialValues = {
  isLoading: false,
  pagesLoaded: {},
  lastLogin: null,
  token: null,
  user: null,
  payment: null,
  startup: null,
  expandedHeader: true,
  moreOneStartup: false,
};

let initialData = loadState("session") || defaultInitialValues;

export default (_state = initialData, action) => {
  let state = loadState("session") || _state;
  let newState = null;
  switch (action.type) {
    case actionTypes.SET_MORE_ONE_STARTUP:
      newState = {
        ...state,
        moreOneStartup: action.moreOneStartup,
      };
      break;
    case actionTypes.LOGIN:
      newState = {
        ...state,
        pagesLoaded: {},
        token: action.token,
        lastLogin: action.lastLogin,
      };
      break;

    case actionTypes.SET_LOADING:
      newState = {
        ...state,
        isLoading: true,
      };
      break;

    case actionTypes.SET_EXPANDED_HEADER:
      newState = {
        ...state,
        expandedHeader: action.expandedValue
          ? action.expandedValue
          : !state.expandedHeader,
      };
      break;

    case actionTypes.SET_TOKEN:
      newState = {
        ...state,
        token: action.token,
      };
      break;

    case actionTypes.SET_USER:
      newState = {
        ...state,
        user: action.user,
      };
      break;

    case actionTypes.SET_STARTUP:
      let hasOverduePayment = _.get(state, "payment.hasOverduePayment", false);
      if (
        _.get(action, "startup.id", null) !== _.get(state, "startup.id", null)
      ) {
        hasOverduePayment = action.hasOverduePayment;
      }
      /* TODO many new fields are a patch to revolve 5328 Have to talk about how to revolve the issue and new cards that need to be hidden after closing them */
      newState = {
        ...state,
        user: { ...state.user, hasStartup: true },
        payment: { hasOverduePayment: hasOverduePayment },
        isLoading: false,
        startup: {
          ...action.startup,
          newField: false,
          isGenerateEsop: false,
          isBookADemo: false,
          isShareYourProfile: false,
          isBoostYourBusiness: false,
        },
      };
      break;

    case actionTypes.UPDATE_HASPAYMENT_OVERDUE:
      newState = {
        ...state,
        payment: {
          ...state.payment,
          hasOverduePayment: action.hasOverduePayment,
        },
      };
      break;

    case actionTypes.UPDATE_USER:
      newState = {
        ...state,
        user: { ...state.user, ...action.user },
      };
      break;

    case actionTypes.UPDATE_STARTUP:
      newState = {
        ...state,
        isLoading: false,
        startup: { ...state.startup, ...action.startup },
      };
      break;

    case actionTypes.CLEAR_SESSION:
      newState = defaultInitialValues;
      break;

    case actionTypes.SET_JUST_LOGGED:
      newState = state;
      _.set(newState, `pagesLoaded.${action.pageLoaded}`, true);
      break;
  }
  if (newState) {
    saveState("session", newState);
    return newState;
  }
  return state;
};

import _ from 'lodash'
import React, { useMemo } from 'react'
import Currency from 'src/components/inputs/Currency/Currency'
import * as Yup from 'yup'
import Form from '../../../../../components/forms/Form/Form'
import CalendarInputDate from '../../../../../components/inputs/Calendars/CalendarInputDate'
import TextInput from '../../../../../components/inputs/Text/TextInput'
import SubmitButton from '../../../../generic/components/Buttons/SubmitButton'
import AutocompleteClara from '../../../../generic/components/Inputs/Autocomplete'
import ControllerInput from '../../../../generic/components/Inputs/ReactHookForm/ControllerInput'
import Typography from '../../../../generic/components/Texts/Typography'
import FormProps from '../../../../generic/forms/interfaces/FormProps'
import useTranslate from '../../../../generic/hooks/useTranslate'
import TemplateLabel from '../../../../generic/templates/Label'
import TemplateFormDefault from "../../../../generic/templates/Modal"
import cls from './styles.module.scss'


interface Props extends FormProps {
    company: any,
    showClass: boolean,
    shareClassList: any[],
    remainShares: number
}
const FormShareOptions: React.FC<Props> = ({
    initialValues: defaultValues = {},
    onCompleteSubmit,
    Template = TemplateFormDefault,
    buttons = {},
    buttonSubmitProps,
    shareClassList,
    propsTemplate,
    showClass = false,
    remainShares = null,

    company }) => {


    const { translate } = useTranslate();

    const handleSubmit = async values => {
        onCompleteSubmit && await onCompleteSubmit(values);
    };

    const shareClasses = useMemo(() => {
        return _.map(shareClassList, sh => ({ value: { id: sh.id }, label: sh.name }))
    }, [shareClassList]);

    const createShareClass = (value) => {
        return { name: value }
    }
    
    const schema = Yup.object().shape({
        amount: Yup.number().nullable().required('This field is required').typeError('Must be a number').test('amount',`There are only ${remainShares < 0 ? 0 : new Intl.NumberFormat("en-US").format(remainShares)} x unallocated shares available to use. `,
            (obj) => {
                if (obj === undefined) return true;
                if (remainShares === null) return true;

                if (obj > remainShares) {
                    return false
                }
                return true;
            }).typeError('Must be a number'),
        price: Yup.number().nullable().required('This field is required').typeError('Must be a number'),
        issuedDate: Yup.date().nullable().required('Required').typeError('Add a valid date'),
    });
    return (
        <Form
            defaultValues={defaultValues}
            schema={schema}
            onSubmit={handleSubmit}
            onlySendDirty={false}
            optionsForm={{ mode: "onChange" }}
        >

            <Template buttons={{ ...buttons, submit: <SubmitButton  {...buttonSubmitProps}>Next</SubmitButton> }} props={propsTemplate}>

                <Typography color={'black'} weight={'bold'} variant={"body"}>
                    {company?.name}
                </Typography>

                <TemplateLabel
                    label={'No. of options'}
                    className={cls.TemplateLabel}
                    isRequired
                >
                    <ControllerInput
                        render={TextInput}
                        name='amount'
                        type={'number'}
                        defaultlabel={''}
                        placeholder='Enter a number'

                    />
                </TemplateLabel>

                <TemplateLabel
                    label={'Exercise price'}
                    className={cls.TemplateLabel}
                    isRequired
                >
                    <ControllerInput
                        render={Currency}
                        name='price'
                        type={'share'}
                        defaultlabel={''}
                        currency={_.get(company, 'transactionCurrency', '')}
                    />
                </TemplateLabel>


                <TemplateLabel
                    label={'Issued date'}
                    className={cls.TemplateLabel}
                    isRequired
                >
                    <ControllerInput
                        render={CalendarInputDate}
                        name='issuedDate'
                        defaultlabel={''}
                        placeholder='dd/mm/yyyy'

                    />
                </TemplateLabel>

                {showClass && (
                    <TemplateLabel label={translate('MODULES.EQUITY.FORMS.SHARE_FORM.SHARE_CLASS')} className={cls.TemplateLabel} isRequired={true}>
                        <ControllerInput
                            render={AutocompleteClara}
                            options={shareClasses}
                            placeholder={translate('MODULES.EQUITY.FORMS.SHARE_FORM.SELECT_PLACEHOLDER')}
                            name={"class"}
                            getCreateValue={createShareClass}
                        />
                    </TemplateLabel>
                )}

            </Template>

        </Form>
    )

}

export default FormShareOptions

import { useMemo } from 'react';
import useBusinessRules from 'src/modules/clara/hooks/useBusinessRules';
import useSession from 'src/modules/session/hooks/useSession';

const useGuards = () => {
  const { isOwner } = useBusinessRules();
  const { session } = useSession();
  return useMemo(() => ({
    addNew: (context) => {
      const addedStep = context?.AddOtherDirector;
      if (addedStep?.addAnother === true) {
        return true;
      }
    },
    doNotAddNew: (context) => {
      const addedStep = context?.AddOtherDirector;
      if (addedStep?.addAnother === false) {
        return true;
      }
    },
    isNotCorporate: (context) => {
      return context?.AddDirector?.isCorporate === false;
    },
    isCorporate: (context) => {
      return context?.AddDirector?.isCorporate === true;
    },
  }), [isOwner, session]);
}

export default useGuards;

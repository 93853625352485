const useGetPriceByAddRole = (requestItems) => {
  const getPriceByAddRole = (roles, jurisdiction) => {
    let pricesRoles = [];
    roles.forEach(role => {
      if (requestItems[`CHANGE_STAKEHOLDER_ADD_ROLE_${role}`]) {
        pricesRoles.push({
          useVat: requestItems[`CHANGE_STAKEHOLDER_ADD_ROLE_${role}`].vat > 0,
          price: requestItems[`CHANGE_STAKEHOLDER_ADD_ROLE_${role}`].value, 
          role 
        })
      }
    })
    return pricesRoles;
  }
  return getPriceByAddRole
}
export default useGetPriceByAddRole
import Link from '@material-ui/core/Link';
import React from 'react';

const LinkComponent = ({ children, ...props }) => {
  return (
    <Link {...props} >
      {children}
    </Link>
  );
}

export default LinkComponent;

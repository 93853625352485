import React from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";

const EditExerciseWarrant = ({go,...props}) => {
  const params = useParams();
  return (
    <MicroFrontend id="MF_Warrants_Exercise_Edit" component={"./EditExerciseWarrant"} mf="capTable" params={{ go, ...props,...params }} />
  );
};

export default EditExerciseWarrant;

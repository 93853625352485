export default {
    WIZARD_ADD_TITLE: "Does your startup have investors?",
    WIZARD_ADD_SUBTITLE: "Your investors",
    WIZARD_CONFIRM_ADD: "Yes, add an investor",
    WIZARD_ADD_INDIVIDUAL_OR_COMPANY_SUBTITLE: "Add an investor",
    WIZARD_ADDED_SUBTITLE: "Investor added!",
    FIRST_STEP_TOOLTIPS: 'An <b>Investor</b> puts money into a company in return for equity, given as shares, options, convertible equity instruments or convertible loan notes. ',
    FIRST_STEP_IS_NOT_EXIST_TOOLTIPS: 'Let\'s turn \'not right now\' into \'ASAP’. Keep an eye on the Clara star for top tips to get you investment-ready.',
    SECOND_STEP_TOOLTIPS: 'Those backing your big idea could include friends and family, accelerators, angel or seed investors and venture capitalists.',
    THIRD_STEP_TOOLTIPS: 'Add more investors here when you ace a financing round! '
}

import React from "react";
import classes from "./classes.module.scss";
import useTranslate from "../../../generic/hooks/useTranslate";
import TypographyLocal from "../../../generic/components/Texts/Typography";
import Block from "../../../generic/components/Contents/Block";
import ListItem from "../../../generic/components/Lists/ListItem";
import Button from "../../../generic/components/Buttons/Button";
import Text from '../../../../components/text/Text/Text';
import setStatusMatter from "../../../../hooks/services/modules/matter/setStatusMatter.js";
import useNavigation from "../../../generic/hooks/useNavigation";
import { Constants } from '../../../../v1/utils/constants';

const BlockAdgmView = (

) => {

  const {translate} = useTranslate();
  const {mutation} = setStatusMatter({
    showSuccessNotification: false,
  });
  const {goTo, params} = useNavigation();

  const handleAgree = () => {
    goTo(Constants.PAGES.incorporationMatter, params);
  }

  const handleDisagree = () => { 
    return new Promise( async (resolve, reject) => {
      const variables = {
        matterId: params.matterId,
        state: "CLOSED",
        declineReason: translate("MODULES.FORMATION.SELECT_JURISDICTION_ADGM_DISAGREE_MSG"),
      }
      await mutation(variables);
      goTo(Constants.PAGES.scopingDisagree, params);
      resolve();
    })
  }

  return (
    <React.Fragment>
        <div className={classes.contentData}>
            <div className={classes.centerFrame}>
              <div className={classes.claraTitle}>
                <TypographyLocal
                  component={"span"}
                  color={"black"}
                  className={classes.flowTitle}
                >
                  {translate("MODULES.FORMATION.SELECT_JURISDICTION_TITLE")}
                </TypographyLocal>
              </div>
              <Block
                title={<Text uuid={'MODULES.FORMATION.SELECT_JURISDICTION_ADGM_TITLE'} />}
              >
                <ListItem
                  item={<Text uuid={'MODULES.FORMATION.SELECT_JURISDICTION_ADGM_ITEM1'} />}
                />
                <ListItem
                  item={<Text uuid={'MODULES.FORMATION.SELECT_JURISDICTION_ADGM_ITEM2'} />}
                  noBottomMargin={true}
                />
              </Block>
              <Block
                title={<Text uuid={'MODULES.FORMATION.SELECT_JURISDICTION_ADGM_TITLE2'} />}
              >
                <ListItem
                  item={<Text uuid={'MODULES.FORMATION.SELECT_JURISDICTION_ADGM_ITEM3'} />}
                />
                <ListItem
                  item={<Text uuid={'MODULES.FORMATION.SELECT_JURISDICTION_ADGM_ITEM4'} />}
                />
                <ListItem
                  item={<Text uuid={'MODULES.FORMATION.SELECT_JURISDICTION_ADGM_ITEM5'} />}
                  noBottomMargin={true}
                />
              </Block>
              <div className={classes.buttonsContainer}>
                <Button onClick={handleDisagree}>
                  {translate("MODULES.FORMATION.SELECT_JURISDICTION_ADGM_DISAGREE_BTN")}
                </Button>
                <span className={classes.btnSpace}>
                  <Button onClick={handleAgree}>
                    {translate("MODULES.FORMATION.SELECT_JURISDICTION_ADGM_AGREE_BTN")}
                  </Button>
                </span>
              </div>
              <div className={classes.buttonsContainer}>
                <TypographyLocal
                  component={"span"}
                  color={"dark-grey"}
                  className={classes.bottomDisclaimer}
                >
                  <Text uuid={'MODULES.FORMATION.SELECT_JURISDICTION_ADGM_BOTTOM'} />
                </TypographyLocal>
              </div>
            </div>
        </div>
    </React.Fragment>
  );
};

export default BlockAdgmView;

import {TypePolicy,Reference} from "@apollo/client";
const Share:TypePolicy={
    keyFields:['stakeholder']
}
const Equity:TypePolicy= {
    fields: {
        documents(existingOffspring: Reference[], {canRead}) {
            return existingOffspring
                ? existingOffspring.filter(canRead)
                : [];
        }
    }
}
const Warrant:TypePolicy= {
    fields: {
        documents(existingOffspring: Reference[], {canRead}) {
            return existingOffspring
                ? existingOffspring.filter(canRead)
                : [];
        }
    }
}
const Convertible:TypePolicy= {
    fields: {
        documents(existingOffspring: Reference[], {canRead}) {
            return existingOffspring
                ? existingOffspring.filter(canRead)
                : [];
        }
    }
}
export {
    Warrant,
    Convertible,
    Equity,
    Share
}
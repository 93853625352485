import React,{useMemo} from 'react';
import { useSelector } from "react-redux";
import * as Yup from 'yup';
// import { editProfileCorporate } from "../../../../../../forms/schemas/modules/startups/groupCompany/schema";
import SecondaryButton from "../../../../../generic/Buttons/SecondaryButton/SecondaryButton";
import SubmitButton from "../../../../../generic/Buttons/SubmitButton/SubmitButton";
import ControllerInput from '../../../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import TextareaInput from '../../../../../inputs/Textarea/TextareaInput';
import NewModalForm from "../../../../../templates/NewModalForm/NewModalForm";
import Text from '../../../../../text/Text/Text';
import Title from '../../../../../text/Title/Title';
import { RowAdd } from '../../../../contents';
import Form from '../../../../Form/Form';
import useSession from '../../../../../../modules/session/hooks/useSession';
import classesModule from './CancelSubscriptionForm.module.scss';
import _ from "lodash"
const schema = Yup.object().shape({
    reason: Yup.string().required('Required'),
})

const CancelSubscriptionForm = ({ initialValues, handleSubmit, teamRoleAndInvestor, investor, handleCloseModal, handleDeleteModal, product = {}, ...props }) => {
    const {startup} = useSession();
    const productCode = useMemo(()=>{
        return _.get(startup, "activeSubscriptionPlan.code");
    },[JSON.stringify(startup)])

    return (
        <Form initialValues={initialValues} schema={schema} onSubmit={handleSubmit} >
            <NewModalForm
                header={
                    <React.Fragment>
                        <Title className={classesModule.title}>
                            <Text uuid={"CANCEL_SUBSCRIPTION_MODAL_TITLE"} ></Text>
                        </Title>
                        <p className={classesModule.subTitle}>
                            <Text uuid={`MODULES.CHECKOUT.PAYMENT_CANCEL_SUBSCRIPTION_${productCode}`} />
                            
                        </p>
                    </React.Fragment>
                }
                content={
                    <React.Fragment>

                        <RowAdd
                            className={classesModule.rowAdd}
                            label={"STARTUP_VIEW_SUBSCRIPTION_CANCEL_REASON_LABEL"}
                            input={
                                <ControllerInput
                                    as={TextareaInput}
                                    defaultValue=""
                                    placeholder={"STARTUP_VIEW_SUBSCRIPTION_CANCEL_REASON_PLACEHOLDER"}

                                    name={"reason"}
                                    clear={true}
                                />
                            }
                        />
                    </React.Fragment>
                }
                botonera={
                    <React.Fragment>
                        <SecondaryButton className={classesModule.secondaryButton} onClick={() => handleCloseModal()}><Text uuid={'BUTTON_CANCEL'} /></SecondaryButton>
                        <SubmitButton className={classesModule.submitButton}><Text uuid={"STARTUP_VIEW_SUBSCRIPTION_CANCEL_CONTINUE"} /></SubmitButton>
                    </React.Fragment>
                }

            />
        </Form>
    )
}

export default CancelSubscriptionForm;

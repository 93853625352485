import useCustomMachine from '../../../../../generic/hooks/useCustomMachine'
import React, { FC, useEffect } from 'react'
import Button from '../../../../../generic/components/Buttons/Button'
import { StepProps } from "../../../../../generic/components/Wizard/Step"
import TemplateWizard from '../../../../../generic/templates/Modal/TemplateWizard'
import useTranslate from '../../../../../generic/hooks/useTranslate'
import FormSpecialMeaning from '../../../Forms/FormSpecialMeaning'
import { useDispatch } from 'react-redux'
import { addAssistantText, clearAssistatText } from "../../../../../generic/store/action";

const Step1: FC<StepProps> = () => {

  const { translate } = useTranslate();
  const { next, send, stepData } = useCustomMachine();
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clearAssistatText())
    dispatch(addAssistantText(translate('MODULES.GROUP_COMPANY.SETUP.FIRST_STEP.TOOLTIP')))
  }, [])

  const handleCompleteSubmit = async values => {
    next(values);
  }
  const handleCancel = async values => {
    send("CANCEL");
  }

  return (
    <>
      <FormSpecialMeaning
        initialValues={stepData}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: translate('MODULES.GROUP_COMPANY.SETUP.FIRST_STEP.TITLE'),
          subTitle: translate('MODULES.GROUP_COMPANY.SETUP.FIRST_STEP.SUBTITLE')
        }}
        buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }}>
      </FormSpecialMeaning>
    </>
  )
}

export default Step1;


import { useInterpret, useMachine } from '@xstate/react';
import _ from "lodash";
import React, { createContext, useMemo } from 'react';
export const GlobalStateContext = createContext({});

export const GlobalStateProvider = ({ machine, children, ...props }) => {
  
  const [state, send] = useMachine(machine, { devTools: _.get(window, 'env.devTools.xstate', false)});
  const machineService = useInterpret(machine);
  return (
    <GlobalStateContext.Provider value={{ machineService, state, send }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

function useCustomMachine() {
  const { state, send, machineService, ...props } = React.useContext(GlobalStateContext);
  const stepData = useMemo(()=>{
    return  _.get(state,`context.data.${state.value}`,{})
  },[JSON.stringify(_.get(state,`context.data`,{})),state.value])
  const paramsData = useMemo(()=>{
    return  _.get(state,`context.paramsData`,{})
  },[JSON.stringify(_.get(state,`context.paramsData`,{})),state.value])
  const next = React.useCallback((payload) => {
    send({ type: "NEXT", payload: { idState: state.value, values: payload } });
  }, [state, send])
  const prev = React.useCallback((payload) => {
    send({ type: "PREVIOUS", payload: { idState: state.value, values: payload } });
  }, [state, send])
  const globalData = useMemo(()=> {
    return _.get(state, `context.globalData`, [])
  },[JSON.stringify(_.get(state, `context.globalData`, []))])
  return { ...machineService, ...props, state, next,prev, send,stepData,paramsData, globalData }
}

export default useCustomMachine;
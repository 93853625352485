import { actions as actionTypes } from "../actions/notification";

const initialState = {
    notifications: {
		unread_messages: {
			all: 0,
			events: 0,
			remarks: 0
		}
	},
};

export default (state = initialState, action) => {


    switch (action.type) {
        case actionTypes.UPDATE_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload.notification
            };

        case actionTypes.CLEAR_NOTIFICATIONS:
            return {
                ...state,
                notifications: {}
            };
        case actionTypes.DECREMENT_NOTIFICATIONS: {
            if (state.notifications.unread_messages.all > 0) {
                if (action.payload.type === "EVENT") {
                    return {
                        ...state,
                        notifications: {
                            ...state.notifications,
                            unread_messages: {
                                all: state.notifications.unread_messages.all - 1,
                                events: state.notifications.unread_messages.events - 1,
                                remarks: state.notifications.unread_messages.remarks
                            }

                        }
                    }
                }

                return {
                    ...state,
                    notifications: {
                        ...state.notifications,
                        unread_messages: {
                            all: state.notifications.unread_messages.all - 1,
                            events: state.notifications.unread_messages.events,
                            remarks: state.notifications.unread_messages.remarks - 1
                        }
                    }
                }
            }
        }
        default:
            return state;
    }
};

import React, { FC, useEffect, useRef } from 'react';
import { useDispatch } from "react-redux";
import * as Yup from 'yup';
import Form from "../../../../../components/forms/Form/Form";
import SubmitButton from "../../../../../components/generic/Buttons/SubmitButton/SubmitButton";
import Button from "../../../../generic/components/Buttons/Button";
import Typography from "../../../../generic/components/Texts/Typography";
import FormProps from "../../../../generic/forms/interfaces/FormProps";
import useTranslate from "../../../../generic/hooks/useTranslate";
import { clearAssistatText } from "../../../../generic/store/action";
import TemplateFormDefault from "../../../../generic/templates/Modal";
import classes from './classes.module.scss';

const schema = Yup.object().shape({
  
})

interface Props extends FormProps {
  initialValues: any,
  onCompleteSubmit: any,
  key: string,
  handlePrev: any,
}

const FormStep1AddInfo: FC<Props> = ({
  initialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
  handlePrev,
  key,
}) => {

  const refForm = useRef();
  const dispatch = useDispatch();
  const { translate } = useTranslate();

  useEffect(() => {
    clearAssistatText();
    return () => {
      dispatch(clearAssistatText());
    }
  }, []);

  return (
    <>
      <Form
        key={key}
        defaultValues={initialValues || {}}
        schema={schema}
        onSubmit={onCompleteSubmit}
        ref={refForm} optionsForm={{ mode: "onChange" }}
      >
        <Template 
          props={propsTemplate} 
          buttons={{
            ...buttons,
            submit: <SubmitButton {...buttonSubmitProps}>Confirm</SubmitButton>,
            previous: <Button onClick={handlePrev} variant="secondary">{translate('GENERIC_BUTTON_PREVIOUS')}</Button>,
          }}
          >
          <Typography
            className={classes.typoNormal}
          >
            {translate("MODAL_SELECT_TOPCO_STEP1_ADD_INFO_P1")}
          </Typography>
          <Typography
            className={classes.typoNormal}
          >
            {translate("MODAL_SELECT_TOPCO_STEP1_ADD_INFO_P2")}
          </Typography>
          <Typography
            className={classes.typoNormal}
          >
            {translate("MODAL_SELECT_TOPCO_STEP1_ADD_INFO_P3")}
          </Typography>
        </Template>
      </Form>
    </>
  )
}

export default FormStep1AddInfo
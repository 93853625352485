import React from "react";
import Form from "src/components/forms/Form/Form";
import TextInput from "src/components/inputs/Text/TextInput";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import CalendarInputDate from "src/components/inputs/Calendars/CalendarInputDate";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import TemplateFormDefault from "src/modules/generic/templates/Modal";
import Checkbox from "src/components/inputs/Checkbox/Checkbox";
import useGroupCompanyDetailsFieldsAnalyzer from "src/components/forms/modules/startups/hooks/useGroupCompanyDetailsFieldsAnalyzer";
import { AnalizerInputWithBanner } from "src/components/inputs/AnalizerInputWithBanner";
import CountryJurisdiction from "src/modules/generic/components/CountryJurisdictionSelector";
import SelectInput from "src/components/inputs/Select/SelectInput/SelectInput";
import CurrencySelector from "src/components/inputs/Select/CurrencySelector/CurrencySelector";

const FormGeneralDetailsADGM = ({
  context,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
}) => {
  const { translate } = useTranslate();

  const initialValues = context?.initialData?.groupCompany;
  const pincasData = context?.pincasData?.pincasData;

  const { config, schema } = useGroupCompanyDetailsFieldsAnalyzer(
    initialValues,
    pincasData
  );

  const handleSubmit = (values) => {
    onCompleteSubmit && onCompleteSubmit(values);
  };

  const licenseTypeList = [
    {
      value: "SPV",
      label: "Special Purpose Vehicles (SPV)",
    },
    {
      value: "TSL",
      label: "Tech Licence",
    },
  ];

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <Template
        buttons={{
          ...buttons,
          submit: (
            <SubmitButton {...buttonSubmitProps}>Save changes</SubmitButton>
          ),
        }}
        props={propsTemplate}
      >
        <ControllerInput
          label="This is the top level holding company."
          isMultiple={false}
          render={Checkbox}
          name="isHoldingCompany"
        />
        <AnalizerInputWithBanner
          component={TextInput}
          controllerInputProps={{
            name: "name",
            placeholder: translate("MODULES.CLARA.TYPE_HERE_HIT"),
          }}
          field={config.name}
        />
        <AnalizerInputWithBanner
          component={CalendarInputDate}
          controllerInputProps={{
            name: "nameBecameEffectiveOn",
            placeholder: translate("MODULES.CLARA.ROLE_DATE_INPUT_HIT"),
          }}
          field={config.nameBecameEffectiveOn}
        />
        <AnalizerInputWithBanner
          component={TextInput}
          controllerInputProps={{
            name: "previousName",
            placeholder: translate("MODULES.CLARA.TYPE_HERE_HIT"),
          }}
          field={config.previousName}
        />
        <CountryJurisdiction disabled={initialValues?.managedByClara} />
        <AnalizerInputWithBanner
          component={TextInput}
          controllerInputProps={{
            name: "type",
            placeholder: translate("MODULES.CLARA.TYPE_HERE_HIT"),
          }}
          field={config.type}
        />
        <AnalizerInputWithBanner
          component={SelectInput}
          controllerInputProps={{
            name: "licenseType",
            placeholder: translate("MODULES.CLARA.TYPE_HERE_HIT"),
            list: licenseTypeList,
          }}
          field={config.licenseType}
        />
        <AnalizerInputWithBanner
          component={TextInput}
          controllerInputProps={{
            name: "companyStatus",
            placeholder: translate("MODULES.CLARA.TYPE_HERE_HIT"),
          }}
          field={config.companyStatus}
        />
        <AnalizerInputWithBanner
          component={TextInput}
          controllerInputProps={{
            name: "registeredNumber",
            placeholder: translate("MODULES.CLARA.TYPE_HERE_HIT"),
          }}
          field={config.registeredNumber}
        />
        <AnalizerInputWithBanner
          component={CalendarInputDate}
          controllerInputProps={{
            name: "incorporation_date",
            placeholder: translate("MODULES.CLARA.ROLE_DATE_INPUT_HIT"),
          }}
          field={config.incorporation_date}
        />
        <AnalizerInputWithBanner
          component={CalendarInputDate}
          controllerInputProps={{
            name: "dissolution_date",
            placeholder: translate("MODULES.CLARA.ROLE_DATE_INPUT_HIT"),
          }}
          field={config.dissolution_date}
        />
        <AnalizerInputWithBanner
          component={CurrencySelector}
          controllerInputProps={{
            name: "transactionCurrency",
            placeholder: translate("MODULES.CLARA.TYPE_HERE_HIT"),
          }}
          field={config.transactionCurrency}
        />
        <AnalizerInputWithBanner
          component={TextInput}
          controllerInputProps={{
            name: "authorisedShareCapital",
            placeholder: translate("MODULES.CLARA.TYPE_HERE_HIT"),
            type: "number",
            leftLabel: "USD",
          }}
          field={config.authorisedShareCapital}
        />
        <AnalizerInputWithBanner
          component={TextInput}
          controllerInputProps={{
            name: "linkedinURL",
            placeholder: translate("MODULES.CLARA.TYPE_HERE_HIT"),
          }}
          field={config.linkedinURL}
        />
        <div>&nbsp;</div>
      </Template>
    </Form>
  );
};

export default FormGeneralDetailsADGM;

import classnames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import classes from "./classes.module.scss";
import TypographyLocal from "../../Texts/Typography";

/**
 * ListItem content
 */

const ListItem = ({
  item,
  noBottomMargin,
  color = 'blue',
  textColor = 'black',
  customClass = true,
  typographyProps= {},
  ...props
}) => {
  return (
    <React.Fragment>
      <div
        className={classnames({
          [classes.listItemContainerNoMargin]: noBottomMargin,
          [classes.listItemContainer]: !noBottomMargin,
        })}
      >
        <div className={classnames(classes.dotContainer, classes[color])}>
          &#9679;
        </div>
        <div className={classnames(classes.textContainer)}>
          <TypographyLocal
            component={"span"}
            color={textColor}
            className={classnames({
              [classes.listItem]: customClass
            })}
            {...typographyProps}
          >
            {item}
          </TypographyLocal>
        </div>
      </div>
    </React.Fragment>
  );
};

ListItem.propTypes = {
  /** Property item */
  item: PropTypes.string.isRequired,
  /** Property noBottomMargin */
  noBottomMargin: PropTypes.bool,
};
ListItem.defaultProps = {
  item: "Test item",
  noBottomMargin: false,
};

export default ListItem;

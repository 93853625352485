import React, { FC, useEffect } from 'react';
import { addAssistantText, clearAssistatText } from "../../../../generic/store/action";

import Button from '../../../../generic/components/Buttons/Button';
import FormConfirmShareholdersDirectors from "../../Forms/FormConfirmShareholdersDirectors";
import { StepProps } from "../../../../generic/components/Wizard/Step";
import TemplateWizard from '../../../../generic/templates/Modal/TemplateWizard';
import { useDispatch } from 'react-redux';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from '../../../../generic/hooks/useTranslate';

const ConfirmShareholdersDirectorsStep: FC<StepProps> = () => {
  const { translate } = useTranslate();
  const { next, send } = useMachine();
  const dispatch = useDispatch();
 
  useEffect(() => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText(translate('MODULES.GROUP_COMPANY.SHARE_CAPITAL_ADGM_CAYMAN.FIRST_STEP.TOOLTIP')));
  }, []);

  const handleCompleteSubmit = async (values) => {
    next(values);
  }

  const handleCancel = () => {
    send("CANCEL");
  }

  return (
    <>
      <FormConfirmShareholdersDirectors
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: translate('MODULES.GROUP_COMPANY.SHARE_CAPITAL.FIRST_STEP.TITLE'),
          subTitle: translate('MODULES.GROUP_COMPANY.SHARE_CAPITAL_ADGM_CAYMAN.GENERIC.SUBTITLE')
        }}
        buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }}/>
    </>
  );
}

export default ConfirmShareholdersDirectorsStep;
import React, { useEffect, useMemo } from "react";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import Button from "src/modules/generic/components/Buttons/Button";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import { useDispatch } from "react-redux";
import { clearAssistatText } from "src/modules/generic/store/action";
import FormIsThereMoreCompanies from "src/modules/startup/GroupCompany/Forms/FormIsThereMoreCompanies";

function DoYouHaveMoreCompaniesStep() {
  const { translate } = useTranslate();
  const { next, prev, cancel, context } = useMachine();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.DoYouHaveMoreCompaniesStep ?? {};
  }, [context]);

  useEffect(() => {
    dispatch(clearAssistatText());
  }, []);

  const handleSubmit = async (values) => {
    next(values);
  };

  const handleCancel = () => {
    cancel();
  };

  const handlePrev = () => {
    prev();
  };

  return (
    <>
      <FormIsThereMoreCompanies
        initialValues={initialValues}
        onCompleteSubmit={handleSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: translate(
            "MODULES.GROUP_COMPANY.ASSETS_AND_REVENUES.THIRD_STEP.TITLE"
          ),
          subTitle: "Review company details",
        }}
        buttons={{
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              Cancel
            </Button>
          ),
        }}
      />
    </>
  );
}

export default DoYouHaveMoreCompaniesStep;

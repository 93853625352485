
const useGetMapEconomicSubstance = () => {

  const getCompanyDetails = (context) => {
    const { ReviewCompanyDetailsStep, ReviewCompanyAddressStep } = context;
    return {
      companyDetails: {
        ...ReviewCompanyDetailsStep,
        mailingAddress: {
          street: ReviewCompanyAddressStep?.mailingAddress?.street,
          city: ReviewCompanyAddressStep?.mailingAddress?.city,
          state: ReviewCompanyAddressStep?.mailingAddress?.state,
          country: ReviewCompanyAddressStep?.mailingAddress?.country,
          zipCode: ReviewCompanyAddressStep?.mailingAddress?.zipCode,
          type: ReviewCompanyAddressStep?.mailingAddressType?.toUpperCase()
        }
      }
    }
  }
  const getGroupCompanyUpdateDetails = (context) => {
    const { ReviewCompanyDetailsStep, ReviewCompanyAddressStep, ReviewRelevantActivitiesStep } = context;
    return {
      groupCompany: {
        name: ReviewCompanyDetailsStep?.name,
        mailingAddress: {
          street: ReviewCompanyAddressStep?.mailingAddress?.street,
          city: ReviewCompanyAddressStep?.mailingAddress?.city,
          state: ReviewCompanyAddressStep?.mailingAddress?.state,
          country: ReviewCompanyAddressStep?.mailingAddress?.country,
          zipCode: ReviewCompanyAddressStep?.mailingAddress?.zipCode,
          type: ReviewCompanyAddressStep?.mailingAddressType?.toUpperCase()
        },
        businessActivities: {
          relevantActivities: ReviewRelevantActivitiesStep.activities,
        }
      }
    }
  }

  const getEconomicSubstance = (context) => {
    const { ReviewRelevantActivitiesStep, ReviewCompanyTaxInformationStep } = context;
    return {
      taxInformation: ReviewCompanyTaxInformationStep,
      businessActivities: {
        relevantActivities: ReviewRelevantActivitiesStep.activities,
      }
    }
  }

  const getIncome = (context) => {
    return {
      companyIncome: {
        currency: context?.ConfirmCompanyIncomeStep?.currency,
        dividend: context?.ConfirmCompanyIncomeStep?.dividendIncome,
        capitalGains: context?.ConfirmCompanyIncomeStep?.capitalGainsIncome,
        relevant: context?.ConfirmCompanyIncomeStep?.relevantIncome,
        incidental: context?.ConfirmCompanyIncomeStep?.incidentalIncome,
        total: context?.ConfirmCompanyIncomeStep?.totalIncome
      }
    }
  }

  const economicSubstanceMapper = (context) => {
    const companyDetails = getCompanyDetails(context);
    const groupComany = getGroupCompanyUpdateDetails(context);
    const economicSubstanceInformation = getEconomicSubstance(context);
    const incomeInformation = getIncome(context);
    return { ...companyDetails, ...economicSubstanceInformation, ...incomeInformation, ...groupComany }
  }

  return economicSubstanceMapper
}

export default useGetMapEconomicSubstance;

import React, {FC} from 'react';
import MachineDirector from "../MachineDirector";
import Wizard from "../../../../../generic/components/Wizard";
import ModalAssistant, {ModalAssistantProps} from '../../../../../generic/components/Modal/ModalAssistant';
import useModal from "../../../../../generic/hooks/useModal";

const ModalDirector: FC<ModalAssistantProps> = ({open = false}) => {
    const {closeModal} = useModal();

    const handleClose = () => {
        closeModal();
    }

    return (
        <ModalAssistant open={open} onClose={handleClose}>
            <Wizard machine={MachineDirector} onComplete={handleClose}/>
        </ModalAssistant>
    );
}

export default ModalDirector;

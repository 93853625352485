import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Loading from "../../../../../components/generic/Loading/Circular/Circular";
import useGetSubscriptionPrices from "../../../../../hooks/services/modules/subscription/useGetSubscriptionPrices";
import CardProductScale from "../../../../../modules/checkout/components/Upgrade/CardProductScale";
import CardProduct from "../../../../../modules/checkout/components/Upgrade/CardProductStart";
import ListProducts from "../../../../../modules/checkout/components/Upgrade/ListProducts";
import { Constants } from "../../../../../v1/utils";
import classes from "./Upgrade.module.scss";
import TypographyLocal from "../../../../../../src/modules/generic/components/Texts/Typography";
import DataFooter from "../../../../../modules/checkout/components/Upgrade/DataFooter";

const Upgrade = ({ ...props }) => {
  const dispatch = useDispatch();
  const { loading, data } = useGetSubscriptionPrices();
  const [product, setProduct] = useState(
    Constants.MODULES.CHECKOUT.PRODUCTS.SCALE_ANNUAL_SUBSCRIPTION
  );

  const goTo = () => {
    dispatch({
      type: "GO_TO",
      page: Constants.PAGES.MODULES.CHECKOUT.payment,
      params: { productCode: product },
    });
  };

  const [isMonths, setIsMonth] = useState(true);

  const handleChangeProduct = (e) => {
    if (!isMonths) {
      setProduct(Constants.MODULES.CHECKOUT.PRODUCTS.SCALE_ANNUAL_SUBSCRIPTION);
    } else {
      setProduct(
        Constants.MODULES.CHECKOUT.PRODUCTS.SCALE_ANNUAL_SUBSCRIPTION_MONTHLY
      );
    }
    setIsMonth(e.target.checked);
  };

  return (
    <>
      <Loading loading={loading}>
        <div
          className={`${classes.containerUpgrade} ${classes.containerUpgradeScale} `}
        >
          <TypographyLocal
            variant="h2"
            color="blue"
            className={`${classes.upgradeTitle} `}
          >
            Upgrade
          </TypographyLocal>
          <div className={classes.containerCardsUpgrade}>
            <div className={classes.containerTwoCards}>
              <div className={classes.containerCardFree}>
                <CardProduct />
              </div>
              <div className={classes.containerCardUpgrade}>
                <CardProductScale
                  handleClick={goTo}
                  subscriptionType={isMonths}
                  ifChangeProduct={handleChangeProduct}
                />
              </div>
            </div>
          </div>
          <TypographyLocal className={`${classes.upgradeTitleProducts} `}>
            {"Benefits to boost your business"}
          </TypographyLocal>
          <TypographyLocal className={`${classes.upgradeSubTitleProducts} `}>
            {
              "Wave goodbye to expensive legal fees. Save up to USD 20,000 with a Scale subscription."
            }
          </TypographyLocal>
          <div className={classes.containerListProductos}>
            <ListProducts product={product} data={data} />
          </div>
          <div className={classes.endData}>
            <DataFooter
              handleClick={goTo}
              // subcriptionType={isMonths}
              // ifChangeProduct={handleChangeProduct}
            />
          </div>
        </div>
      </Loading>
    </>
  );
};

export default Upgrade;

import React, { FC, useRef } from 'react';
import * as Yup from 'yup';
import Form from "../../../../../components/forms/Form/FormL";
import CalendarInputDate from "../../../../../components/inputs/Calendars/CalendarInputDate";
import TextInput from "../../../../../components/inputs/Text/TextInput";
import SubmitButton from "../../../../generic/components/Buttons/SubmitButton";
import TemplateLabel from "../../../../generic/components/Inputs/LabelInput";
import ControllerInput from "../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import Typography from "../../../../generic/components/Texts/Typography";
import FormProps from "../../../../generic/forms/interfaces/FormProps";
import useTranslate from "../../../../generic/hooks/useTranslate";
import TemplateFormDefault from "../../../../generic/templates/Modal";
import classesModule from "../../../onBoarding/company/forms/CompanyForm/classes.module.scss";
import Currency from "src/components/inputs/Currency/Currency";


const schema=Yup.object().shape({
    valuationCap:Yup.number().nullable().required('Required').typeError('Must be a number'),
    amount:Yup.number().nullable().required('Required').typeError('Must be a number'),
    issuedDate:Yup.date().nullable().required('Required').typeError('Add a valid date'),
    discountRate:Yup.number().nullable().required('Required').typeError('Must be a number'),
    interestRate:Yup.number().nullable().required('Required').typeError('Must be a number')
})
interface ConvertibleFormProps extends FormProps{
    companyName:string
    currency:string
}
const ConvertibleForm: FC<ConvertibleFormProps> = ({initialValues,currency, tooltips, onCompleteSubmit, Template = TemplateFormDefault, buttons = {}, buttonSubmitProps, propsTemplate,companyName}) => {
    const { translate } = useTranslate();
    const refForm=useRef()
    return (
        <React.Fragment>
            <Form
                initialValues={initialValues || {}}
                schema={schema}
                onlySendDirty={false}
                onSubmit={onCompleteSubmit}
                ref={refForm} optionsForm = {{mode:"onChange"}}
            >
                <Template props={propsTemplate} buttons={{submit:<SubmitButton{...buttonSubmitProps}>Next</SubmitButton>,...buttons}}>
                    <Typography component={'h5'} color={'black'}>
                        {companyName}
                    </Typography>
                    <TemplateLabel label={translate('MODULES.EQUITY.FORMS.WARRANT_FORM.AMOUNT')} isRequired={true}>
                        <ControllerInput
                            render={Currency}
                            name="amount"
                            type={'number'}
                            defaultValue={""}
                            currency={
                                currency
                            }
                            onChange={(e) => {}}
                        />
                    </TemplateLabel>
                    <TemplateLabel label={translate('MODULES.EQUITY.FORMS.CONVERTIBLE_FORM.DISCOUNT')} isRequired={true}>
                        <ControllerInput
                            as={TextInput}
                            name="discountRate"
                            type={'number'}
                            rightLabel={'%'}
                            //defaultValue={''}
                            placeholder={translate('MODULES.EQUITY.FORMS.CONVERTIBLE_FORM.PLACEHOLDER_NUMBER')}
                            onChange={(e) => {

                            }}
                        />
                    </TemplateLabel>
                    <TemplateLabel label={translate('MODULES.EQUITY.FORMS.CONVERTIBLE_FORM.INTEREST_RATE')} isRequired={true}>
                        <ControllerInput
                            as={TextInput}
                            name="interestRate"
                            type={'number'}
                            rightLabel={'%'}
                            //defaultValue={''}
                            placeholder={translate('MODULES.EQUITY.FORMS.CONVERTIBLE_FORM.PLACEHOLDER_NUMBER')}
                            onChange={(e) => {

                            }}
                        />
                    </TemplateLabel>
                    <TemplateLabel label={translate('MODULES.EQUITY.FORMS.CONVERTIBLE_FORM.VALUATION')} isRequired={true}>
                        <ControllerInput
                            render={Currency}
                            name={"valuationCap"}
                            type={'number'}
                            defaultValue={""}
                            currency={
                                currency
                            }
                            onChange={(e) => {}}
                        />
                    </TemplateLabel>
                    <TemplateLabel
                        label={translate('MODULES.EQUITY.FORMS.CONVERTIBLE_FORM.ISSUED_DATE')}
                        className={classesModule.LabelTitle}
                        isRequired={true}
                    >
                        <ControllerInput
                            as={CalendarInputDate}
                            name='issuedDate'
                            placeholder={translate('MODULES.EQUITY.FORMS.CONVERTIBLE_FORM.PLACEHOLDER_DATE')}
                        />
                    </TemplateLabel>

                </Template>
            </Form>
        </React.Fragment>
    )
}

export default ConvertibleForm

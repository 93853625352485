import { createMachine } from 'xstate';

const machine =
  createMachine({
    id: 'ImmediateParents',
    initial: 'setInitialData',
    states: {
      setInitialData: {
        invoke: {
          src: 'setInitialData',
          id: 'setInitialData',
          onDone: [
            {
              target: 'AddForm',
              actions: ['setInitialContext']
            }
          ]
        }
      },
      AddForm: {
        on: {
          NEXT: {
            target: 'checkIfMoreItems',
            actions: ['setContextAfterForm']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      // si isLastImmediateParent es true, entonces se termina el flujo
      checkIfMoreItems: {
        always: [
          {
            target: 'SaveStep',
            actions: ['setContextAfterEvaluation'],
            cond: (context : any) =>{
             return context.immediateParentIndex === context.immediateParents.length - 1}
          },
          {
            target: 'AddForm',
            actions: ['setContextAfterEvaluation']
          }
        ],
      },
      SaveStep: {
        invoke: {
          src: 'SaveStep',
          id: 'SaveStep',
          onDone: [
            {
              target: 'finish',
            }
          ],
          onError: [
            {
              target: 'finish'
            }
          ]
        }
      },
      cancel: {
        entry: 'cancelMachine',
        type: 'final'
      },
      finish: {
        entry: 'stopMachine',
        type: 'final'
      }
    }
  });

export default machine;

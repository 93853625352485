import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import Form from 'src/components/forms/Form/Form';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import Button from 'src/modules/generic/components/Buttons/Button';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import RadioInput from "src/components/inputs/Radio/RadioInput";
import TextInput from "src/components/inputs/Text/TextInput";
import { HiddenFieldForm } from "src/components/forms/contents";
import classes from './classes.module.scss';
import _ from 'lodash';

const schemaWithDescription = Yup.object({
  question: Yup.boolean().required('Required'),
  description: Yup.string().when(['question'], {
    is: true,
    then: Yup.string().nullable(true).required('Required'),
    otherwise: Yup.string().optional().nullable(true)
  })
});

const schemaQuestion = Yup.object({
  question: Yup.boolean().required('Required'),
});

const schema = Yup.object().shape({
  isTaxResidentsAreFromCayman: schemaQuestion,
  isEntityInvestmentFund: schemaQuestion,
  isCompanyCarriedOnRelevantActivity: schemaQuestion,
  doesHaveFINumber: schemaWithDescription,
  doesHaveIRSGIN: schemaWithDescription,
});

function TaxInformationStep() {
  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    const taxInformationFull = context?.initialData?.groupCompany?.requestBasket?.requestData?.taxInformation;
    const taxInformation = _.omit(taxInformationFull, ['id']);
    return taxInformation
      ;
  }, [context]);

  const handleCancel = () => {
    cancel();
  }

  const handleSubmit = async (values) => {
    next(values);
  }

  useEffect(() => {
    dispatch(addAssistantText(translate('MODULES.RENEWALS.ECONOMIC_SUBSTANCE.TAX_INFORMATION.TOOLTIP')));
    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch]);

  // TODO aca van a cambiar los nombres de los campos.

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: "Edit economic substance information",
          skeleton: null,
          variant: 'simple'
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton>{translate('BUTTON_SAVE_CHANGES')}</SubmitButton>,
        }}
      >
        <ol className={classes.list} type="a">
          <li>
            <TemplateLabel
              label={translate('MODULES.RENEWALS.ECONOMIC_SUBSTANCE.TAX_INFORMATION.OPTION_1')}
              isRequired
              isColumn={true}
              className={classes.radioInput}
            >
              <ControllerInput
                render={RadioInput}
                name='isTaxResidentsAreFromCayman.question'
                defaultValue={false}
              />
            </TemplateLabel>
          </li>
          <li>
            <TemplateLabel
              label={translate('MODULES.RENEWALS.ECONOMIC_SUBSTANCE.TAX_INFORMATION.OPTION_2')}
              isRequired
              isColumn={true}
              className={classes.radioInput}
            >
              <ControllerInput
                render={RadioInput}
                name='isEntityInvestmentFund.question'
                defaultValue={false}
              />
            </TemplateLabel>
          </li>
          <li>
            <TemplateLabel
              label={translate('MODULES.RENEWALS.ECONOMIC_SUBSTANCE.TAX_INFORMATION.OPTION_3')}
              isRequired
              isColumn={true}
              className={classes.radioInput}
            >
              <ControllerInput
                render={RadioInput}
                name='isCompanyCarriedOnRelevantActivity.question'
                defaultValue={false}
              />
            </TemplateLabel>
          </li>
          <li>
            <TemplateLabel
              label={translate('MODULES.RENEWALS.ECONOMIC_SUBSTANCE.TAX_INFORMATION.OPTION_4')}
              isRequired
              isColumn={true}
              className={classes.radioInput}
            >
              <ControllerInput
                render={RadioInput}
                name='doesHaveFINumber.question'
                defaultValue={false}
              />
            </TemplateLabel>
            <HiddenFieldForm
              fieldsWatch="doesHaveFINumber.question"
              conditionHideCallback={(value) => value}
            >
              <TemplateLabel label="Please provide number" isRequired>
                <ControllerInput
                  render={TextInput}
                  name='doesHaveFINumber.description'
                />
              </TemplateLabel>
            </HiddenFieldForm>
          </li>
          <li>
            <TemplateLabel
              label={translate('MODULES.RENEWALS.ECONOMIC_SUBSTANCE.TAX_INFORMATION.OPTION_5')}
              isRequired
              isColumn={true}
              className={classes.radioInput}
            >
              <ControllerInput
                render={RadioInput}
                name='doesHaveIRSGIN.question'
                defaultValue={false}
              />
            </TemplateLabel>
            <HiddenFieldForm
              fieldsWatch="doesHaveIRSGIN.question"
              conditionHideCallback={(value) => value}
            >
              <TemplateLabel label="Please provide number" isRequired>
                <ControllerInput
                  render={TextInput}
                  name='doesHaveIRSGIN.description'
                />
              </TemplateLabel>
            </HiddenFieldForm>
          </li>
        </ol>
      </TemplateWizard>
    </Form>
  );
}

export default TaxInformationStep;
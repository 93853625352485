import useMutation from 'src/hooks/custom/useMutation'

export const UPLOAD_BULK_DOCUMENTS = `
  mutation addAllDocumentsAndConfirmTask($startupId: ID! $documents: [AgreementInputType] $groupCompanyId: ID! $stakeholderId: ID! $areShareholdersAndDirectorsConfirmed:Boolean = true) {
    uploadBulkDocuments( startupId: $startupId, documents: $documents) {
      id
    }
    upsertRenewalData(
        startupId: $startupId
        groupCompanyId: $groupCompanyId
        renewalData: {stakeholderTasksDetails:{areShareholdersAndDirectorsConfirmed:$areShareholdersAndDirectorsConfirmed,stakeholderId:$stakeholderId}}
      ) {
        id
    }
  }
`;

const useAddAllDocumentsAndConfirmTask = (props = {}) => {
  const [mutation] = useMutation(UPLOAD_BULK_DOCUMENTS, {}, { ...props });
  return  mutation 
}

export default useAddAllDocumentsAndConfirmTask
import { gql } from '@apollo/client';
import useMutation from "../../../../../hooks/custom/useMutation";
import useSession from "../../../../session/hooks/useSession";
import {  ADD_SHARE_INCENTIVE_PLAN } from "../fragments";
import { COMPANY_CORPORATE_MAP } from '../../../../map/hooks/services/useCompanyCorporateMap';


const UPSERT_SIP = `
${ADD_SHARE_INCENTIVE_PLAN}
mutation upsertShareIncentivePlan(
    $startupId: String, 
    $shareIncentivePlan: ComponentShareIncentivePlanInputType) {
        upsertShareIncentivePlan (
          startupId: $startupId, 
          shareIncentivePlan:$shareIncentivePlan,
      ) {
            ...AddShareIncentivePlan
      }
}`;
const useUpsertSipDocument=(config,props)=>{
  const { startupId } = useSession();
    const [upsertSipDocument] = useMutation(UPSERT_SIP, config,{ refetchQueries:[{query:gql`${COMPANY_CORPORATE_MAP}`, variables:{startupId:startupId}}], ...props })
    return {upsertSipDocument}
}
export default useUpsertSipDocument
import React, { useState,useEffect } from 'react'
import get from 'lodash/get'
import useFetch from '../../../../../modules/generic/hooks/useFetch';
import useTranslate from "../../../../../modules/generic/hooks/useTranslate";
import classes from '../ChooseDocuments/ChooseDocumentsView.module.scss';
import avatarMintz from 'src/images/mintz.svg';
import { DOCUMENT_REGIONS } from './useRegion'
const translationDescriptionByCode = {
  [DOCUMENT_REGIONS.INTERNATIONAL_DOCUMENTS_TYPES]: 'DOCUMENT_INTERNATIONAL_DESCRIPTION',
    [DOCUMENT_REGIONS.UNITED_STATES_DOCUMENTS_TYPES]: 'DOCUMENT_UNITED_STATES_DESCRIPTION',
    [DOCUMENT_REGIONS.Y_COMBINATOR_DOCUMENTS_TYPES]:'DOCUMENT_Y_COMBINATOR_DESCRIPTION'
}
const useContent = (docTypeClicked) => {
    const [content,setContent] = useState();
    const { translate } = useTranslate()

    const clear = () => {
        setContent(null);
      }

    const getRegionContent = (region) =>{
      return  <>
      <div className={classes.selectedRegionInformationContainer}>
        <div className={classes.selectedRegionInformationTitle}>
          {region?.name}
        </div>
        <div className={classes.selectedRegionInformationDescription}>
          {translate(translationDescriptionByCode[region?.code])}
        </div>
        {region?.code === DOCUMENT_REGIONS.UNITED_STATES_DOCUMENTS_TYPES && <div className={classes.poweredBy}><img src={avatarMintz} alt="Mintz" className={classes.mintzImage} />{translate('DOCUMENT_UNITED_STATES_POWERED_BY')}</div>}
      </div>
    </>
    }
    
    const onCompleted = (values) => {
        const { content: text, file } = values;
        if (get(docTypeClicked, 'code', null)) {
          setContent({ text, file });
        }
      }
    
    const { loading , refetch: refetchDataContenful } = useFetch({
        url: `/contentful/agreementLandingContent/${docTypeClicked.entryId}`,
        defaultResponse: {},
        onCompleted,
        cancelAutomaticFetch: !docTypeClicked.entryId
    });
    
      useEffect(() => {
        if (get(docTypeClicked, 'entryId', null)) {
          refetchDataContenful();
        }
      }, [JSON.stringify(docTypeClicked)]);

      return { content, clear,loading,getRegionContent }
}
export default useContent;
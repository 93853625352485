import React, { FC } from "react";
import Button from "src/modules/generic/components/Buttons/Button";
import { CheckboxOptions } from "src/modules/generic/components/Inputs/Checkbox";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import DeleteForm from "src/modules/generic/forms/Delete";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import TemplateDelete from "src/modules/generic/templates/Modal/TemplateDelete";
import classes from '../../classes.module.scss';
import Reasons from "../../components/Reasons";

interface ReasonsNotAbleToDeleteProps {
  data:any
}

const ReasonsNotAbleToDelete: FC<ReasonsNotAbleToDeleteProps> = ({ data }) => {

  const { translate } = useTranslate()
  const { context, next,send } = useMachine();
  const { connections } = context?.getConnections
  const { stakeholder } = context?.deleteVerification

  const handleCancel = () => {
    send('CANCEL')
  }

  const onSubmit = async (values) => {
    next(values)
  }

  const options: Array<CheckboxOptions<string>> =connections.roles.length > 0?[{ value: 'resolve', label: translate('MODULES.STAKEHOLDER.DELETE.RESOLVE')}]:[{ value: 'resolve', label: translate('MODULES.STAKEHOLDER.DELETE.RESOLVE')}, { value: 'invalidate', label: translate('MODULES.STAKEHOLDER.DELETE.INVALIDATE') }]
  const title =  translate('MODULES.STAKEHOLDER.DELETE.TITLE_CONNECTIONS', { stakeholder })

  return (

        <DeleteForm
          propsTemplate={{
            title,
            description: <Reasons connections={connections} />
          }}
          onCompleteSubmit={onSubmit}
          checkBoxProps={{ options }}
          isThereConnections={true}
          buttons={{
            cancel: <Button type={'button'} className={classes.cancelButton}
              onClick={handleCancel}>Cancel</Button>
          }}
          Template={TemplateDelete}
        />
  )
}

export default ReasonsNotAbleToDelete
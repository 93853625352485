import { useMemo } from 'react';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import AddIndividual from "../steps/AddIndividual";
import DirectorAdded from "../steps/DirectorAdded";

const useSteps = () => {
  return useMemo(() => ({
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    AddIndividual: {
      render: AddIndividual,
    },
    DirectorAdded: {
      render: DirectorAdded,
    },
    SaveStep: {
      render: TemplateWizardSkeletonMachine,
    }
  }), []);
}

export default useSteps;

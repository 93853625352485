import _ from 'lodash';
import React, { FC, useMemo, useRef } from 'react';
import * as Yup from 'yup';
import Form from '../../../../components/forms/Form/Form';
import SubmitButton from "../../../generic/components/Buttons/SubmitButton";
import Checkbox from '../../../generic/components/Inputs/Checkbox';
import TemplateLabel from "../../../generic/components/Inputs/LabelInput";
import ControllerInput from "../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import FormProps from "../../../generic/forms/interfaces/FormProps";
import useTranslate from "../../../generic/hooks/useTranslate";
import TemplateFormDefault from "../../../generic/templates/Modal";
import useSession from '../../../session/hooks/useSession';
import useGetGroupCompanies from '../../graphql/groupCompany/query/useGetGroupCompanies';
import classes from './Form.module.scss';

const schema = Yup.object().shape({
    company: Yup.array().required('Required').min(1)
})


interface SelectCompaniesProps extends FormProps {
}
const SelectCompanyForm: FC<SelectCompaniesProps> = ({ initialValues, onCompleteSubmit, Template = TemplateFormDefault, buttons = {}, buttonSubmitProps, propsTemplate }: SelectCompaniesProps) => {
    const { translate } = useTranslate();

    const { startupId } = useSession();

    const { data, loading } = useGetGroupCompanies({ variables: { startupId } })

    const groupCompanies = useMemo(() => {
        return _.map(data, gc => ({ value: gc.id, label: gc.name }))
    }, [loading]);
    const refForm = useRef();

    const handleSubmit = (values: any) => {
        onCompleteSubmit({ company: _.head(values.company) } as any);
    }

    return (
        <>
            <Form
                defaultValues={initialValues}
                schema={schema}
                onlySendDirty={false}
                onSubmit={handleSubmit}
                ref={refForm} optionsForm={{ mode: "onChange" }}
            >
                <Template
                    buttons={{ ...buttons, submit: <SubmitButton {...buttonSubmitProps}>Next</SubmitButton> }}
                    props={propsTemplate}
                >
                    <TemplateLabel className={`pt-5 ${classes.checkBox}`} label={translate("MODULES.EQUITY.FORMS.SELECT_COMPANIES.COMPANIES")} isRequired={true}>
                        <ControllerInput
                            render={Checkbox}
                            isMultiple={false}
                            name='company'
                            variant='col_1'
                            functionCompare={(value1, value2) => value1 === value2}
                            options={groupCompanies}
                        />
                    </TemplateLabel>
                </Template>
            </Form>
        </>
    )
}
export default SelectCompanyForm

import React, { FC } from "react";
import classes from "./DocumentTab.module.scss";
import classesModule from "./DocumentTabSkeleton.module.scss";
import Skeleton from "@material-ui/lab/Skeleton";

/**
 * Template Document tabs skeleton
 */

export interface DocumentTabSkeletonProps {
  isLoading?: boolean;
}

const DocumentTabSkeleton: FC<DocumentTabSkeletonProps> = ({
  isLoading = false,
  ...props
}: DocumentTabSkeletonProps) => {

  return (
    <>
      {isLoading && (
        <div className={`${classesModule.skeletonDocumentsContainer}`}>
          <div className={classes.containerDocumentTab}>
            <div className={classes.containerSearch}>
              <Skeleton variant="rect" height={"2.4615rem"} width={"16.2308rem"} />
            </div>
            <div className={classesModule.containerTable}>
              <table className={`${classesModule.table}`}>
                <thead>
                  <tr>
                    <th><Skeleton variant="text" height={"0.9231rem"} width={"5.6rem"} /></th>
                    <th className={classesModule.t3}><Skeleton variant="text" height={"0.9231rem"} width={"5.6rem"} /></th>
                    <th className={classesModule.t4}><Skeleton variant="text" height={"0.9231rem"} width={"5.6rem"} /></th>
                    <th className={classesModule.t3}><Skeleton variant="text" height={"0.9231rem"} width={"5.6rem"} /></th>
                    <th className={classesModule.t2}><Skeleton variant="text" height={"0.9231rem"} width={"3.6rem"} /></th>
                    <th className={classesModule.t1}><Skeleton variant="text" height={"0.9231rem"} width={"4rem"} /></th>
                  </tr>
                </thead>
                <tbody>
                  {Array(3).fill(1).map((el, i) =>
                    <tr key={i}>
                      <td>
                        <div className={classesModule.skeletonIcon}>
                          <Skeleton variant="rect" height={"1.6154rem"} width={"1.6154rem"} />
                          <Skeleton variant="text" height={"0.9231rem"} width={"13rem"} />
                        </div>
                      </td>
                      <td><Skeleton variant="text" height={"0.9231rem"} width={"9rem"} /></td>
                      <td><Skeleton variant="text" height={"0.9231rem"} width={"7rem"} /></td>
                      <td><Skeleton variant="text" height={"0.9231rem"} width={"7rem"} /></td>
                      <td>
                        <div className={classesModule.skelCenterAvatar}>
                          <Skeleton variant="rect" height={"2.4615rem"} width={"2.4615rem"} />
                        </div>
                      </td>
                      <td><Skeleton variant="text" height={"0.9231rem"} width={"5rem"} /></td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DocumentTabSkeleton;

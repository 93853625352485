import { Paper, Skeleton, Tabs } from "@mui/material";
import Parser from "html-react-parser";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import Line from '../../../../../components/generic/Line/Line';
import Scrollbar from '../../../../../components/generic/Scrollbar/Scrollbar';
import DocumentTypeCard from '../../../../../components/modules/clara/startups/Document/Components/DocumentTypeCard';
import DocumentTypeCardSkeletor from '../../../../../components/modules/clara/startups/Document/Components/DocumentTypeCardSkeletor';
import Subscriptions from "../../../../../modules/checkout/modals/Subscriptions";
import BusinessRuleAccess from "../../../../../modules/clara/components/BusinessRuleAccess/BusinessRuleAccess";
import useBusinessRules from '../../../../../modules/clara/hooks/useBusinessRules';
import Icon from '../../../../../modules/generic/components/Icon';
import TabsMenu from "../../../../../modules/generic/components/Tab";
import TabMenu from "../../../../../modules/generic/components/Tab/TabMenu";
import Typography from '../../../../../modules/generic/components/Texts/Typography';
import RoleAccess from "../../../../../modules/security/components/RoleAccess/RoleAccess";
import useSession from '../../../../../modules/session/hooks/useSession';
import { Constants } from '../../../../../v1/utils';
import BetaButton from "./../../../../../components/generic/Buttons/BetaButton";
import PrimaryButton from "./../../../../../components/generic/Buttons/PrimaryButton/PrimaryButton";

import SecondaryButton from "src/components/generic/Buttons/SecondaryButton/SecondaryButton";
import EmptyState from "src/modules/generic/components/EmptyState";
import event from 'src/v1/utils/event';
import SubscriptionGatesGenerateDocument from '../../../../../images/SubscriptionGatesGenerateDocument.svg';
import { goToPage } from "../../../../../store/actions/page";
import useGetCustomTemplatesByStartupId from '../hooks/graphql/useGetCustomTemplatesByStartupId';
import useGetDocumentTypes from '../hooks/graphql/useGetDocumentTypes';
import useGetDocuments from '../hooks/graphql/useGetDocuments';
import useContent from '../hooks/useContent';
import useGetDocumentTypesLogic from '../hooks/useDocumentTypesLogic';
import useFilters from '../hooks/useFilter';
import useRegion from '../hooks/useRegion';
import Text from "./../../../../../components/text/Text/Text";
import useGetDocumentType from "./../../../../../hooks/services/modules/startups/documents/useGetDocumentTypes";
import helper from "./../../../../../v1/utils/helper";
import classes from './ChooseDocumentsView.module.scss';
import { LoadingSkeleton } from './components';
import DeleteCustomTemplate from './components/DeleteCustomTemplateButton';
import DropdownRegionFilter from './components/DropdownRegionFilter';
import ModalCreateDocument from './components/ModalCreateDocument';
import SearchInput from "./components/SearchInput";

const ChooseDocumentsView = () => {
  const dispatch = useDispatch();
  const { hasSubscriptionAccessGenerateDocuments, isStartSubscription, isInactivePlan, isNotViewer, isOwner } = useBusinessRules();
  const { previewDocument = '' } = useParams();
  const [indexTab, setIndexTab] = useState(0);
  const [open, setOpen] = useState(false);
  const [parentDoc, setParentDoc] = useState()
  const { getFamilyDocumentType,isOnlyForNewDocumentGeneration } = useGetDocumentTypesLogic()
  let arrSkeleton = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [docTypeClicked, setDocTypeClicked] = useState({ code: null, key: null, label: null, premium: null, entryId: "" });
  const { content,clear:clearContent, loading:loadingContentful,getRegionContent } = useContent(docTypeClicked);
  const {
        clearFilters,
        filterText,
        changeRegionFilter,
        handleChangeTextFilter
  } = useFilters()
  const { region, getRegion, loadingRegions } = useRegion()
  
  const { startup } = useSession();

  const clear = ()=>{
    clearFilters(); 
    clearContent();
    setDocTypeClicked({ code: null, key: null, entryId: "", label: null, premium: null, categoryName: null });
  }
  const handleRegionFilter = async (selectedValue) => {
    if (!previewDocument) setDocTypeClicked({ code: null, key: null, entryId: "", label: null, premium: null, categoryName: null });
    changeRegionFilter(selectedValue)
    clearContent();
  }

  const onCompletedDocumentTypes = ({ getDocumentGroupTypes }) => {
    if (!previewDocument) return;
    for (let documentGroup of getDocumentGroupTypes) {
      let findDocument = _.find(
        _.compact(documentGroup.documentTypes),
        (docType) => docType.code === previewDocument
      );

      if (findDocument) {
        const docTypeClicked = {
          ..._.omit(findDocument, "contentConfig"),
          categoryName: documentGroup.name,
          template: findDocument?.card?.template,
        };
        setDocTypeClicked(docTypeClicked);
        break;
      }
    }
  };

  const { data: docTypes, loading: docTypesLoading } = useGetDocumentTypes({
    onCompleted: onCompletedDocumentTypes,
  });
  const { data: customTemplates } = useGetCustomTemplatesByStartupId({
    variables: { startupId: startup.id },
  });
  const { data: documents } = useGetDocumentType();

  const { data: groupCompanies, loading: loadingDocuments } = useGetDocuments({
    variables: { startupId: startup.id },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    //TODO: REFACTOR: THIS LOGIC SHOULD BE IN A HOOK
    event.on(Constants.EVENTS.DOCUMENT_SCROLL, (params) => {
      if (typeof docTypes !== "undefined") {
        for (let documentGroup of docTypes.getDocumentGroupTypes) {
          let findDocument = _.find(
            _.compact(documentGroup.documentTypes),
            (docType) => docType.code === params.type
          );
          const currentRegionFromState =
            startup.optionsSelected.optionsSelected?.regionFilter;
          if (findDocument) {
            setDocTypeClicked({ ..._.omit(findDocument, 'contentConfig'), categoryName: documentGroup.name });
            _.map(findDocument.region, region => {
              if (region === currentRegionFromState) { }
              else if (region !== undefined && region !== currentRegionFromState) {
                // TODO: TEST TO CHECK WHAT IS THIS 
                // setregionFilter(setRegion);
                // handleChangeRegionMutation(setRegion.code);
              }
            });
            break;
          }
        }
      }
    });

    return () => {
      event.remove(Constants.EVENTS.DOCUMENT_SCROLL, () => { });
    }
  }, [docTypes, region, !docTypesLoading]);


  const requiresUpgrade = (doc) => {
    if (startup.activeSubscriptionPlan) {
      return _.some(
        startup.activeSubscriptionPlan.features,
        (feature) =>
          (feature.code === "010" &&
            !feature.limit.allowed &&
            doc.code === "SHARE_INCENTIVE_PLAN") ||
          (feature.code === "011" &&
            !feature.limit.allowed &&
            doc.code === "GRANT_AGREEMENT") ||
          (feature.code === "015" &&
            !feature.limit.allowed &&
            doc.code === "FOUNDERS_AGREEMENT") ||
          (feature.code === "017" &&
            !feature.limit.allowed &&
            doc.code === "USA_EQUITY_INCENTIVE_PLAN") ||
          (feature.code === "017" &&
            !feature.limit.allowed &&
            doc.code === "USA_BOARD_APPROVING_OPTION_GRANT") ||
          (feature.code === "017" &&
            !feature.limit.allowed &&
            doc.code === "USA_OPTION_GRANT_NOTICE")
      );
    }
    return false;
  };

  const handleOnUpgrade = () => {
    goToPage(dispatch, Constants.PAGES.upgrade, {});
  };

  const handleOnclick = (docType) => {
    const family = getFamilyDocumentType(docType.code);

    const docTypeMissing = _.findLast(family, docFam => !groupCompanies.documentList.some(doc => doc.type.code === docFam && doc.kind === 'GENERATED'))

    if (docTypeMissing) {
      setOpen(true);
      setParentDoc(docTypeMissing);
      return;
    }
    let docTypeInfo = {
      docType: docType.code,
      templateId: docType.template?.id,
    };
    goToPage(dispatch, Constants.PAGES.generateDocument, docTypeInfo);
  };

  const handleCreateDoc = () => {
    goToPage(dispatch, Constants.PAGES.generateDocument, {
      docType: parentDoc,
    });
  };
  
  const handleOnClickBeta = (docType) => {
    const family = getFamilyDocumentType(docType.code);

    const docTypeMissing = _.findLast(
      family,
      (docFam) =>
        !groupCompanies.documentList.some(
          (doc) => doc.type.code === docFam && doc.kind === "GENERATED"
        )
    );
    if (docTypeMissing) {
      setOpen(true);
      setParentDoc(docTypeMissing);
      return;
    }
    let docTypeInfo = {
      docType: docType.code,
      templateId: docType.template?.id,
    };
    if (docType.isCustomTemplateId) {
      let docTypeInfo = {
        customTemplateId: docType.code,
        docType: docType.documentType
      };
      goToPage(
        dispatch,
        Constants.PAGES.generateDocumentCustomTemplateMF,
        docTypeInfo
      );
      return;
    }
    if (docType?.template) {
      goToPage(dispatch, Constants.PAGES.generateDocumentMF, docTypeInfo);
    } else {
      goToPage(dispatch, Constants.PAGES.generateDocument, docTypeInfo);
    }
  };

  const handleUseDocument = (docType) => {
    handleOnclick(docType);
  };
  const handleUseCustomTemplateDocument = (docType) => {
    handleOnClickBeta(docType);
  };

  const handleChangeTab = (i) => {
    if (indexTab !== i) {
      setIndexTab(i);
      scrollToCategory(i);
    }
  };

  const docTypesFiltered = useMemo(() => {
    if (_.get(docTypes, "getDocumentGroupTypes", []).length > 0) {
      const aux = { ...docTypes };
      const df = [];
      let documentGroupTypes = _.get(aux, "getDocumentGroupTypes", []);
      _.forEach(documentGroupTypes, (doc) => {
        const documentTypes = _.filter(doc.documentTypes, (docType) => {
          const filtersApplied = region && _.find(docType?.region ?? [], r => r?.code === region);
          let matchText = true;
          if (filterText && filterText.length > 0) {
            const filterTextLower = filterText.toLowerCase();
            const labelLower = docType?.label?.toLowerCase();
            matchText = labelLower?.indexOf(filterTextLower) > -1;
          }
          return filtersApplied && matchText;
        });
        if (documentTypes.length > 0) {
          df.push({ ...doc, documentTypes });
        }
      });

      if (!_.isEmpty(customTemplates)) {
        let templates = _.filter(customTemplates, (template) => {
          const filtersApplied = region &&
            _.find(template?.documentType?.region ?? [], r => {
              return r?.code === region
            });

          let matchText = true;
          if (filterText && filterText.length > 0) {
            const filterTextLower = filterText.toLowerCase();
            const labelLower = template?.name?.toLowerCase();
            matchText = labelLower?.indexOf(filterTextLower) > -1;
          }
          return filtersApplied && matchText;
        });

        if (templates.length > 0) {
          const documentTypes = templates.map((template) => {
            return {
              ...template,
              entryIf: template?.documentType?.entryId,
              entryId: template?.documentType?.entryId,
              code: template?.id,
              documentType:template?.documentType?.code,
              allowedActions: template?.allowedActions,
              isCustomTemplateId: template?.id,
              label: template.name,
              contentConfig: [
                [
                  {
                    key: "cardIntroductionText",
                    value: `${template?.name} - (${template?.documentType?.name})`,
                  },
                  {
                    key: "cardEnabledButton",
                    value: true,
                  },
                  {
                    key: "enabledDocument",
                    value: true,
                  },
                ],
              ],
            };
          });
          const documentGroups = _.get(docTypes, "getDocumentGroupTypes", []);
          const documentGroup = documentGroups.find(
            (dt) => dt.code === "CUSTOM_TEMPLATES"
          );
          df.push({ ...documentGroup, documentTypes });
        }
      }

      return df;
    }
    return [];
  }, [customTemplates, docTypes, filterText, region]);

  useEffect(() => {
    let indexSelectedDocType;
    if (_.some(docTypesFiltered, { name: docTypeClicked.categoryName })) {
      indexSelectedDocType = _.findIndex(
        docTypesFiltered,
        (type) => type.name === docTypeClicked.categoryName
      );
      handleChangeTab(indexSelectedDocType);
    } else {
      handleChangeTab(0);
    }
  }, [docTypeClicked, docTypesFiltered]);

  const getTabs = () => {
    return _.map(docTypesFiltered, (tab, i) => {
      const { disabled = false, show = true } = tab;
      if (
        show &&
        tab.documentTypes.length >= 1 &&
        helper.getValueFromKey(tab.contentConfig, "groupVisible", false)
      ) {
        return (
          <TabMenu
            key={i}
            disabled={disabled}
            classes={{ root: indexTab === i ? classes.MuiTabRoot : null }}
            onClick={() => {
              handleChangeTab(i);
            }}
          >
            <label style={{ cursor: "pointer" }}>{tab.name}</label>
          </TabMenu>
        );
      }
      return <React.Fragment key={i} />;
    });
  };

  const checkHandler = (code, key, entryId, label, premium, categoryName, card,documentType) => {
    if (docTypeClicked.code === code && docTypeClicked.key === key && docTypeClicked.categoryName === categoryName) {
      setDocTypeClicked({ code: null, key: null, entryId: "", label: null, premium: null, categoryName: null, template: null });
      clearContent();
      return;
    }
    setDocTypeClicked({
      code,
      key,
      entryId,
      label,
      premium,
      categoryName,
      template: card?.template,
      isCustomTemplateId: card?.isCustomTemplateId,
      allowedActions: card?.allowedActions,
      documentType
    });
  };

  const scrollToCategory = (key) => {
    if (key >= 0) {
      const elem = document.getElementById(key);
      elem?.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div className={classes.ChooseDocumentsView}>
        {isOwner() && (
          <BusinessRuleAccess
            conditions={[
              Constants.BUSINESS_RULES.HAS_SUBSCRIPTION_ACCESS_SHARE_PROFILE,
            ]}
          >
            <Subscriptions
              subtitle={"SUSCRIPTION_MODAL_GENERATE_DOCUMENT_SUBTITLE"}
              title={"SUSCRIPTION_MODAL_GENERATE_DOCUMENT_TITLE"}
              content={"SUSCRIPTION_MODAL_GENERATE_DOCUMENT_CONTENT"}
              assistantText={"MODULES.ASSISTANCEV4.ASSISTANCE_MAP_HIT"}
              image={SubscriptionGatesGenerateDocument}
              button={"SUSCRIPTION_MODAL_CONTINUE_BUTTON"}
              gateField={"generateDocumentPopupHasBeenSeen"}
              popupName={"GenerateDocumentPopup"}
            />
          </BusinessRuleAccess>
        )}
        <>
          <LoadingSkeleton
            loading={docTypesLoading || loadingDocuments || loadingRegions}
            cant={1}
            skeletor={<Skeleton variant="text" style={{ width: "40%" }} />}
          >
            <div className={classes.title}>
              <Typography variant="h2" color="blue">
                Generate Documents
              </Typography>
            </div>
          </LoadingSkeleton>
          <Line className={classes.line} />
          <div className={classes.containerDocs}>
            <div className={classes.columnContainerDocs}>
              <div className={classes.tabsContainer}>
                <LoadingSkeleton
                  loading={docTypesLoading || loadingRegions}
                  cant={1}
                  skeletor={
                    <>
                      <Paper square>
                        <Tabs
                          value={null}
                          indicatorColor="primary"
                          textColor="primary"
                        >
                          {[0, 1, 2, 3].map((tab, i) => {
                            return (
                              <div className={classes.tabSkeletonContainer}>
                                <Skeleton variant="text" />
                              </div>
                            );
                          })}
                        </Tabs>
                      </Paper>
                    </>
                  }
                >
                  <div className={classes.tabsMenu}>
                    <TabsMenu initValue={indexTab} tabs={getTabs()} />
                    <DropdownRegionFilter onChange={handleRegionFilter} filterSelected={region} />
                  </div>
                  <SearchInput value={filterText} onChange={handleChangeTextFilter} />
                </LoadingSkeleton>
              </div>
              <div className={classes.cardsScrollableContainer}>
                <Scrollbar className={classes.scrollbarSeparator}>
                  <LoadingSkeleton
                    loading={docTypesLoading || loadingRegions}
                    cant={8}
                    skeletor={
                      <>
                        <div
                          className={`${classes.categoryTabHeader} ${
                            !docTypesLoading && classes.hiddenContainer
                          }`}
                        >
                          <Skeleton variant="text" />
                        </div>
                        <div>
                          <div
                            className={`${classes.cardListContainer} ${
                              !docTypesLoading && classes.hiddenContainer
                            }`}
                          >
                            {arrSkeleton.map((card, key) => {
                              return <DocumentTypeCardSkeletor />;
                            })}
                          </div>
                        </div>
                      </>
                    }
                  >
                    {docTypesFiltered.length === 0 && (
                      <>
                        <EmptyState
                          title={"No result found"}
                          iconType={"Generic"}
                        />
                      </>
                    )}
                    {docTypesFiltered.map((tabData, key) => {
                      if (
                        tabData.documentTypes.length >= 1 &&
                        helper.getValueFromKey(
                          tabData.contentConfig,
                          "groupVisible",
                          false
                        )
                      ) {
                        return (
                          <>
                            <div
                              className={classes.categoryTabHeader}
                              id={`${key}`}
                            >
                              <Icon
                                icon={helper
                                  .getValueFromKey(
                                    tabData.contentConfig,
                                    "groupIcon",
                                    false
                                  )
                                  .replace("clara-", "")}
                                size={"36px"}
                                isClara={true}
                              />
                              <span className={classes.categoryTabHeaderText}>
                                {tabData.name}
                              </span>
                            </div>
                            <div>
                              <div className={classes.cardListContainer}>
                                {_.orderBy(
                                  _.get(tabData, "documentTypes", []),
                                  ["premium"],
                                  ["desc"]
                                ).map((card, key) => {
                                  if (_.get(card, "code", null)) {
                                    return (
                                      <DocumentTypeCard
                                        tabDataName={tabData.name}
                                        card={card}
                                        key={key}
                                        checkHandler={checkHandler}
                                        docTypeClicked={docTypeClicked}
                                      />
                                    );
                                  }
                                })}
                              </div>
                            </div>
                          </>
                        );
                      }
                    })}
                  </LoadingSkeleton>
                </Scrollbar>
              </div>
            </div>
            <div
              className={`${classes.columnContainerDocs} ${classes.documentGroupInformation}`}
            >
              <div
                className={`${classes.descriptionContainer} ${
                  docTypeClicked.code
                    ? classes.descriptionContainerSelected
                    : null
                }`}
              >
                <Scrollbar>
                  <div className={classes.spacer}>
                    <LoadingSkeleton
                      loading={loadingContentful}
                      cant={1}
                      key={_.get(content, "text", "")}
                      id={_.get(content, "text", "")}
                      skeletor={
                        <div>
                          <div className={`${classes.tabDescriptionTitle}`}>
                            <Skeleton variant="rect" />
                          </div>
                          <div className={classes.tabDescriptionText}>
                            {arrSkeleton.map((i) =>
                              i % 4 === 0 ? (
                                <>
                                  <Skeleton variant="rect" />
                                  <br />
                                </>
                              ) : (
                                <Skeleton variant="rect" />
                              )
                            )}
                          </div>
                        </div>
                      }
                    >
                      <>
                        {_.get(content, "text", null) &&
                          Parser(_.get(content, "text"), {})}
                      </>
                    </LoadingSkeleton>
                    <LoadingSkeleton
                      loading={docTypesLoading}
                      cant={1}
                      skeletor={
                        <div
                          className={`${
                            !docTypesLoading && classes.hiddenContainer
                          }`}
                        >
                          <div className={`${classes.tabDescriptionTitle}`}>
                            <Skeleton variant="rect" />
                          </div>
                          <div className={classes.tabDescriptionText}>
                            {arrSkeleton.map((i) =>
                              i % 4 === 0 ? (
                                <>
                                  <Skeleton variant="rect" />
                                  <br />
                                </>
                              ) : (
                                <Skeleton variant="rect" />
                              )
                            )}
                          </div>
                        </div>
                      }
                    >
                      {!docTypeClicked.code && (
                        <>
                          {getRegionContent(getRegion(region))}
                        </>
                      )}
                      {!docTypeClicked.code &&
                        docTypesFiltered.map((tabData) => {
                          return (
                            <div key={JSON.stringify(tabData)}>
                              <div className={classes.tabDescriptionTitle}>
                                {tabData.name}
                              </div>
                              <div className={classes.tabDescriptionText}>
                                {helper.getValueFromKey(
                                  tabData.contentConfig,
                                  "groupIntroductionText",
                                  false
                                )}
                              </div>
                            </div>
                          );
                        })}
                    </LoadingSkeleton>
                  </div>
                </Scrollbar>
              </div>

              {isNotViewer() && (
                <>
                  {docTypeClicked.code && (
                    <div className={classes.buttonsContainer}>
                      {(!isStartSubscription() && !isInactivePlan()) ||
                      (!requiresUpgrade(docTypeClicked) &&
                        hasSubscriptionAccessGenerateDocuments()) ? (
                        <>
                          {!isOnlyForNewDocumentGeneration(docTypeClicked?.code) && docTypeClicked?.template && (
                            <BetaButton
                              onClick={() => {
                                handleOnClickBeta({ ...docTypeClicked });
                              }}
                              type="button"
                              color={"warning"}
                            >
                              <Text
                                uuid={"DOCUMENT_VIEW_USE_DOCUMENT_BUTTON_BETA"}
                              />
                            </BetaButton>
                          )}
                        {/** THIS IS TEMPORARY UNTIL THE NEW DOCUMENT GENERATION REMOVES OLD DOCUMENT GENERATION   */}
                      {isOnlyForNewDocumentGeneration(docTypeClicked?.code) && docTypeClicked?.template &&  (
                        <PrimaryButton onClick={() => { handleOnClickBeta({ ...docTypeClicked }) }} type="button"><Text uuid={'DOCUMENT_VIEW_USE_DOCUMENT_BUTTON'} /></PrimaryButton>)}

                          {!docTypeClicked?.isCustomTemplateId && !isOnlyForNewDocumentGeneration(docTypeClicked?.code) && (
                            <PrimaryButton
                              onClick={() => {
                                handleUseDocument({ ...docTypeClicked });
                              }}
                              type="button"
                            >
                              <Text
                                uuid={"DOCUMENT_VIEW_USE_DOCUMENT_BUTTON"}
                              />
                            </PrimaryButton>
                          )}
                          {docTypeClicked?.isCustomTemplateId && (
                            <>
                              <DeleteCustomTemplate
                                id={docTypeClicked?.isCustomTemplateId}
                                allowedActions={docTypeClicked?.allowedActions}
                                onSuccess={() => clear()}
                                onError={(e) => {}}
                              />
                              <PrimaryButton
                                onClick={() => {
                                  handleUseCustomTemplateDocument({
                                    ...docTypeClicked,
                                  });
                                }}
                                type="button"
                              >
                                <Text
                                  uuid={"DOCUMENT_VIEW_USE_DOCUMENT_BUTTON"}
                                />
                              </PrimaryButton>
                            </>
                          )}
                          {_.get(content, "file", "").length !== 0 && (
                            <SecondaryButton
                              onClick={() =>
                                window.open(
                                  _.get(content, "file", ""),
                                  "_blank"
                                )
                              }
                              type="button"
                            >
                              <Text uuid={"DOCUMENT_VIEW_ANNOTATED_BUTTON"} />
                            </SecondaryButton>
                          )}
                        </>
                      ) : (
                        <>
                          <RoleAccess
                            action={Constants.ACTIONS.MANAGE_SUBSCRIPTIONS}
                          >
                            <SecondaryButton
                              onClick={handleOnUpgrade}
                              className={classes.greenButton}
                              type="button"
                            >
                              <Text uuid={"BUTTON_UPGRADE"} />
                            </SecondaryButton>
                          </RoleAccess>
                        </>
                      )}
                    </div>
                  )}
                </>
              )}
</div>
          </div>
        </>
      </div>
      <ModalCreateDocument
        handleCreateDoc={handleCreateDoc}
        isOpen={open}
        setOpen={setOpen}
        docType={docTypeClicked.label}
        parentDoc={
          (documents?.getDocumentTypes || []).find((doc) => {
            return parentDoc === doc.code;
          })?.label
        }
      />
    </>
  );
};

export default ChooseDocumentsView;

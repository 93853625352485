import React from 'react'
import * as Yup from 'yup'
import Form from '../../../../components/forms/Form/Form'
import SubmitButton from '../../../generic/components/Buttons/SubmitButton'
import CheckboxInput from '../../../generic/components/Inputs/Checkbox'
import ControllerInput from '../../../generic/components/Inputs/ReactHookForm/ControllerInput'
import FormProps from '../../../generic/forms/interfaces/FormProps'
// import useTranslate from '../../../generic/hooks/useTranslate'
import TemplateLabel from '../../../generic/templates/Label'
import TemplateFormDefault from "../../../generic/templates/Modal"
import useSession from '../../../session/hooks/useSession'
import useGetGroupCompanies from '../../graphql/groupCompany/query/useGetGroupCompanies'
import cls from './Form.module.scss'
import { GroupCompanyBO } from "../../core/type/startup/groupCompany";

const schema = Yup.object().shape({
  company: Yup.array().min(1, 'This field is required').required('This field is required')
})

const FormSelectCompany: React.FC<FormProps> = ({
  initialValues: defaultValues = { company: [] },
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate
}) => {

  // const { translate } = useTranslate();
  const { startupId } = useSession();
  const { data } = useGetGroupCompanies({ variables: { startupId } })

  const handleSubmit = async values => {
    onCompleteSubmit && await onCompleteSubmit(values);
  }

  const optionsGroupCompanies = React.useMemo(() => {
    let grouopCompanies = [];
    data.forEach((gc: GroupCompanyBO) => {
      if (!gc.isLocked) {
        grouopCompanies.push({ value: gc, label: gc.name, disabled: false });
      }
    });
    return grouopCompanies;
  }, [JSON.stringify(data)])

  const functionCompare = React.useCallback((valueA, valueB) => {
    return valueA.id === valueB.id;
  }, [])

  return (
    <Form
      defaultValues={defaultValues}
      schema={schema}
      onSubmit={handleSubmit}
      onlySendDirty={false}
      optionsForm={{ mode: "onChange" }}
    >
      <Template buttons={{ ...buttons, submit: <SubmitButton  {...buttonSubmitProps}>Next</SubmitButton> }} props={propsTemplate}>
        <TemplateLabel
          label={'Choose all that apply'}
          isRequired
          className={cls.TemplateLabel}
          variant={"top"}
        >
          <ControllerInput
            render={CheckboxInput}
            options={optionsGroupCompanies}
            name='company'
            functionCompare={functionCompare}
            defaultlabel={''}
          />
        </TemplateLabel>
      </Template>
    </Form>
  )
}

export default FormSelectCompany

import React, { useEffect } from 'react'
import classes from './Styles.module.scss';
import Form from '../../../../../../components/forms/Form/Form';
import TextInput from "../../../../../../components/inputs/Text/TextInput"
import ControllerInput from "../../../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput"
import * as Yup from 'yup';
import useMutation from '../../../../../../hooks/custom/useMutation'
import SubmitButtonSubForm from '../../../../../../components/generic/Buttons/SubmitButtonSubForm/SubmitButtonSubForm';
import { RoleFields } from "../../../../../../modules/stakeholder/graphql/typePolicies";
import { gql } from "@apollo/client";
import { GET_STAKEHOLDERS } from "../../../../../../hooks/services/modules/stakeholders/useGetStakeholders";

const UPSERT_STAKEHOLDER = `
  ${RoleFields}
  mutation upsertComponentStakeholder(
    $startupId: ID!
    $stakeholder: ComponentStakeholderInputType!
  ) {
    upsertComponentStakeholder(startupId: $startupId, stakeholder: $stakeholder) {
      id
      fullName
      roles { ...RoleFields }
    }
  }
`

const FormAddStakeholders = ({ startupId, isAnEntity, handleCloseForm, handleCloseMessage, handleAddValueList, ...props }) => {

  const handleOnCompletedMutation = ({ upsertComponentStakeholder }) => {
    handleCloseForm()
    handleAddValueList(upsertComponentStakeholder)
  }

  const [upsertCompany] = useMutation(UPSERT_STAKEHOLDER, { onCompleted: handleOnCompletedMutation, showSuccessNotification: true }, { refetchQueries: [{ query: gql`${GET_STAKEHOLDERS}`, variables: { startupId: startupId } }] });


  const handleSubmit = async (values) => {
    await upsertCompany({
      variables: {
        startupId,
        stakeholder: {
          fullName: values.fullName,
          isAnEntity
        }
      }
    })
  }

  useEffect(() => {
    return () => {
      handleCloseMessage()
    }
  }, [])

  return (
    <Form defaultValues={{ data: 'asdasdasd' }}
      schema={Yup.object().shape({
        fullName: Yup.string().required('This field is required'),
      })} >
      <div className={classes.containerForm}>
        <div className={classes.conteninerText}>
          <ControllerInput
            as={TextInput}
            name={'fullName'}
            classNameError={classes.errorForm}
            placeholder={`Enter ${isAnEntity ? 'Corporate' : 'Individual'} name...`}
          />
          <SubmitButtonSubForm onClick={handleSubmit}  >
            Save
          </SubmitButtonSubForm>
        </div>
        {/*messageError && <span className={classes.error}>
          {messageError}
        </span>*/}
      </div>
    </Form>
  )
}

export default FormAddStakeholders

import * as Yup from "yup";

import { Constants } from '../../../../../v1/utils';

export const editProfileCorporate = Yup.object().shape({
	fullName: Yup.string().required("This field is required."),
	companyType: Yup.string().required("This field is required.").nullable(),
	nationality: Yup.string().required("This field is required.").nullable(),
	jurisdiction: Yup.string().required('This field is required.').typeError('This field is required.'),
	address: Yup.object().shape({
		country: Yup.string().required('This field is required.').typeError('This field is required.'),
		street: Yup.string().required('This field is required.').typeError('This field is required.'),
		city: Yup.string().required('This field is required.').typeError('This field is required.'),
		state: Yup.string(),
		zipCode: Yup.string().nullable()
	})
});

export const editAuthorisedSignatories = Yup.object().shape({
	authorizedSignatory: Yup.object().shape({
		fullName: Yup.string().nullable().required("This field is required."),
		email: Yup.string().lowercase()
			.matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
			.nullable()
			.optional()
	})
});

export const addressAndCurrency = Yup.object().shape({
	transactionCurrency: Yup.string().required("This field is required."),
});

export const editAddressCorporate = Yup.object().shape({
	address: Yup.object().shape({
		country: Yup.string().required('This field is required.').typeError('This field is required.'),
		street: Yup.string().required('This field is required.').typeError('This field is required.'),
		city: Yup.string().required('This field is required.').typeError('This field is required.'),
		state: Yup.string().nullable(),
		zipCode: Yup.string().nullable()
	})
})

export const editGeneralDetailsCorporate = {
	
	nationality: Yup.string().required("This field is required.").nullable(),
	jurisdiction: Yup.string().required('This field is required.').typeError('This field is required.'),
	
};
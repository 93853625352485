import useMutation from '../../../../../../hooks/custom/useMutation';

export const SUBMITTED_FORMATION = `
  mutation submitFormationCompany(
    $startupId: ID!, 
    $groupCompanyId: ID!, 
    ) {
      submitFormationCompany(
        startupId: $startupId
        groupCompanyId: $groupCompanyId
      ){
        id
      }
    }
  
  `;

const useSubmittedFormation = (config,props) => {

    const [mutation] = useMutation(
      SUBMITTED_FORMATION,
      { 
        showSuccessNotification:false,
        ...config 
      },
      {
        ...props,
      }
    );


    return [mutation ]
};

export default useSubmittedFormation;

import React, { FC, useState } from "react";
import _ from "lodash";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import AssistanceContents from "../../../AssistanceContents";
import TabBubblesContainer from "../../../TabBubblesContainer";
import Typography from "../../../../../generic/components/Texts/Typography";
import Bubble from "../../../Bubble";
import BubbleContents from "../../../BubbleContents";
import animationCongrats from "../../../../animations/congrats-01.json";
import MapTasksCard from "../../../../../../modules/assistanceV4/components/AssistanceCards/MapTasksCard";
import HealthCheckTooltips from "../HealthCheckTooltips";
import useBusinessRules from '../../../../../../modules/clara/hooks/useBusinessRules';
import moment from "moment";
import { useSelector } from 'react-redux';
import PaymentCard from "../../../../../../modules/assistanceV4/components/AssistanceAreas/Map2/Cards/PaymentCard";

export interface HealthCheckTasksProps {
  tasks: any;
}

const HealthCheckTasks: FC<HealthCheckTasksProps> = ({
  tasks: dataTasks,
  ...props
}: HealthCheckTasksProps) => {

  const { translate } = useTranslate();
  const [showCongrats, setShowCongrats] = useState(false);
  const session = useSelector(state => _.get(state, 'session'));
  const activeSubscriptionData = _.get(session ,"startup.activeSubscriptionPlan.activeSubscriptionData", {});
  const needPay = _.get(activeSubscriptionData, "mustPayInvoice", false);
  const { isOwner } = useBusinessRules();

  return (
    <AssistanceContents>
      <TabBubblesContainer>
        {(needPay && isOwner()) && (
          <PaymentCard
            date={moment(_.get(activeSubscriptionData, "downgradedScheduleDate", "")).format("DD MMM YYYY")}
          />
        )}
        {!showCongrats && (
          <HealthCheckTooltips />
        )}
        {showCongrats ? (
          <Bubble animation={animationCongrats}>
            <Typography variant={"s"} weight={"semibold"}>
              {translate("MODULES.ASSISTANCEV4.ASSISTANCE_HEALTH_CHECK_CONGRATS_HIT")}
            </Typography>
          </Bubble>
        ) : (
          <>
            <Bubble>
              <BubbleContents>
                <Typography variant={"s"} weight={"semibold"}>
                  {translate("MODULES.ASSISTANCEV4.ASSISTANCE_HEALTH_CHECK_HIT")}
                </Typography>
              </BubbleContents>
            </Bubble>
          </>
        )}
        {_.map(dataTasks, (group, index) => {
          const completed = _.filter(group.tasks, (task) => task.status === "completed");
          if (_.get(completed, "length", 0) === _.get(group, "tasks.length", 0)) {
            return null;
          }
          return (
            <MapTasksCard
              key={index}
              open={true}
              title={translate(
                "MODULES.ASSISTANCEV4.ASSISTANCE_MAP_TASK_NAME",
                {
                  name: group.group,
                  completed: _.get(completed, "length", 0),
                  totals: _.get(group, "tasks.length", 0),
                }
              )}
              titleIcon={group.icon}
              tasks={group.tasks}
            >
            </MapTasksCard>
          );
        })}
      </TabBubblesContainer>
    </AssistanceContents>
  );
};

export default HealthCheckTasks;

import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import * as jwt from "jsonwebtoken";
import classes from "./SessionManager.module.scss";

import { Constants } from "../../utils/constants";
import event from "../../utils/event";

import { goToPage } from "../../../store/actions/page";
import { setToken } from "../../../modules/session/store/action";
import _ from "lodash";
import ServerConnect from "../../utils/ServerConnect";
import KeyboardEventHandler from 'react-keyboard-event-handler';


class SessionManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAlive: false,

      showWaring: false,
      showLogout: false,
      refresh: false
    };
  }
  /* set that user is alive */
  setAlive = () => {
  
    const { isAlive, showWaring } = this.state;
    if (!showWaring && !isAlive) {
      this.setState({
        isAlive: true
      });
    }
  }
  /* Handle key press in the screen and call method to set isAlive in true */
  handleKeyPress = (key, e) => {
    this.setAlive();
  }
  /* Handle mouse move in the screen and call method to set isAlive in true */
  handleMouseMove = (e) => {
    this.setAlive();

  }
  /* Clear state, clear timer functions and go to the logout page  */
  logOut = () => {
    this.clearTimeout();
    this.setState({
      isAlive: false,
      showWaring: false,
      showLogout: false,
    },()=>{
      this.props.goToPage(Constants.PAGES.logout);
    })
    

  }
  /* Execute the logout function  */
  logIn = () => {
    this.logOut();
  }
  /* cleart timer  functions, and call to server to get a new token. If the function generate one error, then execute logout*/
  renewSession = async (e) => {
    this.clearTimeout();
    try {
      let newToken = await ServerConnect.refreshToken();
      this.props.setToken(newToken);
      this.setState({
        isAlive: false,
        showWaring: false,
        showLogout: false,
      })
    } catch (e) {
      this.executeLogout();
    }
  }
  /* clear timer functions, set data to hide warning modal and show logout modal */
  executeLogout = () => {
    this.clearTimeout();
    this.setState({
      isAlive: false,
      showWaring: false,
      showLogout: true,
    });
  }
  /* Clear timers */
  clearTimeout = () => {
    if (this.functionTimeout) {
      clearTimeout(this.functionTimeout);
      this.functionTimeout = null;
    }
    if (this.interval) {
      clearInterval(this.insterval);
    }
  }
  executeWarningOrRefresh = async () => {
    this.clearTimeout();
    const { isAlive } = this.state;
    if (isAlive) {
      this.renewSession();
    } else {
      const token = jwt.decode(_.get(this, "props.session.token"))
      const expiration = moment.unix(token.exp);
      const expirationInSeconds = moment(expiration).diff(moment(), "seconds");
      this.setState({
        isAlive: false,
        showWaring: true,
        showLogout: false,
      });
      this.interval = setInterval(() => {
        this.setState({
          refresh: !this.state.refresh
        })
      }, 1000);
      this.functionTimeout = setTimeout(this.executeLogout, expirationInSeconds * 1000);
    }
  }

  evaluateToken = (token) => {
    
    const expiration = moment.unix(token.exp);

    const timeToShowWaringInSeconds = moment(expiration).diff(moment(), "seconds") - _.get(window, "env.config.timeToShowWarningModalInSeconds", 120);
    if (timeToShowWaringInSeconds > 0) {

      this.functionTimeout = setTimeout(this.executeWarningOrRefresh, timeToShowWaringInSeconds * 1000);
    } else {
      this.executeWarningOrRefresh();
    }
  }
  componentWillUnmount() {
    this.clearTimeout();
    event.remove(Constants.EVENTS.ERROR_401, () => {
    });
  }
  proccessNewToken = (newToken) => {
    
    const expiration = moment.unix(newToken.exp);
    const expirationInSeconds = moment(expiration).diff(moment(), "seconds");
    if (expirationInSeconds > 0) {
      this.evaluateToken(newToken);
    } else {
      this.logOut();
    }
  }
  componentWillMount() {
    const newToken = jwt.decode(_.get(this, "props.session.token"))
    // const newToken = _.get(this, "props.session.decodedToken");
    if (newToken) {
      this.proccessNewToken(newToken);
    }
    event.on(Constants.EVENTS.ERROR_401, (params) => {
      this.setState({
        isAlive: false,
        showWaring: false,
        showLogout: false,
      });
      this.props.goToPage(Constants.PAGES.logout);
   });
    
  }
  componentWillUpdate(nextProps) {
    const newToken = _.get(nextProps, "session.token");
    const currentToken = _.get(this, "props.session.token");
    if (newToken && currentToken !== newToken) {
      const decodedToken = jwt.decode(_.get(nextProps, "session.token"))
      this.proccessNewToken(decodedToken);
    }

  }
  render() {
    const token = jwt.decode(_.get(this, "props.session.token"))
    const { showLogout, showWaring } = this.state;
    let expiration = 0;
    let expirationInSeconds = 0;
    if (token) {
      expiration = moment.unix(token.exp);
      expirationInSeconds = moment(expiration).diff(moment(), "seconds");
    }

    return (
      <React.Fragment>

        <div className={classes.SessionManagerContentPage} onMouseMove={this.handleMouseMove} >
          {this.props.children}
          {token && (
            <KeyboardEventHandler handleKeys={['all']} onKeyEvent={this.handleKeyPress} >
              {showWaring ? (
                <React.Fragment>
                  <div className={classes.SessionManager}>
                    <div className={classes.SessionManagerModal}>
                      <h1>Are you there?</h1>
                      <p>
                        Your session will expire in <span> {expirationInSeconds} Seconds</span>
                      </p>
                      <div className={classes.Buttons}>
                        <button className={classes.LogOutButton} onClick={this.logOut}>
                          Log Out
                        </button>
                        <button onClick={this.renewSession}>Stay Logged in</button>
                      </div>
                    </div>
                  </div>
                </React.Fragment>)
                : null}
              {(showLogout || _.get(this.props, "session.logout", false)) ? (
                <React.Fragment>
                  <div className={classes.SessionManager}>
                    <div className={classes.SessionManagerModal}>
                      <h1>We've logged you out to keep your info safe</h1>
                      <p>Log back in to pick up where you left off.</p>
                      <div className={classes.Buttons}>
                        <button onClick={this.logIn}>Log in</button>
                      </div>
                    </div>
                  </div>
                </React.Fragment>)
                : null}
            </KeyboardEventHandler>
          )}
        </div>

      </React.Fragment>
    )

  }
}

const mapStateToProps = state => {
  return {
    session: state.session
  };
};
const mapDispatchToProps = dispatch => ({
 setToken: (token)=>{
   setToken(dispatch,token)
 },
  goToPage: (page, params) => {
    goToPage(dispatch, page, params);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SessionManager);

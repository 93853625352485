import React, { Component } from "react";
import Avatar from "../../../../modules/generic/components/Avatars/AvatarLabel";
import { TooltipInput } from "../../../components";
import ImageUploader from 'react-images-upload';
import classes from './PhotoInput.module.scss';
import "./ImageUploader.css";

class PhotoInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pictures: [],
      url: '',
      edit: false,
      isFocused: false
    };
    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(pictureFiles, pictureDataURLs) {
    const { form } = this.props;
    this.setState({
      pictures: this.state.pictures.concat(pictureFiles),
      url: pictureDataURLs,
      edit: false
    });
    form.setValues({
      ...form.values,
      avatar: pictureDataURLs
    });
  }

  deletePhoto = () => {
    const { form } = this.props;
    this.setState({
      edit: true,
      pictures: [],
      url: ''
    });
    form.setValues({
      ...form.values,
      avatar: ''
    });
  };

  setTooltipVisibility = (isFocused) => {
    this.setState({
      isFocused,
    })
  };

  render() {
    const { tooltip } = this.props;
    return (
      <div className={classes.PhotoInput}>
        <label>{this.props.label || ""}</label>
        {!this.state.edit && this.props.field.value ?
          <div
            onClick={this.deletePhoto}
            className={classes.DivInput}
            onMouseOver={this.changeTooltipVisibility}
            onMouseOut={this.changeTooltipVisibility}
            onFocus={() => this.setTooltipVisibility(true)}
            onBlur={() => this.setTooltipVisibility(false)}
          >
            <Avatar avatar={this.props.field.value} name={this.props.field.value} showLabel={false} />
          </div>
          : <div
            className={classes.DivInput}
            onFocus={() => this.setTooltipVisibility(true)}
            onBlur={() => this.setTooltipVisibility(false)}
          >
            <TooltipInput label={tooltip} classes={{ root: classes.ToolTipPhoto }}>
              <ImageUploader
                withIcon={false}
                buttonText={'\uff0B'}
                onChange={this.onDrop}
                imgExtension={['.jpg', '.png', '.gif']}
                maxFileSize={5242880}
                style={{ margin: '0px' }}
              />
            </TooltipInput>
          </div>
        }
      </div>
    );
  }
}

export default PhotoInput;

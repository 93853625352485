import { useMemo } from 'react';
import useUploadBulkDocuments from 'src/modules/startup/Stakeholder/graphql/mutation/useUploadBulkDocuments';
import _ from 'lodash';
import useSession from "src/modules/session/hooks/useSession";
import moment from 'moment';
import { useParams } from "react-router-dom";

const useServices = ({ initialValues }) => {
  const { mutation: uploadDocuments } = useUploadBulkDocuments();
  const { startupId } = useSession();
  const params = useParams();

  return useMemo(() => ({
    initialData: async (context, event) => {
      return initialValues;
    },
    SaveStep: async (context, event) => {
      const file = _.get(context, "UploadStep.document", "");
      const documents = [];
      documents.push({
        title: file.name,
        file: file,
        type: "CERTIFICATE_OF_INCORPORATION",
        parties: [context?.initialData?.stakeholderId ?? params.id],
        status: "Uploaded",
        subCategories: ["constitutionalDocuments"],
        executedOnDate: moment(_.get(context, 'UploadStep.date')).format('YYYY-MM-DD'),
      })
      return await uploadDocuments({ variables: {startupId, documents: documents} })
    },

  }), [initialValues]);
}

export default useServices;

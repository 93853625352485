import _ from 'lodash'
import useQuery from "../../../custom/useQuery";

const GET_POSITIONS = `
query  getPositions($startupId: ID! ){
    getPositions(startupId:$startupId){
        id
        code
        name
        isDefault
        responsibilities {
          id
          text
          name
          code
          isDefault
        }
      }
    }
    `

const useGetIndividualStakeholderPositions = ({ variables, ...props }) => {
    const { loading, error, data, refetch } = useQuery(GET_POSITIONS, variables, null, props);

    return { loading, error, data: _.get(data, 'getPositions', []), refetch };
}
export default useGetIndividualStakeholderPositions;

import FitAndProper from '../steps/FitAndProper';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import { useMemo } from 'react';

const useSteps = () => {

  return{
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    FitAndProper: {
      render: FitAndProper
    },
    SaveStep: {
      render: TemplateWizardSkeletonMachine,
    },
  }
}

export default useSteps;

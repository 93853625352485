import React from "react";
import schema from "../../../../../../forms/schemas/modules/user/renameDevice";
import RowAdd from "../../../../../forms/contents/RowAdd/RowAdd";
import ControllerInput from "../../../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import SubmitButton from "../../../../../generic/Buttons/SubmitButton/SubmitButton";
import Form from "../../../../../forms/Form/FormL";
import TemplateFormDefault from "../../../../../../modules/generic/templates/Modal";
import TextInput from "../../../../../inputs/Text/TextInput";
import useTranslate from "../../../../../../modules/generic/hooks/useTranslate";

const RenameDeviceForm = ({Template = TemplateFormDefault, buttons = {}, buttonSubmitProps, propsTemplate, handleSubmit}) => {
    const { translate } = useTranslate();

    return(
        <Form schema={schema} onSubmit={handleSubmit}>
            <Template
                buttons={{ ...buttons, submit: <SubmitButton {...buttonSubmitProps}>Save</SubmitButton> }}
                props={propsTemplate}
            >
                <RowAdd
                    label={"MODAL_CHANGE_DEVICE_NAME_REASON_LABEL"}
                    asterisk={true}
                    input={
                        <ControllerInput
                            as={TextInput}
                            name="name"
                            defaultValue={""}
                            placeholder={translate("MODULES.CLARA.TYPE_HERE_HIT")}
                            onChange={(e) => {}}
                        />
                    }
                />
            </Template>
        </Form>
    );
}

export default RenameDeviceForm;
import React, { FC } from "react";
import { Stack, Box, Grid } from "@mui/material";
import Typography from "src/modules/generic/components/Texts/Typography";
import Money from "src/components/text/Text/Money";
import Number from "src/components/text/Text/Number";
import AvatarLabel from "src/modules/generic/components/Avatars/AvatarLabel";
import PincasIcon from "src/modules/generic/components/Icon/PincasIcon";
import classes from "./classes.module.scss";

type ItemShare = {
  name: string;
  avatar: string;
  issued: number;
  status: "paid" | "unpaid";
  inProgress?: boolean;
};

/**
 * TableCost
 */
export interface TableCostProps {
  items: ItemShare[];
  value: number;
  valueCurrency: string;
  title: string;
  amountPaid: number;
  amountPaidCurrency: string;
  amountUnpaid: number;
  amountUnpaidCurrency: string;
  total: number;
}

const TableCost: FC<TableCostProps> = ({
  title,
  items = [],
  value,
  valueCurrency,
  amountPaid,
  amountPaidCurrency,
  amountUnpaid,
  total,
}: TableCostProps) => {
  return (
    <>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={"1.3077rem"}
        sx={{
          width: "100%",
        }}
      >
        <Box>
          <Typography color="black" component="h4" weight="bold">
            {title}
          </Typography>
        </Box>
        <Stack direction={"row"} gap={"0.7692rem"}>
          <Typography variant="m" className={classes.newLabel}>
            Par value
          </Typography>
          <Typography variant="m" className={classes.claraLabel}>
            <Money value={value} prefix={valueCurrency} showDecimals={true} />
          </Typography>
        </Stack>
        <Grid
          container
          spacing={0}
          sx={{
            borderBottom: "1px solid #EAF1FE",
            paddingBottom: "14px",
          }}
        >
          <Grid item xs={6} mb={3}>
            <Typography variant="m" className={classes.newLabel}>
              Shareholders
            </Typography>
          </Grid>
          <Grid item xs={3} mb={3}className={classes.LabelOrder}>
            <Typography variant="m" className={classes.newLabel}>
              Issued shares
            </Typography>
          </Grid>
          <Grid item xs={3} mb={3} className={classes.LabelOrder}>
            <Typography variant="m" className={classes.newLabel}>
              Paid/unpaid
            </Typography>
          </Grid>
          {items.map((item, index) => {
            return (
              <>
                <Grid
                  item
                  xs={6}
                  sx={{
                    borderBottom: "1px solid #EAF1FE",
                    padding: "8px 0",
                  }}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                >
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                    sx={{
                      width: "100%",
                    }}
                  >
                    <div className={item.inProgress ? classes.filter : null}>
                      <AvatarLabel
                        name={item.name}
                        avatar={item.avatar}
                        showLabel={true}
                        className={undefined}
                        opacity={undefined}
                        label={undefined}
                        captable={undefined}
                        divStyle={undefined}
                      />
                    </div>
                    {item.inProgress && (
                      <>
                        <PincasIcon />
                      </>
                    )}
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{
                    borderBottom: "1px solid #EAF1FE",
                    padding: "8px 0",
                    alignItems: "center",
                    display: "flex",
                  }}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Typography variant="m" className={classes.label}>
                    <Number value={item.issued} params={{ decimalScale: 5 }} />
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{
                    borderBottom: "1px solid #EAF1FE",
                    padding: "8px 0",
                    alignItems: "center",
                    display: "flex",
                  }}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Typography variant="m" className={classes.label}>
                    {item.status}
                  </Typography>
                </Grid>
              </>
            );
          })}
          <Grid item xs={6} sx={{ padding: "1.2308rem 0 0.6154rem" }} mt={2}>
            <Typography variant="m" className={classes.claraLabelGrey}>
              Total
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ padding: "1.2308rem 0 0.6154rem", display: "flex", justifyContent: "flex-end" }} mt={2}>
            <Typography variant="m" className={classes.claraLabel}>
              <Number value={total} />
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ padding: "1.2308rem 0 0.6154rem" }}>
            <Typography variant="m" className={classes.claraLabelGrey}>
              Amount paid
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ padding: "1.2308rem 0 0.6154rem", display: "flex", justifyContent: "flex-end" }}>
            <Typography variant="m" className={classes.claraLabel}>
              <Money
                value={amountPaid}
                prefix={amountPaidCurrency}
                showDecimals={true}
                params={{ decimalScale: 2 }}
              />
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ padding: "1.2308rem 0 0.6154rem" }}>
            <Typography variant="m" className={classes.claraLabelGrey}>
              Amount unpaid
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ padding: "1.2308rem 0 0.6154rem", display: "flex", justifyContent: "flex-end" }}>
            <Typography variant="m" className={classes.claraLabel}>
              <Money
                value={amountUnpaid}
                prefix={amountPaidCurrency}
                showDecimals={true}
                params={{ decimalScale: 2 }}
              />
            </Typography>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default TableCost;

import React, {Component} from "react";
import classes from "./ClaraSearch.module.scss"

/*
    Properties:
        placeHolder: placeHolder to show
        value: defaultValue
        onChange: execute when close modal
        classes:{
            root: 
        }
        setInput: funciton to set input
*/
class ClaraSearch extends Component {
    state = {
        placeHolder: "Type text to search",
        value: null,
    };
    /* React Methods */

    componentWillMount() {
        const { placeHolder, value } = this.props;
        this.setState({
            placeHolder,
            value: value ? value : "",
        })
    }
    /* Managaer Events*/
    handleChange = (e) => {
        this.setState({
            value:e.target.value
        });
        if (this.props.onChange){
            this.props.onChange(e.target.value)
        }
    }
    setInput = (input)=>{
        if (this.props.setInput){
            this.props.setInput(input)
        }
    }
    render() {
        const { value } = this.state
        const propClasses= this.props.classes;
        let configClasses ={
            root: propClasses && propClasses.root? propClasses.root:classes.root,
            input: propClasses && propClasses.input? propClasses.input:classes.input
        }
        let {autofocus} = this.props;
        autofocus = autofocus!==false?true:false;
        return (
            <div className={`${classes.ClaraSearch} ${configClasses.root}`}>
                <i></i>
                
                <input
                    ref={(input) => { this.setInput(input) }} 
                    value={value}
                    type={"text"}
                    autoFocus={autofocus}
                    placeholder={this.state.placeHolder}
                    className={`${classes.search} ${configClasses.input}`}
                    onChange={(e) => this.handleChange(e)}
                    onClick={(e) => {e.stopPropagation(); e.preventDefault();}}

                />
            </div>
        );
    }
}

export default ClaraSearch

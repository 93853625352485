import { useMemo } from 'react';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import AddNomineeAuthorisedSignatoryStep from '../steps/AddNomineeAuthorisedSignatoryStep';
import WhoIsSecondAuthorisedSignatoryFormStep from '../steps/WhoIsSecondAuthorisedSignatoryFormStep';
import HowDidTheyEnterFormStep from '../steps/HowDidTheyEnterFormStep';
import ThirdPartyWithNomineeFormStep from '../steps/ThirdPartyWithNomineeFormStep';
import AddThirdPartyFormStep from '../steps/AddThirdPartyFormStep';
import WhoIsGoingToBeYourAuthorisedSignatoryFormStep from '../steps/WhoIsGoingToBeYourAuthorisedSignatoryFormStep';

const useSteps = () => {

  return useMemo(() => ({
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    AddNomineeAuthorisedSignatoryStep: {
      render: AddNomineeAuthorisedSignatoryStep
    },
    WhoIsSecondAuthorisedSignatoryFormStep: {
      render: WhoIsSecondAuthorisedSignatoryFormStep
    },
    HowDidTheyEnterFormStep: {
      render: HowDidTheyEnterFormStep
    },
    ThirdPartyWithNomineeFormStep: {
      render: ThirdPartyWithNomineeFormStep
    },
    AddThirdPartyFormStep: {
      render: AddThirdPartyFormStep
    },
    WhoIsGoingToBeYourAuthorisedSignatoryFormStep: {
      render: WhoIsGoingToBeYourAuthorisedSignatoryFormStep
    },
    SaveAddThirdPartyFormStep: {
      render: TemplateWizardSkeletonMachine,
    },
    SaveWhoIsGoingToBeYourAuthorisedSignatoryFormStep: {
      render: TemplateWizardSkeletonMachine,
    },
    SaveThirdPartyWithNomineeFormStep: {
      render: TemplateWizardSkeletonMachine,
    },
    SaveHowDidTheyEnterFormStep: {
      render: TemplateWizardSkeletonMachine,
    },
    
  }), []);
}

export default useSteps;

import { useMemo } from 'react';
import useGetGroupCompaniesForAccelerators from './useGetGroupCompaniesForAccelerators';
import useSession from '../../../../session/hooks/useSession'
import _ from 'lodash';
import useAddTopcoData from "./useSetAcceleratorTopco"

const useServices = () => {

  const { manualQuery: getCompaniesData  } = useGetGroupCompaniesForAccelerators({},{});
  const [addTopcoData] = useAddTopcoData({});
  const { startupId } = useSession();

  return useMemo(() => ({
    getCompaniesData: async (context, event) => {
      const allCompaniesData = await getCompaniesData({
        variables:{
          startupId
        }
      });
      return allCompaniesData;
    },
    uploadTopCo: async (context, event) => {
      const useThisCompanyId = _.get(context, "resultCompanyId", "");
      const variables = {
        startupId,
        eventType: "TOP_CO_SELECTED",
        groupCompanyId: useThisCompanyId
      };
      await addTopcoData({ variables });
    }
  }), []);
}

export default useServices;
import React from 'react';
import useLoading from "../../../../../modules/generic/hooks/useLoading";
import IndividualStakeholderView from "./IndividualStakeholderView"
import CorporateStakeholderViewSkeleton from "../corporateStakeholderView/CorporateStakeholderViewSkeleton";

const IndividualStakeholderPage = ({ ...props }) => {
  const { isLoading } = useLoading();

  return (
    <React.Fragment>
      <CorporateStakeholderViewSkeleton
        isLoading={isLoading}
      >
      </CorporateStakeholderViewSkeleton>
      <IndividualStakeholderView {...props} />
    </React.Fragment>
  );
}

export default IndividualStakeholderPage;
import { useMemo } from 'react';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import AddShareholder from '../steps/AddShareholder';
import AddIndividual from '../steps/AddIndividual';
import AddCorporate from '../steps/AddCorporate';
import ShareholderAdded from '../steps/ShareholderAdded';
import LegalEntityStructure from '../steps/LegalEntityStructure';

const useSteps = () => {

  return useMemo(() => ({
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    AddShareholder: {
      render: AddShareholder,
    },
    AddIndividual: {
      render: AddIndividual,
    },
    AddCorporate: {
      render: AddCorporate,
    },
    LegalEntityStructure: {
      render: LegalEntityStructure,
    },
    SaveStep: {
      render: TemplateWizardSkeletonMachine,
    },
    ShareholderAdded: {
      render: ShareholderAdded,
    },
  }), []);
}

export default useSteps;

import React, { FC, useRef } from "react";
import * as Yup from "yup";
import useTranslate from "../../../generic/hooks/useTranslate";
import Form from "../../../../components/forms/Form/FormL";
import ControllerInput from "../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import SubmitButton from "../../../generic/components/Buttons/SubmitButton";
import TemplateFormDefault from "../../../generic/templates/Modal";
import FormProps from "../../../generic/forms/interfaces/FormProps";
import { CheckboxButtonOptions } from "../../../generic/components/Inputs/CheckboxButton";
import CheckboxButtonSimple from "../../../generic/components/Inputs/CheckboxButtonSimple";
import classes from "./Form.module.scss";

const schema = Yup.object().shape({
  isThereMoreCompanies: Yup.boolean().nullable().required("Required"),
});

const IsIndividualOrCompany: FC<FormProps> = ({
  initialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
}: FormProps) => {

  const { translate } = useTranslate();
  const refForm = useRef();

  const options: Array<CheckboxButtonOptions<boolean, string>> = [
    {
      value: false,
      label: translate("MODULES.GROUP_COMPANY.FORM.IS_THERE_MORE_COMPANIES.NO"),
    },
    {
      value: true,
      label: translate(
        "MODULES.GROUP_COMPANY.FORM.IS_THERE_MORE_COMPANIES.YES"
      ),
    },
  ];

  return (
    <>
      <Form
        initialValues={initialValues || {}}
        schema={schema}
        onlySendDirty={false}
        onSubmit={onCompleteSubmit}
        ref={refForm}
        optionsForm={{ mode: "onChange" }}
      >
        {" "}
        <Template
          buttons={{
            ...buttons,
            submit: <SubmitButton {...buttonSubmitProps}>Next</SubmitButton>,
          }}
          props={propsTemplate}
        >
          <ControllerInput
            as={CheckboxButtonSimple}
            name="isThereMoreCompanies"
            options={options}
            className={classes.CheckboxButton}
            classNameButton={classes.buttonCheckBox}
          />
        </Template>
      </Form>
    </>
  );
};

export default IsIndividualOrCompany;

import React, {useEffect} from 'react';
import useQuery from "../../../../custom/useQuery"

const query = `{ 
  getDocumentTypes {
    code
    label
    premium
    fields {
      key
      label
      placeholder
      tooltip
      required
      type
      options {
        id
        label
      }
    }
    contentConfig
  }
}
`;

const useGetDocumentTypes = (variables) =>{
    
    const {loading, error, data} = useQuery(query,variables);
    useEffect(() => {
        
    }, []);

    return  {loading,error,data} ;
}
export default useGetDocumentTypes;
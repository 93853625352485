import { Box, Divider } from "@mui/material";
import EmptyState from "src/modules/generic/components/EmptyState";
import PEPPanel from "./PEPPanel";
import PropTypes from "prop-types"
import React from "react";
import SourceOfFundsPanel from "./SourceOfFundsPanel";
import _ from "lodash";
import classes from "./ComplianceTab.module.scss";
import useBusinessRules from 'src/modules/clara/hooks/useBusinessRules';
import useTranslate from "src/modules/generic/hooks/useTranslate";

function ComplianceTab({
  data,
  lock,
  jurisdictions
}) {

  const { translate } = useTranslate();
  const { isViewer } = useBusinessRules();

  return (
    <>
      {isViewer() ?
        <EmptyState
          title={translate("DETAILS_FORVIDEN_STATE_VIEWER")}
          iconType="Not-Visible"
        /> : (
          <Box sx={{ paddingRight: "0.4615rem" }}>
            {_.includes(jurisdictions, 'ADGM') && _.get(data, 'pep.isPEP', null) !== null &&
              <PEPPanel data={data} lock={lock} />
            }
            {data?.sourcesOfFunds?.length > 0 && (
              <>
                {_.get(data, 'pep.isPEP', null) !== null && <Divider className={classes.divider} />}
                <SourceOfFundsPanel data={_.get(data, 'sourcesOfFunds', [])} lock={lock} />
              </>
            )
            }
          </Box>
        )
      }
    </>
  );
}

ComplianceTab.propTypes = {
  data: PropTypes.any,
  lock: PropTypes.bool,
  jurisdictions: PropTypes.array
}

export default ComplianceTab;
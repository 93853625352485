import React, { useRef } from "react";
import Form from "../../../../../components/forms/Form/Form";
import ControllerInput from "../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import CheckboxButtonSimple, { CheckboxButtonOptions } from 'src/modules/generic/components/Inputs/CheckboxButtonSimple';
import classesModule from "src/modules/startup/core/forms/YesOrNoForm/classes.module.scss";
import SubmitButton from "../../../../generic/components/Buttons/SubmitButton";
import TemplateFormDefault from "../../../../generic/templates/Modal";
import useTranslate from "../../../../generic/hooks/useTranslate";
import * as Yup from "yup";
import classForm from "./classes.module.scss"
import _ from "lodash";
import TypographyLocal from "src/modules/generic/components/Texts/Typography";

const schema = Yup.object().shape({
  stakeholder: Yup.string().required("Required"),
});

const FormCorporatePosition = ({
  Template = TemplateFormDefault,
  buttons = {},
  onCompleteSubmit,
  buttonSubmitProps,
  propsTemplate,
  handleCancel,
  initialValues,
}) => {
  const refForm = useRef();
  const { translate } = useTranslate();

  const { optionsToShow } = React.useMemo(() => {
    // const defaultValue = _.size(initialValues) === 1 ? initialValues[0].id : "";
    let optionsToShow;
    if (_.size(initialValues) === 0) {
      optionsToShow = {};
    } else {
      optionsToShow = initialValues.map(stks => {
        return {
          value: stks.id,
          label: _.get(stks, "fullName", ""),
        }
      })
    }
    return { optionsToShow };
  }, [JSON.stringify(initialValues)]);

  return (
    <React.Fragment>
      <Form
        schema={schema}
        onlySendDirty={false}
        onSubmit={onCompleteSubmit}
        ref={refForm}
        optionsForm={{ mode: "onChange" }}
      >
        <Template
          buttons={{
            ...buttons,
            submit: (
              <SubmitButton {...buttonSubmitProps}>
                {translate(`MODULES.ONBOARDINGCOMPANY.BUTTON_NEXT`)}
              </SubmitButton>
            ),
          }}
          props={propsTemplate}
        >
          <ControllerInput
            render={CheckboxButtonSimple}
            defaultValue={_.size(initialValues) === 1 ? initialValues[0].id : ""}
            className={classesModule.CheckboxButton}
            classNameButton={classForm.buttonCheckBoxSize}
            name="stakeholder"
            variant="col_1"
            options={optionsToShow}
            isMultiple={false}
            onChange={(value) => { }}
          />
          {_.size(initialValues) === 0 && (
            <TypographyLocal
              variant={"h5"}
              weight={"light"}
            >
              There are no stakeholders to select yet
            </TypographyLocal>           
          )}
        </Template>
      </Form>
    </React.Fragment>
  );
};

export default FormCorporatePosition;

import useQuery from "src/hooks/custom/useQuery";

const GET_QUESTIONNAIRE = `
query getRenewalData($startupId: ID!, $groupCompanyId: ID!) {
  getRenewalData(startupId: $startupId, groupCompanyId: $groupCompanyId){
	techLicense {
    isEmployeeShareholder{
      description
      question
    }
    isThereEmployeeWithOtherVisas{
      description
      question
    }
    isThereFutureEmployees{
      description
      question
    }
    isCompanyOperational{
      description
      question
    }
    employeeAmountStart
    employeeAmountNow
    uaeVisasIssuedAmount
    companyRevenue
    futurePlans
  }
    companyIncome {
      currency
      dividend
      capitalGains
      relevant
      incidental
      total
      __typename
    }
      
    taxInformation {
      id
      isTaxResidentsAreFromCayman {
        description
        question
      }
      isEntityInvestmentFund {
        description
        question
      }
      isCompanyCarriedOnRelevantActivity {
        description
        question
      }
      doesHaveFINumber {
        description
        question
      }
      doesHaveIRSGIN {
        description
        question
      }
      isLCCLHolder {
        description
        question
      }
      isRegisteredInCIMA {
        description
        question
      }
      doesHaveTradeBusinessLicense {
        description
        question
      }
      __typename
    }
    immediateParents {
      id
      name
      type
      mailingAddress {
        useRegisteredAddress
        street
        city
        state
        country {
          code
          alpha3
          name
        }
        zipCode
        type
    	}
      taxJurisdiction {
        code
        alpha3
        name
      }
      taxRegistrationNumber
    }
    ultimateParent {
      id
      name
      type
      mailingAddress {
        useRegisteredAddress
        street
        city
        state
        country {
          code
          alpha3
          name
        }
        zipCode
        type
    	}
      taxJurisdiction {
        code
        alpha3
        name
      }
      taxRegistrationNumber
    }
    ultimateBeneficialOwners {
      id
      name
      type
      mailingAddress {
        useRegisteredAddress
        street
        city
        state
        country {
          code
          alpha3
          name
        }
        zipCode
        type
    	}
      taxJurisdiction {
        code
        alpha3
        name
      }
      taxRegistrationNumber
    }
  }
}`;

export const useGetQuestionnaire = (variables, config) => {
  const { loading, error, data, refetch } = useQuery(
    GET_QUESTIONNAIRE,
    variables,
    config
  );
  return { loading, error, data, refetch };
};

export default useGetQuestionnaire;

import React from 'react';
import classesModule from "./MissingView.module.scss"
import image from "../../../images/404.svg"
import Text from "../../../components/text/Text/Text"

const MissingView =({...props  })=> {

  return (
    <React.Fragment>
      <div className={`${classesModule.MissingView}`}>
            <img src={image} />
            <div className={classesModule.Title}><Text uuid ={"VIEW_MISSING_PAGE_TITLE"}/></div>
            <div className={classesModule.SubTitle}><Text uuid ={"VIEW_MISSING_PAGE_SUBTITLE"}/></div>
      </div>
    </React.Fragment>
  );
}

export default MissingView;
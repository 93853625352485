import React, {Component} from 'react';
import {Field} from "formik";
import _ from "lodash";
import moment from "moment";

import ClaraSelect from "../../../../components/Form/ClaraSelect/ClaraSelect";
import classes from "../../SteperForm.module.scss";
import { TextAreaInput, TextInput,TextType } from '../../../../components';
import Checkbox from "../../Inputs/Checkbox";
import FormikHelper from "../../../../utils/formikHelper";
import DinamicInput from "../../Inputs/DinamicInput";
import ClaraUpload from "../../../../components/Form/ClaraUpload/ClaraUpload";
import ClaraCountrySelector from "../../../../components/Form/ClaraCountrySelector/ClaraCountrySelector";
import AuthorisedSignatories from "../../../../../components/inputs/AuthorizedSignatories/AuthorizedSignatories";
import {groupCompanyRecipientNotice, stakeholderAuthorizedSignatory, stakeholderRecipientNotice} from './helper';

class AssignmentDoc extends Component {

    constructor(props) {
        super(props);
        this.state = {
            typeSelected: undefined,
            assignorSelected: undefined,
            groupCompanySelected: undefined,
            authorizedSelected: undefined,
            authorizedSignatories: [],
            authorizedSignatoriesList: [],
            documentTypes: [],
            documentTypesList: [],
            dynamicParams: [],
            date: undefined,
            expirationDate: undefined,

        }
    };

    componentWillMount() {
        if (this.props.values) {
            const values = this.props.values.values ? this.props.values.values : this.props.values;
            const expirationDate = this.getDateForPickerFromData(values.expirationDate);
            this.setState({
                typeSelected: values.documentType,
                assignorSelected: values.assignor,
                groupCompanySelected: values.assignee,
                expirationDate,
                authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.assignee).map(as => ({
                    id: as.id,
                    label: as.label
                }))
            });


        }

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.values) {
            const values = nextProps.values.values ? nextProps.values.values : nextProps.values;
            const expirationDate = this.getDateForPickerFromData(values.expirationDate);
            this.setState({
                expirationDate,
                authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.assignee)
                    .map(as => ({
                        id: as.id,
                        label: as.label
                    }))
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.values) {
            const values = prevProps.values.values ? prevProps.values.values : prevProps.values;
        }
    }

    selectType = (documentType, extraParams, form) => {
        this.setState({
            typeSelected: documentType,
            dynamicParams: this.getDynamicParamsByType(documentType)
        }, () => {
            FormikHelper.setValueInTheCorrectPosition('terms', form, []);
            FormikHelper.setValueInTheCorrectPosition('documentType', form, documentType);
            this.state.dynamicParams.forEach((dynamicParam, i) => {
                FormikHelper.setValueInTheCorrectPosition(
                    `terms[${i}].key`,
                    form,
                    dynamicParam.key || 'NO_KEY'
                );
            })
        });
    };

    getDynamicParamsByType(documentType) {
        const type = this.props.documentTypes.filter(agrType => agrType.code === documentType)[0] || {};
        return type.fields || [];
    }

    getGroupCompaniesToAssignee = () => {
        let authorizedSignatories = this.props.authorizedSignatories;
        let groupCompanies = this.props.groupCompanies;
        let companies = [];
        if (groupCompanies && authorizedSignatories) {
            companies = _.filter(groupCompanies, (groupCompany) => {
                let fined = _.filter(authorizedSignatories, (authorized) => {
                    if (groupCompany.authorizedSignatories) {
                        return groupCompany.authorizedSignatories.some(au => au.stakeholder.id === authorized.id);
                    }
                    return false;
                });
                if (fined) {
                    return true;
                }
                return false;
            });
        }
        return companies;
    };

    selectGroupCompany = (gcId, extraParams, form) => {

        const groupCompany = this.findGroupCompany(gcId);

        // this is for a weird behaviour when hit edit button after preview a document
        if (this.state.groupCompanySelected && groupCompany) {
            return null;
        }

        this.setState({
            groupCompanySelected: groupCompany
        });

        if (groupCompany) {

            if (!groupCompany.registeredAddress) {
                _.set(groupCompany, "registerAddress", {})
            }

            let authorizedSignatories = _.get(form, 'values.assignee.authorizedSignatories', []);

			form.setValues({
				...form.values,
				assignee: {
					id: gcId,
					name: groupCompany.name || '',
					country: _.get(groupCompany, 'country.code', null) || _.get(groupCompany, 'country', null),
					jurisdiction:groupCompany.otherJurisdiction || groupCompany.jurisdiction,
					registeredNumber: groupCompany.registeredNumber || '',
					type: groupCompany.type || '',
					registeredAddress: {
						...groupCompany.registeredAddress,
                        country: typeof _.get(groupCompany, 'registeredAddress.country', null) ==='object'
							?_.get(groupCompany, 'registeredAddress.country.code', null)
							: _.get(groupCompany, 'registeredAddress.country', null)
                    },
                    authorizedSignatories: authorizedSignatories,
					recipientNotice: groupCompanyRecipientNotice(form.values.assignee, groupCompany)
                }
            });
            this.setState({
                groupCompanySelected: {...groupCompany},
            })
        } else {
            form.setValues({
                ...form.values,
                assignee: {}
            });
        }
    };

    findGroupCompany(gcId) {
        const gc = _.find(this.props.groupCompaniesList,{id:gcId});
        return gc?{...gc}:null
    };

    selectAssignor = (assignor, extraParams, form, cb) => {
        const stakeholder = this.findStakeholder(assignor);
        this.setState({
            assignorSelected: stakeholder,
        });

        if (stakeholder) {
            let authorizedSignatory = {};

            if (!stakeholder.address) {
                _.set(stakeholder,'address', {})
            }

            const _assignor = !_.isEmpty(form.values.assignor) ? form.values.assignor : stakeholder;

            if (stakeholder.isAnEntity) {
				authorizedSignatory = stakeholderAuthorizedSignatory(_assignor);
                // authorizedSignatory = {
                //     fullName: typeof _assignor.authorizedSignatory === 'object'
                //         ? _.get(_assignor, 'authorizedSignatory.fullName', '')
                //         : _.get(_assignor, 'authorizedSignatory', ''),
                //     email: typeof _assignor.authorizedSignatory === 'object'
				// 		? _.get(_assignor, 'authorizedSignatory.email', _assignor.email)
				// 		: ''
                // }
            }

            form.setValues({
                ...form.values,
                assignor: {
                    id: assignor,
                    fullName: _assignor.fullName || '',
                    email: _assignor.email || '',
                    passportNumber: _assignor.passportNumber || '',
                    registeredNumber: _assignor.registeredNumber || '',
                    nationality: _.get(_assignor, 'nationality.code', null) || _.get(_assignor, 'nationality', null),
                    jurisdiction: _assignor.jurisdiction,
                    otherJurisdiction: _assignor.otherJurisdiction,
                    type: _assignor.type || '',
                    address: {
                        ..._assignor.address,
                        country:typeof  _.get(_assignor, 'address.country', null) ==='object'? _.get(_assignor, 'address.country.code', null) : _.get(_assignor, 'address.country', null)
                    },
                    isAnEntity: stakeholder.isAnEntity,
                    authorizedSignatory: authorizedSignatory,
					recipientNotice: stakeholderRecipientNotice(form.values.assignor, stakeholder)
                }
            });
        } else {
            form.setValues({
                ...form.values,
                assignor: {}
            });
        }

    };

    findStakeholder(roleId) {
        return this.props.stakeholderList.find(stakeholder => stakeholder.id === roleId)
    };

    findStakeholderAuthorized(roleId) {
        return this.props.stakeholderList.filter(stakeholder => {
            return stakeholder.roles.some(role => role.stakeholder.id === roleId);
        })[0] || null;
    };

    filterAuthorizedByGroupCompany(groupCompany) {
        if (groupCompany) {
            let gc = this.findGroupCompany(groupCompany.id);
            return this.props.authorizedSignatories.filter(authorized => {
                return authorized.groupCompanyId === gc.id
            });
        }
        return [];
    }

    selectAuthorized = (value, form, field, replace) => {
        // const stakeholder = this.findStakeholder(value);
        const stakeholder = this.props.authorizedSignatories.find(e => e.id === value);
        const email = stakeholder ? stakeholder.email : '';
        replace(0, { id: value, email })
    };

    handleDateChange = (e, field, form) => {
        const date = moment(e).format("YYYY-MM-DD");

        FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

        this.setState({
            date: e
        })
    };

    renderSpecialAgreementFields(form) {
        return this.state.dynamicParams.map((dynamicParam, i) => {
            return (
                <div className={classes.ContainerRow}>
                    <Field name={`terms[${i}].value`}>
                        {({ field, form }) => (
                            <DinamicInput
                                config={dynamicParam}
                                field={field}
                                form={form}
                                handleDateChange={this.handleDateChange}
                                date={this.state.date}
                            />
                        )}
                    </Field>
                </div>
            );
        });
    };

    renderRecipientNotice(fieldName, isAnEntity) {
        const { tooltips = {} } = this.props;

        if (isAnEntity) {
            return (
                <React.Fragment>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.fullName`}>
                            {({ field, form }) => (
                                <TextInput
                                    required={true}
                                    type='text'
                                    label='Recipient for notices '
                                    field={field}
                                    form={form}
                                    placeholder={"Recipient's name"}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient for Notices"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={`${classes.ContainerRow} ${classes.Select}`}>
                        <Field name={`${fieldName}.recipientNotice.address.country`}>
                            {({ field, form }) => (
                                <ClaraCountrySelector
                                    isRequired={{ country: true }}
                                    jurisdictionsEnabled={false}
                                    countryField={`${fieldName}.recipientNotice.address.country`}
                                    label={"Recipient's address "}
                                    setFieldValue={form.setFieldValue}
                                    initialValues={{ country: _.get(form.values, `${fieldName}.recipientNotice.address.country`, null) }}
                                    returnCountryAsString={_.isEmpty(_.get(form.values, `${fieldName}.recipientNotice.address.country.code`, null))}
                                    additionalStyle={'ClaraCountrySelectorWizard'}
                                    tooltips={{
                                        country: tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices - Country"]
                                    }}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.address.street`}>
                            {({ field, form }) => (
                                <TextAreaInput
                                    type='text'
                                    label={' '}
                                    field={field}
                                    form={form} placeholder={"Address"}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Address"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.address.city`}>
                            {({ field, form }) => (
                                <TextInput
                                    type='text'
                                    field={field}
                                    form={form}
                                    placeholder={"City"}
                                    label={' '}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - City"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.address.state`}>
                            {({ field, form }) => (
                                <TextInput
                                    type='text'
                                    field={field}
                                    form={form}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - State"]}
                                    placeholder={"State / Region"}
                                    label={' '}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.address.zipCode`}>
                            {({ field, form }) => (
                                <TextInput
                                    type='text'
                                    field={field} form={form}
                                    label={' '}
                                    placeholder={"Post code"}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Post Code"]}
                                />

                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.email`}>
                            {({ field, form }) => (
                                <TextInput
                                    required={true}
                                    type='email'
                                    label="Recipient's email address "
                                    field={field}
                                    form={form}
                                    placeholder={"Email address"}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient's Email Address"]}
                                />
                            )}
                        </Field>
                    </div>
                </React.Fragment>
            )
        }
    };

    renderAssignorAuthorizedSignatory(field) {
        const { tooltips = {} } = this.props;

        if (this.state.assignorSelected.isAnEntity) {
            return (
                <React.Fragment>
                    <div className={classes.ClaraInputSelect}>
                        <Field name='assignor.authorizedSignatory.fullName'>
                            {({ field, form }) => (
                                <TextInput
                                    required={true}
                                    type='text'
                                    label='Corporate signatory '
                                    field={field}
                                    form={form}
                                    placeholder={"Corporate signatory"}
                                    tooltip={tooltips["Authorised Signatory"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name='assignor.authorizedSignatory.email'>
                            {({ field, form }) => (
                                <TextInput
                                    required={true}
                                    type='email'
                                    label="Corporate signatory's email address "
                                    field={field}
                                    form={form}
                                    onChange={(e) => this.updateEmailValue(e, form)}
                                    placeholder={"Email address"}
                                    tooltip={tooltips['Email Address']}
                                />
                            )}
                        </Field>
                    </div>
                </React.Fragment>
            )
        }
    };

    updateEmailValue(e, form) {
        if (this.state.assignorSelected.isAnEntity) {
            form.setFieldValue('assignor.authorizedSignatory.email', e.target.value, false)
        }
    }

    getDateForPickerFromData(date) {
        if (!date) return undefined;
        return moment(date).toDate();
    }

    handleChange(e, field, form) {

        const date = e === null ? null : moment(e).format("YYYY-MM-DD");

        FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

        this.setState({
            [field.name]: e
        })
    }

    getAuthorizedSignatoriesList = (index, form) => {
        if (form.values && form.values.assignee) {
            return this.filterAuthorizedByGroupCompany(form.values.assignee).map(as => ({
                id: as.id,
                label: as.label
            }))
        } else {
            return [];
        }
    };

    getLabel = (key) => {
        switch (key) {
            case "fullName":
                if (this.state.assignorSelected.isAnEntity) {
                    return "Company name";
                } else {
                    return "Name";
                }
            case "country":
                if (this.state.assignorSelected.isAnEntity) {
                    return "Country of incorporation";
                } else {
                    return "Nationality";
                }
            case "address":
                if (this.state.assignorSelected.isAnEntity) {
                    return "Registered address";
                } else {
                    return "Residential address";
                }
            case "passportOrRegistered":
                if (this.state.assignorSelected.isAnEntity) {
                    return "Company number";
                } else {
                    return "Passport number";
                }
        }
    };

    getField = (key) => {
        switch (key) {
            case "passportOrRegistered":
                if (this.state.assignorSelected.isAnEntity) {
                    return "assignor.registeredNumber";
                } else {
                    return "assignor.passportNumber";
                }
        }
    };

    getTooltip = (key, fieldName) => {
        switch (key) {
            case "fullName":
                if (this.state.assignorSelected.isAnEntity) {
                    return "Company Name";
                } else {
                    return "IP Assignor's Name";
                }
            case "passportOrRegistered":
                if (this.state.assignorSelected.isAnEntity) {
                    return "Company Number";
                } else {
                    return "Passport Number";
                }
            case "country":
                if (this.state.assignorSelected.isAnEntity) {
                    return "Country";
                } else {
                    return "Nationality";
                }
            case "addressLine1":
                if (this.state.assignorSelected.isAnEntity) {
                    return "Registered Address - Address Line 1";
                } else {
                    return "Residential Address - Address Line 1";
                }
            case "addressCity":
                if (this.state.assignorSelected.isAnEntity) {
                    return "Registered Address - City";
                } else {
                    return "Residential Address - City";
                }
            case "addressState":
                if (this.state.assignorSelected.isAnEntity) {
                    return "Registered Address - State";
                } else {
                    return "Residential Address - State";
                }
            case "addressCountry":
                if (this.state.assignorSelected.isAnEntity) {
                    return "Registered Address - Country";
                } else {
                    return "Residential Address - Country";
                }
            case "addressPostCode":
                if (this.state.assignorSelected.isAnEntity) {
                    return "Registered Address - Post Code";
                } else {
                    return "Residential Address - Post Code";
                }
            case "recipientNotice":
                if (fieldName === 'assignee') {
                    return "IP Assignee's";
                } else {
                    return "IP Assignor's";
                }
        }
    };

    getPlaceholder = (key) => {
        switch (key) {
            case "passportOrRegistered":
                if (this.state.assignorSelected.isAnEntity) {
                    return "Company number";
                } else {
                    return "Passport number";
                }
        }
    };

    getStakeholderAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add new corporate stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
                placeholder: "Stakeholder's name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: true,
                    // roles : [{
                    // 	role: "SHAREHOLDER",
                    // 	groupCompany: this.state.groupCompanySelected.id,
                    // }],
                },
                paramsReturn: 'isAnEntity'
            },
            {
                type: "stakeholder",
                label: "Add new individual stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
                placeholder: "Stakeholder's name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: false,
                    // roles : [{
                    // 	role:"SHAREHOLDER",
                    // 	groupCompany: this.state.groupCompanySelected.id,
                    // }]
                },
                paramsReturn: 'isAnEntity'
            },
        ]
    };

    getCompanyAdds = () => {
        return [{
            type: "groupCompany",
            label: "Add new company",
            textWarning: "This will create a new group company for your startup. You can add or amend the details of this group company on its profile page.",
            placeholder: "Company name",
            startupId: this.props.startup.id,
            params: null,
            paramsReturn: `
				name
				authorizedSignatories {
					id
					stakeholder {
						id
						email
						fullName
						roles {   id
        name
        groupCompany { id name}
        stakeholder { id } }
					}
				}
			`
        }]
    };

    getAuthorizedSignatoryAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add new individual stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
                placeholder: "Enter stakeholder's name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: false,
                    roles: [{
                        role: "DIRECTOR",
                        groupCompany: this.state.groupCompanySelected.id,
                    }]
                }
            },
        ]
    };

    handleStakeholderCreate = (value, name, additionalValue, form) => {

        let { stakeholders, stakeholderList } = this.props;
        let newSh = { id: value.id, fullName: name, ...additionalValue };
        stakeholderList.push(newSh);

        stakeholders.push({ id: value.id, label: name });

    };

    handleGroupCompanyCreate = (value, name, additionalValue, form) => {

        let { groupCompanies, groupCompaniesList } = this.props;

        let newGc = { id: value.id, name, ...additionalValue };
        groupCompaniesList.push(newGc);

        groupCompanies.push({ id: value.id, label: name });

    };

    handleAuthorizedSignatoryCreate = (value, name, additionalValue, form) => {

        let { authorizedSignatories } = this.props;
        const { groupCompanySelected } = this.state;

        authorizedSignatories.push({
            id: value.id,
            label: name,
            role: 'DIRECTOR',
            groupCompanyId: groupCompanySelected.id
        });

    };

    handleAuthorizedSignatoryUpdate = (value) => {

        const { authorizedSignatories, groupCompanySelected } = this.state;
        const groupCompany = this.findGroupCompany(groupCompanySelected.id);
       _.set(groupCompany,'authorizedSignatories',_.map(groupCompany.authorizedSignatories,auth=>({...auth})))
        groupCompany.authorizedSignatories.push({ stakeholder: value });
        authorizedSignatories.push(value);
    };

    render() {
        const isRequired = message => value => (!!value ? undefined : message);
        const { tooltips = {}, formType } = this.props;

        return (
            <div>
                <div className={classes.ContainerRow}>
                    {formType === 'uploadToEdit' ? (<div>
                        <Field name={`file`}>
                            {({ field, form }) => (
                                <ClaraUpload
                                    required={true}
                                    name={`filepond`}
                                    label='Upload your document '
                                    field={field}
                                    form={form}
                                />
                            )}
                        </Field>
                    </div>) : null}
                    <Field name='agreementName'>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='text'
                                label='Document name '
                                field={field}
                                form={form}
                                placeholder={"Document name"}
                                tooltip={tooltips['Document Name']}
                            />
                        )}
                    </Field>
                </div>
                <hr className={classes.Separator} />
                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                    <Field name='assignor.id'>
                        {({ field, form }) => (
                            <ClaraSelect
                                required={true}

                                field={field}
                                form={form}

                                label={"Select IP assignor "}
                                adds={this.getStakeholderAdds()}
                                lists={this.props.stakeholders}
                                mode={"classic"}
                                callbacks={{
                                    createValues: this.handleStakeholderCreate,
                                    updateValues: this.selectAssignor
                                }}
                                placeholder={"Select from the list"}
                                tooltip={tooltips["Select IP Assignor"]}
                            />

                        )}
                    </Field>
                </div>
                {
                    this.state.assignorSelected ?
                        <React.Fragment>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                {
                                    this.state.assignorSelected.isAnEntity ?
                                        <>
                                            <h1>IP Assignor Company Details</h1>
                                            <p>Please verify or update the IP assignor's company's information below</p>
                                        </>
                                        :
                                        <>
                                            <h1>IP Assignor Details</h1>
                                            <p>Please verify or update the IP assignor's information below</p>
                                        </>
                                }
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='assignor.fullName'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label={this.getLabel("fullName") + ' '}
                                            field={field}
                                            form={form}
                                            placeholder={'Name'}
                                            required={true}
                                            tooltip={tooltips[this.getTooltip('fullName')]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={`${classes.ContainerRow} ${classes.Select}`}>
                                <Field name='assignor.nationality'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={this.state.assignorSelected.isAnEntity}
                                            countryField={'assignor.nationality'}
                                            label={this.getLabel('country') + ''}
                                            jurisdictionField={'assignor.jurisdiction'}
                                            jurisdictionLabel={'Jurisdiction '}
                                            otherJurisdictionField={'assignor.otherJurisdiction'}
                                            setFieldValue={form.setFieldValue}
                                            initialValues={{
                                                jurisdiction: _.get(form.values, 'assignor.jurisdiction')
                                            }}
                                            returnCountryAsString={true}
                                            additionalStyle={'ClaraCountrySelectorWizard'}
                                            tooltips={{
                                                country: tooltips[this.getTooltip('country')],
                                                jurisdiction: tooltips['Jurisdiction'],
                                                other: tooltips['Other Jurisdiction']
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            {
                                this.state.assignorSelected.isAnEntity ?
                                    <React.Fragment>
                                        <div className={classes.ContainerRow}>
                                            <Field name='assignor.type'>
                                                {({ field, form }) => (
                                                    <TextInput
                                                        required={true}
                                                        type='text'
                                                        label='Company type '
                                                        field={field}
                                                        form={form}
                                                        placeholder={'Company type'}
                                                        tooltip={tooltips['Company Type']}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                    </React.Fragment>
                                    :
                                    null
                            }
                            <div className={classes.ContainerRow}>
                                <Field name={this.getField("passportOrRegistered")}>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label={this.getLabel("passportOrRegistered") + ' '}
                                            field={field}
                                            form={form}
                                            placeholder={this.getPlaceholder("passportOrRegistered")}
                                            tooltip={tooltips[this.getTooltip("passportOrRegistered")]}
                                        />
                                    )}
                                </Field>
                            </div>
                            {
                                !this.state.assignorSelected.isAnEntity ?
                                    <React.Fragment>
                                        <div className={classes.ContainerRow}>
                                            <Field name='assignor.email'>
                                                {({ field, form }) => (
                                                    <TextInput
                                                        type='email'
                                                        label='Email address '
                                                        field={field}
                                                        form={form}
                                                        placeholder={'Email address'}
                                                        required={true}
                                                        tooltip={tooltips['Email Address']}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                    </React.Fragment>
                                    :
                                    null
                            }
                            <div className={`${classes.ContainerRow} ${classes.Select}`}>
                                <Field name='assignor.address.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true }}
                                            jurisdictionsEnabled={false}
                                            countryField={'assignor.address.country'}
                                            label={this.getLabel('address') + ''}
                                            setFieldValue={form.setFieldValue}
                                            returnCountryAsString={true}
                                            // initialValues = {{jurisdiction : _.get(form.values,'assignor.jurisdiction') }}
                                            additionalStyle={'ClaraCountrySelectorWizard'}
                                            tooltips={{
                                                country: tooltips[this.getTooltip('addressCountry')]
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='assignor.address.street'>
                                    {({ field, form }) => (
                                        <TextAreaInput
                                            type='text'
                                            label={' '}
                                            field={field}
                                            form={form}
                                            placeholder={'Address'}
                                            tooltip={tooltips[this.getTooltip('addressLine1')]}

                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='assignor.address.city'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={'City'}
                                            tooltip={tooltips[this.getTooltip('addressCity')]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='assignor.address.state'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={'State / Region'}
                                            tooltip={tooltips[this.getTooltip('addressState')]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='assignor.address.zipCode'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={"Post code"}
                                            tooltip={tooltips[this.getTooltip('addressPostCode')]}
                                        />
                                    )}
                                </Field>
                            </div>
                            {this.renderRecipientNotice('assignor', this.state.assignorSelected.isAnEntity)}
                            {this.renderAssignorAuthorizedSignatory('assignor')}
                        </React.Fragment>
                        :
                        null
                }
                <hr className={classes.Separator} />
                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                    <Field name='assignee.id'>
                        {({ field, form }) => (
                            <ClaraSelect
                                required={true}

                                field={field}
                                form={form}

                                label={"Select company "}
                                lists={this.getGroupCompaniesToAssignee()}
                                mode={"classic"}
                                callbacks={{
                                    createValues: this.handleGroupCompanyCreate,
                                    updateValues: this.selectGroupCompany
                                }}
                                placeholder={"Select from the list"}
                                tooltip={tooltips['Select IP Assignee']}
                            />
                        )}
                    </Field>
                </div>
                {
                    this.state.groupCompanySelected ?
                        <React.Fragment>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>IP Assignee Details</h1>
                                <p>Please verify or update the IP assignee's information below</p>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='assignee.name'>
                                    {({ field, form }) => (
                                        <TextInput

                                            required={true}
                                            type='text'
                                            label='Company name '
                                            field={field}
                                            form={form}
                                            placeholder={"Company name"}
                                            tooltip={tooltips["IP Assignee's Name"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={`${classes.ContainerRow} ${classes.Select}`}>
                                <Field name='assignee.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={true}
                                            countryField={'assignee.country'}
                                            label={'Country of incorporation '}
                                            jurisdictionField={'assignee.jurisdiction'}
                                            jurisdictionLabel={'Jurisdiction '}
                                            returnCountryAsString={true}
                                            otherJurisdictionField={'assignee.otherJurisdiction'}
                                            setFieldValue={form.setFieldValue}
                                            initialValues={{
                                                jurisdiction:_.get(form.values, 'assignee.otherJurisdiction') || _.get(form.values, 'assignee.jurisdiction')
                                            }}
                                            additionalStyle={'ClaraCountrySelectorWizard'}
                                            tooltips={{
                                                country: tooltips["IP Assignee's Country"],
                                                jurisdiction: tooltips["IP Assignee's Jurisdiction"],
                                                other: tooltips['Other Jurisdiction']
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='assignee.type'>
                                    {({ field, form }) => (
                                        <TextType
                                            required={true}
                                            type='text'
                                            groupCompany={this.state.groupCompanySelected}
                                            label='Company type '
                                            field={field}
                                            form={form}
                                            placeholder={'Company type'}
                                            tooltip={tooltips["IP Assignee's Company Type"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='assignee.registeredNumber'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label='Company number '
                                            field={field}
                                            form={form}
                                            placeholder={'Company number'}
                                            tooltip={tooltips["IP Assignee's Company Number"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={`${classes.ContainerRow} ${classes.Select}`}>
                                <Field name='assignee.registeredAddress.country'>
                                    {({field, form}) =>{
                                        return (
                                            <ClaraCountrySelector
                                                isRequired={{ country: true }}
                                                jurisdictionsEnabled={false}
                                                countryField={'assignee.registeredAddress.country'}
                                                label={'Registered address '}
                                                returnCountryAsString={true}
                                                // initialValues={{country:_.get(form.values,field.name,null)}}
                                                setFieldValue={form.setFieldValue}
                                                additionalStyle={'ClaraCountrySelectorWizard'}
                                                tooltips={{
                                                    country: tooltips["IP Assignee's Registered Address - Country"]
                                                }}
                                            />
                                        )
                                    }}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='assignee.registeredAddress.street'>
                                    {({ field, form }) => (
                                        <TextAreaInput
                                            type='text'
                                            label={' '}
                                            field={field}
                                            form={form}
                                            placeholder={"Address"}
                                            tooltip={tooltips["IP Assignee's Registered Address - Address Line 1"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='assignee.registeredAddress.city'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={'City'}
                                            tooltip={tooltips["IP Assignee's Registered Address - City"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='assignee.registeredAddress.state'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={'State / Region'}
                                            tooltip={tooltips["IP Assignee's Registered Address - State"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='assignee.registeredAddress.zipCode'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={"Post code"}
                                            tooltip={tooltips["IP Assignee's Registered Address - Postal Code"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            {this.renderRecipientNotice('assignee', true)}
                            <hr className={classes.Separator}></hr>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Corporate Signatories</h1>
                                <p>Please add all the signatories required to sign this document on behalf of the company.</p>
                            </div>
                            <Field name='company.authorizedSignatories_error'>
                                {({ field, form }) => (
                                    <React.Fragment>
                                        <span hasError={_.get(form.errors, "assignee.authorizedSignatories") ? "true" : "false"}>
                                            <AuthorisedSignatories
                                                list={this.props.stakeholderList}
                                                fieldArrayName={'assignee.authorizedSignatories'}
                                                idGroupCompany={this.state.groupCompanySelected.id}
                                                handleAddListStakeholder={this.handleAuthorizedSignatoryUpdate}
                                                errorMessage={_.get(form.errors, "assignee.authorizedSignatories")}
                                            />
                                        </span>
                                    </React.Fragment>
                                )}
                            </Field>
                        </React.Fragment>
                        :
                        null
                }

                {

                    <Field>
                        {({ field, form }) => (
                            this.renderSpecialAgreementFields(form)
                        )}
                    </Field>
                }{ this.state.assignorSelected && this.state.groupCompanySelected && <>
                 <hr className={classes.Separator}></hr>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Place of Arbitration</h1>
                                <p>This contract’s dispute resolution mechanism uses arbitration under expedited ICC rules. Please select the place where you would like the arbitration to take place (please enter the name of the city and country and, if applicable, state or freezone), e.g. "London, United Kingdom" or "New York City, New York, United States of America” or "Dubai International Financial Centre, Dubai, United Arab Emirates”. Please note that the place of arbitration that you select can have a material impact on the proceedings and enforcement of the arbitration and arbitral award. Please seek legal advice.</p>
                                </div>
                                <div className={classes.ContainerRow}>

<Field name={`terms.placeOfArbitration`}>
    {({ field, form }) => (
        <TextInput
            required={true}
            type='text'
            label='Place of arbitration '
            field={field}
            form={form}
            placeholder={"Place of arbitration"}
        />
    )}
</Field>
</div>
    </>}

                {formType === 'uploadToEdit' ? <div>
                    <Field name='confirmSameData'>
                        {({ field, form }) => (
                            <Checkbox
                                className={classes.sameDataCheckbox}
                                field={field}
                                validate={isRequired('This field is required')}
                                form={form}
                                required={true}
                                label='All document details are verified and correct.'
                            />
                        )}
                    </Field>
                </div> : null}
            </div>

        );
    }

}

export default AssignmentDoc;

import _ from "lodash";
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch } from "react-redux";
// import Cookies from "universal-cookie";
import * as Yup from 'yup';
import BotoneraAdd from '../../../../components/forms/contents/BotoneraAdd/BotoneraAdd';
import FormAdd from '../../../../components/forms/contents/FormAdd/FormAdd';
import Form from '../../../../components/forms/Form/Form';
import SubmitButton from '../../../../components/generic/Buttons/SubmitButton/SubmitButton';
import Scrollbar from '../../../../components/generic/Scrollbar/Scrollbar';
import LinkedinButton from "../../../../components/inputs/ButtonLinkedin/LikedinButton";
import PasswordInput from '../../../../components/inputs/Password/PasswordInput';
import TextInput from '../../../../components/inputs/Text/TextInput';
import SignUpRow from '../../../../components/templates/SignUpRow/SignUpRow';
import Text from '../../../../components/text/Text/Text';
import useGetMe from "../../../../hooks/services/modules/singIn/useGetMe";
import useGetMatterList from "../../../../hooks/services/modules/user/useGetMatterList";
import ControllerInput from "../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import { Constants } from "../../../../v1/utils/constants";
import notification from "../../../../v1/utils/notification";
import ServerConnect from "../../../../v1/utils/ServerConnect";
import VerificationView from '../verificationView/VerificationView';
import useSession from "./../../../../modules/session/hooks/useSession";
import classes from "./SignInView.module.scss";

const schema = Yup.object().shape({
  email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, { message: "Please enter a valid email address", excludeEmptyString: true }).required("This field is required."),
  password: Yup.string().required('This field is required'),
})

const SignInView = () => {

  const { initSession, isLoading } = useSession()
  const [view, setView] = useState('signIn');
  const [userEmail, setUserEmail] = useState('');
  const [userFirstName, setUserFirstName] = useState(null);
  const [totalDevices, setTotalDevices] = useState('00');
  const [userCode, setUserCode] = useState(null)
  const [userPwd, setUserPwd] = useState(null);
  const [startups, setStartups] = useState([])
  const [logged, setLogged] = useState(false)
  const [verificationError, setVerificationError] = useState(false)
  const [showErrors, setShowErrors] = useState(false);
  const dispatch = useDispatch();
  const { manualQuery: getMatterList, loading: loadingMatter, data: dataGetMatter } = useGetMatterList()
  const { manualQuery: getMe, loading: loadingGetMe, data: dataGetMe } = useGetMe()

  const paramsText = {
    userEmail
  }

  const onSubmitVerification = async (val) => {
    try {
      const validations = null;
      const result = await ServerConnect.requestApi("/2fa/verifyCode", {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: userEmail.toLowerCase(),
          deviceName: val.deviceName,
          token: val.verificationCode
        })
      }, validations, { showNotification: false });
      if (result && result.error) {
        setShowErrors(true);
      } else if (result && result.code == 'REDIRECT_TO_UPDATE_PASSWORD') {
        dispatch({ type: 'GO_TO', page: Constants.PAGES.reset, params: { token: result.token } })
      } else if (result) {
        await handleLogin({ email: userEmail, password: userPwd })
      } else {
        setVerificationError(true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleLogin = async (val) => {
    try {
      const tokenResult = await ServerConnect.requestApi("/auth/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          clientType: "browser",
          email: `${val.email}`,
          password: `${val.password}`
        })
      },null,{
        throwExeptionWithDefault:true
      })
      if (tokenResult) {
        if (_.get(tokenResult, 'redirect') === 'REDIRECT_TO_2FA_AUTH') {
          setUserEmail(val.email)
          setUserFirstName(_.get(tokenResult, 'name'))
          setTotalDevices(_.get(tokenResult, 'totalDevices', "00"))
          setUserPwd(val.password)
          setView('verificationIp')
        } else if (_.get(tokenResult, 'pendingVerification')) {
          setUserEmail(val.email)
          setUserPwd(val.password)
          setUserFirstName(_.get(tokenResult, 'name'))
          setTotalDevices(_.get(tokenResult, 'totalDevices', "00"))
          setView('verification')
        } else if (_.get(tokenResult, 'code') === "INVALID_PASSWORD_ATTEMPS") {
          setUserEmail(val.email)
          setUserFirstName(_.get(tokenResult, 'name'))
          setTotalDevices(_.get(tokenResult, 'totalDevices', "00"))
          setView('verificationPasswordError')
        } else {
          const token = _.get(tokenResult, 'token');
          await initSession(token, moment(tokenResult.lastLogin).utc());
        }
      }
    } catch (err) {
      notification.sendNotification("Looks like your password or email is incorrect. Let's try that again", "error", 4990);
    }
  };

  const loginScreen = (
    <React.Fragment>
      <Scrollbar className={classes.fullContainer}>
        <div className={classes.container}>
          <div className={classes.loginTitle}>
            <span>Log in to Clara</span>
          </div>
          <div className={classes.formContainer}>
            <FormAdd title={""} subTitle={""}>
              <Form schema={schema} loading={false} onSubmit={handleLogin} observerWatch={''} watch={["category"]}>
                <div className={classes.fieldContainer}>
                  <SignUpRow
                    label={"FIELD_EMAIL_LABEL"}
                    asterisk={false}
                    input={
                      <ControllerInput
                        as={TextInput}
                        name={'email'}
                        showErrorIcon={true}
                        defaultValue={''}
                        placeholder={"Email address"}
                        onChange={(e) => { }}
                      />
                    }
                  />
                </div>
                <div className={classes.fieldContainer}>
                  <SignUpRow
                    label={"FIELD_PASSWORD_LABEL"}
                    asterisk={false}
                    input={
                      <ControllerInput
                        as={PasswordInput}
                        name={'password'}
                        showErrorIcon={true}
                        placeholder={"Password"}
                        onChange={(e) => { }}
                      />
                    }
                  />
                </div>
                <div className={classes.forgotPasswordLink}>
                  <span>
                    <a onClick={() => { dispatch({ type: 'GO_TO', page: Constants.PAGES.recovery }) }}>
                      Forgotten password?
                    </a>
                  </span>
                </div>
                <div className={classes.submitButton}>
                  <BotoneraAdd>
                    <SubmitButton isSubmitting={isLoading} labelLoading={Constants.TRANSLATES.BUTTON_PROCESSING}>
                      <Text uuid={"TEXT_LOG_IN"} />
                    </SubmitButton>
                  </BotoneraAdd>
                </div>
              </Form>
            </FormAdd>
          </div>
          <div className={classes.textBetweenButtons}>
            <span>
              <div className={classes.line}></div>
              <span className={classes.text}>or</span>
              <div className={classes.line}></div>
            </span>
          </div>
          <div className={classes.linkedInButton}>
            <LinkedinButton />
            <span className={classes.signUpLink} >
              <span>Don't have an account yet?</span>
              <a onClick={() => { dispatch({ type: 'GO_TO', page: Constants.PAGES.register }) }}>
                Join for free
              </a>
            </span>
          </div>
          <div className={classes.footerLinks}>
            <a className={classes.links} href="https://www.clara.co/privacy-policy/">
              Privacy Policy
            </a>
            <span className={classes.footerSeparator}>|</span>
            <a className={classes.links} href="https://clara.co/terms-of-use/">
              Terms & Conditions
            </a>
            <span className={classes.footerSeparator}>|</span>
            <a className={classes.links} href="https://www.clara.co/contact-us">
              Contact Us
            </a>
          </div>
        </div>
      </Scrollbar>
    </React.Fragment>
  );

  const renderView = (view) => {
    switch (view) {
      case 'verification':
        return (
          <VerificationView
            onSubmitVerification={onSubmitVerification}
            isSubmitting={loadingGetMe || loadingMatter}
            paramsText={paramsText}
            userFirstName={userFirstName}
            totalDevices={totalDevices.toString()}
            showVerificationError={verificationError}
            setVerificationError={setVerificationError}
          />
        );
      case 'verificationIp':
        return (
          <VerificationView
            onSubmitVerification={onSubmitVerification}
            isSubmitting={loadingGetMe || loadingMatter}
            paramsText={paramsText}
            isIpVerification={true}
            userFirstName={userFirstName}
            totalDevices={totalDevices.toString()}
            showVerificationError={verificationError}
            setVerificationError={setVerificationError}
          />
        );
      case 'verificationPasswordError':
        return (
          <VerificationView
            onSubmitVerification={onSubmitVerification}
            isSubmitting={loadingGetMe || loadingMatter}
            paramsText={paramsText}
            isIpVerification={true}
            isPasswordError={true}
            secondText={"DEAUTHORISE_DEVICE_MAIL_SECOND_TEXT"}
            thirdText={"DEAUTHORISED_MAIL_THIRD_TEXT"}
            title={"DEAUTHORISE_DEVICE_TITLE"}
            userFirstName={userFirstName}
            totalDevices={totalDevices.toString()}
            showVerificationError={verificationError}
            setVerificationError={setVerificationError}
          />
        )
      case 'signIn':
        return loginScreen;
      default:
        return null;
    }
  }

  return (
    renderView(view)
  );
}

export default SignInView;

import _ from "lodash";
import React, { FC, useMemo } from "react";
import useUpsertRoleStakeholder from "../../../../../../../hooks/services/modules/individualStakeholder/useUpsertRoleStakeholder";
import useGetStakeholders from "../../../../../../../hooks/services/modules/stakeholders/useGetStakeholders";
import Button from "../../../../../../generic/components/Buttons/Button";
import { StepProps } from "../../../../../../generic/components/Wizard/Step";
import useCustomMachine from "../../../../../../generic/hooks/useCustomMachine";
import useTranslate from "../../../../../../generic/hooks/useTranslate";
import TemplateWizard from "../../../../../../generic/templates/Modal/TemplateWizard";
import useSession from "../../../../../../session/hooks/useSession";
import IndividualOrCompanyStakeholderForm from "../../../forms/IndividualOrCompanyStakeholderForm";
import useGetGroupCompanies from "../../../forms/IndividualOrCompanyStakeholderForm/graphql/useGetGroupCompanies";
import SkeletonInput from "../../../../../../../modules/generic/components/Skeletons/Overlays/SkeletonInput";
import SkeletonCheck from "../../../../../../../modules/generic/components/Skeletons/Overlays/SkeletonCheck";
import useIsAn from '../../../hooks/useIsAn';
interface IndividualOrCompanyStakeholderFormStep extends StepProps {
  role: string;
  isIndividual: boolean;
  isTherePrevious?: boolean;
}

const IndividualOrCompanyStakeholderFormStep: FC<IndividualOrCompanyStakeholderFormStep> = ({
  role,
  isIndividual,
  isTherePrevious = true,
}) => {

  const { next, send, stepData } = useCustomMachine();
  const [upsertStakeholder] = useUpsertRoleStakeholder({ showNotificationSuccess: true, });
  const { startupId } = useSession();
  const { data } = useGetGroupCompanies({ startupId });
  const { data: stakeholders } = useGetStakeholders({ variables: { startupId } });
  const { translate } = useTranslate();

  const handleCancel = async (values) => {
    send("CANCEL");
  };
  
  const handlePrevious = async (values) => {
    send("PREVIOUS");
  };

  const handleCompleteSubmit = async (values) => {
    let isStakeholderLimit = false
    const variables = {
      startupId,
      roles: _.map(values.company, (gc) => {
        return { role: [{type:role}], groupCompany: gc.id };
      }),
      stakeholder: {...values.stakeholder,isAnEntity:!isIndividual},
    };
    const companyFormation = _.filter(values.company, com => _.get(com, "formationData.nextGoal", null));
    const isConcierge = _.get(values, "company.0.formationData.nextGoal");
    let showWarning = true;
    let companiesInBuy = _.filter(values.company, (comp) => comp.companyGoal === "BUY");
    isStakeholderLimit = stakeholdersLimit(stakeholders, companiesInBuy);
    if (isIndividual) {
      if (!isStakeholderLimit) {
        await upsertStakeholder(variables);
        showWarning = false;
      }
    } else {
      let isSomeInBuy = _.some(values.company, (comp) => comp.companyGoal === "BUY");
      if (!isSomeInBuy) {
        await upsertStakeholder(variables);
        showWarning = false;
      }
    }
    next({ variables, companyFormation, isStakeholderLimit, isConcierge, showWarning });
  };

  const stakeholdersLimit = ((values, valuesCompany) => {
    let groupsByStakeholder = {}
    let status = false;
    _.forEach(values, (value) => {
      let groups = _.get(value, 'roles')
      _.forEach(groups, (group) => {
        let groupName = _.get(group, 'groupCompany.id')
        let stakeholderRole = _.get(group, 'name')
        if (!groupsByStakeholder[groupName]) {
          groupsByStakeholder[groupName] = 0;
        }
        if (stakeholderRole === role) {
          groupsByStakeholder[groupName] = groupsByStakeholder[groupName] + 1;
        }
      })
    })
    if (valuesCompany.length === 1) {
      //TODO: Remplace Cayman Islands when create Code to Jurisdiction
      if (valuesCompany[0].jurisdiction!=="Cayman Islands" && groupsByStakeholder[valuesCompany[0].id] >= 10) {
        status = true;
      }
    } else {
      _.forEach(valuesCompany, (comp) => {
        if (groupsByStakeholder[comp.id] >= 10) {
          status = true;
        }
      })
    }
    return status;
  })

  const groupCompanies = useMemo(() => {
    const groupCompaniesOrdered = _.orderBy(data, ["isHoldingCompany"], ["desc"])
    return role === 'DIRECTOR' || role === 'SHAREHOLDER' ? _.map(groupCompaniesOrdered,gc=> ({ disabled: gc.isLocked ,label:gc.name,value:gc})) : _.map(groupCompaniesOrdered,gc=> ({ disabled: gc.kind ==='FORMATION' ,label:gc.name,value:gc}));
  }, [JSON.stringify(data), role]);

  const isAn = useIsAn(role);

  return (
    <>
      <IndividualOrCompanyStakeholderForm
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        groupCompanies={groupCompanies}
        isIndividual={isIndividual}
        stakeholders={stakeholders}
        propsTemplate={{
          title: translate("MODULES.ONBOARDING_STEPS.ADD_BASIC_INFO"),
          subTitle: translate("MODULES.ONBOARDING_STEPS.ADD_ROLE", { role: _.lowerCase(translate(`MODULES.ONBOARDING_STEPS.ROLES_LABEL.${role}`)),isAn }),
          skeleton: (
            <>
              <SkeletonInput></SkeletonInput>
              <SkeletonCheck></SkeletonCheck>
            </>
          ),
        }}
        initialValues={stepData}
        buttons={{
          cancel: <Button onClick={handleCancel}>Cancel</Button>,
          previous: isTherePrevious ? (
            <Button onClick={handlePrevious}>Previous</Button>
          ) : null,
        }}
      />
    </>
  );
};

export default IndividualOrCompanyStakeholderFormStep;
import { actions as actionTypes } from "../actions/checkout";
const initialState = {
    product: null,
};

export default (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.CHANGE_DEFAULT_PRODUCT:
            {

                return {
                    ...state,
                    product: action.product,
                };
            };
        default:
            return state;
    }

};

import React from 'react'
import * as Yup from 'yup'
import Form from '../../../../components/forms/Form/Form'
import useTranslate from '../../../generic/hooks/useTranslate'
import TextInput from '../../../../components/inputs/Text/TextInput'
import ControllerInput from '../../../generic/components/Inputs/ReactHookForm/ControllerInput'
import TemplateLabel from '../../../generic/templates/Label'
import SubmitButton from '../../../generic/components/Buttons/SubmitButton'
import FormProps from '../../../generic/forms/interfaces/FormProps';
import TemplateFormDefault from "../../../generic/templates/Modal";
import cls from './Form.module.scss';
import useGetGroupCompanies from '../../graphql/groupCompany/query/useGetGroupCompanies'
import CheckboxInput from '../../../generic/components/Inputs/Checkbox'
import { useSelector } from 'react-redux'
import _ from 'lodash';
import useCustomMachine from '../../../generic/hooks/useCustomMachine'
import ClassNames from 'classnames';
import { HiddenFieldForm } from '../../../../components/forms/contents'

const schema = Yup.object().shape({
    responsabilities: Yup.array().min(1, 'This field is required').required('This field is required')
})

const FormSelectCompany: React.FC<FormProps> = ({
    initialValues: defaultValues = {},
    onCompleteSubmit,
    Template = TemplateFormDefault,
    buttons = {},
    buttonSubmitProps,

    propsTemplate }) => {


    const { translate } = useTranslate();

    const { state } = useCustomMachine()

    const handleSelectList = (list, value = null) => {
        if (value) return list.map(gc => ({ value: gc, label: gc.name, disabled: value.id !== gc.id }));
        return list.map(gc => ({ value: gc, label: gc.name, disabled: false }));
    }

    const optionsResponsabilities = React.useMemo(() => {
        const responsabilities = _.get(state, 'context.data.select_positions_department.position.responsibilities', []);
        return responsabilities.map(res => ({ label: res.name, value: res }))
    }, []);

    const handleSubmit = async (values) => {
        const { responsabilities, textOther } = values;
        const responsibilitiesData = _.map(responsabilities, res => res.isDefault ? res : { ...res, text: textOther });
        await onCompleteSubmit({ responsabilities: responsibilitiesData } as any);
    }



    return (
        <Form
            defaultValues={defaultValues}
            schema={schema}
            onSubmit={handleSubmit}
            optionsForm={{ mode: "onChange" }}
        >

            <Template buttons={{ ...buttons, submit: <SubmitButton  {...buttonSubmitProps}>Add</SubmitButton> }} props={propsTemplate}>
                <TemplateLabel
                    label={'Choose all that apply'}
                    isRequired
                    className={ClassNames(cls.TemplateLabel, cls.checkboxLabel)}
                >
                    <ControllerInput
                        render={CheckboxInput}
                        options={optionsResponsabilities}
                        name='responsabilities'
                        defaultlabel={''}

                    />
                </TemplateLabel>

                <HiddenFieldForm fieldsWatch={'responsabilities'} conditionHideCallback={(values) => _.some(values, res => res.name === 'Other')}>

                    <TemplateLabel
                        label={' '}
                        isRequired
                        className={ClassNames(cls.TemplateLabel)}
                    >
                        <ControllerInput
                            render={TextInput}
                            name='textOther'
                            defaultlabel={''}
                            placeholder={'Add other responsibilities here'}

                        />
                    </TemplateLabel>


                </HiddenFieldForm>

            </Template>

        </Form>
    )

}

export default FormSelectCompany

import { useMemo } from 'react';
import _ from 'lodash';


const useGuards = () => {
  
  return useMemo(() => ({
    // TechStartupEligibilityFormStep
    isQualified: (context) => {
      return _.get(context, 'TechStartupEligibilityFormStep.checkEligibility', false) === 'YES';
    },
    isNotQualified: (context) => {
      return _.get(context, 'TechStartupEligibilityFormStep.checkEligibility', false) === 'NO';
    },
    isUnsure: (context) => {
      return _.get(context, 'TechStartupEligibilityFormStep.checkEligibility', false) === 'DONT_KNOW';
    },
    
    // AdgmTechStartupAuthorisedSignatoryFormStep 
    isADirectorOrShareholder: (context) => {
      return _.get(context, 'AdgmTechStartupAuthorisedSignatoryFormStep.authorisedSignatory', false) === 'DirectorOrShareholder';
    },
    isAThirdParty: (context) => {
      return _.get(context, 'AdgmTechStartupAuthorisedSignatoryFormStep.authorisedSignatory', false) === 'ThirdParty';
    },
    isAClarasNominee: (context) => {
      return _.get(context, 'AdgmTechStartupAuthorisedSignatoryFormStep.authorisedSignatory', false) === 'ClarasNominee';
    },
    isAClaraConcierge: (context) => {
      return _.get(context, 'AdgmTechStartupAuthorisedSignatoryFormStep.authorisedSignatory', false) === 'ClaraConcierge';
    },

    // Return from FormWithClaraConcierge 
    isAdgmTechStartupAuthorisedSignatoryFormStep: (context) => {
      return _.get(context, 'previousStep', false) === 'AdgmTechStartupAuthorisedSignatoryFormStep';
    },
    isTechStartupConciergeFormStep: (context) => {
      return _.get(context, 'previousStep', false) === 'TechStartupConciergeFormStep';

    },

    // TechStartupConciergeFormStep choos if is concierge or want to change jurisdiction
    isConcierge: (context) => {
      return _.get(context, 'TechStartupConciergeFormStep.checkYourStartup', false) === 'CONCIERGE';
    },

    isOtherJurisdiction: (context) => {
      return _.get(context, 'TechStartupConciergeFormStep.checkYourStartup', false) === 'CHANGE_JURISDICTION';
    },

  
  }), []);
}

export default useGuards;

import useQuery from "../../../../../../hooks/custom/useQuery";
import _ from "lodash";

const query = `query getStartup($startupId: ID!){
  getStartup(startupId: $startupId){
    id
    name
    description
    website
    linkedInURL
    primaryLocation {
      name
      code
    }
    resellerInfo{
      receiveEmailsOnBehalfOwners
    }
  }
}`;

const useGetStartup = (variables, config) => {
  const { loading, error, data, refetch } = useQuery(query, variables, config);
  return { loading, error, data: _.get(data, "getStartup", {}), refetch };
}

export default useGetStartup;
import React, { FC, useMemo } from "react";

import AssistanceContents from "../../../AssistanceContents";
import BubbleTask from "../../../BubbleTask";
import { Constants } from "src/v1/utils/constants";
import Constants2 from "../../../../../../modules/assistanceV4/components/AssistanceFrame2/hooks/constants_task";
import IndividualReOpen from "../Cards/IndividualReOpen";
import TabBubblesContainer from "../../../TabBubblesContainer";
import _ from "lodash";
import { goToPage } from "src/store/actions/page";
import { useDispatch } from "react-redux";

export interface StakeholderIndividual2TasksProps {
  tasks: any;
  companiesCorporateData: any;
  entity: any;
  resetCounter: any;
  showGJ: boolean;
  countCompleted: any;
}

const StakeholderIndividual2Tasks: FC<StakeholderIndividual2TasksProps> = ({
  tasks: dataTasks,
  companiesCorporateData = "",
  entity,
}: StakeholderIndividual2TasksProps) => {

  // TODO: use it when have task
  const isAnEntity = _.get(
    companiesCorporateData.getStakeholder,
    "isAnEntity",
    false
  );

  const dispatch = useDispatch();

  //TODO: use to go to task
  const handleGoDashboard = () => {
    goToPage(dispatch, Constants.PAGES.dashboard);
  };


  return (
    <AssistanceContents>
      <TabBubblesContainer>
        <BubbleTask variant={"stakeholder"} />
      </TabBubblesContainer>
    </AssistanceContents>
  );
};

export default StakeholderIndividual2Tasks;

import React,{useEffect} from "react";
import _ from "lodash";
import IndividualOrCompanyStakeholderFormStep from "../../../core/wizards/steps/IndividualOrCompanyStakeholderFormStep/IndividualOrCompanyStakeholderFormStep";
import useCustomMachine from "../../../../../generic/hooks/useCustomMachine";
import {useDispatch} from "react-redux";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import {addAssistantText, clearAssistatText} from "../../../../../generic/store/action";

const IndividualOrCompanyStepDirectorForm = ({ ...props }) => {

  const { state } = useCustomMachine();
  const dispatch = useDispatch();
  const { translate } = useTranslate();

  useEffect(() => {
    dispatch(
      addAssistantText(
        translate("MODULES.ONBOARDINGROLES.DIRECTOR.FIRST_STEP_1_TOOLTIPS"),
        "text"
      )
    );

    return () => dispatch(clearAssistatText()); 
  }, []);
  
  return (
    <>
      <IndividualOrCompanyStakeholderFormStep
        isIndividual={!_.get(
          state,
          "context.data.stakeholder_is_individual_or_company.isEntity"
        )}
        role={"DIRECTOR"}
      />
    </>
  );
};

export default IndividualOrCompanyStepDirectorForm;

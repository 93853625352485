import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import Botonera from '../../../forms/contents/Botonera/Botonera';
import PrimaryButton from '../../Buttons/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../Buttons/SecondaryButton/SecondaryButton';
import DocumentPreviewFile from "../../Documents/DocumentPreview/DocumentPreviewFile";
import classes from './DocumentPreviewModal.module.scss';

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
		height: '6rem'
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		overflowY: 'hidden',
		padding: 0,
		height: '55rem',
		width: '55rem'
	},
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
		height: '6rem'
	},
}))(MuiDialogActions);

const DocumentPreviewModalMF = ({ showModal, document, showDownload = true, showDetails = true, handleClose, handleSend, deactivePreview }) => {

	const { file, name: documentName } = document;
	const fileType = file?.name.split('.').pop(); // path.parse(file.name).ext;
	const [open, setOpen] = useState(showModal);

	const handleCloseModal = () => {
		setOpen(false);
		deactivePreview && deactivePreview();
		handleClose && handleClose();
	};

	const handleSendNow = () => {
		setOpen(false);
		handleSend && handleSend();
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={handleCloseModal}
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
				maxWidth={'md'}
			>
				<DialogTitle onClose={handleCloseModal}>
					<div className={classes.DialogTitle}>
						{documentName}
					</div>
				</DialogTitle>
				<DialogContent dividers={true}>
					<div className={classes.containerPreview}>
						<DocumentPreviewFile
							fileId={file?.id}
							fileType={fileType}
							fileName={file?.name}
						/>
					</div>
				</DialogContent>
				<DialogActions>
					<div className={classes.DocumentPreviewBotonera}>
						<Botonera >
							<SecondaryButton onClick={handleCloseModal}>Cancel</SecondaryButton>
							<PrimaryButton onClick={handleSendNow}>Send</PrimaryButton>
						</Botonera>
					</div>
				</DialogActions>
			</Dialog>
		</>
	)
};

export default DocumentPreviewModalMF;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Loading } from "../../components";
import { Constants } from "../../utils/constants";
import ServerConnect from "../../utils/ServerConnect";
import _ from  "lodash"
import {
  clearPages,
  goToPage,
  goToPrevPage,
} from "../../../store/actions/page";
import { clearSession } from "../../../modules/session/store/action";
import Cookies from "universal-cookie";


class Logout extends Component {

  clearSessionData = () => {
    this.props.clearSession();
    this.props.goToPage(Constants.PAGES.login);
    const client = ServerConnect.getUniqueApolloClient();
    if (client){
      client.clearStore();
    }
    
  };

  componentDidMount() {
    const tokenCookie = _.get(this.props.session,"token");
    if (tokenCookie) {
      ServerConnect.requestApi(
        "/auth/logout",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
        },
        null,
        { showNotification: false }
      )
      this.clearSessionData();
    } else {
      this.clearSessionData();
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearSession: () => {
    clearSession(dispatch);
  },
  goToPage: (page, params) => {
    goToPage(dispatch, page, params);
  },
  goToPrevPage: (reload, defaultPage, params) => {
    goToPrevPage(dispatch, reload, defaultPage, params);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);

import useLazyQuery from 'src/hooks/custom/useLazyQuery';

const GET_GROUP_COMPANY = `
  query getGroupCompany($startupId: ID!, $groupCompanyId: ID!) {
    getGroupCompany(startupId: $startupId, groupCompanyId: $groupCompanyId) {
      id
      requestBasket {
        id
        requestData {
          ... on RenewalData {
            ultimateParent {
              id
              name
              type
              mailingAddress {
                useRegisteredAddress
                street
                city
                state
                country {
                  code
                  alpha3
                  name
                }
                zipCode
                type
              }
              taxJurisdiction {
                code
                alpha3
                name
              }
              taxRegistrationNumber
            }
          }
        }
      }
    }
  }
`;

export const useGetGroupCompanyEconomicSubstance = (variables, config) => {
  const { manualQuery, loading, error, data, refetch } = useLazyQuery(GET_GROUP_COMPANY, variables, config);
  return { loading, error, data, refetch, manualQuery };
};

export default useGetGroupCompanyEconomicSubstance;

import { Field, FieldArray } from 'formik';
import _ from "lodash";
import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';
import useMutation from "../../../../hooks/custom/useMutation";
import ControllerInput from '../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import classes from "../../../../v1/containers/Forms/SteperForm.module.scss";
import RowAdd from '../../../forms/contents/RowAdd/RowAdd';
import Form from '../../../forms/Form/Form';
import IconSecondaryButtonContent from "../../../generic/Buttons/IconSecondaryButtonContent/IconSecondaryButtonContent";
import SubmitButtonSubForm from '../../../generic/Buttons/SubmitButtonSubForm/SubmitButtonSubForm';
import EditDetails from "../../../modules/clara/startups/Document/Modals/EditDocumentGenerate/EditDetails";
import SelectAddInput from "../../Select/SelectAddInput/SelectAddInput";
import TextInput from "../../Text/TextInput";
import { VerifyButton } from "../../VerifyDetails/VerifyDetails";
import useSession from "./../../../../modules/session/hooks/useSession";
import useGetGroupCompanies from './graphql/useGetGroupCompanies';
import Classes from './styles.module.scss';
const SelectCompany = ({label, onChange, list, tooltips, fieldArrayName, className, groupCompanySelected, handleAddListGroupCompany, onBlur, modal = false , errorMessage}) => {
    const [showAddGroupCompany, setAddGroupCompany] = useState(false);
    const [showSelect, setShowSelect] = useState(true);
    const ref = useRef();
    
	const { startupId } = useSession();

    // const listGroupCompany = useMemo(() =>{
    //     const listGC=_.map(list, as => ({ value: as, label: as.name, type: 'company' }));
    //     if (groupCompanySelected) {
	// 		return _.filter(listGC,gc => gc.value.id !== groupCompanySelected.id);
	// 	}
	// 	return listGC
    // }, [JSON.stringify(list), groupCompanySelected]);

	const {
		data: dataGroupCompanies,
		loading: loadingGroupCompanies,
		refetch: refetchGroupCompaies
	} = useGetGroupCompanies(
		{ startupId },
		{ skip: false, showSuccessNotification: false, showErrorNotification: false }
	);
	const dataGroupCompaniesList = useMemo(() => {
		return _.get(dataGroupCompanies, 'getGroupCompanies') || list
	}, [JSON.stringify(dataGroupCompanies)]);

	const listGroupCompany = useMemo(() =>{
		const listGC = _.map(dataGroupCompaniesList, as => ({ value: as, label: as.name, type: 'company' }));
		if (groupCompanySelected) {
			return _.filter(listGC,gc => gc.value.id !== groupCompanySelected.id);
		}
		return listGC
	}, [JSON.stringify(dataGroupCompaniesList), groupCompanySelected]);

	return (
        <FieldArray
            name={fieldArrayName}
            render={(arrayHelpers) => {
                const addList = _.isEmpty(_.get(arrayHelpers.form.values, fieldArrayName)) ? [
                    {
                        label: "Add all Companies",
                        onClick: (value) => {
                            setShowSelect(false);
                            _.forEach(listGroupCompany,groupCompany=>onChange(groupCompany.value,arrayHelpers))
                        },
                        type: 'company',
                    },
                    {
                        label: "Add new company",
                        onClick: (value) => {
                            setAddGroupCompany(true)
                        },
                        type: 'company',
                    },
                ] : [
                    {
                        label: "Add new company",
                        onClick: (value) => {
                            setAddGroupCompany(true);
                            setShowSelect(false);
                        },
                        type: 'company',
                    }
                ];

                return (<>
                    <div className={Classes.containerForm}>
                        <RowAdd
                            label={"Select company"}
                            asterisk={true}
                            classNameInput={modal ? Classes.inputModal : Classes.input}
                            input={
                                <SelectAddInput
                                    value={null}
                                    label={label}
                                    list={listGroupCompany}
                                    mode={'add'}
                                    refSelect={ref}
                                    handleFilterList={(dataGroupCompaniesList) => {
                                        return _.filter(dataGroupCompaniesList, st => !_.some(_.get(arrayHelpers.form.values, `${fieldArrayName}`), sign => sign.id === st.value.id))

                                    }}
                                    errorMessage={_.isEmpty(_.get(arrayHelpers.form.values, `${fieldArrayName}`,[]))?errorMessage:null}
                                    onBlur = {onBlur}
                                    placeholder={"Select from the list"}
                                    onChange={(value) => {
                                        if (value) {
                                            onChange(value,arrayHelpers)
                                            ref.current.clearValue()
                                        }
                                    }}
                                    menuProps={{
                                        addList: addList,
                                        propsList: [{ type: 'company' }]
                                    }}
                                    errorMessage={errorMessage}
                                />
                            }
                        />

                    </div>
                    {_.get(arrayHelpers.form.values, `${fieldArrayName}`,[]).map((company, index) => {
                        const isLast = _.get(arrayHelpers.form.values, `${fieldArrayName}`,[]).length === index + 1;
                        const handleDeleteElement = () => arrayHelpers.remove(index);

                        const handleSubmit=(values, type, updated)=> {
							arrayHelpers.form.setFieldValue(`${fieldArrayName}[${index}]`,{...values.company});
							arrayHelpers.form.setFieldValue(`${fieldArrayName}[${index}].verified`,true);

							if (updated) {
								refetchGroupCompaies();
							}
                        };

                        return (
                            <div className={modal ? Classes.ContainerRow : classes.ContainerRow}>
                                <VerifyCompanyDetails
                                    handleDeleteElement={handleDeleteElement}
                                    index={index}
                                    fieldArrayName={fieldArrayName}
                                    showAddAnother={isLast}
                                    handleSubmit={handleSubmit}
                                    tooltips={tooltips}
                                    modal={modal}
                                    company={company}
                                    groupCompany={company}
                                />
                                {!isLast && <hr className={classes.Separator} />}
                            </div>
                        )
                    })}
                    <>
                        {
                            showAddGroupCompany ? (
                                <AddNewGroupCompany
                                    handleUpdate={(value) => {
                                        arrayHelpers.push({ id: value.id, name:value.name ,showTopLevelOption: false});
                                        if (handleAddListGroupCompany)
                                            handleAddListGroupCompany(value);
                                    }}
                                    closeForm={() => setAddGroupCompany(false)}
                                />
							) : null
                        }
                    </>
                </>)
            }}
        />
	)
};

const  VerifyCompanyDetails = ({fieldArrayName, index, handleDeleteElement, handleSubmit, tooltips, company,groupCompany }) => {

	const [open, setOpen] = useState(false);
    const handleOpenModal = () => {
        setOpen(true)
    };

    const onSubmit = (values, type, updated) => {
        handleClose();
        handleSubmit(values, type, updated)
    };

    const handleClose = () => {
        setOpen(false)
    };

    return (<div className={Classes.containerForm}>
        <div style={{display:'flex','flex-direction':'row'}}>
            <h1>{company.name} </h1>
			<div className={Classes.deleteButton} onClick={() => handleDeleteElement(index)}>
				<IconSecondaryButtonContent color={'primary'} icon={'Delete-bin'} />
			</div>
        </div>
        <div className={classes.buttonsDetails}>
        <Field name={`${fieldArrayName}[${index}].verified`}>
            {({ field, form }) => (
                <VerifyButton
					onClick={handleOpenModal}
					field={field}
					isVerified={field.value}
					showError={_.get(form,`errors.${fieldArrayName}[${index}].verified`)}
					label={`Company details`}
				/>
            )}
        </Field>
        <EditDetails
			nameHandle={'company'}
			handleSubmit={onSubmit}
			title={'Company'}
			initialValues={{company}}
			tooltips={tooltips}
			type={'companyDetails'}
			handleClose={handleClose}
			open={open}
            groupCompany={groupCompany}
		/>
    </div>
    </div>)
};

const mutation = `
                    mutation upsertComponentGroupCompany(
                                   $startupId: ID!
                                 $groupCompany: String!
                                   ) {
                        upsertComponentGroupCompany(startupId: $startupId, groupCompany: $groupCompany) {
                            id
                            name
                        }
                      }`;   

export const AddNewGroupCompany = ({ handleUpdate, tooltip, idGroupCompany, closeForm }) => {
    
    const { startupId } = useSession();
    const [data, setData] = useState(null);

    const handleData = (data) => {
        handleUpdate(data.upsertComponentGroupCompany);
        setData(data)
    };

    const [mutationCall, loading] = useMutation(mutation, { onCompleted: handleData });
    const handleSubmit = (values) => {
        const variables = {
            startupId,
            groupCompany:values.name
        };

        mutationCall(variables)
    };

    useEffect(() => {
        if (loading === false && data) {
            closeForm()
        }
    }, [loading, data]);

    return (
        <Form initialValues={{ data: 'asdasdasd' }}
              schema={Yup.object().shape({
                  name: Yup.string().required("Name is a required field"),
              })}
              loading={loading}
              onSubmit={handleSubmit}

        >
            <div className={Classes.containerForm}>
                <RowAdd
                    label={"Name"}
                    asterisk={true}
                    classNameInput={Classes.input}
                    input={
                        <ControllerInput
                            as={TextInput}
                            name={'name'}
                            classNameError={Classes.errorForm}
                            placeholder={"Name"}
                        />
                    }
                />

                <div className={Classes.containerButtons}>
                    <button className={`genericWhite`} style={{ marginRight: '1rem' }} onClick={closeForm}>
                        Cancel
                    </button>
                    <SubmitButtonSubForm onClick={handleSubmit}>
                        Save
                    </SubmitButtonSubForm>
                </div>
            </div>
        </Form>
    )
};

export default SelectCompany;

import useMutation from "src/hooks/custom/useMutation";
import useSession from "src/modules/session/hooks/useSession";

export const UPSERT_CONFIRM_AUTHORISED_SIGNATORY = `
mutation upsertRenewalData(
    $startupId: ID!
    $groupCompanyId: ID!
	$renewalData: RenewalDataInput
  ) {
    upsertRenewalData(
      startupId: $startupId
      groupCompanyId: $groupCompanyId
      renewalData: $renewalData
    ) {
      id
  }
}
`;

const useConfirmADGMAuthorisedSignatory = () => {
  const { startupId } = useSession();
  const [mutation] = useMutation(UPSERT_CONFIRM_AUTHORISED_SIGNATORY, {
    showSuccessNotification: true,
  });

  return async (groupCompanyId) => {
    const data = {
      variables: {
        startupId,
        groupCompanyId,
        renewalData: {
          companyTasksDetails: {
            areAuthorisedSignatoriesConfirmed: true,
          },
        },
      },
    };
    return await mutation(data);
  };
};

export default useConfirmADGMAuthorisedSignatory;

import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import Form from "src/components/forms/Form/Form";
import AddressInput from "src/components/forms/modules/startups/individualStakeholder/AddressInput";
import SelectMailingAddressTypeInput from "src/components/inputs/Select/MultiSelectInput";
import GroupCompany from "src/components/modules/clara/startups/entities/classes/GroupCompany";
import Button from "src/modules/generic/components/Buttons/Button";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import TemplateLabel from 'src/modules/generic/components/Inputs/LabelInput';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import {
  addAssistantText,
  clearAssistatText,
} from "src/modules/generic/store/action";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import * as Yup from "yup";

function EditAddressesCayman() {
  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    let formattedValues = _.cloneDeep(
      context?.initialData?.groupCompany?.groupCompany
    );
    if (formattedValues?.registeredAddress?.country?.code) {
      formattedValues.registeredAddress.country =
        formattedValues?.registeredAddress?.country?.code;
    } else {
      delete formattedValues.registeredAddress;
    }
    if (formattedValues?.mailingAddress?.country?.code) {
      formattedValues.mailingAddress.country =
        formattedValues?.mailingAddress?.country?.code;
    } else {
      delete formattedValues.mailingAddress;
    }
    return formattedValues;
  }, [context]);

  const managedByClara = useMemo(() => {
    return context?.initialData?.groupCompany?.groupCompany.managedByClara;
  }, [context]);

  const disabledRegisterAddress = useMemo(() => {
    const groupCompanyClass = new GroupCompany(context?.initialData?.groupCompany?.groupCompany);
    return groupCompanyClass.isManagedByClara() || groupCompanyClass.isCayman();
  }, [context]);

  const handleCancel = () => {
    cancel();
  };

  const handleSubmit = async (values) => {
    next(values);
  };

  useEffect(() => {
    dispatch(addAssistantText("The Registered Address of the company is the Registered Agent's address, CO Services Cayman Limited."));
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch, context]);

  const schema = useMemo(() => {
    const baseSchema = {
      mailingAddress: Yup.object().shape({
        type: Yup.string().required('This field is required').typeError('This field is required'),
        country: Yup.string().required('This field is required').typeError('This field is required'),
        street: Yup.string().required('This field is required'),
        city: Yup.string().required('This field is required'),
        state: Yup.string().nullable(),
        zipCode: Yup.string().nullable(),
      }),
    };
    if (!disabledRegisterAddress) {
      baseSchema.registeredAddress = Yup.object().shape({
        country: Yup.string().required("This field is required").nullable(),
        street: Yup.string().required("This field is required").nullable(),
        city: Yup.string().required("This field is required").nullable(),
        state: Yup.string().nullable(),
        zipCode: Yup.string().nullable(),
      });
    }
    return Yup.object().shape(baseSchema);
  }, [disabledRegisterAddress]);  

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: "Edit addresses",
        }}
        buttons={{
          submit: (
            <SubmitButton>
              {translate("MODULES.CLARA.BTN_CONFIRM")}
            </SubmitButton>
          ),
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("MODULES.CLARA.BTN_CANCEL")}
            </Button>
          ),
        }}
      >
        <AddressInput
          name="registeredAddress"
          label={{
            country: translate("MODULES.CLARA.ADDRESS_REGISTERED_LABEL"),
          }}
          disabled={disabledRegisterAddress}
          zipCodeOptional
          stateOptional
          isRequired
        />
        <div>&nbsp;</div>
        <TemplateLabel label="Mailing address type" isRequired>
          <ControllerInput
            render={SelectMailingAddressTypeInput}
            name="mailingAddress.type"

          />
        </TemplateLabel>
        <AddressInput
          name="mailingAddress"
          label={{ country: "Mailing address" }}
          banner={managedByClara}
          zipCodeOptional
          isRequired
        />
        <div>&nbsp;</div>
      </TemplateWizard>
    </Form>
  );
}

export default EditAddressesCayman;
import _ from 'lodash';
import useMachine from '../../../../../generic/context/MachineContext/useMachine';
import React, { useEffect } from 'react';
import Button from "../../../../../generic/components/Buttons/Button";
import useTranslate from '../../../../../generic/hooks/useTranslate';
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import useModal from "../../../../../generic/hooks/useModal";
import Typography from "../../../../../../modules/generic/components/Texts/Typography";
import { useSelector, useDispatch } from 'react-redux';
import { clearAssistatText, addAssistantText } from "../../../../../generic/store/action";
import cls from "./ConfirmRenewalAndBilling.module.scss";

/**
 * Step confirm subscription data
 * @param {*} param0
 * @returns
 */

function ConfirmRenewalAndBilling() {
  const { next, context, prev } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const { closeModal: onClose } = useModal();
  const session = useSelector(state => _.get(state, 'session'));
  const activeSubscriptionData = _.get(session ,"startup.activeSubscriptionPlan.activeSubscriptionData", {});  
  const renewalState = _.get(context, "values.renewal");
  const billingState = _.get(context, "getSubscriptionData.billing.status");  

  useEffect(() => {
    handleTooltips();
    return () => {
      dispatch(clearAssistatText());
    }
  }, [renewalState, billingState]);

  const handleTooltips = () => {
    dispatch(clearAssistatText());
    if (renewalState && billingState) {
      dispatch(addAssistantText(translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_1.TOOLTIP1')));
    }
    if (renewalState && billingState === false) {
      dispatch(addAssistantText(translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_1.TOOLTIP1')));
    }
    if (renewalState === false && billingState) {
      dispatch(addAssistantText(translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_2.TOOLTIP1')));
    }
    if (renewalState === false && billingState === false) {
      dispatch(addAssistantText(translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_2.TOOLTIP1')));
    }
  }

  const handleCancel = () => {
    onClose();
    clearAssistatText();
  }

  const handlePrevious = () => {
    prev();
  }  

  const handleCompleteSubmit = (values) => {
    next(values);
  }

  const posibleResults = () => {
    const actualRenewal = _.get(context, "getSubscriptionData.renewal.status");
    const newRenewal = _.get(context, "values.renewal");
    
    if (actualRenewal === newRenewal) {
      if (billingState) {
        return (
          <>
            <Typography variant="h5">
              {translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_2.PAY_ANUAL')}
            </Typography>
            <Typography variant="h5">
              {translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_2.PAY_ANUAL_INFO', {
                date: _.get(context, "getSubscriptionData.renewal.date",""),
                currency: _.get(context, "getSubscriptionData.billing.annualyMoney",""),
                amount: _.get(context, "getSubscriptionData.billing.annualyAmount",""),
                vat: " " +_.get(context, "getSubscriptionData.billing.annualyVat",""),
                last: _.get(activeSubscriptionData, "bankCard.last4", ""),
              })}
            </Typography>
          </>
        );
      }
      if (billingState === false) {
        return (
          <>
            <Typography variant="h5">
              {translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_2.PAY_MONTH')}
            </Typography>
            <Typography variant="h5">
              {translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_2.PAY_MONTH_INFO', {
                date: _.get(context, "getSubscriptionData.renewal.date",""),
                currency: _.get(context, "getSubscriptionData.billing.monthlyMoney",""),
                amount: _.get(context, "getSubscriptionData.billing.monthlyAmount",""),
                vat: " " +_.get(context, "getSubscriptionData.billing.monthlyVat",""),
                last: _.get(activeSubscriptionData, "bankCard.last4", ""),
              })}
            </Typography>
            <Typography variant="h5">
              {translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_2.BILLING_MONTH_END')}
            </Typography>
          </>
        );
      }
    } else {
      if (renewalState && billingState) {
        return (
          <>
            <Typography variant="h5">
              {translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_2.RENEWAL_ON')}
            </Typography>
            <Typography variant="h5">
              {translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_2.BILLING_ANUAL', {
                date: _.get(context, "getSubscriptionData.renewal.date",""),
                currency: _.get(context, "getSubscriptionData.billing.annualyMoney",""),
                amount: _.get(context, "getSubscriptionData.billing.annualyAmount",""),
                vat: " " +_.get(context, "getSubscriptionData.billing.annualyVat",""),
                last: _.get(activeSubscriptionData, "bankCard.last4", ""),
              })}
            </Typography>
          </>
        );
      }
      if (renewalState && billingState === false) {
        return (
          <>
            <Typography variant="h5">
              {translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_2.RENEWAL_ON')}
            </Typography>
            <Typography variant="h5">
              {translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_2.BILLING_MONTH', {
                date: _.get(context, "getSubscriptionData.renewal.date",""),
                currency: _.get(context, "getSubscriptionData.billing.monthlyMoney",""),
                amount: _.get(context, "getSubscriptionData.billing.monthlyAmount",""),
                vat: " " +_.get(context, "getSubscriptionData.billing.monthlyVat",""),
                last: _.get(activeSubscriptionData, "bankCard.last4", ""),
              })}
            </Typography>
            <Typography variant="h5">
              {translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_2.BILLING_MONTH_END')}
            </Typography>
          </>
        );
      }
      if (renewalState === false && billingState) {
        return (
          <>
            <Typography variant="h5">
              {translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_2.RENEWAL_OFF', {
                expiry: _.get(context, "getSubscriptionData.renewal.date",""),
              })}
            </Typography>
            <Typography variant="h5">
              {translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_2.BEFORE_ENDS')}
            </Typography>
            <ul className={cls.sizeU}>
              <li className={cls.sizeListSmall}>
                <Typography variant="h5">{translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_2.FEATURE1')}</Typography>
              </li>
              <li className={cls.sizeListSmall}>
                <Typography variant="h5">{translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_2.FEATURE2')}</Typography>
              </li>
              <li className={cls.sizeListSmall}>
                <Typography variant="h5">{translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_2.FEATURE3')}</Typography>
              </li>
            </ul>
          </>
        );
      }
      if (renewalState === false && billingState === false) {
        return (
          <>
            <Typography variant="h5">
              {translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_2.RENEWAL_OFF', {
                expiry: _.get(context, "getSubscriptionData.renewal.date",""),
              })}
            </Typography>
            <Typography variant="h5">
              {translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_2.BEFORE_ENDS_MONTHLY')}
            </Typography>
            <Typography variant="h5">
              {translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_2.REGULAR_LIKE')}
            </Typography>
            <ul className={cls.sizeU}>
              <li className={cls.sizeListSmall}>
                <Typography variant="h5">{translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_2.FEATURE1')}</Typography>
              </li>
              <li className={cls.sizeListSmall}>
                <Typography variant="h5">{translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_2.FEATURE2')}</Typography>
              </li>
              <li className={cls.sizeListSmall}>
                <Typography variant="h5">{translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_2.FEATURE3')}</Typography>
              </li>
            </ul>
          </>
        );
      }
    }
  }

  return (
    <TemplateWizard
      props={{
        title: translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_2.SUBTITLE'),
        subTitle: translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_1.TITLE')
      }}
      buttons={{
        cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
        previous: <Button onClick={handlePrevious} variant="secondary">{translate('GENERIC_BUTTON_PREVIOUS')}</Button>,
        submit: <Button onClick={handleCompleteSubmit} variant="primary">{translate('GENERIC_BUTTON_CONFIRM')}</Button>,
      }}
    >
      {posibleResults()}
    </TemplateWizard>
  );
}

export default ConfirmRenewalAndBilling;
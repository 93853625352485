import _ from "lodash";
import React, { FC } from "react";
import Scrollbar from "../../../../../components/generic/Scrollbar/Scrollbar";
import classesModule from "./classes.module.scss";
import Typography from "../../../components/Texts/Typography";
import Box from '@mui/material/Box';

export interface TemplateWizardInfoProps {
  props: {
    skeleton: React.ReactNode;
    subTitle: string;
    title: string;
    image: string;
    isFormationCompany: boolean;
    isRenewalImage: boolean;
  };
  buttons: {
    previous?: React.ReactNode;
    cancel?: React.ReactNode;
    submit?: React.ReactNode;
    anotherLeft?: Array<React.ReactNode>;
    anotherRight?: Array<React.ReactNode>;
  };
  children: React.ReactNode;
  classNameBotonera;
}

//scc setup
const frame_No_Company = classesModule.GridTemplateWizardInfoPreview;
const frame_Yes_Company = classesModule.GridTemplateWizardInfoPreviewCompany;
// const frame_view_company = classesModule.ViewCompanyFomation;
// const frame_view = classesModule.View;

const TemplateWizardInfo: FC<TemplateWizardInfoProps> = ({
  props,
  buttons,
  children,
  classNameBotonera,
}) => {
  const { title, subTitle, image, isFormationCompany, isRenewalImage = false } = props;
  return (
    <div className={`${classesModule.TemplateWizard}`}>
      <div>
        <Scrollbar className={`${classesModule.Scrollbar}`}>
          <div className={`${classesModule.infoContent}`}>
            {(subTitle || title) && (
              <div className={`${classesModule.Header}`}>
                {subTitle && (
                  <Typography
                    component={"label"}
                    variant={"body"}
                    color={"blue"}
                  >
                    {subTitle}
                  </Typography>
                )}
                {title && (
                  <Typography
                    component={"h1"}
                    color={"black"}
                    className={`${classesModule.Spacer}`}
                  >
                    {title}
                  </Typography>
                )}
              </div>
            )}
            <div
              className={
                isFormationCompany ? frame_Yes_Company : frame_No_Company
              }
            >
              <div>{children}</div>
              <Box
                sx={isFormationCompany
                  ? {
                    width: "267px",
                  }
                  : {
                    marginTop: "5.3rem",
                    height: "360px",
                    width: "320px",
                    position: "absolute",
                    bottom: isRenewalImage ? "13px" : "0",
                    right: "6px"
                  }
                }
              >
                <img src={image} alt="detail" className={classesModule.detailImage} />
              </Box>
            </div>
          </div>
        </Scrollbar>
        <div className={`${classesModule.Botonera} ${classNameBotonera}`}>
          <div className={classesModule.LeftButtons}>
            {buttons.previous}
            {_.map(buttons.anotherLeft, (button) => {
              return <React.Fragment>{button}</React.Fragment>;
            })}
          </div>
          <div className={classesModule.RightButtons}>
            {_.map(buttons.anotherRight, (button) => {
              return <React.Fragment>{button}</React.Fragment>;
            })}
            {buttons.cancel}
            {buttons.submit}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateWizardInfo;

import React, { FC, useMemo, useState } from "react";
import classesModule from './InvoicesList.module.scss';
import Table from '../../../../components/Table/Table.js';
import _ from "lodash";
import DocumentInvoice from "../../../../views/modules/startups/startup/startupView/components/Invoices/Components/DocumentInvoice";
import DocumentPreviewModalStyle from "../../../../components/generic/Documents/DocumentPreview/DocumentPreviewModalStyle";
import moment from "moment";
import useTranslate from "../../../../modules/generic/hooks/useTranslate";
import Button from "src/modules/generic/components/Buttons/Button";
import useModal from "src/modules/generic/hooks/useModal";
import ModalPayManualInvoice from "src/modules/startup/PayManualInvoiceModal";
import useFormat from "../../../generic/hooks/useFormat";

export interface InvoicesListProps {
  invoicesList: any;
}

const InvoicesList: FC<InvoicesListProps> = ({
  invoicesList
}: InvoicesListProps) => {
  const [selectDocument, setSelectDocument] = useState(null);
  const { translate } = useTranslate();
  const { openModal } = useModal();
  const { moneyFormat} = useFormat();

  const handlePayManualInvoice = (row) => {
    openModal(
      ModalPayManualInvoice,
      { initialValues: { invoice: row.original } },
      "MODAL_PAY_MANUAL_INVOICE"
    );
  } 

  const columns = useMemo(() => [
    {
      Header: ' ',
      columns: [
        {
          Header: <span className={classesModule.tableHeader}>{translate("STARTUP_VIEW_INVOICES_TABLE_TITLE_DATE")}</span>,
          accessor: 'startDate',
          Cell: ({ row }) => {
            return (
              <div>{moment(row.original.startDate).format("DD MMM YYYY")}</div>
            )
          }
        },
        {
          Header: <span className={classesModule.tableHeader}>{translate("STARTUP_VIEW_INVOICES_TABLE_TITLE_TYPE")}</span>,
          accessor: 'type',
        },
        {
          Header: <span className={classesModule.tableHeader}>{translate("STARTUP_VIEW_INVOICES_TABLE_TITLE_CATEGORY")}</span>,
          accessor: 'category',
          Cell: ({ row }) => {
            return (
              <div>{_.get(row, "original.category", "")}</div>
            )
          }
        },
        {
          Header: <span className={classesModule.tableHeader}>{translate("STARTUP_VIEW_INVOICES_TABLE_TITLE_PAYMENT_METHOD")}</span>,
          accessor: 'paymentMethod',
          Cell: ({ row }) => {
            return (
              <div>{_.get(row, "original.paymentMethod", "")}</div>
            )
          }
        },
        {
          Header: <span className={classesModule.tableHeader}>{translate("STARTUP_VIEW_INVOICES_TABLE_TITLE_AMOUNT")}</span>,
          accessor: 'transaction',
          Cell: ({ row }) => {
            return (
              <div>{_.get(row, "original.currency", "") + " " + moneyFormat(_.get(row, "original.amount"))}</div>
            )
          }
        },
        {
          Header: <span className={classesModule.tableHeader}>{translate("STARTUP_VIEW_INVOICES_TABLE_TITLE_NAME")}</span>,
          accessor: 'subscriptionPlan',
          Cell: ({ row }) => {
            return (
              <DocumentInvoice data={_.get(row, "original.file")} handleOpenViewer={setSelectDocument} />
            )
          }
        },
        {
          Header: () => <div className={classesModule.headerColumnTable} />,
          accessor: "actions",
          Cell: ({ row }) => {
            return (
              <div className={classesModule.viewContainer}>
                {_.get(row, "original.category", "") === "Manual" && _.get(row, "original.status", "") === "PENDING" && (
                  <Button variant="card" onClick={() => handlePayManualInvoice(row)}>Pay invoice</Button>
                )}
              </div>
            );
          },
        },
      ],
    }
  ], []);

  return (
    <>
      <Table
        columns={columns}
        data={invoicesList}
        className={{
          table: classesModule.Table
        }}
      />
      {!!selectDocument &&
        <DocumentPreviewModalStyle
          title={selectDocument.name.split(".")[0]}
          open={!!selectDocument}
          document={{file:selectDocument}}
          handleClose={() => setSelectDocument(null)}
        />
      }
    </>
  );
}

export default InvoicesList;
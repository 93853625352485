import useSession from 'src/modules/session/hooks/useSession';
import useGetPINCAPrices from "src/views/modules/startups/renewal/pincasPrice/queries/useGetPINCAPrices";
import useGetGroupCompanyAndStakeholders from './graphql/useGetGroupCompany';
import useUpdateGroupCompanyDetails from './graphql/useUpdateGroupCompanyDetails';
import moment from "moment";

const useServices = ({ groupCompanyId }) => {
  const { query } = useGetGroupCompanyAndStakeholders({});
  const { startupId } = useSession();
  const { manualQuery: getPINCAPrices } = useGetPINCAPrices({ variables: { filters: { jurisdictionTypes: ['ADGM'] } } });
  const mutation = useUpdateGroupCompanyDetails();

  const initialData = async () => {
    const data = await query({ startupId, groupCompanyId });
    return { groupCompany: data?.groupCompany };
  };

  const pincasData = async () => {
    const pincasData = await getPINCAPrices();
    return { pincasData };
  }

  const SaveStep = (context) => {
    const groupCompanyData = context?.GeneralDetails;
    const variables = {
      startupId,
      groupCompanyId,
      groupCompany: {
        ...groupCompanyData
      }
    };
    const newDate = moment(variables.groupCompany?.nameBecameEffectiveOn).format("YYYY-MM-DD");
    variables.groupCompany.nameBecameEffectiveOn = newDate;
    return mutation({ variables });
  };

  return { initialData, pincasData, SaveStep };
}

export default useServices;

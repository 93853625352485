import useMutation from "src/hooks/custom/useMutation";

export const UPSERT_GROUP_COMPANY_DETAILS = `
mutation upsertGroupCompany(
    $startupId: String
    $groupCompanyId: String
	$groupCompany: GroupCompanyInputType
  ) {
    upsertGroupCompany(
      startupId: $startupId
      groupCompanyId: $groupCompanyId
      groupCompany: $groupCompany
    ) {
      id
  }
}
`;

const useUpdateGroupCompanyDetails = () => {
    const [mutation] = useMutation(
        UPSERT_GROUP_COMPANY_DETAILS,
        { showSuccessNotification: true }
    );
    return mutation;
};

export default useUpdateGroupCompanyDetails;

import React from "react"
import TextInput from "src/v1/containers/Forms/Inputs/TextInput"
import ClaraSelect from "../../ClaraSelect/ClaraSelect"
import classes from "../styles.module.scss"
import { Field } from "formik"
import ClaraRadioWithButton from "../../ClaraRadioWithButton/ClaraRadioWithButton"
import FormikHelper from "src/v1/utils/formikHelper"
import get from 'lodash/get'
const VestingTermsFields = ({isDisabled,tooltips,entityLabel}) =>{

    const handleOnChangeIsThereCliff = (v, form) => {
        if (!v) {
            FormikHelper.setValueInTheCorrectPosition(`${entityLabel}.share[0].vestingTerms.cliffPeriod`, form, null);
            FormikHelper.setValueInTheCorrectPosition(`${entityLabel}.share[0].vestingTerms.isThereCliff`, form, v);
        }
    };

    return (<>
    <Field name={`${entityLabel}.share[0].vestingTerms.vestingPeriod`}>
    {({ field, form }) => (
        <div className={`${classes.ContainerRow}`}>
            <TextInput
                field={field}
                form={form}
                disabled={isDisabled}
                label={`Vesting period`}
                required={true}
                rightLabel={"Months"}
                tooltip={tooltips["Vesting Period"]}
            /></div>
    )}
</Field>
<Field name={`${entityLabel}.share[0].vestingTerms.vestingFrequency`}>
    {({ field, form }) => {
        return (
            <div className={`${classes.ContainerRow} ${classes.Select}`}>
                <ClaraSelect
                    label={`Vesting frequency `}
                    required={true}
                    field={field}
                    form={form}
                    disabled={isDisabled}
                    mode={`classic`}
                    placeholder={`Select from the list`}
                    tooltip={tooltips[`Vesting Frequency`]}
                    lists={[
                        {
                            id: `MONTHLY`,
                            label: `Monthly`,
                        },
                        {
                            id: `QUARTERLY`,
                            label: `Quarterly`,
                        },
                        {
                            id: `ANNUALLY`,
                            label: `Annually`,
                        }
                                                                        ]}
                                        />
                                    </div>
                                )
                            }}
                        </Field> 
                        <div className={classes.alignWithInputDate}>
                            <Field name={`${entityLabel}.share[0].vestingTerms.isThereCliff`}>
                                {({ field, form }) => (
                                    <ClaraRadioWithButton
                                        required={true}
                                        label={`Is there a cliff period? `}
                                        field={field}
                                        disabled={isDisabled}
                                        form={form}
                                        tooltip={tooltips[`Cliff Period`]}
                                        changeParams={field.name}
                                        options={[{ value: true, label: `Yes` }, { value: false, label: `No` }]}
                                        classes={{ root: classes.alignWithInputDate, input: classes.alignWithInputDateButton }}
                                        onChange={(v) => handleOnChangeIsThereCliff(v, form)}
                                    />
                                )}
                            </Field></div>
                        <Field name={`${entityLabel}.share[0].vestingTerms.cliffPeriod`}>
                            {({ field, form }) =>
                                get(form, `values.${entityLabel}.share[0].vestingTerms.isThereCliff`) ?
                                    (
                                        <div className={`${classes.ContainerRow}`}>
                                        <TextInput
                                            field={field}
                                            form={form}
                                            disabled={isDisabled}
                                            label={`How long is the cliff period?`}
                                            required={true}
                                            rightLabel={"Months"}
                                        /></div>
                                    )
                                    : null}
                        </Field>
</>)
}
export default VestingTermsFields;
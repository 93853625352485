import _ from "lodash";
import { useMemo } from "react";
import useTranslate from "./../../../modules/generic/hooks/useTranslate";
import hash from "object-hash";
import useSession from "../../../modules/session/hooks/useSession";
const useTasks = (allTasks, configData, keyTranslate, getParams) => {
  const { translate } = useTranslate();
  const { startup } = useSession();

  const handleStatus = (status, onClick) => {
    let statusVariation;
    switch (status) {
      case "COMPLETED":
        statusVariation = "checked";
        break;
      case "READY":
        statusVariation = "ready";
        break;
      case "IN_PROGRESS":
        statusVariation = "inprogress";
        break;
      case "PENDING":
        statusVariation = "addTask";
        break;
      default:
        if (onClick) {
          statusVariation = "addTask";
        } else {
          statusVariation = "incompleted";
        }
        break;
    }
    return statusVariation;
  };

  const dataTasks = useMemo(() => {
    let result = {};
    let filter = {};
    if (!!allTasks) {
      _.forEach(configData, (tasks, group) => {
        _.forEach(tasks, (task) => {
          if (!result[group]) result[group] = { completed: 0, tasks: [] };
          let resultFilter = _.filter(allTasks, "code").filter((data) => {
            return data.code === task.code;
          });
          if (resultFilter.length > 0) {
            _.forEach(resultFilter, (taskserver) => {
              const taskserverHash = hash(taskserver);
              if (!!!filter[taskserverHash]) {
                filter[taskserverHash] = true;
                let params = {};
                if (getParams) {
                  params = getParams(taskserver, task?.titleParams ?? {});
                }
                const taksInformation = {
                  code: taskserver.code,
                  status: taskserver.status,
                  params: { ...taskserver.params, ...params },
                };
                const valueHash = hash(taksInformation);
                if (taksInformation.status === "COMPLETED") {
                  result[group].completed = result[group].completed + 1;
                }
                const exist = _.find(
                  result[group].tasks,
                  (task) => task.hash === valueHash
                );
                if (!exist) {
                  result[group].tasks.push({
                    hash: valueHash,
                    code: task.code,
                    onClick:
                      taksInformation.status === "PENDING"
                        ? task.onClick
                        : null,
                    params: taksInformation.params,
                    status: handleStatus(
                      taksInformation.status,
                      task.onClick,
                      group
                    ),
                    name: translate(
                      `MODULES.ASSISTANCEV4.${keyTranslate}.${task.code}`,
                      taksInformation.params
                    ),
                    onDelete:
                      taksInformation.status === "PENDING"
                        ? task.onDelete
                        : null,
                  });
                }
              }
            });
          }
        });
      });
    }
    return result;
  }, [JSON.stringify(allTasks), JSON.stringify(_.get(startup, "goals", []))]);

  return { dataTasks };
};
export default useTasks;

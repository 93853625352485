import GOALS from 'src/modules/Onboarding/constants/goals';
import useUpsertStartup from 'src/modules/Onboarding/hooks/services/upsertStartupProfile';
import _ from "lodash"
import useVerifyCodeAccelerator from 'src/modules/Onboarding/hooks/services/useVerifyCode';
import notification from 'src/v1/utils/notification'

const useServices = () => {

  const { verifyCode } = useVerifyCodeAccelerator()
  const { mutation: createStartup } = useUpsertStartup({});

  return {

    verifyCode: async (context, event) => {
      try {
        const code = context?.AcceleratorStep?.verificationCode
        const accelerator = await verifyCode({ code });
        if (!accelerator) throw new Error('Verification failed. Check the code and try again.')
        return accelerator;
      } catch (e) {
        notification.sendNotification(e.message, "error", 2500);
      }
    },

    createStartup: async (context, event) => {
      // this change is made because the goal of doumentos has to send the same as build_cap_talbe, but they are different circuits.
      // BUILD_CAP_TABLE is the default goal.
      const goal = context?.GoalStep?.goal === GOALS.GO_TO_DOCUMENTS ? [context?.GoalStep?.goal,GOALS.BUILD_CAP_TABLE] : [context?.GoalStep?.goal];
      const startupData = {
        name: context?.StartupNameStep?.name,
        website: context?.TellUsAboutStep?.website,
        goalCodes: goal,
        initialOption: context?.GoalStep?.initialOption,
        acceleratorCode: context?.verifyCode?.code,
        primaryLocation: context?.TellUsAboutStep?.location,
        founders: _.map(context?.AddFounderStep?.founders, (founder) => ({
          fullName: founder,
        }))
      }
      const startup = await createStartup({ startupData }, { showSuccessNotification: false });
      return startup;
    }
    
  };
}
export default useServices;
// import React, { useMemo, useState } from 'react';
import React from 'react';
import Form from "src/components/forms/Form/Form";
import TemplateWizard from "src/modules//generic/templates/Modal/TemplateWizard";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import Typography from "src/modules/generic/components/Texts/Typography";
import Button from "../../../../generic/components/Buttons/Button";
import useMachine from '../../../../generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import ControllerInput from "../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import * as Yup from 'yup';
import FileUpload from 'src/modules/generic/components/Inputs/FileUpload';
import useUploadProofOfPayment from 'src/modules/checkout/graphql/payment/mutation/useUploadProofOfPayment';
import useSession from 'src/modules/session/hooks/useSession';
import useUpsertFormationCompany from "src/modules/startup/GroupCompany/graphql/mutations/useUpsertFormationCompany";
import GroupCompany from 'src/components/modules/clara/startups/entities/classes/GroupCompany';
// import { contactClaraConcierge } from 'src/v1/graphql/assistant';
import useSetPrimaryContact from "src/modules/startup/GroupCompany/graphql/mutations/useSetPrimaryContact";

/**
 * Step to set pay data for manual invoice
 * @param {*} param0
 * @returns
 */
function PaymentFormStep({ openParam }) {

  const { next, context, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const [uploadProofPayment] = useUploadProofOfPayment();
  const [upsertGroupCompany] = useUpsertFormationCompany()
  const { startupId } = useSession();
  const entityId = context?.initialData?.groupCompanyId;
  // const jurisdictionType = context?.initialData?.company?.jurisdictionType;
  const { authorisedSignatories } = context?.ChooseAPayeeFormStep;
  const [setPrimaryContact] = useSetPrimaryContact(
    { showSuccessNotification: false},
    {}
  );

  const handlePrev = (values) => {
    prev();
  }

  const handleCancel = (values) => {
    cancel();
  }

  const handleCompleteSubmit = async (values) => {
    
    const groupCompanyClass = new GroupCompany(context?.initialData?.company);
    
    if (groupCompanyClass.isADGM()) {
      if (groupCompanyClass.isManagedByClara()) {
        await setPrimaryContact({
          variables: {
            startupId,
            groupCompanyId: entityId,
            primaryContactId: authorisedSignatories,
          },
        });
      } else {
        await upsertGroupCompany({
          startupId,
          groupCompanyId: entityId,
          groupCompanyData: {
            formationData: {
              primaryContact: authorisedSignatories
            }
          }
        })
      }
    }
    // if (jurisdictionType === 'ADGM') {
    //   await upsertGroupCompany({
    //     startupId,
    //     groupCompanyId: entityId,
    //     groupCompanyData: {
    //       formationData: {
    //         primaryContact: authorisedSignatories
    //       }
    //     }
    //   })
    // }
    await uploadProofPayment({ variables: { startupId, entityId, fileId: values?.proofOfPayment[0]?.id } })
    next({ values, typePayment: 'BANK_TRANSFER' });
  }

  // const [isZipCodeRequired, setIsZipCodeRequired] = useState(false);

  // const validateZipCode = (zipCode) => {
  //   if (isZipCodeRequired) {
  //     return zipCode && zipCode !== ""
  //   }
  //   return true;
  // };

  // const errorCard = useMemo(() => {
  //   return context?.errorCard;
  // }, [context]);

  const schema = Yup.object().shape({
    proofOfPayment: Yup.array().min(1, 'This is Field Required').required('Required'),
  })

  return (
    <Form
      defaultValues={{}}
      schema={schema}
      onSubmit={handleCompleteSubmit}
      optionsForm={{ mode: "onChange" }}
      onlySendDirty={false}
    >
      <TemplateWizard
        props={{
          title: "Upload proof of payment",
          skeleton: null
        }}
        buttons={{
          previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton>
            <Typography variant="body" color={"white"} component={"p"} >Next</Typography>
          </SubmitButton>
        }}
      >
        <div style={{ gridTemplateColumns: "100%", gap: "3rem", display: "grid" }}>
          <Typography variant="body" color={"black"} component={"p"} >
            To confirm your payment, please upload a bank receipt or a SWIFT document.
          </Typography>
        </div>
        <div style={{ gridTemplateColumns: "240px auto", gap: "3rem", display: "grid" }}>
          <Typography variant="body" color={"black"} component={"p"} >
            Upload document *
          </Typography>
          <ControllerInput
            render={FileUpload}
            defaultValue={[]}
            name="proofOfPayment"
          />
        </div>
      </TemplateWizard>
    </Form>
  );
}

export default PaymentFormStep;


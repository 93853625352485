import React, { FC } from 'react'
import TemplateWizard from "../../../../generic/templates/Modal/TemplateWizard";
import { StepProps } from "../../../../generic/components/Wizard/Step";
import useTranslate from "../../../../generic/hooks/useTranslate";
import useCustomMachine from "../../../../generic/hooks/useCustomMachine";
import Button from "../../../../generic/components/Buttons/Button";
import { clearAssistatText } from "../../../../generic/store/action";
import FormLinkedInURL from "../../Forms/FormLinkedInUrl";
import { useParams } from "react-router-dom";
import useSession from "../../../../session/hooks/useSession";
import useModal from "../../../../generic/hooks/useModal";
import { useDispatch } from "react-redux";
import useUpdateStakeholder from "src/modules/startup/onBoarding/core/graphql/mutations/useUpdateStakeholder";

const FirstStep: FC<StepProps> = () => {

  const { closeModal } = useModal();
  const { translate } = useTranslate();
  const { send, stepData, state } = useCustomMachine();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { startupId } = useSession();

  const { updateStakeholder } = useUpdateStakeholder({}, {});

  const handlePrevious = () => {
    send('PREVIOUS')
  }

  const handleCancel = () => {
    dispatch(clearAssistatText())
    send('CANCEL')
  }

  const onSubmit = async (values) => {
    dispatch(clearAssistatText())
    const variables = {
      startupId,
      stakeholderId: state?.context?.data?.stakeholderId ?? id,
      stakeholderData: {
        stakeholder: {
          ...values
        }
      }
    }
    await updateStakeholder({ ...variables })
    closeModal()
  }

  return (
    <FormLinkedInURL
      initialValues={stepData}
      Template={TemplateWizard}
      onCompleteSubmit={onSubmit}
      propsTemplate={{
        title: translate('MODULES.STAKEHOLDER.PROFESSIONAL_HISTORY.SECOND_STEP_A.TITLE'),
        subTitle: translate('MODULES.STAKEHOLDER.PROFESSIONAL_HISTORY.SECOND_STEP_A.SUBTITLE')
      }}
      buttons={{ cancel: <Button onClick={handleCancel}>Cancel</Button>, previous: <Button onClick={handlePrevious}>Previous</Button> }}
    />
  )
}
export default FirstStep
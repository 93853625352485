import useMutation from "src/hooks/custom/useMutation";

export const UPSERT_DELETE_ULTIMATE_PARENT = `
mutation deleteUltimateParent(
  $startupId: ID!
  $groupCompanyId: ID!
) {
  deleteUltimateParent(
    startupId: $startupId
    groupCompanyId: $groupCompanyId
  ) {
    id
  }
}
`;

const useDeleteUltimateParent = () => {
  const [mutation] = useMutation(
    UPSERT_DELETE_ULTIMATE_PARENT,
    { showSuccessNotification: true }
  );
  return mutation;
};

export default useDeleteUltimateParent;


import _ from 'lodash'
import useQuery from "../../../../../hooks/custom/useQuery";
import {TasksRequiredFieldFragment} from "../../../../map/graphql/typePolicies";
import {PEPStakeholderFragmentFields, SourcesOfFundsStakeholderFragmentFields} from "./fragments";

export const GET_STAKEHOLDER = `
  ${PEPStakeholderFragmentFields}
  ${TasksRequiredFieldFragment}
  ${SourcesOfFundsStakeholderFragmentFields}
  query getStakeholderPEP($startupId: ID! $stakeholderId: ID!) {
  getStakeholder(startupId: $startupId stakeholderId: $stakeholderId) {
    id
    tasks {
    ...TasksRequiredFieldFragment
    }
    ...PEPStakeholderFragmentFields
    ...SourcesOfFundsStakeholderFragmentFields
    }
  }`;

const useStakeholderTasks = (variables) =>{

    const {loading, error, data} = useQuery(GET_STAKEHOLDER,variables);

    return  {loading,error,data:_.get(data,'getStakeholder',null)} ;
}
export default useStakeholderTasks;
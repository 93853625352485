import { useMemo } from "react";
import TemplateWizardSkeletonMachine from "src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine";
import ReviewCompanyDetailsStep from "../steps/ReviewCompanyDetailsStep";
import WillHaveSharesIncorporatedStep from "../steps/WillHaveSharesIncorporatedStep";
import WhichCompaniesHaveSharesStep from "../steps/WhichCompaniesHaveSharesStep";
import AddCompanyBasicInfoStep from "../steps/AddCompanyBasicInfoStep";
import DoYouHaveMoreCompaniesStep from "../steps/DoYouHaveMoreCompaniesStep";
import WillHaveSharesNotYetFormedStep from "../steps/WillHaveSharesNotYetFormedStep";
import ReviewShareInIncorporatedCompanies from "../steps/ReviewShareInIncorporatedCompanies";
import SharesInCompaniesNotIncorporatedForm from "../steps/SharesInCompaniesNotIncorporatedForm";
import ReviewSharesInCompaniesNotIncorporated from "../steps/ReviewSharesInCompaniesNotIncorporated";

const useSteps = () => {
  return useMemo(
    () => ({
      initialData: {
        render: TemplateWizardSkeletonMachine,
      },
      pincasData: {
        render: TemplateWizardSkeletonMachine,
      },
      ReviewSharesInIncorporatedCompanies: {
        render: ReviewShareInIncorporatedCompanies,
      },
      ReviewSharesInCompaniesNotIncorporated: {
        render: ReviewSharesInCompaniesNotIncorporated,
      },
      SharesInCompaniesNotIncorporatedForm: {
        render: SharesInCompaniesNotIncorporatedForm,
      },
      ReviewCompanyDetailsStep: {
        render: ReviewCompanyDetailsStep,
      },
      WillHaveSharesIncorporatedStep: {
        render: WillHaveSharesIncorporatedStep,
      },
      WhichCompaniesHaveSharesStep: {
        render: WhichCompaniesHaveSharesStep,
      },
      AddCompanyBasicInfoStep: {
        render: AddCompanyBasicInfoStep,
      },
      DoYouHaveMoreCompaniesStep: {
        render: DoYouHaveMoreCompaniesStep,
      },
      WillHaveSharesNotYetFormedStep: {
        render: WillHaveSharesNotYetFormedStep,
      },
      SaveStep: {
        render: TemplateWizardSkeletonMachine,
      },
    }),
    []
  );
};

export default useSteps;

export default {
  FIRST_STEP: {
    TITLE: 'Is this stakeholder a PEP (Politically Exposed Person)?',
    SUBTITLE: 'PEP disclosure',
    TOOLTIP: 'A PEP is someone who is, or has been, entrusted with a prominent public function. Examples include heads of state, heads of government and senior politicians.'
  },
  SECOND_STEP: {
    TITLE: 'Are they a PEP\'s family member?',
    SUBTITLE: 'PEP disclosure',
    TOOLTIP: "Select 'Yes, they are' if this person is the parent, spouse or child of a PEP, or the spouse of a child of a PEP."
  },
  THIRD_STEP: {
    TITLE: 'Are they a close associate of a PEP?',
    SUBTITLE: 'PEP disclosure',
    TOOLTIP: "Close associates include people with close business relations with a PEP, and those with sole beneficial ownership of a legal arrangement, known to have been set up for the PEP’s benefit."
  },
}
export const PEPStakeholderFragmentFields = `
fragment PEPStakeholderFragmentFields on StakeHolder{
    pep {
      isPEP
      descriptionOfPEP
      isFamilyMemberOfAPEP
      familyMemberOfAPEPDescription
      isCloseAssociateOfAPEP
      closeAssociateOfAPEP
    }
}
`
export const SourcesOfFundsStakeholderFragmentFields =`
fragment SourcesOfFundsStakeholderFragmentFields on StakeHolder{
sourcesOfFunds{
      sourceOfWealth
      sourceOfFunds
      company
}
}
`

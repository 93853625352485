import useMutation from '../../../hooks/custom/useMutation';
import useSession from "../../session/hooks/useSession";

const FINISH_INTRO_MUTATION = `mutation {
  introductionFinished {
    id
    pendingActions
  }
}`

export default function useFinishTour(options = {}) {
    const {updateUser} = useSession()
    const onCompleted=(data)=>{
        updateUser({pendingActions:data.introductionFinished.pendingActions})
    }

    const [mutation] = useMutation(FINISH_INTRO_MUTATION, { showSuccessNotification:false,...options }, {onCompleted});

    return { mutation };
}

import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import ProductItem from '../ProductItem'
import TypographyLocal from "../../../../../src/modules/generic/components/Texts/Typography";
import { Constants } from '../../../../v1/utils';
import classes from './ListProducts.module.scss';

const ListProducts = ({ product, data, ...props }) => {

  const [features, setFeatures] = useState({});
  const [isMonths, setIsMonth] = useState(product === Constants.MODULES.CHECKOUT.PRODUCTS.SCALE_ANNUAL_SUBSCRIPTION_MONTHLY);

  const mapListProducts = (products) => {
    const featuresData = _.flatten(products.map(prod => {
      return prod.features.map(feature => {
        return {
          code: feature.code,
          name: feature.name,
          description: feature.description,
          displayValue: feature.displayValue,
          codePlan: prod.code
        }
      })
    }));
    return _.groupBy(featuresData, f => f.code);
  };

  useEffect(() => {
    if (_.has(data, 'getSubscriptionPrices')) {
      setFeatures(mapListProducts(data.getSubscriptionPrices))
    }
  }, [JSON.stringify(data)]);

  useEffect(() => {
    setIsMonth(product === Constants.MODULES.CHECKOUT.PRODUCTS.SCALE_ANNUAL_SUBSCRIPTION_MONTHLY)
  }, [product]);

  return (
    <div className={classes.containerProducts} >
      <div className={classes.TypeSubscriptionHeader}>
        <div></div>
        <div className={classes.containerTypeProduct} >
          <TypographyLocal className={classes.StartSub}>{'Start subscription'}</TypographyLocal>
        </div>
        <div className={classes.containerTypeProduct} >
          <TypographyLocal className={classes.ScaleSub} >{'Scale subscription'}</TypographyLocal>
        </div>
      </div>
      <div className={classes.containerListProducts} >
        {Constants.MODULES.CHECKOUT.LIST_PRODUCTS_SUSCRIPTION.map((product, index) => {
          return (
            <ProductItem
              iconTable={product.iconTable}
              title = {product.title}
              subTitle = {product.subTitle}
              key={index}
              orderProduct = {product.orderProduct}
            />
          );
        })}
      </div>
    </div>
  )
};

export default ListProducts;
import { actions as actionTypes } from "../actions/page";
import { Constants } from "../../v1/utils/constants";
import _ from "lodash";



const limitHistory = 50;
const initialState = {

    current: null,
    currentPath: null,
    historyPage: [],
    reloadPage: true,
    breadCrumbs: []
};
export const generateUrl = (path, params) => {
    let url = path;
    _.forEach(params, (value, key) => {
        const paramKey = key == "_id" ? "id" : key;
        url = url.replace(new RegExp("((:" + paramKey + "(\\?)*$))", "g"), value)
        url = url.replace(new RegExp("((:" + paramKey + "(\\?)*\/))", "g"), value + "/")

    })
    url = url.replace(new RegExp("(:(\\w)+\\?(\\/)*)", "g"), "")

    return url;
}


export default (state = initialState, action) => {
    let historyPage = state.historyPage;
    switch (action.type) {
        case actionTypes.UPDATE_LABEL_BREADCRUMBS:

            let newBreadCrumbsLabel = _.get(state, "breadCrumbs", []);
            if (newBreadCrumbsLabel.length > 0) {
                newBreadCrumbsLabel[newBreadCrumbsLabel.length - 1].label = action.label
                newBreadCrumbsLabel[newBreadCrumbsLabel.length - 1].paramsLabel = action.paramsLabel
                if (action.path) {
                    newBreadCrumbsLabel[newBreadCrumbsLabel.length - 1].path = action.path
                    newBreadCrumbsLabel[newBreadCrumbsLabel.length - 1].params = action.params
                }
            }

            return {
                ...state,
                breadCrumbs: newBreadCrumbsLabel
            };
        case actionTypes.UPDATE_PARAMS_BREADCRUMBS:

            let newBreadCrumbsParams = _.get(state, "breadCrumbs", []);
            if (newBreadCrumbsParams.length > 0) {
                if (newBreadCrumbsParams.length >= 3) {

                    if (newBreadCrumbsParams[newBreadCrumbsParams.length - 1].params.id == newBreadCrumbsParams[newBreadCrumbsParams.length - 3].params.id) {
                        newBreadCrumbsParams.pop()
                        newBreadCrumbsParams.pop()

                    } else {

                        newBreadCrumbsParams[newBreadCrumbsParams.length - 1].paramsLabel = action.paramsLabel
                    }

                } else {
                    newBreadCrumbsParams[newBreadCrumbsParams.length - 1].paramsLabel = action.paramsLabel
                }
            }



            return {
                ...state,
                breadCrumbs: newBreadCrumbsParams
            };
        case actionTypes.ADD_BREADCRUMBS:

            let newBreadCrumbs = _.get(state, "breadCrumbs", []);

            if (newBreadCrumbs.length >= 2 && newBreadCrumbs[newBreadCrumbs.length - 2].path == action.path && newBreadCrumbs[newBreadCrumbs.length - 2].params == action.params) {
                newBreadCrumbs.pop()
            } else {
                newBreadCrumbs.push({ path: action.path, params: action.params, label: action.label, paramsLabel: action.paramsLabel })
            }
            return {
                ...state,
                breadCrumbs: newBreadCrumbs
            };
        case actionTypes.START_BREADCRUMBS:

            return {
                ...state,
                breadCrumbs: [{ path: action.path, params: action.params, label: action.label, paramsLabel: action.paramsLabel }],
            };
        case actionTypes.CLEAR:

            return {
                ...state,
                historyPage: [],
                reloadPage: false
            };
        case actionTypes.NOT_PREV:

            if (action.url) {
                historyPage.push({ url: action.url });
            }
            return {
                ...state,
                historyPage,
                reloadPage: false
            };
        case actionTypes.GO_TO:
            const url = generateUrl(action.page.path, action.params);
            const current = { url, cantBack: action.page.cantBack, params: action.params };

            if (historyPage.length >= limitHistory) {
                historyPage.splice(0, (historyPage.length - limitHistory))
            }
            if (historyPage.length === 0 || current.url !== historyPage[historyPage.length - 1].url) {

                historyPage.push(current)
            }

            return {
                ...state,
                reloadPage: true,
                historyPage: historyPage
            };
        case actionTypes.SET_PAGE:

            const current2 = { url };

            historyPage.push(current2);
            return {
                ...state,
                historyPage: historyPage,
            };
        case actionTypes.GO_TO_PREV:

            const defaultPage = { url: Constants.PAGES.MODULES.MAP.maps.path }
            let currentPage = {};
            if (historyPage.length !== 0) {
                currentPage = historyPage.pop()
            }
            while (historyPage.length !== 0 &&
                (
                    (historyPage[historyPage.length - 1].cantBack)
                    || (!historyPage[historyPage.length - 1])
                    || (!historyPage[historyPage.length - 1].url)
                    || (currentPage.url === historyPage[historyPage.length - 1].url)
                )
            ) {
                historyPage.pop();
            }



            if (historyPage.length === 0) {

                historyPage.push(defaultPage)
            }
            return {
                ...state,
                historyPage: historyPage,
                reloadPage: true
            };

        default:
            return state;
    }
};

import React, { Component } from 'react';
import classes from "./LeftEducation.module.scss";
import ServerConnect from "../../../utils/ServerConnect";
import { Text } from '../../../components';
import Scrollbar from "./../../../../components/generic/Scrollbar/Scrollbar"
import Typography from '../../../../modules/generic/components/Texts/Typography';
import _ from "lodash"
import { Collapse } from '@mui/material';
class LeftEducation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {
                screen: '',
                content: []
            },
            icon: {
                open: <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M7.52864 14.4708C7.26829 14.2104 7.26829 13.7883 7.52864 13.5279L11.5286 9.52794C11.789 9.26759 12.2111 9.26759 12.4714 9.52794L16.4714 13.5279C16.7318 13.7883 16.7318 14.2104 16.4714 14.4708C16.2111 14.7311 15.789 14.7311 15.5286 14.4708L12 10.9422L8.47145 14.4708C8.2111 14.7311 7.78899 14.7311 7.52864 14.4708Z'
                        fill='white'
                    />
                </svg>,
                closed: <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M16.4714 9.52925C16.7317 9.7896 16.7317 10.2117 16.4714 10.4721L12.4714 14.4721C12.211 14.7324 11.7889 14.7324 11.5286 14.4721L7.52856 10.4721C7.26821 10.2117 7.26821 9.7896 7.52856 9.52925C7.78891 9.2689 8.21101 9.2689 8.47136 9.52925L12 13.0578L15.5286 9.52925C15.7889 9.2689 16.211 9.2689 16.4714 9.52925Z'
                        fill='white'
                    />
                </svg>
            },
            contentDisplays: {
                '0': true
            }
        }
    }

    componentDidMount() {
        const path = `/contentful/leftEducation/${this.props.leftEducationId}`;

        const options = { method: 'GET' };

        ServerConnect.requestApi(path, options)
            .then(fields => {
                if (fields.response) {
                    this.setState({
                        data: fields.response
                    });
                    if (this.props.updateScreen){
                        this.props.updateScreen(_.get(fields,"response.screen"));
                    }
                    
                }
            })
            .catch((err) => { // TODO: See what to do if this fails.
                console.log(`Contentful error: ${err}`);
            });
    }

    changeContentDisplay(index) {
        this.setState(prevState => ({
            ...prevState,
            contentDisplays: {
                ...prevState.contentDisplays,
                [index]: !prevState.contentDisplays[index]
            }
        }));
    }

    renderScreenContent(content) {
        return content.map((c, i) => (
            <div key={i} className={classes.EducationContainer}>
                <div className={classes.ContentTitle}>
                    <Typography variant='body' color="blue">{c.title}</Typography>
                    {this.state.contentDisplays[i] ?
                        <div className={classes.Arrow} onClick={() => this.changeContentDisplay(i)}>
                            {this.state.icon.open}
                        </div>
                        :
                        <div className={classes.Arrow} onClick={() => this.changeContentDisplay(i)}>
                            {this.state.icon.closed}
                        </div>
                    }
                </div>
                <Collapse in={this.state.contentDisplays[i]}>
                    <p className={`${classes.ContentDescription}`}
                    >
                        <Typography variant='body' color="primary" weight='light'>
                            <Text uuid={c.description} />
                        </Typography>

                        {c.link ?
                            <a href={c.link} target={'_blank'}>
                                <Typography variant='body' color="blue" weight='light'>
                                    Learn more...
                                </Typography>
                            </a> : null}

                    </p>
                </Collapse>
            </div >
        ));
    }

    render() {
        return (
            <React.Fragment>
                <div className={classes.LeftEducationFlex}>
                    <Scrollbar>
                        {this.renderScreenContent(this.state.data.content)}
                    </Scrollbar>

                </div>
            </React.Fragment>
        )
    }
}

export default LeftEducation;

import React, { FC, useEffect, useRef, useMemo } from 'react';
import { useDispatch } from "react-redux";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import * as Yup from 'yup';
import Form from "../../../../../components/forms/Form/Form";
import FormProps from "../../../../generic/forms/interfaces/FormProps";
import useTranslate from "../../../../generic/hooks/useTranslate";
import { addAssistantText, clearAssistatText } from "../../../../generic/store/action";
import TemplateFormDefault from "../../../../generic/templates/Modal";
import CheckboxButtonSimple from "../../../../generic/components/Inputs/CheckboxButtonSimple";
import ControllerInput from "../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import classes from './classes.module.scss';
import Button from "../../../../generic/components/Buttons/Button";
import _ from "lodash";

const schema = Yup.object().shape({
  companyData: Yup.string().required("Required"),
})

interface Props extends FormProps {
  initialValues: any,
  onCompleteSubmit: any,
  key: string,
  handlePrev: any,
}

const FormStep4SelectTopco: FC<Props> = ({
  initialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
  handlePrev,
  key,
}) => {

  const refForm = useRef();
  const dispatch = useDispatch();
  const { translate } = useTranslate();

  useEffect(() => {
    handeAddTooltips();
    return () => {
      dispatch(clearAssistatText());
    }
  }, []);

  const handeAddTooltips = () => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText(translate("MODAL_SELECT_TOPCO_STEP4_SELECT_TOOLTIP1")));
  }

	const optionsToShow = useMemo(() => {
    const allCompanies = _.get(initialValues, "allCompanies", []);
    const result = allCompanies.map(comp => {
      return {
        value: comp.id,
        disabled: !_.get(comp, "isJurisdictionApproved"),
        label: _.get(comp, "isJurisdictionApproved") ? comp.name : translate("MODAL_SELECT_TOPCO_STEP4_NOT_APPROVED", {company: comp.name}),
      }
    })
    return result;
	},[JSON.stringify(initialValues)]);

  return (
    <>
      <Form
        key={key}
        defaultValues={initialValues || {}}
        schema={schema}
        onSubmit={onCompleteSubmit}
        ref={refForm} optionsForm={{ mode: "onChange" }}
      >
        <Template 
          props={propsTemplate} 
          buttons={{
            ...buttons,
            submit: <SubmitButton {...buttonSubmitProps}>Confirm</SubmitButton>,
            previous: <Button onClick={handlePrev} variant="secondary">{translate('GENERIC_BUTTON_PREVIOUS')}</Button>,
          }}
          >        
          <ControllerInput
            render={CheckboxButtonSimple}
            name='companyData'
            classNameButton={classes.buttonCheckBox}
            options={optionsToShow}
            defaultValue={[]}
            onChange={(value) => {}}
            isMultiple={false}
          />
        </Template>
      </Form>
    </>
  )
}

export default FormStep4SelectTopco
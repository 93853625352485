import React, {useEffect, useState} from 'react';
import {ClaraCheckForm, Text} from '../index';
import _ from "lodash";
import PropTypes from 'prop-types';
import {Field} from "formik";
import classes from "./Chat.module.scss";
import FactoryChatForm from './FactoryChatForm';
import useCompanyDirectoresShareHolders from '../../Hooks/useCompanyDirectoresShareHolders';
import FactoryChatFormConfig from './FactoryChatFormConfig.js';

const ChatKYCEntityEditMembers = ({ responseKeyName, isForm, field, form, initialValueStart }) => {
  const nameField = responseKeyName ? responseKeyName + "." : "";
  const {draftId} = initialValueStart;
  const [refreshValidate,setRefreshValidate] =useState(false);
  const [refresh, setRefresh] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [formSelect, setFormSelect] = useState("INDIVIDUAL_FORM");
  const { result: companyDirectoresShareHolders ,loading,refetch } = useCompanyDirectoresShareHolders(refresh, draftId)
  const [companies, setCompanies] = useState(null);
  const [directors, setDirectors] = useState(null);
  const [shareholders, setShareholders] = useState(null);
  const [reload, setReload] = useState(false);
  const handleClose = () => {
    setOpenModal(false)
    setReload(!reload);
  }
  const refreshData = () => {
    refetch();
    // setRefresh(refresh + 1)
  }

  useEffect(() => {
    let companiesLocal = [];
    let directorsLocal = [];
    let shareholdersLocal = [];
    companiesLocal.push({
      type: "COMPANY_FORM",
      data: companyDirectoresShareHolders.entity
    })
    let isComplete=true;
    let hasOneDirector=false;

    _.forEach(_.get(companyDirectoresShareHolders, "individuals", []), (individuals) => {
      if (individuals.isDirector) {
        directorsLocal.push({
          type: "INDIVIDUAL_FORM",
          data: { ...individuals }
        })
      }
      if (individuals.isShareholder) {
        shareholdersLocal.push({
          type: "INDIVIDUAL_FORM",
          data: { ...individuals }
        })
      }
      if (individuals.isComplete!==true){
        isComplete=false
      }
      if (individuals.isDirector && individuals.isComplete){
        hasOneDirector=true
      }
    });
    _.forEach(_.get(companyDirectoresShareHolders, "companies", []), (company) => {
      if (company.isDirector) {
        directorsLocal.push({
          type: "INDIVIDUAL_CORPORATE_FORM",
          data: { ...company }
        })
      }
      if (company.isShareholder) {
        shareholdersLocal.push({
          type: "INDIVIDUAL_CORPORATE_FORM",
          data: { ...company }
        })
      }
      if (company.isComplete!==true){
        isComplete=false
      }
      if (company.isDirector && company.isComplete){
        hasOneDirector=true
      }
    });
    setCompanies(companiesLocal);
    setDirectors(directorsLocal);
    form.setFieldValue(nameField + "isComplete", isComplete && hasOneDirector);
    setShareholders(shareholdersLocal);
  }, [JSON.stringify(companyDirectoresShareHolders),loading])
  
  return (
    <React.Fragment>
      <div className={`${classes.ChatQuestionnaire} ${classes.ChatShareholderDirectors}`}>
        <div className={classes.contentField}>
          <h4><Text uuid={"KYC_ENTITY_EDIT_MEMBERS_TEXT_COMPANY"} /></h4>
          {companies ? (
            <Field name={nameField + "companies"}>
              {({ field, form }) => (
                <ClaraCheckForm
                  reload={reload}
                  openForm={openModal}
                  addForm={formSelect}
                  cbClose={handleClose}
                  cbSaveForm={refreshData}
                  field={field}
                  form={form}
                  list={companies ? companies : []}
                  initialValues={{
                    COMPANY_FORM: { formKey: "COMPANY_FORM" },
                  }}
                  multiForm={true}
                  optionsList={{
                    "COMPANY_FORM": {
                      fieldId: _.get(FactoryChatFormConfig, "COMPANY_FORM" + ".id", "id"),
                      fieldLabel: _.get(FactoryChatFormConfig, "COMPANY_FORM" + ".fieldName", null),
                    }
                  }}
                  optionsForm={
                    {
                      "COMPANY_FORM": {
                        titleKey: _.get(FactoryChatFormConfig, "COMPANY_FORM.titleKey", null),
                        educationId: _.get(FactoryChatFormConfig, "COMPANY_FORM.leftEducationId", null),
                        muttation: _.get(FactoryChatFormConfig, "COMPANY_FORM.muttation", null),
                        getIdFromMuttation: _.get(FactoryChatFormConfig, "COMPANY_FORM.getIdFromMuttation", null),
                        paramsMuttaion: initialValueStart,
                        getVariablesMutation: _.get(FactoryChatFormConfig, "COMPANY_FORM.getVariablesMutation", null),
                        succesNotification: "NOTIFICATION_SUCCESS",
                        errorNotification: "NOTIFICATION_BAD",
                      }
                    }
                  }
                  optionsSelect={{
                    auto: false,
                    canSelect: false,
                    autoLoaderData: (data, i) => {
                      return true;
                    },
                  }}
                  optionsOperation={{
                    canAdd: false,
                    canEdit: true,
                    canRemove: false,
                  }}
                  optionsView={{
                    recordsByLine: 7,
                  }}
                  className={{}}
                >
                  <FactoryChatForm type={"COMPANY_FORM"} formKey={"COMPANY_FORM"} propsForm={{ isFlow: true , draftId: initialValueStart.draftId}}></FactoryChatForm>
                </ClaraCheckForm>
              )}
            </Field>
          ) : null}
        </div>
        <div className={classes.contentField}>
          <h4><Text uuid={"KYC_ENTITY_EDIT_MEMBERS_TEXT_DIRECTORS_MANAGERS"} /></h4>
          {directors ? (
            <Field name={nameField + "directors"}>
              {({ field, form }) => (
                <ClaraCheckForm
                  reload={reload}
                  openForm={openModal}
                  addForm={formSelect}
                  cbClose={handleClose}
                  cbSaveForm={refreshData}
                  field={field}
                  form={form}
                  list={directors ? directors : []}
                  initialValues={{
                    INDIVIDUAL_FORM: { formKey: "INDIVIDUAL_FORM" },
                    INDIVIDUAL_CORPORATE_FORM: { formKey: "INDIVIDUAL_CORPORATE_FORM" },
                  }}
                  multiForm={true}
                  optionsList={{
                    "INDIVIDUAL_FORM": {
                      fieldId: _.get(FactoryChatFormConfig, "INDIVIDUAL_FORM" + ".id", "id"),
                      fieldLabel: _.get(FactoryChatFormConfig, "INDIVIDUAL_FORM" + ".fieldName", null),
                    },
                    "INDIVIDUAL_CORPORATE_FORM": {
                      fieldId: _.get(FactoryChatFormConfig, "DIRECTOR_CORPORATE_FORM" + ".id", "id"),
                      fieldLabel: _.get(FactoryChatFormConfig, "DIRECTOR_CORPORATE_FORM" + ".fieldName", null),
                    }
                  }}
                  optionsForm={
                    {
                      "INDIVIDUAL_FORM": {
                        titleKey: _.get(FactoryChatFormConfig, "INDIVIDUAL_FORM.titleKey", null),
                        educationId: _.get(FactoryChatFormConfig, "INDIVIDUAL_FORM.leftEducationId", null),
                        muttation: _.get(FactoryChatFormConfig, "INDIVIDUAL_FORM.muttation", null),
                        muttationRemove: _.get(FactoryChatFormConfig, `INDIVIDUAL_FORM.muttationRemove`, null),
                        getVariablesMutationRemove: _.get(FactoryChatFormConfig, "INDIVIDUAL_FORM.getVariablesMutationRemove", null),
                        getIdFromMuttation: _.get(FactoryChatFormConfig, "INDIVIDUAL_FORM.getIdFromMuttation", null),
                        paramsMuttaion: initialValueStart,
                        getVariablesMutation: _.get(FactoryChatFormConfig, "INDIVIDUAL_FORM.getVariablesMutation", null),
                        succesNotification: "NOTIFICATION_SUCCESS_KYC_INDIVIDUAL",
                        errorNotification: "NOTIFICATION_BAD"
                      },
                      "INDIVIDUAL_CORPORATE_FORM": {
                        titleKey: _.get(FactoryChatFormConfig, "DIRECTOR_CORPORATE_FORM.titleKey", null),
                        educationId: _.get(FactoryChatFormConfig, "DIRECTOR_CORPORATE_FORM.leftEducationId", null),
                        muttation: _.get(FactoryChatFormConfig, "DIRECTOR_CORPORATE_FORM.muttation", null),
                        muttationRemove: _.get(FactoryChatFormConfig, `DIRECTOR_CORPORATE_FORM.muttationRemove`, null),
                        getVariablesMutationRemove: _.get(FactoryChatFormConfig, "DIRECTOR_CORPORATE_FORM.getVariablesMutationRemove", null),
                        getIdFromMuttation: _.get(FactoryChatFormConfig, "DIRECTOR_CORPORATE_FORM.getIdFromMuttation", null),
                        paramsMuttaion: initialValueStart,
                        getVariablesMutation: _.get(FactoryChatFormConfig, "DIRECTOR_CORPORATE_FORM.getVariablesMutation", null),
                        succesNotification: "NOTIFICATION_SUCCESS_KYC_INDIVIDUAL",
                        errorNotification: "NOTIFICATION_BAD",
                      }
                    }
                  }
                  optionsSelect={{
                    auto: false,
                    canSelect: false,
                    autoLoaderData: (data) => {
                      return data.isComplete
                    },
                  }}
                  optionsOperation={{
                    canAdd: false,
                    canEdit: true,
                    canRemove: true,
                  }}
                  optionsView={{
                    recordsByLine: 7,
                    textKeyEdit: "TEXT_UPDATE"
                  }}
                  className={{}}
                >
                  <FactoryChatForm type={"INDIVIDUAL_FORM"} formKey={"INDIVIDUAL_FORM"} propsForm={{ isFlow: true , draftId: initialValueStart.draftId}} ></FactoryChatForm>
                  <FactoryChatForm type={"INDIVIDUAL_CORPORATE_FORM"} formKey={"INDIVIDUAL_CORPORATE_FORM"} propsForm={{ isFlow: true , draftId: initialValueStart.draftId}}></FactoryChatForm>
                </ClaraCheckForm>
              )}
            </Field>
          ) : null}
        </div>
        <div className={classes.contentField}>
          <h4><Text uuid={"KYC_ENTITY_EDIT_MEMBERS_TEXT_ADDITIONAL_SHAREHOLDERS"} /></h4>
          {shareholders ? (
            <Field name={nameField + "shareholders"}>
              {({ field, form }) => (
                <React.Fragment>
                  <ClaraCheckForm
                    reload={reload}
                    cbClose={handleClose}
                    cbSaveForm={refreshData}
                    field={field}
                    form={form}
                    list={shareholders ? shareholders : []}
                    initialValues={{
                      INDIVIDUAL_FORM: { formKey: "INDIVIDUAL_FORM" },
                      INDIVIDUAL_CORPORATE_FORM: { formKey: "INDIVIDUAL_CORPORATE_FORM" },
                    }}
                    multiForm={true}
                    optionsList={{
                      "INDIVIDUAL_FORM": {
                        fieldId: _.get(FactoryChatFormConfig, "INDIVIDUAL_FORM" + ".id", "id"),
                        fieldLabel: _.get(FactoryChatFormConfig, "INDIVIDUAL_FORM" + ".fieldName", null),
                      },
                      "INDIVIDUAL_CORPORATE_FORM": {
                        fieldId: _.get(FactoryChatFormConfig, "DIRECTOR_CORPORATE_FORM" + ".id", "id"),
                        fieldLabel: _.get(FactoryChatFormConfig, "DIRECTOR_CORPORATE_FORM" + ".fieldName", null),
                      }
                    }}
                    optionsForm={
                      {
                        "INDIVIDUAL_FORM": {
                          titleKey: _.get(FactoryChatFormConfig, "INDIVIDUAL_FORM.titleKey", null),
                          educationId: _.get(FactoryChatFormConfig, "INDIVIDUAL_FORM.leftEducationId", null),
                          muttation: _.get(FactoryChatFormConfig, "INDIVIDUAL_FORM.muttation", null),
                          getIdFromMuttation: _.get(FactoryChatFormConfig, "INDIVIDUAL_FORM.getIdFromMuttation", null),
                          muttationRemove: _.get(FactoryChatFormConfig, `INDIVIDUAL_FORM.muttationRemove`, null),
                          getVariablesMutationRemove: _.get(FactoryChatFormConfig, "INDIVIDUAL_FORM.getVariablesMutationRemove", null),
                          paramsMuttaion: initialValueStart,
                          getVariablesMutation: _.get(FactoryChatFormConfig, "INDIVIDUAL_FORM.getVariablesMutation", null),
                          succesNotification: "NOTIFICATION_SUCCESS",
                          errorNotification: "NOTIFICATION_BAD",
                        },
                        "INDIVIDUAL_CORPORATE_FORM": {
                          titleKey: _.get(FactoryChatFormConfig, "DIRECTOR_CORPORATE_FORM.titleKey", null),
                          educationId: _.get(FactoryChatFormConfig, "DIRECTOR_CORPORATE_FORM.leftEducationId", null),
                          muttation: _.get(FactoryChatFormConfig, "DIRECTOR_CORPORATE_FORM.muttation", null),
                          getIdFromMuttation: _.get(FactoryChatFormConfig, "DIRECTOR_CORPORATE_FORM.getIdFromMuttation", null),
                          muttationRemove: _.get(FactoryChatFormConfig, `DIRECTOR_CORPORATE_FORM.muttationRemove`, null),
                          getVariablesMutationRemove: _.get(FactoryChatFormConfig, "DIRECTOR_CORPORATE_FORM.getVariablesMutationRemove", null),
                          paramsMuttaion: initialValueStart,
                          getVariablesMutation: _.get(FactoryChatFormConfig, "DIRECTOR_CORPORATE_FORM.getVariablesMutation", null),
                          succesNotification: "NOTIFICATION_SUCCESS",
                          errorNotification: "NOTIFICATION_BAD",
                        }
                      }
                    }
                    optionsSelect={{
                      auto: false,
                      canSelect: false,
                      autoLoaderData: (data) => {
                        return data.isComplete
                      },
                    }}
                    optionsOperation={{
                      canAdd: false,
                      canEdit: true,
                      canRemove: true,
                    }}
                    optionsView={{
                      recordsByLine: 7,
                      textKeyEdit: "TEXT_UPDATE"
                    }}
                    className={{}}
                  >
                    <FactoryChatForm type={"INDIVIDUAL_FORM"} formKey={"INDIVIDUAL_FORM"} propsForm={{ isFlow: true , draftId: initialValueStart.draftId}}></FactoryChatForm>
                    <FactoryChatForm type={"INDIVIDUAL_CORPORATE_FORM"} formKey={"INDIVIDUAL_CORPORATE_FORM"} propsForm={{ isFlow: true, draftId: initialValueStart.draftId }}></FactoryChatForm>
                  </ClaraCheckForm>
                </React.Fragment>
              )}
            </Field>
          ) : null}
        </div>
      </div>
    </React.Fragment >
  );
}
ChatKYCEntityEditMembers.propTypes = {
  responseKeyName: PropTypes.string.isRequired,
  initialValueStart: PropTypes.object.isRequired,

};
export default ChatKYCEntityEditMembers;

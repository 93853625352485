const removeCountries = ["Wales"];
const filterCountriesAlpha3 = ["VGB", "USA", "ARE", "GBR", "CYM", "SGP", "CYP"];

const filterJurisdictions = [
  "Delaware",
  "Cyprus",
  "Singapore",
  "British Virgin Islands (BVI)",
  "Abu Dhabi Global Market",
  "Dubai International Financial Centre",
  "Cayman Islands",
  "England & Wales",
];

export default [removeCountries, filterCountriesAlpha3, filterJurisdictions];
import {useMemo} from "react";
import _ from "lodash";

const useGetFilterByDocumentType = (documentType) => useMemo(() => {
    switch (documentType){
        case "ADVISOR_AGREEMENT":
        case "EMPLOYMENT_AGREEMENT_ADGM":
        case "EMPLOYMENT_AGREEMENT_DIFC":
		case "EMPLOYMENT_AGREEMENT_UK":
        case "FOUNDER_SERVICES_AGREEMENT":
        case "USA_CAL_EMPLOYEE_INFORMATION_AND_INVENTIONS_AGREEMENT":
        case "USA_STRATEGIC_ADVISORY_BOARD_LETTER": 
        case "USA_CAL_OFFER_LETTER_SALARY_EMPLOYEE":           
            return stakeholder=>!stakeholder.isAnEntity;
        default:
            return null
    }
},[]);

export default useGetFilterByDocumentType

import React, {FC, useEffect} from 'react'
import {StepProps} from "../../../../../../../../generic/components/Wizard/Step";
import SelectCompaniesStep from "../../../../../../wizard/steps/SelectCompaniesStepForm";
import UploadDocumentFormStep from "../../../../../../wizard/steps/UploadDocumentsStepForm";
import useCustomMachine from "../../../../../../../../generic/hooks/useCustomMachine";
import {useDispatch, useSelector} from "react-redux";
import _, {toInteger} from "lodash";
import moment from "moment";
import useTranslate from "../../../../../../../../generic/hooks/useTranslate";
import {addAssistantText, clearAssistatText} from "../../../../../../../../generic/store/action";
import useUpsertConvertible from "src/modules/startup/equity/graphql/mutation/upsertConvertible";
import TemplateWizard from "../../../../../../../../generic/templates/Modal/TemplateWizard";
import Button from "../../../../../../../../generic/components/Buttons/Button";
import UploadForm from "../../forms/UploadForm";
import useSession from '../../../../../../../../session/hooks/useSession';
const UploadDocumentConvertibleFormStep:FC<StepProps>=({})=>{
    const {state,stepData,send,next}=useCustomMachine()
    const {upsertConvertible}=useUpsertConvertible({showNotificationSuccess:false})
    const { startupId } = useSession();
    const dispatch=useDispatch()
    const {translate}=useTranslate()
    useEffect(()=>{
        dispatch(clearAssistatText())
        dispatch(addAssistantText(translate('MODULES.EQUITY.STEPS_CONVERTIBLE.UPLOAD_DOCUMENTS_STEP.TOOLTIP')))
    },[])
    const getCurrency=():string=>{
        if(_.isEmpty(_.get(state,'context.globalData',[]))) return  _.get(state,'context.data.select_companies.companies[0].transactionCurrency','')? _.get(state,'context.data.select_companies.companies[0].transactionCurrency',''):''
        else return _.get(state,`context.globalData[0].select_companies.companies[${_.get(state,'context.numbersOfIteration',0)}].transactionCurrency`,'')?_.get(state,`context.globalData[0].select_companies.companies[${_.get(state,'context.numbersOfIteration',0)}].transactionCurrency`,''):''
    }
    const handlePrev=()=>{
        send("PREVIOUS")
    }
    const handleCancel=()=>{
        send('CANCEL')
    }
    const handleCompleteSubmit=async (values)=> {
            const convertibleData = {
                issuedDate:moment(_.get(state,'context.data.add_equity_form.issuedDate')).format('YYYY-MM-DD'),
                amount:toInteger(_.get(state,'context.data.add_equity_form.amount')),
                discountRate:toInteger(_.get(state,'context.data.add_equity_form.discount')),
                interestRate:toInteger(_.get(state,'context.data.add_equity_form.interestRate')),
                valuationCap:toInteger(_.get(state,'context.data.add_equity_form.valuation')),
                currency:getCurrency(),
                documents:{uploadedFiles:[{...values.file,documentType:values.type, title: values.name}]},
                groupCompany:_.isEmpty(_.get(state,'context.globalData',[]))?_.get(state,'context.data.select_companies.companies[0].id'):_.get(state,`context.globalData[0].select_companies.companies[${_.get(state,'context.numbersOfIteration',0)}].id`)
            }
            await upsertConvertible({startupId, owner: _.get(state, 'context.paramsData'), convertibleData})
        next(values)
    }
    return(<>
        <UploadForm
        initialValues={stepData}
        propsTemplate={{
        title: translate('MODULES.EQUITY.STEPS.UPLOAD_DOCUMENTS_STEP.TITLE',{agreementType:translate(`MODULES.EQUITY.AGREEMENT_TYPE.CONVERTIBLE`)}),
        subTitle: translate('MODULES.EQUITY.STEPS.UPLOAD_DOCUMENTS_STEP.SUBTITLE',{type:translate(`MODULES.EQUITY.TYPES_PLURAL.CONVERTIBLE`)})
    }}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        buttons={{cancel: <Button onClick={handleCancel}>Cancel</Button>,previous:<Button onClick={handlePrev}>Previous</Button>}}/>
    </>)
}
export default UploadDocumentConvertibleFormStep
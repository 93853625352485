import React, {useState} from 'react';
import {ClaraCheckForm, Text} from '../index';
import _ from "lodash";
import PropTypes from 'prop-types';
import {Field} from "formik";
import classes from "./Chat.module.scss";
import FactoryChatForm from './FactoryChatForm';
import useEligibleDataProtection from '../../Hooks/useEligibleDataProtection';
import FactoryChatFormConfig from './FactoryChatFormConfig.js';

const ChatDataProtection = ({ responseKeyName, isForm, initialValueStart, notFlow, className, nameForm }) => {
  const nameField = responseKeyName ? responseKeyName + "." : "";
  const [openModal, setOpenModal] = useState(false);
  const { data } = useEligibleDataProtection(initialValueStart.matterId, initialValueStart.draftId)
  const [dataProtectionList, setDataProtectionList] = useState(null);

  const handleClose = () => {
    setOpenModal(false)
  }

  const whichKey = notFlow ? "DIRECTOR_INDIVIDUAL_FORM" : "INDIVIDUAL_QUESTIONNAIRE_FORM";


  const list = initialValueStart.draftId ? data   : data;

  return (
    <React.Fragment>
      <div className={`${classes.ChatQuestionnaire} ${classes.ChatDataProtection}`}>
        <div className={classes.contentField}>
          <Field name={nameField + "dataProtection"}>
            {({ field, form }) => (
              <button className={`${classes.buttonOption} ${isForm ? _.get(className, "buttonOption") : classes.buttonWithAutomatic} `}
                disabled={_.get(field, "value.length", 0) > 0}
                type="button" onClick={() => { setOpenModal(true) }}>
                <Text uuid={`QUESTIONARY_DATA_PROTECTION_BUTTON`} />
              </button>
            )}
          </Field>
        </div>
        <div className={` ${isForm ? _.get(className, "contentField") : classes.contentField}`}>
          {isForm && <label>Select a data controller:</label>}
          <Field name={nameField + "dataProtection"}>
            {({ field, form }) => (
              <ClaraCheckForm
                openForm={openModal}
                cbClose={handleClose}
                field={field}
                form={form}
                list={list}
                initialValues={{ formKey: `${isForm ? nameForm : whichKey}` }}
                optionsList={{
                  fieldId: initialValueStart.draftId ? "_id" : "id",
                  fieldLabel: _.get(FactoryChatFormConfig, `${isForm ? nameForm : whichKey}.fieldName`, null),
                }}
                optionsForm={{
                  titleKey: _.get(FactoryChatFormConfig, `${isForm ? nameForm : whichKey}.titleKey`, null),
                  educationId: _.get(FactoryChatFormConfig, `${isForm ? nameForm : whichKey}.leftEducationId`, null),
                  muttation: _.get(FactoryChatFormConfig, `${isForm ? nameForm : whichKey}.muttation`, null),
                  muttationRemove: _.get(FactoryChatFormConfig, `${isForm ? nameForm : whichKey}.muttationRemove`, null),
                  getIdFromMuttation: _.get(FactoryChatFormConfig, `${isForm ? nameForm : whichKey}.getIdFromMuttation`, null),
                  paramsMuttaion: initialValueStart,
                  getVariablesMutation: _.get(FactoryChatFormConfig, `${isForm ? nameForm : whichKey}.getVariablesMutation`, null),
                  getVariablesMutationRemove: _.get(FactoryChatFormConfig, `${isForm ? nameForm : whichKey}.getVariablesMutationRemove`, null),
                  succesNotification: "NOTIFICATION_SUCCESS",
                  errorNotification: "NOTIFICATION_BAD",
                }}
                optionsSelect={{
                  auto: true,
                  autoLoaderData: false,
                  canSelect: true,
                  canValidationSelect: (val, news, vals) => {
                    if (news && news.length > 0 && !news.includes(val)) {
                      return false;
                    }
                    if (_.get(vals, "length", 0) > 0) {
                      return false;
                    }
                    return true;
                  },
                  canValidationDeselected: (val, news, vals) => {
                    return !news.includes(val._id);
                  }
                }}
                optionsOperation={{
                  canAdd: false,
                  canEdit: true,
                  canRemove: true,
                  canEditFunctions: () => { },
                  canRemoveFunctions: () => { }

                }}
                optionsView={{
                  recordsByLine: isForm ? 5 : 7
                }}
                className={{}}
              >
                <FactoryChatForm formKey={whichKey} ></FactoryChatForm>
              </ClaraCheckForm>
            )}
          </Field>
        </div>
      </div>
    </React.Fragment >
  );
}
ChatDataProtection.propTypes = {
  responseKeyName: PropTypes.string.isRequired,
  initialValueStart: PropTypes.object.isRequired,

};
export default ChatDataProtection;

import { default as React, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import Form from "src/components/forms/Form/Form";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import * as Yup from "yup";
import Button from "src/modules/generic/components/Buttons/Button";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import {
  clearAssistatText,
  addAssistantText,
} from "src/modules/generic/store/action";
import classes from "../classes.module.scss";
import CheckboxButtonSimple, {
  CheckboxButtonOptions,
} from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import _ from "lodash";

/**
 * Description Step: Choose the authorised signatories for your ADGM company. You can select one or more GCC nationals or UAE residents.
 *
 * @returns
 */
function EligibleStakeholders() {
  const { next, context, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const { initialValues, stakeholders } = useMemo(() => {
    const initialValues = context?.EligibleStakeholders ?? {};
    const stakeholders = _.map(
      _.get(context, "initialData.stakeholdersUAEorGCC", []),
      (st) => {
        return { value: st, label: st.fullName };
      }
    );

    return { initialValues, stakeholders };
  }, [context]);

  const handleCancel = () => {
    cancel();
  };
  const handlePrev = () => {
    prev();
  };
  const handleSubmit = async (values) => {
    next(values);
  };

  useEffect(() => {
    dispatch(
      addAssistantText(
        "An ADGM authorised signatory is a person who can legally sign ADGM documents on the company’s behalf. Be sure to select at least one GCC national or UAE resident."
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const schema = Yup.object().shape({
    authorisedSignatories: Yup.array().min(1,'Required'),
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title:
            "Who will be your GCC national or UAE resident ADGM authorised signatory? (Select one or more)",
          skeleton: null,
          subTitle: "ADGM authorised signatories",
          variant: "simple",
        }}
        buttons={{
          previous: (
            <Button variant="secondary" onClick={handlePrev}>
              {translate("BUTTON_PREVIOUS")}
            </Button>
          ),
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("BUTTON_CANCEL")}
            </Button>
          ),
          submit: (
            <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>
              {translate("BUTTON_NEXT")}
            </SubmitButton>
          ),
        }}
      >
        <ControllerInput
          render={CheckboxButtonSimple}
          isMultiple={true}
          defaultValue={[]}
          name="authorisedSignatories"
          options={stakeholders}
          classNameButton={classes.buttonCheckBox}
          size="lg"
        />
      </TemplateWizard>
    </Form>
  );
}

export default EligibleStakeholders;

import React, { FC, useMemo } from "react";
import IcoMoon from "react-icomoon";
import _ from "lodash";
import { GROUPS_ICON_SET } from "./constants";
import countries from "i18n-iso-countries";
import ClaraFlag from "src/images/Clara-flag.png";
import $CLARA_FLAGS_MASTER_ICON_SET from "./ClaraFlagMaster/selection.json";

export interface FlagProps {
  alpha3?: string;
  alpha2?: string;
  color?: string;
  size?: string | number;
  className?: string;
  style?: React.CSSProperties;
  isClara?: boolean;
  removeInlineStyle?: boolean;
  title?: string;
}

const Flag: FC<FlagProps> = ({ alpha3, alpha2, size = "24px", ...props }) => {
  const iconSet: any = useMemo(() => {
    const code = alpha3 || countries.alpha2ToAlpha3(alpha2.toLocaleUpperCase());
    return _.get(
      GROUPS_ICON_SET.find((groupIconSet) =>
        _.includes(groupIconSet.alpha3Codes, code)
      ),
      "iconSet"
    );
  }, [alpha3, alpha2]);

  return (
    <>
      {iconSet ? (
        <IcoMoon
          iconSet={$CLARA_FLAGS_MASTER_ICON_SET}
          icon={alpha3}
          size={size}
          {...props}
        />
      ) : (
        <img width={size} src={ClaraFlag} alt={"Country Flag"} />
      )}
    </>
  );
};

export default Flag;

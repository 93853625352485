import useMutation from "src/hooks/custom/useMutation";
import useSession from "src/modules/session/hooks/useSession";

export const UPSERT_DATA_PROTECTION_DECLARATION = `
mutation upsertRenewalData(
    $startupId: ID!
    $groupCompanyId: ID!
	$renewalData: RenewalDataInput
  ) {
    upsertRenewalData(
      startupId: $startupId
      groupCompanyId: $groupCompanyId
      renewalData: $renewalData
    ) {
      id
  }
}
`;
export type Tasks = 'isStakeholderDetailsConfirmed' | 'areNationalitiesConfirmed'
const useUpdateDataProtectionDeclaration = () => {
  const { startupId } = useSession()
  const [mutation] = useMutation(
    UPSERT_DATA_PROTECTION_DECLARATION,
    { showSuccessNotification: true }
  );

  const save = async (groupCompanyId: string, tasksCompleted: Tasks[], stakeholderId: string) => {
    const tasks = {}
    tasksCompleted.forEach(t => {
      tasks[t] = true
    })
    const data = {
      variables: {
        startupId,
        groupCompanyId,
        renewalData: {
          stakeholderTasksDetails: {
            ...tasks,
            stakeholderId
          }
        }
      }
    }
    const submitData = await mutation(data)
    return submitData
  }

  return save;
};

export default useUpdateDataProtectionDeclaration;

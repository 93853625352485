import React, { useRef, useState } from 'react';
import Form from 'src/components/forms/Form/Form';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import CheckboxButtonSimple, { CheckboxButtonOptions } from 'src/modules/generic/components/Inputs/CheckboxButtonSimple';
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import Typography from 'src/modules/generic/components/Texts/Typography';
import FormProps from 'src/modules/generic/forms/interfaces/FormProps';
import TemplateFormDefault from "src/modules/generic/templates/Modal";
import * as Yup from 'yup';
import classes from './classes.module.scss';
import useGetContentfulFile from 'src/modules/generic/hooks/useGetContentfulTemplate';

const schema = Yup.object().shape({
  confirm:Yup.boolean().required('Required')
});
const ConfirmDocumentsForm: React.FC<FormProps & {link:string}> = ({
  initialValues = {},
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
  link
}: FormProps & {link:string}) => {
  const [showText, setShowText] = useState<boolean>(false);

  const { fetch } = useGetContentfulFile(link,{cancelAutomaticFetch:true,useDowloadFileRoute:true});

  const options: Array<CheckboxButtonOptions<boolean, any>> = [
    {
      value: true,
      label:'Yes'
    },
    {
      value: false,
      label:'No'
    }
  ]

  const handleSubmit = async values => {
      onCompleteSubmit && await onCompleteSubmit(values);
  }

  const ref = useRef(null);

  return (
    <Form
      defaultValues={initialValues || {}}
      schema={schema}
      onSubmit={handleSubmit}
      ref={ref}
      optionsForm={{ mode: "onChange" }}
    >
      <Template
        buttons={{
          ...buttons,
          submit: <SubmitButton handleDisabled={(f,getValues)=>!getValues('confirm')} {...buttonSubmitProps}>Confirm</SubmitButton>,
        }}
        props={propsTemplate}
      >
        <Typography component="p" className={classes.Text}>
        You are required to upload certain documents to your Data Room for review as part of your onboarding process. Click <a onClick={fetch}>here</a> to see a list of the required documents. Follow the instructions included in the list.     
               </Typography>
        <TemplateLabel
            label="Have you added all the required documents to the Data Room? "
            isRequired
          >
            <ControllerInput
              render={CheckboxButtonSimple}
              name='confirm'
              onChange={e => setShowText(!e)}
              options={options}
            />
          </TemplateLabel>
            { showText && 
            <Typography fontWeight='bold' component="p">
          Click Cancel and return here to confirm once all documents have been added to the Data Room.
            </Typography>
            }
      </Template>
    </Form>
  );
}

export default ConfirmDocumentsForm;
import React, { FC } from "react";
import Skeleton from '@material-ui/lab/Skeleton';
import classesModules from './ZoneMessagesPanelSkeleton.module.scss';
import Icon from "../../../../../../modules/generic/components/Icon/";

/**
 * Zone Messages Panel Skeleton
 */

export interface ZoneMessagesPanelSkeletonProps {

} 

const ZoneMessagesPanelSkeleton: FC<ZoneMessagesPanelSkeletonProps> = ({
  ...props
}:ZoneMessagesPanelSkeletonProps) => {

  return (
    <>
      {Array(3).fill(1).map((el, i) =>
        <div className={classesModules.containerMessage} key={i}>
          <div className={classesModules.iconMessage}>
            <Icon color={"#e4e4e4"} icon={"Assistant"} size={"1.8462rem"} isClara />
          </div>
          <div className={`${classesModules.containerCardMessage}`}>
            <Skeleton variant="rect" height={"1.1538rem"} width={"1.1538rem"}/>
            <label>
              <Skeleton variant="text" height={"1.0769rem"} width={"20rem"}/>
            </label>
            <Skeleton variant="rect" height={"1.1538rem"} width={"1.1538rem"}/>
            <p className={classesModules.textMessage}>
              <Skeleton variant="text" height={"0.8462rem"} width={"19rem"}/>
              <Skeleton variant="text" height={"0.8462rem"} width={"19rem"}/>
            </p>
            <label className={classesModules.dateMessage}><Skeleton variant="text" height={"0.8462rem"} width={"11.5385rem"}/></label>
          </div>
        </div>
      )}
    </>
  );
};

export default ZoneMessagesPanelSkeleton;
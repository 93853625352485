import React, { useState } from 'react';
import useFetch from '../../modules/generic/hooks/useFetch';


function useTooltip(key) {
    const [tooltips, setTooltips] = useState({});

    const onClompletedGetTooltips = (response) => {
        setTooltips(response);
    }

    useFetch({ url: `/contentful/tooltips/${key}`, onCompleted: onClompletedGetTooltips });
    /*
    useEffect(() => {
        ServerConnect.requestApi(`/contentful/tooltips/${key}`, { method: 'GET', headers: { "Content-Type": "application/json" }, }).then(res => {
            setTooltips({
                tooltips: _.get(res, "response.tooltips")
            })
        }).catch(err => {
            console.log('Tooltips from contentful error: ', err);   
        });
    }, [key]);*/

    return { tooltips };
}



export default useTooltip;
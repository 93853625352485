import _ from 'lodash';
import React, { FC, useMemo } from 'react';
import Button from "../../../../../generic/components/Buttons/Button";
import useCustomMachine from '../../../../../generic/context/MachineContext/useMachine';
import useTranslate from '../../../../../generic/hooks/useTranslate';
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import ShareForm from '../../../forms/ShareForm';

const ShareFormStep: FC<{}> = () => {

  const { stepData, next, send, state, context } = useCustomMachine();
  const { translate } = useTranslate();

  const handleCompleteSubmit = (values): void => {
    next(values)
  }

  const handleCancel = (): void => {
    send("CANCEL")
  }

  const handlePrev = (): void => {
    send("PREVIOUS")
  }

  const getCompany = (): object => {
    return context.groupCompanies[_.get(context, 'iterations', 0)]
  }

  const companyName = useMemo((): string => {
    const company = getCompany()
    return _.get(company, 'name', '')
  }, [JSON.stringify(state)]);

  const currency = useMemo((): string => {
    const company = getCompany()
    return _.get(company, 'transactionCurrency', '') ? _.get(company, 'transactionCurrency', '') : ''
  }, [JSON.stringify(state)]);

  const shareClass = useMemo((): any[] => {
    const company = getCompany()
    return _.get(company, 'shareClass')
  }, [JSON.stringify(state)]);

  return (
    <>
      <ShareForm
        currency={currency}
        shareClassList={shareClass}
        propsTemplate={{
          title: translate('MODULES.EQUITY.STEPS_EQUITY_POSITIONS.SHARE_FORM_STEP.TITLE'),
          subTitle: translate('MODULES.EQUITY.STEPS_EQUITY_POSITIONS.SHARE_FORM_STEP.SUBTITLE', { type: translate(`MODULES.EQUITY.TYPES_PLURAL.SHARE`) })
        }}
        companyName={companyName}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        buttons={{ cancel: <Button onClick={handleCancel}>Cancel</Button>}} />
    </>
  );
}
export default ShareFormStep
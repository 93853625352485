import React from 'react';
import { Avatar } from '../../../../../../../v1/components';
import Tag from '../../../../../../../v1/components/Tag/Tag';
import Typography from '../../../../../../../modules/generic/components/Texts/Typography';
import classesModal from "./NameSharedWithTag.module.scss";

const NameSharedWithTag = ({
  fullName,
  avatar,
  lastLogin,
  role,
  email,
}) => {

  return (
    <>
      <div className={classesModal.tagContainer} >
        <div>
          <Avatar
            name={fullName}
            avatar={avatar}
            showLabel={false}
          />
        </div>
        <div className={classesModal.dataContainer}>
          <div className={classesModal.nameContainer}>
            <div className={classesModal.name}>
              <Typography variant='m' fontWeight='regular'>{fullName}</Typography>
            </div>
            {!lastLogin && role !== 'USER_INACTIVE' ? <Tag type={'invitedShareProfile'} show={true} /> : null}
          </div>
          <div className={classesModal.separe}>
            <Typography variant='m' fontWeight='regular'>{email}</Typography>
          </div>
        </div>
      </div>
    </>
  )
}

export default NameSharedWithTag;

import { useMemo } from 'react';
import _ from 'lodash';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

const useGuards = () => {
  const evaluateADGMManagedByClara = (context) => {
    const onlyOneCompany = context?.getStakeholdersGroupCompanies?.groupCompanies?.length === 1;
    const onlyCompany = onlyOneCompany ? context?.getStakeholdersGroupCompanies?.groupCompanies[0] : null;
    const isOnlyCompanyADGMOrCayman = (onlyCompany?.jurisdictionType === "ADGM" || onlyCompany?.jurisdictionType === "CAYMAN") && (onlyCompany?.kind === "FORMATION" || onlyCompany.managedByClara);

    const legalEntityFormations = _.filter(context?.groupCompanies, (company) => (company.jurisdictionType ==="ADGM" || company.jurisdictionType ==="CAYMAN") && (company.kind === "FORMATION" || company.managedByClara) );
    const areFormationsAvailable = legalEntityFormations?.length > 0;
    const rolesForLegalEntityFormations = context?.roles?.includes("DIRECTOR") || context?.roles?.includes("SHAREHOLDER");

    const isEntity = context?.isEntity;

    return isEntity && (areFormationsAvailable || isOnlyCompanyADGMOrCayman) && rolesForLegalEntityFormations;
  }

  return useMemo(() => ({
    noGroupCompanies: (context) => {
      return isEmpty(get(context, 'getStakeholdersGroupCompanies.groupCompanies', []))
    },
    havesGroupCompanies: (context) => {
      return !isEmpty(get(context, 'getStakeholdersGroupCompanies.groupCompanies', []))
    },
    is1GroupCompany: (context) => {
      return get(context, 'getStakeholdersGroupCompanies.groupCompanies').length === 1 || context.roles.length === 0
    },
    isNot1GroupCompany: (context) => {
      return get(context, 'getStakeholdersGroupCompanies.groupCompanies').length > 1 && context.roles.length > 0
    },
    isFinishRoles: (context) => {
      if (get(context, 'getStakeholdersGroupCompanies.groupCompanies', []).length <= 1) return true
      return get(context, 'roles', []).length <= get(context, 'iterations', 0)
    },
    isFinishNotRoles: (context) => {
      if (get(context, 'getStakeholdersGroupCompanies.groupCompanies', []).length <= 1) return false
      return get(context, 'roles', []).length > get(context, 'iterations', 0)
    },
    isFinish: (context) => {
      return !get(context, 'another')
    },
    isNotFinish: (context) => {
      return get(context, 'another')
    },
    isAddGoal: (context) => {
      return get(context, 'enable_clara_concierge')
    },
    isNotAddGoal: (context) => {
      return !get(context, 'enable_clara_concierge')
    },
    isManagedByClaraADGM: (context) => {
      return evaluateADGMManagedByClara(context) || context?.isEntity;
    },
    isNotManagedByClaraADGM: (context) => {
      return !evaluateADGMManagedByClara(context);
    },
  }), []);
}

export default useGuards;
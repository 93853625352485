import React, {Component} from "react";
import classes from "./ClaraScore.module.scss"
import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {ClaraScoreForm, RoleAccess, Modal} from "../../index"
import {Constants} from '../../../utils/constants';

class ClaraScore extends Component {
    state = {
        value: null,
        hover: false,
        openModal: false,
    };
    getStyleData() {
        if (this.props.score < 25) {
            return {
                path: { stroke: "#f43e3e" },
                text: { fill: '#000', fontSize: '35px' },
            };
        }
        if (this.props.score < 50) {
            return {
                path: { stroke: "#ffb946" },
                text: { fill: '#000', fontSize: '35px' },
            };
        }
        if (this.props.score < 75) {
            return {
                path: { stroke: "#0071bc" },
                text: { fill: '#000', fontSize: '35px' },
            };
        }
        return {
            path: { stroke: "#5dd852" },
            text: { fill: '#000', fontSize: '35px' },
        };

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.session !== this.props.session) {

        }
    }
    mouseOver = () => {
        this.setState({
            hover: true
        });
    }
    mouseOut = () => {
        this.setState({
            hover: false
        });
    }
    onClick = () => {
        this.setState({
            openModal: true
        })
    }
    handleClose = () => {
        this.setState({
            openModal: false
        })
    }
    render() {
        const styleData = this.getStyleData();
        let score = this.props.score;
        let data = { score };
        let { hover, openModal } = this.state;
        if (!score) {
            score = 0;
        }
        let classHover = classes.ClaraScoreHoverOff;
        if (hover) {
            classHover = classes.ClaraScoreHoverOn;
        }
        return (
            <div className={classes.ClaraScore}>
                <div className={classes.ClaraText}>Clara Score</div>
                <RoleAccess action={Constants.ACTIONS.EDIT_CLARA_SCORE}>
                    <div className={classes.ClaraContent} >

                        <div className={classes.ClaraContentPercent} onClick={() => this.onClick()} onMouseOver={() => this.mouseOver()} onMouseOut={() => this.mouseOut()}>
                            <CircularProgressbar
                                percentage={score}
                                text={`${score}`}
                                styles={styleData}
                                strokeWidth={5}
                            />
                            <div className={classHover}>

                            </div>
                        </div>

                    </div>
                    <Modal open={openModal} handleClose={this.handleClose} title={`Clara Score`}>
                    <ClaraScoreForm startupId={this.props.startupId} data={data} handleClose={this.handleClose}></ClaraScoreForm>
                </Modal>
                </RoleAccess>

                <RoleAccess action={Constants.ACTIONS.EDIT_CLARA_SCORE} noAccess={true}>
                    <div className={classes.ClaraContent} >

                        <div className={classes.ClaraContentPercent}>
                            <CircularProgressbar
                                percentage={score}
                                text={`${score}`}
                                styles={styleData}
                                strokeWidth={5}
                            />
                            <div className={classHover}>

                            </div>
                        </div>

                    </div>

                </RoleAccess>
                

            </div >
        );
    }
}


export default ClaraScore

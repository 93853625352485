import _ from "lodash";
import useGetStakeholdersGroupCompaniesLazy from "src/modules/startup/onBoarding/core/graphql/queries/useGetStakeholdersGroupCompaniesLazy";
import useGetStakeholders from "src/hooks/services/modules/stakeholders/useGetStakeholders";
import useSession from "src/modules/session/hooks/useSession";
import useCreateStakeholder from "src/modules/startup/onBoarding/core/graphql/mutations/useCreateStakeholder";
import useUpdateStakeholder from "src/modules/startup/onBoarding/core/graphql/mutations/useUpdateStakeholder";
import useUpsertGroupCompany from "./graphql/useUpsertGroupCompanyAuthorisedSignatory";
import useUpsertFormationCompany from "./graphql/useUpsertFormationCompany";
import moment from "moment";
const useServices = ({ initialValues }) => {
  const { startupId } = useSession();
  const {
    adgmAuthorisedSignatories: signatories,
    groupCompanyId,
  } = initialValues;
  const {
    manualQuery: manualQueryStakeholdersGroupCompanies,
  } = useGetStakeholdersGroupCompaniesLazy();

  const mutation = useUpsertGroupCompany();
  const [upsertFormation] = useUpsertFormationCompany()
  const { createStakeholder } = useCreateStakeholder(
    {
      showSuccessNotification: false,
      successMessage: "",
    },
    {}
  );
  const { updateStakeholder } = useUpdateStakeholder(
    {
      showSuccessNotification: false,
      successMessage: "",
    },
    {}
  );

  //Query for UAE or GCC Stakeholders
  const { manualQuery: manualQueryUAEorGCC } = useGetStakeholders({
    variables: {
      startupId,
      groupCompanyId,
      filters: {
        kind: "STAKEHOLDER_INDIVIDUAL",
        nationalityGroup: ["UAE", "GCC"],
      },
    },
  });

  //Query for Not GCC nor UAE Stakeholders
  const { manualQuery: manualQueryNotUAEOrGCC } = useGetStakeholders({
    variables: {
      startupId,
      groupCompanyId,
      filters: {
        kind: "STAKEHOLDER_INDIVIDUAL",
        nationalityGroup: ["NOT_GCC", "NOT_UAE"]
      },
    },
  });

  //Query for UAE or GCC Stakeholders without role DIRECTOR or SHAREHOLDER
  const { manualQuery: manualQueryUAEorGCCNotRole } = useGetStakeholders({
    variables: {
      startupId,
      groupCompanyId,
      filters: {
        kind: "STAKEHOLDER_INDIVIDUAL",
        nationalityGroup: ["UAE", "GCC"],
        role: ['FOUNDER', 'ADVISOR', 'EMPLOYEE', 'NOREMOVE', 'CONSULTANT', 'INVESTOR', 'CONVERTIBLEHOLDER', 'WARRANTHOLDER', 'OPTIONHOLDER', 'IPASSIGNOR', 'AUTHORIZEDSIGNATORY', 'THIRDPARTY']
      },
    },
  });

  const initialData = async () => {
    const result = await manualQueryStakeholdersGroupCompanies();
    const stakeholdersUAEorGCC = await manualQueryUAEorGCC();
    const stakeholdersUAEorGCCNoRole = await manualQueryUAEorGCCNotRole();
    const stakeholdersNotUAEOrGCC = await manualQueryNotUAEOrGCC();

    const groupCompanies = _.orderBy(
      result?.data?.getGroupCompanies,
      ["isHoldingCompany"],
      ["desc"]
    );

    const groupCompaniesMapped = groupCompanies.map((gc) => ({
      disabled: gc?.isLocked || groupCompanyId === gc.id,
      label: gc?.name,
      value: gc?.id,
    }));

    return {
      groupCompanyId,
      signatories,
      stakeholdersUAEorGCC: stakeholdersUAEorGCC?.data?.stakeholderList,
      stakeholdersUAEorGCCNoRole:
        stakeholdersUAEorGCCNoRole?.data?.stakeholderList,
      stakeholdersNotUAEOrGCC: stakeholdersNotUAEOrGCC?.data?.stakeholderList,
      groupCompaniesMapped,
    };
  };

  const SaveFormationStep = async (context) => {
    const shouldCreateStakeholder = !!context?.CreateStakeholderStep;
    const nomineeStep = context?.ClaraNomineeStep;
    const useNominee = nomineeStep?.useNominee === true || nomineeStep?.useNominee === "true"
    const isThereSecondary = context?.IsThereSecondarySignatoryStep?.isThereSecondary === true || context?.IsThereSecondarySignatoryStep?.isThereSecondary === "true"
    const signatoryFromContext =
      context?.AssignSignatoryStep?.authorisedSignatory ||
      context?.AssignOtherSignatoriesStep?.authorisedSignatory;

    let stakeholder = null;

    if (shouldCreateStakeholder) {
      const { fullName, company } = context?.CreateStakeholderStep;
      const enterType = context?.UAEInfoStep?.howDidTheyEnter;
      const entryDate = moment(context?.UAEInfoStep?.entryDate).format('YYYY-MM-DD');
      const mapRoles = _.map(company, (c) => ({
        entityId: c,
        entityType: "GROUP_COMPANY",
        role: [
          {
            type: "AUTHORIZEDSIGNATORY",
          },
          {
            type: "THIRDPARTY",
          },
        ],
      }));
      const stakeholder = {
        fullName,
      }
      if (useNominee) {
        stakeholder['enterType'] = enterType;
        if (entryDate) stakeholder['entryDate'] = entryDate;
      }
      const variables = {
        startupId,
        stakeholderData: {
          stakeholder,
          roles: mapRoles,
        },
      };
      const stakeholderData = await createStakeholder({ variables });
      stakeholder = stakeholderData?.data?.createStakeholder;
    } else {
      if (isThereSecondary || useNominee) {
        const { id } = context?.AssignOtherSignatoriesStep?.authorisedSignatory;
        const enterType = context?.UAEInfoStep?.howDidTheyEnter;
        const entryDate = moment(context?.UAEInfoStep?.entryDate).format('YYYY-MM-DD');
        const variables = {
          startupId,
          stakeholderId: id,
          stakeholderData: {
            stakeholder: {
              enterType,
              entryDate
            },
          },
        };
        await updateStakeholder({ variables });
      }
    }

    const signatory = shouldCreateStakeholder
      ? stakeholder
      : signatoryFromContext;

    const useClaraNominee =
      context?.ClaraNomineeStep?.useNominee === true ||
      context?.ClaraNomineeStep?.useNominee === "true";

    const signatories = [signatory?.id]

    if (isThereSecondary && context?.initialData?.stakeholdersNotUAEOrGCC.length > 0) signatories.push(context?.AssignOtherSignatoriesStep?.authorisedSignatory.id)

    const companyData = {
      formationData: {
        authorisedSignatories: signatories,
        isUsingNominee: useClaraNominee,
      }
    };
    const variables = {
      startupId,
      groupCompanyId,
      groupCompanyData: companyData,
    };
    return await upsertFormation({ variables });

  }

  const SaveStep = async (context) => {
    const shouldCreateStakeholder = !!context?.CreateStakeholderStep;
    const nomineeStep = context?.ClaraNomineeStep;
    const useNominee = nomineeStep?.useNominee === true || nomineeStep?.useNominee === "true"
    const isThereSecondary = context?.IsThereSecondarySignatoryStep?.isThereSecondary === true || context?.IsThereSecondarySignatoryStep?.isThereSecondary === "true"
    const signatoryFromContext =
      context?.AssignSignatoryStep?.authorisedSignatory ||
      context?.AssignOtherSignatoriesStep?.authorisedSignatory;
    let stakeholder = null;

    if (shouldCreateStakeholder) {
      const { fullName, company } = context?.CreateStakeholderStep;
      const enterType = context?.UAEInfoStep?.howDidTheyEnter;
      const entryDate = moment(context?.UAEInfoStep?.entryDate).format('YYYY-MM-DD');
      const mapRoles = _.map(company, (c) => ({
        entityId: c,
        entityType: "GROUP_COMPANY",
        role: [
          {
            type: "AUTHORIZEDSIGNATORY",
          },
          {
            type: "THIRDPARTY",
          },
        ],
      }));
      const stakeholder = {
        fullName,
      }
      if (useNominee) {
        stakeholder['enterType'] = enterType;
        if (entryDate) stakeholder['entryDate'] = entryDate;
      }
      const variables = {
        startupId,
        stakeholderData: {
          stakeholder,
          roles: mapRoles,
        },
      };
      const stakeholderData = await createStakeholder({ variables });
      stakeholder = stakeholderData?.data?.createStakeholder;
    } else {
      if (isThereSecondary || useNominee) {
        const { id } = context?.AssignOtherSignatoriesStep?.authorisedSignatory;
        const enterType = context?.UAEInfoStep?.howDidTheyEnter;
        const entryDate = moment(context?.UAEInfoStep?.entryDate).format('YYYY-MM-DD');
        const variables = {
          startupId,
          stakeholderId: id,
          stakeholderData: {
            stakeholder: {
              enterType,
              entryDate
            },
          },
        };
        await updateStakeholder({ variables });
      }
    }

    const signatory = shouldCreateStakeholder
      ? stakeholder
      : signatoryFromContext;

    const useClaraNominee =
      context?.ClaraNomineeStep?.useNominee === true ||
      context?.ClaraNomineeStep?.useNominee === "true";

    const signatories = [signatory?.id]

    if (isThereSecondary && context?.initialData?.stakeholdersNotUAEOrGCC.length > 0) signatories.push(context?.AssignOtherSignatoriesStep?.authorisedSignatory.id)

    const companyData = {
      keyPeople: {
        authorisedSignatories: signatories,
        useClaraNominee,
      },
    };
    const variables = {
      startupId,
      groupCompanyId,
      groupCompany: companyData,
    };
    return await mutation({ variables });
  };

  return { initialData, SaveStep, SaveFormationStep };
};

export default useServices;

import React, { FC, useCallback, useMemo } from "react";
import AssistanceFrame2 from "./";
import { Constants } from "src/v1/utils/constants.js";
import EconomicSubstanceModal from "src/views/modules/startups/renewal/cayman/modals/EconomicSubstanceModal";
import GroupCompanyTasks from "../../../../modules/assistanceV4/components/AssistanceAreas/GroupCompany2/Tasks";
import ModalAddShare from "../../../startup/equity/company/share/modals/AddSharesModal";
import ModalAddressAndCurrency from "../../../startup/GroupCompany/AddressAndCurrency/ModalAddressAndCurrency";
import ModalConstitutionalDocuments from "../../../clara/components/Accelerator/ConstitutionalDocuments";
import ModalDetailCompany from "../../../startup/GroupCompany/Details/ModalDetailCompany";
import { ModalIpAndGenerate } from "../../../startup/Documents";
import ModalMarkTaskCompleted from "src/modules/map/modals/ModalMarkTaskCompleted";
import ModalNotices from "../../../startup/GroupCompany/Notices/ModalNotices";
import ModalSIPDoc from "src/modules/startup/Documents/Sip/ModalSIPDoc";
import ReviewAuthorisedSignatory from "src/views/modules/startups/renewal/adgm/modals/ReviewAuthorisedSignatory";
import ReviewAddressesModal from "src/views/modules/startups/renewal/adgm/modals/ReviewAddressesModal";
import ReviewAndConfirmCapTableModal from "src/views/modules/startups/renewal/adgm/modals/ReviewAndConfirmCapTableModal";
import ReviewCompanyDetailsModal from "src/views/modules/startups/renewal/adgm/modals/ReviewCompanyDetailsModal";
import ReviewDataProtectionContact from "src/modules/startup/GroupCompany/ADGM/renewals/ReviewDataProtectionContact";
import ReviewDataProtectionDeclaration from "src/modules/startup/GroupCompany/ADGM/renewals/ReviewDataProtectionDeclaration";
import ReviewDirectorsModal from "src/views/modules/startups/renewal/adgm/modals/ReviewDirectorsModal";
import TechLicenceModal from "src/views/modules/startups/renewal/adgm/modals/TechLicenceModal";
import UploadAnnualAccountsModal from "src/views/modules/startups/renewal/cayman/modals/UploadAnnualAccountsModal";
import _ from "lodash";
import { goToPage } from "src/store/actions/page.js";
import { mapGroupCompanyTaskWithParam } from "./helper";
import useBusinessRules from "../../../../modules/clara/hooks/useBusinessRules";
import { useDispatch } from "react-redux";
import useFormat from "../../../generic/hooks/useFormat";
import useGroupCompanyTasks from "../../hooks/services/useGroupCompanyTasks";
import useModal from "../../../generic/hooks/useModal";
import { useParams } from "react-router-dom";
import useSession from "../../../session/hooks/useSession";
import useTabStatus from "../../hooks/useTabStatus";
import useTasks from "../../hooks/useTasks";
import GroupCompany from "src/components/modules/clara/startups/entities/classes/GroupCompany";
/**
 * Assistance Frame for GroupCompany
 */
export interface AssistanceGroupCompany2Props { }

let restCount = 0;

const AssistanceGroupCompany2: FC<AssistanceGroupCompany2Props> = ({
  ...props
}: AssistanceGroupCompany2Props) => {
  const { startupId } = useSession();
  const { id: idGroupCompany } = useParams();
  const { openModal } = useModal();
  const { moneyFormat, dateFormat } = useFormat();

  const paramsMutation = useMemo(() => {
    return {
      startupId,
      groupCompanyId: idGroupCompany,
    };
  }, [startupId, idGroupCompany]);

  const { data: dataGroupCompany } = useGroupCompanyTasks(paramsMutation);
  const { isAcceleratorGoal } = useBusinessRules();
  const dispatch = useDispatch();

  const handleDeleteTask = useCallback(
    async (params, task) => {
      openModal(
        ModalMarkTaskCompleted,
        {
          groupCompanyId: idGroupCompany,
          taskCode: task,
        },
        "MODAL_MARK_TASK_COMPLETED"
      );
    },
    [startupId]
  );

  const allTasks = useMemo(() => {
    /** Get ShareClass Tasks */
    const getShareClassTaks = () => {
      let shareClassTask = [];
      _.forEach(
        dataGroupCompany?.getGroupCompany?.shareClass ?? [],
        (shareClass) => {
          _.forEach(shareClass?.shareIncentivePlans ?? [], (sip) => {
            _.forEach(sip?.tasks ?? [], (task) => {
              shareClassTask.push({
                ...task,
                params: {
                  id: sip?.id,
                  totalShares: moneyFormat(sip?.totalShares, true),
                  className: shareClass?.name,
                  shareClassId: shareClass?.id,
                },
              });
            });
          });
        }
      );
      return shareClassTask;
    };
    if (!dataGroupCompany) return [];
    let result = _.map(
      _.get(dataGroupCompany, "getGroupCompany.tasks", []),
      (task) =>
        mapGroupCompanyTaskWithParam(
          task,
          idGroupCompany,
          _.get(dataGroupCompany, "getGroupCompany")
        )
    );
    let equity = _.get(dataGroupCompany.getGroupCompany, "equity", []);
    let equityTasks = _.flatten(
      _.map(equity, (eq) =>
        _.map(eq.tasks, (task) =>
          mapGroupCompanyTaskWithParam(task, idGroupCompany, eq, {
            moneyFormat,
            dateFormat,
          })
        )
      )
    );
    let shareClassTask = getShareClassTaks();
    const allTasksData = [...result, ...equityTasks, ...shareClassTask];
    return allTasksData;
  }, [JSON.stringify(dataGroupCompany)]);

  const configDataGroupCompanyOriginal = useMemo(() => {
    return {
      READY: [
        {
          code: "TASK_ADD_COMPANY_INFORMATION",
          onClick: () =>
            openModal(
              ModalDetailCompany,
              {
                paramsMutation: {
                  startupId,
                  groupCompanyId: idGroupCompany,
                },
              },
              "MODAL_UNDER_CONTRUCTION"
            ),
        },
        {
          code: "TASK_ADD_ADDRESS_CURRENCY",
          onClick: () =>
            openModal(
              ModalAddressAndCurrency,
              {
                paramsMutation: {
                  startupId,
                  groupCompanyId: idGroupCompany,
                },
              },
              "MODAL_UNDER_CONTRUCTION"
            ),
        },
        {
          code: "TASK_ADD_COMPANY_NOTICES",
          onClick: () =>
            openModal(
              ModalNotices,
              {
                paramsMutation: {
                  startupId,
                  groupCompanyId: idGroupCompany,
                },
              },
              "MODAL_UNDER_CONTRUCTION"
            ),
        },
        {
          code: "TASK_ADD_CERTIFICATE_INCORPORATION",
          onClick: () =>
            openModal(
              ModalIpAndGenerate,
              {
                paramsMutation: {
                  groupCompanyId: idGroupCompany,
                  type: "CERTIFICATE_OF_INCORPORATION",
                },
              },
              "MODAL_UNDER_CONTRUCTION"
            ),
        },
        {
          code: "TASK_ADD_COMMERCIAL_LICENSE",
          onClick: () =>
            openModal(
              ModalIpAndGenerate,
              {
                paramsMutation: {
                  groupCompanyId: idGroupCompany,
                  type: "TRADE_LICENSE",
                },
              },
              "MODAL_UNDER_CONTRUCTION"
            ),
        },
        {
          code: "TASK_ADD_OTHER_CONSTITUTIONAL_DOCUMENTS",
          onClick: () =>
            openModal(
              ModalIpAndGenerate,
              {
                paramsMutation: {
                  groupCompanyId: idGroupCompany,
                  type: "CONSTITUTIONAL_DOCUMENTS",
                },
              },
              "MODAL_UNDER_CONTRUCTION"
            ),
        },
        {
          code: "TASK_ADD_SHARE_AGREEMENT",
          onClick: (params) =>
            openModal(ModalIpAndGenerate, {
              paramsMutation: {
                groupCompanyId: idGroupCompany,
                ...params,
                type: "REGISTER_OF_SHARES",
              },
            }),
        },
        {
          code: "TASK_ADD_COMPANY_EQUITY_POSITIONS",
          onClick: () =>
            openModal(
              ModalAddShare,
              { initialValues: { type: "groupCompany", id: idGroupCompany } },
              "MODAL_ADD_TASK_COMPANY_EQUITY_POSITIONS"
            ),
        },
        {
          code: "TASK_ADD_SHARE_INCENTIVE_PLAN_AGREEMENT",
          onClick: (params) =>
            openModal(
              ModalSIPDoc,
              { paramsMutation: { groupCompanyId: idGroupCompany, ...params } },
              "MODAL_UNDER_CONTRUCTION"
            ),
        },
      ],
      ADGM: [
        { code: "TASK_COMPANY_SET_UP" },
        { code: "TASK_ASSETS_AND_REVENUE" },
        { code: "TASK_ADGM_AUTHORISED_SIGNATORY" },
        { code: "TASK_BANKING_AUTHORITY" },
        { code: "TASK_ACCOUNTING_RECORDS" },
        { code: "TASK_DATA_PROTECTION_CONTACT" },
        { code: "TASK_SHARE_CAPITAL" },
        { code: "TASK_ADGM_BUSINESS_PLAN" },
      ],
      CONFIRM: [
        { code: "TASK_CONFIRM_UPLOAD_CERT_OF_INC" },
        { code: "TASK_CONFIRM_COMPANY_DETAILS" },
      ],
      DETAILS: [
        { code: "TASK_CONFIRM_DIRECTORS_AND_SHAREHOLDERS" },
        { code: "TASK_NATURE_OF_BUSINESS" },
      ],
      REVIEW: [
        { code: "TASK_DOC_REVIEW_RES_OF_INC_SHAREHOLDER" },
        { code: "TASK_DOC_REVIEW_BUSINESS_PLAN" },
        { code: "TASK_DOC_REVIEW_DATA_PROTECTION_QUESTIONNAIRE" },
        { code: "TASK_DOC_REVIEW_CORPORATE_SHAREHOLDER_RESOLUTION" },
      ],
      ACCELERATOR: [
        {
          code: "TASK_ADD_COMPANY_INFORMATION_GRFI_ACCELERATOR",
          onClick: () =>
            openModal(
              ModalDetailCompany,
              {
                paramsMutation: {
                  startupId,
                  groupCompanyId: idGroupCompany,
                },
              },
              "MODAL_UNDER_CONTRUCTION"
            ),
        },
        {
          code: "TASK_ADD_ADDRESS_CURRENCY_GRFI_ACCELERATOR",
          onClick: () =>
            openModal(
              ModalAddressAndCurrency,
              {
                paramsMutation: {
                  startupId,
                  groupCompanyId: idGroupCompany,
                },
              },
              "MODAL_UNDER_CONTRUCTION"
            ),
        },
        {
          code: "TASK_ADD_COMPANY_NOTICES_GRFI_ACCELERATOR",
          onClick: () =>
            openModal(
              ModalNotices,
              {
                paramsMutation: {
                  startupId,
                  groupCompanyId: idGroupCompany,
                },
              },
              "MODAL_UNDER_CONTRUCTION"
            ),
        },
        {
          code: "TASK_ADD_CERTIFICATE_INCORPORATION_GRFI_ACCELERATOR",
          onClick: () =>
            openModal(
              ModalIpAndGenerate,
              {
                paramsMutation: {
                  groupCompanyId: idGroupCompany,
                  type: "CERTIFICATE_OF_INCORPORATION",
                },
              },
              "MODAL_UNDER_CONTRUCTION"
            ),
        },
        {
          code: "TASK_ADD_COMPANY_EQUITY_POSITIONS_GRFI_ACCELERATOR",
          onClick: () =>
            openModal(
              ModalAddShare,
              { initialValues: { type: "groupCompany", id: idGroupCompany } },
              "MODAL_ADD_TASK_COMPANY_EQUITY_POSITIONS"
            ),
        },
        {
          code: "TASK_ADD_COMPANY_INFORMATION_ACCELERATOR",
          onClick: () =>
            openModal(
              ModalDetailCompany,
              {
                paramsMutation: {
                  startupId,
                  groupCompanyId: idGroupCompany,
                },
              },
              "MODAL_UNDER_CONTRUCTION"
            ),
        },
        {
          code: "TASK_ADD_CONSTITUTIONAL_DOCUMENTS_ACCELERATOR",
          onClick: () => {
            openModal(ModalConstitutionalDocuments);
          },
        },
        {
          code: "TASK_ADD_SHARE_AGREEMENT",
          onClick: (params) =>
            openModal(ModalIpAndGenerate, {
              paramsMutation: {
                groupCompanyId: idGroupCompany,
                ...params,
                type: "REGISTER_OF_SHARES",
              },
            }),
        },
        {
          code: "TASK_ADD_COMPANY_EQUITY_POSITIONS",
          onClick: () =>
            openModal(
              ModalAddShare,
              { initialValues: { type: "groupCompany", id: idGroupCompany } },
              "MODAL_ADD_TASK_COMPANY_EQUITY_POSITIONS"
            ),
        },
        {
          code: "TASK_ADD_SHARE_INCENTIVE_PLAN_AGREEMENT",
          onClick: (params) =>
            openModal(
              ModalSIPDoc,
              { paramsMutation: { groupCompanyId: idGroupCompany, ...params } },
              "MODAL_UNDER_CONTRUCTION"
            ),
        },
        {
          code: "TASK_SHARE_CLASSES_ADD",
          onClick: (params) => {
            goToPage(dispatch, Constants.PAGES["capTable.mf.shareClass.list"], {
              groupCompanyId: idGroupCompany,
            });
          },
          showHelp: false,
          tag: "Company",
        },
        {
          code: "TASK_SHARE_ISSUANCES_ADD",
          onClick: (params) => {
            goToPage(dispatch, Constants.PAGES["capTable.mf.shares.list"], {
              groupCompanyId: idGroupCompany,
            });
          },
          showHelp: false,
          tag: "Company",
        },
        {
          code: "TASK_OPTION_PLANS_OPTION_GRANTS_ADD",
          onClick: (params) => {
            goToPage(dispatch, Constants.PAGES["capTable.mf.options.list"], {
              groupCompanyId: idGroupCompany,
            });
          },
          onDelete: (params) => {
            handleDeleteTask(params, "TASK_OPTION_PLANS_OPTION_GRANTS_ADD");
          },
          showHelp: false,
          tag: "Company",
        },
        {
          code: "TASK_CONVERTIBLES_ADD",
          onClick: (params) => {
            goToPage(
              dispatch,
              Constants.PAGES["capTable.mf.convertibles.list"],
              { groupCompanyId: idGroupCompany }
            );
          },
          onDelete: (params) => {
            handleDeleteTask(params, "TASK_CONVERTIBLES_ADD");
          },
          showHelp: false,
          tag: "Company",
        },
        {
          code: "TASK_WARRANTS_ADD",
          onClick: (params) => {
            goToPage(dispatch, Constants.PAGES["capTable.mf.warrants.list"], {
              groupCompanyId: idGroupCompany,
            });
          },
          onDelete: (params) => {
            handleDeleteTask(params, "TASK_WARRANTS_ADD");
          },
          showHelp: false,
          tag: "Company",
        },
      ],
      CAYMAN_START_RENEWAL_TASKS: [
        { code: "TASK_RENEWAL_KY_START_RENEWAL_PROCESS" },
      ],
      ADGM_START_RENEWAL_PROCESS: [
        { code: "TASK_RENEWAL_ADGM_START_RENEWAL_PROCESS" },
      ],
      CAYMAN_RENEWAL_TASKS: [
        {
          code: "TASK_RENEWAL_KY_REVIEW_ECONOMIC_SUBSTANCE_NOTIFICATION",
          onClick: (params) => {
            openModal(
              EconomicSubstanceModal,
              {
                initialValues: {
                  groupCompanyId: idGroupCompany,
                },
              },
              "MODAL_ECONOMIC_SUBSTANCE"
            );
          },
        },
        {
          code: "TASK_RENEWAL_KY_UPLOAD_FINANCIAL_ACCOUNTS",
          onClick: (params) => {
            openModal(
              UploadAnnualAccountsModal,
              {
                ...params,
                initialValues: {
                  groupCompanyId: idGroupCompany,
                },
              },
              "MODAL_UPLOAD_ANNUAL_ACCOUNTS"
            );
          },
        },
      ],
      CAYMAN_RENEWAL_COMPANY_IN_RENEWAL_PROCESS: [
        {
          code: "TASK_RENEWAL_KY_REVIEWING_DATA",
        },
      ],
      ADGM_RENEWAL_COMPANY_IN_RENEWAL_PROCESS: [
        {
          code: "TASK_RENEWAL_ADGM_REVIEWING_DATA",
        },
      ],
      CAYMAN_ONLY_PINCAS_COMPANY_IN_PROCESS: [
        {
          code: "TASK_ONLY_PINCAS_REVIEWING_DATA",
        },
      ],
      CAYMAN_RENEWAL_STRIKE_OFF: [
        {
          code: "TASK_RENEWAL_KY_STRIKE_OFF_PAY",
        },
        {
          code: "TASK_RENEWAL_KY_STRIKE_OFF_SUBMIT",
        },
      ],
      ADGM_RENEWAL_STRIKE_OFF: [
        {
          code: "TASK_RENEWAL_ADGM_STRIKE_OFF_PAY",
        },
        {
          code: "TASK_RENEWAL_ADGM_STRIKE_OFF_SUBMIT",
        },
      ],

      CAYMAN_RENEWAL_COMPANY_IN_STRIKE_OFF_PROCESS: [
        {
          code: "TASK_RENEWAL_KY_STRIKE_OFF_REVIEWING_DATA",
        },
      ],
      ADGM_RENEWAL_COMPANY_IN_STRIKE_OFF_PROCESS: [
        {
          code: "TASK_RENEWAL_ADGM_STRIKE_OFF_REVIEWING_DATA",
        },
      ],
      ADGM_RENEWAL_TASKS: [
        {
          code: "TASK_RENEWAL_ADGM_COMPLETE_TECH_LICENCE_QUESTIONNAIRE",
          onClick: (params) => {
            openModal(
              TechLicenceModal,
              {
                ...params,
                initialValues: {
                  groupCompanyId: idGroupCompany,
                  groupCompanyName: dataGroupCompany?.getGroupCompany?.name,
                },
              },
              "MODAL_TECH_LICENCE"
            );
          },
        },
        {
          code: "TASK_RENEWAL_ADGM_REVIEW_COMPANY_DETAILS",
          onClick: (params) => {
            openModal(
              ReviewCompanyDetailsModal,
              {
                initialValues: {
                  groupCompanyId: idGroupCompany,
                },
              },
              "MODAL_REVIEW_COMPANY_DETAILS"
            );
          },
        },
        {
          code: "TASK_RENEWAL_ADGM_REVIEW_ADDRESSES",
          onClick: () => {
            openModal(
              ReviewAddressesModal,
              {
                initialValues: {
                  groupCompanyId: idGroupCompany,
                },
              },
              "MODAL_START_RENEWAL_PROCESS"
            );
          },
        },
        {
          code: "TASK_RENEWAL_ADGM_REVIEW_DIRECTORS",
          onClick: (params) => {
            openModal(
              ReviewDirectorsModal,
              {
                initialValues: {
                  groupCompanyId: idGroupCompany,
                },
              },
              "MODAL_REVIEW_DIRECTORS"
            );
          },
        },
        {
          code: "TASK_RENEWAL_ADGM_REVIEW_DATA_PROTECTION_CONTACT",
          onClick: (params) => {
            openModal(
              ReviewDataProtectionContact,
              {
                ...params,
                initialValues: {
                  groupCompanyId: idGroupCompany,
                },
              },
              "MODAL_REVIEW_DATA_PROTECTION_CONTACT"
            );
          },
        },
        {
          code: "TASK_RENEWAL_ADGM_REVIEW_AUTHORISED_SIGNATORY",
          onClick: () => {
            openModal(
              ReviewAuthorisedSignatory,
              {
                initialValues: { groupCompanyId: idGroupCompany },
              },
              "MODAL_REVIEW_AUTHORISED_SIGNATORY"
            );
          },
        },
        {
          code: "TASK_RENEWAL_ADGM_REVIEW_CAP_TABLE",
          onClick: (params) => {
            openModal(
              ReviewAndConfirmCapTableModal,
              {
                initialValues: {
                  groupCompanyId: idGroupCompany,
                },
              },
              "MODAL_REVIEW_AND_CONFIRM_CAP_TABLE"
            );
          },
        },
        {
          code: "TASK_RENEWAL_ADGM_REVIEW_DATA_PROTECTION_DECLARATION",
          onClick: (params) => {
            openModal(
              ReviewDataProtectionDeclaration,
              {
                ...params,
                initialValues: {
                  groupCompanyId: idGroupCompany,
                },
              },
              "MODAL_DATA_PROTECTION_DECLARATION"
            );
          },
        },
      ],
    };
  }, [dispatch, handleDeleteTask, idGroupCompany, openModal, startupId]);

  const configDataGroupCompany = useMemo(() => {
    const result = {};
    if (!isAcceleratorGoal()) {
      result.READY = configDataGroupCompanyOriginal.READY;
    }
    result.ADGM = configDataGroupCompanyOriginal.ADGM;
    result.CONFIRM = configDataGroupCompanyOriginal.CONFIRM;
    result.DETAILS = configDataGroupCompanyOriginal.DETAILS;
    result.REVIEW = configDataGroupCompanyOriginal.REVIEW;
    result.ACCELERATOR = configDataGroupCompanyOriginal.ACCELERATOR;
    result.RENEWAL = configDataGroupCompanyOriginal.RENEWAL;
    result.CAYMAN_START_RENEWAL_TASKS =
      configDataGroupCompanyOriginal.CAYMAN_START_RENEWAL_TASKS;
    result.ADGM_START_RENEWAL_PROCESS =
      configDataGroupCompanyOriginal.ADGM_START_RENEWAL_PROCESS;
    result.CAYMAN_RENEWAL_TASKS =
      configDataGroupCompanyOriginal.CAYMAN_RENEWAL_TASKS;
    result.CAYMAN_RENEWAL_COMPANY_IN_RENEWAL_PROCESS =
      configDataGroupCompanyOriginal.CAYMAN_RENEWAL_COMPANY_IN_RENEWAL_PROCESS;
    result.CAYMAN_ONLY_PINCAS_COMPANY_IN_PROCESS =
      configDataGroupCompanyOriginal.CAYMAN_ONLY_PINCAS_COMPANY_IN_PROCESS;
    result.CAYMAN_RENEWAL_STRIKE_OFF =
      configDataGroupCompanyOriginal.CAYMAN_RENEWAL_STRIKE_OFF;
    result.CAYMAN_RENEWAL_COMPANY_IN_STRIKE_OFF_PROCESS =
      configDataGroupCompanyOriginal.CAYMAN_RENEWAL_COMPANY_IN_STRIKE_OFF_PROCESS;
    result.ADGM_RENEWAL_STRIKE_OFF =
      configDataGroupCompanyOriginal.ADGM_RENEWAL_STRIKE_OFF;
    result.ADGM_RENEWAL_COMPANY_IN_STRIKE_OFF_PROCESS =
      configDataGroupCompanyOriginal.ADGM_RENEWAL_COMPANY_IN_STRIKE_OFF_PROCESS;
    result.ADGM_RENEWAL_TASKS =
      configDataGroupCompanyOriginal.ADGM_RENEWAL_TASKS;
    result.ADGM_RENEWAL_COMPANY_IN_RENEWAL_PROCESS =
      configDataGroupCompanyOriginal.ADGM_RENEWAL_COMPANY_IN_RENEWAL_PROCESS;
    return result;
  }, [JSON.stringify(configDataGroupCompanyOriginal)]);

  const getParams = (task) => {
    console.log(task);
    return { ...task.params };
  };

  const { dataTasks } = useTasks(
    allTasks,
    configDataGroupCompany,
    "ASSISTANCE_TASKS_GROUP_COMPANY",
    getParams
  );

  const resetCounter = () => {
    restCount = 0;
  };

  const { taskStatus, showGJ, countCompleted } = useMemo(() => {
    if (!_.isEmpty(dataTasks)) {
      let showGJ = false;
      let result = "incompleted";
      let countCompleted = 0;
      let totalTasks = [];
      _.forEach(dataTasks, (taskGroups) => {
        countCompleted = countCompleted + taskGroups.completed;
        totalTasks.push(...taskGroups.tasks);
      });
      if (countCompleted === _.size(totalTasks)) {
        result = "completed";
        if (restCount === countCompleted - 1) {
          result = "incompleted";
          showGJ = true;
        }
      } else {
        restCount = countCompleted;
      }
      return {
        taskStatus: result,
        showGJ: showGJ,
        countCompleted: countCompleted,
      };
    }
  }, [JSON.stringify(dataTasks)]);

  // showTask only true when  one task is pending
  const { showTask, showAction, showHelper, activeTab } = useTabStatus(
    taskStatus,
    null,
    _.get(dataGroupCompany, "getGroupCompany.isLocked", false)
  );

  const isInactiveGroupCompany = useMemo(() => {
    return !(dataGroupCompany?.getGroupCompany?.isActive ?? true);
  }, [dataGroupCompany]);

  const isDissolvedGroupCompany = useMemo(() => {
    
    return !!dataGroupCompany?.getGroupCompany?.dissolution_date;
  }, [dataGroupCompany]);

  return (
    <>
      <AssistanceFrame2
        variant={"groupCompany"}
        tasksComponent={GroupCompanyTasks}
        tasks={dataTasks}
        // showTask={showTask}
        showTask={true}
        isInactiveGroupCompany={isInactiveGroupCompany}
        isDissolved={isDissolvedGroupCompany}
        companiesCorporateData={dataGroupCompany}
        entity={_.get(dataGroupCompany, "getGroupCompany")}
        disabled={{
          actions: _.get(dataGroupCompany, "getGroupCompany.isLocked", false),
        }}
        resetCounter={resetCounter}
        showGJ={showGJ}
        countCompleted={countCompleted}
        tabsStatus={{
          showTask: showTask,
          showAction: showAction,
          showHelper: showHelper,
          activeTab: activeTab,
        }}
      />
    </>
  );
};

export default AssistanceGroupCompany2;

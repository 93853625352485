import useMutation from "src/hooks/custom/useMutation";

export const UPSERT_EDIT_DATA = `
mutation upsertGroupCompany(
    $startupId: String
    $groupCompanyId: String
	$groupCompany: GroupCompanyInputType
  ) {
    upsertGroupCompany(
      startupId: $startupId
      groupCompanyId: $groupCompanyId
      groupCompany: $groupCompany
    ) {
      id
  }
}
`;

const useUpdateBankingAuthority = () => {
  const [mutation] = useMutation(
    UPSERT_EDIT_DATA,
    { showSuccessNotification: true }
  );
  return mutation;
};

export default useUpdateBankingAuthority;

import React, {useEffect, useRef, useState} from 'react'
import {Field, Formik} from 'formik';
import {useDispatch} from 'react-redux';
import _ from 'lodash';
import Mustache from 'mustache';
import * as Yup from 'yup';
import moment from 'moment';
import classes from './FactoryModals.module.scss';
import {ClaraUpload, Modal, PopoverOption, Text, TextInput} from '../../../../index';
import ServerConnect from "../../../../../utils/ServerConnect";
import {helper} from "../../../../../utils";
import {MenuListAgent} from './components';
import {Constants} from '../../../../../utils/constants';
import {Checkbox} from '@material-ui/core';
import Loading from '../../../../Loading/Loading';
import PrimaryButton from '../../../../../../components/generic/Buttons/PrimaryButton/PrimaryButton';
import RoleAccess from '../../../../../../modules/security/components/RoleAccess/RoleAccess';

export const ModalGenerateADGMDocuments = ({
                                               showModal,
                                               title,
                                               data,
                                               callbackUpdate,
                                               closeModal,
                                               setLoading,
                                               goToTab
                                           }) => {

    const fullNameAgentRef = useRef();
    const emailRef = useRef();
    const [agents, setAgents] = useState([]);
    const [agentId, setAgentId] = useState(null);
    const [agentName, setAgentName] = useState('');
    const [agentEmail, setAgentEmail] = useState('');
    const [applicationNumber, setApplicationNumber] = useState('');
    const [disabledButtonModalSubmit, setDisabledButtonModalSubmit] = useState(true);
    const [isRegenerateDocuments, setIsRegenerateDocuments] = useState(false);

    const validateSchema = Yup.object().shape({
        applicationNumber: Yup.string().trim('This field does not allow spaces').required("This field is required"),
        agentId: Yup.string().required("This field is required").ensure()
    });

    const handleSubmitIncorporation = (applicationNumber, agentId, regenerateDocuments) => {

        const mutation = `mutation upsertIncorporation(
            $matterId: String!
              $incorporationData: IncorporationInput!
          ) {
            upsertIncorporation(
              matterId: $matterId
              incorporationData: $incorporationData
            ) {
              id
              applicationNumber
            }
          }
          `;

        const values = {
            matterId: _.get(data, 'matterId', data.id),
            incorporationData: {
                applicationNumber,
                agentId,
                regenerateDocuments
            }
        };
        closeModal();
        setLoading(true);

        ServerConnect.graphQlMutation(mutation, values)
            .then((upsertIncorporation) => {
                if (callbackUpdate) {
                    callbackUpdate();
                }
                setLoading(false);

                if (goToTab) {
                    goToTab("incorporation")
                }
            })
    };

    useEffect(() => {
        const getAgents = () => {
            const query = ` {
                            getAgents(matterId: "{{matterId}}", roles: [SERVICE_AGENT_MANAGER]){
                            _id
                             fullName
                            email
                                }
                            }`;

            const getAgentsQuery = Mustache.render(query, {matterId: data.matterId});
            ServerConnect.graphQlQuery(getAgentsQuery)
                .then(({getAgents}) => setAgents(getAgents))

        };
        getAgents();

    }, []);

    useEffect(() => {
        setIsRegenerateDocuments(!_.isEmpty(data.applicationNumber));
        setApplicationNumber(data.applicationNumber);

        const agent = agents.find(e => e._id === data.agentManagerId);
        if (agent) {
            setAgentId(data.agentManagerId);
            setAgentName(agent.fullName);
            setAgentEmail(agent.email);
        }

    }, [data]);

    const selectAgentOnChanged = (agent) => {
        setAgentId(agent._id);
        emailRef.current.value = agent.email;
        fullNameAgentRef.current.value = agent.fullName;
    };

    useEffect(() => {
        validateSchema.isValid({applicationNumber, agentId})
            .then((result) => {
                setDisabledButtonModalSubmit(!result)
            });
    }, [applicationNumber, agentId]);

    const handleCloseModal = () => {
        setApplicationNumber("");
        setAgentId(null);
        closeModal();
    };

    return (
        <Modal open={showModal} showHeader={false}>
            <div className={classes.containerModalIncorporation}>
                <h2>ADGM Portal Application Details</h2>
                <div className={classes.containerInput}>
                    <label>Application Number:</label>
                    <input
                        placeholder={"Type here..."}
                        className={`inputGeneric`}
                        value={applicationNumber}
                        onChange={(e) => setApplicationNumber(e.target.value.trimLeft())}
                    />

                </div>

                <div className={classes.containerInput}>
                    <label>Service Agent Manager:</label>
                    <PopoverOption classicStyle={true}
                                   renderElement={<MenuListAgent onClickAgent={selectAgentOnChanged} list={agents}/>}>
                        <input
                            className={`inputGeneric ${classes.readOnly}`}
                            readOnly
                            defaultValue={agentName}
                            ref={fullNameAgentRef}
                        />
                    </PopoverOption>
                </div>


                <div className={classes.containerInput}>
                    <label>Email address</label>
                    <input
                        className={`inputGeneric ${classes.readOnly}`}
                        readOnly
                        defaultValue={agentEmail}
                        ref={emailRef}
                    />
                </div>

                <div className={classes.containerButtons}>
                    <button className={`genericBlue`} onClick={handleCloseModal}>Cancel</button>
                    <button className={`genericBlue`}
                            disabled={disabledButtonModalSubmit || applicationNumber.includes(' ')}
                            onClick={() => handleSubmitIncorporation(applicationNumber, agentId, isRegenerateDocuments)}>Add
                    </button>
                </div>

            </div>

        </Modal>
    )
};

export const ModalAssign = ({showModal, title, data, callbackUpdate, closeModal}) => {

    const mutation = `mutation assignToAgent($matterId: ID!, $agentId: ID!) {
        assignToAgent(matterId: $matterId, agentId: $agentId)
      }
      `;
    const handleSubmit = () => {

        ServerConnect.graphQlMutation(mutation, {agentId: data._id, matterId: data.matterId})
            .then(result => {
                setSuccessChanged(true);
                callbackUpdate();
            })
    };

    const handleSubmitDone = () => {
        closeModal();
        setTimeout(() => setSuccessChanged(false), 300);

    };

    const [successChanged, setSuccessChanged] = useState(false);

    const labelButton = successChanged ? "DONE" : "YES";
    const textModal = successChanged ? "INCORPORATION_PORTAL_MODAL_CHANGED_ASSIGN_TO_AGENT_SUCCES" : "INCORPORATION_PORTAL_MODAL_CHANGED_ASSIGN_TO_AGENT";
    const submitModal = successChanged ? handleSubmitDone : handleSubmit;

    return (
        <Modal open={showModal} showHeader={false}>
            <div className={`${classes.containerModal} ${classes.modalAssign}`}>
                <h2>
                    {title}
                </h2>
                <Text classes={{root: classes.text}} params={{fullName: data.fullName, docName: title}}
                      uuid={textModal}/>
                <div className={classes.containerButtons}>
                    {!successChanged && <button className={`genericBlue`} onClick={closeModal}>NO</button>}
                    <button className={`genericBlue`} onClick={submitModal}>{labelButton}</button>
                </div>


            </div>

        </Modal>
    )
};

export const ModalChangedNumber = ({showModal, title, data, callbackUpdate, closeModal, setLoading}) => {

    const [applicationNumber, setApplicationNumber] = useState("");
    const [disabledButtonModalSubmit, setDisabledButtonModalSubmit] = useState(true);

    const validateSchema = Yup.object().shape({
        applicationNumber: Yup.string().nullable().required("This field is required")
    });

    useEffect(() => {
        validateSchema.isValid({applicationNumber})
            .then((result) => {
                setDisabledButtonModalSubmit(!result)
            });
    }, [applicationNumber]);

    const mutation = `mutation upsertIncorporation(
        $matterId: String!
          $incorporationData: IncorporationInput!
      ) {
        upsertIncorporation(
          matterId: $matterId
          incorporationData: $incorporationData
        ) {
          id
          applicationNumber
        }
      }
      `;

    const handleSubmit = () => {
        closeModal();
        setLoading(true);

        let values = {
            incorporationData: {applicationNumber: applicationNumber, id: data.id},
            matterId: data.matterId
        };

        ServerConnect.graphQlMutation(mutation, values)
            .then(result => {
                callbackUpdate();
                setLoading(false);
            })
    };
    const documentsList = 'Consent Letter to use Registered Office, Consent to Act - Air Jordan - Michael Jordan, ADGM Register of Members, Share Certificate, ADGM Register of Directors - Air Jordan, Register of Directors - Residential Address, Register of UBOs, Passport Summary, Registered Office and Services Agreement, Resolution of The Board of Directors';
    return (
        <Modal open={showModal} showHeader={false}>
            <div className={`${classes.containerModal} ${classes.modalAssign}`}>
                <h2>
                    {helper.getTranslateTextByKey("INCORPORATION_PORTAL_MODAL_CHANGED_NUMBER_APPLICATION", {})}
                </h2>
                <div className={classes.containerInput}>
                    <label>Application Number:</label>
                    <input
                        className={`inputGeneric `}
                        value={applicationNumber}
                        onChange={e => setApplicationNumber(e.target.value.trimLeft())}
                        placeholder={"Type here..."}

                    />
                </div>
                <div className={`${classes.disclaimer} ${classes.disclaimerColorRed}`}>
                    <Text uuid={"INCORPORATION_PORTAL_MODAL_CHANGED_NUMBER_APPLICATION_DISCLAIMER"}
                          params={{documentsList}}/>
                </div>
                <div className={classes.containerButtons}>
                    <button className={`genericBlue`} onClick={closeModal}>Cancel</button>
                    <button className={`genericBlue`} disabled={disabledButtonModalSubmit} onClick={handleSubmit}>Add
                    </button>
                </div>


            </div>

        </Modal>
    )
};

export const ModalRegenerateADGMDocuments = ({
                                                 showModal,
                                                 title,
                                                 data,
                                                 callbackUpdate,
                                                 closeModal,
                                                 setLoading,
                                                 goToTab
                                             }) => {

    const [disabledGenerate, setDisabledGenerate] = useState(true);
    const [showIncorporation, setShowIncorporation] = useState(false);

    const mutation = `mutation regenerateADGMDocuments(
        $matterId: ID!
      ) {
        regenerateADGMDocuments(
          matterId: $matterId
        ) {
          id
        }
      }
      `;

    const handleSubmit = () => {
        // goToTab('questionnaire');
        closeModal();
        setShowIncorporation(true);
        setDisabledGenerate(true);
    };

    const handleCloseIncorporation = () => {
        setShowIncorporation(false);
    };

    return (
        <>
            <Modal className={{modal: classes.modalDelete}} open={showModal} showHeader={false}>
                <div className={`${classes.deleteForm} ${classes.modalAssign}`}>
                    <h1>
                        {helper.getTranslateTextByKey("INCORPORATION_PORTAL_MODAL_REGENERATE_ADGM_DOCUMENTS", {})}
                    </h1>
                    <h2>
                        {helper.getTranslateTextByKey("INCORPORATION_PORTAL_MODAL_REGENERATE_ADGM_DOCUMENTS_DISCLAIMER", {})}
                    </h2>
                    <div className={classes.zoneCheckBox}>
                        <div className={classes.CheckBox}>
                            <Checkbox style={{color: "#FF606F", left: "0px"}}
                                      onChange={(e) => setDisabledGenerate(!e.target.checked)}/>
                            <label><Text
                                uuid={"INCORPORATION_PORTAL_MODAL_REGENERATE_ADGM_DOCUMENTS_QUESTION"}/></label>
                        </div>
                    </div>

                    <div className={classes.actionButtons}>
                        <button onClick={closeModal}>Cancel</button>
                        <button onClick={handleSubmit} className={classes.nextButton}
                                disabled={disabledGenerate}>Generate
                        </button>
                    </div>

                </div>

            </Modal>
            <ModalGenerateADGMDocuments
                showModal={showIncorporation}
                closeModal={handleCloseIncorporation}
                setLoading={setLoading}
                data={data}
                callbackUpdate={callbackUpdate}
            />
        </>
    )
};

export const ModalCancelEnvelope = ({showModal, title, data, callbackUpdate, closeModal, setLoading}) => {

    const [voidedReason, setVoidedReason] = useState("");
    const [disabledButtonModalSubmit, setDisabledButtonModalSubmit] = useState(true);

    const validateSchema = Yup.object().shape({
        voidedReason: Yup.string().nullable().required("This field is required")
    });

    useEffect(() => {
        validateSchema.isValid({voidedReason})
            .then((result) => {
                setDisabledButtonModalSubmit(!result)
            });
    }, [voidedReason]);

    const mutation = `mutation cancelRequestSignatures(
        $matterId: ID!
        $voidedReason: String!
      ) {
        cancelRequestSignatures(
          matterId: $matterId
          voidedReason: $voidedReason
        ) {
          id
        }
      }
      `;

    const handleSubmit = () => {
        closeModal();
        setLoading(true);

        let values = {
            matterId: data.matterId,
            voidedReason: voidedReason
        };

        ServerConnect.graphQlMutation(mutation, values)
            .then(result => {
                callbackUpdate();
                setLoading(false);
            })
    };

    return (
        <Modal open={showModal} showHeader={false}>
            <div className={`${classes.containerModal} ${classes.modalAssign}`}>
                <h2>
                    {helper.getTranslateTextByKey("INCORPORATION_PORTAL_MODAL_CANCEL_ENVELOPE", {})}
                </h2>
                <label>
                    {helper.getTranslateTextByKey("INCORPORATION_PORTAL_MODAL_CANCEL_ENVELOPE_LABEL", {})}
                </label>
                <div className={classes.containerTextArea}>
					<textarea
                        className={`inputGeneric `}
                        value={voidedReason}
                        onChange={e => setVoidedReason(e.target.value.trimLeft())}
                        placeholder={"Enter reason for voiding this envelope"}
                        rows="4"
                        cols="47"
                    />
                </div>
                <div className={`${classes.disclaimer} ${classes.disclaimerColorRed}`}>
                    <Text uuid={"INCORPORATION_PORTAL_MODAL_CANCEL_ENVELOPE_NOTE"}/>
                </div>
                {/*<div className={`${classes.disclaimer} ${classes.disclaimerColorRed}`}>*/}
                {/*	<Text uuid={"INCORPORATION_PORTAL_MODAL_CANCEL_ENVELOPE_DISCLAIMER"} />*/}
                {/*</div>*/}
                <div className={classes.containerButtons}>
                    <button className={`genericBlue`} onClick={closeModal}>Cancel</button>
                    <button className={`genericBlue`} disabled={disabledButtonModalSubmit} onClick={handleSubmit}>Void
                    </button>
                </div>


            </div>

        </Modal>
    )
};

export const ModalADGMBundle = ({showModal, title, data, callbackUpdate, closeModal, setLoading}) => {

    const [value, setValue] = useState("");


    const handleSubmit = () => {
        closeModal();
        setLoading(true);
        const path = `/matter/${data.matterId}/download/adgmbundle`;
        const options = {
            "Content-Type": "application/zip",
            "Accept": "application/zip",
            "responseHeader": "application/zip"
        };
        const validations = null;
        const config = {
            fileName: "adgm_bundle.zip"
        };
        ServerConnect.requestApi(path, options, validations, config)
            .then(() => setLoading(false));
    };
    return (
        <Modal open={showModal} showHeader={false}>
            <div className={`${classes.containerModal} ${classes.modalAssign}`}>
                <h2>
                    {title}
                </h2>

                <Text classes={{root: classes.textLabel}} uuid={"INCORPORATION_PORTAL_MODAL_ADGM_BUNDLE"}/>

                <div className={classes.containerButtons}>
                    <button className={`genericBlue`} onClick={closeModal}>Cancel</button>
                    <button className={`genericBlue`} onClick={handleSubmit}>Download</button>
                </div>


            </div>

        </Modal>
    )
};

export const ModalIncorporationPack = ({showModal, title, data, callbackUpdate, closeModal, setLoading}) => {

    const fullNameAgentRef = useRef();
    const emailRef = useRef();
    const formRef = useRef();
    const [agents, setAgents] = useState([]);
    const dispatch = useDispatch();

    const validateSchema = Yup.object().shape({
        incorporationDate: Yup.string().required("This field is required"),
        certificateOfIncorporationDoc: Yup.object().required("This field is required"),
        commercialLicenseDoc: Yup.object().required("This field is required"),
        agentId: Yup.string().required("This field is required")

    });

    const handleSubmit = (values) => {
        const mutation = `mutation createPackIncorporation(
            $matterId: ID!
            $packIncorporationData: PackIncorporationDataInput!
          ) {
            createPackIncorporation(
              matterId:$matterId
              packIncorporationData: $packIncorporationData
            ) {
                id
            }
          }
        `;
        closeModal();
        setLoading(true);
        ServerConnect.graphQlMutation(mutation, {matterId: data.matterId, packIncorporationData: values})
            .then(result => {
                return callbackUpdate();

            })
            .then(res => setLoading(false));


    };


    useEffect(() => {
        const getAgents = () => {
            const query = ` {
                            getAgents(matterId: "{{matterId}}", roles: [SERVICE_AGENT_MANAGER]){
                            _id
                             fullName
                            email
                                }
                            }`
            const getAgentsQuery = Mustache.render(query, {matterId: data.matterId})
            ServerConnect.graphQlQuery(getAgentsQuery)
                .then(({getAgents}) => setAgents(getAgents))

        };
        getAgents();

    }, []);

    const handleSelectAgent = (agent) => {
        formRef.current.setFieldValue("agentId", agent._id);
        emailRef.current.value = agent.email;
        fullNameAgentRef.current.value = agent.fullName;
    };

    const handleDateChange = (e, field, form) => {
        const date = e === null ? null : moment(e).format("YYYY-MM-DD");
        form.setFieldValue(field.name, date);
    };

    return (
        <Modal open={showModal} showHeader={false}>
            <div className={`${classes.containerModal} ${classes.formModal}`}>
                <h2>
                    {title}
                </h2>

                <Formik
                    initialValues={{}}
                    onSubmit={handleSubmit}
                    innerRef={formRef}
                    //    validationSchema={validateSchema}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue,
                          isValid
                      }) => {

                        return (
                            <div className={classes.containerModalForm}>

                                <Field name='incorporationDate'>
                                    {({field, form}) => (
                                        <TextInput
                                            type='date'
                                            label='Incorporation Date:'
                                            field={field}
                                            form={form}
                                            placeholder={"dd / mm / yyyy"}
                                            prevComponentChange={e =>
                                                handleDateChange(e, field, form)
                                            }
                                            autoComplete='off'
                                            className={classes.textDate}
                                        >

                                        </TextInput>
                                    )}
                                </Field>

                                <Field name={"certificateOfIncorporationDoc"}>
                                    {({field, form}) => (
                                        <ClaraUpload
                                            showRedStar={false}
                                            required={true}
                                            name={`filepond`}
                                            label={helper.getTranslateTextByKey(`INCORPORATION_PORTAL_MODAL_INCORPORATION_PACK_CREATION_CERTIFICATE_OF_INCORPORATION`)}
                                            field={field}
                                            form={form}
                                            className={classes.fileUpload}
                                        />
                                    )}
                                </Field>
                                <Field name={"commercialLicenseDoc"}>
                                    {({field, form}) => (
                                        <ClaraUpload
                                            showRedStar={false}
                                            required={true}
                                            label={helper.getTranslateTextByKey(`INCORPORATION_PORTAL_MODAL_INCORPORATION_PACK_COMERCIAL_LICENCE`)}
                                            name={`filepond`}
                                            field={field}
                                            form={form}
                                            className={classes.fileUpload}
                                        />
                                    )}
                                </Field>

                                <div className={classes.containerInput}>
                                    <label>Service Agent Manager:</label>
                                    <PopoverOption classicStyle={true}
                                                   renderElement={<MenuListAgent onClickAgent={handleSelectAgent}
                                                                                 list={agents}/>}>
                                        <input
                                            className={`inputGeneric ${classes.readOnly}`}
                                            readOnly
                                            ref={fullNameAgentRef}
                                        />
                                    </PopoverOption>
                                </div>


                                <div className={classes.containerInput}>
                                    <label>Email address</label>
                                    <input
                                        className={`inputGeneric ${classes.readOnly}`}
                                        readOnly
                                        ref={emailRef}

                                    />
                                </div>

                            </div>
                        )

                    }}

                </Formik>


                <div className={classes.containerButtons}>
                    <button className={`genericBlue`} onClick={closeModal}>Cancel</button>
                    <button className={`genericBlue`} onClick={() => formRef.current.handleSubmit()}>Generate and
                        review
                    </button>
                </div>


            </div>

        </Modal>
    )
};

export const ModalUploadDocument = ({showModal, title, data, callbackUpdate, closeModal}) => {

    const [isOpenModalInformation, setIsOpenModalInformation] = useState(false);
    const [loading, setLoading] = useState(false);

    const toggleModalInformation = () => setIsOpenModalInformation(!isOpenModalInformation);

    const validateSchema = Yup.object().shape({
        newDocument: Yup.object().nullable().required("This field is required")
    });

    const handleSubmit = (values) => {

        const mutation = `mutation replaceDocumentFileIncorporation(
            $matterId: ID!
            $documentId: ID!
            $file: FilePondInputType!
          ) {
            replaceDocumentFileIncorporation(
              matterId: $matterId
              documentId: $documentId
              file: $file
            ) {
			    id
    			name
				file {
				  id
				  name
				  size
				}
            }
          }
        `;

        setLoading(true);
        ServerConnect.graphQlMutation(mutation,
            {
                matterId: data.matterId,
                documentId: data.document.id,
                file: {
                    id: values.newDocument.id,
                    name: values.newDocument.name,
                    size: values.newDocument.size
                }
            },
			{
        		showErrorNotification: false
        	})
            .then(result => {
				closeModal();
				setLoading(false);
                callbackUpdate();
            })
			.catch(error => {
				setLoading(false);
				setIsOpenModalInformation(true)
			});
    };

    return (
    	<>
			<Modal open={showModal} showHeader={false}>
				<div className={`${classes.containerModal} ${classes.formModal}`}>
					<h2>
						{title}
					</h2>

					<Formik
						initialValues={{
							currentDocument: _.get(data, 'document.name', '')
						}}
						onSubmit={handleSubmit}
						// innerRef={formRef}
						validationSchema={validateSchema}
					>
						{({
							  values,
							  errors,
							  touched,
							  handleChange,
							  handleBlur,
							  handleSubmit,
							  isSubmitting,
							  setFieldValue,
							  isValid,
							  dirty
						  }) => {

							return (
								<React.Fragment>
									<div className={classes.containerModalForm}>

										<Field name='currentDocument'>
											{({field, form}) => (
												<TextInput
													type='text'
													label={helper.getTranslateTextByKey(`INCORPORATION_PORTAL_MODAL_REPLACE_DOCUMENT_CURRENT_DOCUMENT`)}
													field={field}
													form={form}
													readOnly={true}
												>
												</TextInput>
											)}
										</Field>

										<Field name={'newDocument'}>
											{({field, form}) => (
												<ClaraUpload
													showRedStar={false}
													required={true}
													name={`filepond`}
													label={helper.getTranslateTextByKey(`INCORPORATION_PORTAL_MODAL_REPLACE_DOCUMENT_NEW_DOCUMENT`)}
													field={field}
													form={form}
													className={classes.fileUpload}
												/>
											)}
										</Field>

									</div>

									<div className={classes.containerButtons}>
										<button className={`genericBlue`} onClick={closeModal}>Cancel</button>
										{/*<button className={`genericBlue`} onClick={handleSubmit}*/}
										{/*		disabled={!isValid || !dirty}>Replace*/}
										{/*</button>*/}
										<PrimaryButton onClick={handleSubmit} loading={loading} labelLoading={'Replacing'}>
											Replace
										</PrimaryButton>
									</div>

								</React.Fragment>
							)

						}}

					</Formik>

				</div>
			</Modal>
			<ModalInformation
				showModal={isOpenModalInformation}
				closeModal={toggleModalInformation}
				title={'Replace Document Tags'}
				content={'Error replacing this document. The signature tags are different than the ones we have in the system.'}
			/>
		</>
    )
};

export const ModalInformation = ({showModal, title, content, closeModal}) => {

	return (
		<Modal open={showModal} showHeader={false}>
			<div className={`${classes.containerModal} ${classes.modalAssign}`}>
				<h2>
					{title}
				</h2>
				<div>
					{content}
				</div>
				<div className={classes.containerButtons}>
					<button className={`genericBlue`} onClick={closeModal}>Close</button>
				</div>
			</div>
		</Modal>
	)
};

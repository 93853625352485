import { companyDataType, individualDataType } from './matterTypes';
//TYPES

const individualType = `{
  id
  curriculumVitaeDoc{
  file{
      id
      name
      size
      type
    }
  }
  type
  isPrimary
  firstName
  lastName
  isDirector
  isShareholder
  familyMemberOfAPEP
  familyMemberOfAPEPDescription
  closeAssociateOfAPEP
  closeAssociateOfAPEPDescription
  dateOfBirth
  UAEVisaDoc{
    file{
      id
      name
      size
      type
    }
  }
  email
  thirdPartyAddressFormDoc{
    file{
      id
      name
      size
      type
    }
  }
  thirdPartyAddressPassportDoc{
    file{
      id
      name
      size
      type
    }
  }
  lastName 
  thirdPartyAddressFormDoc{
    file{
      id
      name
      size
      type
    }
  }
  thirdPartyAddressPassportDoc{
    file{
      id
      name
      size
      type
    }
  }
  occupation
  address{
    address
    city
    country
    zipCode
    state
  }
  proofOfAddress
  proofOfAddressDoc{
    file{
      id
      name
      size
      type
    }
  }
  addressLetterAndSupportingDoc{
    file{
      id
      name
      size
      type
    }
  }
  contactNumber{
    prefix
    number
    extension
  }
  passports{
    id
    current
    nationality
    passportNumber
    passportExpiryDate
    passportDoc{
      file{
        id
        name
        size
      }
    }
    lastPagePassportDoc{
       file{
        id
        name
        size
      }
    }
    UAEPassportStamp
    UAEPassportStampDoc{
       file{
        id
        name
        size
      }
    }
    UIDDoc{
       file{
        id
        name
        size
      }
    }
    PRCIDDoc{
       file{
        id
        name
        size
      }
    }
    
    
  }
  otherPassports
  holdOrHaveHeldPassportFromAnotherCountry
  passportFromAnotherCountryDoc{
    file{
      id
      name
      size
      type
    }
  }
  politicallyExposedPerson
  politicallyExposedPersonDescription
  sourcesOfWealth
  otherSourcesOfWealth
  sourceOfFunds 
}`


const companyTypeCorporate = `{
  id
  companyName
  type
  isDirector
  isShareholder
  otherTradingName
  directors {
    individuals${individualDataType}
    companies${companyDataType}
    }
    signatory {
      firstName
      lastName
      jobTitle
      email
      nationality
      passportNumber
      passportExpiryDate
      signatoryPassportDoc{
        file{
          id
          name
          size
          type
        }
      }
    }
  clientKeyContact{
    fullName
    contactNumber{
      prefix
      number
      extension
    }
    email
    contactType
    }
  financeContact{
    fullName
    contactNumber{
      prefix
      number
      extension
    }
    email
    contactType
    }
    sourceOfFunds
    representativeDetails{
      firstName
      lastName
      jobTitle
      email
      representativePassportDoc {
        file{
          id
          name
          size
          type
        }
      }
    }
    showingShareholdersRegistrationDoc{
      file{
        id
        name
        size
        type
      }
    }
    showingDirectorsRegistrationDoc{
      file{
        id
        name
        size
        type
      }
    }
  registrationNumber
  isPrimary
  TRNNumber
  jurisdictionOfIncorporation
  VATRegistrationCertificateDoc{
    file{
      id
      name
      size
      type
      }
  }
  address{
    city
    state
    country
    zipCode
    address
  }
samePrincipalPlaceOfBusinessAndLegal
principalAddress{
  city
  state
  country
  zipCode
  address
} 
certificateOfIncorporationOrCommercialLicenseDoc{
  file{
    id
    name
    size
    type
  }
}
memorandumAndArticlesOfAssociationDoc{
  file{
    id
    name
    size
    type
  }
}
countryOfIncorporation {code name}
proofOfPowerDoc{
  file{
    id
    name
    size
    type
  }
}
}`


const editCompanyType = `{
  id
  companyName
  type
  otherTradingName
  PEPs${individualDataType}
  directors {
    individuals{
      firstName
      lastName
      email
      id
    }
    companies{
      companyName
      id
      signatory{
        firstName
        lastName
        email
      }
    }
    }
  shareholders {
    individuals{
      firstName
      lastName
      email
      id
    }
    companies{
      companyName
      id
      signatory{
        firstName
        lastName
        email
      }
    }
    }
  signatory {
    firstName
    lastName
    jobTitle
    email
    nationality
    passportNumber
    passportExpiryDate
    signatoryPassportDoc{
      file{
        id
        name
        size
        type
      }
    }
  }
  clientKeyContact{
    fullName
    contactNumber{
      prefix
      number
      extension
    }
    email
    contactType
    }
  financeContact{
    fullName
    contactNumber{
      prefix
      number
      extension
    }
    email
    contactType
    }
    sourceOfFunds
    representativeDetails{
      firstName
      lastName
      jobTitle
      email
      representativePassportDoc {
        file{
          id
          name
          size
          type
        }
      }
    }
    showingShareholdersRegistrationDoc{
      file{
        id
        name
        size
        type
      }
    }
    showingDirectorsRegistrationDoc{
      file{
        id
        name
        size
        type
      }
    }
  registrationNumber
  isPrimary
  TRNNumber
  jurisdictionOfIncorporation
  VATRegistrationCertificateDoc{
    file{
      id
      name
      size
      type
      }
  }
  address{
    city
    state
    country
    zipCode
    address
  }
samePrincipalPlaceOfBusinessAndLegal
principalAddress{
  city
  state
  country
  zipCode
  address
} 
showingDirectorsRegistrationDoc{
  file{
    id
    name
    size
    type
  }
}
showingShareholdersRegistrationDoc{
  file{
    id
    name
    size
    type
  }
}
certificateOfIncorporationOrCommercialLicenseDoc{
  file{
    id
    name
    size
    type
  }
}
memorandumAndArticlesOfAssociationDoc{
  file{
    id
    name
    size
    type
  }
}
countryOfIncorporation {code name}
proofOfPowerDoc{
  file{
    id
    name
    size
    type
  }
}
}`

const companyType = `{
  id
  companyName
  type
  otherTradingName
  PEPs${individualDataType}
  directors {
    individuals${individualDataType}
    companies${companyTypeCorporate}
    }
  shareholders {
    individuals${individualDataType}
    companies${companyTypeCorporate}
    }
  signatory {
    firstName
    lastName
    jobTitle
    email
    nationality
    passportNumber
    passportExpiryDate
    signatoryPassportDoc{
      file{
        id
        name
        size
        type
      }
    }
  }
  clientKeyContact{
    fullName
    contactNumber{
      prefix
      number
      extension
    }
    email
    contactType
    }
  financeContact{
    fullName
    contactNumber{
      prefix
      number
      extension
    }
    email
    contactType
    }
    sourceOfFunds
    representativeDetails{
      firstName
      lastName
      jobTitle
      email
      representativePassportDoc {
        file{
          id
          name
          size
          type
        }
      }
    }
    showingShareholdersRegistrationDoc{
      file{
        id
        name
        size
        type
      }
    }
    showingDirectorsRegistrationDoc{
      file{
        id
        name
        size
        type
      }
    }
  registrationNumber
  isPrimary
  TRNNumber
  jurisdictionOfIncorporation
  VATRegistrationCertificateDoc{
    file{
      id
      name
      size
      type
      }
  }
  address{
    city
    state
    country
    zipCode
    address
  }
samePrincipalPlaceOfBusinessAndLegal
principalAddress{
  city
  state
  country
  zipCode
  address
} 
showingDirectorsRegistrationDoc{
  file{
    id
    name
    size
    type
  }
}
showingShareholdersRegistrationDoc{
  file{
    id
    name
    size
    type
  }
}
certificateOfIncorporationOrCommercialLicenseDoc{
  file{
    id
    name
    size
    type
  }
}
memorandumAndArticlesOfAssociationDoc{
  file{
    id
    name
    size
    type
  }
}
countryOfIncorporation {code name}
proofOfPowerDoc{
  file{
    id
    name
    size
    type
  }
}
}`


//QUERIES & MUTATIONS

const getPossibleDirectorsAndShareholders = `{
        getPossibleDirectorsAndShareholders(
          matterId: "{{matterId}}",
          isDraft: {{isDraft}}
        ) {
          individuals {
            id
            firstName
            lastName
            email
          }
          companies {
           companyName
            id
            signatory{
                firstName
                lastName
                email
            }
          }
        }
      }`

const sendScopingEmail = `{
  sendScopingEmail(matterId:"{{matterId}}")
}`

const sendQuestionnaireEmail = `mutation sendQuestionnaireEmail(
  $matterId: String!
  $whoIsGoingToDo: String!
){
  sendQuestionnaireEmail(matterId: $matterId,
  whoIsGoingToDo: $whoIsGoingToDo)
}`

const sendRequestSignaturesClientApproval = `mutation sendRequestSignaturesClientApproval(
  $matterId: ID!) {
    sendRequestSignaturesClientApproval(matterId: $matterId) {
      id
    }`

const sendKYCEmail = `mutation sendKYCEmail(
  $matterId: String!
  $KYCType: String!
  $whoIsGoingToDo: String!
){
  sendKYCEmail(matterId: $matterId,
   KYCType: $KYCType
  whoIsGoingToDo: $whoIsGoingToDo)
}`

const getQuestionnaireCompanies = `{
  getMattersList(matterId:"{{matterId}}") {
    id
    questionnaire {
      companies ${companyType}
    }
  }
}`


const addIndividualAsShareholderToEntity = `mutation addIndividualAsShareholderToEntity (
  $matterId: ID!
  $individualId: ID!
  $entityId: ID!
){
  addIndividualAsShareholderToEntity(
    matterId: $matterId,
    individualId: $individualId
    entityId: $entityId)
}`

const addIndividualAsDirectorToEntity = `mutation addIndividualAsDirectorToEntity (
  $matterId: ID!
  $individualId: ID!
  $entityId: ID!
){
  addIndividualAsShareholderToEntity(
    matterId: $matterId,
    individualId: $individualId
    entityId: $entityId)
}`

const addCompanyAsShareholderToEntity = `mutation addCompanyAsShareholderToEntity (
  $matterId: ID!
  $companyId: ID!
  $entityId: ID!
){
  addCompanyAsShareholderToEntity(
    matterId: $matterId,
    companyId: $companyId
    entityId: $entityId)
}`

const addCompanyAsDirectorToEntity = `mutation addCompanyAsDirectorToEntity (
  $matterId: ID!
  $companyId: ID!
  $entityId: ID!
){
  addCompanyAsDirectorToEntity(
    matterId: $matterId,
    companyId: $companyId
    entityId: $entityId)
}`

const getIndicidualKYC = `{
  getMattersList(matterId:"{{matterId}}") {
    id
    KYC{
      id
      KYCType
      entitiesData{
        entities${companyType}
        individuals${individualType}
      }
      individuals${individualType}
    }
  }
}`

const geIndividualQuestionnaire = `{
  getMattersList(matterId:"{{matterId}}") {
    id
    questionnaire{
      individuals${individualType}
    }
  }
}`

const entityQuery = `{
  getMattersList(matterId:"{{matterId}}") {
    id
    state
    companyType
    email
    tags
    contactNumber
    address {
      city
      state
      country
      address
      zipCode
    }
    createdAt
    sequenceNumber
    updatedAt

    clientFullName
    type
    documents {
      id
      name
      type {
        code
        label
      }
      createdAt
      updatedAt
      file {
        id
        name
        size
      }
    }
    createdBy {
    _id
      fullName
    } 
    questionnaire {
      companies ${companyType}
      individuals ${individualType}
      sharesData {
        shareholders {
          individual ${individualType} 
          company ${companyType}
          fullName
          shares
          paid
        }
        shareType
        sharesAuthorized
        shareRemaining
        nomineeShareholders
      }
      beneficialOwners ${individualType}
      bankInformation {
        bankAccountOpening
        bankingAddress{
          city
          state
          country
          zipCode
          address
        }
        authorizedSignatories${individualType}
        requireToSignJointly
}
      dataProtection${individualType}
      authorizedSignatories${individualType}
      directors {
          individuals${individualType}
          companies${companyType}
        }
      companyDetails {
              holdingName
              limitedName
              companyName
              isRevenueGenerating
              isProtection
              intellectualProperty
              alreadyIncorporated
              companyType
              haveAcronym
              shortenedName
              haveArabicName
              arabicName
              haveAffiliation
              fullLegalName              
              assetsHeld {
                primaryAsset                
              }
      }
    }
    KYC{
      id
      KYCType
      thirdParty {
        firstName
        lastName
        contactNumber{
          prefix
          number
          extension
        }
        pointOfContact
        }
      entitiesData{
        entities${editCompanyType}
        individuals${individualType}
        companies${companyTypeCorporate}
      }
      individuals${individualType}
    }
  }
}`

const query = `{
  getMattersList(matterId:"{{matterId}}") {
    id
    state
    companyType
    email
    tags
    contactNumber
    address {
      city
      state
      country
      address
      zipCode
    }
    createdAt
    sequenceNumber
    updatedAt

    clientFullName
    type
    documents {
      id
      name
      kind
      entitySource
      type {
        code
        label
      }
      createdAt
      updatedAt
      file {
        id
        name
        size
      }
      entity
      entityId

      populatedEntity {
        ... on KYCIndividual {
          firstName
          lastName
        }
        ... on KYCCompany {
          companyName
        }
      }

    }
    createdBy {
      fullName
      _id
    }
    scoping{
      incorporationJurisdiction
      companyType
      isRevenueGenerating
      isProtection
      onlyRevenueDividendsOrCapital
      foundationReason
      relevantTechnologyDescription
      companyActivitiesDescription
      detailsOtherIncorporation
      anotherIncomeForm
      shareNumbers
      boardMembersMajorityResident
      bankAccountRequire
      engagementType
      alreadyIncorporated
      alreadyIncorporatedText
      toBeIncorporated
      toBeIncorporatedText
      intellectualProperty
      intellectualPropertyText
      otherAsset
      otherAssetText
      whereTheMajorityBoardMembersReside
      foundationAssetsAndWhereAreLocated
      whereFoundersAreLiving
      engagement{
        status
      }
    }
    questionnaire {
      agreementsData{
        agreementsDirectors
        firstName
        lastName
        email
      }
      state
      companies ${companyType}
      individuals ${individualType}
      sharesData {
        shareholders {
          company ${companyType}
          individual ${individualType}
          entityId
          fullName
          shares
          paid
        }
        shareType
        sharesAuthorized
        shareRemaining
        nomineeShareholders
      }
      beneficialOwners ${individualType}
      bankInformation {
        bankAccountOpening
        bankingAddress{
          city
          state
          country
          zipCode
          address
        }
        authorizedSignatories${individualType}
        requireToSignJointly
}
      dataProtection${individualType}
      authorizedSignatories${individualType}
      directors {
          individuals${individualType}
          companies${companyType}
        }
      companyDetails {
              holdingName
              limitedName
              companyName
              isRevenueGenerating
              isProtection
              intellectualProperty
              intellectualPropertyDoc{
                file{
                  id
                  name
                  size
                  type
                }
              }
              alreadyIncorporated
              alreadyCompanies{
                companyName
              }
              companyType
              haveAcronym
              shortenedName
              haveArabicName
              arabicName
              haveAffiliation
              fullLegalName              
              assetsHeld {
                primaryAsset                
              }
      }
    }
    incorporation {
      status
    }
    KYC{
      id
      KYCType
      engagement{
        status
      }
      thirdParty {
        firstName
        lastName
        contactNumber{
          prefix
          number
          extension
        }
        pointOfContact
        }
      entitiesData{
        entities${companyType}
        individuals${individualType}
        companies${companyTypeCorporate}
      }
      individuals${individualType}
    }
  }
}`

const query2 = `{
  getMattersList(matterId:null) {
    id
    state
    clientFullName
    companyType
    sequenceNumber
    tags
    type
    createdBy {
    _id
      fullName
    }
  }
}`

const upsertMatter = `mutation upsertMatter(
  $matterId: String!
  $matterData: MatterInput!
) {
  upsertMatter(
    matterId: $matterId
    matterData: $matterData
  ) {
  clientFullName
  address {
    city
    state
    country
    zipCode
    address
  }
  contactNumber 
  }
}`


const upsertScoping = `mutation upsertScoping(
  $matterId: String!
	$scopingData: ScopingInputType!
) {
  upsertScoping(
    matterId: $matterId
    scopingData: $scopingData
  ) {
          incorporationJurisdiction
          companyType
          onlyRevenueDividendsOrCapital
          relevantTechnologyDescription
          companyActivitiesDescription
          detailsOtherIncorporation
          anotherIncomeForm
          shareNumbers
          isRevenueGenerating
          isProtection
          boardMembersMajorityResident
          bankAccountRequire
          engagementType
          alreadyIncorporated
          alreadyIncorporatedText
          toBeIncorporated
          toBeIncorporatedText
          intellectualProperty
          intellectualPropertyText
          otherAsset
          foundationReason
          otherAssetText
          whereTheMajorityBoardMembersReside
          foundationAssetsAndWhereAreLocated
          whereFoundersAreLiving
  }
}`

const deleteIndividual = `mutation deleteKYCIndividual( $matterId: String!
	$KYCIndividualId: String!) {
    deleteKYCIndividual(
      matterId: $matterId
      KYCIndividualId: $KYCIndividualId
    ) {
      firstName
    }
  }`

const addIndividual = `mutation upsertKYCIndividual(
  $matterId: String!
  $KYCIndividualData: KYCIndividualInput!
  $entityId: String
){
  upsertKYCIndividual(matterId: $matterId,
  KYCIndividualData: $KYCIndividualData,
  entityId:$entityId
  ){id
      type
      isPrimary
      firstName
      familyMemberOfAPEP
      familyMemberOfAPEPDescription
      closeAssociateOfAPEP
      closeAssociateOfAPEPDescription
      email
      thirdPartyAddressFormDoc{
        file{
          id
          name
          size
          type
        }
      }
      thirdPartyAddressPassportDoc{
        file{
          id
          name
          size
          type
        }
      }
      lastName
      dateOfBirth
      occupation
      address{address
      city
      country
      zipCode
      }proofOfAddress
      proofOfAddressDoc{
        file{
          id
          name
          size
          type
        }
      }
      addressLetterAndSupportingDoc{
        file{
          id
          name
          size
          type
        }
      }
      contactNumber{
        prefix
        number
        extension
      }
      holdOrHaveHeldPassportFromAnotherCountry
      passportFromAnotherCountryDoc{
        file{
          id
          name
          size
          type
        }
      }
      politicallyExposedPerson
      politicallyExposedPersonDescription
      sourcesOfWealth
      otherSourcesOfWealth
      sourceOfFunds
      }
}`

const addCompany = `mutation upsertKYCEntity(
  $matterId: String!
  $KYCEntityData: KYCCompanyInput!
  $KYCEntityId: String
  ) {
  upsertKYCEntity(
  matterId: $matterId,
  KYCEntityData:  $KYCEntityData
  KYCEntityId: $KYCEntityId  
  )${companyType}
}`

const addQuestionnaireCompany = `mutation upsertQuestionnaireCompany(
  $matterId: String!
  $KYCCompanyData: KYCCompanyInput!
  $KYCCompanyId: String
  ) {
    upsertQuestionnaireCompany(
  matterId: $matterId,
  KYCCompanyData:  $KYCCompanyData
  KYCCompanyId: $KYCCompanyId
  )${companyType}
}`

const addQuestionnaireIndividual = `mutation upsertQuestionnaireIndividual(
  $matterId: String!
  $KYCIndividualData: KYCIndividualInput!
  $KYCIndividualId: String
  ) {
    upsertQuestionnaireIndividual(
  matterId: $matterId,
  KYCIndividualData:  $KYCIndividualData
  KYCIndividualId: $KYCIndividualId
  )${individualType}
}`


const deleteCompany = `mutation deleteKYCCompany(
  $matterId: String!
  $KYCCompanyId: String!
  ){ deleteKYCCompany (matterId: $matterId
    KYCCompanyId: $KYCCompanyId){
      companyName
    }
  }`

const setAsPrimary = `mutation setAsPrimary(
    $matterId: String!
    $KYCIndividualOrEntityId: String
  ){
    setAsPrimary(matterId: $matterId,
      KYCIndividualOrEntityId: $KYCIndividualOrEntityId)
  }`
const getEligibleDirectorsAndShareholders = `{getEligibleDirectorsAndShareholders(matterId: "{{matterId}}"){
  individuals${individualType}
  companies${companyType}
}}`;
const getEligibleDirectorsAndShareholdersDraft = `{getEligibleDirectorsAndShareholdersDraft(draftId: "{{draftId}}"){
  individuals
  companies
}}`;

const query3 = `
{
  getListToRequestSignaturesFlow(matterId:"{{matterId}}"){
  id  
  file{
    id
    name
    size
    type
  }    
 }
  getMattersList(matterId:"{{matterId}}") {
      id
      email
      clientFullName
    }
}`


const getListToRequestSignatures = `{ 
  getListToRequestSignatures(matterId:"{{matterId}}") {
    documents {
      name
    }
    signers {
      fullName
      email
      documents {
        name
      }
    }
  }
}`


const getListToIncorporationPackSignatures = `{
  getListToIncorporationPackSignatures(
    matterId: "{{matterId}}"){
    documents{
      name
      groupType
    }
    signers {
      fullName
      isPrimaryClient
      email
      documents {
        name
      }
    }
    agent {
	  fullName
	  email
    }
  }
}`;




export {
  individualType, companyType,
  query, query2, query3, getListToRequestSignatures, sendRequestSignaturesClientApproval, upsertScoping, addCompanyAsDirectorToEntity, addCompanyAsShareholderToEntity, sendScopingEmail, addIndividualAsDirectorToEntity, addIndividualAsShareholderToEntity, sendKYCEmail, deleteCompany, deleteIndividual, addIndividual, setAsPrimary, addCompany, sendQuestionnaireEmail, upsertMatter, getPossibleDirectorsAndShareholders, getEligibleDirectorsAndShareholders, getEligibleDirectorsAndShareholdersDraft,
  getIndicidualKYC, getQuestionnaireCompanies, addQuestionnaireCompany, geIndividualQuestionnaire, addQuestionnaireIndividual,
  getListToIncorporationPackSignatures, entityQuery
} 

import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import Button from "src/modules/generic/components/Buttons/Button";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import FileUpload from "src/modules/generic/components/Inputs/FileUpload";
import LeftContentBlock from "src/modules/startup/Stakeholder/ADGM/components/LeftContentBlock";
import RightContentBlock from "src/modules/startup/Stakeholder/ADGM/components/RightContentBlock";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import { clearAssistatText, addAssistantText } from "src/modules/generic/store/action";
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import Passport from "src/modules/startup/Stakeholder/ADGM/public/Passport.svg";
import * as Yup from 'yup';
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import Form from "src/components/forms/Form/Form";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import { ICONS } from "src/modules/generic/components/Inputs/FileUploadList/NoFilesUploaded";
import classes from "./classes.module.scss";

const schema = Yup.object().shape({
  files: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string()
          .matches(/(.jpg)|(.png)|(.jpeg)$/, "Invalid file type")
          .transform((original) => {
            return original.replace(/(\.[^.]+)$/, (match) =>
              match.toLowerCase()
            );
          }),
      })
    )
    .min(1),
});

function AddPassport() {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const { context, cancel, next } = useMachine();

  const initialValues = useMemo(() => {
    return context?.addPassport ?? context?.initialData ?? {};
  }, [context]);

  const handleCancel = () => {
    cancel();
  }

  const handleSubmit = (values) => {
    next(values);
  }

  useEffect(() => {
    dispatch(
      addAssistantText(
        `Upload a double page scan of the passport clearly showing their photo and passport number.`
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, []);

  const handleTitle = () => {
    let result ="Upload passport";
    if (context?.initialData?.name) {
      result = result + " for " + context?.initialData?.name;
    }
    return result;
  }

  const { acceptTypes, acceptIcon } = useMemo(() => {
    return {
      acceptIcon: [ICONS.JPG, ICONS.PNG],
      acceptTypes: "image/png, .jpg, .jpeg",
    };
  }, []);

  const image = <img alt="Passport" src={Passport} />;

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        buttons={{
          submit: <SubmitButton>{translate("MODULES.CLARA.BTN_NEXT")}</SubmitButton>,
          cancel: <Button onClick={handleCancel} variant="secondary">{translate("MODULES.CLARA.BTN_CANCEL")}</Button>,
        }}
        props={{
          title: "Upload passport",
          subTitle: handleTitle(),
        }}
      >
        <div className={classes.ContainerForm}>
          <LeftContentBlock title="Example">{image}</LeftContentBlock>
          <RightContentBlock title="Upload" isRequired>
            <ControllerInput
              render={FileUpload}
              accept={acceptTypes}
              icons={acceptIcon}
              name="files"
              limit={1}
              defaultValue={[]}
            />
          </RightContentBlock>
        </div>
      </TemplateWizard>
    </Form>
  );
}

export default AddPassport;

import FIRST_STEP from '../FirstStep/langs/index'
import SELECT_COMPANIES_STEP from '../SelectCompaniesStepForm/langs'
import IS_THERE_DOCUMENTS_STEP from '../IsThereDocumentsStep/langs'
import UPLOAD_DOCUMENTS_STEP from '../UploadDocumentsStepForm/langs'
import SELECT_DOCUMENTS_STEP from '../SelectDocumentStepForm/langs'
export default {
    FIRST_STEP:FIRST_STEP.US,
    SELECT_COMPANIES_STEP:SELECT_COMPANIES_STEP.US,
    IS_THERE_DOCUMENTS_STEP:IS_THERE_DOCUMENTS_STEP.US,
    UPLOAD_DOCUMENTS_STEP:UPLOAD_DOCUMENTS_STEP.US,
    SELECT_DOCUMENTS_STEP:SELECT_DOCUMENTS_STEP.US
}
const query = `{
  draftSteps(flowName: "{{flowName}}") {
    name
    code
    order
  }
}
  `;
const queryToRetrieve = ` {
    id
    draftId
    data
    previousAnswer { state event }
    bot_message {
      responseKeyName
      submitButtonText
      disclaimer
      iDontKnowText
      disclaimer
      lastMessage
      bulletCode
      id
      title
      question
      type
      inputConfig {
      
        ... on TextInputConfigType {
          allowNullResponse
          placeHolder
          validation
        }
        
        ... on EmailInputConfigType {
          allowNullResponse
          placeHolder
        }
        ... on AddressInputConfigType {
          subTitle
          allowNullResponse
          placeHolder
          validation
        }
        ... on TextAreaInputConfigType {
          subTitle
          allowNullResponse
          placeHolder
          validation
        }
        
        ... on ChatFormInputConfigType {
          formKey
          automaticSubmitNextQuestion
          automaticOpen
          option
        }
        
        ... on OptionInputConfigType {
          typeButtons
          placeholder
          answerBy
          allowNullResponse
          previewInfo{
            formKey
            key
            data
          }
          isSelect
          isSelectWithEntity
          createStakeholderWithAutomaticRole
          isMultiple
          filterEntityBy
          options {
            id
            text
            bot_message
          }
          entities
          validation
        }
        
        ... on FileUploadInputConfigType {
          isMultiple  
          allowNullResponse      
        }
        
        ... on NumberInputConfigType {
          placeHolder
          allowNullResponse
          validation
        }
        
        ... on DateInputConfigType {
          label
          allowNullResponse
         }
        
        ... on NoInputConfigType {
          label
          allowNullResponse
        }
        
        ... on CheckBoxInputConfigType {
          allowNullResponse
          entities
          filterEntityBy
          options {
            id
            text
          }
        }

        ... on SpecificStepComponentInputConfigType {
          specificStepComponentConfig
          specificStepComponentName
        }
        
      }
      tellme_more {
        label
        tooltip
        link
      }
      tagManagerArgs
    }
    answerBy
    updateBullets
    bullets {
      name
      code
      order
      }
    }`;
const initializeFlow = `mutation initializeFlow($initialData: InitialDataInput, $flowName:String!){
      initializeFlow(initialData: $initialData, flowName: $flowName) 
   ${queryToRetrieve}
   }`;
const nextQuestion = `mutation nextQuestion($draftId: String, $response: ChatUserResponse!) {
  nextQuestion(draftId: $draftId, response: $response) 
   ${queryToRetrieve}
   }`;
const prevQuestion = `mutation prevQuestion($draftId: String, $response: ChatUserResponse!) {
  prevQuestion(draftId: $draftId, response: $response)
    ${queryToRetrieve}
    }`;

export {
  initializeFlow,
  nextQuestion,
  prevQuestion,
  query
}

import React from 'react';
import classesModule from "./Lottie.module.scss"
import Lottie from 'react-lottie';
import _ from "lodash";

const Animations = ({ animation,optionsProps={},height=400,width=400,...props }) => {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const options = _.merge(defaultOptions,optionsProps)

  return (
    <React.Fragment>
      <div className={`${classesModule.Lottie}`}>
        <Lottie
          options={options}
          height={height}
          width={width}
        />
      </div>
    </React.Fragment>
  );
  
}

export default Animations;
import { useState, useCallback } from 'react';
import {gql} from '@apollo/client';
import ServerConnect from '../../v1/utils/ServerConnect'
import notification from "../../v1/utils/notification"
import useTextLang from "./useTextLang"
import _ from "lodash"
import {useSelector} from 'react-redux';
import logger from "./../../v1/utils/logger"
import {Constants} from "../../v1/utils";
import Handlebars from "handlebars";
import event from "../../v1/utils/event";
import useTranslate from "../../modules/generic/hooks/useTranslate";
import {stripTypenames} from '../../v1/utils/graphql-util';

/*
config:{
    showErrorNotification
    showSuccessNotification
    successMessage
    errorMessage
    paramsErrorMessage
}
*/
type onCompleteType=(values:object)=>Promise<void|any>
interface Config{
    showErrorNotification?:boolean
    showSuccessNotification?:boolean
    useError?:boolean
    errorMessage?:string
    successMessage?:string
    paramsErrorMessage?:object
    paramsSuccessMessage?:object
    onCompleted?:onCompleteType
    refreshStartup?:boolean
    shouldRefetchQuery?: (observer:any) => boolean
}
//TODO:Props interface
export default (query:string, config:Config = {}, props = {}) => {
    const {translate} = useTranslate()
    const messageError = useTextLang(_.get(config, "errorMessage", "GENERIC_NOTIFICATION_ERROR"), _.get(config, 'paramsErrorMessage'));
    const messageSuccess = useTextLang(_.get(config, "successMessage", "GENERIC_NOTIFICATION_SUCCESS"), _.get(config, 'paramsSuccessMessage'));    
    const client = ServerConnect.getUniqueApolloClient();
    const [loading, setLoading] = useState(false);
     //useEffect(()=>{console.log(messageSuccess)},[messageSuccess])
    
    const startupId = useSelector(store=>_.get(store,"session.startup.id"));

    const log = (variables) => {
        logger.info(Constants.LOGGER.ACTIONS.MUTATION, { query: query, variables: variables })
    };

    const logError = (error) => {
        logger.error(Constants.LOGGER.ACTIONS.MUTATION, { error, query: query })
    };

    const logSuccess = (result) => {
        logger.success(Constants.LOGGER.ACTIONS.MUTATION, { result, query: query })
    };

    const onError = ({graphQLErrors, networkErrors}) => {
        setLoading(false);
        let error
        if (graphQLErrors||networkErrors) {
            error = graphQLErrors||networkErrors
            logError(error[0]);
            const showNotification = config ? config.showErrorNotification : true;
            const useError = config ? config.useError : true;
            if (_.get(window, "globalNotification") && showNotification !== false) {
                const message = useError !== false ? _.get(error,"0.message",messageError) : messageError;
                notification.sendNotification(message, "error", 2500);
            }
        }
        if (config && config.onError) {
            config.onError(error[0])
        }
        if (props && props.onError) {
            props.onError(error[0])
        }
    };

    const handleMessages = (config, configManual) => {
        const forConfig = _.get(config, 'successMessage', "");
        const forConfigManual = _.get(configManual, 'paramsSuccessMessage', "");
        return `${forConfig}${forConfigManual}`;
    };

    const onCompleted = useCallback( (data,configManual = {}) => {
        setLoading(false);
        if (data) {
            logSuccess(data);
            const newConfig = _.merge(config, configManual);
            const showNotification = newConfig ? newConfig.showSuccessNotification : true;
            if (_.get(window, "globalNotification") && showNotification !== false) {
                // notification.sendNotification(messageSuccess, "success", 10000);
                // This setTimeout() is needed when a notification is send it before a global render   
                const messageSuccess = "GENERIC_NOTIFICATION_SUCCESS";    
                const successMessage = _.get(newConfig, 'successMessage',messageSuccess);
                const paramsSuccessMessage =_.get(newConfig, 'paramsSuccessMessage')

                const newMessageSuccess = translate(successMessage,paramsSuccessMessage); 
                setTimeout(() => notification.sendNotification(newMessageSuccess, "success", 5000), 1000)
            }
        }
        if (config.onCompleted) {
            config.onCompleted(data.data?data.data:data)
        }
        if (props && props.onCompleted) {
            props.onCompleted(data.data?data.data:data)
        }
    },[messageSuccess,JSON.stringify(config),JSON.stringify(props)]);
    
    
    // const [mutation, { ...rest }] = useMutation(gql`${query}`, {
    //     client, onError, onCompleted,fetchPolicy,
    //     awaitRefetchQueries: _.get(config, "awaitRefetchQueries", true), 
    //     ...props
    // }); 
    const defaultShouldRefetchQuery = (observer)=>{
        return true;
    }
    const onQueryUpdated = (observableQuery) =>{

        const validate = _.get(config,"shouldRefetchQuery", defaultShouldRefetchQuery);

        if (validate(observableQuery)) {
            return observableQuery.refetch();
        }

    }
    const updateCache = (cache, { data })=> {
        cache.evict({
            id: "ROOT_QUERY",
            broadcast: false
          });
        cache.gc()
    };
    const mutation = (params,config = {})=>{
        return new Promise((resolve, reject) => {
              const template = Handlebars.compile(query);
              const dynamicQuery =  template(params.variables);
            client.mutate({
                mutation: gql`${dynamicQuery}`,
                awaitRefetchQueries: _.get(config, "awaitRefetchQueries", true), 
                fetchPolicy: "network-only",
                onQueryUpdated: _.get(props,"onQueryUpdated", onQueryUpdated),
                update: _.get(props,"update", updateCache),
                ...props,
                ...params,
                variables:stripTypenames(params.variables),
            }).then(result => {
                //TODO REDUX: Remove when implements subscriptions
                if (startupId && _.get(config,"refreshStartup")!==false){
                    event.emmit(Constants.EVENTS.REFRESH_ACTIVE_SUBSCRIPTION, {startupId})
                }

                onCompleted(result, config)
                resolve(result)
            }).catch(err => {
                onError(err);
                reject(err);
            })
        })
    };

    const mutationCall = async (variables,config = {}) => {

        const variablesParams = _.get(variables, "variables", variables);
        const variablesProps = _.get(props, "variables", {});
        setLoading(true);
        log({...variablesProps,...variablesParams});
        return await mutation({ variables: {...variablesProps,...variablesParams} },config)
    };

    return [mutationCall, loading];
}

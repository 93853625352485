import useLazyQuery from 'src/hooks/custom/useLazyQuery';

export const GET_STAKEHOLDERS = `
query stakeholderList($startupId: ID!, $groupCompanyId: ID) {
  stakeholderList(
    startupId: $startupId
    groupCompanyId: $groupCompanyId
  ) {
    id
    fullName
    roles {
      id
      name
      entity {
        ... on StakeHolder {
          id
        }
        ... on GroupCompany {
          id
        }
      }
    }
  }
}`;

export const useGetStakeholdersShareCapital = (variables, config) => {
  const { manualQuery, loading, error, data, refetch } = useLazyQuery(GET_STAKEHOLDERS, variables, config);
  return { loading, error, data, refetch, manualQuery };
};

export default useGetStakeholdersShareCapital;
import React, {FC} from 'react'
import FormProps from "src/modules/generic/forms/interfaces/FormProps";
import Form from "src/components/forms/Form/FormL";
import * as Yup from 'yup'
import TemplateFormDefault from "src/modules/generic/templates/Modal";
import {DeleteButtonForm} from "src/components/generic/Buttons/DeleteButton";

interface  UnableToRemoveProps extends FormProps{
}
const UnableToRemove:FC<UnableToRemoveProps>=({onCompleteSubmit, Template = TemplateFormDefault, buttons = {}, className, buttonSubmitProps, propsTemplate,children})=>{
    
  return(
    <Form 
      schema={Yup.object().shape({
      })}
      onSubmit={onCompleteSubmit}
      // optionsForm = {{mode:"onChange"}} 
    >
      <Template 
          buttons={{...buttons, submit:<DeleteButtonForm isSubmitting={undefined} handleDisabled={undefined} labelLoading={'Okay'} {...buttonSubmitProps}>{'Okay'}</DeleteButtonForm>}} 
          props={propsTemplate}
          className={className}>
       {children}
      </Template>
    </Form>
  )
}
export default UnableToRemove

import React from 'react';
import { useDispatch } from "react-redux";
import IntroSubmitFlow from "../../../../../components/modules/incorporation/InformationFlow/InformationFlow";
import PAGES from "../../../../../constants/pages";
import image from '../../../../../images/submitFlowImg.svg';






const ScopingSubmittedView = (props) => {



    const dispatch = useDispatch();
    return (
        <React.Fragment>
            <IntroSubmitFlow
                image={image}
                formTitle={"SCOPING_FORM_TITLE_SUBMITTED"}
                firstTitle={"SCOPING_FIRST_TITLE_SUBMITTED"}
                firstText={"SCOPING_FIRST_TEXT_SUBMITTED"}
                secondText={"SCOPING_SECOND_TEXT_SUBMITTED"}
                buttonText={"SCOPING_SUBMITTED_FORMATION_STATUS_BUTTON"} 
                secondButtonText={"SCOPING_SUBMITTED_ACADEMY_BUTTON"}
                handleNext={async () =>{
                    dispatch({ type: 'GO_TO', page: PAGES.switchStartup })
                }}

                handleClose={() => {
                    return window.open("https://academy.clara.co", "_blank");
                }}
            />
        </React.Fragment >
    )
}

export default ScopingSubmittedView;

/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unreachable */
import React, { useCallback, useEffect } from "react";
import TagManager from 'react-gtm-module';
import CapTableRouter from "./CapTableRouter";
import CaptableProvider from "../contexts/CaptableContext/CaptableProvider";
import useSession from "src/modules/session/hooks/useSession";
import { Constants, helper } from "src/v1/utils";
import logger from "src/v1/utils/logger";
import useSecurity from "src/modules/security/hooks/useSecurity";
import { Redirect } from "react-router";
import {Helmet} from "react-helmet"; 
import useTextLang from "src/hooks/custom/useTextLang";
import PAGES from "src/constants/pages";

const CapTableView = ({title = "META_HEAD_TITLE_DEFAULT", custom}) => {
  const titleTranslate =useTextLang(title,null,"META_HEAD_TITLE_DEFAULT");
  const { hasSessionAccess, hasRoleAccess } = useSecurity();
  const hasAccess = () => {
    const access = hasSessionAccess(PAGES["capTable.mf"].path);
    // const accessRol = hasRoleAccess(rest.action, params);
    return (access);
  }
  if (!hasAccess()){
    return (< Redirect to={{ pathname: "/" }} />)
  }
  const { user } = useSession()
  const callTagManager = useCallback(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: "PageView",
        path: window.location.pathname,
        userid: user._id,
        firstName: user.firstName,
        pageName: document.title,
        userType: helper.getTypeUserByEmail(user.email),
      },
      dataLayerName: "PageDataLayer",
    };
    TagManager.dataLayer(tagManagerArgs);
  }, [user])

  const log = () => {
    logger.info(Constants.LOGGER.ACTIONS.PAGE, { path: window.location.pathname })
  }

  useEffect(() => {
    callTagManager()
    log()
  }, [window.location.pathname])


  return (
    <CaptableProvider>
      <Helmet>
        {titleTranslate && <title>{titleTranslate}</title>}
        {custom}
      </Helmet>
      <CapTableRouter />
    </CaptableProvider>

  )
};

export default CapTableView;

import React, { useState, useMemo } from 'react';
import classes from './Message.module.scss';
import Icon from '../../../../../../modules/generic/components/Icon';
import _ from 'lodash';
import TextHandlebars from '../../../../../../components/text/TextHandlebars/TextHandlebars';
import moment from 'moment'
import {mapData} from "./../utils/mapData"
const Message = ({ thread,message, selectMessage, handleMarkImportant, isSelected, index, event, important, typeMessage, ...props }) => {

    const colorType = useMemo(() => {

        const type = _.get(message, "template.urgency")

        switch (type) {
            case "RED":
                return "#ff606f";
            case "GREEN":
                return "#71e096";
            case "AMBER":
                return "#fbbd2c";
            case "UNKNOWN":
                return "#a9b0bc";

            default:
                return "#2C74FF";
        }
    }, [message.type]);


    const selectIcon = (event) => {
    	 //console.log('event', event)
        if (_.includes(event, "EDITED")) {
            return "Document"
        } else if (_.includes(event, "GENERATED_DOCUMENT_CREATED")) {
            return "New"
        } else if (_.includes(event, "CATEGORIZED")  || _.includes(event,'GENERATED_DOCUMENT_TERMINATED') || _.includes(event,'DOCUMENT_CHANGED_NAME')) {
            return "Document"
        } else if (_.includes(event, "DOCUMENT_VESTING_DATE_TODAY")) {
            return "Document"
        } else if (_.includes(event, "DOCUMENT_VESTING_DATE_UPCOMING")) {
            return "Document"
        } else if (_.includes(event, "DUE_DILIGENCE_COMMENTED")) {
            return "AuditLog"
        } else if (_.includes(event, "CREATED")) {
			return "Add"
        } else if (_.includes(event, "DELETED")) {
            return "Delete"
        } else if (_.includes(event, "SENT")) {
            return "View"
        } if (_.includes(event, "VOID")) {
			return "View"
		} if (_.includes(event, "RESEND")) {
			return "View"
		} else if (_.includes(event, "SIGNED")) {
            return "Signed"
        } else if (_.includes(event, "GRANT_REMOVED")) {
            return "Eye-Closed"
        } else if (_.includes(event, "PROFILE_GRANT")) {
            return "Eye-Open"
        } else if (_.includes(event, "REPORT_DOWNLOADED")) {
            return "Download"
        } else if (_.includes(event, "PUBLISHED") || _.includes(event, "PUBLISH_AND_SHARING")) {
            return "AuditLog"
        }
    };

    

    const handleFlagMarked = (e) => {
        handleMarkImportant({variables:{threadId:thread.id}})
        e.preventDefault()
    }


    const classesUnreadIcon = message.read ? "#828C98" : "#2C74FF";

    const classesUnread = message.read ? "" : "#2C74FF";


    const activityDate = moment(message.createdAt).format("DD MMM YYYY");

    const activityTime = moment(message.createdAt).format("LT");


    return (
        <div className={classes.containerMessage} onClick={() => selectMessage(index)} >

            <Icon color={colorType} icon={"Assistant"} size={"1.8462rem"} isClara />


            <div className={`${classes.containerCardMessage} ${isSelected && classes.containerCardMessageSelected}`}>
                <Icon color={classesUnreadIcon} icon={selectIcon(message.template.type)} size={"1.1538rem"} isClara />
                <label style={{ color: classesUnread }} className={classes.titleMessage} >
                    <TextHandlebars uuid={`MESSAGE_HEADER_${message.template.type}`} params={{ activityTime, activityDate, ...mapData(message) }} />
                </label>
                <Icon icon={"Flag"} color={important ? "#ff606f" : "#828C98"} onClick={handleFlagMarked} size={"1.1538rem"} isClara />
                <p className={classes.textMessage}>
                    <TextHandlebars uuid={`MESSAGE_SUB_HEADER_${message.template.type}`} params={{ activityDate, activityTime, ...mapData(message) }} />
                </p>

                <label className={classes.dateMessage} >Received <span>{moment(message.createdAt).format("DD/MM/YYYY")}</span></label>

                {typeMessage === "REMARK" && <div className={classes.containerCommentsCounts}>
                    <Icon icon={"Remark"} size={"1.8462rem"} isClara />
                    <span>{message.template.totalComments}</span>
                </div>
                }
            </div>
        </div>
    )
}


export default Message;

import useMutation from "../../../../hooks/custom/useMutation";
export const REGISTER_CHECKED_TASK = `
mutation registeredCheckedTask($startupId: ID!, $registeredTaskData: RegisteredTaskDataInput!){
registerAsCheckedTask(startupId: $startupId, registeredTaskData: $registeredTaskData){
...on StakeHolder{
    id
    tasks{
        code
        name
        status
        additionalParams{
            entityId
            entityType
      }
}
}
...on GroupCompany{
 id
    tasks{
        code
        name
        status
        additionalParams{
            entityId
            entityType
      }
}
}
}
}
`;
const useRegisterAsCheckedTask = (config, props) => {
  const [mutation, loading] = useMutation(REGISTER_CHECKED_TASK, config, props);
  return { mutation, loading };
};
export default useRegisterAsCheckedTask;

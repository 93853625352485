import React from 'react';
import useMutation from "../../../custom/useMutation";
import {updateCompanyParties,invalidateFields,invalidateObject} from "../../../../modules/generic/graphql/cache";
import _ from 'lodash'
const query = `mutation updateAgreement(
  $startupId: String
  $agreementId: String
  $agreement: GenerateAgreementInputType
) {
  updateAgreement(
    startupId: $startupId
    agreementId: $agreementId
    agreement: $agreement
  )  { id
    parentDocument {
      id
    }
    name
    parties
    freemiumValid
    kind
    status
    type {
      label
      code
    }
    stakeholders {
      id
      fullName
    }
    groupCompanies{
    groupCompany{
    id
    }
    signedBy{
    id
    }
    }
    subCategories
    file {
      name
      size
      type
      id
    }
  }
}`;

const useGenerateDocument = (variables) => {

  const shouldRefetchQuery = (observer) => {
		switch (observer.queryName) {
			case "getDocument":
				return false
		}
		return true;
	};
  const [mutation, loading] = useMutation(query, variables,{shouldRefetchQuery});


  return {mutation, loading};
};
export default useGenerateDocument;

import React, {useEffect, useState} from 'react';
import {Constants} from '../../../../../../utils/constants'
import {useDispatch} from "react-redux";
import _ from "lodash";
import notification from "../../../../../../utils/notification";
import Mustache from 'mustache';
import ServerConnect from "../../../../../../utils/ServerConnect";
import Loading from '../../../../../Loading/Loading';
import {LayoutForm} from '../../../../../../containers';
import {renameKey, stripTypenames} from "../../../../../../utils/graphql-util";
import SignatoriesForm from '../../../../../../containers/Forms/Questionniare/Signatories/SignatoriesForm';
import {getAuthorisedSignatories, upsertQuestionnaire} from '../config'


const EditSignatories = (props) => {
    const dispatch = useDispatch();

    const closeForm = () => {
        dispatch({ type: 'GO_TO', page: Constants.PAGES.viewMatter, params: { id: props.params.matterId, tab:'questionnaire' } })
    }

    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [list, setList] = useState([]);
    const [authorisedSignatories, setAuthorisedSignatories] = useState([]);

    const [initialValues, setInitialValues] = useState(null)

    const getData = () => {
        const query_params = {
            matterId: props.params.matterId
        };

        const query = Mustache.render(getAuthorisedSignatories, query_params)
        ServerConnect.graphQlQuery(query).then(({ getEligibleAuthorizedSignatories, getMattersList }) => {
            setAuthorisedSignatories(getEligibleAuthorizedSignatories);
            const { NASService, authorizedSignatories } = getMattersList[0].questionnaire;
            setData({ NASService, authorizedSignatories: _.map(authorizedSignatories, as => as.id) })
            setLoading(false)
        });
    }

    const refreshData = () => {
        setData(null)
    }



    const getShareholder = (shareholder) => {
        return _.omit(renameKey(shareholder, { id: "entityId" }), "label");
    }
    const submitData = (values) => {
        let message = 'Signatories Updated';
        const { type } = props.params;
        let value = {
            matterId: props.params.matterId,
            questionnaireData: {
                ...values
            }
        }
        ServerConnect.graphQlMutation(upsertQuestionnaire, stripTypenames(value))
            .then(result => {
                notification.sendNotification(message, "success", 4990);
                dispatch({ type: 'GO_TO', page: Constants.PAGES.viewMatter, params: { id: props.params.matterId, tab:'questionnaire' } })
            }).catch(err => {
                console.log(err);
                notification.sendNotification("ERROR", "error", 4990);
            });
    }

    const modifyDataFile = (values) => {
        var newValues = _.forOwn(values, (val, key) => {
            if (_.hasIn(val, 'file')) {
                return values[key] = val.file
            }
        })
        console.log('NEWVALS', { ...newValues, KYCCompanyId: props.params.id })
        return newValues



    }


    useEffect(() => {
        if (!data && props.mode === 'edit') {
            setLoading(true)
            getData();
        }
    }, [data, loading]);

    return (
        <React.Fragment>
            <Loading show={loading}>
                <LayoutForm
                    title={`Signatories`}
                    leftEducationId={Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM[`${_.eq(props.mode, "edit") ? "EDIT" : "ADD"}`].LEFT_EDUCATION_ID}
                    handleClose={closeForm}
                    showCloseIcon={false}  
                    >
                   
                    <SignatoriesForm initialValues={data} onSubmit={submitData} refreshData={refreshData} authorisedSignatories={authorisedSignatories} closeForm={closeForm} />
                </LayoutForm>
            </Loading>
        </React.Fragment>
    )
}

export default EditSignatories;

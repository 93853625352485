
import { useEffect } from 'react';
import useQuery from "../../../../../../hooks/custom/useQuery";
import _ from 'lodash'

export const  GET_GROUP_COMPANIES = `
query getGroupCompanies($startupId: ID!) {
    getGroupCompanies(startupId: $startupId) {
      id
      name
      kind
      avatar
      transactionCurrency
      isHoldingCompany
    }
  }
`
const useGetGroupCompanies = ({ variables, ...props }) => {
    const { loading, error, data, refetch, ...proesDes } = useQuery(GET_GROUP_COMPANIES, variables, null, props);

    return { loading, error, data: _.orderBy(_.get(data, 'getGroupCompanies', []), ['isHoldingCompany'], ['desc']), refetch, ...proesDes };
}
export default useGetGroupCompanies;

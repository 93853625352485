import React, {useState, useEffect, useMemo, useRef} from 'react'
import TextInput from "../../Text/TextInput";
import SelectAddInput from "../../Select/SelectAddInput/SelectAddInput"
import { Field, FieldArray } from 'formik';
import _ from "lodash";
import classes from "../../../../v1/containers/Forms/SteperForm.module.scss";
import RowAdd from '../../../forms/contents/RowAdd/RowAdd';
import * as Yup from 'yup';
import Form from '../../../forms/Form/Form'
import ControllerInput from '../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput'
import SubmitButtonSubForm from '../../../generic/Buttons/SubmitButtonSubForm/SubmitButtonSubForm';
import Classes from './styles.module.scss';
import { useSelector } from 'react-redux';
import { Constants } from '../../../../v1/utils';
import useMutation from "../../../../hooks/custom/useMutation";
import {VerifyButton} from "../../VerifyDetails/VerifyDetails";
import Icon from "../../../profile-picture/components/Icon/Icon";
import IconSecondaryButtonContent from "../../../generic/Buttons/IconSecondaryButtonContent/IconSecondaryButtonContent";
import TooltipInput from "../../../generic/Tooltips/TooltipIput/TooltipInput";
const SelectAddInputs = ({tooltip,isDisabled=()=>false,textInput,label,type,onChange, list, tooltips,add, fieldArrayName,onBlur,filterBy=()=>{}, className,  modal = false ,errorMessage}) => {
    const ref=useRef()

    return (
        <FieldArray
            name={fieldArrayName}
            render={(arrayHelpers) => {
                const addList = add(arrayHelpers);
                return (<>
                    <div className={Classes.containerForm}>
                        <RowAdd
                            label={label}
                            asterisk={true}
                            classNameInput={modal ? Classes.inputModal : Classes.input}
                            input={
                                <TooltipInput label={tooltip}>
                                <SelectAddInput
                                    value={null}
                                    list={list}
                                    mode={'add'}
                                    refSelect={ref}
                                    handleFilterList={(list) => {
                                        return _.filter(list, item => filterBy(_.get(arrayHelpers.form.values, `${fieldArrayName}`,[]),item.value))

                                    }}
                                    errorMessage={_.isEmpty(_.get(arrayHelpers.form.values, `${fieldArrayName}`,[]))?errorMessage:null}
                                    onBlur = {onBlur}
                                    placeholder={"Select from the list..."}
                                    onChange={(value) => {
                                        if (value) {
                                            onChange(value,arrayHelpers)
                                            ref.current.clearValue()
                                        }
                                    }}
                                    menuProps={{
                                        addList: addList,
                                        propsList: [{ type }]
                                    }}
                                />
                                </TooltipInput>
                            }
                        />

                    </div>
                    {_.get(arrayHelpers.form.values, `${fieldArrayName}`,[]).map((item, index) => {
                        const handleDeleteElement = () => arrayHelpers.remove(index);
                        return (
                                    <div className={Classes.containerInput} style={modal?{marginTop:'0.5rem'}:null}>
                                        <div className={Classes.deleteButton}
                                             onClick={() => handleDeleteElement(index)}><IconSecondaryButtonContent
                                            icon={'Delete-bin'}/></div>
                                        <div className={modal ? Classes.ContainerRow : classes.ContainerRow}>
                                            <Field
                                                name={`${fieldArrayName}[${index}].text`}>
                                                {({field, form}) => (
                                                    <TextInput
                                                        name={'text'}
                                                        value={field.value}
                                                        onChange={(value) => form.setFieldValue(field.name, value)}
                                                        classNameError={Classes.errorForm}
                                                        disabled={isDisabled(item)}
                                                        errorMessage={_.get(form.errors, `${fieldArrayName}[${index}].text`)}
                                                        {...textInput}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                    </div>
                               )
                    })}
                </>)

            }}
        />)
};
export default SelectAddInputs;

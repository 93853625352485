
import React from "react";
import classes from "../styles.module.scss";
import TwoColumnsData from "src/modules/generic/components/TwoColumnsData";
import DetailsTabTitle from "src/components/templates/DetailsTabTitle";
import { Divider } from "@mui/material";
import { RoleAccess } from "src/v1/components";
import Button from "src/modules/generic/components/Buttons/Button";
import { Constants } from "src/v1/utils/constants";
import useModal from "src/modules/generic/hooks/useModal";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "src/modules/generic/components/Texts/Typography";
import AddUltimateParentModal from "src/views/modules/startups/renewal/cayman/modals/AddUltimateParentModal";
import EditUltimateParentModal from "src/views/modules/startups/renewal/cayman/modals/EditUltimateParentModal";
import DeleteUltimateParentModal from "src/views/modules/startups/renewal/cayman/modals/DeleteUltimateParent";
import _ from "lodash";

const UltimateParentPanel = ({ data, isLock, renewalData }) => {
  const { openModal, closeModal } = useModal();
  const ultimateParent = _.get(renewalData, "getRenewalData")?.ultimateParent;

  const buttonAddUltimateParent = (
    <>
      {_.isEmpty(ultimateParent) && (
        <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
          <Button
            variant="card"
            disabled={isLock}
            children={"Add"}
            onClick={() => {
              openModal(AddUltimateParentModal, {
                handleCloseModal: closeModal,
                initialValues: { groupCompanyId: data?.id },
              }, 'MODAL_ADD_ULTIMATE_BENEFICIAL_OWNER');
            }}
          />
        </RoleAccess>
      )}
    </>
  );

  const buttonEditUltimateParent = (
    <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
      <Button
        variant="card"
        disabled={isLock}
        children={"Edit"}
        onClick={() => {
          openModal(EditUltimateParentModal, {
            handleCloseModal: closeModal,
            initialValues: { groupCompanyId: data?.id },
          }, 'MODAL_EDIT_ULTIMATE_PARENT');
        }}
      />
    </RoleAccess>
  );

  const itemsColumn1 = [
    {
      label: "Name",
      custom: (
        <Stack
          direction="row"
          spacing={0}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography variant='m'>
            {ultimateParent?.name ?? "-"}
          </Typography>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {!_.isEmpty(ultimateParent) && (
              <>
                <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
                  <Button
                    variant="cardWarning"
                    disabled={isLock}
                    children={"Delete"}
                    onClick={() => {
                      openModal(DeleteUltimateParentModal, {
                        handleCloseModal: closeModal,
                        groupCompanyId: data?.id,
                        parentName: ultimateParent?.name
                      }, 'MODAL_DELETE_ULTIMATE_PARENT');
                    }}
                  />
                </RoleAccess>
                {buttonEditUltimateParent}
              </>
            )}
          </Stack>
        </Stack>
      )
    }
  ];

  return (
    <>
      <Box sx={{ paddingRight: "0.4615rem" }}>
        <Divider className={classes.divider} />
        <DetailsTabTitle title="Ultimate parent" buttons={buttonAddUltimateParent} />
        <TwoColumnsData
          items={itemsColumn1}
          customValueStyle={{
            marginRight: "0px !important",
            width: "100% !important",
          }}
        />
      </Box>
    </>
  );
};

export default UltimateParentPanel;
import React from 'react';
import classesModule from "./SystemLayout.module.scss"
//import "../../styles2/index.scss";

const SystemLayout = ({ children, scrollbar }) => {


  return (
    <React.Fragment>
        <div className={`${classesModule.SystemLayout}`}>
          {children}
        </div>
    </React.Fragment>
  );
}

export default SystemLayout;
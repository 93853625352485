import React, {FC, useEffect, useMemo, useState} from 'react'
import _ from 'lodash'
import ModalAssistant, {ModalAssistantProps} from '../../../../generic/components/Modal/ModalAssistant'
import {useDispatch} from 'react-redux'
import useTranslate from '../../../../generic/hooks/useTranslate'
import useModal from "../../../../generic/hooks/useModal";
import FormIsThereCompaniesFormationsShares from "../../Forms/FormIsThereCompaniesFormationsShares";
import TemplateWizard from "../../../../generic/templates/Modal/TemplateWizard";
import Button from "../../../../generic/components/Buttons/Button";
import {addAssistantText, clearAssistatText} from "../../../../generic/store/action";
import useGetFormationCompany from '../../graphql/queries/useGetFormationCompany'
import {useParams} from "react-router-dom";
import useSession from "../../../../session/hooks/useSession";
import useUpsertFormationCompany from "../../graphql/mutations/useUpsertFormationCompany";

interface ModalNoticesProps extends ModalAssistantProps {
    initialValues: any,
    onClose(): void
}

const ModalNotices: FC<ModalNoticesProps> = ({ children,open: openParam = false, initialValues, ...props }: ModalNoticesProps) => {

    const [open, setOpen] = useState(openParam);

    const { closeModal: onClose } = useModal();

    const dispatch = useDispatch();

    const {id} = useParams()

    const { startupId } = useSession()

    const {data} = useGetFormationCompany({startupId,groupCompanyId:id})

    const [updateFormationCompany] = useUpsertFormationCompany({},{})

    const { translate } = useTranslate();

    useEffect(() => {
        setOpen(openParam);
    }, [openParam])

    const handleCancel = (values) => {
        onClose();
        setOpen(false);
        clearAssistatText()
    }

    const handleInitModal = () => {
        dispatch(clearAssistatText());
        dispatch(addAssistantText(translate('MODULES.GROUP_COMPANY.EDIT_MANAGE_FUTURE_LINKED_COMPANIES.TOOLTIP')))
    }



    const values = data && data.formationData && !_.isNil(data.formationData.notYetFormedCompanyName)?{
        isThereCompaniesFormationsShares:!!_.get(data,'formationData.notYetFormedCompanyName'),
        formationCompaniesHavesShares:_.get(data,'formationData.notYetFormedCompanyName'),
    }:null


    useEffect(() => {
        handleInitModal()
        return () => {
            dispatch(clearAssistatText());
        }
    }, []);

    const onSubmit = async  (values) => {

        const variables = {
            startupId,
            groupCompanyId:id,
            groupCompanyData:{
                formationData: {
                    notYetFormedCompanyName: values.isThereCompaniesFormationsShares ? values.formationCompaniesHavesShares : null
                }
            }
        }

        await updateFormationCompany({...variables})

        onClose()
    }


    return (
        <ModalAssistant open={open}>
            {!_.isEmpty(data) &&
            <FormIsThereCompaniesFormationsShares
                initialValues={values}
                buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }}
                onCompleteSubmit={onSubmit}
                propsTemplate={{
                    title:translate('MODULES.GROUP_COMPANY.EDIT_MANAGE_FUTURE_LINKED_COMPANIES.TITLE'),
                    subTitle:translate('MODULES.GROUP_COMPANY.EDIT_MANAGE_FUTURE_LINKED_COMPANIES.SUBTITLE')
                }}
                Template={TemplateWizard}
            />}
        </ModalAssistant>

    )
}

export default ModalNotices;
import classnames from "classnames";
import PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useImperativeHandle, useRef } from "react";
import Card, { STATUS_CARDS } from "../../../../generic/components/Contents/Card";
import ButtonIcon from "./../../../../../modules/generic/components/Buttons/ButtonIcon";
import classesModule from "./classes.module.scss";
import HeaderCardCompany from "./subcomponents/HeaderCardCompany/HeaderCardCompany";
import HeaderCardCompanySkeletor from "./subcomponents/HeaderCardCompany/HeaderCardCompanySkeletor";

/** 
* GroupCompany Card
*
*  - Sketch Padding: https://www.sketch.com/s/84259da8-b2da-42b4-80a5-9446ee58353d/a/DP8nwwk
*  - Sketch Fonts: https://www.sketch.com/s/84259da8-b2da-42b4-80a5-9446ee58353d/a/ZO4pdvG
*  - New Sketch: https://www.sketch.com/s/84259da8-b2da-42b4-80a5-9446ee58353d/a/kamln9D
*/
const GroupCompanyCard = forwardRef(({ id, tabIndex, onClick, status, onExpandCard, groupCompany, isHoldingCompany, level, totals, lock, visible, isFormation, ...props }, ref) => {

  const inputRef = useRef();

  useImperativeHandle(ref, () => ({
    current: inputRef.current,
  }));

  const handleFilters = useCallback(() => {
    if (onClick) {
      onClick(onClick && onClick(status !== STATUS_CARDS.SELECTED))
    }
  }, [onClick, status]);

  if (!groupCompany) {
    return (
      <Card className={`${classesModule.GroupCompanyCard} ${classesModule.Level_1}`}>
        <HeaderCardCompanySkeletor />
      </Card>
    )
  }

  return (
    <Card
      key={id}
      id={id}
      ref={inputRef}
      tabIndex={0}
      className={classnames(`${classesModule.GroupCompanyCard} ${classesModule["Level_" + level]} `, {
        [classesModule.borderDash]: isFormation,
      })}
      // onClick={onClick}
      status={status}
      showHoverEffect={true}
      onFinishAnimation={onExpandCard}
      visible={visible}
    >
      <ButtonIcon className={classesModule.iconFilter} variant={'secondary'} icon={"Map-lines"} onClick={handleFilters} />
      <HeaderCardCompany
        isInactive={!groupCompany.isActive}      
        id={id}
        companyName={groupCompany.name}
        subtitle={groupCompany.jurisdiction}
        avatar={groupCompany.avatar}
        tasks={groupCompany.tasks}
        totals={totals.total}
        current={totals.current}
        isHoldingCompany={isHoldingCompany}
        level={level}
        lock={lock}
        statusFormation={isFormation}
      />
    </Card>
  )
});

GroupCompanyCard.propTypes = {
  /**  Id or Key to the component */
  id: PropTypes.string,
  /** Tab index */
  tabIndex: PropTypes.number,
  /** Status Card */
  status: PropTypes.oneOf(["default", "suggested", "selected", "innactive", "formation"]),
  /** GroupCompany Data. If null then show the skeleton  */
  groupCompany: PropTypes.object,
  /** Lock Card */
  lock: PropTypes.bool,
  /** Visible Card */
  visible: PropTypes.bool,
  /** isFormation */
  isFormation: PropTypes.bool,
  /** isHoldingCompany */
  isHoldingCompany: false,
};

GroupCompanyCard.defaultProps = {
  tabIndex: 0,
  status: "default",
  level: 1,
  isHoldingCompany: false,
  isFormation: false,
  lock: false,
  visible: true,
};

export default GroupCompanyCard;
import Machine from "../../../../generic/components/Machine/MachineClass";
import { Step1 } from "./Steps";

const machineDefinition = {
  id: "ADGM_accounting_records",
  initial: "initialStatus",
  states: {
    initialStatus: {
      on: {
        NEXT: { target: "finish_accounting_records", actions: "setContext" },
        CANCEL: "finish_accounting_records",
      },
    },
    finish_accounting_records: {
      type: "final",
      entry: "final",
    },
    onDone: {
      actions: "stopMachine",
    },
  },
};

const steps = {
  initialStatus: Step1,
};


export default class MachineAccountingRecords extends Machine {
  constructor() {
    super(machineDefinition, {}, steps);
  }
  getParamsData(values) {
    return values;
  }
}

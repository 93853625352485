import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import ModalAssistant, { ModalAssistantProps } from '../../../../generic/components/Modal/ModalAssistant'
import Wizard from '../../../../generic/components/Wizard'
import useModal from "../../../../generic/hooks/useModal"
import { clearAssistatText } from '../../../../generic/store/action'
import MachineAddressAndCurrency from './machine'

interface ModalBankingAuthorityProps extends ModalAssistantProps {
  paramsMutation: any,
  onClose(): void
}

const ModalBankingAuthority: FC<ModalBankingAuthorityProps> = ({ children, open = false, paramsMutation, ...props }: ModalBankingAuthorityProps) => {

  const { closeModal: onClose } = useModal();
  const dispatch = useDispatch();

  const handleClose = (values) => {
    onClose();
  }

  const handleInitModal = () => {
    dispatch(clearAssistatText());
  }

  useEffect(() => {
    handleInitModal()
    return () => {
      dispatch(clearAssistatText());
    }
  }, []);

  return (
    <ModalAssistant open={open}>
      <Wizard machine={MachineAddressAndCurrency} initialValues={paramsMutation} onComplete={handleClose} />
    </ModalAssistant>
  )
}

export default ModalBankingAuthority;
import React, { FC, useEffect, useRef, useMemo } from 'react';
import { useDispatch } from "react-redux";
import * as Yup from 'yup';
import Form from "../../../../../components/forms/Form/Form";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import FormProps from "../../../../../modules/generic/forms/interfaces/FormProps";
import { addAssistantText, clearAssistatText } from "../../../../../modules/generic/store/action";
import TemplateFormDefault from "../../../../../modules/generic/templates/Modal";
import ControllerInput from "../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import TextInput from '../../../../../components/inputs/Text/TextInput'
import classes from './classes.module.scss';
import Button from "../../../../../modules/generic/components/Buttons/Button";
import useTranslate from '../../../../generic/hooks/useTranslate';
import TemplateLabel from '../../../../generic/templates/Label';
import FileUploaderInput from '../../../../../components/inputs/FileUploader/FileUploaderInput';
import SelectInput from '../../../../../components/inputs/Select/SelectInput/SelectInput';
import _ from 'lodash';
import useSession from "../../../../../modules/session/hooks/useSession";
import useGetStakeholders from '../../../../../hooks/services/modules/stakeholders/useGetStakeholders';

interface Props extends FormProps {
  initialValues: any,
  onCompleteSubmit: any,
  key: string,
  handlePrev: any,
  dataGroupCompanies: any,
}

const FormAddBoardResolutionDocument: FC<Props> = ({
  initialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
  handlePrev,
  key,
  dataGroupCompanies,
}) => {

  const refForm = useRef();
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const { startupId } = useSession();

  const schema = Yup.object().shape({
    name: Yup.string().required(translate('MODULES.GROUP_COMPANY.ENGAGE_CONCIERGE.VALIDATION_REQUIRED'))
      .trim()
      .min(3,translate('MODULES.DOCUMENTS.NAMING_MINIMUM_LENGTH'))
      .matches(/^(?!.*[<>:/\\|?*""]).*/g,translate('MODULES.DOCUMENTS.NAMING_FORBIDDEN_CHARS')),
    file: Yup.object().nullable().required('This field is required'),
    boardType: Yup.string().nullable().required('Required'),
    company: Yup.string().nullable().required('Required'),
    investor: Yup.string().nullable().required('Required'),
  })

  useEffect(() => {
    handeAddTooltips();
    return () => {
      dispatch(clearAssistatText());
    }
  }, []);

  const handeAddTooltips = () => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText(translate('BOARD_RESOLUTION_STEP1_TOOLTIP3')));
  }

  const boardTypeOptions = [
    { label: 'Convertible Loan Note', id: "LOAN" },
    { label: 'SAFE', id: "SAFE" },
    { label: 'KISS', id: "KISS" },
    { label: 'Fixed Equity', id: "FIXED" },
    { label: 'Other', id: "OTHER" },
  ];

  const { 
    data: dataStakeholders,
  } = useGetStakeholders({ variables: { startupId, filters: {"role": ["INVESTOR"]} } });

  const groupCompaniesList = useMemo(() => {
    const result = _.map(
      _.orderBy(dataGroupCompanies, ["isHoldingCompany"], ["desc"]),
      (gc) => ({ 
        value: gc.id,
        label: gc.name,
        type: "groupCompany",
        code: gc.country.code,
      })
    );
    return result;
  },[JSON.stringify(dataGroupCompanies)]);
  
  const investorsStakeholders = useMemo(() => {
    const result = _.map(dataStakeholders,st => {
      return {
        value: st.id,
        label: st.fullName
      }
    });
    return result;
  },[JSON.stringify(dataStakeholders)])

  return (
    <>
      <Form
        key={key}
        defaultValues={initialValues || {}}
        schema={schema}
        onSubmit={onCompleteSubmit}
        ref={refForm} optionsForm={{ mode: "onChange" }}
        onlySendDirty={false}
      >
        <Template 
          props={propsTemplate} 
          buttons={{
            ...buttons,
            submit: <SubmitButton {...buttonSubmitProps}>Add</SubmitButton>,
            previous: <Button onClick={handlePrev}>Previous</Button>,
          }}
        >
          <TemplateLabel
            label={'Document type'}
            isRequired = {true}
          >
            <ControllerInput
              as={SelectInput}
              placeholder={"SELECT_INPUT_PLACEHOLDER_NORMAL"}
              list={boardTypeOptions}
              name={"boardType"}
              clear={true}
              onChange={(value) => {
              }}
            />
          </TemplateLabel>
          <TemplateLabel
            label={'Document name'}
            isRequired = {true}
          >
            <ControllerInput
              render={TextInput}
              name='name'
              placeholder={"Type here"}
              defaultlabel={''}
            />
          </TemplateLabel>
          <TemplateLabel
            label={'Company'}
            isRequired = {true}
          >
            <ControllerInput
              as={SelectInput}
              placeholder={"SELECT_INPUT_PLACEHOLDER_NORMAL"}
              list={groupCompaniesList}
              name={"company"}
              clear={true}
              onChange={(value) => {
              }}
            />
          </TemplateLabel>
          <TemplateLabel
            label={'Investor'}
            isRequired = {true}
          >
            <ControllerInput
              as={SelectInput}
              placeholder={"SELECT_INPUT_PLACEHOLDER_NORMAL"}
              list={investorsStakeholders}
              name={"investor"}
              clear={true}
              custompEmptyListMessage={"There are no investors yet, add one to continue"}
              onChange={(value) => {
              }}
            />
          </TemplateLabel>
          <TemplateLabel
            label={'Upload document'}
            isRequired
          >
            <ControllerInput
              render={FileUploaderInput}
              name='file'
              defaultlabel={''}
              className={classes.fileLoad}
            />
        </TemplateLabel>
        </Template>
      </Form>
    </>
  )
}

export default FormAddBoardResolutionDocument
import React,{FC} from 'react'
import LastStep from "../../../core/wizards/steps/LastStep/LastStep";
import {StepProps} from "../../../../../generic/components/Wizard/Step";
import useTranslate from "../../../../../generic/hooks/useTranslate";
const LastFounderStep:FC<StepProps>=({})=>{
    const {translate}=useTranslate()
    return(<>
        <LastStep tooltips={{isAnother:translate('MODULES.ONBOARDINGROLES.FOUNDER.SECOND_STEP_IS_ANOTHER_TOOLTIPS'),isNotAnother:translate('MODULES.ONBOARDINGROLES.FOUNDER.SECOND_STEP_IS_NOT_ANOTHER_TOOLTIPS')}} role={'FOUNDER'}/>
    </>)
}
export default LastFounderStep
import _ from "lodash";
import useQuery from "../../../../../../hooks/custom/useQuery";

export const DOCUMENT_LIST=`
query getDocumentList($startupId:ID!){
        documentList(startupId:$startupId) {
        name
        id
    }
    }
`
const useGetDocumentList=(variables,config)=>{
    const {data,loading}=useQuery(DOCUMENT_LIST,{...variables},{...config})
    return {data:_.get(data,'documentList'),loading}
}
export default useGetDocumentList
import _ from 'lodash';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import useCategories from '../../../../../hooks/common/useCategories';
import us from '../../../../../langs/us';
import { Constants } from '../../../../../v1/utils';
import Button from '../../../../generic/components/Buttons/Button';
import { StepProps } from "../../../../generic/components/Wizard/Step";
import useCustomMachine from '../../../../generic/hooks/useCustomMachine';
import TemplateWizard from '../../../../generic/templates/Modal/TemplateWizard';
import useSession from '../../../../session/hooks/useSession';
import useUpsertDocument from '../../../graphql/document/mutation/useUpsertDocument';
import Form from "../../Forms/FormDocumentUpload";

const Step2: FC<StepProps> = () => {

  const { next, send, prev, state } = useCustomMachine();
  const { getSubcategoryFromDocType } = useCategories();
  const { mutation: updateDocument } = useUpsertDocument();
  const { startupId } = useSession();
  const { id = '' } = useParams<any>()
  const { type, groupCompanyId, stakeholderId } = state.context.paramsData;

  const valuesParties = React.useMemo(() => {
    const values = {}
    groupCompanyId && _.assign(values, { groupCompanies: [{ groupCompany: groupCompanyId }] });
    if (id && !stakeholderId) {
      _.assign(values, { groupCompanies: [{ groupCompany: id }] });
      return values;
    }
    _.assign(values, { parties: [id || stakeholderId] });
    return values;
  }, []);

  const handleCompleteSubmit = async values => {
    const subCategory = getSubcategoryFromDocType(type);
    let subCategories = [subCategory.key];
    if (!!values.subCategories) {
      subCategories = _.concat(subCategories, values.subCategories);
    }
    const variables = {
      startupId,
      agreementData: {
        ...values,
        ...valuesParties,
        subCategories,
        type,
        status: Constants.AGREEMENT_STATUS.UPLOADED,
      }
    }
    await updateDocument({ variables })
    next(values);
  }

  const handleCancel = async values => {
    send("CANCEL");
  }

  const handlePrev = () => prev();

  const { CATEGORIES_TITLES } = us;

  return (
    <React.Fragment>
      <Form onCompleteSubmit={handleCompleteSubmit} Template={TemplateWizard} propsTemplate={{
        title: 'Now for a few more details...',
        subTitle: `Add the ${CATEGORIES_TITLES[type]}`
      }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button>,
          previous: <Button onClick={handlePrev} variant="secondary">Previous</Button>
        }}>
      </Form>
    </React.Fragment>
  )
}

export default Step2;

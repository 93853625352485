import { createMachine } from "xstate";
const machine = createMachine({
    id: "EditGeneralDetails",
    initial: "InitialData",
    states: {
        InitialData: {
            invoke: {
                src: "initialData",
                id: "initialData",
                onDone: [
                    {
                        target: 'PincasData',
                        actions: ['setContextAfterInvoke']
                    }
                ],
                onError: [
                    {
                        target: 'cancel'
                    }
                ]
            }
        },
        PincasData: {
            invoke: {
                src: 'pincasData',
                id: 'pincasData',
                onDone: [
                    {
                        target: 'GeneralDetails',
                        actions: ['setContextAfterInvoke']
                    }
                ],
            }
        },
        GeneralDetails: {
            on: {
                NEXT: {
                    target: "SaveStep",
                    actions: ["setContextAfterStep"]
                },
                CANCEL: {
                    target: "finish"
                }
            }
        },
        error: {
            on: {
                PREV: {
                    target: "GeneralDetails"
                }
            }
        },
        SaveStep: {
            invoke: {
                src: "SaveStep",
                id: "SaveStep",
                onDone: [
                    {
                        target: "finish"
                    }
                ],
                onError: [
                    {
                        target: "cancel"
                    }
                ]
            }
        },
        cancel: {
            entry: "cancelMachine",
            type: "final"
        },
        finish: {
            entry: "stopMachine",
            type: "final"
        }
    }
});

export default machine;
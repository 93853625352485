
import React, { useEffect, useState, FC } from 'react';
import Button from "../../../../generic/components/Buttons/Button";
import useTranslate from '../../../../generic/hooks/useTranslate';
import useSession from "../../../../session/hooks/useSession";
import { useParams } from 'react-router';
import useUpsertFormationCompany from "../../graphql/mutations/useUpsertFormationCompany";
import TemplateWizard from "../../../../generic/templates/Modal/TemplateWizard";
import FormSelectNatureOfBusiness from "../../Forms/FormBusinessActivity";
import { addAssistantText, clearAssistatText } from '../../../../generic/store/action';
import { useDispatch } from "react-redux";
import useModal from '../../../../generic/hooks/useModal/index';
import _ from 'lodash';
import ModalAssistant, { ModalAssistantProps } from '../../../../generic/components/Modal/ModalAssistant/index';
import useUpdateGroupCompany from "src/hooks/services/modules/groupCompany/useUpdateGroupCompany";

type Mode = 'TASK' | 'EDIT'

interface ModalNatureOfBusinessProps extends ModalAssistantProps {
  paramsMutation: { mode: Mode, initialValues?: any },
  onClose(): void
}

const ModalNatureOfBusiness: FC<ModalNatureOfBusinessProps> = ({
  open: openParam = false,
  paramsMutation,
  ...props
}: ModalNatureOfBusinessProps) => {

  const [open, setOpen] = useState(openParam);
  const { closeModal: onClose } = useModal();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>()
  const { startupId } = useSession()
  const { translate } = useTranslate()
  const [mutation] = useUpsertFormationCompany({}, {})

  const [updateGroupCompany] = useUpdateGroupCompany({}, {});
  useEffect(() => {
    setOpen(openParam);
  }, [openParam]);

  const handleClose = (values) => {
    onClose();
    setOpen(false);
  }

  
  const handleCompleteSubmit = async (values) => {
    let companyId = null
    if (!values?.noHasOperatingCompany) {
      let addData;
      if (values?.noHaveWebsite) {
        addData = {
          hasNoWebsiteOrSocialMedia: true,
          linkedinURL: null,
        }
      } else {
        addData = {
          linkedinURL: values?.website,
        }
      }
      const hasCompanyId = _.has(values, 'companyName.id');

      if (hasCompanyId) {
        companyId = values.companyName.id;
        let variables = {
          startupId,
          groupCompanyId: values.companyName?.id,
          groupCompany: { ...addData },
        };
        await updateGroupCompany(variables);
      } else {
        let variables;
        if (values?.jurisdiction === "OTHER") {
          variables = {
            startupId,
            groupCompany: {
              country: values?.country,
              isHoldingCompany: false,
              jurisdiction: values?.otherJurisdiction,
              name: values?.companyName?.fullName,
              transactionCurrency: "USD",
              ...addData,
            }
          };
        } else {
          variables = {
            startupId,
            groupCompany: {
              country: values?.country,
              isHoldingCompany: false,
              jurisdiction: values?.jurisdiction,
              name: values?.companyName?.fullName,
              transactionCurrency: "USD",
              ...addData,
            }
          };
        }
        const newGroupComapny = await updateGroupCompany(variables);
        companyId = newGroupComapny?.data?.upsertGroupCompany?.id;

      }
    }

    const variables = {
      groupCompanyId: id,
      startupId,
      groupCompanyData: {
        formationData: {
          businessActivity: {
            activity: values?.activity,
          }
        }
      }
    }
    if (companyId) {
      variables.groupCompanyData.formationData.businessActivity.operatingCompanyId = companyId
    }
    await mutation(variables);

    handleClose(values)
  }

  const handleInitModal = () => {
    dispatch(clearAssistatText());
  }

  useEffect(() => {
    handleInitModal()
    dispatch(addAssistantText(translate('MODULES.GROUP_COMPANY.NATURE_OF_BUSINESS.FIRST_STEP.TOOLTIP')))
    return () => {
      dispatch(clearAssistatText());
    }
  }, []);

  return (
    <ModalAssistant open={open}>
      <FormSelectNatureOfBusiness
        initialValues={paramsMutation.initialValues}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: "Edit nature of underlying business",
          subTitle: translate('MODULES.GROUP_COMPANY.NATURE_OF_BUSINESS.FIRST_STEP.SUBTITLE')
        }}
        buttons={{
          cancel: (
            <Button onClick={handleClose} variant="secondary">{translate('BUTTON_CANCEL')}</Button>
          ),
        }}
      />
    </ModalAssistant>
  )
}

export default ModalNatureOfBusiness;
import _ from "lodash";
import React, {useRef } from 'react';
import * as Yup from 'yup';
import BotoneraAdd from '../../../../components/forms/contents/BotoneraAdd/BotoneraAdd';
import FormAdd from '../../../../components/forms/contents/FormAdd/FormAdd';
import RowAdd from '../../../../components/forms/contents/RowAdd/RowAdd';
import Form from '../../../../components/forms/Form/Form';
import SubmitButton from "../../../../components/generic/Buttons/SubmitButton/SubmitButton";
import RadioInput from "../../../../components/inputs/Radio/RadioInput";
import Text from "../../../../components/text/Text/Text";
import ControllerInput from "../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import useFetch from '../../../../modules/generic/hooks/useFetch';
import useVersion from '../../../..//modules/versions/hooks/useVersion';
import classes from "./VersionManagerView.module.scss";
import notification from "../../../../v1/utils/notification";

const schema = Yup.object();

const VersionManagerView = (props) => {

  const { versions, setVersions, loading: loadingVersion } = useVersion()
  const formRef = useRef();

  const componentsVersion = {
    
    // cards: [{ id: "v0", label: "v0" }, { id: "v1.0", label: "v1.0" }],
  }

  const onCompleted = () => {
    if (_.get(window, "globalNotification")) {
      notification.sendNotification("Success", "success", 2500);
    }
  }

  const { refetch, loading, response } = useFetch({ 
    url: `/auth/featureVersions`, 
    onCompleted, 
    options: { method: "POST" },
    cancelAutimaticFetch: true
  });
  
  const onSubmit = (values) => {
    refetch({
      data: {
        payload: values
      }
    });
    setVersions({ ...versions, ...values })
  }

  return (
    <React.Fragment>
      <div className={classes.VersionManagerView}>
        <FormAdd title={"Versions"} subTitle={"Select option version"}>
          <Form defaultValues={versions} onlySendDirty={false}
            // loadingComponent={<div>Esqueleton</div>}
            ref={formRef} schema={schema} loading={loading || loadingVersion} onSubmit={onSubmit} >
            {_.map(componentsVersion, (options, key) => {
              return (
                <RowAdd
                  key={key}
                  className={classes[key]}
                  label={`VERSION_KEY_${key}`}
                  asterisk={true}
                  input={
                    <ControllerInput
                      as={RadioInput}
                      options={options}
                      name={key}
                    />
                  }
                />
              )
            })}
            <BotoneraAdd>
              <SubmitButton><Text uuid={"GENERIC_BUTTON_SUBMIT"} /></SubmitButton>
            </BotoneraAdd>
          </Form>
        </FormAdd>
      </div>
    </React.Fragment >
  )
}

export default VersionManagerView;

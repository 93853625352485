import React, { FC } from "react";
import TemplateWizard from "../../../../generic/templates/Modal/TemplateWizard";
import { StepProps } from "../../../../generic/components/Wizard/Step";
import useTranslate from "../../../../generic/hooks/useTranslate";
import useCustomMachine from "../../../../generic/hooks/useCustomMachine";
import Button from "../../../../generic/components/Buttons/Button";
import { clearAssistatText } from "../../../../generic/store/action";
import { useParams } from "react-router-dom";
import useSession from "../../../../session/hooks/useSession";
import useModal from "../../../../generic/hooks/useModal";
import FormCV from "../../Forms/FormCV";
import { useDispatch } from "react-redux";
import useUpdateStakeholder from "src/modules/startup/onBoarding/core/graphql/mutations/useUpdateStakeholder";

const FirstStep: FC<StepProps> = () => {

  const { closeModal } = useModal();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const { send, stepData, state } = useCustomMachine();
  const { id } = useParams();
  const { startupId } = useSession();
  const { updateStakeholder } = useUpdateStakeholder({}, {});

  const handlePrevious = () => {
    send("PREVIOUS");
  };

  const handleCancel = () => {
    dispatch(clearAssistatText());
    send("CANCEL");
  };

  const onSubmit = async (values) => {
    dispatch(clearAssistatText());
    const variables = {
      startupId,
      stakeholderId: state?.context?.data?.stakeholderId ?? id,
      stakeholderData: {
        stakeholder: {
          ...values,
        },
      }
    };
    await updateStakeholder({ ...variables });
    closeModal();
  };

  return (
    <FormCV
      initialValues={stepData}
      Template={TemplateWizard}
      onCompleteSubmit={onSubmit}
      propsTemplate={{
        title: translate("MODULES.STAKEHOLDER.PROFESSIONAL_HISTORY.SECOND_STEP_B.TITLE"),
        subTitle: translate("MODULES.STAKEHOLDER.PROFESSIONAL_HISTORY.SECOND_STEP_B.SUBTITLE"),
      }}
      buttons={{
        cancel: <Button onClick={handleCancel}>Cancel</Button>,
        previous: <Button onClick={handlePrevious}>Previous</Button>,
      }}
    />
  );
};
export default FirstStep;

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import AutocompleteClara from "src/modules/generic/components/Inputs/Autocomplete";
import * as Yup from 'yup';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateLabel from 'src/modules/generic/components/Inputs/LabelInput';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText } from 'src/modules/generic/store/action';
import Checkbox from "src/components/inputs/Checkbox/Checkbox";
import classes from "../../styles/director.module.scss";

function AddCorporate() {
  const { next, context, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.initialData ?? {}
  }, [context]);

  const { selectedCompany, groupCompanies, groupCompaniesMapped, stakeholders } = initialValues;

  const stakeholdersList = React.useMemo(() => {
    const filterOnlyCorporate = stakeholders.filter(st => st.isAnEntity);
    return filterOnlyCorporate.map(st => ({ value: st, label: st.fullName }));
  }, [JSON.stringify(stakeholders)]);

  const createStakeholder = (value: string) => {
    return { fullName: value };
  }

  const handleCancel = () => {
    cancel();
  }

  const handlePrev = () => {
    prev();
  }

  const handleSubmit = async (values) => {
    next(values);
  }

  useEffect(() => {
    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch]);

  const [add, setAdd] = useState(false);

  const schema = Yup.object().shape({
    stakeholder: Yup.object().nullable().required(translate('MODULES.ONBOARDINGROLES.SHAREHOLDER_V2.FIELD_REQUIRED')),
    company: Yup.array().required(translate('MODULES.ONBOARDINGROLES.SHAREHOLDER_V2.FIELD_REQUIRED')).min(1, 'Please select a company')
  }).test('stakeholder', null, (obj) => {
    if (obj && obj.company?.length > 0 && obj?.stakeholder?.fullName?.length > 0) {
      const isCompanyValid = obj.company.some((companyId) => {
        const groupCompany = groupCompanies.find((gc) => gc.id === companyId);
        return groupCompany && (groupCompany.jurisdictionType === 'ADGM' || groupCompany.jurisdictionType === 'CAYMAN');
      });
      setAdd(!isCompanyValid);
      return true;
    } else {
      setAdd(true);
      return false;
    }
  });

  return (
    <Form
      defaultValues={context?.AddCorporate}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: translate("MODULES.ONBOARDINGROLES.DIRECTOR_V2.INDIVIDUAL_OR_COMPANY_TITLE"),
          skeleton: null,
          subTitle: translate("MODULES.ONBOARDINGROLES.DIRECTOR_V2.CORPORATE_SUBTITLE"),
          variant: 'simple'
        }}
        buttons={{
          previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>{add ? translate('GENERIC_BUTTON_ADD_GROUP') : translate('BUTTON_NEXT')}</SubmitButton>,
        }}
      >
        <>
          <TemplateLabel label={translate("MODULES.ONBOARDINGROLES.SHAREHOLDER_V2.COMPANY_NAME_FIELD")} isRequired>
            <ControllerInput
              render={AutocompleteClara}
              getCreateValue={createStakeholder}
              name='stakeholder'
              options={stakeholdersList}
              placeholder={translate("MODULES.ONBOARDINGROLES.SHAREHOLDER_V2.COMPANY_NAME_PLACEHOLDER")}
            />
          </TemplateLabel>
          <div style={{ display: groupCompaniesMapped.length > 1 ? "block" : "none" }}>
            <TemplateLabel label={translate("MODULES.ONBOARDINGROLES.DIRECTOR_V2.INDIVIDUAL_OR_COMPANY_COMPANY_FIELD")} isRequired>
              <ControllerInput
                render={Checkbox}
                name='company'
                variant='col_1'
                defaultValue={[selectedCompany?.id]}
                options={groupCompaniesMapped}
                classCheckboxLine={classes.CompanyCheckboxButton}
              />
            </TemplateLabel>
          </div>
        </>
      </TemplateWizard>
    </Form>
  );
}

export default AddCorporate;


import useLazyQuery from "src/hooks/custom/useLazyQuery";
export const GET_STAKEHOLDER = `
query getStakeholderDetail ($startupId: ID!, $stakeholderId: ID!) {
  getStakeholder(startupId: $startupId, stakeholderId: $stakeholderId) {
    id
		managedByClaraConnections {
      id
      name
      jurisdictionType
      statusOnMatters {
        isRenewal
      }
    }
  }
}
`

const useGetStakeholder =(variables) => {
    const { manualQuery, loading, error, data, refetch } = useLazyQuery(GET_STAKEHOLDER, variables);
    
    const query = async (variables) => {
        const { data } = await manualQuery({variables})
        return {
          stakeholder: data['getStakeholder'],
        }
    }

    return {
        query,
        loading,
        error,
        data,
        refetch
    }
}
export default useGetStakeholder
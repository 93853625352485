import React, { Component } from 'react';
import classes from "../EditForm.module.scss";
import { Field } from "formik";
import TextInput from "../../../../containers/Forms/Inputs/TextInput";
import ClaraInputSelect from "../../../Form/ClaraInputSelect/ClaraInputSelect";
import ClaraSelect from "../../../Form/ClaraSelect/ClaraSelect";
import moment from "moment";
import FormikHelper from "../../../../utils/formikHelper";
import ChatFileUploaderAndSelector from '../../../../../components/inputs/ChatFileUploaderAndSelector/ChatFileUploaderAndSelector';

class ConvertiblesEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            issuedDate: props.issuedDate,
            selectedGroupCompany: {},
            documents: [],
            investors: [],
            groupCompanySelected: {},
        };
    }

    handleDateChange(e, field, form) {

        const date = e === null ? null : moment(e).format("YYYY-MM-DD");
        FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

        this.setState({
            [field.name]: e
        })
    }


    filterInvestors(groupCompany) {
        return this.props.stakeholders;
    }

    filterDocuments(gc) {
        return this.props.documents.filter(propDocuments => {
            return gc.documents && gc.documents.some(doc => doc.id === propDocuments.id)
        });
    }

    findGroupCompany(gcId) {
        return this.props.groupCompanies.filter(gc => gc.id === gcId)[0] || {};
    }

    /*Config Compoenents Methods*/
    getCompanyAdds = () => {
        return [{
            type: "groupCompany",
            label: "Add New Company",
            textWarning: "This will create a new company for your startup. You can add more details later from the company's profile page.",
            placeholder: "Enter Company Name",
            startupId: this.props.startupId,
            params: null
        }]
    }
    getCompanyLists = () => {
        return [
            {
                type: "groupCompany",
                list: this.props.groupCompanies
            }
        ]
    }
    getOwnerAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add New Corporate Stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add more details later from the stakeholder's profile page.",
                placeholder: "Enter Stakeholder's Name",
                startupId: this.props.startupId,
                textToSave: 'fullName',
                params: {
                    isAnEntity: true,
                    roles: [{
                        role: "CONVERTIBLEHOLDER",
                        groupCompany: this.state.groupCompanyId,
                    }],
                }
            },
            {
                type: "stakeholder",
                label: "Add New Individual Stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add more details later from the stakeholder's profile page.",
                placeholder: "Enter Stakeholder's Name",
                startupId: this.props.startupId,
                textToSave: 'fullName',
                params: {
                    isAnEntity: false,
                    roles: [{
                        role: "CONVERTIBLEHOLDER",
                        groupCompany: this.state.groupCompanyId
                    }],
                }
            },
        ]
    }
    getOwnerLists = () => {
        return [
            {
                type: "stakeholder",
                list: this.props.stakeholders
            }
        ]
    }

    handleGroupCompanyChange = (value) => {
        const gcId = value ? value.id : null;
        this.setState({
            groupCompanyId: gcId
        })
        const gc = this.findGroupCompany(gcId);
        this.setState({
            groupCompanyId: gcId,
            groupCompanySelected: gc,
            documents: this.filterDocuments(gc),
            investors: this.filterInvestors(gc)
        });
    }

    render() {

        const { tooltips = {} } = this.props;
        const { groupCompanySelected } = this.state;
        return (
            <form className={classes.Form}>
                <div>
                    <div className={classes.ContainerRowAgreement}>
                        <Field name='company'>
                            {({ field, form }) => (
                                <ClaraSelect
                                    required={true}

                                    field={field}
                                    form={form}

                                    label={"Company"}
                                    placeholder={"Search or select from a list..."}
                                    adds={this.getCompanyAdds()}
                                    lists={this.getCompanyLists()}
                                    callbacks={{ updateValues: this.handleGroupCompanyChange }}
                                    tooltip={tooltips['Company']}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRowAgreement}>
                        <Field name='owner'>
                            {({ field, form }) => (
                                <ClaraSelect
                                    required={true}

                                    field={field}
                                    form={form}
                                    disabled={this.state.groupCompanyId ? false : true}
                                    label={"Who is the investor?"}
                                    placeholder={"Search or select from a list..."}
                                    adds={this.getOwnerAdds()}
                                    lists={this.getOwnerLists()}
                                    tooltip={tooltips['Who is the investor']}

                                />
                            )}
                        </Field>
                    </div>
                    <hr className={classes.Separator} />
                    <div className={classes.ContainerRowAgreement}>
                        <Field name='amount'>
                            {({ field, form }) => (
                                <TextInput
                                    required={true}
                                    type='text'
                                    label='Investment Amount: '
                                    field={field}
                                    form={form}
                                    placeholder={"Enter number..."}
                                    tooltip={tooltips['Investment Amount']}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRowAgreement}>
                        <Field name='discountRate'>
                            {({ field, form }) => (
                                <TextInput
                                    required={false}
                                    type='text'
                                    label='Discount: '
                                    field={field}
                                    form={form}
                                    placeholder={"Enter number..."}
                                    tooltip={tooltips['Discount']}
                                    rightLabel="%"
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRowAgreement}>
                        <Field name='interestRate'>
                            {({ field, form }) => (
                                <TextInput
                                    required={false}
                                    type='text'
                                    label='Interest Rate: '
                                    field={field}
                                    form={form}
                                    placeholder={"Enter number..."}
                                    tooltip={tooltips['Interest Rate']}
                                    rightLabel="%"
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRowAgreement}>
                        <Field name='valuationCap'>
                            {({ field, form }) => (
                                <TextInput
                                    required={false}
                                    type='text'
                                    label='Valuation Cap: '
                                    field={field}
                                    form={form}
                                    placeholder={"Enter number..."}
                                    tooltip={tooltips['Valuation Cap']}
                                    rightLabel={groupCompanySelected.transactionCurrency ? groupCompanySelected.transactionCurrency : 'USD'}
                                />
                            )}
                        </Field>
                    </div>
                    <hr className={classes.Separator} />
                    <div className={classes.ContainerRowAgreement}>
                        <Field name='issuedDate'>
                            {({ field, form }) => (
                                <TextInput
                                    type='date'
                                    label='Issued Date'
                                    field={field}
                                    form={form}
                                    placeholder={"Date"}
                                    tooltip={tooltips['Issued Date']}
                                    prevComponentChange={e =>
                                        this.handleDateChange(e, field, form)
                                    }
                                >
                                </TextInput>
                            )}
                        </Field>
                    </div>
                    <hr className={classes.Separator} />
                    <div className={classes.ContainerRowAgreement}>
                        <span className={classes.fileUploaderAndSelectorLabel}><label className={classes.labelShareEdit}>Associated Documents:</label></span><span className={classes.fileUploaderAndSelectorContainer}><Field name='associatedDocuments'>
                            {({ field, form }) => (
                                <ChatFileUploaderAndSelector
                                    required={false}
                                    field={field}
                                    name='associatedDocuments'
                                    multipleFiles={true}
                                    form={form}
                                    label={"Associated Documents"}
                                    documents={this.state.documents}
                                />
                            )}
                        </Field></span>
                    </div>
                </div>
            </form>
        );
    }
}

export default ConvertiblesEdit;

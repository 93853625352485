export default {
  STEP_1:{
  TITLE:'What equity position do you want to add?',
  SUBTITLE:'Equity positions',
  TOOLTIP:'Keep an eye on the Clara star for hints and tips'
  },
  STEP_ADD_SHARE:{
    TITLE:'Add Share',
    SUBTITLE:'Equity positions',
    TOOLTIP:'Keep an eye on the Clara star for hints and tips'
    },
    STEP_ADD_CONVERTIBLE:{
      TITLE:'Add a Convertible',
      SUBTITLE:'Equity positions',
      TOOLTIP:'Keep an eye on the Clara star for hints and tips'
    },
    STEP_ADD_OPTION:{
      TITLE:'Add Share Options',
      SUBTITLE:'Equity positions',
      TOOLTIP:'Keep an eye on the Clara star for hints and tips'
    },
    STEP_ADD_WARRANT:{
      TITLE:'Add a Warrant',
      SUBTITLE:'Equity positions',
      TOOLTIP:'Keep an eye on the Clara star for hints and tips'
    },
}
import {Constants} from '../../../v1/utils/constants';
import {helper} from "../../../v1/utils";
import moment from "moment";

export default function useFormat() {

  const currencyFormat= (num, noDecimals, format) => {
    if (noDecimals) {
      num = parseFloat(num);
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g,format.thousandSeparator);
    } else {
      
      num = parseFloat(num).toFixed(format.decimalScale);
      
      if (format?.fixedDecimal) {
        num = parseFloat(num).toString();
      }
      
      const [currency, decimal] = num.split('.');
      if (decimal && parseInt(decimal)>0) {
        return `${currency.replace(/\B(?=(\d{3})+(?!\d))/g, format.thousandSeparator)}${format.decimalSeparator}${decimal}`;
      } else {
        return currency.replace(/\B(?=(\d{3})+(?!\d))/g, format.thousandSeparator);
      }
    }
  }
  
  const moneyFormat = (amount, noDecimals = false, params) => {
    let format = helper.getFormatByKey(Constants.TYPE_FORMATS.NUMBER, params) || {};
    if (amount) {
      amount = currencyFormat(amount, noDecimals, format);
    }
    return amount;
  };

  const numberFormat = (amount, noDecimals = false, params) => {
    let format = helper.getFormatByKey(Constants.TYPE_FORMATS.NUMBER, params) || {};
    if (amount) {
      amount = currencyFormat(amount, noDecimals, format);
    }
    return amount;
  };


  const dateFormat = (date, params) => {
    let format = helper.getFormatByKey(Constants.TYPE_FORMATS.DATE_ASSISTANCE, params) || {};
    if (date) {
      date = moment(date).format(format);
    }
    return date;
  };

  const percentFormat =(percentage, noDecimals = false, params) => {
    let format = helper.getFormatByKey(Constants.TYPE_FORMATS.PERCENT, params) || {};
    let value = percentage;
    if (percentage) {
      value = currencyFormat(percentage, noDecimals, format);
    }
    return `${value}%`;
  };

  return { moneyFormat, dateFormat,numberFormat, percentFormat };
}

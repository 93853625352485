import _ from 'lodash'
import React, { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Button from '../../../../../generic/components/Buttons/Button';
import { StepProps } from "../../../../../generic/components/Wizard/Step";
import useCustomMachine from '../../../../../generic/hooks/useCustomMachine';
import { addAssistantText, clearAssistatText } from '../../../../../generic/store/action';
import TemplateWizardPreview from '../../../../../generic/templates/Modal/TemplateWizard/TemplateWizardPreview';
import useSession from '../../../../../session/hooks/useSession';
import Form from "../../components/Forms/FormPassportData";
import useUpsertTwoDocumentFormation from '../../../../../../hooks/services/modules/individualStakeholder/useUpsertTwoDocumentFormation';

const Step2: FC<StepProps> = () => {

  const { next, send, state } = useCustomMachine();
  const data = useMemo(() => _.get(state, 'context.data', {}), [JSON.stringify(state)])
  const [updateDocument] = useUpsertTwoDocumentFormation();
  const { id } = useParams<any>()
  const { startupId } = useSession();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(addAssistantText(`A copy of a UID Stamp is required for all UAE Nationals.`));
    return () => {
      dispatch(clearAssistatText());
    }
  }, [])

  const handleCompleteSubmit = async values => {
    const variables = {
      stakeholderId: state?.context?.data?.stakeholderId ?? id,
      startupId,
      identityDocumentDataOLD: { ...values, "type": "OLDPASSPORT_DOCUMENT" },
      identityDocumentData: { ..._.get(data, 'set_document_visa', {}), "type": "UID_STAMP_DOCUMENT" }
    }
    await updateDocument({ variables });
    next(values);
  }

  const handleCancel = async values => {
    send("CANCEL");
  }

  return (
    <React.Fragment>
      <Form
        initialValues={_.get(data, 'set_old_passport', {})}
        isOldPassport={true}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizardPreview}
        propsTemplate={{
          title: 'Add their old passport',
          subTitle: 'UID stamp'
        }}
        buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }}>
      </Form>
    </React.Fragment>
  )
}

export default Step2;

import helper from "./helper"

/* 
FUNCTIONS:
-sendNotification
    params: {
        message: message to shown on snackbar
        type: error/success the style of snackbar and position depends on this type
        duration: how long should show the snackbar
    }
    returns: just set the globalVariable window.globalNotification with the notification passed on params

- sendNotificationTranslate
    params: {
        message: message to shown on snackbar
        type: error/success the style of snackbar and position depends on this type
        duration: how long should show the snackbar
    }
    returns: just set the globalVariable window.globalNotification with the notification passed on params
*/



const sendNotification = (message, type, duration) => {

    window.globalNotification.a = {
        message,
        type,
        duration
    }
}
const sendNotificationTranslate = (message, type, duration) => {
    window.globalNotification.a = {
        message:helper.getTranslateTextByKey(message),
        type,
        duration
    }
}


export default{
    sendNotification,
    sendNotificationTranslate
}
export default {
    FIRST_STEP:{
        TITLE:'Do they have a LinkedIn account?',
        SUBTITLE:'Professional background',
        TOOLTIP:'Some jurisdictions require additional information.'
    },
    SECOND_STEP_A:{
        TITLE:'Add their LinkedIn URL',
        SUBTITLE:'Professional background',
        TOOLTIP:'Some jurisdictions require additional information.'
    },
    SECOND_STEP_B:{
        TITLE:'Add their CV instead',
        SUBTITLE:'Professional background',
        TOOLTIP:'Some jurisdictions require additional information.'
    }
}
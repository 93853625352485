import { useMemo } from 'react';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import WhatHappensTechLicenceStepForm from '../steps/WhatHappensTechLicenceStepForm';
import WhatHappensHoldingCompanyStepForm from '../steps/WhatHappensHoldingCompanyStepForm';
import CloseDownCompanyStepForm from "../steps/CloseDownCompanyStepForm";
import CloseDownCompanyWhatHappensStepForm from "../steps/CloseDownCompanyWhatHappensStepForm";

const useSteps = () => {

  return useMemo(() => ({
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    WhatHappensTechLicenceStepForm: {
      render: WhatHappensTechLicenceStepForm,
    },
    WhatHappensHoldingCompanyStepForm: {
      render: WhatHappensHoldingCompanyStepForm,
    },
    CloseDownCompanyStepForm: {
      render: CloseDownCompanyStepForm,
    },
    CloseDownCompanyWhatHappensStepForm: {
      render: CloseDownCompanyWhatHappensStepForm,
    },
    SaveStep: {
      render: TemplateWizardSkeletonMachine,
    },
    StrikeOff: {
      render: TemplateWizardSkeletonMachine,
    },
  }), []);
}

export default useSteps;

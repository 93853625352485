import _ from "lodash";
import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import Text from '../../../../../text/Text/Text';
import Icon from '../../../../../../modules/generic/components/Icon';
import classes from './DocumentTypeCard.module.scss';
import Skeleton from '@material-ui/lab/Skeleton';

const DocumentTypeCard = (props) => {
 
    return (


        <div className={classes.cardParentContainer}>
            <div className={`${classes.cardContainer}`}>
                <div className={classes.headerContainer}>
                    <span className={`${classes.checkContainer}`}>
                        <div className={classes.checkCircle}></div>
                    </span>
                   
                </div>
                <div className={classes.nameContainerSkeleton}>
                    <Skeleton variant="text" />
                </div>


            </div>
        </div>


    )
}

export default DocumentTypeCard;

import React from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";

const EditShares = ({go,...props}) => {
 const params = useParams();
 


  return (
          <MicroFrontend id="MF_Share_Content_Edit" component={"./EditShares"} mf="capTable" params={{go,...props,...params}} />
  );
};

export default EditShares;

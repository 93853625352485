import React, {FC, useEffect} from 'react'
import _ from 'lodash'
import useTranslate from "../../../hooks/useTranslate";
import Typography from "../../Texts/Typography";
import {DocumentBO} from "../../../../startup/core/type/startup/document";
import {EquityBO} from "../../../../startup/core/type/startup/equity";
import classes from "./classes.module.scss";
import Scrollbar from "../../../../../components/generic/Scrollbar/Scrollbar";
import ListItem from "../ListItem";

interface Category{
    title:string
    items:string[]
}
type DotColor='red'|'blue'
interface CategoriesProps{
    categories:Category[]
    dotColor:DotColor
}

const CategoriesList:FC<CategoriesProps>=({categories,dotColor= 'blue'})=>{

    return(<>
        <div className={classes.container}>
            <Scrollbar>
                {_.map(categories,category=>{
                    return(<>
                        <Typography component={'h5'} color={'black'} variant={'body'}>
                            {category.title}
                        </Typography>
                        <ul>
                            {_.map(category.items,item=>(
                                <ListItem item={item} color={dotColor} noBottomMargin={true}/>
                            ))}
                        </ul>
                    </>)
                })}
            </Scrollbar>
        </div>
    </>)
}
export default CategoriesList
export default {
  COMPANY_DETAILS : {
    FIRST_STEP: {
      TITLE: "Please confirm the following details",
      SUBTITLE: "Incorporation confirmation",
      TOOLTIP: "Keep an eye on the Clara star for hints and tips.",
      COMPANY_NAME: "Company name",
      COUNTRY_INCORPORATION: "Country of incorporation",
      JURISDICTION: "Jurisdiction",
      COMPANY_TYPE: "Company type",
      INCORPORATION_DATE: "Incorporation date",
    },
  },
  CANCEL: "Cancel",
  CONFIRM: "Confirm",
};

import React from 'react';
import Icon from "../../../../../generic/components/Icon";
import Typography from "../../../../../generic/components/Texts/Typography";
import useModal from './../../../../../../modules/generic/hooks/useModal';
import classesModule from "./ContentCardRol.module.scss";

const ContentCardRol = ({ rolName, rolId, current, totals, filter, enabledLink, onCompleteDelete }) => {




   
    const { openModal } = useModal()
  
    return (
        <React.Fragment>
            <div className={classesModule.ContentCardRol}>
                <div className={classesModule.HeaderTitle}>
                    <div>
                        <Typography variant={"body"} weight={"bold"} color={"black"} component={"h1"}>
                            {rolName}
                        </Typography>
                    </div>
                    <div className={classesModule.Botonera}>
                        <div className={classesModule.Icon}>
                            <Icon isClara icon={'stakeholders'} size={'1rem'} />
                            <span><b>{current}</b>/{totals}</span>
                        </div>

                    </div>
                </div>
            </div>


        </React.Fragment >
    )
};

export default ContentCardRol;

import useMutation from "../../../hooks/custom/useMutation";

const FORMATION_DECLINE_DOCUMENT = `
  mutation formationDeclineDocument($startupId: ID!, $documentId: ID!, $issues: String!) {
    formationDeclineDocument(startupId: $startupId, documentId: $documentId, issues: $issues) {
      id
      name
      status
    }
  }
`;

const useFormationDeclineDocument = (variables) => {
  const [mutation, loading] = useMutation(FORMATION_DECLINE_DOCUMENT, variables);
  return { mutation, loading };
};

export default useFormationDeclineDocument;

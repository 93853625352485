import useLazyQuery from "src/hooks/custom/useLazyQuery";

const GET_GROUP_COMPANY = `
query getGroupCompany($startupId: ID!, $groupCompanyId: ID!) {
  getGroupCompany(startupId: $startupId, groupCompanyId: $groupCompanyId) {
    id
    name
    registeredNumber
    type
    jurisdiction
    licenseType
    companyType
    accountDetails {
      referenceDate
      auditorAppointment
    }
    assetsAndRevenue {
      sharesInIncorporatedCompanies{
          id
          name
          avatar
      }
			isSharesInNotYetFormedCompanies{
        question
        description
      }
    }
    businessActivities {
      id
      activity
      relevantActivities
      articlesOfAssociationDate
      articlesOfAssociationType
      companyArticlesEntrenched
      confirmationStatementLastFiled
      articlesOfAssociation {
        id
        name
        file {
          id
          name
          size
          type
        }
      }
    }
    registeredAddress {
      street
      city
      state
      country {
        name
        code
        alpha3      
      }
      zipCode    
    }
    authorizedSignatories {
      id
      name
      entity {
        ... on StakeHolder {
          id
          fullName
          isAnEntity
        }
        ... on GroupCompany {
          id
          name
        }
      }
    }
    directors {
      id
      fullName
      isAnEntity
    }
  }
}`;

export const useGetGroupCompanyEconomicSubstance = (variables, config) => {
  const { manualQuery, loading, error, data, refetch } = useLazyQuery(
    GET_GROUP_COMPANY,
    variables,
    config
  );
  return { loading, error, data, refetch, manualQuery };
};

export default useGetGroupCompanyEconomicSubstance;

import { useEffect } from 'react';
import useMutation from "../../../custom/useMutation";


const mutation = `mutation setAsSeenSubscriptionGatePopup($startupId: String!, $popupName: String! ){
  setAsSeenSubscriptionGatePopup(
    startupId: $startupId,
    popupName: $popupName,
      ) 
  } `
const useSetAsSeenSubscriptionGatePopup = (options = {}) => {

  const [send, data] = useMutation(mutation, {refreshStartup:false,awaitRefetchQueries:false,shouldRefetchQuery:()=>false,...options});


  return [send, data];
}
export default useSetAsSeenSubscriptionGatePopup;

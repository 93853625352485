import React, { useCallback, useMemo, useState } from 'react';
import _ from "lodash";
import AutocompleteInput from "src/components/generic/AutocompleteInput";
import useGetCountryAndJurisdiction from 'src/modules/generic/components/CountryJurisdictionSelector/useGetCountryAndJurisdiction';
import Flag from './components/Flag';
import { FixedSizeList } from 'react-window';

function VirtualizedList(props) {
  const { children, ...other } = props;
  return (
    <FixedSizeList
      height={300}
      width="100%"
      itemSize={46}
      itemCount={children.length}
      {...other}
    >
      {({ index, style }) => React.cloneElement(children[index], { style, onMouseDown: other.onMouseDown })}
    </FixedSizeList>
  );
}
function ClaraCountryAutocomplete({...props}: any) {
  const {
    countriesList,
    loading,
  } = useGetCountryAndJurisdiction();

  const [countries, setCountries] = useState([]);
  const getItemsBySearchText = useCallback((searchText: string,limit) => {
    const searchedItems = _.filter(countriesList, (item: any) => {
      return item.label.toLowerCase().includes(searchText.toLowerCase());
    });
    return _.take(searchedItems, limit);
  },[countriesList])
  const getItemsByPagination =useCallback((pagination: any) => {
    if (!pagination) return countriesList;
    if (pagination?.filter) return _.filter(countriesList, { code: pagination?.filter });
    return countriesList;
  },[countriesList]);
  const getItemsFiltered = useCallback( (params: any) => {
    if(!params) return;
    if (params.filters?.searchText && params.filters?.searchText !== '') return getItemsBySearchText(params?.filters?.searchText,params?.pagination?.limit);
    return getItemsByPagination(params?.pagination);
  },[getItemsByPagination,getItemsBySearchText])

  const filterCountries = useCallback(async (params) => {
    if (countriesList) {
      const filteredItems = getItemsFiltered(params);
      const countriesFiltered = _.map(filteredItems, (item: any) => ({
        id: item.value,
        description: item.label,
        prefix: (
          <Flag key={item.value} url={`https://flagcdn.com/${_.toLower(item.value)}.svg`} />
        ),
      }))
      setCountries(countriesFiltered);
      return { data: countriesFiltered }
    }
  }, [countriesList,getItemsFiltered]);

  const lazyQueryEmule = useMemo(() => {
    const service = filterCountries;
    const lazy = {
      data: {
        data: countries,
        pageInfo: {
          total: countriesList.length,
          currentPage: 1,
          perPage: 10,
          lastPage: false,
          prevPage: false,
          nextPage: true,
        }
      },
      fetchMore: filterCountries
    }
    return [service, lazy];
  }, [countries, countriesList.length, filterCountries]);

  if (loading) {
    return <p>Loading ...</p>;
  } else {
    return (
      <AutocompleteInput
        placeholder="Type to search country"
        lazyQueryService={lazyQueryEmule}
        multiple={true}
        {...props}
        take={10}
        ListComponent={VirtualizedList}
      />
    )
  }
}
export default ClaraCountryAutocomplete;

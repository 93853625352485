import _ from "lodash";
import {Constants} from '../../../../utils/constants';

const getInitialValues = (type, data) => {

    const defValues = {
        "incorporationJurisdiction": _.get(data.scoping, 'incorporationJurisdiction', ''),
        "companyType": _.get(data.scoping, 'companyType', ''),
        "shareNumbers": _.get(data.scoping, 'shareNumbers', 0),
        "boardMembersMajorityResident": _.get(data.scoping, 'boardMembersMajorityResident', null),
        "bankAccountRequire": _.get(data.scoping, 'bankAccountRequire', null),
        "engagementType": _.get(data.scoping, 'engagementType', ''),
        "anotherIncomeForm": _.get(data.scoping, 'anotherIncomeForm', ''),        
        "whereTheMajorityBoardMembersReside": _.get(data.scoping, 'whereTheMajorityBoardMembersReside', '')
    }

    const holdingCompanyValues = {

        "alreadyIncorporatedText": _.get(data.scoping, 'alreadyIncorporatedText', ''),
        "toBeIncorporatedText": _.get(data.scoping, 'toBeIncorporatedText', ''),
        "onlyRevenueDividendsOrCapital": _.get(data.scoping, 'onlyRevenueDividendsOrCapital', null),
        "anotherIncomeForm": _.get(data.scoping, 'anotherIncomeForm', ''),
        "intellectualPropertyText": _.get(data.scoping, 'intellectualPropertyText', ''),
        "otherAssetText": _.get(data.scoping, 'otherAssetText', ''),
        "isRevenueGenerating": _.get(data.scoping, 'isRevenueGenerating', false),
        "isProtection": _.get(data.scoping, 'isProtection', false)
    }

    const finTechCompanyValues = {

        "foundationReason": _.get(data.scoping, 'foundationReason', ''),
        "foundationAssetsAndWhereAreLocated": _.get(data.scoping, 'foundationAssetsAndWhereAreLocated', ''),
        "whereFoundersAreLiving": _.get(data.scoping, 'whereFoundersAreLiving', ''),
    }

    const techStartupValues = {
        "relevantTechnologyDescription": _.get(data.scoping, 'relevantTechnologyDescription', ''),

    }

    const nonFinancialValues = {
        "companyActivitiesDescription": _.get(data.scoping, 'companyActivitiesDescription', ''),

    }

    const otherCompanyValues = {
        "detailsOtherIncorporation": _.get(data.scoping, 'detailsOtherIncorporation', ''),

    }

    switch (type) {
        case Constants.COMPANY_TYPES.HOLDING_COMPANY:
            return { ...defValues, ...holdingCompanyValues }
        case Constants.COMPANY_TYPES.FOUNDATION:
            return { ...defValues, ...finTechCompanyValues }
        case Constants.COMPANY_TYPES.TECH_STARTUP:
            return { ...defValues, ...techStartupValues }
        case Constants.COMPANY_TYPES.OPERATING_COMPANY:
            return { ...defValues, ...nonFinancialValues }
        case Constants.COMPANY_TYPES.OTHER:
            return { ...defValues, ...otherCompanyValues }
        default:
            return defValues
    }
}



export default getInitialValues;



import {default as React, useEffect, useMemo, useState} from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import _ from 'lodash';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import Typography from "src/modules/generic/components/Texts/Typography";
import classes from './classes.module.scss';

function ADGMSPVEligibility() {
  const { next, context, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const [firstQuestion, setFirstQuestion] = useState(_.get(context, 'ADGMSPVEligibility.shareOwnership_firstQuestion', true));
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.ADGMSPVEligibility ?? {}
  }, [context]);
  
  const handleCancel = () => {
    cancel();
  }

  const handlePrev = () => {
    prev();
  }

  const handleSubmit = async (values) => {
    next(values);
  }

  const options = [
    {
      value: true,
      label: "It will",
    },
    {
      value: false,
      label: "It won't",
    }
  ];
  
  useEffect( ()=>{
    dispatch(addAssistantText('Tell Clara who will own at least 25% of shares in the new company. your choice here helps evaluate the company\'s eligibility for ADGM formation.'));
    return () => {
      dispatch(clearAssistatText());
    }
  },[dispatch]);

  const schema = Yup.object().shape({
    shareOwnership_firstQuestion: Yup.boolean().nullable().required("Required"),
    shareOwnership_secondQuestion: Yup.boolean().nullable().when('shareOwnership_firstQuestion', { is: false, then: Yup.boolean().nullable().required("Required") } ),
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={ { mode: 'onChange' } }
    >
      <TemplateWizard
        props={{
          title: 'Share ownership',
          skeleton: null,
          subTitle: 'ADGM SPV Eligibility',
          variant: 'simple'
        }}
        buttons={{
          previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('BUTTON_NEXT')}</SubmitButton>,
        }}
      >
        <Typography component='h4'>
          Will at least 25% of the new company's shares be owned by GCC nationals or individuals residing in a GCC country?
        </Typography>
        <div>
          <ControllerInput
              as={CheckboxButtonSimple}
              className={classes.SPVEligibilityCheckboxContainer}
              classNameButton={classes.SPVEligibilityCheckboxButton}
              name="shareOwnership_firstQuestion"
              variant="col_1"
              options={options}
              onChange={(value : boolean) => { setFirstQuestion(value)} }
          />
          {!firstQuestion &&
              <div className={classes.secondQuestionContainer}>
                <Typography component='h4'>
                  Will this new company own shares in a GCC company (already existing or to be established) within six months of incorporation?
                </Typography>
                <ControllerInput
                    as={CheckboxButtonSimple}
                    className={classes.SPVEligibilityCheckboxContainer}
                    classNameButton={classes.SPVEligibilityCheckboxButton}
                    name="shareOwnership_secondQuestion"
                    variant="col_1"
                    options={options}
                />
              </div>
          }
        </div>
      </TemplateWizard>
    </Form>
  );
}

export default ADGMSPVEligibility;

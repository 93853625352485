import useMutation from "../../../hooks/custom/useMutation"

const APPROVE_DOCUMENT = `
mutation approveDocumentIncorporation ($matterId: ID!, $documentId: ID!){
    approveDocumentIncorporation(
     matterId: $matterId, 
        documentId: $documentId) {    
        id    
        status 
   }
}
`;

const useApproveDocument = (options, props) => {

	const refetchQueries = ['getDocumentsForApprovalIncorporation']

	const [mutation, data] = useMutation(APPROVE_DOCUMENT, options, {...props, refetchQueries });

	return { mutation, data };
}
export default useApproveDocument;

import React, { useEffect, useState } from 'react';
import classes from "./ClaraRadioWithButton.module.scss"
import { Text, TooltipInput } from "../../index"
import _ from "lodash";
import FormikHelper from "../../../utils/formikHelper";

const ClaraRadioWithButton = (props) => {
    const [selectedValue, setSelectedValue] = useState('');
    const handleOptionChange = (value) => {
        setSelectedValue(value)
        props.form.setFieldValue(props.changeParams, value)
        if (props.onChange) {
            props.onChange(value)
        }
        
        try {
            const {field,form} = props;
            form.validateField(field.name)
        } catch (e) {
        }
    }

    useEffect(() => {
        if (selectedValue !== props.field.value) {
            setSelectedValue(props.field.value)
            props.onChange && props.onChange(props.field.value)
        }
    }, [selectedValue])
    const hasError = () => {
        const { form, field } = props;
        return FormikHelper.returnErrorsForValidation(field.name, form.errors);
    }
    const getError = () => {
        const { form, field } = props;
        return FormikHelper.returnErrorsForValidation(field.name, form.errors);
    }
    const { options, showRedStar, link, tooltip } = props;
    const length = options.length

    return (
        < div className={`${_.get(props, "className.root")} ${classes.InputContainer} `} >
            <div className={`${_.get(props, "className.input")} ${classes.TextInput}`}>
                {props.label ? (
                    <label>{props.label || ""}{props.required && showRedStar !== false ?
                        <span style={{ color: 'red' }}>{' '}*</span> : null}</label>
                ) : null}
                <div className={classes.buttonContainers}><TooltipInput label={tooltip} link={link ? link : null}>
                    {options.map((op, index) => {
                        let classSelected = selectedValue === op.value ? classes.selectedButton : null;
                        let classLargeText = op.value.length > 6 ? classes.largeTextButton : null;
                        return (<div className={classes.buttonContainers}>

                            <button
                                className={`${classes.buttonOption} ${classSelected} ${classLargeText} ${_.get(props, "className.button")}`}
                                disabled={props.disabled} type="button" onClick={() => handleOptionChange(op.value)}
                                hasError={hasError() ? "true" : "false"}
                                parentTop="offsetParent.offsetParent.offsetParent.offsetParent.offsetTop"
                            >
                                <Text uuid={op.label} /></button>
                        </div>
                        )
                    })}</TooltipInput>
                    {hasError() ? (
                        <div className={classes.MessageError}>
                            {getError()}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );

}
export default ClaraRadioWithButton

import useLazyQuery from 'src/hooks/custom/useLazyQuery';

const GET_GROUP_COMPANY = `
  query getGroupCompany($startupId: ID!, $groupCompanyId: ID!) {
    getGroupCompany(startupId: $startupId, groupCompanyId: $groupCompanyId) {
      id
      requestBasket {
        id
        requestData {
          ... on RenewalData {
            taxInformation {
              id
              isTaxResidentsAreFromCayman{
                description
                question
              }
              isEntityInvestmentFund{
                description
                question
              }
              isCompanyCarriedOnRelevantActivity{
                description
                question
              }
              doesHaveFINumber{
                description
                question
              }
              doesHaveIRSGIN{
                description
                question
              }
              isLCCLHolder{
                description
                question
              }
              isRegisteredInCIMA{
                description
                question
              }
              doesHaveTradeBusinessLicense{
                description
                question
              }
            }
          }
        }
      }
    }
  }
`;


export const useGetGroupCompanyEconomicSubstance = (variables, config) => {
  const { manualQuery, loading, error, data, refetch } = useLazyQuery(GET_GROUP_COMPANY, variables, config);
  return { loading, error, data, refetch, manualQuery };
};

export default useGetGroupCompanyEconomicSubstance;

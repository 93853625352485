import { useMemo } from 'react';
import { assign } from 'xstate';

const useActions = () => {
  return useMemo(() => {
    const exampleAction = assign((context: any = {}, event: any = null) => {
      return {
        ...context,
        newValue: true,
      };
    });

    return {
      exampleAction,
    }
  }, []);
}

export default useActions;

import React, {useEffect, useMemo, useState} from 'react';
import {Formik} from 'formik'
import _ from 'lodash'
import classes from '../GetSignatureForm.module.scss';
import Botonera from '../../../../../contents/Botonera/Botonera';
import Text from '../../../../../../text/Text/Text';
import SecondaryButton from "../../../../../../generic/Buttons/SecondaryButton/SecondaryButton";
import PrimaryButton from "../../../../../../generic/Buttons/PrimaryButton/PrimaryButton";
import Line from "../../../../../../generic/Line/Line";
import ContainerModal from '../../../../../../generic/Modal/ModalScroll/ContainerModal'
import IndividualStakeholder from "./individual/IndividualStakeholder";
import MultipleStakeholders from "./multiple/MultipleStakeholders";
import IndividualCompany from "./individual/IndividualCompany";
import useSchemaGenerate from '../Hooks/useSchemaGenerate';
import useGetPartiesByType from '../Hooks/useGetPartiesByType'

const GetSignatureForm = ({
							  showSaveAndClose = true,
							  initialValues,
							  handleSubmit,
							  isSubmitting,
							  handleSaveAndClose,
							  handleCloseModal,
							  ...props
						  }) => {

	const [shAuthorizedSignatoriesId, setSHAuthorizedSignatoriesId] = useState([]);
	const [shAuthorizedSignatoriesEmailDisabled, setSHAuthorizedSignatoriesEmailDisabled] = useState([]);
	const [gcAuthorizedSignatoriesId, setGCAuthorizedSignatoriesId] = useState([]);
	const [gcAuthorizedSignatoriesEmailDisabled, setGCAuthorizedSignatoriesEmailDisabled] = useState([]);
	const schema = useSchemaGenerate(initialValues.type.code, true);
	const getPartiesType = useGetPartiesByType()
			
	const partiesType = useMemo(() => {
		return getPartiesType(initialValues.type.code)
	}, []);

	const parties = useMemo(() => {
		return initialValues.parties || []
	}, []);

	const findStakeholder = (id) => {
		return props.stakeholderList.filter(stakeholder => id === stakeholder.id)[0] || {};
	};

	showSaveAndClose = showSaveAndClose && _.get(initialValues, 'status') !== 'Pending';

	useEffect(() => {
		if (partiesType.partyStakeholder) {
			if (_.get(partiesType, 'partyStakeholder.isIndividual', true)) {
				const stakeholderId = _.get(initialValues, `params.${partiesType.partyStakeholder.party}.id`);

				setSHAuthorizedSignatoriesId([stakeholderId]);
				setSHAuthorizedSignatoriesEmailDisabled([isSigned(stakeholderId)]);

			} else {
				// multiple
				const shAuthorizedSignatories = _.get(initialValues, `params.${partiesType.partyStakeholder.party}`);

				const stakeholdersId = [];
				const stakeholdersEmailDisabled = [];
				shAuthorizedSignatories.map(as => {
					stakeholdersId.push(as.id);
					stakeholdersEmailDisabled.push(isSigned(as.id));
				});

				setSHAuthorizedSignatoriesId(stakeholdersId);
				setSHAuthorizedSignatoriesEmailDisabled(stakeholdersEmailDisabled);

			}
		}

		if (partiesType.partyGroupCompany) {
			const gcAuthorizedSignatories = _.get(initialValues, `params.${partiesType.partyGroupCompany.party}.authorizedSignatories`, []);

			const stakeholdersId = [];
			const stakeholdersEmailDisabled = [];
			gcAuthorizedSignatories.map(as => {
				stakeholdersId.push(as.id);
				stakeholdersEmailDisabled.push(isSigned(as.id));
			});

			setGCAuthorizedSignatoriesId(stakeholdersId);
			setGCAuthorizedSignatoriesEmailDisabled(stakeholdersEmailDisabled);
		}

	}, [JSON.stringify(initialValues)]);

	const handleOnChangeEmailPartyStakeholder = (form, field, value, index) => {
		value = value.trim();
		const asIndex = gcAuthorizedSignatoriesId.findIndex(asId => asId === shAuthorizedSignatoriesId[index]);

		form.setFieldValue(field.name, value);
		if (asIndex > -1) {
			const fieldName = `${partiesType.partyGroupCompany.party}.authorizedSignatories[${asIndex}].email`;
			form.setFieldValue(fieldName, value);
		}

	};

	const handleOnChangeEmailPartyGroupCompany = (form, field, value, index) => {
		value = value.trim();
		const asIndex = shAuthorizedSignatoriesId.findIndex(asId => asId === gcAuthorizedSignatoriesId[index]);

		form.setFieldValue(field.name, value);
		if (asIndex > -1) {
			let fieldNameEmail;
			let fieldNameValue;

			if (_.get(partiesType, 'partyStakeholder.isIndividual', true)) {
				// forms with one stakeholder
				fieldNameEmail = `${partiesType.partyStakeholder.party}.email`;
				fieldNameValue = `values.${partiesType.partyStakeholder.party}.isAnEntity`;
				if (_.get(form, fieldNameValue)) {
					const fieldNameAuthorizedSignatoryEmail = `${partiesType.partyStakeholder.party}.authorizedSignatory.email`;
					form.setFieldValue(fieldNameAuthorizedSignatoryEmail, value);
				}

			} else {
				// forms with multiple stakeholders
				fieldNameEmail = `${partiesType.partyStakeholder.party}[${asIndex}].email`;
				fieldNameValue = `values.${partiesType.partyStakeholder.party}[${asIndex}].isAnEntity`;
				if (_.get(form, fieldNameValue)) {
					const fieldNameAuthorizedSignatoryEmail = `${partiesType.partyStakeholder.party}[${asIndex}].authorizedSignatory.email`;
					form.setFieldValue(fieldNameAuthorizedSignatoryEmail, value);
				}

			}
			form.setFieldValue(fieldNameEmail, value);
		}
	};

	const isSigned = (id) => {
		let isSigned = false;

		parties.some(party => {
			let signer;
			if (party.authorizedSignatory) {
				signer = party.authorizedSignatory.find(as => as.id === id)
			} else {
				if (party.id === id) {
					signer = party
				}
			}

			if (signer) {
				const recipientStatus = party.recipientStatus.find(el => el.email === signer.email);
				if (recipientStatus) {
					isSigned = !!recipientStatus.signed;
					return isSigned;
				}
			}
		});

		return isSigned;
	};

	return (
		<Formik
			initialValues={_.get(initialValues, 'params')}
			validationSchema={schema}
			onSubmit={handleSubmit}
		>
			{({
				  values,
				  errors,
				  dirty,
				  handleReset,
				  setFieldValue,
				  isValid,
			  }) => {
				const {partyStakeholder = null, partyGroupCompany = null} = partiesType;
				const {isIndividual: isIndividualStakeholder = true} = partyStakeholder ? partyStakeholder : {};
				const {isIndividual: isIndividualGroupCompany = true} = partyGroupCompany ? partyGroupCompany : {};
				const agrPartyStakeholder = partyStakeholder ? _.get(values, `${partyStakeholder.party}`) : null;
				const agrPartyGroupCompany = partyGroupCompany ? _.get(values, `${partyGroupCompany.party}`) : null;

				return (
					<React.Fragment>
						<ContainerModal>
							<div className={`${classes.containerForm}`}>
								<h1 className={classes.title}>{initialValues.type.label}</h1>
								<h5 className={classes.subTitle}>{initialValues.name}</h5>
								<Line/>
								{agrPartyStakeholder ?
									<div className={classes.ContainerView}>
										<div className={classes.ZoneTitle}>
											<h1 className={classes.title}>{`${partiesType.partyStakeholder.title}:`}</h1>
										</div>
										{
											isIndividualStakeholder ?
												<IndividualStakeholder
													fieldName={partyStakeholder.party}
													partyStakeholder={agrPartyStakeholder}
													handleOnChangeEmailPartyStakeholder={handleOnChangeEmailPartyStakeholder}
													shAuthorizedSignatoriesEmailDisabled={shAuthorizedSignatoriesEmailDisabled}/>
												:
												<MultipleStakeholders
													fieldName={partyStakeholder.party}
													shAuthorizedSignatoriesEmailDisabled={shAuthorizedSignatoriesEmailDisabled}
													handleOnChangeEmailPartyStakeholder={handleOnChangeEmailPartyStakeholder}/>
										}
									</div>
									: null
								}

								{agrPartyGroupCompany ? <div className={classes.ContainerView}>
										<div className={classes.ZoneTitle}>
											<h1 className={classes.title}>{`${partiesType.partyGroupCompany.title}:`}</h1>
										</div>
										<div className={classes.ContainerData}>
											<IndividualCompany
												fieldName={partyGroupCompany.party}
												gcAuthorizedSignatoriesEmailDisabled={gcAuthorizedSignatoriesEmailDisabled}
												handleOnChangeEmailPartyGroupCompany={handleOnChangeEmailPartyGroupCompany}
											/>
										</div>
									</div>
									: null
								}
							</div>
						</ContainerModal>
						<div className={classes.containerButtons}>
							<Botonera>

								<SecondaryButton onClick={() => handleCloseModal()}><Text
									uuid={'BUTTON_CANCEL'}/></SecondaryButton>
								{showSaveAndClose &&
								<SecondaryButton disabled={!_.isEmpty(Object.keys(errors)) || isSubmitting}
												 onClick={() => handleSaveAndClose(values)}>
									<Text uuid={'BUTTON_SAVE_AND_CLOSE'}/>
								</SecondaryButton>
								}
								<PrimaryButton disabled={!_.isEmpty(Object.keys(errors)) || isSubmitting}
											   loading={isSubmitting}
											   labelLoading={"Sending..."}
											   onClick={() => handleSubmit(values)}>
									<Text uuid={'REQUEST_SIGNATURES_DOCUMENT'}/>
								</PrimaryButton>

							</Botonera>
						</div>
					</React.Fragment>
				)
			}
			}

		</Formik>

	)
};

export default GetSignatureForm;

import React, { useState, useEffect } from 'react';

import classes from './Styles.module.scss';
import Form from '../../../../../../components/forms/Form/Form';

import TextInput from "../../../../../../components/inputs/Text/TextInput"
import ControllerInput from "../../../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput"
import * as Yup from 'yup';
import _ from 'lodash';

import { useSelector } from 'react-redux'
import useMutation from '../../../../../../hooks/custom/useMutation'

import SubmitButtonSubForm from '../../../../../../components/generic/Buttons/SubmitButtonSubForm/SubmitButtonSubForm';

const UPSERT_SHARE_CLASS = `
mutation upsertComponentShareClass(
    $startupId: ID!,
    $shareClass: ComponentShareClassInputType
  ) {
    upsertComponentShareClass(startupId: $startupId,shareClass:$shareClass) {
      id
      name
      owner{
      id
      shareClass{
      id
      name
      }
  }
  }
}
`




const FormAddGroupCompany = ({ startupId, handleCloseForm, handleAddValueList, groupCompanyId, ...props }) => {

    const handleOnCompletedMutation = ({ upsertComponentShareClass }) => {
        handleCloseForm()
        handleAddValueList(upsertComponentShareClass)
    }

    const [upsertCompany] = useMutation(UPSERT_SHARE_CLASS, { onCompleted: handleOnCompletedMutation });


    const handleSubmit = async ({ name }) => {
       await upsertCompany({
            variables: {
                startupId,
                shareClass: {
                    name,
                    groupCompanyId
                }
            }
        })

    }



    return (
        <Form initialValues={{ data: 'asdasdasd' }}
            schema={Yup.object().shape({
                name: Yup.string().required('This field is required'),

            })} >
            <div className={classes.containerForm}>
                <div className={classes.conteninerText}>

                    <ControllerInput
                        as={TextInput}
                        name={'name'}
                        classNameError={classes.errorForm}
                        placeholder={"Enter Share Class Name..."}

                    />


                    <SubmitButtonSubForm onClick={handleSubmit}  >
                        Save
                    </SubmitButtonSubForm>
                </div>
                {/*messageError && <span className={classes.error}>
                        {messageError}
                        </span>*/}



            </div>
        </Form>

    )

}


export default FormAddGroupCompany

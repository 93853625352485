const handleNationalitiesSaveValue = (nationalities, hasNationalities, oldNationalities = []) => {
    // First parameter is the value of the nationalities field
    // Second parameter is the value of the hasNationalities field
    // Third parameter is the value the user had before editing the form
    const nationalitiesValue = {
        none: true,
        countries: []
    };

    if (hasNationalities === undefined && nationalities === undefined) {
        return undefined;
    }

    if (nationalities) {
        nationalitiesValue.none = false;

        const updatedCountries = nationalities.map((countryCode) => {
            const initCountry = oldNationalities?.find((c) => c?.country?.code === countryCode);
            
            return {
                country: countryCode,
                ...(initCountry?.hasPassport !== null && { hasPassport: initCountry?.hasPassport })
            };
        });

        nationalitiesValue.countries = updatedCountries;
    }

    return nationalitiesValue;
};

const handleNationalitiesInitialValue = (noneValue) => {
    // The parameter is the current value of the none field
    let hasNationalitiesValue;

    if (noneValue === true) {
        hasNationalitiesValue = false;
    } else if (noneValue === false) {
        hasNationalitiesValue = true;
    } else {
        hasNationalitiesValue = null;
    }
    
    return hasNationalitiesValue;
};

export { handleNationalitiesSaveValue, handleNationalitiesInitialValue };
import React from 'react';
import ClaraLayout from "../../../../layouts/Clara/ClaraLayout"
import ACTIONS from "../../../../constants/actions"
import PAGES from "../../../../constants/pages";
//TODO: Actualizar componente
import ShareManagementRecentActivity from "./shareManagementRecentActivity/ShareManagementRecentActivity.jsx";

const moduleRouter = [
  {
    path: PAGES.shareProfileRecentActivty.path,
    exact: true,
    component: <ShareManagementRecentActivity/>,
    layout: ClaraLayout,
    propsLayout: {
      cantBack: true,
      startupMenu: true,
      userMenu: true,
      fullMenu: true,
      showAssistance: false,
      openAssistance: true,
      showBreadCrumbs: false,
      disableClosedAssistance: true,
    }
    
  },
  
];
export default moduleRouter;

import React from 'react';
import classes from './FormAdd.module.scss';
import Text from "../../../text/Text/Text";
import Line from "../../../generic/Line/Line";

const FormAdd = ({ title, subTitle, children ,className, showLine =false}) => {
    return (
        <React.Fragment>
            <div className={`${classes.FormAdd} ${className}`}>
                {title && (<h1><Text uuid={title} defaultKey={""}/></h1>)}
                {subTitle && (<h2><Text uuid={subTitle} defaultKey={""}/></h2>)}
                {((title && title.length >= 1 && subTitle && subTitle.length >= 1) || showLine)&& <Line />}
                <div className={classes.FormChildren}>
                    {children}
                </div>
            </div >
        </React.Fragment >
    )
}

export default FormAdd;
import Machine from "../../../generic/components/Machine/MachineClass";
import { StepAddressNotices, StepNotices } from './Steps';


const machineDefinition = {
    id: 'notices',
    initial: 'add_notices',
    states: {
        add_notices: {
            on: {
                NEXT: { target: 'add_address_notices', actions: 'setContext' },
                CANCEL: 'finish_notices',
            },

        },
        add_address_notices: {
            on: {
                PREVIOUS: { target: 'add_notices' },
                NEXT: { target: 'finish_notices', actions: 'setContext' },
                CANCEL: 'finish_notices',
            },

        },
        finish_notices: {
            type: 'final',
            entry: 'final',
        },
        onDone: {
            actions: 'stopMachine'
        }
    },
}
const steps = {
    add_notices: StepNotices,
    add_address_notices: StepAddressNotices
}

const options = {}



export default class MachineCompany extends Machine {
    constructor() {
        super(machineDefinition, options, steps);
    }
}

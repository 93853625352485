import React, {useEffect, useState} from 'react';
import {ServerConnect} from "../utils";
import _ from "lodash";
import Mustache from 'mustache';

const individualType = `{
  id
  curriculumVitaeDoc{
  id
  name
  }
  type
  isPrimary
  firstName
  isDirector
  isShareholder
  familyMemberOfAPEP
  familyMemberOfAPEPDescription
  closeAssociateOfAPEP
  closeAssociateOfAPEPDescription
  dateOfBirth
  UAEVisaDoc{
    file{
      id
      name
      size
      type
    }
  }
  email
  thirdPartyAddressFormDoc{
    file{
      id
      name
      size
      type
    }
  }
  thirdPartyAddressPassportDoc{
    file{
      id
      name
      size
      type
    }
  }
  lastName 
  thirdPartyAddressFormDoc{
    file{
      id
      name
      size
      type
    }
  }
  thirdPartyAddressPassportDoc{
    file{
      id
      name
      size
      type
    }
  }
  occupation
  address{
    address
    city
    country
    zipCode
    state
  }
  proofOfAddress
  proofOfAddressDoc{
    file{
      id
      name
      size
      type
    }
  }
  addressLetterAndSupportingDoc{
    file{
      id
      name
      size
      type
    }
  }
  contactNumber{
    prefix
    number
    extension
  }
  passports{
    id
    current
    nationality
    passportNumber
    passportExpiryDate
    passportDoc{
      file{
        id
        name
        size
      }
    }
    lastPagePassportDoc{
       file{
        id
        name
        size
      }
    }
    UAEPassportStamp
    UAEPassportStampDoc{
       file{
        id
        name
        size
      }
    }
    UIDDoc{
       file{
        id
        name
        size
      }
    }
    PRCIDDoc{
       file{
        id
        name
        size
      }
    }
    
    
  }
  otherPassports
  holdOrHaveHeldPassportFromAnotherCountry
  passportFromAnotherCountryDoc{
    file{
      id
      name
      size
      type
    }
  }
  politicallyExposedPerson
  politicallyExposedPersonDescription
  sourcesOfWealth
  otherSourcesOfWealth
  sourceOfFunds 
}`

const companyDataType = `{
  id
  companyName
  type
  otherTradingName
  signatory {
    firstName
    lastName
    jobTitle
    email
    authority
    whyHaveAuthority
    contactFullName
    signatoryPassportDoc{
      ...matterFile
    }
  }
  clientKeyContact{
    fullName
    contactNumber{
      prefix
      number
      extension
    }
    email
    contactType
    }
  financeContact{
    fullName
    contactNumber{
      prefix
      number
      extension
    }
    email
    contactType
    }
    sourceOfFunds
    representativeDetails{
      firstName
      lastName
      jobTitle
      email
      representativePassportDoc {
        ...matterFile
      }
    }
    showingShareholdersRegistrationDoc{
      ...matterFile
    }
    showingDirectorsRegistrationDoc{
      ...matterFile
    }
  registrationNumber
  isPrimary
  TRNNumber
  jurisdictionOfIncorporation
  VATRegistrationCertificateDoc{
    ...matterFile
  }
  address{
    city
    state
    country
    zipCode
    address
  }
samePrincipalPlaceOfBusinessAndLegal
principalAddress{
  city
  state
  country
  zipCode
  address
} 
certificateOfIncorporationOrCommercialLicenseDoc{
  ...matterFile
}
memorandumAndArticlesOfAssociationDoc{
  ...matterFile
}
countryOfIncorporation {code name}
proofOfPowerDoc{
  ...matterFile
}
}`

const companyType = `{
  id
  companyName
  type
  isDirector
  isShareholder
  otherTradingName
  PEPs${individualType}
  directors {
    individuals${individualType}
    companies${companyDataType}
    }
  shareholders {
    individuals${individualType}
    companies${companyDataType}
    }
  signatory {
    firstName
    lastName
    jobTitle
    email
    authority
    whyHaveAuthority
    contactFullName
    signatoryPassportDoc{
      file{
        id
        name
        size
        type
      }
    }
  }
  clientKeyContact{
    fullName
    contactNumber{
      prefix
      number
      extension
    }
    email
    contactType
    }
  financeContact{
    fullName
    contactNumber{
      prefix
      number
      extension
    }
    email
    contactType
    }
    sourceOfFunds
    representativeDetails{
      firstName
      lastName
      jobTitle
      email
      representativePassportDoc {
        ...matterFile
      }
    }
    showingShareholdersRegistrationDoc{
      ...matterFile
    }
    showingDirectorsRegistrationDoc{
      ...matterFile
    }
  registrationNumber
  isPrimary
  TRNNumber
  jurisdictionOfIncorporation
  VATRegistrationCertificateDoc{
    ...matterFile
  }
  address{
    city
    state
    country
    zipCode
    address
  }
samePrincipalPlaceOfBusinessAndLegal
principalAddress{
  city
  state
  country
  zipCode
  address
} 
certificateOfIncorporationOrCommercialLicenseDoc{
  ...matterFile
}
memorandumAndArticlesOfAssociationDoc{
  ...matterFile
}
countryOfIncorporation {code name}
proofOfPowerDoc{
  ...matterFile
}
}`
const QUERY_GETMATTER = `query{
  getMattersList(matterId:"{{matterId}}") {
    id
    KYC{
      id
      KYCType
      entitiesData{
        individuals${individualType}
        companies${companyDataType}
      }
      individuals${individualType}
    }
  }
}

fragment matterFile on FileType {
  file {
    id
    name
    size
  }
}`;

const getIndividuals = (kyc)=>{

  if(kyc.KYCType === "corporateEntity"){

    return _.pick(kyc.entitiesData,["companies","individuals"]) 
  }else{
    return _.pick(kyc,["individuals"]);
  }
}
  
  export default function useMatter(id, queryCustom = null, path = null) {
  const [matter, setMatter] = useState(null);
  useEffect(() => {
    if (!matter) {
      const query_params = {
        matterId: id,
      };
      const query_getMatters = queryCustom ? queryCustom : QUERY_GETMATTER;
      const query = Mustache.render(query_getMatters, query_params);
      ServerConnect.graphQlQuery(query).then(result => {
        let kyc = getIndividuals(_.get(result, `${path ? path : "getMattersList.0.KYC"}`, {}));
        setMatter(kyc)
      });
    }
  }, [matter]);

  return { matter };
}
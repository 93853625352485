import React, { useEffect } from 'react';
import * as Yup from "yup";
import Form from "../../../../components/forms/Form/Form";
import ControllerInput from "../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import SubmitButton from "../../../generic/components/Buttons/SubmitButton";
import FormProps from "../../../generic/forms/interfaces/FormProps";
import TemplateFormDefault from "../../../generic/templates/Modal";
import cls from "./Form.module.scss";
import _ from "lodash";
import useCustomMachine from "../../../generic/hooks/useCustomMachine";
import CheckboxButtonSimple from "../../../generic/components/Inputs/CheckboxButtonSimple";
import { CheckboxButtonOptions } from "../../../generic/components/Inputs/CheckboxButton";
import Constants from "../../Constants";
import { addAssistantText, clearAssistatText } from "../../../../modules/generic/store/action";
import { useDispatch } from "react-redux";
import useTranslate from "../../../generic/hooks/useTranslate";

type OnChange = (values: any) => void | Promise<void>;

const schema = Yup.object().shape({
  typeFormCreation: Yup.string().nullable().required("This field is required"),
});
interface FormSelectCreationDocumentProps extends FormProps {
  onChange?: OnChange;
}

const FormSelectCompany: React.FC<FormSelectCreationDocumentProps> = ({
  initialValues: defaultValues = {},
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  onChange,
  propsTemplate,
}) => {

  const { state } = useCustomMachine();
  const { type } = state.context.paramsData;
  const dispatch = useDispatch();
  const { translate } = useTranslate();

  const options: CheckboxButtonOptions[] = React.useMemo(() => {
    const opts = [
      { label: "No, generate one now", value: "GENERATE_DOCUMENT" },
      { label: "Yes, upload it now", value: "UPLOAD_DOCUMENT" },
      { label: "Upload it later", value: "CANCEL_CREATION" },
    ];
    if (!Constants.DOCUMENT_TYPE_GENERATE.includes(type) && type !== 'EMPLOYMENT_AGREEMENT') return _.tail(opts);
    return opts;
  }, []);

  const handeAddTooltips = () => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText(translate("MODAL_IP_ASSIGNMENT_AGREEMENT_TOOLTIP1")));
  }

  useEffect(() => {
    handeAddTooltips();
    return () => {
      dispatch(clearAssistatText());
    }
  }, []);

  return (
    <Form
      defaultValues={defaultValues}
      schema={schema}
      onSubmit={onCompleteSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <Template
        buttons={{
          ...buttons,
          submit: <SubmitButton {...buttonSubmitProps}>Next</SubmitButton>,
        }}
        props={propsTemplate}
      >
        <ControllerInput
          render={CheckboxButtonSimple}
          name="typeFormCreation"
          variant="col_1"
          options={options}
          onChange={onChange}
          className={cls.CheckboxButton}
          classNameButton={cls.buttonCheckBox}
        />
      </Template>
    </Form>
  );
};

export default FormSelectCompany;

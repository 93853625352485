const individualDataType = `{
  id
  curriculumVitaeDoc{
  file{
  id
  size
  name
  }
  }
  type
  isPrimary
  firstName
  isDirector
  isShareholder
  pep {
    isPEP
    familyMemberOfAPEP
    familyMemberOfAPEPDescription
    closeAssociateOfAPEP
    closeAssociateOfAPEPDescription
  }
  dateOfBirth
  UAEVisaDoc{
    file{
      id
      name
      size
      type
    }
  }
  email
  thirdPartyAddressFormDoc{
    file{
      id
      name
      size
      type
    }
  }
  thirdPartyAddressPassportDoc{
    file{
      id
      name
      size
      type
    }
  }
  lastName 
  thirdPartyAddressFormDoc{
    file{
      id
      name
      size
      type
    }
  }
  thirdPartyAddressPassportDoc{
    file{
      id
      name
      size
      type
    }
  }
  occupation
  address{
    address
    city
    country
    zipCode
    state
  }
  proofOfAddress
  proofOfAddressDoc{
    file{
      id
      name
      size
      type
    }
  }
  addressLetterAndSupportingDoc{
    file{
      id
      name
      size
      type
    }
  }
  contactNumber{
    prefix
    number
    extension
  }
  passports{
    current
    nationality
    passportNumber
    passportExpiryDate
    passportDoc{
      file{
        id
        name
        size
      }
    }
    lastPagePassportDoc{
       file{
        id
        name
        size
      }
    }
    UAEPassportStamp
    UAEPassportStampDoc{
       file{
        id
        name
        size
      }
    }
    UIDDoc{
       file{
        id
        name
        size
      }
    }
    PRCIDDoc{
       file{
        id
        name
        size
      }
    }
    
    
  }
  otherPassports
  holdOrHaveHeldPassportFromAnotherCountry
  passportFromAnotherCountryDoc{
    file{
      id
      name
      size
      type
    }
  }
  politicallyExposedPerson
  politicallyExposedPersonDescription
  sourcesOfWealth
  otherSourcesOfWealth
  sourceOfFunds 
}`

const companyDataType = `{
    id
    companyName
    type
    isDirector
    isShareholder
    otherTradingName
    signatory {
      firstName
      lastName
      jobTitle
      email
      nationality
      passportNumber
      passportExpiryDate
      signatoryPassportDoc{
        file{
          id
          name
          size
          type
        }
      }
    }
    clientKeyContact{
      fullName
      contactNumber{
        prefix
        number
        extension
      }
      email
      contactType
      }
    financeContact{
      fullName
      contactNumber{
        prefix
        number
        extension
      }
      email
      contactType
      }
      sourceOfFunds
      representativeDetails{
        firstName
        lastName
        jobTitle
        email
        representativePassportDoc {
          file{
            id
            name
            size
            type
          }
        }
      }
      showingShareholdersRegistrationDoc{
        file{
          id
          name
          size
          type
        }
      }
      showingDirectorsRegistrationDoc{
        file{
          id
          name
          size
          type
        }
      }
    registrationNumber
    isPrimary
    TRNNumber
    jurisdictionOfIncorporation
    VATRegistrationCertificateDoc{
      file{
        id
        name
        size
        type
        }
    }
    address{
      city
      state
      country
      zipCode
      address
    }
  samePrincipalPlaceOfBusinessAndLegal
  principalAddress{
    city
    state
    country
    zipCode
    address
  } 
  certificateOfIncorporationOrCommercialLicenseDoc{
    file{
      id
      name
      size
      type
    }
  }
  memorandumAndArticlesOfAssociationDoc{
    file{
      id
      name
      size
      type
    }
  }
  countryOfIncorporation {code name}
  proofOfPowerDoc{
    file{
      id
      name
      size
      type
    }
  }
  }`

const companyTypeCorporate = `{
    id
    companyName
    type
    isDirector
    isShareholder
    otherTradingName
    directors {
      individuals${individualDataType}
      companies${companyDataType}
      }
      signatory {
        firstName
        lastName
        jobTitle
        email
        nationality
        passportNumber
        passportExpiryDate
        signatoryPassportDoc{
          file{
            id
            name
            size
            type
          }
        }
      }
    clientKeyContact{
      fullName
      contactNumber{
        prefix
        number
        extension
      }
      email
      contactType
      }
    financeContact{
      fullName
      contactNumber{
        prefix
        number
        extension
      }
      email
      contactType
      }
      sourceOfFunds
      representativeDetails{
        firstName
        lastName
        jobTitle
        email
        representativePassportDoc {
          file{
            id
            name
            size
            type
          }
        }
      }
      showingShareholdersRegistrationDoc{
        file{
          id
          name
          size
          type
        }
      }
      showingDirectorsRegistrationDoc{
        file{
          id
          name
          size
          type
        }
      }
    registrationNumber
    isPrimary
    TRNNumber
    jurisdictionOfIncorporation
    VATRegistrationCertificateDoc{
      file{
        id
        name
        size
        type
        }
    }
    address{
      city
      state
      country
      zipCode
      address
    }
  samePrincipalPlaceOfBusinessAndLegal
  principalAddress{
    city
    state
    country
    zipCode
    address
  } 
  certificateOfIncorporationOrCommercialLicenseDoc{
    file{
      id
      name
      size
      type
    }
  }
  memorandumAndArticlesOfAssociationDoc{
    file{
      id
      name
      size
      type
    }
  }
  countryOfIncorporation {code name}
  proofOfPowerDoc{
    file{
      id
      name
      size
      type
    }
  }
  }`

export { individualDataType, companyDataType, companyTypeCorporate }
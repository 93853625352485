import useQuery from "src/hooks/custom/useQuery";
import get from "lodash/get";
const GET_STAKEHOLDER = `
query getStakeholderCorpNotices($startupId: ID!, $stakeholderId: ID!) {
    getStakeholder(startupId: $startupId, stakeholderId: $stakeholderId) {
        id
                recipientNotice{
            id
			fullName
			email
			phoneNumber
			address{
                street
                city
                state
                country{
                    code
                }
                zipCode
            }
			useRegisteredAddress
        }
        
    }}
`;
const useGetStakeholderNotices = (variables,props) =>{
    const { loading, error, data, refetch, ...propsDesc } = useQuery(GET_STAKEHOLDER, variables, null, props);
    return { loading, error, data: get(data, 'getStakeholder'), refetch, ...propsDesc };
}
export default useGetStakeholderNotices
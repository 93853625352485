const paths = {
    "payment": {
        path: "/auth/payment/:productCode",
    },
    "formationPayment": {
        path: "/auth/formation_payment/:productCode/:matterId?",
    },
    "formationPaymentADGM": {
        path: "/auth/formation_ADGM_payment/:productCode/:groupCompanyId",
    },
    "formationPaymentKY": {
        path: "/auth/FORMATION_CAYMAN_PAYMENT/:productCode/:groupCompanyId",
    },
    "formationPaymentDelaware": {
        path: "/auth/FORMATION_DELAWARE_PAYMENT/:productCode/:groupCompanyId",
    },
    "paymentSuccess": {
        path: "/auth/payment_success",
    },
    "paymentError": {
        path: "/auth/payment_error",
    }
}
export default paths;
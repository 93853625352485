import { default as React, useCallback, useMemo, useState } from 'react';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import * as Yup from 'yup';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import Typography from 'src/modules/generic/components/Texts/Typography';
import { Box, Stack } from '@mui/material';
import CheckboxButtonSimple from 'src/modules/generic/components/Inputs/CheckboxButtonSimple';
import cls from "./ADGMBankTransferStep.module.scss"
import { PaymentProvider } from 'src/modules/checkout/hooks/usePayment';
import CardOptionHtml from 'src/modules/Onboarding/components/CardOptionHtml';
import DataBankTransfer from 'src/modules/startup/PayFormationBundleModal/components/DataBankTransfer';

/**
* Description Step: Select type on bank transfer in ADGM
*/

function ADGMBankTransferStep() {

  const { next, context, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const [bankTransferType, setBankTransferType] = useState(1);
  const defineJurisdiction = 'ADGM';
  const bankInformation = context?.getBankInformation;
  const initialValues = useMemo(() => {
    return context?.ADGMBankTransferStep ?? {}
  }, [context]);

  const handlePrev = () => {
    prev();
  }
  
  const handleCancel = () => {
    cancel();
  }

  const handleSubmit = useCallback((values) => {
    next()
  }, [next]);

  const bankTransferTypes = [
    {
      value: 1,
      component: CardOptionHtml,
      componentProps: {
        title: "<strong>International wire details.</strong> Use these details if you are wiring from a non-US bank account",
      },
    },
    {
      value: 2,
      component: CardOptionHtml,
      componentProps: {
        title: "<strong>Domestic ACH / Wire details.</strong> Use these details if you are wiring from a US bank account",
      },
    }
  ]

  const schema = Yup.object().shape({
    bankTransferType: Yup.number().required('Required')
  });

  return (
    <PaymentProvider>
      <Form
        defaultValues={initialValues}
        schema={schema}
        onlySendDirty={false}
        onSubmit={handleSubmit}
        optionsForm={{ mode: 'onChange' }}
      >
        <TemplateWizard
          props={{
            title: null,
            skeleton: null,
            subTitle: null,
            variant: 'twoColumns'
          }}
          buttons={{
            previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
            cancel: <Button variant='secondary' onClick={handleCancel}>{translate('BUTTON_CANCEL')}</Button>,
            submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >Upload proof of payment</SubmitButton>,
          }}
        >
          <div>
            <Stack direction={"column"} gap={4}>
              <>
                <Box mt={5} mb={2}>
                  <Typography variant={'title'} color={'black'} >
                    <b>Pay by bank transfer</b>
                  </Typography>
                </Box>
                <Typography variant="h5" color={"black"} component={"p"} >
                  <span dangerouslySetInnerHTML={{__html: translate("MODULES.CHECKOUT.PAYMENT_ADGM_BANK_TRANSFER_SUBTITLE")}}></span>
                </Typography>
                <Box pb={2}>
                  <ControllerInput
                    render={CheckboxButtonSimple}
                    isMultiple={false}
                    defaultValue={bankTransferType}
                    name="bankTransferType"
                    direction="column"
                    options={bankTransferTypes}
                    classNameButton={cls.CheckboxButton}
                    onChange={(value: number) => {
                      setBankTransferType(value);
                    }}
                  />
                </Box>
              </>
            </Stack>
          </div>
          <div className={cls.PaymentTotalContent}>
            <DataBankTransfer bankTransferType={bankTransferType} defineJurisdiction={defineJurisdiction} bankInformation={bankInformation}></DataBankTransfer>
          </div>
        </TemplateWizard>
      </Form>
    </PaymentProvider>
  );
}

export default ADGMBankTransferStep;


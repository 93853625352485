import classnames from "classnames";
import classes from "./classes.module.scss";
import React, { FC } from "react";
import TypographyLocal from "../../../generic/components/Texts/Typography";
import useTranslate from "../../../../modules/generic/hooks/useTranslate";
import ButtonIcon from 'src/modules/generic/components/Buttons/ButtonIcon';

/**
 * Next Icon
 */

export interface NextIconProps {
  /** Property title */
  title: string,
  /** Property subTitle */
  subTitle: string,
  /** Property help */
  help: string,
  /** Property imageIcon */
  imageIcon: any,
  /** Property showHelp */
  showHelp: boolean
  /** Property removeSeparator */
  removeSeparator: boolean
  /** Property variant */
  variant?: 'white'
  cancelAction?: () => void

}

const NextIcon: FC<NextIconProps> = ({
  title,
  subTitle,
  help,
  showHelp,
  imageIcon,
  removeSeparator = false,
  variant,
  cancelAction,
  ...props
}: NextIconProps) => {

  const { translate } = useTranslate();

  return (
    <React.Fragment>
      <div className={classnames(`${classes.nextContainer}`, {
        [classes.addBorder]: !removeSeparator,
      })}>
        <div className={classnames(classes.textSpace, { [classes.textChat]: cancelAction })}>
          <TypographyLocal
            variant={"h2"}
            className={classnames(classes.titleFontStyle)}
          >
            {!!title ? (
              <span dangerouslySetInnerHTML={{ __html: title }}></span>
            ) :
              <span dangerouslySetInnerHTML={{ __html: translate("MODULES.ASSISTANCEV4.ASSISTANCE_MAP_NEXT_TITLE") }}></span>
            }
          </TypographyLocal>
          {!!subTitle && (
            <TypographyLocal
              color={'white'}
              className={classnames(classes.subtitleFontStyle)}
            >
              <span dangerouslySetInnerHTML={{ __html: subTitle }}></span>
            </TypographyLocal>
          )}
        </div>
        {imageIcon && (
          <div className={classnames(classes.iconSpace)}>
            <img className={classes.imageDetail} src={imageIcon} alt='Step' />
          </div>
        )}
        {cancelAction &&
          <div className={classes.close}>
            <ButtonIcon key={3} size={"18px"} icon="Delete" variant="white" onClick={cancelAction} />
          </div>
        }
      </div>
      {showHelp && !!help && (
        <div className={classnames(classes.hitSpace)}>
          <TypographyLocal
            color={'white'}
            className={classnames(classes.helpFontStyle)}
          >
            <span dangerouslySetInnerHTML={{ __html: help }}></span>
          </TypographyLocal>
        </div>
      )}
    </React.Fragment>
  );
};

export default NextIcon;
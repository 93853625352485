import React, { FC, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import event from 'src/v1/utils/event';
import { gql, useQuery } from "@apollo/client";
import _ from "lodash";
import useCustomMachine from "../../../../generic/hooks/useCustomMachine";
import Button from "../../../../generic/components/Buttons/Button";
import { StepProps } from "../../../../generic/components/Wizard/Step";
import TemplateWizard from "../../../../generic/templates/Modal/TemplateWizard";
import useTranslate from "../../../../generic/hooks/useTranslate";
import Form from "../../Forms/FormSelectCreationDocument";
import { goToNextPage } from "../../../../../store/actions/page";
import { Constants } from "../../../../../v1/utils";
import { addAssistantText, clearAssistatText } from "../../../../generic/store/action";
import useSession from "src/modules/session/hooks/useSession";
import useUpsertStartupOptions from "src/hooks/services/modules/startups/optionsSelected/useUpsertStartupOptions";

const GROUP_COMPANIES_QUERY = gql`
  query getGroupCompanies($startupId: ID!) {
    getGroupCompanies(startupId: $startupId) {
      id
      name
      country { code }
      isHoldingCompany
    }
  }
`;

const StepSelectCreationDocument: FC<StepProps> = () => {
  const { translate } = useTranslate();
  const { next, send, state } = useCustomMachine();
  const { type } = state.context.paramsData;
  const dispatch = useDispatch();
  const { startup } = useSession();
  const { data: companiesData } = useQuery(GROUP_COMPANIES_QUERY, { variables: { startupId: startup?.id } });
  const optionsSelectedFromState = startup?.optionsSelected;
  const [mutation] = useUpsertStartupOptions({ showSuccessNotification: false });

  const topCoCompanyCountry = (
    _.find(companiesData?.getGroupCompanies, { isHoldingCompany: true })?.country?.code ||
    companiesData?.getGroupCompanies[0]?.country?.code
  );

  useEffect(() => {
    if (topCoCompanyCountry === 'US') {
      const regionDocumentType = 'UNITED_STATES_DOCUMENTS_TYPES';
      if (optionsSelectedFromState?.regionFilter === regionDocumentType) return;
      const dispatchStartup = {
        optionsSelected: {
          ...optionsSelectedFromState,
          regionFilter: regionDocumentType
        }
      }
      dispatch({ type: "SESSION_UPDATE_STARTUP", startup: dispatchStartup });
      mutation({ id: startup?.id, startupData: { optionsSelected: { regionFilter: regionDocumentType } } });
    } else {
      const regionDocumentType = 'INTERNATIONAL_DOCUMENTS_TYPES';
      if (optionsSelectedFromState?.regionFilter === regionDocumentType) return;
      const dispatchStartup = {
        optionsSelected: {
          ...optionsSelectedFromState,
          regionFilter: regionDocumentType
        }
      }
      dispatch({ type: "SESSION_UPDATE_STARTUP", startup: dispatchStartup });
      mutation({ id: startup?.id, startupData: { optionsSelected: { regionFilter: regionDocumentType } } });
    }
  }, [startup?.id, topCoCompanyCountry]);

  useEffect(() => {
    dispatch(clearAssistatText());
    return () => {
      dispatch(clearAssistatText());
    };
  }, []);

  const documentType = useMemo(() => {
    if (type === 'IP_ASSIGNMENT') {
      return topCoCompanyCountry === 'US' ? 'USA_CONSULTANT_INFORMATION_AND_INVENTIONS_AGREEMENT' : type;
    } else {
      return type;
    }
  }, [topCoCompanyCountry, type]);

  const handleCompleteSubmit = async (values) => {
    if (values.typeFormCreation === "GENERATE_DOCUMENT") {
      event.emmit(Constants.EVENTS.DOCUMENT_SCROLL, { documentType });
      dispatch(
        goToNextPage(Constants.PAGES.chooseDocumentType, {
          previewDocument: documentType,
        })
      );
    }
    next(values);
  };

  const handleCancel = async () => {
    send("CANCEL");
  };

  const onChange = (value) => {
    if (value === "UPLOAD_DOCUMENT") {
      if (type === "IP_ASSIGNMENT") {
        dispatch(
          addAssistantText(
            translate(
              "MODULES.DOCUMENTS_STARTUP.IP_AND_GENERATE.FIRST_STEP.TOOLTIP_IP"
            )
          )
        );
      }
      dispatch(
        addAssistantText(
          translate(
            "MODULES.DOCUMENTS_STARTUP.IP_AND_GENERATE.FIRST_STEP.TOOLTIP_UPLOAD"
          )
        )
      );
    } else dispatch(clearAssistatText());
  };

  return (
    <>
      <Form
        onCompleteSubmit={handleCompleteSubmit}
        onChange={onChange}
        Template={TemplateWizard}
        propsTemplate={{
          title: translate(
            "MODULES.DOCUMENTS_STARTUP.IP_AND_GENERATE.FIRST_STEP.TITLE",
            {
              type: translate(
                `MODULES.DOCUMENTS_STARTUP.CATEGORIES_TITLES.${type}`
              ),
            }
          ),
          subTitle: translate(
            `MODULES.DOCUMENTS_STARTUP.CATEGORIES_TITLES.${type}`
          ),
        }}
        buttons={{
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              Cancel
            </Button>
          ),
        }}
      ></Form>
    </>
  );
};

export default StepSelectCreationDocument;
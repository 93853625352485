import React, { useEffect, useMemo, useState } from "react";

import Button from "src/modules/generic/components/Buttons/Button";
import { Constants } from 'src/v1/utils/constants';
import DetailsTabTitle from 'src/components/templates/DetailsTabTitle';
import Divider from '@mui/material/Divider';
import EditAddress from "src/components/modules/clara/startups/individualStakeholder/Modals/EditAddress/EditAddress";
import PincasIcon from "src/modules/generic/components/Icon/PincasIcon";
import { RoleAccess } from "src/v1/components";
import Stakeholder from "src/components/modules/clara/startups/entities/classes/Stakeholder";
import TwoColumnsViewer from 'src/modules/generic/components/TwoColumsViewer';
import _ from "lodash";
import classes from '../styles.module.scss'
import useModal from "src/modules/generic/hooks/useModal";
import useTranslate from "src/modules/generic/hooks/useTranslate";

export default function AddressesPanel({ 
  data,
  isLock,
  isManagedByClara,
  jurisdictions
 }) {
  const { translate } = useTranslate();
  const { openModal, closeModal } = useModal();
  const [itemsCol1, setItemsCol1] = useState([]);
  const [itemsCol2, setItemsCol2] = useState([]);

    const AddressesButtons =  (
      <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
        <Button
          variant='card'
          disabled={isLock}
          onClick={() => {
            openModal(EditAddress, { handleCloseModal: closeModal, isManagedByClara })
          }}
        >
          Edit
        </Button>
      </RoleAccess>
    );

  const stakeholderClass = useMemo(() => {
    if (!data) return null;
    return new Stakeholder(data);
  }, [data]);

  useEffect(() => {
    let newItemsCol1 = [];
    let newItemsCol2 = [];
    newItemsCol1.push({
      label: translate("STAKEHOLDER_VIEW_TAB_LABEL_REGISTERED_ADDRESS"),
      value: <>
        <div>{_.get(data, "address.street", "-")}</div>
        <div>{_.get(data, "address.city", "-")}</div>
        <div>{_.get(data, "address.state", "-")}</div>
        <div>{_.get(data, "address.zipCode", "-")}</div>
        <div>{_.get(data, "address.country.name", "-")}</div>
      </>,
      valuePrefix: stakeholderClass?.getFieldInMatter("address") && <PincasIcon />,
      disabledField: stakeholderClass?.getFieldInMatter("address")
    });

    if(_.includes(jurisdictions, 'ADGM')){
      newItemsCol2.push({
        label: translate("STAKEHOLDER_VIEW_TAB_LABEL_NOTICES_ADDRESSES"),
        value: <>
          <div>{_.get(data, "recipientNotice.street", '-')}</div>
          <div>{_.get(data, "recipientNotice.city")}</div>
          <div>{_.get(data, "recipientNotice.state")}</div>
          <div>{_.get(data, "recipientNotice.zipCode")}</div>
          <div>{_.get(data, "recipientNotice.country.name")}</div>
        </>,
        valuePrefix: stakeholderClass?.getFieldInMatter("recipientNotice") && <PincasIcon />,
        disabledField: stakeholderClass?.getFieldInMatter("recipientNotice")
      });
    }

    setItemsCol1(newItemsCol1);
    setItemsCol2(newItemsCol2);
  }, [data]);

  return (
    <>
      <Divider className={classes.divider}/>
      <DetailsTabTitle title={translate("STAKEHOLDER_VIEW_TAB_LABEL_ADDRESSES")} buttons={AddressesButtons} />
      <TwoColumnsViewer
        itemsCol1={itemsCol1}
        itemsCol2={itemsCol2}
      />
    </>
  );
}
import TagManager from 'react-gtm-module';
import _ from "lodash";
const hasPendingData = (entities) => {
    return !!(_.find(entities, entity => entity.remains > 0));
}
export default (result) => {
    const pendingData = hasPendingData(_.get(result, "startUp.pendingEntities", []));
    if (!pendingData) {
        const tagManagerArgsFinish = {
            dataLayer: {
                event: "PageView",
                path: "maps/?status=onboardingOfProfileCompleted",
                pageName: "Stakeholder Map - Onboarding Completed"
            },
            dataLayerName: "PageDataLayer",
        };
        TagManager.dataLayer(tagManagerArgsFinish);
    }
}
import { gql } from '@apollo/client';
import useMutation from '../../../../../hooks/custom/useMutation';
import event from "../../../../../v1/utils/event";
import { TasksRequiredFieldFragment } from "../../../../map/graphql/typePolicies";
import useSession from '../../../../session/hooks/useSession';
import { RoleFields } from "../../../../stakeholder/graphql/typePolicies";
import {Constants} from "../../../../../v1/utils";
import {invalidateFields, updateCompanyParties} from "../../../../generic/graphql/cache";

export const UPSERT_DOCUMENT = `
${TasksRequiredFieldFragment}
${RoleFields}
mutation UpsertDocumentMutation($startupId: String, $agreementId: String, $agreementData: AgreementInputType) {
    upsertDocument(startupId: $startupId, agreementId: $agreementId, agreement: $agreementData) {
      id
      name
      date
      subCategories
      type{
      code
      }
      groupCompanies {
      groupCompany {
        id
        name
        tasks { 
         ...TasksRequiredFieldFragment
         status
         }
         
      }
      signedBy{
         id
         }
    }
    stakeholders {
      id
      fullName
      tasks { 
        ...TasksRequiredFieldFragment 
        status
      }
       roles{
         ...RoleFields
         tasks{
         ...TasksRequiredFieldFragment
         status
         }
         }
         }
    }
  }`;

const useUpsertDocument = (options = {}) => {
  const { startupId } = useSession();
   

    const [mutation] = useMutation(UPSERT_DOCUMENT, {},{ update: updateCompanyParties,  ...options});

    return { mutation };
};

export default useUpsertDocument;

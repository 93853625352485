import React from "react";
import PropTypes from "prop-types";
import useSecurity from "../../hooks/useSecurity";

const RoleAccess = ({ children, action, params }) => {
	const { hasRoleAccess } = useSecurity()
	return (
		<>
			{hasRoleAccess(action, params) && children}
		</>
	)
};

RoleAccess.propTypes = {
	action: PropTypes.object.isRequired,
	params: PropTypes.object
};

export default RoleAccess;
import React from 'react';
import IndividualOrCompanyStep from "../../../core/wizards/steps/IndividualOrCompanyStep/IndividualOrCompanyStep";
import _ from "lodash";
import useCustomMachine from "../../../../../generic/hooks/useCustomMachine";

const IndividualOrCompanyStepAdvisor = ({ ...props }) => {

  const { state } = useCustomMachine();

  return (
    <>
      <IndividualOrCompanyStep isTherePrevious={false} role={'ADVISOR'} />
    </>
  );
}

export default IndividualOrCompanyStepAdvisor;

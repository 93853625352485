import LastStep from './steps/LastStep'
import FirstStep from './steps/FirstStep'
import EmployeeFormStep from "./steps/EmployeeFormStep";
import MachineEntity from "../../core/wizards/machine/MachineStakeholderEntity";


const middleSteps={
    employee_info: {
        on: {
            NEXT: { target: 'is_more_entity', actions: 'setContext' },
            CANCEL: 'finish_entity',
        }
    },
}
const stepsAdded={
    employee_info:EmployeeFormStep,
}

export default class MachineEmployee extends MachineEntity{
    constructor() {
        super(middleSteps, stepsAdded,'employee' ,'employee_info','employee_info','employee_info',FirstStep,LastStep, {},{});
    }
}


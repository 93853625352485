import React, { FC, useEffect, useState } from "react";
import HeathCheckTasks from "../../../../modules/assistanceV4/components/AssistanceAreas/HealthCheck/Tasks";
import AssistanceFrame2 from "./";
// import ModalUnderContruction from "../../../generic/modals/UnderContruction";
import _ from "lodash";
import useModal from "../../../generic/hooks/useModal";
import { ModalConvertibleDoc, ModalEmployeeDoc, ModalIpAndGenerate } from "../../../startup/Documents";
import useDinamicTasks from "../../hooks/useDinamicTasks";

/**
 * Assistance Frame for HeathCheck
 */

export interface AssistanceHealthCheckProps {

}

const AssistanceHealthCheck: FC<AssistanceHealthCheckProps> = ({
  ...props
}: AssistanceHealthCheckProps) => {

  const { openModal } = useModal();

  const configDocuments = [
    { code: "TASK_ADD_CERTIFICATE_INCORPORATION", onClick: (params) => openModal(ModalIpAndGenerate, { paramsMutation: { ...params, type: 'CERTIFICATE_OF_INCORPORATION' } }, "MODAL_UNDER_CONTRUCTION") },
    { code: "TASK_ADD_COMMERCIAL_LICENSE", onClick: (params) => openModal(ModalIpAndGenerate, { paramsMutation: { ...params, type: 'TRADE_LICENSE' } }, "MODAL_UNDER_CONTRUCTION") },
    { code: "TASK_ADD_SHARE_AGREEMENT", onClick: (params) => openModal(ModalIpAndGenerate, { paramsMutation: { ...params, type: 'REGISTER_OF_SHARES' } }) },
  ];

  const configStakeholders = [
    { code: "TASK_ADD_FOUNDERS_AGREEMENT", onClick: (params) => openModal(ModalIpAndGenerate, { paramsMutation: { ...params, type: 'FOUNDER_SERVICES_AGREEMENT' } }) },
    { code: "TASK_ADD_EMPLOYEE_AGREEMENT", onClick: (params) => openModal(ModalEmployeeDoc, { paramsMutation: { ...params } }) },
    { code: "TASK_ADD_CONSULTANT_AGREEMENT", onClick: (params) => openModal(ModalIpAndGenerate, { paramsMutation: { ...params, type: 'CONSULTANT_AGREEMENT' } }) },
    { code: "TASK_ADD_ADVISOR_AGREEMENT", onClick: (params) => openModal(ModalIpAndGenerate, { paramsMutation: { ...params, type: 'ADVISOR_AGREEMENT' } }) },
    { code: "TASK_ADD_IP_ASSIGNMENT_AGREEMENT", onClick: (params) => openModal(ModalIpAndGenerate, { paramsMutation: { ...params, type: 'IP_ASSIGNMENT' } }) },
    { code: "TASK_ADD_WARRANT_AGREEMENT", onClick: (params) => openModal(ModalIpAndGenerate, { paramsMutation: { ...params, type: 'WARRANT_AGREEMENT' } }) },
    { code: "TASK_ADD_SHARE_AGREEMENT", onClick: (params) => openModal(ModalIpAndGenerate, { paramsMutation: { ...params, type: 'REGISTER_OF_SHARES' } }) },
    { code: "TASK_ADD_CONVERTIBLE_AGREEMENT", onClick: (params) => openModal(ModalConvertibleDoc, { paramsMutation: { ...params } }) },
    { code: "TASK_ADD_SHARE_OPTION_AGREEMENT", onClick: (params) => openModal(ModalIpAndGenerate, { paramsMutation: { ...params, type: 'GRANT_AGREEMENT' } }) },
    { code: "TASK_ADD_WARRANT_AGREEMENT_FOR_IN_PROGRESS" },
    { code: "TASK_ADD_CONVERTIBLE_AGREEMENT_FOR_IN_PROGRESS" },
    { code: "TASK_ADD_SHARE_OPTION_AGREEMENT_FOR_IN_PROGRESS" },
  ]
  const getParams = (task, entities) => {
    const {company}=entities
    switch (task.code) {
      case "TASK_ADD_COMMERCIAL_LICENSE":
        return {
          groupCompanyId: _.get(entities, "company.id", _.get(_.find(task.additionalParams, (params) => params.entityType == "GroupCompany"), "entityId", _.get(entities, "info.groupCompany.id", null))),
        }
      case "TASK_ADD_WARRANT_AGREEMENT":
      case "TASK_ADD_CONVERTIBLE_AGREEMENT":
      case "TASK_ADD_SHARE_AGREEMENT":
      case "TASK_ADD_SHARE_OPTION_AGREEMENT":
        return {
          owner:company?{type:'groupCompany',id:_.get(entities,'info.owner.id')}:{type:'stakeholder',id:_.get(entities,'info.owner.id')},
          amount: _.get(entities, "info.amount"),
          currency: _.get(entities, "info.currency"),
          equityId: _.get(entities, "info.id"),
          issuedDate: _.get(entities, "info.issuedDate"),
          groupCompany: _.get(entities, "info.groupCompany"),
          groupCompanyId: _.get(entities, "company.id", _.get(_.find(task.additionalParams, (params) => params.entityType === "GroupCompany"), "entityId", _.get(entities, "info.groupCompany.id", null))),
          stakeholderId: _.get(entities, "stakeholder.id", _.get(_.find(task.additionalParams, (params) => params.entityType === "Stakeholder"), "entityId", null)),
        }
      default:
        return {
          groupCompanyId: _.get(entities, "company.id", _.get(_.find(task.additionalParams, (params) => params.entityType === "GroupCompany"), "entityId", _.get(entities, "info.groupCompany.id", null))),
          groupCompany: _.get(entities, "company", _.get(entities, "info.groupCompany", null)),
          stakeholderId: _.get(entities, "stakeholder.id", _.get(_.find(task.additionalParams, (params) => params.entityType === "Stakeholder"), "entityId", null)),
        }
    }
  }
  const { dataTasks } = useDinamicTasks(configDocuments, configStakeholders, getParams);

  const [taskStatus, setTaskStatus] = useState("completed");

  useEffect(() => {
    if (!_.isEmpty(dataTasks)) {
      let groupWithPending = _.find(dataTasks, (group) => {
        return _.find(group.tasks, (task) => task.status !== "completed");
      });
      if (!(!!groupWithPending)) {
        setTaskStatus("completed");
      } else {
        setTaskStatus("incompleted");
      }
    }
  }, [JSON.stringify(dataTasks)]);

  
  return (
    <AssistanceFrame2
      tasksComponent={HeathCheckTasks }
      tasks={dataTasks}
      showTask={true}
      
    ></AssistanceFrame2>
  );
};

export default AssistanceHealthCheck;

export default {
  GENERIC_TOOLTIP: "A shareholder can be an individual, or a corporate entity such as a company. The KYC requirements are different depending on whether an individual shareholder or a corporate shareholder is being added.",
  INDIVIDUAL_OR_COMPANY_TITLE: "Add their basic info",
  CORP_SELECTED_TOOLTIP: "Please be aware that adding a company as a shareholder might cause potential delays with the incorporation due to increased compliance requirements.<br></br>If speed of incorporation is a priority, you might consider incorporating with individuals as the shareholders and adding in the corporate shareholders following incorporation. Additional fees do apply.",
  INDIVIDUAL_NAME_FIELD: "Individual shareholder name",
  INDIVIDUAL_NAME_PLACEHOLDER: "As it appears in their passport",
  INDIVIDUAL_OR_COMPANY_COMPANY_FIELD: "Company",
  COMPANY_NAME_FIELD: "Corporate shareholder name",
  COMPANY_NAME_PLACEHOLDER: "As shown on the Certificate of Incorporation",
  DIRECTOR_ADDED_TITLE: "Do you have more shareholders to add?",
  DIRECTOR_ADDED_SUBTITLE: "Shareholder added!",
  ADD_SHAREHOLDER_TITLE: "Are they an individual or a corporate stakeholder?",
  ADD_SHAREHOLDER_SUBTITLE: "Add a shareholder",
  SHAREHOLDER_LEGAL_ENTITY_STRUCTURE_TITLE: "What is the legal entity structure of this corporate stakeholder?",
  CORPORATE_SUBTITLE: "Add a corporate shareholder",
  LEGAL_ENTITY_STRUCTURE_TOOLTIP: "Shares held by a Corporate Stakeholder can be held in the name of a Company, a Partnership or a Trust. The KYC information and documents required will differ depending on the type of Corporate Stakeholder being added. <br/><br/>" +
    "For a <strong>Company</strong>, we need the registration documents, statutory registers and information about the shareholders and directors. <br/><br/>" +
    "For a <strong>Partnership</strong>, we need the registration documents, Register of Partners and information regarding the General Partner, including its directors and shareholders. <br/><br/>" +
    "For a <strong>Trust</strong>, we need a recently certified copy of the Trust Deed and information about the Trustee, Settlor and Beneficiaries.<br/><br/>" +
    "The above is indicative and the full list will be requested once you confirm the type of Corporate Shareholder you are adding.",
  FIELD_REQUIRED: "This field is required",
  INDIVIDUAL_SHAREHOLDER_STEP_SUBTITLE: "Add individual shareholder",
}

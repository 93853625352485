import React, { FC, useEffect, useRef } from "react";
import CountrySelector from "../../../../components/inputs/Select/CountrySelector/CountrySelector";
import TemplateLabel from "../../../../modules/generic/components/Inputs/LabelInput";
import JuridictionSelector from "../../../../components/inputs/Select/JuridictionSelector/JuridictionSelector";
import ControllerInput from "../Inputs/ReactHookForm/ControllerInput";
import { useFormContext } from "react-hook-form";
import { HiddenFieldForm } from "../../../../components/forms/contents";
import TextInput from "../../../../components/inputs/Text/TextInput";
import _ from "lodash";
import useTranslate from "../../hooks/useTranslate";
import { Collapse } from "@material-ui/core";
import useGetCountryAndJurisdiction from "./useGetCountryAndJurisdiction";
import { CountryType } from "@interfaces/country";

interface CountryJurisdictionProps {
  name?: string;
  disabled?: boolean;
  countryLabel?: string;
  jurisdictionLabel?: string;
  showJurisdiction?: boolean;
  unmounClearJurisdiction?: boolean;
  onChange?: (value: any) => void;
  hideLoading?: boolean;
}

const CountryJurisdiction: FC<CountryJurisdictionProps> = ({
  name = "country",
  disabled = false,
  countryLabel,
  jurisdictionLabel,
  showJurisdiction = true,
  unmounClearJurisdiction,
  onChange,
  hideLoading = false,
  ...props
}) => {

  const { translate } = useTranslate();
  const { watch, setValue } = useFormContext();
  const refJurisdiction = useRef(null);

  useEffect(() => {
    const country: CountryType = watch(name);
    if (_.has(country, "code")) {
      setValue(name, country.code);
    }
  }, []);

  const {
    countriesList,
    jurisdictionsList,
    loading,
  } = useGetCountryAndJurisdiction();

  const setOtherJurisdiction = (value) => {
    setTimeout(() => {
      setValue("otherJurisdiction", value);
    }, 100);
  };

  const handleOnchange = (value) => {
    if (onChange) {
      onChange(value);
    }
  }

  return (
    <>
      {" "}
      {loading ? (
        <>
          {!hideLoading && (
            <label>loading</label>
          )}
        </>
      ) : (
        <>
          <TemplateLabel
            label={countryLabel ? countryLabel : translate("FORM_COMAPANY_LABEL_COUNTRY")}
            isRequired={true}
          >
            <ControllerInput
              render={CountrySelector}
              name={name}
              defaultValue={""}
              placeholder={"Select country"}
              defaultList={countriesList}
              disabled={disabled}
              clear={true}
              onChange={(value) => {
                if (_.isNull(value)) {
                  setValue("jurisdiction", null);
                }
              }}
            />
          </TemplateLabel>
          <HiddenFieldForm
            fieldsWatch={name}
            conditionHideCallback={(value) => {
              return value && value !== "" && showJurisdiction;
            }}
          >
            <Collapse collapsedSize={"auto"} in={!!watch(name)}>
              <TemplateLabel
                label={jurisdictionLabel ? jurisdictionLabel : translate("FORM_COMAPANY_LABEL_JURISDICTION")}
                isRequired={true}
              >
                {
                // FIX: 9577 CHECK WHY HIDDIEN FIELD FORM IS NOT WORKING
              }

                { watch(name) && 
                <ControllerInput
                  render={JuridictionSelector}
                  defaultList={jurisdictionsList}
                  name="jurisdiction"
                  defaultValue={""}
                  placeholder={"Select jurisdiction"}
                  countrySelected={watch(name)}
                  clear
                  handleOtherJurisdiction={setOtherJurisdiction}
                  disabled={disabled}
                  innerRef={refJurisdiction}
                  onChange={handleOnchange}
                  unmoutClean={unmounClearJurisdiction}
                />
                }
              </TemplateLabel>
            </Collapse>
          </HiddenFieldForm>
          <HiddenFieldForm
            fieldsWatch={"jurisdiction"}
            conditionHideCallback={(value) => {
              return value === "OTHER";
            }}
          >
            <TemplateLabel
              label={translate("FORM_COMAPANY_LABEL_JURISDICTION_OTHER")}
              isRequired={true}
            >
              <ControllerInput
                render={TextInput}
                name="otherJurisdiction"
                placeholder={"Type here"}
              />
            </TemplateLabel>
          </HiddenFieldForm>
        </>
      )}
    </>
  );
};

export default CountryJurisdiction;

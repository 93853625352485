import React from "react";
import { useParams } from "react-router-dom";
import useSession from "../../../../../../../modules/session/hooks/useSession";
import NoticesForm from "../../../../../../forms/modules/startups/individualStakeholder/NoticesForm";
import useGetStakeholderNotices from "../../graphql/queries/useGetStakeholderNotices";
import ModalAssistant from "../../../../../../../modules/generic/components/Modal/ModalAssistant";
import TemplateWizard from "../../../../../../../modules/generic/templates/Modal/TemplateWizard";
import useTranslate from "../../../../../../../modules/generic/hooks/useTranslate";
import { LoadingProvider } from "../../../../../../../modules/generic/hooks/useLoading";
import SkeletonInput from "../../../../../../../modules/generic/components/Skeletons/Overlays/SkeletonInput";
import useUpdateStakeholder from "src/modules/startup/onBoarding/core/graphql/mutations/useUpdateStakeholder";

const EditNotices = ({
  showModal,
  open,
  handleCloseModal,
  initialValues = {},
  refresh,
}) => {

  const { translate } = useTranslate();
  const { startupId } = useSession();
  const params = useParams();

  const { data } = useGetStakeholderNotices({
    startupId,
    stakeholderId: params.id,
  });

  const handleCloseForm = () => {
    refresh && refresh();
    handleCloseModal && handleCloseModal();
  };

  const {updateStakeholder: upsertStakeholder} = useUpdateStakeholder({
    onCompleted: handleCloseForm,
  });

  const handleSubmit = async (values) => {
    await upsertStakeholder({
      variables: { 
        startupId,
        stakeholderId: params.id,
        stakeholderData: {
          stakeholder: values
        }
      },
    });
  };

  return (
    <>
      <ModalAssistant open={showModal || open}>
        <LoadingProvider>
          <NoticesForm
            initialValues={data}
            handleCloseModal={handleCloseForm}
            handleSubmit={handleSubmit}
            Template={TemplateWizard}
            propsTemplate={{
              title: translate(`FORM_NOTICES_TITLE_LABEL`),
              skeleton: (
                <>
                  <SkeletonInput
                    count={2}
                  ></SkeletonInput>
                </>
              ),
            }}
          />
        </LoadingProvider>
      </ModalAssistant>
    </>
  );
};

export default EditNotices;

import React from "react";
import _ from "lodash";
import {Constants} from "src/v1/utils";
import {RoleAccess} from "src/v1/components";
import TwoColumnsViewer from "src/modules/generic/components/TwoColumsViewer";
import DetailsTabTitle from "src/components/templates/DetailsTabTitle";
import Button from "src/modules/generic/components/Buttons/Button";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import useModal from "src/modules/generic/hooks/useModal";
import EditPEP from "src/modules/startup/Stakeholder/ADGM/EditPEP";

const PEPPanel = ({ data, lock }) => {
  const { translate } = useTranslate();
  const { openModal } = useModal();

  const PEPButton =
    <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
      <Button variant="card" disabled={lock} onClick={() => { openModal(EditPEP, {}, "EDIT_PEP") }} >
        {translate("VIEW_COMPANY_TAB_BUTTON_DETAILS_EDIT")}
      </Button>
    </RoleAccess>;

  return(
    <>
      <DetailsTabTitle title="PEP" buttons={PEPButton} />
      <TwoColumnsViewer
        itemsCol1={[
          {
            label: translate("TAB_PEP_LABEL_IS_PEP"),
            value: _.get(data, "pep.isPEP", false) ? translate("TAB_PEP_LABEL_AFIRMATIVE", { description: _.get(data, 'pep.descriptionOfPEP') }) : translate("TAB_PEP_LABEL_NEGATIVE"),
          },
          {
            label: translate("TAB_PEP_LABEL_IS_FAMILY_PEP"),
            value: _.get(data, "pep.isFamilyMemberOfAPEP", false) ? translate("TAB_PEP_LABEL_AFIRMATIVE", { description: _.get(data, 'pep.familyMemberOfAPEPDescription') }) : translate("TAB_PEP_LABEL_NEGATIVE"),
          },
          {
            label: translate("TAB_PEP_LABEL_ASSOCIATE_PEP"),
            value: _.get(data, "pep.isCloseAssociateOfAPEP", false) ? translate("TAB_PEP_LABEL_AFIRMATIVE", { description: _.get(data, 'pep.closeAssociateOfAPEP') }) : translate("TAB_PEP_LABEL_NEGATIVE"),
          }
        ]}
      />
    </>
  )
};

export default PEPPanel;
import classnames from "classnames";
import classes from "./classes.module.scss";
import TypographyLocal from "../../Texts/Typography";
import Icon from "../../../../generic/components/Icon";
import React, { FC } from "react";
import IcoMoon from "react-icomoon";
const $CLARA_ICON_SET = require("../../Icon/clara/selection.json");

/**
 * Check List content
 */

export enum variantTypes {
  pending="pending",
  completed = "completed",
  incompleted = "incompleted",
  inprogress = "inprogress",
  addTask = "addTask",
  ready = "ready",
  checked = "checked",
  forming = "forming",
  formingAccelerator = "formingAccelerator",
};

 export interface CheckListProps {
  /** Property variant */
  variant: variantTypes,
  /** Property title */
  title: string,
  /** Property textColor */
  textColor: string,
  /** Property onClick */
  onClick: (event: React.MouseEvent<HTMLInputElement>) => null
  /** Property deleteClick */
  deleteClick: (event: React.MouseEvent<HTMLInputElement>) => null
  noTopMargin: boolean,
} 

const CheckList: FC<CheckListProps> = ({
  variant = variantTypes.incompleted,
  title,
  textColor = "clara-black",
  onClick = null,
  deleteClick = null,
  noTopMargin = false,
  ...props
}:CheckListProps) => {

  const calculateIcon = () => {
    let useIcon = "complete-and-incomplete";   
    
    if (variant === variantTypes.pending) useIcon = "Task"
    if (variant === variantTypes.incompleted) useIcon = "complete-and-incomplete"
    if (variant === variantTypes.completed) useIcon = "complete-and-incomplete"
    if (variant === variantTypes.inprogress) useIcon = "pending"
    if (variant === variantTypes.addTask) useIcon = "Task"
    return useIcon;
  }

  const calculateSpecialIcon = () => {
    let useIcon = (
      <Icon icon={calculateIcon()} size={"100%"} isClara={true}></Icon>
    );
    if (variant === variantTypes.checked) useIcon = (<IcoMoon iconSet={$CLARA_ICON_SET} icon={"Tick-coloured"} size={"100%"}/>)
    if (variant === variantTypes.forming) useIcon = (<IcoMoon iconSet={$CLARA_ICON_SET} icon={"In-progress"} size={"100%"}/>)
    if (variant === variantTypes.formingAccelerator) useIcon = (<IcoMoon iconSet={$CLARA_ICON_SET} icon={"In-progress"} size={"100%"}/>)
    return useIcon;
  }

  const calculateColor = () => {
    let useColor = "black";
    if (variant === variantTypes.pending) useColor = "blue"
    if (variant === variantTypes.incompleted) useColor = "grey"
    if (variant === variantTypes.addTask) useColor = "secondary"
    if (variant === variantTypes.checked) useColor = "grey"
    if (variant === variantTypes.forming) useColor = "black"
    if (variant === variantTypes.formingAccelerator) useColor = "grey"
    return useColor;
  }

  return (
    <React.Fragment>
      <div
        className={classnames(`${classes.checkContainer}`, {
          [classes.iconGrey]: variant === variantTypes.incompleted || variant === variantTypes.ready,
          [classes.withDelete]: deleteClick !== null,
          [classes.noTopMargin]: noTopMargin,
        })}
      >
        <div className={classnames(classes.iconAling)}>
          {calculateSpecialIcon()}
        </div>
        <div className={classnames(classes.titleContents)}>
          {onClick == null ? (
            <TypographyLocal
              color={calculateColor()}
              className={classnames(`${classes.checkText}`, {
                [classes.changeFont]: variant === variantTypes.forming,
              })}
            >
              {title}
            </TypographyLocal>
          ) : (
            <div onClick={onClick}>
              <TypographyLocal
                color={calculateColor()}
                className={classnames(`${classes.checkText}`, {
                  [classes.cursorHand]: variant !== variantTypes.completed,
                })}
              >
                {title}
              </TypographyLocal>
            </div>
          )}
        </div>
        {deleteClick !== null && (
          <div className={classnames(classes.iconAlingAction)} onClick={deleteClick}>
            <Icon icon={"Delete-bin"} size={"100%"} isClara={true}></Icon>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default CheckList;

import includes from "lodash/includes"
import React,{ FC } from "react"
import { PASSPORT_DOCUMENT_TYPES, VISA_STAMPS_RESIDENCE_ID_DOCUMENTS_TYPES,FORMATION_DOCUMENTS_EXC_DOCUMENT_TYPES,FORMATION_DOCUMENTS_EXP_DOCUMENT_TYPES } from "../constants"
import PassportFields from "./PassportFields"
import IdentityDocumentsFields from "./IdentityDocumentsFields"
import FormationDocumentFieldsExecutedOnDate from "./FormationDocumentFieldsExecutedOnDate"
import FormationDocumentFieldsExpiryDate from "./FormationDocumentFieldsExpiryDate"
import DocumentTypeFieldDefault from "./DocumentTypeFieldsDefault"
interface FieldsByDocumentTypeProps {
    documentType: string;
    getAddsPartyList: () => any;
    individualStakeholders: any;
    parties: any;
    tooltips: any;
    handleChange: any;
    addParty: any;
}
const FieldsByDocumentType:FC<FieldsByDocumentTypeProps> = ({ documentType,...props }) => {    
    console.log(documentType,'documentType')
    const getFieldsByDocumentType = () => {
        if(includes(PASSPORT_DOCUMENT_TYPES,documentType)) return <PassportFields {...props} />
        if(includes(VISA_STAMPS_RESIDENCE_ID_DOCUMENTS_TYPES,documentType)) return <IdentityDocumentsFields {...props} />
        if(includes(FORMATION_DOCUMENTS_EXC_DOCUMENT_TYPES,documentType)) return <FormationDocumentFieldsExecutedOnDate {...props} />
        if(includes(FORMATION_DOCUMENTS_EXP_DOCUMENT_TYPES,documentType)) return <FormationDocumentFieldsExpiryDate {...props} />
        return <DocumentTypeFieldDefault {...props} />
    }
    return getFieldsByDocumentType()
}
export default FieldsByDocumentType;
import React, {Component} from "react";
import FormikHelper from "../../../utils/formikHelper";
import TextInput from './TextInput';
import moment from "moment"

class TextInputDate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            date: undefined,
        };
    }

    handleDateChange(e, field, form) {
        const date = e === null ? null : moment(e).format("YYYY-MM-DD");

        FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

        this.setState({
            date: e
        })
    }

    getDateForPickerFromData(date) {
        if (date === undefined) return null;
        return moment(date).toDate();
    }

    render() {
        const {field, form, className, showRedStar, placeholder = 'Date', label, required, tooltip} = this.props;
        return (
            <React.Fragment>
                <TextInput
                    required={required}
                    showRedStar={showRedStar}
                    type='date'
                    field={field}
                    form={form}
                    label={label}
                    placeholder={placeholder}
                    className={className}
                    tooltip={tooltip}
					trim={false}
                    {...this.props.TextInput}
                    prevComponentChange = {e =>
                        this.handleDateChange(e, field, form)
                    }
                >
                </TextInput>
            </React.Fragment>
        )
    }
}

export default TextInputDate;

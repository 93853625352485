import React, { Component } from "react";
import { helper } from "../../utils";
import SimpleSnackbar from "../SnackBar/SnackBar";

/* This component manage to show notifications on a snackbar

FUNCTIONS:
-addNewNotification
    params: params come from sendNotification(this method explanation above) on utils/notification
    returns: listen to changes on globalNotification value


-handleSnackClose
    params: -
    returns: just close the snackbar via setState


-sendNotification
    params: {
        message: message to shown on snackbar
        type: error/success the style of snackbar and position depends on this type
        duration: how long should show the snackbar
    }
*/

class ManagerNotification extends Component {

  state = {
    notification: null,
    openSnackbar: true,
  };

  addNewNotification = (val) => {
    this.setState({
      notification: val,
      openSnackbar: true,
    });
  };

  handleSnackClose = () => {
    this.setState({ openSnackbar: false, notification: null });
  };

  componentWillMount() {
    window.globalNotification = helper.generateNewGlobalValue(null);
    window.globalNotification.registerListener(this.addNewNotification);
  }

  render() {
    const { openSnackbar, notification } = this.state;
    let horizontal = "center";
    let vertical = "bottom";
    let showClose = false;
    if (notification) {
      switch (notification.type) {
        case "error":
          horizontal = "left";
          showClose = true;
      }
      return (
        <React.Fragment>
          <SimpleSnackbar
            open={openSnackbar}
            message={notification.message}
            duration={notification.duration}
            handleSnackClose={this.handleSnackClose}
            vertical={vertical}
            horizontal={horizontal}
            showClose={showClose}
            type={notification.type}
          />
        </React.Fragment>
      );
    }
    return false;
  }
}

export default ManagerNotification;

export default {
  STEP_1: {
    TITLE:'Edit subscription terms',
    SUBTITLE:'Your subscription',
    TOOLTIP1:'Here are your <a href="https://clara.co/terms-of-use/" target="_blank">Terms & Conditions</a> and your <a href="https://www.clara.co/privacy-policy/" target="_blank">Privacy policy.</a>',
    TOOLTIP2:'Auto-renewal means your subscription will automatically extend for another year on the renewal date.',
    TOOLTIP3:'Changes to the billing cycle will be effective starting from your next renewal date.',
    TOOLTIP4:'If you choose to pay monthly, a monthly payment of {{money}} {{amount}} will be charged to your card starting from {{date}}.',
    TOOLTIP5:'If you choose to pre-pay, a yearly payment of {{money}} {{amount}} will be charged to your card starting from {{date}}.',
    TOOLTIP6:'If you switch off auto-renewal, your account will be downgraded to Clara’s Start plan on {{date}}. And you will lose access to the Scale benefits.',
    TOOLTIP7:'Switch on auto-renewal allows you to continue enjoying the benefits of Scale. And a yearly payment of {{money}} {{amount}} will be charged to your card starting from {{date}}.',
    TOOLTIP8:'Switch on auto-renewal allows you to continue enjoying the benefits of Scale. And a monthly payment of {{money}} {{amount}} will be charged to your card starting from {{date}}.',
    HELP:'Please note: Any changes will be effective starting from your next renewal date.',
    TITLE_RENEWAL:'Auto-renewal',
    TITLE_BILLING:'Your next billing cycle',
    STEP_ON: "On",
    STEP_OFF: "Off",
    RENEWAL_ON: "Your subscription will renew on {{date}}.",
    RENEWAL_OFF: "Your subscription will end on {{date}}.",
    BILLING_MONTHLY: "Pay monthly",
    BILLING_DATA: "{{money}} {{value}} {{vat}} {{save}}",
    BILLING_ANNUAL: "Pre-pay annually",
    ANUAL_SAVE: " (save {{percent}})",
  },
  STEP_2: {
    SUBTITLE:'Confirm updates to your subscription',
    RENEWAL_ON: 'You have chosen to switch on auto-renewal.',
    BILLING_ANUAL: 'The next subscription term will start on {{date}}, and an annual payment of {{currency}} {{amount}} will be charged to your card ending in {{last}}.',
    BILLING_MONTH: 'The next subscription term will start on {{date}}, and a monthly payment of {{currency}} {{amount}} will be charged to your card ending in {{last}}.',
    BILLING_MONTH_END: 'We will continue to take payment monthly until your subscription ends.',
    RENEWAL_OFF: "You have chosen to switch off auto-renewal, which means you will be downgraded to Clara's Start plan when your current subscription ends on {{expiry}}.",
    BEFORE_ENDS: "Before your subscription ends, you are still a Scale subscriber with full access to all Clara's features. After that, you'll lose access to the paid features you regularly use like:",
    TOOLTIP1:"Don't forget you can always switch it back on before the end of the current subscription.",
    BEFORE_ENDS_MONTHLY: "Before your subscription ends, you are still a Scale subscriber with access to all Clara's features, and we will continue to charge your card monthly until the current commitment period ends.",
    REGULAR_LIKE: "After that, you'll lose access to the paid features you regularly use like:",
    PAY_ANUAL: "You have chosen to pay annually for your next subscription term.",
    PAY_MONTH: "You have chosen to pay monthly for your next subscription term.",
    PAY_ANUAL_INFO: "By making this change, an annual payment of {{currency}} {{amount}} will be charged to your card ending in {{last}} on {{date}}.",
    PAY_MONTH_INFO: "By making this change, a monthly payment of {{currency}} {{amount}} will be charged to your card ending in {{last}} starting from {{date}}.",
    FEATURE1: "Unlimited document generation",
    FEATURE2: "Unlimited document uploads",
    FEATURE3: "Unlimited health check",
  }
}
import useCustomMachine from "../../../../../generic/hooks/useCustomMachine";
import React, { FC, useEffect } from "react";
import Button from "../../../../../generic/components/Buttons/Button";
import { StepProps } from "../../../../../generic/components/Wizard/Step";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import FormAfilliatedCompany from "../../../Forms/FormAfilliatedCompany";
import { useDispatch } from "react-redux";
import {
  addAssistantText,
  clearAssistatText,
} from "../../../../../generic/store/action";
import FormCompany from "../../../Forms/FormCompany";
import FormIsThereMoreCompanies from "../../../Forms/FormIsThereMoreCompanies";

const Step1: FC<StepProps> = () => {

  const { translate } = useTranslate();
  const { next, send, state, stepData } = useCustomMachine();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearAssistatText());
  }, []);

  const handleCompleteSubmit = async (values) => {
    next(values);
  };
  
  const handleCancel = async (values) => {
    send("CANCEL");
  };

  return (
    <>
      <FormIsThereMoreCompanies
        initialValues={stepData}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: translate(
            "MODULES.GROUP_COMPANY.ASSETS_AND_REVENUES.THIRD_STEP.TITLE"
          ),
          subTitle: translate(
            "MODULES.GROUP_COMPANY.ASSETS_AND_REVENUES.THIRD_STEP.SUBTITLE"
          ),
        }}
        buttons={{
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              Cancel
            </Button>
          ),
        }}
      />
    </>
  );
};

export default Step1;


import _ from "lodash";
import React from "react";
import useBusinessRules from "../../hooks/useBusinessRules";
const BusinessRuleNoAccess = ({ children,type='and', conditions = [] }) => {

    const businesRules = useBusinessRules()
    
    const hasBusinessRuleAccess = () => {
        let access =true;
        _.forEach(conditions, (rule) => {
            if (businesRules[rule]) {
                if (type=="and" && access) {
                    access = access && businesRules[rule]();
                }else{
                    access = access ||  businesRules[rule]();
                }
            } else {
                access = false;
                console.log(`------> Rule ${rule} no exist`)
            }
        });
        return access;
    };


    return (
        <React.Fragment>
            {!hasBusinessRuleAccess() && children}
        </React.Fragment>
    )
}


export default BusinessRuleNoAccess;
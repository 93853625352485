import React, { Component } from "react";
import classes from "./ZoneGraph.module.scss"
// import { CircularGraph, Tabs } from "../../../index"
import { CircularGraph } from "../../../index"
// import Legends from "./Legends/Legends";
import Title from '../../../../../components/text/Title/Title';
// import { LeftArrow, RightArrow } from '../components/ArrowTable'
import ZoneLegends from "./ZoneLegends";

class ZoneGraph extends Component {

  constructor(props) {
    super(props);
    this.state = {
      options: {
        legend: {
          display: false,
          position: 'right'
        },
        maintainAspectRatio: false
      },
      defaultColors: ["#bbe4ff", "#0071bc", "#ffdc89", "#ed6d79", "#f5a26f", "#71e096", "#898cff"],
      colors: [],
      shareholdersData: [],
      shareholdersLabels: [],
      classData: [],
      classLabels: [],
      tabs: [
        { name: "Shareholders", key: "shareholders" },
        { name: "Class", key: "class" }
      ],
      tab: "shareholders",
      isClass: false,
    };
  }

  goTo = (tab) => {
    this.setState({
      isClass: tab === 'class',
      tab: tab
    })
  };

  calculateColors = (cant) => {
    let backgroundColor = [];
    for (let j = 0; j < cant; j++) {
      if (j + 1 > this.state.defaultColors.length) {
        const letters = '0123456789ABCDEF'.split('');
        let color = '#';
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        backgroundColor[j] = color;
      } else {
        backgroundColor[j] = this.state.defaultColors[j];
      }
    }
    this.setState({
      colors: backgroundColor
    })
  };

  onClick = (event) => {
    this.props.setContent(this.props.cardInfo.container);
  };

  componentWillMount() {
    this.calculateColors(1000);
  }

  dataProcess(props) {
    let shareholders = props.shareholders;
    let shares = props.shares;
    const classData = [];
    const classLabels = [];
    const shareholdersData = [];
    const shareholdersLabels = [];
    const issuedShares = {};
    for (let i = 0; i < shareholders.length; i++) {
      if (shareholders[i] && shareholders[i].equity.length > 0) {
        const shares = shareholders[i].equity.reduce((subTotal, share) => (share.amount || 0) + subTotal, 0);
        shareholdersData.push(shares);
        shareholdersLabels.push(shareholders[i].personalData.fullName);
      }
    }
    props.issuedShares.forEach(issuedShare => {
      issuedShares[issuedShare.shareName] = (issuedShares[issuedShare.shareName] || 0) + issuedShare.share;
    });
    for (let key in issuedShares) {
      classData.push(issuedShares[key]);
      classLabels.push(key);
    }
    this.setState({
      shareholdersData,
      shareholdersLabels,
      classData,
      classLabels
    })
  }

  componentDidMount() {
    this.dataProcess(this.props)
  }

  componentWillReceiveProps(props) {
    this.dataProcess(props)
  }

  render() {
    return (
      <div className={classes.ZoneGraph}>
        <div className={classes.Class}>
          <Title color='black' fontWeight='regular'>Shareholders</Title>
          <div className={classes.Graph}>
            <div className={classes.ClassGraph}>
              <CircularGraph
                options={this.state.options} colors={this.state.colors}
                data={this.state.shareholdersData} labels={this.state.shareholdersLabels}
              />
            </div>
            <ZoneLegends labels={this.state.shareholdersLabels} colors={this.state.colors} />
          </div>
        </div>
        <div className={classes.Class}>
          <Title color='black' fontWeight='regular'>Share Classes</Title>
          <div className={classes.Graph}>
            <div className={classes.ClassGraph}>
              <CircularGraph
                options={this.state.options} colors={this.state.colors}
                data={this.state.classData} labels={this.state.classLabels}
              />
            </div>
            <ZoneLegends labels={this.state.classLabels} colors={this.state.colors} />
          </div>
        </div>
      </div>
    );
  }
}


export default ZoneGraph;
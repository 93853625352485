import _ from 'lodash';
import moment from 'moment';
import Mustache from "mustache";
import React, { Component, useRef, useState } from 'react';
import { connect } from 'react-redux';
import BusinessRuleAccess from "../../../modules/clara/components/BusinessRuleAccess/BusinessRuleAccess";
import BusinessRuleNoAccess from "../../../modules/clara/components/BusinessRuleNoAccess/BusinessRuleNoAccess";
import RoleAccess from "../../../modules/security/components/RoleAccess/RoleAccess";
import Avatar from "../../../modules/generic/components/Avatars/AvatarLabel";
import { Constants } from '../../../v1/utils/constants';
import { Subscription } from "../../Hooks/Subscription";
import ServerConnect from '../../utils/ServerConnect';
import { ButtonModalOptions, Date } from '../index';
import classes from './Comments.module.scss';



const getComments = `{ getComments( startupId: "{{startupId}}", issueId: "{{issueId}}"){
    id
    content
    createdAt
    author{
        _id
      firstName
      lastName
      avatar    
    }

  }
}`

const upsertComment = `mutation upsertComment(
    $startupId: String
    $issueId: String
    $comment: CommentInputType
    $commentId: String
    
  ) {
    upsertComment(startupId: $startupId,  commentId:$commentId, comment: $comment, issueId: $issueId) {
     content
     id
     createdAt
     author{
      _id
      firstName
      lastName
      avatar  
     }
    }
  }`

const deleteComment = `mutation deleteComment(
    $startupId: String!
    $issueId: String!
    $commentId: String!
  ) {
    deleteComment(
      startupId: $startupId
      commentId: $commentId
      issueId: $issueId
    )
  }
  `

const ADD_COMMENT = `
 subscription {
    commentEvent(issueId: "{{issueId}}"){
       data {
        id
        content
        createdAt
        author {
          _id
          lastName
          firstName
          avatar
        }
      }
    	 type
    }
  }
 `

const getComment = `query {
    getComment(
      startupId: "{{startupId}}"
      issueId: "{{issueId}}"
      commentId: "{{commentId}}"
    ) {
      id
      content
      createdAt
      author {
        _id
        lastName
        firstName
        avatar
      }
    }
  }`


export class Comments extends Component {
    constructor(props) {
        super(props)
        this.state = {
            comments: _.map(props.comments,c=>({...c})),
            selectedComment: null,
            inputValue: '',
            inputTextRef: React.createRef(),
            todayDate: moment(new Date()).format("YYYY-MM-DD"),
            separator: true,
            containerChatRef: React.createRef(),
            pointView: 0,
            newComments: [],
            notifications: []

        };
    }

    componentWillMount = () => {
        const newComments = [];
        _.forEach(this.props.notifications, not => {
            const comment = _.find(this.props.comments, com => (not.data && com.id === not.data.commentId));
            if (comment) {
                newComments.push(comment);
            }

        });
        this.setState({ newComments })
    }
    componentDidMount = () => {
        let { containerChatRef } = this.state

        containerChatRef.current.scrollTop = containerChatRef.current.scrollHeight;
        setTimeout(() => this.handleSetViewComment(), 3000);
    }

    componentWillUnmount = () => {
        if (this.state.newComments.length !== 0) {
            this.handleSetViewComment();
        }
    }
    handleSetViewComment = () => {
        const mutation = `mutation setNotificationAsViewedByIssueId(
            $issueId: String
          )  {
              setNotificationAsViewedByIssueId( issueId: $issueId)
          }`;
        ServerConnect.graphQlMutation(mutation, { issueId: this.props.issueId })
            .then(res => {
                this.setState({ newComments: [] })
            });

    }

    callServerSendButton = (data) => {

        ServerConnect.graphQlMutation(upsertComment, data)
            .then(dataResult => {

                const index = _.findIndex(this.state.comments, com => com.id === data.commentId);

                if (index >= 0) {

                    this.state.comments[index] = {...dataResult.upsertComment};

                } else {
                    this.state.comments.push({...dataResult.upsertComment});
                    setTimeout(() => this.automaticScroll(), 500)
                }
                this.setState({ inputValue: "", selectedComment: "", newComments: [] }, () => {
                    this.state.inputTextRef.current.blur();

                });
            });
    }

    handleSendComment = () => {

        if (this.state.inputValue.length === 0) {
            return null;
        }

        let values = {
            startupId: this.props.session.startup.id,
            commentId: this.state.selectedComment,
            issueId: this.props.issueId,
            comment: {
                content: this.state.inputValue
            }

        }

        this.callServerSendButton(values);
    }

    handleRefreshListComment = (commentId) => {

        const query = Mustache.render(getComment, { commentId, startupId: this.props.session.startup.id, issueId: this.props.issueId });
        ServerConnect.graphQlQuery(query)
            .then(res => {
                this.state.comments.push(res.getComment);
                this.forceUpdate();

            });

    }

    handleEdit = ({ id, content }) => {
        this.setState({
            inputValue: content,
            selectedComment: id
        }, () => {
            this.state.inputTextRef.current.focus();

        })
    }

    handleRemove = ({ id }) => {

        const comments = this.state.comments;

        const data = {
            startupId: this.props.session.startup.id,
            issueId: this.props.issueId,
            commentId: id
        }

        ServerConnect.graphQlMutation(deleteComment, data)
            .then(resul => {

                _.remove(comments, com => com.id === id);

                this.setState({ comments });
            });

    }
    handleSendPressKey = (e) => {
        if (e.which === 13) {
            this.handleSendComment();
        }

    }
    handleSubscriptions = ({ data, error = {}, loading }) => {
        const { comments, newComments } = this.state;

        if (data && !loading) {
            const index = _.findIndex(comments, com => com.id === data.commentEvent.data[0].id);
            const newIndex = _.findIndex(newComments, com => com.id === data.commentEvent.data[0].id);
            if (data.commentEvent.type === "addComment" && newIndex < 0) {
                this.state.newComments.push(data.commentEvent.data[0]);

                setTimeout(() => this.automaticScroll(), 1000)
                this.forceUpdate();
            }
            if (data.commentEvent.type === "updateComment" && index >= 0) {

                this.state.comments[index] = data.commentEvent.data[0];
                setTimeout(() => this.forceUpdate(), 1000)


            }
            if (data.commentEvent.type === "deleteComment" && index >= 0) {
                _.remove(comments, com => com.id === data.commentEvent.data[0].id);

                this.setState({ comments });
                this.forceUpdate();

            }



        }


    }



    handleOnChange = (e) => {
        const inputValue = e.target.value;
        this.setState({ inputValue });
    }

    filterComments = (comments) => {

        const { todayDate, newComments } = this.state;

        let lastComments = [];
        let todayComments = [];

        _.forEach(comments, comment => {

            if (moment(comment.createdAt).format("YYYY-MM-DD") === todayDate) {
                todayComments.push(comment)
            } else {
                lastComments.push(comment)
            }
        });

        todayComments = newComments.length > 0 ? todayComments.slice(0, -newComments.length) : todayComments;

        return { lastComments, todayComments };


    }

    automaticScroll = (scrollTop = null) => {

        let { containerChatRef } = this.state


        if (containerChatRef.current) {

            containerChatRef.current.scrollTop = scrollTop || containerChatRef.current.scrollHeight;

        }
    }
    render() {
        const { comments, inputValue, inputTextRef, newComments } = this.state;
        const stateButton = inputValue.length === 0 ? "" : "activeButton";
        let { lastComments, todayComments } = this.filterComments(comments);

        const options = [];
        options.push({ label: "Edit", action: this.handleEdit, className: `${classes.EditIcon}` })
        options.push({ label: "Remove", action: this.handleRemove, className: `${classes.DeleteIcon}` })

        return (
            <div className={classes.containerComments}   >


                <div className={classes.comments}>

                    <label>Comments <span>({comments.length})</span></label>

                </div>

                <Subscription
                    subscriptions={ADD_COMMENT}
                    values={{ issueId: this.props.issueId }}
                    callback={this.handleSubscriptions} />


                <div className={`scrollbar ${classes.chatsContainer}`} ref={this.state.containerChatRef}>

                    {lastComments.map((comment, index) => {

                        const optionsModal = _.get(comment, "author._id") === _.get(this.props,"session.user._id") ? options : null;

                        return <Chat key={comment.id} index={index} options={optionsModal} {...comment} />;
                    })}

                    {todayComments.length !== 0 && <div className={`${classes.separator}`} > </div>}
                    {todayComments.map((comment, index) => {

                        const optionsModal = comment.author._id === this.props.session.user._id ? options : null;

                        return <Chat key={comment.id} index={index} options={optionsModal} {...comment} />;
                    })}

                    {newComments.length !== 0 && <div className={`${classes.separator} ${classes.newComments}`} />}
                    {newComments.map((comment, index) => {

                        const optionsModal = comment.author._id === this.props.session.user._id ? options : null;

                        return <Chat key={comment.id} index={index} options={optionsModal} {...comment} />;
                    })}






                </div>

                <BusinessRuleAccess conditions={[Constants.BUSINESS_RULES.IS_START_SUBSCRIPTION]}>
                    <RoleAccess action={Constants.ACTIONS.EDIT_FREEMIUM_STARTUP_REMARK}>
                        <div className={classes.containerInput} >
                            <input placeholder={"Write your comments..."} ref={inputTextRef} onKeyPress={this.handleSendPressKey} value={inputValue} onChange={this.handleOnChange} className={classes.inputComment} />
                            <div onClick={this.handleSendComment} className={`${classes.sendButton} ${classes[stateButton]}`} />
                        </div>
                    </RoleAccess>
                </BusinessRuleAccess>
                <BusinessRuleNoAccess conditions={[Constants.BUSINESS_RULES.IS_START_SUBSCRIPTION]}>
                    <div className={classes.containerInput} >
                        <input placeholder={"Write your comments..."} ref={inputTextRef} onKeyPress={this.handleSendPressKey} value={inputValue} onChange={this.handleOnChange} className={classes.inputComment} />
                        <div onClick={this.handleSendComment} className={`${classes.sendButton} ${classes[stateButton]}`} />
                    </div>
                </BusinessRuleNoAccess >


            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    session: state.session,
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Comments)


const Chat = (props) => {


    const { options, id, content, author, index, createdAt } = props;

    const [activeChat, setActiveChat] = useState('');
    const fullname = [_.get(author, "firstName"), _.get(author, "lastName")].join(" ");

    const buttonRef = useRef(null)

    const handleOnClik = (e) => {
        if (_.isEmpty(options)) {
            e.preventDefault();
            e.stopPropagation();

        } else {

            const modal = buttonRef.current;
            modal.state.open ? modal.handleClose() : modal.handleClick(e);
            modal.state.open ? setActiveChat("") : setActiveChat("containerChatActive");

        }
    }




    return (
        <div className={`${classes.containerChat} ${classes[activeChat]}`} onClick={handleOnClik}>

            <div className={classes.avatar} >
                <Avatar className={classes.AvatarImg} name={fullname} showLabel={false} size={"42"} avatar={_.get(author, "avatar")} />
            </div>
            <div className={classes.headerChat}>
                <label>{fullname}</label>
                <span ><Date value={createdAt} /></span>
                <div className={classes.buttonModal}>
                    <ButtonModalOptions params={{ id, content }} ref={buttonRef} title={null} options={options} />
                </div>
            </div>

            <span className={classes.description}>
                {content}
            </span>
        </div>
    )

}


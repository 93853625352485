import React, { useMemo } from "react";
import MaskedInput from "react-text-mask";
import classnames from "classnames";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import ErrorInput from "src/modules/generic/components/Inputs/ErrorInput";
import TooltipInput from "src/components/generic/Tooltips/TooltipIput/TooltipInput";
import useTextLang from "src/hooks/custom/useTextLang";
import { helper, Constants } from "src/v1/utils";
import FormHelperError from "../FormHelperError";
import classes from "./TextInput.module.scss";

const TextInput = React.forwardRef(
  (
    {
      type = "text",
      mask = null,
      maskParams = {},
      guide,
      value = "",
      className,
      rightLabel,
      leftLabel,
      placeholder = "",
      tooltip,
      error,
      errorMessage,
      isForm,
      name,
      afterComponent,
      decorator,
      onChange,
      classNameError,
      showErrorIcon,
      isSubmitting,
      disabled,
      defaultValue = "",
      innerRef,
      formState,
      fieldState,
      variant = "normal",
      needOnBlur = true,
      optional,
      decimals = true,
      ...props
    },
  ) => {
    const placeholderTranslate = useTextLang(placeholder);
    let maskType = type;
    let localMask = mask;
    let maskConfig = null;

    if (mask == null && Constants.TYPE_FORMATS[type.toUpperCase()]) {
      let format =
        helper.getFormatByKey(
          Constants.TYPE_FORMATS[type.toUpperCase()],
          maskParams
        ) || {};
      maskConfig = {
        prefix: format.prefix ? format.prefix : "",
        suffix: format.suffix ? format.suffix : "",
        includeThousandsSeparator: !!format.thousandSeparator,
        thousandsSeparatorSymbol: format.thousandSeparator,
        allowDecimal: decimals,
        decimalSymbol: format.decimalSeparator,
        decimalLimit: format.decimalScale,
        allowNegative: false,
        allowLeadingZeroes: format.fixedDecimalScale,
      };
      type = format.inputType ? format.inputType : "text";
      maskType = format.maskType ? format.maskType : maskType;
      localMask = createNumberMask(maskConfig);
    }

    const handleOnChangeType = (value, isOnBlur) => {
      switch (maskType) {
        case "percent":
        case "number":
          let localMaskValue = value
            .replaceAll(maskConfig.thousandsSeparatorSymbol, "")
            .replaceAll(maskConfig.decimalSymbol, ".");
          if (isOnBlur) {
            localMaskValue = Number(localMaskValue).toFixed(
              maskConfig.decimalLimit
            );
          }
          return localMaskValue;
        case "share":
          let localMaskValue2 = value
            .replaceAll(maskConfig.thousandsSeparatorSymbol, "")
            .replaceAll(maskConfig.decimalSymbol, ".");
          if (isOnBlur) {
            localMaskValue2 = Number(localMaskValue).toFixed(
              maskConfig.decimalLimit
            );
          }
          return localMaskValue2;
        case "phoneNumber":
          if (isOnBlur) {
            localMaskValue = Number(value);
          }
          return localMaskValue;
        default:
          return value;
      }
    };

    const handleBlur = (e) => {
      if (needOnBlur) {
        const { onBlur, trim = true } = props;
        //Remove all spaces at start and end of the string
        if (type === "text" && trim && e.target.value) {
          e.target.value = e.target.value.trim();
          onChange && onChange(handleOnChangeType(e.target.value), true);
        }
        if (type === "email") {
          e.target.value = e.target.value.toLowerCase();
          onChange && onChange(e.target.value);
        }
        onBlur && onBlur(e);
      }
    };

    const handleChange = (value) => {
      onChange && onChange(value);
    };

    const onFocus = (e) => {
      props.onFocus && props.onFocus(e);
    };

    const inputValue = useMemo(() => {
      if (value !== null && value !== undefined) {
        return value;
      }

      return defaultValue;
    }, [value]);

    return (
      <div
        className={classnames(` ${className}`, {
          [classes.TextInputSmall]: variant === "small",
          [classes.TextInput]: variant === "normal",
          [classes.TextInputMedium]: variant === "medium",
          [classes.TextInputDisabled]: disabled,
        })}
      >
        {leftLabel && <div className={classes.leftLabel}>{leftLabel}</div>}
        <TooltipInput label={tooltip}>
          {React.createElement(localMask ? MaskedInput : "input", {
            ...props,
            id: `${name}`,
            mask: localMask,
            guide,
            type,
            name,
            ref: innerRef,
            value: inputValue,
            disabled: isSubmitting || disabled,
            placeholder: placeholderTranslate,
            autoComplete: "nope",
            onChange: (e) =>
              handleChange(handleOnChangeType(e.target.value.trimLeft())),
            className: classnames(
              {
                [classes.borderErrorInput]: errorMessage || error,
                [classes.afterComponentPadding]: afterComponent,
                [classes.RightLabelPadding]: rightLabel,
                [classes.LeftLabelPadding]: leftLabel,
              },
              classes.input
            ),
            onBlur: (e) => handleBlur(e),
            onFocus,
          })}
        </TooltipInput>
        {rightLabel && <div className={classes.rightLabel}>{rightLabel}</div>}
        {afterComponent && (
          <div className={`afterComponent ${classes.afterComponent}`}>
            {afterComponent}
          </div>
        )}
        {optional && <FormHelperError errorMessage="Optional" />}
        {errorMessage && (
          <ErrorInput
            message={errorMessage}
            classNameError={classNameError}
            showErrorIcon={showErrorIcon}
            component={error}
          />
        )}
      </div>
    );
  }
);

export default TextInput;

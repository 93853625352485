import useLazyQuery from "src/hooks/custom/useLazyQuery";
import useSession from "src/modules/session/hooks/useSession";
import {get} from "lodash";
import {useParams} from 'react-router'
import { RoleFields } from "src/modules/stakeholder/graphql/typePolicies";
const GROUP_COMPANIES_QUERY =`
${RoleFields}
    query getGroupCompaniesAndStakeholderById($startupId: ID!,$stakeholderId:ID!) {
        getGroupCompanies(startupId: $startupId) {
            id
            name
            kind
            isLocked
            jurisdictionType
            managedByClara
        }
        getStakeholder(startupId: $startupId,stakeholderId:$stakeholderId) {
            id
      fullName
      isAnEntity
      isFounder
      rolesInProgress{
        toAdd{
          ...RoleFields
        }
        toDelete{
          ...RoleFields
        }
      }
      roles{
        id
        name
        stakeholder{
          id
        }
        entity {
            ... on StakeHolder {
              id
              fullName
            }
            ... on GroupCompany {
                id
                name
            }
        }
      }
        }
    }
`;
const useGetGroupCompaniesAndStakeholderByIdLazy = () => {
    const { startupId } = useSession();
    const {id} = useParams()
    const { manualQuery,loading,data,refetch,error } = useLazyQuery(GROUP_COMPANIES_QUERY, {
        startupId ,
        stakeholderId:id
    });
    return { data:{
        groupCompanies:get(data,'getGroupCompanies',[]),
        stakeholder:get(data,'getStakeholder',{}),
    }, loading, error,refetch,manualQuery };
}
export default useGetGroupCompaniesAndStakeholderByIdLazy;

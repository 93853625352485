import React from 'react';
import {Constants} from '../../../../../utils/constants';
import ClientReview from './ClientReview';
import SignatoriesView from './SignatoriesView';
import IncorporationPackView from './IncorporationPackView';

const PortalDocumentsFactoryContent = (props) => {

    const { mode } = props;

    const factoryContent = () => {
        switch (mode) {
            case Constants.PORTAL_DOCUMENTS_KEYS.CLIENT_REVIEW:
                return <ClientReview data={props.data} id={props.id} />;
            case Constants.PORTAL_DOCUMENTS_KEYS.SIGNATORIES:
                return <SignatoriesView data={props.data} id={props.id} />;
            case Constants.PORTAL_DOCUMENTS_KEYS.INCORPORATION_PACK:
                return <IncorporationPackView data={props.data} id={props.id}/>;
             default:
                 return null;       
        }
    }


    return (
        <React.Fragment>
            
                {factoryContent()}
            

        </React.Fragment>
    )
}

export default PortalDocumentsFactoryContent;
import { useMemo } from 'react';
import _ from "lodash";
import useSession from "src/modules/session/hooks/useSession";
import useUpdateStakeholder from "src/modules/startup/onBoarding/core/graphql/mutations/useUpdateStakeholder";
import { useIndividualStakeholderDetailsLazy } from "src/hooks/services/modules/startups/individual/useIndividualStakeholderDetailsData";
import useUploadBulkDocuments from "src/modules/startup/Stakeholder/graphql/mutation/useUploadBulkDocuments";

const useServices = ({ initialValues }) => {
  const { startupId } = useSession();
  const { stakeholderId } = initialValues;
  const { manualQuery: manualQueryGetStakeholder } = useIndividualStakeholderDetailsLazy({ startupId, stakeholderId });

  const { updateStakeholder } = useUpdateStakeholder({
    showSuccessNotification: true,
    successMessage: 'STAKEHOLDER_EDIT_SUCCESS'
  }, {});

  const { mutation: uploadDocuments } = useUploadBulkDocuments();

  return useMemo(() => ({
    initialData: async () => {
      const result = await manualQueryGetStakeholder();
      const stakeholder = _.get(result?.data, 'getStakeholder', {});      
      for (const key in stakeholder) {
        if (stakeholder[key] === null) {
          delete stakeholder[key];
        }
      }
      return { ...initialValues, stakeholder };
    },

    SaveStep: async (context) => {
      const detailsValues = context?.CompanyDetailsInfo;
      const documents = [];
      const file = _.get(context, "CompanyDetailsInfo.documentCertificate", {});
      if (!_.isEmpty(file)) {
        documents.push({
          title: file?.name,
          file: file,
          parties: [stakeholderId],
          status: "Uploaded",
          subCategories: ["miscellaneous"],
          type: "CERTIFICATE_OF_CHANGE_NAME"
        });
        await uploadDocuments({ variables: { startupId, documents: documents } });
      }
      const addressValues = context?.RegisteredAddress;
      const variables = {
        startupId,
        stakeholderId,
        stakeholderData: {
          stakeholder: {
            nationality: detailsValues?.nationality,
            jurisdiction: detailsValues?.otherJurisdiction || detailsValues?.jurisdiction,
            registeredNumber: detailsValues?.registeredNumber,
            taxRegistrationNumber: detailsValues?.taxRegistrationNumber,
            previousName: detailsValues?.previousName,
            address: {
              country: addressValues?.country,
              street: addressValues?.street,
              city: addressValues?.city,
              state: addressValues?.state,
              zipCode: addressValues?.zipCode,
            }
          }
        }
      };
      return await updateStakeholder({ variables });
    },
  }), [initialValues, manualQueryGetStakeholder, stakeholderId, startupId, updateStakeholder, uploadDocuments]);
}

export default useServices;

import React from 'react'
import _ from 'lodash'
import useTranslate from "src/modules/generic/hooks/useTranslate";
import classes from "./SourceOfFundsTab.module.scss";

const SourceOfFundsTab = ({ data: _data }) => {
  const { translate } = useTranslate();
  const data = _data[0] || {};

  return (
    <>
      <div className={classes.subtitle}>
        <span>{_.get(data, "jurisdictionType", '-')}</span>
      </div>
      <div>
        <div className={classes.twoColumnGrid}>
          <label>{translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.SOURCE_OF_FUNDS.SOURCE_OF_FUNDS_LABEL")}</label>
          <span>{_.get(data, "sourceOfFunds", '-')}</span>
        </div>
        <div className={classes.twoColumnGrid}>
          <label>{translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.SOURCE_OF_FUNDS.SOURCE_OF_WEALTH_LABEL")}</label>
          <span>{_.get(data, "sourceOfWealth", '-')}</span>
        </div>
      </div>
    </>
  );
}

export default SourceOfFundsTab;
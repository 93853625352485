import React, {FC, useEffect} from 'react';
import {useDispatch} from "react-redux";
import MachineFounder from "../MachineFounder";
import Wizard from "../../../../../generic/components/Wizard";
import ModalAssistant, {ModalAssistantProps} from '../../../../../generic/components/Modal/ModalAssistant';
import useModal from "../../../../../generic/hooks/useModal";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import {addAssistantText,clearAssistatText} from "../../../../../generic/store/action";
const ModalFounder: FC<ModalAssistantProps> = ({open = false}) => {
    const {closeModal} = useModal();

    const handleClose = () => {
        closeModal();
    }

    return (
        <ModalAssistant open={open} onClose={handleClose}>
            <Wizard machine={MachineFounder} onComplete={handleClose}/>
        </ModalAssistant>
    );
}

export default ModalFounder;

import React, {useEffect, useState} from 'react';
import {ServerConnect} from "../utils";
import _ from "lodash";
import Mustache from 'mustache'
import {individualType} from "../graphql/mutations/matters";

const QUERY_MATTER = `query{
  getEligibleAuthorizedSignatories(matterId:"{{matterId}}""){
    UAEOrGCCNationalSelection
      ${individualType}
    
    otherAdditionalSelection
      ${individualType}
    
  }
} 
    
`;

const QUERY_DRAFT = `query{
  getEligibleAuthorizedSignatoriesDraft(draftId:"{{draftId}}"){
    UAEOrGCCNationalSelection
    otherAdditionalSelection
  }
}
`;

export default function useEligibleAuthorizedSignatories(matterId,draftId,refresh) {
  const [authorizedSignatories, setAuthorizedSignatories] = useState(null);
  useEffect(() => {

    const query_params = {
      matterId,
      draftId
    };
    const query = Mustache.render( draftId ? QUERY_DRAFT : QUERY_MATTER, query_params);
    ServerConnect.graphQlQuery(query).then(result => {
      let kyc = _.get(result, draftId ? "getEligibleAuthorizedSignatoriesDraft" : "getEligibleAuthorizedSignatories", {});
      setAuthorizedSignatories(kyc)
    });
  }, [matterId,draftId,refresh]);

  return { authorizedSignatories };
}
import { useMemo } from 'react';
import useSession from 'src/modules/session/hooks/useSession';

import useUpdateUltimateBeneficialOwner from '../graphql/useUpdateUltimateBeneficialOwner';
import useGetUltimateBeneficialOwner from '../graphql/useGetUltimateBeneficialOwner';

const useServices = ({ initialValues, }) => {
  const [upsertData] = useUpdateUltimateBeneficialOwner();
  const { groupCompanyId, ultimateBeneficialOwnerId } = initialValues;
  const { startupId } = useSession();
  const { manualQuery: getGroupCompany } = useGetUltimateBeneficialOwner({ groupCompanyId, startupId, entityId: ultimateBeneficialOwnerId });
  return useMemo(() => ({

    initialData: async (context, event) => {
      const { data } = await getGroupCompany();
      
      return {
        initialValues,
        ultimateBeneficialOwner: data?.ultimateBeneficialOwner
      };
    },

    SaveStep: async (context, event) => {
      const variables = {
        startupId,
        groupCompanyId,
        entityId: ultimateBeneficialOwnerId,
        entityData: context?.EditFormStep ?? {}
      };
      if (typeof upsertData === 'function') {
        let result = await upsertData({ variables });
        return result;
      }
      return null;
    },

  }), [getGroupCompany, groupCompanyId, initialValues, startupId, ultimateBeneficialOwnerId, upsertData]);
}

export default useServices;

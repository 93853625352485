import React, { useMemo } from "react";
import { ProductBO } from "../../../../type/products"
import classesModule from "./Item.module.scss";
import Text from "../../../../../../components/text/Text/Text";
import Money from "../../../../../../components/text/Text/Money";
import _ from "lodash";
import moment from "moment";
export interface ItemsProps {
  product: ProductBO
}
const Product: React.FC<ItemsProps> = ({ product, ...props }: ItemsProps) => {

  const subTotalAmount = useMemo(() => {
    if (product) {
      return { currency: _.get(product, "currency"), amount: _.get(product, "installmentValue") }
    }
    return {}
  }, [product]);
  
  return (
    <React.Fragment>
      <div className={classesModule.items} >
        <div className={classesModule.item}>
          <div className={classesModule.product}>{_.get(product, "name")}</div>
          <div className={classesModule.amount}><span>{_.get(product, "currency")}</span>
            <b><Money value={_.get(product, "installmentValue")} params={{ prefix: " " }} /> </b>
          </div>
          <div className={classesModule.detail}>
            {product && (
              <Text uuid={`MODULES.CHECKOUT.PAYMENT_SUBLINE_ITEM_${_.get(product, 'code', '')}`} params={{
                date: moment().format('DD MMM'),
                ordinal: moment().format('Do'),
                nextOrdinal: moment().add(1, 'M').format("Do"),
                nextMonth: moment().add(1, 'M').format("MMMM"),
                more: parseInt(moment().format('DD')) >= 29,
              }} />
            )}
          </div>
        </div>
        <div className={`${classesModule.item} ${classesModule.borderTop}`}>
          <div className={classesModule.product}><Text uuid={"MODULES.CHECKOUT.PAYMENY_SUBTOTAL"} /></div>
          <div className={classesModule.amount}><span>{_.get(subTotalAmount, ("currency"))}</span>
            <b><Money value={_.get(subTotalAmount, ("amount"))} params={{ prefix: " " }} /> </b>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Product;

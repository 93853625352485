import React, { FC } from "react";
import classesModule from "./classes.module.scss";
import TypographyLocal from "../../../../modules/generic/components/Texts/Typography";
import Icon from "../../../../modules/generic/components/Icon";
import useTranslate from "../../../../modules/generic/hooks/useTranslate";

export interface AssistanceEmptyProps {
  /** Property title */
  title?: string,
  /** Property icon */
  icon?: string,
} 

const AssistanceEmpty: FC<AssistanceEmptyProps> = ({
  icon = "Bullseye",
  title,
  ...props
}:AssistanceEmptyProps) => {

  const { translate } = useTranslate();
  const defaultTitle = translate("GENERIC_EMPTY_STATE_ASSISTANCE");

  return (
    <div className={`${classesModule.assistanceViewContainerBlue}`}>
      <div className={`${classesModule.blueIcon}`}>
        <Icon icon={icon} size={"96px"} isClara={true}></Icon>
      </div>
      <TypographyLocal
        variant={"h6"}
        color={"dark-grey"}
        className={`${classesModule.titleFontStyle}`}
      >
        <span className={`${classesModule.textTitle}`} dangerouslySetInnerHTML={{__html: !!title ? title : defaultTitle}}></span>
      </TypographyLocal>
    </div>
  );
};

export default AssistanceEmpty;

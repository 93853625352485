import React, { useEffect,useState } from 'react';
import Button from 'src/modules/generic/components/Buttons/Button';
import Typography from 'src/modules/generic/components/Texts/Typography';
import TemplateDelete from 'src/modules/generic/templates/Modal/TemplateDelete';
import classModule from '../ChooseDocumentsView.module.scss';
import ModalDelete from 'src/modules/generic/components/Modal/ModalDelete';
import useModal from 'src/modules/generic/hooks/useModal';
import useDeleteCustomTemplate from '../../hooks/graphql/useDeleteCustomTemplate';
import DeleteForm from "src/modules/generic/forms/Delete";
import classes from '../ChooseDocumentsView.module.scss'
const CustomTemplateWarningModal = ({ description, open,onError,onSuccess,id }) => {
    const [isOpen, setOpen] = React.useState(open);
    const {closeModal} = useModal();
    const deleteCustomDocumentTemplate = useDeleteCustomTemplate({})
    const [loading,setLoading] = useState()
    const deleteTemplate = async () => {
      setLoading(true)
      try {
          await deleteCustomDocumentTemplate(id)
          onSuccess()
      } catch (e) {
          onError(e)
      }
      setLoading(false)
      closeModal()
  }
    useEffect(()=>{
        setOpen(open)
    },[open])
    const handleClose = () => {
        closeModal()
    }
    

    return ( 
        <ModalDelete open={isOpen} showHeader={false}>
          <TemplateDelete
      className={classModule.template}
      props={{
        title: 'Are you sure?',
        description: <Typography component='p' className={classes.templateText} color='error'>This action cannot be undone</Typography>,
      }}
      buttons={{
        submit: <Button variant='submitDelete' loading={loading} labelLoading={'Deleting...'} onClick={deleteTemplate}>Delete</Button>,
        cancel: <Button type={'button'} className={classes.cancelButton}
              onClick={handleClose}>Cancel</Button>
      }}
    ><></></TemplateDelete>
    </ModalDelete>
    );
    }

export default CustomTemplateWarningModal
import React from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";
const AddWarrant = ({go,...props}) => {
  const params = useParams();
  

  return (

    <MicroFrontend id="MF_Warrants_Content_Add" component={"./AddWarrants"} mf="capTable" params={{ go, ...props,...params }} />
  );
};

export default AddWarrant;

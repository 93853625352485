import React, {Component} from "react";

import classes from "./InfoLinks.module.scss";

class InfoLinks extends Component {
  render() {
    return (
      <div className={classes.InfoLinks}>
        <a
          className={classes.InfoLinks}
          href="https://clara.co/terms-of-use/"
        >
          Terms
        </a>
        <a
          className={classes.InfoLinks}
          href="https://www.clara.co/privacy-policy/"
        >
          Privacy
        </a>
        <a className={classes.InfoLinks} href="https://www.clara.co/contact-us">
          Contact Us
        </a>
      </div>
    );
  }
}

export default InfoLinks;

import React, {useEffect} from 'react';
import {Formik} from 'formik';
import classesForm from '../Form.module.scss';
import _ from 'lodash'
import {useParams} from 'react-router-dom';
import SelectorComponent from '../SelectorComponent/SelectorComponent'

const DirectorsForm = React.memo(({ initialValues, onSubmit, closeForm }) => {


  const params = useParams();

  useEffect(() => {

  }, [])


  return (
    <React.Fragment>

      {
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validate={null}
          onSubmit={onSubmit}
          isInitialValid
        >

          {({
            values,
            errors,
            dirty,
            handleSubmit,
            isSubmitting,
            handleReset,
            setFieldValue,
            initialValues,
            isValid,
            setFieldError,
            validateForm,
          }) => {

            return (

              <React.Fragment>
                <div className={classesForm.containerData} >
                  <SelectorComponent
                    name={"directors"}
                    titleButtons={`QUESTIONARY_SHAREHOLDER_DIRECTORS_TITLE_BUTTONS`}
                    titleEntyties={`QUESTIONARY_SHAREHOLDER_DIRECTORS_TEXT_DIRECTORS`}
                    initialValueStart={{ matterId: params.matterId }}
                    showButtonAdd={true}
                  />
                  <hr></hr>
                  <div className={`botoneraButtons ${classesForm.containerButtons}`}>
                    <button className={`genericBlue`} onClick={closeForm} >Cancel</button>
                    <button className={`genericBlue`} onClick={handleSubmit} disabled={!isValid || _.get(values, 'directors', []).length < 1} >Update </button>
                  </div>

                </div>
              </React.Fragment>
            )

          }
          }
        </Formik>
      }
    </React.Fragment>
  )
})

export default DirectorsForm;



import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import Button from "src/modules/generic/components/Buttons/Button";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import { clearAssistatText } from "src/modules/generic/store/action";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import FormIsThereCompaniesShares from "src/modules/startup/GroupCompany/Forms/FormIsThereCompaniesShares";

function WillHaveSharesIncorporatedStep() {
  const { translate } = useTranslate();
  const { next, prev, context, cancel } = useMachine();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return (
      context?.WillHaveSharesStep ?? context?.initialData?.groupCompany ?? {}
    );
  }, [context]);

  useEffect(() => {
    dispatch(clearAssistatText());
  }, []);

  const handleSubmit = async (values) => next(values);

  const handleCancel = () => cancel();

  const handlePrev = () => prev();

  return (
    <FormIsThereCompaniesShares
      initialValues={initialValues}
      onCompleteSubmit={handleSubmit}
      Template={TemplateWizard}
      propsTemplate={{
        title: "Does this company hold shares in any incorporated companies?",
        subTitle: "Review company details",
      }}
      buttons={{
        previous: (
          <Button onClick={handlePrev} variant="secondary">
            Previous
          </Button>
        ),
        cancel: (
          <Button onClick={handleCancel} variant="secondary">
            Cancel
          </Button>
        ),
      }}
    />
  );
}

export default WillHaveSharesIncorporatedStep;

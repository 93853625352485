
export default {
    REFRESH_ACTIVE_SUBSCRIPTION: "REFRESH_ACTIVE_SUBSCRIPTION",
    DOCUMENTS_REFRESH: "DOCUMENTS_REFRESH",
    HEALTH_CHECK_REFRESH: "HEALTH_CHECK_REFRESH",
    SHARE_PROFILE_REFRESH: "SHARE_PROFILE_REFRESH",
    STARTUP_TABLE_SHARING_REFRESH:"STARTUP_TABLE_SHARING_REFRESH",
    STARTUP_TABLE_BANK_REFRESH:"STARTUP_TABLE_BANK_REFRESH",
    RELOAD_ZIP_DOCUMENT:"RELOAD_ZIP_DOCUMENT",
    ERROR_401:"ERROR_401",
    ERROR_CLIENT:"NO_GRAPHQL_CLIENT",
    CONGRATS_ACCELERATOR:"CONGRATS_ACCELERATOR",
    DOCUMENT_SCROLL: "DOCUMENT_SCROLL",
    REFRESH_MY_TASKS: "EVENT_MY_TASKS_REFRESH_MY_TASKS",
    REFRESH_DASHBOARD: "EVENT_DASHBOARD_REFRESH_DASHBOARD",
    REFRESH_CARDS: "EVENT_CARDS_REFRESH_CARDS",
}

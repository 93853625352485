import React, { useEffect } from 'react';
import { addAssistantText, clearAssistatText } from 'src/modules/generic/store/action';

import Button from 'src/modules/generic/components/Buttons/Button';
import FormGeneralDetailsCaymanManagedByClara from 'src/modules/startup/GroupCompany/Forms/FormGeneralDetailsCaymanManagedByClara';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import { useDispatch } from 'react-redux';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';

function EditGeneralDetailsManagedByClara() {
  const { next, cancel, context } = useMachine();
  const dispatch = useDispatch();

  const initialValues = {
    ...context?.initialData?.groupCompany,
    pincasData: context?.pincasData?.pincasData 
  }

  const handleCancel = () => {
    cancel();
  }

  const handleSubmit = async (values) => {
    next(values);
  }

  useEffect(() => {
    dispatch(addAssistantText("<strong>Additional fees</strong> might apply if you need to update some of the details with the Cayman registrar.</br><div style='margin-top:1rem;'>To make a change, just edit the content and proceed. Once a change is made, a request will be sent to Clara and a service agent will get back to you with the related invoice to pay and next steps to legally file the changes for your company.</div>",'alert'));
    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch]);

  return (
    <FormGeneralDetailsCaymanManagedByClara
      initialValues={initialValues}
      onCompleteSubmit={handleSubmit}
      Template={TemplateWizard}
      propsTemplate={{
        title:'Edit general details',
      }}
      buttons={{
        cancel: <Button variant='secondary' onClick={handleCancel}>Cancel</Button>
      }}
    />
  );
}

export default EditGeneralDetailsManagedByClara;
export default {
    GENERIC_TOOLTIP: "A <a href=\"https://academy.clara.co/article/2fvaE5tVF7ywn7izaON0PR\" target=\"_blank\">director</a> is a member of the company's board, and can be responsible for the day-to-day management of the company and for setting the company's strategy.",
    INDIVIDUAL_TITLE: "Add their basic info",
    INDIVIDUAL_SUBTITLE: "Add a director",
    INDIVIDUAL_TOOLTIP: "You should only add individual stakeholders to a Delaware formation.",
    INDIVIDUAL_NAME_FIELD: "Director name",
    INDIVIDUAL_NAME_PLACEHOLDER: "As it appears in their passport",
    INDIVIDUAL_COMPANY_FIELD: "Company",
    DIRECTOR_ADDED_TITLE: "Do you have more directors to add?",
    DIRECTOR_ADDED_SUBTITLE: "Director added!",
    FIELD_REQUIRED: "This field is required",
}

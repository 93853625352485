import React from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";

const DetailsExerciseManual = ({ go, ...props }) => {
  const params = useParams();

  return (
    <MicroFrontend id="MF_Details_Exercise_Manual" component={"./DetailsExerciseManual"} mf="capTable" params={{ go, ...props, ...params }} />
  );
};

export default DetailsExerciseManual;

import React, {useEffect, useState} from 'react';
import useTranslate from '../../modules/generic/hooks/useTranslate';
import Mustache from "mustache";
import TRANSLATES from "../../langs/us"
import _ from "lodash";

export default function useTextLang(key, params, defaultKey) {
    
    const {translate} = useTranslate();
    const [text,setText] = useState("");

    const getTranslateTextByKey = (key, params, defaultKey) => {
        if (!key) {
            return null;
        }
        const label = _.get(TRANSLATES,key.toUpperCase(),getTranslateTextByKey(defaultKey, params));
        if (!label) {
            return key;
        }
        else if(label && params) {
            return Mustache.render(label, params);
        }else{
            return label;
        }
    };

    useEffect(() => {
        const newText = translate(key, params, defaultKey);
        setText(newText);
    }, [key, params, defaultKey]);

    return text ;
};
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import classes from "./SnackBar.module.scss";
import { Snackbar } from "@mui/material";
import Slide from '@mui/material/Slide';

// This component renders a snackbar depends on props given via sendNotification on utils/notification

const styles = (theme) => ({
  close: {
    padding: theme.spacing.unit / 2,
  },
});

class SimpleSnackbar extends React.Component {

  handleClose = (event, reason) => {
    this.props.handleSnackClose();
  };

  componentWillMount() {}

  TransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }

  // shouldShowCloseIcon = (condition) => {
  //   if (condition) {
  //     return (
  //       <IconButton
  //         key="close"
  //         aria-label="Close"
  //         color="inherit"
  //         className={classes.close}
  //         onClick={this.handleClose}
  //       >
  //         <CloseIcon />
  //       </IconButton>
  //     );
  //   }
  //   return false;
  // };

  render() {
    const {
      message,
      open,
      duration,
      // horizontal,
      // showClose,
      // vertical,
      type,
    } = this.props;

    return (
      <div>
        <Snackbar
          TransitionComponent={this.TransitionUp}
          anchorOrigin={{
            // vertical: vertical || "bottom",
            // horizontal: horizontal || "left",
            vertical: "bottom",
            horizontal: "center",
          }}
          open={open}
          autoHideDuration={duration || 2500}
          onClose={this.handleClose}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={message}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        >
          <div
            className={`${classes.SnackBar} ${classes[type]} ${this.props.className}`}
          >
            {message}
            {/* {this.shouldShowCloseIcon(showClose)} */}
          </div>
        </Snackbar>
      </div>
    );
  }
}

SimpleSnackbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleSnackbar);

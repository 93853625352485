import _ from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import NewTable from '../../../../../../components/Table/Table';
import useSession from '../../../../../../modules/session/hooks/useSession';
import { helper } from "../../../../../utils";
import { Constants } from '../../../../../utils/constants';
import notification from "../../../../../utils/notification";
import ServerConnect from '../../../../../utils/ServerConnect';
import { Avatar, ButtonModalOptions, Text, ToolTip } from '../../../../index';
import { ModalUploadDocument } from './FactoryModals';
import classes from './IncorporationPortal.module.scss';


const TableIncorporation = ({ data, matterId, status, incorporationId, callbackUpdate, setIncorporationData, setLoading }) => {

    const [dataModal, setDataModal] = useState({});
    const [showUploadModal, setShowUploadModal] = useState(false);

    const dispatch = useDispatch();
    const { user } = useSession();

    const openFile = (file) => {
        let url = ServerConnect.getUrlFile(file.id);
        ServerConnect.openFile(url, file.name);
    };

    const handleViewDocument = (row) => {
        const typeFile = row.file.name.split('.').pop();

        if (["docx","doc"].includes(typeFile)) {
            dispatch({
                type: 'GO_TO', page: Constants.PAGES.approveDocument, params: {
                    matterId, documentId: row.id,
                    fileId: row.file.id
                }
            })
        } else {
            let url = ServerConnect.getUrlFile(row.file.id);
            ServerConnect.openFile(url, row.file.name);
        }

    };

    const handleDownloadDocument = (row) => {
        let url = ServerConnect.getUrlFile(row.file.id);
        ServerConnect.downloadFileAsDoc(url, row.file.name);
    };

    const handleUploadDocument = (row) => {
        setDataModal({ document: row, matterId: matterId });
        setShowUploadModal(true);
    };

    const handleRetrieveDocument = (row) => {

        const mutation = `
		mutation retrieveDocumentIncorporation(
            $matterId: ID!
            $documentId: ID!
          ) {
            retrieveDocumentIncorporation(
              matterId: $matterId
              documentId: $documentId
            ) {
			    id
    			status
    			name
				file {
				  id
				  name
				  size
				}
            }
          }
        `;

        const documentId = row.id;

        // setIncorporationData(preIncorporationData => {
        //
        // 	const documents = preIncorporationData.documents.map(doc => {
        // 		if (doc.id === documentId) {
        // 			doc.status = 'Changed';
        // 		}
        // 		return doc
        // 	});
        //
        // 	return {
        // 		...preIncorporationData,
        // 		documents: documents
        // 	}
        // });

        notification.sendNotification("Retrieving Document", "success", 4990);
        setLoading(true);
        ServerConnect.graphQlMutation(mutation, { matterId: matterId, documentId: documentId })
            .then(res => {

                const { retrieveDocumentIncorporation: document } = res;

                setIncorporationData(preIncorporationData => {

                    const documents = preIncorporationData.documents.map(doc => {
                        if (doc.id === documentId) {
                            doc.name = document.name;
                            doc.status = document.status;
                            doc.file = document.file;
                        }
                        return doc
                    });

                    console.log(documents.some(d => d.status === "Pending") && !documents.some(d => d.status === "Sending")
                        ? "Pending"
                        : preIncorporationData.status);

                    return {
                        ...preIncorporationData,
                        status: documents.some(d => d.status === "Pending") && !documents.some(d => d.status === "Sending")
                            ? "Pending"
                            : preIncorporationData.status,
                        documents: documents
                    }
                });

                // const { incorporation } = res.retrieveDocumentIncorporation;
                // const documents = mutation.documents.map(doc => {
                // 	return {
                // 		...doc,
                // 		status: incorporation.documents.find(d => d.id === doc.id).status
                // 	}
                // });
                //
                // setIncorporationData(preIncorporationData => {
                // 	return {
                // 		...preIncorporationData,
                // 		status: incorporation.status,
                // 		documents: documents
                // 	}
                // });

                setLoading(false);
                notification.sendNotification("Document was retrieved", "success", 4990);
            }).catch(err => {
                console.log(err);
                setLoading(false);
                notification.sendNotification("ERROR", "error", 4990);
            });

    };


    let options = [];
    options.push({ isViewApproved: true, label: "View Document", action: handleViewDocument, className: `${classes.ViewICon}` });
    options.push({ isViewSignatories: true, label: "View Signatories", className: `${classes.ViewICon}` });
    options.push({ isDownloadDocument: true, label: "Download Document", action: handleDownloadDocument, className: `${classes.DownloadIcon}` });
    options.push({ isUploadDocument: true, label: "Upload Document", action: handleUploadDocument, className: `${classes.UploadIcon}` });
    options.push({ isRetrieveDocument: true, label: "Retrieve Document", action: handleRetrieveDocument, className: `${classes.ReplaceIcon}` });

    const transformAndFilterOptions = (options, row, data) => {
        const { original, toggleRowExpanded, isExpanded } = row;

        const newOption = { isViewSignatories: true, label: "Hide Signatories", action: () => toggleRowExpanded(false), className: `${classes.ViewICon}` }
        const transformOption = _.map(options, op => {
            return op.isViewSignatories ? isExpanded ? newOption : { ...op, action: () => toggleRowExpanded(true) } : op;
        });

        return _.filter(transformOption, option => {
            if (option.isViewApproved) {
                return original.assignedTo._id === user._id || ["Pending"].includes(original.status) ;
            }
            if (option.isRetrieveDocument) {
                return ["Sending"].includes(original.status) && data.some(e => e.status === "Pending");
            }
            if (option.isUploadDocument) {
                return !["Sending", "Pending", "Signed"].includes(original.status);
            }
            if (option.isViewSignatories) {
                return  !original.hideSignatures;
            }
            return true;
        })
    };




    const columns = React.useMemo(
        () => [
            {
                Header: ' ',
                columns: [
                    {
                        Header: 'Name',
                        accessor: 'name',
                        Cell: ({ row }) => {
                            return (
                                <div className={classes.containerName}>
                                    <ToolTip
                                        className={`${classes.letter}  `}
                                        data={row.values.name}
                                        type={"text"}
                                        timeShow={0}
                                        limit={42}
                                    />
                                    {row.original.isReplaced && <div className={classes.replaceIcon}>
                                        <span>Replaced</span>
                                    </div>}
                                </div>)
                        }
                    },
                    {
                        Header: 'Signatories',
                        accessor: 'signatories',
                        Cell: ({ row, data }) => {
                            let signatories = _.size(row.values.signatories);
                            let signed = _.size(row.values.signatories.filter(sig => sig.signed));
                            if (row.original.hideSignatures) {
                                return <div className={classes.containerElementTable}  >-</div>
                            }

                            return (
                                <div className={classes.containerElementTable}>
                                    <Text uuid={"INCORPORATION_PORTAL_TABLE_COLUMN_SIGNATORIES"} params={{ signatories, signed }} />
                                </div>
                            )

                        }
                    },
                    {
                        Header: 'Status',
                        accessor: 'status',
                        Cell: ({ row }) => {


                            if (row.original.hideStatus) {
                                return <div className={`${classes.letterCell} ${classes.circle}  ${classes["Generated"]}`}> - </div>
                            }

                            return (
                                <div className={`${classes.letterCell} ${classes.circle}  ${classes[row.values.status]}`}>
                                    {_.upperFirst(row.values.status) === 'InReview' ? ' In Review' : row.values.status}
                                </div>
                            )
                        },

                    },
                    {
                        Header: 'Assigned',
                        accessor: 'assignedTo',
                        Cell: ({ row }) => {
                            return (
                                <div className={classes.containerElementTable}>
                                    {row.values.assignedTo.fullName}
                                </div>
                            )
                        }

                    },
                    {
                        Header: '',
                        accessor: 'id',
                        Cell: ({ row, data,initialState }) => {
                            return (
                                <span className={classes.buttomModal}>
                                    <ButtonModalOptions title={null}
                                        options={transformAndFilterOptions(options, row, data)}
                                        params={row.original}
                                    />


                                </span>
                            )
                        }

                    },
                ],
            }
        ],
        []
    )


    return (
        <div>

            <NewTable
                columns={columns}
                data={data}
                renderRowSubComponent={RenderSignatories}
                initialState={{status}}
            />

            {/*<Table
                fields={fields}
                data={tableData}
                renderRow={renderSignatories}
            />*/}
            <ModalUploadDocument
                showModal={showUploadModal}
                closeModal={() => setShowUploadModal(false)}
                data={dataModal}
                title={helper.getTranslateTextByKey(`INCORPORATION_PORTAL_MODAL_REPLACE_DOCUMENT_TITLE`)}
                callbackUpdate={callbackUpdate}
                setLoading={setLoading}
            />

        </div>
    )
};





export default TableIncorporation;


const RenderSignatories = ({ row }) => {

    let openRow = !row.isExpanded;

    return (
        <td colSpan={row.cells.length} >
            <div className={`${classes.containerSignatories} ${!openRow && classes.hide}`} >

                {row.original.signatories.map(sig => {
                    const classesSigned = sig.signed ? classes.signedSignatory : classes.noSignedSignatory;
                    return <Avatar
                        key={sig._id}
                        captable
                        className={`${classes.avatar} ${classesSigned}`}
                        name={sig.label}
                        label={sig.label} />
                })}

            </div>
        </td>

    )
}

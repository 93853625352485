import React, { useState } from "react";
import { useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import DropDown from "src/components/generic/Menues/DropDown/DropDown";
import Text from "src/components/text/Text/Text";
import useBusinessRules from "src/modules/clara/hooks/useBusinessRules";
import Icon from "src/modules/generic/components/Icon";
import useSession from "src/modules/session/hooks/useSession";
import { goToPage } from "src/store/actions/page";
import { RoleAccess } from "src/v1/components";
import { Constants } from "src/v1/utils";
import classesModule from "./DropDownMenuUser.module.scss";

const DropDownMenuUser = ({ firstMenu }) => {

  const { session } = useSession();
  const { startup } = session;
  const { isViewer, hasSelectedStartup } = useBusinessRules();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogOut = (event) => {
    goToPage(dispatch, Constants.PAGES.logout);
    handleClose(event);
  };

  const handleMyAccount = (event) => {
    goToPage(dispatch, Constants.PAGES.myAccount, startup);
    handleClose(event);
  };

  const handleAuditLogs = (event) => {
    goToPage(dispatch, Constants.PAGES.messages);
    handleClose(event);
  };

  const handleVersion = (event) => {
    goToPage(dispatch, Constants.PAGES.versionManager);
    handleClose(event);
  };

  const editStartUp = () => {
    goToPage(dispatch, Constants.PAGES.viewStartup, {
      id: startup.id,
      tab: "details",
    });
  };

  const handleAccelerators = (event) => {
    goToPage(dispatch, Constants.PAGES.accelerators);
    handleClose(event);
  };
  const changeMyEquity = () => {
    goToPage(dispatch, Constants.PAGES["capTable.mf.myEquity.home"]);
  };
  const changeStartUp = () => {
    goToPage(dispatch, Constants.PAGES.switchStartup);
  };

  const handleSubscriptions = (event) => {
    goToPage(dispatch, Constants.PAGES.subscriptions);
    handleClose(event);
  };

  const handleInvoices = (event) => {
    goToPage(dispatch, Constants.PAGES.invoices);
    handleClose(event);
  };

  return (
    <div className={`${classesModule.user}`}>
      <DropDown
        buttonClass={classesModule.Button}
        noDropdownArrow
        selectedClassName={classesModule.ButtonSelected}
        options={[
          <MenuItem onClick={handleMyAccount}>
            <Icon icon={"Person"} size={"1.5385rem"} isClara={true} />
            <label>
              <Text uuid="MENU_VIEW_MY_ACCOUNT" />
            </label>
          </MenuItem>,
          <RoleAccess action={Constants.ACTIONS.VIEW_STARTUP}>
            <MenuItem onClick={editStartUp} style={{ display: (!hasSelectedStartup() || firstMenu) ? 'none' : '' }} >
              <Icon icon={"contact-card"} size={"1.5385rem"} isClara={true} />
              <label>
                <Text uuid="MENU_VIEW_STARTUP_PROFILE" />
              </label>
            </MenuItem>
          </RoleAccess>,
          <RoleAccess action={Constants.ACTIONS.MANAGE_ACCELERATORS}>
            <MenuItem onClick={handleAccelerators} style={{ display: (!hasSelectedStartup() || firstMenu) ? 'none' : '' }} >
              <Icon icon={"Star"} size={"1.5385rem"} isClara={true} />
              <label>
                <Text uuid="MENU_VIEW_ACCELERATORS" />
              </label>
            </MenuItem>
          </RoleAccess>,
          <>
            {!isViewer() &&
              <MenuItem onClick={handleAuditLogs} style={{ display: (!hasSelectedStartup() || firstMenu) ? 'none' : '' }}>
                <Icon icon={"Clock"} size={"1.5385rem"} isClara={true} />
                <label>
                  <Text uuid="MENU_ACTIVITY_LOGS" />
                </label>
              </MenuItem>
            }
          </>,
          <RoleAccess action={Constants.ACTIONS.MANAGE_SUBSCRIPTIONS}>
            <MenuItem onClick={handleSubscriptions} style={{ display: (!hasSelectedStartup() || firstMenu) ? 'none' : '' }}>
              <Icon icon={"New"} size={"1.5385rem"} isClara={true} />
              <label>
                <Text uuid="MENU_VIEW_MANAGE_SUBSCRIPTION" />
              </label>
            </MenuItem>
            <MenuItem onClick={handleInvoices} style={{ display: (!hasSelectedStartup() || firstMenu) ? 'none' : '' }}>
              <Icon icon={"Dcument-Money"} size={"1.5385rem"} isClara={true} />
              <label>
                <Text uuid="MENU_VIEW_VIEW_INVOICES" />
              </label>
            </MenuItem>
          </RoleAccess>,
          <MenuItem onClick={changeStartUp} style={{ display: (!hasSelectedStartup() || firstMenu) ? 'none' : '' }} >
            <Icon icon={"Company"} size={"1.5385rem"} isClara={true} />
            <label>
              <Text uuid="BUTTON_SWITCH_STARTUP" />
            </label>
          </MenuItem>,
          <MenuItem onClick={changeMyEquity} style={{ display: (!hasSelectedStartup() || firstMenu) ? 'none' : '' }} >
            <Icon icon={"Equity"} size={"1.5385rem"} isClara={true} />
            <label>
              <Text uuid="BUTTON_MY_EQUITY" />
            </label>
          </MenuItem>,
          <RoleAccess action={Constants.ACTIONS.ENVIRONMENT_CONFIG}>
            <MenuItem onClick={handleVersion}>
              <Icon icon={"Settings"} size={"1.5385rem"} isClara={true} />
              <label>
                <Text uuid="MENU_ENVIRONMENT_CONFIG" />
              </label>
            </MenuItem>
          </RoleAccess>,
          <MenuItem onClick={handleLogOut}>
            <Icon icon={"Logout"} size={"1.5385rem"} isClara={true} />
            <label>Logout</label>
          </MenuItem>,
        ]}
      >
        <Icon icon="Menu" size="26px" isClara={true} className={classesModule.burgerIcon} />
      </DropDown>
    </div>
  );
};

export default DropDownMenuUser;

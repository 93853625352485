import React from 'react';
import Scrollbar from 'src/components/generic/Scrollbar/Scrollbar';
import { Stack } from '@mui/material';
import classesModal from "./DataBankTransfer.module.scss";
import InternationalTransfer from './components/InternacionalTransfer';
import DomesticTransfer from './components/DomesticTransfer';

const DataBankTransfer = ({ bankTransferType, defineJurisdiction, bankInformation }) => {

  return (
    <>
      <div className={`${classesModal.PaymentTotal}`}>
        <Scrollbar>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{
              width: '100%',
            }}
          >
            {bankTransferType === 1 && (
              <InternationalTransfer defineJurisdiction={defineJurisdiction} bankInformation={bankInformation}></InternationalTransfer>
            )}
            {bankTransferType === 2 && (
              <DomesticTransfer defineJurisdiction={defineJurisdiction} bankInformation={bankInformation}></DomesticTransfer>
            )}
          </Stack>
        </Scrollbar>
      </div>
    </>
  )
}

export default DataBankTransfer;

import React from 'react';
import { useDispatch } from "react-redux";
import ReactAvatar from "react-avatar";
import PropTypes from "prop-types";
import Divider from '@mui/material/Divider';
import Button from "src/modules/generic/components/Buttons/Button";
import { Constants } from "src/v1/utils";
import CorporateSignatoryModal from "src/modules/startup/Stakeholder/EntityStructures/CorporateSignatoryModal";
import DetailsTabTitle from "src/components/templates/DetailsTabTitle";
import { RoleAccess } from "src/v1/components";
import useModal from "src/modules/generic/hooks/useModal";
import classes from '../styles.module.scss';

function CorporateSignatoryPanel({ data, isLock }) {
  const dispatch = useDispatch();
  const { openModal } = useModal();

  const goTo = (value) => {
    dispatch({
      type: "GO_TO",
      page: value.isAnEntity ? Constants.PAGES.viewStakeholderCompany : Constants.PAGES.viewStakeholderIndividual,
      params: { id: value.stakeholder, tab: "details" },
    });
  };

  const { authorizedSignatory } = data ?? {};

  const CorporateSignatoryButton = <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
    <Button variant="card" disabled={isLock} onClick={() => {
      openModal(CorporateSignatoryModal, {
        initialValues: {
          stakeholderId: data?.id,
          stakeholderName: data?.fullName ?? '',
          formValues: authorizedSignatory
        }
      }, 'MODAL_CORPORATE_SIGNATORY');
    }}>
      Edit
    </Button>
  </RoleAccess>;

  return (
    <>
      <Divider className={classes.divider} />
      <DetailsTabTitle title={"Corporate Signatory"} buttons={CorporateSignatoryButton} />
      <div className={classes.containerTab}>
        {authorizedSignatory?.fullName &&
          (<div
            className={classes.containerComponent}
            onClick={() => goTo(authorizedSignatory)}
          >
            <ReactAvatar
              name={authorizedSignatory?.fullName}
              round={true}
              size={"3.5rem"}
              src={authorizedSignatory?.avatar}
            />
            <p>{authorizedSignatory?.fullName}</p>
          </div>)
        }
      </div>
    </>
  );
}

CorporateSignatoryPanel.propTypes = {
  data: PropTypes.any,
  isLock: PropTypes.bool,
}

export default CorporateSignatoryPanel;
import React from "react";
import { useParams } from "react-router-dom";
import PaymentView from "./PaymentView";

const PaymentFormationDelawareView = (props) => {

  const params = useParams();
  const { productCode, groupCompanyId } = params;
  

  return (
    <React.Fragment>
      <PaymentView
        productCode={productCode}
        source={"FORMATION_DELAWARE"}
        id={groupCompanyId}
        canSelectCard={false}
      />
    </React.Fragment>
  );
};

export default PaymentFormationDelawareView;

import Machine from "../../../../generic/components/Machine/MachineClass";
import { Step1, Step2 } from './Steps';

const machineDefinition = {
  id: 'uploadDocument',
  initial: 'uploadDocument',
  states: {
    uploadDocument: {
      on: {
        NEXT: { target: 'set_date_expiry', actions: 'setContext' },
        CANCEL: 'finish_stakeholder_assign',
      },
    },
    set_date_expiry: {
      on: {
        NEXT: { target: 'finish_stakeholder_assign', actions: 'setContext' },
        CANCEL: 'finish_stakeholder_assign',
      },
    },
    finish_stakeholder_assign: {
      type: 'final',
      entry: 'final',
    },
    onDone: {
      actions: 'stopMachine'
    }
  },
}

const steps = {
  uploadDocument: Step1,
  set_date_expiry: Step2
}

const options = {}

export default class MachineProfileStakeholder extends Machine {
  constructor() {
    super(machineDefinition, options, steps);
  }

  getParamsData(values) {
    return values;
  }
}

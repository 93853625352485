import _ from 'lodash';
import React, { FC, useMemo, useRef, useState } from 'react';
import { useDispatch } from "react-redux";
import * as Yup from 'yup';
import Form from "../../../../../components/forms/Form/FormL";
import SubmitButton from "../../../../generic/components/Buttons/SubmitButton";
import { CheckboxButtonOptions } from "../../../../generic/components/Inputs/CheckboxButton";
import CheckboxButtonSimple from "../../../../generic/components/Inputs/CheckboxButtonSimple";
import ControllerInput from "../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import FormProps from "../../../../generic/forms/interfaces/FormProps";
import useTranslate from "../../../../generic/hooks/useTranslate";
import { addAssistantText, removeAssistantText } from "../../../../generic/store/action";
import TemplateFormDefault from "../../../../generic/templates/Modal";
import classes from './classes.module.scss';
const schema = Yup.object().shape({
    equity: Yup.string().required()
})
interface IsThereEquityCompanyProps extends FormProps {
    type: string
    isThereToCreate?: boolean
    className?: any
}
const IsThereEquityCompany: FC<IsThereEquityCompanyProps> = ({ initialValues, className, isThereToCreate = true, tooltips, onCompleteSubmit, Template = TemplateFormDefault, buttons = {}, buttonSubmitProps, propsTemplate, type }: IsThereEquityCompanyProps) => {
    const { translate } = useTranslate();
    const dispatch = useDispatch()
    const [first, setFirst] = useState()
    const options: Array<CheckboxButtonOptions<string, any>> = useMemo(() => {
        return isThereToCreate ? [
            {
                value: 'notAdd',
                label: translate(
                    `MODULES.EQUITY.FORMS.IS_THERE_EQUITY.NOT_ADD`
                )
            },
            {
                value: 'isExist',
                label: translate(
                    `MODULES.EQUITY.FORMS.IS_THERE_EQUITY.IS_EXIST`
                )
            },
            {
                value: 'toCreate',
                label: translate(
                    `MODULES.EQUITY.FORMS.IS_THERE_EQUITY.TO_CREATE`
                    , { type: translate(`MODULES.EQUITY.TYPES_PLURAL.${type}`) })
            }] : [ {
                value: 'notAdd',
                label: translate(
                    `MODULES.EQUITY.FORMS.IS_THERE_EQUITY.NOT_ADD`
                )
            },
            {
                value: 'isExist',
                label: translate(
                    `MODULES.EQUITY.FORMS.IS_THERE_EQUITY.IS_EXIST`
                )
            }]
    }, [isThereToCreate])
    const refForm = useRef()
    return (
        <React.Fragment>
            <Form
                defaultValues={initialValues}
                schema={schema}
                onSubmit={onCompleteSubmit}
                onlySendDirty={false}
                ref={refForm}
                optionsForm={{ mode: "onChange" }}
            >
                <Template props={propsTemplate} buttons={{ submit: <SubmitButton{...buttonSubmitProps}>Next</SubmitButton>, ...buttons }}>
                    <ControllerInput
                        render={CheckboxButtonSimple}
                        name='equity'
                        className={className}
                        classNameButton={classes.buttonCheckBox}
                        options={options}
                        onChange={value => {
                            if (tooltips) {
                                if (first) {
                                    dispatch(removeAssistantText());
                                    setFirst(false)
                                }
                                switch (value) {
                                    case 'notAdd':
                                        _.get(tooltips, 'notAdd') && dispatch(addAssistantText(_.get(tooltips, 'notAdd', "text")));
                                        _.get(tooltips, 'notAdd') && setFirst(true)
                                        break
                                    case 'isExist':
                                        _.get(tooltips, 'isExist') && dispatch(addAssistantText(_.get(tooltips, 'isExist', "text")));
                                        _.get(tooltips, 'isExist') && setFirst(true)
                                        break
                                    case 'toCreate':
                                        _.get(tooltips, 'toCreate') && dispatch(addAssistantText(_.get(tooltips, 'toCreate', "text")));
                                        _.get(tooltips, 'toCreate') && setFirst(true)
                                        break
                                }
                            }
                            
                        }}
                    />
                </Template>
            </Form>
        </React.Fragment>
    )
}

export default IsThereEquityCompany

import React, { useMemo } from 'react';
import CartItems from "src/modules/startup/PayFormationBundleModal/steps/CartStep/components/CartItems";
import ADGMCartItems from "src/modules/startup/PayFormationBundleModal/steps/CartStep/components/ADGMCartItems";
import CaymanCartItems from "src/modules/startup/PayFormationBundleModal/steps/CartStep/components/CaymanCartItems";
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';

function CartItemsLogic({ categories, loadingIds, handleDeleteItem, totalGovernmentFee, showFee }) {

  const { context } = useMachine();

  const activePincasSumaryADGM = useMemo(() => {
    let result = false;
    if (context?.initialData?.company?.jurisdictionType === "ADGM") {
      result = true;
    }
    return result;
  }, [context]);

  const activePincasSumaryCayman = useMemo(() => {
    let result = false;
    if (context?.initialData?.company?.jurisdictionType === "CAYMAN") {
      result = true;
    }
    return result;
  }, [context]);

  return (
    <>
      {activePincasSumaryADGM && (
        <ADGMCartItems
          categories={categories}
          loadingIds={loadingIds}
          handleDeleteItem={handleDeleteItem}
          totalGovernmentFee={totalGovernmentFee}
          showFee={showFee}
        />
      )}
      {activePincasSumaryCayman && (
        <CaymanCartItems
          categories={categories}
          loadingIds={loadingIds}
          handleDeleteItem={handleDeleteItem}
          totalGovernmentFee={totalGovernmentFee}
          showFee={showFee}
        />
      )}
      {!activePincasSumaryADGM && !activePincasSumaryCayman && (
        <CartItems
          categories={categories}
          loadingIds={loadingIds}
          handleDeleteItem={handleDeleteItem}
        />
      )}
    </>
  );
}
export default CartItemsLogic;

import { useMemo } from 'react';

const useGuards = () => {
  return useMemo(() => ({
    isNotPreApproved: (context) => {
      return context?.BusinessPlanStep?.preApproved === false;
    },
    isPreApproved: (context) => {
      return context?.BusinessPlanStep?.preApproved === true;
    },
    isUploadNow: (context) => {
      return context?.LetsPreApproveFormStep?.uploadNow === 'now';
    },
    isUploadLater: (context) => {
      return context?.LetsPreApproveFormStep?.uploadNow === 'later';
    },
  }), []);
}

export default useGuards;

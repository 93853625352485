import { useMemo } from "react";
import TemplateWizardSkeletonMachine from "src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import { IsEntity, SelectGroupCompanies, SelectRoles, IsMoreStakeholders, AddStakeholderName, LegalEntityStructure } from "../Steps";

const useSteps = () => {
  const { translate } = useTranslate();
  return useMemo(() => ({
    getStakeholdersGroupCompanies: {
      render: TemplateWizardSkeletonMachine,
      renderProps: {
        title: translate('MODULES.EQUITY.ADD_EQUITY.TITLE'),
        subTitle: translate('MODULES.EQUITY.ADD_EQUITY.SUBTITLE'),
      },
      stepperPosition: 0
    },
    select: {
      render: IsEntity,
      stepperPosition: 0
    },
    stakeholderEntityForm: {
      render: IsEntity,
      stepperPosition: 0
    },
    stakeholderNameForm: {
      render: AddStakeholderName,
      stepperPosition: 1
    },
    selectRolesForm: {
      render: SelectRoles,
      stepperPosition: 1
    },
    selectGroupCompanies: {
      render: SelectGroupCompanies,
      stepperPosition: 1
    },
    LegalEntityStructure: {
      render: LegalEntityStructure,
    },
    addMoreStakeholdersForm: {
      render: IsMoreStakeholders,
      stepperPosition: 1
    },
    SaveStep: {
      render: TemplateWizardSkeletonMachine,
    },
  }), [translate]);
}

export default useSteps;
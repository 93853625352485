import { assign } from "xstate";

const useActions = () => {
  const setIncorporatedCompanies = assign((context) => {
    const incorporatedCompanies = (
      context?.WhichCompaniesHaveSharesStep || []
    ).companies
      .filter((gc) => gc.id)
      .map((gc) => gc.id);

    return {
      ...context,
      incorporatedCompanies,
    };
  });

  const addIncorporatedCompany = assign((context) => {
    const incorporatedCompanies = context.incorporatedCompanies;
    const newCompany = context?.AddCompanyBasicInfoStep?.id;
    incorporatedCompanies.push(newCompany);

    return {
      ...context,
      incorporatedCompanies,
    };
  });

  return {
    setIncorporatedCompanies,
    addIncorporatedCompany,
  };
};

export default useActions;

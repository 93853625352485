import React, { useMemo } from "react";

import DetailsTabCayman from "src/views/modules/startups/stakeholder/corporateStakeholderView/components/Tabs/DetailTab"
import PropTypes from "prop-types";
import _ from "lodash";

function DetailsTab({ data, isLock,jurisdictions, isManagedByClara }) {

  return (
    <>   
      <DetailsTabCayman 
        data={data} 
        isLock={isLock} 
        isManagedByClara={isManagedByClara} 
        jurisdictions={jurisdictions}
        />
    </>
  );
}

DetailsTab.propTypes = {
  data: PropTypes.any
}

export default DetailsTab;
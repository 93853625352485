import React, { useMemo } from "react";
import Checkbox from "src/components/inputs/Checkbox/Checkbox";
import CountrySelector from "src/components/inputs/Select/CountrySelector/CountrySelector";
import useBusinessRules from "src/modules/clara/hooks/useBusinessRules";
import { editStartup } from "../../../../../forms/schemas/modules/startups/groupCompany/schema";
import Button from "../../../../../modules/generic/components/Buttons/Button";
import ControllerInput from "../../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import useTranslate from "../../../../../modules/generic/hooks/useTranslate";
import TemplateLabel from '../../../../../modules/generic/templates/Label';
import TemplateFormDefault from "../../../../../modules/generic/templates/Modal";
import SubmitButton from "../../../../generic/Buttons/SubmitButton/SubmitButton";
import TextInput from "../../../../inputs/Text/TextInput";
import Form from "../../../Form/Form";
import RadioCheckboxInput from "src/components/inputs/Radio/RadioCheckboxInput";

const StartupForm = ({
  initialValues,
  loading,
  handleSubmit,
  handleCloseModal,
  handleDeleteModal,
  propsTemplate,
  Template = TemplateFormDefault,
  ...props
}) => {

  const { translate } = useTranslate();
  const { isReseller } = useBusinessRules();
  const optionsReceiveEmails = useMemo(() => [
    { id: true, label: "Yes" },
    { id: false, label: "No" },
  ], []);
  return (
    <Form
      defaultValues={initialValues}
      schema={editStartup}
      onSubmit={handleSubmit}
      onlySendDirty={false}
    >
      <Template
        buttons={
          {
            submit: (
              <SubmitButton>
                {translate("MODULES.CLARA.BTN_SAVE_CHANGES")}
              </SubmitButton>
            ),
            cancel: (
              <Button onClick={() => handleCloseModal()} variant="secondary">
                {translate("MODULES.CLARA.BTN_CANCEL")}
              </Button>
            ),
          }
        }
        props={propsTemplate}
      >
        <TemplateLabel
          label={translate('FORM_COMAPANY_LABEL_STARTUP_NAME')}
          isRequired={true}
        >
          <ControllerInput
            as={TextInput}
            name="name"
            defaultValue={""}
            placeholder={translate('MODULES.CLARA.TYPE_HERE_HIT')}
            onChange={(e) => { }}
          />
        </TemplateLabel>
        <TemplateLabel
          label={translate('FORM_COMAPANY_LABEL_STARTUP_DESCRIPTION')}
        >
          <ControllerInput
            as={TextInput}
            name="description"
            defaultValue={""}
            placeholder={translate('MODULES.CLARA.TYPE_HERE_HIT')}
            onChange={(e) => { }}
          />
        </TemplateLabel>
        <TemplateLabel
          label={translate('FORM_COMAPANY_LABEL_STARTUP_PRIMARYLOCATION')}
        >
          <ControllerInput
            as={CountrySelector}
            name="primaryLocation"
            defaultValue={""}
            placeholder={translate('MODULES.CLARA.SELECT_COUNTRY')}
            onChange={(e) => { }}
          />
        </TemplateLabel>
        <TemplateLabel
          label={translate('FORM_COMAPANY_LABEL_STARTUP_WEBSITE')}
        >
          <ControllerInput
            as={TextInput}
            name="website"
            defaultValue={""}
            placeholder={translate('MODULES.CLARA.TYPE_HERE_HIT')}
            onChange={(e) => { }}
          />
        </TemplateLabel>
        <TemplateLabel
          label={translate('FORM_COMAPANY_LABEL_STARTUP_LINKEDIN')}
        >
          <ControllerInput
            as={TextInput}
            name="linkedInURL"
            defaultValue={""}
            placeholder={translate('MODULES.CLARA.TYPE_HERE_HIT')}
            onChange={(e) => { }}
          />
        </TemplateLabel>
        {isReseller() &&(
          <TemplateLabel
          label={"Do you want to receive emails on behalf of the profile owners?"}
        >
          <ControllerInput
            as={RadioCheckboxInput}
            name="resellerInfo.receiveEmailsOnBehalfOwners"
            options={optionsReceiveEmails}
            orientation="vertical"

          />
        </TemplateLabel>
        )}
      </Template>
    </Form>
  );
};

export default StartupForm;

import React from "react";
import useTranslate from "../../../generic/hooks/useTranslate";
import ControllerInput from "../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import RowAdd from "../../../../components/forms/contents/RowAdd/RowAdd";
import CountrySelector from "../../../../components/inputs/Select/CountrySelector/CountrySelector";
import JuridictionSelector from "../../../../components/inputs/Select/JuridictionSelector/JuridictionSelector";
import { useFormContext } from "react-hook-form";
import classes from "./classes.module.scss";
import constantsFilters from "../../constants";

const FormSelectJurisdictionFields = ({ }) => {

  const { translate } = useTranslate();
  const { watch } = useFormContext();
  const removeCountries = constantsFilters.COUNTRY_JURISDICTIONS_FILTERS[0];
  const filterCountriesAlpha3 = constantsFilters.COUNTRY_JURISDICTIONS_FILTERS[1];
  const filterJurisdictions = constantsFilters.COUNTRY_JURISDICTIONS_FILTERS[2];


  return (
    <div>
      <RowAdd
        label={"MODULES.FORMATION.FORM_SELECT_JURISDICTION_COUNTRY_LABEL"}
        className={classes.bottomSpace}
        input={
          <ControllerInput
            as={CountrySelector}
            name="country"
            placeholder={translate(
              "MODULES.FORMATION.FORM_SELECT_JURISDICTION_COUNTRY_HIT"
            )}
            clear
            removeCountries={removeCountries}
            filterCountries={filterCountriesAlpha3}
          />
        }
      />
      <RowAdd
        label={"MODULES.FORMATION.FORM_SELECT_JURISDICTION_JURISDICTION_LABEL"}
        input={
          <ControllerInput
            as={JuridictionSelector}
            name="jurisdiction"
            placeholder={translate(
              "MODULES.FORMATION.FORM_SELECT_JURISDICTION_JURISDICTION_HIT"
            )}
            noOtherJurisdiction={true}
            filterJurisdictions={filterJurisdictions}
            countrySelected={watch("country")}
            fakeCombo={true}
          />
        }
      />
    </div>
  );
};

export default FormSelectJurisdictionFields;

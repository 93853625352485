import React,{FC, useEffect} from 'react'
import {StepProps} from "../../../../../generic/components/Wizard/Step";
import { useDispatch } from "react-redux";
import Button from "../../../../../generic/components/Buttons/Button";
import useCustomMachine from "../../../../../generic/hooks/useCustomMachine";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import _ from "lodash";
import UploadForm from "../../../forms/UploadForm";
import useTranslate from "../../../../../generic/hooks/useTranslate";
type FirstStepSubmit = (values:object) => Promise<void>
interface FirstStepProps {
    type: string
    tooltips?: object
    onCompleteSubmit?: FirstStepSubmit
    isTherePrevious?:boolean
}
const UploadDocumentFormStep:FC<FirstStepProps>=({type = "COMPANY",tooltips,onCompleteSubmit,isTherePrevious=true})=>{
    const dispatch = useDispatch()
    const {send,next,stepData}=useCustomMachine()
    const {translate}=useTranslate()
    const handlePrev=()=>{
        send("PREVIOUS")
    }
    const handleCancel=()=>{
        send('CANCEL')
    }
    const handleCompleteSubmit = async (values:object) => {
        onCompleteSubmit && await onCompleteSubmit(values)
        next(values);
    }
    
    return(<>
        <UploadForm
            tooltips={tooltips}
            initialValues={stepData}
            propsTemplate={{
                title: translate(`MODULES.EQUITY.STEPS.UPLOAD_DOCUMENTS_STEP.TITLE`,{agreementType:translate(`MODULES.EQUITY.AGREEMENT_TYPE.${type}`)}),
                subTitle: translate(`MODULES.EQUITY.STEPS.UPLOAD_DOCUMENTS_STEP.SUBTITLE`,{type:translate(`MODULES.EQUITY.TYPES_PLURAL.${type}`)})
            }}
            onCompleteSubmit={handleCompleteSubmit}
            Template={TemplateWizard}
            buttons={{cancel: <Button onClick={handleCancel}>Cancel</Button>,previous:isTherePrevious?<Button onClick={handlePrev}>Previous</Button>:null}}/>
    </>)
}
export default UploadDocumentFormStep
import React, {Component} from 'react';
import {helper} from "../../../utils";
import classesModule from './Text.module.scss';
import Parser from "html-react-parser";
import _ from "lodash";
import Mustache from "mustache";
/*
    Properties:
        text: text to show
        params: params to parse data
*/
class TextParser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
            title: undefined
        }
    }
    handleClose=()=>{
        if (this.props.handleClose){
            this.props.handleClose();
        }
    }
    componentWillMount(){
        let {title} = this.props;

        this.setState({
            title: title
        })
    }
    componentWillUpdate(nextProps, nextState){
        
    }
    render() {
        const {unparsedText,params,classes} = this.props;
        let text = Mustache.render(unparsedText, params);
        text=text?text:"";
        

        return (
            <React.Fragment>
                <span className={`${classesModule.spanClass} ${_.get(classes,"root",null)}`}>{Parser(text)}</span>
            </React.Fragment>
        );
    }
}

export default TextParser;

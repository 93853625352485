import React, { FC, useState, useEffect } from 'react'
import Wizard from "../../../../generic/components/Wizard";
import ModalAssistant, { ModalAssistantProps } from '../../../../generic/components/Modal/ModalAssistant'
import { useDispatch } from 'react-redux'
import useTranslate from '../../../../generic/hooks/useTranslate'
import useModal from "../../../../generic/hooks/useModal";
import FormSourceOfFunds from "../../Forms/FormSourceOfFunds";
import TemplateWizard from "../../../../generic/templates/Modal/TemplateWizard";
import Button from "../../../../generic/components/Buttons/Button";
import { addAssistantText, clearAssistatText } from "../../../../generic/store/action";
import useUpdateStakeholder
  from "../../../../../hooks/services/modules/individualStakeholder/useUpsertComponentStakeholder";
import { useParams } from "react-router-dom";
import useSession from "../../../../session/hooks/useSession";
import _ from 'lodash';

interface ModalSourceOfFundsADGMProps extends ModalAssistantProps {
  paramsMutation: any,
  onClose(): void
}

const ModalSourceOfFundsADGM: FC<ModalSourceOfFundsADGMProps> = ({ children, open: openParam = false, paramsMutation, ...props }: ModalSourceOfFundsADGMProps) => {

  const [open, setOpen] = useState(openParam);
  const { closeModal: onClose } = useModal();
  const dispatch = useDispatch();
  const { id } = useParams()
  const { startupId } = useSession()
  const [upsertComponentStakeholder] = useUpdateStakeholder()
  const { translate } = useTranslate();

  useEffect(() => {
    setOpen(openParam);
  }, [openParam]);

  const handleCancel = (values) => {
    onClose();
    setOpen(false);
    clearAssistatText()
  }

  const handleInitModal = () => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText(translate('MODULES.STAKEHOLDER.SOURCE_OF_FUNDS.TOOLTIP')))
  }

  useEffect(() => {
    handleInitModal()
    return () => {
      dispatch(clearAssistatText());
    }
  }, []);

  const onSubmit = async (values) => {
    let wealth = values.sourceOfWealth;
    if (wealth === 'others') {
      wealth = values.otherSourcesOfWealth;
    }
    const oldSOF = _.get(paramsMutation, "sourcesOfFunds");
    const jt = _.get(paramsMutation, "jurisdictionType") === "" ? "ADGM" : _.get(paramsMutation, "jurisdictionType");
    const itemSOF = {
      jurisdictionType: jt,
      sourceOfFunds: values.sourceOfFunds,
      sourceOfWealth: wealth,
    }
    let newSOF;
    if (oldSOF !== undefined) {
      newSOF = [
        ...oldSOF,
        itemSOF,
      ];
    } else {
      newSOF = [
        itemSOF,
      ];
    }
    const variables = {
      startupId,
      stakeholderId: paramsMutation?.stakeholderId ?? id,
      stakeholder: {
        sourcesOfFunds: newSOF,
      }
    }
    await upsertComponentStakeholder({ ...variables })
    onClose()
  }

  return (
    <ModalAssistant open={open}>
      <FormSourceOfFunds
        isEdit={false}
        buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }}
        onCompleteSubmit={onSubmit}
        propsTemplate={{
          title: translate('MODULES.STAKEHOLDER.SOURCE_OF_FUNDS.TITLE'),
          subTitle: translate('MODULES.STAKEHOLDER.SOURCE_OF_FUNDS.SUBTITLE')
        }} Template={TemplateWizard} />
    </ModalAssistant>
  )
}

export default ModalSourceOfFundsADGM;

import React, {Component} from "react";

import classes from "./ClaraButton.module.scss"

/*
Component to show button with clara style
*/

class ClaraButton extends Component {
    state = {
      
    };
  
   
    render() {

        return (
            <button  
            {...this.props}
            className={`${classes.ClaraButton} ${this.props.className}`}
            
            >
                {this.props.children}
            </button>
        );
    }
}

export default (ClaraButton)

import React, { useEffect } from 'react';
import useQuery from "../../../../custom/useQuery"
import _ from 'lodash'

const query = `query getPossiblesSharingProfiles($startupId:ID!) {
    getPossiblesSharingProfiles(startupId: $startupId) {
        fullName
        email
    }
    getStartupUsersProfiles(startupId: $startupId) {
        _id
        role
        fullName
        email
    }
}`;

const usePossibleShareProfiles = (variables) => {
    const { loading, error, data } = useQuery(query, variables);
    return { loading, error,dataPossibleSharing:_.get(data,'getPossiblesSharingProfiles',{}),dataAlreadyShared:_.get(data,'getStartupUsersProfiles',{})};
}
export default usePossibleShareProfiles;
import React from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";

const EditCancelConvertible = ({ go, ...props }) => {
  const params = useParams()
  return (
    <MicroFrontend id="MF_Convertibles_Edit_Transaction" component={"./EditCancelConvertible"} mf="capTable" params={{ go, ...props, ...params }} />
  );
};

export default EditCancelConvertible;

import React from "react";
import Form from "src/components/forms/Form/Form";
import { HiddenFieldForm } from "src/components/forms/contents";
import RadioInput from "src/components/inputs/Radio/RadioInput";
import TextInput from "src/components/inputs/Text/TextInput";
import TextAreaInput from "src/components/inputs/Textarea/TextareaInput";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import FormProps from "src/modules/generic/forms/interfaces/FormProps";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import Typography from "src/modules/generic/components/Texts/Typography";
import classes from "./styles.module.scss";
import schema from "./schema";

const QuestionnaireForm: React.FC<FormProps> = ({
  initialValues,
  onCompleteSubmit,
  Template = TemplateWizard,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
}) => {
  return (
    <Form
      defaultValues={initialValues || {}}
      schema={schema}
      onlySendDirty={false}
      onSubmit={onCompleteSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      {" "}
      <Template
        buttons={{
          ...buttons,
          submit: <SubmitButton {...buttonSubmitProps}>Confirm</SubmitButton>,
        }}
        props={propsTemplate}
      >
        <Typography component="p">
          To confirm whether your Tech Licence will be renewing at its current
          classification (Seed or Emergent), please answer the questions below.
        </Typography>
        <TemplateLabel
          isRequired
          multilineTexts={["How many full-time employees did the company start with?"]}
        >
          <ControllerInput
            render={TextInput}
            name="employeeAmountStart"
            placeholder="Enter an amount"
          />
        </TemplateLabel>
        <TemplateLabel
          isRequired
          multilineTexts={["How many full-time employees does the company have now?"]}
        >
          <ControllerInput
            render={TextInput}
            name="employeeAmountNow"
            type="number"
            placeholder="Enter an amount"
          />
        </TemplateLabel>
        <TemplateLabel
          isRequired
          multilineTexts={["Are any of the full-time employees a shareholder in the company?"]}
        >
          <ControllerInput
            render={RadioInput}
            name="isEmployeeShareholder.question"
            defaultValue={false}
            className={classes.Gap}
          />
        </TemplateLabel>
        <TemplateLabel
          isRequired
          multilineTexts={["How many UAE employee visas are issued under the company?"]}
        >
          <ControllerInput
            render={TextInput}
            name="uaeVisasIssuedAmount"
            type="number"
            placeholder="Enter an amount"
          />
        </TemplateLabel>
        <TemplateLabel
          isRequired
          multilineTexts={["Does any shareholder of the company hold a visa issued by another UAE entity?"]}
        >
          <ControllerInput
            render={RadioInput}
            name="isThereEmployeeWithOtherVisas.question"
            defaultValue={false}
            className={classes.Gap}
          />
        </TemplateLabel>
        <TemplateLabel
          isRequired
          multilineTexts={["Will you be hiring any additional team members?"]}
        >
          <ControllerInput
            render={RadioInput}
            name="isThereFutureEmployees.question"
            defaultValue={false}
            className={classes.Gap}
          />
        </TemplateLabel>
        <HiddenFieldForm
          fieldsWatch="isThereFutureEmployees.question"
          conditionHideCallback={(value) => value}
        >
          <TemplateLabel
            isRequired
            multilineTexts={["What will be the team member hiring timeline?"]}
          >
            <ControllerInput
              render={TextInput}
              name="isThereFutureEmployees.description"
            />
          </TemplateLabel>
        </HiddenFieldForm>
        <TemplateLabel 
          isRequired
          multilineTexts={["Is the company operational?"]}
        >
          <ControllerInput
            render={RadioInput}
            name="isCompanyOperational.question"
            defaultValue={false}
            className={classes.Gap}
          />
        </TemplateLabel>
        <HiddenFieldForm
          fieldsWatch="isCompanyOperational.question"
          conditionHideCallback={(value) => value}
        >
          <TemplateLabel
            isRequired
            multilineTexts={["How many jurisdictions is this business currently operating in?"]}
          >
            <ControllerInput
              render={TextInput}
              name="isCompanyOperational.description"
              type="number"
            />
          </TemplateLabel>
        </HiddenFieldForm>
        <TemplateLabel 
          isRequired
          multilineTexts={["What is the revenue of the company?"]}
        >
          <ControllerInput
            render={TextInput}
            name="companyRevenue"
            type="number"
            placeholder="e.g. 0.01"
            leftLabel={"USD"}
          />
        </TemplateLabel>
        <TemplateLabel
          isRequired
          variant="top"
          multilineTexts={["Briefly explain the future plans of the company."]}
        >
          <ControllerInput
            render={TextAreaInput}
            name="futurePlans"
            placeholder="Type here"
          />
        </TemplateLabel>
        <div>&nbsp;</div>
      </Template>
    </Form>
  );
};

export default QuestionnaireForm;

import { get } from 'lodash';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Button from '../../../../../generic/components/Buttons/Button';
import { StepProps } from "../../../../../generic/components/Wizard/Step";
import useCustomMachine from '../../../../../generic/hooks/useCustomMachine';
import useTranslate from '../../../../../generic/hooks/useTranslate';
import { addAssistantText, clearAssistatText } from '../../../../../generic/store/action';
import TemplateWizardPreview from '../../../../../generic/templates/Modal/TemplateWizard/TemplateWizardPreview';
import useSession from '../../../../../session/hooks/useSession';
import Form from "../../components/Forms/FormPassportData";
import useUpsertTwoDocumentFormation from '../../../../../../hooks/services/modules/individualStakeholder/useUpsertTwoDocumentFormation';

const Step2: FC<StepProps> = () => {

  const { translate } = useTranslate();
  const { next, send, state } = useCustomMachine();
  const [updateDocument] = useUpsertTwoDocumentFormation();
  const { id: _id } = useParams<any>()
  const id = state?.context?.data.stakeholderId ?? _id;
  const { startupId } = useSession();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(addAssistantText(" Upload a double page scn of the passport clearly showing their photo and passport number"));
    return () => {
      dispatch(clearAssistatText());
    }
  }, [])

  const handleCompleteSubmit = async values => {
    const variables = {
      stakeholderId: state?.context?.data?.stakeholderId ?? id,
      startupId,
      identityDocumentDataOLD: { ...values, "type": "OLDPASSPORT_DOCUMENT", option: false },
      identityDocumentData: { ...get(state, 'context.data.set_document_visa', {}), "type": "UAE_ENTRY_STAMP_DOCUMENT", option: true }
    }
    await updateDocument({ variables });
    next(values);
  }

  const handleCancel = async values => {
    send("CANCEL");
  }

  return (
    <React.Fragment>
      <Form
        initialValues={get(state, 'context.data.set_old_passport', {})}
        isOldPassport={true}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizardPreview}
        propsTemplate={{
          title: 'Add their old passport',
          subTitle: 'UAE entry stamp'
        }}
        buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }}>
      </Form>
    </React.Fragment>
  )
}

export default Step2;

import useLazyQuery from 'src/hooks/custom/useLazyQuery';

const GET_GROUP_COMPANY = `
query getBusinessActivities($startupId: ID!, $groupCompanyId: ID!) {
  getGroupCompany(startupId: $startupId, groupCompanyId: $groupCompanyId) {
    id
    managedByClara
    businessActivities {
      relevantActivities
      articlesOfAssociationType
      articlesOfAssociation{
        id
        name
        file{
          id
          name
        }
      }
  }
}
}`;

export const useGetGroupCompanyEconomicSubstance = (variables, config) => {
  const { manualQuery, loading, error, data, refetch } = useLazyQuery(GET_GROUP_COMPANY, variables, config);
  return { loading, error, data, refetch, manualQuery };
};

export default useGetGroupCompanyEconomicSubstance;
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import _ from "lodash";
import Tooltip from "@material-ui/core/Tooltip";
import { Chip } from "@mui/material";
import Button from "src/modules/generic/components/Buttons/Button";
import { Constants, ServerConnect } from "src/v1/utils";
import CorporatePositionsTab from "src/views/modules/startups/company/companyView/Tabs/CorporatePositionsTab";
import DeleteGroupCompany from "src/modules/startup/GroupCompany/Delete";
import Details from "src/components/templates/Details/Details";
import DetailsTab from "src/views/modules/startups/company/companyView/Tabs/DetailsTab";
import { EquityTab } from "src/components/modules/clara/startups/groupCompany/Tabs";
import GroupCompany from "src/components/modules/clara/startups/entities/classes/GroupCompany";
import KeyPeopleTab from "src/views/modules/startups/company/companyView/Tabs/KeyPeopleTab";
import ModalCategoriseDocument from "src/components/modules/clara/startups/Document/Modals/CategoriseDocument";
import ModalCorporatePositions from "src/modules/startup/Stakeholder/CorporatePosition";
import ModalManageFutureLinkedCompanies from "src/modules/startup/GroupCompany/ADGM/EditManageFutureLinkedCompanies";
import ModalNatureOfBusiness from "src/modules/startup/GroupCompany/KY/NatureOfBusiness";
import ModalShareCapital from "src/modules/startup/GroupCompany/ShareCapital";
import ModalShareCapitalDelaware from "src/modules/startup/GroupCompany/DELAWARE/ShareCapital";
import NatureOfBusinessTab from "src/views/modules/startups/company/companyView/Tabs/NatureOfBusinessTab";
import OtherTab from "src/views/modules/startups/company/companyView/Tabs/OtherTab";
import PincasIcon from "src/modules/generic/components/Icon/PincasIcon";
import { RoleAccess } from "src/v1/components";
import Text from "src/components/text/Text/Text";
import { updateParamsBreadCrumbs } from "src/store/actions/page";
import useCompanyDetails from "src/hooks/services/modules/groupCompany/useGetCompanyDetails";
import useGetQuestionnaire from "src/views/modules/startups/company/companyView/Tabs/DetailsTab/panels/ADGM/QuestionnairePanel/graphql/useGetQuestionnaire";
import useModal from "src/modules/generic/hooks/useModal";
import useNavigation from "src/modules/generic/hooks/useNavigation";
import useSession from "src/modules/session/hooks/useSession";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import DocumentsTab from "src/components/modules/clara/startups/groupCompany/Tabs/DocumentsTab/DocumentsTab";
import useLoading from "src/modules/generic/hooks/useLoading";
import TechLicenceModal from "../../renewal/adgm/modals/TechLicenceModal/index";
import QuestionnairePanel from "./Tabs/DetailsTab/panels/ADGM/QuestionnairePanel";
import classes from "./CompanyView.module.scss";
import EconomicSubstanceTab from "./Tabs/EconomicSubstanceTab";
import EditEconomicSubstanceInformationModal from "../../renewal/cayman/modals/EditEconomicSubstanceInformationModal";

const CompanyView = () => {
  const { goTo } = useNavigation();
  const { openModal, closeModal } = useModal();
  const dispatch = useDispatch();
  const { session } = useSession();
  const { translate } = useTranslate();

  const startupId = useMemo(() => _.get(session, "startup.id"), [
    _.get(session, "startup.id"),
  ]);

  const params = useParams();
  const { isLoading } = useLoading();

  const { data } = useCompanyDetails({
    startupId,
    groupCompanyId: params.id,
  });

  const isLock = useMemo(() => _.get(data, "isLocked", false), [data]);

  const jurisdictionType = useMemo(() => _.get(data, "jurisdictionType"), [
    data,
  ]);

  const { data: questionnaireData } = useGetQuestionnaire({
    startupId,
    groupCompanyId: params.id,
  });

  const handleShareCapitalModal = (data) => {
    let initialValuesShareCapital = null;
    if (data?.formationData?.sharesData?.shareholders) {
      initialValuesShareCapital = _.map(
        data?.formationData?.sharesData?.shareholders ?? [],
        (stakeholder) => {
          const shareIssuances = stakeholder?.shareIssuances;
          let quantity = null;
          let shareClass = null;
          let isPaid = false;
          if (shareIssuances && shareIssuances.length > 0) {
            quantity = shareIssuances[0].quantity;
            shareClass = shareIssuances[0].shareClass;
            isPaid = shareIssuances[0].isPaid;
          }
          return {
            id: stakeholder?.shareholder?.id,
            shares: quantity,
            shareClass,
            isPaid,
          };
        }
      );
    }
    if (jurisdictionType === "ADGM" || jurisdictionType === "CAYMAN") {
      openModal(ModalShareCapital, {
        paramsMutation: {
          id: params?.id,
          jurisdictionType,
          shareholdersInitialValues: initialValuesShareCapital,
        },
      });
    }
    if (jurisdictionType === "DELAWARE") {
      openModal(ModalShareCapitalDelaware, {
        paramsMutation: { id: params?.id, jurisdictionType },
      });
    }
    openModal(ModalShareCapital, {
      paramsMutation: { id: params?.id, jurisdictionType },
    });
  };

  useEffect(() => {
    updateParamsBreadCrumbs(dispatch, { fullName: _.get(data, "name") });
  }, [_.get(data, "name")]);

  const handleChangeAvatar = async (value) => {
    await ServerConnect.requestApi(
      `/avatars/startups/${startupId}/groupCompanies/${params.id}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          avatarData: value,
        }),
      },
      null,
      { showNotification: false }
    );
  };

  const hasNatureOfBusiness = () => {
    let showHasNatureOfBusiness = false;
    if (
      _.get(data, "formationData.businessActivity.activity") !== undefined &&
      _.get(data, "formationData.businessActivity.activity") !== null
    ) {
      showHasNatureOfBusiness = true;
    }
    return showHasNatureOfBusiness;
  };

  const hasCorporatePositions = () => {
    let showCorporatePositions = false;
    if (
      _.get(data, "formationData.corporatePositions") !== undefined &&
      _.get(data, "formationData.corporatePositions") !== null
    ) {
      showCorporatePositions = true;
    }
    return showCorporatePositions;
  };

  const getEnabledTabOthers = () => {
    let showHasShareInIncorporated = false;
    if (
      _.get(data, "formationData.hasShareInIncorporated") !== undefined &&
      _.get(data, "formationData.hasShareInIncorporated") !== null
    ) {
      showHasShareInIncorporated = true;
    }
    let showHasShareInCompanyFormed = false;
    if (
      _.get(data, "formationData.hasShareInCompanyFormed") !== undefined &&
      _.get(data, "formationData.hasShareInCompanyFormed") !== null
    ) {
      showHasShareInCompanyFormed = true;
    }
    return showHasShareInIncorporated || showHasShareInCompanyFormed;
  };

  const hasQuestionnaire = () => {
    let showQuestionnaire = false;
    if (
      _.get(questionnaireData, "getRenewalData")?.techLicense !== undefined &&
      _.get(questionnaireData, "getRenewalData")?.techLicense !== null
    ) {
      showQuestionnaire = true;
    }
    return showQuestionnaire;
  };
  
  const hasEconomicSubstance = () => {
    const hasCompanyIncome = !!_.get(questionnaireData, "getRenewalData")?.companyIncome;
    return hasCompanyIncome;
  };

  const groupCompanyClass = useMemo(() => {
    if (!data) return null;
    return new GroupCompany(data);
  }, [data]);

  const managedByClara = useMemo(() => {
    if (!data) return false;
    return groupCompanyClass.isManagedByClara();
  }, [data]);

  const isRenewal = useMemo(() => {
    if (!data) return false;
    return groupCompanyClass.getIsRenewal();
  }, [data]);

  const isDissolved = useMemo(() => {
    if (!data) return false;
    return groupCompanyClass.getIsDissolved();
  }, [data]);

  const content3 = useMemo(() => {
    const contents = [
      <DetailsTab
        data={data}
        isLock={isLock}
        jurisdictionType={jurisdictionType}
      />,
      <DocumentsTab />,
      <EquityTab data={_.get(data, "kind")} allData={data} />,
      <KeyPeopleTab
        data={data}
        isLock={isLock}
        isRenewal={managedByClara}
        jurisdictionType={jurisdictionType}
        sign={_.get(
          data,
          "formationData.bankInformation.requireToSignJointly",
          false
        )}
      />,
      hasNatureOfBusiness() && (
        <NatureOfBusinessTab data={_.get(data, "formationData")} />
      ),
      // ADGM
      getEnabledTabOthers() && (
        <OtherTab
          data={_.get(data, "formationData")}
          company={data}
          groupCompanyId={params.id}
        />
      ),
      // CAYMAN
      hasEconomicSubstance() && (
        <EconomicSubstanceTab
          data={data}
          renewalData={questionnaireData}
          isLock={isLock}
        />
      ),
      // DELAWARE
      hasCorporatePositions() && (
        <CorporatePositionsTab
          data={_.get(data, "formationData.corporatePositions", {})}
        />
      ),
      <QuestionnairePanel
        data={questionnaireData?.getRenewalData?.techLicense}
      />,
    ];
    return _.compact(contents);
  }, [JSON.stringify(data), isLock, questionnaireData]);

  const originalTabs = useMemo(() => {
    return [
      {
        show: true,
        disabled: false,
        component: (
          <label>
            <Text uuid="VIEW_COMPANY_TAB_LABEL_DETAILS" />
          </label>
        ),
        tabName: translate("VIEW_COMPANY_TAB_LABEL_DETAILS"),
        key: "details",
        multiplePanels: true,
      },
      {
        show: true,
        disabled: false,
        component: (
          <label>
            <Text uuid="VIEW_COMPANY_TAB_LABEL_DOCUMENT" />
          </label>
        ),
        tabName: translate("VIEW_COMPANY_TAB_LABEL_DOCUMENT"),
        key: "document",
      },
      {
        show: true,
        disabled: false,
        component: (
          <label>
            <Text
              uuid={`${data?.kind === "INCORPORATED"
                ? "VIEW_COMPANY_TAB_LABEL_EQUITY"
                : "VIEW_COMPANY_TAB_LABEL_EQUITY_FORMATION_SHARE_CAPITAL"
                }`}
            />
          </label>
        ),
        tabName: translate("EQUITY"),
        key: "equity",
      },
      {
        show: true,
        disabled: false,
        component: (
          <label>
            <Text uuid="VIEW_COMPANY_TAB_KEY_PEOPLE_DETAILS" />
          </label>
        ),
        tabName: translate("VIEW_COMPANY_TAB_KEY_PEOPLE_DETAILS"),
        key: "keyPeople",
        multiplePanels: true,
      },
      {
        show: hasNatureOfBusiness(),
        disabled: false,
        component: (
          <label>
            <Text uuid="VIEW_COMPANY_TAB_LABEL_NATURE_OF_BUSINESS" />
          </label>
        ),
        tabName: translate("VIEW_COMPANY_TAB_LABEL_NATURE_OF_BUSINESS"),
        key: "natural",
      },
      // ADGM
      {
        show: getEnabledTabOthers(),
        disabled: false,
        component: (
          <label>
            <Text uuid="VIEW_COMPANY_TAB_LABEL_OTHER" />
          </label>
        ),
        tabName: translate("VIEW_COMPANY_TAB_LABEL_OTHER"),
        key: "other",
      },
      // DELAWARE
      {
        show: hasCorporatePositions(),
        disabled: false,
        component: (
          <label>
            <Text uuid="VIEW_COMPANY_TAB_LABEL_CORPORATEPOSITIONS_TITLE" />
          </label>
        ),
        tabName: translate("VIEW_COMPANY_TAB_LABEL_CORPORATEPOSITIONS_TITLE"),
        key: "corporatePositions",
      },
      {
        show: hasQuestionnaire(),
        disabled: false,
        component: (
          <label>
            <Text uuid="QUESTIONNAIRE_TSL" />
          </label>
        ),
        tabName: translate("QUESTIONNAIRE_TITLE"),
        key: "questionnaire",
      },
      {
        show: hasEconomicSubstance(),
        disabled: false,
        component: (
          <label>
            <Text uuid="VIEW_COMPANY_TAB_LABEL_ECONOMIC_SUBSTANCE_TITLE" />
          </label>
        ),
        tabName: translate("VIEW_COMPANY_TAB_LABEL_ECONOMIC_SUBSTANCE"),
        key: "economicSubstance",
        multiplePanels: true,
      },
    ];
  }, [JSON.stringify(data), JSON.stringify(questionnaireData)]);

  const tabs = useMemo(() => {
    return _.filter(originalTabs, "show");
  }, [JSON.stringify(data), originalTabs]);

  const hasAssetsAnRevenue = () => {
    const showHasShareIncorporated = _.get(data, "formationData.hasShareInIncorporated") !== undefined &&
      _.get(data, "formationData.hasShareInIncorporated") !== null

    const showHasShareInCompanyFormed = _.get(data, "formationData.hasShareInCompanyFormed") !== undefined &&
      _.get(data, "formationData.hasShareInCompanyFormed") !== null;

    return showHasShareIncorporated || showHasShareInCompanyFormed;
  };

  const indexStart = useMemo(() => {
    return params?.tab === "SIPS" ? 2 : 0;
  }, [params]);

  const categorizeFile = () => {
    openModal(
      ModalCategoriseDocument,
      {
        isCategorize: false,
        isEdit: false,
        isVisaOrPassportDocument: false,
      },
      "MODAL_CATEGORISE_DOCUMENT"
    );
  };

  const renderStatusTag = () => {
    if (isRenewal) {
      return <Chip label="Renewing" className={classes.statusTag} />;
    }
    return null;
  };

  return (
    <>
      {!isLoading && (
        <Details
          showIsInactive={!data?.isActive}
          showDissolved={isDissolved}
          indexStart={indexStart}
          title={_.get(data, "name")}
          managedByClara={managedByClara}
          lastUpdated={_.get(data, "updatedAt")}
          avatarName={_.get(data, "name")}
          avatarImg={_.get(data, "avatar")}
          onChangeAvatar={handleChangeAvatar}
          statusTag={renderStatusTag()}
          additionalTitle={
            <>
              {managedByClara && (
                <Tooltip
                  title="This company is managed by Clara."
                  classes={{ tooltip: classes.managedByClara }}
                  placement="bottom-start"
                >
                  <img
                    src="/img/icons/managed_by_clara.svg"
                    alt="Managed by Clara"
                  />
                </Tooltip>
              )}
              {groupCompanyClass?.getFieldInMatter("name") ? (
                <PincasIcon />
              ) : (
                ""
              )}
            </>
          }
          buttons={[
            <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
              <Button variant="card" onClick={categorizeFile} disabled={isLock}>
                {translate("VIEW_COMPANY_TAB_BUTTON_DETAILS_ADD_DOCUMENT")}
              </Button>
            </RoleAccess>,
            <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
              <Button
                variant="cardWarning"
                onClick={() => {
                  openModal(DeleteGroupCompany, {
                    handleCloseModal: closeModal,
                  });
                }}
                disabled={isLock}
              >
                {translate("VIEW_COMPANY_TAB_BUTTON_DETAILS_DELETE")}
              </Button>
            </RoleAccess>,
          ]}
          buttonsTabs={{
            other: [
              <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
                {hasAssetsAnRevenue() && (
                  <Button
                    disabled={isLock}
                    variant="card"
                    children={translate("VIEW_COMPANY_TAB_BUTTON_DETAILS_EDIT")}
                    onClick={() => {
                      openModal(ModalManageFutureLinkedCompanies, {
                        handleCloseModal: closeModal,
                        initialValues: data,
                      });
                    }}
                  ></Button>
                )}
              </RoleAccess>,
            ],
            natural: [
              <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
                <Button
                  variant="card"
                  disabled={isLock}
                  onClick={() => {
                    openModal(ModalNatureOfBusiness, {
                      handleCloseModal: closeModal,
                      paramsMutation: {
                        initialValues: _.get(data, "formationData", {}),
                        mode: "EDIT",
                      },
                    });
                  }}
                >
                  {translate("VIEW_COMPANY_TAB_BUTTON_DETAILS_EDIT")}
                </Button>
              </RoleAccess>,
            ],
            corporatePositions: [
              <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
                <Button
                  variant="card"
                  disabled={isLock}
                  children={translate("VIEW_COMPANY_TAB_BUTTON_DETAILS_EDIT")}
                  onClick={() => {
                    openModal(ModalCorporatePositions, {
                      edit: true,
                      handleCloseModal: closeModal,
                    });
                  }}
                ></Button>
              </RoleAccess>,
            ],
            equity: [
              <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
                {data?.kind === "INCORPORATED" && (
                  <Button
                    variant="card"
                    onClick={() =>
                      goTo(Constants.PAGES["capTable.mf.captable"], {
                        groupCompanyId: params.id,
                      })
                    }
                  >
                    Go to equity page
                  </Button>
                )}
                {data?.kind !== "INCORPORATED" &&
                  (data?.formationData?.sharesData?.shareholders?.length ?? 0) >
                  0 && (
                    <Button
                      variant="card"
                      disabled={isLock}
                      onClick={() => {
                        handleShareCapitalModal(data);
                      }}
                    >
                      {translate("VIEW_COMPANY_TAB_BUTTON_DETAILS_EDIT")}
                    </Button>
                  )}
              </RoleAccess>,
            ],
            questionnaire: [
              <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
                <Button
                  variant="card"
                  disabled={isLock}
                  onClick={() => {
                    openModal(TechLicenceModal, {
                      handleCloseModal: closeModal,
                      initialValues: {
                        isEdit: true,
                        groupCompanyId: data.id,
                        groupCompanyName: data.name,
                      },
                    });
                  }}
                >
                  {translate("VIEW_COMPANY_TAB_BUTTON_DETAILS_EDIT")}
                </Button>
              </RoleAccess>,
            ],
            economicSubstance: [
              <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
                <Button
                  variant="card"
                  disabled={isLock}
                  onClick={() => {
                    openModal(EditEconomicSubstanceInformationModal, {
                      handleCloseModal: closeModal,
                      initialValues: {
                        groupCompanyId: data.id
                      },
                    });
                  }}
                >
                  {translate("VIEW_COMPANY_TAB_BUTTON_DETAILS_EDIT")}
                </Button>
              </RoleAccess>,
            ],
          }}
          tabs={tabs}
          lock={isLock}
          collapseButton={true}
          tabContents={content3}
        />
      )}
    </>
  );
};

export default CompanyView;

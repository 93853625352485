import React, {useEffect} from 'react';
import {Constants} from '../../../../utils/constants'
import classes from "../ViewForms.module.scss";
import {useDispatch} from "react-redux";
import _ from "lodash";
import ServerConnect from "../../../../utils/ServerConnect";
import helper from "../../../../utils/helper";


const BankingServicesView = (props) => {

    const dispatch = useDispatch();
    const { mode, data } = props;

    const downloadHandler = (file) => {
        let url = ServerConnect.getUrlFile(file.file.id);
        ServerConnect.downloadFileAsDoc(url, file.file.name);
    }


    useEffect(() => {
    }, [,]);


    let renderYesOrNo = (value) => {
        if( typeof value !== 'boolean'){
            return ''
        }
        return !value ? 'No' : 'Yes'
    }

    const renderCountryName = (countryCode) => {
        return _.findKey(Constants.COUNTRYCODES, country => { return country == countryCode })
    }


    return (
        <React.Fragment>
            <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                <label>Banking Services</label>
            </div>
            <hr className={`${classes.Separator} ${classes.modifiedSeparator}`} />
            <div className={`${classes.ContainerRow}`}>
                <label>Bank account opening and operating authorities required:</label>
                <span>{renderYesOrNo(_.get(data, 'bankInformation.bankAccountOpening'))}</span>
            </div>
            <div className={`${classes.ContainerRow}`}>
                <label>Jointly or singly signed:</label>
                <span>{_.get(data, 'bankInformation.requireToSignJointly', '') }</span>
            </div>
            <div className={`${classes.ContainerRow}`}>
                <label>Signatories:</label>
                {_.get(data, 'authorizedSignatories', []) ? _.get(data, 'authorizedSignatories', []).map(signatory => {
                    return (<React.Fragment>
                       <label>{'      '}</label> <span>{_.get(signatory, 'firstName', '')}</span>   <span>{_.get(signatory, 'lastName', '')}</span><br />
                    </React.Fragment>
                    )
                }) : null}
            </div>
            <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                <label>Company Records</label>
            </div>
            <hr className={`${classes.Separator} ${classes.modifiedSeparator}`} />
            <div className={`${classes.ContainerRow}`}>
                <div className={`${classes.ContainerRow}`}>
                    <label>{helper.getTranslateTextByKey('KYC_REGISTERED_ADDRESS_FIELD_VIEW')}:</label>
                    <span>{renderCountryName(_.get(data, 'bankingAddress.country', ''))},<br />{_.get(data, 'bankingAddress.state', '')},<br />{_.get(data, 'bankingAddress.city', '')},<br />{_.get(data, 'bankingAddress.address', '')},<br />{_.get(data, 'bankingAddress.zipCode', '')}<br /></span>
                </div>
            </div>
        </React.Fragment>
    )
}

export default BankingServicesView;

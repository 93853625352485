import React, {useCallback, useMemo} from 'react';
import { useDispatch, useSelector } from "react-redux";
import TextareaInput from '../../../../../../inputs/Textarea/TextareaInput'
import Text from '../../../../../../text/Text/Text';
import SecondaryButton from '../../../../../../generic/Buttons/SecondaryButton/SecondaryButton';
import SubmitButton from '../../../../../../generic/Buttons/SubmitButton/SubmitButton';
import Checkbox from '../../../../../../inputs/Checkbox/Checkbox';
import { RowAdd, Botonera, HiddenFieldForm } from '../../../../../../forms/contents';
import ControllerInput from '../../../../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import Form from '../../../../../../forms/Form/Form';
import _ from 'lodash';
import Title from '../../../../../../text/Title/Title';
import NewModalForm from '../../../../../../templates/NewModalForm/NewModalForm';
import useTextLang from "../../../../../../../hooks/custom/useTextLang";
import useDeleteDocument from "../../../../../../../hooks/services/modules/Document/useDeleteDocument";
import useDeleteManualSip from "../../../../../../../hooks/services/modules/equity/useDeleteManualSip";
import classes from './DeleteGrant.module.scss';
import * as Yup from 'yup';
import useSession from "./../../../../../../../modules/session/hooks/useSession"
import {goToPage} from '../../../../../../../store/actions/page';
import {Constants} from '../../../../../../../v1/utils';

const DeleteSIP = ({ refresh, open, handleCloseModal, onComplete, initialValues, goTo, redirect, ...props }) => {
    
    const { startupId } = useSession();
	const dispatch = useDispatch();
    const form = useMemo(() => {
        // console.log(initialValues);
        return initialValues
    }, []);

    const onCompletedUpdate = () => {
        onComplete && onComplete()
      refresh &&  refresh()
    };

	const callbackBeforeEvict = useCallback(() => {
		return new Promise((resolve,reject)=>{
			if (redirect) {
				goTo ? goToPage(dispatch, goTo) : goToPage(dispatch, Constants.PAGES.MODULES.MAP.maps);
			}
			resolve(true)
		})
	},[dispatch, goTo, redirect])

	const { mutation: deleteDocument, loading } = useDeleteDocument({
		onCompleted: onCompletedUpdate,
		successMessage: "NOTIFICATION_SUCCESS_DELETED",
		paramsSuccessMessage: form,
		callbackBeforeEvict: callbackBeforeEvict
	});

    const { mutation: deleteManualSIP, loadingManualSIP } = useDeleteManualSip({
		onCompleted: onCompletedUpdate,
		successMessage: "NOTIFICATION_SUCCESS_DELETED",
		paramsSuccessMessage: form,
		callbackBeforeEvict: callbackBeforeEvict
	});

    const deleteEntity = async (values) => {
            if (_.get(initialValues, 'isManual', null)) {
                await deleteManualSIP({
                    startupId,
                    shareClassId: initialValues.shareClass.id,
                    shareIncentivePlanId: initialValues.id,
                    reason: values.reason
                });
            } else {
                await deleteDocument({
                    startupId,
                    agreementId: initialValues.id,
                    reason: values.reason
                });
            }

         handleCloseModal()
    };

    return (
        <Form
            defaultValues={{ confirmDelete: false }}
            schema={Yup.object().shape({
                reason: Yup.string().required('Required'),
                confirmDelete: Yup.bool()
                    .required('You must confirm this action')
                    .oneOf([true], 'You must confirm this action')
            })}
            onSubmit={deleteEntity}
        >
            <NewModalForm

                header={<Title><Text uuid={"MODAL_DELETE_SIP"} params={{ sip: _.get(initialValues, 'name') }} /></Title>}
                content={<React.Fragment>
                    <div className={classes.deleteInfo}>
                        <Text uuid={`${_.get(initialValues, 'isManual', null) ? 'MODAL_DELETE_MANUAL_SIP_INFO_TEXT' : 'MODAL_DELETE_SIP_INFO_TEXT'}`} params={{ sip: _.get(initialValues, 'name') }} />
                    </div>
                    <div className={classes.textArea}>
                        <RowAdd
                            asterisk={true}
                            label={"MODAL_DELETE_GRANT_REASON_LABEL"}
                            input={
                                <ControllerInput
                                    as={TextareaInput}
                                    name="reason"
                                    defaultValue={''}
                                    placeholder={"Please let us know the reason why you are deleting this SIP..."}
                                    onChange={(e) => {

                                    }}
                                />
                            } />
                    </div>
                    <div className={`${_.get(initialValues, 'isManual', null) && classes.Checkbox}`}>

                        <ControllerInput
                            as={Checkbox}
                            showErrorMessage={true}
                            name={'confirmDelete'}
                            classNameError={classes.errorCheckboxLabel}
                        />
                        <span className={classes.textCheckbox}>
                            <Text
                                uuid={"MODAL_DELETE_SIP_CHECKBOX_LABEL"}
                                params={{ sip: _.get(initialValues, 'name') }}
                            />
                        </span>
                    </div>

                </React.Fragment>}
                botonera={<React.Fragment>
                    <SecondaryButton onClick={() => handleCloseModal()}><Text uuid={'BUTTON_CANCEL'} /></SecondaryButton>
                    <SubmitButton
						labelLoading={useTextLang('BUTTON_DELETING')}
					><Text uuid={"BUTTON_DELETE"} /></SubmitButton>
                </React.Fragment>}
            />

        </Form >

    )
};





export default DeleteSIP;

import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import PrimaryButton from "../../../../../components/generic/Buttons/PrimaryButton/PrimaryButton";
import DocumentPreviewFile from "../../../../../components/generic/Documents/DocumentPreview/DocumentPreviewFile";
import Scrollbar from "../../../../../components/generic/Scrollbar/Scrollbar.js";
import { PanelUploaded } from "../../../../../components/modules/clara/startups/Document";
import Text from "../../../../../components/text/Text/Text";
import Title from "../../../../../components/text/Title/Title";
import useGetDocument from "../../../../../hooks/services/modules/Document/useGetDocument";
import Icon from "../../../../../modules/generic/components/Icon";
import RoleAccess from "../../../../../modules/security/components/RoleAccess/RoleAccess";
import useSession from "../../../../../modules/session/hooks/useSession";
import { Constants } from "../../../../../v1/utils";
import halfCircle from "./../../../../../images/half_circle_blue.svg";
import { updateParamsBreadCrumbs } from "./../../../../../store/actions/page";
import TitleDocumentUploaded from "./components/TitleDocumentUploaded";
import DetailsDocumentGeneratedSkeleton from "./DetailsDocumentGeneratedSkeleton";
import classes from "./Styles.module.scss";

const DetailsUpload = ({ ...props }) => {

  const params = useParams();
  const { startupId } = useSession();
  const dispatch = useDispatch();

  const { data, loading, refetch } = useGetDocument({
    agreementId: params.id,
    startupId,
  });
  
  const fileName = _.get(data, "file.name");
  const fileType = fileName ? fileName.split(".").pop() : "";

  useEffect(() => {
    updateParamsBreadCrumbs(dispatch, { fullName: _.get(data, "name", "") });
  }, [_.get(data, "name", "")]);

  useEffect(() => {
    //TODO REDUX: Update data info??
    // updateDataInfo(dispatch, `document`, data);
  }, [_.get(data, "id")]);

  return (
    <>
      {loading ? (
        <DetailsDocumentGeneratedSkeleton />
      ) : (
        <div className={classes.DetailsDocument}>
          <TitleDocumentUploaded title={_.get(data, "name", "")} document={data} />
          <div className={classes.containerDetailsUpload}>
            <div className={classes.containerPanel}>
              <PanelUploaded data={data} />
            </div>
            <div
              className={
                _.includes(
                  ["jpg", "jpeg", "gif", "png", "png", "svg", "bmp", "pdf", "zip"],
                  fileType.toLowerCase()
                )
                  ? classes.containerPreviewV4
                  : classes.containerPreviewV4
              }
            >
              {_.get(data, "freemiumValid") ? (
                <DocumentPreviewFile
                  fileId={_.get(data, "file.id")}
                  fileName={fileName}
                  fileType={fileType}
                  defaultScale={0.7}
                />
              ) : (
                <Scrollbar className={classes.Scrollbar}>
                  <div className={classes.Subscriptions}>
                    <img
                      className={classes.circleImage}
                      src={halfCircle}
                      alt="Clara"
                    />
                    <div>
                      <Icon
                        icon={"Convertible-Loan-Note"}
                        size={"4.3rem"}
                        isClara={true}
                      />
                      <Title>
                        <Text uuid={"SUSCRIPTION_MODAL_DOCUMENT_DETAIL_TITLE"} />
                      </Title>
                      <div className={classes.ContentText}>
                        <Text uuid={"SUSCRIPTION_MODAL_DOCUMENT_DETAIL_CONTENT"} />
                      </div>
                      <div className={classes.ContentBotonera}>
                        <RoleAccess action={Constants.ACTIONS.MANAGE_SUBSCRIPTIONS}>
                          <PrimaryButton
                            onClick={() => {
                              dispatch({
                                type: "GO_TO",
                                page: Constants.PAGES.upgrade,
                              });
                            }}
                            className={classes.greenButton}
                            type="button"
                          >
                            <Text uuid={"BUTTON_UPGRADE"} />
                          </PrimaryButton>
                        </RoleAccess>
                      </div>
                    </div>
                  </div>
                </Scrollbar>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DetailsUpload;

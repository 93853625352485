import React, { FC, useEffect, useState } from 'react';
import Modal from "../../../../../../v1/components/Modal/Modal/Modal";
import classes from '../ChooseDocumentsView.module.scss';
interface Props {
    isOpen: boolean;
    docType: string;
    parentDoc: string;
    handleCreateDoc: () => void;
    setOpen: (isOpen: boolean) => void;
}
const ModalCreateDocument:FC<Props> = ({isOpen,docType,parentDoc,handleCreateDoc,setOpen}) => {
    useEffect(() => {
        setOpen(isOpen);
    }
    , [isOpen]);
    return <>
    <Modal
          open={isOpen}
          showHeader={false}
          handleClose={() => setOpen(false)}
        >
          <div className={classes.Modal}>
            <h1>You are unable to create this</h1>
            <p>To create a {docType} you first need to have done the following</p>
            {
              <li>Create a {parentDoc}</li>
            }
            <button className={`genericWhite`} style={{ marginRight: '1rem' }} onClick={() => setOpen(false)}>
              Close
            </button>
            <button className={`genericBlue`}
              onClick={handleCreateDoc}>Create {parentDoc}</button>
          </div>
        </Modal>
    </>
}
export default ModalCreateDocument;
import React, {FC, useMemo, useRef} from 'react'
import * as  Yup from 'yup'
import {map} from 'lodash'
import useTranslate from "../../../../generic/hooks/useTranslate";
import Form from "../../../../../components/forms/Form/FormL";
import ControllerInput from "../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import SubmitButton from "../../../../generic/components/Buttons/SubmitButton";
import TemplateFormDefault from "../../../../generic/templates/Modal";
import FormProps from "../../../../generic/forms/interfaces/FormProps";
import TemplateLabel from "../../../../generic/components/Inputs/LabelInput";
import TextInput from "../../../../../components/inputs/Text/TextInput";
import classesModule from "../../../onBoarding/company/forms/CompanyForm/classes.module.scss";
import CalendarInputDate from "../../../../../components/inputs/Calendars/CalendarInputDate";
import Typography from "../../../../generic/components/Texts/Typography";
import AutocompleteClara from '../../../../generic/components/Inputs/Autocomplete';
import Currency from "src/components/inputs/Currency/Currency";

const schema = Yup.object().shape({
    amount: Yup.number().nullable().required('Required').typeError('Must be a number'),
    price: Yup.number().nullable().required('Required').typeError('Must be a number'),
    issuedDate: Yup.date().nullable().required('Required').typeError('Add a valid date'),
    newShareClass: Yup.object().nullable().required('Required')

})
interface ShareFormProps extends FormProps {
    companyName: string
    currency: string
    shareClassList: any[]
}
const ShareForm: FC<ShareFormProps> = ({ initialValues, currency, shareClassList, tooltips, onCompleteSubmit, Template = TemplateFormDefault, buttons = {}, buttonSubmitProps, propsTemplate, companyName }: ShareFormProps) => {
    const { translate } = useTranslate();
    const refForm = useRef()
    const shareClasses = useMemo(() => {
        return map(shareClassList, sh => ({ value: { id: sh.id }, label: sh.name }))
    }, [shareClassList]);


    const createShareClass = (value) => {
        return { name: value }
    }
    return (
        <React.Fragment>
            <Form
                initialValues={initialValues || {}}
                schema={schema}
                onlySendDirty={false}
                onSubmit={onCompleteSubmit}
                ref={refForm} optionsForm={{ mode: "onChange" }}
            >
                <Template props={propsTemplate} buttons={{ submit: <SubmitButton{...buttonSubmitProps}>Next</SubmitButton>, ...buttons }}>
                    <Typography component={'h5'} color={'black'}>
                        {companyName}
                    </Typography>
                    <TemplateLabel label={translate('MODULES.EQUITY.FORMS.SHARE_FORM.AMOUNT')} isRequired={true}>
                        <ControllerInput
                            as={TextInput}
                            name="amount"
                            type={'number'}
                            //defaultValue={''}
                            placeholder={translate('MODULES.EQUITY.FORMS.SHARE_FORM.PLACEHOLDER_NUMBER')}
                            onChange={(e) => {

                            }}
                        />
                    </TemplateLabel>
                    <TemplateLabel label={translate('MODULES.EQUITY.FORMS.SHARE_FORM.PRICE')} isRequired={true}>
                        <ControllerInput
                            render={Currency}
                            name="price"
                            type={'share'}
                            defaultValue={""}
                            currency={
                                currency
                            }
                            onChange={(e) => {}}
                        />
                    </TemplateLabel>
                    <TemplateLabel label={translate('MODULES.EQUITY.FORMS.SHARE_FORM.SHARE_CLASS')} isRequired={true}>
                        <ControllerInput
                            render={AutocompleteClara}
                            options={shareClasses}
                            placeholder={translate('MODULES.EQUITY.FORMS.SHARE_FORM.SELECT_PLACEHOLDER')}
                            name={"newShareClass"}
                            getCreateValue={createShareClass}
                        />
                    </TemplateLabel>
                    <TemplateLabel
                        label={translate('MODULES.EQUITY.FORMS.SHARE_FORM.ISSUED_DATE')}
                        className={classesModule.LabelTitle}
                        isRequired={true}
                    >
                        <ControllerInput
                            as={CalendarInputDate}
                            name='issuedDate'
                            placeholder={translate('MODULES.EQUITY.FORMS.SHARE_FORM.PLACEHOLDER_DATE')}
                        />
                    </TemplateLabel>

                </Template>
            </Form>
        </React.Fragment>
    )
}

export default ShareForm

import useLazyQuery from 'src/hooks/custom/useLazyQuery';

const GET_ULTIMATE_BENEFICIAL_OWNER = `
  query getUltimateBeneficialOwner($startupId: ID!, $groupCompanyId: ID!, $entityId: ID!) {
    ultimateBeneficialOwner: getUltimateBeneficialOwner(startupId: $startupId, groupCompanyId: $groupCompanyId, entityId: $entityId) {
      id
      name
      type
      mailingAddress {
        useRegisteredAddress
        street
        city
        state
        country {
          code
          alpha3
          name
        }
        zipCode
        type
      }
      taxJurisdiction {
        code
        alpha3
        name
      }
      taxRegistrationNumber
    }
  }
`;

export const useGetUltimateBeneficialOwner = (variables, config) => {
  const { manualQuery, loading, error, data, refetch } = useLazyQuery(GET_ULTIMATE_BENEFICIAL_OWNER, variables, config);
  return { loading, error, data, refetch, manualQuery };
};

export default useGetUltimateBeneficialOwner;

import React, { FC } from "react";
import classesModule from './SquareBlock.module.scss';

/**
 * SquareBlock
 */

 export interface SquareBlockProps {
  /** Property children */
  children?: any,
  /** Property key */
  key?: number,
  /** Property payMode */
  payMode?: boolean,
}

const SquareBlock: FC<SquareBlockProps> = ({
  children,
  payMode,
  key,
}:SquareBlockProps) => {

  return (
    <div className={` ${classesModule.SquareBlock} ${payMode && classesModule.payMode} `} key={key}>
      {children}
    </div>
  );
}
export default SquareBlock;
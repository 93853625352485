import empty from "../modules/empty/constants"
import checkout from "../modules/checkout/constants"
import generic from "../modules/generic/constants"
import onboarding from "../modules/Onboarding/constants"
import startup from "../modules/startup/Constants"
import assistanceV4 from "../modules/assistanceV4/constants"
import formation from "../modules/formation/constants"
const constants = {
    CHECKOUT:checkout,
    EMPTY:empty,
    GENERIC: generic,
    ONBOARDING: onboarding,
    STARTUP: startup,
    ASSISTANCE: assistanceV4,
    FORMATION: formation
}
export default constants;
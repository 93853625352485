import React, { FC, useState, useMemo } from 'react'
import useTranslate from '../../../../../generic/hooks/useTranslate'
import AssistanceContents from '../../../AssistanceContents'
import TabBubblesContainer from '../../../TabBubblesContainer'
import Typography from '../../../../../generic/components/Texts/Typography'
import Bubble from '../../../Bubble'
import BubbleContents from '../../../BubbleContents'
import _ from 'lodash'
import MapTasksCard from '../../../../../../modules/assistanceV4/components/AssistanceCards/MapTasksCard'
import InFormationCard from '../../../AssistanceCards/InFormationCard'
import GenerateDocumentsTooltips from '../GenerateDocumentsTooltips'

export interface GenerateDocumentsTasksProps {
  tasks: any
  entity: any
}

const GenerateDocumentsTasks: FC<GenerateDocumentsTasksProps> = ({
  tasks: dataTasks,
  entity,
  ...props
}: GenerateDocumentsTasksProps) => {

  const { translate } = useTranslate()
  const [showCongrats, setShowCongrats] = useState(false)

  const { isLocked } = useMemo(() => {
    let isLocked = _.get(entity, 'isLocked', false)
    return { isLocked }
  }, [JSON.stringify(entity)])

  return (
    <AssistanceContents>
      <TabBubblesContainer>
        {isLocked && (
          <>
            <InFormationCard type='document' />
          </>
        )}
        {(!isLocked && !showCongrats) && (
          <GenerateDocumentsTooltips />
        )}
        {!isLocked && (
          <>
            {showCongrats ? (
              <Bubble animation={'congrats'}>
                <Typography variant={'s'} weight={'semibold'}>
                  {translate(
                    'MODULES.ASSISTANCEV4.ASSISTANCE_GENERATED_DOCUMENTS_CONGRATS_HIT'
                  )}
                </Typography>
              </Bubble>
            ) : (
              <>
                <Bubble>
                  <BubbleContents>
                    <Typography variant={'s'} weight={'regular'}>
                      {translate(
                        'MODULES.ASSISTANCEV4.ASSISTANCE_GENERATE_DOCUMENTS_HIT'
                      )}
                    </Typography>
                  </BubbleContents>
                </Bubble>
              </>
            )}
            {_.map(dataTasks, group => {
              const completed = _.filter(
                group.tasks,
                task => task.status == 'completed'
              )
              if (
                _.get(completed, 'length', 0) == _.get(group, 'tasks.length', 0)
              ) {
                return null
              }
              return (
                <MapTasksCard
                  open={
                    !(
                      _.get(completed, 'length', 0) ===
                      _.get(group, 'tasks.length', 0)
                    )
                  }
                  title={translate(
                    'MODULES.ASSISTANCEV4.ASSISTANCE_MAP_TASK_NAME',
                    {
                      name: group.group,
                      completed: _.get(completed, 'length', 0),
                      totals: _.get(group, 'tasks.length', 0)
                    }
                  )}
                  titleIcon={group.icon}
                  tasks={group.tasks}
                ></MapTasksCard>
              )
            })}
          </>
        )}
      </TabBubblesContainer>
    </AssistanceContents>
  )
}

export default GenerateDocumentsTasks

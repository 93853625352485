import useSession from 'src/modules/session/hooks/useSession';
import useGetDocumentLinkByAccelerator from './graphql/useGetDocumentLinkByAccelerator'
import useGetAcceleratorDocumentsLink from './graphql/useUpdateAcceleratorData';

const useServices = () => {
  const { startup } = useSession();
  const query = useGetDocumentLinkByAccelerator({}, {})
  const mutation = useGetAcceleratorDocumentsLink({}, {})

  const getLink = async () => {
    const data = await query({ variables: { code: startup?.acceleratorDef?.code } })
    console.log(data)
    return {
      documentsLink: data.data.getAcceleratorDef.documentListCode
    }
  }

  return {
    Save: async (context, event) => {
      await mutation({ variables: { startupId: startup.id } })
    },
    getLink
  }
}
export default useServices;
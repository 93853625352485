import useLazyQuery from "src/hooks/custom/useLazyQuery";
const GET_GROUP_COMPANY_AND_STAKEHOLDERS = `
query getGroupCompanyAssetsAndRevenue($startupId: ID!, $groupCompanyId: ID!) {
  getGroupCompany(startupId: $startupId, groupCompanyId: $groupCompanyId) {
    id
    name
    assetsAndRevenue {
      sharesInIncorporatedCompanies{
          id
          name
          avatar
      }
			isSharesInNotYetFormedCompanies{
        question
        description
      }
    }
  }
}`

const useGetGroupCompanyAndStakeholders =(variables) => {
    const { manualQuery, loading, error, data, refetch } = useLazyQuery(GET_GROUP_COMPANY_AND_STAKEHOLDERS, variables);
    
    const query = async (variables) => {
        const { data } = await manualQuery({variables})
        return data['getGroupCompany']
    }

    return {
        query,
        loading,
        error,
        data,
        refetch
    }
}
export default useGetGroupCompanyAndStakeholders
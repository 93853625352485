import {RoleFields} from "../../../modules/stakeholder/graphql/typePolicies";
import {NoticesFragment} from "../../../modules/startup/Stakeholder/graphql/fragments";

const mutation = `mutation upsertGroupCompany(
  $startupId: String
  $groupCompanyId: String
  $groupCompany: GroupCompanyInputType
) {
  upsertGroupCompany(
    startupId: $startupId
    groupCompanyId: $groupCompanyId
    groupCompany: $groupCompany
  ) {
    name
  }
}
`;
const eliminate = `mutation deleteGroupCompany(
    $startupId: String!
    $groupCompanyId: String!
){
    deleteGroupCompany(
        startupId: $startupId  
        groupCompanyId : $groupCompanyId
    ){name}
}`;

const query = `
  ${RoleFields}
  ${NoticesFragment}
  query getGroupCompany(startupId:"{{startupId}}", groupCompanyId:"{{groupCompanyId}}") {
    id
    name
    avatar
    title
    type
    isHoldingCompany
    jurisdiction
    country { name code }
    registeredNumber
    registeredAddress {
      street
      city
      state
      country {name code alpha3}
      zipCode
    }
    hasSameWorkingAddress
    recipientNotice {...NoticesFragment}
     workingAddress {
      street
      city
      state
      country {code name alpha3}
      zipCode
    }
    certificateOfIncorporation{
      id
      name
      size
      type
    }
    articlesOfAssociation
    documents {
      name
      id
      kind
      status
      type{
        label
        code
      }
      groupCompanies{
        groupCompany{
          avatar
          name
        }
      }
      stakeholders{
        avatar
        fullName
      }
       subCategories
      
      file {
        id
        name
        size
        type
        
      }
    
    }
    incorporation_date
      shareClass{
      id
      defaultShare
      name
      authorizedShares
      allocatedShares
      shareIncentivePlans{
        id
        name
        totalShares
        issuedShares
        document {
          id name
        }
        authorizedOptions
      }
      incentivePlans{
        name
        authorizedOptions
        document{
          id name
        }
      }
     }
     transactionCurrency
     directors{
       id
       avatar
       roles {   id
        name
        groupCompany { id name}
        stakeholder { id } }
       fullName
     }
     extraCurrencies
  
      issuedEquity {
        
        holder {
          ... on GroupCompany {
            id
            name
            avatar
          }
          ... on StakeHolder {
            id
            fullName
            avatar
          }
        }
        equity {
          id
          amount
          price
          type
          documents {id name status }
          issuedDate
          shareClass{
            name
            id
            defaultShare
          }
          shareIncentivePlan{
            id
            name 
          }
          currency
          
        }
     
      }

      issuedConvertibles {
        holder {
        ... on StakeHolder {
              id
              fullName
              avatar
            }
        }
  
        convertible {
          id
          currency
          amount
          discountRate
          valuationCap
          interestRate
          issuedDate
          documents {id name status }
        }
      }
      issuedWarrants {
        holder {
        ... on StakeHolder {
              id
              fullName
              avatar
            }
        }
  
        warrant {
         id
         amount
         valuation
         currency
         issuedDate
         documents { id name type {label} }
        }
      }
     }  
   }`;

const queryGetGroupCompanies = `{stakeholderMap(startupId: "{{startup_id}}") {
  groupCompanies{
    id
    hasAgreements
    roles {
      id
      groupCompany{
        id
      }
      stakeholder{
        id
      }
    }
    avatar
    name
    title
    jurisdiction
    transactionCurrency
    country { name code alpha3 } 
    registeredNumber 
    isHoldingCompany
    type
    documents {
      name
      file {
         id
         name
         size
         type     
       }
       type {
          label
        }
        subCategories
        groupCompanies {
          groupCompany {name}
        }
        stakeholders {
          fullName
        }
    }
  }

}
}`
export {
  mutation,
  eliminate,
  query,
  queryGetGroupCompanies
}

import _ from 'lodash';
import React, { useMemo, useRef, useState } from 'react';
import Checkbox from "src/components/inputs/Checkbox/Checkbox";
import TextareaInput from 'src/components/inputs/Textarea/TextareaInput';
import useGetGroupCompanies from 'src/hooks/services/modules/groupCompany/useGetGroupCompanies';
import CountryJurisdiction from 'src/modules/generic/components/CountryJurisdictionSelector';
import AutocompleteClara from "src/modules/generic/components/Inputs/Autocomplete";
import TemplateLabel from 'src/modules/generic/components/Inputs/LabelInput';
import useSession from 'src/modules/session/hooks/useSession';
import * as Yup from 'yup';
import Form from "../../../../components/forms/Form/FormL";
import TextInput from "../../../../components/inputs/Text/TextInput";
import SubmitButton from "../../../generic/components/Buttons/SubmitButton";
import ControllerInput from "../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import useTranslate from "../../../generic/hooks/useTranslate";
import TemplateFormDefault from "../../../generic/templates/Modal";




const schema = Yup.object().shape({
  companyName: Yup.object().nullable().when("noHasOperatingCompany", {
    is: false,
    then: Yup.object().required("This field is required").nullable().typeError("This field is required"),
    otherwise: Yup.object().nullable(),
  }),
  country: Yup.string().nullable().when("noHasOperatingCompany", {
    is: false,
    then: Yup.string().required("This field is required").nullable().typeError("This field is required"),
    otherwise: Yup.string().nullable(),
  }),
  jurisdiction: Yup.string().nullable().when("noHasOperatingCompany", {
    is: false,
    then: Yup.string().required("This field is required").nullable().typeError("This field is required"),
    otherwise: Yup.string().nullable(),
  }),
  noHaveWebsite: Yup.boolean(),
  website: Yup.string()
    .when("noHaveWebsite", {
      is: false,
      then: Yup.string().url("It must be a valid link").required("This field is required").nullable().typeError("This field is required"),
      otherwise: Yup.string().notRequired().nullable(),
    }),
  activity: Yup.string().required("This field is required").nullable().typeError("This field is required"),
});

const FormBusinessActivity = ({
  initialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
}) => {

  const { translate } = useTranslate();
  const { startupId } = useSession()
  const refForm = useRef();
  
  const [disabled, setDisabled] = useState(false);
  const [disabledMedia, setDisabledMedia] = useState(false);
  const [disabledOperationCompany, setDisabledOperationCompany] = useState(false);
  const { data: groupCompanies, loading } = useGetGroupCompanies({ variables: { startupId , filters: {kind: "INCORPORATED"}} });


  const createOperatingCompany = (value: string) => {
    return { fullName: value };
  };
  const handleChangenoHasOperatingCompany = (value) => {
    setDisabledOperationCompany(value);
    if (refForm?.current) {
      refForm.current.setValue("companyName", null);
      refForm.current.setValue("website", null);
      refForm.current.setValue("country", null);
      refForm.current.setValue("jurisdiction", null);
      refForm.current.trigger(["companyName", "country", "jurisdiction"]);
    }
  }
  const handleChangeMedia = (value) => {
    setDisabledMedia(value);
    if (value && refForm?.current) {
      refForm.current.setValue("website", null);
      refForm.current.trigger("website");
    }
  }
  const handleChangeGroupCompany = (value) => {
    if (!value?.id || value === null) {
      setDisabled(false);
      if (refForm?.current) {
        refForm.current.setValue("country", null);
        refForm.current.setValue("jurisdiction", null);
      }
    } else {
      setDisabled(true);
      if (refForm?.current) {
        refForm.current.setValue("country", value?.country?.code);
        refForm.current.setValue("jurisdiction", value?.jurisdiction);
      }
    }
  };
  const companiesList = useMemo(() => {

    const result = _.map(groupCompanies, gc => ({ value: { id: gc.id, country: gc.country, jurisdiction: gc.jurisdiction }, label: gc?.name ?? "" }));
    return result;
  }, [groupCompanies]);
  const transformInitialValues = useMemo(() => {
    let company = {}
    if (initialValues?.businessActivity?.operatingCompany) {
      company = {
        fullName: initialValues?.businessActivity?.operatingCompany?.name ,
        label: initialValues?.businessActivity?.operatingCompany?.name ,
        id: initialValues?.businessActivity?.operatingCompany?.id,
        country: initialValues?.businessActivity?.operatingCompany?.country,
        jurisdiction: initialValues?.businessActivity?.operatingCompany?.jurisdiction
      }
      setDisabledOperationCompany(false);
      setDisabled(false);
    } else {
      setDisabledOperationCompany(true);
      setDisabled(true);
    }
    const noHaveWebsite = !initialValues?.businessActivity?.operatingCompany?.linkedinURL
    setDisabledMedia(noHaveWebsite);
    return {
      companyName: company,
      noHasOperatingCompany: !initialValues?.businessActivity?.operatingCompany,
      country: initialValues?.businessActivity?.operatingCompany?.country,
      jurisdiction: initialValues?.businessActivity?.operatingCompany?.jurisdiction,
      noHaveWebsite: noHaveWebsite,
      website: initialValues?.businessActivity?.operatingCompany?.linkedinURL,
      activity: initialValues?.businessActivity?.activity
    }
  }, [initialValues])
  return (
    <>
      <Form
        initialValues={transformInitialValues || {}}
        schema={schema}
        onlySendDirty={false}
        onSubmit={onCompleteSubmit}
        ref={refForm}
        optionsForm={{ mode: "onChange" }}
      >
        <Template
          buttons={{
            ...buttons,
            submit: (
              <SubmitButton {...buttonSubmitProps}>{initialValues ? translate("MODULES.CLARA.BTN_SAVE_CHANGES") : translate('GENERIC_BUTTON_ADD_GROUP')}</SubmitButton>
            ),
          }}
          props={{ ...propsTemplate, subTitle: undefined }}
        >
          <TemplateLabel
            label={"Operating company name"}
            isRequired
          >
            {!loading && (
              <ControllerInput
                render={AutocompleteClara}
                getCreateValue={createOperatingCompany}
                disabled={disabledOperationCompany}
                name="companyName"
                options={companiesList}
                placeholder={"Type here"}
                onChange={handleChangeGroupCompany}
              />
            )}
          </TemplateLabel>
          <TemplateLabel>
            <ControllerInput
              render={Checkbox}
              name="noHasOperatingCompany"
              label={
                "This group company does not have an operating company."
              }
              variant="col_2"
              onChange={handleChangenoHasOperatingCompany}
            />
          </TemplateLabel>
          <CountryJurisdiction disabled={disabled || disabledOperationCompany} />
          <TemplateLabel label={"Website or social media link"} isRequired>
            <ControllerInput
              render={TextInput}
              name="website"
              disabled={disabledMedia || disabledOperationCompany}
              placeholder={"Type here"}
            />
          </TemplateLabel>
          <TemplateLabel>
            <ControllerInput
              render={Checkbox}
              disabled={disabledOperationCompany}
              name="noHaveWebsite"
              label={
                "The operating company does not have a website or any social media ccounts."
              }
              variant="col_2"
              onChange={handleChangeMedia}
            />
          </TemplateLabel>
          <TemplateLabel isRequired={true} label={translate('MODULES.GROUP_COMPANY.FORM.NATURE_OF_BUSINESS.ACTIVITY_INPUT')}>
            <ControllerInput
              name='activity'
              render={TextareaInput}
              placeholder={translate('MODULES.GROUP_COMPANY.FORM.NATURE_OF_BUSINESS.ACTIVITY_PLACEHOLDER')}
            /></TemplateLabel>

        </Template>
      </Form>
    </>
  )
}

export default FormBusinessActivity;

import _ from 'lodash'
import React from 'react'
import Scrollbar from '../../../../../components/generic/Scrollbar/Scrollbar'
import classesModule from './classes.module.scss'
import { TemplateFormComponentProps, TemplateComponent } from './../../Modal'
import Typography from '../../../components/Texts/Typography'
import TemplateWizardSkeleton from './TemplateWizardSkeleton'
import useLoading from '../../../../generic/hooks/useLoading'
import { TemplateWizardProps, TemplateWizardButtonsProps } from '.'
import ControllerInput from '../../../components/Inputs/ReactHookForm/ControllerInput'
import DocumentPreviewFile from "src/components/generic/Documents/DocumentPreview/DocumentPreviewFile";
import FileUploadList from 'src/modules/generic/components/Inputs/FileUpload';

export interface TemplateWizardPreviewFullProps extends TemplateWizardProps {
  title: string
  subTitle: string
  skeleton: React.ReactNode,
  canEditDocument,
  fileUploadProps?: {
    fileId: string
    fileType: string
    fileName: string
    defaultScale: any
  }
}

const TemplateWizardPreviewFull: TemplateComponent<
  TemplateWizardPreviewFullProps,
  TemplateWizardButtonsProps
> = ({
  children,
  buttons = {},
  classNameBotonera,
  className,
  loading,
  props: {
    title = '',
    subTitle = '',
    skeleton = null,
    canEditDocument,
    fileUploadProps,
  }
}: TemplateFormComponentProps<
  TemplateWizardPreviewFullProps,
  TemplateWizardButtonsProps
>) => {

    const { isLoading } = useLoading();
  
    return (
      <React.Fragment>
        <div className={`${classesModule.TemplateWizardExtend} ${className}`}>
          {isLoading && (
            <TemplateWizardSkeleton
              skeleton={skeleton}
              subTitle={subTitle}
              title={title}
            />
          )}
          <div>
            <div className={`${classesModule.allContainer}`}>
              {(subTitle || title) && (
                <div className={`${classesModule.headerTitle}`}>
                  {subTitle && (
                    <Typography
                      component={'label'}
                      variant={'body'}
                      color={'blue'}
                    >
                      {subTitle}
                    </Typography>
                  )}
                  {title && (
                    <Typography
                      component={'h1'}
                      color={'black'}
                      className={`${classesModule.SpacerTitle}`}
                    >
                      {title}
                    </Typography>
                  )}
                </div>
              )}
              <Scrollbar className={`${(!subTitle || !title) ? classesModule.scrollSectionExtended : classesModule.scrollSection}`}>
                <div className={`${classesModule.GridTemplateWizardPreviewExpended}`}>
                  <div className={`${classesModule.Form}`}>{children}</div>
                  <div className={`${classesModule.previewFull}`}>
                    {canEditDocument && (
                      <Typography color="dark-grey" variant="body" className={classesModule.uploadTitleSpace}>
                        Upload document
                        <span style={{ color: "red" }}>*</span>
                      </Typography>
                    )}
                    <ControllerInput
                      render={!canEditDocument ? DocumentPreviewFile : FileUploadList}
                      {...fileUploadProps}
                      name="files"
                      limit={1}
                      className={ !canEditDocument && `${classesModule.ClassFileUpload2}`}
                    />
                  </div>
                </div>               
              </Scrollbar>
            </div>
            <div className={`${classesModule.Botonera} ${classNameBotonera}`}>
              <div className={classesModule.LeftButtons}>
                {buttons.previous}
                {_.map(buttons.anotherLeft, button => {
                  return <React.Fragment>{button}</React.Fragment>
                })}
              </div>
              <div className={classesModule.RightButtons}>
                {_.map(buttons.anotherRight, button => {
                  return <React.Fragment>{button}</React.Fragment>
                })}
                {buttons.cancel}
                {buttons.submit}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

export default TemplateWizardPreviewFull

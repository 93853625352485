import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import Button from '../../../generic/components/Buttons/Button'
import useSession from '../../../../modules/session/hooks/useSession'
import ModalAssistant, { ModalAssistantProps } from '../../../generic/components/Modal/ModalAssistant'
import useModal from '../../../generic/hooks/useModal'
import useTranslate from '../../../generic/hooks/useTranslate'
import TemplateWizard from '../../../generic/templates/Modal/TemplateWizard'
import Form from "../Forms/FormDetailCollaborator"
import useInvateCollaborator from "../../../../hooks/services/modules/stakeholders/useInvateCollaborator"
import _ from 'lodash'
interface ModalInviteCollaboratorProps extends ModalAssistantProps {
    paramsMutation: object,
    onClose(): void;
}

const ModalInviteCollaborator: FC<ModalInviteCollaboratorProps> = ({ children, open: openParam = false, paramsMutation, ...props }: ModalInviteCollaboratorProps) => {

    const [open, setOpen] = useState(openParam);

    const dispatch = useDispatch();
    const {id} = useParams<{id:string}>()
    const { translate } = useTranslate();
    const { startupId } = useSession();
    const { closeModal: onClose } = useModal()
    const [invateCollaborator] = useInvateCollaborator({})


    useEffect(() => {
        setOpen(openParam);
    }, [openParam])
    const handleClose = (values) => {
        onClose()
        setOpen(false);
    }
    const handleCompleteSubmit = async values => {
        const variables = {
            stakeholderId: id,
            startupId,
            sharingProfile: { ..._.omit(values,'profession'), "role": "USER_COLLABORATOR", }
        }

        await invateCollaborator({ variables });
        onClose()

    }

    return (
        <ModalAssistant open={open}>
             <Form
                onCompleteSubmit={handleCompleteSubmit}
                Template={TemplateWizard}
                propsTemplate={{
                    title: 'Add their details',
                    subTitle: 'Invite collaborator'
                }}
                initialValues={{...paramsMutation}}
                buttons={{ cancel: <Button onClick={handleClose} variant="secondary">Cancel</Button> }}>
            </Form>

        </ModalAssistant>

    )
}

export default ModalInviteCollaborator;





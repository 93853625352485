import React from 'react'
import * as Yup from 'yup'
import Form from '../../../../components/forms/Form/Form'
import ControllerInput from '../../../generic/components/Inputs/ReactHookForm/ControllerInput'
import SubmitButton from '../../../generic/components/Buttons/SubmitButton'
import FormProps from '../../../generic/forms/interfaces/FormProps';
import TemplateFormDefault from "../../../generic/templates/Modal";
import CheckboxButtonSimple from '../../../generic/components/Inputs/CheckboxButtonSimple'
import { CheckboxButtonOptions } from '../../../generic/components/Inputs/CheckboxButton';
import cls from "./Form.module.scss";

const schema = Yup.object().shape({
  type: Yup.string().nullable().required('This field is required')
})

const FormSelectCompany: React.FC<FormProps> = ({
  initialValues: defaultValues = {},
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate
}) => {

  const options: CheckboxButtonOptions[] = React.useMemo(() => {
    const opts = [
      { label: 'ADGM', value: "EMPLOYMENT_AGREEMENT_ADGM" },
      { label: 'DIFC', value: 'EMPLOYMENT_AGREEMENT_DIFC' },
      // { label: 'UK', value: 'EMPLOYMENT_AGREEMENT_UK' }
    ];
    return opts;
  }, []);

  return (
    <Form
      defaultValues={defaultValues}
      schema={schema}
      onSubmit={onCompleteSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <Template
        buttons={{ 
          ...buttons,
          submit: <SubmitButton  {...buttonSubmitProps}>Next</SubmitButton>
        }}
        props={propsTemplate}
      >
        <ControllerInput
          render={CheckboxButtonSimple}
          name='type'
          variant='col_1'
          options={options}
          onChange={value => {
          }}
          className={cls.CheckboxButton}
          classNameButton={cls.buttonCheckBox}
        />
      </Template>
    </Form>
  )

}

export default FormSelectCompany

import React from "react";
import cls from "./classes.module.scss";
import { StepsDots } from "../../../components/Steps";

const TemplateWarningModal = ({
  buttons,
  children,
  steps = [],
  actualStep,
  setActualStep,
  warning
}) => {

  return (
    <div className={`${cls["container-tour"]} ${cls["container-tour-ratio"]}`}>

      <div className={cls["container-blocks-modal-tour"]}>
        <div>
          {warning && (<div className={cls["container-blocks-warning"]} dangerouslySetInnerHTML={{__html: warning}} />)}
        </div>
        <div>
          {children}
        </div>
      </div>
      <div className={`${cls["container-buttons-modal-tour"]}`}>
        <StepsDots
          className={"container-steps-dots"}
          steps={steps}
          actualStep={actualStep}
          setStep={setActualStep}
        />
        <div className={"d-flex flex-row mb-0"}>
          {buttons.previous}
          {buttons.submit}
        </div>
      </div>
    </div>
  );
};

export default TemplateWarningModal;

import _ from "lodash";
import React, { FC, useMemo, useEffect, useCallback } from 'react';
import Loading from "../../../../../../../../../components/generic/Loading/Circular/Circular";
import Button from "../../../../../../../../generic/components/Buttons/Button";
import { useDispatch } from "react-redux";
import { StepProps } from "../../../../../../../../generic/components/Wizard/Step";
import useCustomMachine from "../../../../../../../../generic/hooks/useCustomMachine";
import { addAssistantText, clearAssistatText } from "../../../../../../../../generic/store/action";
import TemplateWizard from "../../../../../../../../generic/templates/Modal/TemplateWizard";
import useSession from '../../../../../../../../session/hooks/useSession';
import SelectCompanies from "../../../../../../forms/SelectCompanies";
import useGetGroupCompanies from "../../../../../../graphql/queries/useGetGroupCompanies";

const SelectCompaniesShareStep: FC<StepProps> = () => {

  const dispatch = useDispatch();
  const { send, next, stepData, state } = useCustomMachine();
  const { startupId } = useSession();
  const { data, loading } = useGetGroupCompanies({ startupId }, { query: 'SHARE' });

  const handlePrev = () => {
    send("PREVIOUS")
  }

  const handleCancel = () => {
    send('CANCEL')
  }

  const handleCompleteSubmit = async (values: any) => {
    next(values);
  }

  useEffect(() => {
    dispatch(clearAssistatText());
    const haveCompanyInFormation = _.filter(data, gc => gc.kind === "FORMATION");
    if (haveCompanyInFormation.length > 0) {
      dispatch(addAssistantText(`A company can’t have shares in a company that is not officially formed yet. Once the company is incorporated, it will become available for selection.`));
    }
    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch, data, state]);

  const groupCompanies = useMemo((): object[] => {
    if (data) {
      return _.orderBy(
        _.filter(data, gc => _.get(gc, 'id') !== _.get(state, 'context.paramsData.id'))
        , ['isHoldingCompany'], ['desc'])
    } else {
      return []
    }
  }, [data, state])

  const disabledCondition = useCallback((gc) => {
    return gc.kind !== "INCORPORATED"
  }, [])

  // groupCompanies ? setActiveTooltip(true) : setActiveTooltip(false)

  return (
    <>
      <Loading loading={loading}>
        <SelectCompanies
          groupCompaniesList={groupCompanies}
          initialValues={stepData}
          propsTemplate={{
            title: "Select the companies in which they have shares",
            subTitle: "Company equity"
          }}
          onCompleteSubmit={handleCompleteSubmit}
          disabledCondition={disabledCondition}
          Template={TemplateWizard}
          buttons={{ 
            cancel: <Button onClick={handleCancel}>Cancel</Button>,
            previous: <Button onClick={handlePrev}>Previous</Button>
          }}
        />
      </Loading>
    </>
  )
}
export default SelectCompaniesShareStep
import _ from 'lodash';
import React, { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import useCategories from '../../../../../hooks/common/useCategories';
import { Constants } from '../../../../../v1/utils';
import Button from '../../../../generic/components/Buttons/Button';
import { StepProps } from "../../../../generic/components/Wizard/Step";
import useCustomMachine from '../../../../generic/hooks/useCustomMachine';
import useTranslate from '../../../../generic/hooks/useTranslate';
import { clearAssistatText } from "../../../../generic/store/action";
import TemplateWizard from '../../../../generic/templates/Modal/TemplateWizard';
import useUpsertEquity from "../../../equity/graphql/mutation/upsertEquity";
import useUpsertWarrant from "../../../equity/graphql/mutation/upsertWarrant";
import useUpsertDocument from '../../../graphql/document/mutation/useUpsertDocument';
import Form from "../../Forms/FormDocumentUpload";
import useSession from '../../../../session/hooks/useSession';
import { gql, useQuery } from "@apollo/client";

const GROUP_COMPANIES_QUERY = gql`
  query getGroupCompanies($startupId: ID!) {
    getGroupCompanies(startupId: $startupId) {
      id
      name
      country { code }
      isHoldingCompany
    }
  }
`;

const StepDocumentDetails: FC<StepProps> = () => {

  const { translate } = useTranslate();
  const { next, send, prev, state } = useCustomMachine();
  const { getSubcategoryFromDocType } = useCategories();
  const { mutation: updateDocument } = useUpsertDocument();
  const dispatch = useDispatch();
  const { startupId } = useSession();
  const { upsertWarrant } = useUpsertWarrant();
  const { mutation } = useUpsertEquity();
  const { data: companiesData } = useQuery(GROUP_COMPANIES_QUERY, { variables: { startupId } });
  const { type, groupCompanyId, stakeholderId, equityId, owner } = state.context.paramsData;

  const topCoCompanyCountry = (
    _.find(companiesData?.getGroupCompanies, { isHoldingCompany: true })?.country?.code ||
    companiesData?.getGroupCompanies[0]?.country?.code
  );

  const documentType = useMemo(() => {
    if (type === 'IP_ASSIGNMENT') {
      return topCoCompanyCountry === 'US' ? 'USA_CONSULTANT_INFORMATION_AND_INVENTIONS_AGREEMENT' : type;
    } else {
      return type;
    }
  }, [topCoCompanyCountry, type]);

  const valuesParties = React.useMemo(() => {
    const values = {};
    groupCompanyId && _.assign(values, { groupCompanies: [{ groupCompany: groupCompanyId }] });
    stakeholderId && _.assign(values, { parties: [stakeholderId] });
    return values;
  }, [groupCompanyId, stakeholderId]);

  const handleCompleteSubmit = async (values) => {
    switch (type) {
      case "WARRANT_AGREEMENT": {
        const warrantData = {
          id: equityId,
          groupCompany: groupCompanyId,
          documents: { uploadedFiles: [{ ...values.file, title: values.title }] }
        }
        await upsertWarrant({ startupId, warrantData, owner: { type: "stakeholder", id: stakeholderId } });
        break;
      }
      case "REGISTER_OF_SHARES": {
        const equityData = {
          id: equityId,
          documents: { uploadedFiles: [{ ...values.file, title: values.title }] }
        }
        await mutation({ startupId, shareType: 'SHARE', groupCompanyId, equityData, owner });
        break;
      }
      case "GRANT_AGREEMENT":
        const equityData = {
          id: equityId,
          documents: { uploadedFiles: [{ ...values.file, title: values.title }] }
        }
        await mutation({ startupId, shareType: 'OPTION', groupCompanyId, isThereSIP: false, equityData, owner });
        break;
      default: {
        const subCategory = getSubcategoryFromDocType(documentType);
        let subCategories = [subCategory.key];
        if (!!values.subCategories) {
          subCategories = _.concat(subCategories, values.subCategories);
        }
        const variables = {
          startupId,
          agreementData: {
            ...values,
            ...valuesParties,
            subCategories,
            type: documentType,
            status: Constants.AGREEMENT_STATUS.UPLOADED,
          }
        }
        await updateDocument({ variables });
      }
    }
    next(values);
    dispatch(clearAssistatText());
  }

  const handleCancel = () => {
    send("CANCEL");
  }

  const handlePrev = () => {
    prev();
  }

  return (
    <>
      <Form onCompleteSubmit={handleCompleteSubmit} Template={TemplateWizard} propsTemplate={{
        title: translate('MODULES.DOCUMENTS_STARTUP.IP_AND_GENERATE.SECOND_STEP.TITLE'),
        subTitle: translate(`MODULES.DOCUMENTS_STARTUP.IP_AND_GENERATE.SECOND_STEP.SUBTITLE`, { type: translate(`MODULES.DOCUMENTS_STARTUP.CATEGORIES_TITLES.${type}`) })
      }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button>,
          previous: <Button onClick={handlePrev} variant="secondary">Previous</Button>
        }}>
      </Form>
    </>
  );
}

export default StepDocumentDetails;
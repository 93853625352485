import React, { FC, useState, useEffect } from 'react'
import Button from '../../../generic/components/Buttons/Button'
import ModalDelete from '../../../generic/components/Modal/ModalDelete/index';
import TemplateDelete from '../../../../modules/generic/templates/Modal/TemplateDelete';
import useSession from '../../../../modules/session/hooks/useSession';
import useTranslate from '../../../../modules/generic/hooks/useTranslate';
import Typography from '../../../../modules/generic/components/Texts/Typography';
import useModal from '../../../../modules/generic/hooks/useModal';
import classModule from './class.module.scss'
import useRegisterAsCheckedTask from "src/modules/generic/graphql/mutation/useRegisterAsCheckedTask";
import CONSTANTS from "src/modules/map/modals/constants"

interface ModalMarkTaskCompletedProps {
  open: boolean
  groupCompanyId: string,
  taskCode: string
}
const ModalMarkTaskCompleted: FC<ModalMarkTaskCompletedProps> = ({ open, groupCompanyId, taskCode }) => {

  const [isOpen, setOpen] = useState(open);
  const { startupId } = useSession();
  const { translate } = useTranslate();
  const { closeModal } = useModal();
  const { mutation: registerAsCheckedTask, loading } = useRegisterAsCheckedTask();

  const handleClose = () => {
    closeModal()
  }

  const handleSubmit = async () => {
    await registerAsCheckedTask({
      startupId,
      registeredTaskData: {
        entityId: groupCompanyId,
        taskCode: taskCode
      }
    })
    handleClose()
  }

  useEffect(() => {
    setOpen(open)
  }, [open])
  
  return <ModalDelete open={isOpen}>
    <TemplateDelete
      className={classModule.template}
      props={{
        title: translate('MODULES.MAP.MODAL_MARK_TASK_CAPTABLE.TITLE', { equity: CONSTANTS.CAP_TABLE_TASKS[taskCode] }),
        description: (
          <Typography 
            component='p'
            variant="body"
          >
            {translate('MODULES.MAP.MODAL_MARK_TASK_CAPTABLE.SUBTITLE', { equity: CONSTANTS.CAP_TABLE_TASKS[taskCode] })}
          </Typography>
        ),
      }}
      buttons={{
        submit: <Button variant='submitDelete' loading={!!loading} onClick={handleSubmit}>{translate('MODULES.MAP.MODAL_MARK_TASK_CAPTABLE.CONFIRM')}</Button>,
        cancel: <Button variant='cancelSubmit' onClick={handleClose}>{translate('MODULES.MAP.MODAL_MARK_TASK_CAPTABLE.CANCEL')}</Button>
      }}
    ></TemplateDelete>
  </ModalDelete>
}
export default ModalMarkTaskCompleted
import React, {useEffect, useState} from 'react';
import {Field, Formik} from 'formik';
import {ClaraCheckForm, Text} from '../../../../components';
import FactoryChatForm from '../../../../components/Chat/FactoryChatForm';
import FactoryChatFormConfig from '../../../../components/Chat/FactoryChatFormConfig';
import classesForm from '../Form.module.scss';
import _ from 'lodash'
import {useParams} from 'react-router-dom';

const BanckServiceForm = React.memo(({ authorisedSignatories, initialValues, refreshData, onSubmit, closeForm }) => {


    const [openModal, setOpenModal] = useState(false);
    const [openModalOther, setOpenModalOther] = useState(false);
    const [UAEOrGCCNationalSelection, setUAEOrGCCNationalSelection] = useState([]);
    const [otherAdditionalSelection, setOtherAdditionalSelection] = useState([]);
    const params = useParams();






    const handleClose = () => {
        setOpenModal(false)
    }

    const handleCloseOther = () => {
        setOpenModalOther(false)
    }


    useEffect(() => {
        const mapAuthorizedSignatories = (authorisedSignatories) => {

            setUAEOrGCCNationalSelection(authorisedSignatories.UAEOrGCCNationalSelection)
            setOtherAdditionalSelection(authorisedSignatories.otherAdditionalSelection)
        }
        mapAuthorizedSignatories(authorisedSignatories)

    }, [authorisedSignatories])


    return (
        <React.Fragment>

            {
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validate={null}
                    onSubmit={onSubmit}
                    isInitialValid
                >

                    {({
                        values,
                        errors,
                        dirty,
                        handleSubmit,
                        isSubmitting,
                        handleReset,
                        setFieldValue,
                        initialValues,
                        isValid,
                        setFieldError,
                        validateForm,
                    }) => {

                        return (

                            <React.Fragment>
                                <div className={classesForm.containerData} >
                                    <React.Fragment>

                                        {!_.get(values, 'NASService', '') &&
                                            <div className={`${classesForm.ChatQuestionnaire} ${classesForm.ChatAdditionalSignature}`}>
                                                <div>
                                                    <button className={` ${classesForm.buttonOption}   `}
                                                        type="button" onClick={() => { setOpenModalOther(true) }}>
                                                        <Text uuid={`QUESTIONARY_ADDITIONAL_SIGNATURE_BUTTON`} />
                                                    </button>
                                                </div>
                                                <label>Please select at least one <strong>UAE</strong> or a <strong>GCC National</strong> </label>
                                                <Field name={"authorizedSignatories"}>
                                                    {({ field, form }) => (
                                                        <ClaraCheckForm
                                                            openForm={openModal}
                                                            cbClose={handleClose}
                                                            cbSaveForm={refreshData}
                                                            field={field}
                                                            form={form}
                                                            list={UAEOrGCCNationalSelection}
                                                            initialValues={{ formKey: "INDIVIDUAL_FORM" }}
                                                            optionsList={{
                                                                fieldId: _.get(FactoryChatFormConfig, "DIRECTOR_INDIVIDUAL_FORM" + ".id", "id"),
                                                                fieldLabel: _.get(FactoryChatFormConfig, "DIRECTOR_INDIVIDUAL_FORM" + ".fieldName", null),
                                                            }}
                                                            optionsForm={{
                                                                titleKey: _.get(FactoryChatFormConfig, "DIRECTOR_INDIVIDUAL_FORM.titleKey", null),
                                                                educationId: _.get(FactoryChatFormConfig, "DIRECTOR_INDIVIDUAL_FORM.leftEducationId", null),
                                                                muttation: _.get(FactoryChatFormConfig, "DIRECTOR_INDIVIDUAL_FORM.muttation", null),
                                                                getIdFromMuttation: _.get(FactoryChatFormConfig, "DIRECTOR_INDIVIDUAL_FORM.getIdFromMuttation", null),
                                                                paramsMuttaion: { matterId: params.matterId },
                                                                getVariablesMutation: _.get(FactoryChatFormConfig, "DIRECTOR_INDIVIDUAL_FORM.getVariablesMutation", null),
                                                                succesNotification: "NOTIFICATION_SUCCESS",
                                                                errorNotification: "NOTIFICATION_BAD",
                                                            }}
                                                            optionsSelect={{
                                                                auto: true,
                                                                autoLoaderData: false,
                                                                canSelect: true,

                                                            }}
                                                            optionsOperation={{
                                                                canAdd: false,
                                                                canEdit: true,
                                                                canRemove: false,
                                                                canRemoveOnlyNews: false,
                                                                canEditOnlyNews: false,
                                                            }}
                                                            optionsView={{
                                                                recordsByLine: 5
                                                            }}
                                                            className={{ ContentAvatar: classesForm.ContentAvatar }}
                                                        >
                                                            <FactoryChatForm type={"DIRECTOR_INDIVIDUAL_FORM"} formKey={"DIRECTOR_INDIVIDUAL_FORM"} ></FactoryChatForm>
                                                        </ClaraCheckForm>
                                                    )}
                                                </Field>
                                                <label>Other addtional authorised signatories</label>
                                                <Field name={"authorizedSignatories"}>
                                                    {({ field, form }) => (
                                                        <ClaraCheckForm
                                                            openForm={openModalOther}
                                                            cbClose={handleCloseOther}
                                                            cbSaveForm={refreshData}
                                                            field={field}
                                                            form={form}
                                                            list={otherAdditionalSelection}
                                                            initialValues={{ formKey: "INDIVIDUAL_FORM" }}
                                                            optionsList={{
                                                                fieldId: _.get(FactoryChatFormConfig, "DIRECTOR_INDIVIDUAL_FORM" + ".id", "id"),
                                                                fieldLabel: _.get(FactoryChatFormConfig, "DIRECTOR_INDIVIDUAL_FORM" + ".fieldName", null),
                                                            }}
                                                            optionsForm={{
                                                                titleKey: _.get(FactoryChatFormConfig, "DIRECTOR_INDIVIDUAL_FORM.titleKey", null),
                                                                educationId: _.get(FactoryChatFormConfig, "DIRECTOR_INDIVIDUAL_FORM.leftEducationId", null),
                                                                muttation: _.get(FactoryChatFormConfig, "DIRECTOR_INDIVIDUAL_FORM.muttation", null),
                                                                getIdFromMuttation: _.get(FactoryChatFormConfig, "DIRECTOR_INDIVIDUAL_FORM.getIdFromMuttation", null),
                                                                paramsMuttaion: { matterId: params.matterId },
                                                                getVariablesMutation: _.get(FactoryChatFormConfig, "DIRECTOR_INDIVIDUAL_FORM.getVariablesMutation", null),
                                                                succesNotification: "NOTIFICATION_SUCCESS",
                                                                errorNotification: "NOTIFICATION_BAD",
                                                            }}
                                                            optionsSelect={{
                                                                auto: true,
                                                                autoLoaderData: false,
                                                                canSelect: true,

                                                            }}
                                                            optionsOperation={{
                                                                canAdd: false,
                                                                canEdit: true,
                                                                canRemove: false,
                                                                canRemoveOnlyNews: false,
                                                                canEditOnlyNews: true,
                                                            }}
                                                            optionsView={{
                                                                recordsByLine: 5
                                                            }}
                                                            className={{ ContentAvatar: classesForm.ContentAvatar }}
                                                        >
                                                            <FactoryChatForm type={"INDIVIDUAL_FORM"} formKey={"INDIVIDUAL_FORM"} ></FactoryChatForm>
                                                        </ClaraCheckForm>
                                                    )}
                                                </Field>
                                            </div>

                                        }
                                    </React.Fragment >

                                    {/* <pre>{JSON.stringify(values, null, 2)}</pre>*/}
                                    <hr></hr>
                                    <div className={`botoneraButtons ${classesForm.containerButtons}`}>
                                        <button className={`genericBlue`} onClick={closeForm} >Cancel</button>
                                        <button className={`genericBlue`} onClick={handleSubmit} disabled={!isValid || _.get(values, 'authorizedSignatories', []).length < 1} >Update </button>
                                    </div>

                                </div>

                            </React.Fragment>
                        )

                    }
                    }
                </Formik>
            }
        </React.Fragment>
    )
})

export default BanckServiceForm;



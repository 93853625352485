import TagManager from 'react-gtm-module';
import _ from "lodash"
const pendingCompanies = (entities) => {
    let count = 0
    if (entities) {
        _.forEach(entities, (entity) => {
            if (entity.type === "groupCompany") {
                count = entity.remains
            }
        })
    } return count
}
export default (result) => {
    const tagManagerArgs = {
        dataLayer: {
            event: "PageView",
            path: "maps/?status=afterCompanyAdded",
            pageName: "Stakeholder Map - Company Added"
        },
        dataLayerName: "PageDataLayer",
    };
    TagManager.dataLayer(tagManagerArgs);

    const countPrendings = pendingCompanies(_.get(result, "startUp.pendingEntities"));
    if (countPrendings == 0) {
        const tagManagerArgsFinish = {
            dataLayer: {
                event: "PageView",
                path: "maps/?status=addEquityToStartup",
                pageName: "Stakeholder Map - Add Equity To Companies"
            },
            dataLayerName: "PageDataLayer",
        };
        TagManager.dataLayer(tagManagerArgsFinish);
    }

}
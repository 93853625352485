import classnames from 'classnames';
import _ from "lodash";
import { useDispatch } from "react-redux";
import React, { useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import PrimaryButton from "../../../../components/generic/Buttons/PrimaryButton/PrimaryButton";
import Icon from "../../../../modules/generic/components/Icon";
import Scrollbar from '../../../../components/generic/Scrollbar/Scrollbar';
import TextInput from "../../../../components/inputs/Text/TextInput";
import Text from "../../../../components/text/Text/Text";
import Link from "../../../../components/text/Link/Link";
import Money from "../../../../components/text/Text/Money";
import paid from '../../../../images/paid.svg';
import Stack from '@mui/material/Stack';
import useValidatePaymentCoupon from "./../../../../modules/checkout/hooks/useValidatePaymentCoupon";
import classesModal from "./PaymentTotal.module.scss";
import { goToPrevPage } from "../../../../store/actions/page";
import useSession from "./../../../../modules/session/hooks/useSession";
import usePayment from '../../hooks/usePayment/index';
import products from "../../../../modules/checkout/constants/products";
import useBusinessRules from '../../hooks/useBusinessRules';
import currency from 'currency.js';
import ResumeLogic from "src/modules/checkout/components/PaymentTotal/PaymentItems/ResumeLogic";
import PaymentItemLogic from './PaymentItems/PaymentItemLogic';


const PaymentTotal = ({
  company,
  product,
  vat,
  variantProduct,
  freeVat,
  isPaid,
  isProcess,
  onChangeCoupon,
  showBack = true,
  showTerms = true,
  showCostBox = true,
  className,
  onChangeValue,
  innerRef,
  totalGovernmentFee,
  totalPricingDiscount,
  title,
  defaultVat = {
    discountApplies: false,
    value: 0,
    valueWithDiscount: 0
  },
  ...getThemeProps
}) => {

  const dispatch = useDispatch();
  const { isItemEnabled } = useBusinessRules();
  const [coupon, setCoupon] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponApply, setCouponApply] = useState(null);
  const [couponStatus, setCouponStatus] = useState("input");
  const [subTotalAmount, setSubTotalAmount] = useState({ currency: "", amount: "" });
  const [discountAmount, setDiscountAmount] = useState({ currency: "", amount: "" });
  const [totalAmount, setTotalAmount] = useState({ currency: "", amount: "" });
  const [vatAmount, setVatAmount] = useState({ currency: "", amount: "" });
  const [errorMessageCoupon, setErrorMessageCoupon] = useState(null);
  const { startup } = useSession();

  const {
    subTotal: subTotalProvider,
    vatAmount: vatAmountProvider,
    setCoupon: setCouponProvider
  } = usePayment();

  const activePincasSumary = useMemo(() => {
    let result = false;
    if (product && (product?.code === "ADGM_SHOPPING_CART" || product?.code === "CAYMAN_SHOPPING_CART")) {
      result = true;
    }
    return result;
  }, [product]);

  const feeText = useMemo(() => {
    let result = "Government Fee";
    if (product && product?.code === "ADGM_SHOPPING_CART") {
      result = "ADGM Fee";
    }
    return result;
  }, [product]);

  const handleCompleteValidateCupon = () => {
    setCouponApply(couponCode)
  }

  const {
    manualQuery: validateCoupon,
    loading: loadingCoupon,
    error: errorCoupon,
    data: couponDiscount,
    refetch: refetchCoupon
  } = useValidatePaymentCoupon({ couponCode }, { showSuccessNotification: false, showErrorNotification: false, onCompleted: handleCompleteValidateCupon });

  const handleBackButton = () => {
    goToPrevPage(dispatch);
  };

  const handleApplyCoupon = (e, value) => {
    setCouponCode(coupon);
    validateCoupon({ variables: { couponCode: coupon } });
    if (e && e.stopPropagation) e.stopPropagation();
  };

  const handleDeleteCoupon = () => {
    setCouponCode(null);
    setCoupon("");
    onChangeCoupon(null);
    setCouponApply(null)
    setCouponProvider && setCouponProvider(null)
  };

  const handleChangeValue = useCallback((v) => {
    onChangeValue && onChangeValue(v)
  }, [onChangeValue])

  useEffect(() => {
    setCouponProvider && setCouponProvider(couponDiscount)
  }, [couponDiscount]);

  useEffect(() => {
    onChangeCoupon(couponCode);
  }, [couponCode, couponDiscount]);

  useEffect(() => {
    if (coupon && errorCoupon) {
      setErrorMessageCoupon(errorCoupon.graphQLErrors[0].message);
      setCouponCode(null);
      setCoupon(null)
    }
    setCouponApply(null)
  }, [errorCoupon])

  useEffect(() => {
    if (activePincasSumary) {
      setSubTotalAmount({
        currency: product?.currency ?? "USD",
        amount: product?.totals?.subTotalAmount ?? 0
      })
    } else {
      if (product && product?.items) {
        let result = currency(0);
        _.each(product?.items, function (item, key) {
          if (item.amount && isItemEnabled(item, company)) {
            result = result.add(item.amount);
          }
        })
        setSubTotalAmount({ currency: _.get(product, "currency"), amount: result.value })
      } else {
        setSubTotalAmount({ currency: _.get(product, "currency"), amount: product?.installmentValue })
      }
    }
  }, [product, activePincasSumary]);

  useEffect(() => {
    if (subTotalAmount || subTotalProvider) {
      const subTotal = subTotalProvider !== null ? subTotalProvider : _.get(subTotalAmount, "amount", 0)
      if (!couponCode) {
        setDiscountAmount({ currency: subTotalAmount.currency, amount: 0 });
        return;
      }
      const amount = _.get(couponDiscount, "validatePaymentCoupon.discountAmount");
      const percent = _.get(couponDiscount, "validatePaymentCoupon.discountPercentage");
      if (activePincasSumary) {
        const totalAmount = product?.totals?.total;
        let discount = ((totalAmount * percent / 100) + amount) * -1;
        discount = isNaN(discount) ? 0 : discount;
        setDiscountAmount({ currency: subTotalAmount.currency, amount: discount });
      } else {
        let discount = (((subTotal * percent / 100) + amount)) * -1;
        discount = isNaN(discount) ? 0 : discount;
        setDiscountAmount({ currency: subTotalAmount.currency, amount: discount });
      }
    }
  }, [subTotalAmount, subTotalProvider, couponCode, couponDiscount, activePincasSumary, product]);

  useEffect(() => {
    if (activePincasSumary) {
      setVatAmount({
        currency: product?.currency ?? "USD",
        amount: product?.totals?.vat ?? 0
      });
    } else {
      if (vatAmountProvider !== null) {
        setVatAmount({ currency: subTotalAmount.currency, amount: vatAmountProvider });
        return;
      }
      if (vat && subTotalAmount) {
        let percentVat = 0;
        if (freeVat) {
          percentVat = _.get(vat, "valueWithDiscount");
        } else {
          percentVat = _.get(vat, "value");
        }
        const totalAmount = subTotalAmount.amount + discountAmount.amount;
        let newVatAmount = (totalAmount * percentVat / 100);
        newVatAmount = isNaN(newVatAmount) ? 0 : newVatAmount;
        setVatAmount({ currency: subTotalAmount.currency, amount: newVatAmount });
      }
    }
  }, [vat, freeVat, subTotalAmount, discountAmount, vatAmountProvider, product, activePincasSumary]);

  useEffect(() => {
    if (vatAmount && subTotalAmount && discountAmount) {
      if (activePincasSumary) {
        let totalAmount = product?.totals?.total ?? 0;
        totalAmount = isNaN(totalAmount) ? 0 : totalAmount;
        const productDiscount = isNaN(discountAmount?.amount) ? 0 : discountAmount?.amount;
        totalAmount = totalAmount - (productDiscount * -1);
        handleChangeValue({ currency: product?.currency ?? "USD", amount: totalAmount })
        setTotalAmount({ currency: product?.currency ?? "USD", amount: totalAmount });
      } else {
        let totalAmount = subTotalAmount.amount + discountAmount.amount + vatAmount.amount;
        totalAmount = isNaN(totalAmount) ? 0 : totalAmount;
        const productDiscount = isNaN(product?.discount) ? 0 : product?.discount;
        totalAmount = totalAmount - productDiscount;
        handleChangeValue({ currency: subTotalAmount.currency, amount: totalAmount })
        setTotalAmount({ currency: subTotalAmount.currency, amount: totalAmount });
      }
    }
  }, [vatAmount, subTotalAmount, discountAmount, handleChangeValue, product?.discount, activePincasSumary]);

  const useVat = useMemo(() => {
    const pcode = _.get(product, "code");
    let result = true;
    const productsWithOutVat = [products.FORMATION_CAYMAN_PAYMENT,
    products.FORMATION_DELAWARE_PAYMENT,
    products.SCALE_ANNUAL_SUBSCRIPTION]
    if (productsWithOutVat.includes(pcode)) {
      result = false;
    }
    
    return result;
  }, [product])

  useImperativeHandle(
    innerRef,
    () => ({
      clearCoupn: handleDeleteCoupon
    }),
    [handleDeleteCoupon]
  );

  return (
    <>
      <div className={`${classesModal.PaymentTotal} ${className}`}>
        {showBack && (
          <div className={classesModal.Back}>
            <Link target={"_blank"} onClick={handleBackButton}><Text uuid={"MODULES.CHECKOUT.PAYMENT_LABEL_BACK"} /></Link>
          </div>
        )}
        <Scrollbar className={classesModal.paymentCoupon} scrollBottom={true}>
          {title ? title : (
            <div className={classesModal.costbox} >
              {showCostBox && (
                <div className={classesModal.line1}>
                  <Text
                    uuid={`MODULES.CHECKOUT.PAYMENT_LINE1_${_.get(product, 'code', '')}`}
                    defaultKey={"MODULES.CHECKOUT.PAYMENT_SUBSCRIBE"}
                    params={{ ...product, startup }}
                  />
                </div>
              )}
              <div className={classesModal.line2}>
                {_.get(product, "currency")}
                <b><Money value={_.get(product, "installmentValue")} params={{ prefix: " " }} /> </b> {useVat && <>+ VAT</>}
                <Text uuid={`MODULES.CHECKOUT.PAYMENT_LINE2_${_.get(product, 'code', '')}`} defaultKey={"PAYMENT_SUBSCRIBE_AMOUNT"} params={{ ...product }} />
              </div>
            </div>
          )}
          <PaymentItemLogic
            product={product}
            variant={variantProduct}
            company={company}
            activePincasSumary={activePincasSumary}
            feeText={feeText}
          />
          <div className={classesModal.coupon} >
            {product && (product.couponsEnabled !== false) && (
              <div className={`${classesModal.item} ${classesModal.itemCoupon}`}>
                <div className={`${classesModal.product} ${classesModal.productCoupon} `}>
                  {couponApply ? (
                    <div className={classesModal.couponApprobe}>
                      <div>{couponCode}</div>
                      <button disabled={isPaid || isProcess} onClick={handleDeleteCoupon}>
                        <Icon icon={"Delete"} isClara={true} size={"1.2rem"} />
                      </button>
                      <span>
                        {_.get(couponDiscount, "validatePaymentCoupon.type") === "PERCENTAGE" ?
                          (
                            <Text uuid={"MODULES.CHECKOUT.PAYMENT_COUPON_SAVE_PERCENT"} params={{ value: _.get(couponDiscount, "validatePaymentCoupon.discountPercentage", 0) }} />
                          ) : (
                            <Text uuid={"MODULES.CHECKOUT.PAYMENT_COUPON_SAVE_AMOUNT"} params={{ value: (Math.round(_.get(couponDiscount, "validatePaymentCoupon.discountAmount", 0) * 100) / 100).toFixed(2) }} />
                          )}
                      </span>
                    </div>
                  ) : (
                    <TextInput
                      type={"text"}
                      name="holderName"
                      defaultValue={""}
                      placeholder={"MODULES.CHECKOUT.PAYMENT_COUPON_PLACEHOLDER"}
                      value={coupon}
                      errorMessage={errorMessageCoupon ? <Text uuid={errorMessageCoupon} /> : null}
                      classNameError={errorMessageCoupon ? classesModal.couponError : null}
                      onFocus={() => {
                        setCouponStatus("open")
                      }}
                      onBlur={() => {
                        setCouponStatus("input")
                      }}
                      onChange={(e) => {
                        setCoupon(e);
                        setErrorMessageCoupon(null)
                      }}
                      disabled={isPaid || isProcess}
                      className={classnames({
                        [classesModal.couponOpen]: couponStatus === "open" || (couponStatus === "input" && coupon !== ""),
                        [classesModal.couponOk]: couponStatus === "ok",
                      }, classesModal.buttonCouponContent)}
                      afterComponent={
                        <React.Fragment>
                          <PrimaryButton type={"button"} className={classnames({
                            [classesModal.vissible]: couponStatus === "open" || (couponStatus === "input" && coupon !== ""),
                          }, classesModal.buttonCoupon)}
                            disabled={coupon === ""}
                            onClick={handleApplyCoupon}
                            loading={loadingCoupon}
                            labelLoading={" "}
                          ><Text uuid={"BUTTON_APPLY"} /></PrimaryButton>
                        </React.Fragment>
                      }
                    />
                  )}
                </div>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  spacing={0.5}
                >
                  <span style={{ fontSize: "10px", marginBottom: "1px" }}>- {discountAmount.currency}</span>
                  {discountAmount.amount === 0 ? (
                    <b>{(Math.round(parseInt(discountAmount.amount) * 100) / 100).toFixed(2)}</b>
                  ) : (
                    <b><Money value={parseFloat((discountAmount.amount * -1).toFixed(2))} params={{ prefix: " " }} showDecimals={true} /></b>
                  )}
                </Stack>
              </div>
            )}
            <ResumeLogic
              product={product}
              useVat={useVat}
              vatAmount={vatAmount}
              totalAmount={totalAmount}
              freeVat={freeVat}
              vat={vat}
              activePincasSumary={activePincasSumary}
            />
          </div>
          {isPaid && (
            <div className={classesModal.contentPaidImage}>
              <img className={classesModal.paidImage} src={paid} alt='paid' />
            </div>
          )}
        </Scrollbar>
        {showTerms && (
          <div className={classesModal.Terms}>
            <Link target={"_blank"} href={"https://clara.co/terms-of-use"}><Text uuid={"MODULES.CHECKOUT.PAYMENT_LABEL_TERMS"} /> </Link>
            <span>|</span>
            <Link target={"_blank"} href={"https://clara.co/privacy-policy"}><Text uuid={"MODULES.CHECKOUT.PAYMENT_LABEL_PRIVACY"} /> </Link>
          </div>
        )}
      </div>
    </>
  )
}

export default PaymentTotal;

import useMutation from "../../../../../hooks/custom/useMutation";
import {FormationData} from "../fragments";
import {TasksRequiredFieldFragment} from "../../../../map/graphql/typePolicies";

export const UPSERT_LEASE_DETAILS = `
${FormationData}
${TasksRequiredFieldFragment}
mutation formationUpdateLeaseDetails(
    $startupId: ID!
    $groupCompanyId: ID!
    $leaseDetailsData: LeaseDetailsInputType!
  ) {
    formationUpdateLeaseDetails(startupId: $startupId, groupCompanyId: $groupCompanyId, leaseDetailsData: $leaseDetailsData) {
      id
      formationData {
    ...FormationDataFields
      }
      name
      isHoldingCompany
      tasks {
      ...TasksRequiredFieldFragment
      }
    }
  }
`;

const useUpsertFormationLeaseDetails = () => {
    const [mutation, loading] = useMutation(
        UPSERT_LEASE_DETAILS,
        { showSuccessNotification: false }
    ); 
    return { mutation, loading };
};

export default useUpsertFormationLeaseDetails;


import { useEffect } from 'react';
import useQuery from "../../../custom/useQuery";

const query = `query {
  getProfileAccess{
    startup {
    id
      updatedAt
      name
    }
    roleName
    shareDate
  }
}
 
`;
const useGetProfileAccess = (variables) =>{
    
    const {loading, error, data} = useQuery(query,variables);
    useEffect(() => {
        
    }, []);

    return  {loading,error,data} ;
}
export default useGetProfileAccess;
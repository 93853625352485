import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import Form from 'src/components/forms/Form/Form';
import TextInput from 'src/components/inputs/Text/TextInput';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateLabel from 'src/modules/generic/components/Inputs/LabelInput';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import Typography from 'src/modules/generic/components/Texts/Typography';
import FileUploaderInput from 'src/components/inputs/FileUploader/FileUploaderInput';
import CalendarInputDate from 'src/components/inputs/Calendars/CalendarInputDate';
import classes from './classes.module.scss';

function LeaseDetailsStepForm() {
  const { context, cancel, next } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.LeaseDetailsStepForm ?? {}
  }, [context]);

  const handleCancel = () => {
    cancel();
  }

  const handleSubmit = async (values) => {
    next(values);
  }

  useEffect(() => {
    dispatch(addAssistantText('For contact and pricing information regarding office space on Al Maryah Island, click <a rel=\'noopener noreferrer\' target=\'_blank\' href=\'https://help.clara.co/en/articles/6235449-who-can-i-contact-to-lease-an-office-space\'>here</a>'));
    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch]);

  const schema = Yup.object().shape({
    deskOrOfficeNum: Yup.string().nullable().required('Required'),
    floorNum: Yup.string().nullable().required('Required'),
    buildingName: Yup.string().nullable().required('Required'),
    leaseStart: Yup.date().nullable().typeError('Must be a date'),
    leaseEnd: Yup.date().nullable().typeError('Must be a date'),
    document: Yup.object().nullable().required('Required')
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: 'Add your Lease details',
          skeleton: null,
          subTitle: 'Lease details',
          variant: 'simple'
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>{translate('GENERIC_BUTTON_ADD_GROUP')}</SubmitButton>,
        }}
      >
        <Typography weight='regular' variant='xbody'>
          Please enter your lease details below and upload a copy of your signed lease agreement.
        </Typography>
        <TemplateLabel label={'Desk or office number'} isRequired={true}>
          <ControllerInput
            className={classes.shortInput}
            render={TextInput}
            name='deskOrOfficeNum'
            placeholder={'Type here'}
          />
        </TemplateLabel>
        <TemplateLabel label={'Floor number'} isRequired={true}>
          <ControllerInput
            className={classes.shortInput}
            render={TextInput}
            name='floorNum'
            placeholder={'Type here'}
          />
        </TemplateLabel>
        <TemplateLabel label={'Building Name'} isRequired={true}>
          <ControllerInput
            render={TextInput}
            name='buildingName'
            placeholder={'Type here'}
          />
        </TemplateLabel>
        <TemplateLabel label={'Street Name'} isRequired={true}>
          <ControllerInput
            render={TextInput}
            name='streetName'
            placeholder={'Type here'}
          />
        </TemplateLabel>
        <div className={classes.leaseDate}>
          <TemplateLabel label={'Lease start'} isRequired={true}>
            <ControllerInput
              className={classes.dateInput}
              render={CalendarInputDate}
              name='leaseStart'
              defaultlabel={''}
              placeholder='dd/mm/yyyy'
            />
          </TemplateLabel>
          <TemplateLabel label={'Lease end'} className={classes.shortLabel} isRequired={true}>
            <ControllerInput
              className={classes.dateInput}
              render={CalendarInputDate}
              name='leaseEnd'
              defaultlabel={''}
              placeholder='dd/mm/yyyy'
            />
          </TemplateLabel>
        </div>
        <TemplateLabel label={'Upload signed lease agreement copy'} isRequired={true}>
          <ControllerInput
            className={classes.documentUploaderFullLength}
            render={FileUploaderInput}
            defaultValue=''
            name='document'
          />
        </TemplateLabel>
        <div>&nbsp;</div>
      </TemplateWizard>
    </Form>
  );
}

export default LeaseDetailsStepForm;
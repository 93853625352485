export default {
    IS_PEP: {
        YES:'Yes, they are',
        NO:'No, they\'re not',
        DESCRIBE_PEP:'What makes this person a PEP?',
        DESCRIBE_PEP_PLACEHOLDER:'For example: they\'re a senior politician.'
    },
    IS_FAMILY_MEMBER_PEP: {
        YES:'Yes, they are',
        NO:'No, they\'re not',
        DESCRIBE_PEP:'What is this person\'s relationship to the PEP?',
        DESCRIBE_PEP_PLACEHOLDER:'For example, they\'re the spouse of this PEP. '
    },
    IS_CLOSE_ASSOCIATE_PEP: {
        YES:'Yes, they are',
        NO:'No, they\'re not',
        DESCRIBE_PEP:'What is this person\'s connection to the PEP?',
        DESCRIBE_PEP_PLACEHOLDER:'For example, they have close business relations with this PEP.  '
    },
    SOURCE_OF_FUNDS: {
     FUNDS_PLACEHOLDER:'For example, a UAE bank account or a UK personal credit/debit card in the name of the individual or company',
     FUNDS:'Source of funds',
     WEALTH:'Source of wealth',
     WEALTH_PLACEHOLDER:'Select from the list'
    },
    IS_THERE_LINKEDIN_URL:{
      YES:'Yes, they do',
      NO:'No, they don\'t'
    },
    LINKEDIN_URL:{
        LABEL:'LinkedIn URL',
        PLACEHOLDER:'Type or paste link here',
    },
    CV:{
        EXAMPLE:'Example of required layout',
        LABEL:'Upload CV'
    },
    DETAILS_COLLABORATOR:{
        TOOLTIP: 'To invite this person to collaborate, you have to add their email address first. Go back to their profile page using ‘Cancel’, select ‘Edit’ in their Details tab and add their email address. Then come back here to invite them.',

    }
}
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import Button from "src/modules/generic/components/Buttons/Button";
import { clearAssistatText } from "src/modules/generic/store/action";
import FormIsThereCompaniesFormationsShares from "src/modules/startup/GroupCompany/Forms/FormIsThereCompaniesFormationsShares";

function WillHaveSharesNotYetFormedStep() {
  const { next, cancel, prev, context } = useMachine();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return (
      context?.WillHaveSharesNotYetFormedStep ?? {
        isThereCompaniesFormationsShares:
          context.initialData.assetsAndRevenue.isSharesInNotYetFormedCompanies
            .question,
        formationCompaniesHavesShares:
          context.initialData.assetsAndRevenue.isSharesInNotYetFormedCompanies
            .description,
      }
    );
  }, [context]);

  useEffect(() => {
    dispatch(clearAssistatText());
  }, []);

  const handleSubmit = async (values) => next(values);

  const handleCancel = () => cancel();

  const handlePrev = () => prev();

  return (
    <FormIsThereCompaniesFormationsShares
      initialValues={initialValues}
      subTitle="Will the company own any shares in a company that will be formed in the future?"
      onCompleteSubmit={handleSubmit}
      Template={TemplateWizard}
      propsTemplate={{
        title: "Edit assets and revenue",
      }}
      buttons={{
        previous: (
          <Button onClick={handlePrev} variant="secondary">
            Previous
          </Button>
        ),
        cancel: (
          <Button onClick={handleCancel} variant="secondary">
            Cancel
          </Button>
        ),
      }}
    />
  );
}

export default WillHaveSharesNotYetFormedStep;

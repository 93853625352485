import _ from 'lodash';
import React, { FC, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { StepProps } from "../../../../../../../../generic/components/Wizard/Step";
import useRegisterAsCheckedTask from "../../../../../../../../generic/graphql/mutation/useRegisterAsCheckedTask";
import useCustomMachine from "../../../../../../../../generic/hooks/useCustomMachine";
import useTranslate from "../../../../../../../../generic/hooks/useTranslate";
import { addAssistantText, clearAssistatText } from "../../../../../../../../generic/store/action";
import useSession from '../../../../../../../../session/hooks/useSession';
import FirstStep from "../../../../../../wizard/steps/FirstStep";
const FirstShareStep:FC<StepProps>=({})=>{
    const dispatch=useDispatch()
    const {translate}=useTranslate()
    const {state}=useCustomMachine()
    const { startupId } = useSession();
    const {mutation}=useRegisterAsCheckedTask({showSuccessNotification:false})
    useEffect(()=>{
        dispatch(clearAssistatText())
        dispatch(addAssistantText(translate('MODULES.EQUITY.STEPS_SHARES.FIRST_STEP.TOOLTIP')))
    },[])
    const handleCompleteSubmit=async (values)=>{
        if(values.equity==='notAdd') {
            const registeredTaskData = {
                entityId: _.get(state, 'context.paramsData.id'),
                taskCode: 'TASK_ADD_SHARES'
            }
            await mutation({startupId, registeredTaskData})
        }
        dispatch(clearAssistatText())
    }
    return(<>
        <FirstStep
            tooltips={{notAdd:translate('MODULES.EQUITY.STEPS_SHARES.FIRST_STEP.TOOLTIP_NOT_ADD'),isExist:translate('MODULES.EQUITY.STEPS_SHARES.FIRST_STEP.TOOLTIP_IS_EXIST'),toCreate:translate('MODULES.EQUITY.STEPS_SHARES.FIRST_STEP.TOOLTIP_TO_CREATE')}}
            onCompleteSubmit={handleCompleteSubmit}
            type={'SHARE'}/>
    </>)
}
export default FirstShareStep

import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import Typography from "src/modules/generic/components/Texts/Typography";
import classes from "./classes.module.scss";
import Stack from '@mui/material/Stack';
import Money from "src/components/text/Text/Money";
import Tag from "src/v1/components/Tag/Tag"
function FormADGMType() {

  const { next, context, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.FormADGMType ?? {}
  }, [context]);

  const handleCancel = () => {
    cancel();
  }

  const handlePrev = () => {
    prev();
  }

  const handleSubmit = async (values) => {
    next(values);
  }

  const options = [

    {
      value: "SPV",
      component: ButtonText,
      componentProps: {
        title: "ADGM Special Purpose Vehicles (SPV)",
        text: "Special Purpose Vehicles (SPVs) are passive holding companies that are used for holding assets, typically shares in other entities. They cannot have employees or engage in commercial activities.",
        paragraph1: `Form now for ${context?.GetProducts?.spv?.currency}`,
        price1: `${context?.GetProducts?.spv?.price}`,
        vat1: `${context?.GetProducts?.spv?.useVat ? " + VAT" : ""} (includes ADGM fees)`,
        floatComponent: <div style={{ position: "absolute", right: "12px", top: "12px" }}>
          <Tag customLabel={"Holding Company"} show={true} type={"CUSTOM"}/>
        </div>
      }
    },
    {
      value: "Tech",
      component: ButtonText,
      componentProps: {
        title: "ADGM Tech Startup",
        text: "The ADGM Tech Startup Licence is a low-cost operating Licence available for innovative tech-driven startups. Depending on the maturity of the startup, it may qualify for the initial seed stage Licence. The next level is the emergent stage Licence.",
        paragraph1: `Form now for ${context?.GetProducts?.tl.seed?.currency}`,
        price1: `${context?.GetProducts?.tl.seed?.price}`,
        vat1: `${context?.GetProducts?.tl.seed?.useVat ? " + VAT" : ""}  (Seed) or ${context?.GetProducts?.tl.emergent?.currency} `,
        price2: `${context?.GetProducts?.tl.emergent?.price}`,
        vat2: `${context?.GetProducts?.tl.emergent?.useVat ? " + VAT" : ""}  (Emergent)`,
        floatComponent: <div style={{ position: "absolute", right: "12px", top: "12px" }}>
          <Tag customLabel={"Operating Company"}show={true} type={"CUSTOM"}/>
        </div>
      }
    },
  ];

  useEffect(() => {
    dispatch(addAssistantText('To form in ADGM there are some eligibility requirements you will have to meet and ground rules you will have to agree to. In the next screen we will guide you through them and give you alternative options if the requirements are not met.'));
    dispatch(addAssistantText('Click <a rel=\'noopener noreferrer\' target=\'_blank\' href=\'https://help.clara.co/en/articles/6228452-what-is-an-adgm-spv\' >here</a> for more information on ADGM SPVs. <br/><br/> Click <a rel=\'noopener noreferrer\' target=\'_blank\' href=\'https://help.clara.co/en/articles/6230979-what-is-an-adgm-tech-startup\' >here</a> for more information on the ADGM Tech Startup Licence.'));
    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch]);

  const schema = Yup.object().shape({
    type: Yup.string().required('Required').oneOf(['Tech', 'SPV'], 'Required')
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: 'What type of company do you want to form?',
          skeleton: null,
          subTitle: 'Form in ADGM',
          variant: 'simple'
        }}
        buttons={{
          previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('BUTTON_NEXT')}</SubmitButton>,
        }}
      >
        <ControllerInput
          as={CheckboxButtonSimple}
          className={classes.ADGMCheckboxContainer}
          classNameButton={classes.ADGMCheckboxButton}
          name="type"
          variant="col_1"
          options={options}
        />
      </TemplateWizard>
    </Form>
  );
}

export default FormADGMType;

const ButtonText = ({ title, text, paragraph1, price1, vat1, price2, vat2, floatComponent }) => {
  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      sx={{ width: "100%" }}
      gap={2}
      mt={1}
      mb={1}
      className={classes.textPosition}
    >
      {floatComponent}
      <Stack
        sx={{ width: "100%" }}
        gap={1}
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        className={classes.textPosition}
      >
        <Typography weight="semibold" variant="h4">
          {title}
        </Typography>
        <Typography variant="body">
          <span dangerouslySetInnerHTML={{ __html: text }}></span>
        </Typography>
      </Stack>
      <Stack
        sx={{ width: "100%" }}
        direction="row"
        className={classes.textPosition}
      >
        <Typography variant="body">
          <span dangerouslySetInnerHTML={{ __html: paragraph1 }}></span>
          <Money value={price1} params={{ prefix: " " }} showDecimals={false} />
          <span dangerouslySetInnerHTML={{ __html: vat1 }}></span>
          <Money value={price2} params={{ prefix: " " }} showDecimals={false} />
          <span dangerouslySetInnerHTML={{ __html: vat2 }}></span>
        </Typography>
      </Stack>
    </Stack>
  );
};

import { Grid, Box } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useHistory } from "react-router";
import ClaraLayout from "src/layouts/Clara/ClaraLayout";
import MicroFrontend from "src/mf";
import { Constants } from 'src/v1/utils/constants';
import useNavigation from "src/views/modules/startups/capTable/CapTable/useNavigationCaptable";
import MyShareIssuance from "src/views/modules/startups/capTable/MyEquity/components/MyShareIssuance";
import MyOptionGrant from "src/views/modules/startups/capTable/MyEquity/components/MyOptionGrant";
import MyConvertible from "src/views/modules/startups/capTable/MyEquity/components/MyConvertible";
import MyWarrants from "src/views/modules/startups/capTable/MyEquity/components/MyWarrants";
import MyTransationConvert from "src/views/modules/startups/capTable/MyEquity/components/MyTransationConvert";
import MyTransationLapse from "src/views/modules/startups/capTable/MyEquity/components/MyTransationLapse";
import MyTransationStopVesting from "src/views/modules/startups/capTable/MyEquity/components/MyTransationStopVesting";
import MyTransationExerciseManual from "src/views/modules/startups/capTable/MyEquity/components/MyTransationExerciseManual";
import MyTransationExerciseGenerate from "src/views/modules/startups/capTable/MyEquity/components/MyTransationExerciseGenerate";
import MyTransationSharesCancel from "src/views/modules/startups/capTable/MyEquity/components/MyTransationSharesCancel";
import MyTransationSharesTransfer from "src/views/modules/startups/capTable/MyEquity/components/MyTransationSharesTransfer";
import MyTransationConvertiblesCancel from "src/views/modules/startups/capTable/MyEquity/components/MyTransationConvertiblesCancel";
import MyTransationConvertiblesConvert from "src/views/modules/startups/capTable/MyEquity/components/MyTransationConvertiblesConvert";
import MyTransationWarrantsCancel from "src/views/modules/startups/capTable/MyEquity/components/MyTransationWarrantsCancel";
import MyTransationWarrantsExercise from "src/views/modules/startups/capTable/MyEquity/components/MyTransationWarrantsExercise";
import ServerConnect from "src/v1/utils/ServerConnect.js";

const MyEquityRouter = () => {
  const goTo = useNavigation();
  const page = useSelector(state => state.page);
  const history = useHistory();
  
  const openFile = async (id, filename) => {
    const url = await ServerConnect.getPublicUrlFile(id);
    ServerConnect.openFile(url, filename);
  }

  const go = (v, params = {}) => {
    goTo(v, params);
  }

  useEffect(() => {
    if (page?.historyPage && page?.historyPage[page.historyPage.length - 1]?.url && page?.historyPage[page.historyPage.length - 1]?.url.indexOf("/auth") >= 0) {
      history.push(page.historyPage[page.historyPage.length - 1].url);
    }
  }, [history, page]);

  return (
    <ClaraLayout 
      userMenu 
      fullMenu={false} 
      showAssistance={false} 
      showBreadCrumbs={false} 
      linkMenu="LinkMyEquity"
      firstMenu={true}
    >
      <Grid container spacing={0} sx={{ width: "100%", height: "100%", flexWrap: 'nowrap' }}>
        <Grid item xs sx={{ height: "100%", width: "100% !important", backgroundColor: "#FBFCFF"}}>
          <Switch>
            <Route path={Constants.PAGES["capTable.mf.myEquity.home"].path} >
              <MicroFrontend id="MF_myEquity_home" component={"./MyEquityHome"} mf="capTable" params={{go}}/>
            </Route>
            <Route path={Constants.PAGES["capTable.mf.myEquity.mySharesIssuance"].path} >
              <Box sx={{width: "100%", height: "100%", padding: "2.4615rem", display: "flex", justifyContent: "center"}}>
                <Box sx={{width: "100%", height: "100%", maxWidth: '1980px'}}>
                  <MyShareIssuance go={go} openFile={openFile}></MyShareIssuance>
                </Box>
              </Box>
            </Route>
            <Route path={Constants.PAGES["capTable.mf.myEquity.myOptionGrant"].path} >
              <Box sx={{width: "100%", height: "100%", padding: "2.4615rem", display: "flex", justifyContent: "center"}}>
                <Box sx={{width: "100%", height: "100%", maxWidth: '1980px'}}>
                  <MyOptionGrant go={go} openFile={openFile}></MyOptionGrant>
                </Box>
              </Box>
            </Route>
            <Route path={Constants.PAGES["capTable.mf.myEquity.myConvertible"].path} >
              <Box sx={{width: "100%", height: "100%", padding: "2.4615rem", display: "flex", justifyContent: "center"}}>
                <Box sx={{width: "100%", height: "100%", maxWidth: '1980px'}}>
                  <MyConvertible go={go} openFile={openFile}></MyConvertible>
                </Box>
              </Box>
            </Route>
            <Route path={Constants.PAGES["capTable.mf.myEquity.myWarrant"].path} >
              <Box sx={{width: "100%", height: "100%", padding: "2.4615rem", display: "flex", justifyContent: "center"}}>
                <Box sx={{width: "100%", height: "100%", maxWidth: '1980px'}}>
                  <MyWarrants go={go} openFile={openFile}></MyWarrants>
                </Box>
              </Box>
            </Route>
            <Route path={Constants.PAGES["capTable.mf.myEquity.grant.myTransaction.convert"].path} >
              <Box sx={{width: "100%", height: "100%", padding: "2.4615rem", display: "flex", justifyContent: "center"}}>
                <Box sx={{width: "100%", height: "100%", maxWidth: '1980px'}}>
                  <MyTransationConvert go={go} openFile={openFile}></MyTransationConvert>
                </Box>
              </Box>
            </Route>
            <Route path={Constants.PAGES["capTable.mf.myEquity.grant.myTransaction.lapse"].path} >
              <Box sx={{width: "100%", height: "100%", padding: "2.4615rem", display: "flex", justifyContent: "center"}}>
                <Box sx={{width: "100%", height: "100%", maxWidth: '1980px'}}>
                  <MyTransationLapse go={go} openFile={openFile}></MyTransationLapse>
                </Box>
              </Box>
            </Route>
            <Route path={Constants.PAGES["capTable.mf.myEquity.grant.myTransaction.stopVesting"].path} >
              <Box sx={{width: "100%", height: "100%", padding: "2.4615rem", display: "flex", justifyContent: "center"}}>
                <Box sx={{width: "100%", height: "100%", maxWidth: '1980px'}}>
                  <MyTransationStopVesting go={go} openFile={openFile}></MyTransationStopVesting>
                </Box>
              </Box>
            </Route>
            <Route path={Constants.PAGES["capTable.mf.myEquity.grant.myTransaction.exerciseManual"].path} >
              <Box sx={{width: "100%", height: "100%", padding: "2.4615rem", display: "flex", justifyContent: "center"}}>
                <Box sx={{width: "100%", height: "100%", maxWidth: '1980px'}}>
                  <MyTransationExerciseManual go={go} openFile={openFile}></MyTransationExerciseManual>
                </Box>
              </Box>
            </Route>
            <Route path={Constants.PAGES["capTable.mf.myEquity.grant.myTransaction.exerciseGenerate"].path} >
              <Box sx={{width: "100%", height: "100%", padding: "2.4615rem", display: "flex", justifyContent: "center"}}>
                <Box sx={{width: "100%", height: "100%", maxWidth: '1980px'}}>
                  <MyTransationExerciseGenerate go={go} openFile={openFile}></MyTransationExerciseGenerate>
                </Box>
              </Box>
            </Route>
            <Route path={Constants.PAGES["capTable.mf.myEquity.shares.myTransaction.cancel"].path} >
              <Box sx={{width: "100%", height: "100%", padding: "2.4615rem", display: "flex", justifyContent: "center"}}>
                <Box sx={{width: "100%", height: "100%", maxWidth: '1980px'}}>
                  <MyTransationSharesCancel go={go} openFile={openFile}></MyTransationSharesCancel>
                </Box>
              </Box>
            </Route>
            <Route path={Constants.PAGES["capTable.mf.myEquity.shares.myTransaction.transfer"].path} >
              <Box sx={{width: "100%", height: "100%", padding: "2.4615rem", display: "flex", justifyContent: "center"}}>
                <Box sx={{width: "100%", height: "100%", maxWidth: '1980px'}}>
                  <MyTransationSharesTransfer go={go} openFile={openFile}></MyTransationSharesTransfer>
                </Box>
              </Box>
            </Route>
            <Route path={Constants.PAGES["capTable.mf.myEquity.convertibles.myTransaction.cancel"].path} >
              <Box sx={{width: "100%", height: "100%", padding: "2.4615rem", display: "flex", justifyContent: "center"}}>
                <Box sx={{width: "100%", height: "100%", maxWidth: '1980px'}}>
                  <MyTransationConvertiblesCancel go={go} openFile={openFile}></MyTransationConvertiblesCancel>
                </Box>
              </Box>
            </Route>
            <Route path={Constants.PAGES["capTable.mf.myEquity.convertibles.myTransaction.convert"].path} >
              <Box sx={{width: "100%", height: "100%", padding: "2.4615rem", display: "flex", justifyContent: "center"}}>
                <Box sx={{width: "100%", height: "100%", maxWidth: '1980px'}}>
                  <MyTransationConvertiblesConvert go={go} openFile={openFile}></MyTransationConvertiblesConvert>
                </Box>
              </Box>
            </Route>
            <Route path={Constants.PAGES["capTable.mf.myEquity.warrants.myTransaction.cancel"].path} >
              <Box sx={{width: "100%", height: "100%", padding: "2.4615rem", display: "flex", justifyContent: "center"}}>
                <Box sx={{width: "100%", height: "100%", maxWidth: '1980px'}}>
                  <MyTransationWarrantsCancel go={go} openFile={openFile}></MyTransationWarrantsCancel>
                </Box>
              </Box>
            </Route>
            <Route path={Constants.PAGES["capTable.mf.myEquity.warrants.myTransaction.exercise"].path} >
              <Box sx={{width: "100%", height: "100%", padding: "2.4615rem", display: "flex", justifyContent: "center"}}>
                <Box sx={{width: "100%", height: "100%", maxWidth: '1980px'}}>
                  <MyTransationWarrantsExercise go={go} openFile={openFile}></MyTransationWarrantsExercise>
                </Box>
              </Box>
            </Route>
          </Switch>
        </Grid>
      </Grid>
    </ClaraLayout>
  )
};

export default MyEquityRouter;

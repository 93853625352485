import React, { Component } from "react";
import Popover from '@mui/material/Popover';
import classCss from "./PopoverShareProfile.module.scss"
import RadioSelectRoles from '../radioSelectRoles/RadioSelectRoles';
import { Constants } from '../../../../../../v1/utils/constants';

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    boxShadow: "none"
  }
});
/*
    Properties:
        links:[ links to show
            {
                className
                label
                onClick: function to execute when do click in.
                separator:  true|false (default), show border top
            }
        ] 
        callbacks:{ functions to execute in callback
            close: execute when 
        }
        children: content to render
*/
class PopoverShareProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchorEl: null,
            children: props.children
        };
    }


    openMenu = (e) => {
        this.setState({
            anchorEl: e.currentTarget,
            open: true
        });
    };
    closeMenu = (e) => {
        this.setState({
            open: false
        });
        if (this.props.callbacks && this.props.callbacks.close) {
            this.props.callbacks.close();
        }
    };

    onClick = (link) => {
        this.closeMenu();
        if (link.params) {
            link.onClick(link.params)
        } else { link.onClick() }

    };




    render() {
        let { children, callbacks, links,  } = this.props;
        const {classes} = this.props;
        let { open, anchorEl } = this.state;
        const classOpen = open ? classCss.Open : classCss.Close;
        const showArrowClass = this.props.scoping ? null : classCss.Arrow;
        const rolesOptions = [{ id: Constants.USER_ROLES.USER_OWNER, label: Constants.TRANSLATES.ROL_USER_USER_OWNER }, { id: Constants.USER_ROLES.USER_MANAGER, label: Constants.TRANSLATES.ROL_USER_USER_MANAGER }, { id: Constants.USER_ROLES.USER_EDITOR, label: Constants.TRANSLATES.ROL_USER_USER_EDITOR }, { id: Constants.USER_ROLES.USER_VIEWER, label: Constants.TRANSLATES.ROL_USER_USER_VIEWER }]

        return (
            <div className={this.props.captable ? `${classCss.ModalMenu} ${classCss.ModalStyle}` : classCss.ModalMenu}>
                <a   onClick={(e) => { this.openMenu(e) }}>
                    <div>{this.props.captable ? this.state.children : children}</div>
                </a>

                    <Popover
                        className={classes.root}
                        disableEnforceFocus
                        open={open}
                        onClose={this.closeMenu}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        anchorEl={anchorEl}
                    >
                        <div className={classCss.Modal}>

                            <RadioSelectRoles 
                                isPopover={true} 
                                handleOpenDeactivateSelfRoleModal={this.props.handleOpenDeactivateSelfRoleModal} 
                                handleOpenReactiveRoleModal={this.props.handleOpenReactiveRoleModal} 
                                singleOwner={this.props.singleOwner} 
                                userStartupRole={this.props.userStartupRole} 
                                userEditSelf={this.props.userEditSelf} 
                                initVal={this.props.initVal} 
                                options={rolesOptions} 
                                // title={'Roles'} 
                                getRoleName={this.props.getRoleName} 
                                handleOpenDeactivateRoleModal={this.props.handleOpenDeactivateRoleModal} 
                                handleOpenChangeRoleModal={this.props.handleOpenChangeRoleModal} 
                                onChange={(e) => { }} />
                        </div>
                    </Popover>
            </div >
        )
    }
}

export default withStyles(styles, { withTheme: true })(PopoverShareProfile);

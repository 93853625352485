import { useState, useCallback } from 'react'



const zoomStep = 1;
const maxScale = 5;
const minScale = 1;
const defaultScale = minScale;
const defaultRotate = 0;

interface StateImage {
    rotate: number,
    scale: number
}

const defaultStateImage = {
    rotate: defaultRotate,
    scale: defaultScale
}


export default function useImageActions() {

    const [stateImage, setStateImage] = useState<StateImage>(defaultStateImage);

    const zoomIn = useCallback(
        () => setStateImage(pre => {
            const newScale = pre.scale + zoomStep;
            return { ...pre, scale: newScale <= maxScale ? newScale : maxScale }
        }), []);


    const zoomOut = useCallback(() =>
        setStateImage(pre => {
            const newScale = pre.scale - zoomStep;
            return { ...pre, scale: newScale >= minScale ? newScale : minScale }
        }), [])

    const reset = useCallback(
        () => setStateImage(defaultStateImage), [])

    const rotate = () => {
        setStateImage(pre => {
            return { ...pre, rotate: pre.rotate === 270 ? 0 : pre.rotate + 90 }
        });
    };



    return ({ zoomIn, zoomOut, reset, rotate, stateImage });
}

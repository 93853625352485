import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import classes from "./Loading.module.scss";
import { Text } from "../index";

/*
    Properties:
        show: property to show loading
        label: property to show under the loading
*/

class Loading extends Component {
  render() {

    let {
      show,
      label,
      labelStyle,
      loadingStyle,
      showChildren,
      inline,
      className,
    } = this.props;

    const classContent = inline === true ? classes.LoadingInline : classes.Loading;

    if (show) {
      return (
        <React.Fragment>
          <div className={`${className} ${classContent}`} style={loadingStyle}>
            <CircularProgress />
            <span style={labelStyle}>
              <Text uuid={label} />
            </span>
          </div>
          {showChildren === true ? this.props.children : null}
        </React.Fragment>
      );
    } else {
      return inline !== true ? this.props.children : null;
    }
  }
}

export default Loading;
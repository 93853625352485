import React, {useMemo} from 'react'
import {TextAreaInput, TextInput,TextType} from "../../../../../../../v1/components";
import {Field} from "formik";
import _ from 'lodash'
import classes from "../Styles.module.scss";
import ClaraCountrySelector from "../../../../../../../v1/components/Form/ClaraCountrySelector/ClaraCountrySelector";
import Checkbox from "../../../../../../inputs/Checkbox/Checkbox";
import Line from "../../../../../../generic/Line/Line";
import Mustache from "mustache";

const EditCompanyDetailsModal=({nameHandle, companyTitle, companyField, tooltips, showTopLevelOption = true,groupCompany,...props}) => {
    const tooltipHandleBars = (tooltip) => {
        return Mustache.render(tooltip, {company: nameHandle})
    }

    const tooltipsRendered = useMemo(() => {
        return _.mapValues(tooltips,(value, key) => {
         if (key.match(companyTitle)) {
             return tooltipHandleBars(value)
         }
         else return value
     })
    },[tooltips])

    return(<>
        <h1 className={classes.title}>
            Edit Details
        </h1>
        <Field name={`company.name`}>
			{({ field, form }) => (
			<TextInput
				type='text'
				field={field}
				required={true}
				label={`${companyTitle} name `}
				classNameInput={{ root: classes.ContainerRow }}
				form={form}
				placeholder={`${companyTitle} name`}
				tooltip={tooltipHandleBars(tooltipsRendered[`${companyTitle}'s Name`])}
			/>)}
		</Field>
		<Field name='company.registeredNumber'>
                                {({ field, form }) => (
                                    <TextInput
                                        classNameInput={{ root: classes.ContainerRow }}
                                        required={true}
                                        type='text'
                                        label='Company number '
                                        field={field}
                                        form={form}
                                        placeholder={'Company number'}
										tooltip={tooltipHandleBars(tooltipsRendered[`${companyTitle}'s Company Number`])}
										/>
                                )}
                            </Field>
        <div className={` ${classes.Select}`}>
            <Field name={`company.country`}>
                {({ field, form }) => (
                    <ClaraCountrySelector
						isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                        jurisdictionsEnabled={true}
                        countryField={`company.country`}
                        label={'Country of incorporation '}
                        jurisdictionField={`company.jurisdiction`}
                        jurisdictionLabel={'Jurisdiction '}
                        returnCountryAsString={true}
                        otherJurisdictionField={`company.otherJurisdiction`}
                        setFieldValue={form.setFieldValue}
                        initialValues={{
                            jurisdiction: _.get(form.values, `${companyTitle}.otherJurisdiction`)
                        }}
                        additionalStyle={'ClaraCountrySelectorModal'}
                        tooltips={{
                            country: tooltipsRendered[`${companyTitle}'s Country`],
                            jurisdiction: tooltipsRendered[`${companyTitle}'s Jurisdiction`],
                            other: tooltipsRendered['Other Jurisdiction']
                        }}
                    />
                )}
            </Field>
        </div>
		<Field name={`company.type`}>
			{({ field, form }) => (
				 <TextType
				 required={true}
				 type='text'
				 groupCompany={groupCompany}
				 label='Company type '
				 field={field}
				 classNameInput={{ root: classes.ContainerRow }}
				 form={form}
				 placeholder={'Company type'}
				 tooltip={tooltipHandleBars(tooltipsRendered[`${companyTitle}'s Company Type`])}
				 />
			)}
    	</Field>
        {
            showTopLevelOption &&
            <>
				<Line className={classes.line}/>
				<Field name='company.isHoldingCompany'>
					{({ field, form }) => {
						return (<>
						<div style={{
						marginTop: '1.5rem'
					}}>
						<Checkbox
							onChange={(value) => {
								form.setFieldValue(field.name,value)
							}}
							value={field.value}
							className={{checkbox: classes.checkBoxEdit}}
							isMultiple={false}
							label={'The group company is the top level holding company for the startup.'}
						/>
						</div>
						</>)
					}}
            	</Field>
            <Line className={classes.line}/>
            </>
        }
			<div className={` ${classes.Select}`}>
				<Field name={`company.registeredAddress.country`}>
					{({ field, form }) => (
						<ClaraCountrySelector
							isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
							countryField={`company.registeredAddress.country`}
							jurisdictionsEnabled={false}
							label={'Registered address '}
							returnCountryAsString={true}
							setFieldValue={form.setFieldValue}
							additionalStyle={'ClaraCountrySelectorModal'}
							tooltips={{country: tooltipsRendered[`${companyTitle}'s Registered Address - Country`]
							}}
						/>
					)}
				</Field>
			</div>
			<div className={classes.ContainerRow}>
				<Field  name={`company.registeredAddress.street`} >
					{({ field, form }) => (
						<TextAreaInput
							type='text'
							label={' '}
							field={field}
							form={form}
							placeholder={"Address"}
							tooltip={tooltipsRendered[`${companyTitle}'s Registered Address - Address`]}
						/>)}

				</Field>
			</div>
			<Field  name={`company.registeredAddress.city`}>
				{({ field, form }) => (
					<TextInput
						type='text'
						field={field}
						label=' '
						classNameInput={{ root: classes.ContainerRow }}
						form={form}
						placeholder={'City'}
						tooltip={tooltipsRendered[`${companyTitle}'s Registered Address - City`]}
					/>)}
			</Field>
			<Field  name={`company.registeredAddress.state`}>
				{({ field, form }) => (
					<TextInput
						label=' '
						type='text'
						field={field}
						classNameInput={{ root: classes.ContainerRow }}
						placeholder={'State / Region'}
						form={form}
						tooltip={tooltipsRendered[`${companyTitle}'s Registered Address - State`]}
					/>
				)}
			</Field>
			<Field  name={`company.registeredAddress.zipCode`}>
				{({ field, form }) => (
					<TextInput
						type='text'
						field={field}
						label=' '
						classNameInput={{ root: classes.ContainerRow }}
						placeholder={"Post code"}
						form={form}
						tooltip={tooltipsRendered[`${companyTitle}'s Registered Address - Post Code`]}
					/>
				)}
			</Field>
		</>
	)
};

export default EditCompanyDetailsModal;

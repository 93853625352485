import React, { Component } from "react";
import { connect } from "react-redux";
import { ButtonModalOptions, Table, ToolTip } from "../../../index";
import { Paper } from "@material-ui/core";
import helper from "../../../../utils/helper";
import classes from "../DocumentTable.module.scss";
import ServerConnect from "../../../../utils/ServerConnect";
import { Constants } from '../../../../utils/constants';
import { goToPage, goToPrevPage } from '../../../../../store/actions/page'
import _ from "lodash";
import moment from "moment";
import DeleteDocumentMatters from '../../../Form/ClaraForm/DeleteDocumentMatters';

class DocumentTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            showDelete: false,
            deleteIds: [],
            rows: props.data
        }

    }


    componentWillReceiveProps(nextProps) {
        this.setState({
            rows: nextProps.data
        })

    }


    downloadDocumentAsDoc = (row) => {
        let url = ServerConnect.getUrlFile(row.file.id);
        ServerConnect.downloadFileAsDoc(url, row.file.name);
    };

    handleDelete = (row) => {
        this.setState({
            showDelete: true,
            selectedRow: { ...row, matterId: this.props.matterId }
        })
    }

    handleRemoveElement = (id) => {
        _.remove(this.state.rows, row => row.id === id);
    }



    handleReplaceDocuments = (row) => {
        this.props.goToPage(Constants.PAGES.addDocumentKYC, { type: "replace", matterId: this.props.matterId, documentId: row.id, documentName: row.name })
    }
    handleViewDocument = (row) => {

        const typeFile = row.file.name.split('.').pop();
        if (["docx"].includes(typeFile)) {
            this.props.goToPage(Constants.PAGES.viewFileDocx, { fileId: row.file.id, tab: 'documents', matterId: this.props.matterId });
        } else {
            let url = ServerConnect.getUrlFile(row.file.id);
            ServerConnect.openFile(url, row.file.name);
        }
    };

    render() {
        let options = [];
        options.push({ isView: true, label: "View Document", action: this.handleViewDocument, className: `${classes.ViewICon}` })
        options.push({ label: "Download Document", action: this.downloadDocumentAsDoc, className: `${classes.DownloadIcon}`, actionAccess: Constants.ACTIONS.DOWNLOAD_DOCUMENT })
        options.push({ isReplace: true, label: "Replace Document", action: this.handleReplaceDocuments, className: `${classes.ReplaceIcon}`, actionAccess: Constants.ACTIONS.MANAGE_MATTER })
        options.push({ isDelete: true, label: "Delete Document", action: this.handleDelete, className: `${classes.DeleteIcon}`, actionAccess: Constants.ACTIONS.MANAGE_MATTER })
        let fields = [
            {
                label: "Name",
                key: "name",
                order: false,
                "decorator": (props) => {
                    return (

                        <ToolTip
                            className={`${classes.letterCell} ${classes[props.row.kind]} `}
                            data={props.row.name}
                            type={"text"}
                            timeShow={0}
                            limit={85}
                        />
                    )
                }
                ,
                className: classes.Col1
            },
            {
                label: "Type",
                key: "type",
                order: false,
                "decorator": (props) => (

                    <ToolTip
                        className={`${classes.letter}`}
                        data={_.get(props.row, "type.label", " ")}
                        type={"text"}
                        timeShow={0}
                        limit={18}
                    />
                ),
                className: classes.Col2


            }, {
                label: "Date",
                key: "date",
                order: false,
                "decorator": (props) => {
                                     
                    return (< React.Fragment >
                        < ToolTip
                            className={`${classes.letter}`}
                            data={moment(props.row.updatedAt).format('DD-MM-YYYY')}
                            type={"text"}
                            timeShow={0}
                            limit={18}
                        />
                    </React.Fragment>)
                },
                className: classes.Col2


            }
            ,
            {
                label: "",
                "decorator": (props) => (
                    <span className={classes.buttomModal}>
                        <ButtonModalOptions title={null}
                            options={
                                options.filter(op => {
                                    // if (op.isReplace) {
                                    //     return !_.includes(invalidDocReplaceAndUpload, props.row.type.code);
                                    // }
                                    // if (op.isDelete) {
                                    //     return "MISCELLANEOUS_DOCUMENT" === props.row.type.code;
                                    // }
                                    if (op.isView) {
                                        return ["docx", "pdf"].includes(props.row.file.name.split(".").pop())
                                    }
                                    return true;
                                })
                            }

                            params={props.row} />
                    </span>
                ),
                className: classes.Col7
            }
        ];
        let { rows } = this.state

        let { filter = "" } = this.props;


        return (
            <>

                <Table
                    fields={fields}
                    data={rows}
                    filter={filter}
                />
                <DeleteDocumentMatters
                    show={this.state.showDelete}
                    title={"Delete Share Certificates"}
                    handleClose={() => this.setState({ showDelete: false })}
                    data={this.state.selectedRow}
                    callbacks={this.handleRemoveElement}
                />
            </>

        )
    };

}
const mapStateToProps = state => ({
    session: state.session,
    form: state.form
});
const mapDispatchToProps = (dispatch) => ({
    goToPage: (page, params) => {
        goToPage(dispatch, page, params)
    },
    goToPrevPage: (reload, defaultPage, params) => {
        goToPrevPage(dispatch, reload, defaultPage, params)
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(DocumentTable)

import { useMemo } from 'react';
import useGetGroupCompaniesForAccelerators from './useGetGroupCompaniesForAccelerators';
import useSession from '../../../session/hooks/useSession'
import _ from 'lodash';

import useGetFounders from './useGetFounders';
import useUpsertDocument from 'src/modules/startup/graphql/document/mutation/useUpsertDocument'
const useServices = () => {
  const { mutation: updateDocument } = useUpsertDocument({});
  const { manualQuery: getFounders  } = useGetFounders({}, {});
  const { manualQuery: getCompaniesData  } = useGetGroupCompaniesForAccelerators({},{});
  const { startupId } = useSession();

  return useMemo(() => ({
    getFoundersData: async (context, event) => {
      
      const result = await getFounders( {variables:{startupId} });
      return _.get(result,"data.getStartup.founders",[]);
    },  
    getCompaniesData: async (context, event) => {
      const allCompaniesData = await getCompaniesData({
        variables:{
          startupId
        }
      })
      return allCompaniesData;
    },
    upsertDocument: async (context, event) => {
      
      const responseTitle = _.get(context, "UploadDocumentStep.name", "");
      const responsefile = _.get(context, "UploadDocumentStep.file", {});
      const founders = _.get(context, "SelectFounderStep.founders", {});
      const company = _.get(context, "UploadDocumentStep.company", "");
      const variables = {
        startupId,
        agreementData: {
          title: responseTitle,
          file: responsefile,
          type: "FOUNDERS_AGREEMENT",
          parties: founders,
          status: "Uploaded",
          subCategories: ["constitutionalDocuments"],
          groupCompanies: [
            {
              groupCompany: company,
            }
          ],
        },
      }
      return await updateDocument({ variables });
    }
  }), [getCompaniesData, getFounders, startupId]);
}

export default useServices;
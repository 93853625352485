
import { RoleFields } from "../../../../modules/stakeholder/graphql/typePolicies";
import useMutation from "src/hooks/custom/useMutation";
const mutation = `  ${RoleFields}
                    mutation upsertComponentStakeholder(
                        $startupId: ID!
                        $stakeholder: ComponentStakeholderInputType! 
                        $isAnEntity:Boolean!
                      ) {
                        upsertComponentStakeholder(startupId: $startupId, stakeholder: $stakeholder) {
                            id
                            fullName
                            title
                            email
                            isAnEntity
                            authorizedSignatory @include(if:$isAnEntity)
                            roles @skip(if:$isAnEntity)
                            { 
                                ...RoleFields 
                            }  
                            address @skip(if:$isAnEntity) 
                            {
                                city
                                state
                                country {name code}
                                zipCode
                                street
                            }
                        }
                      }`
  const useUpsertStakeholder =(config)=>{
    const [mutationCall, loading] = useMutation(mutation, config)
    return [mutationCall, loading]
  }
  export default useUpsertStakeholder
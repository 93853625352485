import { Field, Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
//import {ScrollbarConsumer,ScrollbarProvider} from "../../../../../components/generic/Scrollbar/ScrollbarContext";
import scrollIntoView from 'scroll-into-view';
import * as Yup from 'yup';
import AutocompleteSelect from '../../../../../components/generic/AutocompleSelect/AutocompleteSelect';
import useSession from '../../../../../modules/session/hooks/useSession';
import { resetAutcompletes, updateAutocomplete } from "../../../../../store/actions/autocomplete";
import {
    ClaraInputPassport,
    ClaraInputPhone,
    ClaraUpload, Loading, OptionsButtonInput,
    TextAreaInput
} from '../../../../components';
import ClaraCountrySelector from "../../../../components/Form/ClaraCountrySelector/ClaraCountrySelector";
import helper, { context } from '../../../../utils/helper';
import TextInput from "../../Inputs/TextInput";
import { AddIndividual, AddIndividualCorporate } from '../../subForms/FormationKYC';
import { AddEntity } from '../Components';
import classesForm from '../Form.module.scss';
import RegisteredAddressInput from '../RegisteredAddressInput/RegistedAddressInput';
import { CompanyFormSchemaStep1, CompanyFormSchemaStep2 } from '../validations/validationForms';
import AutoSaveComponent from "./../../../../../components/forms/Form/AutoSaveComponent";
import { useGetDirectorsAndShareholders } from './useGetDirectorsAndShareholders';
const CompanyForm = ({ initialValues, keyAutoSave,onSubmit, propsForm }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        resetAutcompletes(dispatch);
        
        
    }, [])
    if (window.location.href.indexOf("addKYCToAMatter") > -1) {
        const tagManagerArgs = {
            dataLayer: {
                event: "PageView",
                path: "corporateKYC/corporateForm",
                pageName: "Corporate KYC - Corporate Form"
            },
            dataLayerName: "PageDataLayer",
        };
        TagManager.dataLayer(tagManagerArgs);
    }
    const handleOptionsButtonClick = (field, form, value) => {
        form.setFieldValue(field.name, value);
    }
    const [loading,setLoading]= useState(true);
    const handleLoading = (value) =>{
        setLoading(value)
    }
    const { containerFormRef } = useContext(context);

    const [labelButton, setLabelButton] = useState("Next")

    const [validateSchema, setValidateSchema] = useState(Yup.object().shape(CompanyFormSchemaStep1));
    const [nextStep, setnextStep] = useState(0);
    const handleNext = async (values, validateForm, setFieldError) => {
        const errorForm = await validateForm();
        if (errorForm && Object.keys(errorForm).length>0){
            const errorRef = document.querySelector('[hasError="true"]');
            if (errorRef){
                if (errorRef.tagName =="A"){ 
                    document.querySelector('.kashif').parentElement.parentElement.scrollTo(0,document.querySelector('[hasError="true"]').offsetTop)
                }else if (errorRef.tagName =="SPAN"){ 
                    document.querySelector('[hasError="true"]').querySelector("input").focus()
                }else if(errorRef.type=="checkbox"){
                    document.querySelector('.kashif').parentElement.parentElement.scrollTo(0,document.querySelector('[hasError="true"]').parentElement.parentElement.parentElement.offsetTop)
                }else {
                    errorRef.focus()
                }
                
            }
            return false;
        }
        if (!nextStep) {
            document.getElementById('layoutForm').scrollTo(0,0);
            setnextStep(nextStep + 1);
            setLabelButton("Save");

            
            setValidateSchema(Yup.object().shape(CompanyFormSchemaStep2));


        }
        else {
            childAutoSave.clear();
            onSubmit(mapCountryValues(values))
        }

    }

    const mapCountryValues = (values) => {

        let { countryOfIncorporation, ...restOfValues } = values;

        countryOfIncorporation = (countryOfIncorporation.code) ? countryOfIncorporation.code : countryOfIncorporation;

        return { ...restOfValues, countryOfIncorporation }

    }
    const [defaultValues,setDefaultValues] = useState(initialValues);
    const params = useParams();
    let childAutoSave = useRef();
    const { user } = useSession();
    const keysAutoSave = {
        key: _.get(defaultValues,"formKey","FORM_INDIVIDUAL"),
        key2: keyAutoSave,
        id: _.get(defaultValues,"id"),
        params: JSON.stringify(params),
        userId: user._id
    }
    const handleUpdateInitialValues = (values)=>{
        setDefaultValues(values)
    }
    const handleSubmitAuto = async (values)=>{
        
        childAutoSave.clear();
        onSubmit(values)
    }
    return (
        <React.Fragment>

            {
                (_.has(initialValues, "formKey")) &&
                <Formik
                    initialValues={helper.getInitialValues(defaultValues)}
                    // initialValues={initialValues}
                    enableReinitialize={true}
                    onSubmit={handleSubmitAuto}
                    validationSchema={validateSchema}
                    isInitialValid={false}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    {({
                        values,
                        errors,
                        dirty,
                        handleSubmit,
                        isSubmitting,
                        handleReset,
                        setFieldValue,
                        initialValues,
                        isValid,
                        setFieldError,
                        validateForm,
                    }) => {
                        return (

                            <React.Fragment>
                                <AutoSaveComponent ref={node=>childAutoSave = node}  keys={keysAutoSave} step={"1"} values={values} updateValues={handleUpdateInitialValues} onLoading={handleLoading} />
                                <Loading showChildren={false} show={loading}>
                                    <div className={classesForm.containerData} >
                                        {!nextStep ?
                                            <Step1 handleOptionsButtonClick={handleOptionsButtonClick} values={values}
                                                setFieldValue={setFieldValue} isFlow={_.get(propsForm, "isFlow", true)} draftId={_.get(propsForm, 'draftId', '')} /> :
                                            <Step2 handleOptionsButtonClick={handleOptionsButtonClick} values={values} setFieldValue={setFieldValue} draftId={_.get(propsForm, 'draftId', '')} />
                                        }
                                    </div>
                                    <hr />
                                    <div className={`botoneraButtons`}>
                                        <button className={`genericBlue`} onClick={() => handleNext(values, validateForm, setFieldError)} > {labelButton} </button>
                                    </div>
                                    <div>
                                    </div>
                                </Loading>

                            </React.Fragment>
                        )
                    }
                    }

                </Formik>
            }
        </React.Fragment>
    )
}

export default CompanyForm;


const Step1 = ({ handleOptionsButtonClick, values, setFieldValue, isFlow, draftId }) => {

    useEffect(() => {
        if (typeof _.get(values, "countryOfIncorporation") === "string") {
            setFieldValue("countryOfIncorporation.code", _.get(values, "countryOfIncorporation"));
        }

    }, [])


    const DirectorAndManager = (index, values, name) => {
        if (_.has(values, "companyName")) {

            return (
                <>
                    <Field name={`${name}[${index}].companyName`}>
                        {({ field, form }) => (
                            <TextInput
                                field={field}
                                form={form}
                                label={"Company name as on Certificate of incorporation:"}
                                autoComplete={false}
                            />

                        )}

                    </Field>
                    <Field name={`${name}[${index}].firstName`}>
                        {({ field, form }) => (
                            <TextInput
                                field={field}
                                form={form}
                                label={"First Legal Name:"}
                                autoComplete={false}
                            />

                        )}
                    </Field>

                    <Field name={`${name}[${index}].lastName`}>
                        {({ field, form }) => (
                            <TextInput
                                field={field}
                                form={form}
                                label={"Last Legal Name:"}
                                autoComplete={false}
                            />

                        )}
                    </Field>
                    <Field name={`${name}[${index}].email`}>
                        {({ field, form }) => (
                            <TextInput
                                field={field}
                                type = 'email'
                                form={form}
                                label={"Signatory Email Address:"}
                                autoComplete={false}
                            />

                        )}

                    </Field>

                </>
            )
        }
        return (
            <>



                <Field name={`${name}[${index}].firstName`}>
                    {({ field, form }) => (
                        <TextInput
                            field={field}
                            form={form}
                            label={"First Legal Name:"}
                            autoComplete={false}
                        />

                    )}
                </Field>

                <Field name={`${name}[${index}].lastName`}>
                    {({ field, form }) => (
                        <TextInput
                            field={field}
                            form={form}
                            label={"Last Legal Name:"}
                            autoComplete={false}
                        />

                    )}
                </Field>
                <Field name={`${name}[${index}].email`}>
                    {({ field, form }) => (
                        <TextInput
                            field={field}
                            type = 'email'
                            form={form}
                            label={"Email Address:"}
                            autoComplete={false}
                        />

                    )}

                </Field>
            </>
        )

    }
    const params = useParams();

    const addsDirector = [
        {
            type: "directorOrShareholder",
            label: "Add an Individual as a Director",
            // textWarning: "This will create a new stakeholder for your startup. You can add more details later from the stakeholder's profile page.",
            placeholder: "Enter Stakeholder's Name",
            matterId: params.matterId,
            form: AddIndividual,
            formation: true,
            stateApplication: "matterId",
            additionalParams: [{
                paramName: "isDraft",
                paramType: "Boolean",
                paramValue: isFlow
            }, {
                paramName: "isCorporate",
                paramType: "Boolean",
                paramValue: false
            }, {
                paramName: "draftId",
                paramType: "ID",
                paramValue: draftId
            }],
            paramsReturn: `
            firstName
            lastName
            email
            `
        },
        {
            type: "directorOrShareholder", 
            label: "Add a Company as a Director",
            // textWarning: "This will create a new stakeholder for your startup. You can add more details later from the stakeholder's profile page.",
            placeholder: "Enter Stakeholder's Name",
            matterId: params.matterId,
            stateApplication: "matterId",
            form: AddIndividualCorporate,
            formProps: {},
            additionalParams: [{
                paramName: "isDraft",
                paramType: "Boolean",
                paramValue: isFlow
            }, {
                paramName: "isCorporate",
                paramType: "Boolean",
                paramValue: true
            },
            {
                paramName: "draftId",
                paramType: "ID",
                paramValue: draftId
            }],
            paramsReturn: `
            companyName
            email
            firstName
            lastName
            `
        }
    ]
    const addsQuestionnarieDirector = [
        {
            type: "questionnaireDirector",
            label: "Add an Individual as a Director",
            // textWarning: "This will create a new stakeholder for your startup. You can add more details later from the stakeholder's profile page.",
            placeholder: "Enter Stakeholder's Name",
            matterId: params.matterId,
            form: AddIndividual,
            formation: true,
            stateApplication: "matterId",
            additionalParams: [{
                paramName: "isDraft",
                paramType: "Boolean",
                paramValue: isFlow
            }, {
                paramName: "isCorporate",
                paramType: "Boolean",
                paramValue: false
            }, {
                paramName: "draftId",
                paramType: "ID",
                paramValue: draftId
            }],
            paramsReturn: `
            firstName
            lastName
            email
            `
        },
        {
            type: "questionnaireDirector",
            label: "Add a Company as a Director",
            // textWarning: "This will create a new stakeholder for your startup. You can add more details later from the stakeholder's profile page.",
            placeholder: "Enter Stakeholder's Name",
            matterId: params.matterId,
            stateApplication: "matterId",
            form: AddIndividualCorporate,
            formProps: {},
            additionalParams: [{
                paramName: "isDraft",
                paramType: "Boolean",
                paramValue: isFlow
            }, {
                paramName: "isCorporate",
                paramType: "Boolean",
                paramValue: true
            },
            {
                paramName: "draftId",
                paramType: "ID",
                paramValue: draftId
            }],
            paramsReturn: `
            companyName
            email
            firstName
            lastName
            `
        }
    ]
    
    

    const addsShareholders = [
        {
            type: _.get(values,"formKey",[] ).includes("QUESTIONNAIRE") ?  "questionnaireShareholder" :"directorOrShareholder",
            label: "Add an Individual as a Shareholder",
            // textWarning: "This will create a new stakeholder for your startup. You can add more details later from the stakeholder's profile page.",
            placeholder: "Enter Stakeholder's Name",
            matterId: params.matterId,
            form: AddIndividual,
            stateApplication: "matterId",
            formProps: {},
            additionalParams: [{
                paramName: "isDraft",
                paramType: "Boolean",
                paramValue: isFlow
            }, {
                paramName: "isCorporate",
                paramType: "Boolean",
                paramValue: false
            }, {
                paramName: "draftId",
                paramType: "ID",
                paramValue: draftId
            }],
            paramsReturn: `
            firstName
            lastName
            email
            `
        },
        {
            type: _.get(values,"formKey",[] ).includes("QUESTIONNAIRE")  ?  "questionnaireShareholder" :"directorOrShareholder",
            label: "Add a Company as a Shareholder",
            // textWarning: "This will create a new stakeholder for your startup. You can add more details later from the stakeholder's profile page.",
            placeholder: "Enter Stakeholder's Name",
            matterId: params.matterId,
            stateApplication: "matterId",
            form: AddIndividualCorporate,
            formProps: {

            },
            additionalParams: [{
                paramName: "isDraft",
                paramType: "Boolean",
                paramValue: isFlow
            }, {
                paramName: "isCorporate",
                paramType: "Boolean",
                paramValue: true
            }, {
                paramName: "draftId",
                paramType: "ID",
                paramValue: draftId
            }],
            paramsReturn: `
            companyName
            email
            firstName
            lastName
            `
        }
    ]

    const mapList = (list) => {
        return _.map(list, element => {
            return _.has(element, "companyName") ? { id: element.id, label: element.companyName }
                : { id: element.id, label: `${element.firstName} ${element.lastName}` }
        })
    }

    const shareholder = _.get(values, "formKey",[]).includes("SHAREHOLDER") ? { isShareholder: true, idShareholder: _.get(values, "id") } : { isShareholder: false, idShareholder: null }
    const { list } = useGetDirectorsAndShareholders({ matterId: params.matterId, isDraft: isFlow, ...shareholder, draftId });

    


    return (
        <React.Fragment>

            <h1>Company Details</h1>

            <Field name={"companyName"}>
                {({ field, form }) => (
                    <TextInput
                        autoComplete={false}
                        field={field}
                        form={form}
                        label={"Company Name:"}
                        placeholder={"Name..."}
                        tooltip={"The full legal name of the Company. This is the name under which the Company is registered on the companies registry in its jurisdiction of incorporation."}
                        data-zuko-name='Company name'

                    />

                )}

            </Field>

            <Field name={"otherTradingName"}>
                {({ field, form }) => (
                    <TextInput
                        autoComplete={false}
                        field={field}
                        form={form}
                        label={"Other Trading Names:"}
                        placeholder={"Other Trading Names..."}
                        data-zuko-name="Other Trading Names"
                    />

                )}

            </Field>

            <Field name={"registrationNumber"}>
                {({ field, form }) => (
                    <TextInput
                        autoComplete={false}
                        field={field}
                        form={form}
                        label={"Company Number:"}
                        placeholder={"Company Number..."}
                        data-zuko-name='Company Number'
                    />

                )}

            </Field>

            <Field name={"TRNNumber"}>
                {({ field, form }) => (
                    <TextInput
                        autoComplete={false}
                        field={field}
                        form={form}
                        label={"Tax Registration Number:<br>(if any)"}
                        placeholder={"Tax Registration Number..."}
                        data-zuko-name='Tax Registration Number'
                    />

                )}

            </Field>

            <ClaraCountrySelector
                label={"Country of Incorporation:"}
                data-zuko-name='Country of Incorporation'
                jurisdictionsEnabled={true}
                additionalStyle={"addKyc"}
                countryField={"countryOfIncorporation"}
                jurisdictionField={"jurisdictionOfIncorporation"}
                setFieldValue={setFieldValue}
            />

            <hr />

            <h1>Registered Address</h1>

            <RegisteredAddressInput name={"principalAddress"} label={"Registered Address"} data-zuko-name='Registered Address' />


            <Field name={"samePrincipalPlaceOfBusinessAndLegal"}>
                {({ field, form }) => (
                    <OptionsButtonInput
                        field={field}
                        form={form}
                        className={{ root: classesForm.buttonOptions }}
                        label={"Is the principal place of business the same address as above?"}
                        data-zuko-name='Is the principal place of business the same address as above?'
                        data-zuko-id='OptionsButtonInput'
                        options={[
                            {
                                "id": true,
                                "text": "Yes"
                            },
                            {
                                "id": false,
                                "text": "No"
                            }
                        ]}
                        onClick={handleOptionsButtonClick}
                    // params={this.props.params}
                    // functions={this.props.functions}
                    />

                )}
            </Field>

            {!_.get(values, "samePrincipalPlaceOfBusinessAndLegal", true) &&

                <RegisteredAddressInput name={"address"} label={"Principal Place of Business:"} data-zuko-name='Principal Place of Business' />

            }

            { _.get(values, "formKey",[]).includes("QUESTIONNAIRE")  && <>
                <hr />
                <AddEntity
                    title={"Directors"}
                    description={`Please add <strong>directors</strong> in <strong>${_.get(values, "companyName", "Company Name")}</strong>.  If your Company does not have Directors, please add the Manager listed on the Company's Commercial Licence.`}
                    name={"directors"}
                    values={values}
                    label={"Add Director:"}
                    data-zuko-name='Add Director' 
                    data-zuko-id='AddEntity'
                    renderItem={DirectorAndManager}
                    mapList={mapList}
                    adds={addsQuestionnarieDirector}
                    list={list}
                    calbacks={{
                        updateData: (a, b) => {
                            console.log("Update data", a, b);
                        }
                    }}
                    type={"questionnarieDirector"}
                />
            </>
            }
            {!_.get(values, "formKey",[]).includes("QUESTIONNAIRE")  && <>
                <hr />
                <AddEntity
                    title={"Directors"}
                    description={`Please add <strong>directors</strong> in <strong>${_.get(values, "companyName", "Company Name")}</strong>.  If your Company does not have Directors, please add the Manager listed on the Company's Commercial Licence.`}
                    name={"directors"}
                    values={values}
                    label={"Add Director:"}
                    data-zuko-name='Add Director'
                    data-zuko-id='AddEntity'
                    renderItem={DirectorAndManager}
                    mapList={mapList}
                    adds={addsDirector}
                    list={list}
                    calbacks={{
                        updateData: (a, b) => {
                            console.log("Update data", a, b);
                        }
                    }}
                    type={"directorOrShareholder"}
                />
            </>
            }
            {_.get(values, "formKey",[]).includes("COMPANY_FORM") &&
                <>
                    <AddEntity
                        title={"Shareholders"}
                        description={`Please add all <strong>Shareholders</strong> who own <strong>15% or more</strong> of the shares in <strong>${_.get(values, "companyName", "Company Name")}</strong>.`}
                        name={"shareholders"}
                        values={values}
                        label={"Add Shareholder:"}
                        renderItem={DirectorAndManager}
                        adds={addsShareholders}
                        list={list}
                        mapList={mapList}
                        type={"directorOrShareholder"}
                    />
                </>
            }
        </React.Fragment>
    )


}




const Step2 = ({ handleOptionsButtonClick, values, setFieldValue }) => {
    const dispatch = useDispatch();
    const ref=useRef()
    const handleDateChange = (e, field, form) => {

        const date = e === null ? null : moment(e).format("YYYY-MM-DD");
        form.setFieldValue(field.name, date);

    }
    useEffect(()=>{
        if(ref.current){
            scrollIntoView(ref.current,{align:{top:0}})
        }
    },[ref])

    return (
        <React.Fragment>

            <h1 ref={ref}>Key Company Documents</h1>
            <article>Upload the following documents for {_.get(values, "companyName", "the Company")}. If the original document is not in English, upload the original with an English translation attached. The register of directors and shareholders, or equivalent document, must be dated within the past two months.</article>

            <Field name="showingDirectorsRegistrationDoc">
                {({ field, form }) => (
                    <ClaraUpload
                        name={`filepond`}
                        label={"Current document showing directors:"}
                        data-zuko-name="Current document showing directors"
                        field={field}
                        form={form}
                        className={classesForm.fileUpload}
                        tooltip={'We require a document that lists the Directors of the Company which is and it must be dated within the past two months e.g. an official extract or certificate of incumbency listing the Directors and ordered from the companies registry or a certified copy of the register of directors from the registered agent.'}
                    />
                )}
            </Field>

            <Field name="showingShareholdersRegistrationDoc">
                {({ field, form }) => (
                    <ClaraUpload

                        name={`filepond`}
                        label={"Current document showing shareholders and their shareholding:"}
                        data-zuko-name="Current document showing shareholders and their shareholding"
                        field={field}
                        form={form}
                        className={classesForm.fileUpload}
                        tooltip={"We require a document that lists the shareholders of the company and the number of shares they hold. This document must be dated within the past two months e.g.  an official extract or certificate of incumbency showing this information and ordered from companies registry or a certified copy of the register of shareholders (or members) from the registered agent."}
                    />
                )}
            </Field>

            <Field name="certificateOfIncorporationOrCommercialLicenseDoc">
                {({ field, form }) => (
                    <ClaraUpload

                        name={`filepond`}
                        label={"Certificate of Incorporation or Commercial License:"}
                        data-zuko-name="Certificate of Incorporation or Commercial License"
                        field={field}
                        form={form}
                        className={classesForm.fileUpload}
                    // tooltip={tooltips['Upload your document']}
                    />
                )}
            </Field>

            <Field name="memorandumAndArticlesOfAssociationDoc">
                {({ field, form }) => (
                    <ClaraUpload

                        name={`filepond`}
                        label={"Memorandum & Articles of Association:"}
                        data-zuko-name="Memorandum and Articles of Association"
                        field={field}
                        form={form}
                        className={classesForm.fileUpload}
                    // tooltip={tooltips['Upload your document']}
                    />
                )}
            </Field>
            <hr />

            <h1>Authorised Signatory</h1>

            <article>Please provide the details of the Director of the Company who is authorised to confirm our Engagement Terms on behalf of {_.get(values, "companyName", "the Company")}.</article>
            <Field name={"autocompleteSignatory"}>
                {({ field, form }) => (
                    <AutocompleteSelect
                        autocompleteKey={"individuals"}
                        fieldId={"email"}
                        callbackUpdate={(element, value) => {
                            if (_.get(element, "data.firstName")) {
                                form.setFieldValue("signatory.firstName", element.data.firstName)
                            }
                            if (_.get(element, "data.lastName")) {
                                form.setFieldValue("signatory.lastName", element.data.lastName)
                            }
                            if (_.get(element, "data.email")) {
                                form.setFieldValue("signatory.email", element.data.email)
                            }
                        }}
                    ></AutocompleteSelect>
                )}
            </Field>
            <Field name={"signatory.firstName"}>
                {({ field, form }) => (
                    <TextInput
                        autoComplete={false}
                        field={field}
                        form={form}
                        label={"First Names:"}
                        data-zuko-name="First Names"
                        placeholder={"First Names..."}
                        onChange={(e)=>{
                            updateAutocomplete(dispatch,`individuals`,`signatory`,_.get(form,`values.signatory.lastName`),"lastName");
                            updateAutocomplete(dispatch,`individuals`,`signatory`,_.get(form,`values.signatory.email`),"email");
                            updateAutocomplete(dispatch,`individuals`,`signatory`,e.target.value,"firstName");
                        }}
                        
                    />

                )}

            </Field>


            <Field name={"signatory.lastName"}>
                {({ field, form }) => (
                    <TextInput
                        autoComplete={false}
                        field={field}
                        form={form}
                        label={"Full Surname:"}
                        data-zuko-name="Full Surname"
                        placeholder={"Surname..."}
                        onChange={(e)=>{
                            updateAutocomplete(dispatch,`individuals`,`signatory`,_.get(form,`values.signatory.firstName`),"firstName");
                            updateAutocomplete(dispatch,`individuals`,`signatory`,_.get(form,`values.signatory.email`),"email");
                            updateAutocomplete(dispatch,`individuals`,`signatory`,e.target.value,"lastName");
                        }}
                    />

                )}

            </Field>


            <Field name={"signatory.jobTitle"}>
                {({ field, form }) => (
                    <TextInput
                        autoComplete={false}
                        field={field}
                        form={form}
                        label={"Job Title:"}
                        data-zuko-name="Job title"
                        placeholder={"Job Title..."}
                       
                    />

                )}

            </Field>


            <Field name={"signatory.email"}>
                {({ field, form }) => (
                    <TextInput
                        autoComplete={false}
                        type= 'email'
                        field={field}
                        form={form}
                        label={"Email Address:"}
                        data-zuko-name="Email Address"
                        placeholder={"Email Address..."}
                        onChange={(e)=>{
                            updateAutocomplete(dispatch,`individuals`,`signatory`,_.get(form,`values.signatory.firstName`),"firstName");
                            updateAutocomplete(dispatch,`individuals`,`signatory`,_.get(form,`values.signatory.lastName`),"lastName");
                            updateAutocomplete(dispatch,`individuals`,`signatory`,e.target.value,"email");
                        }}
                    />

                )}

            </Field>

            <ClaraCountrySelector
                jurisdictionsEnabled={false}
                label={"Nationality:"}
                data-zuko-name="Nationality"
                additionalStyle={"addKycCompany"}
                setFieldValue={setFieldValue}
                countryField={`signatory.nationality`}
                returnCountryAsString={true}
            />



            <Field name={`signatory.passportNumber`}>
                {({ field, form }) => (
                    <ClaraInputPassport
                        field={field}
                        form={form}
                        label={"Passport Number"}
                        placeholder={"Passport Number..."}
                    />
                )}
            </Field>

            <Field name='signatory.passportExpiryDate'>
                {({ field, form }) => (
                    <TextInput
                        autoComplete={false}
                        type='date'
                        label='Passport Expiry Date:'
                        data-zuko-name='Passport Expiry Date'
                        field={field}
                        form={form}
                        placeholder={"dd / mm / yyyy"}
                        prevComponentChange={e =>
                            handleDateChange(e, field, form)
                        }
                        autoComplete='off'
                    >

                    </TextInput>
                )}
            </Field>
            <Field name="signatory.signatoryPassportDoc">
                {({ field, form }) => (
                    <ClaraUpload
                        name={`filepond`}
                        label={"Upload Passport Image Here:"}
                        data-zuko-name="Upload Passport Image Here"
                        field={field}
                        form={form}
                        className={classesForm.fileUpload}
                    // tooltip={tooltips['Upload your document']}
                    />
                )}
            </Field>

            {_.get(values, "formKey") === "COMPANY_FORM" && <>
                <hr />
                <h1> Client  Key  Contact</h1>
                <article>Section Description: The Client Key Contact will be named in our Engagement Terms. This is the person we will take instructions from on behalf of {_.get(values, "companyName", "the Company")}.</article>

                <Field name={`clientKeyContact`}>
                    {({ field, form }) => (
                        <InputContact
                            field={field}
                            form={form}
                        />

                    )}

                </Field>


                <hr />
                <h1>Finance Contact</h1>
                <article>Please add the details for the Finance Contact to whom our invoices will be sent. If no details are provided, our invoices will be sent to the Client Key Contact.
                </article>

                <Field name={`financeContact`}>
                    {({ field, form }) => (
                        <InputContact
                            field={field}
                            form={form}
                        />

                    )}

                </Field>


            </>
            }
            <hr />

            <h1>Source of Funds</h1>

            <Field name='sourceOfFunds'>
                {({ field, form }) => (
                    <TextAreaInput
                        className={classesForm.textArea}
                        data-zuko-name="Source of Funds"
                        field={field}
                        form={form}
                        autoComplete='off'
                        placeholder={"Please describe the day-to-day activities of the Company and how it generates revenue..."}
                    // tooltip={tooltips['Issue Note']}
                    />
                )}
            </Field>

        </React.Fragment >
    )
}

const InputContact = ({ field, form }) => {

    const name = field.name;
    const dispatch = useDispatch();
    return (<>
        <Field name={`autocomplete${name}`}>
            {({ field, form }) => (
                <AutocompleteSelect
                    autocompleteKey={"individuals"}
                    fieldId={"email"}
                    callbackUpdate={(element, value) => {
                        if (_.get(element, "data.firstName")) {
                            form.setFieldValue(`${name}.fullName`, `${element.data.firstName} ${element.data.lastName?element.data.lastName:""}`)
                        }
                        if (_.get(element, "data.email")) {
                            form.setFieldValue(`${name}.email`, element.data.email)
                        }
                        if (_.get(element, "data.prefix")) {
                            form.setFieldValue(`${name}.contactNumber.prefix`, element.data.prefix)
                        }
                        if (_.get(element, "data.number")) {
                            form.setFieldValue(`${name}.contactNumber.number`, element.data.number)
                        }
                        if (_.get(element, "data.extension")) {
                            form.setFieldValue(`${name}.contactNumber.extension`, element.data.extension)
                        }
                    }}
                ></AutocompleteSelect>
            )}
        </Field>
        <Field name={`${name}.fullName`}>
            {({ field, form }) => (
                <TextInput
                    field={field}
                    form={form}
                    label={"Contact Full Name:"}
                    data-zuko-name="Contact Full Name"
                    placeholder={"Name..."}
                    onChange={(e)=>{
                        updateAutocomplete(dispatch,`individuals`,`${name}`,_.get(form,`values.${name}.email`),"email");
                        updateAutocomplete(dispatch,`individuals`,`${name}`,_.get(form,`values.${name}.prefix`),"prefix");
                        updateAutocomplete(dispatch,`individuals`,`${name}`,_.get(form,`values.${name}.number`),"number");
                        updateAutocomplete(dispatch,`individuals`,`${name}`,_.get(form,`values.${name}.extension`),"extension");
                        updateAutocomplete(dispatch,`individuals`,`${name}`,e.target.value,"firstName");
                    }}
                />

            )}

        </Field>
        <Field name={`${name}.contactNumber`}>
            {({ field, form }) => (
                <ClaraInputPhone
                    field={field}
                    form={form}
                    label={"Contact Phone Number:"}
                    data-zuko-name="Contact Phone Number"
                    onChange={(prefix,number,extension)=>{
                        updateAutocomplete(dispatch,`individuals`,`${name}`,_.get(form,`values.${name}.email`),"email");
                        updateAutocomplete(dispatch,`individuals`,`${name}`,_.get(form,`values.${name}.fullName`),"firstName");
                        updateAutocomplete(dispatch,`individuals`,`${name}`,prefix,"prefix");
                        updateAutocomplete(dispatch,`individuals`,`${name}`,number,"number");
                        updateAutocomplete(dispatch,`individuals`,`${name}`,extension,"extension");
                    }}
                />

            )}

        </Field>
        <Field name={`${name}.email`}>
            {({ field, form }) => (
                <TextInput
                    autoComplete={false}
                    type = 'email'
                    field={field}
                    form={form}
                    label={"Contact Email Address:"}
                    placeholder={"Email Address..."}
                    onChange={(e)=>{
                        updateAutocomplete(dispatch,`individuals`,`${name}`,_.get(form,`values.${name}.prefix`),"prefix");
                        updateAutocomplete(dispatch,`individuals`,`${name}`,_.get(form,`values.${name}.number`),"number");
                        updateAutocomplete(dispatch,`individuals`,`${name}`,_.get(form,`values.${name}.extension`),"extension");
                        updateAutocomplete(dispatch,`individuals`,`${name}`,_.get(form,`values.${name}.fullName`),"firstName");
                        updateAutocomplete(dispatch,`individuals`,`${name}`,e.target.value,"email");
                    }}
                />

            )}

        </Field>
    </>
    )

}

import useCustomMachine from "../../../../../generic/hooks/useCustomMachine";
import React, { FC, useEffect } from "react";
import Button from "../../../../../generic/components/Buttons/Button";
import { StepProps } from "../../../../../generic/components/Wizard/Step";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import { useDispatch } from "react-redux";
import {
  addAssistantText,
  clearAssistatText,
} from "../../../../../generic/store/action";
import FormStakeholdersSelect from "../../../Forms/FormStakeholdersSelect";
import _ from "lodash";
import useUpsertFormationCompany from "../../../graphql/mutations/useUpsertFormationCompany";
import { useParams } from "react-router-dom";
import useSession from "../../../../../session/hooks/useSession";
import useUpdateBankingAuthority from "src/modules/startup/GroupCompany/ADGM/BankingAuthority/hooks/useUpdateBankingAuthority";
import GroupCompany from "../../../../../../components/modules/clara/startups/entities/classes/GroupCompany";

const Step1: FC<StepProps> = () => {
  const { translate } = useTranslate();
  const [mutation] = useUpsertFormationCompany({}, {});
  const mutation2 = useUpdateBankingAuthority();
  const { id: _id } = useParams();

  const { startupId, session } = useSession();
  const { next, send, stepData, state } = useCustomMachine();
  const id = state?.context?.data?.id ?? _id;
  const dispatch = useDispatch();
  const groupCompany = _.get(session?.startup, "groupCompanies", []).find(
    (gc) => gc.id === id
  );
  const groupCompanyClass = new GroupCompany(groupCompany);

  useEffect(() => {
    dispatch(clearAssistatText());
    dispatch(
      addAssistantText(
        translate(
          "MODULES.GROUP_COMPANY.BANKING_AUTHORITIES.SECOND_STEP.TOOLTIP"
        )
      )
    );
  }, []);

  const formatValuesFormation = (values) => {
    return {
      startupId,
      groupCompanyId: id,
      groupCompanyData: {
        formationData: {
          bankInformation: {
            authorisedSignatories: _.map(
              values.bankingAuthorities,
              (bank) => bank.id
            ),
          },
        },
      },
    };
  };

  const formatValuesIncorp = (values) => {
    return {
      startupId,
      groupCompanyId: id,
      groupCompany: {
        keyPeople: {
          bankAuthorityInformation: {
            requireToSignJointly: false,
            authorisedSignatories: _.map(
              values.bankingAuthorities,
              (bank) => bank.id
            ),
          },
        },
      },
    };
  };

  const mutationCondition: boolean =
    groupCompanyClass.isIncorporated() && groupCompanyClass.isManagedByClara();

  const handleCompleteSubmit = async (values) => {
    if (_.size(values.bankingAuthorities) === 1) {
      if (mutationCondition) {
        const variables = formatValuesIncorp(values);
        await mutation2(variables);
      } else {
        const variables = formatValuesFormation(values);
        await mutation(variables);
      }
    }
    next(values);
  };

  const handleCancel = async () => {
    send("CANCEL");
  };

  const handlePrevious = () => send("PREVIOUS");

  return (
    <>
      <FormStakeholdersSelect
        initialValues={stepData}
        groupCompanyId={id}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: translate(
            "MODULES.GROUP_COMPANY.BANKING_AUTHORITIES.SECOND_STEP.TITLE"
          ),
          subTitle: translate(
            "MODULES.GROUP_COMPANY.BANKING_AUTHORITIES.SECOND_STEP.SUBTITLE"
          ),
        }}
        buttons={{
          previous: (
            <Button onClick={handlePrevious} variant="secondary">
              Previous
            </Button>
          ),
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              Cancel
            </Button>
          ),
        }}
      />
    </>
  );
};

export default Step1;

import React, { Component } from 'react';
import classes from "./ButtonModalOptions.module.scss";
import Popover from '@mui/material/Popover';
import { RoleAccess } from '../../index';
import BusinessRuleAccess from '../../../../modules/clara/components/BusinessRuleAccess/BusinessRuleAccess';
import Icon from '../../../../modules/generic/components/Icon'

class ButtonModalOptions extends Component {
  state = {
    open: false
  }

  handleClick = (e) => {

    if (!this.props.disabled) {
      this.setState({ open: true, anchorEl: e.currentTarget })
    }

  }
  handleOpen = () => {
    this.setState({ open: true })
  };

  handleClose = () => {
    this.setState({ open: false })
  };
  handleClickOption = (option, isDisabled) => {
    if (isDisabled) {
      return;
    }
    option.action(this.props.params);
    this.handleClose();
  }
  getContentOptions = (options) => {
    if (options) {
      return options.map((option, i) => {
        const iconClass = option.icon ? classes.WithIcon : classes.NoIcon;

        const isDisabled = !option.disabled ? false : option.disabled(this.props.params);
        return (
          <RoleAccess action={option.actionAccess} key={i}>
           <BusinessRuleAccess conditions={option.ruleBusiness}>
              
              {option.iconClara ? 
                <div className={` ${classes.RowIconModal} `}>
                <a onClick={(e) => this.handleClickOption(option, isDisabled)}>
                 <Icon isClara icon={option.iconClara} size={'1.5rem'} />
                 <span>{option.label}</span>
                </a>
              </div> :
              <div className={` ${classes.RowModal} ${iconClass} ${option.className} ${isDisabled ? classes.isDisabled : null}`} >
                <a onClick={(e) => this.handleClickOption(option, isDisabled)}>
                  {option.icon ? <i className={option.icon}></i> : null}
                  <span>{option.label}</span>
                </a>
              </div>
              }


            </BusinessRuleAccess>
          </RoleAccess>
        )
      })
    } else {
      return null;
    }
  }
  render() {
    const { disabled = false } = this.props;

    return (
      <div className={classes.ButtonModalOptions}>
        <a
          className={classes.icon}
          variant="contained"
          onClick={(e) => { this.handleClick(e) }}>

        </a>
        <Popover
          disableEnforceFocus
          open={this.state.open && !disabled}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          anchorEl={this.state.anchorEl}
        >
          <div className={classes.ShowModal}>
            {this.props.title ? <div className={classes.TitleModal}>{this.props.title}</div> : null}
            <div className={classes.BodyModal}>
              {this.getContentOptions(this.props.options)}
            </div>
          </div>
        </Popover>

      </div>
    );
  }
}

export default ButtonModalOptions;

import React, {useEffect, useMemo, useState} from 'react';
import {Constants} from '../../../../../../utils/constants';
import {useDispatch} from "react-redux";
import {Text} from "../../../../../../components"
import ServerConnect from "../../../../../../utils/ServerConnect"
import _ from 'lodash'
import PrimaryButton from "../../../../Generic/Buttons/PrimaryButton/PrimaryButton"
import SecondaryButton from "../../../../Generic/Buttons/SecondaryButton/SecondaryButton"
import ModalDecline from "../ModalDecline/ModalDecline"
import useEngagementLinkPaid from "../../../../../Hooks/services/matter/useEngagementLinkPaid";

const mutationDeclineProposal = `
    mutation declineProposal($matterId: ID!,$issues: String!) {
        declineProposal(matterId: $matterId, issues: $issues) {
            status
    }
  }
`;
const mutationApproveProposal = `
    mutation approveProposal($matterId: ID!) {
        approveProposal(matterId: $matterId) {
            status
        }
    }
`;
const BotoneraProposal = ({ matterId, setLoading  }) => {
    const [showModalDecline, setShowModalDecline] = useState(false)
    const dispatch = useDispatch();
    useEffect(() => {

    }, []);
    const { data } = useEngagementLinkPaid(matterId)
    const setLoadingPrevComponent = (value) =>{
        if (setLoading){
            setLoading(value)
        }
    }
    const approve = () => {
        setLoadingPrevComponent(true);
        ServerConnect.graphQlMutation(mutationApproveProposal, {
            matterId: matterId,
        }).then(res => {
            dispatch({ type: 'GO_TO', page: Constants.PAGES.scopingProposalApprove})

        });
    }
    const decline = () => {
        setShowModalDecline(true)
    }
    const closeModalDecline = () => {

        setShowModalDecline(false)

    }

    const handleDecline = (values) =>{

        if (values) {
            setLoadingPrevComponent(true);
            ServerConnect.graphQlMutation(mutationDeclineProposal, {
                matterId: matterId,
                issues: values.issues,
            }).then(res => {
                setLoadingPrevComponent(false);
                dispatch({ type: 'GO_TO', page: Constants.PAGES.scopingProposalDecline})
            });
        }
    }

    const handleTitleCompany = (companyType) => {

        const company = _.startCase(companyType);
        switch (companyType) {
            case "holdingCompany":
                return `SPV ${company} Engagement`;
            case "techStartup":
                return `TECH ${company} Engagement`;
            case "foundation":
                return `TECH ${company} Engagement`;
            default:
                return `OPERATING ${company} Engagement`;
        }

    }
    const title = useMemo(() => handleTitleCompany(_.get(data, 'companyType')));


    return (
        <React.Fragment>
            <SecondaryButton onClick={decline} > <Text uuid={"BUTTON_DECLINE"} /> </SecondaryButton>
            <PrimaryButton onClick={approve} ><Text uuid={"BUTTON_APPROVE"} /></PrimaryButton>
            <ModalDecline show={showModalDecline} handleClose={closeModalDecline} handleDecline={handleDecline} title={"ADGM SPV Proposal"} />
        </React.Fragment>
    )
}

export default BotoneraProposal

import { useMemo } from 'react';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import TechStartupEligibilityFormStep from '../steps/TechStartupEligibilityFormStep';
import WhatIsYourStartupFormStep from '../steps/WhatIsYourStartupFormStep';
import TechStartupConciergeFormStep from '../steps/TechStartupConciergeFormStep';
import CallTeamExpertsFormStep from '../steps/CallTeamExpertsFormStep';
import ClaraInTouchFormStep from '../steps/ClaraInTouchFormStep';
import AdgmTechStartupAuthorisedSignatoryFormStep from '../steps/AdgmTechStartupAuthorisedSignatoryFormStep';
import AddWithClaraConciergeSubMachineStep from '../steps/AddWithClaraConciergeSubMachineStep';
import AddADGMCompany from '../steps/AddADGMCompany';
import FormInADGMSuccess from '../steps/FormInADGMSuccess';

const useSteps = () => {

  return useMemo(() => ({
    SaveStep_ADGM: {
      render: TemplateWizardSkeletonMachine,
    },
    SaveStep_teamExperts: {
      render: TemplateWizardSkeletonMachine,
    },
    TechStartupEligibilityFormStep: {
      render: TechStartupEligibilityFormStep
    },
    WhatIsYourStartupFormStep: {
      render: WhatIsYourStartupFormStep
    },
    TechStartupConciergeFormStep: {
      render: TechStartupConciergeFormStep
    },
    CallTeamExpertsFormStep: {
      render: CallTeamExpertsFormStep
    },
    ClaraInTouchFormStep: {
      render: ClaraInTouchFormStep
    },
    AdgmTechStartupAuthorisedSignatoryFormStep: {
      render: AdgmTechStartupAuthorisedSignatoryFormStep
    },
    AddADGMCompany: {
      render: AddADGMCompany,
    },
    FormInADGMSuccess: {
      render: FormInADGMSuccess,
    },
    AddWithClaraConciergeSubMachineStep: {
      render: AddWithClaraConciergeSubMachineStep
    },

  }), []);
}

export default useSteps;

import useLazyQuery from "src/hooks/custom/useLazyQuery";

export const GET_STAKEHOLDER = `
  query getStakeholder($startupId: ID!, $stakeholderId: ID!) {
    getStakeholder(startupId: $startupId, stakeholderId: $stakeholderId) {
      id
      fullName
      email
      phoneNumber
      dateOfBirth
      profession
      nationality {
        code
      }
      address {
        street
        city
        state
        zipCode
        country {
          code
        }
      }
      birthAddress {
        city
        country {
          code
        }
      }
      nationalities {
        none
        countries {
          country {
            code
          }
          hasPassport {
            description
            question
          }
        }
      }
    }
  }
`;

const useGetStakeholder = (variables) => {
  const { manualQuery, loading, error, data, refetch } = useLazyQuery(GET_STAKEHOLDER, variables);

  const query = async (variables) => {
    const { data } = await manualQuery({ variables });

    return {
      stakeholder: data['getStakeholder'],
    };
  };

  return {
    query,
    loading,
    error,
    data,
    refetch,
  };
};

export default useGetStakeholder;

import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import classes from "./ClaraInputRadioButton.module.scss"
import Mustache from 'mustache';
import FormikHelper from "../../../utils/formikHelper";

class ClaraInputRadioButton extends Component {
    state = {
        tooltipText: null,
        placeHolder: null,
        validation: {},
        label: null,
        defaultValue: null,
        errorMessage: null,
        showError: false,
        value: null,
        options: []
    };

    componentWillMount() {
        const { tooltipText, placeHolder, validation, label, defaultValue, name, options, value } = this.props;
        this.setState({
            tooltipText,
            placeHolder,
            validation,
            label,
            defaultValue,
            name,
            value,
            options
        });
    }

    handleChange = (id) => {
        this.setState({
            value: id
        });
        const { form, field } = this.props;
        const name = field.name;
        FormikHelper.setValueInTheCorrectPosition(name, form, id);
        try {
            form.validateField(field.name)
        } catch (e) {
        }
    };



    componentJSX_isRequired() {
        if (this.props.required) {
            return <span className={`required`}>*</span>
        }
    }

    hasError() {
        const { form, field } = this.props;
        return FormikHelper.returnErrorsForValidation(field.name, form.errors);
    }
    getError() {
        const { form, field } = this.props;
        return FormikHelper.returnErrorsForValidation(field.name, form.errors);
    }
    
    componentJSX_getCheckbox() {
        const { className } = this.props;

        return (
            <React.Fragment>
                {
                    this.state.options.map((option, i) => {
                        let classSelected = null;
                        if (this.state.value === option.id) {
                            if (!option.className) {
                                classSelected = classes.SelectedBLUE;
                            } else {
                                classSelected = option.classNameSelected;
                            }
                        }
                        return (
                            <div className={`${_.get(this.props, 'className', classes.RadioButton)} ${option.className}`} key={i}>
                                <button
                                    disabled={this.props.disabled}
                                    type={"button"}
                                    className={`${classSelected}`}
                                    onClick={(e) => this.handleChange(option.id)}
                                    hasError={this.hasError()?"true":"false"}
                                />
                                <label>
                                    {option.label}
                                </label>
                            </div>
                        )
                    })
                }
                {this.hasError() ? (
                    <div className={classes.MessageError}>
                        {this.getError()}
                    </div>
                ) : null}
            </React.Fragment>
        )

    }
    render() {
        const { className, classNameInput } = this.props;
        let { label } = this.state;
        label = Mustache.render(label, {
            index: this.props.index + 1
        });
        return (
            < div className={`${classes.ClaraInputRadioButton} ${classNameInput} `} >
                <label className={classes.label}>
                    {label}
                    {this.componentJSX_isRequired()}
                </label>
                <div className={`${_.get(this.props, 'className', classes.RadioButton)}`}>
                    {this.componentJSX_getCheckbox()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    session: state.session
});
const mapDispatchToProps = (dispatch) => ({

});
export default connect(mapStateToProps, mapDispatchToProps)(ClaraInputRadioButton)

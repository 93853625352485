import { useMemo } from "react";
import useBusinessRules from '../../../modules/clara/hooks/useBusinessRules';

const useTabStatus = (taskStatus, defaultTab, isLocked) => {
  const { isViewer, isStartSubscription }= useBusinessRules();
  const {showTask, showAction, showHelper, activeTab} = useMemo(() => {
    const getShowTab = () => {
      if (isLocked) {
        return true
      } else {
        if (isViewer()) return false
        if (!isViewer() && taskStatus === "completed") {
          return false;
        } else {
          return true;
        }
      }
    }
    const getActionTab = () => {
      return (!isViewer() || (isViewer() && !isStartSubscription()));
    }
    const getHelperTab = () => {
      return true;
    }
    const getActiveTab = () => {
      if (getShowTab()) {
        return "tasks"
      }
      if (getActionTab()) {
        return "actions"
      }
      return "helper";
    }
    const result = {
      showTask: getShowTab(),
      showAction: getActionTab(),
      showHelper: getHelperTab(),
      activeTab: getActiveTab(),
    }
    if (!!defaultTab){
      result.activeTab = defaultTab;
    }
    return result;
  }, [taskStatus, isViewer, isStartSubscription]);
  return { showTask, showAction, showHelper, activeTab };
};

export default useTabStatus;
import Machine from "../../../../generic/components/Machine/MachineClass";
import { Step1, Step2, Step3, Step4, Step5 } from './Steps';

const haveOldPassport = (context) => {
  return context.data.select_current.haveOldPassposrt
}

const machineDefinition = {
  id: 'select_current',
  initial: 'select_current',
  states: {
    select_current: {
      on: {
        NEXT: { target: 'set_document_visa', actions: 'setContext' },
        CANCEL: 'finish_stakeholder_assign',
      },
    },
    set_document_visa: {
      on: {
        NEXT: { target: 'set_document_visa_expiry_date', actions: 'setContext' },
        PREVIOUS: 'select_current',
        CANCEL: 'finish_stakeholder_assign',
      },
    },
    set_document_visa_expiry_date: {
      on: {
        NEXT: { target: 'loading_data', actions: 'setContext' },
        CANCEL: 'finish_stakeholder_assign',
      },
    },
    loading_data: {
      always: [
        { target: 'set_old_passport', cond: 'haveOldPassport' },
        { target: 'finish_stakeholder_assign' }
      ]
    },
    set_old_passport: {
      on: {
        NEXT: { target: 'set_old_passport_expiry_date', actions: 'setContext' },
        CANCEL: 'finish_stakeholder_assign',
      },
    },
    set_old_passport_expiry_date: {
      on: {
        NEXT: { target: 'finish_stakeholder_assign', actions: 'setContext' },
        CANCEL: 'finish_stakeholder_assign',
      },
    },
    finish_stakeholder_assign: {
      type: 'final',
      entry: 'final',
    },
    onDone: {
      actions: 'stopMachine'
    }
  },
}
const steps = {
  select_current: Step1,
  set_document_visa: Step2,
  set_document_visa_expiry_date: Step3,
  set_old_passport: Step4,
  set_old_passport_expiry_date: Step5
}

const options = {
  guards: {
    haveOldPassport,
  }
}

export default class MachineProfileStakeholder extends Machine {
  constructor() {
    super(machineDefinition, options, steps);
  }

  getParamsData(values) {
    return values;
  }

  /*
  getParamsData({ paramsMutation }) {
    return { ...paramsMutation }
  }*/
}

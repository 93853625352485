import React, { useEffect, useState, useMemo } from 'react';

import useQuery from '../../hooks/custom/useQuery'
import _ from "lodash";
import { individualDataType, companyTypeCorporate } from '../../v1/graphql/mutations/matterTypes';

const individualType = `{
    id
    curriculumVitaeDoc{
    id
    name
    }
    type
    isPrimary
    firstName
    lastName
    isDirector
    isShareholder
    familyMemberOfAPEP
    familyMemberOfAPEPDescription
    closeAssociateOfAPEP
    closeAssociateOfAPEPDescription
    dateOfBirth
    UAEVisaDoc{
      file{
        id
        name
        size
        type
      }
    }
    email
    thirdPartyAddressFormDoc{
      file{
        id
        name
        size
        type
      }
    }
    thirdPartyAddressPassportDoc{
      file{
        id
        name
        size
        type
      }
    }
    lastName 
    thirdPartyAddressFormDoc{
      file{
        id
        name
        size
        type
      }
    }
    thirdPartyAddressPassportDoc{
      file{
        id
        name
        size
        type
      }
    }
    occupation
    address{
      address
      city
      country
      zipCode
      state
    }
    proofOfAddress
    proofOfAddressDoc{
      file{
        id
        name
        size
        type
      }
    }
    addressLetterAndSupportingDoc{
      file{
        id
        name
        size
        type
      }
    }
    contactNumber{
      prefix
      number
      extension
    }
    passports{
      current
      nationality
      passportNumber
      passportExpiryDate
      passportDoc{
        file{
          id
          name
          size
        }
      }
      lastPagePassportDoc{
         file{
          id
          name
          size
        }
      }
      UAEPassportStamp
      UAEPassportStampDoc{
         file{
          id
          name
          size
        }
      }
      UIDDoc{
         file{
          id
          name
          size
        }
      }
      PRCIDDoc{
         file{
          id
          name
          size
        }
      }
      
      
    }
    otherPassports
    holdOrHaveHeldPassportFromAnotherCountry
    passportFromAnotherCountryDoc{
      file{
        id
        name
        size
        type
      }
    }
    politicallyExposedPerson
    politicallyExposedPersonDescription
    sourcesOfWealth
    otherSourcesOfWealth
    sourceOfFunds 
  }`



const companyType = `{
    id
    companyName
    type
    otherTradingName
    PEPs${individualDataType}
    directors {
      individuals${individualDataType}
      companies${companyTypeCorporate}
      }
    shareholders {
      individuals${individualDataType}
      companies${companyTypeCorporate}
      }
    signatory {
      firstName
      lastName
      jobTitle
      email
      nationality
      passportNumber
      passportExpiryDate
      signatoryPassportDoc{
        file{
          id
          name
          size
          type
        }
      }
    }
    clientKeyContact{
      fullName
      contactNumber{
        prefix
        number
        extension
      }
      email
      contactType
      }
    financeContact{
      fullName
      contactNumber{
        prefix
        number
        extension
      }
      email
      contactType
      }
      sourceOfFunds
      representativeDetails{
        firstName
        lastName
        jobTitle
        email
        representativePassportDoc {
          file{
            id
            name
            size
            type
          }
        }
      }
      showingShareholdersRegistrationDoc{
        file{
          id
          name
          size
          type
        }
      }
      showingDirectorsRegistrationDoc{
        file{
          id
          name
          size
          type
        }
      }
    registrationNumber
    isPrimary
    TRNNumber
    jurisdictionOfIncorporation
    VATRegistrationCertificateDoc{
      file{
        id
        name
        size
        type
        }
    }
    address{
      city
      state
      country
      zipCode
      address
    }
  samePrincipalPlaceOfBusinessAndLegal
  principalAddress{
    city
    state
    country
    zipCode
    address
  } 
  showingDirectorsRegistrationDoc{
    file{
      id
      name
      size
      type
    }
  }
  showingShareholdersRegistrationDoc{
    file{
      id
      name
      size
      type
    }
  }
  certificateOfIncorporationOrCommercialLicenseDoc{
    file{
      id
      name
      size
      type
    }
  }
  memorandumAndArticlesOfAssociationDoc{
    file{
      id
      name
      size
      type
    }
  }
  countryOfIncorporation {code name}
  proofOfPowerDoc{
    file{
      id
      name
      size
      type
    }
  }
  }`


const query = `{
    getMattersList(matterId:"{{matterId}}") {
     
      
    }
  }`


const getTypeQuery = (type) => {

    switch (type) {
        case 'QUESTIONNAIRE':
            return `questionnaire {
                agreementsData{
                  agreementsDirectors
                  firstName
                  lastName
                  email
                }
                state
                companies ${companyType}
                individuals ${individualType}
                sharesData {
                  shareholders {
                    company ${companyType}
                    individual ${individualType}
                    entityId
                    fullName
                    shares
                    paid
                  }
                  shareType
                  sharesAuthorized
                  shareRemaining
                  nomineeShareholders
                }
                companyDetails {
                        holdingName
                        limitedName
                        companyName
                        isRevenueGenerating
                        isProtection
                        intellectualProperty
                        intellectualPropertyDoc{
                          file{
                            id
                            name
                            size
                            type
                          }
                        }
                        alreadyIncorporated
                        alreadyCompanies{
                          companyName
                        }
                        companyType
                        haveAcronym
                        shortenedName
                        haveArabicName
                        arabicName
                        haveAffiliation
                        fullLegalName              
                        assetsHeld {
                          primaryAsset                
                        }
                }
              }`;
        case 'KYC':
            return ` KYC{
                id
                KYCType
                engagement{
                  status
                }
                thirdParty {
                  firstName
                  lastName
                  contactNumber{
                    prefix
                    number
                    extension
                  }
                  pointOfContact
                  }
                entitiesData{
                  entities${companyType}
                  individuals${individualType}
                  companies${companyTypeCorporate}
                }
                individuals${individualType}
              }`
        case 'SCOPING':
            return `scoping{
                incorporationJurisdiction
                companyType
                isRevenueGenerating
                isProtection
                onlyRevenueDividendsOrCapital
                foundationReason
                relevantTechnologyDescription
                companyActivitiesDescription
                detailsOtherIncorporation
                anotherIncomeForm
                shareNumbers
                boardMembersMajorityResident
                bankAccountRequire
                engagementType
                alreadyIncorporated
                alreadyIncorporatedText
                toBeIncorporated
                toBeIncorporatedText
                intellectualProperty
                intellectualPropertyText
                otherAsset
                otherAssetText
                whereTheMajorityBoardMembersReside
                foundationAssetsAndWhereAreLocated
                whereFoundersAreLiving
                engagement{
                  status
                }
              }
              `
        default:
            return ``;
    }

}



export default function useMatter({ matterId, type, ...props }) {


    const query = `query getMatterDraft($matterId: ID) {
            getMatterDraft(matterId: $matterId) {
              ${getTypeQuery(type)}             
            }
          }`;
    const { loading, data,  }= useQuery(query, { matterId } );


    return { loading, data};
}
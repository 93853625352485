import _, { toInteger, toNumber } from 'lodash';
import moment from "moment";
import { useDispatch } from "react-redux";
import React, { FC, useEffect } from 'react';
import { stripTypenames } from "../../../../../../../../../v1/utils/graphql-util";
import Button from "../../../../../../../../generic/components/Buttons/Button";
import { StepProps } from "../../../../../../../../generic/components/Wizard/Step";
import useCustomMachine from "../../../../../../../../generic/hooks/useCustomMachine";
import { addAssistantText, clearAssistatText } from "../../../../../../../../generic/store/action";
import useTranslate from "../../../../../../../../generic/hooks/useTranslate";
import TemplateWizard from "../../../../../../../../generic/templates/Modal/TemplateWizard";
import useSession from '../../../../../../../../session/hooks/useSession';
import IsThereDocument from "../../../../../../forms/IsThereDocuments";
import useUpsertEquity from "../../../../../../graphql/mutation/upsertEquity";


const IsThereDocumentShareStep: FC<StepProps> = () => {

  const dispatch = useDispatch()
  const { state, stepData, send, next, context } = useCustomMachine()
  const { mutation } = useUpsertEquity({ showSuccessNotification: false })
  const { startupId } = useSession();
  const { translate } = useTranslate()

  useEffect(() => {
    dispatch(clearAssistatText());

    dispatch(addAssistantText(`A Shareholder Register (or Register of Members) is a record of all the active and former owners of a company’s shares.`));

    return () => {
        dispatch(clearAssistatText())
    }
    
  }, [])
  

  const getCompany = (): object => {
    if (_.isEmpty(_.get(state, 'context.globalData', []))) return _.get(state, 'context.data.select_companies.companies[0]')
    else return _.get(state, `context.globalData[0].select_companies.companies[${_.get(state, 'context.numbersOfIteration', 0)}]`)
  }

  const getCurrency = (): string => {
    const company = getCompany()
    return _.get(company, 'transactionCurrency', 'USD') ? _.get(company, 'transactionCurrency', 'USD') : 'USD'
  }

  const handlePrev = () => {
    send("PREVIOUS")
  }

  const handleCancel = () => {
    send('CANCEL')
  }

  const handleCompleteSubmit = async (values): Promise<void> => {
    if (values.documents === 'later') {
      const equityData = {
        issuedDate: moment(_.get(state, 'context.data.add_equity_form.issuedDate')).format('YYYY-MM-DD'),
        amount: toInteger(_.get(state, 'context.data.add_equity_form.amount')),
        price: toNumber(_.get(state, 'context.data.add_equity_form.price')),
        currency: getCurrency(),
        newShareClass: _.get(state, 'context.data.add_equity_form.newShareClass')
      }
      await mutation({
        startupId,
        shareType: 'SHARE',
        isThereSIP: false,
        owner: _.get(state, 'context.paramsData'),
        groupCompanyId: _.get(getCompany(), 'id'),
        equityData: stripTypenames(equityData)
      })
    }
    next(values)
  }
  
  return (<>
    <IsThereDocument
      initialValues={stepData}
      propsTemplate={{
        title: translate(`MODULES.EQUITY.STEPS_EQUITY_POSITIONS.IS_THERE_DOCUMENTS_STEP.TITLE_${context?.type?.toUpperCase() ?? "COMPANY"}`),
        subTitle: translate(`MODULES.EQUITY.STEPS_EQUITY_POSITIONS.IS_THERE_DOCUMENTS_STEP.SUBTITLE_${context?.type?.toUpperCase() ?? "COMPANY"}`),
      }}
      onCompleteSubmit={handleCompleteSubmit}
      Template={TemplateWizard}
      buttons={{ cancel: <Button onClick={handleCancel}>Cancel</Button> }} />
  </>)
}
export default IsThereDocumentShareStep

import PropTypes from "prop-types";
import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import _ from "lodash";
import NoticesPanel from "src/views/modules/startups/company/companyView/Tabs/KeyPeopleTab/panels/NoticesPanel";
import DirectorsPanel from "src/views/modules/startups/company/companyView/Tabs/KeyPeopleTab/panels/DirectorsPanel";
import ADGMAuthorisedSignatoriesPanel from "src/views/modules/startups/company/companyView/Tabs/KeyPeopleTab/panels/ADGM/AuthorisedSignatoriesPanel";
import DataProtectionContactPanel from "src/views/modules/startups/company/companyView/Tabs/KeyPeopleTab/panels/ADGM/DataProtectionContactPanel";
import BankingAuthorityPanel from "src/views/modules/startups/company/companyView/Tabs/KeyPeopleTab/panels/ADGM/BankingAuthorityPanel";
import GroupCompany from "src/components/modules/clara/startups/entities/classes/GroupCompany";

function KeyPeopleTab({ data, isLock, jurisdictionType, sign }) {
  const groupCompanyClass = new GroupCompany(data);

  const managedByClara = groupCompanyClass.isManagedByClara();
  const isFormation = groupCompanyClass.isFormation();
  const isADGM = groupCompanyClass.isADGM();

  const adgmAuthorisedSignatories = useMemo(() => {
    const nominee = {
      fullName: "Clara Nominee",
      avatar: "/img/clara_logo.svg",
      primarySignatory: true,
      noLink: true,
    };

    if (isFormation) {
      const as = _.get(data, "formationData.authorisedSignatories", []);

      return data?.formationData?.isUsingNominee ? [nominee, ...as] : as;
    } else {
      const haveNominee = _.get(data, "keyPeople.useClaraNominee", false);

      const sh = _.map(data?.keyPeople?.authorisedSignatories, (contact) => {
        return {
          fullName: contact.fullName,
          avatar: contact.avatar,
          id: contact.id,
          noLink: false,
        };
      });
      return haveNominee ? [nominee, ...sh] : sh;
    }
  }, [data]);
  
  const dataProtectionContact = useMemo(() => {
    if (isFormation) {
      return _.compact(
        _.map(_.get(data, "assignee", []), (contact) => {
          if (contact.isDataProtection === true) return contact.stakeholder;
        })
      );
    } else {
      let result = [];
      if (_.get(data, "keyPeople.dataProtectionContact")) {
        result.push({
          fullName: _.get(data, "keyPeople.dataProtectionContact.fullName", ""),
          avatar: _.get(data, "keyPeople.dataProtectionContact.avatar", ""),
          id: _.get(data, "keyPeople.dataProtectionContact.id"),
        });
      }
      return result;
    }
  }, [data]);

  const bankingAuthority = useMemo(() => {
    if (isFormation) {
      return _.compact(
        _.map(_.get(data, "assignee", []), (contact) => {
          if (contact.isBanking === true) return contact.stakeholder;
        })
      );
    } else {
      return _.map(
        data?.keyPeople?.bankAuthorityInformation?.authorisedSignatories,
        (contact) => {
          return {
            fullName: contact.fullName,
            avatar: contact.avatar,
            id: contact.id,
            noLink: false,
          };
        }
      );
    }
  }, [data]);

  const listDirectors = useMemo(() => {
    if (managedByClara && !isFormation) {
      return _.get(data, "keyPeople.directors");
    } else {
      return _.get(data, "directors");
    }
  }, [data]);

  const signMethod = useMemo(() => {
    if (isFormation) {
      return sign;
    } else {
      return _.get(
        data,
        "keyPeople.bankAuthorityInformation.requireToSignJointly",
        false
      );
    }
  }, [data, sign]);

  return (
    <Box sx={{ paddingRight: "0.4615rem" }}>
      {!_.isEmpty(listDirectors) && (
        <>
          <DirectorsPanel 
            data={listDirectors}
            havePincas={groupCompanyClass.getIdsADGMDirectorsInPincas().length > 0}
          />
        </>
      )}
      {isADGM && (
        <>
          {(!_.isEmpty(adgmAuthorisedSignatories) ||
            data?.formationData?.isUsingNominee) && (
            <>
              <ADGMAuthorisedSignatoriesPanel
                adgmAuthorisedSignatories={adgmAuthorisedSignatories}
                data={data}
                isLock={isLock}
                havePincas={groupCompanyClass.getIdsADGMAuthirizeSignatoriesInPincas().length > 0}
              />
            </>
          )}
          {!_.isEmpty(dataProtectionContact) && (
            <>
              <DataProtectionContactPanel
                dataProtectionContact={dataProtectionContact}
                isLock={isLock}
                isFormation={isFormation}
                groupCompany={data}
                havePincas={groupCompanyClass.getIdInDataProtectionContact() !== null}
              />
            </>
          )}
          {!_.isEmpty(bankingAuthority) && (
            <>
              <BankingAuthorityPanel
                bankingAuthority={bankingAuthority}
                isLock={isLock}
                sign={signMethod}
              />
            </>
          )}
        </>
      )}
      <NoticesPanel data={data} isLock={isLock} />
    </Box>
  );
}

KeyPeopleTab.propTypes = {
  data: PropTypes.any,
  isLock: PropTypes.bool,
  isEdit: PropTypes.bool,
  jurisdictionType: PropTypes.string,
  sign: PropTypes.bool,
};

export default KeyPeopleTab;

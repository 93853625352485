import PropTypes from "prop-types";
import React from "react";
import DetailsTabTitle from "src/components/templates/DetailsTabTitle";
import Divider from "@mui/material/Divider";
import classes from "../../styles.module.scss";
import _ from "lodash";
import useNavigation from "src/modules/generic/hooks/useNavigation";
import { Constants } from "src/v1/utils";
import useModal from "src/modules/generic/hooks/useModal";
import Button from "src/modules/generic/components/Buttons/Button";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import ModalBankingAuthorities from "src/modules/startup/GroupCompany/ADGM/BankingAuthority";
import { useParams } from "react-router-dom";
import Typography from "src/modules/generic/components/Texts/Typography";
import UserItem from "src/views/modules/startups/company/companyView/Tabs/KeyPeopleTab/components/UserItem";
import { RoleAccess } from "src/v1/components";

function BankingAuthorityPanel({ bankingAuthority, isLock, sign }) {
  const { goTo } = useNavigation();
  const { openModal } = useModal();
  const { translate } = useTranslate();
  const { id: idGroupCompany } = useParams();

  const actionButton = (
    <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
    <Button
      variant="card"
      disabled={isLock}
      children={translate("VIEW_COMPANY_TAB_BUTTON_DETAILS_EDIT")}
      onClick={() => {
        openModal(ModalBankingAuthorities, {
          paramsMutation: { id: idGroupCompany },
        });
      }}
    />
    </RoleAccess>
  );

  const goToStakeholder = (stakeholder) => {
    if (stakeholder.isAnEntity) {
      goTo(Constants.PAGES.viewStakeholderCompany, { id: stakeholder.id });
    } else {
      goTo(Constants.PAGES.viewStakeholderIndividual, { id: stakeholder.id });
    }
  };

  return (
    <>
      <Divider className={classes.divider} />
      <DetailsTabTitle title="Banking authority" buttons={actionButton} />
      <div className={classes.containerSign}>
        <Typography variant="body">
          <span className={classes.bankingAuthorityTitle}>
            Banking Authority:
          </span>
          <span className={classes.bankingAuthorityDescription}>
            {translate(sign ? "TAB_BANKING_JOINTLY" : "TAB_BANKING_SINGLY")}
          </span>
        </Typography>
      </div>
      <div className={classes.containerDirectorsTab}>
        {_.map(bankingAuthority, (bankingContact) => {
          return (
            <UserItem
              fullName={bankingContact.fullName}
              avatar={bankingContact.avatar}
              onClick={() => goToStakeholder(bankingContact)}
            ></UserItem>
          );
        })}
      </div>
    </>
  );
}

BankingAuthorityPanel.propTypes = {
  bankingAuthority: PropTypes.any,
  isEdit: PropTypes.bool,
  isLock: PropTypes.bool,
};

export default BankingAuthorityPanel;

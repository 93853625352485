import { default as React, useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import _ from 'lodash';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import Typography from "src/modules/generic/components/Texts/Typography";
import TextAreaInput from "src/components/inputs/Textarea/TextareaInput";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import classes from './classes.module.scss';
import useGetStakeholders from "src/hooks/services/modules/stakeholders/useGetStakeholders";
import useSession from "src/modules/session/hooks/useSession";

function PEPForm() {
  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const { startupId } = useSession();
  const {
    data: dataStakeholders,
    loading: loadingStakeholders
  } = useGetStakeholders({ variables: { startupId } });

  const initialValues = useMemo(() => {
    return context?.initialData ?? {}
  }, [context]);

  const stakeholderFullName = useMemo(() => {
    let result = "";
    const filterST = _.find(dataStakeholders, { id: initialValues?.stakeholderId });
    if (filterST) result = filterST.fullName;
    return result
  }, [dataStakeholders, initialValues?.stakeholderId]);

  const { formValues } = initialValues;

  const isEdit = !_.isEmpty(formValues);

  const [isPEP, setIsPEP] = useState(formValues?.isPEP ?? false);

  const handleCancel = () => {
    cancel();
  }

  const handleSubmit = async (values) => {
    next(values);
  }

  useEffect(() => {
    dispatch(addAssistantText(translate('MODULES.STAKEHOLDER.ENTITY_STRUCTURES.PEP.ASSISTANT')));
    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch]);

  const options = [
    {
      value: false,
      label: translate(`MODULES.STAKEHOLDER.ENTITY_STRUCTURES.PEP.OPTION_1`),
    },
    {
      value: true,
      label: translate(`MODULES.STAKEHOLDER.ENTITY_STRUCTURES.PEP.OPTION_2`),
    },
  ];

  const schema = Yup.object().shape({
    isPEP: Yup.boolean()
      .required("This field is required")
      .nullable(),
    descriptionOfPEP: Yup.lazy(() => {
      if (isPEP) return Yup.string().required("This field is required");
      return Yup.string().nullable();
    })
  });

  return (
    <Form
      defaultValues={formValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.PEP.TITLE"),
          skeleton: null,
          subTitle: translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.PEP.SUBTITLE"),
          variant: 'simple'
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>{isEdit ? translate('BUTTON_SAVE_CHANGES') : translate('GENERIC_BUTTON_ADD_GROUP')}</SubmitButton>,
        }}
      >
        <>
          <Typography variant="h5">
            {translate('MODULES.STAKEHOLDER.ENTITY_STRUCTURES.PEP.DESCRIPTION', {stakeholderName: stakeholderFullName})}
          </Typography>
          <ControllerInput
            render={CheckboxButtonSimple}
            name="isPEP"
            size="size1"
            options={options}
            classNameButton={classes.heightCheckboxButtonSimple}
            onChange={(value) => setIsPEP(value)}
          />
          {isPEP && (
            <TemplateLabel
              label={translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.PEP.WHO_IS_PEP")}
              isRequired
              className={classes.whoIsPep}
            >
              <ControllerInput
                render={TextAreaInput}
                name="descriptionOfPEP"
                placeholder="For example: Diana Director is a director and they’re a senior politician."
                rows={8}
              />
            </TemplateLabel>
          )}
        </>
      </TemplateWizard>
    </Form>
  );
}

export default PEPForm;
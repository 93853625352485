import _ from "lodash";
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CancelSubscriptionForm from "../../../../../../components/forms/modules/startups/startup/CancelSubscriptionForm/CancelSubscriptionForm";
import useTextLang from "../../../../../../hooks/custom/useTextLang";
import useCancelSubscription from "../../../../../../modules/checkout/hooks/useCancelSubscription";
import useSession from "../../../../../../modules/session/hooks/useSession";
import { Modal } from '../../../../../../v1/components';
import classes from "./CancelSubscription.module.scss";
const CancelSubscription = ({ startup,callbackUpdate,open, handleCloseModal: handleCloseModalProp,...props }) => {
    
    
    
    const { startup: startupLanding } = useSession();
    
    

    const [showDelete, setShowDelete] = useState(false);

    useEffect(() => {

    }, [])
    

    const params = useParams();


    const handleCloseModal = () => {

        
        handleCloseModalProp && handleCloseModalProp();
    }
  
    const handleFinishUpdate = (values) => {
        handleCloseModal();
        callbackUpdate && callbackUpdate(_.get(values,"upsertStartupProfile"));

    }
   

   

    const overlayName = useTextLang("CANCEL_SUBSCRIPTION_MODAL_TITLE");
    const [updateStartup] = useCancelSubscription({
        onCompleted: handleFinishUpdate ,
        successMessage: "NOTIFICATION_OVERLAY_SAVE_SUCCESS",
        paramsSuccessMessage: {overlayName},
    });
    const handleSubmit = async (values) => {
        await updateStartup({ startupId:startupLanding.id ,revokeReason:_.get(values,'reason','')} )
        handleCloseModal();

    }
    return (
        <>
            <Modal
                className={{ modal: classes.CancelSubscription }}
                open={open } handleClose={() => handleCloseModal()} showHeader={false}
                overlayName={overlayName}
                >
                    <CancelSubscriptionForm initialValues={startup} handleCloseModal={handleCloseModal}  handleSubmit={handleSubmit} />
        
                
            </Modal>
        </>

    )
};





export default CancelSubscription;

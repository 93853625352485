import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import * as Yup from 'yup';
import Button from 'src/modules/generic/components/Buttons/Button';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import AddressInput from "src/components/forms/modules/startups/groupCompany/AddressInput";

function ConfirmForm({
  isManagedByClara = false,  
}) {
  const { next, context, cancel, prev } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();


  const schema = Yup.object().shape({
    address: Yup.object().shape({
      country: Yup.string().required("This field is required").nullable(),
      street: Yup.string().required("This field is required").nullable(),
      city: Yup.string().required("This field is required").nullable(),
      state: Yup.string().nullable(),
      zipCode: Yup.string().nullable(),
    }),
  });

  const initialValues = useMemo(() => {
    return context?.ReviewDetails ?? context.initialData.stakeholder;
  }, [context]);

  const handleCancel = (values) => {
    cancel();
  }

  const handlePrev = (values) => {
    prev();
  }

  const handleSubmit = async (values) => {
    next(values)
  }

  useEffect(() => {
    dispatch(addAssistantText('To change the registered address <strong>additional fees will apply</strong>.', "alert"))
    return () => {
      dispatch(clearAssistatText())
    }
  }, [dispatch])

  const stakeholderName: string = initialValues.fullName;

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: "Review company address",
          skeleton: null,
          subTitle: translate("MODULES.RENEWALS_ADGM.REVIEW_STAKEHOLDER_DETAILS.CORPORATE.COMMON.SUBTITLE", { stakeholderName }),
          variant: 'simple'
        }}

        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>Save</SubmitButton>,
          previous: <Button onClick={handlePrev}>Previous</Button>,
        }}
      >
        <AddressInput
          name="address"
          label={{ country: translate('MODULES.CLARA.ADDRESS_REGISTERED_LABEL') }}
          banner={isManagedByClara}
          zipCodeOptional
          stateOptional
          isRequired
        />
        <div>&nbsp;</div>
      </TemplateWizard>
    </Form>
  );
}

export default ConfirmForm;

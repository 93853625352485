import React, {createContext, useState, useCallback} from 'react';
export const LoadingContext = createContext({});

export type LoadingContextType = {
  isLoading: boolean,
  addLoading: () => void,
  removeLoading: () => void,
}

export const LoadingProvider = ({ children, keyValue }) => {

  const [isLoading, setIsLoading] = useState(false);
  const [loadingCount, setLoadingCount] = useState(0);

  const addLoading =useCallback(() => {
    setLoadingCount(loadingCount=>loadingCount+1);
    setIsLoading(true);
  },[setIsLoading,setLoadingCount])
  
  const removeLoading = useCallback( () => {
    let auxCount = loadingCount - 1;
    if(loadingCount>0){
      setLoadingCount(auxCount);
    }
    if (auxCount <= 0) {
      setTimeout(function (){
        setIsLoading(false);
      }, 200);
    }
  },[setLoadingCount,setIsLoading])

  return (
    <LoadingContext.Provider value={{ isLoading, addLoading, removeLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};

interface UseLoadingReturn {
  isLoading: boolean,
  addLoading: () => void,
  removeLoading: () => void,
}

function useLoading(): UseLoadingReturn {
  const {  isLoading, addLoading, removeLoading } = React.useContext(LoadingContext) as LoadingContextType;
  return { isLoading, addLoading, removeLoading }
}

export default useLoading;
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { addBreadCrumbs, goToPage, startBreadCrumbs } from "../../../store/actions/page";
import { ManagerPages } from "../../components";
import ManagerNotification from '../../components/ManagerNotification/ManagerNotification';
import { Constants } from '../../utils/constants';
import ClaraLayout from "./../../../layouts/Clara/ClaraLayout";
import Layout from "./../../../layouts/Layout";
import classes from "./Content.module.scss";


class Content extends Component {


    /* 
        Get params from de url to add in the child elements 
        if the params is id, the format to id is idNumber-name-second-name-andMoreName
    */
    getParams() {
        let params = _.get(this.props, "match.params", {});
        if (params.id) {
            params.id = params.id.split("-")[0]

        }
        return params;
    }

    getLayout() {

        const newContent = React.cloneElement(this.props.children, { goTo: this.props.goToPage, goToPrev: this.goToPrev, params: this.getParams(), location: this.props.location });

        const { layout, propsLayout, actions } = this.props;
        if (typeof layout == "function") {
            return (
                <React.Fragment>
                    <Layout {...propsLayout} {...this.props.location} layout={layout}>
                        {this.props.children}
                    </Layout>

                </React.Fragment>

            )
        }

        const showStartupAndFullMenu =  this.props.businessRules.isNotServiceAgent() && this.props.businessRules.isNotSeniorServiceAgent()  && this.props.businessRules.isNotServiceAgentManager();
        switch (layout) {
            case Constants.LAYOUTS.VIEW_FORM:
            case Constants.LAYOUTS.EDIT_FORM:
            case Constants.LAYOUTS.ADD_FORM:
                return (
                    <React.Fragment>
                        <Layout showHeader={true} showFooter={true} fullMenu={showStartupAndFullMenu} startupMenu={showStartupAndFullMenu} userMenu={true} layout={ClaraLayout}>
                            {newContent}
                        </Layout>
                    </React.Fragment>
                )
            case Constants.LAYOUTS.SWITCH_STARTUP:
                return (
                    <React.Fragment>
                        <Layout showHeader={true} showFooter={true} fullMenu={showStartupAndFullMenu} startupMenu={showStartupAndFullMenu} userMenu={true} layout={ClaraLayout}>
                            {newContent}
                        </Layout>
                    </React.Fragment>
                )
            case Constants.LAYOUTS.SELECT_FORM:
                return (
                    <React.Fragment>
                            <ManagerNotification />
                            <div className={classes.selectForm}>
                                {newContent}
                            </div>
                    </React.Fragment>
                )

            case Constants.LAYOUTS.INVITE:
                return (
                    <React.Fragment>

                        <ManagerNotification />
                        {newContent}

                    </React.Fragment>
                )
            default:
                return (
                    <React.Fragment>
                        <Layout showHeader={true} showFooter={true} fullMenu={showStartupAndFullMenu} startupMenu={showStartupAndFullMenu} userMenu={true} layout={ClaraLayout}>
                            {newContent}
                        </Layout>
                    </React.Fragment>
                )
        }
    }

    componentWillMount() {
        const label= _.get(this.props,"breadCrumbs.label");
        const path= _.get(this.props,"path");
        const params= _.get(this.props,"match.params",{});
        ;
        if (_.get(this.props,"breadCrumbs.reset")){
            this.props.startBreadCrumbs(label,path,params)
        }else{
            if (_.get(this.props,"breadCrumbs.add")){
                this.props.addBreadCrumbs(label,path,params)
            }  
        }
         
      

    }

    render() {
        return (
            <React.Fragment>
                <ManagerPages history={this.props.history} match={this.props.match} location={this.props.location}>
                    {this.getLayout()}
                </ManagerPages>
            </React.Fragment>
        )

    }
}

const mapStateToProps = state => {
    return {
        store: state,
        session: state.session,
        page: state.page,
        actions: state.session.actions
    };
};
const mapDispatchToProps = (dispatch) => ({
  
    goToPage: (page, params) => {
        goToPage(dispatch, page, params)
    },
    addBreadCrumbs: (label,path,params) => {
        addBreadCrumbs(dispatch, label,path,params)
    },
    startBreadCrumbs: (label,path,params) => {
        startBreadCrumbs(dispatch, label,path,params)
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Content));

import React, { FC, useMemo, useState } from 'react';
import ModalAssistant from 'src/modules/generic/components/Modal/ModalAssistant';
import useModal from 'src/modules/generic/hooks/useModal';
import { clearAssistatText as clearAssistantText } from 'src/modules/generic/store/action';
import CompanyDirectorsWizard from './wizard';
import useGetStakeholderDirectors from 'src/modules/assistanceV4/hooks/services/useGetStakeholderDirectors';
import useSession from 'src/modules/session/hooks/useSession';

interface Props {
  open: boolean
  initialValues: any
}

/* To Call this modal execute: openModal( CompanyDirectorsModal, {},'MODAL_COMPANY_DIRECTORS' ); */
const CompanyDirectorsModal: FC<Props> = ({ open: openParam = false, initialValues = {} }) => {
  const [open, setOpen] = useState(openParam);
  const { closeModal: onClose } = useModal();
  const { startupId } = useSession();
  const {data} = useGetStakeholderDirectors({
    startupId,
    stakeholderId:initialValues.stakeholderId
  });
  const handleStopMachine = () => {
    onClose();
    setOpen(false);
    clearAssistantText();
  }

  const handleCancelMachine = () => {
    onClose();
    setOpen(false);
    clearAssistantText();
  }
  const defaultValues = useMemo(() => { 
    if (data){
      return {
        ...initialValues,
        directors: data?.stakeholder?.directors || []
      }
    }
    return null
  }, [data,initialValues]);

  return (
    <ModalAssistant open={open} variant='assistance'>
      {defaultValues && <CompanyDirectorsWizard initialValues={defaultValues} onFinish={handleStopMachine} onCancel={handleCancelMachine} />}
    </ModalAssistant>
  );
};

export default CompanyDirectorsModal;

import React, { Component } from 'react';
import classes from "../EditForm.module.scss";
import { Field } from "formik";
import TextInput from "../../../../containers/Forms/Inputs/TextInput";
import ClaraInputSelect from "../../../Form/ClaraInputSelect/ClaraInputSelect";
import ClaraSelect from "../../../Form/ClaraSelect/ClaraSelect";
import moment from "moment";
import ChatFileUploaderAndSelector from '../../../../../components/inputs/ChatFileUploaderAndSelector/ChatFileUploaderAndSelector';
import FormikHelper from "../../../../utils/formikHelper";
import _ from "lodash";

class ShareEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            issuedDate: props.issuedDate,
            selectedGroupCompany: {},
            classes: [],
            documents: [],
            shareHolders: [],
            groupCompanyId: null,
            groupCompanySelected: {},
            groupCompanies: props.groupCompanies,
            stakeholders: props.stakeholders
        };
    }

    handleDateChange(e, field, form) {

        const date = e === null ? null : moment(e).format("YYYY-MM-DD");

        FormikHelper.setValueInTheCorrectPosition(field.name, form, date);
        this.setState({
            [field.name]: e
        })
    }
    getOrdinaryClass() {
        const ordinary = this.state.classes.filter(sc => sc.label === 'Ordinary')[0];
        if (ordinary) {
            return ordinary.id;
        }
        return '';
    }

    filterShareholders(groupCompany) {
        return this.state.stakeholders;
    }

    filterClasses(gc) {
        if (!gc || !gc.shareClass) {
            return []
        }
        return gc.shareClass.map(sc => {
            return { id: sc.id, label: sc.name }
        })
    }

    filterDocuments(gc) {
        return this.props.documents.filter(propDocuments => {
            return gc.documents && gc.documents.some(doc => doc.id === propDocuments.id)
        });
    }

    findGroupCompany(gcId) {
        return this.state.groupCompanies.filter(gc => gc.id === gcId)[0] || {};
    }


    /*Config Compoenents Methods*/
    componentWillMount() {
        this.setState({
            groupCompanies: this.props.groupCompanies
        })
    }
    getCompanyAdds = () => {
        return [{
            type: "groupCompany",
            label: "Add New Company",
            textWarning: "This will create a new company for your startup. You can add more details later from the company's profile page.",
            placeholder: "Enter Company Name",
            startupId: this.props.startupId,
            params: null,
            paramsReturn: 'shareClass{ id name defaultShare }'
        }]
    }

    getCompanyLists = () => {
        return [
            {
                type: "groupCompany",
                list: this.props.groupCompanies
            }
        ]
    }
    getOwnerAdds = () => {
        return [
            {
                type: "groupCompany",
                label: "Add New Company",
                textWarning: "This will create a new company for your startup. You can add more details later from the company's profile page.",
                placeholder: "Enter Company Name",
                startupId: this.props.startupId,
                params: null
            },
            {
                type: "stakeholder",
                label: "Add New Corporate Stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add more details later from the stakeholder's profile page.",
                placeholder: "Enter Stakeholder's Name",
                startupId: this.props.startupId,
                textToSave: 'fullName',
                params: {
                    isAnEntity: true,
                    roles: [{
                        role: "SHAREHOLDER",
                        groupCompany: this.state.groupCompanyId,
                    }],
                }
            },
            {
                type: "stakeholder",
                label: "Add New Individual Stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add more details later from the stakeholder's profile page.",
                placeholder: "Enter Stakeholder's Name",
                startupId: this.props.startupId,
                textToSave: 'fullName',
                params: {
                    isAnEntity: false,
                    roles: [{
                        role: "SHAREHOLDER",
                        groupCompany: this.state.groupCompanyId,
                    }],
                }
            },
        ]
    }
    getOwnerLists = () => {
        return [
            {
                type: "groupCompany",
                list: _.filter(this.props.groupCompanies, (element) => {
                    return element.id !== this.state.groupCompanyId
                })
            },
            {
                type: "stakeholder",
                list: this.state.stakeholders
            }
        ]
    }
    getShareClassAdds = () => {
        return [{
            type: "shareClass",
            label: "Add New Share Class",
            placeholder: "Enter Share Class Name",
            startupId: this.props.startupId,
            params: {
                groupCompanyId: this.state.groupCompanyId,
            }
        }]
    }
    getShareClassLists = (form) => {
        var gcId = form.values.company && form.values.company.id ? form.values.company.id : null;
        const gc = this.findGroupCompany(gcId);
        const list = this.filterClasses(gc);
        return [
            {
                type: "shareClass",
                list: list
            }
        ]
    }
    handleGroupCompanyChange = (value, aditionalData, form) => {
        const gcId = value ? value.id : null;
        this.setState({
            groupCompanyId: gcId
        })
        const gc = this.findGroupCompany(gcId);

        this.setState({
            groupCompanySelected: gc,
            classes: this.filterClasses(gc),
            documents: this.filterDocuments(gc),
            shareHolders: this.filterShareholders(gc)
        });
        if (!_.get(form, "values.shareClass.id")) {
            const defaultShareChass = _.find(gc.shareClass, sc => (sc.defaultShare))

            if (defaultShareChass) {
                form.setFieldValue('shareClass', { type: "shareClass", id: defaultShareChass.id })
            }
        }

    };
    handleGroupCompanyCreate = (value, name, additionalValue, form) => {

        let { groupCompanies } = this.state;
        let newGc = { id: value.id, name, label: name, ...additionalValue }
        if (newGc.shareClass) {
            newGc.shareClass[0].defaultShare = true
        }
        const gcIndex = _.findIndex(groupCompanies, (gc) => {
            return gc.id == value.id;
        });
        if (gcIndex >= 0) {
            groupCompanies[gcIndex] = newGc;
        } else {
            groupCompanies.push(newGc);
        }

        this.setState({ groupCompanies });

    }
    handleShareClassCreate = (value, name, additionalValue, form) => {
        var gcId = form.values.company && form.values.company.id ? form.values.company.id : null;
        let { groupCompanies } = this.state;
        const gcIndex = _.findIndex(groupCompanies, (gc) => {
            return gc.id == gcId;
        });
        if (groupCompanies[gcIndex] && value) {
            groupCompanies[gcIndex].shareClass.push({ id: value.id, name: name, label: name });
            this.setState({
                groupCompanies
            })
        }
    }
    /* Render */
    render() {
        const { tooltips = {} } = this.props;
        const { groupCompanySelected } = this.state;

        console.log(this.props)
        return (
            <form className={classes.Form}>
                <div className={classes.ContainerRowAgreement}>
                    <Field name='company'>
                        {({ field, form }) => (
                            <ClaraSelect
                                required={true}

                                field={field}
                                form={form}

                                label={"Company"}
                                placeholder={"Search or select from a list..."}
                                adds={this.getCompanyAdds()}
                                lists={this.getCompanyLists()}
                                callbacks={{ createValues: this.handleGroupCompanyCreate, updateValues: this.handleGroupCompanyChange }}
                                tooltip={tooltips['Company']}
                            />
                        )}
                    </Field>
                </div>
                <div className={classes.ContainerRowAgreement}>
                    <Field name='owner'>
                        {({ field, form }) => (
                            <ClaraSelect
                                required={true}
                                field={field}
                                form={form}
                                disabled={this.state.groupCompanyId ? false : true}
                                label={"Who owns the shares?"}
                                placeholder={"Search or select from a list..."}
                                adds={this.getOwnerAdds()}
                                lists={this.getOwnerLists()}
                                tooltip={tooltips['Who owns the shares']}
                            />
                        )}
                    </Field>
                </div>
                <hr className={classes.Separator} />
                <div className={classes.ContainerRowAgreement}>
                    <Field name='amount'>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='number'
                                label='Number of Shares: '
                                field={field}
                                form={form}
                                placeholder={"Enter number..."}
                                tooltip={tooltips['Number of Shares']}
                            />
                        )}
                    </Field>
                </div>
                <div className={classes.ContainerRowAgreement}>
                    <Field name='price'>
                        {({ field, form }) => (
                            <TextInput
                                required={false}
                                type='text'
                                label='Price Per Share: '
                                field={field}
                                form={form}
                                placeholder={"Enter number..."}
                                tooltip={tooltips['Price Per Share']}
                                rightLabel={groupCompanySelected.transactionCurrency ? groupCompanySelected.transactionCurrency : 'USD'}
                            />
                        )}
                    </Field>
                </div>

                <hr className={classes.Separator} />
                <div className={classes.ContainerRowAgreement}>
                    <Field name='shareClass'>
                        {({ field, form }) => (
                            <ClaraSelect
                                field={field}
                                form={form}
                                disabled={this.state.groupCompanyId ? false : true}
                                label={"Class:"}
                                placeholder={"Search or select from a list..."}
                                adds={this.getShareClassAdds()}
                                lists={this.getShareClassLists(form)}
                                tooltip={tooltips['Class']}
                                callbacks={{ createValues: this.handleShareClassCreate }}
                            />

                        )}
                    </Field>
                </div>
                <hr className={classes.Separator} />
                <div className={classes.ContainerRowAgreement}>
                    <Field name='issuedDate'>
                        {({ field, form }) => (
                            <TextInput
                                type='date'
                                label='Issued Date'
                                field={field}
                                form={form}
                                placeholder={"Date"}
                                tooltip={tooltips['Issued Date']}
                                prevComponentChange={e =>
                                    this.handleDateChange(e, field, form)
                                }
                            >
                            </TextInput>
                        )}
                    </Field>
                </div>
                <hr className={classes.Separator} />
                <div className={classes.ContainerRowAgreement}>
                    <span className={classes.fileUploaderAndSelectorLabel}><label className={classes.labelShareEdit}>Associated Documents:</label></span><span className={classes.fileUploaderAndSelectorContainer}><Field name='associatedDocuments'>
                        {({ field, form }) => (
                            <ChatFileUploaderAndSelector
                                required={false}
                                field={field}
                                name='associatedDocuments'
                                multipleFiles={true}
                                form={form}
                                label={"Associated Documents"}
                                documents={this.state.documents}
                            />
                        )}
                    </Field></span>
                </div>
            </form >
        );
    }
}


export default ShareEdit;

import React from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";

const EditLapse = ({ go, ...props }) => {
  const params = useParams();

  return (
    <MicroFrontend id="MF_Edit_Lapse" component={"./EditLapse"} mf="capTable" params={{ go, ...props, ...params }} />
  );
};

export default EditLapse;

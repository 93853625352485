import useMutation from "src/hooks/custom/useMutation";

export const UPSERT_RENEWAL_DATA = `
mutation upsertRenewalData($startupId: ID!,$groupCompanyId: ID!,$renewalData: RenewalDataInput) {
  upsertRenewalData(startupId: $startupId,groupCompanyId: $groupCompanyId,renewalData: $renewalData) {
    id
  }
}
`;

const useUpdateEconomicSubstanceInformation = () => {
  const [mutation] = useMutation(
    UPSERT_RENEWAL_DATA,
    { showSuccessNotification: true }
  );
  return mutation;
};

export default useUpdateEconomicSubstanceInformation;

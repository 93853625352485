import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import classes from "../../styles/director.module.scss";

function LegalEntityStructure() {
  const { next, context, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  
  const initialValues = useMemo(() => {
    return context?.initialData ?? {}
  }, [context]);

  const handleCancel = () => {
    cancel();
  }

  const handlePrev = () => {
    prev();
  }

  const handleSubmit = async (values) => {
    next(values);
  }
  
  useEffect( () => {
    dispatch(addAssistantText(translate('MODULES.ONBOARDINGROLES.DIRECTOR_V2.LEGAL_ENTITY_STRUCTURE_TOOLTIP')));
    return () => {
      dispatch(clearAssistatText());
    }
  },[dispatch]);
  
  const schema = Yup.object().shape({
    legalEntityStructure: Yup.string().required(translate('MODULES.ONBOARDINGROLES.DIRECTOR_V2.FIELD_REQUIRED'))
  });

  const options = [
    {
      label: "Company Limited by Shares",
      value: "LIMITED_BY_SHARES"
    },
    {
      label: "Limited Partnership",
      value: "LIMITED_PARTNERSHIP"
    },
    {
      label: "Trust",
      value: "TRUST"
    }
  ];

  return (
      <Form
          defaultValues={initialValues}
          schema={schema}
          onlySendDirty={false}
          onSubmit={handleSubmit}
          optionsForm={{ mode: 'onChange' }}
      >
        <TemplateWizard
            props={{
              title: translate("MODULES.ONBOARDINGROLES.DIRECTOR_V2.DIRECTOR_LEGAL_ENTITY_STRUCTURE_TITLE"),
              skeleton: null,
              subTitle: translate("MODULES.ONBOARDINGROLES.DIRECTOR_V2.CORPORATE_SUBTITLE"),
              variant: 'simple'
            }}
            buttons={{
              previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
              cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
              submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>{translate('GENERIC_BUTTON_ADD_GROUP')}</SubmitButton>,
            }}
        >
          <ControllerInput
              render={CheckboxButtonSimple}
              className={classes.CheckboxButtonSimple}
              classNameButton={classes.ButtonCheckbox}
              name="legalEntityStructure"
              options={options}
          />
        </TemplateWizard>
      </Form>
  );
}

export default LegalEntityStructure;
import React , {useEffect} from "react";
import IndividualOrCompanyStep from "../../../core/wizards/steps/IndividualOrCompanyStep/IndividualOrCompanyStep";
import _ from "lodash";
import useCustomMachine from "../../../../../generic/hooks/useCustomMachine";
import {useDispatch} from "react-redux";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import {addAssistantText, clearAssistatText} from "../../../../../generic/store/action";

const IndividualOrCompanyStepDirector = () => {

  const { state } = useCustomMachine();
  const dispatch = useDispatch();
  const { translate } = useTranslate();

  useEffect(() => {
    dispatch(
      addAssistantText(
        translate("MODULES.ONBOARDINGROLES.DIRECTOR.FIRST_STEP_1_TOOLTIPS"),
        "text"
      )
    );

    return () => dispatch(clearAssistatText()); 
  }, []);
  
  return (
    <>
      <IndividualOrCompanyStep
        isTherePrevious={false}
        role={"DIRECTOR"}
      />
    </>
  );
};

export default IndividualOrCompanyStepDirector;

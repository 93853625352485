import React from 'react';
import classes from './AddHealthcheckView.module.scss';
import Loading from "../../../../../components/generic/Loading/Loading";
import AddHealthcheckForm from "../../../../../forms/modules/startups/healthcheck/AddHealthcheckForm"

const AddHealthcheckView = (props) => {

    return (
        <React.Fragment>
            <div className={classes.AddHealthcheckView}>
                <Loading showChildren={false} loading={false}>
                    <AddHealthcheckForm />
                </Loading>
            </div>
        </React.Fragment >
    )
}

export default AddHealthcheckView;

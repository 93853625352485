import React, { useRef } from "react";
import Button from "../../../../generic/components/Buttons/Button";
import Form from "../../../../../components/forms/Form/Form";
import ControllerInput from "../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import CheckboxButtonSimple, {
  CheckboxButtonOptions,
} from "../../../../generic/components/Inputs/CheckboxButtonSimple";
import classesModule from "src/modules/startup/core/forms/YesOrNoForm/classes.module.scss";
import SubmitButton from "../../../../generic/components/Buttons/SubmitButton";
import TemplateFormDefault from "../../../../generic/templates/Modal";
import useTranslate from "../../../../generic/hooks/useTranslate";
import * as Yup from "yup";
import classForm from "./classes.module.scss"
import _ from "lodash";

const schema = Yup.object().shape({
  operationInUs: Yup.boolean().required("Required"),
});

const FormPlaceOfBusiness = ({
  Template = TemplateFormDefault,
  buttons = {},
  onCompleteSubmit,
  buttonSubmitProps,
  propsTemplate,
  handleCancel,
}) => {
 
  const refForm = useRef();
  const { translate } = useTranslate();
  
  const options: Array<CheckboxButtonOptions<boolean, any>> = [
    {
      value: false,
      label: "No, it won't",
    },
    {
      value: true,
      label: "Yes, it will",
    },
  ];

  
  return (
    <React.Fragment>
      <Form
        // initialValues={stepData || {}}
        schema={schema}
        onlySendDirty={false}
        onSubmit={onCompleteSubmit}
        ref={refForm}
        optionsForm={{ mode: "onChange" }}
      >
        <Template
          buttons={{
            ...buttons,
            submit: (
              <SubmitButton {...buttonSubmitProps}>
                {translate(`BUTTON_NEXT`)}
              </SubmitButton>
            ),
          }}
          props={propsTemplate}
        >
          <ControllerInput
            as={CheckboxButtonSimple}
            className={classesModule.CheckboxButton}
            classNameButton={classForm.buttonCheckBoxSize}
            name="operationInUs"
            variant="col_1"
            options={options}
        
          />
        </Template>
      </Form>
    </React.Fragment>
  );
};

export default FormPlaceOfBusiness;

import { useMemo } from 'react';

const useGuards = () => {
  
  return useMemo(() => ({

    isADGM: (context) => {
      return context?.ChooseJurisdiction?.jurisdiction?.jurisdictionType === "ADGM"
    },
    isCayman: (context) => {
      return context?.ChooseJurisdiction?.jurisdiction?.jurisdictionType === "CAYMAN"
    },
    isDelawere: (context) => {
      return context?.ChooseJurisdiction?.jurisdiction?.jurisdictionType === "DELAWARE"
    },
    isOther: (context) => {
      return context?.ChooseJurisdiction?.jurisdiction?.jurisdictionType === null
    },
    isADGM_Tech: (context) => {
      return context?.FormADGMType?.type === "Tech"
    },
    isADGM_SPV: (context) => {
      return context?.FormADGMType?.type === "SPV"
    }

  }), []);
}

export default useGuards;

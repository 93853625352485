import * as Yup from 'yup';
import { Constants } from "../../../utils/constants"
import _ from "lodash";
import moment from 'moment'

const maturityDateMin = new Date().toJSON().slice(0, 10);
// helper for yup transform function
function emptyStringToNull(value, originalValue) {
    if (typeof originalValue === 'string' && originalValue === '') {
        return null;
    }
    return value;
}

// to validate a number with min and max and allowing empty string
// const mySchema = yup.number().min(-0.2).max(0.2).transform(emptyStringToNull).nullable();
const vestingValidation = Yup.object().shape({
    isThereVesting:Yup.boolean().nullable().required('Required'),
    share:Yup.array().when('isThereVesting',{
        is:true,
        then:Yup.array().of(Yup.object().shape({
        issuedDate:Yup.date().typeError("Add a valid date").nullable().required('Required'),
        shareClass:Yup.object().shape({
            id:Yup.string().required('This field is required').nullable(),
            name:Yup.string().required('This field is required').nullable(),
        }),
        amountOfShares:Yup.number()
            .min(0, 'Min value 0')
            .required('Required').typeError('Must be a number'),
        pricePerShare:Yup.number()
            .min(0, 'Min value 0')
            .required('Required').typeError('Must be a number'),
        areTheSharesNumbered:Yup.boolean().nullable().required('This field is required'),
        numberedShares:Yup.object().when('areTheSharesNumbered',{
            is:true,
            then:Yup.object().shape({
                startingShareNumber:Yup.number()
            .min(0, 'Min value 0')
            .required('Required').typeError('Must be a number'),
            endingShareNumber:Yup.number()
            .min(0, 'Min value 0')
            .required('Required').typeError('Must be a number')
            }).required('Required').nullable(),
            otherwise:Yup.object().optional().nullable()
        }).nullable(),
        // isThereVestingTerms:Yup.boolean().required(),
        vestingTerms:Yup.object().shape({
                id:Yup.string().nullable().required('Required'),
                isThereCliff: Yup.boolean().required("Required"),
                cliffPeriod: Yup.string().when(['isThereCliff'], {
                    is: true,
                    then:Yup.string()
                    .matches(/^[^0|\D]\d{0,9}?$/,
                        {
                            message: "Must be a positive whole number greater than 0",
                            excludeEmptyString: true
                        })
                    .nullable()
                    .required('Required'),
                    otherwise: Yup.string().nullable().optional()
                }).nullable(),
                vestingStartDate: Yup.date().typeError("Add a valid date").nullable().required('Required'),
                vestingPeriod: Yup.string()
                    .matches(/^[^0|\D]\d{0,9}?$/,
                        {
                            message: "Must be a positive whole number greater than 0",
                            excludeEmptyString: true
                        })
                    .nullable()
                    .required('Required'),
                vestingFrequency: Yup.string().nullable().required('Required')
            }).nullable()
})).required('Required').nullable().min(1).test('isThereVestingTermsValidation',
null, // we'll return error message our self if needed
(obj) => {

    // only testing the checkboxes here
    if (obj && obj.some(sh=>sh.vestingTerms)) return true; // everything is fine

    return new Yup.ValidationError(
        'Must have one share position with vesting terms', // your custom error message
        null,
        'founder.share[0].vestingTerms'
    );
}),
otherwise:Yup.array().optional()
    }),

    confirmShares:Yup.boolean().when('share',{
        is:s=>s.some(s=>s.vestingSchedule),
        then:Yup.boolean().nullable().required('Required').oneOf([true],'This field is required'),
        otherwise:Yup.boolean().optional()
    })
})
const notEstablishedCompanyFounderValidation = Yup.object().shape({
    share:Yup.array(Yup.object().shape({
        shareClass:Yup.object().shape({
            name:Yup.string().required('This field is required').nullable(),
        }),
        amountOfShares:Yup.number()
            .min(0, 'Min value 0')
            .required('Required').typeError('Must be a number'),
        vestingTerms:Yup.lazy((value) => {
            if(value.id === 'ADD')
            return Yup.object().shape({
            id:Yup.string().nullable().required('Required'),
            isThereCliff: Yup.boolean().required("Required"),
			cliffPeriod: Yup.string().when(['isThereCliff'], {
				is: true,
				then:Yup.string()
                .matches(/^[^0|\D]\d{0,9}?$/,
                    {
                        message: "Must be a positive whole number greater than 0",
                        excludeEmptyString: true
                    })
                .nullable()
                .required('Required'),
				otherwise: Yup.string().nullable().optional()
			}).nullable(),
            vestingStartDate: Yup.date().typeError("Add a valid date").nullable().required('Required'),
            vestingPeriod: Yup.string()
                .matches(/^[^0|\D]\d{0,9}?$/,
                    {
                        message: "Must be a positive whole number greater than 0",
                        excludeEmptyString: true
                    })
                .nullable()
                .required('Required'),
            vestingFrequency: Yup.string().nullable().required('Required')
            }).nullable().required()
            return Yup.object().shape({
                id:Yup.string().nullable().required('Required'),
            })    
        })
    })).required('Required').nullable().min(1)
})
const founderValidation = Yup.object().shape({
     share:Yup.array(Yup.object().shape({
         issuedDate:Yup.date().typeError("Add a valid date").nullable().required('Required'),
        shareClass:Yup.object().shape({
            id:Yup.string().required('This field is required').nullable(),
            name:Yup.string().required('This field is required').nullable(),
        }),
        amountOfShares:Yup.number()
            .min(0, 'Min value 0')
            .required('Required').typeError('Must be a number'),
        pricePerShare:Yup.number()
            .min(0, 'Min value 0')
            .required('Required').typeError('Must be a number'),
        areTheSharesNumbered:Yup.boolean().nullable().required('This field is required'),
        numberedShares:Yup.object().when('areTheSharesNumbered',{
            is:true,
            then:Yup.object().shape({
                startingShareNumber:Yup.number()
            .min(0, 'Min value 0')
            .required('Required').typeError('Must be a number'),
            endingShareNumber:Yup.number()
            .min(0, 'Min value 0')
            .required('Required').typeError('Must be a number')
            }).required('Required').nullable(),
            otherwise:Yup.object().optional().nullable()
        }).nullable(),
        vestingTerms:Yup.lazy((value) => {
            if(value.id === 'NONE' || !value?.id)
            return Yup.object().shape({
                id:Yup.string().nullable().required('Required'),
            })    
            return Yup.object().shape({
            id:Yup.string().nullable().required('Required'),
            isThereCliff: Yup.boolean().required("Required"),
			cliffPeriod: Yup.string().when(['isThereCliff'], {
				is: true,
				then:Yup.string()
                .matches(/^[^0|\D]\d{0,9}?$/,
                    {
                        message: "Must be a positive whole number greater than 0",
                        excludeEmptyString: true
                    })
                .nullable()
                .required('Required'),
				otherwise: Yup.string().nullable().optional()
			}).nullable(),
            vestingStartDate: Yup.date().typeError("Add a valid date").nullable().required('Required'),
            vestingPeriod: Yup.string()
                .matches(/^[^0|\D]\d{0,9}?$/,
                    {
                        message: "Must be a positive whole number greater than 0",
                        excludeEmptyString: true
                    })
                .nullable()
                .required('Required'),
            vestingFrequency: Yup.string().nullable().required('Required')
            }).nullable().required()
        })
     })
    ).required('Required').nullable().min(1,'This founder does not have any share positions yet. Please go to “Equity” to add a share issuance for this founder.'),
            })
const founderValidationForNoVestingTerms = Yup.object().shape({
                share:Yup.array(Yup.object().shape({
                    issuedDate:Yup.date().typeError("Add a valid date").nullable().required('Required'),
                   shareClass:Yup.object().shape({
                       id:Yup.string().required('This field is required').nullable(),
                       name:Yup.string().required('This field is required').nullable(),
                   }),
                   amountOfShares:Yup.number()
                       .min(0, 'Min value 0')
                       .required('Required').typeError('Must be a number'),
                   pricePerShare:Yup.number()
                       .min(0, 'Min value 0')
                       .required('Required').typeError('Must be a number'),
                   areTheSharesNumbered:Yup.boolean().nullable().required('This field is required'),
                   numberedShares:Yup.object().when('areTheSharesNumbered',{
                       is:true,
                       then:Yup.object().shape({
                           startingShareNumber:Yup.number()
                       .min(0, 'Min value 0')
                       .required('Required').typeError('Must be a number'),
                       endingShareNumber:Yup.number()
                       .min(0, 'Min value 0')
                       .required('Required').typeError('Must be a number')
                       }).required('Required').nullable(),
                       otherwise:Yup.object().optional().nullable()
                   }).nullable(),
                   vestingTerms: Yup.object().shape({
                       id:Yup.string().nullable().required('Required'),
                       isThereCliff: Yup.boolean().required("Required"),
                       cliffPeriod: Yup.string().when(['isThereCliff'], {
                           is: true,
                           then:Yup.string()
                           .matches(/^[^0|\D]\d{0,9}?$/,
                               {
                                   message: "Must be a positive whole number greater than 0",
                                   excludeEmptyString: true
                               })
                           .nullable()
                           .required('Required'),
                           otherwise: Yup.string().nullable().optional()
                       }).nullable(),
                       vestingStartDate: Yup.date().typeError("Add a valid date").nullable().required('Required'),
                       vestingPeriod: Yup.string()
                           .matches(/^[^0|\D]\d{0,9}?$/,
                               {
                                   message: "Must be a positive whole number greater than 0",
                                   excludeEmptyString: true
                               })
                           .nullable()
                           .required('Required'),
                       vestingFrequency: Yup.string().nullable().required('Required')
                       }).nullable()
                   })
               ).required('Required').nullable().min(1,'This founder does not have any share positions yet. Please go to “Equity” to add a share issuance for this founder.'),
                       })            
const validationsFounderDetails = Yup.object().shape({
    fullName: Yup.string().nullable().required('Required'),
			email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true }).nullable().required('Required')
				.test('test email', '', function (value) {
					return true
				}),
			isAnEntity: Yup.boolean(),
			authorizedSignatory: Yup.object().when(['isAnEntity'], {
				is: true,
				then: Yup.object().shape({
					fullName: Yup.string().required('Required'),
					email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true }).nullable().required('Required')
						.test('test email', '', function (value) {
							return true
						})
				})
			}),
			verified1: Yup.boolean().required(),
			verified2: Yup.boolean().required()
}).nullable()
const validationsDefault=Yup.object().shape({
    agreementName:Yup.string().required('Required')
});

const validationsUploadToEdit = Yup.object().shape({
    confirmSameData: Yup.boolean().nullable().required('Required').oneOf([true]),
    file: Yup.object().nullable().required('Required')
});

const validationsUploadToEditFile=Yup.object().shape({
	file:Yup.object().nullable().required('Required')
});

const validationsIPAssignment = Yup.object().shape({
    agreementName: Yup.string().required("Required"),
    assignor: Yup.object().shape({
        id: Yup.string().nullable().required('Required'),
        fullName: Yup.string().nullable().required('Required'),
		isAnEntity: Yup.boolean(),
        nationality: Yup.string().nullable().required('Required'),
        address: Yup.object().shape({
            street: Yup.string().nullable().required('Required'),
            city: Yup.string().nullable().required('Required'),
            country: Yup.string().nullable().required('Required'),
            zipCode: Yup.string().nullable().optional(),
        }),
		email: Yup.string().when(['isAnEntity'], {
			is: false,
			then: Yup.string().lowercase()
				.matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
				.nullable().required('Required'),
			otherwise: Yup.string().nullable().optional()
		}),
		passportNumber: Yup.string().when(['isAnEntity'], {
            is: false,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        registeredNumber: Yup.string().when(['isAnEntity'], {
            is: true,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        jurisdiction: Yup.string().when(['isAnEntity'], {
            is: true,
            then: Yup.string().nullable().required('Required'),
            otherwise: Yup.string().nullable().optional()
        }),
        type: Yup.string().when(['isAnEntity'], {
            is: true,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        authorizedSignatory: Yup.object().when(['isAnEntity'], {
            is: true,
            then: Yup.object().shape({
                fullName: Yup.string().required('Required'),
				email: Yup.string().lowercase()
					.matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
					.nullable().required('Required')
					.test('test email', '', function (value) {
						return true
					})
            })
        }),
        recipientNotice: Yup.object().when(['isAnEntity'], {
            is: true,
            then: Yup.object().shape({
                fullName: Yup.string().nullable().required('Required'),
                email: Yup.string().lowercase()
					.matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
					.nullable()
					.required('Required'),
                address: Yup.object().shape({
                    country: Yup.string().nullable().required('Required'),
                    street: Yup.string().nullable().required('Required'),
                    city: Yup.string().nullable().required('Required'),
                    state: Yup.string().nullable().optional(),
                    zipCode: Yup.string().nullable().optional()
                })
            })
        })
    }).required('Required'),
    assignee: Yup.object().shape({
        id: Yup.string().nullable().required('Required'),
        name: Yup.string().required('Required'),
        country: Yup.string().nullable().required('Required'),
        registeredNumber: Yup.string().required('Required'),
        type: Yup.string().required('Required'),
        registeredAddress: Yup.object().shape({
            city: Yup.string().nullable().required('Required'),
            country: Yup.string().nullable().required('Required'),
            zipCode: Yup.string().nullable().optional(),
            street: Yup.string().nullable().required('Required')
        }),
        authorizedSignatories: Yup.array().of(Yup.object().shape({
                id: Yup.string().nullable().required('Required'),
                email: Yup.string().lowercase()
					.matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
					.nullable()
					.required('Required')
            })).min(1, 'Must have at least 1 items'),
        recipientNotice: Yup.object().shape({
            fullName: Yup.string().nullable().required('Required'),
            email: Yup.string().lowercase()
				.matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
				.nullable()
				.required('Required'),
            address: Yup.object().shape({
                country: Yup.string().nullable().required('Required'),
                street: Yup.string().nullable().required('Required'),
                city: Yup.string().nullable().required('Required'),
                state: Yup.string().nullable().optional(),
                zipCode: Yup.string().nullable().optional()
            })
        })
    }).required('Required'),
    terms:Yup.object().shape({
        placeOfArbitration:Yup.string().nullable().required('Required')
    })
});

const validationsSAFEAgreement = Yup.object().shape({
    agreementName: Yup.string().required("Required"),
    investor: Yup.object().shape({
        id: Yup.string().nullable().required('Required'),
        fullName: Yup.string().required('Required'),
		isAnEntity: Yup.boolean(),
        nationality: Yup.string().nullable().required('Required'),
        address: Yup.object().shape({
            street: Yup.string().nullable().required('Required'),
            city: Yup.string().nullable().required('Required'),
            country: Yup.string().nullable().required('Required'),
            zipCode: Yup.string().nullable().optional(),
        }),
		email: Yup.string().when(['isAnEntity'], {
			is: false,
			then: Yup.string().lowercase()
				.matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
				.nullable().required('Required'),
			otherwise: Yup.string().nullable().optional()
		}),
		passportNumber: Yup.string().when(['isAnEntity'], {
            is: false,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        registeredNumber: Yup.string().when(['isAnEntity'], {
            is: true,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()

        }),
        jurisdiction: Yup.string().when(['isAnEntity'], {
            is: true,
            then: Yup.string().nullable().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        type: Yup.string().when(['isAnEntity'], {
            is: true,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        authorizedSignatory: Yup.object().when(['isAnEntity'], {
            is: true,
            then: Yup.object().shape({
                fullName: Yup.string().required('Required'),
				email: Yup.string().lowercase()
					.matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
					.nullable()
					.required('Required')
					.test('test email', '', function (value) {
						return true
					})
			})
        }),
        recipientNotice: Yup.object().when(['isAnEntity'], {
            is: true,
            then: Yup.object().shape({
                fullName: Yup.string().required('Required'),
                email: Yup.string().lowercase()
					.matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
					.nullable()
					.required('Required'),
                address: Yup.object().shape({
                    country: Yup.string().nullable().required('Required'),
                    street: Yup.string().nullable().required('Required'),
                    city: Yup.string().nullable().required('Required'),
                    state: Yup.string().nullable().optional(),
                    zipCode: Yup.string().nullable().optional()
                })
            })
        })
    }).required('Required'),
    company: Yup.object().shape({
        id: Yup.string().nullable().required('Required'),
        name: Yup.string().required('Required'),
        country: Yup.string().nullable().required('Required'),
        registeredNumber: Yup.string().required('Required'),
        type: Yup.string().required('Required'),
        registeredAddress: Yup.object().shape({
            city: Yup.string().nullable().required('Required'),
            country: Yup.string().nullable().required('Required'),
            zipCode: Yup.string().nullable().optional(),
            street: Yup.string().nullable().required('Required')
        }),
        authorizedSignatories:
            Yup.array().of(Yup.object().shape({
                id: Yup.string().nullable().required('Required'),
                email: Yup.string().lowercase()
					.matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
					.nullable()
					.required('Required')
            })).min(1, 'Must have at least 1 items'),
        recipientNotice: Yup.object().shape({
            fullName: Yup.string().nullable().required('Required'),
            email: Yup.string().lowercase()
				.matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
				.nullable()
				.required('Required'),
            address: Yup.object().shape({
                country: Yup.string().nullable().required('Required'),
                street: Yup.string().nullable().required('Required'),
                city: Yup.string().nullable().required('Required'),
                state: Yup.string().nullable().optional(),
                zipCode: Yup.string().nullable().optional()
            })
        })
    }),
    terms: Yup.object().shape({
        discountRate: Yup.number()
            .min(1, 'Min value 1')
            .max(99, 'Max value 99')
            .required('Required').typeError('Must be a number'),
        currency: Yup.string().nullable().required('Required'),
        investmentAmount: Yup.number()
            .min(0, 'Min value 0')
            .required('Required').typeError('Must be a number'),
        valuationAmount: Yup.number()
            .min(0, 'Min value 0')
            .required('Required').typeError('Must be a number'),
            bankInfo: Yup.object().shape({
                code: Yup.string().nullable().required('Required'),
                country: Yup.string().required('Required').nullable(),
                bankName: Yup.string().nullable().required('Required'),
                ownerFullName: Yup.string().nullable().required('Required'),
                number: Yup.string().nullable().required('Required')
            }).nullable().required('Required')
    })
});

const validationsCLNAgreement = Yup.object().shape({
    agreementName: Yup.string().required("Required"),
    investor: Yup.object().shape({
        id: Yup.string().nullable().required('Required'),
        fullName: Yup.string().required('Required'),
		nationality: Yup.string().nullable().required('Required'),
        address: Yup.object().shape({
            street: Yup.string().nullable().required('Required'),
            city: Yup.string().nullable().required('Required'),
            country: Yup.string().nullable().required('Required'),
            zipCode: Yup.string().nullable().optional(),
        }),
        isAnEntity: Yup.boolean(),
		email: Yup.string().when(['isAnEntity'], {
			is: false,
			then: Yup.string().lowercase()
				.matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
				.nullable().required('Required'),
			otherwise: Yup.string().nullable().optional()
		}),
		passportNumber: Yup.string().when(['isAnEntity'], {
            is: false,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        registeredNumber: Yup.string().when(['isAnEntity'], {
            is: true,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        jurisdiction: Yup.string().when(['isAnEntity'], {
            is: true,
            then: Yup.string().nullable().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        type: Yup.string().when(['isAnEntity'], {
            is: true,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        authorizedSignatory: Yup.object().when(['isAnEntity'], {
            is: true,
            then: Yup.object().shape({
                fullName: Yup.string().required('Required'),
				email: Yup.string().lowercase()
					.matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
					.nullable()
					.required('Required')
					.test('test email', '', function (value) {
						return true
					})
			})
        }),
        recipientNotice: Yup.object().when(['isAnEntity'], {
            is: true,
            then: Yup.object().shape({
                fullName: Yup.string().required('Required'),
                email: Yup.string().lowercase()
					.matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
					.nullable()
					.required('Required'),
                address: Yup.object().shape({
                    country: Yup.string().nullable().required('Required'),
                    street: Yup.string().nullable().required('Required'),
                    city: Yup.string().nullable().required('Required'),
                    state: Yup.string().nullable().optional(),
                    zipCode: Yup.string().nullable().optional()
                })
            })
        })
    }).required('Required'),
    company: Yup.object().shape({
        id: Yup.string().nullable().required('Required'),
        name: Yup.string().required('Required'),
        country: Yup.string().nullable().required('Required'),
        registeredNumber: Yup.string().required('Required'),
        type: Yup.string().required('Required'),
        registeredAddress: Yup.object().shape({
            city: Yup.string().nullable().required('Required'),
            country: Yup.string().nullable().required('Required'),
            zipCode: Yup.string().nullable().optional(),
            street: Yup.string().nullable().required('Required')
        }),
        authorizedSignatories:
            Yup.array().of(Yup.object().shape({
                id: Yup.string().nullable().required('Required'),
                email: Yup.string().lowercase()
					.matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
					.nullable()
					.required('Required')
            })).min(1, 'Must have at least 1 items'),
        recipientNotice: Yup.object().shape({
            fullName: Yup.string().nullable().required('Required'),
            email: Yup.string().lowercase()
				.matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
				.nullable()
				.required('Required'),
            address: Yup.object().shape({
                country: Yup.string().nullable().required('Required'),
                street: Yup.string().nullable().required('Required'),
                city: Yup.string().nullable().required('Required'),
                state: Yup.string().nullable().optional(),
                zipCode: Yup.string().nullable().optional()
            })
        })
    }),
    terms: Yup.object().shape({
        discountRate: Yup.number()
            .min(0, 'Min value 0')
            .max(100, 'Max value 100')
            .required('Required').typeError('Must be a number'),
        currency: Yup.string().nullable().required('Required'),
        loanAmount: Yup.number()
            .min(0, 'Min value 0')
            .required('Required').typeError('Must be a number'),
        valuationAmount: Yup.number()
            .min(0, 'Min value 0')
            .required('Required').typeError('Must be a number'),
        applyInterestRate: Yup.boolean().required('Please select one option'),
        interestRate: Yup.number().when(['applyInterestRate'], {
            is: true,
            then: Yup.number()
                .min(0, 'Min value 0')
                .max(100, 'Max value 100')
                .required('Required')
                .typeError('Must be a number'),
            otherwise: Yup.number().nullable().optional()
        }),
        maturityDateOption: Yup.string().nullable().required('Please select an option'),
        maturityMonths: Yup.number().when(['maturityDateOption'], {
            is: 'months',
            then: Yup.number()
                .min(1, 'Min value 1')
                .required('Required')
                .typeError('Must be a number'),
            otherwise: Yup.number().nullable().optional()
        }),
        maturityDate: Yup.date().when(['maturityDateOption'], {
            is: 'specificDate',
            then: Yup.date()
                .min(maturityDateMin, 'Must be later then ' + maturityDateMin)
                .required('Required')
                .typeError('Must be a valid date'),
            otherwise: Yup.date().nullable().optional()
        }),
        bankInfo: Yup.object().shape({
            code: Yup.string().nullable().required('Required'),
            country: Yup.string().required('Required').nullable(),
            bankName: Yup.string().nullable().required('Required'),
            ownerFullName: Yup.string().nullable().required('Required'),
            number: Yup.string().nullable().required('Required')
        }).nullable().required('Required'),
        placeOfArbitration:Yup.string().required('Required')
    })
});

const validationsAdvisorAgreement = Yup.object().shape({
    agreementName: Yup.string().required("Required"),
    advisor: Yup.object().shape({
        id: Yup.string().nullable().required('Required'),
        fullName: Yup.string().required('Required'),
        nationality: Yup.string().nullable().required('Required'),
        address: Yup.object().shape({
            street: Yup.string().nullable().required('Required'),
            city: Yup.string().nullable().required('Required'),
            country: Yup.string().nullable().required('Required'),
            zipCode: Yup.string().nullable().optional(),
        }),
        isAnEntity: Yup.boolean(),
		email: Yup.string().when(['isAnEntity'], {
			is: false,
			then: Yup.string().lowercase()
				.matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
				.nullable().required('Required'),
			otherwise: Yup.string().nullable().optional()
		}),
		passportNumber: Yup.string().when(['isAnEntity'], {
            is: false,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        registeredNumber: Yup.string().when(['isAnEntity'], {
            is: true,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()

        }),
        jurisdiction: Yup.string().when(['isAnEntity'], {
            is: true,
            then: Yup.string().nullable().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        type: Yup.string().when(['isAnEntity'], {
            is: true,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        authorizedSignatory: Yup.object().when(['isAnEntity'], {
            is: true,
            then: Yup.object().shape({
                fullName: Yup.string().required('Required'),
				email: Yup.string().lowercase()
					.matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
					.nullable()
					.required('Required')
					.test('test email', '', function (value) {
						return true
					})
			})
        }),
        recipientNotice: Yup.object().when(['isAnEntity'], {
            is: true,
            then: Yup.object().shape({
                fullName: Yup.string().required('Required'),
                email: Yup.string().lowercase()
					.matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
					.nullable()
					.required('Required'),
                address: Yup.object().shape({
                    country: Yup.string().nullable().required('Required'),
                    street: Yup.string().nullable().required('Required'),
                    city: Yup.string().nullable().required('Required'),
                    state: Yup.string().nullable().optional(),
                    zipCode: Yup.string().nullable().optional()
                })
            })
        })
    }).required('Required'),
    company: Yup.object().shape({
        id: Yup.string().nullable().required('Required'),
        name: Yup.string().required('Required'),
        country: Yup.string().nullable().required('Required'),
        jurisdiction: Yup.string().nullable().required('Required'),
        registeredNumber: Yup.string().required('Required'),
        type: Yup.string().required('Required'),
        registeredAddress: Yup.object().shape({
            city: Yup.string().nullable().required('Required'),
            country: Yup.string().nullable().required('Required'),
            zipCode: Yup.string().nullable().optional(),
            street: Yup.string().nullable().required('Required')
        }),
        authorizedSignatories: Yup.array().of(Yup.object().shape({
                id: Yup.string().nullable().required('Required'),
                email: Yup.string().lowercase()
					.matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
					.nullable()
					.required('Required')
            })).min(1, 'Must have at least 1 items'),
        recipientNotice: Yup.object().shape({
            fullName: Yup.string().nullable().required('Required'),
            email: Yup.string()
				.matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
				.nullable()
				.required('Required'),
            address: Yup.object().shape({
                country: Yup.string().nullable().required('Required'),
                street: Yup.string().nullable().required('Required'),
                city: Yup.string().nullable().required('Required'),
                state: Yup.string().nullable().optional(),
                zipCode: Yup.string().nullable().optional()
            })
        })
    }),
    terms: Yup.object().shape({
        placeOfArbitration:Yup.string().required('Required'),
        timeCommitment: Yup.number()
            .min(0, 'Min value 0')
            .max(100, 'Max value 100')
            .required('Required')
            .transform(emptyStringToNull)
            .typeError('Must be a number')
            .nullable(),
        adviceAreas: Yup.object().shape({
            option_a: Yup.object().shape({
                id: Yup.boolean().nullable()
            }),
            option_b: Yup.object().shape({
                id: Yup.boolean().nullable()
            }),
            option_c: Yup.object().shape({
                id: Yup.boolean().nullable()
            }),
            option_d: Yup.object().shape({
                id: Yup.boolean().nullable()
            }),
            option_e: Yup.object().shape({
                id: Yup.boolean().nullable()
            }),
            option_f: Yup.object().shape({
                id: Yup.boolean().nullable()
            }),
            option_g: Yup.object().shape({
                id: Yup.boolean().nullable()
            }),
            option_h: Yup.object().shape({
                id: Yup.boolean().nullable(),
                message: Yup.string().when(['id'], {
                    is: true,
                    then: Yup.string().required('Required'),
                    otherwise: Yup.string().nullable().optional()
                })
            })
        }).test('adviceAreasCheckboxValidation',
            null, // we'll return error message our self if needed
            (obj) => {
                // only testing the checkboxes here
                if (obj.option_a.id || obj.option_b.id || obj.option_c.id || obj.option_d.id ||
                    obj.option_e.id || obj.option_f.id || obj.option_g.id || obj.option_h.id) { // put every checkbox here
                    return true; // everything is fine
                }
                return new Yup.ValidationError(
                    'Must select at least one area of advice', // your custom error message
                    null,
                    'adviceAreasFieldName'
                );
            }),
        timeCommitmentFrequency: Yup.string().required('Please select an option'),
        meetingAttendance: Yup.object().shape({
            isAdvisoryBoard: Yup.boolean().required('Required'),
            advisoryBoardMeetingFrequency: Yup.string().when(['isAdvisoryBoard'], {
                is: true,
                then: Yup.string().required('Required'),
                otherwise: Yup.string().nullable().optional()
            }),
            duration: Yup.number().nullable()
                .min(1, 'Min value 1')
                .max(20, 'Max value 20')
                .required('Required')
                .transform(emptyStringToNull)
                .typeError('Must be a number'),
            frequency: Yup.string().required('Required'),
        }),
        compensation: Yup.object().shape({
            numberShares: Yup.number()
                .min(0, 'Min value 0')
                .required('Required')
                .transform(emptyStringToNull)
                .typeError('Must be a number')
                .nullable(),
            percentageOwnership: Yup.number()
                .min(0, 'Min value 0')
                .max(100, 'Max value 100')
                .required('Required')
                .transform(emptyStringToNull)
                .typeError('Must be a number')
                .nullable(),
        }),
    })
});

const validationsFounderTerms = Yup.object().shape({
    agreementName: Yup.string().required("Required"),
    totalFounders: Yup.number().required("Required"),
    holdingCompany: Yup.string().required("Required"),
    founders: Yup.array().compact().of(
        Yup.object().shape({
            rolesAndResponsibilities: Yup.string().required('Required'),
            shareOwnership: Yup.number().required('Required'),
            initialCapitalContributions: Yup.number().required('Required'),
            shares: Yup.number().required('Required')
        })
    ).required('Required')
});

const validationsKISSAgreement = Yup.object().shape({
    agreementName: Yup.string().required("Required")
});

const validationsConsultantAgreement = Yup.object().shape({
    agreementName: Yup.string().required("Required"),
    company: Yup.object().shape({
        id: Yup.string().nullable().required('Required'),
        name: Yup.string().nullable().required('Required'),
        country: Yup.string().nullable().required('Required'),
        registeredNumber: Yup.string().required('Required'),
        type: Yup.string().required('Required'),
        registeredAddress: Yup.object().shape({
            city: Yup.string().nullable().required('Required'),
            country: Yup.string().nullable().required('Required'),
            zipCode: Yup.string().nullable().optional(),
            street: Yup.string().nullable().required('Required')
        }),
        authorizedSignatories:
            Yup.array().of(Yup.object().shape({
                id: Yup.string().nullable().required('Required'),
                email: Yup.string().lowercase().nullable().matches(Constants.REGEXP.EMAIL, {
                    message: "This field must be a valid email",
                    excludeEmptyString: true
                }).required('Required')
            })).min(1, 'Must have at least 1 items'),
        recipientNotice: Yup.object().shape({
            fullName: Yup.string().nullable().required('Required'),
            email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, {
                message: "This field must be a valid email",
                excludeEmptyString: true
            }).nullable().required('Required'),
            address: Yup.object().shape({
                country: Yup.string().nullable().required('Required'),
                street: Yup.string().nullable().required('Required'),
                city: Yup.string().nullable().required('Required'),
                state: Yup.string().nullable().optional(),
                zipCode: Yup.string().nullable().optional()
            })
        })
    }),
    consultant: Yup.object().shape({
        id: Yup.string().nullable().required('Required'),
        fullName: Yup.string().nullable().required('Required'),
        nationality: Yup.string().nullable().required('Required'),
        address: Yup.object().shape({
            street: Yup.string().nullable().required('Required'),
            city: Yup.string().nullable().required('Required'),
            country: Yup.string().nullable().required('Required'),
            zipCode: Yup.string().nullable().optional(),
        }),
        isAnEntity: Yup.boolean(),
		email: Yup.string().when(['isAnEntity'], {
			is: false,
			then: Yup.string().lowercase()
				.matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
				.nullable().required('Required'),
			otherwise: Yup.string().nullable().optional()
		}),
		passportNumber: Yup.string().when(['isAnEntity'], {
            is: false,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        registeredNumber: Yup.string().when(['isAnEntity'], {
            is: true,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        jurisdiction: Yup.string().when(['isAnEntity'], {
            is: true,
            then: Yup.string().nullable().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        type: Yup.string().when(['isAnEntity'], {
            is: true,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        authorizedSignatory: Yup.object().when(['isAnEntity'], {
            is: true,
            then: Yup.object().shape({
                fullName: Yup.string().required('Required'),
				email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true }).nullable().required('Required')
					.test('test email', '', function (value) {
						return true
					})
            })
        }),
        recipientNotice: Yup.object().when(['isAnEntity'], {
            is: true,
            then: Yup.object().shape({
                fullName: Yup.string().nullable().required('Required'),
                email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, {
                    message: "This field must be a valid email",
                    excludeEmptyString: true
                }).nullable().required('Required'),
                address: Yup.object().shape({
                    country: Yup.string().nullable().required('Required'),
                    street: Yup.string().nullable().required('Required'),
                    city: Yup.string().nullable().required('Required'),
                    state: Yup.string().nullable().optional(),
                    zipCode: Yup.string().nullable().optional()
                })
            })
        })
    }).required('Required'),
    terms: Yup.object().shape({
        // agreementDuration: Yup.object().shape({
        //     year: Yup.number().optional().nullable(),
        //     month: Yup.number().optional().nullable()
        // }).test('one must be completed',
        //     null, // we'll return error message our self if needed
        //     (obj) => {
        //         if (obj.month || obj.year) { // put every checkbox here
        //             return true; // everything is fine
        //         }
        //         return new Yup.ValidationError(
        //             'Must complete the duration', // your custom error message
        //             null,
        //             'agreementDuration'
        //         );
        //     }),
        placeOfArbitration:Yup.string().required('Required'),
        fees: Yup.object().shape({
            currency: Yup.string().nullable().required('Please select a currency'),
            feesFrequency: Yup.string().nullable().required("Required"),
            amount: Yup.number().when(['feesFrequency'], {
                is: (value)=>value === 'perWeek' || value === 'perMonth' ,
                then: Yup.number()
                .nullable()
                .required('Required')
                .transform(emptyStringToNull)
                .typeError('Must be a number'),
                otherwise: Yup.number().nullable().optional()
            })
        }),
    })
});

const validationsEmploymentAgreement = Yup.object().shape({
    agreementName: Yup.string().required("Required"),
    company: Yup.object().shape({
        id: Yup.string().nullable().required('Required'),
        name: Yup.string().nullable().required('Required'),
        country: Yup.string().nullable().required('Required'),
        registeredNumber: Yup.string().required('Required'),
        type: Yup.string().required('Required'),
        registeredAddress: Yup.object().shape({
            city: Yup.string().nullable().required('Required'),
            country: Yup.string().nullable().required('Required'),
            zipCode: Yup.string().nullable().optional(),
            street: Yup.string().nullable().required('Required')
        }),
        authorizedSignatories:
            Yup.array().of(Yup.object().shape({
                id: Yup.string().nullable().required('Required'),
                email: Yup.string().lowercase().nullable().matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true }).required('Required')
            })).min(1, 'Must have at least 1 items'),
        recipientNotice: Yup.object().shape({
            fullName: Yup.string().nullable().required('Required'),
            email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true }).nullable().required('Required'),
            address: Yup.object().shape({
                country: Yup.string().nullable().required('Required'),
                street: Yup.string().nullable().required('Required'),
                city: Yup.string().nullable().required('Required'),
                state: Yup.string().nullable().optional(),
                zipCode: Yup.string().nullable().optional()
            })
        })
    }),
    employee: Yup.object().shape({
        id: Yup.string().nullable().required('Required'),
        fullName: Yup.string().required('Required'),
        nationality: Yup.string().nullable().required('Required'),
        address: Yup.object().shape({
            street: Yup.string().nullable().required('Required'),
            city: Yup.string().nullable().required('Required'),
            country: Yup.string().nullable().required('Required'),
            zipCode: Yup.string().nullable().optional(),
        }),
        isAnEntity: Yup.boolean(),
		email: Yup.string().when(['isAnEntity'], {
			is: false,
			then: Yup.string().lowercase()
				.matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
				.nullable().required('Required'),
			otherwise: Yup.string().nullable().optional()
		}),
		passportNumber: Yup.string().when(['isAnEntity'], {
            is: false,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        registeredNumber: Yup.string().when(['isAnEntity'], {
            is: true,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        jurisdiction: Yup.string().when(['isAnEntity'], {
            is: true,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        type: Yup.string().when(['isAnEntity'], {
            is: true,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        authorizedSignatory: Yup.object().when(['isAnEntity'], {
            is: true,
            then: Yup.object().shape({
                fullName: Yup.string().required('Required'),
				email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true }).nullable().required('Required')
					.test('test email', '', function (value) {
						return true
					})
            })
        }),
        recipientNotice: Yup.object().when(['isAnEntity'], {
            is: true,
            then: Yup.object().shape({
                fullName: Yup.string().required('Required'),
                email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true }).nullable().required('Required'),
                address: Yup.object().shape({
                    country: Yup.string().nullable().required('Required'),
                    street: Yup.string().nullable().required('Required'),
                    city: Yup.string().nullable().required('Required'),
                    state: Yup.string().nullable().optional(),
                    zipCode: Yup.string().nullable().optional()
                })
            })
        })
    }).required('Required'),
    terms: Yup.object().shape({
        positionAndLocation: Yup.object().shape({
            position:Yup.string().nullable().required('Required'),
                    workingAddress: Yup.object().shape({
                            city: Yup.string().nullable().required('Required'),
                            country: Yup.string().nullable().required('Required'),
                            zipCode: Yup.string().nullable().optional(),
                            street: Yup.string().nullable().required('Required')
                        }),
                        homeCountry:Yup.string().nullable().required('Required')
                }),
        workTime: Yup.object().shape({
            start: Yup.string().nullable().required('Required')
                .test('format check',
                    null, // we'll return error message our self if needed
                    (obj) => {
                        if (!moment(obj, "HH:mm").isValid()) {
                            return new Yup.ValidationError(
                                'Must be a valid time', // your custom error message
                                null,
                                'terms.workTime.start'
                            )// everything is fine
                        } 
                            return true
                    }),
            finish: Yup.string().nullable().required('Required')
                .test('format check',
                    null, // we'll return error message our self if needed
                    (obj) => {
                        if (!moment(obj, "HH:mm").isValid()) { // put every checkbox here
                            return new Yup.ValidationError(
                                'Must be a valid time', // your custom error message
                                null,
                                'terms.workTime.finish'
                            )// everything is fine
                        } else {
                            return true
                        }
                    }),
            isOvertimePermitted: Yup.boolean().nullable().required('Required')
        }),
        commencementAndProbation: Yup.object().shape({
            date:Yup.date()
                .nullable()
                .required('Required')
                .transform(emptyStringToNull)
                .typeError('Add a valid date'),
            probationPeriod: Yup.number()
                .min(1, 'Min value 1')
                .max(24, 'Max value 24')
                .nullable()
                .required('Required')
                .transform(emptyStringToNull)
                .typeError('Must be a number')
            ,
            employerNoticePeriod: Yup.number()
                .min(1, 'Min value 1')
                .max(24, 'Max value 24')
                .nullable()
                .required('Required')
                .transform(emptyStringToNull)
                .typeError('Must be a number')
            ,
            employeeNoticePeriod: Yup.number()
                .min(1, 'Min value 1')
                .max(24, 'Max value 24')
                .nullable()
                .required('Required')
                .transform(emptyStringToNull)
                .typeError('Must be a number')
        }),
        salaryAndBenefits: Yup.object().shape({
            baseSalary: Yup.number().nullable()
                .required('Required')
                .transform(emptyStringToNull)
                .typeError('Must be a number'),
            annualSalary: Yup.number().nullable()
                .required('Required')
                .transform(emptyStringToNull)
                .typeError('Must be a number'),
            benefits: Yup.array().of(Yup.string()).nullable(),
            housingAllowance: Yup.number().when(['benefits'], {
                is: (values) => _.some(values, value => value === 'housingAllowance'),
                then: Yup.number()
                    .transform(emptyStringToNull)
                    .typeError('Must be a number').required('Required'),
                otherwise: Yup.number().nullable().optional()
            }),
            transportAllowance: Yup.number().when(['benefits'], {
                is: (values) => _.some(values, value => value === 'transportAllowance'),
                then: Yup.number()
                    .transform(emptyStringToNull)
                    .typeError('Must be a number').required('Required'),
                otherwise: Yup.number().nullable().optional()
            }),
            lifeInsurance: Yup.string().when(['benefits'], {
                is: (values) => _.some(values, value => value === 'lifeInsurance'),
                then: Yup.string().required('Required'),
                otherwise: Yup.string().nullable().optional()
            }),
            medicalCare: Yup.string().when(['benefits'], {
                is: (values) => _.some(values, value => value === 'medicalCare'),
                then: Yup.string().nullable().required('Please select an option'),
                otherwise: Yup.string().nullable().optional()
            })
        }),
        gardenLeave: Yup.object().shape({
            havesGardenLeave: Yup.boolean().required('Required')
        }),
        terminationAndVariation: Yup.object().shape({
            terminationNoticeTime: Yup.number()
                .min(1, 'Min value 1')
                .max(7, 'Max value 7')
                .required('Required')
                .transform(emptyStringToNull)
                .typeError('Must be a number')
                .nullable(),
            withoutNoticeAndPayment: Yup.boolean().required('Required'),
            isPayingContractualNotice: Yup.boolean().when(['withoutNoticeAndPayment'], {
                is: true,
                then: Yup.boolean().required('Required'),
                otherwise: Yup.boolean().nullable().optional()
            }).nullable(),
            variations: Yup.string().nullable().required('Required')
        }),
        annualLeave: Yup.object().shape({
            days: Yup.number()
                .min(20, 'Min value 20')
                .max(360, 'Max value 360')
                .required('Required')
                .transform(emptyStringToNull)
                .typeError('Must be a number')
                .nullable(),
            startDate: Yup.date()
                .nullable()
                .required('Required')
                .transform(emptyStringToNull)
                .typeError('Add a valid date'),
        })
    })
});

const validationsFounderServicesAgreement = Yup.object().shape({
    agreementName: Yup.string().required('Required'),
    company: Yup.object().shape({
        id: Yup.string().nullable().required('Required'),
        name: Yup.string().nullable().required('Required'),
        country: Yup.string().nullable().required('Required'),
        registeredNumber: Yup.string().required('Required'),
        type: Yup.string().required('Required'),
        registeredAddress: Yup.object().shape({
            city: Yup.string().nullable().required('Required'),
            country: Yup.string().nullable().required('Required'),
            zipCode: Yup.string().nullable().optional(),
            street: Yup.string().nullable().required('Required')
        }),
        authorizedSignatories:
            Yup.array().of(Yup.object().shape({
                id: Yup.string().nullable().required('Required'),
                email: Yup.string().lowercase().nullable().matches(Constants.REGEXP.EMAIL, {
                    message: "This field must be a valid email",
                    excludeEmptyString: true
                }).required('Required')
            })).min(1, 'Must have at least 1 items'),
        recipientNotice: Yup.object().shape({
            fullName: Yup.string().nullable().required('Required'),
            email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, {
                message: "This field must be a valid email",
                excludeEmptyString: true
            }).nullable().required('Required'),
            address: Yup.object().shape({
                country: Yup.string().nullable().required('Required'),
                street: Yup.string().nullable().required('Required'),
                city: Yup.string().nullable().required('Required'),
                state: Yup.string().nullable().optional(),
                zipCode: Yup.string().nullable().optional()
            })
        })
    }).required('Required'),
    founder: Yup.object().shape({
        id: Yup.string().nullable().required('Required'),
        fullName: Yup.string().required('Required'),
        nationality: Yup.string().nullable().required('Required'),
        address: Yup.object().shape({
            street: Yup.string().nullable().required('Required'),
            city: Yup.string().nullable().required('Required'),
            country: Yup.string().nullable().required('Required'),
            zipCode: Yup.string().nullable().optional(),
        }),
        position:Yup.lazy(obj=>{
            //console.log(obj)
        if(!obj) {
            return Yup.object().shape({}).nullable().required('Required')
        }else if (!obj && obj === null) {
          return Yup.object()
            .shape({ id: Yup.string().nullable().required("Required") })
            .nullable();
        } else if(obj && obj.isDefault){
            return Yup.object().shape({
                id: Yup.string().nullable().required('Required'),
                isDefault: Yup.boolean(),
                responsibilities: Yup.array().of(Yup.object()).when(['isDefault'], {
                    is: true,
                    then: Yup.array().of(Yup.object()).nullable().required('At least one must be selected'),
                    otherwise: Yup.array().of(Yup.object()).nullable().optional()
                }),
                reports: Yup.string().when(['code'], {
                    is: (value) => value !== 'CEO',
                    then: Yup.string().nullable().required('At least one must be selected'),
                    otherwise: Yup.string().nullable().optional()
                })
            })
        } else if(obj && !obj.isDefault){
            return Yup.object().shape({
                id: Yup.string().nullable().required('Required'),
                isDefault: Yup.boolean(),
                otherResponsibilities: Yup.string().when(['isDefault'], {
                    is: false,
                    then: Yup.string().nullable().required('Required'),
                    otherwise: Yup.string().nullable().optional()
                }),
                reports: Yup.string().when(['code'], {
                    is: (value) => value !== 'CEO',
                    then: Yup.string().nullable().required('At least one must be selected'),
                    otherwise: Yup.string().nullable().optional()
                })
            })
        }
        }),
        isAnEntity: Yup.boolean(),
		email: Yup.string().when(['isAnEntity'], {
			is: false,
			then: Yup.string().lowercase()
				.matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
				.nullable().required('Required'),
			otherwise: Yup.string().nullable().optional()
		}),
		passportNumber: Yup.string().when(['isAnEntity'], {
            is: false,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        registeredNumber: Yup.string().when(['isAnEntity'], {
            is: true,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        jurisdiction: Yup.string().when(['isAnEntity'], {
            is: true,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        type: Yup.string().when(['isAnEntity'], {
            is: true,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        authorizedSignatory: Yup.object().when(['isAnEntity'], {
            is: true,
            then: Yup.object().shape({
                fullName: Yup.string().required('Required'),
                email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, {
                    message: "This field must be a valid email",
                    excludeEmptyString: true
                }).nullable().required('Required')
                    .test('test email', '', function (value) {
                        return true
                    })
            })
        })
    }).required('Required').concat(vestingValidation),
    terms: Yup.object().shape({
        services: Yup.lazy((values) => {
            if (!values || _.get(values, 'commitment') !== 'otherCommitment') {
                return Yup.object().shape({
                    commitment: Yup.string().nullable().required('Required'),
                    otherCommitment: Yup.string().when(['commitment'], {
                        is: 'otherCommitment',
                        then: Yup.string().required('Required'),
                        otherwise: Yup.string().nullable().optional()
                    })
                })
            } else if (values.commitment && values.commitment === 'otherCommitment') {
                return Yup.object().shape({
                    commitment: Yup.string().nullable().required('Required'),
                    otherCommitment: Yup.string().when(['commitment'], {
                        is: 'otherCommitment',
                        then: Yup.string().required('Required'),
                        otherwise: Yup.string().nullable().optional()
                    }),
                    otherHours: Yup.number().when(['otherCommitment'], {
                        is: "otherHours",
                        then: Yup.number()
                            .min(1, 'Min value 1')
                            .max(168, 'Max value 168')
                            .transform(emptyStringToNull)
                            .required('Required')
                            .typeError('Must be a number'),
                        otherwise: Yup.number().nullable()
                    }),
                })
            }
        }),
        placeOfArbitration:Yup.string().required('Required'),
        location: Yup.object().shape({
            workingAddress: Yup.object().shape({
                city: Yup.string().nullable().required('Required'),
                country: Yup.string().nullable().required('Required'),
            })
        }),
        compensation: Yup.object().shape({
            currency: Yup.string().nullable().required('Please select an option'),
            salary: Yup.number().nullable()
                .required('Required')
                .transform(emptyStringToNull)
                .typeError('Must be a number')
        })
    }).required('Required')
});

const validationsGrantAgreement = Yup.object().shape({
    agreementName: Yup.string().required("Required"),
    company: Yup.object().shape({
        id: Yup.string().nullable().required('Required'),
        name: Yup.string().nullable().required('Required'),
        country: Yup.string().nullable().required('Required'),
        jurisdiction: Yup.string().nullable().required('Required'),
        registeredNumber: Yup.string().required('Required'),
        type: Yup.string().required('Required'),
        registeredAddress: Yup.object().shape({
            city: Yup.string().nullable().required('Required'),
            country: Yup.string().nullable().required('Required'),
            zipCode: Yup.string().nullable().optional(),
            street: Yup.string().nullable().required('Required')
        }),
        authorizedSignatories:
            Yup.array().of(Yup.object().shape({
                id: Yup.string().nullable().required('Required'),
                email: Yup.string().lowercase().nullable().matches(Constants.REGEXP.EMAIL, {
                    message: "This field must be a valid email",
                    excludeEmptyString: true
                }).required('Required')
            })).min(1, 'Must have at least 1 items'),
        recipientNotice: Yup.object().shape({
            fullName: Yup.string().nullable().required('Required'),
            email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, {
                message: "This field must be a valid email",
                excludeEmptyString: true
            }).nullable().required('Required'),
            address: Yup.object().shape({
                country: Yup.string().nullable().required('Required'),
                street: Yup.string().nullable().required('Required'),
                city: Yup.string().nullable().required('Required'),
                state: Yup.string().nullable().optional(),
                zipCode: Yup.string().nullable().optional()
            })
        })
    }),
    participant: Yup.object().shape({
        id: Yup.string().nullable().required('Required'),
        fullName: Yup.string().required('Required'),
        nationality: Yup.string().nullable().required('Required'),
        address: Yup.object().shape({
            street: Yup.string().nullable().required('Required'),
            city: Yup.string().nullable().required('Required'),
            country: Yup.string().nullable().required('Required'),
            zipCode: Yup.string().nullable().optional(),
        }),
        isAnEntity: Yup.boolean(),
		email: Yup.string().when(['isAnEntity'], {
			is: false,
			then: Yup.string().lowercase()
				.matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
				.nullable().required('Required'),
			otherwise: Yup.string().nullable().optional()
		}),
		passportNumber: Yup.string().when(['isAnEntity'], {
            is: false,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        registeredNumber: Yup.string().when(['isAnEntity'], {
            is: true,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        jurisdiction: Yup.string().when(['isAnEntity'], {
            is: true,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        type: Yup.string().when(['isAnEntity'], {
            is: true,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        authorizedSignatory: Yup.object().when(['isAnEntity'], {
            is: true,
            then: Yup.object().shape({
                fullName: Yup.string().required('Required'),
				email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true }).nullable().required('Required')
					.test('test email', '', function (value) {
						return true
					})
            })
        }),
        recipientNotice: Yup.object().when(['isAnEntity'], {
            is: true,
            then: Yup.object().shape({
                fullName: Yup.string().required('Required'),
                email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, {
                    message: "This field must be a valid email",
                    excludeEmptyString: true
                }).nullable().required('Required'),
                address: Yup.object().shape({
                    country: Yup.string().nullable().required('Required'),
                    street: Yup.string().nullable().required('Required'),
                    city: Yup.string().nullable().required('Required'),
                    state: Yup.string().nullable().optional(),
                    zipCode: Yup.string().nullable().optional()
                })
            }),
			otherwise: Yup.object().nullable().optional()
        })
    }).required('Required'),
    terms: Yup.object().shape({
        shareIncentivePlan: Yup.object().shape({
            id: Yup.string().nullable().required('Required')
        }),
        grant: Yup.object().shape({
            sharesNumber: Yup.string()
                .matches(Constants.REGEXP.POSITIVE, {
                    message: "Must be  an integer greater than or equal to 0",
                    excludeEmptyString: true
                })
                .nullable()
                .required('Required')
                .transform(emptyStringToNull)
                .typeError('Must be a number'),
            pricePerShare: Yup.number()
                .nullable()
                .required('Required')
                .transform(emptyStringToNull)
                .typeError('Must be a number'),
        }).test('sipValidationDataValidate',null,value => {
                if(value && value.sipValidationData && parseInt(value.sharesNumber) > _.get(value.sipValidationData, 'remaining', 0)){
                    return new Yup.ValidationError(
                         `You currently only have ${_.get(value.sipValidationData, 'remaining')} unallocated shares in ${_.get(value.sipValidationData, 'name')}, please enter a lower amount.`,
                        null,
                        'terms.grant.sharesNumber')
                }

                return true
            }),
        vesting: Yup.object().shape({
            id:Yup.string().nullable().required('Required'),
            isThereCliff: Yup.boolean().required("Required"),
			cliffPeriod: Yup.string().when(['isThereCliff'], {
				is: true,
				then:Yup.string()
                .matches(/^[^0|\D]\d{0,9}?$/,
                    {
                        message: "Must be a positive whole number greater than 0",
                        excludeEmptyString: true
                    })
                .nullable()
                .required('Required'),
				otherwise: Yup.string().nullable().optional()
			}).nullable(),
            vestingStartDate: Yup.date().typeError("Add a valid date").nullable().required('Required'),
            vestingPeriod: Yup.string()
                .matches(/^[^0|\D]\d{0,9}?$/,
                    {
                        message: "Must be a positive whole number greater than 0",
                        excludeEmptyString: true
                    })
                .nullable()
                .required('Required'),
            vestingFrequency: Yup.string().nullable().required('Required')
        }).nullable(),
        placeOfArbitration:Yup.string().nullable().required('Required')
    })
});

const validationSharesIncentivePlan = Yup.object().shape({
    agreementName: Yup.string().required('Required'),
    company: Yup.object().shape({
        id: Yup.string().nullable().required('Required'),
        name: Yup.string().nullable().required('Required'),
        country: Yup.string().nullable().required('Required'),
        registeredNumber: Yup.string().required('Required'),
        type: Yup.string().required('Required'),
        registeredAddress: Yup.object().shape({
            city: Yup.string().nullable().required('Required'),
            country: Yup.string().nullable().required('Required'),
            zipCode: Yup.string().nullable().optional(),
            street: Yup.string().nullable().required('Required')
        }),
        authorizedSignatories:
            Yup.array().of(Yup.object().shape({
                id: Yup.string().nullable().required('Required'),
                email: Yup.string().lowercase().nullable().matches(Constants.REGEXP.EMAIL, {
                    message: "This field must be a valid email",
                    excludeEmptyString: true
                }).required('Required')
            })).min(1, 'Must have at least 1 items'),
        recipientNotice: Yup.object().shape({
            fullName: Yup.string().nullable().required('Required'),
            email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, {
                message: "This field must be a valid email",
                excludeEmptyString: true
            }).nullable().required('Required'),
            address: Yup.object().shape({
                country: Yup.string().nullable().required('Required'),
                street: Yup.string().nullable().required('Required'),
                city: Yup.string().nullable().required('Required'),
                state: Yup.string().nullable().optional(),
                zipCode: Yup.string().nullable().optional()
            })
        })
    }).required('Required'),
    terms: Yup.object().shape({
        shareCapital: Yup.object().shape({
            parValue: Yup.number()
                .required('Required')
                .transform(emptyStringToNull)
                .typeError('Must be a number')
                .nullable(),
            numberOfSIPShares: Yup.number()
                .required('Required')
                .transform(emptyStringToNull)
                .typeError('Must be a number')
                .nullable(),
        }).test('allocatedSharesLimit',(value)=>{
                if(value && value.allocatedShares && value.numberOfSIPShares < value.allocatedShares){
                    return new Yup.ValidationError(
                        `You currently have grants connected to this SIP using ${value.allocatedShares} share options. If you wish to change the number of reserved shares then please update the Grants Agreements first`, // your custom error message
                        null,
                        'terms.shareCapital.numberOfSIPShares')
                }

                return true
        }),
        effectiveDate: Yup.date()
            .transform(emptyStringToNull)
            .typeError('Add a valid date')
            .nullable()
            .required('Required')
    }).required('Required')
});

const validationsRestrictiveCovenantAgreement = Yup.object().shape({
    agreementName: Yup.string().required("Required"),
    company: Yup.object().shape({
        id: Yup.string().nullable().required('Required'),
        name: Yup.string().nullable().required('Required'),
        country: Yup.string().nullable().required('Required'),
        registeredNumber: Yup.string().required('Required'),
        type: Yup.string().required('Required'),
        registeredAddress: Yup.object().shape({
            city: Yup.string().nullable().required('Required'),
            country: Yup.string().nullable().required('Required'),
            zipCode: Yup.string().nullable().optional(),
            street: Yup.string().nullable().required('Required')
        }),
        authorizedSignatories:
            Yup.array().of(Yup.object().shape({
                id: Yup.string().nullable().required('Required'),
                email: Yup.string().lowercase().nullable().matches(Constants.REGEXP.EMAIL, {
                    message: "This field must be a valid email",
                    excludeEmptyString: true
                }).required('Required')
            })).min(1, 'Must have at least 1 items'),
        recipientNotice: Yup.object().shape({
            fullName: Yup.string().nullable().required('Required'),
            email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, {
                message: "This field must be a valid email",
                excludeEmptyString: true
            }).nullable().required('Required'),
            address: Yup.object().shape({
                country: Yup.string().nullable().required('Required'),
                street: Yup.string().nullable().required('Required'),
                city: Yup.string().nullable().required('Required'),
                state: Yup.string().nullable().optional(),
                zipCode: Yup.string().nullable().optional()
            })
        })
    }),
    teamMember: Yup.object().shape({
        id: Yup.string().nullable().required('Required'),
        fullName: Yup.string().required('Required'),
        nationality: Yup.string().nullable().required('Required'),
        address: Yup.object().shape({
            street: Yup.string().nullable().required('Required'),
            city: Yup.string().nullable().required('Required'),
            country: Yup.string().nullable().required('Required'),
            zipCode: Yup.string().nullable().optional(),
        }),
        isAnEntity: Yup.boolean(),
		email: Yup.string().when(['isAnEntity'], {
			is: false,
			then: Yup.string().lowercase()
				.matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
				.nullable().required('Required'),
			otherwise: Yup.string().nullable().optional()
		}),
		passportNumber: Yup.string().when(['isAnEntity'], {
            is: false,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        registeredNumber: Yup.string().when(['isAnEntity'], {
            is: true,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        jurisdiction: Yup.string().when(['isAnEntity'], {
            is: true,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        type: Yup.string().when(['isAnEntity'], {
            is: true,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        authorizedSignatory: Yup.object().when(['isAnEntity'], {
            is: true,
            then: Yup.object().shape({
                fullName: Yup.string().required('Required'),
				email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true }).nullable().required('Required')
					.test('test email', '', function (value) {
						return true
					})
            })
        }),
        recipientNotice: Yup.object().when(['isAnEntity'], {
            is: true,
            then: Yup.object().shape({
                fullName: Yup.string().required('Required'),
                email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, {
                    message: "This field must be a valid email",
                    excludeEmptyString: true
                }).nullable().required('Required'),
                address: Yup.object().shape({
                    country: Yup.string().nullable().required('Required'),
                    street: Yup.string().nullable().required('Required'),
                    city: Yup.string().nullable().required('Required'),
                    state: Yup.string().nullable().optional(),
                    zipCode: Yup.string().nullable().optional()
                })
            })
        })
    }).required('Required'),
    terms: Yup.object().shape({
        restrictedTerritory: Yup.string().nullable().required('Required'),
        placeOfArbitration:Yup.string().nullable().required('Required')
        })
});

const validationsNDAMutual = Yup.object().shape({
    agreementName: Yup.string().required("Required"),
    company: Yup.object().shape({
        id: Yup.string().nullable().required('Required'),
        name: Yup.string().nullable().required('Required'),
        country: Yup.string().nullable().required('Required'),
        registeredNumber: Yup.string().required('Required'),
        type: Yup.string().required('Required'),
        registeredAddress: Yup.object().shape({
            city: Yup.string().nullable().required('Required'),
            country: Yup.string().nullable().required('Required'),
            zipCode: Yup.string().nullable().optional(),
            street: Yup.string().nullable().required('Required')
        }),
        authorizedSignatories:
            Yup.array().of(Yup.object().shape({
                id: Yup.string().nullable().required('Required'),
                email: Yup.string().lowercase().nullable().matches(Constants.REGEXP.EMAIL, {
                    message: "This field must be a valid email",
                    excludeEmptyString: true
                }).required('Required')
            })).min(1, 'Must have at least 1 items'),
        recipientNotice: Yup.object().shape({
            fullName: Yup.string().nullable().required('Required'),
            email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, {
                message: "This field must be a valid email",
                excludeEmptyString: true
            }).nullable().required('Required'),
            address: Yup.object().shape({
                country: Yup.string().nullable().required('Required'),
                street: Yup.string().nullable().required('Required'),
                city: Yup.string().nullable().required('Required'),
                state: Yup.string().nullable().optional(),
                zipCode: Yup.string().nullable().optional()
            })
        })
    }),
    stakeholder: Yup.object().shape({
        id: Yup.string().nullable().required('Required'),
        fullName: Yup.string().required('Required'),
        nationality: Yup.string().nullable().required('Required'),
        address: Yup.object().shape({
            street: Yup.string().nullable().required('Required'),
            city: Yup.string().nullable().required('Required'),
            country: Yup.string().nullable().required('Required'),
            zipCode: Yup.string().nullable().optional(),
        }),
        isAnEntity: Yup.boolean(),
		email: Yup.string().when(['isAnEntity'], {
			is: false,
			then: Yup.string().lowercase()
				.matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
				.nullable().required('Required'),
			otherwise: Yup.string().nullable().optional()
		}),
		passportNumber: Yup.string().when(['isAnEntity'], {
            is: false,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        registeredNumber: Yup.string().when(['isAnEntity'], {
            is: true,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        jurisdiction: Yup.string().when(['isAnEntity'], {
            is: true,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        type: Yup.string().when(['isAnEntity'], {
            is: true,
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable()
        }),
        authorizedSignatory: Yup.object().when(['isAnEntity'], {
            is: true,
            then: Yup.object().shape({
                fullName: Yup.string().required('Required'),
				email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true }).nullable().required('Required')
					.test('test email', '', function (value) {
						return true
					})
            })
        }),
        recipientNotice: Yup.object().when(['isAnEntity'], {
            is: true,
            then: Yup.object().shape({
                fullName: Yup.string().required('Required'),
                email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, {
                    message: "This field must be a valid email",
                    excludeEmptyString: true
                }).nullable().required('Required'),
                address: Yup.object().shape({
                    country: Yup.string().nullable().required('Required'),
                    street: Yup.string().nullable().required('Required'),
                    city: Yup.string().nullable().required('Required'),
                    state: Yup.string().nullable().optional(),
                    zipCode: Yup.string().nullable().optional()
                })
            })
        })
    }).required('Required'),
    terms: Yup.object().shape({
        placeOfArbitration:Yup.string().nullable().required('Required'),
        transaction: Yup.string().nullable().required('Required'),
        otherTransaction:Yup.string().when(['transaction'],{
            is:'option_z',
            then:Yup.string().nullable().required('Required'),
            otherwise:Yup.string().nullable().optional()
        }),
        duration:Yup.object().shape({
            year: Yup.number().optional().nullable(),
            month: Yup.number().optional().nullable()
        }).test('one must be completed',
            null, // we'll return error message our self if needed
            (obj) => {
                if (obj.month || obj.year) { // put every checkbox here
                    return true; // everything is fine
                }
                return new Yup.ValidationError(
                    'Must complete the duration', // your custom error message
                    null,
                    'agreementDuration'
                );
            })
    })
});

const validationsTermsFounder = Yup.object().shape({
    transferShares:Yup.object().shape({
        transferSharesGroupCompanies:Yup.boolean().required('Required'),
        companies:Yup.array().when(['transferSharesGroupCompanies'],{
            is:true,
            then:Yup.array().of(
                Yup.object().shape({
                    verified:Yup.boolean().required()
                }),
            ).required('Required'),
            otherwise:Yup.array().nullable().optional()
        })
    }),
    business:Yup.object().shape({
        description:Yup.string().nullable().required('Required')
    }),
    directors: Yup.object().shape({
        amount: Yup.number()
            .min(0, 'Min value 0')
            .max(20,'Max value 20')
            .required('Required')
            .transform(emptyStringToNull)
            .typeError('Must be a number')
            .nullable(),
            hasCastingVoteCP:Yup.boolean().required('Required'),
        founders: Yup.array().of(
            Yup.object().shape({
                amount: Yup.number()
                    .min(0, 'Min value 0')
                    .max(20,'Max value 20')
                    .required('Required')
                    .transform(emptyStringToNull)
                    .typeError('Must be a number')
                    .nullable(),
                id:Yup.string().required()
            })
        ).required('Required'),
        // appointDirectors:Yup.string().nullable().required('At least one must be selected'),
        // minimumPercentage:Yup.string().when(['appointDirectors'], {
        //     is: 'minimumPercentage',
        //     then: Yup.string().nullable().required('Required'),
        //     otherwise:Yup.string().nullable().optional()
        // }),
        // chairPersonHavesVote:Yup.boolean().required('Required')
    }).test('directorsAmount', (value) => {
        // console.log(value);
        if (_.sumBy(value.founders,founder => founder.amount) !== value.amount) {
            return new Yup.ValidationError(
                'The total number of directors must be equal to the sum of the number of directors which each founder can appoint.', // your custom error message
                null,
                'terms.directors.amount')
        } else {
            return true;
        }
    }),
    leadFounder: Yup.object().shape({
        havesLeadFounder: Yup.boolean().required('Required'),
        id: Yup.string().when(['havesLeadFounder'],{
            is:true,
            then:Yup.string().nullable().required('Required'),
            otherwise:Yup.string().nullable().optional()
        }),
        minimumToAppointCP: Yup.number().when(['havesLeadFounder'], {
            is: true,
            then: Yup.number().min(0, 'Min value 0')
                .required('Required')
                .transform(emptyStringToNull)
                .typeError('Must be a number')
                .nullable(),
            otherwise: Yup.number().nullable().optional()
        })
    }),
    meetings: Yup.object().shape({
        quorum: Yup.number()
            .min(0, 'Min value 0')
            .required('Required')
            .transform(emptyStringToNull)
            .typeError('Must be a number')
            .nullable()
    }),
      placeOfArbitration: Yup.string().required("Required")
})
const validationsEditFoundersAgreement =Yup.lazy((value) => {
    if(value?.company?.id === 'groupCompanyNotSelected' )
    return  Yup.object().shape({
    founders:Yup.array().of(notEstablishedCompanyFounderValidation.concat(validationsFounderDetails)).required('Required').nullable().min(2),
    company:Yup.object().shape({
        country: Yup.string().nullable().required('Required'),
        jurisdiction: Yup.string().nullable().required('Required')
    }).nullable(),
    terms:validationsTermsFounder
    }) 
    if((value?.founders || []).every(f=>(f.share || []).some(sh=>sh?.id && !sh.vestingTerms) || (f.share || []).length === 0)) return Yup.object().shape({
        //founders
        founders: Yup.array().of(founderValidationForNoVestingTerms.concat(validationsFounderDetails)).test('isThereVestingTermsValidation',
        null, // we'll return error message our self if needed
        (obj) => {
            if (obj && obj.some(founder=>(founder?.share || []).length > 0)) return true; // everything is fine
            return obj.map((f,index)=>new Yup.ValidationError(
                'Must have one share position with vesting terms', // your custom error message
                null,
                f.share && f.share.length > 0?`founders[${index}].share[0].vestingTerms`:`founders[${index}].share[0]`
            ))
        }),
    })
    return Yup.object().shape({
        founders: Yup.array().of(founderValidation.concat(validationsFounderDetails)).required('Required').nullable().min(2),
        confirmShares:Yup.boolean().when('founders',{
            is:(value)=>(value || []).every(f=>(f?.share || []).some(sh=>sh.vestingSchedule)),
            then:Yup.boolean().nullable().required('Required').oneOf([true]),
            otherwise:Yup.boolean().optional()
        }),
        company:Yup.object().shape({
            authorizedSignatories: Yup.array().of(Yup.object().shape({
                id: Yup.string().nullable().required('Required'),
                email: Yup.string().lowercase().nullable().matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true }).required('Required')
            })).min(1, 'Must have at least 1 items'),
            verified1: Yup.boolean().required(),
            verified2: Yup.boolean().required()
        }).nullable(),
        terms:validationsTermsFounder
    })
}) 
const validationsFoundersAgreement = Yup.object().shape({
    agreementName: Yup.string().required("Required"),
	company: Yup.lazy((value) => {
        if(value && value.id) {
            if (value.id !== "groupCompanyNotSelected") {
                return Yup.object().shape({
					authorizedSignatories: Yup.array().of(Yup.object().shape({
						id: Yup.string().nullable().required('Required'),
						email: Yup.string().lowercase().nullable().matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true }).required('Required')
					})).min(1, 'Must have at least 1 items'),
					verified1: Yup.boolean().required(),
                    verified2: Yup.boolean().required()
                }).nullable()
            }
            return Yup.object().shape({
                country: Yup.string().nullable().required('Required'),
                jurisdiction: Yup.string().nullable().required('Required')
            }).nullable()
        }
        return Yup.object().shape({
            id: Yup.string().required('Required')
        }).nullable()
    }),
	founders: Yup.array().of(
		validationsFounderDetails
	).required('Required').min(2,'Must have at least two founders'),
      terms:validationsTermsFounder
});

const validationsFoundersAgreement2 =Yup.lazy((value)=> {
    if(value?.company?.id === 'groupCompanyNotSelected' )
    return  Yup.object().shape({
    founders:Yup.array().of(notEstablishedCompanyFounderValidation).required('Required').min(2),
    })
    if(value.founders.every(f=>(f.share || []).some(sh=>sh?.id && !sh.vestingTerms) || (f.share || []).length === 0)) return Yup.object().shape({
        //founders
        founders: Yup.array().of(founderValidationForNoVestingTerms).test('isThereVestingTermsValidation',
        null, // we'll return error message our self if needed
        (obj) => {
            if (obj && obj.some(founder=>(founder?.share || []).length > 0)) return true; // everything is fine
            return obj.map((f,index)=>new Yup.ValidationError(
                'Must have one share position with vesting terms', // your custom error message
                null,
                f.share && f.share.length > 0?`founders[${index}].share[0].vestingTerms`:`founders[${index}].share[0]`
            ))
        }),
    }) 
    return Yup.object().shape({
        //founders
        founders: Yup.array().of(founderValidation),
        confirmShares:Yup.boolean().when('founders',{
            is:(value)=>(value || []).every(f=>(f?.share || []).some(sh=>sh.vestingSchedule)),
            then:Yup.boolean().nullable().required('Required').oneOf([true]),
            otherwise:Yup.boolean().optional()
        })
    })
});

const validationsFoundersAgreement3 = Yup.object().shape({
    agreementName: Yup.string().required("Required"),
    company: Yup.lazy((value) => {
        if (value && value.id) {
            if (value.id !== "groupCompanyNotSelected") {
                return Yup.object().shape({
                    name: Yup.string().required('Required'),
                    country: Yup.string().nullable().required('Required'),
                    registeredNumber: Yup.string().required('Required'),
                    type: Yup.string().required('Required'),
                    registeredAddress: Yup.object().shape({
                        city: Yup.string().nullable().required('Required'),
                        country: Yup.string().nullable().required('Required'),
                        zipCode: Yup.string().nullable().optional(),
                        street: Yup.string().nullable().required('Required')
                    }),
                    authorizedSignatories: Yup.array().of(Yup.object().shape({
                        id: Yup.string().nullable().required('Required'),
                        email: Yup.string().lowercase().nullable().matches(Constants.REGEXP.EMAIL, {
                            message: "This field must be a valid email",
                            excludeEmptyString: true
                        }).required('Required')
                    })).min(1, 'Must have at least 1 items'),
                    recipientNotice: Yup.object().shape({
                        fullName: Yup.string().nullable().required('Required'),
                        email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, {
                            message: "This field must be a valid email",
                            excludeEmptyString: true
                        }).nullable().required('Required'),
                        address: Yup.object().shape({
                            country: Yup.string().nullable().required('Required'),
                            street: Yup.string().nullable().required('Required'),
                            city: Yup.string().nullable().required('Required'),
                            state: Yup.string().nullable().optional(),
                            zipCode: Yup.string().nullable().optional()
                        })
                    }),
                    verified1: Yup.boolean().required(),
                    verified2: Yup.boolean().required()
                })
            }
            return Yup.object().shape({
                country: Yup.string().nullable().required('Required'),
                jurisdiction: Yup.string().nullable().required('Required')
            })
        }
        return Yup.object().shape({
            id:Yup.string().required('Required')
        })
    }),
    founders: Yup.array().of(
        Yup.object().shape({
            verified1: Yup.boolean().required(),
            verified2: Yup.boolean().required(),
        })
    ).required('Required'),
    terms:Yup.object().shape({
        transferShares:Yup.object().shape({
            transferSharesGroupCompanies:Yup.boolean('Required').required(),
            companies:Yup.array().when(['transferSharesGroupCompanies'],{
                is:true,
                then:Yup.array().of(
                    Yup.object().shape({
                        id: Yup.string().nullable().required('Required'),
                        name: Yup.string().required('Required'),
                        country: Yup.string().nullable().required('Required'),
                        registeredNumber: Yup.string().required('Required'),
                        type: Yup.string().required('Required'),
                        registeredAddress: Yup.object().shape({
                            city: Yup.string().nullable().required('Required'),
                            country: Yup.string().nullable().required('Required'),
                            zipCode: Yup.string().nullable().optional(),
                            street: Yup.string().nullable().required('Required')
                        })
                    }),
                ).required('Required'),
                otherwise:Yup.array().nullable().optional()
            })
        }),
        business:Yup.object().shape({
            description:Yup.string().nullable().required('Required')
        }),
        directors:Yup.object().shape({
            amount:Yup.number()
                .min(0, 'Min value 0')
                .required('Required')
                .transform(emptyStringToNull)
                .typeError('Must be a number')
                .nullable(),
                hasCastingVoteCP:Yup.boolean().required('Required'),
            founders:Yup.array().of(
                Yup.object().shape({
                    amount:Yup.number()
                        .min(0, 'Min value 0')
                        .required('Required')
                        .transform(emptyStringToNull)
                        .typeError('Must be a number')
                        .nullable(),
                    id:Yup.string().required()
                })
            ).required('Required'),
            // appointDirectors:Yup.string().nullable().required(),
            // minimumPercentage:Yup.string().when(['appointDirectors'], {
            //     is: 'minimumPercentage',
            //     then: Yup.string().nullable().required('Required'),
            //     otherwise:Yup.string().nullable().optional()
            // }),
            // chairPersonHavesVote:Yup.boolean().required('Required')
        }).test('directorsAmount', (value) => {
            if(_.sumBy(value.founders,founder => founder.amount) !== value.amount) {
                return new Yup.ValidationError(
                    'The amount of directors per founder in total must be equal to directors amount', // your custom error message
                    null,
                    'terms.directors.amount')
            } else {
                return true;
            }
        }),
        leadFounder:Yup.object().shape({
            havesLeadFounder:Yup.boolean().required('Required'),
            id:Yup.string().when(['havesLeadFounder'],{
                is:true,
                then:Yup.string().nullable().required('Required'),
                otherwise:Yup.string().nullable().optional()
            }),
            minimumToAppointCP:Yup.number().when(['havesLeadFounder'],{
                is:true,
                then:Yup.number().min(0, 'Min value 0')
                    .required('Required')
                    .transform(emptyStringToNull)
                    .typeError('Must be a number')
                    .nullable(),
                otherwise:Yup.number().nullable().optional()
            })
        }),
        meetings:Yup.object().shape({
            quorum:Yup.number()
                .min(0, 'Min value 0')
                .required('Required')
                .transform(emptyStringToNull)
                .typeError('Must be a number')
                .nullable(),
        })
    })
});

const validationsFoundersAgreementRequestSignature = Yup.object().shape({
	founders: Yup.array().of(
		Yup.object().shape({
			fullName: Yup.string().nullable().required('Required'),
			email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true }).nullable().required('Required')
				.test('test email', '', function (value) {
					return true
				}),
			isAnEntity: Yup.boolean(),
			authorizedSignatory: Yup.object().when(['isAnEntity'], {
				is: true,
				then: Yup.object().shape({
					fullName: Yup.string().required('Required'),
					email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true }).nullable().required('Required')
						.test('test email', '', function (value) {
							return true
						})
				})
			}),
		}).nullable()
	).required('Required').min(2,'Must have at least two founders'),
	company: Yup.object().shape({
		authorizedSignatories: Yup.array().of(Yup.object().shape({
			id: Yup.string().nullable().required('Required'),
			email: Yup.string().lowercase().nullable().matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true }).required('Required')
		})).min(1, 'Must have at least 1 items'),
	}).nullable().optional()
});

const validationsWebsiteTermsOfUse = Yup.object().shape({
    agreementName: Yup.string().nullable().required("Required"),
    company: Yup.object().shape({
        id: Yup.string().nullable().required('Required'),
        name: Yup.string().nullable().required('Required'),
        country: Yup.string().nullable().required('Required'),
        registeredNumber: Yup.string().required('Required'),
        type: Yup.string().required('Required'),
        registeredAddress: Yup.object().shape({
            city: Yup.string().nullable().required('Required'),
            country: Yup.string().nullable().required('Required'),
            zipCode: Yup.string().nullable().optional(),
            street: Yup.string().nullable().required('Required')
        }),
    }),
    terms:Yup.object().shape({
        domainAddress:Yup.string().nullable().required('Required').matches(Constants.REGEXP.DOMAIN,{
            message:'This field must be a valid Domain Address',
            excludeEmptyString: true
        }),
        supportEmail:Yup.string().nullable().matches(Constants.REGEXP.EMAIL, {
            message: "This field must be a valid email",
            excludeEmptyString: true
        }).required('Required'),
        privacyPolicy:Yup.string().nullable().required('Required').matches(Constants.REGEXP.URL, {
            message: "This field must be a valid URL",
            excludeEmptyString: true
        }).required('Required'),
        acceptablePolicy:Yup.string().nullable().matches(Constants.REGEXP.URL, {
            message: "This field must be a valid URL",
            excludeEmptyString: true
        }).required('Required'),
        cookieNotice:Yup.string().nullable().matches(Constants.REGEXP.URL, {
            message: "This field must be a valid URL",
            excludeEmptyString: true
        }).required('Required'),
        conditionsOfSupply:Yup.string().nullable().matches(Constants.REGEXP.URL, {
            message: "This field must be a valid URL",
            excludeEmptyString: true
        })
    })
});

const validationsWebsitePrivacyPolicy = Yup.object().shape({
    agreementName: Yup.string().nullable().required("Required"),
    company: Yup.object().shape({
        id: Yup.string().nullable().required('Required'),
        name: Yup.string().nullable().required('Required'),
        country: Yup.string().nullable().required('Required'),
        registeredNumber: Yup.string().required('Required'),
        type: Yup.string().required('Required'),
        registeredAddress: Yup.object().shape({
            city: Yup.string().nullable().required('Required'),
            country: Yup.string().nullable().required('Required'),
            zipCode: Yup.string().nullable().optional(),
            street: Yup.string().nullable().required('Required')
        }),
    }),
    terms:Yup.object().shape({
        domainAddress:Yup.string().nullable().required('Required').matches(Constants.REGEXP.DOMAIN,{
            message:'This field must be a valid Domain Address',
            excludeEmptyString: true
        }),
        supportEmail:Yup.string().nullable().matches(Constants.REGEXP.EMAIL, {
            message: "This field must be a valid email",
            excludeEmptyString: true
        }).required('Required'),
        cookieNotice:Yup.string().nullable().matches(Constants.REGEXP.URL, {
            message: "This field must be a valid URL",
            excludeEmptyString: true
        }).required('Required'),
    })
});

const validationsWebsiteAcceptableUsePolicy = Yup.object().shape({
    agreementName: Yup.string().nullable().required("Required"),
    company: Yup.object().shape({
        id: Yup.string().nullable().required('Required'),
        name: Yup.string().nullable().required('Required'),
        country: Yup.string().nullable().required('Required'),
        registeredNumber: Yup.string().required('Required'),
        type: Yup.string().required('Required'),
        registeredAddress: Yup.object().shape({
            city: Yup.string().nullable().required('Required'),
            country: Yup.string().nullable().required('Required'),
            zipCode: Yup.string().nullable().optional(),
            street: Yup.string().nullable().required('Required')
        }),
    }),
    terms:Yup.object().shape({
        domainAddress:Yup.string().nullable().required('Required').matches(Constants.REGEXP.DOMAIN,{
            message:'This field must be a valid Domain Address',
            excludeEmptyString: true
        }),
        supportEmail:Yup.string().nullable().matches(Constants.REGEXP.EMAIL, {
            message: "This field must be a valid email",
            excludeEmptyString: true
        }).required('Required'),
        interactiveFeatures:Yup.array().of(
            Yup.object().shape({
                text:Yup.string().required('Required')
            })
        ).required('Required'),
        termsAndConditions:Yup.string().nullable().matches(Constants.REGEXP.URL, {
            message: "This field must be a valid URL",
            excludeEmptyString: true
        }).required('Required'),
    })
});

const validationsWebsiteCookieNotice = Yup.object().shape({
    agreementName: Yup.string().nullable().required("Required"),
    company: Yup.object().shape({
        id: Yup.string().nullable().required('Required'),
        name: Yup.string().nullable().required('Required'),
        country: Yup.string().nullable().required('Required'),
        registeredNumber: Yup.string().required('Required'),
        type: Yup.string().required('Required'),
        registeredAddress: Yup.object().shape({
            city: Yup.string().nullable().required('Required'),
            country: Yup.string().nullable().required('Required'),
            zipCode: Yup.string().nullable().optional(),
            street: Yup.string().nullable().required('Required')
        }),
    }),
    terms:Yup.object().shape({
        domainAddress:Yup.string().nullable().required('Required').matches(Constants.REGEXP.DOMAIN,{
            message:'This field must be a valid Domain Address',
            excludeEmptyString: true
        }),
        supportEmail:Yup.string().nullable().matches(Constants.REGEXP.EMAIL, {
            message: "This field must be a valid email",
            excludeEmptyString: true
        }).required('Required'),
        cookies:Yup.array().of(
            Yup.object().shape({
                name:Yup.string().nullable().required('Required'),
                description:Yup.string().nullable().required('Required'),
                duration:Yup.string().nullable().required('Required'),
                otherDuration:Yup.string().when(['duration'],{
                    is:'option_z',
                    then:Yup.string().nullable().required('Required'),
                    otherwise:Yup.string().nullable().optional()
                }),
                otherPeriod:Yup.string().when(['duration'],{
                    is:'option_z',
                    then:Yup.string().nullable().required('Required'),
                    otherwise:Yup.string().nullable().optional()
                })
            })
        ),
        thirdPartyCookies:Yup.array().of(
            Yup.object().shape({
                name:Yup.string().nullable().required('Required'),
                description:Yup.string().nullable().required('Required'),
                duration:Yup.string().nullable().required('Required'),
                otherDuration:Yup.string().when(['duration'],{
                    is:'option_z',
                    then:Yup.string().nullable().required('Required'),
                    otherwise:Yup.string().nullable().optional()
                }),
                otherPeriod:Yup.string().when(['duration'],{
                    is:'option_z',
                    then:Yup.string().nullable().required('Required'),
                    otherwise:Yup.string().nullable().optional()
                })
            })
        ),
        functionalCookies:Yup.array().of(
            Yup.object().shape({
                name:Yup.string().nullable().required('Required'),
                description:Yup.string().nullable().required('Required'),
                duration:Yup.string().nullable().required('Required'),
                otherDuration:Yup.string().when(['duration'],{
                    is:'option_z',
                    then:Yup.string().nullable().required('Required'),
                    otherwise:Yup.string().nullable().optional()
                }),
                otherPeriod:Yup.string().when(['duration'],{
                    is:'option_z',
                    then:Yup.string().nullable().required('Required'),
                    otherwise:Yup.string().nullable().optional()
                })
            })
        ),
        thirdPartyPerfCookies:Yup.array().of(
            Yup.object().shape({
                name:Yup.string().nullable().required('Required'),
                description:Yup.string().nullable().required('Required'),
                duration:Yup.string().nullable().required('Required')
            })
        )
    })
});

const validationsBoardResolutionConsentSAFE =  Yup.object().shape({
	documentType: Yup.string().nullable().required('Required'),
	agreementName: Yup.string().required('Required'),
	company: Yup.object().shape({
		id: Yup.string().nullable().required('Required'),
		name: Yup.string().required('Required'),
		country: Yup.string().nullable().required('Required'),
        jurisdiction: Yup.string().nullable().required('Required'),
        authorizedSignatories:
                Yup.array().of(Yup.object().shape({
                    id: Yup.string().nullable().required('Required')
                })).required('Must have at least 1 items')
    }),         
	terms: Yup.object().shape({
        amount: Yup.number()
			.min(0, 'Min value 0')
			.required('Required').typeError('Must be a number'),
		convertibleType: Yup.string().test('convertibleType-test', 'Required', function (value) {
			// this.options.context is setting con Formik validate prop
			const { documentType } = this.options.context;
			return !(documentType === 'BOARD_RESOLUTION_CONVERTIBLE_OTHER' && _.isEmpty(value));
		    }),
	    }).required('Required')
    })

const validationsEquityIncentivePlan =  Yup.object().shape({
        documentType: Yup.string().nullable().required('Required'),
        agreementName: Yup.string().required('Required'),
        stakeholders:Yup.array().of(Yup.object().shape({
            id: Yup.string().nullable().required('Required'),
            fullName:Yup.string().nullable().required('Required'),
            authorizedSignatory: Yup.object().when(['isAnEntity'], {
				is: true,
				then: Yup.object().shape({
					fullName: Yup.string().nullable().required('Required'),
                    title: Yup.string().nullable().required('Required'),
					email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true }).nullable().required('Required')
						.test('test email', '', function (value) {
							return true
						})
				})
			}),
            email: Yup.string().when(['isAnEntity'], {
				is: false,
				then: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true }).nullable().required('Required')
						.test('test email', '', function (value) {
							return true
						}),
                otherwise:Yup.string().nullable().optional()
        
				})
            
        })).required('Must have at least 1 items'),
        company: Yup.object().shape({
            id: Yup.string().nullable().required('Required'),
            name: Yup.string().required('Required'),
            country: Yup.string().nullable().required('Required'),
            jurisdiction: Yup.string().nullable().required('Required'),
			registeredAddress: Yup.object().shape({
				country: Yup.string().nullable().required('Required'),
				street: Yup.string().nullable().required('Required'),
				city: Yup.string().nullable().required('Required'),
				state: Yup.string().nullable().optional(),
				zipCode: Yup.string().nullable().optional()
			}),
			placeOfBusiness: Yup.object().shape({
				state: Yup.string().nullable(),
				city: Yup.string().when(['state'], {
					is: 'Delaware',
					then: Yup.string().optional().nullable(),
					otherwise: Yup.string().nullable().required('Required')
				})
			}).nullable().required('Required'),
			authorizedSignatories: Yup.array().of(Yup.object().shape({
                id: Yup.string().nullable().required('Required'),
                email: Yup.string().lowercase().nullable().matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true }).required('Required')
            })).min(1, 'Must have at least 1 items').required('Required'),
        }),         
        terms: Yup.object().shape({
            equity:Yup.object().shape({
                eipYear:Yup.string()
                .required('Required'),
                sharesAmount: Yup.number()
                    .min(0, 'Min value 0')
                    .required('Required').typeError('Must be a number'),
            }),
            // effectiveDate:Yup.date()
            // .nullable()
            // .required('Required')
            // .transform(emptyStringToNull)
            // .typeError('Add a valid date')
        })
    })

const validationsBRCAgreement =Yup.object().shape({
	documentType: Yup.string().nullable().required('Required'),
	agreementName: Yup.string().required('Required'),
	company: Yup.object().shape({
		id: Yup.string().nullable().required('Required'),
		name: Yup.string().required('Required'),
		country: Yup.string().nullable().required('Required'),
		registeredNumber: Yup.string().required('Required'),
		type: Yup.string().required('Required'),
		registeredAddress: Yup.object().shape({
			city: Yup.string().nullable().required('Required'),
			country: Yup.string().nullable().required('Required'),
			zipCode: Yup.string().nullable().optional(),
			street: Yup.string().nullable().required('Required')
		}),
		authorizedSignatories:
			Yup.array().of(Yup.object().shape({
				id: Yup.string().nullable().required('Required'),
				email: Yup.string().lowercase()
					.matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
					.nullable()
					.required('Required')
			})).min(1, 'Must have at least 1 items')
	}).required('Required'),
	terms: Yup.object().shape({
		authorizedSignatories:
			Yup.array().of(Yup.object().shape({
				id: Yup.string().nullable().required('Required')
			})).required('Must have at least 1 items'),
		stakeholder: Yup.object().shape({
			id: Yup.string().nullable().required('Required'),
			fullName: Yup.string().required('Required'),
		}).required('Required'),
		convertibleType: Yup.string().test('convertibleType-test', 'Required', function (value) {
			// this.options.context is setting con Formik validate prop
			const { documentType } = this.options.context;
			return !(documentType === 'BOARD_RESOLUTION_CONVERTIBLE_OTHER' && _.isEmpty(value));
		}),
		currency: Yup.string().nullable().required('Required'),
		investmentAmount: Yup.number()
			.min(0, 'Min value 0')
			.required('Required').typeError('Must be a number')
	}).required('Required')
});

const validationsEmploymentAgreementUK = validationsEmploymentAgreement.omit(['terms']).concat(
    Yup.object().shape({
        terms: Yup.object().shape({
            positionAndLocation: Yup.object().shape({
                        workingAddress: Yup.object().shape({
                                city: Yup.string().nullable().required('Required'),
                                country: Yup.string().nullable().required('Required'),
                                zipCode: Yup.string().nullable().optional(),
                                street: Yup.string().nullable().required('Required')
                            }),
                }),
            workTime: Yup.object().shape({
                start: Yup.string().nullable().required('Required')
                    .test('format check',
                        null, // we'll return error message our self if needed
                        (obj) => {
                            if (!moment(obj, "HH:mm").isValid()) {
                                return new Yup.ValidationError(
                                    'Must be a valid time', // your custom error message
                                    null,
                                    'terms.workTime.start'
                                )// everything is fine
                            } 
                            return true
                        }),
                finish: Yup.string().nullable().required('Required')
                    .test('format check',
                        null, // we'll return error message our self if needed
                        (obj) => {
                            if (!moment(obj, "HH:mm").isValid()) { // put every checkbox here
                                return new Yup.ValidationError(
                                    'Must be a valid time', // your custom error message
                                    null,
                                    'terms.workTime.finish'
                                )// everything is fine
                            } 
                                return true
                        }),
                isOvertimePermitted: Yup.boolean().nullable().required('Required')
            }),
            commencementAndProbation: Yup.object().shape({
                date:Yup.date()
                .nullable()
                .required('Required')
                .transform(emptyStringToNull)
                .typeError('Add a valid date'),
                probationPeriod: Yup.number()
                    .min(1, 'Min value 1')
                    .max(24, 'Max value 24')
                    .nullable()
                    .required('Required')
                    .transform(emptyStringToNull)
                    .typeError('Must be a number'),
                employerNoticePeriod: Yup.number()
                    .min(1, 'Min value 1')
                    .max(24, 'Max value 24')
                    .nullable()
                    .required('Required')
                    .transform(emptyStringToNull)
                    .typeError('Must be a number')
                ,
                employeeNoticePeriod: Yup.number()
                    .min(1, 'Min value 1')
                    .max(24, 'Max value 24')
                    .nullable()
                    .required('Required')
                    .transform(emptyStringToNull)
                    .typeError('Must be a number')
            }),
            salaryAndBenefits: Yup.object().shape({
                annualSalary: Yup.number().nullable()
                    .required('Required')
                    .transform(emptyStringToNull)
                    .typeError('Must be a number'),
                benefits: Yup.array().of(Yup.string()).nullable(),
                housingAllowance: Yup.number().when(['benefits'], {
                    is: (values) => _.some(values, value => value === 'housingAllowance'),
                    then: Yup.number()
                        .transform(emptyStringToNull)
                        .typeError('Must be a number').required('Required'),
                    otherwise: Yup.number().nullable().optional()
                }),
                transportAllowance: Yup.number().when(['benefits'], {
                    is: (values) => _.some(values, value => value === 'transportAllowance'),
                    then: Yup.number()
                        .transform(emptyStringToNull)
                        .typeError('Must be a number').required('Required'),
                    otherwise: Yup.number().nullable().optional()
                }),
                lifeInsurance: Yup.string().when(['benefits'], {
                    is: (values) => _.some(values, value => value === 'lifeInsurance'),
                    then: Yup.string().required('Required'),
                    otherwise: Yup.string().nullable().optional()
                }),
                medicalCare: Yup.string().when(['benefits'], {
                    is: (values) => _.some(values, value => value === 'medicalCare'),
                    then: Yup.string().nullable().required('Please select an option'),
                    otherwise: Yup.string().nullable().optional()
                })
            }),
            gardenLeave: Yup.object().shape({
                havesGardenLeave: Yup.boolean().required('Required')
            }),
            terminationAndVariation: Yup.object().shape({
                terminationNoticeTime: Yup.number()
                    .min(1, 'Min value 1')
                    .max(7, 'Max value 7')
                    .required('Required')
                    .transform(emptyStringToNull)
                    .typeError('Must be a number')
                    .nullable(),
                withoutNoticeAndPayment: Yup.boolean().required('Required'),
                isPayingContractualNotice: Yup.boolean().when(['withoutNoticeAndPayment'], {
                    is: true,
                    then: Yup.boolean().required('Required'),
                    otherwise: Yup.boolean().nullable().optional()
                }).nullable(),
                variations: Yup.string().nullable().required('Required')
            }),
            annualLeave: Yup.object().shape({
                days: Yup.number()
                    .min(20, 'Min value 20')
                    .max(360, 'Max value 360')
                    .required('Required')
                    .transform(emptyStringToNull)
                    .typeError('Must be a number')
                    .nullable(),
                startDate: Yup.date()
                    .nullable()
                    .required('Required')
                    .transform(emptyStringToNull)
                    .typeError('Add a valid date'),
            })
        })
    })
)

const validationsUsaNDA = Yup.object().shape({
    agreementName: Yup.string().required("Required"),
    company: Yup.object().shape({
        id: Yup.string().nullable().required('Required'),
        name: Yup.string().required('Required'),
        country: Yup.string().nullable().required('Required'),
        placeOfBusiness:Yup.object().shape({
            state:Yup.string().nullable().required('Required'),
            city: Yup.string().when(['state'], {
                is: 'Delaware',
                then: Yup.string().optional().nullable(),
                otherwise: Yup.string().nullable().required('Required')
            })
        }).nullable().required('Required'),
        authorizedSignatories: Yup.array().of(Yup.object().shape({
            id: Yup.string().nullable().required('Required'),
            fullName: Yup.string().nullable().required('Required'),
            title: Yup.string().nullable().required('Required'),
            email: Yup.string().lowercase()
            .matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
            .nullable()
            .required('Required'),
            address: Yup.object().shape({
                country: Yup.string().nullable().required('Required'),
                street: Yup.string().nullable().required('Required'),
                city: Yup.string().nullable().required('Required'),
                state: Yup.string().nullable().optional(),
                zipCode: Yup.string().nullable().optional()
            })
        })).min(1, 'Must select a signatory').required('Required'),
    }).required('Required'),
    stakeholder: Yup.object().shape({
        id: Yup.string().nullable().required('Required'),
        fullName: Yup.string().nullable().required('Required'),
        email: Yup.string().lowercase()
            .matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
            .nullable()
            .required('Required'),
        title: Yup.string().nullable().required('Required'),
        address: Yup.object().shape({
            country: Yup.string().nullable().required('Required'),
            street: Yup.string().nullable().required('Required'),
            city: Yup.string().nullable().required('Required'),
            state: Yup.string().nullable().optional(),
            zipCode: Yup.string().nullable().optional()
        })
    }).required('Required'),
})

//CP-5321: WIP
const validationsUsaCalOfferLetterSalaryEmployee = Yup.object().shape({
    agreementName: Yup.string().required("Required"),
    company: Yup.object().shape({
        id: Yup.string().nullable().required('Required'),
        name: Yup.string().required('Required'),
        country: Yup.string().nullable().required('Required'),
        placeOfBusiness:Yup.object().shape({
            state:Yup.string().nullable().required('Required'),
            city: Yup.string().when(['state'], {
                is: 'California',
                then: Yup.string().nullable().required('Required'),
                otherwise: Yup.string().nullable().required('Required')
            })
        }).nullable().required('Required'),
        authorizedSignatories: Yup.array().of(Yup.object().shape({
            id: Yup.string().nullable().required('Required'),
            fullName: Yup.string().nullable().required('Required'),
            email: Yup.string().lowercase()
                .matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
                .nullable()
                .required('Required'),
            title: Yup.string().nullable().required('Required'),
        })).min(1, 'Must select a signatory').required('Required'),
    }).required('Required'),
    stakeholder: Yup.object().shape({
        id: Yup.string().nullable().required('Required'),
        fullName: Yup.string().nullable().required('Required'),
        email: Yup.string().lowercase()
            .matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
            .nullable()
            .required('Required'),
        address: Yup.object().shape({
            country: Yup.string().nullable().required('Required'),
            street: Yup.string().nullable().required('Required'),
            city: Yup.string().nullable().required('Required'),
            state: Yup.string().nullable().optional(),
            zipCode: Yup.string().nullable().optional()
        })
    }).required('Required'),
    terms: Yup.object().shape({
        previousInventions: Yup.object().shape({
            isTherePreviousInventions: Yup.boolean().nullable().required('Required'),
			description: Yup.string().when(['isTherePreviousInventions'], {
				is: true,
				then: Yup.string().nullable().required('Required'),
				otherwise: Yup.string().nullable().optional()
			}),
		}),
        willHaveShares: Yup.boolean().nullable().required('Required'),
		numberOfShares: Yup.number().when(['willHaveShares'], {
			is: true,
			then: Yup.number()
				.min(0, 'Min value 0')
				.required('Required')
				.transform(emptyStringToNull)
				.typeError('Must be a number')
				.nullable(),
			otherwise: Yup.number().nullable().optional()
		}),
        positions: Yup.string().nullable().required('Required'),
        jobDuties: Yup.string().nullable().required('Required'),
        reportTo:Yup.object().shape({
        isReportingTo: Yup.string().nullable().required('Required'),
            description: Yup.string().when(['isReportingTo'], {
                is: 'supervisor',
                then: Yup.string().nullable().required('Required'),
                otherwise: Yup.string().nullable()
            }),
        }),
        officeLocation: Yup.string().nullable().required('Required'),
        startDate: Yup.date()
            .nullable()
            .required('Required')
            .transform(emptyStringToNull)
            .typeError('Add a valid date'),
        annualSalary: Yup.number()
            .min(0, 'Min value 0')
            // .max(20,'Max value 20')
            .required('Required')
            .transform(emptyStringToNull)
            .typeError('Must be a number')
            .nullable(),
        returnDate: Yup.date()
            .nullable()
            .required('Required')
            .transform(emptyStringToNull)
            .typeError('Add a valid date'),
    }).required('Required'),
});

export const validationsEmployeeInformationInventionsAgreement = Yup.object().shape({
    agreementName: Yup.string().required("Required"),
    company: Yup.object().shape({
        jurisdiction: Yup.string().nullable().required('Required'),
        country: Yup.string().nullable().required('Required'),
        authorizedSignatories:
                Yup.array().of(Yup.object().shape({
                    id: Yup.string().nullable().required('Required'),
                    title:Yup.string().nullable().required('Required'),
                    email: Yup.string().lowercase()
        .matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
        .nullable().required('Required')
                })
                ).min(1, 'Must select a signatory').required('Must have at least 1 items'),
        placeOfBusiness:Yup.object().shape({
            city:Yup.string().nullable().required('Required')
        }).nullable().required('Required')
    }),
    employee: Yup.object().shape({
        fullName:Yup.string().nullable().required('Required'),
        email: Yup.string().lowercase()
        .matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
        .nullable().required('Required')
    }),
    terms: Yup.object().shape({
        previousInventions: Yup.object().shape({
            isTherePreviousInventions: Yup.boolean().nullable().required('Required'),
            description: Yup.string().when(['isTherePreviousInventions'], {
                is: true,
                then: Yup.string().nullable().required('Required'),
                otherwise: Yup.string().nullable().optional()
            }),
        })
    })
})

export const validationsConsultantInformationInventionsAgreement = Yup.object().shape({
    agreementName: Yup.string().required("Required"),
    company: Yup.object().shape({
        jurisdiction: Yup.string().nullable().required('Required'),
        country: Yup.string().nullable().required('Required'),
        authorizedSignatories:
                Yup.array().of(Yup.object().shape({
                    id: Yup.string().nullable().required('Required'),
                    title:Yup.string().nullable().required('Required'),
                    email: Yup.string().lowercase()
        .matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
        .nullable().required('Required')
                })).min(1, 'Must select a signatory').required('Must have at least 1 items'),
        placeOfBusiness:Yup.object().shape({
            state:Yup.string().nullable().required('Required'),
            city: Yup.string().when(['state'], {
                is: 'Delaware',
                then: Yup.string().optional().nullable(),
                otherwise: Yup.string().nullable().required('Required')
            })
        }).nullable().required('Required')
    }),
    consultant: Yup.object().shape({
        fullName:Yup.string().nullable().required('Required'),
        email: Yup.string().lowercase()
        .matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
        .nullable().required('Required')
    }),
    terms: Yup.object().shape({
        previousInventions: Yup.object().shape({
            isTherePreviousInventions: Yup.boolean().nullable().required('Required'),
            description: Yup.string().when(['isTherePreviousInventions'], {
                is: true,
                then: Yup.string().nullable().required('Required'),
                otherwise: Yup.string().nullable().optional()
            }),
        })
    })
})

export const validationsAdvisoryBoardOfLetter = Yup.object().shape({
    agreementName: Yup.string().required("Required"),
    company: Yup.object().shape({
        jurisdiction: Yup.string().nullable().required('Required'),
        country: Yup.string().nullable().required('Required'),
        placeOfBusiness:Yup.object().shape({
            state:Yup.string().nullable().required('Required'),
            city: Yup.string().when(['state'], {
                is: 'Delaware',
                then: Yup.string().optional().nullable(),
                otherwise: Yup.string().nullable().required('Required')
            })
        }).nullable().required('Required'),
        registeredAddress:Yup.object().shape({
            country: Yup.string().nullable().required('Required'),
            street: Yup.string().nullable().required('Required'),
            city: Yup.string().nullable().required('Required'),
            state: Yup.string().nullable().optional(),
            zipCode: Yup.string().nullable().optional()
        }),
        authorizedSignatories:
                Yup.array().of(Yup.object().shape({
                    id: Yup.string().nullable().required('Required'),
                    title:Yup.string().nullable().required('Required'),
                    email: Yup.string().lowercase()
        .matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
        .nullable().required('Required')
                })).min(1, 'Must select a signatory').required('Must have at least 1 items'),
            }),
    advisor: Yup.object().shape({
        fullName:Yup.string().nullable().required('Required'),
        email: Yup.string().lowercase()
        .matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
        .nullable().required('Required')
    }),
    terms: Yup.object().shape({
        previousInventions: Yup.object().shape({
            isTherePreviousInventions:Yup.boolean().nullable().required('Required'),
            description: Yup.string().when(['isTherePreviousInventions'], {
                is: true,
                then: Yup.string().nullable().required('Required'),
                otherwise: Yup.string().nullable().optional()
                }),
            }),
		equity: Yup.object().shape({
                sipYear:Yup.string()
                .required('Required'),
                percentage: Yup.number()
                .min(0, 'Min value 0')
                .max(100, 'Max value 100')
                .required('Required')
                .transform(emptyStringToNull)
                .typeError('Must be a number')
                .nullable(),
            })
        })
    })

export const validationsConsultingAgreement = Yup.object().shape({
	consultant: Yup.lazy((obj) => {
            if(!obj) return Yup.object().shape({
                id: Yup.string().nullable().required('Required'),
            }) 
            if(obj.isAnEntity) return Yup.object().shape({
                id: Yup.string().nullable().required('Required'),
                fullName: Yup.string().nullable().required('Required'),
                authorizedSignatory:Yup.object().shape({
                        fullName: Yup.string().required('Required'),
                        email: Yup.string().lowercase()
                            .matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
                            .nullable().required('Required')
                            .test('test email', '', function (value) {
                                return true
                            })
                        }),
                nationality: Yup.string().nullable().required('Required'),
                jurisdiction:Yup.string().nullable().required('Required'),
                address: Yup.object().shape({
                    country: Yup.string().nullable().required('Required'),
                    street: Yup.string().nullable().required('Required'),
                    city: Yup.string().nullable().required('Required'),
                    state: Yup.string().nullable().optional(),
                    zipCode: Yup.string().nullable().optional()
                }),
                recipientNotice:Yup.object().shape({
                    address: Yup.object().shape({
                        country: Yup.string().nullable().required('Required'),
                        street: Yup.string().nullable().required('Required'),
                        city: Yup.string().nullable().required('Required'),
                        state: Yup.string().nullable().optional(),
                        zipCode: Yup.string().nullable().optional()
                    })
                })
            }) 
                return Yup.object().shape({
            id: Yup.string().nullable().required('Required'),
            fullName: Yup.string().nullable().required('Required'),
            email: Yup.string().lowercase()
                .matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
                .nullable()
                .required('Required'),
            title: Yup.string().nullable().required('Required'),
            address: Yup.object().shape({
                country: Yup.string().nullable().required('Required'),
                street: Yup.string().nullable().required('Required'),
                city: Yup.string().nullable().required('Required'),
                state: Yup.string().nullable().optional(),
                zipCode: Yup.string().nullable().optional()
            })
        }).required('Required')
    }),
	company: Yup.object().shape({
            id: Yup.string().nullable().required('Required'),
            name: Yup.string().required('Required'),
            country: Yup.string().nullable().required('Required'),
            registeredAddress:Yup.object().shape({
                country: Yup.string().nullable().required('Required'),
                street: Yup.string().nullable().required('Required'),
                city: Yup.string().nullable().required('Required'),
                state: Yup.string().nullable().optional(),
                zipCode: Yup.string().nullable().optional()
            }),
            placeOfBusiness:Yup.object().shape({
                state:Yup.string().nullable().required('Required'),
                city: Yup.string().when(['state'], {
                    is: 'Delaware',
                    then: Yup.string().optional().nullable(),
                    otherwise: Yup.string().nullable().required('Required')
                })
            }).nullable().required('Required'),
            recipientNotice:Yup.object().shape({
                address: Yup.object().shape({
                    country: Yup.string().nullable().required('Required'),
                    street: Yup.string().nullable().required('Required'),
                    city: Yup.string().nullable().required('Required'),
                    state: Yup.string().nullable().optional(),
                    zipCode: Yup.string().nullable().optional()
                })
            }),
            authorizedSignatories: Yup.array().of(Yup.object().shape({
                id: Yup.string().nullable().required('Required'),
                fullName: Yup.string().nullable().required('Required'),
                title: Yup.string().nullable().required('Required'),
                email: Yup.string().lowercase()
                .matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
                .nullable()
                .required('Required'),
                
            })).min(1, 'Must select a signatory').required('Must have at least 1 items'),
    }),
    terms: Yup.object().shape({
        services: Yup.string().required('Required'),
        previousInventions: Yup.object().shape({
            isTherePreviousInventions: Yup.boolean().nullable().required('Required'),
            description: Yup.string().when(['isTherePreviousInventions'], {
                is: true,
                then: Yup.string().nullable().required('Required'),
                otherwise: Yup.string().nullable().optional()
			}),
		}),
		compensation: Yup.lazy((obj) => {
			let schema = Yup.object().shape({
				compensationMethod:Yup.array().nullable().required('Required').min(1, 'Must have at least 1 items'),
			})
			if(_.includes(obj.compensationMethod ,'fees')) schema = schema.concat(Yup.object().shape({
				compensationFrequency:Yup.string().nullable().required("Required"),
				amount: Yup.number().when(['compensationFrequency'], {
					is: (value)=>value === 'week' || value === 'month' || value === 'year' ,
					then: Yup.number()
					.nullable()
					.required('Required')
					.transform(emptyStringToNull)
					.typeError('Must be a number'),
					otherwise: Yup.number().nullable().optional()
				})
			}))
			if(_.includes(obj.compensationMethod ,'equity')) schema = schema.concat(Yup.object().shape({
				sipYear:Yup.string()
					.required('Required'),
				percentage: Yup.number().min(0, 'Min value 0')
				.required('Required')
				.transform(emptyStringToNull)
				.typeError('Must be a number')
				.nullable(),
			}))
			return schema
		})
 	})
})

export const validationsBoardApprovingOptionsGrant = Yup.object().shape({
    agreementName:Yup.string().required('Required'),
    company:Yup.object().shape({
        id: Yup.string().nullable().required('Required'),
        authorizedSignatories: Yup.array().of(Yup.object().shape({
            id: Yup.string().nullable().required('Required'),
            fullName: Yup.string().nullable().required('Required'),
            email: Yup.string().lowercase()
                .matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
                .nullable()
                .required('Required')
        })).min(1, 'Must select a director').required('Required')
    }),
    grantees:Yup.array().of(
        Yup.object().shape({
            id: Yup.string().nullable().required('Required'),
            fullName: Yup.string().nullable().required('Required'),
			isAnEntity: Yup.boolean().nullable(),
            email: Yup.string().when(['isAnEntity'], {
				is: true,
				then: Yup.string().nullable().optional(),
				otherwise: Yup.string().lowercase()
					.matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
					.nullable()
					.required('Required')
			}),
			authorizedSignatory: Yup.object().when(['isAnEntity'], {
				is: true,
				then: Yup.object().shape({
					fullName: Yup.string().required('Required'),
					email: Yup.string().lowercase()
						.matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
						.nullable().required('Required')
						.test('test email', '', function (value) {
							return true
						}),
					title: Yup.string().nullable().required('Required')
				})
			}),
			vesting: Yup.object().shape({
                id:Yup.string().nullable().required('Required'),
                isThereCliff: Yup.boolean().required("Required"),
			cliffPeriod: Yup.string().when(['isThereCliff'], {
				is: true,
				then:Yup.string()
                .matches(/^[^0|\D]\d{0,9}?$/,
                    {
                        message: "Must be a positive whole number greater than 0",
                        excludeEmptyString: true
                    })
                .nullable()
                .required('Required'),
				otherwise: Yup.string().nullable().optional()
			}).nullable(),
            vestingStartDate: Yup.date().typeError("Add a valid date").nullable().required('Required'),
            vestingPeriod: Yup.string()
                .matches(/^[^0|\D]\d{0,9}?$/,
                    {
                        message: "Must be a positive whole number greater than 0",
                        excludeEmptyString: true
                    })
                .nullable()
                .required('Required'),
            vestingFrequency: Yup.string().nullable().required('Required')
            }),
            grant: Yup.object().shape({
                sharesNumber: Yup.string()
                .matches(Constants.REGEXP.POSITIVE, {
                    message: "Must be  an integer greater than or equal to 0",
                    excludeEmptyString: true
                })
                .nullable()
                .required('Required')
                .transform(emptyStringToNull)
                .typeError('Must be a number'),
				pricePerShare: Yup.number()
					.nullable()
					.required('Required')
					.transform(emptyStringToNull)
					.typeError('Must be a number'),
				typeOption: Yup.string().nullable().required('Required'),
				havesMoreThan10Percent: Yup.boolean().when(['typeOption'], {
					is: 'ISO',
					then: Yup.boolean().nullable().required('Required'),
					otherwise: Yup.boolean().nullable().optional()
				})
            })    
        })
    ).nullable().min(1, 'Must select a grantee').required('Required'),
    terms: Yup.object().shape({
        equityIncentivePlan: Yup.object().shape({
            id: Yup.string().nullable().required('Required'),
        }),
        A409Valuation: Yup.lazy((obj) => {
            const valuationValidation = Yup.object().shape({
                perShare: Yup.number()
					.min(0,'Must be greater than 0')
					.nullable()
					.required('Required')
					.transform(emptyStringToNull)
					.typeError('Must be a number'),
                valuationFirmName: Yup.string().nullable().required('Required'),
                date: Yup.string()
					.nullable()
                	.required('Required')
            })
            if(obj.isItApproved) return valuationValidation
            if(obj.isItApproved === false && obj.isItByBoardOrFirstApproved) return valuationValidation
            if(obj.isItApproved === false && obj.isItByBoardOrFirstApproved === false) return Yup.object().shape({
                perShare: Yup.number()
					.min(0,'Must be greater than 0')
                	.nullable()
                	.required('Required')
                	.transform(emptyStringToNull)
                	.typeError('Must be a number'),
            })
            return Yup.object().shape({
                isItApproved: Yup.boolean().nullable().required('Required'),
                isItByBoardOrFirstApproved: Yup.boolean().nullable().required('Required'),
            })
        })
    })
})

export const validationsOptionGrantNotice = Yup.object().shape({
    agreementName: Yup.string().required('Required'),
    terms: Yup.object().shape({
        boardApprovingOptionsGrant: Yup.object().shape({
            id:Yup.string().nullable().required('Required'),
        })
    }),
    company: Yup.object().shape({
        id: Yup.string().nullable().required('Required'),
        authorizedSignatories: Yup.array().of(Yup.object().shape({
            id: Yup.string().nullable().required('Required'),
            fullName: Yup.string().nullable().required('Required'),
        })).min(1, 'Must select a director').required('Required'),
        placeOfBusiness:Yup.object().shape({
            state: Yup.string().nullable(),
            city: Yup.string().when(['state'], {
                is: 'Delaware',
                then: Yup.string().optional().nullable(),
                otherwise: Yup.string().nullable().required('Required')
            })
        }).nullable().required('Required'),
        jurisdiction:Yup.string().nullable().required('Required'),
        country: Yup.string().nullable().required('Required'),
            registeredAddress:Yup.object().shape({
                country: Yup.string().nullable().required('Required'),
                street: Yup.string().nullable().required('Required'),
                city: Yup.string().nullable().required('Required'),
                state: Yup.string().nullable().optional(),
                zipCode: Yup.string().nullable().optional()
            }),
    }),
    participant: Yup.object().shape({
            id: Yup.string().nullable().required('Required'),
            vesting:Yup.object().shape({
            id:Yup.string().nullable().required('Required'),
                isThereCliff: Yup.boolean().required("Required"),
			cliffPeriod: Yup.string().when(['isThereCliff'], {
				is: true,
				then:Yup.string()
                .matches(/^[^0|\D]\d{0,9}?$/,
                    {
                        message: "Must be a positive whole number greater than 0",
                        excludeEmptyString: true
                    })
                .nullable()
                .required('Required'),
				otherwise: Yup.string().nullable().optional()
			}).nullable(),
			vestingStartDate: Yup.date()
                .typeError("Add a valid date")
                .nullable()
                .required('Required'),
            vestingPeriod: Yup.string()
                .matches(/^[^0|\D]\d{0,9}?$/,
                    {
                        message: "Must be a positive whole number greater than 0",
                        excludeEmptyString: true
                    })
                .nullable()
                .required('Required'),
            vestingFrequency: Yup.string().nullable().required('Required')
            }),
            grant:Yup.object().shape({
                dateOfExpiration:Yup.date()
                .nullable()
                .required('Required')
                .transform(emptyStringToNull)
                .typeError('Add a valid date'),
                sharesNumber: Yup.string()
                .matches(Constants.REGEXP.POSITIVE, {
                    message: "Must be  an integer greater than or equal to 0",
                    excludeEmptyString: true
                })
                .nullable()
                .required('Required')
                .transform(emptyStringToNull)
                .typeError('Must be a number'),
            pricePerShare: Yup.number()
                .nullable()
                .required('Required')
                .transform(emptyStringToNull)
                .typeError('Must be a number'),
                typeOption:Yup.string().nullable().required('Required'),
                havesMoreThan10Percent: Yup.boolean().when(['typeOption'], {
					is: 'ISO',
					then: Yup.boolean().nullable().required('Required'),
					otherwise: Yup.boolean().nullable().optional()
				})
            })    
        })
})

export {
    validationsEmploymentAgreementUK,
    validationsWebsiteCookieNotice,
	validationsBoardResolutionConsentSAFE,
	validationsEquityIncentivePlan,
    validationsWebsiteAcceptableUsePolicy,
    validationsWebsitePrivacyPolicy,
    validationsWebsiteTermsOfUse,
	validationsFoundersAgreementRequestSignature,
    validationsFoundersAgreement3,
    validationsFoundersAgreement2,
    validationsFoundersAgreement,
    validationsNDAMutual,
    validationsIPAssignment,
    validationsSAFEAgreement,
    validationsCLNAgreement,
    validationsFounderTerms,
    validationsAdvisorAgreement,
    validationsKISSAgreement,
    validationsConsultantAgreement,
    validationsEmploymentAgreement,
    validationsFounderServicesAgreement,
    validationsGrantAgreement,
    validationSharesIncentivePlan,
    validationsRestrictiveCovenantAgreement,
	validationsBRCAgreement,
    validationsEditFoundersAgreement,
    validationsUploadToEdit,
	validationsUploadToEditFile,
    validationsDefault,
    validationsUsaNDA,
    validationsUsaCalOfferLetterSalaryEmployee
};

import useFetch from "./useFetch";
import { useState,useEffect } from "react";
import axios from 'axios'
import download from 'downloadjs'
type OptionsType = {onCompleted?:()=>any | Promise<any>,cancelAutomaticFetch?:boolean,useDowloadFileRoute?:boolean}

const useGetContentfulFile = ( documentType:string, options:OptionsType ) => {
  
    const [loading, setLoading] = useState(true);
    
    const [response,setResponse] = useState(null)

    //TODO: REMOVE THIS ONCE THE MOVEMENT HAS BEEN DONE FROM BACKEND TO USE ONE ROUTE

    const url = options?.useDowloadFileRoute?`/contentful/file/${documentType}`:`/contentful/agreementFile/${documentType}`
    
    const { loading:loadingTemplate, refetch:refetchTemplate, response:responseTemplate} = useFetch({
          url,
          defaultResponse: null,
          onCompleted:options?.onCompleted,
          cancelAutomaticFetch: !documentType || options?.cancelAutomaticFetch
    });
    
    useEffect(()=>{
        if(documentType && !options?.cancelAutomaticFetch && responseTemplate) { 
            setResponse(responseTemplate);
            setLoading(false);
        }  
    }, [documentType,options?.cancelAutomaticFetch,responseTemplate])

    const fetch = async ()=>{

        setLoading(true);

        const data = await refetchTemplate({ url });

        setResponse(data?.response);

        const contentfulRequestResponse = await axios.get(`https:${data?.response?.fields?.file?.url}`,{responseType:'blob'});

        download(contentfulRequestResponse.data, data?.response?.fields?.file?.fileName, data?.response?.fields?.file?.contentType);

        setLoading(false);

    }

    return { fetch, loading, response };
}
export default useGetContentfulFile
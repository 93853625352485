import React, { useState } from "react";
import cls from "./FileUpload.module.scss";
import NoFilesUploaded from "./NoFilesUploaded";
import UploadComponent from "./UploadComponent";
import Previwer from "./Previwer";
import _ from "lodash";
import { DropEvent, useDropzone } from "react-dropzone";
import ClassNames from "classnames";
import Scrollbars from "../../../../../components/generic/Scrollbar/Scrollbar";
import { Constants } from "src/v1/utils/constants.js";

interface FileInputType {
  id: string;
  name: string;
  size: number;
  type: string;
}

interface FileUploadProps {
  [key: string]: any;
  onChange?(file: FileInputType): void;
  value: FileInputType | FileInputType[];
  testUrlFile?: string;
  className?: string;
  /*To limit the amount of files to be uploaded*/
  limit?: number;
}

const FileUpload: React.FC<FileUploadProps> = ({
  onChange,
  value,
  disabled = false,
  limitFile = 1,
  limit,
  getValue = (e) => e,
  testUrlFile,
  className,
  accept = null,
  icons,
}) => {

  const [bufferFiles, setBufferFiles] = useState([]);
  const [filesUploadedSuccess, addFileUploaded] = useState(value);
  const [isEnableUploadFile, setIsEnableUploadFile] = useState(!disabled);
  const [uploadCounter, setUploadCounter] = useState(0);
  const [auxCount, setAuxCount] = useState();

  React.useEffect(() => {
    if (_.isEmpty(bufferFiles) && _.isEmpty(filesUploadedSuccess)) {
      setIsEnableUploadFile(true);
      setAuxCount(0);
      return
    }
    if (!!filesUploadedSuccess.length && uploadCounter === 0) {
      setIsEnableUploadFile(false);
    }
  }, [filesUploadedSuccess, bufferFiles]);


  const handleAddFileUploaded = (file: FileInputType, fileBuffer?: any) => {
    let y;
    addFileUploaded((pre) => {
      const files = _.concat(pre, file);
      y = files.length;
      onChange && onChange(getValue(files));
      return files;
    });
    if (uploadCounter === y && uploadCounter !== 0) {
      setBufferFiles([]);
      setIsEnableUploadFile(false);
      setAuxCount(y);
    } else {
      let t = bufferFiles.length;
      if (auxCount + t === y) {
        setBufferFiles([]);
        setIsEnableUploadFile(false);
        setAuxCount(y);
      } else if (auxCount === undefined) {
        setBufferFiles([]);
        setIsEnableUploadFile(false);
        setAuxCount(y);
      }
    }
  };

  const onDropAccepted = (files: any, event) => {
    const filterFiles = _.filter(files, (file) => {
      const extension = file.name.split(".").pop();
      return Constants.BLOCK_EXTENSIONS.indexOf(extension);
    });
    if (filterFiles.length > 0) {
      setBufferFiles((pre) => {
        const x =_.concat(pre, filterFiles);
        setUploadCounter(x.length);
        return x;
      });
    }
  };

  const deleteFileUploaded = (file) => {
    const pre = _.filter(filesUploadedSuccess, (f) => f.id !== file.id);
    addFileUploaded(pre);
    if (pre.length === 0) {
      setIsEnableUploadFile(true);
    }
    onChange && onChange(getValue(pre));
  };

  const getFilesFromEvent = (event: DropEvent) => {
    const fileList = event.dataTransfer
      ? event.dataTransfer.files
      : event.target.files;
    return _.map(fileList, (file) => {
      return Object.defineProperty(file, "id", {
        value: _.uniqueId("file"),
      });
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    getFilesFromEvent,
    accept
  });

  return (
    <div className={`${cls.FileUpload} ${className}`}>
      <div>
        {!isEnableUploadFile ? (
          <Previwer
            limit={limit}
            files={filesUploadedSuccess || []}
            testFile={testUrlFile}
            handleRemove={deleteFileUploaded}
            uploadMoreFile={() => {
              setIsEnableUploadFile(true)
            }}
          />
        ) : (
          <div
            {...getRootProps()}
            className={ClassNames(cls.containerFileInput, {
              [cls.notFilesUploaded]: !bufferFiles.length,
            })}
          >
            {!bufferFiles.length && <NoFilesUploaded icons={icons}/>}
            <input {...getInputProps()} />
            {bufferFiles.length > 0 && (
              <>
                <Scrollbars>
                  {bufferFiles.map((file: any, index) => {
                    return (
                      <UploadComponent
                        key={index + file.name}
                        file={file}
                        url={
                          testUrlFile
                            ? "https://jsonplaceholder.typicode.com/upload"
                            : ""
                        }
                        addFileUploaded={handleAddFileUploaded}
                      />
                    );
                  })}
                </Scrollbars>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FileUpload;

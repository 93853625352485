import React, { useCallback } from 'react';
import _ from 'lodash';
import classes from './Details.module.scss';
import { Constants } from '../../../../../../v1/utils';
import TextMoney from '../../../../../text/Text/Money';
import TextNumber from '../../../../../text/Text/Number';
import { Date } from '../../../../../../v1/components';
import capitalize from 'lodash/capitalize'
import moment from 'moment'
const Details = ({ data, isIdentityDocumentDetails, ...props }) => {
  const isTrueDocGenVe = (value) => {
    try {
      value.includes("MFNRight");
      return false
    } catch (e) {
      return true
    }

  }
  const getValuation = (params) => {
    return <>
      <div className={classes.containerField}>
        <label>Valuation per share amount:</label>
        <span>{params?.A409Valuation.perShare}</span>
      </div>
      <div className={classes.containerField}>
        <label>Name of valuation firm:</label>
        <span>{params?.A409Valuation.valuationFirmName}</span>
      </div>
      <div className={classes.containerField}>
        <label>Date of valuation:</label>
        <span><Date value={params?.A409Valuation.date} /></span>
      </div>
    </>
  }
  const getTextBoolean = (value) => {
    return value ? "YES" : "NO";
  };

  const getYearsMonths = (agreementDuration) => {
    const years = Number(agreementDuration.year) > 0 ? `${agreementDuration.year} Year(s)` : '';
    const months = Number(agreementDuration.month) > 0 ? `${agreementDuration.month} Month(s)` : '';
    return `${years} ${months}`
  };

  const fields = useCallback(() => {

    const params = _.get(data, 'params.terms', {});

    switch (data.type.code) {
      case "IP_ASSIGNMENT":
        return <div className={classes.containerField}>
          <label>Place of arbitration:</label>
          <span>{params?.placeOfArbitration}</span>
        </div>
      case "SAFE": {

        return (
          <>
            <div className={classes.containerField}>
              <label>Discount:</label>
              <span>{params?.discountRate}%</span>
            </div>

            <div className={classes.containerField}>
              <label>Transaction Currencies:</label>
              <span>{params?.currency}</span>
            </div>

            <div className={classes.containerField}>
              <label>Investment Amount:</label>
              <span><TextMoney value={params?.investmentAmount} params={{ prefix: " " }} /></span>
            </div>

            <div className={classes.containerField}>
              <label>Valuation Cap:</label>
              <span><TextMoney value={params?.valuationAmount} params={{ prefix: " " }} /></span>
            </div>
            <label>Investor rights</label>
            <div className={classes.containerField}>
              <label>Most Favoured Nation Right (“MFN”):</label>
              <span>
                {isTrueDocGenVe(params?.investorRights) ? getTextBoolean(params?.investorRights?.hasMFNRight) : getTextBoolean(params?.investorRights?.includes('MFNRight'))}
              </span>
            </div>
            <div className={classes.containerField}>
              <label>Participation Right:</label>
              <span>{isTrueDocGenVe(params?.investorRights) ? getTextBoolean(params?.investorRights?.hasParticipationRight) : getTextBoolean(params?.investorRights?.includes('participationRight'))}</span>
            </div>
            <div className={classes.containerField}>
              <label>Information Rights:</label>
              <span>{isTrueDocGenVe(params?.investorRights) ? getTextBoolean(params?.investorRights?.hasInformationRights) : getTextBoolean(params?.investorRights?.includes('informationRights'))}</span>
            </div>


            <label>Company’s bank account details</label>

            <div className={classes.containerField}>
              <label>SWIFT/IBAN code:</label>
              <span>{params?.bankInfo?.code}</span>
            </div>

            <div className={classes.containerField}>
              <label>Country of bank account:</label>
              <span>{params?.bankInfo?.country}</span>
            </div>

            <div className={classes.containerField}>
              <label>Bank Name:</label>
              <span>{params?.bankInfo?.bankName}</span>
            </div>

            <div className={classes.containerField}>
              <label>Name of bank account holder:</label>
              <span>{params?.bankInfo?.ownerFullName}</span>
            </div>

            <div className={classes.containerField}>
              <label>Bank account number:</label>
              <span>{params?.bankInfo?.number}</span>
            </div>

            <div className={classes.containerField}>
              <label>Additional banking information:</label>
              <span>{params?.bankInfo?.additionalInfo}</span>
            </div>
            <label>Place of arbitration:</label>
            <div className={classes.containerField}>
              <label>Place of arbitration:</label>
              <span>{params?.placeOfArbitration}</span>
            </div>

          </>
        )
      }
      case "ADVISOR_AGREEMENT": {
        const areasOfAdvice = Constants.LABEL_FIELDS_FORM_GENERATE.ADVISOR_AGREEMENT.areasOfAdvise;
        return (
          <>
            <div className={classes.containerField}>
              <label>Time Commitment Hours:</label>
              <span>{params?.timeCommitment}</span>
            </div>

            <div className={classes.containerField}>
              <label>Time Commitment:</label>
              <span>{Constants.LABEL_FIELDS_FORM_GENERATE.ADVISOR_AGREEMENT.commitmentFrequency[params.timeCommitmentFrequency]}</span>
            </div>

            <label>Areas of advice</label>

            {Object.keys(params?.adviceAreas).map(key => {
              return (
                <div key={key + "a"} className={classes.containerField}>
                  <label> {`${areasOfAdvice[key] || "Other"}`}:</label>
                  <span>{getTextBoolean(params?.adviceAreas[key].id)}</span>
                </div>
              )
            })}

            <label>Other Services</label>

            {/* {Object.keys(params?.otherServices).map(key => {
                            return (
                                <div key={key + "b"} className={classes.containerField}>
                                    <label> {`${Constants.LABEL_FIELDS_FORM_GENERATE.ADVISOR_AGREEMENT.otherServices[key].message}`}</label>
                                    <span>{getTextBoolean(_.get(params,`otherServices.${key}.id`,null))}</span>
                                </div>
                            )
                        })} */}

            <div className={classes.containerField}>
              <label>Does the Company have an Advisory Board?</label>
              <span>{getTextBoolean(params?.meetingAttendance?.isAdvisoryBoard)}</span>
            </div>
            {params?.meetingAttendance?.isAdvisoryBoard ?
              <div className={classes.containerField}>
                <label>Advisory Board meeting frequency:</label>
                <span>{params?.meetingAttendance?.advisoryBoardMeetingFrequency}</span>
              </div> : null
            }
            <div className={classes.containerField}>
              <label>Meeting attendance duration:</label>
              <span>{params?.meetingAttendance?.duration}</span>
            </div>

            <div className={classes.containerField}>
              <label>Meeting attendance frequency:</label>
              <span>{Constants.LABEL_FIELDS_FORM_GENERATE.ADVISOR_AGREEMENT.meetingAttendance[params.meetingAttendance.frequency]}</span>
            </div>

            <div className={classes.containerField}>
              <label>Potential Prospect:</label>
              <span>{getTextBoolean(_.get(params?.meetingAttendance, 'potentialProspect.id', null))}</span>
            </div>

            <div className={classes.containerField}>
              <label>Internal Prospect:</label>
              <span>{getTextBoolean(_.get(params?.meetingAttendance, 'internalProject.id', null))}</span>
            </div>

            <div className={classes.containerField}>
              <label>Strategy Prospect:</label>
              <span>{getTextBoolean(_.get(params?.meetingAttendance, 'strategyMeeting.id', null))}</span>
            </div>

            <label>Compensation</label>

            <div className={classes.containerField}>
              <label>Number of shares:</label>
              <span><TextNumber value={params?.compensation.numberShares} /></span>
            </div>

            <div className={classes.containerField}>
              <label>Percentage ownership:</label>
              <span>{params?.compensation?.percentageOwnership}%</span>
            </div>

            <div className={classes.containerField}>
              <label>Price for Company's repurchase option:</label>
              <span>{Constants.LABEL_FIELDS_FORM_GENERATE.ADVISOR_AGREEMENT.repurchasePriceOption[params?.compensation?.repurchasePrice]}</span>
            </div>

            <div className={classes.containerField}>
              <label>Responsibility for the Advisor's expenses:</label>
              <span>{Constants.LABEL_FIELDS_FORM_GENERATE.ADVISOR_AGREEMENT.advisorExpenses[params?.advisorExpenses]}</span>
            </div>
            <label>Place of arbitration:</label>
            <div className={classes.containerField}>
              <label>Place of arbitration:</label>
              <span>{params?.placeOfArbitration}</span>
            </div>
          </>
        )
      }
      case "CONSULTANT_AGREEMENT": {

        return (
          <>

            <label>Fees</label>

            <div className={classes.containerField}>
              <label> Currency: </label>
              <span>{params?.fees.currency?.currency || params?.fees.currency}</span>
            </div>

            <div className={classes.containerField}>
              <label>Frequency: </label>
              <span>{Constants.LABEL_FIELDS_FORM_GENERATE.CONSULTANT_AGREEMENT.feesFrequency[params.fees.feesFrequency]}</span>
            </div>

            {(params?.fees.feesFrequency === 'perWeek' || params.fees.feesFrequency === 'perMonth') &&
              <div className={classes.containerField}>
                <label>Amount:</label>
                <span><TextMoney value={params?.fees.amount} params={{ prefix: " " }} /></span>
              </div>
            }
            <label>Place of arbitration:</label>
            <div className={classes.containerField}>
              <label>Place of arbitration:</label>
              <span>{params?.placeOfArbitration}</span>
            </div>
          </>
        )
      }
      case "CONVERTIBLE_NOTE": {
        return (
          <>
            <div className={classes.containerField}>
              <label>Discount:</label>
              <span>{params?.discountRate}%</span>
            </div>

            <div className={classes.containerField}>
              <label>Transaction currencies:</label>
              <span>{params?.currency}</span>
            </div>

            <div className={classes.containerField}>
              <label>Loan amount:</label>
              <span><TextMoney value={params?.loanAmount} params={{ prefix: " " }} /></span>
            </div>

            <label>Interest rate</label>

            <div className={classes.containerField}>
              <label>Apply interest on the Loan Amount?</label>
              <span>{getTextBoolean(params?.applyInterestRate)}</span>
            </div>

            <HiddenField path={'applyInterestRate'} values={params} value={true}>
              <div className={classes.containerField}>
                <label>Percentage: </label>
                <span>{params?.interestRate}%</span>
              </div>
            </HiddenField>

            <label>Maturity date</label>

            <div className={classes.containerField}>
              <label>Maturity date type: </label>
              <span>{Constants.LABEL_FIELDS_FORM_GENERATE.SAFE.maturityDateType[params.maturityDateOption]}</span>
            </div>

            <HiddenField path={'maturityDateOption'} values={params} value={'specificDate'}>
              <div className={classes.containerField}>
                <label>Maturity date:</label>
                <span><Date value={params?.maturityDate} /></span>
              </div>
            </HiddenField>

            <HiddenField path={'maturityDateOption'} values={params} value={'months'}>
              <div className={classes.containerField}>
                <label>Maturity date:</label>
                <span>{params?.maturityMonths} Months</span>
              </div>
            </HiddenField>

            <label>Repayment amount</label>

            <div className={classes.containerField}>
              <label>Valuation cap:</label>
              <span><TextMoney value={params?.valuationAmount} params={{ prefix: " " }} /></span>
            </div>
            <label>Investor rights</label>
            <div className={classes.containerField}>
              <label>Most Favoured Nation Right (“MFN”):</label>
              <span>{isTrueDocGenVe(params?.investorRights) ? getTextBoolean(params?.investorRights?.hasMFNRight) : getTextBoolean(params?.investorRights?.includes('MFNRight'))}</span>
            </div>
            <div className={classes.containerField}>
              <label>Participation Right:</label>
              <span>{isTrueDocGenVe(params?.investorRights) ? getTextBoolean(params?.investorRights?.hasParticipationRight) : getTextBoolean(params?.investorRights?.includes('participationRight'))}</span>
            </div>
            <div className={classes.containerField}>
              <label>Information Rights:</label>
              <span>{isTrueDocGenVe(params?.investorRights) ? getTextBoolean(params?.investorRights?.hasInformationRights) : getTextBoolean(params?.investorRights?.includes('informationRights'))}</span>
            </div>
            <label>Company’s bank account details</label>

            <div className={classes.containerField}>
              <label>SWIFT/IBAN code:</label>
              <span>{params?.bankInfo?.code}</span>
            </div>

            <div className={classes.containerField}>
              <label>Country of bank account:</label>
              <span>{params?.bankInfo?.country}</span>
            </div>

            <div className={classes.containerField}>
              <label>Bank Name:</label>
              <span>{params?.bankInfo?.bankName}</span>
            </div>

            <div className={classes.containerField}>
              <label>Name of bank account holder:</label>
              <span>{params?.bankInfo?.ownerFullName}</span>
            </div>

            <div className={classes.containerField}>
              <label>Bank account number:</label>
              <span>{params?.bankInfo?.number}</span>
            </div>

            <div className={classes.containerField}>
              <label>Additional banking information:</label>
              <span>{params?.bankInfo?.additionalInfo}</span>
            </div>
            <label>Place of arbitration:</label>
            <div className={classes.containerField}>
              <label>Place of arbitration:</label>
              <span>{params?.placeOfArbitration}</span>
            </div>
          </>
        )
      }
      case "EMPLOYMENT_AGREEMENT_ADGM":
      case "EMPLOYMENT_AGREEMENT_DIFC":
      case "EMPLOYMENT_AGREEMENT_UK": {

        const labels = {
          'housingAllowance': 'Housing Allowance',
          'transportAllowance': 'Transport Allowance',
          'discretionaryBonus': 'Discretionary Bonus',
          'lifeInsurance': 'Life Insurance',
          'incomeProtection': 'Income Protection',
          'medicalCare': data.type.code === 'EMPLOYMENT_AGREEMENT_UK' ? 'Include dependants?' : 'Medical Care for family and dependents',
          'bonus': 'Bonus'
        };

        const benefitsFieldType = {
          'housingAllowance': 'money',
          'transportAllowance': 'money',
          'discretionaryBonus': 'boolean',
          'lifeInsurance': 'money',
          'incomeProtection': 'boolean',
          'medicalCare': 'ref',
          'bonus': 'boolean'
        };


        return (
          <>
            <label>Position and Location</label>

            <div className={classes.containerField}>
              <label>Position:</label>
              <span><Field values={params} path={"positionAndLocation.position"} /></span>
            </div>
            <div className={classes.containerField}>
              <label>Address:</label>
            </div>
            <div className={classes.containerField}>
              <label>Country:</label>
              <span><Field values={params} path={'positionAndLocation.workingAddress.country'}
                type={"text"} /></span>
            </div>
            <div className={classes.containerField}>
              <label>Address:</label>
              <span><Field values={params} path={'positionAndLocation.workingAddress.street'}
                type={"text"} /></span>
            </div>
            <div className={classes.containerField}>
              <label>City:</label>
              <span><Field values={params} path={'positionAndLocation.workingAddress.city'}
                type={"text"} /></span>
            </div>
            <div className={classes.containerField}>
              <label>State / Region:</label>
              <span><Field values={params} path={'positionAndLocation.workingAddress.state'}
                type={"text"} /></span>
            </div>
            <div className={classes.containerField}>
              <label>Post Code:</label>
              <span><Field values={params} path={'positionAndLocation.workingAddress.zipCode'}
                type={"text"} /></span>
            </div>

            <div className={classes.containerField}>
              <label>Home Country:</label>
              <span><Field values={params} path={"positionAndLocation.homeCountry"} /></span>
            </div>

            <label>Hours</label>

            <div className={classes.containerField}>
              <label>Start Time:</label>
              <span><Field values={params} path={"workTime.start"} type="time" /></span>
            </div>

            <div className={classes.containerField}>
              <label>Finish Time:</label>
              <span><Field values={params} path={"workTime.finish"} type="time" /></span>
            </div>

            <div className={classes.containerField}>
              <label>Overtime permitted?</label>
              <span><Field values={params} path={"workTime.isOvertimePermitted"} type={"boolean"} /></span>
            </div>

            <label>Commencement and Probation</label>

            <div className={classes.containerField}>
              <label>Employment commencement date:</label>
              <span><Field values={params} path={'commencementAndProbation.date'}
                type={'date'} /></span>
            </div>

            <div className={classes.containerField}>
              <label>Probation Period:</label>
              <span><Field values={params}
                path={"commencementAndProbation.probationPeriod"} /> Months</span>
            </div>

            <div className={classes.containerField}>
              <label>Employer Probation Notice Period:</label>
              <span><Field values={params} path={"commencementAndProbation.employerNoticePeriod"} /> Weeks</span>
            </div>

            <div className={classes.containerField}>
              <label>Employee Probation Notice Period:</label>
              <span><Field values={params} path={"commencementAndProbation.employeeNoticePeriod"} /> Weeks</span>
            </div>

            <label>Salary and Benefits</label>

            <div className={classes.containerField}>
              <label>Annual Salary:</label>
              <span><Field values={params} path={"salaryAndBenefits.annualSalary"} type={'money'} /></span>
            </div>

            <div className={classes.containerField}>
              <label>Base Salary:</label>
              <span><Field values={params} path={"salaryAndBenefits.baseSalary"} type={'money'} /></span>
            </div>

            {_.map(_.get(params, 'salaryAndBenefits.benefits', []), key => {
              return (
                <div key={key} className={classes.containerField}>
                  <label>{`${labels[key]}:`}</label>
                  <span>
                    <Field
                      values={params}
                      path={`salaryAndBenefits.${key}`}
                      defaultValue={"YES"}
                      type={benefitsFieldType[key]}
                      labelsRef={Constants.LABEL_FIELDS_FORM_GENERATE.EMPLOYMENT_AGREEMENT.medicalCare}
                    />
                  </span>
                </div>
              )
            })}

            <label>Annual Leave</label>

            <div className={classes.containerField}>
              <label>{`Annual leave:`}</label>
              <span><Field values={params} path={`annualLeave.days`} /> per Year</span>
            </div>

            <div className={classes.containerField}>
              <label>{`Annual leave start date:`}</label>
              <span><Date value={params?.annualLeave.startDate} params={{ format: 'DD MMMM' }} /></span>
            </div>

            <label>Termination and Variation</label>

            <div className={classes.containerField}>
              <label>{`Termination period:`}</label>
              <span><Field values={params} path={`terminationAndVariation.terminationNoticeTime`} /> Months</span>
            </div>

            <div className={classes.containerField}>
              <label>{`Without notice and payment in lieu?:`}</label>
              <span><Field values={params} path={`terminationAndVariation.withoutNoticeAndPayment`}
                type={'boolean'} /></span>
            </div>

            <HiddenField path={'terminationAndVariation.withoutNoticeAndPayment'} values={params}
              value={true}>
              <div className={classes.containerField}>
                <label>{`Contractual benefits paid in lieu of notice?:`}</label>
                <span><Field values={params} path={`terminationAndVariation.isPayingContractualNotice`}
                  type={'boolean'} /></span>
              </div>
            </HiddenField>

            <div className={classes.containerField}>
              <label>{`Variations to Agreement:`}</label>
              <span>
                <Field
                  values={params}
                  path={`terminationAndVariation.variations`}
                  labelsRef={Constants.LABEL_FIELDS_FORM_GENERATE.EMPLOYMENT_AGREEMENT.variationsToAgreement}
                  type={'ref'}
                />
              </span>
            </div>

            <div className={classes.containerField}>
              <label>{`Garden Leave required?`}</label>
              <span><Field values={params} path={`gardenLeave.havesGardenLeave`} type={"boolean"} /></span>
            </div>
          </>
        )
      }
      case "BOARD_RESOLUTION":
      case "SHARE_INCENTIVE_PLAN": {
        return (
          <>
            <label>Share Capital</label>

            <div className={classes.containerField}>
              <label>Total number of shares allocated to the Share Incentive Plan: </label>
              <span><TextNumber value={params?.shareCapital.numberOfSIPShares} /></span>
            </div>

            <div className={classes.containerField}>
              <label>Currency: </label>
              <span>{params?.shareCapital.currency}</span>
            </div>

            <div className={classes.containerField}>
              <label>Par Value: </label>
              <span>{params?.shareCapital.parValue}</span>
            </div>

            <div className={classes.containerField}>
              <label>Effective Date: </label>
              <span><Field values={params} path={'effectiveDate'} type={'date'} /></span>
            </div>
          </>
        )
      }
      case "GRANT_AGREEMENT": {
        return (
          <>
            <label>Grant</label>

            <div className={classes.containerField}>
              <label>Number of Shares: </label>
              <span><TextNumber value={params?.grant.sharesNumber} /></span>
            </div>

            <div className={classes.containerField}>
              <label>Price per Shares: </label>
              <span>{params?.grant.pricePerShare}</span>
            </div>

            <label>Vesting</label>

            <div className={classes.containerField}>
              <label>Is there a cliff period: </label>
              <span>{getTextBoolean(params?.vesting.isThereCliff)}</span>
            </div>
            {params?.vesting.isThereCliff ?
              <div className={classes.containerField}>
                <label>How long is the cliff period?: </label>
                <span>{`${params?.vesting.cliffPeriod} Months`}</span>
              </div> : null
            }
            <div className={classes.containerField}>
              <label>Vesting Start Date: </label>
              <span><Field values={params} path={'vesting.vestingStartDate'} type={'date'} /></span>
            </div>

            <div className={classes.containerField}>
              <label>Vesting Period: </label>
              <span>{params?.vesting.vestingPeriod} Months</span>
            </div>

            <div className={classes.containerField}>
              <label>Vesting Frequency: </label>
              <span>{`${capitalize(params?.vesting.vestingFrequency)}`}</span>
            </div>
          </>
        )
      }
      case "USA_BOARD_APPROVING_OPTION_GRANT": {

        return (<>
          <label>409A Valuation</label>
          <div className={classes.containerField}>
            <label>Is there board-approved 409A valuation?:</label>
            <span>{getTextBoolean(params?.A409Valuation.isItApproved === 'yes')}</span>
          </div>
          {params?.A409Valuation.isItApproved === 'yes' ?
            getValuation(params) : <>
              <div className={classes.containerField}>
                <label>Is an external valuation firm going to evaluate?:</label>
                <span>{getTextBoolean(params?.A409Valuation.isItByBoardOrFirstApproved === 'yes')}</span>
              </div>
              {params?.A409Valuation.isItByBoardOrFirstApproved ? <>
                {getValuation(params)}
              </> : <>
                <div className={classes.containerField}>
                  <label>Valuation per share amount:</label>
                  <span>{params?.A409Valuation.perShare}</span>
                </div>
              </>
              }
            </>
          }
          <label>Grantees</label>
          {data.params.grantees.map((g, index) => (<div key={index}>
            <div className={classes.containerField}>
              <label>Full name: </label>
              <span>{g.fullName}</span>
            </div>
            <label>Grant</label>
            <div className={classes.containerField}>
              <label>Number of Shares: </label>
              <span><TextNumber value={g.grant.sharesNumber} /></span>
            </div>

            <div className={classes.containerField}>
              <label>Price per Shares: </label>
              <span>{g.grant.pricePerShare}</span>
            </div>
            <div className={classes.containerField}>
              <label>Type of option: </label>
              <span>{g.grant.typeOption}</span>
            </div>
            <div className={classes.containerField}>
              <label>Does this grantee owns more than 10% of votable shares: </label>
              <span>{getTextBoolean(g.grant.havesMoreThan10Percent)}</span>
            </div>
            <label>Vesting</label>

            <div className={classes.containerField}>
              <label>Is there a cliff period: </label>
              <span>{getTextBoolean(g.vesting.cliffPeriod)}</span>
            </div>
            {g.vesting.isThereCliff ?
              <div className={classes.containerField}>
                <label>How long is the cliff period?: </label>
                <span>{`${g.vesting.cliffPeriod} Months`}</span>
              </div> : null
            }
            <div className={classes.containerField}>
              <label>Vesting Start Date: </label>
              <span><Field values={g} path={'vesting.vestingStartDate'} type={'date'} /></span>
            </div>

            <div className={classes.containerField}>
              <label>Vesting Period: </label>
              <span>{g.vesting.vestingPeriod} Months</span>
            </div>

            <div className={classes.containerField}>
              <label>Vesting Frequency: </label>
              <span>{`${capitalize(g.vesting.vestingFrequency)}`}</span>
            </div>
          </div>
          ))
          }
        </>)
      }
      case "USA_OPTION_GRANT_NOTICE":
        return (<>
          <div className={classes.containerField}>
            <label>Paritcipant: </label>
            <span>{data.params.participant.fullName}</span>
          </div>
          <label>Grant</label>
          <div className={classes.containerField}>
            <label>Number of Shares: </label>
            <span><TextNumber value={data.params.participant.grant.sharesNumber} /></span>
          </div>

          <div className={classes.containerField}>
            <label>Price per Shares: </label>
            <span>{data.params.participant.grant.pricePerShare}</span>
          </div>
          <div className={classes.containerField}>
            <label>Type of option: </label>
            <span>{data.params.participant.grant.typeOption}</span>
          </div>
          <div className={classes.containerField}>
            <label>Does this grantee owns more than 10% of votable shares: </label>
            <span>{getTextBoolean(data.params.participant.grant.havesMoreThan10Percent)}</span>
          </div>
          <div className={classes.containerField}>
            <label>Date of expiration: </label>
            <span><Field values={data.params.participant} path={'grant.dateOfExpiration'} type={'date'} /></span>
          </div>

          <label>Vesting</label>

          <div className={classes.containerField}>
            <label>Is there a cliff period: </label>
            <span>{getTextBoolean(data.params.participant.vesting.cliffPeriod)}</span>
          </div>
          {data.params.participant.vesting.isThereCliff ?
            <div className={classes.containerField}>
              <label>How long is the cliff period?: </label>
              <span>{`${data.params.participant.vesting.cliffPeriod} Months`}</span>
            </div> : null
          }
          <div className={classes.containerField}>
            <label>Vesting Start Date: </label>
            <span><Field values={data.params.participant} path={'vesting.vestingStartDate'} type={'date'} /></span>
          </div>

          <div className={classes.containerField}>
            <label>Vesting Period: </label>
            <span>{data.params.participant.vesting.vestingPeriod} Months</span>
          </div>

          <div className={classes.containerField}>
            <label>Vesting Frequency: </label>
            <span>{`${capitalize(data.params.participant.vesting.vestingFrequency)}`}</span>
          </div>

        </>)
      case "POST_MONEY_VALUATION_CAP":
        return <>
          <label>Purchase Amount</label>
          <div className={classes.containerField}>
          <label>Purchase Amount:</label>
            <span><TextMoney value={params?.purchaseAmount} params={{ prefix: "$" }} /></span>
          </div>
           <label>Post-Money Valuation Cap</label>
           <div className={classes.containerField}>
           <label>Post-Money Valuation Cap:</label>
             <span><TextMoney value={params?.valuationCap} params={{ prefix: "$" }} /></span>
           </div>
           <label>Date of SAFE</label>
           <div className={classes.containerField}>
           <label>Date of SAFE:</label>
             <span><Field values={params} path={'dateOfSafe'} type={'date'} /></span>
           </div>
        </>
        case "SAFE_MFN_ONLY_USA":
        case "POST_MONEY_VALUATION_CAP_USA":
          case "POST_MONEY_VALUATION_CAP_SINGAPORE":
          return <>
          <label>Purchase Amount</label>
          <div className={classes.containerField}>
          <label>Purchase Amount:</label>
            <span><TextMoney value={params?.purchaseAmount} params={{ prefix: "$" }} /></span>
          </div>
           <label>Post-Money Valuation Cap</label>
           <div className={classes.containerField}>
           <label>Post-Money Valuation Cap:</label>
             <span><TextMoney value={params?.valuationCap} params={{ prefix: "$" }} /></span>
           </div>
           <label>Date of SAFE</label>
           <div className={classes.containerField}>
           <label>Date of SAFE:</label>
             <span><Field values={params} path={'dateOfSafe'} type={'date'} /></span>
           </div>
        </>
        case "PRO_RATA_SIDE_LETTER_USA":
          case "PRO_RATA_SIDE_LETTER_CAYMAN":
            case "PRO_RATA_SIDE_LETTER_SINGAPORE":
            return <>
             <label>Date of SAFE</label>
             <div className={classes.containerField}>
             <label>Date of SAFE:</label>
               <span><Field values={params} path={'dateOfSafe'} type={'date'} /></span>
             </div>
          </>
         case "SAFE_DISCOUNT_ONLY_USA":
          return <>
          <label>Purchase Amount</label>
          <div className={classes.containerField}>
          <label>Purchase Amount:</label>
            <span><TextMoney value={params?.purchaseAmount} params={{ prefix: "$" }} /></span>
          </div>
           <label>Post-Money Valuation Cap</label>
           <div className={classes.containerField}>
           <label>Post-Money Valuation Cap:</label>
             <span><TextMoney value={params?.valuationCap} params={{ prefix: "$" }} /></span>
           </div>
           <label>Date of SAFE</label>
           <div className={classes.containerField}>
           <label>Date of SAFE:</label>
             <span><Field values={params} path={'dateOfSafe'} type={'date'} /></span>
           </div>
           <div className={classes.containerField}>
              <label>Discount:</label>
              <span>{params?.discountRate}%</span>
            </div>
        </>
      case "FOUNDERS_AGREEMENT": {
        return (
          <>
            <label>Transfer Shares</label>
            <div className={classes.containerField}>
              <label>Are there any companies whose shares need to be transferred to the Holding Company?</label>
              <span><Field values={params} path={'transferShares.transferSharesGroupCompanies'} type={'boolean'} /></span>
            </div>
            {params?.transferShares.transferSharesGroupCompanies &&
              <>
                <span style={{ 'margin': '10px 0' }}>Companies with Transfer Shares</span>
                {params?.transferShares.companies.map((company, index) => {
                  return (
                    <React.Fragment key={`${company} ${index}`}>
                      <span>
                        {company.name}
                      </span>
                      <br />
                    </React.Fragment>
                  )
                })}
              </>
            }
            <label>Business Description</label>
            <span><Field values={params} path={'business.description'} type={'text'} /></span>
            <label>Directors</label>
            <div className={classes.containerField}>
              <label>Number of directors: </label>
              <span><Field values={params} path={'directors.amount'} type={'text'} /></span>
            </div>
            <br />
            <span>How many directors will each founder appoint?</span>
            {_.map(_.get(params, 'directors.founders'), (founder, index) => {
              const user = _.find(data.params.founders, found => founder.id === found.id);
              return (
                <div key={`${founder} ${index}`} className={classes.containerField}>
                  <label>{user.fullName}</label>
                  <span><Field values={founder} path={'amount'} type={'text'} /></span>
                </div>
              )
            })}
            <br />
            <span>Are Founders able to appoint Directors?</span>
            <br />
            {params?.directors.appointDirectors === 'holdsShares' ?
              <span>
                If Founder holds any Shares (i.e no minimum)
              </span>
              :
              <div className={classes.containerField}>
                <label>Minimum percentage shareholding:</label>
                <span><Field values={params} path={'directors.minimumPercentage'} type={'percent'} /></span>
              </div>
            }
            <br />
            <div className={classes.containerField}>
              <label>Will the Chairperson have a casting vote?</label>
              <span><Field values={params} path={'directors.hasCastingVoteCP'} type={'boolean'} /></span>
            </div>
            <label>Lead Founder</label>
            <div className={classes.containerField}>
              <label>Is there a Lead Founder?</label>
              <span><Field values={params} path={'leadFounder.havesLeadFounder'} type={'boolean'} /></span>
            </div>
            <br />
            {params?.leadFounder.havesLeadFounder === true &&
              <>
                <span>{_.find(data.params.founders, ['id', _.get(params, 'leadFounder.id')]).fullName}</span>
                <br />
                <div className={classes.containerField}>
                  <label>Minimum shareholding the Lead Founder must hold to appoint chairperson:</label>
                  <span><Field values={params} path={'leadFounder.minimumToAppointCP'} type={'percent'} /></span>
                </div>
              </>
            }
            <label>Shareholders' Meetings</label>
            <div className={classes.containerField}>
              <label>Quorum</label>
              <span><Field values={params} path={'meetings.quorum'} type={'text'} /></span>
            </div>
            <label>Shareholding</label>
            {_.map(_.get(data, 'params.founders'), (founder) => {
              return _.map(founder.share, sh => (<>
                <label>{founder.fullName}</label>
                <div className={classes.containerField}>
                  <label>{`Issued date:`}</label>
                  <span><Date value={sh.issuedDate} params={{ format: 'DD MMM YYYY' }} /></span>
                </div>
                <div className={classes.containerField}>
                  <label>Share class:</label>
                  <span>{sh.shareClass.name}</span>
                </div>
                <div className={classes.containerField}>
                  <label>Number of shares:</label>
                  <span>{sh.amountOfShares}</span>
                </div>
                <div className={classes.containerField}>
                  <label>Price per share:</label>
                  <span>{sh.pricePerShare}</span>
                </div>
                <div className={classes.containerField}>
                  <label>Are the shares numbered?:</label>
                  <span><Field values={sh} path={'areTheSharesNumbered'} type={'boolean'} /></span>
                </div>
                <HiddenField path={'areTheSharesNumbered'} values={sh} value={true}>
                  <>
                    <div className={classes.containerField}>
                      <label>Starting share number:</label>
                      <span>{sh?.numberedShares?.startingShareNumber}</span>
                    </div>
                    <div className={classes.containerField}>
                      <label>Ending share number:</label>
                      <span>{sh?.numberedShares?.endingShareNumber}</span>
                    </div>
                  </>
                </HiddenField>
                {
                  (sh.vestingTerms?.id && sh.vestingTerms?.id !== 'NONE')
                    ? <>
                      <div className={classes.containerField}>
                        <label>Is there a cliff period: </label>
                        <span>{getTextBoolean(sh?.vestingTerms?.isThereCliff)}</span>
                      </div>
                      {sh.vestingTerms.isThereCliff ?
                        <div className={classes.containerField}>
                          <label>How long is the cliff period?: </label>
                          <span>{`${sh?.vestingTerms?.cliffPeriod} Months`}</span>
                        </div> : null
                      }
                      <div className={classes.containerField}>
                        <label>Vesting Start Date: </label>
                        <span><Field values={sh} path={'vestingTerms.vestingStartDate'} type={'date'} /></span>
                      </div>

                      <div className={classes.containerField}>
                        <label>Vesting Period: </label>
                        <span>{sh?.vestingTerms?.vestingPeriod} Months</span>
                      </div>

                      <div className={classes.containerField}>
                        <label>Vesting Frequency: </label>
                        <span>{`${capitalize(sh?.vestingTerms?.vestingFrequency || '')}`}</span>
                      </div>
                    </> : <div className={classes.containerField}>
                      <label>Vesting Terms: </label>
                      <span>None</span>
                    </div>}                        </>))


            })}
            <label>Place of arbitration:</label>
            <div className={classes.containerField}>
              <label>Place of arbitration:</label>
              <span>{params?.placeOfArbitration}</span>
            </div>
          </>
        )
      }
      case "USA_CAL_EMPLOYEE_INFORMATION_AND_INVENTIONS_AGREEMENT": {
        return <>
          <div className={classes.containerField}>
            <label>Previous inventions:</label>
            <span>{params?.previousInventions.isTherePreviousInventions === 'yes' ? "Yes" : "No"}</span>
          </div>
          <HiddenField path={'previousInventions.isTherePreviousInventions'} values={params} value={'yes'}>
            <div className={classes.containerField}>
              <label>List of employee's previous inventions:</label>
              <span>{params?.previousInventions.description}</span>
            </div>
          </HiddenField>
        </>
      }
      case "USA_STRATEGIC_ADVISORY_BOARD_LETTER": {
        return <>
          <label>Equity:</label>
          <div className={classes.containerField}>
            <label>Company's equity incentive plan year:</label>
            <span>{params?.equity.sipYear}</span>
          </div>
          <div className={classes.containerField}>
            <label>Option percentage:</label>
            <span>{params?.equity.percentage}</span>
          </div>
          <label>Previous inventions:</label>
          <div className={classes.containerField}>
            <label>Previous inventions:</label>
            <span>{params?.previousInventions.isTherePreviousInventions === 'yes' ? "Yes" : "No"}</span>
          </div>
          <HiddenField path={'previousInventions.isTherePreviousInventions'} values={params} value={'yes'}>
            <div className={classes.containerField}>
              <label>List of employee's previous inventions:</label>
              <span>{params?.previousInventions.description}</span>
            </div>
          </HiddenField>
        </>
      }
      case "USA_CONSULTING_AGREEMENT": {
        return <>
          <label>Services</label>
          <div className={classes.containerField}>
            <label>List of services:</label>
            <span>{params?.services}</span>
          </div>
          <label>Compensation:</label>
          <div className={classes.containerField}>
            <label>Compensation:</label>
          </div>
          {_.map(params?.compensation.compensationMethod, (method, index) => (
            <div key={index} className={classes.containerField}>
              <li>{method}</li>
            </div>)
          )
          }
          {_.includes(params?.compensation.compensationMethod, 'equity') && <>
            <div className={classes.containerField}>
              <label>Company's equity incentive plan year:</label>
              <span>{params?.compensation.sipYear}</span>
            </div>
            <div className={classes.containerField}>
              <label>Option percentage:</label>
              <span>{params?.compensation.percentage}</span>
            </div>
          </>}
          {_.includes(params?.compensation.compensationMethod, 'fees') && <>
            <div className={classes.containerField}>
              <label>Frequency: </label>
              <span>{_.upperFirst(params?.compensation.compensationFrequency)}</span>
            </div>

            <div className={classes.containerField}>
              <label>Amount:</label>
              <span><TextMoney value={params?.compensation.amount} params={{ prefix: "$" }} /></span>
            </div>
          </>}
          <label>Previous inventions:</label>
          <div className={classes.containerField}>
            <label>Previous inventions:</label>
            <span>{params?.previousInventions.isTherePreviousInventions === 'yes' ? "Yes" : "No"}</span>
          </div>
          <HiddenField path={'previousInventions.isTherePreviousInventions'} values={params} value={'yes'}>
            <div className={classes.containerField}>
              <label>List of employee's previous inventions:</label>
              <span>{params?.previousInventions.description}</span>
            </div>
          </HiddenField>
        </>
      }
      case "USA_CONSULTANT_INFORMATION_AND_INVENTIONS_AGREEMENT": {
        return <>
          <label>Previous inventions</label>
          <div className={classes.containerField}>
            <label>Previous inventions:</label>
            <span>{params?.previousInventions.isTherePreviousInventions === 'yes' ? "Yes" : "No"}</span>
          </div>
          <HiddenField path={'previousInventions.isTherePreviousInventions'} values={params} value={'yes'}>
            <div className={classes.containerField}>
              <label>List of employee's previous inventions:</label>
              <span>{params?.previousInventions.description}</span>
            </div>
          </HiddenField>
        </>
      }
      case "FOUNDER_SERVICES_AGREEMENT": {
        return (
          <>

            <label>Services</label>

            <div className={classes.containerField}>
              <label>Commitment: </label>
              <span>{`${Constants.LABEL_FIELDS_FORM_GENERATE.FOUNDER_SERVICES_AGREEMENT.servicesCommitment[params.services.commitment]}`}</span>
            </div>

            {params?.services.commitment !== 'commitmentFull' &&
              <div className={classes.containerField}>
                <label> </label>
                <span>{`${params?.services.otherCommitment === 'otherHours' ? params.services.otherHours : params.services.otherCommitment} hours per week`}</span>
              </div>
            }

            <label>Location</label>
            <div className={classes.containerField}>
              <label>Country:</label>
              <span>{params?.location.workingAddress.country}</span>
            </div>
            <div className={classes.containerField}>
              <label>City:</label>
              <span>{params?.location.workingAddress.city}</span>
            </div>

            <label>Compensation</label>

            <div className={classes.containerField}>
              <label>Currency: </label>
              <span>{params?.compensation.currency}</span>
            </div>

            <div className={classes.containerField}>
              <label>Salary per year: </label>
              <span><TextMoney value={params?.compensation.salary} params={{ prefix: " " }} /></span>
            </div>

            <label>Founder Role</label>
            <div className={classes.containerField}>
              <label>Role: </label>
              <span>{data.params.founder.position.name}</span>
            </div>

            <div className={classes.containerField}>
              <label>Responsibilities:</label>
            </div>
            {_.map(data.params.founder.position.responsibilities, (responsibility, index) => (
              <div key={index} className={classes.containerField}>
                <li>{responsibility.text}</li>
              </div>)
            )
            }
            {data.params.founder.position.code !== 'CEO' ? (<>
              <div className={classes.containerField}>
                <label>Report to:</label>
                <span>{`${Constants.LABEL_FIELDS_FORM_GENERATE.FOUNDER_SERVICES_AGREEMENT.reportTo[data.params.founder.position.reports]}`}</span>
              </div>
            </>) : null
            }
            <label>Vesting:</label>
            <div className={classes.containerField}>
              <label>Are the founder's shares subject to vesting?:</label>
              <span><Field values={data} path={'params.founder.isThereVesting'} type={'boolean'} /></span>
            </div>
            <HiddenField path={'isThereVesting'} values={data.params.founder} value={true}>
              <>
                {_.map(data.params.founder.share, sh => (<>
                  <div className={classes.containerField}>
                    <label>{`Issued date:`}</label>
                    <span><Date value={sh.issuedDate} params={{ format: 'DD MMM YYYY' }} /></span>
                  </div>
                  <div className={classes.containerField}>
                    <label>Share class:</label>
                    <span>{sh.shareClass.name}</span>
                  </div>
                  <div className={classes.containerField}>
                    <label>Number of shares:</label>
                    <span>{sh.amountOfShares}</span>
                  </div>
                  <div className={classes.containerField}>
                    <label>Price per share:</label>
                    <span>{sh.pricePerShare}</span>
                  </div>
                  <div className={classes.containerField}>
                    <label>Are the shares numbered?:</label>
                    <span><Field values={sh} path={'areTheSharesNumbered'} type={'boolean'} /></span>
                  </div>
                  <HiddenField path={'areTheSharesNumbered'} values={sh} value={true}>
                    <>
                      <div className={classes.containerField}>
                        <label>Starting share number:</label>
                        <span>{sh?.numberedShares?.startingShareNumber}</span>
                      </div>
                      <div className={classes.containerField}>
                        <label>Ending share number:</label>
                        <span>{sh?.numberedShares?.endingShareNumber}</span>
                      </div>
                    </>
                  </HiddenField>
                  <div className={classes.containerField}>
                    <label>Is there a cliff period: </label>
                    <span>{getTextBoolean(sh?.vestingTerms?.isThereCliff)}</span>
                  </div>
                  {sh.vestingTerms.isThereCliff ?
                    <div className={classes.containerField}>
                      <label>How long is the cliff period?: </label>
                      <span>{`${sh?.vestingTerms?.cliffPeriod} Months`}</span>
                    </div> : null
                  }
                  <div className={classes.containerField}>
                    <label>Vesting Start Date: </label>
                    <span><Field values={sh} path={'vestingTerms.vestingStartDate'} type={'date'} /></span>
                  </div>

                  <div className={classes.containerField}>
                    <label>Vesting Period: </label>
                    <span>{sh?.vestingTerms?.vestingPeriod} Months</span>
                  </div>

                  <div className={classes.containerField}>
                    <label>Vesting Frequency: </label>
                    <span>{`${capitalize(sh?.vestingTerms?.vestingFrequency || '')}`}</span>
                  </div>
                </>))
                }
              </>
            </HiddenField>
            <label>Place of arbitration:</label>
            <div className={classes.containerField}>
              <label>Place of arbitration:</label>
              <span>{params?.placeOfArbitration}</span>
            </div>
          </>
        )
      }
      case "RESTRICTIVE_COVENANT_AGREEMENT": {
        return (
          <>

            <div className={classes.containerField}>
              <label>Restricted Territory:</label>
              <span>{params?.restrictedTerritory}</span>
            </div>
            <div className={classes.containerField}>
              <label>Place of arbitration:</label>
              <span>{params?.placeOfArbitration}</span>
            </div>
          </>
        )
      }
      case "NDA_MUTUAL":
        return (<>
          <div className={classes.containerField}>
            <label>Transaction:</label>
            <span>{params?.transaction === 'option_z' ? params.otherTransaction : `${Constants.LABEL_FIELDS_FORM_GENERATE.NDA_MUTUAL.transaction[params.transaction]}`}</span>
          </div>
          <div className={classes.containerField}>
            <label>Duration:</label>
            <span>{`${params?.duration.year ? params.duration.year : 0} Years ${params?.duration.month ? params.duration.month : 0} Months`}</span>
          </div>
          <div className={classes.containerField}>
            <label>Place of arbitration:</label>
            <span>{params?.placeOfArbitration}</span>
          </div>
        </>);
      case "WEBSITE_TERMS_OF_USE":
        return (<>
          <div className={classes.containerField}>
            <label>Domain Address:</label>
            <span>{params?.domainAddress}</span>
          </div>
          <div className={classes.containerField}>
            <label>Support Email:</label>
            <span>{params?.supportEmail}</span>
          </div>
          <div className={classes.containerField}>
            <label>Privacy Policy URL:</label>
            <span>{params?.privacyPolicy}</span>
          </div>
          <div className={classes.containerField}>
            <label>Acceptable Use Policy URL:</label>
            <span>{params?.acceptablePolicy}</span>
          </div>
          <div className={classes.containerField}>
            <label>Cookie Notice URL:</label>
            <span>{params?.cookieNotice}</span>
          </div>
          {!!_.size(params?.conditionsOfSupply) ?
            <div className={classes.containerField}>
              <label>Terms of Business URL:</label>
              <span>{params?.conditionsOfSupply}</span>
            </div> : null
          }
        </>)
      case "WEBSITE_PRIVACY_POLICY":
        return (<>
          <div className={classes.containerField}>
            <label>Domain Address:</label>
            <span>{params?.domainAddress}</span>
          </div>
          <div className={classes.containerField}>
            <label>Support Email:</label>
            <span>{params?.supportEmail}</span>
          </div>
          <div className={classes.containerField}>
            <label>Cookie Notice URL:</label>
            <span>{params?.cookieNotice}</span>
          </div>
        </>)
      case "WEBSITE_ACCEPTABLE_USE_POLICY":
        return (<>
          <div className={classes.containerField}>
            <label>Domain Address:</label>
            <span>{params?.domainAddress}</span>
          </div>
          <div className={classes.containerField}>
            <label>Support Email:</label>
            <span>{params?.supportEmail}</span>
          </div>
          <div className={classes.containerField}>
            <label>Terms And Conditions URL:</label>
            <span>{params?.termsAndConditions}</span>
          </div>
          <div className={classes.containerField}>
            <label>Interactive Features:</label>
          </div>

          {_.map(params?.interactiveFeatures, (feature, index) => (
            <div key={index} className={classes.containerField}>
              <li>{feature.text}</li>
            </div>)
          )
          }
        </>)
      case "WEBSITE_COOKIE_NOTICE":
        return (<>
          <div className={classes.containerField}>
            <label>Domain Address:</label>
            <span>{params?.domainAddress}</span>
          </div>
          <div className={classes.containerField}>
            <label>Support Email:</label>
            <span>{params?.supportEmail}</span>
          </div>
          {!_.isEmpty(params?.cookies) ? (<>
            <div className={classes.containerField}>
              <label>Essential Cookies:</label>
            </div>

            {_.map(params?.cookies, (cookie, index) => (
              <div key={index} className={classes.containerField}>
                <li>
                  <span>Name: {cookie.name}</span><br />
                  <span>Description: {cookie.description}</span><br />
                  <span>Duration: {cookie.duration !== 'option_z' ? Constants.LABEL_FIELDS_FORM_GENERATE.WEBSITE_COOKIE_NOTICE.cookies[cookie.duration] : `For ${cookie.otherDuration} ${cookie.otherPeriod}`}</span>
                </li>
              </div>)
            )
            }</>) : null
          }
          {!_.isEmpty(params?.thirdPartyCookies) ? (<>
            <div className={classes.containerField}>
              <label>Essential Third Parties Cookies:</label>
            </div>

            {_.map(params?.thirdPartyCookies, (cookie, index) => (
              <div key={index} className={classes.containerField}>
                <li>
                  <span>Name: {cookie.name}</span><br />
                  <span>Description: {cookie.description}</span><br />
                  <span>Duration: {cookie.duration !== 'option_z' ? Constants.LABEL_FIELDS_FORM_GENERATE.WEBSITE_COOKIE_NOTICE.cookies[cookie.duration] : `For ${cookie.otherDuration} ${cookie.otherPeriod}`}</span>
                </li>
              </div>)
            )
            }</>) : null
          }
          {!_.isEmpty(params?.functionalCookies) ? (<>
            <div className={classes.containerField}>
              <label>Functional Cookies:</label>
            </div>

            {_.map(params?.functionalCookies, (cookie, index) => (
              <div key={index} className={classes.containerField}>
                <li>
                  <span>Name: {cookie.name}</span><br />
                  <span>Description: {cookie.description}</span><br />
                  <span>Duration: {cookie.duration !== 'option_z' ? Constants.LABEL_FIELDS_FORM_GENERATE.WEBSITE_COOKIE_NOTICE.functional[cookie.duration] : `For ${cookie.otherDuration} ${cookie.otherPeriod}`}</span>
                </li>
              </div>)
            )
            }</>) : null
          }
          {!_.isEmpty(params?.functionalCookies) ? (<>
            <div className={classes.containerField}>
              <label>Third Parties Performance Cookies:</label>
            </div>

            {_.map(params?.thirdPartyPerfCookies, (cookie, index) => (
              <div key={index} className={classes.containerField}>
                <li>
                  <span>Name: {cookie.name}</span><br />
                  <span>Description: {cookie.description}</span><br />
                  <span>Duration: {cookie.duration !== 'option_z' ? Constants.LABEL_FIELDS_FORM_GENERATE.WEBSITE_COOKIE_NOTICE.thirdPartyPerformance[cookie.duration] : `For ${cookie.otherDuration} ${cookie.otherPeriod}`}</span>
                </li>
              </div>)
            )
            }</>) : null
          }
        </>)
      case "BOARD_RESOLUTION_CONVERTIBLE_LOAN_NOTE":
      case "BOARD_RESOLUTION_CONVERTIBLE_SAFE":
      case "BOARD_RESOLUTION_CONVERTIBLE_KISS":
      case "BOARD_RESOLUTION_CONVERTIBLE_FIXED_EQUITY":
      case "BOARD_RESOLUTION_CONVERTIBLE_OTHER":

        return (
          <>
            <div className={classes.containerField}>
              <label>Authorised Signatories:</label>
            </div>
            {_.map(params?.authorizedSignatories, (as, index) => (
              <div key={index} className={classes.containerField}>
                <li>{as.fullName}</li>
              </div>)
            )
            }

            <div className={classes.containerField}>
              <label>Investor Name:</label>
              <span>{params?.stakeholder.fullName}</span>
            </div>

            <HiddenField path={'params.documentType'} values={data} value={'BOARD_RESOLUTION_CONVERTIBLE_OTHER'}>
              <div className={classes.containerField}>
                <label>Convertible Type: </label>
                <span>{params?.convertibleType}</span>
              </div>
            </HiddenField>

            <div className={classes.containerField}>
              <label>Currency:</label>
              <span>{params?.currency}</span>
            </div>

            <div className={classes.containerField}>
              <label>Amount:</label>
              <span><TextMoney value={params?.investmentAmount} params={{ prefix: " " }} /></span>
            </div>

          </>
        )
      case "USA_BOARD_CONSENT_SAFE_CONVERTIBLE_LOAN_NOTE":
      case "USA_BOARD_CONSENT_SAFE_SAFE":
      case "USA_BOARD_CONSENT_SAFE_KISS":
      case "USA_BOARD_CONSENT_SAFE_FIXED_EQUITY":
      case "USA_BOARD_CONSENT_SAFE_OTHER":
      case "USA_BOARD_CONSENT_SAFE":

        return (
          <>
            <HiddenField path={'params.documentType'} values={data} value={'BOARD_RESOLUTION_CONVERTIBLE_OTHER'}>
              <div className={classes.containerField}>
                <label>Convertible Type: </label>
                <span>{params?.convertibleType}</span>
              </div>
            </HiddenField>

            <div className={classes.containerField}>
              <label>Amount: </label>
              <span>{params?.amount}</span>
            </div>
          </>
        )
      case "THIRDPARTY_ADDRESS_PASSPORT_DOCUMENT":
      case "PASSPORT_DOCUMENT":
      case "OLDPASSPORT_DOCUMENT":
        return (<>
          <div className={classes.containerField}>
            <label>Nationality:</label>
            <span>{data?.identityDocument?.nationality ?? "Expired"}</span>
          </div>
          <div className={classes.containerField}>
            <label>Passport No:</label>
            <span>{data?.identityDocument?.number ?? "Expired"}</span>
          </div>
          <div className={classes.containerField}>
            <label>Passport expiry:</label>
            {_.isEmpty(data?.identityDocument) ? (
              <span>Expired</span>
            ) : (
              <span><Field values={data?.identityDocument ?? {}} path={'expiryDate'} type={'date'} /></span>
            )}
          </div>
        </>)
      case "UAE_VISA_DOCUMENT":
      case "UAE_VISA_LASTPAGE_DOCUMENT":
      case "GCC_RESIDENT_ID_DOCUMENT":
      case "NATIONAL_ID_DOCUMENT":
      case "UAE_RESIDENCE_VISA_DOCUMENT":
      case "UAE_ENTRY_STAMP_DOCUMENT":
      case "UID_STAMP_DOCUMENT":
      case "EID_DOCUMENT":
      case "EID_LASTPAGE_DOCUMENT":
        return (
          <div className={classes.containerField}>
            <label>Expiry:</label>
            <span><Field values={data?.identityDocument ?? {}} path={'expiryDate'} type={'date'} /></span>
          </div>
        )
      case "USA_EQUITY_INCENTIVE_PLAN":
      case "USA_STAKEHOLDERS_DIRECTORS_EQUITY_INCENTIVE_PLAN":
        return <>
          <label>Shares allocated to the plan:</label>
          <div className={classes.containerField}>
            <label>Amount of shares:</label>
            <span><Field values={params} path={'equity.sharesAmount'} /></span>
          </div>
          <div className={classes.containerField}>
            <label>Company's equity incentive plan year:</label>
            <span>{params?.equity.eipYear}</span>
          </div>
          {/* <label>Effective date:</label>
                <div className={classes.containerField}>
                        <label>Effective date:</label>
                        <span><Field values={params} path={'effectiveDate'} type={'date'}/></span>
                    </div>  */}
        </>
      case "USA_NDA":
        return null
      //CP-5321
      case "USA_CAL_OFFER_LETTER_SALARY_EMPLOYEE":
        return (<>
          <label>Previous inventions</label>
          <div className={classes.containerField}>
            <label>Previous inventions:</label>
            <span>{params?.previousInventions.isTherePreviousInventions === 'yes' ? "Yes" : "No"}</span>
          </div>
          <HiddenField path={'previousInventions.isTherePreviousInventions'} values={params} value={'yes'}>
            <div className={classes.containerField}>
              <label>List of employee's previous inventions:</label>
              <span>{params?.previousInventions.description}</span>
            </div>
          </HiddenField>
          <label>Job details</label>
          <div className={classes.containerField}>
            <label>Position:</label>
            <span>{params?.positions}</span>
          </div>
          <div className={classes.containerField}>
            <label>Job duties:</label>
            <span>{params?.jobDuties}</span>
          </div>
          <div className={classes.containerField}>
            <label>Report to:</label>
            <span>{params?.reportTo.isReportingTo === 'supervisor' ? params.reportTo.description : 'Board of directors'}</span>
          </div>
          <div className={classes.containerField}>
            <label>Office location:</label>
            <span>{params?.officeLocation}</span>
          </div>
          <div className={classes.containerField}>
            <label>Start date:</label>
            <span><Date value={params?.startDate} /></span>
          </div>

          <label>Salary and benefits</label>
          <div className={classes.containerField}>
            <label>Annual salary:</label>
            <span>{params?.annualSalary}</span>
          </div>
          <div className={classes.containerField}>
            <label>Number of shares:</label>
            <span>{params?.numberOfShares}</span>
          </div>
          <div className={classes.containerField}>
            <label>Return date:</label>
            <span><Date value={params?.returnDate} /></span>
          </div>

        </>)
      default:
        break;
    }
  }, [JSON.stringify(data)]);

  return (
    <div className={classes.containerDetails}>
      {fields()}
    </div>
  )
};

export default Details

const HiddenField = ({ values, path, value, children, ...props }) => {

  return (
    <>
      {_.get(values, path) === value && children}
    </>
  )

};

const Field = ({ values, path, defaultValue = "", type = "text", labelsRef, ...props }) => {


  const getTextBoolean = (value) => {
    return value ? "YES" : "NO";
  };
  const getTextTime = (value) => {
    if(moment(value).isValid()) return moment(value).format('HH:mm')
    return value
  }

  return (
    <>
      {type === "text" && _.get(values, path, defaultValue)}
      {type === "boolean" && getTextBoolean(_.get(values, path, defaultValue))}
      {type === "date" && _.get(values, path) && <Date value={_.get(values, path)} />}
      {type === "money" && _.get(values, path) && <TextMoney value={_.get(values, path)} params={{ prefix: " " }} />}
      {type === "ref" && _.get(values, path) && labelsRef[_.get(values, path)]}
      {type === "percent" && `${_.get(values, path, defaultValue)} %`}
      {type === "time" && _.get(values, path, defaultValue) && getTextTime(_.get(values, path, defaultValue))}
    </>
  )

};

const FounderRoleResponsibilities = ({ params }) => {

  let responsibilities = [];
  let responsibilitiesLabels = [];
  let otherRole = false;

  switch (params?.founderRole.role) {
    case 'COO':
      //
      responsibilities = _.sortBy(params?.founderRole.responsibilitiesCOO);
      responsibilitiesLabels = Constants.LABEL_FIELDS_FORM_GENERATE.FOUNDER_SERVICES_AGREEMENT.responsibilitiesCOO;
      break;

    case 'CEO':
      //
      responsibilities = _.sortBy(params?.founderRole.responsibilitiesCEO);
      responsibilitiesLabels = Constants.LABEL_FIELDS_FORM_GENERATE.FOUNDER_SERVICES_AGREEMENT.responsibilitiesCEO;
      break;

    case 'CFO':
      //
      responsibilities = _.sortBy(params?.founderRole.responsibilitiesCFO);
      responsibilitiesLabels = Constants.LABEL_FIELDS_FORM_GENERATE.FOUNDER_SERVICES_AGREEMENT.responsibilitiesCFO;
      break;

    case 'CTO':
      //
      responsibilities = _.sortBy(params?.founderRole.responsibilitiesCTO);
      responsibilitiesLabels = Constants.LABEL_FIELDS_FORM_GENERATE.FOUNDER_SERVICES_AGREEMENT.responsibilitiesCTO;
      break;

    case 'CIO':
      //
      responsibilities = _.sortBy(params?.founderRole.responsibilitiesCIO);
      responsibilitiesLabels = Constants.LABEL_FIELDS_FORM_GENERATE.FOUNDER_SERVICES_AGREEMENT.responsibilitiesCIO;
      break;

    default:
      otherRole = true
    //
  }

  const mapResponsibilities = responsibilities.map(option => {
    if (option === 'option_z') {
      // Other Role description
      return {
        id: option,
        label: params.founderRole.otherResponsibility
      }
    } else {
      // set labels
      return {
        id: option,
        label: responsibilitiesLabels[option]
      }
    }
  });

  return (
    <>
      {!otherRole &&
        <div className={classes.containerField}>
          <label>Responsibilities: </label>
          <ul>
            {mapResponsibilities.map(item => {
              return (
                <li key={item.id}>
                  <span>{item.label}</span>
                </li>
              )
            })}
          </ul>
        </div>
      }
    </>
  )
};

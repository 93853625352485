import { createMachine } from "xstate";

const machine = createMachine({
  id: "EditAuthorisedSignatory",
  initial: "initialData",
  states: {
    initialData: {
      invoke: {
        src: "initialData",
        id: "initialData",
        onDone: [
          {
            target: "ReviewAndConfirmStep",
            actions: ["setContextAfterInvoke"],
          },
        ],
      },
    },
    ReviewAndConfirmStep: {
      on: {
        NEXT: {
          target: "EvaluateNextStep",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    EvaluateNextStep: {
      always: [
        {
          cond: "correct",
          target: "finish",
        },
        {
          cond: "nomineeQuestion",
          target: "ClaraNomineeStep",
        },
      ],
    },
    ClaraNomineeStep: {
      on: {
        NEXT: {
          target: "EvaluateNominee",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
        PREV: {
          target: "ReviewAndConfirmStep",
        },
      },
    },
    EvaluateNominee: {
      always: [
        {
          cond: "useNominee",
          target: "AssignOtherSignatoriesStep",
        },
        {
          cond: "dontUseNominee",
          target: "AssignSignatoryStep",
        },
      ],
    },
    AssignOtherSignatoriesStep: {
      on: {
        NEXT: {
          target: "UAEInfoStep",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
        PREV: {
          target: "ClaraNomineeStep",
        },
      },
    },
    EvaluateSaveSecondary:{
      always:[
        {
          cond: "isSecondaryFlow",
          target: "EvaluateSave",
        },
        {
          cond: "isNotSecondaryFlow",
          target: "EvaluateThirdParty",
        },
      ]
    },
    AssignSignatoryStep: {
      on: {
        NEXT: {
          target: "EvaluateThirdParty",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
        PREV: {
          target: "ClaraNomineeStep",
        },
      },
    },
    EvaluateThirdParty: {
      always: [
        {
          cond: "thirdParty",
          target: "CreateStakeholderStep",
        },
        {
          cond: "notThirdParty",
          target: "EvaluateIsSecondary",
        },
      ],
    },
    EvaluateAddUAEInfo: {
      always: [
        {
          cond: "isSecondarySignor",
          target: "UAEInfoStep",
        },
        {
          cond: "isNotSecondarySignor",
          target: "EvaluateSave",
        },
      ],
    },
    CreateStakeholderStep: {
      on: {
        NEXT: {
          target: "EvaluateIsSecondary",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
        PREV: {
          target: "EvaluateNominee",
        },
      },
    },
    EvaluateIsSecondary:{
      always: [
        {
          cond: "isSecondarySignor",
          target: "UAEInfoStep",
        },
        {
          cond: "isNotSecondarySignor",
          target: "EvaluateHasNoGCCorUEAStakeholders",
        },
      ],
    },
    EvaluateHasNoGCCorUEAStakeholders:{
      always: [
        {
          cond: "hasNotGCCOrUAE",
          target: "IsThereSecondarySignatoryStep",
        },
        {
          cond: "hasNotNotGCCOrUAE",
          target: "EvaluateSave",
        },
      ],
    },
    IsThereSecondarySignatoryStep:{
      on:{
        NEXT:{
        target:'EvaluateIsThereSecondarySignatoryStep',
        actions: ["setContextAfterStep"],

      },
      PREV:{
        target: 'AssignSignatoryStep'
      },
      CANCEL:{
        target:'cancel'
      }}
    },
    EvaluatePreviousFrom:{
      always:[
      {
        cond: "useNominee",
        target: "AssignOtherSignatoriesStep",
      },
      {
        cond: "dontUseNominee",
        target: "ClaraNomineeStep",
      }
    ]
    },
    EvaluateIsThereSecondarySignatoryStep:{
      always: [
        {
          cond: "thereIsSecondary",
          target: "AssignOtherSignatoriesStep",
        },
        {
          cond: "thereIsNotSecondary",
          target: "EvaluateSave",
        },
      ],
    },
    EvaluateThereSecondary:{
      always: [
        {
          cond: "isSecondarySignor",
          target: "UAEInfoStep",
        },
        {
          cond: "isNotSecondarySignor",
          target: "IsThereSecondarySignatoryStep",
        },
      ],
    },
    UAEInfoStep: {
      on: {
        NEXT: {
          target: "EvaluateSave",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
        PREV: {
          target: "EvaluateNominee",
        },
      },
    },
    EvaluateSave:{
      always: [
        {
          cond: "isIncorporated",
          target: "SaveStep",
        },
        {
          cond: "isNotIncorporated",
          target: "SaveFormationStep",
        },
      ],
    },
    SaveFormationStep:{
      invoke: {
        src: "SaveFormationStep",
        id: "SaveFormationStep",
        onDone: [
          {
            target: "finish",
          },
        ],
        onError: [
          {
            target: "cancel",
          },
        ],
      },
    },
    SaveStep: {
      invoke: {
        src: "SaveStep",
        id: "SaveStep",
        onDone: [
          {
            target: "finish",
          },
        ],
        onError: [
          {
            target: "cancel",
          },
        ],
      },
    },
    cancel: {
      entry: "cancelMachine",
      type: "final",
    },
    finish: {
      entry: "stopMachine",
      type: "final",
    },
  },
});

export default machine;

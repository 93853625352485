import React from 'react';
import classes from './Dots.module.scss';

const Dots = ({ loading,className, containerLoadingStyle = {}, children, label, showChildren = false, loadingDataComplete = true }) => {


    return (
        <React.Fragment>
            {loading &&
                 <div className={`${classes["dot-elastic"]} ${className}`}></div>
            }
        </React.Fragment>

    )
}
export default Dots;
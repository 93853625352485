import React from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch} from "react-redux";

import {Text} from '../../components';
import DocumentPreview from "../../../components/generic/Documents/DocumentPreview/DocumentPreview";
import SecondaryButton from "../../../components/generic/Buttons/SecondaryButton/SecondaryButton";
import BlueScreenLayout from "../../../layouts/BlueScreen/BlueScreenLayout";


const ViewFile = ({ loading, fileType, fileIdPre, showClose=true, close, ...props }) => {
    const params = useParams();
    const dispatch = useDispatch();
    const fileId = fileIdPre ? fileIdPre : params.fileId;
    const closeForm = () => {
        if (close) {
            return close();
        }
        dispatch({ type: "GO_TO_PREV" });
    };

    return (
        <React.Fragment>
            {fileId && (
                <BlueScreenLayout showClose={showClose}> 
                    <DocumentPreview fileId={fileId} fileType={fileType}>
                        <SecondaryButton onClick={closeForm} > <Text uuid={"GENERIC_BUTTON_CLOSE"} /> </SecondaryButton>
                    </DocumentPreview>
                </BlueScreenLayout>
            )}

        </React.Fragment>
    )
}

export default ViewFile;

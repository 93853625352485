import { Box, Stack } from '@mui/material';
import React, { useMemo } from 'react';
import Typography from 'src/modules/generic/components/Texts/Typography';
import cls from "./CardProductFormationScale.module.scss";
import useFormat from "src/modules/generic/hooks/useFormat.jsx"
import useBusinessRules from 'src/modules/checkout/hooks/useBusinessRules';
import _ from 'lodash';
function CardProductFormationScale({ product, company, ...props }) {

  const { moneyFormat } = useFormat();
  const { isItemEnabled, isUsingNominee } = useBusinessRules();

  const { fullPrice, priceWithDiscount } = useMemo(() => {
    let fullPrice = 0;
    _.each(product?.items, function (item, key) {
      if (item.amount && isItemEnabled(item, company)) {
        fullPrice = fullPrice + item.amount;
      }
    })

    const priceWithDiscount = fullPrice - props.save;
    return {
      fullPrice,
      priceWithDiscount
    }
  }, [company, isItemEnabled, product?.items, props.save])

  return (
    <>
      <div className={` ${cls.cornerContainer} `}>
        <div className={` ${cls.cornerRounder} `}>
          <div className={` ${cls.cornerAngle} `} data-corner="ANGLE"></div>
        </div>
        <div className={` ${cls.cornerDetail} `}>
        Save {props.currency}{props.currency === "$" ? "" : " "}{moneyFormat(props.save, true)}
        </div>
      </div>
      <Stack direction={"column"} gap={2} justifyContent="flex-start" alignItems={"flex-start"} textAlign="left" sx={{ width: "100%", paddingLeft: "1.8462rem" }}>
        <Stack direction={"column"} justifyContent="flex-start" alignItems={"flex-start"} textAlign="left" sx={{ width: "100%" }}>
          <Typography variant={"h4"} className={cls["text"]}>
            {props.label}
          </Typography>
          {isUsingNominee(company) && (
            <Typography variant={"body"} fontWeight={"bold"}>
              + Clara’s Nominee Authorised Signatory Fee
            </Typography>
          )}
        </Stack>
        <Typography variant={"m"}>
        <b>Save {props.currency}{props.currency === "$" ? "" : " "}{moneyFormat(props.save, true)}</b> when you combine your formation with our annual Scale subscription. Unlimited access to documents, ESOP, and more - Scale ensures your business can reach unbounded heights!
        </Typography>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems="flex-end" sx={{ width: "100%" }}>
          <Box sx={{ width: "50%" }}>
            <Box></Box>
          </Box>
          <Stack>
            <Stack
              direction={"row"}
              gap={1}
              justifyContent="flex-end"
              alignItems={"flex-end"}
              textAlign="right"
            >
              <Typography variant={"xs"} className={cls["moneyScratch"]}>
                {props.currency}
              </Typography>
              <Typography variant={"m"} className={cls["priceScratch"]}>
                {moneyFormat(fullPrice, true)}
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              gap={1}
              justifyContent="flex-end"
              alignItems={"flex-end"}
              textAlign="right"
            >
              <Typography variant={"xs"} className={cls["money"]}>
                {props.currency}
              </Typography>
              <Typography variant={"h4"} className={cls["price"]}>
                {moneyFormat(priceWithDiscount)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
export default CardProductFormationScale;
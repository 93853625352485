import { useMemo } from "react";
import TemplateWizardSkeletonMachine from "../../../../generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine";
import { StartEditSingleQuestion } from "../steps";

const useSteps = () => {

  return useMemo(() => ({
    initialData: {
      render: TemplateWizardSkeletonMachine,
      renderProps: {
          title: "",
          subTitle: ""
      }
    },
    editQuestion: {
      render: StartEditSingleQuestion,
    },
    uploadQuestion: {
      render: TemplateWizardSkeletonMachine,
      renderProps: {
        title: "",
        subTitle: ""
      }
    },
  }), []);
}

export default useSteps;
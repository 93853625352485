import { Field } from "formik";
import React from "react";
import Line from "src/components/generic/Line/Line";
import { ClaraSelect, TextInput } from 'src/v1/components';
import classes from '../DocumentUploadForm.module.scss';
const IdentityDocumentsFields = ({ getAddsPartyList,individualStakeholders }) => {
    return (<>
        <Field name="party">
          {({ field, form }) => {
            return (
              <div className={`${classes.row} ${classes.Select}`}>
                <ClaraSelect
                  label={`Party: `}
                  field={field}
                  required={true}
                  form={form}
                  placeholder={"Search or select from a list..."}
                  adds={getAddsPartyList()}
                  lists={[{ list: individualStakeholders }]}
                />
              </div>)
          }}
        </Field>
        <Line className={classes.Separator} />
            <Field name="visaStamp.expirationDate">
              {({ field, form }) => {
                return (
                  <TextInput
                    required={true}
                    classNameInput={{ input: classes.Calendars, root: classes.labelCalendar }}
                    type='date'
                    messageErrorClassName={classes.MessageErrorDate}
                    label='Expiration Date: '
                    field={field}
                    form={form}
                    placeholder={"dd/mm/yyyy"}
                  />)
              }
              }
            </Field></>)
  
}
export default IdentityDocumentsFields;
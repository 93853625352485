import * as Yup from 'yup';
import _ from 'lodash'
import {Constants} from "../../../../utils/constants"

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const notZipCode = [
    "Angola",
    "Antigua and Barbuda",
    "Aruba",
    "Bahamas",
    "Belize",
    "Benin",
    "Botswana",
    "Burkina Faso",
    "Burundi",
    "Cameroon",
    "Central African Republic",
    "Comoros",
    "Congo",
    "Congo, the Democratic Republic of the",
    "Cook Islands",
    "Cote d'Ivoire",
    "Djibouti",
    "Dominica",
    "Equatorial Guinea",
    "Eritrea",
    "Fiji",
    "French Southern Territories",
    "Gambia",
    "Ghana",
    "Grenada",
    "Guinea",
    "Guyana",
    "Hong Kong",
    "Ireland",
    "Jamaica",
    "Kenya",
    "Kiribati",
    "Macao",
    "Malawi",
    "Mali",
    "Mauritania",
    "Mauritius",
    "Montserrat",
    "Nauru",
    "Netherlands Antilles",
    "Niue",
    "North Korea",
    "Panama",
    "Qatar",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Seychelles",
    "Sierra Leone",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "Suriname",
    "Syria",
    "Tanzania, United Republic of",
    `Timor-Leste`,
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tuvalu",
    "Uganda",
    "United Arab Emirates",
    "Vanuatu",
    "Yemen",
    "Zimbabwe",
]

const IndividualSchema = {


    firstName: Yup.string().required("This field is required."),
    lastName: Yup.string().required("This field is required."),
    nationality: Yup.string().required("This field is required."),
    dateOfBirth: Yup.string().required("This field is required."),
    UIDDoc: Yup.object()
        .when("nationality", {
            is: (nationality) => nationality === "Bahrain" ,
            then: Yup.object().required("This field is required."),
            otherwise: Yup.object().notRequired()
        }).nullable(),
    lastPagePassportDoc: Yup.object()
        .when("nationality", {
            is: (nationality) => nationality === "India",
            then: Yup.object().required("This field is required."),
            otherwise: Yup.object().notRequired()
        }).nullable(),
    PRCIDDoc: Yup.object()
        .when("nationality", {
            is: (nationality) => nationality === "China",
            then: Yup.object().required("This field is required."),
            otherwise: Yup.object().notRequired()
        }).nullable(),
    occupation: Yup.string().required("This field is required."),
    address: Yup.object().shape({
        address: Yup.string().required("This field is required."),
        city: Yup.string().required("This field is required."),
        state: Yup.string(),
        country: Yup.string().required("This field is required."),
        zipCode: Yup.string().nullable()
    }),
    proofOfAddress: Yup.boolean().required("This field is required."),
    proofOfAddressDoc: Yup.object().required("This field is required."),
    contactNumber: Yup.object().shape({
        prefix: Yup.string().required("This field is required.").nullable(),
        number: Yup.string().required("This field is required.").nullable().matches(phoneRegExp, 'Phone number is not valid'),
        extension: Yup.string().notRequired().nullable(),
    }),
    passportNumber: Yup.string().required("This field is required.").nullable(),
    passportDoc: Yup.object().required("This field is required.").nullable(),
    holdOrHaveHeldPassportFromAnotherCountry: Yup.boolean().required("This field is required."),
    passportFromAnotherCountryDoc: Yup.object()
        .when("option", {
            is: value => _.eq(value, "yes"),
            then: Yup.object().required("This field is required."),
            otherwise: Yup.object().notRequired()
        }).nullable(),
    politicallyExposedPerson: Yup.boolean().required("This field is required."),
    politicallyExposedPersonDescription: Yup.string()
        .when("politicallyExposedPerson", {
            is: true,
            then: Yup.string().required("This field is required."),
            otherwise: Yup.string().notRequired()
        }).nullable(),
    sourcesOfWealth: Yup.string().required("This field is required.").nullable(),
    otherSourcesOfWealth: Yup.string().when("sourcesOfWealth", {
        is: value => _.eq(value, "Others"),
        then: Yup.string().required("This field is required."),
        otherwise: Yup.string().notRequired()
    }).nullable(),
    sourceOfFunds: Yup.string().required("This field is required."),
    isCompanyFlow: Yup.boolean()


};

const OnBehalfFormSchema = {

    firstName: Yup.string().required("This field is required.").nullable(),
    lastName: Yup.string().required("This field is required.").nullable(),
    contactNumber: Yup.object().shape({
        prefix: Yup.string().required("This field is required.").nullable(),
        number: Yup.string().required("This field is required.").matches(phoneRegExp, 'Phone number is not valid').nullable(),
        extension: Yup.string().notRequired().nullable()
    }),
    pointOfContact: Yup.boolean().required("This field is required.").nullable()

}


const CompanyFormSchemaStep1 = {
    companyName: Yup.string().required("This field is required.").nullable(),
    otherTradingName: Yup.string().nullable(),
    registrationNumber: Yup.string().required("This field is required.").nullable(),
    TRNNumber: Yup.string().nullable(),
    countryOfIncorporation: Yup.string().required("This field is required.").nullable(),
    jurisdictionOfIncorporation: Yup.string().required("This field is required.").nullable(),
    otherJurisdiction: Yup.string().required("This field is required.").nullable(),
    principalAddress: Yup.object().shape({
        address: Yup.string().required("This field is required.").nullable(),
        city: Yup.string().required("This field is required.").nullable(),
        state: Yup.string().nullable(),
        country: Yup.string().required("This field is required.").nullable(),
        zipCode: Yup.string().nullable()
    }),
    address: Yup.object().when('samePrincipalPlaceOfBusinessAndLegal', {
        is: false,
        then: Yup.object().shape({
            address: Yup.string().nullable(),
            city: Yup.string().nullable(),
            state: Yup.string().nullable(),
            country: Yup.string().nullable(),
            zipCode: Yup.string().nullable()
        }),
        otherwise: Yup.object().notRequired()
    }),
    samePrincipalPlaceOfBusinessAndLegal: Yup.boolean().required("This field is required").nullable(),
    certificateOfIncorporationOrCommercialLicenseDoc: Yup.object().nullable(),
    memorandumAndArticlesOfAssociationDoc: Yup.object().nullable()

};



const CompanyFormSchemaStep2 = {
    signatoryfirstName: Yup.string().required("This field is required.").nullable(),
    signatorylastName: Yup.string().required("This field is required.").nullable(),
    signatoryJobTitle: Yup.string().required("This field is required.").nullable(),
    signatoryEmail: Yup.string().lowercase().required("This field is required.").matches(Constants.REGEXP.EMAIL, { message:"This field must be a valid email", excludeEmptyString: true }).nullable(),
    signatoryAuthority: Yup.string().required("This field is required."),
    proofOfPowerDoc: Yup.object().when("signatoryAuthority", {
        is: value => _.eq(value, "powerOfAttorney"),
        then: Yup.object().required("This field is required."),
        otherwise: Yup.object().notRequired()
    }).nullable(),
    signatoryWhyHaveAuthority: Yup.string().when("signatoryAuthority", {
        is: value => _.eq(value, "other"),
        then: Yup.string().required("This field is required.").nullable(),
        otherwise: Yup.string().notRequired()
    }).nullable(),
    clientContacts: Yup.array().of(Yup.object().shape({
        fullName: Yup.string().required("This field is required.").nullable(),
        /*   contactNumber: Yup.lazy(value => {
               if (_.has(value,"prefix") && _.has(value,"number") && _.has(value,"extension")) {
                   return Yup.object({
                       prefix: Yup.string().required("This field is required.").nullable(),
                       number: Yup.string().required("This field is required.").nullable().matches(phoneRegExp, 'Phone number is not valid'),
                       extension: Yup.string().required("This field is required.").nullable().matches(/^[0-9]*$/, "This is only number")
                   })
               }
               return Yup.mixed();
           }),*/
        email: Yup.string().lowercase().required("This field is required.").matches(Constants.REGEXP.EMAIL, { message:"This field must be a valid email", excludeEmptyString: true }).nullable()
    }))
}


const clientContac = Yup.object().shape({
    fullName: Yup.string().required("This field is required."),
    contactNumber: Yup.object({
        prefix: Yup.string().required("This field is required."),
        number: Yup.string().required("This field is required.").matches(phoneRegExp, 'Phone number is not valid'),
        extension: Yup.string().required("This field is required.").matches(/^[0-9]*$/, "This is only number")
    }).nullable(),
    email: Yup.string().lowercase().required("This field is required.")
})

const shareholdersSchema = Yup.lazy(value => {
    return Yup.object().shape({
        shareholders: Yup.array().min(1).required(),
        sharesAssigned: Yup.number(),
        sharesAuthorized: Yup.number().min(Yup.ref("sharesAssigned"), `The number of authorised shares must be equal to or greater than the sum of Issued Shares.
     The total number of issued shares is currently ${value.sharesAssigned}.`)
    })
})

const shareholdersAmountSchema = Yup.object().shape({
    sharedIssues: Yup.array().of(Yup.object({
        amount: Yup.number().required("This field is required.").min(0, "This value is invalid")
    }))
})



export {
    clientContac,
    IndividualSchema,
    OnBehalfFormSchema,
    CompanyFormSchemaStep1,
    CompanyFormSchemaStep2,
    shareholdersSchema,
    shareholdersAmountSchema
}

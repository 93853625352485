import React from 'react'
import * as Yup from 'yup'
import Form from '../../../../components/forms/Form/Form'
import useTranslate from '../../../generic/hooks/useTranslate'

import ControllerInput from '../../../generic/components/Inputs/ReactHookForm/ControllerInput'
import TemplateLabel from '../../../generic/templates/Label'
import SubmitButton from '../../../generic/components/Buttons/SubmitButton'
import FormProps from '../../../generic/forms/interfaces/FormProps';
import TemplateFormDefault from "../../../generic/templates/Modal";
import cls from './Form.module.scss';
import TextInput from '../../../../components/inputs/Text/TextInput'



const schema = Yup.object().shape({
    authorizedSignatory: Yup.string().nullable().required('This field is required'),
    authorizedSignatoryRol: Yup.string().nullable().required('This field is required'),
    email: Yup.string().email('Email Invalid').nullable().required('This field is required')
})

const FormMoreDetails: React.FC<FormProps> = ({
    initialValues: defaultValues = {},
    onCompleteSubmit,
    Template = TemplateFormDefault,
    buttons = {},
    buttonSubmitProps,
    propsTemplate }) => {


    const { translate } = useTranslate();

    const handleSubmit = async values => {
        onCompleteSubmit && await onCompleteSubmit(values);
    }

    return (
        <Form
            defaultValues={defaultValues}
            schema={schema}
            onSubmit={handleSubmit}
            onlySendDirty={false}
            optionsForm = {{mode:"onChange"}}
        >

            <Template buttons={{ ...buttons, submit: <SubmitButton  {...buttonSubmitProps}>Add</SubmitButton> }} props={propsTemplate}>


                <TemplateLabel
                    label={'Full legal name'}
                    isRequired
                    className={cls.TemplateLabel}
                >
                    <ControllerInput
                        render={TextInput}
                        name='authorizedSignatory'
                        defaultlabel={''}
                        placeholder='As it appears in their passport'

                    />
                </TemplateLabel>

                <TemplateLabel
                    label={'Role'}
                    className={cls.TemplateLabel}
                    isRequired
                >
                    <ControllerInput
                        render={TextInput}
                        name='authorizedSignatoryRol'
                        defaultlabel={''}
                        placeholder='Type here'

                    />
                </TemplateLabel>


                <TemplateLabel
                    label={'Email'}
                    className={cls.TemplateLabel}
                    isRequired
                >
                    <ControllerInput
                        render={TextInput}
                        name='email'
                        defaultlabel={''}
                        placeholder='name@example.com'

                    />
                </TemplateLabel>



            </Template>

        </Form>
    )

}

export default FormMoreDetails

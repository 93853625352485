import React, { Component } from 'react';
import ModalLoading from "../../../../../components/generic/Loading/ModalLoading/ModalLoading";

class Step4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoading: true
        }
    };

    render() {
        return (

            <ModalLoading
                loading={this.state.showLoading}
                label={"DOCTYPE_LOADING_TEXT"}
            ></ModalLoading>
        );
    }
}

export default Step4;

import { useMemo } from "react";
import useQuery from "../../../../hooks/custom/useQuery";
import { RoleFields } from "../../../stakeholder/graphql/typePolicies";
import _ from "lodash";

export const GET_STAKEHOLDER_TASKS = `
${RoleFields}
query stakeholderList($startupId: ID!) {
  stakeholderList(startupId:$startupId){
    id
    isFounder
    fullName
    isAnEntity
    tasks{
      code
      status
      additionalParams{
        entityId
        entityType
      }
    }
    warrants{
      id
      amount { amount currency }
      issuedDate
      groupCompany{
        id
        name
        
      }
      tasks {
        name
        code
        status
        additionalParams{
          entityId
          entityType
        }
      }
    }
    convertibles{
      id
      amount { amount currency }
      issuedDate
      groupCompany{
        id
        name
      }
      tasks {
        name
        code
        status
        additionalParams{
          entityId
          entityType
        }
      }
    }
    roles {
      ...RoleFields
      tasks{
        code
        status
        additionalParams{
          entityId
          entityType
        }
      }
    }
    rolesInProgress {
      toAdd {
        ...RoleFields
      }
      toDelete {
        ...RoleFields
      }
    }
  }
}`;

export const COMPANY_CORPORATE_MAP_TASKS = `
  query companyCorporateMapForTask($startupId: ID!) {
  companyCorporateMap(startupId: $startupId) {
    id
    tasks{
      code
      status
      isEssential
      additionalParams{
        entityId
        entityType
      }
    }
    companies {
      id
      kind
      companyType
      companyGoal
      jurisdiction
      jurisdictionType
      companyType
      isLocked
      managedByClara
      postIncorporation {
      	nextRenewalDate
      	renewalDeadlineDate
      }
      formationProgress
      statusOnMatters {
        isAwaitingFunds
        isRenewal
      }
      matter{
        matterProgress
      }
      name
      pendingTasksCount
      formationData{
        tslType
        paymentMethod
        isAwaitingFunds
        isUsingNominee
        businessPlan {
          isBusinessPlanPreApproved
        }
      }
      tasks{
        code
        status
        additionalParams{
          entityId
          entityType
        }
      }
      stakeholders {
        id
        role{
          id
          pendingTasksCount
          tasks{
            code
            status
            additionalParams{
              entityId
              entityType
            }
          }
        }
        tasks{
          code
          status
          additionalParams{
            entityId
            entityType
          }
        }
      }
    }
  }
}`;

const getEtities = (entity, stakeholderList, blackList = []) => {
  const entities = {};
  if (_.isEmpty(entity)) return entities;
  if (entity.__typename === "GroupCompany") {
    entities[entity.id] = entity;
    return entities;
  }
  const parent = _.find(stakeholderList, (stakeholder) => {
    return stakeholder.id === entity.id;
  });
  if (parent) {
    _.forEach(parent.roles, (role) => {
      if (_.includes(blackList, entity.id)) return;
      const roleEntities = getEtities(role.entity, stakeholderList, [
        ...blackList,
        entity.id,
      ]);
      _.forEach(roleEntities, (roleEntity) => {
        entities[roleEntity.id] = roleEntity;
      });
    });
    return entities;
  }
};

const getRoles = (roles, stakeholderList) => {
  const rolesResult = [];
  _.forEach(roles, (role) => {
    const entities = getEtities(role.entity, stakeholderList, []);
    _.forEach(entities, (entity) => {
      rolesResult.push({
        ...role,
        entity,
      });
    });
  });
  return rolesResult;
};

const useCompanyCorporateMapTasks = (variables,props={}) => {
  const { loading, error, data } = useQuery(
    COMPANY_CORPORATE_MAP_TASKS,
    variables,
    {},props
  );
  const {
    data: stakeholders,
  } = useQuery(GET_STAKEHOLDER_TASKS, variables,{},props);
  const result = useMemo(() => {
    const dataMap = _.cloneDeep(data);
    // TODO review stakeholderList
    // console.log("**** data", data);
    // let listStakeholders = [];
    // if (!_.isEmpty(dataMap)) {
    //   dataMap?.companyCorporateMap?.companies.forEach(company => {
    //     listStakeholders.push(...company.stakeholders);
    //   });
    //   const uniqueStakeholders = _.uniqBy(listStakeholders, 'id');
    //   console.log("**** listStakeholdersOriginal", stakeholders?.stakeholderList);
    //   console.log("**** listStakeholders", uniqueStakeholders);
    //   if (!_.isEmpty(uniqueStakeholders)) {
    //     dataMap.companyCorporateMap.stakeholders = _.map(uniqueStakeholders, (stakeholder) => {
    //       const result = {
    //         ...stakeholder,
    //         roles: getRoles(stakeholder.roles, uniqueStakeholders),
    //       };
    //       console.log("**** result", result);
    //       return result;
    //     });
    //   }
    // }
    if (dataMap?.companyCorporateMap && stakeholders?.stakeholderList) {
      dataMap.companyCorporateMap.stakeholders = _.map(
        stakeholders?.stakeholderList,
        (stakeholder) => {
          return {
            ...stakeholder,
            roles: getRoles(stakeholder.roles, stakeholders?.stakeholderList),
          };
        }
      );
    }
    return dataMap;
  }, [data, stakeholders]);
  return { loading, error, data: result };
};

export default useCompanyCorporateMapTasks;
import { useMemo } from 'react';
import useSession from "src/modules/session/hooks/useSession";
import useGetGroupCompany from "src/views/modules/startups/renewal/cayman/modals/EditUltimateParentModal/wizard/graphql/useGetGroupCompany";
import useUpdateUltimateParent from "./../graphql/useUpdateUltimateParent";

const useServices = ({ initialValues }) => {
  const { groupCompanyId } = initialValues;
  const { startupId } = useSession();
  const { manualQuery: getGroupCompany } = useGetGroupCompany({ groupCompanyId, startupId });
  const mutation = useUpdateUltimateParent();

  return useMemo(() => ({

    initialData: async (context, event) => {
      const { data: groupCompanyData } = await getGroupCompany();
      return { 
        initialValues, 
        groupCompany: groupCompanyData?.getGroupCompany
      };
    },

    SaveStep: async (context, event) => {
      const variables = {
        startupId,
        groupCompanyId: groupCompanyId,
        entityData: {
          name: context?.EditFormStep?.immediateParentName,
          taxJurisdiction: context?.EditFormStep?.taxJurisdiction,
          taxRegistrationNumber: context?.EditFormStep?.tin,
          mailingAddress: {
            street: context?.EditFormStep?.mailingAddress?.street,
            city: context?.EditFormStep?.mailingAddress?.city,
            state: context?.EditFormStep?.mailingAddress?.state,
            country: context?.EditFormStep?.mailingAddress?.country,
            zipCode: context?.EditFormStep?.mailingAddress?.zipCode,
            type: context?.EditFormStep?.mailingAddressType?.toUpperCase()
          }
        },
      };
      return await mutation({ variables });
    },

  }), [initialValues]);
}

export default useServices;

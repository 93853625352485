import { default as React, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import Form from "src/components/forms/Form/Form";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import Button from "src/modules/generic/components/Buttons/Button";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import {
  clearAssistatText,
  addAssistantText,
} from "src/modules/generic/store/action";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import { Constants } from "src/v1/utils";
import TextareaInput from "src/components/inputs/Textarea/TextareaInput";
import Typography from "src/modules/generic/components/Texts/Typography";
import classes from './classes.module.scss'

function WhatDoesYourCompanyDo() {
  const { next, context, cancel, prev } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return {};
  }, [context]);

  const handleCancel = () => {
    cancel();
  };

  const handleSubmit = async (values) => {
    next(values);
  };

  const handlePrevious = () => {
    prev();
  };

  useEffect(() => {
    dispatch(
      addAssistantText(
        Constants.TRANSLATES.MODAL_BUSINESS_ACTIVITY_TOOLTIP
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, []);

  const schema = Yup.object().shape({
    businessActivity: Yup.string().required("Required"),
  })

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: "What does your business do?",
          skeleton: null,
          subTitle: "Nature of underlying business",
          variant: "simple",
        }}
        buttons={{
          previous: <Button onClick={handlePrevious}>Previous</Button>,
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("BUTTON_CANCEL")}
            </Button>
          ),
          submit: (
            <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>
              {translate("GENERIC_BUTTON_ADD_GROUP")}
            </SubmitButton>
          ),
        }}
      >
        <Typography className={classes.typography}>
          {`Tell us a bit more about the underlying business activities of your operating company. This is the company that will actually conduct operations (e.g. provide goods or services, hire employees, rent office space etc.) and will be held by the topco you will form in the Cayman Islands.`}
        </Typography>
        <TemplateLabel
          label={"Nature of underlying business"}
          isRequired={true}
          variant="top"
        >
          <ControllerInput
            name={"businessActivity"}
            render={TextareaInput}
            placeholder={
              "Type here"
            }
            defaultValue={""}
          />
        </TemplateLabel>
      </TemplateWizard>
    </Form>
  );
}

export default WhatDoesYourCompanyDo;

import { createMachine } from 'xstate';

const machine =
  createMachine({
    id: 'RegistersOrCertificateOfIncumbency',
    initial: 'initialData',
    states: {
      initialData: {
        invoke: {
          src: 'initialData',
          id: 'initialData',
          onDone: [
            {
              target: 'SelectCertificate',
              actions: ['setContextAfterInvoke']
            }
          ],
        }
      },
      SelectCertificate: {
        on: {
          NEXT: {
            target: 'EvaluateCertificate',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      EvaluateCertificate: {
        always: [
          {
            cond: 'isCond_1',
            target: 'RegisterDirectorsAndMembers'
          },
          {
            cond: 'isCond_2',
            target: 'CertificateOfIncumbency'
          },
        ]
      },
      RegisterDirectorsAndMembers: {
        on: {
          PREV: {
            target: 'SelectCertificate',
          },
          NEXT: {
            target: 'SaveStep',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      CertificateOfIncumbency: {
        on: {
          PREV: {
            target: 'SelectCertificate',
          },
          NEXT: {
            target: 'SaveStep',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      SaveStep: {
        invoke: {
          src: 'SaveStep',
          id: 'SaveStep',
          onDone: [
            {
              target: 'finish',
            }
          ],
          onError: [
            {
              target: 'SaveStep'
            }
          ]
        }
      },
      cancel: {
        entry: 'cancelMachine',
        type: 'final'
      },
      finish: {
        entry: 'stopMachine',
        type: 'final'
      }
    }
  });

export default machine;

/* eslint-disable import/no-anonymous-default-export */
import LAYOUTS from "./layouts"
import ACTIONS from "./actions"
import moduleCheckoutRouter from "./../modules/checkout/routes"
import moduleMapRouter from "./../modules/map/routes"
import moduleTestRouter from "./../modules/test/routes"
import moduleFormationRouter from "./../modules/formation/routes"

export default {
  MODULES: {
    CHECKOUT: moduleCheckoutRouter,
    MAP: moduleMapRouter,
    TEST: moduleTestRouter,
    FORMATION: moduleFormationRouter
  },
  /** MF */
  "documents": {
    path: "/auth/documents/groupCompany/:groupCompanyId?"
  },
  "account": {
    path: "/account",

  },
  "login": {
    path: "/account/signIn",

  },
  "linkedinLogin": {
    path: "/linkedin/login/callback",
  },
  "recovery": {
    path: "/account/recovery",

  },
  "register": {
    path: "/account/signUp",

  },
  "reset": {
    path: "/account/reset",
  },
  "dashboard": {
    path: "/auth/dashboard",
  },
  "myTasks": {
    path: "/auth/myTasks",
  },
  /** Fin MF */


  "onboarding": {
    path: "/auth/onboarding"
  },
  "error": {
    path: "/auth/error",
  },
  "forbidden": {
    path: "/auth/forbidden",
  },
  "404": {
    path: "/auth/404",
  },
  "myAccount": {
    path: "/auth/myAccount",
  },
  "versionManager": {
    path: "/admin/version_manager"
  },
  "auditLogs": {
    path: "/admin/auditLogs",
  },
  "messages": {
    path: "/auth/messages",
  },
  upgrade: {
    path: "/auth/upgrade",
  },
  "capTable": {
    path: "/auth/profile/capTable",
  },
  "capTable.mf": {
    path: "/auth/profile/equity/:groupCompanyId",
  },
  "capTable.mf.empty": {
    path: "/auth/profile/equity",
  },
  "capTable.mf.myEquity": {
    path: "/auth/profile/myequity",
  },


  "capTable.mf.myEquity.home": {
    path: "/auth/profile/myequity/home",
  },
  "capTable.mf.myEquity.mySharesIssuance": {
    path: "/auth/profile/myequity/mySharesIssuance/:startupId/:groupCompanyId/:shareIssuanceId/:shareClassId",
  },
  "capTable.mf.myEquity.myOptionGrant": {
    path: "/auth/profile/myequity/myOptionGrant/:startupId/:groupCompanyId/:shareClassId/:optionPlanId/:optionGrantId",
  },
  "capTable.mf.myEquity.myConvertible": {
    path: "/auth/profile/myequity/myConvertible/:startupId/:groupCompanyId/:convertibleId",
  },
  "capTable.mf.myEquity.myWarrant": {
    path: "/auth/profile/myequity/myWarrant/:startupId/:groupCompanyId/:warrantId",
  },
  "capTable.mf.myEquity.grant.myTransaction.convert": {
    path: "/auth/profile/myequity/myTrasaction/convert/:startupId/:groupCompanyId/:shareClassId/:optionPlanId/:optionGrantId/:transactionId",
  },
  "capTable.mf.myEquity.grant.myTransaction.lapse": {
    path: "/auth/profile/myequity/myTrasaction/lapse/:startupId/:groupCompanyId/:shareClassId/:optionPlanId/:optionGrantId/:transactionId",
  },
  "capTable.mf.myEquity.grant.myTransaction.stopVesting": {
    path: "/auth/profile/myequity/myTrasaction/stopVesting/:startupId/:groupCompanyId/:shareClassId/:optionPlanId/:optionGrantId/:transactionId",
  },
  "capTable.mf.myEquity.grant.myTransaction.exerciseManual": {
    path: "/auth/profile/myequity/myTrasaction/exerciseManual/:startupId/:groupCompanyId/:shareClassId/:optionPlanId/:optionGrantId/:transactionId",
  },
  "capTable.mf.myEquity.grant.myTransaction.exerciseGenerate": {
    path: "/auth/profile/myequity/myTrasaction/exerciseGenerate/:startupId/:groupCompanyId/:shareClassId/:optionPlanId/:optionGrantId/:transactionId",
  },
  "capTable.mf.myEquity.shares.myTransaction.cancel": {
    path: "/auth/profile/myequity/shares/myTrasaction/cancel/:startupId/:groupCompanyId/:shareIssuanceId/:shareClassId/:transactionId",
  },
  "capTable.mf.myEquity.shares.myTransaction.transfer": {
    path: "/auth/profile/myequity/shares/myTrasaction/transfer/:startupId/:groupCompanyId/:shareIssuanceId/:shareClassId/:transactionId",
  },
  "capTable.mf.myEquity.convertibles.myTransaction.cancel": {
    path: "/auth/profile/myequity/convertibles/myTrasaction/cancel/:startupId/:groupCompanyId/:convertibleId",
  },
  "capTable.mf.myEquity.convertibles.myTransaction.convert": {
    path: "/auth/profile/myequity/convertibles/myTrasaction/convert/:startupId/:groupCompanyId/:convertibleId",
  },
  "capTable.mf.myEquity.warrants.myTransaction.cancel": {
    path: "/auth/profile/myequity/warrants/myTrasaction/cancel/:startupId/:groupCompanyId/:warrantId",
  },
  "capTable.mf.myEquity.warrants.myTransaction.exercise": {
    path: "/auth/profile/myequity/warrants/myTrasaction/exercise/:startupId/:groupCompanyId/:warrantId",
  },
  "capTable.mf.myEquity.equityCalendar": {
    path: "/auth/profile/myequity/quityCalendar",
  },
  "capTable.mf.modelling": {
    path: "/auth/profile/equity/:groupCompanyId/modelling",
  },
  "capTable.mf.settings": {
    path: "/auth/profile/equity/:groupCompanyId/settings",
  },
  "capTable.mf.valuation.add": {
    path: "/auth/profile/equity/:groupCompanyId/valuation/add",
  },
  "capTable.mf.vesting.add": {
    path: "/auth/profile/equity/:groupCompanyId/vesting/add",
  },
  "capTable.mf.valuation.edit": {
    path: "/auth/profile/equity/:groupCompanyId/valuation/edit/:valuationId",
  },
  "capTable.mf.vesting.edit": {
    path: "/auth/profile/equity/:groupCompanyId/vesting/edit/:vestingId",
  },
  "capTable.mf.valuation.view": {
    path: "/auth/profile/equity/:groupCompanyId/valuation/view/:valuationId",
  },
  "capTable.mf.vesting.view": {
    path: "/auth/profile/equity/:groupCompanyId/vesting/view/:vestingId",
  },
  "capTable.mf.captable": {
    path: "/auth/profile/equity/:groupCompanyId/captable",
  },
  "capTable.mf.shareClass": {
    path: "/auth/profile/equity/:groupCompanyId/shareClass",
  },
  "capTable.mf.shareClass.add": {
    path: "/auth/profile/equity/:groupCompanyId/shareClass/add",
  },
  "capTable.mf.shareClass.amend.add": {
    path: "/auth/profile/equity/:groupCompanyId/shareClass/:shareClassId/amend/add",
  },
  "capTable.mf.shareClass.amend.edit": {
    path: "/auth/profile/equity/:groupCompanyId/shareClass/:shareClassId/amend/:transactionId/edit",
  },
  "capTable.mf.shareClass.list": {
    path: "/auth/profile/equity/:groupCompanyId/shareClass/list",
  },
  "capTable.mf.shareClass.edit": {
    path: "/auth/profile/equity/:groupCompanyId/shareClass/edit/:shareClassId",
  },
  "capTable.mf.shareClass.view": {
    path: "/auth/profile/equity/:groupCompanyId/shareClass/view/:shareClassId",
  },
  "capTable.mf.shareClass.amend.view": {
    path: "/auth/profile/equity/:groupCompanyId/shareClass/view/:shareClassId/amend/:transactionId",
  },
  "capTable.mf.shares": {
    path: "/auth/profile/equity/:groupCompanyId/shares",
  },
  "capTable.mf.shares.list": {
    path: "/auth/profile/equity/:groupCompanyId/shares/list",
  },
  "capTable.mf.shares.add": {
    path: "/auth/profile/equity/:groupCompanyId/shares/add",
  },
  "capTable.mf.shares.edit": {
    path: "/auth/profile/equity/:groupCompanyId/shares/edit/:shareIssuanceId/:shareClassId",
  },
  "capTable.mf.shares.view": {
    path: "/auth/profile/equity/:groupCompanyId/shares/view/:shareIssuanceId/:shareClassId",
  },
  "capTable.mf.shares.transfer.add": {
    path: "/auth/profile/equity/:groupCompanyId/shares/transfer/add/:shareIssuanceId/:shareClassId",
  },
  "capTable.mf.shares.transfer.edit": {
    path: "/auth/profile/equity/:groupCompanyId/shares/transfer/edit/:shareIssuanceId/:shareClassId/:transactionId",
  },
  "capTable.mf.shares.transfer.view": {
    path: "/auth/profile/equity/:groupCompanyId/shares/transfer/view/:shareIssuanceId/:shareClassId/:transactionId",
  },
  "capTable.mf.shares.cancel.add": {
    path: "/auth/profile/equity/:groupCompanyId/shares/cancel/add/:shareIssuanceId/:shareClassId",
  },
  "capTable.mf.shares.cancel.edit": {
    path: "/auth/profile/equity/:groupCompanyId/shares/cancel/edit/:shareIssuanceId/:shareClassId/:transactionId",
  },
  "capTable.mf.shares.cancel.view": {
    path: "/auth/profile/equity/:groupCompanyId/shares/cancel/view/:shareIssuanceId/:shareClassId/:transactionId",
  },
  "capTable.mf.shares.vestingSchedule.update": {
    path: "/auth/profile/equity/:groupCompanyId/shares/vestingSchedule/update/:shareIssuanceId/:shareClassId",
  },
  "capTable.mf.options": {
    path: "/auth/profile/equity/:groupCompanyId/options",
  },
  "capTable.mf.options.list": {
    path: "/auth/profile/equity/:groupCompanyId/options/list",
  },
  "capTable.mf.options.add": {
    path: "/auth/profile/equity/:groupCompanyId/options/add",
  },
  "capTable.mf.options.amend.add": {
    path: "/auth/profile/equity/:groupCompanyId/options/amend/add/:optionPlanId/:shareClassId",
  },
  "capTable.mf.options.amend.edit": {
    path: "/auth/profile/equity/:groupCompanyId/options/amend/edit/:optionPlanId/:shareClassId/:transactionId",
  },
  "capTable.mf.options.amend.view": {
    path: "/auth/profile/equity/:groupCompanyId/options/amend/view/:optionPlanId/:shareClassId/:transactionId",
  },
  "capTable.mf.options.edit": {
    path: "/auth/profile/equity/:groupCompanyId/options/edit/:shareClassId/:optionPlanId",
  },
  "capTable.mf.options.view": {
    path: "/auth/profile/equity/:groupCompanyId/options/view/:shareClassId/:optionPlanId",
  },
  "capTable.mf.options.grant.add": {
    path: "/auth/profile/equity/:groupCompanyId/options/grant/add/:shareClassId/:optionPlanId?",
  },
  "capTable.mf.options.grant.edit": {
    path: "/auth/profile/equity/:groupCompanyId/options/grant/edit/:shareClassId/:optionPlanId/:optionGrantId",
  },
  "capTable.mf.options.grant.addStopVestingAssistance": {
    path: "/auth/profile/equity/:groupCompanyId/options/grant/addstopvestingassistance/:shareClassId/:optionPlanId/:optionGrantId",
  },
  "capTable.mf.options.grant.addLapseAssistance": {
    path: "/auth/profile/equity/:groupCompanyId/options/grant/addlapseassistance/:shareClassId/:optionPlanId/:optionGrantId",
  },
  "capTable.mf.options.grant.addStopVesting": {
    path: "/auth/profile/equity/:groupCompanyId/options/grant/stopVesting/add/:shareClassId/:optionPlanId/:optionGrantId",
  },
  "capTable.mf.options.grant.addLapse": {
    path: "/auth/profile/equity/:groupCompanyId/options/grant/lapse/add/:shareClassId/:optionPlanId/:optionGrantId",
  },
  "capTable.mf.options.grant.addConvert": {
    path: "/auth/profile/equity/:groupCompanyId/options/grant/convert/add/:shareClassId/:optionPlanId/:optionGrantId",
  },
  "capTable.mf.options.grant.addExerciseManual": {
    path: "/auth/profile/equity/:groupCompanyId/options/grant/exerciseManual/add/:shareClassId/:optionPlanId/:optionGrantId",
  },
  "capTable.mf.options.grant.addExerciseGenerate": {
    path: "/auth/profile/equity/:groupCompanyId/options/grant/exerciseGenerate/add/:shareClassId/:optionPlanId/:optionGrantId",
  },
  "capTable.mf.options.grant.editStopVesting": {
    path: "/auth/profile/equity/:groupCompanyId/options/grant/stopVesting/edit/:shareClassId/:optionPlanId/:optionGrantId/:transactionId",
  },
  "capTable.mf.options.grant.editLapse": {
    path: "/auth/profile/equity/:groupCompanyId/options/grant/lapse/edit/:shareClassId/:optionPlanId/:optionGrantId/:transactionId",
  },
  "capTable.mf.options.grant.editConvert": {
    path: "/auth/profile/equity/:groupCompanyId/options/grant/convert/edit/:shareClassId/:optionPlanId/:optionGrantId/:transactionId",
  },
  "capTable.mf.options.grant.editExerciseManual": {
    path: "/auth/profile/equity/:groupCompanyId/options/grant/exerciseManual/edit/:shareClassId/:optionPlanId/:optionGrantId/:transactionId",
  },
  "capTable.mf.options.grant.editExerciseGenerate": {
    path: "/auth/profile/equity/:groupCompanyId/options/grant/exerciseGenerate/edit/:shareClassId/:optionPlanId/:optionGrantId/:transactionId",
  },
  "capTable.mf.options.grant.viewStopVesting": {
    path: "/auth/profile/equity/:groupCompanyId/options/grant/stopVesting/view/:shareClassId/:optionPlanId/:optionGrantId/:transactionId",
  },
  "capTable.mf.options.grant.viewExerciseManual": {
    path: "/auth/profile/equity/:groupCompanyId/options/grant/exerciseManual/view/:shareClassId/:optionPlanId/:optionGrantId/:transactionId",
  },
  "capTable.mf.options.grant.viewExerciseGenerate": {
    path: "/auth/profile/equity/:groupCompanyId/options/grant/exerciseGenerate/view/:shareClassId/:optionPlanId/:optionGrantId/:transactionId",
  },
  "capTable.mf.options.grant.viewLapse": {
    path: "/auth/profile/equity/:groupCompanyId/options/grant/lapse/view/:shareClassId/:optionPlanId/:optionGrantId/:transactionId",
  },
  "capTable.mf.options.grant.viewConvert": {
    path: "/auth/profile/equity/:groupCompanyId/options/grant/convert/view/:shareClassId/:optionPlanId/:optionGrantId/:transactionId",
  },
  "capTable.mf.options.grant.view": {
    path: "/auth/profile/equity/:groupCompanyId/options/grant/view/:shareClassId/:optionPlanId/:optionGrantId",
  },
  "capTable.mf.convertibles": {
    path: "/auth/profile/equity/:groupCompanyId/convertibles",
  },
  "capTable.mf.convertibles.list": {
    path: "/auth/profile/equity/:groupCompanyId/convertibles/list",
  },
  "capTable.mf.convertibles.add": {
    path: "/auth/profile/equity/:groupCompanyId/convertibles/add",
  },
  "capTable.mf.convertibles.edit": {
    path: "/auth/profile/equity/:groupCompanyId/convertibles/edit/:convertibleId",
  },
  "capTable.mf.convertibles.view": {
    path: "/auth/profile/equity/:groupCompanyId/convertibles/view/:convertibleId",
  },
  "capTable.mf.convertibles.convert.add": {
    path: "/auth/profile/equity/:groupCompanyId/convertibles/convert/add/:convertibleId/:transactionId",
  },
  "capTable.mf.convertibles.convert.edit": {
    path: "/auth/profile/equity/:groupCompanyId/convertibles/convert/edit/:convertibleId/:transactionId",
  },
  "capTable.mf.convertibles.convert.view": {
    path: "/auth/profile/equity/:groupCompanyId/convertibles/convert/view/:convertibleId/:transactionId",
  },
  "capTable.mf.convertibles.cancel.add": {
    path: "/auth/profile/equity/:groupCompanyId/convertibles/cancel/add/:convertibleId/:transactionId",
  },
  "capTable.mf.convertibles.cancel.edit": {
    path: "/auth/profile/equity/:groupCompanyId/convertibles/cancel/edit/:convertibleId/:transactionId",
  },
  "capTable.mf.convertibles.cancel.view": {
    path: "/auth/profile/equity/:groupCompanyId/convertibles/cancel/view/:convertibleId/:transactionId",
  },
  "capTable.mf.warrants": {
    path: "/auth/profile/equity/:groupCompanyId/warrants",
  },
  "capTable.mf.warrants.list": {
    path: "/auth/profile/equity/:groupCompanyId/warrants/list",
  },
  "capTable.mf.warrants.add": {
    path: "/auth/profile/equity/:groupCompanyId/warrants/add",
  },
  "capTable.mf.warrants.cancel.add": {
    path: "/auth/profile/equity/:groupCompanyId/warrants/cancel/add/:warrantId"
  },
  "capTable.mf.warrants.cancel.edit": {
    path: "/auth/profile/equity/:groupCompanyId/warrants/cancel/edit/:warrantId/:transactionId"
  },
  "capTable.mf.warrants.cancel.view": {
    path: "/auth/profile/equity/:groupCompanyId/warrants/cancel/view/:warrantId/:transactionId"
  },
  "capTable.mf.warrants.exercise.add": {
    path: "/auth/profile/equity/:groupCompanyId/warrants/exercise/add/:warrantId"
  },
  "capTable.mf.warrants.exercise.edit": {
    path: "/auth/profile/equity/:groupCompanyId/warrants/exercise/edit/:warrantId/:transactionId"
  },
  "capTable.mf.warrants.exercise.view": {
    path: "/auth/profile/equity/:groupCompanyId/warrants/exercise/view/:warrantId/:transactionId"
  },
  "capTable.mf.warrants.edit": {
    path: "/auth/profile/equity/:groupCompanyId/warrants/edit/:warrantId",
  },
  "capTable.mf.warrants.view": {
    path: "/auth/profile/equity/:groupCompanyId/warrants/view/:warrantId",
  },
  "screnario": {
    path: "/auth/profile/equity/:groupCompanyId/scenario",
  },
  "healthCheck": {
    path: "/auth/profile/healthCheck",
  },

  "switchStartup": {
    // path: "/auth/startups",
    path: "/auth/myProfiles",
  },
  "subscriptions": {
    path: "/auth/subscriptions",
  },
  "accelerators": {
    path: "/auth/accelerators",
  },
  "invoices": {
    path: "/auth/invoices",
  },

  /**TODO: delete when remove service agent */
  "switchMatters": {
    path: "/auth/matters"
  },
  /**TODO: Delete when delete formation */
  "scopingDisagree": {
    path: "/auth/formation/scoping/disagree",
  },
  "scopingSubmitted": {
    path: "/auth/formation/scoping/submitted",
  },
  "scopingProposalIntro": {
    path: "/auth/formation/scoping/proposal/intro/:matterId?",
  },
  "scopingProposalApprove": {
    path: "/auth/formation/scoping/proposal/approved",
  },
  "scopingProposalDecline": {
    path: "/auth/formation/scoping/proposal/declined",
  },
  "kycIndividualIntro": {
    path: "/auth/formation/kyc/individual/intro/:matterId?",
  },
  "kycCompanyIntro": {
    path: "/auth/formation/kyc/company/intro/:matterId?",
  },
  "kycSubmitted": {
    path: "/auth/formation/kyc/submitted",
  },
  "kycEngagementIntro": {
    path: "/auth/formation/kyc/engagement/intro/:matterId?",
  },
  "kycEngagementApprove": {
    path: "/auth/formation/kyc/engagement/approved",
  },
  "kycEngagementDecline": {
    path: "/auth/formation/kyc/engagement/declined",
  },
  "questionnaireIntro": {
    path: "/auth/formation/questionnaire/intro/:matterId?",
  },
  "questionnaireSubmitted": {
    path: "/auth/formation/questionnaire/submitted",
  },
  "incorporationIntro": {
    path: "/auth/formation/documents/intro/:matterId?",
  },
  "incorporationSubmitted": {
    path: "/auth/formation/documents/submitted",
  },
  "matterProposalView": {
    path: "/auth/formation/scoping/proposal/document/:matterId",
  },
  "viewFileDocx": {
    path: "/auth/formation/document/view/:fileId",
  },
  "matterEngagementView": {
    path: "/auth/formation/kyc/engagement/document/:matterId",
  },
  "addHealthCheckIssue": {
    path: "/auth/startup/healthCheck/issue/add",
  },
  "chooseDocumentType": {
    path: "/auth/startup/documents/choose/:previewDocument?/:region?",
  },
  "shareProfileRecentActivty": {
    path: "/auth/profile/startup/shareProfile/:startupId?/:userId?",
  },

  "redirectBackOffice": {
    path: "/redirect/back-office/:startupId?/:token?",
  },
  // A Refactorizar
  "raiz": {
    path: "/",
  },



  "logout": {
    path: "/auth/logout",
    label: "Logout"
  },

  introduction: {
    path: "/auth/profile/introduction"
  },

  "verify": {
    path: "/verify/:token",
    layout: LAYOUTS.INVITE
  },


  "addMatter": {
    path: "/auth/addMatter",
    label: "Add Matter",
    layout: LAYOUTS.ADD_FORM,
    action: ACTIONS.MANAGE_MATTER,
    cantBack: true
  },
  "addKYCToAMatter": {
    path: "/auth/addKYCToAMatter/:matterId",
    label: "Add KYC to a Matter",
    action: ACTIONS.COMPLETE_MATTER_FLOW,
    cantBack: true
  },
  "answerTheQuestionnaire": {
    path: "/auth/answerTheQuestionnaire/:matterId",
    label: "Answer the Matter Questionnaire",
    action: ACTIONS.COMPLETE_MATTER_FLOW,
    cantBack: true
  },
  "ADGMFormationDocumentReview": {
    path: "/auth/ADGMFormationDocumentReview/:matterId",
    label: "ADGM Formation - Document Review",
    action: ACTIONS.COMPLETE_MATTER_FLOW,
    cantBack: true
  },
  'addIndividual': {
    path: "/auth/addIndividual/:matterId/:entityId?",
    label: "Add Individual",
    action: ACTIONS.MANAGE_MATTER,
    cantBack: true
  },
  'addCompanyKYC': {
    path: "/auth/addCompany/:matterId",
    label: "Add Company",
    action: ACTIONS.MANAGE_MATTER,
    cantBack: true
  },
  'addDocumentKYC': {
    path: "/auth/documentKYC/:matterId/:documentId?/:documentName?",
    label: "Add Document",
    action: ACTIONS.MANAGE_MATTER,
    cantBack: true
  },
  "incorporationMatter": {
    path: "/auth/formationMatter/:matterId",
    cantBack: true
  },
  'proposalsAndEngagements': {
    path: "/auth/matter/:matterId/:tab/form/:mode/:typeForm?/",
    label: "Add Proposal and engagements",
    action: ACTIONS.MANAGE_MATTER,
    cantBack: true
  },
  "addQuestionnaireCompany": {
    path: "/auth/add/questionnaireCompany/:matterId",
    label: "Add Company",
    action: ACTIONS.MANAGE_MATTER,
    cantBack: true
  },
  "editQuestionnaireCompany": {
    path: "/auth/edit/questionnaire/Company/:matterId/:id",
    label: "Edit Company",
    action: ACTIONS.MANAGE_MATTER,
    cantBack: true
  },
  "clientReview": {
    path: "/auth/clientReview/:matterId",
    layout: LAYOUTS.SELECT_FORM
  },
  "incorporationSignatories": {
    path: "/auth/formationSignatories/:matterId",
    layout: LAYOUTS.SELECT_FORM
  },
  "incorporationPack": {
    path: "/auth/formationPack/:matterId",
    layout: LAYOUTS.SELECT_FORM
  },
  "addDocument": {
    path: "/auth/add/document",
    label: "Upload Document",
    layout: LAYOUTS.ADD_FORM,
    action: ACTIONS.EDIT_PROFILE,
    cantBack: true
  },
  "approveDocument": {
    path: "/auth/approve/document/:matterId/:documentId/:fileId",
    label: "Approve Document",
    layout: LAYOUTS.ADD_FORM,
    action: ACTIONS.MANAGE_MATTER,
    cantBack: true
  },


  "generateDocument": {
    path: "/auth/generate/document/:docType",
    label: "Generate Document",
    layout: LAYOUTS.ADD_FORM,
    action: ACTIONS.EDIT_PROFILE,
    cantBack: true
  },
  generateDocumentCustomTemplateMF: {
    path: "/auth/generate/documentMF/custom/:docType/:customTemplateId",
    label: "Generate Document",
    layout: LAYOUTS.ADD_FORM,
    action: ACTIONS.EDIT_PROFILE,
    cantBack: true
  },
  "generateDocumentMF": {
    path: "/auth/generate/documentMF/template/:docType/:templateId",
    label: "Generate Document",
    layout: LAYOUTS.ADD_FORM,
    action: ACTIONS.EDIT_PROFILE,
    cantBack: true
  },
  "viewDocumentDraft": {
    path: "/auth/generate/viewDocumentDraft/:docType/:documentDraftId",
    label: "Draft Document",
    layout: LAYOUTS.ADD_FORM,
    action: ACTIONS.EDIT_PROFILE,
    cantBack: true
  },
  "viewDocumentTemplate": {
    path: "/auth/generate/documentTemplate/:docType/:documentTemplateId",
    label: "Generate Document",
    layout: LAYOUTS.ADD_FORM,
    action: ACTIONS.EDIT_PROFILE,
    cantBack: true
  },
  "viewDocumentTemplateMF": {
    path: "/auth/generate/documentTemplateMF/:docType/:documentTemplateId",
    label: "Generate Document",
    layout: LAYOUTS.ADD_FORM,
    action: ACTIONS.EDIT_PROFILE,
    cantBack: true
  },
  "generateTemplate": {
    path: "/auth/generate/template",
    label: "Generate Template",
    layout: LAYOUTS.ADD_FORM,
    action: ACTIONS.EDIT_PROFILE,
    cantBack: true
  },
  "viewStartup": {
    path: "/auth/view/startup/:id/:tab?",
    layout: LAYOUTS.VIEW_FORM,
    action: ACTIONS.VIEW_PROFILE
  },
  "viewIncorporation": {
    path: "/auth/view/formation/:id",
    layout: LAYOUTS.VIEW_FORM,
    cantBack: true
  },
  "viewMatter": {
    path: "/auth/view/matter/:id/:tab?",
    layout: LAYOUTS.VIEW_FORM,
    action: ACTIONS.VIEW_MATTER
  },
  "editMatter": {
    path: "/auth/edit/matter/:id/:tab?",
    layout: LAYOUTS.EDIT_FORM,
    action: ACTIONS.MANAGE_MATTER,
    cantBack: true
  },
  "editIndividual": {
    path: "/auth/edit/individual/:matterId/:id",
    action: ACTIONS.MANAGE_MATTER,
    cantBack: true
  },
  "editQuestionnaireIndividual": {
    path: "/auth/edit/questionnaire/individual/:matterId/:id",
    action: ACTIONS.MANAGE_MATTER,
    cantBack: true
  },
  "editCompany": {
    path: "/auth/edit/company/:matterId/:id/:type?",
    action: ACTIONS.MANAGE_MATTER,
    cantBack: true
  },
  "editCompanyQuestionnaire": {
    path: "/auth/questionnaire/edit/company/:matterId/:id/:type?",
    action: ACTIONS.MANAGE_MATTER,
    cantBack: true
  },
  "editEntity": {
    path: "/auth/edit/entity/:matterId/:id",
    action: ACTIONS.MANAGE_MATTER,
    cantBack: true
  },
  "addEntity": {
    path: "/auth/add/entity/:matterId",
    action: ACTIONS.MANAGE_MATTER,
    cantBack: true
  },
  "questionnaireCompany": {
    path: "/auth/edit/questionnaire/company/:matterId",
    action: ACTIONS.MANAGE_MATTER,
    cantBack: true
  },
  "questionnaireEditdirectors": {
    path: "/auth/edit/questionnaire/directors/:matterId",
    cantBack: true
  },
  "questionnaireEditBeneficialOwners": {
    path: "/auth/edit/questionnaire/beneficialOwners/:matterId",
    cantBack: true
  },
  "questionnaireEditAgreements": {
    path: "/auth/edit/questionnaire/agreements/:matterId",
    cantBack: true
  },
  "questionnaireDirector": {
    path: "/auth/edit/questionnaire/director/:matterId",
    action: ACTIONS.MANAGE_MATTER,
    cantBack: true
  },
  "questionnaireBanckService": {
    path: "/auth/edit/questionnaire/banckService/:matterId",
    action: ACTIONS.MANAGE_MATTER,
    cantBack: true
  },
  "questionnaireSignatories": {
    path: "/auth/edit/questionnaire/signatories/:matterId",
    action: ACTIONS.MANAGE_MATTER,
    cantBack: true
  },
  "questionnaireShareholders": {
    path: "/auth/edit/questionnaire/shareholders/:matterId/:type?",
    action: ACTIONS.MANAGE_MATTER,
    cantBack: true
  },
  "questionnaireDataControler": {
    path: "/auth/edit/questionnaire/dataControler/:matterId",
    action: ACTIONS.MANAGE_MATTER,
    cantBack: true
  },
  "viewGroupCompany": {
    path: "/auth/profile/groupCompany/:id/:tab?",
    layout: LAYOUTS.VIEW_FORM,
    action: ACTIONS.VIEW_PROFILE,
    cantBack: true
  },
  "viewStakeholderCompany": {
    path: "/auth/profile/stakeholderCompany/:tab/:id/:startupId?",
    layout: LAYOUTS.VIEW_FORM,
    action: ACTIONS.VIEW_PROFILE
  },
  "viewStakeholderIndividual": {
    path: "/auth/profile/stakeholderIndividual/:tab/:id/:startupId?",
    layout: LAYOUTS.VIEW_FORM,
    action: ACTIONS.VIEW_PROFILE
  },
  "editGenerateDocument": {
    path: "/auth/edit/generateDocument/:id/:docType?",
    layout: LAYOUTS.ADD_FORM,
    cantBack: true
  },
  "editDraftDocument": {
    path: "/auth/edit/editDraftDocument/:id",
    layout: LAYOUTS.ADD_FORM,
    action: ACTIONS.EDIT_PROFILE,
    cantBack: true
  },
  "uploadNewDocumentVersion": {
    path: "/auth/edit/uploadNewDocumentVersion/:id/:docType/:templateId?",
    layout: LAYOUTS.ADD_FORM,
    action: ACTIONS.EDIT_PROFILE,
    cantBack: true
  },
  "editDocument": {
    path: "/auth/edit/document/:id",
    layout: LAYOUTS.EDIT_FORM,
    action: ACTIONS.EDIT_PROFILE,
    cantBack: true
  },
  "viewDocumentUploaded": {
    path: "/auth/profile/document-UPLOADED/:id",
    layout: LAYOUTS.EDIT_FORM,
    action: ACTIONS.VIEW_PROFILE
  },
  "viewDocumentGenerated": {
    path: "/auth/profile/document-GENERATED/:id/:status?",
    layout: LAYOUTS.EDIT_FORM,
    action: ACTIONS.VIEW_PROFILE
  },
  "editHealthCheckIssue": {
    path: "/auth/edit/healthCheckIssue/:category/:subCategory/:id",
    layout: LAYOUTS.EDIT_FORM,
    action: ACTIONS.ADD_DUEDILIGENCE,
    cantBack: true
  },
  "viewHealthCheckIssue": {
    path: "/auth/view/healthCheckIssue/:category/:subCategory/:id",
    layout: LAYOUTS.VIEW_FORM,
    action: ACTIONS.VIEW_PROFILE
  },
  "getSignature": {
    path: "/auth/getSignature/:id",
    layout: LAYOUTS.ADD_FORM,
    action: ACTIONS.EDIT_PROFILE
  },
}

import useSession from "src/modules/session/hooks/useSession";
import useUpdateGroupCompanyDetails from "src/modules/startup/GroupCompany/ADGM/EditGeneralDetails/wizard/hooks/graphql/useUpdateGroupCompanyDetails";
import useUpsertRenewalData from "src/views/modules/startups/renewal/adgm/modals/ReviewCompanyDetailsModal/wizard/hooks/graphql/useUpsertRenewalData";
import useGetPINCAPrices from "src/views/modules/startups/renewal/pincasPrice/queries/useGetPINCAPrices";
import useGetGroupCompany from "./graphql/useGetGroupCompany";
import useGroupCompanyMapper from "./useGroupCompanyMapper";

const useServices = ({ initialValues }) => {
  const { groupCompanyId } = initialValues;
  const { startupId } = useSession();
  const { getSubmitData } = useGroupCompanyMapper();
  const { manualQuery: getGroupCompany } = useGetGroupCompany({
    groupCompanyId,
    startupId,
  });

  const mutation = useUpdateGroupCompanyDetails();
  const submitRenewal = useUpsertRenewalData();
  const { manualQuery: getPINCAPrices } = useGetPINCAPrices({
    variables: {
      filters: {
        jurisdictionTypes: ["ADGM"],
      },
    },
  });

  const initialData = async () => {
    const { data: groupCompanyData } = await getGroupCompany();
    const groupCompanies =
      groupCompanyData?.getGroupCompany?.assetsAndRevenue
        ?.sharesInIncorporatedCompanies || [];
    return {
      initialValues,
      groupCompany: groupCompanyData?.getGroupCompany,
      groupCompanies,
    };
  };

  const pincasData = async () => {
    const pincasData = await getPINCAPrices();
    return { pincasData };
  };

  const SaveStep = async (context) => {

    const variables = getSubmitData(context, groupCompanyId);
    await mutation({ variables });
    return await submitRenewal(groupCompanyId);

  };

  return { initialData, SaveStep, pincasData };
};

export default useServices;

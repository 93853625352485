import React, { useEffect } from 'react';
import useLazyQuery from "../../../hooks/custom/useLazyQuery";

const query = `query getStartup($startupId: ID!){
  getStartup(startupId: $startupId){
    id
    name
    hasScaleSubscription
    subscriptions{
      id
      startDate
      renewalDate
      paymentMethod {
        ... on BankCard {
            bankCardType
            last4
            _id
          }
      }
      status
      subscriptionPlan {
        id
        code
        name
        description
        price
        features {
          id
          name
        }
      }
    }
    activeSubscriptionPlan{
      id
      code
      name
      features{
        code
        id
        name
        limit {
          ...on SubscriptionFeatureAmountLimit{
              amount
          }
          ...on SubscriptionFeatureAllowedLimit{
              allowed
          }
          ...on SubscriptionFeatureAllowedFileExtensionLimit{
              allowedExtensions
          }
        }
      }
    }
    subscriptionFeaturesUsage{
      code
      amount
    }
  }
} `
const useGetStartupUpdatePlan = (variables, options = {}) => {

  const { manualQuery, loading, error, data } = useLazyQuery(query, variables, options);

  useEffect(() => {

  }, []);
  return { loading, error, data, manualQuery };
}
export default useGetStartupUpdatePlan;

import React, {useEffect, useState} from 'react';
import {Constants} from '../../../../../../utils/constants'
import {useDispatch} from "react-redux";
import _ from "lodash";
import notification from "../../../../../../utils/notification";
import Mustache from 'mustache';
import ServerConnect from "../../../../../../utils/ServerConnect";
import Loading from '../../../../../Loading/Loading';
import {LayoutForm} from '../../../../../../containers';
import {stripTypenames} from "../../../../../../utils/graphql-util";
import CompanyQuestionniareForm
    from '../../../../../../containers/Forms/Questionniare/Company/CompanyQuestionniareForm';
import {getCompanyDataCompany, getEligibleSPVAlreadyIncorporatedCompanies, upsertCompanyDetails} from '../config'


const EditCompanyDetails = (props) => {
    const dispatch = useDispatch();

    const closeForm = () => {
        dispatch({ type: 'GO_TO', page: Constants.PAGES.viewMatter, params: { id: props.params.matterId, tab: 'questionnaire' } })
    }

    const [data, setData] = useState(null)
    const [companiesData, setCompaniesData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [list, setList] = useState([])
    const [eligibleData, setEligibleData] = useState(null)
    const [initialValues, setInitialValues] = useState(null)

    const getData = () => {
        const query_params = {
            matterId: props.params.matterId
        };

        const query = Mustache.render(getCompanyDataCompany, query_params)
        ServerConnect.graphQlQuery(query).then(result => {
            setData({
                ...result.getMattersList[0].questionnaire.companyDetails,
                alreadyCompanies: _.map(result.getMattersList[0].questionnaire.companyDetails.alreadyCompanies, com => mapCompany(com))
            });

            setLoading(false)
        });
    }

    const mapDirectorAndShareholders = (elements) => {       
        return _.concat(_.get(elements,"individuals",[]), _.compact(_.map(_.get(elements,"companies",[]), com => ({ id: com.id, companyName: com.companyName, ...com.signatory }))))
    }

    const mapCompany = (companies) => {
        return {
            ...companies,
            shareholders: mapDirectorAndShareholders(companies.shareholders),
            directors: mapDirectorAndShareholders(companies.directors),
        }


    }

    const getEligibleData = () => {
        const query_params = {
            matterId: props.params.matterId
        };

        const query = Mustache.render(getEligibleSPVAlreadyIncorporatedCompanies, query_params)
        ServerConnect.graphQlQuery(query).then(result => {
            setEligibleData({ alreadyCompanies: stripTypenames(_.map(result.getEligibleSPVAlreadyIncorporatedCompanies, com => mapCompany(com))) })
        })
    }

    const removeQuestionnaireCompany = `
    mutation removeQuestionnaireCompany(
      $matterId: String!
      $KYCCompanyId: String!
    ){
        removeQuestionnaireCompany(matterId: $matterId, KYCCompanyId: $KYCCompanyId ){
        companyName
      }
    }
    `

    const removeCompany = (id) => {
        let variables = {
            matterId: props.params.matterId,
            KYCCompanyId: id
        }
        let message = 'Company Removed';
        ServerConnect.graphQlMutation(removeQuestionnaireCompany, variables)
            .then(result => {
                notification.sendNotification(message, "success", 4990);
                dispatch({ type: 'GO_TO', page: Constants.PAGES.questionnaireCompany, params: { matterId: props.params.matterId } })
            }).catch(err => {
                console.log(err);
                notification.sendNotification("ERROR", "error", 4990);
            });
    }





    const submitData = (values) => {
        let message = 'Company Details Updated';
        let data = { ..._.omit(values, "assetsHeld"), ...values.assetsHeld }
        delete data.alreadyCompanies
        data.arabicName = !data.haveArabicName ? null : data.arabicName
        data.shortenedName = !data.haveAcronym ? null : data.shortenedName
        data.fullLegalName = !data.haveAffiliation ? null : data.fullLegalName

        console.log(JSON.stringify(data, null, 2))
        ServerConnect.graphQlMutation(upsertCompanyDetails, getVariablesMutation(props.params.matterId, stripTypenames(data)))
            .then(result => {
                notification.sendNotification(message, "success", 4990);
                closeForm();
            }).catch(err => {
                console.log(err);
                notification.sendNotification("ERROR", "error", 4990);
            });
    }

    const modifyDataFile = (values) => {
        var newValues = _.forOwn(values, (val, key) => {
            if (_.hasIn(val, 'file')) {
                return values[key] = val.file
            }
        })
        console.log('NEWVALS', { ...newValues, KYCCompanyId: props.params.id })
        return newValues



    }

    const getVariablesMutation = (matterId, values) => {
        let companyDetailsData = { ...modifyDataFile(values) };
        return {
            matterId,
            companyDetailsData
        }
    }

    useEffect(() => {
        let unmounted = false
        if (!data && !eligibleData && props.mode == 'edit' && !unmounted) {
            getData();
            getEligibleData();
        }
        return () => { unmounted = true };
    }, [data, loading, eligibleData]);

    return (
        <React.Fragment>
            <Loading show={loading} showChildren={false}>
                <LayoutForm
                    showCloseIcon={false}
                    title={"Add Company"}
                    leftEducationId={Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM[`${_.eq(props.mode, "edit") ? "EDIT" : "ADD"}`].LEFT_EDUCATION_ID}
                    handleClose={closeForm}>
                    <CompanyQuestionniareForm eligibleData={eligibleData} removeCompany={removeCompany} initialValues={data} onSubmit={submitData} redirect={closeForm} />
                </LayoutForm>
            </Loading>
        </React.Fragment>
    )
}

export default EditCompanyDetails;

import React from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";
const DetailsConvertibles = ({ go, ...props }) => {
  const params = useParams();


  return (
    <MicroFrontend id="MF_Convertibles_Content_View" component={"./DetailsConvertibles"} mf="capTable" params={{ go, ...props, ...params }} />
  );
};

export default DetailsConvertibles;
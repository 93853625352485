import React, {useEffect, useState} from 'react';
import classesForm from './Form.module.scss';
import {Field, FieldArray,} from 'formik';
import {ClaraInputPassport, ClaraSelect, ClaraUpload, OptionsButtonInput, TextInput} from '../../../components';
import moment from 'moment'
import _ from 'lodash';
import {useDispatch} from 'react-redux';
import ClaraCountrySelector from "../../../components/Form/ClaraCountrySelector/ClaraCountrySelector"
import {updateAutocomplete} from "../../../../store/actions/autocomplete"

export const AddEntity = ({ title, description, values, name, list, adds, label, renderItem, mapList, type }) => {

    const [listComboSelect, setListComboSelect] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {

        if (!_.isEmpty(list)) {
            let filterList = _.filter(list, element => !_.some(_.compact(values[name]), ind => ind.id === element.id))
            setListComboSelect([{ type, list: mapList(filterList) }]);
            let index = 0;

            _.forEach(list, (element) => {
                if (element.__typename === "KYCIndividual") {
                    updateAutocomplete(dispatch, `individuals`, `${name}_${index}`, `${element.firstName} ${element.lastName} - ${element.email}`, "label");
                    updateAutocomplete(dispatch, `individuals`, `${name}_${index}`, element.email, "email");
                    updateAutocomplete(dispatch, `individuals`, `${name}_${index}`, element.firstName, "firstName");
                    updateAutocomplete(dispatch, `individuals`, `${name}_${index}`, element.lastName, "lastName");
                    index++;
                }

            })


        }

    }, [list, values])

    useEffect(() => {

    }, [])

    const createIndividual = (value, name, additionalValue, form) => {
        if (!_.has(additionalValue, "companyName")) {
            updateAutocomplete(dispatch, `individuals`, `${name}_${list.length}`, `${additionalValue.firstName} ${additionalValue.lastName} - ${additionalValue.email}`, "label");
            updateAutocomplete(dispatch, `individuals`, `${name}_${list.length}`, additionalValue.email, "email");
            updateAutocomplete(dispatch, `individuals`, `${name}_${list.length}`, additionalValue.firstName, "firstName");
            updateAutocomplete(dispatch, `individuals`, `${name}_${list.length}`, additionalValue.lastName, "lastName");
        };
        list.push(additionalValue);
    }


    const updateValues = (value, additionalValue, form, paramsToCallbak, field) => {

        if (value) {
            const findElement = _.find(list, element => element.id === value.id);
            const listClean = _.compact(form.values[name])

            if (findElement) {
                form.setFieldValue(`${name}[${_.size(listClean)}]`, findElement);
            } else {
                form.setFieldValue(`${name}[${_.size(listClean)}]`, additionalValue);

            }
            form.setFieldValue(field.name, null);
            if (listComboSelect && listComboSelect[0]){
                setListComboSelect([{ type, list: _.filter(listComboSelect[0].list, ind => value.id !== ind.id) }])
            }else{
                setListComboSelect([{ type, list:[]}]) 
            }
        }

    }

    useEffect(() => {
        return () => {
            delete values[`${name}_draftSelector`]
        }
    }, [values])


    return (
        <div className={classesForm.containerAddEntity}>
            <h1>{title}</h1>
            <span dangerouslySetInnerHTML={{ __html: description }} />
            <FieldArray
                name={name}
                render={arrayHelpers => {


                    return (
                        <>
                            {!_.isEmpty(values[name]) && _.compact(values[name]).map((element, index) => {

                                const isLast = values[name].length === index + 1;
                                const handleAddElement = isLast ? arrayHelpers.handlePush(null) : null;
                                let handleDeleteElement = () => arrayHelpers.remove(index);
                                return (<>

                                    {renderItem(index, element, name)}
                                    <div className={classesForm.containerButtons}>
                                        <button onClick={handleDeleteElement}>Remove</button>
                                        {handleAddElement && <button className={`genericBlue`} onClick={handleAddElement}>Add Another</button>}
                                    </div>
                                    {!isLast && <hr />}
                                </>)

                            })}
                                
                            {((_.isEmpty(_.last(_.get(values, name))))) ?
                                
                                <Field name={`${name}_draftSelector`}>
                                    {({ field, form }) => (
                                        <ClaraSelect
                                            field={field}
                                            form={form}
                                            label={label}
                                            placeholder={"Search or select from the list..."}
                                            adds={adds}
                                            lists={listComboSelect}
                                            callbacks={{ createValues: createIndividual, updateValues }}
                                            className={classesForm.claraSelect}
                                        //tooltip={tooltips['Company']}
                                        />

                                    )}
                                </Field> : null


                            }
                        </>
                    )

                }}
            />

        </div>
    )
}

export const PassportData = ({ name, values, setFieldValue, current = false }) => {

    const handleOptionsButtonClick = (field, form, value) => {
        form.setFieldValue(field.name, value);
    };

    const handleDateChange = (e, field, form) => {

        const date = e === null ? null : moment(e).format("YYYY-MM-DD");
        form.setFieldValue(field.name, date);

    }

    useEffect(() => {
        if(_.get(values,name)){
            setFieldValue(name, { ..._.get(values, name), current })
        }
    }, [])

    const nationalityUID = ["AE", "SA", "QA", "KW", "BH", "OM"]

    useEffect(() => {
        if (_.get(values, `${name}.nationality`)) {

            getLabel(_.get(values, `${name}.nationality`));

        }
    }, [])

    const [labelNationalityDoc, setLabelNationalityDoc] = useState(null);

    const [fieldNameDoc, setFieldName] = useState(() => {
        const nationality = _.get(values, `${name}.nationality`);
        return nationality === "CN" ? `PRCIDDoc` : nationality === "IN" ? `lastPagePassportDoc` : `UIDDoc`;
    });

    const getLabel = (type) => {
        const nationality = _.includes(nationalityUID, type) ? `UIDDoc` : type;
        switch (nationality) {
            case "CN":
                setFieldName("PRCIDDoc");
                return setLabelNationalityDoc("Upload a copy of your PRC ID:");
            case "IN":
                setFieldName("lastPagePassportDoc")
                return setLabelNationalityDoc("Upload a copy of the last page of your passport:");
            case "UIDDoc":
                setFieldName("UIDDoc")
                return setLabelNationalityDoc("Upload a copy of your National ID:");
            default:
                setLabelNationalityDoc(null)
                break;
        }

    }

     return (
            <>
                <ClaraCountrySelector
                    jurisdictionsEnabled={false}
                    label={"Nationality"}
                    additionalStyle={"addKyc"}
                    setFieldValue={setFieldValue}
                    callbackUpdate={getLabel}
                    countryField={`${name}.nationality`}
                    returnCountryAsString={true}
                />
                <Field name={`${name}.passportNumber`}>
                    {({field, form}) => (
                        <ClaraInputPassport
                            field={field}
                            form={form}
                            label={"Passport Number"}
                            placeholder={"Passport Number..."}
                        />
                    )}
                </Field>

                <Field name={`${name}.passportDoc`}>
                    {({field, form}) => {
                        if(_.has(form.values, name)) {
                        return (
                            <ClaraUpload
                                name={`filepond`}
                                label={'Upload Passport Image Here:'}
                                field={field}
                                form={form}
                                className={classesForm.fileUpload}
                            />
                        )} else return (<div></div>)
                    }}
                </Field>

                {_.includes(["CN", "IN", ...nationalityUID], _.get(values, `${name}.nationality`, null)) &&

                <Field name={`${name}.${fieldNameDoc}`}>
                    {({field, form}) => (
                        <ClaraUpload
                            name={`filepond`}
                            label={labelNationalityDoc}
                            field={field}
                            form={form}
                            className={classesForm.fileUpload}
                            // tooltip={tooltips['Upload your document']}
                        />
                    )}
                </Field>
                }

                <Field name={`${name}.passportExpiryDate`}>
                    {({field, form}) => (
                        <TextInput
                            type='date'
                            label='Passport Expiry Date:'
                            field={field}
                            form={form}
                            placeholder={"dd / mm / yyyy"}
                            prevComponentChange={e =>
                                handleDateChange(e, field, form)
                            }
                            autoComplete='off'
                        >

                        </TextInput>
                    )}
                </Field>

                <Field name={`${name}.UAEPassportStamp`}>
                    {({field, form}) => (
                        <OptionsButtonInput
                            field={field}
                            form={form}
                            className={{root: classesForm.buttonOptions}}
                            label={"Do you have a UAE entry stamp in your passport?"}
                            options={[
                                {
                                    "id": true,
                                    "text": "Yes"
                                },
                                {
                                    "id": false,
                                    "text": "No"
                                }
                            ]}
                            onClick={handleOptionsButtonClick}
                            // params={this.props.params}
                            // functions={this.props.functions}
                        />

                    )}
                </Field>


                {_.get(values, `${name}.UAEPassportStamp`, null) &&

                <Field name={`${name}.UAEPassportStampDoc`}>
                    {({field, form}) => (

                        <ClaraUpload
                            className={classesForm.fileUpload}
                            name={`filepond`}
                            label='Upload an image of the entry stamp here: '
                            field={field}
                            form={form}
                        />
                    )}
                </Field>
                }

            </>
        )

}
import React, {Component} from 'react';
import classes from './Stakeholder.module.scss';
import Parser from "html-react-parser";


class SelectForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoading: false
        };
        
    };
    handleClose=()=> {
        this.props.handleClose();
    };
    render() {
        let { title } = this.props;
        return (
            <React.Fragment>
                <div className={`${classes.genericForm} ${classes.selectGenericForm}`}>
                    <a className={`${classes.genericFormExit} mask`} onClick={this.handleClose}>
                    </a>
                    <div className={classes.contentStakeHolder}>
                        <h1>{Parser(title)}</h1>
                        {this.props.children}
                    </div>
                </div>
            </React.Fragment>
        )
    }

};

export default SelectForm

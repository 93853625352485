import _ from "lodash";
import { useMemo } from "react";
import { assign } from 'xstate';

const useActions = () => {
  return useMemo(() => ({
    setUseSameNacionality: assign((context, event) => {
      return {
        ...context,
        isSameNacionality: true,
      };
    }),
  }), []);
};

export default useActions;

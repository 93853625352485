import React, { useMemo, useState } from 'react';
import classes from './Styles.module.scss'
import Text from '../../../../text/Text/Text'
import useCategories from '../../../../../hooks/common/useCategories';
import _ from 'lodash'
import IconBlock from '../../../../../components/templates/IconBlock/IconBlock';
import Date from "../../../../../components/text/Text/Date";
import Line from '../../../../generic/Line/Line';
import TabsMenu from "../../../../../modules/generic/components/Tab";
import TabMenu from "../../../../../modules/generic/components/Tab/TabMenu";
import { PartiesGenerated, History, Details } from './Tabs';

const PanelUploaded = ({ data, ...props }) => {

  const { categories = [], loading } = useCategories("STARTUP");

  const category = useMemo(() => {
    return _.find(categories, cat => _.some(cat.subCategories, subCat => subCat.key === _.get(data, "subCategories.0")));
  }, [loading, _.get(data, "subCategories")]);

  const subCategory = useMemo(() => {
    if (loading) {
      return "";
    }
    const category = _.find(categories, cat => _.some(cat.subCategories, subCat => subCat.key === _.get(data, "subCategories.0")));
    return _.find(category.subCategories, subCat => subCat.key === _.get(data, "subCategories.0"));
  }, [loading, _.get(data, "subCategories")]);

  const [tabValue, setTabValue] = useState(0);

  const handleOnChangeTab = (event, value) => {
    setTabValue(value);
  };

  const isDetailDisabled = useMemo(() => {
    return _.get(data, 'type.isGeneratedIncorporated')
  }, []);

  const tabs = useMemo(() => {
    return _.compact([
      <TabMenu classes={{ root: classes.tabElement }}  >
        Parties
      </TabMenu>,
      !isDetailDisabled && <TabMenu classes={{ root: classes.tabElement }}    >
        Details
      </TabMenu>,
      <TabMenu classes={{ root: classes.tabElement }} >
        History
      </TabMenu>,
    ])
  }, []);

  return (
    <div className={classes.containerPanel} >
      <div className={classes.containerCategories}>
        <div className={classes.containerLabelText}>
          <label><Text uuid={"DOCUMENT_DETAIL_LABEL_TYPE"} /></label>
          <span>{_.get(data, 'type.label', '-')}</span>
        </div>
        <div className={classes.containerLabelText}>
          <label><Text uuid={"DOCUMENT_DETAIL_LABEL_CATEGORY"} /></label>
          <span>{_.get(category, 'title', '-')}</span>
        </div>
        <div className={classes.containerLabelText}>
          <label><Text uuid={"DOCUMENT_DETAIL_LABEL_SUBCATEGORY"} /></label>
          <span> {_.get(subCategory, 'title')}</span>
        </div>
      </div>
      <Line />
      <div className={classes.containerDates}>
        <IconBlock icon={"Date"} title={<Date value={_.get(data, "executedOnDate")} />} subTitle={<Text uuid={"DOCUMENT_DETAIL_LABEL_EXECUTION_DATE"} />} />
        <IconBlock icon={"Date"} title={<Date value={_.get(data, "expirationDate")} />} subTitle={<Text uuid={"DOCUMENT_DETAIL_LABEL_EXPIRATION_DATE"} />} />
      </div>
      <div className={classes.containerTabsMenu}>
        <TabsMenu
          classes={{
            root: classes.rootTabs
          }}
          onChange={handleOnChangeTab}
          initValue={tabValue} tabs={tabs} />
        <div className={`${classes.containerTab} scrollbar`}>
          {_.isEqual(0, tabValue) && <PartiesGenerated data={data} />}
          {_.isEqual(1, tabValue) && !isDetailDisabled && <Details data={data} />}
          {_.isEqual(2 - isDetailDisabled, tabValue) && <History data={data} />}
        </div>
      </div>
    </div>
  )
};

export default PanelUploaded

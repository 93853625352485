import PropTypes from "prop-types";
import React, { useMemo } from "react";
import DetailsTabTitle from "src/components/templates/DetailsTabTitle";
// import { RoleAccess } from "src/v1/components";
// import useTranslate from "src/modules/generic/hooks/useTranslate";
// import Button from "src/modules/generic/components/Buttons/Button";
// import useModal from "src/modules/generic/hooks/useModal";
// import { Constants } from "src/v1/utils/constants";
import TwoColumnsViewer from "src/modules/generic/components/TwoColumsViewer";
import Divider from "@mui/material/Divider";
import classes from "../../styles.module.scss";
// import EditBusinessActivity from "src/modules/startup/GroupCompany/ADGM/EditBusinessActivity";
import PincasIcon from "src/modules/generic/components/Icon/PincasIcon";
import GroupCompany from "src/components/modules/clara/startups/entities/classes/GroupCompany";
import moment from "moment/moment";

function BusinessActivityPanel({ data, isLock }) {
  // const { translate } = useTranslate();
  // const { openModal, closeModal } = useModal();

  // TODO: El edit no entra la V1
  // const buttonsManager = (
  //   <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
  //     <Button
  //       variant="card"
  //       disabled={isLock}
  //       children={translate("VIEW_COMPANY_TAB_BUTTON_DETAILS_EDIT")}
  //       onClick={() => {
  //         openModal(EditBusinessActivity, {
  //           handleCloseModal: closeModal,
  //           initialValues: { groupCompanyId: data?.id },
  //         });
  //       }}
  //     />
  //   </RoleAccess>
  // );

  const groupCompanyClass = useMemo(() => {
    if (!data) return null;
    return new GroupCompany(data);
  }, [data]);

  return (
    <>
      <Divider className={classes.divider} />
      <DetailsTabTitle title="Business activity" />
      <TwoColumnsViewer
        itemsCol1={[
          {
            label: "Business activity",
            value: data?.businessActivities?.activity ?? "",
            valuePrefix: groupCompanyClass?.getFieldInMatter(
              "businessActivities.activity"
            ) ? (
              <PincasIcon />
            ) : null,
            disabledField: groupCompanyClass?.getFieldInMatter(
              "businessActivities.activity"
            ),
          },
          {
            label: "Business activity section",
            value: data?.businessActivities?.activitySection ?? "-"
          },
          {
            label: "Business activity type",
            value: data?.businessActivities?.activityType ?? "-"
          },
          {
            label: "Articles of Association",
            value:
              data?.businessActivities?.articlesOfAssociationType === "MODEL"
                ? "Model" : data?.businessActivities?.articlesOfAssociationType === "MODEL_WITH_AMENDMENTS" ? "Model with amendments"
                : "Bespoke",
            valuePrefix: groupCompanyClass?.getFieldInMatter(
              "businessActivities.articlesOfAssociationType"
            ) ? (
              <PincasIcon />
            ) : null,
            disabledField: groupCompanyClass?.getFieldInMatter(
              "businessActivities.articlesOfAssociationType"
            ),
          },
          {
            label: "Articles of Association date",
            value: data?.businessActivities?.articlesOfAssociationDate
              ? moment(
                  data.businessActivities.articlesOfAssociationDate
                ).format("D MMM YYYY")
              : "-",
          },
        ]}
        itemsCol2={[
          {
            label: "Are the company’s articles entrenched?",
            value: data?.businessActivities?.companyArticlesEntrenched
              ? "Yes"
              : "No",
          },
          {
            label: "Confirmation statement last filed",
            value: data?.businessActivities?.confirmationStatementLastFiled
              ? moment(
                  data.businessActivities.confirmationStatementLastFiled
                ).format("D MMM YYYY")
              : "-",
          },
          {
            label: "Lease registration number",
            value: data?.leaseDetails?.registrationNumber ?? "",
            isHidden: data?.licenseType === "SPV",
          },
          {
            label: "Lease start date",
            value: data?.leaseDetails?.leaseStart
              ? moment(data.leaseDetails.leaseStart).format("D MMM YYYY")
              : "-",
            isHidden: data?.licenseType === "SPV",
          },
          {
            label: "Lease end date",
            value: data?.leaseDetails?.leaseEnd
              ? moment(data.leaseDetails.leaseEnd).format("D MMM YYYY")
              : "-",
            isHidden: data?.licenseType === "SPV",
          },
        ]}
      />
    </>
  );
}

BusinessActivityPanel.propTypes = {
  data: PropTypes.any,
  isLock: PropTypes.bool,
};

export default BusinessActivityPanel;

import React, { useEffect } from 'react';
import useQuery from "../../../../hooks/custom/useQuery"
import useLazyQuery from "../../../../hooks/custom/useLazyQuery"
import _ from "lodash";

export const GET_FOUNDERS = `query getStartup($startupId: ID!){
  getStartup(startupId: $startupId){
    id
    founders {
      id
      fullName
      isFounder
      avatar
      email
    }
  }
}`;


const useGetStartupFounders = (variables,config) => {
    const configDefault = { showErrorNotification: true, showSuccessNotification: false}
    const { manualQuery,loading, error, data, refetch } = useLazyQuery(GET_FOUNDERS, variables, {...configDefault,...config});
    return { manualQuery,loading, error, data, refetch };
  }

export default useGetStartupFounders;
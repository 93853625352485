import useMutation from '../../../../../hooks/custom/useMutation'

export const UPSERT_STARTUP = `mutation upsertStartupProfile(
  $id: ID
  $startupData: StartupInput!        
) {
  upsertStartupProfile(
    id: $id
    startupData: $startupData
  ) {
    optionsSelected {
      regionFilter
    }
  }
}`;

const useUpsertStartupOptions = ({ ...props }) => {
  return useMutation(UPSERT_STARTUP, {...props});
}
export default useUpsertStartupOptions;
import React, {useEffect} from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import useDeauthoriseDevice from "./../../../../../../hooks/services/modules/user/useDeauthoriseDevice";
import { Constants } from "../../../../../../v1/utils/constants";
import useTranslate from "../../../../../../modules/generic/hooks/useTranslate";
import useModal from "./../../../../../../modules/generic/hooks/useModal";
import ModalAssistant from "../../../../../../modules/generic/components/Modal/ModalAssistant";
import Button from "../../../../../../modules/generic/components/Buttons/Button";
import TemplateWizard from "../../../../../../modules/generic/templates/Modal/TemplateWizard";
import DeauthoriseDeviceForm from "./DeauthoriseDeviceForm";
import {clearAssistatText} from "../../../../../../modules/generic/store/action";

const DeauthoriseDeviceModal = ({
  showModal,
  open,
  handleCloseModal,
  initialValues
}) => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const { closeModal} = useModal();

  const handleCloseForm = () => {
    closeModal();
  }

  const onCompletedUpdate = (data) => {
    if (_.get(data, "deauthorizeDevice.errorCode") === "DEVICE_DEAUTHORISED") {
      dispatch({ type: "GO_TO", page: Constants.PAGES.logout, params: {} });
    } else {
      handleCloseModal();
    }
  };

  const [deauthoriseDevice, loadingDeauthorise] = useDeauthoriseDevice({
    onCompleted: onCompletedUpdate,
    successMessage: "DEVICE_DEAUTHORISED_MESSAGE_SUCCESS",
  });

  const handleSubmit = async (values) => {
    await deauthoriseDevice({ id: initialValues.id, reason: values.reason });
  };

  const handleInitModal = () => {
    dispatch(clearAssistatText());
  }

  useEffect(() => {
    handleInitModal();
  }, []);

  return (
    <ModalAssistant open={showModal || open} handleClose={() => handleCloseModal()} showHeader={false}>
      <DeauthoriseDeviceForm
          initialValues={initialValues}
          buttons={{
            cancel: <Button onClick={handleCloseForm} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
          }}
          handleSubmit={handleSubmit}
          propsTemplate={{
            title: translate("MY_ACCOUNT_MODAL_DEAUTHORISE_DEVICE_TITLE", { device_name: _.get(initialValues, "name") }),
            subTitle: translate("MY_ACCOUNT_MODAL_RENAME_DEVICE_SUBTITLE")
          }}
          Template={TemplateWizard}
      />
    </ModalAssistant>
  );
};

export default DeauthoriseDeviceModal;

import MachineEquity from "../../../../wizard/machine/MachineEquity";
import FirstShareStep from "./steps/FirstStep";
import SelectCompaniesShareStep from "./steps/SelectCompaniesStep";
import ShareFormStep from "./steps/ShareFormStep";
import IsThereDocumentShareStep from "./steps/IsThereDocumentsStep";
import UploadDocumentShareFormStep from "./steps/UploadDocumentStep";
import SelectDocumentShareStep from "./steps/SelectDocumentStep";

class ShareMachine extends MachineEquity{
    constructor() {
        super('share',FirstShareStep,SelectCompaniesShareStep,ShareFormStep,IsThereDocumentShareStep,UploadDocumentShareFormStep,SelectDocumentShareStep,null);
    }
    getParamsData(initialData: object){
        return initialData
    }
    getInitialValues(initialData: object): object {
        return {}
    }
}
export default ShareMachine
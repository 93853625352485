import React, {useEffect, useState} from 'react';
import {ServerConnect} from "../utils";
import _ from "lodash";
import Mustache from 'mustache'

const QUERY_GET_PEPS = `query{
  getEntityPEPsDraft(draftId: "{{draftId}}"){
		id
    isDirector
    isShareholder
    type
    isPrimary
    firstName
    lastName
    nationality
    dateOfBirth
    email
  }
  
}`;

export default function usePeps(draftId) {
  const [peps, setPeps] = useState(null);
  useEffect(() => {

    const query = Mustache.render(QUERY_GET_PEPS, {draftId});
    ServerConnect.graphQlQuery(query).then(result => {
      let rPeps = _.get(result, "getEntityPEPsDraft", {});
      setPeps(rPeps)
    });
  }, []);

  return { peps };
}
import React, { FC } from 'react'
import ScaleDetail from '../ScaleDetail'
import Button from '../../../generic/components/Buttons/Button'
import TypographyLocal from "../../../generic/components/Texts/Typography";
import classes from './DataFooter.module.scss'

export interface DataFooterProps {
  products: string,
  handleClick: any,
  handleChangeProduct: any,
  subcriptionType: any,
  ifChangeProduct: any
}

const DataFooter: FC<DataFooterProps> = ({ 
  handleClick,
  // ifChangeProduct,
  // subcriptionType,
}: DataFooterProps) => {

  return (
    <div className={classes.containerFooter}>
      <div className={classes.textContainer}>
        <TypographyLocal
          className={classes.containerTitle}
        >{'Save up to USD 20,000 '}</TypographyLocal>
        <TypographyLocal
          className={classes.containerSubTitle}
        >{'on software & legal fees (est.)'}</TypographyLocal>
      </div>
      <div></div>
      <div>
          <div className={classes.containerDetail}>
              <div className={classes.itemPriceAnnually} >
                <TypographyLocal
                  className={classes.priceAnnually}
                >
                  {'USD 1,995'}
                </TypographyLocal>
              </div>
          </div>
          <Button
          onClick={handleClick}
          variant='upgrade'
          children={"Upgrade"}
          ></Button>
      </div>
    </div>
  )
}

export default DataFooter


import useMutationDelete from '../../../../hooks/custom/useMutationDelete'
import {useCallback} from "react";
const DELETE_GROUP_COMPANY = `mutation deleteGroupCompany(
    $startupId: String!
    $groupCompanyId: String!
){
    deleteGroupCompany(
        startupId: $startupId  
        groupCompanyId : $groupCompanyId
    ){id name}
}
`;


const useDeleteGroupCompany = (props= {}) => {
    const shouldRefetchQuery =useCallback( (observer) => {
        return false;
    },[])
    const updateCache = (cache, { data })=> {
        const mutationName = Object.keys(data)[0];
        const identity = cache.identify(data[mutationName]);
        cache.evict({ id: identity })
        const identityMap = cache.identify({ __typename: "GroupCompanyMapItem", id:data[mutationName].id});
        cache.evict({ id: identityMap })
    };
    const mutation = useMutationDelete(DELETE_GROUP_COMPANY, { ...props, shouldRefetchQuery },{update:updateCache})

    return mutation;
}
export default useDeleteGroupCompany;
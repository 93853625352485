import { DeclarationPart1, DeclarationPart2, DeclarationPart3, DeclarationPart4 } from "../steps"
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';

const useSteps = () => {
  return {
    InitialData: {
      render: TemplateWizardSkeletonMachine
    },
    SaveDeclaration: {
      render: TemplateWizardSkeletonMachine
    },
    DataProtectionDeclarationPart1: {
      render: DeclarationPart1
    },
    DataProtectionDeclarationPart2: {
      render: DeclarationPart2
    },
    DataProtectionDeclarationPart3: {
      render: DeclarationPart3
    },
    DataProtectionDeclarationPart4: {
      render: DeclarationPart4
    }
  }
}
export default useSteps;
import React, {Component} from "react";

import classes from "./Overview.module.scss"


class Category extends Component {
    state = {
        showSubRow: {}
    };
    getCircles(issues) {

        if (issues) {
            const moreIssues = issues.length > 5 ? issues.length - 5 : 0;
            return <>
                {!!moreIssues && <label className={classes.moreIssue}>+{moreIssues}</label>}
                {issues.slice(0, 5).map((issue, i) => {
                    const className = classes["Circle" + issue.status];
                    return (
                        <span key={i} className={className}>

                        </span>
                    )
                })}
            </>
        } else {
            return null;
        }
    }
    onClick = (subCategoryKey) => {
        this.props.onChange(this.props.categoryKey, subCategoryKey, this.props.title)
    }
    getSubCategories = (category) => {

        if (category) {
            return category.map((subcategory, i) => {
                let classNameLink = null;
                if (this.props.categoryKey == this.props.categorySelected && this.props.subCategorySelected == subcategory.key) {
                    classNameLink = classes.LinkSelected;
                }
                return (
                    <div key={i} className={classes.Row}>
                        <a className={classNameLink} onClick={(e) => { this.onClick(subcategory.key) }}>{subcategory.name}</a>
                        <div className={classes.Circles}>
                            {this.getCircles(subcategory.issues)}
                        </div>
                    </div>
                )
            })
        } else {
            return null;
        }
    }
    getCountIssues(category) {
        let count = 0;
        if (category && category.length) {
            for (let i = 0; i < category.length; i++) {
                count = count + this.getIssuesTotal(category[i].issues);
            }
        }

        return count;
    }

    getIssuesTotal(issues) {
        let issuesTotal = 0;
        issues.forEach(i => {
            issuesTotal++;
        });

        return issuesTotal;
    }

    render() {
        return (
            <div className={`${classes.Category} ${this.props.className}`}>
                <h1>{this.props.title} <span>{this.getCountIssues(this.props.data)} {this.getCountIssues(this.props.data) === 1 ? `Remark` : `Remarks`} </span></h1>
                <div className={classes.SubCategories}>
                    {this.getSubCategories(this.props.data)}
                </div>
            </div>
        );

    }
}


export default Category

import React, { FC, useEffect, useMemo } from 'react'
import Button from "../../../../../generic/components/Buttons/Button";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import _ from "lodash";
import UploadOptionSipForm from "../../../forms/UploadOptionSipForm";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import useCustomMachine from '../../../../../generic/context/MachineContext/useMachine';
import { clearAssistatText } from "src/modules/generic/store/action";
import { useDispatch } from "react-redux";

const UploadDocumentOptionSipStep: FC<{}> = ({ uploadFile }) => {

  const { send, next, context, state } = useCustomMachine()
  const { translate } = useTranslate()
  const dispatch = useDispatch()

  const handlePrev = () => {
    send("PREVIOUS")
  }

  const handleCancel = () => {
    send('CANCEL')
  }

  const handleCompleteSubmit = async (values: object) => {
    next(values);
  }

  useEffect(() => {
    dispatch(clearAssistatText())
  }, [])

  const getCompany = (): object => {
    return context.groupCompanies[_.get(context, 'iterations', 0)]
  }

  const shareClass = useMemo((): any[] => {
    const company = getCompany()
    return _.get(company, 'shareClass')
  }, [JSON.stringify(state)]);

  return (
    <>
      <UploadOptionSipForm
        uploadFile={uploadFile}
        company={context.groupCompanies[_.get(context,'iterations',0)]}
        propsTemplate={{
          title: translate(`MODULES.EQUITY.STEPS.UPLOAD_DOCUMENTS_STEP.TITLE_${context.type.toUpperCase()}_SIP`, { agreementType: translate(`MODULES.EQUITY.AGREEMENT_TYPE.${context.type}`) }),
          subTitle: translate(`MODULES.EQUITY.STEPS.UPLOAD_DOCUMENTS_STEP.SUBTITLE_${context.type.toUpperCase()}`)
        }}
        shareClassList={shareClass}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        buttons={{ cancel: <Button onClick={handleCancel}>Cancel</Button> , previous: <Button onClick={handlePrev}>Previous</Button>}}
      />
    </>
  )
}

export default UploadDocumentOptionSipStep
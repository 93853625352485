import React, { FC } from 'react'
import useTranslate from "../../../generic/hooks/useTranslate";
import ControllerInput from "../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import FormProps from "../../../generic/forms/interfaces/FormProps";
import { CheckboxButtonOptions } from "../../../generic/components/Inputs/CheckboxButton";
import CheckboxButtonSimple from "../../../generic/components/Inputs/CheckboxButtonSimple";
import { HiddenFieldForm } from "../../../../components/forms/contents";
import TemplateLabel from "../../../generic/components/Inputs/LabelInput";
import TextareaInput from "../../../../components/inputs/Textarea/TextareaInput";
import classes from './Form.module.scss'

export const IsPEP: FC = () => {
  const { translate } = useTranslate();

  const optionsIsPEP: Array<CheckboxButtonOptions<boolean, string>> = [
    {
      value: false,
      label: translate('MODULES.STAKEHOLDER.FORM.IS_PEP.NO')
    },
    {
      value: true,
      label: translate('MODULES.STAKEHOLDER.FORM.IS_PEP.YES')
    }
  ]

  return (
    <>
      <ControllerInput
        as={CheckboxButtonSimple}
        name='isPEP'
        options={optionsIsPEP}
        className={classes.CheckboxButton}
        classNameButton={classes.buttonCheckBox}
      />
      <HiddenFieldForm fieldsWatch={'isPEP'} conditionHideCallback={value => value}>
        <div className={classes.describePEPContainer}>
          <TemplateLabel label={translate('MODULES.STAKEHOLDER.FORM.IS_PEP.DESCRIBE_PEP')} className={classes.TemplateLabelColumn} isRequired>
            <ControllerInput
              name={'descriptionOfPEP'}
              render={TextareaInput}
              className={classes.TextArea}
              placeholder={translate('MODULES.STAKEHOLDER.FORM.IS_PEP.DESCRIBE_PEP_PLACEHOLDER')}
              defaultValue={''}
            />
          </TemplateLabel>
        </div>
      </HiddenFieldForm>
    </>
  )
}

export const IsPEPCloseAssociate: FC = () => {
  const { translate } = useTranslate()

  const options: Array<CheckboxButtonOptions<boolean, string>> = [
    {
      value: false,
      label: translate('MODULES.STAKEHOLDER.FORM.IS_CLOSE_ASSOCIATE_PEP.NO')
    },
    {
      value: true,
      label: translate('MODULES.STAKEHOLDER.FORM.IS_CLOSE_ASSOCIATE_PEP.YES')
    }
  ]

  return (
    <>
      <ControllerInput
        as={CheckboxButtonSimple}
        name='isCloseAssociateOfAPEP'
        options={options}
        className={classes.CheckboxButton}
        classNameButton={classes.buttonCheckBox}
      />
      <HiddenFieldForm fieldsWatch={'isCloseAssociateOfAPEP'} conditionHideCallback={value => value}>
        <div className={classes.describePEPContainer}>
          <TemplateLabel label={translate('MODULES.STAKEHOLDER.FORM.IS_CLOSE_ASSOCIATE_PEP.DESCRIBE_PEP')} className={classes.TemplateLabelColumn} isRequired>
            <ControllerInput
              name={'closeAssociateOfAPEP'}
              render={TextareaInput}
              className={classes.TextArea}
              placeholder={translate('MODULES.STAKEHOLDER.FORM.IS_CLOSE_ASSOCIATE_PEP.DESCRIBE_PEP_PLACEHOLDER')}
              defaultValue={''}
            />
          </TemplateLabel>
        </div>
      </HiddenFieldForm>
    </>
  )
}

export const IsPEPFamilyMember: FC<FormProps> = () => {
  const { translate } = useTranslate()

  const options: Array<CheckboxButtonOptions<boolean, string>> = [
    {
      value: false,
      label: translate('MODULES.STAKEHOLDER.FORM.IS_FAMILY_MEMBER_PEP.NO')
    },
    {
      value: true,
      label: translate('MODULES.STAKEHOLDER.FORM.IS_FAMILY_MEMBER_PEP.YES')
    }
  ]

  return (
    <>
      <ControllerInput
        as={CheckboxButtonSimple}
        name='isFamilyMemberOfAPEP'
        options={options}
        className={classes.CheckboxButton}
        classNameButton={classes.buttonCheckBox}
      />
      <HiddenFieldForm fieldsWatch={'isFamilyMemberOfAPEP'} conditionHideCallback={value => value}>
        <div className={classes.describePEPContainer}>
          <TemplateLabel label={translate('MODULES.STAKEHOLDER.FORM.IS_FAMILY_MEMBER_PEP.DESCRIBE_PEP')} className={classes.TemplateLabelColumn} isRequired>
            <ControllerInput
              name={'familyMemberOfAPEPDescription'}
              render={TextareaInput}
              className={classes.TextArea}
              placeholder={translate('MODULES.STAKEHOLDER.FORM.IS_FAMILY_MEMBER_PEP.DESCRIBE_PEP_PLACEHOLDER')}
              defaultValue={''}
            />
          </TemplateLabel>
        </div>
      </HiddenFieldForm>
    </>
  )
}
import React, { useEffect, useRef, useState } from 'react';
import useTranslate from '../../../../modules/generic/hooks/useTranslate';
import ServerConnect from '../../../../v1/utils/ServerConnect';
import DocumentNotPreview from './DocumentNotPreview';
import classes from './DocumentPreviewFile.module.scss';
import DocumentPreviewFileSkeleton from './DocumentPreviewFileSkeleton';
// import { PDFReaderSinglePage, PDFViewerAllPagesToolbars } from './PDFViewer/PDFViewer';
import { PDFViewerAllPagesToolbars } from './PDFViewer/PDFViewer';
import ConstitutionalDocuments from 'src/modules/clara/components/Accelerator/ConstitutionalDocuments/Steps/ConstitutionalDocuments';

const imageTypeSupported = ['jpg', 'jpeg', 'gif', 'png', 'png', 'svg', 'bmp'];
const officeTypeSupported = ['doc', 'xls', 'ppt', 'docx', 'xlsx', 'pptx'];
const previewTypeSupported = ['pdf'].concat(imageTypeSupported).concat(officeTypeSupported);

const DocumentPreviewFile = ({ fileId, fileType, fileName = '', style = null, className, defaultScale }) => {
	const iframeRef = useRef();
	const [loading, setLoading] = useState(true);
	const [url, setUrl] = useState(null);
	const [countReload, setCountReload] = useState(0);
	const [isImage, setIsImage] = useState(false);
	const [isDoc, setIsDoc] = useState(false)
	const [isPreviewSupported, setIsPreviewSupported] = useState(false);
	const { translate } = useTranslate();

	useEffect(() => {
		setLoading(true)
		if (fileId) {
			fileType = fileType ? fileType.toLowerCase() : fileType;
			if (!fileType) {
				// TODO: Remove this when update calls for this component and pass document instead just fileId
				setIsPreviewSupported(true)
			} else {
				setIsPreviewSupported(previewTypeSupported.indexOf(fileType) > -1);
			}
			let newUrl;
			if (imageTypeSupported.indexOf(fileType) > -1) {
				newUrl = ServerConnect.getPublicUrlFile(fileId);
				setIsImage(true);
			} else {
				newUrl = `${ServerConnect.getPublicUrlFile(fileId)}`;
			}
			setUrl(newUrl);
		}
	}, [fileId]);

	const handleLoading = () => {
		setLoading(false);
	}

	const reload = () => {
		setCountReload(countReload + 1);
	}
	return (
		<div className={`${classes.containerPreview} ${loading && classes.containerWhite} ${className}`}>
			{isPreviewSupported ? (
				<div className={`${classes.containerPreviewFile} ${loading && classes.docSkelPreview}`}>
					{loading && (
						<DocumentPreviewFileSkeleton />
					)}
					<>
						{(isImage && url) && (
							<img src={url} onLoad={handleLoading} alt={"Preview"} />
						)}
						{(!isImage && url) && (
							<>
								{isDoc && (
									<iframe
										title={"Preview"}
										className={style}
										ref={iframeRef}
										id="documentPreviewIframe"
										src={`${url}/${countReload}`}
										onLoad={handleLoading}
									/>
								)}
								{!isDoc && (
									// <PDFReaderSinglePage pdf={url} onLoad={handleLoading} defaultScale={defaultScale}/>
									<PDFViewerAllPagesToolbars pdf={url} onLoad={handleLoading} defaultScale={defaultScale}/>
								)}
								{!loading && isDoc && (
									<div onClick={reload} className={classes.Reload}>
										{translate("RELOAD_DOCUMENT")}
									</div>
								)}
							</>
						)}
					</>
				</div>
			) : (
				<div className={classes.containerNotPreview}>
					<DocumentNotPreview
						fileId={fileId}
						fileName={fileName}
					/>
				</div>
			)}
		</div>
	)
};

export default DocumentPreviewFile;

import React from "react";
import PaymentItemActivePincas from "../PaymentItemActivePincas";
import PaymentItems from "../../PaymentItems";

export interface PaymentItemLogicProps {
  product: any;
  variantProduct: any;
  activePincasSumary?: boolean;
  company: any;
  feeText: string;
}

const PaymentItemLogic: React.FC<PaymentItemLogicProps> = ({
  product,
  variantProduct,
  company,
  feeText,
  activePincasSumary = false,
}) => {

  return (
    <>
      {activePincasSumary ? (
        <PaymentItemActivePincas
          product={product}
          variant={variantProduct}
          company={company}
          feeText={feeText}
        />
      ) : (
        <PaymentItems
          product={product}
          variant={variantProduct}
          company={company}
          useTotals={false}
          feeText={feeText}
        ></PaymentItems>
      )}
    </>
  );
};
export default PaymentItemLogic;

import _ from 'lodash'
import { useSelector } from 'react-redux'
export interface UseSessionReturn {
  hasSessionAccess: (url: string) => boolean
  hasRoleAccess: (action: string, params: any) => boolean
}

function useSecurity(): UseSessionReturn {

  const session = useSelector(state => _.get(state, 'session'))

  const hasSessionAccess = (url: string) => {
    if (!url || url == '/') {
      return true
    }
    let needLogger = false
    let needStartup = false
    if (url.indexOf('/dev/') >= 0) {
      return true
    }
    if (url.indexOf('/auth/') >= 0) {
      needLogger = true
    }
    if (url.indexOf('/admin/') >= 0) {
      needLogger = true
    }
    if (url.indexOf('/profile/') >= 0) {
      needStartup = true
    }
    if (url.indexOf('/back-office/') >= 0) {
      return true
    }
    if (url.indexOf('/logout') >= 0) {
      return true
    }
    if (needLogger && (!session || !session.user)) {
      return false
    }
    if (!needLogger && session && session.user) {
      return false
    }
    if (needLogger && needStartup && !session.startup && !session.isLoading) {
      return false
    }
    return true
  }

  const getActionsByKind = (kind, params) => {
    switch (kind) {
      case 'startup':
        return _.get(session, 'startup.allowedActions', [])
      default:
        return _.get(session, 'user.allowedActions', [])
    }
  }
  const hasRoleAccess = (action, params) => {

    if (!action) { return true }
    let evaluateAction = action
    let kind = null
    if (typeof action == 'object') {
      evaluateAction = action.key
      kind = action.kind
    }
    const actions = getActionsByKind(kind, params)
    const hasAccess = actions.indexOf(evaluateAction) >= 0 || !action
    if (!hasAccess && process.env && process.env.NODE_ENV == 'development') {
      console.log(
        `Mismatched Action --> you have: ${evaluateAction} but available : ${JSON.stringify(
          actions
        )}`
      )
    }
    return hasAccess
  }


  return {
    hasSessionAccess,
    hasRoleAccess,
  }
}

export default useSecurity

import { Field } from "formik";
import _ from "lodash";
import React, { Component } from 'react';
import { TextInput,ClaraRadioWithButton,TextAreaInput } from '../../../../../../../v1/components';
import ClaraSelect from "../../../../../../../v1/components/Form/ClaraSelect/ClaraSelect";
import ClaraUpload from "../../../../../../../v1/components/Form/ClaraUpload/ClaraUpload";
import Checkbox from "../../../../../../../v1/containers/Forms/Inputs/Checkbox";
import FormikHelper from "../../../../../../../v1/utils/formikHelper";
import Line from "../../../../../../generic/Line/Line";
import AuthorisedSignatories from "../../../../../../inputs/AuthorizedSignatories/AuthorizedSignatories";
import SelectStakeholders from "../../../../../../inputs/Select/SelectStakeholders";
import classes from "../Styles.module.scss";
import CheckBox from '../../../../../../inputs/Checkbox/Checkbox';
import SelectStateCity from "../../../../../../inputs/Select/SelectStateCity";
import ClaraCountrySelector from "../../../../../../../v1/components/Form/ClaraCountrySelector/ClaraCountrySelector";

class OptionGrantNoticeDoc extends Component {

    constructor(props) {
        super(props);
        this.state = {
            typeSelected: undefined,
            participantSelected: undefined,
            groupCompanySelected: undefined,
            authorizedSelected: undefined,
            authorizedSignatories: [],
            authorizedSignatoriesList: [],
            documentTypes: [],
            documentTypesList: [],
            remainingSipShares: 0,
            dynamicParams: [],
            date: undefined,
            responsibilities: [],
            stakeholders:[],
            stateValue:null
        }
    };

    componentWillMount() {
        if (this.props.values) {
            const values = this.props.values.values ? this.props.values.values : this.props.values;
            this.setState({
                typeSelected: values.documentType,
                participantSelected: values.participant,
                groupCompanySelected: values.company,
                authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.company).map(as => ({
                    id: as.id,
                    label: as.label
                }))
            });
        }

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.values) {
            const values = nextProps.values.values ? nextProps.values.values : nextProps.values;
            this.setState({
                authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.company)
                    .map(as => ({
                        id: as.id,
                        label: as.label
                    }))
            });
        }
    }

    selectType = (documentType, extraParams, form) => {
        this.setState({
            typeSelected: documentType,
            dynamicParams: this.getDynamicParamsByType(documentType)
        }, () => {
            FormikHelper.setValueInTheCorrectPosition('terms', form, []);
            FormikHelper.setValueInTheCorrectPosition('documentType', form, documentType);
            this.state.dynamicParams.forEach((dynamicParam, i) => {
                FormikHelper.setValueInTheCorrectPosition(
                    `terms[${i}].key`,
                    form,
                    dynamicParam.key || 'NO_KEY'
                );
            })
        });
    };

    getDynamicParamsByType(documentType) {
        const type = this.props.documentTypes.filter(agrType => agrType.code === documentType)[0] || {};
        return type.fields || [];
    }

    getDocs = () => {
        
        return _.map(this.props.documentList, doc => ({
            id: doc.id,
            label: doc.name
        }))

    };

    selectGroupCompany = (boardApprovingOptionsGrantId, extraParams, form) => {
        const groupCompany = this.findGroupCompanyByDoc(boardApprovingOptionsGrantId);
        const boardApprovingGrantsData = _.find(this.props.documentList, doc => {
            return doc.id === boardApprovingOptionsGrantId
         });
        const stakeholders = this.props.stakeholders.filter(st=>(boardApprovingGrantsData?.params?.grantees || []).some(g=>g.id === st.id))
        this.setState({
            groupCompanySelected: groupCompany,
            stakeholders
        });

        if (groupCompany) {
            if (!groupCompany.registeredAddress) {
                groupCompany.registeredAddress = {}
            }
            let authorizedSignatories = _.get(form, 'values.company.authorizedSignatories', []);

            const newFormValues = {
                ...form.values,
                company: {
                    id: groupCompany.id,
                    name: groupCompany.name || '',
                    country:typeof  _.get(groupCompany, 'country', null) ==='object'? _.get(groupCompany, 'country.code', null) : _.get(groupCompany, 'country', ''),
                    jurisdiction: _.get(groupCompany, 'jurisdiction', ''),
                    registeredAddress: {
                        ...groupCompany.registeredAddress, country:typeof  _.get(groupCompany, 'registeredAddress.country', null) ==='object'? _.get(groupCompany, 'registeredAddress.country.code', null) : _.get(groupCompany, 'registeredAddress.country', '')
                    },
                    authorizedSignatories: authorizedSignatories,
                    placeOfBusiness:groupCompany.placeOfBusiness,
                },
                terms: {
                        boardApprovingOptionsGrant: {
                            id: boardApprovingOptionsGrantId
                            
                        }
                }
            };

            form.setValues(newFormValues);

            this.setState({
                groupCompanySelected: groupCompany,
            })
        } else {
            form.setValues({
                ...form.values,
                company: {},
                // boardApprovingOptionsGrant: null,
                terms: {
                    ...form.values.terms,
                    boardApprovingOptionsGrant: {
                        id:null
                    }
                }
            });
        }
    };

    findGroupCompanyByDoc(boardApprovingOptionsGrantId) {

        const document = _.find(this.props.documentList, doc => doc.id === boardApprovingOptionsGrantId);
        return this.findGroupCompany(_.get(document, 'params.company.id'));

    };

    findGroupCompany(gcId) {
        const gc = _.find(this.props.groupCompaniesList,{id:gcId});
        return gc?{...gc}:null
    };

    selectParticipant = (participant, extraParams, form, cb) => {
        const stakeholder = this.findStakeholder(participant);
        const boardApprovingGrantsData = _.find(this.props.documentList, doc => {
            return doc.id === form?.values?.terms?.boardApprovingOptionsGrant?.id
         });
        const participantGrantInfo = (boardApprovingGrantsData?.params?.grantees || []).find(g=>g.id === participant)
        
        this.setState({
            participantSelected: stakeholder,
        });



        if (stakeholder) {
            

            form.setValues({
                ...form.values,
                participant: {
                    id: participant,
                    ...participantGrantInfo,
                    ...(form?.values?.participant || {}),
                }
            });
        } else {
            form.setValues({
                ...form.values,
                participant: {}
            });
        }
    };

    findStakeholder(roleId) {
        return this.props.stakeholderList.find(stakeholder => stakeholder.id === roleId);
    };

    findStakeholderAuthorized(roleId) {
        return this.props.stakeholderList.filter(stakeholder => {
            return stakeholder.roles.some(role => role.stakeholder.id === roleId);
        })[0] || null;
    };

    filterAuthorizedByGroupCompany(groupCompany) {
        if (groupCompany) {
            let gc = this.findGroupCompany(groupCompany.id);
            return this.props.authorizedSignatories.filter(authorized => {
                return authorized.groupCompanyId === gc.id
            });
        }
        return [];
    }

    selectAuthorized = (value, form, field, replace) => {
        const stakeholder = this.props.authorizedSignatories.find(e => e.id === value);
        const email = stakeholder ? stakeholder.email : '';
        replace(0, { id: value, email })
    };

    renderParticipantAuthorizedSignatory(field) {
        const { tooltips = {} } = this.props;

        if (this.state.participantSelected.isAnEntity) {
            return (
                <React.Fragment>
                    <div className={classes.ClaraInputSelect}>
                        <Field name='participant.authorizedSignatory.fullName'>
                            {({ field, form }) => (
                                <TextInput
                                    required={true}
                                    type='text'
                                    label='Corporate signatory '
                                    field={field}
                                    form={form}
                                    placeholder={"Corporate Signatory"}
                                    tooltip={tooltips["Authorised Signatory"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name='participant.email'>
                            {({ field, form }) => (
                                <TextInput
                                    required={true}
                                    type='text'
                                    label="Corporate signatory's email address "
                                    field={field}
                                    form={form}
                                    onChange={(e) => this.updateEmailValue(e, form)}
                                    placeholder={"Email address"}
                                    tooltip={tooltips['Authorised Signatory Email']}
                                />
                            )}
                        </Field>
                    </div>
                </React.Fragment>
            )
        }
    };



    getAuthorizedSignatoriesList = (index, form) => {
        if (form.values && form.values.company) {
            return this.filterAuthorizedByGroupCompany(form.values.company).map(as => ({
                id: as.id,
                label: as.label
            }))
        } else {
            return [];
        }
    };

    getLabel = (key) => {
        switch (key) {
            case "fullName":
                if (this.state.participantSelected.isAnEntity) {
                    return "Company name";
                } else {
                    return "Name";
                }
            case "country":
                if (this.state.participantSelected.isAnEntity) {
                    return "Country of incorporation";
                } else {
                    return "Nationality";
                }
            case "address":
                if (this.state.participantSelected.isAnEntity) {
                    return "Registered address";
                } else {
                    return "Residential address";
                }
            case "passportOrRegistered":
                if (this.state.participantSelected.isAnEntity) {
                    return "Company number";
                } else {
                    return "Passport number";
                }
        }
    };

    getField = (key) => {
        switch (key) {
            case "passportOrRegistered":
                if (this.state.participantSelected.isAnEntity) {
                    return "participant.registeredNumber";
                } else {
                    return "participant.passportNumber";
                }
        }
    };

    getTooltip = (key, fieldName) => {
        switch (key) {
            case "fullName":
                if (this.state.participantSelected.isAnEntity) {
                    return "Participant's Company's Name";
                } else {
                    return "Participant's Name";
                }
            case "passportOrRegistered":
                if (this.state.participantSelected.isAnEntity) {
                    return "Company Number";
                } else {
                    return "Passport Number";
                }
            case "country":
                if (this.state.participantSelected.isAnEntity) {
                    return "Country";
                } else {
                    return "Nationality";
                }
            case "addressLine":
                if (this.state.participantSelected.isAnEntity) {
                    return "Registered Address - Address";
                } else {
                    return "Residential Address - Address";
                }
            case "addressCity":
                if (this.state.participantSelected.isAnEntity) {
                    return "Registered Address - City";
                } else {
                    return "Residential Address - City";
                }
            case "addressState":
                if (this.state.participantSelected.isAnEntity) {
                    return "Registered Address - State";
                } else {
                    return "Residential Address - State";
                }
            case "addressCountry":
                if (this.state.participantSelected.isAnEntity) {
                    return "Registered Address - Country";
                } else {
                    return "Residential Address - Country";
                }
            case "addressPostCode":
                if (this.state.participantSelected.isAnEntity) {
                    return "Registered Address - Post Code";
                } else {
                    return "Residential Address - Post Code";
                }
            case "recipientNotice":
                if (fieldName === 'company') {
                    return "Company's";
                } else {
                    return "Participant's";
                }
        }
    };

    getPlaceholder = (key) => {
        switch (key) {
            case "passportOrRegistered":
                if (this.state.participantSelected.isAnEntity) {
                    return "Company number";
                } else {
                    return "Passport number";
                }
        }
    };

    renderRecipientNotice(fieldName, isAnEntity) {
        const { tooltips = {} } = this.props;

        if (isAnEntity) {
            return (
                <React.Fragment>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.fullName`}>
                            {({ field, form }) => (
                                <TextInput
                                    required={true}
                                    type='text'
                                    label='Recipient for notices '
                                    field={field}
                                    form={form}
                                    placeholder={"Recipient's name"}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={`${classes.ContainerRow} ${classes.Select}`}>
                        <Field name={`${fieldName}.recipientNotice.address.country`}>
                            {({ field, form }) => (
                                <ClaraCountrySelector
                                    jurisdictionsEnabled={false}
                                    isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                    countryField={`${fieldName}.recipientNotice.address.country`}
                                    label={"Recipient's address "}
                                    setFieldValue={form.setFieldValue}
                                    initialValues={{ country: _.get(form.values, `${fieldName}.recipientNotice.address.country`, '') }}
                                    returnCountryAsString={_.isEmpty(_.get(form.values, `${fieldName}.recipientNotice.address.country.code`, ''))}
                                    additionalStyle={'ClaraCountrySelectorWizard'}
                                    tooltips={{
                                        country: tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices - Country"]
                                    }}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.address.street`}>
                            {({ field, form }) => (
                                <TextAreaInput
                                    type='text'
                                    label={' '}
                                    field={field}
                                    form={form} placeholder={"Address"}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Address"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.address.city`}>
                            {({ field, form }) => (
                                <TextInput
                                    type='text'
                                    field={field}
                                    form={form}
                                    placeholder={"City"}
                                    label={' '}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - City"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.address.state`}>
                            {({ field, form }) => (
                                <TextInput
                                    type='text'
                                    field={field}
                                    form={form}
                                    placeholder={"State / Region"}
                                    label={' '}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - State"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.address.zipCode`}>
                            {({ field, form }) => (
                                <TextInput
                                    type='text'
                                    field={field} form={form}
                                    label={' '}
                                    placeholder={"Post code"}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Post Code"]}
                                />

                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.email`}>
                            {({ field, form }) => (
                                <TextInput
                                    required={true}
                                    type='text'
                                    label="Recipient's email address "
                                    field={field}
                                    form={form}
                                    placeholder={"Email address"}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Email Address"]}
                                />
                            )}
                        </Field>
                    </div>
                </React.Fragment>
            )
        }
    };


   



    handleAuthorizedSignatoryUpdate = (value) => {

        const { authorizedSignatories, groupCompanySelected } = this.state;
        const groupCompany = this.findGroupCompany(groupCompanySelected.id);

        _.set(groupCompany,'authorizedSignatories',_.map(groupCompany.authorizedSignatories,auth=>({...auth})))
        groupCompany.authorizedSignatories.push({ stakeholder: value });
        authorizedSignatories.push(value);

    };

    handleAuthorizedSignatoryCreate = (value, name, additionalValue, form) => {

        let { authorizedSignatories } = this.props;
        const { groupCompanySelected } = this.state;

        authorizedSignatories.push({
            id: value.id,
            label: name,
            role: 'DIRECTOR',
            groupCompanyId: groupCompanySelected.id
        });

    };

    handleOnChangeIsThereCliff = (v, form) => {
        if (!v) {
            FormikHelper.setValueInTheCorrectPosition('terms.vesting.cliffPeriod', form, null);
            FormikHelper.setValueInTheCorrectPosition('terms.vesting.isThereCliff', form, v);
        }
    };

    render() {
        const isRequired = message => value => (!!value ? undefined : message);
        const { tooltips = {}, formType } = this.props;

        return (
            <div>
                    {formType === 'uploadToEdit' ? (<div>
                        <Field name={`file`}>
                            {({ field, form }) => (
                                <ClaraUpload
                                    required={true}
                                    name={`filepond`}
                                    label='Upload your document '
                                    field={field}
                                    form={form}
                                />
                            )}
                        </Field>
                    </div>) : null}
                    <Field name='agreementName'>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='text'
                                label='Document name '
                                field={field}
                                form={form}
                                classNameInput={{root:classes.ContainerRow}}
                                placeholder={"Document name"}
                                tooltip={tooltips ? tooltips['Document Name'] : {}}
                            />
                        )}
                    </Field>

                <Line className={classes.Line} />
                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                    <Field name='terms.boardApprovingOptionsGrant.id'>
                        {({ field, form }) => (
                            <ClaraSelect
                                required={true}
                                field={field}
                                form={form}
                                label={"Board Consent Approving Option Grants"}
                                lists={this.getDocs()}
                                mode={"classic"}
                                callbacks={{
                                    createValues: this.handleGroupCompanyCreate,
                                    updateValues: this.selectGroupCompany
                                }}
                                placeholder={"Select from the list"}
                                tooltip={tooltips ? tooltips['Choose Board Approving Grant Options'] : ""}
                            />
                        )}
                    </Field>
                </div>
                {
                    this.state.groupCompanySelected ?
                        <React.Fragment>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Company Details</h1>
                                <p>Please verify or update the company's information below</p>
                            </div>
                                <Field name='company.name'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label='Company name '
                                            field={field}
                                            form={form}
                                            classNameInput={{root:classes.ContainerRow}}
                                            placeholder={"Company name"}
                                            tooltip={tooltips["Company's Name"]}
                                        />
                                    )}
                                </Field>
                            <div className={`${classes.Select}`}>
                                <Field name='company.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={true}
                                            countryField={'company.country'}
                                            returnCountryAsString={true}
                                            label={'Country of incorporation '}
                                            jurisdictionField={'company.jurisdiction'}
                                            jurisdictionLabel={'Jurisdiction '}
                                            otherJurisdictionField={'company.otherJurisdiction'}
                                            setFieldValue={form.setFieldValue}
                                            initialValues={{
                                                jurisdiction: _.get(form.values, 'company.jurisdiction', '')
                                            }}
                                            additionalStyle={'ClaraCountrySelectorModal'}
                                            tooltips={{
                                                country: tooltips["Company's Country"],
                                                jurisdiction: tooltips["Company's Jurisdiction"],
                                                other: tooltips['Other Jurisdiction']
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            {/*
                            <div className={classes.ContainerRow}>
                                <Field name='company.jurisdiction'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label='Jurisdiction: '
                                            field={field}
                                            form={form}
                                            placeholder={"Jurisdiction"}
                                            tooltip={tooltips["Company's Jurisdiction"]}
                                        />
                                    )}
                                </Field>
                            </div>
                        */}
                            
                            <div className={`${classes.Select}`}>
                                <Field name='company.registeredAddress.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={false}
                                            countryField={'company.registeredAddress.country'}
                                            label={'Registered address '}
                                            returnCountryAsString={true}
                                            setFieldValue={form.setFieldValue}
                                            additionalStyle={'ClaraCountrySelectorModal'}
                                            tooltips={{
                                                country: tooltips["Company's Registered Address - Country"]
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.registeredAddress.street'>
                                    {({ field, form }) => (
                                        <TextAreaInput
                                            type='text'
                                            label={' '}
                                            field={field}
                                            form={form}
                                            placeholder={"Address"}
                                            tooltip={tooltips["Company's Registered Address - Address"]}
                                        />
                                    )}
                                </Field>
                            </div>
                                <Field name='company.registeredAddress.city'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            classNameInput={{root:classes.ContainerRow}}
                                            placeholder={'City'}
                                            tooltip={tooltips["Company's Registered Address - City"]}
                                        />
                                    )}
                                </Field>
                                <Field name='company.registeredAddress.state'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            classNameInput={{root:classes.ContainerRow}}
                                            placeholder={'State / Region'}
                                            tooltip={tooltips["Company's Registered Address - State"]}
                                        />
                                    )}
                                </Field>
                                <Field name='company.registeredAddress.zipCode'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            classNameInput={{root:classes.ContainerRow}}
                                            form={form}
                                            placeholder={"Post code"}
                                            tooltip={tooltips["Company's Registered Address - Post Code"]}
                                        />
                                    )}
                                </Field>
                            <Field name='company.placeOfBusiness_error'>
                                    {({ field, form }) => (
                                        <React.Fragment>
                                            <span hasError={_.get(form.errors, "company.placeOfBusiness.city") || _.get(form.errors, "company.placeOfBusiness.state") || _.get(form.errors, "company.placeOfBusiness")  ? "true" : "false"}>
                                                <SelectStateCity
                                                    selectStateLabel={'State of principal office'}
                                                    selectCityLabel={'City of principal office'}
                                                    showState={true}
                                                    modal={true}
                                                    defaultState={form?.values?.company?.placeOfBusiness?.state}
                                                    tooltips={{city:tooltips["Company's City Of Principal Office"],state:tooltips["Company's State Of Principal Office"]}}
                                                    defaultCity={form?.values?.company?.placeOfBusiness?.city}
                                                    onChangeState={(value) =>{
                                                        form.setFieldValue('company.placeOfBusiness.state',value)
                                                        if(this.state.stateValue !== value){
                                                             return this.setState({
                                                            stateValue:value
                                                        })
                                                        form.setFieldValue('company.placeOfBusiness.city',null)
                                                    }
                                                    }}
                                                    onChangeCity={(value) => form.setFieldValue('company.placeOfBusiness.city',value)}
                                                    errorMessage={_.get(form.errors, "company.placeOfBusiness.city") || _.get(form.errors, "company.placeOfBusiness.state") || _.get(form.errors, "company.placeOfBusiness")  }
                                                />
                                            </span>
                                        </React.Fragment>
                                    )}
                                </Field>
                            <Line className={classes.Line}/>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Corporate Signatories</h1>
                                <p>Please add all the signatories required to sign this document on behalf of the company.</p>
                            </div>
                            <Field name='company.authorizedSignatories_error'>
                                {({ field, form }) => (
                                    <React.Fragment>
                                        <span hasError={_.get(form.errors, "company.authorizedSignatories") ? "true" : "false"}>
                                            <AuthorisedSignatories
                                            list={this.props.stakeholderList}
                                            modal={true}
                                                fieldArrayName={'company.authorizedSignatories'}
                                                idGroupCompany={this.state.groupCompanySelected.id}
                                                showTitle={true}
                                                handleAddListStakeholder={this.handleAuthorizedSignatoryUpdate}
                                                errorMessage={_.get(form.errors, "company.authorizedSignatories")}
                                            />
                                        </span>
                                    </React.Fragment>
                                )}
                            </Field>
                            <Line className={classes.Line}/>
                                                    </React.Fragment>
                        :
                        null
                }

{this.state.groupCompanySelected &&
<div className={`${classes.ContainerRow} ${classes.Select}`}>
    <Field name='participant.id'>
        {({ field, form }) => (
            <ClaraSelect
                required={true}
                field={field}
                form={form}
                label={"Select participant "}
                lists={this.state.stakeholders}
                mode={"classic"}
                callbacks={{
                    updateValues: this.selectParticipant,
                    createValues: this.handleStakeholderCreate
                }}
                placeholder={"Select from the list"}
                tooltip={tooltips ? tooltips['Select Participant'] : {}}
            />

        )}
    </Field>
</div>}
{
    this.state.participantSelected ?
        <>   
              <Line className={classes.Line}/>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Grant</h1>
                                <p>The granting of the options to the grantee.</p>
                            </div>
                            <Field name={`participant.grant.sharesNumber`}>
                                {({ field, form }) => (
                                        <TextInput
                                            required={true}
											disabled={true}
                                            type='number'
                                            label='Number of shares '
                                            field={field}
                                            classNameInput={{root:classes.ContainerRow}}
                                            form={form}
                                            placeholder={'e.g. 10, 20'}
                                            />
                                )}
                            </Field>
                            <Field name={`participant.grant.pricePerShare`}>
                                {({ field, form }) => (
                                        <TextInput
                                            required={true}
											disabled={true}
                                            type='share'
                                            label='Exercise price per share '
                                            field={field}
                                            classNameInput={{root:classes.ContainerRow}}
                                            form={form}
                                            placeholder={'Amount'}
                                            />
                                )}
                            </Field>
                            <Field name={`participant.grant.typeOption`}>
                                {({ field, form }) => {
                                    return (
                                        <div className={`${classes.ContainerRow} ${classes.Select}`}>
                                            <ClaraSelect
                                                required={true}
												disabled={true}
                                                label={'Type of option '}
                                                field={field}
                                                form={form}
                                                mode={'classic'}
                                                placeholder={'Select from the list'}
                                                tooltip={tooltips['Type Of Option']}
                                                lists={[
                                                    {
                                                        id: 'NSO',
                                                        label: 'NSO',
                                                    },
                                                    {
                                                        id: 'ISO',
                                                        label: 'ISO',
                                                    }
                                                ]}
                                                />
                                        </div>
                                    )
                                }}
                            </Field>
                                <Field name='participant.grant.dateOfExpiration'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            className={classes.inputDateEdit}
                                            field={field}
                                            form={form}
                                            tooltip={tooltips["Expiration Date"]}
                                            type={'date'}
                                            placeholder={'dd/mm/yyyy'}
                                            label={"Option expiration date"}
                                            
                                        />
                                    )}
                                </Field>
                            <Line className={classes.Line}/>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Vesting</h1>
                                <p>The vesting of the shares granted to the grantees.</p>
                            </div>
                            <Field name={`participant.vesting.vestingStartDate`}>
                                {({ field, form }) => (
                                        <TextInput
                                            required={true}
											disabled={true}
                                            type='date'
                                            className={classes.inputDateEdit}

                                            label='Vesting start date  '
                                            field={field}
                                            form={form}
                                            placeholder={'dd/mm/yyyy'}
                                            />
                                    
                                )}
                            </Field>
                            <Field name={`participant.vesting.vestingPeriod`}>
                                {({ field, form }) => (
                                        <TextInput
                                            field={field}
                                            form={form}
                                            label={'Vesting period'}
                                            required={true}
											disabled={true}
                                            classNameInput={{root:classes.ContainerRow}}
                                            rightLabel={"Months"}
                                            />
                                            )}
                            </Field>
                            <Field name={`participant.vesting.vestingFrequency`}>
                                {({ field, form }) => {
                                    return (
                                        <div className={`${classes.ContainerRow} ${classes.Select}`}>
                                            <ClaraSelect
                                                label={'Vesting frequency '}
                                                required={true}
                                                field={field}
                                                form={form}
                                                mode={'classic'}
                                                placeholder={'Select from the list'}
                                                lists={[
                                                    {
                                                        id: 'monthly',
                                                        label: 'Monthly',
                                                    },
                                                    {
                                                        id: 'quarterly',
                                                        label: 'Quarterly',
                                                    },
                                                    {
                                                        id: 'annually',
                                                        label: 'Annually',
                                                    }
                                                ]}
												disabled={true}
                                            />
                                        </div>
                                    )
                                }}
                            </Field>
                            <div className={classes.alignWithInputDateButton}>
                                <Field name={`participant.vesting.isThereCliff`}>
                                    {({ field, form }) => (
                                        <ClaraRadioWithButton
                                        required={true}
										disabled={true}
                                        label={'Is there a cliff period? '}
                                        field={field}
                                        form={form}
                                        changeParams={field.name}
                                        options={[{ value: true, label: 'Yes' }, { value: false, label: 'No' }]}
                                        />
                                        )}
                                </Field></div>
                            <Field name={`participant.vesting.cliffPeriod`}>
                                {({ field, form }) =>
                                    _.get(form, `values.participant.vesting.isThereCliff`) ?
                                    (
                                            <TextInput
                                                field={field}
                                                form={form}
                                                classNameInput={{root:classes.ContainerRow}}
                                                label={'How long is the cliff period?'}
                                                required={true}
												disabled={true}
                                                rightLabel={"Months"}
                                                />
                                                )
                                                : null}
                            </Field>
     
        </>
        :
        null
}
                
                {formType === 'uploadToEdit' ? <div>
                    <Field name='confirmSameData'>
                        {({ field, form }) => (
                            <Checkbox
                                className={classes.sameDataCheckbox}
                                field={field}
                                validate={isRequired('This field is required')}
                                form={form}
                                required={true}
                                label='All document details are verified and correct.'
                            />
                        )}
                    </Field>
                </div> : null}
            </div>

        );
    }

}

export default OptionGrantNoticeDoc;

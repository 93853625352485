import React, { FC, useRef } from 'react';
import * as Yup from 'yup';
import Form from "../../../../components/forms/Form/FormL";
import SubmitButton from "../../../generic/components/Buttons/SubmitButton";
import { CheckboxButtonOptions } from "../../../generic/components/Inputs/CheckboxButton";
import CheckboxButtonSimple from "../../../generic/components/Inputs/CheckboxButtonSimple";
import ControllerInput from "../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import TemplateFormDefault from "../../../generic/templates/Modal";
import cls from "./Form.module.scss";

interface IsUploadArticlesOfAssociationProps {
  addNoText?: boolean
  initialValues?: any
  onCompleteSubmit?: (values: any) => void
  Template?: any
  buttons?: any
  buttonSubmitProps?: any
  propsTemplate?: any
}

const schema = Yup.object().shape({
    isUpload: Yup.boolean().nullable().required('Required'),
});

const IsUploadArticlesOfAssociation: FC<IsUploadArticlesOfAssociationProps> = ({ 
  initialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate
}: IsUploadArticlesOfAssociationProps) => {
  const options: Array<CheckboxButtonOptions<boolean, string>> = [
    {
      value: false,
      label: "I want to use the model Articles of Associations"
    },
    {
      value: true,
      label: "I want to upload amended Articles of Association"
    }
  ];

  const refForm = useRef();

  return (
    <>
      <Form
        initialValues={initialValues || {}}
        schema={schema}
        onlySendDirty={false}
        onSubmit={onCompleteSubmit}
        ref={refForm} optionsForm={{ mode: "onChange" }}
      >
        <Template
          buttons={{ ...buttons, submit: <SubmitButton {...buttonSubmitProps}>Next</SubmitButton> }}
          props={propsTemplate}
        >
          <ControllerInput
            as={CheckboxButtonSimple}
            name='isUpload'
            options={options}
            className={cls.CheckboxButton}
            classNameButton={cls.buttonCheckBox}
          />
        </Template>
      </Form>
    </>
  );
}

export default IsUploadArticlesOfAssociation;
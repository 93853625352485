import React from "react";
import ACTIONS from "src/constants/actions";
import PAGES from "src/constants/pages";
import ClaraLayout from "src/layouts/Clara/ClaraLayout";
import AssistanceDocumentGeneratedV4 from "src/modules/assistanceV4/components/AssistanceFrame2/assistanceDocumentGenerated";
import AssistanceDocumentUploadedV4 from "src/modules/assistanceV4/components/AssistanceFrame2/assistanceDocumentUploaded";
import AssistanceGenerateDocumentsV4 from "src/modules/assistanceV4/components/AssistanceFrame2/assistanceGenerateDocuments";
import Text from "src/components/text/Text/Text";
import ChooseDocumentsView from "./ChooseDocuments/ChooseDocumentsView";
import { DetailsDocumentGenerated, DetailsDocumentUploaded } from "./Details";
import DocumentView from "./Documents/DocumentView";
import { RegionProvider } from './ChooseDocuments/contexts/Region'
const moduleRouter = [
  {
    path: PAGES.chooseDocumentType.path,
    exact: true,
    breadCrumbs: {
      reset: true,
      add: true,
      label: <Text uuid="BREADCRUMS_GENERATE_DOCUMENT" />,
    },
    component:<RegionProvider><ChooseDocumentsView /></RegionProvider>,
    layout: ClaraLayout,
    propsLayout: {
      startupMenu: true,
      userMenu: true,
      showBreadCrumbs: true,
      fullMenu: true,
      showAssistance: true,
      assistance: AssistanceGenerateDocumentsV4,
      linkMenu: "LinkGenerate",
    },
    action: ACTIONS.VIEW_PROFILE,
  },
  {
    path: PAGES.documents.path,
    exact: false,
    breadCrumbs: {
      reset: true,
      add: false,
      label: <Text uuid="BREADCRUMS_DOCUMENTS_LIST" />,
    },
    component: <DocumentView version="v2" />,
    layout: ClaraLayout,
    propsLayout: {
      cantBack: true,
      startupMenu: true,
      userMenu: true,
      fullMenu: true,
      showAssistance: false,
      showBreadCrumbs: false,
      linkMenu: "LinkDocuments",
    },

    action: ACTIONS.VIEW_PROFILE,
  },
  {
    path: PAGES.viewDocumentUploaded.path,
    exact: true,

    breadCrumbs: {
      reset: false,
      add: true,
      label: <Text uuid="BREADCRUMS_DOCUMENTS" params={{ fullName: "" }} />,
    },

    component: <DetailsDocumentUploaded />,
    layout: ClaraLayout,
    propsLayout: {
      cantBack: true,
      startupMenu: true,
      userMenu: true,
      fullMenu: true,
      showAssistance: true,
      scrollbar: false,
      showBreadCrumbs: true,
      disableClosedAssistance: true,
      linkMenu: "LinkDocuments",
      assistance: AssistanceDocumentUploadedV4,
    },

    action: ACTIONS.VIEW_PROFILE,
  },
  {
    path: PAGES.viewDocumentGenerated.path,
    exact: true,

    breadCrumbs: {
      reset: false,
      add: true,
      label: <Text uuid="BREADCRUMS_DOCUMENTS" params={{ fullName: "" }} />,
    },

    component: <DetailsDocumentGenerated />,
    layout: ClaraLayout,
    propsLayout: {
      cantBack: true,
      startupMenu: true,
      userMenu: true,
      fullMenu: true,
      showAssistance: true,
      showBreadCrumbs: true,
      scrollbar: false,
      disableClosedAssistance: true,
      linkMenu: "LinkDocuments",
      assistance: AssistanceDocumentGeneratedV4,
    },

    action: ACTIONS.VIEW_PROFILE,
  },
];

export default moduleRouter;

import _ from "lodash";
import GroupCompany from "src/components/modules/clara/startups/entities/classes/GroupCompany";

const useGuards = () => {
  const isCompaniesCorrect = (context) =>
    context?.ReviewSharesInIncorporatedCompanies?.accept;
  const isCompaniesNotCorrect = (context) => !isCompaniesCorrect(context);

  const isCorrectSharesInCompaniesNotIncorporated = (context) =>{
    return context?.ReviewSharesInCompaniesNotIncorporated?.accept;
  }
  const isNotCorrectSharesInCompaniesNotIncorporated = (context) =>
    !isCorrectSharesInCompaniesNotIncorporated(context);
  const isTSL = (context) => {
    const gc = new GroupCompany(context?.initialData?.groupCompany);
    return gc.isTSL();
  }
  const isNotTSL = (context) => {
    
    return !isTSL(context);
  }
  return {
    isNotTSL,
    isTSL,
    isCorrectSharesInCompaniesNotIncorporated,
    isNotCorrectSharesInCompaniesNotIncorporated,
    isCompaniesCorrect,
    isCompaniesNotCorrect,
    hasIncorporatedShares: (context) => {
      return context?.WillHaveSharesIncorporatedStep?.isThereCompaniesShares;
    },
    doesNotHaveIncorporatedShares: (context) => {
      return !context?.WillHaveSharesIncorporatedStep?.isThereCompaniesShares;
    },
    addNewCompany: (context) => {
      return _.some(
        context?.WhichCompaniesHaveSharesStep?.companies,
        (gc) => gc === "ADD"
      );
    },
    existingCompany: (context) => {
      return !_.some(
        context?.WhichCompaniesHaveSharesStep?.companies,
        (gc) => gc === "ADD"
      );
    },
    moreCompanies: (context) => {
      return context?.DoYouHaveMoreCompaniesStep?.isThereMoreCompanies;
    },
    noMoreCompanies: (context) => {
      return !context?.DoYouHaveMoreCompaniesStep?.isThereMoreCompanies;
    },
  };
};

export default useGuards;

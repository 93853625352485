import React, {Component} from "react";
import {connect} from "react-redux";
import classes from "./ClaraAddInput.module.scss"

import Tooltip from '@material-ui/core/Tooltip';
import {Constants} from "../../../utils/constants";
import _ from "lodash";
import async from "async";

class ClaraAddInput extends Component {
    state = {
        validation: {},
        label: null,
        errorMessage: null,
        showError: true,
        fields: {},
        value: [],
        vecFields: {},
        vecValues: {},
        vecIndex: 0,
        removeLabel: "",
        invalidFields: [],
        min: 0,
    };

    componentWillMount() {

        const {validation, label, name, value, fields, index, removeLabel, callbacks, isUpdate, addOptions, min, disabled} = this.props;

        if (addOptions) {
            addOptions(this.addFields, label);
        }

        let {vecFields, vecValues, vecIndex} = this.state;
        _.forEach(value, (element, i) => {
            vecFields[vecIndex] = this.props.getFields(fields, element, {}, this.updateLocalData, vecIndex, callbacks, isUpdate, disabled);
            if (!vecValues[vecIndex]) {
                vecValues[vecIndex] = {};
            }
            _.forEach(fields, (v, k) => {
                vecValues[vecIndex][v.name] = null;
                if (element[v.name]) {
                    vecValues[vecIndex][v.name] = element[v.name]
                }

            });
            vecIndex = vecIndex + 1;
        });

        this.setState({
            validation,
            label: label || "Add",
            removeLabel: removeLabel || "Remove",
            name,
            value: value || [],
            fields: fields || {},
            index,
            vecIndex,
            vecFields,
            vecValues,
            min: min || 0,
        }, function () {


        });


    }

    componentDidMount() {
        let {value, min} = this.state;
        let functionCallbacks = [];
        for (let i = 0; i < min - (value.length); i++) {
            functionCallbacks.push((cb) => {
                this.addFields(null, cb)
            });
        }
        async.series(functionCallbacks, () => {
            this.managerValidation(this.state.vecValues, null, null, null, this.state.index);
        })

    }

    componentWillUpdate() {

    }

    getError(type, validation) {
        validation = this.state.validation[type];
        if (validation.errorMessage) {
            return validation.errorMessage
        }
        return Constants.DEFAULT_MESSAGES.VALIDATION[type];
    }

    validate(invalidFields) {
        let isValid = true;
        _.forEach(invalidFields, (values, i) => {
            _.forEach(values, (field, k) => {
                if (field === false) {
                    isValid = false;
                }
            })
        });
        return isValid;
    }

    managerValidation(vecValues, key, value, isValid, index) {
        let {errorMessage, invalidFields} = this.state;
        if (!invalidFields[index]) {
            invalidFields[index] = {};
        }
        if (key) {
            if (!invalidFields[index][key]) {
                invalidFields[index][key] = null;
            }
            invalidFields[index][key] = isValid;
        }
        let isValidResult = this.validate(invalidFields);


        let values = [];

        _.forEach(vecValues, (v, k) => {
            values.push(v);
        });

        this.setState({
            value: values,
            vecValues,
            invalidFields
        });

        // this.props.updateData(this.state.name, values, isValidResult,this.props.index);
    }


    handleBlur = (e) => {

    };

    componentJSX_getTooltip() {
        if (this.state.tooltipText
        ) {
            return (
                <Tooltip title={this.state.tooltipText} aria-label={this.state.tooltipText}
                         placement="right"
                         classes={{
                             tooltip: classes.tooltip,
                             tooltipPlacementRight: classes.tooltipPlacementRight
                         }}
                >
                    <img src="/img/icons/tooltip.svg" alt="help"/>
                </Tooltip>
            )
        }
        return null;
    }

    componentJSX_getErrorMessage() {
        if (this.state.errorMessage && this.state.showError) {
            return (
                <div className='errorClaraInput'>
                    {this.state.errorMessage}
                </div>
            )
        }
        return null;
    }

    updateLocalData = (key, value, isValid, index) => {

        let {vecValues} = this.state;
        vecValues[index][key] = value;
        this.managerValidation(vecValues, key, value, isValid, index);


    };

    removefields = (index) => {
        let {vecFields, vecValues, invalidFields} = this.state;
        delete vecFields[index];
        delete vecValues[index];
        delete invalidFields[index];

        this.setState({
            vecFields,
            vecValues,
            invalidFields
        });
        this.managerValidation(vecValues, null, null, true, index);
    };

    addFields = (e, callback) => {
        let {vecFields, vecValues} = this.state;
        const components = this.props.getFields(this.state.fields, {}, {}, this.updateLocalData, this.state.vecIndex);
        vecFields[this.state.vecIndex] = components;
        vecValues[this.state.vecIndex] = {};
        _.forEach(this.state.fields, (v, k) => {
            vecValues[this.state.vecIndex][v.name] = null
        });

        this.setState({
            vecFields,
            vecIndex: this.state.vecIndex + 1,
            vecValues
        }, function () {
            if (callback) {
                callback();
            }
            this.managerValidation(vecValues, null, null, true, this.state.vecIndex);
        })
    };

    componentJSX_getFields = () => {
        let {min} = this.state;
        return (
            <div className={classes.ZoneAddFields}>
                {Object.keys(this.state.vecFields).length > 0 ? <h3>{this.state.label}</h3> : null}
                {_.map(this.state.vecFields, (fields, i) => {
                    return (

                        <div key={i} className={classes.ZoneFields}>
                            {i > (min - 1) ? <a onClick={(e) => {
                                this.removefields(i)
                            }} className={classes.Remove}>{this.state.removeLabel}</a> : null}
                            {fields.map((field, j) => {
                                let show = true;
                                if (this.props.fields[j].show) {
                                    show = this.props.fields[j].show(i, this.props.data);
                                }
                                if (show) {
                                    return field
                                }

                            })}
                        </div>
                    )
                })}
            </div>
        )
    };

    render() {
        return (
            <div className={classes.ClaraAddInput}>
                {this.componentJSX_getFields()}

                <a onClick={this.addFields} className={classes.Add}>{this.state.label}</a>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    session: state.session
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ClaraAddInput)

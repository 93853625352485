import useGetMe from "../../../hooks/services/modules/singIn/useGetMe";
import React, {useEffect, useState} from "react";
import ServerConnect from "../../../v1/utils/ServerConnect";
import classes from './LikedinButton.module.scss'
import Icon from '../../../modules/generic/components/Icon';
import Cookies from "universal-cookie";
import {useHistory} from 'react-router-dom'
const cookie=new Cookies()
const LinkedinButton=(classname=null)=>{
    const handleOnClick=()=>{
        cookie.set('initialEntry',window.location.pathname)
        ServerConnect.requestApi('/auth/linkedin',{
            method:'GET'
        }).then(response=>{
            window.location.replace(response.url)
        })
    }
return(
    <div className={classes.linkedInButton}>
    <button type='button' onClick={handleOnClick}><Icon isClara={true} size={'1rem'} icon={'linkedin-Logo'}/>Sign in with LinkedIn</button>
</div>)
}
export default LinkedinButton
import React, {useEffect, useState} from 'react';
import {ClaraCheckForm, Text} from '../index';
import _ from "lodash";
import PropTypes from 'prop-types';
import {Field} from "formik";
import classes from "./Chat.module.scss";
import FactoryChatForm from './FactoryChatForm';
import FactoryChatFormConfig from './FactoryChatFormConfig';
import useQuestionaryShareHolderDirector from '../../Hooks/useQuestionaryShareHolderDirector';

const ChatShareholderDirectors = ({ responseKeyName, isForm, initialValueStart }) => {
  const nameField = responseKeyName ? responseKeyName + "." : "";
  const [openModal, setOpenModal] = useState(false);
  const [refresh, setRefresh] = useState(1);
  const { matter } = useQuestionaryShareHolderDirector(initialValueStart.matterId, initialValueStart.draftId, refresh)
  const [formSelect, setFormSelect] = useState("INDIVIDUAL_FORM");
  const [listStateholder, setListStateholder] = useState(null);
  const [listDirector, setListDirector] = useState(null);

  const [reload, setReload] = useState(false);
  const handleClose = () => {
    setOpenModal(false)
    setReload(!reload);
  }
  const setFom = (form) => {
    setFormSelect(form);
    setOpenModal(true);
  }
  const refreshData = (data) => {
    setRefresh(refresh + 1);
  }

  const mapDirectorAndShareholders = (elements) => {
    let individuals = [];
    let companies = [];

    if(elements && (elements.individuals || elements.companies)){

      individuals = _.get(elements, "individuals", []);

      companies = _.map(_.compact(_.get(elements, "companies", [])), com => ({ id: com.id, companyName: com.companyName, ...com.signatory }));

      return _.concat(individuals, companies);
      
    } else {
      
      return elements || [];
    }
    
  }

  useEffect(() => {

    let listStateholderLocal = [];
    _.forEach(_.get(matter, "individuals", []), (individual) => {
      listStateholderLocal.push({
        type: "INDIVIDUAL_QUESTIONNAIRE_FORM",
        data: individual
      })
    });
    _.forEach(_.get(matter, "companies", []), (company) => {
      listStateholderLocal.push({
        type: "COMPANY_QUESTIONNAIRE_FORM_CORPORATE",
        data: {
          ...company,
          directors: mapDirectorAndShareholders(company.directors)
        }
      })
    });
    setListStateholder(listStateholderLocal);
  }, [matter]);

  useEffect(() => {

    let listDirectorLocal = [];
    _.forEach(_.get(matter, "individuals", []), (individual) => {
      listDirectorLocal.push({
        type: "INDIVIDUAL_QUESTIONNAIRE_FORM",
        data: individual
      })
    });
    _.forEach(_.get(matter, "companies", []), (company) => {
      if (!company.isPrimary) {
        listDirectorLocal.push({
          type: "COMPANY_QUESTIONNAIRE_FORM_CORPORATE",
          data: {
            ...company,
            directors: mapDirectorAndShareholders(company.directors)
          }
        })
      }
    });
    setListDirector(listDirectorLocal);
  }, [matter]);



  return (
    <React.Fragment>
      <div className={`${classes.ChatQuestionnaire} ${classes.ChatShareholderDirectors}`}>
        <div className={classes.contentBotonera}>
          <button className={`${classes.buttonWithAutomatic} ${classes.buttonOption} `} type="button" onClick={() => { setFom("INDIVIDUAL_QUESTIONNAIRE_FORM") }}>
            <Text uuid={`QUESTIONARY_SHAREHOLDER_DIRECTORS_BUTTON_INDIVIDUAL`} />
          </button>
          <button className={`${classes.buttonWithAutomatic} ${classes.buttonOption} `} type="button" onClick={() => { setFom("COMPANY_QUESTIONNAIRE_FORM_CORPORATE") }}>
            <Text uuid={`QUESTIONARY_SHAREHOLDER_DIRECTORS_BUTTON_COMPANY`} />
          </button>
        </div>
        <div className={classes.contentField}>
          <h4><Text uuid={"QUESTIONARY_SHAREHOLDER_DIRECTORS_TEXT_SHAREHOLDERS"} /></h4>
          {listStateholder ? (
            <Field name={nameField + "shareholders"}>
              {({ field, form }) => (
                <ClaraCheckForm
                  reload={reload}
                  openForm={openModal}
                  addForm={formSelect}
                  cbClose={handleClose}
                  field={field}
                  form={form}
                  cbSaveForm={refreshData}
                  list={listStateholder ? listStateholder : []}
                  initialValues={{
                    INDIVIDUAL_QUESTIONNAIRE_FORM: { formKey: "INDIVIDUAL_QUESTIONNAIRE_FORM" },
                    COMPANY_QUESTIONNAIRE_FORM_CORPORATE: { formKey: "COMPANY_QUESTIONNAIRE_FORM_CORPORATE" }
                  }}
                  multiForm={true}
                  optionsList={{
                    "INDIVIDUAL_QUESTIONNAIRE_FORM": {
                      fieldId: initialValueStart.draftId ? "_id" : "id",
                      fieldLabel: _.get(FactoryChatFormConfig, "INDIVIDUAL_QUESTIONNAIRE_FORM" + ".fieldName", null),
                    },
                    "COMPANY_QUESTIONNAIRE_FORM_CORPORATE": {
                      fieldId:  "id",
                      fieldLabel: _.get(FactoryChatFormConfig, "COMPANY_QUESTIONNAIRE_FORM_CORPORATE" + ".fieldName", null),
                    }
                  }}
                  optionsForm={
                    {
                      "INDIVIDUAL_QUESTIONNAIRE_FORM": {
                        titleKey: _.get(FactoryChatFormConfig, "INDIVIDUAL_QUESTIONNAIRE_FORM.titleKey", null),
                        educationId: _.get(FactoryChatFormConfig, "INDIVIDUAL_QUESTIONNAIRE_FORM.leftEducationId", null),
                        muttation: _.get(FactoryChatFormConfig, "INDIVIDUAL_QUESTIONNAIRE_FORM.muttation", null),
                        getIdFromMuttation: _.get(FactoryChatFormConfig, "INDIVIDUAL_QUESTIONNAIRE_FORM.getIdFromMuttation", null),
                        paramsMuttaion: initialValueStart,
                        getVariablesMutation: _.get(FactoryChatFormConfig, "INDIVIDUAL_QUESTIONNAIRE_FORM.getVariablesMutation", null),
                        succesNotification: "NOTIFICATION_SUCCESS",
                        errorNotification: "NOTIFICATION_BAD",
                      },
                      "COMPANY_QUESTIONNAIRE_FORM_CORPORATE": {
                        titleKey: _.get(FactoryChatFormConfig, "COMPANY_QUESTIONNAIRE_FORM_CORPORATE.titleKey", null),
                        educationId: _.get(FactoryChatFormConfig, "COMPANY_QUESTIONNAIRE_FORM_CORPORATE.leftEducationId", null),
                        muttation: _.get(FactoryChatFormConfig, "COMPANY_QUESTIONNAIRE_FORM_CORPORATE.muttation", null),
                        getIdFromMuttation: _.get(FactoryChatFormConfig, "COMPANY_QUESTIONNAIRE_FORM_CORPORATE.getIdFromMuttation", null),
                        paramsMuttaion: initialValueStart,
                        getVariablesMutation: _.get(FactoryChatFormConfig, "COMPANY_QUESTIONNAIRE_FORM_CORPORATE.getVariablesMutation", null),
                        succesNotification: "NOTIFICATION_SUCCESS",
                        errorNotification: "NOTIFICATION_BAD",
                      }
                    }
                  }
                  optionsSelect={{
                    auto: false,
                    canSelect: true
                  }}
                  optionsOperation={{
                    canAdd: false,
                    canEdit: true,
                    canRemove: false,
                  }}
                  optionsView={{
                    recordsByLine: 7
                  }}
                  className={{}}
                >
                  <FactoryChatForm type={"INDIVIDUAL_QUESTIONNAIRE_FORM"} formKey={"INDIVIDUAL_QUESTIONNAIRE_FORM"} propsForm={{ isFlow: true, draftId: initialValueStart.draftId }}></FactoryChatForm>
                  <FactoryChatForm type={"COMPANY_QUESTIONNAIRE_FORM_CORPORATE"} formKey={"COMPANY_QUESTIONNAIRE_FORM_CORPORATE"} propsForm={{ isFlow: true, draftId: initialValueStart.draftId }}></FactoryChatForm>
                </ClaraCheckForm>
              )}
            </Field>
          ) : null}
        </div>
        <div className={classes.contentField}>
          <h4><Text uuid={"QUESTIONARY_SHAREHOLDER_DIRECTORS_TEXT_DIRECTORS"} /></h4>
          {listDirector ? (
            <Field name={nameField + "directors"}>
              {({ field, form }) => (
                <React.Fragment>
                  <ClaraCheckForm
                    reload={reload}
                    cbClose={handleClose}
                    cbSaveForm={refreshData}
                    field={field}
                    form={form}
                    list={listDirector ? listDirector : []}
                    initialValues={{ formKey: formSelect }}
                    multiForm={true}
                    optionsList={{
                      "INDIVIDUAL_QUESTIONNAIRE_FORM": {
                        fieldId: initialValueStart.draftId ? "_id" : "id",
                        fieldLabel: _.get(FactoryChatFormConfig, "INDIVIDUAL_QUESTIONNAIRE_FORM" + ".fieldName", null),
                      },
                      "COMPANY_QUESTIONNAIRE_FORM_CORPORATE": {
                        fieldId:  "id",
                        fieldLabel: _.get(FactoryChatFormConfig, "COMPANY_QUESTIONNAIRE_FORM_CORPORATE" + ".fieldName", null),
                      }
                    }}
                    optionsForm={
                      {
                        "INDIVIDUAL_QUESTIONNAIRE_FORM": {
                          titleKey: _.get(FactoryChatFormConfig, "INDIVIDUAL_QUESTIONNAIRE_FORM.titleKey", null),
                          educationId: _.get(FactoryChatFormConfig, "INDIVIDUAL_QUESTIONNAIRE_FORM.leftEducationId", null),
                          muttation: _.get(FactoryChatFormConfig, "INDIVIDUAL_QUESTIONNAIRE_FORM.muttation", null),
                          getIdFromMuttation: _.get(FactoryChatFormConfig, "INDIVIDUAL_QUESTIONNAIRE_FORM.getIdFromMuttation", null),
                          paramsMuttaion: initialValueStart,
                          getVariablesMutation: _.get(FactoryChatFormConfig, "INDIVIDUAL_QUESTIONNAIRE_FORM.getVariablesMutation", null),
                          succesNotification: "NOTIFICATION_SUCCESS",
                          errorNotification: "NOTIFICATION_BAD",
                        },
                        "COMPANY_QUESTIONNAIRE_FORM_CORPORATE": {
                          titleKey: _.get(FactoryChatFormConfig, "COMPANY_QUESTIONNAIRE_FORM_CORPORATE.titleKey", null),
                          educationId: _.get(FactoryChatFormConfig, "COMPANY_QUESTIONNAIRE_FORM_CORPORATE.leftEducationId", null),
                          muttation: _.get(FactoryChatFormConfig, "COMPANY_QUESTIONNAIRE_FORM_CORPORATE.muttation", null),
                          getIdFromMuttation: _.get(FactoryChatFormConfig, "COMPANY_QUESTIONNAIRE_FORM_CORPORATE.getIdFromMuttation", null),
                          paramsMuttaion: initialValueStart,
                          getVariablesMutation: _.get(FactoryChatFormConfig, "COMPANY_QUESTIONNAIRE_FORM_CORPORATE.getVariablesMutation", null),
                          succesNotification: "NOTIFICATION_SUCCESS",
                          errorNotification: "NOTIFICATION_BAD",
                        }
                      }
                    }
                    optionsSelect={{
                      auto: false,
                      canSelect: true
                    }}
                    optionsOperation={{
                      canAdd: false,
                      canEdit: true,
                      canRemove: false,
                    }}
                    optionsView={{
                      recordsByLine: 7
                    }}
                    className={{}}
                  >
                    <FactoryChatForm type={"INDIVIDUAL_QUESTIONNAIRE_FORM"} formKey={"INDIVIDUAL_QUESTIONNAIRE_FORM"} ></FactoryChatForm>
                    <FactoryChatForm type={"COMPANY_QUESTIONNAIRE_FORM_CORPORATE"} formKey={"COMPANY_QUESTIONNAIRE_FORM_CORPORATE"} ></FactoryChatForm>
                  </ClaraCheckForm>
                </React.Fragment>
              )}
            </Field>
          ) : null}
        </div>
      </div>
    </React.Fragment >
  );
}
ChatShareholderDirectors.propTypes = {
  responseKeyName: PropTypes.string.isRequired,
  initialValueStart: PropTypes.object.isRequired,

};
export default ChatShareholderDirectors;

import get from 'lodash/get'
import { useMemo } from 'react';
const useGuards = () => {
    return useMemo(()=> ({
        isShare: (context) => {
          return get(context, 'type') === 'SHARE'
        },
        isOption: (context) => {
          return get(context, 'type') === 'OPTION'
        },
        isConvertible: (context) => {
          return get(context, 'type') === 'CONVERTIBLE'
        },
        isWarrant: (context) => {
          return get(context, 'type') === 'WARRANT'
        },
        needSelectEquity: (context) => {
          return get(context, 'selectEquityType.type')!!
        }
      }),[]);
}
export default useGuards;
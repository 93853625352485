import React from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";
const EditOptionGrant = ({ go, ...props }) => {
  const params = useParams();



  return (

    <MicroFrontend id="MF_EditOptionGrant_Content_Edit" component={"./EditOptionGrant"} mf="capTable" params={{ go, ...props, ...params }} />
  );
};

export default EditOptionGrant;

import { useMemo } from "react";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import TemplateWizardSkeletonMachine from "src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine";
import SipStep from "../Steps/SipStep";

const useSteps = () => {
  const { translate } = useTranslate();
  return useMemo(() => ({
    getInitialData: {
      render: TemplateWizardSkeletonMachine,
    },
    SipStep: {
      render: SipStep,
    },
    useUpsertSipDocument: {
      render: TemplateWizardSkeletonMachine,
      
    },
    
  }), []);
}
export default useSteps;
import classesModule from "./Scrollbar.module.scss";
import React, { useRef, useEffect, useImperativeHandle, useCallback } from "react";
import useResizeObserver from 'beautiful-react-hooks/useResizeObserver';

const Scrollbar = ({ 
  children, 
  className, 
  havePadding, 
  id, 
  scrollBottom = false, 
  scrollTop = false, 
  overflowX = true, 
  overflowY = true, 
  innerRef 
}) => {

  const scrollEndRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const DOMRect = useResizeObserver(scrollEndRef);
  let contentHeight = 0;

  const scrollTo = useCallback((value, props = {}) => {
    if (DOMRect?.height !== contentHeight) {
      const scroll = value;
      scrollContainerRef.current.scrollTo({
        top: scroll,
        behavior: 'smooth',
        ...props
      });
    }
  }, [scrollContainerRef, DOMRect?.height, contentHeight]);

  useImperativeHandle(
    innerRef,
    () => ({
      scrollTo,
      scrollHeight: scrollContainerRef.current
    }),
    [scrollTo, scrollContainerRef]
  );

  const scrollToBottom = () => {
    if (DOMRect?.height !== contentHeight) {
      const scroll = scrollContainerRef.current.scrollHeight - scrollContainerRef.current.clientHeight;
      scrollContainerRef.current.scrollTo({
        top: scroll,
        behavior: 'smooth',
      });
      contentHeight = DOMRect?.height;
    }
  }

  const scrollToTop = () => {
    scrollContainerRef.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  useEffect(() => {
    if (scrollBottom) scrollToBottom();
    if (scrollTop) scrollToTop();
  });

  return (
    <React.Fragment>
      <div
        className={`
          ${classesModule.Scrollbar}
          ${havePadding ? classesModule['paddingAdd'] : null}
          ${overflowY ? classesModule['overflow-y'] : null}
          ${overflowX ? classesModule['overflow-x'] : null}
          ${className} 
        `}
        id={id}
        ref={scrollContainerRef}
      >
        {scrollBottom && <div ref={scrollEndRef}>{children}</div>}
        {!scrollBottom && children}
      </div>
    </React.Fragment>
  );
}
export default Scrollbar;

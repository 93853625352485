

import useModal from 'src/modules/generic/hooks/useModal';


import { goToPage } from "src/store/actions/page";
import { Constants } from "src/v1/utils/constants.js";

import { useDispatch } from 'react-redux';
import { useMemo } from 'react';
import _ from "lodash"
const useFunctionTasksDocument = () => {

  const { openModal } = useModal();
  const dispatch = useDispatch();
  const dictionary = useMemo(() => ({
    TASK_GENERATE_DOCUMENT:{
        onClick: (params) => {
          goToPage(
            dispatch,
            Constants.PAGES["chooseDocumentType"]
          );
        },
    },
    TASK_DOCUMENT_DRAFT_TO_BE_SIGNED:{
      onClick: (params) => {
        const document = _.find(params?.additionalParams, (addParam) => addParam.entityType === "Document");
        goToPage(
          dispatch,
          Constants.PAGES["viewDocumentGenerated"],
          {id: document?.entityId}
        );
      },
    },
    TASK_DOCUMENT_PENDING_TO_BE_SIGNED:{
      onClick: (params) => {
        const document = _.find(params?.additionalParams, (addParam) => addParam.entityType === "Document");
        goToPage(
          dispatch,
          Constants.PAGES["viewDocumentGenerated"],
          {id: document?.entityId}
        );
      },
    }
  }), [dispatch]);
  return dictionary
}
export default useFunctionTasksDocument

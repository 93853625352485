import get from 'lodash/get'
import { useMemo } from 'react';
const useGuards = () => {
  return useMemo(() => ({
    isShare: (context) => {
      return get(context, 'type') === 'SHARE'
    },
    isOption: (context) => {
      return get(context, 'type') === 'OPTION'
    },
    isConvertible: (context) => {
      return get(context, 'type') === 'CONVERTIBLE'
    },
    isWarrant: (context) => {
      return get(context, 'type') === 'WARRANT'
    },
    continue: (context) => {
      return get(context, 'toContinue')
    },
    notContinue: (context) => {
      return !get(context, 'toContinue')
    },
    isThereConvertibles: (context) => {
      return get(context, 'equity') === 'isExist'
    },
    toCreateConvertible: (context) => {
      return get(context, 'equity') === 'toCreate'
    },

    generatedDocumentsConvertible: (context) => {
      return context.documents === 'generated'
    },
    isThereIsNoAgreement: (context) => {
      return context.documents === 'agreement'
    },
    isSip: (context) => {
      return context.documentsSIP === 'sip'
    },
    
    uploadDocumentsOptionSIP: (context) => {
      return context.documentsSIP === 'upload'
    },
    noDocumentsOptionSIP: (context) => {
      return context.documentsSIP === 'later'
    },
    generatedDocumentsOptionSIP: (context) => {
      return context.documentsSIP === 'generated'
    },
    
  
    uploadDocumentsConvertible: (context) => {
      return context.documents === 'upload'
    },
    noDocumentsConvertible: (context) => {
      return context.documents === 'later'
    },
    noMoreConvertibles: (context) => {
      return context.groupCompanies.length === 1 ? true : context.iterations === context.groupCompanies.length 
    },
    isThereMoreConvertibles: (context) => {
      return context.groupCompanies.length === 1 ? false : context.iterations !== context.groupCompanies.length 
    },
    isThereOptions: (context) => {
      return get(context, 'equity') === 'isExist'
    },
    uploadDocumentsOption: (context) => {
      return context.documents === 'upload'
    },
    noDocumentsOption: (context) => {
      return context.documents === 'later'
    },
    noMoreOptions: (context) => {
      return context.groupCompanies.length === 1 ? true : context.iterations === context.groupCompanies.length
    },
    isThereMoreOptions: (context) => {
      return context.groupCompanies.length === 1 ? false : context.iterations !== context.groupCompanies.length
    },
    generatedDocumentsOption: (context) => {
      return context.documents === 'generated'
    },
    toCreate: (context) => {
      return get(context, 'equity') === 'toCreate'
    },
    noMoreShares: (context) => {
      return context.groupCompanies.length === 1 ? true : context.iterations === context.groupCompanies.length
    },
    isThereMoreShares: (context) => {
      return context.groupCompanies.length === 1 ? false : context.iterations !== context.groupCompanies.length
    },
    uploadDocuments: (context) => {
      return context.documents === 'upload'
    },
    noDocuments: (context) => {
      return context.documents === 'later'
    },
    noMoreWarrants: (context) => {
      return context.groupCompanies.length === 1 ? true : context.iterations === context.groupCompanies.length
    },
    isThereMoreWarrant: (context) => {
      return context.groupCompanies.length === 1 ? false : context.iterations !== context.groupCompanies.length
    },
    generatedDocuments: (context) => {
      return context.documents === 'generated'
    },
    isThereDocumentsWarrant: (context) => {
      return get(context, 'isThereDocumentsConvertible')
    },
    isThereMoreWarrants: (context) => {
      return get(context, 'isThereMoreConvertibles')
    }
  }), []);
}
export default useGuards;
import { ApolloProvider } from '@apollo/client';
// import { InMemoryCache, ApolloClient } from '@apollo/client';
import React, { useMemo } from 'react';
import useSession from "./../../modules/session/hooks/useSession";
import ServerConnect from "./ServerConnect";

const ApolloProviderHelper = ({ children, ...props }) => {

  const { session: { token } } = useSession();

  const clientApollo = useMemo(() => {
    if (!token) return null;
    return ServerConnect.getUniqueApolloClient(true);
  }, [token]);

  if (!clientApollo) {
    return children
  }
  
  return (
    <React.Fragment>
      <React.Fragment>
        <ApolloProvider client={clientApollo}>
          {children}
        </ApolloProvider>
      </React.Fragment>
    </React.Fragment>
  )
}


export default ApolloProviderHelper
import { createMachine } from "xstate";

const machine = createMachine({
  id: "UploadAnnualAccounts",
  initial: "initialData",
  states: {
    initialData: {
      invoke: {
        src: "initialData",
        id: "initialData",
        onDone: [
          {
            target: "UploadAnnualAccounts",
            actions: ["setContextAfterInvoke"],
          },
        ],
      },
    },
    UploadAnnualAccounts: {
      on: {
        NEXT: {
          target: "SaveStep",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    SaveStep: {
      invoke: {
        src: "SaveStep",
        id: "SaveStep",
        onDone: [
          {
            target: "finish",
          },
        ],
        onError: [
          {
            target: "finish",
          },
        ],
      },
    },
    cancel: {
      entry: "cancelMachine",
      type: "final",
    },
    finish: {
      entry: "stopMachine",
      type: "final",
    },
  },
});

export default machine;

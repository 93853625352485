import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import _, { sumBy, uniq } from 'lodash';
import * as numeral from "numeral";
import React, { Component } from "react";
import { connect } from "react-redux";
import Avatar from "../../../../../../modules/generic/components/Avatars/AvatarLabel";
import { goToPage, goToPrevPage } from '../../../../../../store/actions/page';
import { Constants } from "../../../../../utils";
import { LeftArrowScroll, RightArrowScrollNext } from '../../components/ArrowTable';
import HeaderRole from "../../components/HeaderRole";
import classes from "./TableShares.module.scss";

let rowsIndex = [];

class TableShares extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      tableWidth: 0,
      showLeftArrow: false,
      showRightArrow: true,
      data: props.data,
      unallocatedShares: props.unallocatedShares,
      subRow: {
        founders: true,
        investors: true,
        teamMembers: true,
        groupCompanies: true,
        unallocatedSIPs: true,
        unallocatedWarrants: true,
        unallocatedShares: true,
        others: true
      },
      totals: this.calculateTotals(props).totals,
      extraTotals: this.calculateTotals(props).extraTotals,
      missing: props.data.length <= 0,
      refPapper: React.createRef(),
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      data: props.data,
      unallocatedShares: props.unallocatedShares,
      subRow: {
        founders: true,
        investors: true,
        teamMembers: true,
        groupCompanies: true,
        unallocatedSIPs: true,
        unallocatedWarrants: true,
        unallocatedShares: true,
        others: true
      },
      totals: this.calculateTotals(props).totals,
      extraTotals: this.calculateTotals(props).extraTotals,
      missing: props.data.length <= 0,
    });
  }

  componentWillMount(){
    this.updateWindowDimensions();
  }

  updateWindowDimensions= () => {
    this.setState({width: window.innerWidth});
  }

  componentDidMount() {
    rowsIndex = [];
    window.addEventListener('resize', this.updateWindowDimensions);
    const clientWidth = this.divElement?.clientWidth ?? 0;
    this.setState({ tableWidth: clientWidth });
  }

  componentDidUpdate(prevProps, prevState) {
    const clientWidth = this.divElement?.clientWidth ?? 0;
    if (prevState.tableWidth !== clientWidth) {
      this.setState({ tableWidth: clientWidth });
    }
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  handleShowLeftArrow = () => {
    this.setState({ showLeftArrow: true });
  }

  handleHideLeftArrow = () => {
    this.setState({ showLeftArrow: false });
  }

  handleShowRightArrow = () => {
    this.setState({ showRightArrow: true });
  }

  handleHideRightArrow = () => {
    this.setState({ showRightArrow: false });
  }

  calculateTotals(props) {
    let shareRows = props.data;
    let allTotals = {
      totals: [],
      extraTotals: []
    };
    shareRows = this.addTotalSecurities(shareRows);
    const founders = shareRows.filter(row => row.role === 'FOUNDER');
    const investors = shareRows.filter(row => row.role === 'INVESTOR');
    const teamMembers = shareRows.filter(row => row.role === 'TEAM_MEMBER');
    const groupCompanies = shareRows.filter(row => row.role === 'GROUPCOMPANY');
    const unallocatedSIPs = shareRows.filter(row => row.role === 'UNALLOCATED_SIP');
    const unallocatedWarrants = shareRows.filter(row => row.role === 'UNALLOCATED_WARRANT');
    const others = shareRows.filter(row => row.role === 'OTHER');
    const unallocatedShares = this.addTotalSecurities(props.unallocatedShares);
    unallocatedShares.forEach(unallocatedShare => {
      for (let share in unallocatedShare.shares) {
        allTotals = this.addTotal(
          "unallocatedShares",
          share,
          unallocatedShare,
          allTotals.totals,
          allTotals.extraTotals
        );
      }
    });
    founders.forEach(founder => {
      for (let share in founder.shares) {
        allTotals = this.addTotal(
          "founders",
          share,
          founder,
          allTotals.totals,
          allTotals.extraTotals
        );
      }
    });
    investors.forEach(investor => {
      for (let share in investor.shares) {
        allTotals = this.addTotal(
          "investors",
          share,
          investor,
          allTotals.totals,
          allTotals.extraTotals
        );
      }
    });
    teamMembers.forEach(teamMember => {
      for (let share in teamMember.shares) {
        allTotals = this.addTotal(
          "teamMembers",
          share,
          teamMember,
          allTotals.totals,
          allTotals.extraTotals
        );
      }
    });
    groupCompanies.forEach(groupCompany => {
      for (let share in groupCompany.shares) {
        allTotals = this.addTotal(
          "groupCompanies",
          share,
          groupCompany,
          allTotals.totals,
          allTotals.extraTotals
        );
      }
    });
    unallocatedSIPs.forEach(unallocatedSIP => {
      for (let share in unallocatedSIP.shares) {
        allTotals = this.addTotal(
          "unallocatedSIPs",
          share,
          unallocatedSIP,
          allTotals.totals,
          allTotals.extraTotals
        );
      }
    });
    unallocatedWarrants.forEach(unallocatedWarrant => {
      for (let share in unallocatedWarrant.shares) {
        allTotals = this.addTotal(
          "unallocatedWarrants",
          share,
          unallocatedWarrant,
          allTotals.totals,
          allTotals.extraTotals
        );
      }
    });
    others.forEach(other => {
      for (let share in other.shares) {
        allTotals = this.addTotal(
          "others",
          share,
          other,
          allTotals.totals,
          allTotals.extraTotals
        );
      }
    });
    this.props.setIssuedShares(allTotals.totals);
    return {
      totals: allTotals.totals,
      extraTotals: allTotals.extraTotals
    };
  }

  componentWillUpdate(nextProps, nextState, nextContent) {
    rowsIndex = [];
  }

  changeDisplay(key) {
    this.setState(prevState => ({
      ...prevState,
      subRow: {
        ...prevState.subRow,
        [key]: !prevState.subRow[key]
      }
    }));
  }

  cleanDuplicatedRows(shareRows) {
    let finalRows = [];
    const { unallocatedShares = [] } = this.props;
    let shares = _.concat(shareRows, unallocatedShares).map(row => row.shares);
    shares.forEach(share => {
      for (let key in share) {
        if (!finalRows.find(row => row === key)) {
          finalRows.push(key);
          if (!rowsIndex.find(row => row === key)) {
            rowsIndex.push(key);
          }
        }
      }
    });
    finalRows = this.reorderRows(finalRows);
    rowsIndex = this.reorderRows(rowsIndex);
    return finalRows;
  }

  renderRows() {
    let shareRows = this.state.data;
    let returnRows = [];
    shareRows = this.addTotalSecurities(shareRows);
    const founders = shareRows.filter(row => row.role === 'FOUNDER');
    const investors = shareRows.filter(row => row.role === 'INVESTOR');
    const teamMembers = shareRows.filter(row => row.role === 'TEAM_MEMBER');
    const groupCompanies = shareRows.filter(row => row.role === 'GROUPCOMPANY');
    const unallocatedSIPs = shareRows.filter(row => row.role === 'UNALLOCATED_SIP');
    const unallocatedWarrants = shareRows.filter(row => row.role === 'UNALLOCATED_WARRANT');
    const others = shareRows.filter(row => row.role === 'OTHER');
    this.addRowsFor(founders, returnRows, "founders");
    this.addRowsFor(investors, returnRows, "investors");
    this.addRowsFor(teamMembers, returnRows, "teamMembers");
    this.addRowsFor(groupCompanies, returnRows, "groupCompanies");
    this.addRowsFor(unallocatedSIPs, returnRows, "unallocatedSIPs");
    this.addRowsFor(unallocatedWarrants, returnRows, "unallocatedWarrants");
    this.addRowsFor(others, returnRows, "others");
    const unallocatedShares = this.addTotalSecurities(this.state.unallocatedShares);
    this.addRowsFor(unallocatedShares, returnRows, 'unallocatedShares');
    return returnRows;
  }

  addTotalSecurities(shareRows) {
    return shareRows.map(row => {
      let totalSecurities = 0;
      for (let key in row.shares) {
        totalSecurities += row.shares[key];
      }
      return {
        ...row,
        totalSecurities
      };
    });
  }

  getPageForShareholder(shareholder) {
    let page;
    let params = {};
    if (shareholder.isGroupCompany) {
      return { page: Constants.PAGES.viewGroupCompany }
    } else if (shareholder.isSIP) {
      page = Constants.PAGES.viewGroupCompany
      params = { tab: "SIPS" }
    }
    else {
      if (shareholder.isAnEntity) {
        page = Constants.PAGES.viewStakeholderCompany
      } else {
        page = Constants.PAGES.viewStakeholderIndividual
      }
    }
    return { page, params };
  }

  openShareholderDetails(shareholder) {
    const { page, params } = this.getPageForShareholder(shareholder);
    let isMAnual = shareholder.document ? true : false
      if(!isMAnual)
        this.props.goToPage(page, { id: shareholder.id, tab: "details", ...params });
      else if(isMAnual){
        this.props.goToPage(Constants.PAGES.viewDocumentGenerated, { id: shareholder.document });
      }
  }

  addRowsFor(shareHolders, returnRows, key) {
    const role = _.upperFirst(key)
    if (shareHolders.length) {
      returnRows.push(
        <TableRow
          key={key}
          className={`${classes.ShareholdersHeader} ${classes.TableCell}`}
        >
          <TableCell align='left' className={classes.TableCell}>
            <HeaderRole
              role={role}
              onClick={() => this.changeDisplay(key)}
            />
          </TableCell>
          {this.renderRowsTotals(key)}
        </TableRow>
      );
      shareHolders.forEach(shareHolder => {
        returnRows.push(
          <TableRow
            validate={true}
            key={JSON.stringify(shareHolder) + "shares"}
            className={
              this.state.subRow[key] ? classes.TableCell : classes.HideRow
            }
          >
            <TableCell
              align='left'
              onClick={() => this.openShareholderDetails(shareHolder)}
              className={`${classes.TableCell} ${classes.Clickable}`}
            >
              <Avatar link avatar={shareHolder.avatar} name={shareHolder.label} pointerLabel={true} />
            </TableCell>
            <TableCell
              align='right'
              className={`${classes.TableCell} ${classes.Clickable}`}
            >
              {numeral(shareHolder.totalSecurities).format("0,0.00")}
            </TableCell>
            <TableCell
              align='right'
              className={`${classes.TableCell} ${classes.Clickable}`}
            >
              {numeral(shareHolder.fd).format("0.00") + "%"}
            </TableCell>
            {this.renderSharesInRow(shareHolder, key)}
          </TableRow>
        );
      });
    }
  }

  addTotal(key, shareName, shareholder, totals, extraTotals) {
    const newTotals = totals;
    const newExtraTotals = extraTotals;
    let total = newTotals.find(
      total => total.key === key && total.shareName === shareName
    );
    const index = newTotals.indexOf(total);
    let totalSecuritiesTotal = extraTotals.find(
      extraTotal =>
        extraTotal.key === key && extraTotal.name === "totalSecurities"
    );
    const tsIndex = extraTotals.indexOf(totalSecuritiesTotal);
    let fdTotals = extraTotals.find(
      extraTotal => extraTotal.key === key && extraTotal.name === "fd"
    );
    const fdIndex = extraTotals.indexOf(fdTotals);
    if (tsIndex >= 0) {
      if (
        !totalSecuritiesTotal.shareholders.find(sh => sh === shareholder.id) || shareholder.isSIP
      ) {
        newExtraTotals[tsIndex] = {
          ...totalSecuritiesTotal,
          value: totalSecuritiesTotal.value + shareholder.totalSecurities,
          shareholders: [...totalSecuritiesTotal.shareholders, shareholder.id]
        };
      }
    } else {
      newExtraTotals.push({
        key,
        name: "totalSecurities",
        value: shareholder.totalSecurities,
        shareholders: [shareholder.id]
      });
    }
    if (fdIndex >= 0) {
      if (
        !totalSecuritiesTotal.shareholders.find(sh => sh === shareholder.id) || shareholder.isSIP
      ) {
        newExtraTotals[fdIndex] = {
          ...fdTotals,
          value: fdTotals.value + shareholder.fd,
          shareholders: [...fdTotals.shareholders, shareholder.id]
        };
      }
    } else {
      newExtraTotals.push({
        key,
        name: "fd",
        value: shareholder.fd,
        shareholders: [shareholder.id]
      });
    }
    if (index >= 0) {
      total = {
        key: total.key,
        shareName: total.shareName,
        share: total.share + shareholder.shares[shareName]
      };
      newTotals[index] = total;
    } else {
      newTotals.push({
        key,
        shareName,
        share: shareholder.shares[shareName]
      });
    }
    return {
      totals: newTotals,
      extraTotals: newExtraTotals
    };
  }

  renderRowsTotals(key) {
    const cells = [];
    const totals = this.state.totals;
    const totalSecurities = this.state.extraTotals.find(
      extraTotals =>
        extraTotals.key === key && extraTotals.name === "totalSecurities"
    );
    const totalFd = this.state.extraTotals.find(
      extraTotals => extraTotals.key === key && extraTotals.name === "fd"
    );
    this.fillArrayWithEmptyCells(cells, 2);
    totals.forEach(total => {
      if (total.key === key) {
        const index = rowsIndex.indexOf(total.shareName);
        // Plus 2 because of the 'total securities' and the 'fd' columns
        cells[index + 2] = (
          <TableCell
            key={index + 2}
            align='right'
            className={`${classes.TableCell} ${classes.TotalsConvertiblesTableCell
              }`}
          >
            {numeral(total.share).format("0,0.00")}
          </TableCell>
        );
      }
    });
    if (totalSecurities)
      cells[0] = (
        <TableCell
          key={0}
          align='right'
          className={`${classes.TableCell} ${classes.TotalsConvertiblesTableCell
            }`}
        >
          {numeral(totalSecurities.value).format("0,0.00")}
        </TableCell>
      );
    if (totalFd)
      cells[1] = (
        <TableCell
          key={1}
          align='right'
          className={`${classes.TableCell} ${classes.TotalsConvertiblesTableCell
            }`}
        >
          {numeral(totalFd.value).format("0.00") + "%"}
        </TableCell>
      );
    return cells;
  }

  renderEmptyCells(plus = 0) {
    const array = [];
    this.fillArrayWithEmptyCells(array, plus);
    return array;
  }

  fillArrayWithEmptyCells(array, plus = 0) {
    for (let i = 0; i < rowsIndex.length + plus; i++) {
      array[i] = (
        <TableCell key={i} align='right' className={classes.TableCell}>0</TableCell>
      );
    }
  }

  renderSharesInRow(row, key) {
    let headRows = [];
    // Filling the array with empty cells
    this.fillArrayWithEmptyCells(headRows);
    // Replacing the values in the correct position of the array
    for (let share in row.shares) {
      const position = rowsIndex.indexOf(share);
      headRows[position] = (
        <TableCell
          key={position} align='right'
          className={`${classes.TableCell} ${classes.Clickable}`}
        >
          {numeral(row.shares[share]).format("0,0.00")}
        </TableCell>
      );
    }
    return headRows;
  }

  reorderRows(rows) {
    const options = rows.filter(row => row.includes('Options'));
    const shares = rows.filter(row => row.includes('Shares'));
    return [...shares, ...options];
  }

  renderHeadRows() {
    let shareRows = this.state.data;
    let rows = this.cleanDuplicatedRows(shareRows);
    rows = this.reorderRows(rows);
    this.props.setRowsCount(rows.length);
    return rows.map((row, i) => (
      <TableCell
        key={i}
        align='right'
        className={classes.TitleCell}
      >
        <div className={classes.LastRow}>
          {row}
        </div>
      </TableCell>
    ));
  }

  renderTotalRow() {
    const totals = this.state.totals;
    const tableCells = [];
    const sharesClass = uniq(totals.map(total => total.shareName));
    const totalSecurities = totals.reduce((subTotal, row) => (row.share || 0) + subTotal, 0);
    this.fillArrayWithEmptyCells(tableCells);
    tableCells[0] = (
      <TableCell
        align='left'
        className={`${classes.TableCell}`}
      >
        Totals
      </TableCell>
    );
    tableCells[1] = (
      <TableCell
        align='right'
        className={`${classes.TableCell}`}
      >
        {numeral(totalSecurities).format("0,0.00")}
      </TableCell>
    );
    tableCells[2] = (
      <TableCell
        align='right'
        className={`${classes.TableCell}`}
      >
        100%
      </TableCell>
    );
    sharesClass.forEach(sh => {
      const index = rowsIndex.indexOf(sh);
      let totalSh = sumBy(totals, total => {
        if (total.shareName === sh) return total.share;
      })
      tableCells[index + 3] = (
        <TableCell
          align='right'
          className={`${classes.TableCell}`}
        >
          {numeral(totalSh).format("0,0.00")}
        </TableCell>
      );
    });
    return (
      <TableRow className={`${classes.TableCell} ${classes.TotalRow}`}>
        {tableCells}
      </TableRow>
    );
  }

  onNextScroll = () => {
    const widthScroll = this.state.refPapper.current.scrollLeft;
    this.state.refPapper.current.scrollLeft = widthScroll + 100;
  }

  onPrevScroll = () => {
    const widthScroll = this.state.refPapper.current.scrollLeft;
    this.state.refPapper.current.scrollLeft = widthScroll - 100;
  }

  render() {
    if (!this.state.missing) {
      const rowsCells = this.renderHeadRows();
      const needShow = this.state.tableWidth > this.state.width;
      const countScroll = this.state.tableWidth - this.state.width;
      return (
        <div className={classes.TableShares}>
          <Paper className={classes.Paper} ref={this.state.refPapper} id='sharesTable'>
            {(needShow && this.state.showLeftArrow) && (
              <>
                <LeftArrowScroll 
                  containerRef={this.state.refPapper}
                  className={classes.prevButton}
                  handleShowRightArrow={this.handleShowRightArrow}
                  handleHideLeftArrow={this.handleHideLeftArrow}
                  countScroll={countScroll}
                />
              </>
            )}
            {(needShow && this.state.showRightArrow) && (
              <>
                <RightArrowScrollNext 
                  containerRef={this.state.refPapper}
                  className={classes.nextButton}
                  handleShowLeftArrow={this.handleShowLeftArrow}
                  handleHideRightArrow={this.handleHideRightArrow}
                  countScroll={countScroll}
                />
              </>
            )}
            <Table className={classes.Table} ref={ (divElement) => { this.divElement = divElement } }>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.TitleCell} >
                    <div className={classes.LastRow}></div>
                  </TableCell>
                  <TableCell align='right' className={classes.TitleCell}>
                    <div className={classes.LastRow}>
                      Total Securities
                    </div>
                  </TableCell>
                  <TableCell align='right' className={classes.TitleCell}>
                    <div className={classes.LastRow}>
                      FD%
                    </div>
                  </TableCell>
                  {rowsCells}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.renderRows()}
                {this.renderTotalRow()}
              </TableBody>
            </Table>
          </Paper>
        </div>
      );
    } else {
      return (
        <div className={classes.MissingShares}>
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M12 23.001C18.0751 23.001 23 18.0761 23 12.001C23 5.92584 18.0751 1.00098 12 1.00098C5.92487 1.00098 1 5.92584 1 12.001C1 18.0761 5.92487 23.001 12 23.001Z'
              stroke='#A9B0BC'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
            <path
              d='M14.5 17.005H13C12.4477 17.005 12 16.5573 12 16.005V9.505C12 9.22886 11.7761 9.005 11.5 9.005H10'
              stroke='#A9B0BC'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
            <path
              d='M11.7451 6.5C11.607 6.5 11.4951 6.61193 11.4951 6.75C11.4951 6.88807 11.607 7 11.7451 7C11.8832 7 11.9951 6.88807 11.9951 6.75C11.9951 6.61193 11.8832 6.5 11.7451 6.5'
              stroke='#A9B0BC'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
          <p>There are no shares</p>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  session: state.session,
});

const mapDispatchToProps = (dispatch) => ({
  goToPage: (page, params) => {
    goToPage(dispatch, page, params)
  },
  goToPrevPage: (reload, defaultPage, params) => {
    goToPrevPage(dispatch, reload, defaultPage, params)
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TableShares)

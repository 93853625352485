import _ from "lodash";
import Mustache from 'mustache';
import React, { Component } from "react";
import { connect } from "react-redux";
import { Loading } from "../../../components";
import EditForm from "../../../components/Form/ClaraForm/EditForm";
import EditDocument from "../../../components/Forms/Edit/Document";
import ViewClient from "../../../components/Forms/View/IncorporationCompany/Client";
import ViewDocument from "../../../components/Forms/View/IncorporationCompany/Document";
import EditScoping from "../../../components/Forms/View/IncorporationCompany/editScoping";
import IncorporationPortal from '../../../components/Forms/View/IncorporationCompany/IncorporationPortal/IncorporationPortal';
import ViewKYC from "../../../components/Forms/View/IncorporationCompany/Kyc";
import Notes from "../../../components/Forms/View/IncorporationCompany/Notes";
import ViewQuestionnaire from "../../../components/Forms/View/IncorporationCompany/Questionnaire";
import ViewScoping from "../../../components/Forms/View/IncorporationCompany/Scoping";
import { query } from '../../../graphql/mutations/matters';
import { Constants } from '../../../utils/constants';
import ServerConnect from '../../../utils/ServerConnect';
import MattersLogs from '../../TabsView/MattersLogs/MattersLogs';



class EditMatter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      refresh: false,
      showLoading: false,
      wasUpdated: false,
      agents: []
    }
  }

  getTitleEditForm = () => {
    return this.state.data ? this.state.data.clientFullName + " - " + this.state.data.sequenceNumber : "";
  };

  callServerToGetAgents = () => {
    const agentQuery = ` {
      getAgents(matterId: "{{matterId}}", roles: [SERVICE_AGENT_MANAGER, SERVICE_AGENT, SENIOR_SERVICE_AGENT]){
      _id
       fullName
      email
          }
      }`
    return new Promise((resolve, reject) => {
      const getAgentsQuery = Mustache.render(agentQuery, { roles: ['SERVICE_AGENT'] })
      ServerConnect.graphQlQuery(getAgentsQuery).then(result => {
        let agents = {
          ...result
        }
        resolve(agents);
      })
    });
  };




  callServerToGetData = () => {
    return new Promise((resolve, reject) => {
      const query_params = {
        matterId: this.props.params.id
      };
      const queryExecute = Mustache.render(query, query_params);
      ServerConnect.graphQlQuery(queryExecute).then(result => {
        let data = {
          ...result.getMattersList
        }
        resolve(data);
      });
    })
  };

  updateData = (data = null) => {
    this.setState({
      showLoading: true
    });

    if (data) {
      console.log({ ...this.state.data, ...data });
      this.setState({
        data: { ...this.state.data, ...data },
        showLoading: false
      })
    } else {

      Promise.resolve(this.callServerToGetData())
        .then((result) => {
          this.setState({
            data: result[0],
            refresh: !this.state.refresh,
            showLoading: false,
          })
        })
    }
  }

  mapAgentList = (agents) => {
    let agentsList = [];
    _.forEach(agents, agent => {
      agentsList.push({ label: agent.fullName, id: agent._id })
      return agent
    })
    return agentsList
  }

  componentDidMount() {
    this.setState({
      showLoading: true
    });

    Promise.resolve(this.callServerToGetData())
      .then((result) => {
        Promise.resolve(this.callServerToGetAgents())
          .then((result) => {
            this.setState({ agents: this.mapAgentList(result.getAgents), showLoading: false })
          })
        this.setState({
          data: result[0],
          refresh: !this.state.refresh,
        })
      })

    ServerConnect.requestApi(
      `/contentful/tooltips/${Constants.CONTENTFUL.ENTRIES.FORMS.GROUPCOMPANY.TOOLTIP}`,
      {
        method: 'GET', headers: { "Content-Type": "application/json" },
      }).then(res => {
        // console.log('response', res.response);
        this.setState({
          tooltips: res.response
        })
      }).catch(err => {
        console.log('Tooltips from contentful error: ', err);
      });
  }

  handleShowLoading = (value) => {
    this.setState({
      showLoading: value
    });
  }

  handleQuestionnaireComplete = () => {
    return _.get(this.state.data)
  }



  render() {
    let { mode } = this.props;
    let { data, groupCompanies, wasUpdated } = this.state;

    return (
      <Loading show={this.state.showLoading}>
        <EditForm
          formType={Constants.FORMS.GROUP_COMPANIES}
          accessDelete={Constants.ACTIONS.DELETE_MATTER}
          tab={this.props.params.tab}
          title={this.getTitleEditForm()} mode={mode} data={data} wasUpdated={wasUpdated}
          pageBackButton={Constants.PAGES.switchMatters}
        >
          <EditForm.Container tab={{ name: "Client", key: "client" }}>
            <EditForm.View isView={true}>
              <ViewClient data={data} user={this.props.session.user} agents={this.state.agents} updateData={this.updateData} />
            </EditForm.View>
            <EditForm.Edit>
              {/* TODO: Edit Client eliminado */}
              <div>Empty</div>
            </EditForm.Edit>
          </EditForm.Container>
          <EditForm.Container tab={{ name: "Scoping", key: "scoping" }} disabled={true}>
            <EditForm.View isView={true}>
              <ViewScoping
                data={data}
                refreshData={this.refresData}
                updateForm={this.transformFinishData} goTo={this.props.goTo}
                showLoading={this.handleShowLoading}
                user={this.props.session.user}
              />
            </EditForm.View>
            <EditForm.Edit>
              <EditScoping
                updateData={this.updateData}
                tooltips={this.state.tooltips}
                id={this.props.params.id}
                data={data}
                updateForm={this.transformFinishData}
              />
            </EditForm.Edit>
          </EditForm.Container>
          {_.get(data, 'KYC', null) ?
            <EditForm.Container tab={{ name: "KYC", key: "kyc" }}>
              <EditForm.View isView={true}>
                <ViewKYC
                  data={data}
                  refreshData={this.refresData}
                  updateForm={this.transformFinishData} goTo={this.props.goTo}
                  showLoading={this.handleShowLoading}
                  user={this.props.session.user}
                />
              </EditForm.View>
              <EditForm.Edit>
                <ViewKYC
                  tooltips={this.state.tooltips}
                  id={this.props.params.id}
                  data={data.KYC}
                  updateForm={this.transformFinishData}
                />
              </EditForm.Edit>
            </EditForm.Container> : null
          }

          {_.get(data, 'questionnaire') &&
            <EditForm.Container tab={{ name: "Questionnaire", key: "questionnaire" }}>
              <EditForm.View isView={true}>
                <ViewQuestionnaire
                  data={data}
                  fullData={data}
                  refreshData={this.refresData}
                  updateForm={this.transformFinishData}
				  goTo={this.props.goTo}
                  showLoading={this.handleShowLoading}
                  user={this.props.session.user}
                />
              </EditForm.View>

              <EditForm.Edit>
                <ViewQuestionnaire
                  tooltips={this.state.tooltips}
                  id={this.props.params.id}
                  data={data}
                  updateForm={this.transformFinishData}
                />
              </EditForm.Edit>
            </EditForm.Container>
          }
          {[Constants.PROFILE_ROLES.SERVICE_AGENT, Constants.PROFILE_ROLES.SERVICE_AGENT_MANAGER, Constants.PROFILE_ROLES.SENIOR_SERVICE_AGENT].includes(this.props.session.user.userRole) ?
            <EditForm.Container view={false} tab={{ name: "Incorporation", key: "incorporation" }}>
              <EditForm.View isView={true} >
                <IncorporationPortal data={data} />
              </EditForm.View>
            </EditForm.Container> : null
          }
          {[Constants.PROFILE_ROLES.SERVICE_AGENT, Constants.PROFILE_ROLES.SERVICE_AGENT_MANAGER, Constants.PROFILE_ROLES.SENIOR_SERVICE_AGENT].includes(this.props.session.user.userRole) ?
            <EditForm.Container tab={{ name: "Notes", key: "notes" }}>
              <EditForm.View isView={true}>
                <Notes
                  data={data}
                  title={"Documents"}
                  updateForm={this.transformFinishData}
                  accessToAdd={Constants.ACTIONS.EDIT_PROFILE}
                />
              </EditForm.View>
            </EditForm.Container> : null
          }
          <EditForm.Container tab={{ name: "Documents", key: "documents" }}>
            <EditForm.View isView={true}>
              <ViewDocument
                data={data}
                title={"Documents"}
                updateForm={this.transformFinishData}
                accessToAdd={Constants.ACTIONS.EDIT_PROFILE}
              />
            </EditForm.View>
            <EditForm.Edit>
              <EditDocument
                data={data}
                updateForm={this.transformFinishData}
                documentTypes={Constants.DOCUMENTS_TYPES.GROUPCOMPANY}
              />
            </EditForm.Edit>
          </EditForm.Container>
          {[Constants.PROFILE_ROLES.SERVICE_AGENT, Constants.PROFILE_ROLES.SERVICE_AGENT_MANAGER, Constants.PROFILE_ROLES.SENIOR_SERVICE_AGENT].includes(this.props.session.user.userRole) &&
            <EditForm.Container tab={{ name: "History", key: "history" }}>
              <EditForm.View isView={true}>
                <MattersLogs
                  data={data}
                  title={`History Matter`}
                  updateForm={this.transformFinishData}
                  accessToAdd={Constants.ACTIONS.EDIT_PROFILE}
                />
              </EditForm.View>
            </EditForm.Container>

          }
        </EditForm>
      </Loading>
    )
  }
}

const mapStateToProps = state => ({
  session: state.session,

});
const mapDispatchToProps = (dispatch) => ({

 
});
export default connect(mapStateToProps, mapDispatchToProps)(EditMatter)

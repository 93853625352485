import React from 'react';
import { TimelineProvider } from './../Timeline/Context/timelineContext'
import MapContent from './MapContent'

const Map = ({
  ...props
}) => {

  return (
    <React.Fragment>
      <TimelineProvider>
        <MapContent  {...props} />
      </TimelineProvider>
    </React.Fragment >
  )
};

export default Map;

import React, {useEffect, useState} from 'react';
import {ClaraRadioWithButton, ClaraUpload, Text} from '../index';
import _ from "lodash";
import PropTypes from 'prop-types';
import {Field} from "formik";
import classes from "./Chat.module.scss";

const ChatArticlesAssociation = ({ responseKeyName,isForm,initialValueStart }) => {
  const nameField = responseKeyName ? responseKeyName + "." : "";
  const [optionsButtons, setOptionsButtons] = useState([
    { 'label': 'TEXT_YES', 'value': true },
    { 'label': 'TEXT_NO', 'value': false     }
  ])
  const [optionsUploadButtons, setOptionsUploadButtons] = useState([
    { 'label': 'TEXT_YES', 'value': true },
    { 'label': 'TEXT_LATER', 'value': false }
  ])
  const clearUpload = (v,form) =>{
    let values =form.values;
    values = _.set(values, nameField + "file", null);
    values = _.set(values, nameField + "likeUpload", null);
    values = _.set(values, nameField + "articleAsociation", v);
    //TODO: change this method, have problem with time to refresh
    setTimeout(()=>{
      form.setValues(values);
    },100);
    

  }
  useEffect(() => {

  })
  return (
    <React.Fragment>
      <div className={`${classes.ChatQuestionnaire} ${classes.ChatArticlesAssociation}`}>
        <Field name={nameField}>
          {({ field, form }) => (
            <React.Fragment>
              <div className={classes.contentField}>
                <Field name={nameField+"articleAsociation"}>
                  {({ field, form }) => (
                    <React.Fragment>
                      <ClaraRadioWithButton
                        label=""
                        field={field}
                        form={form}
                        changeParams={field.name}
                        options={optionsButtons}
                        className={{
                          root: classes.ChatClaraRadioButton,
                          input: classes.ChatClaraRadioButtonInput
                        }}
                        onChange={(v) => { clearUpload(v,form) }}
                      />
                    </React.Fragment>
                  )}
                </Field>
              </div>
              {_.get(form, "values." + nameField + "articleAsociation", null) === false ? (
                <React.Fragment>
                  <h3><Text uuid={"QUESTIONARY_ARTICLES_ASSOCIATION_QUESTION_UPLOAD"} /></h3>
                  <div className={classes.contentField}>
                    <Field name={nameField+ "likeUpload"}>
                      {({ field, form }) => (
                        <React.Fragment>
                          <ClaraRadioWithButton
                            label=""
                            field={field}
                            form={form}
                            changeParams={field.name}
                            options={optionsUploadButtons}
                            className={{
                              root: classes.ChatClaraRadioButton,
                              input: classes.ChatClaraRadioButtonInput
                            }}
                          />
                        </React.Fragment>
                      )}
                    </Field>
                  </div>
                  {_.get(form, "values." + nameField + "likeUpload", null) === true ? (
                    <div className={classes.contentField}>
                      <Field name={nameField + "file"}>
                        {({ field, form }) => (
                          <ClaraUpload
                            showRedStar={false}
                            required={true}
                            name={`filepond`}
                            field={field}
                            form={form}
                            className={classes.ChatClaraUpload}
                          />
                        )}
                      </Field>
                    </div>
                  ) : null}
                </React.Fragment>
              ) : null}
            </React.Fragment>
          )}
        </Field>
      </div>
    </React.Fragment >
  );
}
ChatArticlesAssociation.propTypes = {
  responseKeyName: PropTypes.string.isRequired,
  initialValueStart: PropTypes.object.isRequired, 
  
};
export default ChatArticlesAssociation;

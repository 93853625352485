import _ from 'lodash';

const useMapperStakeholderDetails = () => {
  const getInitialData = (stakeholder) => {
    const sourceOfFunds = stakeholder.sourcesOfFunds.find(s => s.jurisdictionType === 'ADGM')
    return {
      stakeholder,
      authorizedSignatory: {
        ...stakeholder.authorizedSignatory,
        stakeholder: (stakeholder?.directors || []).find(s => s.id === stakeholder.authorizedSignatory.stakeholder)
      },
      PEP: {
        isPEP: stakeholder?.pep?.isPEP || stakeholder?.pep?.isFamilyMemberOfAPEP || stakeholder?.pep?.isCloseAssociateOfAPEP,
        descriptionOfPEP: stakeholder?.pep?.descriptionOfPEP || stakeholder?.pep?.familyMemberOfAPEPDescription || stakeholder?.pep?.closeAssociateOfAPEP
      },
      sourceOfFunds,
      groupCompany: stakeholder.managedByClaraConnections.find(g => g.jurisdictionType === 'ADGM' && g.statusOnMatters.isRenewal),
    }
  }

  const getSubmitData = (context) => {
    const { ReviewDetails, ReviewAddress, ReviewSignatory, ReviewSourceOfFunds, ReviewPEP, initialData } = context;
    let newReviewDetails = ReviewDetails;
    if (ReviewDetails?.jurisdiction === "OTHER") {
      const newObj = _.omit(ReviewDetails, 'otherJurisdiction');
      newReviewDetails = {
        ...newObj,
        jurisdiction: ReviewDetails.otherJurisdiction
      };
    }
    return {
      stakeholder: {
        ...newReviewDetails,
        ...ReviewAddress,
        pep: {
          ...ReviewPEP,
        },
        authorizedSignatory: {
          ...ReviewSignatory,
          fullName: ReviewSignatory.stakeholder.fullName,
          stakeholder: ReviewSignatory.stakeholder.id
        },
        sourcesOfFunds: [{ ...initialData.sourceOfFunds, ...ReviewSourceOfFunds }]
      },
    }
  }

  return { getInitialData, getSubmitData };
}
export default useMapperStakeholderDetails;
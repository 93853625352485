import Button from "src/modules/generic/components/Buttons/Button";
import CompanyDirectorsModal from "src/modules/startup/Stakeholder/EntityStructures/ADGM/LimitedByShares/CompanyDirectorsModal";
import { Constants } from "src/v1/utils";
import DetailsTabTitle from "src/components/templates/DetailsTabTitle";
import Divider from '@mui/material/Divider';
import PropTypes from "prop-types";
import React from 'react';
import ReactAvatar from "react-avatar";
import { RoleAccess } from "src/v1/components";
import _ from "lodash";
import classes from '../styles.module.scss';
import { useDispatch } from "react-redux";
import useModal from "src/modules/generic/hooks/useModal";
import useTranslate from "src/modules/generic/hooks/useTranslate";

function DirectorsPanel ({ data, isLock }) {
  const directosData = _.get(data, "directors")
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const { openModal, closeModal } = useModal();

  const goTo = (value) => {
    dispatch({
      type: "GO_TO",
      page: value.isAnEntity ? Constants.PAGES.viewStakeholderCompany : Constants.PAGES.viewStakeholderIndividual,
      params: { id: value.id, tab: "details" },
    });
  };

  const DirectorButton = <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE} params={undefined}>
    <Button
      variant='card'
      disabled={isLock}
      children={translate(
        'VIEW_COMPANY_TAB_BUTTON_DETAILS_EDIT'
      )}
      onClick={() => {
        openModal(CompanyDirectorsModal, {
          handleCloseModal: closeModal,
          initialValues: {
          stakeholderId: data?.id,
          stakeholderName: data?.fullName ?? '',
          directors: _.get(data, 'directors', [])
        }
      })
      }}
    />
  </RoleAccess>;


  return (
    <>
      <Divider className={classes.dividerTop}/>
      <DetailsTabTitle title={"Directors"} buttons={DirectorButton} />
      <div className={classes.containerTab}>
        {directosData?.map((director) => {
          return (
            <div
              className={classes.containerComponent}
              onClick={() => goTo(director)}
              key={'director'}
            >
              <ReactAvatar
                name={director?.fullName}
                round={true}
                size={"3.5rem"}
                src={director?.avatar}
              />
              <p>{director?.fullName}</p>
            </div>
          );
        })}
      </div>
    </>
  );
}

DirectorsPanel.propTypes = {
  data: PropTypes.any,
  isLock: PropTypes.bool,
}

export default DirectorsPanel;
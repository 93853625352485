import React, { useState, useEffect, useCallback, useRef } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import ToolbarTop from './ToolbarTop';
import ToolbarRight from './ToolbarRight';
import classes from './PDFViewer.module.scss';
import Scrollbar from "../../../Scrollbar/Scrollbar";
import { Waypoint } from 'react-waypoint';
import _ from "lodash"

export function PDFViewerAllPages(props) {

  const { pdf, onLoad } = props;
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    onLoad && onLoad();
  }

  function onDocumentLoadError() {
    onLoad && onLoad();
  }

  return (
    <div className={classes.PDFContainerAllPages}>
      <Scrollbar className={`${classes.pageScrollPadding}`} havePadding={undefined} id={undefined}>
        <Document
          file={pdf}
          options={{ workerSrc: "/pdf.worker.js" }}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentLoadError}
          loading={<div></div>}
          error={<div className={classes.errorWarning}>Failed to load PDF file.</div>}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <>
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                scale={0.4}
                className={`${classes.pageSeparator}`}
              />
              <div className={classes.divSpacer}></div>
            </>
          ))}
        </Document>
      </Scrollbar>
    </div>
  );
}
export function PDFViewerAllPagesToolbars({ pdf, onLoad, defaultScale = 1.2 }) {

  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(defaultScale);
  const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page
  const [countPagesLoaders, setCountPagesLoaders] = useState(0);
  const scrollRef = useRef();

  function onDocumentLoadSuccess({ numPages }) {
    setCountPagesLoaders(0);
    setNumPages(numPages);
    setPageNumber(1)
    updatePageNumberWithScroll(1)
    onLoad && onLoad();
  }

  function onDocumentLoadError() {
    onLoad && onLoad();
  }
  
  const handleEnter = useCallback((e, index) => {
    if (countPagesLoaders===numPages) {
      setPageNumber(index + 1)
    }else{
      setCountPagesLoaders((a)=>a+1);
    }
  }, [countPagesLoaders,setCountPagesLoaders,numPages])

  const updatePageNumberWithScroll = useCallback((page, position) => {
    if (scrollRef) {
      const height = _.get(scrollRef, "current.scrollHeight.children[0].scrollHeight", 0);
      if (height > 0 && numPages > 0) {
        const top = parseInt((height / numPages) * (page - 1))
        scrollRef.current.scrollTo(top);
      }
    }
    setPageNumber(page)
  }, [scrollRef, numPages])

  return (
    <div className={classes.PDFViewer}>
      <Scrollbar className={`${classes.scroll}`} havePadding={undefined} id={undefined} innerRef={scrollRef}>
        <Document
          file={pdf}
          options={{ workerSrc: "/pdf.worker.js" }}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentLoadError}
          loading={<div></div>}
          error={<div className={classes.errorWarning}>Failed to load PDF file.</div>}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <>
              <Waypoint onEnter={(e) => handleEnter(e, index)} bottomOffset='50%'></Waypoint>
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  scale={scale}
                  className={`${classes.pageTextContent} ${classes.pageTextNoSelect}`}
                />
                <div className={classes.divSpacer}></div>
              
            </>
          ))}
        </Document>
      </Scrollbar>
      {numPages !== null && (
        <ToolbarTop
          pageNumber={pageNumber}
          numPages={numPages}
          setPageNumber={updatePageNumberWithScroll}
        />
      )}
      {numPages !== null && (
        <ToolbarRight
          scale={scale}
          setScale={setScale}
          defaultScale={defaultScale}
        />
      )}
    </div>
  );
}
export function PDFViewerSinglePage(props) {

  const { pdf, onLoad } = props;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page

  function onDocumentLoadSuccess({ numPages }) {
    onLoad && onLoad();
    setNumPages(numPages);
    setPageNumber(1);
  }

  function onDocumentLoadError() {
    onLoad && onLoad();
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <>
      <Document
        file={pdf}
        options={{ workerSrc: "/pdf.worker.js" }}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={onDocumentLoadError}
      >
        <Page pageNumber={pageNumber} />
      </Document>
      <div >
        <p>
          Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
        </p>
        <button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
          Previous
        </button>
        <button
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          Next
        </button>
      </div>
    </>
  );
}

export const PDFReaderSinglePage = ({ pdf, onLoad, defaultScale = 0.9 }) => {

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(defaultScale);

  useEffect(() => {
    setPageNumber(1)
  }, [pdf]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setScale(defaultScale);
    setNumPages(numPages);
    onLoad && onLoad();
  }

  const onDocumentLoadError = () => {
    onLoad && onLoad();
  }

  return (
    <React.Fragment>
      <div className={classes.PDFViewer}>
        {numPages !== null && (
          <ToolbarTop
            pageNumber={pageNumber}
            numPages={numPages}
            setPageNumber={setPageNumber}
          />
        )}
        <Scrollbar className={classes.scroll} havePadding={undefined} id={undefined} >
          <Document
            file={pdf}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={onDocumentLoadError}
            onContextMenu={(e) => e.preventDefault()}
            loading={<div></div>}
            error={<div className={classes.errorWarning}>Failed to load PDF file.</div>}
          >
            <Page
              className={`${classes.pageTextContent} ${classes.pageTextNoSelect}`}
              pageNumber={pageNumber}
              scale={scale}
            />
          </Document>
        </Scrollbar>
        {numPages !== null && (
          <ToolbarRight
            scale={scale}
            setScale={setScale}
            defaultScale={defaultScale}
          />
        )}
      </div>
    </React.Fragment>
  )
}

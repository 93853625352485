import React, {useEffect, useState} from 'react';
import {ServerConnect} from "../utils";
import {
    getEligibleDirectorsAndShareholders,
    getEligibleDirectorsAndShareholdersDraft
} from "../graphql/mutations/matters"
import _ from "lodash";
import Mustache from 'mustache'

const QUERY_GETMATTER_MATTER = getEligibleDirectorsAndShareholders;
const QUERY_GETMATTER_DRAFT = getEligibleDirectorsAndShareholdersDraft;
export default function useQuestionaryShareHolderDirector(matterId, draftId,refresh) {
  const [matter, setMatter] = useState(null);
  useEffect(() => {
      const query_params = {
        matterId,
        draftId
      };
      const query = Mustache.render( draftId ? QUERY_GETMATTER_DRAFT : QUERY_GETMATTER_MATTER, query_params);
      ServerConnect.graphQlQuery(query).then(result => {
        let kyc = _.get(result, draftId ? "getEligibleDirectorsAndShareholdersDraft" : "getEligibleDirectorsAndShareholders", {});
        setMatter(kyc)
      });
  }, [matterId,draftId,refresh]);

  return { matter };
}
import { useMemo } from 'react';
import useGetStakeholderDetails from 'src/views/modules/startups/renewal/adgm/modals/ReviewDetailsCorporateStakeholderModal/wizard/hooks/graphql/useGetStakeholderDetails';
import useMapperStakeholderDetails from 'src/views/modules/startups/renewal/adgm/modals/ReviewDetailsCorporateStakeholderModal/wizard/hooks/useMapperStakeholderDetails';
import useUpsertStakeholderDetails from 'src/views/modules/startups/renewal/adgm/modals/ReviewDetailsCorporateStakeholderModal/wizard/hooks/graphql/useUpsertStakeholderDetails';
import useUpsertRenewalData from 'src/views/modules/startups/renewal/adgm/modals/ReviewDetailsCorporateStakeholderModal/wizard/hooks/graphql/useUpsertRenewalData';

const useServices = ({ initialValues }) => {
  const { query } = useGetStakeholderDetails({});
  const { getInitialData } = useMapperStakeholderDetails();
  const updateStakeholder = useUpsertStakeholderDetails({}, {});
  const updateStakeholderTasks = useUpsertRenewalData();

  return useMemo(() => ({

    initialData: async (context, event) => {
      const data = await query(initialValues.stakeholderId)
      const initData = getInitialData(data?.stakeholder)
      return {...initData, ...initialValues};
    },

    SaveStep: async (context, event) => {
      const { AddressForm, ConfirmForm } = context;
      const confirmForm = ConfirmForm;
      if (confirmForm.jurisdiction === "OTHER") {
        confirmForm.jurisdiction = confirmForm.otherJurisdiction;
        delete confirmForm.otherJurisdiction;
      }
      const variables = {
        stakeholder: {
          ...confirmForm,
          ...AddressForm,
        }
      }
      await updateStakeholder(variables, initialValues.stakeholderId);
      const result = await updateStakeholderTasks(context?.initialData?.groupCompanyId, initialValues.stakeholderId);
      return result;
    },

  }), [initialValues]);
}

export default useServices;

import React from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";
const EditConvertConvertible = ({ go, ...props }) => {
  const params = useParams()
  return (
    <MicroFrontend id="MF_Convertibles_Edit_Transaction" component={"./EditConvertConvertible"} mf="capTable" params={{ go, ...props, ...params }} />
  );
};

export default EditConvertConvertible;

import React from 'react';
import classesModule from "./LottieView.module.scss"
import animationData from './../../animations/test_animation.json';
import Lottie from './../../../generic/components/Animations/Lottie/Lottie'
const LottieView = ({ ...props }) => {
  
  return (
    <React.Fragment>
      <div className={`${classesModule.Lottie}`}>
        <Lottie animation={animationData}/>
      </div>
    </React.Fragment>
  );
}

export default LottieView;
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import BusinessRuleAccess from "../../../../../modules/clara/components/BusinessRuleAccess/BusinessRuleAccess";
import BusinessRuleNoAccess from "../../../../../modules/clara/components/BusinessRuleNoAccess/BusinessRuleNoAccess";
import { goToPage } from "../../../../../store/actions/page";
import { Constants } from '../../../../utils/constants';
import { Avatar, Comments, RoleAccess } from '../../../index';
import classes from "../ViewForms.module.scss";
import classesIssue from './Details.module.scss';



class Details extends Component {
    state = {
        fieldOptional: 0
    };
    changeMode = () => {
        this.props.goToPage(Constants.PAGES.editHealthCheckIssue, { ...this.props.data });
    }
    getCategories = () => {
        var vec = [];
        _.forEach(this.props.categories, (category, id) => {
            vec.push({ id: category.key, label: category.title });
        })
        return vec
    }
    getSubCategories = (category) => {

        let { categories } = this.state;
        var index = _.findIndex(categories, function (o) {
            return o.key === category;
        });

        if (index >= 0) {
            return categories[index].subCategories.map((value, key) => {
                return { id: value.key, label: value.name }
            })
        }
        return [];

    }
    findById = (vec, id) => {
        return vec.find(function (element) {
            return element.id === id;
        });

    };

    renderStakeholderOrGroupCompany = (elements, label) => {
        return _.map(elements, (e, index) => (
            <div key={index + e.id} className={`${classes.ContainerRow}`}>

                <label>{`${label} ${index + 1}#: `}</label>
                <span>
                    <Avatar

                        key={index + e.name}
                        avatar={e.avatar}
                        name={e.name || e.fullName}
                        showLabel={true}

                    />
                </span>

            </div>

        ));

    }

    concatElementRelated = (elements, fieldName) => {
        return _.isEmpty(elements) ? "-" : _.join(_.map(elements, e => e[fieldName]), ", ");
    }

    componentWillMount() {
        this.setState({
            categories: this.props.categories
        })

    }


    render() {
        const { data, users } = this.props;
        if (_.isEmpty(data)) {
            return null;
        }

        const category = this.findById(this.getCategories(), data.category);
        const subCategory = this.findById(this.getSubCategories(data.category), data.subCategory);

        return (
            <div className={classesIssue.ContainerView}>
                <div className={classesIssue.ContainerData}>

                    <div className={classesIssue.ContainerDescription}>
                        <label></label>
                        <span>{data.description}{/*<a>Learn more</a>*/}</span>
                    </div>

                    <hr className={classes.Separator}></hr>


                    <div className={`${classesIssue.ContainerRow}`}>
                        <label>Category:</label>
                        <span>{category ? category.label : ""}</span>
                    </div>
                    <div className={`${classesIssue.ContainerRow}`}>
                        <label>Subcategory:</label>
                        <span>{subCategory ? subCategory.label : ""}</span>
                    </div>
                    <hr className={classes.Separator}></hr>
                    <div className={`${classesIssue.ContainerRow}`}>
                        <label>Status:</label>
                        <span className={`${classesIssue.status} ${classesIssue[data.urgency]} `}>
                            {data.urgency}
                        </span>
                    </div>

                    <hr className={classes.Separator}></hr>

                    {/* {subCategory.id == "jurisdiction" ? <span><button className={classesIssue.ResolutionButton} onClick={() => {}}>Add a Group Company</button></span>:<span></span>}                      
                    {subCategory.id == "founderServicesAgreeement" ? <span><button className={classesIssue.ResolutionButton} onClick={() => {}}>Add a Document</button><button className={classesIssue.ResolutionButton} onClick={() => {}}>Create a Founder<br/>Services Agreement</button></span>:<span></span>}                      
                    {subCategory.id == "employmentAgreeement" ? <span><button className={classesIssue.ResolutionButton} onClick={() => {}}>Add a Document</button><button className={classesIssue.ResolutionButton} onClick={() => {}}>Create an ADGM<br/>Employment Agreement</button></span>:<span></span>}                      
                    {subCategory.id == "consultingAgreements" ? <span><button className={classesIssue.ResolutionButton} onClick={() => {}}>Add a Document</button><button className={classesIssue.ResolutionButton} onClick={() => {}}>Create a Consultancy<br/> Agreement</button></span>:<span></span>}                      
                    {subCategory.id == "advisorygreements" ? <span><button className={classesIssue.ResolutionButton} onClick={() => {}}>Add a Document</button><button className={classesIssue.ResolutionButton} onClick={() => {}}>Create a Advisor<br/> Agreement</button></span>:<span></span>}                      
                    {subCategory.id == "IPAssignment" ? <span><button className={classesIssue.ResolutionButton} onClick={() => {}}>Add a Document</button><button className={classesIssue.ResolutionButton} onClick={() => {}}>Create an IP <br/>Assignment Agreement</button></span>:<span></span>}                      

                    <hr className={classes.Separator}></hr> */}

                    <div className={classesIssue.ContainerRow}>
                        <label>Related Companies:</label>
                        <span>{this.concatElementRelated(data.groupCompanies, "name")}</span>

                    </div>
                    <div className={classesIssue.ContainerRow}>
                        <label>Related Stakeholders:</label>
                        <span>{this.concatElementRelated(data.stakeholders, "fullName")}</span>

                    </div>

                    <div className={classesIssue.ContainerRow}>
                        <label>Related Documents:</label>
                        {_.isEmpty(data.documents) ? <span>-</span> : <span>{_.join(data.documents.map(doc => { return doc.name || doc.fileName }), ", ")}</span>}
                    </div>


                </div>

                <div>
                    <RoleAccess action={Constants.ACTIONS.ADD_DUEDILIGENCE}>
                        <BusinessRuleAccess conditions={[Constants.BUSINESS_RULES.IS_START_SUBSCRIPTION]}>
                            <RoleAccess action={Constants.ACTIONS.EDIT_FREEMIUM_STARTUP_REMARK}>
                                <button style={{ top: "-154px" }} className={`${classes.buttonEditFloat}`} onClick={(e) => { this.changeMode() }}>
                                    <span>Edit Details</span>
                                </button>
                            </RoleAccess>
                        </BusinessRuleAccess>
                        <BusinessRuleNoAccess conditions={[Constants.BUSINESS_RULES.IS_START_SUBSCRIPTION]}>
                            <button style={{ top: "-154px" }} className={`${classes.buttonEditFloat}`} onClick={(e) => { this.changeMode() }}>
                                <span>Edit Details</span>
                            </button>
                        </BusinessRuleNoAccess >

                    </RoleAccess>

                    <Comments issueId={data.id} comments={this.props.comments} notifications={this.props.notifications} />

                </div>


            </div>
        )
    }
}

const mapStateToProps = state => ({
    session: state.session,

});
const mapDispatchToProps = (dispatch) => ({

    goToPage: (page, params) => {
        goToPage(dispatch, page, params)
    },

});
export default connect(mapStateToProps, mapDispatchToProps)(Details)

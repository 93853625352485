import React, {useEffect, useState} from 'react';
import {ClaraCheckForm, Text} from '../../../../components';
import _ from "lodash";
import {Field} from "formik";
import classes from "../SelectorComponent/Shareholders.module.scss";
import FactoryChatForm from '../../../../components/Chat/FactoryChatForm';
import {Constants} from '../../../../utils'
import CompanyForm from '../../KYC/Company/CompanyForm';
import {modifyDataFileKYC, stripTypenames} from "../../../../utils/graphql-util";

const CompanyDetailsSelector = ({ name,showButtonAdd, initialValueStart, eligibleData, titleButtons, titleEntyties }) => {


  const [openModal, setOpenModal] = useState(false);
  const [formSelect, setFormSelect] = useState("QUESTIONNAIRE_COMPANY_FORM");

  const [listStateholder, setListStateholder] = useState(null);
  const [reload, setReload] = useState(false);
  const handleClose = () => {
    setOpenModal(false);
    setReload(!reload);
  }
  const setFom = (form) => {
    setFormSelect(form);
    setOpenModal(true);
  }
  useEffect(() => {
    if (!listStateholder) {

      let listStateholderLocal = [];
      _.forEach(_.get(eligibleData, 'alreadyCompanies', []), (company) => {
        if(!company.isDirector && !company.isShareholder){
          listStateholderLocal.push({
            type:  "QUESTIONNAIRE_COMPANY_FORM",
            data: company
          })
        }     
        
      });
      setListStateholder(listStateholderLocal);
      console.log(listStateholderLocal)
    }
  })

  
  return (
    <React.Fragment>
      <div className={` ${classes.containerShareholder}`}>
        <div className={classes.contentField}>
          <h4><Text uuid={titleEntyties} /></h4>
          <Field name={name}>
            {({ field, form }) => (
              <ClaraCheckForm
                reload={reload}
                openForm={openModal}
                addForm={formSelect}
                cbClose={handleClose}
                field={field}
                multiForm
                form={form}
                list={listStateholder}
                initialValues={{
                  "QUEST_CORPORATE_DIRECTOR_FORM":{ formKey: "QUEST_CORPORATE_DIRECTOR_FORM" },
                  "QUESTIONNAIRE_COMPANY_FORM":{ formKey: "QUESTIONNAIRE_COMPANY_FORM" }
                }}
                optionsList={{
                  "QUEST_CORPORATE_DIRECTOR_FORM": {
                    fieldId: _.get(FormConfig, "QUEST_CORPORATE_DIRECTOR_FORM" + ".id", "id"),
                    fieldLabel: _.get(FormConfig, "QUEST_CORPORATE_DIRECTOR_FORM" + ".fieldName", null),
                  },
                  "QUESTIONNAIRE_COMPANY_FORM":{
                    fieldId: _.get(FormConfig, "QUESTIONNAIRE_COMPANY_FORM" + ".id", "id"),
                    fieldLabel: _.get(FormConfig, "QUESTIONNAIRE_COMPANY_FORM" + ".fieldName", null),
                 
                  }
                }}
                optionsForm={{
                  "QUEST_CORPORATE_DIRECTOR_FORM": {
                    titleKey: _.get(FormConfig, "QUEST_CORPORATE_DIRECTOR_FORM.titleKey", null),
                    educationId: _.get(FormConfig, "QUEST_CORPORATE_DIRECTOR_FORM.leftEducationId", null),
                    muttation: _.get(FormConfig, "QUEST_CORPORATE_DIRECTOR_FORM.muttation", null),
                    getIdFromMuttation: _.get(FormConfig, "QUEST_CORPORATE_DIRECTOR_FORM.getIdFromMuttation", null),
                    paramsMuttaion: initialValueStart,
                    getVariablesMutation: _.get(FormConfig, "QUEST_CORPORATE_DIRECTOR_FORM.getVariablesMutation", null),
                    succesNotification: "NOTIFICATION_SUCCESS",
                    errorNotification: "NOTIFICATION_BAD",
                  },
                  "QUESTIONNAIRE_COMPANY_FORM":{
                    titleKey: _.get(FormConfig, "QUESTIONNAIRE_COMPANY_FORM.titleKey", null),
                    educationId: _.get(FormConfig, "QUESTIONNAIRE_COMPANY_FORM.leftEducationId", null),
                    muttation: _.get(FormConfig, "QUESTIONNAIRE_COMPANY_FORM.muttation", null),
                    getIdFromMuttation: _.get(FormConfig, "QUESTIONNAIRE_COMPANY_FORM.getIdFromMuttation", null),
                    paramsMuttaion: initialValueStart,
                    getVariablesMutation: _.get(FormConfig, "QUESTIONNAIRE_COMPANY_FORM.getVariablesMutation", null),
                    succesNotification: "NOTIFICATION_SUCCESS",
                    errorNotification: "NOTIFICATION_BAD",
                  }
                }}
                optionsSelect={{
                  auto: false,
                  canSelect: true
                }}
                optionsOperation={{
                  canAdd: true,
                  canEdit: true,
                  canRemove: false,
                }}
                optionsView={{
                  recordsByLine: 5
                }}
                className={{}}

              >
                <FactoryChatForm type={"QUEST_CORPORATE_DIRECTOR_FORM"} propsForm={{isFlow:false}} formKey={"QUEST_CORPORATE_DIRECTOR_FORM"} ></FactoryChatForm>
                <FactoryChatForm type={"QUESTIONNAIRE_COMPANY_FORM"} propsForm={{isFlow:false}} formKey={"QUESTIONNAIRE_COMPANY_FORM"} ></FactoryChatForm>

              </ClaraCheckForm>
            )}
          </Field>
        </div>
        {showButtonAdd && <>

          <div className={classes.contentBotonera}>
            <button className={`${classes.buttonWithAutomatic} ${classes.buttonOption} `} type="button" onClick={() => { setFom("QUESTIONNAIRE_COMPANY_FORM") }}>
              <Text uuid={`QUESTIONARY_SHAREHOLDER_BUTTON_COMPANY_EDIT`} />
            </button>

          </div>
        </>}
      </div>
    </React.Fragment >
  );
}
export default CompanyDetailsSelector;

const  FormConfig= {
  "QUESTIONNAIRE_COMPANY_FORM": {
    fieldName: "companyName",
    titleKey: "QUESTIONNAIRE_COMPANY_FORM_TITLE",
    muttation: `mutation upsertQuestionnaireCompany(
      $matterId: String!
      $KYCCompanyData: KYCCompanyInput! 
      $KYCCompanyId: String
    ) {
      upsertQuestionnaireCompany(matterId: $matterId, KYCCompanyData: $KYCCompanyData, KYCCompanyId: $KYCCompanyId) {
        id
        companyName
      }
    }
    `,
    leftEducationId: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.ADD.LEFT_EDUCATION_ID,
    leftEducationId_ADD: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.ADD.LEFT_EDUCATION_ID,
    leftEducationId_EDIT: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.EDIT.LEFT_EDUCATION_ID,
    getAvatarLabel: (obj) => {
      return _.get(obj, "companyName", "");
    },
    getForm: (props) => {
      return <CompanyForm {...props} isFlow={false} />
    },
    getVariablesMutation: (paramsMutattion, values) => {
      let KYCCompanyData = { ...values };
      delete KYCCompanyData.type;
      delete KYCCompanyData.formKey;
      delete KYCCompanyData.isShareholder
      delete KYCCompanyData.isDirector
      delete KYCCompanyData.isComplete
      delete KYCCompanyData.jurisdiction
      delete KYCCompanyData.__typename;
      KYCCompanyData = modifyDataFileKYC(stripTypenames(KYCCompanyData));
      KYCCompanyData.signatory = modifyDataFileKYC(stripTypenames(KYCCompanyData.signatory));
      return {
        ...paramsMutattion,
        KYCCompanyData,
        KYCCompanyId: KYCCompanyData.id
      }
    },
    getIdFromMuttation: (result) => {
      return _.get(result, "upsertQuestionnaireCompany.id");
    }
  },
  "QUEST_CORPORATE_DIRECTOR_FORM": {
    fieldName: "companyName",
    titleKey: "QUESTIONNAIRE_COMPANY_FORM_TITLE",
    muttation: `mutation upsertQuestionnaireCompany(
      $matterId: String!
      $KYCCompanyData: KYCCompanyInput! 
      $KYCCompanyId: String
    ) {
      upsertQuestionnaireCompany(matterId: $matterId, KYCCompanyData: $KYCCompanyData, KYCCompanyId: $KYCCompanyId) {
        id
        companyName
      }
    }
    `,
    leftEducationId: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.ADD.LEFT_EDUCATION_ID,
    leftEducationId_ADD: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.ADD.LEFT_EDUCATION_ID,
    leftEducationId_EDIT: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.EDIT.LEFT_EDUCATION_ID,
    getAvatarLabel: (obj) => {
      return _.get(obj, "companyName", "");
    },
    getForm: (props) => {
      return <CompanyForm {...props} isFlow={true} />
    },
    getVariablesMutation: (paramsMutattion, values) => {
      let KYCCompanyData = { ...values };
      delete KYCCompanyData.type;
      delete KYCCompanyData.formKey;
      delete KYCCompanyData.isShareholder
      delete KYCCompanyData.isDirector
      delete KYCCompanyData.isComplete
      delete KYCCompanyData.__typename;
      delete KYCCompanyData.jurisdiction

      KYCCompanyData = modifyDataFileKYC(stripTypenames(KYCCompanyData));
      KYCCompanyData.signatory = modifyDataFileKYC(stripTypenames(KYCCompanyData.signatory));
      return {
        ...paramsMutattion,
        KYCCompanyData,
        KYCCompanyId: KYCCompanyData.id
      }
    },
    getIdFromMuttation: (result) => {
      return _.get(result, "upsertQuestionnaireCompany.id");
    }
  }
}
import React, {Component} from 'react';

import classes from "./FilterZone.module.scss"


class FilterZone extends Component {
    state={

    }
    onClick=(filter,type)=>{
        this.props.goTo(filter,type)
    }
    render() {
        
            
          
        
        
        return (
            
            <div className={classes.FilterZone}>
                <nav className={classes.FilterType}>
                    {this.props.type.map((filter,i)=>{
                        const classSelected=this.props.currentType === filter.key? classes["selectedType"]:null;
                        return <a key={i} className={`${classSelected} ${classes[filter.key]}`} onClick={()=>{this.onClick(this.props.currentFilter,filter.key)}}></a>
                    })}
                </nav>
            </div>
        )
    }
}
export default FilterZone;

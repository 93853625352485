import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import * as Yup from 'yup';
import Button from 'src/modules/generic/components/Buttons/Button';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText } from 'src/modules/generic/store/action';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from "src/modules/generic/components/Texts/Typography";
import reactImage from "src/images/formationSendMail.svg";
import classesModule from "../classes.module.scss";
// import useUpsertJurisdictionMail from "src/modules/startup/onBoarding/CreateCompanyModal/wizard/submachines/AddFormationCompanyModal/wizard/hooks/useUpsertJurisdictionMail";

function ComingSoonJurisdiction() {

  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  // const {mutation: sendJurisdictionMail} = useUpsertJurisdictionMail();
  
  const initialValues = useMemo(() => {
    return context?.ComingSoonJurisdiction ?? {}
  }, [context]);
  
  const handleCancel = (values) => {
    cancel();
  }

  const handleSubmit = async (values) => {
    next(values)
  }

  // const handleSendMail = async () => {
  //   await sendJurisdictionMail();
  // }
  
  useEffect( ()=>{
    // handleSendMail();
    return () => {
      dispatch(clearAssistatText())
    }
  },[dispatch])
  
  const schema = Yup.object().shape({});

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={ { mode: 'onChange' } }
    >
      <TemplateWizard
        props={{
          title: "We've sent you an email!​​",
          subTitle: "Company jurisdiction​",
          skeleton: null,
          variant: 'simple'
        }}
        buttons={{
          submit: <Button variant={"primary"} onClick={handleCancel}>{translate('GENERIC_BUTTON_CLOSE')}</Button>,
        }}
      >
        <>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          spacing={3}
        >
          <Stack sx={{ width: "100%"}} spacing={2}>
            <Typography component='p' variant='xbody'>
              We currently do not process formations in this jurisdiction, but we may still be able to help.
            </Typography>
            <Typography component='p' variant='xbody'>
              We've sent you an email with a calendar booking link. Please book a time that works for you and we will discuss your formations needs.
            </Typography>
          </Stack>
          <Box sx={{ width: "80%"}}>
            <Box sx={{width: "22.3077rem", position: "absolute", right: "0.7692rem", marginTop: "5.2308rem"}}>
              <img src={reactImage} alt="" className={classesModule.ViewCompanyFomation}/>
            </Box>
          </Box>
        </Stack>
        </>
      </TemplateWizard>
    </Form>
  );
}

export default ComingSoonJurisdiction;


import React from 'react';
import classes from './SecondaryButton.module.scss';
import Button from '../Button/Button'

const SecondaryButton = (props) => {
    return (
        <Button {...props} className={`${classes.SecondaryButton} ${props.className}`} > {props.children} </Button>
    )
}

export default SecondaryButton


import { useEffect } from 'react';
import useQuery from "../../../hooks/custom/useQuery";
import useLazyQuery from "../../../hooks/custom/useLazyQuery";
import _ from "lodash"
import { loadSessionState } from "../../../session";
const query = `query getProduct($productCode: String!,$relatedItemId: ID) {
  getProduct(productCode:$productCode,relatedItemId: $relatedItemId){
  code
  discount
  name
  currency
  value
  installmentValue
  totals {
    subTotalAmount
    totalPricingDiscount
    vatPercent
    vat
    total
  }
  couponsEnabled
  defaultVAT{
    value
    discountApplies
    valueWithDiscount
  }
  countryVAT{
    country
    value
    discountApplies
    valueWithDiscount
  }
  items {
    code
    name
    descriptionLabel
    description
    amount
    applyDiscount
    apply_VAT
    VAT_per
    quantity
    countryVAT {
      country
      value
      discountApplies
      valueWithDiscount
    }
  }
}
  

}`;
const queryV0 = `query getProduct($productCode: String!,$relatedItemId: ID) {
  getProduct(productCode:$productCode,relatedItemId: $relatedItemId){
    code
    name
    currency
    value
    installmentValue
    couponsEnabled
    totals {
      subTotalAmount
      totalPricingDiscount
      vatPercent
      vat
      total
    }
    defaultVAT{
      value
      discountApplies
      valueWithDiscount
    }
    countryVAT{
      country
      value
      discountApplies
      valueWithDiscount
    }
    items {
      code
      name
      description
      amount
      applyDiscount
      apply_VAT
      VAT_per
      quantity
      countryVAT {
        country
        value
        discountApplies
        valueWithDiscount
      }
    }
  }
}`;

const useGetProduct = (variables) =>{
    let version = _.get(loadSessionState("draftValues"),"getFormDraft.values.payment","v0");
    
    const {loading, error, data,refetch} = useQuery(version=="v0"?queryV0:query,variables);
    useEffect(() => {
        
    }, []);

    return  {loading,error,data,refetch} ;
}
export const  useGetProductLazy = (variables,config) =>{
  let version = _.get(loadSessionState("draftValues"),"getFormDraft.values.payment","v0");
  
  const {manualQuery,data} = useLazyQuery(query,variables,config);
  

  return  {manualQuery,data} ;
}
export default useGetProduct;

import _ from 'lodash';
import React, { FC, useMemo } from 'react';
import Button from "../../../../../generic/components/Buttons/Button";
import useMachine from '../../../../../generic/context/MachineContext/useMachine';
import useTranslate from '../../../../../generic/hooks/useTranslate';
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import WarrantForm from "../../../forms/WarrantsForm";

const WarrantFormStep:FC<{}>=({})=>{
    const {stepData,next,send,state,context}=useMachine()
    const {translate}=useTranslate()
    const handleCompleteSubmit=(values)=>{
        next(values)
    }
    const handleCancel=()=>{
        send("CANCEL")
    }

    const handlePrev=()=>{
        send("PREVIOUS")
    }
    const companyName=useMemo(()=>{
        return _.get(context,`groupCompanies[${_.get(context,'iterations',0)}].name`,'')
    },[JSON.stringify(context)])
    const currency=useMemo( ()=>{
        return _.get(context,`groupCompanies[${_.get(context,'iterations',0)}].transactionCurrency`,'')?_.get(context,`groupCompanies[${_.get(context,'numbersOfIteration',0)}].transactionCurrency`,''):''
    },[JSON.stringify(context)])
    return(<>
        <WarrantForm
            initialValues={stepData}
            currency={currency}
            propsTemplate={{
                title: translate('MODULES.EQUITY.STEPS_WARRANT.WARRANT_FORM_STEP.TITLE'),
                subTitle: translate('MODULES.EQUITY.STEPS_WARRANT.WARRANT_FORM_STEP.SUBTITLE',{type:translate(`MODULES.EQUITY.TYPES_PLURAL.WARRANT`)})
            }}
            companyName={companyName}
            onCompleteSubmit={handleCompleteSubmit}
            Template={TemplateWizard}
            buttons={{cancel: <Button onClick={handleCancel}>Cancel</Button>}}/>
    </>)
}
export default WarrantFormStep
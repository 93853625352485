import useMutation from "src/hooks/custom/useMutation";

export const UPSERT_UPDATE_ULTIMATE_BENEFICIAL_OWNER = `
mutation updateUltimateBeneficialOwner(
  $startupId: ID!
  $groupCompanyId: ID!
  $entityId: ID!
  $entityData: RegulatoryBusinessEntityInput!
) {
  updateUltimateBeneficialOwner(
    startupId: $startupId
    groupCompanyId: $groupCompanyId
    entityId: $entityId
    entityData: $entityData
  ) {
    id
  }
}
`;

const useUpdateUltimateBeneficialOwner = () => {
  const [mutation, rest] = useMutation(
    UPSERT_UPDATE_ULTIMATE_BENEFICIAL_OWNER,
    { showSuccessNotification: true }
  );
  return [mutation, rest];
};

export default useUpdateUltimateBeneficialOwner;

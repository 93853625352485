import { Stack } from '@mui/material';
import React, { useMemo } from 'react';
import Typography from 'src/modules/generic/components/Texts/Typography';
import cls from "./classes.module.scss";
import useFormat from "src/modules/generic/hooks/useFormat.jsx"
import _ from "lodash"
import useBusinessRules from 'src/modules/checkout/hooks/useBusinessRules';

function CardProductFormation({ product, company, ...props }) {

  const { moneyFormat } = useFormat();
  const { isItemEnabled, isUsingNominee } = useBusinessRules();

  const { fullPrice } = useMemo(() => {
    let fullPrice = 0;
    _.each(product?.items, function (item, key) {
      if (item.amount && isItemEnabled(item, company)) {
        fullPrice = fullPrice + item.amount;
      }
    })
    return {
      fullPrice,
    }
  }, [company, isItemEnabled, product?.items])

  return (
    <>
      <Stack direction={"row"} justifyContent={"space-between"} alignItems="center" sx={{ width: "100%", paddingLeft: "1.8462rem" }}>
        <Stack direction={"column"} justifyContent="flex-start" alignItems={"flex-start"} textAlign="left" sx={{ width: "100%" }}>
          <Typography variant={"h4"} className={cls["text"]}>
            {props.label}
          </Typography>
          {isUsingNominee(company) && (
            <Typography variant={"body"} fontWeight={"bold"}>
              + Clara’s Nominee Authorised Signatory Fee
            </Typography>
          )}
        </Stack>
        <Stack
          direction={"row"}
          gap={1}
          justifyContent="flex-end"
          alignItems={"flex-end"}
          textAlign="right"
        >
          <Typography variant={"xs"} className={cls["money"]}>
            {props.currency}
          </Typography>
          <Typography variant={"h4"} className={cls["price"]}>
            {moneyFormat(fullPrice, true)}
          </Typography>
        </Stack>
      </Stack>
    </>
  );
}
export default CardProductFormation;
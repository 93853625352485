import React from "react";
import schema from "../../../../../../forms/schemas/modules/user/updatePasswordSchema";
import RowAdd from "../../../../../forms/contents/RowAdd/RowAdd";
import ControllerInput from "../../../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import PasswordInput from "../../../../../inputs/Password/PasswordInput";
import SubmitButton from "../../../../../generic/Buttons/SubmitButton/SubmitButton";
import Form from "../../../../../forms/Form/FormL";
import TemplateFormDefault from "../../../../../../modules/generic/templates/Modal";

const ChangePasswordForm = ({Template = TemplateFormDefault, buttons = {}, buttonSubmitProps, propsTemplate, initialValues, handleSubmit}) => {
    return(
        <Form initialValues={''} schema={schema} onSubmit={handleSubmit}>
            <Template
                buttons={{ ...buttons, submit: <SubmitButton {...buttonSubmitProps}>Save</SubmitButton> }}
                props={propsTemplate}
            >
                <RowAdd
                    label={"MY_ACCOUNT_MODAL_UPDATEPASSWORD_FIELD_CURRENT_PASSWORD"}
                    asterisk={true}
                    input={
                        <ControllerInput
                            as={PasswordInput}
                            name="currentPassword"
                            defaultValue=''
                            placeholder=''
                        />
                    }
                />

                <RowAdd
                    label={"MY_ACCOUNT_MODAL_UPDATEPASSWORD_FIELD_NEW_PASSWORD"}
                    asterisk={true}
                    input={
                        <ControllerInput
                            as={PasswordInput}
                            name="newPassword"
                            defaultValue=''
                            placeholder=''
                            showStrong={true}
                        />
                    }
                />
            </Template>
        </Form>
    );
}

export default ChangePasswordForm;
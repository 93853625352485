import React, { Component } from "react";
import { connect } from "react-redux";
import Mustache from "mustache";
import { Constants } from "../../../../v1/utils/constants";
import {
  ModalLoading,
  Overview,
  Tabs,
} from "../../../components";
import ServerConnect from "../../../utils/ServerConnect";
import BusinessRuleAccess from "../../../../modules/clara/components/BusinessRuleAccess/BusinessRuleAccess";
import Subscriptions from "./../../../../modules/checkout/modals/Subscriptions";
import { Config } from "./config";
import classes from "./DueDiligence.module.scss";
import Issues from "./Issues";
import SubscriptionGatesHealthCheck from "../../../../images/SubscriptionGatesHealthCheck.svg";
import TitleHealthCheck from "./components/TitleHealthCheck";

class DueDiligence extends Component {
  state = {
    tabs: [
      { name: "Overview", key: "overview" },
      { name: "Remarks", key: "issues" },
    ],
    tab: "overview",
    isOverview: true,
    data: null,
    category: {},
    subCategory: null,
    title: "",
    refresh: 0,
    showModalLoading: false,
  };

  goTo = (tab) => {
    this.setState({
      isOverview: tab === "overview",
      tab: tab,
    });
  };

  updateData(id) {
    const query_params = {
      startup_id: id ? id : this.props.session.startup.id,
    };
    const query = Mustache.render(Config.query, query_params);
    const corporateStructureCategory = this.props.categories.find(
      (cat) => cat.key === "CORPORATE_STRUCTURE"
    );
    this.props.addLoading();
    ServerConnect.graphQlQuery(query)
      .then((result) => {
        let data = result.dueDiligence;
        let subCategory = "";
        if (
          data[corporateStructureCategory.key] &&
          data[corporateStructureCategory.key][0]
        ) {
          subCategory = data[corporateStructureCategory.key][0].key;
        }
        let refresh = this.state.refresh;
        refresh = refresh + 1;
        this.setState({
          data: data,
          refresh: refresh,
          category: corporateStructureCategory.key,
          subCategory: subCategory,
        });
        this.props.removeLoading();
      })
      .catch((err) => {
        this.props.removeLoading();
      });
  }

  componentDidMount() {
    this.updateData();
  }

  componentWillUpdate(nextProps, nextState, snapshot) {
    if (nextProps.session.startup.id !== this.props.session.startup.id) {
      this.updateData(nextProps.session.startup.id);
    }
    if (nextProps.reload !== this.props.reload) {
      this.updateData(nextProps.session.startup.id);
    }
  }

  onChange = (categoryKey, subCategoryKey, title, callback) => {
    this.setState(
      {
        isOverview: false,
        category: categoryKey,
        subCategory: subCategoryKey,
        title: title,
        tab: "issues",
      },
      () => {
        console.log("Top", document.querySelector("#div_issue_key_" + subCategoryKey).offsetTop);
        document.querySelector("#zoneIssues").scrollTo(0, document.querySelector("#div_issue_key_" + subCategoryKey).offsetTop - 243);
      }
    );
  };

  componentDidUpdate() {}

  handleExportPdf = () => {
    this.setState({
      showModalLoading: true,
    });
    ServerConnect.requestApi(
      "/file/dd-report/" + this.props.session.startup.id,
      { responseHeader: "application/pdf" }
    ).then((blob) => {
      const tempURL = URL.createObjectURL(blob);
      window.open(tempURL, "_blank");
      //URL.revokeObjectURL(tempURL);
      this.setState({
        showModalLoading: false,
      });
    });
    //ServerConnect.openFile("file/dd-report/" + this.props.session.startup.id, "file.pdf");
  };

  render() {
    const { reload } = this.props;
    return (
      <div className={classes.DueDiligence}>
        <TitleHealthCheck />
        <BusinessRuleAccess
          type="and"
          conditions={[
            Constants.BUSINESS_RULES.HAS_SUBSCRIPTION_ACCESS_HEALTH_CHECK,
            Constants.BUSINESS_RULES.IS_OWNER,
          ]}
        >
          <Subscriptions
            subtitle={"SUSCRIPTION_MODAL_HEALTH_CHECK_SUBTITLE"}
            title={"SUSCRIPTION_MODAL_HEALTH_CHECK_TITLE"}
            content={"SUSCRIPTION_MODAL_HEALTH_CHECK_CONTENT"}
            assistantText={"MODULES.ASSISTANCEV4.ASSISTANCE_MAP_HIT"}
            image={SubscriptionGatesHealthCheck}
            button={"SUSCRIPTION_MODAL_CONTINUE_BUTTON"}
            gateField={"healthCheckHasBeenSeen"}
            popupName={"HealthCheck"}
          />
        </BusinessRuleAccess>
        <div className={classes.SubHeader}>
          <div className={classes.TabsContainer}>
            <Tabs
              tabs={this.state.tabs}
              goTo={this.goTo}
              selected={this.state.tab}
              classesTabs={classes.Tabs}
            >
            </Tabs>
          </div>
        </div>
        <ModalLoading
          open={this.state.showModalLoading}
          title={"MODAL_LOADING_PLEASE_WAIT"}
          label={"MODAL_LOADING_FILE_GENERATED"}
        />
        {this.state.data && this.state.isOverview && (
          <Overview
            refresh={this.state.refresh}
            data={this.state.data}
            categories={this.props.categories}
            onChange={this.onChange}
          />
        )}
        {this.state.data && !this.state.isOverview && (
          <Issues
            refresh={this.state.refresh}
            startup={this.props.session.startup}
            data={this.state.data}
            title={this.state.title}
            categories={this.props.categories}
            category={this.state.category}
            subCategory={this.state.subCategory}
            user={this.props.session.user}
            onChange={this.onChange}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DueDiligence);

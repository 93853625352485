import React, {useState, useMemo, useCallback} from 'react';

const TimelineContext = React.createContext({});

export type TimelineContextType = {
  isPlaying: boolean,
  isPaused: boolean,
  playCode: string,
  playingStatus: 'play' | 'pause' | 'stop',
  startPlaying: (string) => void,
  stopPlaying: () => void,
  pausePlaying: () => void,
  infoCurrentPosition: any,
  setInfoCurrentPosition: (value: any) => void,
  isResumable: boolean,
}

export const  TimelineProvider = (props) => {
  
  const [ isPlaying, setIsPlaying ] = useState( false );
  const [ isPaused, setIsPaused ] = useState( false );
  const [ playCode, setPlayCode ] = useState( null )
  const [ isResumable, setIsResumable ] = useState( false )
  const [playingStatus, setPlayingStatus] = useState('stop');
  const [infoCurrentPosition,setInfoCurrentPosition] = useState({});

  const startPlaying = (playCode) => {   
    setIsPlaying(true);
    setIsPaused(false);
    setPlayCode(playCode)
    setPlayingStatus('play')
  }

  const stopPlaying = useCallback(() => {
    setIsPlaying(false);
    setIsPaused(true);
    setIsResumable(false)
    setPlayingStatus('stop')    
  },[setIsPlaying,setIsPaused])


  const pausePlaying = useCallback(() => {
    setIsPlaying(false);
    setIsPaused(true);
    setIsResumable(true)
    setPlayingStatus('pause')

  },[setIsPlaying,setIsPaused])

  const resumePlay = () => {
    
  }


  return <TimelineContext.Provider value={{startPlaying, playCode, stopPlaying,  pausePlaying, isPlaying, isPaused, playingStatus,infoCurrentPosition,setInfoCurrentPosition, isResumable}} {...props} />
}
interface UseTimelineReturn {
  isPlaying: boolean,
  isPaused: boolean,
  playingStatus: 'play' | 'pause' | 'stop',
  playCode: string,
  infoCurrentPosition:any,
  startPlaying: (string) => void,
  stopPlaying: () => void,
  pausePlaying: () => void,
  setInfoCurrentPosition: (value: any) => void,
  isResumable: boolean
}

function useTimeline(): UseTimelineReturn {
  
  const {  isPlaying, isPaused,playCode, startPlaying, stopPlaying, pausePlaying, playingStatus,infoCurrentPosition,setInfoCurrentPosition, isResumable } = React.useContext(TimelineContext) as TimelineContextType;
  return { isPlaying, isPaused,playCode, startPlaying, stopPlaying, pausePlaying, playingStatus,infoCurrentPosition,setInfoCurrentPosition, isResumable }
}

export default useTimeline;
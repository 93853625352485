import { Stack } from '@mui/material';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import React from 'react';
import classes from './CardInput.module.scss';
import ErrorInput from 'src/modules/generic/components/Inputs/ErrorInput';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
const CardInputStripe = ({ errorMessage, classNameError }) => {
    const { formState } = useFormContext();
    const { isSubmitting: isSubmittingForm } = formState;



    return (

        <Stack direction={"column"} sx={{
            width: "100%",
            height: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: "10px",
            "iframe": {
                width: "100%",
                height: "auto"
            },

        }}>

            <CardNumberElement className={classNames(classes.CardStripeInput, {
                [classes.CardStripeInputDisabled]: isSubmittingForm,
                [classes.CardStripeInputError]: errorMessage
            })} options={{
                showIcon: true,
                disabled: isSubmittingForm,
                style: {
                    base: {
                        color: "#192a3e",
                    },
                    invalid: {
                        color: "#192a3e",
                        border: "1px solid red"
                    }

                }
            }} />
            <Stack direction={"row"} sx={{ gap: "2rem", width: "100%" }}>
                <CardExpiryElement className={classNames(classes.CardStripeInput, {
                    [classes.CardStripeInputDisabled]: isSubmittingForm,
                    [classes.CardStripeInputError]: errorMessage
                })} options={{
                    disabled: isSubmittingForm
                }} />
                <CardCvcElement className={classNames(classes.CardStripeInput, {
                    [classes.CardStripeInputDisabled]: isSubmittingForm,
                    [classes.CardStripeInputError]: errorMessage
                })} options={{
                    disabled: isSubmittingForm
                }} />
            </Stack>
            <Stack sx={{ mt: "0.5rem", width: "100%" }} >
                {errorMessage && <ErrorInput message={errorMessage} classNameError={`${classes.classNameError} ${classNameError}`} />}
            </Stack>
        </Stack>

    )
};

export default CardInputStripe

import React, { useMemo, useState } from "react";
import _ from "lodash";
import Wizard from "src/modules/generic/components/Wizard/v2";
import ModalAssistant from "src/modules/generic/components/Modal/ModalAssistant";
import TemplateWizardSkeletonMachine from "src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine";
import useSession from "src/modules/session/hooks/useSession";
import useModal from "src/modules/generic/hooks/useModal";
import { clearAssistatText } from "src/modules/generic/store/action";
import EditPosition from "./Steps/EditPosition";
import useUpsertComponentStakeholderPosition from "./hooks/useUpsertComponentStakeholderPosition";
import machine from "./machine";

/**
 * Wizard to Renewal and Billing
 * added with the new state machine
 * @returns
 */
function ModalEditPosition({
  stakeholderId,
  initialValues,
  open: openParam = false,
}) {
  const [open, setOpen] = useState(openParam);
  const { startup } = useSession();
  const [upsertPosition] = useUpsertComponentStakeholderPosition({});

  const { closeModal: onClose } = useModal();

  const steps = useMemo(
    () => ({
      initialData: {
        render: TemplateWizardSkeletonMachine,
        renderProps: {
          title: "",
          subTitle: "",
        },
      },
      editPosition: {
        render: EditPosition,
      },
      updateEditPosition: {
        render: TemplateWizardSkeletonMachine,
        renderProps: {
          title: "",
          subTitle: "",
        },
      },
    }),
    []
  );

  const guards = {};

  const services = {
    initialData: async (context, event) => {
      //Consegui los roles pasandoselos al hook de servicios

      return {
        department: initialValues?.department,
        position: initialValues?.position,
        isDefault: initialValues?.position?.isDefault || false,
        responsibilities: initialValues?.position?.responsibilities,
      };
    },
    updateEditPosition: async (context, event) => {
      const data = _.get(event, "payload.values");
      const values = {
        startupId: startup?.id,
        stakeholderId: stakeholderId,
        stakeholderPosition: {
          departmentId: data?.department?.id,
          positionId: data?.position?.id,
          responsibilities: _.map(
            data?.responsibilities ?? [],
            (responsibility) => {
              return {
                id: responsibility?.id,
                code: responsibility?.code,
                name: responsibility?.label,
                isDefault: !(
                  responsibility?.label === "Other" ||
                  !responsibility?.isDefault
                ),
                text: responsibility?.text ?? responsibility?.label ?? "",
              };
            }
          ),
        },
      };

      return await upsertPosition(values);
    },
  };

  const actions = {};

  const handleStopMachine = () => {
    onClose();
    setOpen(false);
    clearAssistatText();
  };

  return (
    <ModalAssistant open={open}>
      <Wizard
        guards={guards}
        machine={machine}
        steps={steps}
        services={services}
        actions={actions}
        onStopMachine={handleStopMachine}
      />
    </ModalAssistant>
  );
}

export default ModalEditPosition;

import React, {useEffect, useState} from 'react';
import {Constants} from '../../../../utils/constants'
import {useDispatch} from "react-redux";
import _ from "lodash";
import {addCompany, entityQuery} from '../../../../graphql/mutations/matters';
import notification from "../../../../utils/notification";
import Mustache from 'mustache';
import ServerConnect from "../../../../utils/ServerConnect";
import Loading from '../../../Loading/Loading';
import CompanyForm from '../../../../containers/Forms/KYC/Company/CompanyForm';
import {LayoutForm} from '../../../../containers';
import {modifyDataFileKYC, stripTypenames} from "../../../../utils/graphql-util";
import {useParams} from 'react-router-dom';


const AddKYCCompanyForm = (props) => {

  const dispatch = useDispatch();
  const params = useParams();

  const closeForm = () => {
    dispatch({ type: 'GO_TO', page: Constants.PAGES.viewMatter, params: { id: props.params.matterId, tab: "Kyc" } })
  }

  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)

  const individualType = `
  {
    id
    firstName
    email
    lastName 
  }
  `

  const companyTypeShareholder = `{
  id
  companyName
  type
  otherTradingName
  signatory {
    firstName
    lastName
    jobTitle
    email
    nationality
    passportNumber
    passportExpiryDate
    signatoryPassportDoc{
      file{
        id
        name
        size
        type
      }
    }
  }
  directors{
    individuals{
      id
      firstName
      lastName
      email
    }
    companies{
      id
      companyName
      signatory{
        firstName
        lastName
        email
      }
    }
    
  }
  clientKeyContact{
    fullName
    contactNumber{
      prefix
      number
      extension
    }
    email
    contactType
    }
  financeContact{
    fullName
    contactNumber{
      prefix
      number
      extension
    }
    email
    contactType
    }
    sourceOfFunds
    representativeDetails{
      firstName
      lastName
      jobTitle
      email
      representativePassportDoc {
        file{
          id
          name
          size
          type
        }
      }
    }
    showingShareholdersRegistrationDoc{
      file{
        id
        name
        size
        type
      }
    }
    showingDirectorsRegistrationDoc{
      file{
        id
        name
        size
        type
      }
    }
  registrationNumber
  isPrimary
  TRNNumber
  jurisdictionOfIncorporation
  VATRegistrationCertificateDoc{
    file{
      id
      name
      size
      type
      }
  }
  address{
    city
    state
    country
    zipCode
    address
  }
samePrincipalPlaceOfBusinessAndLegal
principalAddress{
  city
  state
  country
  zipCode
  address
} 
certificateOfIncorporationOrCommercialLicenseDoc{
  file{
    id
    name
    size
    type
  }
}
memorandumAndArticlesOfAssociationDoc{
  file{
    id
    name
    size
    type
  }
}
countryOfIncorporation {code name}
proofOfPowerDoc{
  file{
    id
    name
    size
    type
  }
}
}
  `

  const companyTypeDirector = `{
  id
  companyName
  type
  directors{
    companies${companyTypeShareholder}
    individuals${individualType}
  }
  otherTradingName
  signatory {
    firstName
    lastName
    jobTitle
    email
    nationality
    passportNumber
    passportExpiryDate
    signatoryPassportDoc{
      file{
        id
        name
        size
        type
      }
    }
  }
  clientKeyContact{
    fullName
    contactNumber{
      prefix
      number
      extension
    }
    email
    contactType
    }
  financeContact{
    fullName
    contactNumber{
      prefix
      number
      extension
    }
    email
    contactType
    }
    sourceOfFunds
    representativeDetails{
      firstName
      lastName
      jobTitle
      email
      representativePassportDoc {
        file{
          id
          name
          size
          type
        }
      }
    }
    showingShareholdersRegistrationDoc{
      file{
        id
        name
        size
        type
      }
    }
    showingDirectorsRegistrationDoc{
      file{
        id
        name
        size
        type
      }
    }
  registrationNumber
  isPrimary
  TRNNumber
  jurisdictionOfIncorporation
  VATRegistrationCertificateDoc{
    file{
      id
      name
      size
      type
      }
  }
  address{
    city
    state
    country
    zipCode
    address
  }
samePrincipalPlaceOfBusinessAndLegal
principalAddress{
  city
  state
  country
  zipCode
  address
} 
certificateOfIncorporationOrCommercialLicenseDoc{
  file{
    id
    name
    size
    type
  }
}
memorandumAndArticlesOfAssociationDoc{
  file{
    id
    name
    size
    type
  }
}
countryOfIncorporation {code name}
proofOfPowerDoc{
  file{
    id
    name
    size
    type
  }
}
}
  `

  const entityType = `{
    id
    companyName
    type
    otherTradingName
    directors {
      individuals${individualType}
      companies${companyTypeDirector}
      }
    shareholders {
      individuals${individualType}
      companies${companyTypeShareholder}
      }
    signatory {
      firstName
      lastName
      jobTitle
      email
      nationality
      passportNumber
      passportExpiryDate
      signatoryPassportDoc{
        file{
          id
          name
          size
          type
        }
      }
    }
    clientKeyContact{
      fullName
      contactNumber{
        prefix
        number
        extension
      }
      email
      contactType
      }
      financeContact{
        fullName
      contactNumber{
        prefix
        number
        extension
      }
      email
      contactType
      }
      sourceOfFunds
      representativeDetails{
        firstName
        lastName
        jobTitle
        email
        representativePassportDoc {
          file{
            id
            name
            size
            type
          }
        }
      }
      showingShareholdersRegistrationDoc{
        file{
          id
          name
          size
          type
        }
      }
      showingDirectorsRegistrationDoc{
        file{
          id
          name
          size
          type
        }
      }
    registrationNumber
    isPrimary
    TRNNumber
    jurisdictionOfIncorporation
    VATRegistrationCertificateDoc{
      file{
        id
        name
        size
        type
        }
    }
    address{
      city
      state
      country
      zipCode
      address
    }
  samePrincipalPlaceOfBusinessAndLegal
  principalAddress{
    city
    state
    country
    zipCode
    address
  } 
  certificateOfIncorporationOrCommercialLicenseDoc{
    file{
      id
      name
      size
      type
    }
  }
  memorandumAndArticlesOfAssociationDoc{
    file{
      id
      name
      size
      type
    }
  }
  countryOfIncorporation {code name}
  proofOfPowerDoc{
    file{
      id
      name
      size
      type
    }
   }
  }`


  const filteredQuery = `{
        getMattersList(matterId:"{{matterId}}") {
          id
          KYC{
            id
            KYCType
            entitiesData{
              entities${entityType}
            }
          }
      } 
    }`


  const addCompany2 = `mutation upsertKYCEntity(
        $matterId: String!
        $KYCEntityData: KYCCompanyInput!
        $KYCEntityId: String
        ) {
        upsertKYCEntity(
        matterId: $matterId,
        KYCEntityData:  $KYCEntityData
        KYCEntityId: $KYCEntityId  
        ){
          id
        }
      }`

  const getQuery = () => {
    let specificQuery
    if (params.type === 'CORPORATE_DIRECTOR') {
      specificQuery = `{
        getMattersList(matterId:"{{matterId}}") {
          id
          questionnaire{
            directors {
              companies${companyTypeDirector}
            }
          }
      } 
    }`
      return specificQuery
    } else if (props.type == 'entity') {
      specificQuery = entityQuery
      return specificQuery
    } else {
      specificQuery = filteredQuery
      return specificQuery
    }
  }


  const getData = () => {
    let updatedQuery = getQuery()
    return new Promise((resolve, reject) => {
      const query_params = {
        matterId: props.params.matterId
      };
      const queryExecute = Mustache.render(updatedQuery, query_params);
      ServerConnect.graphQlQuery(queryExecute).then(result => {
        let data = {
          ...result.getMattersList
        }
        resolve(data);
      });
    })
  }


  const getFormKey = () => {
    if (props.type == 'entity') {
      return 'COMPANY_FORM'
    }
    else if (params.type === "SHAREHOLDER") {
      return 'INDIVIDUAL_CORPORATE_FORM_SHAREHOLDER'
    } else if (params.type === 'CORPORATE_DIRECTOR') {
      return 'INDIVIDUAL_CORPORATE_FORM_DIRECTOR'
    }
    else {
      return 'INDIVIDUAL_CORPORATE_FORM'
    }
  }




  const filterData = (data) => {


    if (props.type == 'entity') {
      return formatDirectorsAndIndividuals(_.find(_.get(data, 'KYC.entitiesData.entities', []), ele => { return props.params.id == ele.id }))
    }
    else if (params.type === "SHAREHOLDER") {
      return formatDirector(_.find(_.get(data, 'KYC.entitiesData.entities[0].shareholders.companies', []), com => { return props.params.id == com.id }))
    } else if (params.type == 'DIRECTOR') {
      return formatDirector(_.find(_.get(data, 'KYC.entitiesData.entities[0].directors.companies', []), com => { return props.params.id == com.id }));
    } else if (params.type == 'CORPORATE_DIRECTOR') {
      console.log('LEERCORPDIR:', _.find(_.get(data, 'questionnaire.directors.companies', []), comp => { return props.params.id == comp.id }))
      return  formatDirector(_.find(_.get(data, 'questionnaire.directors.companies', []), comp => { return props.params.id == comp.id }))
    }
    else {

      return formatDirectorsAndIndividuals(_.find(data.KYC.entitiesData.entities[0].directors.companies, com => { return props.params.id == com.id }));

    }


  }

  const formatDirectorsAndIndividuals = (data) => {
    if (!data) return null;
    const directorCorporate = _.map(data.directors.companies, com => ({ companyName: com.companyName, id: com.id, ..._.pick(com.signatory, ["firstName", "lastName", "email"]) }))
    const shareholdersCorporate = _.map(data.shareholders.companies, com => ({ companyName: com.companyName, id: com.id, ..._.pick(com.signatory, ["firstName", "lastName", "email"]) }))
    data.directors = [...directorCorporate, ...data.directors.individuals]
    data.shareholders = [...shareholdersCorporate, ...data.shareholders.individuals]

    return data
  }

  const formatDirector = (data) => {
    if (!data) return null;
    const directorCorporate = _.map(_.get(data, "directors.companies", []), com => ({ companyName: com.companyName, id: com.id, ..._.pick(com.signatory, ["firstName", "lastName", "email"]) }))
    data.directors = [...directorCorporate, ..._.get(data, "directors.individuals", [])]
    return data;
  }

  const getMutation = () => {
    if (props.type == 'entity') {
      return addCompany
    } else if (params.type === 'CORPORATE_DIRECTOR') {
      return `mutation upsertQuestionnaireCompany(
        $matterId: String!
        $KYCCompanyData: KYCCompanyInput! 
        $KYCCompanyId: String
      ) {
        upsertQuestionnaireCompany(matterId: $matterId, KYCCompanyData: $KYCCompanyData, KYCCompanyId: $KYCCompanyId) {
          id
          companyName
        }
      }
      `
    } else {
      return addCompany2
    }
  }


  const submitData = (values) => {
    let mutationByType = getMutation();
    let message = props.mode == 'edit' ? 'Company Updated' : 'Company Created';


    ServerConnect.graphQlMutation(mutationByType, getVariablesMutation(props.params.matterId, stripTypenames(values))).then(result => {
      let updatedTab = params.type == 'CORPORATE_DIRECTOR' ? 'Questionnaire' : 'Kyc'
      notification.sendNotification(message, "success", 4990);
      dispatch({ type: 'GO_TO', page: Constants.PAGES.viewMatter, params: { id: props.params.matterId, tab: updatedTab } })
    }).catch(err => {
      console.log(err);
      notification.sendNotification("ERROR", "error", 4990);
    });
  }



  const getVariablesMutation = (matterId, values) => {
    if (params.type === 'CORPORATE_DIRECTOR') {
      let KYCCompanyId = props.params.id
      let KYCCompanyData = { ...modifyDataFileKYC(values) };
      delete KYCCompanyData.formKey
      delete KYCCompanyData.type
      delete KYCCompanyData.jurisdiction;

      return {
        KYCCompanyId,
        KYCCompanyData,
        matterId
      }
    } else {
      values.jurisdictionOfIncorporation = values.jurisdictionOfIncorporation === "OTHER" ? values.otherJurisdiction : values.jurisdictionOfIncorporation
      let KYCEntityData = { ...modifyDataFileKYC(values), signatory: modifyDataFileKYC(values.signatory) };
      delete KYCEntityData.isShareholder
      delete KYCEntityData.isDirector
      delete KYCEntityData.type;
      delete KYCEntityData.formKey;
      delete KYCEntityData.jurisdiction;

      let KYCEntityId = props.params.id
      return {
        matterId,
        KYCEntityData,
        KYCEntityId
      }
    }

  }

  useEffect(() => {
    if (!data && props.mode === 'edit') {
      if (params.type == 'CORPORATE_DIRECTOR') {
        Promise.resolve(getData())
          .then((result) => {
            console.log('LLER01 -               :', filterData(result[0]))
            setData(filterData(result[0]))
          })
          .then(() => {
            setLoading(false)
          })
      } else {
        Promise.resolve(getData())
          .then((result) => {
            console.log('LLER01 -               :', filterData(result))
            setData(filterData(result[0]))
          })
          .then(() => {
            setLoading(false)
          })
      }

    } else {
      setLoading(false)
    }
  }, [data, loading]);

  console.log(JSON.stringify(data, null, 2))
  return (
    <React.Fragment>
      <Loading show={loading}>
        <LayoutForm
          title={"Add Company"}
          leftEducationId={Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM[`${_.eq(props.mode, "edit") ? "EDIT" : "ADD"}`].LEFT_EDUCATION_ID}
          handleClose={closeForm}>
          <CompanyForm initialValues={props.mode == 'edit' ? { ...modifyDataFileKYC(data), formKey: getFormKey() } : { formKey: getFormKey() }} onSubmit={submitData} propsForm={{ isFlow: false }} />
        </LayoutForm>
      </Loading>
    </React.Fragment>
  )
}

export default AddKYCCompanyForm;

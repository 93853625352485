import React, { useEffect } from "react";
// import Mustache from "mustache";
// import ServerConnect from "../../../../v1/utils/ServerConnect";
import useQuery from "../../../custom/useQuery";
import _ from "lodash";
import { useLazyQuery, gql } from "@apollo/client";

const queryAnswers = `query askAnythingContentful($ask:String) {
    askAnythingContentful(ask: $ask) {
      title
      description
      link
    }
}`;

const useAnswerAcademyQuestion = (variables) => {
  const config = { showErrorNotification: true, showSuccessNotification: false };
  const { loading, error, data, refetch } = useQuery(
    queryAnswers,
    variables,
    config
  );
  useEffect(() => {
    refetch();
  }, [variables.search]);
  return { loading, error, data };
};
export default useAnswerAcademyQuestion;

export function useAnswerAcademyQuestionLazy(options) {
  const [manualQuery, { ...rest }] = useLazyQuery(
    gql`
      ${queryAnswers}
    `,
    options,
  );
  return { manualQuery, ...rest };
}

import React, { useCallback, useEffect } from "react";
import useUploadedFile from "./hooks/useUploadedFile";
import ProgressUpload from "./ProgressUpload";
import { Collapse } from "@mui/material";

const UploadComponent = ({ file, addFileUploaded, url }: any) => {
  
  const parseCustomResponse = useCallback((response) => {
    return response.data.filePond;
  }, []);

  const onCompleted = (fileUploaded) => {
    addFileUploaded(fileUploaded, file);
  };

  const { error, onUploadFile, progressUpload, response, stopUpload } = useUploadedFile({
    onCompleted,
    parseCustomResponse,
    url,
  });

  const handleUploadFile = () => {
    onUploadFile(file);
  };

  const handleStopUploadFile = () => {
    stopUpload()
  };

  useEffect(() => {
    handleUploadFile();
  }, []);

  return (
    <React.Fragment>
      <Collapse
        in={!response}
        style={{ height: "56px", width: "100%"}}
        unmountOnExit={false}
      >
        <ProgressUpload
          progress={progressUpload}
          failedUpload={error}
          uploadFileAgain={handleUploadFile}
          nameFile={file.name}
          stopUpload={handleStopUploadFile}
        />
      </Collapse>

    </React.Fragment>
    
  );
};

export default UploadComponent;

import React, { useEffect, useState, forwardRef,useRef ,useImperativeHandle} from 'react';
import classesModule from "./SVGZone.module.scss";
import classnames from "classnames"

const SVGZone = forwardRef(({ children,style}, ref) => {
    const inputRef = useRef();
    useImperativeHandle(ref, () => ({
        content: inputRef.current,
        focus: () => {
            inputRef.current.focus();
        }
    }));
    return (
        <React.Fragment>

            <div className={classnames(classesModule.SVGZone)} style={style} ref={inputRef} >
                <svg >
                    {children}
                </svg>
            </div>


        </React.Fragment >
    )
});

export default SVGZone;

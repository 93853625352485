import { Constants } from 'src/v1/utils/constants';
import { default as React, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import * as Yup from 'yup';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import Typography from 'src/modules/generic/components/Texts/Typography';
import { Box, Stack } from '@mui/material';
import CheckboxButtonSimple from 'src/modules/generic/components/Inputs/CheckboxButtonSimple';
import _ from 'lodash';
import PaymentTotal from 'src/modules/checkout/components/PaymentTotal/PaymentTotal';
import cls from "./ChooseAPayeeFormStep.module.scss"
import { PaymentProvider } from 'src/modules/checkout/hooks/usePayment';
import useSession from 'src/modules/session/hooks/useSession';

/**
* Description Step: Select the stakeholder to pay
* 
* @returns 
*/

function ChooseAPayeeFormStep() {

  const { next, context, prev, cancel, send, state } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const [coupon, setCoupon] = useState(null);
  const [amount, setAmount] = useState(null);
  const paymentTotalRef = React.useRef(null);
  const { startup } = useSession();

  const initialValues = useMemo(() => {
    return context?.ChooseAPayeeFormStep ?? {}
  }, [context]);

  const handlePrev = (values) => {
    prev();
  }

  const handlePrevRenewal = (payload) => {
    send({
      type: 'PREV_RENEWAL',
      payload: { idState: state.value, values: payload }
    });
  }

  const handleCancel = (values) => {
    cancel();
  }

  const handleSubmit = useCallback((values) => {
    if (amount?.amount<0) {
      amount.amount = 0
    }
    next({ coupon, ...values, amount })
  }, [coupon, amount, next]);

  const handleChangeValue = useCallback((value) => {
    setAmount(value)
  }, [setAmount]);

  useEffect(() => {
    dispatch(addAssistantText('-'))
    return () => {
      dispatch(clearAssistatText())
    }
  }, [dispatch]);

  const stakeholders = useMemo(
    () => {
      return _.map(context?.getStakeholders?.stakeholders ?? [], (st) => {
        return {
          value: st.id,
          label: st.fullName,
          componentProps: {
            component: "h4",
            variant: "h4",
            weight: "bold",
          },
        };
      })
    },
    [context]
  );

  const product = useMemo(() => {
    return context?.getProduct?.product ?? {}
  }, [context])

  const schema = useMemo(() => {
    if (context?.initialData?.needFirstContact) {
      return Yup.object().shape({
        authorisedSignatories: Yup.string().required("Required"),
      })
    } else {
      return Yup.object().shape({
      })
    }
  }, [context?.initialData?.needFirstContact]);

  const PreviousButton = useMemo(() => {
    //If Renew
    if (context?.CartStep) {
      return <Button variant='secondary' onClick={handlePrevRenewal}>{translate('BUTTON_PREVIOUS')}</Button>
    }
    if (startup?.hasScaleSubscription || context?.initialData?.companyType === Constants.MODULES.FORMATION.COMPANIES_TYPE.ADGM_TSL) {
      return null
    } else {
      return <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>
    }
  }, [handleCancel, startup?.hasScaleSubscription, translate, context])

  return (
    <PaymentProvider>
      <Form
        defaultValues={initialValues}
        schema={schema}
        onlySendDirty={false}
        onSubmit={handleSubmit}
        optionsForm={{ mode: 'onChange' }}
      >
        <TemplateWizard
          props={{
            title: null,
            skeleton: null,
            subTitle: null,
            variant: 'twoColumns'
          }}
          buttons={{
            previous: PreviousButton,
            cancel: <Button variant='secondary' onClick={handleCancel}>{translate('BUTTON_CANCEL')}</Button>,
            submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('BUTTON_NEXT')}</SubmitButton>,
          }}
        >
          <div>
            {/* change this if by teo steps  */}
            <Stack direction={"column"} gap={4}>
              <>
                {context?.initialData?.needFirstContact ? (
                  <>
                    <Box mt={5} mb={2}>
                      <Typography variant={'title'} color={'black'} >
                        <b>Choose a payee</b>
                      </Typography>
                    </Box>
                    <Typography variant="h5" color={"black"} component={"p"} >
                      {translate(`MODULES.CHECKOUT.PAYMENT_ADGM_PRIMARY_CONTACT_SUBTITLE`, { startup })}
                    </Typography>
                    <div>
                      <ControllerInput
                        render={CheckboxButtonSimple}
                        isMultiple={false}
                        name="authorisedSignatories"
                        direction="column"
                        options={stakeholders}
                        classNameButton={cls.CheckboxButton}
                      />
                      &nbsp;
                    </div>
                  </>
                ) : (
                  <>
                    <Box mt={5} mb={2}>
                      <Typography variant={'title'} color={'black'} >
                        <b>Your payment</b>
                      </Typography>
                    </Box>
                    <Typography variant="h5" color={"black"} component={"p"} >
                      These are the details of your payment.
                    </Typography>
                  </>
                )}
              </>
            </Stack>
          </div>
          <div className={cls.PaymentTotalContent}>
            <PaymentTotal
              company={context?.initialData?.company}
              innerRef={paymentTotalRef}
              isPaid={false}
              isProcess={false}
              product={product}
              vat={_.get(product, "defaultVAT")}
              freeVat={false}
              showBack={false}
              showTerms={false}
              showCostBox={false}
              variantProduct={"description"}
              title={(
                <div>
                  <Typography variant={'h2'} color={'black'} weight="bold" component='h2'>
                    Order Summary
                  </Typography>
                </div>
              )}
              className={cls.PaymentTotal}
              onChangeCoupon={(v) => {
                setCoupon(v)
              }}
              onChangeValue={handleChangeValue}
            />
          </div>
        </TemplateWizard>
      </Form>
    </PaymentProvider>
  );
}

export default ChooseAPayeeFormStep;


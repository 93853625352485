import { default as React, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import * as Yup from 'yup';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import useBusinessRules from "src/modules/clara/hooks/useBusinessRules";
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import classesModule from "./classes.module.scss";

function AddMore() {

  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const { isAcceleratorGoal } = useBusinessRules();
  const [defaultBtnText, setDefaultBtnText] = useState("Next");

  const initialValues = useMemo(() => {
    return context?.AddMore ?? {}
  }, [context]);

  const handleSubmit = async (values) => {
    next(values)
  }

  useEffect(() => {
    dispatch(clearAssistatText());
    if (isAcceleratorGoal()) {
      dispatch(
        addAssistantText(
          translate('MODULES.ONBOARDINGCOMPANY.FORM_INC_ADD_ANOTHER_COMPANY_TOOLTIP_ACCELERATOR')
        )
      );
    } else {
      dispatch(
        addAssistantText(
          translate('MODULES.ONBOARDINGCOMPANY.FORM_INC_ADD_ANOTHER_COMPANY_TOOLTIP')
        )
      );
    }
    return () => {
      dispatch(clearAssistatText())
    }
  }, [dispatch])

  const schema = Yup.object().shape({
    another: Yup.boolean().required(),
  });

  const options = [
    {
      value: false,
      label: translate(`MODULES.ONBOARDINGCOMPANY.FORM_INC_NO_ADD_MORE_COMPANY`),
    },
    {
      value: true,
      label: translate(`MODULES.ONBOARDINGCOMPANY.FORM_INC_ADD_MORE_COMPANY`),
    }
  ];

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: "Do you have more companies in your business?",
          subTitle: "Add a company",
          skeleton: null,
          variant: 'simple'
        }}
        buttons={{
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined} >{defaultBtnText}</SubmitButton>,
        }}
      >
        <>
          <ControllerInput
            as={CheckboxButtonSimple}
            name="another"
            variant="col_1"
            classNameButton={classesModule.buttonCheckBoxSize}
            options={options}
            onChange={value => {
              if (value) {
                setDefaultBtnText("Next");
              } else {
                setDefaultBtnText("Continue");
              }
            }}
          />
        </>
      </TemplateWizard>
    </Form>
  );
}

export default AddMore;


const useGuards = () => {    

    const isManagedByClara = (context)=>{
        return context.type === 'managedByClara'
    }

    const isDelete = (context)=>{
        return context.type === 'delete'
    }

    const isConnections = (context)=>{
        return context.type === 'connections'
    }

    return {
        isManagedByClara,
        isDelete,
        isConnections
    }
}

export default useGuards
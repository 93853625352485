import React, { useEffect, useMemo } from "react";
import {addAssistantText, clearAssistatText} from "src/modules/generic/store/action";

import AddressInput from "./AddressInput";
import Button from "../../../../../modules/generic/components/Buttons/Button";
import Form from "../../../Form/Form";
import SubmitButton from "../../../../generic/Buttons/SubmitButton/SubmitButton";
import TemplateFormDefault from "../../../../../modules/generic/templates/Modal";
import { editAddressCorporate } from "../../../../../forms/schemas/modules/startups/stakeholder/schema";
import {useDispatch} from "react-redux";
import useTranslate from "../../../../../modules/generic/hooks/useTranslate";

const CorporateStakeholderForm = ({
  initialValues,
  handleSubmit,
  handleCloseModal,
  propsTemplate,
  Template = TemplateFormDefault,
  isManagedByClara,
}) => {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const showBanners = initialValues.managedByClaraOnJurisdictionType.some(jur => jur !== 'DELAWARE')

  useEffect(() => {
    isManagedByClara && showBanners && dispatch(addAssistantText("<strong>Additional fees</strong> might apply if you need to update some of the details.</br><div style='margin-top:1rem;'>Click on the field to update to view the corresponding fees. To make a change, just edit the content and proceed. The change request will be added to your cart.</div></br> Please note: if the stakeholder is a shareholder or director in multiple companies, the change must updated and paid for all companies.",'alert'));
    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch]);

  return (
    <Form
      defaultValues={initialValues}
      schema={editAddressCorporate}
      onSubmit={handleSubmit}
    >
      <Template
        buttons={
          {
            submit: (
              <SubmitButton>
                {translate("MODULES.CLARA.BTN_SAVE_CHANGES")}
              </SubmitButton>
            ),
            cancel: (
              <Button onClick={() => handleCloseModal()} variant="secondary">
                {translate("MODULES.CLARA.BTN_CANCEL")}
              </Button>
            ),
          }
        }
        props={propsTemplate}
      >
        <AddressInput
          name={"address"}
          defaultValue={""}
          label={{ country: translate('MODULES.CLARA.ADDRESS_REGISTERED_LABEL') }}
          isRequired
          stateOptional
          zipCodeOptional
          banner={isManagedByClara && showBanners}
        />        
      </Template>
    </Form>
  );
};

export default CorporateStakeholderForm;

import React,{useEffect} from "react";
import classes from "./classes.module.scss";
import useTranslate from "../../../generic/hooks/useTranslate";
import TypographyLocal from "../../../generic/components/Texts/Typography";
import Text from '../../../../components/text/Text/Text';
import FormSelectJurisdiction from "../../forms/SelectJurisdiction";
import useNavigation from "../../../generic/hooks/useNavigation";
import { Constants } from '../../../../v1/utils/constants';
import constantsFilters from "../../constants";
import HelpLink from "../../../generic/components/HelpLink";

const SelectJurisdictionView = () => {

  const {translate} = useTranslate();
  const {goTo, params} = useNavigation();
  const linkJurisdiction = constantsFilters.JURISDICTION_LINKS[0];

  const handleOnComplete = async (values) => {
    if (values.country === constantsFilters.APROVED_FILTERS[0] && values.jurisdiction === constantsFilters.APROVED_FILTERS[1]) {
      goTo(Constants.PAGES.MODULES.FORMATION.blockAdgm, params);
    } else {
      let newParams= {
        ...params,
        ...values,
      }
      goTo(Constants.PAGES.MODULES.FORMATION.formJurisdictionDetails, newParams);
    }
  };
  useEffect(()=>{
    console.log('scopíng')
  },[])
  return (
    <React.Fragment>
        <div className={classes.contentData}>
            <div className={classes.centerFrame}>
              <div className={classes.claraTitle}>
                <TypographyLocal
                  component={"span"}
                  color={"black"}
                  className={classes.flowTitle}
                >
                  <Text uuid={'MODULES.FORMATION.SELECT_JURISDICTION_TITLE'} />
                </TypographyLocal>
              </div>
              <div className={classes.claraQuestion}>
                <TypographyLocal
                  variant={"h4"}
                  color={"black"}
                  component={"span"}
                >
                  <Text uuid={'MODULES.FORMATION.SELECT_JURISDICTION_QUESTION'} />
                </TypographyLocal>
              </div>
              <HelpLink 
                linkText={translate("MODULES.FORMATION.SELECT_JURISDICTION_LINK")}
                hrefUrl={linkJurisdiction}
              />
              <FormSelectJurisdiction 
                onCompleteSubmit={handleOnComplete}
              >
              </FormSelectJurisdiction>
            </div>
        </div>
    </React.Fragment>
  );
};

export default SelectJurisdictionView;

import React, {useEffect, useState} from 'react';
import {Constants} from '../../../../../../utils/constants'
import {useDispatch} from "react-redux";
import _ from "lodash";
import notification from "../../../../../../utils/notification";
import Mustache from 'mustache';
import ServerConnect from "../../../../../../utils/ServerConnect";
import Loading from '../../../../../Loading/Loading';
import {LayoutForm} from '../../../../../../containers';
import {renameKey, stripTypenames} from "../../../../../../utils/graphql-util";
import ShareholdersForm from '../../../../../../containers/Forms/Questionniare/Shareholders/ShareholdersForm';
import {getShareholders, upsertQuestionnaire} from '../config'


const EditShareholders = (props) => {
    const dispatch = useDispatch();

    const closeForm = () => {
        dispatch({ type: 'GO_TO', page: Constants.PAGES.viewMatter, params: { id: props.params.matterId, tab:'questionnaire' } })
    }

    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [list, setList] = useState([]);
    const [shareData, setShareData] = useState({});

    const [initialValues, setInitialValues] = useState(null)

    const getData = () => {
        const query_params = {
            matterId: props.params.matterId
        };

        const query = Mustache.render(getShareholders, query_params)
        ServerConnect.graphQlQuery(query).then(({ getMattersList }) => {
            let shareData = _.get(getMattersList, "[0].questionnaire.sharesData");
            setData(props.params.type ? shareData : { ...shareData, shareholders: _.map(shareData.shareholders, sh => sh.id) })
            setLoading(false)
            setShareData(shareData)
        });
    }


    const mapShareholders = (shareholders) => {
        return _.map(shareholders, sh => {
            let share = _.find(shareData.shareholders, shareholder => shareholder.id === sh);
            return share ? getShareholder(share) : { entityId: sh, shares: 1, paid: true };
        });
    }

    const getShareholder = (shareholder) => {
        return _.omit(renameKey(shareholder, { id: "entityId" }), "label");
    }

    const submitData = (values) => {
        let message = 'Company Updated';
        const { type } = props.params;
        const shareholders = type ? values.shareholders.map(sh => getShareholder(sh)) : mapShareholders(values.shareholders);
        let value = {
            matterId: props.params.matterId,
            questionnaireData: {
                sharesData: {
                    ..._.omit(values, "sharesAssigned", "sharedIssues"),
                    shareholders
                }
            }
        }
        ServerConnect.graphQlMutation(upsertQuestionnaire, stripTypenames(value))
            .then(result => {
                notification.sendNotification(message, "success", 4990);
                dispatch({ type: 'GO_TO', page: Constants.PAGES.viewMatter, params: { id: props.params.matterId, tab:'questionnaire' } })
            }).catch(err => {
                console.log(err);
                notification.sendNotification("ERROR", "error", 4990);
            });
    }

    const modifyDataFile = (values) => {
        var newValues = _.forOwn(values, (val, key) => {
            if (_.hasIn(val, 'file')) {
                return values[key] = val.file
            }
        })
        console.log('NEWVALS', { ...newValues, KYCCompanyId: props.params.id })
        return newValues



    }


    useEffect(() => {
        if (!data && props.mode === 'edit') {
            setLoading(true)
            getData();
        }
    }, [data, loading]);

    const title = props.params.type  ? "Shares issued to each shareholder" : "shareholders";
    return (
        <React.Fragment>
            <Loading show={loading}>
                <LayoutForm
                    title={title}
                    showCloseIcon={false}
                    leftEducationId={Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM[`${_.eq(props.mode, "edit") ? "EDIT" : "ADD"}`].LEFT_EDUCATION_ID}
                    handleClose={closeForm}>
                    <ShareholdersForm initialValues={data} onSubmit={submitData} shareholders={shareData.shareholders} closeForm={closeForm} />
                </LayoutForm>
            </Loading>
        </React.Fragment>
    )
}

export default EditShareholders;

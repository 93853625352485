import React, { useEffect, useState } from 'react'
import classes from "./OtherTab.module.scss";
import classnames from 'classnames';
import TypographyLocal from "../../../../../../../modules/generic/components/Texts/Typography";
import useTranslate from "../../../../../../../modules/generic/hooks/useTranslate";
import ReactAvatar from 'react-avatar';
import _ from "lodash";
import useSession from '../../../../../../../modules/session/hooks/useSession'
import useGetGroupCompanies from "../../../../../../../components/forms/modules/startups/Equity/graphql/useGetGroupCompanies";
import useUpsertFormationCompany from '../../../../../../../modules/startup/GroupCompany/graphql/mutations/useUpsertFormationCompany'
import Skeleton from '@material-ui/lab/Skeleton';

const OtherTabPage = ({ data, company: groupCompany, groupCompanyId, ...props }) => {

  const { translate } = useTranslate();
  const { startupId } = useSession();
  const [isLoading, setIsLoading] = useState(true)
  const [filteredCompanies, setfilteredCompanies] = useState([]);
  const [upsertFormationCompany] = useUpsertFormationCompany({ showSuccessNotification: false }, {})
  const {
    data: dataGroupCompanies,
  } = useGetGroupCompanies({ variables: { startupId } });

  const handleChangeCompany = async (idFind) => {
    setIsLoading(true);
    let copyData = filteredCompanies;
    _.forEach(copyData, (company) => {
      if (company.id === idFind) {
        company.valid = !company.valid;
      }
    })
    setfilteredCompanies([...copyData]);
    let sendData = _.filter(copyData, (data) => data.valid);
    await upsertFormationCompany({
      startupId,
      groupCompanyId: groupCompanyId,
      groupCompanyData: {
        formationData: {
          sharesInIncorporatedCompanies: sendData.map(gc=>({id:gc.id})),
        }
      }
    })
    setIsLoading(false)
  }

  useEffect(() => {
    let filtered = _.filter(dataGroupCompanies, (company) => company.kind === "INCORPORATED");
    let result = []
    if (filtered.length > 0) {
      let add;
      _.forEach(filtered, (filterGropCompany, index) => {
        add = {
          id: filterGropCompany.id,
          name: filterGropCompany.name,
          avatar: filterGropCompany.avatar,
        }
        let exist = !!_.find(_.get(data, "sharesInIncorporatedCompanies", []), (share) => share.id === filterGropCompany.id);
        if (exist) {
          add.valid = true;
        } else {
          add.valid = false;
        }
        result.push(add);
      })
    }
    setIsLoading(false)
    setfilteredCompanies(result);
  }, [JSON.stringify(dataGroupCompanies), JSON.stringify(data)])

  return (
    <>
      <div className={classes.sourceContainer}>
        <TypographyLocal
          variant={"s"}
          weight={"light"}
          className={classes.title}
        >
          {translate("TAB_OTHER_TITLE")}
        </TypographyLocal>
        {isLoading ? (
          <>
            <div className={classes.row}>
              <div className={classes.titleArea}>
                <TypographyLocal
                  variant={"s"}
                  weight={"light"}
                  className={classes.text}
                >
                  {translate("TAB_OTHER_HIT_COMPANIES")}
                </TypographyLocal>
              </div>
              {filteredCompanies && (
                <>
                  {Array(filteredCompanies.length).fill(1).map((el, i) =>
                    <div className={classes.containerSkeleton}>
                      <Skeleton variant="rect" height={"3.5rem"} width={"3.5rem"} className={classes.SkeletonRounder} />
                      <p><Skeleton variant="text" height={"0.9231rem"} width={"4.6154rem"} /></p>
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        ) : (
          <>
            {(filteredCompanies && filteredCompanies.length > 0) && (
              <div className={classes.row}>
                <div className={classes.titleArea}>
                  <TypographyLocal
                    variant={"s"}
                    weight={"light"}
                    className={classes.text}
                  >
                    {translate("TAB_OTHER_HIT_COMPANIES")}
                  </TypographyLocal>
                </div>
                {filteredCompanies.map((company) => {
                  return (
                    <div
                      className={classnames(
                        {
                          [classes.containerCompany]: company.valid,
                          [classes.opaque]: !company.valid,
                          [classes.locked]: groupCompany.isLocked
                        })}
                      onClick={() => !groupCompany.isLocked && handleChangeCompany(company.id)}
                    >
                      <ReactAvatar
                        name={company.name}
                        round={true}
                        size={"3.5rem"}
                        src={company.avatar}
                      />
                      <p>{company.name}</p>
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
        <div className={classes.row}>
          <div className={classes.titleArea}>
            <TypographyLocal
              variant={"s"}
              weight={"light"}
              className={classes.text}
            >
              {translate("TAB_OTHER_HIT")}
            </TypographyLocal>
          </div>
          {_.get(data, "notYetFormedCompanyName", null) !== null ? (
            <TypographyLocal
              variant={"s"}
              weight={"semibold"}
              className={classes.contain}
            >
              {translate("TAB_OTHER_LABEL_AFIRMATIVE", { description: _.get(data, "notYetFormedCompanyName", "") })}
            </TypographyLocal>
          ) : (
            <TypographyLocal
              variant={"s"}
              weight={"semibold"}
              className={classes.contain}
            >
              {translate("TAB_OTHER_LABEL_NEGATIVE")}
            </TypographyLocal>
          )}
        </div>
      </div>
    </>
  );
};

export default OtherTabPage;

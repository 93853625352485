import React, { useEffect } from 'react';
import useLazyQuery from "../../../../custom/useLazyQuery"

export const GET_STARTUP_USERS_PROFILE = `query getStartupUsersProfiles($startupId:ID!) {
    getStartupUsersProfiles(startupId: $startupId) {
        _id
        userId
        role
        fullName
        avatar
        email
        lastLogin
        lastInvitationSent
    }
}`;
const useShareProfiles = (variables,config) => {
  const configDefault = {errorMessage: 'GENERIC_PERMISSION_ERROR', showErrorNotification: true, showSuccessNotification: false}
  const { manualQuery,loading, error, data, refetch } = useLazyQuery(GET_STARTUP_USERS_PROFILE, variables, {...configDefault,...config});
  return { manualQuery,loading, error, data, refetch };
}
export default useShareProfiles;

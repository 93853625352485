import _ from 'lodash';
import useMachine from '../../../../../generic/context/MachineContext/useMachine';
import AddWarrantForm from '../../../forms/AddWarrantForm';
import React, { useEffect } from 'react';
import Button from "../../../../../generic/components/Buttons/Button";
import useTranslate from '../../../../../generic/hooks/useTranslate';
import { useDispatch } from "react-redux";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import { addAssistantText, clearAssistatText } from "../../../../../generic/store/action";
import useModal from "../../../../../generic/hooks/useModal";
// import { useParams } from 'react-router';

/**
 * Step para editar los datos de SOF OTHER
 * @param {*} param0
 * @returns
 */
function AddWarrant({ openParam }) {

  const { next, send, context, prev } = useMachine();
  const { translate } = useTranslate()
  const dispatch = useDispatch();
  // const params = useParams<{ id }>()
  const { closeModal: onClose } = useModal();

  const handleCancel = (values) => {
    onClose();
    clearAssistatText()
  }

  const handlePrevious = () => {
    prev()
  }

  const handleInitModal = () => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText(translate('MODULES.EQUITY.ADD_EQUITY.STEP_ADD_CONVERTIBLE.TOOLTIP')))
  }

  useEffect(() => {
    handleInitModal()
    return () => {
      dispatch(clearAssistatText());
    }
  }, []);

  const handleCompleteSubmit = async (values) => {
    next(values)
  }

  const getInitialValues = () => {
    if (_.get(context, 'selectCompany') || _.get(context, 'selectStakeholder')) {
      return { 
        groupCompany: _.get(context, 'selectCompany'),
        owner: _.get(context, 'selectStakeholder')
      }
    }
    // return params.id ? { owner: { id: params.id } } : {};
    return {};
  }

  return (
    <AddWarrantForm
      initialValues={getInitialValues()}
      dataGroupCompanies={context.getStakeholdersGroupCompanies.groupCompanies}
      dataStakeholders={context.getStakeholdersGroupCompanies.stakeholders}
      onAdd={(values) => send({
        type: 'REFRESH',
        payload: { values }
      })}
      buttons={{
        previous: (
          <Button onClick={handlePrevious}>Previous</Button>
        ),
        cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
      }}
      onCompleteSubmit={handleCompleteSubmit}
      propsTemplate={{
        title: translate('MODULES.EQUITY.ADD_EQUITY.STEP_ADD_WARRANT.TITLE'),
        subTitle: translate('MODULES.EQUITY.ADD_EQUITY.STEP_ADD_WARRANT.SUBTITLE')
      }}
      Template={TemplateWizard}
    />
  );
}

export default AddWarrant;

import React, { useEffect, useState } from 'react';
import _, { uniqueId } from 'lodash'
import classes from './AddServices.module.scss';
import { PopoverOption } from '../../../../../../components';
import Search from '../../../../General/Search/Search';
import Scrollbar from "../../../../../../../components/generic/Scrollbar/Scrollbar";
import FieldArrayComponent from '../../../../../Forms/ProposalAndEngagementsForm/V2/FieldArrayComponent';
import ControllerInput from '../../../../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import TextareaInput from '../../../../../../../components/inputs/Textarea/TextareaInput';
import TextInput from '../../../../../../../components/inputs/Text/TextInput';
import HiddenValueSetter from '../../../../../../../modules/generic/components/HiddenValueSetter';

const AddServices = ({ placeholder, name, listOtherServices = [], servicesList = [] }) => {

    const refFieldArray = React.useRef();
    const handleAddService = (value) => {
        if (value) {
            return refFieldArray.current.append(value);
        }
        return refFieldArray.current.append({ description: "", comments: "", amount: "", currency: "" });
    };

    return (
        <div className={classes.containerService}>

            <PopoverOption
                renderElement={MenuServices}
                propsRenderElement={{
                    handleAddService,
                    listServices: listOtherServices,
                }}
            >
                <input
                    className={`InputGeneric`}
                    style={{ width: '29.375rem' }}
                    readOnly
                    placeholder={placeholder}
                />
            </PopoverOption>

            <FieldArrayComponent
                name={name}
                innerRef={refFieldArray}
                component={ServiceComponent}
                hiddenButtonRemoveDefault
                hideButtonAdd
                defaultValues={{ description: "", comments: "", amount: "", currency: "" }}
            />
        </div>
    )
};

export default AddServices;


const ServiceComponent = ({ index, value, name, remove }) => {

    return (
        <div className={classes.services_component_container}>

            <ControllerInput
                name={`${name}.engagementPriceId`}
                defaultValue={_.get(value, 'engagementPriceId', '')}
                render={HiddenValueSetter}
            />
			<ControllerInput
				name={`${name}.type`}
				defaultValue={_.get(value, 'type', '')}
				render={HiddenValueSetter}
			/>
			<ControllerInput
				name={`${name}.currency`}
				defaultValue={_.get(value, 'currency', '')}
				render={HiddenValueSetter}
			/>
			<ControllerInput
                name={`${name}.description`}
                render={TextareaInput}
                rows={4}
                defaultValue={_.get(value, 'description', '')}
            />
            <ControllerInput
                name={`${name}.amount`}
                render={TextInput}
                defaultValue={_.get(value, 'amount', '')}

            />
            <ControllerInput
                name={`${name}.comments`}
                render={TextareaInput}
                rows={4}
                defaultValue={_.get(value, 'comments', '')}
            />
            <button type={"button"} className={`genericBlue`} onClick={() => remove(index)}>Remove</button>

        </div>
    )
};

const MenuServices = ({ handleAddService, listServices, closePopover }) => {

    const [searchValue, setSearchValue] = useState("");
    const [list, setList] = useState(listServices);

	const mapItem = (item) => {
		return {
			...item,
			id: uniqueId('serviceItem_'),
			engagementPriceId: item.id
		}
	};

	const handleOnClickService = (service) => {
        handleAddService(mapItem(service));
        closePopover()
    };

    // const addService = () => {
    //     handleAddService();
    //     closePopover();
    // };

    useEffect(() => {

        const handleOnChangeFilter = () => {
            let data = listServices;
            if (searchValue && searchValue !== "") {
                let dataFilter = _.filter(data, (element) => {
                    return element.description.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0;
                });
                return dataFilter;
            }
            return data
        };

        setList(handleOnChangeFilter());

    }, [searchValue, listServices]);

    return (
        <div className={classes.MenuServices}>
            <Search placeHolder={`Search...`} onChange={setSearchValue} />
            <Scrollbar className={`${classes.containerMenuListService}`}>
                {/*<div onClick={addService} className={classes.addService} >Add a new Service</div>*/}
                {list.map((service, index) => {
                    return (
                        <div key={index} className={classes.itemService} onClick={() => handleOnClickService(service)}>
                            <div>{service.description}</div>
                            <span>{`${service.currency} ${service.amount}`}</span>
                        </div>
                    )
                })}
            </Scrollbar>
        </div>
    )
};

import React, {useEffect} from 'react';
import classes from "../ViewForms.module.scss";
import {useDispatch} from "react-redux";
import ServerConnect from "../../../../utils/ServerConnect";


const NASView = (props) => {

    const dispatch = useDispatch();
    const { mode, data } = props;

    const downloadHandler = (file) => {
        let url = ServerConnect.getUrlFile(file.file.id);
        ServerConnect.downloadFileAsDoc(url, file.file.name);
    }


    useEffect(() => {
    }, [,]);


    return (
        <React.Fragment>
            <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                <label>Signatories Details</label>
            </div>
            <hr className={`${classes.Separator} ${classes.modifiedSeparator}`} />
            <div>
                <span className={classes.regularText}>User has selected to use the <b>Nominee Authorised Signatory Service.</b></span>
            </div>


        </React.Fragment>
    )
}

export default NASView;

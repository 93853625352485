import { useMemo } from 'react';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import AddThirdPartyFormStep from '../steps/AddThirdPartyFormStep';
import WhoIsGoingToBeYourAuthorisedSignatoryFormStep from '../steps/WhoIsGoingToBeYourAuthorisedSignatoryFormStep';
import HowDidTheyEnterFormStep from '../steps/HowDidTheyEnterFormStep';
import WhoIsSecondAuthorisedSignatoryFormStep from '../steps/WhoIsSecondAuthorisedSignatoryFormStep'

const useSteps = () => {

  return useMemo(() => ({
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    AddThirdPartyFormStep: {
      render: AddThirdPartyFormStep
    },
    WhoIsGoingToBeYourAuthorisedSignatoryFormStep: {
      render: WhoIsGoingToBeYourAuthorisedSignatoryFormStep
    },
    SaveOnWhoIsGoingToBeYourAuthorisedSignatoryFormStep: {
      render: TemplateWizardSkeletonMachine,
    },
    SaveOnAddThirdPartyFormStep: {
      render: TemplateWizardSkeletonMachine,
    },
    SaveAddClaraNominee:{
      render: TemplateWizardSkeletonMachine,
    },
    EntryTypeFormStep:{
      render: HowDidTheyEnterFormStep
    },
    SelectSecondAuthorisedSignatoryFormStep:{
      render: WhoIsSecondAuthorisedSignatoryFormStep
    }
  }), []);
}

export default useSteps;

import React, { FC } from "react";
import Scrollbar from "src/components/generic/Scrollbar/Scrollbar";
import Search from "src/components/generic/Search/Search";
import { InputWithBanner } from "src/components/inputs/InputWithBanner";
import ControllerInput, {
  ControllerInputProps,
} from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import CheckboxButtonSimple from "../../Inputs/CheckboxButtonSimple/index";
import classes from "./styles.module.scss";
import { Box } from "@mui/material";

interface Props {
  controllerInputProps: ControllerInputProps & { name: string };
  withBanner?: boolean;
  info?: string;
  placeholderSearch: string;
}
const SearchWithList: FC<Props> = ({
  controllerInputProps,
  withBanner = false,
  info,
  placeholderSearch,
}) => {
  const [searchValue, setSearchValue] = React.useState("");
  const options = controllerInputProps.options.filter((option) =>
    option.label.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <Box display={"flex"} flexDirection={"column"} gap={"11px"}>
      <Box sx={{ paddingBottom: "13px"}}>
        <InputWithBanner
          component={Search}
          info={info}
          show={withBanner}
          controllerInputProps={{
            name: "search",
            placeholder: placeholderSearch ?? "Search",
            onChange: setSearchValue,
            value: searchValue,
            className: classes.searchSize,
          }}
          fieldsToWatch={[controllerInputProps.name]}
          bannerLogicType="values"
          applyLogic={(values) => values[1]}
        />
      </Box>
      <Scrollbar className={classes.scrollbar}>
        <ControllerInput
          {...controllerInputProps}
          className={classes.CheckboxButton}
          classNameButton={classes.buttonCheckBox}
          options={options}
          isMultiple={false}
          render={CheckboxButtonSimple}
          onChange={controllerInputProps.onChange}
        />
      </Scrollbar>
    </Box>
  );
};
export default SearchWithList;

import React from 'react'
import {ClaraUpload} from "../../../../../components";
import {Field} from "formik";
import classesForm from "../../Form.module.scss";

const Curriculum=({...props})=>{
    return(<>
        <h1>Curriculum Vitae</h1>
    <article>We require a copy of your most up to date CV in either Microsoft Word or PDF format.</article>
        <Field name={`curriculumVitaeDoc`}>
            {({ field, form }) => (
                <ClaraUpload
                    name={`filepond`}
                    label='Upload CV: '
                    field={field}
                    form={form}
                    className={classesForm.fileUpload}
                />
            )}
        </Field>
    </>)
}
export default Curriculum;
import React, { useEffect, useMemo, useState } from "react";
import useReducerModalSteps from "./useReducerModalSteps";
import { Step, StepsDots } from "../../Steps";
import Button from "../../Buttons/Button";
import Modal from "../Default";
import _ from "lodash";
import "./ModalSteps.scss";
import { DialogProps } from "@material-ui/core/Dialog";

const stepsDefualts = [
  { step: 0, viewed: true },
  { step: 1, viewed: false },
  { step: 3, viewed: false },
  { step: 4, viewed: false },
  { step: 5, viewed: false },
  { step: 5, viewed: false },
];

interface ModalStepsProps extends DialogProps {
  componentLeftContend?: any;
  componentRightContend?: any;
  pages: Array<any>;
  open: boolean;
  handleLastStep?(): void;
  className?: string;
}

const ModalSteps: React.FC<ModalStepsProps> = ({
  open,
  handleLastStep,
  componentLeftContend: ComponentLeftContend,
  componentRightContend: ComponentRightContend,
  pages = [],
  children,
  className,
  ...propsDialog
}) => {

  const [actualStep, setActualStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [steps, updateSteps] = useState<Array<Step>>([]);
  const { state, ...dispatchModalSteps } = useReducerModalSteps();

  const handleNextStep = async (numberStep: number) => {
    setLoading(true);
    if (_.get(state, "buttonNext.onClick")) {
      await state.buttonNext.onClick();
    }
    if (handleLastStep && numberStep === pages.length) {
      handleLastStep();
    }
    if (numberStep < pages.length) {
      setActualStep(numberStep);
      updateSteps((pre) =>
        pre.map((step, index) =>
          index === numberStep ? { ...step, viewed: true } : step
        )
      );
    }
    setLoading(false);
  };

  const handlePrevStep = (numberStep: number) => {
    setActualStep(numberStep);
  };

  const stepsOfContenful = useMemo(() => {
    if (!pages.length) return [];
    return pages;
  }, [JSON.stringify(pages)]);

  useEffect(() => {
    if (!steps.length && !!stepsOfContenful.length) {
      updateSteps(_.take(stepsDefualts, stepsOfContenful.length));
    }
  }, [JSON.stringify(stepsOfContenful)]);

  return (
    <Modal
      open={open}
      className={`container_modal_tour ${className}`}
      {...propsDialog}
    >
      <div className={"container-tour"}>
        <div className={` ${"container-blocks-modal-tour"}`}>
          <>
            {children ? (
              children
            ) : (
              <>
                <div className={"left-content-block-modal-tour"}>
                  <ComponentLeftContend
                    pages={pages}
                    actualStep={actualStep}
                    setStep={updateSteps}
                    {...{ ...state, ...dispatchModalSteps }}
                  />
                </div>
                <div className={`right-content-block-modal-tour`}>
                  <ComponentRightContend
                    pages={pages}
                    actualStep={actualStep}
                    setStep={updateSteps}
                    {...{ ...state, ...dispatchModalSteps }}
                  />
                </div>
              </>
            )}
          </>
        </div>
        <div className={"container-buttons-modal-tour"}>
          <StepsDots
            className={"container-steps-dots"}
            steps={steps}
            actualStep={actualStep}
            setStep={setActualStep}
          />
          <div className={"d-flex flex-row mb-0"}>
            {!!actualStep && (
              <Button
                disabled={
                  actualStep >= pages.length ||
                  actualStep === 0 ||
                  state.buttonPrev.disabled
                }
                onClick={() => handlePrevStep(actualStep - 1)}
              >
                <>{state.buttonPrev.label}</>
              </Button>
            )}
            <Button
              loading={loading}
              className={"custom_button_primary"}
              variant={"primary"}
              onClick={() => handleNextStep(actualStep + 1)}
              disabled={actualStep >= pages.length || state.buttonNext.disabled}
            >
              <>{state.buttonNext.label}</>
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalSteps;

import React, { FC, useRef } from "react";
import * as Yup from "yup";
import Form from "../../../../../components/forms/Form/FormL";
import SubmitButton from "../../../../generic/components/Buttons/SubmitButton";
import CheckboxButtonIcon from "../../../../generic/components/Inputs/CheckboxButtonIcon";
import ControllerInput from "../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import FormProps from "../../../../generic/forms/interfaces/FormProps";
import TemplateFormDefault from "../../../../generic/templates/Modal";
import classes from "./styles.module.scss";
import { useDispatch } from "react-redux";
import {
  addAssistantText,
  clearAssistatText,
} from "src/modules/generic/store/action";

const schema = Yup.object().shape({
  type: Yup.string().required("Required"),
});
interface FormSelectEquityFormProps extends FormProps {}

const SelectEquityForm: FC<FormSelectEquityFormProps> = ({
  initialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  groupCompanyId,
  buttonSubmitProps,
  propsTemplate,
}: FormSelectEquityFormProps) => {
  const dispatch = useDispatch();
  const options = [
    {
      value: "SHARE",
      icon: "Pen-paper",
      label: "Share position",
      color: "#2c74ff",
    },
    {
      value: "CONVERTIBLE",
      icon: "Create-and-manage-cap-tables",
      label: "Convertible",
      color: "#2c74ff",
    },
    {
      value: "OPTION",
      icon: "Pie-chart",
      label: "Option grant",
      color: "#2c74ff",
    },
    {
      value: "WARRANT",
      icon: "Dollar",
      label: "Warrant",
      color: "#2c74ff",
    },
  ];
  const handleTooltips = (value) => {
    dispatch(clearAssistatText());
    dispatch(
      addAssistantText(
        "If this stakeholder has multiple equity positions, here you’ll be able to add them one after the other. You will also be asked to upload the related documents or generate them with Clara if they are new equity positions."
      )
    );
    switch (value) {
      case "SHARE":
        dispatch(
          addAssistantText(
            "Shares represent ownership interests in a company. A person who owns shares is known as a Shareholder, and the rights and benefits can differ if the shares in the company are divided into different types or “classes”."
          )
        );
        break;
      case "CONVERTIBLE":
        dispatch(
          addAssistantText(
            "A convertible is a debt or equity (or combination of the two) instrument that converts to shares in a company in the future."
          )
        );
        break;
      case "OPTION":
        dispatch(
          addAssistantText(
            "Option grants give the option holder the right to buy options in the company in the future. <a rel='noopener noreferrer' target='_blank' href='https://help.clara.co/en/articles/6417338-an-introduction-to-share-incentive-plans-grant-agreements-on-clara' >Click here</a> to find out more about the difference between options and shares."
          )
        );
        break;
      case "WARRANT":
        dispatch(
          addAssistantText(
            "A warrant gives its holder the right to purchase a specific number of shares in a company within a specified period and at a specified price."
          )
        );
        break;
      default:
        break;
    }
  };
  const refForm = useRef();
  return (
    <>
      <Form
        initialValues={initialValues || {}}
        schema={schema}
        onlySendDirty={false}
        onSubmit={onCompleteSubmit}
        ref={refForm}
        optionsForm={{ mode: "onChange" }}
      >
        <Template
          buttons={{
            ...buttons,
            submit: <SubmitButton {...buttonSubmitProps}>Next</SubmitButton>,
          }}
          props={propsTemplate}
        >
          <ControllerInput
            as={CheckboxButtonIcon}
            className={classes.checkboxMultiple}
            name="type"
            options={options}
            onChange={handleTooltips}
          />
        </Template>
      </Form>
    </>
  );
};

export default SelectEquityForm;

import { Field } from "formik";
import Parser from "html-react-parser";
import _ from 'lodash';
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import ChatFileUploaderAndSelector from '../../../components/inputs/ChatFileUploaderAndSelector/ChatFileUploaderAndSelector';
import FileUploadChatVersionable from "../../../modules/chat/components/fileUpload";
import { clearMessageChat, setInitialFlow } from '../../../store/actions/chatbot';
import { goToPage, goToPrevPage } from '../../../store/actions/page';
import Checkbox from "../../containers/Forms/Inputs/Checkbox";
import OptionsButtonInput from "../../containers/Forms/Inputs/OptionsButtonInput";
import TextAreaInput from "../../containers/Forms/Inputs/TextAreaInput";
import TextInput from "../../containers/Forms/Inputs/TextInput";
import RegistedAddressInput from "../../containers/Forms/KYC/RegisteredAddressInput/RegistedAddressInput";
import ClaraCountrySelector from "../Form/ClaraCountrySelector/ClaraCountrySelector";
import ClaraInputSelect from "../Form/ClaraInputSelect/ClaraInputSelect";
import ClaraSelect from "../Form/ClaraSelect/ClaraSelect";
import ClaraUpload from "../Form/ClaraUpload/ClaraUpload";
import classesNullResponse from "../Form/ClaraUpload/ClaraUpload.module.scss";
import {
    ChatAdditionalSignature,
    ChatADGMApproveDocuments,
    ChatAgreementDirectos,
    ChatArticlesAssociation,
    ChatAuthorizedSignatories,
    ChatBeneficialOwners,
    ChatCompanyDetails,
    ChatCompanyName,
    ChatDataProtection,
    ChatDeclareProvideGenuineInformation,
    ChatForm,
    ChatKYCEntityEditMembers,
    ChatKYCEntityHowManyEntities,
    ChatKYCEntityPepIndividuals,
    ChatPrimaryAsset,
    ChatScopingHoldingCompanySPV,
    ChatShareCaptial,
    ChatShareholderDirectors,
    ChatSharesIssues
} from "../index";
import { Constants } from './../../utils/constants';
import classes from "./Chat.module.scss";





const Handlebars = require("handlebars");
const NumeralHelper = require("handlebars.numeral");
NumeralHelper.registerHelpers(Handlebars);

const dropzoneRef = React.createRef();

class FactoryChat extends Component {

    constructor(props) {
        super(props);
        this.state = {
            areCountries: false
        }
    }

    isOptionCountry(entities) {
        return entities.includes("countries")
    }

    renderInput = (bot_message, newKey, draftId, setFieldValue) => {

        newKey = newKey ? newKey + "." : "";
        switch (_.get(bot_message, "type")) {
            case 'MULTIFIELD':
                return (
                    bot_message.inputConfig.fields.map(field => {
                        return this.renderInput(field, bot_message.responseKeyName);
                    })
                )
            case 'TEXT':
            case 'EMAIL':
            case 'FLOAT':
            case 'NUMBER':
                return (
                    <Field name={newKey + bot_message.responseKeyName}>
                        {({ field, form }) => (
                            <React.Fragment>
                                <TextInput
                                    showRedStar={false}
                                    required={true}
                                    type='text'
                                    field={field}
                                    label={'    '}
                                    form={form}
                                    placeholder={bot_message.inputConfig.placeHolder}
                                    className={classes.ChatInputText}
                                />
                                {
                                    bot_message.inputConfig.allowNullResponse
                                    && <div className={classesNullResponse.NullResponse}>
                                        <i className={classesNullResponse.NullReponseLink} onClick={() => this.props.functions.submitQuestion(form.values, form)}>{this.props.params.activeQuestion.bot_message.iDontKnowText || "I don't know"}</i>
                                    </div>
                                }
                            </React.Fragment>
                        )}
                    </Field>
                );
            case 'TEXTAREA':
                return (
                    <React.Fragment>
                        {_.get(bot_message, "inputConfig.subTitle") && (
                            <p className={classes.subTitle}>{Parser(_.get(bot_message, "inputConfig.subTitle"))}</p>
                        )}
                        <Field name={newKey + bot_message.responseKeyName}>
                            {({ field, form }) => (
                                <React.Fragment>
                                    <TextAreaInput
                                        required={true}
                                        field={field}
                                        form={form}
                                        autoComplete='off'
                                        placeholder={bot_message.inputConfig.placeHolder}
                                        showRequired={false}
                                        className={classes.ChatInputTextarea}
                                    />
                                    {
                                        bot_message.inputConfig.allowNullResponse
                                        && <div className={classesNullResponse.NullResponse}>
                                            <i className={classesNullResponse.NullReponseLink} onClick={() => this.props.functions.submitQuestion(form.values, form)}>{this.props.params.activeQuestion.bot_message.iDontKnowText || "I don't know"}</i>
                                        </div>
                                    }

                                </React.Fragment>
                            )}
                        </Field>
                    </React.Fragment>
                );
            case 'DATE':
                return (
                    <Field name={newKey + bot_message.responseKeyName}>
                        {({ field, form }) => (
                            <React.Fragment>
                                <TextInput
                                    showRedStar={false}
                                    required={true}
                                    type='date'
                                    field={field}
                                    form={form}
                                    placeholder={"Date"}
                                    className={classes.ChatInputDate}
                                    prevComponentChange={(e) => this.props.functions.handleDateChange(e, field, form)}
                                >

                                </TextInput>
                                {
                                    bot_message.inputConfig.allowNullResponse
                                    && <div className={classesNullResponse.NullResponse}>
                                        <i className={classesNullResponse.NullReponseLink} onClick={() => this.props.functions.submitQuestion(form.values, form)}>{this.props.params.activeQuestion.bot_message.iDontKnowText || "I don't know"}</i>
                                    </div>
                                }
                            </React.Fragment>
                        )}
                    </Field>
                );
            case 'FILE_UPLOAD':
                if (bot_message.inputConfig && bot_message.inputConfig.isMultiple) {
                    // Multiple File Upload
                    return (
                        <FileUploadChatVersionable
                            newKey={newKey}
                            dropzoneRef={dropzoneRef}
                            bot_message={bot_message}
                            params={this.props.params}
                            functions={this.props.functions}
                        />)
                } else {
                    // Normal File Upload
                    return (
                        <Field name={bot_message.responseKeyName}>
                            {({field, form}) => (
                                <ClaraUpload
                                    showRedStar={false}
                                    required={true}
                                    name={`filepond`}
                                    field={field}
                                    form={form}
                                    className={classes.ChatClaraUpload}
                                    allowNullResponse={bot_message.inputConfig && bot_message.inputConfig.allowNullResponse}
                                    submitNullResponse={() => this.props.functions.submitQuestion(form.values, form)}
                                />
                            )}
                        </Field>
                    );
                }
            case 'OPTIONS':

                if (bot_message.inputConfig && bot_message.inputConfig.isSelect) {
                    // Normal Combo
                    const isCountrySelector = this.isOptionCountry(_.get(bot_message.inputConfig, "entities") || []);
                    if (isCountrySelector) {
                        const isRequired = _.get(bot_message,"inputConfig.allowNullResponse",bot_message.allowNullResponse) ? {
                            country : false,
                            jurisdiction: false,
                            otherJurisdiction: false
                        }:{}
                        return (<div className={classes.ChatInputClaraSelect}>
                                <ClaraCountrySelector
                                    jurisdictionsEnabled={(bot_message.responseKeyName !== 'nationality')}
                                    countryField={bot_message.responseKeyName}
                                    label={""}
                                    jurisdictionLabel={""}
                                    otherJurisdictionLabel={""}
                                    additionalStyle={"chatSelector"}
                                    additionalStyleOther={"otherJurisdiction"}
                                    setFieldValue={setFieldValue}
                                    isRequired = {{
                                        country : false,
                                        jurisdiction: false,
                                        otherJurisdiction: false
                                    }}
                                />
                            </div>)
                    } else {

                        return ( <Field name={bot_message.responseKeyName}>
                                {({ field, form }) => (
                                    <React.Fragment>
                                        <ClaraInputSelect
                                            showRedStar={false}
                                            required={true}
                                            field={field}
                                            form={form}
                                            list={ _.get(bot_message.inputConfig, "options", []).map(o => ({ id: o.id, label: o.text }))}
                                            search={true}
                                            areCountries={false}
                                            className={classes.ChatInputClaraInputSelect}
                                        />
                                        {
                                            bot_message.inputConfig.allowNullResponse
                                            &&
                                            <div className={classesNullResponse.NullResponse}>
                                                <i className={classesNullResponse.NullReponseLink} onClick={() => this.props.functions.submitQuestion(form.values, form)}>{this.props.params.activeQuestion.bot_message.iDontKnowText || "I don't know"}</i>
                                            </div>
                                        }
                                    </React.Fragment>
                                )}
                            </Field> );
                    }

                } else if (bot_message.inputConfig && bot_message.inputConfig.isSelectWithEntity) {

                    // Combo with add Entity
                    this.props.functions.getListsFromBackend(_.get(bot_message.inputConfig, "entities", []));

                    if (bot_message.inputConfig.isMultiple) {
                        // Multiple
                        return (
                            <div>Multiple select with add entities ??</div>
                        );
                    } else {
                        // Normal
                        return (
                            <Field name={bot_message.responseKeyName}>
                                {({ field, form }) => (
                                    <React.Fragment>
                                        <ClaraSelect
                                            showRedStar={false}
                                            required={true}
                                            field={field}
                                            form={form}
                                            placeholder={bot_message.inputConfig.placeholder}
                                            adds={this.props.functions.getEntitiesForAddInSelector(bot_message.inputConfig, this.props.session.startup.id, this.props.params.data)}
                                            lists={this.props.functions.getEntityListForSelector(bot_message.inputConfig, this.props.params.data)}
                                            callbacks={{ createValues: this.props.functions.handleSelectorChanged }}
                                            paramsToCallbacks={{ index: 0 }}
                                            buttonStyle={{ marginTop: '0px' }}
                                            className={classes.ChatInputClaraSelect}
                                        />
                                        {
                                            bot_message.inputConfig.allowNullResponse
                                            && <div className={classesNullResponse.NullResponse}>
                                                <i className={classesNullResponse.NullReponseLink} onClick={() => this.props.functions.submitQuestion(form.values, form)}>{this.props.params.activeQuestion.bot_message.iDontKnowText || "I don't know"}</i>
                                            </div>
                                        }
                                    </React.Fragment>
                                )}
                            </Field>
                        );
                    }
                } else {
                    // Option Select Boxes (yes-no)
                    return (<Field name={bot_message.responseKeyName}>
                            {({ field, form }) => (
                                <React.Fragment>
                                    <OptionsButtonInput
                                        field={field}
                                        form={form}
                                        widthAuto={_.get(bot_message, "inputConfig.typeButtons") == "adapt" ? false : true}
                                        previewInfo={_.get(bot_message, "inputConfig.previewInfo", [])}
                                        options={bot_message.inputConfig ? bot_message.inputConfig.options : []}
                                        onClick={this.props.functions.handleOptionsButtonClick}
                                        params={this.props.params}
                                        submitLastStep = {this.props.functions.submitLastStep}
                                        functions={this.props.functions}
                                        initialValueStart={{ matterId: this.props.initialValueStart, draftId: this.props.params.activeQuestion.draftId }}
                                        initialKey={this.props.initialKey}
                                        disclaimer={_.get(this, "props.params.activeQuestion.bot_message.disclaimer")}
                                    />
                                    {
                                        bot_message.inputConfig.allowNullResponse
                                        && <div className={classesNullResponse.NullResponse}>
                                            <i className={classesNullResponse.NullReponseLink} onClick={() => this.props.functions.submitQuestion(form.values, form)}>{this.props.params.activeQuestion.bot_message.iDontKnowText || "I don't know"}</i>
                                        </div>
                                    }
                                </React.Fragment>
                            )}
                        </Field>
                    );
                }
            case 'AUTOMATIC':
                return (<Field name={bot_message.responseKeyName}>
                        {({ field, form }) => {
                            this.props.functions.submitQuestion(form.values, form)
                        }}
                    </Field>);
            case 'CHECKBOX':

                if (bot_message.inputConfig && bot_message.inputConfig.entities) {

                    this.props.functions.getListsFromBackend(bot_message.inputConfig.entities);

                    const entityList = this.props.functions.getEntityListForSelector(bot_message.inputConfig, this.props.params.data);

                    const orderedEntityList = bot_message.inputConfig.entities[0] == Constants.TRANSLATES.GROUP_COMPANY ? _.orderBy(entityList[0].list, ['isHoldingCompany'], ['desc']) : entityList[0].list

                    const count = entityList[0].list.length;

                    return (
                        <div style={count > 6 ? { width: 'auto' } : {}} className={classes.CheckBoxGroup}>
                            {
                             (orderedEntityList).map((gc, i) => (
                                    <Field name={bot_message.responseKeyName}>
                                        {({ field, form }) => (
                                            <Checkbox
                                                form={form}
                                                field={field}
                                                title={gc.label}
                                                subTitle={gc.country}
                                                className={classes.CheckBox}
                                                isChat={true}
                                                divForChatClassName={_.some(field.value, el => (el === gc.id)) ? `${classes.Check} ${classes.CheckedDiv}` : `${classes.Check} ${classes.NotCheckedDiv}`}
                                                handleChange={() => this.props.functions.handleCheckboxChange({id:gc.id, label:gc.label}, i, form, field)}
                                            />
                                        )}
                                    </Field>
                                ))
                            }
                        </div>
                    );
                } else {
                    const cantOfGc = bot_message.inputConfig.options.length;

                    return (
                        <div style={cantOfGc > 6 ? { width: 'auto' } : {}} className={classes.CheckBoxGroup}>
                            {
                                bot_message.inputConfig.options.map((op, i) => (
                                    <Field name={bot_message.responseKeyName}>
                                        {({ field, form }) => (
                                            <Checkbox
                                                form={form}
                                                field={field}
                                                title={op.text}
                                                subTitle={null}
                                                className={classes.CheckBox}
                                                isChat={true}
                                                divForChatClassName={_.some(field.value, el => (el === op.id)) ? `${classes.Check} ${classes.CheckedDiv}` : `${classes.Check} ${classes.NotCheckedDiv}`}
                                                handleChange={() => this.props.functions.handleCheckboxChange(op, i, form, field)}
                                            />
                                        )}
                                    </Field>
                                ))
                            }
                        </div>
                    );
                }
            case "CHAT_FORM":
                const isAutomaticForm = _.get(bot_message, "inputConfig.automaticOpen", false);
                return (
                    <Field name={bot_message.responseKeyName}>
                        {({ field, form }) => (
                            <React.Fragment>
                                <ChatForm
                                    formKey={bot_message.inputConfig.formKey}
                                    draftId={this.props.params.activeQuestion.draftId}
                                    inputConfig={bot_message.inputConfig}
                                    groupKey={bot_message.responseKeyName} option={this.props.option || _.get(bot_message, "inputConfig.option")}
                                    initialValueStart={{ matterId: this.props.initialValueStart, draftId: this.props.params.activeQuestion.draftId }}
                                    initialKey={this.props.initialKey}
                                    onCancelForm={isAutomaticForm ? this.props.functions.onCloseChat : () => { }}
                                    nextStep={(values, event, cb) => {
                                        let newValue = {}
                                        newValue[field.name] = values;
                                        this.props.functions.submitQuestion(newValue, form, event, null, () => {
                                            if (cb) {
                                                cb()
                                            }
                                        })
                                    }}
                                ></ChatForm>
                            </React.Fragment>
                        )}
                    </Field>
                )
                break;
            case 'ADDRESS':
                return (
                    <div className={classes.Addres}>
                        <RegistedAddressInput name={bot_message.responseKeyName} />
                    </div>
                );
            case 'MULTIPLE_FILE_UPLOAD':
                return (
                    <Field name={`${bot_message.responseKeyName}`}>
                        {({ field, form }) => (
                            <div className={classes.FileUploaderAndSelectorContainer}>
                                <div className={classes.FileUploaderAndSelector}>
                                    <ChatFileUploaderAndSelector
                                        form={form}
                                        field={field}
                                        multipleFiles={true}
                                        name={bot_message.responseKeyName}
                                    ></ChatFileUploaderAndSelector>
                                </div>
                            </div>
                        )
                        }
                    </Field >);
            case "SPECIFIC_STEP_COMPONENT":
                switch (bot_message.inputConfig.specificStepComponentName) {
                    case "QUESTIONARY_COMPANY_NAME":
                        return (
                            <Field name={`${bot_message.responseKeyName}.companyNameForm`}>
                                {({ field, form }) => (
                                    <ChatCompanyName
                                        form={form}
                                        responseKeyName={bot_message.responseKeyName}
                                        type={_.get(bot_message, "inputConfig.specificStepComponentConfig.type", "")}
                                        isForm={false}
                                    ></ChatCompanyName>
                                )
                                }
                            </Field >
                        )
                    case "QUESTIONARY_COMPANY_DETAILS":
                        return (
                            <ChatCompanyDetails
                                companyName={_.get(bot_message, "inputConfig.specificStepComponentConfig.companyName", null)}
                                responseKeyName={bot_message.responseKeyName}
                            ></ChatCompanyDetails>
                        )
                    case "QUESTIONARY_SPV_PRIMARY_ASSET":
                        return <ChatPrimaryAsset
                            config={_.get(bot_message, "inputConfig.specificStepComponentConfig", "")}
                            responseKeyName={bot_message.responseKeyName} initialValueStart={{ matterId: this.props.initialValueStart, draftId: this.props.params.activeQuestion.draftId }} ></ChatPrimaryAsset    >
                    case "QUESTIONARY_ARTICLES_ASSOCIATION":
                        return <ChatArticlesAssociation responseKeyName={bot_message.responseKeyName} initialValueStart={{ matterId: this.props.initialValueStart, draftId: this.props.params.activeQuestion.draftId }}></ChatArticlesAssociation>
                    case "QUESTIONARY_SHAREHOLDER_DIRECTORS":
                        return <ChatShareholderDirectors responseKeyName={bot_message.responseKeyName} initialValueStart={{ matterId: this.props.initialValueStart, draftId: this.props.params.activeQuestion.draftId }}></ChatShareholderDirectors>
                    case "QUESTIONARY_AUTHORIZED_SIGNATORIES":
                        return <ChatAuthorizedSignatories responseKeyName={bot_message.responseKeyName} initialValueStart={{ matterId: this.props.initialValueStart, draftId: this.props.params.activeQuestion.draftId }}></ChatAuthorizedSignatories>
                    case "QUESTIONARY_SHARE_CAPITAL":
                        return (
                            <Field name={`${bot_message.responseKeyName}.shareCapital`}>
                                {({ field, form }) => (
                                    <ChatShareCaptial field={field} form={form} responseKeyName={bot_message.responseKeyName} initialValueStart={{ matterId: this.props.initialValueStart }}></ChatShareCaptial>
                                )}
                            </Field>
                        )
                    case "QUESTIONARY_SHARES_ISSUES":
                        return (
                            <Field name={`${bot_message.responseKeyName}`}>
                                {({ field, form }) => (
                                    <ChatSharesIssues field={field} form={form}
                                        stakeholdes={_.get(bot_message, "inputConfig.specificStepComponentConfig.shareholders", null)}
                                        responseKeyName={bot_message.responseKeyName}
                                        sharesAuthorized={_.get(bot_message, "inputConfig.specificStepComponentConfig.sharesAuthorized", null)}
                                        initialValueStart={{ matterId: this.props.initialValueStart }} >
                                    </ChatSharesIssues>
                                )}
                            </Field>
                        )
                    case "QUESTIONARY_DATA_PROTECTION":
                        return <ChatDataProtection
                            responseKeyName={bot_message.responseKeyName}
                            initialValueStart={{ matterId: this.props.initialValueStart, draftId: this.props.params.activeQuestion.draftId }}
                            dataProtection={_.get(bot_message, "inputConfig.specificStepComponentConfig.dataProtection", null)}
                        ></ChatDataProtection>
                    case "QUESTIONARY_ADDITIONAL_SIGNATURE":
                        return <ChatAdditionalSignature responseKeyName={bot_message.responseKeyName} initialValueStart={{ matterId: this.props.initialValueStart, draftId: this.props.params.activeQuestion.draftId }}></ChatAdditionalSignature>
                    case "SCOPING_HOLDING_COMPANY_SPV":
                        return <ChatScopingHoldingCompanySPV responseKeyName={bot_message.responseKeyName} initialValueStart={{ matterId: this.props.initialValueStart, draftId: this.props.params.activeQuestion.draftId }} ></ChatScopingHoldingCompanySPV    >
                    case "DECLARE_PROVIDE_GENUINE_INFORMATION":
                        return <ChatDeclareProvideGenuineInformation
                            submitQuestion={this.props.functions.submitQuestion}
                            responseKeyName={bot_message.responseKeyName} initialValueStart={{ matterId: this.props.initialValueStart, draftId: this.props.params.activeQuestion.draftId }} ></ChatDeclareProvideGenuineInformation>
                    case "KYC_ENTITY_HOW_MANY_ENTITIES":
                        return <ChatKYCEntityHowManyEntities
                            submitQuestion={this.props.functions.submitQuestion}
                            responseKeyName={bot_message.responseKeyName} initialValueStart={{ matterId: this.props.initialValueStart, draftId: this.props.params.activeQuestion.draftId }} ></ChatKYCEntityHowManyEntities>
                    case "KYC_ENTITY_EDIT_MEMBERS":
                        return (
                            <Field name={`${bot_message.responseKeyName}.editMemebers`}>
                                {({ field, form }) => (
                                    <ChatKYCEntityEditMembers
                                        field={field} form={form}
                                        submitQuestion={this.props.functions.submitQuestion}
                                        responseKeyName={bot_message.responseKeyName} initialValueStart={{ matterId: this.props.initialValueStart, draftId: this.props.params.activeQuestion.draftId }} ></ChatKYCEntityEditMembers>
                                )}
                            </Field>
                        )
                    case "KYC_ENTITY_PEP_INDIVIDUALS":
                        return <ChatKYCEntityPepIndividuals
                            submitQuestion={this.props.functions.submitQuestion}
                            responseKeyName={bot_message.responseKeyName} initialValueStart={{ matterId: this.props.initialValueStart, draftId: this.props.params.activeQuestion.draftId }} ></ChatKYCEntityPepIndividuals>
                    case "ADGM_APPROVAL_DOCUMENTS":
                        return (
                            <Field name={`${bot_message.responseKeyName}.adgm_aprovval_documents`}>
                                {({ field, form }) => (
                                    <ChatADGMApproveDocuments
                                        field={field} form={form}
                                        submitQuestion={this.props.functions.submitQuestion}
                                        responseKeyName={bot_message.responseKeyName} initialValueStart={{ matterId: this.props.initialValueStart, draftId: this.props.params.activeQuestion.draftId }} ></ChatADGMApproveDocuments>
                                )}
                            </Field>
                        )
                    case "QUESTIONARY_BENEFICIAL_OWNERS":
                        return <ChatBeneficialOwners responseKeyName={bot_message.responseKeyName} initialValueStart={{ matterId: this.props.initialValueStart, draftId: this.props.params.activeQuestion.draftId }}></ChatBeneficialOwners>
                    case "QUESTIONARY_AGREEMENT_DIRECTORS":
                        return <ChatAgreementDirectos responseKeyName={bot_message.responseKeyName} initialValueStart={{ matterId: this.props.initialValueStart, draftId: this.props.params.activeQuestion.draftId }}></ChatAgreementDirectos>

                    default:
                        return null;
                }

            default:
                return null;
        }
    }

    showButton(activeQuestion) {
        const bot_message = this.props.bot_message || {};
        const inputConfig = bot_message.inputConfig || {};

        const isOptions = bot_message.type === 'OPTIONS';
        if (_.get(bot_message, "inputConfig.specificStepComponentName") === "DECLARE_PROVIDE_GENUINE_INFORMATION") {
            return false;
        }
        return !isOptions || (inputConfig.isSelect || inputConfig.isSelectWithEntity)
    }

    render() {

        const { setFieldValue, functions, bot_message, draftId, params, newKey  } = this.props
        const { keepUploadedFilesInState } = params

        return (
            <React.Fragment>
                {this.renderInput(bot_message, newKey, draftId, setFieldValue)}
                {this.showButton(bot_message) &&
                    <Field>
                        {({ field, form }) => (
                            <button
                                className={classes.FormButton}
                                data-cy="submit"
                                disabled={params.finished ? false : functions.isDisabled(params.errors, form, params.isValid)}
                                onClick={() => functions.submitQuestion(params.values, form, null, keepUploadedFilesInState )}
                            >{_.get(this, "props.bot_message.submitButtonText") || "Next"}</button>
                        )}
                    </Field>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {

    return {
        session: state.session
    };
};
const mapDispatchToProps = (dispatch) => ({
    
    setInitialFlow: () => setInitialFlow(dispatch),
    clearMessageChat: () => clearMessageChat(dispatch),
    goToPage: (page, params) => {
        goToPage(dispatch, page, params)
    },
    goToPrevPage: (reload, defaultPage, params) => {
        goToPrevPage(dispatch, reload, defaultPage, params)
    },

});

export default connect(mapStateToProps, mapDispatchToProps)(FactoryChat);

import React, {useEffect} from 'react';
import useMutation from "../../../../custom/useMutation"

const mutation = `mutation upsertIssue($startupId:String, $issue: IssueInputType, $issueId: String ) {
  upsertIssue(startupId: $startupId, issue: $issue, issueId: $issueId) {
  corporateStructure {
      name
    }
  }
}`;
const useUpsertIssue = (options) =>{
    
    const [send,data] = useMutation(mutation,options);
    useEffect(() => {
        
    }, []);

    return  [send,data] ;
}
export default useUpsertIssue;
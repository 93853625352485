import React from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";

const MyTransationWarrantsCancel = ({ go, ...props }) => {
  const {startupId, ...params} = useParams();

  return (
    <MicroFrontend id="MF_myEquity_myTransation_warrants_cancel" component={"./CancelTransactionDetailsWarrants"} mf="capTable" params={{ go, ...props, ...params, startup:{id: startupId} }}/>
  );
};

export default MyTransationWarrantsCancel;

import { Constants } from 'src/v1/utils/constants';
import useCreateRenewalProcess  from "src/views/modules/startups/renewal/mutations/useCreateRenewalProcess";
import useCreateStrikeOffProcess  from "src/views/modules/startups/renewal/mutations/useCreateStrikeOffProcess";
import useGetPINCAPrices from 'src/views/modules/startups/renewal/pincasPrice/queries/useGetPINCAPrices';
import { useGetProductLazy } from 'src/modules/checkout/hooks/useGetProduct';
import { useMemo } from 'react';
import useSession from "src/modules/session/hooks/useSession";

const useServices = ({ initialValues }) => {
  const { startupId } = useSession();
  const [upsertCreateRenewalProcess] = useCreateRenewalProcess({}, {});
  const [upsertCreateStrikeOffProcess] = useCreateStrikeOffProcess({}, {});
  const { manualQuery: getProduct } = useGetProductLazy();

  const { manualQuery: getPINCAPrices } = useGetPINCAPrices(
    {
      variables: {
        filters: {
          jurisdictionTypes: ['CAYMAN']
        }
      }
    }
  );


  return useMemo(() => ({

    initialData: async (context, event) => {
      return initialValues;
    },

    pincasData: async () => {
      const pincasData = await getPINCAPrices();
      return { pincasData };
    },

    getProduct: async (context, event) => {
      const variables = {
        productCode: Constants.MODULES.CHECKOUT.PRODUCTS.COMPANY_RENEWAL,
        relatedItemId: context?.initialData?.company?.id ?? "",     
      }
      const resultProduct =  await getProduct({ variables });
      return resultProduct?.data?.getProduct ?? {};
    },

    SaveStep: async (context, event) => {
      const variables = {
        startupId,
        groupCompanyId: context?.initialData?.company?.id ?? ""
      };

      await upsertCreateRenewalProcess({ variables });

      if(initialValues?.callback){
        initialValues?.callback()
      }

      return context
    },

    StrikeOff: async (context, event) => {
      const variables = {
        startupId,
        groupCompanyId: context?.initialData?.company?.id ?? ""
      };


      await upsertCreateStrikeOffProcess({ variables });
      
      if(initialValues?.callback){
        initialValues?.callback()
      }

      return context
    },

  }), [initialValues]);
}

export default useServices;

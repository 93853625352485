const templates = {
    
    MESSAGE_HEADER_SCENARIO_MODELLING_CREATED:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_SUB_HEADER_SCENARIO_MODELLING_CREATED:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_TEMPLATE_SCENARIO_MODELLING_CREATED: `
    <label> {{userName}} saved the scenario modelling results of {{companyName}}</label> 
    <ul>
        <li> The modelling results of {{companyName}} has been saved by {{userName}} at {{activityTime}} on {{activityDate}}</li>
    </ul> `,


    MESSAGE_HEADER_SCENARIO_MODELLING_DELETED:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_SUB_HEADER_SCENARIO_MODELLING_DELETED:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_TEMPLATE_SCENARIO_MODELLING_DELETED: `
    <label> {{userName}} cleared the scenario modelling results of {{companyName}}</label> 
    <ul>
        <li> The modelling results of {{companyName}} has been cleared by {{userName}} at {{activityTime}} on {{activityDate}}</li>
    </ul> `,

    MESSAGE_BUTTON_REDIRECT_GO_TO_MODELLING: `Go to Scenario modelling`,

}

export default templates

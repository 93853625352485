
import useMutationDelete from '../../../../hooks/custom/useMutationDelete'
import { gql } from '@apollo/client'
import { GET_COMPANY_DETAILS } from '../../../../hooks/services/modules/groupCompany/useGetCompanyDetails';
import { useParams } from 'react-router-dom';
import useSession from '../../../../modules/session/hooks/useSession';



const DELETE_SIP = `mutation deleteShareIncentivePlans(
    $startupId: ID
    $shareClassId: ID
    $shareIncentivePlanId:ID
    $reason: String
  ) {
    deleteShareIncentivePlans(
      startupId: $startupId
      shareClassId: $shareClassId
      shareIncentivePlanId:$shareIncentivePlanId
      reason: $reason
    ) {
    id
    }
  }  
`;


const useDeleteManualSip= (props ) => {

  const params = useParams();
  const { startupId } = useSession(); 

  const refetchQueries= [{
    query:gql`${GET_COMPANY_DETAILS}`, 
    variables:{startupId:startupId,  groupCompanyId: params.id}
  }]; 

  const callbackBeforeEvict = (cache,data)=>{
    const identify =cache.identify({id:data.id,__typename:'CaptableShareSIPData'})
    cache.evict({ id: identify})
  }

  const [mutation, loading] = useMutationDelete(DELETE_SIP, { ...props,callbackBeforeEvict }, {refetchQueries})

  return {mutation, loading};
}
export default useDeleteManualSip;   

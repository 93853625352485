import React, {useEffect} from 'react';
import useMutation from "../../custom/useMutation"

const mutation = `mutation cleanFormDraft($keys: Object!) {
  cleanFormDraft(keys: $keys) 
} `;
const useCleanFormDraft = (options) =>{
    
    const [send,data] = useMutation(mutation,options);
    useEffect(() => {
        
    }, []);

    return  [send,data] ;
}
export default useCleanFormDraft;
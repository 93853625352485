import { useMemo } from 'react';
import useGetGroupCompaniesForAccelerators from './useGetGroupCompaniesForAccelerators';
import useSession from '../../../session/hooks/useSession'
import _ from 'lodash';

import useUpsertDocument from 'src/modules/startup/graphql/document/mutation/useUpsertDocument'
import useGetFounders from './useGetFounders';

const useServices = () => {
  const { manualQuery: getFounders  } = useGetFounders({}, {});
  const { mutation: updateDocument } = useUpsertDocument();
  const { manualQuery: getCompaniesData } = useGetGroupCompaniesForAccelerators({}, {});
  const { startupId } = useSession();

  return useMemo(() => ({
     
    getCompaniesData: async (context, event) => {
      const allCompaniesData = await getCompaniesData({
        variables: {
          startupId
        }
      })
      return allCompaniesData;
    },
    upsertDocument: async (context, event) => {
      
      const result = await getFounders( {variables:{startupId} });
      const founders =  _.get(result,"data.getStartup.founders",[]);
      if (founders.length !== 1) {
        throw Error("Founder problmes");
      }
      const founder = founders[0]

      const responseTitle = _.get(context, "UploadDocumentStep.name", "");
      const responsefile = _.get(context, "UploadDocumentStep.file", {});
      
      const company = _.get(context, "UploadDocumentStep.company", "");
      const variables = {
        startupId,
        agreementData: {
          title: responseTitle,
          file: responsefile,
          type: "FOUNDER_SERVICES_AGREEMENT",
          status: "Uploaded",
          parties: [founder.id],
          subCategories: ["constitutionalDocuments"],
          groupCompanies: [
            {
              groupCompany: company,
            }
          ],
        },
      }
      return await updateDocument({ variables });
    }
  }), [getCompaniesData, startupId]);
}

export default useServices;
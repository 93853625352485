import useCustomMachine from '../../../../generic/hooks/useCustomMachine'
import React, { FC } from 'react'
import Button from '../../../../generic/components/Buttons/Button'
import { StepProps } from "../../../../generic/components/Wizard/Step"
import TemplateWizard from '../../../../generic/templates/Modal/TemplateWizard'
import useTranslate from '../../../../generic/hooks/useTranslate'
import Form from "../../Forms/FormCurrency"
import useUpdateGroupCompany from "../../../../../hooks/services/modules/groupCompany/useUpdateGroupCompany";
import _ from 'lodash';

const Step2: FC<StepProps> = () => {

    const { translate } = useTranslate();

    /*Function*/

    const { next, send, state } = useCustomMachine();

    const [mutation ] = useUpdateGroupCompany();

    const { groupCompanyId, startupId } = state.context.data;

    const getValuesForMutation = (state, valuesStep) => {
        const { context } = state;
        const { extraCurrencies } = valuesStep;
        return { ...valuesStep, extraCurrencies: _.compact(extraCurrencies), ...context.data.add_address }
    };




    const handleSubmit = React.useCallback(
        async (values) => {
            const variables = {
                groupCompany: getValuesForMutation(state, values),
                groupCompanyId,
                startupId
            }

            console.log(JSON.stringify(variables, null, 2))
            await mutation({ variables })
        }, [mutation, startupId, groupCompanyId, state]);



    const handleCompleteSubmit = async values => {

        await handleSubmit(values);
        next(values);
    }
    const handleCancel = async values => {
        send("CANCEL");
    }

    return (
        <React.Fragment>
            <Form onCompleteSubmit={handleCompleteSubmit} Template={TemplateWizard} propsTemplate={{
                title: translate(`MODULES.MAP.WIZARD_COMPANY_CURRENCIES_FORM_TITLE`),
                subTitle: "Address & Currency"
            }}
                buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }}>
            </Form>

        </React.Fragment>
    )
}

export default Step2;

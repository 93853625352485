import React from 'react';
import classes from './Styles.module.scss';
import _ from 'lodash'

const Status = ({ data, ...props }) => {

    const { status } = _.get(data, 'original');

    return (
        <div className={classes.containerStatus}>
            <i className={`${classes[status]} ${classes.circle} `} />
            <span>{' ' + status}</span>

        </div>
    )
}

export default Status;
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as moment from 'moment';
import * as numeral from 'numeral';
import React, { Component } from "react";
import { connect } from "react-redux";
import Avatar from "../../../../../../modules/generic/components/Avatars/AvatarLabel";
import { goToPage, goToPrevPage } from '../../../../../../store/actions/page';
import { Constants } from "../../../../../utils";
import classes from "../TableShares/TableShares.module.scss";

class TableWarrants extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      cantOfWarrants: props.data.length,
      totalInvestment: this.calculateTotalInvestment(props.data),
      missing: props.data.length <= 0
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      data: nextProps.data,
      cantOfWarrants: nextProps.data.length,
      totalInvestment: this.calculateTotalInvestment(nextProps.data),
      missing: nextProps.data.length <= 0
    });
  }

  calculateTotalInvestment(rows) {
    return rows.reduce((subTotal, row) => (row.investment || 0) + subTotal, 0);
  }

  getFormForShareholder(shareholder) {
    let page;
    if (shareholder.isGroupCompany) {
      page = Constants.PAGES.viewGroupCompany
    } else {
      if (shareholder.isAnEntity) {
        page = Constants.PAGES.viewStakeholderCompany
      } else {
        page = Constants.PAGES.viewStakeholderIndividual
      }
    }
    return page;
  }

  openShareholderDetails(shareholder) {
    const page = this.getFormForShareholder(shareholder);
    this.props.goToPage(page, { id: shareholder.id, tab: "equity" });
  }

  formatDate(date) {
    if (date) {
      return moment.utc(date).format('MM/DD/YYYY'); // 'D MMMM YYYY' -> 10 August 2010
    } else {
      return '';
    }
  }

  renderRows() {
    const rows = this.state.data;
    return rows.map(row => (
      <TableRow key={rows.indexOf(row)} className={classes.TableCell}>
        <TableCell
          align='left'
          onClick={() => this.openShareholderDetails(row)}
          className={`${classes.TableCell} ${classes.Clickable}`}
        >
          <Avatar avatar={row.avatar} name={row.label} pointerLabel={true} link />
        </TableCell>
        <TableCell
          align='right'
          className={`${classes.TableCell} ${classes.Clickable}`}
        >
          {numeral(row.investment).format('0,0')}
        </TableCell>
        <TableCell
          align='right'
          className={`${classes.TableCell} ${classes.Clickable}`}
        >
          {numeral(row.valuation).format('0,0')}
        </TableCell>
        <TableCell
          align='right'
          className={`${classes.TableCell} ${classes.Clickable}`}
        >
          {this.formatDate(row.grantDate)}
        </TableCell>
      </TableRow>
    ))
  }

  render() {
    if (!this.state.missing) {
      return (
        <div className={classes.TableDetails}>
          <Paper className={classes.Paper}>
            <Table className={classes.Table}>
              <TableHead>
                <TableRow>
                  <TableCell align='left' className={classes.TitleCell} />
                  <TableCell align='right' className={classes.TitleCell}>Investment Amount</TableCell>
                  <TableCell
                    align='right'
                    className={classes.TitleCell}
                  >Valuation</TableCell>
                  <TableCell align='right' className={classes.TitleCell}>Grant Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className={classes.TableCell}>
                  <TableCell
                    align='left'
                    className={`${classes.TitleCell} ${classes.TotalsConvertiblesTableCell}`}
                  >
                    {`Warrants`}
                  </TableCell>
                  <TableCell
                    align='right'
                    className={`${classes.TitleCell} ${classes.TotalsConvertiblesTableCellNumber}`}
                  >
                    {numeral(this.state.totalInvestment).format('0,0')}
                  </TableCell>
                  <TableCell align='right' className={classes.TitleCell} />
                  <TableCell align='right' className={classes.TitleCell} />
                </TableRow>
                {this.renderRows()}
              </TableBody>
            </Table>
          </Paper>
        </div>
      );
    } else {
      return (
        <div className={classes.MissingConvertibles}>
          <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              fill-rule='evenodd' clip-rule='evenodd'
              d='M12 23.001C18.0751 23.001 23 18.0761 23 12.001C23 5.92584 18.0751 1.00098 12 1.00098C5.92487 1.00098 1 5.92584 1 12.001C1 18.0761 5.92487 23.001 12 23.001Z'
              stroke='#A9B0BC' stroke-linecap='round' stroke-linejoin='round'
            />
            <path
              d='M14.5 17.005H13C12.4477 17.005 12 16.5573 12 16.005V9.505C12 9.22886 11.7761 9.005 11.5 9.005H10'
              stroke='#A9B0BC' stroke-linecap='round' stroke-linejoin='round'
            />
            <path
              d='M11.7451 6.5C11.607 6.5 11.4951 6.61193 11.4951 6.75C11.4951 6.88807 11.607 7 11.7451 7C11.8832 7 11.9951 6.88807 11.9951 6.75C11.9951 6.61193 11.8832 6.5 11.7451 6.5'
              stroke='#A9B0BC' stroke-linecap='round' stroke-linejoin='round'
            />
          </svg>
          <p>There are no warrants</p>
        </div>
      );
    }
  }
}


const mapStateToProps = state => ({
  session: state.session
});

const mapDispatchToProps = (dispatch) => ({
  goToPage: (page, params) => {
    goToPage(dispatch, page, params)
  },
  goToPrevPage: (reload, defaultPage, params) => {
    goToPrevPage(dispatch, reload, defaultPage, params)
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TableWarrants)

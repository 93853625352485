import _, { get } from 'lodash';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../../generic/components/Buttons/Button';
import SubmitButton from '../../../../generic/components/Buttons/SubmitButton';
import { StepProps } from "../../../../generic/components/Wizard/Step";
import useCustomMachine from '../../../../generic/hooks/useCustomMachine';
import { addAssistantText, clearAssistatText } from '../../../../generic/store/action';
import TemplateWizard from '../../../../generic/templates/Modal/TemplateWizard';
import Form from "../../ADGM/components/Forms/FormVisa";
import Third_party_address_form from '../Public/Third_party_address_form.svg';

const Step2: FC<StepProps> = () => {
  const { next, send, state } = useCustomMachine();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(addAssistantText(`Keep an eye on the Clara star for hints and tips.`));
    return () => {
      dispatch(clearAssistatText());
    }
  }, [])

  const handleCompleteSubmit = async values => {
    next(values);
  }

  const handleCancel = async values => {
    send("CANCEL");
  }

  return (
    <React.Fragment>
      <Form
        initialValues={get(state, 'context.data.set_document_visa', {})}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: 'Add third party address form',
          subTitle: 'Proof of address'
        }}
        image={<img src={Third_party_address_form} alt={''} />}
        buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button>, submit: <SubmitButton >Add</SubmitButton> }}>
      </Form>
    </React.Fragment>
  )
}

export default Step2;

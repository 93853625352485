import { useMemo } from 'react';
import useFormationJurisdictionType from './useFormationJurisdictionType';
import _ from 'lodash';
import useUpsertJurisdictionMail from 'src/modules/startup/onBoarding/CreateCompanyModal/wizard/submachines/AddFormationCompanyModal/wizard/hooks/useUpsertJurisdictionMail';
import { useGetProductLazy } from 'src/modules/checkout/hooks/useGetProduct';
import { Constants } from 'src/v1/utils'
import useSession from 'src/modules/session/hooks/useSession';

const useServices = () => {

  const { manualQuery: getJurisdictionData } = useFormationJurisdictionType({}, {});
  const { mutation: sendJurisdictionMail } = useUpsertJurisdictionMail();
  const { manualQuery: spvPayment } = useGetProductLazy({ productCode: Constants.MODULES.CHECKOUT.PRODUCTS.FORMATION_ADGM_SPV_PAYMENT });
  const { manualQuery: tlSeed } = useGetProductLazy({ productCode: Constants.MODULES.CHECKOUT.PRODUCTS.FORMATION_ADGM_TSL_SEED_PAYMENT });
  const { manualQuery: tlEmergent } = useGetProductLazy({ productCode: Constants.MODULES.CHECKOUT.PRODUCTS.FORMATION_ADGM_TSL_EMERGENT_PAYMENT });
  const {startupId} = useSession()

  return useMemo(() => ({
    
    ChooseJurisdiction_data: async (context, event) => {
      const allJurisdictionData = await getJurisdictionData();
      return _.get(allJurisdictionData, "data.formationJurisdictionList", []);
    },

    SendMailCommingSoonJurisdiction: async (context, event) => {
      await sendJurisdictionMail({startupId});
      return null;
    },

    GetProducts: async (context, event) => {
      const productSpv = await spvPayment();
      const productTlSeed = await tlSeed();
      const productTlEmergent = await tlEmergent();
      return {
        spv : {
          price: _.get(productSpv, "data.getProduct.value", "0"),
          currency: _.get(productSpv, "data.getProduct.currency:", "USD"),
          // useVat: _.get(productSpv, "data.getProduct.defaultVAT.discountApplies", true),
          useVat: true
        },
        tl: {
          seed: {
            price: _.get(productTlSeed, "data.getProduct.value", "0"),
            currency: _.get(productTlSeed, "data.getProduct.currency:", "USD"),
            // useVat: _.get(productTlSeed, "data.getProduct.defaultVAT.discountApplies", true),
            useVat: true
          },
          emergent: {
            price: _.get(productTlEmergent, "data.getProduct.value", "0"),
            currency: _.get(productTlEmergent, "data.getProduct.currency:", "USD"),
            // useVat: _.get(productTlEmergent, "data.getProduct.defaultVAT.discountApplies", true),
            useVat: true
          }
        }
      }
    },

  }), [getJurisdictionData, sendJurisdictionMail, spvPayment, tlSeed, tlEmergent]);
}

export default useServices;

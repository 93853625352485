import { useMemo } from 'react';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import FormCaymanAgree from "src/modules/startup/onBoarding/CreateCompanyModal/wizard/submachines/AddFormationCompanyModal/wizard/submachines/AddFormationCompanyInCaymanModal/wizard/steps/FormCaymanAgree";
import FormInCaymanInfo from "src/modules/startup/onBoarding/CreateCompanyModal/wizard/submachines/AddFormationCompanyModal/wizard/submachines/AddFormationCompanyInCaymanModal/wizard/steps/FormInCaymanInfo";
import AddCaymanCompany from "src/modules/startup/onBoarding/CreateCompanyModal/wizard/submachines/AddFormationCompanyModal/wizard/submachines/AddFormationCompanyInCaymanModal/wizard/steps/AddCaymanCompany";
import EngageConciergeCayman from "src/modules/startup/onBoarding/CreateCompanyModal/wizard/submachines/AddFormationCompanyModal/wizard/submachines/AddFormationCompanyInCaymanModal/wizard/steps/EngageConciergeCayman";

const useSteps = () => {

  return useMemo(() => ({
    FormCaymanAgree: {
      render: FormCaymanAgree,
    },
    FormInCaymanInfo: {
      render: FormInCaymanInfo,
    },
    AddCaymanCompany: {
      render: AddCaymanCompany,
    },
    EngageConciergeCayman: {
      render: EngageConciergeCayman,
    },
    SaveStep: {
      render: TemplateWizardSkeletonMachine,
    },
    SendMailNow: {
      render: TemplateWizardSkeletonMachine,
    },
  }), []);
}

export default useSteps;

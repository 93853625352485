const upserStartup = `
              mutation upsertStartupProfile(
                  $id: ID
                  $startupData: StartupInput!
                ) {
                  upsertStartupProfile(
                    id: $id
                    startupData: $startupData
                  ) {
                    id
                    avatar
                    accelerator
                    groupCompanies {
                       id
                       name
                       avatar
                    }
                    pendingEntities { 
                      flow 
                      remains 
                      type
                    }
                    name
                    website
                    description
                    statistic{
                      companies
                      stakeholders
                      investors
                      documents
                      issues{
                        RED
                        AMBER
                        GREEN
                      }
                    }
                  }
                }`;

const removeStartup = `mutation removeStartup($id: ID ) {
  removeStartup(_id: $id)
  }`;


export { upserStartup, removeStartup }

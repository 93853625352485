import useMutation from '../../../../../hooks/custom/useMutation'

export const UPSERT_UPDATE_QUESTIONNAIRE = `
  mutation updateAcceleratorResponse($startupId: ID, $response: QuestionResponseInput) {
    updateAcceleratorResponse(startupId: $startupId, response: $response) {
      name
      acceleratorData {
        acceleratorCode
        responses {
          _id
          response
          explainComment
        }
      }
    }
  }
`;

const useUpdateQuestion = (props = {}) => {
  const mutation = useMutation(
    UPSERT_UPDATE_QUESTIONNAIRE,
    { showSuccessNotification: false, ...props },
  )
  return mutation
}

export default useUpdateQuestion

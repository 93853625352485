import React, { Component } from "react";
import { connect } from "react-redux";
import { DeleteForm, Loading } from "../../index";
import ServerConnect from '../../../utils/ServerConnect';
import { deleteDraftDocumentQuery as eliminate } from '../../../graphql/mutations/addAgreement';
import {gql} from "@apollo/client";
import {COMPANY_CORPORATE_MAP_TASKS} from "../../../../modules/assistanceV4/hooks/services/useGetCompanyCorporate";
import {TABLE_AGREEMENT_LIST} from "../../../../views/modules/startups/documents/Documents/graphql/queries/useGetTableAgreementList";
import _ from "lodash";

const updateCache = (cache, { data })=> {
    const mutationName = Object.keys(data)[0];
    const identity = cache.identify(data[mutationName]);
    cache.evict({ id: identity })
    cache.gc()
};


class DocumentDraftDeleteForm extends Component {
    state = {
        data: {},
        showLoading: false,
        options: [],
    }
    /* Particular data */
    getTitle() {
        const data = this.state.data;
        return "Delete document";
    }
    setOptions(data) {
        let options = [];
        options.push({ label: "Delete this document?" });
        this.setState({
            options
        })
    }
    hasAllData() {
        return true;
    }
    /*  Call Server*/
    callServerToGetData = () => {
        this.showLoading(true);
        //Get data if exist this posibility
    };
    deleteEntity = () => {
        return new Promise((resolve, reject) => {
            var data = {
                startupId: this.props.session.startup.id,
                draftDocumentId: this.state.data.documentId,
            };
            this.showLoading(true);
            ServerConnect.graphQlMutation(eliminate, data,{},{},{update:updateCache}).then((result) => {
                this.showLoading(false);
                if (this.props.callbacks) {
                    this.props.callbacks.deleteEntity(data);
                }
                resolve();
            }).catch(err => {
                reject(err)
            });
        });
    }
    /* Change status */
    showLoading = (status) => {
        this.setState({
            showLoading: status
        });
    }
    /* REac functions */
    componentWillMount() {
        if (this.hasAllData()) {
            this.setState({
                data: this.props.data
            }, () => {
                this.setOptions();
            })


        } else {
            this.callServerToGetData();
        }
    }


    render() {

        return (
            <React.Fragment>
                {!this.state.showLoading && <DeleteForm handleClose={this.props.handleClose} show={true} deleteEntity={this.deleteEntity} showLoading={this.showLoading} title={this.getTitle()} options={this.state.options}></DeleteForm>}
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    session: state.session,
    form: state.form
});
const mapDispatchToProps = (dispatch) => ({

});
export default connect(mapStateToProps, mapDispatchToProps)(DocumentDraftDeleteForm)

import classes from "./PositionCheckbox.module.scss";
import _ from "lodash";
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Constants } from '../../../../../../../../v1/utils';
import Checkbox from '../../.././../../../../inputs/Checkbox/Checkbox'
import { useSelector } from 'react-redux';
import { RowAdd, ObserverField, HiddenFieldForm } from '../../../../../../contents';
import ControllerInput from '../../../../../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput'
import TextInput from '../../../../../../../inputs/Text/TextInput'

import { useFormContext } from 'react-hook-form'

const PositionCheckbox = ({ onChange, selectedPosition, valueOther, data, list, groupCompany, value, name, nameOther, nameRoot, refCheckbox }) => {
    const { watch,setValue } = useFormContext()
    const { position } = watch(nameRoot)


    const listOptions = useMemo(() => {
        return _.map(list, option => option.isDefault ? { value: option.value, label: option.label } : { value: { ...option, text: null }, label: option.label })

    }, [JSON.stringify(list)]);

    useEffect(() => {
        return () => {
            onChange([])
        }
    }, [])
    const [showOther, setShowOther] = useState(false)
    useEffect(() => {
        
        
        //Se usa el watch para que se actualice el valor de la variable showOther
        const find = _.find(value, res => _.get(res, "label") === "Other" || _.get(res, "isDefault") === false);
        //Si el la etiqueta es other o isDefault es false, quiere decir que el usuario selecciono la opcion "Other", es lo que hay por ahora
        
        setValue(nameOther,find?.text ?? null)
        setShowOther(!!find)
    }, [value])
    return (
        <React.Fragment>

            <div className={classes.container}>
                <Checkbox
                    options={listOptions}
                    onChange={(value) => {
                        onChange(value)
                    }}
                    values={value}
                    compare="code"
                    keyId={position?.id}
                    key={position?.id}
                    className={{
                        root: classes.rootCheckbox,
                        checkbox: classes.checkboxResponsibility
                    }}
                    innerRef={refCheckbox}
                />
                {showOther && (
                    <ControllerInput
                        as={TextInput}
                        name={nameOther}
                        defaultValue={''}
                        placeholder={"Type here..."}
                        onChange={(e) => {

                        }}
                    />
                )}

            </div>



        </React.Fragment>
    )
}

export default PositionCheckbox;

import useMutation from "src/hooks/custom/useMutation";
import useSession from "src/modules/session/hooks/useSession";
export const UPSERT_COMPANY_ADDRESSES = `
mutation updateDataProtectionContact(
    $startupId: String
    $groupCompanyId: String
	  $groupCompany: GroupCompanyInputType
  ) {
    upsertGroupCompany(
      startupId: $startupId
      groupCompanyId: $groupCompanyId
      groupCompany: $groupCompany
    ) {
      id
  }
}
`;

const useUpdateGroupCompanyAddresses = () => {
    const [mutation] = useMutation(
      UPSERT_COMPANY_ADDRESSES,
        { showSuccessNotification: true }
    ); 
    const { startupId } = useSession();
    const updateDataProtectionContact = (groupCompanyId, dataProtectionContact) => {
        const groupCompany = {
            keyPeople: {
                dataProtectionContact
            }
        };
        return mutation({ variables: { startupId, groupCompanyId, groupCompany } });
    }

    return updateDataProtectionContact;
};

export default useUpdateGroupCompanyAddresses;

import useQuery from "../../../../hooks/custom/useQuery";
import useLazyQuery from "../../../../hooks/custom/useLazyQuery";
import _ from "lodash";

export const query = `query getStartup($startupId: ID!){
  getStartup(startupId: $startupId){
    id
    name
    hasScaleSubscription
    resellerInfo{
      receiveEmailsOnBehalfOwners
    }
    gates{
      documentsPopupHasBeenSeen
      profilePopupHasBeenSeen
      healthCheckHasBeenSeen
      generateDocumentPopupHasBeenSeen
      capTablePopupHasBeenSeen
    }
    description
    avatar
    updatedAt
    accelerator
    primaryLocation {
      name
      code
    }
    website
    createdAt
    linkedInURL
    hasPendingInvoices
    invoices {
      id
      paymentMethod
      productCode
      currency
      file {
        id
        name
      }
      amount
      type
      category
      subCategory
      startDate
      transaction {
        currency
        totalAmount
      }
      status
    }
    subscriptions{
      id
      startDate
      renewalDate
      paymentMethod {
        ... on BankCard {
            bankCardType
            last4
            expiryMonth
            expiryYear
            _id
          }
      }
      status
      productCode
      subscriptionPlan {
        id
        code
        name
        description
        price
        features {
        code
          id
          name
        }
      }
    }
    bankCards{
      bankCardType
      last4
      expiryMonth
      expiryYear
      cardHolderName
      lastUsed
      billingAddress
    }
    optionsSelected{
      regionFilter
    }
  }
}`;

//TODO: Delete `groupCompanies { id name avatar isHoldingCompany}`when replace redux with cache
const queryActiveSubscription = `query getActiveSubscription($startupId: ID!){
  getStartup(startupId: $startupId){
    id
    hasScaleSubscription
    groupCompanies {
      id
      name
      avatar
      isHoldingCompany
      jurisdiction
      jurisdictionType
      kind
      transactionCurrency
      managedByClara
      country { code name }
    }
    activeSubscriptionPlan{
      id
      code
      name
      activeSubscriptionData {
        autoPaymentScheduleDate
        bankCard {
          bankCardType
          last4
          expiryMonth
          expiryYear
          cardHolderName
          lastUsed
          billingAddress
          inUse
        }
        isDowngraded
        cancelAutoRenewal
        downgradedScheduleDate
        mustPayInvoice
        nextDaysScheduleDate
        pendingMonths
        productCode
        renewalDate
        startDate
        status
      }
      features{
        code
        id
        name
        limit {
          ...on SubscriptionFeatureAmountLimit{
              amount
          }
          ...on SubscriptionFeatureAllowedLimit{
              allowed
          }
          ...on SubscriptionFeatureAllowedFileExtensionLimit{
              allowedExtensions
          }
        }
      }
    }
    subscriptions{
      id
      startDate
      renewalDate
      paymentMethod {
        ... on BankCard {
            bankCardType
            last4
            expiryMonth
            expiryYear
            _id
          }
      }
      status  
      productCode
    }
    subscriptionFeaturesUsage{
      code
      amount
    }
  }
}`;

const getStartupAndAccess = `query getStartupAndActions($startupId: ID!){
  getUserActionsByStartup(startupId: $startupId){
    allowedActions
    user{id}
    profile{
      startup{id}
      role
    }
  }
  getStartup(startupId: $startupId){
    id
    name
    hasScaleSubscription
    knowTheLocation
    showCalendar
    acceleratorDef{
      code
      name
      startDate
      endDate
      shareEmail
      approvedJurisdictions {
        country
        countryName
        jurisdiction
        enabledToCreateFormations
      }
    }
    goals{
      code
    }
    gates{
      documentsPopupHasBeenSeen
      profilePopupHasBeenSeen
      healthCheckHasBeenSeen
      generateDocumentPopupHasBeenSeen
      capTablePopupHasBeenSeen
    }
    lastHealthCheckRun
    description
    avatar
    updatedAt
    accelerator
    acceleratorData {
      isSubmitted
    }
    primaryLocation {
      name
      code
    }
    website
    linkedInURL
    createdAt
    updatedAt
    hasPendingInvoices
    invoices{
      file{
        id
        name
      }
      type
      productCode
      amount
      currency
      category
      subCategory
      startDate
      transaction {
        currency
        totalAmount
      }
    }
    subscriptions{
      id
      startDate
      renewalDate
      paymentMethod {
        ... on BankCard {
            bankCardType
            last4
            expiryMonth
            expiryYear
            _id
          }
      }
      status
      productCode
      subscriptionPlan {
        id
        code
        name
        description
        price
        features { 
        code
          id
          name
        }
      }
    }
    bankCards{
      bankCardType
      last4
      expiryMonth
      expiryYear
      cardHolderName
      lastUsed
      billingAddress
    }
    founders {
      id
      fullName
      isFounder
      avatar
    }
    activeSubscriptionPlan{
      id
      code
      name
      activeSubscriptionData {
        autoPaymentScheduleDate
        bankCard {
          bankCardType
          last4
          expiryMonth
          expiryYear
          cardHolderName
          lastUsed
          billingAddress
          inUse
        }
        isDowngraded
        cancelAutoRenewal
        downgradedScheduleDate
        mustPayInvoice
        nextDaysScheduleDate
        pendingMonths
        productCode
        renewalDate
        startDate
        status
      }
      features{
        code
        id
        name
        limit {
          ...on SubscriptionFeatureAmountLimit{
              amount
          }
          ...on SubscriptionFeatureAllowedLimit{
              allowed
          }
          ...on SubscriptionFeatureAllowedFileExtensionLimit{
              allowedExtensions
          }
        }
      }
    }
    subscriptionFeaturesUsage{
      code
      amount
    }
    optionsSelected{
      regionFilter
    }
  }
}`;

const getLastHealthCheckRun = `query getLastHealthCheckRun($startupId: ID!){
  getStartup(startupId: $startupId){
    id
    lastHealthCheckRun
    optionsSelected{
      regionFilter
    }
  }
}`;

const useGetStartup = (variables, config) => {
  const { loading, error, data: startupData, refetch } = useQuery(
    query,
    variables,
    config
  );
  const data = _.get(startupData, "getStartup", {});
  return { loading, error, data, refetch };
};

const useGetStartupAndActionsLazy = (variables, config) => {
  const {
    manualQuery,
    loading,
    error,
    data,
    ...pre
  } = useLazyQuery(getStartupAndAccess, variables, { ...config });
  return {
    manualQuery,
    loading,
    error,
    data: _.get(data, "getStartup"),
    ...pre,
  };
};

const useGetActiveSubscription = (variables, config) => {
  const { manualQuery, loading, error, data, ...pre } = useLazyQuery(
    queryActiveSubscription,
    variables,
    {
      fetchPolicy: "no-cache",
      ...config,
    }
  );

  return {
    manualQuery,
    loading,
    error,
    data: _.get(data, "getStartup"),
    ...pre,
  };
};

const useGetLastHealthCheckRun = (variables, config) => {
  const { loading, error, data } = useQuery(getLastHealthCheckRun, variables, {
    ...config,
  });
  return { loading, error, data: _.get(data, "getStartup") };
};

export {
  useGetActiveSubscription,
  useGetStartupAndActionsLazy,
  useGetLastHealthCheckRun,
};

export default useGetStartup;

export default {
  SELECT_JURISDICTION_TITLE: "Clara Formations",
  SELECT_JURISDICTION_QUESTION: "Which <b>jurisdiction</b> do you want to form your company in?",
  SELECT_JURISDICTION_LINK: "What is a jurisdiction?",
  SELECT_FORM_JURISDICTION_DETAILS_QUESTION: "Please fill out the following details",
  SELECT_JURISDICTION_ADGM_TITLE: "To use our <b>ADGM</b> company incorporation service, you must meet the following <b>criteria</b>:",
  SELECT_JURISDICTION_ADGM_ITEM1: "Your <b>Company</b> will be incorporated with the <b>ADGM Model Articles of Association</b> and <b>Ordinary Shares</b>.<sup>1</sup>",
  SELECT_JURISDICTION_ADGM_ITEM2: "You must have either a <b>UAE Resident</b> or <b>GCC National</b> who can be the <b>Authorised Signatory</b> for your Company or you can use our <b>Nominee Service</b>.<sup>2</sup>",
  SELECT_JURISDICTION_ADGM_TITLE2: "Your <b>Company</b> must be able to show an adequate connection to the <b>GCC</b> by satisfying one of the following:",
  SELECT_JURISDICTION_ADGM_ITEM3: "The <b>Company</b> will primarly hold <b>assets</b> in the <b>GCC</b>.",
  SELECT_JURISDICTION_ADGM_ITEM4: "The majority of the <b>Company's Directors</b> are <b>resident</b> in the <b>GCC</b>.",
  SELECT_JURISDICTION_ADGM_ITEM5: "The majority of the <b>Company's</b> shares will be held by <b>Shareholders</b> resident in the <b>GCC</b>.",
  SELECT_JURISDICTION_ADGM_AGREE_BTN: "Agree",
  SELECT_JURISDICTION_ADGM_DISAGREE_BTN: "Disagree",
  SELECT_JURISDICTION_ADGM_BOTTOM: "1 After incorporation, the Articles of Association can be amended to create additional Share Classes or vary the right of Shareholders.<br>2 If you do not have a UAE Resident or GCC National available to be the Company´s Authorised Signatory, we can provide one for you for a fee.",
  SELECT_JURISDICTION_DONE_QUESTION: "Form submitted",
  SELECT_JURISDICTION_DONE_INFO: "We have your inquiry and will be in contact shortly.",
  SELECT_JURISDICTION_DONE_BTN: "Done",
  FORM_JURISDICTION_DETAILS_NAME :"Full name:",
  FORM_JURISDICTION_DETAILS_EMAIL :"Email:",
  FORM_JURISDICTION_DETAILS_CONTACT_NUMBER :"Contact number:",
  FORM_JURISDICTION_DETAILS_OTHER_INFO :"Any other info:",
  FORM_TYPE_HERE:"Type here...",
  FORM_JURISDICTION_BTN_SUBMIT:"Submit",
  FORM_JURISDICTION_VALIDATION:"This field is required.",
  FORM_JURISDICTION_VALIDATION_EMAIL:"This field must be a valid email.",
  FORM_JURISDICTION_VALIDATION_PHONE:"Phone number is not valid.",
  FORM_JURISDICTION_BTN_PREVIOUS:"Previous",
  FORM_JURISDICTION_BTN_CANCEL:"Cancel",
  FORM_SELECT_JURISDICTION_BTN_NEXT:"Next",
  FORM_SELECT_JURISDICTION_COUNTRY_LABEL:"Country",
  FORM_SELECT_JURISDICTION_COUNTRY_HIT:"Select country",
  FORM_SELECT_JURISDICTION_JURISDICTION_LABEL:"Jurisdiction",
  FORM_SELECT_JURISDICTION_JURISDICTION_HIT:"Select jurisdiction",
  SELECT_JURISDICTION_ADGM_DISAGREE_MSG:"User declined to use Formation service",
};
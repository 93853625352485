import React, { Component } from 'react';
import DropDownDocuments from "../../../../../components/inputs/Clara/document/DropDownDocuments";
import classes from "../../SteperForm.module.scss";
import Cookies from 'universal-cookie';
import DocumentPreview from "./../../../../../components/generic/Documents/DocumentPreview/DocumentPreview";
import _ from 'lodash';

const time = 1000;
class Step3 extends Component {

    constructor(props) {
        super(props);
        this.state = {
			fileIdSelected: null
		}
    };

    componentDidMount() {
		this.setState({fileIdSelected: _.get(this, 'props.file.id')});
	}

	render() {
        return (
            <React.Fragment>
				<div className={classes.Preview}>
                    { this.props.generatedAgreements.length>1?
                        <div className={classes.Header}>
                        <DropDownDocuments documents={this.props.generatedAgreements} onChange={(value)=>{
                            this.setState({
                                fileIdSelected: _.get(value,"file.id")
                            })
                        }}/></div>:null
                    }
            	<DocumentPreview
					header={this.props.generatedAgreements.length > 1}
					fileId={this.state.fileIdSelected}
					style={this.props.generatedAgreements.length > 1 ? classes.IFrame : null}>
                    {this.props.footer}
                </DocumentPreview>
            </div></React.Fragment>);
    }
}

export default Step3;

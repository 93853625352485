import React from "react";
import { useFormContext } from "react-hook-form";
import Button from "../Button";
import _ from "lodash";
interface Props { 
  isSubmitting?: boolean;
  handleDisabled?: (formState: any, getValues: any) => boolean;
  disabled?: boolean;
  loading?:boolean;
  children?: React.ReactNode;
  [x: string]: any;
}
const SubmitButton:React.FC<Props> = ({ isSubmitting, handleDisabled, disabled = false,loading=false, children,...props }) => {
  const { formState, getValues } = useFormContext();
  const { isSubmitting: isSubmittingForm, isValid, errors } = formState;

  if (window?.env?.devTools?.showErrorsForms && errors && !_.isEmpty(errors)) {
    console.log("**** errors", errors);
  }

  const disabledButton = () => {
    if (window?.env?.devTools?.showErrorsForms) {
      return false;
    }
    if (disabled) return true;
    return handleDisabled
      ? handleDisabled(formState,getValues)
      : isSubmitting || !isValid;
  };

  return (
    <>
      <Button
        type={"submit"}
        variant={"primary"}
        data-cy="submit"
        {...props}
        loading={loading || isSubmitting || isSubmittingForm}
        disabled={disabledButton()}
      >
        {children}
      </Button>
    </>
  );
};

export default SubmitButton;

import React, { Component } from 'react';
import { Field, Form } from "formik";
import _ from "lodash";
import moment from "moment";
import ClaraSelect from "../../../../../../../v1/components/Form/ClaraSelect/ClaraSelect";
import classes from "../Styles.module.scss";
import { TextAreaInput, TextInput, TextType } from '../../../../../../../v1/components';
import Checkbox from "../../../../../../../v1/containers/Forms/Inputs/Checkbox";
import FormikHelper from "../../../../../../../v1/utils/formikHelper";
import ClaraUpload from "../../../../../../../v1/components/Form/ClaraUpload/ClaraUpload";
import ClaraCountrySelector from "../../../../../../../v1/components/Form/ClaraCountrySelector/ClaraCountrySelector";import Line from "../../../../../../generic/Line/Line";
import ClaraAddInputs from "../../../../../../../v1/components/Form/ClaraAddInputs/ClaraAddInputs";
class WebsiteCookieNotice extends Component {

    constructor(props) {
        super(props);
        this.refEvent1 = React.createRef()
        this.refEvent2 = React.createRef()
        this.state = {
            typeSelected: undefined,
            participantSelected: undefined,
            groupCompanySelected: undefined,
            authorizedSelected: undefined,
            authorizedSignatories: [],
            authorizedSignatoriesList: [],
            documentTypes: [],
            documentTypesList: [],
            dynamicParams: [],
            date: undefined,
            showSelectAuthorized: true,
            showInputName: false,
            showAddAllDirectors: true
        }
    };

    componentWillMount() {
        if (this.props.values) {
            const values = this.props.values.values ? this.props.values.values : this.props.values;
            this.setState({
                typeSelected: values.documentType,
                participantSelected: values.participant,
                groupCompanySelected: values.company,
                authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.company).map(as => ({
                    id: as.id,
                    label: as.label
                }))
            });
        }

    }

    addList() {
        return [
            {
                label: "Add New Individual Director",
                onClick: (value) => console.log(value),
                type: 'stakeholder',

            },
            {
                label: "Add New Corporate Director",
                type: 'stakeholder',

            }
        ]
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.values) {
            const values = nextProps.values.values ? nextProps.values.values : nextProps.values;
        }
    }

    filterAuthorizedByGroupCompany(groupCompany) {
        if (groupCompany) {
            let gc = this.findGroupCompany(groupCompany.id);
            return this.props.authorizedSignatories.filter(authorized => {
                return authorized.groupCompanyId === gc.id
            });
        }
        return [];
    }

    selectType = (documentType, extraParams, form) => {
        this.setState({
            typeSelected: documentType,
            dynamicParams: this.getDynamicParamsByType(documentType)
        }, () => {
            FormikHelper.setValueInTheCorrectPosition('terms', form, []);
            FormikHelper.setValueInTheCorrectPosition('documentType', form, documentType);
            this.state.dynamicParams.forEach((dynamicParam, i) => {
                FormikHelper.setValueInTheCorrectPosition(
                    `terms[${i}].key`,
                    form,
                    dynamicParam.key || 'NO_KEY'
                );
            })
        });
    };

    getTotalNumberOfShares(ref1, ref2, form) {
        if (ref1.current && ref2.current) {
            const percentage = Number(ref2.current.target.value);
            const totalOfOrdinaryShares = Number(ref1.current.target.value);
            const numberOfSIPShares = _.round(percentage / 100 * totalOfOrdinaryShares);
            form.setFieldValue('terms.shareCapital.numberOfSIPShares', numberOfSIPShares)
        }
    }

    getDynamicParamsByType(documentType) {
        const type = this.props.documentTypes.filter(agrType => agrType.code === documentType)[0] || {};
        return type.fields || [];
    }

    getGroupCompanies = () => {
        let authorizedSignatories = this.props.authorizedSignatories;
        let groupCompanies = this.props.groupCompanies;
        let companies = [];
        if (groupCompanies && authorizedSignatories) {
            companies = _.filter(groupCompanies, (groupCompany) => {
                let fined = _.filter(authorizedSignatories, (authorized) => {
                    if (groupCompany.authorizedSignatories) {
                        return groupCompany.authorizedSignatories.some(au => au.stakeholder.id === authorized.id);
                    }
                    return false;
                });
                if (fined) {
                    return true;
                }
                return false;
            });
        }
        return companies;
    };

    filterAuthorizedSignatoriesSelected(list) {
        return _.filter(list, element => !_.some(this.state.authorizedSignatories, director => element.value.id === director.id))
    }

    selectGroupCompany = (gcId, extraParams, form) => {
        const groupCompany = this.findGroupCompany(gcId);

        // this is for a weird behaviour when hit edit button after preview a document
        if (this.state.groupCompanySelected && groupCompany) {
            return null;
        }
        this.setState({
            groupCompanySelected: groupCompany
        });

        if (groupCompany) {

            if (!groupCompany.registeredAddress) {
                groupCompany.registeredAddress = {}
            }

            let authorizedSignatories = _.get(form, 'values.company.authorizedSignatories', []);
            const authorizedSignatoriesList = _.map(groupCompany.authorizedSignatories, st => st.stakeholder);

            if (!_.isEmpty(authorizedSignatories)) {
                authorizedSignatories = authorizedSignatories.map(as => {
                    return {
                        ...as,
                        fullName: authorizedSignatoriesList.find(st => st.id === as.id).fullName
                    }
                })
            }

            const newFormValues = {
                ...form.values,
                company: {
                    id: gcId,
                    name: groupCompany.name || '',
                    country: _.get(groupCompany, 'country.code', null) || _.get(groupCompany, 'country', null),
                    jurisdiction: _.get(groupCompany, 'jurisdiction', null),
                    registeredNumber: groupCompany.registeredNumber || '',
                    type: groupCompany.type || '',
                    registeredAddress: {
                        ...groupCompany.registeredAddress, country: _.get(groupCompany, 'registeredAddress.country.code', null) || _.get(groupCompany, 'registeredAddress.country', null)
                    }
                }
            };

            form.setValues(newFormValues);

            this.setState({
                groupCompanySelected: groupCompany,
                authorizedSignatories: authorizedSignatoriesList
            })
        } else {
            form.setValues({
                ...form.values,
                company: {}
            });
        }
    };

    findGroupCompany(gcId) {
        return this.props.groupCompaniesList.find(gc => gc.id === gcId);
    };

    selectAuthorized = (value, form, field, replace) => {
        const stakeholder = this.props.authorizedSignatories.find(e => e.id === value);
        const email = stakeholder ? stakeholder.email : '';
        replace(0, { id: value, email })
    };


    updateEmailValue(e, form) {
        if (this.state.participantSelected.isAnEntity) {
            form.setFieldValue('participant.authorizedSignatory.email', e.target.value, false)
        }
    }

    getDateForPickerFromData(date) {
        if (!date) return undefined;
        return moment(date).toDate();
    }

    handleChange(e, field, form) {

        const date = e === null ? null : moment(e).format("YYYY-MM-DD");

        FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

        this.setState({
            [field.name]: e
        })
    }

    getAuthorizedSignatoriesList = (index, form) => {
        if (form.values && form.values.company) {
            const authorizedSignatories = this.filterAuthorizedByGroupCompany(form.values.company).map(as => ({
                id: as.id,
                label: as.label
            }))
            this.setState({
                authorizedSignatories: authorizedSignatories
            })
        } else {
            return [];
        }
    };

    getLabel = (key) => {
        switch (key) {
            case "fullName":
                if (this.state.participantSelected.isAnEntity) {
                    return "Company Name";
                } else {
                    return "Name";
                }
            case "country":
                if (this.state.participantSelected.isAnEntity) {
                    return "Country of Incorporation";
                } else {
                    return "Nationality";
                }
            case "address":
                if (this.state.participantSelected.isAnEntity) {
                    return "Registered Address";
                } else {
                    return "Residential Address";
                }
            case "passportOrRegistered":
                if (this.state.participantSelected.isAnEntity) {
                    return "Company Number";
                } else {
                    return "Passport Number";
                }
        }
    };

    getField = (key) => {
        switch (key) {
            case "passportOrRegistered":
                if (this.state.participantSelected.isAnEntity) {
                    return "participant.registeredNumber";
                } else {
                    return "participant.passportNumber";
                }
        }
    };

    getTooltip = (key, fieldName) => {
        switch (key) {
            case "fullName":
                if (this.state.participantSelected.isAnEntity) {
                    return "Participant's Company's Name";
                } else {
                    return "Funder's Name";
                }
            case "passportOrRegistered":
                if (this.state.participantSelected.isAnEntity) {
                    return "Company Number";
                } else {
                    return "Passport Number";
                }
            case "country":
                if (this.state.participantSelected.isAnEntity) {
                    return "Country";
                } else {
                    return "Nationality";
                }
            case "addressLine":
                if (this.state.participantSelected.isAnEntity) {
                    return "Registered Address - Address";
                } else {
                    return "Residential Address - Address";
                }
            case "addressCity":
                if (this.state.participantSelected.isAnEntity) {
                    return "Registered Address - City";
                } else {
                    return "Residential Address - City";
                }
            case "addressState":
                if (this.state.participantSelected.isAnEntity) {
                    return "Registered Address - State";
                } else {
                    return "Residential Address - State";
                }
            case "addressCountry":
                if (this.state.participantSelected.isAnEntity) {
                    return "Registered Address - Country";
                } else {
                    return "Residential Address - Country";
                }
            case "addressPostCode":
                if (this.state.participantSelected.isAnEntity) {
                    return "Registered Address - Post Code";
                } else {
                    return "Residential Address - Post Code";
                }
            case "recipientNotice":
                if (fieldName === 'company') {
                    return "Company's";
                } else {
                    return "Participant's";
                }
        }
    };

    getPlaceholder = (key) => {
        switch (key) {
            case "passportOrRegistered":
                if (this.state.participantSelected.isAnEntity) {
                    return "Company Number...";
                } else {
                    return "Passport Number...";
                }
        }
    };

    renderRecipientNotice(fieldName, isAnEntity) {
        const { tooltips = {} } = this.props;

        if (isAnEntity) {
            return (
                <React.Fragment>
                    <Field name={`${fieldName}.recipientNotice.fullName`}>
                        {({ field, form }) => (
                            <TextInput
                                classNameInput={{ root: classes.ContainerRow }}
                                required={true}
                                type='text'
                                label='Recipient for Notices: '
                                field={field}
                                form={form}
                                placeholder={"Recipient's Name..."}
                                tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices"]}
                            />
                        )}
                    </Field>

                    <div className={`${classes.Select}`}>
                        <Field name={`${fieldName}.recipientNotice.address.country`}>
                            {({ field, form }) => (
                                <ClaraCountrySelector
                                    isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                    jurisdictionsEnabled={false}
                                    countryField={`${fieldName}.recipientNotice.address.country`}
                                    label={"Recipient's Address: "}
                                    setFieldValue={form.setFieldValue}
                                    initialValues={{ country: _.get(form.values, `${fieldName}.recipientNotice.address.country`, '') }}
                                    returnCountryAsString={_.isEmpty(_.get(form.values, `${fieldName}.recipientNotice.address.country.code`, ''))}
                                    additionalStyle={'ClaraCountrySelectorModal'}
                                    tooltips={{
                                        country: tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Country"]
                                    }}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.address.street`}>
                            {({ field, form }) => (
                                <TextAreaInput
                                    type='text'
                                    label={' '}
                                    field={field}
                                    form={form} placeholder={"Address..."}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Address"]}
                                />
                            )}
                        </Field>
                    </div>
                    <Field name={`${fieldName}.recipientNotice.address.city`}>
                        {({ field, form }) => (
                            <TextInput
                                classNameInput={{ root: classes.ContainerRow }}
                                type='text'
                                field={field}
                                form={form}
                                placeholder={"City..."}
                                label={' '}
                                tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - City"]}
                            />
                        )}
                    </Field>

                    <Field name={`${fieldName}.recipientNotice.address.state`}>
                        {({ field, form }) => (
                            <TextInput
                                classNameInput={{ root: classes.ContainerRow }}
                                type='text'
                                field={field}
                                form={form}
                                placeholder={"State / Region..."}
                                label={' '}
                                tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - State"]}
                            />
                        )}
                    </Field>
                    <Field name={`${fieldName}.recipientNotice.address.zipCode`}>
                        {({ field, form }) => (
                            <TextInput
                                classNameInput={{ root: classes.ContainerRow }}
                                type='text'
                                field={field} form={form}
                                label={' '}
                                placeholder={"Post Code"}
                                tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Post Code"]}
                            />

                        )}
                    </Field>
                    <Field name={`${fieldName}.recipientNotice.email`}>
                        {({ field, form }) => (
                            <TextInput
                                classNameInput={{ root: classes.ContainerRow }}
                                required={true}
                                type='text'
                                label="Recipient's Email Address: "
                                field={field}
                                form={form}
                                placeholder={"Email Address..."}
                                tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Email Address"]}
                            />
                        )}
                    </Field>
                </React.Fragment>
            )
        }
    };


    getStakeholderAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add New Individual Stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add more details later from the stakeholder's profile page.",
                placeholder: "Enter Stakeholder's Name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: false,
                    // roles : [{
                    // 	role:"SHAREHOLDER",
                    // 	groupCompany: this.state.groupCompanySelected.id,
                    // }]
                },
                paramsReturn: 'isAnEntity'
            },
        ]
    };

    getCompanyAdds = () => {
        return [{
            type: "groupCompany",
            label: "Add New Company",
            textWarning: "This will create a new company for your startup. You can add more details later on the company's profile page.",
            placeholder: "Company Name...",
            startupId: this.props.startup.id,
            params: null,
            paramsReturn: `
				name
				authorizedSignatories {
					id
					stakeholder {
						id
						email
						fullName
						roles {  id
        name
        groupCompany { id name}
        stakeholder { id }}
				}
			`
        }]
    };

    getAuthorizedSignatoryAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add New Individual Stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add more details later from the stakeholder's profile page.",
                placeholder: "Enter Stakeholder's Name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: false,
                    roles: [{
                        role: "DIRECTOR",
                        groupCompany: this.state.groupCompanySelected.id,
                    }]
                }
            }
        ]
    };

    handleStakeholderCreate = (value, name, additionalValue, form) => {

        let { stakeholders, stakeholderList } = this.props;
        let newSh = { id: value.id, fullName: name, ...additionalValue };
        stakeholderList.push(newSh);

        stakeholders.push({ id: value.id, label: name });
        // this.setState({ groupCompaniesList, groupCompanies });

    };

    handleGroupCompanyCreate = (value, name, additionalValue, form) => {

        let { groupCompanies, groupCompaniesList } = this.props;
        console.log(value)
        let newGc = { id: value.id, name, ...additionalValue };
        groupCompaniesList.push(newGc);

        groupCompanies.push({ id: value.id, label: name });
        // this.setState({ groupCompaniesList, groupCompanies });

    };

    handleAuthorizedSignatoryCreate = (value, name, additionalValue, form) => {

        let { authorizedSignatories } = this.props;
        const { groupCompanySelected } = this.state;

        authorizedSignatories.push({
            id: value.id,
            label: name,
            role: 'DIRECTOR',
            groupCompanyId: groupCompanySelected.id
        });

    };


    render() {
        const isRequired = message => value => (!!value ? undefined : message);
        const { tooltips = {}, formType } = this.props;

        return (
            <React.Fragment>
                <h1 className={classes.title}>
                    Edit Document
                </h1>
                {formType == 'uploadToEdit' ? (<div>
                    <Field name={`file`}>
                        {({ field, form }) => (
                            <ClaraUpload
                                required={true}
                                name={`filepond`}
                                label='Upload your document: '
                                field={field}
                                form={form}
                            />
                        )}
                    </Field>
                </div>) : null}
                <Field name='agreementName'>
                    {({ field, form }) => (
                        <TextInput
                            classNameInput={{ root: classes.ContainerRow }}
                            required={true}
                            type='text'
                            label='Document Name: '
                            field={field}
                            form={form}
                            placeholder={"Document Name..."}
                            tooltip={tooltips['Document Name']}
                        />
                    )}
                </Field>
                <Line className={classes.line} />
                <div className={classes.ZoneTitle}>
                    <h1>{'Company'}</h1>
                </div>
                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                    <Field name='company.id'>
                        {({ field, form }) => (
                            <ClaraSelect
                                required={true}

                                field={field}
                                form={form}
                                label={"Select Company: "}
                                lists={this.getGroupCompanies()}
                                mode={"classic"}
                                callbacks={{
                                    createValues: this.handleGroupCompanyCreate,
                                    updateValues: this.selectGroupCompany
                                }}
                                placeholder={"Select from the list..."}
                                tooltip={tooltips['Select Company']}
                            />
                        )}
                    </Field>
                </div>
                {
                    this.state.groupCompanySelected ?
                        <React.Fragment>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Company Details</h1>
                                <p>Please verify or update the company's information below:</p>
                            </div>
                            <Field name='company.name'>
                                {({ field, form }) => (
                                    <TextInput
                                        classNameInput={{ root: classes.ContainerRow }}
                                        required={true}
                                        type='text'
                                        label='Company Name: '
                                        field={field}
                                        form={form}
                                        placeholder={"Company Name..."}
                                        tooltip={tooltips["Company's Name"]}
                                    />
                                )}
                            </Field>
                            <div className={` ${classes.Select}`}>
                                <Field name='company.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={true}
                                            countryField={'company.country'}
                                            returnCountryAsString={true}
                                            label={'Country of Incorporation: '}
                                            jurisdictionField={'company.jurisdiction'}
                                            jurisdictionLabel={'Jurisdiction: '}
                                            otherJurisdictionField={'company.otherJurisdiction'}
                                            setFieldValue={form.setFieldValue}
                                            initialValues={{
                                                jurisdiction: _.get(form.values, 'company.jurisdiction', '')
                                            }}
                                            additionalStyle={'ClaraCountrySelectorModal'}
                                            tooltips={{
                                                country: tooltips["Company's Country"],
                                                jurisdiction: tooltips["Company's Jurisdiction"],
                                                other: tooltips['Other Jurisdiction']
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <Field name='company.type'>
                                {({ field, form }) => (
                                    <TextType
                                        classNameInput={{ root: classes.ContainerRow }}
                                        required={true}
                                        groupCompany={this.state.groupCompanySelected}
                                        type='text'
                                        label='Company Type: '
                                        field={field}
                                        form={form}
                                        placeholder={'Company Type...'}
                                        tooltip={tooltips["Company's Company Type"]}
                                    />
                                )}
                            </Field>
                            <Field name='company.registeredNumber'>
                                {({ field, form }) => (
                                    <TextInput
                                        classNameInput={{ root: classes.ContainerRow }}
                                        required={true}
                                        type='text'
                                        label='Company Number: '
                                        field={field}
                                        form={form}
                                        placeholder={'Company Number...'}
                                        tooltip={tooltips["Company's Company Number"]}
                                    />
                                )}
                            </Field>
                            <div className={` ${classes.Select}`}>
                                <Field name='company.registeredAddress.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={false}
                                            countryField={'company.registeredAddress.country'}
                                            label={'Registered Address: '}
                                            returnCountryAsString={true}
                                            setFieldValue={form.setFieldValue}
                                            additionalStyle={'ClaraCountrySelectorModal'}
                                            tooltips={{
                                                country: tooltips["Company's Registered Address - Country"]
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.registeredAddress.street'>
                                    {({ field, form }) => (
                                        <TextAreaInput
                                            type='text'
                                            label={' '}
                                            field={field}
                                            form={form}
                                            placeholder={"Address..."}
                                            tooltip={tooltips["Company's Registered Address - Address"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <Field name='company.registeredAddress.city'>
                                {({ field, form }) => (
                                    <TextInput
                                        classNameInput={{ root: classes.ContainerRow }}
                                        type='text'
                                        label=' '
                                        field={field}
                                        form={form}
                                        placeholder={'City...'}
                                        tooltip={tooltips["Company's Registered Address - City"]}
                                    />
                                )}
                            </Field>

                            <Field name='company.registeredAddress.state'>
                                {({ field, form }) => (
                                    <TextInput
                                        classNameInput={{ root: classes.ContainerRow }}
                                        type='text'
                                        label=' '
                                        field={field}
                                        form={form}
                                        placeholder={'State / Region...'}
                                        tooltip={tooltips["Company's Registered Address - State"]}
                                    />
                                )}
                            </Field>
                            <Field name='company.registeredAddress.zipCode'>
                                {({ field, form }) => (
                                    <TextInput
                                        classNameInput={{ root: classes.ContainerRow }}
                                        type='text'
                                        label=' '
                                        field={field}
                                        form={form}
                                        placeholder={"Post Code..."}
                                        tooltip={tooltips["Company's Registered Address - Post Code"]}
                                    />
                                )}
                            </Field>
                            <Line className={classes.line} />
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Domain Address</h1>
                                <p>The domain address of your startup's website.</p>
                            </div>
                            <Field name='terms.domainAddress'>
                                {({ field, form }) => {
                                    return (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label='Website: '
                                            classNameInput={{ root: classes.ContainerRow }}
                                            field={field}
                                            form={form}
                                            placeholder={'Domain Name...'}
                                            tooltip={`${tooltips['Domain Address']}`}
                                        />
                                    )
                                }}
                            </Field>
                            <Line className={classes.line} />
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Support Email</h1>
                                <p>The email address to which website users can send any questions or enquiries about the website.</p>
                            </div>
                            <Field name='terms.supportEmail'>
                                {({ field, form }) => (
                                    <TextInput
                                        required={true}
                                        classNameInput={{ root: classes.ContainerRow }}
                                        label='Email Address:  '
                                        placeholder={'Email Address...'}
                                        field={field}
                                        form={form}
                                        tooltip={tooltips['Support Email']}
                                    />
                                )}
                            </Field>
                            <Line className={classes.line} />
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Essential Cookies</h1>
                                <p>These cookies are essential to enable users to move around your site and use its features e.g. accessing secure areas. Without these cookies, your website cannot provide services to users.</p>
                            </div>
                            <ClaraAddInputs
                                isModal={true}
                                fieldArrayName={'terms.cookies'}
                                fields={[
                                    {label:'Name:',isRequired:true,fieldName:'name'},
                                    {label:'Description:',isRequired:true,fieldName:'description'},
                                    {label:'Duration:',isRequired: true,fieldName:'duration'}
                                ]}
                                isOtherBy={(id)=>id==='option_z'}
                                listSelect={[
                                    {label:'Until deleted by user',id:'option_a'},
                                    {label:'Set time',id:'option_z'},
                                    {label:'End of browser session',id:'option_b'}]}
                                listOther={[{label:'Years',id:'years'},{label:'Months',id:'months'},{label:'Days',id:'days'}]}
                                textInputOther={{placeholder:'e.g. 10'}}
                                fieldOtherName={{inputName:'otherDuration',selectName:'otherPeriod'}}
                            />
                            <Line className={classes.line} />
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Essential Third Party Cookies</h1>
                                <p>Third parties' cookies are used to provide important functionality to your site, as well as collecting anonymous user data for analysis.</p>
                            </div>
                            <ClaraAddInputs
                                isModal={true}
                                fieldArrayName={'terms.thirdPartyCookies'}
                                fields={[
                                    {label:'Name:',isRequired:true,fieldName:'name'},
                                    {label:'Description:',isRequired:true,fieldName:'description'},
                                    {label:'Duration:',isRequired: true,fieldName:'duration'}
                                ]}
                                isOtherBy={(id)=>id==='option_z'}
                                listSelect={[
                                    {label:'Until deleted by user',id:'option_a'},
                                    {label:'Set time',id:'option_z'},
                                    {label:'End of browser session',id:'option_b'}]}
                                listOther={[{label:'Years',id:'years'},{label:'Months',id:'months'},{label:'Days',id:'days'}]}
                                textInputOther={{placeholder:'e.g. 10'}}
                                fieldOtherName={{inputName:'otherDuration',selectName:'otherPeriod'}}
                            />
                            <Line className={classes.line} />
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Functional Cookies</h1>
                                <p>These non-essential cookies allow your site to remember choices made by users. The information these cookies collect may be anonymised and they cannot track a user's browsing activity on other sites.</p>
                            </div>
                            <ClaraAddInputs
                                isModal={true}
                                fieldArrayName={'terms.functionalCookies'}
                                fields={[
                                    {label:'Name:',isRequired:true,fieldName:'name'},
                                    {label:'Description:',isRequired:true,fieldName:'description'},
                                    {label:'Duration:',isRequired: true,fieldName:'duration'}
                                ]}
                                listOther={[{label:'Years',id:'years'},{label:'Months',id:'months'},{label:'Days',id:'days'}]}
                                isOtherBy={(id)=>id==='option_z'}
                                listSelect={[
                                    {label:'30 days',id:'option_a'},
                                    {label:'Until deleted by user',id:'option_b'},
                                    {label:'Set time',id:'option_z'},
                                    {label:'End of browser session',id:'option_c'},
                                ]}
                                textInputOther={{placeholder:'e.g. 10'}}
                                fieldOtherName={{inputName:'otherDuration',selectName:'otherPeriod'}}
                            />
                            <Line className={classes.line} />
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Third Party Performance Cookies</h1>
                                <p>These non-essential cookies collect information about how visitors use our site e.g. which pages visitors go to most often and if they get error messages from web pages. These cookies don't collect information that identifies a visitor. All information these cookies collect is aggregated and therefore anonymous. It is only used to improve how a website works.</p>
                            </div>
                            <ClaraAddInputs
                                isModal={true}
                                fieldArrayName={'terms.thirdPartyPerfCookies'}
                                fields={[
                                    {label:'Name:',isRequired:true,fieldName:'name'},
                                    {label:'Description:',isRequired:true,fieldName:'description'},
                                    {label:'Duration:',isRequired: true,fieldName:'duration'}
                                ]}
                                listSelect={[
                                    {label:'2 years',id:'option_a'},
                                    {label:'1 minute',id:'option_b'},
                                    {label:'24 hours',id:'option_c'},
                                    {label:'Until deleted by user',id:'option_d'}]}
                                textInputOther={{placeholder:'e.g. 10'}}
                            />
                        </React.Fragment>
                        : null
                }

                {formType === 'uploadToEdit' ? <div>
                    <Field name='confirmSameData'>
                        {({ field, form }) => (
                            <Checkbox
                                className={classes.sameDataCheckbox}
                                field={field}
                                validate={isRequired('This field is required')}
                                form={form}
                                required={true}
                                label='All document details are verified and correct.'
                            />
                        )}
                    </Field>
                </div> : null}
            </React.Fragment>

        );
    }

}

export default WebsiteCookieNotice;

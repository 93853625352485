import React, { FC } from "react";
import useTranslate from "../../../../../../generic/hooks/useTranslate";
import Bubble from "../../../../Bubble";
import imagePay from "../../../../../../../images/Payment.svg";
import classes from "./payment.module.scss";
import classnames from "classnames";
import NextIcon from "../../../../../../../modules/generic/components/NextIcon";
import Button from "../../../../../../../modules/generic/components/Buttons/Button";
import useModal from "../../../../../../generic/hooks/useModal";
import { Constants } from "../../../../../../../v1/utils/constants";
import { useDispatch } from "react-redux";
import { goToPage } from "../../../../../../../store/actions/page";
import PayOverdueModal from "src/modules/startup/PayOverdueModal";
import moment from "moment";

export interface PaymentCardProps {
  date?: string,
  isSubscriptionPage?: boolean,
  btnOnClick?: (event: React.MouseEvent<HTMLInputElement>) => void
}

const PaymentCard: FC<PaymentCardProps> = ({
  date,
  isSubscriptionPage = false,
  ...props
}: PaymentCardProps) => {

  const { translate } = useTranslate();
  const { openModal, closeModal } = useModal();
  const dispatch = useDispatch();

  return (
    <>
      <Bubble
        color={"pay"}
      >
        <div className={classnames(classes.BubbleContentsNext)}>
          <NextIcon
            title={translate("MODULES.CHECKOUT.PAYMENT_OVERDUE")}
            subTitle={translate("MODULES.CHECKOUT.PAYMENT_SUBTITLE", { date: moment(date).format("DD MMM YYYY") })}
            imageIcon={imagePay}
            removeSeparator={true}
          />
          <div className={classnames(classes.buttonSeparator)}>
            {isSubscriptionPage ?
              <Button
                variant={"pay"}
                children={translate("MODULES.CHECKOUT.BUTTON_STARTUP_MAKE_PAYMENT")}
                onClick={() => {
                  openModal(PayOverdueModal, {})
                }}
              ></Button> :
              <Button
                variant={"pay"}
                children={translate("MODULES.CHECKOUT.BUTTON_MANAGE_SUBSCRIPTION")}
                onClick={() => {
                  goToPage(dispatch, Constants.PAGES.subscriptions);
                }}
              ></Button>
            }
          </div>
        </div>
      </Bubble>
    </>
  );
};

export default PaymentCard;

import useMutation from '../../../custom/useMutation'
import { RoleFields } from '../../../../modules/stakeholder/graphql/typePolicies'
import { StakeholderPositionsFragment } from '../../../../modules/stakeholder/graphql/queries'
import { COMPANY_CORPORATE_MAP } from '../../../../modules/map/hooks/services/useCompanyCorporateMap'
import { useSelector } from 'react-redux'
import { gql } from '@apollo/client';
import _ from "lodash";
import useSession from '../../../../modules/session/hooks/useSession'
export const UPSERT_TWO_DOCUMENT_FORMATION = `
mutation upsertTwoDocumentFormation(
  $startupId: ID!
  $stakeholderId: ID!
  $identityDocumentDataOLD: IdentityDocumentInput
  $identityDocumentData: IdentityDocumentInput
) {
  addIdentityDocumentStakeholder(
    startupId: $startupId
    stakeholderId: $stakeholderId
    identityDocumentData: $identityDocumentData
  ) {
    id
    number
    nationality
    expiryDate
    current
    documents {
      signedAs
      id
      type {
        code
      }
      status
      file {
        id
        name
      }
    }
  }

  addIdentityDocumentStakeholderOLD: addIdentityDocumentStakeholder(
    startupId: $startupId
    stakeholderId: $stakeholderId
    identityDocumentData: $identityDocumentDataOLD
  ) {
    id
    number
    nationality
    expiryDate
    current
    documents {
      signedAs
      id
      type {
        code
      }
      status
      file {
        id
        name
      }
    }
  }
}
`

const useUpsertTwoDocumentFormation = () => {
    //const { startupId } = useSession();
    const mutation = useMutation(
      UPSERT_TWO_DOCUMENT_FORMATION,
        { showSuccessNotification: false },
       // { refetchQueries: [{ query: gql`${COMPANY_CORPORATE_MAP}`, variables: { startupId: startupId } }], ...props }
    )

    return mutation
}

export default useUpsertTwoDocumentFormation

import '../../../../../../modules/generic/components/Icon/clara/style.css';

import React, { useMemo } from 'react';

import Avatar from '../../../../../../modules/generic/components/Avatars/AvatarLabel';
import { Constants } from '../../../../../../v1/utils/constants';
import Icon from '../../../../../../modules/generic/components/Icon';
import TextHandlebars from '../../../../../../components/text/TextHandlebars/TextHandlebars';
import _ from 'lodash';
import classes from './MessageTemplate.module.scss';
import { mapEntityData } from '../utils/mapEntityData';
import moment from 'moment';
import useCategories from "../../../../../../hooks/common/useCategories";
import useSession from "./../../../../../../modules/session/hooks/useSession";

const getCountryByCode = (code) => {
    const countries = _.invert(Constants.COUNTRYCODES);
    return countries[code];
}

const MessageTemplate = ({ template, activityTime = "", activityDate = "", createdBy, ...props }) => {

    
    const { startup } = useSession();
    const {categories, loading} = useCategories("STARTUP")

    const getDataForTemplateByDocumentType=()=>{
        switch (template.agreement.type){
            case 'SHARE_INCENTIVE_PLAN':
                return {
                    documentName: template.agreement.title,
                    documentType: _.startCase(_.camelCase(template.agreement.type)),
                    parties: template.parties,
                    isThereCustomMessage:true,
                    customMessage:_.some(props.actions,action=>action.type==='GO_TO_DOCUMENT_LIST')?'There is a grant agreement assigned to this SIP would you like to review it?':'You can now create a Grant Agreement and attached it to this SIP'
                };
            case 'ADVISOR_AGREEMENT':
                return{
                    documentName: template.agreement.title,
                    documentType: _.startCase(_.camelCase(template.agreement.type)),
                    parties: template.parties,
                    isThereCustomMessage:true,
                    customMessage:_.some(props.actions,action=>action.type==='GO_TO_CREATE_DOCUMENTS')?'You can now create a SIP Agreement':'You can now create a Grant Agreement and attach it to a SIP or create a new SIP Agreement'
                }
             default:
                return{
                    documentName: template.agreement.title,
                    documentType: _.startCase(_.camelCase(template.agreement.type)),
                    parties: template.parties,
                    isThereCustomMessage:false
                }
        }
    }
    const getCategoryFromSubCategory = (subCategoryInput) => {

        let category = '';
        let subCategory = '';

        for (let categoryEach in categories) {
            const cat = categories[categoryEach];
            if (cat && cat.subCategories) {
                const sc = cat.subCategories.filter(subCat => subCat.key === subCategoryInput)[0];
                if (sc) {
                    category = cat.title;
                    subCategory = sc.title;
                }
            }
        }

        return { category, subCategory };
    }

    const mapData = (template) => {
        switch (template.type) {
            case "STAKEHOLDER_EDITED":
                {

                    const mapRoles = (roles) => {

                        return _.map(roles, role => ({ role: Constants.ROLES_LABEL[role.type], companyName: role.groupCompany }))
                    }

                    return ({
                        ...template,
                        addedRoles: mapRoles(_.get(template, "addedRoles", [])),
                        deletedRoles: mapRoles(_.get(template, "deletedRoles", [])),
                        updatedRoles: mapRoles(_.get(template, "updatedRoles", [])),
                        stakeholderName: _.get(template, "stakeholder.fullName", ""),
                        updatedPosition: mapRoles(_.get(template, "updatedPosition", [])),
                        deletedPosition: mapRoles(_.get(template, "deletedPosition", [])),

                    })
                }

            case "STAKEHOLDER_CREATED":
                {
                    const stakeholder = _.get(template, "stakeholder");
                    const role = _.map(stakeholder.roles, ({ role, groupCompany }) =>{
                        const company = _.find(startup.groupCompanies, gc => gc.id === groupCompany);
                        return { role: Constants.ROLES_LABEL[role], companyName: _.get(company,"name")};
                    });
                        
                    return { stakeholderName: stakeholder.fullName, role };
                }

            case "STAKEHOLDER_DELETED":
                return { stakeholderName: template.stakeholder.fullName }


            case "EQUITY_POSITION_CREATED":
                {
                    return template.equityPosition;
                }
            case "EQUITY_POSITION_EDITED":
                {
                    const typeEquity = _.toUpper(template.equityPosition.equityPositionName);
                    return { ...template.equityPosition, ...template, [typeEquity]: true };
                }

            case "EQUITY_POSITION_DELETED":
                {

                    const typeEquity = _.toUpper(template.equityPosition.equityPositionName);
                    return { ...template.equityPosition, activityTime, activityDate, [typeEquity]: true };

                }

            case "ACT_EQUITY_CREATED":
                {   
                    const entityData = mapEntityData({ ...template })
                    return { activityTime, activityDate, ...entityData, entityAction: "added" };
                }


            case "ACT_EQUITY_EDITED":
                {
                    const entityData = mapEntityData({ ...template })
                    return { activityTime, activityDate, ...entityData, entityAction: "edited" };
                }


            case "ACT_EQUITY_DELETED":
                {
                    const entityData = mapEntityData({ ...template })
                    return { activityTime, activityDate, ...entityData, entityAction: "deleted" };
                }


            case "ACT_EQUITY_PUBLISHED":
                {
                    const entityData = mapEntityData({ ...template})
                    return { activityTime, activityDate, ...entityData,entityAction: "published" };
                }

            case "ACT_EQUITY_PUBLISH_AND_SHARING":
                {
                    const entityData = mapEntityData({ ...template })
                    return { activityTime, activityDate, ...entityData, entityAction: "published and shared" };
                }

          
                

            // Option Plan
            case "OPTION_PLAN_CREATED":
                {
                    const entityData = mapEntityData({ ...template })
                    return { activityTime, activityDate, ...entityData, entityAction: "added" };
                }

            case "OPTION_PLAN_EDITED":
                {
                    const entityData = mapEntityData({ ...template })
                    return { activityTime, activityDate, ...entityData, entityAction: "edited" };
                }


            case "OPTION_PLAN_DELETED":
                {
                    const entityData = mapEntityData({ ...template })
                    return { activityTime, activityDate, ...entityData, entityAction: "deleted" };
                }


            case "OPTION_PLAN_PUBLISHED":
                {
                    const entityData = mapEntityData({ ...template })
                    return { activityTime, activityDate, ...entityData, entityAction: "published" };
                }   

            case "OPTION_PLAN_PUBLISH_AND_SHARING":
                {
                    const entityData = mapEntityData({ ...template })
                    return { activityTime, activityDate, ...entityData, entityAction: "published and shared" };
                }
            // Share Class
            case "SHARE_CLASS_CREATED":
                {
                    const entityData = mapEntityData({ ...template })
                    return { activityTime, activityDate, ...entityData, entityAction: "added" };
                }

            case "SHARE_CLASS_EDITED":
                {
                    const entityData = mapEntityData({ ...template })
                    return { activityTime, activityDate, ...entityData, entityAction: "edited" };
                }


            case "SHARE_CLASS_DELETED":
                {
                    const entityData = mapEntityData({ ...template })
                    return { activityTime, activityDate, ...entityData, entityAction: "deleted" };
                }


            case "SHARE_CLASS_PUBLISHED":
                {
                    const entityData = mapEntityData({ ...template })
                    return { activityTime, activityDate, ...entityData, entityAction: "published" };
                }

            case "SHARE_CLASS_PUBLISH_AND_SHARING":
                {
                    const entityData = mapEntityData({ ...template })
                    return { activityTime, activityDate, ...entityData, entityAction: "published" };
                }
     
            //  Vesting 
            case "VESTING_TERM_CREATED":
                {
                    const entityData = mapEntityData({ ...template })
                    return { activityTime, activityDate, ...entityData, entityAction: "added" };
                }

            case "VESTING_TERM_EDITED":
                {
                    const entityData = mapEntityData({ ...template })
                    return { activityTime, activityDate, ...entityData, entityAction: "edited" };
                }


            case "VESTING_TERM_DELETED":
                {
                    const entityData = mapEntityData({ ...template })
                    return { activityTime, activityDate, ...entityData, entityAction: "deleted" };
                }


            case "VESTING_TERM_PUBLISHED":
                {
                    const entityData = mapEntityData({ ...template })
                    return { activityTime, activityDate, ...entityData, entityAction: "published" };
                }



            // Valuation
            case "VALUATION_CREATED":
                {
                    const entityData = mapEntityData({ ...template })
                    return { activityTime, activityDate, ...entityData, entityAction: "added" };
                }

            case "VALUATION_EDITED":
                {
                    const entityData = mapEntityData({ ...template })
                    return { activityTime, activityDate, ...entityData, entityAction: "edited" };
                }


            case "VALUATION_DELETED":
                {
                    const entityData = mapEntityData({ ...template })
                    return { activityTime, activityDate, ...entityData, entityAction: "deleted" };
                }


            case "VALUATION_PUBLISHED":
                {
                    const entityData = mapEntityData({ ...template })
                    return { activityTime, activityDate, ...entityData, entityAction: "published" };
                }
   
            // Modelling
            case "SCENARIO_MODELLING_CREATED":
                {
                    const entityData = mapEntityData({ ...template })
                    return { activityTime, activityDate, ...entityData, entityAction: "added" };
                }

            case "SCENARIO_MODELLING_DELETED":
                {
                    const entityData = mapEntityData({ ...template })
                    return { activityTime, activityDate, ...entityData, entityAction: "deleted" };
                }


                
            // FoT    
            case "FOT_CREATED":
                {
                    const entityData = mapEntityData({ ...template })
                    return { activityTime, activityDate, ...entityData, entityAction: "added" };
                }


            case "FOT_EDITED":
                {
                    const entityData = mapEntityData({ ...template })
                    return { activityTime, activityDate, ...entityData, entityAction: "edited" };
                }


            case "FOT_DELETED":
                {
                    const entityData = mapEntityData({ ...template })
                    return { activityTime, activityDate, ...entityData, entityAction: "deleted" };
                }


            case "FOT_PUBLISHED":
                {
                    const entityData = mapEntityData({ ...template })
                    return { activityTime, activityDate, ...entityData, entityAction: "published" };
                }
                
            case "COMPANY_CREATED": {

                const { jurisdiction = "", country = "", name: companyName } = template.groupCompany
                return { companyName, jurisdiction, country }
            }

            case "COMPANY_EDITED":
                {
                    const mapAddres = ({ registeredAddress = {} }) => {
                        const templateAddres = registeredAddress.newValue && `
                   ${registeredAddress.newValue.country ? `<li>Registered Address Country changed from ${getCountryByCode(registeredAddress.oldValue.country)} to ${getCountryByCode(registeredAddress.newValue.country)}</li>` : ""}
                   ${registeredAddress.newValue.city ? `<li>Registered Address city changed from ${registeredAddress.oldValue.city} to ${registeredAddress.newValue.city}</li>` : ""}
                   ${registeredAddress.newValue.state ? `<li>Registered Address state changed from ${registeredAddress.oldValue.state} to ${registeredAddress.newValue.state}</li>` : ""}
                   ${registeredAddress.newValue.street ? `<li>Registered Address state changed from ${registeredAddress.oldValue.street} to ${registeredAddress.newValue.street}</li>` : ""}
                   `
                        return { ...registeredAddress, templateAddres }

                    };
                    return {
                        ..._.omit(template, "type"),
                        fieldsChanged: { ...template.fieldsChanged, address: mapAddres(_.get(template, "fieldsChanged", {})) },
                        companyName: template.groupCompany.name,

                    };
                }
            case "COMPANY_DELETED":
                return { companyName: template.groupCompany.name }

            case "PROFILE_CREATED":
                return { activityTime, activityDate, startupName: template.startupName };
            case "PROFILE_EDITED":

                return { activityTime, activityDate, startupName: template.startupName, ...template };


            case "DOCUMENT_CREATED": {

                return {
                    ...getCategoryFromSubCategory(template.agreement.subCategories[0]),
                    activityTime,
                    activityDate,
                    documentName: template.agreement.title,
                    parties: template.parties,
                    documentType: _.startCase(_.camelCase(template.agreement.type))
                };
            }
            case "DOCUMENT_EDITED": {
                const fieldsChanged = _.get(template, "fieldsChanged");
                let categoriesValues = {}
                if (_.get(fieldsChanged, "subCategories.newValue")) {
                    const { category: oldCategory, subCategory: oldSubCategory } = getCategoryFromSubCategory(_.get(fieldsChanged, "subCategories.oldValue[0]"))
                    const { category, subCategory } = getCategoryFromSubCategory(_.get(fieldsChanged, "subCategories.newValue[0]"))
                    categoriesValues = {
                        category: {
                            oldCategory,
                            category
                        },
                        subCategories: {
                            oldSubCategory,
                            subCategory
                        }
                    }

                }

                return {
                    ...template,
                    fieldsChanged: {
                        ...fieldsChanged,
                        ...categoriesValues

                    },
                    documentName: _.get(template, "agreement.title", "Not name"),
                };
            }
            case "DOCUMENT_DELETED":
                {

                    return {
                        ...getCategoryFromSubCategory(template.agreement.subCategories[0]),
                        documentName: template.agreement.title ? template.agreement.title : template.agreement.name,
                        activityTime,
                        activityDate,
                        parties: template.parties,
                        documentType: _.startCase(_.camelCase(template.agreement.type))
                    };
                }

            case "UNCATEGORIZED_DOCUMENT_DELETED":
            {
                return { documentName: template.file.name }
            }

        
            case "GENERATED_DOCUMENT_CREATED":
                return {
                    documentType: _.startCase(_.camelCase(template.agreement.type)),
                    activityTime,
                    activityDate,
                    documentName: template.agreement.title,
                    parties: template.parties
                };
            case "GENERATED_DOCUMENT_SENT": {
                return {
                    documentName: template.agreement.title,
                    documentType: _.startCase(_.camelCase(template.agreement.type)),
                    parties: template.parties
                };
            }
			case "GENERATED_DOCUMENT_VOID": {
				return {
					documentName: template.agreement.title,
					documentType: _.startCase(_.camelCase(template.agreement.type)),
					voidedReason: template.voidedReason
				};
			}
			case "GENERATED_DOCUMENT_RESEND": {
				return {
					documentName: template.agreement.title,
					documentType: _.startCase(_.camelCase(template.agreement.type)),
					parties: template.parties
				};
			}
			case "GENERATED_DOCUMENT_SIGNED":
                return  getDataForTemplateByDocumentType();

            case "DUE_DILIGENCE_CREATED":
                return {
                    remarkName: template.remark.title,
                    urgency: _.capitalize(template.remark.urgency),
                    category: _.startCase(template.remark.category),
                    description: template.remark.description,
                    subCategory: _.startCase(template.remark.subCategory)
                };
                case  "DOCUMENT_CHANGED_NAME":
                    return {
                        documentName:_.get(template,"oldAgreementName"),
                        newDocumentName:_.get(template,"agreement.title") || _.get(template,"agreement.file.name", ""),
                        date:moment(_.get(template,'agreement.updatedAt')).format('DD MMM YYYY'),
                        time:moment(_.get(template,'agreement.updatedAt')).format('hh:mm')
                    }    
            case "DUE_DILIGENCE_DELETED":
                return {
                    ...template.remark,
                    remarkName: template.remark.title,
                    userName: createdBy.fullName,
                    activityTime,
                    activityDate
                };
            case "DUE_DILIGENCE_EDITED": {

                const templateStatus = template.fieldsChanged &&
                    `${template.fieldsChanged.urgency ?
                        `<span  class="clara-Assistant ${classes.start} ${classes[template.fieldsChanged.urgency.oldValue]} " ></span>  <span>to:</span>  <span class="${classes.start} ${classes[template.fieldsChanged.urgency.newValue]}  clara-Assistant  "></span> `
                        : ''}`
                return { ...template, ...template.fieldsChanged, templateStatus, remarkName: _.get(template, 'remark.title', '') };
            }

            case "DUE_DILIGENCE_COMMENTED": {
                return { ...template };
            }


            case "PROFILE_GRANTED":
                return { ...template.sharingProfile };



            case "PROFILE_GRANT_REMOVED": {
                return { ...template.sharingProfile };
            }

            case "PROFILE_GRANT_CHANGED": {
                return { ...template.sharingProfile };
            }

            case "REPORT_DOWNLOADED":
                return {}

            case "UNCATEGORIZED_DOCUMENT_CREATED":
                return { documentName: template.file.name }


            case "DOCUMENT_CATEGORIZED": {
                const { category, subCategory } = getCategoryFromSubCategory(_.get(template, "agreement.subCategories[0]"));

                return {
                    category,
                    subCategory,
                    documentName: template.agreement.title,
                    documentType: _.startCase(_.camelCase(template.agreement.type)),
                    ...template

                };
            }

            case "DOCUMENT_VESTING_DATE_TODAY": {

                return ({
                    ...template,
                    stakeholderName: _.get(template, "stakeholder.fullName", ""),
                    numberOfOptionsInVestingPeriod: _.get(template, "vestingDate.numberOfOptions", ""),
                    grantName: _.get(template, "document.title", ""),

                })
            }

            case "DOCUMENT_VESTING_DATE_UPCOMING": {

                return ({
                    ...template,
                    stakeholderName: _.get(template, "stakeholder.fullName", ""),
                    numberOfOptionsInVestingPeriod: _.get(template, "vestingDate.numberOfOptions", ""),
                    grantName: _.get(template, "document.title", ""),

                })
            }
            case "GENERATED_DOCUMENT_TERMINATED":{
                return {
                    ...template,
                    grantName: _.get(template, "agreement.title", ""),
                }
            }
            default:
                return ({})
                break;
        }
    }

    const colorType = useMemo(() => {
        switch (_.get(template, "urgency")) {
            case "RED":
                return "#ff606f";
            case "GREEN":
                return "#71e096";
            case "AMBER":
                return "#fbbd2c";
            case "UNKNOWN":
                return "#a9b0bc";

            default:
                return "#2C74FF";
        }
    }, [template]);


    return (

        <div className={classes.containerMessageTemplateWithIcon}>

            {_.eq("DUE_DILIGENCE_COMMENTED", template.type) ?
                <div className={classes.containerAvatar}> <Avatar showLabel={false} name={createdBy.fullName} avatar={createdBy.avatar} /></div> :
                <div className={classes.containerIcon}> <Icon color={colorType} icon={"Assistant"} size={"1.8462rem"} isClara /></div>}

            <div className={`${classes.containerMessageTemplate} ${classes.containerStakeholderTemplate}`}>
                <TextHandlebars uuid={`MESSAGE_TEMPLATE_${template.type}`} params={{ activityTime, activityDate, userName: createdBy.fullName, ...mapData(template) }} />

                <label className={classes.date} >Received:<span> {`${activityTime} ${activityDate}`} </span></label>
            </div>
        </div>
    )
}

export default MessageTemplate;

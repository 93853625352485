import React from 'react';
import QuestionnaireIntroView from "./QuestionnaireIntro/QuestionnaireIntroView"
import QuestionnaireSubmittedView from "./QuestionnaireSubmitted/QuestionnaireSubmittedView"
import layout from "../../../../layouts/Clara/ClaraLayout"
import ACTIONS from "../../../../constants/actions"
import PAGES from "../../../../constants/pages";

const moduleRouter = [
  {
    path: PAGES.questionnaireIntro.path,
    exact: true,
    component: <QuestionnaireIntroView />,
    layout: layout,
    propsLayout : {},
    action: ACTIONS.COMPLETE_MATTER_FLOW,
  },
  {
    path: PAGES.questionnaireSubmitted.path,
    exact: true,
    component: <QuestionnaireSubmittedView />,
    layout: layout,
    propsLayout : {},
    action: ACTIONS.COMPLETE_MATTER_FLOW,
  },
];
export default moduleRouter;

import React, { FC, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { addAssistantText, clearAssistatText } from "src/modules/generic/store/action";
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import Button from "../../../../../generic/components/Buttons/Button";
import useCustomMachine from '../../../../../generic/context/MachineContext/useMachine';
import useTranslate from '../../../../../generic/hooks/useTranslate';
import IsThereDocument from "../../../forms/IsThereDocumentsConvertible";
interface StepProps {
    withAgreement?: boolean;

}
const IsThereDocumentsConvertibleStep: FC<StepProps> = ({ withAgreement }: StepProps) => {
    const { send, next, context } = useCustomMachine()
    const dispatch = useDispatch()
    const { translate } = useTranslate()
    useEffect(() => {
        dispatch(clearAssistatText())
        
    }, [])
    const handlePrev = () => {
        send("PREVIOUS")
    }
    const handleCancel = () => {
        send('CANCEL')
    }
    const handleCompleteSubmit = async (values): Promise<void> => {

        next(values)
    }
    return (<>
        <IsThereDocument
            propsTemplate={{
                title: translate(`MODULES.EQUITY.STEPS_EQUITY_POSITIONS.IS_THERE_DOCUMENTS_STEP.TITLE_${context.type.toUpperCase()}`),
                subTitle: translate(`MODULES.EQUITY.STEPS_EQUITY_POSITIONS.IS_THERE_DOCUMENTS_STEP.SUBTITLE_${context.type.toUpperCase()}`),
            }}
            onCompleteSubmit={handleCompleteSubmit}
            Template={TemplateWizard}
            withAgreement={withAgreement}
            buttons={{
                cancel: <Button onClick={handleCancel}>Cancel</Button>, previous: <Button onClick={handlePrev}>Previous</Button>
            }} />

    </>)
}
export default IsThereDocumentsConvertibleStep

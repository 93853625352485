import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch } from "react-redux";
import useNavigation from 'src/modules/generic/hooks/useNavigation';
import { Constants } from 'src/v1/utils';
import useBusinessRules from 'src/modules/clara/hooks/useBusinessRules';
import Button from "../../../../../generic/components/Buttons/Button";
import useCustomMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { addAssistantText, removeAssistantText, clearAssistatText } from 'src/modules/generic/store/action';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import useSession from 'src/modules/session/hooks/useSession';
import GenerateOrScaleUp from '../../../stakeholder/convertible/modals/AddConvertibleModal/forms/GenerateOrScaleUp';
import _ from 'lodash';

const GenerateDocumentStep: FC<{}> = () => {
  const dispatch = useDispatch()
  const { next, send, context } = useCustomMachine()
  const { translate } = useTranslate()
  const { goTo } = useNavigation()
  const { hasSubscriptionAccessGenerateDocuments } = useBusinessRules()
  const canGenerateDocuments = hasSubscriptionAccessGenerateDocuments();
  const { startup } = useSession();

  const suscriptionPlan = useMemo(() => _.get(startup, "activeSubscriptionPlan"), [JSON.stringify(startup)]);
  const handleCompleteSubmit = (values) => {
    next(values)
  };

  useEffect(() => {
    if (context.type === 'CONVERTIBLE') {
      if (canGenerateDocuments) {
        dispatch(removeAssistantText());
        dispatch(addAssistantText(translate('MODULES.EQUITY.STEPS_CONVERTIBLE.GENERATE_DOCUMENTS.TOOLTIP_CAN_GENERATE')));
        return
      }
      dispatch(removeAssistantText());
      dispatch(addAssistantText(translate('MODULES.EQUITY.STEPS_CONVERTIBLE.GENERATE_DOCUMENTS.TOOLTIP_CANT_GENERATE')))
    }
    return () => {
      dispatch(clearAssistatText());
    }
  }, [canGenerateDocuments]);

  React.useEffect(() => {
    if (suscriptionPlan.code !== "START_SUBSCRIPTION" && context.type === 'OPTION') {
      goTo(Constants.PAGES.chooseDocumentType, { previewDocument: 'GRANT_AGREEMENT' });
    }
  }, []);

  const handleCancel = () => {
    send("CANCEL")
  }

  const handlePrev = () => {
    send("PREVIOUS")
  }
  
  return (<>
    <GenerateOrScaleUp
      onCompleteSubmit={handleCompleteSubmit}
      type={context.type}
      buttons={{ cancel: <Button onClick={handleCancel}>Cancel</Button>, previous: <Button onClick={handlePrev}>Previous</Button> }}
      propsTemplate={{
        title: context.type === "OPTION"  ? translate('EQUITY_SHARE_OPTION_TASK_TITLE') : translate('MODULES.EQUITY.STEPS_CONVERTIBLE.GENERATE_DOCUMENTS.TITLE'),
        subTitle: context.type === "OPTION"  ? translate('ADD_SHARES_OPTION') : translate('MODULES.EQUITY.STEPS_CONVERTIBLE.GENERATE_DOCUMENTS.SUBTITLE')
      }}
      Template={TemplateWizard}
    />
  </>)
}
export default GenerateDocumentStep
import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import * as Yup from 'yup';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import classes from '../classes.module.scss';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import Typography from 'src/modules/generic/components/Texts/Typography';
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";

/**
* Description Step: Form for ADGM tech startup licence from here you can check your eligibility
* 
*/

function TechStartupEligibilityFormStep() {

  const { next, context, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.TechStartupEligibilityFormStep ?? {}
  }, [context]);

  const handleCancel = (values) => {
    cancel();
  }

  const handlePrev = (values) => {
    prev();
  }

  const handleSubmit = async (values) => {
    next(values)
  }

  useEffect(() => {
    dispatch(addAssistantText('ADGM introduced the Tech Startup Licence as a low cost operating Licence to encourage entrepreneurship in the region but not all startups are eligible for this Licence.<br/><br/>Learn more about the ADGM Tech Startup Licence <a rel=\'noopener noreferrer\' target=\'_blank\' href=\'https://help.clara.co/en/articles/6230979-what-is-an-adgm-tech-startup\' >here</a>.'))
    dispatch(addAssistantText('The lease should only be signed once you have submitted your application to us for review and we advise you to proceed with the lease signing.', 'alert'))
    return () => {
      dispatch(clearAssistatText())
    }
  }, [dispatch])

  const options = [
    {
      value: 'YES',
      component: ButtonText,
      componentProps: {
        title: "Yes, I qualify",
      },
    },
    {
      value: 'NO',
      component: ButtonText,
      componentProps: {
        title: "No, I don’t qualify",
      },
    },
    {
      value: 'DONT_KNOW',
      component: ButtonText,
      componentProps: {
        title: "I don’t know",
      },
    },
  ];

  const schema = Yup.object().shape({
    checkEligibility: Yup.string().nullable().required("Required"),
  })

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: 'Form a Tech Startup Licence',
          skeleton: null,
          subTitle: 'ADGM eligibility',
          variant: 'simple'
        }}
        buttons={{
          previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('BUTTON_NEXT')}</SubmitButton>,
        }}
      >
        <div className={classes.tsefsContainer} >
          <Typography weight='regular' variant='xbody'>
            To form your company, you will first need to check your eligibility.
          </Typography>
          <div className={classes.tsefsColumns} >
            <div>
              <Typography weight='bold' variant='xbody'>
                You are eligible if your startup:
              </Typography>
              <Typography weight='light' variant='body'>
                <ul>
                  <li>Is an early stage technology-driven startup.</li>
                  <li>Is planning to operate in the UAE.</li>
                  <li>Has an innovative business concept that has the potential to scale and promotes innovation that can be deployed in the UAE.</li>
                </ul>
              </Typography>
            </div>
            <div>
              <Typography weight='bold' variant='xbody'>
                You are not eligible if your startup:<br />
              </Typography>
              <Typography weight='light' variant='body'>
                <ul>
                  <li>Is an operating entity only using technology to offer a service or product.</li>
                  <li>Has existed for more than 5 years or has over 50 employees.</li>
                  <li>Provides a financially regulated service or product.</li>
                </ul>
              </Typography>
            </div>
          </div>
          <div className={classes.tsefsbuttons}>
            <ControllerInput
              as={CheckboxButtonSimple}
              className={classes.tsefsCheckboxButton}
              classNameButton={classes.tsefsButtonCheckBoxSize}
              name="checkEligibility"
              options={options}
            />
          </div>
        </div>
        <div />
      </TemplateWizard>
    </Form>
  );
}

export default TechStartupEligibilityFormStep;

const ButtonText = ({ title, text }) => {
  return (
    <Typography weight="bold" variant="body" className="mb-2">
      {title}
    </Typography>
  );
};

import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../../generic/components/Buttons/Button';
import { StepProps } from "../../../../generic/components/Wizard/Step";
import TemplateWizard from '../../../../generic/templates/Modal/TemplateWizard';
import useTranslate from '../../../../generic/hooks/useTranslate';
import { addAssistantText, clearAssistatText } from "../../../../generic/store/action";
import FormUseDefaultShareCapital from "../../Forms/FormUseDefaultShareCapital";
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useJurisdictionNameByJurisdictionType from '../hooks/useGetJurisdictionNameByJurisdictionType';

const IsDefaultValuesStep: FC<StepProps> = () => {
  const { translate } = useTranslate();
  const { next, send, prev, context } = useMachine();
  const dispatch = useDispatch();
  const { jurisdictionType } = context?.initialData;

  const jurisdictionName = useJurisdictionNameByJurisdictionType(jurisdictionType);
  const shares = context?.defaultShares;
  const parValue = context?.defaultParValue;
  const currency = context?.currency;

  useEffect(() => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText(translate('MODULES.GROUP_COMPANY.SHARE_CAPITAL_ADGM_CAYMAN.AUTHORISE_SHARES_STEP.TOOLTIP', { jurisdictionName, currency })));
  }, []);

  const handleCompleteSubmit = async (values) => {
    next(values);
  }

  const handleCancel = () => {
    send("CANCEL");
  }

  const handlePrev = () => {
    prev();
  }

  return (
    <>
      <FormUseDefaultShareCapital
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        shares={shares}
        parValue={parValue}
        currency={currency}
        propsTemplate={{
          title: translate('MODULES.GROUP_COMPANY.SHARE_CAPITAL_ADGM_CAYMAN.AUTHORISE_SHARES_STEP.TITLE'),
          subTitle: translate('MODULES.GROUP_COMPANY.SHARE_CAPITAL_ADGM_CAYMAN.GENERIC.SUBTITLE')
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button>,
          previous: <Button onClick={handlePrev} variant="secondary">Previous</Button>
        }}
      />
    </>
  );
}

export default IsDefaultValuesStep;

import { createMachine } from 'xstate';

const machine =
  createMachine({
    /** @xstate-layout N4IgpgJg5mDOIC5QAUwCdYHsB2BDANgJLYBmmAsphAQHQCW2dALnQQCK5O4DEEOY9bADdMAawENmrfBy4BtAAwBdRKAAOmWFJyqQAD0QBmAEwAOGgEZTAdgCsC4xYAst6wDZrxgDQgAnoicATgUaQ1NjQMNrJ0MLQ0NbFwBfJJ9UDBwCYjJKanwaAEEICAAhXC0AY2zMbgA5AFEADQAVRRUkEA0tFh0OgwR4wJoFWOM3C0DjBUnTQLcffwQI8yd3N1tDaeC3BQU3FLT0LDwiUgoqWiLS8roqs+4AYQLah-qAGTbdLu1sXX7TXY0Yz2BQTWz2EwJBYBCY0dZuQxuNzhWwWEbuA4gdLHLJnXKXYqUNBgNhgLh0fCwbjIABK9QAap8Ot8er8+kYzJYbPZHC53J5oQNAk4aE4ItZArYxqjAtY7JjsZlTjkLvkrkSSWTcBSqQ0Wkz1Jofn8OeYrHYHM5XB5vH5EFNjDQpQk0ZEIsZjIiFUcldV8WrCZhiaTyZTHs9Xh9lF8jayTQNOeaeVb+bbFqYLE7diMwuCYuFDN6Mic-aqaABlXBCMDlphgNS8fiCETiCtVmt1tQGzqxui9UD-QHA3ZgiGe2yCkwhIKBQIAybrOKmIs45XnPJt6u1+vcdBoIM0NT4ThkNAAW03Hfr3ZZfbZA8QAJCw9BkrHULtSwsjpBeycpiCUwNilFJUhAbAqDgXRFRLPFVRjbo73jABaeZP1QldfTgjdJBYdhOFwBDjXZBAxUFf9zB2SUTFMeItiiTDYJVDcrjKSpqiIuMSNsdZRQcBIdlsWjbECCxyKmSweKcBwhOsWY9kY3FmIJCANRDbVKU4pCSLiR0aO-T05UMSUEUFYUnRBVYbCcFw9mXMCYKU9daErLdOy0-t9EQDZzDmGwdiCBQnAsOxBQdIEQXiKJTB2NFrEUtd-RoCpcGwCowHwDz7y8hA-KBQwYllUxiqsRFBSsNwaAtOL3BlOVbAS0sNxISRYAACyy+M8s9Qq5JK2i0MWCIRQtfiAKsVxAlApIgA */
    id: 'PersonalInfoModal',
    initial: 'initialData',
    states: {
      initialData: {
        invoke: {
          src: 'initialData',
          id: 'initialData',
          onDone: [
            {
              target: 'AddBasicInfo',
              actions: ['setContextAfterInvoke']
            }
          ],
        }
      },
      AddBasicInfo: {
        on: {
          NEXT: {
            target: 'AddMoreDetails',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          },
        }
      },
      AddMoreDetails: {
        on: {
          PREV: {
            target: 'AddBasicInfo'
          },
          NEXT: {
            target: 'SaveStep',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          },
        }
      },
      SaveStep: {
        invoke: {
          src: 'SaveStep',
          id: 'SaveStep',
          onDone: [
            {
              target: 'finish',
            }
          ],
          onError: [
            {
              target: 'cancel'
            }
          ]
        }
      },
      cancel: {
        entry: 'cancelMachine',
        type: 'final'
      },
      finish: {
        entry: 'stopMachine',
        type: 'final'
      }
    }
  });

export default machine;


const TEXTS = {
    TITLE_COMPANY: 'Is there a company Shareholder Register listing this share position?',
    TITLE: 'Select companies',
    TITLE_SHARE: 'Select the companies in which the have a share position',
    TITLE_CONVERTIBLE: 'Select the companies in which the have a convertible',
    TITLE_OPTION: 'Select the companies in which the have an option grant',
    TITLE_WARRANT: 'Select the companies in which the have a warrant',
    SUBTITLE_SHARE: 'Equity positions - Add share position',
    SUBTITLE_CONVERTIBLE: 'Equity positions - Add convertible',
    SUBTITLE_OPTION: 'Equity positions - Add option grant',
    SUBTITLE_WARRANT: 'Equity positions - Add warrant',
}
export default TEXTS;
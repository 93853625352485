import {TypePolicy} from "@apollo/client";

const PossibleSharingProfile:TypePolicy={
    keyFields:['email']
}
const SubscriptionFeatureUsage:TypePolicy={
    keyFields:['code']
}
const SubscriptionFeature:TypePolicy={
    keyFields:['code'],
}
const Invoice:TypePolicy={
    keyFields:['file']
}
const Task: TypePolicy = {
    keyFields: ["code", "additionalParams"]
}
const TaskEntityData: TypePolicy = {
    keyFields: ["entityId","entityType"]
}
export {
    Invoice,
    SubscriptionFeature,
    SubscriptionFeatureUsage,
    PossibleSharingProfile,
    Task,
    TaskEntityData
}
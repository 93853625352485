import React, { useEffect } from 'react';
import * as Yup from 'yup';
import Form from '../../../../../../../components/forms/Form/Form';
import TextInput from "../../../../../../../components/inputs/Text/TextInput"
import ControllerInput from "../../../../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput"
import useMutation from '../../../../../../../hooks/custom/useMutation'
import SubmitButtonSubForm from '../../../../../../../components/generic/Buttons/SubmitButtonSubForm/SubmitButtonSubForm';
import classes from './Style.module.scss';

const UPSERT_POSITION = `
mutation upsertComponentPosition(
    $startupId: ID!
    $position: ComponentPositionInputType!
  ) {
    upsertComponentPosition(startupId: $startupId, position: $position) {
		id
		code
		name
		isDefault
		responsibilities {
			id
			name
			isDefault
			code
			text
		}
    }
  }
`;

const FormAddPosition = ({ startupId, handleCloseForm, handleCloseMessage, handleAddValueList, ...props }) => {

    const handleOnCompletedMutation = ({upsertComponentPosition}) => {
        handleCloseMessage()
        handleCloseForm()
        handleAddValueList(upsertComponentPosition)
    }
    const [upsertPosition] = useMutation(UPSERT_POSITION, { onCompleted: handleOnCompletedMutation});

    const handleSubmit =async (values) => {
        await upsertPosition({
            variables: {
                startupId,
                position: values
            }
        })
    }

    useEffect(() => {
        return () => {
            handleCloseMessage()
        }
    }, []);

    return (
        <Form initialValues={{ data: 'asdasdasd' }}
            schema={Yup.object().shape({
                name: Yup.string().required('This field is required')
            })} >

            <div className={classes.containerForm}>
                <div className={classes.conteninerText}>
                    <ControllerInput
                        as={TextInput}
                        name={'name'}
                        classNameError={classes.errorForm}
                        placeholder={"Enter Position Name..."}

                    />
                    <SubmitButtonSubForm onClick={handleSubmit}  >
                        Save
                    </SubmitButtonSubForm>
                </div>
            </div>
        </Form>
    )
};

export default FormAddPosition

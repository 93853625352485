import React from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import TwoColumnsData from "src/modules/generic/components/TwoColumnsData";

/**
 * TwoColumnsViewer
 * @param {*} param0
 * @returns
 */
function TwoColumnsViewer({ itemsCol1, itemsCol2 }) {

  return (
    <Grid container spacing={0}>
      <Grid item xs={6}>
        <TwoColumnsData items={itemsCol1} />
      </Grid>
      <Grid item xs={6}>
        <TwoColumnsData items={itemsCol2} />
      </Grid>
    </Grid>
  );
}

TwoColumnsViewer.propTypes = {
  itemsCol1: PropTypes.array,
  itemsCol2: PropTypes.array,
};

TwoColumnsViewer.defaultProps = {
  itemsCol1: [],
  itemsCol2: [],
};

export default TwoColumnsViewer;

export default {
  FIRST_STEP: {
    TITLE: "Will the company have shares in any incorporated companies?",
    SUBTITLE: "Assets and revenue",
    TOOLTIP_1: "Choose \'Yes, it will\' if the company you’re forming will be a topco, incorporated to hold shares in other companies, known as its ‘subsidiaries’. The topco will usually own the assets used by its subsidiaries, which in tech companies tend to be intellectual property (such as domain names, source code and trademarks).",
    TOOLTIP_2: "\'Yes, it will\' is also the option to pick if the company you’re forming is going to be a corporate shareholder in another company.",
  },
  SECOND_STEP: {
    TITLE: "Which incorporated companies will it have shares in?",
    SUBTITLE: "Assets and revenue",
    TOOLTIP_2: "Select ‘Add new’ to add a company that has been incorporated but has not been added to Clara yet. You will be asked some basic details about the new company and it will be added to the map.",
    TOOLTIP_1: "You can select multiple companies from the list of companies that you have already added on Clara and also add new companies.",
  },
  THIRD_STEP: {
    TITLE: "Do you have more new companies to add?",
    SUBTITLE: "Assets and revenue",
  },
  FOURTH_STEP: {
    TITLE: "Add this company's basic info",
    SUBTITLE: "Assets and revenue",
  },
  FIFTH_STEP: {
    TITLE: "Will the company have shares in any companies not yet formed?",
    SUBTITLE: "Assets and revenue",
    TOOLTIP_1: "Choose ‘Yes, it will’ if the company you’re forming will be a topco, holding shares in any companies which have not yet been incorporated. The topco will usually own the assets used by its subsidiaries, which in tech companies tend to be intellectual property (such as domain names, source code and trademarks).",
    TOOLTIP_2: "Big plans? Choose 'Yes, it will’ if the company you’re forming is going to be a corporate shareholder in another company which has not yet been incorporated.",
  },
};

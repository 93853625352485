import useCustomMachine from "../../../../../generic/hooks/useCustomMachine";
import React, { FC, useContext, useEffect, useMemo, useRef } from "react";
import Button from "../../../../../generic/components/Buttons/Button";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import _ from "lodash";
import { useDispatch } from "react-redux";
import useUpsertRoleStakeholder from "../../../../../../hooks/services/modules/individualStakeholder/useUpsertRoleStakeholder";
import useUpsertCompanyFormation from "../../../../../../hooks/services/modules/groupCompany/useUpsertCompanyFormation";
import useSession from "../../../../../session/hooks/useSession";
import { $CODE_CAYMAN_GOAL } from "../../../company/steps/Step8A";
import ClaraConciergeIndividual from "../../../core/forms/ClaraConciergeIndividual";

type StepClaraConciergeDirectorSubmit = (values: any) => Promise<any>;
interface StepClaraConciergeDirectorProps {
  role: string;
  entity: string;
  tooltips?: object;
  onCompleteSubmit?: StepClaraConciergeDirectorSubmit;
}

const StepClaraConciergeDirector: FC<StepClaraConciergeDirectorProps> = ({
  role,
  tooltips,
  onCompleteSubmit,
}) => {
  const { translate } = useTranslate();
  const { next, send, state } = useCustomMachine();
  const dispatch = useDispatch();
  const [upsertStakeholder] = useUpsertRoleStakeholder({
    showNotificationSuccess: false,
  });
  const updateGroupCompany = useUpsertCompanyFormation();

  const { startupId } = useSession();

  const handleCancel = () => {
    send("CANCEL");
  };

  const handleCompleteSubmit = async (values: any) => {

    const { enable_clara_concierge } = values;
    const { stakeholder_individual_details } = state.context.data;
    const [company] = stakeholder_individual_details.companyFormation;

    if (company && enable_clara_concierge) {
      await upsertStakeholder(stakeholder_individual_details.variables);
      await updateGroupCompany({
        groupCompanyId: company.id,
        startupId,
        goal: $CODE_CAYMAN_GOAL,
      });
      return next(values);
    }
    send("CANCEL");
  };

  const roleLabel = useMemo(() => {
    const text = _.words(
      translate(`MODULES.ONBOARDING_STEPS.ROLES_LABEL_PLURAL.${role}`)
    );
    let label = "";
    _.forEach(text, (t) => (label = `${label} ${_.lowerFirst(t)}`));
    return label;
  }, [role]);

  return (
    <React.Fragment>
      <ClaraConciergeIndividual
        tooltips={tooltips}
        initialValues={{}}
        propsTemplate={{
          title: translate(
            "MODULES.ONBOARDINGROLES.DIRECTOR.WIZARD_FORMATION_TITLE_INDIVIDUAL"
          ),
          subTitle: translate(
            "MODULES.ONBOARDINGROLES.DIRECTOR.WIZARD_FORMATION_SUBTITLE_INDIVIDUAL"
          ),
        }}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        buttons={{ cancel: <Button onClick={handleCancel}>Cancel</Button> }}
      />
    </React.Fragment>
  );
};

export default StepClaraConciergeDirector;

import _ from "lodash";
import React, { Component, useState, useEffect } from 'react';
import { Field } from "formik";
import moment from "moment";

import classes from "../Styles.module.scss";
import ClaraSelect from "../../../../../../../v1/components/Form/ClaraSelect/ClaraSelect";
import { ClaraInputYearsMonths, ClaraRadioWithButton, TextAreaInput, TextInput, ClaraInputDate,TextType } from '../../../../../../../v1/components';
import Checkbox from "../../../../../../../v1/containers/Forms/Inputs/Checkbox";
import CheckBox from "../../../../../../../components/inputs/Checkbox/Checkbox"
import FormikHelper from "../../../../../../../v1/utils/formikHelper";
import ClaraUpload from "../../../../../../../v1/components/Form/ClaraUpload/ClaraUpload";
import ClaraCountrySelector from "../../../../../../../v1/components/Form/ClaraCountrySelector/ClaraCountrySelector";
import AuthorisedSignatories from "../../../../../../inputs/AuthorizedSignatories/AuthorizedSignatories";
import Line from "../../../../../../generic/Line/Line";
import {TooltipInput} from "../../../../../../../v1/components";
import {
	groupCompanyRecipientNotice, stakeholderAuthorizedSignatory, stakeholderRecipientNotice
} from '../../../../../../../v1/containers/Forms/generateDocumentForm/FormsGenerate/helper';

class NDAMutualDoc extends Component {

    constructor(props) {
        super(props);
        this.state = {
            typeSelected: undefined,
            cosultantSelected: undefined,
            groupCompanySelected: undefined,
            authorizedSelected: undefined,
            authorizedSignatories: [],
            authorizedSignatoriesList: [],
            documentTypes: [],
            documentTypesList: [],
            dynamicParams: [],
            date: undefined,
        }
    };

    componentWillMount() {
        if (this.props.values) {
            const values = this.props.values.values ? this.props.values.values : this.props.values;
            this.setState({
                typeSelected: values.documentType,
                stakeholderSelected: values.stakeholder,
                groupCompanySelected: values.company,
                authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.company).map(as => ({
                    id: as.id,
                    label: as.label
                }))
            });
        }

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.values) {
            const values = nextProps.values.values ? nextProps.values.values : nextProps.values;
            this.setState({
                authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.company)
                    .map(as => ({
                        id: as.id,
                        label: as.label
                    }))
            });
        }
    }

    selectType = (documentType, extraParams, form) => {
        this.setState({
            typeSelected: documentType,
            dynamicParams: this.getDynamicParamsByType(documentType)
        }, () => {
            FormikHelper.setValueInTheCorrectPosition('terms', form, []);
            FormikHelper.setValueInTheCorrectPosition('documentType', form, documentType);
            this.state.dynamicParams.forEach((dynamicParam, i) => {
                FormikHelper.setValueInTheCorrectPosition(
                    `terms[${i}].key`,
                    form,
                    dynamicParam.key || 'NO_KEY'
                );
            })
        });
    };
    fieldHasError(form,field) {
        return FormikHelper.returnErrorsForValidation(field.name, form.errors);
    }
    getDynamicParamsByType(documentType) {
        const type = this.props.documentTypes.filter(agrType => agrType.code === documentType)[0] || {};
        return type.fields || [];
    }

    getGroupCompanies = () => {
        let authorizedSignatories = this.props.authorizedSignatories;
        let groupCompanies = this.props.groupCompanies;
        let companies = [];
        if (groupCompanies && authorizedSignatories) {
            companies = _.filter(groupCompanies, (groupCompany) => {
                let fined = _.filter(authorizedSignatories, (authorized) => {
                    if (groupCompany.authorizedSignatories) {
                        return groupCompany.authorizedSignatories.some(au => au.stakeholder.id === authorized.id);
                    }
                    return false;
                });
                if (fined) {
                    return true;
                }
                return false;
            });
        }
        return companies;
    };

    selectGroupCompany = (gcId, extraParams, form) => {
        const groupCompany = this.findGroupCompany(gcId);

        // this is for a weird behaviour when hit edit button after preview a document
        if (this.state.groupCompanySelected && groupCompany) {
            return null;
        }

        this.setState({
            groupCompanySelected: groupCompany
        });

        if (groupCompany) {

            if (!groupCompany.registeredAddress) {
                groupCompany.registeredAddress = {}
            }
            let authorizedSignatories = _.get(form, 'values.company.authorizedSignatories', []);
            
            const newFormValues = {
                ...form.values,
                company: {
                    id: gcId,
                    name: groupCompany.name || '',
                    country: _.get(groupCompany, 'country.code', null) || _.get(groupCompany, 'country', null),
                    jurisdiction: _.get(groupCompany, 'jurisdiction'),
                    registeredNumber: groupCompany.registeredNumber || '',
                    type: groupCompany.type || '',
                    registeredAddress: {
                        ...groupCompany.registeredAddress, country: _.get(groupCompany, 'registeredAddress.country.code', null) || _.get(groupCompany, 'registeredAddress.country', null)
                    },
                    authorizedSignatories: authorizedSignatories,
					recipientNotice: groupCompanyRecipientNotice(form.values.company, groupCompany)
                }
            };

            form.setValues(newFormValues);

            this.setState({
                groupCompanySelected: groupCompany,
                // authorizedSignatoriesList: authorizedSignatories.map(as => ({
                // 	id: as.id,
                // 	label: as.label
                // }))
            })
        } else {
            form.setValues({
                ...form.values,
                company: {}
            });
        }
    };

    findGroupCompany(gcId) {
        return this.props.groupCompaniesList.find(gc => gc.id === gcId);
    };

    selectStakeholder = (stakeholderSelect, extraParams, form, cb) => {
        const stakeholder = this.findStakeholder(stakeholderSelect);

        this.setState({
            stakeholderSelected: stakeholder,
        });


        if (stakeholder) {
            let authorizedSignatory = {};

            if (!stakeholder.address) {
                _.set(stakeholder,'address', {})
            }

            const _stakeholder = !_.isEmpty(form.values.stakeholder) ? form.values.stakeholder : stakeholder;

            if (stakeholder.isAnEntity) {
				authorizedSignatory = stakeholderAuthorizedSignatory(_stakeholder);
                // authorizedSignatory = {
                //     fullName: typeof _stakeholder.authorizedSignatory === 'object'
                //         ? _.get(_stakeholder, 'authorizedSignatory.fullName', '')
                //         : _.get(_stakeholder, 'authorizedSignatory', ''),
                //     email: _stakeholder.email || ''
                // }
            }

            form.setValues({
                ...form.values,
                stakeholder: {
                    id: stakeholderSelect,
                    fullName: _stakeholder.fullName || '',
                    email: _stakeholder.email || '',
                    passportNumber: _stakeholder.passportNumber || '',
                    registeredNumber: _stakeholder.registeredNumber || '',
                    nationality: _.get(_stakeholder, 'nationality.code', null) || _.get(_stakeholder, 'nationality', null),
                    jurisdiction: _.get(_stakeholder, 'jurisdiction'),
                    otherJurisdiction: _.get(_stakeholder, 'otherJurisdiction', ''),
                    type: _stakeholder.type || '',
                    address: { ..._stakeholder.address, country: _.get(_stakeholder, 'address.country.code', null) || _.get(_stakeholder, 'address.country', null) },
                    isAnEntity: _stakeholder.isAnEntity,
                    authorizedSignatory: authorizedSignatory,
					recipientNotice: stakeholderRecipientNotice(form.values.stakeholder, stakeholder)
                }
            });
        } else {
            form.setValues({
                ...form.values,
                stakeholder: {}
            });
        }
    };


    findStakeholder(roleId) {
        return this.props.stakeholderList.find(stakeholder => stakeholder.id === roleId);
    };

    findStakeholderAuthorized(roleId) {
        return this.props.stakeholderList.filter(stakeholder => {
            return stakeholder.roles.some(role => role.stakeholder.id === roleId);
        })[0] || null;
    };

    filterAuthorizedByGroupCompany(groupCompany) {
        if (groupCompany) {
            let gc = this.findGroupCompany(groupCompany.id);
            return this.props.authorizedSignatories.filter(authorized => {
                return authorized.groupCompanyId === gc.id
            });
        }
        return [];
    }

    selectAuthorized = (value, form, field, replace) => {
        const stakeholder = this.props.authorizedSignatories.find(e => e.id === value);
        const email = stakeholder ? stakeholder.email : '';
        replace(0, { id: value, email })
    };

    renderStakeholderAuthorizedSignatory(field) {
        const { tooltips = {} } = this.props;

        if (this.state.stakeholderSelected.isAnEntity) {
            return (
                <React.Fragment>
                    <div className={classes.ClaraInputSelect}>
                        <Field name='stakeholder.authorizedSignatory.fullName'>
                            {({ field, form }) => (
                                <TextInput
                                    classNameInput={{ root: classes.ContainerRow }}
                                    required={true}
                                    type='text'
                                    label='Corporate signatory '
                                    field={field}
                                    form={form}
                                    placeholder={"Corporate signatory"}
                                    tooltip={tooltips["Authorised Signatory"]}
                                />
                            )}
                        </Field>
                    </div>
                        <Field name='stakeholder.authorizedSignatory.email'>
                            {({ field, form }) => (
                                <TextInput
                                    required={true}
                                    classNameInput={{ root: classes.ContainerRow }}
                                    type='email'
                                    label="Corporate signatory's email address "
                                    field={field}
                                    form={form}
                                    onChange={(e) => this.updateEmailValue(e, form)}
                                    placeholder={"Email address"}
                                    tooltip={tooltips['Authorised Signatory Email']}
                                />
                            )}
                        </Field>
                </React.Fragment>
            )
        }
    };

    updateEmailValue(e, form) {
        if (this.state.stakeholderSelected.isAnEntity) {
            form.setFieldValue('stakeholder.authorizedSignatory.email', e.target.value, false)
        }
    }

    getDateForPickerFromData(date) {
        if (!date) return undefined;
        return moment(date).toDate();
    }

    handleChange(e, field, form) {

        const date = e === null ? null : moment(e).format("YYYY-MM-DD");

        FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

        this.setState({
            [field.name]: e
        })
    }

    getAuthorizedSignatoriesList = (index, form) => {
        if (form.values && form.values.company) {
            return this.filterAuthorizedByGroupCompany(form.values.company).map(as => ({
                id: as.id,
                label: as.label
            }))
        } else {
            return [];
        }
    };

    getLabel = (key) => {
        switch (key) {
            case "fullName":
                if (this.state.stakeholderSelected.isAnEntity) {
                    return "Company name";
                } else {
                    return "Full legal name";
                }
            case "country":
                if (this.state.stakeholderSelected.isAnEntity) {
                    return "Country of incorporation";
                } else {
                    return "Nationality";
                }
            case "address":
                if (this.state.stakeholderSelected.isAnEntity) {
                    return "Registered address";
                } else {
                    return "Residential address";
                }
            case "passportOrRegistered":
                if (this.state.stakeholderSelected.isAnEntity) {
                    return "Company number";
                } else {
                    return "Passport number";
                }
        }
    };

    getField = (key) => {
        switch (key) {
            case "passportOrRegistered":
                if (this.state.stakeholderSelected.isAnEntity) {
                    return "stakeholder.registeredNumber";
                } else {
                    return "stakeholder.passportNumber";
                }
        }
    };

    getTooltip = (key, fieldName) => {
        switch (key) {
            case "fullName":
                if (this.state.stakeholderSelected.isAnEntity) {
                    return "Party One's Name";
                } else {
                    return "Party One's Name";
                }
            case "passportOrRegistered":
                if (this.state.stakeholderSelected.isAnEntity) {
                    return "Company Number";
                } else {
                    return "Passport Number";
                }
            case "country":
                if (this.state.stakeholderSelected.isAnEntity) {
                    return "Country";
                } else {
                    return "Nationality";
                }
            case "addressLine":
                if (this.state.stakeholderSelected.isAnEntity) {
                    return "Registered Address - Address";
                } else {
                    return "Residential Address - Address";
                }
            case "addressCity":
                if (this.state.stakeholderSelected.isAnEntity) {
                    return "Registered Address - City";
                } else {
                    return "Residential Address - City";
                }
            case "addressState":
                if (this.state.stakeholderSelected.isAnEntity) {
                    return "Registered Address - State";
                } else {
                    return "Residential Address - State";
                }
            case "addressCountry":
                if (this.state.stakeholderSelected.isAnEntity) {
                    return "Registered Address - Country";
                } else {
                    return "Residential Address - Country";
                }
            case "addressPostCode":
                if (this.state.stakeholderSelected.isAnEntity) {
                    return "Registered Address - Post Code";
                } else {
                    return "Residential Address - Post Code";
                }
            case "recipientNotice":
                if (fieldName === 'company') {
                    return "Company's";
                } else {
                    return "Party One's";
                }
        }
    };

    getPlaceholder = (key) => {
        switch (key) {
            case "passportOrRegistered":
                if (this.state.stakeholderSelected.isAnEntity) {
                    return "Company number";
                } else {
                    return "Passport number";
                }
        }
    };

    renderRecipientNotice(fieldName, isAnEntity) {
        const { tooltips = {} } = this.props;

        if (isAnEntity) {
            return (
                <React.Fragment>
                        <Field name={`${fieldName}.recipientNotice.fullName`}>
                            {({ field, form }) => (
                                <TextInput
                                    required={true}
                                    classNameInput={{ root: classes.ContainerRow }}
                                    type='text'
                                    label='Recipient for notices '
                                    field={field}
                                    form={form}
                                    placeholder={"Recipient's name"}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices"]}
                                />
                            )}
                        </Field>
                    <div className={` ${classes.Select}`}>
                        <Field name={`${fieldName}.recipientNotice.address.country`}>
                            {({ field, form }) => (
                                <ClaraCountrySelector
                                    isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                    jurisdictionsEnabled={false}
                                    countryField={`${fieldName}.recipientNotice.address.country`}
                                    label={"Recipient's address "}
                                    setFieldValue={form.setFieldValue}
                                    initialValues={{ country: _.get(form.values, `${fieldName}.recipientNotice.address.country`, null) }}
                                    returnCountryAsString={_.isEmpty(_.get(form.values, `${fieldName}.recipientNotice.address.country.code`, null))}
                                    additionalStyle={'ClaraCountrySelectorModal'}
                                    tooltips={{
                                        country: tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Country"]
                                    }}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.address.street`}>
                            {({ field, form }) => (
                                <TextAreaInput
                                    type='text'
                                    label={' '}
                                    field={field}
                                    form={form} placeholder={"Address"}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Address"]}
                                />
                            )}
                        </Field>
                    </div>
                        <Field name={`${fieldName}.recipientNotice.address.city`}>
                            {({ field, form }) => (
                                <TextInput
                                    type='text'
                                    classNameInput={{ root: classes.ContainerRow }}
                                    field={field}
                                    form={form}
                                    placeholder={"City"}
                                    label={' '}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - City"]}
                                />
                            )}
                        </Field>
                        <Field name={`${fieldName}.recipientNotice.address.state`}>
                            {({ field, form }) => (
                                <TextInput
                                    classNameInput={{ root: classes.ContainerRow }}
                                    type='text'
                                    field={field}
                                    form={form}
                                    placeholder={"State / Region"}
                                    label={' '}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - State"]}
                                />
                            )}
                        </Field>
                        <Field name={`${fieldName}.recipientNotice.address.zipCode`}>
                            {({ field, form }) => (
                                <TextInput
                                    classNameInput={{ root: classes.ContainerRow }}
                                    type='text'
                                    field={field} form={form}
                                    label={' '}
                                    placeholder={"Post code"}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Post Code"]}
                                />

                            )}
                        </Field>
                        <Field name={`${fieldName}.recipientNotice.email`}>
                            {({ field, form }) => (
                                <TextInput
                                    required={true}
                                    classNameInput={{ root: classes.ContainerRow }}
                                    type='email'
                                    label="Recipient's email address "
                                    field={field}
                                    form={form}
                                    placeholder={"Email address"}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Email Address"]}
                                />
                            )}
                        </Field>
                </React.Fragment>
            )
        }
    };

    renderAgreementDurationFields(form) {
        const { tooltips = {} } = this.props;
        if (!_.get(form, 'values.terms.agreementDuration')) {
            return null
        }

        return (
            <React.Fragment>
                {
                    form.values.terms.agreementDuration === 'otherDuration' ?
                        <div className={classes.ContainerRow}>
                            <Field name='terms.otherDuration'>
                                {({ field, form }) => (
                                    <ClaraInputYearsMonths

                                        required={true}
                                        field={field}
                                        form={form}
                                        label={'Duration '}
                                        tooltips={tooltips}

                                    />
                                )}
                            </Field>

                        </div>
                        : null
                }
            </React.Fragment>
        );

    }

    getStakeholderAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add new corporate stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add more details later on the stakeholder's profile page.",
                placeholder: "Stakeholder's name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: true,
                    // roles : [{
                    // 	role: "SHAREHOLDER",
                    // 	groupCompany: this.state.groupCompanySelected.id,
                    // }],
                },
                paramsReturn: 'isAnEntity'
            },
            {
                type: "stakeholder",
                label: "Add new individual stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add more details later on the stakeholder's profile page.",
                placeholder: "Stakeholder's name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: false,
                    // roles : [{
                    // 	role:"SHAREHOLDER",
                    // 	groupCompany: this.state.groupCompanySelected.id,
                    // }]
                },
                paramsReturn: 'isAnEntity'
            },
        ]
    };

    getCompanyAdds = () => {
        return [{
            type: "groupCompany",
            label: "Add new company",
            textWarning: "This will create a new company for your startup. You can add more details later on the company's profile page.",
            placeholder: "Company name",
            startupId: this.props.startup.id,
            params: null,
            paramsReturn: `
				name
				authorizedSignatories {
					id
					stakeholder {
						id
						email
						fullName
						roles {  id
        name
        groupCompany { id name}
        stakeholder { id } }
					}
				}
			`
        }]
    };

    getAuthorizedSignatoryAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add new individual stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add more details later from the stakeholder's profile page.",
                placeholder: "Enter stakeholder's name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: false,
                    roles: [{
                        role: "DIRECTOR",
                        groupCompany: this.state.groupCompanySelected.id,
                    }]
                }
            },
        ]
    };

    handleStakeholderCreate = (value, name, additionalValue, form) => {

        let { stakeholders, stakeholderList } = this.props;
        let newSh = { id: value.id, fullName: name, ...additionalValue };
        stakeholderList.push(newSh);

        stakeholders.push({ id: value.id, label: name });
        // this.setState({ groupCompaniesList, groupCompanies });

    };

    handleGroupCompanyCreate = (value, name, additionalValue, form) => {

        let { groupCompanies, groupCompaniesList } = this.props;
        let newGc = { id: value.id, name, ...additionalValue };
        groupCompaniesList.push(newGc);

        groupCompanies.push({ id: value.id, label: name });
        // this.setState({ groupCompaniesList, groupCompanies });

    };

    handleAuthorizedSignatoryCreate = (value, name, additionalValue, form) => {

        let { authorizedSignatories } = this.props;
        const { groupCompanySelected } = this.state;

        authorizedSignatories.push({
            id: value.id,
            label: name,
            role: 'DIRECTOR',
            groupCompanyId: groupCompanySelected.id
        });

    };

    render() {
        const isRequired = message => value => (!!value ? undefined : message);
        const { tooltips = {}, formType } = this.props;

        return (
            <div>
                    {formType == 'uploadToEdit' ? (<div>
                        <Field name={`file`}>
                            {({ field, form }) => (
                                <ClaraUpload
                                    required={true}
                                    name={`filepond`}
                                    label='Upload your document: '
                                    field={field}
                                    form={form}
                                />
                            )}
                        </Field>
                    </div>) : null}
                    <Field name='agreementName'>
                        {({ field, form }) => (
                            <TextInput
                                classNameInput={{ root: classes.ContainerRow }}
                                required={true}
                                type='text'
                                label='Document name '
                                field={field}
                                form={form}
                                placeholder={"Document name"}
                                tooltip={tooltips['Document Name']}
                            />
                        )}
                    </Field>

                <Line className={classes.line} />
                <div className={classes.ZoneTitle}>
                    <h1>{'Party One'}</h1>
                </div>
                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                    <Field name='stakeholder.id'>
                        {({ field, form }) => (
                            <ClaraSelect
                                required={true}
                                field={field}
                                form={form}
                                label={"Party one "}
                                lists={this.props.stakeholders}
                                adds={this.getStakeholderAdds()}
                                mode={"classic"}
                                callbacks={{
                                    updateValues: this.selectStakeholder,
                                    createValues: this.handleStakeholderCreate
                                }}
                                placeholder={"Select from the list"}
                                tooltip={tooltips["Select Party One"]}
                            />

                        )}
                    </Field>
                </div>
                {
                    this.state.stakeholderSelected ?
                        <React.Fragment>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                {
                                    this.state.stakeholderSelected.isAnEntity ?
                                        <>
                                            <h1>Party One Details</h1>
                                            <p>Please verify or update Party One’s information below</p>
                                        </>
                                        :
                                        <>
                                            <h1>Party One Details</h1>
                                            <p>Please verify or update Party One’s information below</p>
                                        </>
                                }
                            </div>
                                <Field name='stakeholder.fullName'>
                                    {({ field, form }) => (
                                        <TextInput
                                            classNameInput={{ root: classes.ContainerRow }}
                                            type='text'
                                            label={this.getLabel("fullName") + ' '}
                                            field={field}
                                            form={form}
                                            placeholder={'Name'}
                                            required={true}
                                            tooltip={tooltips[this.getTooltip('fullName')]}
                                        />
                                    )}
                                </Field>
                            <div className={`${classes.Select}`}>
                                <Field name='stakeholder.nationality'>
                                    {({ field, form }) => {
                                        return (
                                            <ClaraCountrySelector
                                                isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                                jurisdictionsEnabled={this.state.stakeholderSelected.isAnEntity}
                                                countryField={'stakeholder.nationality'}
                                                label={this.getLabel('country') + ''}
                                                jurisdictionField={'stakeholder.jurisdiction'}
                                                jurisdictionLabel={'Jurisdiction '}
                                                returnCountryAsString={true}
                                                otherJurisdictionField={'stakeholder.otherJurisdiction'}
                                                setFieldValue={form.setFieldValue}
                                                initialValues={{ jurisdiction: _.get(form.values, 'stakeholder.jurisdiction') }}
                                                additionalStyle={'ClaraCountrySelectorModal'}
                                                tooltips={{
                                                    country: tooltips[this.getTooltip('country')],
                                                    jurisdiction: tooltips['Jurisdiction'],
                                                    other: tooltips['Other Jurisdiction']
                                                }}
                                            />
                                        )
                                    }
                                    }
                                </Field>
                            </div>
                            {
                                this.state.stakeholderSelected.isAnEntity ?
                                    <React.Fragment>
                                            <Field name='stakeholder.type'>
                                                {({ field, form }) => (
                                                    <TextInput
                                                        classNameInput={{ root: classes.ContainerRow }}
                                                        required={true}
                                                        type='text'
                                                        label='Company type '
                                                        field={field}
                                                        form={form}
                                                        placeholder={'Company type'}
                                                        tooltip={tooltips['Company Type']}
                                                    />
                                                )}
                                            </Field>
                                    </React.Fragment>
                                    :
                                    null
                            }
                                <Field name={this.getField("passportOrRegistered")}>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            classNameInput={{ root: classes.ContainerRow }}
                                            type='text'
                                            label={this.getLabel("passportOrRegistered") + ' '}
                                            field={field}
                                            form={form}
                                            placeholder={this.getPlaceholder("passportOrRegistered")}
                                            tooltip={tooltips[this.getTooltip("passportOrRegistered")]}
                                        />
                                    )}
                                </Field>
                            {
                                !this.state.stakeholderSelected.isAnEntity ?
                                    <React.Fragment>
                                            <Field name='stakeholder.email'>
                                                {({ field, form }) => (
                                                    <TextInput
                                                        type='email'
                                                        classNameInput={{ root: classes.ContainerRow }}
                                                        label='Email address '
                                                        field={field}
                                                        form={form}
                                                        placeholder={'Email address'}
                                                        required={true}
                                                        tooltip={tooltips["Party One's Email Address"]}
                                                    />
                                                )}
                                            </Field>
                                    </React.Fragment>
                                    :
                                    null
                            }
                            <div className={`${classes.Select}`}>
                                <Field name='stakeholder.address.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={false}
                                            countryField={'stakeholder.address.country'}
                                            label={this.getLabel('address') + ''}
                                            setFieldValue={form.setFieldValue}
                                            returnCountryAsString={true}
                                            additionalStyle={'ClaraCountrySelectorModal'}
                                            tooltips={{
                                                country: tooltips[this.getTooltip('addressCountry')]
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='stakeholder.address.street'>
                                    {({ field, form }) => (
                                        <TextAreaInput
                                            type='text'
                                            label={' '}
                                            field={field}
                                            form={form}
                                            placeholder={'Address'}
                                            tooltip={tooltips[this.getTooltip('addressLine')]}

                                        />
                                    )}
                                </Field>
                            </div>
                                <Field name='stakeholder.address.city'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            classNameInput={{ root: classes.ContainerRow }}
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={'City'}
                                            tooltip={tooltips[this.getTooltip('addressCity')]}
                                        />
                                    )}
                                </Field>
                                <Field name='stakeholder.address.state'>
                                    {({ field, form }) => (
                                        <TextInput
                                            classNameInput={{ root: classes.ContainerRow }}
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={'State / Region'}
                                            tooltip={tooltips[this.getTooltip('addressState')]}
                                        />
                                    )}
                                </Field>
                                <Field name='stakeholder.address.zipCode'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            classNameInput={{ root: classes.ContainerRow }}
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={"Post code"}
                                            tooltip={tooltips[this.getTooltip('addressPostCode')]}
                                        />
                                    )}
                                </Field>
                            {this.renderRecipientNotice('stakeholder', this.state.stakeholderSelected.isAnEntity)}
                            {this.renderStakeholderAuthorizedSignatory('stakeholder')}
                        </React.Fragment>
                        :
                        null
                }
                <Line className={classes.line} />
                <div className={classes.ZoneTitle}>
                    <h1>{'Party Two'}</h1>
                </div>
                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                    <Field name='company.id'>
                        {({ field, form }) => (
                            <ClaraSelect
                                required={true}

                                field={field}
                                form={form}
                                label={"Party two "}
                                lists={this.getGroupCompanies()}
                                mode={"classic"}
                                callbacks={{
                                    createValues: this.handleGroupCompanyCreate,
                                    updateValues: this.selectGroupCompany
                                }}
                                placeholder={"Select from the list"}
                                tooltip={tooltips['Select Company']}
                            />
                        )}
                    </Field>
                </div>
                {
                    this.state.groupCompanySelected ?
                        <React.Fragment>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Party Two Details</h1>
                                <p>Please verify or update Party Two's information below</p>
                            </div>
                                <Field name='company.name'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            classNameInput={{ root: classes.ContainerRow }}
                                            label='Company name '
                                            field={field}
                                            form={form}
                                            placeholder={"Company name"}
                                            tooltip={tooltips["Company's Name"]}
                                        />
                                    )}
                                </Field>
                            <div className={` ${classes.Select}`}>
                                <Field name='company.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={true}
                                            countryField={'company.country'}
                                            label={'Country of incorporation '}
                                            jurisdictionField={'company.jurisdiction'}
                                            jurisdictionLabel={'Jurisdiction '}
                                            returnCountryAsString={true}
                                            otherJurisdictionField={'company.otherJurisdiction'}
                                            setFieldValue={form.setFieldValue}
                                            initialValues={{
                                                jurisdiction: _.get(form.values, 'company.jurisdiction')
                                            }}
                                            additionalStyle={'ClaraCountrySelectorModal'}
                                            tooltips={{
                                                country: tooltips["Company's Country"],
                                                jurisdiction: tooltips["Company's Jurisdiction"],
                                                other: tooltips['Other Jurisdiction']
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            {/*
                            <div className={classes.ContainerRow}>
                                <Field name='company.jurisdiction'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label='Jurisdiction: '
                                            field={field}
                                            form={form}
                                            placeholder={"Jurisdiction"}
                                            tooltip={tooltips["Company's Jurisdiction"]}
                                        />
                                    )}
                                </Field>
                            </div>
							*/}
                                <Field name='company.type'>
                                    {({ field, form }) => (
                                        <TextType
                                            required={true}
                                            type='text'
                                            groupCompany={this.state.groupCompanySelected}
                                            classNameInput={{ root: classes.ContainerRow }}
                                            label='Company type '
                                            field={field}
                                            form={form}
                                            placeholder={'Company type'}
                                            tooltip={tooltips["Company's Company Type"]}
                                        />
                                    )}
                                </Field>
                                <Field name='company.registeredNumber'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            classNameInput={{ root: classes.ContainerRow }}
                                            label='Company number '
                                            field={field}
                                            form={form}
                                            placeholder={'Company number'}
                                            tooltip={tooltips["Company's Company Number"]}
                                        />
                                    )}
                                </Field>
                            <div className={` ${classes.Select}`}>
                                <Field name='company.registeredAddress.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            jurisdictionsEnabled={false}
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            countryField={'company.registeredAddress.country'}
                                            label={'Registered address '}
                                            returnCountryAsString={true}
                                            setFieldValue={form.setFieldValue}
                                            additionalStyle={'ClaraCountrySelectorModal'}
                                            tooltips={{
                                                country: tooltips["Company's Registered Address - Country"]
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.registeredAddress.street'>
                                    {({ field, form }) => (
                                        <TextAreaInput
                                            type='text'
                                            label={' '}
                                            field={field}
                                            form={form}
                                            placeholder={"Address"}
                                            tooltip={tooltips["Company's Registered Address - Address"]}
                                        />
                                    )}
                                </Field>
                            </div>
                                <Field name='company.registeredAddress.city'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            classNameInput={{ root: classes.ContainerRow }}
                                            field={field}
                                            form={form}
                                            placeholder={'City'}
                                            tooltip={tooltips["Company's Registered Address - City"]}
                                        />
                                    )}
                                </Field>
                                <Field name='company.registeredAddress.state'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            classNameInput={{ root: classes.ContainerRow }}
                                            form={form}
                                            placeholder={'State / Region'}
                                            tooltip={tooltips["Company's Registered Address - State"]}
                                        />
                                    )}
                                </Field>
                                <Field name='company.registeredAddress.zipCode'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            classNameInput={{ root: classes.ContainerRow }}
                                            field={field}
                                            form={form}
                                            placeholder={"Post code"}
                                            tooltip={tooltips["Company's Registered Address - Post Code"]}
                                        />
                                    )}
                                </Field>
                            {this.renderRecipientNotice('company', true)}
                            <Line className={classes.line}/>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Corporate Signatories</h1>
                                <p>Please add all the signatories required to sign this document on behalf of the company.</p>
                            </div>
                            <Field name='company.authorizedSignatories_error'>
                                {({ field, form }) => (
                                    <React.Fragment>
                                        <span hasError={_.get(form.errors, "company.authorizedSignatories") ? "true" : "false"}>
                                            <AuthorisedSignatories
                                            list={this.props.stakeholderList}
                                            fieldArrayName={'company.authorizedSignatories'}
                                                idGroupCompany={this.state.groupCompanySelected.id}
                                                errorMessage={_.get(form.errors, "company.authorizedSignatories")}
                                            />
                                        </span>
                                    </React.Fragment>
                                )}
                            </Field>
                            <Line className={classes.line}/>
                        </React.Fragment>
                        :
                        null
                }

                {
                    this.state.stakeholderSelected && this.state.groupCompanySelected ?
                        <React.Fragment>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Transaction Details</h1>
                                <p>Details of the transaction in which the confidential information is being shared.</p>
                            </div>
                            <Field name='terms.transaction'>
                                {({ field, form }) => (<>
                                    <div className={`${classes.labelGroup}`}>
                                        <label>Transaction details <span style={{ color: 'red' }}>*</span></label>
                                        <TooltipInput label={tooltips['Transaction']} classes={{tooltip:classes.chechboxFix}}>
                                        <CheckBox
                                            errorMessage={this.fieldHasError(form, field)}
                                            isMultiple={false}
                                            onChange={(value) => { form.setFieldValue(field.name, value); form.validateField(field.name) }}
                                            options={[{
                                                label: 'Investment in one party by the other',
                                                value: 'option_a',
                                            },
                                                {
                                                    label: 'Acquisition of one party by the other',
                                                    value: 'option_b',
                                                },
                                                {
                                                    label: 'Joint venture between the parties',
                                                    value: 'option_c',
                                                },
                                                {
                                                    label: 'Other',
                                                    value: 'option_z',
                                                    renderElement: () => (
                                                        <Field name='terms.otherTransaction'>
                                                            {({ field, form }) => (
                                                                <div className={classes.InputContainer}>
                                                                <TextAreaInput
                                                                    type='text'
                                                                    label=' '
                                                                    field={field}
                                                                    form={form}
                                                                    tooltip={tooltips['Other Transaction']}
                                                                    placeholder={'Describe the details of the transaction here'}
                                                                />
                                                                </div>)}
                                                        </Field>
                                                    )
                                                }
                                            ]}
                                            values={field.value}
                                            className={{ root: classes.groupCheckBoxWizard, checkbox: classes.checkBoxWizard }}
                                        /></TooltipInput>
                                    </div>
                                </>)}
                            </Field>
                            <Line className={classes.line}/>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Duration</h1>
                                <p>{"The duration of the confidentiality obligations (even after termination)."}</p>
                            </div>
                            <Field name='terms.duration'>
                                {({ field, form }) => (
                                    <ClaraInputDate
                                        start={{ month: 0, year: 0 }}
                                        stop={{ month: 11, year: 1 }}
                                        required={true}
                                        field={field}
                                        isModal={true}
                                        form={form}
                                        label={'Duration '}
                                        tooltip={{ text: tooltips["Duration"]}}
                                        errorName={"agreementDuration"}
                                    />
                                )}
                            </Field>

                            <Line className={classes.line}/>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Place of Arbitration</h1>
                                <p>This contract’s dispute resolution mechanism uses arbitration under expedited ICC rules. Please select the place where you would like the arbitration to take place (please enter the name of the city and country and, if applicable, state or freezone), e.g. "London, United Kingdom" or "New York City, New York, United States of America” or "Dubai International Financial Centre, Dubai, United Arab Emirates”. Please note that the place of arbitration that you select can have a material impact on the proceedings and enforcement of the arbitration and arbitral award. Please seek legal advice.</p>
</div>
<Field name={`terms.placeOfArbitration`}>
    {({ field, form }) => (
        <TextInput
            required={true}
            type='text'
            label='Place of arbitration '
            field={field}
            classNameInput={{ root: classes.ContainerRow }}

            form={form}
            placeholder={"Place of arbitration"}
        />
    )}
</Field>
                            
                        </React.Fragment>
                        : null
                }

                {formType === 'uploadToEdit' ? <div>
                    <Field name='confirmSameData'>
                        {({ field, form }) => (
                            <Checkbox
                                className={classes.sameDataCheckbox}
                                field={field}
                                validate={isRequired('This field is required')}
                                form={form}
                                required={true}
                                label='All document details are verified and correct.'
                            />
                        )}
                    </Field>
                </div> : null}

            </div>

        );
    }

}


export default NDAMutualDoc;

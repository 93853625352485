import React from 'react';
import PAGES from "../../../../constants/pages";
import ClaraLayout from "../../../../layouts/Clara/ClaraLayout";
import MyTasksView from './views';
//TODO: borrar el dashboard viejo
const moduleRouter = [
  
  {
    path: PAGES.myTasks.path,
    exact: false,
    component: <MyTasksView />,
    layout: ClaraLayout,
    propsLayout: {
      cantBack: true,
      startupMenu: true,
      userMenu: true,
      fullMenu: true,
      showBreadCrumbs: false,
      linkMenu: "LinkMyTasks",
    },
  },
];
export default moduleRouter;

const templates = {

  MESSAGE_HEADER_FOT_CREATED: ` 
<label> 
{{#if customId}}
{{userName}} added a {{entityEvent}} to {{customId}} for {{companyName}} 
{{else}}
{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.
{{/if}}
</label>
`,
  MESSAGE_SUB_HEADER_FOT_CREATED: `

  <label> 
{{#if customId}}
{{userName}} added a {{entityEvent}} to {{customId}} for {{companyName}} 
{{else}}
{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.
{{/if}}
</label>
`,
MESSAGE_TEMPLATE_FOT_CREATED: `
<label> 
{{#if customId}}
{{userName}} added a {{entityEvent}} to {{customId}} for {{companyName}} 
{{else}}
{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.
{{/if}}
</label>

<ul>
{{#if customId}} 
<li> A {{entityEvent}} to {{ customId }} has been {{entityAction}}  for {{ companyName }} by {{userName}} at {{activityTime}} on {{activityDate}}</li>
{{else}}
<li> An {{entityEvent}} to {{entityName}} has been {{entityAction}} for {{ companyName }} by {{userName}} at {{activityTime}} on {{activityDate}}</li>
{{/if}}
</ul>
 `,



 MESSAGE_HEADER_FOT_EDITED: ` 
<label> 
{{#if customId}}
{{userName}} edited the details of {{customId}} {{entityName}} in {{companyName}}
{{else}}
{{userName}} {{entityAction}}  the details of {{entityName}} in {{companyName}}
{{/if}}
</label>`,
MESSAGE_SUB_HEADER_FOT_EDITED: `
{{#if customId}}<label> 
{{userName}} edited the details of {{customId}} {{entityName}} in {{companyName}}
{{else}}
{{userName}} {{entityAction}}  the details of {{entityName}} in {{companyName}}
{{/if}}
</label>
  `,
MESSAGE_TEMPLATE_FOT_EDITED: `
  <label> 
{{#if customId}}
{{userName}} edited the details of {{customId}} transfer in {{companyName}}
{{else}}
{{userName}} {{entityAction}}  the details of {{entityName}} in {{companyName}}
{{/if}}
</label>

<ul>
{{#if customId}} 
<li> The details of the {{ customId }} transfer in {{ companyName }} have been {{entityAction}} by {{userName}} at {{activityTime}} on {{activityDate}}</li>
{{else}}
<li> The details of {{entityName}} in {{companyName }} has been {{entityAction}} for have been  by {{userName}} at {{activityTime}} on {{activityDate}}</li>
{{/if}}
</ul> `,




  MESSAGE_HEADER_FOT_DELETED: `
  <label> 
{{#if customId}}
{{userName}} deleted a {{entityEvent}} to {{customId}} for {{companyName}} 
{{else}}
{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.
{{/if}}
</label>`,
  MESSAGE_SUB_HEADER_FOT_DELETED: `
<label> 
{{#if customId}}
{{userName}} deleted a {{entityEvent}} to {{customId}} for {{companyName}} 
{{else}}
{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.
{{/if}}
</label>
`,
MESSAGE_TEMPLATE_FOT_DELETED: `
<label> 
{{#if customId}}
{{userName}} deleted a {{entityEvent}} to {{customId}} for {{companyName}} 
{{else}}
{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.
{{/if}}
</label>

<ul>
{{#if customId}} 
<li> A {{entityEvent}} to {{ customId }} has been {{entityAction}}  for {{ companyName }} by {{userName}} at {{activityTime}} on {{activityDate}}</li>
{{else}}
<li> An {{entityEvent}} to {{entityName}} has been {{entityAction}} for {{ companyName }} by {{userName}} at {{activityTime}} on {{activityDate}}</li>
{{/if}}
</ul> `,




MESSAGE_HEADER_FOT_PUBLISHED: `
<label> 
{{#if customId}}
{{userName}} published a {{entityEvent}} to {{customId}} for {{companyName}} 
{{else}}
{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.
{{/if}}
</label>`,
MESSAGE_SUB_HEADER_FOT_PUBLISHED: ` 
<label> {{#if customId}}
{{userName}} published a {{entityEvent}} to {{customId}} for {{companyName}} 
{{else}}
{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.
{{/if}}
</label>`,
MESSAGE_TEMPLATE_FOT_PUBLISHED: `
<label> 
{{#if customId}}
{{userName}} published a {{entityEvent}} to {{customId}} for {{companyName}} 
{{else}}
{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.
{{/if}}
</label>
<ul>
{{#if customId}} 
<li> A {{entityEvent}} to {{ customId }} has been {{entityAction}}  for {{ companyName }} by {{userName}} at {{activityTime}} on {{activityDate}}</li>
{{else}}
<li> An {{entityEvent}} to {{entityName}} has been {{entityAction}} for {{ companyName }} by {{userName}} at {{activityTime}} on {{activityDate}}</li>
{{/if}}
</ul> `,

MESSAGE_BUTTON_REDIRECT_GO_TO_FOT: `View equity details`,

}

export default templates

import { useSessionStorage } from 'beautiful-react-hooks';
import _ from "lodash";
import { useEffect, useState } from "react";
import useFetch from '../../../modules/generic/hooks/useFetch';
const useVersion = () => {
    const [versionsStorage, setVersionsStorage] = useSessionStorage('versions', null);
    const [versions, setVersions] = useState(versionsStorage);

    const onCompleted = (response) => {
        publicSetVersions(response);
    }

    const { refetch, loading } = useFetch({
        url: `/featureVersions`, onCompleted, cancelAutimaticFetch: true,
        options: {
            headers: {
                Accept: "application/json",
                'Content-Type': "application/json"
            }
        }
    });

    useEffect(() => {
        if (_.isEmpty(versionsStorage)) {
            refetch();
            return;
        }
        setVersions(versionsStorage)
    }, [JSON.stringify(versionsStorage)])
    const getVersion = (key, defaultVersion) => {
        return loading ? null : _.get(versions, key, defaultVersion)
    }
    const getVersionEnv = (key, defaultVersion) => {
        return _.get(window, `env.versions.${key}`, defaultVersion);
    }
    const publicSetVersions = (versions) => {
        setVersions(versions)
        setVersionsStorage(versions);
    }
    return { getVersion,getVersionEnv, versions, loading: loading || versions == null, setVersions: publicSetVersions };
}

export default useVersion;
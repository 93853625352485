import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import DocumentPreviewFile from "../../../../../components/generic/Documents/DocumentPreview/DocumentPreviewFile";
import { PanelGenerated } from "../../../../../components/modules/clara/startups/Document";
import useGetDocument from "../../../../../hooks/services/modules/Document/useGetDocument";
import useGetGroupCompaniesLazy from "../../../../../hooks/services/modules/generate/useGetGroupCompaniesLazy";
import useSession from "../../../../../modules/session/hooks/useSession";
import {
  // goToPage,
  updateParamsBreadCrumbs
} from "../../../../../store/actions/page";
import TitleDocumentGenerated from "./components/TitleDocumentGenerated";
import DetailsDocumentGeneratedSkeleton from "./DetailsDocumentGeneratedSkeleton";
import classes from "./Styles.module.scss";
import useGetTemplateByDocumentType from "src/hooks/services/modules/Document/useGetTemplateByDocumentType";

const DetailsUpload = ({ ...props }) => {

  // const [dataDocument, setDataDocument] = useState(null);
  const params = useParams();
  const { startupId } = useSession();
  const dispatch = useDispatch();

  const {
    manualQuery,
    loading: loadingSipDocument,
    data: dataSipDocument,
  } = useGetGroupCompaniesLazy();

  const { data, loading, refetch } = useGetDocument({
    agreementId: params.id,
    startupId,
  });
  const {getTemplateByDocumentType} = useGetTemplateByDocumentType()
  const [template, setTemplate] = React.useState(null)
  useEffect(()=>{
    if (data && !template) getTemplateByDocumentType({documentType:_.get(data,'type.code')?data.type.code:data?.docType}).then(res=>{
      setTemplate(res.template)
    })
  },[data, template])

  const fileName = _.get(data, "file.name");
  const fileType = fileName ? fileName.split(".").pop() : "";

  useEffect(() => {
    if (data && data.type.code === "GRANT_AGREEMENT") {
      manualQuery({ variables: { startupId } });
    } else if (data) {
      dispatch({ type: "SET_FORM_DOCUMENT", payload: { document: data } });
    }
  }, [JSON.stringify(data)]);

  const handleLoading = () => {
    if (data && data.type.code === "GRANT_AGREEMENT") {
      return loadingSipDocument && loading;
    } else {
      return loading;
    }
  };

  useEffect(() => {
    if (_.get(data, "name", null)) {
      updateParamsBreadCrumbs(dispatch, { fullName: data.name });
    }
  }, [loading]);

  useEffect(() => {
    if (data) {
      dispatch({ type: "SET_FORM_DOCUMENT", payload: { document: data } });
    }
  }, [data]);

  useEffect(() => {
    if (dataSipDocument) {
      // _.set(data, 'params.terms.shareIncentivePlan.signed', _.some(dataSipDocument, gc => _.some(gc.shareClass, shareClass => _.some(shareClass.shareIncentivePlans, sh => sh.document && (sh.document.id === _.get(data, 'params.terms.shareIncentivePlan.id', '') || sh.id === _.get(data, 'params.terms.shareIncentivePlan.id', '')) && sh.document.status === 'Signed'))))
      // _.set(data, 'params.terms.shareIncentivePlan.signed')
      dispatch({ type: "SET_FORM_DOCUMENT", payload: { document: data } });
    }
  }, [dataSipDocument]);


  return (
    <>
      {handleLoading() ? (
        <DetailsDocumentGeneratedSkeleton></DetailsDocumentGeneratedSkeleton>
      ) : (
        <div className={classes.DetailsDocument}>
          <TitleDocumentGenerated title={_.get(data, "name", "")} document={data} refetch={refetch} template={template} />
          <div className={classes.containerDetailsUpload}>
            {/* <h1 className={classes.titleDocument}>{_.get(data, "name", "")}</h1> */}
            <div className={classes.containerPanel}>
              <PanelGenerated data={data} />
            </div>
            <div className={classes.containerPreviewV4}>
              <DocumentPreviewFile
                fileId={_.get(data, "file.id")}
                fileName={fileName}
                fileType={fileType}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DetailsUpload;

import React, { FC } from "react";
import StartupProfileTasks from "../../../../modules/assistanceV4/components/AssistanceAreas/StartupProfile/Tasks";
import AssistanceFrame2 from "./";

/**
 * Assistance Frame for StartupProfile
 */

export interface AssistanceStartupProfileProps { }

const AssistanceStartupProfile: FC<AssistanceStartupProfileProps> = ({
  ...props
}: AssistanceStartupProfileProps) => {

  

  return (
    <AssistanceFrame2
      tasksComponent={StartupProfileTasks}
      showTask={true}
      
    ></AssistanceFrame2>
  );
};

export default AssistanceStartupProfile;

import { useMemo } from 'react';
import createUltimateBeneficialOwner from "../graphql/useCreateUltimateBeneficialOwner";
import useSession from "src/modules/session/hooks/useSession";
import useUpsertRenewalData from "../graphql/useUpsertRenewalData";

const useServices = ({ initialValues }) => {
  const { groupCompanyId } = initialValues;
  const { startupId } = useSession();
  const mutation = createUltimateBeneficialOwner();
  const mutationRenewalData = useUpsertRenewalData();

  return useMemo(() => ({

    initialData: async (context, event) => {
      return initialValues;
    },

    SaveStep: async (context, event) => {
      let result = null;
      if (context?.ConfirmForm?.accept === false && context?.ConfirmForm?.accept2 === false) {
        const variables = {
          startupId,
          groupCompanyId: groupCompanyId,
          renewalData: {
            hasOwnerWithQuarterOrMore: context?.ConfirmForm?.accept,
            hasIndividualsWithSignificantInfluence: context?.ConfirmForm?.accept2
          }
        };
        result = await mutationRenewalData({ variables })        
      } else {
        const variables = {
          startupId,
          groupCompanyId: groupCompanyId,
          entityData: {
            name: context?.AddForm?.ultimateBeneficialOwnerName,
            taxJurisdiction: context?.AddForm?.taxJurisdiction,
            taxRegistrationNumber: context?.AddForm?.tin,
            type: context?.AddForm?.ultimateBeneficialOwnerType,
            mailingAddress: {
              street: context?.AddForm?.mailingAddress?.street,
              city: context?.AddForm?.mailingAddress?.city,
              state: context?.AddForm?.mailingAddress?.state,
              country: context?.AddForm?.mailingAddress?.country,
              zipCode: context?.AddForm?.mailingAddress?.zipCode,
              type: context?.AddForm?.mailingAddressType?.toUpperCase()
            }
          },
        };
        result = await mutation({ variables });
      }
      return result;
    },

  }), [initialValues]);
}

export default useServices;

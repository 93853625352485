import React, { FC, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { goToNextPage } from "../../../../../../../../../store/actions/page";
import { Constants } from "../../../../../../../../../v1/utils";
import useBusinessRules from '../../../../../../../../clara/hooks/useBusinessRules';
import Button from "../../../../../../../../generic/components/Buttons/Button";
import { StepProps } from "../../../../../../../../generic/components/Wizard/Step";
import useCustomMachine from "../../../../../../../../generic/hooks/useCustomMachine";
import useTranslate from "../../../../../../../../generic/hooks/useTranslate";
import { addAssistantText, clearAssistatText, removeAssistantText } from "../../../../../../../../generic/store/action";
import TemplateWizard from "../../../../../../../../generic/templates/Modal/TemplateWizard";
import GenerateOrScaleUp from "../../forms/GenerateOrScaleUp";
const GenerateDocumentStep:FC<StepProps>=({})=>{
    const dispatch=useDispatch()
    const {send}=useCustomMachine()
    const {translate}=useTranslate()
    const {hasSubscriptionAccessGenerateDocuments} = useBusinessRules()
    const canGenerateDocuments = hasSubscriptionAccessGenerateDocuments();
    const handleCompleteSubmit=(values)=>{
        dispatch(clearAssistatText())
        if(values.generate==='safe' || values.generate==='convertible') dispatch(goToNextPage(Constants.PAGES.chooseDocumentType, { previewDocument:values.generate==='safe'?'SAFE':'CONVERTIBLE_NOTE' }))
        else if(values.generate==='notGenerate') send("CANCEL")
        else if(values.generate==='true') dispatch(goToNextPage(Constants.PAGES.upgrade))
        else send("CANCEL")
    }
    useEffect(()=>{
       if(canGenerateDocuments){
           dispatch(removeAssistantText());
           dispatch(addAssistantText(translate('MODULES.EQUITY.STEPS_CONVERTIBLE.GENERATE_DOCUMENTS.TOOLTIP_CAN_GENERATE')));
       }
       else{
           dispatch(removeAssistantText());
           dispatch(addAssistantText(translate('MODULES.EQUITY.STEPS_CONVERTIBLE.GENERATE_DOCUMENTS.TOOLTIP_CANT_GENERATE')))
       }
    },[canGenerateDocuments])
    const handleCancel=()=>{
        send("CANCEL")
    }
    const handlePrev=()=>{
        send("PREVIOUS")
    }
    return (<>
        <GenerateOrScaleUp
            onCompleteSubmit={handleCompleteSubmit}
            buttons={{cancel: <Button onClick={handleCancel}>Cancel</Button>,previous:<Button onClick={handlePrev}>Previous</Button>}}
            propsTemplate={{
                title:translate('MODULES.EQUITY.STEPS_CONVERTIBLE.GENERATE_DOCUMENTS.TITLE'),
                subTitle:translate('MODULES.EQUITY.STEPS_CONVERTIBLE.GENERATE_DOCUMENTS.SUBTITLE')
            }}
            Template={TemplateWizard}
        />
        </>)
}
export default GenerateDocumentStep
export default {
    FORMATION_DETAIL_STEP:{
        TITLE:'Formation details',
        SUBTITLE:'Engage concierge',
        TOOLTIP:'Tell Clara concierge some details about the company being formed and add any additional requests or info.​',
    },
    ENGAGE_CONCIERGE_DONE_STEP: {
        TITLE:'Clara will be in touch',
        SUBTITLE:'Engage concierge',
        INFORMATION: 'These details have been sent to the Clara concierge service. You’ll be contacted soon to continue the incorporation process and make the company official!​',
    },

    BUTTON_ENGAGE_CONCIERGE:'Engage concierge',
    BUTTON_DONE: 'Done',
    LABEL_ADDITIONAL_INFO:'Additional info',
    LABEL_NAME:'Name',
    LABEL_PROFILE_NAME:'Profile name',
    LABEL_TELEPHONE_NUMBER:'Telephone number',
    PLACEHOLDER_ADDITIONAL_INFO:'Additional info',
    PLACEHOLDER_EMAIL:'Add a valid email address',
    PLACEHOLDER_NAME:'Name',
    PLACEHOLDER_PROFILE_NAME:'Profile Name',
    PLACEHOLDER_TELEPHONE:'Include numbers only',
    VALIDATION_REQUIRED:'Required field',

}
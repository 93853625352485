import _ from "lodash";
import React, { Component } from "react";
import { CustomView, isMobile, isTablet, MobileView } from "react-device-detect";
import { connect } from "react-redux";
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import classes from "./App.module.scss";
import ManagerHeadMetadata from "./components/managers/ManagerHeadMetadata/ManagerHeadMetadata";
import { LoadingProvider } from "./modules/generic/hooks/useLoading";
import Onbording from './modules/Onboarding';
import { goToPage } from './store/actions/page';
import { ManagerRoute } from "./v1/components";
import AddKYCCompanyForm from './v1/components/Forms/View/IncorporationCompany/AddKYCCompanyForm';
import AddKYCForm from './v1/components/Forms/View/IncorporationCompany/AddKYCForm';
import AddProposalAndEngagements from './v1/components/Forms/View/IncorporationCompany/AddProposalAndEngagements';
import AddQuestionnaireCompanyForm from './v1/components/Forms/View/IncorporationCompany/AddQuestionnaireCompanyForm';
import AddDocumentKYCForm from './v1/components/Forms/View/IncorporationCompany/DocumentKYC/AddDocumentKYCForm';
import PortalDocumentView from './v1/components/Forms/View/IncorporationCompany/PortalDocuments/PortalDocumentsView';
import EditAgreement from './v1/components/Forms/View/IncorporationCompany/Questionniare/AgreementData/EditAgreement';
import EditBanckService from './v1/components/Forms/View/IncorporationCompany/Questionniare/BanckService/EditBanckService';
import EditBeneficialOwners from './v1/components/Forms/View/IncorporationCompany/Questionniare/BeneficialOwners/EditBeneficialOwners';
import AddQuestionnaireCompany from './v1/components/Forms/View/IncorporationCompany/Questionniare/Company/AddQuestionnaireCompany';
import EditCompanyDetails from './v1/components/Forms/View/IncorporationCompany/Questionniare/Company/EditCompanyDetails';
import EditDataControler from './v1/components/Forms/View/IncorporationCompany/Questionniare/DataControler/EditDataControler';
import EditEntities from './v1/components/Forms/View/IncorporationCompany/Questionniare/Entities/EditEntities';
import AddQuestionnaireIndividual from './v1/components/Forms/View/IncorporationCompany/Questionniare/Individual/AddQuestionnaireIndividual';
import EditShareholders from './v1/components/Forms/View/IncorporationCompany/Questionniare/Shareholders/EditShareholders';
import EditSignatories from "./v1/components/Forms/View/IncorporationCompany/Questionniare/Signatories/EditSignatories";
import Tutorial from "./v1/components/IntroductionTutorials/tutorial";
/* A refactorizar*/
import ManagerNotification from './v1/components/ManagerNotification/ManagerNotification';
import SessionDataManager from "./v1/components/SessionManager/SessionDataManager";
import VerifyEmail from "./v1/components/VerifyEmail/VerifyEmail";
import {
  ApproveDocument, EditDueDiligenceForm, EditIncorporationCompany,
  EditMatter, GenerateDocumentForm,
  GetSignatureForm,
  Logout,
  Mobile, UploadDocumentForm
} from "./v1/containers";
import ApolloProviderHelper from "./v1/utils/ApolloProviderHelper";
import { Constants } from './v1/utils/constants';
import logger from "./v1/utils/logger";
/* Start Refactor */
import routers from "./views";
import IntercomComponent from "./layouts/Clara/IntercomComponent";
import GenerateDocumentsTemplateView from "./views/modules/startups/documents/GenerateDocumentsTemplateView/GenerateDocumentsTemplateView";
import GenerateDocumentsTemplateViewAdmin from "./views/modules/startups/documents/GenerateDocumentsTemplateView/GenerateDocumentsTemplateViewAdmin";
import CapTableView from "./views/modules/startups/capTable/CapTable/CapTableView";
import CapTableEmptyView from "./views/modules/startups/capTable/CapTable/CapTableEmptyView";
import MyEquityView from "./views/modules/startups/capTable/MyEquity/MyEquityView";
import EmptyScreenLayout from "./layouts/EmptyScreenLayout/EmptyScreenLayout";
import GeneralLoading from "src/components/generic/Loading/GeneralLoading";
import { Elements } from '@stripe/react-stripe-js';

class App extends Component {
  addDocumentKYC
  constructor(props) {
    super(props);
    this.state = {
      pages: {
        "healthCheck": {
          path: "/healthCheck",
        }
      },
      loading: true,
    }
  };

  loadFile = async (fileName) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = fileName;
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });
  };

  loadFiles = async () => {
    try {
      const importFiles = _.map(_.get(window, "env.mf", []), (mf) => {
        return this.loadFile(mf?.url + "?v=" + _.get(window, "env.releaseVersion", new Date().getTime())).catch(error => {
          console.error(`Error loading file ${mf.url}:`, error);
          // Devolvemos una promesa resuelta para que Promise.all no falle si este archivo falla
          return Promise.resolve();
        });
      });
      const minDelay = new Promise((resolve) => setTimeout(resolve, 2000));
      await Promise.all([...importFiles, minDelay]
      );
      this.setState({ loading: false });
    } catch (error) {
      console.error("Error loading files:", error);
    }
  };

  componentDidCatch(error, errorInfo) {
    logger.error(Constants.LOGGER.ACTIONS.REACT, { error, errorInfo })
    if (_.get(window, "env.loggerConfig.redirectError", false)) {
      window.location = Constants.PAGES.error.path
    }
  };

  componentDidMount() {
    this.loadFiles();
  };

  isDefaultView = () => {
    let rootElement = document.getElementById('html_root');
    if (isTablet) {
      rootElement.classList.add(classes['detect-device-tablet']);
    }
    return (isTablet && window?.env?.enabledTablet) || !isMobile;
  };

  render() {
    const { loading } = this.state;
    if (loading) {
      return (<GeneralLoading />)
    }
    return (
      <>
        
          <IntercomComponent />
          <div className={classes.rootContentStyle}>
            {
              ((!window?.env?.enabledTablet && isTablet) || (!isTablet && isMobile)) && < MobileView >
                <Mobile />
              </MobileView>
            }
            <CustomView condition={this.isDefaultView()}>
              <ApolloProviderHelper >
                <SessionDataManager />
                <Router>
                  <div className={classes.Router} >
                    <Switch>
                      <Route path={"/login"} exact="true" render={() => <Redirect to={Constants.PAGES.login.path} />} />
                      <Route path={"/recovery"} exact="true" render={() => <Redirect to={Constants.PAGES.recovery.path} />} />
                      <Route path={"/register"} exact="true" render={() => <Redirect to={Constants.PAGES.register.path} />} />
                      <Route path={"/reset/:token"} exact="true" render={(obj) => {
                        return (<Redirect to={`${Constants.PAGES.reset.path}?token=${obj?.match?.params?.token}`} />)
                      }} />



                      <ManagerRoute path={Constants.PAGES.onboarding.path} layout={"invite"}>
                        <Onbording />
                      </ManagerRoute>



                      <ManagerRoute path={Constants.PAGES.logout.path} layout={EmptyScreenLayout}>
                        <Logout />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.clientReview.path} action={Constants.PAGES.clientReview.action} layout={Constants.PAGES.clientReview.layout} >
                        <PortalDocumentView mode={Constants.PORTAL_DOCUMENTS_KEYS.CLIENT_REVIEW} />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.incorporationSignatories.path} action={Constants.PAGES.incorporationSignatories.action} layout={Constants.PAGES.incorporationSignatories.layout} >
                        <PortalDocumentView mode={Constants.PORTAL_DOCUMENTS_KEYS.SIGNATORIES} />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.incorporationPack.path} action={Constants.PAGES.incorporationPack.action} layout={Constants.PAGES.incorporationPack.layout} >
                        <PortalDocumentView mode={Constants.PORTAL_DOCUMENTS_KEYS.INCORPORATION_PACK} />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.viewIncorporation.path} layout={Constants.PAGES.viewIncorporation.layout} action={Constants.PAGES.viewIncorporation.action} >
                        <EditIncorporationCompany mode={"view"}>
                        </EditIncorporationCompany>
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.viewMatter.path} layout={Constants.PAGES.viewMatter.layout} action={Constants.PAGES.viewMatter.action} >
                        <EditMatter mode={"view"}>
                        </EditMatter>
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.editMatter.path} layout={Constants.PAGES.editMatter.layout} action={Constants.PAGES.editMatter.action} >
                        <EditMatter mode={"edit"} />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.addIndividual.path} layout={Constants.PAGES.addIndividual.layout} action={Constants.PAGES.addIndividual.action} >
                        <AddKYCForm />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.editIndividual.path} layout={Constants.PAGES.editIndividual.layout} action={Constants.PAGES.editIndividual.action} >
                        <AddKYCForm mode={'edit'} />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.editCompany.path} layout={Constants.PAGES.editCompany.layout} action={Constants.PAGES.editCompany.action} >
                        <AddKYCCompanyForm mode={'edit'} />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.editEntity.path} layout={Constants.PAGES.editEntity.layout} action={Constants.PAGES.editEntity.action} >
                        <AddKYCCompanyForm mode={'edit'} type={'entity'} />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.addEntity.path} layout={Constants.PAGES.addEntity.layout} action={Constants.PAGES.addEntity.action} >
                        <AddKYCCompanyForm type={'entity'} />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.proposalsAndEngagements.path} layout={Constants.PAGES.proposalsAndEngagements.layout} action={Constants.PAGES.proposalsAndEngagements.action} >
                        <AddProposalAndEngagements />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.addDocumentKYC.path} layout={Constants.PAGES.addDocumentKYC.layout} action={Constants.PAGES.addDocumentKYC.action} >
                        <AddDocumentKYCForm />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.addCompanyKYC.path} layout={Constants.PAGES.addCompanyKYC.layout} action={Constants.PAGES.addCompanyKYC.action} >
                        <AddKYCCompanyForm />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.editCompanyQuestionnaire.path} layout={Constants.PAGES.editCompanyQuestionnaire.layout} action={Constants.PAGES.editCompanyQuestionnaire.action} >
                        <AddQuestionnaireCompanyForm />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.addQuestionnaireCompany.path} layout={Constants.PAGES.addQuestionnaireCompany.layout} action={Constants.PAGES.addQuestionnaireCompany.action} >
                        <AddQuestionnaireCompany />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.editQuestionnaireCompany.path} layout={Constants.PAGES.editQuestionnaireCompany.layout} action={Constants.PAGES.editQuestionnaireCompany.action} >
                        <AddQuestionnaireCompany mode={'edit'} />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.editQuestionnaireIndividual.path} layout={Constants.PAGES.editQuestionnaireIndividual.layout} action={Constants.PAGES.editQuestionnaireIndividual.action} >
                        <AddQuestionnaireIndividual mode={'edit'} />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.questionnaireEditAgreements.path} layout={Constants.PAGES.questionnaireEditAgreements.layout} action={Constants.PAGES.questionnaireEditAgreements.action} >
                        <EditAgreement mode={'edit'} />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.questionnaireCompany.path} action={Constants.PAGES.addCompanyKYC.action} >
                        <EditCompanyDetails mode={'edit'} />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.questionnaireShareholders.path} action={Constants.PAGES.addCompanyKYC.action} >
                        <EditShareholders mode={'edit'} />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.questionnaireDataControler.path} action={Constants.PAGES.addCompanyKYC.action} >
                        <EditDataControler mode={'edit'} />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.questionnaireEditdirectors.path} action={Constants.PAGES.addCompanyKYC.action} >
                        <EditEntities mode={'edit'} />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.questionnaireEditBeneficialOwners.path} action={Constants.PAGES.addCompanyKYC.action} >
                        <EditBeneficialOwners mode={'edit'} />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.questionnaireBanckService.path} action={Constants.PAGES.addCompanyKYC.action} >
                        <EditBanckService mode={'edit'} />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.questionnaireSignatories.path} action={Constants.PAGES.addCompanyKYC.action} >
                        <EditSignatories mode={'edit'} />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.addDocument.path} layout={Constants.PAGES.addDocument.layout} action={Constants.PAGES.addDocument.action} >
                        <UploadDocumentForm />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.generateDocument.path} layout={Constants.PAGES.generateDocument.layout} action={Constants.PAGES.generateDocument.action} >
                        <GenerateDocumentForm />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.generateDocumentMF.path} layout={Constants.PAGES.generateDocumentMF.layout} action={Constants.PAGES.generateDocumentMF.action} >
                        <GenerateDocumentsTemplateView />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.generateDocumentCustomTemplateMF.path} layout={Constants.PAGES.generateDocumentCustomTemplateMF.layout} action={Constants.PAGES.generateDocumentCustomTemplateMF.action} >
                        <GenerateDocumentsTemplateView />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.viewDocumentDraft.path} layout={Constants.PAGES.viewDocumentDraft.layout} action={Constants.PAGES.viewDocumentDraft.action} >
                        <GenerateDocumentsTemplateView />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.viewDocumentTemplate.path} layout={Constants.PAGES.viewDocumentTemplate.layout} action={Constants.PAGES.viewDocumentTemplate.action} >
                        <GenerateDocumentsTemplateView />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.generateTemplate.path} layout={Constants.PAGES.generateTemplate.layout} action={Constants.PAGES.generateTemplate.action} >
                        <GenerateDocumentsTemplateViewAdmin />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.editHealthCheckIssue.path} layout={Constants.PAGES.editHealthCheckIssue.layout} action={Constants.PAGES.editHealthCheckIssue.action} >
                        <EditDueDiligenceForm mode={"edit"} />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.viewHealthCheckIssue.path} layout={Constants.PAGES.viewHealthCheckIssue.layout} action={Constants.PAGES.viewHealthCheckIssue.action} >
                        <EditDueDiligenceForm mode={"view"} />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.editGenerateDocument.path} layout={Constants.PAGES.editGenerateDocument.layout} action={Constants.PAGES.editGenerateDocument.action} >
                        <GenerateDocumentForm isEdit={true} />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.editDraftDocument.path} layout={Constants.PAGES.editDraftDocument.layout} action={Constants.PAGES.editDraftDocument.action} >
                        <UploadDocumentForm isEditDraft={true} />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.uploadNewDocumentVersion.path} layout={Constants.PAGES.uploadNewDocumentVersion.layout} action={Constants.PAGES.uploadNewDocumentVersion.action} >
                        <GenerateDocumentForm isEdit={true} formType={"uploadToEdit"} />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.getSignature.path} layout={Constants.PAGES.getSignature.layout} action={Constants.PAGES.getSignature.action} >
                        <GetSignatureForm />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.approveDocument.path} layout={Constants.PAGES.approveDocument.layout} action={Constants.PAGES.approveDocument.action} >
                        <ApproveDocument />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.introduction.path} layout={Constants.PAGES.introduction.layout} action={Constants.PAGES.introduction.action} >
                        <Tutorial />
                      </ManagerRoute>
                      <ManagerRoute path={Constants.PAGES.verify.path} layout={Constants.PAGES.verify.layout} action={Constants.PAGES.verify.action} >
                        <VerifyEmail />
                      </ManagerRoute>
                      <Route path={Constants.PAGES["capTable.mf"].path} >
                        <CapTableView routers={Constants.PAGES["capTable.mf"].routers} />
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.myEquity"].path} >
                        <MyEquityView routers={Constants.PAGES["capTable.mf.myEquity"].routers} />
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.empty"].path} >
                        <CapTableEmptyView />
                      </Route>
                      {routers.map((route, i) => (
                        <ManagerRoute
                          key={i}
                          {...route}
                        >
                          <React.Fragment>
                            <ManagerNotification />
                            <ManagerHeadMetadata {...route.head} />
                            <LoadingProvider keyValue={"page"}>{route.component}</LoadingProvider>
                          </React.Fragment>
                        </ManagerRoute>
                      ))}
                    </Switch>
                  </div>
                </Router >
              </ApolloProviderHelper>
            </CustomView>
          </div >
        
      </>
    )
  }
}

const mapStateToProps = state => ({
  session: state.session,
});

const mapDispatchToProps = (dispatch) => ({
  goToPage: (page, params) => {
    goToPage(dispatch, page, params)
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

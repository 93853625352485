import MachineEquity from "../../../../wizard/machine/MachineEquity";
import FirstConvertibleStep from "./steps/FirstStep";
import SelectCompaniesConvertibleStep from "./steps/SelectCompaniesStep";
import ConvertibleFormStep from "./steps/ConvertibleFormStep";
import IsThereDocumentConvertibleStep from "./steps/IsThereDocumentsStep";
import UploadDocumentConvertibleFormStep from "./steps/UploadDocumentStep";
import SelectDocumentConvertibleStep from "./steps/SelectDocumentStep";
import GenerateDocumentStep from "./steps/GenerateDocumentStep";
class ConvertibleMachine extends MachineEquity{
    constructor() {
        super('convertible',FirstConvertibleStep,SelectCompaniesConvertibleStep,ConvertibleFormStep,IsThereDocumentConvertibleStep,UploadDocumentConvertibleFormStep,SelectDocumentConvertibleStep,GenerateDocumentStep);
    }
    getParamsData(initialData: object){
        return initialData
    }
    getInitialValues(initialData: object): object {
        return {}
    }
}
export default ConvertibleMachine
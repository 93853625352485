import React, {Component} from 'react';
import {ButtonModalOptions, ShareDeleteForm, Table} from "../../../../index";
import classes from './EquityTable.module.scss';
import * as _ from 'lodash';
import ModalShare from '../../../../Modal/ModalShare/ModalShare';
import {deleteShareClass, upsertShareClass} from '../../../../../graphql/mutations/equity';
import ServerConnect from "../../../../../utils/ServerConnect";

class ShareclassesTable extends Component {

        constructor(props){
            super(props);
            this.state={
                showEdit: false,
                showDelete: false,

            }
        }


    handleEditShare =(row) =>{
        this.setState({
            showEdit:true,
            type: "edit",
            shareClassSelected: row
        })

    }
    handleClose = props =>{
            this.setState({
                ...props
            })
    }
    countEquityByShareClass = equities =>{
        
        let shareClass = [];
        //map shareClass in Equity
        equities.forEach(eq => {

            if (!_.some(shareClass, eq.shareClass)) {
                shareClass.push({
                    ...eq.shareClass,
                    totalEquity: 1
                })
            }
            else {
                shareClass[_.findIndex(shareClass, eq.shareClass)].totalEquity++;
            }


        });

        //when is to Group Company can have share Class with 0 equity
        if (!_.isEmpty(this.props.shareClass)) {
            _.forEach(this.props.shareClass, sh => {
                if (!_.some(shareClass, { "id": sh.id })) {
                    shareClass.push({
                        ...sh,
                        totalEquity: 0
                    })
                }
            })


           
        }
        return shareClass;
    }

    handleCloseDeleteModal = redirect => {
        this.setState({
            showDelete: false
        });
    };
    handleDelete = (row) =>{
        this.setState({
            showDelete:true,
            shareClassSelected: row.id
        })
    }

    handleSubmitDelete =() =>{
        this.props.showLoading(true);
        
        let variables ={
            startupId: this.props.startupId,
            groupCompanyId: this.props.equityBelong.id,
            shareClassId: this.state.shareClassSelected
        };
        ServerConnect.graphQlMutation(deleteShareClass,variables)
        .then(result =>{
            this.props.refreshData();
        })

    }

    handleSubmit = (value) =>{
        this.handleClose();
        this.props.showLoading(true);
        let variables={
            startupId: this.props.startupId,
            shareClass: {
                name: value.name,
                groupCompanyId: this.props.equityBelong.id,
                id: this.state.shareClassSelected.id
            }
        };
        ServerConnect.graphQlMutation(upsertShareClass,variables)
        .then(result =>{
            this.props.refreshData()
        })
            
    }

        
    componentWillMount(){
        const { shares, shareOptions }= this.props.data;
        this.setState({
            shareClass: this.countEquityByShareClass(_.concat(shares,shareOptions))
        })
        

    }

    render() {

        let {shareClass} =this.state;
        
        let options=[];
        options.push({label: "Edit", action: this.handleEditShare , className: `${classes.EditIcon}`})
        options.push({ label: "Delete", action: this.handleDelete, className: `${classes.DeleteIcon}`, isDelete: true })

        let fields = [
            {
                key: "name",
                label: "Class Name",
                decorator: props => (
                    <div className={classes.letterTables}>
                        {props.row.name}
                    </div>
                ),
                className:classes.Col1

            },
            {
                label: "Equity Positions",
                decorator: props => {
                 return props.row.totalEquity>0? <div>{props.row.totalEquity}</div>
                :<div className={classes.letterDisable}>No Equity Positions</div>

                },
                className:classes.Col2
            },
            {
                label: "",
                decorator: props => {
                    return (
                        <span className={classes.containerButtonModal}>
                            <ButtonModalOptions title={null}
                                options={_.filter(options, op => {
                                    return op.isDelete ? props.row.totalEquity === 0 && !props.row.defaultShare  : true;
                                })}
                                params={props.row} />
                                { props.row.defaultShare &&  <div className={`${classes.letterDisable} ${classes.default}`}>Default</div> }
                        </span>
                        )
                },
                className:classes.Col3
            }

        ]
        return (
            <div className={classes.containerTables}>

                <div className={`${classes.containerTable} ${classes.shareTable}`}>
                    <Table
                        fields={fields}
                        data={shareClass}
                        filter={this.props.filter}
                    />
                </div>
                {
                    this.state.showEdit &&
                    (<ModalShare
                        handleEditShare={this.handleEditShare}
                        handleClose={this.handleClose}
                        show={this.state.showEdit}
                        type={"edit"}
                        labelButton={"Rename"}
                        title={"Edit Share Class Name"}
                        placeholder={"Share Class Name"}
                        handleSubmit= {this.handleSubmit}
                        values={_.cloneDeep(this.state.shareClassSelected)}
                        
                    />

                    
                )}
                {this.state.showDelete &&

                    <ShareDeleteForm
                        title={"Delete this Share Class "}
                        label={"Delete this Share Class"}
                        handleClose={this.handleCloseDeleteModal}
                        handleSubmit={this.handleSubmitDelete}
                    />

                }
                
            </div>
        )
                
    }
}

export default ShareclassesTable

import useLazyQuery from "src/hooks/custom/useLazyQuery";

const GET_DATA = `
query getFormationJurisdictionType {
  formationJurisdictionList(language: "en") {
    name
    jurisdictionType
    country {
      name
      alpha3
      code
    }
  }
}
`;

const useFormationJurisdictionType = (variables,config) => {
  const configDefault = {errorMessage: 'GENERIC_PERMISSION_ERROR', showErrorNotification: true, showSuccessNotification: false}
  const { manualQuery,loading, error, data, refetch } = useLazyQuery(GET_DATA, variables, {...configDefault,...config});
  return { manualQuery,loading, error, data, refetch };
}

export default useFormationJurisdictionType;
import React, { FC, useEffect, useRef, useMemo } from 'react';
import { useDispatch } from "react-redux";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import * as Yup from 'yup';
import Form from "../../../../../components/forms/Form/Form";
import Typography from "../../../../generic/components/Texts/Typography";
import FormProps from "../../../../generic/forms/interfaces/FormProps";
import useTranslate from "../../../../generic/hooks/useTranslate";
import { addAssistantText, clearAssistatText } from "../../../../generic/store/action";
import TemplateFormDefault from "../../../../generic/templates/Modal";
import CheckboxButtonSimple from "../../../../generic/components/Inputs/CheckboxButtonSimple";
import ControllerInput from "../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import classes from './classes.module.scss';
import useSession from '../../../../../modules/session/hooks/useSession';
import _ from 'lodash';

const schema = Yup.object().shape({
  confirm: Yup.string().required("Required"),
})

interface Props extends FormProps {
  initialValues: any,
  onCompleteSubmit: any,
  key: string,
  handlePrev: any,
}

const FormStep2: FC<Props> = ({
  initialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
  handlePrev,
  key,
}) => {

  const refForm = useRef();
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const { startup } = useSession();
  const acceleratorName = _.get(startup, "acceleratorDef.name", "");

  const approvedJurisdictions = useMemo(() => {
    let result ="<ul>";
    _.forEach(_.get(startup, "acceleratorDef.approvedJurisdictions", []), appjur => {
      let dataJurisdiction = appjur.jurisdiction;
      if (dataJurisdiction == null) {
        dataJurisdiction = appjur.countryName;
      }
      result += `<li>${dataJurisdiction}</li>`;
    });
    result += "</ul>";
    return result;
  }, [JSON.stringify(_.get(startup, "acceleratorDef.approvedJurisdictions", []))]);

  const approvedJurisdictionsForAccelerator = useMemo(() => {
    const dataFilter = _.filter(_.get(startup, "acceleratorDef.approvedJurisdictions", []), (element) => {
      return element.enabledToCreateFormations
    })
    let result ="<ul>";
    _.forEach(dataFilter, appjur => {
      result += `<li>${appjur.jurisdiction}</li>`;
    });
    result += "</ul>";
    return result;
  }, [JSON.stringify(_.get(startup, "acceleratorDef.approvedJurisdictions", []))]);

  useEffect(() => {
    handeAddTooltips();
    return () => {
      dispatch(clearAssistatText());
    }
  }, []);

  const handeAddTooltips = () => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText(translate("MODAL_SELECT_TOPCO_STEP1_TOOLTIP1")));
    dispatch(addAssistantText(translate("MODAL_SELECT_TOPCO_STEP1_TOOLTIP2", { 
      acceleratorName: acceleratorName,
      approvedJurisdictions: approvedJurisdictions,
      approvedJurisdictionsForAccelerator: approvedJurisdictionsForAccelerator,
    })));
  }

  const options = [
    {
      value: "CONFIRM",
      disabled: true,
      label: translate(
        `MODAL_SELECT_TOPCO_STEP2_CONFIRM`
      )
    },
    {
      value: "ADD",
      label: translate(
        `MODAL_SELECT_TOPCO_STEP2_ADD`
      )
    },
    {
      value: "FORM",
      label: translate(
        `MODAL_SELECT_TOPCO_STEP2_FORM`
      )
    },
  ];

  return (
    <>
      <Form
        key={key}
        defaultValues={initialValues || {}}
        schema={schema}
        onSubmit={onCompleteSubmit}
        ref={refForm} optionsForm={{ mode: "onChange" }}
      >
        <Template 
          props={propsTemplate} 
          buttons={{
            ...buttons,
            submit: <SubmitButton {...buttonSubmitProps}>Confirm</SubmitButton>,
          }}
          >
          <Typography
            className={classes.typoResalt}
          >
            {translate("MODAL_SELECT_TOPCO_STEP2_P1")}
          </Typography>
          <Typography
            className={classes.typoNormal}
          >
            {translate("MODAL_SELECT_TOPCO_STEP2_P2")}
          </Typography>
          <Typography
            className={classes.typoNormal}
          >
            {translate("MODAL_SELECT_TOPCO_STEP2_P3", { acceleratorName: acceleratorName})}
          </Typography>
          <Typography
            className={classes.typoResalt}
          >
            {translate("MODAL_SELECT_TOPCO_STEP2_P4")}
          </Typography>
          <ControllerInput
            render={CheckboxButtonSimple}
            name='confirm'
            classNameButton={classes.buttonCheckBox}
            options={options}
            defaultValue={[]}
            onChange={(value) => {}}
            isMultiple={false}
          />
        </Template>
      </Form>
    </>
  )
}

export default FormStep2
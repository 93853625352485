import * as Yup from "yup";

const schemaWithDescription = Yup.object({
  question: Yup.boolean().required("Required"),
  description: Yup.string().when(["question"], {
    is: true,
    then: Yup.string().nullable(true).required("Required"),
    otherwise: Yup.string().optional().nullable(true),
  }),
});

const validationAmount = Yup.number()
  .required("Required")
  .min(0, "Needs to be an amount number")
  .typeError("Needs to be a number")
  .nullable();

const schemaWithDescriptionAmount = Yup.object({
  question: Yup.boolean().required("Required"),
  description: Yup.string().when(["question"], {
    is: true,
    then: Yup.string().required("Required").typeError("This field is required"),
    otherwise: Yup.string().optional().nullable(true),
  }),
});

const schemaWithDescriptionNumberAmount = Yup.object({
  question: Yup.boolean().required("Required"),
  description: Yup.number().when(["question"], {
    is: true,
    then: validationAmount,
    otherwise: Yup.number().optional().nullable(true),
  }),
});

const schemaQuestion = Yup.object({
  question: Yup.boolean().required("Required"),
});

const schema = Yup.object().shape({
  employeeAmountStart: validationAmount,
  employeeAmountNow: validationAmount,
  isEmployeeShareholder: schemaQuestion,
  uaeVisasIssuedAmount: validationAmount,
  isThereEmployeeWithOtherVisas: schemaQuestion,
  isThereFutureEmployees: schemaWithDescription,
  isCompanyOperational: schemaWithDescriptionNumberAmount,
  companyRevenue: validationAmount,
  futurePlans: Yup.string().required("Required").nullable(),
});

export default schema;

import React, { useState, useImperativeHandle, useRef } from 'react';
import classesModule from "./classes.module.scss"
import PropTypes from "prop-types"
import Botonera from "./../../../../modules/generic/components/Buttons/Botonera"
import ButtonIcon from "./../../../../modules/generic/components/Buttons/ButtonIcon"
import Scrollbar from './../../../../components/generic/Scrollbar/Scrollbar';
const MapInteaction = ({ children, maxScale, minScale, defaultScale, defaultX, defaultY, customBotonera, buttonsPosition = false, innerRef }) => {

  const defaultValue = {
    scale: defaultScale,
    translation: { x: defaultX, y: defaultY }

  }
  const [value, setValue] = useState({
    scale: 1,
    translation: { x: 0, y: 0 }

  })
  const handleReset = () => {
    setValue(defaultValue);
  }
  const getTranslation = (scale) => {
    return { x: scale > 1 ? ((100 / scale) - 100) * -1 : 0, y: 0 }
  }
  const incrementZoom = () => {
    const scale = 0.1 + value.scale;
    setValue({ translation: getTranslation(scale), scale: (scale) });
  }
  const decrementZoom = () => {
    const scale = -0.1 + value.scale;
    setValue({ translation: getTranslation(scale), scale: (scale) });
  }
  const scrollRef = useRef()
  useImperativeHandle(
    innerRef,
    () => ({
      scrollTo: scrollRef.current.scrollTo
    }),
    [scrollRef]
  );
  return (
    <div className={classesModule.MapInteraction}>
      <div className={`${classesModule.Botoneras} ${buttonsPosition ? classesModule.BotonerasUp : classesModule.BotonerasDown}`}>
        <Botonera
          buttons={[
            <ButtonIcon disabled={value.scale === 1} key={3} size={"55%"} icon="focus" variant="transparent" onClick={handleReset} />,
          ]}
          onClick={() => { }}
          type="icons"
        />
        {customBotonera}
        <Botonera
          buttons={[
            <ButtonIcon key={1} size={"55%"} icon="Plus" disabled={value.scale >= maxScale} variant="transparent" onClick={incrementZoom} />,
            <ButtonIcon key={2} size={"55%"} icon="Minus" disabled={value.scale <= minScale} variant="transparent" onClick={decrementZoom} />,
          ]}
          onClick={() => { }}
          type="icons"
        />
      </div>
      {/* /onChange={setValue} maxScale={maxScale} minScale={minScale} showControls={false}> */}
      <Scrollbar innerRef={scrollRef}>
        <div className={classesModule.ZoomZone} style={{ transform: `scale(${value.scale}) translate(${value.translation.x}%, ${value.translation.y}%)` }}>
          {children}
        </div>
      </Scrollbar>
    </div >
  )
};

MapInteaction.propTypes = {
  /**  Id or Key to the component */
  maxScale: PropTypes.number,
  /** Tab index */
  minScale: PropTypes.number,
  /** Dedault Value to scale */
  defaultScale: PropTypes.number,
  /** Dedault X position to map*/
  defaultX: PropTypes.number,
  /** Dedault Y position to map*/
  defaultY: PropTypes.number,

};
MapInteaction.defaultProps = {
  maxScale: 1,
  minScale: 0.5,
  defaultScale: 1,
  defaultX: 0,
  defaultY: 0,
  customBotonera: null
};

export default MapInteaction;

import PropTypes from "prop-types"
import { Grid } from '@material-ui/core';
import React from 'react';
import Typography from 'src/modules/generic/components/Texts/Typography';
import useTranslate from "src/modules/generic/hooks/useTranslate";
import classesModule from './CorporatePositions.module.scss';
import Avatar from 'src/modules/generic/components/Avatars/AvatarLabel'


function CorporatePositionsTab({ data }) {

  const { translate } = useTranslate();

  return (
    <>
      <div className={` ${classesModule.DetailsTab} `}>
        <Grid container spacing={2} >
          <Grid item xs={6} md={12} >
            <Grid container  >
              <Grid item xs={12} >
                <Typography color='dark-grey' variant='m' className={` ${classesModule.spacing} `}>
                  {translate("VIEW_COMPANY_TAB_LABEL_CORPORATEPOSITIONS_PRESIDENT")}
                </Typography>
              </Grid>
              <Grid item xs={12} >
                <Typography color='dark-grey' variant='m' className={` ${classesModule.spacing} `}>
                  {translate("VIEW_COMPANY_TAB_LABEL_CORPORATEPOSITIONS_SECRETARY")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography color='dark-grey' variant='m' className={` ${classesModule.spacing} `}>
                  {translate("VIEW_COMPANY_TAB_LABEL_CORPORATEPOSITIONS_TREASURER")}
                </Typography>
              </Grid>
              <Grid item xs={6} justifyContent="center">
                <Avatar name={data?.fullName ?? ""} avatar={null} showLabel={true} captable={undefined} className={undefined} divStyle={undefined} label={undefined} />
              </Grid>
              <Grid item xs={12}>
                <Typography color='dark-grey' variant='m' className={` ${classesModule.spacing} `}>
                  {translate("VIEW_COMPANY_TAB_LABEL_CORPORATEPOSITIONS_SOLE_INCORPORATOR")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography color='dark-grey' variant='m' className={` ${classesModule.spacing} `}>
                  {translate("VIEW_COMPANY_TAB_LABEL_CORPORATEPOSITIONS_CORPORATE_SIGNER")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

CorporatePositionsTab.propTypes = {
  data: PropTypes.any
}

export default CorporatePositionsTab;
const theme = {
    palette: {
        primary: {
            main: '#2c74ff',
        }
    },
    overrides: {
        MuiPaper: {
            root: {
                minHeight: "auto",
            },
            elevation1: {
                boxShadow: "unset!important",
                background: "transparent",
            },
            elevation8: {
                boxShadow: "unset",
            },
            rounded: {
                borderRadius: "8px",
            },
        },
        MuiTabs: {
            root: {
                height: "100%",
                alignItems: "center",
                minHeight: "auto",
            },
            fixed: {
                height: "100%",
            },
            flexContainer: {
                height: "100%",
            },
            indicator: {
                height: "1px",
                transition: "none",
            }
        },
        MuiTab: {
            root: {
                minWidth: "auto!important",
                minHeight: "auto",
                padding: "0 0 1rem 0    ",
                margin: "0 2.5rem 0 0",
                lineHeight: "normal",
                '&.Mui-disabled': {
                    // color: theme.palette.primary.main,
                    color: "#E0E5E9!important",
                    opacity: "1",
                },
            },
            wrapper: {
                textTransform: "none",
                fontSize: "1.0769rem",
                "fontWeight": "normal",
            }
        },

        MuiPickersDay: {
            daySelected: {
                backgroundColor: "#2c74ff",
            }
        },
        MuiPickersYear: {
            yearSelected: {
                fontWeight: 700
            }
        },
        MuiPickersMonth: {
            monthSelected: {
                fontWeight: 700
            }
        },
        MuiTypography: {
            h1: {
                "fontSize": "24px",
                "color": "#2c74ff",
                "fontWeight": "normal",
                "fontFamily": "cl-regular",
                "lineHeight": "19px",
            },
            h4: {
                fontFamily: "cl-regular"
            },
            h5: {
                fontFamily: "cl-regular"
            },
            subtitle1: {
                fontFamily: "cl-regular"
            },
            caption: {
                fontFamily: "cl-regular"
            },
            body1: {
                fontFamily: "cl-regular"
            },
            colorPrimary: {
                color: "#2c74ff",
            }
        },
        MuiButton: {
            root: {
                fontFamily: "cl-regular",
                '&:hover': {
                    backgroundColor: 'unset',
                },
            },
            textPrimary: {
                color: "#2c74ff!important",
            }
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: "#2c74ff!important",
            }
        },
        MuiOutlinedInput: {
            root: {
                borderWidth: "1px!important",
                borderColor: "yellow!important",
                '&$focused $notchedOutline': {
                    borderColor: "#2c74ff!important",
                },
                '&$error  $notchedOutline': {
                    borderColor: "#ff606f!important",
                }
            },
            notchedOutline: {
                borderWidth: "1px!important",
                borderColor: "#c2cfe0!important",

            },

            input: {
                padding: "11px 14px",
                height: "18px",
                fontSize: "13px",

            }
        },
        MuiTextField: {
            root: {
                background: "#FFF",
            }
        },
        MuiIconButton: {
            root: {
                padding: "5px",
                color: "#2c74ff"
            }
        }
    }
};
export default theme;
export default {
    FIRST_STEP:{
        TOOLTIP:'<b>Share options</b> give the option holder the right to buy shares in your company in the future. Find out more about the difference between shares and options. ',
        TITLE:'Do they have share options?',
        SUBTITLE: "Add share options",
        TOOLTIP_CANCEL:'Giving team members and directors options to buy shares in the future is a great way to incentivise and motivate them. '
    },
    SECOND_STEP:{
        TOOLTIP:'',
        TITLE:'Select the companies in which they have share options',
        SUBTITLE:"Add share options"
    },
}
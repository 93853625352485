import React, {Component} from 'react'
import {ButtonModalOptions, ShareDeleteForm, Table} from "../../../../index";
import classes from './EquityTable.module.scss';
import * as _ from 'lodash';
import ModalShare from '../../../../Modal/ModalShare/ModalShare';

import {deleteShareIncentivePlan, upsertShareClassIncentivePlan} from '../../../../../graphql/mutations/equity';
import {ServerConnect} from '../../../../../utils';

class ShareIncentivePlansTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showEdit: false,
            equityBelong: props.equityBelong,
            showDelete: false,
            sipSelected: {}
        }
    }

    countEquityByShareIncentivePlans = equities => {


        let shareIncentiveP = [];
        let shareIncentivePlans = _.flatten(_.compact(this.props.shareIncentivePlans))
        //map shareIncentivePlans in Equity
        equities.forEach(eq => {
            if (!_.some(shareIncentiveP, eq.shareIncentivePlan)) {
                shareIncentiveP.push({
                    ...eq.shareIncentivePlan,
                    totalEquity: 1
                })
            }
            else {
                shareIncentiveP[_.findIndex(shareIncentiveP, eq.shareIncentivePlan)].totalEquity++;
            }


        });

        //when is to Group Company can have share Class with 0 equity
        if (!_.isEmpty(shareIncentivePlans)) {
            _.forEach(shareIncentivePlans, sip => {
                if (!_.some(shareIncentiveP, { "id": sip.id })) {
                    shareIncentiveP.push({
                        ...sip,
                        totalEquity: 0
                    })
                }
            })

        }
        console.log(_.filter(shareIncentiveP, sip => sip.id))
        return _.filter(shareIncentiveP, sip => sip.id);

    }

    handleEditShare = (row) => {
        this.setState({
            showEdit: true,
            type: "edit",
            sipSelected: row
        })

    }
    handleClose = props => {
        this.setState({
            ...props
        })
    }

    handleCloseDeleteModal = redirect => {
        this.setState({
            showDelete: false
        });
    };
    handleDelete = (row) =>{
        this.setState({
            showDelete:true,
            sipSelected: row.id
        })
    }

    handleSubmit = ( values ) =>{
        
        this.handleClose();
        this.props.showLoading(true);
        
        let shareClass = _.find(this.props.shareClass, sc =>{
                return _.some(sc.shareIncentivePlans,{ id: this.state.sipSelected.id })
        })
   
        let variables = {
            startupId: this.props.startupId,
            shareIncentivePlan: {
                name: values.name,
                shareClassId: shareClass.id,
                id: this.state.sipSelected.id
            }
        }
        ServerConnect.graphQlMutation(upsertShareClassIncentivePlan, variables)
            .then(result =>{
                this.props.refreshData();
            })

    }

    handleSubmitDelete = () =>{


        this.props.showLoading(true);
        let shareClass = _.find(this.props.shareClass, sc =>{
            return _.some(sc.shareIncentivePlans,{ id: this.state.sipSelected })
    })

        let variables ={
            startupId: this.props.startupId,
            shareClassId: shareClass.id,
            shareIncentivePlanId: this.state.sipSelected
        }
        ServerConnect.graphQlMutation(deleteShareIncentivePlan, variables)
        .then(result =>{
            this.props.refreshData();
        });

    }
    componentWillMount() {
        const { shareOptions } = this.props.data;


        this.setState({
            shareIncentivePlans: this.countEquityByShareIncentivePlans(shareOptions)
        })

    }
    render() {
        let { shareIncentivePlans } = this.state;
        let options = [];
        options.push({ label: "Edit", action: this.handleEditShare, className: `${classes.EditIcon}` })
        options.push({ label: "Delete", action: this.handleDelete, className: `${classes.DeleteIcon}`, isDelete: true })

        let fields = [
            {
                key: "name",
                label: "SIP Name",
                decorator: props => (
                    <div className={classes.letterTables}>
                        {props.row.name}
                    </div>
                ),
                className:classes.Col1

            },
            {
                label: "Equity Positions",
                decorator: props => {
                    return props.row.totalEquity > 0 ? <div>{props.row.totalEquity}</div>
                        : <div  className={classes.letterDisable}>No Equity Positions</div>

                },
                className:classes.Col2
            },
            {
                label:"Document",
                decorator:props =>
                    props.row.document?(<div className={classes.letterTables}>
                        {props.row.name}
                    </div>):null,
            },
            {
                label: "",
                decorator: props => {
                    return props.row.document ? null :
                        <span>
                            <ButtonModalOptions title={null}
                                options={_.filter(options, op => {
                                    return op.isDelete ? props.row.totalEquity === 0 : true;
                                })}
                                params={props.row} />
                        </span>
                },
                className:classes.Col3
            }

        ]
        return (
            <div className={classes.containerTables}>

                <div className={`${classes.containerTable} ${classes.shareTable}`} >
                    <Table
                        fields={fields}
                        data={shareIncentivePlans}
                        filter={this.props.filter}
                    />
                </div>

                {
                    this.state.showEdit &&
                    (<ModalShare
                        handleEditShare={this.handleEditShare}
                        handleSubmit={ this.handleSubmit}
                        handleClose={this.handleClose}
                        show={this.state.showEdit}
                        type={"edit"}
                        labelButton={"Rename"}
                        title={"Edit Share Incentive Plan Name"}
                        placeholder={"Share Incentive Plan Name"}
                        values={_.cloneDeep(this.state.sipSelected)}
                    />

                    
                )}
                {this.state.showDelete &&

                <ShareDeleteForm
                    title={"Delete this Share Incentive Plan"}
                    label={"Delete this Share Incentive Plan"}
                    handleClose={this.handleCloseDeleteModal}
                    handleSubmit={this.handleSubmitDelete}
                />

                }

            </div>
        )
    }
}
export default ShareIncentivePlansTable

import useLazyQuery from "src/hooks/custom/useLazyQuery";
import _ from "lodash";
const GET_COMPANY_DATA_QUERY = `
query getFormationCompanyDirectors($startupId: ID!, $groupCompanyId: ID!) {
  getGroupCompany(startupId: $startupId, groupCompanyId: $groupCompanyId) {
    id
    name
    formationData{
      isUsingNominee
      isUsingThirdParty
    }
  }
}`

const useGetCompanyFormationData =({ variables },config) => {
  const { manualQuery,loading, error, refetch } = useLazyQuery(
    GET_COMPANY_DATA_QUERY,
    variables,
    config,
    
  );
  return {
    loading, error, manualQuery, refetch
  };
}
export default useGetCompanyFormationData
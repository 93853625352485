import CloseDownCompanyStepForm from '../steps/CloseDownCompanyStepForm';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import WhatHappensStepForm from '../steps/WhatHappensStepForm';
import { useMemo } from 'react';

const useSteps = () => {

  return useMemo(() => ({
    
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    pincasData: {
      render: TemplateWizardSkeletonMachine,
    },
    getProduct: {
      render: TemplateWizardSkeletonMachine,
    },
    WhatHappensStepForm: {
      render: WhatHappensStepForm,
    },
    CloseDownCompanyStepForm: {
      render: CloseDownCompanyStepForm,
    },
    SaveStep: {
      render: TemplateWizardSkeletonMachine,
    },
    StrikeOff: {
      render: TemplateWizardSkeletonMachine,
    },

  }), []);
}

export default useSteps;

import React, {Component} from "react";

import classes from "./MissingInformation.module.scss";

class MissingInformation extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={classes.MissingInformation}>
                <div style={this.props.iconStyle} className={classes.Icon}>{this.props.icon}</div>
                <p className={classes.Title}>{this.props.title}</p>
                <p className={classes.SubTitle}>{this.props.subtitle}</p>
            </div>
        );
    }
}

export default MissingInformation;

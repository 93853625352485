import { useMutation, gql } from "@apollo/client";

const UPSERT_GROUP_COMPANY = gql`
  mutation upsertGroupCompany(
    $startupId: String
    $groupCompanyId: String
    $groupCompany: GroupCompanyInputType
  ) {
    upsertGroupCompany(
      startupId: $startupId
      groupCompanyId: $groupCompanyId
      groupCompany: $groupCompany
    ) {
      id
      type
      registeredNumber
      registeredAddress {
        country {
          code
        }
        city
        street
        state
        zipCode
      }
      recipientNotice {
        fullName
        address {
          country {
            code
          }
          city
          state
          street
          zipCode
        }
      }
      tasks {
        code
        status
        additionalParams {
          entityId
          entityType
        }
      }
      transactionCurrency
      extraCurrencies
      hasSameWorkingAddress
      workingAddress {
        city
        state
        street
        zipCode
        country {
          code
        }
      }
    }
  }
`;

const useUpsertProfileCompany = (options = {}) => {
  const [mutation, { ...rest }] = useMutation(UPSERT_GROUP_COMPANY, options);
  return { mutation, ...rest };
};

export default useUpsertProfileCompany;

import _ from 'lodash';
import React, { useEffect, useState, useMemo } from 'react';
import useMachine from '../../../../../../../modules/generic/context/MachineContext/useMachine';
import Button from "../../../../../../../modules/generic/components/Buttons/Button";
import useTranslate from '../../../../../../../modules/generic/hooks/useTranslate';
import TemplateWizard from "../../../../../../../modules/generic/templates/Modal/TemplateWizard";
import { clearAssistatText } from "../../../../../../../modules/generic/store/action";
import useModal from "../../../../../../../modules/generic/hooks/useModal";
import FormCompleteStakeholders from "../../forms/FormCompleteStakeholders/FormCompleteStakeholders";

/**
 * Step edit subscriptions
 * @param {*} param0
 * @returns
 */

function CompleteStakeholders({ openParam }) {

  const { next, context } = useMachine();
  const { translate } = useTranslate()
  const { closeModal: onClose } = useModal();

  const handleCancel = (values) => {
    onClose();
    clearAssistatText()
  }


  const handleCompleteSubmit = async (values) => {
    next(values)
  }

  return (
    <FormCompleteStakeholders
      buttons={{
        cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
      }}
      onCompleteSubmit={handleCompleteSubmit}
      propsTemplate={{
        title: translate('MODULES.COMPLETE_STAKEHOLDERS.STEP_1.TITLE'),
        subTitle: translate('MODULES.COMPLETE_STAKEHOLDERS.STEP_1.SUBTITLE')
      }} 
      Template={TemplateWizard}
    />
  );
}

export default CompleteStakeholders
;

import React, { FC, useRef } from "react";
import Form from "src/components/forms/Form/FormL";
import SubmitButton from "src/components/generic/Buttons/SubmitButton/SubmitButton";
import NestedList from "src/modules/generic/components/Lists/NestedList";
import FormProps from "src/modules/generic/forms/interfaces/FormProps";
import TemplateFormDefault from 'src/modules/generic/templates/Modal';
import useGetFields from "../wizard/hooks/useGetFields";
import Typography from "src/modules/generic/components/Texts/Typography";

const FormDataProtectionDeclarationPart2: FC<FormProps> = ({
  initialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
}) => {

  const refForm = useRef();
  const { getFieldsForm, getSchema } = useGetFields(2);

  return (
    <>
      <Form
        initialValues={initialValues || {}}
        schema={getSchema()}
        onlySendDirty={false}
        onSubmit={onCompleteSubmit}
        ref={refForm}
        optionsForm={{ mode: "onChange" }}
      >
        {" "}
        <Template
          buttons={{
            ...buttons,
            submit: <SubmitButton {...buttonSubmitProps}>Next</SubmitButton>,
          }}
          props={propsTemplate}
        >
          <Typography component="subTitle">The below declarations were previously made with regards to the ADGM entities data protection activities. Review and confirm or indicate any changes required (fees may apply).</Typography>
          <NestedList color={'clara-black'} data={getFieldsForm()} />
          <div>&nbsp;</div>
        </Template>
      </Form>
    </>
  );
};

export default FormDataProtectionDeclarationPart2;

import React, { useMemo } from "react";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import DetailsTabTitle from "src/components/templates/DetailsTabTitle";
import { RoleAccess } from "src/v1/components";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import Button from "src/modules/generic/components/Buttons/Button";
import useModal from "src/modules/generic/hooks/useModal";
import { Constants } from "src/v1/utils/constants";
import TwoColumnsViewer from "src/modules/generic/components/TwoColumsViewer";
import Link from "src/components/text/Link/Link.js";
import EditGeneralDetailsModal from "src/modules/startup/GroupCompany/KY/Renewals/GeneralDetailsEditModal";
import GroupCompany from "src/components/modules/clara/startups/entities/classes/GroupCompany";
import PincasIcon from "src/modules/generic/components/Icon/PincasIcon";
import * as numeral from "numeral";

function GeneralDetailsPanel({ data, isLock, isFormation, managedByClara }) {
  const { translate } = useTranslate();
  const { openModal, closeModal } = useModal();
  const { id } = data;

  const generalDetailsButtons = (
    <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
      <Button
        variant="card"
        disabled={isLock}
        children={translate("VIEW_COMPANY_TAB_BUTTON_DETAILS_EDIT")}
        onClick={() => {
          openModal(EditGeneralDetailsModal, {
            handleCloseModal: closeModal,
            initialValues: { groupCompanyId: id, isFormation, managedByClara },
          });
        }}
      />
    </RoleAccess>
  );

  const groupCompanyClass = useMemo(() => {
    if (!data) return null;
    return new GroupCompany(data);
  }, [data]);

  const purposeOfEntityMap = {
    HOLDING_COMPANY_UNDERLYING_BUSINESS: "Holding Company for an underlying business",
    PERSONAL_ASSETS_HOLDING_COMPANY: "Personal Assets Holding Company",
    OTHER: "Other",
  };

  const purposeOfEntityCode = data?.purposeOfEntity?.code;
  const purposeOfEntityLabel = purposeOfEntityCode ? purposeOfEntityMap[purposeOfEntityCode] : null;
  const purposeOfEntityDescription = (purposeOfEntityCode === "OTHER" || purposeOfEntityCode === "PERSONAL_ASSETS_HOLDING_COMPANY")
    ? data?.purposeOfEntity?.description
    : null;

  return (
    <>
      <DetailsTabTitle
        title="General details"
        buttons={generalDetailsButtons}
      />
      <TwoColumnsViewer
        itemsCol1={[
          {
            label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_COUNTRY"),
            value: _.get(data, "country.name"),
            valuePrefix: groupCompanyClass?.getFieldInMatter("country") ? (
              <PincasIcon />
            ) : null,
            disabledField: groupCompanyClass?.getFieldInMatter("country"),
          },
          {
            label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_JURISDICTION"),
            value: _.get(data, "jurisdiction"),
            valuePrefix: groupCompanyClass?.getFieldInMatter("jurisdiction") ? (
              <PincasIcon />
            ) : null,
            disabledField: groupCompanyClass?.getFieldInMatter("jurisdiction"),
          },
          {
            label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_COMPANY_TYPE"),
            value: _.get(data, "type")
              ? _.startCase(_.toLower(_.get(data, "type")))
              : "-",
            valuePrefix: groupCompanyClass?.getFieldInMatter("type") ? (
              <PincasIcon />
            ) : null,
            disabledField: groupCompanyClass?.getFieldInMatter("type"),
          },
          {
            label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_COMPANY_NUMBER"),
            value: _.get(data, "registeredNumber", "-"),
            valuePrefix: groupCompanyClass?.getFieldInMatter(
              "registeredNumber"
            ) ? (
              <PincasIcon />
            ) : null,
            disabledField: groupCompanyClass?.getFieldInMatter(
              "registeredNumber"
            ),
          },
          {
            label: translate(
              "VIEW_COMPANY_TAB_LABEL_DETAILS_INCORPORATION_DATE"
            ),
            value: _.get(data, "incorporation_date")
              ? moment(_.get(data, "incorporation_date")).format("D MMM YYYY")
              : "-",
            valuePrefix: groupCompanyClass?.getFieldInMatter(
              "incorporation_date"
            ) ? (
              <PincasIcon />
            ) : null,
            disabledField: groupCompanyClass?.getFieldInMatter(
              "incorporation_date"
            ),
          },
          {
            label: "Dissolution date",
            value: data?.dissolution_date
              ? moment(data?.dissolution_date).format(
                  "D MMM YYYY"
                )
              : "-",
            isHidden: _.get(data, "dissolution_date") === null,
          },
        ]}
        itemsCol2={[
          {
            label: "End of financial year",
            value: _.get(data, "nextFinancialYearEnd")
              ? moment(_.get(data, "nextFinancialYearEnd")).format("D MMM")
              : "-",
            isHidden: !managedByClara,
            valuePrefix: groupCompanyClass?.getFieldInMatter(
              "nextFinancialYearEnd"
            ) ? (
              <PincasIcon />
            ) : null,
            disabledField: groupCompanyClass?.getFieldInMatter(
              "nextFinancialYearEnd"
            ),
          },
          {
            label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_CURRENCY"),
            value: isFormation ? "USD" : _.get(data, "transactionCurrency"),
            valuePrefix: groupCompanyClass?.getFieldInMatter(
              "transactionCurrency"
            ) ? (
              <PincasIcon />
            ) : null,
            disabledField: groupCompanyClass?.getFieldInMatter(
              "transactionCurrency"
            ),
          },
          {
            label: translate(
              "VIEW_COMPANY_TAB_LABEL_DETAILS_AUTHORISED_SHARE_CAPITAL"
            ),
            value:
              !_.isNil(_.get(data, "authorisedShareCapital")) &&
              `USD ${numeral(_.get(data, "authorisedShareCapital")).format(
                "0,0.00"
              )}`,
            valuePrefix: groupCompanyClass?.getFieldInMatter(
              "authorisedShareCapital"
            ) ? (
              <PincasIcon />
            ) : null,
            disabledField: groupCompanyClass?.getFieldInMatter(
              "authorisedShareCapital"
            ),
          },
          {
            label: translate(
              "VIEW_COMPANY_TAB_LABEL_DETAILS_PURPOSE_OF_ENTITY"
            ),
            value: purposeOfEntityLabel,
          },
          purposeOfEntityDescription && {
            label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_PURPOSE_OF_ENTITY_DESCRIPTION"),
            value: purposeOfEntityDescription,
          },
          {
            label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_WEBSITE"),
            value: _.get(data, "linkedinURL") ? (
              <Link target={"_blank"} href={_.get(data, "linkedinURL")}>
                {_.get(data, "linkedinURL")}
              </Link>
            ) : (
              "-"
            ),
          },
        ]}
      />
    </>
  );
}

GeneralDetailsPanel.propTypes = {
  data: PropTypes.any,
  isLock: PropTypes.bool,
  isFormation: PropTypes.bool,
  managedByClara: PropTypes.bool,
};

export default GeneralDetailsPanel;

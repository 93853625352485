import React, {Component} from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';
import classes from './Loading.module.scss';
import {Modal, Text} from "../index"

/*
    Properties:
        open: property to show loading
        title: property to show upper the loading
        label: property to show under the loading
*/

class ModalLoading extends Component {

    render() {
        let { open, title, label } = this.props;

        return (
            <React.Fragment>
                <Modal open={open} showHeader={false}>
                    <div className={classes.ModalLoading}>
                        <div className={classes.contentLoading}>
                            <CircularProgress />
                        </div>
                        <div className={classes.contentText}>
                            <h1><Text uuid={title} /></h1>
                            <p><Text uuid={label} /></p>
                        </div>
                        
                        
                        
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalLoading;

import useMutation from "../../../hooks/custom/useMutation";

const mutation = `mutation setSubscriptionOptions( 
  $startupId: ID!,
  $autoRenewal: Boolean!,
  $billingCycle : String
) {
  setSubscriptionOptions(
    startupId: $startupId,
    autoRenewal: $autoRenewal,
    billingCycle: $billingCycle
  ){
    id
  }
}`;

const useSetSubscriptionsOptions = (options = {}) => {
  const [send, data] = useMutation(mutation, {...options, successMessage: "Your subscription plan has been updated"});
  return [send, data];
}

export default useSetSubscriptionsOptions;

//TODO: Falta constitucional documents
const mutation = `mutation upsertStartupProfile(
    $id: ID
    $startupData: StartupInput!
  ) {
    upsertStartup(
      id: $id
      startupData: $startupData
    ) {
      id
      name
      description   
      score
      avatar
      pendingEntities { 
        flow 
        remains 
        type
      }
      stakeholders {
        id
        fullName
        roles {
          name
          agreements {
            id
          }
          id
          groupCompany{
            id
          }
          stakeholder{
            id
          }
        }
      }
    }
  }`
const profileLite = `{
  getMe {
    _id
    user_hash
    allowedActions
    profiles{
      startup{
        id
        name
        lastHealthCheckRun
      }
      role
      allowedActions
    }
    pendingActions
    fullName
    email
    firstName
    avatar
    drafts
    register
    userRole
    startups {
      id
      name
      createdAt
      subscriptions{
        status
      }
      activeSubscriptionPlan{
        id
        code
        name
        features{
          code
          id
          name
          limit {
            ...on SubscriptionFeatureAmountLimit{
                amount
            }
            ...on SubscriptionFeatureAllowedLimit{
                allowed
            }
            ...on SubscriptionFeatureAllowedFileExtensionLimit{
                allowedExtensions
            }
          }
        }
      }
      subscriptionFeaturesUsage{
        code
        amount
      }
 
    }
  }
}`;

const profile = `{
    getMe {
      _id
      user_hash
      allowedActions
      matters{
      state
      id
      }
      lastLogin
  	  profiles{
        startup{
          id
          name
          lastHealthCheckRun
        }
        role
        allowedActions
      }
      pendingActions
      fullName
      email
      firstName
      avatar
      drafts
      register
      userRole
      startups {
        id
        createdAt
        subscriptions{
          status
        }
        statistic {
          companies
          stakeholders
          investors
          documents
          issues {
            RED
            AMBER
            GREEN
            UNKNOWN
          }
          founders
          consultants
          employees
          advisors
        }
        avatar
        name
        score
        description
        website
        accelerator
        pendingEntities { flow remains type}
        groupCompanies { id name avatar isHoldingCompany}
        activeSubscriptionPlan{
          id
          code
          name
          features{
            code
            id
            name
            limit {
              ...on SubscriptionFeatureAmountLimit{
                  amount
              }
              ...on SubscriptionFeatureAllowedLimit{
                  allowed
              }
              ...on SubscriptionFeatureAllowedFileExtensionLimit{
                  allowedExtensions
              }
            }
          }
        }
        subscriptionFeaturesUsage{
          code
          amount
        }

      }
    }
}`;
const Config = {
  mutation,
  profile,
  profileLite
}
export {
  Config
}

import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import Button from "src/modules/generic/components/Buttons/Button";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import { clearAssistatText } from "src/modules/generic/store/action";
import FormSelectGroupCompanies from "src/modules/startup/GroupCompany/Forms/FormSelectGroupCompanies";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";

function WhichCompaniesHaveSharesStep() {
  const { next, prev, context, cancel } = useMachine();
  const dispatch = useDispatch();
  const groupCompanyId = context?.initialData?.groupCompany?.id;

  const initialValues = useMemo(() => {
    return context?.initialData?.groupCompany ?? {};
  }, [context]);

  useEffect(() => {
    dispatch(clearAssistatText());
  }, []);

  const handleSubmit = async (values) => next(values);

  const handleCancel = () => cancel();

  const handlePrev = () => prev();

  return (
    <FormSelectGroupCompanies
      initialValues={initialValues}
      subTitle="Select all the options that apply."
      groupCompanyId={groupCompanyId}
      onCompleteSubmit={handleSubmit}
      Template={TemplateWizard}
      propsTemplate={{
        title: "Which incorporated companies does this company have shares in?",
        subTitle: "Review company details",
      }}
      buttons={{
        previous: (
          <Button onClick={handlePrev} variant="secondary">
            Previous
          </Button>
        ),
        cancel: (
          <Button onClick={handleCancel} variant="secondary">
            Cancel
          </Button>
        ),
      }}
    />
  );
}

export default WhichCompaniesHaveSharesStep;

/* eslint-disable react-hooks/exhaustive-deps */
import classnames from "classnames";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import statusPending from "../../../../config/statusPending";
import useSession from "src/modules/session/hooks/useSession";
import MapV3 from "../../components/Map/index";
import classes from "./classes.module.scss";

const MapView = (props) => {
  const { setPageLoaded } = useSession();

  const hasPendingCompany = useSelector((store) =>
    statusPending.pendingGroupCompanies(store, null)
  );

  const hasPendingOnboard = useSelector((store) =>
    statusPending.hasPendingOnboard(store, null)
  );

  useEffect(() => {
    setPageLoaded("switchStartup");
  }, []);

  return (
    <React.Fragment>
      <div
        className={classnames(`${classes.ContentMap} ${classes.ContentMapV4}`)}
      >
        <MapV3 empty={hasPendingCompany} openHeader={hasPendingOnboard} />
      </div>
    </React.Fragment>
  );
};

export default MapView;

import MachineEntityStakeholder from "./MachineStakeholderEntity";

const isIndividual = ({ data }) => !data.stakeholder_is_individual_or_company.isEntity;
const isCompany = ({ data }) => data.stakeholder_is_individual_or_company.isEntity;

const isClaraConcierge = ({ data }) => {
  return !!data.stakeholder_company_details.companyFormation.length;
};

const needShowWarning = ({ data }) => {
  return data.stakeholder_company_details.showWarning;
};

const needShowWarningIndividual = ({ data }) => {
  return data.stakeholder_individual_details.showWarning;
};

const isClaraConciergeIndividual = ({ data }) => {
  let isC = data.stakeholder_individual_details.isConcierge
  let limit = data.stakeholder_individual_details.isStakeholderLimit
  return limit && isC;
};

const middleSteps = {
  stakeholder_is_individual_or_company: {
    on: {
      NEXT: { target: 'stakeholder_set_individual_or_company', actions: 'setContext' },
      CANCEL: 'finish_entity'
    }
  },
  stakeholder_set_individual_or_company: {
    exit: '',
    on: {
      '': [
        { target: 'stakeholder_company_details', cond: 'isCompany' },
        { target: 'stakeholder_individual_details', cond: 'isIndividual' }
      ]
    }
  },
  stakeholder_company_details: {
    on: {
      PREVIOUS: 'stakeholder_is_individual_or_company',
      NEXT: { target: 'processing_type_company_formation', actions: 'setContext' },
      CANCEL: 'finish_entity'
    }
  },
  processing_type_company_formation: {
    always: [
      { target: 'is_corporate_stakeholder', cond: 'needShowWarning' },
      { target: 'is_more_entity' }
    ]
  },
  is_corporate_stakeholder: {
    on: {
      PREVIOUS: 'stakeholder_company_details',
      NEXT: { target: 'is_more_entity', actions: 'setContext' },
      CANCEL: 'finish_entity'
    }
  },
  stakeholder_individual_details: {
    on: {
      PREVIOUS: 'stakeholder_is_individual_or_company',
      NEXT: { target: 'processing_type_individual_formation', actions: 'setContext' },
      CANCEL: 'finish_entity'
    }
  },
  processing_type_individual_formation: {
    always: [
      { target: 'is_individual_stakeholder', cond: 'needShowWarningIndividual' },
      { target: 'is_more_entity' }
    ]
  },
  is_individual_stakeholder: {
    on: {
      PREVIOUS: 'stakeholder_individual_details',
      NEXT: { target: 'is_more_entity', actions: 'setContext' },
      CANCEL: 'finish_entity'
    }
  },
}

const guards = {
  isIndividual,
  isCompany,
  isClaraConcierge,
  isClaraConciergeIndividual,
  needShowWarning,
  needShowWarningIndividual
}
export default class MachineStakeholder extends MachineEntityStakeholder {
  constructor(id = '', firstStepComponent, lastStepComponent, individualOrCompanyStep, individualOrCompanyStakeholderComponent, StepClaraConcierge, StepClaraConciergeIndividual = null) {
    const stepsAdded = {
      stakeholder_is_individual_or_company: individualOrCompanyStep,
      stakeholder_company_details: individualOrCompanyStakeholderComponent,
      stakeholder_individual_details: individualOrCompanyStakeholderComponent,
      is_corporate_stakeholder: StepClaraConcierge,
      is_individual_stakeholder: StepClaraConciergeIndividual
    }
    super(
      middleSteps,
      stepsAdded,
      id,
      'stakeholder_is_individual_or_company',
      'stakeholder_set_individual_or_company',
      'stakeholder_set_individual_or_company',
      firstStepComponent,
      lastStepComponent,
      guards,
      {},
      true
    )
  }
}

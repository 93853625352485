import { Constants } from 'src/v1/utils/constants';
import useBusinessRules from 'src/modules/clara/hooks/useBusinessRules';
import { useMemo } from 'react';
import useSession from 'src/modules/session/hooks/useSession';

const useGuards = () => {
  const { isOwner } = useBusinessRules();
  const { session } = useSession();

  return useMemo(() => ({
    isTechLicence: (context) => {
      return context?.initialData?.groupCompany?.companyType === Constants.MODULES.FORMATION.COMPANIES_TYPE.ADGM_TSL;
    },
    isSPV: (context) => {
      return context?.initialData?.groupCompany?.companyType === Constants.MODULES.FORMATION.COMPANIES_TYPE.ADGM_SPV;
    },
  }), [isOwner, session]);
}

export default useGuards;

import { useMemo } from 'react';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import AddIncorporateCompany from '../steps/AddIncorporateCompany';
import AddMore from '../steps/AddMore';

const useSteps = () => {

  return useMemo(() => ({

    AddIncorporateCompany: {
      render: AddIncorporateCompany,
    },
    SaveStep: {
      render: TemplateWizardSkeletonMachine,
    },
    AddMoreStep: {
      render: AddMore,
    },

  }), []);
}

export default useSteps;

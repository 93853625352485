import React, {useEffect} from 'react';
import moment from "moment";
import {Log, Text} from '../../index';
import classes from "./TimeLine.module.scss"
import {Constants} from '../../../utils/constants';

const getRange = (date) => {
    const daysDiff = moment().diff(date, 'days');
    if (daysDiff === 0) {
        return Constants.AUDITLOGS_TRANSACTIONS.RANGES.TODAY
    }
    if (daysDiff === 1) {
        return Constants.AUDITLOGS_TRANSACTIONS.RANGES.YESTERDAY
    }
    if (daysDiff < 8) {
        return Constants.AUDITLOGS_TRANSACTIONS.RANGES.LASTWEEK
    }
    const monthDiff = moment().diff(date, 'months');
    if (monthDiff === 0) {
        return Constants.AUDITLOGS_TRANSACTIONS.RANGES.LASTMONTH
    }
    return Constants.AUDITLOGS_TRANSACTIONS.RANGES.OLDEST
};

const needChangeRange = (logs, i) => {
    const currentRange = logs[i] ? getRange(logs[i].date) : null;
    const prevRange = getRange(logs[i - 1].date);
    return currentRange !== prevRange;
};

export default function ({ timeLine, isSharing }) {
    const logClass = isSharing ? classes.SharingLogs : classes.logs;

    return (
        <React.Fragment>
            <div className={classes.TimeLine}>
                <div className={logClass}>
                    {timeLine.map((log, i) => {
                        return (
                            <React.Fragment key={i}>
                                {(i === 0 || needChangeRange(timeLine, i)) && (
                                    <React.Fragment>
                                        <div className={classes.header}>
                                            <div className={classes.day}>
                                                <Text uuid={"AUDITLOGS_RANGES_" + getRange(log.date)} />
                                            </div>
                                            <div className={classes.line}>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}
                                <Log isSharing={isSharing} log={log} key={i} isLast={needChangeRange(timeLine, i + 1)} />
                            </React.Fragment>
                        )
                    })}
                </div>
            </div>

        </React.Fragment>
    );
}

// actions means if you have grants to the page
const actions = {
  ADD_STARTUP: "ADD_STARTUP",
  ADD_USER: "ADD_USER",
  VIEW_USER: "VIEW_USER",
  VIEW_AUDIT_LOGS: "VIEW_AUDIT_LOGS",
  VERIFY_DOCUMENT: "VERIFY_DOCUMENT",
  ADD_DUEDILIGENCE: "ADD_DUEDILIGENCE",
  ADD_CLARA_SCORE: "ADD_CLARA_SCORE",
  EDIT_DUEDILIGENCE: "EDIT_DUEDILIGENCE",
  EDIT_CLARA_SCORE: "EDIT_CLARA_SCORE",
  DELETE_DUEDILIGENCE: "DELETE_DUEDILIGENCE",
  DELETE_CLARA_SCORE: "DELETE_CLARA_SCORE",
  MANAGE_MATTER: "MANAGE_MATTER",
  ANSWER_QUESTIONNAIRE: "ANSWER_QUESTIONNAIRE",
  VIEW_MATTER: "VIEW_MATTER",
  DOWNLOAD_DOCUMENT: "DOWNLOAD_DOCUMENT",
  DOWNLOAD_DOCUMENT_TO_EDIT: { kind: "startup", key: "DOWNLOAD_DOCUMENT_TO_EDIT" },
  UPLOAD_DOCUMENT_NEW_VERSION: { kind: "startup", key: "UPLOAD_DOCUMENT_NEW_VERSION" },
  MANAGE_SHARING: { key: "MANAGE_SHARING", kind: "startup" },
  EDIT_PROFILE: { key: "EDIT_PROFILE", kind: "startup" },
  EDIT_SHARE_CAPITAL: { key: "EDIT_SHARE_CAPITAL", kind: "startup" },
  VIEW_PROFILE: { key: "VIEW_PROFILE", kind: "startup" },
  CREATE_EDIT_COMMENT_REMARKS: "CREATE_EDIT_COMMENT_REMARKS",
  COMPLETE_MATTER_FLOW: "COMPLETE_MATTER_FLOW",
  VIEW_FILE: "VIEW_FILE",
  VIEW_KITCHEN_SINK: "VIEW_KITCHEN_SINK",
  ENVIRONMENT_CONFIG: "ENVIRONMENT_CONFIG",
  MANAGE_SUBSCRIPTIONS: { key: "MANAGE_SUBSCRIPTIONS", kind: "startup" },
  SEE_FREEMIUM_POPUP: { key: "SEE_FREEMIUM_POPUP", kind: "startup" },
  EDIT_FREEMIUM_STARTUP_REMARK: { key: "EDIT_FREEMIUM_STARTUP_REMARK" },
  SEE_CONTACT_OWNER_FOR_UPGRADE: { key: "SEE_CONTACT_OWNER_FOR_UPGRADE", kind: "startup" },
  SEE_FREEMIUM_COUNTER_LIMITS: { key: "SEE_FREEMIUM_COUNTER_LIMITS", kind: "startup" },
  VIEW_UPGRADE_DOCUMENTS: "VIEW_UPGRADE_DOCUMENTS"
}
export default actions;

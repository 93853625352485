import { createMachine } from 'xstate';

const machine =
  /** @xstate-layout N4IgpgJg5mDOIC5QFVZgE4AUCuAjANgJYDGASmFIbAC7oCG1hA9gHYB02a6AYk+gLYBiAHIBRABoAVRKAAOTWIUasZIAB6IAjABYAHGwBMAVgBsukye1GjABgDsuzQYA0IAJ5bNATjZGvf3V1tL20TOxt-AF9I11QMHAISckoaemV2AGslXgFBTFJRADVVeUV01Q0EHX1jMwsrWwcnVw8EO01fLxMbG10AZh6m42jYrgSiMgoqWgZmTOy+ITEpEoUlOYrEPr6fTXCvO1s+0J7NFq0DAzYIgKCQsJuRkDisPAnk6bS5thowWT68gVikgQKV1ioQZVtrt9ocbMdujYzu5EHZtNcuj1+oNHMMniwmBA4KoXuMklNUrNWBwuDl+KsyhtIYhtC4UW0fDs7H0DLoutpNDy+iYnqS3uSUjN0mwstQ6QzwSxNggDALDKZdEYdF4Qto+v1zghHGw+dzefzBQZhaKxuLJpKvtTfv8FeVmQg9Vc9hFEQ4ukZdIagmwuTy+ZZLdaYs9bYl7Z8qUqQWC3aBKppA+yALQdbQ2PM2IzaVlWvMWaLRIA */
  createMachine({
    id: 'AddFounderAgrement',
    initial: 'HaveDocumentStep',
    states: {
      HaveDocumentStep: {
        on: {
          NEXT: {
            target: 'EvaluateHaveDocumentStep',
            actions: ['setContextAfterStep']
          },
        }
      },
      EvaluateHaveDocumentStep: {
        always: [
          {
            cond: 'isSelectedGenerateDocument',
            target: 'HaveDocumentStep',
            actions: ['redirectToGenerateDocument'],
          },
          {
            cond: 'isSelectedUpload',
            target: 'UploadDocumentStep',
          },
          {
            cond: 'isSelectedUploadLater',
            target: 'finish',
          },
        ],
      },
      UploadDocumentStep: {
        on: {
          PREV: {
            target: 'HaveDocumentStep'
          },
          NEXT: {
            target: 'UpsertDocumentStep',
            actions: ['setContextAfterStep']
          },
        }
      },
      UpsertDocumentStep: {
        invoke: {
          src: 'upsertDocument',
          id: 'upsertDocument',
          onDone: [
            {
              target: 'finish',
              actions: ["setContextAfterInvoke"]
            }
          ],
          onError: [
            {
              target: 'UploadDocumentStep',
            }
          ],
        }
      },
      finish: {
        entry: 'stopMachine',
        type: 'final'
      }
    }
  });

export default machine;
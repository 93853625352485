import { useMemo } from 'react';
import useSession from 'src/modules/session/hooks/useSession';
import useGetStakeholder from '../graphql/useGetStakeholder';
import useReviewPersonalDetails from '../graphql/useReviewPersonalDetails';
import _ from 'lodash';
import { handleNationalitiesInitialValue, handleNationalitiesSaveValue } from 'src/utils/otherNationalitiesHelper';

const useServices = ({ initialValues }) => {
  const { startupId } = useSession();
  const { manualQuery: getStakeholder } = useGetStakeholder({ startupId, stakeholderId: initialValues?.stakeholderId }, {});
  const reviewPersonalDetails = useReviewPersonalDetails();

  return useMemo(() => ({
    initialData: async () => {
      const queryResult = await getStakeholder();
      const stakeholder = queryResult?.data?.getStakeholder;

      const result = {
        ...initialValues,
        stakeholder: {
          fullName: stakeholder?.fullName,
          dateOfBirth: stakeholder?.dateOfBirth,
          nationality: stakeholder?.nationality?.code,
          countryOfBirth: stakeholder?.birthAddress?.country?.code,
          cityOfBirth: stakeholder?.birthAddress?.city,
          profession: stakeholder?.profession,
          email: stakeholder?.email,
          phoneNumber: stakeholder?.phoneNumber,
          linkedinURL: stakeholder?.linkedinURL,
          hasNationalities: handleNationalitiesInitialValue(stakeholder?.nationalities?.none),
          nationalities: stakeholder?.nationalities?.countries?.map(n => n.country
            ? n.country.code
            : []),
          address: {
            street: stakeholder?.address?.street,
            city: stakeholder?.address?.city,
            state: stakeholder?.address?.state,
            country: stakeholder?.address?.country?.code,
            zipCode: stakeholder?.address?.zipCode,
          },
          curriculumVitae: stakeholder?.curriculumVitae ? {
            id: stakeholder?.curriculumVitae?.id,
            name: stakeholder?.curriculumVitae?.name,
            size: stakeholder?.curriculumVitae?.size,
            type: stakeholder?.curriculumVitae?.type,
          } : null,
        }
      }
      return result;
    },

    SaveStep: async (context) => {
      const { initialData, AddressForm, ConfirmForm } = context;
      const { groupCompanyId, stakeholderId } = initialData;
      const { address } = AddressForm;
      const { nationalities, hasNationalities } = ConfirmForm;

      const stakeholderData = {
        ...ConfirmForm,
        birthAddress: {
          country: ConfirmForm?.countryOfBirth,
          city: ConfirmForm?.cityOfBirth,
        },
        address: {
          ...address,
        },
        nationalities: handleNationalitiesSaveValue(nationalities, hasNationalities, _.get(initialData, "stakeholder.nationalities.countries", [])),
      }
      delete stakeholderData.countryOfBirth;
      delete stakeholderData.cityOfBirth;
      delete stakeholderData.hasNationalities;

      return await reviewPersonalDetails(groupCompanyId, stakeholderId, stakeholderData);
    },
  }), [initialValues, getStakeholder]);
}

export default useServices;
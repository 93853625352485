import React,{useEffect, useState} from "react";
import _ from 'lodash';
import ClassesModule from "./CheckOut.module.scss";
import useMachine from '../../../../../generic/context/MachineContext/useMachine';
import useFinish3DSPaymentToUpgradeStartup from "../../../../../checkout/hooks/useFinish3DSPaymentToUpgradeStartup";


const EditCard = (

  source = "STARTUP",

) => {
  const { context, next,send } = useMachine();

  const completeFinish = (data) => {

  };

  const [finishAddCard, loading] = useFinish3DSPaymentToUpgradeStartup({
    showSuccessNotification: false,
    showErrorNotification: true,
    onCompleted: completeFinish,
  });

  const STATUS = {
    success: "success",
    error: "error",
    process: "process",
    input: "input",
  };

  useEffect(() => {
    const handler = async (event) => {
      let data = event.data;
      if (typeof _.get(event, "data") == "string") {
        data = JSON.parse(event.data);
      }
      if (_.get(data, "claraPayment") === true) {
        const newStatus = _.get(data, "status", null);
        if (_.get(data, "ckoSessionId")) {
          if (newStatus === STATUS.success) {
            const ckoSessionId = _.get(data, "ckoSessionId", STATUS.input);
            await finishAddCard({ ckoSessionId, paymentSource: "STARTUP" });
            next({status: newStatus, ckoSessionId: ckoSessionId})
            return;
          }
        } 
        next({status: newStatus})
      }
    };
    window.addEventListener("message", handler);
    return () => window.removeEventListener("message", handler);
  }, []);

  const [counter, setCounter] = useState(-1);
  useEffect(() => {
    if (counter===0){
      send({
        type: 'TIMEOUT',
        payload: {}
      });
    }
  }, [counter, send])
  React.useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);
  const handleOnload = (data) => {
    setCounter(window?.env?.checkout?.timeToExpiredInSeconds ?? 60)
  }
  return (

   
          <div className={`${ClassesModule.StepCheckout}`}>
            <iframe src={_.get(context, 'addNewCard.url')} onLoad={handleOnload}></iframe>
          </div>


  );
};

export default EditCard;
export default {
  PRESUBMITTED_FORMATION_STEP: {
    TITLE: 'Before you take the leap...',
    SUBTITLE: 'Have you checked that all your added info is correct? Here’s your chance to give the details one last overview.',
    BUTTON_NEXT: 'Yes, I’m sure',
    BUTTON_PREVIOUS: 'No, let’s check',
  },
  WHATS_NEXT_STEP: {
    TITLE: 'Great! So, what’s next?',
    SUBTITLE: 'Once submitted, Clara will reach out to you and your collaborators if we need additional info or have some questions about your formation.',
    BUTTON_NEXT: 'Submit to Clara',
    BUTTON_PREVIOUS: 'Previous',
  },
}

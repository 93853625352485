import React, { FC, useMemo } from 'react'
import Bubble from '../../../../../modules/assistanceV4/components/Bubble'
import CheckList from '../../../../../modules/generic/components/Lists/CheckList';
import Typography from '../../../../../modules/generic/components/Texts/Typography'
import _ from 'lodash'
import classes from './classes.module.scss'
import useTranslate from '../../../../../modules/generic/hooks/useTranslate'

export interface InFormationCardProps {
  type?: 'map' | 'company' | 'stakeholder' | 'document',
  tasks: any,
  company: any,
  stakeholders: any
}

const InFormationCard: FC<InFormationCardProps> = ({
  stakeholders,
  type = 'map',
  company,
  ...props
}: InFormationCardProps) => {

  const { translate } = useTranslate();
  const blockItems = useMemo(() => {
    const companyStakeholders = _.get(company, "stakeholders", [])
    const stakeholdersData = _.map(companyStakeholders, stakeholder => {
      const stakeholderFull = _.find(stakeholders, (sh) => sh.id === stakeholder.id);
      return { name: _.get(stakeholderFull, "fullName"), id: _.get(stakeholderFull, "id") }
    })
    const uniqueStakeholders = _.uniqBy(stakeholdersData, (sh) => _.get(sh, "id"));
    const blockItems = [
      { name: _.get(company, "name") },
      ...uniqueStakeholders
    ]
    return blockItems;
  }, [company, stakeholders]);

  const calculatePercent = useMemo(() => {
    return _.get(company, "formationProgress", 100);
  }, [company]);

  const isPreApprovePlan = useMemo(() => {
    let result = company?.formationData?.businessPlan?.isBusinessPlanPreApproved ?? ".NORMAL"
    if (result === true) {
      result = ".PRE_APPROVED"
    }
    if (result === false) {
      result = ".NO_APPROVED"
    }
    return result;
  }, [company]);

  const progressValue = useMemo(() => {
    return company?.formationProgress ?? 0
  }, [company]);

  return (
    <>
      {/* TODO- esto no es correcto, la task no debe venir */}
      {progressValue < 100 && (
        <>
          {company?.formationData?.paymentMethod === "BANK_TRANSFER" ? (
            <>
              {company?.formationData?.isAwaitingFunds && (
                <Bubble percent={null} animation={null}>
                  <div className={classes.separation2}>
                    <Typography variant={'s'} component={'p'} weight={'regular'}>
                      <span dangerouslySetInnerHTML={{ __html: translate("MODULES.ASSISTANCEV4.ASSISTANCE_MAPS_FORMATION_BLOCK_BUBBLE4", {jurisdiction: _.get(company, "jurisdiction")}) }}></span>
                    </Typography>
                  </div>
                  <Typography variant={'s'} component={'p'} weight={'regular'}>
                    {translate(
                      'MODULES.ASSISTANCEV4.ASSISTANCE_MAPS_FORMATION_BLOCK_BUBBLE5'
                    )}
                  </Typography>
                </Bubble>
              )}
            </>
          ) : (
            <>
              {type === 'map' && _.get(company, "formationProgress", 100) === 5 && _.get(company, "jurisdictionType") === "CAYMAN" && (
                <Bubble percent={null} animation={null}>
                  <>
                    <Typography
                      variant={'s'}
                      component={'p'}
                      weight={'semibold'}
                    >
                      <b>Good news!</b> Clara has started the review of your {_.get(company, "jurisdiction")} formation.
                    </Typography>
                    <div className={classes.separation}>
                      <Typography variant={'s'} component={'p'} weight={'semibold'}>
                        {translate(
                          'MODULES.ASSISTANCEV4.ASSISTANCE_CARD_COMPANY_AWAITING_FUNDS_UNDER_REVIEW'
                        )}
                      </Typography>
                    </div>
                  </>
                </Bubble>
              )}
            </>
          )}
          <Bubble percent={calculatePercent === 100 ? null : calculatePercent} animation={calculatePercent === 100 ? 'formation' : null}>
            {type === 'document' && (
              <>
                <Typography variant={'s'} component={'p'} weight={'semibold'}>
                  {translate(
                    'MODULES.ASSISTANCEV4.ASSISTANCE_MAPS_FORMATION_BLOCK_DOCUMENT'
                  )}
                </Typography>
              </>
            )}
            {type === 'company' && (
              <>
                <Typography variant={'s'} component={'p'} weight={'semibold'}>
                  {translate(
                    'MODULES.ASSISTANCEV4.ASSISTANCE_MAPS_FORMATION_BLOCK_COMPANY'
                  )}
                </Typography>
              </>
            )}
            {type === 'stakeholder' && (
              <>
                <Typography variant={'s'} component={'p'} weight={'semibold'}>
                  {translate(
                    'MODULES.ASSISTANCEV4.ASSISTANCE_MAPS_FORMATION_BLOCK_STAKEHOLDER'
                  )}
                </Typography>
              </>
            )}
            {type === 'map' && (
              <>
                <Typography variant={'s'} component={'p'} weight={'semibold'}>
                  {_.get(company, "formationProgress", 100) === 10 ? (
                    <>
                      {translate('MODULES.ASSISTANCEV4.ASSISTANCE_TASKS_BLOCK_STEPS_' + _.get(company, "companyType") + isPreApprovePlan + '.HIT_' + _.get(company, "formationProgress", 100))}
                    </>
                  ) : (
                    <>
                      {translate('MODULES.ASSISTANCEV4.ASSISTANCE_TASKS_BLOCK_STEPS_' + _.get(company, "companyType") + '.HIT_' + _.get(company, "formationProgress", 100))}
                    </>
                  )}
                </Typography>
                <div className={classes.separation2}>
                  <CheckList
                    variant={"forming"}
                    title={translate('MODULES.ASSISTANCEV4.ASSISTANCE_TASKS_BLOCK_STEPS_' + _.get(company, "companyType") + '.STEP_' + _.get(company, "formationProgress", 100))}
                  />
                </div>
                <Typography
                  variant={'s'}
                  component={'p'}
                  weight={'semibold'}
                >
                  {translate('MODULES.ASSISTANCEV4.HIT_WAIT')}
                </Typography>
                <div className={classes.separation}>
                  {_.map(blockItems, (blockItem, index) => {
                    return (
                      <Typography
                        variant={'s'}
                        component={'p'}
                        weight={'semibold'}
                        key={index}
                      >
                        {blockItem.name}
                      </Typography>
                    )
                  })}
                </div>
                <Typography variant={'s'} component={'p'} weight={'semibold'}>
                  {translate(
                    'MODULES.ASSISTANCEV4.ASSISTANCE_MAPS_FORMATION_BLOCK_BUBBLE2'
                  )}
                </Typography>
              </>
            )}
          </Bubble>
        </>
      )}      
    </>
  )
}

export default InFormationCard
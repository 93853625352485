import { useMemo } from 'react';


const useActions = () => {

  return useMemo(() => {

  }, []);
}

export default useActions;

import React, { Component } from "react";
import FormikHelper from "../../../utils/formikHelper";
import classes from './TextAreaInput.module.scss';
import { TooltipInput } from "../../../components";
import { helper } from "../../../utils";
import _ from "lodash"
class TextAreaInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFocused: false,
            mounted:false
        };
    }
    handleOnBlur = (e) => {
        const { field, form, trim = true } = this.props;
        // Remove all spaces at start and end of the string
        if (trim) {
            e.target.value = e.target.value.trim();
            FormikHelper.setValueInTheCorrectPosition(field.name, form, e.target.value);
            field.onChange(e);
        }

        this.setTooltipVisibility(false);
        try {
           
            form.validateField(field.name)
        } catch (e) {

        }

    };
    hasError() {
        const { form, field } = this.props;
        return FormikHelper.returnErrorsForValidation(field.name, form.errors);
    }

    getError() {
        const { form, field } = this.props;
        return FormikHelper.returnErrorsForValidation(field.name, form.errors);

    }


    setTooltipVisibility = (isFocused) => {
        this.setState({
            isFocused
        })
    };

    showRequiredOrNot = (condition) => {
        if (condition !== false) {
            return (
                this.props.required &&
                <span style={{ color: 'red' }}>*</span>
            )
        } return (
            null
        )
    }
    componentDidUpdate(nextProps,nexState) {
        
        if (_.get(this.props,"mounted")==true && _.get(this.props,"field.value") && _.get(this.props,"field.value")!=="") {
            const { field, form } = this.props;
            this.setState({mounted:false});
           
            try{
                form.validateField(field.name)
                }catch(e){
                    
                }
        }
    }

    componentWillMount(nextProps) {
       
        this.setState({mounted:true});
    }
    render() {
        const { tooltip, className } = this.props;

        return (
            <div className={`${classes.InputContainer} ${className}`} >
                <div className={classes.TextInput}>
                    <label>{this.props.label || ""}{this.showRequiredOrNot(this.props.showRequired)}</label>
                    <div className={classes.contentOnlyInput}>
                        <TooltipInput label={tooltip}>
                            <textarea
                                className={this.hasError() ? classes.Error : null}
                                {...this.props.field}
                                placeholder={helper.getTranslateTextByKey(this.props.placeholder)}
                                onFocus={() => this.setTooltipVisibility(true)}
                                onChange={(e) => {
                                    if (this.props.trim !== false) {
                                        e.target.value = e.target.value.trimStart();
                                    }
                                    const { field, form } = this.props;
                                    try {
                                        
                                        form.validateField(field.name)
                                    } catch (e) {

                                    }
                                    this.props.field.onChange(e);
                                }}
                                onBlur={(e) => this.handleOnBlur(e)}
                                rows="4"
                                cols="47"
                                style={{ resize: 'none' }}
                                hasError={this.hasError() ? "true" : "false"}
                            />
                        </TooltipInput>
                        {this.hasError() ? (
                            <div className={classes.MessageError}>
                                {this.getError()}
                            </div>
                        ) : null}

                        {this.props.rightLabel ?
                            (
                                <div className={classes.rightLabel}>{this.props.rightLabel}</div>
                            ) : null
                        }
                    </div>
                </div>

            </div>
        );

    }
}

export default TextAreaInput;

import React, { FC } from "react";
import AssistanceFrame2 from "./";
import MyAccountTaskComponent from "./MyAccountTaskComponent/MyAccountTaskComponent";

/**
 * Assistance Frame for MyAccount
 */

export interface AssistanceMyAccountProps {

}

const AssistanceMyAccount: FC<AssistanceMyAccountProps> = ({
  ...props
}: AssistanceMyAccountProps) => {

  
  
  return (
    <AssistanceFrame2 
      tasksComponent={MyAccountTaskComponent}
      showTask={true}
    ></AssistanceFrame2>
  );
};

export default AssistanceMyAccount;

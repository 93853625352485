import React from "react";
//import InputBase from '@material-ui/core/InputBase';
import classes from "./AutocompleteSelect.module.scss";
import {ClaraSelect} from "../../../v1/components";
import _ from "lodash"
import {useSelector} from 'react-redux';

const AutocompleteSelect = ({ autocompleteKey, fieldId, fieldLabel, callbackUpdate }) => {

    const autocomplete = useSelector(state => {
        return _.get(state, `autocomplete.autocompletes.${autocompleteKey}`)
    });


    const options = {
        fieldId: fieldId ? fieldId : "id",
        fieldLabel: fieldLabel ? fieldLabel : "label",
    }


    const getCurrentData = (autocomplete) => {
        let newData = [];

        _.forEach(_.get(autocomplete, "data", []), (e) => {
            if (e[options.fieldId]) {
                var index = _.findIndex(newData, (currentElement) => {
                    return currentElement.id == e[options.fieldId];
                });

                if (index>=0) {
                    newData[index].data = _.merge(newData[index].data, e);
                } else {
                    if(e[options.fieldLabel]){
                        newData.push({ id: e[options.fieldId], label: e[options.fieldLabel], data: e });
                    }
                    
                }
            }

        });
        return newData;
    }
    const updateValue = (value, addionalDAta, form, paramsToCallbacks, field, element) => {
        callbackUpdate(element, value)
    }
    return (
        <React.Fragment>
            <div className={`${classes.AutocompleteSelect}`}>
                <ClaraSelect
                    required={false}
                    label={"Existing individuals"}
                    placeholder={"Select from the list or enter a new person below..."}
                    lists={getCurrentData(autocomplete)}
                    mode={"classic"}
                    disabled={false}
                    field={{ value: null }}
                    callbacks={{ updateValues: updateValue }}
                    autocomplete={"off_1"}
                    tooltip={"You can select existing individuals"}
                    className={classes.claraSelect}
                />
            </div>
        </React.Fragment>
    )
}

export default AutocompleteSelect


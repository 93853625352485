import useMutation from "src/hooks/custom/useMutation";

export const UPSERT_GROUP_COMPANY_AUTHORISED_SIGNATORY = `
mutation upsertGroupCompany(
    $startupId: String
    $groupCompanyId: String
	$groupCompany: GroupCompanyInputType
  ) {
    upsertGroupCompany(
      startupId: $startupId
      groupCompanyId: $groupCompanyId
      groupCompany: $groupCompany
    ) {
      id
  }
}
`;

const useUpdateGroupCompanyReviewAuthorisedSignatory = () => {
  const [mutation] = useMutation(UPSERT_GROUP_COMPANY_AUTHORISED_SIGNATORY, {
    showSuccessNotification: true,
  });
  return mutation;
};

export default useUpdateGroupCompanyReviewAuthorisedSignatory;

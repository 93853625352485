import _ from 'lodash'
import React, { FC, ReactNode, useEffect, useState } from 'react'
import CheckboxButton from "./../CheckboxButton"
import InputInterface from './../Input/InputInterface';
import ClassNames from 'classnames';
import cls from './classes.module.scss';
export class CheckboxButtonOptions<Value = any, ComponentProps = any> {
  public value: Value
  public label?: string
  public component?: ReactNode
  public componentProps?: ComponentProps
  public disabled?: boolean
  public visible?: boolean
}


type SizeButton = "xs" | "md" | "lg" | "auto" | "full" | "size2";
export interface CheckboxProps extends InputInterface {
  /**  Option's List */
  options: Array<CheckboxButtonOptions>
  /**  Array value component */
  value: any
  /**  Default component */
  defaultValue: any,
  classNameButton: object,
  size?: SizeButton
  /*If you want to be able to choose multiple people*/
  isMultiple: boolean
}
/**
- Checkbox component to show labels with inputs
**/
const CheckboxButtonSimple: FC<CheckboxProps> = ({ value, defaultValue, onChange, size = "md", classNameButton, className, isMultiple = false, ...props }: CheckboxProps) => {
  const [localValue, setLocalValue] = useState(value || defaultValue || null);
  const [checkboxValue, setCheckboxValue] = useState([]);

  useEffect(() => {
    if (!_.isEqual(value, localValue) && !_.isNil(value)) setLocalValue(value)
  }, [JSON.stringify(value)])

  useEffect(() => {
    if (isMultiple) setCheckboxValue(localValue)
    else setCheckboxValue(localValue !== null ? [localValue] : []);
  }, [localValue])


  const handleClick = (option: CheckboxButtonOptions, isSelected: boolean) => {
    if (isMultiple) {
      setLocalValue(pre => {
        let values
        if (isSelected) values = _.filter(pre, value => value !== option.value)
        else values = [...pre, option.value]
        onChange && onChange(values)
        return values
      })
    }
    else {
      var auxLocalValue = ''
      auxLocalValue = option.value;
      setLocalValue(auxLocalValue)
      onChange && onChange(auxLocalValue)
    }
  }

  return (
    <React.Fragment>
      <CheckboxButton
        className={className}
        classNameButton={ClassNames(classNameButton, cls[`button_size_width_${size}`])}
        value={checkboxValue}
        onChange={() => { }}
        defaultValue={[]}
        handleSelect={handleClick} {...props}
      />
    </React.Fragment>
  )
}

export default CheckboxButtonSimple

import React from 'react'
import _ from 'lodash';
function useContenfull() {

    const mapLineBreak = (text: string) => {
        return text.replaceAll('\n', '<br/>');
    }


    const mapDataOfContenful = (pages, pathFields) => {
        return _.map(pages, ({ fields }) => {

            const values = _.pick(fields, pathFields);
            return { ...values, description: mapLineBreak(values.description), topMedia: _.get(values, 'topMedia.fields.file.url') };
        })
    }

    const getDataTour = (data) => {
        return mapDataOfContenful(data, ['subtitle', 'description', 'lottieAnimation', 'topMedia']);
    }

    const getDataFeactures = (data) => {
        return _.map(data, ({ fields }) => {
            const values = _.pick(fields, ['subtitle', 'description', 'lottieAnimation', 'RightImage']);
            return { ...values, description: mapLineBreak(values.description), topMedia: _.get(values, 'RightImage.fields.file.url') };
        })
    }



    return { getDataTour, getDataFeactures };
}

export default useContenfull




import React, { FC, ReactNode } from 'react';
import useModal from 'src/modules/generic/hooks/useModal';
import Wizard from '../../../generic/components/Wizard/v2';
import useGuards from './hooks/useGuards';
import useSteps from './hooks/useSteps';
import useServices from './hooks/useServices';
import useActions from './hooks/useActions';
import machine from './machine';
import ModalDelete from "../../../generic/components/Modal/ModalDelete";
interface ModalDeleteProps {
  paramsMutation: any,
  onClose(): void,
  children: ReactNode,
  open: boolean,
}

const DeleteModal: FC<ModalDeleteProps> = ({ children, open, ...props }: ModalDeleteProps) => {
  const { closeModal: onClose } = useModal();
  const guards = useGuards();
  const steps = useSteps();
  const actions = useActions();
  const services = useServices();

  const handleClose = () => {
    onClose();
  }

  const onCancelMachine = (c, v) => {
    console.log(c, v)
  }

  return (
    <ModalDelete open={open}>
      <Wizard
        guards={guards}
        machine={machine}
        steps={steps}
        services={services}
        actions={actions}
        onStopMachine={handleClose}
        onCancelMachine={onCancelMachine}
      />
    </ModalDelete>
  );
}

export default DeleteModal;
import _ from 'lodash';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../../../generic/components/Buttons/Button';
import { StepProps } from "../../../../generic/components/Wizard/Step";
import useCustomMachine from '../../../../generic/hooks/useCustomMachine';
import TemplateWizard from '../../../../generic/templates/Modal/TemplateWizard';
import useSession from '../../../../session/hooks/useSession';
import Form from "../../Forms/FormPositionDepartment";
import { getValuesMutation } from '../utils';
import useUpdateStakeholder from "src/modules/startup/onBoarding/core/graphql/mutations/useUpdateStakeholder";

const Step2: FC<StepProps> = () => {

  const { next, send, state, prev } = useCustomMachine();
  const { numbersOfIteration, companies, data } = state.context;
  const { id: stakeholderId } = useParams<any>();
  const { startupId } = useSession();

  const { updateStakeholder } = useUpdateStakeholder({}, {});

  const handleCompleteSubmit = async values => {
    if (companies.length === (numbersOfIteration + 1) && !values.position) {
      const lastValues = {
        ...data,
        select_positions_department: values
      };
      const variables = {
        startupId,
        stakeholderId,
        stakeholderData: {
          stakeholder: getValuesMutation(lastValues, state)
        }
      };
      await updateStakeholder({ variables })
      send("CANCEL");
    }
    !values.position ? send({ type: "NEXT_NO_POSITIONS", payload: { idState: "select_positions_department", values } }) : next(values);
  }

  const handleCancel = async values => {
    send("CANCEL");
  }

  const handlePrev = () => {
    prev()
  }

  return (
    <React.Fragment>
      <Form onCompleteSubmit={handleCompleteSubmit} Template={TemplateWizard} propsTemplate={{
        title: 'Now for a few more details...',
        subTitle: "Roles and Positions"
      }}
        initialValues={_.get(state, 'context.data.select_positions_department', {})}
        buttons={{
          cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button>,
          previous: <Button onClick={handlePrev} variant="secondary">Previous</Button>
        }}>
      </Form>
    </React.Fragment>
  )
}

export default Step2;

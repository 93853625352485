import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import * as Yup from 'yup';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import Typography from "src/modules/generic/components/Texts/Typography";
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import classesModule from "./classes.module.scss";
import { HiddenFieldForm } from "src/components/forms/contents";

function ConfirmForm() {
  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.ConfirmForm ?? {}
  }, [context]);

  const handleCancel = (values) => {
    cancel();
  }

  const handleSubmit = async (values) => {
    next(values)
  }

  useEffect(() => {
    dispatch(addAssistantText(translate('MODULES.RENEWALS.BENEFICIAL_OWNERS.CONFIRM_FORM.TOOLTIP')));
    return () => {
      dispatch(clearAssistatText())
    }
  }, [dispatch]);

  const options = [
    {
      value: false,
      label: "No",
      componentProps:{
        weight: "bold",
      }
    },
    {
      value: true,
      label: "Yes",
      componentProps:{
        weight: "bold",
      }
    },
  ];

  const options2 = [
    {
      value: false,
      label: "No",
      componentProps:{
        weight: "bold",
      }
    },
    {
      value: true,
      label: "Yes",
      componentProps:{
        weight: "bold",
      }
    },
  ];

  const schema = Yup.object().shape({
    accept: Yup.boolean().required("Required"),
    accept2: Yup.boolean().when('accept', {
      is: false,
      then: Yup.boolean().required("Required"),
      otherwise: Yup.boolean().notRequired(),
    }),
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: translate('MODULES.RENEWALS.BENEFICIAL_OWNERS.GENERIC.TITLE'),
          skeleton: null,
          subTitle: translate('MODULES.RENEWALS.BENEFICIAL_OWNERS.GENERIC.SUBTITLE'),
          variant: 'simple'
        }}

        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('BUTTON_NEXT')}</SubmitButton>,
        }}
      >
        <>
          <Typography component='p' variant='xbody' weight="bold">
            {translate('MODULES.RENEWALS.BENEFICIAL_OWNERS.CONFIRM_FORM.TEXT1')}
          </Typography>
          <ControllerInput
            render={CheckboxButtonSimple}
            className={classesModule.CheckboxButton}
            classNameButton={classesModule.buttonCheckBox2}
            name="accept"
            options={options}
            onChange={(value) => { }}
          />
          <HiddenFieldForm
            fieldsWatch="accept"
            conditionHideCallback={(value) => {
              if (value === undefined) {
                return false;
              }
              return !value
            }}
          >
            <Typography component='p' variant='xbody' weight="bold">
              {translate('MODULES.RENEWALS.BENEFICIAL_OWNERS.CONFIRM_FORM.TEXT2')}
            </Typography>
            <ControllerInput
              render={CheckboxButtonSimple}
              className={classesModule.CheckboxButton}
              classNameButton={classesModule.buttonCheckBox2}
              name="accept2"
              options={options2}
              onChange={(value) => { }}
            />
          </HiddenFieldForm>
        </>
        <div>&nbsp;</div>
      </TemplateWizard>
    </Form>
  );
}

export default ConfirmForm;


import React, { FC, useEffect } from 'react'
import TemplateWizard from "../../../../generic/templates/Modal/TemplateWizard";
import { StepProps } from "../../../../generic/components/Wizard/Step";
import useTranslate from "../../../../generic/hooks/useTranslate";
import useCustomMachine from "../../../../generic/hooks/useCustomMachine";
import Button from "../../../../generic/components/Buttons/Button";
import { addAssistantText, clearAssistatText } from "../../../../generic/store/action";
import FormThereIsLinkedInUrl from "../../Forms/FormThereIsLinkedInUrl";
import { useDispatch } from "react-redux";

const FirstStep: FC<StepProps> = () => {
  const { translate } = useTranslate()
  const { next, send, stepData } = useCustomMachine()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clearAssistatText())
    dispatch(addAssistantText(translate('MODULES.STAKEHOLDER.PROFESSIONAL_HISTORY.FIRST_STEP.TOOLTIP')))
  }, [])

  const handleCancel = () => {
    clearAssistatText()
    send('CANCEL')
  }

  const onSubmit = (values) => {
    clearAssistatText()
    next(values)
  }

  return (
    <FormThereIsLinkedInUrl
      initialValues={stepData}
      Template={TemplateWizard}
      onCompleteSubmit={onSubmit}
      propsTemplate={{
        title: translate('MODULES.STAKEHOLDER.PROFESSIONAL_HISTORY.FIRST_STEP.TITLE'),
        subTitle: translate('MODULES.STAKEHOLDER.PROFESSIONAL_HISTORY.FIRST_STEP.SUBTITLE')
      }}
      buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }}
    />
  )
}
export default FirstStep
import React, { FC } from 'react'
import classes from '../styles.module.scss'
import moment from 'moment'
import  capitalize  from 'lodash/capitalize'
import  startCase  from 'lodash/startCase'
import { currencyFormat,amountFormat } from 'src/v1/containers/Forms/generateDocumentForm/FormsGenerate/helper'
interface Props {
    share: any;
}
const detailsKeys = ['issuedDate',
    'shareClass',
    'numberOfShares',
    'pricePerShare',
    'startingShareNumber',
    'endingShareNumber',
    'vestingTerms',
    'vestingStartDate',
    'vestingPeriod',
    'cliffPeriod']
const BodyDetailsShare:FC<Props> = ({share}) =>{
	const getValueByKey = (d,share)=>{
		const keyValue = {
			issuedDate:()=>share.issuedDate?moment(share.issuedDate).format('DD MMM YYYY'): '-',
			shareClass:()=>share.shareClass.name,
			numberOfShares:()=>amountFormat(share.quantity),
                pricePerShare:()=>currencyFormat(share.sharePrice, { defaultEmpty: 0 }),
                startingShareNumber:()=>amountFormat(share.shareNumbersIssued?.startingShareNumber),
                endingShareNumber:()=>amountFormat(share.shareNumbersIssued?.endingShareNumber),
			vestingStartDate:()=>share.vestingSchedule?.vestingStartDate?moment(share.vestingSchedule?.vestingStartDate).format('DD MMM YYYY'):'-',
			vestingTerms:()=>share.vestingSchedule?.vestingTerm?.name || 'None',
			vestingPeriod:()=>share.vestingSchedule?.vestingTerm?.standardTerm?.vestingPeriod || '-',
			cliffPeriod:()=>share.vestingSchedule?.vestingTerm?.standardTerm?.cliffPeriod || '-',
			vestingFrequency:()=>s.vestingSchedule?.vestingTerm?.standardTerm?.vestingFrequency
		}
		return keyValue[d]?keyValue[d]():share[d]
	}
	return <div className={classes.detailsShare}>
		{detailsKeys.map((d,index)=>!share.vestingSchedule &&  (d === 'vestingPeriod' ||  d === 'cliffPeriod' || d === "vestingStartDate" || d === 'vestingFrequency')?null:(
		<div className={classes.detailsColumn} id={`${index}`}>
			<div className={classes.shareLabel}>
				{d==='cliffPeriod'?'Cliff period (months)':capitalize(startCase(d))}
			</div>
			<div className={classes.shareDetails}>
				{getValueByKey(d,share)}
			</div>
		</div>
		))
		}
	</div>
}
export default BodyDetailsShare
import React, {useEffect, useState} from 'react';
import {ServerConnect} from "../utils";
import {Constants} from "../utils/constants";
import helper from "../utils/helper";
import mustache from "mustache";
import _ from "lodash"

const query = `{
  getTimeline ( {{{filters}}})   {
   _id		
   date
    user {
      fullName
      firstName
      lastName
    }
    startup{
      name
    }
    transaction{
      label
      constant
    }
    labelValues
  }
}`
export default function useTimeline(newPage, newFilters) {
  const [timeLine, setTimeLine] = useState([]);
  const [limit, setLimit] = useState(Constants.CONFIG.AUDIT_INFINITE_SCROLL_LIMIT_DEFAULT);
  const [cursorId, setCursorId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState(null);
  const [page, setPage] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(null);
  
  useEffect(() => {
    let reload = false;
    let currentCursorId = cursorId;
    if (page !== newPage) {
      reload = true;
    }
    if (!_.isEqual(newFilters, filters)) {
      reload = true;
      currentCursorId=null;
      setTimeLine([]);
      setCursorId(null);
    }
    if (reload) {
      setFilters(newFilters);
      setPage(newPage);
      setLoading(true);
      const filtersQuery = {
        startups: _.get(newFilters,"startups.length",0)>0?newFilters.startups:null,
        stakeholders: _.get(newFilters,"stakeholders.length",0)>0?newFilters.stakeholders:null,
        groupCompanies: _.get(newFilters,"groupCompanies.length",0)>0?newFilters.groupCompanies:null,
        transactions: _.get(newFilters,"transactions.constant.length",0)>0?newFilters.transactions.constant:null,
        dateFrom: newFilters.dateFrom,
        dateTo: newFilters.dateTo,
        limit,
        cursorId:currentCursorId
      }
      const queryParams = mustache.render(query, {filters:helper.jsonToStringParameters(filtersQuery)});
      ServerConnect.graphQlQuery(queryParams).then(result => {
        let timeLinesServerLocal = newPage === 0?[]:timeLine;
        timeLinesServerLocal = timeLinesServerLocal.concat(result.getTimeline);
        setTimeLine(timeLinesServerLocal);
        setHasNextPage(result.getTimeline.length>0);
        setLoading(false);
        if (result.getTimeline.length>0){
          setCursorId(result.getTimeline[result.getTimeline.length-1]._id)
        }
        
      });
    }

  },[newFilters,newPage]);

  return { timeLine, loading, hasNextPage };
}
import { default as React, useMemo, useEffect } from "react";
import * as Yup from "yup";
import { useDispatch } from 'react-redux';
import { clearAssistatText } from 'src/modules/generic/store/action';
import Form from "src/components/forms/Form/Form";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import Button from "src/modules/generic/components/Buttons/Button";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import classesModule from "./classes.module.scss";

function HasOperatingCompany() {
  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return {};
  }, [context]);

  const handleCancel = () => {
    cancel();
  };

  const handleSubmit = async (values) => {
    next(values);
  };

  const schema = Yup.object().shape({
    hasOperatingCompany: Yup.boolean().required("Required"),
  })

  useEffect(() => {
    return () => {
      dispatch(clearAssistatText())
    }
  }, [dispatch]);

  const options = [
    {
      value: false,
      label: "No, not yet",
      componentProps:{
        weight: "bold",
      }
    },
    {
      value: true,
      label: "Yes, I have",
      componentProps:{
        weight: "bold",
      }
    },
  ];

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: "Do you have an operating company already incorporated?",
          skeleton: null,
          subTitle: "Nature of underlying business",
          variant: "simple",
        }}
        buttons={{
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("BUTTON_CANCEL")}
            </Button>
          ),
          submit: (
            <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>
              {translate("BUTTON_NEXT")}
            </SubmitButton>
          ),
        }}
      >
        <ControllerInput
          // render={CheckboxButtonSimple}
          // classNameButton={classes.checkboxButton}
          name="hasOperatingCompany"
          // variant="col_1"
          options={options}
          render={CheckboxButtonSimple}
          className={classesModule.CheckboxButton}
          classNameButton={classesModule.buttonCheckBox2}
          onChange={(value) => { }}
        />
      </TemplateWizard>
    </Form>
  );
}

export default HasOperatingCompany;

// const ButtonText = ({ title }) => {
//   return (
//     <div>
//       <Typography variant="body">{title}</Typography>
//     </div>
//   );
// };


import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from "react";
import { TooltipBox } from './../../../../../../src/v1/components';
import classesModule from "./classes.module.scss";

/**
* Component to trunc data
*/
const Truncate = ({ line, text, maxCharacters, ...props }) => {
  const [printText, setPrintText] = useState("");
  useEffect(() => {
    if (maxCharacters && text) {
      const truncateText = "...";
      const lenthTruncateText = maxCharacters - (truncateText ? truncateText.length : 0);
      if (lenthTruncateText > 0 && lenthTruncateText < text.length) {
        const newText = text.substring(0, lenthTruncateText) + truncateText;
        setPrintText(newText)
        return;
      }
    }
    setPrintText(text)
  }, [text, maxCharacters])
  
  
  const textRef = useRef();
  const [enabledTooltip,setEnabledTooltip] = useState(false);
  useEffect(()=>{
    if(textRef){
      setEnabledTooltip(textRef?.current?.scrollHeight!==textRef?.current?.clientHeight);
    }
  },[textRef?.current?.scrollHeight,textRef?.current?.clientHeight])
  return (
    <React.Fragment>
      <TooltipBox label={printText} enabled={enabledTooltip} enabledTypo={false}>
        <div ref={textRef} className={`${classesModule.Truncate}`} style={{ WebkitLineClamp: line }}>
          {printText}
        </div>
      </TooltipBox>

    </React.Fragment>
  )

}

Truncate.propTypes = {
  /** Number the lines to show */
  line: PropTypes.number.isRequired,
  /** Text to show */
  text: PropTypes.string.isRequired,
  /** Max number of charactes */
  maxCharacters: PropTypes.number.isRequired,
};
Truncate.defaultProps = {
  line: 1,
  text: "",
  maxCharacters: 80
};


export default Truncate;
import React, {useMemo} from 'react'
import _ from 'lodash'
import EditCompanyDetails from "../EditDetails/EditCompanyDetails";
import EditStakeholderDetails from "../EditDetails/EditStakeholderDetails";
import EditRolesAndServicesDetails from "../EditDetails/EditRolesAndServicesDetails";
import EditRecipientNotice from "../EditDetails/EditRecipientNotice";
import EditRolesAndServicesNoGroupDetails from "../EditDetails/EditRolesAndServicesNoGroupDetails";

const useGetEditDetails = (
	type,
	tooltips,
	field,
	title,
	initialValues,
	groupCompany,
	nameHandle) => {
    return  useMemo(()=> {
        switch (type) {
            case "stakeholderDetails":
                return <EditStakeholderDetails
                    tooltips={tooltips}
                    stakeholderField={field}
                    title={title}
                    stakeholderTitle={title}
                />;
            case "companyDetails":
                return <EditCompanyDetails
                    tooltips={tooltips}
                    companyField={field}
                    companyTitle={title}
                    showTopLevelOption={_.get(initialValues, 'company.showTopLevelOption')}
                    nameHandle={nameHandle}
                    groupCompany={groupCompany}
                />;
            case "stakeholderRoleAndServicesNoGroup":
                return <EditRolesAndServicesNoGroupDetails
                    stakeholderField={field}
                    tooltips={tooltips}
                    title={title}
                />;
            case "stakeholderRoleAndServices":
                return <EditRolesAndServicesDetails
                    stakeholderField={field}
                    tooltips={tooltips}
                    title={title}
                    groupCompany={groupCompany}
                />;
            case "recipientNoticeDetails":
                return <EditRecipientNotice
                    companyTitle={title}
                    companyField={field}
                    tooltips={tooltips}
                    title={title}
                    nameHandle={nameHandle}
                    company={initialValues.company}
                />
                default: return null;
        }
    },[type, JSON.stringify(initialValues)])
};

export default useGetEditDetails;

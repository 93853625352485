import useMachine from '../../../../../generic/context/MachineContext/useMachine';
import Form from './../../forms/FormPlaceOfBusiness';
import React, { useEffect, useState } from 'react';
import Button from "../../../../../generic/components/Buttons/Button";
import useTranslate from '../../../../../generic/hooks/useTranslate';
import { useDispatch } from "react-redux";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import { addAssistantText, clearAssistatText } from "../../../../../generic/store/action";
import useModal from "../../../../../generic/hooks/useModal";
import useSession from 'src/modules/session/hooks/useSession';
import { Constants } from "src/v1/utils";

/**
 * Step para editar los datos de SOF OTHER
 * @param {*} param0
 * @returns
 */
function PlaceOfBusiness({ openParam,isEdit }) {

  const { next, send, context, prev } = useMachine();
  const { translate } = useTranslate()
  const dispatch = useDispatch();
  const { closeModal: onClose } = useModal();
  const { startup } = useSession();

  const handleCancel = (values) => {
    dispatch(clearAssistatText())
    // setOpen(false);
    onClose();
  }

  const handleInitModal = () => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText(translate('MODULES.STAKEHOLDER.PLACE_OF_BUSINESS.STEP_1.TOOLTIP')))
  }

  useEffect(() => {
    handleInitModal()
  }, []);

  const handleCompleteSubmit = async (values) => {
    next(values)
  }
  
  return (
    <Form
      buttons={{
        cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
      }}
      onCompleteSubmit={handleCompleteSubmit}
      propsTemplate={{
        title: translate('MODULES.STAKEHOLDER.PLACE_OF_BUSINESS.STEP_1.TITLE'),
        subTitle: isEdit?translate('MODULES.STAKEHOLDER.PLACE_OF_BUSINESS.STEP_1.EDIT_SUBTITLE'):translate('MODULES.STAKEHOLDER.PLACE_OF_BUSINESS.STEP_1.SUBTITLE'),
      }} 
      Template={TemplateWizard}
    />
  );
}
export default PlaceOfBusiness;

import React from "react";
import LastStep from "../../../core/wizards/steps/LastStep/LastStep";
import useTranslate from "../../../../../generic/hooks/useTranslate";

const IndividualOrCompanyStepDirector = ({}) => {

  const { translate } = useTranslate();
  
  return (
    <>
      <LastStep
        role={"DIRECTOR"}
        tooltips={{
          isNotAnother: translate(
            "MODULES.ONBOARDINGROLES.DIRECTOR.THIRD_STEP_NOT_ANOTHER"
          ),
        }}
      />
    </>
  );
};

export default IndividualOrCompanyStepDirector;

import React, {useEffect, useMemo, useState} from 'react';
import { Avatar, ClaraSelect, Number, Text, TextInput } from '../index';
import _ from "lodash";
import PropTypes from 'prop-types';
import { Field, FieldArray } from "formik";
import classes from "./Chat.module.scss";
import { helper } from '../../utils';
import numeral from "numeral";
const ChatSharesIssues = ({ className, responseKeyName, isForm, form, field, initialValueStart, sharesAuthorized, stakeholdes }) => {
  const nameField = responseKeyName ? responseKeyName + "." : "";
  const [error, setError] = useState(null);
  const [updateValues, setUpdateValues] = useState(true)

  const paidTypes =useMemo(()=>[
    { id: true, label: helper.getTranslateTextByKey("TEXT_PAID") },
    { id: false, label: helper.getTranslateTextByKey("TEXT_UNPAID") },

  ],[]);
  const getTotalCurrentShares = (values) => {
    let total = 0;
    _.forEach(values, (element) => {
      total += parseInt(_.get(element, "shares", 0))||0;
    })
    return total;
  }
  const getTotalLeft = (field, form) => {
    if (!field.value) {
      return sharesAuthorized;
    }

    let total = getTotalCurrentShares(field.value);
    const diff = sharesAuthorized - total;
    if (diff < 0) {
      if (!error) {
        setError("QUESTIONARY_SHARES_ISSUES_ERROR")
      }

      return 0;
    }
    if (error) {
      setError(null)
    }
    return sharesAuthorized - total;
  }
  const getClassError = () => {
    if (error) {
      return classes.authorizedSharesAmountError;
    }
  }
  const getPercent = (value, field) => {
    const total = getTotalCurrentShares(field);
    if (total <= 0) {
      return 0;
    }

    const percent=(_.toInteger(value))/total
    return numeral(percent).format('0[.]00%');
  }

  useEffect(() => {

    const getInitialValues = () => {
      if (isForm) {

      }
    }
    getInitialValues();
  }, [])
  useEffect(() => {
    if (updateValues) {
      setUpdateValues(false);
      const defaultValues = {...form.values};
      _.set(defaultValues, `${field.name}.sharesAuthorized`, sharesAuthorized);
      _.forEach(stakeholdes, (stateholder, i) => {
         _.set(defaultValues, `${field.name}.shareholders[${i}].paid`,_.find(paidTypes,{id:true}).id)
         _.set(defaultValues, `${field.name}.shareholders[${i}]entityId`, stateholder.id)
      });
      form.setValues(defaultValues);
    }
  },[updateValues])
  return (
    <React.Fragment>
      <div className={`${classes.ChatQuestionnaire} ${classes.ChatAuthorizedSignatories} `}>
        <Field name={`${nameField}shareholders`}>
          {({ field, form }) => {

            return (
              <div className={isForm ? className.containerTotals : ""}>
                <p className={isForm ? "" : classes.authorizedSharesTotals}><Text uuid={"QUESTIONARY_SHARES_ISSUES_AUTHORIZED_SHARES"} /> <span>
                  <Number value={sharesAuthorized} />
                </span></p>
                <p className={`${isForm ? "" : classes.authorizedSharesTotals} `}><Text uuid={"QUESTIONARY_SHARES_ISSUES_TOTAL_LEFT"} /> <span className={getClassError()}>
                  <Number value={getTotalLeft(field, form)} />
                </span></p>
                <span className={classes.authorizedSharesError}><Text uuid={error} /></span>
              </div>
            )

          }}
        </Field>
        <div className={classes.columnTitles}>
          <label className={isForm ? className.columntitleNumShares : classes.columntitleNumShares}><Text uuid={"QUESTIONARY_SHARES_ISSUES_TITLE_N_SHARES"} /></label>
          <label className={isForm ? className.columntitlePaidUnpaid : classes.columntitlePaidUnpaid}><Text uuid={"QUESTIONARY_SHARES_ISSUES_TITLE_PAID_UNPAID"} /></label>
        </div>
        <FieldArray
          name={`${nameField}shareholders`}
          render={arrayHelpers => (
            <React.Fragment>
              {stakeholdes.map((stateholder, index) => {
                return (
                  <React.Fragment>
                    <div className={`${isForm ? className.containerRow : classes.contentField} `}>
                      <Avatar name={stateholder.label} showLabel={false} size={50} className={classes.AvatarPrice}> </Avatar>
                      <Field name={`${nameField}shareholders.${index}.shares`}>
                        {({ field, form }) => (
                          <React.Fragment>
                            <TextInput
                              showRedStar={false}
                              required={true}
                              type='number'
                              field={field}
                              label={""}
                              parseInt={true}
                              form={form}
                              
                              placeholder={""}
                              className={isForm ? className.inputContainer : classes.ChatAuthorizedSharesInput}

                            />
                          </React.Fragment>
                        )}
                      </Field>


                      <Field name={`${nameField}shareholders.${index}.shares`}>
                        {({ field, form }) => (
                          <div className={classes.percent}>
                            {getPercent(field.value, _.get(form, `values.${nameField}shareholders`), {})}
                          </div>
                        )}
                      </Field>
                      <Field name={`${nameField}shareholders.${index}.paid`}>
                        {({ field, form }) => (
                          <ClaraSelect
                            required={true}
                            field={field}
                            form={form}
                            lists={paidTypes}
                            mode={"classic"}
                            className={classes.ChatAuthorizedSharesSelect}
                          />
                        )}
                      </Field>
                    </div>
                  </React.Fragment>
                )

              })}
            </React.Fragment>
          )}
        />
      </div>
    </React.Fragment >
  );
}
ChatSharesIssues.propTypes = {
  responseKeyName: PropTypes.string.isRequired,
  initialValueStart: PropTypes.object.isRequired,

};
export default ChatSharesIssues;

import React, {Component} from 'react';
//import classes from "./DinamicForm.module.scss"
import {ButtonModalOptions, SimpleButton} from "../index";
//import "./styles/form.scss";
import classes from './botonera.module.scss'

/*
    Properties:
        botoneraClasses:{  
            container: class to container the component
        } 
        handleClose: function to execute when close de modal
        
*/
class Botonera extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    getTitle = () => {
        if (this.props.options && this.props.options.length > 1) {
            return "Add new:"
        } else {
            return null;
        }
    }
    render() {
        const { isAdd, isDelete } = this.props
        let containerClass = this.props.botoneraClasses && this.props.botoneraClasses.container ?this.props.botoneraClasses.container : classes.containerBotoneraUpload;
        return (
            <React.Fragment>
                {isAdd && <SimpleButton
                    className={classes.save}
                    onClick={(e) => { this.props.onClick(e) }}
                    disabled={this.props.disabled}
                >
                    {this.props.buttonLabel}
                </SimpleButton>}
                {!isAdd &&
                    <div className={containerClass} >
                        <div>
                            <button
                                className={classes.save}
                                onClick={(e) => { this.props.onClick(e) }}
                                disabled={this.props.disabled}
                            >
                                {this.props.buttonLabel}
                            </button>
                            <button className={classes.cancel}
                                onClick={(e) => { this.props.handleClose(e) }}>
                                Cancel
                            </button>
                        </div>
                        {this.props.options && this.props.options.length>0?
                            <div className={classes.tripleDot}>
                                <ButtonModalOptions title={this.getTitle()} options={this.props.options} />
                            </div>
                        :null}
                    </div>
                }
            </React.Fragment>


        );
    }
}

export default Botonera;


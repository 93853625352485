import React, { useEffect, useState } from "react";
import { Constants } from "src/v1/utils";
import Date from "src/components/text/Text/Date";
import DetailsTabTitle from "../DetailsTabTitle";
import ImageUpload from "src/components/inputs/ImageUpload/ImageUpload";
import { LoadingProvider } from "src/modules/generic/hooks/useLoading";
import Scrollbar from "src/components/generic/Scrollbar/Scrollbar";
import TabMenu from "src/modules/generic/components/Tab/TabMenu";
import TabsMenu from "src/modules/generic/components/Tab";
import Tag from "src/v1/components/Tag/Tag";
import TagManager from "react-gtm-module";
import Typography from "src/modules/generic/components/Texts/Typography";
import _ from "lodash";
import classNames from "classnames";
import classesModule from "./Details.module.scss";
import helper from "src/v1/utils/helper";
import logger from "src/v1/utils/logger";
import useSession from "src/modules/session/hooks/useSession";
import useTranslate from "src/modules/generic/hooks/useTranslate";

const Details = ({
  title,
  lastUpdated,
  buttons,
  buttonsTabs = {},
  onChangeAvatar,
  tabContents,
  tabs,
  avatarName,
  avatarImg,
  lock,
  showFounderTag = false,
  showIsInactive = false,
  showDissolved = false,
  indexStart = 0,
  additionalTitle = null,
  statusTag = null,
}) => {
  
  const { translate } = useTranslate();
  const [indexTab, setIndexTab] = useState(indexStart);
  const { user } = useSession();

  const handleChangeAvatar = (value, file) => {
    onChangeAvatar && onChangeAvatar(value, file);
  };

  const callTagManager = (tabName) => {
    if (tabName) {
      const userID = _.get(user, "_id");
      const firstName = _.get(user, "firstName");
      const email = _.get(user, "email");
      const tagManagerArgs = {
        dataLayer: {
          event: "TabView",
          userId: userID,
          firstName: firstName,
          path: window.location.pathname,
          pageName: document.title,
          tabName: tabName,
          userType: helper.getTypeUserByEmail(email),
        },
        dataLayerName: "PageDataLayer",
      };
      TagManager.dataLayer(tagManagerArgs);
    }
  };

  const log = (tabName) => {
    logger.info(Constants.LOGGER.ACTIONS.TAB, { tab: tabName });
  };

  const handleChangeTab = (i, tabName) => {
    if (indexTab !== i) {
      callTagManager(tabName);
      log(tabName);
      setIndexTab(i);
    }
  };

  const getTabs = () => {
    return _.map(tabs, (tab, i) => {
      const { disabled = false, show = true, component, tabName } = tab;
      if (show) {
        return (
          <TabMenu
            key={i}
            disabled={disabled}
            classes={{ root: indexTab === i ? classesModule.MuiTabRoot : null }}
            onClick={() => {
              handleChangeTab(i, tabName);
            }}
          >
            {tabName}
          </TabMenu>
        );
      } else {
        return <React.Fragment key={i} />;
      }
    });
  };

  useEffect(() => {
    if (!!!tabs[indexTab] && tabs[0]) {
      handleChangeTab(0, tabs[0].tabName);
    }
  }, [tabs[indexTab]]);

  return (
    <>
      <div className={classesModule.Details}>
        <div className={classesModule.Header}>
          <div className={classesModule.Info}>
            <div>
              <ImageUpload
                value={avatarImg}
                avatarName={avatarName}
                variant={"medium"}
                onChange={handleChangeAvatar}
                lock={lock}
              />
            </div>
            <div className={classesModule.HeaderData}>
              <div className={classesModule.tagContainer}>
                <Typography color="black" component="h5">
                  {title}
                </Typography>
                {additionalTitle}
                <Tag type="isFounderSquare" show={showFounderTag} marginLeft="0px" />
              </div>
              <div className={classesModule.headerDescriptionContainer}>
                <Typography color="dark-grey" variant="xs">
                  {translate("LAST_UPDATE")}
                  <Date value={lastUpdated} />
                </Typography>
                {showIsInactive && (
                  <Tag type={"USER_INACTIVE"} show={true} marginLeft="0px" />
                )}
                {showDissolved && (
                  <Tag type={"GC_DISSOLVED"} show={true} marginLeft="0px" />
                )}
                {statusTag}
              </div>
            </div>
          </div>
          <div className={classesModule.Botonera}>{buttons}</div>
        </div>
        <div className={classesModule.ContentTabs}>
          <div className={classesModule.TabArea}>
            <TabsMenu initValue={indexTab} tabs={getTabs()}></TabsMenu>
          </div>
          <div
            className={classNames(`${classesModule.ContentTab}`, {
              [classesModule.NoTitle]: tabs[indexTab]?.multiplePanels,
            })}
          >
            <LoadingProvider>
              {tabs[indexTab] && !tabs[indexTab]?.multiplePanels && (
                <DetailsTabTitle
                  title={tabs[indexTab].component}
                  buttons={buttonsTabs[tabs[indexTab].key]}
                />
              )}
              <Scrollbar
                className={classNames(classesModule.fitContent, {
                  [classesModule.noBottonSpace]: tabs[indexTab]?.multiplePanels,
                })}
              >
                {tabContents[indexTab]}
              </Scrollbar>
            </LoadingProvider>
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;

import { createMachine } from 'xstate';

const machine =
  /** @xstate-layout N4IgpgJg5mDOIC5QFVZgE4AUCuAjANgJYDGASmFIbAC7oCG1hA9gHYB02a6AYk+gLYBiAHIBRABoAVRKAAOTWIUasZIAB6IAjABYAHGwBMAVgBsukye1GjABgDsuzQYA0IAJ5bNATjZGvf3V1tL20TOxt-AF9I11QMHAISckoaemV2AGslXgFBTFJRADVVeUV01Q0EHX1jMwsrWwcnVw8EO01fLxMbG10AZh6m42jYrgSiMgoqWgZmTOy+ITEpEoUlOYrEPr6fTXCvO1s+0J7NFq0DAzYIgKCQsJuRkDisPAnk6bS5thowWT68gVikgQKV1ioQZVtrt9ocbMdujYzu5EHZtNcuj1+oNHMMniwmBA4KoXuMklNUrNWBwuDl+KsyhtIYhtC4UW0fDs7H0DLoutpNDy+iYnqS3uSUjN0mwstQ6QzwSxNggDALDKZdEYdF4Qto+v1zghHGw+dzefzBQZhaKxuLJpKvtTfv8FeVmQg9Vc9hFEQ4ukZdIagmwuTy+ZZLdaYs9bYl7Z8qUqQWC3aBKppA+yALQdbQ2PM2IzaVlWvMWaLRIA */
  createMachine({
    id: 'selectTopCompany',
    initial: 'getCompaniesData',
    states: {
      getCompaniesData: {
        invoke: {
          src: 'getCompaniesData',
          id: 'getCompaniesData',
          onDone: [
            {
              target: 'evaluateCompanies',
              actions: ['setContextAfterFirstInvoke']
            }
          ],
        }
      },
      evaluateCompanies: {
        always: [
          {
            cond: 'toStep1',
            target: 'topOK_jurOK',
            actions: ['setCompanyTopco'],
          },
          {
            cond: 'toStep2',
            target: 'topOK_jurNO_coOK_cojurNO',
            actions: ['setCompanyTopco'],
          },
          {
            cond: 'toStep3',
            target: 'topOK_jurNO_coOK_cojurOK',
            actions: ['setCompanyTopco'],
          },
          {
            cond: 'toStep4',
            target: 'topNO_jurNO_coOK_cojurOK',
          },
          {
            cond: 'toStep5',
            target: 'topNO_jurNO_coOK_conjurNO',
          },
        ],
      },
      // HAVE TOPCO IN JURISDICTION APPROVED
      topOK_jurOK: {
        on: {
          NEXT: {
            target: 'evaluateTopOK_jurOK',
            actions: ['setStep1ConfirmResult']
          },
        }
      },
      // HAVE TOPCO IN DISAPPROVED JURISDICTION WITH OTHERS COMPANIES IN DISAPPROVED JURISDICTION
      topOK_jurNO_coOK_cojurNO: {
        on: {
          NEXT: {
            target: 'evaluateTopOK_jurNO_coOK_cojurNO',
            actions: ['setStep1Result']
          },
        }
      },
      // HAVE TOPCO IN DISAPPROVED JURISDICTION WITH OTHERS COMPANIES APPROVED JURISDICTION
      topOK_jurNO_coOK_cojurOK: {
        on: {
          NEXT: {
            target: 'evaluateTopOK_jurNO_coOK_cojurOK',
            actions: ['setStep1Result']
          },
        }
      },
      // NO HAVE TOPCO IN DISAPPROVED JURISDICTION WITH OTHERS COMPANIES APPROVED JURISDICTION
      topNO_jurNO_coOK_cojurOK: {
        on: {
          NEXT: {
            target: 'evaluateTopNO_jurNO_coOK_cojurOK',
            actions: ['setStep1Result']
          },
        }
      },
      // NO HAVE TOPCO IN DISAPPROVED JURISDICTION NO OTHERS COMPANIES DISAPPROVED JURISDICTION
      topNO_jurNO_coOK_conjurNO: {
        on: {
          NEXT: {
            target: 'evaluateTopNO_jurNO_coOK_conjurNO',
            actions: ['setStep1Result']
          },
        }
      },
      // STEP 1
      evaluateTopOK_jurOK: {
        always: [
          {
            cond: 'step1Confirm',
            target: 'uploadTopCo',
          },
          {
            cond: 'step1Add',
            target: 'step1AddInfo',
          },
          {
            cond: 'step1Form',
            target: 'openFormFlow',
          },
        ],
      },
      // STEP 2
      evaluateTopOK_jurNO_coOK_cojurNO: {
        always: [
          {
            cond: 'step1Add',
            target: 'step1AddInfo',
          },
          {
            cond: 'step1Form',
            target: 'openFormFlow',
          },
        ],
      },
      // STEP 3
      evaluateTopOK_jurNO_coOK_cojurOK: {
        always: [
          {
            cond: 'step4Select',
            target: 'step4SelectTopco',
          },
          {
            cond: 'step1Add',
            target: 'step1AddInfo',
          },
          {
            cond: 'step1Form',
            target: 'openFormFlow',
          },
        ],
      },
      // STEP 4
      evaluateTopNO_jurNO_coOK_cojurOK: {
        always: [
          {
            cond: 'step4Select',
            target: 'step4SelectTopco',
          },
          {
            cond: 'step1Add',
            target: 'step1AddInfo',
          },
          {
            cond: 'step1Form',
            target: 'openFormFlow',
          },
        ],
      },
      // STEP 5
      evaluateTopNO_jurNO_coOK_conjurNO: {
        always: [
          {
            cond: 'step1Add',
            target: 'step1AddInfo',
          },
          {
            cond: 'step1Form',
            target: 'openFormFlow',
          },
        ],
      },
      step1AddInfo: {
        on: {
          NEXT: {
            target: 'finish',
          },
          PREV: { 
            target: 'evaluateBack',
          }
        }
      },
      step4SelectTopco: {
        on: {
          NEXT: {
            target: 'uploadTopCo',
            actions: ['setStep4ConfirmCompanyData']
          },
          PREV: {
            target: 'evaluateBack',
          }
        }
      },
      evaluateBack: {
        always: [
          {
            cond: 'toStep1',
            target: 'topOK_jurOK',
          },
          {
            cond: 'toStep2',
            target: 'topOK_jurNO_coOK_cojurNO',
          },
          {
            cond: 'toStep3',
            target: 'topOK_jurNO_coOK_cojurOK',
          },
          {
            cond: 'toStep4',
            target: 'topNO_jurNO_coOK_cojurOK',
          },
          {
            cond: 'toStep5',
            target: 'topNO_jurNO_coOK_conjurNO',
          },
        ],
      },
      // OPEN FORM MODAL
      openFormFlow: {
      },
      uploadTopCo: {
        invoke: {
          src: 'uploadTopCo',
          id: 'uploadTopCo',
          onDone: [
            {
              target: 'finish',
            }
          ]
        }
      },
      finish: {
        entry: 'stopMachine',
        type: 'final'
      }
    }
  });

export default machine;
import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import Button from "src/modules/generic/components/Buttons/Button";
import Icon from "src/modules/generic/components/Icon";
import Tabs from "src/v1/components/Tabs/Tabs";
import TypographyLocal from "src/modules/generic/components/Texts/Typography";
import useBusinessRules from "src/modules/clara/hooks/useBusinessRules";
import useEndProgram from "src/hooks/services/modules/startups/accelerators/useEndProgram";
import useSession from "src/modules/session/hooks/useSession";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import useModal from "src/modules/generic/hooks/useModal";
import { goToPage } from "src/store/actions/page";
import { Constants } from "src/v1/utils";
import EndProgramModal from "../EndProgramModal/EndProgramModal";
import AcceleratorTabs from "../AcceleratorTabs/AcceleratorTabs";
import classes from "../../AcceleratorsView.module.scss";
import _ from "lodash";

export interface AcceleratorDashboardProps {}

const AcceleratorDashboard: FC<AcceleratorDashboardProps> = ({}: AcceleratorDashboardProps) => {
  const dispatch = useDispatch();
  const { isViewer } = useBusinessRules();
  const [tab, setTab] = useState("stakeholders");
  const { translate } = useTranslate();
  const { openModal } = useModal();
  const { startup, updateStartup } = useSession();
  const selectedProgram = startup?.acceleratorDef;
  const acceleratorIsSubmitted = startup?.acceleratorData?.isSubmitted;

  const [mutationEndProgram] = useEndProgram({
    onCompleted: () => {
      const newGoals = _.filter(startup?.goals, (goal) => {
        return goal?.code !== "GOAL_ACCELERATOR";
      });

      updateStartup({
        acceleratorDef: null,
        acceleratorData: null,
        goals: newGoals,
      });
      goToPage(dispatch, Constants.PAGES.dashboard);
    },
    showSuccessNotification: true,
    successMessage: "MODULES.ACCELERATORS.PROGRAM_END_MESSAGE",
    paramsSuccessMessage: "",
  });

  const handleSubmitEndProgram = async () => {
    const variables = {
      id: startup.id,
      startupData: {
        acceleratorCode: selectedProgram.code,
      },
    };
    await mutationEndProgram(variables);
  };

  const tabs = [
    { name: "Stakeholders", key: "stakeholders" },
    { name: "Documents", key: "documents" },
    { name: "Questionnaire", key: "questionnaire" },
  ];

  const handleTabChange = (tab) => {
    setTab(tab);
  };

  const handleOpenEndModal = () => {
    openModal(EndProgramModal, {
      handleSubmit: handleSubmitEndProgram,
      program: selectedProgram,
    });
  };

  return (
    <div className={classes.Accelerators}>
      <div className={classes.joinedProgramTitleContainer}>
        <TypographyLocal className={classes.titleColor} variant="h2">
          {acceleratorIsSubmitted
            ? translate("MODULES.ACCELERATORS.PROGRAM_SUBMITTED_APPLICATION")
            : translate("MODULES.ACCELERATORS.PROGRAM_CURRENTLY_ENROLED")}
        </TypographyLocal>
        <div>
          {!isViewer() && (
            <Button variant="cardWarning" onClick={handleOpenEndModal}>
              {translate("MODULES.ACCELERATORS.PROGRAM_END")}
            </Button>
          )}
        </div>
      </div>
      <div className={classes.alreadyJoinedProgramEnrolment}>
        <div className={classes.titleContainer}>
          <TypographyLocal variant="h3" weight="bold" className={classes.title}>
            {selectedProgram?.name}
          </TypographyLocal>
          <div
            className={`${classes.status} ${
              acceleratorIsSubmitted
                ? classes.statusSubmitted
                : classes.statusNotSubmitted
            }`}
          >
            <div className={classes.statusCircle}>
              {acceleratorIsSubmitted ? (
                <div className={classes.innerDotSubmitted}>
                  <Icon icon="minitick" isClara={true} size="0.7rem" />
                </div>
              ) : (
                <div className={`${classes.innerDotNotSubmitted}`} />
              )}
            </div>
            <span className={classes.statusText}>
              {acceleratorIsSubmitted
                ? translate("MODULES.ACCELERATORS.PROGRAM_IS_SUBMITTED")
                : translate("MODULES.ACCELERATORS.PROGRAM_IS_NOT_SUBMITTED")}
            </span>
          </div>
        </div>
        <TypographyLocal variant="body" className={classes.subTitle}>
          Last updated{" "}
          {moment(selectedProgram?.updatedAt).format("DD MMM YYYY")}
        </TypographyLocal>
      </div>
      <Tabs
        tabs={tabs}
        goTo={handleTabChange}
        selected={tab}
        classesTabs={classes.Tabs}
      />
      <AcceleratorTabs currentTab={tab} />
    </div>
  );
};

export default AcceleratorDashboard;

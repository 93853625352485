
import useLazyQuery from "src/hooks/custom/useLazyQuery";


export const GET_GROUPCOMPANY = `
query companyDetailsView($startupId: ID!, $groupCompanyId: ID!) {
  getGroupCompany(startupId: $startupId, groupCompanyId: $groupCompanyId) {
    fullName
    nationality {
      name
      code
    }
    jurisdiction
    registeredNumber
    taxRegistrationNumber
    previousName
    address {
      street
      city
      state
      country {name code alpha3}
      zipCode
    }
    documents {
      name
		  id
		  kind
		  status
		  type{
        label
        code
		  }
    }
    isSeparateLegalEntity
  }
}`;

export const GET_GROUPCOMPANY_TASKCOUNT = `query getGroupCompany($startupId: ID!,$groupCompanyId: ID!,$filters: GroupCompanyFilter){
  groupCompany: getGroupCompany(startupId: $startupId, groupCompanyId: $groupCompanyId, filters: $filters) {
    id
    pendingTasksCount
  }
}`;



// New getStakeholders lazy query, need review is correct
const useGetGroupCompanyLazy = ({ variables, query = GET_GROUPCOMPANY, ...props }, config) => {
  const { manualQuery, loading, error, data, refetch } = useLazyQuery(
    query, 
    { ...variables },
    config,
    props
  );
  return {
    manualQuery, loading, error, data, refetch
  };
};

export default useGetGroupCompanyLazy;

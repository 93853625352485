import React, { FC, useEffect } from 'react';
import Button from "../../../../../generic/components/Buttons/Button";
import useCustomMachine from '../../../../../generic/context/MachineContext/useMachine';
import useTranslate from '../../../../../generic/hooks/useTranslate';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import { useDispatch } from "react-redux";
import { clearAssistatText, addAssistantText } from "src/modules/generic/store/action";
import IsThereDocument from "../../../forms/IsThereDocuments";

interface StepProps {

}

const IsThereDocumentsOptionStep: FC<StepProps> = () => {

  const { send, next, context } = useCustomMachine()
  const dispatch = useDispatch()
  const { translate } = useTranslate()

  useEffect(() => {
    dispatch(clearAssistatText())
    dispatch(addAssistantText('A shareholder register (or register of members) is a record of all the active and former owners of a company’s shares.'))
    dispatch(addAssistantText("An equity position is not validly issued without the approval of the company's board of directors."))
  }, [])

  const handlePrev = () => {
    send("PREVIOUS")
  }

  const handleCancel = () => {
    send('CANCEL')
  }

  const handleCompleteSubmit = async (values): Promise<void> => {
    next(values)
  }

  return (
    <>
      <IsThereDocument
        propsTemplate={{
          title: translate(`MODULES.EQUITY.STEPS_EQUITY_POSITIONS.IS_THERE_DOCUMENTS_STEP.TITLE_${context.type.toUpperCase()}`),
          subTitle: translate(`MODULES.EQUITY.STEPS_EQUITY_POSITIONS.IS_THERE_DOCUMENTS_STEP.SUBTITLE_${context.type.toUpperCase()}`),
        }}
        clearTooltips= {true}
        tooltips={{
          isUpload: true,
          isGenerated:true,
          isLater:true,
          isExist:'A shareholder register (or register of members) is a record of all the active and former owners of a company’s shares.',
          isNotExist:'A shareholder register (or register of members) is a record of all the active and former owners of a company’s shares.'
        }}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        buttons={{ cancel: <Button onClick={handleCancel}>Cancel</Button>, previous: <Button onClick={handlePrev}>Previous</Button> }}
      />
    </>
  )
}
export default IsThereDocumentsOptionStep

import _ from "lodash";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import AddEntity from "src/components/forms/modules/startups/Equity/Components/AddEntity";
import FormAddCompany from "src/components/forms/modules/startups/Equity/Components/FormAddCompany";
import FormAddStakeholders from "src/components/forms/modules/startups/Equity/Components/FormAddStakeholders";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import Line from "src/components/generic/Line/Line";
import CalendarInputDate from "src/components/inputs/Calendars/CalendarInputDate";
import FileUploaderAndSelector from "src/components/inputs/FileUploaderAndSelector/FileUploaderAndSelector";
import TextInput from "src/components/inputs/Text/TextInput";
import Text from "src/components/text/Text/Text";
import { useGetEditShareOption } from "src/forms/schemas/modules/startups/Equity/schema";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import FormProps from "src/modules/generic/forms/interfaces/FormProps";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import TemplateFormDefault from "src/modules/generic/templates/Modal";
import useSession from "src/modules/session/hooks/useSession";
import Form from "src/components/forms/Form/FormL";
import SelectShareIncentivePlan from "src/components/forms/modules/startups/Equity/Components/SelectShareIncentivePlan";
import SelectShareClass from "src/components/forms/modules/startups/Equity/Components/SelectShareClass";
import classes from "./styles.module.scss";
import Currency from "src/components/inputs/Currency/Currency";

interface Props extends FormProps{
  dataGroupCompanies: any[];
  dataStakeholders: any[];
  onAdd:(value:any)=>any
}
const AddOptionForm:FC<Props> = ({
    initialValues,
    dataGroupCompanies,
    dataStakeholders,
    onCompleteSubmit,
    propsTemplate,
    buttons,
    buttonSubmitProps,
    Template = TemplateFormDefault,
    onAdd,
    ...props
}) => {

  const { translate } = useTranslate();
  const [isAnEntity, setAnEntity] = useState(false);
  const { startupId } = useSession();
  const [currencyData, setCurrencyData] = useState("");
  const refSelectShareholders = useRef();
  const refSelectCompany = useRef();
  const editShareOption = useGetEditShareOption(initialValues);

  const [messageAddCopmany, setMessageAddCompany] = useState({
    open: false,
    text: "",
  });

  const [messageAddShareHolder, setMessageAddShareholder] = useState({
    open: false,
    text: "",
  });

  const stakeholderList = useMemo(
    () =>
      _.map(dataStakeholders, (st) => ({
        value: st,
        label: st.fullName,
        type: "stakeholder",
      })),
    [JSON.stringify(dataStakeholders)]
  );

  const groupCompaniesList = useMemo(
    () =>
      _.map(dataGroupCompanies, (gc) => ({
        value: gc,
        label: gc.name,
        type: "groupCompany",
      })),
    [JSON.stringify(dataGroupCompanies)]
  );

  const handleCloseMessageAddCompany = () => {
    setMessageAddCompany({ open: false, text: "" });
  };

  const handleOpenFormCompany = () => {
    setMessageAddCompany({ open: true, text: "MESSAGE_ADD_COMPANY_COMPONENT" });
  };

  const handleOpenFormStakeholderShareholder = (value) => {
    setAnEntity(value);
    setMessageAddShareholder({
      open: true,
      text: "MESSAGE_ADD_STAKEHOLDER_COMPONENT",
    });
  };

  const handleCloseMessageAddShareholder = () => {
    setMessageAddShareholder({ open: false, text: "" });
  };

  const hadleAddShareHolders = (value,formValues) => {
    onAdd && onAdd(value);
  };

  const menuPropsShareholder = {
    addList: [
      {
        label: "Add New Individual Stakeholder",
        type: "stakeholder",
        onClick: () => handleOpenFormStakeholderShareholder(false),
      },
      {
        label: "Add New Corporate Stakeholder",
        type: "stakeholder",
        onClick: () => handleOpenFormStakeholderShareholder(true),
      },
    ],
    propsList: [{ type: "stakeholder", title: "Stakeholders" }],
  };

  const formsAddShareholders = [
    {
      type: "stakeholder",
      form: FormAddStakeholders,
      handleAddValueList: hadleAddShareHolders,
      propsForm: {
        startupId,
        isAnEntity,
        handleCloseMessage: handleCloseMessageAddShareholder,
      },
    },
  ];

  const formsAddCompany = [
    {
      type: "groupCompany",
      form: FormAddCompany,
      handleAddValueList: hadleAddShareHolders,
      propsForm: {
        startupId,
        handleCloseMessage: handleCloseMessageAddCompany,
      },
    },
  ];

  const menuPropsCompany = {
    addList: [
      {
        label: "Add New Company",
        type: "groupCompany",
        onClick: handleOpenFormCompany,
      },
    ],
    propsList: [{ type: "groupCompany", title: "Group Companies" }],
  };

  const getCurrencyData =useCallback( (val) => {
    setCurrencyData(val.transactionCurrency ? val.transactionCurrency : "");
  },[setCurrencyData]);

  useEffect(()=>{
    setCurrencyData( _.get(initialValues, "groupCompany.transactionCurrency")
        ? _.get(initialValues, "groupCompany.transactionCurrency"):'')
  },[JSON.stringify(initialValues)])

  return (
    <Form
      defaultValues={initialValues}
      schema={editShareOption}
      onlySendDirty={false}
      optionsForm={{mode: "onChange"}}
      onSubmit={onCompleteSubmit}
    >
    <Template props={propsTemplate} buttons={{submit:<SubmitButton{...buttonSubmitProps}>Add</SubmitButton>,...buttons}}>

        <AddEntity
          asterisk
          callback={getCurrencyData}
          formsAdd={formsAddCompany}
          refSelect={refSelectCompany}
          list={groupCompaniesList}
          menuProps={menuPropsCompany}
          message={messageAddCopmany}
          name={"groupCompany"}
          label={"COMPANY_NAME_FIELD"}
        />
        <AddEntity
          asterisk
          formsAdd={formsAddShareholders}
          refSelect={refSelectCompany}
          list={stakeholderList}
          menuProps={menuPropsShareholder}
          message={messageAddShareHolder}
          name={"owner"}
          label={"OPTION_HOLDER_NAME_FIELD"}
        />
        <Line />
        <SelectShareClass
          name={"equity.shareClass"}
          startupId={startupId}
          list={groupCompaniesList.map((gc) => gc.value)}
          observerField={"groupCompany"}
        />
        <Line />
        <SelectShareIncentivePlan
          name={"equity.shareIncentivePlan"}
          startupId={startupId}
          list={groupCompaniesList.map((gc) => gc.value)}
          observerField={"equity.shareClass"}
        />
        <Line />
        <TemplateLabel
          label={translate('NUMBER_SHARES_FIELD')}
          isRequired={true}
        >
          <ControllerInput
            as={TextInput}
            type="number"
            name="equity.amount"
            defaultValue={""}
            placeholder={translate('MODULES.CLARA.ENTER_NUMBER')}
            onChange={(e) => {}}
          />
        </TemplateLabel>
        <TemplateLabel
          label={translate('EXCERCISE_PRICE_FIELD')}
        >
          <ControllerInput
            as={Currency}
            name="equity.price"
            defaultValue={""}
            type="share"
            currency={
              currencyData
            }
            onChange={(e) => {}}
          />
        </TemplateLabel>
        <Line />
        <TemplateLabel
          label={translate('ISSUED_DATE_FIELD')}
        >
          <ControllerInput
            as={CalendarInputDate}
            placeholder={translate('MODULES.CLARA.ROLE_DATE_INPUT_HIT')}
            name="equity.issuedDate"
            onChange={(e) => {}}
          />
        </TemplateLabel>
        <Line />
        <div className={classes.fileUploader}>
          <label>
            <Text uuid={"ASSOCIATED_DOCUMENTS_FIELD"} />
          </label>
          <div>
            <ControllerInput
              as={FileUploaderAndSelector}
              name="equity.documents"
              multipleFiles={true}
              onChange={(e) => {}}
            />
          </div>
        </div>
      </Template>
    </Form>
  );
};

export default AddOptionForm;

import PropTypes from "prop-types"
import { Fade } from "@material-ui/core";
import _ from "lodash";
import React from "react";
import classModule from "./classes.module.scss";

const SkeletonRepeat = ({
  cant = 1,
  show,
  skeletor,
  id = "skel",
  children,
}) => {

  const getSkeleton = () => {
    return _.map(new Array(cant), (a, key) => (
      <span key={key} id={id + "_" + key}>
        {skeletor}
      </span>
    ));
  };
  
  return (
    <React.Fragment>
      {show ? (
        <Fade in={show} disableStrictModeCompat={true}>
          <div className={classModule.SkeletonRepeat}>{getSkeleton()}</div>
        </Fade>
      ) : (
        <React.Fragment>{children}</React.Fragment>
      )}
    </React.Fragment>
  );
};

SkeletonRepeat.propTypes = {
  cant: PropTypes.number,
  children: PropTypes.any,
  id: PropTypes.string,
  show: PropTypes.any,
  skeletor: PropTypes.any
}

export default SkeletonRepeat;

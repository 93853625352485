import * as Yup from 'yup';
import { default as React, useEffect, useMemo } from 'react';
import { addAssistantText, clearAssistatText } from 'src/modules/generic/store/action';
import Button from 'src/modules/generic/components/Buttons/Button';
import Form from 'src/components/forms/Form/Form';
import { Stack } from '@mui/material';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateWizardInfo from "src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardInfo";
import Typography from 'src/modules/generic/components/Texts/Typography';
import classModule from "./classes.module.scss"
import reactImage from "../../../../../../../../../../images/Group2022.png";
import { useDispatch } from 'react-redux';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import Link from '@mui/material/Link';
import useFormat from 'src/modules/generic/hooks/useFormat';

function WhatHappensStepForm() {
  const {moneyFormat} = useFormat();
  const { next, context, cancel, setAction } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const Paragraph = () => {
    return <div className={classModule.Paragraph}>
      <Typography component='p' weight='bold' variant='h5'>
        {translate('MODULES.RENEWALS.WHATHAPPENS.STEP_1.CONTENT_1')}
      </Typography>
      <Typography component='p' variant='h5'>
        {translate('MODULES.RENEWALS.WHATHAPPENS.STEP_1.CONTENT_2')}
      </Typography>
      <Typography component='p' weight='bold' variant='h5'>
        {translate('MODULES.RENEWALS.WHATHAPPENS.STEP_1.CONTENT_3')}
      </Typography>
      <Typography component='p' variant='h5'>
        <span dangerouslySetInnerHTML={{ __html: translate('MODULES.RENEWALS.WHATHAPPENS.STEP_1.CONTENT_4', { currency: "USD", amount: moneyFormat(context?.getProduct?.value ?? "") }) }}></span>
      </Typography>
    </div>
  }

  const initialValues = useMemo(() => {
    return context?.WhatHappensStepForm ?? {}
  }, [context]);

  const handleCancel = (values) => {
    cancel();
  }

  const handleSubmit = async (values) => {
    next(values)
  }

  const handleCloseCompany = (values = {}) => {
    setAction({ action: 'GO_CLOSE_COMPANY', payload: values });
  }

  useEffect(() => {
    dispatch(addAssistantText(translate('MODULES.RENEWALS.WHATHAPPENS.STEP_1.TOOLTIP_1', { date: "TODO date" })))
    dispatch(addAssistantText(translate('MODULES.RENEWALS.WHATHAPPENS.STEP_1.TOOLTIP_2', { currency: "USD", amount: "50,000" })))
    return () => {
      dispatch(clearAssistatText())
    }
  }, [dispatch])

  const schema = Yup.object().shape({});

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizardInfo
        props={{
          title: translate('MODULES.RENEWALS.COMMON.TITLE'),
          subTitle: translate('MODULES.RENEWALS.COMMON.SUBTITLE'),
          image: reactImage,
          isRenewalImage: true,
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('MODULES.RENEWALS.WHATHAPPENS.STEP_1.BTN_START')}</SubmitButton>,
        }}
      >
        <>
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
          >
            <Paragraph />
            <Typography component='p' variant='s'>
              {translate('MODULES.RENEWALS.WHATHAPPENS.STEP_1.CONTENT_5')}
              <Link onClick={handleCloseCompany}>{translate('MODULES.RENEWALS.WHATHAPPENS.STEP_1.CONTENT_6')}</Link>
              {translate('MODULES.RENEWALS.WHATHAPPENS.STEP_1.CONTENT_7')}
            </Typography>
          </Stack>
        </>
      </TemplateWizardInfo>
    </Form>
  );
}

export default WhatHappensStepForm;


import * as Yup from 'yup';

import { default as React, useEffect, useMemo, useState } from 'react';
import { addAssistantText, clearAssistatText } from 'src/modules/generic/store/action';

import Button from 'src/modules/generic/components/Buttons/Button';
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import Form from 'src/components/forms/Form/Form';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateLabel from 'src/modules/generic/components/Inputs/LabelInput';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import TextAreaInput from "src/components/inputs/Textarea/TextareaInput";
import classes from './classes.module.scss';
import { useDispatch } from 'react-redux';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';

export default function ReviewPersonalDetailNomineeStepForm() {

  const { next, context, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.initialData ?? {}
  }, [context]);

  const { formValues } = initialValues;
  const [isNominee, setIsNominee] = useState(formValues?.isNominee ?? false);

  const directorName = 'John Doe';

  const handleCancel = (values) => {
    cancel();
  }

  const handlePrev = (values) => {
    prev();
  }

  const handleSubmit = async (values) => {
    next(values)
  }

  useEffect(() => {
    dispatch(addAssistantText(`When someone acts in a <strong>nominee capacity</strong>, it means they are serving as a representative or agent for another individual or entity. In this role, the nominee holds legal title to assets or rights, but the beneficial interest belongs to another party.
    <br/>
    <br/>
    The nominee acts on behalf of the true owner, often for purposes of confidentiality or administrative convenience. If this datapoint indicates "Yes", it suggests that the person is holding a position or asset on behalf of someone else. If "No", the person is acting on their own behalf.`))
    return () => {
      dispatch(clearAssistatText())
    }
  }, [dispatch])

  const options = [
    {
      value: false,
      label: "No, they",
    },
    {
      value: true,
      label: "Yes, they are",
    },
  ];
  

  const schema = Yup.object().shape({
    isNominee: Yup.boolean()
      .required("This field is required")
      .nullable(),
    descriptionOfPEP: Yup.lazy(() => {
      if (isNominee) return Yup.string().required("This field is required");
      return Yup.string().nullable();
    })
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: 'Is this stakeholder acting in a Nominee Capacity for another person?',
          skeleton: null,
          subTitle: 'Review details for ' + directorName,
          variant: 'simple'
        }}
        buttons={{
          previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('BUTTON_NEXT')}</SubmitButton>,
        }}
      >
        <TemplateLabel
          isRequired={true}
          renderPosition="row"
        >
          <ControllerInput
            render={CheckboxButtonSimple}
            name="isNominee"
            size="size1"
            options={options}
            classNameButton={classes.heightCheckboxButtonSimple}
            onChange={(value) => setIsNominee(value)}
          />
        </TemplateLabel>
        {isNominee && (
          <TemplateLabel
            isRequired={true}
            renderPosition="row"
            multilineTexts={["What makes this stakeholder a PEP?"]}
          >
            <ControllerInput
              render={TextAreaInput}
              name="descriptionOfPEP"
              placeholder="Type here"
              rows={8}
            />
          </TemplateLabel>
        )}
        <div>&nbsp;</div>
      </TemplateWizard>
    </Form>
  );
}


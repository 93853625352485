import { createMachine } from 'xstate';

const machine =
  /** @xstate-layout N4IgpgJg5mDOIC5QFVZgE4AUCuAjANgJYDGASmFIbAC7oCG1hA9gHYB02a6AYk+gLYBiAHIBRABoAVRKAAOTWIUasZIAB6IAjABYAHGwBMAVgBsukye1GjABgDsuzQYA0IAJ5bNATjZGvf3V1tL20TOxt-AF9I11QMHAISckoaemV2AGslXgFBTFJRADVVeUV01Q0EHX1jMwsrWwcnVw8EO01fLxMbG10AZh6m42jYrgSiMgoqWgZmTOy+ITEpEoUlOYrEPr6fTXCvO1s+0J7NFq0DAzYIgKCQsJuRkDisPAnk6bS5thowWT68gVikgQKV1ioQZVtrt9ocbMdujYzu5EHZtNcuj1+oNHMMniwmBA4KoXuMklNUrNWBwuDl+KsyhtIYhtC4UW0fDs7H0DLoutpNDy+iYnqS3uSUjN0mwstQ6QzwSxNggDALDKZdEYdF4Qto+v1zghHGw+dzefzBQZhaKxuLJpKvtTfv8FeVmQg9Vc9hFEQ4ukZdIagmwuTy+ZZLdaYs9bYl7Z8qUqQWC3aBKppA+yALQdbQ2PM2IzaVlWvMWaLRIA */
  createMachine({
    id: "renewal_and_billing",
    initial: "getSubscriptionData",
    states: {
      getSubscriptionData: {
        invoke: {
          src: 'getSubscriptionData',
          id: 'getSubscriptionData',
          onDone: [
            {
              target: 'initialStatus',
              actions: ['setContextAfterInvoke']
            }
          ],
        }
      },
      initialStatus: {
        on: {
          NEXT: { 
            target: "confirm_or_save",
            actions: ['setContextAfterSelect'],
          },
          CANCEL: "finish_renewal_and_billing",
        },
      },
      confirm_or_save: {
        always: [
          { cond: 'subscriptionModified', target: 'confirm_renewal_and_billing' },
          { cond: 'subscriptionNotModified', target: 'saveSubscriptionData' }
        ]
      },
      confirm_renewal_and_billing: {
        on: {
          NEXT: { target: "saveSubscriptionData", actions: "setContext" },
          CANCEL: "finish_renewal_and_billing",
          PREV: { target: 'initialStatus'}
        },
      },
      saveSubscriptionData: {
        invoke: {
          src: 'saveSubscriptionData',
          id: 'saveSubscriptionData',
          onDone: [
            {
              target: 'finish_renewal_and_billing',
              actions: ['setContextAfterInvoke']
            }
          ],
          onError: [
            {
              target: 'confirm_renewal_and_billing'
            }
          ]
        }
      },
      finish_renewal_and_billing: {
        type: "final",
        entry: 'stopMachine',
      },
    },
  }
);

export default machine;

import _ from 'lodash';
const parseValueToRole = (company, value) => {
    const groupCompany = _.get(company, 'id');
    const role = _.get(value, 'more_details.roles', []);
    return { groupCompany, role }
}

const parseValueToPosition = (company, value) => {
    const { position = null, department = null } = _.get(value, "select_positions_department", {});
    const { responsabilities = [] } = _.get(value, "select_responsabilities", {});
    const groupCompanyId = _.get(company, 'id');

    const values = {};

    position && _.assign(values, {
        position: {
            id: position.id,
            name: position.name
        },
        responsibilities: _.map(responsabilities, res => _.pick(res, ['id', 'text', 'isDefault', 'code']))
    });

    department && _.assign(values, { departmentId: department.id });

    return {
        groupCompanyId,
        ...values
    };
}

export const getValuesMutation = (values, state) => {
    const { globalData, companies } = state.context;
    let roles = [];
    let positions = [];
    _.forEach([...globalData, values], (value, index) => {
        const role = parseValueToRole(companies[index], value);
        roles.push(role);
        const position = parseValueToPosition(companies[index], value);
        positions.push(position);
    });


    return { roles, positions };
}

export const hasRolesPosition = (roles)=>{
    const rolesPosition = ["FOUNDER", "EMPLOYEE", "CONSULTANT"];
    return _.some(roles, rp => _.includes(rolesPosition, rp));    
}


import React from 'react';
import {Field} from 'formik';
import {TextAreaInput} from '../../../../components';
import TextInput from "../../Inputs/TextInput";
import ClaraCountrySelector from "../../../../components/Form/ClaraCountrySelector/ClaraCountrySelector"

export default function RegistedAddressInput({ name, label }) {

    const nameField = name ? name + "." : "";

    return (
        <div>

<Field name={`${nameField}country`}>
                {({ field, form }) => (
            <ClaraCountrySelector 
                jurisdictionsEnabled={false}
                label={label}
                additionalStyle={"addKyc"}
                setFieldValue={form.setFieldValue}
                countryField={`${nameField}country`}
                returnCountryAsString={true}
            />
                )}
</Field>
            <Field name={`${nameField}address`}>
                {({ field, form }) => (
                    <TextAreaInput
                        //   className={classesForm.textArea}
                        label={" "}
                        field={field}
                        form={form}
                        autoComplete='off'
                        placeholder={"Address..."}
                    // tooltip={tooltips['Issue Note']}
                    />
                )}
            </Field>



            <Field name={`${nameField}city`}>
                {({ field, form }) => (
                    <TextInput
                        type='text'
                        label=' '
                        field={field}
                        form={form}
                        placeholder={"City..."}
                    />
                )}
            </Field>



            <Field name={`${nameField}state`}>
                {({ field, form }) => (
                    <TextInput
                        type='text'
                        label=' '
                        field={field}
                        form={form}
                        placeholder={"State/Region..."}
                    />
                )}
            </Field>



            <Field name={`${nameField}zipCode`}>
                {({ field, form }) => (
                    <TextInput
                        type='text'
                        label=' '
                        field={field}
                        form={form}
                        placeholder={"Zip Code/Post Code..."}
                    />
                )}
            </Field>



        </div>
    )
}

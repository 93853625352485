import useCustomMachine from "../../../../generic/hooks/useCustomMachine";
import React, { FC, useEffect } from "react";
import Button from "../../../../generic/components/Buttons/Button";
import { StepProps } from "../../../../generic/components/Wizard/Step";
import TemplateWizard from "../../../../generic/templates/Modal/TemplateWizard";
import useTranslate from "../../../../generic/hooks/useTranslate";
import FormPEPCloseAssociate from "../../Forms/FormPEPCloseAssociate";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import useSession from "../../../../session/hooks/useSession";
import { addAssistantText, clearAssistatText } from "../../../../generic/store/action";
import useUpdateStakeholder from "src/modules/startup/onBoarding/core/graphql/mutations/useUpdateStakeholder";

const Step1: FC<StepProps> = () => {

  const { translate } = useTranslate();
  const { next, send, state, stepData } = useCustomMachine();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { startupId } = useSession();
  const { updateStakeholder } = useUpdateStakeholder({}, {});

  useEffect(() => {
    dispatch(clearAssistatText());
    dispatch(
      addAssistantText(translate("MODULES.STAKEHOLDER.PEP.THIRD_STEP.TOOLTIP"))
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, []);

  const handleCompleteSubmit = async (values) => {
    await updateStakeholder({
      startupId,
      stakeholderId: state?.context?.data?.stakeholderId ?? id,
      stakeholderData: {
        stakeholder: {
          pep: {
            ...values,
            ...state.context.data.pep,
            ...state.context.data.pep_family_member,
          }
        },
      }
    });
    next(values);
  };
  
  const handleCancel = async (values) => {
    send("CANCEL");
  };

  const handlePrevious = () => send("PREVIOUS");

  return (
    <React.Fragment>
      <FormPEPCloseAssociate
        initialValues={stepData}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: translate("MODULES.STAKEHOLDER.PEP.THIRD_STEP.TITLE"),
          subTitle: translate("MODULES.STAKEHOLDER.PEP.THIRD_STEP.SUBTITLE"),
        }}
        buttons={{
          previous: (
            <Button onClick={handlePrevious} variant="secondary">
              Previous
            </Button>
          ),
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              Cancel
            </Button>
          ),
        }}
      ></FormPEPCloseAssociate>
    </React.Fragment>
  );
};

export default Step1;

import {Field} from "formik";
import {OptionsButtonInput, TextAreaInput} from "../../../../../components";
import classesForm from "../../Form.module.scss";
import _ from "lodash";
import React from "react";

const PoliticallyExposedPerson = ({ values, handleOptionsButtonClick }) => {

    return (<>

        <h1>Politically Exposed Person</h1>
        <article>A PEP is someone who is, or has been, entrusted with a prominent public function, a family member of a PEP or a close associate of a PEP. <a href="https://academy.clara.co/article/2yLAMAsu2H5FnRlZdNLCgT" target="_blank">Learn More.</a>
        </article>

        <Field name={"politicallyExposedPerson"}>
            {({ field, form }) => (
                <OptionsButtonInput chatform={false}
                                    field={field}
                                    form={form}
                                    className={{ root: classesForm.buttonOptions }}
                                    label={"Is this individual a PEP?"}
                                    data-zuko-name='Is this individual a PEP?'
                                    data-zuko-id='OptionsButtonInput'
                                    options={[
                                        {
                                            "id": true,
                                            "text": "Yes"
                                        },
                                        {
                                            "id": false,
                                            "text": "No"
                                        }
                                    ]}
                                    onClick={handleOptionsButtonClick}
                    // params={this.props.params}
                    // functions={this.props.functions}
                />

            )}
        </Field>
        {_.get(values, "politicallyExposedPerson") &&
        <Field name="politicallyExposedPersonDescription">
            {({ field, form }) => (

                <TextAreaInput
                    className={classesForm.textArea}
                    label='Please describe why you are a PEP.'
                    data-zuko-name='Please describe why you are a PEP'
                    field={field}
                    form={form}
                    autoComplete='off'
                    placeholder={"Please describe why you are a PEP..."}
                    // tooltip={tooltips['Issue Note']}

                />
            )}
        </Field>}

        <Field name={"familyMemberOfAPEP"}>
            {({ field, form }) => (
                <OptionsButtonInput chatform={false}
                                    field={field}
                                    form={form}
                                    className={{ root: classesForm.buttonOptions }}
                                    label={"Is this individual a Family Member of a PEP?"}
                                    data-zuko-name='Is this individual a family member of a PEP'
                                    data-zuko-id='OptionsButtonInput'
                                    options={[
                                        {
                                            "id": true,
                                            "text": "Yes"
                                        },
                                        {
                                            "id": false,
                                            "text": "No"
                                        }
                                    ]}
                                    onClick={handleOptionsButtonClick}
                    // params={this.props.params}
                    // functions={this.props.functions}
                />

            )}
        </Field>

        {_.get(values, "familyMemberOfAPEP") &&
        <Field name="familyMemberOfAPEPDescription">
            {({ field, form }) => (

                <TextAreaInput
                    className={classesForm.textArea}
                    label={`Describe which of the individual’s Family Members is a PEP and why:`}
                    data-zuko-name='Is this individual a family member of a PEP'
                    field={field}
                    form={form}
                    autoComplete='off'
                    tooltip="You are a Family Member of a PEP if you are the parent, spouse or child of a PEP, or you are the spouse of a child of a PEP."
                    placeholder={"Describe who is a PEP..."}
                    // tooltip={tooltips['Issue Note']}
                />
            )}
        </Field>}

        <Field name={"closeAssociateOfAPEP"}>
            {({ field, form }) => (
                <OptionsButtonInput chatform={false}
                                    field={field}
                                    form={form}
                                    className={{ root: classesForm.buttonOptions }}
                                    label={"Is this individual  a Close Associate of a PEP?"}
                                    data-zuko-name='Is this individual a Close Associate of a PEP'
                                    data-zuko-id='OptionsButtonInput'
                                    options={[
                                        {
                                            "id": true,
                                            "text": "Yes"
                                        },
                                        {
                                            "id": false,
                                            "text": "No"
                                        }
                                    ]}
                                    onClick={handleOptionsButtonClick}
                    // params={this.props.params}
                    // functions={this.props.functions}
                />

            )}
        </Field>
        {_.get(values, "closeAssociateOfAPEP") &&
        <Field name="closeAssociateOfAPEPDescription">
            {({ field, form }) => (

                <TextAreaInput
                    className={classesForm.textArea}
                    label={`Describe which  member of this individual's relationship with the PEP and why the are a PEP?`}
                    data-zuko-name="Describe which  member of this individual's relationship with the PEP and why the are a PEP"
                    field={field}
                    form={form}
                    autoComplete='off'
                    tooltip="Individuals (a) known to have joint beneficial ownership of a legal entity or legal arrangement, or any close business relations or (b) with sole beneficial ownership of a legal entity or legal arrangement known to have been set up for the PEP’s benefit."
                    placeholder={"Describe who is a PEP..."}
                    // tooltip={tooltips['Issue Note']}
                />
            )}
        </Field>}


    </>)
}
export default PoliticallyExposedPerson;
import useGetDelete from './useGetDelete'
import useNavigation from "src/modules/generic/hooks/useNavigation";
import { useParams } from "react-router-dom";
import useSession from "src/modules/session/hooks/useSession";
import { Constants } from "src/v1/utils";
import useGetConnections from './graphql/useGetConnections'
import useGetDeleteVerification from './graphql/useGetDeleteVerification'
import useDeleteGroupCompany from "src/hooks/services/modules/groupCompany/useDeleteGroupCompany";

const useServices = ()=>{
    const { mapConnections } = useGetDelete()
    const queryVerification = useGetDeleteVerification({})
    const queryConnections = useGetConnections({})
    const [mutation] = useDeleteGroupCompany();
    const { startupId } = useSession()
    const { goTo } = useNavigation()
    const { id } = useParams<{ id: string }>()

    const deleteVerification = async (context) => {
        const data = await queryVerification({variables:{startupId, groupCompanyId: id}})
        return {
            ableToDelete: data?.data?.getGroupCompany?.allowedActions?.toDelete,
            groupCompany:{
            name:data?.data?.getGroupCompany?.name,
        }
    }
}

    const getConnections = async (context) => {
        const data =await queryConnections({variables:{startupId, groupCompanyId: id}})
        return mapConnections(data.data)
    }

    const deleteGroupCompany = async (context) => {
        await mutation({ variables: { startupId, groupCompanyId: id }});
            goTo(Constants.PAGES.MODULES.MAP.maps);
    }

    return {
        deleteVerification,
        getConnections,
        deleteGroupCompany
    }
}

export default useServices;
import { useMemo } from 'react';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import LeaseDetailsStepForm from '../steps/LeaseDetailsStepForm';

const useSteps = () => {
  return useMemo(() => ({
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    LeaseDetailsStepForm: {
      render: LeaseDetailsStepForm,
    },
    SaveStep: {
      render: TemplateWizardSkeletonMachine,
    },
    
  }), []);
}

export default useSteps;
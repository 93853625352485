import React, { useState, useMemo } from "react";
// import React, { useEffect, useState, useMemo } from "react";
import _ from "lodash";
// import axios from "axios";
import cls from "./classes.module.scss";
import { Fade, Skeleton } from "@mui/material";
import { getURLApi } from "../../../../../../utils/urlHelper";
import ButtonIcon from "../../../Buttons/ButtonIcon";
import ClassNames from "classnames";
// import { Botonera } from "../../../../../../components/forms/contents";
import useSession from "../../../../../session/hooks/useSession";
import useImageActions from "../hooks/useImageActions";
import TypographyLocal from "../../../../../../modules/generic/components/Texts/Typography";
import Icon from "../../../../../../modules/generic/components/Icon";
import {
  PDFViewerAllPages
} from '../../../../../../components/generic/Documents/DocumentPreview/PDFViewer/PDFViewer';

const Handlebars = require("handlebars");
const imageTypeSupported = ["jpg", "jpeg", "gif", "png", "png", "svg", "bmp"];
const officeTypeSupported = ["doc", "xls", "ppt", "docx", "xlsx", "pptx"];

const useGetUrlPreview = (file, defaultResponse = null) => {
  const PUBLIC_URL_FILE = "{{{api_url}}}/filePreview/{{file_id}}/{{token}}";
  const MICROSOFT_DRIVE_URL_FILE_PREVIEW = "https://view.officeapps.live.com/op/embed.aspx?src={{{api_url}}}/filePreview/{{file_id}}/{{token}}";
  const api_url = useMemo(() => `${getURLApi()}`, []);
  const { session } = useSession();
  const token = useMemo(() => session.token, [JSON.stringify(session)]);

  let url = useMemo(() => {
    if (defaultResponse) return defaultResponse;
    let template;
    // if (imageTypeSupported.includes(file.type) || file.type === "pdf") {
    //   template = Handlebars.compile(PUBLIC_URL_FILE);
    // } else {
    //   template = Handlebars.compile(MICROSOFT_DRIVE_URL_FILE_PREVIEW);
    // }
    template = Handlebars.compile(PUBLIC_URL_FILE);
    const params = { api_url, file_id: file.id, token };
    return template(params);
  }, []);
  return { url };
};

const Previewer = ({
  files,
  testFile,
  handleRemove,
  uploadMoreFile,
  limit,
  hideDelete = false,
}: {
  limit?: number;
  files: any[];
  testFile: string;
  uploadMoreFile: any;
  handleRemove: any;
  hideDelete?: boolean;
}) => {

  const [numberFile, setNumberFile] = useState(0);
  const [loading, setLoading] = useState(true);

  const nextStep = React.useCallback(() => {
    setNumberFile((pre) => pre + 1);
    setLoading(true);
  }, []);

  const prevStep = React.useCallback(() => {
    setNumberFile((pre) => pre - 1);
    setLoading(true);
  }, []);

  const firstFile = React.useCallback(() => {
    setNumberFile(0);
    setLoading(true);
  }, []);

  const handleLoading = () => {
    setLoading(false);
  };

  const fileNow = useMemo(() => {
    const manageFile =  files[numberFile];
    const rebuidExtension =  () => {
      const fileExt = manageFile.name.split('.').pop().toLowerCase();
      const fileName = manageFile.name.substr(0, manageFile.name.lastIndexOf("."));
      return fileName + "." + fileExt;
    }
    const newManageFile =  {
      ...manageFile,
      name : rebuidExtension(),
      type : manageFile?.type?.toLowerCase() || manageFile.name.split('.').pop().toLowerCase(),
    }    
    return newManageFile;
  }, [numberFile, JSON.stringify(files)]);

  const { stateImage, zoomIn, zoomOut, rotate } = useImageActions();

  const styleImg = useMemo(() => {
    return {
      transform: `scale(${stateImage.scale}) rotate(${stateImage.rotate}deg)`,
    };
  }, [stateImage]);

  return (
    <div className={cls.containerDocumentPreviewer}>
      {numberFile !== 0 && (
        <ButtonIcon
          icon={"Arrow-Left"}
          className={cls.buttonLeftStep}
          onClick={prevStep}
        />
      )}
      <div>
        <div className={cls.nameContainer}>
          <TypographyLocal
            component={"span"}
            color={"dark-grey"}
          >
            {_.get(fileNow, "name", "")}
          </TypographyLocal>
        </div>
        <div
          className={ClassNames(cls.containerPreviwer, {
            [cls.containerPreviewerButton]:
              !loading &&
              imageTypeSupported.includes(_.get(fileNow, "type", "")),
          })}
        >
          <Fade in={loading}>
            <div className={cls.skeletonDocument}>
              <div>
                <Icon color={"#e4e4e4"} icon={"Zip"} size={"240px"} isClara />
                <div className={cls.skeletoncap}>
                  {Array(3).fill(1).map((el, i) =>
                    <Skeleton variant="text" height={"1.3rem"} width={"100%"} />
                  )}
                </div>
                <div className={cls.skeletoncap2}>
                  {Array(4).fill(1).map((el, i) =>
                    <Skeleton variant="text" height={"1.3rem"} width={"100%"} />
                  )}
                  <Skeleton variant="text" height={"1.3rem"} width={"50%"} />
                </div>
              </div>
            </div>
          </Fade>
          <RenderView
            file={fileNow || {}}
            key={_.get(fileNow, "id", null)}
            onEndLoading={handleLoading}
            style={styleImg}
            testRender={testFile}
            isLoading={loading}
          />
          <div className={ClassNames(cls.containerButtons)}>
            {!hideDelete && (
              <ButtonIcon
                className={cls.buttonActions}
                key={1}
                size={"20px"}
                icon="Delete"
                variant="transparent"
                onClick={() => {
                  firstFile();
                  handleRemove(fileNow);
                }}
              />
            )}
            {imageTypeSupported.includes(_.get(fileNow, "type", "")) && (
              <div>
                <ButtonIcon
                  className={cls.buttonActions}
                  key={1}
                  size={"20px"}
                  icon="Back"
                  variant="transparent"
                  onClick={rotate}
                />
                &nbsp;
                <ButtonIcon
                  className={cls.buttonActions}
                  key={2}
                  size={"20px"}
                  icon="Minus"
                  variant="transparent"
                  onClick={zoomOut}
                />
                <ButtonIcon
                  className={cls.buttonActions}
                  key={3}
                  size={"20px"}
                  icon="Plus"
                  variant="transparent"
                  onClick={zoomIn}
                />
              </div>
            )}
          </div>
        </div>
        {(!limit || limit > files.length) && (
          <div className={cls.moreContainer}>
            <TypographyLocal
              component={"span"}
              color={"dark-grey"}
            >
              Document {numberFile + 1} of {files.length}
            </TypographyLocal>
            <div onClick={uploadMoreFile}>
              <TypographyLocal
                component={"span"}
                className={cls.flowLink}
                color={"dark-grey"}
              >
                Upload more
              </TypographyLocal>
            </div>
          </div>
        )}
      </div>
      {files.length - 1 !== numberFile && (
        <ButtonIcon
          icon={"Arrow-Right"}
          className={cls.buttonRightStep}
          onClick={nextStep}
        />
      )}
    </div>
  );
};

export default Previewer;

const RenderView = ({ file, onEndLoading, style, testRender, isLoading }) => {

  const { url } = useGetUrlPreview(file, testRender);

  return (
    <>
      {imageTypeSupported.includes(file.type) && (
        <div className={cls.containerImage}>
          <img
            style={style}
            className={cls.imagesContainer}
            src={url}
            alt={"Preview"}
            onLoad={onEndLoading}
          />
        </div>
      )}
      {(file.type === "pdf" || officeTypeSupported.includes(file.type)) && (
        // <div className={cls.containerIframe}>
        //   <iframe
        //     title={"Preview"}
        //     //className={style}
        //     //ref={iframeRef}
        //     id={file.id}
        //     src={url}
        //     onLoad={onEndLoading}
        //   />
        // </div>
        <div className={`${cls.containerMultiplePagePDF} ${isLoading && cls.isLoadingPDF}`}>
          <PDFViewerAllPages pdf={url} onLoad={onEndLoading} />
        </div>
      )}
    </>
  );
};

import { createMachine } from 'xstate';

const machine =
  createMachine({
    id: 'StartRenewalProcess',
    initial: 'initialData',
    states: {
      initialData: {
        invoke: {
          src: 'initialData',
          id: 'initialData',
          onDone: [
            {
              target: 'EvaluateCond',
              actions: ['setContextAfterInvoke']
            }
          ],
        }
      },
      EvaluateCond: {
        always: [
          {
            cond: 'isTechLicence',
            target: 'WhatHappensTechLicenceStepForm'
          },
          {
            cond: 'isHoldingCompany',
            target: 'WhatHappensHoldingCompanyStepForm'
          },
        ]
      },
      WhatHappensTechLicenceStepForm: {
        on: {
          NEXT: {
            target: 'SaveStep',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          },
          GO_CLOSE_COMPANY: {
            target: 'CloseDownCompanyStepForm',
          }
        }
      },
      WhatHappensHoldingCompanyStepForm: {
        on: {
          NEXT: {
            target: 'SaveStep',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          },
          GO_CLOSE_COMPANY: {
            target: 'CloseDownCompanyStepForm',
          }
        }
      },
      CloseDownCompanyStepForm : {
        on: {
          PREV: {
            target: 'EvaluateCond',
          },
          NEXT: {
            target: 'CloseDownCompanyWhatHappensStepForm',
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      CloseDownCompanyWhatHappensStepForm : {
        on: {
          PREV: {
            target: 'CloseDownCompanyStepForm',
          },
          NEXT: {
            target: 'StrikeOff',
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      SaveStep: {
        invoke: {
          src: 'SaveStep',
          id: 'SaveStep',
          onDone: [
            {
              target: 'finish',
            }
          ],
          onError: [
            {
              target: 'SaveStep'
            }
          ]
        }
      },
      StrikeOff: {
        invoke: {
          src: 'StrikeOff',
          id: 'StrikeOff',
          onDone: [
            {
              target: 'finish',
            }
          ],
          onError: [
            {
              target: 'StrikeOff'
            }
          ]
        }
      },
      cancel: {
        entry: 'cancelMachine',
        type: 'final'
      },
      finish: {
        entry: 'stopMachine',
        type: 'final'
      }
    }
  });

export default machine;

import React from 'react'
import * as Yup from 'yup'
import Form from '../../../../components/forms/Form/Form'
import useTranslate from '../../../generic/hooks/useTranslate'
import TextInput from '../../../../components/inputs/Text/TextInput'
import ControllerInput from '../../../generic/components/Inputs/ReactHookForm/ControllerInput'
import TemplateLabel from '../../../generic/templates/Label'
import SubmitButton from '../../../generic/components/Buttons/SubmitButton'
import FormProps from '../../../generic/forms/interfaces/FormProps';
import TemplateFormDefault from "../../../generic/templates/Modal";

import cls from './Form.module.scss';
import CountrySelector from '../../../../components/inputs/Select/CountrySelector/CountrySelector';
import { useDispatch } from 'react-redux';
import { addAssistantText, clearAssistatText } from '../../../generic/store/action';


const schema = Yup.object().shape({
    country: Yup.string().required('This field is required').nullable(),
    street: Yup.string().required('This field is required').nullable(),
    city: Yup.string().required('This field is required').nullable(),
})



const StepCompanyInfo: React.FC<FormProps> = ({
    initialValues,
    onCompleteSubmit,
    Template = TemplateFormDefault,
    buttons = {},
    buttonSubmitProps,
    propsTemplate
}: FormProps) => {

    const { translate } = useTranslate()
    const refForm = React.useRef(null);

    const handleSubmit = async values => {
        onCompleteSubmit && onCompleteSubmit(values);
    };


    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(addAssistantText(translate('MODULES.MAP.WIZARD_COMPANY_DETAIL_INIT_TEXT_ASSISTANCE'), "text"));
        return () => {
            dispatch(clearAssistatText());
        }
    }, []);



    return (
        <Form
            defaultValues={initialValues}
            schema={schema}
            onSubmit={handleSubmit}
            ref={refForm} optionsForm = {{mode:"onChange"}}
        >
            <Template buttons={{ ...buttons, submit: <SubmitButton  {...buttonSubmitProps}>Next</SubmitButton> }} props={propsTemplate}>

                <TemplateLabel
                    label={translate('MODULES.MAP.WIZARD_COMPANY_DETAIL_FORM_FIELD_COUNTRY')}
                    className={cls.TemplateLabel}
                    isRequired
                >
                    <ControllerInput
                        render={CountrySelector}
                        name='country'

                        defaultlabel={''}
                        placeholder='Country'
                    />
                </TemplateLabel>


                <TemplateLabel
                    label={translate('MODULES.MAP.WIZARD_COMPANY_DETAIL_FORM_FIELD_STREET')}
                    className={cls.TemplateLabel}
                    isRequired
                >
                    <ControllerInput
                        render={TextInput}
                        name='street'
                        defaultlabel={''}
                        placeholder='Street'
                    />
                </TemplateLabel>

                <TemplateLabel
                    label={translate('MODULES.MAP.WIZARD_COMPANY_DETAIL_FORM_FIELD_CITY')}
                    className={cls.TemplateLabel}
                    isRequired
                >
                    <ControllerInput
                        render={TextInput}
                        name='city'
                        defaultlabel={''}
                        placeholder='City'
                    />
                </TemplateLabel>

                <TemplateLabel
                    label={translate('MODULES.MAP.WIZARD_COMPANY_DETAIL_FORM_FIELD_STATE')}
                    className={cls.TemplateLabel}
                >
                    <ControllerInput
                        render={TextInput}
                        name='state'
                        defaultlabel={''}
                        placeholder='State/Region'
                    />
                </TemplateLabel>

                <TemplateLabel
                    label={translate('MODULES.MAP.WIZARD_COMPANY_DETAIL_FORM_FIELD_ZIPCODE')}
                    className={cls.TemplateLabel}
                >
                    <ControllerInput
                        render={TextInput}
                        name='zipCode'
                        defaultlabel={''}
                        placeholder='Zip Code / Post Code'
                    />
                </TemplateLabel>

            </Template>

        </Form>
    )
}

export default StepCompanyInfo

import React, { FC } from 'react'
import classesModule from './classes.module.scss'
import Icon from '../../../../../modules/generic/components/Icon'
import classnames from 'classnames'
import Button, { ButtonProps } from './../Button'

export interface ButtonIconProps extends ButtonProps {
  /**  Button Content  */
  children?: React.ComponentType | undefined
  /** Size */
  size?: string
  /** Icon */
  icon?: string
  /** Component  Class */
  className?: string
  onClick: (event: React.MouseEvent<HTMLInputElement>) => void
}

/**
 * Button Icon
 */
const ButtonIcon: FC<ButtonIconProps> = ({ className, icon = 'plus', size = '1rem', variant = "secondary", onClick, ...props }: ButtonIconProps) => {
  return (
    <React.Fragment>
      <Button
        labelLoading={' '}
        {...props}
        variant={variant}
        onClick={onClick}

        className={classnames(classesModule.ButtonIcon, className, {
          [classesModule.ButtonLoading]: props.loading,
        })}
      >
        <Icon isClara icon={icon} size={size} />
      </Button>
    </React.Fragment>
  )
}

export default ButtonIcon

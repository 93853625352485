import { useEffect } from 'react';
import useMutation from "../../../custom/useMutation";


const mutation = `mutation renameDevice (  $id: ID!, $name: String) {
    renameDevice (
     id:$id
     name:$name
   ) {
        id
        name
        
   }
 }`
const useRenameDevice = (options) => {

    const [send, data] = useMutation(mutation, options);
    const sendForm = (id) => {

        return send(id);
    }
    useEffect(() => {

    }, []);

    return [sendForm, data];
}
export default useRenameDevice;

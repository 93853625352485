import React from "react";
import ReactDOM from "react-dom";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import getGTMtag from "./v1/utils/gtmIdManager";
import { loadState, saveState } from "./session"

/* Cuando se termine el re*/
import "./index.module.scss";
import "./style.scss";
import "./generic.scss";
import './styles/index.scss';
//import  './styles/variables/index.scss';
//-------------------

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import pageReducer from "./store/reducers/page";
import chatBotReducer from "./store/reducers/chatbot";
import autocompleteReducer from "./store/reducers/autocomplete";
import notificationReducer from "./store/reducers/notification";

import checkoutReducer from "./modules/checkout/store/reducers/checkout";
import moduleGenericReducer from './modules/generic/store/reducer'
import moduleSessionReducer from './modules/session/store/reducer'
import capTableReducer from './modules/capTable/store/reducer';

import { isTablet } from "react-device-detect";



import dotEnv from 'dotenv';
import TagManager from 'react-gtm-module'
import _ from "lodash"
//import logger from 'redux-logger'
dotEnv.config({ path: './.env' });




const tagManagerArgs = {
  // @todo: make this value env dependant
  gtmId: getGTMtag(),
  dataLayerName: 'PageDataLayer'
}

TagManager.initialize(tagManagerArgs)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; //debug reducer

const rootReducer = combineReducers({
  page: pageReducer,
  chatBot: chatBotReducer,
  autocomplete: autocompleteReducer,
  notifications: notificationReducer,
  
  checkout: checkoutReducer,
  
  generic: moduleGenericReducer,
  capTable: capTableReducer,
  session: moduleSessionReducer,
  
});
// const rootReducer = combineReducers({
//   page: pageReducer,
//   chatBot: chatBotReducer,
//   autocomplete: autocompleteReducer,
//   notifications: notificationReducer,
//   form: formReducer,
//   checkout: checkoutReducer,
//   generic: moduleGenericReducer
// });

const middlewares = [thunk];




let initialData = {};
// _.set(initialData, "page.breadCrumbs", [])
const store = createStore(
  rootReducer,
  initialData,
  composeEnhancers(
    applyMiddleware(...middlewares)
  ),
  +  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)
// store.subscribe(function () {
//   let state = store.getState();
//   saveState('state', state)
// })
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

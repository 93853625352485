import useLazyQuery from "src/hooks/custom/useLazyQuery";

const GET_GROUP_COMPANY = `
query getGroupCompanyGeneralDetails($startupId: ID!, $groupCompanyId: ID!) {
  getGroupCompany(startupId: $startupId, groupCompanyId: $groupCompanyId) {
    id
    name
    isHoldingCompany
    type
    registeredNumber
    incorporation_date
    dissolution_date
    transactionCurrency
    authorisedShareCapital
    linkedinURL
    country {
      name
      code
    }
    jurisdiction
    nameBecameEffectiveOn
    previousName
    licenseType
    companyStatus
    managedByClara
    jurisdictionType
  }
}`

const useGetGroupCompany = (variables) => {
    const { manualQuery, loading, error, data, refetch } = useLazyQuery(GET_GROUP_COMPANY, variables);
    
    const query = async (variables) => {
        const { data } = await manualQuery({ variables });
        return { groupCompany: data['getGroupCompany'] };
    }

    return { query, loading, error, data, refetch };
}

export default useGetGroupCompany
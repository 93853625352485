import _ from "lodash";
import { useMemo } from "react";
import useSession from "src/modules/session/hooks/useSession";
import useUploadBulkDocuments from "src/modules/startup/Stakeholder/graphql/mutation/useUploadBulkDocuments";
import useUpsertRenewalData from "src/modules/startup/Stakeholder/graphql/mutation/useUpsertRenewalData";
const useServices = ({ initialValues }) => {
  const { mutation: uploadDocuments } = useUploadBulkDocuments();
  const { startupId } = useSession();
  const mutation = useUpsertRenewalData();

  return useMemo(
    () => ({
      initialData: async (context, event) => {
        return initialValues;
      },

      SaveStep: async (context, event) => {
        const groupCompanyId = context?.initialData?.groupCompanyId;
        const file1 = _.get(
          context,
          "UploadAnnualAccounts.documentBalanceSheet",
          ""
        );
        const file2 = _.get(
          context,
          "UploadAnnualAccounts.documentProfitLoss",
          ""
        );
        const documents = [];
        documents.push({
          title: file1.name,
          file: file1,
          type: "CAYMAN_BALANCE_SHEET",
          status: "Uploaded",
          parties: [],
          groupCompanies: [{ groupCompany: groupCompanyId }],
          subCategories: ["miscellaneous"],
        });
        documents.push({
          title: file2.name,
          file: file2,
          type: "CAYMAN_PROFIT_AND_LOSS",
          status: "Uploaded",
          parties: [],
          groupCompanies: [{ groupCompany: groupCompanyId }],
          subCategories: ["miscellaneous"],
        });
        const result = await uploadDocuments({
          variables: { startupId, documents: documents },
        });
        if (result?.data) {
          await mutation({
            variables: {
              startupId,
              groupCompanyId,
              renewalData: {
                annualAccounts: [result?.data?.uploadBulkDocuments[0].id, result?.data?.uploadBulkDocuments[1].id]
              },
            },
          });
        }
        return result;
      },
    }),
    [initialValues]
  );
};

export default useServices;

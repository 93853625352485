import React, {Component} from 'react';
import classes from './Notification.module.scss';

/*
    Properties:
        show: notification is show
        type: type notification (warning|danget|success)
        children: content to render
*/
class Notification extends Component {
    getClass=(type)=>{
        return classes[type.toLowerCase()];
    }
    render() {
        const { type, children, show } = this.props;
        return (
            <React.Fragment>
                {show ? (
                    <div className={`${classes.Notification} ${this.getClass(type)}`}>
                        {children}
                    </div>
                ):null}

            </React.Fragment>
        );
    }
}

export default Notification;

const ROLES_LABEL = {
  FOUNDER: "Founder",
  ADVISOR: "Advisor",
  EMPLOYEE: "Employee",
  CONSULTANT: "Consultant",
  INVESTOR: "Investor",
  OPTIONHOLDER: "Option Holder",
  CONVERTIBLEHOLDER: "Convertible Holder",
  WARRANTHOLDER: "Warrant Holder",
  SHAREHOLDER: "Shareholder",
  IPASSIGNOR: "IP Assignor",
  THIRDPARTY: "Third Party",
  DIRECTOR: "Director",
  AUTHORIZEDSIGNATORY: "Authorised Signatory"
};

const ROLES_LABEL_PLURAL = {
  FOUNDER: "Founders",
  ADVISOR: "Advisors",
  EMPLOYEE: "Employees",
  CONSULTANT: "Consultants",
  INVESTOR: "Investors",
  OPTIONHOLDER: "Option Holders",
  CONVERTIBLEHOLDER: "Convertible Holders",
  WARRANTHOLDER: "Warrant Holders",
  SHAREHOLDER: "Shareholders",
  IPASSIGNOR: "IP Assignors",
  THIRDPARTY: "Third Parties",
  DIRECTOR: "Directors",
  AUTHORIZEDSIGNATORY: "Authorised Signatories"
};
export default {
  ROLES_LABEL,
  ROLES_LABEL_PLURAL,
  ADD_BASIC_INFO: 'Add their basic info',
  ADD_ROLE: 'Add {{#isAn}}an{{/isAn}}{{^isAn}}a{{/isAn}} {{role}}',
  INDIVIDUAL: 'Individual',
  COMPANY: 'Company',
  ARE_MORE_ENTITY: 'Do you have more {{role}} to add?',
  ADDED_ENTITY: '{{role}} added!',
  IS_INDIVIDUAL_OR_COMPANY: 'Are they an individual or company?',
  ADD_CHECKBOX: 'Yes, add {{entity}}',
  IS_THERE_ENTITY: 'Do you have {{role}}s in your startup?',
  IS_ENTITY: 'Your {{role}}s'
}

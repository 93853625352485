import { ModalConvertibleDoc, ModalEmployeeDoc, ModalIpAndGenerate } from "../../../startup/Documents";
import React, { FC, useMemo } from "react";
import AddEquityTaskModal from "src/modules/startup/equity/AddEquityTask";
import ArticlesOfAssociationModal from "src/modules/startup/Stakeholder/EntityStructures/Cayman/ArticlesOfAssociationModal";
import AssistanceFrame2 from "./";
import CertificateOfGoodStandingModal from "src/modules/startup/Stakeholder/EntityStructures/ADGM/LimitedPartnership/CertificateOfGoodStandingModal";
import CertificateOfIncorporationModal from "src/modules/startup/Stakeholder/EntityStructures/CertificateOfIncorporationModal";
import CertificateOfRegistrationModal from "src/modules/startup/Stakeholder/EntityStructures/ADGM/Trust/CertificateOfRegistrationModal";
import CompanyDirectorsModal from "src/modules/startup/Stakeholder/EntityStructures/ADGM/LimitedByShares/CompanyDirectorsModal";
import ConfirmationsOfShareholdersAndDirectorsModal from "src/views/modules/startups/renewal/adgm/modals/ConfirmationsOfShareholdersAndDirectorsModal";
import CorporateSignatoryModal from "src/modules/startup/Stakeholder/EntityStructures/CorporateSignatoryModal";
import LimitedPartnershipDetailsModal from "src/modules/startup/Stakeholder/EntityStructures/ADGM/LimitedPartnership/LimitedPartnershipDetailsModal";
import ModalAddAddresses from "src/modules/startup/Stakeholder/AddAddresses/ModalAddAddresses";
import ModalAuthorisedSignatories from '../../../startup/Stakeholder/AuthorisedSignatories/ModalAuthorisedSignatories';
import ModalNotices from '../../../startup/Stakeholder/Notices/ModalNotices';
import ModalPassport from 'src/modules/startup/Stakeholder/Passport';
import ModalPersonalInfoCorporate from '../../../startup/Stakeholder/PersonalInfoCorporate/ModalPersonalInfoCorporate';
import ModalRemoveTask from "src/modules/startup/core/components/modals/DeleteTaskModal";
import ModalRolesAndPositions from '../../../startup/Stakeholder/RolesAndPositions/ModalRolesAndPositions';
import OfficialDocumentModal from "src/modules/startup/Stakeholder/EntityStructures/ADGM/LimitedByShares/OfficialDocumentModal";
import PEPModal from "src/modules/startup/Stakeholder/EntityStructures/ADGM/LimitedByShares/PEPModal";
import RegOfPartnersOrLimPartnershipAgreeModal from "src/modules/startup/Stakeholder/EntityStructures/ADGM/LimitedPartnership/RegOfPartnersOrLimPartnershipAgreeModal";
import RegistersModal from "src/modules/startup/Stakeholder/EntityStructures/Cayman/RegistersModal";
import RegistersOrCertificateOfIncumbencyModal from "src/modules/startup/Stakeholder/EntityStructures/ADGM/LimitedByShares/RegistersOrCertificateOfIncumbencyModal";
// import ReviewAndConfirmCapTableModal from "src/views/modules/startups/renewal/adgm/modals/ReviewAndConfirmCapTableModal";
import SourceOfFundsModal from "src/modules/startup/Stakeholder/EntityStructures/ADGM/LimitedByShares/SourceOfFundsModal";
import StakeholderCompanyTasks from "../../../../modules/assistanceV4/components/AssistanceAreas/StakeholderIndividual2/Tasks";
import StakeholderCorporateDetailsModal from "src/modules/startup/Stakeholder/EntityStructures/ADGM/LimitedByShares/StakeholderCorporateDetailsModal";
import TrustDeedModal from "src/modules/startup/Stakeholder/EntityStructures/ADGM/Trust/TrustDeedModal";
import ReviewDetailsCorporateStakeholderModal from "src/views/modules/startups/renewal/adgm/modals/ReviewDetailsCorporateStakeholderModal";
import _ from "lodash";
import useGetStakeholder from "../../hooks/services/useGetStakeholder.jsx";
import useModal from "../../../generic/hooks/useModal";
import { useParams } from "react-router-dom";
import useSession from "../../../session/hooks/useSession";
import useTabStatus from "../../hooks/useTabStatus";
import useTasks from "../../hooks/useTasks";

/**
 * Assistance Frame for StakeholderCompany
 */

export interface AssistanceStakeholderCompanyProps { }

let restCount = 0;

const AssistanceStakeholderCompany: FC<AssistanceStakeholderCompanyProps> = ({
  ...props
}: AssistanceStakeholderCompanyProps) => {
  const { startupId } = useSession();
  const { id: idStakeholder } = useParams();
  const { data: dataStakeholder, error: errorStakeholderAssistance, loading: loadingStakeholderAssistance, } = useGetStakeholder({ startupId: startupId, stakeholderId: idStakeholder });
  const { openModal } = useModal();
  const jurisdiction = _.get(dataStakeholder, 'getStakeholder.jurisdiction', '');
  const jurisdictionType = jurisdiction === 'Abu Dhabi Global Market' ? 'ADGM' : jurisdiction === 'Cayman Islands' ? 'CAYMAN' : null;

  const allTasks = useMemo(() => {
    if (!dataStakeholder) return [];
    let result = [];
    _.forEach(_.get(dataStakeholder, "getStakeholder.tasks", []), (task) => {
      result.push(task);
    });
    _.forEach(_.get(dataStakeholder, "getStakeholder.equity", []), (equity) => {
      _.forEach(equity.tasks, (task) => {
        result.push({ ...task, params: { amount: equity.amount, currency: equity.currency, id: equity.id, issuedDate: equity.issuedDate, groupCompany: equity.groupCompany, owner: { id: idStakeholder, type: 'stakeholder' } } });
      });
    });
    _.forEach(_.get(dataStakeholder, "getStakeholder.warrants", []), (warrant) => {
      _.forEach(warrant.tasks, (task) => {
        result.push({ ...task, params: { amount: warrant.amount, currency: warrant.currency, id: warrant.id, issuedDate: warrant.issuedDate, groupCompany: warrant.groupCompany } });
      });
    });
    _.forEach(_.get(dataStakeholder, "getStakeholder.convertibles", []), (convertible) => {
      _.forEach(convertible.tasks, (task) => {
        result.push({ ...task, params: { amount: convertible.amount, currency: convertible.currency, id: convertible.id, issuedDate: convertible.issuedDate, groupCompany: convertible.groupCompany } });
      });
    });
    _.forEach(_.get(dataStakeholder, "getStakeholder.roles", []), (role) => {
      _.forEach(role.tasks, (task) => {
        result.push({ ...task, params: { groupCompany: role.groupCompany } });
      });
    });
    return result;
  }, [JSON.stringify(dataStakeholder)]);

  const configDataStakeholder = {
    READY: [

      { code: "TASK_ADD_ROLES_POSITIONS", onClick: (params) => openModal(ModalRolesAndPositions, { ...params }) },
      { code: "TASK_ADD_PERSONAL_INFORMATION", onClick: (params) => openModal(ModalPersonalInfoCorporate, { ...params }) },
      { code: "TASK_ADDRESSES", onClick: (params) => openModal(ModalAddAddresses, { ...params }) },
      { code: "TASK_ADD_STAKEHOLDER_CORPORATE_INFORMATION", onClick: (params) => openModal(ModalPersonalInfoCorporate, { ...params }) },
      { code: "TASK_ADD_STAKEHOLDER_CORPORATE_NOTICES", onClick: (params) => openModal(ModalNotices, { ...params }) },
      { code: "TASK_ADD_AUTHORISED_SIGNATORIES", onClick: (params) => openModal(ModalAuthorisedSignatories, { ...params }) },

      { code: "TASK_ADD_FOUNDERS_AGREEMENT", onClick: (params) => openModal(ModalIpAndGenerate, { paramsMutation: { ...params, type: "FOUNDER_SERVICES_AGREEMENT" } }) },
      { code: "TASK_ADD_EMPLOYEE_AGREEMENT", onClick: (params) => openModal(ModalEmployeeDoc, { paramsMutation: { ...params } }) },
      { code: "TASK_ADD_CONSULTANT_AGREEMENT", onClick: (params) => openModal(ModalIpAndGenerate, { paramsMutation: { ...params, type: 'CONSULTANT_AGREEMENT' } }) },
      { code: "TASK_ADD_ADVISOR_AGREEMENT", onClick: (params) => openModal(ModalIpAndGenerate, { paramsMutation: { ...params, type: 'ADVISOR_AGREEMENT' } }) },
      { code: "TASK_ADD_OTHER_DOCUMENTS", onClick: (params) => openModal(ModalIpAndGenerate, { paramsMutation: { ...params, type: 'IP_ASSIGNMENT' } }) },
      { code: "TASK_ADD_IP_ASSIGNMENT_AGREEMENT", onClick: (params) => openModal(ModalIpAndGenerate, { paramsMutation: { ...params, type: "IP_ASSIGNMENT" } }) },
      { code: "TASK_ADD_OTHERS", onClick: (params) => openModal(ModalIpAndGenerate, { ...params }) },

      { code: "TASK_ADD_WARRANT_AGREEMENT", onClick: (params) => openModal(ModalIpAndGenerate, { paramsMutation: { ...params, type: 'WARRANT_AGREEMENT' } }) },
      { code: "TASK_ADD_SHARE_AGREEMENT", onClick: (params) => openModal(ModalIpAndGenerate, { paramsMutation: { ...params, type: 'REGISTER_OF_SHARES' } }) },
      { code: "TASK_ADD_CONVERTIBLE_AGREEMENT", onClick: (params) => openModal(ModalConvertibleDoc, { paramsMutation: { ...params } }) },
      { code: "TASK_ADD_SHARE_OPTION_AGREEMENT", onClick: (params) => openModal(ModalIpAndGenerate, { paramsMutation: { ...params, type: 'GRANT_AGREEMENT' } }) },

      { code: "TASK_ADD_WARRANT_AGREEMENT_FOR_IN_PROGRESS" },
      { code: "TASK_ADD_CONVERTIBLE_AGREEMENT_FOR_IN_PROGRESS" },
      { code: "TASK_ADD_SHARE_OPTION_AGREEMENT_FOR_IN_PROGRESS" },

      {
        code: "TASK_ADD_EQUITY_POSITIONS", onClick: () => openModal(AddEquityTaskModal, {}),
        onDelete: (params) => openModal(ModalRemoveTask, { registeredTaskData: { entityId: idStakeholder, taskCode: 'TASK_ADD_EQUITY_POSITIONS' } })
      },
    ],
    LBS: [
      {
        code: "TASK_COMPANY_DETAILS_LBS_CORP", onClick: (params) => openModal(StakeholderCorporateDetailsModal, {
          initialValues: {
            stakeholderId: idStakeholder,
            stakeholderName: _.get(dataStakeholder, 'getStakeholder.fullName', '')
          }
        }, 'MODAL_TASK_COMPANY_DIRECTORS_LBS_CORP')
      },
      {
        code: "TASK_COMPANY_DIRECTORS_LBS_CORP", onClick: (params) => openModal(CompanyDirectorsModal, {
          initialValues: {
            stakeholderId: idStakeholder,
            stakeholderName: _.get(dataStakeholder, 'getStakeholder.fullName', ''),
            directors: _.get(dataStakeholder, 'getStakeholder.directors', [])
          }
        }, 'MODAL_TASK_COMPANY_DIRECTORS_LBS_CORP')
      },
      {
        code: "TASK_PASSPORT_LBS",
        onClick: (params) => {
          const individualToFind = params?.additionalParams[0]?.entityId ?? null;
          if (individualToFind !== null) {
            const individualData = _.find(dataStakeholder?.getStakeholder?.directors, (individual) => individual.id === individualToFind);
            openModal(ModalPassport, {
              initialValues: {
                idToSave: individualData?.id,
                name: individualData?.fullName
              }
            }, 'MODAL_PASSPORT_FOR_ENTITY_REPRESENTATIVE_LBS_CORP');
          }
        },
      },
      {
        code: "TASK_CORPORATE_SIGNATORY_LBS_CORP", onClick: (params) => openModal(CorporateSignatoryModal, {
          initialValues: {
            stakeholderId: idStakeholder,
            stakeholderName: _.get(dataStakeholder, 'getStakeholder.fullName', ''),
          }
        }, 'MODAL_TASK_CORPORATE_SIGNATORY_LBS_CORP')
      },
      {
        code: "TASK_PASSPORT_FOR_CORPORATE_SIGNATORY_LBS_CORP",
        onClick: (params) => openModal(ModalPassport, {
          initialValues: {
            idToSave: dataStakeholder?.getStakeholder?.authorizedSignatory?.stakeholder ?? "",
            name: dataStakeholder?.getStakeholder?.authorizedSignatory?.fullName ?? ""
          }
        }, 'MODAL_PASSPORT_FOR_ENTITY_REPRESENTATIVE_LBS_CORP'),
        titleParams: {
          name: dataStakeholder?.getStakeholder?.authorizedSignatory?.fullName ?? ""
        }
      },
      {
        code: "TASK_PEP_LBS_CORP", onClick: (params) => {
          openModal(PEPModal, {
            initialValues: {
              stakeholderId: idStakeholder,
            }
          }, 'TASK_PEP_LBS_CORP');
        }
      },
      {
        code: "TASK_SOURCE_OF_FUNDS_LBS_CORP", onClick: (params) => openModal(SourceOfFundsModal, {
          initialValues: {
            stakeholderId: idStakeholder,
            stakeholderName: _.get(dataStakeholder, 'getStakeholder.fullName', ''),
            jurisdictionType
          }
        }, 'MODAL_SOURCE_OF_FUNDS')
      },
      { code: "TASK_CERTIFICATE_OF_INCORPORATION_LBS_CORP", onClick: (params) => openModal(CertificateOfIncorporationModal, {}, 'MODAL_CERTIFICATE_OF_INCORPORATION') },
      { code: "TASK_REGISTERS_OR_CERTIFICATE_OF_INCUMBENCY_LBS_CORP", onClick: (params) => openModal(RegistersOrCertificateOfIncumbencyModal, {}, 'MODAL_REGISTERS_OR_CERTIFICATE_OF_INCUMBENCY') },
      { code: "TASK_OFFICIAL_DOCUMENT_LBS_CORP", onClick: (params) => openModal(OfficialDocumentModal, {}, 'MODAL_OFFICIAL_DOCUMENT') },
      { code: "TASK_ARTICLES_OF_ASSOCIATION_LBS_CORP", onClick: (params) => openModal(ArticlesOfAssociationModal, {}, 'MODAL_ARTICLES_OF_ASSOCIATION') },
      { code: "TASK_REGISTERS_LBS_CORP", onClick: (params) => openModal(RegistersModal, {}, 'MODAL_CAYMAN_REGISTERS') },
    ],
    REOPEN_LBS: [
      {
        code: "TASK_COMPANY_DETAILS_LBS_CORP_KYC_INC",
        onClick: (params) => openModal(StakeholderCorporateDetailsModal, {
          initialValues: {
            stakeholderId: idStakeholder,
            stakeholderName: _.get(dataStakeholder, 'getStakeholder.fullName', '')
          }
        }, 'MODAL_TASK_COMPANY_DETAILS_LBS_CORP_KYC_INC')
      },
      {
        code: "TASK_COMPANY_DIRECTORS_LBS_CORP_KYC_INC",
        onClick: (params) => openModal(CompanyDirectorsModal, {
          initialValues: {
            stakeholderId: idStakeholder,
            stakeholderName: _.get(dataStakeholder, 'getStakeholder.fullName', ''),
            directors: _.get(dataStakeholder, 'getStakeholder.directors', [])
          }
        }, 'MODAL_TASK_COMPANY_DIRECTORS_LBS_CORP_KYC_INC')
      },
      {
        code: "TASK_PASSPORT_LBS_KYC_INC",
        onClick: (params) => {
          const individualToFind = params?.additionalParams[0]?.entityId ?? null;
          if (individualToFind !== null) {
            const individualData = _.find(dataStakeholder?.getStakeholder?.directors, (individual) => individual.id === individualToFind);
            openModal(ModalPassport, {
              initialValues: {
                idToSave: individualData?.id,
                name: individualData?.fullName
              }
            }, 'MODAL_TASK_PASSPORT_LBS_KYC_INC');
          }
        },
      },
      {
        code: "TASK_CORPORATE_SIGNATORY_LBS_CORP_KYC_INC",
        onClick: (params) => openModal(CorporateSignatoryModal, {
          initialValues: {
            stakeholderId: idStakeholder,
            stakeholderName: _.get(dataStakeholder, 'getStakeholder.fullName', ''),
          }
        }, 'MODAL_TASK_CORPORATE_SIGNATORY_LBS_CORP_KYC_INC')
      },
      {
        code: "TASK_PASSPORT_FOR_CORPORATE_SIGNATORY_LBS_CORP_KYC_INC",
        onClick: (params) => openModal(ModalPassport, {
          initialValues: {
            idToSave: dataStakeholder?.getStakeholder?.authorizedSignatory?.stakeholder ?? "",
            name: dataStakeholder?.getStakeholder?.authorizedSignatory?.fullName ?? ""
          }
        }, 'MODAL_TASK_PASSPORT_FOR_CORPORATE_SIGNATORY_LBS_CORP_KYC_INC'),
        titleParams: {
          name: dataStakeholder?.getStakeholder?.authorizedSignatory?.fullName ?? ""
        }
      },
      {
        code: "TASK_PEP_LBS_CORP_KYC_INC", onClick: (params) => {
          openModal(PEPModal, {
            initialValues: {
              stakeholderId: idStakeholder,
            }
          }, 'TASK_PEP_LBS_CORP_KYC_INC');
        }
      },
      {
        code: "TASK_SOURCE_OF_FUNDS_LBS_CORP_KYC_INC", onClick: (params) => openModal(SourceOfFundsModal, {
          initialValues: {
            stakeholderId: idStakeholder,
            stakeholderName: _.get(dataStakeholder, 'getStakeholder.fullName', ''),
            jurisdictionType
          }
        }, 'TASK_SOURCE_OF_FUNDS_LBS_CORP_KYC_INC')
      },
      { 
        code: "TASK_CERTIFICATE_OF_INCORPORATION_LBS_CORP_KYC_INC", 
        onClick: (params) => openModal(CertificateOfIncorporationModal, {}, 'MODAL_TASK_CERTIFICATE_OF_INCORPORATION_LBS_CORP_KYC_INC') 
      },
      { 
        code: "TASK_REGISTERS_OR_CERTIFICATE_OF_INCUMBENCY_LBS_CORP_KYC_INC", 
        onClick: (params) => openModal(RegistersOrCertificateOfIncumbencyModal, {}, 'MODAL_TASK_REGISTERS_OR_CERTIFICATE_OF_INCUMBENCY_LBS_CORP_KYC_INC') 
      },
      { 
        code: "TASK_OFFICIAL_DOCUMENT_LBS_CORP_KYC_INC", 
        onClick: (params) => openModal(OfficialDocumentModal, {}, 'MODAL_TASK_OFFICIAL_DOCUMENT_LBS_CORP_KYC_INC') 
      },
      { 
        code: "TASK_ARTICLES_OF_ASSOCIATION_LBS_CORP_KYC_INC",
        onClick: (params) => openModal(ArticlesOfAssociationModal, {}, 'MODAL_TASK_ARTICLES_OF_ASSOCIATION_LBS_CORP_KYC_INC')
      },
      { 
        code: "TASK_REGISTERS_LBS_CORP_KYC_INC",
        onClick: (params) => openModal(RegistersModal, {}, 'MODAL_TASK_REGISTERS_LBS_CORP_KYC_INC')
      },
    ],
    LP: [
      {
        code: "TASK_COMPANY_DETAILS_LP_CORP", onClick: (params) => openModal(LimitedPartnershipDetailsModal, {
          initialValues: {
            stakeholderId: idStakeholder,
          }
        }, 'MODAL_LIMITED_PARTNERSHIP_DETAILS')
      },
      {
        code: "TASK_CORPORATE_SIGNATORY_LP_CORP", onClick: (params) => openModal(CorporateSignatoryModal, {
          initialValues: {
            stakeholderId: idStakeholder,
            stakeholderName: _.get(dataStakeholder, 'getStakeholder.fullName', ''),
          }
        }, 'MODAL_TASK_CORPORATE_SIGNATORY_LP_CORP')
      },
      {
        code: "TASK_PASSPORT_FOR_CORPORATE_SIGNATORY_LP_CORP",
        onClick: (params) => openModal(ModalPassport, {
          initialValues: {
            idToSave: dataStakeholder?.getStakeholder?.authorizedSignatory?.stakeholder ?? "",
            name: dataStakeholder?.getStakeholder?.authorizedSignatory?.fullName ?? ""
          }
        }, 'MODAL_PASSPORT_FOR_ENTITY_REPRESENTATIVE_LP_CORP'),
        titleParams: {
          name: dataStakeholder?.getStakeholder?.authorizedSignatory?.fullName ?? ""
        }
      },
      { code: "TASK_CERTIFICATE_OF_INCORPORATION_LP_CORP", onClick: (params) => openModal(CertificateOfIncorporationModal, {}, 'MODAL_CERTIFICATE_OF_INCORPORATION') },
      { code: "TASK_CERTIFICATE_OF_GOOD_STANDING_LP_CORP", onClick: (params) => openModal(CertificateOfGoodStandingModal, {}, 'MODAL_CERTIFICATE_OF_GOOD_STANDING') },
      { code: "TASK_REGISTER_OF_PARTNERS_OR_LIMITED_PARTNERSHIP_AGREEMENT_LP_CORP", onClick: (params) => openModal(RegOfPartnersOrLimPartnershipAgreeModal, {}, 'MODAL_REGOFPARTNER_ORLIMPARTNERSHIPAGREE') },
    ],
    REOPEN_LP: [
      {
        code: "TASK_COMPANY_DETAILS_LP_CORP_KYC_INC", onClick: (params) => openModal(LimitedPartnershipDetailsModal, {
          initialValues: {
            stakeholderId: idStakeholder,
          }
        }, 'MODAL_TASK_COMPANY_DETAILS_LP_CORP_KYC_INC')
      },
      {
        code: "TASK_CORPORATE_SIGNATORY_LP_CORP_KYC_INC", onClick: (params) => openModal(CorporateSignatoryModal, {
          initialValues: {
            stakeholderId: idStakeholder,
            stakeholderName: _.get(dataStakeholder, 'getStakeholder.fullName', ''),
          }
        }, 'MODAL_TASK_CORPORATE_SIGNATORY_LP_CORP_KYC_INC')
      },
      {
        code: "TASK_PASSPORT_FOR_CORPORATE_SIGNATORY_LP_CORP_KYC_INC",
        onClick: (params) => openModal(ModalPassport, {
          initialValues: {
            idToSave: dataStakeholder?.getStakeholder?.authorizedSignatory?.stakeholder ?? "",
            name: dataStakeholder?.getStakeholder?.authorizedSignatory?.fullName ?? ""
          }
        }, 'MODAL_TASK_PASSPORT_FOR_CORPORATE_SIGNATORY_LP_CORP_KYC_INC'),
        titleParams: {
          name: dataStakeholder?.getStakeholder?.authorizedSignatory?.fullName ?? ""
        }
      },
      { 
        code: "TASK_CERTIFICATE_OF_INCORPORATION_LP_CORP_KYC_INC", 
        onClick: (params) => openModal(CertificateOfIncorporationModal, {}, 'MODAL_TASK_CERTIFICATE_OF_INCORPORATION_LP_CORP_KYC_INC') 
      },
      { 
        code: "TASK_CERTIFICATE_OF_GOOD_STANDING_LP_CORP_KYC_INC",
        onClick: (params) => openModal(CertificateOfGoodStandingModal, {}, 'MODAL_TASK_CERTIFICATE_OF_GOOD_STANDING_LP_CORP_KYC_INC')
      },
      { 
        code: "TASK_REGISTER_OF_PARTNERS_OR_LIMITED_PARTNERSHIP_AGREEMENT_LP_CORP_KYC_INC",
        onClick: (params) => openModal(RegOfPartnersOrLimPartnershipAgreeModal, {}, 'MODAL_TASK_REGISTER_OF_PARTNERS_OR_LIMITED_PARTNERSHIP_AGREEMENT_LP_CORP_KYC_INC')
      },
    ],
    TD: [
      {
        code: "TASK_CORPORATE_SIGNATORY_TD_CORP", onClick: (params) => openModal(CorporateSignatoryModal, {
          initialValues: {
            stakeholderId: idStakeholder,
            stakeholderName: _.get(dataStakeholder, 'getStakeholder.fullName', ''),
          }
        }, 'MODAL_TASK_CORPORATE_SIGNATORY_TD_CORP')
      },
      {
        code: "TASK_PASSPORT_FOR_CORPORATE_SIGNATORY_TD_CORP",
        onClick: (params) => openModal(ModalPassport, {
          initialValues: {
            idToSave: dataStakeholder?.getStakeholder?.authorizedSignatory?.stakeholder ?? "",
            name: dataStakeholder?.getStakeholder?.authorizedSignatory?.fullName ?? ""
          }
        }, 'MODAL_PASSPORT_FOR_ENTITY_REPRESENTATIVE_TD_CORP'),
        titleParams: {
          name: dataStakeholder?.getStakeholder?.authorizedSignatory?.fullName ?? ""
        }
      },
      { code: "TASK_TRUST_DEED_TD_CORP", onClick: (params) => openModal(TrustDeedModal, {}, 'MODAL_TRUST_DEED') },
      { code: "TASK_CERTIFICATE_OF_REGISTRATION_TD_CORP", onClick: (params) => openModal(CertificateOfRegistrationModal, {}, 'MODAL_CERTIFICATE_OF_REGISTRATION') },
    ],
    REOPEN_TD: [
      {
        code: "TASK_CORPORATE_SIGNATORY_TD_CORP_KYC_INC", onClick: (params) => openModal(CorporateSignatoryModal, {
          initialValues: {
            stakeholderId: idStakeholder,
            stakeholderName: _.get(dataStakeholder, 'getStakeholder.fullName', ''),
          }
        }, 'MODAL_TASK_CORPORATE_SIGNATORY_TD_CORP_KYC_INC')
      },
      {
        code: "TASK_PASSPORT_FOR_CORPORATE_SIGNATORY_TD_CORP_KYC_INC",
        onClick: (params) => openModal(ModalPassport, {
          initialValues: {
            idToSave: dataStakeholder?.getStakeholder?.authorizedSignatory?.stakeholder ?? "",
            name: dataStakeholder?.getStakeholder?.authorizedSignatory?.fullName ?? ""
          }
        }, 'MODAL_TASK_PASSPORT_FOR_CORPORATE_SIGNATORY_TD_CORP_KYC_INC'),
        titleParams: {
          name: dataStakeholder?.getStakeholder?.authorizedSignatory?.fullName ?? ""
        }
      },
      { 
        code: "TASK_TRUST_DEED_TD_CORP_KYC_INC",
        onClick: (params) => openModal(TrustDeedModal, {}, 'MODAL_TASK_TRUST_DEED_TD_CORP_KYC_INC') 
      },
      { 
        code: "TASK_CERTIFICATE_OF_REGISTRATION_TD_CORP_KYC_INC",
        onClick: (params) => openModal(CertificateOfRegistrationModal, {}, 'MODAL_TASK_CERTIFICATE_OF_REGISTRATION_TD_CORP_KYC_INC')
      },
    ],
    RENEWAL: [
      {
        code: "TASK_PASSPORT_FOR_CORPORATE_SIGNATORY_CHECK_EXP_DATE",
        onClick: (params) => {
          openModal(ModalPassport, {
            initialValues: {
              idToSave: params.stakeholderId ?? "",
            }
          }, 'TASK_PASSPORT_FOR_CORPORATE_SIGNATORY_CHECK_EXP_DATE');
        },
      },
      {
        code: "TASK_REGISTER_OF_DIRECTORS_CHECK_EXP_DATE",
        onClick: (params) => openModal(ModalPassport, {}, 'TASK_REGISTER_OF_DIRECTORS_CHECK_EXP_DATE')
      },
      {
        code: "TASK_REGISTER_OF_MEMBERS_CHECK_EXP_DATE",
        onClick: (params) => openModal(ModalPassport, {}, 'TASK_REGISTER_OF_MEMBERS_CHECK_EXP_DATE')
      },
      {
        code: "TASK_CERTIFICATE_OF_INCUMBENCY_CHECK_EXP_DATE",
        onClick: (params) => openModal(ModalPassport, {}, 'TASK_CERTIFICATE_OF_INCUMBENCY_CHECK_EXP_DATE')
      },
      {
        code: "TASK_CERTIFICATE_OF_GOOD_STANDING_CHECK_EXP_DATE",
        onClick: (params) => openModal(ModalPassport, {}, 'TASK_CERTIFICATE_OF_GOOD_STANDING_CHECK_EXP_DATE')
      },
      {
        code: "TASK_TRADE_LICENSE_CHECK_EXP_DATE",
        onClick: (params) => openModal(ModalPassport, {}, 'TASK_TRADE_LICENSE_CHECK_EXP_DATE')
      },
      {
        code: "TASK_COMMERCIAL_LICENSE_CHECK_EXP_DATE",
        onClick: (params) => openModal(ModalPassport, {}, 'TASK_COMMERCIAL_LICENSE_CHECK_EXP_DATE')
      },
      {
        code: "TASK_REGISTRY_EXTRACT_CHECK_EXP_DATE",
        onClick: (params) => openModal(ModalPassport, {}, 'TASK_REGISTRY_EXTRACT_CHECK_EXP_DATE')
      },
      {
        code: "TASK_REGISTER_OF_PARTNERS_CHECK_EXP_DATE",
        onClick: (params) => openModal(ModalPassport, {}, 'TASK_REGISTER_OF_PARTNERS_CHECK_EXP_DATE')
      },
      {
        code: "TASK_LIMITED_PARTNERSHIP_AGREEMENT_CHECK_EXP_DATE",
        onClick: (params) => openModal(ModalPassport, {}, 'TASK_LIMITED_PARTNERSHIP_AGREEMENT_CHECK_EXP_DATE')
      },
      {
        code: "TASK_RENEWAL_ADGM_REVIEW_CORPORATE_COMPANY_DETAILS",
        onClick: (params) => {
          openModal(ReviewDetailsCorporateStakeholderModal, { initialValues: { stakeholderId: idStakeholder } }, 'TASK_RENEWAL_ADGM_REVIEW_CORPORATE_COMPANY_DETAILS');
        },
      },
      {
        code: "TASK_RENEWAL_ADGM_REVIEW_CONFIRMATION_DIRECTORS_SHAREHOLDERS",
        onClick: (params) => {
          openModal(ConfirmationsOfShareholdersAndDirectorsModal, {}, 'TASK_RENEWAL_ADGM_REVIEW_CONFIRMATION_DIRECTORS_SHAREHOLDERS');
        },
      },
    ]
  };

  const getParams = (task, titleParams) => {
    const result = {
      ...task.params,
      additionalParams: task?.additionalParams,
      equityId: _.get(task, "params.id"),
      // TODO this line need review
      groupCompanyId: _.get(task, "params.entity.id"),
      entity: _.get(task, "params.entity"),
      stakeholderId: idStakeholder,
      ...titleParams
    }
    if (task?.code === "TASK_PASSPORT_LBS" || task?.code === "TASK_PASSPORT_LBS_KYC_INC") {
      const individualToFind = task?.additionalParams[0]?.entityId ?? null;
      if (individualToFind !== null) {
        const individualData = _.find(dataStakeholder?.getStakeholder?.directors, (individual) => individual.id === individualToFind);
        result.name = individualData?.fullName;
      }
    }
    return result;
  }

  const { dataTasks, loading } = useTasks(allTasks, configDataStakeholder, "ASSISTANCE_TASKS_STAKEHOLDER", getParams);

  const resetCounter = () => {
    restCount = 0;
  }

  const { taskStatus, showGJ, countCompleted } = useMemo(() => {
    if (!_.isEmpty(dataTasks)) {
      let showGJ = false;
      let result = "incompleted";
      let countCompleted = 0;
      let totalTasks = [];
      _.forEach(dataTasks, (taskGroups) => {
        countCompleted = countCompleted + taskGroups.completed;
        totalTasks.push(...taskGroups.tasks);
      });
      if (countCompleted === _.size(totalTasks)) {
        result = "completed";
        if (restCount === countCompleted - 1) {
          result = "incompleted";
          showGJ = true;
        }
      } else {
        restCount = countCompleted;
      }
      return {
        taskStatus: result,
        showGJ: showGJ,
        countCompleted: countCompleted,
      };
    }
  }, [JSON.stringify(dataTasks)]);

  const { showTask, showAction, activeTab } = useTabStatus(taskStatus, null, _.get(dataStakeholder, "getStakeholder.isLocked", false));

  const showInactiveTag = useMemo(() => {
    let result = false;
    if (!_.isEmpty(dataStakeholder)) {
      const allRoles = _.get(dataStakeholder, "getStakeholder.roles", []);
      const isInactive = _.every(allRoles, {'isActive': false});
      if (dataStakeholder?.getStakeholder?.isFounder === false) {
        result = isInactive;
      }
    }
    return result;
  }, [dataStakeholder]);  

  return (
    <AssistanceFrame2
      variant={"stakeholder"}
      isInactive={showInactiveTag}
      tasksComponent={StakeholderCompanyTasks}
      // showTask={showTask}
      showTask={true}
      tasks={dataTasks}
      companiesCorporateData={dataStakeholder}
      entity={_.get(dataStakeholder, "getStakeholder")}
      disabled={{
        actions: _.get(dataStakeholder, "getStakeholder.isLocked", false)
      }}
      resetCounter={resetCounter}
      showGJ={showGJ}
      countCompleted={countCompleted}
      tabsStatus={{
        showTask: showTask,
        showAction: showAction,
        showHelper: true,
        activeTab: activeTab,
      }}
    ></AssistanceFrame2>
  );
};

export default AssistanceStakeholderCompany;

import { useMemo } from "react";
import TemplateWizardSkeletonMachine from "src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine";
import { ConfigureDocument } from "../steps";

const useSteps = ({ isCategorize, isEdit, isVisaOrPassportDocument }) => {

  return useMemo(() => ({
    initialData: {
      render: TemplateWizardSkeletonMachine,
      renderProps: {
        title: "",
        subTitle: ""
      }
    },
    getAllCategoriesData: {
      render: TemplateWizardSkeletonMachine,
      renderProps: {
        title: "",
        subTitle: ""
      }
    },
    getAllStakeholders: {
      render: TemplateWizardSkeletonMachine,
      renderProps: {
        title: "",
        subTitle: ""
      }
    },
    categoriseDocumentData: {
      render: ConfigureDocument,
      renderProps: {
        isCategorize,
        isEdit,
        isVisaOrPassportDocument

      }
    },
    saveCategoriseData: {
      render: TemplateWizardSkeletonMachine,
      renderProps: {
        title: "",
        subTitle: ""
      }
    },
  }), [isCategorize, isEdit, isVisaOrPassportDocument]);
}

export default useSteps;
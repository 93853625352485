import { gql } from "@apollo/client";
import useLazyQuery from "../../../custom/useLazyQuery";
import _ from "lodash";


export const GET_TEMPLATE_BY_ID = gql`
  query getTemplateByDocumentType($documentType: String!) {
    template: getTemplateByDocumentType(documentType: $documentType) {
      id
      name
    }
  }
`;

function useGetTemplateByDocumentType () {
    const {manualQuery} = useLazyQuery(GET_TEMPLATE_BY_ID);

    const getTemplateByDocumentType=  async (variables) => {
        const { data } = await manualQuery({ variables });
        const template = _.get(data, 'template', null);
        return { template };
      }

    return { getTemplateByDocumentType };

}

export default useGetTemplateByDocumentType
import React, { useEffect, useRef, useState } from "react";
import { Constants } from "../../../../../utils/constants";
import { useDispatch } from "react-redux";
import classes from "./AddDocumentKYCForm.module.scss";
import { ClaraSelect, ClaraUpload, TextInput } from "../../../../index";
import _ from "lodash";
import notification from "../../../../../utils/notification";
import Mustache from "mustache";
import ServerConnect from "../../../../../utils/ServerConnect";
import { LayoutForm } from "../../../../../containers";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import {
  getDataServer,
  getMatterDocument,
  invalidDocReplaceAndUpload,
  upsertMatterDocument,
  validateRemplaceForm,
  validateUploadedForm,
} from "./Config";

import moment from "moment";
import Modal from "../../../../Modal/Modal/Modal";
import { useParams } from "react-router-dom";

const AddDocumentKYCForm = (props) => {
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");

  useEffect(() => {
    getTitle();
  }, []);

  const dispatch = useDispatch();
  const params = useParams();

  const asd = () => {
    dispatch({
      type: "GO_TO",
      page: Constants.PAGES.viewMatter,
      params: { id: params.matterId },
    });
  };

  const goTo = () =>
    dispatch({
      type: "GO_TO",
      page: Constants.PAGES.viewMatter,
      params: { id: props.params.matterId },
    });

  const setDocumentName = (matterId, type, extension) => {
    const dateFormatted = moment.utc().format("DD MMM YYYY").toUpperCase();
    return `${matterId.toString()}-${type}-${dateFormatted}.${extension
      .split(".")
      .pop()}`.replace(/\s/g, "");
  };

  const getTitle = () => {
    if (params.documentId) {
      setTitle("Replacing uploaded Document with new/edited version");
    } else {
      setTitle("Upload new document version");
    }
  };

  return (
    <React.Fragment>
      <LayoutForm
        title={title}
        leftEducationId={
          Constants.CONTENTFUL.ENTRIES.FORMS.STARTUP.LEFT_EDUCATION_ID
        }
        handleClose={asd}
      >
        {params.documentName ? (
          <RemplaceDocumentForm
            setDocumentName={setDocumentName}
            params={params}
            goTo={goTo}
          />
        ) : (
          <UploadDocumentForm
            setDocumentName={setDocumentName}
            params={params}
            goTo={goTo}
          />
        )}
      </LayoutForm>
    </React.Fragment>
  );
};

export default AddDocumentKYCForm;

const RemplaceDocumentForm = ({ setDocumentName, params, goTo }) => {
  const [dataServer, setDataServer] = useState(null);
  const validateRemplaceForm = Yup.object().shape(validateRemplaceForm);
  useEffect(() => {
    if (!dataServer) {
      const queryDataServer = Mustache.render(getMatterDocument, {
        matterId: params.matterId,
        documentId: params.documentId,
      });
      ServerConnect.graphQlQuery(queryDataServer).then(
        ({ getMatterDocument }) => {
          setDataServer(getMatterDocument);
        }
      );
    }
  }, []);

  const handleSubmitForm = (values) => {
    const variables = {
      documentId: params.documentId,
      matterId: params.matterId,
      kind: "UPLOADED",
      file: {
        ...values.file,
        name: setDocumentName(
          params.documentName,
          dataServer.type.label,
          values.file.name
        ),
      },
      docName: params.documentName,
      docType: dataServer.type.code,
      entityId: null,
      entity: null,
    };

    ServerConnect.graphQlMutation(upsertMatterDocument, variables)
      .then(({ upsertMatterDocument }) => {
        goTo();
        notification.sendNotification("Document Replace", "success", 4990);
      })
      .catch((err) => {
        console.log(err);
        notification.sendNotification("ERROR", "error", 4990);
      });
  };

  return (
    <Formik
      initialValues={{ documentName: params.documentName }}
      onSubmit={handleSubmitForm}
      validationSchema={validateRemplaceForm}
    >
      {({ values, handleSubmit, isValid }) => {
        return (
          <div className={classes.containerData}>
            <Field name="file">
              {({ field, form }) => (
                <ClaraUpload
                  name={`filepond`}
                  label={"Document:"}
                  field={field}
                  form={form}
                  className={classes.fileUpload}
                  typeFileAllowed={
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  }

                  // tooltip={tooltips['Upload your document']}
                />
              )}
            </Field>

            <Field name="documentName">
              {({ field, form }) => (
                <TextInput
                  type="text"
                  label={`${_.get(dataServer, "type.label")} holders Name: `}
                  field={field}
                  form={form}
                  placeholder={"Name Document ..."}
                  disabled
                  // tooltip={tooltips['Number of Shares']}
                />
              )}
            </Field>

            <div className={`botoneraButtons ${classes.containerButtons}`}>
              <button className={`genericBlue`} onClick={goTo}>
                Cancel
              </button>
              <button
                className={`genericBlue`}
                disabled={!isValid}
                onClick={handleSubmit}
              >
                Continue{" "}
              </button>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

const UploadDocumentForm = ({ setDocumentName, params, goTo }) => {
  const [docTypes, setDocTypes] = useState([]);
  const [listEntity, setListEntity] = useState([]);
  const [documentId, setDocumentId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const docTypeRef = useRef();
  const [dataServer, setDataServer] = useState(null);

  const validateUploadedSchema = Yup.object().shape(validateUploadedForm);

  const listDocuments = (data) => {
    return [
      {
        type: "individual",
        list: data.individual.map((doc) => ({ ...doc, id: doc.code })),
      },
      {
        type: "company",
        list: data.company.map((doc) => ({ ...doc, id: doc.code })),
      },
      {
        type: "generate",
        list: _.filter(
          data.generate,
          (doc) => !_.includes(invalidDocReplaceAndUpload, doc.code)
        ).map((doc) => ({ ...doc, id: doc.code })),
      },
      {
        type: "individualAndCompany",
        list: data.individualAndCompany.map((doc) => ({
          ...doc,
          id: doc.code,
        })),
      },
    ];
  };

  const getListIndividual = (individuals) => {
    return [
      {
        type: "individual",
        list: _.map(individuals, (ind) => ({
          id: ind.id,
          label: `${_.get(ind, "firstName")} ${_.get(ind, "lastName")}`,
        })),
      },
    ];
  };

  const getListCompany = (companies) => {
    return [
      {
        type: "company",
        list: _.map(companies, (com) => ({
          id: com.id,
          label: com.companyName,
        })),
      },
    ];
  };
  useEffect(() => {
    if (!dataServer) {
      const queryDataServer = Mustache.render(getDataServer, {
        matterId: params.matterId,
      });
      ServerConnect.graphQlQuery(queryDataServer).then((result) => {
        setDataServer(result);
        setDocTypes(listDocuments(result));
        let individuals = getListIndividual(
          result.getMattersList[0].KYC.individuals
        );
        let companies = getListCompany(
          result.getMattersList[0].KYC.companies
        );

        let entitiesDataIndividuals = getListIndividual(
          result.getMattersList[0].KYC.entitiesData.individuals
        );
        let entitiesDataCompanies = getListCompany(
          result.getMattersList[0].KYC.entitiesData.companies
        );
        let entitiesDataEntities = getListCompany(
          result.getMattersList[0].KYC.entitiesData.entities
        );

        setListEntity([
          ...individuals,
          ...entitiesDataIndividuals,
          ...companies,
          ...entitiesDataCompanies,
          ...entitiesDataEntities,
        ]);
      });
    }
  }, []);

  const handleReplaceDocument = (values) => {
    handleSubmitForm({ ...values, documentId });
  };
  const handleChangedType = () => {
    docTypeRef.current.instanceRef.clearValueAndFilter(
      docTypeRef.current.props
    );
    setShowModal(false);
  };
  const handleMiscellaneous = (setFieldValue) => {
    setFieldValue("docType", {
      type: "individualAndCompany",
      id: "MISCELLANEOUS_DOCUMENT",
    });
    setFieldValue("entity", "");
    setShowModal(false);
  };

  const handleSubmitForm = (values) => {
    const variables = {
      documentId: _.get(values, "documentId", null),
      matterId: params.matterId,
      kind: "UPLOADED",
      file: {
        ...values.file,
        name: setDocumentName(
          getNameDoc(values),
          getTypeDoc(values.docType),
          values.file.name
        ),
      },
      docName: getNameDoc(values),
      docType: values.docType.id,
      entityId: _.get(values, "entity.id", null),
      entity: _.get(values, "entity.type", null),
    };

    ServerConnect.graphQlMutation(upsertMatterDocument, variables)
      .then(({ upsertMatterDocument }) => {
        if (upsertMatterDocument.upsertFailed) {
          setDocumentId(_.get(upsertMatterDocument, "matterDocument.id"));
          setShowModal(true);
        } else {
          goTo();
          notification.sendNotification("Document Created", "success", 4990);
        }
      })
      .catch((err) => {
        console.log(err);
        notification.sendNotification("ERROR", "error", 4990);
      });
  };

  const handleSelected = (value, aditionalData, form) => {
    let entitiesDataEntities;
    let entitiesDataIndividuals;
    let entitiesDataCompanies;
    let individuals;
    let companies;

    switch (_.get(value, "type")) {
      case "individual":
        entitiesDataIndividuals = getListIndividual(
          dataServer.getMattersList[0].KYC.entitiesData.individuals
        );
        individuals = getListIndividual(
          dataServer.getMattersList[0].KYC.individuals
        );

        setListEntity([...individuals, ...entitiesDataIndividuals]);
        break;
      case "company":
        entitiesDataCompanies = getListCompany(
          dataServer.getMattersList[0].KYC.entitiesData.companies
        );
        companies = getListCompany(
          dataServer.getMattersList[0].KYC.companies
        );

        setListEntity([...companies, ...entitiesDataCompanies]);
        break;

      case "individualAndCompany":
        entitiesDataIndividuals = getListIndividual(
          dataServer.getMattersList[0].KYC.entitiesData.individuals
        );
        entitiesDataCompanies = getListCompany(
          dataServer.getMattersList[0].KYC.entitiesData.companies
        );
        individuals = getListIndividual(
          dataServer.getMattersList[0].KYC.individuals
        );
        companies = getListCompany(
          dataServer.getMattersList[0].KYC.companies
        );

        setListEntity([
          ...individuals,
          ...entitiesDataIndividuals,
          ...companies,
          ...entitiesDataCompanies,
        ]);
        break;

      default:
        individuals = getListIndividual(
          dataServer.getMattersList[0].KYC.individuals
        );
        companies = getListCompany(
          dataServer.getMattersList[0].KYC.companies
        );
        entitiesDataIndividuals = getListIndividual(
          dataServer.getMattersList[0].KYC.entitiesData.individuals
        );
        entitiesDataCompanies = getListCompany(
          dataServer.getMattersList[0].KYC.entitiesData.companies
        );
        entitiesDataEntities = getListCompany(
          dataServer.getMattersList[0].KYC.entitiesData.entities
        );

        setListEntity([
          ...individuals,
          ...entitiesDataIndividuals,
          ...companies,
          ...entitiesDataCompanies,
          ...entitiesDataEntities,
        ]);

        break;
    }
  };

  const getNameDoc = (values) => {
    if (_.get(values, "entity.id")) {
      let docName;
      listEntity.forEach((l) => {
        if (l.type === _.get(values, "entity.type")) {

          let doc = l.list.find((entity) => {

            return entity.id === _.get(values, "entity.id");
          });
          if (doc) {
            docName = doc.label;
          }
        }
      });
      return docName;
    }
    if (_.get(values, "entity")) {
      return _.get(values, "entity");
    }

    return null;
  };

  const getTypeDoc = (doc) => {
    let doctype;
    docTypes.forEach((l) => {
      if (l.type === _.get(doc, "type")) {
        doctype = l.list.find((entity) => entity.id === _.get(doc, "id")).label;
      }
    });
    return doctype;
  };

  return (
    <Formik
      initialValues={{}}
      onSubmit={handleSubmitForm}
      validationSchema={validateUploadedSchema}
    >
      {({ values, errors, handleSubmit, setFieldValue, isValid }) => {
        return (
          <div className={classes.containerData}>
            <Field name="file">
              {({ field, form }) => (
                <ClaraUpload
                  name={`filepond`}
                  label={"Document:"}
                  field={field}
                  form={form}
                  className={classes.fileUpload}

                  // tooltip={tooltips['Upload your document']}
                />
              )}
            </Field>
            <hr />

            <Field name="docType">
              {({ field, form }) => (
                <ClaraSelect
                  field={field}
                  form={form}
                  label={"Type:"}
                  placeholder={"Select Doc Type"}
                  lists={docTypes}
                  callbacks={{ updateValues: handleSelected }}
                  className={classes.claraSelect}
                  ref={docTypeRef}
                  //tooltip={tooltips['Company']}
                />
              )}
            </Field>

            {_.get(values, "docType.id") === "MISCELLANEOUS_DOCUMENT" ? (
              <Field name="entity">
                {({ field, form }) => (
                  <div className={classes.containerInputText}>
                    <TextInput
                      type="text"
                      label="Name: "
                      field={field}
                      form={form}
                      placeholder={"Name Document ..."}
                      // tooltip={tooltips['Number of Shares']}
                    />
                    <article>
                      Please name this document in a descriptive manner. eg
                      describe the document content.
                    </article>
                  </div>
                )}
              </Field>
            ) : (
              <Field name="entity">
                {({ field, form }) => (
                  <ClaraSelect
                    field={field}
                    form={form}
                    label={"Name:"}
                    placeholder={"Search or select from a list..."}
                    lists={listEntity}
                    //callbacks={{ updateValues: handleSourceOfWealth }}
                    className={classes.claraSelect}
                    //tooltip={tooltips['Company']}
                  />
                )}
              </Field>
            )}

            <div className={`botoneraButtons ${classes.containerButtons}`}>
              <button className={`genericBlue`} onClick={goTo}>
                Cancel
              </button>
              <button
                className={`genericBlue`}
                onClick={handleSubmit}
                disabled={!isValid}
              >
                Continue{" "}
              </button>
            </div>

            <Modal
              open={showModal}
              showHeader={false}
              className={{ modal: classes.modal }}
            >
              <div className={classes.containerModal}>
                <h1>Duplicate Document</h1>
                <span>
                  This document has already been uploaded. please select from
                  the following options below:{" "}
                </span>
                <div className={classes.modalContainerButton}>
                  <button
                    className={`genericBlue`}
                    onClick={() => handleReplaceDocument(values)}
                  >
                    Replace Document
                  </button>
                  <button
                    className={`genericBlue`}
                    onClick={() => handleChangedType()}
                  >
                    Change Type
                  </button>
                  <button
                    className={`genericBlue`}
                    onClick={() => handleMiscellaneous(setFieldValue)}
                  >
                    Miscellaneous
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        );
      }}
    </Formik>
  );
};

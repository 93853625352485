import React, {FC, useContext, useEffect, useMemo, useRef, useState} from 'react'
import _ from 'lodash'
import {useDispatch} from "react-redux";
import * as  Yup from 'yup'
import useTranslate from "../../../../generic/hooks/useTranslate";
import Form from "../../../../../components/forms/Form/FormL";
import classes from './classes.module.scss'
import ControllerInput from "../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import SubmitButton from "../../../../generic/components/Buttons/SubmitButton";
import TemplateFormDefault from "../../../../generic/templates/Modal";
import FormProps from "../../../../generic/forms/interfaces/FormProps";
import {CheckboxButtonOptions} from "../../../../generic/components/Inputs/CheckboxButton";
import CheckboxButtonSimple from "../../../../generic/components/Inputs/CheckboxButtonSimple";
import {addAssistantText, clearAssistatText, removeAssistantText} from "../../../../generic/store/action";
import useCustomMachine from "../../../../generic/hooks/useCustomMachine";
const schema=Yup.object().shape({
    documents:Yup.string().required()
})
interface IsThereDocumentProps extends FormProps {
    
    withAgreement?: boolean
    
}
const IsThereDocumentOption: FC<IsThereDocumentProps> = ({withAgreement=false,initialValues, tooltips, onCompleteSubmit, Template = TemplateFormDefault, buttons = {}, buttonSubmitProps, propsTemplate}:IsThereDocumentProps) => {
    const { translate } = useTranslate();
    const dispatch=useDispatch()
    const [first,setFirst]=useState()
    const options: Array<CheckboxButtonOptions<string, any>> = useMemo(()=>{
        const ops= [
            {
                value: 'upload',
                label: translate(
                    `MODULES.EQUITY.FORMS.IS_THERE_DOCUMENTS.UPLOAD`
                )
            },
            {
                value: 'later',
                label: translate(
                    `MODULES.EQUITY.FORMS.IS_THERE_DOCUMENTS.LATER`
                )
            },
            {
                value: 'generated',
                label: translate(
                    `MODULES.EQUITY.FORMS.IS_THERE_DOCUMENTS.GENERATED`
                )
            },
            {
                value: 'agreement',
                label: translate(`MODULES.EQUITY.FORMS.IS_THERE_DOCUMENTS.NO_AGREEMENT`)
            }
        ]
        
            
        
        return ops;
    },[translate])
    
    const refForm=useRef()
    return (
        <React.Fragment>
            <Form
                initialValues={initialValues || {}}
                schema={schema}
                onlySendDirty={false}
                onSubmit={onCompleteSubmit}
                ref={refForm} optionsForm = {{mode:"onChange"}}
            >
                <Template props={propsTemplate} buttons={{submit:<SubmitButton {...buttonSubmitProps}>Next</SubmitButton>,...buttons}}>
                    <ControllerInput
                        as={CheckboxButtonSimple}
                        name='documents'
                        // variant='col_1'
                        // className={classes.checkBox}
                        // classNameButton={classes.buttonCheckBox}
                        options={options}
                        // size ={"xs"}
                        onChange={value => {
                            if(tooltips) {
                                if(first) {
                                    dispatch(removeAssistantText());
                                    setFirst(false)
                                }
                                    switch (value) {
                                        case 'upload':
                                        _.get(tooltips, 'isUpload') && dispatch(addAssistantText(_.get(tooltips, 'isExist', "text")));
                                            _.get(tooltips, 'isUpload') && setFirst(true)
                                            break;
                                        case 'generated':
                                        _.get(tooltips, 'isGenerated') && dispatch(addAssistantText(_.get(tooltips, 'isNotExist', "text")));
                                        _.get(tooltips, 'isGenerated') && setFirst(true)
                                            break
                                        case 'later':
                                            _.get(tooltips, 'isLater') && dispatch(addAssistantText(_.get(tooltips, 'isNotExist', "text")));
                                            _.get(tooltips, 'isLater') && setFirst(true)
                                            break
                                }
                            }
                        }}
                    />
                </Template>
            </Form>
        </React.Fragment>
    )
}

export default IsThereDocumentOption

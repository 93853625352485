import { useMemo } from 'react';
import _ from "lodash";
import useSession from "src/modules/session/hooks/useSession";
import useGetStakeholdersGroupCompaniesLazy from "src/modules/startup/onBoarding/core/graphql/queries/useGetStakeholdersGroupCompaniesLazy";
import useCreateStakeholder from "src/modules/startup/onBoarding/core/graphql/mutations/useCreateStakeholder";
import useUpsertRole from 'src/modules/startup/Stakeholder/graphql/mutation/useUpsertRole';

const useServices = ({ initialValues }) => {
  const { startupId } = useSession();
  const { manualQuery: manualQueryStakeholdersGroupCompanies } = useGetStakeholdersGroupCompaniesLazy();
  const selectedCompany = initialValues?.company ?? null;

  const { createStakeholder } = useCreateStakeholder({
    showSuccessNotification: true,
    successMessage: 'STAKEHOLDER_ADD_SUCCESS'
  }, {});

  const { upsertComponentRole } = useUpsertRole({
    showSuccessNotification: false,
  }, {});
  
  return useMemo(() => ({

    initialData: async () => {
      const result = await manualQueryStakeholdersGroupCompanies();
      const groupCompanies = _.orderBy(result?.data?.getGroupCompanies, ['isHoldingCompany'], ['desc']);
      const stakeholders = _.get(result?.data, 'stakeholderList', []);
      const groupCompaniesMapped = groupCompanies.map(gc => ({
        disabled: gc?.isLocked,
        label: gc?.name,
        value: gc?.id,
      }));
      return { selectedCompany, startupId, groupCompanies, groupCompaniesMapped, stakeholders };
    },

    SaveStep: async (context, event) => {
      const isAnEntity = context?.AddShareholder?.shareholderType === 'corporate' ?? false;
      const data = isAnEntity ? context?.AddCorporate : context?.AddIndividual;
      const legalEntityStructure = context?.LegalEntityStructure?.legalEntityStructure ?? null;
      const searchID = _.find(context?.initialData?.stakeholders, (st) => st.id === data?.stakeholder?.id);
      const isUpdate = !_.isEmpty(searchID);
      const roles = [];
      _.forEach(data?.company, (comp) => {
        const role = {
          entityId: comp,
          entityType: 'GROUP_COMPANY',
          role: [
            {
              type: 'SHAREHOLDER'
            }
          ]
        };
        roles.push(role);
      });
      if (isUpdate) {
        const variables = {
          startupId,
          stakeholderId: data?.stakeholder?.id,
          stakeholderRolesData: {
            roles
          }
        };
        return await upsertComponentRole({ variables });
      } else {
        const variables = {
          startupId,
          stakeholderData: {
            stakeholder: {
              fullName: data?.stakeholder?.fullName || data?.fullName,
              isAnEntity: isAnEntity,
              companyType: isAnEntity ? legalEntityStructure : null,
            },
            roles
          }
        };
        return await createStakeholder({ variables });
      }
    },

  }), [initialValues]);
}

export default useServices;

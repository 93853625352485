import _ from "lodash"

const mapFoTActions = (template: any) => {

    const action: any = _.last(template.actions);
    let params = action.params;

    switch (action.event) {
        case "CANCEL_SHARE_ISSUANCE_EVENT":
            return {
                path: "capTable.mf.shares.transfer.view", params: { ...params, shareIssuanceId: action.entityId }
            };
        case "TRANSFER_SHARE_ISSUANCE_EVENT":
            return {
                path: "capTable.mf.shares.transfer.view", params: { ...params, shareIssuanceId: action.entityId }
            };
        case "AMEND_SHARE_CLASS_EVENT":
            return {
                path: "capTable.mf.shareClass.amend.view", params: { ...params, shareClassId: action.entityId }
            };
        case "AMEND_OPTION_PLAN_EVENT":
            return {
                path: "capTable.mf.options.amend.view", params: { ...params, optionPlanId: action.entityId }
            };
        case "CONVERT_CONVERTIBLE_EVENT":
            return {
                path: "capTable.mf.convertibles.view", params: { ...params, convertibleId: action.entityId }
            };
        case "CANCEL_CONVERTIBLE_EVENT":
            return {
                path: "capTable.mf.convertibles.view.cancel", params: { ...params, convertibleId: action.entityId }
            };
        case "TRANSFER_CONVERTIBLE_EVENT":
            return {
                path: "capTable.mf.convertibles.view.transaction", params: { ...params, convertibleId: action.entityId }
            };
        case "EXERCISE_OPTION_GRANT_EVENT":
            return {
                path: "capTable.mf.options.grant.viewExerciseManual", params: { ...params, optionGrantId: action.entityId }
            };
        case "LAPSE_OPTION_GRANT_EVENT":
            return {
                path: "capTable.mf.options.grant.viewLapse", params: { ...params, optionGrantId: action.entityId }
            };
        case "CANCEL_OPTION_GRANT_EVENT":
            return {
                path: "capTable.mf.options.grant.viewStopVesting", params: { ...params, optionGrantId: action.entityId }
            };
        default:
            return { path: "capTable.mf", params: { ...params } };
    }

}
export {
    mapFoTActions
}

import React, { useEffect } from 'react';
import { Constants } from '../../../../utils/constants'
import classes from "../ViewForms.module.scss";
import { useDispatch } from "react-redux";
import _ from "lodash";
import ServerConnect from "../../../../utils/ServerConnect";
import moment from "moment";
import helper from "../../../../utils/helper";
import { Date } from "../../../index";


const IndividualEntityView = (props) => {

    const dispatch = useDispatch();
    const { mode, data } = props;

    const downloadHandler = (file) => {
        let url = ServerConnect.getUrlFile(file.file.id);
        ServerConnect.downloadFileAsDoc(url, file.file.name);
    }

    const renderCountryName = (countryCode) => {
        return _.findKey(Constants.COUNTRYCODES, country => { return country == countryCode })
    }


    useEffect(() => {
    }, [,]);

    const renderDirectors = (entity) => {
        switch (_.get(entity, 'type', '')) {
            case 'individual':
                return (
                    <React.Fragment>
                        <hr className={`${classes.Separator} ${classes.modifiedSeparator}`} />
                        <div className={`${classes.ContainerRow}`}>
                            <label>Full Legal Name:</label>
                            <span>{_.get(entity, 'firstName', '') + ' ' + _.get(entity, 'lastName', '')}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>Email Address:</label>
                            <span className={classes.textExtended}>{_.get(entity, 'email', '')}</span>
                        </div>
                    </React.Fragment>
                );
            case 'company':
                return (
                    <React.Fragment>
                        <hr className={`${classes.Separator} ${classes.modifiedSeparator}`} />
                        <div className={`${classes.ContainerRow}`}>
                            <label>Company name as on Certificate Of Incorporation:</label>
                            <span>{_.get(entity, 'companyName', '')}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>Signatory Name:</label>
                            <span>{_.get(entity.signatory, 'firstName', '') + ' ' + _.get(entity.signatory, 'lastName', '')}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_ENTITY_EMAIL_ADDRESS_FIELD_VIEW')}:</label>
                            <span className={classes.textExtended}>{_.get(entity.signatory, 'email', '')}</span>
                        </div>
                    </React.Fragment>
                )
        }
    }


    let renderYesOrNo = (value) => {
        return value == false ? 'No' : 'Yes'
    }

    const avoidNullFields = (value) => {
        if (value == null) {
            return ''
        } return value
    }

    const getSignatoryName = (name) => {
        if (name == 'powerOfAttorney') {
            return 'Power Of Attorney'
        }
        else if (name == 'shareholder') {
            return 'Shareholder'
        }
        else {
            return name
        }
    }

    const renderEmptyInsteadNull = (value) => {
        return value == null ? '' : value
    }

    let renderTitleByMode = () => {
        switch (mode) {
            case Constants.QUESTIONNAIRE_KEYS.DIRECTORS:
                return 'Director Details'
            case Constants.QUESTIONNAIRE_KEYS.SHAREHOLDERS:
            case Constants.QUESTIONNAIRE_KEYS.SHAREHOLDEREDITABLE:
                return 'Shareholder Details'
            case Constants.QUESTIONNAIRE_KEYS.SIGNATORIES:
                return 'Signatories Details'
            case Constants.QUESTIONNAIRE_KEYS.DATA_CONTROLLER:
            case Constants.QUESTIONNAIRE_KEYS.DATA_CONTROLLER_ENTITY:
                return 'Appointed Data Controller'
            case Constants.QUESTIONNAIRE_KEYS.BENEFICIAL_OWNERS:
                return 'Beneficial Owner Details'
            case Constants.QUESTIONNAIRE_KEYS.AGREEMENTSDATA_ENTITY:
                return 'Agreement Signatory Details'
        }
    }

    const renderByType = () => {
        if (data.type == Constants.ENTITY_TYPES.INDIVIDUAL) {
            return (<React.Fragment>
                <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                    <label>{renderTitleByMode()}</label>
                </div>
                <hr className={`${classes.Separator} ${classes.modifiedSeparator}`} />
                <div className={`${classes.ContainerRow}`}>
                    <label>{helper.getTranslateTextByKey('USER_TABLE_FIELDS_TITLE_FIRSTNAME')}:</label>
                    <span>{_.get(data, 'firstName', '')}</span>
                </div>
                <div className={`${classes.ContainerRow}`}>
                    <label>{helper.getTranslateTextByKey('KYC_SURNAME_FIELD_VIEW')}:</label>
                    <span>{_.get(data, 'lastName', '')}</span>
                </div>
                <div className={`${classes.ContainerRow}`}>
                    <label>{helper.getTranslateTextByKey('KYC_BIRTH_DATE_FIELD_VIEW')}:</label>
                    <span><Date value={data.dateOfBirth} /></span>
                </div>
                {data ? (data.passportDoc ? <div className={`${classes.ContainerRow}`}>
                    <label></label>
                    <span className={classes.docButton} onClick={() => { downloadHandler(_.get(data, 'passportDoc', '')) }}>Passport</span>
                </div> : null) : null}

                <div className={`${classes.ContainerRow}`}>
                    <label>{helper.getTranslateTextByKey('KYC_OCCUPATION_FIELD_VIEW')}:</label>
                    <span>{_.get(data, 'occupation', '')}</span>
                </div>
                <hr className={`${classes.Separator} ${classes.modifiedSeparator}`} />
                <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                    <label>{helper.getTranslateTextByKey('KYC_RESIDENTIAL_ADDRESS_TITLE')}</label>
                </div>
                <hr className={`${classes.Separator} ${classes.modifiedSeparator}`} />
                <div className={`${classes.ContainerRow}`}>
                    <label>{helper.getTranslateTextByKey('KYC_RESIDENTIAL_ADDRESS_TITLE')}</label>
                    <span>{renderCountryName(_.get(data, 'address.country', ''))},<br />{_.get(data, 'address.state', '')},<br />{_.get(data, 'address.city', '')},<br />{_.get(data, 'address.address', '')},<br />{_.get(data, 'address.zipCode', '')}<br /></span>
                </div>
                {_.get(data, 'UAEVisaDoc', null) ? <div className={`${classes.ContainerRow}`}>
                    <label></label>
                    <span className={classes.docButton} onClick={() => { downloadHandler(_.get(data, 'UAEVisaDoc', '')) }}>UAE Visa</span>
                </div> : null}
                {_.get(data, 'proofOfAddress', false) ? <div className={`${classes.ContainerRow}`}>
                    <label></label>
                    <span className={classes.docButton} onClick={() => { downloadHandler(_.get(data, 'proofOfAddresDoc', '')) }}>Proof of Address</span>
                </div> : null}
                {_.get(data, 'thirdPartyAddressFormDoc', false) ? <React.Fragment> <div className={`${classes.ContainerRow}`}>
                    <label>         </label>
                    <span className={classes.docButton} onClick={() => { downloadHandler(_.get(data, 'thirdPartyAddressFormDoc', '')) }}>Third party address form</span>
                </div>
                </React.Fragment> : null}
                {_.get(data, 'thirdPartyAddressPassportDoc', false) ? <React.Fragment>
                    <div className={`${classes.ContainerRow}`}>
                        <label>    </label>

                        <span className={`${classes.docButton} ${classes.textExtended}`} onClick={() => { downloadHandler(_.get(data, 'thirdPartyAddressPassportDoc', '')) }}>Third parties address passport copy</span>
                    </div>
                </React.Fragment> : null}
                <hr className={`${classes.Separator} ${classes.modifiedSeparator}`} />
                <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                    <label>{helper.getTranslateTextByKey('KYC_CONTACT_DETAILS_TITLE')}</label>
                </div>
                <hr className={`${classes.Separator} ${classes.modifiedSeparator}`} />
                <div className={`${classes.ContainerRow}`}>
                    <label>{helper.getTranslateTextByKey('KYC_CONTACT_NUMBER_FIELD_VIEW')}</label>
                    <span>{_.get(data.contactNumber, 'prefix', '') + '-' + _.get(data.contactNumber, 'number', '') + '-' + avoidNullFields(_.get(data.contactNumber, 'extension', ''))}</span>
                </div>
                <div className={`${classes.ContainerRow}`}>
                    <label>{helper.getTranslateTextByKey('KYC_ENTITY_EMAIL_ADDRESS_FIELD_VIEW')}:</label>
                    <span className={classes.textExtended}>{_.get(data, 'email', '')}</span>
                </div>

                {/* PASSPORTS*/}

                <hr className={`${classes.Separator} ${classes.modifiedSeparator}`} />
                <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                    <label>Current Passport</label>
                </div>
                {_.get(data, 'passports', []).map(passp => {
                    if (passp.current) {
                        return (<React.Fragment>
                            <div className={`${classes.ContainerRow}`}>
                                <label>{helper.getTranslateTextByKey('KYC_NATIONALITY_FIELD_VIEW')}</label>
                                <span>{renderCountryName(_.get(passp, 'nationality', ''))}</span>
                            </div>
                            <div className={`${classes.ContainerRow}`}>
                                <label>{helper.getTranslateTextByKey('KYC_PASSPORT_NUMBER_FIELD_VIEW')}</label>
                                <span className={classes.textExtended}>{_.get(passp, 'passportNumber', '')}</span>
                            </div>
                            <div className={`${classes.ContainerRow}`}>
                                <label>{helper.getTranslateTextByKey('KYC_PASSPORT_EXPIRY_DATE_FIELD_VIEW')}</label>
                                <span>{_.get(passp, 'passportExpiryDate', '')}</span>
                            </div>
                            <div className={`${classes.ContainerRow}`}>
                                <label>Passport:</label>
                                <span className={classes.docButton} onClick={() => { downloadHandler(_.get(passp, 'passportDoc', '')) }}>Passport Image</span>
                            </div>
                            {_.get(passp, 'lastPagePassportDoc', '') ? <div className={`${classes.ContainerRow}`}>
                                <label>Last Page of Passport:</label>
                                <span className={classes.docButton} onClick={() => { downloadHandler(_.get(passp, 'lastPagePassportDoc', '')) }}>Passport Last Page</span>
                            </div> : null}
                            {_.get(passp, 'UAEPassportStampDoc', '') ? <div className={`${classes.ContainerRow}`}>
                                <label>UAE Passport Stamp:</label>
                                <span className={classes.docButton} onClick={() => { downloadHandler(_.get(passp, 'UAEPassportStampDoc', '')) }}>UAE Stamp</span>
                            </div> : null}
                            {_.get(passp, 'UIDDoc', '') ? <div className={`${classes.ContainerRow}`}>
                                <label>UID:</label>
                                <span className={classes.docButton} onClick={() => { downloadHandler(_.get(passp, 'UIDDoc', '')) }}>UID</span>
                            </div> : null}
                        </React.Fragment>)
                    }
                })}
                {_.find(_.get(data, 'passports', []), passp => {
                    return !passp.current
                }) ? (<React.Fragment> <hr className={`${classes.Separator} ${classes.modifiedSeparator}`} />
                    <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                        <label>Other Passports</label>
                    </div> </React.Fragment>) : null}
                {_.get(data, 'passports', []).map(passp => {
                    if (!passp.current) {
                        return (<React.Fragment>
                            <div className={`${classes.ContainerRow}`}>
                                <label>{helper.getTranslateTextByKey('KYC_NATIONALITY_FIELD_VIEW')}:</label>
                                <span>{renderCountryName(_.get(passp, 'nationality', ''))}</span>
                            </div>
                            <div className={`${classes.ContainerRow}`}>
                                <label>{helper.getTranslateTextByKey('KYC_PASSPORT_NUMBER_FIELD_VIEW')}:</label>
                                <span>{_.get(passp, 'passportNumber', '')}</span>
                            </div>
                            <div className={`${classes.ContainerRow}`}>
                                <label>{helper.getTranslateTextByKey('KYC_PASSPORT_EXPIRY_DATE_FIELD_VIEW')}:</label>
                                <span>{_.get(passp, 'passportExpiryDate', '')}</span>
                            </div>
                            <div className={`${classes.ContainerRow}`}>
                                <label>Passport:</label>
                                <span className={classes.docButton} onClick={() => { downloadHandler(_.get(passp, 'passportDoc', '')) }}>Passport Image</span>
                            </div>
                            {_.get(passp, 'lastPagePassportDoc', '') ? <div className={`${classes.ContainerRow}`}>
                                <label>Last Page of Passport:</label>
                                <span className={classes.docButton} onClick={() => { downloadHandler(_.get(passp, 'lastPagePassportDoc', '')) }}>Passport Last Page</span>
                            </div> : null}
                            {_.get(passp, 'UAEPassportStampDoc', '') ? <div className={`${classes.ContainerRow}`}>
                                <label>UAE Passport Stamp:</label>
                                <span className={classes.docButton} onClick={() => { downloadHandler(_.get(passp, 'UAEPassportStampDoc', '')) }}>UAE Stamp</span>
                            </div> : null}
                            {_.get(passp, 'UIDDoc', '') ? <div className={`${classes.ContainerRow}`}>
                                <label>UID:</label>
                                <span className={classes.docButton} onClick={() => { downloadHandler(_.get(passp, 'UIDDoc', '')) }}>UID</span>
                            </div> : null}
                        </React.Fragment>)
                    }

                })}
                {data.curriculumVitaeDoc &&(<>
                    <hr className={`${classes.Separator} `} />
                    <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                        <label>Curriculum Vitae</label>
                    </div>
                    <div className={`${classes.ContainerRow}`}>
                        <label>{helper.getTranslateTextByKey('KYC_CURRICULUM_VITAE_FIELD_VIEW')}:</label>
                        <span className={classes.docButton} onClick={() => { downloadHandler(_.get(data, 'curriculumVitaeDoc', '')) }}>CV File</span>
                    </div>
                </> )
                }

                {/* PEP */}

                <hr className={`${classes.Separator} ${classes.modifiedSeparator}`} />
                <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                    <label>{helper.getTranslateTextByKey('KYC_ENTITY_POLITICALLY_EXPOSED__FIELD_VIEW')}</label>
                </div>
                <div className={`${classes.ContainerRow}`}>
                    <label>{helper.getTranslateTextByKey('KYC_ENTITY_POLITICALLY_EXPOSED_FIELD_VIEW')}:</label>
                    <span>{_.get(data, 'politicallyExposedPerson', '') ? 'Yes' : 'No'}</span>
                </div>
                {_.get(data, 'politicallyExposedPerson', '') ? <div className={`${classes.ContainerRow}`}>
                    <label>    </label>
                    <span className={classes.textExtended}>{_.get(data, 'politicallyExposedPersonDescription', '')}</span>
                </div> : null}
                <div className={`${classes.ContainerRow}`}>
                    <label>{helper.getTranslateTextByKey('KYC_ENTITY_FAMILY_POLITICALLY_EXPOSED_FIELD_VIEW')}:</label>
                    <span>{_.get(data, 'familyMemberOfAPEP', '') ? 'Yes' : 'No'}</span>
                </div>
                {_.get(data, 'familyMemberOfAPEP', '') ? <div className={`${classes.ContainerRow}`}>
                    <label>    </label>
                    <span className={classes.textExtended}>{_.get(data, 'familyMemberOfAPEPDescription', '')}</span>
                </div> : null}
                <div className={`${classes.ContainerRow}`}>
                    <label>{helper.getTranslateTextByKey('KYC_ENTITY_ASSOCIATE_POLITICALLY_EXPOSED_FIELD_VIEW')}:</label>
                    <span>{_.get(data, 'closeAssociateOfAPEP', '') ? 'Yes' : 'No'}</span>
                </div>
                {_.get(data, 'closeAssociateOfAPEP', '') ? <div className={`${classes.ContainerRow}`}>
                    <label>    </label>
                    <span className={classes.textExtended}>{_.get(data, 'closeAssociateOfAPEPDescription', '')}</span>
                </div> : null}
                <hr className={`${classes.Separator} ${classes.modifiedSeparator}`} />
                <div className={`${classes.ContainerRow}`}>
                    <label>{helper.getTranslateTextByKey('KYC_ENTITY_SOURCE_WEALTH_FIELD_VIEW')}:</label>
                    <span>{_.get(data, 'sourcesOfWealth', '')}</span>
                </div>
                <div className={`${classes.ContainerRow}`}>
                    <label>{helper.getTranslateTextByKey('KYC_ENTITY_SOURCE_FUNDS_FIELD_VIEW')}:</label>
                    <span className={classes.textExtended}>{_.get(data, 'sourceOfFunds', '')}</span>
                </div>
                <hr className={`${classes.Separator} ${classes.modifiedSeparator}`} />


            </React.Fragment>)
        } else {
            return (
                <React.Fragment>
                    <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                        <label>{renderTitleByMode()}</label>
                    </div>
                    {/* COMPANY DETAILS */}
                    {/*  */}
                    <hr className={`${classes.Separator} ${classes.modifiedSeparator}`} />
                    <div className={`${classes.ContainerRow}`}>
                        <label>{helper.getTranslateTextByKey('KYC_COMPANY_NAME_FIELD_VIEW')}:</label>
                        <span>{_.get(data, 'companyName', '')}</span>
                    </div>
                    <div className={`${classes.ContainerRow}`}>
                        <label>{helper.getTranslateTextByKey('KYC_COMPANY_ALTERNATIVE_NAME_FIELD_VIEW')}:</label>
                        <span>{_.get(data, 'otherTradingName', '')}</span>
                    </div>
                    <div className={`${classes.ContainerRow}`}>
                        <label>{helper.getTranslateTextByKey('KYC_COMPANY_CRN_NUMBER_FIELD_VIEW')}:</label>
                        <span>{_.get(data, 'registrationNumber', '')}</span>
                    </div>
                    <div className={`${classes.ContainerRow}`}>
                        <label>{helper.getTranslateTextByKey('KYC_COMPANY_TRN_NUMBER_FIELD_VIEW')}:</label>
                        <span>{_.get(data, 'TRNNumber', '')}</span>
                    </div>
                    <div className={`${classes.ContainerRow}`}>
                        <label>{helper.getTranslateTextByKey('KYC_COMPANY_COUNTRY_INCORPORATION_FIELD_VIEW')}:</label>
                        <span>{renderCountryName(_.get(data, 'countryOfIncorporation.code', ''))}</span>
                    </div>
                    <div className={`${classes.ContainerRow}`}>
                        <label>{helper.getTranslateTextByKey('KYC_COMPANY_JURISDICTION_INCORPORATION_FIELD_VIEW')}:</label>
                        <span style={{ "textFormat": "lowercase" }}>{_.get(data, 'jurisdictionOfIncorporation', '')} </span>
                    </div>
                    {_.get(data, 'VATRegistrationCertificateDoc', null) ? <div className={`${classes.ContainerRow}`}>
                        <label>{'       '}</label>
                        <span className={`${classes.docButton} ${classes.textExtended}`} onClick={() => { downloadHandler(_.get(data, 'VATRegistrationCertificateDoc', '')) }}>VAT Registration Certificate</span>
                    </div> : null}
                    {/* REGISTERED ADDRESS */}
                    {/*  */}
                    <hr className={`${classes.Separator} ${classes.modifiedSeparator}`} />
                    <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                        <label>{helper.getTranslateTextByKey('KYC_REGISTERED_ADDRESS_FIELD_VIEW')}</label>
                    </div>
                    <hr className={`${classes.Separator} ${classes.modifiedSeparator}`} />
                    <div className={`${classes.ContainerRow}`}>
                        <label>{helper.getTranslateTextByKey('KYC_REGISTERED_ADDRESS_FIELD_VIEW')}</label>
                        <span>{renderCountryName(_.get(data, 'principalAddress.country', ''))},<br />{_.get(data, 'principalAddress.state', '')},<br />{_.get(data, 'principalAddress.city', '')},<br />{_.get(data, 'principalAddress.address', '')},<br />{_.get(data, 'principalAddress.zipCode', '')}<br /></span>
                    </div>
                    {_.get(data, 'proofOfAddressDoc', null) ? <div className={`${classes.ContainerRow}`}>
                        <label>{'       '}</label>
                        <span className={`${classes.docButton} ${classes.textExtended}`} onClick={() => { downloadHandler(_.get(data, 'proofOfAddressDoc', '')) }}>Proof Of Address</span>
                    </div> : null}
                    <div className={`${classes.ContainerRow}`}>
                        <label>{helper.getTranslateTextByKey('KYC_COMPANY_PRINCIPAL_BUSINESS_PLACE_FIELD_VIEW')}:</label>
                        <span>{_.get(data, 'samePrincipalPlaceOfBusinessAndLegal', '') ? 'Yes' : 'No'}</span>
                    </div>
                    {!_.get(data, 'samePrincipalPlaceOfBusinessAndLegal', '') ?
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_COMPANY_OTHER_ADDRESS_FIELD_VIEW')}:</label>
                            <span>{renderCountryName(_.get(data, 'address.country', ''))},<br />{_.get(data, 'address.state', '')},<br />{_.get(data, 'address.city', '')},<br />{_.get(data, 'address.address', '')},<br />{_.get(data, 'address.zipCode', '')}<br /></span>
                        </div> : null}
                    {_.get(data, 'certificateOfIncorporationOrCommercialLicenseDoc', null) ? <div className={`${classes.ContainerRow}`}>
                        <label></label>
                        <span className={classes.docButton} onClick={() => { downloadHandler(_.get(data, 'certificateOfIncorporationOrCommercialLicenseDoc', '')) }}>Certificate of Incorporation</span>
                    </div> : null}
                    {_.get(data, 'memorandumAndArticlesOfASsociationDoc', null) ? <div className={`${classes.ContainerRow}`}>
                        <label>{'       '}</label>
                        <span className={`${classes.docButton} ${classes.textExtended}`} onClick={() => { downloadHandler(_.get(data, 'memorandumAndArticlesOfASsociationDoc', '')) }}>Memorandum and Articles of Association</span>
                    </div> : null}
                    {/* SIGNATORY DETAILS */}
                    {/*  */}
                    <hr className={`${classes.Separator} ${classes.modifiedSeparator}`} />
                    <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                        <label>{helper.getTranslateTextByKey('KYC_SIGNATORY_DETAILS_TITLE')}</label>
                    </div>
                    <hr className={`${classes.Separator} ${classes.modifiedSeparator}`} />
                    <div className={`${classes.ContainerRow}`}>
                        <label>{helper.getTranslateTextByKey('USER_TABLE_FIELDS_TITLE_FIRSTNAME')}:</label>
                        <span>{_.get(data.signatory, 'firstName', '')}</span>
                    </div>
                    <div className={`${classes.ContainerRow}`}>
                        <label>{helper.getTranslateTextByKey('KYC_SURNAME_FIELD_VIEW')}:</label>
                        <span>{_.get(data.signatory, 'lastName', '')}</span>
                    </div>
                    <div className={`${classes.ContainerRow}`}>
                        <label>{helper.getTranslateTextByKey('KYC_ENTITY_JOB_TITLE_FIELD_VIEW')}:</label>
                        <span>{_.get(data.signatory, 'jobTitle', '')}</span>
                    </div>
                    <div className={`${classes.ContainerRow}`}>
                        <label>{helper.getTranslateTextByKey('KYC_ENTITY_EMAIL_ADDRESS_FIELD_VIEW')}:</label>
                        <span className={classes.textExtended}>{_.get(data.signatory, 'email', '')}</span>
                    </div>
                    <div className={`${classes.ContainerRow}`}>
                        <label>{helper.getTranslateTextByKey('KYC_NATIONALITY_FIELD_VIEW')}:</label>
                        <span className={classes.textExtended}>{renderCountryName(_.get(data.signatory, 'nationality', ''))}</span>
                    </div>
                    <div className={`${classes.ContainerRow}`}>
                        <label>{helper.getTranslateTextByKey('KYC_PASSPORT_NUMBER_FIELD_VIEW')}:</label>
                        <span className={classes.textExtended}>{_.get(data.signatory, 'passportNumber', '')}</span>
                    </div>
                    <div className={`${classes.ContainerRow}`}>
                        <label>{helper.getTranslateTextByKey('KYC_PASSPORT_EXPIRY_DATE_FIELD_VIEW')}:</label>
                        <span>{moment(_.get(data.signatory, 'passportExpiryDate', '')).format("DD-MMM-YYYY").toUpperCase().replace(/-/g, ' ')}</span>
                    </div>
                    {_.get(data.signatory, 'signatoryPassportDoc', null) ? <div className={`${classes.ContainerRow}`}>
                        <label>{helper.getTranslateTextByKey('KYC_PASSPORT_DOC_FIELD_VIEW')}:</label>
                        <span className={classes.docButton} onClick={() => { downloadHandler(_.get(data.signatory, 'signatoryPassportDoc', '')) }}>Passport Image</span>
                    </div> : null}
                    <hr className={`${classes.Separator} ${classes.modifiedSeparator}`} />

                    {/* KEY COMPANY DOCUMENTS*/}
                    <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                        <label>{helper.getTranslateTextByKey('KYC_KEY_DOCS_TITLE')}</label>
                    </div>
                    <hr className={`${classes.Separator} ${classes.modifiedSeparator}`} />
                    {_.get(data, 'showingDirectorsRegistrationDoc', null) ? <div className={`${classes.ContainerRow}`}>
                        <label>{helper.getTranslateTextByKey('KYC_DOC_SHOW_DIRECTOR_FIELD')}:</label>
                        <span className={classes.docButton} onClick={() => { downloadHandler(_.get(data, 'showingDirectorsRegistrationDoc', '')) }}>Directors Register</span>
                    </div> : null}
                    {_.get(data, 'showingShareholdersRegistrationDoc', null) ? <div className={`${classes.ContainerRow}`}>
                        <label>{helper.getTranslateTextByKey('KYC_DOC_SHOW_SHARE_FIELD')}:</label>
                        <span className={classes.docButton} onClick={() => { downloadHandler(_.get(data, 'showingShareholdersRegistrationDoc', '')) }}>Shareholders Register</span>
                    </div> : null}
                    {_.get(data, 'certificateOfIncorporationOrCommercialLicenseDoc', null) ? <div className={`${classes.ContainerRow}`}>
                        <label>{helper.getTranslateTextByKey('KYC_CERTIFICATE_COMERCIAL_LICENSE_FIELD')}:</label>
                        <span className={classes.docButton} onClick={() => { downloadHandler(_.get(data, 'certificateOfIncorporationOrCommercialLicenseDoc', '')) }}>Commercial Licence</span>
                    </div> : null}
                    {_.get(data, 'memorandumAndArticlesOfAssociationDoc', null) ? <div className={`${classes.ContainerRow}`}>
                        <label>{helper.getTranslateTextByKey('KYC_MEMORANDUM_FIELD')}:</label>
                        <span className={classes.docButton} onClick={() => { downloadHandler(_.get(data, 'memorandumAndArticlesOfAssociationDoc', '')) }}>Memorandum</span>
                    </div> : null}



                    {data ? (data.clientKeyContact ?
                        (<React.Fragment>
                            <hr className={`${classes.Separator} ${classes.modifiedSeparator}`} />
                            <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                                <label>{helper.getTranslateTextByKey('KYC_KEY_CLIENT_CONTRACT_TITLE')}</label>
                            </div>
                            <hr className={`${classes.Separator} ${classes.modifiedSeparator}`} />
                            <div className={`${classes.ContainerRow}`}>
                                <label>{helper.getTranslateTextByKey('KYC_CONTACT_FULL_NAME')}:</label>
                                <span>{data.clientKeyContact.fullName}</span>
                            </div>
                            <div className={`${classes.ContainerRow}`}>
                                <label>{helper.getTranslateTextByKey('KYC_CONTACT_PHONE_NUMBER')}:</label>
                                <span>{data.clientKeyContact.contactNumber.prefix + '-' + data.clientKeyContact.contactNumber.number + '-' + renderEmptyInsteadNull(data.clientKeyContact.contactNumber.extension)}</span>
                            </div>
                            <div className={`${classes.ContainerRow}`}>
                                <label>{helper.getTranslateTextByKey('KYC_CONTACT_EMAIL_ADDRESS_FIELD_VIEW')}:</label>
                                <span className={classes.textExtended}>{data.clientKeyContact.email}</span>
                            </div>
                            <hr className={`${classes.Separator} ${classes.modifiedSeparator}`} />
                        </React.Fragment>)
                        : null) : null}
                    {data ? (data.financeContact ? (<React.Fragment>
                        <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                            <label>{helper.getTranslateTextByKey('KYC_FINANCE_CLIENT_CONTRACT_TITLE')}</label>
                        </div>
                        <hr className={`${classes.Separator} ${classes.modifiedSeparator}`} />
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_CONTACT_FULL_NAME')}:</label>
                            <span>{data.financeContact.fullName}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_CONTACT_PHONE_NUMBER')}:</label>
                            <span>{data.financeContact.contactNumber.prefix + '-' + data.financeContact.contactNumber.number + '-' + renderEmptyInsteadNull(data.financeContact.contactNumber.extension)}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_CONTACT_EMAIL_ADDRESS_FIELD_VIEW')}:</label>
                            <span className={classes.textExtended}>{data.financeContact.email}</span>
                        </div>
                        <hr className={`${classes.Separator} ${classes.modifiedSeparator}`} />
                    </React.Fragment>) : null) : null}
                    {/* SOURCE OF WEALTH */}
                    {/*  */}
                    <hr className={`${classes.Separator} ${classes.modifiedSeparator}`} />
                    <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                        <label>{helper.getTranslateTextByKey('KYC_ENTITY_SOURCE_WEALTH_FIELD_VIEW')}</label>
                    </div>
                    <div className={`${classes.ContainerRow}`}>
                        <label>{helper.getTranslateTextByKey('KYC_ENTITY_SOURCE_FUNDS_FIELD_VIEW')}:</label>
                        <span>{_.get(data, 'sourceOfFunds', '')}</span>
                    </div>
                    {/* DIRECTORS */}
                    {/*  */}
                    <React.Fragment>
                        <hr className={`${classes.Separator} ${classes.modifiedSeparator}`} />
                        <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                            <label>{helper.getTranslateTextByKey('KYC_ENTITY_DIRECTORS_MANAGERS_FIELD_VIEW')}</label>
                        </div>
                        {_.get(data.directors, 'individuals', []).map(ind => {
                            return renderDirectors(ind)
                        })}
                        {_.get(data.directors, 'companies', []).map(comp => {
                            return renderDirectors(comp)
                        })}</React.Fragment>


                    {/* SHAREHOLDERS */}
                    {/*  */}
                    {mode !== 'ENTITY' ? null : <React.Fragment><hr className={`${classes.Separator} ${classes.modifiedSeparator}`} />
                        <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                            <label>{helper.getTranslateTextByKey('QUESTIONARY_SHAREHOLDER_DIRECTORS_TEXT_SHAREHOLDERS')}</label>
                        </div>
                        {_.get(data.shareholders, 'individuals', []).map(ind => {
                            return renderDirectors(ind)
                        })}
                        {_.get(data.shareholders, 'companies', []).map(comp => {
                            return renderDirectors(comp)
                        })}</React.Fragment>}

                </React.Fragment>
            )
        }
    }


    return (
        <React.Fragment>
            {renderByType()}

        </React.Fragment>
    )
}

export default IndividualEntityView;

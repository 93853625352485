import { createMachine } from "xstate";

const machine = createMachine({
  id: "ReviewAuthorisedSignatory",
  initial: "initialData",
  states: {
    initialData: {
      invoke: {
        src: "initialData",
        id: "initialData",
        onDone: [
          {
            target: "ReviewAndConfirmStep",
            actions: ["setContextAfterInvoke"],
          },
        ],
      },
    },
    ReviewAndConfirmStep: {
      on: {
        NEXT: {
          target: "EvaluateNextStep",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    EvaluateNextStep: {
      always: [
        {
          cond: "correct",
          target: "SaveStep",
        },
        {
          cond: "nomineeQuestion",
          target: "ClaraNomineeStep",
        },
      ],
    },
    ClaraNomineeStep: {
      on: {
        NEXT: {
          target: "EvaluateNominee",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
        PREV: {
          target: "ReviewAndConfirmStep",
        },
      },
    },
    EvaluateNominee: {
      always: [
        {
          cond: "useNominee",
          target: "AssignSignatoriesStep",
        },
        {
          cond: "dontUseNominee",
          target: "AssignSignatoryStep",
        },
      ],
    },
    EvaluatePreviousFrom:{
      always: [
        {
          cond: "previousClaraNominee",
          target: "ClaraNomineeStep",
        },
        {
          cond: "previousIsSecondary",
          target: "IsThereSecondarySignatoryStep",
        }
      ],
    },
    AssignSignatoriesStep: {
      on: {
        NEXT: {
          target: "UAEInfoStep",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
        PREV: {
          target: "EvaluatePreviousFrom",
        },
      },
    },
    AssignSignatoryStep: {
      on: {
        NEXT: {
          target: "EvaluateThirdParty",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
        PREV: {
          target: "ClaraNomineeStep",
        },
      },
    },
    EvaluateThirdParty: {
      always: [
        {
          cond: "thirdParty",
          target: "CreateStakeholderStep",
        },
        {
          cond: "notThirdParty",
          target: "EvaluateIsSecondary",
        }
      ],
    },
    CreateStakeholderStep: {
      on: {
        NEXT: {
          target: "EvaluateIsSecondary",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
        PREV: {
          target: "AssignSignatoryStep",
        },
      },
    },
    EvaluateIsSecondary:{
      always: [
        {
          cond: "isSecondarySignor",
          target: "UAEInfoStep",
        },
        {
          cond: "isNotSecondarySignor",
          target: "EvaluateHasNoGCCorUEAStakeholders",
        },
      ],
    },
    EvaluateHasNoGCCorUEAStakeholders:{
      always: [
        {
          cond: "hasNotGCCOrUAE",
          target: "IsThereSecondarySignatoryStep",
        },
        {
          cond: "hasNotNotGCCOrUAE",
          target: "SaveStep",
        }
      ],
    },
    IsThereSecondarySignatoryStep:{
      on:{
        NEXT: {
          target: "EvaluateIsThereSecondarySingatory",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
        PREV: {
          target: "ClaraNomineeStep",
        },
      }
    },
    EvaluateIsThereSecondarySingatory:{
      always:[
        {
          cond: "isThereSecondary",
          target: "AssignSignatoriesStep",
        },
        {
          cond: "thereIsNoSecondary",
          target: "SaveStep",
        }
      ]
    },
    UAEInfoStep: {
      on: {
        NEXT: {
          target: "SaveStep",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
        PREV: {
          target: "EvaluateNominee",
        },
      },
    },
    SaveStep: {
      invoke: {
        src: "SaveStep",
        id: "SaveStep",
        onDone: [
          {
            target: "finish",
          },
        ],
        onError: [
          {
            target: "cancel",
          },
        ],
      },
    },
    cancel: {
      entry: "cancelMachine",
      type: "final",
    },
    finish: {
      entry: "stopMachine",
      type: "final",
    },
  },
});

export default machine;

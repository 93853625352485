import { gql, useQuery } from '@apollo/client';
import {get, orderBy} from 'lodash'
const GET_GROUP_COMPANIES = gql`
query getGroupCompanies($startupId: ID!) {
    getGroupCompanies(startupId: $startupId) {
      id
      kind
      name
      isLocked
      transactionCurrency
      isHoldingCompany
    }
  }
`
function useGetGroupCompanies(options = {}) {
  const { data, ...rest } = useQuery(GET_GROUP_COMPANIES, options);

  return { data: orderBy(get(data, 'getGroupCompanies', []),['isHoldingCompany'],['desc']), ...rest }
}

export default useGetGroupCompanies;

import _ from 'lodash';
import React, { FC } from "react";
import useSession from "../../../../modules/session/hooks/useSession";
import Button from "../../../generic/components/Buttons/Button";
import ModalAssistant, { ModalAssistantProps } from "../../../generic/components/Modal/ModalAssistant";
import useModal from "../../../generic/hooks/useModal";
import useTranslate from "../../../generic/hooks/useTranslate";
import TemplateWizard from "../../../generic/templates/Modal/TemplateWizard";
import RenameForm from '../../forms/Rename';
import useRenameDocument from '../../mutations/useRenameDocument';
interface RenameProps extends ModalAssistantProps {
  document: object;
  onClose(): void;
  onComplete(): ()=> void;
}

const Rename: FC<RenameProps> = ({
  open = false,
  document,
  onComplete,
  ...props
}: RenameProps) => {
  const { translate } = useTranslate();
  const { closeModal: onClose } = useModal();
  const handleCancel = () => {
    onClose();
  }

  const { startupId } = useSession();

  const [ renameDocument ] = useRenameDocument({
    successMessage: "MODULES.DOCUMENTS.RENAME_MESSAGE",
  });

  
  const handleCompleteSubmit = async (values) => {

    const variables = {
      startupId: startupId,
      agreementId: _.get( document,'agreementId'),
      agreement: {title:_.get( values,'name')} ,
    };

    await renameDocument ({ variables },{paramsSuccessMessage:{oldName:`${_.get(document, 'documentName')}`,newName:`${_.get( values,'name')}`}})

    onComplete && onComplete();
    onClose();
  }

  return (
    <ModalAssistant open={open}>
        <RenameForm 
            initialValues={undefined}
            onCompleteSubmit={handleCompleteSubmit}
            defaultValues={ {documentName:_.get(document, 'documentName')}}
            Template={TemplateWizard}
            propsTemplate={{
              title: translate("MODULES.DOCUMENTS.RENAME_TITLE") ,
              subTitle:  translate("MODULES.DOCUMENTS.RENAME_SUBTITLE", {documentName:`${_.get(document, 'documentName')}`}) ,
            }}
            buttons={{ 
            cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button>
            }}
        />
    </ModalAssistant>

  );
};

export default Rename;

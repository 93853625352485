import { default as React, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import * as Yup from "yup";
import Form from "src/components/forms/Form/Form";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import Button from "src/modules/generic/components/Buttons/Button";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import {
  clearAssistatText,
  addAssistantText,
} from "src/modules/generic/store/action";
import TextInput from "src/components/inputs/Text/TextInput";
import CheckboxButtonSimple, {
  CheckboxButtonOptions,
} from "../../../../../../../../../../modules/generic/components/Inputs/CheckboxButtonSimple";
import classes from "../../../../../../../../../../modules/startup/GroupCompany/ADGM/AuhorisedSignatoryModal/wizard/submachines/NomineeServiceModal/wizard/steps/classes.module.scss";
import TemplateLabel from "../../../../../../../../../../modules/generic/components/Inputs/LabelInput";
import CalendarInputDate from "../../../../../../../../../../components/inputs/Calendars/CalendarInputDate";

function UAEInfoStep() {
  const { context, cancel, next, prev } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const [eGate, setEGate] = useState(false);

  const initialValues = useMemo(() => {
    return context?.UAEInfoStep ?? {};
  }, [context]);

  const handleCancel = () => {
    cancel();
  };

  const handleSubmit = async (values) => {
    next(values);
  };

  const handlePrev = () => prev();

  useEffect(() => {
    dispatch(
      addAssistantText(
        translate(
          "MODULES.RENEWALS_ADGM.REVIEW_ADGM_AUTHORISED_SIGNATORY.UAE_STEP.TOOLTIP"
        )
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const schema = Yup.object().shape({
    howDidTheyEnter: Yup.string().nullable().required("Required"),
    entryDate: Yup.date()
      .nullable()
      .typeError("Must be a date")
      .when(["howDidTheyEnter"], {
        is: (value) => {
          return value === "E_GATE";
        },
        then: Yup.date()
          .nullable()
          .required("Required")
          .typeError("Add a valid date"),
        otherwise: Yup.date().nullable().typeError("Must be a date").optional(),
      }),
  });

  const options: Array<CheckboxButtonOptions<string>> = [
    {
      value: "E_GATE",
      label: "E-Gate",
    },
    {
      value: "UAE_ENTRY_STAMP",
      label: "UAE entry stamp",
    },
  ];

  const handleChangeSelection = (value) => {
    if (value.includes("E_GATE")) {
      setEGate(true);
    } else {
      setEGate(false);
    }
  };

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title:'How did they enter the UAE?',
          skeleton: null,
          subTitle: translate(
            "MODULES.RENEWALS_ADGM.REVIEW_ADGM_AUTHORISED_SIGNATORY.SUBTITLE"
          ),
          variant: "simple",
        }}
        buttons={{
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("BUTTON_CANCEL")}
            </Button>
          ),
          submit: <SubmitButton>Next</SubmitButton>,
          previous: (
            <Button onClick={handlePrev} variant="secondary">
              Previous
            </Button>
          ),
        }}
      >
        <ControllerInput
          render={CheckboxButtonSimple}
          defaultValue={[]}
          name="howDidTheyEnter"
          options={options}
          classNameButton={classes.buttonCheckBox}
          size="lg"
          onChange={handleChangeSelection}
        />
        {eGate && (
          <TemplateLabel label="Entry date" isRequired={true}>
            <ControllerInput
              render={CalendarInputDate}
              name="entryDate"
              placeholder="dd/mm/yyyy"
            />
          </TemplateLabel>
        )}
      </TemplateWizard>
    </Form>
  );
}

export default UAEInfoStep;

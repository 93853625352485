import { assign } from 'xstate';
import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import machine from './machine';
import TemplateWizardSkeletonMachine from '../../../../../modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import useSession from "../../../../../modules/session/hooks/useSession";
import StepConstitutionalDocuments from './Steps/ConstitutionalDocuments';
import useTranslate from "../../../../../modules/generic/hooks/useTranslate";
import Wizard from '../../../../../modules/generic/components/Wizard/v2';
import ModalAssistant from '../../../../../modules/generic/components/Modal/ModalAssistant'
import useModal from "../../../../../modules/generic/hooks/useModal";
import { clearAssistatText } from "../../../../../modules/generic/store/action";
import useConstitutionalDocuments from "./hooks/useConstitutionalDocuments";
import useUploadBulkDocuments from './hooks/useUploadBulkDocuments'
import { useParams } from 'react-router';

/**
 * Wizard to Complete Stakeholders
 * @returns
 */

const ModalConstitutionalDocuments = ({
  initialValues,
  open: openParam = false,
  props
}) => {

  const [open, setOpen] = useState(openParam);
  const { translate } = useTranslate();
  const { mutation: uploadDocuments } = useUploadBulkDocuments();
  const { closeModal: onClose } = useModal();
  const { mutation } = useConstitutionalDocuments();
  const { startupId } = useSession();
  const { id: companyId } = useParams();

  const steps = useMemo(() => ({
    confirmCompleStakeholders: {
      render: TemplateWizardSkeletonMachine,
      renderProps: {
        title: translate('MODULES.STAKEHOLDER.EDIT_SOURCE_OF_FUNDS.TITLE'),
        subTitle: translate('MODULES.STAKEHOLDER.EDIT_SOURCE_OF_FUNDS.SUBTITLE'),
      },
      stepperPosition: 0
    },
    initialStatus: {
      render: StepConstitutionalDocuments,
      stepperPosition: 0
    },
  }), []);

  const guards = {
  };

  const services = {
    ConfirmCompleStakeholders: async (context, event) => {
      const files = _.get(context, "initialStatus.files", "");
      const documents = [];
      _.forEach(files, (file) => {
        documents.push({
          title: file.name,
          file: file,
          type: "OTHER_CONSTITUTIONAL_DOC",
          parties: [],
          status: "Uploaded",
          subCategories: ["constitutionalDocuments"],
          groupCompanies: [
            {
              groupCompany: initialValues?.groupCompanyId ?? companyId,
            }
          ],
        })
      })
      return await uploadDocuments({ variables: { startupId, documents: documents } })
    }
  };

  const actions = {
    setContextAfterSelect: assign((context, event) => {
      const values = _.get(event, 'payload');
      const solution = { ...context, ...values };
      return solution;
    }),
  };

  const handleStopMachine = () => {
    onClose();
    setOpen(false);
    clearAssistatText()
  }

  return (
    <ModalAssistant open={open}>
      <Wizard
        guards={guards}
        machine={machine}
        steps={steps}
        services={services}
        actions={actions}
        onStopMachine={handleStopMachine}
      />
    </ModalAssistant>
  );
}

export default ModalConstitutionalDocuments;

import React, {useState} from 'react';
import classes from './PortalDocuments.module.scss';
import {Constants} from '../../../../../utils/constants';
import helper from '../../../../../utils/helper';
import greenCheck from '../../../../../../images/greenCheck.svg';
import {Avatar} from "../../../../index";
import _ from "lodash";
import {useDispatch} from "react-redux";
import Modal from '../../../../Modal/Modal/Modal';
import ServerConnect from "../../../../../utils/ServerConnect";
import Loading from "../../../../Loading/Loading";


const ClientReview = (props) => {

    const { data } = props;
    const [showModal, setShowModal] = useState(false);
	const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();


    const handleRequestApproval = ()=>{

        const mutation = `mutation sendRequestReviewClientApproval($matterId: ID!) {
            sendRequestReviewClientApproval(matterId: $matterId)
          }`;
		setLoading(true);
        const matterId =props.id ;
		ServerConnect.graphQlMutation(mutation,{matterId})
			.then(result=>{
         		setLoading(false);
				setShowModal(true);
		})
    };




    return (
        <React.Fragment>
			<Loading show={loading} showChildren={true} loadingStyle={{ position: "fixed", zIndex: 1000 }}>
				<div className={classes.container}>
					<p className={classes.title}>{helper.getTranslateTextByKey(Constants.TRANSLATES.CLIENT_APPROVAL_TITLE)}</p>
					<p className={classes.subTitle}>{helper.getTranslateTextByKey(Constants.TRANSLATES.CLIENT_APPROVAL_SUBTITLE)}</p>
					<hr className={`${classes.Separator}`} />
				</div>

				<div className={classes.container}>
					{_.get(props.data, 'documents', []).map(doc => {
						return (
							<React.Fragment>
								<div>
									<img className={classes.greenCheck} src={greenCheck} /> <p className={classes.docName}>{doc.file.name}</p>
								</div>
							</React.Fragment>
						)
					})}
					<hr className={`${classes.Separator}`} />
				</div>
				<div className={classes.container}>
					<p className={classes.approvalTitle}>{helper.getTranslateTextByKey(Constants.TRANSLATES.APPROVAL_TITLE)}</p>
					<div className={classes.FieldValueContainer}>
						<label>{helper.getTranslateTextByKey(Constants.TRANSLATES.MAIN_CLIENT)}</label><span><Avatar className={classes.Avatar} name={`${_.get(props.data, 'clientFullName')}`} showLabel={false} />{_.get(props.data, 'clientFullName')}</span>
					</div>

					<div className={classes.FieldValueContainer}>
						<label>{helper.getTranslateTextByKey(Constants.TRANSLATES.KYC_ENTITY_EMAIL_ADDRESS_FIELD_VIEW)}</label><span>{_.get(props.data, 'email')}</span>
					</div>
					<hr className={`${classes.Separator}`} />
				</div>
				<div className={`${classes.container} ${classes.botonera}`}>
					<button onClick={() => dispatch({ type: 'GO_TO', page: Constants.PAGES.viewMatter, params: { id: props.id, tab:'incorporation' } })} className={`${classes.buttonCancel} `}>
						<span>{helper.getTranslateTextByKey(Constants.TRANSLATES.BUTTON_CANCEL)}</span>
					</button>
					<button onClick={handleRequestApproval} className={`${classes.buttonBlue}`}>
						<span>{helper.getTranslateTextByKey(Constants.TRANSLATES.REQUEST_APPROVAL)}</span>
					</button>
				</div>
				<Modal open={showModal} showHeader={false} className={{ modal: classes.modal }}>
					<div className={classes.containerModal}>
						<span> <img className={classes.greenCheck} src={greenCheck} />Your Approval request has been sent! </span>
						<div className={classes.modalContainerButton}>
							<button className={`${classes.buttonBlue} ${classes.buttonModal}`} onClick={() => dispatch({ type: 'GO_TO', page: Constants.PAGES.viewMatter, params: { id: props.id, tab:'incorporation' } })} >Return to Incorporation</button>
						</div>
					</div>
				</Modal>
			</Loading>
        </React.Fragment >
    )
}

export default ClientReview;

import _ from "lodash"
import React, { FC, useRef, useEffect } from 'react'
import * as Yup from 'yup'
import Form from '../../../../components/forms/Form/Form'
import TextInput from '../../../../components/inputs/Text/TextInput'
import TextareaInput from "../../../../components/inputs/Textarea/TextareaInput"
import SubmitButton from "../../../generic/components/Buttons/SubmitButton"
import ControllerInput from '../../../generic/components/Inputs/ReactHookForm/ControllerInput'
import Typography from '../../../generic/components/Texts/Typography'
import TemplateLabel from '../../../generic/templates/Label'
import useSession from '../../../session/hooks/useSession'
import FormProps from './../../../generic/forms/interfaces/FormProps'
import useTranslate from './../../../generic/hooks/useTranslate'
import TemplateFormDefault from "./../../../generic/templates/Modal"
import { useDispatch } from "react-redux";
import { clearAssistatText, addAssistantText } from '../../../generic/store/action';

/**
- Form rename document
**/

const FormRenameDocument: FC<FormProps> = ({
  defaultValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate
}: FormProps) => {
  const dispatch=useDispatch()

  useEffect(()=>{
    dispatch(clearAssistatText())
    dispatch(addAssistantText('Ensure that you include information to help identify this document, such as party names.','text'))
  },[])

  const { translate } = useTranslate();
  const schema = Yup.object().shape({
    name: Yup.string().required(translate('MODULES.GROUP_COMPANY.ENGAGE_CONCIERGE.VALIDATION_REQUIRED'))
      .trim()
      .min(3,translate('MODULES.DOCUMENTS.NAMING_MINIMUM_LENGTH'))
      .matches(/^(?!.*[<>:/\\|?*""]).*/g,translate('MODULES.DOCUMENTS.NAMING_FORBIDDEN_CHARS')),
  })
  const refForm = useRef(null)

  const handleSubmit = async values => {

    onCompleteSubmit && await onCompleteSubmit(values);
  }

  return (
    <React.Fragment>
      <Form
        onlySendDirty={false}
        defaultValues={defaultValues}
        schema={schema}
        onSubmit={handleSubmit}
        ref={refForm}
        optionsForm = {{mode:"onChange"}}
      >
          <Template 
            buttons={{
              ...buttons,
              submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined} {...buttonSubmitProps}>
                {translate('MODULES.DOCUMENTS.BUTTON_SAVE')}
              </SubmitButton> 

            }}
            props={propsTemplate}
          >
          <TemplateLabel
            label={'Document name'}
            isRequired = {true}
          >
            <ControllerInput
              render={TextInput}
              name='name'
              placeholder={_.get(defaultValues, 'documentName')}
              defaultlabel={''}
            />
          </TemplateLabel>
          
        </Template>
      </Form>
    </React.Fragment>
  )
}

export default FormRenameDocument

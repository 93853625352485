import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import Form from '../../../../../../src/components/forms/Form/Form';
import classes from '../ProposalAndEngagementsForm.module.scss';
import InputAndOptionsButtons
    from '../../../Components/Clara/matter/proposal/InputAndOptionsButtons/InputAndOptionsButtons';
import AddServices from '../../../Components/Clara/matter/proposal/AddServices/AddServices';
import ViewFile from '../../../../containers/Forms/ViewFile';
import { ServerConnect } from '../../../../utils';
import { schemaEngagement, schemaProposal } from '../../Validations/V1/ProposalAndEngagementValidations';
import ControllerInput from '../../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import TextInput from '../../../../../components/inputs/Text/TextInput';
import SubmitButton from '../../../../../components/generic/Buttons/SubmitButton/SubmitButton';
import SubmitButtonSubForm from '../../../../../components/generic/Buttons/SubmitButtonSubForm/SubmitButtonSubForm';
import SecondaryButton from '../../../../../components/generic/Buttons/SecondaryButton/SecondaryButton';
import {stripTypenames} from '../../../../utils/graphql-util';

const upsertEngagement = `
mutation upsertEngagement(
    $matterId: ID!
    $engagementData: EngagementInput!
    ) {
    upsertEngagement(
    matterId:  $matterId 
    engagementData: $engagementData) {
     engagementType
   file{
       file{
           id
           name
           size
       }
   }
    }  
   } 

`;

const ProposalAndEngagementsForm = ({ initialValues, onSubmit, closeForm, showCloseIcon }) => {
    const params = useParams();
    const [fileType, setFileType] = useState(null);
    const schema = params.typeForm === "ENGAGEMENT" ? schemaEngagement : schemaProposal;

    const [styleWindowFile, setStyleWindowFile] = useState({ zIndex: -1, opacity: 0 });

    const [loadingFile, setLoadingFile] = useState(false);
    const [fileId, setFileId] = useState(0);

    const handleViewFileClose = (values) => {
        showCloseIcon(true);
        setStyleWindowFile({
            "zIndex": "-1",
            opacity: 0
        })
    };

    const handlePreviewFile = (values) => {
		const variables = stripTypenames(mapValuesMutation(values));
		variables.engagementData.status = 'PREVIEW';

        setFileType('pdf');
        ServerConnect.graphQlMutation(upsertEngagement, variables).then(({ upsertEngagement }) => {
            setFileId(upsertEngagement.file.file.id);
            showCloseIcon(false);
            setStyleWindowFile({
                "zIndex": "100",
                opacity: 1
            })
        })
    };

	const mapValuesMutation = (values) => {
		const { engagementItems = [] } = values;

		return {
			matterId: params.matterId,
			engagementData: {
				engagementItems: _.map(engagementItems, (service) => {
					const { id, engagementPriceId, amount, ...rest } = service;
					return {
						engagementPriceId: _.isEmpty(engagementPriceId) ? id : engagementPriceId,
						amount: Number(amount),
						...rest
					};
				}),
				engagementType: params.typeForm
			},
		};
	};

    return (
    	<>
			<div className={classes.windowFile} style={styleWindowFile}>
				<ViewFile showClose={false} loading={loadingFile} fileType={'pdf'} fileIdPre={fileId} close={handleViewFileClose} />
			</div>

			<Form
				defaultValues={initialValues}
				schema={schema}
				onSubmit={onSubmit}
				onlySendDirty={false}
			>

				<div className={classes.containerForm}>
					<h1>Contact details</h1>
					<article>Person who will be our primary contact</article>

					<hr />
					<LabelComponent label={"Full Name :"} typeComponent={"INPUT"}>
						<ControllerInput
							render={TextInput}
							name='clientFullName'
							disabled={true}
						/>
					</LabelComponent>
					<LabelComponent label={"Email Address:"} typeComponent={"INPUT"}>
						<ControllerInput
							render={TextInput}
							name='email'
							disabled={true}
						/>
					</LabelComponent>

					<hr style={{ borderColor: "#FFF" }} />
					<h1>Services</h1>
					<article>Break down of each service and costing</article>
					<AddServices
						placeholder={'Select a service'}
						name={'engagementItems'}
						listOtherServices={_.get(initialValues, 'servicesList', [])}
						servicesList={[]}
					/>

					<hr />
					<div className={classes.containerButtonForm}>
						<SecondaryButton onClick={closeForm}>Cancel</SecondaryButton>
						<SubmitButtonSubForm buttonType={'secondary'} onClick={handlePreviewFile}>Generate Preview</SubmitButtonSubForm>
						<SubmitButton>Send {_.capitalize(params.typeForm)} Email</SubmitButton>
					</div>

				</div>

			</Form>
		</>
    )
};

export default ProposalAndEngagementsForm;


const LabelComponent = React.memo(({ style, children, label, typeComponent, className, extraClasses = "" }) => {

    const getTypeComponentStyle = () => {
        switch (typeComponent) {
            case "INPUT":
                return classes.LabelWithInput;
            default:
                return className;
        }
    };

    return (
        <div style={style} className={`${getTypeComponentStyle()} ${extraClasses}`}>
            {label && <label>{label}</label>}
            {children}
        </div>
    )
});



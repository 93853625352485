import _ from 'lodash';
import React, {useCallback, useMemo} from 'react';
import { useDispatch } from "react-redux";
import * as Yup from 'yup';
import useCancelRequestSignatures from "../../../../../../../hooks/services/modules/Document/useCancelRequestSignatures";
import ControllerInput from '../../../../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useSession from '../../../../../../../modules/session/hooks/useSession';
import { RowAdd } from '../../../../../../forms/contents';
import Form from '../../../../../../forms/Form/Form';
import SecondaryButton from '../../../../../../generic/Buttons/SecondaryButton/SecondaryButton';
import SubmitButton from '../../../../../../generic/Buttons/SubmitButton/SubmitButton';
import Checkbox from '../../../../../../inputs/Checkbox/Checkbox';
import TextareaInput from '../../../../../../inputs/Textarea/TextareaInput';
import NewModalForm from '../../../../../../templates/NewModalForm/NewModalForm';
import Text from '../../../../../../text/Text/Text';
import Title from '../../../../../../text/Title/Title';
import classes from '../Delete/DeleteGrant.module.scss';
import {goToPage} from '../../../../../../../store/actions/page';
import {Constants} from '../../../../../../../v1/utils';


const CancelGetSignatures = ({refresh,  handleCloseModal, initialValues, goTo, redirect,onComplete, ...props }) => {
    
    const { startupId } = useSession();
    const dispatch = useDispatch();
    const form = useMemo(() => {
        // console.log(initialValues);
        return initialValues
    },[]);

    const onCompletedUpdate = () => {
		if(!redirect) {
			refresh && refresh()
		}
        onComplete && onComplete()
	};

	const callbackBeforeEvict = useCallback(() => {
		return new Promise((resolve,reject)=>{
			if (redirect) {
				goTo ? goToPage(dispatch, goTo) : goToPage(dispatch, Constants.PAGES.MODULES.MAP.maps);
			}
			resolve(true)
		})
	},[dispatch, goTo, redirect])

	const { mutation: cancelRequestSignatures, loading } = useCancelRequestSignatures({
		onCompleted: onCompletedUpdate,
		successMessage: "NOTIFICATION_SUCCESS_VOID_DOCUMENT",
		paramsSuccessMessage: form,
		isRefetchQuery: !redirect,
		callbackBeforeEvict: callbackBeforeEvict
    });

    const voidEnvelope = async (values) => {
    	await cancelRequestSignatures({
		         startupId,
		         agreementId: initialValues.id,
		         voidReason: values.reason
		 });
		handleCloseModal();
    };

    return (
        <Form
			initialValues={{confirmDelete: false}}
			schema={Yup.object().shape({
            	reason: Yup.string().required('Required'),
            	confirmVoid: Yup.bool()
					.required('You must confirm this action')
					.oneOf([true], 'You must confirm this action')
        	})}
            onSubmit={voidEnvelope}
        >
            <NewModalForm
                header={<Title><Text uuid={"MODAL_VOID_ENVELOPE"} params={{ documentName: _.get(initialValues, 'name') }} /></Title>}
                content={<React.Fragment>
                    <div className={classes.deleteInfo}>
                        <Text uuid={'MODAL_VOID_ENVELOPE_INFO_TEXT'} />
                    </div>
                    <div className={classes.textArea}>
                        <RowAdd
                            label={"MODAL_VOID_ENVELOPE_REASON_LABEL"}
                            input={
                                <ControllerInput
                                    as={TextareaInput}
                                    name="reason"
                                    defaultValue={''}
                                    placeholder={"Please let us know the reason why..."}
                                    onChange={(e) => {

                                    }}
                                />
                            } />
                    </div>
                    <div>
                        <ControllerInput
                            as={Checkbox}
                            showErrorMessage={true}
                            name={'confirmVoid'}
							classNameError={classes.errorCheckboxLabel}
                        />
                        <span className={classes.textCheckbox}>
							<Text
								uuid={"MODAL_VOID_ENVELOPE_CHECKBOX_LABEL"}
								params={{ documentName: _.get(initialValues, 'name') }}
							/>
                        </span>
                    </div>

                </React.Fragment>}
                botonera={<React.Fragment>
                    <SecondaryButton onClick={() => handleCloseModal()}><Text uuid={'GENERIC_BUTTON_CLOSE'} /></SecondaryButton>
					<SubmitButton
						labelLoading={"Sending..."}
					>
						<Text uuid={"BUTTON_VOID"} />
					</SubmitButton>
                </React.Fragment>}
            />

        </Form >

    )
};

export default CancelGetSignatures;

import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import useBusinessRules from '../../../clara/hooks/useBusinessRules';
import Botonera from '../../../generic/components/Buttons/Botonera';
import ButtonIcon from '../../../generic/components/Buttons/ButtonIcon';
import { MapContextProvider } from '../../context/MapContext';
import useMapData from '../../hooks/useMapData';
import useSession from 'src/modules/session/hooks/useSession';
import useGetMe from "src/modules/session/graphql/useGetMe";
import useUpdateOptionSelected from 'src/v1/graphql/mutations/updateOptionSelected'
import useMapDataCards from '../../hooks/useMapDataCards';
import useMapLines from '../../hooks/useMapLines';
import Header from '../Header';
import MapInteaction from '../MapInteaction';
import useTimeline from '../Timeline/Context/timelineContext';
import classesModule from './classes.module.scss';
import { HTMLMap } from './HTMLMap';
import { SVGMap } from './SVGMap';
import { Box, LinearProgress } from "@mui/material";

const MapContent = ({ openHeader }) => {
  const timeToUpdateInMiliseconds = 200;
  const { isGroupCompanyInFormation } = useBusinessRules();
  const [isFooterOpen, setIsFooterOpen] = useState(false);
  const [typeFilter, setTypeFilter] = useState(0);
  const [finishToLoadCards, setFinishToLoadCards] = useState(false);

  /** Handle  Filters*/
  const [filters, setFilters] = useState({
    text: '',
    groupCompany: null,
    rol: null,
    stakeholder: null
  })

  const hasFilter = () => {
    return (
      (filters.text && filters.text !== '') ||
      filters.groupCompany ||
      filters.rol ||
      filters.stakeholder
    )
  }

  const handeChangeFilter = filter => {
    const newFilter = { ...filters, text: filter }
    moveScroll();
    setFilters(newFilter)
  }

  const handleClickCard = (status, id, filterKey) => {
    let newFilters = { ...filters }
    if (status || newFilters[filterKey] !== id) {
      newFilters[filterKey] = id
    } else {
      newFilters[filterKey] = null
    }
    moveScroll();
    setFilters(newFilters)
  }

  const handleDeleteRol = () => {
    let newFilters = { ...filters }
    newFilters['rol'] = null
    moveScroll();
    setFilters(newFilters)
    setEnabledLines(false)
  }

  /** Local Store */
  const groupCompaniesRef = useRef({});
  const rolesRef = useRef({});
  const stakeholdersRef = useRef({});
  const svgRef = useRef();

  /** Context */
  const [contextValues, setContextValues] = useState({
    lines: {}
  })

  const onUpdatedLine = id => {
    setContextValues(newContext => {
      newContext.lines[id] = false
      return newContext
    })
  }

  /** Config */
  const maxLevels = 3;
  /**  Get Server data*/
  const [stakeholdersOrders, setStakeholdersOrders] = useState([]);

  const {
    stakeholders,
    roles,
    groupCompanies,
    loading
  } = useMapData({ maxLevels, filter: typeFilter });

  useEffect(() => {
    const stc = Object.keys(stakeholdersRef.current).filter(
      key => stakeholdersRef.current[key] !== null
    ).length;
    const gcc = Object.keys(groupCompaniesRef.current).filter(
      key => groupCompaniesRef.current[key] !== null
    ).length;
    const rolc = Object.keys(rolesRef.current).filter(
      key => rolesRef.current[key] !== null
    ).length;
    const gcItemsCount = Object.keys(groupCompanies).length;
    const stItemsCount = Object.keys(stakeholders).length;
    const rolItemsCount = Object.keys(roles).length;
    const isFinishLoad = stc === stItemsCount && gcc === gcItemsCount && rolc === rolItemsCount;
    if (finishToLoadCards !== isFinishLoad) {
      setFinishToLoadCards(isFinishLoad)
    }
  }, [stakeholders, groupCompanies, roles, finishToLoadCards]);  

  const {
    dataPropsGroupCompany,
    dataPropsRol,
    dataPropsStakeholder,
    totals
  } = useMapDataCards({
    filters,
    groupCompanies,
    stakeholders,
    roles,
  });

  const { lines, reloadLines } = useMapLines({
    groupCompanies,
    stakeholders,
    roles,
    dataPropsGroupCompany,
    dataPropsRol,
    dataPropsStakeholder,
    groupCompaniesRef,
    rolesRef,
    stakeholdersRef,
    maxLevels,
    classesModule,
    filters,
    finishToLoadCards,
  })

  const [statusLogin, setStatusLogin] = useState('no-execute');

  useEffect(() => {
    if (loading && statusLogin === 'no-execute') {
      setStatusLogin('loading')
    }
    if (!loading && statusLogin === 'loading') {
      setStatusLogin('complete')
    }
  }, [loading]);

  useEffect(() => {
    reloadLines()
  }, [stakeholdersOrders, JSON.stringify(roles)]);

  const mapRef = useRef();

  const moveScroll = useCallback(() => {
    if (mapRef && mapRef.current) {
      const scrollToFunction = _.get(mapRef, "current.scrollTo", null);
      if (scrollToFunction) {
        scrollToFunction({
          top: 0,
          behavior: 'smooth',
        })
      }
    }
  }, [mapRef]);

  const handleChangeOrder = filterType => {
    switch (filterType) {
      case 1:
        let orderRol = Object.values(stakeholders)
        orderRol.sort((a, b) => {
          const aRolOrder = _.get(a, 'rolOrder')
          const bRolOrder = _.get(b, 'rolOrder')
          if (aRolOrder < bRolOrder) return 1
          if (aRolOrder === bRolOrder) return 0
          return -1
        })
        setStakeholdersOrders(orderRol)
        break
      case 2:
        let orderCreatedAtAsc = Object.values(stakeholders)
        orderCreatedAtAsc.sort((a, b) => {
          const aDate = new Date(_.get(a, 'createdAt')).getTime()
          const bDate = new Date(_.get(b, 'createdAt')).getTime()
          if (aDate < bDate) return 1
          if (aDate === bDate) return 0
          return -1
        })
        setStakeholdersOrders(orderCreatedAtAsc)
        break
      case 3:
        let orderCreatedAtDec = Object.values(stakeholders)
        orderCreatedAtDec.sort((a, b) => {
          const aDate = new Date(_.get(a, 'createdAt')).getTime()
          const bDate = new Date(_.get(b, 'createdAt')).getTime()
          if (aDate > bDate) return 1
          if (aDate === bDate) return 0
          return -1
        })
        setStakeholdersOrders(orderCreatedAtDec)
        break
      case 4:
        let orderAZ = Object.values(stakeholders)
        orderAZ.sort((a, b) => {
          const aFullName = _.get(a, 'fullName').toUpperCase()
          const bFullName = _.get(b, 'fullName').toUpperCase()
          if (aFullName > bFullName) return 1
          if (aFullName === bFullName) return 0
          return -1
        })
        setStakeholdersOrders(orderAZ)
        break
      case 5:
        let orderZA = Object.values(stakeholders)
        orderZA.sort((a, b) => {
          const aFullName = _.get(a, 'fullName').toUpperCase()
          const bFullName = _.get(b, 'fullName').toUpperCase()
          if (aFullName < bFullName) return 1
          if (aFullName === bFullName) return 0
          return -1
        })
        setStakeholdersOrders(orderZA)
        break
    }
  }

  const handleChangeActive = (e) => {
    const normalizeFilters = {
      text: '',
      groupCompany: null,
      rol: null,
      stakeholder: null
    };
    setFilters(normalizeFilters);
    setTypeFilter(e);
  }

  const getStatusCard = props => {
    // console.log("---> props", props)
    // if (_.isEmpty(groupCompanies)) {
    //   return 'innactive'
    // }
    if (!_.isEmpty(props)) {
      if (props['data-unavailable']) return 'innactive'
      if (props['data-selected']) return 'selected'
      if (props['data-suggested']) return 'suggested'
    }
    return 'default'
  }

  const getStatusCardRol = (props, rolId) => {
    let status = getStatusCard(props);
    if (status === 'default') {
      const groupCompaniesInFormation = _.filter(groupCompanies, (groupCompany) => {
        return isGroupCompanyInFormation(groupCompany)
      });
      // if (groupCompaniesInFormation.length >= 1 && groupCompaniesInFormation.length == Object.keys(groupCompanies).length) {
      //   switch (rolId) {
      //     case Constants.ROLES_FROM_BACKEND.DIRECTOR.id:
      //     case Constants.ROLES_FROM_BACKEND.SHAREHOLDER.id:
      //       return status
      //     default:
      //       return 'innactive'
      //   }
      // }
    }
    return status
  }

  useEffect(() => {
    handleChangeOrder(1)
  }, [JSON.stringify(stakeholders)]);

  /** Update */
  const updateLines = () => {
    let newContextValues = { ...contextValues }
    _.forEach(lines, (line, key) => {
      newContextValues.lines[key] = true
    })
    setContextValues(newContextValues)
  }

  const [needUpdate, setNeedUpdate] = useState(false);

  const handleExpandCard = () => {
    setNeedUpdate(true)
  }

  const [enabledLines, setEnabledLines] = useState(false);
  const { startupId, user, setUser } = useSession();
  const { mutation } = useUpdateOptionSelected();

  const handleCompleteGetMe = (data) => {
    setUser(_.get(data, "getMe", []));
  };

  const { manualQuery: getMe, loading: loadingGetMe } = useGetMe(null, {
    showSuccessNotification: false,
    onCompleted: handleCompleteGetMe,
  });

  useEffect(() => {
    if (!loadingGetMe) {
      getMe().then((data) => {
        const user = _.get(data, "data.getMe");
        setEnabledLines(!user.optionsSelected.filter(dt => dt.option === 'MAP_LINES_ENABLED' && dt.startupId === startupId)[0]?.value)
      });
    }
  }, [])

  const handleEnabledLines = async () => {
    setEnabledLines(!enabledLines)
    const variables = {
      id: user.id,
      optionSelected: {
        option: "MAP_LINES_ENABLED",
        startupId,
        value: enabledLines
      }
    }
    await mutation(variables)
  }

  /** use this setTimeout to avoiod continues multiples updates  */
  useEffect(() => {
    setTimeout(() => {
      if (needUpdate) {
        updateLines()
        setNeedUpdate(false)
      }
    }, timeToUpdateInMiliseconds)
  }, [needUpdate]);

  /** Resize */
  const {
    width: widthMap,
    height: heightMapResize,
    ref: refMap
  } = useResizeDetector();

  useEffect(() => {
    updateLines()
  }, [widthMap, heightMapResize]);

  const heightMap = useMemo(() => {
    let max = 0;
    const sh = _.get(stakeholdersOrders, "length", 0) * 104
    if (sh > max) max = sh;
    const gc = _.get(groupCompanies, "length", 0) * 104
    if (gc > max) max = gc;
    const r = _.get(roles, "length", 0) * 90
    if (r > max) max = r;
    if (max === 0) {
      max = heightMapResize;
    }
    return sh;
  }, [stakeholdersOrders, heightMapResize]);

  const {
    isPlaying,
    isPaused,
    playingStatus,
    playCode,
    infoCurrentPosition
  } = useTimeline()

  const playStatus = useMemo(() => {
    return playingStatus === 'play' && playCode === "coorporate"
  }, [playingStatus, playCode])

  const getGroupCompanyShowingStatus = groupCompany => {
    if (!playStatus) {
      return true
    }
    const playingDay = _.get(infoCurrentPosition, 'day', null)
    if (groupCompany.incorporation_date) {
      let incorporation_date = moment(groupCompany.incorporation_date)
      if (!moment(incorporation_date).isValid() || !moment(playingDay).isSameOrAfter(moment(incorporation_date))) {
        return false
      }
    }
    if (groupCompany.dissolution_date) {
      let dissolution_date = moment(groupCompany.dissolution_date)
      if (!moment(dissolution_date).isValid() || !moment(playingDay).isSameOrBefore(moment(dissolution_date))) {
        return false;
      }
    }
    return true;
  }

  const isLineVisibleByPlay = (key, line) => {
    if (!playStatus) {
      return true
    }
    let isVisible = true;
    _.forEach(_.get(line, 'params.stakeholders', []), shId => {
      const stakeholder = stakeholders[shId]
      if (isVisible) {
        isVisible = getStakeholderShowingStatus(stakeholder);
      }
    })
    if (isVisible) {
      _.forEach(_.get(line, 'params.groupCompanies', []), gcId => {
        const groupCompany = groupCompanies[gcId]
        if (isVisible) {
          isVisible = getGroupCompanyShowingStatus(groupCompany);
        }
      })
    }
    return isVisible
  }

  const getStakeholderShowingStatus = stakeholder => {
    if (!playStatus) {
      return true
    }
    const playingDay = _.get(infoCurrentPosition, 'day', null)
    if (stakeholder.startDate) {
      let startDate = moment(stakeholder.startDate)
      if (!moment(startDate).isValid() || !moment(playingDay).isSameOrAfter(moment(startDate))) {
        return false
      }
    }
    if (stakeholder.endDate) {
      let endDate = moment(stakeholder.endDate)
      if (!moment(endDate).isValid() || !moment(playingDay).isSameOrBefore(moment(endDate))) {
        return false;
      }
    }
    return true;
  }

  return (
    <React.Fragment>
      {
        <MapContextProvider value={contextValues}>
          <div className={classesModule.Map} ref={refMap}>
            <Header
              defaultValueIndex={typeFilter}
              open={openHeader}
              onChangeFilter={handeChangeFilter}
              groupCompanies={groupCompanies}
              onChangeOrder={handleChangeOrder}
              onChangeActive={handleChangeActive}
              countGroupCompanies={Object.keys(groupCompanies).length}
              countStakeholders={Object.keys(stakeholders).length}
            />
            {loading ? (
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
            ) : (
              <Box sx={{ width: "100%", height: "4px", backgroundColor: "#fbfcff" }} />
            )}
            <MapInteaction
              innerRef={mapRef}
              customBotonera={
                <Botonera
                  className={classesModule.BotoneraReset}
                  buttons={[
                    <ButtonIcon
                      key={3}
                      enabledActive={true}
                      size={'55%'}
                      icon='Map-lines'
                      variant='transparent'
                      onClick={handleEnabledLines}
                    />
                  ]}
                />
              }
              buttonsPosition={isFooterOpen}
            >
              <div
                className={classesModule.ZoneMap}
                style={{ width: widthMap - 11, minHeight: heightMap }}
              >
                <SVGMap 
                  widthMap={widthMap} 
                  heightMap={heightMap} 
                  svgRef={svgRef} 
                  lines={lines} 
                  isLineVisibleByPlay={isLineVisibleByPlay} 
                  hasFilter={hasFilter} 
                  enabledLines={enabledLines} 
                  onUpdatedLine={onUpdatedLine}
                />
                <HTMLMap
                  dataPropsStakeholder={dataPropsStakeholder}
                  dataPropsGroupCompany={dataPropsGroupCompany}
                  stakeholdersRef={stakeholdersRef}
                  rolesRef={rolesRef}
                  groupCompaniesRef={groupCompaniesRef}
                  widthMap={widthMap} heightMap={heightMap}
                  groupCompanies={groupCompanies}
                  handleClickCard={handleClickCard}
                  handleExpandCard={handleExpandCard}
                  isGroupCompanyInFormation={isGroupCompanyInFormation}
                  getStatusCard={getStatusCard}
                  totals={totals}
                  getGroupCompanyShowingStatus={getGroupCompanyShowingStatus} 
                  statusLogin={statusLogin}
                  roles={roles}
                  handleDeleteRol={handleDeleteRol}
                  getStatusCardRol={getStatusCardRol}
                  dataPropsRol={dataPropsRol}
                  stakeholdersOrders={stakeholdersOrders}
                  getStakeholderShowingStatus={getStakeholderShowingStatus}
                />
              </div>
            </MapInteaction>
            {/* The timeline integration is commented until future release */}
            {/* <Footer handleClick={hadleFooterClick} open={isFooterOpen} /> */}
          </div>
        </MapContextProvider>
      }
    </React.Fragment>
  )
}

MapContent.propTypes = {
  /**  Open Header Map */
  openHeader: PropTypes.bool
}

MapContent.defaultProps = {
  openHeader: false
}

export default MapContent


import React, { useRef, useState } from "react";
import _ from "lodash"
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Popover from '@mui/material/Popover';
import classesPopover from './classes.module.scss';
/**
 * PopoveOption
 *
 * Compontent to show option in a popover
*/
const PopoverOption = ({ children,  className, onOpen,onClose,onClickOption, options, elevation, innerDivClass, selectedClass }) => {

    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const openMenu = (e) => {
        setAnchorEl(e.currentTarget);
        setOpen(true);
        onOpen && onOpen();
    }

    const refPaper = useRef()

    const closePopover = (e) => {
        setOpen(false)
        onClose && onClose();
    };
    const handleOnclickOption = (e,key)=>{
        _.set(e,"target.value",key)
        onClickOption && onClickOption(e);
        closePopover(e)
    }


    return (
        <div className={classesPopover.PopoverOption}>
            <div onClick={(e) => { openMenu(e) }} className={classnames(`${innerDivClass}`, {[selectedClass] : open})}>
                {children}
            </div>
            <Popover
                open={open}
                onClose={closePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                classes={{
                    paper:  `${classesPopover.papper} ${classesPopover.arrowCenter}  `
                }}
                anchorEl={anchorEl}
                elevation={elevation}
            >
                <div className={`${classesPopover.containerElement} ${className}`} >
                    {_.map(options, option => {
                        return (
                            <div key={option.key} className={classesPopover.optionElement} tabIndex={0} onClick={(e)=>handleOnclickOption(e,option.key)}>
                                    {option.component}
                            </div>
                        )
                    })}
                </div>
            </Popover>
        </div >
    )

}


PopoverOption.propTypes = {
    /** Button or component to do click and open popover */
    children: PropTypes.node,
    /** ClassName Styles */
    className: PropTypes.node,
    /** Function call when open Popover */
    onOpen: PropTypes.func,
    /** Function call when close Popover */
    onClose:  PropTypes.func,
    /** Function call when selec one option in the Popover (value = targe.value)*/
    onClickOption:  PropTypes.func,
    /** Array of options [{component: JSX compoente, key: value returned in onClickOption insede target.value}] */
    options:  PropTypes.array,
    elevation:PropTypes.number,
    innerDivClass: PropTypes.node,
    selectedClass: PropTypes.node,

};
PopoverOption.defaultProps = {
    elevation: 8
};





export default PopoverOption;

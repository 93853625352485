import FLOWS from "../../../../../../constants/flows"
import  CreateAGroupCompany from './CreateAGroupCompany';
import  CreateAnEquity from './CreateAnEquity';
import  OnboardTeamMember from './OnboardTeamMember';
import  CreateAnInvestor from './CreateAnInvestor';
import  CreateAStartupProfile from './CreateAStartupProfile';
import  FinishOnboard from './FinishOnboard';
export default {
    [FLOWS.CREATE_STARTUP.NAME]: CreateAStartupProfile,
    [FLOWS.ADD_COMPANY.NAME]: CreateAGroupCompany,
    [FLOWS.ONBOARD_EQUITY.NAME]: CreateAnEquity,
    [FLOWS.ONBOARD_TEAM_MEMBER.NAME]: OnboardTeamMember,
    [FLOWS.ONBOARD_AN_INVESTOR.NAME]: CreateAnInvestor,
    ["FinishOnboard"]: FinishOnboard
}
import useLazyQuery from "../../../../../hooks/custom/useLazyQuery";

const GET_GROUP_COMPANIES_FOR_ACCELERATORS = `
query getGroupCompaniesForAccelerators($startupId: ID!) {
  getGroupCompaniesForAccelerators(startupId: $startupId) {
    id
    name
    kind
    avatar
    isHoldingCompany
    jurisdiction
    jurisdictionType
    companyType
    isJurisdictionApproved
  }}
`;

const useGetGroupCompaniesForAccelerators = (variables,config) => {
  const configDefault = {errorMessage: 'GENERIC_PERMISSION_ERROR', showErrorNotification: true, showSuccessNotification: false}
  const { manualQuery,loading, error, data, refetch } = useLazyQuery(GET_GROUP_COMPANIES_FOR_ACCELERATORS, variables, {...configDefault,...config});
  return { manualQuery,loading, error, data, refetch };
}

export default useGetGroupCompaniesForAccelerators;
import React, { useMemo } from 'react';
import SelectInput from 'src/components/inputs/Select/SelectInput/SelectInput';
import useTranslate from "src/modules/generic/hooks/useTranslate";
import { Constants } from 'src/v1/utils/constants';


/**
 * Component to select mailing address type
 * @param {*} param0 
 * @returns 
 */

const SelectUltimateBeneficialOwnershipTypeInput = ({ ...props }) => {
    const { translate } = useTranslate();
    
    const ultimateBeneficialOwnershipType = useMemo(() => {
        return [
          { value: Constants.COMPANIES.ULTIMATE_BENEFICIAL_OWNERSHIP_TYPE.LP_OWNERSHIP, label: Constants.COMPANIES.ULTIMATE_BENEFICIAL_OWNERSHIP_TYPE_LABEL.LP_OWNERSHIP },
          { value: Constants.COMPANIES.ULTIMATE_BENEFICIAL_OWNERSHIP_TYPE.LP_SENIOR_MANAGING_OFFICIAL, label: Constants.COMPANIES.ULTIMATE_BENEFICIAL_OWNERSHIP_TYPE_LABEL.LP_SENIOR_MANAGING_OFFICIAL },
          { value: Constants.COMPANIES.ULTIMATE_BENEFICIAL_OWNERSHIP_TYPE.LP_OTHER_MEANS, label: Constants.COMPANIES.ULTIMATE_BENEFICIAL_OWNERSHIP_TYPE_LABEL.LP_OTHER_MEANS }
        ];
      }, []);


    return (
        <SelectInput placeholder={translate("SELECT_INPUT_PLACEHOLDER")}
            clear={true} list={ultimateBeneficialOwnershipType} {...props} />
    )
}

export default SelectUltimateBeneficialOwnershipTypeInput;


import { useMemo } from 'react';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import AddFormationCompanySubMachineStep from '../steps/AddFormationCompanySubMachineStep';
import AddIncorporateCompanySubMachineStep from '../steps/AddIncorporateCompanySubMachineStep';
import SelectWhatToAdd from '../steps/SelectWhatToAdd';

const useSteps = () => {

  return useMemo(() => ({
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    AddIncorporateCompanySubMachineStep: {
      render: AddIncorporateCompanySubMachineStep
    },
    AddFormationCompanySubMachineStep: {
      render: AddFormationCompanySubMachineStep
    },
    SelectWhatToAddStep: {
      render: SelectWhatToAdd,
    },
  }), []);
}

export default useSteps;

import moment from "moment";
import * as numeral from "numeral";

const PrintDate = (date,format) =>{

    if(!date)return "";
    
    return moment(date).format(format);

}

const PrintFormattedNumber= (number,type,symbol="",format="") =>{
    if(!number)return "";
    switch (type){
        case "CURRENCY": return numeral(number).format("0,0.00") + " " + symbol;

        case "PERCETAGE": return number/100>0?numeral(number/100).format('0%'):"<0.01%";

        case "NUMBER": return numeral(number).format("0,0");

        default:
        break;
    }
    
}
const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step)).map(number => number.toString());

export  {
    PrintDate,
    PrintFormattedNumber,
    range 
}



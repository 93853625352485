import React from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";

const EditTransferShares = ({go,...props}) => {
 const params = useParams();

  return (
    <MicroFrontend id="MF_Share_Edit_Transfer" component={"./EditTransferShares"} mf="capTable" params={{go,...props,...params}} />
  );
};

export default EditTransferShares;

import React, { createContext, useState } from 'react';
import {Constants} from "src/v1/utils";
import event from "src/v1/utils/event";
export const GlobalModalContext = createContext({});

export type GlobalModalContextType<Params = any> = {
  openModal: (component: React.ReactNode, params: Params, overlayName: string) => void
  closeModal: () => void
}

export const GlobalModalProvider = ({ children }) => {
  const [modalComponent, setModal] = useState(null)
  const closeModal = () => {
    setModal(null)
    event.emmit(Constants.EVENTS.REFRESH_MY_TASKS, {});
  }
  const openModal = (component, params = {}, overlayName) => {
    // alert(JSON.stringify(params,null,2));
    setModal(React.createElement(component, { open: true, ...params }, overlayName));
  }
  return (
    <GlobalModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      {modalComponent}
    </GlobalModalContext.Provider>
  );
};

export interface UseModalReturn {
  openModal(component: React.ReactNode, params?: any, overlayName?: string): void,
  closeModal: () => void
}

function useModal(): UseModalReturn {
  const { openModal, closeModal } = React.useContext(GlobalModalContext) as GlobalModalContextType;
  return { openModal, closeModal }
}

export default useModal;
import useMutation from "src/hooks/custom/useMutation";
import {useMemo} from "react";

export const CREATE_STRIKE_OFF_PROCESS = `
mutation createStrikeOffProcess(
  $startupId: ID!, 
  $groupCompanyId: ID!
) {
    createStrikeOffProcess (
      startupId: $startupId,
      groupCompanyId: $groupCompanyId
    )
  {
    id
    type
    product {
      id
      code
      name
      value
      vat
    }
  }
}
`
const useCreateStrikeOffProcess = (config, props) => {

  const handleSuccessMessage = useMemo(() => {
    return (config && config.successMessage) || "RENEWAL_START_STRIKE_OFF";
  }, [config]);

  return useMutation(
    CREATE_STRIKE_OFF_PROCESS,
    {
      ...config,
      successMessage: handleSuccessMessage
    },
    {
      ...props,
    }
  );
};

export default useCreateStrikeOffProcess;
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { FormProvider, useForm } from "react-hook-form";
import classes from "./Form.module.scss";
import DevToolsForm from "../../../modules/devTools/Form";
import _ from "lodash";
import { yupResolver } from '@hookform/resolvers/yup';

export interface FormProps {
  [key: string]: any;
  defaultValues?: any;
  loading?: boolean;
  loadingComponent?: React.ReactElement;
  onlySendDirty?: boolean;
  className?: string;
  optionsForm?:any
}

const Form = forwardRef(
  (
    {
      className = "",
      onlySendDirty = true,
      children,
      defaultValues,
      onSubmit,
      schema = {},
      optionsForm = {},
    }: FormProps,
    ref
  ) => {

    const methods = useForm({
      defaultValues,
      resolver: yupResolver(schema),
      mode: 'onSubmit',
      criteriaMode: "firstErrorDetected",
      reValidateMode: 'onChange',
      shouldUnregister: true,
      shouldFocusError: true,
      //context: { ...context },
      ...optionsForm
    });

    const formRef = useRef(null);

    const dirtyValues = (dirtyFields, allValues) => {
      if (dirtyFields === true || Array.isArray(dirtyFields)) {
        return allValues;
      }
      return Object.fromEntries(
        Object.keys(dirtyFields).map((key) => [
          key,
          dirtyValues(dirtyFields[key], allValues[key]),
        ])
      );
    };

    const handleSubmit = async (data) => {
      const newValues = onlySendDirty
        ? dirtyValues(methods.formState.dirtyFields, data)
        : _.cloneDeep(data);
      if (onSubmit) {
        await onSubmit(newValues);
      }
    };


    useImperativeHandle(ref, () => ({
      ...methods,
      async submit() {
        // formRef.current.submit()
      },
      async reset(values, omitResetState) {
        return methods.reset(values);
      },

    }));


    return (
      <React.Fragment>
        <FormProvider {...methods} >
          <form
            ref={formRef}
            autocomplete={"off_1"}
            className={`${classes.Form} ${className}`}
            onSubmit={methods.handleSubmit(handleSubmit)}
            noValidate={true}
          >
            <>
              {children}
            </>
          </form>
          <DevToolsForm />
        </FormProvider>
      </React.Fragment>
    );
  }
);

export default Form;

import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import Form from "src/components/forms/Form/Form";
import CalendarInputDate from "src/components/inputs/Calendars/CalendarInputDate";
import CountrySelector from "src/components/inputs/Select/CountrySelector/CountrySelector";
import TextInput from "src/components/inputs/Text/TextInput";
import Button from "src/modules/generic/components/Buttons/Button";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import PhoneInput from "src/modules/generic/components/Inputs/PhoneInput";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import {
  addAssistantText,
  clearAssistatText,
} from "src/modules/generic/store/action";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import SelectInput from "../../../../../../../../../../components/inputs/Select/SelectInput/SelectInput";
import { Constants } from "../../../../../../../../../../v1/utils";
import RadioInput from "src/components/inputs/Radio/RadioInput";
import { HiddenFieldForm } from "src/components/forms/contents";
import ClaraCountryAutocomplete from "src/v1/components/Form/ClaraCountryAutocomplete/ClaraCountrySelector";

/**
 * Description Step: Review Details UBO
 */
function ReviewUboDetailsFormStep() {
  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const [countryNationality, setCountryNationality] = React.useState("");

  const initialValues = useMemo(() => {
    return (
      context?.ReviewUboDetailsFormStep ?? context?.initialData?.stakeholder
    );
  }, [context]);

  const handleCancel = () => cancel();

  const handleSubmit = async (values) => {
    next({ ...values, countryNationality });
  };

  useEffect(() => {
    dispatch(
      addAssistantText(
        translate("MODULES.RENEWALS_ADGM.REVIEW_DETAILS_UBO.COMMON.TOOLTIP_1"),
        "alert"
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const listTitle = [
    { label: "Mr", value: "Mr" },
    { label: "Mrs", value: "Mrs" },
    { label: "Ms", value: "Ms" },
    { label: "Dr", value: "Dr" },
    { label: "Hon", value: "Hon" },
    { label: "Reverend", value: "Reverend" },
    { label: "Very Reverend", value: "Very Reverend" },
    { label: "Senator", value: "Senator" },
  ];

  const schema = Yup.object().shape({
    title: Yup.string().nullable().required("This field is required"),
    fullName: Yup.string().nullable().required("This field is required"),
    previousName: Yup.string().nullable(),
    arabicName: Yup.string().nullable(),
    nationality: Yup.string().required("This field is required").nullable(),
    hasNationalities: Yup.boolean().required("This field is required").typeError("This field is required"),
    nationalities: Yup.mixed().when('hasNationalities', {
      is: true,
      then: Yup.array().of(Yup.string()).min(1, translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")).required(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")),
      otherwise: Yup.array().of(Yup.string()).nullable(),
    }),
    dateOfBirth: Yup.string()
      .matches(Constants.REGEXP.DATE, {
        message: translate("MODULES.CLARA.VALIDATION_ENTER_DATE"),
        excludeEmptyString: true,
      })
      .required(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED"))
      .nullable(),
    countryOfBirth: Yup.string().required("This field is required").typeError("This field is required").nullable(),
    cityOfBirth: Yup.string().required("This field is required").nullable(),
    email: Yup.string().email().required("This field is required").nullable(),
    phoneNumber: Yup.string()
      .required("This field is required")
      .typeError(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")),
    profession: Yup.string().nullable().required("This field is required"),
  });

  const UBOName = initialValues?.fullName;

  const labelLines = [
    "Do they have other<br />nationalities?",
  ];

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: "Review and confirm their details",
          subTitle: "Review details for " + UBOName,
          skeleton: null,
          variant: "simple",
        }}
        buttons={{
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("BUTTON_CANCEL")}
            </Button>
          ),
          submit: (
            <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>
              {translate("BUTTON_NEXT")}
            </SubmitButton>
          ),
        }}
      >
        <TemplateLabel label="Title" isRequired>
          <ControllerInput
            name="title"
            render={SelectInput}
            list={listTitle}
            placeholder={translate("MODULES.CLARA.TYPE_HERE_HIT")}
          />
        </TemplateLabel>
        <TemplateLabel label="Full legal name" isRequired>
          <ControllerInput
            name="fullName"
            placeholder="Type here"
            render={TextInput}
          />
        </TemplateLabel>
        <TemplateLabel label="Former names (if applicable)">
          <ControllerInput
            name="previousName"
            placeholder="Type here"
            render={TextInput}
          />
        </TemplateLabel>
        <TemplateLabel label="Arabic name">
          <ControllerInput
            name="arabicName"
            placeholder="Type here"
            render={TextInput}
          />
        </TemplateLabel>
        <TemplateLabel label="Nationality" isRequired>
          <ControllerInput
            name="nationality"
            placeholder="Type here"
            render={CountrySelector}
            callbakcs={{ updateValues: (v) => setCountryNationality(v) }}
          />
        </TemplateLabel>
        <TemplateLabel
          multilineTexts={labelLines}
          isRequired
        >
          <ControllerInput
            render={RadioInput}
            name='hasNationalities'
          />
        </TemplateLabel>
        <HiddenFieldForm
          fieldsWatch="hasNationalities"
          conditionHideCallback={(value) => value}
        >
          <TemplateLabel label="Other nationalities" isRequired>
            <ControllerInput
              placeholder="Select all that apply"
              render={ClaraCountryAutocomplete}
              name='nationalities'
            />
          </TemplateLabel>
        </HiddenFieldForm>
        <TemplateLabel label="Date of birth" isRequired>
          <ControllerInput
            name="dateOfBirth"
            placeholder="dd/mm/yyyy"
            render={CalendarInputDate}
          />
        </TemplateLabel>
        <TemplateLabel label="Country of birth" isRequired>
          <ControllerInput
            placeholder="Select country"
            render={CountrySelector}
            name='countryOfBirth'
          />
        </TemplateLabel>
        <TemplateLabel label="City of birth" isRequired>
          <ControllerInput
            render={TextInput}
            name="cityOfBirth"
            placeholder="Type here"
          />
        </TemplateLabel>
        <TemplateLabel label="Profession" isRequired>
          <ControllerInput
            name="profession"
            placeholder="Type here"
            render={TextInput}
          />
        </TemplateLabel>
        <TemplateLabel label="Email address" isRequired>
          <ControllerInput
            name="email"
            placeholder="Type here"
            render={TextInput}
          />
        </TemplateLabel>
        <TemplateLabel label="Telephone number" isRequired>
          <ControllerInput
            render={PhoneInput}
            disableDropdown={false}
            name="phoneNumber"
            placeholder="Include numbers"
          />
        </TemplateLabel>
        <div>&nbsp;</div>
      </TemplateWizard>
    </Form>
  );
}

export default ReviewUboDetailsFormStep;

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import Form from 'src/components/forms/Form/Form';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import Button from 'src/modules/generic/components/Buttons/Button';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { addAssistantText, clearAssistatText } from 'src/modules/generic/store/action';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import Checkbox from "src/components/inputs/Checkbox/Checkbox";
import Typography from "src/modules/generic/components/Texts/Typography";
import classes from "./classes.module.scss";
import _ from 'lodash';

const schema = Yup.object().shape({
  declarations: Yup.boolean()
    .oneOf([true], 'You must accept the terms to continue')
    .required('This field is required')
});

function DeclarationsStep() {
  const { next, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const Paragraph = () => {
    return (
      <div className={classes.Paragraph}>
        <Typography component="p" variant="m">
          {translate(
            "MODULES.RENEWALS.ECONOMIC_SUBSTANCE.DECLARATIONS.DESCRIPTION"
          )}
        </Typography>
      </div>
    );
  };

  const Terms = () => {
    return (
      <ul className={classes.list}>
        {_.times(6, (index) => (
          <li key={index}>
            <Typography component="p" variant="m">
              {translate(
                `MODULES.RENEWALS.ECONOMIC_SUBSTANCE.DECLARATIONS.TERMS_${index + 1}`
              )}
            </Typography>
          </li>
        ))}
      </ul>
    );
  };

  const handleCancel = () => {
    cancel();
  }

  const handlePrev = () => {
    prev();
  }

  const handleSubmit = async (values) => {
    next(values);
  }

  useEffect(() => {
    dispatch(
      addAssistantText(
        translate(
          "MODULES.RENEWALS.ECONOMIC_SUBSTANCE.DECLARATIONS.TOOLTIP"
        ),
        "alert"
      )
    );
    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch]);

  return (
    <Form
      defaultValues={{}}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: translate('MODULES.RENEWALS.ECONOMIC_SUBSTANCE.DECLARATIONS.TITLE'),
          skeleton: null,
          subTitle: translate('MODULES.RENEWALS.ECONOMIC_SUBSTANCE.GENERIC.SUBTITLE'),
          variant: 'simple'
        }}
        buttons={{
          previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton>Confirm</SubmitButton>,
        }}
      >
        <div>
          <Paragraph />
          <ControllerInput
            label="By submitting this Economic Substance Return, I confirm on behalf of the Company that:"
            isMultiple={false}
            render={Checkbox}
            name="declarations"
            showErrorMessage={true}
            isRequired
          />
          <Terms />
        </div>
      </TemplateWizard>
    </Form>
  );
}

export default DeclarationsStep;
import React, { useEffect } from 'react';
import useQuery from "../../../custom/useQuery"

const query = `query getSystemConfig($key: String!){
  getSystemConfig(key: $key)
}`;
window.useGetSystemConfig = [];
const useGetSystemConfig = (variables, config) => {
  const { loading, error, data, refetch } = useQuery(query, variables, config);


  return { useQuery, loading, error, data };
}
export default useGetSystemConfig;
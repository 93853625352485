import React, {useContext} from 'react';
import FormContext from '../../../forms/Form/FormContext';
import PrimaryButton from '../PrimaryButton/PrimaryButton'
import SecondaryButton from '../SecondaryButton/SecondaryButton';
import {useFormContext} from 'react-hook-form';

const SubmitButtonSubForm = ({onClick, buttonType = 'primary', ...props}) => {
    const formState = useContext(FormContext);
    const {isSubmitting, isValid, enabledSubmit} = formState;
    const {handleSubmit} = useFormContext();

    return (
        <React.Fragment>
			{
				buttonType === 'primary'
					? <PrimaryButton onClick={handleSubmit(onClick)} type={'button'}  disabled={isSubmitting} {...props}> {props.children} </PrimaryButton>
					: <SecondaryButton onClick={handleSubmit(onClick)} type={'button'}  disabled={isSubmitting} {...props}> {props.children} </SecondaryButton>
			}
        </React.Fragment>
    )
};

export default SubmitButtonSubForm

import Machine from "../../../../generic/components/Machine/MachineClass";
import { Step2 } from './Steps';

const haveOldPassport = (context) => {
    return context.data.select_current.haveOldPassposrt
}

const machineDefinition = {
    id: 'address_and_family_contact',
    initial: 'select_current',
    states: {
        select_current: {
            on: {
                NEXT: { target: 'finish_stakeholder_assign', actions: 'setContext' },
                CANCEL: 'finish_stakeholder_assign',
            },
        },

        finish_stakeholder_assign: {
            type: 'final',
            entry: 'final',
        },
        onDone: {
            actions: 'stopMachine'
        }
    },
}
const steps = {
    select_current: Step2
}

const options = {
    guards: {
        haveOldPassport,

    }
}



export default class MachineProfileStakeholder extends Machine {
    constructor() {
        super(machineDefinition, options, steps);
    }

    getParamsData(values) {
        return values;
    }
    /*
    
    getParamsData({ paramsMutation }) {
        return { ...paramsMutation }
    }*/
}

import React, {useEffect, useState} from 'react';
import {ServerConnect} from "../utils";
import _ from "lodash";
import Mustache from 'mustache'

const QUERY = `query{
  getQuestionnaireDirectorsDraftFlow(draftId:"{{draftId}}"){
    individuals{
      id
      firstName
      lastName
    }
    companies{
      id
      companyName
    }
  }
}`;
export default function useQuestionnaireDirectorsDraftFlow(matterId, draftId) {
  const [directors, setDirectors] = useState([]);
  useEffect(() => {

    const query_params = {
      draftId
    };
    
    const query = Mustache.render(QUERY, query_params);
    ServerConnect.graphQlQuery(query).then(result => {
      let individuals = _.get(result, "getQuestionnaireDirectorsDraftFlow.individuals", []);
      let companies = _.get(result, "getQuestionnaireDirectorsDraftFlow.companies", []);
      let directors = [];
      _.forEach(individuals,(individual)=>{
        directors.push({id: individual.id, firstName:individual.firstName+ " "+ individual.lastName})
      })
      _.forEach(companies,(company)=>{
        directors.push({id: company.id, firstName:company.companyName})
      })
      setDirectors(directors)
    });
   

  }, [matterId,draftId]);

  return { directors };
}
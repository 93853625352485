import  useMutation  from './useMutation';
import _ from "lodash";

/**
 *
 * @param {*} query
 * @param {
 *  callbackBeforeEvict: ():=><Promise> Use this callback to execute redirect or distroy all elements data use the objects in the cache.
 * } config
 * @param {*} props
 * @returns
 */

export default (query, config = {}, props = {}) => {
    const defaultShouldRefetchQuery = (observer)=>{
        return true;
    }
    const onQueryUpdated = (observableQuery) =>{
        const validate = _.get(config,"shouldRefetchQuery", defaultShouldRefetchQuery);

		if (validate(observableQuery)) {
            return observableQuery.refetch();
        }
    }

    const updateCache = async (cache, { data })=> {
        const mutationName = Object.keys(data)[0];
        const identity = cache.identify(data[mutationName]);

		// Use this callback to execute redirect or destroy all elements data use the objects in the cache.
		if (!!_.get(config,"callbackBeforeEvict")) await config.callbackBeforeEvict(cache,data[mutationName])

		cache.evict({ id: identity,broadcast: false })
        cache.gc()
    };

    const result  = useMutation(query,config,{update: updateCache, onQueryUpdated,...props});

    return result;
}


import _ from "lodash";
import hash from "object-hash";
import { useMemo, useState, useEffect } from "react";
import useSession from "../../session/hooks/useSession";
import useTranslate from "./../../../modules/generic/hooks/useTranslate";
import useCompanyCorporateMapTasks from "./services/useGetCompanyCorporate";

const useDynamicTasks = (configDocuments, configStakeholders, getParams, preData) => {

  
  const { startupId } = useSession();
  const { translate } = useTranslate();
  const { dataMapAssistance, loadingMapAssistance } =  useCompanyCorporateMapTasks({ startupId: startupId },null,{enabled: !preData});



  const handleStatus = (status, onClick) => {
    let statusVariation;
    switch (status) {
      case "IN_PROGRESS":
        statusVariation = "inprogress";
      break
      case "COMPLETED":
        statusVariation = "completed";
        break
      case "READY":
        statusVariation = "ready";
        break
      case "PENDING":
      default:
        if (onClick) {
          statusVariation = "addTask";
        } else {
          statusVariation = "incompleted";
        }
        break
    }
    return statusVariation;
  }

  const dataTasks = useMemo(() => {
    let result = {};
    _.forEach(_.get(dataMapAssistance, "companyCorporateMap.companies", []), (company) => {
      _.forEach(company.tasks,  (task) => {
        const configTask = _.find(configDocuments, (config) => config.code === task.code);
        const configTasksOrder = _.findIndex(configDocuments, (config) => config.code === task.code);
        if (!!configTask) {
          if (!result[`gc_${company.id}`]) {
            result[`gc_${company.id}`] = { group: company.name, icon: 'Company', tasks: [] }
          }
          let params = {}
          if (getParams) {
            params =  getParams(task,{company})
          }
          const taksInformation = {
            code: configTask.code,
            status: configTask.status,
            params
          }
          const valueHash = hash(taksInformation);
          const exists = _.find(result[`gc_${company.id}`].tasks, (task) => task.hash === valueHash);
          if (!exists) {            
            result[`gc_${company.id}`].tasks.push(
              {
                code: configTask.code,
                onClick: task.status === "PENDING" ? () => configTask.onClick(taksInformation.params) : null,
                status: handleStatus(task.status, true),
                name: translate(`MODULES.ASSISTANCEV4.ASSISTANCE_TASKS_MAP.${configTask.code}`, taksInformation.params),
                order: configTasksOrder
              }
            )
          }
        }
      });
      _.forEach(_.get(company, "equity", []), (equity) => {
        _.forEach(_.get(equity,"tasks",[]),  (task) => {
          const configGroupCompany = _.find(configDocuments, (config) => config.code === task.code);
          const configGroupCompanyOrder = _.findIndex(configDocuments, (config) => config.code === task.code);
          if (!!configGroupCompany) {
            if (!result[`gc_${company.id}`]) {
              result[`gc_${company.id}`] = { group: company.name, icon: 'Company', tasks: [] }
            }
            let params = {}
            if (getParams) {
              params =  getParams(task,{info:equity,company})
            }
            const taksInformation = {
              code: configGroupCompany.code,
              status: configGroupCompany.status,
              params
            }
            const valueHash = hash(taksInformation);
            const exists = _.find(result[`gc_${company.id}`].tasks, (task) => task.hash === valueHash);
            if (!exists) {
              result[`gc_${company.id}`].tasks.push(
                {
                  hash: valueHash,
                  code: configGroupCompany.code,
                  onClick: task.status === "PENDING" ? () => configGroupCompany.onClick(taksInformation.params) : null,
                  status: handleStatus(task.status, true),
                  name: translate(`MODULES.ASSISTANCEV4.ASSISTANCE_TASKS_MAP.${configGroupCompany.code}`, taksInformation.params),
                  order:configGroupCompanyOrder
                }
              )
            }
          }
        });
      });
    });

    _.forEach(_.get(dataMapAssistance, "companyCorporateMap.stakeholders", []), (stakeholder) => {
      _.forEach(stakeholder.tasks,  (task) => {
        const configTaksStakeholder = _.find(configStakeholders, (config) => config?.code === task?.code);
        const configTaksStakeholderOrder = _.findIndex(configStakeholders, (config) => config?.code === task?.code);
        if (!!configTaksStakeholder) {
          if (!result[`sh_${stakeholder.id}`]) {
            result[`sh_${stakeholder.id}`] = { group: stakeholder.fullName, icon: 'Person', tasks: [] }
          }
          let params = {}
          if (getParams) {
            params =  getParams(task,{stakeholder})
          }
          const taksInformation = {
            code: configTaksStakeholder.code,
            status: configTaksStakeholder.status,
            params
          }
          const valueHash = hash(taksInformation);
          const exists = _.find(result[`sh_${stakeholder.id}`].tasks, (task) => task.hash === valueHash);
          if (!exists) {
            result[`sh_${stakeholder.id}`].tasks.push(
              {
                hash: valueHash,
                code: configTaksStakeholder.code,
                onClick: task.status === "PENDING" ? () => configTaksStakeholder.onClick(taksInformation.params) : null,
                status: handleStatus(task.status, true),
                name: translate(`MODULES.ASSISTANCEV4.ASSISTANCE_TASKS_MAP.${configTaksStakeholder.code}`, taksInformation.params),
                order: configTaksStakeholderOrder
              }
            )
          }
        }
      });

      const concatTasks = _.concat(stakeholder.roles,stakeholder.warrants,stakeholder.convertibles,stakeholder.equity);
      _.forEach(concatTasks, (groupTaks) => {
        _.forEach(_.get(groupTaks,"tasks",[]),  (task) => {
          const configTaksStakeholder = _.find(configStakeholders, (config) => config?.code === task?.code);
          const configTaksStakeholderOrder = _.findIndex(configStakeholders, (config) => config?.code === task?.code);
          if (!!configTaksStakeholder) {
            if (!result[`sh_${stakeholder.id}`]) {
              result[`sh_${stakeholder.id}`] = { group: stakeholder.fullName, icon: 'Person', tasks: [] }
            }
            let params = {}
            if (getParams) {
              params =  getParams(task,{info:groupTaks,stakeholder})
            }
            const taksInformation = {
              code: configTaksStakeholder.code,
              status: configTaksStakeholder.status,
              params
            }
            const valueHash = hash(taksInformation);
            const exists = _.find(result[`sh_${stakeholder.id}`].tasks, (task) => task.hash === valueHash);
            if (!exists) {
              result[`sh_${stakeholder.id}`].tasks.push(
                {
                  hash: valueHash,
                  code: configTaksStakeholder.code,
                  onClick: task.status === "PENDING" ? () => configTaksStakeholder.onClick(taksInformation.params) : null,
                  status: handleStatus(task.status, true),
                  name: translate(`MODULES.ASSISTANCEV4.ASSISTANCE_TASKS_MAP.${configTaksStakeholder.code}`, taksInformation.params),
                  order:configTaksStakeholderOrder
                }
              )
            }
          }
        });
      });
    });

    _.forEach(result, (res) => {
      res.tasks.sort((a, b) => a.order - b.order);
    });

    return result;
  }, [JSON.stringify(dataMapAssistance)]);
  return { dataTasks, loading: loadingMapAssistance };
};
export default useDynamicTasks;
import get from 'lodash/get';
import { useMemo } from 'react';
import GOALS from 'src/modules/Onboarding/constants/goals';

const useGuards = () => {
  return useMemo(() => ({

    isNotVerified: (context) => {
      return !get(context, 'verifyCode')
    },

    isVerified: (context) => {
      return get(context, 'verifyCode')
    },

    isFormation: (context) => {
      return get(context, 'GoalStep.goal') === GOALS.FORMATION
    },

    isBuildCapTable: (context) => {
      return get(context, 'GoalStep.goal') === GOALS.BUILD_CAP_TABLE
    },

    isGoToDocuments: (context) => {
      return get(context, 'GoalStep.goal') === GOALS.GOAL_GENERATE_DOCUMENT
    },

    isAccelerator: (context) => {
      return get(context, 'GoalStep.goal') === GOALS.ACCELERATOR
    },

    isNotEmptyAccelerator: (context) => {
      const accelerators = get(context, 'getAccelerators', []);
      return accelerators.length > 0
    },

    isEmptyAccelerator: (context) => {
      const accelerators = get(context, 'getAccelerators', []);
      return accelerators.length === 0
    }
    
  }), []);
}

export default useGuards;
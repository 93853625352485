import Machine from "../../../generic/components/Machine/MachineClass";
import { StepPreviewDocument, StepDecline } from "./Steps";

const machineDefinition = {
  id: "document_review",
  initial: "preview_document",
  states: {
    preview_document: {
      on: {
        NEXT: { target: "decline_data", actions: "setContext" },
        CANCEL: "finish_document_review",
      },
    },
    decline_data: {
      on: {
        NEXT: { target: "finish_document_review", actions: "setContext" },
        CANCEL: "finish_document_review",
        PREVIOUS: { target: "preview_document" },
      },
    },
    finish_document_review: {
      type: "final",
      entry: "final",
    },
    onDone: {
      actions: "stopMachine",
    },
  },
};
const steps = {
  preview_document: StepPreviewDocument,
  decline_data: StepDecline,
};

const options = {};

export default class MachineCompany extends Machine {
  constructor() {
    super(machineDefinition, options, steps);
  }
}

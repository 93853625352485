import TemplateWizardSkeletonMachine from "src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine";
import { ConfirmDocumentsStep } from "../steps";

const useSteps = () => {

  return {
    Save: {
      render: TemplateWizardSkeletonMachine,
    },
    GetLink: {
      render: TemplateWizardSkeletonMachine,
    },
    ConfirmDocumentsForm: {
      render: ConfirmDocumentsStep
    }
  }
}

export default useSteps;
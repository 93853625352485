import Machine from "../../../generic/components/Machine/MachineClass";
import { StepMoreDetails, StepPositionDepartment, StepSelectCompany, StepResponsibilities } from './Steps';
import { assign } from 'xstate';
import _ from 'lodash';
import { hasRolesPosition } from "./utils";



const setCompanies = assign((context: any) => {
    const { data } = context;
    return {
        ...context,
        companies: data.select_company.company
    }
})


const isFinish = (context) => {
    const { numbersOfIteration, companies } = context;
    return companies.length === (numbersOfIteration + 1);
};

const isEnablePositions = (context) => {
    const { roles } = context.data.more_details;
    return hasRolesPosition(roles);
}


const machineDefinition = {
    id: 'roles_and_positions',
    initial: 'select_company',
    states: {
        select_company: {
            on: {
                NEXT: { target: 'more_details', actions: ['setContext', 'setCompanies'] },
                CANCEL: 'finish_stakeholder',
            },

        },
        more_details: {
            on: {
                PREVIOUS: { target: 'select_company', actions: 'setContext' },
                NEXT: { target: 'loading_positions', actions: 'setContext' },
                CANCEL: 'finish_stakeholder',
            },
        },
        loading_positions: {
            always: [
                { target: 'select_positions_department', cond: 'isEnablePositions' },
                { target: 'more_details', actions: ['setGlobal'] }
            ]
        },
        select_positions_department: {
            on: {
                PREVIOUS: { target: 'more_details', actions: 'setContext' },
                NEXT: { target: 'select_responsabilities', actions: 'setContext' },
                CANCEL: 'finish_stakeholder',
                NEXT_NO_POSITIONS: { target: 'more_details', actions: ['setContext', 'setGlobal'] }
            },

        },
        select_responsabilities: {
            on: {
                PREVIOUS: { target: 'select_positions_department', actions: 'setContext' },
                NEXT: { target: 'loading_data', actions: 'setContext' },
                CANCEL: 'finish_stakeholder',
            },

        },
        loading_data: {
            always: [
                { target: 'finish_stakeholder', cond: 'isFinish' },
                { target: 'more_details', actions: ['setGlobal'] }
            ]
        },
        finish_stakeholder: {
            type: 'final',
            entry: 'final',
        },
        onDone: {
            actions: 'stopMachine'
        }
    },
}
const steps = {
    select_company: StepSelectCompany,
    more_details: StepMoreDetails,
    select_positions_department: StepPositionDepartment,
    select_responsabilities: StepResponsibilities
}

const options = {
    actions: {
        setCompanies
    },
    guards: {
        isFinish,
        isEnablePositions
    }
}



export default class MachineProfileStakeholder extends Machine {
    constructor() {
        super(machineDefinition, options, steps);
    }

    getParamsData(values) {
        return values;
    }
}

import { Chip, Typography } from '@mui/material';
import React, { FC } from 'react';
/**
 * Component to render a tag with a label
 * @param {object} props - Component props
 * @param {string} props.label - The label of the tag
 * @returns {JSX.Element} - The Tag component
 */
interface Props {
    label: string;
    style?: any;
}
const colors = {
    default: '#2C74FF',
    blue: '#2C74FF',
    draft: '#FCC72D',
    published: '#18C5A8',
    amended: '#796EFC',
    vestingongoing: '#82C66B',
    fullyvested: '#82C66B',
    vested: '#82C66B',
    unvested: '#192A3E',
    vestingstopped: '#E95D76',
    lapsed: '#B28B67',
    partiallyexercised: '#EE9716',
    fullyexercised: '#EE9716',
    transferred: '#C45DE9',
    partiallyconverted: '#0277BD',
    fullyconverted: '#0277BD',
    repurchased: '#0277BD',
    cancelled: '#E95D76',
    pending: '#EE9816',
    converted: '#0277BD',
    exercise: '#EE9716',
    pendingsignature: '#ffd600'
}
const Tag:FC<Props> = ({ label,style }) => {

  /**
   * Convert the label to the format used for theme colors
   * @param {string} labelToConvert - The label to convert
   * @returns {string} - The converted label
   */
  const handleVariant = (labelToConvert:string) => labelToConvert.toLowerCase().replace(/\s+/g, '').replace('_', '');

  /**
   * Format the label to remove the underscore
   */
  const newFormatedLabel = (labelToConvert:string) => {
    const removeData = labelToConvert.replace('_', ' ');
    return removeData.charAt(0).toUpperCase() + removeData.slice(1).toLowerCase();
  };

  return (
    <>
      {label && (
        <Chip
          label={
            <Typography variant="h6" sx={{ color: `${colors[handleVariant(label)]}` }}>
              {newFormatedLabel(label)}
            </Typography>
          }
          sx={{
            ...style,
            backgroundColor: `${colors[handleVariant(label)]}20`
          }}
        />
      )}
    </>
  );
}

export default Tag;

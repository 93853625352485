import React from "react";
import _ from "lodash";
import moment from "moment";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/date-fns";
import { Constants, helper } from "../../../v1/utils";
import { TooltipInput } from "../../../v1/components";
import Icon from "../../../modules/generic/components/Icon";
import claraTheme from "../../../styles/themes/clara";
import "../../../styles/themes/ClaraTheme.css";
import classes from "./Calendar.module.scss";
import classnames from "classnames";

const materialTheme = createTheme(claraTheme);

function InputCalendarDateTime({
  tooltip,
  type,
  errorMessage,
  onChangeGetDate = false,
  ...props
}) {
  let formatDateTime = helper.getFormatByKey(Constants.TYPE_FORMATS.DATE_TIME);
  let formatDate = helper.getFormatByKey(Constants.TYPE_FORMATS.DATE);
  let formatTime = helper.getFormatByKey(Constants.TYPE_FORMATS.TIME);

  return (
    <TooltipInput label={tooltip}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ThemeProvider theme={materialTheme}>
          {type === "dateTime" ? (
            <KeyboardDateTimePicker
              autoOk
              variant="inline"
              className={classnames(classes.CalendarInput, {
                [classes.disabled]: props?.disabled,
              })}
              views={["year", "month", "date", "hours", "minutes"]}
              inputVariant="outlined"
              openTo="year"
              ampm={false}
              initialFocusedDate={null}
              clearable
              format={formatDateTime}
              {...props}
              error={errorMessage}
              helperText={errorMessage}
              InputAdornmentProps={{ position: "start" }}
              onChange={(e) => {
                props.onChange(e);
              }}
            />
          ) : null}
          {type === "date" ? (
            <>
              <KeyboardDatePicker
                autoOk
                variant="inline"
                className={classnames(classes.CalendarInput, {
                  [classes.disabled]: props?.disabled,
                })}
                views={["year", "month", "date"]}
                inputVariant="outlined"
                openTo="year"
                ampm={false}
                initialFocusedDate={null}
                clearable
                format={formatDate}
                {...props}
                error={errorMessage}
                helperText={errorMessage}
                value={props.value ? moment(props.value, "YYYY-MM-DD") : null}
                InputAdornmentProps={{ position: "start" }}
                keyboardIcon={<Icon icon="Date" size="1rem" isClara={true} />}
                onChange={(e) => {
                  const m = moment(e, formatDate);
                  if (_.isNull(e)) {
                    if (onChangeGetDate) {
                      props.onChange(m.format("YYYY-MM-DD"));
                    } else {
                      props.onChange(null);
                    }
                  } else {
                    props.onChange(m.format("YYYY-MM-DD"));
                  }
                }}
              />
            </>
          ) : null}
          {type === "time" ? (
            <>
              <KeyboardTimePicker
                autoOk
                variant="inline"
                className={classnames(classes.CalendarInput, {
                  [classes.disabled]: props?.disabled,
                })}
                inputVariant="outlined"
                ampm={false}
                initialFocusedDate={null}
                clearable
                format={formatTime}
                keyboardIcon={<Icon icon="Date" size="1rem" isClara={true} />}
                {...props}
                error={errorMessage}
                helperText={errorMessage}
                InputAdornmentProps={{ position: "start" }}
                onChange={(e) => {
                  props.onChange(e);
                }}
              />
            </>
          ) : null}
          {type === "day" ? (
            <>
              <KeyboardDatePicker
                autoOk
                variant="inline"
                className={classnames(classes.CalendarInput, {
                  [classes.disabled]: props?.disabled,
                })}
                views={["month", "date"]}
                inputVariant="outlined"
                openTo="month"
                ampm={false}
                initialFocusedDate={null}
                clearable
                format={formatDate}
                {...props}
                error={errorMessage}
                helperText={errorMessage}
                InputAdornmentProps={{ position: "start" }}
                keyboardIcon={<Icon icon="Date" size="1rem" isClara={true} />}
                onChange={(e) => {
                  props.onChange(e);
                }}
              />
            </>
          ) : null}
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </TooltipInput>
  );
}

export default InputCalendarDateTime;

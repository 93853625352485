import { default as React, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import Form from "src/components/forms/Form/Form";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import Button from "src/modules/generic/components/Buttons/Button";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import {
  clearAssistatText,
  addAssistantText,
} from "src/modules/generic/store/action";
import TextareaInput from "src/components/inputs/Textarea/TextareaInput";
import SelectInput from "src/components/inputs/Select/SelectInput/SelectInput";
import TextInput from "src/components/inputs/Text/TextInput";
import { HiddenFieldForm } from "src/components/forms/contents";
import classes from "./classes.module.scss";

export const listPossibleSourcesOfWealth = [
  { value: "Wages", label: "Wages" },
  { value: "Business income", label: "Business income" },
  { value: "Investments", label: "Investments" },
  { value: "Asset sale", label: "Asset sale" },
  { value: "Inheritance", label: "Inheritance" },
  { value: "others", label: "Others" },
];

/**
 * Description Step: Review UBO Source of funds
 */
function ReviewUboSofFormStep() {
  const { next, context, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const UBOName = context?.initialData.stakeholder.fullName;

  const initialValues =
    context.ReviewUboSofFormStep || context.initialData.sourceOfFunds;

  const handleCancel = () => cancel();

  const handlePrev = () => prev();

  const handleSubmit = async (values) => next(values);

  useEffect(() => {
    dispatch(
      addAssistantText(
        `<strong>Source of funds</strong> refers to the origin of money used for the company’s payments. Click <a target="_blank" href="https://help.clara.co/en/articles/6394773-what-does-source-of-funds-mean">here</a> to learn more.
      <br><br>
      <strong>Source of wealth</strong> refers to how this person accrued their total wealth. Click <a target="_blank" href="https://help.clara.co/en/articles/6394792-what-is-the-difference-between-source-of-wealth-and-source-of-funds">here</a> to learn more.`
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const schema = Yup.object().shape({
    sourceOfFunds: Yup.string().required("Required").nullable(),
    sourceOfWealth: Yup.string().required("Required").nullable(),
    otherSourcesOfWealth: Yup.mixed().when("sourceOfWealth", {
      is: "others",
      then: Yup.string().required("Required").nullable(),
      otherwise: Yup.string().nullable(),
    }),
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: "Review and confirm their source of funds",
          skeleton: null,
          subTitle: "Review details for " + UBOName,
          variant: "simple",
        }}
        buttons={{
          previous: (
            <Button variant="secondary" onClick={handlePrev}>
              {translate("BUTTON_PREVIOUS")}
            </Button>
          ),
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("BUTTON_CANCEL")}
            </Button>
          ),
          submit: <SubmitButton>{translate("BUTTON_CONFIRM")}</SubmitButton>,
        }}
      >
        <TemplateLabel
          variant="top"
          label={translate("MODULES.STAKEHOLDER.FORM.SOURCE_OF_FUNDS.FUNDS")}
          isRequired
        >
          <ControllerInput
            name="sourceOfFunds"
            render={TextareaInput}
            className={classes.TextArea}
            placeholder={translate(
              "MODULES.STAKEHOLDER.FORM.SOURCE_OF_FUNDS.FUNDS_PLACEHOLDER"
            )}
          />
        </TemplateLabel>
        <TemplateLabel
          label={translate("MODULES.STAKEHOLDER.FORM.SOURCE_OF_FUNDS.WEALTH")}
          isRequired
        >
          <ControllerInput
            name="sourceOfWealth"
            render={SelectInput}
            clear={true}
            placeholder={translate(
              "MODULES.STAKEHOLDER.FORM.SOURCE_OF_FUNDS.WEALTH_PLACEHOLDER"
            )}
            list={listPossibleSourcesOfWealth}
          />
        </TemplateLabel>
        <HiddenFieldForm
          fieldsWatch="sourceOfWealth"
          conditionHideCallback={(value) => value === "others"}
        >
          <TemplateLabel label="Other Sources Of Wealth" isRequired>
            <ControllerInput name="otherSourcesOfWealth" render={TextInput} />
          </TemplateLabel>
        </HiddenFieldForm>
      </TemplateWizard>
    </Form>
  );
}

export default ReviewUboSofFormStep;

import { clearAssistatText } from "../../../generic/store/action";
import _ from 'lodash';
import CheckOut from './Steps/Checkout'
import EditCard from './Steps/EditCard';
import Failure from './Steps/Failure';
import machine from './machine';
import ModalAssistant from '../../../generic/components/Modal/ModalAssistant'
import React, { useMemo, useState } from 'react';
import Success from './Steps/Success'
import TemplateWizardSkeletonMachine from '../../../generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import useModal from "../../../generic/hooks/useModal";
import Wizard from '../../../generic/components/Wizard/v2';
// import useUpdateCardInSubscriptions from "../../../checkout/hooks/useUpdateCardInSubscriptions";
import useUpdateCreditCardToAStartup from "../../../checkout/hooks/useUpdateCreditCardToAStartup";
import useAddCreditCardToAStartup from "../../../checkout/hooks/useAddCreditCardToAStartup";
import useCheckoutApi from "./../../../../modules/checkout/hooks/useCheckoutApi"
import useSession from "../../../../modules/session/hooks/useSession";
import TimeOutStep from "./Steps/TimeOutStep";


/**
 * Wizard to Change card
 * addded with the new state machine 
 * @returns
 */

function ModalChangeCard({
  open: openParam = false,
  source = 'STARTUP'
}) {

  const [open, setOpen] = useState(openParam);
  const { closeModal: onClose } = useModal();
  // const [card, setCard] = useState(null);
  const { startup } = useSession();

  // const [upsertCard, loading] = useUpdateCardInSubscriptions({
  //   showSuccessNotification: false,
  //   showErrorNotification: false,
  // });
  const [addCard, loadingAdd] = useAddCreditCardToAStartup({
    showSuccessNotification: false,
    showErrorNotification: false,
  });

  const [updateCard, loadingUpdate] = useUpdateCreditCardToAStartup({
    showSuccessNotification: false,
    showErrorNotification: false,
  });

  const steps = useMemo(() => ({
    //Step 1: show the form to edit the card
    stepEditCard: {
      render: EditCard,
      stepperPosition: 0
    },
    //Step 2: show the check out screen
    stepCheckout: {
      render: CheckOut,
      stepperPosition: 0
    },
    //Step Success: show the success screen
    stepSuccess: {
      render: Success,
      stepperPosition: 0
    },
    //Step Failure: show the failure screen
    stepFailure: {
      render: Failure,
      stepperPosition: 0
    },
    //This is the skeleton for the addCardService
    serviceAddCard: {
      render: TemplateWizardSkeletonMachine,
      renderProps: {
        title: 'test title',
        subTitle: 'Test sub title',
      },
      stepperPosition: 0
    },
    //this show the skeleton component for the service
    serviceAddUpdateNewCard: {
      render: TemplateWizardSkeletonMachine,
      renderProps: {
        title: 'test title',
        subTitle: 'Test sub title',
      },
      stepperPosition: 0
    },
    TimeOutStep: {
      render: TimeOutStep
    }

  }), []);

  const guards = {
    isSuccess: (context) => {
      return _.get(context, 'stepCheckout.status') === "success";
    },
    isFailed: (context) => {
      return _.get(context, 'stepCheckout.status') !== "success";
    },
    isCardUpdatedOk: (context) => {
      return (_.get(context, 'addNewCard.result') === "ok" && _.get(context, 'addNewCard.updated'))
    },

    isCardOk: (context) => {
      return (_.get(context, 'addNewCard.result') === "ok" && !_.get(context, 'addNewCard.updated'));
    },

  };

  const services = {
    addNewCard: async (context, event) => {

      const result = await handleAddEditCard(_.get(context, 'stepEditCard'))
      return result;
    },
  };

  const actions = {

  };

  const handleStopMachine = () => {
    onClose();
    setOpen(false);
    clearAssistatText()
  }

  const { keys: checkoutSource } = useCheckoutApi("STARTUP")

  const getCheckoutToken = async (values) => {


    const paramsCheckout = {
      name: _.get(values, "cardHolderName", ""),
      cvv: _.get(values, "cvc", ""),
      expiry_month: parseInt(_.get(values, "expiryMonth")),
      expiry_year: parseInt(_.get(values, "expiryYear")),
      number: _.get(values, "bankCardNumber", "").replaceAll(" ", ""),
      phone: {},
      requestSource: "JS",
      type: "card",
    };

    const options = {
      headers: {
        Authorization: _.get(checkoutSource, `token`),
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(paramsCheckout),
    };

    const api = _.get(checkoutSource, `api`);

    const responseCheckOut = await fetch(api, options);

    if (responseCheckOut.ok) {
      const json = await responseCheckOut.json();
      return json;
    } else {
      throw new Error("Fetch to checkout failed");
    }
  };

  const handleAddEditCard = async (values) => {
    let checkout = '';
    try {
      if (_.get(values, 'bankCardId') === "") {
        checkout = await getCheckoutToken(values);
      }
      const paymentValues = {
        creditCardToken: _.get(checkout, "token", null),
        billingAddress: {
          country: _.get(values, "billingAddress.country"),
          addressLine1: _.get(values, "billingAddress.addressLine1"),
          addressLine2: _.get(values, "billingAddress.addressLine2"),
          city: _.get(values, "billingAddress.city"),
          state: _.get(values, "billingAddress.state"),
          zipCode: _.get(values, "billingAddress.zipCode"),
        },
        cardHolderName: _.get(values, "cardHolderName"),
        productCode: _.get(startup, "activeSubscriptionPlan.code"),
        startupId: _.get(startup, "id"),
      };

      if (_.get(values, "onBehalfOfAVATRegisteredCompany")) {
        paymentValues.billingAddress.onBehalfOfAVATRegisteredCompany = _.get(
          values,
          "onBehalfOfAVATRegisteredCompany"
        );
      }


      let result = null;
      let url = null;
      let updated = false;
      if (
        _.get(values, "bankCardId", "") == '' ||
        _.get(values, "bankCardId") == null
      ) {
        result = await addCard(paymentValues);
        url = _.get(result, "data.addCreditCardToAStartup");
      } else {
        delete paymentValues.creditCardToken;

        result = await updateCard({
          ...paymentValues,
          creditCardId: _.get(values, "bankCardId"),
        });
        updated = true;
        url = _.get(result, "data.updateCreditCardToAStartup");

      }

      if (result) {
        return {
          result: "ok",
          url: url,
          updated: updated
        };
      } else {
        return { result: "fail" };

      }
    } catch (e) {
      return { result: "fail" };
    }
  };

  return (
    <ModalAssistant open={open}>
      <Wizard
        guards={guards}
        machine={machine}
        steps={steps}
        services={services}
        actions={actions}
        onStopMachine={handleStopMachine}
      />
    </ModalAssistant>
  );
}

export default ModalChangeCard;

import React, { useMemo } from 'react';
import Icon from '../../../../../../../../modules/generic/components/Icon'
import classes from './Styles.module.scss';
import { PopoverOption } from '../../../../../../../../v1/components';
import _ from 'lodash'
import { useDispatch } from 'react-redux';
import useRoleAccess from '../../../../../../../../modules/security/hooks/useRoleAccess';
import Rename from '../../../../../../../../modules/documents/modals/Rename';
import useModal from '../../../../../../../../modules/generic/hooks/useModal';
import { Constants, ServerConnect } from '../../../../../../../../v1/utils';
import useBusinessRules from '../../../../../../../../modules/clara/hooks/useBusinessRules';
import useDocumentBusinessRules from '../../../../../../../../modules/clara/hooks/useDocumentBussinessRules';

const DocumentName = ({
  data,
  handleOpenViewer,
  name,
  onlyDetails = false,
  tags,
  locked,
  customOpenViewer,
  ...props
}) => {

  const dispatch = useDispatch();
  const { openModal } = useModal();
  const { isStartSubscription, isOwner, isManager, isEditor } = useBusinessRules();
  const { canRenameDocument } = useDocumentBusinessRules(_.get(data, 'original'));
  const isDownloadDocument = useRoleAccess(Constants.ACTIONS.DOWNLOAD_DOCUMENT);
  const downloadToEdit = useRoleAccess(Constants.ACTIONS.DOWNLOAD_DOCUMENT_TO_EDIT);
  const hiddenOpenTab = (_.get(data, "original.file.name", "").split('.').pop() === "docx");

  const handleViewDocumentDetails = () => {
    dispatch({ type: 'GO_TO', page: Constants.PAGES[`viewDocument${_.upperFirst(_.toLower(data.original.kind))}`], params: { id: data.original.id, status: data.original.status } })
  }

  const handleOpenNewTab = async () => {
    const url = await ServerConnect.getPublicUrlFile(data.original.file.id);
    ServerConnect.openFile(url, data.original.file.name);
  }

  const handleDownloadDocument = async () => {
    let url = await ServerConnect.getUrlFile(data.original.file.id);
    ServerConnect.downloadFile(url, data.original.file.name);
  }

  const handleRenameDocument = () => {
    openModal(Rename, {
      document: {
        documentId: _.get(data.original, 'file.id'),
        agreementId: _.get(data.original, 'id'),
        documentName: _.get(data.original, 'name'),
      },
    })
  }

  const downloadDocument = useMemo(() => {
    return isStartSubscription() || !isDownloadDocument
  }, [isStartSubscription]);

  const permissionCheck = () => {
    let roles = !((isOwner() || isManager() || isEditor()) && canRenameDocument())
    return roles;
  }

  const optionsDocuments = useMemo(() => {
    const hiddenRename = permissionCheck();
    const options = ([
      {
        label: "Preview",
        onClick: () => handleOpenViewer(data.original)
      },
      {
        label: "Details",
        onClick: handleViewDocumentDetails
      },
      {
        label: "Rename",
        onClick: handleRenameDocument,
        hidden: hiddenRename
      },
      {
        label: "Open in New tab",
        onClick: handleOpenNewTab,
        hidden: hiddenOpenTab
      },
      {
        label: "Download",
        onClick: handleDownloadDocument,
        hidden: downloadDocument
      }
    ])
    return !downloadToEdit && _.get(data, 'original.status') === 'Draft' ? _.filter(options, option => option.label !== 'Download') : options
  }, [downloadDocument, hiddenOpenTab, downloadToEdit])

  return (
    <div className={classes.containerDocumentName}>
      <Icon isClara icon={'Document'} size={'1.5rem'} />
      {onlyDetails ? (
        <label 
          className={locked ? classes.labelDocumentBlock : classes.labelDocument}
          onClick={()=>{
            if (customOpenViewer) {
              customOpenViewer();
            } else {
              handleViewDocumentDetails();
            }
          }}
        >
          {!!name ? name : _.get(data, 'original.name')}
        </label>
      ) : (
        <PopoverOption elevation={0} renderElement={MenuOptionsDocument} propsRenderElement={{ options: optionsDocuments }}>
          <label 
            className={classes.labelDocument}
          >
            {!!name ? name : _.get(data, 'original.name')}
          </label>
        </PopoverOption>
      )}
      {tags && tags}
    </div>
  )
}

export default DocumentName

const MenuOptionsDocument = ({ options = [], closePopover, ...props }) => {

  const handleClose = ({ onClick }) => {
    onClick();
    closePopover()
  }

  return (
    <div className={classes.containerMenuOptionDocument}>
      {options.map((option, index) => {
        return (
          <>{!_.get(option, 'hidden', false) &&
            <div key={`${JSON.stringify(option)}-${index}`} className={classes.containerOption} onClick={() => handleClose(option)}>
              {option.label}
            </div>}
          </>
        )
      })
      }
    </div>
  )
} 

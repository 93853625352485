//Types Forms for Startups

const STARTUP = {
    PROFILE: "PROFILE",
    ADD_CARD: "ADD_CARD",
    EDIT_SUBSCRIPTION:"EDIT_SUBSCRIPTION",
    CANCEL_SUBSCRIPTION:"CANCEL_SUBSCRIPTION"
};


const USER = {
    DEAUTHORISE_DEVICE: "DEAUTHORISE_DEVICE",
    RENAME_DEVICE: "RENAME_DEVICE"
}

const GROUP_COMPANY = {
    SHARE: "SHARE",
    COMPANY_VESTING_SCHEDULE: 'COMPANY_VESTING_SCHEDULE',
    COMPANY_CONNECTED_GRANTS: 'COMPANY_CONNECTED_GRANTS',
    COMPANY_MANUAL_SIP: 'COMPANY_MANUAL_SIP',
    CONVERTIBLE: "CONVERTIBLE",
    SHARE_OPTION_GRANT: "SHARE_OPTION_GRANT",
    WARRANT: "WARRANT",
    SHARE_INCENTIVE_PLAN: "SHARE_INCENTIVE_PLAN",
    SHARE_CLASS: "SHARE_CLASS",
    ADDRESS_AND_CURRENCY: "ADDRESS_AND_CURRENCY",
    PROFILE: "PROFILE",
    NOTICES: "NOTICES",
    DIRECTORS: "DIRECTORS",
    DELETE: "DELETE_GROUP_COMPANY"
};

const INDIVIDUAL_STAKEHOLDER = {
    INDIVIDUAL_STAKEHOLDER_SHARE: 'INDIVIDUAL_STAKEHOLDER_SHARE',
    INDIVIDUAL_STAKEHOLDER_SHARE_OPTION: 'INDIVIDUAL_STAKEHOLDER_SHARE_OPTION',
    INDIVIDUAL_STAKEHOLDER_WARRANT: 'INDIVIDUAL_STAKEHOLDER_WARRANT',
    INDIVIDUAL_STAKEHOLDER_CONVERTIBLE: 'INDIVIDUAL_STAKEHOLDER_CONVERTIBLE',
    INDIVIDUAL_STAKEHOLDER_PROFILE: 'INDIVIDUAL_STAKEHOLDER_PROFILE',
    INDIVIDUAL_STAKEHOLDER_ROLES_POSITION: 'INDIVIDUAL_STAKEHOLDER_ROLES_POSITION',
    INDIVIDUAL_VESTING_SCHEDULE: 'INDIVIDUAL_VESTING_SCHEDULE',
};

const CORPORATE_STAKEHOLDER = {
    CORPORATE_STAKEHOLDER_SHARE: 'CORPORATE_STAKEHOLDER_SHARE',
    CORPORATE_STAKEHOLDER_SHARE_OPTION: 'CORPORATE_STAKEHOLDER_SHARE_OPTION',
    CORPORATE_STAKEHOLDER_WARRANT: 'CORPORATE_STAKEHOLDER_WARRANT',
    CORPORATE_STAKEHOLDER_CONVERTIBLE: 'CORPORATE_STAKEHOLDER_CONVERTIBLE',
    CORPORATE_STAKEHOLDER_PROFILE: 'CORPORATE_STAKEHOLDER_PROFILE',
    CORPORATE_STAKEHOLDER_ROLES_POSITION: 'CORPORATE_STAKEHOLDER_ROLES_POSITION',
    CORPORATE_STAKEHOLDER_NOTICES: "CORPORATE_STAKEHOLDER_NOTICES",
    CORPORATE_AUTHORISED_SIGANATORY: "CORPORATE_AUTHORISED_SIGANATORY",
    CORPORATE_VESTING_SCHEDULE: "CORPORATE_VESTING_SCHEDULE",

};

const DOCUMENT_UPLOAD = {
    EDIT: "EDTI",
    DELETE: "DELETE",
	DELETE_SIP: "DELETE_SIP",
    DOWNLOAD: "DOWNLOAD",
    PREVIEW:"PREVIEW",
    PREVIEW_SIGNATURES:"PREVIEW_SIGNATURES",
};

const DOCUMENT_GENERATED = {
    UPLOAD_DOCUMENT:'UPLOAD_DOCUMENT',
	EDIT: "EDIT",
	DELETE: "DELETE",
	DELETE_GRANT: "DELETE_GRANT",
    TERMINATE_GRANT:"TERMINATE_GRANT",
	DELETE_SIP: "DELETE_SIP",
	DOWNLOAD: "DOWNLOAD",
	GET_SIGNATURES: "GET_SIGNATURES",
	VOID_ENVELOPE: "VOID_ENVELOPE",
	GET_SIGNATURES_EXERCISE_NOTICE:"GET_SIGNATURES_EXERCISE_NOTICE",
	PREVIEW:"PREVIEW",
	PREVIEW_SIGNATURES:"PREVIEW_SIGNATURES",
    DOWNLOAD_ZIP:"DOWNLOAD_ZIP"
};

const EQUITIES = {
    CONVERT_TO_SHARE: 'CONVERT_TO_SHARE',
    ADD_SHARE_CLASS: 'ADD_SHARE_CLASS',
    EDIT_SHARE_CLASS: 'EDIT_SHARE_CLASS'
};

export default {
    STARTUP,
    USER,
    GROUP_COMPANY,
    INDIVIDUAL_STAKEHOLDER,
    CORPORATE_STAKEHOLDER,
    DOCUMENT_UPLOAD,
	DOCUMENT_GENERATED,
    EQUITIES
}


import React, { FC } from 'react'
import { StepProps } from "../../../../../generic/components/Wizard/Step";
import IndividualOrCompanyStakeholderFormStep from "../../../core/wizards/steps/IndividualOrCompanyStakeholderFormStep/IndividualOrCompanyStakeholderFormStep";
import useCustomMachine from "../../../../../generic/hooks/useCustomMachine";
import _ from "lodash";

const IndividualOrCompanyStepFormAdvisor: FC<StepProps> = ({ ...props }) => {

  const { state } = useCustomMachine();

  return (
    <IndividualOrCompanyStakeholderFormStep 
      isIndividual={!_.get(state, 'context.data.stakeholder_is_individual_or_company.isEntity')}
      role={'ADVISOR'}
    />
  )
}
export default IndividualOrCompanyStepFormAdvisor

import React, { useEffect } from 'react'
import Button from "../../../../../generic/components/Buttons/Button"
import useCustomMachine from '../../../../../generic/hooks/useCustomMachine'
import useTranslate from '../../../../../generic/hooks/useTranslate'
import useSession from "../../../../../session/hooks/useSession"
import { useParams } from 'react-router'
import useUpsertFormationCompany from "../../../graphql/mutations/useUpsertFormationCompany"
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard"
import FormConfirmShareholdersDirectors from "../../../Forms/FormConfirmShareholdersDirectors"
import { addAssistantText, clearAssistatText } from '../../../../../generic/store/action';
import { useDispatch } from "react-redux";
import useGetFormationCompanyDirectors from 'src/modules/startup/GroupCompany/graphql/queries/useGetFormationDirectors';

const FirstStep = () => {

  const { translate } = useTranslate()
  const { startupId } = useSession()
  const [mutation] = useUpsertFormationCompany({}, {})
  const { next, send, paramsData } = useCustomMachine()
  const { id: _id } = useParams<{ id: string }>()
  const dispatch = useDispatch();
  const id = paramsData?.idGroupCompany ?? _id;

  useEffect(() => {
    dispatch(
      addAssistantText(
        translate('MODULES.GROUP_COMPANY.CONFIRM_SHAREHOLDERS_DIRECTORS.FIRST_STEP.TOOLTIP')
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, []);

  const isFormationJurisdiction = () => {
    return paramsData.useThisJurisdiction === "ADGM" || paramsData.useThisJurisdiction === "CAYMAN";
  }

  const { data } = useGetFormationCompanyDirectors({ variables: { startupId, groupCompanyId: id } });

  const handleCompleteSubmit = async (values) => {
    if ((values.isAllShareholdersAndDirectorsConfirmed && data?.formationData?.directors.length === 1 && isFormationJurisdiction()) || (!isFormationJurisdiction() && values.isAllShareholdersAndDirectorsConfirmed)) {
      const variables = {
        groupCompanyId: id,
        startupId,
        groupCompanyData: {
          formationData: {
            directorsAndShareholdersCompleted: true,
            directorToSignShareCertificate: data?.formationData?.directors[0].id
          }
        }
      }
      try {
        await mutation(variables)
      }
      catch (e) {
        console.error("**** error",e)
      }
    }
    next(values)
  }

  const handleClose = () => {
    send('CANCEL')
  }

  return (
    <FormConfirmShareholdersDirectors
      onCompleteSubmit={handleCompleteSubmit}
      Template={TemplateWizard}
      addNoText={true}
      propsTemplate={{
        title: translate('MODULES.GROUP_COMPANY.CONFIRM_SHAREHOLDERS_DIRECTORS.FIRST_STEP.TITLE'),
        subTitle: translate('MODULES.GROUP_COMPANY.CONFIRM_SHAREHOLDERS_DIRECTORS.FIRST_STEP.SUBTITLE')
      }}
      buttons={{
        cancel: <Button onClick={handleClose} variant="secondary">Cancel</Button>
      }}
    />
  )
}
export default FirstStep
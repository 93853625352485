import { TypePolicies } from "@apollo/client";
import fields from './Query/fields'
import {StakeholderRole, Goal,StartupUserProfile} from "../../../../modules/map/graphql/typePolicies";
import {VestingSchedule,StakeholderPosition,Role,StakeHolder} from "../../../../modules/startup/Stakeholder/graphql/typePolicies";
import {PossibleSharingProfile,SubscriptionFeatureUsage,Invoice,TaskEntityData,Task} from "../../../../modules/startup/graphql/typePolicies";
import {IssuedEquity,IssuedWarrant,IssuedConvertibles,Position,GroupCompany,Responsibility,FormationData} from "../../../../modules/startup/GroupCompany/graphql/typePolicies";
import {AvatarType,CountryType,Currency,Jurisdiction,Address} from "../../../../modules/generic/graphql/typePolicies";
import {Category,SubCategory,Document,GroupCompanyParty,DocumentType} from "../../../../modules/startup/Documents/graphql/typePolicies";
import {Equity,Share,Convertible,Warrant} from "../../../../modules/startup/equity/graphql/typePolicies";

const typePolicies: TypePolicies = {
    Responsibility,
    StartupUserProfile,
    DocumentType,
    Warrant,
    Convertible,
    Share,
    GroupCompany,
    Equity,
    Document,
    GroupCompanyParty,
    Jurisdiction,
    Currency,
    CountryType,
    AvatarType,
    Category,
    SubCategory,
    StakeHolder,
    Address,
    SubscriptionFeatureUsage,
    PossibleSharingProfile,
    Invoice,
    StakeholderPosition,
    VestingSchedule,
   // FormationData,
    StakeholderRole,
    Goal,
    Task,
    Role,
    TaskEntityData,
    Position,
    IssuedConvertibles,
    IssuedEquity,
    IssuedWarrant,
    DueDiligenceCategoryType: {
        merge: false
    },
    KYCCompanySignatory: {
        merge: true
    },
    Query: {
        fields
    },
}

export default typePolicies;

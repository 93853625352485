import React, { useEffect, useImperativeHandle } from "react";
import { Controller, SetValueConfig, useFormContext } from "react-hook-form";
import _ from "lodash";
import classesModules from "./classes.module.scss";

export interface ControllerInputProps<TFieldValues = any> {
  [key: string]: any;
  /**
   * @deprecated The prop should not be used by update react-hook-form
   */
  as?: any;
  /**  Component Render */
  render?: any;
  /**  Name Component */
  name: string;
  /**  List of Erros */
  disabled?: boolean;
  /**  Class to add the component */
  className?: string;
  /**  Default component */
  defaultValue?: any;
  /** Onchange method */
  onChange?: (
    values: TFieldValues,
    setValue?: (
      name: string,
      value: TFieldValues,
      options?: SetValueConfig
    ) => void
  ) => void;
  /** onFocus method */
  onFocus?: (any) => void;
  /** onBlur method */
  onBlur?: (any) => void;
  /** ref  in component input */
  innerRef?: any;
  isRequired?: boolean;
}

const useIsComponentMounted = () => {
  const isMounted = React.useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []); // Using an empty dependency array ensures this only runs on unmount
  return isMounted.current;
};

const ControllerInput = <ValuesInput extends object>(
  {
    name,
    onChange,
    defaultValue,
    error,
    resetFields,
    render,
    as,
    innerRef,
    disabled,
    isRequired,
    ...props
  }: ControllerInputProps<ValuesInput>,
  ref: React.RefObject<any>
): ReturnType<typeof ControllerInput> => {
  const {
    control,
    formState,
    getValues,
    watch,
    trigger,
    clearErrors,
    setValue: setValueForm,
    onAutoSave,
    onFocus,
    onBlur,
  } = useFormContext();

  const { isSubmitting } = formState;
  const nameWatch = watch(name);
  const isMountComponent = useIsComponentMounted();

  useEffect(() => {
    isMountComponent && onChange && onChange(nameWatch, setValueForm);
    onAutoSave && onAutoSave(name, nameWatch);
    //TODO: Review  if this solution can apply with yup
    if (resetFields) {
      _.forEach(resetFields, (element) => {
        if (!(element.validate && !element.validate(nameWatch, getValues()))) {
          trigger(element.field);
          clearErrors(element.field);
        }
      });
    }
  }, [JSON.stringify(nameWatch)]);

  useImperativeHandle(ref, () => ({
    setValue(value) {
      setValueForm(name, value, { shouldValidate: true, shouldDirty: true });
      //   onChange && onChange(value, setValueForm);
    },
  }));

  const customOnFocus = () => {
    if (onFocus) {
      onFocus(name);
      props && props.onFocus && props.onFocus(nameWatch);
    }
  };

  const customOnBlur = () => {
    if (onBlur) {
      onBlur(name);
      props && props.onBlur && props.onBlur(nameWatch);
    }
  };

  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field, formState, fieldState }) => {
          return React.createElement(as || render, {
            ...field,
            formState,
            fieldState,
            ...props,
            innerRef,
            errors: _.get(fieldState, `error`, {}),
            onFocus: customOnFocus,
            onBlur: customOnBlur,
            errorMessage: _.get(fieldState, `error.message`, error),
            disabled: disabled || isSubmitting,
          });
        }}
      />
      {isRequired && (
        <span className={classesModules.required}>*</span>
      )}
    </>
  );
};

const ControllerInputClara = React.forwardRef(ControllerInput) as <T>(
  props: ControllerInputProps<T> & { ref?: any }
) => ReturnType<typeof ControllerInput>;

export default ControllerInputClara;

import React from 'react';
import PAGES from "../../../constants/pages";
import ACTIONS from "../../../constants/actions"
import ClaraLayout from "../../../layouts/Clara/ClaraLayout";
import SystemLayout from "../../../layouts/System/SystemLayout";
import PaymentSubscriptionView from "./Payment/PaymentSubscriptionView";
import PaymentFormationView from './Payment/PaymentFormationView';
import PaymentFormationADGMView from './Payment/PaymentFormationADGMView';
import PaymentFormationKYView from './Payment/PaymentFormationKYView';
import PaymentFormationDelawareView from './Payment/PaymentFormationDelawareView';
import PaymentSuccessView from "./PaymentSuccess/PaymentSuccessView";
import PaymentErrorView from "./PaymentError/PaymentErrorView";

const routes = [
  {
    path: PAGES.MODULES.CHECKOUT.payment.path,
    exact: true,
    component: <PaymentSubscriptionView />,
    layout: ClaraLayout,
    propsLayout: {
      startupMenu: false,
      userMenu: true,
      fullMenu: false,
      showAssistance: false,
      cantBack: false,
    },
    action: ACTIONS.MANAGE_SUBSCRIPTIONS,
  },
  {
    path: PAGES.MODULES.CHECKOUT.paymentSuccess.path,
    exact: true,
    component: <PaymentSuccessView/>,
    layout: SystemLayout,
    propsLayout: {
      
    },

  },
  {
    path: PAGES.MODULES.CHECKOUT.paymentError.path,
    exact: true,
    component: <PaymentErrorView/>,
    layout: SystemLayout,
    propsLayout: {
      
    },
  },
	{
		path: PAGES.MODULES.CHECKOUT.formationPayment.path,
		exact: true,
		component: <PaymentFormationView />,
		layout: ClaraLayout,
		propsLayout: {
			startupMenu: false,
			userMenu: true,
			fullMenu: false,
			showAssistance: false,
			cantBack: false,
		},
		action: ACTIONS.VIEW_MATTER,
	},
  {
		path: PAGES.MODULES.CHECKOUT.formationPaymentADGM.path,
		exact: true,
		component: <PaymentFormationADGMView />,
		layout: ClaraLayout,
		propsLayout: {
			startupMenu: false,
			userMenu: true,
			fullMenu: false,
			showAssistance: false,
			cantBack: false,
		},
		action: ACTIONS.VIEW_MATTER,
	},
  {
		path: PAGES.MODULES.CHECKOUT.formationPaymentKY.path,
		exact: true,
		component: <PaymentFormationKYView />,
		layout: ClaraLayout,
		propsLayout: {
			startupMenu: false,
			userMenu: true,
			fullMenu: false,
			showAssistance: false,
			cantBack: false,
		},
		action: ACTIONS.VIEW_MATTER,
	},
  {
		path: PAGES.MODULES.CHECKOUT.formationPaymentDelaware.path,
		exact: true,
		component: <PaymentFormationDelawareView />,
		layout: ClaraLayout,
		propsLayout: {
			startupMenu: false,
			userMenu: true,
			fullMenu: false,
			showAssistance: false,
			cantBack: false,
		},
		action: ACTIONS.VIEW_MATTER,
	},
];

export default routes;

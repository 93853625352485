import React,{useEffect, useState} from "react";
// import classnames from "classnames";
import { useFormContext } from 'react-hook-form';
import _ from 'lodash';
import useMachine from '../../../../../generic/context/MachineContext/useMachine';
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import Button from '../../../../../generic/components/Buttons/Button';
import useTranslate from '../../../../../generic/hooks/useTranslate';
import Typography from "../../../../../../modules/generic/components/Texts/Typography";

const EditCardSuccess = () => {
  const { context, next } = useMachine();
  const {translate} = useTranslate();
  const handleCancel = () => {
    next();
  }

  return (
    <React.Fragment>

      <TemplateWizard 
        props={{
          title:translate('MODULES.CHECKOUT.MODALS.CHANGE_CARD.STEP_SUCCESS.TITLE'), 
          subTitle:translate('MODULES.CHECKOUT.MODALS.CHANGE_CARD.STEP_SUCCESS.SUB_TITLE')}}
        buttons={
          {cancel:  
            <Button variant="primary" onClick={() => handleCancel()}>
              {translate('GENERIC_BUTTON_CLOSE')}
            </Button>
          }}
        >
        <Typography variant={"h5"}>
           { translate('MODULES.CHECKOUT.MODALS.CHANGE_CARD.STEP_SUCCESS.MESSAGE')}
        </Typography>
      </TemplateWizard>
    </React.Fragment>

  );
};

export default EditCardSuccess;
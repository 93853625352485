import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import IntroSubmitFlow from "../../../../../components/modules/incorporation/InformationFlow/InformationFlow"
import image from '../../../../../images/introFlowImg.svg';
import PAGES from "../../../../../constants/pages";
import PendingDraft from "../../../../../components/modules/incorporation/PendingDraft/PendingDraft"
import { Constants } from '../../../../../v1/utils/constants';
import Loading from "../../../../../components/generic/Loading/Loading";
//TODO:  V1
import useHasDraftPending from '../../../../../hooks/common/useHasDraftPending';
import useDocumentsForApproval from '../../../../../v1/Hooks/useDocumentsForApproval';
import classes from '../../../../../components/modules/incorporation/InformationFlow/InformationFlow.module.scss';
import Text from '../../../../../components/text/Text/Text';

const IncorporationDocumentsIntroView = (props) => {
    const dispatch = useDispatch();
    const params = useParams();
    const { isPending: isDraftPending, loading } = useHasDraftPending("ADGMFormationDocumentReview", params.matterId, "MATTER_KIND");
    const [showPending, setShowPending] = useState(false);

    const handleClosePending = (restart) => {
        if (restart) {
            setShowPending(false)
        } else {
            dispatch({ type: 'GO_TO', page: Constants.PAGES.ADGMFormationDocumentReview, params: { ...params } })
        }
    };

    useEffect(() => {
        setShowPending(isDraftPending);
    }, [isDraftPending]);

    return (
        <React.Fragment>
            <Loading showChildren={false} loading={loading}>
                {showPending && (<PendingDraft operationButton={"Incorporation"} title={"Incorporation"} handleClose={handleClosePending} />)}
                {!showPending && (
                    <IntroSubmitFlow
						componentLeft={DocumentList}
                        isIntro={true}
                        image={image}
                        buttonText={"INCORPORATION_VIEW_PROFILES_BUTTON"}
                        secondButtonText={"BUTTON_REMIND_LATER"}
                        handleNext={() => dispatch({ type: 'GO_TO', page: PAGES.ADGMFormationDocumentReview, params: { matterId: params.matterId } })}
                        handleClose={() => dispatch({ type: 'GO_TO', page: PAGES.switchStartup })}
                    />
                )}
            </Loading>
        </React.Fragment >
    )
};

const DocumentList = () => {
	const params = useParams();
	const { data: documents } = useDocumentsForApproval({ variables:{matterId: params.matterId} });

	return (
		<>
			<div className={classes.pretitleContainer}>
				<Text uuid={'INCORPORATION_FORM_TITLE'} />
			</div>
			<div className={classes.titleContainer}>
				<Text uuid={'INCORPORATION_FIRST_TITLE'} />
			</div>

			<ul className={classes.subTitleContainerList}>
				{documents.map(({type: { label }}, index) => {
					return (
						<li key={index + label} className={classes.subTitleContainer}>
							{label}
						</li>
					)
				})}
			</ul>
		</>
	)
};

export default IncorporationDocumentsIntroView;

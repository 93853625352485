import React, { FC } from "react";
import Skeleton from '@material-ui/lab/Skeleton';
import Scrollbar from '../../../../../components/generic/Scrollbar/Scrollbar';
import classesModule from './classes.module.scss';
import Typography from '../../../components/Texts/Typography';
import SkeletonInput from "../../../../../modules/generic/components/Skeletons/Overlays/SkeletonInput";
import SkeletonArea from "../../../../../modules/generic/components/Skeletons/Overlays/SkeletonArea";
import SkeletonCardButton from "../../../../../modules/generic/components/Skeletons/Overlays/SkeletonCardButton";

/**
 * Template Wizard Skeleton
 */

export interface TemplateWizardSkeletonProps {
  skeleton: React.ReactNode
  subTitle: string,
  title: string,
} 

const TemplateWizardSkeleton: FC<TemplateWizardSkeletonProps> = ({
  skeleton,
  subTitle,
  title,
  ...props
}:TemplateWizardSkeletonProps) => {

  return (
    <div className={`${classesModule.showSkeleton}`}>
      <Scrollbar className={`${classesModule.Scrollbar}`}>
        <div className={`${classesModule.Content}`}>
          {(subTitle || title) && (
            <div className={`${classesModule.Header}`}>
              {subTitle && (
                <Typography component={'label'} variant={'body'}>
                  <Skeleton variant="text" height={"1.3846rem"} width={"30%"}/>
                </Typography>
              )}
              {title && (
                <Typography
                  component={'h1'}
                  color={'black'}
                  className={`${classesModule.Spacer}`}
                  >
                    <Skeleton variant="text" height={"2.9231rem"} width={"100%"}/>
                </Typography>
              )}
            </div>
          )}
          <div className={`${classesModule.Form}`}>
            {skeleton ? skeleton: (
              <>
                <SkeletonCardButton></SkeletonCardButton>
                <SkeletonInput></SkeletonInput>
                <SkeletonArea></SkeletonArea>
              </>
            )}
          </div>
        </div>
      </Scrollbar>
      <div className={`${classesModule.Botonera}`}>
        <div className={classesModule.LeftButtons}></div>
        <div className={classesModule.RightButtons}>
          <Skeleton variant="rect" height={"3.0769rem"} width={"9.2308rem"}/>
          <Skeleton variant="rect" height={"3.0769rem"} width={"9.2308rem"}/>
        </div>
      </div>
    </div>
  );
};

export default TemplateWizardSkeleton;

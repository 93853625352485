import _ from "lodash";
import { useMemo } from "react";
import { assign } from 'xstate';
import useNavigation from "src/modules/generic/hooks/useNavigation";
import { Constants } from "src/v1/utils/constants";

const useActions = () => {
  const { goTo } = useNavigation()
  return useMemo(() => ({
    redirectToGenerateDocument: assign((context: object, event) => {
      goTo(Constants.PAGES.chooseDocumentType, {
        previewDocument: "FOUNDER_SERVICES_AGREEMENT",
      })
      return context;
    }),
    setContextAfterFirstInvoke: assign((context: object, event) => {
      const values = _.get(event, 'data.data.getGroupCompaniesForAccelerators', []);
      return {
        ...context,
        allCompanies: values,
      };
    }),
    setCompanyTopco: assign((context: object, event) => {
      const filterTopco = _.filter(_.get(context, "allCompanies", {}), (item) => {
        return item.isHoldingCompany;
      });
      return {
        ...context,
        optionTopCo: filterTopco,
      };
    }),
    setStep1Result: assign((context: object, event) => {
      const resultOption = _.get(event, 'payload.values.confirm', "");
      return {
        ...context,
        resultChoice: resultOption,
      };
    }),
    setStep1ConfirmResult: assign((context: object, event) => {
      const resultOption = _.get(event, 'payload.values.confirm', "");
      const resultCompanyId = _.get(event, 'payload.values.companyId', "");
      return {
        ...context,
        resultChoice: resultOption,
        resultCompanyId: resultCompanyId,
      };
    }),
    setStep4ConfirmCompanyData: assign((context: object, event) => {
      const resultCompanyId = _.get(event, 'payload.values.companyData', "");
      return {
        ...context,
        resultCompanyId: resultCompanyId,
      };
    }),
  }), []);
}

export default useActions;
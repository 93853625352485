import React from 'react';
import { Modal } from '../../../../../../../v1/components';
import { Constants } from "../../../../../../../v1/utils";
import DeleteSIP from "./DeleteSIP";

const DeleteSIPContent = ({ open, handleCloseModal, data, onComplete, goTo, redirect, ...props }) => {

    return (
        <Modal
            open={open} 
            overlayName={Constants.FORM_TYPES.GROUP_COMPANY.COMPANY_CONNECTED_GRANTS} handleClose={() => handleCloseModal()} showHeader={false}>
            <DeleteSIP  handleCloseModal={handleCloseModal} initialValues={data} onComplete={onComplete} goTo={goTo} redirect={redirect}/>
        </Modal>
    )
}

export default DeleteSIPContent;

import { createMachine } from 'xstate';

const machine =
  createMachine({
    id: 'ADGMSPVEligibility',
    initial: 'ADGMSPVEligibility',
    states: {
      ADGMSPVEligibility: {
        on: {
          PREV: {
            target: 'previousMachine',
          },
          NEXT: {
            target: 'EvaluateSPVEligibility',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      EvaluateSPVEligibility: {
        always: [
          {
            cond: 'formSPV',
            target: 'FormSPVInADGM'
          },
          {
            cond: 'formADGMAuthorisedSignatory',
            target: 'ADGMSPVAuthorisedSignatory'
          },
          {
            cond: 'formInCayman',
            target: 'CaymanIsBetter'
          },
        ]
      },
      FormSPVInADGM: {
        on: {
          PREV: {
            target: 'ADGMSPVEligibility',
            actions: ['setContextAfterStep']
          },
          NEXT: {
            target: 'EvaluateADGMConcierge',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      EvaluateADGMConcierge: {
        always: [
          {
            cond: 'formWithConcierge',
            target: 'BetterFitADGMTL'
          },
          {
            cond: 'formWithADGM',
            target: 'AddADGMCompany',
          },
        ]
      },
      BetterFitADGMTL: {
        on: {
          PREV: {
            target: 'FormSPVInADGM',
          },
          NEXT: {
            target: 'EvaluateBetterFitADGMTL',
            actions: ['setContextAfterStep']
          },
          CANCEL:{
            target: 'cancel'
          }
        }
      },
      EvaluateBetterFitADGMTL: {
        always: [
          {
            cond: 'formWithADGMTechLicence',
            target: 'adgmTechStartup'
          },
          {
            cond: 'changeLocation',
            target: 'chooseJurisdiction',
          },
        ]
      },
      AddADGMCompany: {
        on: {
          PREV: {
            target: 'FormSPVInADGM',
            actions: ['setContextAfterStep']
          },
          NEXT: {
            target: 'SaveStep_ADGM',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      CaymanIsBetter: {
        on: {
          PREV: {
            target: 'ADGMSPVEligibility',
            actions: ['setContextAfterStep']
          },
          NEXT: {
            target: 'Evaluate_CaymanIsBetter',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      Evaluate_CaymanIsBetter: {
        always: [
          {
            cond: 'isChooseJurisdiction',
            target: 'chooseJurisdiction'
          },
          {
            cond: 'isCayman',
            target: 'caymanStep',
          },
        ]
      },
      ADGMSPVAuthorisedSignatory: {
        on: {
          PREV: {
            target: 'ADGMSPVEligibility',
            actions: ['setContextAfterStep']
          },
          NEXT: {
            target: 'FormSPVInADGM',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      SaveStep_ADGM: {
        invoke: {
          src: 'SaveStep_ADGM',
          id: 'SaveStep_ADGM',
          onDone: [
            {
              target: 'FormInADGMSuccess',
            }
          ],
          onError: [
            {
              target: 'AddADGMCompany'
            }
          ]
        }
      },
      FormInADGMSuccess: {
        on: {
          NEXT: {
            target: 'finish'
          },
        }
      },
      caymanStep:{
        entry: 'caymanStep',
        type: 'final'
      },
      chooseJurisdiction: {
        entry: 'chooseJurisdiction',
        type: 'final'
      },
      adgmTechStartup: {
        entry: 'adgmTechStartup',
        type: 'final'
      },
      previousMachine: {
        entry: 'previousMachine',
        type: 'final'
      },
      cancel: {
        entry: 'cancelMachine',
        type: 'final'
      },
      finish: {
        entry: 'stopMachine',
        type: 'final'
      }
    }
  });

export default machine;

import React, { FC, useRef, useState } from 'react';
import * as Yup from 'yup';
import Form from "../../../../components/forms/Form/FormL";
import SubmitButton from "../../../generic/components/Buttons/SubmitButton";
import { CheckboxButtonOptions } from "../../../generic/components/Inputs/CheckboxButton";
import CheckboxButtonSimple from "../../../generic/components/Inputs/CheckboxButtonSimple";
import ControllerInput from "../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import Typography from "../../../generic/components/Texts/Typography";
import useTranslate from "../../../generic/hooks/useTranslate";
import TemplateFormDefault from "../../../generic/templates/Modal";
import cls from "./Form.module.scss";

interface IsAllShareholdersAndDirectorsConfirmedProps {
  addNoText?: boolean
  initialValues?: any
  onCompleteSubmit?: (values: any) => void
  Template?: any
  buttons?: any
  buttonSubmitProps?: any
  propsTemplate?: any
}

const schema = Yup.object().shape({
  isAllShareholdersAndDirectorsConfirmed: Yup.boolean().nullable().required('Required'),
});

const IsAllShareholdersAndDirectorsConfirmed: FC<IsAllShareholdersAndDirectorsConfirmedProps> = ({ 
  initialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
  addNoText = false
}: IsAllShareholdersAndDirectorsConfirmedProps) => {
  const { translate } = useTranslate();
  const [isAllShareholdersAndDirectorsConfirmed, setIsAllShareholdersAndDirectorsConfirmed] = useState<boolean>(true);

  const options: Array<CheckboxButtonOptions<boolean, string>> = [
    {
      value: false,
      label: translate('MODULES.GROUP_COMPANY.FORM.CONFIRM_DIRECTORS_SHAREHOLDERS.NO')
    },
    {
      value: true,
      label: translate('MODULES.GROUP_COMPANY.FORM.CONFIRM_DIRECTORS_SHAREHOLDERS.YES')
    }
  ];

  const refForm = useRef();

  return (
    <>
      <Form
        initialValues={initialValues || {}}
        schema={schema}
        onlySendDirty={false}
        onSubmit={onCompleteSubmit}
        ref={refForm} optionsForm={{ mode: "onChange" }}
      >
        <Template
          buttons={{ ...buttons, submit: <SubmitButton {...buttonSubmitProps}>Next</SubmitButton> }}
          props={propsTemplate}
        >
          <ControllerInput
            as={CheckboxButtonSimple}
            name='isAllShareholdersAndDirectorsConfirmed'
            options={options}
            onChange={(value: boolean) => {
              setIsAllShareholdersAndDirectorsConfirmed(value)
            }}
            className={cls.CheckboxButton}
            classNameButton={cls.buttonCheckBox}
          />
          {!isAllShareholdersAndDirectorsConfirmed && !addNoText &&
            <Typography component={'p'} variant={'body'}>
              {translate('MODULES.GROUP_COMPANY.FORM.CONFIRM_DIRECTORS_SHAREHOLDERS.NO_ADDED_ALL_DIRECTORS_SHAREHOLDERS')}
            </Typography>
          }
        </Template>
      </Form>
    </>
  );
}

export default IsAllShareholdersAndDirectorsConfirmed;
import React, {Component} from "react";
import {connect} from "react-redux";
import {goToPage, goToPrevPage} from '../../../../../store/actions/page'
import {Constants} from '../../../../utils/constants'
import classes from "../ViewForms.module.scss";
import {RoleAccess} from '../../../index';
import _ from "lodash";
import helper from "../../../../utils/helper";
import {sendKYCEmail, sendScopingEmail} from '../../../../graphql/mutations/matters';
import notification from "../../../../utils/notification";
import Mustache from 'mustache';
import ServerConnect from "../../../../utils/ServerConnect";
import ModalMenu from '../../../Modal/ModalMenu/ModalMenu';


class Scoping extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false
        };
    }

    changeMode = () => {
        this.props.goToPage(Constants.PAGES.editMatter, { id: this.props.data.id, tab: 'scoping' });
    };

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            this.setState({ shouldUpdate: true })
        }
    }



    sendScopingEmail = () => {
        return new Promise((resolve, reject) => {
            const query_params = {
                matterId: this.props.data.id
            };
            const queryExecute = Mustache.render(sendScopingEmail, query_params);

            ServerConnect.graphQlQuery(queryExecute).then(result => {
                resolve(result);
            });
        })
    };




    sendKYCMail = (params) => {
        const variables = {
            matterId: this.props.data.id,
            KYCType: params.type,
            whoIsGoingToDo: params.who,
        }

        let text = params.type == 'individual' ? 'Individual ' : 'Company ';
        ServerConnect.graphQlMutation(sendKYCEmail, variables)
            .then(result => {
                if (params.shouldGoToFlow) {
                    this.props.goToPage(Constants.PAGES.addKYCToAMatter, { matterId: this.props.data.id });
                } else {
                    notification.sendNotification(text + "KYC has been sent to " + this.props.data.clientFullName, "success", 4990);
                }
            }).catch(err => {
                console.log(err);
                notification.sendNotification("ERROR", "error", 4990);
            });
    }
    sendScopingEmailClickHandler = () => {

        Promise.resolve(this.sendScopingEmail())
            .then((result) => {
                notification.sendNotification("Scoping has been sent to " + this.props.data.clientFullName, "success", 4990);
            }).catch(err => {
                notification.sendNotification(err, "error", 4990);
            });
    }


    renderEngagementValue = (value) => {
        if (value !== (null || '') && value == 'corporateEntity') {
            return 'Corporate Entity'
        } else if (value !== (null || '') && value == 'individual') {
            return 'Individual(s)'
        }
    }

    renderShareAgreementValue = (value) => {
        if (value !== (null || '') && value == 'inPlace') {
            return 'In Place'
        } else if (value !== (null || '') && value == 'beingPrepared') {
            return 'Being Prepared'
        }
    }
    renderYesOrNo = (value) => {
        if (value == true) {
            return 'Yes'
        } else if (value == false) {
            return 'No'
        }
    }

    openModal = () => {

    }

    getFieldsByType = (data) => {

        switch (_.get(data.scoping, 'companyType', '')) {
            case Constants.COMPANY_TYPES.HOLDING_COMPANY:
                return (
                    <React.Fragment>
                        <hr className={classes.Separator} />
                        <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                            <label>{helper.getTranslateTextByKey("HOLDING_COMPANY")}</label>
                        </div>
                        {_.get(data.scoping, 'alreadyIncorporatedText', null) !== null ? <div className={`${classes.ContainerRow}`}>
                            <label>The company will hold shares in a company already incorporated. Details:</label>
                            <span>{_.get(data.scoping, 'alreadyIncorporatedText', '')}</span>
                        </div> : null}
                        {_.get(data.scoping, 'toBeIncorporatedText', null) !== null ? <div className={`${classes.ContainerRow}`}>
                            <label>The company will hold shares in a company to be incorporated. Details:</label>
                            <span>{_.get(data.scoping, 'toBeIncorporatedText', '')}</span>
                        </div> : null}
                        {_.get(data.scoping, 'intellectualPropertyText', null) !== null ? <div className={`${classes.ContainerRow}`}>
                            <label>The company will hold intellectual property. Details:</label>
                            <span>{_.get(data.scoping, 'intellectualPropertyText', '')}</span>
                        </div> : null}
                        {_.get(data.scoping, 'isRevenueGenerating', null) ? <div className={`${classes.ContainerRow} ${classes.bulletText}`}>
                            <label>          </label>
                            <span>• Revenue Generation</span>
                        </div> : null}
                        {_.get(data.scoping, 'isProtection', null) ? <div className={`${classes.ContainerRow} ${classes.bulletText}`}>
                            <label>         </label>
                            <span>• Protection</span>
                        </div> : null}
                        {_.get(data.scoping, 'otherAssetText', null) !== null ? <div className={`${classes.ContainerRow}`}>
                            <label>Other:</label>
                            <span>{_.get(data.scoping, 'otherAssetText', '')}</span>
                        </div> : null}
                        <div className={`${classes.ContainerRow}`}>
                            <label>Will this holding company only receive revenue from dividends or through capital gains?</label>
                            <span>{this.renderYesOrNo(_.get(data.scoping, 'onlyRevenueDividendsOrCapital', ''))}</span>
                        </div>
                        {_.get(data.scoping, 'onlyRevenueDividendsOrCapital', '') ? null :
                            <div className={`${classes.ContainerRow} ${classes.ContainerRowMultiline}`}>
                                <label>What other forms of income will this holding company receive? </label>
                                <span>{_.get(data.scoping, 'anotherIncomeForm', '')}</span>
                            </div>}
                    </React.Fragment>
                );
            case Constants.COMPANY_TYPES.FOUNDATION:
                return (
                    <React.Fragment>
                        <hr className={classes.Separator} />
                        <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                            <label>{helper.getTranslateTextByKey("FOUNDATION")}</label>
                        </div>
                        {_.get(data.scoping, 'foundationReason', null) !== null ? <div className={`${classes.ContainerRow}`}>
                            <span>{_.get(data.scoping, 'foundationReason', '')}</span>
                        </div> : null}
                        {_.get(data.scoping, 'foundationAssetsAndWhereAreLocated', null) !== null ? <div className={`${classes.ContainerRow}`}>
                            <span>{_.get(data.scoping, 'foundationAssetsAndWhereAreLocated', '')}</span>
                        </div> : null}
                        {_.get(data.scoping, 'whereFoundersAreLiving', null) !== null ? <div className={`${classes.ContainerRow}`}>
                            <span>{_.get(data.scoping, 'whereFoundersAreLiving', '')}</span>
                        </div> : null}
                    </React.Fragment>
                );
            case Constants.COMPANY_TYPES.TECH_STARTUP:
                return (
                    <React.Fragment>
                        <hr className={classes.Separator} />
                        <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                            <label>{helper.getTranslateTextByKey("TECH_STARTUP")}</label>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <span>{_.get(data.scoping, 'relevantTechnologyDescription', '')}</span>
                        </div>
                    </React.Fragment>
                );
            case Constants.COMPANY_TYPES.OPERATING_COMPANY:
                return (
                    <React.Fragment>
                        <hr className={classes.Separator} />
                        <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                            <label>{helper.getTranslateTextByKey("OPERATING_COMPANY")}</label>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <span>{_.get(data.scoping, 'companyActivitiesDescription', '')}</span>
                        </div>
                    </React.Fragment>
                );
            case Constants.COMPANY_TYPES.OTHER:
                return (
                    <React.Fragment>
                        <hr className={classes.Separator} />
                        <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                            <label>{helper.getTranslateTextByKey("OTHER")}</label>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <span>{_.get(data.scoping, 'detailsOtherIncorporation', '')}</span>
                        </div>
                    </React.Fragment>
                );
            case '':
                return null
            default:
                return null
        }
    }

    handleProposal = () => {
        return this.props.goToPage(Constants.PAGES.proposalsAndEngagements, {
            matterId: this.props.data.id,
            tab: "scoping",
            typeForm: "PROPOSAL",
            mode: this.props.data.scoping.engagement ? "EDIT" : "ADD"
        })
    };



    render() {
        let { data, user } = this.props;
        let links = _.get(data.scoping, 'engagementType', null) == 'corporateEntity' ? [
            {
                className: classes.agentKYC,
                label: 'Send Company KYC Email',
                params: { type: 'corporateEntity', who: 'client' },
                onClick: this.sendKYCMail,
                separator: true,
            }, {
                className: classes.agentKYC,
                label: 'Agent Company KYC',
                params: { type: 'corporateEntity', who: 'serviceAgent', shouldGoToFlow: true },
                onClick: this.sendKYCMail,
                separator: true,
            }
        ] : [{
            className: classes.agentKYC,
            label: 'Send Individual KYC Email',
            params: { type: 'individual', who: 'client' },
            onClick: this.sendKYCMail,
            separator: true,
        },
        {
            className: classes.agentKYC,
            label: 'Agent Individual KYC',
            params: { type: 'individual', who: 'serviceAgent', shouldGoToFlow: true },
            onClick: this.sendKYCMail,
            separator: true,
        }]

        if (!data) {
            return null;
        }
        return (
            <div className={classes.ContainerView} >
                {/* BOTON */}
                < RoleAccess action={Constants.ACTIONS.MANAGE_MATTER} >
                    <button className={classes.buttonEditFloat} onClick={(e) => { this.changeMode() }}>
                        <span>Edit Details</span>
                    </button>
                </RoleAccess>

                <div className={classes.ContainerData}>
                    {user.userRole === Constants.PROFILE_ROLES.SERVICE_AGENT || user.userRole === Constants.PROFILE_ROLES.SERVICE_AGENT_MANAGER || user.userRole === Constants.PROFILE_ROLES.SENIOR_SERVICE_AGENT ? <React.Fragment> <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                        <label>Actions</label>
                    </div>
                        <div className={`${classes.ContainerRow}`}>
                            <div className={classes.botoneraActions}>
                                <button
									disabled={!!(_.get(data, 'scoping.companyType', null) || _.get(this, 'props.data.state') === 'Closed')}
									onClick={this.sendScopingEmailClickHandler}
								>
									Send Scoping Email
                                </button>
                                <button
									disabled={_.get(this.props, 'data.scoping.proposal.status', null) === 'APPROVED' || _.get(this.props,'data.state') === 'Closed'}
									onClick={this.handleProposal}
								>
									{_.get(this.props, 'data.scoping.engagement.status') ? "Resend" : "Create"} Proposal
                                </button>
                                <ModalMenu
									links={links}
									scoping={true}>
									<button
										disabled={_.get(this.props, 'data.scoping.engagement.status', null) !== 'APPROVED' || _.get(this.props,'data.state') === 'Closed'}
										onClick={() => this.openModal}
										className={classes.buttonModalOnActions}>Start KYC
									</button>
                                </ModalMenu>

                            </div>
                        </div><hr className={classes.Separator} /></React.Fragment> : null}
                    <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                        <label>Company details</label>
                    </div>
                    <div className={`${classes.ContainerRow}`}>
                        <label>Incorporation jurisdiction</label>
                        <span>{helper.getTranslateTextByKey(_.findKey(Constants.INCORPORATIONS, key => { return key == _.get(data.scoping, 'incorporationJurisdiction', '') }))}</span>
                    </div>
                    <div className={`${classes.ContainerRow}`}>
                        <label>Type of company</label>
                        <span>{helper.getTranslateTextByKey(_.findKey(Constants.COMPANY_TYPES, key => { return key == _.get(data.scoping, 'companyType', '') }))}</span>
                    </div>
                    {this.getFieldsByType(data)}
                    <hr className={classes.Separator} />
                    <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                        <label>Shareholders / Directors</label>
                    </div>
                    <div className={`${classes.ContainerRow}`}>
                        <label>Number of shareholders</label>
                        <span>{_.get(data.scoping, 'shareNumbers', '')}</span>
                    </div>
                    <div className={`${classes.ContainerRow}`}>
                        <label>Are the majority of directors UAE residents?</label>
                        <span>{this.renderYesOrNo(_.get(data.scoping, 'boardMembersMajorityResident', ''))}</span>
                    </div>
                    {_.get(data.scoping, 'boardMembersMajorityResident', '') == false ? <div className={`${classes.ContainerRow} ${classes.ContainerRowMultiline}`}>
                        <label>Where will the majority of the directors reside?</label>
                        <span>{_.get(data.scoping, 'whereTheMajorityBoardMembersReside', '')}</span>
                    </div> : null}
                    <hr className={classes.Separator} />
                    <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                        <label>Banking services</label>
                    </div>
                    <div className={`${classes.ContainerRow}`}>
                        <label>Do you require a bank account?</label>
                        <span>{this.renderYesOrNo(_.get(data.scoping, 'bankAccountRequire', ''))}</span>
                    </div>
                    <hr className={classes.Separator} />
                    <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                        <span>Engagement</span>
                    </div>
                    <div className={`${classes.ContainerRow}`}>
                        <label>Engagement type</label>
                        <span>{this.renderEngagementValue(_.get(data.scoping, 'engagementType', ''))}</span>
                    </div>


                </div>
            </div >
        )
    }
}

const mapStateToProps = state => ({
    session: state.session,

});
const mapDispatchToProps = (dispatch) => ({
    goToPrevPage: () => {
        goToPrevPage(dispatch)
    },
    goToPage: (page, params) => {
        goToPage(dispatch, page, params)
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Scoping)

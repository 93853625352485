

import React from 'react'
import * as Yup from 'yup'
import Form from '../../../../components/forms/Form/Form'
import useTranslate from '../../../generic/hooks/useTranslate'
import ControllerInput from '../../../generic/components/Inputs/ReactHookForm/ControllerInput'
import SubmitButton from '../../../generic/components/Buttons/SubmitButton'
import FormProps from '../../../generic/forms/interfaces/FormProps';
import TemplateFormDefault from "../../../generic/templates/Modal";
import cls from './Form.module.scss';
import useGetGroupCompanies from '../../graphql/groupCompany/query/useGetGroupCompanies'
import { useDispatch } from 'react-redux'
import CheckboxButtonSimple from '../../../generic/components/Inputs/CheckboxButtonSimple'
import useSession from '../../../session/hooks/useSession'
import useToggle from '../../../generic/hooks/useToggle'
import { addAssistantText, removeAssistantText } from '../../../generic/store/action'

const schema = Yup.object().shape({
  mode: Yup.string().required('This field is required').nullable()
})

const FormSelectCompany: React.FC<FormProps> = ({
  initialValues: defaultValues = { company: [] },
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate
}) => {

  const { translate } = useTranslate();
  const [isYesUpload, toggleUpload] = useToggle(false);
  const dispatch = useDispatch()
  const { startupId } = useSession();
  const { data } = useGetGroupCompanies({ variables: { startupId } })

  const handleSubmit = async values => {
    onCompleteSubmit && await onCompleteSubmit(values);
  }

  const options = React.useMemo(() => {
    return [
      { label: "Yes, upload now", value: 'YES_UPLOAD' },
      { label: "Upload it later", value: 'UPLOAD_LATER' },
      { label: "Already uploaded", value: 'SELECT_DOCUMENT' }]
  }, [])

  return (
    <Form
      defaultValues={defaultValues}
      schema={schema}
      onSubmit={handleSubmit}
      onlySendDirty={false}
      optionsForm={{ mode: "onChange" }}
    >
      <Template
        buttons={{ ...buttons, submit: <SubmitButton  {...buttonSubmitProps}>Next</SubmitButton> }} props={propsTemplate}>
        <ControllerInput
          render={CheckboxButtonSimple}
          options={options}
          name='mode'
          defaultlabel={''}
          className={cls.CheckboxButton}
          classNameButton={cls.buttonCheckBox}
          onChange={(value) => {
            if (value === "YES_UPLOAD" && !isYesUpload) {
              dispatch(addAssistantText(translate('MODULES.STAKEHOLDER.SHARE_OPTIONS.FOURTH_STEP.TOOLTIP_IS_YES_UPLOAD'), 'text'))
              toggleUpload();
            }
            if (isYesUpload) {
              dispatch(removeAssistantText());
              toggleUpload();
            }
          }}
        />
      </Template>
    </Form>
  )
}

export default FormSelectCompany

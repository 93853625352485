import React, { FC } from "react";
import Icon from "../../../../../../modules/generic/components/Icon";
import classes from "./styles.module.scss";

type Props = Omit<React.HTMLAttributes<HTMLButtonElement>, 'children'>

export const LeftArrow: FC<Props> = ({ ...props }) => {
  console.log(props);
  return (
    <button className={classes.ArrowContainer} {...props}>
      <Icon icon={"Arrow-Left"} className={classes.Arrow} isClara={true} size={'0.75rem'} />
    </button>);
}

export const RightArrow: FC<Props> = ({ ...props }) => {
  return (
    <button className={classes.ArrowContainer} {...props} >
      <Icon icon={"Arrow-Right"} className={classes.Arrow} color='blue' isClara={true} size={'0.75rem'} />
    </button>);
}

interface ButtonScroll extends Props {
  containerRef: React.RefObject<HTMLDivElement>;
  handleHideRightArrow: any;
  handleHideLeftArrow: any;
  handleShowLeftArrow: any;
  handleShowRightArrow: any;
  countScroll: number;
}

export const LeftArrowScroll: FC<ButtonScroll> = ({ containerRef, className, handleShowRightArrow, countScroll, handleHideLeftArrow, ...props }) => {

  const handleScrollPrev = () => {
    containerRef.current.scrollLeft = containerRef.current.scrollLeft - 200
    if (containerRef.current.scrollLeft !== countScroll + 57) {
      handleShowRightArrow();
    }
    if (containerRef.current.scrollLeft === 0) {
      handleHideLeftArrow();
    }
  }

  return (
    <button className={`${classes.ArrowContainer} ${className}`} {...props} onClick={handleScrollPrev}>
      <Icon icon={"Arrow-Left"} className={classes.Arrow} isClara={true} size={'1rem'} />
    </button>
  );
}

export const RightArrowScrollNext: FC<ButtonScroll> = ({ containerRef, className, handleShowLeftArrow, countScroll, handleHideRightArrow, ...props }) => {

  const handleScrollNext = () => {
    containerRef.current.scrollLeft = containerRef.current.scrollLeft + 200;
    if (containerRef.current.scrollLeft === countScroll + 57) {
      handleHideRightArrow();
    }
    if (countScroll + 57) {
      handleShowLeftArrow();
    }
  }

  return (
    <button className={`${classes.ArrowContainer} ${className}`} {...props} onClick={handleScrollNext} >
      <Icon icon={"Arrow-Right"} className={classes.Arrow} color='blue' isClara={true} size={'0.75rem'} />
    </button>
  );
}
import React, { Component } from 'react';
import { Field, Form } from "formik";
import _ from "lodash";
import moment from "moment";
import classes from "../Styles.module.scss";
import ClaraSelect from "../../../../../../../v1/components/Form/ClaraSelect/ClaraSelect";
import { TextAreaInput, TextInput } from '../../../../../../../v1/components';
import Checkbox from "../../../../../../../v1/containers/Forms/Inputs/Checkbox";
import FormikHelper from "../../../../../../../v1/utils/formikHelper";
import DinamicInput from "../../../../../../../v1/containers/Forms/Inputs/DinamicInput";
import ClaraUpload from "../../../../../../../v1/components/Form/ClaraUpload/ClaraUpload";
import ClaraCountrySelector from "../../../../../../../v1/components/Form/ClaraCountrySelector/ClaraCountrySelector";
import AuthorisedSignatories from "../../../../../../inputs/AuthorizedSignatories/AuthorizedSignatories";
import SelectStateCity from 'src/components/inputs/Select/SelectStateCity'
import Line from "../../../../../../generic/Line/Line";

// USA Non disclosure agreement: Edit

class UsaNDADoc extends Component {

    constructor(props) {
        super(props);

        this.state = {
            typeSelected: undefined,
            assignorSelected: undefined,
            groupCompanySelected: undefined,
            authorizedSelected: undefined,
            authorizedSignatories: [],
            authorizedSignatoriesList: [],
            documentTypes: [],
            documentTypesList: [],
            dynamicParams: [],
            date: undefined,
            expirationDate: undefined,
            firstSetCity:true,
            stateValue:null
        }
    };



    componentWillMount() {
        if (this.props.values) {
            const values = this.props.values.values ? this.props.values.values : this.props.values;
            const expirationDate = this.getDateForPickerFromData(values.expirationDate);
            this.setState({
                typeSelected: values.documentType,
                assignorSelected: values.assignor,
                groupCompanySelected: values.company,
                expirationDate,
                authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.company).map(as => ({
                    id: as.id,
                    label: as.label
                }))
            });


        }

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.values) {
            const values = nextProps.values.values ? nextProps.values.values : nextProps.values;
            const expirationDate = this.getDateForPickerFromData(values.expirationDate);
            this.setState({
                expirationDate,
                authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.company)
                    .map(as => ({
                        id: as.id,
                        label: as.label
                    }))
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.values) {
            const values = prevProps.values.values ? prevProps.values.values : prevProps.values;
        }
    }

    selectType = (documentType, extraParams, form) => {
        this.setState({
            typeSelected: documentType,
            dynamicParams: this.getDynamicParamsByType(documentType)
        }, () => {
            FormikHelper.setValueInTheCorrectPosition('terms', form, []);
            FormikHelper.setValueInTheCorrectPosition('documentType', form, documentType);
            this.state.dynamicParams.forEach((dynamicParam, i) => {
                FormikHelper.setValueInTheCorrectPosition(
                    `terms[${i}].key`,
                    form,
                    dynamicParam.key || 'NO_KEY'
                );
            })
        });
    };

    getDynamicParamsByType(documentType) {
        const type = this.props.documentTypes.filter(agrType => agrType.code === documentType)[0] || {};
        return type.fields || [];
    }

    getGroupCompaniesToAssignee = () => {
        let authorizedSignatories = this.props.authorizedSignatories;
        let groupCompanies = this.props.groupCompanies;
        let companies = [];
        if (groupCompanies && authorizedSignatories) {
            companies = _.filter(groupCompanies, (groupCompany) => {
                let fined = _.filter(authorizedSignatories, (authorized) => {
                    if (groupCompany.authorizedSignatories) {
                        return groupCompany.authorizedSignatories.some(au => au.stakeholder.id === authorized.id);
                    }
                    return false;
                });
                if (fined) {
                    return true;
                }
                return false;
            });
        }
        return companies;
    };

    selectGroupCompany = (gcId, extraParams, form) => {
        
        const groupCompany = this.findGroupCompany(gcId);

        // this is for a weird behaviour when hit edit button after preview a document
        if (this.state.groupCompanySelected && groupCompany) {
            return null;
        }

        this.setState({
            groupCompanySelected: groupCompany,
            firstSetCity:true,
            stateValue:null
        });

        if (groupCompany) {

            if (!groupCompany.registeredAddress) {
                // _.set(groupCompany, "registerAddress", {})
                groupCompany.registeredAddress = {}

            }

            let authorizedSignatories = _.get(form, 'values.company.authorizedSignatories', []);
            // let authorizedAddress = _.get(form, 'initialValues.company.address', []);

			form.setValues({
				...form.values,
				company: {
					id: gcId,
                    address: _.get(form, 'values.company.address' ,'initialValues.company.address'),
					name: groupCompany.name || '',
					country: _.get(groupCompany, 'country.code', null) || _.get(groupCompany, 'country', null),
                    authorizedSignatories: authorizedSignatories,
                    placeOfBusiness:_.get(form, 'initialValues.company.placeOfBusiness',null),
                    // { 
                        // state: _.get(form, 'initialValues.company.placeOfBusiness.state', null),
                        // city: _.get(form, 'initialValues.company.placeOfBusiness.city', null),
					// }
                }
            });
            this.setState({
                groupCompanySelected: {...groupCompany},
            })
        } else {
            form.setValues({
                ...form.values,
                company: {}
            });
        }
    };

    findGroupCompany(gcId) {
        return this.props.groupCompaniesList.find(gc => gc.id === gcId);
    };

    selectStakeholder = (assignor, extraParams, form, cb) => {
        
        const stakeholder = this.findStakeholder(assignor);
        this.setState({
            assignorSelected: stakeholder,
        });

        if (stakeholder) {

            if (!stakeholder.address) {
                _.set(stakeholder,'address', {})
            }

            const _stakeholder = !_.isEmpty(form.values.stakeholder) ? form.values.stakeholder : stakeholder;
            form.setValues({
                ...form.values,
                
                stakeholder: {
                    ...stakeholder,
                    id: assignor,
                    fullName: stakeholder.fullName || '',
                    email: stakeholder.email || '',
                    nationality: _.get(stakeholder, 'nationality.code', null) || _.get(stakeholder, 'nationality', null),
                    title: _.get(form, 'values.stakeholder.title',null),
                    type: stakeholder.type || '',
                    address: {
                        ...stakeholder.address,
                        country:typeof  _.get(stakeholder, 'address.country', null) ==='object'? _.get(stakeholder, 'address.country.code', null) : _.get(stakeholder, 'address.country', null)

                        // country:typeof  _.get(stakeholder, 'address.country', null) ==='object'? _.get(_stakeholder, 'address.country.code', null) : _.get(_stakeholder, 'address.country', null)
                    },
                    isAnEntity: stakeholder.isAnEntity,
                    },
               
            });
        } else {
            form.setValues({
                ...form.values,
                stakeholder: {}
            });
        }
        
    };

    findStakeholder(roleId) {
        return this.props.stakeholderList.find(stakeholder => stakeholder.id === roleId)
    };

    findStakeholderAuthorized(roleId) {
        return this.props.stakeholderList.filter(stakeholder => {
            return stakeholder.roles.some(role => role.stakeholder.id === roleId);
        })[0] || null;
    };

    filterAuthorizedByGroupCompany(groupCompany) {
        if (groupCompany) {
            let gc = this.findGroupCompany(groupCompany.id);
            return this.props.authorizedSignatories.filter(authorized => {
                return authorized.groupCompanyId === gc.id
            });
        }
        return [];
    }



    handleDateChange = (e, field, form) => {
        const date = moment(e).format("YYYY-MM-DD");

        FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

        this.setState({
            date: e
        })
    };

    renderSpecialAgreementFields(form) {
        return this.state.dynamicParams.map((dynamicParam, i) => {
            return (
                <div className={classes.ContainerRow}>
                    <Field name={`terms[${i}].value`}>
                        {({ field, form }) => (
                            <DinamicInput
                                config={dynamicParam}
                                field={field}
                                form={form}
                                handleDateChange={this.handleDateChange}
                                date={this.state.date}
                            />
                        )}
                    </Field>
                </div>
            );
        });
    };


    handleCountryChange(value, form) {


        this.setState({
            countrySelected: value
        })
        
    }

    // updateEmailValue(e, form) {
    //     if (this.state.assignorSelected.isAnEntity) {
    //         form.setFieldValue('assignor.authorizedSignatory.email', e.target.value, false)
    //     }
    // }

    getDateForPickerFromData(date) {
        if (!date) return undefined;
        return moment(date).toDate();
    }

    // handleChange(e, field, form) {

    //     const date = e === null ? null : moment(e).format("YYYY-MM-DD");

    //     FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

    //     this.setState({
    //         [field.name]: e
    //     })
    // }

    getAuthorizedSignatoriesList = (index, form) => {
        if (form.values && form.values.assignee) {
            return this.filterAuthorizedByGroupCompany(form.values.assignee).map(as => ({
                id: as.id,
                label: as.label
            }))
        } else {
            return [];
        }
    };

    getStakeholderAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add new corporate stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
                placeholder: "Stakeholder's name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: true,
                },
                paramsReturn: 'isAnEntity'
            },
            {
                type: "stakeholder",
                label: "Add new individual stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
                placeholder: "Stakeholder's name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: false,
                },
                paramsReturn: 'isAnEntity'
            },
        ]
    };

    getCompanyAdds = () => {
        return [{
            type: "groupCompany",
            label: "Add new company",
            textWarning: "This will create a new group company for your startup. You can add or amend the details of this group company on its profile page.",
            placeholder: "Company name",
            startupId: this.props.startup.id,
            params: null,
            paramsReturn: `
				name
				authorizedSignatories {
					id
					stakeholder {
						id
						email
						fullName
						roles {   id
        name
        groupCompany { id name}
        stakeholder { id } }
					}
				}
			`
        }]
    };

    handleStakeholderCreate = (value, name, additionalValue, form) => {

        let { stakeholders, stakeholderList } = this.props;
        let newSh = { id: value.id, fullName: name, ...additionalValue };
        stakeholderList.push(newSh);

        stakeholders.push({ id: value.id, label: name });

    };

    handleGroupCompanyCreate = (value, name, additionalValue, form) => {

        let { groupCompanies, groupCompaniesList } = this.props;

        let newGc = { id: value.id, name, ...additionalValue };
        groupCompaniesList.push(newGc);

        groupCompanies.push({ id: value.id, label: name });

    };

    handleAuthorizedSignatoryCreate = (value, name, additionalValue, form) => {

        let { authorizedSignatories } = this.props;
        const { groupCompanySelected } = this.state;

        authorizedSignatories.push({
            id: value.id,
            label: name,
            role: 'DIRECTOR',
            groupCompanyId: groupCompanySelected.id
        });

    };

    handleAuthorizedSignatoryUpdate = (value) => {
        const { authorizedSignatories, groupCompanySelected } = this.state;
        const groupCompany = this.findGroupCompany(groupCompanySelected.id);
       _.set(groupCompany,'authorizedSignatories',_.map(groupCompany.authorizedSignatories,auth=>({...auth})))
        groupCompany.authorizedSignatories.push({ stakeholder: value });
        authorizedSignatories.push(value);
    };


    handleChangeState(value, form){
        form.setValues({
            ...form.values,
            company: {
                ...form.values.company,
                placeOfBusiness:{
                    ...form.values.company.placeOfBusiness,
                    state: value
                }
            }
        });    
    }

    handleChangeCity(value, form){
        form.setValues({
            ...form.values,
            company: {
                ...form.values.company,
                placeOfBusiness:{
                    ...form.values.company.placeOfBusiness,
                    city: value
                }
            }
        });    
    }

    render() {
        const isRequired = message => value => (!!value ? undefined : message);
        const { tooltips = {}, formType } = this.props;

        return (
            <div>
                <div className={classes.ContainerRow}>
                    {formType === 'uploadToEdit' ? (<div>
                        <Field name={`file`}>
                            {({ field, form }) => (
                                <ClaraUpload
                                    required={true}
                                    name={`filepond`}
                                    label='Upload your document '
                                    field={field}
                                    form={form}
                                />
                            )}
                        </Field>
                    </div>) : null}
                    <h1 className={classes.title}>
                        Edit Document
                    </h1>
                    <Field name='agreementName'>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='text'
                                label='Document name '
                                field={field}
                                form={form}
                                placeholder={"Document name"}
                                tooltip={tooltips['Document Name']}
                            />
                        )}
                    </Field>
                </div>
                <Line className={classes.Line} />
                <div className={classes.ZoneTitle}>
                    <h1>{'Company'}</h1>
                </div>
                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                    <Field name='company.id'>
                        {({ field, form }) => (
                            <ClaraSelect
                                required={true}
                                field={field}
                                form={form}
                                label={"Select company "}
                                lists={this.getGroupCompaniesToAssignee()}
                                mode={"classic"}
                                callbacks={{
                                    createValues: this.handleGroupCompanyCreate,
                                    updateValues: this.selectGroupCompany
                                }}
                                placeholder={"Select from the list"}
                                tooltip={tooltips['Select Company']}
                            />
                        )}
                    </Field>
                </div>
                {
                    this.state.groupCompanySelected ?
                        <React.Fragment>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Company details</h1>
                                <p>Please verify or update the company's information below</p>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.name'>
                                    {({ field, form }) => (
                                        <TextInput

                                            required={true}
                                            type='text'
                                            label='Company name '
                                            field={field}
                                            form={form}
                                            placeholder={"Company name"}
                                            tooltip={tooltips["Company's Name"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={` ${classes.Select}`}>
                                <Field name='company.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: false, otherJurisdiction: false }}
                                            jurisdictionsEnabled={false}
                                            countryField={'company.country'}
                                            label={'Country of incorporation'}
                                            returnCountryAsString={true}
                                            setFieldValue={form.setFieldValue}
                                            additionalStyle={'ClaraCountrySelectorModal'}
                                            tooltips={{
                                                country: tooltips["Country of incorporation"],
                                            }}
                                        />
                                    )}
                                </Field>
                                
                            </div>

                            <>
                            <div className={`${classes.ContainerRow} ${classes.Select}`}>

                                <Field name='company.placeOfBusiness_error'>
                                    {({ field, form }) => {
                                    
                                    return (
                                        <React.Fragment>
                                            <span hasError={_.get(form.errors, "company.placeOfBusiness.city") || _.get(form.errors, "company.placeOfBusiness.state") ? "true" : "false"}>
                                            <SelectStateCity
                                                    selectStateLabel={'State of principal office'}
                                                    selectCityLabel={'City of principal office'}
                                                    showState={true}
                                                    modal={true}
                                                    defaultState={form?.values?.company?.placeOfBusiness?.state}
                                                    tooltips={{city:tooltips["Company's City Of Principal Office"],state:tooltips["Company's State Of Principal Office"]}}
                                                    defaultCity={form?.values?.company?.placeOfBusiness?.city}
                                                    onChangeState={(value) => {
                                                        form.setFieldValue('company.placeOfBusiness.state', value)
                                                    }}
                                                    onChangeCity={(value) => {
                                                        form.setFieldValue('company.placeOfBusiness.city', value)
                                                    }}
                                                    errorMessage={_.get(form.errors, "company.placeOfBusiness.city") || _.get(form.errors, "company.placeOfBusiness.state") || _.get(form.errors, "company.placeOfBusiness")}
                                                />
                                            </span>
                                        </React.Fragment>
                                    )}}
                                </Field>
                                </div>
                                
                                
                            </>
                            

                            <Line className={classes.Line}/>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Corporate Signatory</h1>
                                <p>Please add the corporate signatory required to sign this document on behalf of the company.</p>
                            </div>
                            <div className={`${classes.ContainerRow} ${classes.Select}`}>
                                <Field name='company.authorizedSignatories'>
                                    {({ field, form }) => (
                                        <span hasError={_.get(form.errors, "company.authorizedSignatories") ? "true" : "false"} >
                                            <AuthorisedSignatories
                                            	modal={true}
                                                showTitle={true}
                                                list={this.props.stakeholderList}
                                                fieldArrayName={'company.authorizedSignatories'}
                                                idGroupCompany={this.state.groupCompanySelected.id}
                                                errorMessage={_.get(form.errors, "company.authorizedSignatories")}
                                                limit={1}
                                                showRegisteredAddress={true}
                                            />
                                        </span>
                                    )}
                                </Field>
                            </div>
                        
                        </React.Fragment>
                        :
                        null
                }
                
                <Line className={classes.Separator}/>
                <div className={classes.ZoneTitle}>
                    <h1>{'Other Party'}</h1>
                </div>
                <React.Fragment>
                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                    <Field name='stakeholder.id'>
                        {({ field, form }) => (
                            <ClaraSelect
                                required={true}
                                field={field}
                                form={form}
                                limitedModalSize={true}
                                label={"Other Party"}
                                adds={this.getStakeholderAdds()}
                                lists={this.props.stakeholders}
                                mode={"classic"}
                                callbacks={{
                                    updateValues: this.selectStakeholder,
                                    createValues: this.handleStakeholderCreate,
                                }}
                                placeholder={"Select from the list"}
                                tooltip={tooltips["Other Party Select"]}
                            />
                        )}
                    </Field>
                </div>
                { this.state.assignorSelected && <>
                <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                    <h1>Other Party details</h1>
                    <p>Please verify or update the consultant's information below</p>
                </div>
                  
                <div className={`${classes.ContainerRow} ${classes.Select}`}>

                    <Field name='stakeholder.fullName'>
                        {({ field, form }) => (
                            
                            <TextInput
                                required={true}
                                type='text'
                                label='Name'
                                field={field}
                                form={form}
                                placeholder={"Name"}
                                tooltip={tooltips["Other Party's Name"]}
                            />
                        )}
                    </Field>
                </div>
                <div className={`${classes.ContainerRow} ${classes.Select}`}>

                    <Field name='stakeholder.email'>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='email'
                                label='Email address'
                                field={field}
                                form={form}
                                placeholder={"Email address"}
                                tooltip={tooltips["Other Party's Email Address"]}
                            />
                        )}
                    </Field>
                    </div>

                    <div className={classes.ContainerRow}>
                        <Field name='stakeholder.title'>
                            {({ field, form }) => (
                                <TextInput
                                    required={true}
                                    type='text'
                                    label='Title'
                                    field={field}
                                    form={form}
                                    placeholder={"Title"}
                                    tooltip={tooltips["Other Party's Title"]}
                                />
                            )}
                        </Field>
                    </div> 
                    <div className={`${classes.ContainerRow} ${classes.Select}`}>
                        <Field name='stakeholder.address.country'>
                            {({field, form}) =>{
                                return (
                                    <ClaraCountrySelector
                                        isRequired={{ country: true }}
                                        jurisdictionsEnabled={false}
                                        countryField={'stakeholder.address.country'}
                                        label={'Address '}
                                        returnCountryAsString={true}
                                        setFieldValue={form.setFieldValue}
                                        additionalStyle={'ClaraCountrySelectorModal'}
                                        tooltips={{
                                            country: tooltips["Other Party's Country"]
                                        }}
                                    />
                                )
                            }}
                        </Field>
                    </div>

                    <div className={classes.ContainerRow}>
                        <Field name='stakeholder.address.street'>
                            {({ field, form }) => (
                                <TextAreaInput
                                    type='text'
                                    label={' '}
                                    field={field}
                                    form={form}
                                    placeholder={"Address"}
                                    tooltip={tooltips["Other Party's Address"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name='stakeholder.address.city'>
                            {({ field, form }) => (
                                <TextInput
                                    type='text'
                                    label=' '
                                    field={field}
                                    form={form}
                                    placeholder={'City'}
                                    tooltip={tooltips["Other Party's City"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name='stakeholder.address.state'>
                            {({ field, form }) => (
                                <TextInput
                                    type='text'
                                    label=' '
                                    field={field}
                                    form={form}
                                    placeholder={'State / Region'}
                                    tooltip={tooltips["Other Party's State"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name='stakeholder.address.zipCode'>
                            {({ field, form }) => (
                                <TextInput
                                    type='text'
                                    label=' '
                                    field={field}
                                    form={form}
                                    placeholder={"Post code"}
                                    tooltip={tooltips["Other Party's Postal Code"]}
                                />
                            )}
                        </Field>
                    </div>
                    </>}
             </React.Fragment>

            </div>

        );
    }

}

export default UsaNDADoc;

import { createMachine } from 'xstate';

const machine =
  /** @xstate-layout N4IgpgJg5mDOIC5QFVZgE4AUCuAjANgJYDGASmFIbAC7oCG1hA9gHYB02a6AYk+gLYBiAHIBRABoAVRKAAOTWIUasZIAB6IAjABYAHGwBMAVgBsukye1GjABgDsuzQYA0IAJ5bNATjZGvf3V1tL20TOxt-AF9I11QMHAISckoaemV2AGslXgFBTFJRADVVeUV01Q0EHX1jMwsrWwcnVw8EO01fLxMbG10AZh6m42jYrgSiMgoqWgZmTOy+ITEpEoUlOYrEPr6fTXCvO1s+0J7NFq0DAzYIgKCQsJuRkDisPAnk6bS5thowWT68gVikgQKV1ioQZVtrt9ocbMdujYzu5EHZtNcuj1+oNHMMniwmBA4KoXuMklNUrNWBwuDl+KsyhtIYhtC4UW0fDs7H0DLoutpNDy+iYnqS3uSUjN0mwstQ6QzwSxNggDALDKZdEYdF4Qto+v1zghHGw+dzefzBQZhaKxuLJpKvtTfv8FeVmQg9Vc9hFEQ4ukZdIagmwuTy+ZZLdaYs9bYl7Z8qUqQWC3aBKppA+yALQdbQ2PM2IzaVlWvMWaLRIA */
  createMachine({
    id: 'EditSourceOfFundsByJurisdictionWizard',
    initial: 'getJurisdiction',
    states: {
      select: {
        on: {
          NEXT: {
            target: 'getJurisdiction',
            actions: ['setContextAfterStep']
          }
        }
      },
      getJurisdiction: {
        invoke: {
          src: 'getJurisdiction',
          id: 'getJurisdiction',
          onDone: [
            {
              target: 'evaluateJurisdiction',
              actions: ['setContextAfterInvoke']
            }
          ],
          onError: [
            {
              target: 'select'
            }
          ]
        }
      },
      evaluateJurisdiction: {
        always: [
          {
            cond: 'needSelectJurisdiction',
            target: 'selectJurisdiction'
          },
          {
            cond: 'isCayman',
            target: 'editCayman'
          },
          {
            cond: 'isADGM',
            target: 'editADGM'
          },
          {
            cond: 'isDIFC',
            target: 'editDIFC'
          },
          {
            cond: 'isOTHER',
            target: 'editOTHER'
          },
          {
            target: 'error'
          },
        ]
      },
      selectJurisdiction: {
        on: {
          NEXT: {
            target: 'evaluateSelectedJurisdiction',
            actions: ['setContextAfterStep']
          },
          PREV: {
            target: 'finish'
          }
        }
      },
      evaluateSelectedJurisdiction: {
          always: [
            {
              cond: 'isCaymanSelected',
              target: 'editCayman'
            },
            {
              cond: 'isADGMSelected',
              target: 'editADGM'
            },
            {
              cond: 'isDIFCSelected',
              target: 'editDIFC'
            },
            {
              cond: 'isOTHERSelected',
              target: 'editOTHER'
            },
          ]        
      },
      editCayman: {
        on: {
          NEXT: {
            target: 'finish',
            actions: ['upsertShareholder']
          },
          PREV: {
            target: 'getJurisdiction'
          }
        }
      },
      editADGM: {
        on: {
          NEXT: {
            target: 'finish',
            actions: ['upsertShareholder']
          },
          PREV: {
            target: 'getJurisdiction'
          }
        }
      },
      editDIFC: {
        on: {
          NEXT: {
            target: 'finish',
            actions: ['upsertShareholder']
          },
          PREV: {
            target: 'getJurisdiction'
          }
        }
      },
      editOTHER: {
        on: {
          NEXT: {
            target: 'finish',
            actions: ['upsertShareholder']
          },
          PREV: {
            target: 'getJurisdiction'
          }
        }
      },
      error: {
        on: {
          PREV: {
            target: 'select'
          }
        }
      },
      result: {
        on: {
          PREV: {
            target: 'select'
          }
        }
      },
      finish: {
        entry: 'stopMachine',
        type: 'final'
      }
    }
  });
export default machine;

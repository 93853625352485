import React, { useMemo } from "react";
import PaymentItems from "../../PaymentItems";
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import _ from "lodash";

export interface PaymentItemActivePincasProps {
  product: any;
  variantProduct: any;
  company: any;
  feeText: string;
}

const PaymentItemActivePincas: React.FC<PaymentItemActivePincasProps> = ({
  product,
  variantProduct,
  company,
  feeText,
}) => {

  const { context } = useMachine();
  const totalGovernmentFee = useMemo(() => {
    let totalGovernmentAmountBase = 0;
    _.forEach(context?.getCart?.requestBasket?.pricingItems, (item) => {
      totalGovernmentAmountBase += item?.totalGovernmentFee;
    });
    return totalGovernmentAmountBase;
  }, [context?.getCart?.requestBasket?.pricingItems]);

  return (
    <>
      <PaymentItems
        product={product}
        variant={variantProduct}
        company={company}
        useTotals={true}
        totalGovernmentFee={totalGovernmentFee}
        totalPricingDiscount={context?.getProduct?.product?.totals?.totalPricingDiscount}
        feeText={feeText}
      ></PaymentItems>
    </>
  );
};
export default PaymentItemActivePincas;

import React, { useState } from "react";
import classes from "./Messages.module.scss";
import Title from "../../../../../components/text/Title/Title";
import {
  PanelMessages,
  ZoneMessages,
} from "../../../../../components/modules/clara/startups/messages";
import useLoading from "../../../../../modules/generic/hooks/useLoading";
import MessagesSkeleton from "./MessagesSkeleton";
import useTranslate from "src/modules/generic/hooks/useTranslate";

const Messages = ({ ...props }) => {

  const [threadMessages, setThreadMessages] = useState(null);
  const {isLoading} = useLoading();
  const {translate } = useTranslate();

  return (
    <React.Fragment>
      <MessagesSkeleton
        isLoading={isLoading}
      ></MessagesSkeleton>
      <div className={classes.container}>
        <div className={classes.containerMessages}>
          <div className={classes.containerTitle}>
            <Title>{translate('MY_ACCOUNT_ACTIVITY_LOG')}</Title>
          </div>
          <div className={classes.containerPanelMessages}>
            <PanelMessages setThreadMessages={setThreadMessages} />
          </div>
          <div className={classes.containerZoneMessages}>
            <ZoneMessages thread={threadMessages} />
          </div>
        </div>
      </div>   
    </React.Fragment>
  );
};

export default Messages;

import * as Yup from "yup";
import {Constants} from '../../utils/constants';
import helper from "../../utils/helper";
import _ from 'lodash';
import ConfigChatQuestionary from "./ConfigChatQuestionary"

const generateSchema = (bot_message) => {
    const validation = createValidation(bot_message);
    const type = bot_message.type;
    if (type == "OPTIONS" && _.get(bot_message, "inputConfig.validation.isEnabled", false)) {
        return validation;
    }
    return Yup.object().shape({
        [bot_message.responseKeyName]: validation
    })

}
const createValidation = (bot_message) => {
    const {inputConfig = {}, type} = bot_message;

    const requiredField = 'This field is required';
    switch (type) {
        case "MULTIFIELD":
            let multiValidator = {}
            bot_message.inputConfig.fields.forEach(field => {
                multiValidator[field.responseKeyName] = createValidation(field);
            })
            return Yup.object().shape(multiValidator);
        case 'TEXTAREA':
        case 'TEXT':
            let validText = Yup.string().trim().required(requiredField);
            if (_.get(inputConfig, "validation.minLength")) {
                validText = validText.min(_.get(inputConfig, "validation.minLength"), _.get(inputConfig, "validation.minLengthMessage", `Must have ${_.get(inputConfig, "validation.minLength")} characters`))
            }
            if (_.get(inputConfig, "validation.maxLength")) {
                validText = validText.max(_.get(inputConfig, "validation.maxLength"), _.get(inputConfig, "validation.maxLengthMessage", `Must have ${_.get(inputConfig, "validation.maxLength")} characters to max`))
            }
            if (_.get(inputConfig, "validation.regExp")) {
                validText = validText.matches(_.get(inputConfig, "validation.regExp"), _.get(inputConfig, "validation.regExpMessage", `Invalid Format`))
            }
            return validText;
        case 'EMAIL':
            return Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, { message: "Invalid Email", excludeEmptyString: true }).required(requiredField)
        case 'NUMBER':

            let validNumber = Yup.number().typeError("Only numbers is allowed")
                .integer("Only fixed numbers is allowed")
                .required(requiredField);

            const validation  = _.get(inputConfig,"validation",{});
            const minMessage = _.get(validation,"minMessage", `The number must be greater than ${validation && validation.min}`);
            const maxMessage = _.get(validation, "maxMessage", `The number must be less than ${validation && validation.max}`)

            if (validation && validation.min !== null) {
                validNumber = validNumber.min(validation.min, `${minMessage}`)
            }

            if (validation && validation.max !== null) {
                validNumber = validNumber.max(validation.max, `${maxMessage} `)
            }

            return validNumber;
        case 'FLOAT':
            return Yup.number()
                .typeError("Only numbers is allowed")
                .required(requiredField);
        case 'MULTIPLE_FILE_UPLOAD':
            return Yup.object().shape({
                documentsUploaded: Yup.array().of(
                    Yup.object().shape({
                        name: Yup.string().required(requiredField),
                        id: Yup.string().required(requiredField)
                    })),
                uploadedFiles: Yup.array().of(
                    Yup.object().shape({
                        name: Yup.string().required(requiredField),
                        id: Yup.string().required(requiredField),
                        size: Yup.string().required(requiredField)
                    }))
            }).required(requiredField)
        case 'FILE_UPLOAD':
            if (inputConfig.isMultiple) {
                return Yup.array().of(
                    Yup.object().shape({
                        name: Yup.string().required(requiredField),
                        size: Yup.string().required(requiredField),
                        id: Yup.string().required(requiredField)
                    }).required(requiredField).nullable()
                ).required(requiredField);
            } else {
                return Yup.object().shape({
                    name: Yup.string().required(requiredField),
                    size: Yup.string().required(requiredField),
                    id: Yup.string().required(requiredField)
                }).required(requiredField).nullable();
            }
        case "ADDRESS":
            return Yup.object().shape({
                country: Yup.string().nullable().required(requiredField),
                address: Yup.string().nullable().required(requiredField),
                city: Yup.string().nullable().required(requiredField),
                state: Yup.string().nullable(),
                zipCode: Yup.string().nullable()
            }).required(requiredField);

        case 'CHECKBOX':
            return Yup.array().of(Yup.string()).required(requiredField).min(1);
        case 'OPTIONS':
            if (_.get(bot_message, "inputConfig.validation.isEnabled", false)) {
                let optionValid = {};
                _.forEach(bot_message.inputConfig.options, (option) => {
                    if (option.bot_message) {
                        optionValid[option.bot_message.responseKeyName] = createValidation(option.bot_message);
                    }

                });
                return Yup.object().shape(optionValid)
            }
            if (bot_message.inputConfig && bot_message.inputConfig.isSelectWithEntity && !bot_message.inputConfig.isMultiple) {
                return Yup.object().shape({
                    id: Yup.string().required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED")).nullable(false).typeError(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED")),
                    type: Yup.string().required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED")).nullable(false).typeError(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED"))
                }).required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED")).nullable(false).typeError(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED"))
            } else {
                if (bot_message.inputConfig && bot_message.inputConfig.entities && bot_message.inputConfig.entities.includes("countries")) {

                    return Yup.object().shape({
                        code: Yup.string().required(requiredField).nullable()
                    }).required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED")).nullable(false).typeError(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED"))
                }
            }
        case "SPECIFIC_STEP_COMPONENT":
            switch (bot_message.inputConfig.specificStepComponentName) {
                case "QUESTIONARY_COMPANY_NAME":
                    return _.get(ConfigChatQuestionary, "COMPANY_NAME." + _.get(bot_message, "inputConfig.specificStepComponentConfig.type", "") + ".validation", null)
                case "QUESTIONARY_COMPANY_DETAILS":
                case "QUESTIONARY_SPV_PRIMARY_ASSET":
                case "QUESTIONARY_ARTICLES_ASSOCIATION":
                case "QUESTIONARY_SHAREHOLDER_DIRECTORS":
                case "QUESTIONARY_AUTHORIZED_SIGNATORIES":
                case "QUESTIONARY_SHARE_CAPITAL":
                case "QUESTIONARY_SHARES_ISSUES":
                case "QUESTIONARY_DATA_PROTECTION":
                case "QUESTIONARY_ADDITIONAL_SIGNATURE":
                case "SCOPING_HOLDING_COMPANY_SPV":
                case "KYC_ENTITY_EDIT_MEMBERS":
                case "KYC_ENTITY_EDIT_MEMBERS":
                case "ADGM_APPROVAL_DOCUMENTS":
                case "QUESTIONARY_BENEFICIAL_OWNERS":
                case "KYC_ENTITY_PEP_INDIVIDUALS":
                case "QUESTIONARY_AGREEMENT_DIRECTORS":
                    console.log("Validation: ", _.get(ConfigChatQuestionary, `${bot_message.inputConfig.specificStepComponentName}.validation`, null))
                    return _.get(ConfigChatQuestionary, `${bot_message.inputConfig.specificStepComponentName}.validation`, null)
                default:
                    return null;
            }


        case 'DATE': return Yup.string().required("This field is required.").matches(Constants.REGEXP.DATE, { message: "This field is invalid", excludeEmptyString: true }).nullable();
        case 'AUTOMATIC':
            return {};
        default:
            return null;
    }
}
export default {
    generateSchema
}

import useMachine from '../../../../../generic/context/MachineContext/useMachine';
import SelectRoles from '../../../Forms/FormSelectRoles';
import React, { useEffect } from 'react';
import Button from "../../../../../generic/components/Buttons/Button";
import useTranslate from '../../../../../generic/hooks/useTranslate';
import { useDispatch } from "react-redux";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import { addAssistantText, clearAssistatText } from "../../../../../generic/store/action";
import useModal from "../../../../../generic/hooks/useModal";
import useSession from 'src/modules/session/hooks/useSession';
import { Constants } from "src/v1/utils";
import useGetPriceByAddRole from '../../hooks/useGetPriceByAddRole'
/**
 * Step para editar los datos de SOF OTHER
 * @param {*} param0
 * @returns
 */
function SelectRolesStep({ openParam }) {

  const { next, context } = useMachine();
  const { translate } = useTranslate()
  const dispatch = useDispatch();
  const { closeModal: onClose } = useModal();
  const { startup } = useSession();
  const getPriceByRole = useGetPriceByAddRole(context?.getGroupCompaniesAndStakeholder?.requestItems);
  const handleCancel = (values) => {
    dispatch(clearAssistatText())
    onClose();
  }

  const handleInitModal = () => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText(translate('MODULES.EQUITY.ADD_EQUITY.STEP_ADD_CONVERTIBLE.TOOLTIP')))
  }

  useEffect(() => {
    handleInitModal()
  }, []);

  const handleCompleteSubmit = async (values) => {
    next(values)
  }

  const getRoles = () => {
    const roleKeys = Object.keys(context.roles)
    return [
      "SHAREHOLDER",
      "DIRECTOR",
      "FOUNDER",
      "EMPLOYEE",
      "CONSULTANT",
      "ADVISOR",
      "INVESTOR",
      "THIRDPARTY",
    ].map(key => {
      const value = Constants.ROLES_FROM_BACKEND[key]
      return { value: value.id, label: value.label, disabled: !roleKeys.includes(key) }
    })
  }
  return (
    <SelectRoles
      roles={getRoles()}
      priceList={getPriceByRole(['DIRECTOR','SHAREHOLDER'])}
      groupCompanies={context?.getGroupCompaniesAndStakeholder?.groupCompanies ?? []}
      hasPincas={context?.hasPincas}
      buttons={{
        cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
      }}
      onCompleteSubmit={handleCompleteSubmit}
      propsTemplate={{
        title: `Select all the roles they have in ${startup.name}`,
        subTitle: context?.getGroupCompaniesAndStakeholder?.stakeholder?.fullName
      }}
      Template={TemplateWizard}
    />
  );
}
export default SelectRolesStep;

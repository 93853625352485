import useMutation from "src/hooks/custom/useMutation";

export const UPSERT_UPDATE_ULTIMATE_PARENT = `
mutation updateUltimateParent(
  $startupId: ID!
  $groupCompanyId: ID!
  $entityData: RegulatoryBusinessEntityInput!
) {
  updateUltimateParent(
    startupId: $startupId
    groupCompanyId: $groupCompanyId
    entityData: $entityData
  ) {
    id
  }
}
`;

const useUpdateUltimateParent = () => {
  const [mutation] = useMutation(
    UPSERT_UPDATE_ULTIMATE_PARENT,
    { showSuccessNotification: true }
  );
  return mutation;
};

export default useUpdateUltimateParent;

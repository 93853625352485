import React, {useEffect} from 'react';
import {Checkbox, Text, TextAreaInput} from '../index';
import _ from "lodash";
import PropTypes from 'prop-types';
import {Field} from "formik";
import classes from "./Chat.module.scss";

const ChatScopingHoldingCompanySPV = ({ responseKeyName, isForm, initialValueStart, className }) => {
  const nameField = responseKeyName ? responseKeyName + "." : "";


  const publicValues = (fieldName, value, form) => {
    form.setFieldValue(fieldName, value);
  }
  const selectButton = (fieldName, field, form) => {
    const value = _.get(form.values, fieldName);
    if (value === "TRUE") {
      publicValues(fieldName + "Text", null, form);
      publicValues(fieldName, null, form);
    } else {
      publicValues(fieldName, "TRUE", form);
    }



  }

  useEffect(() => {

  })

  return (
    <React.Fragment>
      <div className={`${classes.ChatQuestionnaire} ${classes.ChatPrimaryAsset}`}>
        <Field name={`${nameField}scopingHoldingCompanySPV`}>
          {({ field, form }) => {
            const classSelectedAlreadyIncorporated = _.get(form.values, `${nameField}alreadyIncorporated`) === "TRUE" ? classes.buttonOptionSelected : null;
            const classSelectedToBeIncorporated = _.get(form.values, `${nameField}toBeIncorporated`) === "TRUE" ? classes.buttonOptionSelected : null;
            const classSelectedIntellectualProperty = _.get(form.values, `${nameField}intellectualProperty`) === "TRUE" ? classes.buttonOptionSelected : null;
            const classSelectedOtherAsset = _.get(form.values, `${nameField}otherAsset`) === "TRUE" ? classes.buttonOptionSelected : null;
            return (
              <React.Fragment>
                <button className={`${classes.buttonOption} ${classSelectedAlreadyIncorporated}`} type="button" onClick={() => { selectButton(`${nameField}alreadyIncorporated`, field, form) }}>
                  <Text uuid={`CHAT_SCOPING_HOLDING_COMPANY_SPV_ALREADY_INCORPORATED_BUTTON`} />
                </button>
                {_.get(form.values, `${nameField}alreadyIncorporated`) === "TRUE" ? (
                  <React.Fragment>

                    <div className={classes.contentField}>
                      <Field name={nameField + "alreadyIncorporatedText"}>
                        {({ field, form }) => (
                          <React.Fragment>
                            <label className={classes.labelChat}><Text uuid={"CHAT_SCOPING_HOLDING_COMPANY_SPV_ALREADY_INCORPORATED_LABEL"} /></label>
                            <TextAreaInput
                              required={true}
                              field={field}
                              form={form}
                              autoComplete='off'
                              placeholder={"CHAT_SCOPING_HOLDING_COMPANY_SPV_ALREADY_INCORPORATED_PLACEHOLDER"}
                              showRequired={false}
                              className={classes.ChatInputTextarea}
                            />
                          </React.Fragment>
                        )}
                      </Field>
                    </div>
                  </React.Fragment>
                ) : null}

                <button className={`${classes.buttonOption} ${classSelectedToBeIncorporated}`} type="button" onClick={() => { selectButton(`${nameField}toBeIncorporated`, field, form) }}>
                  <Text uuid={`CHAT_SCOPING_HOLDING_COMPANY_SPV_TOBE_INCORPORATED_BUTTON`} />
                </button>
                {_.get(form.values, `${nameField}toBeIncorporated`) === "TRUE" ? (
                  <React.Fragment>
                    <div className={classes.contentField}>
                      <Field name={nameField + "toBeIncorporatedText"}>
                        {({ field, form }) => (
                          <React.Fragment>
                            <label className={classes.labelChat}><Text uuid={"CHAT_SCOPING_HOLDING_COMPANY_SPV_TOBE_INCORPORATED_LABEL"} /></label>
                            <TextAreaInput
                              required={true}
                              field={field}
                              form={form}
                              autoComplete='off'
                              placeholder={"CHAT_SCOPING_HOLDING_COMPANY_SPV_TOBE_INCORPORATED_PLACEHOLDER"}
                              showRequired={false}
                              className={classes.ChatInputTextarea}
                            />
                          </React.Fragment>
                        )}
                      </Field>
                    </div>
                  </React.Fragment>
                ) : null}

                <button className={`${classes.buttonOption} ${classSelectedIntellectualProperty}`} type="button" onClick={() => { selectButton(`${nameField}intellectualProperty`, field, form) }}>
                  <Text uuid={`CHAT_SCOPING_HOLDING_COMPANY_SPV_INTELLECTUAL_PROPERTY_BUTTON`} />
                </button>
                {_.get(form.values, `${nameField}intellectualProperty`) === "TRUE" ? (
                  <React.Fragment>

                    <div className={classes.contentField}>
                      <Field name={nameField + "intellectualPropertyText"}>
                        {({ field, form }) => (
                          <React.Fragment>
                            <label className={classes.labelChat}><Text uuid={"CHAT_SCOPING_HOLDING_COMPANY_SPV_INTELLECTUAL_PROPERTY_LABEL"} /></label>
                            <TextAreaInput
                              required={true}
                              field={field}
                              form={form}
                              autoComplete='off'
                              placeholder={"CHAT_SCOPING_HOLDING_COMPANY_SPV_INTELLECTUAL_PROPERTY_PLACEHOLDER"}
                              showRequired={false}
                              className={classes.ChatInputTextarea}
                            />
                          </React.Fragment>
                        )}
                      </Field>
                    </div>
                    <label className={classes.labelChat}><Text uuid={"CHAT_SCOPING_HOLDING_COMPANY_SPV_INTELLECTUAL_PROPERTY_TYPE_LABEL"} /></label>
                    <div className={classes.contentCheckBox}>
                      <Field name={nameField + "isRevenueGenerating"}>
                        {({ field, form }) => (
                          <React.Fragment>
                            <Checkbox
                              form={form}
                              field={field}
                              title={"Revenue Generation"}
                              value={true}
                              className={classes.CheckBox}
                              divForChatClassName={field.value ? `${classes.Check} ${classes.CheckedDiv}` : `${classes.Check} ${classes.NotCheckedDiv}`}
                              isChat={true}
                              handleChange={() => {
                                form.setFieldValue(nameField + "isRevenueGenerating", !field.value)
                              }}
                            />
                          </React.Fragment>
                        )}
                      </Field>
                      <Field name={nameField + "isProtection"}>
                        {({ field, form }) => (
                          <React.Fragment>

                            <Checkbox
                              form={form}
                              field={field}
                              title={"Protection"}
                              value={true}
                              className={classes.CheckBox}
                              divForChatClassName={field.value ? `${classes.Check} ${classes.CheckedDiv}` : `${classes.Check} ${classes.NotCheckedDiv}`}
                              isChat={true}
                              handleChange={() => {
                                form.setFieldValue(nameField + "isProtection", !field.value)
                              }}
                            />
                          </React.Fragment>
                        )}
                      </Field>
                    </div>
                  </React.Fragment>
                ) : null}

                <button className={`${classes.buttonOption} ${classSelectedOtherAsset}`} type="button" onClick={() => { selectButton(`${nameField}otherAsset`, field, form) }}>
                  <Text uuid={`CHAT_SCOPING_HOLDING_COMPANY_SPV_OTHER_ASSET_BUTTON`} />
                </button>
                {_.get(form.values, `${nameField}otherAsset`) === "TRUE" ? (
                  <React.Fragment>

                    <div className={classes.contentField}>
                      <Field name={nameField + "otherAssetText"}>
                        {({ field, form }) => (
                          <React.Fragment>
                           <label className={classes.labelChat}><Text uuid={"CHAT_SCOPING_HOLDING_COMPANY_SPV_OTHER_LABEL"} /></label>
                            <TextAreaInput
                              required={true}
                              field={field}
                              form={form}
                              autoComplete='off'
                              placeholder={"CHAT_SCOPING_HOLDING_COMPANY_SPV_OTHER_ASSET_PLACEHOLDERS"}
                              showRequired={false}
                              className={classes.ChatInputTextarea}
                            />
                          </React.Fragment>
                        )}
                      </Field>

                    </div>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            )
          }}
        </Field>


      </div>
    </React.Fragment >
  );
}
ChatScopingHoldingCompanySPV.propTypes = {
  responseKeyName: PropTypes.string.isRequired,

};
export default ChatScopingHoldingCompanySPV;

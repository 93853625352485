import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateLabel from 'src/modules/generic/components/Inputs/LabelInput';
import * as Yup from 'yup';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import CalendarInputDate from "src/components/inputs/Calendars/CalendarInputDate";
import FileUploaderInput from 'src/components/inputs/FileUploader/FileUploaderInput';
import classes from './classes.module.scss';

function UploadStep() {
  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.UploadStep ?? {}
  }, [context]);

  const handleCancel = () => {
    cancel();
  }

  const handleSubmit = async (values) => {
    next(values)
  }

  useEffect(() => {
    dispatch(addAssistantText(translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.ARTICLES_OF_ASSOCIATION.TOOLTIP")))
    return () => {
      dispatch(clearAssistatText())
    }
  }, [dispatch]);

  const schema = Yup.object().shape({
    document: Yup.object().nullable().required(translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.ARTICLES_OF_ASSOCIATION.REQUIRED")),
    date: Yup.date().nullable().typeError(translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.ARTICLES_OF_ASSOCIATION.REQUIRED_DATE")).required()
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.ARTICLES_OF_ASSOCIATION.TITLE"),
          skeleton: null,
          subTitle: translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.ARTICLES_OF_ASSOCIATION.SUBTITLE"),
          variant: 'simple'
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('GENERIC_BUTTON_ADD_GROUP')}</SubmitButton>,
        }}
      >
        <>
          <TemplateLabel 
            label={translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.ARTICLES_OF_ASSOCIATION.FIELD_DOCUMENT")}
            isRequired
            variant={"top"}
          >
            <ControllerInput
              render={FileUploaderInput}
              name='document'
              defaultlabel={''}
              className={classes.fileLoad}
            />
          </TemplateLabel>
          <TemplateLabel
            label={translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.ARTICLES_OF_ASSOCIATION.ISSUE_DATE")}
            isRequired
          >
            <ControllerInput
              render={CalendarInputDate}
              placeholder={"dd/mm/yyyy"}
              name="date"
              onChange={(e) => { }}
            />
          </TemplateLabel>
        </>
      </TemplateWizard>
    </Form>
  );
}

export default UploadStep;


import { useEffect, useState } from 'react';
import _ from "lodash";

const useMapDataCards = ({ filters, groupCompanies, stakeholders, roles }) => {

  const [dataPropsGroupCompany, setDataPropsGroupCompany] = useState({});
  const [dataPropsRol, setDataPropsRol] = useState({});
  const [dataPropsStakeholder, setDataPropsStakeholder] = useState({});
  const [totals, setTotals] = useState({ groupCompanies: {}, roles: {} })

  const matchFilterText = (stakeholder) => {
    if (!filters.text || filters.text === "") {
      return true;
    }
    return stakeholder?.fullName?.toLowerCase().indexOf(filters?.text?.toLowerCase()) >= 0;
  }

  const getTotalsByRol = (rol) => {
    var stakeholdersCurrent = [];
    var stakeholdersTotal = [];
    var countTotals = 0;
    var countCurrent = 0;
    _.forEach(stakeholders, (sh, stakeholderId) => {
      if (rol.id === "FOUNDER" && sh.isFounder) {
        if (stakeholdersTotal.indexOf(stakeholderId) < 0) {
          stakeholdersTotal.push(stakeholderId);
          countTotals++;
        }
        if (stakeholdersCurrent.indexOf(stakeholderId) < 0) {
          if (
            (!filters.rol || (filters.rol && filters.rol == rol.id)) &&
            (!filters.stakeholder || (filters.stakeholder && filters.stakeholder == stakeholderId)) &&
            matchFilterText(sh)
          ) {
            stakeholdersCurrent.push(stakeholderId);
            countCurrent++;
          }
        }
      }
      _.forEach(sh.roles[rol.id], (groupCompany, groupCompanyId) => {
        if (stakeholdersTotal.indexOf(stakeholderId) < 0) {
          stakeholdersTotal.push(stakeholderId);
          countTotals++;
        }
        if (stakeholdersCurrent.indexOf(stakeholderId) < 0) {
          if (
            (!filters.groupCompany || (filters.groupCompany && filters.groupCompany == groupCompanyId)) &&
            (!filters.rol || (filters.rol && filters.rol == rol.id)) &&
            (!filters.stakeholder || (filters.stakeholder && filters.stakeholder == stakeholderId)) &&
            matchFilterText(sh)
          ) {
            stakeholdersCurrent.push(stakeholderId);
            countCurrent++;
          }
        }
      });
    })
    return { total: countTotals, current: countCurrent }
  }

  const getTotalsByGroupCompany = (gc) => {
    var stakeholdersCurrent = [];
    var stakeholdersTotal = [];
    var countTotals = 0;
    var countCurrent = 0;
    _.forEach(gc.roles, (rol, rolId) => {
      _.forEach(rol, (stakeholder, stakeholderId) => {
        if (stakeholdersTotal.indexOf(stakeholderId) < 0) {
          stakeholdersTotal.push(stakeholderId);
          countTotals++;
        }
        if (stakeholdersCurrent.indexOf(stakeholderId) < 0) {
          if (
            (!filters.groupCompany || (filters.groupCompany && filters.groupCompany == gc.id)) &&
            (!filters.rol || (filters.rol && filters.rol == rolId)) &&
            (!filters.stakeholder || (filters.stakeholder && filters.stakeholder == stakeholderId)) &&
            matchFilterText(stakeholders[stakeholderId])
          ) {
            stakeholdersCurrent.push(stakeholderId);
            countCurrent++;
          }
        }
      })
    })
    return { total: countTotals, current: countCurrent }
  }

  const findRolInStakeholders = (stakeholders, rolId) => {
    const finded = _.find(stakeholders, (stakeholder) => {
      if (matchFilterText(stakeholder, filters)) {
        return _.find(stakeholder.roles, (rol, rolKey) => {
          if (rolId == rolKey) {
            const gc = _.find(rol, (value, keyGC) => {
              return !filters.groupCompany || keyGC == filters.groupCompany
            })
            return !!(gc);
          }
          return false;
        });
      }
    })
    return finded;
  }

  const findGroupCompanylInStakeholders = (gcId) => {
    const finded = _.find(groupCompanies[gcId].roles, (rol, rolId) => {
      return _.find(rol, (sh, shId) => matchFilterText(stakeholders[shId], filters));
    })
    return finded;
  }

  const isGroupCompanySelected = (keyGC, filters) => {
    return filters.groupCompany == keyGC
  }

  const isGroupCompanySuggested = (keyGC, filters) => {
    //If this GC is selected then it isn't suggested
    if (filters.groupCompany == keyGC) return false;
    let isSuggestedByGroupCompany = !(!!(filters.groupCompany))
    let isSuggestedByRol = filters.rol || filters.stakeholder
    let isSuggestedByStakeholder = filters.rol || filters.stakeholder
    let rolesAvailables = groupCompanies[keyGC].roles;
    if (filters.rol) {
      rolesAvailables = _.filter(rolesAvailables, (rol, rolId) => rolId == filters.rol)
      isSuggestedByRol = !_.isEmpty(rolesAvailables)
    }
    if (filters.stakeholder || filters.text) {
      const findedStakeholer = _.find(rolesAvailables, (rol, rolId) => {
        return _.find(rol, (sh, shId) => {
          var machFilter = matchFilterText(stakeholders[shId]);
          if (!machFilter) return false;
          if (filters.stakeholder) {
            return shId == filters.stakeholder
          }
          return true;
        })
      })
      isSuggestedByStakeholder = !!(findedStakeholer)
    }
    if (filters.groupCompany && !(filters.stakeholder || filters.rol || filters.text)) {
      let companies = [];
      const addChildrens = (id) => {
        const newChildrens = _.get(groupCompanies, `${id}.childrens`);
        if (newChildrens) {
          companies = companies.concat(newChildrens);
          _.forEach(newChildrens, (children) => {
            addChildrens(children.id)
          });
        }
      }
      addChildrens(filters.groupCompany)
      const addParent = (id) => {
        _.forEach(groupCompanies, (gc, gcId) => {
          const children = _.find(gc.childrens, (children) => children.id == id);
          if (children) {
            companies = companies.concat([gc]);
            addParent(gcId)
          }
        });
      }
      addParent(filters.groupCompany)
      const findChild = _.find(companies, (element) => {
        return element.id === keyGC
      });
      if (findChild) return true;
      isSuggestedByGroupCompany = !!(findChild)
    }
    return isSuggestedByGroupCompany && isSuggestedByRol && isSuggestedByStakeholder
  }

  const isGroupCompanyUnavailable = (groupCompanyId, data_selected, data_suggested, filters) => {
    //If this is selected then it isn't unvailable
    if (data_selected) return false;
    //filter by text
    if (filters.text && filters.text !== "") {
      const stakeholderFinded = findGroupCompanylInStakeholders(groupCompanyId)
      return !(!!(stakeholderFinded))
    }
    //If this is suggested then it isn't unvailable
    if (data_suggested) return false;
    //If my parent is selected and not select other filters then it is selected
    if (filters.rol || filters.stakeholder) return true
    //if the groupCompany filtet exists then it is unvailable
    return false;
  }

  const isGroupCompanyBlockLines = (data_selected, data_suggested, filters) => {
    if (data_selected) {
      return false;
    }
    return !!filters.groupCompany;
  }

  const isRolSelected = (key, filters) => {
    return filters.rol == key
  }

  const isRolSuggested = (key, filters) => {
    //If this Rol is selected then it isn't suggested
    if (filters.rol == key) return false;
    let isSuggestedByGroupCompany = !!(filters.groupCompany || filters.stakeholder)
    let isSuggestedByStakeholder = !!(filters.groupCompany || filters.stakeholder)
    let groupCompaniesAvailables = _.filter(groupCompanies, (groupCompany) => _.hasIn(groupCompany.roles, key));
    if (filters.groupCompany) {
      groupCompaniesAvailables = _.filter(groupCompaniesAvailables, (gc) => gc.id == filters.groupCompany);
      isSuggestedByGroupCompany = !_.isEmpty(groupCompaniesAvailables)
    }
    if (filters.stakeholder || filters.text) {
      if (stakeholders[filters.stakeholder] && stakeholders[filters.stakeholder].isFounder) {
        isSuggestedByStakeholder = true
      } else {
        const findedStakeholer = _.find(groupCompaniesAvailables, (gc) => {
          return _.find(gc.roles[key], (sh, shId) => {
            var machFilter = matchFilterText(stakeholders[shId]);
            if (!machFilter) return false;
            if (filters.stakeholder) {
              return shId == filters.stakeholder
            }
            return true;
          })
        })
        isSuggestedByStakeholder = !!(findedStakeholer)
      }
    }
    return isSuggestedByGroupCompany && isSuggestedByStakeholder
  }

  const isRolUnavailable = (rolId, data_selected, data_suggested, filters) => {
    //If this is selected then it isn't unvailable
    if (data_selected) return false;
    //filter by text
    if (filters.text && filters.text !== "") {
      const rolFinded = findRolInStakeholders(stakeholders, rolId)
      return !(!!(rolFinded))
    }
    //If this is suggested then it isn't unvailable
    if (data_suggested) return false;
    //If my parent is selected and not select other filters then it is selected
    if (filters.groupCompany || filters.stakeholder) return true
    //if the groupCompany filtet exists then it is unvailable
    return false;
  }

  const isRolBlockLines = (data_selected, data_suggested, filters) => {
    if (data_selected) return false
    return !!filters.rol;
  }

  const isStakeholderSelected = (key, filters) => {
    return filters.stakeholder === key
  }

  const isStakeholderVisible = (keySH, filters) => {
    const stakeholder = stakeholders[keySH];
    if (filters.stakeholder) {
      return keySH === filters.stakeholder;
    }
    let rolFilter = null;
    if (filters.rol) {
      rolFilter = _.find(stakeholder.roles, (stakeholder, key) => key == filters.rol);
      if (!rolFilter) {
        return false;
      }
    }
    if (filters.groupCompany) {
      const gcFilter = _.find(groupCompanies[filters.groupCompany].roles, (roles, key) => {
        if (!filters.rol || (filters.rol && filters.rol == key)) {
          return _.find(roles, (stakeholder, keySHF) => keySHF == keySH);
        }
        return false;
      });
      if (!gcFilter) {
        return false;
      }
    }
    return (!filters.text || filters.text == "" || stakeholder.fullName.toLowerCase().indexOf(filters.text.toLowerCase()) >= 0)
  }

  const isStakeholderSuggested = (keySH, filters) => {
    //If this Rol is selected then it isn't suggested
    if (filters.stakeholder) return false;
    //Evaluate Suggested by filters
    if (filters.groupCompany || filters.rol) {
      //Evaluate Suggested by filters
      let resultSearch = null
      if (filters.rol && !filters.groupCompany) {
        resultSearch = _.find(stakeholders[keySH].roles, (rol, key) => {
          return key == filters.rol
        });
      } else if (!filters.rol && filters.groupCompany) {
        resultSearch = _.find(stakeholders[keySH].roles, (rol, key) => {
          return _.find(rol, (gc, keyGC) => {
            return keyGC == filters.groupCompany
          });
        });
      } else if (filters.rol && filters.groupCompany) {
        resultSearch = _.find(stakeholders[keySH].roles, (roles, key) => {
          if (key === filters.rol) {
            return _.find(roles, (gc, keyGC) => {
              return keyGC === filters.groupCompany
            });
          }
          return false
        });
      }
      return (!!resultSearch)
    }
    //Default is not suggested
    return false
  }

  const isStakeholderUnavailable = (data_selected, data_suggested, filters) => {
    //If this is selected then it isn't unvailable
    if (data_selected) return false;
    //If this is suggested then it isn't unvailable
    if (data_suggested) return false;
    //If my parent is selected and not select other filters then it is selected
    if (filters.rol || filters.groupCompany) return true
    //if the groupCompany filtet exists then it is unvailable
    return !!(filters.stakeholder);
  }

  const isStakeholderBlockLines = (data_selected, data_suggested, filters) => {
    if (data_selected) return false
    return !!filters.stakeholder;
  }

  const setDataStatus = () => {
    let totalsGroupCompanies = {};
    let totalsRoles = {};
    let newDataPropsGroupCompany = {};
    let newDataPropsRol = {};
    let newDataPropsStakeHolder = {};
    _.forEach(groupCompanies, (gc, keyGC) => {
      const data_selected = isGroupCompanySelected(keyGC, filters);
      const data_suggested = isGroupCompanySuggested(keyGC, filters);
      const data_unavailable = isGroupCompanyUnavailable(keyGC, data_selected, data_suggested, filters);
      const data_blockLines = isGroupCompanyBlockLines(data_selected, data_suggested, filters);
      newDataPropsGroupCompany[keyGC] = {
        "data-selected": data_selected,
        "data-suggested": data_suggested,
        "data-unavailable": data_unavailable,
        "data-visible": true,
        "data-blockLines": data_blockLines
      }
      if (!totalsGroupCompanies[keyGC]) {
        totalsGroupCompanies[keyGC] = getTotalsByGroupCompany(gc)
      }
    });
    _.forEach(roles, (rol, keyRol) => {
      const data_selected = isRolSelected(rol.id, filters);
      const data_suggested = isRolSuggested(rol.id, filters);
      const data_unavailable = isRolUnavailable(rol.id, data_selected, data_suggested, filters);
      const data_blockLines = isRolBlockLines(data_selected, data_suggested, filters);
      newDataPropsRol[rol.id] = {
        "data-selected": data_selected,
        "data-suggested": data_suggested,
        "data-unavailable": data_unavailable,
        "data-visible": true,
        "data-blockLines": data_blockLines
      }
      if (!totalsRoles[rol.id]) {
        totalsRoles[rol.id] = getTotalsByRol(rol)
      }
    });
    _.forEach(stakeholders, (sh, keySH) => {
      const data_selected = isStakeholderSelected(keySH, filters);
      const data_suggested = isStakeholderSuggested(keySH, filters);
      const data_unavailable = isStakeholderUnavailable(data_selected, data_suggested, filters);
      const data_visible = isStakeholderVisible(keySH, filters);
      const data_blockLines = isStakeholderBlockLines(data_selected, data_suggested, filters);
      newDataPropsStakeHolder[keySH] = {
        "data-selected": data_selected,
        "data-suggested": data_suggested,
        "data-unavailable": data_unavailable,
        "data-visible": data_visible,
        "data-blockLines": data_blockLines
      }
    });
    setDataPropsGroupCompany(newDataPropsGroupCompany)
    setDataPropsRol(newDataPropsRol)
    setDataPropsStakeholder(newDataPropsStakeHolder)
    setTotals({
      groupCompanies: totalsGroupCompanies,
      roles: totalsRoles
    });
  }

  useEffect(() => {
    setDataStatus()
  }, [
    JSON.stringify(filters),
    JSON.stringify(groupCompanies),
    JSON.stringify(stakeholders),
    JSON.stringify(roles)
  ]);

  return { setDataStatus, dataPropsGroupCompany, dataPropsRol, dataPropsStakeholder, totals };
  
}
export default useMapDataCards;
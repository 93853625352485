import React, {useEffect, useState} from 'react';
import _ from "lodash";
import PropTypes from 'prop-types';


import FactoryChatFormConfig from './FactoryChatFormConfig.js';


const FactoryChatForm = ({ formKey, propsForm, handleSubmit, initialValues, configForm }) => {

  const [initValues, setInitValues] = useState({});
  useEffect(() => {
    setInitValues(initialValues ? initialValues : {});
  },[initialValues])
  const getForm = () => {
    const formConfig =    _.get( configForm || FactoryChatFormConfig, formKey);
    
    if (formConfig) {
      return formConfig.getForm({ initialValues: initialValues, propsForm, onSubmit: (values) => { handleSubmit(values, formKey) } })
    }
    return <div> Form not found</div>
  }

  return (
    <React.Fragment>
      {getForm()}
    </React.Fragment>
  )
}

FactoryChatForm.propTypes = {
  formKey: PropTypes.string.isRequired, /* Key to identify form*/
  handleSubmit: PropTypes.func.isRequired, /* Handle submit options*/
  initialValues: PropTypes.object

};
export default FactoryChatForm;
import { createMachine } from 'xstate';

const machine =
  createMachine({
    id: 'NomineeService',
    initial: 'initialData',
    states: {
      initialData: {
        invoke: {
          src: 'initialData',
          id: 'initialData',
          onDone: [
            {
              target: 'AddNomineeAuthorisedSignatoryStep',
              actions: ['setContextAfterInvoke']
            }
          ],
        }
      },
      AddNomineeAuthorisedSignatoryStep: {
        on: {
          YES: {
            target: 'WhoIsSecondAuthorisedSignatoryFormStep',
            actions: ['setContextAfterStep']
          },
          NO: {
            target: 'WhoIsGoingToBeYourAuthorisedSignatoryFormStep',
            actions: ['setContextAfterStep']
          },
          CANCEL:{
            target: 'cancel'
          }
        }
      },
      WhoIsSecondAuthorisedSignatoryFormStep: {
        on: {
          PREV: {
            target: 'AddNomineeAuthorisedSignatoryStep',
            actions: ['setContextAfterStep']
          },
          NEXT: {
            target: 'EvaluateWhoIsSecondAuthorisedSignatoryCond',
            actions: ['setContextAfterStep']
          },
          CANCEL:{
            target: 'cancel'
          }
        }
      },
      EvaluateWhoIsSecondAuthorisedSignatoryCond: {
        always: [
          {
            cond: 'isNomineeThirdParty',
            target: 'ThirdPartyWithNomineeFormStep'
          },
          {
            target: 'HowDidTheyEnterFormStep'
          },
        ]
      },
      HowDidTheyEnterFormStep: {
        on: {
          PREV: {
            target: 'WhoIsSecondAuthorisedSignatoryFormStep',
            actions: ['setContextAfterStep']
          },
          NEXT: {
            target: 'SaveHowDidTheyEnterFormStep',
            actions: ['setContextAfterStep']
          },
          CANCEL:{
            target: 'cancel'
          }
        }
      },
      ThirdPartyWithNomineeFormStep: {
        on: {
          PREV: {
            target: 'WhoIsSecondAuthorisedSignatoryFormStep',
            actions: ['setContextAfterStep']
          },
          NEXT: {
            target: 'SaveThirdPartyWithNomineeFormStep',
            actions: ['setContextAfterStep']
          },
          CANCEL:{
            target: 'cancel'
          }
        }
      },
  WhoIsGoingToBeYourAuthorisedSignatoryFormStep: {
    on: {
      PREV: {
        target: 'AddNomineeAuthorisedSignatoryStep',
        actions: ['setContextAfterStep']
      },
      NEXT: {
        target: 'EvaluateCond',
        actions: ['setContextAfterStep']
      },
      CANCEL:{
        target: 'cancel'
      }
    }
  },
  EvaluateCond: {
    always: [
      {
        cond: 'isThirdParty',
        target: 'AddThirdPartyFormStep'
      },
      {
        target: 'SaveWhoIsGoingToBeYourAuthorisedSignatoryFormStep'
      },
    ]
  },
  AddThirdPartyFormStep: {
    on: {
      PREV: {
        target: 'WhoIsGoingToBeYourAuthorisedSignatoryFormStep',
        actions: ['setContextAfterStep']
      },
      NEXT: {
        target: 'SaveAddThirdPartyFormStep',
        actions: ['setContextAfterStep']
      },
      CANCEL:{
        target: 'cancel'
      }
    }
  },

  SaveHowDidTheyEnterFormStep: {
    invoke: {
      src: 'SaveHowDidTheyEnterFormStep',
      id: 'SaveHowDidTheyEnterFormStep',
      onDone: [
        {
          target: 'finish',
        }
      ],
    }
  },
  SaveThirdPartyWithNomineeFormStep: {
    invoke: {
      src: 'SaveThirdPartyWithNomineeFormStep',
      id: 'SaveThirdPartyWithNomineeFormStep',
      onDone: [
        {
          target: 'finish',
        }
      ],
    }
  },
  SaveWhoIsGoingToBeYourAuthorisedSignatoryFormStep: {
    invoke: {
      src: 'SaveWhoIsGoingToBeYourAuthorisedSignatoryFormStep',
      id: 'SaveWhoIsGoingToBeYourAuthorisedSignatoryFormStep',
      onDone: [
        {
          target: 'finish',
        }
      ],
    }
  },
  SaveAddThirdPartyFormStep: {
    invoke: {
      src: 'SaveAddThirdPartyFormStep',
      id: 'SaveAddThirdPartyFormStep',
      onDone: [
        {
          target: 'finish',
        }
      ],
    }
  },
  // SaveStep: {
  //     invoke: {
  //       src: 'SaveStep',
  //       id: 'SaveStep',
  //       onDone: [
  //         {
  //           target: 'finish',
  //         }
  //       ],
  //     }
  //   },
  previousMachine:{ 
    entry: 'previousMachine', 
    type: 'final' 
   }, 
  cancel: {
    entry: 'cancelMachine',
    type: 'final'
  },
  finish: {
    entry: 'stopMachine',
    type: 'final'
  }
    }
  });
 
export default machine;

import _ from 'lodash';
import React from 'react';
import { useDispatch } from "react-redux";
import Button from "../../../../../generic/components/Buttons/Button";
import useMachine from '../../../../../generic/context/MachineContext/useMachine';
import useModal from "../../../../../generic/hooks/useModal";
import useTranslate from '../../../../../generic/hooks/useTranslate';
import { clearAssistatText } from "../../../../../generic/store/action";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import FormStep1 from '../../forms/formStep1';

/**
 * Step to have topco in appove jurisdiction
 * @param {*} param0
 * @returns
 */
function Step1({ openParam }) {

  const { next, context, prev } = useMachine();
  const { translate } = useTranslate()
  const dispatch = useDispatch();
  const { closeModal: onClose } = useModal();

  const handleCancel = (values) => {
    dispatch(clearAssistatText())
    onClose();
  }

  const handleCompleteSubmit = async (values) => {
    next(values)
  }

  const handlePrev = () => {
    prev()
  }

  return (
    <FormStep1
      initialValues={_.get(context, 'optionTopCo', {})}
      handlePrev={handlePrev}
      key={"step1"}
      buttons={{
        cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
      }}
      onCompleteSubmit={handleCompleteSubmit}
      propsTemplate={{
        title: "Tell us about your topco",
        subTitle: "Select your topco",
      }}
      Template={TemplateWizard}
    />
  );
}

export default Step1;

import _ from 'lodash';
import { createMachine } from "xstate";
import { assign } from 'xstate';
const ACTIONS = {
  FINISH: {
    key: "final",
  },
  CLOSE: {
    key: "close",
  },
  SET_GLOBAL: {
    key: "setGlobal",
    action: assign((context: any) => {
      const globalData = _.get(context, 'globalData', []);
      globalData.push(context.data);
      return {
        ...context,
        data: {},
        numbersOfIteration: ++context.numbersOfIteration,
        globalData: globalData
      }
    })
  },
  SET_CONTEXT: {
    key: "setContext",
    action: assign((context, { payload }) => {
      const { data } = context;
      const { idState, values } = payload;
      const addContext = {
        ...context,
        data: {
          ...data,
          [idState]: values
        }
      };
      return addContext;
    })
  },
  SET_GLOBAL_VALUES: {
    key: "setGlobalValues",
    action: assign((context: any) => {
      const globalValues = _.get(context, 'globalValues', []);
      globalValues.push(context.data);
      return {
        ...context,
        globalValues: globalValues
      }
    })
  },
}
let globalOnComplete = null;
interface OptionsI{
  guards?:{[key:string]:()=>boolean}
  [key:string]:any
}
export default class Machine {
  machineDefinition: any;
  options: OptionsI;
  machine: object;
  steps: any;

  constructor(machineDefinition: any, options: any, steps: any) {
    this.options = options;
    this.machineDefinition = machineDefinition;
    this.steps = steps;

  }
  generateMachine(initialData: any, onComplete: any,onClose:any,actions:any = [] ) {
    globalOnComplete = onComplete;
    _.set(this.options, `actions.${ACTIONS.SET_CONTEXT.key}`, ACTIONS.SET_CONTEXT.action);
    _.set(this.options, `actions.${ACTIONS.SET_GLOBAL.key}`, ACTIONS.SET_GLOBAL.action);
    _.set(this.options, `actions.${ACTIONS.SET_GLOBAL_VALUES.key}`, ACTIONS.SET_GLOBAL_VALUES.action);
    _.set(this.options, `actions.${ACTIONS.CLOSE.key}`, (context) => {
      onClose(context)
    });
    _.set(this.options, `actions.${ACTIONS.FINISH.key}`, (context) => {
      onComplete(context)
    });
    _.map(actions,(callback)=>{
      const key= callback.key;
      const func= callback.action;
      _.set(this.options, `actions.${key}`, () => { 
        func() })
    })
    _.set(this.machineDefinition, "context", { numbersOfIteration: 0, paramsData:this.getParamsData(initialData),globalData: [], data: this.getInitialValues(initialData) });
    this.machine = createMachine(this.machineDefinition, this.options);
  }
  getParamsData(initialData: any) {
    if (_.get(initialData,"id")){
      return {id:_.get(initialData,"id")}
    }
    return {}
  }
  getInitialValues(initialData: any) {
    return initialData;
  }
  getStep(key: string) {
    return _.get(this.steps, key, 'div');
  }
  getMachine(key: string) {
    return this.machine
  }

}

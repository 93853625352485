import CONSULTANT from '../consultant/langs/us';
import INVESTOR from '../investor/langs/us';
import ADVISOR from '../advisor/langs/us';
import DIRECTOR from '../director/langs/us';
import DIRECTOR_V2 from '../DirectorV2Modal/langs/us';
import DIRECTOR_DELAWARE from '../DirectorDelawareModal/langs/us';
import SHAREHOLDER_V2 from '../ShareholderV2Modal/langs/us';
import SHAREHOLDER_DELAWARE from '../ShareholderDelawareModal/langs/us';
import FOUNDER from '../founder/langs/us'
import EMPLOYEE from '../employee/langs/us'
import THIRD_PARTY from '../thirdParty/langs/us'
import SHAREHOLDER from '../shareholder/langs'
export default {
    LABEL_NAME:'Full legal name',
    WIZARD_ADD_INDIVIDUAL_OR_COMPANY_TITLE: "Are they an individual or a company?",
    WIZARD_INDIVIDUAL_TITLE: "Add their basic info",
    CONSULTANT,
    INVESTOR,
    ADVISOR,
    DIRECTOR,
    DIRECTOR_V2,
    DIRECTOR_DELAWARE,
    SHAREHOLDER_V2,
    SHAREHOLDER_DELAWARE,
    FOUNDER,
    EMPLOYEE,
    THIRD_PARTY,
    SHAREHOLDER:SHAREHOLDER.US
}

import React, { Component } from 'react';
import { Field } from "formik";
import _ from 'lodash';
import moment from "moment";
import classes from "../Styles.module.scss";
import ClaraSelect from "../../../../../../../v1/components/Form/ClaraSelect/ClaraSelect";
import { ClaraRadioWithButton, TextAreaInput, TextInput, TextType } from '../../../../../../../v1/components';
import Checkbox from "../../../../../../../v1/containers/Forms/Inputs/Checkbox";
import FormikHelper from "../../../../../../../v1/utils/formikHelper";
import ClaraUpload from "../../../../../../../v1/components/Form/ClaraUpload/ClaraUpload";
import ClaraCountrySelector from "../../../../../../../v1/components/Form/ClaraCountrySelector/ClaraCountrySelector";
import ClaraCurrencySelector from "../../../../../../../v1/components/Form/ClaraCurrencySelector/ClaraCurrencySelector";
import AuthorisedSignatories from "../../../../../../inputs/AuthorizedSignatories/AuthorizedSignatories";
import BankAccountSelect from "../../../../../../inputs/BankAccountsSelect";
import Line from "../../../../../../generic/Line/Line";
import CheckBox from "../../../../../../../components/inputs/Checkbox/Checkbox"
import {
	groupCompanyRecipientNotice, stakeholderAuthorizedSignatory, stakeholderRecipientNotice
} from '../../../../../../../v1/containers/Forms/generateDocumentForm/FormsGenerate/helper';

class CLNAgreementDoc extends Component {

    constructor(props) {
        super(props);
        this.state = {
            typeSelected: undefined,
            investorSelected: undefined,
            groupCompanySelected: undefined,
            authorizedSelected: undefined,
            authorizedSignatories: [],
            authorizedSignatoriesList: [],
            documentTypes: [],
            documentTypesList: [],
            dynamicParams: [],
            date: undefined,
            expirationDate: undefined,
            showBankInfo:false
        }
    };
    // componentDidMount(){
    //     console.log(this.props.initialValues)
    //     this.props.setFieldValue('terms.bankInfo.id',this.props.initialValues.terms.bankInfo.id)
    // }

    componentWillMount() {
        if (this.props.values) {
            const values = this.props.values.values ? this.props.values.values : this.props.values;
            const expirationDate = this.getDateForPickerFromData(values.expirationDate);
            this.setState({
                typeSelected: values.documentType,
                investorSelected: values.stakeholder,
                groupCompanySelected: values.company,
                expirationDate,
                authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.company).map(as => ({
                    id: as.id,
                    label: as.label
                }))
            });


        }

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.values) {
            const values = nextProps.values.values ? nextProps.values.values : nextProps.values;
            const expirationDate = this.getDateForPickerFromData(values.expirationDate);
            this.setState({
                expirationDate,
                authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.company)
                    .map(as => ({
                        id: as.id,
                        label: as.label
                    }))
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.values) {
            const values = prevProps.values.values ? prevProps.values.values : prevProps.values;
        }
    }

    getDynamicParamsByType(documentType) {
        const type = this.props.documentTypes.filter(agrType => agrType.code === documentType)[0] || {};
        return type.fields || [];
    }

    getGroupCompanies = () => {
        let authorizedSignatories = this.props.authorizedSignatories;
        let groupCompanies = this.props.groupCompanies;
        let companies = [];
        if (groupCompanies && authorizedSignatories) {
            companies = _.filter(groupCompanies, (groupCompany) => {
                let fined = _.filter(authorizedSignatories, (authorized) => {
                    if (groupCompany.authorizedSignatories) {
                        return groupCompany.authorizedSignatories.some(au => au.stakeholder.id === authorized.id);
                    }
                    return false;
                });
                if (fined) {
                    return true;
                }
                return false;
            });
        }
        return companies;
    };

    selectGroupCompany = (gcId, extraParams, form) => {
        const groupCompany = this.findGroupCompany(gcId);

        // this is for a weird behaviour when hit edit button after preview a document
        if (this.state.groupCompanySelected && groupCompany) {
            return null;
        }

        this.setState({
            groupCompanySelected: groupCompany
        });

        if (groupCompany) {

            if (!groupCompany.registeredAddress) {
                groupCompany.registeredAddress = {}
            }

            let authorizedSignatories = _.get(form, 'values.company.authorizedSignatories', []);

            form.setValues({
                ...form.values,
                company: {
                    id: gcId,
                    name: groupCompany.name || '',
                    country: _.get(groupCompany, 'country.code', null) || _.get(groupCompany, 'country', null),
                    jurisdiction: groupCompany.jurisdiction,
                    registeredNumber: groupCompany.registeredNumber || '',
                    type: groupCompany.type || '',
                    registeredAddress: {
                        ...groupCompany.registeredAddress, country: _.get(groupCompany, 'registeredAddress.country.code', null) || _.get(groupCompany, 'registeredAddress.country', null)
                    },
                    authorizedSignatories: authorizedSignatories,
					recipientNotice: groupCompanyRecipientNotice(form.values.company, groupCompany)
                }
            });

            this.setState({
                groupCompanySelected: groupCompany,
            })
        } else {
            form.setValues({
                ...form.values,
                company: {}
            });
        }
    };

    findGroupCompany(gcId) {
        return this.props.groupCompaniesList.find(gc => gc.id === gcId);
    };

    selectInvestor = (investor, extraParams, form, cb) => {
        const stakeholder = this.findStakeholder(investor);

        this.setState({
            investorSelected: stakeholder,
        });

        if (stakeholder) {
            let authorizedSignatory = {};

            if (!stakeholder.address) {
                _.set(stakeholder,'address', {})
            }

            const _investor = !_.isEmpty(form.values.investor) ? form.values.investor : stakeholder;

            if (stakeholder.isAnEntity) {
				authorizedSignatory = stakeholderAuthorizedSignatory(_investor);
                // authorizedSignatory = {
                //     fullName: typeof _investor.authorizedSignatory === 'object'
                //         ? _.get(_investor, 'authorizedSignatory.fullName', '')
                //         : _.get(_investor, 'authorizedSignatory', ''),
                //     email: _investor.email || ''
                // }
            }

            form.setValues({
                ...form.values,
                investor: {
                    id: investor,
                    fullName: _investor.fullName || '',
                    email: _investor.email || '',
                    passportNumber: _investor.passportNumber || '',
                    registeredNumber: _investor.registeredNumber || '',
                    nationality: _.get(_investor, 'nationality.code', null) || _.get(_investor, 'nationality', null),
                    jurisdiction: _investor.jurisdiction,
                    otherJurisdiction: _.get(_investor, 'otherJurisdiction', ''),
                    type: _investor.type || '',
                    address: { ..._investor.address, country: _.get(_investor, 'address.country.code', null) || _.get(_investor, 'address.country', null) },
                    isAnEntity: _investor.isAnEntity,
                    authorizedSignatory: authorizedSignatory,
					recipientNotice: stakeholderRecipientNotice(form.values.investor, stakeholder)
                }
            });
        } else {
            form.setValues({
                ...form.values,
                investor: {}
            });
        }
    };

    findStakeholder(roleId) {
        return this.props.stakeholderList.find(stakeholder => stakeholder.id === roleId)
    };

    findStakeholderAuthorized(roleId) {
        return this.props.stakeholderList.filter(stakeholder => {
            return stakeholder.roles.some(role => role.stakeholder.id === roleId);
        })[0] || null;
    };

    filterAuthorizedByGroupCompany(groupCompany) {
        if (groupCompany) {
            let gc = this.findGroupCompany(groupCompany.id);
            return this.props.authorizedSignatories.filter(authorized => {
                return authorized.groupCompanyId === gc.id
            });
        }
        return [];
    }

    selectAuthorized = (value, form, field, replace) => {
        const stakeholder = this.props.authorizedSignatories.find(e => e.id === value);
        const email = stakeholder ? stakeholder.email : '';
        replace(0, { id: value, email })
    };

    handleDateChange = (e, field, form) => {
        const date = moment(e).format("YYYY-MM-DD");

        FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

        this.setState({
            date: e
        })
    };

    fieldHasError = (form, field) => {
        return FormikHelper.returnErrorsForValidation(field.name, form.errors);
    }

    renderRecipientNotice(fieldName, isAnEntity) {
        const { tooltips = {} } = this.props;

        if (isAnEntity) {
            return (
                <React.Fragment>

                    <Field name={`${fieldName}.recipientNotice.fullName`}>
                        {({ field, form }) => (
                            <TextInput
                                classNameInput={{ root: classes.ContainerRow }}
                                required={true}
                                type='text'
                                label='Recipient for notices '
                                field={field}
                                form={form}
                                placeholder={"Recipient's name"}
                                tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices"]}
                            />
                        )}
                    </Field>
                    <div className={`${classes.Select}`}>
                        <Field name={`${fieldName}.recipientNotice.address.country`}>
                            {({ field, form }) => (
                                <ClaraCountrySelector
                                    isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                    jurisdictionsEnabled={false}
                                    countryField={`${fieldName}.recipientNotice.address.country`}
                                    label={"Recipient's address "}
                                    setFieldValue={form.setFieldValue}
                                    initialValues={{ country: _.get(form.values, `${fieldName}.recipientNotice.address.country`, null) }}
                                    returnCountryAsString={true}
                                    additionalStyle={'ClaraCountrySelectorModal'}
                                    tooltips={{
                                        country: tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Country"]
                                    }}
                                />
                            )}
                        </Field>
                    </div>
                    <Field name={`${fieldName}.recipientNotice.address.street`}>
                        {({ field, form }) => (
                            <div className={classes.ContainerRow}>
                                <TextAreaInput
                                    type='text'
                                    label={' '}
                                    field={field}
                                    form={form}
                                    placeholder={"Address"}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Address"]}
                                />
                            </div>)}
                    </Field>
                    <Field name={`${fieldName}.recipientNotice.address.city`}>
                        {({ field, form }) => (
                            <TextInput
                                type='text'
                                classNameInput={{ root: classes.ContainerRow }}
                                field={field}
                                form={form}
                                placeholder={"City"}
                                label={' '}
                                tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - City"]}
                            />
                        )}
                    </Field>
                    <Field name={`${fieldName}.recipientNotice.address.state`}>
                        {({ field, form }) => (
                            <TextInput
                                type='text'
                                field={field}
                                classNameInput={{ root: classes.ContainerRow }}
                                form={form}
                                placeholder={"State / Region"}
                                label={' '}
                                tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - State"]}
                            />
                        )}
                    </Field>
                    <Field name={`${fieldName}.recipientNotice.address.zipCode`}>
                        {({ field, form }) => (
                            <TextInput
                                classNameInput={{ root: classes.ContainerRow }}
                                type='text'
                                field={field} form={form}
                                label={' '}
                                placeholder={"Post code"}
                                tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Post Code"]}
                            />

                        )}
                    </Field>
                    <Field name={`${fieldName}.recipientNotice.email`}>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='email'
                                classNameInput={{ root: classes.ContainerRow }}
                                label="Recipient's email address "
                                field={field}
                                form={form}
                                placeholder={"Email address"}
                                tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Email Address"]}
                            />
                        )}
                    </Field>

                </React.Fragment>
            )
        }
    };

    renderInvestorAuthorizedSignatory(field) {
        const { tooltips = {} } = this.props;

        if (this.state.investorSelected.isAnEntity) {
            return (
                <React.Fragment>
                    <Field name='investor.authorizedSignatory.fullName'>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                classNameInput={{ root: classes.ContainerRow }}
                                type='text'
                                label='Corporate signatory '
                                field={field}
                                form={form}
                                placeholder={"Corporate signatory"}
                                tooltip={tooltips["Authorised Signatory"]}
                            />
                        )}
                    </Field>
                    <Field name='investor.authorizedSignatory.email'>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='email'
                                classNameInput={{ root: classes.ContainerRow }}
                                label="Corporate signatory's email address "
                                field={field}
                                form={form}
                                onChange={(e) => this.updateEmailValue(e, form)}
                                placeholder={"Email address"}
                                tooltip={tooltips['Authorised Signatory Email']}
                            />
                        )}
                    </Field>
                </React.Fragment>
            )
        }
    };

    updateEmailValue(e, form) {
        if (this.state.investorSelected.isAnEntity) {
            form.setFieldValue('investor.authorizedSignatory.email', e.target.value, false)
        }
    }

    getDateForPickerFromData(date) {
        if (!date) return undefined;
        return moment(date).toDate();
    }

    handleChange(e, field, form) {

        const date = e === null ? null : moment(e).format("YYYY-MM-DD");

        FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

        this.setState({
            [field.name]: e
        })
    }

    getAuthorizedSignatoriesList = (index, form) => {
        if (form.values && form.values.company) {
            return this.filterAuthorizedByGroupCompany(form.values.company).map(as => ({
                id: as.id,
                label: as.label
            }))
        } else {
            return [];
        }
    };

    getLabel = (key) => {
        switch (key) {
            case "fullName":
                if (this.state.investorSelected.isAnEntity) {
                    return "Company name";
                } else {
                    return "Name";
                }
            case "country":
                if (this.state.investorSelected.isAnEntity) {
                    return "Country of incorporation";
                } else {
                    return "Nationality";
                }
            case "address":
                if (this.state.investorSelected.isAnEntity) {
                    return "Registered address";
                } else {
                    return "Residential address";
                }
            case "passportOrRegistered":
                if (this.state.investorSelected.isAnEntity) {
                    return "Company number";
                } else {
                    return "Passport number";
                }
        }
    };

    getField = (key) => {
        switch (key) {
            case "passportOrRegistered":
                if (this.state.investorSelected.isAnEntity) {
                    return "investor.registeredNumber";
                } else {
                    return "investor.passportNumber";
                }
        }
    };

    getTooltip = (key, fieldName) => {
        switch (key) {
            case "fullName":
                if (this.state.investorSelected.isAnEntity) {
                    return "Investor Company's Name";
                } else {
                    return "Investor's Name";
                }
            case "passportOrRegistered":
                if (this.state.investorSelected.isAnEntity) {
                    return "Company Number";
                } else {
                    return "Passport Number";
                }
            case "country":
                if (this.state.investorSelected.isAnEntity) {
                    return "Country";
                } else {
                    return "Nationality";
                }
            case "addressLine":
                if (this.state.investorSelected.isAnEntity) {
                    return "Registered Address - Address";
                } else {
                    return "Residential Address - Address";
                }
            case "addressCity":
                if (this.state.investorSelected.isAnEntity) {
                    return "Registered Address - City";
                } else {
                    return "Residential Address - City";
                }
            case "addressState":
                if (this.state.investorSelected.isAnEntity) {
                    return "Registered Address - State";
                } else {
                    return "Residential Address - State";
                }
            case "addressCountry":
                if (this.state.investorSelected.isAnEntity) {
                    return "Registered Address - Country";
                } else {
                    return "Residential Address - Country";
                }
            case "addressPostCode":
                if (this.state.investorSelected.isAnEntity) {
                    return "Registered Address - Post Code";
                } else {
                    return "Residential Address - Post Code";
                }
            case "recipientNotice":
                if (fieldName === 'company') {
                    return "Company's";
                } else {
                    return "Investor's";
                }
        }
    };

    getPlaceholder = (key) => {
        switch (key) {
            case "passportOrRegistered":
                if (this.state.investorSelected.isAnEntity) {
                    return "Company number";
                } else {
                    return "Passport number";
                }
        }
    };

    getCurrencyLabel = (form) => {
        return _.get(form, 'values.terms.currency', '');
    };

    renderBankInfoFields() {
        const { tooltips = {} } = this.props

        return (
            <React.Fragment>
                            <Field name='terms.bankInfo.code'>
                                {({ field, form }) => (
                                    <TextInput
                                        required={true}
                                        type='text'
                                        label='SWIFT/IBAN code'
                                        field={field}
                                        form={form}
                                        classNameInput={{ root: classes.ContainerRow }}
                                        placeholder={"SWIFT/IBAN code"}
                                    />
                                )}
                            </Field>
                <div className={` ${classes.Select}`}>
                    <Field name={'terms.bankInfo.country'}>
                        {({ field, form }) => (
                            <ClaraCountrySelector
                                isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                jurisdictionsEnabled={false}
                                returnCountryAsString={true}
                                countryField={'terms.bankInfo.country'}
                                label={'Country of bank account '}
                                setFieldValue={form.setFieldValue}
                                additionalStyle={'ClaraCountrySelectorModal'}
                                tooltips={{
                                    country: tooltips['Bank Country']
                                }}
                            />
                        )}
                    </Field>
                </div>
                    <Field name='terms.bankInfo.bankName'>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='text'
                                label='Bank name '
                                field={field}
                                form={form}
                                classNameInput={{ root: classes.ContainerRow }}
                                placeholder={"Bank name"}
                                tooltip={tooltips['Bank Name']}
                            />
                        )}
                    </Field>
                
                
                    <Field name='terms.bankInfo.ownerFullName'>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='text'
                                classNameInput={{ root: classes.ContainerRow }}
                                label='Name of bank account holder '
                                field={field}
                                form={form}
                                placeholder={"Account holder name"}
                                tooltip={tooltips['Account Name']}
                            />
                        )}
                    </Field>
                    <Field name='terms.bankInfo.number'>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='text'
                                classNameInput={{ root: classes.ContainerRow }}
                                label='Bank account number '
                                field={field}
                                form={form}
                                placeholder={"Account number"}
                                tooltip={tooltips['Account Number']}
                            />
                        )}
                    </Field>
                    <div className={classes.ContainerRow}>
                    <Field name='terms.bankInfo.additionalInfo'>
                        {({ field, form }) => (
                            <TextAreaInput
                                type='text'
                                label='Additional banking information '
                                field={field}
                                form={form}
                                placeholder={"Describe any additional banking information here"}
                                tooltip={tooltips['Bank Additional Information']}
                            />
                        )}
                    </Field>
                    </div>
            </React.Fragment>
        );
    };

    renderMaturityDateFields(form) {
        const { tooltips = {} } = this.props;
        if (!_.get(form, 'values.terms.maturityDateOption')) {
            return null
        }

        return (
            <React.Fragment>
                {
                    form.values.terms.maturityDateOption === 'months' ?
                        <Field name='terms.maturityMonths'>
                            {({ field, form }) => (
                                <TextInput
                                    classNameInput={{ root :classes.ContainerRow }}
                                    required={true}
                                    type='text'
                                    label='Maturity date '
                                    field={field}
                                    form={form}
                                    placeholder={"Months"}
                                    tooltip={tooltips['Maturity Months']}
                                />
                            )}
                        </Field>
                        :
                        <Field name='terms.maturityDate'>
                            {({ field, form }) => (
                                <TextInput
                                    required={true}
                                    field={field}
                                    form={form}
                                    type='date'
                                    classNameInput={{ input: classes.Calendars, root: classes.labelCalendar }}
                                    label={'Maturity date '}
                                    placeholder={"dd/mm/yyyy"}
                                    tooltip={tooltips['Maturity Date']}
                                />
                            )}
                        </Field>
                }
            </React.Fragment>
        );
    };

    getStakeholderAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add new corporate stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add more details later on the stakeholder's profile page.",
                placeholder: "Stakeholder's Name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: true,
                    // roles : [{
                    // 	role: "SHAREHOLDER",
                    // 	groupCompany: this.state.groupCompanySelected.id,
                    // }],
                },
                paramsReturn: 'isAnEntity'
            },
            {
                type: "stakeholder",
                label: "Add new Individual stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add more details later on the stakeholder's profile page.",
                placeholder: "Stakeholder's Name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: false,
                    // roles : [{
                    // 	role:"SHAREHOLDER",
                    // 	groupCompany: this.state.groupCompanySelected.id,
                    // }]
                },
                paramsReturn: 'isAnEntity'
            },
        ]
    };

    getCompanyAdds = () => {
        return [{
            type: "groupCompany",
            label: "Add new company",
            textWarning: "This will create a new group company for your startup. You can add or amend the details of this group company on its profile page.",
            placeholder: "Company Name",
            startupId: this.props.startup.id,
            params: null,
            paramsReturn: `
				name
				authorizedSignatories {
					id
					stakeholder {
						id
						email
						fullName
						roles {  id
        name
        groupCompany { id name}
        stakeholder { id } }
					}
				}
			`
        }]
    };

    getAuthorizedSignatoryAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add new individual stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add more details later from the stakeholder's profile page.",
                placeholder: "Enter Stakeholder's Name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: false,
                    roles: [{
                        role: "DIRECTOR",
                        groupCompany: this.state.groupCompanySelected.id,
                    }]
                }
            },
        ]
    };

    handleStakeholderCreate = (value, name, additionalValue, form) => {

        let { stakeholders, stakeholderList } = this.props;
        let newSh = { id: value.id, fullName: name, ...additionalValue };
        stakeholderList.push(newSh);

        stakeholders.push({ id: value.id, label: name });
        // this.setState({ groupCompaniesList, groupCompanies });

    };

    handleGroupCompanyCreate = (value, name, additionalValue, form) => {

        let { groupCompanies, groupCompaniesList } = this.props;
        let newGc = { id: value.id, name, ...additionalValue };
        groupCompaniesList.push(newGc);

        groupCompanies.push({ id: value.id, label: name });

    };

    handleAuthorizedSignatoryUpdate = (value) => {


    };

    render() {
        const isRequired = message => value => (!!value ? undefined : message);
        const { tooltips = {}, formType } = this.props;

        return (
            <React.Fragment>
                {formType === 'uploadToEdit' ? (<div>
                    <Field name={`file`}>
                        {({ field, form }) => (
                            <ClaraUpload
                                required={true}
                                name={`filepond`}
                                label='Upload your document '
                                field={field}
                                form={form}
                            />
                        )}
                    </Field>
                </div>) : null}
                <h1 className={classes.title}>
                    Edit Document
					</h1>
                <Field name='agreementName'>
                    {({ field, form }) => (
                        <TextInput
                            classNameInput={{ root: classes.ContainerRow }}
                            required={true}
                            type='text'
                            label='Document name '
                            field={field}
                            form={form}
                            placeholder={"Document Name"}
                            tooltip={tooltips['Document Name']}
                        />
                    )}
                </Field>
                <Line className={classes.line} />
                <div className={classes.ZoneTitle}>
                    <h1>{'Holder Name'}</h1>
                </div>
                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                    <Field name='investor.id'>
                        {({ field, form }) => (
                            <ClaraSelect
                                required={true}
                                field={field}
                                form={form}
                                label={"Select investor"}
                                adds={this.getStakeholderAdds()}
                                lists={this.props.stakeholders}
                                mode={"classic"}
                                callbacks={{
                                    createValues: this.handleStakeholderCreate,
                                    updateValues: this.selectInvestor
                                }}
                                placeholder={"Select from the list"}
                                tooltip={tooltips["Select Investor"]}
                            />

                        )}
                    </Field>
                </div>
                {
                    this.state.investorSelected ?
                        <React.Fragment>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                {
                                    this.state.investorSelected.isAnEntity ?
                                        <>
                                            <h1>Note Holder Company Details</h1>
                                            <p>Please verify or update the company's information below</p>
                                        </>
                                        :
                                        <>
                                            <h1>Individual Investor's Details</h1>
                                            <p>Please verify or update the investor's information below</p>
                                        </>
                                }
                            </div>

                            <Field name='investor.fullName'>
                                {({ field, form }) => (
                                    <TextInput
                                        classNameInput={{ root: classes.ContainerRow }}
                                        type='text'
                                        label={this.getLabel("fullName") + ' '}
                                        field={field}
                                        form={form}
                                        placeholder={'Name'}
                                        required={true}
                                        tooltip={tooltips[this.getTooltip('fullName')]}
                                    />
                                )}
                            </Field>
                            <div className={`${classes.Select}`}>
                                <Field name='investor.nationality'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={this.state.investorSelected.isAnEntity}
                                            countryField={'investor.nationality'}
                                            label={this.getLabel('country') + ''}
                                            jurisdictionField={'investor.jurisdiction'}
                                            jurisdictionLabel={'Jurisdiction '}
                                            returnCountryAsString={true}
                                            otherJurisdictionField={'investor.otherJurisdiction'}
                                            setFieldValue={form.setFieldValue}
                                            initialValues={{ jurisdiction: _.get(form.values, 'investor.jurisdiction') }}
                                            additionalStyle={'ClaraCountrySelectorModal'}
                                            tooltips={{
                                                country: tooltips[this.getTooltip('country')],
                                                jurisdiction: tooltips['Jurisdiction'],
                                                other: tooltips['Other Jurisdiction']
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            {
                                this.state.investorSelected.isAnEntity ?
                                    <React.Fragment>
                                        <Field name='investor.type'>
                                            {({ field, form }) => (
                                                <TextInput
                                                    classNameInput={{ root: classes.ContainerRow }}
                                                    required={true}
                                                    type='text'
                                                    label='Company type '
                                                    field={field}
                                                    form={form}
                                                    placeholder={'Company Type'}
                                                    tooltip={tooltips['Company Type']}
                                                />
                                            )}
                                        </Field>
                                    </React.Fragment>
                                    :
                                    null
                            }
                            <Field name={this.getField("passportOrRegistered")}>
                                {({ field, form }) => (
                                    <TextInput
                                        required={true}
                                        type='text'
                                        classNameInput={{ root: classes.ContainerRow }}
                                        label={this.getLabel("passportOrRegistered") + ' '}
                                        field={field}
                                        form={form}
                                        placeholder={this.getPlaceholder("passportOrRegistered")}
                                        tooltip={tooltips[this.getTooltip("passportOrRegistered")]}
                                    />
                                )}
                            </Field>
                            {
                                !this.state.investorSelected.isAnEntity ?
                                    <React.Fragment>
                                        <Field name='investor.email'>
                                            {({ field, form }) => (
                                                <TextInput
                                                    type='email'
                                                    classNameInput={{ root: classes.ContainerRow }}
                                                    label='Email address '
                                                    field={field}
                                                    form={form}
                                                    placeholder={'Email address'}
                                                    required={true}
                                                    tooltip={tooltips["Investor's Email Address"]}
                                                />
                                            )}
                                        </Field>
                                    </React.Fragment>
                                    :
                                    null
                            }
                            <div className={` ${classes.Select}`}>
                                <Field name='investor.address.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={false}
                                            countryField={'investor.address.country'}
                                            label={this.getLabel('address') + ''}
                                            setFieldValue={form.setFieldValue}
                                            returnCountryAsString={true}
                                            additionalStyle={'ClaraCountrySelectorModal'}
                                            tooltips={{
                                                country: tooltips[this.getTooltip('addressCountry')]
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='investor.address.street'>
                                    {({ field, form }) => (
                                        <TextAreaInput
                                            type='text'
                                            label={' '}
                                            field={field}
                                            form={form}
                                            placeholder={'Address'}
                                            tooltip={tooltips[this.getTooltip('addressLine')]}

                                        />
                                    )}
                                </Field>
                            </div>
                            <Field name='investor.address.city'>
                                {({ field, form }) => (
                                    <TextInput
                                        classNameInput={{ root: classes.ContainerRow }}
                                        type='text'
                                        label=' '
                                        field={field}
                                        form={form}
                                        placeholder={'City'}
                                        tooltip={tooltips[this.getTooltip('addressCity')]}
                                    />
                                )}
                            </Field>
                            <Field name='investor.address.state'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        label=' '
                                        classNameInput={{ root: classes.ContainerRow }}
                                        field={field}
                                        form={form}
                                        placeholder={'State / Region'}
                                        tooltip={tooltips[this.getTooltip('addressState')]}
                                    />
                                )}
                            </Field>
                            <Field name='investor.address.zipCode'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        label=' '
                                        classNameInput={{ root: classes.ContainerRow }}
                                        field={field}
                                        form={form}
                                        placeholder={"Post code"}
                                        tooltip={tooltips[this.getTooltip('addressPostCode')]}
                                    />
                                )}
                            </Field>
                            {this.renderRecipientNotice('investor', this.state.investorSelected.isAnEntity)}
                            {this.renderInvestorAuthorizedSignatory('investor')}
                        </React.Fragment>
                        :
                        null
                }
                <Line className={classes.line} />
                <div className={` ${classes.Select} ${classes.ContainerRow}`}>
                    <Field name='company.id'>
                        {({ field, form }) => (
                            <ClaraSelect
                                required={true}

                                field={field}
                                form={form}

                                label={"Select company "}
                                lists={this.getGroupCompanies()}
                                mode={"classic"}
                                callbacks={{
                                    createValues: this.handleGroupCompanyCreate,
                                    updateValues: this.selectGroupCompany
                                }}
                                placeholder={"Select from the list"}
                                tooltip={tooltips['Select Company']}
                            />
                        )}
                    </Field>
                </div>
                {
                    this.state.groupCompanySelected ?
                        <React.Fragment>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Company Details</h1>
                                <p>Please verify or update the company's information below</p>
                            </div>
                            <Field name='company.name'>
                                {({ field, form }) => (
                                    <TextInput
                                        classNameInput={{ root: classes.ContainerRow }}
                                        required={true}
                                        type='text'
                                        label='Company name '
                                        field={field}
                                        form={form}
                                        placeholder={"Company name"}
                                        tooltip={tooltips["Company's Name"]}
                                    />
                                )}
                            </Field>
                            <div className={`${classes.Select}`}>
                                <Field name='company.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={true}
                                            countryField={'company.country'}
                                            label={'Country of incorporation '}
                                            jurisdictionField={'company.jurisdiction'}
                                            returnCountryAsString={true}
                                            jurisdictionLabel={'Jurisdiction '}
                                            otherJurisdictionField={'company.otherJurisdiction'}
                                            setFieldValue={form.setFieldValue}
                                            initialValues={{ jurisdiction: _.get(form.values, 'company.jurisdiction') }}
                                            additionalStyle={'ClaraCountrySelectorModal'}
                                            tooltips={{
                                                country: tooltips["Company's Country"],
                                                jurisdiction: tooltips["Company's Jurisdiction"],
                                                other: tooltips['Other Jurisdiction']
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            {/*
                            <div className={classes.ContainerRow}>
                                <Field name='company.jurisdiction'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label='Jurisdiction: '
                                            field={field}
                                            form={form}
                                            placeholder={"Jurisdiction..."}
                                            tooltip={tooltips["Company's Jurisdiction"]}
                                        />
                                    )}
                                </Field>
                            </div>
							*/}
                            <Field name='company.type'>
                                {({ field, form }) => (
                                    <TextType
                                        classNameInput={{ root: classes.ContainerRow }}
                                        required={true}
                                        type='text'
                                        groupCompany={this.state.groupCompanySelected}
                                        label='Company type '
                                        field={field}
                                        form={form}
                                        placeholder={'Company type'}
                                        tooltip={tooltips["Company's Company Type"]}
                                    />
                                )}
                            </Field>
                            <Field name='company.registeredNumber'>
                                {({ field, form }) => (
                                    <TextInput
                                        classNameInput={{ root: classes.ContainerRow }}
                                        required={true}
                                        type='text'
                                        label='Company number '
                                        field={field}
                                        form={form}
                                        placeholder={'Company number'}
                                        tooltip={tooltips["Company's Company Number"]}
                                    />
                                )}
                            </Field>
                            <div className={` ${classes.Select}`}>
                                <Field name='company.registeredAddress.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={false}
                                            countryField={'company.registeredAddress.country'}
                                            label={'Registered address '}
                                            returnCountryAsString={true}
                                            setFieldValue={form.setFieldValue}
                                            additionalStyle={'ClaraCountrySelectorModal'}
                                            tooltips={{
                                                country: tooltips["Company's Registered Address - Country"]
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.registeredAddress.street'>
                                    {({ field, form }) => (
                                        <TextAreaInput
                                            type='text'
                                            label={' '}
                                            field={field}
                                            form={form}
                                            placeholder={"Address"}
                                            tooltip={tooltips["Company's Registered Address - Address"]}
                                        />
                                    )}
                                </Field></div>
                            <Field name='company.registeredAddress.city'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        label=' '
                                        classNameInput={{ root: classes.ContainerRow }}
                                        field={field}
                                        form={form}
                                        placeholder={'City'}
                                        tooltip={tooltips["Company's Registered Address - City"]}
                                    />
                                )}
                            </Field>
                            <Field name='company.registeredAddress.state'>
                                {({ field, form }) => (
                                    <TextInput
                                        classNameInput={{ root: classes.ContainerRow }}
                                        type='text'
                                        label=' '
                                        field={field}
                                        form={form}
                                        placeholder={'State / Region'}
                                        tooltip={tooltips["Company's Registered Address - State"]}
                                    />
                                )}
                            </Field>
                            <Field name='company.registeredAddress.zipCode'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        classNameInput={{ root: classes.ContainerRow }}
                                        label=' '
                                        field={field}
                                        form={form}
                                        placeholder={"Post code"}
                                        tooltip={tooltips["Company's Registered Address - Post Code"]}
                                    />
                                )}
                            </Field>
                            {this.renderRecipientNotice('company', true)}
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Corporate Signatories</h1>
                                <p>Please add all the signatories required to sign this document on behalf of the company.</p>
                            </div>
                            <Field name='company.authorizedSignatories_error'>
                                {({ field, form }) => (
                                    <React.Fragment>
                                        <AuthorisedSignatories
                                            list={this.props.stakeholderList}
                                            fieldArrayName={'company.authorizedSignatories'}
                                            idGroupCompany={this.state.groupCompanySelected.id}
                                            handleAddListStakeholder={this.handleAuthorizedSignatoryUpdate}
                                            modal={true}
                                            errorMessage={_.get(form.errors, "adviceAreasFieldName")}
                                        />
                                    </React.Fragment>
                                )}
                            </Field>
                        </React.Fragment>
                        :
                        null
                }

                {
                    this.state.investorSelected && this.state.groupCompanySelected ?
                        <React.Fragment>
                            <Line className={classes.line} />
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Discount</h1>
                                <p>The discount for the investor on the price paid by an investor in a future financing round.</p>
                            </div>
                            <Field name='terms.discountRate'>
                                {({ field, form }) => (
                                    <TextInput
                                        classNameInput={{ root: classes.ContainerRow }}
                                        required={true}
                                        type='text'
                                        label='Discount'
                                        field={field}
                                        form={form}
                                        placeholder={'e.g. 10, 20'}
                                        rightLabel={'%'}
                                        tooltip={tooltips["Discount"]}
                                    />
                                )}
                            </Field>
                            <Line className={classes.line} />
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Currency</h1>
                                <p>The currency in which the loan amount, valuation cap and equity financing amount will be quoted in the Convertible Loan Note.</p>
                            </div>
                            <div className={`${classes.ContainerRow} ${classes.Select}`}>
                                <Field name='terms.currency'>
                                    {({ field, form }) => (
                                        <ClaraCurrencySelector
                                            currencyField={'terms.currency'}
                                            setFieldValue={form.setFieldValue}
                                            type={'List'}
                                            placeholder={'Select from the list'}
                                            label={'Convertible loan note currency'}
                                            // initialValue={_.get(data,'transactionCurrency')}
                                            additionalStyle={'ClaraCurrencySelectorModal'}
                                            tooltips={tooltips['Currency']}
                                        />
                                    )}
                                </Field>
                            </div>
                            <Line className={classes.line} />
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Loan Amount</h1>
                                <p>The amount the investor is providing to the holding company.</p>
                            </div>
                            <Field name='terms.loanAmount'>
                                {({ field, form }) => (
                                    <TextInput
                                        required={true}
                                        type='text'
                                        label='Loan amount '
                                        field={field}
                                        classNameInput={{ root: classes.ContainerRow }}
                                        form={form}
                                        placeholder={"Amount"}
                                        leftLabel={this.getCurrencyLabel(form)}
                                        tooltip={tooltips["Loan Amount"]}
                                    />
                                )}
                            </Field>
                            <Line className={classes.line} />
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Interest Rate</h1>
                                <p>The interest which will apply on the loan amount.</p>
                            </div>
                            <div className={classes.alignWithInputDateButton}>
                                <Field name={'terms.applyInterestRate'}>
                                    {({ field, form }) => (
                                        <ClaraRadioWithButton
                                            required={true}
                                            label={'Will interest apply on the loan amount?'}
                                            field={field}
                                            form={form}
                                            changeParams={field.name}
                                            options={[{ value: true, label: 'Yes' }, { value: false, label: 'No' }]}
                                            tooltip={tooltips['Apply Interest Rate']}
                                        // onChange={(v) => { onChange(v,form) }}
                                        />
                                    )}
                                </Field>
                            </div>

                            <Field>
                                {({ field, form }) => (
                                    _.get(form, 'values.terms.applyInterestRate') ?
                                        <Field name='terms.interestRate'>
                                            {({ field, form }) => (
                                                <TextInput
                                                    classNameInput={{ root: classes.ContainerRow }}
                                                    required={true}
                                                    type='text'
                                                    label='Percentage '
                                                    field={field}
                                                    form={form}
                                                    placeholder={'e.g. 10, 20'}
                                                    leftLabel={'%'}
                                                    tooltip={tooltips["Interest Rate"]}
                                                />
                                            )}
                                        </Field>
                                        : null
                                )}
                            </Field>

                            <Line className={classes.line} />
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Maturity Date</h1>
                                <p>The date on which the topco must repay the loan if the Convertible Loan Note has not yet converted into shares.</p>
                            </div>
                            <div className={`${classes.ContainerRow} ${classes.Select}`}>
                                <Field name='terms.maturityDateOption'>
                                    {({ field, form }) => (
                                        <ClaraSelect
                                            required={true}
                                            label={'Maturity date period '}
                                            field={field}
                                            form={form}
                                            value={field.value}
                                            placeholder={"Select from the list"}
                                            lists={[
                                                { id: 'months', label: 'A number of months from agreement date' },
                                                { id: 'specificDate', label: 'A specific date' }
                                            ]}
                                            mode={"classic"}
                                            tooltip={tooltips['Maturity Option Date']}
                                        />
                                    )}
                                </Field>
                                <Field>
                                    {({ field, form }) => (
                                        this.renderMaturityDateFields(form)
                                    )}
                                </Field>
                            </div>
                            <Line className={classes.line} />
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Valuation Cap</h1>
                                <p>The maximum value of the topco at which the Convertible Loan Note may convert into shares.  <a onClick={() => window.open('https://academy.clara.co/article/7mNidrxi3rDFuAzCFER4i')}>Learn more...</a></p>
                            </div>
                            <Field name='terms.valuationAmount'>
                                {({ field, form }) => (
                                    <TextInput
                                        required={true}
                                        type='text'
                                        classNameInput={{ root: classes.ContainerRow }}
                                        label='Valuation cap '
                                        field={field}
                                        form={form}
                                        placeholder={"Amount"}
                                        leftLabel={this.getCurrencyLabel(form)}
                                        tooltip={tooltips["Valuation Amount"]}
                                    />
                                )}
                            </Field>
                            <Line className={classes.line} />
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Investor rights</h1>
                            </div>
                            <Field name='terms.investorRights'>
                                {({ field, form }) => (
                                    <div className={`${classes.labelGroup}`}>
                                    <label> </label>
                                            <CheckBox
                                                errorMessage={this.fieldHasError(form, field)}
                                                onChange={(value) => { form.setFieldValue(field.name, value); form.validateField(field.name) }}
                                                options={[
                                                    {
                                                        value: 'MFNRight',
                                                        label: 'Most Favoured Nation Right (“MFN”)',
                                                        paragraph:'This grants the investor the right to upgrade their rights under the Convertible Loan Note to any more favourable rights that are granted to other investors in future convertibles (e.g. a higher discount or lower valuation cap).'
                                                    },
                                                    {
                                                        value: 'participationRight',
                                                        label: 'Participation Right',
                                                        paragraph:"This grants the investor the right to invest additional money in your startup’s next <a href='https://help.clara.co/en/articles/6413107-what-is-an-equity-funding-round' target='_blank'>equity funding round</a> in an amount proportionate to what their percentage shareholding would be in the startup if converted."
                                                    },
                                                    {
                                                        value: 'informationRights',
                                                        label: 'Information Rights',
                                                        paragraph:'These rights require your startup to provide certain information to the investor about its business and financials (e.g. quarterly management accounts, annual audited accounts, right to inspect books and premises).'
                                                    }
                                                ]}
                                                values={field.value}
                                                className={{ root: classes.groupCheckBoxWizard, checkbox: classes.checkBoxWizard }}
                                            />
                               </div> )}
                            </Field>
                            <Line className={classes.line} />
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Company’s Bank Account Details</h1>
                                <p>The details of the bank account into which the investor will transfer the funds.</p>
                            </div>
                            <Field name='terms.bankInfo.id'>
                                {({ field, form }) => (
                            <BankAccountSelect
                            value={form.values?.terms?.bankInfo || this.props.initialValues?.terms?.bankInfo}
                            modal={true}
                            groupCompanyId={this.state.groupCompanySelected.id}
                            onChange={(value)=>{
                                if(value){
                                if(value === 'ADD'){
                                    form.setFieldValue('terms.bankInfo',{country:null,bankName:'',code:'',ownerFullName:'',number:'',additionalInfo:''})
                                     return this.setState({
                                    showBankInfo:true
                                })
                            }
                                form.setFieldValue('terms.bankInfo',{...value,country:value?.country?.code || value?.country})
                                this.setState({
                                    showBankInfo:!!value
                                })
                            }
                            }}
                            />
                                )}
                                </Field>
                              
                                        { this.state.showBankInfo &&
                                        this.renderBankInfoFields()
                                        }
                                                                    <Line className={classes.line} />

                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Place of Arbitration</h1>
                                <p>This contract’s dispute resolution mechanism uses arbitration under expedited ICC rules. Please select the place where you would like the arbitration to take place (please enter the name of the city and country and, if applicable, state or freezone), e.g. "London, United Kingdom" or "New York City, New York, United States of America” or "Dubai International Financial Centre, Dubai, United Arab Emirates”. Please note that the place of arbitration that you select can have a material impact on the proceedings and enforcement of the arbitration and arbitral award. Please seek legal advice.</p>

<Field name={`terms.placeOfArbitration`}>
    {({ field, form }) => (
        <TextInput
            required={true}
            type='text'
            label='Place of arbitration '
            field={field}
            classNameInput={{ root: classes.ContainerRow }}

            form={form}
            placeholder={"Place of Arbitration"}
        />
    )}
</Field>
                            </div>
                        </React.Fragment>
                        : null
                }
                {formType === 'uploadToEdit' ? <div>
                    <Field name='confirmSameData'>
                        {({ field, form }) => (
                            <Checkbox
                                className={classes.sameDataCheckbox}
                                field={field}
                                validate={isRequired('This field is required')}
                                form={form}
                                required={true}
                                label='All document details are verified and correct.'
                            />
                        )}
                    </Field>
                </div> : null}
            </React.Fragment>
        );
    }
}

export default CLNAgreementDoc;

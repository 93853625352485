import useMutation from '../../../../../../../hooks/custom/useMutation'
import useSession from "src/modules/session/hooks/useSession";


export const UPLOAD_BULK_DOCUMENTS = `
  mutation uploadBulkDocuments($startupId: ID!, $documents: [AgreementInputType]) {
    uploadBulkDocuments( startupId: $startupId, documents: $documents) {
      id
    }
  }
`;

const useUploadBulkDocuments
 = (props = {}) => {

  const [mutation] = useMutation(UPLOAD_BULK_DOCUMENTS, {},{...props});

  return {mutation}
}

export default useUploadBulkDocuments
import React, { Component } from "react";
import { connect } from "react-redux";
import { DeleteForm, Loading } from "../../index";
import ServerConnect from '../../../utils/ServerConnect';
import _ from "lodash";
import { deleteEquity as eliminate } from '../../../graphql/mutations/addAgreement';
import { Constants } from '../../../utils/constants'
import { goToPage } from "../../../../store/actions/page";

class EquityDeleteForm extends Component {
    state = {
        data: {},
        showLoading: false,
        options: [],
        typeEquity: {
            SHARE: "shares",
            OPTION: "shareOptions",
            CONVERTIBLE: "convertibles",
            WARRANT: "warrants"
        }
    }
    /* Particular data */
    getTitle() {
        const data = this.state.data;
        return `Delete this ${data.title}?`;
    }
    setOptions(data) {
        let options = [];
        options.push({ label: `Delete this ${data.title}?` });
        this.setState({
            options
        })
    }
    hasAllData() {
        return true;
    }
    /*  Call Server*/
    callServerToGetData = () => {
        this.showLoading(true);
        //Get data if exist this posibility
    };
    deleteEntity = () => {
        return new Promise((resolve, reject) => {
            if (this.state.data.holder) {
                // from table
                let { holder, type: equityType, id: equityId } = this.state.data;
                let ownerType = holder.__typename === "StakeHolder" ? "stakeholder" : "groupCompany"
                var data = {
                    startupId: this.props.session.startup.id,
                    equityId,
                    equityType,
                    ownerId: holder.id,
                    ownerType
                };
                this.filterEquityPosition(this.state.typeEquity[equityType], equityId)
            }
            else {
                // from view
                let { owner, type: equityType, id: equityId } = this.state.data

                var data = {
                    startupId: this.props.session.startup.id,
                    equityId,
                    equityType,
                    ownerId: owner.id,
                    ownerType: owner.type
                }
            }

            ServerConnect.graphQlMutation(eliminate, data).then((result) => {
                if (this.props.callbacks) {
                    this.props.callbacks.deleteEntity(data);
                }
                resolve();
            }).catch(err => {
                reject(err)
            });

        });
    }
    /* Change status */
    showLoading = (status) => {
        this.setState({
            showLoading: status
        });
    }
    // redirect 
    setRedirect = () => {
        let url = this.props.isGroupCompany ? Constants.PAGES.viewGroupCompany : Constants.PAGES.viewStakeholderIndividual;
        this.setState({
            url
        })
    }
    //filter EquityPsition of table when delete
    //revisar la funcionalidad
    filterEquityPosition = (type, id) => {
        const aux = _.cloneDeep(this.props.equityElements);
        aux[type] = _.filter(aux[type], equity => !_.eq(equity.id, id));
        this.props.setData(aux);
    }

    /* REac functions */
    componentWillMount() {
        if (this.hasAllData()) {
            this.setState({
                data: this.props.data
            }, () => {
                this.setOptions(this.props.data);
                this.setRedirect();
            })


        } else {
            this.callServerToGetData();
        }
    }


    render() {

        return (
            <Loading show={this.state.showLoading}>
                <DeleteForm show={true} handleClose={this.props.handleClose} deleteEntity={this.deleteEntity} showLoading={this.showLoading} title={this.getTitle()} options={this.state.options}></DeleteForm>
            </Loading>
        )
    }
}

const mapStateToProps = state => ({
    session: state.session
});
const mapDispatchToProps = (dispatch) => ({
    goToPage: (page, params) => {
        goToPage(dispatch, page, params)
    }

});
export default connect(mapStateToProps, mapDispatchToProps)(EquityDeleteForm)

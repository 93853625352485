export default {
    TITLE: 'Please confirm that you want to remove {{stakeholder}} as a founder',
    DESCRIPTION: 'This action cannot be undone',
    CHECKBOX: 'Remove as founder',
    
    UNABLE: {
        TITLE: 'It’s not possible to remove this founder title',
        DESCRIPTION: 'Here’s why:',
        REASON_1: 'You must have at least one founder in your startup',
    }
}
import * as React from "react";
import _ from 'lodash'
import AssignmentDoc from "../AssignmentDoc/AssignmentDoc";
import FounderDocs from "../../../../../../../v1/containers/Forms/generateDocumentForm/FormsGenerate/FounderDocs";
import SAFEAgreementDoc from '../SAFEAgreementDoc/SAFEAgreementDoc';
import CLNAgreementDoc from '../CLNAgreementDoc/CLNAgreementDoc';
import RestrivtiveCovenantsAgreementDoc from "../RestrivtiveCovenantsAgreementDoc/ResctrictiveConvenantsAgreementDoc";
import ShareIncentivePlanDoc from '../ShareIncentivePlanDoc/ShareIncentivePlanDoc'
import EmploymentAgreementDoc from "../EmploymentAgreementADGMDoc/EmploymentAgreementADGMDoc";
import FounderServicesAgreementDoc from "../FounderServicesAgreementDoc/FounderServicesAgreementDoc";
import GrantAgreementDoc
    from "../GrantAgreementDoc/GrantAgreementDoc";
import AdvisorAgreementDoc from "../AdvisorAgreementDoc/AdvisorAgreementDoc";
import ConsultantAgreementDoc from "../ConsultantAgreementDoc/ConsultantAgreementDoc";
import KISSAgreementDoc
    from "../../../../../../../v1/containers/Forms/generateDocumentForm/FormsGenerate/KISSAgreementDoc";
import NDAMutual from "../NDAMutualDoc/NDAMutualDoc";
import UsaNDADoc from "../UsaNDADoc/UsaNDADoc";
import FoundersAgreement from "../FoundersAgreement/FoundersAgreement";
import WebsiteTermsOfUseDoc from "../WebsiteTermsOfUseDoc/WebsiteTermsOfUseDoc";
import WebsitePrivacyPolicyDoc from "../WebsitePrivacyPolicyDoc/WebsitePrivacyPolicyDoc";
import WebsiteAcceptableUsePolicyDoc from "../WebsiteAcceptableUsePolicyDoc/WebsiteAcceptableUsePolicyDoc";
import WebsiteCookieNotice from "../WebsiteCookieNoticeDoc/WebsiteCookieNoticeDoc";
import BRCAgreementDoc from '../BRCAgreementDoc/BRCAgreementDoc';
import BoardResolutionConsentSAFEDoc from '../BoardResolutionConsentSAFEDoc'
import EmploymentInformationInventionsAgreement from '../EmploymentInformationInventionsAgreement/EmploymentInformationAgreementDoc'
import ConsultantInformationInventionsAgreement from '../ConsultantInformationInvetionsAgreement/ConsultantInformationInvetionsAgreementDoc'
import USAStrategicAdvisoryBoardOfLetterDoc from '../USAStrategicAdvisoryBoardOfLetterDoc';
import EquityIncentivePlanDoc from '../EquityIncentivePlanDoc';
import UsaCaliforniaOfferLetterSalaryEmployee from '../UsaCaliforniaOfferLetterSalaryEmployee/UsaCaliforniaOfferLetterSalaryEmployee';
import BoardApprovingOptionGrant from '../BoardApprovingOptionGrantDoc'
import OptionGrantNotice from '../OptionGrantNoticeDoc'
import ConsultingAgreementDoc from '../ConsultingAgreementDoc'
const useGetGenerateForm = (document, props, startup) => {
    switch (document.type.code) {
        case "IP_ASSIGNMENT":
            return <AssignmentDoc
                startup={startup}
                stakeholders={props.stakeholders || []}
                formType={document.type.code || []}
                documentTypes={props.documentTypes || []}
                documentTypesList={props.documentTypesList || []}
                stakeholderList={props.stakeholderList || []}
                groupCompanies={props.groupCompanies || []}
                groupCompaniesList={props.groupCompaniesList || []}
                authorizedSignatories={props.authorizedSignatories || []}
                tooltips={props.tooltips}

            />;
        case "FOUNDERS_TERM_SHEET":
            return  <FounderDocs
                startup={startup}
                fromGenerateDoc={true}
                formType={document.type.code}
                stakeholders={props.stakeholders}
                stakeholderList={props.stakeholderList}
                groupCompanies={props.groupCompanies}
                groupCompaniesList={props.groupCompaniesList}
            />
        case "SAFE":
            return <SAFEAgreementDoc
                startup={startup}
                stakeholders={props.stakeholders || []}
                formType={document.type.code || []}
                documentTypes={props.documentTypes || []}
                documentTypesList={props.documentTypesList || []}
                stakeholderList={props.stakeholderList || []}
                groupCompanies={props.groupCompanies || []}
                groupCompaniesList={props.groupCompaniesList || []}
				authorizedSignatories={props.authorizedSignatories || []}
                tooltips={props.tooltips}
            />;
        case "CONVERTIBLE_NOTE":
            return <CLNAgreementDoc
                startup={startup}
                stakeholders={props.stakeholders || []}
                formType={document.type.code || []}
                documentTypes={props.documentTypes || []}
                documentTypesList={props.documentTypesList || []}
                stakeholderList={props.stakeholderList || []}
                groupCompanies={props.groupCompanies || []}
                groupCompaniesList={props.groupCompaniesList || []}
                authorizedSignatories={props.authorizedSignatories || []}
                tooltips={props.tooltips}
                initialValues={document.params}
            />;
        case "ADVISOR_AGREEMENT":
            return <AdvisorAgreementDoc
                startup={startup}
                formType={document.type.code  || []}
                stakeholders={props.stakeholders || []}
                documentTypes={props.documentTypes || []}
                documentTypesList={props.documentTypesList || []}
                stakeholderList={props.stakeholderList || []}
                groupCompanies={props.groupCompanies || []}
                groupCompaniesList={props.groupCompaniesList || []}
                authorizedSignatories={props.authorizedSignatories || []}
                tooltips={props.tooltips}
            />;
        case "CONSULTANT_AGREEMENT":
            return <ConsultantAgreementDoc
                startup={startup}
                formType={document.type.code}
                stakeholders={props.stakeholders}
                documentTypes={props.documentTypes}
                documentTypesList={props.documentTypesList}
                stakeholderList={props.stakeholderList}
                groupCompanies={props.groupCompanies}
                groupCompaniesList={props.groupCompaniesList}
                authorizedSignatories={props.authorizedSignatories}
                tooltips={props.tooltips}
            />;
		case "EMPLOYMENT_AGREEMENT_ADGM":
        case "EMPLOYMENT_AGREEMENT_DIFC":
		case "EMPLOYMENT_AGREEMENT_UK":
            return <EmploymentAgreementDoc
                startup={startup}
                formType={document.type.code}
                stakeholders={props.stakeholders}
                documentTypes={props.documentTypes}
                documentTypesList={props.documentTypesList}
                stakeholderList={props.stakeholderList}
                groupCompanies={props.groupCompanies}
                groupCompaniesList={props.groupCompaniesList}
                authorizedSignatories={props.authorizedSignatories}
                tooltips={props.tooltips}
            />;
        case "FOUNDER_SERVICES_AGREEMENT":
            return <FounderServicesAgreementDoc
                startup={startup}
                formType={document.type.code}
                stakeholders={props.stakeholders}
                documentTypes={props.documentTypes}
                documentTypesList={props.documentTypesList}
                stakeholderList={props.stakeholderList}
                groupCompanies={props.groupCompanies}
                groupCompaniesList={props.groupCompaniesList}
                authorizedSignatories={props.authorizedSignatories}
                tooltips={props.tooltips}
                initialValues={document.params}
            />;
        case "USA_CAL_EMPLOYEE_INFORMATION_AND_INVENTIONS_AGREEMENT":
            return <EmploymentInformationInventionsAgreement
                startup={startup}
                formType={document.type.code}
                stakeholders={props.stakeholders}
                documentTypes={props.documentTypes}
                documentTypesList={props.documentTypesList}
                stakeholderList={props.stakeholderList}
                groupCompanies={props.groupCompanies}
                groupCompaniesList={props.groupCompaniesList}
                authorizedSignatories={props.authorizedSignatories}
                tooltips={props.tooltips}
                documentList={props.documentList}
            />;
            case "USA_STRATEGIC_ADVISORY_BOARD_LETTER":
                return <USAStrategicAdvisoryBoardOfLetterDoc
                    startup={startup}
                    formType={document.type.code}
                    stakeholders={props.stakeholders}
                    documentTypes={props.documentTypes}
                    documentTypesList={props.documentTypesList}
                    stakeholderList={props.stakeholderList}
                    groupCompanies={props.groupCompanies}
                    groupCompaniesList={props.groupCompaniesList}
                    authorizedSignatories={props.authorizedSignatories}
                    tooltips={props.tooltips}
                    documentList={props.documentList}
                />;
                case "USA_CONSULTING_AGREEMENT":
                    return <ConsultingAgreementDoc
                        startup={startup}
                        formType={document.type.code}
                        stakeholders={props.stakeholders}
                        documentTypes={props.documentTypes}
                        documentTypesList={props.documentTypesList}
                        stakeholderList={props.stakeholderList}
                        groupCompanies={props.groupCompanies}
                        groupCompaniesList={props.groupCompaniesList}
                        authorizedSignatories={props.authorizedSignatories}
                        tooltips={props.tooltips}
                        documentList={props.documentList}
                        initialValues={document.params}
                    />; 
            case "USA_BOARD_APPROVING_OPTION_GRANT":
                return <BoardApprovingOptionGrant
                startup={startup}
                formType={document.type.code}
                stakeholders={props.stakeholders}
                documentTypes={props.documentTypes}
                documentTypesList={props.documentTypesList}
                stakeholderList={props.stakeholderList}
                groupCompanies={props.groupCompanies}
                groupCompaniesList={props.groupCompaniesList}
                authorizedSignatories={props.authorizedSignatories}
                tooltips={props.tooltips}
                documentList={props.documentList}
                initialValues={document.params}
            />                
            case "USA_CONSULTANT_INFORMATION_AND_INVENTIONS_AGREEMENT":
                return <ConsultantInformationInventionsAgreement
                    startup={startup}
                    formType={document.type.code}
                    stakeholders={props.stakeholders}
                    documentTypes={props.documentTypes}
                    documentTypesList={props.documentTypesList}
                    stakeholderList={props.stakeholderList}
                    groupCompanies={props.groupCompanies}
                    groupCompaniesList={props.groupCompaniesList}
                    authorizedSignatories={props.authorizedSignatories}
                    tooltips={props.tooltips}
                    documentList={props.documentList}
                />;    
        case "GRANT_AGREEMENT":
            
            return <GrantAgreementDoc
                startup={startup}
                formType={document.type.code}
                stakeholders={props.stakeholders}
                documentTypes={props.documentTypes}
                documentShares={_.get(document, 'params.terms.grant.sharesNumber')}
                documentTypesList={props.documentTypesList}
                stakeholderList={props.stakeholderList}
                groupCompanies={props.groupCompanies}
                groupCompaniesList={props.groupCompaniesList}
                authorizedSignatories={props.authorizedSignatories}
                tooltips={props.tooltips}
                documentList={props.documentList}
            />;
        case "SHARE_INCENTIVE_PLAN":
        case "BOARD_RESOLUTION":
        case "SHARE_INCENTIVE_PLAN_FAQ":
            return <ShareIncentivePlanDoc
                startup={startup}
                formType={document.type.code}
                stakeholders={props.stakeholders}
                documentTypes={props.documentTypes}
                documentTypesList={props.documentTypesList}
                stakeholderList={props.stakeholderList}
                groupCompanies={props.groupCompanies}
                groupCompaniesList={props.groupCompaniesList}
                authorizedSignatories={props.authorizedSignatories}
                tooltips={props.tooltips}
            />;
        case "KISS":
            return <KISSAgreementDoc
                startup={startup}
                formType={document.type.code}
                stakeholders={props.stakeholders}
                documentTypes={props.documentTypes}
                documentTypesList={props.documentTypesList}
                stakeholderList={props.stakeholderList}
                groupCompanies={props.groupCompanies}
                groupCompaniesList={props.groupCompaniesList}
                authorizedSignatories={props.authorizedSignatories}
            />
        case "RESTRICTIVE_COVENANT_AGREEMENT":
            return <RestrivtiveCovenantsAgreementDoc
                startup={startup}
                formType={document.type.code}
                stakeholders={props.stakeholders}
                documentTypes={props.documentTypes}
                documentTypesList={props.documentTypesList}
                stakeholderList={props.stakeholderList}
                groupCompanies={props.groupCompanies}
                groupCompaniesList={props.groupCompaniesList}
                authorizedSignatories={props.authorizedSignatories}
                tooltips={props.tooltips}
            />;
        case "NDA_MUTUAL":
            return <NDAMutual
                startup={startup}
                formType={document.type.code}
                stakeholders={props.stakeholders}
                documentTypes={props.documentTypes}
                documentTypesList={props.documentTypesList}
                stakeholderList={props.stakeholderList}
                groupCompanies={props.groupCompanies}
                groupCompaniesList={props.groupCompaniesList}
                authorizedSignatories={props.authorizedSignatories}
                tooltips={props.tooltips}
                />
        case "FOUNDERS_AGREEMENT":
            return <FoundersAgreement
                startup={startup}
                formType={document.type.code}
                stakeholders={props.stakeholders}
                documentTypes={props.documentTypes}
                documentTypesList={props.documentTypesList}
                stakeholderList={props.stakeholderList}
                groupCompanies={props.groupCompanies}
                groupCompaniesList={props.groupCompaniesList}
                authorizedSignatories={props.authorizedSignatories}
                tooltips={props.tooltips || {}}
                initialValues={document.params}
            />
        case "WEBSITE_TERMS_OF_USE":
            return <WebsiteTermsOfUseDoc
                startup={startup}
                formType={document.type.code}
                stakeholders={props.stakeholders}
                documentTypes={props.documentTypes}
                documentTypesList={props.documentTypesList}
                stakeholderList={props.stakeholderList}
                groupCompanies={props.groupCompanies}
                groupCompaniesList={props.groupCompaniesList}
                authorizedSignatories={props.authorizedSignatories}
                tooltips={props.tooltips || {}}
            />
        case "WEBSITE_PRIVACY_POLICY":
            return <WebsitePrivacyPolicyDoc
                startup={startup}
                formType={document.type.code}
                stakeholders={props.stakeholders}
                documentTypes={props.documentTypes}
                documentTypesList={props.documentTypesList}
                stakeholderList={props.stakeholderList}
                groupCompanies={props.groupCompanies}
                groupCompaniesList={props.groupCompaniesList}
                authorizedSignatories={props.authorizedSignatories}
                tooltips={props.tooltips || {}}
            />
        case "WEBSITE_ACCEPTABLE_USE_POLICY":
            return <WebsiteAcceptableUsePolicyDoc
                startup={startup}
                formType={document.type.code}
                stakeholders={props.stakeholders}
                documentTypes={props.documentTypes}
                documentTypesList={props.documentTypesList}
                stakeholderList={props.stakeholderList}
                groupCompanies={props.groupCompanies}
                groupCompaniesList={props.groupCompaniesList}
                authorizedSignatories={props.authorizedSignatories}
                tooltips={props.tooltips || {}}
            />
        case "USA_OPTION_GRANT_NOTICE":
           return <OptionGrantNotice
                startup={startup}
                formType={document.type.code}
                stakeholders={props.stakeholders}
                documentList={props.documentList}
                documentTypes={props.documentTypes}
                documentTypesList={props.documentTypesList}
                stakeholderList={props.stakeholderList}
                groupCompanies={props.groupCompanies}
                groupCompaniesList={props.groupCompaniesList}
                authorizedSignatories={props.authorizedSignatories}
                tooltips={props.tooltips || {}}
            />

        case "WEBSITE_COOKIE_NOTICE":
            return <WebsiteCookieNotice
                startup={startup}
                formType={document.type.code}
                stakeholders={props.stakeholders}
                documentTypes={props.documentTypes}
                documentTypesList={props.documentTypesList}
                stakeholderList={props.stakeholderList}
                groupCompanies={props.groupCompanies}
                groupCompaniesList={props.groupCompaniesList}
                authorizedSignatories={props.authorizedSignatories}
                tooltips={props.tooltips || {}}
            />
		case "BOARD_RESOLUTION_CONVERTIBLE_LOAN_NOTE":
		case "BOARD_RESOLUTION_CONVERTIBLE_SAFE":
		case "BOARD_RESOLUTION_CONVERTIBLE_KISS":
		case "BOARD_RESOLUTION_CONVERTIBLE_FIXED_EQUITY":
		case "BOARD_RESOLUTION_CONVERTIBLE_OTHER":
			return <BRCAgreementDoc
				startup={startup}
				stakeholders={props.stakeholders || []}
				formType={document.type.code || []}
				documentTypes={props.documentTypes || []}
				documentTypesList={props.documentTypesList || []}
				stakeholderList={props.stakeholderList || []}
				groupCompanies={props.groupCompanies || []}
				groupCompaniesList={props.groupCompaniesList || []}
				authorizedSignatories={props.authorizedSignatories || []}
				tooltips={props.tooltips}
			/>;
            case "USA_BOARD_CONSENT_SAFE_CONVERTIBLE_LOAN_NOTE":
            case "USA_BOARD_CONSENT_SAFE_SAFE":
            case "USA_BOARD_CONSENT_SAFE_KISS":
            case "USA_BOARD_CONSENT_SAFE_FIXED_EQUITY":
            case "USA_BOARD_CONSENT_SAFE_OTHER":
            case "USA_BOARD_CONSENT_SAFE":
                return (
                <BoardResolutionConsentSAFEDoc
                startup={startup}
                stakeholders={props.stakeholders || []}
                formType={document.type.code || []}
                documentTypes={props.documentTypes || []}
                documentTypesList={props.documentTypesList || []}
                stakeholderList={props.stakeholderList || []}
                groupCompanies={props.groupCompanies || []}
                groupCompaniesList={props.groupCompaniesList || []}
                authorizedSignatories={props.authorizedSignatories || []}
                tooltips={props.tooltips}
                />
            );    
            case "USA_EQUITY_INCENTIVE_PLAN":
            case "USA_STAKEHOLDERS_DIRECTORS_EQUITY_INCENTIVE_PLAN":
                    return (
                    <EquityIncentivePlanDoc
                    startup={startup}
                    stakeholders={props.stakeholders || []}
                    formType={document.type.code || []}
                    documentTypes={props.documentTypes || []}
                    documentTypesList={props.documentTypesList || []}
                    stakeholderList={props.stakeholderList || []}
                    groupCompanies={props.groupCompanies || []}
                    groupCompaniesList={props.groupCompaniesList || []}
                    authorizedSignatories={props.authorizedSignatories || []}
                    tooltips={props.tooltips}
                    />
                );           
        case "USA_NDA":
            return <UsaNDADoc
                startup={startup}
                formType={document.type.code}
                stakeholders={props.stakeholders}
                documentTypes={props.documentTypes}
                documentTypesList={props.documentTypesList}
                stakeholderList={props.stakeholderList}
                groupCompanies={props.groupCompanies}
                groupCompaniesList={props.groupCompaniesList}
                authorizedSignatories={props.authorizedSignatories}
                tooltips={props.tooltips}
            />

        //CP-5321
        case "USA_CAL_OFFER_LETTER_SALARY_EMPLOYEE":
            return <UsaCaliforniaOfferLetterSalaryEmployee
                startup={startup}
                formType={document.type.code}
                stakeholders={props.stakeholders}
                documentTypes={props.documentTypes}
                documentTypesList={props.documentTypesList}
                stakeholderList={props.stakeholderList}
                groupCompanies={props.groupCompanies}
                groupCompaniesList={props.groupCompaniesList}
                authorizedSignatories={props.authorizedSignatories}
                tooltips={props.tooltips}
            />

		default:
            break;
    }
}

export default useGetGenerateForm

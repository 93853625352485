import { get } from 'lodash';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../../../generic/components/Buttons/Button';
import { StepProps } from "../../../../../generic/components/Wizard/Step";
import useCustomMachine from '../../../../../generic/hooks/useCustomMachine';
import { addAssistantText, clearAssistatText } from '../../../../../generic/store/action';
import TemplateWizard from '../../../../../generic/templates/Modal/TemplateWizard';
import Form from "../../components/Forms/FormCurrentSelect";

const Step1: FC<StepProps> = () => {
  const { next, send, state } = useCustomMachine();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText(`A copy of a UID Stamp is required for all UAE Nationals.`));
    return () => {
      dispatch(clearAssistatText());
    }
  }, [])

  const handleCompleteSubmit = async values => {
    next(values);
  }

  const handleCancel = async values => {
    send("CANCEL");
  }

  return (
    <React.Fragment>
      <Form
        initialValues={get(state, 'context.data.uploadDocument', {})}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: 'Where is their UID stamp?',
          subTitle: 'UID stamp'
        }}
        text="If their UID stamp is in an old  passport, Clara will need scans of this passport plus the UID stamp itself."
        buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }}>
      </Form>
    </React.Fragment>
  )
}

export default Step1;

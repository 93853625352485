const useGuards = () => {
  const mainRoles = ['DIRECTOR', 'SHAREHOLDER', 'AUTHORIZEDSIGNATORY']
  return {
    isMainRole: (context) =>
      context?.initialData?.stakeholder?.roles.some(rol => mainRoles?.includes(rol.name)),
    isOtherRole: (context) =>
      !context?.initialData?.stakeholder?.roles.some(rol => mainRoles?.includes(rol.name))
  }
}

export default useGuards;

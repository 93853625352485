import React, { FC, useMemo, useRef } from 'react';
import * as Yup from 'yup';
import _ from 'lodash';
import Form from "../../../../components/forms/Form/FormL";
import ControllerInput from "../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import SubmitButton from "../../../generic/components/Buttons/SubmitButton";
import TemplateFormDefault from "../../../generic/templates/Modal";
import FormProps from "../../../generic/forms/interfaces/FormProps";
import CheckboxButtonSimple from "../../../generic/components/Inputs/CheckboxButtonSimple";
import useGetStakeholders from "../../../../hooks/services/modules/stakeholders/useGetStakeholders";
import useSession from "../../../session/hooks/useSession";
import classes from './Form.module.scss';

const schema = Yup.object().shape({
  bankingAuthorities: Yup.array().required('Required').min(1, "Required")
})
interface FormIsIndividualOrCompanyProps extends FormProps {
  groupCompanyId: String;
}

const BankingAuthorities: FC<FormIsIndividualOrCompanyProps> = ({ initialValues, onCompleteSubmit, Template = TemplateFormDefault, buttons = {}, groupCompanyId, buttonSubmitProps, propsTemplate }: FormIsIndividualOrCompanyProps) => {

  const { startupId } = useSession();
  const { data } = useGetStakeholders({ variables: { startupId, groupCompanyId } }, { isADGM: true });

  const stakeholders = useMemo(() => {
    const filterOnlyIndividuals = _.filter(data, (stakeholder) => stakeholder.isAnEntity === false);
    return _.map(filterOnlyIndividuals, st => ({ value: st, label: st.fullName }));
  }, [JSON.stringify(data)]);

  const refForm = useRef();

  return (
    <>
      <Form
        initialValues={initialValues || {}}
        schema={schema}
        onlySendDirty={false}
        onSubmit={onCompleteSubmit}
        ref={refForm} optionsForm={{ mode: "onChange" }}
      >
        <Template
          buttons={{ ...buttons, submit: <SubmitButton {...buttonSubmitProps}>Next</SubmitButton> }}
          props={propsTemplate}
        >
          <ControllerInput
            as={CheckboxButtonSimple}
            isMultiple={true}
            className={classes.checkboxMultiple}
            defaultValue={[]}
            name='bankingAuthorities'
            options={stakeholders}
            classNameButton={classes.buttonCheckBox}
          />
        </Template>
      </Form>
    </>
  )
}

export default BankingAuthorities

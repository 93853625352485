import React from "react";
import MicroFrontend from "src/mf";
import useSession from "src/modules/session/hooks/useSession";
const LoginView = (props) => {
  const { initSession, session } = useSession()
  return (
    <React.Fragment>
      {!session?.token ? (
        <MicroFrontend id="MF_Login_" component={"./Login"} mf="dashboard" params={{
          initSession
        }} loader={<></>} />
      ) : <></>}
    </React.Fragment>
  );
};

export default LoginView;

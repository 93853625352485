import React, { FC, useEffect, useState } from 'react'
import ModalAssistant, { ModalAssistantProps } from '../../../../generic/components/Modal/ModalAssistant'
import { useDispatch } from 'react-redux'
import useTranslate from '../../../../generic/hooks/useTranslate'
import useModal from "../../../../generic/hooks/useModal";
import FormSourceOfFunds from "../Forms/SourceOfFundsForm";
import TemplateWizard from "../../../../generic/templates/Modal/TemplateWizard";
import Button from "../../../../generic/components/Buttons/Button";
import { addAssistantText, clearAssistatText } from "../../../../generic/store/action";
import { useParams } from "react-router-dom";
import useSession from "../../../../session/hooks/useSession";
import _ from 'lodash';
import useUpdateStakeholder from "../../../../../hooks/services/modules/individualStakeholder/useUpsertComponentStakeholder";
import useGetStakeholder from '../../ProofOfAddress/hooks/useGetStakeholder';
import useGetStakeholderLazy from 'src/hooks/services/modules/stakeholders/useGetStakeholderLazy';

interface ModalSourceOfFundsKYProps extends ModalAssistantProps {
  paramsMutation: any,
  onClose(): void
}

const ModalSourceOfFundsKY: FC<ModalSourceOfFundsKYProps> = ({
  children,
  open: openParam = false,
  paramsMutation = {},
  ...props 
}: ModalSourceOfFundsKYProps) => {

  const [open, setOpen] = useState(openParam);
  const { closeModal: onClose } = useModal();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>()
  const { startupId } = useSession()
  
  const [upsertComponentStakeholder] = useUpdateStakeholder()
  const { translate } = useTranslate();

  useEffect(() => {
    setOpen(openParam);
  }, [openParam]);

  const handleCancel = (values) => {
    onClose();
    setOpen(false);
    clearAssistatText()
  }

  const handleInitModal = () => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText('Clara needs this confirmation as assurance that no proceeds of crime are being used to fund the new company, or being transferred through it. Click <a href="https://help.clara.co/en/articles/8391040-what-does-source-of-wealth-mean-in-cayman" target="_blank">here</a> to learn more.'))
  }

  useEffect(() => {
    handleInitModal()
    return () => {
      dispatch(clearAssistatText());
    }
  }, []);

  const onSubmit = async (values) => {
    const oldSOF = _.get(paramsMutation, "sourcesOfFunds");
    const jt = _.get(paramsMutation, "jurisdictionType") === "" ? "CAYMAN" : _.get(paramsMutation, "jurisdictionType");
    const itemSOF = {
      jurisdictionType: jt,
      sourceOfFunds: values.sourceOfFunds,
      sourceOfFundsYesNo: values.sourceOfFundsYesNo === "true" ? true : false,
    }
    let newSOF;
    if (oldSOF !== undefined) {
      newSOF = [
        ...oldSOF,
        itemSOF,
      ];
    } else {
      newSOF = [
        itemSOF,
      ];
    }
    const variables = {
      startupId,
      stakeholderId: paramsMutation?.stakeholderId ?? id,
      stakeholder: {
        sourcesOfFunds: newSOF,
      }
    }
    await upsertComponentStakeholder({ ...variables })
    onClose();
  }

  return (
    <ModalAssistant open={open}>
      <FormSourceOfFunds
        initialValues={paramsMutation.initialValues}
        isEdit={false}
        buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }}
        onCompleteSubmit={onSubmit}
        propsTemplate={{
          title: 'Confirm source of wealth',
          subTitle: "Source of wealth",
        }}
        Template={TemplateWizard} />
    </ModalAssistant>
  )
}

export default ModalSourceOfFundsKY;

import get from 'lodash/get';
import uniq from 'lodash/uniq';
import { useMemo } from 'react';
import useGetPINCAPrices from 'src/views/modules/startups/renewal/pincasPrice/queries/useGetPINCAPrices';
import useGetGroupCompaniesLazy from '../../graphql/queries/useGetGroupCompaniesAndStakeholderByIdLazy';
const useServices = () => {
  const { data: userData, manualQuery } = useGetGroupCompaniesLazy();
  const { data, manualQuery: getPINCAPrices } = useGetPINCAPrices()
  return useMemo(() => ({
    getGroupCompaniesAndStakeholder: async (context, event) => {
      const groupCompaniesAndStakeholder = await manualQuery();
      const groupCompaniesWithPincas = get(groupCompaniesAndStakeholder, 'data.getGroupCompanies', []).filter(g => g.managedByClara && g.kind === 'INCORPORATED')
      const jurisdictions = uniq(groupCompaniesWithPincas.map(g => g.jurisdictionType));
      const rolesInProgress = [...get(groupCompaniesAndStakeholder, 'data.getStakeholder.rolesInProgress.toAdd', []),...get(groupCompaniesAndStakeholder, 'data.getStakeholder.rolesInProgress.toDelete', [])]
      const requestItems = await getPINCAPrices({
        variables: {
          filters: {
            jurisdictionTypes:jurisdictions
          }
        }
      })


      const result = {
        groupCompanies: get(groupCompaniesAndStakeholder, 'data.getGroupCompanies', []),
        stakeholder: get(groupCompaniesAndStakeholder, 'data.getStakeholder', {}),
        requestItems: requestItems || {},
        rolesInProgress
      };
      return result;
    }

  }), [getPINCAPrices, manualQuery]);
}
export default useServices;

import React, { useState } from 'react';
import ClaraFlag from '../../../../../../images/Clara-flag.png';

function Flag({
  url, 
  className,
}: any) {

  const [style, setStyle] = useState({ display: '', width: "26px", height: "18px" })
  const [flag, setFlag] = useState(url);

  const handleOnError = () => {
    setFlag(ClaraFlag);
    setStyle({ display: "", width: "26px", height: "18px" })
  }

  return <img style={style} className={className} src={flag} onError={handleOnError} alt="Country flag" />
}
export default Flag;

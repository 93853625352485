import useCustomMachine from "../../../../generic/hooks/useCustomMachine";
import React, { FC, useEffect } from "react";
import Button from "../../../../generic/components/Buttons/Button";
import { StepProps } from "../../../../generic/components/Wizard/Step";
import TemplateWizard from "../../../../generic/templates/Modal/TemplateWizard";
import useTranslate from "../../../../generic/hooks/useTranslate";
import FormPEPFamilyMember from "../../Forms/FormPEPFamilyMember";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import useSession from "../../../../session/hooks/useSession";
import { addAssistantText, clearAssistatText } from "../../../../generic/store/action";
import useUpdateStakeholder from "src/modules/startup/onBoarding/core/graphql/mutations/useUpdateStakeholder";

const Step1: FC<StepProps> = () => {

  const { translate } = useTranslate();
  const { next, send, state, stepData } = useCustomMachine();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { startupId } = useSession();
  const { updateStakeholder } = useUpdateStakeholder({}, {});

  useEffect(() => {
    dispatch(clearAssistatText());
    dispatch(
      addAssistantText(translate("MODULES.STAKEHOLDER.PEP.SECOND_STEP.TOOLTIP"))
    );
  }, []);

  const handleCompleteSubmit = async (values) => {
    if (values.isFamilyMemberOfAPEP)
      await updateStakeholder({
        startupId,
        stakeholderId: state?.context?.data?.stakeholderId ?? id,
        stakeholderData: {
          stakeholder: {
            pep: {
              ...values,
              ...state.context.data.pep,
            }
          },
        }
      });
    next(values);
  };

  const handleCancel = async (values) => {
    send("CANCEL");
  };

  const handlePrevious = () => send("PREVIOUS");

  return (
    <React.Fragment>
      <FormPEPFamilyMember
        initialValues={stepData}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: translate("MODULES.STAKEHOLDER.PEP.SECOND_STEP.TITLE"),
          subTitle: translate("MODULES.STAKEHOLDER.PEP.SECOND_STEP.SUBTITLE"),
        }}
        buttons={{
          previous: (
            <Button onClick={handlePrevious} variant="secondary">
              Previous
            </Button>
          ),
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              Cancel
            </Button>
          ),
        }}
      ></FormPEPFamilyMember>
    </React.Fragment>
  );
};

export default Step1;

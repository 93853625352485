import React, {Component} from "react";
import {Avatar, ButtonModalOptions, Table} from "../../../../index";
import classes from "./EquityTable.module.scss";
import _ from "lodash";
import * as numeral from "numeral";
import {Constants} from "../../../../../utils/constants";

class EquityTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            groups: [
                {
                    data: "Share Positions",
                    openTable: true,
                    key: "shares"
                },
                {
                    data: "Convertibles",
                    openTable: true,
                    key: "convertibles"
                },
                {
                    data: "Share Option Grants",
                    openTable: true,
                    key: "shareOptions"
                },
                {
                    data: "Warrants",
                    openTable: true,
                    key: "warrants"
                },
            ]
        }

    }

    showTable(data) {
        return _.size(data) > 0;
    }
    handleOpenGroup = (index) => {
        let { groups } = this.state;
        groups[index].openTable = !groups[index].openTable;
        this.setState({
            groups
        })
    }

    render() {
        let options = [];
        options.push({ label: "View", action: this.props.viewEquity, className: `${classes.ViewIcon}` })
        options.push({ label: "Edit", action: this.props.editEquity, className: `${classes.EditIcon}`, actionAccess: Constants.ACTIONS.EDIT_PROFILE })
        options.push({ label: "Delete", action: this.props.deleteEquity, className: `${classes.DeleteIcon}`, actionAccess: Constants.ACTIONS.EDIT_PROFILE })

        let fields = [
            [
                {
                    key: "filterField",
                    label: this.props.isGroupCompany ? "Shareholders" : "Company",
                    "decorator": (props) => (
                        <div className={classes.containerHolder} onClick={() => this.props.viewHolder(props.row)}>
                            <Avatar
                                avatar={props.row.holder.avatar}
                                name={props.row.holder.name || props.row.holder.fullName}
                                showLabel={true}
                            />
                        </div>
                    )
                },
                {
                    label: "No. of Shares",
                    "decorator": (props) => (
                        <div className={classes.containerNShares}>
                            {numeral(props.row.amount).format("0,0.00")}
                        </div>
                    )
                },
                {
                    label: "Class",
                    "decorator": (props) => (
                        <div className={classes.Class}>
                            {props.row.shareClass.name}
                        </div>
                    )
                },
                {
                    label: "Price per Share",
                    "decorator": (props) => (
                        <div className={classes.containerPrice}>
                            {props.row.price ? numeral(props.row.price).format("0,0.00") + " " + props.row.currency : " "}
                        </div>
                    )
                },
                {
                    label: "",
                    "decorator": (props) => (
                        <span className={classes.buttomModal}>
                            <ButtonModalOptions title={null} options={options} params={props.row} />
                        </span>
                    )

                },
            ],
            [
                {
                    label: this.props.isGroupCompany ? "Convertible Holder" : "Company",
                    key: "filterField",
                    "decorator": (props) => (
                        <div className={classes.containerHolder} onClick={() => this.props.viewHolder(props.row)}>
                            <Avatar
                                avatar={props.row.holder.avatar}
                                name={props.row.holder.name || props.row.holder.fullName}
                                showLabel={true}
                            />
                        </div>
                    )
                },
                {
                    label: "Invest. Amount",
                    "decorator": (props) => (
                        <div>
                            {numeral(props.row.amount).format("0,0.00") + " " + props.row.currency}
                        </div>
                    )
                },
                {
                    label: "Discount",
                    "decorator": (props) => (
                        <div>
                            {props.row.discountRate>0.01? props.row.discountRate + "%" : "<0.01%"}
                        </div>
                    )
                },
                {
                    label: "Interest Rate",
                    "decorator": (props) => (
                        <div>
                            {props.row.interestRate>0 ? props.row.interestRate + "%" : "<0.01%"}
                        </div>
                    )
                },
                {
                    label: "Valuation Cap",
                    "decorator": (props) => (
                        <div>
                            {props.row.valuationCap ? numeral(props.row.valuationCap).format("0,0.00") + " " + props.row.currency : " "}
                        </div>
                    )
                },

                {
                    label: "",
                    "decorator": (props) => (
                        <span className={classes.buttomModal} >
                            <ButtonModalOptions title={null} options={options} params={props.row}
                            />
                        </span>
                    )

                }],
            [
                {
                    key: "filterField",
                    label: this.props.isGroupCompany ? "Option Holders" : "Company",
                    "decorator": (props) => (
                        <div className={classes.containerHolder} onClick={() => this.props.viewHolder(props.row)}>
                            <Avatar
                                avatar={props.row.holder.avatar}
                                name={props.row.holder.name || props.row.holder.fullName}
                                showLabel={true}
                            />
                        </div>
                    )
                },
                {
                    label: "No. of Share Options",
                    "decorator": (props) => (
                        <div className={classes.containerNShares}>
                            {numeral(props.row.amount).format("0,0.00")}
                        </div>
                    )
                },
                {
                    label: "Class",
                    "decorator": (props) => (
                        <div className={classes.containerClass}>
                            {props.row.shareClass.name}
                        </div>
                    )
                },
                {
                    label: "Exercise Price",
                    "decorator": (props) => (
                        <div className={classes.containerPrice}>
                            {props.row.price ? numeral(props.row.price).format("0,0.00") + " " + props.row.currency : " "}
                        </div>
                    )
                },
                {
                    label: "Share Incentive Plan",
                    "decorator": (props) => (
                        <div className={classes.containerIncentivePlans}>
                            {props.row.shareIncentivePlan.name || "-"}
                        </div>
                    )
                },
                {
                    label: "",
                    "decorator": (props) => (
                        <span className={classes.buttomModal} >
                            <ButtonModalOptions title={null} options={options} params={props.row}
                            />
                        </span>
                    )

                }],
            [
                {
                    label: this.props.isGroupCompany ? "Warrant Holders" : "Company",
                    key: "filterField",
                    "decorator": (props) => (
                        <div className={classes.containerHolder} onClick={() => this.props.viewHolder(props.row)}>
                            <Avatar
                                avatar={props.row.holder.avatar}
                                name={props.row.holder.name || props.row.holder.fullName}
                                showLabel={true}
                            />
                        </div>
                    )
                },
                {
                    label: "Invest. Amount",
                    "decorator": (props) => (
                        <div >
                            {numeral(props.row.amount).format("0,0.00") + " " + props.row.currency}
                        </div>
                    )
                },
                {
                    label: "Valuation",
                    "decorator": (props) => (
                        <div >
                            {props.row.valuation ? numeral(props.row.valuation).format("0,0.00") + " " + props.row.currency : " "}
                        </div>
                    )
                },
                {
                    label: "",
                    "decorator": (props) => (
                        <span className={classes.buttomModal} >
                            <ButtonModalOptions title={null} options={options} params={props.row}
                            />
                        </span>
                    )

                }]
        ]
        let { filter, data: rows } = this.props;

        return (
            <div className={classes.containerTables}>
                {this.state.groups.map((a, index) => {
                    let showTable = this.showTable(rows[a.key]);
                    return (
                        <div className={`${classes.containerTable} ${classes[a.key]}`} key={index}>

                            <div className={classes.tableHead}>
                                <button
                                    disabled={!showTable}
                                    className={`${classes[a.openTable ? "groupOpen" : "groupClose"]}`}
                                    onClick={() => this.handleOpenGroup(index)}
                                >

                                    {a.data + ` (${rows[a.key] ? rows[a.key].length : 0})`}
                                </button>
                                <i className={classes.help} />
                            </div>
                            {showTable && a.openTable &&

                                <Table
                                    fields={fields[index]}
                                    data={rows[a.key]}
                                    filter={filter}
                                />
                            }

                        </div>
                    )
                })}   </div>
        )
    };

}

export default EquityTable;

import React, {useEffect} from 'react';
import useMutation from "../../custom/useMutation"

const mutation = `mutation setFormDraft($formDraft: FormDraftInput!) {
  setFormDraft(formDraft: $formDraft) 
} `;
const useSetFormDraft = (options) =>{
    
    const [send,data] = useMutation(mutation,options);
    useEffect(() => {
        
    }, []);

    return  [send,data] ;
}
export default useSetFormDraft;
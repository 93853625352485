import get from 'lodash/get'
import  isEmpty  from 'lodash/isEmpty';
import set from 'lodash/set'
import useTranslate from "src/modules/generic/hooks/useTranslate";
const useGetDelete = () => {
    const { translate } = useTranslate();

    const mapConnections = (data)=>{
        return {
            ...get(data,'getGroupCompany'),
                connections:{
                sharesInIncorporatedCompanies:get(data,'getGroupCompany.formationData.sharesInIncorporatedCompanies',[]),
                assetsAndRevenues:get(data,'getGroupCompany.formationDataConnections.assetsAndRevenue',[]),
                documents:get(data,'getGroupCompany.documentAsParties',[]),
                equity:get(data,'getGroupCompany.areThereEquityConnections',false)?[{}]:[],
                dueDiligences:[]
        }
    }
}
    const getType = (allowedActionDelete) => {
        if(allowedActionDelete?.reasonCode === 'GROUP_COMPANY_ON_DELETE_MANAGED_BY_CLARA') return 'managedByClara'
        if(allowedActionDelete?.reasonCode === 'GROUP_COMPANY_ON_DELETE_THERE_ARE_CONNECTIONS') return 'connections'
        if(allowedActionDelete?.value)return 'delete'
    }

    const getCategories = (connections) => {
        const categories= [];
        Object.keys(connections).forEach(connectionKey=> {
            if(!isEmpty(get(connections,connectionKey,[]))) {
                categories.push({
                    title: translate(`MODULES.GROUP_COMPANY.DELETE.CONNECTIONS.${connectionKey.toUpperCase()}_TITLE`),
                    items: connections[connectionKey].map(item => {
                        const params = {};
                        return translate(`MODULES.GROUP_COMPANY.DELETE.CONNECTIONS.${connectionKey.toUpperCase()}`, set(params,connectionKey,item));
                    })
                });
            }
        });
        return categories;
    }

    return { getType,mapConnections,getCategories }
}
export default useGetDelete

import { useMemo } from 'react';
import { assign } from 'xstate';

const useActions = ({ onPreviousMachine }) => {

  return useMemo(() => {
    // const exampleAction = assign((context: any = {}, event: any = null) => {
    //   return {
    //     ...context,
    //     newValue: true,
    //   };
    // });

    /** this action is online used to return of chidlren machine to parent machine. If yo need other type of comunication you can add more actions here */
    const previousMachine = assign((context: any = {}, event: any = null) => {
      onPreviousMachine && onPreviousMachine();
      return context;
    });
    

    return {
      // exampleAction,
      previousMachine,
    }
  }, [onPreviousMachine]);

}

export default useActions;

import React from "react";
import _ from "lodash";
import Bubble from "../../Bubble";
import BubbleContents from "../../BubbleContents";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import AssistanceContents from "../../AssistanceContents";
import TabBubblesContainer from "../../TabBubblesContainer";
import moment from "moment";
import { useSelector } from "react-redux";
import PaymentCard from "../../AssistanceAreas/Map2/Cards/PaymentCard";
import PayInvoiceCard from "src/modules/assistanceV4/components/AssistanceAreas/Map2/Cards/PayInvoice";
import ReadMore from "../../../../../modules/assistanceV4/components/ReadMore";
import classes from "./styles.module.scss";
import classnames from "classnames";
import useSession from "../../../../../modules/session/hooks/useSession";
import useGetStartup from "../../../../../modules/startup/graphql/startup/useGetStartup";

const InvoiceTaskComponent = () => {
  const { translate } = useTranslate();
  const session = useSelector((state) => _.get(state, "session"));
  const activeSubscriptionData = _.get(
    session,
    "startup.activeSubscriptionPlan.activeSubscriptionData",
    {}
  );
  const needPay = _.get(activeSubscriptionData, "mustPayInvoice", false);
  const { startup } = useSession();

  const { data: startupFull } = useGetStartup({
    startupId: startup.id,
  });

  const invoicesManual = _.filter(
    _.get(startupFull, "invoices", []),
    (invoice) => {
      return (
        _.get(invoice, "category", "") === "Manual" &&
        _.get(invoice, "status", "") === "PENDING"
      );
    }
  );

  return (
    <AssistanceContents>
      <TabBubblesContainer>
        {!_.isEmpty(invoicesManual) && (
          <PayInvoiceCard
            invoice={!_.isEmpty(invoicesManual) ? invoicesManual[0] : {}}
          />
        )}
        {needPay && (
          <PaymentCard
            isSubscriptionPage={true}
            date={moment(
              _.get(activeSubscriptionData, "autoPaymentScheduleDate", "")
            ).format("DD MMM YYYY")}
          />
        )}
        <Bubble>
          <BubbleContents>
            <span className={classnames(classes.bubbleSpecialFont)}>
              {translate("MODULES.CHECKOUT.ASSISTANCE_HERE")}
              <ReadMore
                link={"https://clara.co/terms-of-use/"}
                text={"MODULES.CHECKOUT.ASSISTANCE_TERMS"}
                useBlueColor={true}
              />
              {translate("MODULES.CHECKOUT.ASSISTANCE_AND_YOUR")}
              <ReadMore
                link={"https://clara.co/privacy-policy/"}
                text={"MODULES.CHECKOUT.ASSISTANCE_PRIVACY"}
                useBlueColor={true}
              />
            </span>
          </BubbleContents>
        </Bubble>
      </TabBubblesContainer>
    </AssistanceContents>
  );
};

export default InvoiceTaskComponent;

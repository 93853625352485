import _ from "lodash";
import useMutation from "src/hooks/custom/useMutation";
import useSession from "src/modules/session/hooks/useSession";

export const UPSERT_COMPANY_ADDRESSES = `
mutation upsertGroupCompany(
    $startupId: String
    $groupCompanyId: String
	  $groupCompany: GroupCompanyInputType
  ) {
    upsertGroupCompany(
      startupId: $startupId
      groupCompanyId: $groupCompanyId
      groupCompany: $groupCompany
    ) {
      id
  }
}
`;

const useUpdateGroupCompanyAddresses = () => {
  const { startupId } = useSession()


    const [mutation] = useMutation(
      UPSERT_COMPANY_ADDRESSES,
        { showSuccessNotification: true }
    ); 

    const save = async (groupCompanyData) => {
      const data = {
        variables: {
          startupId: startupId.toString(),
          groupCompanyId: groupCompanyData.groupCompanyId.toString(),
          groupCompany: groupCompanyData.groupCompany,
        }
      }
      const submitData = await mutation(data)
      return submitData
    }
  
    return save;
};

export default useUpdateGroupCompanyAddresses;

import _ from 'lodash'
import useSession from "src/modules/session/hooks/useSession";
import useLazyQuery from "src/hooks/custom/useLazyQuery";

const DELETE_VERIFICATION=`
query getGroupCompanyVerifyDelete($startupId:ID!, $groupCompanyId:ID!){
getGroupCompany(startupId:$startupId, groupCompanyId:$groupCompanyId){
id
allowedActions{
toDelete{
    value
    reasonText
    reasonCode
}
}
}
}
`
const useGetDeleteVerification=(variables,config?,props?)=>{
    const {data,loading,manualQuery}=useLazyQuery(DELETE_VERIFICATION,variables,config,props)
    return manualQuery;
}
export default useGetDeleteVerification
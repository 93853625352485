import _ from "lodash";
import React, { Component } from 'react';
import { Field } from "formik";
import ClaraSelect from "../../../../components/Form/ClaraSelect/ClaraSelect";
import classes from "../../SteperForm.module.scss";
import TextInput from "../../Inputs/TextInput";
import FormikHelper from "../../../../utils/formikHelper";
import Checkbox from '../../Inputs/Checkbox'
import moment from "moment";
import AuthorisedSignatories from "../../../../../components/inputs/AuthorizedSignatories/AuthorizedSignatories";
import ClaraUpload from "../../../../components/Form/ClaraUpload/ClaraUpload";
import { ClaraInputYearsMonths, TextAreaInput,TextType } from "../../../../components";
import ClaraCountrySelector from "../../../../components/Form/ClaraCountrySelector/ClaraCountrySelector";
import {groupCompanyRecipientNotice} from './helper';

class ShareIncentivePlanDoc extends Component {

    constructor(props) {
        super(props);
        this.refEvent1 = React.createRef()
        this.refEvent2 = React.createRef()
        this.state = {
            typeSelected: undefined,
            participantSelected: undefined,
            groupCompanySelected: undefined,
            authorizedSelected: undefined,
            authorizedSignatories: [],
            authorizedSignatoriesList: [],
            documentTypes: [],
            documentTypesList: [],
            dynamicParams: [],
            date: undefined,
            showSelectAuthorized: true,
            showInputName: false,
            showAddAllDirectors: true
        }
    };

    componentWillMount() {
        if (this.props.values) {
            const values = this.props.values.values ? this.props.values.values : this.props.values;
            this.setState({
                typeSelected: values.documentType,
                participantSelected: values.participant,
                groupCompanySelected: values.company,
                authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.company).map(as => ({
                    id: as.id,
                    label: as.label
                }))
            });
        }

    }

    addList() {
        return [
            {
                label: "Add new individual director",
                onClick: (value) => console.log(value),
                type: 'stakeholder',

            },
            {
                label: "Add new corporate director",
                type: 'stakeholder',

            }
        ]
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.values) {
            const values = nextProps.values.values ? nextProps.values.values : nextProps.values;
        }
    }

    filterAuthorizedByGroupCompany(groupCompany) {
        if (groupCompany) {
            let gc = this.findGroupCompany(groupCompany.id);
            return this.props.authorizedSignatories.filter(authorized => {
                return authorized.groupCompanyId === gc.id
            });
        }
        return [];
    }

    selectType = (documentType, extraParams, form) => {
        this.setState({
            typeSelected: documentType,
            dynamicParams: this.getDynamicParamsByType(documentType)
        }, () => {
            FormikHelper.setValueInTheCorrectPosition('terms', form, []);
            FormikHelper.setValueInTheCorrectPosition('documentType', form, documentType);
            this.state.dynamicParams.forEach((dynamicParam, i) => {
                FormikHelper.setValueInTheCorrectPosition(
                    `terms[${i}].key`,
                    form,
                    dynamicParam.key || 'NO_KEY'
                );
            })
        });
    };

    getTotalNumberOfShares(ref1, ref2, form) {
        if (ref1.current && ref2.current) {
            const percentage = Number(ref2.current.target.value);
            const totalOfOrdinaryShares = Number(ref1.current.target.value);
            const numberOfSIPShares = _.round(percentage / 100 * totalOfOrdinaryShares);
            form.setFieldValue('terms.shareCapital.numberOfSIPShares', numberOfSIPShares)
        }
    }

    getDynamicParamsByType(documentType) {
        const type = this.props.documentTypes.filter(agrType => agrType.code === documentType)[0] || {};
        return type.fields || [];
    }

    getGroupCompanies = () => {
        let authorizedSignatories = this.props.authorizedSignatories;
        let groupCompanies = this.props.groupCompanies;
        let companies = [];
        if (groupCompanies && authorizedSignatories) {
            companies = _.filter(groupCompanies, (groupCompany) => {
                let fined = _.filter(authorizedSignatories, (authorized) => {
                    if (groupCompany.authorizedSignatories) {
                        return groupCompany.authorizedSignatories.some(au => au.stakeholder.id === authorized.id);
                    }
                    return false;
                });
                if (fined) {
                    return true;
                }
                return false;
            });
        }
        return companies;
    };

    filterAuthorizedSignatoriesSelected(list) {
        return _.filter(list, element => !_.some(this.state.authorizedSignatories, director => element.value.id === director.id))
    }

    selectGroupCompany = (gcId, extraParams, form) => {
        const groupCompany = this.findGroupCompany(gcId);

        // this is for a weird behaviour when hit edit button after preview a document
        if (this.state.groupCompanySelected && groupCompany) {
            return null;
        }
        this.setState({
            groupCompanySelected: groupCompany
        });

        if (groupCompany) {

            if (!groupCompany.registeredAddress) {
                groupCompany.registeredAddress = {}
            }

            let authorizedSignatories = _.get(form, 'values.company.authorizedSignatories', []);

            const newFormValues = {
                ...form.values,
                company: {
                    id: gcId,
                    name: groupCompany.name || '',
                    country:typeof  _.get(groupCompany, 'country', null) ==='object'? _.get(groupCompany, 'country.code', null) : _.get(groupCompany, 'country', ''),
                    jurisdiction: _.get(groupCompany, 'jurisdiction', ''),
                    registeredNumber: groupCompany.registeredNumber || '',
                    type: groupCompany.type || '',
                    registeredAddress: {
                        ...groupCompany.registeredAddress, country:typeof  _.get(groupCompany, 'registeredAddress.country', null) ==='object'? _.get(groupCompany, 'registeredAddress.country.code', null) : _.get(groupCompany, 'registeredAddress.country', '')
                    },
                    authorizedSignatories: authorizedSignatories,
					recipientNotice: groupCompanyRecipientNotice(form.values.company, groupCompany)
                },
            };

            if(groupCompany.transactionCurrency && !form.values?.terms?.shareCapital?.currency) _.set(newFormValues,'terms.shareCapital.currency', groupCompany.transactionCurrency)

            form.setValues(newFormValues);

            this.setState({
                groupCompanySelected: groupCompany,
            })
        } else {
            form.setValues({
                ...form.values,
                company: {}
            });
        }
    };

    findGroupCompany(gcId) {
        const gc = _.find(this.props.groupCompaniesList,{id:gcId});
        return gc?{...gc}:null
    };

    selectAuthorized = (value, form, field, replace) => {
        const stakeholder = this.props.authorizedSignatories.find(e => e.id === value);
        const email = stakeholder ? stakeholder.email : '';
        replace(0, { id: value, email })
    };

    renderParticipantAuthorizedSignatory(field) {
        const { tooltips = {} } = this.props;

        if (this.state.participantSelected.isAnEntity) {
            return (
                <React.Fragment>
                    <div className={classes.ClaraInputSelect}>
                        <Field name='participant.authorizedSignatory.fullName'>
                            {({ field, form }) => (
                                <TextInput
                                    required={true}
                                    type='text'
                                    label='Corporate signatory '
                                    field={field}
                                    form={form}
                                    placeholder={"Corporate signatory"}
                                    tooltip={tooltips["Authorised Signatory"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name='participant.authorizedSignatory.email'>
                            {({ field, form }) => (
                                <TextInput
                                    required={true}
                                    type='text'
                                    label="Corporate signatory's email address "
                                    field={field}
                                    form={form}
                                    onChange={(e) => this.updateEmailValue(e, form)}
                                    placeholder={"Email address"}
                                    tooltip={tooltips['Authorised Signatory Email']}
                                />
                            )}
                        </Field>
                    </div>
                </React.Fragment>
            )
        }
    };

    updateEmailValue(e, form) {
        if (this.state.participantSelected.isAnEntity) {
            form.setFieldValue('participant.authorizedSignatory.email', e.target.value, false)
        }
    }

    getDateForPickerFromData(date) {
        if (!date) return undefined;
        return moment(date).toDate();
    }

    handleChange(e, field, form) {

        const date = e === null ? null : moment(e).format("YYYY-MM-DD");

        FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

        this.setState({
            [field.name]: e
        })
    }

    getAuthorizedSignatoriesList = (index, form) => {
        if (form.values && form.values.company) {
            const authorizedSignatories = this.filterAuthorizedByGroupCompany(form.values.company).map(as => ({
                id: as.id,
                label: as.label
            }))
            this.setState({
                authorizedSignatories: authorizedSignatories
            })
        } else {
            return [];
        }
    };

    getLabel = (key) => {
        switch (key) {
            case "fullName":
                if (this.state.participantSelected.isAnEntity) {
                    return "Company name";
                } else {
                    return "Name";
                }
            case "country":
                if (this.state.participantSelected.isAnEntity) {
                    return "Country of incorporation";
                } else {
                    return "Nationality";
                }
            case "address":
                if (this.state.participantSelected.isAnEntity) {
                    return "Registered address";
                } else {
                    return "Residential address";
                }
            case "passportOrRegistered":
                if (this.state.participantSelected.isAnEntity) {
                    return "Company number";
                } else {
                    return "Passport number";
                }
        }
    };

    getField = (key) => {
        switch (key) {
            case "passportOrRegistered":
                if (this.state.participantSelected.isAnEntity) {
                    return "participant.registeredNumber";
                } else {
                    return "participant.passportNumber";
                }
        }
    };

    getTooltip = (key, fieldName) => {
        switch (key) {
            case "fullName":
                if (this.state.participantSelected.isAnEntity) {
                    return "Participant's Company's Name";
                } else {
                    return "Funder's Name";
                }
            case "passportOrRegistered":
                if (this.state.participantSelected.isAnEntity) {
                    return "Company Number";
                } else {
                    return "Passport Number";
                }
            case "country":
                if (this.state.participantSelected.isAnEntity) {
                    return "Country";
                } else {
                    return "Nationality";
                }
            case "addressLine":
                if (this.state.participantSelected.isAnEntity) {
                    return "Registered Address - Address";
                } else {
                    return "Residential Address - Address";
                }
            case "addressCity":
                if (this.state.participantSelected.isAnEntity) {
                    return "Registered Address - City";
                } else {
                    return "Residential Address - City";
                }
            case "addressState":
                if (this.state.participantSelected.isAnEntity) {
                    return "Registered Address - State";
                } else {
                    return "Residential Address - State";
                }
            case "addressCountry":
                if (this.state.participantSelected.isAnEntity) {
                    return "Registered Address - Country";
                } else {
                    return "Residential Address - Country";
                }
            case "addressPostCode":
                if (this.state.participantSelected.isAnEntity) {
                    return "Registered Address - Post Code";
                } else {
                    return "Residential Address - Post Code";
                }
            case "recipientNotice":
                if (fieldName === 'company') {
                    return "Company's";
                } else {
                    return "Participant's";
                }
        }
    };

    getPlaceholder = (key) => {
        switch (key) {
            case "passportOrRegistered":
                if (this.state.participantSelected.isAnEntity) {
                    return "Company number";
                } else {
                    return "Passport number";
                }
        }
    };

    renderRecipientNotice(fieldName, isAnEntity) {
        const { tooltips = {} } = this.props;

        if (isAnEntity) {
            return (
                <React.Fragment>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.fullName`}>
                            {({ field, form }) => (
                                <TextInput
                                    required={true}
                                    type='text'
                                    label='Recipient for notices '
                                    field={field}
                                    form={form}
                                    placeholder={"Recipient's name"}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={`${classes.ContainerRow} ${classes.Select}`}>
                        <Field name={`${fieldName}.recipientNotice.address.country`}>
                            {({ field, form }) => (
                                <ClaraCountrySelector
                                    isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                    jurisdictionsEnabled={false}
                                    countryField={`${fieldName}.recipientNotice.address.country`}
                                    label={"Recipient's address "}
                                    setFieldValue={form.setFieldValue}
                                    initialValues={{ country: _.get(form.values, `${fieldName}.recipientNotice.address.country`, '') }}
                                    returnCountryAsString={_.isEmpty(_.get(form.values, `${fieldName}.recipientNotice.address.country.code`, ''))}
                                    additionalStyle={'ClaraCountrySelectorWizard'}
                                    tooltips={{
                                        country: tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Country"]
                                    }}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.address.street`}>
                            {({ field, form }) => (
                                <TextAreaInput
                                    type='text'
                                    label={' '}
                                    field={field}
                                    form={form} placeholder={"Address"}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Address"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.address.city`}>
                            {({ field, form }) => (
                                <TextInput
                                    type='text'
                                    field={field}
                                    form={form}
                                    placeholder={"City"}
                                    label={' '}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - City"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.address.state`}>
                            {({ field, form }) => (
                                <TextInput
                                    type='text'
                                    field={field}
                                    form={form}
                                    placeholder={"State / Region"}
                                    label={' '}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - State"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.address.zipCode`}>
                            {({ field, form }) => (
                                <TextInput
                                    type='text'
                                    field={field} form={form}
                                    label={' '}
                                    placeholder={"Post code"}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Post Code"]}
                                />

                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.email`}>
                            {({ field, form }) => (
                                <TextInput
                                    required={true}
                                    type='text'
                                    label="Recipient's email address "
                                    field={field}
                                    form={form}
                                    placeholder={"Email address"}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Email Address"]}
                                />
                            )}
                        </Field>
                    </div>
                </React.Fragment>
            )
        }
    };

    renderAgreementDurationFields(form) {
        const { tooltips = {} } = this.props;
        if (!_.get(form, 'values.terms.agreementDuration')) {
            return null
        }

        return (
            <React.Fragment>
                {
                    form.values.terms.agreementDuration === 'otherDuration' ?
                        <div className={classes.ContainerRow}>
                            <Field name='terms.otherDuration'>
                                {({ field, form }) => (
                                    <ClaraInputYearsMonths

                                        required={true}
                                        field={field}
                                        form={form}
                                        label={'Duration '}
                                        tooltips={tooltips}

                                    />
                                )}
                            </Field>

                        </div>
                        : null
                }
            </React.Fragment>
        );

    }

    getStakeholderAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add new individual stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
                placeholder: "Enter stakeholder's name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: false,
                    // roles : [{
                    // 	role:"SHAREHOLDER",
                    // 	groupCompany: this.state.groupCompanySelected.id,
                    // }]
                },
                paramsReturn: 'isAnEntity'
            },
        ]
    };

    getCompanyAdds = () => {
        return [{
            type: "groupCompany",
            label: "Add new company",
            textWarning: "This will create a new group company for your startup. You can add or amend the details of this group company on its profile page.",
            placeholder: "Company name",
            startupId: this.props.startup.id,
            params: null,
            paramsReturn: `
				name
				authorizedSignatories {
					id
					stakeholder {
						id
						email
						fullName
						roles {   id
        name
        groupCompany { id name}
        stakeholder { id }}
					}
				}
			`
        }]
    };

    getAuthorizedSignatoryAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add new individual stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
                placeholder: "Enter stakeholder's name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: false,
                    roles: [{
                        role: "DIRECTOR",
                        groupCompany: this.state.groupCompanySelected.id,
                    }]
                }
            }
        ]
    };

    handleStakeholderCreate = (value, name, additionalValue, form) => {

        let { stakeholders, stakeholderList } = this.props;
        let newSh = { id: value.id, fullName: name, ...additionalValue };
        stakeholderList.push(newSh);

        stakeholders.push({ id: value.id, label: name });
        // this.setState({ groupCompaniesList, groupCompanies });

    };

    handleGroupCompanyCreate = (value, name, additionalValue, form) => {

        let { groupCompanies, groupCompaniesList } = this.props;
        let newGc = { id: value.id, name, ...additionalValue };
        groupCompaniesList.push(newGc);

        groupCompanies.push({ id: value.id, label: name });
        // this.setState({ groupCompaniesList, groupCompanies });

    };

    handleAuthorizedSignatoryCreate = (value, name, additionalValue, form) => {

        let { authorizedSignatories } = this.props;
        const { groupCompanySelected } = this.state;

        authorizedSignatories.push({
            id: value.id,
            label: name,
            role: 'DIRECTOR',
            groupCompanyId: groupCompanySelected.id
        });

    };

    handleAuthorizedSignatoryUpdate = (value) => {

        const { authorizedSignatories, groupCompanySelected } = this.state;
        const groupCompany = this.findGroupCompany(groupCompanySelected.id);

        _.set(groupCompany,'authorizedSignatories',_.map(groupCompany.authorizedSignatories,auth=>({...auth})))
        groupCompany.authorizedSignatories.push({ stakeholder: value });
        authorizedSignatories.push(value);

    };

    render() {
        const isRequired = message => value => (!!value ? undefined : message);
        const { tooltips = {}, formType } = this.props;

        return (
            <div>
                <div className={classes.ContainerRow}>
                    {formType == 'uploadToEdit' ? (<div>
                        <Field name={`file`}>
                            {({ field, form }) => (
                                <ClaraUpload
                                    required={true}
                                    name={`filepond`}
                                    label='Upload your document '
                                    field={field}
                                    form={form}
                                />
                            )}
                        </Field>
                    </div>) : null}
                    <Field name='agreementName'>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='text'
                                label='Document name '
                                field={field}
                                form={form}
                                placeholder={"Document name"}
                                tooltip={tooltips['Document Name']}
                            />
                        )}
                    </Field>
                </div>
                <hr className={classes.Separator} />
                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                    <Field name='company.id'>
                        {({ field, form }) => (
                            <ClaraSelect
                                required={true}

                                field={field}
                                form={form}
                                label={"Select company"}
                                lists={this.getGroupCompanies()}
                                mode={"classic"}
                                callbacks={{
                                    createValues: this.handleGroupCompanyCreate,
                                    updateValues: this.selectGroupCompany
                                }}
                                placeholder={"Select from the list"}
                                tooltip={tooltips['Select Company']}
                            />
                        )}
                    </Field>
                </div>
                {
                    this.state.groupCompanySelected ?
                        <React.Fragment>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Company Details</h1>
                                <p>Please verify or update the company's information below</p>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.name'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label='Company name '
                                            field={field}
                                            form={form}
                                            placeholder={"Company name"}
                                            tooltip={tooltips["Company's Name"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={`${classes.ContainerRow} ${classes.Select}`}>
                                <Field name='company.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={true}
                                            countryField={'company.country'}
                                            returnCountryAsString={true}
                                            label={'Country of incorporation '}
                                            jurisdictionField={'company.jurisdiction'}
                                            jurisdictionLabel={'Jurisdiction '}
                                            otherJurisdictionField={'company.otherJurisdiction'}
                                            setFieldValue={form.setFieldValue}
                                            initialValues={{ jurisdiction:_.get(form.values, 'company.otherJurisdiction') || _.get(form.values, 'company.jurisdiction') }}

                                            additionalStyle={'ClaraCountrySelectorWizard'}
                                            tooltips={{
                                                country: tooltips["Company's Country"],
                                                jurisdiction: tooltips["Company's Jurisdiction"],
                                                other: tooltips['Other Jurisdiction']
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.type'>
                                    {({ field, form }) => (
                                        <TextType
                                            required={true}
                                            type='text'
                                            groupCompany={this.state.groupCompanySelected}
                                            label='Company type '
                                            field={field}
                                            form={form}
                                            placeholder={'Company type'}
                                            tooltip={tooltips["Company's Company Type"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.registeredNumber'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label='Company number '
                                            field={field}
                                            form={form}
                                            placeholder={'Company number'}
                                            tooltip={tooltips["Company's Company Number"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={`${classes.ContainerRow} ${classes.Select}`}>
                                <Field name='company.registeredAddress.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={false}
                                            countryField={'company.registeredAddress.country'}
                                            label={'Registered address '}
                                            returnCountryAsString={true}
                                            setFieldValue={form.setFieldValue}
                                            additionalStyle={'ClaraCountrySelectorWizard'}
                                            tooltips={{
                                                country: tooltips["Company's Registered Address - Country"]
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.registeredAddress.street'>
                                    {({ field, form }) => (
                                        <TextAreaInput
                                            type='text'
                                            label={' '}
                                            field={field}
                                            form={form}
                                            placeholder={"Address"}
                                            tooltip={tooltips["Company's Registered Address - Address"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.registeredAddress.city'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={'City'}
                                            tooltip={tooltips["Company's Registered Address - City"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.registeredAddress.state'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={'State / Region'}
                                            tooltip={tooltips["Company's Registered Address - State"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.registeredAddress.zipCode'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={"Post code"}
                                            tooltip={tooltips["Company's Registered Address - Post Code"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            {this.renderRecipientNotice('company', true)}
                            <hr className={classes.Separator} />
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Corporate Signatories</h1>
                                <p>Please add all the signatories required to sign this document on behalf of the company.</p>
                            </div>
                            <Field name='company.authorizedSignatories_error'>
                                {({ field, form }) => (
                                    <React.Fragment>
                                        <span hasError={_.get(form.errors, "company.authorizedSignatories")?"true":"false"}>
                                        <AuthorisedSignatories
                                                list={this.props.stakeholderList}
                                                fieldArrayName={'company.authorizedSignatories'}
                                            idGroupCompany={this.state.groupCompanySelected.id}
                                            handleAddListStakeholder={this.handleAuthorizedSignatoryUpdate}
                                            errorMessage={_.get(form.errors, "company.authorizedSignatories")}
                                        />
                                        </span>
                                    </React.Fragment>
                                )}
                            </Field>
                            <hr className={classes.Separator} />
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Shares allocated to the Plan</h1>
                                <p> The shares in your <a rel="noreferrer" href={"https://help.clara.co/en/articles/6385562-what-is-a-topco"} target="_blank">topco</a> which will be allocated to the plan.</p>
                            </div>
                            <Field name='terms.shareCapital.numberOfSIPShares'>
                                {({ field, form }) => {
                                    return (
                                        <div className={classes.ContainerRow}>
                                            <TextInput
                                                required={true}
                                                type='number'
                                                label='Total number of shares allocated to the plan '
                                                field={field}
                                                form={form}
                                                placeholder={'Number'}
                                                rightLabel={'shares'}
                                                tooltip={`${tooltips['Number of Shares']}`}
                                                link={'https://academy.clara.co/article/T71lIzBWSIEdCu2UBIgFi'}
                                            />
                                        </div>
                                    )
                                }}
                            </Field>
                            <Field name='terms.shareCapital.parValue'>
                                {({ field, form }) => (
                                    <div className={classes.ContainerRow}>
                                        <TextInput
                                            required={true}
                                            type='share'
                                            label='Par value '
                                            field={field}
                                            form={form}
                                            rightLabel={_.get(form.values, 'terms.shareCapital.currency', '')}
                                            placeholder={'Amount'}
                                            tooltip={`${tooltips['Par Value of Shares']}`}
                                            link={'https://academy.clara.co/article/2g41dRh3pLQH8w7QEvBI5K'}
                                        />
                                    </div>
                                )}
                            </Field>
                            <hr className={classes.Separator}></hr>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Effective Date</h1>
                                <p>The date on which the Plan comes into effect.</p>
                            </div>
                            <Field name='terms.effectiveDate'>
                                {({ field, form }) => (
                                    <div className={classes.ContainerRow}>
                                        <TextInput
                                            required={true}
                                            type='date'
                                            label='Effective date  '
                                            field={field}
                                            form={form}
                                            placeholder={'dd/mm/yyyy'}
                                            tooltip={tooltips['Effective Date']}
                                        />
                                    </div>
                                )}
                            </Field>
                        </React.Fragment>
                        : null
                }

                {formType === 'uploadToEdit' ? <div>
                    <Field name='confirmSameData'>
                        {({ field, form }) => (
                            <Checkbox
                                className={classes.sameDataCheckbox}
                                field={field}
                                validate={isRequired('This field is required')}
                                form={form}
                                required={true}
                                label='All document details are verified and correct.'
                            />
                        )}
                    </Field>
                </div> : null}
            </div>

        );
    }

}

export default ShareIncentivePlanDoc;

import React, { useMemo, useEffect } from 'react'
import Parser from "html-react-parser";
import _ from 'lodash'
import { useDispatch } from 'react-redux';
import { Constants } from '../../../../v1/utils';
import cls from './LeftContentTour.module.scss'
// import { hideTourSession } from '../../../../store/actions/session';
const LeftContentTour = ({ pages, actualStep, ...props }) => {

    const dispatch = useDispatch()


    useEffect(() => {
        if (actualStep === pages.length - 1) {
            props.setLabelButtonNext(`Let's start`);
            //TODO REDUX: ver que hace el hideTour
            // hideTourSession(dispatch);
        } else {
            if (props.buttonNext.onClick) {
                props.setOnClickButtonNext(null);
            }
            props.setLabelButtonNext('Next');
   
        }


    }, [actualStep])

    const page = useMemo(() => {
        return _.get(pages, `[${actualStep}]`);
    }, [JSON.stringify(actualStep), JSON.stringify(pages)]);

    return (
        <>
            <h1 className={cls.title_tour_content_left}>
                {Parser(_.get(page, `subtitle`, ''))}
            </h1>
            <div className={cls.text_tour_content_left} >
                {Parser(_.get(page, `description`, ''))}
            </div>

        </>
    )
}

export default LeftContentTour

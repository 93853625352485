import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateLabel from 'src/modules/generic/components/Inputs/LabelInput';
import * as Yup from 'yup';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import classes from './classes.module.scss';
import ClaraList from "src/modules/generic/components/Lists/ClaraList";
import _ from 'lodash';
import { HiddenFieldForm } from "src/components/forms/contents";
import Typography from 'src/modules/generic/components/Texts/Typography';
import Stakeholder from 'src/components/modules/clara/startups/entities/classes/Stakeholder';

function ReviewDirectors() {

  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.ReviewDirectors ?? {}
  }, [context]);

  const handleCancel = (values) => {
    cancel();
  }

  const handleSubmit = async (values) => {
    next(values)
  }

  useEffect(() => {
    dispatch(addAssistantText('If you need to add or remove directors, please select ‘No’ to this question. You will then be prompted in the next screen to describe the changes you need.<br><br>Fees apply to remove or add directors.'))
    return () => {
      dispatch(clearAssistatText())
    }
  }, [dispatch])

  const schema = Yup.object().shape({
    accept: Yup.boolean()
      .required('Required')
      .test('is-true', '', value => value === true),
  });  

  const options = [
    {
      value: false,
      label: "No",
    },
    {
      value: true,
      label: "Yes",
    }
  ];  

  const stakeholdersDirectors = useMemo(() => {
    const directors = context?.initialData?.stakeholderList;
    const result = _.map(directors, (dir) => {
      const stakeholderClass = new Stakeholder(dir);
      return {
        name: dir?.fullName,
        avatar: dir?.avatar,
        isFounder: dir?.isFounder,
        isLocked: stakeholderClass.hasPincas() ?? false,
      }
    });
    return result;
  }, [context]);

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: 'Review and confirm directors',
          skeleton: null,
          subTitle: 'Review directors',
          variant: 'simple'
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined} >Confirm</SubmitButton>,
        }}
      >
        <>
          <ClaraList
            items={stakeholdersDirectors}
          >
          </ClaraList>
          <TemplateLabel 
            label={'Please confirm this is correct'}
            isRequired
            variant='top'
          >
            <ControllerInput
              render={CheckboxButtonSimple}
              name='accept'
              options={options}
              defaultValue={[]}
              onChange={(value) => {
              }}
              isMultiple={false}
              classNameButton={classes.buttonCheckBox}
            />
          </TemplateLabel>
          <TemplateLabel 
            label={''}
          >
            <HiddenFieldForm
              fieldsWatch={"accept"}
              conditionHideCallback={(value) => {
                if (typeof value === 'boolean') {
                  return value === false;
                }
              }}
            >
              <Typography component='p' variant='h4' fontWeight="semibold">
                <span dangerouslySetInnerHTML={{ __html: "Please use the blue plus button on the header to add new directors or go to their details page to remove them. Fees apply." }}></span>
              </Typography>
            </HiddenFieldForm>
          </TemplateLabel>
        </>
        <div>&nbsp;</div>
      </TemplateWizard>
    </Form>
  );
}

export default ReviewDirectors;
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../../../generic/components/Buttons/Button';
import { StepProps } from "../../../../generic/components/Wizard/Step";
import useCustomMachine from '../../../../generic/hooks/useCustomMachine';
import useTranslate from '../../../../generic/hooks/useTranslate';
import TemplateWizard from '../../../../generic/templates/Modal/TemplateWizard';
import useSession from '../../../../session/hooks/useSession';
import Form from "../../../GroupCompany/Forms/FormCompanyMoreDetails";
import useUpdateStakeholder from "src/modules/startup/onBoarding/core/graphql/mutations/useUpdateStakeholder";

const Step1: FC<StepProps> = () => {

  const { translate } = useTranslate();
  const { next, send, state, prev } = useCustomMachine();
  const { id: _stakeholderId } = useParams<any>();
  const stakeholderId = state?.context?.data?.stakeholderId ?? _stakeholderId;
  const { startupId } = useSession();

  const { updateStakeholder: updateCompanyDetail } = useUpdateStakeholder({}, {});

  const getValuesForMutation = (state, valuesStep) => {
    const { context } = state;
    return { ...valuesStep, address: context.data.company_info }
  };

  const handleSubmit = React.useCallback(async (values) => {
    const variables = {
      stakeholderId,
      startupId,
      stakeholderData: {
        stakeholder: values
      }
    }
    await updateCompanyDetail({ variables });
  }, [updateCompanyDetail, stakeholderId, startupId]);

  const handleCompleteSubmit = async values => {
    await handleSubmit(getValuesForMutation(state, values));
    next(values);
  };

  const handleCancel = async values => {
    send("CANCEL");
  };

  const handlePrevious = () => {
    prev('PREV');
  }

  return (
    <React.Fragment>
      <Form onCompleteSubmit={handleCompleteSubmit} Template={TemplateWizard} propsTemplate={{
        title: translate(`MODULES.MAP.WIZARD_COMPANY_DETAIL_MORE_INFO_FORM_TITLE`),
        subTitle: translate(`MODULES.MAP.WIZARD_COMPANY_DETAIL_FORM_SUBTITLE`)
      }}
        buttons={{ previous: <Button type={'button'} onClick={handlePrevious} variant="secondary">Previous</Button>, cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }}>
      </Form>
    </React.Fragment>
  )
}

export default Step1;

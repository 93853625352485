import React from 'react';
import {Formik} from 'formik';
import {ChatDataProtection} from '../../../../components';
import classesForm from '../Form.module.scss';
import _ from 'lodash'
import {useParams} from 'react-router-dom';

const DataControlerForm = React.memo(({ initialValues, onSubmit, closeForm }) => {


    const params = useParams();

    return (
        <React.Fragment>

            {
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validate={null}
                    onSubmit={onSubmit}
                    isInitialValid
                >

                    {({
                        values,
                        errors,
                        dirty,
                        handleSubmit,
                        isSubmitting,
                        handleReset,
                        setFieldValue,
                        initialValues,
                        isValid,
                        setFieldError,
                        validateForm,
                    }) => {

                        return (

                            <React.Fragment>
                                <div className={classesForm.containerData} >
                                    <ChatDataProtection
                                        initialValueStart={{ matterId: params.matterId }}
                                        isForm
                                        notFlow={true}
                                        nameForm={"DIRECTOR_INDIVIDUAL_FORM"}
                                        className={{
                                            contentField: classesForm.contentField,
                                            buttonOption: classesForm.buttonOption
                                        }}
                                    />
                                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                                    <hr></hr>
                                    <div className={`botoneraButtons ${classesForm.containerButtons}`}>
                                        <button className={`genericBlue`} onClick={closeForm} >Cancel</button>
                                        <button className={`genericBlue`} onClick={handleSubmit} disabled={!isValid || _.get(values, 'dataProtection', []).length < 1 } >Update </button>
                                    </div>

                                </div>

                            </React.Fragment>
                        )

                    }
                    }
                </Formik>
            }
        </React.Fragment>
    )
})

export default DataControlerForm;



import React, { FC } from "react";
import Button from "src/modules/generic/components/Buttons/Button";
import { CheckboxOptions } from "src/modules/generic/components/Inputs/Checkbox";
import DeleteForm from "src/modules/generic/forms/Delete";
import useModal from "src/modules/generic/hooks/useModal";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import TemplateDelete from "src/modules/generic/templates/Modal/TemplateDelete";
import Reasons from "../../components/Reasons";
import classes from '../../classes.module.scss';
import useMachine from "src/modules/generic/context/MachineContext/useMachine";

const ReasonsNotAbleToDelete: FC<{}> = ({}) => {

  const { closeModal } = useModal()
  const { translate } = useTranslate()
  const { context, next,send } = useMachine();
  const { connections } = context?.getConnections
  const { groupCompany } = context?.deleteVerification

  const handleCancel = () => {
    send('CANCEL')
  }

  const onSubmit = async () => {
    next()
  }

  const options: Array<CheckboxOptions<string>> = [{ value: 'resolve', label: translate('MODULES.GROUP_COMPANY.DELETE.RESOLVE') }];
  const title =  translate('MODULES.GROUP_COMPANY.DELETE.TITLE_CONNECTIONS', { groupCompany })

  return (

        <DeleteForm
          propsTemplate={{
            title,
            description: <Reasons connections={connections} />
          }}
          onCompleteSubmit={onSubmit}
          checkBoxProps={{ options }}
          isThereConnections={true}
          buttons={{
            cancel: <Button type={'button'} className={classes.cancelButton}
              onClick={handleCancel}>Cancel</Button>
          }}
          Template={TemplateDelete}
        />
  )
}

export default ReasonsNotAbleToDelete
import React from "react";
import cls from "./classes.module.scss";
import Icon from "../../../Icon";
export const ICONS = {
  "DOC": "DOC",
  "JPG": "JPG",
  "PNG": "PNG",
  "PDF": "PDF",
  "XLXS": "XLXS"
}
const NoFilesUploaded = ({ icons = Object.keys(ICONS) }) => {


  return (
    <div className={cls.containerNoFilesUpload}>
      <div>
        {icons.map((icon, index) => {
          return (
            <Icon
              key={icon + index}
              icon={icon}
              isClara
              size={"48px"}
              color={"#2c74ff"}
            />
          );
        })}
      </div>
      <p className={cls.text}>
        Drag and drop file here to upload, or <span>Browse</span>
      </p>
    </div>
  );
};

export default NoFilesUploaded;

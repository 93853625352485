import React from 'react';
import { useDispatch } from "react-redux";
import Button from "../../../../../generic/components/Buttons/Button";
import useMachine from '../../../../../generic/context/MachineContext/useMachine';
import useModal from "../../../../../generic/hooks/useModal";
import useTranslate from '../../../../../generic/hooks/useTranslate';
import { clearAssistatText } from "../../../../../generic/store/action";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import FormStep4SelectTopco from '../../forms/formStep4SelectTopco';
import _ from "lodash";

/**
 * Step4 select topco
 * @param {*} param0
 * @returns
 */
function Step4SelectTopco({ openParam }) {

  const { next, context, prev } = useMachine();
  const { translate } = useTranslate()
  const dispatch = useDispatch();
  const { closeModal: onClose } = useModal();

  const handleCancel = (values) => {
    dispatch(clearAssistatText())
    onClose();
  }

  const handleCompleteSubmit = async (values) => {
    next(values)
  }

  const handlePrev = () => {
    prev()
  }

  const initialData = {
    allCompanies: _.get(context, 'allCompanies', {}),
    approvedJurisdictions: _.get(context, 'approvedJurisdictions', {}),
  }

  return (
    <FormStep4SelectTopco
      initialValues={initialData}
      handlePrev={handlePrev}
      key={"step4SelectTopco"}
      buttons={{
        cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
      }}
      onCompleteSubmit={handleCompleteSubmit}
      propsTemplate={{
        title: "Select your topco",
        subTitle: "Select your topco",
      }}
      Template={TemplateWizard}
    />
  );
}

export default Step4SelectTopco;

import React, { forwardRef } from 'react';
import classes from './Currency.module.scss';
import ErrorInput from "../../../modules/generic/components/Inputs/ErrorInput";
import TooltipInput from "../../generic/Tooltips/TooltipIput/TooltipInput";
import useTextLang from "../../../hooks/custom/useTextLang";
import classnames from 'classnames';
import MaskedInput from 'react-text-mask';
import { helper, Constants } from "../../../v1/utils";
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import useTranslate from "src/modules/generic/hooks/useTranslate";

export interface CurrencyProps {
  type?: string;
  mask?: any;
  maskParams?: {};
  guide?: any;
  value?: string;
  className?: string;
  placeholder?: string;
  tooltip?:any; 
  error?:any; 
  errorMessage?:any; 
  isForm?:any; 
  name?:string; 
  afterComponent?:any; 
  decorator?:any; 
  onChange?:any; 
  classNameError?:any; 
  showErrorIcon?:any; 
  isSubmitting?:any; 
  disabled?:any; 
  defaultValue?: string; 
  innerRef?:any; 
  formState?:any; 
  fieldState?:any; 
  variant?: string; 
  needOnBlur?: boolean;
  currency: string;
}
const Currency: React.FC<CurrencyProps> = forwardRef (({ 
  type = "text",
  mask = null,
  maskParams = {},
  guide,
  value = '',
  className,
  currency,
  placeholder = 'MODULES.CLARA.ENTER_AMOUNT',
  tooltip, 
  error,
  errorMessage,
  isForm,
  name,
  afterComponent,
  decorator,
  onChange,
  classNameError,
  showErrorIcon,
  isSubmitting,
  disabled,
  defaultValue = '',
  innerRef,
  formState,
  fieldState,
  variant = 'normal',
  needOnBlur = true,
  ...props
  }: CurrencyProps, ref) => {
    const { translate } = useTranslate();

    const placeholderTranslate = useTextLang(translate(placeholder));
    // const [amount, setAmount] = React.useState(value || defaultValue);
    let maskType = type;
    let localMask = mask;
    let maskConfig = null;

    if (mask == null && Constants.TYPE_FORMATS[type.toUpperCase()]) {
      let format = helper.getFormatByKey(Constants.TYPE_FORMATS[type.toUpperCase()], maskParams) || {};
      maskConfig = {
        prefix: format.prefix ? format.prefix : "",
        includeThousandsSeparator: !!(format.thousandSeparator),
        thousandsSeparatorSymbol: format.thousandSeparator,
        allowDecimal: true,
        decimalSymbol: format.decimalSeparator,
        decimalLimit: '3',
        allowNegative: false,
        allowLeadingZeroes: format.fixedDecimalScale,
      }
      type = format.inputType ? format.inputType : "text";
      maskType = format.maskType ? format.maskType : maskType;
      localMask = createNumberMask(maskConfig);
    }

    const handleOnChangeType = (value, isOnBlur) => {
      switch (maskType) {
        case 'percent':
        case 'number':
          let localMaskValue = value.replaceAll(maskConfig.thousandsSeparatorSymbol, '').replaceAll(maskConfig.decimalSymbol, '.');
          if (isOnBlur) {
            localMaskValue = Number(localMaskValue).toFixed(maskConfig.decimalLimit);
          }
          return localMaskValue;
        case 'share':
          let localMaskValue2 = value.replaceAll(maskConfig.thousandsSeparatorSymbol, '').replaceAll(maskConfig.decimalSymbol, '.');
          if (isOnBlur) {
            localMaskValue2 = Number(localMaskValue).toFixed(maskConfig.decimalLimit);
          }
          return localMaskValue2;
        case "phoneNumber":
          if (isOnBlur) {
            localMaskValue = Number(value);
          }
          return localMaskValue;
        default:
          return value;
      }
    };
    const handleBlur = (e) => {
      if (needOnBlur) {
        const { onBlur, trim = true } = props;
        //Remove all spaces at start and end of the string
        if (type === 'text' && trim && e.target.value) {
          e.target.value = e.target.value.trim();
          onChange && onChange(handleOnChangeType(e.target.value), true)
        }
        if(type === 'email'){
          e.target.value = e.target.value.toLowerCase();
        }
        onBlur && onBlur(e);
      }
    };

    const handleChange = (value) => {
      onChange && onChange(value);
    }

    const onFocus = (e) => {
      props.onFocus && props.onFocus(e);
    }

    return (
      <div className={classnames(` ${className}`,{
        [classes.TextInputSmall]: variant=='small',
        [classes.TextInput]: variant=='normal',
      })} >
        {currency && (<div className={classes.currency} onChange={undefined}>{currency}</div>)}
        <TooltipInput 
          label={tooltip} 
          classes={undefined} 
          size={undefined} 
          align={undefined}>
          {React.createElement(localMask ? MaskedInput : "input", {
            ...props,
            id: `${name}`,
            mask: localMask,
            guide,
            type,
            name,
            ref:innerRef,
            value : value || defaultValue,
            disabled: isSubmitting || disabled,
            placeholder: placeholderTranslate,
            autoComplete: "nope",
            onChange: e => handleChange(handleOnChangeType(e.target.value.trimLeft())),
            className: classnames({
              [classes.borderErrorInput]: errorMessage || error,
              [classes.afterComponentPadding]: afterComponent,
              [classes.LeftLabelPadding]: currency,
            }, classes.input),
            onBlur: (e) => handleBlur(e),
            onFocus
          })}
        </TooltipInput>
        {afterComponent && <div className={`afterComponent ${classes.afterComponent}`}>{afterComponent}</div>}
        {errorMessage && <ErrorInput message={errorMessage} classNameError={classNameError} showErrorIcon={showErrorIcon} component={error} />}
      </div>
    );
// }
});

export default Currency

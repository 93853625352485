import FirstStep from "./Steps/FirstStep";
import SecondStep from "./Steps/SecondStep";
import ThirdStep from "./Steps/ThirdStep";
import ForthStep from "./Steps/ForthStep";
import Machine from "../../../../generic/components/Machine/MachineClass";

const isAffiliatedCompany = ({data}) => data.companies_affiliations_form.isThereAffiliatedCompanies
const isNotAffiliatedCompany = ({data}) => !data.companies_affiliations_form.isThereAffiliatedCompanies

const machineDefinition = {
    initial:'name_special_meaning_form',
    states:{
        name_special_meaning_form:{
            on:{
                NEXT:{target:'provide_translation_form',actions:'setContext'},
                CANCEL:'finish_set_up'
            }
        },
        provide_translation_form:{
            on:{
                NEXT:{target:'companies_affiliations_form',actions:'setContext'},
                PREVIOUS:'name_special_meaning_form',
                CANCEL:'finish_set_up',
            }
        },
        companies_affiliations_form:{
            on:{
                NEXT:{target:'is_there_affiliations_company',actions:'setContext'},
                PREVIOUS: 'provide_translation_form',
                CANCEL:'finish_set_up'
            }
        },
        is_there_affiliations_company:{
            exit: '',
            on: {
                '': [
                    {target: 'commercial_license_form', cond: 'isAffiliatedCompany'},
                    {target:'finish_set_up', cond: 'isNotAffiliatedCompany'},
                ]
            }
        },
        commercial_license_form:{
          on:{
              NEXT:{target:'finish_set_up',actions:'setContext'},
              PREVIOUS:'companies_affiliations_form',
              CANCEL:'finish_set_up'
          }
        },
        finish_set_up: {
            type: 'final',
            entry: 'final',
        },
        onDone: {
            actions: 'stopMachine'
        }
    }
}
const options = {
    guards:{
        isAffiliatedCompany,
        isNotAffiliatedCompany
    }
}

const steps = {
    name_special_meaning_form: FirstStep,
    provide_translation_form: SecondStep,
    companies_affiliations_form: ThirdStep,
    commercial_license_form: ForthStep
}
class MachineSetUp extends Machine {
    constructor() {
        super(machineDefinition,options,steps);
    }
}
export default MachineSetUp
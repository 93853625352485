import React from 'react';
import classesModule from "./ForbiddenView.module.scss"
import image from "../../../images/404.svg"
import Text from "../../../components/text/Text/Text"

const ForbiddenView =({...props  })=> {

  return (
    <React.Fragment>
      <div className={`${classesModule.ForbiddenView}`}>
            <img src={image} />
            <div className={classesModule.Title}><Text uuid ={"VIEW_FORBIDDEN_PAGE_TITLE"}/></div>
            <div className={classesModule.SubTitle}><Text uuid ={"VIEW_FORBIDDEN_PAGE_SUBTITLE"}/></div>
      </div>
    </React.Fragment>
  );
}

export default ForbiddenView;
import useMutation from "src/hooks/custom/useMutation";
import useSession from "src/modules/session/hooks/useSession";

export const UPSERT_ADDRESSES_DIRECTORS = `
mutation upsertRenewalData(
    $startupId: ID!
    $groupCompanyId: ID!
	$renewalData: RenewalDataInput
  ) {
    upsertRenewalData(
      startupId: $startupId
      groupCompanyId: $groupCompanyId
      renewalData: $renewalData
    ) {
      id
  }
}
`;

const useConfirmDirectors = () => {
  const { startupId } = useSession()
  const [mutation] = useMutation(
    UPSERT_ADDRESSES_DIRECTORS,
    { showSuccessNotification: true }
  );

  const save = async (groupCompanyId) => {
    const data = {
      variables: {
        startupId,
        groupCompanyId,
        renewalData: {
          companyTasksDetails: {
            areAddressesConfirmed: true
          }
        }
      }
    }
    const submitData = await mutation(data)
    return submitData
  }

  return save;
};

export default useConfirmDirectors;

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Modal from '../../../../modules/generic/components/Modal/Default';
import cls from './classes.module.scss'
import useSession from '../../../../modules/session/hooks/useSession';
import { Constants } from "../../../../v1/utils";


const RedirectBackOffice = () => {

  const { token, startupId } = useParams<{ token: string, startupId: string }>();
  const { initSession, selectStartup } = useSession();

  const handleAutoLogin = async () => {
    await initSession(token, new Date());
    await selectStartup(startupId, Constants.PAGES.MODULES.MAP.maps);
  }

  useEffect(() => {
    handleAutoLogin();
  }, [])

  return (
    <Modal
      BackdropProps={{
        classes: { root: cls.container_modal }
      }}
      open={true}>
      <div className={cls.containerPadding}>
        Redirect to Startup...
      </div>
    </Modal>
  )
}

export default RedirectBackOffice

import { EditRoleView } from "../Steps";
import TemplateWizardSkeletonMachine
    from "../../../../generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine";
import { useMemo } from "react";

const useSteps = () => {
    return  useMemo(() => ({
        initialData: {
            render: TemplateWizardSkeletonMachine,
            renderProps: {
                title: "",
                subTitle: ""
            }
        },
        pincasData: {
            render: TemplateWizardSkeletonMachine,
          },
        editRoleView: {
          render: EditRoleView,
          stepperPosition: 0
        },
        upsertRole: {
            render: TemplateWizardSkeletonMachine,
            renderProps: {
                title: "",
                subTitle: ""
            }
        }
    }),[]);
}

export default useSteps;
import React from 'react'
import {ReactComponent as ImgPassport} from '../../../../../images/passport-img.svg'
import classesPassport from './ClaraInputPassport.module.scss';
import {TextInput} from '../../../index';
import {Field} from 'formik'

const ClaraInputPassport = ({ field, form, label }) => {

    return (
        <div className={`${classesPassport.containerPassport}`} >

            <label>{label}</label>

            <Field name={`${field.name}`}>
                {({ form, field }) => (
                    <TextInput
                        form={form}
                        field={field}
                        type='text'
                        placeholder={"Passport Number..."}
                        className={classesPassport.inputCode}
                    />

                )}


            </Field>


            <ImgPassport style={{justifySelf:"start"}} />

        </div>
    )
}

export default ClaraInputPassport;
import { useMemo } from 'react';

const useActions = () => {

  return useMemo(() => {

    return {

    }

  }, []);
}

export default useActions;

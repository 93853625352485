
export default {
  HEALTH_CHECK: "001",
  SHARE_PROFILE: "002",
  DOCUMENTS: "003",
  GENERATE_DOCUMENTS: "004",
  CLARA_REPORTS: "010",
  START_SUBSCRIPTION:"START_SUBSCRIPTION",
  START_EXTENDED_SUBSCRIPTION:"START_EXTENDED_SUBSCRIPTION",
  SCALE_ANNUAL_SUBSCRIPTION_MONTHLY:"SCALE_ANNUAL_SUBSCRIPTION_MONTHLY"
};

import _, { toInteger } from "lodash";
import moment from "moment";
import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { StepProps } from "../../../../../../../../generic/components/Wizard/Step";
import useCustomMachine from "../../../../../../../../generic/hooks/useCustomMachine";
import useTranslate from "../../../../../../../../generic/hooks/useTranslate";
import {
  addAssistantText,
  clearAssistatText,
} from "../../../../../../../../generic/store/action";
import useSession from "../../../../../../../../session/hooks/useSession";
import useUpsertEquity from "../../../../../../graphql/mutation/upsertEquity";
import SelectDocumentStep from "../../../../../../wizard/steps/SelectDocumentStepForm";

const SelectDocumentShareStep: FC<StepProps> = ({}) => {

  const { state } = useCustomMachine();
  const { mutation } = useUpsertEquity({ showNotificationSuccess: false });
  const { startupId } = useSession();
  const dispatch = useDispatch();
  const { translate } = useTranslate();

  useEffect(() => {
    dispatch(clearAssistatText());
    dispatch(
      addAssistantText(
        translate("MODULES.EQUITY.STEPS_SHARES.SELECT_DOCUMENT_STEP.TOOLTIP")
      )
    );
  }, []);

  const getCompany = (): object => {
    if (_.isEmpty(_.get(state, "context.globalData", [])))
      return _.get(state, "context.data.select_companies.companies[0]");
    else
      return _.get(
        state,
        `context.globalData[0].select_companies.companies[${_.get(
          state,
          "context.numbersOfIteration",
          0
        )}]`
      );
  };

  const getCurrency = (): string => {
    const company = getCompany();
    return _.get(company, "transactionCurrency", "USD")
      ? _.get(company, "transactionCurrency", "USD")
      : "USD";
  };

  const handleCompleteSubmit = async (values) => {
    const equityData = {
      issuedDate: moment(
        _.get(state, "context.data.add_equity_form.issuedDate")
      ).format("YYYY-MM-DD"),
      // amount: toInteger(_.get(state, "context.data.add_equity_form.amount")),
      // price: toInteger(_.get(state, "context.data.add_equity_form.price")),
      amount: _.get(state, "context.data.add_equity_form.amount"),
      price: _.get(state, "context.data.add_equity_form.price"),
      currency: getCurrency(),
      newShareClass: _.get(state, "context.data.add_equity_form.newShareClass"),
      documents: { documentsUploaded: [{ ...values.document }] },
    };
    await mutation({
      startupId,
      shareType: "SHARE",
      isThereSIP: false,
      owner: _.get(state, "context.paramsData"),
      groupCompanyId: _.get(getCompany(), "id"),
      equityData,
    });
  };

  return (
    <>
      <SelectDocumentStep
        onCompleteSubmit={handleCompleteSubmit}
        type={"SHARE"}
      />
    </>
  );
};
export default SelectDocumentShareStep;

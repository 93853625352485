const upsertEquity = `
mutation upsertEquity(
    $groupCompanyId:String,
    $startupId: String, 
    $owner: OwnerInput, 
    $shareType: String,
    $equityData: EquityInput) {
      upsertEquity (
        $groupCompanyId:groupCompanyId,
          startupId: $startupId, 
          owner:$owner,
          shareType: $shareType,
          equityData: $equityData
      ) {
            id
      }
}`;

const upsertConvertibles = ` mutation upsertConvertible(
  $startupId: String
  $owner: OwnerInput
  $convertibleData: ConvertibleInput
) {
  upsertConvertible(
    startupId: $startupId
    owner: $owner
    convertibleData: $convertibleData
  ) {
    amount
  }
}`


const upsertWarrants = ` mutation upsertWarrant(
  $startupId: String
  $owner: OwnerInput
  $warrantData: WarrantInput
) {
  upsertWarrant(
    startupId: $startupId
    owner: $owner
    warrantData: $warrantData
  ) {
    amount
  }
}`

const upsertShareClassIncentivePlan= `mutation upsertComponentShareIncentivePlan(
  $startupId: ID!
  $shareIncentivePlan: ComponentShareIncentivePlanInputType! 
) {
  upsertComponentShareIncentivePlan(
    startupId: $startupId
    shareIncentivePlan: $shareIncentivePlan
    
  ) {
    id
    name
  }
}
`;

const upsertShareClass =`mutation upsertComponentShareClass(
  $startupId: String!
  $shareClass: ComponentShareClassInputType!
) {
  upsertComponentShareClass(
    startupId: $startupId
    shareClass: $shareClass
  ) {
    id
    name
    shareIncentivePlans{
        id
        name
    }
  }
}
`;

const deleteShareClass = `mutation deleteShareClass( 
  $startupId: ID
  $groupCompanyId: ID
  $shareClassId: ID
 ){
   deleteShareClass(
     startupId: $startupId
     groupCompanyId: $groupCompanyId
     shareClassId: $shareClassId 
   )
 }
 
 `

 const  deleteShareIncentivePlan = `mutation deleteShareIncentivePlans( 
  $startupId: ID
  $shareIncentivePlanId: ID
  $shareClassId: ID
 ){
   deleteShareIncentivePlans(
     startupId: $startupId
     shareIncentivePlanId: $shareIncentivePlanId
     shareClassId: $shareClassId 
   )
 }
 `

export {
    upsertEquity,
    upsertConvertibles,
    upsertWarrants,
    upsertShareClass,
    upsertShareClassIncentivePlan,
    deleteShareClass,
    deleteShareIncentivePlan
}

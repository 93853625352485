import { useMemo } from "react";
import { assign } from 'xstate';
import useUpsertEquity from "../../graphql/mutation/upsertEquity";
import useUpsertWarrant from "../../graphql/mutation/upsertWarrant";
import useUpsertConvertible from "src/modules/startup/equity/graphql/mutation/upsertConvertible";
import {isArray,get,omit,some,find} from 'lodash'
import { stripTypenames } from "src/v1/utils/graphql-util";
import  useSession  from 'src/modules/session/hooks/useSession';
const useActions = () => {
    const {mutation} = useUpsertEquity({showSuccessNotification:true});
    const {upsertWarrant} = useUpsertWarrant({showSuccessNotification:true});
    const {upsertConvertible} = useUpsertConvertible({showSuccessNotification:true});
    const {startupId} = useSession()
    const mapDocuments = (documents) => {
        return {
          documentsUploaded: documents,
          uploadedFiles: [],
        };
      };
    const getCurrency = (id,groupCompanies) => {
        const company = find(groupCompanies,{id})
        return company.transactionCurrency || 'USD'
    }

    const getType= (id,stakeholdersGroupCompanies) => {
        return some(stakeholdersGroupCompanies.stakeholders,{id})?'stakeholder':'groupCompany'
    }
    return useMemo(() => ({
        setContextAfterStep: assign((context, event) => {
          const values = get(event, 'payload.values');
          const solution = { ...context, ...values };
          return solution;
        }),
        setSelectParty: assign((context, event) => {
            const values = get(event, 'payload.values');
            return values.name?{...context,selectCompany:{...values}}:{...context,selectStakeholder:{...values}}
        }),
        upsertEquity: async (context, event) => {
            const values = get(event, 'payload.values');
            const { owner, equity } = values;
            const variables = {
                startupId,
                shareType: context.type,
                isThereSIP: context.type === 'OPTION',
                groupCompanyId: get(values, "groupCompany.id"),
                equityData: {
                    ...equity,
                    currency: getCurrency(get(values, "groupCompany.id"),context.getStakeholdersGroupCompanies.groupCompanies),
                    price: equity.price ? equity.price : null,
                    shareClass: get(equity, "shareClass.id"),
                    shareIncentivePlan:context.type === 'OPTION'? get(equity, "shareIncentivePlan.id"):null,
                    documents: isArray(equity.documents)
                    ? mapDocuments(equity.documents)
                    : equity.documents,
                },
            owner: { id: owner.id, type:getType(owner.id,context.getStakeholdersGroupCompanies) },
        };
        await mutation({ ...stripTypenames(omit(variables,'equityData.type')) });
    },
        upsertWarrant: async (context, event) => {
        const values = get(event, 'payload.values');
        const { owner, warrant } = values;
        const variables = {
            startupId,
            owner: { id: owner.id, type: "stakeholder" },
            warrantData: {
                ...warrant,
                currency: getCurrency(get(values, "groupCompany.id"),context.getStakeholdersGroupCompanies.groupCompanies),
                groupCompany: get(values, "groupCompany.id"),
                documents: isArray(warrant.documents)
                ? mapDocuments(warrant.documents)
                : warrant.documents,
            },
        };
        await upsertWarrant({ ...stripTypenames(variables) });
    },
        upsertConvertible: async (context, event) => {
            const values = get(event, 'payload.values');
            const { owner, convertible } = values;
            const variables = {
                startupId,
                owner: { id: owner.id, type: "stakeholder" },
                convertibleData: {
                        ...convertible,
                        currency: getCurrency(get(values, "groupCompany.id"),context.getStakeholdersGroupCompanies.groupCompanies),
                        groupCompany: get(values, "groupCompany.id"),
                        documents: isArray(convertible.documents)
                        ? mapDocuments(convertible.documents)
                        : convertible.documents,
                    },
                groupCompanyId: get(values, "groupCompany.id"),
                };
            await upsertConvertible({ variables });
        },
    }), [])
}
export default useActions;

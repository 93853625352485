const query = `{getCaptableInfo(startupId: "{{startup_id}}", groupCompanyId: "{{groupCompany_id}}") {
  startup {
    updatedAt
  }
  shareholders {
    personalData {
      id
      fullName
      avatar
    }
    fullyDiluted
    options {
      shareClass {
        id
        name
      }
      amount
      type
    }
    convertible {
      id
      amount
      currency
      discountRate
      interestRate
      valuationCap
    }
    isAnEntity
    isGroupCompany
    warrants {
      id
      amount
      valuation
      issuedDate
    }
    type
  }
 }
}`;
export const GET_CAPTABLE_INFO = `
query getCaptableInfo($startupId: ID! ,$groupCompanyId: ID!){
  getCaptableInfo(startupId: $startupId, groupCompanyId: $groupCompanyId){
  startup {
    updatedAt
  }
  shareholders {
    personalData {
      id
      fullName
      avatar
    }
    fullyDiluted
    options {
      shareClass {
        id
        name
      }
      amount
      type
    }
    convertible {
      id
      amount
      currency
      discountRate
      interestRate
      valuationCap
    }
    isAnEntity
    isGroupCompany
    warrants {
      id
      amount
      valuation
      issuedDate
    }
    type
  }
  shares {
    id
    name
    allocatedShares
    authorizedShares
    shareIncentivePlans{
      id
    }
  }

  sipUnallocatedShares{
    id
    name
    unallocatedShares
    unallocatedOptions
    unallocatedFullyDiluted
    document{
      id
    }
  }

  totals {
    unallocatedOptions
    unallocatedWarrants
    fullyDilutedSecurities
    unissuedShares
    totalAuthorized
    totalSecurities
  }
 }
}`;

const Config = {
  query: query
};

export { Config };

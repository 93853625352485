export default {
  STEP_1:{
    TITLE:'Will your principal place of operations be in the US?',
    SUBTITLE:'Place of business',
    EDIT_SUBTITLE:'Edit place of business',
    TOOLTIP: "A company's principal place of operations is the primary location where its business is performed. If this is the US for your company, the provisions governing where you will resolve disputes in the contracts generated on Clara as part of the formation process will include the governing law of the state in which you will operate and the city in that state nearest your operations."
  },
  STEP_2:{
    TITLE:'Select the principal place of business',
    SUBTITLE:'Place of business',
    TOOLTIP: "A company's principal place of operations is the primary location where its business is performed. If this is the US for your company, the provisions governing where you will resolve disputes in the contracts generated on Clara as part of the formation process will include the governing law of the state in which you will operate and the city in that state nearest your operations.",
    LABEL_CITY: "City",
    LABEL_STATE: "State",
    PLACEHOLDER: "Search or select from the list",
    
  },
}
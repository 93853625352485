import React, { useEffect, useState } from 'react';


const SVGTag = ({ fromX, fromY, toX, toY, curve = 12, displacementX = 0, percent,id, className }) => {
    const [p, setP] = useState()
    const [t, setT] = useState()


    const getPolylinePoints = () => {

        return `${fromX + displacementX + curve} ${toY - 6} ${fromX + displacementX + curve} ${toY + 6} ${fromX + displacementX + curve + 27} ${toY + 6} ${fromX + displacementX + curve + 31} ${toY} ${fromX + displacementX + curve + 27} ${toY - 6}`;


    }
    const getTextPosition = () => {

        return {
            x: fromX + displacementX + curve + 14,
            y: toY
        }


    }
    useEffect(() => {
        if (fromX && fromY && toX && toY) {
            const points = getPolylinePoints();
            const textPosition = getTextPosition();
            setP(points)
            setT(textPosition)

        }
    }, [fromX, fromY, toX, toY, curve, displacementX])
    return (
        <React.Fragment>
            {t && (
                <React.Fragment>
                    <polyline id={`polyline_${percent}`} points={p} ></polyline>
                    <text  id={`text_${percent}`} x={t.x} y={t.y} dominantBaseline="middle" textAnchor="middle" >{percent}%</text>
                </React.Fragment>
            )}

        </React.Fragment>
    )
};

export default SVGTag;

import TemplateWizardSkeletonMachine from "src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine";
import HasBeenRenewedStep from "../Steps/HasBeenRenewedStep";
import UploadDocumentStep from "../Steps/UploadDocumentStep";
import DocumentDetailsStep from "../Steps/DocumentDetailsStep";

const useSteps = () => {
  return {
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    HasBeenRenewedStep: {
      render: HasBeenRenewedStep,
    },
    UploadDocumentStep: {
      render: UploadDocumentStep,
    },
    DocumentDetailsStep: {
      render: DocumentDetailsStep,
    },
    SaveStep: {
      render: TemplateWizardSkeletonMachine,
    },
  };
};

export default useSteps;

import { gql } from '@apollo/client';
import useQuery from 'src/hooks/custom/useQuery';


const GET_COUNT_BY_TASK = gql`
  query getCountByTask($startupId: ID!, $cardCode: CardCode, $entity: CardEntityInputType) {
    getCountByTask(startupId: $startupId, cardCode: $cardCode, entity: $entity) {
      code
      entity {
        id
        type
        name
      }
      count
    }
  }
`;
/**
 * Get count by task
 */
const useGetCountByTask = (variables) => {
  const vars = { ...variables, notifyOnNetworkStatusChange: true };
  const { data, loading, error, ...rest } = useQuery(GET_COUNT_BY_TASK, vars, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only'
  });
  return { data, loading, error, ...rest };
};

export default useGetCountByTask;

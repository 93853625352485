import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useMachine from '../../../../generic/context/MachineContext/useMachine';
import IframeComponent from "./IframeComponent";

/**
 * Step to set pay data for manual invoice
 * @param {*} param0
 * @returns
 */
function CheckoutFormStep({ openParam }) {

  const { next, send, context } = useMachine();
  const [counter, setCounter] = useState(-1);

  const handleNext = (values) => {
    next(values)
  }
  
  useEffect(() => {
    if (counter === 0) {
      send({
        type: 'TIMEOUT',
        payload: {}
      });
    }
  }, [counter, send])

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const handleOnload = (data) => {
    setCounter(window?.env?.checkout?.timeToExpiredInSeconds ?? 60)
  }

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <IframeComponent onComplete={handleNext} onLoad={handleOnload} url={context?.CheckoutPayment?.url} />
    </Box>
  );
}

export default CheckoutFormStep;

import { default as React, useMemo } from 'react';
import Form from 'src/components/forms/Form/Form';
import PRODUCTS from "src/modules/checkout/constants/products";
import Button from 'src/modules/generic/components/Buttons/Button';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import CheckboxButtonSimple from 'src/modules/generic/components/Inputs/CheckboxButtonSimple';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import Typography from 'src/modules/generic/components/Texts/Typography';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import * as Yup from 'yup';
import CardProductFormation from '../../components/CardProductFormation';
import CardProductFormationScale from '../../components/CardProductFormationScale';
import cls from "./SaveWithScaleADGM_SPVFormStep.module.scss"
import CardBenefits from '../../components/CardBenefits';

/**
* Description Step: Select if save ADGM formation with scale 
*/

function SaveWithScaleADGM_SPVFormStep() {

  const { next, context, cancel } = useMachine();
  const { translate, } = useTranslate();

  const initialValues = useMemo(() => {
    return context?.SaveWithScaleADGM_SPVFormStep ?? {
      product: PRODUCTS.SCALE_FORMATION_ADGM_BUNDLE,
    }
  }, [context]);

  const handleCancel = (values) => {
    cancel();
  }

  const handleSubmit = async (values) => {
    next(values)
  }

  const products = useMemo(
    () => {
      const bundleProduct = context?.getProducts?.products[PRODUCTS.SCALE_FORMATION_ADGM_BUNDLE];
      const formationProduct = context?.getProducts?.products[PRODUCTS.FORMATION_ADGM_SPV_PAYMENT];
      return [
        {
          value: PRODUCTS.SCALE_FORMATION_ADGM_BUNDLE,
          label: PRODUCTS.SCALE_FORMATION_ADGM_BUNDLE,
          component: CardProductFormationScale,
          componentProps: {
            label: "ADGM Formation + Scale",
            currency: bundleProduct.currency,
            save: bundleProduct.discount,
            product:bundleProduct,
            company: context?.initialData?.company,
            price:(bundleProduct.value- bundleProduct.discount),
            fullPrice: bundleProduct.value,
          },
        },
        {
          value: PRODUCTS.FORMATION_ADGM_SPV_PAYMENT,
          label: PRODUCTS.FORMATION_ADGM_SPV_PAYMENT,
          component: CardProductFormation,
          componentProps: {
            component: "h4",
            weight: "semibold",
            currency: formationProduct.currency,
            price: formationProduct.value,
            product:formationProduct,
            company: context?.initialData?.company,
            label: "ADGM Formation only"
          },
        }
      ]
    }
    ,
    [context?.getProducts?.products]
  );

  const schema = Yup.object().shape({
    product: Yup.string().required(translate('VALIDATION_REQUIRED')),
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: undefined,
          skeleton: null,
          subTitle: undefined,
          variant: 'twoColumns',
          styleVariant: { gridTemplateColumns: "42rem 1fr" }
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('BUTTON_NEXT')}</SubmitButton>,
        }}
      >
        <div>
          <div className={cls.spacer}>
            <Typography variant={'xxxl'} color={'black'} >
              <b>Save With Scale</b>
            </Typography>
          </div>
          <ControllerInput
            render={CheckboxButtonSimple}
            isMultiple={false}
            name="product"
            direction="column"
            options={products}
            classNameButton={cls.buttonCard}
          />
        </div>
        <div className={cls.rightContent}>
          <CardBenefits />
        </div>
      </TemplateWizard>
    </Form>
  );
}

export default SaveWithScaleADGM_SPVFormStep;


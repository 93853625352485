import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import Icon from '../../../../../../../../modules/generic/components/Icon'
import { PopoverOption } from '../../../../../../../../v1/components';
import classes from './Styles.module.scss';

const CategoryHeader = ({ data, className, handler, categoriesList, handleOpenViewer, ...props }) => {

    const [categoryOpts, setCategoryOpts] = useState(null);

    const categories = [
        { id: 'CORPORATE_STRUCTURE', label: 'Corporate Structure' },
        { id: 'EQUITY', label: 'Equity' },
        { id: 'GOVERNANCE', label: 'Governance' },
        { id: 'TEAM', label: 'Team' },
        { id: 'INTELLECTUAL_PROPERTY', label: 'Intellectual Property' },
        { id: 'OTHER', label: 'Miscellaneous' }
    ];

    const formatCategory = () => {
        let opts = [];

         _.forEach(categories, cat => {
            
            _.find(categoriesList, catItem => {
                if(cat.id == catItem){
                    
                   return opts.push({
                        label: cat.label,
                        onClick: () => handler(cat.id, 'CATEGORY')
                    })
                }
            })
         });

         setCategoryOpts(opts)
    };

    useEffect(() => {
        formatCategory()
    }, [JSON.stringify(categoriesList)]);

    return (
        <div className={`${classes.containerDocumentName}  ${categoryOpts && categoryOpts.length == 0 ? classes.notClickable : null} ${className} ${classes.documentTabFilterHeader}`}>
            {categoryOpts && categoryOpts.length  !== 0 ? <PopoverOption elevation={0} classicStyle={true} renderElement={CategoryOptions} propsRenderElement={{ options: categoryOpts }}>
                <span><label>Category</label><Icon isClara icon={'Dropdown'} size={'1rem'} /></span>
            </PopoverOption> : <span><label>Category</label></span>}
        </div>
    )
};

export default CategoryHeader


const CategoryOptions = ({ options = [], closePopover, ...props }) => {

    const handleClose = ({ onClick }) => {
        onClick();
        closePopover()
    }

    return (
        <div className={classes.containerMenuOptionDocument}>
            {options && options.map((option, index) => {
                return (
                    <div key={`${JSON.stringify(option)}-${index}`} className={classes.containerOption} onClick={() => handleClose(option)}>
                        {option.label}
                    </div>
                )
            })
            }

        </div>
    )

} 

import _ from 'lodash';
import React, { FC, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { StepProps } from "../../../../../../../../generic/components/Wizard/Step";
import useRegisterAsCheckedTask from "../../../../../../../../generic/graphql/mutation/useRegisterAsCheckedTask";
import useCustomMachine from "../../../../../../../../generic/hooks/useCustomMachine";
import useTranslate from "../../../../../../../../generic/hooks/useTranslate";
import { addAssistantText, BubbleCustomChildren, clearAssistatText } from "../../../../../../../../generic/store/action";
import useSession from '../../../../../../../../session/hooks/useSession';
import FirstStep from "../../../../../../wizard/steps/FirstStep";
const FirstWarrantStep: FC<StepProps> = ({ }) => {
    const dispatch = useDispatch()
    const { translate } = useTranslate()
    const { state } = useCustomMachine()
    const { startupId } = useSession();
    const { mutation } = useRegisterAsCheckedTask({ showSuccessNotification: false })

    useEffect(() => {
        const bubbleChild: BubbleCustomChildren = {
            component: <><a rel='noopener noreferrer' href={'https://academy.clara.co/category/sharecapital/warrants'} target='_blank'>Warrants</a> give investors the right to buy a fixed number of shares at a fixed price, on a future date. Find out more about about warrants.</>
        }
        dispatch(addAssistantText(bubbleChild, "custom-child"))

        return () => {
            dispatch(clearAssistatText())
        }
    }, []);


    const handleCompleteSubmit = async (values) => {
        if (values.equity === 'notAdd') {
            const registeredTaskData = {
                entityId: _.get(state, 'context.paramsData.id'),
                taskCode: 'TASK_ADD_WARRANTS'
            }
            await mutation({ startupId, registeredTaskData })
        }
        dispatch(clearAssistatText())
    }
    return (<>
        <FirstStep onCompleteSubmit={handleCompleteSubmit} type={'WARRANT'} />
    </>)
}
export default FirstWarrantStep

import { createMachine } from "xstate";

const machine = createMachine({
  id: "ReviewCompanyDetails",
  initial: "initialData",
  states: {
    initialData: {
      invoke: {
        src: "initialData",
        id: "initialData",
        onDone: [
          {
            target: "pincasData",
            actions: ["setContextAfterInvoke"],
          },
        ],
      },
    },
    pincasData: {
      invoke: {
        src: "pincasData",
        id: "pincasData",
        onDone: [
          {
            target: "ReviewCompanyDetailsStep",
            actions: ["setContextAfterInvoke"],
          },
        ],
      },
    },
    ReviewCompanyDetailsStep: {
      on: {
        NEXT: {
          target: "EvaluateIsTLS",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    EvaluateIsTLS: {
      always: [
        {
          cond: "isTSL",
          target: "SaveStep",
        },
        {
          cond: "isNotTSL",
          target: "ReviewSharesInIncorporatedCompanies",
        },
      ],
    },

    ReviewSharesInIncorporatedCompanies: {
      on: {
        NEXT: {
          target: "EvaluateIsSharesInIncorporatedCompaniesCorrect",
          actions: ["setContextAfterStep"],
        },
        PREV: {
          target: "ReviewCompanyDetailsStep",
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    EvaluateIsSharesInIncorporatedCompaniesCorrect: {
      always: [
        {
          cond: "isCompaniesCorrect",
          target: "ReviewSharesInCompaniesNotIncorporated",
        },
        {
          cond: "isCompaniesNotCorrect",
          target: "WillHaveSharesIncorporatedStep",
        },
      ],
    },
    WillHaveSharesIncorporatedStep: {
      on: {
        NEXT: {
          target: "EvaluateHasSharesIncorporated",
          actions: ["setContextAfterStep"],
        },
        PREV: {
          target: "ReviewSharesInIncorporatedCompanies",
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    EvaluateHasSharesIncorporated: {
      always: [
        {
          cond: "hasIncorporatedShares",
          target: "WhichCompaniesHaveSharesStep",
        },
        {
          cond: "doesNotHaveIncorporatedShares",
          target: "ReviewSharesInCompaniesNotIncorporated",
        },
      ],
    },
    WhichCompaniesHaveSharesStep: {
      on: {
        NEXT: {
          target: "EvaluateIsNewCompany",
          actions: ["setContextAfterStep", "setIncorporatedCompanies"],
        },
        PREV: {
          target: "WillHaveSharesIncorporatedStep",
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    EvaluateIsNewCompany: {
      always: [
        {
          cond: "addNewCompany",
          target: "AddCompanyBasicInfoStep",
        },
        {
          cond: "existingCompany",
          target: "ReviewSharesInCompaniesNotIncorporated",
        },
      ],
    },
    AddCompanyBasicInfoStep: {
      on: {
        NEXT: {
          target: "DoYouHaveMoreCompaniesStep",
          actions: ["setContextAfterStep", "addIncorporatedCompany"],
        },
        PREV: {
          target: "WhichCompaniesHaveSharesStep",
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    DoYouHaveMoreCompaniesStep: {
      on: {
        NEXT: {
          target: "EvaluateMoreCompanies",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    EvaluateMoreCompanies: {
      always: [
        {
          cond: "moreCompanies",
          target: "AddCompanyBasicInfoStep",
        },
        {
          cond: "noMoreCompanies",
          target: "ReviewSharesInCompaniesNotIncorporated",
        },
      ],
    },
    ReviewSharesInCompaniesNotIncorporated: {
      on: {
        NEXT: {
          target: "EvaluateIsCorrectSharesInCompaniesNotIncorporated",
          actions: ["setContextAfterStep"],
        },
        PREV: {
          target: "ReviewSharesInIncorporatedCompanies",
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    EvaluateIsCorrectSharesInCompaniesNotIncorporated: {
      always: [
        {
          cond: "isCorrectSharesInCompaniesNotIncorporated",
          target: "SaveStep",
        },
        {
          cond: "isNotCorrectSharesInCompaniesNotIncorporated",
          target: "SharesInCompaniesNotIncorporatedForm",
        },
      ],
    },
    SharesInCompaniesNotIncorporatedForm: {
      on: {
        NEXT: {
          target: "SaveStep",
          actions: ["setContextAfterStep"],
        },
        PREV: {
          target: "ReviewSharesInCompaniesNotIncorporated",
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    SaveStep: {
      invoke: {
        src: "SaveStep",
        id: "SaveStep",
        onDone: [
          {
            target: "finish",
          },
        ],
        onError: [
          {
            target: "cancel",
          },
        ],
      },
    },
    cancel: {
      entry: "cancelMachine",
      type: "final",
    },
    finish: {
      entry: "stopMachine",
      type: "final",
    },
  },
});

export default machine;

import _ from "lodash";
import { Formik } from "formik";
import React, { useMemo, useState } from 'react';
import useUpdateGroupCompany from "../../../../../../../hooks/services/modules/groupCompany/useUpdateGroupCompany";
import useUpsertComponentPosition from "../../../../../../../hooks/services/modules/startups/positions/useUpsertComponentPosition";
import useSession from "../../../../../../../modules/session/hooks/useSession";
import { stripTypenames } from "../../../../../../../v1/utils/graphql-util";
import PrimaryButton from "../../../../../../generic/Buttons/PrimaryButton/PrimaryButton";
import SecondaryButton from "../../../../../../generic/Buttons/SecondaryButton/SecondaryButton";
import ContainerModal from "../../../../../../generic/Modal/ModalScroll/ContainerModal";
import Text from '../../../../../../text/Text/Text';
import Botonera from "../../../../../contents/Botonera/Botonera";
import classes from '../FormSelector.module.scss';
import getDataByDetails from "../Hooks/useGetDataByDetails";
import useGetEditDetails from "../Hooks/useGetEditDetails";
import getSchemaDetails from "../Hooks/useGetSchemaDetails";

const FormSelector = ({
	groupCompany,
	initialValues,
	title,
	field,
	type,
	handleDisabled,
	tooltips = {},
	handleSubmit,
	handleCloseModal,
	...props
}) => {

	const [isSubmitting, setSubmitting] = useState(false);

	const form = useGetEditDetails(
		type,
		tooltips,
		field,
		title,
		initialValues,
		groupCompany,
		props.nameHandle
	);

	const schema = useMemo(() => { return getSchemaDetails(type) }, [type]);
	const { startupId } = useSession();
	const [updateGroupCompany, loadingGroupCompany] = useUpdateGroupCompany();
	const { mutation: upsertPosition, loading: loadingPosition } = useUpsertComponentPosition();

	const onSubmit = async (values, validateForm, dirty) => {
		const errors = await validateForm();
		if (!_.size(errors)) {
			if (dirty) {
				setSubmitting(true);
				const variables = getDataByDetails(type, initialValues, values, startupId, groupCompany);
				if (type === 'companyDetails' || type === 'recipientNoticeDetails') {
					await updateGroupCompany(variables)
				} else if (type === 'stakeholderRoleAndServices') {
					// Only save not default position responsibilities update text/name
					if (_.has(variables, 'position.isDefault') && !variables.position.isDefault) {
						delete variables.position.isDefault;
						await upsertPosition(stripTypenames(variables));
					}
				} else if (type === 'stakeholderDetails') {
					// await updateStakeholder(stripTypenames(variables));
				}
				setSubmitting(false);
			}
			handleSubmit(values, type, dirty);
		}
	};

	return (
		<>
			<Formik
				initialValues={initialValues}
				validationSchema={schema}
				onSubmit={handleSubmit}
				validateOnChange={false}
			>
				{({
					values,
					errors,
					dirty,
					handleReset,
					setFieldValue,
					isValid,
					validateForm
				}) => {
					// console.log('values-->',values);
					// console.log('errors-->',errors);
					return (
						<>
							<ContainerModal>
								<div className={`${classes.containerForm} `}>
									{form}
								</div>
							</ContainerModal>
							<div className={classes.containerButtons}>
								<Botonera>
									<SecondaryButton
										onClick={() => handleCloseModal()}>
										<Text uuid={'GENERIC_BUTTON_CLOSE'} />
									</SecondaryButton>
									<PrimaryButton
										disabled={isSubmitting}
										loading={isSubmitting}
										onClick={() => onSubmit(values, validateForm, dirty)}>
										<Text uuid={"MY_ACCOUNT_MODAL_UPDATEPASSWORD_SAVE"} />
									</PrimaryButton>
								</Botonera>
							</div>
						</>
					)
				}}
			</Formik>
		</>
	)
};

export default FormSelector;

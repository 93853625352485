import useMutation from "src/hooks/custom/useMutation";

export const UPSERT_RENEWAL_DATA = `
mutation upsertGroupCompany(
    $startupId: String
    $groupCompanyId: String
	$groupCompany: GroupCompanyInputType
  ) {
    upsertGroupCompany(
      startupId: $startupId
      groupCompanyId: $groupCompanyId
      groupCompany: $groupCompany
    ) {
      id
  }
}
`;

const useUpdateEconomicSubstanceInformation = () => {
    const [mutation] = useMutation(
        UPSERT_RENEWAL_DATA,
        { showSuccessNotification: true }
    ); 
    return mutation;
};

export default useUpdateEconomicSubstanceInformation;

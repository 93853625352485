import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import PaymentView from './PaymentView';

const PaymentFormationView = (props) => {
    const params = useParams();
    const {productCode, matterId} = params;

    const completeFinish = (data) => {

    };

    return (
        <React.Fragment>
            <PaymentView
                productCode={productCode}
                source={'FORMATION'}
                id={matterId}
                canSelectCard={false}
                completeFinish={completeFinish}
            />
        </React.Fragment>
    )
};

export default PaymentFormationView;

import { useQuery,gql } from '@apollo/client';
import React, { useEffect, useState } from 'react';

import ServerConnect from '../../../utils/ServerConnect'

import  _ from "lodash";
import ClaraSelect from "../ClaraSelect/ClaraSelect";
import TextInput from "../../../containers/Forms/Inputs/TextInput";
import { Field } from "formik";
import styles from "./Styles.module.scss";
import useGetCountryAndJurisdiction from 'src/modules/generic/components/CountryJurisdictionSelector/useGetCountryAndJurisdiction';

function CountryList({
    jurisdictionsEnabled = false,
    prefix = '', // such as  address. or registeredAddress.,...
    countryField = "country",
    jurisdictionField = "jurisdiction",
    jurisdictionLabel = "Jurisdiction",
    otherJurisdictionField = "otherJurisdiction",
    otherJurisdictionLabel = "Other jurisdiction",
    label,
    setFieldValue,
    additionalStyle = "",
    additionalStyleOther="",
    initialValues = { jurisdiction: null },
    callbackUpdate,
    returnCountryAsString,
    tooltips = {},
    isRequired = {}
}: any) {
    
  const {
    countriesList,
    jurisdictionsList,
    loading,
  } = useGetCountryAndJurisdiction();

    const [country, setCountry] = useState(initialValues.country);
    const [jurisdiction, setJurisdiction] = useState(initialValues.jurisdiction);
    const [jurisdictions, setJurisdictions] = useState([])
    const [disabled,setDisabled] = useState<boolean>()
    const [countries,setCountries] = useState([])


    useEffect(() => {

        if (jurisdictionsEnabled) {
            if(country){
                const jurisdictionsByCountry = jurisdictionsList[country]
               if(!_.isEmpty(jurisdictionsByCountry)){
                 setJurisdictions(jurisdictionsByCountry.map((item:any) => ({ id: item.value, label: item.label })))
                 setDisabled(false)
                 if(initialValues.jurisdiction &&!jurisdictionsByCountry.some(j=>j.label === initialValues.jurisdiction)){
                    setFieldValue(otherJurisdictionField,initialValues.jurisdiction) 
                    setFieldValue(jurisdictionField,'OTHER')
                }
               }else{ 
                setJurisdictions([{label:'Other Jurisdiction', id:'OTHER'}])
                setFieldValue(jurisdictionField,'OTHER')
                setFieldValue(otherJurisdictionField,initialValues.jurisdiction)
                setDisabled(true)
            }
            }
            if (country === null) {
                
                setJurisdiction(null);
                setFieldValue(jurisdictionField, null)
                setFieldValue(otherJurisdictionField,null)
            }
        }
        if (initialValues.country) {
            const field = returnCountryAsString ? countryField : `${prefix}${countryField}.code`
            setFieldValue(field, country ? country : '')
        }
    }, [country]);
    useEffect(() => {
        if(countriesList) setCountries(_.map(countriesList, ((item: any) => ({  id: item.value, label: item.label,code:item.value}))));
    },[countriesList])

    const handleCallbacksCountry = (country: String) => {
        setCountry(country);
        if (callbackUpdate) {
            callbackUpdate(country)
        }

    };

    if (loading) {
        return <p>Loading ...</p>;
    } else {
        return (
            <React.Fragment>
                <div className={`${styles.Select} ${styles[additionalStyle]}`} >
                    <Field name={returnCountryAsString ? countryField : `${prefix}${countryField}.code`}>
                        {({ field, form }: any) => {
                            return (
                                <ClaraSelect
                                    required={isRequired.country}
                                    label={label}
                                    form={form}
                                    field={field}
                                    placeholder={"Search for a country"}
                                    data-zuko-name={label}
                                    lists={countries}
                                    mode={"classic"}
                                    countryReady={true}
                                    disabled={false}
                                    callbacks={{ updateValues: handleCallbacksCountry }}
                                    autocomplete={"off_1"}
                                    tooltip={tooltips.country}
                                />)
                        }
                        }
                    </Field>
                </div>
                {country && jurisdictionsEnabled && jurisdictions &&
                    <div className={`${styles.Select} ${styles[additionalStyle]}`}>
                        <Field name={`${prefix}${jurisdictionField}`} >
                            {({ field, form }: any) => {
                                return <ClaraSelect
                                    required={isRequired.jurisdiction}
                                    field={field}
                                    form={form}
                                    label={`${jurisdictionLabel}`}
                                    placeholder={"Search for a jurisdiction"}
                                    data-zuko-name={jurisdictionLabel}
                                    lists={jurisdictions}
                                    mode={"classic"}
                                    countryReady={false}
                                    callbacks={{ updateValues:setJurisdiction }}
                                    disabled={disabled}
                                    tooltip={tooltips.jurisdiction}
                                />
                            }
                            }
                        </Field>
                    </div>
                }
                {jurisdiction && jurisdiction === "OTHER" &&
                    <div className={`${styles.Input} ${styles[additionalStyle]} ${styles[additionalStyleOther]}`}>
                        <Field name={`${otherJurisdictionField}`}>
                            {({ field, form }: any) => (
                                <TextInput
                                    type='text'
                                    label={`${otherJurisdictionLabel}`}
                                    data-zuko-name={otherJurisdictionLabel}
                                    field={field}
                                    form={form}
                                    required={isRequired.otherJurisdiction}
                                    placeholder={"Type name of the jurisdiction"}
                                    tooltip={tooltips.other}
                                />
                            )}
                        </Field>
                    </div>
                }
            </React.Fragment>

        )
    }
}
export default CountryList;

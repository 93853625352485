import React from "react";
import * as Yup from 'yup'
import Form from "../../../../../../components/forms/Form/Form";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import SubmitButton from "../../../../../generic/components/Buttons/SubmitButton";
import FormProps from "../../../../../generic/forms/interfaces/FormProps";
import TemplateFormDefault from "../../../../../generic/templates/Modal";
import CheckboxButtonSimple from '../../../../../../modules/generic/components/Inputs/CheckboxButtonSimple'
import ControllerInput from '../../../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput'
import useGetStakeholders from "../../../../../../hooks/services/modules/stakeholders/useGetStakeholders";
import useSession from "../../../../../../modules/session/hooks/useSession";
import _ from 'lodash';
import cls from "./classes.module.scss";

const schema = Yup.object().shape({
  dataProtectionContact: Yup.string().required('Required')
})

interface FormDataProtectionContactProps extends FormProps {
  groupCompanyId: string
}

const FormDataProtectionContact: React.FC<FormDataProtectionContactProps> = ({
  initialValues = {},
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  groupCompanyId,
  propsTemplate,
}) => {

  const { translate } = useTranslate();
  const { startupId } = useSession();

  const handleSubmit = async (values) => {
    onCompleteSubmit && (await onCompleteSubmit(values));
  };

  const {
    data: dataStakeholders,
  } = useGetStakeholders({ variables: { startupId, groupCompanyId, filters: { forADGM: true, checkIsFounder: true } } });

  const options = React.useMemo(() => {
    const filterOnlyIndividuals = _.filter(dataStakeholders, (stakeholder) => stakeholder.isAnEntity === false);
    let result = [];
    _.forEach(filterOnlyIndividuals, (stakeholder) => {
      let newItem = {
        label: stakeholder.fullName,
        value: stakeholder.id,
      };
      result.push(newItem);
    });
    return result;
  }, [JSON.stringify(dataStakeholders)])

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
      onlySendDirty={false}
    >
      <Template
        buttons={{
          ...buttons,
          submit: <SubmitButton {...buttonSubmitProps}>{translate('MODULES.STAKEHOLDER.ADGM.ADD')}</SubmitButton>,
        }}
        props={propsTemplate}
      >
        <ControllerInput
          render={CheckboxButtonSimple}
          options={options}
          name='dataProtectionContact'
          defaultlabel={''}
          size={"md"}
          className={cls.CheckboxButton}
          classNameButton={cls.buttonCheckBox}
        />
      </Template>
    </Form>
  );
};

export default FormDataProtectionContact;

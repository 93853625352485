import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import useMachine from '../../../../../generic/context/MachineContext/useMachine';
import useModal from "../../../../../generic/hooks/useModal";
import useTranslate from '../../../../../generic/hooks/useTranslate';
import { addAssistantText, clearAssistatText } from "../../../../../generic/store/action";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import AreThereMoreEntity from '../../../core/forms/AreThereMoreEntity';
function IsMoreStakeholders({ openParam }) {

  const { next } = useMachine();
  const { translate } = useTranslate()
  const dispatch = useDispatch();
  const { closeModal: onClose } = useModal();

  const handleInitModal = () => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText(translate('MODULES.EQUITY.ADD_EQUITY.STEP_ADD_CONVERTIBLE.TOOLTIP')));
  }

  useEffect(() => {
    handleInitModal()
  }, []);

  const handleCompleteSubmit = async (values) => {
    next(values)
  }

  return (
    <AreThereMoreEntity
      buttons={{

      }}
      onCompleteSubmit={handleCompleteSubmit}
      propsTemplate={{
        title:'Do you have more stakeholders to add?',
        subTitle:'Add another stakeholder'
      }} 
      Template={TemplateWizard}
    />
  );
}
export default IsMoreStakeholders;

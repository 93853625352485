import React, { useState } from 'react';
import RegionContext from './RegionContext';
import useGetDocumentRegions from '../../../hooks/graphql/useGetDocumentRegions';

const Provider = RegionContext.Provider;

function RegionProvider({ children }) {
  const [region, setRegion] = useState()
  const { data,loading } = useGetDocumentRegions({})

  return (
    <Provider value={{ setRegion, region, regions: data, loading }}>
      {children}
    </Provider>
  );
}

export default RegionProvider;

import MachineStakeholder from "../../core/wizards/machine/MachineStakeholder";
import FirstStep from './steps/FirstStep';
import LastStepConsultant from "./steps/LastStep";
import IndividualOrCompanyStep from './steps/IndividualOrCompanyStep';
import IndividualOrCompanyStepFormConsultant from "./steps/IndividualOrCompanyStepForm";

export default class MachineConsultant extends MachineStakeholder{
    constructor() {
        super('consultant',FirstStep,LastStepConsultant,IndividualOrCompanyStep,IndividualOrCompanyStepFormConsultant);
    }
}

import { default as React, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import * as Yup from "yup";
import Form from "src/components/forms/Form/Form";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import Typography from "src/modules/generic/components/Texts/Typography";
import Button from "src/modules/generic/components/Buttons/Button";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import {
  clearAssistatText,
  addAssistantText,
} from "src/modules/generic/store/action";
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import cls from "./classes.module.scss";

function AssignSignatoryStep() {
  const { context, cancel, next, prev } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.AssignSignatoryStep;
  }, [context]);

  const signatories = context?.initialData?.stakeholdersUAEorGCCNoRole;

  const stakeholders =  _.map(signatories, (signatory) => ({
      value: signatory,
      label: signatory?.fullName,
    }));

  const handleCancel = () => {
    cancel();
  };

  const handleSubmit = async (values) => {
    next(values);
  };

  const handlePrev = () => prev();

  useEffect(() => {
    dispatch(
      addAssistantText(
        translate(
          "MODULES.RENEWALS_ADGM.REVIEW_ADGM_AUTHORISED_SIGNATORY.ASSIGN_STEP.TOOLTIP_1"
        )
      )
    );
    dispatch(
      addAssistantText(
        translate(
          "MODULES.RENEWALS_ADGM.REVIEW_ADGM_AUTHORISED_SIGNATORY.ASSIGN_STEP.TOOLTIP_2"
        )
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const schema = Yup.object().shape({
    authorisedSignatory: Yup.object().nullable().required("Required"),
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: translate(
            "MODULES.RENEWALS_ADGM.REVIEW_ADGM_AUTHORISED_SIGNATORY.ASSIGN_STEP.TITLE"
          ),
          subTitle: translate(
            "MODULES.RENEWALS_ADGM.REVIEW_ADGM_AUTHORISED_SIGNATORY.SUBTITLE"
          ),

          variant: "simple",
        }}
        buttons={{
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("BUTTON_CANCEL")}
            </Button>
          ),
          submit: <SubmitButton>Next</SubmitButton>,
          previous: (
            <Button onClick={handlePrev} variant="secondary">
              Previous
            </Button>
          ),
        }}
        >
       <Typography component="h5">
       An Authorised Signatory must be a UAE Resident or GCC National. Select from your current eligible stakeholder(s) listed below. 
</Typography>
<Typography component="h5">
Alternatively, you can add a new eligible stakeholder via the blue plus button from the main navigation. Once you have completed all personal details tasks for that stakeholder, return here to select your new Authorised Signatory.
        </Typography>
        {stakeholders && stakeholders.length > 0?<ControllerInput
          name="authorisedSignatory"
          render={CheckboxButtonSimple}
          options={stakeholders}
          classNameButton={cls.buttonCheckBox}
        />:<Typography component="h5" fontWeigth={'bold'}>
          None
            </Typography>}
      </TemplateWizard>
    </Form>
  );
}

export default AssignSignatoryStep;

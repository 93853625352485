import _ from 'lodash';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Button from '../../../../../generic/components/Buttons/Button';
import { StepProps } from "../../../../../generic/components/Wizard/Step";
import useCustomMachine from '../../../../../generic/hooks/useCustomMachine';
import { addAssistantText, clearAssistatText } from '../../../../../generic/store/action';
import TemplateWizardPreview from '../../../../../generic/templates/Modal/TemplateWizard/TemplateWizardPreview';
import useSession from '../../../../../session/hooks/useSession';
import Form from "../../components/Forms/FormPassportData";
import useUpsertTwoDocumentFormation from '../../../../../../hooks/services/modules/individualStakeholder/useUpsertTwoDocumentFormation';

const Step5: FC<StepProps> = () => {

  const { next, send, state } = useCustomMachine();
  const [updateDocument] = useUpsertTwoDocumentFormation();
  const { id } = useParams<any>()
  const { startupId } = useSession();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(addAssistantText('Upload a double page scan of the passport clearly showing their photo and passport number.'));
    return () => {
      dispatch(clearAssistatText());
    }
  }, [])

  const handleCompleteSubmit = async values => {
    const variables = {
      stakeholderId: state?.context?.paramsData?.stakeholderId ?? id,
      startupId,
      identityDocumentDataOLD: { ...values, "type": "OLDPASSPORT_DOCUMENT" },
      identityDocumentData: { ..._.get(state, 'context.data.set_document_visa_expiry_date', {}), "type": "UAE_RESIDENCE_VISA_DOCUMENT" }
    }
    await updateDocument({ variables });
    next(values);
  }

  const handleCancel = async values => {
    send("CANCEL");
  }

  return (
    <React.Fragment>
      <Form
        initialValues={_.get(state, 'context.data.set_old_passport', {})}
        isOldPassport={true}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizardPreview}
        propsTemplate={{
          title: 'Add their old passport',
          subTitle: 'passport'
        }}
        buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }}>
      </Form>
    </React.Fragment>
  )
}

export default Step5;

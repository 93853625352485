import _ from "lodash";
import Mustache from "mustache";
import React, { Component } from "react";
import { connect } from "react-redux";
import Scrollbar from "src/components/generic/Scrollbar/Scrollbar";
import Date from "../../../../components/text/Text/Date";
import { ReactComponent as MissingInformationIcon } from "../../../../images/MissingInformation.svg";
import { setSelectedCompany } from '../../../../modules/capTable/store/action';
import MaterialAvatarLabel from "../../../../modules/generic/components/Avatars/AvatarLabel";
import { Constants } from "../../../../v1/utils/constants";
import event from "../../../../v1/utils/event";
import { ModalMenu, ZoneTables } from "../../../components";
import Text from "../../../components/Lang/Text/Text";
import MissingInformation from "../../../components/MissingInformation";
import tabsClasses from "../../../components/Tabs/Tabs.module.scss";
import ServerConnect from "../../../utils/ServerConnect";
import classes from "./CapTable.module.scss";
import TitleCapTable from "./components/TitleCapTable";
import { Config } from "./config";

class CapTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shares: [],
      shareholders: [],
      sipUnallocatedShares: [],
      selectedGroupCompany: _.find(
        this.props.session.groupCompanies,
        (gc) => gc.isHoldingCompany
      ),
      selectStyle: {
        width: this.calculateWidthFromName(
          _.get(props, "session.startup.groupCompanies", []).length > 0
            ? props.session.startup.groupCompanies[0].name
            : ""
        ),
      },
      missing: false,
      loading: true,
      startup: {},
    };
  }

  updateGroupCompanySelected(groupCompany) {
    groupCompany && this.props.setSelectedCompany(groupCompany.id, groupCompany.name, !this.state.missing);
  }

  updateData(groupCompany) {
    if (groupCompany) {
      const query_params = {
        startup_id: this.props.session.startup.id,
        groupCompany_id: groupCompany.id,
      };
      const query = Mustache.render(Config.query, query_params);
      this.props.addLoading();
      ServerConnect.graphQlQuery(query, null, null, { fetchPolicy: "no-cache", nextFetchPolicy: "no-cache" }).then((result) => {
        this.props.removeLoading();
        if (
          !this.existsCapTable(
            result.getCaptableInfo.shares,
            result.getCaptableInfo.shareholders,
            result.getCaptableInfo.totals
          )
        ) {
          this.setState({
            shares: [],
            shareholders: [],
            totals: {},
            missing: true,
            loading: false,
            startup: result.getCaptableInfo.startup,
          }, () => this.updateGroupCompanySelected(groupCompany));
        } else {
          this.setState({
            shares: result.getCaptableInfo.shares.map((share) => ({
              ...share,
              label: share.shareClass,
            })),
            sipUnallocatedShares: result.getCaptableInfo.sipUnallocatedShares,
            shareholders: result.getCaptableInfo.shareholders,
            totals: result.getCaptableInfo.totals,
            selectedGroupCompany: groupCompany,
            missing: false,
            loading: false,
            startup: result.getCaptableInfo.startup,
          }, () => this.updateGroupCompanySelected(groupCompany));
        }
      }).catch((err) => {
        this.props.removeLoading();
        this.setState({ missing: true });
      });
    }
  }

  existsCapTable(shares, shareholders, totals) {
    return (
      shares.length &&
      shareholders.filter(
        (sh) =>
          (sh.convertible && sh.convertible.length) ||
          (sh.equity && sh.equity.length)
      ).length &&
      Object.keys(totals).length
    );
  }

  componentDidMount() {
    // const { groupCompany } = this.getCompaniesData();
    this.updateCapTable();
    event.on(Constants.EVENTS.REFRESH_ACTIVE_SUBSCRIPTION, (params) => {
      this.updateCapTable();
    });
    // groupCompany && this.props.setSelectedCompany(groupCompany.id, groupCompany.name, !this.state.missing);
  }

  componentWillUnmount() {
    event.remove(Constants.EVENTS.REFRESH_ACTIVE_SUBSCRIPTION, () => { });
  }

  updateCapTable = () => {
    const companiesData = this.getCompaniesData();
    let key = _.findKey(companiesData.groupCompanies, (o) => {
      return o.name === companiesData.groupCompany.name
    })
    if (!_.isEmpty(this.props.session.startup.groupCompanies)) {
      const { selectedGroupCompany } = this.state;
      selectedGroupCompany
        ? this.updateData(selectedGroupCompany)
        : this.updateData(this.props.session.startup.groupCompanies[key]);
    } else {
      this.setState({ missing: true });
    }
  };

  componentWillUpdate(nextProps, nextState, snapshot) {
    if (nextProps.session !== this.props.session) {
      this.updateData(nextState.selectedGroupCompany);
    }
  }

  changeSelectedGroupCompany = (groupCompany) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        selectStyle: {
          width: this.calculateWidthFromName(groupCompany.name),
        },
      }),
      () => {
        this.updateData(groupCompany);
      }
    );
    // this.props.setSelectedCompany(groupCompany.id, groupCompany.name, !this.state.missing);
  };

  calculateWidthFromName(name) {
    const length = name.length;
    let multiplier = 20;
    if (length < 4) multiplier = 30;
    if (length < 10) multiplier = 25;
    if (length < 15) multiplier = 25;
    if (length >= 20) multiplier = 15;
    return (length * multiplier).toString() + "px";
  }

  scroll(
    elementId,
    component,
    direction,
    buttonId,
    speed = 5,
    distance = 500,
    step = 10
  ) {
    const element = document.getElementById(elementId);
    if (element) {
      let scrollAmount = 0;
      const slideTimer = setInterval(function () {
        if (direction === "left") {
          element.scrollLeft -= step;
        } else {
          element.scrollLeft += step;
        }
        scrollAmount += step;
        if (scrollAmount >= distance) {
          window.clearInterval(slideTimer);
        }
        component.verifyAndChangeButtonsStates(element, component, buttonId);
      }, speed);
    }
  }

  verifyAndChangeButtonsStates(element, component, buttonId) {
    if (element.scrollLeft === 0) {
      component.changeButtonState("left" + buttonId, "Left", "Deactive");
    } else {
      component.changeButtonState("left" + buttonId, "Left", "Active");
    }
    if (element.offsetWidth + element.scrollLeft >= element.scrollWidth) {
      component.changeButtonState("right" + buttonId, "Right", "Deactive");
    } else {
      component.changeButtonState("right" + buttonId, "Right", "Active");
    }
  }

  changeButtonState(buttonId, buttonPosition, state) {
    const button = document.getElementById(buttonId);
    if (button) {
      button.className = `${tabsClasses.Button} ${tabsClasses[buttonPosition]} ${tabsClasses[state]}`;
    }
  }

  dashboard() {
    const companiesData = this.getCompaniesData();
    return (
      <>
        {!this.state.missing ? (
          <div className={classes.CapTableZones}>
            <div className={classes.LastUpdate}>
              <Text uuid={"LAST_UPDATED"} />{" "}
              <Date value={_.get(this.state, "startup.updatedAt")} />{" "}
            </div>
            {(
              <div className={classes.CapTableTables}>
                <ZoneTables
                  companiesData={companiesData}
                  totals={this.state.totals}
                  shareholders={this.state.shareholders}
                  shares={this.state.shares}
                  startup={this.props.session.startup}
                  sipUnallocatedShares={this.state.sipUnallocatedShares}
                  selectedGroupCompany={this.state.selectedGroupCompany}
                  changeSelectedGroupCompany={this.changeSelectedGroupCompany}
                />
              </div>
            )}
          </div>
        ) : (
          <>
            {companiesData.hasCompanies && (
              <div
                className={` ${classes.DivAvatar}`}
              >
                <ModalMenu
                  captable={true}
                  groupCompanies={_.get(companiesData,'groupCompanies')}
                  changeSelectedGroupCompany={(gc) =>
                    this.changeSelectedGroupCompany(gc)
                  }
                >
                  <MaterialAvatarLabel
                    captable={true}
                    avatar={_.get(companiesData,'groupCompany.avatar')}
                    name={_.get(companiesData,'groupCompany.name')}
                    pointerLabel={true}
                    labelColor={'#192a3e'}
                  />
                </ModalMenu>
              </div>
            )}
            <MissingInformation
              title={"Cap Table"}
              subtitle={"Sorry, your Cap Table is not ready for an overview yet."}
              icon={MissingInformationIcon}
              className={classes.MissingInformation}
            />
          </>
        )}
      </>
    );
  }

  getCompaniesData() {
    const { selectedGroupCompany } = this.state;
    const { groupCompanies = [] } = this.props.session.startup;
    const hasCompanies = !!groupCompanies.length;
    const holdingCompany = _.find(
      this.props.session.startup.groupCompanies,
      (comp) => comp.isHoldingCompany
    );
    const groupCompany =
      !selectedGroupCompany && hasCompanies
        ? holdingCompany
          ? holdingCompany
          : groupCompanies[0]
        : selectedGroupCompany;
    return { groupCompany, groupCompanies, hasCompanies };
  }

  render() {
    return (
      <React.Fragment>
        <div className={classes.CapTable}>
          <TitleCapTable />
          <Scrollbar className={classes.CapTableContent}>
            {this.dashboard()}
          </Scrollbar>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setSelectedCompany: (id, name, enabled) => {
    setSelectedCompany(dispatch, id, name, enabled)
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CapTable);

import _ from "lodash";
import React, { useState } from "react";
import { useUploadCategories as useCategories } from "../../../../../../../hooks/common/useCategories";
import useUpsertDocument from "../../../../../../../hooks/services/modules/Document/useUpsertDocument";
import useGetGroupCompanies from "../../../../../../../hooks/services/modules/groupCompany/useGetGroupCompanies";
import useGetStakeholders from "../../../../../../../hooks/services/modules/stakeholders/useGetStakeholders";
import { stripTypenames } from "../../../../../../../v1/utils/graphql-util";
import DocumentUploadLast from "../../../../../../forms/modules/startups/Document/DocumentUpload/DocumentUploadLast";
import ModalScroll from "../../../../../../generic/Modal/ModalScroll/ModalScroll";
import useSession from "./../../../../../../../modules/session/hooks/useSession";
import useAddDocumentFormation from "../../../../../../../hooks/services/modules/individualStakeholder/useAddDocumentFormation";
import useUpdateIdentityDocumentPageStakeholder from "../../../../../../../modules/startup/Documents/graphql/mutations/useUpdateIdentityDocumentPageStakeholder";
import { Constants } from "../../../../../../../v1/utils/constants";
import { DELETE_COMPONENT_STAKEHOLDER_ROLE } from "../../../individualStakeholder/graphql/mutation/deleteRole";

const EditDocumentUpload = ({ data, open, handleClose, refresh, ...props }) => {
  const { startupId } = useSession();
  const {
    data: stakeholders,
    loading: loadingST,
    refetch: refetchStackHolders,
  } = useGetStakeholders({ variables: { startupId } });
  const {
    data: groupCompanies,
    loading: loadingGC,
    refetch: refetchGroupCompanies,
  } = useGetGroupCompanies({ variables: { startupId } });

  const onCompleted = () => {
    handleClose();
    refetchGroupCompanies();
    refetchStackHolders();
    refresh && refresh();
  };

  const [addDocument] = useAddDocumentFormation({ onCompleted });
  const {
    mutation: updateDocumentFormation,
  } = useUpdateIdentityDocumentPageStakeholder(
    { onCompleted, showSuccessNotification: false },
    {}
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [upsertDocument] = useUpsertDocument({ onCompleted });
  const { categories, loadingCat } = useCategories();

  const mapForAddVariablesIdentityDocument = (values) => {
    let addedVariables = {};
    if (values.draft) addedVariables["draftDocumentId"] = values.id;
    const mappedValues = mapVariablesIdentityDocument(values);
    return {
      ...mappedValues,
      identityDocumentData: {
        ..._.omit(mappedValues.identityDocumentData, ["file"]),
        ...addedVariables,
        files: [{ ...values.file }],
      },
    };
  };

  const mapVariablesIdentityDocument = (values) => {
    const commonVariables = {
      startupId,
      pageId: values.id,
      stakeholderId: values.party.id,
      identityDocumentData: {
        name: values.title,
        type: values.type,
        file: values.file,
      },
    };
    if (_.includes(Constants.PASSPORT_DOCUMENT_TYPES, values.type))
      return {
        ...commonVariables,
        identityDocumentData: {
          ...commonVariables.identityDocumentData,
          number: values.passport.number,
          expiryDate: values.passport.expirationDate,
          nationality: values.passport.nationality,
        },
      };
    else if (
      _.includes(
        Constants.VISA_STAMPS_RESIDENCE_ID_DOCUMENTS_TYPES,
        values.type
      )
    )
      return {
        ...commonVariables,
        identityDocumentData: {
          ...commonVariables.identityDocumentData,
          expiryDate: values.visaStamp.expirationDate,
        },
      };
    return {
      ...commonVariables,
    };
  };

  const handleSubmit = async (values, validateForm) => {
    const errors = await validateForm();
    if (!_.size(errors)) {
      if (_.includes(Constants.DOCUMENT_TYPE_IDENTITY_DOCUMENTS, values.type)) {
        if (!_.get(data, "identityDocument")) {
          const variables = mapForAddVariablesIdentityDocument(values);
          setIsSubmitting(true);

          await addDocument({ ...stripTypenames(variables) });
          setIsSubmitting(false);
        } else {
          const variables = mapVariablesIdentityDocument(values);
          setIsSubmitting(true);

          await updateDocumentFormation({ ...stripTypenames(variables) });

          setIsSubmitting(false);
        }
      } else {
        const { subCategory, parties, file } = values;
        const fieldAgreementId = values.draft
          ? "draftDocumentId"
          : "agreementId";
        const category = _.find(categories, (cat) => {
          return _.some(cat.subCategories, (sub) => {
            return _.some(sub.documentTypes, (doc) => {
              return doc.key === "IP_ASSIGNMENT";
            });
          });
        });
        const subCategoryIP = _.find(category.subCategories, (sub) =>
          _.some(sub.documentTypes, (type) => type.key === "IP_ASSIGNMENT")
        );
        const variables = {
          agreement: {
            ..._.pick(values, [
              "title",
              "executedOnDate",
              "expirationDate",
              "type",
              "status",
            ]),
            subCategories:
              _.get(values, "IPAssignmentProvisions") &&
              subCategory !== subCategoryIP.key
                ? [subCategory, subCategoryIP.key]
                : [subCategory],
            groupCompanies: parties
              .filter(({ party }) => party.type === "groupCompany")
              .map(({ party }) => ({ groupCompany: party.id })),
            parties: parties
              .filter(({ party }) => party.type === "stakeholder")
              .map(({ party }) => party.id),
            file,
            status: "Uploaded",
          },
          startupId,
          [fieldAgreementId]: values.id,
        };
        setIsSubmitting(true);
        await upsertDocument({ ...stripTypenames(variables) });
        setIsSubmitting(false);
      }
    }
  };

  return (
    <ModalScroll open={open} handleClose={handleClose} maxWidth={"lg"}>
      {!loadingST && !loadingGC && !loadingCat && categories?.length > 0 && (
        <DocumentUploadLast
          data={data}
          stakeholders={stakeholders}
          groupCompanies={groupCompanies}
          handleCloseModal={handleClose}
          handleSubmit={handleSubmit}
          startupId={startupId}
          addDocument={props.addADocument}
          isSubmitting={isSubmitting}
          categories={categories}
        />
      )}
    </ModalScroll>
  );
};

export default EditDocumentUpload;

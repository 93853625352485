import React, {useEffect} from 'react';
import LastStep from "../../../core/wizards/steps/LastStep/LastStep";
import {useDispatch} from "react-redux";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import {addAssistantText, clearAssistatText} from "../../../../../generic/store/action";

const IndividualOrCompanyStepInvestor = ({})=>{
    const dispatch=useDispatch()
    const {translate} = useTranslate();
    useEffect(()=>{
        dispatch(clearAssistatText())
        dispatch(addAssistantText(translate("MODULES.ONBOARDINGROLES.INVESTOR.THIRD_STEP_TOOLTIPS"),'text'))
    },[])
    return(
        <>
            <LastStep role={'INVESTOR'}/>
        </>
    );
}

export default IndividualOrCompanyStepInvestor;

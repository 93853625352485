import {actions as actionTypes} from "../actions/autocomplete";
import _ from "lodash"

const initialState = {
    autocompletes: {

    },

};

export default (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.RESET_AUTOCOMPLETES:
            return {
                ...state,
                autocompletes: {}
            };
        case actionTypes.AUTOCOMPLETE_UPDATE_VALUE:
            let autocompletes = state.autocompletes;
            _.set(autocompletes, `${action.key}.data.${action.rowKey}.${action.fieldName}`,action.value)
            return {
                ...state,
                autocompletes
            };
        default:
            return state;
    }
};

import useMachine from '../../../../../generic/context/MachineContext/useMachine';
import SelectGroupCompaniesForm from '../../../Forms/FormSelectCompanies';
import React, { useEffect, useMemo } from 'react';
import Button from "../../../../../generic/components/Buttons/Button";
import useTranslate from '../../../../../generic/hooks/useTranslate';
import { useDispatch } from "react-redux";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import { addAssistantText, clearAssistatText } from "../../../../../generic/store/action";
import useModal from "../../../../../generic/hooks/useModal";
// import { useParams } from 'react-router-dom';
import { Constants } from 'src/v1/utils'
import useGetPriceByRole from '../../hooks/useGetPriceByRole'
/**
 * Step para editar los datos de SOF OTHER
 * @param {*} param0
 * @returns
 */

function SelectGroupCompanies({ openParam }) {

  const { next, context, prev } = useMachine();
  const role = context.roles[context.iterations || 0]
  const { translate } = useTranslate()
  const dispatch = useDispatch();
  const { closeModal: onClose } = useModal();
  const getPriceByRole = useGetPriceByRole(context.getGroupCompaniesAndStakeholder.requestItems)
  // const params = useParams<{ id }>()

  const handleCancel = (values) => {
    onClose();
    dispatch(clearAssistatText())
  }

  const handlePrevious = () => {
    prev()
  }

  const handleInitModal = () => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText(translate('MODULES.EQUITY.ADD_EQUITY.STEP_ADD_CONVERTIBLE.TOOLTIP')))
  }

  useEffect(() => {
    handleInitModal()
  }, []);

  const stakeholderHavesRoleInGroupCompany = (gc, roleToCompare) => {
    return context.getGroupCompaniesAndStakeholder.stakeholder.roles.some(roleSt => roleSt.name === roleToCompare && roleSt.entity.id === gc.id)
  }

  const handleCompleteSubmit = async (values, ref) => {
    next(values)
    ref?.current?.reset()
    if (context.roles.length - 1 >= (context.iterations || 0) + 1) {
      return ref.current?.setValue('groupCompanies', context.getGroupCompaniesAndStakeholder.groupCompanies.filter(gc => stakeholderHavesRoleInGroupCompany(gc, context.roles[(context.iterations || 0) + 1])))
    }
    ref.current?.setValue('groupCompanies', [])
  }

  const getGroupCompanies = useMemo(() => {
    return context.getGroupCompaniesAndStakeholder.groupCompanies.map(gc => {
      if (stakeholderHavesRoleInGroupCompany(gc, role)) {
        return { value: gc, label: gc.name, disabled: true }
      }
      if (role !== Constants.ROLES_FROM_BACKEND.SHAREHOLDER.id && role !== Constants.ROLES_FROM_BACKEND.DIRECTOR.id && role !== Constants.ROLES_FROM_BACKEND.THIRDPARTY.id) {
        return { value: gc, label: gc.name, disabled: gc.kind === 'FORMATION' || gc.isLocked }
      }
      return { value: gc, label: gc.name, disabled: gc.isLocked }
    })
  }, [role])

  return (
    <SelectGroupCompaniesForm
      companies={getGroupCompanies}
      role={role}
      priceList={getPriceByRole([role],'CAYMAN')}
      initialValues={{ groupCompanies: context.getGroupCompaniesAndStakeholder.groupCompanies.filter(gc => stakeholderHavesRoleInGroupCompany(gc, role)) }}
      buttons={{
        previous: (
          <Button onClick={handlePrevious}>Previous</Button>
        ),
        cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
      }}
      onCompleteSubmit={handleCompleteSubmit}
      propsTemplate={{
        title: `Select all the companies in which they are a ${Constants.ROLES_FROM_BACKEND[role].label}`,
        subTitle: context.getGroupCompaniesAndStakeholder.stakeholder.fullName
      }}
      Template={TemplateWizard}
    />
  );
}
export default SelectGroupCompanies;

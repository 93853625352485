import { useMemo } from 'react';
import useBusinessRules from 'src/modules/clara/hooks/useBusinessRules';
import useSession from 'src/modules/session/hooks/useSession';


const useGuards = () => {
  const {isOwner} = useBusinessRules()
  const { session} = useSession()
  return useMemo(() => ({

    //**  initial guard to select witch flow must follow */
    
    // in the case that the authorised signatory is a stakeholder
    isValidStakeholder: (context) => {
      return context?.initialData?.typeOfAuthorisedSignatory === 'VALID_STAKEHOLDER'
    },

    // in the case that the authorised signatory is a nominee
    isNominee: (context) => {
      return context?.initialData?.typeOfAuthorisedSignatory === 'NOMINEE'
    },

    // in the case that the authorised signatory is a third party
    isThirdParty: (context) => {
      return context?.initialData?.typeOfAuthorisedSignatory === 'THIRD_PARTY'
    },
  
  }), [isOwner, session]);
}

export default useGuards;

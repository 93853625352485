import React, { useRef, useState } from "react";
import Form from "../../../../../components/forms/Form/Form";
import ControllerInput from "../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import SubmitButton from "../../../../generic/components/Buttons/SubmitButton";
import TemplateFormDefault from "../../../../generic/templates/Modal";
import useTranslate from "../../../../generic/hooks/useTranslate";
import * as Yup from "yup";
import _ from "lodash";
import TemplateLabel from 'src/modules/generic/templates/Label'
import SelectInput from 'src/components/inputs/Select/SelectInput/SelectInput'
import useGetStatesByCountry from './../hooks/useGetStatesByCountry';
import useGetCityByState from './../hooks/useGetCityByState';

const schema = Yup.object().shape({
  state: Yup.string().nullable(),
  city: Yup.string().required("Required").nullable(),
});

const FormPlaceSelect = ({
  Template = TemplateFormDefault,
  buttons = {},
  onCompleteSubmit,
  buttonSubmitProps,
  propsTemplate,
  handleCancel,
}) => {
 
  const refForm = useRef();
  const { translate } = useTranslate();
  const [state, setState] = useState(null);
  const {data:states} =  useGetStatesByCountry({
    language : "en",
    code: "USA"
  },{})

  
const {data:city, loading, manualQuery:getCities } =  useGetCityByState({  
  language : "en",
  code: "USA",
  state: state ? state : "Alaska"   
},{})



const stateList = React.useMemo(() => {
  let statesList = [];
  _.forEach(states,(stateItem)=>{
    statesList.push({label:stateItem, value:stateItem})
  })
  return statesList;
}, [JSON.stringify(states)])


const citiesList = React.useMemo(() => {
  let citiesList = [];
  _.forEach(city,(cityItem)=>{
    citiesList.push({label:cityItem, value:cityItem})
  })
  return citiesList;
}, [JSON.stringify(city)])
  
  const handleStateChange = (value) => {
    setState(value) 
    getCities()
  }



  return (
    <React.Fragment>
      <Form
        schema={schema}
        onlySendDirty={false}
        onSubmit={onCompleteSubmit}
        ref={refForm}
        optionsForm={{ mode: "onChange" }}
      >
        <Template
          buttons={{
            ...buttons,
            submit: (
              <SubmitButton {...buttonSubmitProps}>
                {translate(`GENERIC_BUTTON_ADD_GROUP`)}
              </SubmitButton>
            ),
          }}
          props={propsTemplate}
        >
          <TemplateLabel
          label={translate('MODULES.STAKEHOLDER.PLACE_OF_BUSINESS.STEP_2.LABEL_STATE')}
          >
            <ControllerInput
              id={'formPLaceOfBusinessSelectState'}
              render={SelectInput}
              name="state"
              defaultValue={""}
              list={stateList}
              clear={true}
              placeholder={translate('MODULES.STAKEHOLDER.PLACE_OF_BUSINESS.STEP_2.PLACEHOLDER')}
              onChange={(e) => { handleStateChange(e) }}
            />
          </TemplateLabel> 
          <TemplateLabel
            label={translate('MODULES.STAKEHOLDER.PLACE_OF_BUSINESS.STEP_2.LABEL_CITY')}
          >
            <ControllerInput
              id={'formPLaceOfBusinessSelectCity'}
              render={SelectInput}
              name="city"
              list={citiesList}
              defaultValue={""}
              clear={true}
              placeholder={translate('MODULES.STAKEHOLDER.PLACE_OF_BUSINESS.STEP_2.PLACEHOLDER')}
              onChange={(e) => { }}
              disabled={!!!state}
            />
          </TemplateLabel>
        </Template>
      </Form>
    </React.Fragment>
  );
};

export default FormPlaceSelect;

import _ from 'lodash'
import useQuery from "../../../../hooks/custom/useQuery";
import { RoleFields } from "../../../../modules/stakeholder/graphql/typePolicies";
const GET_STAKEHOLDERS = `
${RoleFields}
query stakeholderCompanyView($startupId: ID! ) {
    stakeholderList(startupId:$startupId) {
        id
        fullName
        email
        roles { ...RoleFields }
    }
  }
`;
const useGetStakeholders = ({ variables, ...props }) => {
    const { loading, error, data, refetch, ...propsDesc } = useQuery(GET_STAKEHOLDERS, variables, null, props);

    return { loading, error, data: _.get(data, 'stakeholderList', []), refetch, ...propsDesc };
};
export default useGetStakeholders;

import React, { useRef } from 'react';
import { LeftEducation, Text } from '../../components';
import { context } from '../../utils/helper';

import classesModal from "./LayoutForm.module.scss"
import PropTypes from 'prop-types';
import Scrollbar from "../../../components/generic/Scrollbar/Scrollbar";
const LayoutForm = ({ children, title, leftEducationId, handleClose, showCloseIcon = true }) => {

  const contextLayout = context;

  const containerFormRef = useRef(null);


  return (
    <React.Fragment>
      <div className={classesModal.LayoutForm}>
        <Scrollbar className={`${classesModal.LeftPanel}`}>
          <div className={classesModal.IconClara}>
            <img src='/img/claraIconLogin.svg' alt='Clara' />
            <div className={classesModal.LeftEducation}>
              <LeftEducation leftEducationId={leftEducationId} />
            </div>
          </div>
        </Scrollbar>
        <Scrollbar className={`${classesModal.RightPanel}`} id={'layoutForm'}>
          <div>
            {showCloseIcon &&
              <a className={classesModal.CloseIcon} onClick={handleClose}>
                <i className={`icon-x_symbol`} />
              </a>
            } <div className={classesModal.ContentForm}>
              {title && <>
                <h1><Text uuid={title} /></h1>
                <hr />
              </>}
              <div className={classesModal.ContentChildren} >
                <contextLayout.Provider
                  value={{
                    containerFormRef
                  }}
                >
                  {children}
                </contextLayout.Provider>
              </div>
            </div>
          </div>
        </Scrollbar>
      </div>
    </React.Fragment>
  );
}
LayoutForm.propTypes = {
  children: PropTypes.element.isRequired, /* Componentn to show */
  title: PropTypes.string.isRequired,
  leftEducationId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  showCloseIcon: PropTypes.bool
};
export default LayoutForm;
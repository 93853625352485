import React, {memo} from "react";
import MicroFrontend from "src/mf";
import PropTypes from "prop-types";

const DashboardViewMF = ({ goToMF, goToCaptable, dashboardActions }) => {
  return (
    <>
      <MicroFrontend
        id="MF_Dashboard_"
        component={"./Dashboard"}
        mf="dashboard"
        params={{
          go: goToMF,
          goToCaptable: goToCaptable,
          dashboardActions,
        }}
        loader={<></>}
      />
    </>
  );
};

DashboardViewMF.propTypes = {
  goToMF: PropTypes.func,
  dashboardActions: PropTypes.any,
};

export default memo(DashboardViewMF);

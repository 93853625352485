import _ from 'lodash'
import moment from 'moment'
import React, { FC, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import Form from '../../../../../../components/forms/Form/Form'
import CalendarInputDate from '../../../../../../components/inputs/Calendars/CalendarInputDate'
import TextInput from '../../../../../../components/inputs/Text/TextInput'
import useUpdateGroupCompany from '../../../../../../hooks/services/modules/groupCompany/useUpdateGroupCompany'
import CheckboxButtonSimple, {
  CheckboxButtonOptions
} from '../../../../../generic/components/Inputs/CheckboxButtonSimple'
import TemplateLabel from '../../../../../generic/components/Inputs/LabelInput'
import ControllerInput from '../../../../../generic/components/Inputs/ReactHookForm/ControllerInput'
import FormProps from '../../../../../generic/forms/interfaces/FormProps'
import useTranslate from '../../../../../generic/hooks/useTranslate'
import {
  addAssistantText, removeAssistantText
} from '../../../../../generic/store/action'
import TemplateFormDefault from '../../../../../generic/templates/Modal'
import useSession from '../../../../../session/hooks/useSession'
import CountryJurisdiction from '../../../../../generic/components/CountryJurisdictionSelector'
import SubmitButton from './../../../../../generic/components/Buttons/SubmitButton'
import classesModule from './classes.module.scss'

const schema = Yup.object().shape({
  isTop: Yup.boolean().required('Required'),
  name: Yup.string()
    .nullable()
    .required('Add the company name'),
  country: Yup.string()
    .nullable()
    .required('Required'),
  jurisdiction: Yup.string()
    .nullable()
    .required('Required'),
  otherJurisdiction: Yup.string().when(['jurisdiction'], {
    is: 'OTHER',
    then: Yup.string()
      .nullable()
      .required('Add the jurisdiction name'),
    otherwise: Yup.string()
      .nullable()
      .optional()
  }),
  incorporationDate: Yup.date()
    .nullable()
    .typeError('Add a valid date')
    .required('Required')
})
const schemaNoTop = Yup.object().shape({
  name: Yup.string()
    .nullable()
    .required('Add the company name'),
  country: Yup.string()
    .nullable()
    .required('Required'),
  jurisdiction: Yup.string()
    .nullable()
    .required('Required'),
  otherJurisdiction: Yup.string().when(['jurisdiction'], {
    is: 'OTHER',
    then: Yup.string()
      .nullable()
      .required('Add the jurisdiction name'),
    otherwise: Yup.string()
      .nullable()
      .optional()
  }),
  incorporationDate: Yup.date()
    .nullable()
    .typeError('Add a valid date')
    .required('Required')
})
type CompanyFormSubmit = (values: object) => Promise<any>
interface CompanyFormProps extends FormProps {
  hasTopCompany: boolean
  onCompleteSubmit?: CompanyFormSubmit
}
const CompanyForm: FC<CompanyFormProps> = ({
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
  initialValues,
  onCompleteSubmit,
  tooltips,
  hasTopCompany,
  ...props
}) => {
  const refForm = useRef()
  const { translate } = useTranslate()
  const [first, setFirst] = useState()
  const dispatch = useDispatch()
  const [upsertGroupCompany] = useUpdateGroupCompany({
    showSuccessNotification: false
  })

  const { startupId } = useSession();

  const options: Array<CheckboxButtonOptions<boolean, any>> = [
    {
      value: true,
      label: translate(`MODULES.ONBOARDINGCOMPANY.LABEL_ANOTHER_YES`)
    },
    {
      value: false,
      label: translate(`MODULES.ONBOARDINGCOMPANY.LABEL_ANOTHER_NO`)
    }
  ]

  const formatDate = (date) => {

    return date === null ? null : moment(date).format("YYYY-MM-DD"); 

  }

  const handleCompleteSubmit = async values => {
    const {
      isTop: isHoldingCompany,
      name,
      jurisdiction,
      otherJurisdiction,
      country,
      incorporationDate: incorporation_date
    } = values
    const groupCompany = {
      name,
      isHoldingCompany,
      jurisdiction: jurisdiction === 'OTHER' ? otherJurisdiction : jurisdiction,
      country,
      incorporation_date: formatDate(incorporation_date)
    }
    const resultCompany = await upsertGroupCompany({ variables: { startupId, groupCompany } });
    onCompleteSubmit && await onCompleteSubmit(_.get(resultCompany,"data.upsertGroupCompany",null));
  }

  return (
    <Form
      defaultValues={initialValues}
      schema={hasTopCompany ? schemaNoTop : schema}
      onlySendDirty={false}
      onSubmit={handleCompleteSubmit}
      ref={refForm}
      optionsForm={{ mode: 'onChange' }}
    >
      <Template
        buttons={{
          ...buttons,
          submit: <SubmitButton {...buttonSubmitProps}>Save</SubmitButton>
        }}
        props={propsTemplate}
      >
        {!hasTopCompany && (
          <TemplateLabel
            label={translate('MODULES.ONBOARDINGCOMPANY.LABEL_TOP_COMPANY')}
            className={classesModule.LabelTitle}
            isRequired={true}
          >
            <ControllerInput
              as={CheckboxButtonSimple}
              className={classesModule.CheckboxButton}
              name='isTop'
              options={options}
              onChange={value => {
                if (tooltips) {
                  if (!value) {
                    setFirst(true)
                    dispatch(
                      addAssistantText(_.get(tooltips, 'isNotTop', 'text'))
                    )
                  } else if (first && value) {
                    dispatch(removeAssistantText())
                    setFirst(false)
                  }
                }
              }}
            />
          </TemplateLabel>
        )}
        <TemplateLabel
          label={translate('MODULES.ONBOARDINGCOMPANY.LABEL_NAME')}
          className={classesModule.LabelTitle}
          isRequired={true}
        >
          <ControllerInput
            render={TextInput}
            name='name'
            placeholder={'As shown on the Certificate of Incorporation'}
            defaultlabel={''}
          />
        </TemplateLabel>
        <CountryJurisdiction />
        <TemplateLabel
          label={translate(
            'MODULES.ONBOARDINGCOMPANY.LABEL_INCORPORATION_DATE'
          )}
          className={classesModule.LabelTitle}
          isRequired={true}
        >
          <ControllerInput
            as={CalendarInputDate}
            name='incorporationDate'
            placeholder={'dd/mm/yyyy'}
          />
        </TemplateLabel>
      </Template>
    </Form>
  )
}
export default CompanyForm

const useGetPriceByRole = (requestItems) => {
  const getPriceByRole = (roles, jurisdiction) => {
    let pricesRoles = [];
    roles.forEach(role => {
      if (requestItems[`CHANGE_STAKEHOLDER_ON_ROLE_${role}`]) {
        pricesRoles.push({ price: requestItems[`CHANGE_STAKEHOLDER_ON_ROLE_${role}`].value, role })
      }
    })
    return pricesRoles;
  }
  return getPriceByRole
}
export default useGetPriceByRole
import React, { FC, useMemo } from 'react';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import  AddFormationCompanyInDelawareWizard from './../../submachines/AddFormationCompanyInDelawareModal/wizard';

interface Props {

}
/**
* Description Step: Add formation company in Delaware 
*/
const AddFormationCompanyInDelawareSubMachineStep: FC<Props> = () => {

  const { next, context, prev, cancel } = useMachine();

  const initialValues = useMemo(() => {
    return context?.AddFormationCompanyInDelawareSubMachineStep ?? {}
  }, [context]);
  
  const handleStopMachine = () => {
    next(context)
  }

  const handleCancelMachine = () => {
    cancel();
  }
  
  const handlePrevMachine = () => {
      prev();
  }
  
  return (
    <AddFormationCompanyInDelawareWizard initialValues={initialValues} onFinish={handleStopMachine} onCancel={handleCancelMachine} onPreviousMachine={handlePrevMachine}/>
  );
};

export default AddFormationCompanyInDelawareSubMachineStep;

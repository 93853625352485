import { useMemo } from 'react';
import useBusinessRules from 'src/modules/clara/hooks/useBusinessRules';
import useSession from 'src/modules/session/hooks/useSession';


const useGuards = () => {

  const { isOwner } = useBusinessRules()
  const { session } = useSession()

  return useMemo(() => ({
    isCond_1: (context) => {
      return !context?.SelectCertificate?.selectType;
    },
    isCond_2: (context) => {
      return context?.SelectCertificate?.selectType;
    },
  }), [isOwner, session]);
}

export default useGuards;

import React, { Component } from "react";
import { connect } from "react-redux";
import { DeleteForm, Loading } from "../../index";
import {Constants, ServerConnect} from "../../../utils";
import { deleteDocumentQuery as eliminate } from '../../../graphql/mutations/addAgreement';

import { COMPANY_CORPORATE_MAP_TASKS } from './../../../../modules/assistanceV4/hooks/services/useGetCompanyCorporate'
import { gql } from '@apollo/client'
import _ from "lodash"
import {TABLE_AGREEMENT_LIST} from "../../../../views/modules/startups/documents/Documents/graphql/queries/useGetTableAgreementList";
class DocumentDeleteForm extends Component {
    state = {
        data: {},
        showLoading: false,
        options: [],
		warningText: null
    };
    /* Particular data */
    getTitle() {
        const data = this.state.data;
        return "Delete document";
    }

    setOptions() {
    	const {data} = this.state;
        let options = [];
        let warningText;

        if (data.parentDocument) {
			warningText = 'This action will also delete all related documents and cannot be undone';
		}
        options.push({ label: "Delete this document?" });

        this.setState({
            options,
			warningText
        })
    }

    hasAllData() {
        return true;
    }

    /*  Call Server*/
    callServerToGetData = () => {
        this.showLoading(true);
        //Get data if exist this possibility
    };

    deleteEntity = () => {
        return new Promise((resolve, reject) => {
            var data = {
                startupId: this.props.session.startup.id,
                agreementId: this.state.data.documentId || this.state.data.agreementId || this.state.data.id,
            };

            this.showLoading(true);
            const refetchQueries= [{query:gql`${COMPANY_CORPORATE_MAP_TASKS}`, variables:{startupId:this.props.session.startup.id}},{query:gql`${TABLE_AGREEMENT_LIST}`, variables:{startupId:this.props.session.startup.id}}];
            ServerConnect.graphQlMutation(eliminate, data,{},{},{
                refetchQueries
            }).then((result) => {
                this.showLoading(false);
                if (this.props.callbacks) {
                    this.props.callbacks.deleteEntity(data);
                }
               
                
                resolve();


            }).catch(err => {
                reject(err)
            });
        });
    };

    /* Change status */
    showLoading = (status) => {
        this.setState({
            showLoading: status
        });
    };

    /* React functions */
    componentWillMount() {
        if (this.hasAllData()) {
            this.setState({
                data: this.props.data
            }, () => {
                this.setOptions();
            })


        } else {
            this.callServerToGetData();
        }
    }

    render() {
        return (
            <React.Fragment>
                {!this.state.showLoading &&
				<DeleteForm
					handleClose={this.props.handleClose}
					show={true}
					deleteEntity={this.deleteEntity}
					showLoading={this.showLoading}
					title={this.getTitle()}
					options={this.state.options}
					warning={this.state.warningText}>
				</DeleteForm>}
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    session: state.session,
    form: state.form
});
const mapDispatchToProps = (dispatch) => ({
    
});
export default connect(mapStateToProps, mapDispatchToProps)(DocumentDeleteForm)

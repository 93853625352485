import { gql } from '@apollo/client';
import useMutation from "../../../../../hooks/custom/useMutation";
import useSession from "../../../../session/hooks/useSession";
import { CONVERTIBLE, OWNER_CONVERTIBLE } from "../fragments";
import { COMPANY_CORPORATE_MAP } from './../../../../../modules/map/hooks/services/useCompanyCorporateMap';
export const UPSERT_CONVERTIBLE = `
${CONVERTIBLE}
${OWNER_CONVERTIBLE}
mutation upsertConvertible(
    $startupId: String
    $owner: OwnerInput
    $convertibleData: ConvertibleInput
  ) {
    upsertConvertible(
      startupId: $startupId
      owner: $owner
      convertibleData: $convertibleData
    ) {
    ...ConvertibleFragment
    owner{
    ...OwnerConvertible
    }
    }
  }
`
const useUpsertConvertible=(config,props)=>{
  const { startupId } = useSession();
    const [upsertConvertible] = useMutation(UPSERT_CONVERTIBLE, config,{ refetchQueries:[{query:gql`${COMPANY_CORPORATE_MAP}`, variables:{startupId:startupId}}], ...props })
    return {upsertConvertible}
}
export default useUpsertConvertible
import _ from "lodash";
import useMutation from "src/hooks/custom/useMutation";
/* 
	startupId: ID!
				groupCompanyId: ID!
				entityData: RegulatoryBusinessEntityInput!
*/
const CREATE_MANY_IMMEDIATE_PARENT_MUTATION = `
mutation createManyImmediateParent($startupId: ID!, $groupCompanyId: ID!, $entitiesData: [RegulatoryBusinessEntityInput!]!) {
  createManyImmediateParent(startupId: $startupId, groupCompanyId: $groupCompanyId, entitiesData: $entitiesData) {
    name
  }
}`

const useCreateManyImmediateParent =() => {
  const [createManyImmediateParent] = useMutation(
    CREATE_MANY_IMMEDIATE_PARENT_MUTATION
  );
  return {
   createManyImmediateParent
  };
}
export default useCreateManyImmediateParent
import React from 'react';
import { useDispatch } from "react-redux";
import IntroSubmitFlow from "../../../../../components/modules/incorporation/InformationFlow/InformationFlow";
import image from '../../../../../images/submitFlowImg.svg';
import useNavigation from "../../../../../modules/generic/hooks/useNavigation"
import { Constants } from "../../../../../v1/utils/constants"
 

const ScopingDisagreeView = (props) => {
    const {goTo, params} = useNavigation();

    const handleLearnMoreButtonUrl = (params) => {
        return window.open("https://academy.clara.co","_blank");
    }

    const handleNext = () => { 
        goTo(Constants.PAGES.switchStartup,params);
    }

    return (
        <React.Fragment>
            <IntroSubmitFlow
                image= {image}
                formTitle ={"SCOPING_DISAGREE_FORM_TITLE"}
                firstTitle ={"SCOPING_DISAGREE_FIRST_TITLE"}
                firstText = {"SCOPING_DISAGREE_FIRST_TEXT"}
                secondText = {"SCOPING_DISAGREE_SECOND_TEXT"}
                buttonText= {"SCOPING_DISAGREE_BUTTON_NEXT_TEXT"}
                secondButtonText={"SCOPING_SUBMITTED_ACADEMY_BUTTON"}
                handleNext = {handleNext}
                handleClose  ={handleLearnMoreButtonUrl}
            />
        </React.Fragment >
    )
}

export default ScopingDisagreeView;

import useMutation from "src/hooks/custom/useMutation";

const GET_DOCUMENTS_LINK_FROM_ACCELERATOR = `
mutation updateAcceleratorEventALLDocumentsAdded($startupId: ID, $eventType: AcceleratorEventEnum = ALL_DOCUMENTS_ADDED) {
    startup: updateAcceleratorEvent(startupId:$startupId eventType:$eventType) {
    id
  }}
`;

const useGetAcceleratorDocumentsLink = (variables, config) => {
  const [mutation] = useMutation(GET_DOCUMENTS_LINK_FROM_ACCELERATOR, variables, { ...config });
  return mutation;
}

export default useGetAcceleratorDocumentsLink;
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as Yup from 'yup'
import * as numeral from 'numeral'
import _ from 'lodash'
import classes from './Form.module.scss'
import useTranslate from "../../../generic/hooks/useTranslate";
import Form from "../../../../components/forms/Form/FormL";
import ControllerInput from "../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import SubmitButton from "../../../generic/components/Buttons/SubmitButton";
import TemplateFormDefault from "../../../generic/templates/Modal";
import FormProps from "../../../generic/forms/interfaces/FormProps";
import Typography from "../../../generic/components/Texts/Typography";
import { StakeholderBO } from "../../core/type/startup/stakeholder";
import Table from "../../../../components/Table/Table";
import TextInput from "../../../../components/inputs/Text/TextInput";
import { useFormContext } from "react-hook-form";
import useGetStakeholders from "../../../../hooks/services/modules/stakeholders/useGetStakeholders";
import Parser from "html-react-parser";
import useSession from "../../../session/hooks/useSession";

interface ShareCapitalDelawareForm extends FormProps {
  groupCompanyId: string
}

interface Value {
  shares: number
}

interface GetStakeholders {
  data: StakeholderBO
}

const schema = Yup.object().shape({
  stakeholders: Yup.array().of(Yup.object().shape({
    shares: Yup.number().typeError('Must be a number').required('Required').min(1, 'Must at at least have one share'),
    id: Yup.string().required()
  })).test('stakeholders', (value: Value[]) => {
    let result = _.reject(value, _.isEmpty);
    if (_.sumBy(result, value => value.shares) !== 8000000) {
      return new Yup.ValidationError(
        '', // your custom error message
        null,
        'shares')
    }
    return true;
  })
})

const ShareCapitalDelawareForm: FC<ShareCapitalDelawareForm> = ({
  initialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  submitButtonChildren = 'Add',
  buttonSubmitProps,
  propsTemplate,
  groupCompanyId,
}: ShareCapitalDelawareForm) => {

  const { translate } = useTranslate()
  const issuedShares = useMemo(() => 8000000, [])
  const [remaining, setRemaining] = useState(issuedShares)
  const { startupId } = useSession()
  const { data }: GetStakeholders = useGetStakeholders({ variables: { startupId } })

  const shareholders = useMemo(() => {
    function ordenarResultados(initialValues, result) {
      const sortedResult = [...result];
      sortedResult.sort((a, b) => {
        const indexA = initialValues.stakeholders.findIndex(
          (stakeholder) => stakeholder.id === a.id
        );
        const indexB = initialValues.stakeholders.findIndex(
          (stakeholder) => stakeholder.id === b.id
        );
        if (indexA !== -1 && indexB !== -1) {
          return indexA - indexB;
        }
        if (indexA !== -1) {
          return -1;
        }
        if (indexB !== -1) {
          return 1;
        }
        return 0;
      });
      return sortedResult;
    }

    let result = _.map(_.filter(data, (stakeholder: StakeholderBO) =>
      _.some(stakeholder.roles, role => role.name === 'SHAREHOLDER' && role.groupCompany.id === groupCompanyId)),
      (sh: StakeholderBO, index: number) => ({ index, ...sh }));

    if (!initialValues?.stakeholders) {
      return result;
    }

    const resultOrder = ordenarResultados(initialValues, result);
    _.forEach(resultOrder, (stakeholder, index) => {
      stakeholder.index = index;
    })

    return resultOrder;
  }, [JSON.stringify(data), initialValues])

  const getPercentage = useCallback((shares: number): string => {
    if (shares) {
      const percentage = (shares * 100) / issuedShares;
      return `${percentage.toFixed(2)}%`;
    }
    return '0%';
  }, [issuedShares]);

  const columns = useMemo(() => ([{
    Header: ' ',
    columns: [
      {
        accessor: 'name',
        Cell: ({ row }) => {
          return (
            <Typography component={'label'} color={'dark-grey'} variant={'body'}>
              {row.original.fullName}
            </Typography>
          )
        }
      },
      {
        accessor: 'percentage',
        Header: () => (<Typography component={'p'}>{translate('MODULES.GROUP_COMPANY.FORM.SHARE_CAPITAL_DELAWARE.PERCENTAGE')}</Typography>),
        Cell: ({ row }) => {
          const { watch } = useFormContext()
          return (
            <TextInput value={getPercentage(watch(`stakeholders[${row.original.index}].shares`))} disabled={true} className={classes.spacerItems} />
          )
        }
      },
      {
        accessor: 'shares',
        Header: () => (<Typography component={'p'}>{translate('MODULES.GROUP_COMPANY.FORM.SHARE_CAPITAL_DELAWARE.NUMBER_OF_SHARES')}<span style={{ color: 'red' }}>*</span></Typography>),
        Cell: ({ row }) => {
          const { watch, setValue } = useFormContext()
          useEffect(() => {
            setValue(`stakeholders[${row.original.index}].id`, row.original.id)
          }, [])
          return (
            <>
              <div>
                <ControllerInput
                  name={`stakeholders[${row.original.index}].id`}
                  render={() => null}
                />
                <ControllerInput
                  name={`stakeholders[${row.original.index}].shares`}
                  render={TextInput}
                  defaultValue={''}
                  type={'number'}
                  onChange={value => {
                    setRemaining(issuedShares - _.sumBy(watch(`stakeholders`), (sh: Value) => _.toNumber(sh.shares)))
                  }}
                />
              </div>
            </>
          )
        }
      }
    ]
  }]), [initialValues])

  const refForm = useRef();

  return (
    <>
      <Form
        initialValues={initialValues || {}}
        schema={schema}
        onlySendDirty={false}
        onSubmit={onCompleteSubmit}
        ref={refForm} optionsForm={{ mode: "onChange" }}
      >
        <Template
          buttons={{ ...buttons, submit: <SubmitButton {...buttonSubmitProps}>{submitButtonChildren}</SubmitButton> }}
          props={propsTemplate}
        >
          <Typography component={'p'} variant={'body'}>
            {Parser(translate('MODULES.GROUP_COMPANY.FORM.SHARE_CAPITAL_DELAWARE.PARAGRAPH') || '')}
          </Typography>
          <Typography component={'p'} variant={'body'}>
            {Parser(translate('MODULES.GROUP_COMPANY.FORM.SHARE_CAPITAL_DELAWARE.REMAINING_SHARES', { shares: numeral(remaining).format("0,0") }) || '')}
          </Typography>
          <div>
            <Typography component={'p'} variant={'body'} className={classes.spacerTable}>
              {translate('MODULES.GROUP_COMPANY.FORM.SHARE_CAPITAL_DELAWARE.STAKEHOLDERS_SHARES')}
            </Typography>
            <Table
              className={{ table: classes.containerTableSharesNoBoder }}
              columns={columns}
              data={shareholders}
            />
          </div>
        </Template>
      </Form>
    </>
  )
}

export default ShareCapitalDelawareForm

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import _ from "lodash";
import {
  EquityTab,
  PositionTab,
  RolesTab,
} from "src/components/modules/clara/startups/individualStakeholder/Tabs";
import AddRole from "src/modules/startup/Stakeholder/AddRole";
import Button from "src/modules/generic/components/Buttons/Button";
import { Constants, ServerConnect } from "src/v1/utils";
import DeleteStakeholder from "src/modules/startup/Stakeholder/Delete/index.tsx";
import Details from "src/components/templates/Details/Details";
import EditPosition from "src/components/modules/clara/startups/individualStakeholder/Modals/EditPositions";
import EditProfileModal from "src/components/modules/clara/startups/individualStakeholder/Modals/EditProfile/EditProfile";
import ModalCategoriseDocument from "src/components/modules/clara/startups/Document/Modals/CategoriseDocument";
import ModalInviteCollaborator from "src/modules/startup/Stakeholder/InviteCollaborator/ModalInviteCollaborator";
import PincasIcon from "src/modules/generic/components/Icon/PincasIcon";
import RemoveAsFounder from "src/modules/startup/Stakeholder/RemoveAsFounder";
import { RoleAccess } from "src/v1/components";
import Stakeholder from "src/components/modules/clara/startups/entities/classes/Stakeholder";
import Text from "src/components/text/Text/Text";
import { updateParamsBreadCrumbs } from "src/store/actions/page";
import useGetStartupFounders from "src/modules/startup/graphql/startup/useGetStartupFounders";
import useIndividualStakeholderData from "src/hooks/services/modules/startups/individual/useIndividualStakeholderData";
import useModal from "src/modules/generic/hooks/useModal";
import useSession from "src/modules/session/hooks/useSession";
import useShareProfiles from "src/hooks/services/modules/startups/shareManagement/useShareProfiles";
import useTextLang from "src/hooks/custom/useTextLang";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import useLoading from "src/modules/generic/hooks/useLoading";
import { ComplianceTab, DetailsTab } from "./components/Tabs";
import DocumentsTab from "../../../../../components/modules/clara/startups/individualStakeholder/Tabs/DocumentsTab/DocumentsTab";

const IndividualStakeholderView = () => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const { openModal, closeModal } = useModal();
  const { startup } = useSession();
  const params = useParams();
  const [user, setUser] = useState(null);
  const [hasMoreFounders, setHasMoreFounders] = useState(false);
  const { isLoading } = useLoading();

  const isLock = useMemo(() => _.get(user, "isLocked", false), [user]);

  const { data: founders } = useGetStartupFounders({ startupId: startup.id });

  const { data: userData } = useIndividualStakeholderData({
    startupId: startup.id,
    stakeholderId: params.id,
  });

  const handleChangeAvatar = async (value) => {
    await ServerConnect.requestApi(
      `/avatars/startups/${startup.id}/stakeholders/${params.id}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          avatarData: value,
        }),
      },
      null,
      { showNotification: false }
    );
  };

  const categorizeFile = () => {
    openModal(
      ModalCategoriseDocument,
      {
        isCategorize: false,
        isEdit: false,
        isVisaOrPassportDocument: false,
      },
      "MODAL_CATEGORISE_DOCUMENT"
    );
  };

  useEffect(() => {
    if (_.get(founders, "founders", null)) {
      setHasMoreFounders(_.get(founders, "founders", []).length > 1);
    }
  }, [founders]);

  useEffect(() => {
    setUser(_.get(userData, "getStakeholder"));
  }, [JSON.stringify(userData)]);

  useEffect(() => {
    updateParamsBreadCrumbs(dispatch, { fullName: _.get(user, "fullName") });
  }, [user]);

  const stakeholderClass = useMemo(() => {
    if (!user) return null;
    return new Stakeholder(user);
  }, [user]);

  const isManagedByClara = useMemo(() => {
    if (!user) return false;
    return stakeholderClass.getIsManagedByClara();
  }, [user]);

  const jurisdictions = useMemo(() => {
    if (!user) return null;
    const result = stakeholderClass.getJurisdictionsUsingRolesAndRolesInProgress();
    return result;
  }, [user]);

  const dataRoles = useMemo(() => {
    let roles = _.map(_.get(user, "roles", []), (role) => {
      return {
        ...role,
        inProgress: false,
      };
    });
    let rolesInProgress = _.map(
      _.get(user, "rolesInProgress.toAdd", []),
      (role) => {
        _.remove(
          roles,
          (r) => r.name === role.name && r.entity.id === role.entity.id
        );
        return {
          ...role,
          inProgress: true,
        };
      }
    );
    _.forEach(_.get(user, "rolesInProgress.toDelete", []), (role) => {
      _.remove(
        roles,
        (r) => r.name === role.name && r.entity.id === role.entity.id
      );
    });
    _.forEach(_.get(user, "rolesInProgress.toDelete", []), (role) => {
      _.remove(
        roles,
        (r) => r.name === role.name && r.entity.id === role.entity.id
      );
      rolesInProgress.push({
        ...role,
        inProgress: true,
      });
    });
    return _.concat(roles, rolesInProgress);
  }, [user]);

  let contents = useMemo(() => {
    return [
      {
        show: true,
        component: (
          <DetailsTab
            data={user}
            lock={isLock}
            isManagedByClara={isManagedByClara}
            jurisdictions={jurisdictions}
          />
        ),
      },
      {
        show: true,
        component: (
          <RolesTab
            data={dataRoles}
            lock={isLock}
            isManagedByClara={isManagedByClara}
            jurisdictions={jurisdictions}
          />
        ),
      },
      {
        show: true,
        component: (
          <PositionTab
            data={_.get(user, "startupPosition", {})}
            lock={isLock}
          />
        ),
      },
      {
        show: true,
        component: <DocumentsTab />,
      },
      {
        show: true,
        component: <EquityTab />,
      },
      {
        show:
          user?.sourcesOfFunds?.length > 0 ||
          _.get(user, "pep.isPEP", null) !== null,
        component: (
          <ComplianceTab
            data={user}
            lock={isLock}
            jurisdictions={jurisdictions}
          />
        ),
      },
    ];
  }, [JSON.stringify(user), JSON.stringify(userData)]);

  const tabs = [
    {
      show: true,
      disabled: false,
      component: (
        <label>
          <Text uuid="STAKEHOLDER_VIEW_TAB_LABEL_DETAILS" />
        </label>
      ),
      tabName: translate("STAKEHOLDER_VIEW_TAB_LABEL_DETAILS"),
      key: "details",
      multiplePanels: true,
    },
    {
      show: true,
      disabled: false,
      component: (
        <label>
          <Text uuid="ROLES" />
        </label>
      ),
      tabName: useTextLang("ROLES"),
      tabKey: "ROLES",
      key: "roles",
    },
    {
      show: true,
      disabled: false,
      component: (
        <label>
          <Text uuid="POSITION" />
        </label>
      ),
      tabName: useTextLang("POSITION"),
      tabKey: "POSITIONS",
      key: "position",
    },
    {
      show: true,
      disabled: false,
      component: (
        <label>
          <Text uuid="DOCUMENTS" />
        </label>
      ),
      tabName: useTextLang("DOCUMENTS"),
      tabKey: "DOCUMENTS",
    },
    {
      show: true,
      disabled: false,
      component: (
        <label>
          <Text uuid="EQUITY" />
        </label>
      ),
      tabName: useTextLang("EQUITY"),
      tabKey: "EQUITY",
      key: "equity",
    },
    {
      show:
        user?.sourcesOfFunds?.length > 0 || _.get(user, "pep.isPEP", null) !== null,
      disabled: false,
      component: (
        <label>
          <Text uuid="COMPLIANCE" />
        </label>
      ),
      tabName: useTextLang("COMPLIANCE"),
      tabKey: "COMPLIANCE",
      key: "compliance",
      multiplePanels: true,
    },
  ];

  const getTabs = () => {
    let filteredTabs = [];
    _.forEach(tabs, (tab) => {
      if (tab.show) {
        filteredTabs.push(tab);
      }
    });
    return filteredTabs;
  };

  const getTabContents = () => {
    let filteredContents = [];
    _.forEach(contents, (content) => {
      if (content.show) {
        filteredContents.push(content.component);
      }
    });
    return filteredContents;
  };

  const { data: sharingProfiles } = useShareProfiles({ startupId: startup.id });

  const canInviteCollaborator = useCallback(() => {
    const stakeholder = _.get(userData, "getStakeholder");
    const shares = _.get(sharingProfiles, "getStartupUsersProfiles");
    // TODO Review for StakeholderCorporate when rol.groupCompany is null
    return (
      stakeholder &&
      shares &&
      !_.some(shares, { email: stakeholder.email }) &&
      _.some(
        stakeholder.roles,
        (role) =>
          (role.name === "DIRECTOR" || role.name === "SHAREHOLDER") &&
          role.groupCompany &&
          role.groupCompany.kind === "FORMATION"
      )
    );
  }, [JSON.stringify(userData), JSON.stringify(sharingProfiles)]);

  const showInactiveTag = useMemo(() => {
    let result = false;
    if (!_.isEmpty(userData)) {
      const allRoles = _.get(userData, "getStakeholder.roles", []);
      const isInactive = _.every(allRoles, {'isActive': false});
      if (userData?.getStakeholder?.isFounder === false) {
        result = isInactive;
      }
    }
    return result;
  }, [userData]);

  return (
    <>
      {!isLoading && (
        <Details
          showIsInactive={showInactiveTag}
          showFounderTag={_.get(userData, "getStakeholder.isFounder", false)}
          lastUpdated={_.get(user, "updatedAt")}
          lock={isLock}
          collapseButton={true}
          title={_.get(user, "fullName")}
          additionalTitle={
            <>
              {stakeholderClass?.getFieldInMatter("fullName") ? (
                <PincasIcon />
              ) : (
                ""
              )}
            </>
          }
          avatarName={_.get(user, "fullName")}
          avatarImg={_.get(user, "avatar")}
          onChangeAvatar={handleChangeAvatar}
          buttonsTabs={{
            details: [
              <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
                <Button
                  disabled={isLock}
                  variant="card"
                  children={translate("VIEW_COMPANY_TAB_BUTTON_DETAILS_EDIT")}
                  onClick={() => {
                    openModal(EditProfileModal, {
                      handleCloseModal: closeModal,
                      isManagedByClara,
                      jurisdictions,
                    });
                  }}
                />
              </RoleAccess>,
            ],
            roles: [
              <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
                {_.get(userData, "getStakeholder.isFounder", false) && (
                  <Button
                    variant="cardWarning"
                    disabled={isLock}
                    onClick={() => {
                      openModal(RemoveAsFounder, {
                        stakeholder: _.get(userData, "getStakeholder"),
                        canRemove: hasMoreFounders,
                        handleCloseModal: closeModal,
                      });
                    }}
                  >
                    {translate("VIEW_ROLES_TAB_BUTTON_REMOVE_FOUNDER")}
                  </Button>
                )}
              </RoleAccess>,
            ],
            position: [
              <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
                <Button
                  variant="card"
                  disabled={isLock}
                  onClick={() => {
                    openModal(EditPosition, {
                      stakeholderId: _.get(userData, "getStakeholder.id"),
                      initialValues: _.get(user, "startupPosition", {}),
                      handleCloseModal: closeModal,
                    });
                  }}
                >
                  {translate("VIEW_COMPANY_TAB_BUTTON_DETAILS_EDIT")}
                </Button>
              </RoleAccess>,
            ],
          }}
          buttons={[
            <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
              <Button
                variant="card"
                disabled={isLock}
                onClick={() => {
                  openModal(AddRole, { handleCloseModal: closeModal });
                }}
              >
                {translate("VIEW_ROLES_DEATILS_BUTTON_ADD_ROLE")}
              </Button>
            </RoleAccess>,
            <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
              <Button variant="card" disabled={isLock} onClick={categorizeFile}>
                {translate("STAKEHOLDER_VIEW_BUTTON_ADD_DOCUMENT")}
              </Button>
            </RoleAccess>,
            <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
              {canInviteCollaborator() && (
                <Button
                  variant="card"
                  disabled={isLock}
                  onClick={() => {
                    openModal(ModalInviteCollaborator, {
                      handleCloseModal: closeModal,
                      paramsMutation: {
                        email: _.get(userData, "getStakeholder.email"),
                        fullName: _.get(userData, "getStakeholder.fullName"),
                      },
                    });
                  }}
                >
                  {translate("STAKEHOLDER_VIEW_BUTTON_INVITE_COLLABORATOR")}
                </Button>
              )}
            </RoleAccess>,
            <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
              <Button
                variant="cardWarning"
                disabled={isLock}
                onClick={() => {
                  openModal(DeleteStakeholder, {
                    handleCloseModal: closeModal,
                  });
                }}
              >
                {translate("STAKEHOLDER_VIEW_BUTTON_DELETE")}
              </Button>
            </RoleAccess>,
          ]}
          tabContents={getTabContents()}
          tabs={getTabs()}
        />
      )}
    </>
  );
};

export default IndividualStakeholderView;

import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import Button from "../../../../../generic/components/Buttons/Button";
import useMachine from '../../../../../generic/context/MachineContext/useMachine';
import useModal from "../../../../../generic/hooks/useModal";
import useTranslate from '../../../../../generic/hooks/useTranslate';
import { clearAssistatText } from "../../../../../generic/store/action";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import IsIndividualOrCompany from '../../../core/forms/IsIndividualOrCompany';
function IsEntity({ openParam }) {

  const { next } = useMachine();
  const { translate } = useTranslate()
  const dispatch = useDispatch();
  const { closeModal: onClose } = useModal();

  const handleCancel = (values) => {
    dispatch(clearAssistatText());
    onClose();
  }

  const handleInitModal = () => {
    dispatch(clearAssistatText());
  }

  useEffect(() => {
    handleInitModal()
  }, []);

  const handleCompleteSubmit = async (values) => {
    next(values)
  }

  return (
    <IsIndividualOrCompany
      buttons={{
        cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
      }}
      onCompleteSubmit={handleCompleteSubmit}
      propsTemplate={{
        title: 'Are they an individual or company?',
        subTitle: 'Add a stakeholder'
      }} 
      Template={TemplateWizard}
    />
  );
}
export default IsEntity;

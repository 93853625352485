import {Reference, TypePolicy} from "@apollo/client";
import _ from 'lodash'
const Category:TypePolicy={
    keyFields:['code','subCategories']
}
const SubCategory:TypePolicy={
    keyFields:['code','documentTypes']
}

const GroupCompanyParty:TypePolicy={
    keyFields:[
    'groupCompany',
    'signedBy'
]
}
const Document:TypePolicy={
    keyFields:['id'],
    fields: {
        relatedDocuments(existingDocuments: Reference[], {canRead,cache,readField}) {
            _.forEach(existingDocuments,(doc:Reference)=>{
                if(canRead(doc)) {
                    const document = readField(doc)
                    if (!canRead(_.get(document,'parentDocument'))) cache.evict({id: cache.identify({id: document})})
                }})
            return existingDocuments
                ? existingDocuments.filter(canRead)
                : [];
        },
        name:{

        }
    }
}

const DocumentType:TypePolicy={
    keyFields:['code']
}

export {
    Category,
    DocumentType,
    SubCategory,
    Document,
    GroupCompanyParty
}
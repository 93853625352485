import React, {Component} from 'react';
import ServerConnect from "../../utils/ServerConnect";
import Modal from "@material-ui/core/es/Modal/Modal";
import classes from "./KnowHow.module.scss"

const modalBodyStyle = {
    display: 'flex',
    maxWidth: '1000px',
    margin: 'auto',
    marginTop: '11%',
    marginBottom: '11%'
};

const knowHowContent = {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    padding: '30px',
    width: '75%'
};

const title1 = {
    color: '#192A3E'
};


const title2 = {
    color: '#192A3E'
};


const description = {
    color: '#192A3E'
};

class KnowHow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openButton: false,
            open: false,
            legalReferences: [],
            activeLegalReference: {
                content: {
                    title: '',
                    description: ''
                }
            }
        }
    }

    handleOpen(legalReference) {
        const legalReferences = this.setModal(legalReference, true);

        this.setState(prevState => ({
            ...prevState,
            legalReferences
        }));

        this.setModalContent(legalReference);
    };

    handleClose(legalReference) {
        const legalReferences = this.setModal(legalReference, false);

        this.setState(prevState => ({
            ...prevState,
            legalReferences
        }));
    };

    handleClickButton = () => {
        let openButton = !this.state.openButton;
        this.setState({
            openButton: openButton
        });
    };

    setModal(legalReference, value) {
        const legalReferences = this.state.legalReferences;
        const index = this.state.legalReferences.indexOf(legalReference);

        legalReferences[index].open = value;

        return legalReferences;
    }


    componentWillMount() {
        const path = `/contentful/knowHow/${this.props.entryId}`;

        const options = { method: 'GET' };

        ServerConnect.requestApi(path, options)
            .then(fields => {
                if (fields.response){
                    this.setState(prevState => ({
                        ...prevState,
                        legalReferences: fields.response.filter(legalReference => legalReference.context.includes(this.props.context))
                    }));

                    this.setModalContent(this.state.legalReferences[0]);
                }
            })
            .catch((err) => { // TODO: See what to do if this fails.
                alert(`Contentful error: ${err}`);
            });
    }

    renderQuestionList(legalReferences) {
        return legalReferences.map(legalReference => (
            <li className={classes.listItem}>
                <p onClick={() => this.handleOpen(legalReference)}>{legalReference.title}</p>
                <Modal
                    open={legalReference.open}
                    onClose={() => this.handleClose(legalReference)}
                >
                    <div style={modalBodyStyle}>
                        <div className={` ${classes.KnowHowBoxModal}`}>
                            <div className={classes.KnowHowHeader}>
                                <div className={classes.KnowHowSelectModal}>
                                    <img src="/img/icons/academy_knowhow_modal.svg" alt="Know-How" />
                                    <label>Know-How</label>
                                </div>
                            </div>
                            <div className={classes.KnowHowQuestion}>
                                <ul>
                                    {this.renderQuestionListForModal(this.state.legalReferences)}
                                </ul>
                            </div>
                            <div className={classes.KnowHowFooter}>
                                <p>Learn more on <a href="https://academy.clara.co">Clara Academy</a></p>
                            </div>
                        </div>
                        <div style={knowHowContent}>
                            <h2 style={title1}>Know-How</h2>
                            <h3 style={title2}>{this.state.activeLegalReference.content.title}</h3>
                            <p style={description}>{this.state.activeLegalReference.content.description}</p>
                        </div>
                    </div>
                </Modal>
            </li>
        ));
    }

    setModalContent(legalReference) {
        this.setState(prevState => ({
            ...prevState,
            activeLegalReference: legalReference
        }));
    }

    renderQuestionListForModal(legalReferences) {
        return legalReferences.map((legalReference,i) => (
            <li className="listItem" key={i}>
                <p onClick={() => this.setModalContent(legalReference)}>{legalReference.title}</p>
            </li>
        ));
    }

    knowHowComponent(questionListRenderer, isFloat) {
        if (this.state.openButton) {
            return (

                <div className={` ${classes.KnowHowBox}`}>
                    <div className={classes.KnowHowHeader}>
                        <div className={classes.KnowHowSelect}>
                            <img src="/img/icons/academy_knowhow_select.svg" alt="Know-How" />
                            <label>Know-How</label>
                        </div>
                        <div>
                            <img src="/img/icons/to_do_knowhow.svg" alt="To-do" />
                            <label></label>
                        </div>

                    </div>
                    <div className={classes.KnowHowQuestion}>
                        <ul className="list">
                            {questionListRenderer}
                        </ul>
                    </div>
                    <div className={classes.KnowHowFooter}>
                        <p>Learn more on <a href="http://www.google.com">Clara Academy</a></p>

                    </div>
                </div>
            );
        }
    }
    buttonComponent() {
        let img = "/img/icons/arrow_down_know.svg";
        if (!this.state.openButton) {
            img = "/img/icons/clara.svg";
        }
        return (
            <button className={classes.KnowHowButton} onClick={this.handleClickButton}>
                <img className={img==="/img/icons/clara.svg"? classes.KnowHowImage: ''} src={`${img}`} alt="know" />
            </button>
        )
    }
    render() {
        const know = this.knowHowComponent(this.renderQuestionList(this.state.legalReferences));
        const button = this.buttonComponent();
        return (
            <div className={classes.KnowHow}>
                {button}
                {know}
            </div>
        )
    }
}
export default KnowHow;

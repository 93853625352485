import { gql } from '@apollo/client';
import useMutation from "../../../../../hooks/custom/useMutation";
import useSession from "../../../../session/hooks/useSession";
import { OWNER_WARRANT, WARRANT } from "../fragments";
import { COMPANY_CORPORATE_MAP } from './../../../../../modules/map/hooks/services/useCompanyCorporateMap';
export const UPSERT_WARRANT = `
${WARRANT}
${OWNER_WARRANT}
 mutation upsertWarrant(
    $startupId: String
    $owner: OwnerInput
    $warrantData: WarrantInput
  ) {
    upsertWarrant(
      startupId: $startupId
      owner: $owner
      warrantData: $warrantData
    ) {
    ...WarrantAdd
    owner{
    ...OwnerWarrant
    }
    }
    }`
const useUpsertWarrant=(config,props)=>{
  const { startupId } = useSession();
    const [upsertWarrant] = useMutation(UPSERT_WARRANT, config,{ refetchQueries:[{query:gql`${COMPANY_CORPORATE_MAP}`, variables:{startupId:startupId}}], ...props })
    return {upsertWarrant}
}
export default useUpsertWarrant
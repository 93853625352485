import { FieldArray } from 'formik';
import _ from "lodash";
import React, { useMemo, useState } from 'react';
import classes from "../../../v1/containers/Forms/SteperForm.module.scss";
import RowAdd from '../../forms/contents/RowAdd/RowAdd';
import SelectAddInput from "../Select/SelectAddInput/SelectAddInput";
import Classes from './AuthorizedSignatories.module.scss';

import  AddNewAuthorisedSignatory from './components/AddNewAuthorisedSignatory';
import  AuthorisedSignatory from './components/AuthorisedSignatory';
const AuthorisedSignatories = (
	{
		list,
		fieldArrayName,
		idGroupCompany,
		handleAddListStakeholder,
		onBlur,
		modal = false ,
		selectInputLabel = 'Corporate signatory',
		showEmailInput = true,
		errorMessage,
        showTitle,
        showRegisteredAddress,
        limit,
        tooltips
	}) => {
    const [showAddStakeholder, setAddStakeholder] = useState(false);
    const [showSelect, setShowSelect] = useState(false);


    const listAuthorizedSignatories = useMemo(() => _.map(list, as => ({ value: as, label: as.fullName, type: 'stakeholder' })), [JSON.stringify(list)])

    const mapAuthorisedDirectors = (list) => {
        return _.map(_.filter(list, signatory => _.some(signatory.value.roles, role => role.name === 'DIRECTOR')), signatory => ({ email: signatory.value.email, id: signatory.value.id, fullName: signatory.value.fullName }))
    };

    return (
        <FieldArray
            name={fieldArrayName}
            render={(arrayHelpers) => {
                const addList = !limit && _.isEmpty(_.get(arrayHelpers.form.values, fieldArrayName)) && !_.isEmpty(listAuthorizedSignatories)  ? [
                    {
                        label: "Add all directors",
                        onClick: (value) => {
                            setShowSelect(false)

                            const signatoryList = mapAuthorisedDirectors(listAuthorizedSignatories);
                            arrayHelpers.form.setFieldValue(arrayHelpers.name, signatoryList)
                        },
                        type: 'stakeholder',
                    },
                ] : []

                return (<>
                    {_.get(arrayHelpers.form.values, `${fieldArrayName}`,[]).map((signatory, index) => {
                        const reachLimit = _.get(arrayHelpers.form.values, `${fieldArrayName}`).length === limit
                        const isLast = _.get(arrayHelpers.form.values, `${fieldArrayName}`).length === index + 1;
                        const handleAddElement = () => setShowSelect(true)
                        const handleDeleteElement = () => arrayHelpers.remove(index);
                        return (
                            <div className={modal ? Classes.ContainerRow : classes.ContainerRow}>
                                <AuthorisedSignatory
                                    handleDeleteElement={handleDeleteElement}
                                    handleAddElement={handleAddElement}
                                    index={index}
                                    fieldArrayName={fieldArrayName}
                                    showAddAnother={limit?!(isLast && reachLimit):isLast}
                                    showSelect={showSelect}
                                    tooltips={tooltips}
									showEmailInput={showEmailInput}
                                    modal={modal}
                                    showTitle={showTitle}
                                    showRegisteredAddress={showRegisteredAddress}
                                />
                                {!isLast && <hr className={classes.Separator} />}
                            </div>
                        )
                    })}

                        {!showAddStakeholder && (showSelect || _.isEmpty(_.get(arrayHelpers.form.values, `${fieldArrayName}`))) ?
                            (<div className={Classes.containerForm}>
                                <RowAdd
                                    label={`${selectInputLabel} `}
                                    asterisk={true}
                                    classNameInput={modal ? Classes.inputModal : Classes.input}
                                    input={
                                        <SelectAddInput
                                            value={null}
                                            list={listAuthorizedSignatories}
                                            mode={'add'}
                                            handleFilterList={(list) => {
                                                return _.filter(list, st => !_.some(_.get(arrayHelpers.form.values, `${fieldArrayName}`), sign => sign.id === st.value.id))
                                            }}
                                            onBlur = {onBlur}
                                            placeholder={"Select from the list"}
                                            onChange={(value) => {
                                                if (value) {
                                                    const signatory = {
                                                    id: value.id,
                                                    email: value.email,
                                                    fullName: value.fullName,
                                                }
                                                if(showTitle) _.set(signatory,'title',value.title)
                                                if(showRegisteredAddress) _.set(signatory,'address',{...value.address,country:value.address?.country?.code || value.address?.country})
                                                    arrayHelpers.push(signatory)
                                                    setShowSelect(false)
                                                }
                                            }}
                                            menuProps={{
                                                addList: addList,
                                                propsList: [{ type: 'stakeholder' }]
                                            }}
                                            errorMessage={typeof errorMessage === 'string' ?errorMessage:''}
                                        />
                                    }
                                />

                            </div>  ) : null}
                    <div>
                        {
                            showAddStakeholder ? (
                                <AddNewAuthorisedSignatory
                                showTitle={showTitle}
                                    showRegisteredAddress={showRegisteredAddress}
                                    handleUpdate={(value) => {
                                        arrayHelpers.push({ id: value.id, email: value.email, fullName: value.fullName,title:value.title,address:value.address });
                                        if (handleAddListStakeholder)
                                            handleAddListStakeholder(value);
                                    }}
                                    tooltips={tooltips}
                                    closeForm={() => setAddStakeholder(false)}
                                    idGroupCompany={idGroupCompany}
                                />) : null
                        }
                    </div>
                </>)

            }}
        />)
};
export default AuthorisedSignatories
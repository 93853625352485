import { createMachine } from 'xstate';

const machine =
  createMachine({
    id: 'BusinessPlan',
    initial: 'BusinessPlanStep',
    states: {
      BusinessPlanStep: {
        on: {
          NEXT: {
            target: 'EvaluateCond',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      EvaluateCond: {
        always: [
          {
            cond: 'isNotPreApproved',
            target: 'LetsPreApproveFormStep'
          },
          {
            cond: 'isPreApproved',
            target: 'AlreadyApprovedUploadDocumentsFormStep'
          },
        ]
      },
      LetsPreApproveFormStep: {
        on: {
          PREV: {
            target: 'BusinessPlanStep',
            actions: ['setContextAfterStep']
          },
          NEXT: {
            target: 'EvaluateWhenToUpload',
            actions: ['setContextAfterStep']
          },
          CANCEL:{
            target: 'cancel'
          }
        }
      },
      EvaluateWhenToUpload: {
        always: [
          {
            cond: 'isUploadNow',
            target: 'PreApprovedUploadDocumentsFormStep'
          },
          {
            cond: 'isUploadLater',
            target: 'SaveStep'
          },
        ]
      },
      PreApprovedUploadDocumentsFormStep: {
        on: {
          PREV: {
            target: 'LetsPreApproveFormStep',
            actions: ['setContextAfterStep']
          },
          NEXT: {
            target: 'SaveStep',
            actions: ['setContextAfterStep']
          },
          CANCEL:{
            target: 'cancel'
          }
        }
      },
      AlreadyApprovedUploadDocumentsFormStep: {
        on: {
          PREV: {
            target: 'BusinessPlanStep',
            actions: ['setContextAfterStep']
          },
          NEXT: {
            target: 'SaveStep',
            actions: ['setContextAfterStep']
          },
          CANCEL:{
            target: 'cancel'
          }
        }
      },
      SaveStep: {
        invoke: {
          src: 'SaveStep',
          id: 'SaveStep',
          onDone: [
            {
              target: 'finish',
            }
          ],
          onError: [
            {
              target: 'finish'
            }
          ]
        }
      },
      cancel: {
        entry: 'cancelMachine',
        type: 'final'
      },
      finish: {
        entry: 'stopMachine',
        type: 'final'
      }
    }
  });
 
export default machine;
import React, { FC } from "react";
import AssistanceFrame2 from "./";
import ViewerDetailsTaskComponent from "./ViewerDetailsTaskComponent";

/**
 * Assistance Frame for Subscriptions
 */

export interface AssistanceViewerDetailsProps {

}

const AssistanceViewerDetails: FC<AssistanceViewerDetailsProps> = ({
  ...props
}: AssistanceViewerDetailsProps) => {

  return (
    <AssistanceFrame2 
      tasksComponent={ViewerDetailsTaskComponent} 
      showTask={true} 
      tasks={undefined} 
      companiesCorporateData={undefined} 
      allTaskDictionary={undefined} 
      entity={undefined} 
      resetCounter={undefined} 
      showGJ={undefined} 
      countCompleted={undefined} 
    />
      
    
  );
};

export default AssistanceViewerDetails;

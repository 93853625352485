import useMutation from "src/hooks/custom/useMutation";
import useSession from "src/modules/session/hooks/useSession";
import { gql } from '@apollo/client';
    export const ADD_NEW_VESTING_TERMS = `
    mutation createVestingTerm ($startupId: ID!, $groupCompanyId: ID!, $vestingTermData: VestingTermInput!) {
        createVestingTerm (startupId: $startupId, groupCompanyId: $groupCompanyId,  vestingTermData: $vestingTermData) {
            id
            name
            description
            vestingType
            allocationType
            standardTerm{
              vestingPeriod
              vestingFrequency
              isThereCliff
              cliffPeriod
            }
        }
    }`;
const useAddNewVestingTerms=(config,props,groupCompanyId)=>{
    const {startupId} = useSession()
      const [mutation,loading] = useMutation(ADD_NEW_VESTING_TERMS, config,{...props, fetchPolicy:'no-cache' })
      return {mutation,loading}
  }
  export default useAddNewVestingTerms

import React, {useState} from 'react';
import {Modal} from '../../../../../../../components';
import classes from "../ModalPaidEngagement.module.scss";
import Text from '../../../../../../../../components/text/Text/Text';
import PrimaryButton from "../../../../../Generic/Buttons/PrimaryButton/PrimaryButton"
import SecondaryButton from "../../../../../Generic/Buttons/SecondaryButton/SecondaryButton"
import _ from 'lodash'
import Line from '../../../../../../../../components/generic/Line/Line';
import ModalBankTransfer from './ModalBankTransfer';
import {useDispatch} from 'react-redux';
import {Constants} from '../../../../../../../utils';

const ModalPaidEngagement = ({ matterId, data, file, show, title, handleClose, handlePaid, setLoading }) => {
	const [showModalBankTransfer, setShowModalBankTransfer] = useState(false);

    const handleCancel = () => {
        handleClose();
    };
    const handleSubmit = (values) => {
        window.open(_.get(data, 'KYC.engagement.paymentLink'), "_blank");
        handlePaid();
        handleClose();

    };

	const dispatch = useDispatch();

	const goTo = () => {
		dispatch({ type: 'GO_TO', page: Constants.PAGES.MODULES.CHECKOUT.formationPayment, params: { productCode: "FORMATION_ENGAGEMENT", matterId } })
	};

	return (
        <React.Fragment>
            <Modal open={show} showHeader={false}>
                <div className={classes.ModalDecline}>
                    <h1 className={classes.titlePaymentOptions}><Text uuid={"TITLE_MODAL_PAID_ENGAGEMENT"} /></h1>
					<Text uuid={"SUBTITLE_MODAL_PAID_ENGAGEMENT"} />

					<div className={classes.containerPaymentMethod}>
						<div className={classes.containerOnline}>
							<Text uuid={"TEXT_IN_MODAL_PAID_ENGAGEMENT_ONLINE_TITLE"} />
							<Text uuid={"TEXT_IN_MODAL_PAID_ENGAGEMENT_ONLINE_OVERVIEW"} />
						</div>
						<PrimaryButton type="button" onClick={goTo}><Text uuid={"BUTTON_NOW"} /></PrimaryButton>
					</div>
					<Line />
					<div className={classes.containerPaymentMethod}>
						<div className={classes.containerBankTransfer}>
							<Text uuid={"TEXT_IN_MODAL_PAID_ENGAGEMENT_TRANSFER"} />
						</div>
						<SecondaryButton type="button" onClick={() => setShowModalBankTransfer(true)}><Text uuid={"BUTTON_BANK_TRANSFER"} /></SecondaryButton>
					</div>


                    <div className={classes.botonera}>
                        <SecondaryButton type="button" onClick={handleCancel}><Text uuid={"BUTTON_CANCEL"} /></SecondaryButton>
                    </div>

                </div>
            </Modal>

			<ModalBankTransfer
				show={showModalBankTransfer}
				setLoading={setLoading}
				handleClose={() => setShowModalBankTransfer(false)}
				matterId={matterId}
				file={file}
				data={data}
			/>
        </React.Fragment>
    )
};

export default ModalPaidEngagement;

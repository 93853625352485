import React, { FC } from "react";
import classesModule from './ProductItem.module.scss';
import Icon from '../../../generic/components/Icon';
import TypographyLocal from "../../../generic/components/Texts/Typography";
import TagsProduct from '../TagsUnLocked'


export interface orderProduct {
  icon: string,
  text: string,
  color: string
}

export interface ProductItemProps {
  /** Property icon */
  iconTable?: string,
  /** Property title */
  title?: string,
  /** Property subTitle */
  subTitle?: string,
  /** Property key */
  key?: number
  /** Order Product*/
  orderProduct?: [orderProduct]
}

const ProductItem: FC<ProductItemProps> = ({
  iconTable = "Academy",
  title,
  subTitle,
  orderProduct,
  key,
}: ProductItemProps) => {

  return (
    <div className={` ${classesModule.containerProduct} `} key={key}>
      <div className={classesModule.containerIcon}>
        <Icon isClara icon={iconTable} size={'1.8462rem'} className={classesModule.iconColor} />
      </div>
      <div className={classesModule.containerProductDescription}>
        <TypographyLocal
          className={`${classesModule.textItemTitle}`}
        >
          {title}
        </TypographyLocal>
        <TypographyLocal
          className={`${classesModule.textItemSubTitle}`}
        >
          {subTitle}
        </TypographyLocal>
      </div>
      {orderProduct.map((codePlan, index) => {
        return (
          <div className={classesModule.centerTag}>
            <TagsProduct icon={codePlan.icon} renderItem={codePlan.text} key={index} color={codePlan.color} />
          </div>
        )
      })}
    </div>
  );
}

export default ProductItem;
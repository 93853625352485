import React, { forwardRef, useImperativeHandle } from 'react';

import { useFieldArray, useFormContext } from 'react-hook-form';

const GroupControllerInput = React.memo(forwardRef(({ name, as, onChange, components = [], ...props }, ref) => {
    const { control, watch, getValue: getValueForm, setValue: setValueForm } = useFormContext();
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: name
    });
    useImperativeHandle(ref, () => ({

        setValue(value) {
            setValueForm(name, value)
            onChange && onChange(value);
        },
        getValue() {
            getValueForm(name)
        }
    }));
    return (
        <React.Fragment>
            {as({ name, components, ...props, fields, append, remove })}
        </React.Fragment >
    )
}))

export default GroupControllerInput;
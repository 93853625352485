import useLazyQuery from 'src/hooks/custom/useLazyQuery';

const GET_FORMATION_COMPANY = `
query getFormationCompany($startupId: ID!, $groupCompanyId: ID!) {
  getFormationCompany(startupId: $startupId, groupCompanyId: $groupCompanyId) {
    id
    name
    formationData {
      sharesData {
        id
        defaultShareCapital
        authorisedShares
        sharesAuthorised
        remainingShares
        shareNominalPrice
        articlesOfAssociationAmended {
          id
          name
        }
      }
    }
  }
}`;

export const useGetGroupCompanyShareCapital = (variables, config) => {
  const { manualQuery, loading, error, data, refetch } = useLazyQuery(GET_FORMATION_COMPANY, variables, config);
  return { loading, error, data, refetch, manualQuery };
};

export default useGetGroupCompanyShareCapital;

import _ from "lodash";
import React, { useMemo, useState } from 'react';
import RowAdd from '../../forms/contents/RowAdd/RowAdd';
import SelectAddInput from "../Select/SelectAddInput/SelectAddInput";
import Classes from './style.module.scss';
import useGetBankAccounts from './graphql/useGetBankAccounts'

const BankAccountsSelect = (
	{
		onBlur,
		modal = false ,
		selectInputLabel = 'Bank account',
        onChange,
        value,
        groupCompanyId,
		errorMessage
	}) => {
    const {data,loading} = useGetBankAccounts(groupCompanyId)
    const [showBankAccount, setBankAccount] = useState(true);
    const listBankAccount = useMemo(() => _.map(data, as => ({ value: as, label: `${as.bankName} - ${as.number.substring(as.number.length - 4)}` ,type:'bankAccount'})), [JSON.stringify(data)])
    const addList = [
        {
            label: "Add new bank account",
            onClick: (value) => {
                setBankAccount(false)
                onChange && onChange('ADD') 
            },
        },
    ];
    if(loading){
        return null
    }

                return (<>

                        {showBankAccount &&
                            (<div className={Classes.containerForm}>
                                <RowAdd
                                    label={`${selectInputLabel} `}
                                    asterisk={true}
                                    classNameInput={modal ? Classes.inputModal : Classes.input}
                                    input={
                                        <SelectAddInput
                                            list={listBankAccount}
                                            mode={'add'}
                                            onBlur = {onBlur}
                                            value={{id:value?.id}}
                                            compare={'id'}
                                            handleFilterList={(value) => {return value}}
                                            placeholder={"Select from the list"}
                                            onChange={(value) => {
                                                onChange && onChange(value) 
                                            }}
                                            menuProps={{
                                                addList: addList,
                                                propsList: [{ type: 'bankAccount' }]
                                            }}
                                            errorMessage={typeof errorMessage === 'string' ?errorMessage:''}
                                        />
                                    }
                                    />
                            </div>)}
                        
                </>)

};
export default BankAccountsSelect

import React, { useEffect, useState } from 'react';
import classes from './PortalDocuments.module.scss';
import PortalDocumentsFactoryContent from './PortalDocumentsFactoryContent';
import img from '../../../../../../images/left_education_background_circle.svg';
import claraIconLogin from '../../../../../../images/claraIconLogin.svg';
import helper from '../../../../../utils/helper';
import notification from "../../../../../utils/notification";
import Mustache from 'mustache';
import ServerConnect from "../../../../../utils/ServerConnect";
import { Constants } from '../../../../../utils/constants';
import {
    getListToIncorporationPackSignatures,
    getListToRequestSignatures,
    query3
} from '../../../../../graphql/mutations/matters';
import Loading from '../../../../Loading/Loading';


const PortalDocumentsView = (props) => {


    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)


    const getQuery = () => {
        switch (props.mode) {
            case Constants.PORTAL_DOCUMENTS_KEYS.CLIENT_REVIEW:
                return query3;
            case Constants.PORTAL_DOCUMENTS_KEYS.SIGNATORIES:
                return getListToRequestSignatures;
            case Constants.PORTAL_DOCUMENTS_KEYS.INCORPORATION_PACK:
                return getListToIncorporationPackSignatures
            default:
                return "";
        }
    }

    const getDataByType = (res) => {
        if (props.mode == Constants.PORTAL_DOCUMENTS_KEYS.CLIENT_REVIEW) {
            return { ...res.getMattersList[0], documents: res.getListToRequestSignaturesFlow }
        } else if (props.mode == Constants.PORTAL_DOCUMENTS_KEYS.SIGNATORIES) {
            return res.getListToRequestSignatures
        } else if (props.mode == Constants.PORTAL_DOCUMENTS_KEYS.INCORPORATION_PACK) {
            return res.getListToIncorporationPackSignatures
        }


    }

    const getData = () => {
        const query_params = {
            matterId: props.params.matterId
        }
        const queryExecute = Mustache.render(getQuery(), query_params);
        ServerConnect.graphQlQuery(queryExecute).then(result => {
            setData(getDataByType(result))
            setLoading(false)
        }).catch(err => {
            console.log(err);
            notification.sendNotification("ERROR", "error", 4990);
        });
    }

    const getLeftEducationText = () => {
        switch (props.mode) {
            case Constants.PORTAL_DOCUMENTS_KEYS.CLIENT_REVIEW:
                return (
                    <React.Fragment>
                        <h5>{helper.getTranslateTextByKey('CLIENT_REVIEW_UPPER_TITLE')}</h5>
                        <span>{helper.getTranslateTextByKey('APPROVAL_FINAL_DOCUMENTS')}</span>
                    </React.Fragment>
                )
            case Constants.PORTAL_DOCUMENTS_KEYS.SIGNATORIES:
                return (
                    <React.Fragment>
                        <h5>{helper.getTranslateTextByKey('SIGNATORIES_LEFT_SUBTITLE')}</h5>
                        <span>{helper.getTranslateTextByKey('SIGNATORIES_LEFT_TITLE')}</span>
                    </React.Fragment>
                )

            case Constants.PORTAL_DOCUMENTS_KEYS.INCORPORATION_PACK:
                return (
                    <React.Fragment>
                        <h5>{helper.getTranslateTextByKey('INCORPORATION_PACK_SIGNATORIES_LEFT_SUBTITLE')}</h5>
                        <span>{helper.getTranslateTextByKey('INCORPORATION_PACK_SIGNATORIES_LEFT_TITLE')}</span>
                    </React.Fragment>
                )
            default:
                return (
                    <React.Fragment>
                        <h5>{helper.getTranslateTextByKey('SIGNATORIES_LEFT_SUBTITLE')}</h5>
                        <span>{helper.getTranslateTextByKey('SIGNATORIES_LEFT_TITLE')}</span>
                    </React.Fragment>
                )
        }
    }

    useEffect(() => {
        if (!data) {
            setLoading(true)
            getData()
        }
    }, [data]);

    return (

        <Loading show={loading} showChildren={false}>
            <div className={classes.generalContainer}>
                <div className={classes.leftEducation}>
                    <img src={img}></img>
                    <header>
                        <img src={claraIconLogin} alt='Clara' />
                        <label>Clara</label>
                    </header>
                    <div className={classes.textContainer}>
                        <div className={classes.upperTitle}>
                            {getLeftEducationText()}
                        </div>
                    </div>
                </div>
                <div className={`${classes.contentContainer} scrollbar`}>
                    <PortalDocumentsFactoryContent data={data} mode={props.mode} id={props.params.matterId} />
                </div>
            </div>
        </Loading>
    )
}

export default PortalDocumentsView;
import { ARTICLES_OF_ASSOCIATION_TYPE } from "src/modules/startup/GroupCompany/Forms/FormBusinessActivityCayman";

const useGetMapEconomicSubstance = () => {
    const getEconomicSubstance = (context, document)=>{
        const { EditBusinessActivityForm } = context;
        return {
            businessActivities: {
                relevantActivities: EditBusinessActivityForm?.activities || context?.initialData?.activities,
                articlesOfAssociationType: EditBusinessActivityForm?.articlesOfAssociationType || context?.initialData?.articlesOfAssociationType,
                articlesOfAssociation: document?.id || EditBusinessActivityForm?.articlesOfAssociation?.id || context?.initialData?.articlesOfAssociation?.id
            }
        };
    }

    const getDocument = (context)=>{
        const { EditBusinessActivityForm } = context;
        const { file } = EditBusinessActivityForm;
        if(!file) return;
        if ((EditBusinessActivityForm.articlesOfAssociationType || context.initialData.articlesOfAssociationType) === ARTICLES_OF_ASSOCIATION_TYPE.CUSTOM && context.initialData.file?.id !== EditBusinessActivityForm?.file?.id)
        return {
            title: file.name,
            file: file,
            type: "ARTICLES_OF_ASSOCIATION",
            groupCompanies: [{ groupCompany: context?.initialData?.initialValues?.groupCompanyId }],
            status: "Uploaded",
            subCategories: ["constitutionalDocuments"],
        };
    }

    const economicSubstanceMapper = (context: any, document?: any)=>{
        const economicSubstanceInformation = getEconomicSubstance(context, document);
        return { ...economicSubstanceInformation };
    }

    const getInitialValues = (data)=>{
        const businessActivities = data?.businessActivities;
        return {
            activities: businessActivities?.relevantActivities,
            articlesOfAssociationType: businessActivities?.articlesOfAssociationType,
            file: businessActivities?.articlesOfAssociation?.file,
            isManagedByClara: !!data?.managedByClara
        };
    }

    return { economicSubstanceMapper, getInitialValues, getDocument };
}

export default useGetMapEconomicSubstance;
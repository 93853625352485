import * as Yup from 'yup';
import _ from 'lodash'
import {Constants} from "../../../../utils/constants"

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const IndividualSchema = {
    firstName: Yup.string().required("This field is required."),
    lastName: Yup.string().required("This field is required."),
    dateOfBirth: Yup.string().required("This field is required.").matches(Constants.REGEXP.DATE, { message: "This field is invalid", excludeEmptyString: true }).nullable(),
    email: Yup.string().lowercase().required("This field is required.").matches(Constants.REGEXP.EMAIL, { message: "This email is invalid", excludeEmptyString: true }).nullable(),
    occupation: Yup.string().required("This field is required."),
    address: Yup.object().shape({
        address: Yup.string().required("This field is required."),
        city: Yup.string().required("This field is required."),
        state: Yup.string().nullable(),
        country: Yup.string().required("This field is required.").nullable(),
        zipCode: Yup.string().nullable()
    }),

    passports: Yup.array().of(Yup.object().shape({
        nationality: Yup.string().required("This field is required.").nullable(),
        passportNumber: Yup.string().required("This field is required.").nullable(),
        passportExpiryDate: Yup.string().required("This field is required.").matches(Constants.REGEXP.DATE, { message: "This field is invalid", excludeEmptyString: true }).nullable(),
        passportDoc: Yup.object().required("This field is required.").nullable(),
        UAEPassportStamp: Yup.boolean().required("This field is required."),
        UAEPassportStampDoc: Yup.object().when("UAEPassportStamp", {
            is: true,
            then: Yup.object().required("This field is required."),
            otherwise: Yup.object().notRequired()
        }).nullable(),
        PRCIDDoc: Yup.object()
            .when("nationality", {
                is: (nationality) => "CN" === nationality,
                then: Yup.object().required("This field is required."),
                otherwise: Yup.object().notRequired()
            }).nullable(),
        curriculumVitaeDoc:Yup.object().nullable().optional(),
        lastPagePassportDoc: Yup.object()
            .when("nationality", {
                is: (nationality) => "IN" === nationality,
                then: Yup.object().required("This field is required."),
                otherwise: Yup.object().notRequired()
            }).nullable(),
        UIDDoc: Yup.object()
            .when("nationality", {
                is: (nationality) => _.includes(["AE", "SA", "QA", "KW", "BH", "OM"], nationality),
                then: Yup.object().required("This field is required."),
                otherwise: Yup.object().notRequired()
            }).nullable()
    })).min(1),
    otherPassports: Yup.boolean().required("This field is required.").nullable(),
    proofOfAddress: Yup.boolean().required("This field is required.").nullable(),
    proofOfAddressDoc: Yup.object()
        .when("proofOfAddress", {
            is: true,
            then: Yup.object().required("This field is required."),
            otherwise: Yup.object().notRequired()
        }).nullable(),
    thirdPartyAddressFormDoc: Yup.object()
        .when("proofOfAddress", {
            is: false,
            then: Yup.object().required("This field is required."),
            otherwise: Yup.object().notRequired()
        }).nullable(),
    contactNumber: Yup.object().shape({
        prefix: Yup.string().required("This field is required.").nullable(),
        number: Yup.string().required("This field is required.").nullable().matches(phoneRegExp, 'Phone number is not valid'),
        extension: Yup.string().notRequired().nullable(),
    }),
    passportFromAnotherCountryDoc: Yup.object()
        .when("holdOrHaveHeldPassportFromAnotherCountry", {
            is: true,
            then: Yup.object().required("This field is required."),
            otherwise: Yup.object().notRequired()
        }).nullable(),
    politicallyExposedPerson: Yup.boolean().required("This field is required."),
    politicallyExposedPersonDescription: Yup.string()
        .when("politicallyExposedPerson", {
            is: true,
            then: Yup.string().required("This field is required."),
            otherwise: Yup.string().notRequired()
        }).nullable(),
    familyMemberOfAPEP: Yup.boolean().required("This field is required."),
    familyMemberOfAPEPDescription: Yup.string()
        .when("familyMemberOfAPEP", {
            is: true,
            then: Yup.string().required("This field is required."),
            otherwise: Yup.string().notRequired()
        }).nullable(),
    closeAssociateOfAPEP: Yup.boolean().required("This field is required."),
    closeAssociateOfAPEPDescription: Yup.string()
        .when("closeAssociateOfAPEP", {
            is: true,
            then: Yup.string().required("This field is required."),
            otherwise: Yup.string().notRequired()
        }).nullable(),
    sourcesOfWealth: Yup.string().required("This field is required.").nullable(),
    otherSourcesOfWealth: Yup.string().when("sourcesOfWealth", {
        is: value => _.eq(value, "Others"),
        then: Yup.string().required("This field is required."),
        otherwise: Yup.string().notRequired()
    }).nullable(),
    sourceOfFunds: Yup.string().nullable().required("This field is required."),
    isCompanyFlow: Yup.boolean()


};

const OnBehalfFormSchema = {

    firstName: Yup.string().required("This field is required.").nullable(),
    lastName: Yup.string().required("This field is required.").nullable(),
    contactNumber: Yup.object().shape({
        prefix: Yup.string().required("This field is required.").nullable(),
        number: Yup.string().required("This field is required.").matches(phoneRegExp, 'Phone number is not valid').nullable(),
        extension: Yup.string().notRequired().nullable()
    }),
    pointOfContact: Yup.boolean().required("This field is required.").nullable()

}

const shareholders = Yup.array().when('formKey', {
    is: (val) => (val.includes("DATA")) ,
    then: Yup.array().of(
        Yup.lazy(value => {
            if (_.has(value, 'companyName')) {
                return Yup.object().shape({
                    firstName: Yup.string().required("This field is required").nullable(),
                    lastName: Yup.string().required("This field is required").nullable(),
                    email: Yup.string().lowercase().required("This field is required").matches(Constants.REGEXP.EMAIL, { message: "This email is invalid", excludeEmptyString: true }).nullable(),
                    companyName: Yup.string().required("This field is required").nullable()
                })
            }
            return Yup.object().shape({
                firstName: Yup.string().required("This field is required").nullable(),
                email: Yup.string().lowercase().required("This field is required").matches(Constants.REGEXP.EMAIL, { message: "This email is invalid", excludeEmptyString: true }).nullable(),
                lastName: Yup.string().required("This field is required").nullable()
            })
        })
    ).min(1, "This field is required").required(),
    otherwise: Yup.array().of(
        Yup.lazy(value => {
            if (_.has(value, 'companyName')) {
                return Yup.object().shape({
                    firstName: Yup.string().required("This field is required").nullable(),
                    lastName: Yup.string().required("This field is required").nullable(),
                    email: Yup.string().lowercase().required("This field is required").matches(Constants.REGEXP.EMAIL, { message: "This email is invalid", excludeEmptyString: true }).nullable(),
                    companyName: Yup.string().required("This field is required").nullable()
                })
            }
            return Yup.object().shape({
                firstName: Yup.string().required("This field is required").nullable(),
                email: Yup.string().lowercase().required("This field is required").matches(Constants.REGEXP.EMAIL, { message: "This email is invalid", excludeEmptyString: true }).nullable(),
                lastName: Yup.string().required("This field is required").nullable()
            })
        })
    ).min(0, "This field is required")
})


const CompanyFormSchemaStep1 = {
    formKey: Yup.string(),
    companyName: Yup.string().required("This field is required.").nullable(),
    otherTradingName: Yup.string().nullable(),
    registrationNumber: Yup.string().required("This field is required.").nullable(),
    TRNNumber: Yup.string().nullable(),
    countryOfIncorporation: Yup.object().required("This field is required.").nullable(),
    jurisdictionOfIncorporation: Yup.string().required("This field is required.").nullable(),
    otherJurisdiction: Yup.string()
        .when('jurisdictionOfIncorporation', {
            is: (val) => (val === "OTHER"),
            then: Yup.string().required("This field is required."),
            otherwise: Yup.string().nullable(),
        }),
    principalAddress: Yup.object().shape({
        address: Yup.string().required("This field is required.").nullable(),
        city: Yup.string().required("This field is required.").nullable(),
        state: Yup.string().nullable(),
        country: Yup.string().required("This field is required.").nullable(),
        zipCode: Yup.string().nullable()
    }),
    address: Yup.object().when('samePrincipalPlaceOfBusinessAndLegal', {
        is: false,
        then: Yup.object().shape({
            address: Yup.string().nullable(),
            city: Yup.string().nullable(),
            state: Yup.string().nullable(),
            country: Yup.string().nullable(),
            zipCode: Yup.string().nullable()
        }),
        otherwise: Yup.object().notRequired()
    }),
    samePrincipalPlaceOfBusinessAndLegal: Yup.boolean().required("This field is required").nullable(),
    directors: Yup.array().when('formKey', {
        is: (val) => {
            if (val){
                return (val.includes("COMPANY_FORM") || val.includes("SHAREHOLDER") || val.includes("CORPORATE"));
            }
            return false;
        },  
        then: Yup.array().of(
            Yup.lazy(value => {
                if (_.has(value, 'companyName')) {
                    return Yup.object().shape({
                        firstName: Yup.string().required("This field is required").nullable(),
                        lastName: Yup.string().required("This field is required").nullable(),
                        email: Yup.string().lowercase().required("This field is required").matches(Constants.REGEXP.EMAIL, { message: "This email is invalid", excludeEmptyString: true }).nullable(),
                        companyName: Yup.string().required("This field is required").nullable()
                    })
                }
                return Yup.object().shape({
                    firstName: Yup.string().required("This field is required").nullable(),
                    email: Yup.string().lowercase().required("This field is required").matches(Constants.REGEXP.EMAIL, { message: "This email is invalid", excludeEmptyString: true }).nullable(),
                    lastName: Yup.string().required("This field is required").nullable()
                })
            })
        ).min(1, "This field is required").required(),
        otherwise: Yup.array().nullable()
    }),
    shareholders: Yup.array().when('formKey', {
        is: (val) => {
            if (val){
                return (val.includes("COMPANY_FORM"))
            }
            return false
            
        },
        then: shareholders,
        otherwise: Yup.array().nullable()
    }),
};



const CompanyFormSchemaStep2 = {
    formKey: Yup.string(),
    signatory: Yup.object().shape({
        firstName: Yup.string().required("This field is required.").nullable(),
        lastName: Yup.string().required("This field is required.").nullable(),
        jobTitle: Yup.string().required("This field is required.").nullable(),
        email: Yup.string().lowercase().required("This field is required.").matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true }).nullable(),
        nationality: Yup.string().required("This field is required.").nullable(),
        passportNumber: Yup.string().required("This field is required.").nullable(),
        passportExpiryDate: Yup.string().required("This field is required.").matches(Constants.REGEXP.DATE, { message: "This date is invalid", excludeEmptyString: true }).nullable(),
        signatoryPassportDoc: Yup.object().required("This field is required.").nullable()
    }),
    certificateOfIncorporationOrCommercialLicenseDoc: Yup.object().required("This field is required.").nullable(),
    memorandumAndArticlesOfAssociationDoc: Yup.object().required("This field is required.").nullable(),
    showingDirectorsRegistrationDoc: Yup.object().required("This field is required.").nullable(),
    showingShareholdersRegistrationDoc: Yup.object().required("This field is required.").nullable(),

    clientKeyContact: Yup.object().when("formKey", {
        is: value => _.eq(value, "COMPANY_FORM"),
        then: Yup.object().shape({
            fullName: Yup.string().nullable(),
            email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true }).nullable(),
            contactNumber: Yup.object().shape({
                prefix: Yup.string().nullable(),
                number: Yup.string().matches(phoneRegExp, 'Phone number is not valid').nullable(),
                extension: Yup.string().notRequired().nullable()
            })
        }),
        otherwise: Yup.object().nullable()
    }),
    financeContact: Yup.object().when("formKey", {
        is: value => _.eq(value, "COMPANY_FORM"),
        then: Yup.object().shape({
            fullName: Yup.string().nullable(),
            email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true }).nullable(),
            contactNumber: Yup.object().shape({
                prefix: Yup.string().nullable(),
                number: Yup.string().matches(phoneRegExp, 'Phone number is not valid').nullable(),
                extension: Yup.string().notRequired().nullable()
            })
        }),
        otherwise: Yup.object().nullable()
    }),
    sourceOfFunds: Yup.string().nullable().required("This field is required.")
}

const clientContac = Yup.object().shape({
    fullName: Yup.string().required("This field is required."),
    contactNumber: Yup.object({
        prefix: Yup.string().required("This field is required."),
        number: Yup.string().required("This field is required.").matches(phoneRegExp, 'Phone number is not valid'),
        extension: Yup.string().notRequired("This field is required.").matches(/^[0-9]*$/, "This is only number")
    }).nullable(),
    email: Yup.string().lowercase().required("This field is required.")
})


const validateSchema = (schema, values) => {
    schema.validate(values)

    return null;

}

export {
    clientContac,
    IndividualSchema,
    OnBehalfFormSchema,
    CompanyFormSchemaStep1,
    CompanyFormSchemaStep2,
    validateSchema
}

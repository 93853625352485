import { useMemo } from 'react';

const useGuards = () => {
  return useMemo(() => ({
    managedByClara: (context) => {
      const managedByClara = context?.initialData?.initialValues?.managedByClara;
      if (managedByClara) {
          return true;
      }
    },
    notManagedByClara: (context) => {
      const managedByClara = context?.initialData?.initialValues?.managedByClara;
      if (!managedByClara) {
          return true;
      }
    },
  }), []);
}

export default useGuards;

import useLazyQuery from "src/hooks/custom/useLazyQuery";
import useSession from "src/modules/session/hooks/useSession";
const GET_STAKEHOLDER = `
query getStakeholderADGMDetails($startupId: ID!, $stakeholderId: ID!) {
    getStakeholder(startupId: $startupId, stakeholderId: $stakeholderId) {
        id
        fullName
        arabicName
        title
        nationality{
            code
            name 
            alpha3
        }
        isAuthorizedSignatory
        dateOfBirth
        profession
        title
        previousName
        email
        phoneNumber
        nationalities {
            none
            countries {
                country {
                    code
                }
                hasPassport {
                    description
                    question
                }
            }
        }
        dateOfBirth
        birthAddress {
            country {
                code
            }
            city
        }
        managedByClaraConnections{
            id
            jurisdictionType
            statusOnMatters{
                isRenewal
            }
        }
        sourcesOfFunds{
            company
			jurisdictionType
			sourceOfFunds
			sourceOfWealth
        }
        pep {
            isPEP
            descriptionOfPEP
            isFamilyMemberOfAPEP
            familyMemberOfAPEPDescription
            isCloseAssociateOfAPEP
            closeAssociateOfAPEP
        }
        address{
            street
			city
			state
			country{
                code
            }
			zipCode
        }
        roles{
            id
            name
            entity{
                ...on StakeHolder{
                    id
                }
                ... on GroupCompany{
                id
                }
            }
        }
    }}
`;
const useGetStakeholderDetails = (v) =>{
const { manualQuery, loading, error, data, refetch } = useLazyQuery(GET_STAKEHOLDER, v);
    const { startupId } = useSession()
    const query = async (stakeholderId) => {
        const { data } = await manualQuery({variables:{startupId,stakeholderId}})
        return {
                stakeholder: data['getStakeholder'],
        }
    }

    return {
        query,
        loading,
        error,
        data,
        refetch
    }
}
export default useGetStakeholderDetails

import React, { useEffect, useState } from 'react';
import _ from "lodash"
import useTextLang from "../../../hooks/custom/useTextLang";
import ErrorInput from "../../../modules/generic/components/Inputs/ErrorInput";
import TextInput from "./../Text/TextInput";
import classes from './CardInput.module.scss';
import cvcIcon from "./images/cvc.svg";
import mastercardIcon from "./images/mastercard.svg";
import visaIcon from "./images/visa.svg";
import AmexIcon from "./images/american_express.svg";
import CardInputStripe from './CardInputStripe';
import { Constants } from "src/v1/utils";

const CardInput = ({ 
  type = "text", 
  className, 
  value, 
  error, 
  errors, 
  errorMessage, 
  name, 
  onChange, 
  classNameError, 
  isSubmitting, 
  disabled, 
  defaultValue, 
  ...props 
}) => {
  const isStripe = true;
  const errorCardInvalid = useTextLang("RANGE_CARD_INVALID");
  const [stateValue, setStateValue] = useState(value);
  const [showIconVisa, setIconVisa] = useState(true);
  const [showIconMastercard, setIconMastercard] = useState(true);
  const [showIconAmex, setIconAmex] = useState(true);

  const handleChange = (val, field) => {
    let auxVal = stateValue;
    if (!auxVal) {
      auxVal = {};
    }
    auxVal[field] = val;
    setStateValue(auxVal);
    onChange && onChange(auxVal);
  };

  useEffect(() => {
    if (stateValue !== value) {
      setStateValue(value)
    }
  }, [value]);

  const handleChangeCreditCard = (creditCard) => {
    const iniciales = parseInt(creditCard.substring(0, 4));
    if ((iniciales >= Constants.CARD_RANGES.MASTERCARD.initial_1 && iniciales <= Constants.CARD_RANGES.MASTERCARD.final_1) || (iniciales >= Constants.CARD_RANGES.MASTERCARD.initial_2 && iniciales <= Constants.CARD_RANGES.MASTERCARD.final_2)) {
      setIconMastercard(true);
      setIconVisa(false);
      setIconAmex(false);
      return
    }
    if (iniciales >= Constants.CARD_RANGES.VISA.initial_1 && iniciales <= Constants.CARD_RANGES.VISA.final_1) {
      setIconVisa(true);
      setIconMastercard(false)
      setIconAmex(false);
      return
    }
    if (iniciales >= Constants.CARD_RANGES.AMEX.initial_1 && iniciales <= Constants.CARD_RANGES.AMEX.final_1) {
      setIconAmex(true);
      setIconMastercard(false)
      setIconVisa(true);
      return
    }
    setIconMastercard(true)
    setIconVisa(true);
    setIconAmex(true);
  };

  if (isStripe) {
    return <CardInputStripe errorMessage={errorMessage} classNameError={classNameError} />
  }

  return (
    <div className={`${classes.CardInput} ${className}`} >
      <div>
        <TextInput
          value={_.get(stateValue, "number", "")}
          name={'number'}
          onChange={(value) => { handleChangeCreditCard(value); handleChange(value, "number") }}
          placeholder={"INPUT_CARD_NUMBER_PLACEHOLDER"}
          guide={false}
          mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
          errorMessage={_.get(errors, "number.message")}
          disabled={isSubmitting || disabled}
          className={classes.InputCard}
          afterComponent={
            <React.Fragment>
              <div className={classes.IconsCards}>
                {showIconVisa && <img className={classes.afterComponent} src={visaIcon} alt=""/>}
                {showIconMastercard && <img className={classes.afterComponent} src={mastercardIcon} alt=""/>}
                {showIconAmex && <img className={classes.afterComponent} src={AmexIcon} alt=""/>}
              </div>
            </React.Fragment>
          }
        />
      </div>
      <div>
        <TextInput
          name={'date'}
          onChange={(value) => { handleChange(value, "date") }}
          placeholder={"INPUT_CARD_EXPIRED_PLACEHOLDER"}
          disabled={isSubmitting || disabled}
          guide={false}
          errorMessage={_.get(errors, "date.message")}
          value={_.get(stateValue, "date", "")}
          mask={[/\d/, /\d/, "/", /\d/, /\d/]}
        />
      </div>
      <div>
        <TextInput
          name={'cvc'}
          type={'password'}
          onChange={(value) => { handleChange(value, "cvc") }}
          disabled={isSubmitting || disabled}
          placeholder={"INPUT_CARD_CVC_PLACEHOLDER"}
          guide={false}
          errorMessage={_.get(errors, "cvc.message")}
          value={_.get(stateValue, "cvc", "")}
          mask={[/\d/, /\d/, /\d/]}
          afterComponent={<img className={classes.afterComponentCvv} src={cvcIcon} alt=""/>}
        />
      </div>
      {errorMessage && <ErrorInput message={errorMessage} classNameError={`${classes.classNameError} ${classNameError}`} />}
    </div>
  )
};

export default CardInput

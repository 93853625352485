import { Field, Formik } from 'formik';
import _ from 'lodash';
import React, { useRef, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import {
    ClaraSelect, TextAreaInput
} from '../../../../components';
import FormikHelper from '../../../../utils/formikHelper';
import helper from '../../../../utils/helper';
import classesForm from '../Form.module.scss';
import { IndividualSchema } from '../validations/validationForms';
import AutoSaveComponent from "./../../../../../components/forms/Form/AutoSaveComponent";
import { ContactDetails, Curriculum, PersonalInformation, PoliticallyExposedPerson, ResidentialAddress } from './forms/index';
//in Formik 2. is initial values changed by validateOnMount
const FormIndividual = ({ initialValues, keyAutoSave, onSubmit, isCompanyFlow }) => {
    const schema = Yup.object().shape(IndividualSchema);
    const handleOptionsButtonClick = (field, form, value) => {
        FormikHelper.setValueInTheCorrectPosition(field.name, form, value);
    }

    if (window.location.href.indexOf("addKYCToAMatter") > -1) {
        const tagManagerArgs = {
            dataLayer: {
                event: "PageView",
                path: "individualKYC/individualForm",
                pageName: "Individual KYC - Individual Form"
            },
            dataLayerName: "PageDataLayer",
        };
        TagManager.dataLayer(tagManagerArgs);
    }

    const mapPassportInitialValues = React.useCallback(
        (initialValues) => {
            let passports = [];
            if (initialValues && initialValues.passports) {
                passports = _.get(initialValues, 'passports', []).map(passport => {
                    const { _id = null, ...data } = passport;
                    return { ...data, id: _id };
                })
            }
            return { ...initialValues, passports };
        }, [])

    const [defaultValues, setDefaultValues] = useState(() => {
        return mapPassportInitialValues(initialValues)
    });
    const params = useParams();
    let childAutoSave = useRef();
    const keysAutoSave = {
        key: _.get(defaultValues, "formKey", "FORM_INDIVIDUAL"),
        key2: keyAutoSave,
        id: _.get(defaultValues, "id"),
        params: JSON.stringify(params),
    }
    const handleUpdateInitialValues = (values) => {
        const initialValues = { ...values }
        if (values && values.passports && values.passports.length > 1) {
            initialValues.otherPassports = true;
        } else {
            initialValues.otherPassports = false;
        }
        setDefaultValues(initialValues)
    }
    const handleSubmitAuto = async (values, validateForm) => {
        const errorForm = await validateForm();
        if (errorForm && Object.keys(errorForm).length > 0) {
            const errorRef = document.querySelector('[hasError="true"]');
            if (errorRef) {
                if (errorRef.tagName === "A") {
                    document.getElementById('layoutForm').scrollTo(0, document.querySelector('[hasError="true"]').offsetTop)
                } else if (errorRef.tagName === "SPAN") {
                    document.querySelector('[hasError="true"]').querySelector("input").focus()
                } else if (errorRef.type === "checkbox") {
                    document.getElementById('layoutForm').scrollTo(0, document.querySelector('[hasError="true"]').parentElement.parentElement.parentElement.offsetTop - 70)
                } else if (errorRef.type === "file") {
                    document.getElementById('layoutForm').scrollTo(0, document.querySelector('[hasError="true"]').parentElement.parentElement.parentElement.parentElement.parentElement.offsetTop - 70)
                } else {
                    errorRef.focus()
                }

            }
            return false;
        }
        childAutoSave.clear();
        onSubmit(values)
    }


    return (

        <React.Fragment>
            {(_.has(initialValues, "formKey")) &&
                <Formik
                    initialValues={helper.getInitialValues(defaultValues)}
                    // initialValues={initialValues}
                    enableReinitialize={true}
                    onSubmit={handleSubmitAuto}
                    validationSchema={schema}
                    isInitialValid={false}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    {({
                        values,
                        errors,
                        dirty,
                        handleSubmit,
                        validateForm,
                        isSubmitting,
                        handleReset,
                        setFieldValue,
                        isValid
                    }) => {
                        return (
                            <React.Fragment>
                                <AutoSaveComponent ref={node => childAutoSave = node} keys={keysAutoSave} step={"1"} values={values} updateValues={handleUpdateInitialValues} />
                                <div className={classesForm.containerData} >
                                    <PersonalInformation values={values} setFieldValue={setFieldValue} />
                                    <hr />
                                    <ResidentialAddress values={values} />
                                    <hr />
                                    <ContactDetails values={values} setFieldValue={setFieldValue} />
                                    <hr />
                                    <Curriculum />
                                    <hr />
                                    <PoliticallyExposedPerson values={values} handleOptionsButtonClick={handleOptionsButtonClick} />
                                    <hr />
                                    <h1>Source of Funds</h1>


                                    <Field name='sourceOfFunds'>
                                        {({ field, form }) => (
                                            <TextAreaInput
                                                className={classesForm.textArea}
                                                label='Source of Funds: '
                                                data-zuko-name='Source of Funds'
                                                field={field}
                                                form={form}
                                                autoComplete='off'
                                                placeholder={"Source of Funds..."}
                                                tooltip={'Where the individual receives funds from to pay for any costs of the company incorporation e.g. a UAE bank account or a UK personal credit card in the individual’s name.'}
                                            />
                                        )}
                                    </Field>

                                    <Field name='sourcesOfWealth'>
                                        {({ field, form }) => (
                                            <ClaraSelect
                                                field={field}
                                                form={form}
                                                label={"Source of Wealth"}
                                                data-zuko-name="Source of Wealth"
                                                placeholder={"Source of Wealth..."}
                                                mode={"classic"}
                                                lists={["Wages", "Business income", "Investments", "Asset sale", "Inheritance", "Others"]}
                                                // callbacks={{ updateValues: handleSourceOfWealth }}
                                                className={classesForm.claraSelect}
                                                tooltip={"How the individual accrued their wealth to pay for any costs of the company incorporation e.g. wages, business income, investments, sale of an asset, inheritance or other forms of wealth."}
                                            />
                                        )}
                                    </Field>

                                    {_.get(values, "sourcesOfWealth") === "Others" &&

                                        <Field name='otherSourcesOfWealth'>
                                            {({ field, form }) => (
                                                <TextAreaInput
                                                    className={classesForm.textArea}
                                                    label='Describe the Source of Wealth.'
                                                    field={field}
                                                    form={form}
                                                    autoComplete='off'
                                                    placeholder={"Source of Wealth..."}
                                                // tooltip={tooltips['Issue Note']}
                                                />
                                            )}
                                        </Field>}



                                </div>
                                <hr />
                                <div className={`botoneraButtons`}>
                                    <button className={`genericBlue`} onClick={() => handleSubmitAuto(values, validateForm)} > Save </button>
                                </div>



                            </React.Fragment>
                        )

                    }

                    }
                </Formik>

            }

        </React.Fragment>

    )
}


export default FormIndividual;
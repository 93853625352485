import { AddPassport, ConfigurePassport, IsTherePassport  } from "../Steps";
import TemplateWizardSkeletonMachine from "src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine";

const useSteps = () => {
  return {
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    Save: {
      render: TemplateWizardSkeletonMachine,
      renderProps: {
        title: "",
        subTitle: ""
      }
    },
    UploadPassport: {
      render: AddPassport,
    },
    ConfigurePassport: {
      render: ConfigurePassport,
    },
    IsTherePassport: {
      render: IsTherePassport,
    },
  };
}

export default useSteps;
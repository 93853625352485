import _ from "lodash";
import React, { useEffect, useState } from "react";
import useEquityShareDocuments from "../../../hooks/services/modules/startups/equityShareDocuments/useEquityShareDocuments";
import Icon from "../../../modules/generic/components/Icon";
import useSession from "../../../modules/session/hooks/useSession";
import Text from "../../text/Text/Text";
import Scrollbars from "../Scrollbar/Scrollbar";
import classes from "./UploadedFilesSelector.module.scss";
import SkeletonCheck from "../../../modules/generic/components/Skeletons/Overlays/SkeletonCheck";

const UploadedFilesSelectorComponent = ({ onChange, documents = [] }) => {

  const [selectedFiles, setSelectedFiles] = useState(documents);
  const { startup } = useSession();
  const [filesList, setFilesList] = useState([]);

  const {
    data: fileList,
    loading,
    error: errorModal,
  } = useEquityShareDocuments({ startupId: startup.id });

  useEffect(() => {
    onChange([...selectedFiles]);
  }, [JSON.stringify(selectedFiles)]);

  useEffect(() => {
    if (
      filesList.length === 0 &&
      _.get(fileList, "documentUploadedList", []).length >= 1
    ) {
      setFilesList(_.get(fileList, "documentUploadedList", []));
    }
  }, [fileList]);

  const handleSelectUploadedFiles = (file) => {
    if (_.some(selectedFiles, { id: file.id })) {
      const filterableList = _.filter(
        selectedFiles,
        (fileObject) => fileObject.id !== file.id
      );
      setSelectedFiles(filterableList);
    } else {
      setSelectedFiles([...selectedFiles, file]);
      return file;
    }
  };

  return (
    <React.Fragment>
      {filesList.length >= 1 && (
        <>
          <div>
            {selectedFiles.map((file) => {
              return (
                <div className={classes.selectedFileContainer}>
                  <Icon
                    size={"1rem"}
                    className={classes.tickIcon}
                    icon={"Tick"}
                    isClara={true}
                  />
                  <span>{file.label ? file.label : file.name}</span>
                  <Icon
                    size={"1rem"}
                    onClick={() => {
                      handleSelectUploadedFiles(file);
                    }}
                    className={classes.deleteIcon}
                    icon={"Delete"}
                    isClara={true}
                  />
                </div>
              );
            })}
          </div>
          <span className={classes.listPreTitle}>
            <Text uuid={"UPLOADED_FILES_SELECTOR_PRE_TITLE"} />
          </span>
          <Scrollbars className={classes.uploadedSelectorContainer}>
            {loading ? (
              <>
                <SkeletonCheck noLabel={true}></SkeletonCheck>
                <SkeletonCheck noLabel={true}></SkeletonCheck>
                <SkeletonCheck noLabel={true}></SkeletonCheck>
              </>
            ) : (
              <>
                <span className={classes.listTitle}>
                  <Text uuid={"UPLOADED_FILES_SELECTOR_LIST_TITLE"} />
                </span>
                {filesList.map((file) => {
                  if (file.label || file.name) {
                    return (
                      <span>
                        <button
                          type="button"
                          className={`${
                            _.some(selectedFiles, { id: file.id })
                              ? classes.checkboxChecked
                              : classes.checkbox
                          }`}
                          onClick={() => {
                            handleSelectUploadedFiles(file);
                          }}
                        />
                        {file.label ? file.label : file.name}
                      </span>
                    );
                  } else {
                    return null;
                  }
                })}
              </>
            )}
          </Scrollbars>
        </>
      )}
    </React.Fragment>
  );
};

export default UploadedFilesSelectorComponent;

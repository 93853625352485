import { useMemo } from 'react';
import useSession from 'src/modules/session/hooks/useSession'
import _ from "lodash";
import useUpsertFormationCompany from 'src/modules/startup/GroupCompany/graphql/mutations/useUpsertFormationCompany'
import useAddComponentRole from 'src/modules/startup/onBoarding/core/graphql/mutations/useAddComponentRole'
import useUpdateStakeholder from "src/modules/startup/onBoarding/core/graphql/mutations/useUpdateStakeholder";
import moment from 'moment';

const useServices = ({initialValues}) => {
  // const [upsertData] = useMutation();

  const { startupId } = useSession();
  const [upsertFormationCompany] = useUpsertFormationCompany({}, {});
  const { addComponentRole } = useAddComponentRole({ 
    showSuccessNotification: true,
    successMessage: 'STAKEHOLDER_ADD_SUCCESS'
  }, {});
  const { updateStakeholder } = useUpdateStakeholder(
    {
      showSuccessNotification: false,
      successMessage: "",
    },
    {}
  );

  const upsertFormation = async (groupCompanyId,authorisedSignatoriesIds,isUsingNominee) => {
    return await upsertFormationCompany({
      startupId,
      groupCompanyId,
      groupCompanyData: {
        formationData: {
          authorisedSignatories: authorisedSignatoriesIds,
          isUsingNominee
        }
      }
    })
  }

  return useMemo(() => ({
  
    initialData: async (context, event) => {
      return {...initialValues};
    },
    SaveOnAddThirdPartyFormStep: async (context, event)=>{
      const values = {
        startupId,
        stakeholder: {fullName: context?.AddThirdPartyFormStep?.nameField ?? null},
        roles: [{role: [{type:"THIRDPARTY"}], groupCompany: context?.initialData?.idGroupCompany ?? null}],
      }

      const st = await addComponentRole({...values})
      if (st){
        return upsertFormation(
          context?.initialData?.idGroupCompany ?? null,
          st?.data?.addComponentStakeholderRole.id ?? null,
          _.get(context.WhoIsGoingToBeYourAuthorisedSignatoryFormStep, 'authorisedSignatory.value', '') === 'USE_NOMINEE'
        )
      }
        return false
    },
    SaveOnWhoIsGoingToBeYourAuthorisedSignatoryFormStep: async (context, event)=>{
      const authorisedSignatoriesIds = [context?.WhoIsGoingToBeYourAuthorisedSignatoryFormStep?.authorisedSignatory.id ?? null]

      if (authorisedSignatoriesIds){
        return upsertFormation(
          context?.initialData?.idGroupCompany ?? null,
          authorisedSignatoriesIds
        )
      }
        return false
    },
    SaveAddClaraNominee:async (context)=>{
      const authorisedSignatoriesIds = context?.SelectSecondAuthorisedSignatoryFormStep?.secondAuthorisedSignatory.id? [context?.SelectSecondAuthorisedSignatoryFormStep?.secondAuthorisedSignatory.id] : null
      const st = context?.SelectSecondAuthorisedSignatoryFormStep?.secondAuthorisedSignatory.id 
      if (authorisedSignatoriesIds && st){
        const enterType = context?.EntryTypeFormStep?.howDidTheyEnter;
        const entryDate = moment(context?.EntryTypeFormStep?.entryDate).format('YYYY-MM-DD');
        const variables = {
          startupId,
          stakeholderId: st,
          stakeholderData: {
            stakeholder: {
              enterType,
              entryDate
            },
          },
        };
        await updateStakeholder({ variables })
        return upsertFormation(
          context?.initialData?.idGroupCompany ?? null,
          authorisedSignatoriesIds,
          true
        )
      }
      return false;
    }

  }), [initialValues]);
}

export default useServices;

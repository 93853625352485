import React from 'react';
import classnames from 'classnames';
// import BubbleTask from '../BubbleTask';
import TabBubblesContainer from '../TabBubblesContainer';
import classes from './classes.module.scss';
import { Typography } from '@mui/material'
import BubbleContents from 'src/modules/assistanceV4/components/BubbleContents';
import Bubble from 'src/modules/assistanceV4/components/Bubble'

const AssistanceFrame2 = ({ showTask, variant, isInactive = false, isInactiveGroupCompany = false, isDissolved = false }) => {
  return (
    <div className={classnames(classes.ContentAssitance)}>
      {showTask && (
        <TabBubblesContainer>
          {/* <BubbleTask variant={variant} /> */}
          {isInactive && (
            <Bubble >
              <BubbleContents>
                <Typography variant="body1" sx={{ marginBottom: '10px', fontSize: "12px" }}>
                  This stakeholder is currently inactive as they don’t have an active role in any company.
                </Typography>
              </BubbleContents>
            </Bubble>
          )}
          {(isInactiveGroupCompany && !isDissolved) && (
            <Bubble >
              <BubbleContents>
                <Typography variant="body1" sx={{ marginBottom: '10px', fontSize: "12px" }}>
                  This company is now inactive.
                </Typography>
              </BubbleContents>
            </Bubble>
          )}
          {(isInactiveGroupCompany && isDissolved) && (
            <Bubble >
              <BubbleContents>
                <Typography variant="body1" sx={{ marginBottom: '10px', fontSize: "12px" }}>
                  This company has been struck off and is now inactive
                </Typography>
              </BubbleContents>
            </Bubble>
          )}
        </TabBubblesContainer>
      )}
    </div>
  );
}

export default AssistanceFrame2

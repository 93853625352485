import _ from "lodash";
import React, {Component} from 'react';
import {Field} from "formik";
import ClaraSelect from "../../../../components/Form/ClaraSelect/ClaraSelect";
import classes from "../../SteperForm.module.scss";
import TextInput from "../../Inputs/TextInput";
import Checkbox from '../../Inputs/Checkbox'
import FormikHelper from "../../../../utils/formikHelper";
import moment from "moment";
import ClaraUpload from "../../../../components/Form/ClaraUpload/ClaraUpload";
import { ClaraInputDate, ClaraRadioWithButton, TextAreaInput,TextType } from "../../../../components";
import ClaraInputRadioButton from "../../../../components/Form/ClaraInputRadioButton/ClaraInputRadioButton";
import ClaraCountrySelector from "../../../../components/Form/ClaraCountrySelector/ClaraCountrySelector";
import AuthorisedSignatories from "../../../../../components/inputs/AuthorizedSignatories/AuthorizedSignatories";
import ErrorInput from "../../../../../modules/generic/components/Inputs/ErrorInput"
import {groupCompanyRecipientNotice, stakeholderAuthorizedSignatory, stakeholderRecipientNotice} from './helper';

class AdvisorAgreementDoc extends Component {

    constructor(props) {
        super(props);
        this.state = {
            typeSelected: undefined,
            advisorSelected: undefined,
            groupCompanySelected: undefined,
            authorizedSelected: undefined,
            authorizedSignatories: [],
            authorizedSignatoriesList: [],
            documentTypes: [],
            documentTypesList: [],
            dynamicParams: [],
            date: undefined
        }
    };

    componentWillMount() {
        if (this.props.values) {
            const values = this.props.values.values ? this.props.values.values : this.props.values;

            this.setState({
                typeSelected: values.documentType,
                advisorSelected: values.advisor,
                groupCompanySelected: values.company,
                authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.company).map(as => ({
                    id: as.id,
                    label: as.label
                }))
            });
        }

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.values) {
            const values = nextProps.values.values ? nextProps.values.values : nextProps.values;
            this.setState({
                authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.company)
                    .map(as => ({
                        id: as.id,
                        label: as.label
                    }))
            });
        }
    }

    selectType = (documentType, extraParams, form) => {
        this.setState({
            typeSelected: documentType,
            dynamicParams: this.getDynamicParamsByType(documentType)
        }, () => {
            FormikHelper.setValueInTheCorrectPosition('terms', form, []);
            FormikHelper.setValueInTheCorrectPosition('documentType', form, documentType);
            this.state.dynamicParams.forEach((dynamicParam, i) => {
                FormikHelper.setValueInTheCorrectPosition(
                    `terms[${i}].key`,
                    form,
                    dynamicParam.key || 'NO_KEY'
                );
            })
        });
    };

    getDynamicParamsByType(documentType) {
        const type = this.props.documentTypes.filter(agrType => agrType.code === documentType)[0] || {};
        return type.fields || [];
    }

    getGroupCompanies = () => {
        let authorizedSignatories = this.props.authorizedSignatories;
        let groupCompanies = this.props.groupCompanies;
        let companies = [];
        if (groupCompanies && authorizedSignatories) {
            companies = _.filter(groupCompanies, (groupCompany) => {
                let fined = _.filter(authorizedSignatories, (authorized) => {
                    if (groupCompany.authorizedSignatories) {
                        return groupCompany.authorizedSignatories.some(au => au.stakeholder.id === authorized.id);
                    }
                    return false;
                });
                if (fined) {
                    return true;
                }
                return false;
            });
        }
        return companies;
    };

    selectGroupCompany = (gcId, extraParams, form) => {
        const groupCompany = this.findGroupCompany(gcId);

        // this is for a weird behaviour when hit edit button after preview a document
        if (this.state.groupCompanySelected && groupCompany) {
            return null;
        }

        this.setState({
            groupCompanySelected: groupCompany
        });

        if (groupCompany) {

            if (!groupCompany.registeredAddress) {
                groupCompany.registeredAddress = {}
            }

            let authorizedSignatories = _.get(form, 'values.company.authorizedSignatories', []);

            form.setValues({
                ...form.values,
                company: {
                    id: gcId,
                    name: groupCompany.name || '',
                    country:typeof  _.get(groupCompany, 'country', null) ==='object'? _.get(groupCompany, 'country.code', null): _.get(groupCompany, 'country', null),
                    jurisdiction: groupCompany.jurisdiction,
                    registeredNumber: groupCompany.registeredNumber || '',
                    type: groupCompany.type || '',
                    registeredAddress: {
                        ...groupCompany.registeredAddress, country:typeof  _.get(groupCompany, 'registeredAddress.country', null) ==='object'? _.get(groupCompany, 'registeredAddress.country.code', null):_.get(groupCompany, 'registeredAddress.country', null)
                    },
                    authorizedSignatories: authorizedSignatories,
					recipientNotice: groupCompanyRecipientNotice(form.values.company, groupCompany)
                }
            });

            this.setState({
                groupCompanySelected: groupCompany,
            })
        } else {
            form.setValues({
                ...form.values,
                company: {}
            });
        }
    };

    findGroupCompany(gcId) {
        const gc = _.find(this.props.groupCompaniesList,{id:gcId});
        return gc?{...gc}:null
    };

    selectAdvisor = (advisor, extraParams, form, cb) => {
        const stakeholder = this.findStakeholder(advisor);

        this.setState({
            advisorSelected: stakeholder,
        });


        if (stakeholder) {
            let authorizedSignatory = {};

            if (!stakeholder.address) {
                _.set(stakeholder,'address', {})
            }

            const _advisor = !_.isEmpty(form.values.advisor) ? form.values.advisor : stakeholder;

            if (stakeholder.isAnEntity) {
				authorizedSignatory = stakeholderAuthorizedSignatory(_advisor);
                // authorizedSignatory = {
                //     fullName: typeof _advisor.authorizedSignatory === 'object'
                //         ? _.get(_advisor, 'authorizedSignatory.fullName', '')
                //         : _.get(_advisor, 'authorizedSignatory', ''),
                //     email: _advisor.email || ''
                // }
            }

            form.setValues({
                ...form.values,
                advisor: {
                    id: advisor,
                    fullName: _advisor.fullName || '',
                    email: _advisor.email || '',
                    passportNumber: _advisor.passportNumber || '',
                    registeredNumber: _advisor.registeredNumber || '',
                    nationality: _.get(_advisor, 'nationality.code', null) || _.get(_advisor, 'nationality', null),
                    jurisdiction: _advisor.jurisdiction,
                    otherJurisdiction: _.get(_advisor, 'otherJurisdiction', ''),
                    type: _advisor.type || '',
                    address: { ..._advisor.address, country:typeof  _.get(_advisor, 'address.country', null) ==='object'?_.get(_advisor, 'address.country.code', null) : _.get(_advisor, 'address.country', null) },
                    isAnEntity: _advisor.isAnEntity,
                    authorizedSignatory: authorizedSignatory,
					recipientNotice: stakeholderRecipientNotice(form.values.advisor, stakeholder)
                }
            });
        } else {
            form.setValues({
                ...form.values,
                advisor: {}
            });
        }
    };

    findStakeholder(roleId) {
        return this.props.stakeholderList.find(stakeholder => stakeholder.id === roleId);
    };

    findStakeholderAuthorized(roleId) {
        return this.props.stakeholderList.filter(stakeholder => {
            return stakeholder.roles.some(role => role.stakeholder.id === roleId);
        })[0] || null;
    };

    filterAuthorizedByGroupCompany(groupCompany) {
        if (groupCompany) {
            let gc = this.findGroupCompany(groupCompany.id);
            return this.props.authorizedSignatories.filter(authorized => {
                return authorized.groupCompanyId === gc.id
            });
        }
    }

    selectAuthorized = (value, form, field, replace) => {
        const stakeholder = this.props.authorizedSignatories.find(e => e.id === value);
        const email = stakeholder ? stakeholder.email : '';
        replace(0, { id: value, email })
    };

    renderAdvisorAuthorizedSignatory(field) {
        const { tooltips = {} } = this.props;

        if (this.state.advisorSelected.isAnEntity) {
            return (
                <React.Fragment>
                    <div className={classes.ClaraInputSelect}>
                        <Field name='advisor.authorizedSignatory.fullName'>
                            {({ field, form }) => (
                                <TextInput
                                    required={true}
                                    type='text'
                                    label='Corporate signatory '
                                    field={field}
                                    form={form}
                                    placeholder={"Corporate signatory"}
                                    tooltip={tooltips["Authorised Signatory"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name='advisor.authorizedSignatory.email'>
                            {({ field, form }) => (
                                <TextInput
                                    required={true}
                                    type='email'
                                    label="Coporate signatory's email address "
                                    field={field}
                                    form={form}
                                    onChange={(e) => this.updateEmailValue(e, form)}
                                    placeholder={"Email address"}
                                    tooltip={tooltips['Authorised Signatory Email']}
                                />
                            )}
                        </Field>
                    </div>
                </React.Fragment>
            )
        }
    };

    updateEmailValue(e, form) {
        if (this.state.advisorSelected.isAnEntity) {
            form.setFieldValue('advisor.authorizedSignatory.email', e.target.value, false)
        }
    }

    getDateForPickerFromData(date) {
        if (!date) return undefined;
        return moment(date).toDate();
    }

    handleChange(e, field, form) {

        const date = e === null ? null : moment(e).format("YYYY-MM-DD");

        FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

        this.setState({
            [field.name]: e
        })
    }

    getAuthorizedSignatoriesList = (index, form) => {
        if (form.values && form.values.company) {
            return this.filterAuthorizedByGroupCompany(form.values.company).map(as => ({
                id: as.id,
                label: as.label
            }))
        } else {
            return [];
        }
    };

    getLabel = (key) => {
        switch (key) {
            case "fullName":
                if (this.state.advisorSelected.isAnEntity) {
                    return "Company name";
                } else {
                    return "Name";
                }
            case "country":
                if (this.state.advisorSelected.isAnEntity) {
                    return "Country of incorporation";
                } else {
                    return "Nationality";
                }
            case "address":
                if (this.state.advisorSelected.isAnEntity) {
                    return "Registered address";
                } else {
                    return "Residential address";
                }
            case "passportOrRegistered":
                if (this.state.advisorSelected.isAnEntity) {
                    return "Company number";
                } else {
                    return "Passport number";
                }
        }
    };

    getField = (key) => {
        switch (key) {
            case "passportOrRegistered":
                if (this.state.advisorSelected.isAnEntity) {
                    return "advisor.registeredNumber";
                } else {
                    return "advisor.passportNumber";
                }
        }
    };

    getTooltip = (key, fieldName) => {
        switch (key) {
            case "fullName":
                if (this.state.advisorSelected.isAnEntity) {
                    return "Advisor Company's Name";
                } else {
                    return "Advisor's Name";
                }
            case "passportOrRegistered":
                if (this.state.advisorSelected.isAnEntity) {
                    return "Company Number";
                } else {
                    return "Passport Number";
                }
            case "country":
                if (this.state.advisorSelected.isAnEntity) {
                    return "Country";
                } else {
                    return "Nationality";
                }
            case "addressLine":
                if (this.state.advisorSelected.isAnEntity) {
                    return "Registered Address - Address";
                } else {
                    return "Residential Address - Address";
                }
            case "addressCity":
                if (this.state.advisorSelected.isAnEntity) {
                    return "Registered Address - City";
                } else {
                    return "Residential Address - City";
                }
            case "addressState":
                if (this.state.advisorSelected.isAnEntity) {
                    return "Registered Address - State";
                } else {
                    return "Residential Address - State";
                }
            case "addressCountry":
                if (this.state.advisorSelected.isAnEntity) {
                    return "Registered Address - Country";
                } else {
                    return "Residential Address - Country";
                }
            case "addressPostCode":
                if (this.state.advisorSelected.isAnEntity) {
                    return "Registered Address - Post Code";
                } else {
                    return "Residential Address - Post Code";
                }
            case "recipientNotice":
                if (fieldName === 'company') {
                    return "Company's";
                } else {
                    return "Advisor's";
                }
        }
    };

    getPlaceholder = (key) => {
        switch (key) {
            case "passportOrRegistered":
                if (this.state.advisorSelected.isAnEntity) {
                    return "Company number";
                } else {
                    return "Passport number";
                }
        }
    };

    renderRecipientNotice(fieldName, isAnEntity) {
        const { tooltips = {} } = this.props;

        if (isAnEntity) {
            return (
                <React.Fragment>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.fullName`}>
                            {({ field, form }) => (
                                <TextInput
                                    required={true}
                                    type='text'
                                    label='Recipient for notices '
                                    field={field}
                                    form={form}
                                    placeholder={"Recipient's name"}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={`${classes.ContainerRow} ${classes.Select}`}>
                        <Field name={`${fieldName}.recipientNotice.address.country`}>
                            {({ field, form }) => (
                                <ClaraCountrySelector
                                    isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                    jurisdictionsEnabled={false}
                                    countryField={`${fieldName}.recipientNotice.address.country`}
                                    label={"Recipient's address "}
                                    setFieldValue={form.setFieldValue}
                                    initialValues={{ country: _.get(form.values, `${fieldName}.recipientNotice.address.country`, null) }}
                                    returnCountryAsString={_.isEmpty(_.get(form.values, `${fieldName}.recipientNotice.address.country.code`, null))}
                                    additionalStyle={'ClaraCountrySelectorWizard'}
                                    tooltips={{
                                        country: tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Country"]
                                    }}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.address.street`}>
                            {({ field, form }) => (
                                <TextAreaInput
                                    type='text'
                                    label={' '}
                                    field={field}
                                    form={form} placeholder={"Address"}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Address"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.address.city`}>
                            {({ field, form }) => (
                                <TextInput
                                    type='text'
                                    field={field}
                                    form={form}
                                    placeholder={"City.."}
                                    label={' '}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - City"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.address.state`}>
                            {({ field, form }) => (
                                <TextInput
                                    type='text'
                                    field={field}
                                    form={form}
                                    placeholder={"State / Region"}
                                    label={' '}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - State"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.address.zipCode`}>
                            {({ field, form }) => (
                                <TextInput
                                    type='text'
                                    field={field} form={form}
                                    label={' '}
                                    placeholder={"Post code"}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Post Code"]}
                                />

                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.email`}>
                            {({ field, form }) => (
                                <TextInput
                                    required={true}
                                    type='email'
                                    label="Recipient's email address "
                                    field={field}
                                    form={form}
                                    placeholder={"Email address"}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Email Address"]}
                                />
                            )}
                        </Field>
                    </div>
                </React.Fragment>
            )
        }
    };

    renderAgreementDurationFields(form) {
        const { tooltips = {} } = this.props;
        if (!_.get(form, 'values.terms.agreementDuration')) {
            return null
        }

        return (
            <React.Fragment>
                {
                    form.values.terms.agreementDuration === 'otherDuration' ?
                        <Field name='terms.otherDuration'>
                            {({ field, form }) => (
                                <ClaraInputDate
                                    start={{ month: 0, year: 0 }}
                                    stop={{ month: 11, year: 99 }}
                                    required={true}
                                    field={field}
                                    form={form}
                                    label={'Duration '}
                                    tooltips={{ years: tooltips["Duration Years"], months: tooltips["Duration Months"] }}

                                />
                            )}
                        </Field>
                        : null
                }
            </React.Fragment>
        );

    }

    getStakeholderAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add new individual stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
                placeholder: "Stakeholder's name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: false,
                    // roles : [{
                    // 	role:"SHAREHOLDER",
                    // 	groupCompany: this.state.groupCompanySelected.id,
                    // }]
                },
                paramsReturn: 'isAnEntity'
            },
        ]
    };

    getCompanyAdds = () => {
        return [{
            type: "groupCompany",
            label: "Add new company",
            textWarning: "This will create a new group company for your startup. You can add or amend the details of this group company on its profile page.",
            placeholder: "Company name",
            startupId: this.props.startup.id,
            params: null,
            paramsReturn: `
				name
				authorizedSignatories {
					id
					stakeholder {
						id
						email
						fullName
					    roles {  
					    id
                        name
        groupCompany { id name}
        stakeholder { id }
         }
					}
				}
			`
        }]
    };

    getAuthorizedSignatoryAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add new individual stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
                placeholder: "Enter stakeholder's name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: false,
                    roles: [{
                        role: "DIRECTOR",
                        groupCompany: this.state.groupCompanySelected.id,
                    }]
                }
            },
        ]
    };

    handleStakeholderCreate = (value, name, additionalValue, form) => {

        let { stakeholders, stakeholderList } = this.props;
        let newSh = { id: value.id, fullName: name, ...additionalValue };
        stakeholderList.push(newSh);

        stakeholders.push({ id: value.id, label: name });
        // this.setState({ groupCompaniesList, groupCompanies });

    };

    handleGroupCompanyCreate = (value, name, additionalValue, form) => {

        let { groupCompanies, groupCompaniesList } = this.props;
        let newGc = { id: value.id, name, ...additionalValue };
        groupCompaniesList.push(newGc);

        groupCompanies.push({ id: value.id, label: name });
        // this.setState({ groupCompaniesList, groupCompanies });

    };

    handleAuthorizedSignatoryUpdate = (value) => {

        const { authorizedSignatories, groupCompanySelected } = this.state;
        const groupCompany = this.findGroupCompany(groupCompanySelected.id);

        _.set(groupCompany,'authorizedSignatories',_.map(groupCompany.authorizedSignatories,auth=>({...auth})))
        groupCompany.authorizedSignatories.push({ stakeholder: value });
        authorizedSignatories.push(value);

    };

    render() {
        const isRequired = message => value => (!!value ? undefined : message);
        const { tooltips = {}, formType } = this.props;

        return (
            <div>
                <div className={classes.ContainerRow}>
                    {formType == 'uploadToEdit' ? (<div>
                        <Field name={`file`}>
                            {({ field, form }) => (
                                <ClaraUpload
                                    required={true}
                                    name={`filepond`}
                                    label='Upload your document '
                                    field={field}
                                    form={form}
                                />
                            )}
                        </Field>
                    </div>) : null}
                    <Field name='agreementName'>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='text'
                                label='Document name '
                                field={field}
                                form={form}
                                placeholder={"Document name"}
                                tooltip={tooltips['Document Name']}
                            />
                        )}
                    </Field>
                </div>

                <hr className={classes.Separator} />
                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                    <Field name='advisor.id'>
                        {({ field, form }) => (
                            <ClaraSelect
                                required={true}
                                field={field}
                                form={form}
                                label={"Select advisor "}
                                adds={this.getStakeholderAdds()}
                                lists={this.props.stakeholders}
                                mode={"classic"}
                                callbacks={{
                                    createValues: this.handleStakeholderCreate,
                                    updateValues: this.selectAdvisor
                                }}
                                placeholder={"Select from the list"}
                                tooltip={tooltips["Select Advisor"]}
                            />

                        )}
                    </Field>
                </div>
                {
                    this.state.advisorSelected ?
                        <React.Fragment>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                {
                                    this.state.advisorSelected.isAnEntity ?
                                        <>
                                            <h1>Advisor Company Details</h1>
                                            <p>Please verify or update the advisor company's information below</p>
                                        </>
                                        :
                                        <>
                                            <h1>Advisor Details</h1>
                                            <p>Please verify or update the advisor’s information below</p>
                                        </>
                                }
                            </div>

                            <div className={classes.ContainerRow}>
                                <Field name='advisor.fullName'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label={this.getLabel("fullName") + ' '}
                                            field={field}
                                            form={form}
                                            placeholder={'Name'}
                                            required={true}
                                            tooltip={tooltips[this.getTooltip('fullName')]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={`${classes.ContainerRow} ${classes.Select}`}>
                                <Field name='advisor.nationality'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={this.state.advisorSelected.isAnEntity}
                                            countryField={'advisor.nationality'}
                                            label={this.getLabel('country') + ''}
                                            jurisdictionField={'advisor.jurisdiction'}
                                            jurisdictionLabel={'Jurisdiction '}
                                            returnCountryAsString={true}
                                            otherJurisdictionField={'advisor.otherJurisdiction'}
                                            setFieldValue={form.setFieldValue}
                                            initialValues={{ jurisdiction: _.get(form.values, 'advisor.jurisdiction') }}
                                            additionalStyle={'ClaraCountrySelectorWizard'}
                                            tooltips={{
                                                country: tooltips[this.getTooltip('country')],
                                                jurisdiction: tooltips['Jurisdiction'],
                                                other: tooltips['Other Jurisdiction']
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            {
                                this.state.advisorSelected.isAnEntity ?
                                    <React.Fragment>
                                        {/*
                                        <div className={classes.ContainerRow}>
                                            <Field name='advisor.jurisdiction'>
                                                {({ field, form }) => (
                                                    <TextInput
                                                        required={true}
                                                        type='text'
                                                        label='Jurisdiction: '
                                                        field={field}
                                                        form={form}
                                                        placeholder={'Jurisdiction'}
                                                        tooltip={tooltips['Jurisdiction']}
                                                    />
                                                )}
                                            </Field>
                                        </div>
*/}
                                        <div className={classes.ContainerRow}>
                                            <Field name='advisor.type'>
                                                {({ field, form }) => (
                                                    <TextInput
                                                        required={true}
                                                        type='text'
                                                        label='Company type '
                                                        field={field}
                                                        form={form}
                                                        placeholder={'Company type'}
                                                        tooltip={tooltips['Company Type']}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                    </React.Fragment>
                                    :
                                    null
                            }
                            <div className={classes.ContainerRow}>
                                <Field name={this.getField("passportOrRegistered")}>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label={this.getLabel("passportOrRegistered") + ' '}
                                            field={field}
                                            form={form}
                                            placeholder={this.getPlaceholder("passportOrRegistered")}
                                            tooltip={tooltips[this.getTooltip("passportOrRegistered")]}
                                        />
                                    )}
                                </Field>
                            </div>
                            {
                                !this.state.advisorSelected.isAnEntity ?
                                    <React.Fragment>
                                        <div className={classes.ContainerRow}>
                                            <Field name='advisor.email'>
                                                {({ field, form }) => (
                                                    <TextInput
                                                        type='text'
                                                        label='Email address '
                                                        field={field}
                                                        form={form}
                                                        placeholder={'Email address'}
                                                        required={true}
                                                        tooltip={tooltips["Advisor's Email Address"]}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                    </React.Fragment>
                                    :
                                    null
                            }
                            <div className={`${classes.ContainerRow} ${classes.Select}`}>
                                <Field name='advisor.address.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={false}
                                            countryField={'advisor.address.country'}
                                            returnCountryAsString={true}
                                            label={this.getLabel('address') + ''}
                                            setFieldValue={form.setFieldValue}
                                            additionalStyle={'ClaraCountrySelectorWizard'}
                                            tooltips={{
                                                country: tooltips[this.getTooltip('addressCountry')]
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='advisor.address.street'>
                                    {({ field, form }) => (
                                        <TextAreaInput
                                            type='text'
                                            label={' '}
                                            field={field}
                                            form={form}
                                            placeholder={'Address'}
                                            tooltip={tooltips[this.getTooltip('addressLine')]}

                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='advisor.address.city'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={'City'}
                                            tooltip={tooltips[this.getTooltip('addressCity')]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='advisor.address.state'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={'State / Region'}
                                            tooltip={tooltips[this.getTooltip('addressState')]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='advisor.address.zipCode'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={"Post code"}
                                            tooltip={tooltips[this.getTooltip('addressPostCode')]}
                                        />
                                    )}
                                </Field>
                            </div>
                            {this.renderRecipientNotice('advisor', this.state.advisorSelected.isAnEntity)}
                            {this.renderAdvisorAuthorizedSignatory('advisor')}
                        </React.Fragment>
                        :
                        null
                }
                <hr className={classes.Separator} />
                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                    <Field name='company.id'>
                        {({ field, form }) => (
                            <ClaraSelect
                                required={true}

                                field={field}
                                form={form}

                                label={"Select company "}
                                lists={this.getGroupCompanies()}
                                mode={"classic"}
                                callbacks={{
                                    createValues: this.handleGroupCompanyCreate,
                                    updateValues: this.selectGroupCompany
                                }}
                                placeholder={"Select from the list"}
                                tooltip={tooltips['Select Company']}
                            />
                        )}
                    </Field>
                </div>
                {
                    this.state.groupCompanySelected ?
                        <React.Fragment>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Company Details</h1>
                                <p>Please verify or update the company's information below</p>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.name'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label='Company name '
                                            field={field}
                                            form={form}
                                            placeholder={"Company name"}
                                            tooltip={tooltips["Company's Name"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={`${classes.ContainerRow} ${classes.Select}`}>
                                <Field name='company.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={true}
                                            countryField={'company.country'}
                                            label={'Country of incorporation '}
                                            jurisdictionField={'company.jurisdiction'}
                                            returnCountryAsString={true}
                                            jurisdictionLabel={'Jurisdiction '}
                                            otherJurisdictionField={'company.otherJurisdiction'}
                                            setFieldValue={form.setFieldValue}
                                            initialValues={{ jurisdiction:_.get(form.values, 'company.otherJurisdiction') || _.get(form.values, 'company.jurisdiction') }}
                                            additionalStyle={'ClaraCountrySelectorWizard'}
                                            tooltips={{
                                                country: tooltips["Company's Country"],
                                                jurisdiction: tooltips["Company's Jurisdiction"],
                                                other: tooltips['Other Jurisdiction']
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.type'>
                                    {({ field, form }) => (
                                        <TextType
                                            required={true}
                                            groupCompany={this.state.groupCompanySelected}
                                            type='text'
                                            label='Company type '
                                            field={field}
                                            form={form}
                                            placeholder={'Company type'}
                                            tooltip={tooltips["Company's Company Type"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.registeredNumber'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label='Company number '
                                            field={field}
                                            form={form}
                                            placeholder={'Company number'}
                                            tooltip={tooltips["Company's Company Number"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={`${classes.ContainerRow} ${classes.Select}`}>
                                <Field name='company.registeredAddress.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={false}
                                            countryField={'company.registeredAddress.country'}
                                            label={'Registered address '}
                                            returnCountryAsString={true}
                                            setFieldValue={form.setFieldValue}
                                            additionalStyle={'ClaraCountrySelectorWizard'}
                                            tooltips={{
                                                country: tooltips["Company's Registered Address - Country"]
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.registeredAddress.street'>
                                    {({ field, form }) => (
                                        <TextAreaInput
                                            type='text'
                                            label={' '}
                                            field={field}
                                            form={form}
                                            placeholder={"Address"}
                                            tooltip={tooltips["Company's Registered Address - Address"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.registeredAddress.city'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={'City'}
                                            tooltip={tooltips["Company's Registered Address - City"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.registeredAddress.state'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={'State / Region'}
                                            tooltip={tooltips["Company's Registered Address - State"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.registeredAddress.zipCode'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={"Post code"}
                                            tooltip={tooltips["Company's Registered Address - Post Code"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            {this.renderRecipientNotice('company', true)}
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Corporate Signatories</h1>
                                <p>Please add all the signatories required to sign this document on behalf of the company.</p>
                            </div>
                            <Field name='company.authorizedSignatories_error'>
                                {({ field, form }) => (
                                    <React.Fragment>
                                        <span hasError={_.get(form.errors, "company.authorizedSignatories") ? "true" : "false"}>
                                            <AuthorisedSignatories
                                                list={this.props.stakeholderList}
                                                fieldArrayName={'company.authorizedSignatories'}
                                                idGroupCompany={this.state.groupCompanySelected.id}
                                                handleAddListStakeholder={this.handleAuthorizedSignatoryUpdate}
												errorMessage={_.get(form.errors, "company.authorizedSignatories")}
                                            />
                                        </span>
                                    </React.Fragment>
                                )}
                            </Field>
                        </React.Fragment>
                        :
                        null
                }

                {
                    this.state.advisorSelected && this.state.groupCompanySelected ?
                        <React.Fragment>
                            <hr className={classes.Separator}/>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Services</h1>
                                <p>The activities the advisor will perform for your startup.</p>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='terms.timeCommitment'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label='Time commitment '
                                            field={field}
                                            form={form}
                                            placeholder={'e.g. 5, 10, 20'}
                                            rightLabel={'Hours'}
                                            tooltip={tooltips['Time Commitment']}
                                        />
                                    )}
                                </Field>

                                <div className={classes.componentCheckBox}>
                                    <div className={`${classes.labelGroup} ${classes.labelGroupWizard}`}>
                                        <label> </label>
                                    </div>
                                    <div className={`${classes.groupCheckbox} ${classes.groupCheckBoxWizard}`}>
                                        <Field name='terms.timeCommitmentFrequency'>
                                            {({ field, form }) => (
                                                <ClaraInputRadioButton
                                                    label={' '}
                                                    field={field}
                                                    form={form}
                                                    value={field.value}
                                                    options={[
                                                        {
                                                            id: 'perWeek',
                                                            label: 'per week',
                                                            className: classes.radioButtonWizard,
                                                            classNameSelected: classes.SelectedBLUE
                                                        },
                                                        {
                                                            id: 'perMonth',
                                                            label: 'per month',
                                                            className: classes.radioButtonWizard,
                                                            classNameSelected: classes.SelectedBLUE
                                                        },

                                                    ]}
                                                    className={classes.radioButtonWizard}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.ContainerRow}>
                                <Field name={'terms.meetingAttendance.isAdvisoryBoard'}>
                                    {({ field, form }) => (
                                        <ClaraRadioWithButton
                                            required={true}
                                            label={'Does the company have an advisory board? '}
                                            field={field}
                                            form={form}
                                            changeParams={field.name}
                                            options={[{ value: true, label: 'Yes' }, { value: false, label: 'No' }]}
                                            link={'https://academy.clara.co/glossary/a'}
                                            tooltip={tooltips['Advisory Board']}
                                        // onChange={(v) => { onChange(v,form) }}
                                        />
                                    )}
                                </Field>
                                <Field>
                                    {({ field, form }) => (
                                        _.get(form, 'values.terms.meetingAttendance.isAdvisoryBoard') ?
                                            <div className={classes.ContainerRow}>
                                                <Field name='terms.meetingAttendance.advisoryBoardMeetingFrequency'>
                                                    {({ field, form }) => (
                                                        <TextInput
                                                            required={true}
                                                            type='text'
                                                            label='Advisory board meeting frequency '
                                                            field={field}
                                                            form={form}
                                                            placeholder={'e.g. quarterly'}
                                                            tooltip={tooltips['Advisory Board Meeting Frequency']}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                            : null
                                    )}
                                </Field>
                            </div>

                            <hr className={classes.SeparatorTransparent}/>
                            <div className={classes.ContainerRowAgreement}>
                                <div className={classes.componentCheckBox}>
                                    <div className={`${classes.labelGroup} ${classes.labelGroupWizard}`}>
                                        <label>Areas of advice<span style={{color:'red'}}>*</span></label>
                                    </div>
                                    <div className={`${classes.groupCheckbox} ${classes.groupCheckBoxWizard}`}>
                                        <Field name='terms.adviceAreas.option_a'>
                                            {({ field, form }) => (
                                                <CheckboxInput
                                                    id='terms.adviceAreas.option_a'
                                                    label={'Business operations'}
                                                    field={field}
                                                    form={form}
                                                    className={classes.checkBoxWizard}
                                                    hasError={_.get(form.errors, "adviceAreasFieldName")?"true":"false"}
                                                />
                                            )}
                                        </Field>
                                        <Field name='terms.adviceAreas.option_b'>
                                            {({ field, form }) => (
                                                <CheckboxInput
                                                    id='terms.adviceAreas.option_b'
                                                    label={'Strategic planning'}
                                                    field={field}
                                                    form={form}
                                                    className={classes.checkBoxWizard}
                                                />
                                            )}
                                        </Field>
                                        <Field name='terms.adviceAreas.option_c'>
                                            {({ field, form }) => (
                                                <CheckboxInput
                                                    id='terms.adviceAreas.option_c'
                                                    label={'Networking opportunities'}
                                                    field={field}
                                                    form={form}
                                                    className={classes.checkBoxWizard}
                                                />
                                            )}
                                        </Field>
                                        <Field name='terms.adviceAreas.option_d'>
                                            {({ field, form }) => (
                                                <CheckboxInput
                                                    id='advisor.services.option_d'
                                                    label={'Business development'}
                                                    field={field}
                                                    form={form}
                                                    className={classes.checkBoxWizard}
                                                />
                                            )}
                                        </Field>
                                        <Field name='terms.adviceAreas.option_e'>
                                            {({ field, form }) => (
                                                <CheckboxInput
                                                    id='advisor.services.option_e'
                                                    label={'Market environment'}
                                                    field={field}
                                                    form={form}
                                                    className={classes.checkBoxWizard}
                                                />
                                            )}
                                        </Field>
                                        <Field name='terms.adviceAreas.option_f'>
                                            {({ field, form }) => (
                                                <CheckboxInput
                                                    id='advisor.services.option_f'
                                                    label={'Solutions to innovation challenges'}
                                                    field={field}
                                                    form={form}
                                                    className={classes.checkBoxWizard}
                                                />
                                            )}
                                        </Field>
                                        <Field name='terms.adviceAreas.option_g'>
                                            {({ field, form }) => (
                                                <CheckboxInput
                                                    id='advisor.services.option_g'
                                                    label={'Overall business performance'}
                                                    field={field}
                                                    form={form}
                                                    className={classes.checkBoxWizard}
                                                />
                                            )}
                                        </Field>

                                        <Field name='terms.adviceAreas.option_h'>
                                            {({ field, form }) => (
                                                <CheckboxInput
                                                    id='terms.adviceAreas.option_h'
                                                    label='Other'
                                                    field={field}
                                                    form={form}
                                                    input={true}
                                                    className={classes.checkBoxWizard}
                                                    TextInput={{
                                                        placeholder: 'Describe other activities here'
                                                    }}
                                                />
                                            )}
                                        </Field>
                                        <Field name='terms.adviceAreas.option_error'>
                                            {({ field, form }) => (
                                                <React.Fragment>
                                                    {_.get(form.errors, "adviceAreasFieldName") && <ErrorInput message={_.get(form.errors, "adviceAreasFieldName")} />}
                                                </React.Fragment>
                                            )}
                                        </Field>

                                    </div>
                                </div>
                            </div>

                            <hr className={classes.SeparatorTransparent}/>

                            <div className={classes.ContainerRow}>
                                <Field name='terms.meetingAttendance.duration'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label='Meeting attendance '
                                            field={field}
                                            form={form}
                                            placeholder={'Meeting duration'}
                                            rightLabel={'Hours'}
                                            tooltip={tooltips['Meeting Attendance Duration']}
                                        />
                                    )}
                                </Field>

                                <div className={classes.componentCheckBox}>
                                    <div className={`${classes.labelGroup} ${classes.labelGroupWizard}`}>
                                        <label> </label>
                                    </div>
                                    <div className={`${classes.groupCheckbox} ${classes.groupCheckBoxWizard}`}>
                                        <Field name='terms.meetingAttendance.frequency'>
                                            {({ field, form }) => (
                                                <ClaraInputRadioButton
                                                    label={' '}
                                                    field={field}
                                                    form={form}
                                                    value={field.value}
                                                    options={[
                                                        {
                                                            id: 'weekly',
                                                            label: 'Weekly',
                                                            className: classes.radioButtonWizard,
                                                            classNameSelected: classes.SelectedBLUE
                                                        },
                                                        {
                                                            id: 'monthly',
                                                            label: 'Monthly',
                                                            className: classes.radioButtonWizard,
                                                            classNameSelected: classes.SelectedBLUE
                                                        },

                                                    ]}
                                                    className={classes.radioButtonWizard}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                </div>
                            </div>
                            <hr className={classes.Separator}></hr>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Advisory Services</h1>
                                <p>Select any that apply from the below:</p>
                            </div>
                            <hr className={classes.SeparatorTransparent}></hr>
                            <div className={classes.ContainerRowAgreement}>
                                <div className={classes.componentCheckBox}>
                                    <div className={`${classes.labelGroup} ${classes.labelGroupWizard}`}>
                                        <label>Promoting the company</label>
                                    </div>
                                    <div className={`${classes.groupCheckbox} ${classes.groupCheckBoxWizard}`}>
                                        <Field name='terms.otherServices.option_a'>
                                            {({ field, form }) => (
                                                <CheckboxInput
                                                    id='terms.otherServices.option_a'
                                                    label={'The advisor shall actively promote the company, including by making introductions on behalf of the company to the advisor\'s overall network of business contacts and circulating the company\'s business plan, pitch deck and other materials as requested by the company to such contacts.'}
                                                    field={field}
                                                    form={form}
                                                    className={classes.checkBoxWizard}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.ContainerRowAgreement}>
                                <div className={classes.componentCheckBox}>
                                    <div className={`${classes.labelGroup} ${classes.labelGroupWizard}`}>
                                        <label>Introductions</label>
                                    </div>
                                    <div className={`${classes.groupCheckbox} ${classes.groupCheckBoxWizard}`}>
                                        <Field name='terms.otherServices.option_b'>
                                            {({ field, form }) => (
                                                <CheckboxInput
                                                    id='terms.otherServices.option_b'
                                                    label={'The advisor shall help the company to find additional, potential founding team members and employees, including by making introductions on behalf of the company to the advisor\'s overall network of business contacts.'}
                                                    field={field}
                                                    form={form}
                                                    className={classes.checkBoxWizard}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.ContainerRowAgreement}>
                                <div className={classes.componentCheckBox}>
                                    <div className={`${classes.labelGroup} ${classes.labelGroupWizard}`}>
                                        <label>Fundraising</label>
                                    </div>
                                    <div className={`${classes.groupCheckbox} ${classes.groupCheckBoxWizard}`}>
                                        <Field name='terms.otherServices.option_c'>
                                            {({ field, form }) => (
                                                <CheckboxInput
                                                    id='terms.otherServices.option_c'
                                                    label={'The advisor shall help the company to raise financing, including by making introductions on behalf of the company to the advisor\'s overall network of business contacts.'}
                                                    field={field}
                                                    form={form}
                                                    className={classes.checkBoxWizard}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.ContainerRowAgreement}>
                                <div className={classes.componentCheckBox}>
                                    <div className={`${classes.labelGroup} ${classes.labelGroupWizard}`}>
                                        <label>Strategic projects</label>
                                    </div>
                                    <div className={`${classes.groupCheckbox} ${classes.groupCheckBoxWizard}`}>
                                        <Field name='terms.otherServices.option_d'>
                                            {({ field, form }) => (
                                                <CheckboxInput
                                                    id='terms.otherServices.option_d'
                                                    label={'The advisor shall help the company on at least one strategic project as requested by the company.'}
                                                    field={field}
                                                    form={form}
                                                    className={classes.checkBoxWizard}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='terms.otherServices.option_e'>
                                    {({ field, form }) => (
                                        <TextAreaInput
                                            type='text'
                                            label={'Other'}
                                            field={field}
                                            form={form}
                                            placeholder={"Describe other activities here"}
                                        />
                                    )}
                                </Field>
                            </div>
                            <hr className={classes.Separator}></hr>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Compensation</h1>
                                <p>The grant of options to the advisor as compensation for the services.<a onClick={() => window.open("https://academy.clara.co/article/2LQfCK5jV3kwneL0bGYICv")}>Learn more...</a></p>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='terms.compensation.numberShares'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='number'
                                            label={'Number of shares '}
                                            field={field}
                                            form={form}
                                            placeholder={'Number'}
                                            tooltip={tooltips['Number Of Shares']}
                                        />
                                    )}
                                </Field>
                                <Field name='terms.compensation.percentageOwnership'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label={'Percentage ownership '}
                                            field={field}
                                            form={form}
                                            placeholder={'e.g. 1, 2, 2.5'}
                                            rightLabel={'%'}
                                            tooltip={tooltips['Percentage Ownership Of Shares']}
                                        />
                                    )}
                                </Field>
                            </div>
                            <hr className={classes.Separator}></hr>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Place of Arbitration</h1>
                                <p>This contract’s dispute resolution mechanism uses arbitration under expedited ICC rules. Please select the place where you would like the arbitration to take place (please enter the name of the city and country and, if applicable, state or freezone), e.g. "London, United Kingdom" or "New York City, New York, United States of America” or "Dubai International Financial Centre, Dubai, United Arab Emirates”. Please note that the place of arbitration that you select can have a material impact on the proceedings and enforcement of the arbitration and arbitral award. Please seek legal advice.</p>
                                </div>
                                <div className={classes.ContainerRow}>

<Field name={`terms.placeOfArbitration`}>
    {({ field, form }) => (
        <TextInput
            required={true}
            type='text'
            label='Place of arbitration '
            field={field}
            form={form}
            placeholder={"Place of arbitration"}
        />
    )}
</Field>
</div>
                        </React.Fragment>
                        : null
                }

                {formType === 'uploadToEdit' ? <div>
                    <Field name='confirmSameData'>
                        {({ field, form }) => (
                            <Checkbox
                                className={classes.sameDataCheckbox}
                                field={field}
                                validate={isRequired('This field is required')}
                                form={form}
                                required={true}
                                label='All document details are verified and correct.'
                            />
                        )}
                    </Field>
                </div> : null}
            </div>

        );
    }

}

const CheckboxInput = (props) => {

    const { label, options, required = false, form, field, tooltips, input, hasError } = props;
    const { value } = field;
    const [selection, setSelection] = React.useState(value ? value.id : 0);

    const handleInput = () => {
        if (value && value.id !== selection) {
            setSelection(value.id);
            form.setFieldValue(`${field.name}.message`, '');
            if (!input) form.setFieldValue(`${field.name}.message`, label);
        }
    };

    if (value && value.id !== selection) {
        form.setFieldValue(`${field.name}.message`, label);
        setSelection(value.id);
    }

    handleInput();

    return (

        <>

            <Field name={`${field.name}.id`}>
                {({ field, form }) => (
                    <Checkbox
                        id={`${field.name}.id`}
                        label={label}
                        field={field}
                        form={form}
                        className={classes.checkBoxWizard}
                        hasError={hasError}
                    />
                )}
            </Field>

            {
                input && value && value.id &&
                <>
                    <Field name={`${field.name}.message`}>

                        {({ field, form }) => (
                            <TextInput
                                type='text'
                                field={field}
                                form={form}
                                className={{
                                    root: classes.InputContainer,
                                    input: classes.TextInput
                                }}
                                {...props.TextInput}
                            />
                        )}
                    </Field>
                    <hr className={classes.Separator}></hr>
                </>
            }
        </>
    )

};

export default AdvisorAgreementDoc;

import { useCallback, useEffect } from 'react';
import useLazyQuery from "../../../../hooks/custom/useLazyQuery";
import _ from "lodash";
import currency from "currency.js"

export const GET_GROUP_COMPANY = `
fragment RequestBasketInfo on RequestBasket {
  id
  status
  isPaid
  pricingItems {
    totalClaraAmount
    totalGovernmentFee
    totalPricingAmount
    totalDiscount
    vat
  }
  requestItems {
    id
    type
    category
    entity {
      ... on StakeHolder {
        id
        description:fullName
      }
      ... on GroupCompany {
        id
        description:name
      }
    }
    values
    value
    product {
      id
      code
      name
      value
      vat
      items{
        code
			name
			description
			value
			subTotal
			vat
			vatPercent
      }
    }
  }
}
  query getGroupCompany($startupId: ID!, $groupCompanyId: ID!) {
  getGroupCompany(startupId: $startupId, groupCompanyId: $groupCompanyId) {
    id 
    name
    requestBasket { ...RequestBasketInfo}
  }
}`;

const useGroupCompany = (variables) => {

  const { manualQuery, data, ...rest } = useLazyQuery(GET_GROUP_COMPANY, variables);

  useEffect(() => {

  }, []);

  const getTotals = useCallback((requestItems) => {
    let total = currency(0);
    let subtotal = currency(0);
    let totalVat = currency(0);
    let vat = null
    // TODO REVIEW THIS LOGIC
    _.forEach(requestItems, (requestItem) => {
      subtotal = currency(subtotal).add(requestItem.product.value);
      if (requestItem.product.vat) {
        totalVat = currency(totalVat).add(requestItem.product.value * requestItem.product.vat / 100);
        // total = currency(total).add(requestItem.product.value * (1 + requestItem.product.vat / 100));
        total = currency(total).add(requestItem.product.value);
        vat = requestItem.product.vat;
      } else {
        total = currency(total).add(requestItem.product.value);
      }
    })
    return {
      total: total.value,
      subtotal: subtotal.value,
      totalVat: totalVat.value,
      vat
    }
  }, [])
  return { manualQuery, getTotals, rest };
}

export default useGroupCompany;

import { useEffect } from "react";
import useQuery from "../../../../hooks/custom/useQuery";
import { TasksRequiredFieldFragment } from "../../../map/graphql/typePolicies";

export const GET_GROUP_COMPANY_FOR_ASSISTANCE = `
${TasksRequiredFieldFragment}
query getGroupCompanyAssistanceTasks($startupId: ID!, $groupCompanyId: ID!) {
  getGroupCompany(startupId: $startupId, groupCompanyId: $groupCompanyId) {
    id
    name
    isADGM
    managedByClara
    statusOnMatters {
      isLocked
      isRenewal
      onFields
    },
    advanceCapTable{
      warrants{
        id
        customId
        allowedActions {
          toPublish {
            value
            reasonText
            reasonCode
          }
        }
        tasks{
          status
          ...TasksRequiredFieldFragment
        }
        transactions {
          tasks{
            status
            ...TasksRequiredFieldFragment
          }
        }          
      }
      convertibles{
        id
        customId
        allowedActions {
          toPublish {
            value
            reasonText
            reasonCode
          }
        }
        convertibleType
        tasks{
          status
          ...TasksRequiredFieldFragment
        }
        transactions {
          tasks{
            status
            ...TasksRequiredFieldFragment
          }
        }  
      }
      shareClasses{
        name
        id
        allowedActions {
          toPublish {
            value
            reasonText
            reasonCode
          }
        }
        tasks{
          status
          ...TasksRequiredFieldFragment
        }
        transactions {
          tasks{
            status
            ...TasksRequiredFieldFragment
          }
        }        
        optionPlans{
          id
          name
          allowedActions {
            toPublish {
              value
              reasonText
              reasonCode
            }
            toCreateChild {
              value
              reasonText
              reasonCode
            }
          }
          tasks{
            status
            ...TasksRequiredFieldFragment
          }
          transactions {
            tasks{
              status
              ...TasksRequiredFieldFragment
            }
          }  
          optionGrants{
            id
            customId
            holder {
              ...on StakeHolder {
                id
                fullName
                # email # removed for improvement
              }
              ...on GroupCompany {
                id
                name
              }
            }
            allowedActions {
              toPublish {
                value
                reasonText
                reasonCode
              }
            }
            tasks{
              status
              ...TasksRequiredFieldFragment
            }
            transactions {
              tasks{
                status
                ...TasksRequiredFieldFragment
              }
            }  
          }
        }
        shareIssuances{
          id
          customId
          allowedActions {
            toPublish {
              value
              reasonText
              reasonCode
            }
            toAddToCart {
              value
              reasonText
              reasonCode
            }
          }
          tasks{
            status
            ...TasksRequiredFieldFragment          
          }
          transactions {
            tasks{
              status
              ...TasksRequiredFieldFragment
            }
          }          
        }
      }
    }
    isLocked
    dissolution_date
    isActive
    jurisdiction
    jurisdictionType
    companyType
    companyGoal
    formationProgress
    tasks {
      name
      status
      ...TasksRequiredFieldFragment
    }
    formationData {
      directors {
        id
      }
      businessActivity {
        activity
        operationCompany
      }
    }
	requestBasket {
      matter {
        matterProgress
      }
    }
  }
}
`;

const useGroupCompanyTasks = (variables, config) => {
  const { loading, error, data, ...rest } = useQuery(
    GET_GROUP_COMPANY_FOR_ASSISTANCE,
    variables,
    config,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
    }
  );

  return { loading, error, data, ...rest };
};
export default useGroupCompanyTasks;

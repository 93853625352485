import React, {useEffect, useMemo, useState} from 'react';
import {Constants} from '../../../../../../../utils/constants';
import {useDispatch} from "react-redux";
import {Text} from "../../../../../../../components"
import ServerConnect from "../../../../../../../utils/ServerConnect"
import _ from 'lodash'

import PrimaryButton from "../../../../../Generic/Buttons/PrimaryButton/PrimaryButton"
import SecondaryButton from "../../../../../Generic/Buttons/SecondaryButton/SecondaryButton"
import ModalDecline from "../../ModalDecline/ModalDecline"
import ModalPaidEngagement from '../../ModalPaidEngagement/V1/ModalPaidEngagement';
import useEngagementLinkPaid from "../../../../../../Hooks/services/matter/useEngagementLinkPaid";

const mutationDeclineEngagement = `
    mutation declineEngagement($matterId: ID!,$issues: String!) {
        declineEngagement(matterId: $matterId, issues: $issues) {
            status
    }
  }
`;
const mutationIhavePaid = `
    mutation setAsPaidEngagement($matterId: ID!) {
        setAsPaidEngagement(matterId: $matterId) {
            id
        }
    }
`;

const BotoneraEngagement = ({ matterId, file, setLoading }) => {
    const [showModalDecline, setShowModalDecline] = useState(false);
    const [enabledIHavePaid, setEnabledIHavePaid] = useState(false);
    const [showModalPaid, setShowModalPaid] = useState(false);

    const dispatch = useDispatch();
    const { data } = useEngagementLinkPaid(matterId);
    useEffect(() => {

    }, []);

    const setLoadingPrevComponent = (value) => {
        if (setLoading) {
            setLoading(value)
        }
    };

    const havePaid = () => {
        setLoadingPrevComponent(true);
        ServerConnect.graphQlMutation(mutationIhavePaid, {
            matterId: matterId,
        }).then(res => {
            dispatch({ type: 'GO_TO', page: Constants.PAGES.kycEngagementApprove })

        });
    };

    const decline = () => {
        setShowModalDecline(true)
    };

    const closeModalDecline = (values) => {
        setShowModalDecline(false)
        if (values) {
            setLoadingPrevComponent(true);
            ServerConnect.graphQlMutation(mutationDeclineEngagement, {
                matterId: matterId,
                issues: values.issues,
            }).then(res => {
                setLoadingPrevComponent(false);
                dispatch({ type: 'GO_TO', page: Constants.PAGES.kycEngagementDecline })
            });
        }
    };

    const handleTitleCompany = (companyType) => {

        const company = _.startCase(companyType);
        switch (companyType) {
            case "holdingCompany":
                return `SPV ${company} Engagement`;
            case "techStartup":
                return `TECH ${company} Engagement`;
            case "foundation":
                return `TECH ${company} Engagement`;
            default:
                return `OPERATING ${company} Engagement`;
        }

    };

    const title = useMemo(() => handleTitleCompany(_.get(data, 'companyType')));

    return (
        <React.Fragment>
            <SecondaryButton onClick={decline} > <Text uuid={"BUTTON_DECLINE"} /> </SecondaryButton>
            <PrimaryButton onClick={()=>setShowModalPaid(true)}  ><Text uuid={"BUTTON_PAID"} /></PrimaryButton>
            <ModalDecline show={showModalDecline} handleClose={closeModalDecline} title={title} />
            <ModalPaidEngagement
				show={showModalPaid}
				setLoading={setLoading}
				handlePaid={()=>setEnabledIHavePaid(true)}
				handleClose={() => setShowModalPaid(false)}
				matterId={matterId}
				file={file}
				data={data}
			/>
        </React.Fragment>
    )
};

export default BotoneraEngagement

import React, { FC } from "react";
import useModal from "../../../../../generic/hooks/useModal";
import ModalAssistant, {ModalAssistantProps} from "../../../../../generic/components/Modal/ModalAssistant";
import MachineSubmittedFormation from "../MachineSubmittedFormation/machine";
import Wizard from "../../../../../generic/components/Wizard";
import ModalOverlayFull from '../../../../../generic/components/Modal/ModalOverlayFull/index';
import classesModule from './classes.module.scss'

interface ModalSubmittedFormationProps extends ModalAssistantProps {
  paramsMutation: object;
  onClose(): void;
}

const ModalSubmittedFormation: FC<ModalSubmittedFormationProps> = ({
  open = false,
  paramsMutation
}: ModalSubmittedFormationProps) => {
  const { closeModal: onClose } = useModal();

  const handleClose = () => {
    onClose();
  };

  const stepsDefualts = [
    { step: 0, viewed: true },
    { step: 1, viewed: false },
  ]

  return (
    <ModalOverlayFull open={open} className={classesModule.modalSubmittedFormation} >
      <Wizard
        machine={MachineSubmittedFormation}
        onComplete={handleClose}
        onClose={handleClose}
        initialValues={{ params: { steps: stepsDefualts, actualStep: 0 }, initialData:paramsMutation }}
      />

    </ModalOverlayFull>

  );
};

export default ModalSubmittedFormation;

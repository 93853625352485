import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import ControllerInput from '../../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useSession from '../../../../../modules/session/hooks/useSession';
import { RoleFields } from "../../../../../modules/stakeholder/graphql/typePolicies";
import { Constants } from '../../../../../v1/utils/constants';
import Form from '../../../../forms/Form/Form';
import RowAdd from '../../../../forms/contents/RowAdd/RowAdd';
import SubmitButtonSubForm from '../../../../generic/Buttons/SubmitButtonSubForm/SubmitButtonSubForm';
import CountrySelector from '../../../Select/CountrySelector/CountrySelector';
import TextInput from "../../../Text/TextInput";
import Classes from '../styles.module.scss';
import { TooltipInput } from 'src/v1/components';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Loading from 'src/components/generic/Loading/Loading'
import useUpsertStakeholder from '../useUpsertStakeholder'


export const AddNewStakeholderIndividual = ({ handleUpdate,
    showTitle  ,
    idGroupCompany, 
    closeForm,
    showRegisteredAddress,
tooltips }) => {
    
    const { startupId } = useSession();
    const [data, setData] = useState(null)
    const handleData = (data) => {
        handleUpdate(data.upsertComponentStakeholder)
        setData(data)
    }
    const [mutationCall, loading] = useUpsertStakeholder( { onCompleted: handleData,showSuccessNotification:false })
    const handleSubmit = (values) => {
        const variables = {
            startupId,
            stakeholder: {
                fullName:values.fullName,
                email:values.email,
                "isAnEntity": false,
            },
            "isAnEntity": false,
        };
        if(showTitle) variables.stakeholder.title = values.title
        if(showRegisteredAddress) variables.stakeholder.address = {
            city:values.city,
            state:values.state,
            street:values.street,
            country:values.country,
            zipCode:values.zipCode
        }
        mutationCall(variables)
    };
    useEffect(() => {
        if (loading === false && data) {
            closeForm()
        }
    }, [loading, data])
    const getSchema = ()=>{
        let schema = Yup.object().shape({
            fullName: Yup.string().required("Name is a required field"),
            email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, { message: "This email is invalid", excludeEmptyString: true }).required("Email Address is a required field"),

        })
        if(showTitle) schema = schema.concat(Yup.object().shape({
                title:Yup.string().required('Title field is a required field')
            }))
        if(showRegisteredAddress) schema = schema.concat(Yup.object().shape({
                city:Yup.string().required('City field is a required field'),
                country:Yup.string().required('Country is a required field'),
                state:Yup.string(),
                street:Yup.string().required('Address field is a required field')
        }))
        return schema 
    }
    return (<>{ loading?
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",width:'100%',height:'100%'}}><CircularProgress /></div>:
        <Form defaultValues={{ data: 'asdasdasd' }}
            schema={getSchema()}
            //loading={loading}
            onSubmit={handleSubmit}
        >
            <div className={Classes.containerForm}>
                <RowAdd
                    label={"Name"}
                    asterisk={true}
                    classNameInput={Classes.input}
                    input={
                        <ControllerInput
                            render={TextInput}
                            name={'fullName'}
                            classNameError={Classes.errorForm}
                            placeholder={"Name"}
                        />
                    }
                />

                <RowAdd
                    label={"Email address"}
                    asterisk={true}
                    classNameInput={Classes.input}
                    input={
                        <ControllerInput
                            render={TextInput}
                            name={'email'}
                            type = {'email'}
                            classNameError={Classes.errorForm}
                            placeholder={"Email address"}
                        />
                    }
                />
                {showTitle &&
                <TooltipInput label={tooltips?.title}>
                <RowAdd
                    label={"Title"}
                    asterisk={true}
                    classNameInput={Classes.input}
                    input={
                        <ControllerInput
                            render={TextInput}
                            name={'title'}
                            classNameError={Classes.errorForm}
                            placeholder={"Title"}
                            />
                        }
                        />
                        </TooltipInput>
            }
            {showRegisteredAddress && <>
            <RowAdd
            label={'Address'}
            asterisk={true}
            classNameInput={Classes.input}
            input={
            <ControllerInput
              render={CountrySelector}
              name='country'
              placeholder='Country'
            />
            }/>
            <RowAdd
            classNameInput={Classes.input}
            input={
            <ControllerInput
              render={TextInput}
              name='street'
              defaultlabel={''}
              placeholder='Street'
            />
            }/>
            <RowAdd
            classNameInput={Classes.input}
            input={
            <ControllerInput
              render={TextInput}
              name='city'
              defaultlabel={''}
              placeholder='City'
            />
            }/>
          <RowAdd
            classNameInput={Classes.input}
            input={
            <ControllerInput
              render={TextInput}
              name='state'
              defaultlabel={''}
              placeholder='State / Region'
            />
            }/>
          <RowAdd
            classNameInput={Classes.input}
            input={
            <ControllerInput
              render={TextInput}
              name='zipCode'
              defaultlabel={''}
              placeholder='Zip Code / Post Code'
            />
            }
            />                
            </>}
<div className={Classes.containerButtons}>

                    <button className={`genericWhite`} style={{ marginRight: '1rem' }} onClick={closeForm}>
                        Cancel
                    </button>
                    <SubmitButtonSubForm onClick={handleSubmit}>
                        Save
					</SubmitButtonSubForm>
                </div>
            </div>
        </Form>}
    </>)

}

import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Form from "src/components/forms/Form/Form";
import SelectInput from "src/components/inputs/Select/SelectInput/SelectInput";
import TextInput from "src/components/inputs/Text/TextInput";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import FormProps from "src/modules/generic/forms/interfaces/FormProps";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import {
  addAssistantText,
  clearAssistatText,
} from "src/modules/generic/store/action";
import TemplateLabel from "src/modules/generic/templates/Label";
import TemplateFormDefault from "src/modules/generic/templates/Modal";
import { Constants } from "src/v1/utils";
import * as Yup from "yup";
import useGetFieldsByDocumentType from "../hooks/useGetFieldsByDocumentType";
import useDocumentMapper from "../hooks/useDocumentMapper";
import cls from "./classes.module.scss";

interface FormPropsCustom extends FormProps {
  isCategorize: boolean;
  isEdit: boolean;
  isVisaOrPassportDocument: boolean;
}

const FormCategoriseDocumentData: React.FC<FormPropsCustom> = ({
  initialValues = {},
  isCategorize,
  isEdit,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
}) => {
  const { translate } = useTranslate();
  const { context } = useMachine();
  const dispatch = useDispatch();
  const [parties, setParties] = useState([]);
  const [category, setCategory] = useState(null);
  const [subCategory, setSubCategory] = useState(null);
  const [flow, setFlow] = useState(1);
  const [docType, setDocType] = useState(null);
  
  //TODO: MOVE LABEL AND TOOLTIPS LOGIC TO THIRD HOOK USEGETTOOLTIPSANDLABELS
  const {
    getPartiesList,
    submitValuesMapper,
    getButtonLabel,
    getListDocumentTypes,
    getCustomListParties,
    getPartyList,
  } = useDocumentMapper({
    subCategory,
    parties,
    category,
    context,
    isCategorize,
    isEdit,
    flow,
  });

  const isEditAndIdentityDocument =
    isEdit && _.includes(Constants.DOCUMENT_TYPE_IDENTITY_DOCUMENTS, docType);

  const schemaDefinitions = {
    type: Yup.string().nullable().required("Document Type is required."),
    files: Yup.array().min(1),
    title: Yup.string()
      .nullable()
      .when(["type"], {
        is: (value) =>
          !_.includes(Constants.DOCUMENT_TYPE_IDENTITY_DOCUMENTS, value),
        then: Yup.string()
          .nullable()
          .required("This field is required.")
          .nullable(),
        otherwise: Yup.string().nullable().notRequired(),
      }),
  };

  const schema = !isEditAndIdentityDocument
    ? Yup.object().shape({
      title: schemaDefinitions.title,
      type: schemaDefinitions.type,
      files: schemaDefinitions.files,
    })
    : Yup.object().shape({
      files: schemaDefinitions.files,
    });

  useEffect(() => {
    handleTooltips(null);
  }, []);

  const listDocumentsTypes = getListDocumentTypes();

  const listParties = getPartiesList();

  const listParty = getPartyList();

  const handleSubmit = async (values) => {
    const sendValues = submitValuesMapper(values);
    onCompleteSubmit && (await onCompleteSubmit(sendValues));
  };

  //TODO: REFACTOR CODE AND PUT LOGIC IN USEDOCUMENTMAPPER
  const handleTooltips = (value) => {
    setDocType(value);
    dispatch(clearAssistatText());
    dispatch(
      addAssistantText(
        `Search or select a document type to automatically categorise your documents.`
      )
    );
    if (value !== null) {
      const constantCategories = _.get(context, "categories", []);
      let indexSubCategory = null;
      let category = _.find(constantCategories, (cat) => {
        indexSubCategory = _.findIndex(cat.subCategories, (subCat) => {
          return (
            _.findIndex(subCat.documentTypes, (docType) => {
              return docType.key === value;
            }) !== -1
          );
        });
        return indexSubCategory !== -1;
      });
      if (category) {
        const isIdentityDocument = Constants.DOCUMENT_TYPE_IDENTITY_DOCUMENTS.includes(
          value
        );
        if (isIdentityDocument && isEdit) {
          const documentType = _.find(
            category?.subCategories[indexSubCategory]?.documentTypes ?? [],
            (dt) => dt.key === value
          );
          dispatch(
            addAssistantText(
              translate("MODAL_CATEGORISE_IDENTITY_DOCUMENT_DYNAMIC_TOOLTIP", {
                category: category.title,
                subcategory: category.subCategories[indexSubCategory].title,
                documentType: documentType.title,
              })
            )
          );
        } else {
          dispatch(
            addAssistantText(
              translate("MODAL_CATEGORISE_DOCUMENT_DYNAMIC_TOOLTIP", {
                category: category.title,
                subcategory: category.subCategories[indexSubCategory].title,
              })
            )
          );
        }
        setCategory(category);
        setSubCategory(category.subCategories[indexSubCategory]);
      }
    } else {
      setCategory(null);
      setSubCategory(null);
      setFlow(1);
    }
  };

  useEffect(() => {
    const defaultDocType = _.get(context, "file.type.code");
    if (defaultDocType) {
      setDocType(defaultDocType);
      handleTooltips(defaultDocType);
    }
  }, [context, context?.file?.type?.code]);

  useEffect(() => {
    if (_.includes(Constants.DOCUMENT_TYPE_IDENTITY_DOCUMENTS, docType)) {
      if (
        _.includes(Constants.VISA_STAMPS_RESIDENCE_ID_DOCUMENTS_TYPES, docType)
      ) {
        setFlow(4);
        return;
      }
      setFlow(3);
    }
  }, [docType]);

  const handleAddToParties = (value) => {
    const fullParty = _.find(listParties, (partie) => {
      return partie.id === value;
    });
    if (fullParty) {
      setParties((prev) => [...prev, fullParty]);
    }
  };

  useEffect(() => {
    _.forEach(context?.parties ?? [], (partie) => {
      handleAddToParties(partie.id);
    });
  }, [context?.parties]);

  const customListParties = getCustomListParties(listParties || [], parties);

  const handleRemoveParty = (value) => {
    setParties((pre) => (pre || []).filter((v) => v.id !== value.id));
  };

  const { getFields, getSchema } = useGetFieldsByDocumentType(
    docType,
    category,
    context,
    {
      listParty,
      isDisabled: isEditAndIdentityDocument,
      listParties: customListParties,
      handleRemoveParty,
      handleAddToParties,
      parties,
    }
  );

  return (
    <Form
      defaultValues={initialValues}
      schema={schema.concat(getSchema())}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
      onlySendDirty={false}
    >
      <Template
        buttons={{
          ...buttons,
          submit: (
            <SubmitButton {...buttonSubmitProps}>
              {getButtonLabel()}
            </SubmitButton>
          ),
        }}
        props={propsTemplate}
      >
        {!isEditAndIdentityDocument && (
          <TemplateLabel
            label="Document type"
            className={cls.TemplateTwoLines}
            isRequired
          >
            <ControllerInput
              render={SelectInput}
              placeholder="Type here"
              list={listDocumentsTypes}
              name="type"
              clear={true}
              defaultValue={_.get(context, "file.type.code", "")}
              onChange={(value) => {
                handleTooltips(value);
              }}
            />
          </TemplateLabel>
        )}
        {!Constants.DOCUMENT_TYPE_IDENTITY_DOCUMENTS.includes(docType) && (
          <TemplateLabel
            label="Document name"
            className={cls.TemplateTwoLines}
            isRequired
          >
            <ControllerInput
              placeholder="Type here"
              render={TextInput}
              name="title"
              defaultValue={_.get(context, "file.name", "")}
            />
          </TemplateLabel>
        )}
        {getFields()}
        <div>&nbsp;</div>
      </Template>
    </Form>
  );
};

export default FormCategoriseDocumentData;

import React from 'react';
import Button from "@material-ui/core/Button";

const ButtonMaterial = (props) => {
   
    
    return (
        <button {...props}  > {props.children} </button>
    )
}

export default ButtonMaterial

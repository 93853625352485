import React, { useEffect, useState } from "react";
import * as Yup from 'yup'
import cls from "./FormConstitutionalDocuments.module.scss";
import _ from "lodash";
import { useDispatch } from "react-redux";
import Form from "../../../../../../../components/forms/Form/Form";
import useTranslate from "../../../../../../generic/hooks/useTranslate";
import SubmitButton from "../../../../../../generic/components/Buttons/SubmitButton";
import FormProps from "../../../../../../generic/forms/interfaces/FormProps";
import TemplateFormDefault from "../../../../../../generic/templates/Modal";
import ControllerInput from '../../../../../../generic/components/Inputs/ReactHookForm/ControllerInput';
import { addAssistantText, clearAssistatText } from "../../../../../../generic/store/action";
import useMachine from '../../../../../../generic/context/MachineContext/useMachine';
import FileUploadList from '../../../../../../generic/components/Inputs/FileUploadList';

const schema = Yup.object().shape({
  // files:  Yup.bool(),
})

interface FormConstitutionalDocumentsProps extends FormProps {
  defaultData?: any;
}

const FormConstitutionalDocuments: React.FC<FormConstitutionalDocumentsProps> = ({
  initialValues = {},
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
  defaultData = {},
}) => {

  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const [hasValues, setHasValues] = useState(false);
  const { next } = useMachine();

  const handleSubmit = async (values) => {
    next(values)
  };

  const handleTooltips = () => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText(translate('MODULES.CONSTITUTIONAL_DOCUMENTS.STEP_1.TOOLTIP1')));
  }

  useEffect(() => {
    handleTooltips();
  }, []);

  const DOCUMENT_LIST = [
    'Certificate of Incorporation',
    'Commercial License',
    'Memorandum and/or Articles of Association',
    'Bylaws',
    'Charter',
    'Register of Members',
    'Register of Directors',
    'Share Certificates',
    'Board Resolution ',
    'Shareholder Resolution'
  ];

  const handleChange = (e) => {
    setHasValues(_.get(e, 'length') > 0)
  }

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
      onlySendDirty={false}
    >
      <Template
        buttons={{
          ...buttons,
          submit: <SubmitButton
            isSubmitting={undefined}
            handleDisabled={undefined}
            disabled={!hasValues}
            {...buttonSubmitProps}>
            {translate('GENERIC_BUTTON_ADD_GROUP')}
          </SubmitButton>,
        }}
        props={propsTemplate}
      >
        <div className={cls.container} >
          <div className={cls.columList} >
            {translate('MODULES.CONSTITUTIONAL_DOCUMENTS.STEP_1.DESCRIPTION')}
            <div>
              <ul>
                {_.map(DOCUMENT_LIST, (item) => { return <li>  {item}</li> })}
              </ul>
            </div>
          </div>
          <div className={cls.columnUpload} >
            <div  >
              <ControllerInput
                as={FileUploadList}
                name="files"
                value={[]}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </Template>
    </Form>
  );
};

export default FormConstitutionalDocuments;
import useLazyQuery from "../../../../../hooks/custom/useLazyQuery";

const GET_ACCELERATOR_QUESTIONNAIRE = `
  query getAcceleratorDef($code: String!) {
    getAcceleratorDef(code: $code) {
      code
      name
      questionnaire
      startDate 
      endDate
    }
  }
`;

const useGetQuestionnaire = (variables,config) => {
  const configDefault = {errorMessage: 'GENERIC_PERMISSION_ERROR', showErrorNotification: true, showSuccessNotification: false}
  const { manualQuery,loading, error, data, refetch } = useLazyQuery(GET_ACCELERATOR_QUESTIONNAIRE, variables, {...configDefault,...config});
  return { manualQuery,loading, error, data, refetch };
}

export default useGetQuestionnaire;
import _ from "lodash";
import { useMemo } from "react";
import { assign } from 'xstate';

const useActions = () => {
  return useMemo(() => ({
    setContextAfterFirstInvoke: assign((context, event) => {
      const values = _.get(event, 'data.data.getGroupCompaniesForAccelerators', []);
      return {
        ...context,
        allCompanies: values,
      };
    }),
    setCompanyTopco: assign((context, event) => {
      const filterTopco = _.filter(_.get(context, "allCompanies", {}), (item) => {
        return item.isHoldingCompany;
      });
      return {
        ...context,
        optionTopCo: filterTopco,
      };
    }),
    setStep1Result: assign((context, event) => {
      const resultOption = _.get(event, 'payload.values.confirm', "");
      return {
        ...context,
        resultChoice: resultOption,
      };
    }),
    setStep1ConfirmResult: assign((context, event) => {
      const resultOption = _.get(event, 'payload.values.confirm', "CONFIRM");
      const resultCompanyId = _.get(event, 'payload.values.companyId', "");
      return {
        ...context,
        resultChoice: resultOption,
        resultCompanyId: resultCompanyId,
      };
    }),
    setStep4ConfirmCompanyData: assign((context, event) => {
      const resultCompanyId = _.get(event, 'payload.values.companyData', "");
      return {
        ...context,
        resultCompanyId: resultCompanyId,
      };
    }),
  }), []);
}

export default useActions;
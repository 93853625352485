import React, {Component} from 'react';
import classes from "./DinamicForm.module.scss"
import ClaraInputText from "./ClaraInputText/ClaraInputText"
import ClaraInputHidden from "./ClaraInputHiden/ClaraInputHidden"

import ClaraInputTextArea from "./ClaraInputTextArea/ClaraInputTextArea"
import ClaraInputCheckBoxGroup from "./ClaraInputCheckBoxGroup/ClaraInputCheckBoxGroup"
import ClaraInputSelect from "./ClaraInputSelect/ClaraInputSelect"
import ClaraInputFile from "./ClaraInputFile/ClaraInputFile";
import ClaraInputRadioButton from "./ClaraInputRadioButton/ClaraInputRadioButton";
import ClaraAddInput from "./ClaraAddInput/ClaraAddInput";
import ClaraSwitchButton from "./ClaraSwitchButton/ClaraSwitchButton";
import Botonera from './Botonera'
import "./styles/form.scss";
//utils
var refresh = 0;
class DinamicForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formData: [],
            data: {},
            invalidFields: [],
            viewOnly:true
        }
    };

    /* React Methods */
    componentWillMount() {
        let data = this.props.data ? this.props.data : {};
        this.setState({
            formData: this.props.formData,
            data: data,
            invalidFields: []
        })
    }

    /* Data Manager */
    updateData = (key, value, isValid) => {
        let currentData = this.state.data;
        let invalidFields = this.state.invalidFields;
        let index = invalidFields.indexOf(key);
        if (isValid) {
            if (index >= 0) {
                invalidFields.splice(index, 1);
            }
        } else if (index < 0) {
            invalidFields.push(key);
        }
        currentData[key] = value;
        this.setState({
            data: currentData,
            invalidFields: invalidFields
        }, () => {
            //console.log("Dinamic Form",this.state.data,invalidFields);
        })
    };

    /* Events */
    handlerClick = (e) => {
        this.props.submit(this.state.data)
    };
    handlerDeleteClick = (e) => {
        this.props.handleDelete(this.state.data)
    }

    /* Section to get dinamic components*/
    componentJSX_getFields(data, propsData, stateData, updateData, index,callbacks,isUpdate,disabled) {
        return data.map((input, i) => {
            switch (input.type) {
                case "TEXT":
                    return <ClaraInputText
                        key={i}
                        disabled={disabled}
                        value={propsData ? propsData[input.name] : ""}
                        asd={propsData}
                        tooltipText={input.tooltipText}
                        placeHolder={input.placeHolder}
                        validation={input.validation}
                        label={input.label}
                        name={input.name}
                        updateData={updateData}
                        index={index}
                        type={input.inputType}
                    />;
                case "HIDDEN":
                    return <ClaraInputHidden
                        key={i}
                        value={propsData ? propsData[input.name] : ""}
                        asd={propsData}
                        tooltipText={input.tooltipText}
                        placeHolder={input.placeHolder}
                        validation={input.validation}
                        label={input.label}
                        name={input.name}
                        updateData={updateData}
                        index={index}
                        type={input.inputType}
                        disabled={disabled}
                    />;
                case "TEXTAREA":
                    return <ClaraInputTextArea
                        key={i}
                        value={propsData ? propsData[input.name] : ""}
                        tooltipText={input.tooltipText}
                        placeHolder={input.placeHolder}
                        validation={input.validation}
                        label={input.label}
                        name={input.name}
                        updateData={updateData}
                        index={index}
                        disabled={disabled}
                    />;
                case "CHECKBOX":
                    return <ClaraInputCheckBoxGroup
                        key={i}
                        value={propsData ? propsData[input.name] : []}
                        valueAccessKey={input.valueAccessKey}
                        tooltipText={input.tooltipText}
                        placeHolder={input.placeHolder}
                        validation={input.validation}
                        label={input.label}
                        name={input.name}
                        updateData={updateData}
                        options={input.options}
                        index={index}
                        disabled={disabled}
                    />;
                case "RADIOBUTTON":
                    return <ClaraInputRadioButton
                        key={i}
                        tooltipText={input.tooltipText}
                        placeHolder={input.placeHolder}
                        validation={input.validation}
                        label={input.label}
                        name={input.name}
                        value={propsData ? propsData[input.name] : null}
                        updateData={updateData}
                        options={input.options}
                        index={index}
                        disabled={disabled}
                    />;
                case "SWITCH":
                        return <ClaraSwitchButton
                            key={i}
                            validation={input.validation}
                            label={input.label}
                            name={input.name}
                            value={propsData ? propsData[input.name] : null}
                            updateData={updateData}
                            index={index}
                            disabled={disabled}
                        />;
                case "SELECT":
                    refresh++;
                    return <ClaraInputSelect
                        key={i}
                        value={propsData ? propsData[input.name] : null}
                        tooltipText={input.tooltipText}
                        placeHolder={input.placeHolder}
                        validation={input.validation}
                        label={input.label}
                        name={input.name}
                        updateData={updateData}
                        updateListFunction={input.updateListFunction}
                        callbackUpdate={input.callbackUpdate}
                        decorator={input.decorator}
                        list={input.list}
                        data={stateData}
                        refresh={refresh}
                        index={index}
                        search={input.search}
                        modalTitle={input.modalTitle}
                    />;
                case "FILE":
                    return <ClaraInputFile
                        key={i}
                        value={propsData && propsData[input.name] ? propsData[input.name] : null}
                        name={input.name}
                        updateData={updateData}
                        index={index}
                        callbacks={callbacks}
                        isUpdate={isUpdate}
                        disabled={disabled}
                    />;
                case "ADD":
                    return <ClaraAddInput
                        key={i}
                        value={propsData ? propsData[input.name] : null}
                        tooltipText={input.tooltipText}
                        validation={input.validation}
                        label={input.label}
                        removeLabel={input.removeLabel}
                        name={input.name}
                        updateData={updateData}
                        callbackUpdate={input.callbackUpdate}
                        decorator={input.decorator}
                        data={stateData}
                        refresh={refresh}
                        fields={input.fields}
                        min={input.min}
                        getFields={this.componentJSX_getFields}
                        addOptions = {this.props.addOptions}
                        index={index}
                        callbacks={callbacks}
                        isUpdate={isUpdate}
                        disabled={disabled}
                    />;
                default:
                    return <div/>;
            }
        });
    }

    render() {
        return (
            <div className={classes.DinamicForm}>
                <form>
                    {this.props.title?<h1 className={classes.title}>{this.props.title}</h1>:null}
                    {this.componentJSX_getFields(this.props.formData, this.props.data, this.state.data, this.updateData,null,this.props.callbacks,this.props.isUpdate===true,this.props.disabled)}
                </form>
                {/* agregar el roleView */}
                <div hidden={this.props.disabled}>
                <Botonera
                    onClick={(e) => { this.handlerClick(e) }}
                    disabled={this.state.invalidFields.length > 0 || this.props.disabled}
                    buttonLabel={this.props.buttonLabel}
                    isDelete={this.props.isDelete}
                    isAdd={this.props.isAdd}
                    handleClose={this.props.handleClose}
                    handlerDeleteClick={(e) => { this.handlerDeleteClick(e) }}
                    buttonLabelDelete={this.props.buttonLabelDelete}
                    options={this.props.options}
                    botoneraClasses={this.props.botoneraClasses}
                />
                </div>
                
            </div>
        );
    }
}
export default DinamicForm

import PropTypes from "prop-types"
import React from 'react';
import DetailsTabTitle from "src/components/templates/DetailsTabTitle";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import useModal from "src/modules/generic/hooks/useModal";
import TwoColumnsViewer from "src/modules/generic/components/TwoColumsViewer";
import _ from "lodash";
import { RoleAccess } from "src/v1/components";
import { Constants } from 'src/v1/utils/constants';
import Button from "src/modules/generic/components/Buttons/Button";
import ModalPlacesOfBusiness from  'src/modules/startup/Stakeholder/PlaceOfBusiness';
import Divider from '@mui/material/Divider';
import classes from '../../styles.module.scss'

function PlaceOfBusinessPanel({ data, isLock }) {
  const { translate } = useTranslate();
  const { openModal, closeModal } = useModal();

  const placeOfBusinessButtons = (
    <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE} noAccess={undefined} actions={undefined} params={undefined}>
      <Button variant="card" onClick={() => { openModal(ModalPlacesOfBusiness, { handleCloseModal: closeModal, isEdit:true }) }} disabled={isLock}>
        {translate("VIEW_COMPANY_TAB_BUTTON_DETAILS_EDIT")}
      </Button>
    </RoleAccess>
  );

  return (
    <>
      <Divider className={classes.divider}/>
      <DetailsTabTitle title={translate("VIEW_COMPANY_TAB_LABEL_PLACE_OF_BUSINESS")} buttons={placeOfBusinessButtons} />
      <TwoColumnsViewer
        itemsCol1={[
          {
            label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_STATE"),
            value: _.get(data, "state"),
          },
        ]}
        itemsCol2={[
          {
            label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_CITY"),
            value: _.get(data, "city"),
          },
        ]}
      ></TwoColumnsViewer>
    </>
  );
}

PlaceOfBusinessPanel.propTypes = {
  data: PropTypes.any,
  isLock: PropTypes.bool,
  isFormation: PropTypes.bool,
}

export default PlaceOfBusinessPanel;
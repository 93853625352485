import get from 'lodash/get'
import some from 'lodash/some'
import isEmpty from 'lodash/isEmpty';
import { Constants } from 'src/v1/utils'
import { useMemo } from 'react';

const useGuards = () => {
  return useMemo(() => ({
    noGroupCompanies: (context) => {
      return isEmpty(get(context, 'getGroupCompaniesAndStakeholder.groupCompanies', []))
    },
    havesGroupCompanies: (context) => {
      return !isEmpty(get(context, 'getGroupCompaniesAndStakeholder.groupCompanies', []))
    },
    is1GroupCompany: (context) => {
      return get(context, 'getGroupCompaniesAndStakeholder.groupCompanies').length === 1 || context.roles.length === 0
    },
    isNot1GroupCompany: (context) => {
      return get(context, 'getGroupCompaniesAndStakeholder.groupCompanies').length > 1 && context.roles.length > 0
    },
    isFinishRoles: (context) => {
      if (get(context, 'getGroupCompaniesAndStakeholder.groupCompanies', []).length <= 1) return true
      return get(context, 'roles', []).length <= get(context, 'iterations', 0)
    },
    isFinishNotRoles: (context) => {
      if (get(context, 'getGroupCompaniesAndStakeholder.groupCompanies', []).length <= 1) return false
      return get(context, 'roles', []).length > get(context, 'iterations', 0)
    },
    isFinish: (context) => {
      return !get(context, 'another')
    },
    isNotFinish: (context) => {
      return get(context, 'another')
    },
  }), []);
}
export default useGuards;
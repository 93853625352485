import React, { useMemo } from 'react';
import _ from 'lodash';
import MachineContext from '.';

/**
 * Hook que maneja los estados de una maquina de estado teninedo predefinido funciones y pasos
 */
function useMachine() {
  const { state, send, steps, stepper } = React.useContext(MachineContext);

  const context = useMemo(() => _.get(state, `context`, {}), [state]);
  const next = React.useCallback(
    (payload) => {
      send({
        type: 'NEXT',
        payload: { idState: state.value, values: payload }
      });
    },
    [state, send]
  );

  const prev = React.useCallback(
    (payload) => {
      send({
        type: 'PREV',
        payload: { idState: state.value, values: payload }
      });
    },
    [state, send]
  );

  const cancel = React.useCallback(
    (payload) => {
      send({
        type: 'CANCEL',
        payload: {}
      });
    },
    [send]
  );

  const setAction = React.useCallback(
    ({ action, payload }) => {
      send({
        type: action,
        payload: { idState: state.vale, values: payload }
      })
    },
    [send, state],
  )

  return {
    state,
    steps,
    stepper,
    next,
    prev,
    cancel,
    send,
    context,
    setAction
  };
}

export default useMachine;

import _ from 'lodash'

const filterCategoryByDoctype = (categories, code, docSubCat) =>{

    //special treatment for old types #OTHER
    let mutableArrayCategories = [...categories]
    let category;

    if(code==="OTHER"){
        category = mutableArrayCategories
            .map( cat => ({...cat, subCategories: cat.subCategories
                    .filter(subCat => subCat.documentTypes
                        .some(doctype => doctype.key.includes(code)) &&
                        subCat.key === docSubCat
                    )})
            )
            .find(cat=> cat.subCategories && cat.subCategories.length)

    } else {
        category = mutableArrayCategories
            .map( cat => ({...cat, subCategories: cat.subCategories
                    .filter(subCat => subCat.documentTypes
                        .some(doctype => doctype.key === code)
                    )})
            )
            .find(cat=> cat.subCategories && cat.subCategories.length)


    }

    if(!category){
        // console.log( `missing docType: ${code} setting "OTHER"` );
        category = mutableArrayCategories
            .map( cat => ({...cat, subCategories: cat.subCategories
                    .filter(subCat => subCat.documentTypes
                            .some(doctype => doctype.key.includes("OTHER"))
                    )})
            )
            .find(cat=> cat.subCategories && cat.subCategories.length)

    }

    return category;
}
const getCategoryBySubcategory=(categories,subCategory)=>{
    return _.find(categories,cat=>_.some(cat.subCategories,subCat=>subCat.key===subCategory))
}
export {filterCategoryByDoctype,getCategoryBySubcategory}

import useMutation from "src/hooks/custom/useMutation";

export const UPSERT_COMPANY_ADDRESSES = `
mutation updateStakeholder(
    $startupId: ID!
    $stakeholderId: ID!
	  $stakeholderData: StakeholderInput!
  ) {
    updateStakeholder(
      startupId: $startupId
      stakeholderId: $stakeholderId
      stakeholderData: $stakeholderData
    ) {
      id
  }
}
`;

const useUpdateStakeholderAddresses = () => {
    const [mutation] = useMutation(
      UPSERT_COMPANY_ADDRESSES,
        { showSuccessNotification: true }
    ); 
    return mutation;
};

export default useUpdateStakeholderAddresses;

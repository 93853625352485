import React, { FC, useMemo } from 'react';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import  UaEorGccWizard from './../../submachines/UaEorGccModal/wizard';

interface Props {

}
/**
* Description Step:  You have decided to for your company in ADGM and for your authorised signatory to be a director or shareholder.
* 
*/
const UaEorGccSubMachineStep: FC<Props> = () => {

  const { next, context, prev, cancel } = useMachine();
  const initialValues = useMemo(() => {
    return { ...context?.initialData ?? {} }
  }, [context]);
  
  const handleStopMachine = () => {
    next(context)
  }
  const handleCancelMachine = () => {
    cancel();
  }
  const handlePrevMachine = () => {
      prev();
  }
  
  return (
    <UaEorGccWizard initialValues={initialValues} onFinish={handleStopMachine} onCancel={handleCancelMachine} onPreviousMachine={handlePrevMachine}/>
  );
};

export default UaEorGccSubMachineStep;

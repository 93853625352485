import { useMemo } from 'react';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import AddForm from '../steps/AddForm';

const useSteps = () => {

  return useMemo(() => ({
    AddForm: {
      render: AddForm,
    },
    setInitialData: {
      render: TemplateWizardSkeletonMachine,
    },
    SaveStep: {
      render: TemplateWizardSkeletonMachine,
    }

  }), []);
}

export default useSteps;

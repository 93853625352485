import React, { FC, useRef } from 'react';
import * as Yup from 'yup';
import Form from "../../../../../components/forms/Form/FormL";
import SubmitButton from "../../../../generic/components/Buttons/SubmitButton";
import { CheckboxButtonOptions } from "../../../../generic/components/Inputs/CheckboxButton";
import CheckboxButtonSimple from "../../../../generic/components/Inputs/CheckboxButtonSimple";
import ControllerInput from "../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import FormProps from "../../../../generic/forms/interfaces/FormProps";
import useTranslate from "../../../../generic/hooks/useTranslate";
import TemplateFormDefault from "../../../../generic/templates/Modal";
import classes from './classes.module.scss';

const schema = Yup.object().shape({
  toContinue: Yup.boolean().nullable().required("Required"),
});

const IsThereMoreEquity: FC<FormProps> = ({
  initialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
}: FormProps) => {

  const { translate } = useTranslate();
  const refForm = useRef();

  const options: Array<CheckboxButtonOptions<boolean, string>> = [
    {
      value: false,
      label: translate("MODULES.EQUITY.FORMS.IS_THERE_MORE_EQUITY.NO"),
    },
    {
      value: true,
      label: translate(
        "MODULES.EQUITY.FORMS.IS_THERE_MORE_EQUITY.YES"
      ),
    },
  ];

  return (
    <>
      <Form
        initialValues={initialValues || {}}
        schema={schema}
        onlySendDirty={false}
        onSubmit={onCompleteSubmit}
        ref={refForm}
        optionsForm={{ mode: "onChange" }}
      >
        {" "}
        <Template
          buttons={{
            ...buttons,
            submit: <SubmitButton {...buttonSubmitProps}>Next</SubmitButton>,
          }}
          props={propsTemplate}
        >
          <ControllerInput
            as={CheckboxButtonSimple}
            name="toContinue"
            options={options}
            className={classes.CheckboxButton}
            classNameButton={classes.buttonCheckBox}
          />
        </Template>
      </Form>
    </>
  );
};

export default IsThereMoreEquity;

import React from 'react'
import {Field} from "formik";
import {TextAreaInput, TextInput} from "../../../../../../../v1/components";
import classes from "../Styles.module.scss";
import ClaraCountrySelector from "../../../../../../../v1/components/Form/ClaraCountrySelector/ClaraCountrySelector";
import PhoneInputFormik from "../../../../../../../modules/generic/components/Inputs/PhoneInputFormik"

const EditStakeholderDetails=({stakeholderField,stakeholderTitle, tooltips,...props}) => {
    return (<>
        <h1 className={classes.title}>
            Edit Details
        </h1>
        <Field name={`stakeholder.fullName`}>
            {({ field, form }) => (
                <TextInput
                    type='text'
					required={true}
                    classNameInput={{ root: classes.ContainerRow }}
                    label={'Full legal name '}
                    field={field}
                    form={form}
                    placeholder={'Name'}
                    tooltip={tooltips[`${stakeholderTitle}'s Name`]}
                />
            )}
        </Field>
        <div className={` ${classes.Select}`}>
            <Field name={`stakeholder.nationality`}>
                {({ field, form }) => {
                    return (
                        <ClaraCountrySelector
							isRequired={{ country: true, jurisdiction: false, otherJurisdiction: false }}
                            countryField={`stakeholder.nationality`}
                            label={'Nationality '}
                            returnCountryAsString={true}
                            setFieldValue={form.setFieldValue}
                            additionalStyle={'ClaraCountrySelectorModal'}
                            tooltips={{
                                country: tooltips['Nationality'],
                            }}
                        />
                    )
                }
                }
            </Field>
        </div>
        <Field name={`stakeholder.passportNumber`}>
            {({ field, form }) => (
                <TextInput
                    required={true}
                    type='text'
                    classNameInput={{ root: classes.ContainerRow }}
                    label={'Passport number '}
                    field={field}
                    form={form}
                    placeholder={"Passport number"}
                    tooltip={tooltips['Passport number']}
                />
            )}
        </Field>
        <div className={`${classes.Select}`}>
            <Field name='stakeholder.address.country'>
                {({ field, form }) => (
                    <ClaraCountrySelector
                        isRequired={{ country: true, jurisdiction: false, otherJurisdiction: false }}
                        jurisdictionsEnabled={false}
                        countryField={'stakeholder.address.country'}
                        label={'Residential address'}
                        setFieldValue={form.setFieldValue}
                        returnCountryAsString={true}
                        additionalStyle={'ClaraCountrySelectorModal'}
                        tooltips={{
                            country: tooltips['Residential Address - Country']
                        }}
                    />
                )}
            </Field>
        </div>
        <div className={classes.ContainerRow}>
            <Field name='stakeholder.address.street'>
                {({ field, form }) => (
                    <TextAreaInput
                        type='text'
                        label={' '}
                        field={field}
                        form={form}
                        placeholder={'Address'}
                        tooltip={tooltips['Residential Address - Address']}
                    />
                )}
            </Field>
        </div>
        <Field name='stakeholder.address.city'>
            {({ field, form }) => (
                <TextInput
                    type='text'
                    label=' '
                    classNameInput={{ root: classes.ContainerRow }}
                    field={field}
                    form={form}
                    placeholder={'City'}
                    tooltip={tooltips['Residential Address - City']}
                />
            )}
        </Field>
        <Field name='stakeholder.address.state'>
            {({ field, form }) => (
                <TextInput
                    type='text'
                    classNameInput={{ root: classes.ContainerRow }}
                    label=' '
                    field={field}
                    form={form}
                    placeholder={'State / Region'}
                    tooltip={tooltips['Residential Address - State']}
                />
            )}
        </Field>
        <Field name='stakeholder.address.zipCode'>
            {({ field, form }) => (
                <TextInput
                    type='text'
                    label=' '
                    field={field}
                    classNameInput={{ root: classes.ContainerRow }}
                    form={form}
                    placeholder={"Post code"}
                    tooltip={tooltips['Residential Address - Post Code']}
                />
            )}
        </Field>
		<Field name={`stakeholder.email`}>
            {({ field, form }) => (
                <TextInput
                    classNameInput={{ root: classes.ContainerRow }}
                    type='email'
                    label='Email address '
                    field={field}
                    form={form}
                    placeholder={'Email address'}
                    required={true}
                    tooltip={tooltips[`${stakeholderTitle}'s Email Address`]}
                />
            )}
        </Field>
        <Field name={`stakeholder.phoneNumber`}>
            {({ field, form }) => (
                <PhoneInputFormik
                    classNameInput={{ root: classes.ContainerRow }}
                    label='Telephone number '
                    field={field}
                    form={form}
                    required={true}
                    value={field.value}
                    disableDropdown={false}

                />
            )}
        </Field>
    </>)
};
export default EditStakeholderDetails;

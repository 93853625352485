import React, { useEffect } from 'react';
import classes from './AddGroupInput.module.scss';
import RowAdd from '../../forms/contents/RowAdd/RowAdd';
import ControllerInput from "../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput"
import LinkButton from "../../generic/Buttons/LinkButton/LinkButton"
import Text from "../../text/Text/Text"
import Icon from "../../../modules/generic/components/Icon";
import _ from 'lodash';

const AddGroupInput = ({ name, components,
    errorMessage, error,
    buttonAdd = "GENERIC_BUTTON_ADD_GROUP",buttonRemove= "GENERIC_BUTTON_REMOVE_GROUP",
    fields, append, remove, min=0,...props }) => {

    
    useEffect(()=>{
        
        for (var i = fields.length; i < min; i++) {
            append({})
         }
    },[])
    return (
        <div className={classes.AddGroupInput} >
            {fields.map((item, index) => (
                <div key={item.id} className={classes.AddGroupFieldInput}>
                    {fields.length > min  && (
                    <LinkButton className={classes.LinkRemove} onClick={() => { remove(index); }}>
                        <React.Fragment>
                            {(typeof buttonRemove == "function") ?
                                buttonRemove :
                                (<React.Fragment>
                                    <Icon icon={"minus_circle"} size={"1rem"} /> <Text uuid={buttonRemove} />
                                </React.Fragment>)
                            }
                        </React.Fragment>
                    </LinkButton>
                    )}
                    {components.map((component, i) => {
                        const { label, ...controllerProps } = component;
                        return (<React.Fragment key={i}>
                            <RowAdd
                                label={label}
                                paramLabel={{number:index+1}}
                                input={
                                    <ControllerInput
                                        {...controllerProps}
                                        defaultValue={_.get(item, component.name, "")}
                                        name={`${name}[${index}].${component.name}`}
                                    />
                                }
                            />
                        </React.Fragment>)
                    })}
                </div>
            ))}
            <LinkButton className={classes.LinkAdd} onClick={() => { append({}); }}>
                <React.Fragment>
                    {(typeof buttonAdd == "function") ?
                        buttonAdd :
                        (<React.Fragment>
                            <Icon icon={"plus_circle"} size={"1rem"} /> <Text uuid={buttonAdd} />
                        </React.Fragment>)
                    }
                </React.Fragment>
            </LinkButton>
            {/* <ErrorInput message={errorMessage} component={error} /> */}
        </div>
    )
}


export default AddGroupInput
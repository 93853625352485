import _ from "lodash";
import { useMemo } from "react";
import { assign } from 'xstate';

const useActions = () => {
  return useMemo(() => ({
    setContextAfterFirstInvoke: assign((context, event) => {
      const values = _.get(event, 'data', []);
      const newContext = {
        ...context,
        allFounders: values,
      }
      return newContext;
    }),
    setStakeholderNotice: assign((context, event) => {
      const filterStakeholder = _.filter(_.get(context, "allFounders.stakeholders", {}), (item) => {
        return item.id === _.get(event, "payload.values.confirm", "");
      });
      return {
        ...context,
        resultChoice: filterStakeholder,
      };
    }),
  }), []);
}

export default useActions;
import React, { useEffect, useMemo, useState } from "react";

import Button from "src/modules/generic/components/Buttons/Button";
import { Constants } from "src/v1/utils/constants";
import DetailsTabTitle from "src/components/templates/DetailsTabTitle";
import Divider from "@mui/material/Divider";
import EditNotices from "src/components/modules/clara/startups/groupCompany/Modals/EditNotices/EditNotices";
import PincasIcon from "src/modules/generic/components/Icon/PincasIcon";
import PropTypes from "prop-types";
import { RoleAccess } from "src/v1/components";
import Stakeholder from "src/components/modules/clara/startups/entities/classes/Stakeholder";
import TwoColumnsViewer from "src/modules/generic/components/TwoColumsViewer";
import _ from "lodash";
import classes from "../styles.module.scss";
import useModal from "src/modules/generic/hooks/useModal";
import useTranslate from "src/modules/generic/hooks/useTranslate";

function NoticesPanel({ data, isLock, isStakeholder }) {
  const { translate } = useTranslate();
  const { openModal, closeModal } = useModal();
  const [itemsCol1, setItemsCol1] = useState([]);
  const [itemsCol2, setItemsCol2] = useState([]);

  const NoticesButtons = (
    <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
      <Button
        variant="card"
        disabled={isLock}
        children={translate("VIEW_COMPANY_TAB_BUTTON_DETAILS_EDIT")}
        onClick={() => {
          openModal(EditNotices, {
            handleCloseModal: closeModal,
            isStakeholder,
          });
        }}
      />
    </RoleAccess>
  );

  const stakeholderClass = useMemo(() => {
    if (!data) return null;
    return new Stakeholder(data);
  }, [data]);

  useEffect(() => {
    let newItemsCol1 = [];
    let newItemsCol2 = [];
    newItemsCol1.push({
      label: "Name",
      value: _.get(data, "recipientNotice.fullName", "-"),
      valuePrefix: stakeholderClass?.getFieldInMatter(
        "recipientNotice.fullName"
      ) ? (
        <PincasIcon />
      ) : null,
      disabledField: stakeholderClass?.getFieldInMatter(
        "recipientNotice.fullName"
      ),
    });
    newItemsCol1.push({
      label: "Email",
      value: _.get(data, "recipientNotice.email", "-"),
      valuePrefix: stakeholderClass?.getFieldInMatter(
        "recipientNotice.email"
      ) ? (
        <PincasIcon />
      ) : null,
      disabledField: stakeholderClass?.getFieldInMatter(
        "recipientNotice.email"
      ),
    });
    newItemsCol2.push({
      label: "Address",
      value: (
        <>
          {_.get(data, "recipientNotice.useRegisteredAddress", false) ? (
            <div>
              The address for the recipient is the same as the registered
              address
            </div>
          ) : (
            <>
              <div>{_.get(data, "recipientNotice.address.street", "-")}</div>
              <div>{_.get(data, "recipientNotice.address.city")}</div>
              <div>{_.get(data, "recipientNotice.address.state")}</div>
              <div>{_.get(data, "recipientNotice.address.zipCode")}</div>
              <div>{_.get(data, "recipientNotice.address.country.name")}</div>
            </>
          )}
        </>
      ),
      valuePrefix: stakeholderClass?.getFieldInMatter(
        "recipientNotice.address.street"
      ) ? (
        <PincasIcon></PincasIcon>
      ) : null,
      disabledField: stakeholderClass?.getFieldInMatter(
        "recipientNotice.address.street"
      ),
    });

    setItemsCol1(newItemsCol1);
    setItemsCol2(newItemsCol2);
  }, [data]);

  return (
    <>
      <Divider className={classes.divider} />
      <DetailsTabTitle title="Notices" buttons={NoticesButtons} />
      <TwoColumnsViewer itemsCol1={itemsCol1} itemsCol2={itemsCol2} />
    </>
  );
}

NoticesPanel.propTypes = {
  data: PropTypes.any,
  isLock: PropTypes.bool,
  isStakeholder: PropTypes.bool,
};

export default NoticesPanel;

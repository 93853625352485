import React from "react";
import useTranslate from "../../../generic/hooks/useTranslate";
import Form from "../../../../components/forms/Form/Form";
import * as Yup from "yup";
import ControllerInput from "../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import TextInput from "../../../../components/inputs/Text/TextInput";
import TextareaInput from "../../../../components/inputs/Textarea/TextareaInput";
import Botonera from "../../../../components/forms/contents/Botonera/Botonera";
import SubmitButton from "../../../../components/generic/Buttons/SubmitButton/SubmitButton";
import RowAdd from "../../../../components/forms/contents/RowAdd/RowAdd";
import classes from "./classes.module.scss";
import { Constants } from "../../../../v1/utils/constants";
import useSendEmailToInfoClaraFormation from "../../hooks/useSendEmailToInfoClaraFormation";

const FormJurisdictionDetails = ({onCompleteSubmit, children, ...props}) => {

  const { translate } = useTranslate();
  const [send, loading] = useSendEmailToInfoClaraFormation({
    showSuccessNotification: false,
    onCompleted: onCompleteSubmit,
  });

  const jurisdictionDetailsSchema = Yup.object().shape({
    name: Yup.string().required(
      translate("MODULES.FORMATION.FORM_JURISDICTION_VALIDATION")
    ),
    email: Yup.string()
      .lowercase()
      .matches(Constants.REGEXP.EMAIL, {
        message: translate(
          "MODULES.FORMATION.FORM_JURISDICTION_VALIDATION_EMAIL"
        ),
        excludeEmptyString: true,
      })
      .nullable()
      .required(translate("MODULES.FORMATION.FORM_JURISDICTION_VALIDATION")),
    contact: Yup.string()
      .required(translate("MODULES.FORMATION.FORM_JURISDICTION_VALIDATION"))
      .nullable()
      .matches(Constants.REGEXP.PHONE, translate("MODULES.FORMATION.FORM_JURISDICTION_VALIDATION_PHONE")),
  });

  const handleOnSubmit = (values) => {
    const sendValues = {
      data:{
        fullName: values.name,
        email: values.email,
        contactNumber: values.contact,
        anyOtherInfo: values.any,
        country: props.params.country,
        jurisdiction: props.params.jurisdiction,
      }
    }
    send(sendValues);
  };

  return (
    <div>
      <Form
        initialValues={{}}
        schema={jurisdictionDetailsSchema}
        onSubmit={handleOnSubmit}
      >
        <RowAdd
          label={"MODULES.FORMATION.FORM_JURISDICTION_DETAILS_NAME"}
          input={
            <ControllerInput
              as={TextInput}
              name="name"
              placeholder={translate("MODULES.FORMATION.FORM_TYPE_HERE")}
            />
          }
        />
        <RowAdd
          label={"MODULES.FORMATION.FORM_JURISDICTION_DETAILS_EMAIL"}
          input={
            <ControllerInput
              as={TextInput}
              name="email"
              placeholder={translate("MODULES.FORMATION.FORM_TYPE_HERE")}
            />
          }
        />
        <RowAdd
          label={"MODULES.FORMATION.FORM_JURISDICTION_DETAILS_CONTACT_NUMBER"}
          input={
            <ControllerInput
              as={TextInput}
              name="contact"
              placeholder={translate("MODULES.FORMATION.FORM_TYPE_HERE")}
              // mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            />
          }
        />
        <RowAdd
          label={"MODULES.FORMATION.FORM_JURISDICTION_DETAILS_OTHER_INFO"}
          className={classes.labelSpecial}
          input={
            <ControllerInput
              as={TextareaInput}
              name="any"
              placeholder={translate("MODULES.FORMATION.FORM_TYPE_HERE")}
            />
          }
        />
        <Botonera
          className={classes.buttonsContainer}
        >
          {children}
          <SubmitButton>
            {translate("MODULES.FORMATION.FORM_JURISDICTION_BTN_SUBMIT")}
          </SubmitButton>
        </Botonera>
      </Form>
    </div>
  );
};

export default FormJurisdictionDetails;

import React from "react";
import * as Yup from "yup";
import Form from "../../../../components/forms/Form/Form";
import useTranslate from "../../../generic/hooks/useTranslate";
import TextInput from "../../../../components/inputs/Text/TextInput";
import ControllerInput from "../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import TemplateLabel from "../../../generic/components/Inputs/LabelInput";
import SubmitButton from "../../../generic/components/Buttons/SubmitButton";
import FormProps from "../../../generic/forms/interfaces/FormProps";
import TemplateFormDefault from "../../../generic/templates/Modal";
import cls from "./Form.module.scss";
import { Constants } from "../../../../v1/utils";
import CheckboxBoolean from "../../../generic/components/Inputs/CheckboxBoolean";
import {
  addAssistantText,
  clearAssistatText,
} from "src/modules/generic/store/action";
import _ from "lodash";
import { useDispatch } from "react-redux";

const schema = Yup.object().shape({
  fullName: Yup.string().required("This field is required"),
  email: Yup.string()
    .required("This field is required")
    .matches(Constants.REGEXP.EMAIL, {
      message: "Invalid Email",
      excludeEmptyString: true,
    }),
  profession: Yup.boolean()
    .required("This field is required")
    .oneOf([true], "Field must be checked"),
});

const FormDetailCollaborator: React.FC<FormProps> = ({
  initialValues: defaultValues = {},
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
}) => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();

  const handleSubmit = async (values) => {
    onCompleteSubmit && (await onCompleteSubmit(values));
  };

  const handleTooltips = () => {
    dispatch(clearAssistatText());
    dispatch(
      addAssistantText(
        translate("MODULES.STAKEHOLDER.FORM.DETAILS_COLLABORATOR.TOOLTIP"),
        "alert"
      )
    );
  };

  React.useEffect(() => {
    if (!_.get(defaultValues, "email", null)) {
      handleTooltips();
    }
    return () => {
      dispatch(clearAssistatText());
    };
  }, []);

  return (
    <Form
      defaultValues={defaultValues}
      schema={schema}
      onSubmit={handleSubmit}
      onlySendDirty={false}
      optionsForm={{ mode: "onChange" }}
    >
      <Template
        buttons={{
          ...buttons,
          submit: <SubmitButton {...buttonSubmitProps}>Add</SubmitButton>,
        }}
        props={propsTemplate}
      >
        <TemplateLabel label={"Name"} className={cls.TemplateLabel} isRequired>
          <ControllerInput
            render={TextInput}
            name="fullName"
            defaultlabel={""}
            placeholder="Full legal name"
            disabled={true}
          />
        </TemplateLabel>
        <TemplateLabel label={"Email"} className={cls.TemplateLabel} isRequired>
          <ControllerInput
            render={TextInput}
            name="email"
            type={"email"}
            defaultlabel={""}
            placeholder="name@example.com"
            disabled={true}
          />
        </TemplateLabel>
        <ControllerInput
          render={CheckboxBoolean}
          label="By inviting this person as a collaborator, you’ll give them access to the entire startup profile"
          name="profession"
          defaultValue={false}
        />
      </Template>
    </Form>
  );
};

export default FormDetailCollaborator;

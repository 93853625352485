import { MachineConfig, assign } from "xstate";
import Machine from "../../../generic/components/Machine/MachineClass";
import { StepDocumentDetails, StepSelectCreationDocument, StepSelectTypeDocument } from './Steps';

export type typeFormCreation = 'GENERATE_DOCUMENT' | 'UPLOAD_DOCUMENT' | 'CANCEL_CREATION';

interface DataDocumentContext {
  select_document_creation_type: { typeFormCreation: typeFormCreation }
}

interface ContextDocument {
  [key: string]: any,
  data: DataDocumentContext
}

const isGenerateDocument = ({ data }: ContextDocument) => data.select_document_creation_type.typeFormCreation === 'GENERATE_DOCUMENT';
const isCancelCreationDocument = ({ data }: ContextDocument) => data.select_document_creation_type.typeFormCreation === 'CANCEL_CREATION';
const isUploadDocument = ({ data }: ContextDocument) => data.select_document_creation_type.typeFormCreation === 'UPLOAD_DOCUMENT';

const setDocumentType = assign((context: ContextDocument, { payload }: any) => {
  const { paramsData } = context;
  const { values: { type } } = payload;
  return { ...context, paramsData: { ...paramsData, type } };
});

const machineDefinition: MachineConfig<ContextDocument, any, any> = {
  id: 'create_employee',
  initial: 'select_document_creation_type',
  states: {
    select_document_creation_type: {
      on: {
        NEXT: { target: 'loading', actions: 'setContext' },
        CANCEL: 'finish_upload_document',
      }
    },
    loading: {
      always: [
        { cond: 'isGenerateDocument', target: 'select_type_doc_employee' },
        { target: 'finish_upload_document', cond: 'isCancelCreationDocument' },
        { target: 'details_document', cond: 'isUploadDocument' },
      ]
    },
    select_type_doc_employee: {
      on: {
        NEXT: 'finish_upload_document',
        CANCEL: 'finish_upload_document',
      },
    },
    details_document: {
      on: {
        PREVIOUS: { target: 'select_document_creation_type' },
        NEXT: { target: 'finish_upload_document', actions: 'setContext' },
        CANCEL: 'finish_upload_document',
      },
    },
    finish_upload_document: {
      type: 'final',
      entry: 'final',
    },
    onDone: {
      actions: 'stopMachine'
    }
  },
}

const steps = {
  select_type_doc_employee: StepSelectTypeDocument,
  details_document: StepDocumentDetails,
  select_document_creation_type: StepSelectCreationDocument
}

const options = {
  guards: {
    isGenerateDocument,
    isCancelCreationDocument,
    isUploadDocument
  },
  actions: {
    setDocumentType
  }
}

export default class MachineCompany extends Machine {
  constructor() {
    super(machineDefinition, options, steps);
  }
  getParamsData({ paramsMutation }) {
    return { ...paramsMutation }
  }
  getInitialValues({ initialValues = {} }) {
    return initialValues
  }
}



import get from 'lodash/get';
import useAddDocumentFormation from "src/hooks/services/modules/individualStakeholder/useAddDocumentFormation";
import useSession from "src/modules/session/hooks/useSession";
import { stripTypenames } from 'src/v1/utils/graphql-util';
import useUpdateStakeholderOtherNationality from "../../../graphql/mutation/useUpdateStakeholderOtherNationality";
import useGetStakeholderOtherNationalityById from "../../../graphql/queries/useGetStakeholderOtherNationalitiesById";

const useServices = ({ initialValues }) => {
  const { startupId } = useSession();
  const [updateDocument] = useAddDocumentFormation({});
  const updateStakeholderOtherNationality = useUpdateStakeholderOtherNationality();
  const { manualQuery } = useGetStakeholderOtherNationalityById();

  const uploadDocument = async (context) => {
    const passportFile = get(context, "UploadPassport");
    const stakeholderId = get(context, "initialData.stakeholderId");
    // TODO: get from constants
    const documentType = "PASSPORT_DOCUMENT"

    const variables = {
      stakeholderId: stakeholderId,
      startupId,
      identityDocumentData: {
        ...passportFile,
        nationality: get(context, "ConfigurePassport.nationality"),
        number: get(context, "ConfigurePassport.number"),
        expiryDate: get(context, "ConfigurePassport.expiryDate"),
        type: documentType
      },
    };
    await updateDocument({ variables });
  }

  const updateStakeholder = async (context) => {
    const stakeholderId = get(context, "initialData.stakeholderId");
    const initialNationalities = get(context, "initialData.nationalities", []);
    const country = get(context, 'initialData.country');

    const variables = {
      startupId,
      stakeholderId,
      stakeholderData: {
        stakeholder: {
          nationalities: {
            none: false,
            countries: initialNationalities.map(nationality => nationality.country.code === country.code ? { country: country.code, hasPassport: { description: get(context, 'IsTherePassport.reason'), question: get(context, 'IsTherePassport.isThereOtherPassport') } } : { ...stripTypenames(nationality), country: nationality.country.code })
          }
        }
      }
    };
    await updateStakeholderOtherNationality(variables);
  }

  return {
    initialData: async () => {
      const stakeholderId = get(initialValues, "stakeholderId");
      const stakeholder = await manualQuery({ variables: { startupId, stakeholderId } });
      return { ...initialValues, nationalities: get(stakeholder, 'data.getStakeholder.nationalities.countries') };
    },
    save: async (context) => {
      const isTherePassport = get(context, "IsTherePassport.isThereOtherPassport");
      if (isTherePassport) {
        await uploadDocument(context);
      }
      await updateStakeholder(context);
    }
  }
}

export default useServices;
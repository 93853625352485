const useGuards = (initialValues) => {
  return {
      isIncorporated:()=>initialValues.isIncorporated,
      isNotIncorporated:()=>!initialValues.isIncorporated,
      thereIsSecondary:(context)=>context?.IsThereSecondarySignatoryStep?.isThereSecondary === true || context?.IsThereSecondarySignatoryStep?.isThereSecondary === "true",
      thereIsNotSecondary:(context)=>!context?.IsThereSecondarySignatoryStep?.isThereSecondary || context?.IsThereSecondarySignatoryStep?.isThereSecondary === "false",
      isSecondaryFlow: (context)=>context?.IsThereSecondarySignatoryStep?.isThereSecondary === true || context?.IsThereSecondarySignatoryStep?.isThereSecondary === "true",
      isNotSecondaryFlow:(context)=>!context?.IsThereSecondarySignatoryStep?.isThereSecondary || context?.IsThereSecondarySignatoryStep?.isThereSecondary === "false",
      isSecondarySignor:(context)=>{
        const nomineeStep = context?.ClaraNomineeStep;
        return  (
          nomineeStep?.useNominee === true || nomineeStep?.useNominee === "true"
        )
      },
      hasNotGCCOrUAE:(context)=>context?.initialData?.stakeholdersNotUAEOrGCC.length>0,
      hasNotNotGCCOrUAE:(context)=>!context?.initialData?.stakeholdersNotUAEOrGCC.length>0,
      isNotSecondarySignor:(context)=>{
        const nomineeStep = context?.ClaraNomineeStep;
        return (
          nomineeStep?.useNominee === false ||
          nomineeStep?.useNominee === "false"
        );
      },
      correct: (context) => {
        const confirmStep = context?.ReviewAndConfirmStep;
        return confirmStep?.confirm === true;
      },
      nomineeQuestion: (context) => {
        const confirmStep = context?.ReviewAndConfirmStep;
        return confirmStep?.confirm === false;
      },
      useNominee: (context) => {
        const nomineeStep = context?.ClaraNomineeStep;
        return (
          nomineeStep?.useNominee === true || nomineeStep?.useNominee === "true"
        );
      },
      dontUseNominee: (context) => {
        const nomineeStep = context?.ClaraNomineeStep;
        return (
          nomineeStep?.useNominee === false ||
          nomineeStep?.useNominee === "false"
        );
      },
      thirdParty: (context) => {
        const stakeholder =
          context?.AssignOtherSignatoriesStep?.authorisedSignatory ||
          context?.AssignSignatoryStep?.authorisedSignatory;
        return stakeholder?.fullName === "ADD_THIRD_PARTY";
      },
      notThirdParty: (context) => {
        const stakeholder =
          context?.AssignOtherSignatoriesStep?.authorisedSignatory ||
          context?.AssignSignatoryStep?.authorisedSignatory;
        return stakeholder?.fullName !== "ADD_THIRD_PARTY";
      },
    }
};

export default useGuards;

import React, {FC, useEffect, useMemo, useRef} from 'react'
import _ from 'lodash'
import useTranslate from "../../../../generic/hooks/useTranslate";
import Form from "../../../../../components/forms/Form/Form";
import ControllerInput from "../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import Checkbox from '../../../../generic/components/Inputs/Checkbox'
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import TemplateFormDefault from "../../../../generic/templates/Modal";
import FormProps from "../../../../generic/forms/interfaces/FormProps";
import TemplateLabel from "../../../../generic/components/Inputs/LabelInput";
import classes from './classes.module.scss'
import { GroupCompanyBO } from "../../../../../modules/startup/core/type/startup/groupCompany";
import * as  Yup from 'yup'
const schema=Yup.object().shape({
    companies:Yup.array().required('Required').min(1)
})
interface SelectCompaniesProps extends FormProps{
    groupCompaniesList:Array<GroupCompanyBO>
    disabledCondition:(gc:GroupCompanyBO)=>boolean
}
const SelectCompanies: FC<SelectCompaniesProps> = ({initialValues,disabledCondition, groupCompaniesList, onCompleteSubmit, Template = TemplateFormDefault, buttons = {}, buttonSubmitProps, propsTemplate}:SelectCompaniesProps) => {
    const { translate } = useTranslate()
    const groupCompanies=useMemo(()=>{
        let companies = [];
        _.forEach(groupCompaniesList,(gc:GroupCompanyBO)=>{
            if (!gc.isLocked){
                const c = {value:gc,label:gc.name}
                if (disabledCondition){
                    c['disabled']=disabledCondition(gc)
                }
                companies.push(c);
            }
        })
        return companies;
    },[JSON.stringify(groupCompaniesList)])


    // console.log(groupCompanies, 'thid id companyes');

    const refForm=useRef()
    useEffect(()=>{
        if (refForm && refForm?.current){
            if (groupCompaniesList.length === 1){
                if (!disabledCondition ||!disabledCondition(groupCompaniesList[0])){
                    refForm.current.setValue('companies',[groupCompaniesList[0]])
                    refForm.current.trigger('companies')
                }
                
            }

        }
        
    },[refForm,initialValues,JSON.stringify(groupCompaniesList)])
    
    return (
        <>
        <Form
                   defaultValues={initialValues}
                    schema={schema}
                    onlySendDirty={false}
                    onSubmit={onCompleteSubmit}
                    ref={refForm} optionsForm = {{mode:"onChange"}}
                    
                >
                    <Template
                        buttons={{ ...buttons, submit: <SubmitButton {...buttonSubmitProps}>Next</SubmitButton>}}
                        props={propsTemplate}
                    >
                        <TemplateLabel className={classes.checkBox} label={translate("MODULES.EQUITY.FORMS.SELECT_COMPANIES.COMPANIES")} isRequired={true}>
                            <ControllerInput
                                render={Checkbox}
                                name='companies'
                                variant='col_1'
                                functionCompare={(value1,value2)=>value1.id===value2.id}
                                options={groupCompanies}
                            />
                        </TemplateLabel>
                    </Template>
                </Form>
        </>
    )
}

export default SelectCompanies

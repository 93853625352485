import useCustomMachine from "../../../../../generic/hooks/useCustomMachine";
import React, { FC, useEffect } from "react";
import Button from "../../../../../generic/components/Buttons/Button";
import { StepProps } from "../../../../../generic/components/Wizard/Step";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import FormAfilliatedCompany from "../../../Forms/FormAfilliatedCompany";
import { useDispatch } from "react-redux";
import {
  addAssistantText,
  clearAssistatText,
} from "../../../../../generic/store/action";
import useUpsertFormationCompany from "../../../graphql/mutations/useUpsertFormationCompany";
import useSession from "../../../../../session/hooks/useSession";
import { useParams } from "react-router-dom";
import useModal from "../../../../../generic/hooks/useModal";

const Step1: FC<StepProps> = () => {

  const { translate } = useTranslate();
  const { next, send, state, stepData } = useCustomMachine();
  const dispatch = useDispatch();
  const [mutation] = useUpsertFormationCompany({}, {});
  const { closeModal: onClose } = useModal();
  const { startupId } = useSession();
  const { id } = useParams();

  useEffect(() => {
    dispatch(clearAssistatText());
    dispatch(
      addAssistantText(
        translate("MODULES.GROUP_COMPANY.SETUP.THIRD_STEP.TOOLTIP")
      )
    );
  }, []);

  const formatValues = (values): object => {
    const { context } = state;
    const { data } = context;
    const { name_special_meaning_form } = data;
    const { provide_translation_form } = data;
    return {
      groupCompanyId: state?.context?.data?.idGroupCompany ?? id,
      startupId: startupId,
      groupCompanyData: {
        formationData: {
          affiliatedYN: false,
          specialMeaningYN: name_special_meaning_form.isThereSpecialMeaning,
          arabicTranslationYN: provide_translation_form.isThereTranslation,
          specialMeaning: name_special_meaning_form.isThereSpecialMeaning
            ? name_special_meaning_form.descriptionOfSpecialMeaning
            : null,
          arabicTranslation: provide_translation_form.isThereTranslation
            ? provide_translation_form.translation
            : null,
        },
      },
    };
  };

  const handleCompleteSubmit = async (values) => {
    if (!values.isThereAffiliatedCompanies) {
      const variables = formatValues(values);
      await mutation(variables);
    }
    next(values);
  };

  const handleCancel = async (values) => {
    send("CANCEL");
  };

  const handlePrevious = () => send("PREVIOUS");

  return (
    <>
      <FormAfilliatedCompany
        initialValues={stepData}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: translate("MODULES.GROUP_COMPANY.SETUP.THIRD_STEP.TITLE"),
          subTitle: translate(
            "MODULES.GROUP_COMPANY.SETUP.THIRD_STEP.SUBTITLE"
          ),
        }}
        buttons={{
          previous: (
            <Button onClick={handlePrevious} variant="secondary">
              Previous
            </Button>
          ),
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              Cancel
            </Button>
          ),
        }}
      ></FormAfilliatedCompany>
    </>
  );
};

export default Step1;

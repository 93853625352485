import { useMemo } from 'react';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import AddIndividual from "../steps/AddIndividual";
import AddCorporate from "../steps/AddCorporate";
import AddDirector from "../steps/AddDirector";
import DirectorAdded from "../steps/DirectorAdded";
import LegalEntityStructure from "../steps/LegalEntityStructure";

const useSteps = () => {
  return useMemo(() => ({
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    AddIndividual: {
      render: AddIndividual,
    },
    DirectorAdded: {
      render: DirectorAdded,
    },
    AddDirector: {
      render: AddDirector,
    },
    AddCorporate: {
      render: AddCorporate,
    },
    LegalEntityStructure: {
      render: LegalEntityStructure,
    },
    SaveStep: {
      render: TemplateWizardSkeletonMachine,
    }
  }), []);
}

export default useSteps;

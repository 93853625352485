import React, {Component} from 'react';
import {Field} from "formik";
import _ from 'lodash';
import ClaraSelect from "../../../../../components/Form/ClaraSelect/ClaraSelect";
import classes from "../../../SteperForm.module.scss";
import { TextInput } from '../../../../../components';
import Checkbox from "../../../Inputs/Checkbox";
import FormikHelper from "../../../../../utils/formikHelper";
import moment from "moment";
import ClaraUpload from "../../../../../components/Form/ClaraUpload/ClaraUpload";
import ClaraCountrySelector from "../../../../../components/Form/ClaraCountrySelector/ClaraCountrySelector";
import AuthorisedSignatories from "../../../../../../components/inputs/AuthorizedSignatories/AuthorizedSignatories";

class BoardResolutionConsentSAFEDoc extends Component {

    constructor(props) {
        super(props);
        this.state = {
            typeSelected: undefined,
            stakeholderSelected: undefined,
            groupCompanySelected: undefined,
            authorizedSelected: undefined,
            authorizedSignatories: [],
            authorizedSignatoriesList: [],
            documentTypes: [],
            documentTypesList: [],
            dynamicParams: [],
            date: undefined,
            expirationDate: undefined,

        }
    };

    componentWillMount() {
        if (this.props.values) {
            const values = this.props.values.values ? this.props.values.values : this.props.values;
            const expirationDate = this.getDateForPickerFromData(values.expirationDate);
            this.setState({
                typeSelected: values.documentType,
                stakeholderSelected: values.stakeholder,
                groupCompanySelected: values.company,
                expirationDate,
                authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.company).map(as => ({
                    id: as.id,
                    label: as.label
                }))
            });


        }

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.values) {
            const values = nextProps.values.values ? nextProps.values.values : nextProps.values;
            const expirationDate = this.getDateForPickerFromData(values.expirationDate);
            this.setState({
                expirationDate,
                authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.company)
                    .map(as => ({
                        id: as.id,
                        label: as.label
                    }))
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.values) {
            const values = prevProps.values.values ? prevProps.values.values : prevProps.values;

        }
    }

    getDynamicParamsByType(documentType) {
        const type = this.props.documentTypes.filter(agrType => agrType.code === documentType)[0] || {};
        return type.fields || [];
    }

    getGroupCompanies = () => {
        let authorizedSignatories = this.props.authorizedSignatories;
        let groupCompanies = this.props.groupCompanies;
        let companies = [];
        if (groupCompanies && authorizedSignatories) {
            companies = _.filter(groupCompanies, (groupCompany) => {
                let fined = _.filter(authorizedSignatories, (authorized) => {
                    if (groupCompany.authorizedSignatories) {
                        return groupCompany.authorizedSignatories.some(au => au.stakeholder.id === authorized.id);
                    }
                    return false;
                });
                if (fined) {
                    return true;
                }
                return false;
            });
        }
        return companies;
    };

    selectGroupCompany = (gcId, extraParams, form) => {
        const groupCompany = this.findGroupCompany(gcId);

        // this is for a weird behaviour when hit edit button after preview a document
        if (this.state.groupCompanySelected && groupCompany) {
            return null;
        }

        this.setState({
            groupCompanySelected: groupCompany
        });

        if (groupCompany) {

            if (!groupCompany.registeredAddress) {
                groupCompany.registeredAddress = {}
            }

            let authorizedSignatories = _.get(form, 'values.company.authorizedSignatories', []);

            form.setValues({
                ...form.values,
                company: {
                    id: gcId,
                    name: groupCompany.name || '',
                    country:typeof  _.get(groupCompany, 'country', null) ==='object'? _.get(groupCompany, 'country.code', null) : _.get(groupCompany, 'country', null),
                    jurisdiction: groupCompany.jurisdiction,
                    registeredNumber: groupCompany.registeredNumber || '',
                    type: groupCompany.type || '',
                    registeredAddress: {
                        ...groupCompany.registeredAddress, country:typeof  _.get(groupCompany, 'registeredAddress.country', null) ==='object'? _.get(groupCompany.registeredAddress, "country.code", null) : _.get(groupCompany.registeredAddress, "country", null)
                    },
                    authorizedSignatories: authorizedSignatories
                },
                terms: {
                    ...form.values.terms,
                    currency: _.get(form, 'values.terms.currency', groupCompany.transactionCurrency)
                }
            });

            this.setState({
                groupCompanySelected: groupCompany,
            })
        } else {
            form.setValues({
                ...form.values,
                company: {}
            });
        }
    };

    findGroupCompany(gcId) {
        const gc = _.find(this.props.groupCompaniesList,{id: gcId});
        return gc ? {...gc} : null
    };

    selectStakeholder = (stakeholderId, extraParams, form, cb) => {
        const stakeholder = this.findStakeholder(stakeholderId);

        this.setState({
            stakeholderSelected: stakeholder,
        });

        if (stakeholder) {
            const _stakeholder = !_.isEmpty(form.values.stakeholder) ? form.values.stakeholder : stakeholder;

            form.setValues({
                ...form.values,
				terms: {
					...form.values.terms,
					stakeholder: {
						id: stakeholderId,
						fullName: _stakeholder.fullName || ''
					}

				}
            });
        } else {
            form.setValues({
                ...form.values,
                terms: {
					...form.values.terms,
					stakeholder: {}
				}
            });
        }
    };

    findStakeholder(roleId) {
        return this.props.stakeholderList.find(stakeholder => stakeholder.id === roleId)
    };

    findStakeholderAuthorized(roleId) {
        return this.props.stakeholderList.filter(stakeholder => {
            return stakeholder.roles.some(role => role.stakeholder.id === roleId);
        })[0] || null;
    };

    filterAuthorizedByGroupCompany(groupCompany) {
        if (groupCompany) {
            let gc = this.findGroupCompany(groupCompany.id);
            return this.props.authorizedSignatories.filter(authorized => {
                return authorized.groupCompanyId === gc.id
            });
        }
        return [];
    }

    selectAuthorized = (value, form, field, replace) => {
        const stakeholder = this.props.authorizedSignatories.find(e => e.id === value);
        const email = stakeholder ? stakeholder.email : '';
        replace(0, { id: value, email })
    };

    handleDateChange = (e, field, form) => {
        const date = moment(e).format("YYYY-MM-DD");

        FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

        this.setState({
            date: e
        })
    };

    updateEmailValue(e, form) {
        if (this.state.stakeholderSelected.isAnEntity) {
            form.setFieldValue('stakeholder.authorizedSignatory.email', e.target.value, false)
        }
    }

    getDateForPickerFromData(date) {
        if (!date) return undefined;
        return moment(date).toDate();
    }

    handleChange(e, field, form) {

        const date = e === null ? null : moment(e).format("YYYY-MM-DD");

        FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

        this.setState({
            [field.name]: e
        })
    }

    getAuthorizedSignatoriesList = (index, form) => {
        if (form.values && form.values.company) {
            return this.filterAuthorizedByGroupCompany(form.values.company).map(as => ({
                id: as.id,
                label: as.label
            }))
        } else {
            return [];
        }
    };

    getLabel = (key) => {
        switch (key) {
            case "fullName":
                if (this.state.stakeholderSelected.isAnEntity) {
                    return "Company name";
                } 
                    return "Name";
                
            case "country":
                if (this.state.stakeholderSelected.isAnEntity) {
                    return "Country of incorporation";
                } 
                    return "Nationality";
            case "address":
                if (this.state.stakeholderSelected.isAnEntity) {
                    return "Registered address";
                } else {
                    return "Residential address";
                }
            case "passportOrRegistered":
                if (this.state.stakeholderSelected.isAnEntity) {
                    return "Company number";
                } else {
                    return "Passport number";
                }
        }
    };

    getField = (key) => {
        switch (key) {
            case "passportOrRegistered":
                if (this.state.stakeholderSelected.isAnEntity) {
                    return "stakeholder.registeredNumber";
                } else {
                    return "stakeholder.passportNumber";
                }
        }
    };

    getTooltip = (key, fieldName) => {
        switch (key) {
            case "fullName":
                if (this.state.stakeholderSelected.isAnEntity) {
                    return "Investor Company's Name";
                } else {
                    return "Investor's Name";
                }
            case "passportOrRegistered":
                if (this.state.stakeholderSelected.isAnEntity) {
                    return "Company Number";
                } else {
                    return "Passport Number";
                }
            case "country":
                if (this.state.stakeholderSelected.isAnEntity) {
                    return "Country";
                } else {
                    return "Nationality";
                }
            case "addressLine":
                if (this.state.stakeholderSelected.isAnEntity) {
                    return "Registered Address - Address";
                } else {
                    return "Residential Address - Address";
                }
            case "addressCity":
                if (this.state.stakeholderSelected.isAnEntity) {
                    return "Registered Address - City";
                } else {
                    return "Residential Address - City";
                }
            case "addressState":
                if (this.state.stakeholderSelected.isAnEntity) {
                    return "Registered Address - State";
                } else {
                    return "Residential Address - State";
                }
            case "addressCountry":
                if (this.state.stakeholderSelected.isAnEntity) {
                    return "Registered Address - Country";
                } else {
                    return "Residential Address - Country";
                }
            case "addressPostCode":
                if (this.state.stakeholderSelected.isAnEntity) {
                    return "Registered Address - Post Code";
                } else {
                    return "Residential Address - Post Code";
                }
        }
    };

    getPlaceholder = (key) => {
        switch (key) {
            case "passportOrRegistered":
                if (this.state.stakeholderSelected.isAnEntity) {
                    return "Company number";
                } else {
                    return "Passport number";
                }
        }
    };

    getCurrencyLabel = (form) => {
        return _.get(form, 'values.terms.currency', '');
    };

    getStakeholderAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add new individual stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
                placeholder: "Stakeholder's name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: false,
                    // roles : [{
                    // 	role:"SHAREHOLDER",
                    // 	groupCompany: this.state.groupCompanySelected.id,
                    // }]
                },
                paramsReturn: 'isAnEntity'
            },
            {
                type: "stakeholder",
                label: "Add new corporate stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
                placeholder: "Stakeholder's name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: true,
                    // roles : [{
                    // 	role: "SHAREHOLDER",
                    // 	groupCompany: this.state.groupCompanySelected.id,
                    // }],
                },
                paramsReturn: 'isAnEntity'
            },
        ]
    };

    getCompanyAdds = () => {
        return [{
            type: "groupCompany",
            label: "Add new company",
            textWarning: "This will create a new company for your startup. You can add more details later on the company's profile page.",
            placeholder: "Company Name",
            startupId: this.props.startup.id,
            params: null,
            paramsReturn: `
				name
				authorizedSignatories {
					id
					stakeholder {
						id
						email
						fullName
						roles {   id
        name
        groupCompany { id name}
        stakeholder { id } }
					}
				}
			`
        }]
    };

    getAuthorizedSignatoryAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add new individual stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add more details later from the stakeholder's profile page.",
                placeholder: "Enter stakeholder's name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: false,
                    roles: [{
                        role: "DIRECTOR",
                        groupCompany: this.state.groupCompanySelected.id,
                    }]
                }
            },
        ]
    };

    handleStakeholderCreate = (value, name, additionalValue, form) => {

        let { stakeholders, stakeholderList } = this.props;
        let newSh = { id: value.id, fullName: name, ...additionalValue };
        stakeholderList.push(newSh);

        stakeholders.push({ id: value.id, label: name });
        // this.setState({ groupCompaniesList, groupCompanies });

    };

    handleGroupCompanyCreate = (value, name, additionalValue, form) => {

        let { groupCompanies, groupCompaniesList } = this.props;
        let newGc = { id: value.id, name, ...additionalValue };
        groupCompaniesList.push(newGc);

        groupCompanies.push({ id: value.id, label: name });

    };

    handleAddInvestor = (value)=>{

        this.handleStakeholderCreate(
			{id: value.id, type: 'stakeholder'},
			value.fullName,
			{id: value.id, isAnEntity: false}
		)

		// refactor this logic, we need using cache
		this.props.loadGroupCompanies(this.props)
        
    }

    handleAuthorizedSignatoryUpdate = (value) => {

        const { authorizedSignatories, groupCompanySelected } = this.state;
        const groupCompany = this.findGroupCompany(groupCompanySelected.id);

        _.set(groupCompany,'authorizedSignatories',_.map(groupCompany.authorizedSignatories,auth=>({...auth})))
        groupCompany.authorizedSignatories.push({ stakeholder: value });
        authorizedSignatories.push(value);

		this.handleStakeholderCreate(
			{id: value.id, type: 'stakeholder'},
			value.fullName,
			{id: value.id, isAnEntity: false}
		)

		// refactor this logic, we need using cache
		this.props.loadGroupCompanies(this.props)

    };

    render() {
        const isRequired = message => value => (!!value ? undefined : message);
        const { tooltips = {}, formType } = this.props;

        return (
            <div>
                <div className={classes.ContainerRow}>
                    {formType === 'uploadToEdit' ? (<div>
                        <Field name={`file`}>
                            {({ field, form }) => (
                                <ClaraUpload
                                    required={true}
                                    name={`filepond`}
                                    label='Upload your document '
                                    field={field}
                                    form={form}
                                />
                            )}
                        </Field>
                    </div>) : null}
                    <Field name='agreementName'>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='text'
                                label='Document name '
                                field={field}
                                form={form}
                                placeholder={"Document name"}
                                tooltip={tooltips['Document Name']}
                            />
                        )}
                    </Field>
                </div>
				<hr className={classes.Separator} />
				<div className={`${classes.ContainerRow} ${classes.Select}`}>
					<Field name='company.id'>
						{({ field, form }) => (
							<ClaraSelect
								required={true}

								field={field}
								form={form}

								label={"Select company "}
								adds={this.getCompanyAdds()}
								lists={this.getGroupCompanies()}
								mode={"classic"}
								callbacks={{
									createValues: this.handleGroupCompanyCreate,
									updateValues: this.selectGroupCompany
								}}
								placeholder={"Select from the list"}
								tooltip={tooltips['Select Company']}
							/>
						)}
					</Field>
				</div>
				{
					this.state.groupCompanySelected ?
						<React.Fragment>
							<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
								<h1>Company Details</h1>
								<p>Please verify or update the company's information below</p>
							</div>
							<div className={`${classes.ContainerRow} ${classes.Select}`}>
								<Field name='company.country'>
									{({ field, form }) => (
										<ClaraCountrySelector
											jurisdictionsEnabled={true}
											isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
											countryField={'company.country'}
											label={'Country of incorporation '}
											jurisdictionField={'company.jurisdiction'}
											jurisdictionLabel={'Jurisdiction '}
											returnCountryAsString={true}
											otherJurisdictionField={'company.otherJurisdiction'}
											setFieldValue={form.setFieldValue}
											initialValues={{ jurisdiction:_.get(form.values, 'company.otherJurisdiction') || _.get(form.values, 'company.jurisdiction') }}
											additionalStyle={'ClaraCountrySelectorWizard'}
											tooltips={{
												country: tooltips["Company's Country"],
												jurisdiction: tooltips["Company's Jurisdiction"],
												other: tooltips['Other Jurisdiction']
											}}
										/>
									)}
								</Field>
							</div>
							<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
								<h1>Directors</h1>
								<p>Select the directors that will sign this board resolution. You can add them individually, or all together, or add new directors.</p>
							</div>
							<Field name='company.authorizedSignatories_error'>
								{({ field, form }) => (
									<React.Fragment>
                                        <span hasError={_.get(form.errors, "company.authorizedSignatories") ? "true" : "false"}>
                                            <AuthorisedSignatories
												selectInputLabel={'Select director'}
                                                list={this.props.stakeholderList}
												fieldArrayName={'company.authorizedSignatories'}
												idGroupCompany={this.state.groupCompanySelected.id}
												handleAddListStakeholder={this.handleAuthorizedSignatoryUpdate}
												errorMessage={_.get(form.errors, "company.authorizedSignatories")}
											/>
                                        </span>
									</React.Fragment>
								)}
							</Field>
						</React.Fragment>
						:
						null
				}
                {
                    this.state.groupCompanySelected ?
                        <React.Fragment>
							{
								this.props.typeSelected === 'USA_BOARD_CONSENT_SAFE_OTHER' &&
									<>
										<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
											<h1>Convertible Type</h1>
											<p>The type of convertible being approved by the directors.</p>
										</div>
										<div className={classes.ContainerRow}>
											<Field name='terms.convertibleType'>
												{({ field, form }) => (
													<TextInput
														required={true}
														type='text'
														label='Convertible type '
														field={field}
														form={form}
														placeholder={"Type here"}
														tooltip={tooltips["Convertible Type"]}
													/>
												)}
											</Field>
										</div>
										<hr className={classes.Separator}/>
									</>
							}
                              <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
								<h1>Amount</h1>
								<p>The aggregate amount the investors are providing to the company.</p>
							</div>
                            <div className={classes.ContainerRow }>
							<Field name='terms.amount'>
								{({ field, form }) => (
									<TextInput
                                    required={true}
                                    type='number'
                                    label='Amount'
                                    field={field}
                                    leftLabel={'USD'}
                                    form={form}
                                    placeholder={"Amount"}
                                    tooltip={tooltips['Amount']}
                                />
								)}
							</Field>
                            </div>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
								
								<p>Please note: Once you have signed this board resolution, please attach a copy of the template convertible you are approving to the end of the resolution and store them together in one file.</p>
							</div>
                        </React.Fragment>
                        : null
                }
                {formType === 'uploadToEdit' ? <div>
                    <Field name='confirmSameData'>
                        {({ field, form }) => (
                            <Checkbox
                                className={classes.sameDataCheckbox}
                                field={field}
                                validate={isRequired('This field is required')}
                                form={form}
                                required={true}
                                label='All document details are verified and correct.'
                            />
                        )}
                    </Field>
                </div> : null}
            </div>
        );
    }
}

export default BoardResolutionConsentSAFEDoc;

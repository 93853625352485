import React, { FC } from 'react';
import classesModule from './classes.module.scss';
import TemplateWizardSkeleton from "./TemplateWizardSkeleton";

export interface TemplateWizardSkeletonMachineProps {
  skeleton?: React.ReactNode
  subTitle?: string,
  title?: string,
  className?: string;
}


/**
 - Template to Form
 **/

const TemplateWizardSkeletonMachine: FC<TemplateWizardSkeletonMachineProps> = ({
  className,
  skeleton ,
  subTitle ,
  title,
}: TemplateWizardSkeletonMachineProps) => {


  return (
    <React.Fragment>
      <div className={`${classesModule.TemplateWizard} ${className}`}>
          <TemplateWizardSkeleton
            skeleton={skeleton}
            subTitle={subTitle}
            title={title}
          />
      </div>
    </React.Fragment>
  )
}

export default TemplateWizardSkeletonMachine

import React, { FC } from "react";
import useSession from "src/modules/session/hooks/useSession";
import AcceleratorDashboardView from "./components/AcceleratorDashboardView";
import VerificationView from "./components/VerificationView";

export interface AcceleratorsProps {}

const Accelerators: FC<AcceleratorsProps> = ({}: AcceleratorsProps) => {
  const { startup } = useSession();

  return (
    <>
      {startup.acceleratorDef ? (
        <AcceleratorDashboardView />
      ) : (
        <VerificationView />
      )}
    </>
  );
};

export default Accelerators;

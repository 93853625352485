import _ from "lodash";
import React, { Component } from "react";
import classes from "./Overview.module.scss";
import ZoneChartRun from "./ZoneChartRun";
class Category extends Component {
  state = {
    colors: ["#71e096", "#fbbd2c", "#ff606f"],
    options: {
      legend: {
        display: false
      },
      scale: {
        ticks: {
          display: false
        },
        gridLines: {
          circular: true
        }
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            var label = data.datasets[tooltipItem.datasetIndex].label || "";

            if (label) {
              label += ": ";
            }
            label += Math.round(tooltipItem.yLabel * 100) / 100;
            return label;
          }
        }
      }
    },
    totalStatus: {
      RED: 0,
      AMBER: 0,
      GREEN: 0,
      UNKNOWN: 0
    },
    totalAll: 0
  };
  getCircles(issues) {
    if (issues) {
      return issues.map((issue, i) => {
        const className = classes["Circle" + issue.status];
        return <span key={i} className={className}></span>;
      });
    } else {
      return null;
    }
  }
  getSubCategoies(category) {
    if (category) {
      return category.map((subcategory, i) => {
        return (
          <div key={i} className={classes.Row}>
            <a href="#">{subcategory.name}</a>
            <div className={classes.Circles}>
              {this.getCircles(subcategory.issues)}
            </div>
          </div>
        );
      });
    } else {
      return null;
    }
  }
  update() {
    let labels = [];
    let totalRed = [];
    let totalGreen = [];
    let totalYellow = [];
    let totalGrey = [];
    let totals = {};
    let totalAll = 0;
    let totalStatus = {
      RED: 0,
      AMBER: 0,
      GREEN: 0,
      UNKNOWN: 0
    };
    _.forEach(this.props.data, (category, key) => {
      if (!totals[key]) {
        totals[key] = {
          RED: 0,
          AMBER: 0,
          GREEN: 0,
          UNKNOWN: 0
        };
      }
      _.forEach(category, (subCategory, keysubCategory) => {
        _.forEach(subCategory.issues, (issue, keyIssue) => {
          totals[key][issue.status]++;
          totalStatus[issue.status]++;
          totalAll++;
        });
      });
    });
    _.forEach(this.props.categories, (category, key) => {
      labels.push(category.title);
      if (!totals[category.key]) {
        totalRed.push(0);
        totalYellow.push(0);
        totalGreen.push(0);
        totalGrey.push(0)
      } else {
        totalRed.push(totals[category.key].RED);
        totalYellow.push(totals[category.key].AMBER);
        totalGreen.push(totals[category.key].GREEN);
        totalGrey.push(totals[category.key].UNKNOWN);
      }
    });
    const data = {
      labels: labels,
      datasets: [
        {
          label: "Green",
          backgroundColor: "rgba(113, 224, 150, 0.4)",
          borderColor: "#71E096",
          pointBackgroundColor: "#71E096",
          pointRadius: 2,
          data: totalGreen
        },
        {
          label: "Yellow",
          backgroundColor: "rgba(251, 189, 44, 0.3)",
          borderColor: "#FBBD2C",
          pointBackgroundColor: "#FBBD2C",
          pointRadius: 2,
          data: totalYellow
        },
        {
          label: "Red",
          backgroundColor: "rgba(255, 96, 111, 0.3)",
          borderColor: "#FF606F",
          pointBackgroundColor: "#FF606F",
          pointRadius: 2,
          data: totalRed
        },
        {
          label: "Grey",
          backgroundColor: "rgba(190, 201, 215,0.3)",
          borderColor: "#BEC9D7",
          pointRadius: 2,
          pointBackgroundColor: "#BEC9D7",
          data: totalGrey
        }
      ]
    };
    this.setState({
      data: data,
      totalStatus: totalStatus,
      totalAll
    });
  }
  getCountIssues(category) {
    let count = 0;
    if (category) {
      for (let i = 0; i < category.length; i++) {
        count = count + category[i].issues.length;
      }
    }
    return count;
  }
  componentWillMount() {
    this.update();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.data !== this.props.data) {
      this.update();
    }
  }

  render() {
    return (
      <div className={classes.ZoneChartContent}>
        <div className={classes.ZoneChartTitle}>
          <h2>{this.state.totalAll} {this.state.totalAll === 1 ? `remark` : `remarks`} in total</h2>
        </div>
        <div className={classes.ZoneChartCircles}>
        <span className={classes.CircleRED}>
            {this.state.totalStatus.RED}
          </span>
          <span className={classes.CircleAMBER}>
            {this.state.totalStatus.AMBER}
          </span>
          <span className={classes.CircleGREEN}>
            {this.state.totalStatus.GREEN}
          </span>
          <span className={classes.CircleUNKNOWN}>
            {this.state.totalStatus.UNKNOWN}
          </span>
        </div>
      
        <ZoneChartRun/>

      </div>
    );
  }
}


export default  (Category);

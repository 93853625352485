import React, {useEffect, useState} from 'react';
import {Constants} from '../../../../../../utils/constants'
import {useDispatch} from "react-redux";
import _ from "lodash";
import notification from "../../../../../../utils/notification";
import Mustache from 'mustache';
import ServerConnect from "../../../../../../utils/ServerConnect";
import Loading from '../../../../../Loading/Loading';
import {LayoutForm} from '../../../../../../containers';
import AgreementForm from '../../../../../../containers/Forms/Questionniare/AgreementData/AgreementForm';
import {getAgreementData, upsertQuestionnaireAgreementsData} from '../config'


const EditAgreement = (props) => {
    const dispatch = useDispatch();

    const closeForm = () => {
        dispatch({ type: 'GO_TO', page: Constants.PAGES.viewMatter, params: { id: props.params.matterId, tab:'questionnaire' } })
    }

    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [list, setList] = useState([])

    const [initialValues, setInitialValues] = useState(null)

    

    const getData = () => {
        const query_params = {
            matterId: props.params.matterId
        };

        const query = Mustache.render(getAgreementData, query_params)
        ServerConnect.graphQlQuery(query).then((result) => {
            let dataToFormat = result.getMattersList[0].questionnaire.agreementsData
            setData({ agreementsDirectors: result.getMattersList[0].questionnaire.agreementsData.agreementsDirectors , firstName: dataToFormat.firstName, lastName: dataToFormat.lastName, email: dataToFormat.email })
            setLoading(false)
        });
    }



    const submitData = (values) => {
        let message = 'Agreement Information Updated';
        ServerConnect.graphQlMutation(upsertQuestionnaireAgreementsData, { matterId: props.params.matterId, agreementsData: { agreementsDirectors: _.get(values, 'agreementsDirectors', []), firstName: _.get(values, 'firstName', ''), lastName: _.get(values, 'lastName', ''), email: _.get(values, 'email', '') } })
            .then(result => {
                notification.sendNotification(message, "success", 4990);
                dispatch({ type: 'GO_TO', page: Constants.PAGES.viewMatter, params: { id: props.params.matterId, tab:'questionnaire' } })
            }).catch(err => {
                console.log(err);
                notification.sendNotification("ERROR", "error", 4990);
            });
    }

    const modifyDataFile = (values) => {
        var newValues = _.forOwn(values, (val, key) => {
            if (_.hasIn(val, 'file')) {
                return values[key] = val.file
            }
        })
        return newValues

    }

    const getVariablesMutation = (matterId, values) => {
        let companyDetailsData = { ...modifyDataFile(values) };
        return {
            matterId,
            companyDetailsData
        }
    }

    useEffect(() => {
        if (!data && props.mode == 'edit') {
            setLoading(true)
            getData();
        }
    }, [data, loading]);



    return (
        <React.Fragment>
            <Loading show={loading}>
                <LayoutForm
                    title={_.capitalize(props.params.type)}
                    leftEducationId={Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM[`${_.eq(props.mode, "edit") ? "EDIT" : "ADD"}`].LEFT_EDUCATION_ID}
                    handleClose={closeForm}>
                    <AgreementForm initialValues={data} onSubmit={submitData} list={list} closeForm={closeForm} />
                </LayoutForm>
            </Loading>
        </React.Fragment>
    )
}

export default EditAgreement;

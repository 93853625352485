import React, { FC } from "react";
import _, { get } from "lodash";
import {
  addAssistantText,
  clearAssistatText,
} from "./../../../../generic/store/action";

import Button from "./../../../../generic/components/Buttons/Button";
import Form from "../../ADGM/components/Forms/FormProofOfAddress";
import ProofOfAddress from "../Public/Proof_of_address.svg";
import { StepProps } from "./../../../../generic/components/Wizard/Step";
import SubmitButton from "../../../../generic/components/Buttons/SubmitButton";
import TemplateWizard from "./../../../../generic/templates/Modal/TemplateWizard";
import useAddDocumentFormation from "../../../../../hooks/services/modules/individualStakeholder/useAddDocumentFormation";
import useConfirmProofAddress from "../hooks/useUpsertRenewalData";
import useCustomMachine from "./../../../../generic/hooks/useCustomMachine";
import { useDispatch } from "react-redux";
import useGetStakeholder from "../hooks/useGetStakeholder";
import { useParams } from "react-router-dom";
import useSession from "../../../../session/hooks/useSession";

const Step2: FC<StepProps> = () => {

  const { next, send, state, ...props } = useCustomMachine();
  const [updateDocument] = useAddDocumentFormation();
  const { id: stakeholderIdParamId } = useParams<any>();
  const { startupId } = useSession();
  const isRenewal = props?.machine?.config?.context?.data?.isRenewal
  const submitRenewal = useConfirmProofAddress()
  const { query } = useGetStakeholder({});
  const stakeholderId = state?.context?.paramsData?.stakeholderId ?? stakeholderIdParamId;

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(
      addAssistantText(
        `Add a utility bill or bank statement dated within the past two months, with this stakeholder's residential address displayed clearly.`
      )
    );
    if (get(state, "context.data.jurisdictionType", {}) === "ADGM") {
      dispatch(
        addAssistantText(
          'The utility bill or bank statement MUST be written in English. We can NOT accept tenancy contracts/agreements or title deeds as proof of address.'
          ,
          'alert')
      )
    }
    return () => {
      dispatch(clearAssistatText());
    };
  }, []);

  const stakeholder = async () => {
    const stakeholderData = await query({ startupId, stakeholderId });
    return stakeholderData.stakeholder
  }

  const handleCompleteSubmit = async (values) => {
    const stakeholderData = await stakeholder()
    const variables = {
      stakeholderId,
      startupId,
      identityDocumentData: { ...values, type: "PROOF_OF_ADDRESS_DOCUMENT" },
    };
    await updateDocument({ variables });
    let groupCompanyFiltered
    if (!_.isEmpty(stakeholderData) && isRenewal) {
      groupCompanyFiltered = stakeholderData.managedByClaraConnections.filter((sh) =>
        sh.jurisdictionType === 'ADGM' &&
        sh.statusOnMatters.isRenewal === true
      )
      const groupCompanyId = groupCompanyFiltered[0].id
      await submitRenewal(groupCompanyId, stakeholderId)
    }
    next(values);
  };

  const handleCancel = async (values) => {
    send("CANCEL");
  };

  return (
    <Form
      initialValues={get(state, "context.data.uploadDocument", {})}
      onCompleteSubmit={handleCompleteSubmit}
      Template={TemplateWizard}
      propsTemplate={{
        title: "Add proof of address",
        subTitle: "Proof of address",
      }}
      image={<img src={ProofOfAddress} alt={""}></img>}
      buttons={{
        cancel: (
          <Button onClick={handleCancel} variant="secondary">
            Cancel
          </Button>
        ),
        submit: <SubmitButton>Add</SubmitButton>,
      }}
    />
  );
};

export default Step2;

import React, { useMemo } from "react";
import * as Yup from "yup";
import { ICONS } from "src/modules/generic/components/Inputs/FileUpload/NoFilesUploaded";
import Form from "src/components/forms/Form/Form";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import FormProps from "src/modules/generic/forms/interfaces/FormProps";
import TemplateFormDefault from "src/modules/generic/templates/Modal";
import FileUpload from "src/modules/generic/components/Inputs/FileUpload";
import LeftContentBlock from "../../components/LeftContentBlock";
import RightContentBlock from "../../components/RightContentBlock";
import cls from "./classes.module.scss";

const schema = {
  image_pdf: Yup.object().shape({
    files: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string()
            .matches(/(.jpg)|(.png)|(.jpeg)$/, "Invalid file type")
            .transform((current, original) => {
              return original.replace(/(\.[^.]+)$/, (match) =>
                match.toLowerCase()
              );
            }),
        })
      )
      .min(1),
  }),
  all: Yup.object().shape({
    files: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string(),
        })
      )
      .min(1),
  }),
};

interface FormPropsCustom extends FormProps {
  text?: string;
  image?: any;
  accept?: "all" | "image_pdf";
}

const FormCurrentSelect: React.FC<FormPropsCustom> = ({
  initialValues = {},
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  image,
  propsTemplate,
  accept = "all",
}) => {
  const handleSubmit = async (values) => {
    onCompleteSubmit && onCompleteSubmit(values);
  };

  const { acceptTypes, acceptIcon } = useMemo(() => {
    switch (accept) {
      case "all":
        return {
          acceptIcon: undefined,
          acceptTypes: undefined,
        };
      case "image_pdf":
        return {
          acceptIcon: [ICONS.JPG, ICONS.PNG],
          acceptTypes: "image/png, .jpg, .jpeg",
        };
    }
  }, [accept]);

  return (
    <Form
      defaultValues={initialValues}
      schema={schema[accept]}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <Template
        buttons={{
          submit: <SubmitButton {...buttonSubmitProps}>Next</SubmitButton>,
          ...buttons,
        }}
        props={propsTemplate}
      >
        <div className={cls.ContainerForm}>
          <LeftContentBlock title="Example">{image}</LeftContentBlock>
          <RightContentBlock title="Upload" isRequired>
            <ControllerInput
              render={FileUpload}
              accept={acceptTypes}
              icons={acceptIcon}
              name="files"
              limit={1}
              defaultValue={[]}
            />
          </RightContentBlock>
        </div>
      </Template>
    </Form>
  );
};

export default FormCurrentSelect;

export const actions = {
    UPDATE_NOTIFICATIONS: "UPDATE_NOTIFICATIONS",
    CLEAR_NOTIFICATIONS: "CLEAR_NOTIFICATIONS",
    DECREMENT_NOTIFICATIONS: "DECREMENT_NOTIFICATIONS"

};

export const updateNotificationsMessages = (dispatch, payload) => {
    dispatch({
        type: actions.UPDATE_NOTIFICATIONS,
        payload
    })
};


export const clearNotificationsMessages = (dispatch) => {
    dispatch({
        type: actions.CLEAR_NOTIFICATIONS
    })
};


export const decrementNotifications = (dispatch, payload) => {
    dispatch({
        type: actions.DECREMENT_NOTIFICATIONS,
        payload
    })
};

import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Constants } from '../../../../utils/constants'
import { useDispatch } from "react-redux";
import classes from "../ViewForms.module.scss";
import { RoleAccess, Modal, PopoverOption } from '../../../index';
import _, { set } from "lodash";
import helper from "../../../../utils/helper";
import Mustache from 'mustache';
import ServerConnect from "../../../../utils/ServerConnect";
import Loading from '../../../Loading/Loading';
import FactoryQuestionnaireContent from './FactoryQuestionnaireContent';
import { MenuListAgent } from './IncorporationPortal/components';
import useGetDraftMatter from '../../../../Hooks/useGetDraftMatter'
import { ModalGenerateADGMDocuments} from './IncorporationPortal/FactoryModals';
import * as Yup from 'yup';

const validateIncorporation = Yup.object().shape({
    applicationNumber: Yup.string().trim('This field does not allow spaces').required("This field is requiered"),
    agentId: Yup.string().required("This field is requiered").ensure()
})

const Questionnaire = (props) => {

    const dispatch = useDispatch();

    const { user, data } = props;

    const { data: dataQuestionnaire, loading: loadingQuestionnaire } = useGetDraftMatter({ matterId: props.data.id, type: "QUESTIONNAIRE" });

    const [isDraftData, setIsDraftData] = useState(true)

    const [questionnaire, setQuestionnaire] = useState(null);

    useEffect(() => {
        if (_.get(props, 'data.questionnaire.individuals', []).length) {
            setIsDraftData(false);
            setQuestionnaire(props.data.questionnaire);
        }
        if (_.get(dataQuestionnaire, 'getMatterDraft.questionnaire')) {
            setIsDraftData(true);
            setQuestionnaire(dataQuestionnaire.getMatterDraft.questionnaire);
        }

    }, [loadingQuestionnaire])

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [categories, setCategories] = useState(null);
    const [mode, setMode] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showIncorporation, setShowIncorporation] = useState(false);


    let getNominee = _.get(questionnaire, 'NASService', false) === true ? [{ firstName: 'Nominee Authorized', lastName: 'Signatory' }] : _.get(questionnaire, 'authorizedSignatories', []);
    let agreementDirectorsIndividuals = _.filter(_.get(data, 'questionnaire.individuals', []), ind => {
        if (questionnaire && questionnaire.agreementsData.agreementsDirectors.includes(ind.id)) {
            return ind
        }
    });
    let agreementDirectorsCompanies = _.filter(_.get(data, 'questionnaire.companies', []), comp => {
        if (questionnaire && questionnaire.agreementsData.agreementsDirectors.includes(comp.id)) {
            return comp
        }
    })

    const mapDirectorDataDraft = (questionnaire) => {
        const compactEntities = _.concat(questionnaire.individuals, questionnaire.companies);
        return _.filter(compactEntities, 'isDirector')

    }


    const mapShareholderDataDraft = (questionnaire) => {
        const compactEntities = _.concat(questionnaire.individuals, questionnaire.companies);
        return _.filter(compactEntities, 'isShareholder')

    }

    let formattedData = questionnaire ? {
        companyDetails: { ...questionnaire.companyDetails },
        directors: isDraftData ? mapDirectorDataDraft(questionnaire) : _.concat(_.get(questionnaire, 'directors.individuals', []), _.get(questionnaire, 'directors.companies', [])),
        sharesData: { ...questionnaire.sharesData, shareholders: [...questionnaire.sharesData.shareholders] },
        signatories: getNominee,
        bankingServices: { ...questionnaire.bankInformation },
        dataController: [{ ...questionnaire.dataProtection }],
        beneficialOwners: [..._.get(questionnaire, 'beneficialOwners', [])],
        agreementsData: {
            ...questionnaire.agreementsData, agreementsDirectors: _.concat(agreementDirectorsIndividuals, agreementDirectorsCompanies)
        }
    } : null;

    const callServerToGetData = () => {
        setCategories(formattedData)
        setLoading(false)
    };

    const handleSelectCategory = (entity, key) => {
        if (Array.isArray(entity) && key === Constants.QUESTIONNAIRE_KEYS.DIRECTORS) {
            setSelectedCategory(entity)
            setMode(Constants.QUESTIONNAIRE_KEYS.DIRECTORS_CATEGORY)
        } else if (Array.isArray(entity) && key === Constants.QUESTIONNAIRE_KEYS.SIGNATORIES) {
            setSelectedCategory(entity)
            setMode(Constants.QUESTIONNAIRE_KEYS.SIGNATORIES_CATEGORY)
        } else if (Array.isArray(entity) && key == Constants.QUESTIONNAIRE_KEYS.BENEFICIAL_OWNERS) {
            setSelectedCategory(entity)
            setMode(Constants.QUESTIONNAIRE_KEYS.BENEFICIAL_OWNERS_CATEGORY)
        } else {
            setSelectedCategory(entity)
            setMode(key)
        }

    }

    const handleSelectSub = (entity, key) => {
        if (key == Constants.QUESTIONNAIRE_KEYS.SIGNATORIES) {
            if (_.get(questionnaire, 'NASService') == true) {
                setMode(Constants.QUESTIONNAIRE_KEYS.NAS)
                setSelectedCategory(entity)
            } else {
                setMode(key)
                setSelectedCategory(entity)
            }

        } else {
            if (key == Constants.QUESTIONNAIRE_KEYS.SIGNATORIES) {
                setSelectedCategory(entity)
                setMode(key)
            } else if (key == Constants.QUESTIONNAIRE_KEYS.SHARESDATA) {
                setMode(Constants.QUESTIONNAIRE_KEYS.SHAREHOLDEREDITABLE)
                if (_.get(entity, 'individual', null)) {
                    setSelectedCategory(entity.individual)
                } else {
                    setSelectedCategory(_.get(entity, 'company', ''))
                }
            } else if (key == Constants.QUESTIONNAIRE_KEYS.DATA_CONTROLLER) {
                setSelectedCategory(entity)
                setMode(Constants.QUESTIONNAIRE_KEYS.DATA_CONTROLLER_ENTITY)
            } else if (key == Constants.QUESTIONNAIRE_KEYS.AGREEMENTSDATA) {
                setSelectedCategory(entity)
                setMode(Constants.QUESTIONNAIRE_KEYS.AGREEMENTSDATA_ENTITY)
            } else {
                setSelectedCategory(entity)
                setMode(key)
            }
        }

    }

    const showCategories = () => {
        if (!categories) {
            return []
        } else {
            return _.map(categories, (value, entity) => {
                let selectedEntityClass = categories[entity] == selectedCategory ? classes.selectedEntityText : null;
                return (
                    <React.Fragment>
                        <span className={`${classes.entityText} ${selectedEntityClass}`} onClick={() => { handleSelectCategory(categories[entity], entity) }}>{helper.getTranslateTextByKey('QUESTIONNAIRE_TAB_NAME_' + entity)}</span>
                        {Array.isArray(categories[entity]) && entity !== Constants.QUESTIONNAIRE_KEYS.SHARESDATA ? categories[entity].map(subCat => {
                            let selectedSubCatClass = subCat == selectedCategory ? classes.selectedEntityText : null
                            return (
                                <span className={`${classes.entityText} ${selectedSubCatClass} ${classes.subCategoryEntity}`} onClick={() => { handleSelectSub(subCat, entity) }}>{_.get(subCat, 'companyName', null) ? _.get(subCat, 'companyName', '') : _.get(subCat, 'firstName', '') + ' ' + _.get(subCat, 'lastName', '')}</span>
                            )
                        }) : null}
                        {entity == Constants.QUESTIONNAIRE_KEYS.SHARESDATA ? categories[entity].shareholders.map(subCat => {
                            let selectedSubCatClass = subCat.individual ? (subCat.individual == selectedCategory ? classes.selectedEntityText : null) : (subCat.company == selectedCategory ? classes.selectedEntityText : null)
                            return (
                                <span className={`${classes.entityText} ${selectedSubCatClass} ${classes.subCategoryEntity}`} onClick={() => { handleSelectSub(subCat, entity) }}>{_.get(subCat, 'companyName', null) ? _.get(subCat, 'companyName', '') : _.get(subCat, 'fullName', '')}</span>
                            )
                        }) : null}
                        {entity == Constants.QUESTIONNAIRE_KEYS.AGREEMENTSDATA ? categories[entity].agreementsDirectors.map(subCat => {
                            let selectedSubCatClass = subCat == selectedCategory ? classes.selectedEntityText : null
                            return (
                                <span className={`${classes.entityText} ${selectedSubCatClass} ${classes.subCategoryEntity}`} onClick={() => { handleSelectSub(subCat, entity) }}>{_.get(subCat, 'companyName', null) ? _.get(subCat, 'companyName', '') : _.get(subCat, 'firstName', '') + ' ' + _.get(subCat, 'lastName', '')}</span>
                            )
                        }) : null}
                    </React.Fragment>
                )
            })
        }
    }

    let pathToEdit = _.get(selectedCategory, 'type', '') === 'individual' ? Constants.PAGES.editQuestionnaireIndividual : Constants.PAGES.editCompanyQuestionnaire;
    let typeParam = _.get(selectedCategory, 'type', '') === 'individual' ? 'INDIVIDUAL_DIRECTOR' : 'CORPORATE_DIRECTOR';
    const getTypeParam = () => {
        if (_.get(selectedCategory, 'isDirector', null)) {
            return 'DIRECTOR'
        } else if (_.get(selectedCategory, 'isShareholder', null)) {
            return 'SHAREHOLDER'
        } else {
            return 'entity'
        }
    }

    const editHandlerByMode = () => {
        switch (mode) {
            case Constants.QUESTIONNAIRE_KEYS.COMPANY_DETAILS:
                return dispatch({ type: 'GO_TO', page: Constants.PAGES.questionnaireCompany, params: { matterId: props.data.id } })
            case Constants.QUESTIONNAIRE_KEYS.AGREEMENTSDATA:
                return dispatch({ type: 'GO_TO', page: Constants.PAGES.questionnaireEditAgreements, params: { matterId: props.data.id } })
            case Constants.QUESTIONNAIRE_KEYS.DIRECTORS:
                return dispatch({ type: 'GO_TO', page: pathToEdit, params: { matterId: props.data.id, id: _.get(selectedCategory, 'id', ''), type: typeParam } })
            case Constants.QUESTIONNAIRE_KEYS.BENEFICIAL_OWNERS:
                return dispatch({ type: 'GO_TO', page: pathToEdit, params: { matterId: props.data.id, id: _.get(selectedCategory, 'id', ''), type: typeParam } })
            case Constants.QUESTIONNAIRE_KEYS.BENEFICIAL_OWNERS_CATEGORY:
                return dispatch({ type: 'GO_TO', page: Constants.PAGES.questionnaireEditBeneficialOwners, params: { matterId: props.data.id } })
            case Constants.QUESTIONNAIRE_KEYS.SHAREHOLDERS:
                return dispatch({ type: 'GO_TO', page: Constants.PAGES.questionnaireEditdirectors, params: { type: "shareholderAmount", matterId: props.data.id } })
            case Constants.QUESTIONNAIRE_KEYS.DATA_CONTROLLER_ENTITY:
            case Constants.QUESTIONNAIRE_KEYS.SHAREHOLDEREDITABLE:
            case Constants.QUESTIONNAIRE_KEYS.SIGNATORIES:
            case Constants.QUESTIONNAIRE_KEYS.AGREEMENTSDATA_ENTITY:
                return dispatch({ type: 'GO_TO', page: pathToEdit, params: { matterId: props.data.id, id: _.get(selectedCategory, 'id', ''), type: getTypeParam } })
            case Constants.QUESTIONNAIRE_KEYS.BANKING_SERVICES:
                return dispatch({ type: 'GO_TO', page: Constants.PAGES.questionnaireBanckService, params: { matterId: props.data.id } })
            case Constants.QUESTIONNAIRE_KEYS.DATA_CONTROLLER:
                return dispatch({ type: 'GO_TO', page: Constants.PAGES.questionnaireDataControler, params: { matterId: props.data.id } })
            case Constants.QUESTIONNAIRE_KEYS.DIRECTORS_CATEGORY:
                return dispatch({ type: 'GO_TO', page: Constants.PAGES.questionnaireEditdirectors, params: { matterId: props.data.id } })
            case Constants.QUESTIONNAIRE_KEYS.SIGNATORIES_CATEGORY:
                return dispatch({ type: 'GO_TO', page: Constants.PAGES.questionnaireSignatories, params: { matterId: props.data.id } })
            case Constants.QUESTIONNAIRE_KEYS.NAS:
                return dispatch({ type: 'GO_TO', page: Constants.PAGES.questionnaireSignatories, params: { matterId: props.data.id } })
        }

    }

    useEffect(() => {
        if (!categories) {
            callServerToGetData();
        } else if (!selectedCategory) {
            setSelectedCategory(categories[Constants.QUESTIONNAIRE_KEYS.COMPANY_DETAILS])
            setMode(Constants.QUESTIONNAIRE_KEYS.COMPANY_DETAILS)
        }
    }, [categories, selectedCategory, JSON.stringify(questionnaire)]);

    const handleCloseModal = () => {
        setShowIncorporation(false);
    };

    if (!loadingQuestionnaire && !questionnaire) {
        return null
    }

    // console.log('formattedData------>', formattedData)

    return (
        <React.Fragment>
            <Loading show={loading || (isDraftData && loadingQuestionnaire)} showChildren={true} loadingStyle={{ position: "fixed", zIndex: 1000 }}>
                <div className={classes.ContainerView}>
                    {/* BOTON */}
                    {mode !== Constants.QUESTIONNAIRE_KEYS.SHARESDATA ? < RoleAccess action={Constants.ACTIONS.MANAGE_MATTER}>
                        <button className={`${classes.buttonEditFloat} ${classes.buttonEditOnKYC}`} disabled={isDraftData} onClick={() => editHandlerByMode()}>
                            <span>Edit Details</span>
                        </button>
                    </RoleAccess> : null}


                    <div className={classes.ContainerData}>
                        {[Constants.PROFILE_ROLES.SERVICE_AGENT, Constants.PROFILE_ROLES.SENIOR_SERVICE_AGENT, Constants.PROFILE_ROLES.SERVICE_AGENT_MANAGER].includes(user.userRole) ? <React.Fragment> <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                            <label>Actions</label>
                        </div>
                            <div className={`${classes.ContainerRow}`}>
                                <RoleAccess action={Constants.ACTIONS.EDIT_MATTER} >
                                    <div className={`${classes.botoneraActions}`}>
										<button
											disabled={isDraftData || data.incorporation}
											className={classes.generateDocsButton}
											onClick={() => setShowIncorporation(true)}
										>
											Generate ADGM Documents
										</button>
                                    </div>
                                </RoleAccess>
                            </div></React.Fragment> : null}
                        <div className={classes.pushedContainer}>

                            <div className={`${classes.entitiesContainer}`}>

                                <hr className={classes.Separator} />
                                <div className={classes.containerDraft}>
                                    {isDraftData && <span className={classes.draftLabel}>Draft Questionnaire</span>}

                                    <label>Categories</label>
                                </div>
                                {showCategories()}
                            </div>
                            <div className={classes.ContainerPushed}>
                                <FactoryQuestionnaireContent isDraftData={isDraftData} data={selectedCategory ? selectedCategory : null} mode={mode ? mode : 'companyDetails'} matterId={props.data.id} />
                            </div>
                        </div>
                    </div>
                </div >
				<ModalGenerateADGMDocuments
					showModal={showIncorporation}
					closeModal={handleCloseModal}
					setLoading={setLoading}
					goToTab={props.goToTab}
					data={props.data}
				/>
            </Loading >
        </React.Fragment >
    )
}

export default Questionnaire;


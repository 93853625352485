import React, { FC } from 'react'
import classesModule from './ScaleDetail.module.scss'
import TypographyLocal from "../../../generic/components/Texts/Typography";
// // import Switch from '@material-ui/core/Switch';
// import classnames from "classnames";

export interface ScaleDetailProps {
  // tagTitle?: string,
  priceAnnually?: string,
  // priceMonthly?: string,
  // stringMonthly?: string,
  stringAnnually?: string
  // handleClick?(e: object): void;
  // isMounth: boolean;
}

const ScaleDetail: FC<ScaleDetailProps> = ({

  // tagTitle,
  priceAnnually,
  // priceMonthly,
  // stringMonthly,
  stringAnnually,
  // handleClick,
  // isMounth

}: ScaleDetailProps) => {

  return <div className={classesModule.containerDetail}>
     {/* <div className={classnames(classesModule.itemMonthly, {
      [classesModule.select]: !isMounth
    })} >
      <div className={classesModule.itemMonthlyText}>
        <TypographyLocal
          className={classesModule.stringMonthly}
        >
          {stringMonthly}
        </TypographyLocal>
      </div>
      <div className={classesModule.itemMonthlyPrice} >
        <TypographyLocal
          className={classesModule.priceMonthly}
        >
          {priceMonthly}
        </TypographyLocal>
      </div>
    </div>
    <div className={classesModule.ContinerRowSwitch}>
      <Switch
        classes={{
          root: classesModule.SWITHC_root,
          switchBase: classesModule.SWITHC_switchBase,
          thumb: classesModule.SWITHC_thumb,
          track: classesModule.SWITHC_track,
          checked: classesModule.SWITHC_checked,
        }}
        checked={isMounth}
        onChange={handleClick}
      />
    </div> */}
      {/* <div className={classnames(classesModule.itemAnnually, {
      [classesModule.select]: isMounth
    })}></div> */}
    
      <div className={classesModule.itemstringAnnually}>
        <TypographyLocal
          className={classesModule.stringAnnually}
          >
          {stringAnnually}
        </TypographyLocal>
        {/* <div className={classesModule.tagOnly} >
        <span
        >
          {tagTitle}
        </span>
      </div> */}
      </div>
      <div className={classesModule.itemPriceAnnually} >
        <TypographyLocal
          className={classesModule.priceAnnually}
        >
          {`${priceAnnually}`}
        </TypographyLocal>
      </div>
    {/* </div> */}
  </div>
}

export default ScaleDetail
import WARRANT_FORM_STEP from "../WarrantFormStep/langs";
import FIRST_STEP from '../FirstStep/langs'
import IS_THERE_DOCUMENTS_STEP from '../IsThereDocumentsStep/langs'
import UPLOAD_DOCUMENTS_STEP from '../UploadDocumentStep/langs'
import SELECT_DOCUMENT_STEP from '../SelectDocumentStep/langs'
export default {
    WARRANT_FORM_STEP:WARRANT_FORM_STEP.US,
    FIRST_STEP:FIRST_STEP.US,
    IS_THERE_DOCUMENTS_STEP:IS_THERE_DOCUMENTS_STEP.US,
    UPLOAD_DOCUMENTS_STEP:UPLOAD_DOCUMENTS_STEP.US,
    SELECT_DOCUMENT_STEP:SELECT_DOCUMENT_STEP.US
}
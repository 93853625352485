import React, { useEffect, useState } from "react";
import * as Yup from 'yup'
import cls from "./FormApplicationSummary.module.scss";
import _ from "lodash";
import { useDispatch } from "react-redux";
import Form from "../../../../../../../components/forms/Form/Form";
import useTranslate from "../../../../../../generic/hooks/useTranslate";
import SubmitButton from "../../../../../../generic/components/Buttons/SubmitButton";
import FormProps from "../../../../../../generic/forms/interfaces/FormProps";
import TemplateFormDefault from "../../../../../../generic/templates/Modal";
import { addAssistantText, clearAssistatText } from "../../../../../../generic/store/action";
import useMachine from '../../../../../../generic/context/MachineContext/useMachine';
import useGetCompanyData from './../../hooks/useGetCompanyData';
import Typography from '../../../../../../generic/components/Texts/Typography/index';
import Avatar from 'src/modules/generic/components/Avatars/AvatarLabel';
import Icon from "../../../../../../../modules/generic/components/Icon";
import useSession from '../../../../../../../modules/session/hooks/useSession';
import useGetTableAgreementList from "src/views/modules/startups/documents/Documents/graphql/queries/useGetTableAgreementList";

const schema = Yup.object().shape({

})

interface FormApplicationSummaryProps extends FormProps {
  defaultData?: any;
}

const FormApplicationSummary: React.FC<FormApplicationSummaryProps> = ({
  initialValues = {},
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
  defaultData = {},
}) => {

  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const { next } = useMachine();
  const { data, loading, manualQuery } = useGetCompanyData();
  const [companiesList, setCompaniesList] = useState([]);
  const [stakeholdersList, setStakeholdersList] = useState([]);
  const [documentsList, setDocumentsList] = useState([]);
  const { startup, startupId } = useSession();
  const acceleratorName = _.get(startup, "acceleratorDef.name", "");
  const { data: tableAgreementListData, loading: loadingData } = useGetTableAgreementList({ startupId })

  const handleTooltips = () => {
    //dispatch(clearAssistatText());
    //dispatch(addAssistantText(translate('MODULES.SUBMIT_APPLICATION.STEP_2.TOOLTIP1', { acceleratorName: acceleratorName })));
  }

  useEffect(() => {
    handleTooltips();
    manualQuery();
  }, []);

  const handleSubmit = async (values) => {
    next(values)
  };

  useEffect(() => {
    let companies = [];
    let stakeholders = [];
    if (data) {
      _.forEach(_.get(data, 'getGroupCompanies'), (gc) => {
        companies.push(_.get(gc, 'name'))
      })
      _.forEach(_.get(data, 'stakeholderList'), (sh) => {
        stakeholders.push(_.get(sh, 'fullName'))
      })
      setCompaniesList(companies);
      setStakeholdersList(stakeholders);
      setDocumentsList(tableAgreementListData?.documents);
    }
  }, [loading, loadingData]);

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
      onlySendDirty={false}
    >
      <Template
        buttons={{
          ...buttons,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined} {...buttonSubmitProps}>{translate('BUTTON_SUBMIT')}</SubmitButton>,
        }}
        props={propsTemplate}
      >
        <div>
          <div className={cls.segment}>
            <Typography variant="body" weight="semibold">{translate('MODULES.SUBMIT_APPLICATION.STEP_2.COMPANIES')}</Typography>
          </div>
          <div className={cls.cardContainer}>
            {_.map(companiesList, (company) => {
              return (
                <React.Fragment>
                  <div className={cls.card}>
                    <Avatar
                      showLabel={false}
                      name={company}
                      avatar={null}
                      label={undefined}
                      className={undefined}
                      captable={undefined}
                      divStyle={undefined} />
                    <label>{company}</label>
                  </div>
                </React.Fragment>
              )
            })}
          </div>
        </div>
        <div className={cls.line} />
        <div>
          <div className={cls.segment}>
            <Typography variant="body" weight="semibold">{translate('MODULES.SUBMIT_APPLICATION.STEP_2.STAKEHOLDERS')}</Typography>
          </div>
          <div className={cls.cardContainer}>
            {_.map(stakeholdersList, (stakeholder) => {
              return (
                <React.Fragment>
                  <div className={cls.card}>
                    <Avatar
                      showLabel={false}
                      name={stakeholder}
                      avatar={null}
                      label={undefined}
                      className={undefined}
                      captable={undefined}
                      divStyle={undefined} />
                    <label>{stakeholder}</label>
                  </div>
                </React.Fragment>
              )
            })}
          </div>
        </div>
        <div className={cls.line} />
        <div>
          <div className={cls.segment}>
            <Typography variant="body" weight="semibold">{translate('MODULES.SUBMIT_APPLICATION.STEP_2.QUESTIONNAIRE')}</Typography>
          </div>
          <Typography>
            {translate('MODULES.SUBMIT_APPLICATION.STEP_2.TEXT_QUESTIONNAIRE')}
          </Typography>
        </div>
        <div className={cls.line} />
        <div>
          <div className={cls.segment}>
            <Typography variant="body" weight="semibold">{translate('MODULES.SUBMIT_APPLICATION.STEP_2.DOCUMENTS')}</Typography>
          </div>
          <div className={cls.cardContainer}>
            {_.map(documentsList, (document) => {
              return (
                <React.Fragment>
                  <div className={cls.document}>
                    <Icon icon={"Document"} size={"24px"} isClara={true} />
                    <label>{document?.name}</label>
                  </div>
                </React.Fragment>
              )
            })}
          </div>
        </div>
        <div />
      </Template>
    </Form>
  );
};

export default FormApplicationSummary;
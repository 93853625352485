import EditBusinessActivity from '../steps/EditBusinessActivity';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import { useMemo } from 'react';

const useSteps = () => {
  return useMemo(() => ({
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    pincasData: {
      render: TemplateWizardSkeletonMachine,
    },
    EditBusinessActivityForm: {
      render: EditBusinessActivity,
    },
    SaveBusinessActivity: {
      render: TemplateWizardSkeletonMachine,
    },
  }), []);
}

export default useSteps;

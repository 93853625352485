import { useMemo } from "react";
import TemplateWizardSkeletonMachine from "src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import { AddConvertible,AddShare,AddOption,SelectEquityType,AddWarrant } from "../Steps";
const useSteps = () => {
    const { translate } = useTranslate();
    return  useMemo(() => ({
        getStakeholdersGroupCompanies: {
          render: TemplateWizardSkeletonMachine,
          renderProps:{
            title:  translate('MODULES.EQUITY.ADD_EQUITY.TITLE'),
            subTitle: translate('MODULES.EQUITY.ADD_EQUITY.SUBTITLE'),
          },
          stepperPosition: 0
        },
        select: {
          render: SelectEquityType,
          stepperPosition: 0
        },
        selectEquityType: {
          render: SelectEquityType,
          stepperPosition: 0
        },
        addShare: {
          render: AddShare,
          stepperPosition: 1
        },
        addOption: {
          render: AddOption,
          stepperPosition: 1
        },
        addConvertible: {
          render: AddConvertible,
          stepperPosition: 1
        },
        addWarrant: {
          render: AddWarrant,
          stepperPosition: 1
        },
        refreshData: {
          render: TemplateWizardSkeletonMachine,
          renderProps:{
            title:  translate('MODULES.EQUITY.ADD_EQUITY.TITLE'),
            subTitle: translate('MODULES.EQUITY.ADD_EQUITY.SUBTITLE'),
          },
          stepperPosition: 0
        },
      }),[]);
}
export default useSteps;
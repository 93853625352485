import React, { FC, useRef } from 'react';
import * as Yup from 'yup';
import useTranslate from "../../../generic/hooks/useTranslate";
import Form from "../../../../components/forms/Form/FormL";
import ControllerInput from "../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import SubmitButton from "../../../generic/components/Buttons/SubmitButton";
import TemplateFormDefault from "../../../generic/templates/Modal";
import FormProps from "../../../generic/forms/interfaces/FormProps";
import { CheckboxButtonOptions } from "../../../generic/components/Inputs/CheckboxButton";
import CheckboxButtonSimple from "../../../generic/components/Inputs/CheckboxButtonSimple";
import classes from './Form.module.scss';

const schema = Yup.object().shape({
  isJointly: Yup.boolean().nullable().required('Required'),
})

const IsIndividualOrCompany: FC<FormProps> = ({ initialValues, onCompleteSubmit, Template = TemplateFormDefault, buttons = {}, buttonSubmitProps, propsTemplate }: FormProps) => {

  const { translate } = useTranslate()
  const options: Array<CheckboxButtonOptions<boolean, string>> = [
    {
      value: false,
      label: translate('MODULES.GROUP_COMPANY.FORM.SINGLY_OR_JOINTLY.SINGLY')
    },
    {
      value: true,
      label: translate('MODULES.GROUP_COMPANY.FORM.SINGLY_OR_JOINTLY.JOINTLY')
    }
  ]

  const refForm = useRef()
  return (
    <>
      <Form
        initialValues={initialValues || {}}
        schema={schema}
        onlySendDirty={false}
        onSubmit={onCompleteSubmit}
        ref={refForm} optionsForm={{ mode: "onChange" }}
      >
        <Template
          buttons={{ ...buttons, submit: <SubmitButton {...buttonSubmitProps}>Next</SubmitButton> }}
          props={propsTemplate}
        >
          <ControllerInput
            as={CheckboxButtonSimple}
            name='isJointly'
            options={options}
            className={classes.checkboxMultiple}
            classNameButton={classes.buttonCheckBox}
          />
        </Template>
      </Form>
    </>
  )
}

export default IsIndividualOrCompany

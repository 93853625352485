import React, { FC, useMemo } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import {
  ModalConvertibleDoc,
  ModalEmployeeDoc,
  ModalIpAndGenerate,
} from "../../../startup/Documents";
import AddEquityTaskModal from "src/modules/startup/equity/AddEquityTask";
import AssistanceFrame2 from "./";
import ModalAuthorisedSignatories from "../../../../modules/startup/Stakeholder/AuthorisedSignatories/ModalAuthorisedSignatories";
import ModalEmiratesID from "src/modules/startup/Stakeholder/ADGM/EmiratesID/ModalEmiratesID";
import ModalNotices from "../../../../modules/startup/Stakeholder/Notices/ModalNotices";
import ModalPassport from "src/modules/startup/Stakeholder/Passport";
import ModalProofAddress from "src/modules/startup/Stakeholder/ProofOfAddress/ModalProofAddress";
import ModalRemoveTask from "src/modules/startup/core/components/modals/DeleteTaskModal";
import ModalRolesAndPositions from "../../../startup/Stakeholder/RolesAndPositions/ModalRolesAndPositions";
import ModalUAEResidenceVisa from "src/modules/startup/Stakeholder/ADGM/UAEResidenceVisa/ModalUAEResidenceVisa";
import ReviewAndConfirmCapTableModal from "src/views/modules/startups/renewal/adgm/modals/ReviewAndConfirmCapTableModal";
import ReviewPersonalDetailModal from "src/views/modules/startups/renewal/adgm/modals/ReviewPersonalDetailModal";
import ReviewPersonalDetailShareholderModal from "src/views/modules/startups/renewal/adgm/modals/ReviewPersonalDetailShareholderModal";
import ReviewPersonalDetailUBOModal from "src/views/modules/startups/renewal/adgm/modals/ReviewDetailsUboModal";
import StakeholderIndividualTasks from "../../../../modules/assistanceV4/components/AssistanceAreas/StakeholderIndividual2/Tasks";
import useGetStakeholder from "../../hooks/services/useGetStakeholder";
import useModal from "../../../generic/hooks/useModal";
import useSession from "../../../session/hooks/useSession";
import useTabStatus from "../../hooks/useTabStatus";
import useTasks from "../../hooks/useTasks";
import ModalUAEEntryStamp from "src/modules/startup/Stakeholder/ADGM/UAEEntryStamp/ModalUAEEntryStamp";
import StakeholderExpiredDocumentModal from "../../../../views/modules/startups/renewal/adgm/modals/StakeholderExpiredDocumentModal";

/**
 * Assistance Frame for StakeholderIndividual
 */

export interface AssistanceStakeholderIndividual2Props {}

let restCount = 0;

const AssistanceStakeholderIndividual2: FC<AssistanceStakeholderIndividual2Props> = ({
  ...props
}: AssistanceStakeholderIndividual2Props) => {
  const { startupId } = useSession();
  const { id: idStakeholder } = useParams();
  const { data: dataStakeholder } = useGetStakeholder({
    startupId: startupId,
    stakeholderId: idStakeholder,
  });
  const { openModal } = useModal();

  const allTasks = useMemo(() => {
    if (!dataStakeholder) return [];
    let result = [];
    _.forEach(_.get(dataStakeholder, "getStakeholder.tasks", []), (task) => {
      result.push(task);
    });
    _.forEach(_.get(dataStakeholder, "getStakeholder.equity", []), (equity) => {
      _.forEach(equity.tasks, (task) => {
        result.push({
          ...task,
          params: {
            amount: equity.amount,
            currency: equity.currency,
            equityId: equity.id,
            issuedDate: equity.issuedDate,
            groupCompany: equity.groupCompany,
            owner: { id: idStakeholder, type: "stakeholder" },
          },
        });
      });
    });
    _.forEach(
      _.get(dataStakeholder, "getStakeholder.warrants", []),
      (warrant) => {
        _.forEach(warrant.tasks, (task) => {
          result.push({
            ...task,
            params: {
              amount: warrant.amount,
              currency: warrant.currency,
              equityId: warrant.id,
              issuedDate: warrant.issuedDate,
              groupCompany: warrant.groupCompany,
            },
          });
        });
      }
    );
    _.forEach(
      _.get(dataStakeholder, "getStakeholder.convertibles", []),
      (convertible) => {
        _.forEach(convertible.tasks, (task) => {
          result.push({
            ...task,
            params: {
              amount: convertible.amount,
              currency: convertible.currency,
              equityId: convertible.id,
              issuedDate: convertible.issuedDate,
              groupCompany: convertible.groupCompany,
            },
          });
        });
      }
    );
    _.forEach(_.get(dataStakeholder, "getStakeholder.roles", []), (role) => {
      _.forEach(role.tasks, (task) => {
        result.push({ ...task, params: { groupCompany: role.groupCompany } });
      });
    });
    return result;
  }, [JSON.stringify(dataStakeholder)]);

  const configDataStakeholder = {
    READY: [
      {
        code: "TASK_ADD_ROLES_POSITIONS",
        onClick: (params) => openModal(ModalRolesAndPositions, { ...params }),
      },
      {
        code: "TASK_ADD_STAKEHOLDER_CORPORATE_NOTICES",
        onClick: (params) => openModal(ModalNotices, { ...params }),
      },
      {
        code: "TASK_ADD_AUTHORISED_SIGNATORIES",
        onClick: (params) =>
          openModal(ModalAuthorisedSignatories, { ...params }),
      },
      {
        code: "TASK_ADD_FOUNDERS_AGREEMENT",
        onClick: (params) =>
          openModal(ModalIpAndGenerate, {
            paramsMutation: { ...params, type: "FOUNDER_SERVICES_AGREEMENT" },
          }),
      },
      {
        code: "TASK_ADD_EMPLOYEE_AGREEMENT",
        onClick: (params) => {
          openModal(ModalEmployeeDoc, {
            paramsMutation: { ...params, type: "EMPLOYMENT_AGREEMENT" },
          });
        },
      },
      {
        code: "TASK_ADD_CONSULTANT_AGREEMENT",
        onClick: (params) =>
          openModal(ModalIpAndGenerate, {
            paramsMutation: { ...params, type: "CONSULTANT_AGREEMENT" },
          }),
      },
      {
        code: "TASK_ADD_ADVISOR_AGREEMENT",
        onClick: (params) =>
          openModal(ModalIpAndGenerate, {
            paramsMutation: { ...params, type: "ADVISOR_AGREEMENT" },
          }),
      },
      {
        code: "TASK_ADD_OTHER_DOCUMENTS",
        onClick: (params) =>
          openModal(ModalIpAndGenerate, {
            paramsMutation: { ...params, type: "IP_ASSIGNMENT" },
          }),
      },
      {
        code: "TASK_ADD_IP_ASSIGNMENT_AGREEMENT",
        onClick: (params) =>
          openModal(ModalIpAndGenerate, {
            paramsMutation: { ...params, type: "IP_ASSIGNMENT" },
          }),
      },
      {
        code: "TASK_ADD_OTHERS",
        onClick: (params) => openModal(ModalIpAndGenerate, { ...params }),
      },
      {
        code: "TASK_ADD_WARRANT_AGREEMENT",
        onClick: (params) =>
          openModal(ModalIpAndGenerate, {
            paramsMutation: { ...params, type: "WARRANT_AGREEMENT" },
          }),
      },
      {
        code: "TASK_ADD_SHARE_AGREEMENT",
        onClick: (params) =>
          openModal(ModalIpAndGenerate, {
            paramsMutation: { ...params, type: "REGISTER_OF_SHARES" },
          }),
      },
      {
        code: "TASK_ADD_CONVERTIBLE_AGREEMENT",
        onClick: (params) =>
          openModal(ModalConvertibleDoc, { paramsMutation: { ...params } }),
      },
      {
        code: "TASK_ADD_SHARE_OPTION_AGREEMENT",
        onClick: (params) =>
          openModal(ModalIpAndGenerate, {
            paramsMutation: { ...params, type: "GRANT_AGREEMENT" },
          }),
      },
      { code: "TASK_ADD_WARRANT_AGREEMENT_FOR_IN_PROGRESS" },
      { code: "TASK_ADD_CONVERTIBLE_AGREEMENT_FOR_IN_PROGRESS" },
      { code: "TASK_ADD_SHARE_OPTION_AGREEMENT_FOR_IN_PROGRESS" },
      {
        code: "TASK_ADD_EQUITY_POSITIONS",
        onClick: () => openModal(AddEquityTaskModal, {}),
        onDelete: () =>
          openModal(ModalRemoveTask, {
            registeredTaskData: {
              entityId: idStakeholder,
              taskCode: "TASK_ADD_EQUITY_POSITIONS",
            },
          }),
      },
    ],
    DETAILS: [
      { code: "TASK_PEP" },
      { code: "TASK_SOURCE_OF_FUNDS_ADGM" },
      { code: "TASK_SOURCE_OF_FUNDS_CAYMAN" },
      { code: "TASK_SOURCE_OF_FUNDS_DIFC" },
      { code: "TASK_SOURCE_OF_FUNDS_GENERIC_BUILD" },
      { code: "TASK_PROOF_OF_ADDRESS" },
      { code: "TASK_PASSPORT" },
      { code: "TASK_PROFESSIONAL_BACKGROUND" },
      { code: "TASK_ADD_PERSONAL_INFORMATION" },
      { code: "TASK_ADDRESSES" },
    ],
    ADGM: [
      { code: "TASK_EMIRATES_ID" },
      { code: "TASK_UAE_RESIDENCE_VISA" },
      { code: "TASK_UID_STAMP" },
      { code: "TASK_UAE_ENTRY_STAMP" },
      { code: "TASK_ADDRESS_FAMILY_CONTACT_PAGE" },
      { code: "TASK_GCC_RESIDENT_ID" },
      { code: "TASK_NATIONAL_ID" },
    ],
    KYC_REOPEN: [
      {
        code: "TASK_ADD_PERSONAL_INFORMATION_KYC_INC",
      },
      {
        code: "TASK_PEP_KYC_INC",
      },
      {
        code: "TASK_SOURCE_OF_FUNDS_ADGM_KYC_INC",
      },
      {
        code: "TASK_SOURCE_OF_FUNDS_CAYMAN_KYC_INC",
      },
      {
        code: "TASK_PROOF_OF_ADDRESS_KYC_INC",
      },
      {
        code: "TASK_PASSPORT_KYC_INC",
      },
      {
        code: "TASK_PROFESSIONAL_BACKGROUND_KYC_INC",
      },
      {
        code: "TASK_EMIRATES_ID_KYC_INC",
      },
      {
        code: "TASK_UAE_RESIDENCE_VISA_KYC_INC",
      },
      {
        code: "TASK_UID_STAMP_KYC_INC",
      },
      {
        code: "TASK_UAE_ENTRY_STAMP_KYC_INC",
      },
      {
        code: "TASK_ADDRESS_FAMILY_CONTACT_PAGE_KYC_INC",
      },
      {
        code: "TASK_GCC_RESIDENT_ID_KYC_INC",
      },
      {
        code: "TASK_NATIONAL_ID_KYC_INC",
      },
      {
        code: "TASK_PASSPORT_LBS_KYC_INC",
      },
    ],
    RENEWAL: [
      {
        code: "TASK_PROOF_OF_ADDRESS_CHECK_EXP_DATE",
        onClick: () => {
          openModal(ModalProofAddress, { paramsMutation: {} });
        },
      },
    ],
    LBS: [
      {
        code: "TASK_PASSPORT_LBS",
        onClick: () => {
          openModal(
            ModalPassport,
            {
              initialValues: {
                idToSave: dataStakeholder?.getStakeholder?.id,
                name: dataStakeholder?.getStakeholder?.fullName,
              },
            },
            "MODAL_PASSPORT_FOR_ENTITY_REPRESENTATIVE_LBS_CORP"
          );
        },
      },
    ],
    RENEWAL_ADGM: [
      {
        code: "TASK_RENEWAL_ADGM_REVIEW_DETAILS_DIRECTOR", //EXAMPLE 1
        onClick: (params) => {
          openModal(
            ReviewPersonalDetailModal,
            {
              initialValues: {
                groupCompanyId: params?.groupCompanyId,
                stakeholderId: dataStakeholder?.getStakeholder?.id,
              },
            },
            "MODAL_REVIEW_PERSONAL_DETAIL"
          );
        },
      },
      {
        code: "TASK_RENEWAL_ADGM_REVIEW_DETAILS_SHAREHOLDER", //EXPAMPLE 2
        onClick: (params) => {
          openModal(
            ReviewPersonalDetailShareholderModal,
            {
              initialValues: {
                stakeholderId: dataStakeholder?.getStakeholder?.id,
                groupCompanyId: params?.groupCompanyId,
              },
            },
            "MODAL_REVIEW_DETAILS_SHAREHOLDER"
          );
        },
      },
      {
        code: "TASK_RENEWAL_ADGM_REVIEW_DETAILS_DATA_PROTECTION_CONTACT", // example 2
        onClick: () => {
          openModal(
            ReviewPersonalDetailShareholderModal,
            {
              initialValues: {
                stakeholderId: dataStakeholder?.getStakeholder?.id,
              },
            },
            "MODAL_PASSPORT_FOR_ENTITY_REPRESENTATIVE_LBS_CORP"
          );
        },
      },
      {
        code: "TASK_RENEWAL_ADGM_REVIEW_DETAILS_AUTHORISED_SIGNATORY", //EXAMPLE 1 with arabic name
        onClick: (params) => {
          openModal(
            ReviewPersonalDetailModal,
            {
              initialValues: {
                groupCompanyId: params?.groupCompanyId,
                stakeholderId: dataStakeholder?.getStakeholder?.id,
              },
            },
            "TASK_RENEWAL_ADGM_REVIEW_DETAILS_AUTHORISED_SIGNATORY"
          );
        },
      },
      {
        code: "TASK_RENEWAL_ADGM_REVIEW_DETAILS_UBO", //EXAMPLE 4
        onClick: (params) => {
          openModal(
            ReviewPersonalDetailUBOModal,
            {
              initialValues: {
                stakeholderId: dataStakeholder?.getStakeholder?.id,
              },
            },
            "TASK_RENEWAL_ADGM_REVIEW_DETAILS_UBO"
          );
        },
      },
      {
        code: "TASK_RENEWAL_ADGM_REVIEW_PERSONAL_DETAILS",
        onClick: (params) => {
          openModal(
            ModalPassport,
            {
              initialValues: {
                idToSave: dataStakeholder?.getStakeholder?.id,
                name: dataStakeholder?.getStakeholder?.fullName,
              },
            },
            "MODAL_PASSPORT_FOR_ENTITY_REPRESENTATIVE_LBS_CORP"
          );
        },
      },
      {
        code: "TASK_RENEWAL_ADGM_OTHER_PASSPORTS",
        onClick: (params) => {
          openModal(
            ModalPassport,
            {
              initialValues: {
                idToSave: dataStakeholder?.getStakeholder?.id,
                name: dataStakeholder?.getStakeholder?.fullName,
                isRenewal: true,
                isOldPassport: true,
              },
            },
            "MODAL_PASSPORT_FOR_ENTITY_REPRESENTATIVE_LBS_CORP"
          );
        },
      },
      {
        code: "TASK_IDENTITY_DOCUMENT_CHECK_EXP_DATE",
        onClick: (params) => {
          openModal(
            StakeholderExpiredDocumentModal,
            {
              initialValues: {
                documentId: params?.documentId,
                document: params?.document,
                documentType: params?.documentType,
                country: params?.country,
                ...params,
              },
            },
            "TASK_IDENTITY_DOCUMENT_CHECK_EXP_DATE"
          );
        },
      },
      {
        code: "TASK_RENEWAL_ADGM_PROOF_OF_ADDRESS",
        onClick: (params) => {
          openModal(
            ModalProofAddress,
            {
              initialValues: {
                stakeholderId: dataStakeholder?.getStakeholder?.id,
                name: dataStakeholder?.getStakeholder?.fullName,
                isRenewal: true,
              },
            },
            "TASK_RENEWAL_ADGM_PROOF_OF_ADDRESS"
          );
        },
      },
      {
        code: "TASK_UAE_ENTRY_STAMP_SEC_SIGNER",
        onClick: (params) => openModal(ModalUAEEntryStamp, {}),
      },
      {
        code: "TASK_RENEWAL_ADGM_REVIEW_CONFIRMATION_DIRECTORS_SHAREHOLDERS",
        onClick: (params) => {
          openModal(
            ReviewAndConfirmCapTableModal,
            {
              initialValues: {
                idToSave: dataStakeholder?.getStakeholder?.id,
                name: dataStakeholder?.getStakeholder?.fullName,
              },
            },
            "TASK_RENEWAL_ADGM_REVIEW_CONFIRMATION_DIRECTORS_SHAREHOLDERS"
          );
        },
      },
      {
        code: "TASK_PASSPORT_CHECK_EXP_DATE",
        onClick: (params) => {
          openModal(
            ModalPassport,
            {
              initialValues: {
                idToSave: dataStakeholder?.getStakeholder?.id,
                name: dataStakeholder?.getStakeholder?.fullName,
              },
            },
            "MODAL_TASK_PASSPORT_CHECK_EXP_DATE"
          );
        },
      },
      {
        code: "TASK_EMIRATES_ID_CHECK_EXP_DATE",
        onClick: (params) => openModal(ModalEmiratesID),
      },
      {
        code: "TASK_UAE_RESIDENCE_VISA_CHECK_EXP_DATE",
        onClick: (params) =>
          openModal(ModalUAEResidenceVisa, {
            paramsMutation: {
              documentTypeOld: "UID_OLDPASSPORT_DOCUMENT",
              documentType: "UID_STAMP_DOCUMENT",
            },
          }),
      },
    ],
  };

  const beautifyDocumentName = (documentType) => {
    switch (documentType) {
      case "PASSPORT_DOCUMENT":
        return "Passport";
      case "UAE_RESIDENCE_VISA_DOCUMENT":
        return "Visa";
      case "EID_DOCUMENT":
        return "Emirates ID";
      case "NATIONAL_ID_DOCUMENT":
        return "National ID";
      default:
        return "Document";
    }
  };

  const getParams = (task) => {
    const groupFilter = _.filter(
      task.additionalParams,
      (param) => param.entityType === "GroupCompany"
    );
    const groupCompany = groupFilter[0];
    const filterCompany = _.filter(
      dataStakeholder?.getStakeholder?.roles,
      (rol) => rol.entity.id === groupCompany?.entityId
    );
    let nameCompany = null;
    let groupCompanyId = _.get(task, "params.groupCompany.id");
    if (filterCompany.length > 0) {
      nameCompany = filterCompany[0].entity.name;
      groupCompanyId = filterCompany[0].entity.id;
    }
    const result = {
      ...task.params,
      equityId: _.get(task, "params.equityId"),
      groupCompanyId: groupCompanyId,
      stakeholderId: idStakeholder,
      groupCompany: {
        name: nameCompany,
      },
    };
    if (
      task?.code === "TASK_PASSPORT_LBS" ||
      task?.code === "TASK_PASSPORT_LBS_KYC_INC"
    ) {
      result.name = dataStakeholder?.getStakeholder?.fullName;
    }

    if (task?.code === "TASK_IDENTITY_DOCUMENT_CHECK_EXP_DATE") {
      if (task?.additionalParams[0]?.type === "PASSPORT_DOCUMENT") {
        result.countryName = task?.additionalParams[0]?.country?.name;
      }
      result.country = task?.additionalParams[0]?.country;
      result.document = beautifyDocumentName(task?.additionalParams[0]?.type);
      result.documentType = task?.additionalParams[0]?.type;
      result.documentId = task?.additionalParams[0]?.entityId;
    }
    return result;
  };

  const { dataTasks } = useTasks(
    allTasks,
    configDataStakeholder,
    "ASSISTANCE_TASKS_STAKEHOLDER",
    getParams
  );

  const resetCounter = () => {
    restCount = 0;
  };

  const { taskStatus, showGJ, countCompleted } = useMemo(() => {
    if (!_.isEmpty(dataTasks)) {
      let showGJ = false;
      let result = "incompleted";
      let countCompleted = 0;
      let totalTasks = [];
      _.forEach(dataTasks, (taskGroups) => {
        countCompleted = countCompleted + taskGroups.completed;
        totalTasks.push(...taskGroups.tasks);
      });
      if (countCompleted === _.size(totalTasks)) {
        result = "completed";
        if (restCount === countCompleted - 1) {
          result = "incompleted";
          showGJ = true;
        }
      } else {
        restCount = countCompleted;
      }
      return {
        taskStatus: result,
        showGJ: showGJ,
        countCompleted: countCompleted,
      };
    }
  }, [JSON.stringify(dataTasks)]);

  const { showTask, showAction, activeTab } = useTabStatus(
    taskStatus,
    null,
    _.get(dataStakeholder, "getStakeholder.isLocked", false)
  );

  const showInactiveTag = useMemo(() => {
    let result = false;
    if (!_.isEmpty(dataStakeholder)) {
      const allRoles = _.get(dataStakeholder, "getStakeholder.roles", []);
      const isInactive = _.every(allRoles, {'isActive': false});
      if (dataStakeholder?.getStakeholder?.isFounder === false) {
        result = isInactive;
      }
    }
    return result;
  }, [dataStakeholder]);

  return (
    <>
      <AssistanceFrame2
        variant={"stakeholder"}
        isInactive={showInactiveTag}
        tasksComponent={StakeholderIndividualTasks}
        // showTask={showTask}
        showTask={true}
        tasks={dataTasks}
        companiesCorporateData={dataStakeholder}
        entity={_.get(dataStakeholder, "getStakeholder")}
        disabled={{
          actions: _.get(dataStakeholder, "getStakeholder.isLocked", false),
        }}
        resetCounter={resetCounter}
        showGJ={showGJ}
        countCompleted={countCompleted}
        tabsStatus={{
          showTask: showTask,
          showAction: showAction,
          showHelper: true,
          activeTab: activeTab,
        }}
      />
    </>
  );
};

export default AssistanceStakeholderIndividual2;

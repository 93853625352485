
export default {

  IS_FORMATION: "isFormation",
  IS_NOT_FORMATION: "isNotFormation",
  IS_NOT_SERVICE_AGENT: "isNotServiceAgent",
  IS_INTRODUCTION: "isIntroduction",
  IS_NOT_INTRODUCTION: "isNotIntroduction",
  HAS_MATTERS: "hasMatters",
  HAS_STARTUPS: "hasStartups",
  NO_HAS_STARTUPS: "noHasStartups",
  IS_FOUNDER_AGREEMENT: "isFounderAgreement",
  IS_NOT_FOUNDER_AGREEMENT: "isNotFounderAgreement",
  IS_SERVICE_AGENT_MANAGER: "isServiceAgentManager",
  IS_SERVICE_AGENT: "isServiceAgent",
  IS_SENIOR_SERVICE_AGENT: "isSeniorServiceAgent",
  IS_NOT_SENIOR_SERVICE_AGENT: "isNotSeniorServiceAgent",
  IS_NOT_SERVICE_AGENT_MANAGER: "isNotServiceAgentManager",
  IS_ADMIN: "isAdmin",
  IS_SUPER_ADMIN: "isSuperAdmin",
  IS_OWNER: "isOwner",
  IS_VIEWER: "isViewer",
  IS_NOT_ADMIN: "isNotAdmin",
  IS_NOT_OWNER: "isNotOwner",
  IS_NOT_VIEWER: "isNotViewer",
  IS_JUST_LOGGED: "isPageLoaded",
  HAS_SUBSCRIPTION_ACCESS_HEALTH_CHECK: "hasSubscriptionAccessHealthCheck",
  HAS_SUBSCRIPTION_ACCESS_SHARE_PROFILE: "hasSubscriptionAccessShareProfile",
  HAS_SUBSCRIPTION_ACCESS_GENERATE_DOCUMENTS: "hasSubscriptionAccessGenerateDocuments",
  HAS_SUBSCRIPTION_ACCESS_DOCUMENTS: "hasSubscriptionAccessDocuments",
  IS_START_SUBSCRIPTION: "isStartSubscription",
  IS_SCALE_SUBSCRIPTION: "isScaleSubscription",
  IS_NOT_MANAGER: "isNotManager",
  IS_MANAGER: "isManager",
  IS_NOT_START_SUBSCRIPTION: "isNotStartSubscription",
  IS_ACTIVE_PLAN: "isActivePlan",
  IS_INACTIVE_PLAN: "isInactivePlan",
  CAN_USE_CLARA_REPORT: "canUseClaraReport",
  IS_DOWNLOAD_DOCUMENT_TO_EDIT: 'isDownloadDocumentToEdit',
  IS_UPLOAD_DOCUMENT_NEW_VERSION: 'isUploadDocumentNewVersion',
  
  HAS_SELECTED_STARTUP: 'hasSelectedStartup'
};

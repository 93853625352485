import get from 'lodash/get';
import React, { FC, useEffect } from 'react';
import useCustomMachine from 'src/modules/generic/context/MachineContext/useMachine';
import Button from "../../../../../generic/components/Buttons/Button";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import SelectDocument from "../../../forms/SelectDocument";
import upperFirst from 'lodash/upperFirst';
import { useDispatch } from "react-redux";
import { addAssistantText, clearAssistatText } from "../../../../../generic/store/action";

const SelectDocumentStep: FC<{}> = () => {

  const { send, next, context } = useCustomMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const handleCancel = () => {
    send('CANCEL')
  }
  const handlePrev = async (values: object) => {
    send("PREVIOUS")
  }

  const handleCompleteSubmit = async (values: object) => {
    next(values);
  }

  const handleInitModal = () => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText(translate('MODULES.EQUITY.STEPS.UPLOAD_DOCUMENTS_STEP.TOOLTIP_2')))
    return () => {
      dispatch(clearAssistatText());
    }
  }

  useEffect(() => {
    handleInitModal()
  }, []);  

  return (
    <>
      <SelectDocument
        documents={get(context, `getDocumentList${upperFirst(context.type.toLowerCase())}.documents`)}
        propsTemplate={{
          title: translate(
            `MODULES.EQUITY.STEPS.SELECT_DOCUMENTS_STEP.TITLE_${context.type}`,
            {
              agreementType: translate(
                `MODULES.EQUITY.AGREEMENT_TYPE.${context.type}`
              ),
            }
          ),
          subTitle: translate(
            `MODULES.EQUITY.STEPS.SELECT_DOCUMENTS_STEP.SUBTITLE_${context.type}`,
            { type: translate(`MODULES.EQUITY.TYPES_PLURAL.${context.type}`) }
          ),
        }}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        buttons={{
          cancel: <Button onClick={handleCancel}>Cancel</Button>,
          previous: <Button onClick={handlePrev}>Previous</Button>
        }}
      />
    </>
  );
}
export default SelectDocumentStep
import useQuery from "../../../../hooks/custom/useQuery"

const query = `query getAcceleratorResponses($startupId: ID) {
  acceleratorQuestionnaireResponses: getAcceleratorResponses(startupId: $startupId)
}`;

const useGetAcceleratorQuestionnarieResponses = (variables,config) => {
  const { loading, error, data, refetch } = useQuery(query, variables, config);
  return { loading, error, data, refetch };
}

export default useGetAcceleratorQuestionnarieResponses;
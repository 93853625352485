import React from 'react';
import useSession from 'src/modules/session/hooks/useSession';
import { Constants } from "src/v1/utils/constants";
import Wizard from '../../../generic/components/Wizard/v2';
import useActions from './hooks/useActions';
import useGuards from './hooks/useGuards';
import useServices from './hooks/useServices';
import useSteps from './hooks/useSteps';
import machine from './machine';
import useNavigation from "src/modules/generic/hooks/useNavigation";

/**
 * Wizard to edit onboarding
 * addded with the new state machine 
 * @returns
 */
const OnboardingWizard = () => {

  const guards = useGuards();
  const steps = useSteps();
  const actions = useActions();
  const services = useServices();
  const { selectStartup } = useSession();
  const { goTo } = useNavigation();

  const handleStopMachine = (context) => {
    let url = Constants.PAGES.MODULES.MAP.dashboard;
    selectStartup(context?.createStartup?.data?.upsertStartupProfile?.id, url);
  }

  const handleCancelMachine = (context) => {
    let url = Constants.PAGES.switchStartup;
    goTo(url);
  }

  return (
    <Wizard
      guards={guards}
      machine={machine}
      steps={steps}
      services={services}
      actions={actions}
      onCancelMachine={handleCancelMachine}
      onStopMachine={handleStopMachine}
    />
  );
}

export default OnboardingWizard;

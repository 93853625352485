export const MAILING_ADDRESS_TYPE = {
  BUSINESS: 'BUSINESS',
  RESIDENTIAL: 'RESIDENTIAL'
}
export const MAILING_ADDRESS_TYPE_LABEL = {
  BUSINESS: 'Business',
  RESIDENTIAL: 'Residential'
}
// Define constants for the values
export const ULTIMATE_BENEFICIAL_OWNERSHIP_TYPE = {
  LP_OWNERSHIP: 'LP_OWNERSHIP',
  LP_SENIOR_MANAGING_OFFICIAL: 'LP_SENIOR_MANAGING_OFFICIAL',
  LP_OTHER_MEANS: 'LP_OTHER_MEANS'
};

// Define constants for the labels
export const ULTIMATE_BENEFICIAL_OWNERSHIP_TYPE_LABEL = {
  LP_OWNERSHIP: 'Legal person (ownership)',
  LP_SENIOR_MANAGING_OFFICIAL: 'Legal person – senior managing official',
  LP_OTHER_MEANS: 'Legal person (other means)'
};

const constants = {
  MAILING_ADDRESS_TYPE,
  MAILING_ADDRESS_TYPE_LABEL,
  ULTIMATE_BENEFICIAL_OWNERSHIP_TYPE,
  ULTIMATE_BENEFICIAL_OWNERSHIP_TYPE_LABEL
};

export default constants;
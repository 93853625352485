import React, {Component} from 'react';
import _ from "lodash";
import {Field} from "formik";
import ClaraSelect from "../../../../components/Form/ClaraSelect/ClaraSelect";
import classes from "../../SteperForm.module.scss";
import TextInput from "../../Inputs/TextInput";
import Checkbox from '../../Inputs/Checkbox'
import ClaraInputRadioButton from "../../../../components/Form/ClaraInputRadioButton/ClaraInputRadioButton";
import FormikHelper from "../../../../utils/formikHelper";
import DinamicInput from "../../Inputs/DinamicInput";
import moment from "moment";
import ClaraUpload from "../../../../components/Form/ClaraUpload/ClaraUpload";

class KISSAgreementDoc extends Component {

    constructor(props) {
        super(props);
        this.state = {
            typeSelected: undefined,
            investorSelected: undefined,
            groupCompanySelected: undefined,
            authorizedSelected: undefined,
            authorizedSignatories: [],
            authorizedSignatoriesList: [],
            documentTypes: [],
            documentTypesList: [],
            dynamicParams: [],
            date: undefined,
            expirationDate: undefined,
            closingDate: undefined
        }
    };

    componentWillMount() {
        if (this.props.values) {
            const values = this.props.values.values ? this.props.values.values : this.props.values;
            const expirationDate = this.getDateForPickerFromData(values.expirationDate);
            const closingDate = this.getDateForPickerFromData(values.termsKISS.series.closingDate);

            this.setState({
                typeSelected: values.documentType,
                assignorSelected: values.assignor,
                groupCompanySelected: values.holdingCompany,
                expirationDate,
                closingDate,
                authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.holdingCompany).map(as => ({
                    id: as.id,
                    label: as.label
                }))
            });


        }

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.values) {
            const values = nextProps.values.values ? nextProps.values.values : nextProps.values;
            const expirationDate = this.getDateForPickerFromData(values.expirationDate);
            const closingDate = this.getDateForPickerFromData(values.termsKISS.series.closingDate);

            this.setState({
                expirationDate,
                closingDate,
                authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.holdingCompany)
                    .map(as => ({
                        id: as.id,
                        label: as.label
                    }))
            });
        }
    }

    selectType = (documentType, extraParams, form) => {
        this.setState({
            typeSelected: documentType,
            dynamicParams: this.getdynamicParamsByType(documentType)
        }, () => {
            FormikHelper.setValueInTheCorrectPosition('terms', form, []);
            FormikHelper.setValueInTheCorrectPosition('documentType', form, documentType);
            this.state.dynamicParams.forEach((dynamicParam, i) => {
                FormikHelper.setValueInTheCorrectPosition(
                    `terms[${i}].key`,
                    form,
                    dynamicParam.key || 'NO_KEY'
                );
            })
        });
    };

    getdynamicParamsByType(documentType) {
        const type = this.props.documentTypes.filter(agrType => agrType.code === documentType)[0] || {};
        return type.fields || [];
    }

    getGroupCompaniesToAssigne = () => {
        let authorizedSignatories = this.props.authorizedSignatories;
        let groupCompanies = this.props.groupCompanies;
        let companies = [];
        if (groupCompanies && authorizedSignatories) {
            companies = _.filter(groupCompanies, (groupCompany) => {
                let fined = _.filter(authorizedSignatories, (authorized) => {
                    if (groupCompany.authorizedSignatories) {
                        return groupCompany.authorizedSignatories.some(au => au.stakeholder.id === authorized.id);
                    }
                    return false;
                })
                if (fined) {
                    return true;
                }
                return false;
            });
        }
        return companies;
    };


    selectGroupCompany = (gcId, extraParams, form) => {
        const groupCompany = this.findGroupCompany(gcId);
        if (groupCompany) {

            if (!groupCompany.registeredAddress) {
                groupCompany.registeredAddress = {}
            }


            const newAuthorizedList = this.filterAuthorizedByGroupCompany(groupCompany);
            const newAuthorizedValue = {
                id: newAuthorizedList[0] ? newAuthorizedList[0].id : '',
                email: newAuthorizedList[0] ? newAuthorizedList[0].email : ''
            };
            const newFormValues = {
                ...form.values,
                holdingCompany: {
                    id: gcId,
                    country: groupCompany.country || '',
                    jurisdiction: groupCompany.jurisdiction || '',
                    registeredNumber: groupCompany.registeredNumber || '',
                    type: groupCompany.type || '',
                    registeredAddress: groupCompany.registeredAddress,
                    authorizedSignatory: newAuthorizedValue,
                    bankInfo: {
                        ...(form.values.holdingCompany ? form.values.holdingCompany.bankInfo : [])
                    }
                },
                termsKISS: {
                    ...form.values.termsKISS,
                    investment: {
                        ...(form.values.termsKISS ? form.values.termsKISS.investment : []),
                        currency: groupCompany.transactionCurrency || ''
                    },
                    valuationCap: {
                        ...(form.values.termsKISS ? form.values.termsKISS.valuationCap : []),
                        currency: groupCompany.transactionCurrency || ''
                    },
                    series: {
                        ...(form.values.termsKISS ? form.values.termsKISS.series : []),
                        currency: groupCompany.transactionCurrency || ''
                    }
                }
            };

            form.setValues(newFormValues);

            this.setState({
                groupCompanySelected: groupCompany,
                authorizedSignatoriesList: newAuthorizedList.map(as => ({
                    id: as.id,
                    label: as.label
                }))
            })
        }
    };

    findGroupCompany(gcId) {
        return this.props.groupCompaniesList.filter(gc => gc.id === gcId)[0] || {};
    };

    selectInvestor = (investor, extraparams, form, cb) => {
        const stakeholder = this.findStakeholder(investor);

        this.setState({
            investorSelected: stakeholder,
        });


        if (stakeholder) {
            if (!stakeholder.address) {
                _.set(stakeholder, 'address', {})
            }


            form.setValues({
                ...form.values,
                investor: {
                    ...form.values.investor,
                    id: investor,
                    email: stakeholder.email || '',
                    passportNumber: stakeholder.passportNumber || '',
                    nationality: stakeholder.nationality || '',
                    address: stakeholder.address
                }
            });
        }
    };

    findStakeholder(roleId) {
        return this.props.stakeholderList.filter(stakeholder => {
            return stakeholder.id === roleId;
            // return stakeholder.roles.some(role => role.id === roleId);
        })[0] || null;
    };

    findStakeholderAutoraized(roleId) {
        return this.props.stakeholderList.filter(stakeholder => {
            return stakeholder.roles.some(role => role.stakeholder.id === roleId);
        })[0] || null;
    };

    filterAuthorizedByGroupCompany(groupCompany) {
        if (groupCompany) {
            let gc = this.findGroupCompany(groupCompany.id);
            return this.props.authorizedSignatories.filter(authorized => {
                return authorized.groupCompanyId === gc.id
            });
        }
    }

    selectAuthorized = (value, values, index) => {
        const stakeholder = this.findStakeholder(value);
        const email = stakeholder ? stakeholder.email : '';
        values.holdingCompany.authorizedSignatory.email = email;
    };

    handleDateChange = (e, field, form) => {
        const date = moment(e).format("YYYY-MM-DD");

        FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

        this.setState({
            date: e
        })
    };

    renderSpecialAgreementFields(form) {
        return this.state.dynamicParams.map((dynamicParam, i) => {
            return (
                <div className={classes.ContainerRow}>
                    <Field name={`terms[${i}].value`}>
                        {({ field, form }) => (
                            <DinamicInput
                                config={dynamicParam}
                                field={field}
                                form={form}
                                handleDateChange={this.handleDateChange}
                                date={this.state.date}
                            />
                        )}
                    </Field>
                </div>
            );
        });
    };

    renderSeriesFields(form) {
        return (
            _.get(form, 'values.termsKISS.series.multipleInvestorsAtTheSameTime', false) ?
                <React.Fragment>
                    <div className={classes.ContainerRow}>
                        <Field name='termsKISS.series.currency'>
                            {({ field, form }) => (
                                <TextInput
                                    required={true}
                                    type='text'
                                    label='Total Series (Currency):'
                                    field={field}
                                    form={form}
                                    placeholder={'Currency...'}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name='termsKISS.series.amount'>
                            {({ field, form }) => (
                                <TextInput
                                    required={true}
                                    type='number'
                                    label='Total Series (Amount):'
                                    field={field}
                                    form={form}
                                    placeholder={'Amount...'}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name='termsKISS.series.closingDate'>
                            {({ field, form }) => (
                                <>
                                    <TextInput
                                        required={true}
                                        type='date'
                                        label='Series Closing Date:'
                                        field={field}
                                        form={form}
                                        placeholder='Date...'
                                        prevComponentChange = {e =>
                                            this.handleChange(e, field, form)
                                        }
                                    />
                                  
                                </>
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name='termsKISS.series.closingDate'>
                            {({ field, form }) => (
                                <TextInput
                                    required={true}
                                    type='date'
                                    label='Series Closing Date:'
                                    field={field}
                                    form={form}
                                    placeholder='Date...'
                                    prevComponentChange = {e =>
                                        this.handleChange(e, field, form)
                                    }
                                >
                                </TextInput>
                            )}
                        </Field>
                    </div>
                </React.Fragment>
                : null
        );
    };

    getDateForPickerFromData(date) {
        if (!date) return undefined;
        return moment(date).toDate();
    }

    handleChange(e, field, form) {

        const date = e === null ? null : moment(e).format("YYYY-MM-DD");

        FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

        this.setState({
            [field.name]: e
        })
    }
    getAuthorizedSignatoriesList = (props) => {
        if (props.values && props.values.holdingCompany) {
            return this.filterAuthorizedByGroupCompany(props.values.holdingCompany).map(as => ({
                id: as.id,
                label: as.label
            }))
        } else {
            return [];
        }
    };

    render() {
        const isRequired = message => value => (!!value ? undefined : message);
        const { tooltips = {}, formType } = this.props;

        return (
            <div>
                <div className={classes.ContainerRow}>
                    {formType == 'uploadToEdit' ? (<div>
                        <Field name={`file`}>
                            {({ field, form }) => (
                                <ClaraUpload
                                    required={true}
                                    name={`filepond`}
                                    label='Upload your document: '
                                    field={field}
                                    form={form}
                                />
                            )}
                        </Field>
                    </div>) : null}
                    <Field name='agreementName'>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='text'
                                label='Document Name: '
                                field={field}
                                form={form}
                                placeholder={"Type here..."}
                                tooltip={tooltips['Document Name']}
                            />
                        )}
                    </Field>
                </div>
                <hr className={classes.Separator} />
                <div className={classes.ZoneTitle}>
                    <h1>{'Investor'}</h1>
                </div>
                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                    <Field name='investor.id'>
                        {({ field, form }) => (
                            <ClaraSelect
                                required={true}

                                field={field}
                                form={form}

                                label={"Investor's Name: "}
                                lists={this.props.stakeholders}
                                mode={"classic"}
                                callbacks={{
                                    updateValues: this.selectInvestor
                                }}
                                placeholder={"Select from a list.."}
                                tooltip={tooltips["Investor's Name"]}
                            />

                        )}
                    </Field>
                </div>
                {
                    this.state.investorSelected ?
                        <React.Fragment>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyAssignor}`}>
                                <h1>Please verify or update Investor's information below:</h1>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='investor.email'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label='Email Address: '
                                            field={field}
                                            form={form}
                                            placeholder={"email..."}
                                            required={true}
                                            tooltip={tooltips['Email Address']}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='investor.nationality'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label='Nationality: '
                                            field={field}
                                            form={form}
                                            placeholder={"nationality..."}
                                            tooltip={tooltips['Nationality']}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='investor.passportNumber'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label='Passport Number: '
                                            field={field}
                                            form={form}
                                            placeholder={"passport..."}
                                            tooltip={tooltips['Passport Number']}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='investor.address.street'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label='Registered Address: '
                                            field={field}
                                            form={form}
                                            placeholder={"address..."}
                                            tooltip={tooltips['Registered Address']}

                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='investor.address.city'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={"City..."}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='investor.address.country'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={"Country..."}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='investor.address.zipCode'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={"Zip Code..."}
                                        />
                                    )}
                                </Field>
                            </div>
                        </React.Fragment>
                        :
                        null
                }
                <hr className={classes.Separator} />
                <div className={classes.ZoneTitle}>
                    <h1>{'Holding Company'}</h1>
                </div>
                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                    <Field name='holdingCompany.id'>
                        {({ field, form }) => (
                            <ClaraSelect
                                required={true}

                                field={field}
                                form={form}

                                label={"Holding Company's Name: "}
                                lists={this.getGroupCompaniesToAssigne()}
                                mode={"classic"}
                                callbacks={{
                                    updateValues: this.selectGroupCompany
                                }}
                                placeholder={"Select from a list.."}
                                tooltip={tooltips["Holding Company's Name"]}
                            />
                        )}
                    </Field>
                </div>
                {
                    this.state.groupCompanySelected ?
                        <React.Fragment>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyAssignor}`}>
                                <h1>Please verify or update Holding Company's information below:</h1>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='holdingCompany.country'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label='Country: '
                                            field={field}
                                            form={form}
                                            placeholder={"Country..."}
                                            tooltip={tooltips['Country']}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='holdingCompany.jurisdiction'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label='Jurisdiction: '
                                            field={field}
                                            form={form}
                                            placeholder={"Jurisdiction..."}
                                            tooltip={tooltips['Jurisdiction']}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='holdingCompany.type'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label='Company Type: '
                                            field={field}
                                            form={form}
                                            placeholder={"Company type..."}
                                            tooltip={tooltips['Company Type']}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='holdingCompany.registeredNumber'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label='Registered Number: '
                                            field={field}
                                            form={form}
                                            placeholder={"Registered number..."}
                                            tooltip={tooltips['Registered number']}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='holdingCompany.registeredAddress.street'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label='Registered Address: '
                                            field={field}
                                            form={form}
                                            placeholder={"Street ..."}
                                            tooltip={tooltips['Registered Address']}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='holdingCompany.registeredAddress.city'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={"City..."}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='holdingCompany.registeredAddress.country'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={"Country..."}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='holdingCompany.registeredAddress.zipCode'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={"Zip Code..."}
                                        />
                                    )}
                                </Field>
                            </div>
                            <hr className={classes.Separator}></hr>
                            <div className={classes.ClaraInputSelect}>
                                <Field name='holdingCompany.authorizedSignatory.id'>
                                    {({ field, form }) => (
                                        <ClaraSelect
                                            required={true}

                                            field={field}
                                            form={form}

                                            label={"Authorised Signatory: "}
                                            lists={this.getAuthorizedSignatoriesList(form)}
                                            mode={"classic"}
                                            callbacks={{
                                                updateValues: (e) => { this.selectAuthorized(e, form.values) }
                                            }}
                                            placeholder={"Select from a list.."}
                                            tooltip={tooltips["IP Assignor's Name"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='holdingCompany.authorizedSignatory.email'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label='Email Address: '
                                            field={field}
                                            form={form}
                                            placeholder={"email..."}
                                        />
                                    )}
                                </Field>
                            </div>
                            <hr className={classes.Separator}></hr>
                            <div className={classes.ContainerRow}>
                                <Field name='termsKISS.investment.currency'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='number'
                                            label='Investment Amount Currency: '
                                            field={field}
                                            form={form}
                                            placeholder={"Currency..."}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='termsKISS.investment.amount'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='number'
                                            label='Investment Amount: '
                                            field={field}
                                            form={form}
                                            placeholder={"Amount..."}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='termsKISS.discountRate.percentage'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label='Discount:'
                                            field={field}
                                            form={form}
                                            placeholder={'Discount...'}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='termsKISS.corporateTransactionPayment.number'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label='Corporate Transaction Multiple:'
                                            field={field}
                                            form={form}
                                            placeholder={'Number...'}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='termsKISS.corporateTransactionPayment.words'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={'Words...'}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='termsKISS.initialMaturityDate.numberOfMonths'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label='Initial Maturity Date (Months)'
                                            field={field}
                                            form={form}
                                            placeholder={'Months...'}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='termsKISS.valuationCap.currency'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label='Valuation Cap (Currency)'
                                            field={field}
                                            form={form}
                                            placeholder={'Currency...'}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='termsKISS.valuationCap.amount'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='number'
                                            label='Valuation Cap (Amount)'
                                            field={field}
                                            form={form}
                                            placeholder={'Amount...'}
                                        />
                                    )}
                                </Field>
                            </div>

                            <hr className={classes.Separator} />

                            <Field name='termsKISS.investorsRights.investInFutureFundraisings'>
                                {({ field, form }) => (
                                    <ClaraInputRadioButton
                                        label='Will the Investor have the right to invest in future fundraisings of the Holding Company?'
                                        tooltip={tooltips}
                                        field={field}
                                        form={form}
                                        value={field.value}
                                        required={true}
                                        options={[
                                            {
                                                "id": true,
                                                "label": "Yes"
                                            },
                                            {
                                                "id": false,
                                                "label": "No"
                                            },

                                        ]}
                                        disabled={false}
                                    />
                                )}
                            </Field>
                            <Field name='termsKISS.investorsRights.investorCanExchangeThisKISS'>
                                {({ field, form }) => (
                                    <ClaraInputRadioButton
                                        label='Can the Investor exchange this KISS for a subsequent KISS or other convertible instrument where the terms of that subsequent KISS or convertible instrument are more Investor-friendly than this KISS?'
                                        tooltip={tooltips}
                                        field={field}
                                        form={form}
                                        value={field.value}
                                        required={true}
                                        options={[
                                            {
                                                "id": true,
                                                "label": "Yes"
                                            },
                                            {
                                                "id": false,
                                                "label": "No"
                                            },

                                        ]}
                                        disabled={false}
                                    />
                                )}
                            </Field>

                            <hr className={classes.Separator} />

                            <Field name='termsKISS.series.multipleInvestorsAtTheSameTime'>
                                {({ field, form }) => (
                                    <ClaraInputRadioButton
                                        label='Are there multiple Investors investing in your Holding Company at the same time?'
                                        tooltip={tooltips}
                                        field={field}
                                        form={form}
                                        value={field.value}
                                        required={true}
                                        options={[
                                            {
                                                "id": true,
                                                "label": "Yes"
                                            },
                                            {
                                                "id": false,
                                                "label": "No"
                                            },

                                        ]}
                                        disabled={false}
                                    />
                                )}
                            </Field>

                            {
                                <Field>
                                    {({ field, form }) => (
                                        this.renderSeriesFields(form)
                                    )}
                                </Field>
                            }

                            <hr className={classes.Separator} />
                            <div className={classes.ContainerRow}>
                                <Field name='holdingCompany.bankInfo.bankName'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label='Bank Name: '
                                            field={field}
                                            form={form}
                                            placeholder={"Name..."}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='holdingCompany.bankInfo.bankBranchName'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label='Bank Branch Name: '
                                            field={field}
                                            form={form}
                                            placeholder={"Branch Name..."}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='holdingCompany.bankInfo.accountHolderName'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label='Bank Account Holder Name: '
                                            field={field}
                                            form={form}
                                            placeholder={"Account Holder Name..."}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='holdingCompany.bankInfo.accountNumber'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label='Bank Account Number: '
                                            field={field}
                                            form={form}
                                            placeholder={"Account Number..."}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='holdingCompany.bankInfo.accountIBAN'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label='Bank Account IBAN: '
                                            field={field}
                                            form={form}
                                            placeholder={"Account IBAN..."}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='holdingCompany.bankInfo.accountSWIFT'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label='Bank Account SWIFT: '
                                            field={field}
                                            form={form}
                                            placeholder={"Account SWIFT..."}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='holdingCompany.bankInfo.accountRoutingCode'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label='Bank Account Routing Code: '
                                            field={field}
                                            form={form}
                                            placeholder={"Account Routing Code..."}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='holdingCompany.bankInfo.accountType'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label='Bank Account Type: '
                                            field={field}
                                            form={form}
                                            placeholder={"Account Type..."}
                                        />
                                    )}
                                </Field>
                            </div>

                        </React.Fragment>
                        :
                        null
                }

                <hr className={classes.Separator} />

                <div className={classes.ContainerRowAgreement}>
                    <Field name='expirationDate'>
                        {({ field, form }) => (
                            <TextInput
                                type='date'
                                label='Expiration date:'
                                field={field}
                                form={form}
                                placeholder='Date'
                                tooltip={tooltips['Expiration date']}
                                prevComponentChange = {e =>
                                    this.handleChange(e, field, form)
                                }
                            >
                            </TextInput>
                        )}
                    </Field>
                </div>

                {
                    <Field>
                        {({ field, form }) => (
                            this.renderSpecialAgreementFields(form)
                        )}
                    </Field>
                }
                <hr className={classes.Separator} />
                {formType == 'uploadToEdit' ? <div>
                    <Field name='confirmSameData'>
                        {({ field, form }) => (
                            <Checkbox
                                className={classes.sameDataCheckbox}
                                field={field}
                                validate={isRequired('This field is required')}
                                form={form}
                                required={true}
                                label='All document details are verified and correct.'
                            />
                        )}
                    </Field>
                </div> : null}
            </div>
        );
    }
}

export default KISSAgreementDoc;

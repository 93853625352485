import useLazyQuery from "src/hooks/custom/useLazyQuery";
const VERIFY_CODE = `
    query verifyAcceleratorCode($code: String!) {
        accelerator: verifyAcceleratorCode(code: $code) {
            code
            name
        }
    }
`;

//TODO: add error message from backend
const useVerifyCodeAccelerator = (variable?:any) => {
    const { manualQuery } = useLazyQuery(VERIFY_CODE,variable,{showErrorNotification:true,errorMessage:'Verification failed. Check the code and try again.'});
    const verifyCode = async (values) => {
            const result = await manualQuery({ variables: values });
            return result?.data?.accelerator ?? null;
        }
    return {
        verifyCode
    };

}
export default useVerifyCodeAccelerator;
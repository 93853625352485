import React from 'react'
import { Field } from "formik";
import _ from "lodash";
import ClaraSelect from "../../../../../../../v1/components/Form/ClaraSelect/ClaraSelect";
import {TextAreaInput, TextInput} from "../../../../../../../v1/components";
import CheckBox from "../../../../../../inputs/Checkbox/Checkbox";
import classes from "../Styles.module.scss";
import FormikHelper from '../../../../../../../v1/utils/formikHelper';

const EditRolesAndServicesNoGroupDetails=({stakeholderField, tooltips, ...props}) => {

	const fieldHasError = (form, field) => {
		return FormikHelper.returnErrorsForValidation(field.name, form.errors);
	};

	const handleOnChange = (form, field, value) => {
		form.setFieldValue(field.name, value);
		form.validateField(field.name)
	};

	const selectRole = (form, field, value) => {
		if (!value) {
			const position = _.get(form, 'values.stakeholder.position', []);
			const responsibilities = Object.keys(position).filter(p => p.substring(0,16) === 'responsibilities');
			responsibilities.forEach(role => {
				const fieldResponsibilities = `stakeholder.position.${role}`;
				form.setFieldValue(fieldResponsibilities, []);
				form.setFieldValue('stakeholder.position.otherResponsibility', null);
			})
		}
	};

	return(<>
        <h1 className={classes.title}>
            Role and Services
        </h1>
        <div className={`${classes.ContainerRow} ${classes.Select}`}>
            <Field name={`stakeholder.position.role`}>
                {({ field, form }) => (
                    <ClaraSelect
                        required={true}
                        field={field}
                        form={form}
                        modalClassName={classes.limitSize}
                        label={"Role: "}
                        lists={[
							{ label: 'Chief Executive Officer (CEO)', id: 'CEO' },
                        	{ label: 'Chief Operating Officer (COO)', id: 'COO' },
                            { label: 'Chief Financial Officer (CFO)', id: 'CFO' },
                            { label: 'Chief Technology Officer (CTO)', id: 'CTO' },
                            { label: 'Chief Information Officer (CIO)', id: 'CIO' },
                            { label: 'Other', id: 'otherRole' }]}
                        mode={"classic"}
						callbacks={{
							updateValues: (value) => selectRole(form, field, value)
						}}
						placeholder={"Select from the list..."}
                        tooltip={tooltips["Select Role"]}
                    />
                )}
            </Field>
        </div>
        <Field>
            {({ field, form }) => (
                _.get(form.values, `stakeholder.position.role`) === 'otherRole' ?
                    <>
                        <Field name={`stakeholder.position.otherRole`}>
                            {({ field, form }) => (
                                <TextInput
                                    classNameInput={{ root: classes.ContainerRow }}
                                    required={true}
                                    type='text'
                                    label='Role title: '
                                    field={field}
                                    form={form}
                                    placeholder={'Role Title...'}
                                    tooltip={tooltips['Other Role']}
                                />
                            )}
                        </Field>
                        <div className={classes.ContainerRow}>
                            <Field name={`stakeholder.position.otherResponsibilities`}>
                                {({ field, form }) => (
                                    <TextAreaInput
                                        required={true}
                                        type='text'
                                        label='Responsibilities: '
                                        field={field}
                                        form={form}
                                        placeholder={'Describe the responsibilities here...'}
                                        tooltip={tooltips['Other Responsibilities']}
                                    />
                                )}
                            </Field>
                        </div>
                    </>
                    : null
            )}
        </Field>
        <Field name={`stakeholder.position.responsibilitiesCEO`}>
            {({ field, form }) => {
                return (
                    _.get(form, `values.stakeholder.position.role`) === 'CEO' ?
                        <>
                            <div className={`${classes.labelGroup}`}>
                                <label>Responsibilities:<span style={{ color: 'red' }}>*</span></label>
                                <CheckBox
									errorMessage={fieldHasError(form, field)}
									onChange={(value) => handleOnChange(form, field, value)}
                                    options={[
                                    	{
                                        	label: 'Leading the management team',
                                        	value: 'option_a'
                                    	},
                                        {
                                            label: 'Setting strategy, direction and culture',
                                            value: 'option_b'
                                        },
                                        {
                                            label: 'Developing the company\'s business plan',
                                            value: 'option_c'
                                        },
                                        {
                                            label: 'External communications',
                                            value: 'option_d'
                                        },
                                        {
                                            label: 'Building the team (employees, consultants and advisors)',
                                            value: 'option_e'
                                        },
                                        {
                                            label: 'Financing',
                                            value: 'option_f'
                                        },
                                        {
                                            label: 'Financial decision-making',
                                            value: 'option_g'
                                        },
                                        {
                                            label: 'Business development',
                                            value: 'option_h'
                                        },
                                        {
                                            label: 'Other',
                                            value: 'option_z',
                                            renderElement: () => (
                                                <Field name={`stakeholder.position.otherResponsibility`}>
                                                    {({ field, form }) => (
                                                        <div className={classes.InputContainer}>
                                                            <TextInput
                                                                type='text'
                                                                label=' '
                                                                field={field}
                                                                form={form}
                                                                placeholder={'Type here...'}
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                            )
                                        }
                                    ]}
                                    values={field.value}
                                    className={{ root: classes.groupCheckBoxWizard, checkbox: classes.checkBoxWizard }}
                                />
                            </div>
                        </> : null
                )
            }}
        </Field>
        <Field name={'stakeholder.position.responsibilitiesCFO'}>
            {({ field, form }) => {
                return (
                    _.get(form, 'values.stakeholder.position.role') === 'CFO' && _.get(form, 'values.stakeholder.position.role') !== 'otherRole' ?
                        <>
                            <div className={`${classes.labelGroup}`}>
                                <label>Responsibilities: <span style={{ color: 'red' }}>*</span></label>
                                <CheckBox
									errorMessage={fieldHasError(form, field)}
									onChange={(value) => handleOnChange(form, field, value)}
                                    options={[
                                        {
                                            label: 'Supervising the financial performance of the company',
                                            input: false,
                                            value: 'option_a'
                                        },
                                        {
                                            label: 'Managing the company\'s capital, liquidity and major expenditure',
                                            input: false,
                                            value: 'option_b'
                                        },
                                        {
                                            label: 'Financial reporting',
                                            input: false,
                                            value: 'option_c'
                                        },
                                        {
                                            label: 'Financial planning and analysis',
                                            input: false,
                                            value: 'option_d'
                                        },
                                        {
                                            label: 'Other',
                                            input: true,
                                            value: 'option_z',
                                            renderElement: () => (
												<Field name={`stakeholder.position.otherResponsibility`}>
                                                    {({ field, form }) => (
														<div className={classes.InputContainer}>
															<TextInput
																type='text'
																label=' '
																field={field}
																form={form}
																placeholder={'Type here...'}
															/>
														</div>
                                                    )}
                                                </Field>
                                            )
                                        }]}
                                    values={field.value}
                                    className={{ root: classes.groupCheckBoxWizard, checkbox: classes.checkBoxWizard }}
                                />
                            </div> </>: null
                )
            }}
        </Field>
        <Field name={`stakeholder.position.responsibilitiesCTO`}>
            {({ field, form }) => {
                return (
                    _.get(form, `values.stakeholder.position.role`) === 'CTO'?
                        <>
                            <div className={`${classes.labelGroup}`}>
                                <label>Responsibilities:<span style={{ color: 'red' }}>*</span></label>

                                <CheckBox
									errorMessage={fieldHasError(form, field)}
									onChange={(value) => handleOnChange(form, field, value)}
                                    options={[
                                    	{
                                        	label: 'Leading the company\'s technology team',
                                        	value: 'option_a'
                                    	},
                                        {
                                            label: 'Responsibility for the company\'s technology, technology research and development and the use of technology to develop products and services',
                                            value: 'option_b'
                                        },
                                        {
                                            label: 'Setting and implementing the company\'s technical strategy',
                                            value: 'option_c'
                                        },
                                        {
                                            label: 'Responsibility for the oversight of the company\'s technology-related intellectual property rights',
                                            value: 'option_d'
                                        },
                                        {
                                            label: 'Oversight of the company\'s computer systems, data, security, maintenance and network',
                                            value: 'option_e'
                                        },
                                        {
                                            label: 'Other',
                                            value: 'option_z',
                                            renderElement: () => (
                                                <Field name={`stakeholder.position.otherResponsibility`}>
                                                    {({ field, form }) => (
														<div className={classes.InputContainer}>
															<TextInput
																type='text'
																label=' '
																field={field}
																form={form}
																placeholder={'Type here...'}
															/>
														</div>
                                                    )}
                                                </Field>
                                            )
                                        }
                                    ]}
                                    values={field.value}
                                    className={{ root: classes.groupCheckBoxWizard, checkbox: classes.checkBoxWizard }}
                                />
                            </div>
                        </> : null
                )
            }}
        </Field>
        <Field name={`stakeholder.position.responsibilitiesCIO`}>
            {({ field, form }) => {
                return (
                    _.get(form, `values.stakeholder.position.role`) === 'CIO' ?
                        <>
                            <div className={`${classes.labelGroup}`}>
                                <label>Responsibilities:<span style={{ color: 'red' }}>*</span></label>
                                <CheckBox
									errorMessage={fieldHasError(form, field)}
									onChange={(value) => handleOnChange(form, field, value)}
                                    options={[
                                        {
                                            label: 'Day to day responsibility for the company\'s computer systems, data, security, maintenance and network',
                                            value: 'option_a'
                                        },
                                        {
                                            label: 'Implementing the company\'s technical strategy',
                                            input: false,
                                            value: 'option_b'
                                        },
                                        {
                                            label: 'Other',
                                            value: 'option_z',
                                            renderElement: () => (
                                                <Field name={`stakeholder.position.otherResponsibility`}>
                                                    {({ field, form }) => (
														<div className={classes.InputContainer}>
															<TextInput
																type='text'
																label=' '
																field={field}
																form={form}
																placeholder={'Type here...'}
															/>
														</div>
                                                    )}
                                                </Field>
                                            )
                                        }]}
                                    values={field.value}
                                    className={{ root: classes.groupCheckBoxWizard, checkbox: classes.checkBoxWizard }}
                                />
                            </div>
                        </> : null
                )
            }}

        </Field>
        <Field name={`stakeholder.position.responsibilitiesCOO`}>
            {({ field, form }) => {
                return (
                    _.get(form, `values.stakeholder.position.role`) === 'COO' ?
                        <>
                            <div className={`${classes.labelGroup}`}>
                                <label>Responsibilities:<span style={{ color: 'red' }}>*</span></label>
                                <CheckBox
									errorMessage={fieldHasError(form, field)}
									onChange={(value) => handleOnChange(form, field, value)}
                                    options={[
                                        {
                                            label: 'Responsibility for day to day business operations and product development',
                                            value: 'option_a'
                                        },
                                        {
                                            label: 'Implementing the company\'s business plan',
                                            value: 'option_b'
                                        },
                                        {
                                            label: 'Managing the team',
                                            value: 'option_c'
                                        },
                                        {
                                            label: 'Setting Company policies',
                                            value: 'option_d'
                                        },
                                        {
                                            label: 'Measuring Company performance',
                                            value: 'option_e'
                                        },
                                        {
                                            label: 'Internal communications',
                                            value: 'option_f'
                                        },
                                        {
                                            label: 'Compliance',
                                            value: 'option_g'
                                        },
                                        {
                                            label: 'Other',
                                            value: 'option_z',
                                            renderElement: () => (
                                                <Field name={`stakeholder.position.otherResponsibility`}>
                                                    {({ field, form }) => (
														<div className={classes.InputContainer}>
															<TextInput
																type='text'
																label=' '
																field={field}
																form={form}
																placeholder={'Type here...'}
															/>
														</div>
													)}
                                                </Field>
                                            )
                                        }]}
                                    className={{ root: classes.groupCheckBoxWizard, checkbox: classes.checkBoxWizard }}
                                    values={field.value}
                                /></div>
                        </> : null
                )
            }}
        </Field>
        <Field name={`stakeholder.position.reports`}>
            {({ field, form }) => (
                _.get(form, `values.stakeholder.position.role`) && _.get(form, `values.stakeholder.position.role`) !== 'CEO' ?
                    <>
                        <div className={`${classes.labelGroup}`}>
                            <label>Reports to:<span style={{ color: 'red' }}>*</span></label>
                            <CheckBox
                                isMultiple={false}
								errorMessage={fieldHasError(form, field)}
								onChange={(value) => handleOnChange(form, field, value)}
                                values={field.value}
                                options={[
                                    {
                                        value: 'CEO',
                                        label: 'Chief Executive Officer',
                                    },
                                    {
                                        value: 'directors',
                                        label: 'Board of Directors',
                                    }

                                ]}
                                className={{ root: classes.groupCheckBoxWizard, checkbox: classes.checkBoxWizard }}
                            />
                        </div></> : null
            )}
        </Field>
        <div className={`${classes.labelGroup}`}>
            <label style={{ width: '12rem !important' }}>Commitment:<span style={{ color: 'red' }}>*</span></label>
            <Field name='stakeholder.position.services.commitment'>
                {({ field, form }) => (
                    <CheckBox
						errorMessage={fieldHasError(form, field)}
						onChange={(value) => handleOnChange(form, field, value)}
                        className={{ root: classes.groupCheckBoxWizard, checkbox: classes.checkBoxWizard }}
                        values={field.value}
                        isMultiple={false}
                        tooltip={tooltips['Commitment']}
                        options={[
                            {
                                value: 'commitmentFull',
                                label: 'Devote the whole of their working time, attention and skill',
                                tooltip:tooltips['Other Amount'],
                            },
                            {
                                value: 'otherCommitment',
                                label: 'Other',
                                tooltip:tooltips['Other Amount'],
                                renderElement: () => (
                                    <Field name='stakeholder.position.services.otherCommitment'>
                                        {({ field, form }) => (
                                            <div style={{'marginLeft':'2rem'}}>
                                                <CheckBox
													errorMessage={fieldHasError(form, field)}
													onChange={(value) => handleOnChange(form, field, value)}
                                                    className={{ root: classes.groupCheckBoxWizard, checkbox: classes.checkBoxWizard }}
                                                    values={field.value}
                                                    isMultiple={false}
                                                    options={[
                                                        {
                                                            value: '10',
                                                            label: 'HOURS10',
                                                        },
                                                        {
                                                            value: '20',
                                                            label: 'HOURS20',
                                                        },
                                                        {
                                                            value: '30',
                                                            label: 'HOURS30',
                                                        },
                                                        {
                                                            value: '40',
                                                            label: 'HOURS40',
                                                        },
                                                        {
                                                            value: 'otherHours',
                                                            label: 'Other',
                                                            renderElement: () => (
                                                                <Field name='stakeholder.position.services.otherHours'>
                                                                    {({ field, form }) => (
                                                                        <div className={classes.InputContainer}>
                                                                            <label style={{ marginTop: '0.5rem' }}>Hours per week: <span style={{ color: 'red' }}>*</span></label>
                                                                            <TextInput
                                                                                type='text'
                                                                                field={field}
                                                                                form={form}
                                                                                rightLabel={'Hours'}
                                                                                placeholder={'Number...'}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            )
                                                        }
                                                    ]}
                                                /></div>
                                        )}
                                    </Field>
                                )
                            }
                        ]}

                    />
                )}
            </Field>
        </div>
    </>)
};

export default EditRolesAndServicesNoGroupDetails;

import { useFormContext } from "react-hook-form";

const useGetShowBanner = (type: 'values' | 'touched', field: string, fieldsToWatch: string[]) => {
    const { formState, getValues, focusedValues } = useFormContext();
    const { dirtyFields } = formState;

    const showBannerByType: { [key: string]: (params: any[] | any) => boolean } = {
        touched: () => {
            const getDirtyValue = (f) => {
                const indexes = f.split('.');
                let value = dirtyFields;
                indexes.forEach(i => {
                    if (value && value[i]) {
                        value = value[i];
                    } else {
                        value = undefined;
                    }
                });
                return value;
            }
            const fields = fieldsToWatch && fieldsToWatch.length ? fieldsToWatch : [field];
            const result = fields.some(f => focusedValues.includes(f) || getDirtyValue(f));
            return result;
        },



        values: (apply: (values: any) => boolean) => apply(getValues([field, ...fieldsToWatch]))
    }

    return showBannerByType[type];
}

export default useGetShowBanner;
import React, {useState} from "react";
//import InputBase from '@material-ui/core/InputBase';
import classes from "./search.module.scss";

const Search = ({ value, onChange }) => {

    const [filter, setFilter] = useState("");


    const handleOnChange = (event) => {


        setFilter(event.target.value);
        onChange(event.target.value)

    }

    return (
        <div className={`${classes.contentInput}`}>
            <i className={`icon-search`} />
            <input
                placeholder="Search"
                value={filter}
                className={`${classes.input}`}
                onChange={handleOnChange}
            />
        </div>
    )
}

export default Search


import React, { FC } from 'react'
import classesModule from "../classes.module.scss";
import Typography from "../../../../../../generic/components/Texts/Typography";
interface ContentProps {
  children: React.ReactNode
  title: string
  subTitle: string
}
const Content: FC<ContentProps> = ({ children, title, subTitle }) => {
  return (
    <div className={`${classesModule.Content}`}>
      {(subTitle || title) && (
        <div className={`${classesModule.Header}`}>
          {subTitle && (
            <Typography component={'label'} variant={'body'} color={'blue'}>
              {subTitle}
            </Typography>
          )}
          {title && (
            <Typography
              component={'h1'}
              color={'black'}
              className={`${classesModule.Spacer}`}
            >
              {title}
            </Typography>
          )}
        </div>
      )}
      <div className={`${classesModule.Form}`}>{children}</div>
    </div>
  )
}
export default Content
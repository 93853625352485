import React, { useState } from "react"
import { DeleteButton } from "src/components/generic/Buttons/DeleteButton"
import useDeleteCustomTemplate from "../../hooks/graphql/useDeleteCustomTemplate"
import useModal from "src/modules/generic/hooks/useModal"
import CustomTemplateWarningModal from "./CustomTemplateWarningModal"
import CustomTemplateDeleteMessageModal from './CustomTemplateDeleteModal'
const DeleteCustomTemplate = ({ onError,onSuccess,id,allowedActions })=> {
    const deleteCustomDocumentTemplate = useDeleteCustomTemplate({})
    const [loading,setLoading] = useState(false)
    const {openModal} = useModal()
    
    const deleteTemplate = async () => {
        openModal(CustomTemplateDeleteMessageModal,{
            onError,
            onSuccess,
            id
        })
    }
    const openWarningModal = () => {
        openModal(CustomTemplateWarningModal,{
            description: allowedActions?.toDelete?.reasonText
        })
    }
    return (
        <DeleteButton
            loading={loading}
            labelLoading={'Deleting...'}
            onClick={async () => {
                if (allowedActions?.toDelete?.value) return deleteTemplate()
                return openWarningModal() 
            }}
        >
            Delete template
        </DeleteButton>
    )
}
export default DeleteCustomTemplate;
const templates = {
    
    // old messages templates
    
    // STAKEHOLDER MESSAGES
    MESSAGE_HEADER_STAKEHOLDER_CREATED:
        "{{userName}} added '{{stakeholderName}}'",
    MESSAGE_SUB_HEADER_STAKEHOLDER_CREATED:
        "The Stakeholder '{{stakeholderName}}' was added by {{userName}} at {{activityTime}} on {{activityDate}}.",
    MESSAGE_TEMPLATE_STAKEHOLDER_CREATED: `
    <label>{{userName}} added the Stakeholder '{{stakeholderName}}'</label>
    <ul>
      <li>The Stakeholder '{{stakeholderName}}' was added by {{userName}} at {{activityTime}} on {{activityDate}}.</li>
    </ul>
  `,

    MESSAGE_HEADER_STAKEHOLDER_EDITED:
        "{{userName}} edited the details of '{{stakeholderName}}'",
    MESSAGE_SUB_HEADER_STAKEHOLDER_EDITED:
        "The details of {{stakeholderName}} were edited by {{userName}} at {{activityTime}} on {{activityDate}}.",
    MESSAGE_TEMPLATE_STAKEHOLDER_EDITED: `
    <label>{{userName}} edited the details of '{{stakeholderName}}'</label>
    <ul>
      {{#if isAnEntity}} 
        {{#fieldsChanged}}
          {{#avatar.newValue}}<li>Avatar has been uploaded</li>{{/avatar.newValue}}
          {{#address}}
            {{#isEqual newValue.country  oldValue.country }}
              {{else}} <li> Address Country changed from {{ getCountryByCode newValue.country}}  to {{ getCountryByCode oldValue.country}}  </li>
            {{/isEqual}}
            {{#isEqual newValue.city  oldValue.city }}
              {{else}} <li> Address City changed from {{ newValue.city}}  to {{  oldValue.city}}  </li>
            {{/isEqual}}
            {{#isEqual newValue.state  oldValue.state }}
              {{else}} <li> Address State changed from {{ newValue.state}}  to {{  oldValue.state}}  </li>
            {{/isEqual}}
            {{#isEqual newValue.street  oldValue.street }}
              {{else}} <li> Address Street changed from {{newValue.street}} to  {{oldValue.street}}  </li>
            {{/isEqual}}
            {{#isEqual newValue.zipCode  oldValue.zipCode }}
              {{else}} <li> Address Zip Code changed from {{newValue.zipCode}} to  {{oldValue.zipCode}}  </li>
            {{/isEqual}}
          {{/address}}
          {{#recipientNoticeAddress}}
            {{#isEqual newValue.country  oldValue.country }}
              {{else}} <li> Recipient's Address Country changed from {{ getCountryByCode oldValue.country}} to {{ getCountryByCode newValue.country}}    </li>
            {{/isEqual}}
            {{#isEqual newValue.city  oldValue.city }}
              {{else}} <li> Recipient's Address City changed from  {{  oldValue.city}} to {{ newValue.city}}    </li>
            {{/isEqual}}
            {{#isEqual newValue.state  oldValue.state }}
              {{else}} <li> Recipient's Address State changed from  {{  oldValue.state}} to {{ newValue.state}}   </li>
            {{/isEqual}}
            {{#isEqual newValue.street  oldValue.street }}
              {{else}} <li> Recipient's Address Street changed from   {{oldValue.street}} to {{newValue.street}}  </li>
            {{/isEqual}}
            {{#isEqual newValue.zipCode  oldValue.zipCode }}
              {{else}} <li> Recipient's Address Zip Code changed from   {{oldValue.zipCode}} to {{newValue.zipCode}}  </li>
            {{/isEqual}}
          {{/recipientNoticeAddress}}
          {{#fullName}}<li>Company Name changed from {{oldValue}} to {{newValue}}</li>{{/fullName}}
          {{#nationality}}<li>Nationality changed from {{oldValue}} to {{newValue}}</li>{{/nationality}}
          {{#jurisdiction}}<li>Jurisdiction changed from {{oldValue}} to {{newValue}}</li>{{/jurisdiction}}
          {{#passportNumber}}<li>Passport Number changed from {{oldValue}} to {{newValue}}</li>{{/passportNumber}}  
          {{#type}}<li>Type Company changed from {{oldValue}} to {{newValue}}</li>{{/type}}
          {{#registeredNumber}}<li>Company Number changed from: {{oldValue}} to {{newValue}}</li>{{/registeredNumber}}
          {{#recipientNoticeFullName}}<li>Recipient for Notices changed from {{oldValue}} to {{newValue}}</li>{{/recipientNoticeFullName}}
          {{#recipientNoticeEmail}}<li>Recipient's Email Address changed from {{oldValue}} to {{newValue}}</li>{{/recipientNoticeEmail}}
          {{#authorizedSignatory}}<li>Authorised Signatory changed from {{oldValue}} to {{newValue}}</li>{{/authorizedSignatory}}
          {{#authorizedSignatoryRol}}<li>Authorised Signatory Role changed from {{oldValue}} to {{newValue}}</li>{{/authorizedSignatoryRol}}
          {{#email}}<li>Email changed from {{oldValue}} to {{newValue}}</li>{{/email}}
        {{/fieldsChanged}}
        <br/>
        {{#updatedPosition}}
          <li>{{@root.userName}} edited the position details of {{@root.companyName}}.</li>
        {{/updatedPosition}}
        {{#addedPosition}}
          <li>{{@root.userName}}  added a position to {{@root.companyName}} as {{positionName}}.</li>
        {{/addedPosition}}

        {{#addedRoles}}
          <li>{{@root.userName}} added a {{role}} role to {{@root.stakeholderName}}.</li>
        {{/addedRoles}}
        {{#fieldsAdded}}
          {{#address}}
            {{#country}} <li> Address Country added: {{ getCountryByCode . }}</li> {{/country}}
            {{#city}} <li> Address City added: {{.}}</li> {{/city}}
            {{#state}}<li> Address State added: {{.}}</li> {{/state}}
            {{#street}}<li> Address Street added: {{.}}</li> {{/street}}
            {{#zipCode}}<li> Address Zip Code added: {{.}}</li> {{/zipCode}}
          {{/address}}
          {{#fullName}}<li>Full Name  added: {{.}}</li>{{/fullName}}
          {{#nationality}}<li>Nationality added: {{.}}</li>{{/nationality}}
          {{#passportNumber}}<li>PassPort Number added: {{.}}</li>{{/passportNumber}}
          {{#avatar}}<li>Avatar added</li>{{/avatar}}
          {{#type}}<li>Type Company added: {{.}}</li>{{/type}}
          {{#registeredNumber}}<li>Company Number added: {{.}}</li>{{/registeredNumber}}
          {{#recipientNoticeFullName}}<li>Recipient for Notices added: {{.}}</li>{{/recipientNoticeFullName}}
          {{#recipientNoticeEmail}}<li>Recipient's Email Address added: {{.}}</li>{{/recipientNoticeEmail}}
          {{#authorizedSignatory}}<li>Authorised Signatory added: {{.}}</li>{{/authorizedSignatory}}
          {{#authorizedSignatoryRol}}<li>Authorised Signatory Role added: {{.}}</li>{{/authorizedSignatoryRol}}
          {{#email}}<li>Email added : {{.}} </li>{{/email}}
        {{/fieldsAdded}}
        <br/>
        {{#deletedRoles}}
          <li> {{@root.userName}} deleted a {{role}} role from {{@root.stakeholderName}}. </li>
        {{/deletedRoles}}
        {{#updatedRoles}}
          <li> {{@root.userName}} edited {{role}} role details for{{@root.companyName}}. </li>
        {{/updatedRoles}}
        {{#removed}}
          {{#address}}
            {{#country}} <li> Address Country removed: {{getCountryByCode . }}</li> {{/country}}
            {{#city}} <li> Address City removed: {{.}}</li> {{/city}}
            {{#state}}<li> Address State removed: {{.}}</li> {{/state}}
            {{#street}}<li> Address Street removed: {{.}}</li> {{/street}}
            {{#zipCode}}<li> Address Zip Code removed: {{.}}</li> {{/zipCode}}
          {{/address}}
          {{#fullName}}<li>FullName  removed: {{.}}</li>{{/fullName}}
          {{#nationality}}<li>Nationality removed: {{ getCountryByCode .}}</li>{{/nationality}}
          {{#passportNumber}}<li>Passport Number removed: {{.}}</li>{{/passportNumber}}
          {{#type}}<li>Type Company removed: {{.}}</li>{{/type}}
          {{#registeredNumber}}<li>Company Number removed: {{.}}</li>{{/registeredNumber}}
          {{#recipientNoticeFullName}}<li>Recipient for Notices removed: {{.}}</li>{{/recipientNoticeFullName}}
          {{#recipientNoticeEmail}}<li>Recipient's Email Address removed: {{.}}</li>{{/recipientNoticeEmail}}
          {{#authorizedSignatory}}<li>Authorised Signatory removed: {{.}}</li>{{/authorizedSignatory}}
          {{#authorizedSignatoryRol}}<li>Authorised Signatory Role removed: {{.}}</li>{{/authorizedSignatoryRol}}
          {{#email}}<li>Email removed : {{.}} </li>{{/email}}
        {{/removed}}
      {{else}}
        {{#fieldsChanged}}
          {{#avatar.newValue}}<li>Avatar has been uploaded</li>{{/avatar.newValue}}
          {{#address}}
            {{#isEqual newValue.country  oldValue.country }}
              {{else}} <li> Address Country changed from  {{ getCountryByCode oldValue.country}} to {{ getCountryByCode newValue.country}}    </li>
            {{/isEqual}}
            {{#isEqual newValue.city  oldValue.city }}
              {{else}} <li> Address City changed from  {{  oldValue.city}} to {{ newValue.city}}    </li>
            {{/isEqual}}
            {{#isEqual newValue.state  oldValue.state }}
              {{else}} <li> Address State changed from  {{  oldValue.state}} to {{ newValue.state}}    </li>
            {{/isEqual}}
            {{#isEqual newValue.street  oldValue.street }}
              {{else}} <li> Address Street changed from   {{oldValue.street}} to {{newValue.street}}   </li>
            {{/isEqual}}
            {{#isEqual newValue.zipCode  oldValue.zipCode }}
              {{else}} <li> Address Zip Code changed from   {{oldValue.zipCode}} to {{newValue.zipCode}}   </li>
            {{/isEqual}}
          {{/address}}
          {{#fullName}}<li>Full Name changed from {{oldValue}} to {{newValue}}</li>{{/fullName}}
          {{#nationality}}<li>Nationality changed from {{ getCountryByCode oldValue}} to {{ getCountryByCode newValue}}</li>{{/nationality}}
          {{#passportNumber}}<li>Passport Number changed from {{oldValue}} to {{newValue}}</li>{{/passportNumber}}
          {{#email}}<li>Email changed from {{oldValue}} to {{newValue}}</li>{{/email}}
        {{/fieldsChanged}}
        {{#updatedPosition}}
          <li>{{@root.userName}} edited the position details of {{@root.stakeholderName}}.</li>
        {{/updatedPosition}}
        {{#addedPosition}}
          <li>{{@root.userName}}  added a position to {{@root.stakeholderName}} as {{positionName}}.</li>
        {{/addedPosition}}
        {{#addedRoles}}
        <br/>
          <li>{{@root.userName}} added a {{role}} role to {{@root.stakeholderName}}.</li>
        {{/addedRoles}}
        {{#fieldsAdded}}
        <br/>
          {{#avatar}}<li>Avatar added</li>{{/avatar}}
          {{#address}}
            {{#country}} <li> Address Country added: {{getCountryByCode . }}</li> {{/country}}
            {{#city}} <li> Address City added: {{.}}</li> {{/city}}
            {{#state}}<li> Address State added: {{.}}</li> {{/state}}
            {{#street}}<li> Address Street added: {{.}}</li> {{/street}}
            {{#zipCode}}<li> Address Zip Code added: {{.}}</li> {{/zipCode}}
          {{/address}}
          {{#fullName}}<li>Full Name  added: {{.}}</li>{{/fullName}}
          {{#nationality}}<li>Nationality added: {{ getCountryByCode . }}</li>{{/nationality}}
          {{#passportNumber}}<li>PassPort Number added: {{.}}</li>{{/passportNumber}}
          {{#email}}<li>Email added : {{.}} </li>{{/email}}
        {{/fieldsAdded}}
        {{#deletedRoles}}
        <br/>
          <li> {{@root.userName}} deleted a {{role}} role from {{@root.stakeholderName}}. </li>
        {{/deletedRoles}}
        {{#updatedRoles}}
        <br/>
        <li> {{@root.userName}} edited  {{role}} role details for{{@root.stakeholderName}}. </li>
        {{/updatedRoles}}
        {{#removed}}
          {{#address}}
            {{#country}} <li> Address Country removed: {{ getCountryByCode . }}</li> {{/country}}
            {{#city}} <li> Address City removed: {{.}}</li> {{/city}}
            {{#state}}<li> Address State removed: {{.}}</li> {{/state}}
            {{#street}}<li> Address Street removed: {{.}}</li> {{/street}}
            {{#zipCode}}<li> Address Zip Code removed: {{.}}</li> {{/zipCode}}
          {{/address}}
          {{#fullName}}<li>Full Name  removed: {{.}}</li>{{/fullName}}
          {{#nationality}}<li>Nationality removed: {{ getCountryByCode . }}</li>{{/nationality}}
          {{#passportNumber}}<li>Passport Number removed: {{.}}</li>{{/passportNumber}}
          {{#email}}<li>Email removed : {{.}} </li>{{/email}}
        {{/removed}}
      {{/if}}
    </ul>
  `,

    MESSAGE_HEADER_STAKEHOLDER_DELETED:
        "{{userName}} removed '{{stakeholderName}}'",
    MESSAGE_SUB_HEADER_STAKEHOLDER_DELETED:
        "The Stakeholder '{{stakeholderName}}' was deleted by {{userName}} at {{activityTime}} on {{activityDate}}.",
    MESSAGE_TEMPLATE_STAKEHOLDER_DELETED: `
    <label>{{userName}} removed '{{stakeholderName}}'</label>
    <ul>
      <li>{{stakeholderName}} was deleted by {{userName}} at {{activityTime}} on {{activityDate}}.</li>
    </ul>
  `,

    MESSAGE_BUTTON_REDIRECT_GO_TO_STAKEHOLDER: `View Stakeholder Details`,
    MESSAGE_BUTTON_REDIRECT_GO_TO_STAKEHOLDER_SHARE_OPTIONS: `Go to Options`,
    MESSAGE_BUTTON_REDIRECT_GO_TO_DOCUMENT_LIST: "View Documents",
    MESSAGE_BUTTON_REDIRECT_GO_TO_GENERATE_GRANT_DOCUMENT: "Create Grant",
    MESSAGE_BUTTON_REDIRECT_GO_TO_GENERATE_SIP_DOCUMENT: "Create SIP",
    MESSAGE_BUTTON_REDIRECT_GO_TO_CREATE_DOCUMENTS: "Create SIP",
    // COMPANY MESSAGES
    MESSAGE_HEADER_COMPANY_CREATED: "{{userName}} added '{{companyName}}'",
    MESSAGE_SUB_HEADER_COMPANY_CREATED:
        "The Company '{{companyName}}' was added by {{userName}} at {{activityTime}} on {{activityDate}}.",
    MESSAGE_TEMPLATE_COMPANY_CREATED: `
    <label>{{userName}} added the Company '{{companyName}}'</label>
    <ul>
      <li>The company '{{companyName}}' was added by {{userName}} at {{activityTime}} on {{activityDate}}.</li>
      <li>Country: {{ getCountryByCode country }}.</li>
      <li>Jurisdiction: {{jurisdiction}}</li>
      </ul>
  `,

    MESSAGE_HEADER_COMPANY_EDITED:
        "{{userName}} edited the details of '{{companyName}}'",
    MESSAGE_SUB_HEADER_COMPANY_EDITED:
        "The details of {{companyName}} were edited by {{userName}} at {{activityTime}} on {{activityDate}}.",
    MESSAGE_TEMPLATE_COMPANY_EDITED: `
    <label>{{userName}} edited the details of '{{companyName}}'</label>
    <ul>
      {{#fieldsChanged}}
        {{#name}} <li>Company Name changed from {{oldValue}} to {{newValue}}</li> {{/name}}
        {{#country}} <li>Country changed from {{getCountryByCode  oldValue}} to {{getCountryByCode newValue}}</li> {{/country}}
        {{#jurisdiction}} <li>Jurisdiction changed from {{oldValue}} to {{newValue}}</li> {{/jurisdiction}}
        {{#type}}<li>Company type changed from {{oldValue}} to {{newValue}}</li>{{/type}}
        {{#registeredNumber}}<li>Company Number  changed from {{oldValue}} to {{newValue}}</li>{{/registeredNumber}}
        {{#isEqual registeredAddress_country.newValue  registeredAddress_country.oldValue }}
          {{else}} <li> Registered Address Country changed from {{ getCountryByCode registeredAddress_country.oldValue }} to {{ getCountryByCode registeredAddress_country.newValue }}</li>
        {{/isEqual}}
        {{#isEqual registeredAddress_city.oldValue registeredAddress_city.newValue   }}
          {{else}} <li> Registered Address City changed from {{ registeredAddress_city.oldValue }} to {{ registeredAddress_city.newValue }}    </li>
        {{/isEqual}}
        {{#isEqual registeredAddress_state.newValue  registeredAddress_state.oldValue }}
          {{else}} <li> Registered Address State changed from  {{  registeredAddress_state.oldValue }} to {{ registeredAddress_state.newValue }}   </li>
        {{/isEqual}}
        {{#isEqual registeredAddress_street.oldValue registeredAddress_street.newValue   }}
          {{else}} <li> Registered Address Street changed from {{ registeredAddress_street.oldValue }} to {{ registeredAddress_street.newValue }}    </li>
        {{/isEqual}}
        {{#isEqual registeredAddress_zipCode.newValue  registeredAddress_zipCode.oldValue }}
          {{else}} <li> Registered Address Zip Code changed from   {{registeredAddress_zipCode.oldValue}} to {{registeredAddress_zipCode.newValue}}  </li>
        {{/isEqual}}
        {{#notUseRecipientNotice}}<li>Recipient Notice  changed from {{#if oldValue}} Selected {{else}} Unselected {{/if}} to {{#if newValue}} Selected {{else}} Unselected {{/if}} </li>{{/notUseRecipientNotice}}
        {{#isHoldingCompany}}<li>Is Holding Company  changed from {{#if oldValue}} Selected {{else}} Unselected {{/if}} to {{#if newValue}} Selected {{else}} Unselected {{/if}} </li>{{/isHoldingCompany}}
        {{#recipientNoticeAddress}}
          {{#isEqual newValue.country  oldValue.country }}
            {{else}} <li> Recipient's Address Country changed from {{ getCountryByCode oldValue.country}} to {{ getCountryByCode newValue.country}}    </li>
          {{/isEqual}}
          {{#isEqual newValue.city  oldValue.city }}
            {{else}} <li> Recipient's Address City changed from  {{  oldValue.city}} to {{ newValue.city}}    </li>
          {{/isEqual}}
          {{#isEqual newValue.state  oldValue.state }}
            {{else}} <li> Recipient's Address State changed from  {{  oldValue.state}} to {{ newValue.state}}   </li>
          {{/isEqual}}
          {{#isEqual newValue.street  oldValue.street }}
            {{else}} <li> Recipient's Address Street changed from   {{oldValue.street}} to {{newValue.street}}  </li>
          {{/isEqual}}
          {{#isEqual newValue.zipCode  oldValue.zipCode }}
            {{else}} <li> Recipient's Address Zip Code changed from   {{oldValue.zipCode}} to {{newValue.zipCode}}  </li>
          {{/isEqual}}
        {{/recipientNoticeAddress}}
        {{/fieldsChanged}}
        <br/>
        {{#fieldsAdded}}
          {{#country}} <li>Country added: {{ getCountryByCode . }}</li> {{/country}}
          {{#jurisdiction}} <li>Jurisdiction added: {{.}}</li> {{/jurisdiction}}
          {{#type}}<li>Company Type Company added: {{.}}</li>{{/type}}
          {{#registeredNumber}}<li>Registered Number added: {{.}}</li>{{/registeredNumber}}
          {{#registeredAddress_country}} <li> Address Country added: {{getCountryByCode . }}</li> {{/registeredAddress_country}}
          {{#registeredAddress_city}} <li> Registered Address City added: {{.}}</li> {{/registeredAddress_city}}
          {{#registeredAddress_state}}<li> Registered Address State added: {{.}}</li> {{/registeredAddress_state}}
          {{#registeredAddress_street}}<li> Registered Address Street added: {{.}}</li> {{/registeredAddress_street}}
          {{#registeredAddress_zipCode}}<li> Registered Address Zip Code added: {{.}}</li> {{/registeredAddress_zipCode}}
          {{#notUseRecipientNotice}}<li>Recipient for Notices added Selected </li>{{/notUseRecipientNotice}}
          {{#recipientNoticeFullName}}<li>Recipient for Notices added: {{.}}</li>{{/recipientNoticeFullName}}
          {{#recipientNoticeEmail}}<li>Recipient's Email Address added: {{.}}</li>{{/recipientNoticeEmail}}
          {{#authorizedSignatory}}<li>Authorised Signatory added: {{.}}</li>{{/authorizedSignatory}}
          {{#authorizedSignatoryRol}}<li>Authorised Signatory Role added: {{.}}</li>{{/authorizedSignatoryRol}}
          {{#recipientNoticeAddress}}
            {{#country}} <li> Recipient's Address Country added: {{ getCountryByCode . }}</li> {{/country}}
            {{#city}} <li> Recipient's Address  City added: {{.}}</li> {{/city}}
            {{#state}}<li> Recipient's Address  State added: {{.}}</li> {{/state}}
            {{#street}}<li> Recipient's Address Street added: {{.}}</li> {{/street}}
            {{#zipCode}}<li> Recipient's Address Zip Code added: {{.}}</li> {{/zipCode}}
          {{/recipientNoticeAddress}}
          {{#transactionCurrency}}<li> Transaction Currency added: {{.}}</li> {{/transactionCurrency}}
        {{/fieldsAdded}}
        <br/>
        {{#fieldsRemoved}}
          {{#country}} <li>Country removed: {{getCountryByCode .}}</li> {{/country}}
          {{#jurisdiction}} <li>Jurisdiction removed: {{.}}</li> {{/jurisdiction}}
          {{#type}}<li>Company Type Company removed: {{.}}</li>{{/type}}
          {{#registeredNumber}}<li>Registered Number removed: {{.}}</li>{{/registeredNumber}}  
          {{#registeredAddress_country}} <li> Address Country Removed: {{getCountryByCode . }}</li> {{/registeredAddress_country}}
          {{#registeredAddress_city}} <li> Registered Address City Removed: {{.}}</li> {{/registeredAddress_city}}
          {{#registeredAddress_state}}<li> Registered Address State Removed: {{.}}</li> {{/registeredAddress_state}}
          {{#registeredAddress_street}}<li> Registered Address Street Removed: {{.}}</li> {{/registeredAddress_street}}
          {{#registeredAddress_zipCode}}<li> Registered Address Zip Code Removed: {{.}}</li> {{/registeredAddress_zipCode}}
          {{#recipientNoticeAddress}}
            {{#country}} <li> Recipient's Address Country removed: {{getCountryByCode .}}</li> {{/country}}
            {{#city}} <li> Recipient's Address  City removed: {{.}}</li> {{/city}}
            {{#state}}<li> Recipient's Address  State removed: {{.}}</li> {{/state}}
            {{#street}}<li> Recipient's Address Street removed: {{.}}</li> {{/street}}
            {{#zipCode}}<li> Recipient's Address Zip Code removed: {{.}}</li> {{/zipCode}}
          {{/recipientNoticeAddress}}
          {{#transactionCurrency}}<li> Transaction Currency removed: {{.}}</li> {{/transactionCurrency}}
          {{#notUseRecipientNotice}}<li>Recipient for Notices Removed  </li>{{/notUseRecipientNotice}}
          {{#recipientNoticeFullName}}<li>Recipient for Notices removed: {{.}}</li>{{/recipientNoticeFullName}}
          {{#recipientNoticeEmail}}<li>Recipient's Email Address removed: {{.}}</li>{{/recipientNoticeEmail}}
          {{#authorizedSignatory}}<li>Authorised Signatory removed: {{.}}</li>{{/authorizedSignatory}}
          {{#authorizedSignatoryRol}}<li>Authorised Signatory Role removed: {{.}}</li>{{/authorizedSignatoryRol}}
        {{/fieldsRemoved}}
      </ul>
    `,

    MESSAGE_HEADER_COMPANY_DELETED: "{{userName}} removed '{{companyName}}'",
    MESSAGE_SUB_HEADER_COMPANY_DELETED:
        "The Company '{{companyName}}' was deleted by {{userName}} at {{activityTime}} on {{activityDate}}.",
    MESSAGE_TEMPLATE_COMPANY_DELETED: `
    <label>{{userName}} removed '{{companyName}}'</label>
    <ul>
      <li>{{companyName}} was deleted by {{userName}} at {{activityTime}} on {{activityDate}}.</li>
    </ul>
  `,

    MESSAGE_BUTTON_REDIRECT_GO_TO_COMPANY: "View Company Details",

    // REMARK MESSAGES
    MESSAGE_HEADER_DUE_DILIGENCE_CREATED:
        "{{userName}} created a {{urgency}} Health Check Remark",
    MESSAGE_SUB_HEADER_DUE_DILIGENCE_CREATED:
        "A new {{urgency}} Health Check Remark was created by {{userName}}  at {{activityTime}} on {{activityDate}}.",
    MESSAGE_TEMPLATE_DUE_DILIGENCE_CREATED: `
    <label>{{userName}} created a '{{remarkName}}' Health Check Remark<br></label>
    <ul>
      <li>Description: <strong>{{description}}</strong></li>
      <br>
      <li>This Remark is categorized as <strong>'{{category}} - {{subCategory}}'</strong></li>
      <br>
      <li>This is a <strong>{{urgency}}</strong> Remark.</li>
    </ul>
  `,

    MESSAGE_HEADER_DUE_DILIGENCE_EDITED:
        "{{userName}} edited the details of a {{urgency}} Health Check Remark",
    MESSAGE_SUB_HEADER_DUE_DILIGENCE_EDITED:
        "A {{urgency}} Health Check Remark was edited by {{userName}} at {{activityTime}} on {{activityDate}}.",
    MESSAGE_TEMPLATE_DUE_DILIGENCE_EDITED: `
    <label>{{userName}} edited the details of the '{{remarkName}}' Health Check Remark<br></label>
    <ul>
      {{#title}}
        <li>The Remark's title has been changed.<br/>Previous title: <strong>{{oldValue}}</strong><br/>New title: <strong>{{newValue}}</strong></li>
      {{/title}}
      <br/>
      {{#description }}
        <li>The Remark's description has been changed.<br/> Previous description: <strong>{{oldValue}}</strong><br/>New description: <strong>{{newValue}}</strong></li>
      {{/description}}
      <br/>
      {{#isEqual templateStatus '' }}
        {{else}}
        <li>The Remark's status has been changed.<br/>{{{templateStatus}}}</li>
      {{/isEqual}}
      <br>
      {{#documents}}
        {{#if oldValue}}
          <li>The Remark is no longer associated with the following Documents:</li>
        {{/if}}
        {{#oldValue}}
          <li><strong>{{.}}</strong></li>
        {{/oldValue}}
        {{#if newValue}}
          <li>The Remark is now associated with the following Documents:</li>
        {{/if}}
        {{#newValue}}
        <li><strong>{{.}}</strong></li>
        {{/newValue}}
      {{/documents}}
      {{#stakeholders}}
        {{#if oldValue}}
          <li>The Remark is no longer associated with the following Stakeholders:</li>
        {{/if}}
        {{#oldValue}}
        <li><strong>{{.}}</strong></li>
        {{/oldValue}}
        {{#if newValue}}
          <li>The Remark is now associated with the following Stakeholders:</li>
        {{/if}}
        {{#newValue}}
        <li><strong>{{.}}</strong></li>
        {{/newValue}}
      {{/stakeholders}}
    </ul>

  `,

    MESSAGE_HEADER_DUE_DILIGENCE_COMMENTED:
        "{{userName}} commented on a {{urgency}} Health Check Remark",
    MESSAGE_SUB_HEADER_DUE_DILIGENCE_COMMENTED:
        "{{userName}} commented on a {{urgency}} Health Check Remark at {{activityTime}} on {{activityDate}}.",
    MESSAGE_TEMPLATE_DUE_DILIGENCE_COMMENTED: `
    <label>{{userName}} commented on the '{{remarkName}}' Health Check Remark<br></label>
    <ul>
      <li><strong>'{{comment}}'</strong></li>
    </ul>
  `,

    MESSAGE_HEADER_DUE_DILIGENCE_DELETED:
        "{{userName}} deleted a {{urgency}} Health Check Remark",
    MESSAGE_SUB_HEADER_DUE_DILIGENCE_DELETED:
        "A {{urgency}} Health Check Remark was deleted by {{userName}} at {{activityTime}} on {{activityDate}}.",
    MESSAGE_TEMPLATE_DUE_DILIGENCE_DELETED: `
    <label>{{userName}} deleted the '{{remarkName}}' Health Check Remark<br></label>
    <ul>
      <li>Description: <strong>{{description}}</strong></li>
      <br>
      <li>This Remark was categorized as <strong>'{{category}} - {{subCategory}}'</strong></li>
      <br>
      <li>This was a <strong>{{urgency}}</strong> Remark.</li>
    </ul>
  `,

    MESSAGE_BUTTON_REDIRECT_GO_TO_REMARK: "View Remark Details",

    // EQUITY POSITION MESSAGES
    MESSAGE_HEADER_EQUITY_POSITION_CREATED:
        "{{userName}} created {{equityPositionName}}s for {{equityHolderName}}",
    MESSAGE_SUB_HEADER_EQUITY_POSITION_CREATED:
        "{{equityHolderName}}'s {{equityPositionName}}s in {{equityCompanyName}} was created by {{userName}} at {{activityTime}} on {{activityDate}}.",
    MESSAGE_TEMPLATE_EQUITY_POSITION_CREATED: `
    <label>{{userName}} created a {{equityPositionName}} equity position in {{equityCompanyName}} for {{equityHolderName}}</label> 
    <ul>
      {{#shares}}
        <li>An equity position of {{numberOfShares}} {{shareClass.name}} shares in {{../equityCompanyName}} for {{../equityHolderName}} has been added to the profile</li>
      {{/shares}}
      {{#options}}
        <li>An equity position of {{numberOfOptions}} {{shareClass.name}} options in {{../equityCompanyName}} for {{../equityHolderName}} has been added to the profile</li>
      {{/options}}
      {{#convertible}}
        <li>An equity position of a convertible in {{../equityCompanyName}} for {{../equityHolderName}}'s investment of {{investmentAmount}} has been added to the profile</li>
      {{/convertible}}
      {{#warrants}}
        <li>An equity position of a warrant in {{../equityCompanyName}} for {{../equityHolderName}}'s investment of {{investmentAmount}} has been added to the profile</li>
    {{/warrants}}
  </ul>
  `,


    MESSAGE_HEADER_EQUITY_POSITION_EDITED:
        "{{userName}} edited the details of {{equityHolderName}}'s {{equityPositionName}}s",
    MESSAGE_SUB_HEADER_EQUITY_POSITION_EDITED:
        "The details of {{equityHolderName}}'s {{equityPositionName}}s in {{equityCompanyName}} were edited by {{userName}} at {{activityTime}} on {{activityDate}}.",
    MESSAGE_TEMPLATE_EQUITY_POSITION_EDITED: `
    <label>{{userName}} edited the details of {{equityHolderName}}'s {{equityPositionName}} equity position</label> 
    <ul>
      {{#fieldsChanged}}

      {{#../SHARE}} 
      {{#price}}<li>  Price per Share  changed from {{oldValue}} to {{newValue}} </li>{{/price}}
      {{#amount}}<li>  Number of Shares  changed from {{oldValue}} to {{newValue}} </li>{{/amount}}
      {{#issuedDate}}<li>Issued Date  changed from {{oldValue}} to {{newValue}} </li>{{/issuedDate}}
      {{/../SHARE}}

      {{#../OPTION}} 
      {{#price}}<li>  Exercise Price  changed from {{oldValue}} to {{newValue}} </li>{{/price}}
      {{#amount}}<li>  Number of Share Options  changed from {{oldValue}} to {{newValue}} </li>{{/amount}}
      {{#shareIncentivePlan.name}}<li>  Share Incentive Plan: {{.}} </li>{{/shareIncentivePlan.name}}
      {{#issuedDate}}<li>Issued Date  changed from {{oldValue}} to {{newValue}} </li>{{/issuedDate}}
      {{/../OPTION}}

      {{#../CONVERTIBLE}}
          {{#amount}}<li> Investment Amount changed from {{oldValue}} to {{newValue}} </li>{{/amount}}
          {{#discountRate}}<li> Discount changed from {{oldValue}} to {{newValue}} </li>{{/discountRate}}
          {{#valuationCap}}<li> Valuation Amount changed from {{oldValue}} to {{newValue}} </li>{{/valuationCap}}
          {{#interestRate}}<li> Interest Rate changed from {{oldValue}} to {{newValue}} </li>{{/interestRate}}
          {{#issuedDate}}<li>Issued Date changed from {{oldValue}} to {{newValue}} </li>{{/issuedDate}}
      {{/../CONVERTIBLE}}

      {{#../WARRANT}}
      {{#amount}}<li> Investment Amount changed from {{oldValue}} to {{newValue}} </li>{{/amount}}
      {{#valuation}}<li> Valuation Amount changed from {{oldValue}} to {{newValue}} </li>{{/valuation}}
      {{#issuedDate}}<li>Issued Date changed from {{oldValue}} to {{newValue}} </li>{{/issuedDate}}
      {{/../WARRANT}}

      {{/fieldsChanged}}
      {{#fieldsAdded}}
        {{#../SHARE}} 
          {{#price}}<li>  Price per Share added: {{.}} </li>{{/price}}
          {{#amount}}<li>  Number of Shares added: {{.}} </li>{{/amount}}
          {{#issuedDate}}<li>Issued Date added: {{.}} </li>{{/issuedDate}}
        {{/../SHARE}}
          {{#../OPTION}}
          {{#price}}<li>  Exercise Price added: {{.}} </li>{{/price}}
          {{#amount}}<li> Number of Share Options added: {{.}} </li>{{/amount}}
          {{#shareIncentivePlan.name}}<li>  Share Incentive Plan: {{.}} </li>{{/shareIncentivePlan.name}}
          {{#issuedDate}}<li>Issued Date added: {{.}} </li>{{/issuedDate}}
        {{/../OPTION}} 
        {{#../CONVERTIBLE}}
          {{#amount}}<li> Investment Amount added: {{.}} </li>{{/amount}}
          {{#discountRate}}<li> Discount added: {{.}} </li>{{/discountRate}}
          {{#valuationCap}}<li> Valuation Amount added: {{.}} </li>{{/valuationCap}}
          {{#interestRate}}<li> Interest Rate added: {{.}} </li>{{/interestRate}}
          {{#issuedDate}}<li>Issued Date added: {{.}} </li>{{/issuedDate}}
        {{/../CONVERTIBLE}}
      {{/fieldsAdded}}
      <br/>
      {{#fieldsRemoved}}
        {{#../SHARE}}
          {{#price}}<li> Price per Share removed: {{.}} </li>{{/price}}
          {{#amount}}<li>  Number of Shares removed: {{.}} </li>{{/amount}}
          {{#issuedDate}}<li>Issued Date removed: {{.}} </li>{{/issuedDate}}
        {{/../SHARE}}
        {{#../OPTION}}
          {{#price}}<li>  Exercise Price removed: {{.}} </li>{{/price}}
          {{#amount}}<li> Number of Share Options removed: {{.}} </li>{{/amount}}
          {{#shareIncentivePlan.name}}<li>  Share Incentive Plan removed: {{.}} </li>{{/shareIncentivePlan.name}}
          {{#issuedDate}}<li>Issued Date removed: {{.}} </li>{{/issuedDate}}
        {{/../OPTION}} 
        {{#../CONVERTIBLE}}
          {{#amount}}<li> Investment Amount removed: {{.}} </li>{{/amount}}
          {{#discountRate}}<li> Discount removed: {{.}} </li>{{/discountRate}}
          {{#valuationCap}}<li> Valuation Amount removed: {{.}} </li>{{/valuationCap}}
          {{#interestRate}}<li> Interest Rate removed: {{.}} </li>{{/interestRate}}
          {{#issuedDate}}<li>Issued Date removed: {{.}} </li>{{/issuedDate}}
        {{/../CONVERTIBLE}}
      {{/fieldsRemoved}}
    </ul>
  `,

    MESSAGE_HEADER_EQUITY_POSITION_DELETED:
        "{{userName}} deleted an Equity Position",
    MESSAGE_SUB_HEADER_EQUITY_POSITION_DELETED:
        "{{equityHolderName}}'s {{equityPositionName}}s in {{equityCompanyName}} was deleted by {{userName}} at {{activityTime}} on {{activityDate}}.",
    MESSAGE_TEMPLATE_EQUITY_POSITION_DELETED: `
  <label>{{userName}} deleted a {{equityPositionName}} equity position in {{equityCompanyName}} for {{equityHolderName}}</label> 
  <ul>
    {{#SHARE}}
      <li>A position of {{shares.numberOfShares}} {{shareClass.name}} shares in {{equityCompanyName}} for {{equityHolderName}} has been deleted.</li>
    {{/SHARE}}
    {{#OPTION}}
      <li>A position of {{options.numberOfOptions}} {{shareClass.name}} options in {{equityCompanyName}} for {{equityHolderName}} has been deleted.</li>
    {{/OPTION}}
    {{#CONVERTIBLE}}
      <li>A convertible in {{equityCompanyName}} for {{equityHolderName}}'s investment of {{convertible.investmentAmount}} has been deleted.</li>
    {{/CONVERTIBLE}}
    {{#WARRANT}}
      <li>A warrant in {{equityCompanyName}} for {{equityHolderName}}'s investment of {{warrants.investmentAmount}} has been deleted.</li>
  {{/WARRANT}}
</ul>
  `,

    MESSAGE_BUTTON_REDIRECT_GO_TO_EQUITY_POSITION: "View Equity Position Details",



    // STARTUP PROFILE MESSAGES (UPDATED)
    MESSAGE_HEADER_PROFILE_CREATED:
        "{{userName}} created a profile for '{{startupName}}'",
    MESSAGE_SUB_HEADER_PROFILE_CREATED:
        "The Clara Profile for {{startupName}} was created by {{userName}} at {{activityTime}} on {{activityDate}}.",
    MESSAGE_TEMPLATE_PROFILE_CREATED: `
  <label>{{userName}} created a profile for '{{startupName}}'</label>
  <ul>
    <li>The Clara Profile for {{startupName}} was created by {{userName}} at {{activityTime}} on {{activityDate}}.</li>
  </ul>
  `,

    MESSAGE_HEADER_PROFILE_EDITED:
        "{{userName}} edited the profile for '{{startupName}}'",
    MESSAGE_SUB_HEADER_PROFILE_EDITED:
        "The details of {{startupName}} were edited by {{userName}} at {{activityTime}} on {{activityDate}}.",
    MESSAGE_TEMPLATE_PROFILE_EDITED: `
  <label>{{userName}} edited the profile for '{{startupName}}'</label>
  <ul>
    {{#fieldsChanged}}
      {{#name}} <li>The Company Name was changed from <strong>'{{oldValue}}'</strong> to <strong>'{{newValue}}'</strong>.</li> {{/name}}
      {{#website}} <li>The Website was changed from <strong>'{{oldValue}}'</strong> to <strong>'{{newValue}}'</strong>.</li> {{/website}}
      {{#description}} <li>The Business Description changed from <strong>'{{oldValue}}'</strong> to <strong>'{{newValue}}'</strong>.</li> {{/description}}
      {{#accelerator}} <li>The Accelerator was changed from <strong>'{{oldValue}}'</strong> to <strong>'{{newValue}}'</strong>.</li> {{/accelerator}}
      {{#avatar.newValue}} <li>The logo was changed.</li> {{/avatar.newValue}}
    {{/fieldsChanged}}
    {{#fieldsAdded}}
      {{#avatar}} <li>A logo was added to the profile.</li> {{/avatar}}
      {{#website}} <li>The Website was set to <strong>'{{.}}'</strong>.</li> {{/website}}
      {{#description}} <li> The Business Description was set to <strong>'{{.}}'</strong>.</li> {{/description}}
      {{#accelerator}} <li>The Accelerator was set to <strong>'{{.}}'</strong>.</li> {{/accelerator}}
    {{/fieldsAdded}}
    {{#fieldsRemoved}}
      {{#avatar}} <li>The logo was removed from the profile.</li> {{/avatar}}
      {{#website}} <li>The Website <strong>'{{.}}'</strong> was removed from the profile.</li> {{/website}}
      {{#description}} <li>The Business Description of <strong>'{{.}}'</strong> was removed from the profile.</li> {{/description}}
      {{#accelerator}} <li>The Accelerator <strong>'{{.}}'</strong> was removed from the profile.</li> {{/accelerator}}
    {{/fieldsRemoved}}
</ul>  
`,

    MESSAGE_BUTTON_REDIRECT_GO_TO_STARTUP_PROFILE: "View Startup Profile",

    //DOCUMENT MESSAGES
    MESSAGE_HEADER_DOCUMENT_CHANGED_NAME:
        '{{userName}} renamed a document',
    MESSAGE_SUB_HEADER_DOCUMENT_CHANGED_NAME:
        "{{userName}} changed the name of ‘{{documentName}}' to ‘{{newDocumentName}}’ at {{time}} on {{date}}.",
    MESSAGE_TEMPLATE_DOCUMENT_CHANGED_NAME:
        `
  <label>{{userName}} renamed a document</label>
  <ul>
<li>{{userName}} changed the name of ‘{{documentName}}' to ‘{{newDocumentName}}’ at {{time}} on {{date}}.</li>

<li>{{documentName}} to {{newDocumentName}}</li>
</ul>
  `,
    MESSAGE_HEADER_DOCUMENT_CREATED:
        "{{userName}} uploaded the document '{{documentName}}'",
    MESSAGE_SUB_HEADER_DOCUMENT_CREATED:
        "The Document {{documentName}} was uploaded by {{userName}} at {{activityTime}} on {{activityDate}}.",
    MESSAGE_TEMPLATE_DOCUMENT_CREATED: `
    <label>{{userName}} uploaded the document '{{documentName}}'</label>
    <ul>
      <li> This document is classified as a: {{documentType}} </li>
      <li> This document is catergorized under: {{category}} - {{subCategory}}</li>
      </br>
      {{#if parties}}
      <li> The parties of this document are:</li>
        {{#parties}} <li> {{.}} </li> {{/parties}}
      {{/if}}
    <ul>
  `,

    MESSAGE_HEADER_UNCATEGORIZED_DOCUMENT_CREATED:
        "{{userName}} uploaded the Document '{{documentName}}'",
    MESSAGE_HEADER_UNCATEGORIZED_DOCUMENT_DELETED:
        "{{userName}} deleted the Document '{{documentName}}'",
    MESSAGE_SUB_HEADER_UNCATEGORIZED_DOCUMENT_CREATED:
        "The Document {{documentName}} was uploaded by {{userName}} at {{activityTime}} on {{activityDate}}.",
    MESSAGE_SUB_HEADER_UNCATEGORIZED_DOCUMENT_DELETED:
        "The Document {{documentName}} was deleted by {{userName}} at {{activityTime}} on {{activityDate}}.",
    MESSAGE_TEMPLATE_UNCATEGORIZED_DOCUMENT_CREATED: `
    <label>{{userName}} uploaded the document '{{documentName}}'</label>
    <ul>
      <li> This document is currently uncategorised.</li>
    <ul>
  `,

    MESSAGE_TEMPLATE_UNCATEGORIZED_DOCUMENT_DELETED: `
    <label>{{userName}} deleted the document '{{documentName}}'</label>
    <ul>
      <li> This document is no longer available.</li>
    <ul>
  `,

    MESSAGE_HEADER_DOCUMENT_CATEGORIZED:
        "{{userName}} categorised the Document '{{documentName}}'",
    MESSAGE_SUB_HEADER_DOCUMENT_CATEGORIZED:
        "The Document {{documentName}} has been categorised by {{userName}} at {{activityTime}} on {{activityDate}}.",
    MESSAGE_TEMPLATE_DOCUMENT_CATEGORIZED: `
    <label>{{userName}} categorised the document '{{documentName}}'</label>
    <ul>
      <li> This document is classified as a: {{documentType}} </li>
      <li> This document is categorised under: {{category}} - {{subCategory}}</li>
      </br>
      {{#if parties}}
      <li> The parties of this document are:</li>
        {{#parties}} <li> {{.}} </li> {{/parties}}
      {{/if}}
    <ul>
  `,

    MESSAGE_HEADER_DOCUMENT_EDITED:
        "{{userName}} edited the details of the Document '{{documentName}}'",
    MESSAGE_SUB_HEADER_DOCUMENT_EDITED:
        "The details of the Document '{{documentName}}' were edited by {{userName}} at {{activityTime}} on {{activityDate}}.",
    MESSAGE_TEMPLATE_DOCUMENT_EDITED: `
    <label>{{userName}} edited the details of the document '{{documentName}}'</label>
    <ul>
      {{#fieldsChanged}}
        {{#title}} <li>Document Name changed from {{oldValue}} to {{newValue}} </li> {{/title}}
        {{#fileName}} <li>Document Name changed from {{oldValue}} to {{newValue}} </li> {{/fileName}}
        {{#file}} <li>The document was replaced </li> {{/file}}
        {{#isEqual category.oldCategory  category.category }}
          {{else}} <li> Category changed from  {{ category.oldCategory}} to {{ category.category}}    </li>
        {{/isEqual}}
        {{#isEqual subCategories.oldSubCategory  subCategories.subCategory }}
          {{else}} <li> Subcategory changed from  {{ subCategories.oldSubCategory  }} to {{ subCategories.subCategory}}    </li>
        {{/isEqual}}
        {{#documentType}} <li>Document type from {{oldValue}} to {{newValue}} </li> {{/documentType}}
        {{#if parties}}
          {{#if parties.oldValue}}
            <li>Parties Removed:</li>
          {{/if}}
          {{#parties.oldValue}}
            <li>{{name}}</li>  
          {{/parties.oldValue}}
          <br/>
          {{#if parties.newValue}}
            <li>Parties added:</li>
          {{/if}}
          {{#parties.newValue}}
            <li>{{name}}</li>  
          {{/parties.newValue}}
        {{/if}}
      {{/fieldsChanged}}
      <br/>
      {{#fieldsAdded}}
        {{#executedOnDate}} <li> Execution date added {{getDate .}} </li> {{/executedOnDate}}
        {{#expirationDate}} <li>Expiration date added {{getDate .}}  </li> {{/expirationDate}}
      {{/fieldsAdded}}
      <br/>
      {{#fieldsRemoved}}
        {{#if parties}}
          <li>Parties removed:</li>
        {{/if}}
        {{#parties}}
          <li>{{.}}</li>
        {{/parties}}
        {{#executedOnDate}} <li> Execution date Removed {{getDate .}} </li> {{/executedOnDate}}
        {{#expirationDate}} <li>Expiration date Removed {{getDate  .}}  </li> {{/expirationDate}}
      {{/fieldsRemoved}}
    </ul>  
  `,

    MESSAGE_HEADER_DOCUMENT_DELETED:
        "{{userName}} deleted the Document '{{documentName}}'",
    MESSAGE_SUB_HEADER_DOCUMENT_DELETED:
        "The Document {{documentName}} was deleted by {{userName}} at {{activityTime}} on {{activityDate}}.",
    MESSAGE_TEMPLATE_DOCUMENT_DELETED: `
  <label>{{userName}} deleted the document '{{documentName}}'</label>
  <ul>
    <li> This document was classified as a: {{documentType}} </li>
    <li> This document was catergorized under: {{category}} - {{subCategory}}</li>
    </br>
    {{#if parties}}
    <li> The parties of this document were:</li>
      {{#parties}} <li> {{.}} </li> {{/parties}}
    {{/if}}
  <ul>
  `,

    MESSAGE_BUTTON_REDIRECT_GO_TO_DOCUMENT: "View Document Details",
    MESSAGE_BUTTON_REDIRECT_GO_TO_UNCATEGORIZED_DOCUMENT: "Categorise",

    //GENERATED DOCUMENT MESSAGES
    MESSAGE_HEADER_GENERATED_DOCUMENT_CREATED:
        "{{userName}} created a draft of an Agreement",
    MESSAGE_SUB_HEADER_GENERATED_DOCUMENT_CREATED:
        "A draft of a new agreement was created by {{userName}} at {{activityTime}} on {{activityDate}}.",
    MESSAGE_HEADER_GENERATED_DOCUMENT_TERMINATED:
        "{{userName}} Terminated {{grantName}}",
    MESSAGE_SUB_HEADER_GENERATED_DOCUMENT_TERMINATED:
        "The {{grantName}} was Terminated by {{userName}} at {{time}} on {{date}}",
    MESSAGE_TEMPLATE_GENERATED_DOCUMENT_CREATED: `
    <label>{{userName}} created a draft of the Agreement '{{documentName}}'</label>
    <ul>
      <li>Document Type: {{documentType}} </li>
      <li>Parties:</li>
      {{#parties}}
        <li>{{.}}</li>
      {{/parties}}
    </ul>
  `,
    MESSAGE_TEMPLATE_GENERATED_DOCUMENT_TERMINATED: `
<label>{{userName}} Terminated {{grantName}}</label>
<ul>
<li>This was Terminated because - {{reason}}</li>
</ul>
`,

    MESSAGE_HEADER_GENERATED_DOCUMENT_SENT:
        "{{userName}} has sent an Agreement out for signature",
    MESSAGE_SUB_HEADER_GENERATED_DOCUMENT_SENT:
        "An Agreement was sent out for signature by {{userName}} at {{activityTime}} on {{activityDate}}.",
    MESSAGE_TEMPLATE_GENERATED_DOCUMENT_SENT: `
    <label>{{userName}} has sent the agreement '{{documentName}}' out for signature</label>
    <ul>
      <li>Document Type: {{documentType}} </li>
      <li>Parties:</li>
      {{#parties}}
        <li>{{.}}</li>
      {{/parties}}
    </ul>
  `,

    MESSAGE_HEADER_GENERATED_DOCUMENT_VOID:
        "{{userName}} Voided '{{documentName}}'",
    MESSAGE_SUB_HEADER_GENERATED_DOCUMENT_VOID:
        "The Document {{documentName}} was Voided by {{userName}} at {{activityTime}} on {{activityDate}}.",
    MESSAGE_TEMPLATE_GENERATED_DOCUMENT_VOID: `
    <label>{{userName}} Voided '{{documentName}}'</label>
    <ul>
      <li>This was voided because - {{voidedReason}}.</li>
    </ul>
  `,

    MESSAGE_HEADER_GENERATED_DOCUMENT_RESEND:
        "{{userName}} has resend an Agreement out for signature",
    MESSAGE_SUB_HEADER_GENERATED_DOCUMENT_RESEND:
        "An Agreement was resend out for signature by {{userName}} at {{activityTime}} on {{activityDate}}.",
    MESSAGE_TEMPLATE_GENERATED_DOCUMENT_RESEND: `
    <label>{{userName}} has resend the agreement '{{documentName}}' out for signature</label>
    <ul>
      <li>Document Type: {{documentType}} </li>
      <li>Parties:</li>
      {{#parties}}
        <li>{{.}}</li>
      {{/parties}}
    </ul>
  `,

    MESSAGE_HEADER_GENERATED_DOCUMENT_SIGNED: "An Agreement has been signed",
    MESSAGE_SUB_HEADER_GENERATED_DOCUMENT_SIGNED:
        "An agreement was signed and executed at {{activityTime}} on {{activityDate}}.",
    MESSAGE_TEMPLATE_GENERATED_DOCUMENT_SIGNED: `
    <label>The Agreement '{{documentName}}' has been signed and executed</label>
    <ul>
      <li>Document Type: {{documentType}} </li>
      <li>Parties:</li>
      {{#parties}}
        <li>{{.}}</li>
      {{/parties}}
      {{#isThereCustomMessage}}
      <li>{{customMessage}}</li>
      {{/isThereCustomMessage}}
    </ul>
  `,

    //SHARING MESSAGES
    MESSAGE_HEADER_PROFILE_GRANTED:
        "{{userName}} has given {{sharedUserName}} access to {{startupName}}'s Profile",
    MESSAGE_SUB_HEADER_PROFILE_GRANTED:
        "{{userName}} has given {{roleName}} access to {{sharedUserName}} at {{activityTime}} on {{activityDate}}.",
    MESSAGE_TEMPLATE_PROFILE_GRANTED: `
    <label>{{userName}} has given {{sharedUserName}} access to {{startupName}}'s Profile</label>
    <ul>
      <li>{{userName}} has given {{roleName}} access to {{sharedUserName}} at {{activityTime}} on {{activityDate}}.</li>
    </ul>
  `,

    MESSAGE_HEADER_PROFILE_GRANT_CHANGED:
        "{{userName}} has changed the {{sharedUserName}}'s access level to {{startupName}}'s Profile",
    MESSAGE_SUB_HEADER_PROFILE_GRANT_CHANGED:
        "{{userName}} has changed  {{sharedUserName}}'s access from {{oldRoleName}} to {{newRoleName}} at {{activityTime}} on {{activityDate}}.",
    MESSAGE_TEMPLATE_PROFILE_GRANT_CHANGED: `
    <label>{{userName}} has changed the {{sharedUserName}}'s access level to {{startupName}}'s Profile</label>
    <ul>
      <li>{{userName}} has changed  {{sharedUserName}}'s access from {{oldRoleName}} to {{newRoleName}} at {{activityTime}} on {{activityDate}}.</li>
    </ul>
  `,

    MESSAGE_HEADER_PROFILE_GRANT_REMOVED:
        "{{userName}} has revoked {{sharedUserName}}'s access to {{startupName}}'s Profile",
    MESSAGE_SUB_HEADER_PROFILE_GRANT_REMOVED:
        "{{userName}} has revoked {{sharedUserName}}'s {{roleName}} access at {{activityTime}} on {{activityDate}}.",
    MESSAGE_TEMPLATE_PROFILE_GRANT_REMOVED: `
    <label>{{userName}} has revoked {{sharedUserName}}'s access to {{startupName}}'s Profile</label>
    <ul>
      <li>{{userName}} has revoked {{sharedUserName}}'s {{roleName}} access at {{activityTime}} on {{activityDate}}.</li>
    </ul>
  `,

    MESSAGE_BUTTON_REDIRECT_GO_TO_USER_PROFILE: "View Sharing Management",

    // CLARA REPORT MESSAGES
    MESSAGE_HEADER_REPORT_DOWNLOADED:
        "{{userName}} has downloaded the Clara Report",
    MESSAGE_SUB_HEADER_REPORT_DOWNLOADED:
        "{{userName}} has downloaded the Clara Report at {{activityTime}} on {{activityDate}}.",
    MESSAGE_TEMPLATE_REPORT_DOWNLOADED: `
    <label>{{userName}} has downloaded the Clara Report</label>
    <ul>
      <li>{{userName}} has downloaded the Clara Report at {{activityTime}} on {{activityDate}}.</li>
    </ul>
  `,

    // CLARA REPORT MESSAGES
    MESSAGE_HEADER_DOCUMENT_VESTING_DATE_UPCOMING:
        "Some of {{stakeholderName}}'s options are set to vest in 5 days.",
    MESSAGE_SUB_HEADER_DOCUMENT_VESTING_DATE_UPCOMING:
        "{{numberOfOptionsInVestingPeriod}} options are vesting as part of {{stakeholderName}}'s grant:<br> {{grantName}}",
    MESSAGE_TEMPLATE_DOCUMENT_VESTING_DATE_UPCOMING: `
  <label>{{numberOfOptionsInVestingPeriod}} options will vest in 5 days.</label>
  <ul>
    <li>You can view more information on their share options page.</li>
  </ul>
`,

    MESSAGE_HEADER_DOCUMENT_VESTING_DATE_TODAY:
        "Some of {{stakeholderName}}'s options vested today.",
    MESSAGE_SUB_HEADER_DOCUMENT_VESTING_DATE_TODAY:
        "{{numberOfOptionsInVestingPeriod}} options vested today as part of {{stakeholderName}}'s grant:<br> {{grantName}}",
    MESSAGE_TEMPLATE_DOCUMENT_VESTING_DATE_TODAY: `
  <label>{{numberOfOptionsInVestingPeriod}} options vested today.</label>
  <ul>
  <li>You can generate an exercise notice for these options on their share options page.</li>
  </ul>
  `,

}

export default templates

import React, { FC, useEffect, useState } from 'react';
import Wizard from '../../../generic/components/Wizard';
import ModalAssistant, { ModalAssistantProps } from '../../../generic/components/Modal/ModalAssistant';
import MachineIpAndGenerate from './machine';
import useModal from "../../../generic/hooks/useModal";
import { useDispatch } from 'react-redux';
import { clearAssistatText } from '../../../generic/store/action';

interface ModalAddressAndCurrencyProps extends ModalAssistantProps {
  onClose(): void,
  paramsMutation: object
}

const ModalIPAndGenerate: FC<ModalAddressAndCurrencyProps> = ({ children, open: openParam = false, paramsMutation = {}, onClose, ...props }: ModalAddressAndCurrencyProps) => {

  const [open, setOpen] = useState(openParam);
  const { closeModal } = useModal();

  useEffect(() => {
    setOpen(openParam);
  }, [openParam]);

  const handleClose = (values) => {
    closeModal();
    setOpen(false);
  }

  const dispatch = useDispatch()

  useEffect(() => {
    return () => { dispatch(clearAssistatText()); }
  }, []);

  return (
    <ModalAssistant open={open} onClose={handleClose}>
      <Wizard machine={MachineIpAndGenerate} initialValues={{ paramsMutation }} onComplete={handleClose} />
    </ModalAssistant>
  )
}

export default ModalIPAndGenerate;





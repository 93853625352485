import React, { FC } from 'react';
import * as Yup from 'yup';
import Form from "../../../../components/forms/Form/FormL";
import { DeleteButtonForm } from "../../../../components/generic/Buttons/DeleteButton";
import CheckboxInput, { CheckboxProps } from "../../components/Inputs/Checkbox";
import ControllerInput from "../../components/Inputs/ReactHookForm/ControllerInput";
import TemplateFormDefault from "../../templates/Modal";
import FormProps from "../interfaces/FormProps";
interface DeleteFormProps extends FormProps {
    checkBoxProps: CheckboxProps
    isThereConnections: boolean
}
const DeleteForm: FC<DeleteFormProps> = ({ isThereConnections = false, onCompleteSubmit, Template = TemplateFormDefault, buttons = {}, buttonSubmitProps, propsTemplate, checkBoxProps }) => {
    return (
        <Form schema={Yup.object().shape({
            delete: Yup.array().required('Required')
        })}
            onSubmit={onCompleteSubmit}
            optionsForm={{ mode: "onChange" }}>
            <Template buttons={{ ...buttons, submit: <DeleteButtonForm labelLoading={'Removing...'}  {...buttonSubmitProps}>{isThereConnections ? 'Okay' : 'Delete'}</DeleteButtonForm> }} props={propsTemplate}>
                <ControllerInput
                    name={'delete'}
                    render={CheckboxInput}
                    isMultiple={false}
                    buttonType={'Delete'}
                    {...checkBoxProps}
                /></Template>
        </Form>
    )
}
export default DeleteForm

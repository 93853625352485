import {Field} from "formik";
import Dropzone from "react-dropzone";
import React from "react";
import {Modal, Upload} from "../../../../v1/components";
import classes from "../../../../v1/components/Chat/Chat.module.scss";
import classesNullResponse from "../../../../v1/components/Form/ClaraUpload/ClaraUpload.module.scss";
import {useSessionStorage} from 'beautiful-react-hooks';
import _ from "lodash";
import { Constants } from "src/v1/utils/constants.js";

const FileUploadChatVersionable = ({params, functions, bot_message, dropzoneRef, newKey}) => {

    const {openModalUpload, acceptedFiles, cantFiles, uploadedFiles} = params
    const [versions] = useSessionStorage('versions');
    const {onboarding} = versions
    const keepUploadedFilesInState = onboarding !== 'v2';

    return (
        <Field name={newKey + bot_message.responseKeyName}>
            {({field, form}) => (
                <Dropzone onDrop={acceptedFiles => {
                    const filterFiles = _.filter(acceptedFiles, (file) => {
                        const extension = file.name.split(".").pop();
                        return Constants.BLOCK_EXTENSIONS.indexOf(extension);
                    });
                    if (filterFiles.length > 0) {
                        functions.handleDropFiles(filterFiles);
                    }                    
                }}>
                    {({getRootProps, getInputProps, isDragActive}) => {
                        return (
                            <React.Fragment>
                                <Modal
                                    extraStyle={{
                                        padding: '10px',
                                        minWidth: '300px'
                                    }}
                                    open={openModalUpload}
                                    handleClose={functions.handleClose}
                                    title={functions.getTitleUploadModal(cantFiles)}
                                >
                                    <Field name={newKey + bot_message.responseKeyName}>
                                        {({field, form}) => (
                                            <Upload files={acceptedFiles} form={form} field={field}
                                                    keepUploadedFilesInState={keepUploadedFilesInState}
                                                    handleClose={functions.handleClose}
                                                    handleDone={functions.handleDone}
                                                    automaticDone={false}/>
                                        )}
                                    </Field>
                                </Modal>

                                {functions.renderAcceptedFiles(uploadedFiles, bot_message)}

                                <div className={classes.DropZoneButton}>

                                    <div {...getRootProps({})}>

                                        {isDragActive ? (
                                            <div className={classes.Dropzone}>
                                            </div>
                                        ) : null}

                                        <input ref={dropzoneRef}  {...getInputProps()} />

                                        <p>
                                            Drag and drop file here to upload, or <span>Browse</span>
                                        </p>
                                    </div>
                                </div>
                                {
                                    bot_message.inputConfig.allowNullResponse
                                    && <div className={classesNullResponse.NullResponse}>
                                        <i className={classesNullResponse.NullReponseLink}
                                           onClick={() => functions.submitQuestion(form.values, form, null, keepUploadedFilesInState)}>{params.activeQuestion.bot_message.iDontKnowText || "I don't know"}</i>
                                    </div>
                                }
                            </React.Fragment>
                        )
                    }}
                </Dropzone>)
            }
        </Field>
    )
}

export default FileUploadChatVersionable
import React, { FC, useRef } from 'react';
import * as Yup from 'yup';
import Form from "../../../../../components/forms/Form/FormL";
import FileUploaderInput from "../../../../../components/inputs/FileUploader/FileUploaderInput";
import TextInput from "../../../../../components/inputs/Text/TextInput";
import SubmitButton from "../../../../generic/components/Buttons/SubmitButton";
import TemplateLabel from "../../../../generic/components/Inputs/LabelInput";
import ControllerInput from "../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import FormProps from "../../../../generic/forms/interfaces/FormProps";
import useTranslate from "../../../../generic/hooks/useTranslate";
import TemplateFormDefault from "../../../../generic/templates/Modal";
import SelectInput from 'src/components/inputs/Select/SelectInput/SelectInput';
const schema = Yup.object().shape({
    name: Yup.string().nullable().required('Required'),
    file: Yup.object().nullable().required('Required'),
    documentType: Yup.string().nullable().required('Required'),
})
const UploadOptionSipForm: FC<FormProps> = ({ initialValues, tooltips, onCompleteSubmit, Template = TemplateFormDefault, buttons = {}, buttonSubmitProps, propsTemplate }: FormProps) => {
    const { translate } = useTranslate();
    const refForm = useRef()
    const boardTypeOptions = [
        { label: 'Convertible Loan Note', id: "BOARD_RESOLUTION_CONVERTIBLE_LOAN_NOTE" },
        { label: 'SAFE', id: "BOARD_RESOLUTION_CONVERTIBLE_SAFE" },
        { label: 'KISS', id: "BOARD_RESOLUTION_CONVERTIBLE_KISS" },
        { label: 'Fixed Equity', id: "BOARD_RESOLUTION_CONVERTIBLE_FIXED_EQUITY" },
        { label: 'Other', id: "BOARD_RESOLUTION_CONVERTIBLE_OTHER" },
      ];
    return (
        <React.Fragment>
            <Form
                initialValues={initialValues || {}}
                schema={schema}
                onlySendDirty={false}
                onSubmit={onCompleteSubmit}
                ref={refForm} optionsForm={{ mode: "onChange" }}
            >
                <Template props={propsTemplate} buttons={{ submit: <SubmitButton{...buttonSubmitProps}>Add</SubmitButton>, ...buttons }}>
                    <TemplateLabel label={translate('MODULES.EQUITY.FORMS.UPLOAD_DOCUMENT.NAME')} isRequired={true}>
                        <ControllerInput
                            as={TextInput}
                            defaultValue=""
                            name={"name"}
                            placeholder={translate('MODULES.EQUITY.FORMS.UPLOAD_DOCUMENT.PLACEHOLDER_NAME')}
                            onChange={(e) => {

                            }}
                        />
                    </TemplateLabel>
                    <TemplateLabel
                        label={'Document type'}
                        isRequired={true}
                    >
                        <ControllerInput
                            as={SelectInput}
                            placeholder={"SELECT_INPUT_PLACEHOLDER"}
                            list={boardTypeOptions}
                            name={"documentType"}
                            clear={true}
                            onChange={(value) => {
                            }}
                        />
                    </TemplateLabel>
                    <TemplateLabel label={translate('MODULES.EQUITY.FORMS.UPLOAD_DOCUMENT.FILE')} isRequired={true}>
                        <ControllerInput
                            as={FileUploaderInput}
                            defaultValue=""
                            name={"file"}
                            onChange={(e) => {

                            }}
                        />
                    </TemplateLabel>
                </Template>
            </Form>
        </React.Fragment>
    )
}

export default UploadOptionSipForm

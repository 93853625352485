import React, { FC, useEffect, useRef } from "react";
import ControllerInput from "../Inputs/ReactHookForm/ControllerInput";
import CountrySelector from "../../../../components/inputs/Select/CountrySelector/CountrySelector";
import { CountryType } from "@interfaces/country";
import { HiddenFieldForm } from "../../../../components/forms/contents";
import { InputLabelWithBanner } from "src/components/inputs/InputWithBanner";
import JuridictionSelector from "../../../../components/inputs/Select/JuridictionSelector/JuridictionSelector";
import TemplateLabel from "../../../../modules/generic/components/Inputs/LabelInput";
import TextInput from "../../../../components/inputs/Text/TextInput";
import _ from "lodash";
import { useFormContext } from "react-hook-form";
import useGetCountryAndJurisdiction from "./useGetCountryAndJurisdiction";
import useTranslate from "../../hooks/useTranslate";

interface CountryJurisdictionProps {
  name?: string;
  disabled?: boolean;
  countryLabel?: string;
  jurisdictionLabel?: string;
  showJurisdiction?: boolean;
  unmounClearJurisdiction?: boolean;
  onChange?: (value: any) => void;
  hideLoading?: boolean;
}

const CountryJurisdiction: FC<CountryJurisdictionProps> = ({
  name = "country",
  disabled = false,
  countryLabel,
  jurisdictionLabel,
  showJurisdiction = true,
  unmounClearJurisdiction,
  onChange,
  hideLoading = false,
  ...props
}) => {

  const { translate } = useTranslate();
  const { watch, setValue } = useFormContext();
  const refJurisdiction = useRef(null);

  useEffect(() => {
    const country: CountryType = watch(name);
    if (_.has(country, "code")) {
      setValue(name, country.code);
    }
  }, []);

  const {
    countriesList,
    jurisdictionsList,
    loading,
  } = useGetCountryAndJurisdiction();

  const setOtherJurisdiction = (value) => {
    setTimeout(() => {
      setValue("otherJurisdiction", value);
    }, 100);
  };

  const handleOnchange = (value) => {
    if (onChange) {
      onChange(value);
    }
  }

  return (
    <>
      {" "}
      {loading ? (
        <>
          {!hideLoading && (
            <label>loading</label>
          )}
        </>
      ) : (
        <>
          <InputLabelWithBanner
            bannerLogicType='touched'
            component={CountrySelector}
            controllerInputProps={{
              name,
              placeholder: "Select country",
              clear: true,
              showStrong: true,
              defaultValue: "",
              defaultList: countriesList,
              disabled,
              onChange: (value) => {
                if (_.isNull(value)) {
                  setValue("jurisdiction", null);
                }
              }
            }}
            templateLabelProps={{
              bannerTitle: "Select \"Save changes\" to add this change request to your cart. Then go to the Dashboard and click \"View cart\" to pay and submit.",
              label: countryLabel ? countryLabel : translate("FORM_COMAPANY_LABEL_COUNTRY"),
              isRequired: true,
            }}
          />
          <HiddenFieldForm
            fieldsWatch={name}
            conditionHideCallback={(value) => {
              return value && value !== ""  && showJurisdiction;
            }}
          >
            {watch(name) && (
              <>
                <InputLabelWithBanner
                  bannerLogicType='touched'
                  component={JuridictionSelector}
                  controllerInputProps={{
                    name: "jurisdiction",
                    placeholder: "Select jurisdiction",
                    defaultList: jurisdictionsList,
                    disabled,
                    clear: true,
                    handleOtherJurisdiction: setOtherJurisdiction,
                    innerRef: refJurisdiction,
                    onChange: handleOnchange,
                    unmoutClean: unmounClearJurisdiction,
                    countrySelected: watch(name),
                    defaultValue: "",
                  }}
                  templateLabelProps={{
                    bannerTitle: "Select \"Save changes\" to add this change request to your cart. Then go to the Dashboard and click \"View cart\" to pay and submit.",
                    label: jurisdictionLabel ? jurisdictionLabel : translate("FORM_COMAPANY_LABEL_JURISDICTION"),
                    isRequired: true
                  }}
                  fieldsToWatch={[name]}
                />
              </>
            )}
          </HiddenFieldForm>
          <HiddenFieldForm
            fieldsWatch={"jurisdiction"}
            conditionHideCallback={(value) => {
              return value === "OTHER";
            }}
          >
            <TemplateLabel
              label={translate("FORM_COMAPANY_LABEL_JURISDICTION_OTHER")}
              isRequired={true}
            >
              <ControllerInput
                render={TextInput}
                name="otherJurisdiction"
                placeholder={"Type here"}
              />
            </TemplateLabel>
          </HiddenFieldForm>
        </>
      )}
    </>
  );
};

export default CountryJurisdiction;

import React from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";

const AddCancelWarrant = ({go,...props}) => {
  const params = useParams();

  return (
    <MicroFrontend id="MF_Warrants_Cancel_Add" component={"./AddCancelWarrant"} mf="capTable" params={{ go, ...props,...params }} />
  );
};

export default AddCancelWarrant;
import _ from "lodash";
import React, { useMemo, useState } from 'react';
import RowAdd from '../../../forms/contents/RowAdd/RowAdd';
import SelectAddInput from "../../Select/SelectAddInput/SelectAddInput";
import Classes from './styles.module.scss';
import useGetVestingTerms from './graphql/useGetVestingTerms'

const VestingTermsSelect = (
	{
		onBlur,
		modal = false ,
		selectInputLabel = 'Vesting terms',
        onChange,
        value,
        groupCompanyId,
		errorMessage,
        list = []
	}) => {
    const {data,loading} = useGetVestingTerms(groupCompanyId,{skip:!groupCompanyId})
    const [showVestingTerms, setVestingTerms] = useState(true);
    const listVestingTerms = useMemo(() => _.map((data || []).concat(list), as => ({ value: as, label: `${as.name}` ,type:'vestingTerms'})), [JSON.stringify(data)])
    const addList = [
        {
            label: "Add new vesting terms",
            onClick: (value) => {
                setVestingTerms(false)
                onChange && onChange('ADD') 
            },
        },
    ];
    if(loading){
        return null
    }

                return (<>

                        {showVestingTerms &&
                            (<div className={Classes.containerForm}>
                                <RowAdd
                                    label={`${selectInputLabel} `}
                                    asterisk={true}
                                    classNameInput={modal ? Classes.inputModal : Classes.input}
                                    input={
                                        <SelectAddInput
                                            list={listVestingTerms}
                                            mode={'add'}
                                            onBlur = {onBlur}
                                            value={{id:value}}
                                            compare={'id'}
                                            handleFilterList={(value) => {return value}}
                                            placeholder={"Select from the list"}
                                            onChange={(value) => {
                                                onChange && onChange(value) 
                                            }}
                                            menuProps={{
                                                addList: addList,
                                                propsList: [{ type: 'vestingTerms' }]
                                            }}
                                            errorMessage={typeof errorMessage === 'string' ?errorMessage:''}
                                        />
                                    }
                                    />
                            </div>)}
                        
                </>)

};
export default VestingTermsSelect

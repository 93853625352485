import useLazyQuery from "src/hooks/custom/useLazyQuery";

export const GET_GROUP_COMPANY = `
query getGroupCompany($startupId: ID!, $groupCompanyId: ID!) {
  getGroupCompany(startupId: $startupId, groupCompanyId: $groupCompanyId) {
    id
    jurisdictionType
    managedByClara
    companyType
    companyGoal
    formationData {
      isPaid
      isAwaitingFunds
      isSubmitted
      sharesData {
        id
        shareType
        shareNominalPrice
        sharesAuthorised
        shareholders {
          id
          shareholder {
            id
            fullName
            isAnEntity
          }
          shareIssuances {
            sharePrice {
              amount
              currency
            }
            quantity
            percentage
            isPaid
            shareClass
          }
        }
      }
      directorsAndShareholdersCompleted
      specialMeaning
      tslType
      isUsingNominee
      arabicTranslation
      affiliated
      businessActivity {
        activity
        operationCompany
      }
      commercialLicense {
        id
        name
        type {
          code
        }
        file {
          name
          id
        }
      }
      hasAssetsAndRevenue
      notYetFormedCompanyName
      hasShareInIncorporated
      hasShareInCompanyFormed
      sharesInIncorporatedCompanies {
        id
        name
        avatar
      }
      gccNationalOrUaeResident
      authorisedSignatories {
        id
        fullName
      }
      address {
        street
        zipCode
        country {
          code
        }
      }
      bankInformation {
        id
        authorisedSignatories {
          id
          fullName
        }
        address {
          street
          zipCode
          city
          state
          country {
            code
            name
          }
        }
        requireToSignJointly
      }
      stateRegion
      dataProtectionContact {
        id
        fullName
      }
      placeOfBusiness {
        state
        city
      }
      corporatePositions {
        id
        fullName
        avatar
      }
    }
  }
}`;

const useGroupCompanyData = (variables) => {
  const { manualQuery, data, ...rest } = useLazyQuery(
    GET_GROUP_COMPANY,
    variables
  );
  return { manualQuery, rest };
};

export default useGroupCompanyData;

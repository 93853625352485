import React from "react";
import { Checkbox, Box, FormControl, FormControlLabel } from "@mui/material";
import FormHelperError from "src/components/inputs/FormHelperError";

const CheckboxBoolean = (props) => {
  const {
    errorMessage,
    size,
    label,
    helperText = null,
    value = false,
    sx,
    ...restProps
  } = props;

  return (
    <FormControl sx={{ width: "100%", ...sx }} error={!!errorMessage}>
      <FormControlLabel
        value="true"
        control={<Checkbox size={size} checked={value} {...restProps} />}
        label={label}
        labelPlacement="end"
        style={{ columnGap: "10px" }}
      />
      <Box sx={{ display: "flex", justifyContent: "start" }}>
        <FormHelperError errorMessage={errorMessage} helperText={helperText} />
      </Box>
    </FormControl>
  );
};

export default CheckboxBoolean;

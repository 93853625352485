import React, { FC, useState, useEffect } from 'react'
import Wizard from "../../../generic/components/Wizard";
import ModalAssistant, { ModalAssistantProps } from '../../../generic/components/Modal/ModalAssistant'
import { useDispatch } from 'react-redux'
import useTranslate from '../../../generic/hooks/useTranslate'
import useModal from "../../../generic/hooks/useModal";
import { addAssistantText, clearAssistatText } from "../../../generic/store/action";
import MachinePep from "./machine";

interface ModalNoticesProps extends ModalAssistantProps {
  paramsMutation: any,
  onClose(): void
}

const ModalNotices: FC<ModalNoticesProps> = ({
  children,
  open: openParam = false,
  paramsMutation,
  ...props
}: ModalNoticesProps) => {

  const [open, setOpen] = useState<boolean>(openParam);
  const { closeModal: onClose } = useModal();
  const dispatch = useDispatch();
  const { translate } = useTranslate();

  useEffect(() => {
    setOpen(openParam);
  }, [openParam]);

  const handleInitModal = () => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText(translate('MODULES.STAKEHOLDER.PEP.TOOLTIP')))
  }

  useEffect(() => {
    handleInitModal();
    return () => {
      dispatch(clearAssistatText());
    }
  }, []);

  return (
    <ModalAssistant open={open}>
      <Wizard machine={MachinePep} onComplete={onClose} initialValues={paramsMutation}/>
    </ModalAssistant>
  )
}

export default ModalNotices;
import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateLabel from 'src/modules/generic/components/Inputs/LabelInput';
import * as Yup from 'yup';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import classes from './classes.module.scss';
import CalendarInputDate from "src/components/inputs/Calendars/CalendarInputDate";
import FileUploaderInput from 'src/components/inputs/FileUploader/FileUploaderInput';

function RegisterDirectorsAndMembers() {

  const { next, context, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.RegisterDirectorsAndMembers ?? {}
  }, [context]);

  const handleCancel = (values) => {
    cancel();
  }

  const handleSubmit = async (values) => {
    next(values)
  }

  const handlePrev = (values) => {
    prev();
  }

  useEffect(() => {
    dispatch(addAssistantText(translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.REGISTERS_OR_CERTIFICATE_OF_INCUMBENCY.REGISTER_DIRECTORS_AND_MEMBERS.TOOLTIP")))
    return () => {
      dispatch(clearAssistatText())
    }
  }, [dispatch])

  const schema = Yup.object().shape({
    documentDirectors: Yup.object().nullable().required(translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.REGISTERS_OR_CERTIFICATE_OF_INCUMBENCY.REGISTER_DIRECTORS_AND_MEMBERS.REQUIRED")),
    documentMembers: Yup.object().nullable().required(translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.REGISTERS_OR_CERTIFICATE_OF_INCUMBENCY.REGISTER_DIRECTORS_AND_MEMBERS.REQUIRED")),
    dateDirectors: Yup.date().nullable().typeError(translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.REGISTERS_OR_CERTIFICATE_OF_INCUMBENCY.REGISTER_DIRECTORS_AND_MEMBERS.REQUIRED_DATE")).required(),
    dateMembers: Yup.date().nullable().typeError(translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.REGISTERS_OR_CERTIFICATE_OF_INCUMBENCY.REGISTER_DIRECTORS_AND_MEMBERS.REQUIRED_DATE")).required()
  })

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.REGISTERS_OR_CERTIFICATE_OF_INCUMBENCY.REGISTER_DIRECTORS_AND_MEMBERS.TITLE"),
          skeleton: null,
          subTitle: translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.REGISTERS_OR_CERTIFICATE_OF_INCUMBENCY.SUBTITLE_2"),
          variant: 'simple'
        }}
        buttons={{
          previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('GENERIC_BUTTON_ADD_GROUP')}</SubmitButton>,
        }}
      >
        <>
          <TemplateLabel
            label={translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.REGISTERS_OR_CERTIFICATE_OF_INCUMBENCY.REGISTER_DIRECTORS_AND_MEMBERS.FIELD_DIRECTORS")}
            isRequired
            variant={"top"}
          >
            <ControllerInput
              render={FileUploaderInput}
              name='documentDirectors'
              defaultlabel={''}
              className={classes.fileLoad}
            />
          </TemplateLabel>
          <TemplateLabel
            label={translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.REGISTERS_OR_CERTIFICATE_OF_INCUMBENCY.REGISTER_DIRECTORS_AND_MEMBERS.FIELD_DATE")}
            isRequired
          >
            <ControllerInput
              render={CalendarInputDate}
              placeholder={"dd/mm/yyyy"}
              name="dateDirectors"
              onChange={(e) => { }}
            />
          </TemplateLabel>
          <TemplateLabel
            label={translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.REGISTERS_OR_CERTIFICATE_OF_INCUMBENCY.REGISTER_DIRECTORS_AND_MEMBERS.FIELD_MEMBERS")}
            isRequired
            variant={"top"}
          >
            <ControllerInput
              render={FileUploaderInput}
              name='documentMembers'
              defaultlabel={''}
              className={classes.fileLoad}
            />
          </TemplateLabel>
          <TemplateLabel
            label={translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.REGISTERS_OR_CERTIFICATE_OF_INCUMBENCY.REGISTER_DIRECTORS_AND_MEMBERS.FIELD_DATE")}
            isRequired
          >
            <ControllerInput
              render={CalendarInputDate}
              placeholder={"dd/mm/yyyy"}
              name="dateMembers"
              onChange={(e) => { }}
            />
          </TemplateLabel>
        </>
      </TemplateWizard>
    </Form>
  );
}

export default RegisterDirectorsAndMembers;


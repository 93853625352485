import useLazyQuery from "src/hooks/custom/useLazyQuery";
import useSession from "src/modules/session/hooks/useSession";
import {get} from "lodash";

const COMPANY_DATA =`
  query getCompanyData($startupId: ID!) {
 		getGroupCompanies(startupId: $startupId) {
    		id
        name
        avatar
				documents{
          id
          name
        }
      }
  		stakeholderList(
        startupId: $startupId
      ) {
    		id
        avatar
        fullName
    		isFounder
      }
     
    }
`;


const useGetCompanyData = () => {
    const { startupId } = useSession();
    const { manualQuery,loading,data,refetch,error } = useLazyQuery(COMPANY_DATA, {
        startupId 
    });

    return { data, loading, error, refetch, manualQuery };
}
export default useGetCompanyData;
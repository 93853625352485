import React, { useEffect, useState } from 'react';
import ErrorInput from "../../../modules/generic/components/Inputs/ErrorInput";
import classes from './RadioCheckboxInput.module.scss';
import Checkbox from '@material-ui/core/Checkbox';

const RadioCheckboxInput = ({
  name,
  options,
  type = "radio",
  orientation = "horizontal",
  fieldId = "id",
  fieldLabel = "label",
  onChange,
  errorMessage,
  error,
  value,
  ...props
}) => {

  const [stateValue, setStateValue] = useState(value);

  const handleSelect = (option) => {
    setStateValue(option[fieldId])
    onChange(option[fieldId]);
  };

  useEffect(() => {
    if (stateValue !== value) {
      setStateValue(value)
    }
  }, [value]);

  const getOrientationClass = () => {
    if (orientation === "horizontal") {
      return classes.Horizontal
    }
    return classes.Vertical
  };

  return (
    <div className={`${classes.RadioCheckboxInput} `} >
      <div className={`${classes.Options} ${getOrientationClass()}`} >
        {options.map((option, i) => (
          <div key={i} className={classes.Option} >
            <Checkbox
              checked={stateValue === option[fieldId]}
              onClick={(e) => handleSelect(option)}
              color="primary"
            />
            <label>
              {option.label}
            </label>
          </div>
        ))}
      </div>
      <ErrorInput message={errorMessage} component={error} />
    </div>
  )
}


export default RadioCheckboxInput
import useGetStakeholderDetails from './graphql/useGetStakeholderDetails'
import useUpsertRenewalData, { Tasks } from './graphql/useUpsertRenewalData'
import useUpsertStakeholderDetails from './graphql/useUpsertStakeholderDetails'
import useMapperStakeholderDetails from './useMapperStakeholderDetails';

const useServices = ({ initialValues }) => {
  const updateStakeholderTasks = useUpsertRenewalData()
  const { query } = useGetStakeholderDetails({})
  const { getInitialData, getSubmitData } = useMapperStakeholderDetails()
  const updateStakeholder = useUpsertStakeholderDetails({}, {})

  return {
    
    initialData: async (context, event) => {
      const data = await query(initialValues.stakeholderId)
      const initData = getInitialData(data?.stakeholder)
      return initData;
    },

    SaveStep: async (context, event) => {
      const { ReviewNationalitiesUBO, initialData } = context;
      const tasks = ['isStakeholderDetailsConfirmed' as Tasks]
      const data = getSubmitData(context);
      const result = await updateStakeholder(data, initialValues.stakeholderId)
      const resultTasks = await updateStakeholderTasks(initialData.groupCompany.id, initialValues.stakeholderId)
      return result;
    }
  }
}

export default useServices;

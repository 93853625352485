import _ from 'lodash';
import { useMemo } from 'react';
import useUpdateAcceleratorQuestion from "../../../../../modules/accelerators/modals/editQuestion/hooks/useUpdateAcceleratorQuestion";
import useSession from "../../../../session/hooks/useSession";

const useServices = ({ initialValues }) => {

  const [updateQuestionResult] = useUpdateAcceleratorQuestion({});
  const { startupId } = useSession();

  return useMemo(() => ({
    initialData: async () => {
      return initialValues;
    },
    uploadQuestionnaire: async (context, event) => {
      const allResponse = _.get(context, "newResponse");
      const variables = {
        startupId,
        response: {
          ...allResponse,
          id: _.get(context, "initialData.id"),
        },
      };
      await updateQuestionResult({ variables });
    }
  }), []);
}

export default useServices;
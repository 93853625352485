import moment from "moment";
import hash from "object-hash";
import publicIp from 'public-ip';
import { v4 as uuidv4 } from 'uuid';
import { loadSessionState, saveSessionState } from "./../../session";
import { Constants } from './constants';
import ServerConnect from "./ServerConnect";
import _ from "lodash";
const intialLogger = [];//loadSessionState(Constants.LOGGER.SESSION_STORAGE.INFO)];
// const loggerUnitsToSaveStore = window.env.loggerConfig.unitToSaveStorage?window.env.loggerConfig.unitToSaveStorage:Constants.LOGGER.DEFAULT_LOGGER_UNITS_TO_SAVE_STORE;
const loggerUnitsToClearStore = window?.env?.loggerConfig?.unitToClearStorage ?? Constants.LOGGER.DEFAULT_LOGGER_UNITS_TO_CLEAR_STORE;
const loggerSendToServer =  window?.env?.loggerConfig?.sendToServer ??Constants.LOGGER.DEFAULT_LOGGER_SEND_TO_SERVER;
const loggerLimitCharSuccess =  window?.env?.loggerConfig?.limitChartSuccess??Constants.LOGGER.DEFAULT_LIMIT_CHART_SUCCESS;
const hashUuid = loadSessionState(Constants.LOGGER.SESSION_STORAGE.HASH_UUID);
window.loggerInfo=intialLogger?intialLogger:[];

const getUser = ()=>{
  const state = JSON.parse(localStorage.getItem("session"));
  if (!state){ return null }
  const user = state.user;

  if (!user){
    return null
  }
  const {_id,email,userRole} = user;
  return {id:_id,email,userRole}
}
const getStartup = ()=>{
  const state = JSON.parse(localStorage.getItem("session"));
  if (!state){ return null }
  const startup = state.startup;
  if (!startup){
    return null
  }
  const {id,name} = startup;
  return {id,name}
}
const sendLogToServer =async (user)=>{
  console.log("Logger: Send Server - ", window.loggerInfo)
  if (loggerSendToServer){
    const ip = await publicIp.v4();
    let newHashUuid =hashUuid;
    if (!hashUuid){
      const uuid= uuidv4();
      newHashUuid = hash({uuid,ip});
      saveSessionState(Constants.LOGGER.SESSION_STORAGE.HASH_UUID,newHashUuid);
    }
    const element = {
      "uuid": newHashUuid,
      "remoteClient": ip,
      "email": user.email,
      "steps": window.loggerInfo
    }
    ServerConnect.requestApi("/applicationError", {
      method: 'POST', headers: { "Content-Type": "application/json" },
      body: JSON.stringify(element)
    },null,{showNotification:false},false);
  }
  window.loggerInfo=[];
  // saveSessionStorage([]);
 
  
}
const clearStore = ()=>{
  window.loggerInfo = window.loggerInfo.slice( Math.trunc(window.loggerInfo.length/2) , window.loggerInfo.length)
  // saveSessionState(Constants.LOGGER.SESSION_STORAGE.INFO,window.loggerInfo)
}
// const saveSessionStorage=(data)=>{
//   try{
//     saveSessionState(Constants.LOGGER.SESSION_STORAGE.INFO,data)
//   }catch(error){
//     clearStore()
//   }
// }
const log = (type, action, params) => {
  
    const element = {
      type,
      action,
      user:getUser(),
      startup:getStartup(),
      time: moment().format("YYYY-MM-DD hh:mm:ss"),
      params
    }
    window.loggerInfo.push(element)
    // if (window.loggerInfo.length % loggerUnitsToSaveStore ==0){
    //   saveSessionStorage(window.loggerInfo);

    // }
    if (window.loggerInfo.length % loggerUnitsToClearStore ==0){
      clearStore()
    }
    if (window?.env?.loggerConfig?.showLogger) {
      console.log("Logger: Line - ", element)
    }
  
}
const error = (action, params) => {
  log(Constants.LOGGER.TYPES.ERROR, action, params)
  const user = getUser();
  if (user){
    sendLogToServer(user);
  }
}
const success = (action, params) => {
  if (_.get(params,"result")){
    const cad = JSON.stringify(_.get(params,"result"));
    if (cad.length>loggerLimitCharSuccess){
      _.set(params,"result",cad.substring(0,loggerLimitCharSuccess));
    }
  }
  log(Constants.LOGGER.TYPES.SUCCESS, action, params)
}
const warning = (action, params) => {
  log(Constants.LOGGER.TYPES.WARGING, action, params)
}
const info = (action, params) => {
  log(Constants.LOGGER.TYPES.INFO, action, params)
}
info(Constants.LOGGER.ACTIONS.RELOAD,{})
export default {
  log,
  error,
  success,
  warning,
  info,
}

import { useMemo } from 'react';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import EditFormStep from '../steps/EditFormStep';

const useSteps = () => {

  return useMemo(() => ({
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    EditFormStep: {
      render: EditFormStep,
    },
    SaveStep: {
      render: TemplateWizardSkeletonMachine,
    },
    
  }), []);
}

export default useSteps;

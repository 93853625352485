import MachineEquity from "../../../../wizard/machine/MachineEquity";
import FirstWarrantStep from "./steps/FirstStep";
import SelectCompaniesWarrantStep from "./steps/SelectCompaniesStep";
import WarrantFormStep from "./steps/WarrantFormStep";
import IsThereDocumentWarrantStep from "./steps/IsThereDocumentsStep";
import UploadDocumentWarrantFormStep from "./steps/UploadDocumentStep";
import SelectDocumentWarrantStep from "./steps/SelectDocumentStep";
class WarrantMachine extends MachineEquity{
   constructor() {
       super('warrant',FirstWarrantStep,SelectCompaniesWarrantStep,WarrantFormStep,IsThereDocumentWarrantStep,UploadDocumentWarrantFormStep,SelectDocumentWarrantStep,null);
   }
   getParamsData(initialData: object){
       return initialData
   }
   getInitialValues(initialData: object): object {
       return {}
   }
}
export default WarrantMachine
import React, {useState, useRef, useEffect, FC} from "react";
import useGetCityByState from './graphql/useGetCityByState'
import useGetStatesByCountry from './graphql/useGetStatesByCountry'
import RowAdd from '../../../forms/contents/RowAdd/RowAdd';
import SelectInput from "../SelectInput/SelectInput";
import Classes from './styles.module.scss'
import Loading from "src/components/generic/Loading/Loading";
import TooltipInput from "src/components/generic/Tooltips/TooltipIput/TooltipInput"
import CircularProgress from '@mui/material/CircularProgress'

interface Props {
    defaultState: string
    errorMessage: any
    modal?: boolean
    defaultCity: string
    onChangeState: (value: string) => void
    onChangeCity: (value: string) => void
    selectCityLabel: string
    selectStateLabel?: string
    tooltips?: { city: string, state: string }
    showState: boolean
    showCity?: boolean
}

const SelectStateCity: FC<Props> = ({
        defaultState,
        errorMessage,
        showState,
        selectCityLabel,
        selectStateLabel,
        modal,
        onChangeState,
        onChangeCity,
        defaultCity,
        tooltips,
        showCity = true
    }) => {
    // console.log(`defaultState: ${defaultState}`);
    // console.log(`defaultCity: ${defaultCity}`);

    const [state, setState] = useState(null);
    const {data: states} = useGetStatesByCountry({
        language: "en",
        code: "USA"
    }, {skip: !showState});

    const {data: cities, loading, manualQuery: getCities} = useGetCityByState({
        language: "en",
        code: "USA",
        state: defaultState ? defaultState : "Alaska"
    }, {skip: (state || defaultState)!});

    const refState = useRef()
    const refCity = useRef()

    const stateList = React.useMemo(() => (
        states || []).map((stateItem) => (
        {label: stateItem, value: stateItem})), [JSON.stringify(states)]);
    const citiesList = React.useMemo(() => (
        cities || []).map(cityItem => (
        {label: cityItem, value: cityItem})), [JSON.stringify(cities)]);

    useEffect(() => {
        if (defaultState) onChangeState && onChangeState(defaultState)
    }, [defaultState])
    useEffect(()=>{
      if(citiesList && defaultCity) onChangeCity(defaultCity)
    },[defaultCity,citiesList])

    const handleStateChange = (value) => {
        // console.log(`state: ${value}`)
        setState(value)
        getCities({variables: {state: value, code: "USA", language: "en"}})
        onChangeState(value)
        if (!value) onChangeCity(null);
    };

    useEffect(() => {
        if (defaultState) getCities({variables: {state: defaultState, code: "USA", language: "en"}})
    }, [defaultState]);

    return <>
        {showState && (<div className={Classes.containerForm}>
            <TooltipInput label={tooltips?.state} classes={undefined} size={undefined} align={undefined}>
                <RowAdd
                    label={`${selectStateLabel} `}
                    asterisk={true}
                    classNameInput={modal ? Classes.inputModal : Classes.input}
                    input={<SelectInput
                        ref={refState}
                        list={stateList}
                        clear={true}
                        value={defaultState || null}
                        placeholder={"Select from the list"}
                        onChange={(value) => {
                                handleStateChange(value);
                        }}
                        errorMessage={typeof errorMessage === 'string' ? errorMessage : ''}
                    />}
                    className={undefined}
                    paramLabel={undefined}
                />
            </TooltipInput>
        </div>)}

        {(((state  || defaultState) && state !== 'Delaware') && showCity) &&
            <div className={Classes.containerForm}>
                {loading ? <CircularProgress/> : <>
                    <TooltipInput
                        label={tooltips?.city}
                        classes={undefined}
                        size={undefined}
                        align={undefined}>
                        <RowAdd
                            label={`${selectCityLabel} `}
                            asterisk={true}
                            classNameInput={modal ? Classes.inputModal : Classes.input}
                            input={<SelectInput
                                // list={(state || defaultState) ? citiesList : []}
                                list={citiesList}
                                ref={refCity}
                                value={defaultCity || null}
                                clear={true}
                                placeholder={"Select from the list"}
                                onChange={(value) => {
                                        onChangeCity(value);
                                }}
                                errorMessage={typeof errorMessage === 'string' ? errorMessage : ''}
                            />}
                            className={undefined}
                            paramLabel={undefined}
                        />
                    </TooltipInput>
                </>
                }
            </div>
        }
    </>;
};

export default SelectStateCity;

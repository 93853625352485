import { Stack, Box } from "@mui/material";
import _ from "lodash";
import { useCallback, useMemo, useState } from 'react';
import Form from "src/components/forms/Form/FormL";
import Scrollbar from "src/components/generic/Scrollbar/Scrollbar";
import TextInput from "src/components/inputs/Text/TextInput";
import Avatar from "src/modules/generic/components/Avatars/AvatarLabel";
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import Icon from "src/modules/generic/components/Icon";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import Typography from "src/modules/generic/components/Texts/Typography";
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from "src/modules/generic/hooks/useTranslate";
import * as Yup from 'yup';
import cls from "./classes.module.scss";
import TemplateOnboarding2Wizard from 'src/modules/generic/templates/Modal/TemplateOnboarding2Wizard';
import Button from "src/modules/generic/components/Buttons/Button";

/**
 * Step to add founders
 * @param {*} param0
 * @returns
 */
function AddFounderStep({ submitButonProps, actualStep, totalSteps }) {
  const { next, prev, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const contextValues = context?.AddFounderStep?.founders ?? [];
  const schema = Yup.object().shape({});
  const [founder, setFounder] = useState("");
  const [founders, setFounders] = useState(contextValues);

  const addFounder = useCallback(() => {
    if (founder) {
      setFounders((prev) => [...prev, founder]);
      setFounder("");
    }
  }, [founder]);

  const handleNext = useCallback(
    () => {
      next({ founders });
    },
    [next, founders]
  );

  const handleCancel = () => {
    cancel();
  }

  const handleRemoveFounder = useCallback((index) => {
    const newFounders = _.cloneDeep(founders);
    newFounders.splice(index, 1);
    setFounders(newFounders);
  }, [founders]);

  const leftComponent = useMemo(() => {
    return (
    <Stack spacing={4} pt={11} sx={{width: "100%", height: "100%"}}>
      <Typography color={"black"} variant={"onboarding-titles-bold"}  >
        <span dangerouslySetInnerHTML={{ __html: translate("MODULES.ONBOARDINGCOMPANY.ONBOARDING_ADD_FOUNDER_TITLE") }}>
        </span>
      </Typography>
        <Stack spacing={2} pr={"120px"}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <TemplateLabel
              isRequired
              label={translate('MODULES.ONBOARDINGCOMPANY.ONBOARDING_ADD_FOUNDER_LABEL_FOUNDER')}
            >
              <TextInput
                name="founder"
                value={founder}
                placeholder={translate('MODULES.ONBOARDINGCOMPANY.ONBOARDING_ADD_FOUNDER_PLACEHOLDER_FOUNDER')}
                onChange={setFounder}
              />
            </TemplateLabel>
            <Button
              className={cls.ButtonAdd} 
              onClick={addFounder} 
              disabled={founder === ""}
              variant="primary"
            >
              {translate('MODULES.ONBOARDINGCOMPANY.ONBOARDING_ADD_FOUNDER_BUTTON_ADD')}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    )
  }, [addFounder, founder, translate]);

  const rightComponent = useMemo(() => {
    return (
      <Box
        sx={{
          width: "100%",
          height: "486px",
          paddingTop: "38px"
        }}
      >
        <div class={cls.FounderContent}>
          <Scrollbar class={cls.scrollArea}>
            <div class={cls.TagsContent}>
              {_.map(founders, (founder, index) => {
                return (
                  <Stack key={index} direction="row" spacing={2} className={cls.Tag}>
                    <Avatar
                      name={founder}
                      showLabel={true}
                    />
                    <Icon icon="Delete-bin" size={"16px"} isClara={true} onClick={()=>handleRemoveFounder(index)} />
                  </Stack>
                )
              })}
            </div>
          </Scrollbar>
        </div>
      </Box>
    )
  }, [founders, handleRemoveFounder])

  return (
    <Form
      template={TemplateOnboarding2Wizard}
      schema={schema}
      onSubmit={handleNext}
    >
      <TemplateOnboarding2Wizard
        buttons={{
          submit: <SubmitButton {...submitButonProps} disabled={founders.length === 0}/>
        }}
        cancel={handleCancel}
        previousClick={prev}
        leftComponent={leftComponent}
        rightComponent={rightComponent}
        actualStep= {actualStep}
        totalSteps={totalSteps}
      >
      </TemplateOnboarding2Wizard>
    </Form>
  );
}
export default AddFounderStep;
import React, { FC } from "react";
import Wizard from "../../../../generic/components/Wizard";
import ModalAssistant, {ModalAssistantProps} from "../../../../generic/components/Modal/ModalAssistant";
import MachineAccountingRecords from "./machine";
import useModal from "../../../../generic/hooks/useModal";

interface ModalAccountingRecordsProps extends ModalAssistantProps {
  paramsMutation: object;
  onClose(): void;
}

const ModalAccountingRecords: FC<ModalAccountingRecordsProps> = ({
  children,
  open = false,
  paramsMutation,
  ...props
}: ModalAccountingRecordsProps) => {

  const { closeModal: onClose } = useModal();

  const handleClose = (values) => {
    onClose();
  };

  return (
    <ModalAssistant open={open}>
      <Wizard
        machine={MachineAccountingRecords}
        initialValues={paramsMutation}
        onComplete={handleClose}
      />
    </ModalAssistant>
  );
};

export default ModalAccountingRecords;

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from "react-redux";
import _ from "lodash";
import { addAssistantText, clearAssistatText } from 'src/modules/generic/store/action';
import Button from 'src/modules/generic/components/Buttons/Button';
import { Constants } from 'src/v1/utils';
import DetailsTabTitle from 'src/components/templates/DetailsTabTitle';
import EditGeneralDetails from 'src/components/modules/clara/startups/individualStakeholder/Modals/EditGeneralDetails/EditGeneralDetails.js'
import Link from "src/components/text/Link/Link";
import PincasIcon from "src/modules/generic/components/Icon/PincasIcon";
import { RoleAccess } from 'src/v1/components';
import Stakeholder from 'src/components/modules/clara/startups/entities/classes/Stakeholder';
import TwoColumnsViewer from "src/modules/generic/components/TwoColumsViewer";
import useModal from 'src/modules/generic/hooks/useModal';
import useTranslate from "src/modules/generic/hooks/useTranslate";

export default function GeneralDetailsPanel({ data, isLock, isManagedByClara, jurisdictions }) {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const [itemsCol1, setItemsCol1] = useState([]);
  const [itemsCol2, setItemsCol2] = useState([]);
  const { openModal, closeModal } = useModal();

  const entityLegalStructureName = (code: string) => {
    switch (code) {
      case 'LIMITED_BY_SHARES':
        return 'Company Limited by shares';
      case 'LIMITED_PARTNERSHIP':
        return 'Limited partnership';
      case 'TRUST':
        return 'Trust';
      case 'OTHER':
        return 'Other';
      case 'DEFAULT':
        return 'Default';
      default:
        return '';
    }
  };

  useEffect(() => {
    isManagedByClara && dispatch(addAssistantText("<strong>Additional fees</strong> might apply if you need to update some of the details.</br><div style='margin-top:1rem;'>Click on the field to update to view the corresponding fees. To make a change, just edit the content and proceed. The change request will be added to your cart.</div> <div style='margin-top:1rem;'>Please note: if the stakeholder is a shareholder or director in multiple companies, the change must updated and paid for all companies.</div> ",'alert'));
    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch]);

  const stakeholderClass = useMemo(()=>{
    if (!data) return null;
    return new Stakeholder(data);
  }, [data]);

  const generalDetailsButton =
  <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
    <Button
      disabled={isLock}
      variant='card'
      children={translate('VIEW_COMPANY_TAB_BUTTON_DETAILS_EDIT')}
      onClick={() => {
        openModal(EditGeneralDetails, { handleCloseModal: closeModal, isManagedByClara, jurisdictions })
      }}
    />
  </RoleAccess>;

  useEffect(() => {
    let newItemsCol1 = [];
    let newItemsCol2 = [];
    newItemsCol1.push({
      label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_COUNTRY"),
      value: _.get(data, "nationality.name", "-"),
      valuePrefix: stakeholderClass?.getFieldInMatter("nationality") ? <PincasIcon></PincasIcon> : null,
      disabledField: stakeholderClass?.getFieldInMatter("nationality")
    });
    newItemsCol1.push({
      label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_JURISDICTION"),
      value: _.get(data, "jurisdiction", "-"),
      valuePrefix: stakeholderClass?.getFieldInMatter("jurisdiction") ? <PincasIcon></PincasIcon> : null,
      disabledField: stakeholderClass?.getFieldInMatter("jurisdiction")
    });
    newItemsCol1.push({
      label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_PREVIOUS_COMPANY_NAME"),
      value: _.get(data, "previousName", "-"),
      valuePrefix: stakeholderClass?.getFieldInMatter("previousName") ? <PincasIcon></PincasIcon> : null,
      disabledField: stakeholderClass?.getFieldInMatter("previousName")
    });
    newItemsCol1.push({
      label: translate("QUESTIONNAIRE_FIELD_COMPANY_TYPE"),
      value: _.get(data, "type", "-"),
      valuePrefix: stakeholderClass?.getFieldInMatter("type") ? <PincasIcon></PincasIcon> : null,
      disabledField: stakeholderClass?.getFieldInMatter("type")
    });
    if (_.includes(jurisdictions, 'CAYMAN')) {
    newItemsCol1.push({
      label: translate("QUESTIONNAIRE_FIELD_LEGAL_ENTITY"),
      value: entityLegalStructureName(_.get(data, "companyType", "-")),
      valuePrefix: stakeholderClass?.getFieldInMatter("companyType") ? <PincasIcon></PincasIcon> : null,
      disabledField: stakeholderClass?.getFieldInMatter("companyType")
    });}

    newItemsCol2.push({
      label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_COMPANY_NUMBER"),
      value: _.get(data, "registeredNumber", "-"),
      valuePrefix: stakeholderClass?.getFieldInMatter("registeredNumber") ? <PincasIcon></PincasIcon> : null,
      disabledField: stakeholderClass?.getFieldInMatter("registeredNumber")
    });
    newItemsCol2.push({
      label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_TAX_REGISTRATION_NUMBER"),
      value: _.get(data, "taxRegistrationNumber", "-"),
      valuePrefix: stakeholderClass?.getFieldInMatter("taxRegistrationNumber") ? <PincasIcon></PincasIcon> : null,
      disabledField: stakeholderClass?.getFieldInMatter("taxRegistrationNumber")
    });
    newItemsCol2.push({
      label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_WEBSITE"),
      value: _.get(data, 'linkedinURL') ? <Link target={"_blank"} href={_.get(data, 'linkedinURL', "-")}>{_.get(data, 'linkedinURL', "-")} </Link> : null,
    });

    setItemsCol1(newItemsCol1);
    setItemsCol2(newItemsCol2);
  }, [data]);

  return (
    <>
      <DetailsTabTitle title="General details" buttons={generalDetailsButton} />
      <TwoColumnsViewer
        itemsCol1={itemsCol1}
        itemsCol2={itemsCol2}
      />
    </>
  )
}
import React, { useEffect, useCallback, useState ,useMemo} from 'react';
import { useDropzone } from 'react-dropzone';
import classes from "./FileUploaderDropzone.module.scss";
import Icon from '../../../modules/generic/components/Icon';
import Upload from '../../../v1/components/Form/Upload/Upload';
import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormikHelper from "../../../v1/utils/formikHelper";

const FileUploaderDropzone = ({ field, form,required, ...props }) => {
    const [uploadedFile, setUploadedFile] = useState(null);
    const [loading, setLoading] = useState(false)

    const onDrop = () => {
        setLoading(true)
    }


    const handleDone = (files) => {
        let fileUpdate = null;
        _.forEach(files, (file) => {
            fileUpdate = file;
        });
        form.setFieldValue(field.name, fileUpdate)
    }

    const {acceptedFiles, getRootProps, getInputProps, open, isDragActive} = useDropzone({
        onDrop,
        multiple: false,
        maxFiles: 1,
        maxSize: 100000000,
        noClick: true,
        noKeyboard: true
    });
    const error = useMemo(() => {
        return FormikHelper.returnErrorsForValidation(field.name, form.errors);
    }, [JSON.stringify(form.errors)])

    return (<>
        <section className={`${classes.container} ${error?classes.containerError:null}`}>
            <div {...getRootProps({ className: 'dropzone' })}>
                <div className={classes.contentContainer}>


                    <input {...getInputProps()} />
                    <Icon isClara={true} icon={'Generic'} size={'5rem'} />
                    <span><span className={classes.browseButton} onClick={open}>Upload</span> your file or drag and drop here{required && <span style={{color:'red'}}>{' '}*</span>}</span>
                    {loading ? <CircularProgress /> : null}
                    {acceptedFiles ? <div className={classes.hiddenContainer}><Upload files={acceptedFiles} handleDone={handleDone} automaticDone={true} {...props} /></div> : null}
                </div>
            </div>
            <aside>
            </aside>
        </section>
        {error?
            <div className={classes.MessageError}>{error}</div>:null
    }
    </>);
}

export default FileUploaderDropzone;
import React, {Component} from "react";
import {connect} from "react-redux";
import {Field, FieldArray, Formik} from "formik";
import {Constants} from '../../../../utils/constants'
import TextInput from "../../../../containers/Forms/Inputs/TextInput.js";
import ClaraSelect from "../../../Form/ClaraSelect/ClaraSelect";
import ClaraInputRadioButton from "../../../Form/ClaraInputRadioButton/ClaraInputRadioButton";
import classes from '../EditForm.module.scss';
import {issuesValidations} from "../../../../containers/Forms/validations";
import _ from "lodash";
import {goToPage, goToPrevPage} from "../../../../../store/actions/page";
import TextAreaInput from "../../../../containers/Forms/Inputs/TextAreaInput";

import AddInput from "../../../../containers/Forms/Inputs/AddInput";

class Details extends Component {
    state = {
        refresh: false,
        category: undefined,
        categories: {},
        groupCompanies: [],
        stakeholders: [],
        documents: []
    }
    getCategoriesList = () => {
        var vec = [];
        _.forEach(this.props.categories, (category, id) => {
            vec.push({ id: category.key, label: category.title });
        })
        return vec
    }
    getSubCategories = () => {

        let { categories, category } = this.state;
        var index = _.findIndex(categories, function (o) {
            return o.key === category;
        });

        if (index >= 0) {
            return categories[index].subCategories.map((value, key) => {
                return { id: value.key, label: value.name }
            })
        }
        return [];

    }
    updateCategory = (category) => {
        this.setState({
            refresh: !this.state.refresh,
            category: category
        });

    }

    componentWillMount() {

        const {data} = this.props

        if(!_.isEmpty(data)){

        this.setState({
            categories: this.props.categories,
            category: data.category,
            data : this.getDataFormik(data)
            
        })
        this.addElementsFromData(data.stakeholders, 'stakeholders', this.props.stakeholderList,'stakeholder');
        this.addElementsFromData(data.groupCompanies, 'groupCompanies', this.props.groupCompanyList, 'company');
        this.addElementsFromData(data.documents, 'documents', this.props.documentList, "document");
    }

    }
    getDataFormik = (data) =>{

        return {
            ...data,
            stakeholders: data.stakeholders.map(st => st.id),
            groupCompanies: data.groupCompanies.map(gc => gc.id),
            documents: data.documents.map(doc => doc.id)
        }
    }

    handleChangedElement = (value, _, form, paramsToCallbacks) => {
        if (value) {
            const id = value.id;
            const index = paramsToCallbacks.index;

            const element = paramsToCallbacks.list.find(e => e.id === id);


        }


    }

    addElementsFromData(elements, arrayName, list, elementName) {
        
        const newElements = [];
        elements.forEach((e, i) => {
            newElements.push(
                this.getNewElementStructure(arrayName, i, list, elementName)
            )

        });

        this.setState({
            [arrayName]: newElements
        })

    }

    addElement = (arrayName, list, elementName) => {

        const newElements = this.state[arrayName];
        const i = newElements.length;

        newElements.push(
            this.getNewElementStructure(arrayName, i, list, elementName)
        )

        this.setState({
            [arrayName]: newElements
        });
    }

    getNewElementStructure = (arrayName, index, list, elementName) => {
        const { tooltips = {} } = this.props;

        return (
            <div>

                <div className={classes.ContainerRow}>
                    <Field name={`${arrayName}[${index}]`}>
                        {({ field, form }) => (
                            <ClaraSelect
                                label={`${_.capitalize(elementName)} #` + (index + 1)}
                                field={field}
                                form={form}
                                placeholder={"Search or select from a list..."}
                                lists={list}
                                mode={"classic"}
                                tooltip={tooltips[" "]}
                                //callbacks={{ updateValues: handleChanged }}
                                paramsToCallbacks={{ index }}
                            />
                        )

                        }

                    </Field>

                </div>
            </div>
        )

    }

    changeMode = () => {
        this.props.goToPrevPage();
    };

    onSubmit = (values, bag) => {
        this.props.updateForm(values);
        this.props.goToPrevPage();
    };

    isDisabled(errors, touched) {
        return Object.keys(errors).length > 0;
    }

    render() {
        let { data, tooltips = {} } = this.state;


        return (
            <div className={classes.ContainerEdit}>
                <Formik
                    initialValues={data}
                    enableReinitialize={false}
                    onSubmit={this.onSubmit}
                    validationSchema={issuesValidations}
                    render={({ values, handleSubmit, isSubmitting, handleReset, setFieldValue, errors, touched, }) => {
                        return (
                            <form onSubmit={handleSubmit} className={classes.Form}>
                                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                                    <Field name={`category`}>
                                        {({ field, form }) => (
                                            <ClaraSelect
                                                required={false}

                                                field={field}
                                                form={form}

                                                label={"Choose Remark Category: "}
                                                lists={this.getCategoriesList()}
                                                mode={"classic"}
                                                callbacks={{
                                                    updateValues: this.updateCategory
                                                }}
                                                placeholder={"Select from a list.."}
                                                tooltip={tooltips['Choose Remark Category']}
                                            />

                                        )}
                                    </Field>
                                </div>
                                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                                    <Field name={`subCategory`}>
                                        {({ field, form }) => (

                                            <ClaraSelect
                                                required={false}

                                                field={field}
                                                form={form}

                                                label={"Choose Sub Category: "}
                                                lists={this.getSubCategories()}
                                                mode={"classic"}

                                                placeholder={"Select from a list.."}
                                                tooltip={tooltips['Choose Sub Category']}
                                            />
                                        )}
                                    </Field>
                                </div>
                                <hr className={classes.Separator} />
                                <div className={`${classes.ContainerRow} ${classes.issuesContainerLabel}`}>
                                    <Field name='urgency'>
                                        {({ field, form }) => (
                                            <ClaraInputRadioButton
                                                label={"Choose Remark Status"}
                                                field={field}
                                                form={form}
                                                value={field.value}
                                                required={true}
                                                options={[
                                                    {
                                                        "id": "RED",
                                                        "label": "Red",
                                                        className: classes.RED,
                                                        classNameSelected: classes.SelectedRED
                                                    },
                                                    {
                                                        "id": "AMBER",
                                                        "label": "Amber",
                                                        className: classes.AMBER,
                                                        classNameSelected: classes.SelectedAMBER
                                                    },
                                                    {
                                                        "id": "GREEN",
                                                        "label": "Green",
                                                        className: classes.GREEN,
                                                        classNameSelected: classes.SelectedGREEN
                                                    },
                                                    {
                                                        "id": "UNKNOWN",
                                                        "label": "Unknown",
                                                        className: classes.UNKNOWN,
                                                        classNameSelected: classes.SelectedUNKNOWN
                                                    }

                                                ]}
                                                disabled={false}
                                                tooltip={tooltips['Choose Issue Status']}
                                            />
                                        )}
                                    </Field>
                                </div>
                                  <hr className={classes.Separator} />

                                <FieldArray
                                    name={"groupCompanies"}
                                    render={() => <div>{this.state.groupCompanies.map(gc => gc)}</div>}
                                />
                                <div className={`${classes.ContainerRow} `}>
                                    <Field name="groupCompany">
                                        {({ field, form }) => (
                                            <AddInput
                                                label={"Add Group Company"}
                                                onClickAdd={() => this.addElement("groupCompanies", this.props.groupCompanyList, "company")}

                                            />

                                        )}

                                    </Field>
                                </div>

                                <FieldArray
                                    name={"stakeholders"}
                                    render={() => <div>{this.state.stakeholders.map(st => st)}</div>}
                                />

                                <div className={`${classes.ContainerRow} `}>
                                    <Field name="stakeholder">
                                        {({ field, form }) => (
                                            <AddInput
                                                label={"Add stakeholder"}
                                                onClickAdd={() => this.addElement("stakeholders", this.props.stakeholderList, "stakeholder")}

                                            />

                                        )}

                                    </Field>
                                </div>


                                <FieldArray
                                    name={"documents"}
                                    render={() => this.state.documents.map(doc => doc)}
                                />

                                <div className={`${classes.ContainerRow} `}>
                                    <Field name="documents">
                                        {({ field, form }) => (
                                            <AddInput
                                                label={"Add document"}
                                                onClickAdd={() => this.addElement("documents", this.props.documentList, "document")}

                                            />

                                        )}

                                    </Field>
                                </div>

                                <hr className={classes.Separator} />

                                <div className={classes.ContainerRow}>
                                    <Field name='title'>
                                        {({ field, form }) => (
                                            <TextInput
                                                required={true}
                                                type='text'
                                                label='Remark Name: '
                                                field={field}
                                                form={form}
                                                autoComplete='off'
                                                placeholder={"Remark Name..."}
                                                tooltip={tooltips['Issue Name']}
                                            />
                                        )}
                                    </Field>
                                </div>
                                <div className={classes.ContainerRow}>
                                    <Field name='description'>
                                        {({ field, form }) => (
                                            <TextAreaInput
                                                required={false}
                                                label='Remark Note: '
                                                field={field}
                                                form={form}
                                                autoComplete='off'
                                                placeholder={"Remark Note..."}
                                                tooltip={tooltips['Issue Note']}
                                            />
                                        )}
                                    </Field>
                                </div>


                                <div className={classes.Botonera}>
                                    <button type='submit' data-cy="submit" disabled={this.isDisabled(errors, touched)}>
                                        Save Changes
                                    </button>
                                    <button
                                        type='button' onClick={() => {
                                            this.changeMode()
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        )
                    }}
                >

                </Formik>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    session: state.session,

});
const mapDispatchToProps = (dispatch) => ({
    goToPage: (page, params) => {
        goToPage(dispatch, page, params)
    },
    goToPrevPage: () => {
        goToPrevPage(dispatch)
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(Details)

import React, { FC, useRef } from "react";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import Form from "../../../../../../components/forms/Form/Form";
import ControllerInput from "../../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import SubmitButton from "../../../../../generic/components/Buttons/SubmitButton";
import TemplateFormDefault from "../../../../../generic/templates/Modal";
import FormProps from "../../../../../generic/forms/interfaces/FormProps";
import { CheckboxButtonOptions } from "../../../../../generic/components/Inputs/CheckboxButton";
import schema from "./schema";
import CheckboxButtonSimple from "../../../../../generic/components/Inputs/CheckboxButtonSimple";
import classModule from "./classes.module.scss";

const IsIndividualOrCompany: FC<FormProps> = ({
  initialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
}: FormProps) => {

  const { translate } = useTranslate();
  const refForm = useRef();

  const options: Array<CheckboxButtonOptions<boolean, any>> = [
    {
      value: false,
      label: translate(
        "MODULES.ONBOARDING_FORMS.INDIVIDUAL_OR_COMPANY.INDIVIDUAL"
      ),
    },
    {
      value: true,
      label: translate(
        "MODULES.ONBOARDING_FORMS.INDIVIDUAL_OR_COMPANY.COMPANY"
      ),
    },
  ];

  return (
    <>
      <Form
        defaultValues={initialValues || {}}
        schema={schema}
        onlySendDirty={false}
        onSubmit={onCompleteSubmit}
        ref={refForm}
        optionsForm={{ mode: "onChange" }}
      >
        {" "}
        <Template
          buttons={{
            ...buttons,
            submit: <SubmitButton {...buttonSubmitProps}>Next</SubmitButton>,
          }}
          props={propsTemplate}
        >
          <ControllerInput
            render={CheckboxButtonSimple}
            className={classModule.CheckboxButton}
            classNameButton={classModule.buttonCheckBox}
            name='isEntity'
            options={options}
            onChange={value => {}}
          />
        </Template>
      </Form>
    </>
  );
};

export default IsIndividualOrCompany;

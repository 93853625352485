const useGuards = () => {
  return {
      isSecondarySignor:(context)=>{
        const nomineeStep = context?.ClaraNomineeStep;
        return  (
          nomineeStep?.useNominee === true || nomineeStep?.useNominee === "true"
        )
      },
      isNotSecondarySignor:(context)=>{
        const nomineeStep = context?.ClaraNomineeStep;
        return (
          nomineeStep?.useNominee === false ||
          nomineeStep?.useNominee === "false"
        );
      },
      correct: (context) => {
        const confirmStep = context?.ReviewAndConfirmStep;
        return confirmStep?.confirm === true;
      },
      previousClaraNominee:(context)=>{
        const nomineeStep = context?.ClaraNomineeStep;
        return (
          nomineeStep?.useNominee === true ||
          nomineeStep?.useNominee === "true"
        );
      },
      previousIsSecondary:(context)=>{
        const nomineeStep = context?.ClaraNomineeStep;
        console.log(nomineeStep)
        return (
          nomineeStep?.useNominee === false ||
          nomineeStep?.useNominee === "false"
        );
      },
      hasNotGCCOrUAE:(context)=>{
        return context?.initialData?.stakeholdersNotUAEOrGCC?.length > 0
      },
      hasNotNotGCCOrUAE:(context)=>{
        return !context?.initialData?.stakeholdersNotUAEOrGCC?.length > 0
      },
      isThereSecondary:(context)=>{
        const secondaryStep = context?.IsThereSecondarySignatoryStep;
        return (
          secondaryStep?.isThereSecondary === true ||
          secondaryStep?.isThereSecondary === "true"
        );
      },
      thereIsNoSecondary:(context)=>{
        const secondaryStep = context?.IsThereSecondarySignatoryStep;
        return (
          secondaryStep?.isThereSecondary === false ||
          secondaryStep?.isThereSecondary === "false"
        );
      },
      nomineeQuestion: (context) => {
        const confirmStep = context?.ReviewAndConfirmStep;
        return confirmStep?.confirm === false;
      },
      useNominee: (context) => {
        const nomineeStep = context?.ClaraNomineeStep;
        return (
          nomineeStep?.useNominee === true || nomineeStep?.useNominee === "true"
        );
      },
      dontUseNominee: (context) => {
        const nomineeStep = context?.ClaraNomineeStep;
        return (
          nomineeStep?.useNominee === false ||
          nomineeStep?.useNominee === "false"
        );
      },
      thirdParty: (context) => {
        const stakeholder =
          context?.AssignSignatoriesStep?.authorisedSignatory ||
          context?.AssignSignatoryStep?.authorisedSignatory;
        return stakeholder?.fullName === "ADD_THIRD_PARTY";
      },
      notThirdParty: (context) => {
        const stakeholder =
          context?.AssignSignatoriesStep?.authorisedSignatory ||
          context?.AssignSignatoryStep?.authorisedSignatory;
        return stakeholder?.fullName !== "ADD_THIRD_PARTY";
      },
    };
};

export default useGuards;

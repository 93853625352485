
export default {
  STAKEHOLDERS: "Stakeholders ({{count}})",
  FILTERS: "Filters ({{count}})",
  GROUP_COMPANIES: "Group Companies ({{count}})",
  ZOOM: "Zoom",
  ADD_COMPANY: "Add Company",
  ADD_STAKEHOLDER: "Add Stakeholder",
  ADD_FILTER: "Add Filter",
  SEARCH: "Search",
  ORDER_A_Z: "From A to Z",
  ORDER_Z_A: "From Z to A",
  ORDER_NEWEST: "By newest added",
  ORDER_OLDEST: "By oldest added",
  ORDER_ROL: "By roles order",
  BREADCRUMS_MAPS: "Maps",
  SUBMIT_APPLICATION: "Submit application",
  ROLES_LABEL: {
    FOUNDER: "Founder",
    ADVISOR: "Advisor",
    EMPLOYEE: "Employee",
    CONSULTANT: "Consultant",
    INVESTOR: "Investor",
    OPTIONHOLDER: "Option Holder",
    CONVERTIBLEHOLDER: "Convertible Holder",
    WARRANTHOLDER: "Warrant Holder",
    SHAREHOLDER: "Shareholder",
    IPASSIGNOR: "IP Assignor",
    THIRDPARTY: "Third Parties",
    DIRECTOR: "Director",
    AUTHORIZEDSIGNATORY: "Authorised Signatory",
    UBO: "Ultimate Beneficial Owners (UBO)",
    NO_ROLE: "NO ROLE"
  },
  ROLES_LABEL_PLURAL: {
    FOUNDER: "Founders",
    ADVISOR: "Advisors",
    EMPLOYEE: "Employees",
    CONSULTANT: "Consultants",
    INVESTOR: "Investors",
    OPTIONHOLDER: "Option Holders",
    CONVERTIBLEHOLDER: "Convertible Holders",
    WARRANTHOLDER: "Warrant Holders",
    SHAREHOLDER: "Shareholders",
    IPASSIGNOR: "IP Assignors",
    THIRDPARTY: "Third Parties",
    DIRECTOR: "Directors",
    AUTHORIZEDSIGNATORY: "Authorised Signatories",
    UBO: "Ultimate Beneficial Owners (UBO)",
  },
  CARD_GROUPCOMPANY_VIEWMORE: "'Go to this company’ profile to view, edit, add details and more",
  CARD_STAKEHOLDER_VIEWMORE: "'Go to this stakeholder’ profile to view, edit, add details and more.'",
  CARD_COMPANY_TASK_MORE_TWO: "There are {{tasks}} tasks to complete. Go to this company’s profile to view, edit and add details.",
  CARD_COMPANY_TASK_ONE: "There is 1 task to complete. Go to this company’s profile to view, edit and add details.",
  CARD_COMPANY_TASK_NONE: "Go to this company’s profile to view, edit and add details.",
  CARD_STAKEHOLDER_TASK_MORE_TWO: "There are {{tasks}} tasks to complete for this stakeholder. Go to their profile to view, edit and add details.",
  CARD_STAKEHOLDER_TASK_ONE: "There is 1 task to complete for this stakeholder. Go to their profile to view, edit and add details.",
  CARD_STAKEHOLDER_TASK_NONE: "Go to this stakeholder’s profile to view, edit and add details.",
  LABEL_ROLES: "Filters",
  LABEL_STARTED: "Started/ Created from",
  LABEL_NAME: "Add name",
  FILTER_LABEL_TO: "to",
  LABEL_ANOTHER_YES: "Yes, add another one",
  LABEL_ANOTHER_NO: "No, not right now",

  //-----------Company Detail---------------
  WIZARD_COMPANY_DETAIL_FORM_TITLE: "Add the company info",
  WIZARD_COMPANY_DETAIL_FORM_SUBTITLE: "Company's details",
  //---------Fields Label-------------------
  WIZARD_COMPANY_DETAIL_FORM_FIELD_COUNTRY: 'Registered address',
  WIZARD_COMPANY_DETAIL_FORM_FIELD_STREET: "Street",
  WIZARD_COMPANY_DETAIL_FORM_FIELD_CITY: "City",
  WIZARD_COMPANY_DETAIL_FORM_FIELD_STATE: "State / Region",
  WIZARD_COMPANY_DETAIL_FORM_FIELD_ZIPCODE: "Zip Code / Post Code",
  //-----------Text Assistance-----------------------

  WIZARD_COMPANY_DETAIL_INIT_TEXT_ASSISTANCE: 'Adding this information now will save you time later.',
  //-----Step2------
  WIZARD_COMPANY_DETAIL_MORE_INFO_FORM_TITLE: "Now for a few more details...",
  WIZARD_COMPANY_DETAIL_MORE_INFO_FORM_FIELD_NUMBER: 'Company number',
  WIZARD_COMPANY_DETAIL_MORE_INFO_FORM_FIELD_TYPE: "Company type",
  WIZARD_COMPANY_DETAIL_MORE_INFO_FORM_FIELD_LINKED_IN_URL: "LinkedIn URL",
  //--------------- Company Detail End---------------

  //----------Address and Currencies -----
  WIZARD_COMPANY_ADDRESS_CURRENCIES_FORM_FIELD_TRANSACTION_CURRENCY: "Transaction currency",
  WIZARD_COMPANY_ADDRESS_CURRENCIES_FORM_FIELD_TRANSACTION_EXTRA_CURRENCY: "Additional currency",
  WIZARD_COMPANY_ADDRESS_CURRENCIES_FORM_TITLE: "Add currency",
  WIZARD_COMPANY_CURRENCIES_FORM_TITLE: "Add currency",
  WIZARD_COMPANY_ADDRESS_FORM_TITLE: "Add address",


  //----------Address and Currencies end -----


  //----------Notices -----
  WIZARD_COMPANY_NOTICES_STEP_1_FORM_TITLE:'Add notices',
  WIZARD_COMPANY_NOTICES_STEP_2_FORM_TITLE:'Add an address for notices',
  WIZARD_COMPANY_NOTICES_FORM_SUBTITLE: "Company's notices",

  WIZARD_COMPANY_NOTICES_FORM_FIELD_FULLNAME: "Full legal name",
  WIZARD_COMPANY_NOTICES_FORM_FIELD_EMAIL: "Email",


  //---------Notices end -----
  WIZARD_FILTER_FORM_TITLE_FIRST: "What do you want to display?",
  WIZARD_FILTER_FORM_TITLE_SECOND: "Do you want to add another filter?",
  WIZARD_FILTER_FORM_SUBTITLE: "{{#name}}Edit {{name}}{{/name}}{{^name}}Add your custom filter{{/name}}",
  WIZARD_FILTER_ANOTHER_TITLE: "Do you want to add another filter?",
  WIZARD_FILTER_ANOTHER_SUBTITLE: "Add your custom filter",
  WIZARD_CANCEL_BUTTON: "Cancel",
  WIZARD_ADD_BUTTON: "Add",
  WIZARD_SAVE_BUTTON: "Save",
  WIZARD_NEXT_BUTTON: "Next",
  WIZARD_CONTINUE_BUTTON: "Continue",
  MODAL_DELETE_TITLE_FILTER: 'Delete {{name}}?',
  MODAL_DELETE_CONTENT_FILTER: 'This custom filter will be permanently deleted for you and any other account users.',
  ERROR_FILTER_NAME_REQUIRED: "Name is required",
  ERROR_FILTER_ROLE_AT_LEAST_ONE: "Must select at least one",
  PLACEHOLDER_FILTER_NAME: "Directors with shares",
  CUSTOM_FILTER_NAME:"Custom Filter {{count}}",
  ERROR_FILTER_ANOTHER_REQUIRED: "Required",
  WIZARD_FILTER_TOOLTIP: "Choose what you want this custom filter to display. Select roles, equity and document ownership, and date to create a custom view of your map.",
  WIZARD_FILTER_TOOLTIP_1:  "Create new custom filters whenever you need.",


  //--------- Timeline ----
  TIMELINE_CLARA_HISTORY: "Clara History",
  TIMELINE_CORPORATE_HISTORY: "Corporate History",
  TIMELINE_DATE_FILTER_FROM: "From",
  TIMELINE_DATE_FILTER_TO: "To",
  TIMELINE_EVENTCONTENT_PAUSE: "Pause and open full list",
  TIMELINE_EVENTCONTENT_VIEW_MORE: "View more",
  TIMELINE_EVENTCONTENT_SHOW_LESS: "Show less",
  TIMELINE_DATE_FILTER_PLACEHOLDER: "Select...",

  
  //---------Events ----
  EVENT_TYPE1: "custom event type 1 {{name}}",
  EVENT_DISSOLUTION_GROUPCOMPANY: "Company {{name}} dissolution",
  EVENT_INCORPORATION_GROUPCOMPANY: "Company {{name}} incorporation",
  EVENT_START_STAKEHOLDER: "Stakeholder {{fullName}} start",
  EVENT_END_STAKEHOLDER: "Stakeholder {{fullName}} end",

  //--------- Timeline end----
MODAL_REMOVE_ESSENTIAL:{
  TITLE: "Please confirm your startup doesn’t have any {{role}}s",
  SUBTITLE: "Don’t worry you can add {{role}}s as your startup grows by using the filters in the centre of the map.",
  CONTENT: "Are you sure you want to remove this essential? This action cannot be undone.",
  CONFIRM:'Confirm',
  CANCEL:'Cancel'
},

MODAL_MARK_TASK_CAPTABLE:{
  TITLE: "Please confirm that this company does not have any {{equity}}.",
  SUBTITLE: "This task will be marked as completed. If you would like to add {{equity}} in the future, you will be able to do so from the Equity page.",
  CONFIRM:'Confirm',
  CANCEL:'Cancel'
}

};

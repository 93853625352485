const GOALS = {
  
  FORMATION: "GOAL_FORMATION_ADGM_BUY_REFER",
  GOAL_GENERATE_DOCUMENT: "GOAL_GENERATE_DOCUMENT",
  
  BUILD_CAP_TABLE: "GOAL_GET_READY_FOR_INVESTMENT",
  ACCELERATOR: "GOAL_ACCELERATOR",

}
export const GOAL_INITIAL_OPTION = {
  [GOALS.FORMATION]:"FORM_A_COMPANY",
  [GOALS.GOAL_GENERATE_DOCUMENT]:"GENERATE_DOCUMENTS",
  [GOALS.BUILD_CAP_TABLE]:"BUILD_YOUR_CAP_TABLE",
  [GOALS.ACCELERATOR]:"PREPARE_YOUR_ACCELERATOR"
}
 
export default GOALS
import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText,addAssistantText } from 'src/modules/generic/store/action';
import Typography from "src/modules/generic/components/Texts/Typography";
import ListItem from 'src/modules/generic/components/Lists/ListItem';
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import classesModule from "../classes.module.scss";
import { Box, Stack } from '@mui/material';
import Scrollbars from "src/components/generic/Scrollbar/Scrollbar";
import classes from './FormCaymanAgree.module.scss';

function FormCaymanAgree() {
  const { next, context, prev,cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  
  const initialValues = useMemo(() => {
    return context?.FormCaymanAgree ?? {};
  }, [context]);
  
  const handleCancel = () => {
    cancel();
  }

  const handlePrev = () => {
    prev();
  }

  const handleSubmit = async (values) => {
    next(values);
  }
  
  useEffect( () => {
    dispatch(
      addAssistantText(translate('MODULES.ONBOARDINGCOMPANY.TOOLTIP_ACCEPT_TERMS_CAYMAN_2'))
    );
    return () => {
      dispatch(clearAssistatText());
    }
  },[dispatch]);
  
  const schema = Yup.object().shape({
    accept: Yup.boolean().required("Required"),
  });

  const options= [
    {
      value: false,
      label: "No, I don’t agree to the above requirements",
      componentProps:{
        variant: "h4"
      }
    },
    {
      value: true,
      label: "Yes, I agree to the above requirements",
      componentProps:{
        variant: "h4"
      }
    },
  ];

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={ { mode: 'onChange' } }
    >
      <TemplateWizard
        props={{
          title: 'Form in the Cayman Islands',
          subTitle: "Let's form",
          skeleton: null,
          variant: 'simple'
        }}
        buttons={{
          previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined} >{translate('BUTTON_NEXT')}</SubmitButton>,
        }}
      >
        <>
          <Box sx={{ width: '100%', height: '400px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <Scrollbars className={`${classes.scrollSpace}`}>
                <Box mb={2}>
                  <Typography component='h3'>
                    You can form an Exempted Company using the Clara platform. To do so, you will first need to agree to the following requirements:
                  </Typography>
                </Box>
                <Stack
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1.5}
                  sx={{
                    width: '100%',
                  }}
                >
                  <ListItem
                    color='black'
                    item='The Exempted Company will perform its operations outside of the Cayman Islands.'
                    customClass={false}
                    typographyProps={
                      { component: "body" }
                    }
                    noBottomMargin={true}
                  />
                  <ListItem
                    color='black'
                    item='The Exempted Company will not conduct any business involved with the issuance or receipt of cryptocurrencies, gambling, and/or sale of distribution of illegal narcotics.'
                    customClass={false}
                    typographyProps={
                      { component: "body" }
                    }
                    noBottomMargin={true}
                  />
                  <ListItem
                    color='black'
                    customClass={false}
                    typographyProps={
                      { component: "body" }
                    }
                    item='None of the stakeholders have ever been charged with or convicted of any criminal offence involving dishonesty, fraud, corruption, money laundering, or terrorism or the financing of terrorism or proliferation.'
                    noBottomMargin={true}
                  />
                  <ListItem
                    color='black'
                    customClass={false}
                    typographyProps={
                      { component: "body" }
                    }
                    item='None of the shareholders or directors are acting under the direction or instruction of any other persons.'
                    noBottomMargin={true}
                  />
                  <ListItem
                    color='black'
                    customClass={false}
                    typographyProps={
                      { component: "body" }
                    }
                    item='None of the stakeholders are citizens of any country that are subject to the Office of Financial Sanctions Implementation (UK) and the Office of Foreign Assets Control (US) sanction lists, nor they are the subject of any such sanctions.'
                    noBottomMargin={true}
                  />
                  <ListItem
                    color='black'
                    customClass={false}
                    typographyProps={
                      { component: "body" }
                    }
                    item='The cost for incorporating an exempted company is USD 3,250.00. If there are more than four shareholders, there will be additional charges.'
                    noBottomMargin={true}
                  />
                  <ListItem
                    color='black'
                    customClass={false}
                    typographyProps={
                      { component: "body" }
                    }
                    item='The company and registered office will renew on the 31st of December each year regardless of when the company is incorporated.'
                    noBottomMargin={true}
                  />
                </Stack>
              </Scrollbars>
              <Box sx={{ paddingTop: '16px' }}>
                <ControllerInput
                  render={CheckboxButtonSimple}
                  className={classesModule.CheckboxButton}
                  classNameButton={classesModule.buttonCheckBoxSize}
                  name="accept"
                  variant="col_1"
                  size={"size2"}
                  options={options}
                  onChange={() => {}}
                />
              </Box>
            </Box>
          </Box>
        </>
      </TemplateWizard>
    </Form>
  );
}

export default FormCaymanAgree;


import useMutation from "../../../../hooks/custom/useMutation";

export const UPSERT_DATA_PROTECTION_CONTACT = `
  mutation upsertFormationCompany(
    $startupId: ID!
    $groupCompanyId: ID
    $groupCompanyData: FormationCompanyInputType
  ) {
    upsertFormationCompany(
      startupId: $startupId
      groupCompanyId: $groupCompanyId
      groupCompanyData: $groupCompanyData
    ) {
      id
      formationData {
        dataProtectionContact {
          id
          fullName
        }
      }
    }
  }
`;

const useUpsertDataProtectionContact = () => {
  const mutation = useMutation(
    UPSERT_DATA_PROTECTION_CONTACT,
    { showSuccessNotification: true }
  );
  return mutation;
};

export default useUpsertDataProtectionContact;

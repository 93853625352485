export default {
  FIRST_STEP: {
    TOOLTIP_UPLOAD: 'This document will be safely stored in Documents, your Clara data room.   ',
    TITLE: 'Do they have a {{{type}}}?',
    SUBTITLE: '{{stakeholderType}}\'s documents',
    TOOLTIP_IP: '<b>IP </b>(intellectual property)<b> provisions </b>protect your IP assets and your company in the long term. <a rel="noopener noreferrer" target="_blank"  href="https://academy.clara.co/article/6dw8KXeZDJKzAQyN5hhI4Y">Find out how</a>.'
  },
  SECOND_STEP: {
    TOOLTIP: 'What’s in a name? In this case, include any parties, to help easily identify this document later.',
    TITLE: 'Now for a few more details...',
    SUBTITLE: "Add the {{{type}}}"
  },
  IP_STEP: {
    TOOLTIP: '<b>IP </b>(intellectual property)<b> provisions </b>protect your IP assets and your company in the long term. Find out how. ',
    TITLE: 'Select the company which they have the agreement with...',
    SUBTITLE: "IP Agreement documents"
  }
}
import useQuery from "src/hooks/custom/useQuery";
import useSession from "src/modules/session/hooks/useSession";

const GET_VESTING_TERMS=`
query getShareClasses($startupId: ID!, $groupCompanyId: ID!) {
    getShareClasses(startupId: $startupId, groupCompanyId: $groupCompanyId) {
        id
  name
}
}`;
const useGetVestingTerms = (groupCompanyId,config)=>{
    const {startupId} = useSession() 
    const {data,loading}=useQuery(GET_VESTING_TERMS,{startupId,groupCompanyId},config)
    return{data:data?.getShareClasses,loading}
}
export default useGetVestingTerms

import classnames from "classnames"
import _ from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import KeyboardEventHandler from 'react-keyboard-event-handler'
import useTextLang from "../../../hooks/custom/useTextLang"
import ErrorInput from '../../../modules/generic/components/Inputs/ErrorInput'
import useSession from '../../../modules/session/hooks/useSession'
import Icon from "./../../../modules/generic/components/Icon"
import classes from './FixedSelectInput.module.scss'


const FixedSelectInput = ({ search = true, clear = false,
    value, list, onChange, onBlour, disabled, type, placeholder, errorMessage, classNameError, showErrorIcon, error, fixedTitleContent, fixedFilterable, fixedTitle, callbakcs, name, fixedList, clearedValues, fixedNotSelectable, fixedListAtTop, canCreate }) => {
    const placeholderTranslate = useTextLang(placeholder);
    
    const [filter, setFilter] = useState(value);
    const [open, setOpen] = useState(false);
    const { session } = useSession();
    const user = useMemo(() => _.get(session, "user.fullname"), [JSON.stringify(_.get(session, "user.fullname"))]);
    const [modalStyle, setModalStyle] = useState({});
    const refInput = useRef()
    const [hasMouse, setHasMouse] = useState(false)
    const refModal = useRef()

    const getFilterList = (list) => {
        if (!list) {
            list = [];
        }
        if (!filter || filter === "" || filter == 'Assigned to Me' || filter == 'Show All') {
            return list;
        }
        let dataFilter = _.filter(list, (element) => {
            return element.label.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
        })
        return dataFilter
    };

    useEffect(() => {
        if (!filter) {
            setFilter(value);
        }


    }, [value])

    const handleFocus = () => {
        setOpen(true)
    }
    const handleChange = (e) => {
        if (canCreate) {
            setFilter(e.target.value)
            onChange(e.target.value)
        } setFilter(e.target.value);

    }
    const handleBlur = () => {
        if (!hasMouse) {
            setOpen(false)
        }
    }

    const handleMouseOverModal = () => {
        if (!hasMouse) {
            setHasMouse(true);
        }
    }
    const handleMouseOutModal = () => {
        if (hasMouse) {
            setHasMouse(false);
        }
    }

    const handleSelected = (element) => {
        onChange(element.id);
        setOpen(false);
        setFilter(element.label)
        if (_.has(callbakcs, "updateValues")) {
            const updateValues = _.get(callbakcs, 'updateValues');
            updateValues()
        }
    }

    const handleClearValue = () => {
        if (clearedValues) {
            setFilter(clearedValues.filter)
            onChange(clearedValues.value)
        } else {
            setFilter("")
            onChange(null)
        }
    }


    const renderList = (list) => {
        const listFilter = search ? getFilterList(list) : list;
        const fixedListFilter = search && fixedFilterable ? getFilterList(fixedList) : fixedList

        return (
            <React.Fragment >
                <React.Fragment>
                    <div className={classes.zoneList}>
                        {listFilter.length > 0 && fixedListAtTop ? listFilter.map((element, i) => {
                            return <div
                                className={classes.classic}
                                onClick={() => handleSelected(element)}
                                key={i}>
                                {element.component ? (element.component) : element.label}
                            </div>

                        }) : null}

                        {fixedListFilter.length > 0 ? (fixedNotSelectable ? <React.Fragment>
                            <hr className={classes.optionsSeparator} />
                            {fixedTitle ? <label className={classes.fixedTitle}>{fixedTitleContent}</label> : null}

                            {fixedListFilter.map((element, i) => {
                                const fixedClass = element.fixedClass ? classes.fixedBlueOption : classes.fixedNotBlue
                                return <div
                                    className={`${classes.classic} ${fixedClass} ${classes.notSelectableFixedOption}`}
                                    key={i}>
                                    {element.component ? (element.component) : element.label}
                                </div>

                            })}</React.Fragment> : <React.Fragment> {
                                fixedListFilter.map((element, i) => {
                                    const fixedClass = element.fixedClass ? classes.fixedBlueOption : null
                                    return <div
                                        className={`${classes.classic} ${fixedClass}`}
                                        onClick={() => handleSelected(element)}
                                        key={i}>
                                        {element.component ? (element.component) : element.label}
                                    </div>

                                })
                            }
                        </React.Fragment>) : null}

                        {listFilter.length > 0 && !fixedListAtTop ? listFilter.map((element, i) => {
                            return <div
                                className={classes.classic}
                                onClick={() => handleSelected(element)}
                                key={i}>
                                {element.component ? (element.component) : element.label}
                            </div>

                        }) : null}
                    </div>

                    <hr className={classes.Separator} />
                </React.Fragment>
            </React.Fragment>
        )

    }


    const showModal = open ? classes.openModal : "";
    return (
        <div className={classes.ClaraSelectContainer}>
            <span>
                <KeyboardEventHandler>
                    <input
                        className={classnames({
                            [classes.borderErrorInput]: errorMessage || error,
                        }, `${classes.claraSelectInput} ${filter ? classes.claraSelectSelected : ""}`)}
                        type={type}
                        disabled={disabled}
                        value={filter}
                        onChange={handleChange}
                        placeholder={placeholderTranslate}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        autoComplete={`autocomplete-inpit-${name}`}
                    />
                    {errorMessage && <ErrorInput message={errorMessage} classNameError={classNameError} showErrorIcon={showErrorIcon} component={error} />}

                    <div className={`${classes.claraSelectArrow} `} ></div>
                    {clear && value && !disabled &&

                        <div onClick={() => { handleClearValue() }} className={`${classes.claraButtonClearValue}`}>
                            <Icon icon={"x_symbol"} size={"0.75rem"} />
                        </div>
                    }

                    {open &&
                        <div className={`${classes.Modal} ${showModal}  scrollbar`}
                            onMouseOver={handleMouseOverModal}
                            onMouseOut={handleMouseOutModal}
                            ref={refModal}
                        >
                            {renderList(list)}
                        </div>
                    }
                </KeyboardEventHandler>
            </span>
        </div >
    )
}


export default FixedSelectInput;
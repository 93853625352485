import Core from "./Core";
import GroupCompany from "./GroupCompany";

class Rol extends Core {
  name: string;
  groupCompany: GroupCompany;
  constructor(data) {
    super(data);
    this.groupCompany = new GroupCompany(data?.groupCompany);
    this.name = data?.name;
  }
  getGroupCompany = () => {
    return this.groupCompany;
  }
  getName = () => {
    return this.name;
  }
  /** Question about type of director */
  isDirector = () => {
    return this.getName() === 'DIRECTOR';
  }
  isShareholder = () => {
    return this.getName()=== 'SHAREHOLDER';
  }

  isRolWithCompanyInManagerByClaraWitRolDirectorOrShareholder = () => {
    const groupCompany: GroupCompany = this.getGroupCompany() ;
    if (!groupCompany){
      throw new Error('groupCompany is null')
    }
    return groupCompany.isManagedByClara() && (this.isDirector() || this.isShareholder());
  }

}
export default Rol;
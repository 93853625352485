import { get } from "lodash";
import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "../../../../../generic/components/Buttons/Button";
import { StepProps } from "../../../../../generic/components/Wizard/Step";
import useCustomMachine from "../../../../../generic/hooks/useCustomMachine";
import {
  addAssistantText,
  clearAssistatText,
} from "../../../../../generic/store/action";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import useSession from "../../../../../session/hooks/useSession";
import Form from "../Forms/FormEmiratesIDExpiryDate";
import useAddDocumentFormation from "../../../../../../hooks/services/modules/individualStakeholder/useAddDocumentFormation";

const Step2: FC<StepProps> = () => {
  
  const { next, send, state } = useCustomMachine();
  const [updateDocument] = useAddDocumentFormation();
  const { id } = useParams<any>();
  const { startupId } = useSession();

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(
      addAssistantText(
        `Some regions required additional national identification.`
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, []);

  const handleCompleteSubmit = async (values) => {
    const variables = {
      stakeholderId: state?.context?.paramsData?.stakeholderId ?? id,
      startupId,
      identityDocumentData: { ...values, type: "EID_DOCUMENT" },
    };
    await updateDocument({ variables });
    next(values);
  };
  
  const handleCancel = async (values) => {
    send("CANCEL");
  };

  return (
    <React.Fragment>
      <Form
        initialValues={get(state, "context.data.uploadDocument", {})}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: "Add the ID expiry date",
          subTitle: "Emirates ID",
        }}
        buttons={{
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              Cancel
            </Button>
          ),
        }}
      ></Form>
    </React.Fragment>
  );
};

export default Step2;

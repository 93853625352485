import useMutation from "src/hooks/custom/useMutation";

export const CREATE_STAKEHOLDER = `
mutation createStakeholder(
  $startupId: ID!
  $stakeholderData: StakeholderInput!
) {
  createStakeholder(
    startupId: $startupId
    stakeholderData: $stakeholderData
  ) {
    id
    fullName
    isAnEntity
    roles {
      id
      name
      entity {
        ... on StakeHolder {
          id
          fullName
        }
        ... on GroupCompany {
          id
          name
        }
      }
    }
  }
}
`
const useCreateStakeholder = (config, props) => {
  const [createStakeholder] = useMutation(CREATE_STAKEHOLDER, config, { refetchQueries: ["companyCorporateMap"], ...props });
  return { createStakeholder };
};
export default useCreateStakeholder;
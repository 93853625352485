export default {
    ADD_COMPANY: {
        NAME: "CreateAGroupCompany",
        V2: {
            NAME: "CreateAGroupCompanyV2"
        }
    },
    ONBOARD_TEAM_MEMBER: {
        NAME: "OnboardTeamMember",
        V2: {
            FOUNDER: "CreateFounderV2",
            EMPLOYEE: "CreateEmployeeV2",
            ADVISOR: "CreateAdvisorV2",
            CONSULTANT: "CreateConsultantV2"
        }
    },
    CREATE_TEAM_MEMBER: {
        NAME: "CreateTeamMember"
    },
    CREATE_INVESTOR: {
        NAME: "CreateAnInvestor"
    },
    ONBOARD_AN_INVESTOR: {
        NAME: "CreateAnInvestor",
        V2: {
            INVESTOR: "CreateAnInvestorV2"
        }
    },
    ONBOARD_A_DIRECTOR: {
        V2: {
            NAME: "CreateDirectorV2"
        }
    },
    ONBOARD_EQUITY: {
        NAME: "CreateAnEquity",
    },
    CREATE_STARTUP: {
        NAME: "CreateAStartupProfile"
    },
    CREATE_STARTUP_GHOST: {
        NAME: "CreateAStartupWithoutGhost"
    },
    ONBOARD_COMPANY_EQUITY: {
        NAME: "GroupCompaniesEquities"
    },
    SCOPING: {
        NAME: "IncorporationMatter"
    },
    ADD_MATTER: {
        NAME: "AddNewMatter"
    },
    CONCIERNE_ENGAGE: {
        NAME:"ConciergeEngage"
    },
    ANSWER_THE_QUESTIONNAIRE:{
        NAME:"AnswerTheQuestionnaire"
    },
    ADGM_FORMATION_DOCUMENT_REVIEW:{
        NAME:"ADGMFormationDocumentReview"
    },
    ONBOARDING_PARTNERS_ENGAGE:{
        NAME:"PartnersEngage"
    },
    ADD_KYC_TO_MATTER:{
        NAME:"AddKYCToAMatter"
    }

};
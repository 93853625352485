import React from "react";
import ACTIONS from "../../../../constants/actions";
import PAGES from "../../../../constants/pages";
import layout from "../../../../layouts/BlueScreen/BlueScreenLayout";
import ClaraLayout from "../../../../layouts/Clara/ClaraLayout";
import AssistanceHealthCheckV4 from "../../../../modules/assistanceV4/components/AssistanceFrame2/assistanceHealthCheck";
import { LeftEducation } from "../../../../v1/components";
import Text from "./../../../../components/text/Text/Text";
import AddHealthcheckView from "./AddHealthcheck/AddHealthcheckView";
import HealthcheckView from "./Healthcheck/HealthcheckView";

const moduleRouter = [
  {
    path: PAGES.addHealthCheckIssue.path,
    exact: true,
    component: <AddHealthcheckView />,
    layout: layout,
    propsLayout: {
      title: <Text uuid={"FORM_HEALCHECK_ADD_TITLE"} />,
      leftPanelComponent: (
        <LeftEducation
          showDisclaimer={false}
          leftEducationId={"35gYIgEodj7YhgErhOcHgd"}
        />
      ),
    },
    action: ACTIONS.ADD_DUEDILIGENCE,
  },
  {
    path: PAGES.healthCheck.path,
    exact: true,

    breadCrumbs: {
      reset: true,
      add: false,
      label: <Text uuid={"BREADCRUMS_HEALTH_CHECK"} />,
    },


    component: <HealthcheckView />,
    layout: ClaraLayout,
    propsLayout: {
      cantBack: true,
      startupMenu: true,
      userMenu: true,
      fullMenu: true,
      showAssistance: true,
      linkMenu: "LinkDueDiligence",
      showBreadCrumbs: false,
      assistance: AssistanceHealthCheckV4,
    },

  },
];
export default moduleRouter;

// import { Constants } from './../v1/utils/constants';
import _ from "lodash";

const pendingEntity = (store, context, key) => {
    const entities = _.get(store, "session.startup.pendingEntities", []);
    const entity = _.find(entities, (entity) => entity.type === key);
    return _.get(entity, "remains", 0);
};

const pendingFounders = (store, context) => {
    return pendingEntity(store, context, "FOUNDER");
};
const pendingEmployees = (store, context) => {
    return pendingEntity(store, context, "EMPLOYEE");
};
const pendingAdvisors = (store, context) => {
    return pendingEntity(store, context, "ADVISOR");
};
const currentConsultats = (store, context) => {
    return currentEntities(store, context, "consultants");
};

const pendingTeamMembers = (store, context) => {
    return (
        pendingFounders(store, context) +
        pendingEmployees(store, context) +
        pendingAdvisors(store, context) +
        pendingConsultants(store, context)
    );
};

const pendingGroupCompanies = (store, context) => {
    return pendingEntity(store, context, "groupCompany");
};
const pendingEquities = (store, context) => {
    if (_.get(store, "session.startup.groupCompanies.length") <= 1) {
      return 0;
    }
    return pendingEntity(store, context, "GroupCompaniesEquities");
};
const pendingInvestors = (store, context) => {
    return pendingEntity(store, context, "INVESTOR");
};
const hasPendingOnboardV1 = (store, context) => {
    return (
        pendingTeamMembers(store, context) +
        pendingGroupCompanies(store, context) +
        pendingEquities(store, context) +
        pendingInvestors(store, context)
    );
};
const hasPendingOnboard = (store, context) => {
    return (
        pendingTeamMembers(store, context) +
        pendingGroupCompanies(store, context) +
        pendingInvestors(store, context)
    );
};

const pendingDirectors = (store, context) => {
    return pendingEntity(store, context, "DIRECTOR");
};
const pendingConsultants = (store, context) => {
    return pendingEntity(store, context, "CONSULTANT");
};

const currentEntities = (store, context, key) => {
    return _.get(store, `session.startup.statistic.${key}`, 0);
};
const currentCompanies = (store, context) => {
    return currentEntities(store, context, "companies");
};
const currentFounders = (store, context) => {
    return currentEntities(store, context, "founders");
};
const currentEmployees = (store, context) => {
    return currentEntities(store, context, "employees");
};
const currentAdvisors = (store, context) => {
    return currentEntities(store, context, "advisors");
};
const currentInvestors = (store, context) => {
    return currentEntities(store, context, "investors");
};

export default {
  hasPendingOnboard,
  pendingGroupCompanies,
  pendingEquities,
  pendingInvestors,
  pendingTeamMembers,
  pendingConsultants,
  pendingAdvisors,
  pendingEmployees,
  pendingFounders,
  pendingDirectors,
  currentCompanies,
  currentFounders,
  currentEmployees,
  currentAdvisors,
  currentConsultats,
  currentInvestors,
  hasPendingOnboardV1
};
import { Field } from "formik"
import get from 'lodash/get'
import React, { FC, useState } from "react"
import SelectShareClasses from "src/components/inputs/Select/SelectShareClass"
import SelectVestingTerms from "src/components/inputs/Select/SelectVestingTerms"
import TextInput from "src/v1/containers/Forms/Inputs/TextInput"
import FormikHelper from "src/v1/utils/formikHelper"
import ClaraRadioWithButton from "../../ClaraRadioWithButton/ClaraRadioWithButton"
import classes from "../styles.module.scss"
import ShareClassFields from "./ShareClassFields"
import VestingTermsFields from "./VestingTermsFields"
interface Props {
    tooltips:{[label:string]:any};
    entityLabel:string
    groupCompany:any;
}
    const  ShareForm:FC<Props> = ({tooltips,entityLabel, groupCompany}) =>{
        const [isAddShareClass,setIsAddShareClass] = useState(false)
        const [isAddVestingTerms,setIsAddVestingTerms] = useState(false)
        const [showVestingTerms,setShowVestingTerms] = useState(false)
        const handleOnChangeAreTheSharesNumbered= (v,form) => {
            if (!v) {
                FormikHelper.setValueInTheCorrectPosition(`${entityLabel}.share[0].numberedShares`, form, null);
                FormikHelper.setValueInTheCorrectPosition(`${entityLabel}.share[0].areTheSharesNumbered`, form, v);
            }
        }

         return (<>
           <Field name={`${entityLabel}.share[0].issuedDate`}>
     {({ field, form }) => (
         <div className={classes.ContainerRow}>
             <TextInput
                 required={true}
                 type="date"
                 label="Issue date  "
                 field={field}
                 form={form}
                 placeholder={`dd/mm/yyyy`}
                 />
         </div>
     )}
    </Field>
    <Field name={`${entityLabel}.share[0].shareClass.id`}>
     {({ field, form }) => (
    <SelectShareClasses
    value={field.value}
    groupCompanyId={groupCompany?.id}
    onChange={(value)=>{
     if(value === `ADD`){
         form.setFieldValue(`${entityLabel}.share[0].shareClass`,{id:value})
          return setIsAddShareClass(true)
     }
     form.setFieldValue(`${entityLabel}.share[0].shareClass`,{...value})
}}
    errorMessage={get(form.errors,`${entityLabel}.share[0].shareClass.id`) || get(form.errors,`${entityLabel}.share[0].shareClass`)}
    />
     )}
     </Field>
     { isAddShareClass &&
             <ShareClassFields entityLabel={entityLabel} groupCompanyName={groupCompany?.name}/>
             }
         <div className={classes.ContainerRow}>
    <Field name={`${entityLabel}.share[0].amountOfShares`}>
        {({ field, form }) => (
            <TextInput
                required={true}
                type="number"
                label="Number of shares "
                field={field}
                form={form}
                placeholder={`e.g. 100.000`}
            />
        )}
    </Field>
    </div>
    <div className={classes.ContainerRow}>
    <Field name={`${entityLabel}.share[0].pricePerShare`}>
        {({ field, form }) => (
            <TextInput
                required={true}
                type="share"
                label="Price paid per share"
                field={field}
                form={form}
                leftLabel={groupCompany?.transactionCurrency || `USD`}
                placeholder={`e.g. 0.01`}
            />
        )}
    </Field>
    </div>
    <div className={classes.alignWithInputDate}>
                                <Field name={`${entityLabel}.share[0].areTheSharesNumbered`}>
                                    {({ field, form }) => (
                                        <ClaraRadioWithButton
                                            required={true}
                                            label={`Are the shares numbered?`}
                                            field={field}
                                            form={form}
                                            changeParams={field.name}
                                            options={[{ value: true, label: `Yes` }, { value: false, label: `No` }]}
                                            classes={{ root: classes.alignWithInputDate, input: classes.alignWithInputDateButton }}
                                            onChange={(v) => handleOnChangeAreTheSharesNumbered(v, form)}
                                        />
                                    )}
                                </Field></div>
                            <Field name={`${entityLabel}.share[0].numberedShares.startingShareNumber`}>
                                {({ field, form }) =>
                                    get(form, `values.${entityLabel}.share[0].areTheSharesNumbered`) ?
                                        (
                                            <div className={`${classes.ContainerRow}`}>
                                            <TextInput
                                                field={field}
                                                form={form}
                                                type={`number`}
                                                placeholder={`E.g. 1`}
                                                label={`Starting share number`}
                                                required={true}
                                            /></div>
                                        )
                                        : null}
                            </Field>
                            <Field name={`${entityLabel}.share[0].numberedShares.endingShareNumber`}>
                                {({ field, form }) =>
                                    get(form, `values.${entityLabel}.share[0].areTheSharesNumbered`) ?
                                        (
                                            <div className={`${classes.ContainerRow}`}>
                                            <TextInput
                                                field={field}
                                                form={form}
                                                type={`number`}
                                                placeholder={`E.g. 100,000`}
                                                label={`Ending share number`}
                                                required={true}
                                            /></div>
                                        )
                                        : null}
                            </Field>
                            <div className={classes.containerFounderName}>
            <h1>Vesting </h1>
            <p>The vesting of the shares granted to the founders.</p>
        </div>
     <Field name={`${entityLabel}.share[0].vestingTerms.id`}>
     {({ field, form }) => (
    <SelectVestingTerms
    value={field.value}
    groupCompanyId={groupCompany?.id}
    list={[{id:'NONE',name:'None'}]}
    onChange={(value)=>{
        if(value?.id === 'NONE') {
            setShowVestingTerms(false)
            form.setFieldValue(`${entityLabel}.share[0].vestingTerms`,value);
            return;
        };
     if(value === `ADD`){
         form.setFieldValue(`${entityLabel}.share[0].vestingTerms`,{id:value})
           setShowVestingTerms(true)
         setIsAddVestingTerms(true)
        return;
    }
     form.setFieldValue(`${entityLabel}.share[0].vestingTerms`,{...value?.standardTerm,isThereCliff:!!value?.standardTerm?.isThereCliff,id:value?.id,vestingStartDate:get(form,`values.${entityLabel}.share[0].vestingTerms.vestingStartDate`)})
     setShowVestingTerms(!!value)
    }}
    errorMessage={get(form.errors,`${entityLabel}.share[0].vestingTerms.id`) || get(form.errors,`${entityLabel}.share[0].vestingTerms`)}
    />
     )}
     </Field>
     { showVestingTerms &&
             <VestingTermsFields entityLabel={entityLabel} isDisabled={!isAddVestingTerms} tooltips={tooltips} />
             }
              <Field name={`${entityLabel}.share[0].vestingTerms.vestingStartDate`}>
        {({ field, form }) => 
        get(form, `values.${entityLabel}.share[0].vestingTerms.id`) !== 'NONE' ?(
            <div className={classes.ContainerRow}>
                <TextInput
                    required={true}
                    type="date"
                    label="Vesting start date  "
                    field={field}
                    form={form}
                    placeholder={`dd/mm/yyyy`}
                    />
            </div>
        ):null}
        </Field>
    </>)
    }
export default ShareForm
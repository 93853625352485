import React, {useEffect} from 'react';
import { useDispatch } from 'react-redux';
import useUpdatePassword from "./../../../../../../hooks/services/modules/user/useUpdatePassword";
import ModalAssistant from "../../../../../../modules/generic/components/Modal/ModalAssistant";
import TemplateWizard from "../../../../../../modules/generic/templates/Modal/TemplateWizard";
import useTranslate from "../../../../../../modules/generic/hooks/useTranslate";
import useModal from "../../../../../../modules/generic/hooks/useModal";
import { addAssistantText, clearAssistatText } from "../../../../../../modules/generic/store/action";
import ChangePasswordForm from "./ChangePasswordForm";
import Button from "../../../../../../modules/generic/components/Buttons/Button";

const ChangePasswordModal = ({ showModal, open, handleCloseModal, startupId }) => {
    const dispatch = useDispatch();
    const { translate } = useTranslate();
    const {closeModal} = useModal();

    const handleCloseForm = () => {
        closeModal();
    }

    const onCompletedUpdate = ()=>{
        handleCloseModal();
    }

    const [upsertPassword, loadingPassword] = useUpdatePassword({ onCompleted: onCompletedUpdate, successMessage:"MY_ACCOUNTNOTIFICATION_SUCCESS_UPDATE_PASSWORD" });

    const handleSubmit = async (values) => {
        await upsertPassword(values);
    }

    const handleInitModal = () => {
        dispatch(clearAssistatText());
        dispatch(addAssistantText(translate('MY_ACCOUNT_MODAL_UPDATEPASSWORD_NOTE')));
    }

    useEffect(() => {
        handleInitModal();
    }, []);

    return (
        <ModalAssistant open={showModal || open} handleClose={() => handleCloseModal()} showHeader={false}>
            <ChangePasswordForm
                initialValues={{}}
                buttons={{
                    cancel: <Button onClick={handleCloseForm} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
                }}
                handleSubmit={handleSubmit}
                propsTemplate={{
                    title: translate("MY_ACCOUNT_MODAL_UPDATEPASSWORD_TITLE"),
                }}
                Template={TemplateWizard}
            />
        </ModalAssistant>
    );
};

export default ChangePasswordModal;

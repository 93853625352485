import React, { useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import cls from "./class.module.scss";
import { useParams } from "react-router-dom";
import DocumentPreviewModalMF from "src/components/generic/Documents/DocumentPreview/DocumentPreviewModalMF";
import { getURL, getURLAssets } from "src/utils/urlHelper.js";
import _ from "lodash";
window.claraCDNHost = getURLAssets();
const MicroFrontend = ({ id, mf, component, params, retry = 3, loader }) => {
  const paramsUrl = useParams();
  const [openPreview, setOpenPreview] = useState(false);
  const [previewProps, setPreviewProps] = useState(null);
  //FIX: for CP 116163 https://clara-technologies.atlassian.net/browse/CP-11613
  // this makes it so that component is loaded once
  const [isLoaded,setIsLoaded] = useState()
  /**
   * togglePreview
   */
  const togglePreview = ({ open, propsPreview }) => {
    setPreviewProps(propsPreview);
    setOpenPreview(open);
  };

  const deactivePreview = () => {
    setOpenPreview(false);
  }

  const idElementMF = useMemo(() => {
    return `${id}_${uuidv4()}`;
  }, [id, JSON.stringify(params)]);

  useEffect(() => {
    return () => {
      try {
        if (window.microfrontend && window.microfrontend.removeComponentFederation && idElementMF) {
          window.microfrontend.removeComponentFederation(idElementMF);
          if (window.microfrontendUnMounted && window.microfrontendUnMounted[idElementMF]){
            window.microfrontendUnMounted[idElementMF].forEach((fn) => {
              fn()
            })
          }
        }
      } catch (e) {
        console.error(e)
      }
    };
  }, [idElementMF]);

  useEffect(() => {
    const loadComponent = (attemptsLeft) => {
      if (!window?.microfrontend?.addComponentFederation) {
        //Retry if no load the library
        setTimeout(() => {
          if(!isLoaded)
          loadComponent(attemptsLeft); // Number of attempts to retry  
        }, 1000);
        return;
      }
      try {
        if (!document.getElementById(`${idElementMF}`)) {
          throw new Error("The element does not exist")
        }
        const defaultLoader =( <div style={{
          position: 'relative',
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <div className={cls.Loader}>
            <div></div>
          </div>
        </div>)
        if (!window?.microfrontendUnMounted){
          window.microfrontendUnMounted = {}
        }
        if (!window.microfrontendUnMounted[idElementMF]){
          window.microfrontendUnMounted[idElementMF] = []
        }

        window.microfrontend.addComponentFederation(
          idElementMF,
          document.getElementById(`${idElementMF}`),
          window.env.mf[mf].url + "?v=" +_.get(window, "env.releaseVersion", new Date().getTime()),
          mf,
          component,
          {
            idElementMF,
            ...paramsUrl, ...params, loggerConfig: window.env.loggerConfig, togglePreview, urls: {
              api: getURL(), cdn: getURLAssets(),
            }
          },
          "The component is not available",
          loader || defaultLoader
        )
        setIsLoaded(true)
      } catch (error) {
        console.error(`**** Error loading component: ${error.message}`);
        if (attemptsLeft > 0) {
          setTimeout(() => {
            loadComponent(attemptsLeft - 1);
          }, 1000);
        }
      };
    };
    if(!isLoaded)loadComponent(retry); // Number of attempts to retry
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idElementMF, loader]);


  const documentData = useMemo(() => {
    return {
      file: {
        id: previewProps?.fileId,
        name: previewProps?.fileName
      }
    }
  }, [previewProps, openPreview]);

  return (
    <React.Fragment>
      {openPreview && (
        <>
          <DocumentPreviewModalMF
            showModal={openPreview}
            document={documentData}
            handleClose={previewProps?.onCancel}
            handleSend={previewProps?.onSubmit}
            deactivePreview={deactivePreview}
          />
        </>
      )}
      <>
        <div id={idElementMF} style={{ width: "100%", height: "100%" }} />
      </>
    </React.Fragment>
  );
};

export default React.memo(MicroFrontend);

import useCustomMachine from "../../../../../generic/hooks/useCustomMachine";
import React, { FC, useEffect } from "react";
import Button from "../../../../../generic/components/Buttons/Button";
import { StepProps } from "../../../../../generic/components/Wizard/Step";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import { useDispatch } from "react-redux";
import {
  addAssistantText,
  clearAssistatText,
} from "../../../../../generic/store/action";
import useUpsertFormationCompany from "../../../graphql/mutations/useUpsertFormationCompany";
import { useParams } from "react-router-dom";
import useSession from "../../../../../session/hooks/useSession";
import _ from "lodash";
import FormIsThereCompaniesFormationsShares from "../../../Forms/FormIsThereCompaniesFormationsShares";
import { stripTypenames } from "../../../../../../v1/utils/graphql-util";
import useRegisterAsCheckedTask from "../../../../../../modules/generic/graphql/mutation/useRegisterAsCheckedTask";

const Step1: FC<StepProps> = () => {

  const { translate } = useTranslate();
  const { id } = useParams();
  const { startupId } = useSession();
  const [mutation] = useUpsertFormationCompany({}, {});
  const { next, send, state, stepData, globalData } = useCustomMachine();
  const dispatch = useDispatch();

  const { mutation: mutationchecktask } = useRegisterAsCheckedTask({
    showSuccessNotification: false,
  });

  useEffect(() => {
    dispatch(clearAssistatText());
    dispatch(
      addAssistantText(
        translate(
          "MODULES.GROUP_COMPANY.ASSETS_AND_REVENUES.FIFTH_STEP.TOOLTIP_1"
        )
      )
    );
    dispatch(
      addAssistantText(
        translate(
          "MODULES.GROUP_COMPANY.ASSETS_AND_REVENUES.FIFTH_STEP.TOOLTIP_2"
        )
      )
    );
  }, []);

  const formatData = (values) => {
    const sharesInIncorporatedCompanies = [];
    _.forEach(state.context.globalValues, (data) => {
      if (data.create_company_1)
        sharesInIncorporatedCompanies.push(data.create_company_1);
      if (data.create_company_2)
        sharesInIncorporatedCompanies.push(data.create_company_2);
      if (data.create_company_3)
        sharesInIncorporatedCompanies.push(data.create_company_3);
      if (_.get(data, "select_companies.companies", null))
        sharesInIncorporatedCompanies.push([
          ...data.select_companies.companies,
        ]);
    });
    if (_.get(state.context, "data.select_companies.companies", null))
      sharesInIncorporatedCompanies.push(
        state.context.data.select_companies.companies
      );
    return {
      startupId,
      groupCompanyId: state?.context?.paramsData?.startup?.groupCompanies?.idGroupCompany ?? id,
      groupCompanyData: {
        formationData: {
          sharesInIncorporatedCompanies: _.remove(
            _.flatten(sharesInIncorporatedCompanies),
            (gc) => gc !== "ADD"
          ).map((gc) => {
            return {
              id: gc.id,
            };
          }),
          notYetFormedCompanyName: values.isThereCompaniesFormationsShares
            ? values.formationCompaniesHavesShares
            : null,
          hasShareInIncorporated: _.get(
            state.context,
            "data.is_there_shares_in_companies.isThereCompaniesShares"
          ),
          hasShareInCompanyFormed: values.isThereCompaniesFormationsShares,
        },
      },
    };
  };

  const handleCompleteSubmit = async (values) => {
    await mutation(stripTypenames(formatData(values)));
    next(values);
  };

  const handleCancel = async (values) => {
    send("CANCEL");
  };

  const handlePrevious = () => send("PREVIOUS");

  return (
    <>
      <FormIsThereCompaniesFormationsShares
        initialValues={stepData}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: translate(
            "MODULES.GROUP_COMPANY.ASSETS_AND_REVENUES.FIFTH_STEP.TITLE"
          ),
          subTitle: translate(
            "MODULES.GROUP_COMPANY.ASSETS_AND_REVENUES.FIFTH_STEP.SUBTITLE"
          ),
        }}
        buttons={{
          previous: (
            <Button onClick={handlePrevious} variant="secondary">
              Previous
            </Button>
          ),
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              Cancel
            </Button>
          ),
        }}
      ></FormIsThereCompaniesFormationsShares>
    </>
  );
};

export default Step1;

import {Field} from "formik";
import _ from "lodash";
import React, {Component} from 'react';
import {TextInput, ClaraRadioWithButton} from '../../../../../../../v1/components';
import ClaraSelect from "../../../../../../../v1/components/Form/ClaraSelect/ClaraSelect";
import ClaraUpload from "../../../../../../../v1/components/Form/ClaraUpload/ClaraUpload";
import Checkbox from "../../../../../../../v1/containers/Forms/Inputs/Checkbox";
import FormikHelper from "../../../../../../../v1/utils/formikHelper";
import Line from "../../../../../../generic/Line/Line";
import AuthorisedSignatories from "../../../../../../inputs/AuthorizedSignatories/AuthorizedSignatories";
import SelectStakeholders from "../../../../../../inputs/Select/SelectStakeholders";
import classes from "../Styles.module.scss";
import HiddenField from '../../../../../../../v1/components/Form/ClaraHiddenField/ClaraHiddenField';
import SelectVestingTerms from "../../../../../../inputs/Select/SelectVestingTerms";

class BoardApprovingOptionsGrantDoc extends Component {

	constructor(props) {
		super(props);
		this.state = {
			typeSelected: undefined,
			participantSelected: undefined,
			groupCompanySelected: undefined,
			authorizedSelected: undefined,
			authorizedSignatories: [],
			authorizedSignatoriesList: [],
			documentTypes: [],
			documentTypesList: [],
			remainingSipShares: 0,
			indexesAddGrantees:[],
			indexesGrantees:[],
			dynamicParams: [],
			date: undefined,
			responsibilities: [],
			question2: null,
			question1: null
		}
	};

	getDynamicParamsByType(documentType) {
		const type = this.props.documentTypes.filter(agrType => agrType.code === documentType)[0] || {};
		return type.fields || [];
	}

	componentWillMount() {
		this.setState({
			question1: this.props.initialValues.terms.A409Valuation.isItApproved,
			question2: this.props.initialValues.terms.A409Valuation.isItByBoardOrFirstApproved
		});


	}

	getDocs = () => {
		// const documents = this.props.documentList.filter(doc => {
		// return doc.kind === 'GENERATED' &&	doc?.params?.company?.id === this.state.groupCompanySelected.id
		// })
		return _.map(this.props.documentList, doc => ({
			id: doc.id,
			label: doc.name
		}))

	};

	handleOnChangeIsThereCliff = (v, form,index) => {
        if (!v) {
            FormikHelper.setValueInTheCorrectPosition(`grantees[${index}].vesting.cliffPeriod`, form, null);
            FormikHelper.setValueInTheCorrectPosition(`grantees[${index}].vesting.isThereCliff`, form, v);
        }
    };

	selectGroupCompany = (gcId, extraParams, form) => {
		const groupCompany = this.findGroupCompany(gcId);

		this.setState({
			groupCompanySelected: groupCompany,
		});

		if (groupCompany) {


			if (!groupCompany.registeredAddress) {
				groupCompany.registeredAddress = {}
			}
			let authorizedSignatories = _.get(form, 'values.company.authorizedSignatories', []);
			const newFormValues = {
				...form.values,
				terms: {
					...(form.values?.terms || {}),
					A409Valuation: form.values?.terms?.A409Valuation || {}
				},
				// shareIncentivePlan: {
				//   id: docId
				// },
				company: {
					id: groupCompany.id,
					name: groupCompany.name,
					authorizedSignatories: authorizedSignatories
				},
			}

			form.setValues(newFormValues);

			this.setState({
				groupCompanySelected: groupCompany,
			})
		} else {
			form.setValues({
				...form.values,
				company: {},
				// shareIncentivePlan: null,
			});
		}
	};

	findGroupCompany(gcId) {
		const gc = _.find(this.props.groupCompaniesList, {id: gcId});
		return gc ? {...gc} : null
	};

	findStakeholder(roleId) {
		return this.props.stakeholderList.find(stakeholder => stakeholder.id === roleId);
	};

	findStakeholderAuthorized(roleId) {
		return this.props.stakeholderList.filter(stakeholder => {
			return stakeholder.roles.some(role => role.stakeholder.id === roleId);
		})[0] || null;
	};

	filterAuthorizedByGroupCompany(groupCompany) {
		if (groupCompany) {
			let gc = this.findGroupCompany(groupCompany.id);
			return this.props.authorizedSignatories.filter(authorized => {
				return authorized.groupCompanyId === gc.id
			});
		}
		return [];
	}

	selectAuthorized = (value, form, field, replace) => {
		const stakeholder = this.props.authorizedSignatories.find(e => e.id === value);
		const email = stakeholder ? stakeholder.email : '';
		replace(0, {id: value, email})
	};

	fieldHasError(form, field) {
		return FormikHelper.returnErrorsForValidation(field.name, form.errors);
	}

	getAuthorizedSignatoriesList = (index, form) => {
		if (form.values && form.values.company) {
			return this.filterAuthorizedByGroupCompany(form.values.company).map(as => ({
				id: as.id,
				label: as.label
			}))
		} else {
			return [];
		}
	};

	findDoc(docId) {
		return this.state.groupCompanySelected.documents.find(doc => doc.id === docId)
	}

	selectDoc = (docId, extraParams, form) => {
		const document = this.findDoc(docId);

		if (document) {
			const newFormValues = {
				...form.values,
				terms: {
					...(form.values?.terms || {}),
					equityIncentivePlan: {
						id: docId
					},

				}
			}


			form.setValues(newFormValues);
		} else {
			form.setValues({
				...form.values,
				terms: {
					...(form.values?.terms || {}),
					equityIncentivePlan: {id: null},
				}
			});
		}
	}

	selectTypeOption = (value, form, field, index) => {
		if (!value) {
			form.setFieldValue(`grantees[${index}].grant.havesMoreThan10Percent`, null);
		}
	}

	getLabel = (key) => {
		switch (key) {
			case "fullName":
				if (this.state.participantSelected.isAnEntity) {
					return "Company name";
				} else {
					return "Name";
				}
			case "country":
				if (this.state.participantSelected.isAnEntity) {
					return "Country of incorporation";
				} else {
					return "Nationality";
				}
			case "address":
				if (this.state.participantSelected.isAnEntity) {
					return "Registered address";
				} else {
					return "Residential address";
				}
			case "passportOrRegistered":
				if (this.state.participantSelected.isAnEntity) {
					return "Company number";
				} else {
					return "Passport number";
				}
		}
	};

	getField = (key) => {
		switch (key) {
			case "passportOrRegistered":
				if (this.state.participantSelected.isAnEntity) {
					return "grantee.registeredNumber";
				} else {
					return "grantee.passportNumber";
				}
		}
	};

	getGroupCompanies = () => {
		const groupCompanies = this.props.groupCompanies
		const groupCompaniesList = this.props.groupCompaniesList.filter(gc => {
			return (gc?.documents || []).some(doc => doc.type.code === 'USA_EQUITY_INCENTIVE_PLAN');
		})
		return groupCompanies.filter(gc => groupCompaniesList.some(gcL => gcL.id === gc.id))
	};

	getTooltip = (key, fieldName) => {
		switch (key) {
			case "fullName":
				if (this.state.participantSelected.isAnEntity) {
					return "Participant's Company's Name";
				} else {
					return "Participant's Name";
				}
			case "passportOrRegistered":
				if (this.state.participantSelected.isAnEntity) {
					return "Company Number";
				} else {
					return "Passport Number";
				}
			case "country":
				if (this.state.participantSelected.isAnEntity) {
					return "Country";
				} else {
					return "Nationality";
				}
			case "addressLine":
				if (this.state.participantSelected.isAnEntity) {
					return "Registered Address - Address";
				} else {
					return "Residential Address - Address";
				}
			case "addressCity":
				if (this.state.participantSelected.isAnEntity) {
					return "Registered Address - City";
				} else {
					return "Residential Address - City";
				}
			case "addressState":
				if (this.state.participantSelected.isAnEntity) {
					return "Registered Address - State";
				} else {
					return "Residential Address - State";
				}
			case "addressCountry":
				if (this.state.participantSelected.isAnEntity) {
					return "Registered Address - Country";
				} else {
					return "Residential Address - Country";
				}
			case "addressPostCode":
				if (this.state.participantSelected.isAnEntity) {
					return "Registered Address - Post Code";
				} else {
					return "Residential Address - Post Code";
				}
			case "recipientNotice":
				if (fieldName === 'company') {
					return "Company's";
				} else {
					return "Participant's";
				}
		}
	};

	getPlaceholder = (key) => {
		switch (key) {
			case "passportOrRegistered":
				if (this.state.participantSelected.isAnEntity) {
					return "Company number";
				} else {
					return "Passport number";
				}
		}
	};

	getAuthorizedSignatoryAdds = () => {
		return [
			{
				type: "stakeholder",
				label: "Add new individual stakeholder",
				textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
				placeholder: "Enter stakeholder's name",
				startupId: this.props.startup.id,
				textToSave: "fullName",
				params: {
					isAnEntity: false,
					roles: [{
						role: "DIRECTOR",
						groupCompany: this.state.groupCompanySelected.id,
					}]
				}
			}
		]
	};

	renderVestingTermsFields(index,isDisabled){
        const { tooltips = {} } = this.props

        return <>
        <Field name={`grantees[${index}].vesting.vestingPeriod`}>
        {({ field, form }) => (
            <div className={`${classes.ContainerRow}`}>
                <TextInput
                    field={field}
                    form={form}
					disabled={isDisabled}
                    label={'Vesting period'}
                    required={true}
                    rightLabel={"Months"}
                    tooltip={tooltips["Vesting Period"]}
                /></div>
        )}
    </Field>
    <Field name={`grantees[${index}].vesting.vestingFrequency`}>
        {({ field, form }) => {
            return (
                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                    <ClaraSelect
                        label={'Vesting frequency '}
                        required={true}
                        field={field}
                        form={form}
						disabled={isDisabled}
                        mode={'classic'}
                        placeholder={'Select from the list'}
                        tooltip={tooltips['Vesting Frequency']}
                        lists={[
                            {
                                id: 'MONTHLY',
                                label: 'Monthly',
                            },
                            {
                                id: 'QUARTERLY',
                                label: 'Quarterly',
                            },
                            {
                                id: 'ANNUALLY',
                                label: 'Annually',
                            }
                                                                            ]}
                                            />
                                        </div>
                                    )
                                }}
                            </Field>
                            <div className={classes.alignWithInputGrantee}>
                                <Field name={`grantees[${index}].vesting.isThereCliff`}>
                                    {({ field, form }) => (
                                        <ClaraRadioWithButton
                                            required={true}
                                            label={'Is there a cliff period? '}
                                            field={field}
                                            form={form}
											disabled={isDisabled}
                                            tooltip={tooltips['Cliff Period']}
                                            changeParams={field.name}
                                            options={[{ value: true, label: 'Yes' }, { value: false, label: 'No' }]}
                                            classes={{ root: classes.alignWithInputGrantee, input: classes.alignWithInputButton }}
                                            onChange={(v) => this.handleOnChangeIsThereCliff(v, form,index)}
                                        />
                                    )}
                                </Field></div>
                            <Field name={`grantees[${index}].vesting.cliffPeriod`}>
                                {({ field, form }) =>
                                    _.get(form, `values.grantees[${index}].vesting.isThereCliff`) ?
                                        (
                                            <div className={`${classes.ContainerRow}`}>
                                            <TextInput
                                                field={field}
                                                form={form}
												disabled={isDisabled}
                                                label={'How long is the cliff period?'}
                                                required={true}
                                                rightLabel={"Months"}
                                            /></div>
                                        )
                                        : null}
                            </Field>
</>

    }

	renderValuation(tooltips = {}) {
		return (<>
				<Field name='terms.A409Valuation.perShare'>
					{({field, form}) => (

						<TextInput
							required={true}
							type='share'
							classNameInput={{root: classes.ContainerRow}}
							label='Valuation per share amount '
							field={field}
							form={form}
							rightLabel={'USD'}
							placeholder={'Valuation per share amount'}
							tooltip={tooltips['Valuation Per Share Amount']}
						/>
					)}
				</Field>
				<Field name='terms.A409Valuation.valuationFirmName'>
					{({field, form}) => (

						<TextInput
							required={true}
							classNameInput={{root: classes.ContainerRow}}
							type='text'
							label='Name of valuation firm '
							field={field}
							form={form}
							placeholder={'Name of valuation firm'}
						/>
					)}
				</Field>

				<Field name='terms.A409Valuation.date'>
					{({field, form}) => (
						<TextInput
							required={true}
							field={field}
							form={form}
							className={classes.inputDateEdit}
							tooltip={tooltips["Date Of Valuation"]}
							type={'date'}
							placeholder={'dd/mm/yyyy'}
							label={"Date of valuation"}
						/>
					)}
				</Field>
			</>
		)
	}

	renderNextQuestionPart1() {
		if (this.state.question1 === true)
			return (<>
				{this.renderValuation()}
			</>)
		if (this.state.question1 === false)
			return (
				<>
					<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
						<h1>409A Valuation</h1>
						<p>{!_.isNil(this.state.question2) && !this.state.question2?
						"You require a 409A valuation before you grant stock options. This can either be obtained from an external valuation firm or approved internally by your board. Will you appoint an external valuation firm (select 'No' if you will approve the valuation internally by your board instead)?.":
						`You require a 409A valuation before you grant stock options. This can either be obtained from
							an external valuation firm or approved internally by your board. Will you appoint an
							external valuation firm (select 'No' if you will approve the valuation internally by your
							board instead)? Once you have signed this board consent, please attach a copy of the 409A
							Valuation Report being approved to the end of the signed pdf (after the Exhibit page) and
						save a copy of this combined pdf.`}</p>
					</div>
					<Field name='terms.A409Valuation.isItByBoardOrFirstApproved'>
						{({field, form}) => (
							<div style={!field.value ? {marginBottom: '2rem'} : null}
								 className={classes.ContainerRow}>
								<ClaraRadioWithButton
									required={true}
									label={'Will you appoint an external valuation firm? '}
									field={field}
									form={form}
									// tooltip={tooltips['Cliff Period']}
									changeParams={field.name}
									options={[{value: true, label: 'Yes'}, {value: false, label: 'No'}]}
									onChange={(v) => this.handleA409isItByBoardOrFirstApproved(v, form)}
								/>
							</div>)}
					</Field>

{/*
					<Field name='terms.A409Valuation.isItByBoardOrFirstApproved'>
						{({field, form}) => (
							<CheckBox
								label={' '}
								values={field.value}
								errorMessage={this.fieldHasError(form, field)}
								onChange={(value) => {
									form.setFieldValue(field.name, value);
									form.validateField(field.name);
									this.setState({
										question2: value
									})
								}}
								isMultiple={false}
								options={[
									{
										value: 'yes',
										label: 'Yes',
									},
									{
										value: 'no',
										label: 'No',
									},
								]}
								className={{root: classes.CheckboxInline, checkbox: classes.CheckboxWizardInline}}
							/>
						)}
					</Field>
*/}
					{this.renderNextQuestionPart2()}
				</>
			)
		return null
	}

	renderNextQuestionPart2(tooltips = {}) {
		if (this.state.question2 === true)
			return (<>
				{this.renderValuation()}
			</>)
		if (this.state.question2 === false)
			return (<>
				<Field name='terms.A409Valuation.perShare'>
					{({field, form}) => (
						<TextInput
							required={true}
							classNameInput={{root: classes.ContainerRow}}
							type='share'
							label='Valuation per share amount '
							field={field}
							form={form}
							rightLabel={'USD'}
							placeholder={'e.g. 10, 20'}
							tooltip={tooltips['Commencement Date']}
						/>
					)}
				</Field>
			</>)
	}

	handleStakeholderCreate = (value, name, additionalValue, form) => {

		let {stakeholders, stakeholderList} = this.props;
		let newSh = {id: value.id, fullName: name, ...additionalValue};
		stakeholderList.push(newSh);

		stakeholders.push({id: value.id, label: name});
		// this.setState({ groupCompaniesList, groupCompanies });

	};

	handleGroupCompanyCreate = (value, name, additionalValue, form) => {

		let {groupCompanies, groupCompaniesList} = this.props;
		let newGc = {id: value.id, name, ...additionalValue};
		groupCompaniesList.push(newGc);

		groupCompanies.push({id: value.id, label: name});
		// this.setState({ groupCompaniesList, groupCompanies });

	};

	handleAuthorizedSignatoryUpdate = (value) => {

		const {authorizedSignatories, groupCompanySelected} = this.state;
		const groupCompany = this.findGroupCompany(groupCompanySelected.id);

		_.set(groupCompany, 'authorizedSignatories', _.map(groupCompany.authorizedSignatories, auth => ({...auth})))
		groupCompany.authorizedSignatories.push({stakeholder: value});
		authorizedSignatories.push(value);

	};

	handleAuthorizedSignatoryCreate = (value, name, additionalValue, form) => {

		let {authorizedSignatories} = this.props;
		const {groupCompanySelected} = this.state;

		authorizedSignatories.push({
			id: value.id,
			label: name,
			role: 'DIRECTOR',
			groupCompanyId: groupCompanySelected.id
		});

	};

	handleA409ItIsApproved = (value, form, field) => {
		// form.setFieldValue('terms.A409Valuation.isItByBoardOrFirstApproved', null)
		this.setState({question1: value});
	}

	handleA409isItByBoardOrFirstApproved = (value, form) => {
		this.setState({question2: value});
	}

	render() {
		const isRequired = message => value => (!!value ? undefined : message);
		const {tooltips = {}, formType} = this.props;

		return (
			<div>

				{formType === 'uploadToEdit' ? (<div>
					<Field name={`file`}>
						{({field, form}) => (
							<ClaraUpload
								required={true}
								name={`filepond`}
								label='Upload your document '
								field={field}
								form={form}
							/>
						)}
					</Field>
				</div>) : null}
				<Field name='agreementName'>
					{({field, form}) => (
						<TextInput
							required={true}
							type='text'
							label='Document name '
							classNameInput={{root: classes.ContainerRow}}
							field={field}
							form={form}
							placeholder={"Document name"}
							tooltip={tooltips ? tooltips['Document Name'] : {}}
						/>
					)}
				</Field>
				<Line className={classes.Line}/>
				<div className={`${classes.ContainerRow} ${classes.Select}`}>
					<Field name='company.id'>
						{({field, form}) => (
							<ClaraSelect
								required={true}

								field={field}
								form={form}

								label={"Select company "}
								lists={this.getGroupCompanies()}
								mode={"classic"}
								callbacks={{
									updateValues: this.selectGroupCompany
								}}
								placeholder={"Select from the list"}
								tooltip={tooltips['Select Company']}
							/>
						)}
					</Field>
				</div>
				{this.state.groupCompanySelected ? <>
					<Line className={classes.Line}/>
					<div className={`${classes.ContainerRow} ${classes.Select}`}>
						<Field name='terms.equityIncentivePlan.id'>
							{({field, form}) => (
								<ClaraSelect
									required={true}
									field={field}
									form={form}
									label={"Select equity incentive plan"}
									lists={this.getDocs()}
									callbacks={{
										updateValues: this.selectDoc
									}}
									mode={"classic"}
									placeholder={"Select from the list"}
									tooltip={tooltips ? tooltips['Select Equity Incentive Plan'] : ""}
								/>
							)}
						</Field>
					</div>

					<React.Fragment>
						<Line className={classes.Line}></Line>
						<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
							<h1>Directors</h1>
							<p>Please ensure all directors of your company are added.</p>
						</div>
						<Field name='company.authorizedSignatories_error'>
							{({field, form}) => (
								<React.Fragment>
                                        <span
											hasError={_.get(form.errors, "company.authorizedSignatories") ? "true" : "false"}>
                                            <AuthorisedSignatories
												selectInputLabel={'Select director'}
												list={this.props.stakeholderList}
												fieldArrayName={'company.authorizedSignatories'}
												idGroupCompany={this.state.groupCompanySelected.id}
												modal={true}
												handleAddListStakeholder={this.handleAuthorizedSignatoryUpdate}
												errorMessage={_.get(form.errors, "company.authorizedSignatories")}
											/>
                                        </span>
								</React.Fragment>
							)}
						</Field>
						<Line className={classes.Line}></Line>
					</React.Fragment>
					<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
						<h1>409A Valuation</h1>
						<p>Do you already have a board-approved 409A valuation from an external valuation firm?</p>
					</div>
					<Field name='terms.A409Valuation.isItApproved'>
						{({field, form}) => (
							<div style={!field.value ? {marginBottom: '2rem'} : null}
								 className={classes.ContainerRow}>
								<ClaraRadioWithButton
									required={true}
									label={'Is external valuation already done? '}
									field={field}
									form={form}
									// tooltip={tooltips['Cliff Period']}
									changeParams={field.name}
									options={[{value: true, label: 'Yes'}, {value: false, label: 'No'}]}
									onChange={(v) => this.handleA409ItIsApproved(v, form, field)}
								/>
							</div>)}
					</Field>

{/*
					<Field name='terms.A409Valuation.isItApproved'>
						{({field, form}) => (
							<CheckBox
								label={' '}
								values={field.value}
								errorMessage={this.fieldHasError(form, field)}
								onChange={(value) => {
									form.setFieldValue(field.name, value);
									form.validateField(field.name);
									this.setState({
										question1: value
									})
								}}
								isMultiple={false}
								options={[
									{
										value: 'yes',
										label: 'Yes',
									},
									{
										value: 'no',
										label: 'No',
									},
								]}
								className={{root: classes.CheckboxInline, checkbox: classes.CheckboxWizardInline}}
							/>
						)}
					</Field>
*/}
					{this.renderNextQuestionPart1()}
					<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
						<h1>Grantees</h1>
						<p>Select or add all the grantees for the share options and provide additional details about the
							options.</p>
					</div>
					<Field name='stakeholders_error'>
						{({field, form}) => (
							<span hasError={_.get(form.errors, "grantees") ? "true" : "false"}>
                                    <SelectStakeholders
										list={this.props.stakeholderList}
										fieldArrayName={'grantees'}
										addButtonText={'Add another grantee'}
										selectInputLabel={'Select grantee'}
										showCorporate={{title: true}}
										modal={true}
										tooltips={{corporate: {title: tooltips["Company's Authorized Signatory Title"]}}}
										idGroupCompany={this.state.groupCompanySelected.id}
										handleAddListStakeholder={this.handleAuthorizedSignatoryUpdate}
										errorMessage={_.get(form.errors, "grantees")}
									>
                            {(form.values?.grantees || []).map((grantee, index) => (
								<div key={index}>
									<Line className={classes.Line}></Line>
									<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
										<h1>Grant</h1>
										<p>The granting of the options to the grantee.</p>
									</div>
									<Field name={`grantees[${index}].grant.sharesNumber`}>
										{({field, form}) => (

											<TextInput
												required={true}
												type='number'
												label='Number of shares '
												field={field}
												classNameInput={{root: classes.ContainerRow}}
												form={form}
												placeholder={'e.g. 10, 20'}
												tooltip={tooltips['Number Of Shares']}
											/>
										)}
									</Field>
									<Field name={`grantees[${index}].grant.pricePerShare`}>
										{({field, form}) => (

											<TextInput
												required={true}
												type='share'
												label='Exercise price per share '
												field={field}
												form={form}
												classNameInput={{root: classes.ContainerRow}}
												placeholder={'Amount'}
												tooltip={tooltips['Price per Share']}
											/>
										)}
									</Field>
									<Field name={`grantees[${index}].grant.typeOption`}>
										{({field, form}) => {
											return (
												<div className={`${classes.ContainerRow} ${classes.Select}`}>
													<ClaraSelect
														label={'Type of option '}
														required={true}
														field={field}
														form={form}
														mode={'classic'}
														placeholder={'Select from the list'}
														tooltip={tooltips['Type Of Option']}
														lists={[
															{
																id: 'NSO',
																label: 'NSO',
															},
															{
																id: 'ISO',
																label: 'ISO',
															}
														]}
														callbacks={{
															updateValues: (e) => {
																this.selectTypeOption(e, form, field, index)
															}
														}}
													/>
												</div>
											)
										}}
									</Field>
									<HiddenField
										fieldName={`grantees[${index}].grant.havesMoreThan10Percent`}
										hiddenCondition={`values.grantees[${index}].grant.typeOption`}
										conditionValue={'ISO'}
										className={classes.ContainerRow}
									>
										<ClaraRadioWithButton
											required={true}
											label={'Does this grantee owns more than 10% of voting shares?'}
											options={[{value: true, label: 'Yes'}, {value: false, label: 'No'}]}
											tooltip={tooltips['Apply Interest Rate']}
										/>
									</HiddenField>
									<Line className={classes.Line}/>
									<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
										<h1>Vesting</h1>
										<p>The vesting of the shares granted to the grantees.</p>
									</div>
									<Field name={`grantees[${index}].vesting.id`}>
                                {({ field, form }) => (
                            <SelectVestingTerms
                            value={field.value}
                            groupCompanyId={this.state.groupCompanySelected.id}
                            onChange={(value)=>{
                                if(value === 'ADD'){ 
									form.setFieldValue(`grantees[${index}].vesting`,{id:value})
									this.state.indexesGrantees.push(index)
									this.state.indexesAddGrantees.push(index)
									return this.setState({
                                    indexesGrantees:this.state.indexesGrantees,
									indexesAddGrantees:this.state.indexesAddGrantees
                                })}
								form.setFieldValue(`grantees[${index}].vesting`,{...value?.standardTerm,id:value?.id,vestingStartDate:_.get(form?.values,`grantees[${index}].vesting.vestingStartDate`)})
								if(value){
									this.state.indexesGrantees.push(index)
									this.setState({
                                    indexesGrantees:this.state.indexesGrantees,
                                })
								return
							}
							const indexes = this.state.indexesGrantees.filter(i=>i!==index)
							const indexesAdd = this.state.indexesAddGrantees.filter(i=>i!==index)
							this.setState({
								indexesGrantees:indexes,
								indexesAddGrantees:indexesAdd
							})
                            }}
                            errorMessage={_.get(form.errors,`grantees[${index}].vesting.id`) || _.get(form.errors,`grantees[${index}].vesting`)}
                            />
                                )}
                                </Field>
                                { this.state.indexesGrantees.some(i=>i===index) &&
                                        this.renderVestingTermsFields(index,!this.state.indexesAddGrantees.some(i=>i===index))
                                        }
										<Field name={`grantees[${index}].vesting.vestingStartDate`}>
                                {({ field, form }) => (
                                    <div className={classes.ContainerRow}>
                                        <TextInput
                                            required={true}
                                            type='date'
                                            label='Vesting start date  '
                                            field={field}
                                            form={form}
                                            placeholder={'dd/mm/yyyy'}
                                            />
                                    </div>
                                )}
                            </Field>
								</div>))}
        </SelectStakeholders>
    </span>
						)}
					</Field>

					{formType === 'uploadToEdit' ? <div>
						<Field name='confirmSameData'>
							{({field, form}) => (
								<Checkbox
									className={classes.sameDataCheckbox}
									field={field}
									validate={isRequired('This field is required')}
									form={form}
									required={true}
									label='All document details are verified and correct.'
								/>
							)}
						</Field>
					</div> : null}
				</> : null}
			</div>
		);
	}
}

export default BoardApprovingOptionsGrantDoc;

import Machine from "../../../../../generic/components/Machine/MachineClass";

const isMoreEntity = ({ data }) => data.is_more_entity.another;

const notIsMoreEntity = ({ data }) => !data.is_more_entity.another;

export default class MachineEntity extends Machine {
  constructor(addedStates: any, stepsAdded, id: string = '', firstStep: string, nextFirstStep: string, previousLastStep: string, firstStepComponent, lastStepComponent, guards = {}, actions = {}, restarts?: boolean) {
    const machineDefinition = {
      initial: firstStep,
      id: id,
      states: {
        is_more_entity: {
          on: {
            NEXT: { target: 'adding_entity', actions: 'setContext' },
            CANCEL: 'finish_entity',
            PREVIOUS: previousLastStep
          }
        },
        adding_entity: {
          exit: 'setGlobal',
          on: {
            '': [
              { target: restarts ? firstStep : nextFirstStep, cond: 'isMoreEntity' },
              { target: 'finish_entity', cond: 'notIsMoreEntity' }
            ]
          }
        },
        finish_entity: {
          type: 'final',
          entry: 'final',
        },
        onDone: {
          actions: 'stopMachine'
        },
        ...addedStates,
      }
    }
    const steps = {
      exists_entity: firstStepComponent,
      is_more_entity: lastStepComponent,
      ...stepsAdded
    }
    const options = {
      guards: {
        isMoreEntity,
        notIsMoreEntity,
        ...guards,
      },
      actions: {
        ...actions
      }
    }
    super(machineDefinition, options, steps)
  }
}

import _ from 'lodash';
import { useMemo } from 'react';

const useGuards = () => {
  return useMemo(() => ({
    needContinue: (context) => {
      const actualCount = _.get(context, 'currentQuestionnarie');
      const totalCount = _.get(context, 'questionnarie').length;
      return actualCount < totalCount - 1;
    },
    isLast: (context) => {
      const actualCount = _.get(context, 'currentQuestionnarie');
      const totalCount = _.get(context, 'questionnarie').length;
      return actualCount === totalCount - 1;
    },
  }), []);
}

export default useGuards;
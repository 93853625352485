import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import TagManager from 'react-gtm-module';
import { Redirect } from "react-router-dom";
import useBusinessRules from "../../../modules/clara/hooks/useBusinessRules";
import { Content } from "../../containers";
import { Constants } from "./../../../v1/utils/constants";
import helper from "./../../../v1/utils/helper";
import logger from "./../../../v1/utils/logger";

function ManagerRoutePage({ children, version, props, rest }) {


    let userID = '';
    let firstName = '';
    let email = '';
    if (_.get(rest, "session.user")) {
        userID = rest.session.user._id;
        firstName = rest.session.user.firstName;
        email = rest.session.user.email;
    }
    const callTagManager = () => {
        const tagManagerArgs = {
            dataLayer: {
                event: "PageView",
                path: window.location.pathname,
                userid: userID,
                firstName: firstName,
                pageName: document.title,
                userType: helper.getTypeUserByEmail(email),
            },
            dataLayerName: "PageDataLayer",
        };
        TagManager.dataLayer(tagManagerArgs);
    }
    const log = () => {
        logger.info(Constants.LOGGER.ACTIONS.PAGE, { path: window.location.pathname })
    }
    useEffect(() => {
        callTagManager(rest.propsLayout)
        log()
    }, [rest.location])
    const businessRules = useBusinessRules()

    const propsLayout = useMemo(() => {
        if (rest?.propsLayout && typeof rest.propsLayout === 'function') {
            return rest.propsLayout(businessRules)
        }
        return rest?.propsLayout
    }, [rest, businessRules])

    return (
        <React.Fragment>
            <span>

                {version ? (
                    <React.Fragment>
                        {rest[version] ? (
                            <Content {...props} businessRules={businessRules} path={rest.path} layout={rest[version].layout} breadCrumbs={_.get(rest,`${version}.breadCrumbs`, rest.breadCrumbs)} propsLayout={rest[version].propsLayout} >
                                {rest[version].component}
                            </Content>
                        ):(
                            < Redirect to={{ pathname: Constants.PAGES["404"].path}} />
                        )}

                    </React.Fragment>
                ) : (
                    <Content {...props} businessRules={businessRules}  path={rest.path} layout={rest.layout} breadCrumbs={rest.breadCrumbs} propsLayout={propsLayout} >
                        {children}
                    </Content>
                )}


            </span>
        </React.Fragment>
    );
}


export default ManagerRoutePage

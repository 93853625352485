import React, {FC, useEffect, useMemo} from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import Button from '../../../../generic/components/Buttons/Button';
import { StepProps } from "../../../../generic/components/Wizard/Step";
import useTranslate from '../../../../generic/hooks/useTranslate';
import { addAssistantText, clearAssistatText } from "../../../../generic/store/action";
import TemplateWizard from '../../../../generic/templates/Modal/TemplateWizard';
import FormShareCapital from "../../Forms/FormShareCapital";

const SetShareCapitalValuesStep: FC<StepProps> = () => {
  const { translate } = useTranslate();
  const { next, prev, send, context } = useMachine();
  const dispatch = useDispatch();

  const isDefaultValues = context?.isDefaultValues;

  const sharesData = {
    currency: context?.currency,
    authorisedShares: isDefaultValues ? context?.defaultShares : context?.shares,
    shareNominalPrice: isDefaultValues ? context?.defaultParValue : context?.parValue,
    shareClass: context?.shareClass,
    isUpload: context?.isUpload
  };

  const { initialData } = context;
  const stakeholdersData = initialData?.stakeholders;
  const groupCompanyId = initialData?.groupCompanyId;
  const shareholdersInitialValues = initialData?.shareholdersInitialValues;

  const shareholders = useMemo(() => {
    return _.map(_.filter(stakeholdersData, (stakeholder) => _.some(stakeholder.roles, role => role.name === 'SHAREHOLDER' && role.entity.id === groupCompanyId)), (sh, index: number) => ({ index, ...sh }));
  }, [JSON.stringify(stakeholdersData)]);
 
  useEffect(() => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText(translate('MODULES.GROUP_COMPANY.SHARE_CAPITAL_ADGM_CAYMAN.SHARE_STEP.TOOLTIP')));
  }, []);

  const handleCompleteSubmit = async (values) => {
    next(values);
  }

  const handleCancel = async () => {
    send("CANCEL");
  }

  const handlePrev = () => {
    prev();
  }

  return (
    <>
      <FormShareCapital
        sharesData={sharesData}
        shareholders={shareholders}
        shareholdersInitialValues={shareholdersInitialValues}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: translate('MODULES.GROUP_COMPANY.SHARE_CAPITAL_ADGM_CAYMAN.SHARE_STEP.TITLE'),
          subTitle: translate('MODULES.GROUP_COMPANY.SHARE_CAPITAL_ADGM_CAYMAN.GENERIC.SUBTITLE')
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button>,
          previous: <Button onClick={handlePrev} variant="secondary">Previous</Button>
        }}
      />
    </>
  );
}

export default SetShareCapitalValuesStep;
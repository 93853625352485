import React, { useCallback } from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";
import useNavigation from "src/views/modules/startups/capTable/CapTable/useNavigationCaptable";

const EquityTab = () => {
  const params = useParams();
  const goTo = useNavigation();
  const stakeholderId = params.id;

  const go = useCallback(
    (v, params = {}) => {
      goTo(v, params);
    },
    [goTo]
  );

  return (
    <MicroFrontend
      id="MF_Stakeholder_Equity_Details"
      component={"./StakeholderEquity"}
      mf="capTable"
      params={{ go, stakeholderId }}
    />
  );
};

export default EquityTab;

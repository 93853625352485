import React, {createContext, useState, useCallback} from 'react';
import _ from 'lodash'
export const AssistanceTasksContext = createContext({});

export type AssistanceTasksContextType = {
  showContent: any,
  handleCardsStatus: (key, status) => void,
}

export const AssistanceTasksProvider = ({ children, keyValue }) => {

  const [statusCards, setStatusCards] = useState({});
  const [showContent, setShowContent] = useState(true);
  
  const handleCardsStatus = useCallback((key, status) => {
    let newStatusCards = {...statusCards};
    newStatusCards[key] = status;
    let result = _.find(newStatusCards, (status)=> status);
    setShowContent(!!result);
    setStatusCards(newStatusCards);
  },[setStatusCards, setShowContent]);

  return (
    <AssistanceTasksContext.Provider value={{ showContent, handleCardsStatus }}>
      {children}
    </AssistanceTasksContext.Provider>
  );
};

interface UseAssistanceTasksReturn {
  showContent: any,
  handleCardsStatus: (key, status) => void,
}

function useAssistanceTasks(): UseAssistanceTasksReturn {
  const {  showContent, handleCardsStatus } = React.useContext(AssistanceTasksContext) as AssistanceTasksContextType;
  return { showContent, handleCardsStatus }
}

export default useAssistanceTasks;
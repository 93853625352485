import { Paper } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ClassNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import Badge from '../../Badge';
import ErrorInput from '../ErrorInput';
import InputInterface from '../Input/InputInterface';
import cls from './Autocomplete.module.scss';
import useAutomaticEvent from './useAutomaticEvent';
import useFilterAutocompleted from './useFilterAutocompleted';
interface AutocompleteProps extends InputInterface {
  configFilter?: any,
  callbackCreated?: any
  options: any,
  getOptionLabel?: any,
  functionCompare?: any,
  placeholder?: string,
  getCreateValue?: any,
  classNameError?: string,
  showErrorIcon?: any,
  error?: any,
  noNewTagForThisInputs?: Array<string>
}

const defaultGetOptionLabel = (option) => {
  return option.label;
}

const defaultGetCreateValue = (value) => value;

const AutocompleteClara: React.FC<AutocompleteProps> = ({ className = "", value, placeholder = "", options = [], functionCompare, onChange,
  getCreateValue = defaultGetCreateValue, configFilter = {}, callbackCreated, getOptionLabel = defaultGetOptionLabel, errorMessage, classNameError,
  showErrorIcon, error, noNewTagForThisInputs = [],
  ...props }) => {

  const [inputValue, setInputValue] = React.useState(value?.fullName ?? '');
  const [selectedValue, setSelectedValue] = React.useState(value);
  // const query = useDebounce(inputValue, 200);
  const [open, setOpen] = React.useState(false)
  const { isValueExisting, filterOptions: filterOptionsFunction, isHadOptions } = useFilterAutocompleted({ configFilter, timeCheckValue: 0, inputValue, options, selectedValue, functionCompare });

  const isNewValue = React.useMemo(() => {
    let result = !selectedValue && (!isValueExisting && inputValue !== "");
    return result;
  }, [isValueExisting, inputValue]);

  const handleNewTag = (isNewValue) => {
    let result = !isNewValue
    if (noNewTagForThisInputs.length > 0) {
      const isInArrayValues = noNewTagForThisInputs.includes(inputValue);
      if (isInArrayValues) {
        result = true;
      }
    }
    return result;
  }


  useAutomaticEvent({
    inputValue, value, onChange,
    onChangeInputValue: setInputValue,
    functionCompare,
    options
  });

  const handleOnChange = (e, optionSelect, reason, details) => {
    if (optionSelect) {
      const { value, label } = optionSelect;
      onChange(value);
      setInputValue(label);
      setSelectedValue(value);
      setOpen(false);
    }
  };

  const handleInputOnChange = (e: any, value: string, reason) => {
    if (e) {
      setInputValue(value);
      setSelectedValue(null);
    }
  };

  const handleOnFocus = () => {
    setOpen(true);
  }

  const openMenuOptions = React.useMemo(() => {
    return open && isHadOptions;
  }, [open, isHadOptions]);

  const refMenu = React.useRef({ isMouseEnterMenu: false })

  const PaperMenu = React.useCallback(
    ({ children, className, ...props }) => {
      return <Menu innerRef={refMenu} className={className}>{children}</Menu>
    }, [])

  const handleOnBlur = () => {
    setOpen(false);
  }

  const handleValue = () => {

    isNewValue && onChange(getCreateValue(inputValue));
    if (isValueExisting && !refMenu.current.isMouseEnterMenu && inputValue !== "") {

      const valueFound = _.find(options, op => op.label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 || _.isEqual(op.value, selectedValue));
      (!_.isEqual(value, valueFound) && valueFound) && onChange(valueFound.value);
    }
  }

  React.useEffect(() => {
    handleValue();
  }, [open, inputValue]);

  React.useEffect(() => {
    if (!value) {
      setInputValue('');
    }
  }, [value])

  return (
    <Autocomplete
      // disablePortal
      onChange={handleOnChange}
      filterOptions={filterOptionsFunction}
      onInputChange={handleInputOnChange}
      inputValue={inputValue}
      open={openMenuOptions}
      onBlurCapture={handleOnBlur}
      onFocusCapture={handleOnFocus}
      clearOnBlur={false}
      noOptionsText={false}
      fullWidth={true}
      blurOnSelect={true}
      PaperComponent={PaperMenu}
      classes={{
        "paper": cls.MuiAutocompletePaper
      }}
      //filterSelectedOptions={true}
      options={options}
      getOptionLabel={option => option.label}
      renderInput={({ inputProps, InputProps, ...params }) => {
        const currentValue = inputValue || inputProps?.value
        return (
          <Badge className={`${cls.containerBadge}`} classes={{ badge: 'mr-2' }} invisible={handleNewTag(isNewValue)}
            vertical={'center'} horinzontal={'center'} originPosition={'in'}
            badgeContent={'New'} color="primary">
            <div ref={InputProps.ref} className={cls.containerInput} >
              <input  {...inputProps} value={currentValue} onBlur={handleOnBlur} onFocus={handleOnFocus} placeholder={placeholder} className={ClassNames(cls.inputText, { [cls['input-error']]: !!errorMessage })} />
              <ErrorInput message={errorMessage} classNameError={classNameError} showErrorIcon={showErrorIcon} component={error} />
            </div>
          </Badge>
        )
      }}
      {...props}
    />
  )
}

export default AutocompleteClara;


const Menu = ({ innerRef, children, className }) => {

  const handleMouseEnterMenu = React.useCallback(
    () => {
      innerRef.current.isMouseEnterMenu = true;
    }, [innerRef]);
  const handleMouseLeaveMenu = React.useCallback(
    () => {
      innerRef.current.isMouseEnterMenu = false;
    }, [innerRef])

  React.useEffect(() => {
    return () => {
      handleMouseLeaveMenu()
    }
  }, [handleMouseLeaveMenu])

  return <Paper onMouseEnter={handleMouseEnterMenu} onMouseLeave={handleMouseLeaveMenu} className={className} >{children}</Paper>
}

import React, {Component} from "react";
import Switch from "react-switch";
import FormikHelper from "../../../utils/formikHelper";
import classes from "./TextInput.module.scss";

class SwitchInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            checked: false
        };
    }

    handleChange = (e) => {
        this.setState(prevState => ({
            ...prevState,
            checked: !prevState.checked
        }), () => {
            const {form} = this.props;

            const name = this.props.field.name;

            FormikHelper.setValueInTheCorrectPosition(name, form, this.state.checked);
        });
    };

    render() {
        return(
            <div className={classes.TextInput}>
                <label>{this.props.label || ""}{this.props.required &&
                <span style={{color: 'red'}}>*</span>}</label>
                <Switch
                    onChange={this.handleChange}
                    checked={this.state.checked}
                    checkedIcon={
                        <span style={{
                            color: 'white',
                            top: '5px',
                            position: 'absolute',
                            left: '7px'
                        }}>
                            YES
                        </span>
                    }
                    uncheckedIcon={
                        <span style={{
                            color: 'white',
                            top: '4px',
                            position: 'absolute',
                            left: '4px'
                        }}>
                            NO
                        </span>
                    }
                    onColor={'#002AD0'}
                    offColor={'#FF0000'}
                    width={60}
                />
            </div>
            );
        }
}

export default SwitchInput;

import _ from "lodash";
import Mustache from 'mustache';
import React, { useEffect, useState,useMemo } from 'react';
import { useDispatch } from "react-redux";
import FixedSelectAgentInput from '../../../../../components/inputs/Clara/Incorporation/FixedSelectAgent/FixedSelectAgentInput';
import useSession from '../../../../../modules/session/hooks/useSession';
import { ClaraSearch, Loading, RoleAccess } from "../../../../../v1/components";
import useMatters from '../../../../Hooks/useMatters';
import ServerConnect from "../../../../utils/ServerConnect";
import Title from "./../../../../../components/text/Title/Title";
import classes from "./../../../../containers/Contents/SwitchStartup.module.scss";
import { Constants } from './../../../../utils/constants';
import MatterBlock from './matterBlock';


const Matters = (props) => {
    let { matters, loading, refetch } = useMatters();
    const dispatch = useDispatch();
    const { session} = useSession();
    const user = useMemo(() => _.get(session, "user.fullname"), [JSON.stringify(_.get(session, "user"))]);

    const [filteredData, setFilteredData] = useState([]);
    const [agents, setAgents] = useState([]);
    const [preFilter, setPreFilter] = useState('showAll')
    const [loadingAgent, setLoadingAgent] = useState(false)

    useEffect(() => {
        if (matters) {
            setFilteredData(matters)
        }
    },[JSON.stringify(matters)])

    useEffect(()=>{
        refetch()
    },[])

    useEffect(() => {
        if (agents.length < 1) {
            setLoadingAgent(true)
            const getAgents = () => {
                let agentsList = [{ label: 'Show All', id: 'showAll' }, { label: 'Assigned To Me', id: user }];
                const query = ` {
                                getAgents(matterId: "{{matterId}}", roles: [SERVICE_AGENT, SENIOR_SERVICE_AGENT, SERVICE_AGENT_MANAGER]){
                                _id
                                 fullName
                                email
                                    }
                                }`
                const getAgentsQuery = Mustache.render(query, { roles: ['SERVICE_AGENT', 'SENIOR_SERVICE_AGENT', 'SERVICE_AGENT_MANAGER'] })
                ServerConnect.graphQlQuery(getAgentsQuery)
                    .then(({getAgents}) => {
                        _.forEach(getAgents, agent => {
                            if (agent.fullName !== user) {
                                agentsList.push({id: agent.fullName, label: agent.fullName})
                                return agent
                            }
                        })
                        setAgents(agentsList)
                        setLoadingAgent(false)

                    }).catch((err) => {
                        console.log(err.message);
                        dispatch({type: 'GO_TO', page: Constants.PAGES.logout})
                })
            }
            getAgents();
        }

    }, [])

    const handleChangeOnPreFilter = (val) => {
        if (val) {
            setFilteredData(filterDataByAgent(val))
            setPreFilter(val)
        }

    }

    const filterDataByAgent = (val) => {
        let data = matters
        if (val == user) {
            let dataFilter = _.filter(data, (matter) => {
                return matter.createdBy.fullName == user
            })
            return dataFilter
        } else if (val !== user && val !== 'showAll') {

            let dataFilter = _.filter(data, (matter) => {
                return matter.createdBy.fullName == val
            })
            return dataFilter
        }
        return data
    }

    const renderAgentSelector = () => {
        return (
            <Loading show={loadingAgent} showChildren={false}>

                <div className={classes.selectorContainer}>
                    <FixedSelectAgentInput className={classes.selector} name={'agent'} onChange={(e) => {
                        setFilteredData(filterDataByAgent(e))
                        setPreFilter(e)
                    }}>

                    </FixedSelectAgentInput>
                </div>
            </Loading>
        )
    }

    const onChangeFilter = (filter) => {
        setFilteredData(filterData(filter))
    };

    const filterData = (filter) => {
        let data = matters
        if (filter && filter !== "") {
            let dataFilter = _.filter(data, (element) => {
                let tag = element.tags.join(' ')
                return element && (element.clientFullName.toLowerCase().indexOf(filter.toLowerCase()) >= 0 || tag.toLowerCase().indexOf(filter.toLowerCase()) >= 0 || element.sequenceNumber.toString().indexOf(filter.toString()) >= 0 || (_.get(element,'questionnaire.companyDetails.companyName',null)  && _.get(element,'questionnaire.companyDetails.companyName','').toLowerCase().indexOf(filter.toLowerCase()) >= 0))
            });
            return dataFilter
        }
        return data
    };

    const sizeOfFilteredData = (filteredData, type) => {

        if (_.size(_.filter(filteredData, cp => {
            return cp.state == type
        })) > 0) {
            return true
        } else {
            return false
        }
    }

    return (
        <React.Fragment>
            <Loading show={loadingAgent || loading} showChildren={false}>
                <div className={classes.containerMattersTable}>
                    <div className={classes.containerMatterTitleAndSearch}>
                        <div className={classes.matterSectionTitle}>
                            <Title >Matters</Title>
                        </div>
                        <div className={classes.containerMatterSearch}>
                            <ClaraSearch placeHolder={"Search..."} onChange={(onChangeFilter)} />
                        </div>
                        <div className={classes.addMatterAndSearch}>
                            <RoleAccess action={Constants.ACTIONS.MANAGE_MATTER} >
                                <div className={classes.addMatter} onClick={() => { dispatch({ type: 'GO_TO', page: Constants.PAGES.addMatter }) }}> Add New Matter</div>
                            </RoleAccess>
                            {renderAgentSelector()}
                        </div>

                    </div>
                    {_.isEmpty(matters) ? <p className={classes.missingMatters}>There are no matters assigned to you at this time</p> :
                        <div>
                            {filteredData ? (filteredData.length < 1 && preFilter !== "showAll"  ? <p className={classes.missingMatters}>There are no matters assigned to {preFilter}</p> : null) : null}
                            {filteredData ? (sizeOfFilteredData(filteredData, 'inReview') ? <h3 className={classes.matterStateTitle}>In Review</h3> : null) : <h3 className={classes.matterStateTitle}>In Review</h3>}
                            {(_.filter(filteredData, cp => {
                                return cp.state == 'inReview'
                            }).map((matter, index) => {
                                return (
                                    <MatterBlock matter={matter} index={index} />
                                )

                            }))}

                            {filteredData ? (sizeOfFilteredData(filteredData, 'inProgress') ? <h3 className={classes.matterStateTitle}>In Progress</h3> : null) : <h3 className={classes.matterStateTitle}>In Progress</h3>}
                            {(_.filter(filteredData, cp => {
                                return cp.state == 'inProgress'
                            }).map((matter, index) => {
                                return (
                                    <MatterBlock matter={matter} index={index} />
                                )
                            }))}


                            {filteredData ? (sizeOfFilteredData(filteredData, 'Closed') ? <h3 className={classes.matterStateTitle}>Closed</h3> : null) : <h3 className={classes.matterStateTitle}>Closed</h3>}
                            {(_.filter(filteredData, cp => {
                                return cp.state == 'Closed'
                            }).map((matter, index) => {
                                return (
                                    <MatterBlock matter={matter} index={index} />
                                )
                            }))}


                        </div>
                    }

                </div>
            </Loading>
        </React.Fragment>
    );
}

export default Matters;
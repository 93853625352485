import React, { FC, useContext, useEffect, useMemo, useRef, useState } from 'react'
import * as Yup from 'yup'
import _ from 'lodash'
import useTranslate from "../../../generic/hooks/useTranslate";
import Form from "../../../../components/forms/Form/FormL";
import ControllerInput from "../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import SubmitButton from "../../../generic/components/Buttons/SubmitButton";
import TemplateFormDefault from "../../../generic/templates/Modal";
import FormProps from "../../../generic/forms/interfaces/FormProps";
import { HiddenFieldForm } from "../../../../components/forms/contents";
import TemplateLabel from "../../../generic/components/Inputs/LabelInput";
import TextareaInput from "../../../../components/inputs/Textarea/TextareaInput";
import classes from './Form.module.scss'
import TextInput from '../../../../components/inputs/Text/TextInput'
import SelectInput from "../../../../components/inputs/Select/SelectInput/SelectInput";

interface FormSourceOfFundsProps extends FormProps {
  isEdit: boolean,
  hasChanged: () => { void }
}

const schema = Yup.object().shape({
  sourceOfFunds: Yup.string().nullable().required('Required'),
  sourceOfWealth: Yup.string().nullable().required('Required'),
  otherSourcesOfWealth: Yup.string().when(['sourceOfWealth'], {
    is: 'others',
    then: Yup.string().required('This field is required').nullable(),
    otherwise: Yup.string().nullable().optional()
  })
})

const SourceOfFounds: FC<FormSourceOfFundsProps> = ({ 
  initialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
  isEdit = false,
  hasChanged
}: FormSourceOfFundsProps) => {

  const { translate } = useTranslate();
  const refForm = useRef();
  const listPossibleSourcesOfWealth = useMemo<object[]>(() => ([
    { value: 'Wages', label: 'Wages' },
    { value: 'Business income', label: 'Business income' },
    { value: 'Investments', label: 'Investments' },
    { value: 'Asset sale', label: 'Asset sale' },
    { value: 'Inheritance', label: 'Inheritance'},
    { value: 'others', label: 'Others' }
  ]), []);

  useEffect(() => {
    if (refForm && initialValues && !_.isEmpty(initialValues)) {
      const sow = _.get(initialValues, 'sourceOfWealth');
      const isExist = _.find(listPossibleSourcesOfWealth, (item) => item.value === sow);
      if (!isExist && sow) {
        refForm.current.setValue('sourceOfWealth', "others");
        refForm.current.setValue('otherSourcesOfWealth', sow);
      }
    }
  }, [initialValues, refForm]);

  return (
    <>
      <Form
        initialValues={initialValues || {}}
        schema={schema}
        onlySendDirty={false}
        onSubmit={onCompleteSubmit}
        ref={refForm} optionsForm={{ mode: "onChange" }}
      >
        <Template
          buttons={{ ...buttons, submit: <SubmitButton {...buttonSubmitProps}>{isEdit ? 'Save changes' : 'Add'}</SubmitButton> }}
          props={propsTemplate}
        >
          <TemplateLabel variant={"top"} label={translate('MODULES.STAKEHOLDER.FORM.SOURCE_OF_FUNDS.FUNDS')} isRequired>
            <ControllerInput
              name={'sourceOfFunds'}
              render={TextareaInput}
              className={classes.TextArea}
              placeholder={translate('MODULES.STAKEHOLDER.FORM.SOURCE_OF_FUNDS.FUNDS_PLACEHOLDER')}
              defaultValue={''}
              onChange={hasChanged}
            />
          </TemplateLabel>
          <TemplateLabel label={translate('MODULES.STAKEHOLDER.FORM.SOURCE_OF_FUNDS.WEALTH')} isRequired>
            <ControllerInput
              name={'sourceOfWealth'}
              render={SelectInput}
              clear={true}
              placeholder={translate('MODULES.STAKEHOLDER.FORM.SOURCE_OF_FUNDS.WEALTH_PLACEHOLDER')}
              list={listPossibleSourcesOfWealth}
              onChange={hasChanged}
            />
          </TemplateLabel>
          <HiddenFieldForm fieldsWatch={'sourceOfWealth'} conditionHideCallback={value => value === 'others'}>
            <TemplateLabel label={'Other Sources Of Wealth'} isRequired>
              <ControllerInput
                name={'otherSourcesOfWealth'}
                render={TextInput}
                defaultValue={''}
              />
            </TemplateLabel>
          </HiddenFieldForm>
        </Template>
      </Form>
    </>
  )
}

export default SourceOfFounds

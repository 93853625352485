import React from "react";
import InvoiceTaskComponent from "./InvoiceTaskComponent";

const AssistanceInvoice = () => {
  return (
    <InvoiceTaskComponent />
  );
};

export default AssistanceInvoice;

import React from 'react';
import PAGES from "../../../../constants/pages";
import ClaraLayout from "../../../../layouts/Clara/ClaraLayout";
import AssistanceStakeholderCompanyV4 from "../../../../modules/assistanceV4/components/AssistanceFrame2/assistanceStakeholderCompany";
import AssistanceStakeholderIndividualV4 from "../../../../modules/assistanceV4/components/AssistanceFrame2/assistanceStakeholderIndividual2";
import AssistanceViewerDetails from 'src/modules/assistanceV4/components/AssistanceFrame2/assistanceViewerDetails'
import Text from "./../../../../components/text/Text/Text";
import CorporateStakeholderPage from './corporateStakeholderView/CorporateStakeholderPage';
import IndividualStakeholderPage from './individualStakeholderView/IndividualStakeholderPage';

const routes = [
  {
    path: PAGES.viewStakeholderIndividual.path,
    exact: true,
    redirectOnLogin: true,

    breadCrumbs: {
      reset: false,
      add: true,
      label: <Text uuid={"BREADCRUMS_VIEW_STAKEHOLDER_INDIVIDUAL"} params={{ fullName: "" }} />,
    },


    component: <IndividualStakeholderPage />,
    layout: ClaraLayout,
    propsLayout: {
      startupMenu: true,
      userMenu: true,
      fullMenu: true,
      showAssistance: true,
      cantBack: true,
      showBreadCrumbs: true,
      assistance: AssistanceStakeholderIndividualV4,
      assistanceViewer: AssistanceViewerDetails
    },

  },
  {
    path: PAGES.viewStakeholderCompany.path,
    exact: true,
    redirectOnLogin: true,
    breadCrumbs: {
      reset: false,
      add: true,
      label: <Text uuid={"BREADCRUMS_VIEW_STAKEHOLDER_COMPANY"} params={{ fullName: "" }} />,
    },

    component: <CorporateStakeholderPage />,
    layout: ClaraLayout,
    propsLayout: {
      startupMenu: true,
      userMenu: true,
      fullMenu: true,
      showAssistance: true,
      cantBack: true,
      showBreadCrumbs: true,
      assistance: AssistanceStakeholderCompanyV4,
    },
  },
];

export default routes;
import _ from "lodash";
import { assign } from "xstate";
import React, { useMemo, useState } from "react";
import machine from "./machine";
import Wizard from "../../../generic/components/Wizard/v2";
import SelectJurisdiction from "./Steps/SelectJurisdiction";
import editADGM from "./Steps/EditADGM";
import editDIFC from "./Steps/EditDIFC";
import editOTHER from "./Steps/EditOTHER";
import editCayman from "./Steps/EditCayman";
import ModalAssistant from "../../../generic/components/Modal/ModalAssistant";
import useModal from "../../../generic/hooks/useModal";
import { useStakeholderDetailLazyQuery } from "../../../../hooks/services/modules/startups/individual/useIndividualStakeholderData";
import useSession from "../../../session/hooks/useSession";
import { useParams } from "react-router-dom";
import { clearAssistatText } from "../../../generic/store/action";
import useUpdateStakeholder from "../../../../hooks/services/modules/individualStakeholder/useUpsertComponentStakeholder";
import TemplateWizardSkeletonMachine from "../../../generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine";
import useTranslate from "../../../generic/hooks/useTranslate";

/**
 * Wizard to edit source of founds
 * addded with the new state machine
 * @returns
 */

function EditSourceOfFundsByJurisdiction({ open: openParam = false, props }) {
  const { translate } = useTranslate();
  const [open, setOpen] = useState(openParam);
  const params = useParams();
  const [upsertComponentStakeholder] = useUpdateStakeholder();
  const { startupId } = useSession();
  const { closeModal: onClose } = useModal();

  const { data: userData, manualQuery } = useStakeholderDetailLazyQuery({
    startupId,
    stakeholderId: params.id,
  });

  const steps = useMemo(
    () => ({
      getJurisdiction: {
        render: TemplateWizardSkeletonMachine,
        renderProps: {
          title: translate("MODULES.STAKEHOLDER.EDIT_SOURCE_OF_FUNDS.TITLE"),
          subTitle: translate(
            "MODULES.STAKEHOLDER.EDIT_SOURCE_OF_FUNDS.SUBTITLE"
          ),
        },
        stepperPosition: 0,
      },
      select: {
        render: SelectJurisdiction,
        stepperPosition: 0,
      },
      selectJurisdiction: {
        render: SelectJurisdiction,
        stepperPosition: 0,
      },
      editADGM: {
        render: editADGM,
        stepperPosition: 1,
      },
      editDIFC: {
        render: editDIFC,
        stepperPosition: 1,
      },
      editOTHER: {
        render: editOTHER,
        stepperPosition: 1,
      },
      editCayman: {
        render: editCayman,
        stepperPosition: 1,
      },
    }),
    []
  );

  const guards = {
    isCayman: (context) => {
      return _.get(context, "getJurisdiction.jurisdiction.isKY", false);
    },
    isADGM: (context) => {
      return _.get(context, "getJurisdiction.jurisdiction.isADGM", false);
    },
    isDIFC: (context) => {
      return _.get(context, "getJurisdiction.jurisdiction.isDIFC", false);
    },
    isOTHER: (context) => {
      return _.get(context, "getJurisdiction.jurisdiction.isOTHER", false);
    },
    needSelectJurisdiction: (context) => {
      let countValids = 0;
      _.forEach(
        _.get(context, "getJurisdiction.jurisdiction"),
        (jurisdiction) => {
          if (jurisdiction) {
            countValids++;
          }
        }
      );
      const result = countValids >= 2;
      return result;
    },
    isCaymanSelected: (context) => {
      return _.get(context, "selectedSOF") === "KY";
    },
    isADGMSelected: (context) => {
      return _.get(context, "selectedSOF") === "ADGM";
    },
    isDIFCSelected: (context) => {
      return _.get(context, "selectedSOF") === "DIFC";
    },
    isOTHERSelected: (context) => {
      return _.get(context, "selectedSOF") === "OTHER";
    },
  };

  const services = {
    getJurisdiction: async (context, event) => {
      const stakeholderData = await manualQuery({
        variables: {
          startupId,
          stakeholderId: params.id,
        },
      });
      const stakeholder = _.get(stakeholderData, "data.getStakeholder", {});
      const jurisdictions = _.map(
        stakeholder.sourcesOfFunds,
        "jurisdictionType"
      );
      const result = {
        sourcesOfFunds: stakeholder.sourcesOfFunds,
        jurisdiction: {
          isADGM: jurisdictions.includes("ADGM"),
          isKY: jurisdictions.includes("CAYMAN"),
          isDIFC: jurisdictions.includes("DIFC"),
          isOTHER: jurisdictions.includes(null),
        },
      };
      return result;
    },
  };

  const actions = {
    setContextAfterStep: assign((context, event) => {
      const values = _.get(event, "payload.values");
      const solution = { ...context, ...values };
      return solution;
    }),
    upsertShareholder: async (values, event) => {
      let eventPayload = _.get(event, "payload.values");
      if (eventPayload.sourceOfWealth === "others") {
        const auxSOF = {
          jurisdictionType: eventPayload.jurisdictionType,
          sourceOfFunds: eventPayload.sourceOfFunds,
          sourceOfWealth: eventPayload.otherSourcesOfWealth,
        };
        eventPayload = auxSOF;
      }
      const sourcesOfFunds = [...userData.getStakeholder.sourcesOfFunds];
      const payloadJurisdictionType = _.get(eventPayload, "jurisdictionType");
      const indexOfSOF = _.findIndex(sourcesOfFunds, (o) => {
        if (payloadJurisdictionType === null) {
          return o.jurisdictionType === null;
        } else {
          return o.jurisdictionType === `${payloadJurisdictionType}`;
        }
      });
      sourcesOfFunds[indexOfSOF] = eventPayload;
      const variables = {
        startupId,
        stakeholderId: params.id,
        stakeholder: {
          sourcesOfFunds: sourcesOfFunds,
        },
      };
      await upsertComponentStakeholder({ ...variables });
    },
  };

  const handleStopMachine = () => {
    onClose();
    setOpen(false);
    clearAssistatText();
  };

  return (
    <ModalAssistant open={open}>
      <Wizard
        guards={guards}
        machine={machine}
        steps={steps}
        services={services}
        actions={actions}
        onStopMachine={handleStopMachine}
      />
    </ModalAssistant>
  );
}

export default EditSourceOfFundsByJurisdiction;

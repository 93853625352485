import { useMemo } from "react";
import TemplateWizardSkeletonMachine from "src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine";
import CheckoutFormStep from "../steps/CheckoutFormStep";
import ChooseAPayeeFormStep from "../steps/ChooseAPayeeFormStep";
import PaymentFormStep from "../steps/PaymentFormStep";
import SaveWithScaleADGM_SPVFormStep from "../steps/SaveWithScaleADGM_SPVFormStep";
import SaveWithScaleCaymanFormStep from "../steps/SaveWithScaleCaymanFormStep";
import SaveWithScaleDelawareFormStep from "../steps/SaveWithScaleDelawareFormStep";
import ThanksForYourPaymentCaymanFormStep from "../steps/ThanksForYourPaymentCaymanFormStep";
import ThanksForYourPaymentDelawareFormStep from "../steps/ThanksForYourPaymentDelawareFormStep";
import ThanksForYourPaymentADGMFormStep from "../steps/ThanksForYourPaymentADGMFormStep";
import ThanksForYourPaymentPincasFormStep from "../steps/ThanksForYourPaymentPincasFormStep";
import PayTimeOverdue from "../steps/PayTimeOverdue";
import SubmitingADGMStep from "../steps/SubmitingADGMStep";
import BankTransferStep from "../steps/BankTransferStep";
import UploadProofPaymentStep from "../steps/UploadProofPaymentStep";
import SubmitingPincasStep from "../steps/SubmitingPincasStep";
import CartStep from "../steps/CartStep";
import SubmitADGMFormStep from "../steps/SubmitADGMFormStep";
import PreSubmitADGMFormStep from "../steps/PreSubmitADGMFormStep";
import ADGMBankTransferFormStep from "src/modules/startup/PayFormationBundleModal/steps/ADGMBankTransferStep";
import CaymanBankTransferFormStep from "src/modules/startup/PayFormationBundleModal/steps/CaymanBankTransferStep";

const useSteps = () => {
  return useMemo(
    () => ({
      initialData: {
        render: TemplateWizardSkeletonMachine,
      },
      getProducts: {
        render: TemplateWizardSkeletonMachine,
      },
      SaveWithScaleADGM_SPVFormStep: {
        render: SaveWithScaleADGM_SPVFormStep,
      },
      SaveWithScaleCaymanFormStep: {
        render: SaveWithScaleCaymanFormStep,
      },
      SaveWithScaleDelawareFormStep: {
        render: SaveWithScaleDelawareFormStep,
      },
      getCart: {
        render: TemplateWizardSkeletonMachine,
      },
      getProduct: {
        render: TemplateWizardSkeletonMachine,
      },
      getSource: {
        render: TemplateWizardSkeletonMachine,
      },
      getStakeholders: {
        render: TemplateWizardSkeletonMachine,
      },
      StripePayment:{
        render: TemplateWizardSkeletonMachine,
      },
      CheckoutPayment: {
        render: TemplateWizardSkeletonMachine,
      },
      FinishPayment: {
        render: TemplateWizardSkeletonMachine,
      },
      UpdateStartup: {
        render: TemplateWizardSkeletonMachine,
      },
      ChooseAPayeeFormStep: {
        render: ChooseAPayeeFormStep,
      },
      CheckoutFormStep: {
        render: CheckoutFormStep,
      },
      PayTimeOverdue: {
        render: PayTimeOverdue,
      },
      PaymentFormStep: {
        render: PaymentFormStep,
      },
      BankTransferFormStep: {
        render: BankTransferStep,
      },
      UploadDocumentTransfer: {
        render: UploadProofPaymentStep,
      },
      ThanksForYourPaymentCaymanFormStep: {
        render: ThanksForYourPaymentCaymanFormStep,
      },
      ThanksForYourPaymentDelawareFormStep: {
        render: ThanksForYourPaymentDelawareFormStep,
      },
      ThanksForYourPaymentADGMFormStep: {
        render: ThanksForYourPaymentADGMFormStep,
      },
      ThanksForYourPaymentPincasFormStep: {
        render: ThanksForYourPaymentPincasFormStep,
      },
      PreSubmitADGMFormStep: {
        render: PreSubmitADGMFormStep,
      },
      SubmitADGMFormStep: {
        render: SubmitADGMFormStep,
      },
      SubmitingADGMStep: {
        render: SubmitingADGMStep,
      },
      SubmitingPincasStep: {
        render: SubmitingPincasStep,
      },
      CartStep: {
        render: CartStep,
      },
      ADGMBankTransferFormStep: {
        render: ADGMBankTransferFormStep,
      },
      CaymanBankTransferFormStep: {
        render: CaymanBankTransferFormStep,
      },
      BankInformation:{
        render: TemplateWizardSkeletonMachine,
      }
    }),
    []
  );
};

export default useSteps;

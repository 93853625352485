import React, { FC } from 'react';
import Tag from '../../../../../components/inputs/Tag';
import classes from '../styles.module.scss';

interface Props {
    share: any;
}
const TitleShare:FC<Props> = ({share}) =>{
    return <div className={classes.titleshareare}>
        <h1>
        {share.shareClass.defaultIdPrefix}-{share.customId}
        </h1>
        <Tag label={share.status.state}/>
    </div>
}
export default TitleShare;
import _ from "lodash";
import useGetStakeholdersGroupCompaniesLazy from "src/modules/startup/onBoarding/core/graphql/queries/useGetStakeholdersGroupCompaniesLazy";
import useGetStakeholders from "src/hooks/services/modules/stakeholders/useGetStakeholders";
import useSession from "src/modules/session/hooks/useSession";
import useCreateStakeholder from "src/modules/startup/onBoarding/core/graphql/mutations/useCreateStakeholder";
import useUpdateStakeholder from "src/modules/startup/onBoarding/core/graphql/mutations/useUpdateStakeholder";
import useUpsertGroupCompany from "./graphql/useUpsertGroupCompanyAuthorisedSignatory";
import useUpsertRenewalData from "./graphql/useUpsertRenewalData";
import useGetGroupCompany from "./graphql/useGetGroupCompany";

const useServices = ({ initialValues }) => {
  const { startupId } = useSession();
  const { groupCompanyId } = initialValues;
  const {
    manualQuery: manualQueryStakeholdersGroupCompanies,
  } = useGetStakeholdersGroupCompaniesLazy();
  const { manualQuery: getGroupCompany } = useGetGroupCompany({
    groupCompanyId,
    startupId,
  });

  const mutation = useUpsertGroupCompany();
  const submitRenewal = useUpsertRenewalData();

  const { createStakeholder } = useCreateStakeholder(
    {
      showSuccessNotification: false,
      successMessage: "",
    },
    {}
  );
  const { updateStakeholder } = useUpdateStakeholder(
    {
      showSuccessNotification: false,
      successMessage: "",
    },
    {}
  );

  //Query for UAE or GCC Stakeholders
  const { manualQuery: manualQueryUAEorGCC } = useGetStakeholders({
    variables: {
      startupId,
      groupCompanyId,
      filters: {
        kind: "STAKEHOLDER_INDIVIDUAL",
        nationalityGroup: ["UAE", "GCC"],
      },
    },
  });

  //Query for Not GCC nor UAE Stakeholders
  const { manualQuery: manualQueryNotUAEOrGCC } = useGetStakeholders({
    variables: {
      startupId,
      groupCompanyId: _.get(initialValues, "idGroupCompany"),
      filters: {
        kind: "STAKEHOLDER_INDIVIDUAL",
        nationalityGroup:["NOT_GCC","NOT_UAE"]
      },
    },
  });

  //Query for UAE or GCC Stakeholders without role DIRECTOR or SHAREHOLDER
  const { manualQuery: manualQueryUAEorGCCNotRole } = useGetStakeholders({
    variables: {
      startupId,
      groupCompanyId: _.get(initialValues, "idGroupCompany"),
      filters: {
        nationalityGroup: ["UAE", "GCC"],
        kind: "STAKEHOLDER_INDIVIDUAL",
      },
    },
  });

  const initialData = async () => {
    const result = await manualQueryStakeholdersGroupCompanies();
    const resultGC = await getGroupCompany();
    const stakeholdersUAEorGCC = await manualQueryUAEorGCC();
    const stakeholdersUAEorGCCNoRole = await manualQueryUAEorGCCNotRole();
    const stakeholdersNotUAEOrGCC = await manualQueryNotUAEOrGCC();

    const groupCompanies = _.orderBy(
      result?.data?.getGroupCompanies,
      ["isHoldingCompany"],
      ["desc"]
    );

    const groupCompaniesMapped = groupCompanies.map((gc) => ({
      disabled: gc?.isLocked || groupCompanyId === gc.id,
      label: gc?.name,
      value: gc?.id,
    }));

    let signatories = [];

    const useNominee = _.get(
      resultGC,
      "data.getGroupCompany.keyPeople.useClaraNominee",
      false
    );

    signatories = _.get(
      resultGC,
      "data.getGroupCompany.keyPeople.authorisedSignatories",
      []
    );

    if (useNominee) {
      signatories = [{ fullName: "Clara Nominee" }, ...signatories];
    }

    return {
      groupCompanyId,
      useNominee,
      signatories,
      stakeholdersUAEorGCC: stakeholdersUAEorGCC?.data?.stakeholderList,
      stakeholdersUAEorGCCNoRole:
        stakeholdersUAEorGCCNoRole?.data?.stakeholderList,
      stakeholdersNotUAEOrGCC: stakeholdersNotUAEOrGCC?.data?.stakeholderList,
      groupCompaniesMapped,
    };
  };

  const SaveStep = async (context) => {
    const confirm = context?.ReviewAndConfirmStep?.confirm;
    const nomineeStep = context?.ClaraNomineeStep;
    const useNominee = nomineeStep?.useNominee === true || nomineeStep?.useNominee === "true"

    if (confirm) {
      
      const signatories = _.map(_.filter(context?.initialData?.signatories ?? [], (s) => !!s?.id),(s)=>s.id);
      const companyData = {
        keyPeople: {
          authorisedSignatories: signatories,
          useClaraNominee:context?.initialData?.useNominee ?? false,
        },
      };

      const variables = {
        startupId,
        groupCompanyId,
        groupCompany: companyData,
      };
      await mutation({ variables });
      return await submitRenewal(groupCompanyId);
    }

    const shouldCreateStakeholder = !!context?.CreateStakeholderStep;
    const isThereSecondary = context?.IsThereSecondarySignatoryStep?.isThereSecondary === true || context?.IsThereSecondarySignatoryStep?.isThereSecondary === "true"
    const signatoryFromContext =
    context?.AssignSignatoryStep?.authorisedSignatory ||
    context?.AssignSignatoriesStep?.authorisedSignatory;
    let stakeholderToSave = null;

    if (shouldCreateStakeholder) {
      const { fullName, company } = context?.CreateStakeholderStep;
      const enterType = context?.UAEInfoStep?.howDidTheyEnter;
      const mapRoles = _.map(company, (c) => ({
        entityId: c,
        entityType: "GROUP_COMPANY",
        role: [
          {
            type: "AUTHORIZEDSIGNATORY",
          },
          {
            type: "THIRDPARTY",
          },
        ],
      }));
      const stakeholder = {
        fullName,
      }
      if (isThereSecondary) stakeholder['enterType'] = enterType;
      const variables = {
        startupId,
        stakeholderData: {
          stakeholder,
          roles: mapRoles,
        },
      };
      const stakeholderData = await createStakeholder({ variables });
      stakeholderToSave = stakeholderData?.data?.createStakeholder;
    } else {
      if(isThereSecondary){
      const { id } = context?.AssignSignatoriesStep?.authorisedSignatory;
      const enterType = context?.UAEInfoStep?.howDidTheyEnter;
        const variables = {
          startupId,
          stakeholderId: id,
          stakeholderData: {
            stakeholder: {
              enterType,
            },
          },
        };
        await updateStakeholder({ variables }); 
    }
  }

    const signatory = shouldCreateStakeholder
      ? stakeholderToSave
      : signatoryFromContext;
    const useClaraNominee =
      context?.ClaraNomineeStep?.useNominee === true ||
      context?.ClaraNomineeStep?.useNominee === "true";
      const authorisedSignatories = [signatory?.id];
      if(context?.IsThereSecondarySignatoryStep?.isThereSecondary === true || context?.IsThereSecondarySignatoryStep?.isThereSecondary === "true") authorisedSignatories.push(context?.AssignSignatoriesStep?.authorisedSignatory?.id)
    const companyData = {
      keyPeople: {
        authorisedSignatories,
        useClaraNominee,
      },
    };

    const variables = {
      startupId,
      groupCompanyId,
      groupCompany: companyData,
    };
    await mutation({ variables });
    return await submitRenewal(groupCompanyId);
  };

  return { initialData, SaveStep };
};

export default useServices;

import React, {useEffect, useState} from "react";
import classes from "./Notes.module.scss";
import {Loading} from '../../../index';
import {ServerConnect} from '../../../../utils'
import {ReactComponent as NoComments} from '../../../../../images/Group 562.svg'
import NotesOnline from '../../../Comments/NotesOnline';
import Mustache from "mustache";
import {useParams} from 'react-router-dom';


const Notes = (props) => {

    const [data, setData] = useState([]);
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const getNotes = `
    {
        getMatterNotes( matterId: "{{matterId}}"){
          id
          createdBy {
            firstName
            lastName
            avatar
            _id
          }
          content:note
          createdAt
          updatedAt
        }
      }
    `

    useEffect(() => {

        const getDataServer = () => {
            const query = Mustache.render(getNotes, { matterId: params.id })
            ServerConnect.graphQlMutation(query)
                .then(result => {
                    setData(result.getMatterNotes);
                    setLoading(false);

                })
        }

        getDataServer();

    }, [])



    return (
        <div className={classes.ContainerView}>
            <Loading show={loading} showChildren={false} loadingStyle={{ marginTop: "4.5rem" }} >

                <NotesOnline notes={data}>
                    <div className={classes.containerNotes}>
                        <NoComments className={classes.NoComments} />
                    </div>
                </NotesOnline>
            </Loading>
        </div>)

}

export default Notes;

import React, { useEffect } from 'react';
import useQuery from "../../../custom/useQuery";
import _ from 'lodash'
import {RoleFields} from "../../../../modules/stakeholder/graphql/typePolicies";

export const query = `
 ${RoleFields} 
  query getDocument ( 
    $startupId: ID!
    $agreementId:ID!  
    ){
      getDocument(startupId: $startupId, agreementId: $agreementId){
        id
        template{
          id
        }
        draftTemplate {
          id
        }
        isLocked
        freemiumValid
        isReplaced
        historyTimeLine{
          createdAt
          createdBy{
          _id
            firstName
            lastName
          }
          event
          
        }
        status
        verified
        date
        name
        file {
          id
          name
          type
          size
        }
        terms {
          key
          value
        }
        type {
          code
          label
          allowedActions
          isGeneratedIncorporated
        }
    name
    terms {
          key
          value
        }
     stakeholders{
      id
      isAnEntity
      email
      fullName
     roles { ...RoleFields }
      address{
        city
        country {name code alpha3}
        zipCode
        street
      }
      passportNumber
      nationality {name code}
    }
    parties
    showInMaps
    kind
    subCategories
    groupCompanies{
    groupCompany{
      id
      country {name code alpha3}
      registeredNumber
      type
      avatar
      name
      registeredAddress{
        city
        country {name code alpha3}
        zipCode
        street
      }
      }
      signedBy{
        id 
        fullName
        avatar
        email
      }           
    }
    termNotes {title description}
    expirationDate
     executedOnDate
    params
    fileHTML
	parentDocument {
    	id
    	name
    	type { code }
    	status
    }
    relatedDocuments {
      id
      status
      name
      kind
      type {
          code
          label
          allowedActions
        }
      stakeholders{
        id
        isAnEntity
        email
        fullName
       roles { ...RoleFields }
        address{
          city
          country {name code alpha3}
          zipCode
          street
        }
        passportNumber
        nationality {name code}
      }
		file {id name size}
    	type {code}
	}
	identityDocument{
	id
	number
    nationality
    expiryDate
    current
    documents{
    id
    file{
    name
    id
    type
    }
    }
	}
  }
  
    }
`;

const useGetDocument = (variables) => {

  const { loading, error, data, ...pre } = useQuery(query, variables);

  return { loading, error, data: _.get(data, 'getDocument'), ...pre };
};


export default useGetDocument;

import React, { useRef } from 'react'
import Legends from './Legends/Legends';
import classes from './ZoneGraph.module.scss'
import { LeftArrowScroll, RightArrowScrollNext } from '../components/ArrowTable'

interface ZoneLegendsProps {
  labels: any[];
  colors: any[];
}

const ZoneLegends: React.FC<ZoneLegendsProps> = ({ labels, colors }) => {
  
  const refContainer = useRef();

  return (
    <div className={classes.containerZoneLegends}>
      {refContainer?.current && labels.length > 15 && <LeftArrowScroll containerRef={refContainer} className={classes.prevButton} />}
      <div className={classes.ClassLegend} ref={refContainer}>
        <Legends labels={labels} colors={colors} />
      </div>
      {refContainer?.current && labels.length > 15 && <RightArrowScrollNext containerRef={refContainer} className={classes.nextButton} />}
    </div >
  )
}

export default ZoneLegends
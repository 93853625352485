import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import _ from "lodash";
import useRenameDevice from "./../../../../../../hooks/services/modules/user/useRenameDevice";
import useTranslate from "../../../../../../modules/generic/hooks/useTranslate";
import useModal from "./../../../../../../modules/generic/hooks/useModal";
import Button from "../../../../../../modules/generic/components/Buttons/Button";
import TemplateWizard from "../../../../../../modules/generic/templates/Modal/TemplateWizard";
import ModalAssistant from "../../../../../../modules/generic/components/Modal/ModalAssistant";
import { clearAssistatText } from "../../../../../../modules/generic/store/action";
import RenameDeviceForm from "./RenameDeviceForm";

const RenameDeviceModal = ({
  showModal,
  open,
  handleCloseModal,
  initialValues
}) => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const { closeModal } = useModal();

  const handleCloseForm = () => {
    closeModal();
  }

  const onCompletedUpdate = () => {
    handleCloseModal();
  }

  const [renameDevice, loadingRename] = useRenameDevice({
    onCompleted: onCompletedUpdate,
    successMessage: "DEVICE_RENAME_MESSAGE_SUCCESS",
  });

  const handleSubmit = async (values) => {
    await renameDevice({ id: initialValues.id, name: values.name });
  };

  const handleInitModal = () => {
    dispatch(clearAssistatText());
  }

  useEffect(() => {
    handleInitModal();
  }, []);

  return (
    <ModalAssistant open={showModal || open} handleClose={() => handleCloseModal()} showHeader={false}>
      <RenameDeviceForm
          initialValues={{}}
          buttons={{
            cancel: <Button onClick={handleCloseForm} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
          }}
          handleSubmit={handleSubmit}
          propsTemplate={{
            title: translate("MY_ACCOUNT_MODAL_RENAME_DEVICE", { device_name: _.get(initialValues, "name") }),
            subTitle: translate("MY_ACCOUNT_MODAL_RENAME_DEVICE_SUBTITLE")
          }}
          Template={TemplateWizard}
      />
    </ModalAssistant>
  );
};

export default RenameDeviceModal;

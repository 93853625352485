import React, { FC } from "react";
import useTranslate from "../../../../../../modules/generic/hooks/useTranslate";
import Bubble from "../../../../../../modules/assistanceV4/components/Bubble";
import imageUpgrade from "../../../../../../images/upgrade.svg";
import classes from "./healthCheckTooltips.module.scss";
import classnames from "classnames";
import NextIcon from "../../../../../../modules/generic/components/NextIcon";
import Button from "../../../../../../modules/generic/components/Buttons/Button/index";
import { useSelector, useDispatch } from 'react-redux';
import { Constants } from "../../../../../../v1/utils/constants";
import BusinessRuleAccess from "../../../../../clara/components/BusinessRuleAccess/BusinessRuleAccess";
import _ from "lodash";
import { RoleAccess } from "src/v1/components";

export interface HealthCheckTooltipsProps {

}

const HealthCheckTooltips: FC<HealthCheckTooltipsProps> = ({
  ...props
}: HealthCheckTooltipsProps) => {

  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const left = useSelector((store) => {
    const feature = _.find(
      _.get(store, `session.startup.activeSubscriptionPlan.features`, []),
      (feature) => feature.code === Constants.PRODUCTS.HEALTH_CHECK
    );
    if (!feature) return null;
    const limit = _.get(feature, "limit.amount", 0);
    const usages = _.get(store, "session.startup.subscriptionFeaturesUsage");
    const usage = _.find(
      usages,
      (feature) => feature.code === Constants.PRODUCTS.HEALTH_CHECK
    );
    const actual = usage ? usage.amount : 0;
    const left = limit - actual;
    return left < 0 ? 0 : left;
  });

  return (
    <>
      <BusinessRuleAccess type={'or'} conditions={[Constants.BUSINESS_RULES.IS_START_SUBSCRIPTION, Constants.BUSINESS_RULES.IS_INACTIVE_PLAN]}>
        <Bubble
          color={"upgrade"}
        >
          <div className={classnames(classes.BubbleContentsNext)}>
            <NextIcon
              title={left === 0 ? translate("TOOLTIPS_HEALTHCHECK_TITLE_UPGRADE", { left: left }) : translate("TOOLTIPS_HEALTHCHECK_TITLE", { left: left })}
              subTitle={left === 0 ? translate("TOOLTIPS_HEALTHCHECK_SUBTITLE_UPGRADE") : translate("TOOLTIPS_HEALTHCHECK_SUBTITLE")}
              imageIcon={imageUpgrade}
              removeSeparator={!(left === 0)}
            />
            <RoleAccess action={Constants.ACTIONS.MANAGE_SUBSCRIPTIONS}>
              {left === 0 && (
                <div className={classnames(classes.buttonSeparator)}>

                  <Button
                    variant={"upgrade"}
                    children={translate("UPGRADE_SCALE_PRICE_BUTTON")}
                    onClick={() => {
                      dispatch({ type: "GO_TO", page: Constants.PAGES.upgrade });
                    }}
                  ></Button>
                </div>
              )}
            </RoleAccess>
          </div>
        </Bubble>
      </BusinessRuleAccess>
    </>
  );
};

export default HealthCheckTooltips;

import React, { useEffect, useMemo } from 'react'
import {Formik, validateYupSchema, yupToFormErrors} from 'formik'
import classes from './FormSelector.module.scss';
import useSchemaGenerate from "./Hooks/useSchemaGenerate";
import Botonera from '../../../../contents/Botonera/Botonera';
import Text from '../../../../../text/Text/Text';
import SecondaryButton from "../../../../../generic/Buttons/SecondaryButton/SecondaryButton";
import PrimaryButton from "../../../../../generic/Buttons/PrimaryButton/PrimaryButton";
import useGetGenerateForm from "./Hooks/useGetGenerateForm";
import ContainerModal from '../../../../../generic/Modal/ModalScroll/ContainerModal'
import Loading from "../../../../../generic/Loading/Loading";
import useSession from '../../../../../../modules/session/hooks/useSession';

const FormSelector = ({ initialValues, isSubmitting, handleSubmit, handleCloseModal, generate, handleDeleteModal = () => { }, ...props }) => {
	
	const { startup } = useSession();

	const schema = useSchemaGenerate(initialValues.type.code);
	const form = useGetGenerateForm(initialValues, props, startup);

	return (
		<Formik
			initialValues={initialValues.params}
			validate={(value) => {
				try {
					validateYupSchema(value, schema, true, value);
				} catch (err) {
					return yupToFormErrors(err); //for rendering validation errors
				}

				return {};
			}}
			// validationSchema={schema}
			validateOnChange={false}
			onSubmit={handleSubmit}
		>
			{({
				values,
				errors,
				dirty,
				handleReset,
				setFieldValue,
				isValid,
				validateForm,
			}) => {
				// {console.log('values-->',values)}
				// {console.log('errors-->',errors)}
				return (
					<>
						<ContainerModal>
							 <Loading loading={props.loading}>
								<div className={`${classes.containerForm} `}>
									{React.cloneElement(form, { setFieldValue: setFieldValue })}
								</div>
							 </Loading>
						</ContainerModal>

						<div className={classes.containerButtons}>
							<Botonera>
								<SecondaryButton
									onClick={() => handleCloseModal()}><Text
										uuid={'BUTTON_CANCEL'} /></SecondaryButton>
								<PrimaryButton
									loading={isSubmitting}
									labelLoading={"Saving..."}
									onClick={() => handleSubmit(values, validateForm)}><Text
										uuid={"MY_ACCOUNT_MODAL_UPDATEPASSWORD_SAVE"}
									/></PrimaryButton>
							</Botonera>
						</div>
					</>
				)
			}
			}
		</Formik >
	)
};


export default FormSelector;

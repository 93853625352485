import { createMachine } from 'xstate';

const machine =
  createMachine({
    id: 'CompanyDirectors',
    initial: 'initialData',
    states: {
      initialData: {
        invoke: {
          src: 'initialData',
          id: 'initialData',
          onDone: [
            {
              target: 'AddDirector',
              actions: ['setContextAfterInvoke']
            }
          ],
        }
      },
      // ADD DIRECTOR
      AddDirector: {
        on: {
          NEXT: {
            target: 'EvaluateNeedCompanyType',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      // EVALUATE NEED COMPANY TYPE
      EvaluateNeedCompanyType: {
        always: [
          {
            cond: 'isNotCorporate',
            target: 'SaveStep'
          },
          {
            cond: 'isCorporate',
            target: 'LegalEntityStructure'
          },
        ]
      },
      // LEGAL ENTITY STRUCTURE
      LegalEntityStructure: {
        on: {
          PREV: {
            target: 'AddDirector',
          },
          NEXT: {
            target: 'SaveStep',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      // ADD OTHER DIRECTOR
      AddOtherDirector: {
        on: {
          NEXT: {
            target: 'EvaluateLeave',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      // EVALUATE LEAVE
      EvaluateLeave: {
        always: [
          {
            cond: 'addNew',
            target: 'AddDirector'
          },
          {
            cond: 'doNotAddNew',
            target: 'cancel'
          },
        ]
      },
      // SAVE STEP
      SaveStep: {
        invoke: {
          src: 'SaveStep',
          id: 'SaveStep',
          onDone: [
            {
              target: 'AddOtherDirector',
              actions: ['addStakeholder']
            }
          ],
          onError: [
            {
              target: 'cancel'
            }
          ]
        }
      },
      cancel: {
        entry: 'cancelMachine',
        type: 'final'
      },
      finish: {
        entry: 'stopMachine',
        type: 'final'
      }
    }
  });

export default machine;

import { createMachine } from 'xstate';

const machine =
  /** @xstate-layout N4IgpgJg5mDOIC5QDED2AnAtgUQB4ENMAHAGzADo0sA5QsAQQDsIAZfWAF1szAGJrsADQAqiUEVSwAlhympGYkLkQAmFQBZyARgDMOgJxajOlQA4tKgKwAaEAE9EAdi3lT+gAzut6gGzrT6jqOlgC+IbZUOATEZJQYOJj4UiS8AAoAStgAaooS0rLyisoIOlqO2lqmKo5eKjqWhiq2DghG+q4enu5+7jo+pqah4SCReISkFKOJyfxCokggeTJyCgvFpeW6BiqGgZU+NvaIWj7lA-r6pj4+JjqeKmER8WMxk-EAmmD46GmZOQtLAqrUDFNSaLaGYxmCyHFoqLSWVxufSOFQ+DR1BGmR4jZ7RCZxLCfb6zES5STLQprVQabR6SG6aFWZqIUouc76dS+dymdycqw40b42KwACuACNMDJeBB5BQpIwAG6oADWFAAZgqpLAABYAZQlUo45PyKyKiBuOnIfR8fJ5-R06ncTSOCGqiNRJkMnJ96J0grx4xFhulYHQ6Aw5FI+A46vi5E1jG1+pDxoBFKB5rdtIhRkZ5mZrrRVo5liuJxOOwDWBeBLFAGN63BYKT5uIM2bqQhLdbrnarqZHc6Wa1eXSav56QEgupq1EgxQwxGfgIyenTVSQRa9L3bR4B0OXS1dO5yBoanofLtuhowsNGKgIHBFEKF4TMNwGMw2JxPybKcCSiIOoR6IFU5B8nofR8uo+gaF4c61rEUxJCQ-6Zl2gSnkYVQDBCBxlCOg7kI0njqM45EIv0iHCm8RJfOg6GdlurTuJsliWDc+h+NOwQjmoLiBAYDSOLaVRqD4NFvmKkoyExm5AQgWEVLh5j0gRjj8Tc5CemYsHqJYvhaNxUmvOQDZNrA8DrgBWaWEEEFeM4fKDJY1Saa6lRWleKi9E6RgNN0knDK+ZlLhg8mAcUHGnlc3HeiBHFuCOWyuM6Xj6H0pT2Q8IWBmZibJpFWaDio1qWO4XJlpxamwscjo6eRzrXKYjhBMZs55TWtHFV2lQjgAtC4Wh8voDSXpYjiOK1-p3kAA */
  createMachine({
    id: 'OnboardingMachine',
    initial: 'StartupNameStep',
    states: {
      StartupNameStep: {
        on: {
          NEXT: {
            target: 'TellUsAboutStep',
            actions: ["setContextAfterStep"]
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      TellUsAboutStep: {
        on: {
          PREV: {
            target: 'StartupNameStep'
          },
          NEXT: {
            target: 'AddFounderStep',
            actions: ["setContextAfterStep"]
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      AddFounderStep: {
        on: {
          PREV: {
            target: 'TellUsAboutStep'
          },
          NEXT: {
            target: 'GoalStep',
            actions: ["setContextAfterStep"]
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      GoalStep: {
        on: {
          PREV: {
            target: 'AddFounderStep'
          },
          NEXT: {
            target: 'EvaluateGoal',
            actions: ["setContextAfterStep"]
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      EvaluateGoal: {
        always: [{
          cond: 'isFormation',
          target: 'WhatHappensNextFormationStep',
        },
        {
          cond: 'isGoToDocuments',
          target: 'WhatHappensNextGenerateDocumentStep',
        },
        {
          cond: 'isBuildCapTable',
          target: 'WhatHappensNextStepCapTable'
        },
        {
          cond: 'isAccelerator',
          target: 'AcceleratorStep'
        }]
      },
      WhatHappensNextFormationStep: {
        on: {
          PREV: {
            target: 'GoalStep'
          },
          NEXT: {
            target: 'createStartupFormation'
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      WhatHappensNextGenerateDocumentStep: {
        on: {
          PREV: {
            target: 'GoalStep'
          },
          NEXT: {
            target: 'createStartupDocuments'
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      WhatHappensNextStepCapTable: {
        on: {
          PREV: {
            target: 'GoalStep'
          },
          NEXT: {
            target: 'createStartupCapTable'
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      AcceleratorStep: {
        on: {
          PREV: {
            target: 'GoalStep'
          },
          NEXT: {
            target: 'verifyCode',
            actions: ["setContextAfterStep"]
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      verifyCode: {
        invoke: {
          src: 'verifyCode',
          id: 'verifyCode',
          onDone: [
            {
              target: 'EvaluateCode',
              actions: ["setContextAfterInvoke"]
            }
          ],
          onError: [
            {
              target: 'AcceleratorStep'
            }
          ]
        }
      },      
      EvaluateCode: {
        always: [
          {
            cond: 'isVerified',
            target: 'WhatHappensNextAcceleratorStep',
          },
          {
            cond: 'isNotVerified',
            target: 'AcceleratorStep'
          }
        ]
      },
      WhatHappensNextAcceleratorStep: {
        on: {
          PREV: {
            target: 'AcceleratorStep'
          },
          NEXT: {
            target: 'createStartupAccelerator',
            actions: ["setContextAfterStep"]
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      createStartupFormation: {
        invoke: {
          src: 'createStartup',
          id: 'createStartup',
          onDone: [
            {
              actions: ["setContextAfterInvoke", "stopMachine"]
            }
          ],
          onError: [
            {
              target: 'EvaluateGoal'
            }
          ]
        }
      },
      createStartupDocuments: {
        invoke: {
          src: 'createStartup',
          id: 'createStartup',
          onDone: [
            {
              actions: ["setContextAfterInvoke", "stopMachine"]
            }
          ],
          onError: [
            {
              target: 'EvaluateGoal'
            }
          ]
        }
      },
      createStartupCapTable: {
        invoke: {
          src: 'createStartup',
          id: 'createStartup',
          onDone: [
            {
              actions: ["setContextAfterInvoke", "stopMachine"]
            }
          ],
          onError: [
            {
              target: 'EvaluateGoal'
            }
          ]
        }
      },
      createStartupAccelerator: {
        invoke: {
          src: 'createStartup',
          id: 'createStartup',
          onDone: [
            {
              actions: ["setContextAfterInvoke", "stopMachine"]
            }
          ],
          onError: [
            {
              target: 'EvaluateGoal'
            }
          ]
        }
      },
      cancel: {
        entry: "cancelMachine",
        type: "final",
      },
      finish: {
        entry: "stopMachine",
        type: "final",
      },
    }
  });

export default machine;

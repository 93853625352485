import { Field } from "formik";
import _ from "lodash";
import React, { Component } from 'react';
import { TextAreaInput, TextInput } from '../../../../../../../v1/components';
import ClaraCountrySelector from "../../../../../../../v1/components/Form/ClaraCountrySelector/ClaraCountrySelector";
import ClaraSelect from "../../../../../../../v1/components/Form/ClaraSelect/ClaraSelect";
import ClaraUpload from "../../../../../../../v1/components/Form/ClaraUpload/ClaraUpload";
import Checkbox from "../../../../../../../v1/containers/Forms/Inputs/Checkbox";
import FormikHelper from "../../../../../../../v1/utils/formikHelper";
import Line from "../../../../../../generic/Line/Line";
import AuthorisedSignatories from "../../../../../../inputs/AuthorizedSignatories/AuthorizedSignatories";
import SelectStakeholders from "../../../../../../inputs/Select/SelectStakeholders";
import classes from "../Styles.module.scss";
import SelectStateCity from '../../../../../../inputs/Select/SelectStateCity';

class EquityIncentivePlan extends Component {

	constructor(props) {
		super(props);
		this.state = {
			typeSelected: undefined,
			employeeSelected: undefined,
			groupCompanySelected: undefined,
			authorizedSelected: undefined,
			authorizedSignatories: [],
			authorizedSignatoriesList: [],
			documentTypes: [],
			documentTypesList: [],
			dynamicParams: [],
			date: undefined,
			expirationDate: undefined,
			firstSetCity: true,
			stateValue: null
		}
	};

	componentWillMount() {
		if (this.props.values) {
			const values = this.props.values.values ? this.props.values.values : this.props.values;
			const expirationDate = this.getDateForPickerFromData(values.expirationDate);
			this.setState({
				typeSelected: values.documentType,
				employeeSelected: values.employee,
				groupCompanySelected: values.company,
				expirationDate,
				authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.company).map(as => ({
					id: as.id,
					label: as.label
				}))
			});


		}

	}

	componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps.values) {
			const values = nextProps.values.values ? nextProps.values.values : nextProps.values;
			const expirationDate = this.getDateForPickerFromData(values.expirationDate);
			this.setState({
				expirationDate,
				authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.company)
					.map(as => ({
						id: as.id,
						label: as.label
					}))
			});
		}
	}

	fieldHasError(form, field) {
		return FormikHelper.returnErrorsForValidation(field.name, form.errors);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.values) {
			const values = prevProps.values.values ? prevProps.values.values : prevProps.values;
		}
	}

	selectType = (documentType, extraParams, form) => {
		this.setState({
			typeSelected: documentType,
			dynamicParams: this.getDynamicParamsByType(documentType)
		}, () => {
			FormikHelper.setValueInTheCorrectPosition('terms', form, []);
			FormikHelper.setValueInTheCorrectPosition('documentType', form, documentType);
			this.state.dynamicParams.forEach((dynamicParam, i) => {
				FormikHelper.setValueInTheCorrectPosition(
					`terms[${i}].key`,
					form,
					dynamicParam.key || 'NO_KEY'
				);
			})
		});
	};

	getDynamicParamsByType(documentType) {
		const type = this.props.documentTypes.filter(agrType => agrType.code === documentType)[0] || {};
		return type.fields || [];
	}

	getGroupCompaniesToCompany = () => {
		let authorizedSignatories = this.props.authorizedSignatories;
		let groupCompanies = this.props.groupCompanies;
		let companies = [];
		if (groupCompanies && authorizedSignatories) {
			companies = _.filter(groupCompanies, (groupCompany) => {
				let fined = _.filter(authorizedSignatories, (authorized) => {
					if (groupCompany.authorizedSignatories) {
						return groupCompany.authorizedSignatories.some(au => au.stakeholder.id === authorized.id);
					}
					return false;
				});
				if (fined) {
					return true;
				}
				return false;
			});
		}
		return companies;
	};

	selectGroupCompany = (gcId, extraParams, form) => {

		const groupCompany = this.findGroupCompany(gcId);

		// this is for a weird behaviour when hit edit button after preview a document
		if (this.state.groupCompanySelected && groupCompany) {
			return null;
		}

		this.setState({
			groupCompanySelected: groupCompany,
			firstSetCity: true,
			stateValue: null
		});

		if (groupCompany) {

			if (!groupCompany.registeredAddress) {
				_.set(groupCompany, "registerAddress", {})
			}

			let authorizedSignatories = _.get(form, 'values.company.authorizedSignatories', []);

			form.setValues({
				...form.values,
				company: {
					id: gcId,
					name: groupCompany.name || '',
					country: _.get(groupCompany, 'country.code', null) || _.get(groupCompany, 'country', null),
					jurisdiction: groupCompany.jurisdiction,
					registeredAddress: {
						...groupCompany.registeredAddress,
						country: _.get(groupCompany, 'registeredAddress.country.code', null) || _.get(groupCompany, 'registeredAddress.country', null)
					},
					placeOfBusiness: groupCompany.placeOfBusiness,
					authorizedSignatories: authorizedSignatories,
				}
			});
			this.setState({
				groupCompanySelected: { ...groupCompany },
			})
		} else {
			form.setValues({
				...form.values,
				company: {}
			});
		}
	};

	findGroupCompany(gcId) {
		const gc = _.find(this.props.groupCompaniesList, { id: gcId });
		return gc ? { ...gc } : null
	};

	selectEmployee = (employee, extraParams, form, cb) => {
		const stakeholder = this.findStakeholder(employee);
		this.setState({
			employeeSelected: stakeholder
		});

		if (stakeholder) {
			let authorizedSignatory = {};

			if (!stakeholder.address) {
				_.set(stakeholder, 'address', {})
			}

			const _employee = !_.isEmpty(form.values.employee) ? form.values.employee : stakeholder;

			if (stakeholder.isAnEntity) {
				authorizedSignatory = {
					fullName: typeof _employee.authorizedSignatory === 'object'
						? _.get(_employee, 'authorizedSignatory.fullName', '')
						: _.get(_employee, 'authorizedSignatory', ''),
					email: _employee.email || ''
				}
			}

			form.setValues({
				...form.values,
				employee: {
					id: employee,
					fullName: _employee.fullName || '',
					email: _employee.email || '',
				}
			});
		} else {
			form.setValues({
				...form.values,
				employee: {}
			});
		}

	};

	findStakeholder(roleId) {
		return this.props.stakeholderList.find(stakeholder => stakeholder.id === roleId)
	};

	findStakeholderAuthorized(roleId) {
		return this.props.stakeholderList.filter(stakeholder => {
			return stakeholder.roles.some(role => role.stakeholder.id === roleId);
		})[0] || null;
	};

	filterAuthorizedByGroupCompany(groupCompany) {
		if (groupCompany) {
			let gc = this.findGroupCompany(groupCompany.id);
			return this.props.authorizedSignatories.filter(authorized => {
				return authorized.groupCompanyId === gc.id
			});
		}
		return [];
	}

	selectAuthorized = (value, form, field, replace) => {
		// const stakeholder = this.findStakeholder(value);
		const stakeholder = this.props.authorizedSignatories.find(e => e.id === value);
		const email = stakeholder ? stakeholder.email : '';
		replace(0, { id: value, email })
	};

	getAuthorizedSignatoriesList = (index, form) => {
		if (form.values && form.values.company) {
			return this.filterAuthorizedByGroupCompany(form.values.company).map(as => ({
				id: as.id,
				label: as.label
			}))
		} else {
			return [];
		}
	};

	getLabel = (key) => {
		switch (key) {
			case "fullName":
				if (this.state.employeeSelected.isAnEntity) {
					return "Company name";
				} else {
					return "Name";
				}
			case "country":
				if (this.state.employeeSelected.isAnEntity) {
					return "Country of incorporation";
				} else {
					return "Nationality";
				}
			case "address":
				if (this.state.employeeSelected.isAnEntity) {
					return "Registered address";
				} else {
					return "Residential address";
				}
			case "passportOrRegistered":
				if (this.state.employeeSelected.isAnEntity) {
					return "Company number";
				} else {
					return "Passport number";
				}
		}
	};

	getField = (key) => {
		switch (key) {
			case "passportOrRegistered":
				if (this.state.employeeSelected.isAnEntity) {
					return "employee.registeredNumber";
				} else {
					return "employee.passportNumber";
				}
		}
	};

	getTooltip = (key, fieldName) => {
		switch (key) {
			case "fullName":
				if (this.state.employeeSelected.isAnEntity) {
					return "Company Name";
				} else {
					return "Employee's Name";
				}
			case "passportOrRegistered":
				if (this.state.employeeSelected.isAnEntity) {
					return "Company Number";
				} else {
					return "Passport Number";
				}
			case "country":
				if (this.state.employeeSelected.isAnEntity) {
					return "Country";
				} else {
					return "Nationality";
				}
			case "addressLine1":
				if (this.state.employeeSelected.isAnEntity) {
					return "Registered Address - Address Line 1";
				} else {
					return "Residential Address - Address Line 1";
				}
			case "addressCity":
				if (this.state.employeeSelected.isAnEntity) {
					return "Registered Address - City";
				} else {
					return "Residential Address - City";
				}
			case "addressState":
				if (this.state.employeeSelected.isAnEntity) {
					return "Registered Address - State";
				} else {
					return "Residential Address - State";
				}
			case "addressCountry":
				if (this.state.employeeSelected.isAnEntity) {
					return "Registered Address - Country";
				} else {
					return "Residential Address - Country";
				}
			case "addressPostCode":
				if (this.state.employeeSelected.isAnEntity) {
					return "Registered Address - Post Code";
				} else {
					return "Residential Address - Post Code";
				}
			case "recipientNotice":
				if (fieldName === 'company') {
					return "Group company";
				} else {
					return "Employee's";
				}
		}
	};

	getPlaceholder = (key) => {
		switch (key) {
			case "passportOrRegistered":
				if (this.state.employeeSelected.isAnEntity) {
					return "Company number";
				} else {
					return "Passport number";
				}
		}
	};

	getStakeholderAdds = () => {
		return [
			{
				type: "stakeholder",
				label: "Add new individual stakeholder",
				textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
				placeholder: "Stakeholder's name",
				startupId: this.props.startup.id,
				textToSave: "fullName",
				params: {
					isAnEntity: false,
					// roles : [{
					// 	role:"EMPLOYEE",
					// 	groupCompany: this.state.groupCompanySelected.id,
					// }]
				},
				paramsReturn: 'isAnEntity'
			},
		]
	};

	getCompanyAdds = () => {
		return [{
			type: "groupCompany",
			label: "Add new company",
			textWarning: "This will create a new group company for your startup. You can add or amend the details of this group company on its profile page.",
			placeholder: "Company name",
			startupId: this.props.startup.id,
			params: null,
			paramsReturn: `
				name
				authorizedSignatories {
					id
					stakeholder {
						id
						email
						fullName
						roles {   id
        name
        groupCompany { id name}
        stakeholder { id } }
					}
				}
			`
		}]
	};

	getAuthorizedSignatoryAdds = () => {
		return [
			{
				type: "stakeholder",
				label: "Add new individual stakeholder",
				textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
				placeholder: "Enter stakeholder's name",
				startupId: this.props.startup.id,
				textToSave: "fullName",
				params: {
					isAnEntity: false,
					roles: [{
						role: "DIRECTOR",
						groupCompany: this.state.groupCompanySelected.id,
					}]
				}
			},
		]
	};

	handleStakeholderCreate = (value, name, additionalValue, form) => {

		let { stakeholders, stakeholderList } = this.props;
		let newSh = { id: value.id, fullName: name, ...additionalValue };
		stakeholderList.push(newSh);

		stakeholders.push({ id: value.id, label: name });

	};

	handleGroupCompanyCreate = (value, name, additionalValue, form) => {

		let { groupCompanies, groupCompaniesList } = this.props;

		let newGc = { id: value.id, name, ...additionalValue };
		groupCompaniesList.push(newGc);

		groupCompanies.push({ id: value.id, label: name });

	};

	handleAuthorizedSignatoryCreate = (value, name, additionalValue, form) => {

		let { authorizedSignatories } = this.props;
		const { groupCompanySelected } = this.state;

		authorizedSignatories.push({
			id: value.id,
			label: name,
			role: 'DIRECTOR',
			groupCompanyId: groupCompanySelected.id
		});

	};

	handleAuthorizedSignatoryUpdate = (value) => {

		const { authorizedSignatories, groupCompanySelected } = this.state;
		const groupCompany = this.findGroupCompany(groupCompanySelected.id);
		_.set(groupCompany, 'authorizedSignatories', _.map(groupCompany.authorizedSignatories, auth => ({ ...auth })))
		groupCompany.authorizedSignatories.push({ stakeholder: value });
		authorizedSignatories.push(value);
	};

	render() {
		const isRequired = message => value => (!!value ? undefined : message);
		const { tooltips = {}, formType } = this.props;

		return (
			<div>
				{formType === 'uploadToEdit' ? (<div>
					<Field name={`file`}>
						{({ field, form }) => (
							<ClaraUpload
								required={true}
								name={`filepond`}
								label='Upload your document '
								field={field}
								form={form}
							/>
						)}
					</Field>
				</div>) : null}
				<Field name='agreementName'>
					{({ field, form }) => (
						<TextInput
							required={true}
							type='text'
							label='Document name '
							field={field}
							classNameInput={{ root: classes.ContainerRow }}
							form={form}
							placeholder={"Document name"}
							tooltip={tooltips['Document Name']}
						/>
					)}
				</Field>
				<Line className={classes.Line} />
				<div className={`${classes.ContainerRow} ${classes.Select}`}>
					<Field name='company.id'>
						{({ field, form }) => (
							<ClaraSelect
								required={true}

								field={field}
								form={form}

								label={"Select company "}
								lists={this.getGroupCompaniesToCompany()}
								mode={"classic"}
								callbacks={{
									createValues: this.handleGroupCompanyCreate,
									updateValues: this.selectGroupCompany
								}}
								placeholder={"Select from the list"}
								tooltip={tooltips['Select Company']}
							/>
						)}
					</Field>
				</div>
				{
					this.state.groupCompanySelected ?
						<React.Fragment>
							<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
								<h1>Company Details</h1>
								<p>Please verify or update the company's information below</p>
							</div>
							<Field name='company.name'>
								{({ field, form }) => (
									<TextInput
										classNameInput={{ root: classes.ContainerRow }}

										required={true}
										type='text'
										label='Company name '
										field={field}
										form={form}
										placeholder={"Company name"}
										tooltip={tooltips["IP company's Name"]}
									/>
								)}
							</Field>
							<div className={` ${classes.Select}`}>
								<Field name='company.country'>
									{({ field, form }) => (
										<ClaraCountrySelector
											isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
											jurisdictionsEnabled={true}
											countryField={'company.country'}
											label={'Country of incorporation '}
											jurisdictionField={'company.jurisdiction'}
											jurisdictionLabel={'Jurisdiction '}
											returnCountryAsString={true}
											otherJurisdictionField={'company.otherJurisdiction'}
											setFieldValue={form.setFieldValue}
											initialValues={{
												jurisdiction: _.get(form.values, 'company.jurisdiction')
											}}
											additionalStyle={'ClaraCountrySelectorModal'}
											tooltips={{
												country: tooltips["Company's Country"],
												jurisdiction: tooltips["Company's Jurisdiction"],
												other: tooltips['Other Jurisdiction']
											}}
										/>
									)}
								</Field>
							</div>
							<div className={` ${classes.Select}`}>
								<Field name='company.registeredAddress.country'>
									{({ field, form }) => (
										<ClaraCountrySelector
											isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
											jurisdictionsEnabled={false}
											countryField={'company.registeredAddress.country'}
											label={'Registered address '}
											returnCountryAsString={true}
											setFieldValue={form.setFieldValue}
											additionalStyle={'ClaraCountrySelectorModal'}
											tooltips={{
												country: tooltips["Company's Registered Address - Country"]
											}}
										/>
									)}
								</Field>
							</div>
							<div className={classes.ContainerRow}>
								<Field name='company.registeredAddress.street'>
									{({ field, form }) => (
										<TextAreaInput
											type='text'
											label={' '}
											field={field}
											form={form}
											placeholder={"Address"}
											tooltip={tooltips["Company's Registered Address - Address"]}
										/>
									)}
								</Field>
							</div>
							<Field name='company.registeredAddress.city'>
								{({ field, form }) => (
									<TextInput
										type='text'
										label=' '
										field={field}
										classNameInput={{ root: classes.ContainerRow }}
										form={form}
										placeholder={'City'}
										tooltip={tooltips["Company's Registered Address - City"]}
									/>
								)}
							</Field>
							<Field name='company.registeredAddress.state'>
								{({ field, form }) => (
									<TextInput
										type='text'
										label=' '
										field={field}
										form={form}
										placeholder={'State / Region'}
										classNameInput={{ root: classes.ContainerRow }}
										tooltip={tooltips["Company's Registered Address - State"]}
									/>
								)}
							</Field>
							<Field name='company.registeredAddress.zipCode'>
								{({ field, form }) => (
									<TextInput
										type='text'
										label=' '
										field={field}
										form={form}
										placeholder={"Post code"}
										classNameInput={{ root: classes.ContainerRow }}
										tooltip={tooltips["Company's Registered Address - Post Code"]}
									/>
								)}
							</Field>
							<Field name='company.placeOfBusiness_error'>
								{({ field, form }) => (
									<React.Fragment>
										<span
											hasError={_.get(form.errors, "company.placeOfBusiness.city") || _.get(form.errors, "company.placeOfBusiness.state") ? "true" : "false"}>
											<SelectStateCity
												selectStateLabel={'State of principal office'}
												selectCityLabel={'City of principal office'}
												showState={true}
												modal={true}
												defaultState={form?.values?.company?.placeOfBusiness?.state}
												tooltips={{
													city: tooltips["Company's City Of Principal Office"],
													state: tooltips["Company's State Of Principal Office"]
												}}
												defaultCity={form?.values?.company?.placeOfBusiness?.city}
												onChangeState={(value) => {
													form.setFieldValue('company.placeOfBusiness.state', value)
												}}
												onChangeCity={(value) => {
													form.setFieldValue('company.placeOfBusiness.city', value)
												}}
												errorMessage={_.get(form.errors, "company.placeOfBusiness.city") || _.get(form.errors, "company.placeOfBusiness.state")}
											/>
										</span>
									</React.Fragment>
								)}
							</Field>
							<Line className={classes.Line} />
							<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
								<h1>Directors</h1>
								<p>Select the directors that will sign this Joint Written Consent of Stockholders and
									Directors in Lieu of a Meeting connected to this Equity Incentive Plan. You can add
									them individually, or all together, or add new directors.</p>
								<p>The Equity Incentive Plan requires the approval of all directors.</p>

							</div>
							<Field name='company.authorizedSignatories_error'>
								{({ field, form }) => (
									<React.Fragment>
										<span
											hasError={_.get(form.errors, "company.authorizedSignatories") ? "true" : "false"}>
											<AuthorisedSignatories
												selectInputLabel={'Select director'}
												list={this.props.stakeholderList}
												modal={true}
												fieldArrayName={'company.authorizedSignatories'}
												idGroupCompany={this.state.groupCompanySelected.id}
												handleAddListStakeholder={this.handleAuthorizedSignatoryUpdate}
												errorMessage={_.get(form.errors, "company.authorizedSignatories")}
											/>
										</span>
									</React.Fragment>
								)}
							</Field>
							<Line className={classes.Line} />
							<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
								<h1>Stockholders</h1>
								<p>Select the stockholders that will sign this Joint Written Consent of Stockholders and
									Directors in Lieu of a Meeting.</p>
								<p>The Equity Incentive Plan requires the approval of at least a majority of all stockholders.</p>

							</div>
							<Field name='stakeholders_error'>
								{({ field, form }) => (
									<React.Fragment>
										<span hasError={_.get(form.errors, "stakeholders") ? "true" : "false"}>
											<SelectStakeholders
												selectInputLabel={'Select stockholders'}
												list={this.props.stakeholderList}
												fieldArrayName={'stakeholders'}
												modal={true}
												tooltips={{ corporate: { title: tooltips["Company's Authorized Signatory Title"] } }}
												showCorporate={{ title: true }}
												idGroupCompany={this.state.groupCompanySelected.id}
												handleAddListStakeholder={this.handleAuthorizedSignatoryUpdate}
												errorMessage={_.get(form.errors, "stakeholders")}
											/>
										</span>
									</React.Fragment>
								)}
							</Field>
						</React.Fragment>
						:
						null
				}

				{this.state.groupCompanySelected && <>
					<Line className={classes.Line} />
					<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
						<h1>Shares allocated to the plan</h1>
						<p>The shares that your company will allocate to this Equity Incentive Plan.</p>
					</div>

					<Field name='terms.equity.sharesAmount'>
						{({ field, form }) => (
							<TextInput
								type='number'
								label='Total of shares allocated to the plan'
								field={field}
								classNameInput={{ root: classes.ContainerRow }}
								form={form}
								placeholder={'Number'}
								required={true}
							/>
						)}
					</Field>
					<div className={classes.ContainerRow}>
					<Field name='terms.equity.eipYear'>
						{({ field, form }) => (
							<TextInput
								required={true}
								field={field}
								form={form}
								tooltip={tooltips["Company's Equity Incentive Plan Year"]}
								type={'date'}
								modal={true}
								placeholder={'YYYY'}
								label={"Company's equity incentive plan year"}
								calendarInputProps={{
									type: 'year'
								}}
							/>
						)}
					</Field>
					</div>
					{/*
					<Line className={classes.Line}/>
					<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
						<h1>Effective date of the plan</h1>
						<p>The date that the plan comes to effect.</p>
					</div>

					<Field name='terms.effectiveDate'>
						{({field, form}) => (
							<TextInput
								required={true}
								field={field}
								form={form}
								type={'date'}
								className={classes.inputDateEdit}
								placeholder={'dd/mm/yyyy'}
								label={"Effective date"}
							/>
						)}
					</Field>
*/}

				</>}

				{formType === 'uploadToEdit' ? <div>
					<Field name='confirmSameData'>
						{({ field, form }) => (
							<Checkbox
								className={classes.sameDataCheckbox}
								field={field}
								validate={isRequired('This field is required')}
								form={form}
								required={true}
								label='All document details are verified and correct.'
							/>
						)}
					</Field>
				</div> : null}
			</div>

		);
	}

}

export default EquityIncentivePlan;

import React from 'react';
import ClaraLayout from "../../../layouts/Clara/ClaraLayout"
import BlueScreenLayout from "../../../layouts/BlueScreen/BlueScreenLayout";
import AuditLogsView from "./AuditLogs/AuditLogsView"
import Text from "../../../components/text/Text/Text";
import VersionManagerView from "./VersionManager/VersionManagerView"

import ACTIONS from "../../../constants/actions"
import PAGES from "../../../constants/pages";
const routes = [
  {
    path: PAGES.auditLogs.path,
    exact: true,
    component: <AuditLogsView />,
    breadCrumbs: { // BreadCrums Config
      reset:false, //Reset breadCrumbs
       add:true,// Add path to breadCrumbs
      label: <Text uuid={"BREADCRUMS_VIEW_AUDIT_LOGS"}  />, //Label to show
    },
    layout: ClaraLayout,
    propsLayout: {
      startupMenu:true,
      userMenu:true,
      fullMenu:true,
      showBreadCrumbs:true,
      showAssistance:false,
      cantBack: true
    },
    action: ACTIONS.VIEW_AUDIT_LOGS,
  },
  {
    path: PAGES.versionManager.path,
    exact: true,
    component: <VersionManagerView />,
    layout: BlueScreenLayout,
    propsLayout: {
      
    },
    action: ACTIONS.ENVIRONMENT_CONFIG,
  },
 
];

export default routes;

import {RoleFields} from "../../../modules/stakeholder/graphql/typePolicies";
import {NoticesFragment, AuthorizedSignatoryFragment} from "../../../modules/startup/Stakeholder/graphql/fragments";

const mutationIndividual = `mutation upsertComponentStakeholder(
  $startupId: ID!
  $stakeholderId: ID!
  $stakeholder: ComponentStakeholderInputType!
) {
  upsertComponentStakeholder(startupId: $startupId ,stakeholderId: $stakeholderId ,stakeholder: $stakeholder ) {
    fullName
  }
}`;

const mutationCompany = `mutation upsertComponentStakeholder(
  $startupId: ID!
  $stakeholderId: ID!
  $stakeholder: ComponentStakeholderInputType!
) {
  upsertComponentStakeholder(startupId: $startupId ,stakeholderId: $stakeholderId ,stakeholder: $stakeholder ) {
    fullName
  }
}`;

const getGroupCompaniesWrong = `
${RoleFields}
${NoticesFragment}
query getGroupCompanies($startupId: ID!){ 
    getGroupCompanies(startupId: $startupId){
    managedByClara
    authorizedSignatories {
      ...RoleFields
      stakeholder {
        title
        id
        email
        fullName
        address {
          city
          state
          country {name code}
          zipCode
          street
      }
        roles { id ...RoleFields 
          }
        }
      }
    id
    placeOfBusiness{
      city
      state
    }
    kind
    avatar
    name
    type
    jurisdiction
    country { name code alpha3}
    registeredNumber
    transactionCurrency
    isHoldingCompany
    hasAgreements
    documents {
      name
      id
      kind
      status
      type{
        label
        code
      }
      groupCompanies{
        groupCompany{
        id
          avatar
          name
        }
        signedBy{
        id
        }
      }
      stakeholders{
      id
        avatar
        fullName
      }
      subCategories
      file {
        id
        name
        size
        type
      }
    } # documents
    registeredAddress {
      city
      state
      street
      zipCode
      country {name code alpha3}
    }
    advanceCapTable{
      shareClasses{
        id 
        name
        shareIssuances{
          id
			issuedDate
			customId
			quantity
			sharePrice{
        amount
        currency
      }
      shareClass{
        id
        defaultIdPrefix
        name
      }
			boardApprovalDate
			holder{
        ... on StakeHolder{
          id
          fullName
        }
        ... on GroupCompany{
          id
        }
      }
	  vestingSchedule{
        vestingTerm{
          id
			name
			description
			vestingType
			allocationType
			standardTerm{
        vestingPeriod
			vestingFrequency
      			isThereCliff
			cliffPeriod
      }
        }
			vestingStartDate
      }
			shareNumbersIssued{
        startingShareNumber
        endingShareNumber
      }
			status{
        state 
        generated
      }
        }
      }
    }
    recipientNotice {...NoticesFragment}
  }
}`;

const query = `
${NoticesFragment}
${AuthorizedSignatoryFragment}
{getStakeholder(startupId: "{{startupId}}", stakeholderId: "{{stakeholderId}}") {
    id
    avatar
    fullName
    email
    passportNumber
    nationality {code name}
    authorizedSignatory {...AuthorizedSignatoryFragment}
    type
    jurisdiction
    registeredNumber
    hasAgreements
    isAnEntity
    documents {
      name
      id
      kind
      status
      type{
        label
        code
      }
      groupCompanies{
        groupCompany{
          avatar
          name
        }
      }
      stakeholders{
        avatar
        fullName
      }
       subCategories
      
      file {
        id
        name
        size
        type
        __typename
      }
      __typename
      
    }
    address {
      street
      city
      state
      country {alpha3 name code}
      zipCode
    }
    recipientNotice {...NoticesFragment}
    convertibles{
      id
      amount
      currency
      discountRate
      valuationCap
      interestRate
      issuedDate
      groupCompany{
        id
        name
        avatar
      }
      
    }
    warrants {
      id
      amount
      valuation  
       currency
       issuedDate
       documents{
         name
         id
         status
       }
       groupCompany{
         id
         name
         avatar
       }
     }
    equity {
      id      
      groupCompany{
        name  
        id
        avatar
        
      }
      type
      amount
      price
      issuedDate
      documents{
        id
        name
        status
      }
      shareClass{
        id
        name
        defaultShare
      }
      shareIncentivePlan{
        id
        name 
      }
      currency
    }
    roles {
      name
      groupCompanies
      ...RoleFields
    }

    groupCompanyPositions {
    department {
	  id
    }
    position {
        id
        name
        isDefault
        responsibilities {
          id
          name
          isDefault
          text
        }
      }
      
      groupCompany {
        id
        name
      }
      department {
        id
        name
        isDefault
      } 
    }
  }`;

const eliminate = `mutation deleteStakeholder(
    $startupId: String!
    $stakeholderId: String!
){
    deleteStakeholder(
        startupId: $startupId  
        stakeholderId: $stakeholderId
    ){fullName}
}`;

const update = `
mutation updateStakeholder(
  $startupId: String
  $stakeholderId: String
  $stakeholder: StakeholderInputType
) {
  updateStakeholder(
    startupId: $startupId
    stakeholderId: $stakeholderId
    stakeholder: $stakeholder
  ) {
    id
    avatar
    fullName
    roles {
      name
      id
      groupCompany{
        id
      }
      stakeholder{
        id
      }
      stakeHolderId
      agreements {
        signedAs
        id
        status
        groupId
        date
        name
        file {
          name
          payload
        }
      }
    }
    createdAt
  }
}
`;

export {
  mutationIndividual,
  mutationCompany,
  getGroupCompaniesWrong,
  query,
  update,
  eliminate
}

import React from 'react'
import * as Yup from 'yup'
import Form from '../../../../components/forms/Form/Form'
import { StepProps } from "../../../generic/components/Wizard/Step"
import TemplateWizard from '../../../generic/templates/Modal/TemplateWizard'
import useTranslate from '../../../generic/hooks/useTranslate'
import TextInput from '../../../../components/inputs/Text/TextInput'
import { Constants } from '../../../../v1/utils'
import ControllerInput from '../../../generic/components/Inputs/ReactHookForm/ControllerInput'
import Typography from '../../../generic/components/Texts/Typography'
import TemplateLabel from '../../../generic/templates/Label'
import SubmitButton from '../../../generic/components/Buttons/SubmitButton'
import FormProps from '../../../generic/forms/interfaces/FormProps';
import TemplateFormDefault from "../../../generic/templates/Modal";

import cls from './Form.module.scss';
import { useDispatch } from 'react-redux'
import { addAssistantText, clearAssistatText } from '../../../generic/store/action'


const schema = Yup.object().shape({
    registeredNumber: Yup.string().required('This field is required').nullable(),
    type: Yup.string().required('This field is required').nullable(),
    linkedinURL: Yup.string().matches(Constants.REGEXP.URL_LINKEDIN,{message:"Please enter a valid LinkedIn URL' ",excludeEmptyString:true}).nullable()
})



const FormCompanyMoreDetails: React.FC<FormProps> = ({
    initialValues,
    onCompleteSubmit,
    Template = TemplateFormDefault,
    buttons = {},
    buttonSubmitProps,
    propsTemplate
}: FormProps) => {



    const { translate } = useTranslate()
    const refForm = React.useRef(null);

    const handleSubmit = async values => {
        onCompleteSubmit && await onCompleteSubmit(values);
    }

    const dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(addAssistantText(`You'll typically find your company number on your Certificate of Incorporation.`));
        dispatch(addAssistantText(`A company type is a label given to a company under the laws of its jurisdiction of incorporation, for example, private company limited by shares, limited liability company, c corp, exempted company, joint stock company or private company.`));
        return () => {
            dispatch(clearAssistatText())
        }
    }, [])

    return (
        <Form
            defaultValues={initialValues}
            schema={schema}
            onSubmit={handleSubmit}
            ref={refForm} optionsForm = {{mode:"onChange"}}
        >
            <Template buttons={{ ...buttons, submit: <SubmitButton  {...buttonSubmitProps}>Add</SubmitButton> }} props={propsTemplate}>

                <TemplateLabel
                    label={translate('MODULES.MAP.WIZARD_COMPANY_DETAIL_MORE_INFO_FORM_FIELD_NUMBER')}
                    className={cls.TemplateLabel}
                    isRequired
                >
                    <ControllerInput
                        render={TextInput}
                        name='registeredNumber'
                        defaultlabel={''}
                        placeholder='Include number and letters only'
                    />
                </TemplateLabel>

                <TemplateLabel
                    label={translate('MODULES.MAP.WIZARD_COMPANY_DETAIL_MORE_INFO_FORM_FIELD_TYPE')}
                    className={cls.TemplateLabel}
                    isRequired
                >
                    <ControllerInput
                        render={TextInput}
                        name='type'
                        defaultlabel={''}
                        placeholder='Type here'
                    />
                </TemplateLabel>

                <TemplateLabel
                    label={translate('MODULES.MAP.WIZARD_COMPANY_DETAIL_MORE_INFO_FORM_FIELD_LINKED_IN_URL')}
                    className={cls.TemplateLabel}
                >
                    <ControllerInput
                        render={TextInput}
                        name='linkedinURL'
                        defaultlabel={''}
                        placeholder='Type or paste link here'
                    />
                </TemplateLabel>

            </Template>

        </Form>
    )
}

export default FormCompanyMoreDetails


import React, { useEffect } from 'react'
import { addAssistantText, clearAssistatText } from '../../../../../../modules/generic/store/action'
import { useParams } from "react-router-dom"
import Button from "../../../../../generic/components/Buttons/Button"
import useCustomMachine from '../../../../../generic/hooks/useCustomMachine'
import useTranslate from '../../../../../generic/hooks/useTranslate'
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard"
import useSession from "../../../../../session/hooks/useSession"
import FormSelectStakeholdersDirectors from '../../../Forms/FormSelectDirectors'
import useUpsertFormationCompany from "../../../graphql/mutations/useUpsertFormationCompany"
import _ from 'lodash'
import { useDispatch } from "react-redux";

const SecondStep = () => {

  const { id: _id } = useParams<{ id: string }>();

  
  const { translate } = useTranslate();
  const { next, send, paramsData } = useCustomMachine();
  const { startupId } = useSession()
  const [mutation] = useUpsertFormationCompany({}, {});
  const dispatch = useDispatch();
  const id =  paramsData?.idGroupCompany ?? _id;
  useEffect(() => {
    dispatch(
      addAssistantText(
        isCayman ? translate('MODULES.GROUP_COMPANY.CONFIRM_SHAREHOLDERS_DIRECTORS.SECOND_STEP.TOOLTIP_KY') : translate('MODULES.GROUP_COMPANY.CONFIRM_SHAREHOLDERS_DIRECTORS.SECOND_STEP.TOOLTIP')
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, []);

  const handleCompleteSubmit = async (values) => {
    const variables = {
      groupCompanyId: id,
      startupId,
      groupCompanyData: {
        formationData: {
          directorsAndShareholdersCompleted: true,
          directorToSignShareCertificate: values.director.id
        }
      }
    }
    await mutation(variables)
    next(values)
  }

  const handleClose = () => {
    send('CANCEL')
  }

  const isCayman = _.get(paramsData, "useThisJurisdiction","") === "CAYMAN";


  const handlePrevious = () => {
    send("PREVIOUS");
  };

  return (
    <FormSelectStakeholdersDirectors
      onCompleteSubmit={handleCompleteSubmit}
      Template={TemplateWizard}
      groupCompanyId={id}
      propsTemplate={{
        title: isCayman ? translate('MODULES.GROUP_COMPANY.CONFIRM_SHAREHOLDERS_DIRECTORS.SECOND_STEP.TITLE_KY') : translate('MODULES.GROUP_COMPANY.CONFIRM_SHAREHOLDERS_DIRECTORS.SECOND_STEP.TITLE'),
        subTitle: isCayman ? translate('MODULES.GROUP_COMPANY.CONFIRM_SHAREHOLDERS_DIRECTORS.SECOND_STEP.SUBTITLE_KY'): translate('MODULES.GROUP_COMPANY.CONFIRM_SHAREHOLDERS_DIRECTORS.SECOND_STEP.SUBTITLE'),
      }}
      buttons={{ 
        cancel: <Button onClick={handleClose} variant="secondary">Cancel</Button>,
        previous: <Button onClick={handlePrevious}>Previous</Button>,
      }}
    />
  )
}
export default SecondStep
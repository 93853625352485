import React, {useEffect, useState} from 'react';
import classes from "./ClaraCheckForm.module.scss"
import {Avatar, DeleteForm, Loading, Text} from "../../index";
import ServerConnect from '../../../utils/ServerConnect';
import {LayoutForm} from '../../../containers';
import notification from "../../../utils/notification";
import _ from "lodash";


const ClaraCheckForm = ({ form, field, className, list, values, multiForm, optionsList, optionsForm, optionsSelect, optionsOperation, optionsView, children, initialValues, openForm, addForm, cbClose, cbSaveForm }) => {
    const defaultType = "DEFAULT_TYPE";
    const [elementSelect, setElementSelect] = useState({
        data: {},
        types: defaultType
    });
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentList, setCurrentList] = useState(null);
    const [news, setNews] = useState([]);
    const [row, setRow] = useState(null);
    const [indexDelete, setIndexDelete] = useState(null);
    const [prevList, setPrevList] = useState(null);
    const [showChildren, setShowChildren] = useState(false);
    const [options, setOptions] = useState({
        list: {
            fieldId: "id",
            fieldLabel: "label",
        },
        form: {
            titleKey: "",
            educationId: "",
            muttation: null,
            paramsMuttaion: null,
            getVariablesMutation: null,
            succesNotification: "NOTIFICATION_SUCCESS",
            errorNotification: "NOTIFICATION_BAD",
            getIdFromMuttation: null
        },
        select: {
            auto: false,
            canSelect: false
        },
        operation: {
            canAdd: true,
            canEdit: true,
            canRemove: true,
            canEditOnlyNews: false,
            canRemoveOnlyNews: false
        },
        view: {
            recordsByLine: 7,
            textKeyEdit: "TEXT_EDIT"
        }

    });
    const publicValues = (values) => {
        form.setFieldValue(field.name, values);
    }
    const handleSubmit = (values, elementType) => {
        setShowChildren(true);
        setLoading(true)

        const getVariablesMutation = options.form[elementType].getVariablesMutation;
        const paramsMutation = options.form[elementType].paramsMuttaion;
        const mutation = options.form[elementType].muttation;

        let paramToMutation = getVariablesMutation ? getVariablesMutation(paramsMutation, values) : { ...values, ...paramsMutation };

        ServerConnect.graphQlMutation(mutation, paramToMutation).then(result => {
            const getIdFromMutation = options.form[elementType].getIdFromMuttation;
            notification.sendNotificationTranslate(options.form[elementType].succesNotification, "success", 5000);

            let newElement = { ...values };
            const newId = getIdFromMutation(result)
            newElement[options.list[elementType].fieldId] = newId;
            const indexCurrentList = _.findIndex(currentList, (e) => {
                return e.data[options.list[elementType].fieldId] === newId;
            });
            let currentNews = news;
            if (indexCurrentList < 0) {
                if (options.select.addToList) {
                    currentList.push({
                        data: newElement,
                        type: elementType
                    });
                    if (options.select.auto) {
                        let val = field.value;
                        if (!val) { val = [] };
                        val.push(newId);
                        publicValues(val)
                    }
                    currentNews.push(newId);
                }
            } else {
                currentList[indexCurrentList] = {
                    data: newElement,
                    type: elementType
                }
            }
            setLoading(false);
            setNews(currentNews)

            setCurrentList(currentList);
            handleClose();
            if (cbSaveForm) {
                cbSaveForm(currentList);
            }
        }).catch(err => {

            notification.sendNotificationTranslate(err ? err : options.form[elementType].errorNotification, "error", 5000);
            console.log("NOTIFICATION_BAD:", err);
            setLoading(false);
        });
    }
    const handleClose = (e) => {
        setElementSelect({
            data: {},
            types: defaultType
        });
        if (cbClose) {
            cbClose(currentList);
        }
        setShowModal(false);

    }
    const handleAdd = () => {
        let type = "";
        if (openForm && addForm) {
            type = addForm;
        } else {
            type = defaultType;
        }
        setElementSelect({
            type: type,
            data: {}
        });
        setShowModal(!showModal)
    }
    const handleEdit = (element) => {
        setElementSelect(element);
        setShowModal(true);
    }
    const handleRemove = (row, index) => {
        setIndexDelete(index);
        setRow(row);
    }
    const handleClickAvatar = (element, isSelected) => {
        if (options.select.canSelect) {
            let val = field.value;
            if (!val) { val = [] };
            if (isSelected) {
                let canValidationDeselected = _.get(options, "select.canValidationDeselected", () => { return true });
                if (canValidationDeselected(element.data, news, field.value)) {
                    const index = _.findIndex(val, (e) => { return e === _.get(element.data, options.list[element.type].fieldId); });
                    val.splice(index, 1);
                }
            } else {
                let canValidationSelect = _.get(options, "select.canValidationSelect", () => { return true });
                const value = _.get(element.data, options.list[element.type].fieldId);
                if (canValidationSelect(value, news, field.value)) {
                    val.push(value);
                }

            }
            publicValues(val)


        }
    }
    const handleCloseDelete = () => {
        setIndexDelete(null)
        setRow(null);
    }
    const getCurrentList = () => {
        return currentList ? currentList : [];
    }
    const getWithPercent = (cant) => {
        return Math.trunc(100 / cant).toString() + "%";
    }
    const evaluateOnlyNews = (element, isEdit, isRemove) => {
        const canEditOnlyNews = _.get(options, `operation.canEditOnlyNews`, false);
        const canRemoveOnlyNews = _.get(options, `operation.canRemoveOnlyNews`, false);

        if (isEdit && !canEditOnlyNews) {
            return true;
        }
        if (isRemove && !canRemoveOnlyNews) {
            return true;
        }


        const index = _.find(news, (newElementId) => {
            return (newElementId == _.get(element.data, options.list[element.type].fieldId))
        })
        return index;
    }
    const deleteRow = (params) => {
        return new Promise((resolve, reject) => {
            let elementType = currentList[params.index].type;
            const getVariablesMutationRemove = options.form[elementType].getVariablesMutationRemove;
            const paramsMuttaion = options.form[elementType].paramsMuttaion;
            const muttationRemove = options.form[elementType].muttationRemove;
            const newParams = _.get(currentList, `${params.index}.data`, {});
            let paramToMuttation = getVariablesMutationRemove ? getVariablesMutationRemove({ ...newParams, ...paramsMuttaion }, values) : { ...values, ...paramsMuttaion };
            let val = field.value;
            let idNewsDelete = _.get(currentList[params.index].data, options.list[elementType].fieldId)
            let indexNewsDelete = _.findIndex(news, id => id === idNewsDelete);
            let indexValDelete = _.findIndex(val, id => id === idNewsDelete);
            if (muttationRemove) {
                setLoading(true)
                ServerConnect.graphQlMutation(muttationRemove, paramToMuttation).then(result => {
                    let newsAux = news;
                    if (indexNewsDelete >= 0) {
                        newsAux.splice(indexNewsDelete, 1);
                        val.splice(indexValDelete, 1)
                        setNews(newsAux);
                        publicValues(val)
                    }

                    currentList.splice(params.index, 1)
                    setCurrentList(currentList);
                    resolve();
                    setLoading(false)
                    if (cbSaveForm) {
                        cbSaveForm();
                    }
                    setIndexDelete(null);
                    notification.sendNotificationTranslate(options.form[elementType].succesNotification, "success", 5000);
                }).catch(err => {
                    notification.sendNotificationTranslate(options.form[elementType].errorNotification, "error", 5000);

                    setLoading(false);
                    handleClose();
                });
            } else {

                let newsAux = news;
                newsAux.splice(indexNewsDelete, 1);
                setNews(newsAux);
                setIndexDelete(null);
                val.splice(indexNewsDelete, 1)
                publicValues(val)
                currentList.splice(indexNewsDelete, 1)
                setCurrentList(currentList);
                resolve();
            }


        });
    }
    useEffect(() => {
        if (openForm) {
            handleAdd();
        }
    }, [openForm])
    useEffect(() => {
        let calculateOptions = options;
        if (!multiForm) {
            optionsList = { [defaultType]: optionsList }
            optionsForm = { [defaultType]: optionsForm }

        }
        calculateOptions.list = {};
        _.forEach(optionsList, (option, key) => {
            calculateOptions.list[key] = {};
            calculateOptions.list[key].fieldId = _.get(option, "fieldId", "id");
            calculateOptions.list[key].fieldLabel = _.get(option, "fieldLabel", "label");
        })
        calculateOptions.form = {};
        _.forEach(optionsForm, (option, key) => {
            calculateOptions.form[key] = {};
            calculateOptions.form[key].titleKey = _.get(option, "titleKey", "");
            calculateOptions.form[key].educationId = _.get(option, "educationId", "");
            calculateOptions.form[key].muttation = _.get(option, "muttation", "");
            calculateOptions.form[key].muttationRemove = _.get(option, "muttationRemove", "");
            calculateOptions.form[key].paramsMuttaion = _.get(option, "paramsMuttaion", {});
            calculateOptions.form[key].getVariablesMutation = _.get(option, "getVariablesMutation", null);
            calculateOptions.form[key].getVariablesMutationRemove = _.get(option, "getVariablesMutationRemove", null);
            calculateOptions.form[key].succesNotification = _.get(option, "succesNotification", "NOTIFICATION_SUCCESS");
            calculateOptions.form[key].errorNotification = _.get(option, "errorNotification", "NOTIFICATION_BAD");
            calculateOptions.form[key].getIdFromMuttation = _.get(option, "getIdFromMuttation", null);
        })

        calculateOptions.select.auto = optionsSelect.auto !== true ? false : true;
        calculateOptions.select.autoLoaderData = optionsSelect.autoLoaderData ? optionsSelect.autoLoaderData : false;
        calculateOptions.select.canSelect = optionsSelect.canSelect !== false ? true : false;
        calculateOptions.select.canValidationSelect = _.get(optionsSelect, "canValidationSelect");
        calculateOptions.select.canValidationDeselected = _.get(optionsSelect, "canValidationDeselected");
        calculateOptions.select.addToList = optionsSelect.addToList !== false ? true : false;
        

        calculateOptions.operation.canAdd = optionsOperation.canAdd !== false ? true : false;
        calculateOptions.operation.canEdit = optionsOperation.canEdit !== false ? true : false;
        calculateOptions.operation.canRemove = optionsOperation.canRemove !== false ? true : false;
        calculateOptions.operation.onlyNews = optionsOperation.onlyNews !== true ? false : true;
        calculateOptions.operation.canEditOnlyNews = optionsOperation.canEditOnlyNews !== true ? false : true;
        calculateOptions.operation.canRemoveOnlyNews = optionsOperation.canRemoveOnlyNews !== true ? false : true;

        calculateOptions.view.recordsByLine = _.get(optionsView, "recordsByLine", 7);
        calculateOptions.view.textKeyEdit = _.get(optionsView, "textKeyEdit", "TEXT_EDIT");



        if (!_.isEqual(calculateOptions, options)) {
            setOptions(calculateOptions)
        }

        if (!currentList || !_.isEqual(prevList, list)) {
            let listLocal = [];
            if (!multiForm) {
                _.forEach(list, (element) => {
                    listLocal.push({
                        type: defaultType,
                        data: element
                    })
                });
            } else {
                listLocal = list;
            }
            setCurrentList(listLocal);
            if (calculateOptions.select.autoLoaderData === true) {
                let val = _.map(listLocal, (element => {
                    return _.get(element.data, calculateOptions.list[element.type].fieldId);
                }));
                publicValues(val)
            } else if (calculateOptions.select.autoLoaderData && typeof calculateOptions.select.autoLoaderData === "function") {
                let valAux = [];
                _.forEach(listLocal, ((element, i) => {
                    if (calculateOptions.select.autoLoaderData(element.data, i)) {
                        valAux.push(_.get(element.data, calculateOptions.list[element.type].fieldId));
                    }
                }));
                publicValues(valAux)
            }
            setPrevList(list)
        }
    })


    const getLabel = (element) => {
        if (typeof options.list[element.type].fieldLabel === "function") {
            return options.list[element.type].fieldLabel(element.data)
        }
        return _.get(element.data, options.list[element.type].fieldLabel, "label");

    }
    return (
        <div className={`${_.get(className, "root")} ${classes.ClaraCheckForm} `} >
            {getCurrentList().map((element, i) => {
                const idFined = _.find(field.value, (value) => {
                    return _.get(element.data, options.list[element.type].fieldId) == value
                });
                const classButtonSelected = idFined ? classes.selected : classes.unselected;
                return (
                    <React.Fragment key={i}>
                        <div className={`${classes.Circle} ${classes.ContentAvatar} ${_.get(className, "ContentAvatar")}`} style={{ width: getWithPercent(options.view.recordsByLine) }}>
                            <button className={`${classButtonSelected}`} onClick={() => { handleClickAvatar(element, idFined) }}>
                                <Avatar name={getLabel(element)} showLabel={false} size={50} className={classes.Avatar}> </Avatar>
                            </button>
                            {options.operation.canEdit || options.operation.canRemove ? (
                                <div className={classes.Options}>
                                    {options.operation.canEdit && evaluateOnlyNews(element, true, false) ? (<a onClick={() => { handleEdit(element) }}> <Text uuid={options.view.textKeyEdit} /> </a>) : null}
                                    {options.operation.canEdit && options.operation.canRemove && evaluateOnlyNews(element, true, false) && evaluateOnlyNews(element, false, true) ? (<span> / </span>) : null}
                                    {options.operation.canRemove && evaluateOnlyNews(element, false, true) ? (<a onClick={() => { handleRemove(element.data, i) }}><Text uuid={"TEXT_REMOVE"} /></a>) : null}
                                </div>
                            ) : null}
                        </div>
                    </React.Fragment>
                )
            })}
            {options.operation.canAdd && !multiForm ? (
                <div className={`${classes.Circle} ${classes.ContentButton}`} style={{ width: getWithPercent(options.view.recordsByLine) }}>
                    <button className={` ${classes.Button}`} onClick={handleAdd}><i className={"icon-plus"} /> </button>
                </div>
            ) : null}
            {
                React.Children.toArray(children).map((e, i) => {
                    let elementTypeChild = null;
                    let selectElement = elementSelect.data;
                    if (openForm && addForm) {
                        elementTypeChild = addForm;
                        selectElement = {}
                    } else if (openForm) {
                        elementTypeChild = defaultType;
                    } else {
                        elementTypeChild = elementSelect.type;
                    }
                    let showForm = multiForm ? e.props.type == elementTypeChild : true;
                    let initialValuesAux = initialValues && initialValues[elementTypeChild] ? initialValues[elementTypeChild] : initialValues;
                    return (
                        <React.Fragment key={i}>
                            {(openForm || showModal) && showForm ? (
                                <div className={`${classes.ModalCheckForm}`}>
                                    <LayoutForm title={_.get(options, `form[${elementTypeChild}].titleKey`)} style={{position:"relative",right:"50px"}} leftEducationId={_.get(options, `form[${elementTypeChild}].educationId`)} handleClose={handleClose}>
                                        <Loading show={loading} showChildren={showChildren}>

                                            {
                                                React.cloneElement(e, { handleSubmit: (values) => { handleSubmit(values, elementTypeChild) }, initialValues: { ...initialValuesAux, ...elementSelect.data } })
                                            }

                                        </Loading>
                                    </LayoutForm>
                                </div>
                            ) : null}
                        </React.Fragment>
                    )
                })
            }

            {
                indexDelete !== null ?
                    <DeleteForm
                        show={indexDelete !== null}
                        deleteEntity={deleteRow}
                        paramsDeleteEntity={{ index: indexDelete }}
                        title={`Delete...`}
                        options={[]}
                        closeManager={false}
                        handleClose={handleCloseDelete}
                    ></DeleteForm>
                    : null
            }
        </div>
    );

}
export default ClaraCheckForm

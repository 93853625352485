import React, { useContext } from 'react';
import FormContext from "../../../forms/Form/FormContext";
import { useFormContext } from 'react-hook-form'
import Button from "../Button/Button";
import classes from './classes.module.scss'

export const DeleteButtonForm = ({ isSubmitting, handleDisabled, ...props }) => {
    const formState = useContext(FormContext);
    const { isSubmitting: isSubmittingForm } = formState;

    const disabledButton = () => {
        // return handleDisabled ? handleDisabled(formState) : (isSubmitting || !isDirty || !isValid || !_.isEmpty(methods.errors)) ;
        return handleDisabled ? handleDisabled(formState) : false;
    };
    return (
        <React.Fragment>
            <Button type={"submit"} data-cy="submit"
                className={`${classes.DeleteButton} ${props.className}`}
                loading={isSubmitting || isSubmittingForm}
                disabled={disabledButton()} {...props} > {props.children} </Button>
        </React.Fragment>
    )
};

export const DeleteButton = ({ ...props }) => {
    return (
        <>
            <Button type={"submit"} data-cy="submit"
                className={`${classes.DeleteButton} ${props.className}`} {...props} > {props.children} </Button>
        </>
    )
}
import useMutation from "../../../../custom/useMutation";
import { GET_STARTUP_USERS_PROFILE } from "./useShareProfilesLazy";
import { gql } from "@apollo/client";
import _ from "lodash"
const SET_ACCESS_TO_USER = `mutation setUserAccessToAStartup($startupId:String!, $sharingProfile: SharingProfileInput! ) {
            setUserAccessToAStartup(startupId: $startupId, sharingProfile: $sharingProfile){
          _id
        userId
        role
        fullName
        avatar
        email
        lastLogin
        lastInvitationSent
            }
          }`
const useSetUserAccessToStartup = (config, props, startupId) => {
    const updateSharingProfile = async (cache, { data }) => {
        const mutationName = Object.keys(data)[0];
        cache.evict({ id: cache.identify({ email: data[mutationName].email, __typename: 'PossibleSharingProfile' }) })
        const queries = await cache.readQuery({ query: gql`${GET_STARTUP_USERS_PROFILE}`, variables: { startupId } })
        const getStartupUsersProfiles = _.get(queries, "getStartupUsersProfiles", null);
        if (getStartupUsersProfiles) {
            cache.writeQuery({
                query: gql`${GET_STARTUP_USERS_PROFILE}`,
                data: {
                    getStartupUsersProfiles: [...getStartupUsersProfiles, data[mutationName]]
                }
            })
        }
    }
    const [mutation, loading] = useMutation(SET_ACCESS_TO_USER, config, { ...props, update: startupId ? updateSharingProfile : null, refetchQueries: [gql`${GET_STARTUP_USERS_PROFILE}`] })
    return { mutation, loading }
}

export default useSetUserAccessToStartup
import useLazyQuery from "src/hooks/custom/useLazyQuery";
import _ from "lodash";
import { useParams } from "react-router-dom";
import useSession from 'src/modules/session/hooks/useSession';

export const GET_STAKEHOLDERS = `
  query stakeholderList($startupId: ID!, $groupCompanyId: ID, $filters: StakeholderFilterInput){
    stakeholderList(startupId:$startupId, groupCompanyId: $groupCompanyId, filters: $filters){
      id
      fullName      
    }
  }
`;
  
const useGetStakeholdersLazy = () => {

  const params = useParams();
  const { startupId } = useSession();
  const {data, manualQuery,loading, error} = useLazyQuery(GET_STAKEHOLDERS,{variables:{
    startupId,
    groupCompanyId: params.id}
  });
  
  return { data: { stakeholders: _.get(data, 'stakeholderList', []) }, loading, error, manualQuery };
};

export default useGetStakeholdersLazy;

export default {
    ARE_MORE_ENTITY:'Do you have more {{role}} to add?',
    ADDED_ENTITY:'{{role}} added!',
    BUTTON_NEXT:'Next',
    OPTION_NO:'No, not right now',
    OPTION_YES:'Yes, add anyway',
    TEXT_ADD_INDIVIDUAL_DESCRIPTION:`Companies with four or more directors are formed through Clara’s specialist concierge service. 
    Add this director and form with concierge instead of online?`,
    TEXT_WHAT_IS_CONCIERGE:'Clara’s concierge service provides tailored formation services for companies with more than the standard number of stakeholders. Once you’ve completed your Essentials, engage the concierge to send your formation for review. ',
    TITLE_WHAT_IS_CONCIERGE:'What is Clara’s concierge service?',

}
import get from 'lodash/get'
import some from 'lodash/some'
import  isEmpty from 'lodash/isEmpty';
import {Constants} from 'src/v1/utils'
import { useMemo } from 'react';

const useGuards = () => {
    return useMemo(()=> ({

        pobYes:(context) => {
          return get(context, 'operationInUs') 
        },
        pobNo:(context) => {
          return !get(context, 'operationInUs') 
        },
        
      }),[]);
}
export default useGuards;
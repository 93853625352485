import React, { useContext, useEffect } from 'react';
import FormContext from "../../../forms/Form/FormContext";
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import { useFormContext } from 'react-hook-form'
import _ from "lodash";

const SubmitButton = ({ isSubmitting, handleDisabled, ...props }) => {
    const { formState } = useFormContext();
    const { isSubmitting: isSubmittingForm, isValid, isDirty, errors } = formState;

    const disabledButton = () => {
        // return handleDisabled ? handleDisabled(formState) : (isSubmitting || !isDirty || !isValid || !_.isEmpty(methods.errors)) ;
        return handleDisabled ? handleDisabled(formState) : false;
    };

    return (
        <React.Fragment>
            <PrimaryButton type={"submit"} data-cy="submit"
                loading={isSubmitting || isSubmittingForm}
                disabled={disabledButton()} {...props} > {props.children} </PrimaryButton>
        </React.Fragment>
    )
};

export default SubmitButton

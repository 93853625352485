import React, {FC, useMemo} from 'react'
import _ from 'lodash'
import Typography from "../../../../../generic/components/Texts/Typography";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import {ConvertibleBO, EquityBO, WarrantBO} from "../../../../core/type/startup/equity";
import {DocumentBO} from "../../../../core/type/startup/document";
import CategoriesList from "../../../../../generic/components/Lists/CategoriesList";
import classes from './classes.module.scss'
import {DueDiligenceBO} from "../../../../core/type/startup";
interface Connection{
    equity:EquityBO[]
    documents:DocumentBO[]
    dueDiligences:DueDiligenceBO[]
}
interface ReasonsProps{
    connections:Connection
}
const Reasons:FC<ReasonsProps>=({connections})=>{
    const {translate}=useTranslate()
    const categories=useMemo(()=>{
        const category=[]
        _.forEach(Object.keys(connections),connectionKey=>{
            if(!_.isEmpty(_.get(connections,connectionKey,[])))
                category.push({title:translate(`MODULES.GROUP_COMPANY.DELETE.CONNECTIONS.${connectionKey.toUpperCase()}_TITLE`),
                    items:_.map(connections[connectionKey],item=>{
                        const params={}
                        return  translate(`MODULES.GROUP_COMPANY.DELETE.CONNECTIONS.${connectionKey.toUpperCase()}`,_.set(params,connectionKey,item))
                    })})
        })
        return category;
    },[JSON.stringify(connections)])
    return(<>
        <div className={classes.reasonsContainer}>
        <Typography component={'p'} color={'black'}>
            {translate('MODULES.STAKEHOLDER.DELETE.REASONS')}
        </Typography>
       <CategoriesList dotColor={'red'} categories={categories}/></div>
    </>)
}
export default Reasons
import React from "react";
import Icon from "./../../../modules/generic/components/Icon";
import classesModule from "./IconBlock.module.scss";

const IconBlock = ({ title, icon, subTitle, ...props }) => {
  return (
    <div className={`${classesModule.IconBlock}`}>
      <Icon isClara icon={icon}></Icon>
      <div>{title}</div>
      <div>{subTitle}</div>
    </div>
  );
};

export default IconBlock;

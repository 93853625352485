import { useMemo } from "react";
import TemplateWizardSkeletonMachine from "src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import {
  SelectEquityType,
  ToContinue,
  AddEquityOrDocument,
  GenerateDocumentStep,
  IsThereDocumentsStep,
  IsThereDocumentsWarrantStep,
  OptionFormStep,
  SelectCompaniesStep,
  SelectDocumentStep,
  SelectDocumentSipStep,
  WarrantFormStep,
  UploadDocumentStep,
  UploadDocumentConvertibleStep,
  UploadDocumentOptionSipStep,
  ConvertibleFormStep,
  ShareFormStep
} from "../Steps";
import IsThereDocumentsOptionStep from "../Steps/IsThereDocumentsOptionStep";
import IsThereDocumentsOptionSIPStep from "../Steps/IsThereDocumentsOptionSIPStep";
import IsThereDocumentsConvertibleStep from "../Steps/IsThereDocumentsConvertibleStep";
const useSteps = () => {
  const { translate } = useTranslate();
  return useMemo(() => ({
    getStakeholdersGroupCompanies: {
      render: TemplateWizardSkeletonMachine,
      renderProps: {
        title: translate('MODULES.EQUITY.ADD_EQUITY.TITLE'),
        subTitle: translate('MODULES.EQUITY.ADD_EQUITY.SUBTITLE'),
      },
      stepperPosition: 0
    },
    upsertConvertible:{
      render: TemplateWizardSkeletonMachine,
    },
    upsertOption:{
      render: TemplateWizardSkeletonMachine,
    },
    upsertSipUploadLater:{
      render: TemplateWizardSkeletonMachine,
    },
    upsertSipDocument:{
      render: TemplateWizardSkeletonMachine,
    },
    select: {
      render: SelectEquityType,
      stepperPosition: 0
    },
    selectEquityType: {
      render: SelectEquityType,
      stepperPosition: 0
    },
    //Convertible flow
    addConvertible: {
      render: AddEquityOrDocument,
      stepperPosition: 1
    },
    generateDocumentConvertible: {
      render: GenerateDocumentStep,
      stepperPosition: 1
    },
    selectCompaniesConvertible: {
      render: SelectCompaniesStep,
      stepperPosition: 1
    },
    addConvertibleForm: {
      render: ConvertibleFormStep,
      stepperPosition: 1
    },
    addDocumentsConvertible: {
      render: IsThereDocumentsConvertibleStep,
      renderProps: {
        withAgreement: true
      },
    },
    uploadDocumentsConvertible: {
      render: UploadDocumentConvertibleStep,
      stepperPosition: 1
    },
    generatedDocumentsConvertible: {
      render: SelectDocumentStep,
      stepperPosition: 1
    },
    getDocumentListConvertible: {
      render: TemplateWizardSkeletonMachine,
      renderProps: {
        title: translate('MODULES.EQUITY.ADD_EQUITY.TITLE'),
        subTitle: translate('MODULES.EQUITY.ADD_EQUITY.SUBTITLE'),
      },
      stepperPosition: 1
    },
    //Share flow
    selectCompaniesShare: {
      render: SelectCompaniesStep,
      stepperPosition: 1
    },
    addShareForm: {
      render: ShareFormStep,
      stepperPosition: 1
    },
    addDocumentsShare: {
      render: IsThereDocumentsStep,
      stepperPosition: 1
    },
    uploadDocumentsShare: {
      render: UploadDocumentStep,
      stepperPosition: 1
    },
    generatedDocumentsShare: {
      render: SelectDocumentStep,
      stepperPosition: 1
    },
    getDocumentListShare: {
      render: TemplateWizardSkeletonMachine,
      renderProps: {
        title: translate('MODULES.EQUITY.ADD_EQUITY.TITLE'),
        subTitle: translate('MODULES.EQUITY.ADD_EQUITY.SUBTITLE'),
      },
      stepperPosition: 1
    },
    //Option flow
    addOption: {
      render: AddEquityOrDocument,
      stepperPosition: 1
    },
    generateDocumentOption: {
      render: GenerateDocumentStep,
      stepperPosition: 1
    },
    selectCompaniesOption: {
      render: SelectCompaniesStep,
      stepperPosition: 1
    },
    addOptionForm: {
      render: OptionFormStep,
      stepperPosition: 1
    },

    addDocumentsOptionSIP:{
      render: IsThereDocumentsOptionSIPStep,
    },
    uploadDocumentsOptionSIP:{
      render: UploadDocumentOptionSipStep,
    },
    uploadDocumentsOptionSIPLater:{
      render: UploadDocumentOptionSipStep,
      renderProps: {
        uploadFile: false
      },
    },
    addDocumentsOption: {
      render: IsThereDocumentsOptionStep,
    },
    uploadDocumentsOption: {
      render: UploadDocumentStep,
      stepperPosition: 1
    },
    generatedDocumentOption: {
      render: SelectDocumentStep,
      stepperPosition: 1
    },
    generatedDocumentOptionSIP: {
      render: SelectDocumentSipStep,
      stepperPosition: 1
    },
    getDocumentListOptionSIP: {
      render: TemplateWizardSkeletonMachine,
      renderProps: {
        title: translate('MODULES.EQUITY.ADD_EQUITY.TITLE'),
        subTitle: translate('MODULES.EQUITY.ADD_EQUITY.SUBTITLE'),
      },
      stepperPosition: 1
    },
    getDocumentListOption: {
      render: TemplateWizardSkeletonMachine,
      renderProps: {
        title: translate('MODULES.EQUITY.ADD_EQUITY.TITLE'),
        subTitle: translate('MODULES.EQUITY.ADD_EQUITY.SUBTITLE'),
      },
      stepperPosition: 1
    },
    //Warrant flow
    selectCompaniesWarrant: {
      render: SelectCompaniesStep,
      stepperPosition: 1
    },
    addWarrantForm: {
      render: WarrantFormStep,
      stepperPosition: 1
    },
    addDocumentsWarrant: {
      render: IsThereDocumentsWarrantStep,
      stepperPosition: 1
    },
    uploadDocumentsWarrant: {
      render: UploadDocumentStep,
      stepperPosition: 1
    },
    generatedDocumentsWarrant: {
      render: SelectDocumentStep,
      stepperPosition: 1
    },
    getDocumentListWarrant: {
      render: TemplateWizardSkeletonMachine,
      renderProps: {
        title: translate('MODULES.EQUITY.ADD_EQUITY.TITLE'),
        subTitle: translate('MODULES.EQUITY.ADD_EQUITY.SUBTITLE'),
      },
      stepperPosition: 1
    },
    selectToContinue: {
      render: ToContinue,
      stepperPosition: 2
    },
  }), []);
}
export default useSteps;
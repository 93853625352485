import React from "react";
import * as Yup from "yup";
import Form from "../../../../../../components/forms/Form/Form";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import ControllerInput from "../../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import SubmitButton from "../../../../../generic/components/Buttons/SubmitButton";
import FormProps from "../../../../../generic/forms/interfaces/FormProps";
import TemplateFormDefault from "../../../../../generic/templates/Modal";
import cls from "./classes.module.scss";
import FileUpload from "../../../../../generic/components/Inputs/FileUpload";
import LeftContentBlock from "../../components/LeftContentBlock";
import RightContentBlock from "../../components/RightContentBlock";

const schema = Yup.object().shape({
  files: Yup.array().min(1),
});

interface FormPropsCustom extends FormProps {
  text: string;
  image?: any;
}

const FormCurrentSelect: React.FC<FormPropsCustom> = ({
  initialValues = {},
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  image,
  propsTemplate,
}) => {

  const { translate } = useTranslate();

  const handleSubmit = async (values) => {
    onCompleteSubmit && (await onCompleteSubmit(values));
  };

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <Template
        buttons={{
          submit: <SubmitButton {...buttonSubmitProps}>Add</SubmitButton>,
          ...buttons,
        }}
        props={propsTemplate}
      >
        <div className={cls.ContainerForm}>
          <LeftContentBlock title={"Example"}>
            <>{image}</>
          </LeftContentBlock>
          <RightContentBlock title={"Upload"}>
            <ControllerInput
              render={FileUpload}
              name="files"
              limit={1}
              // limit={1}
              defaultValue={[]}
            />
          </RightContentBlock>
        </div>
      </Template>
    </Form>
  );
};

export default FormCurrentSelect;

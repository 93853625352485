import classnames from "classnames";
import classes from "./classes.module.scss";
import React, { FC } from "react";
import TypographyLocal from "../../../generic/components/Texts/Typography";
import Icon from "../../../generic/components/Icon";

/**
 * Icon Title
 */

export interface IconTitleProps {
  /** Property iconType */
  iconType: string,
  /** Property title */
  title: string,
  /** Property textColor */
  textColor: string,
  /** Property removeBottomMargin */
  removeBottomMargin: boolean,
  /** Property iconBlack */
  iconBlack: boolean,
} 

const IconTitle: FC<IconTitleProps> = ({
  iconType = "Academy",
  title,
  textColor = "black",
  removeBottomMargin = false,
  iconBlack = false,
  ...props
}:IconTitleProps) => {

  return (
    <React.Fragment>
      <div
        className={classnames(`${classes.contentsTitle}`, {
          [classes.titleNoMargin]: removeBottomMargin,
          [classes.iconBlack]: iconBlack,
        })} 
      >
        <div className={classnames(classes.iconAling)}>
          <Icon icon={iconType} size={"100%"} isClara={true}></Icon>
        </div>
        <div className={classnames(classes.titleContents)}>
          <TypographyLocal
            variant={"h2"}
            className={classnames(classes.titleFontStyle)}
            color={textColor}
          >
            {title}
          </TypographyLocal>
        </div>
      </div>
    </React.Fragment>
  );
};

export default IconTitle;
import React, { FC } from "react";
import classesModule from './SubscriptionScale.module.scss';
import TypographyLocal from "../../../generic/components/Texts/Typography";
import ScaleItemFree from "../ScaleItemFree";
import ScaleDetail from '../ScaleDetail'
import Rocket_man from '../../../../../src/images/Rocket_man.svg'
import TagsProduct from '../TagsUnLocked';

export interface SubscriptionScaleProps {
  /** Property children */
  children?: any,
  /** Property saveUpTitle */
  saveUpTitle?: string,
  /** Property saveUpSubtitle */
  saveUpSubtitle?: string,
  /** Property detailTitle */
  detailTitle?: string,
  /** Property detailSubtitle */
  detailSubtitle?: string,
  /** Property scaleItemList */
  scaleItemList?: any,
  /** Property function onSelectProduct */
  subcriptionType?: any,
  ifChangeProduct?: any,
}


const SubscriptionScale: FC<SubscriptionScaleProps> = ({
  children,
  saveUpTitle,
  saveUpSubtitle,
  detailTitle,
  detailSubtitle,
  scaleItemList,
  subcriptionType,
  ifChangeProduct,
}: SubscriptionScaleProps) => {

  return (
    <div className={` ${classesModule.SubscriptionDetails} `}>
      {!!saveUpTitle && (
        <div className={` ${classesModule.cornerContainer} `}>
          <div className={` ${classesModule.cornerRounder} `}>
            <div className={` ${classesModule.cornerAngle} `}></div>
          </div>
          <div className={` ${classesModule.cornerDetail} `}>
            <TypographyLocal
              className={` ${classesModule.textCorner} `}
              color={"white"}
            >
              {saveUpTitle}
            </TypographyLocal>
            {!!saveUpSubtitle && (
              <TypographyLocal
                className={` ${classesModule.textSubTitleCorner} `}
                color={"white"}
              >
                {saveUpSubtitle}
              </TypographyLocal>
            )}
          </div>
        </div>
      )}
      
      <div className={`${classesModule.dataSpace} `}>
        <div className={classesModule.dataLeft}>
          <div className={` ${classesModule.titleSpace} `}>
            <TypographyLocal
              className={` ${classesModule.textTitle} `}
              color={"pink"}
            >
              {detailTitle}
            </TypographyLocal>
            <TypographyLocal
              className={` ${classesModule.textSubTitle} `}
            >
              {detailSubtitle}
            </TypographyLocal>
          </div>
          <div className={classesModule.containerListData}>
            {scaleItemList.map((element, i) => {
              return <div className={classesModule.listItems} >
                <ScaleItemFree
                  icon={element.icon}
                  title={element.title}
                  key={i}
                ></ScaleItemFree>
                <TagsProduct icon={element.tags.icon} renderItem={element.tags.text} color={element.tags.color} />
              </div>
            })}
          </div>
        </div>
        <div className={`${classesModule.dataRight}`}>
          <img src={Rocket_man} alt="Rocket_man" />
          <ScaleDetail
            stringAnnually='Pre-paid (Annually)'
            // tagTitle='Save 20%'

            priceAnnually='USD 1,995'
            // stringMonthly='Monthly instalments (Annual commitment)'
            // priceMonthly='USD 199'
            // handleClick={ifChangeProduct}
            // isMounth={subcriptionType}
          />
          {children}
        </div>
      </div>
    </div>
  )
}
export default SubscriptionScale
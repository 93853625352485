import React from 'react';
import classes from './Botonera.module.scss';

const BotoneraAdd = ({ children,className }) => {
    return (
        <React.Fragment>
            <div className={`${classes.BotoneraAdd} ${className}`}>
                {children}
            </div >
        </React.Fragment >
    )
}

export default BotoneraAdd;
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import classes from './TextLoading.module.scss'
import Text from "./../../../text/Text/Text"

const TextLoading = ({ loading, label, className }) => {

    return (
        <>
            {loading &&
                <div className={`${classes.TextLoading} ${className}`}>
                    <span className={classes.text}>
                        {label?label:(<Text uuid="BUTTON_SAVING"/>)}
                    </span>
                    <CircularProgress />
                </div>
            }
        </>

    )
};

export default TextLoading;

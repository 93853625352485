import useMutation from "../../../hooks/custom/useMutation"

const DECLINE_DOCUMENT = `
    mutation declineDocumentIncorporation ($matterId: ID!, $documentId: ID!,$issues: String! ){
        declineDocumentIncorporation(
            matterId: $matterId,
            issues: $issues,
            documentId: $documentId
        ) {
            id,
            status
        }
    }
`;

const useDeclineDocument = (options, props) => {

	const refetchQueries = ['getDocumentsForApprovalIncorporation']

	const [mutation, data] = useMutation(DECLINE_DOCUMENT, options, {...props, refetchQueries });

	return { mutation, data };
}
export default useDeclineDocument;

import { createMachine } from "xstate";
const machine =
  /** @xstate-layout N4IgpgJg5mDOIC5QFVZgE4AUCuAjANgJYDGASmFIbAC7oCG1hA9gHYB02a6AYk+gLYBiAHIBRABoAVRKAAOTWIUasZIAB6IAjABYAHGwBMAVgBsukye1GjABgDsuzQYA0IAJ5bNATjZGvf3V1tL20TOxt-AF9I11QMHAISckoaemV2AGslXgFBTFJRADVVeUV01Q0EHX1jMwsrWwcnVw8EO01fLxMbG10AZh6m42jYrgSiMgoqWgZmTOy+ITEpEoUlOYrEPr6fTXCvO1s+0J7NFq0DAzYIgKCQsJuRkDisPAnk6bS5thowWT68gVikgQKV1ioQZVtrt9ocbMdujYzu5EHZtNcuj1+oNHMMniwmBA4KoXuMklNUrNWBwuDl+KsyhtIYhtC4UW0fDs7H0DLoutpNDy+iYnqS3uSUjN0mwstQ6QzwSxNggDALDKZdEYdF4Qto+v1zghHGw+dzefzBQZhaKxuLJpKvtTfv8FeVmQg9Vc9hFEQ4ukZdIagmwuTy+ZZLdaYs9bYl7Z8qUqQWC3aBKppA+yALQdbQ2PM2IzaVlWvMWaLRIA */
  createMachine({
    id: 'DataProtectionDeclaration',
    initial: 'InitialData',
    states: {
      InitialData: {
        invoke: {
          src: 'initialData',
          id: 'initialData',
          onDone: [
            {
              target: 'DataProtectionDeclarationPart1',
              actions: ['setContextAfterInvoke']
            }
          ],
          onError: [
            {
              target: 'cancel'
            }
          ]
        }
      },
      DataProtectionDeclarationPart1: {
        on: {
          NEXT: {
            target: 'DataProtectionDeclarationPart2',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'finish'
          }
        }
      },
      DataProtectionDeclarationPart2: {
        on: {
          NEXT: {
            target: 'DataProtectionDeclarationPart3',
            actions: ['setContextAfterStep']
          },
          PREV: {
            target: 'DataProtectionDeclarationPart1',
          },
          CANCEL: {
            target: 'finish'
          }
        }
      },
      DataProtectionDeclarationPart3: {
        on: {
          NEXT: {
            target: 'SaveDeclaration',
            actions: ['setContextAfterStep']
          },
          PREV: {
            target: 'DataProtectionDeclarationPart2',
          },
          CANCEL: {
            target: 'finish'
          }
        }
      },
      // EvaluateIsEdit: {
      //   always: [
      //     {
      //       cond: "isEdit",
      //       target: 'DataProtectionDeclarationPart4'
      //     },
      //     {
      //       cond: "isNotEdit",
      //       target: 'SaveDeclaration'
      //     },
      //   ]
      // },
      // DataProtectionDeclarationPart4: {
      //   on: {
      //     NEXT: {
      //       target: 'SaveDeclaration',
      //       actions: ['setContextAfterStep']
      //     },
      //     PREV: {
      //       target: 'DataProtectionDeclarationPart3',
      //     },
      //     CANCEL: {
      //       target: 'finish'
      //     }
      //   }
      // },
      SaveDeclaration: {
        invoke: {
          src: 'saveDeclaration',
          id: 'saveDeclaration',
          onDone: [
            {
              target: 'finish'
            }
          ],
          onError: [
            {
              target: 'finish'
            }
          ]
        }
      },
      error: {
        on: {
          PREV: {
            target: 'DataProtectionDeclarationPart3'
          }
        }
      },
      cancel: {
        entry: 'cancelMachine',
        type: 'final'
      },
      finish: {
        entry: 'stopMachine',
        type: 'final'
      }
    }
  });
export default machine;

import React, { FC, useEffect } from 'react';
import { useDispatch } from "react-redux";
import useCustomMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import { addAssistantText, clearAssistatText } from "src/modules/generic/store/action";
import IsThereEquity from "../../../forms/IsThereEquity";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import Button from "../../../../../generic/components/Buttons/Button";

const AddEquityOrDocument: FC<{}> = () => {

  const dispatch = useDispatch()
  const { translate } = useTranslate()
  const { state, next, context, send } = useCustomMachine();

  useEffect(() => {
    dispatch(clearAssistatText())
    dispatch(addAssistantText(
      translate(`MODULES.EQUITY.STEPS_CONVERTIBLE.FIRST_STEP.TOOLTIP_${context.type}`)
    ))
    return () => {
      dispatch(clearAssistatText())
    }
  }, []);

  const handleCompleteSubmit = async (values) => {
    next(values)
  }

  const handleCancel = () => {
    send('CANCEL')
  }

  return (
    <>
      <IsThereEquity
        propsTemplate={{
          title: translate('MODULES.EQUITY.STEPS.FIRST_STEP.TITLE', { type: translate(`MODULES.EQUITY.TYPES_PLURAL.${context.type}`) }),
          subTitle: translate('MODULES.EQUITY.STEPS.FIRST_STEP.SUBTITLE', { type: translate(`MODULES.EQUITY.TYPES_PLURAL.${context.type}`) })
        }}
        type={context.type}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        buttons={{ cancel: <Button onClick={handleCancel}>Cancel</Button> }}
      />
    </>
  );
}
export default AddEquityOrDocument

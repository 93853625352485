import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import * as Yup from 'yup';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import classes from "../../styles/director.module.scss";

function DirectorAdded() {
  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.initialData ?? {}
  }, [context]);

  const handleCancel = () => {
    cancel();
  }

  const handleSubmit = async (values) => {
    next(values);
  }

  useEffect( () => {
    dispatch(addAssistantText(translate('MODULES.ONBOARDINGROLES.DIRECTOR_DELAWARE.GENERIC_TOOLTIP')));
    return () => {
      dispatch(clearAssistatText());
    }
  },[dispatch]);

  const schema = Yup.object().shape({
    addAnother: Yup.boolean().required(translate('MODULES.ONBOARDINGROLES.DIRECTOR_DELAWARE.FIELD_REQUIRED'))
  });

  const options = [
    {
      value: false,
      label: translate("MODULES.MAP.LABEL_ANOTHER_NO"),
    },
    {
      value: true,
      label: translate("MODULES.MAP.LABEL_ANOTHER_YES"),
    },
  ];

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: translate("MODULES.ONBOARDINGROLES.DIRECTOR_DELAWARE.DIRECTOR_ADDED_TITLE"),
          skeleton: null,
          subTitle: translate("MODULES.ONBOARDINGROLES.DIRECTOR_DELAWARE.DIRECTOR_ADDED_SUBTITLE"),
          variant: 'simple'
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>{translate('BUTTON_NEXT')}</SubmitButton>,
        }}
      >
        <ControllerInput
            render={CheckboxButtonSimple}
            className={classes.CheckboxButtonSimple}
            classNameButton={classes.ButtonCheckbox}
            name="addAnother"
            options={options}
        />
      </TemplateWizard>
    </Form>
  );
}

export default DirectorAdded;
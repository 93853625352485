import Machine from "../../../generic/components/Machine/MachineClass";
import { Step1, Step2, Step2B, Step3B, Step4B } from './Steps';

/**
 * 
 *      { label: "No, not right now", value: "NO_PROOF_ADDRESS" },
            { label: "Yes, upload it now", value: "UPLOAD_PROOF_ADDRESS" },
            { label: "Upload third party address form and passports", value: "UPLOAD_PROOF_ADDRESS_PARTY" },
            { label: "Download third party address form", value: "DOWNLOAD_PROOF_ADDRESS" }
       
 */


const isUploadProofAddress = (context) => {
    return context.data.select_current.typeProofAddress === 'UPLOAD_PROOF_ADDRESS'
}


const isUploadProofAddressParty = (context) => {
    return context.data.select_current.typeProofAddress === 'UPLOAD_PROOF_ADDRESS_PARTY'
}

const isDownloadProofAddress = (context) => {
    return context.data.select_current.typeProofAddress === 'DOWNLOAD_PROOF_ADDRESS'
}

const machineDefinition = {
    id: 'select_current',
    initial: 'select_current',
    states: {
        select_current: {
            on: {
                NEXT: { target: 'loading_flow', actions: 'setContext' },
                CANCEL: 'finish_stakeholder_assign',
            },
        },
        loading_flow: {
            always: [
                { target: 'upload_proof_address', cond: 'isUploadProofAddress' },
                { target: 'upload_proof_address_party', cond: 'isUploadProofAddressParty' },
                { target: 'upload_proof_address_party', cond: 'isDownloadProofAddress' },
                { target: 'finish_stakeholder_assign' }
            ]
        },
        upload_proof_address: {
            on: {
                NEXT: { target: 'finish_stakeholder_assign', actions: 'setContext' },
                CANCEL: 'finish_stakeholder_assign',
            },
        },
        upload_proof_address_party: {
            on: {
                NEXT: { target: 'party_passport', actions: 'setContext' },
                CANCEL: 'finish_stakeholder_assign',
            },
        },
        party_passport: {
            on: {
                NEXT: { target: 'party_passport_data', actions: 'setContext' },
                CANCEL: 'finish_stakeholder_assign',
            },
        },
        party_passport_data: {
            on: {
                NEXT: { target: 'finish_stakeholder_assign', actions: 'setContext' },
                CANCEL: 'finish_stakeholder_assign',
            },
        },
        finish_stakeholder_assign: {
            type: 'final',
            entry: 'final',
        },
        onDone: {
            actions: 'stopMachine'
        }
    },
}
const steps = {
    select_current: Step1,
    upload_proof_address: Step2,
    upload_proof_address_party: Step2B,
    party_passport: Step3B,
    party_passport_data: Step4B
}

const options = {
    guards: {
        isUploadProofAddress,
        isUploadProofAddressParty,
        isDownloadProofAddress

    }
}



export default class MachineProfileStakeholder extends Machine {
    constructor() {
        super(machineDefinition, options, steps);
    }

    getParamsData(values) {
        return values;
    }
    /*
    
    getParamsData({ paramsMutation }) {
        return { ...paramsMutation }
    }*/
}

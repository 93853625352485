import React, {FC, useContext, useEffect, useMemo, useRef, useState} from 'react'
import _ from 'lodash'
import {useDispatch} from "react-redux";
import * as  Yup from 'yup'
import useTranslate from "../../../../../../../../generic/hooks/useTranslate";
import Form from "../../../../../../../../../components/forms/Form/FormL";
import classes from './classes.module.scss'
import ControllerInput from "../../../../../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import SubmitButton from "../../../../../../../../generic/components/Buttons/SubmitButton";
import TemplateFormDefault from "../../../../../../../../generic/templates/Modal";
import FormProps from "../../../../../../../../generic/forms/interfaces/FormProps";
import FileUploaderInput from "../../../../../../../../../components/inputs/FileUploader/FileUploaderInput";
import TemplateLabel from "../../../../../../../../generic/components/Inputs/LabelInput";
import TextInput from "../../../../../../../../../components/inputs/Text/TextInput";
import useCategories from "../../../../../../../../../hooks/common/useCategories";
import SelectInput from "../../../../../../../../../components/inputs/Select/SelectInput/SelectInput";
import Loading from "../../../../../../../../../components/generic/Loading/Circular/Circular";
import {Constants} from "../../../../../../../../../v1/utils";
const schema=Yup.object().shape({
    name:Yup.string().nullable().required('Required'),
    file:Yup.object().nullable().required('Required'),
    type:Yup.string().nullable().required('Required')
})
const UploadForm: FC<FormProps> = ({initialValues, tooltips, onCompleteSubmit, Template = TemplateFormDefault, buttons = {}, buttonSubmitProps, propsTemplate}:FormProps) => {
    const { translate } = useTranslate();
    const {categories, loadingCat} = useCategories("STARTUP")
    const convertibleCategory=useMemo(()=>{
        if(categories && !_.isEmpty(categories)) {
            const equityCat = _.find(categories, {key: 'EQUITY'})
            const subCat = _.find(equityCat.subCategories, {key: 'convertibleInstruments'})
            return _.map(_.filter(subCat.documentTypes,doc=>doc.key!=='WARRANT_AGREEMENT'), doc => ({label:doc.title, value: doc.key}))
        }
        return []
    },[JSON.stringify(categories)])
    const refForm=useRef()
    return (
        <React.Fragment>
            <Loading loading={loadingCat}>
            <Form
                initialValues={initialValues || {}}
                schema={schema}
                onlySendDirty={false}
                onSubmit={onCompleteSubmit}
                ref={refForm} optionsForm = {{mode:"onChange"}}
            >
                <Template props={propsTemplate} buttons={{submit:<SubmitButton{...buttonSubmitProps}>Add</SubmitButton>,...buttons}}>
                    <TemplateLabel label={translate('MODULES.EQUITY.FORMS_CONVERTIBLE.UPLOAD_FORM.NAME')} isRequired={true}>
                        <ControllerInput
                            as={TextInput}
                            defaultValue=""
                            name={"name"}
                            placeholder={translate('MODULES.EQUITY.FORMS_CONVERTIBLE.UPLOAD_FORM.PLACEHOLDER_NAME')}
                            onChange={(e) => {

                            }}
                        />
                    </TemplateLabel>
                    <TemplateLabel label={translate('MODULES.EQUITY.FORMS_CONVERTIBLE.UPLOAD_FORM.TYPE')}>
                        <ControllerInput
                            as={SelectInput}
                            list={convertibleCategory}
                            placeholder={translate('MODULES.EQUITY.FORMS_CONVERTIBLE.UPLOAD_FORM.PLACEHOLDER_TYPE')}
                            name={"type"}
                            onChange={(e) => {
                            }}
                        />
                    </TemplateLabel>
                    <TemplateLabel label={translate('MODULES.EQUITY.FORMS_CONVERTIBLE.UPLOAD_FORM.FILE')} isRequired={true}>
                        <ControllerInput
                            as={FileUploaderInput}
                            defaultValue=""
                            name={"file"}
                            onChange={(e) => {

                            }}
                        />
                    </TemplateLabel>
                </Template>
            </Form></Loading>
        </React.Fragment>
    )
}

export default UploadForm

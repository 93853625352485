import React from 'react'
import * as Yup from 'yup'
import Form from '../../../../components/forms/Form/Form'
import useTranslate from '../../../generic/hooks/useTranslate'
import TextInput from '../../../../components/inputs/Text/TextInput'
import ControllerInput from '../../../generic/components/Inputs/ReactHookForm/ControllerInput'
import TemplateLabel from '../../../generic/templates/Label'
import SubmitButton from '../../../generic/components/Buttons/SubmitButton'
import FormProps from '../../../generic/forms/interfaces/FormProps';
import TemplateFormDefault from "../../../generic/templates/Modal";
import cls from './Form.module.scss';
import CalendarInputDate from '../../../../components/inputs/Calendars/CalendarInputDate'
import FileUploaderInput from '../../../../components/inputs/FileUploader/FileUploaderInput'
import useCustomMachine from '../../../generic/hooks/useCustomMachine'
import _ from 'lodash'
import useCategories from '../../../../hooks/common/useCategories'
import CheckboxInput from '../../../generic/components/Inputs/Checkbox';
import SelectInput from '../../../../components/inputs/Select/SelectInput/SelectInput';
import ClassNames from 'classnames'
import { Constants } from '../../../../v1/utils'

const schema = Yup.object().shape({
    title: Yup.string().nullable().required('This field is required'),
    docType: Yup.string().nullable().required('This field is required'),
    file: Yup.object().nullable().required('This field is required')

})

const FormProfessionalHistory: React.FC<FormProps> = ({
    initialValues: defaultValues = {},
    onCompleteSubmit,
    Template = TemplateFormDefault,
    buttons = {},
    buttonSubmitProps,
    propsTemplate }) => {


    const { translate } = useTranslate();

    const handleSubmit = async values => {
        onCompleteSubmit && await onCompleteSubmit(values);
    }

    const { state, send } = useCustomMachine();

    const { getCategoryFromDocType, loading } = useCategories();


    const category = React.useMemo(() => {
        return getCategoryFromDocType(_.get(state, 'context.paramsData.type', ''));
    }, [loading])


    console.log('categories', category);

    const listConvertibleTypes = React.useMemo(() => {
        return [
            { label: 'Convertible Note', value: 'CONVERTIBLE_NOTE' },
            { label: 'Keep It Simple Security', value: 'KISS' },
            { label: 'Simple Agreement for Future Equity', value: 'SAFE' },
            { label: 'Miscellaneous Convertible Instrument Document', value: 'OTHER_CONVERTIBLE_AGREEMENT' }]
    }, []);


    const handleOnChangeDocType = (type) => {
        send({ type: 'SET_DOC_TYPE', payload: type });
    }

    return (
        <Form
            defaultValues={defaultValues}
            schema={schema}
            onSubmit={handleSubmit}
            onlySendDirty={false}
            optionsForm={{ mode: "onChange" }}
        >

            <Template buttons={{ ...buttons, submit: <SubmitButton  {...buttonSubmitProps}>Add</SubmitButton> }} props={propsTemplate}>


                {category && category.key === "TEAM" &&
                    <>

                        <TemplateLabel
                            label={''}
                            className={cls.TemplateLabel}
                        >
                            <ControllerInput
                                render={CheckboxInput}
                                name='subCategories'
                                defaultlabel={''}
                                options={[{
                                    label: 'This agreement has an IP provision',
                                    value: 'IPAssignment'
                                }]}

                            />
                        </TemplateLabel>
                    </>
                }


                <TemplateLabel
                    label={'Document name'}
                    isRequired
                    className={cls.TemplateLabel}
                >
                    <ControllerInput
                        render={TextInput}
                        name='title'
                        defaultlabel={''}
                        placeholder='Give this document a name'

                    />
                </TemplateLabel>

                <TemplateLabel
                    label={'Document Type'}
                    className={ClassNames(cls.TemplateLabel)}
                    isRequired
                >

                    <ControllerInput
                        render={SelectInput}
                        list={listConvertibleTypes}
                        name={"docType"}
                        onChange={handleOnChangeDocType}
                        clear
                        defaultlabel={''}
                        placeholder='Search or select from list'

                    />

                </TemplateLabel>


                <TemplateLabel
                    label={'Upload'}
                    isRequired
                    className={cls.TemplateLabel}
                >
                    <ControllerInput
                        render={FileUploaderInput}
                        name='file'
                        defaultlabel={''}
                        placeholder='Include numbers only'

                    />
                </TemplateLabel>


            </Template>

        </Form>
    )

}

export default FormProfessionalHistory;

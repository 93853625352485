import React, { useEffect, useState } from 'react';
import { query2 } from '../graphql/mutations/matters';
import ServerConnect from '../utils/ServerConnect';
import useQuery from '../../hooks/custom/useQuery'
import _ from 'lodash'


const GET_MATTERS_LIST = `{
  getMattersList(matterId:null) {
    id
    state
    clientFullName
    companyType
    sequenceNumber
    tags
    type
    createdBy {
    _id
      fullName
    }
     questionnaire{
      companyDetails{
        companyName
      }
    }
  }
}`

const useMatters = () => {

  const { data: matters, loading, ...props } = useQuery(GET_MATTERS_LIST);

  return { matters: _.get(matters, "getMattersList"), loading, ...props };
}

export default useMatters;
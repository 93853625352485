import { Box, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import Link from '@mui/material/Link';
import cls from './ModalStepper.module.scss';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

const ModalStepper = ({totalSteps, actualStep, previousClick}) => {
  const widthStep = useMemo(() => 100 / totalSteps, [totalSteps]);
  const [width, setWidth] = useState(null);

  useEffect(() => {
    if (actualStep === 0) {
      setWidth(0);
    } else if (actualStep === totalSteps) {
      setWidth(100);
    } else {
      setWidth(widthStep * actualStep);
    }
  }, [actualStep, totalSteps, widthStep]);

  const handleClick = (event) => {
    if (previousClick) previousClick(event);
    event.preventDefault();
  };

  return (
    <>
      <Box
        className={`${cls['container-track']}`}
        sx={{
          height: '8px',
          width: '100%',
          borderRadius: '4px'
        }}
      >
        <Box
          className={`${cls['container-progress']}`}
          sx={{
            height: '100%',
            width: `${width}%`,
            borderRadius: '4px',
          }}
        />
      </Box>
      <Stack direction="row" justifyContent={actualStep > 1 ? 'space-between' : 'flex-end'} alignItems="center" spacing={2} mt="12px">
        {actualStep > 1 && (
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={0.5}
            sx={{
              color: "#2C74FF !important",
            }}
          >
            <KeyboardArrowLeftIcon />
            <Link 
              underline="hover" 
              onClick={handleClick} 
              sx={{ 
                cursor: 'pointer', 
                fontSize: '14px',
                "&:hover": {
                  color: "#2c74ff",
                  textDecoration: 'underline !important'
                }
              }}
            >
              Previous
            </Link>
          </Stack>
        )}
        {actualStep > 0 && (
          <Typography sx={{ fontWeight: '600', fontSize: "14px" }}>
            Step {actualStep} of {totalSteps}
          </Typography>
        )}
      </Stack>
    </>
  )
}

export default ModalStepper

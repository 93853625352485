import { useMemo } from 'react';

const useGuards = () => {

  return useMemo(() => ({

  }), []);
}

export default useGuards;

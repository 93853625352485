export default {
  STEP_1: {
    TITLE:'Your stakeholders',
    SUBTITLE:'Complete stakeholders',
    DESCRIPTION:`{{{acceleratorName}}} requires you to add all your <b>Founders, Shareholders, Directors, Employees, Advisors, Consultants</b> and <b>Investors</b> for <b> all your companies</b>. Check that all your stakeholders are in the list below, if not add them to the map and then come back to complete this task.`,
    TOOLTIP1: 'You can add stakeholders by using the Add Stakeholder button on the map. When adding stakeholders, make sure to assign them all the roles they have in every company. To edit or add a role, go to their details page.',
    TOOLTIP2: 'Every stakeholder must have at least ONE role in a company. Founders of the startup must have at least one additional role in a company, for example Shareholder or Director.',
    TOOLTIP3: 'You don’t have any stakeholders added at the moment. You have to add your stakeholders before completing this task.',
    CONFIRMATION: 'Have you added all your stakeholders & their roles?',
    CONFIRMATION_YES: 'Yes, I’ve added them all',
    CONFIRMATION_NO: 'No, I have more to add',
    NO_ROLE: 'NO ROLE',
 },
}
import React, {useEffect} from 'react';
import {Field, Formik} from 'formik';
import TextInput from "../../Inputs/TextInput";
import classesForm from '../Form.module.scss';
import {Constants} from "../../../../utils";
import _ from 'lodash'
import * as Yup from 'yup';
import {useParams} from 'react-router-dom';
import AgreementSelect from './AgreementSelect'

const AgreementForm = React.memo(({ initialValues, onSubmit, closeForm }) => {


    const params = useParams();
    let reqText = 'This field is required';
    let shortText = "Must have 5 characters";
    let largerText = "Must be shorter than 255 characters"


    useEffect(() => {

    }, [])

    return (
        <React.Fragment>

            {
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={Yup.object().shape({
                        "firstName": Yup.string().min(5, shortText).max(255, largerText).nullable(),
                        "lastName": Yup.string().min(5, shortText).max(255, largerText).nullable(),
                        "email": Yup.string().matches(Constants.REGEXP.EMAIL, { message: "This email is invalid", excludeEmptyString: true }).nullable(),
                    })
                    }
                    onSubmit={onSubmit}
                    isInitialValid
                >

                    {({
                        values,
                        errors,
                        dirty,
                        handleSubmit,
                        isSubmitting,
                        handleReset,
                        setFieldValue,
                        initialValues,
                        isValid,
                        setFieldError,
                        validateForm,
                    }) => {

                        return (

                            <React.Fragment>
                                <div className={classesForm.containerData} >
                                    <React.Fragment>
                                        <AgreementSelect
                                            name={"agreementsDirectors"}
                                            titleButtons={`QUESTIONARY_SHAREHOLDER_DIRECTORS_TEXT_DIRECTORS`}
                                            titleEntyties={`QUESTIONARY_SIGNATORIES_OFFICE_AGREEMENT_TEXT`}
                                            initialValueStart={{ matterId: params.matterId }}
                                            showButtonAdd={false}
                                        />
                                    </React.Fragment>
                                    {_.get(initialValues, 'agreementsDirectors', []).length < 2 ? <React.Fragment>
                                        <p className={classesForm.titleRow}>Witness Details</p>
                                        <Field name='firstName'>
                                            {({ field, form }) => (
                                                <TextInput
                                                    type='text'
                                                    label='First Names:'
                                                    field={field}
                                                    form={form}
                                                    placeholder={"Type here..."}
                                                    autoComplete='off'
                                                />
                                            )}
                                        </Field>


                                        <Field name='lastName'>
                                            {({ field, form }) => (
                                                <TextInput
                                                    type='text'
                                                    label='Surname:'
                                                    field={field}
                                                    form={form} isValid
                                                    placeholder={"Type here..."}
                                                    autoComplete='off'
                                                />
                                            )}
                                        </Field>

                                        <Field name='email'>
                                            {({ field, form }) => (
                                                <TextInput
                                                    type='email'
                                                    label='Email Address'
                                                    field={field}
                                                    form={form}
                                                    placeholder={"Type here..."}
                                                    autoComplete='off'
                                                >

                                                </TextInput>
                                            )}
                                        </Field>
                                    </React.Fragment> : null}
                                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                                    <hr></hr>
                                    <div className={`botoneraButtons ${classesForm.containerButtons}`}>
                                        <button className={`genericBlue`} onClick={closeForm} >Cancel</button>
                                        <button className={`genericBlue`} onClick={handleSubmit} disabled={!isValid || _.get(values, 'agreementsDirectors.length', []) < 1 || (_.get(values, 'agreementsDirectors', []).length < 2 ? ((_.get(values, 'firstName.length') < 1 || !_.get(values, 'firstName')) || (_.get(values, 'lastName.length') < 1 || !_.get(values, 'lastName')) || (_.get(values, 'email.length') < 1 || !_.get(values, 'email'))) : false)} >Update </button>
                                    </div>
                                </div>
                            </React.Fragment>
                        )

                    }
                    }
                </Formik>
            }
        </React.Fragment >
    )
})

export default AgreementForm;



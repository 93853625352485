import _, { toInteger } from 'lodash';
import moment from "moment";
import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch } from "react-redux";
import { StepProps } from "../../../../../../../../generic/components/Wizard/Step";
import useCustomMachine from "../../../../../../../../generic/hooks/useCustomMachine";
import useTranslate from "../../../../../../../../generic/hooks/useTranslate";
import { addAssistantText, clearAssistatText } from "../../../../../../../../generic/store/action";
import useSession from '../../../../../../../../session/hooks/useSession';
import useUpsertConvertible from "src/modules/startup/equity/graphql/mutation/upsertConvertible";
import IsThereDocumentStep from "../../../../../../wizard/steps/IsThereDocumentsStep";
const IsThereDocumentConvertibleStep: FC<StepProps> = ({ }) => {
    const { state } = useCustomMachine()
    const { upsertConvertible } = useUpsertConvertible({ showNotificationSuccess: false })
    const { startupId } = useSession();
    const dispatch = useDispatch()
    const { translate } = useTranslate()
    useEffect(() => {
        dispatch(clearAssistatText())
        dispatch(addAssistantText(translate('MODULES.EQUITY.STEPS_CONVERTIBLE.IS_THERE_DOCUMENTS_STEP.FISRT_TOOLTIP')));
        dispatch(addAssistantText(translate('MODULES.EQUITY.STEPS_CONVERTIBLE.IS_THERE_DOCUMENTS_STEP.TOOLTIP')));

    }, [])
    const getCurrency = (): string => {
        if (_.isEmpty(_.get(state, 'context.globalData', []))) return _.get(state, 'context.data.select_companies.companies[0].transactionCurrency', 'USD') ? _.get(state, 'context.data.select_companies.companies[0].transactionCurrency', 'USD') : 'USD'
        else return _.get(state, `context.globalData[0].select_companies.companies[${_.get(state, 'context.numbersOfIteration', 0)}].transactionCurrency`, 'USD') ? _.get(state, `context.globalData[0].select_companies.companies[${_.get(state, 'context.numbersOfIteration', 0)}].transactionCurrency`, 'USD') : 'USD'
    }
    const handleCompleteSubmit = async (values) => {
        if (values.documents === 'later') {
            const convertibleData = {
                issuedDate: moment(_.get(state, 'context.data.add_equity_form.issuedDate')).format('YYYY-MM-DD'),
                amount: toInteger(_.get(state, 'context.data.add_equity_form.amount')),
                discountRate: toInteger(_.get(state, 'context.data.add_equity_form.discount')),
                interestRate: toInteger(_.get(state, 'context.data.add_equity_form.interestRate')),
                valuationCap: toInteger(_.get(state, 'context.data.add_equity_form.valuation')),
                currency: getCurrency(),
                documents: {},
                groupCompany: _.isEmpty(_.get(state, 'context.globalData', [])) ? _.get(state, 'context.data.select_companies.companies[0].id') : _.get(state, `context.globalData[0].select_companies.companies[${_.get(state, 'context.numbersOfIteration', 0)}].id`)
            }
            await upsertConvertible({ startupId, owner: _.get(state, 'context.paramsData'), convertibleData })
        }
    }

    const tooltips = useMemo(() => {
        return {
            isUpload: true,
            isExist: 'This will be safely stored in Documents, your Clara data room.'
        }
    }, [])

    return (<>
        <IsThereDocumentStep tooltips={tooltips} isTherePrevious={false} onCompleteSubmit={handleCompleteSubmit} type={'CONVERTIBLE'} />
    </>)
}
export default IsThereDocumentConvertibleStep
import React from 'react';
import * as Yup from 'yup';
import CardSelector from "../../../../../../components/inputs/Select/CardSelector/CardSelector";
// import { editProfileCorporate } from "../../../../../../forms/schemas/modules/startups/groupCompany/schema";
import SecondaryButton from "../../../../../generic/Buttons/SecondaryButton/SecondaryButton";
import SubmitButton from "../../../../../generic/Buttons/SubmitButton/SubmitButton";
import ControllerInput from '../../../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import NewModalForm from "../../../../../templates/NewModalForm/NewModalForm";
import Text from '../../../../../text/Text/Text';
import Title from '../../../../../text/Title/Title';
import { RowAdd } from '../../../../contents';
import Form from '../../../../Form/Form';
const schema = Yup.object().shape({
    card: Yup.string().required('Required'),
})
const EditSubscriptionForm = ({ initialValues, cards,handleSubmit, teamRoleAndInvestor, investor, handleCloseModal, handleDeleteModal, product = {}, ...props }) => {
    
   
    return (
        <Form initialValues={initialValues} schema={schema} onSubmit={handleSubmit} >
            <NewModalForm
                header={<Title  ><Text uuid={"EDIT_SUBSCRIPTION_MODAL_TITLE"} ></Text></Title>}
                content={
                    <React.Fragment>
                        <RowAdd
                            label={"PAYMENT_CARD_SELECT_LABEL"}
                            input={
                                <ControllerInput
                                    as={CardSelector}
                                    name="card"
                                    cards={cards}
                                    placeholder={"PAYMENT_CARD_SELECT_PACLEHOLDER"}
                                />
                            } />
                    </React.Fragment>
                }
                botonera={
                    <React.Fragment>
                        <SecondaryButton onClick={() => handleCloseModal()}><Text uuid={'BUTTON_CANCEL'} /></SecondaryButton>
                        <SubmitButton ><Text uuid={"BUTTON_UPDATE"} /></SubmitButton>
                    </React.Fragment>
                }

            />
        </Form>
    )
}

export default EditSubscriptionForm;

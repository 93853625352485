import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Button from "src/modules/generic/components/Buttons/Button";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import {
  addAssistantText,
  clearAssistatText,
} from "src/modules/generic/store/action";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import FormQuestionnaire from "../../forms/FormQuestionnaire";

function ReviewCompanyTaxInformation() {
  const { next, context, cancel } = useMachine();
  const { initialData } = context;
  const { techLicense } = initialData;
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const handleCancel = () => cancel();

  const handleSubmit = async (values) => next(values);

  useEffect(() => {
    dispatch(
      addAssistantText(
        "Once we received your answers, we will discuss with ADGM to confirm the renewal classification.<br><br><a rel='noopener noreferrer' target='_blank' href='https://help.clara.co/en/articles/6965962-what-is-the-difference-between-the-seed-and-emergent-stages-for-an-adgm-tech-startup-licence'>Click here</a> for information on the difference between the seed and emergent licence."
      )
    );
    dispatch(
      addAssistantText(
        "In order to proceed with your commercial licence renewal, it is mandatory that your Tech Licence company has <strong>at least one</strong> full time employee with a visa issued under the company. Alternatively, a shareholder of the company must have a visa issued under another entity in the UAE. If this is not the case for your entity, please contact us to discuss options."
      )
    );

    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  return (
    <FormQuestionnaire
      initialValues={techLicense}
      onCompleteSubmit={handleSubmit}
      Template={TemplateWizard}
      propsTemplate={{
        title: "Tech Licence questionnaire",
        subTitle: `Complete Tech Licence questionnaire`,
      }}
      buttons={{
        cancel: (
          <Button onClick={handleCancel} variant="secondary">
            {translate("BUTTON_CANCEL")}
          </Button>
        ),
      }}
    />
  );
}

export default ReviewCompanyTaxInformation;

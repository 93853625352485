import React, { useEffect } from 'react';
import useQuery from "../../../../hooks/custom/useQuery"
import useLazyQuery from "../../../../hooks/custom/useLazyQuery"
import _ from "lodash";

export const query = `query getStartup($startupId: ID!){
  getStartup(startupId: $startupId){
    id
    founders {
      id
      fullName
      isFounder
      avatar
      email
    }
  }
}`;

const useGetStartupFounders = (variables,config) => {
  const { loading, error, data: startupData, refetch } = useQuery(query, variables, config);


  const data  = _.get(startupData, "getStartup", {})

  return { loading, error, data, refetch };
}

export default useGetStartupFounders;
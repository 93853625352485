import useMutation from '../../../../../hooks/custom/useMutation'

export const UPSERT_SET_TOPCO = `
  mutation updateAcceleratorEvent($startupId: ID, $eventType: AcceleratorEventEnum, $groupCompanyId: ID) {
    updateAcceleratorEvent(startupId: $startupId, eventType: $eventType, groupCompanyId: $groupCompanyId) {
      acceleratorData {
        topCoSelected
        allStakeholdersAdded
        allQuestionsComplete
      }
    }
  }
`;

const useSelectTopco = (props = {}) => {
  const mutation = useMutation(
    UPSERT_SET_TOPCO,
    { showSuccessNotification: false, ...props },
  )
  return mutation
}

export default useSelectTopco

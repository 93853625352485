import React, { useCallback, useEffect, useState } from "react";
import useGetGroupCompanyLazy, { GET_GROUPCOMPANY_TASKCOUNT } from "src/hooks/services/modules/groupCompany/useGetGroupCompanyLazy";
import useGetStakeholderLazy, { GET_STAKEHOLDER_TASKCOUNT } from 'src/hooks/services/modules/stakeholders/useGetStakeholderLazy';

import BubbleNoPendingTask from '../BubbleNoPendingTask';
import BubblePendingTask from '../BubblePendingTask';
import useGetTaskByType from "./graphql/useGetTaskByType"
import { useParams } from 'react-router';
import useSession from 'src/modules/session/hooks/useSession';

/**
  Assistance bubbles
*/
const BubbleTask = ({ variant  }) => {
  const { startupId } = useSession();
  const { id } = useParams();

  const {
    loading: loadingStakeholder,
    manualQuery: getStakeholder
  } = useGetStakeholderLazy({
    query: GET_STAKEHOLDER_TASKCOUNT,
    variables: {
      stakeholderId: id,
      startupId
    }
  });

  const { loading: loadingGroupCompany,
    manualQuery: getGroupCompany
  } = useGetGroupCompanyLazy({
    query: GET_GROUPCOMPANY_TASKCOUNT,
    variables: {
      groupCompanyId: id,
      startupId
    }
  });

  const { loading: loadingAccelerator, manualQuery: getAccelerator } = useGetTaskByType({
    variables: {
      startupId,
      cardCode: "ACCELERATOR",
      entity: {
        id: startupId,
        type: "STARTUP"
      }
    }
  });

  const [hasPendingTasks, setHasPendingTasks] = useState(false);

  const evaluatePendingTasks = useCallback(async () => {
    switch (variant) {
      case 'stakeholder':
        const dataStakeholder = await getStakeholder();
        setHasPendingTasks(dataStakeholder?.data?.stakeholder?.pendingTasksCount > 0)
        return;

      case 'groupCompany':
          const dataGroupComany = await getGroupCompany();
          setHasPendingTasks(dataGroupComany?.data?.groupCompany?.pendingTasksCount > 0)
          return;

      case 'ACCELERATOR':
        const dataAccelerator = await getAccelerator()
        const pendingTasksAcc = dataAccelerator?.data?.getTaskByType?.tasks?.length;
        const subGroupTaskAcc = dataAccelerator?.data?.getTaskByType?.subGroupTask?.length;
        setHasPendingTasks(pendingTasksAcc > 0 || subGroupTaskAcc > 0)
        return;

      default:
        setHasPendingTasks(false);
        return
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variant,JSON.stringify(getStakeholder),JSON.stringify(getGroupCompany)]);

  useEffect(() => {
    evaluatePendingTasks();
  }, [evaluatePendingTasks]);

  if (loadingStakeholder || loadingGroupCompany  || loadingAccelerator) {
    return null;
  }

  return (
    <>
      {!hasPendingTasks && <BubbleNoPendingTask isEntity/>}
      {hasPendingTasks && <BubblePendingTask variant={variant} isEntity={variant === "groupCompany"} />}
    </>
  );
};

export default BubbleTask;

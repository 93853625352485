import React, { useState } from 'react'
import * as Yup from 'yup'
import Form from '../../../../components/forms/Form/Form'
import useTranslate from '../../../generic/hooks/useTranslate'
import ControllerInput from '../../../generic/components/Inputs/ReactHookForm/ControllerInput'
import TemplateLabel from '../../../generic/templates/Label'
import SubmitButton from '../../../generic/components/Buttons/SubmitButton'
import FormProps from '../../../generic/forms/interfaces/FormProps';
import TemplateFormDefault from "../../../generic/templates/Modal";
import cls from './Form.module.scss';
import FormDevTools from '../../../devTools/Form';

import SelectInput from '../../../../components/inputs/Select/SelectInput/SelectInput'
import useGetCurrencies from '../../graphql/groupCompany/query/useGetCurrencies'
import _ from 'lodash';


const schema = Yup.object().shape({
    transactionCurrency: Yup.string().required('This field is required').nullable()
})

const StepCompanyInfo: React.FC<FormProps> = ({
    initialValues = {},
    onCompleteSubmit,
    Template = TemplateFormDefault,
    buttons = {},
    buttonSubmitProps,
    propsTemplate
}: FormProps) => {


    const [listCountry, setListCountry] = useState([]);

    const { translate } = useTranslate()
    const refForm = React.useRef(null);

    const handleSubmit = async values => {
        onCompleteSubmit && await onCompleteSubmit(values);
    }
    const onCompletedCurrencies = ({ countryList }) => {
        const currencies = _.uniqBy(_.map(countryList, ((item) => (
            {
                label: item.currency.currencyCode + " " + item.currency.currencySymbol + " " + item.currency.currencyName,
                id: item.currency.currencyCode
            }
        ))), 'id');
        setListCountry(currencies);
    };

    useGetCurrencies({ variables: { code: "", name: "", language: 'en' }, onCompleted: onCompletedCurrencies });



    return (
        <Form
            defaultValues={initialValues}
            schema={schema}
            onSubmit={handleSubmit}
            ref={refForm} optionsForm = {{mode:"onChange"}}
        >
            <FormDevTools />
            <Template buttons={{ ...buttons, submit: <SubmitButton  {...buttonSubmitProps}>Add</SubmitButton> }} props={propsTemplate}>

                <TemplateLabel
                    label={translate('MODULES.MAP.WIZARD_COMPANY_ADDRESS_CURRENCIES_FORM_FIELD_TRANSACTION_CURRENCY')}
                    className={cls.TemplateLabel}
                    isRequired
                >
                    <ControllerInput
                        render={SelectInput}
                        list={listCountry}
                        name='transactionCurrency'
                        clear={true}
                        defaultlabel={''}
                        placeholder='Select a currency from the list'
                    />
                </TemplateLabel>

                <TemplateLabel
                    label={translate('MODULES.MAP.WIZARD_COMPANY_ADDRESS_CURRENCIES_FORM_FIELD_TRANSACTION_EXTRA_CURRENCY')}
                    className={cls.TemplateLabel}
                >
                    <ControllerInput
                        render={SelectInput}
                        list={listCountry}
                        name='extraCurrencies[0]'
                        clear={true}
                        defaultlabel={''}
                        placeholder='Select a currency from the list'
                    />
                </TemplateLabel>

                <TemplateLabel
                    label={translate('MODULES.MAP.WIZARD_COMPANY_ADDRESS_CURRENCIES_FORM_FIELD_TRANSACTION_EXTRA_CURRENCY')}
                    className={cls.TemplateLabel}
                >
                    <ControllerInput
                        render={SelectInput}
                        list={listCountry}
                        name='extraCurrencies[1]'
                        clear={true}
                        defaultlabel={''}
                        placeholder='Select a currency from the list'
                    />
                </TemplateLabel>
                <TemplateLabel
                    label={translate('MODULES.MAP.WIZARD_COMPANY_ADDRESS_CURRENCIES_FORM_FIELD_TRANSACTION_EXTRA_CURRENCY')}
                    className={cls.TemplateLabel}
                >
                    <ControllerInput
                        render={SelectInput}
                        list={listCountry}
                        name='extraCurrencies[2]'
                        clear={true}
                        defaultlabel={''}
                        placeholder='Select a currency from the list'
                    />
                </TemplateLabel>

                <TemplateLabel
                    label={translate('MODULES.MAP.WIZARD_COMPANY_ADDRESS_CURRENCIES_FORM_FIELD_TRANSACTION_EXTRA_CURRENCY')}
                    className={cls.TemplateLabel}
                >
                    <ControllerInput
                        render={SelectInput}
                        list={listCountry}
                        name='extraCurrencies[3]'
                        clear={true}
                        defaultlabel={''}
                        placeholder='Select a currency from the list'
                    />
                </TemplateLabel>


                <TemplateLabel
                    label={translate('MODULES.MAP.WIZARD_COMPANY_ADDRESS_CURRENCIES_FORM_FIELD_TRANSACTION_EXTRA_CURRENCY')}
                    className={cls.TemplateLabel}
                >
                    <ControllerInput
                        render={SelectInput}
                        list={listCountry}
                        name='extraCurrencies[4]'
                        clear={true}
                        defaultlabel={''}
                        placeholder='Select a currency from the list'
                    />
                </TemplateLabel>

            </Template>

        </Form>
    )
}

export default StepCompanyInfo

import React from 'react';
import PAGES from "../../../constants/pages";
import ACTIONS from "../../../constants/actions"
import SystemLayout from "../../../layouts/System/SystemLayout";
import LottieView from "./Lottie/LottieView";

const routes = [
    {
        path:PAGES.MODULES.TEST.testLottie.path,
        exact: true,
        head:{
          title: "MODULE.TEST.LOTTIER"
        },
        component: <LottieView/>,
        layout: SystemLayout,
        propsLayout: {
          startupMenu:false,
          userMenu:false,
          fullMenu:false,
          showAssistance:false,
          cantBack: false
        },
      },
];

export default routes;

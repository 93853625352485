import useMutation from "src/hooks/custom/useMutation";
import useSession from "src/modules/session/hooks/useSession";

export const UPSERT_PROOF_ADDRESS = `
mutation upsertRenewalData(
    $startupId: ID!
    $groupCompanyId: ID!
	$renewalData: RenewalDataInput
  ) {
    upsertRenewalData(
      startupId: $startupId
      groupCompanyId: $groupCompanyId
      renewalData: $renewalData
    ) {
      id
  }
}
`;

const useConfirmProofAddress = () => {
  const { startupId } = useSession()
  const [mutation] = useMutation(
    UPSERT_PROOF_ADDRESS,
    { showSuccessNotification: true }
  );

  const save = async (groupCompanyId, stakeholderId) => {
    const data = {
      variables: {
        startupId,
        groupCompanyId,
        renewalData: {
          stakeholderTasksDetails: {
            stakeholderId,
            isProofOfAddressConfirmed: true
          }
        }
      }
    }
    const submitData = await mutation(data)
    return submitData
  }

  return save;
};

export default useConfirmProofAddress;

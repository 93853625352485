import React from 'react';
import _ from "lodash";
import { Field } from "formik";
import viewClasses from "../../../../../../components/Forms/View/ViewForms.module.scss";
import RowAdd from "../../../../../../../components/forms/contents/RowAdd/RowAdd";
import Classes from "../../../../../../../components/inputs/AuthorizedSignatories/AuthorizedSignatories.module.scss";
import TextInput from "../../../../Inputs/TextInput";
import checkOk from "../../../../../../../images/checkOk.svg";

const IndividualStakeholder=({fieldName, partyStakeholder, shAuthorizedSignatoriesEmailDisabled, handleOnChangeEmailPartyStakeholder}) => {

    return(<>
        {
            partyStakeholder.isAnEntity && partyStakeholder.authorizedSignatory ?
                <React.Fragment>
                    <div
                        className={`${viewClasses.ContainerRow} ${viewClasses.ContainerDataPhoto}`}>
                        <label>Corporate signatory:</label>
                    </div>
                    <div className={`${viewClasses.ContainerRow}`}>
                        <Field
                            name={`${fieldName}.authorizedSignatory.fullName`}>
                            {({field, form}) => {
                                return (
                                    <RowAdd
                                        label={"Name:"}
                                        asterisk={true}
                                        classNameInput={Classes.input}
                                        input={
                                            <TextInput
                                                type={'text'}
                                                field={field}
                                                form={form}
                                                disabled={true}
                                                value={field.value}
                                                classNameError={Classes.errorForm}
                                                placeholder={"Enter corporate signatory name..."}
                                            />
                                        }
                                    />
                                )
                            }}
                        </Field>
                        <Field
                            name={`${fieldName}.authorizedSignatory.email`}>
                            {({field, form}) => {
                                return (
                                    <RowAdd
                                        label={"Email address:"}
                                        asterisk={true}
                                        classNameInput={Classes.input}
                                        input={
                                            <TextInput
                                                type={'email'}
                                                field={field}
                                                form={form}
                                                disabled={shAuthorizedSignatoriesEmailDisabled[0]}
                                                onChange={(e) => handleOnChangeEmailPartyStakeholder(form, field, e.target.value, 0)}
                                                errorMessage={_.get(form.errors, field.name)}
                                                classNameError={Classes.errorForm}
                                                placeholder={"Email address..."}
                                            />
                                        }
                                    />
                                )
                            }}
                        </Field>
                    </div>
                </React.Fragment>
                :
                <React.Fragment>
                    <div className={`${viewClasses.ContainerRow}`}>
                        <Field
                            name={`${fieldName}.fullName`}>
                            {({field, form}) => {
                                return (
                                    <RowAdd
                                        label={"Name:"}
                                        asterisk={true}
                                        classNameInput={Classes.input}
                                        input={
                                            <TextInput
                                                type={'text'}
                                                field={field}
                                                form={form}
                                                disabled={true}
                                                classNameError={Classes.errorForm}
                                                placeholder={"Enter coporate signatory name..."}
                                            />
                                        }
                                    />
                                )
                            }}
                        </Field>
                        <Field
                            name={`${fieldName}.email`}>
                            {({field, form}) => {
                                return (
                                    <RowAdd
                                        label={"Email address:"}
                                        asterisk={true}
                                        classNameInput={Classes.input}
                                        input={
                                            <TextInput
                                                type={'email'}
                                                field={field}
                                                form={form}
                                                disabled={shAuthorizedSignatoriesEmailDisabled[0]}
                                                onChange={(e) => handleOnChangeEmailPartyStakeholder(form, field, e.target.value, 0)}
                                                errorMessage={_.get(form.errors, field.name)}
                                                classNameError={Classes.errorForm}
                                                placeholder={"Email address..."}
                                                rightLabel={shAuthorizedSignatoriesEmailDisabled[0] ? <img style={{'position': 'absolute', 'top': '-8px', 'right': '-10px'}} width={30} height={30} src={checkOk}/> : null}
                                            />
                                        }
                                    />
                                )
                            }}
                        </Field>
                    </div>
                </React.Fragment>
        }
    </>)
};

export default IndividualStakeholder;

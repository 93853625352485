import useCustomMachine from '../../../../generic/hooks/useCustomMachine';
import React, { FC } from 'react';
import Button from '../../../../generic/components/Buttons/Button';
import { StepProps } from "../../../../generic/components/Wizard/Step";
import TemplateWizard from '../../../../generic/templates/Modal/TemplateWizard';
import useTranslate from '../../../../generic/hooks/useTranslate';
import Form from "../../Forms/FormCompanyMoreDetails";
import useUpdateGroupCompany from "./../../../../../hooks/services/modules/groupCompany/useUpdateGroupCompany";
import useSession from "src/modules/session/hooks/useSession";

const Step1: FC<StepProps> = () => {

  const { translate } = useTranslate();
  const { next, send, state, prev } = useCustomMachine();
  const [updateCompanyDetail] = useUpdateGroupCompany();
  const { groupCompanyId } = state.context.data;
  const { startupId } = useSession();

  const getValuesForMutation = (state, valuesStep) => {
    const { context } = state;
    return { ...valuesStep, registeredAddress: context.data.company_info }
  };

  const handleSubmit = React.useCallback(
    async (values) => {
      const variables = {
        groupCompanyId,
        startupId,
        groupCompany: values
      }
      await updateCompanyDetail({ variables });
    }, [updateCompanyDetail, groupCompanyId, startupId]);

  const handleCompleteSubmit = async values => {
    await handleSubmit(getValuesForMutation(state, values));
    next(values);
  };

  const handleCancel = async values => {
    send("CANCEL");
  };

  const handlePrevious = () => {
    prev('PREV');
  }

  return (
    <React.Fragment>
      <Form onCompleteSubmit={handleCompleteSubmit} Template={TemplateWizard} propsTemplate={{
        title: translate(`MODULES.MAP.WIZARD_COMPANY_DETAIL_MORE_INFO_FORM_TITLE`),
        subTitle: "Company Information"
      }}
        buttons={{ previous: <Button type={'button'} onClick={handlePrevious} variant="secondary">Previous</Button>, cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }}>
      </Form>
    </React.Fragment>
  )
}

export default Step1;

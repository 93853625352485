import { SelectNewDataProtectionContact } from "../steps"
import  TemplateWizardSkeletonMachine  from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
const useSteps = () => {
    return {
        InitialData:{
            render:TemplateWizardSkeletonMachine
        },
        pincasData: {
            render: TemplateWizardSkeletonMachine,
        },
        SaveNewDataProtectionContact:{
            render:TemplateWizardSkeletonMachine
        },
        SelectDataProtectionContact:{
            render:SelectNewDataProtectionContact
        }
    }
}
export default useSteps;
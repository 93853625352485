import React from "react";
import * as Yup from "yup";
import Form from "../../../../components/forms/Form/Form";
import useTranslate from "../../../generic/hooks/useTranslate";
import ControllerInput from "../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import TemplateLabel from "../../../generic/templates/Label";
import SubmitButton from "../../../generic/components/Buttons/SubmitButton";
import FormProps from "../../../generic/forms/interfaces/FormProps";
import TemplateFormDefault from "../../../generic/templates/Modal";
import cls from "./Form.module.scss";
import _ from "lodash";
import FormDevTools from "../../../devTools/Form";
import FileUpload from "../../../generic/components/Inputs/FileUpload";
import Typography from "../../../generic/components/Texts/Typography";

const schema = Yup.object().shape({
  curriculumVitae: Yup.object().nullable().required("Required"),
});

const StepCompanyInfo: React.FC<FormProps> = ({
  initialValues = {},
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
}: FormProps) => {
  
  const { translate } = useTranslate();
  const refForm = React.useRef(null);

  const handleSubmit = async (values) => {
    onCompleteSubmit && (await onCompleteSubmit(values));
  };

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onSubmit={handleSubmit}
      ref={refForm}
      optionsForm={{ mode: "onChange" }}
    >
      <FormDevTools />
      <Template
        buttons={{
          ...buttons,
          submit: <SubmitButton {...buttonSubmitProps}>Add</SubmitButton>,
        }}
        props={propsTemplate}
      >
        <div className={cls.ContainerForm}>
          <div>
            <Typography
              component={"label"}
              variant={"body"}
              color={"dark-grey"}
            >
              {translate("MODULES.STAKEHOLDER.FORM.CV.EXAMPLE")}
            </Typography>
            <div className={cls.CurriculumVitae} />
          </div>
          <TemplateLabel
            label={translate("MODULES.STAKEHOLDER.FORM.CV.LABEL")}
            className={cls.TemplateLabelColumn}
            isRequired
          >
            <ControllerInput
              render={FileUpload}
              name="curriculumVitae"
              defaultValue={[]}
              getValue={_.head}
              limit={1}
            />
          </TemplateLabel>
        </div>
      </Template>
    </Form>
  );
};

export default StepCompanyInfo;

import React from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";

const EditExerciseGenerate = ({ go, ...props }) => {
  const params = useParams();

  return (
    <MicroFrontend id="MF_Edit_Exercise_Generate" component={"./EditExerciseGenerate"} mf="capTable" params={{ go, ...props, ...params }} />
  );
};

export default EditExerciseGenerate;

import { gql, useQuery } from '@apollo/client';
const GET_DOCUMENT_LIST = gql` query getDocumentList($startupId:ID!) {
    documentList(startupId:$startupId) {
      id
      name
      status
      freemiumValid
      groupCompanies {
        groupCompany {
          id
          name
        }
        signedBy {
          id
        }
      }
      executedOnDate
      subCategories
      kind
      type {
        code
        label
        allowedActions
      }
      id
      file {
        id
        name
        size
        type
      }
      avatars {
        name
        type
        imageUrl
      }
          parentDocument {
        id
        type {
          code
        }
      }
      relatedDocuments {
        id
        type {
          code
        }
          }
      }
  }`;
const useGetDocuments = (config)=>{
    const {data,loading} = useQuery(GET_DOCUMENT_LIST,config)
    return {data,loading}
}
export default useGetDocuments;
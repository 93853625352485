import _ from "lodash";
import React from 'react';
import { useDispatch } from "react-redux";
import PrimaryButton from "../../../../components/generic/Buttons/PrimaryButton/PrimaryButton";
import Loading from "../../../../components/generic/Loading/Loading";
import Text from "../../../../components/text/Text/Text";
import Title from "../../../../components/text/Title/Title";
import { goToPage } from "../../../../store/actions/page";
import { Constants } from '../../../../v1/utils/constants';
import useSession from '../../../session/hooks/useSession';
import classesModal from "./PaymentSuccessMessage.module.scss";
import Typography from "../../../../modules/generic/components/Texts/Typography";
import useTranslate from "../../../../modules/generic/hooks/useTranslate";


const PaymentSuccessMessage = ({ loading, product, id, invoiceNumber }) => {

  const dispatch = useDispatch();
  const { startup } = useSession();
  const { translate } = useTranslate();

  const goToProfile = () => {
    if (product.code === "FORMATION_ENGAGEMENT") {
      goToPage(dispatch, Constants.PAGES.questionnaireIntro, { matterId: id });
      return
    }
    goToPage(dispatch, Constants.PAGES.MODULES.MAP.maps);
  }

  return (
    <React.Fragment>
      <div className={classesModal.PaymentSuccessMessageGlobal}>
        <Loading loading={loading || !product} showChildern={false}>
          <div className={classesModal.PaymentSuccessMessage}>
            {_.get(product, "code","") === "FORMATION_CAYMAN_PAYMENT" ? (
              <div>
                <Typography component={"p"} className={classesModal.title}>
                  {translate(
                    "MODULES.CHECKOUT.PAYMENT_SUCCESS_KY_TITTLE"
                  )}
                </Typography>
                <Typography component={"p"} className={classesModal.normalText}>
                  {translate(
                    "MODULES.CHECKOUT.PAYMENT_SUCCESS_KY_HIT_1"
                  )}
                </Typography>
                <Typography component={"p"} className={classesModal.normalText}>
                  {translate(
                    "MODULES.CHECKOUT.PAYMENT_SUCCESS_KY_HIT_2"
                  )}
                </Typography>
                <Typography component={"p"} className={classesModal.normalText}>
                  {translate(
                    "MODULES.CHECKOUT.PAYMENT_SUCCESS_KY_HIT_3"
                  )}
                </Typography>
                <PrimaryButton onClick={goToProfile} className={classesModal.Button}>
                  <Text uuid={`MODULES.CHECKOUT.PAYMENT_SUCCESS_BUTTON_${_.get(product, "code")}`} />
                </PrimaryButton>
              </div>
            ) : (
              <>
                <div>
                  <Title><Text uuid={`MODULES.CHECKOUT.PAYMENT_SUCCESS_TITLE_${_.get(product, "code")}`} /></Title>
                  <Text uuid={`MODULES.CHECKOUT.PAYMENT_SUCCESS_TEXT_${_.get(product, "code")}`} params={{ product: _.get(product, "name"), startup: _.get(startup, "name") }} />
                  <PrimaryButton onClick={goToProfile} className={classesModal.Button}>
                    <Text uuid={`MODULES.CHECKOUT.PAYMENT_SUCCESS_BUTTON_${_.get(product, "code")}`} />
                  </PrimaryButton>
                </div>
                <div className={classesModal.Confirm}>
                  <Text uuid={"MODULES.CHECKOUT.PAYMENT_CONFIRM_SUBSCRIPTION"} />
                </div>
              </>
            )}
          </div>
        </Loading>
      </div>
    </React.Fragment >
  )
}

export default PaymentSuccessMessage;

import PropTypes from "prop-types";
import React from "react";
import DetailsTabTitle from "src/components/templates/DetailsTabTitle";
import Divider from "@mui/material/Divider";
import classes from "../../styles.module.scss";
import _ from "lodash";
import useNavigation from "src/modules/generic/hooks/useNavigation";
import { Constants } from "src/v1/utils";
import useModal from "src/modules/generic/hooks/useModal";
import Button from "src/modules/generic/components/Buttons/Button";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import ModalDataProtectionContact from "src/modules/startup/Stakeholder/ADGM/DataProtectionContact/DataProtectionContact";
import ModalEditDataProtectionContact from "src/modules/startup/GroupCompany/ADGM/EditDataProtectionContact";
import UserItem from "src/views/modules/startups/company/companyView/Tabs/KeyPeopleTab/components/UserItem";
import { RoleAccess } from "src/v1/components";

function DataProtectionContactPanel({ dataProtectionContact, isLock, isFormation,groupCompany, havePincas = false }) {
  const { goTo } = useNavigation();
  const { openModal } = useModal();
  const { translate } = useTranslate();

  const actionButton = (
    <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
    <Button
      variant="card"
      disabled={isLock}
      children={translate("VIEW_COMPANY_TAB_BUTTON_DETAILS_EDIT")}
      onClick={() => {
        openModal(isFormation?ModalDataProtectionContact:ModalEditDataProtectionContact, {initialValues:{groupCompanyId:groupCompany.id}});
      }}
    />
    </RoleAccess>
  );

  const goToStakeholder = (stakeholder) => {
    if (stakeholder.isAnEntity) {
      goTo(Constants.PAGES.viewStakeholderCompany, { id: stakeholder.id });
    } else {
      goTo(Constants.PAGES.viewStakeholderIndividual, { id: stakeholder.id });
    }
  };

  return (
    <>
      <Divider className={classes.divider} />
      <DetailsTabTitle title="Data protection contact" buttons={actionButton} inPincas={havePincas}/>
      <div className={classes.containerDirectorsTab}>
        {_.map(dataProtectionContact, (contact) => {
          return (
            <UserItem
              fullName={contact.fullName}
              avatar={contact.avatar}
              onClick={() => goToStakeholder(contact)}
            ></UserItem>
          );
        })}
      </div>
    </>
  );
}

DataProtectionContactPanel.propTypes = {
  dataProtectionContact: PropTypes.any,
  isEdit: PropTypes.bool,
  isLock: PropTypes.bool,
  isFormation: PropTypes.bool,
  havePincas: PropTypes.bool,
};

export default DataProtectionContactPanel;

import _ from 'lodash';
import React from 'react';
import { useDispatch } from "react-redux";
import Button from "../../../../../generic/components/Buttons/Button";
import useMachine from '../../../../../generic/context/MachineContext/useMachine';
import useModal from "../../../../../generic/hooks/useModal";
import useTranslate from '../../../../../generic/hooks/useTranslate';
import { clearAssistatText } from "../../../../../generic/store/action";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import FormAddBoardResolutionDocument from '../../../../../../modules/accelerators/modals/boardResolution/forms/formAddBoardResolutionDocument';

/**
 * Step to add board resolution document
 * @param {*} param0
 * @returns
 */
function AddBoardResolutionDocument({ openParam }) {
  const { next, context, prev } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const { closeModal: onClose } = useModal();

  const handleCancel = (values) => {
    dispatch(clearAssistatText())
    onClose();
  }

  const handleCompleteSubmit = async (values) => {
    next(values)
  }

  const handlePrev = () => {
    prev()
  }

  return (
    <FormAddBoardResolutionDocument
      dataGroupCompanies={_.get(context, 'allGroupCompanies', [])}
      initialValues={{}}
      handlePrev={handlePrev}
      key={_.get(context, 'boardResolution')}
      buttons={{
        cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
      }}
      onCompleteSubmit={handleCompleteSubmit}
      propsTemplate={{
        title: "Upload document",
        subTitle: "Board Resolution",
      }}
      Template={TemplateWizard}
    />
  );
}

export default AddBoardResolutionDocument;

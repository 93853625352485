import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Divider from "@mui/material/Divider";
import DetailsTabTitle from "src/components/templates/DetailsTabTitle";
import { Constants } from "src/v1/utils";
import useNavigation from "src/modules/generic/hooks/useNavigation";
import { useParams } from "react-router-dom";
import useModal from "src/modules/generic/hooks/useModal";
import Button from "src/modules/generic/components/Buttons/Button";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import EditAuthorisedSignatory from "src/modules/startup/GroupCompany/ADGM/EditAuthorisedSignatory";
import classes from "../../styles.module.scss";
import UserItem from "src/views/modules/startups/company/companyView/Tabs/KeyPeopleTab/components/UserItem";
import { RoleAccess } from "src/v1/components";

function AuthorisedSignatoriesPanel({
  adgmAuthorisedSignatories,
  data,
  isLock,
  havePincas = false
}) {
  const { goTo } = useNavigation();
  const { openModal } = useModal();
  const { id: groupCompanyId } = useParams();
  const { translate } = useTranslate();

  const goToStakeholder = (stakeholder) => {
    if (stakeholder.isAnEntity) {
      goTo(Constants.PAGES.viewStakeholderCompany, { id: stakeholder.id });
    } else {
      goTo(Constants.PAGES.viewStakeholderIndividual, { id: stakeholder.id });
    }
  };

  const actionButton = (
    <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
    <Button
      variant="card"
      disabled={isLock}
      children={translate("VIEW_COMPANY_TAB_BUTTON_DETAILS_EDIT")}
      onClick={() => {
        openModal(EditAuthorisedSignatory, {
          initialValues: { adgmAuthorisedSignatories, groupCompanyId,isIncorporated:data.kind === 'INCORPORATED'},
        });
      }}
    />
    </RoleAccess>
  );

  return (
    <>
      <Divider className={classes.divider} />
      <DetailsTabTitle
        title="ADGM authorised signatories"
        buttons={actionButton}
        inPincas={havePincas}
      />
      <div className={classes.containerDirectorsTab}>
        {_.map(adgmAuthorisedSignatories, (signatory) => {
          return (
            <UserItem
              fullName={signatory.fullName}
              avatar={signatory.avatar}
              onClick={() => {
                if (!signatory.noLink) {
                  goToStakeholder(signatory);
                }
              }}
              useNominee ={data?.formationData?.isUsingNominee}
              isPrimarySignatory = {!signatory.primarySignatory}
            ></UserItem>
          );
        })}
      </div>
    </>
  );
}

AuthorisedSignatoriesPanel.propTypes = {
  adgmAuthorisedSignatories: PropTypes.any,
  data: PropTypes.any,
  isEdit: PropTypes.bool,
  isLock: PropTypes.bool,
  havePincas: PropTypes.bool,
};

export default AuthorisedSignatoriesPanel;

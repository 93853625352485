import useMutation from "src/hooks/custom/useMutation";

const UPDATE_STAKEHOLDER_OTHER_NATIONALITY = `
mutation updateStakeholder(
    $startupId: ID!
    $stakeholderId: ID!
    $stakeholderData: StakeholderInput!
) {
    updateStakeholder(
        startupId: $startupId
        stakeholderId: $stakeholderId
        stakeholderData: $stakeholderData
    ) {
        id
        fullName
            nationalities {
                countries {
                    country {
                        name
                        code
                    }
                    hasPassport {
                        description
                        question
                    }
                }
            }
        }
    }`;
const useUpdateStakeholderOtherNationality = () => {
    const [mutation, loading] = useMutation(UPDATE_STAKEHOLDER_OTHER_NATIONALITY, { showSuccessNotification: true });
    return mutation;
}
export default useUpdateStakeholderOtherNationality;
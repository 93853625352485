import _ from 'lodash';
import React, { useMemo } from 'react';
import { useDispatch } from "react-redux";
import Button from "../../../../../generic/components/Buttons/Button";
import useMachine from '../../../../../generic/context/MachineContext/useMachine';
import useModal from "../../../../../generic/hooks/useModal";
import useTranslate from '../../../../../generic/hooks/useTranslate';
import { clearAssistatText } from "../../../../../generic/store/action";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import FormQuestion from '../../forms/formQuestion';
import FormExplaind from '../../forms/formExplaind';

/**
 * Step to start questionnaire
 * @param {*} param0
 * @returns
 */
function StartQuestionnaire({ openParam }) {

  const { next, context, prev } = useMachine();
  const { translate } = useTranslate()
  const dispatch = useDispatch();
  const { closeModal: onClose } = useModal();

  const handleCancel = (values) => {
    dispatch(clearAssistatText())
    onClose();
  }

  const handleCompleteSubmit = async (values) => {
    next(values)
  }

  const handlePrev = () => {
    prev()
  }

  const handleInicialValues = useMemo(() => {
    const actualCount = _.get(context, 'currentQuestionnarie');
    const prevResult = _.get(context, 'results');
    const prevData = _.get(prevResult, `${actualCount}`, {});
    return prevData;
  }, [context]);

  const questionData = {
    isLast: _.get(context, 'currentQuestionnarie') === _.get(context, 'questionnarie').length - 1,
    handlePrevious: _.get(context, 'currentQuestionnarie') > 0,
    question: _.get(context, `questionnarie[${_.get(context, "currentQuestionnarie")}].fields.yes_no.label`, ""),
    defaultExplain: _.get(context, `questionnarie[${_.get(context, "currentQuestionnarie")}].fields.yes_no.defaultExplain`, null),
    toolTips: _.get(context, `questionnarie[${_.get(context, "currentQuestionnarie")}].fields.tooltip`, []),
    options: _.get(context, `questionnarie[${_.get(context, "currentQuestionnarie")}].fields.yes_no.options`, []),
    explaind: _.get(context, `questionnarie[${_.get(context, "currentQuestionnarie")}].fields.text.label`, ""),
    explaindPlaceholder: _.get(context, `questionnarie[${_.get(context, "currentQuestionnarie")}].fields.text.placeholder`, ""),
    useOptions: _.get(context, `questionnarie[${_.get(context, "currentQuestionnarie")}].fields.yes_no.label`, "") === "" ? false : true,
  }

  return (
    <>
      {questionData.useOptions ? (
        <FormQuestion
          initialValues={handleInicialValues}
          handlePrev={handlePrev}
          questionData={questionData}
          key={_.get(context, 'currentQuestionnarie')}
          buttons={{
            cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
          }}
          onCompleteSubmit={handleCompleteSubmit}
          propsTemplate={{
            title: _.get(context, `questionnarie[${_.get(context, "currentQuestionnarie")}].title`, ""),
            subTitle: "Questionnaire"
          }}
          Template={TemplateWizard}
        />
      ) : (
        <FormExplaind
          initialValues={handleInicialValues}
          handlePrev={handlePrev}
          questionData={questionData}
          key={_.get(context, 'currentQuestionnarie')}
          buttons={{
            cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
          }}
          onCompleteSubmit={handleCompleteSubmit}
          propsTemplate={{
            title: _.get(context, `questionnarie[${_.get(context, "currentQuestionnarie")}].title`, ""),
            subTitle: "Questionnaire"
          }}
          Template={TemplateWizard}
        />
      )}
    </>
  );
}

export default StartQuestionnaire;

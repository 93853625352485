import { useMemo } from 'react';
import useBusinessRules from 'src/modules/clara/hooks/useBusinessRules';
import useSession from 'src/modules/session/hooks/useSession';
import _ from "lodash";

const useGuards = () => {
  const { isOwner } = useBusinessRules()
  const { session } = useSession()
  return useMemo(() => ({

    needMore: (context) => {
      return _.get(context, 'MoreToAdd.another');
    },
    noNeedMore: (context) => {
      return _.get(context, 'MoreToAdd.another') === false;
    },    

    isPositivePrimary: (context) => {
      if (context.ConfirmForm.accept === true && context.ConfirmForm.accept2 === undefined) {
        return true;
      }
    },

    isNegativeAll: (context) => {
      if (context.ConfirmForm.accept === false && context.ConfirmForm.accept2 === false) {
        return true;
      }
    },

    isPositiveSecond: (context) => {
      if (context.ConfirmForm.accept === false && context.ConfirmForm.accept2 === true) {
        return true;
      }
    },

  }), [isOwner, session]);
}

export default useGuards;

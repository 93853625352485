import useSession from "src/modules/session/hooks/useSession";
import useMutationDelete from 'src/hooks/custom/useMutationDelete';

const DELETE_DOCUMENT = `
mutation deleteTemplate($startupId:ID!,$templateId:ID!){
    deleteCustomTemplate(startupId:$startupId,templateId:$templateId){
      id
    }
  }  
`;

const useDeleteCustomTemplate = (props) => {

  const { startupId } = useSession();

	const [mutation] = useMutationDelete(DELETE_DOCUMENT,{ ...props },{})

    const customTemplateDelete = async (customTemplateId) => {

        const variables = {
            startupId: startupId,
            templateId: customTemplateId
        }

        return mutation({variables})
    }

    return customTemplateDelete;
};

export default useDeleteCustomTemplate;

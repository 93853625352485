import _ from "lodash";
import {Constants} from "./constants";
import Mustache from "mustache";
import React from 'react'

/** name: getValueIsFunctionOrValue
 * THIS FUNCTION CHECK IF IS A FUNCTION OR NOT AND RETURN HIS VALUE
 * params:
 *  -obj: the object you want to check and get his value
 *  -properties: the specific prop you want to get
 *  -parameters: parameters to pass in case the object was a function
 *  -defaultValue: a default value to return
 * 
 * returns: VALUE OF THE OBJECT(FIRST PARAMETER)
 * 
*/


const getValueIsFunctionOrValue = (obj, properties, parameters, defaultValue) => {
  let value = defaultValue;
  //Accept diferentes options 
  if (_.get(obj, properties, null) && typeof _.get(obj, properties, null) === "function") {
    value = _.get(obj, properties, null)(parameters)
  } else if (_.get(obj, properties, null)) {
    value = _.get(obj, properties, null);
  } else if (properties === null && obj === "function") {
    value = obj(parameters)
  } else if (properties === null) {
    value = obj;
  }
  return value;
};

/** name: quantityCheck
 *  check the amount and return limited amount specified on the 2nd argument
 * params: 
 *  quantity: the quantity you want to limit
 *  max: specify the limit for the value of the first argument
 * RETURN: limited quantity
*/
const quantityCheck = (quantity, max) => {
  max = max ? max : 99;

  if (quantity > max) {
    return max
  } else { return quantity }

}



/** name: generateNewGlobalValue
 *  just generate a global variable with a change listener
 * params: 
 *  defaultValue: just a defaultValue to set on the new globalVariable
 *  
 * RETURN: created global variable
*/

const generateNewGlobalValue = (defaultValue) => {
  return {
    aInternal: defaultValue,
    aListener: function (val) { },
    set a(val) {
      this.aInternal = val;
      this.aListener(val);
    },
    get a() {
      return this.aInternal;
    },
    registerListener: function (listener) {
      this.aListener = listener;
    }
  }
}

/** name: getTranslateTextByKey
 *  returns some text setted on constants depends on the key passed on the 1st parameter
 * params: 
 *  key:
 *  
 * RETURN: created global variable
*/

const getTranslateTextByKey = (key, params, defaultKey) => {
  if (!key) {
    return null;
  }
  const label = Constants.TRANSLATES[key.toUpperCase()] ? Constants.TRANSLATES[key.toUpperCase()] : getTranslateTextByKey(defaultKey, params);
  if (!label) {
    return key;
  }
  return Mustache.render(label, params);

}
/** name: getFormatByKey
 *  returns some text setted on constants depends on the key passed on the 1st parameter
 * params: 
 *  key:
 *  
 * RETURN: created global variable
*/

const getFormatByKey = (key, params, defaultKey) => {
  if (!key) {
    return {};
  }
  const obj = Constants.FORMATS[key.toUpperCase()] ? Constants.FORMATS[key.toUpperCase()] : getFormatByKey(defaultKey, params);
  if (!obj) {
    return {};
  }
  
  return params?{...obj,...params}:obj;

}

/** name: transform a json object to  string with each field separated with ,
 *  returns string with field separated by coma
 * params: 
 *  json: json object
 *  
 * RETURN: string with parameters
*/

const jsonToStringParameters = (json) => {
  let parameters = "";
  _.forEach(json, (value, key) => {
    if (value) {
      if (parameters !== "") {
        parameters += ",";
      }

      if (_.isArray(value)) {
        parameters += key + ":" + JSON.stringify(value.toString())
      } else if (typeof value === "number") {
        parameters += key + ': ' + value;
      } else {
        parameters += key + ': "' + value + '"';
      }

    }
  });
  return parameters;
};

/** name: isEqual validate path property and compare value if the property exist ,
 *  returns boolean
 * params:
 *  object: object
 *  path: path to reach the property to compare
 *  value: value to compare against the property returned for the path
 *
 * RETURN: true or false
 */

export const context = React.createContext();

export const isEqual = (object, path, value) => {
  return _.get(object, path, false) === value
};
window.formInitValue = null;


const getInitialValues = (initialValues, key) => {
  
  if (process.env && process.env.NODE_ENV == "development" && window.formInitValue) {
    let jsonMock = {};
    if (typeof window.formInitValue == "object"){
      jsonMock = window.formInitValue;
    }else{
      jsonMock = require("./jsons/" + window.formInitValue + ".json");
    }
    if (!jsonMock) {
      jsonMock = {};
    }
    return _.merge(jsonMock, initialValues);
  }
  return initialValues;
}
const getValueFromKey = (contentConfig, key, defaultValue = null) => {

  return contentConfig ? _.get(contentConfig.find(c => c.key === key), 'value', defaultValue) : null

};
const getTypeUserByEmail = (email)=>{
  if (email){
    if (email.indexOf("@clara.co")>=0){
      return "Internal"
    }
    if (email.indexOf("mailosaur")>=0){
      return "Tester"
    }
  }
  return "External"
}

const optimizeFields = (query, variables)=> {

  //initially query must have all fields with @include directive



}

export default {
  getValueIsFunctionOrValue,
  quantityCheck,
  generateNewGlobalValue,
  getTranslateTextByKey,
  jsonToStringParameters,
  isEqual,
  getFormatByKey,
  getInitialValues,
  getValueFromKey,
  getTypeUserByEmail,
  optimizeFields,
}

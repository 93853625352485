import React, {FC, useEffect} from 'react';
import animationData from './../../../../../../../generic/animations/formation-in-progress.json'
import { StepProps } from '../../../../../../../generic/components/Wizard/Step';
import useCustomMachine from '../../../../../../../generic/hooks/useCustomMachine';
import ModalTourTextlessTemplate from '../../../../../../../../components/templates/ModalTourTextlessTemplate';
import Lottie from '../../../../../../../generic/components/Animations/Lottie/Lottie';
import useSubmittedFormation from '../../../mutations/useSubmittedFormation';
import _ from 'lodash';
import useSession from '../../../../../../../session/hooks/useSession';

/**
 -  Forming Step
 **/
const FormingStep: FC<StepProps> = () => {
  const { next, state } = useCustomMachine();
  const [ submittedFormation ] = useSubmittedFormation({},{});
  const { startupId } = useSession();

  const doSubmit = async () => {
    await submittedFormation({
      variables:{
        startupId: startupId,
        groupCompanyId: _.get(state,'context.data.initialData.groupCompanyId'),
      },
    })
      if (state?.context?.data?.initialData?.callback) {
          state?.context?.data?.initialData?.callback();
      }
    next()
    }

    useEffect(() => {
      doSubmit()
    },[] );

  return (
    <>
      <ModalTourTextlessTemplate
        image={<Lottie
          height={403}
          width={602}
          options={{
            loop: true,
            autoplay: true,
          }}
          animation={animationData} />}
        className= {''}
      />
    </>
  )
}

export default FormingStep;

import React, { useMemo } from 'react';
import _ from 'lodash';
import ToolTip from '../../../../../../../generic/Tooltips/TooltipIput/TooltipInput';
import classes from './Styles.module.scss'
import MaterialAvatarLabel from '../../../../../../../../modules/generic/components/Avatars/AvatarLabel'

const Parties = ({ data, categories, ...props }) => {

  const parties = useMemo(() => {
    const { groupCompanies, stakeholders } = _.get(data, 'original');
    const avatars = [];
    const moreAvatars = [];
    _.forEach(groupCompanies, gc => {
      if (avatars.length <= 4) {
        avatars.push({ imageUrl: gc.groupCompany.avatar, name: gc.groupCompany.name });
      } else {
        moreAvatars.push({ imageUrl: gc.groupCompany.avatar, name: gc.groupCompany.name })
      }
    });
    _.forEach(stakeholders, st => {
      if (avatars.length <= 4) {
        avatars.push({ imageUrl: st.avatar, name: st.fullName });
      } else {
        moreAvatars.push({ imageUrl: st.avatar, name: st.fullName })
      }
    });
    return { avatars, moreAvatars }
  }, [JSON.stringify(data.original)]);

  return (
    <div className={classes.containerParties}>
      {parties.avatars.map((avatar, i) => {
        return <MaterialAvatarLabel className={classes.li} avatar={avatar.imageUrl} showLabel={false} name={avatar.name} key={i} color={"#2c74ff"}/>
      })}
      {!_.isEmpty(parties.moreAvatars) && <ToolTip data={parties.moreAvatars} direction={"right"} type={"parties"} />}
    </div>
  )
}

export default Parties;

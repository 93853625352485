import useMutation from '../../../../hooks/custom/useMutation'

export const INVITE_COLLABORATOR_TO_STARTUP = `
mutation inviteCollaboratorToAStartup($startupId: String!,$stakeholderId: String!, $sharingProfile: SharingProfileInput!) {
  inviteCollaboratorToAStartup(startupId: $startupId, stakeholderId: $stakeholderId,sharingProfile: $sharingProfile) {
    _id
    userId
    role
    fullName
    avatar
    email
    lastLogin
    lastInvitationSent
  }
}   
`

const useInviteCollaborator = ({ ...props }) => {
  const mutation = useMutation(
    INVITE_COLLABORATOR_TO_STARTUP,
    { showSuccessNotification: true },
    { refetchQueries: ["companyCorporateMap"], ...props }
  )

  return mutation
}

export default useInviteCollaborator

import React, { useState } from "react";
import cls from "./FileUpload.module.scss";
import NoFilesUploaded from "./NoFilesUploaded";
import UploadComponent from "./UploadComponent";
import _ from "lodash";
import { DropEvent, useDropzone } from "react-dropzone";
import ClassNames from "classnames";
import Scrollbars from "../../../../../components/generic/Scrollbar/Scrollbar";
import ButtonIcon from '../../../components/Buttons/ButtonIcon'
import Icon from "../../../components/Icon";
import Typography from 'src/modules//generic/components/Texts/Typography';

interface FileInputType {
  id: string;
  name: string;
  size: number;
  type: string;
}

interface FileUploadListProps {
  [key: string]: any;
  onChange?(file: FileInputType): void;
  value: FileInputType | FileInputType[];
  testUrlFile?: string;
  className?: string;
  /*To limit the amount of files to be uploaded*/
  limit?: number;
}

const FileUploadList: React.FC<FileUploadListProps> = ({
  onChange,
  value,
  disabled = false,
  limitFile = 1,
  limit,
  getValue = (e) => e,
  testUrlFile,
  className,
  accept = null,
  icons,
}) => {

  const [bufferFiles, setBufferFiles] = useState([]);
  const [filesUploadedSuccess, addFileUploaded] = useState(value);
  const [isEnableUploadFile, setIsEnableUploadFile] = useState(!disabled);
  const [uploadCounter, setUploadCounter] = useState(0);
  const [auxCount, setAuxCount] = useState();

  React.useEffect(() => {
    if (_.isEmpty(bufferFiles) && _.isEmpty(filesUploadedSuccess)) {
      setIsEnableUploadFile(true);
      setAuxCount(0);
      return
    }
    if (!!filesUploadedSuccess.length && uploadCounter === 0) {
      setIsEnableUploadFile(false);
    }
  }, [filesUploadedSuccess, bufferFiles]);


  const handleAddFileUploaded = (file: FileInputType, fileBuffer?: any) => {
    let y;
    addFileUploaded((pre) => {
      const files = _.concat(pre, file);
      y = files.length;
      onChange && onChange(getValue(files));
      return files;
    });
    if (uploadCounter === y && uploadCounter !== 0) {
      setBufferFiles([]);
      setIsEnableUploadFile(false);
      setAuxCount(y);
    } else {
      let t = bufferFiles.length;
      if (auxCount + t === y) {
        setBufferFiles([]);
        setIsEnableUploadFile(false);
        setAuxCount(y);
      } else if (auxCount === undefined) {
        setBufferFiles([]);
        setIsEnableUploadFile(false);
        setAuxCount(y);
      }
    }
  };

  const onDropAccepted = (files: any, event) => {
    setBufferFiles((pre) => {
      const x =_.concat(pre, files);
      setUploadCounter(x.length);
      return x;
    });
  };

  const deleteFileUploaded = (file) => {
    const pre = _.filter(filesUploadedSuccess, (f) => f.id !== file.id);
    addFileUploaded(pre);
    if (pre.length === 0) {
      setIsEnableUploadFile(true);
    }
    onChange && onChange(getValue(pre));
  };

  const getFilesFromEvent = (event: DropEvent) => {
    const fileList = event.dataTransfer
      ? event.dataTransfer.files
      : event.target.files;
    return _.map(fileList, (file) => {
      return Object.defineProperty(file, "id", {
        value: _.uniqueId("file"),
      });
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    getFilesFromEvent,
     accept
  });


  const labelShorter = (fileName) => {
    const extension = fileName.split(".").pop();
    const name = fileName.split(`.${extension}`)[0];

    if (`${name}`.length >= 18) {
      return `${name}`.substring(0, 22) + "..." + extension;
    }
    return `${name}.${extension}`;
  };

  const hasFiles = React.useMemo(() => {
    const uploaded =  _.get(filesUploadedSuccess, 'length', 0) + _.get(bufferFiles, 'length', 0)
   
    return uploaded > 0;


  }, [filesUploadedSuccess, bufferFiles]);


  return (
    <div>
      <input {...getInputProps()} />
      { (!hasFiles) ? (
      <div {...getRootProps()} className={cls.containerNoFilesUploaded} >
        <NoFilesUploaded icons={icons}/>
       </div>) : (
       <div
            {...getRootProps()}
            className={cls.containerFileInput}
          >
            <div>
              <div  className={cls.notFile} >

                <div className={cls.addFiles}>
                  <div className={cls.addFilesIcon}>
                    <Icon
                      size={'1.5385rem'}
                      icon={"Plus"}
                      isClara={true}
                      className={cls.tick}

                    />
                  </div>
                  <div className={cls.addFilesLabels}>
                  
                    <div className={cls.addFilesLink}>
                      <span>
                        {'Add more files'}
                      </span> 
                    </div>
                    <div>
                      <Typography variant="xxs" color="primary" weight="regular">
                        {`${filesUploadedSuccess.length} file uploaded`}
                      </Typography>
                    </div>
                  </div>
                </div>

              </div>
              <div   className={cls.scrollbars}>

                <Scrollbars>
                  {bufferFiles.length > 0 && (
                      <>
                          {bufferFiles.map((file: any, index) => {
                            return (
                              <UploadComponent
                                key={index + file.name}
                                file={file}
                                url={
                                  testUrlFile
                                    ? "https://jsonplaceholder.typicode.com/upload"
                                    : ""
                                }
                                addFileUploaded={handleAddFileUploaded}
                              />
                            );
                          })}
                      </>
                    )}
                    {_.map (filesUploadedSuccess, (file, index) => {
                      return (

                        <div className={cls.fileContainer}>
                          <div>
                            <Icon
                              size={'1.5385rem'}
                              icon={"Tick"}
                              isClara={true}
                              className={cls.tick}

                            />
                          </div>
                          <div className={cls.fileName}>
                            <Typography variant="body" color="blue" weight="semibold">
                              { labelShorter(_.get(file,'name'))} 
                            </Typography>
                          </div>
                          <div >
                            <ButtonIcon
                              className={cls.button}
                              size={'1.5385rem'}
                              icon={'Delete-bin'}
                              variant='flat'
                              children={undefined}
                              onClick={(event) => {
                                event.stopPropagation();
                                deleteFileUploaded(file)
                              }}
                            />
                          </div>
                        </div>

                      );
                    })}
                </Scrollbars>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default FileUploadList;

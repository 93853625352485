import _ from "lodash";
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from "react-redux";
import SecondaryButton from "src/components/generic/Buttons/SecondaryButton/SecondaryButton";
import BotoneraAdd from '../../../../components/forms/contents/BotoneraAdd/BotoneraAdd';
import FormAdd from '../../../../components/forms/contents/FormAdd/FormAdd';
import RowAdd from '../../../../components/forms/contents/RowAdd/RowAdd';
import Form from '../../../../components/forms/Form/Form';
import SubmitButton from "../../../../components/generic/Buttons/SubmitButton/SubmitButton";
import Line from "../../../../components/generic/Line/Line";
import AddGroupInput from "../../../../components/inputs/AddGroup/AddGroupInput";
import RadioInput from "../../../../components/inputs/Radio/RadioInput";
import SelectInput from "../../../../components/inputs/Select/SelectInput/SelectInput";
import TextInput from "../../../../components/inputs/Text/TextInput";
import TextareaInput from "../../../../components/inputs/Textarea/TextareaInput";
import Text from "../../../../components/text/Text/Text";
import useCategories from "../../../../hooks/common/useCategories";
import useTooltips from "../../../../hooks/custom/useTooltips";
import useCallServerData from "../../../../hooks/services/modules/startups/healthcheck/useCallServerData";
import useUpsertIssue from "../../../../hooks/services/modules/startups/healthcheck/useUpsertIssue";
import ControllerInput from "../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import GroupControllerInput from "../../../../modules/generic/components/Inputs/ReactHookForm/GroupControllerInput";
import { goToPage, goToPrevPage } from "../../../../store/actions/page";
import { Constants } from '../../../../v1/utils/constants';
import schema from "../../../schemas/modules/startups/healtcheck/addHealtcheckSchema";
import useSession from "./../../../../modules/session/hooks/useSession";
import classes from './AddHealthcheckForm.module.scss';

const AddHealthcheckForm = ({ initialValues, handleSubmit, handleClose }) => {
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [valuesWatch, setValuesWatch] = useState({});
    const refSubCategory = useRef()
    const dispatch = useDispatch();
    const tooltips = useTooltips(Constants.CONTENTFUL.ENTRIES.FORMS.DUEDILIGENCE.TOOLTIP)

    const { startup } = useSession();
    const { categories: categoriesFromDb, loading: loadingCategory } = useCategories("STARTUP")


    const { data: listData, loading, error } = useCallServerData({ startupId: _.get(startup, "id") });

    const stakeholderList = useMemo(() => {
        return _.get(listData, "stakeholderList", []).map(st => { return { ...st, label: st.fullName } })
    }, [listData]);
    const documentsList = useMemo(() => {
        return _.get(listData, "documentList", []).map(doc => { return { ...doc, label: doc.name || doc.fileName } })
    }, [listData]);
    const groupCompanyList = useMemo(() => {
        return _.orderBy(_.get(listData, "getGroupCompanies", []), ['isHoldingCompany'], ['desc']).map(gc => { return { ...gc, label: gc.name } })
    }, [listData]);
    const status = useMemo(() => {
        return _.map(Constants.ISSUES_STATUS, (e) => {
            return { id: e, label: <Text uuid={`ISSUE_STATUS_${e}`} />, classeSelected: classes[`selected_${e}`], classes: classes[e] }
        });
    }, [Constants.ISSUES_STATUS]);

    const setCategoriesList = () => {

        if (!loadingCategory) {
            const mappedCat = _.map(categoriesFromDb, (category) => {
                return { id: category.key, label: category.title, component: <div>{category.title}</div> }
            })
            setCategories(mappedCat)
        }
    }

    const setSubCategoriesList = (values) => {

        const constantCategories = categoriesFromDb;
        const categorySelected = valuesWatch[0];
        const category = _.find(categoriesFromDb, (e) => {
            return e.key == categorySelected;
        })

        let subCategories = [];
        if (category && category.subCategories) {
            subCategories = category.subCategories.map(subCategory => ({
                id: subCategory.key,
                label: subCategory.title
            }));
        }
        if (!valuesWatch.category && refSubCategory.current) {
            refSubCategory.current.handleSelected(null);
        }
        setSubCategories(subCategories);
    }

    const observerWatch = (data) => {
        setValuesWatch(data)
    }
    useEffect(() => {
        setCategoriesList();
    }, [loadingCategory])

    useEffect(() => {
        setSubCategoriesList();
    }, [JSON.stringify(valuesWatch)])

    const onCompletedUpdate = (dataUpdate) => {
        goToPage(dispatch, Constants.PAGES.healthCheck);
    }
    const [upsertIssue, loadingUpdate] = useUpsertIssue({ onCompleted: onCompletedUpdate });

    const onSubmit = (values) => {

        let variables = {
            "startupId": startup.id,
            "issue": { ...values }
        }
        variables.issue.documents = _.map(variables.issue.documents, (e) => e.document)
        variables.issue.groupCompanies = _.map(variables.issue.groupCompanies, (e) => e.company)
        variables.issue.stakeholders = _.map(variables.issue.stakeholders, (e) => e.stakeholder)

        upsertIssue(variables);
    }
    const back = () => {
        goToPrevPage(dispatch);

    }
    return (
        <React.Fragment>
            <FormAdd className={classes.noMargin} showLine={true}>
                <Form initialValues={initialValues} schema={schema} loading={loadingUpdate || loading || loadingCategory}
                    onSubmit={onSubmit} observerWatch={observerWatch} watch={["category", "subCategory"]}>
                    <RowAdd
                        label={"FORM_HEALCHECK_ADD_LABEL_CATEGORY"}
                        asterisk={true}
                        input={
                            <ControllerInput
                                as={SelectInput}
                                defaultValue=""
                                placeholder={"FORM_HEALTH_CHECK_ADD_PLACEHOLDER_CATEGORY"}
                                list={categories}
                                name={"category"}
                                clear={true}
                                tooltip={tooltips['Choose Remark Category']}
                                onChange={(value) => {

                                }}
                            />
                        }
                    />
                    <RowAdd
                        label={"FORM_HEALCHECK_ADD_LABEL_SUBCATEGORY"}
                        asterisk={true}
                        input={
                            <ControllerInput
                                as={SelectInput}
                                defaultValue=""
                                placeholder={"FORM_HEALCHECK_ADD_PLACEHOLDER_SUBCATEGORY"}
                                list={subCategories}
                                name={"subCategory"}
                                clear={true}
                                innerRef={refSubCategory}
                                onChange={(e) => {

                                }}
                            />
                        }
                    />
                    <Line />
                    <RowAdd
                        label={"FORM_HEALCHECK_ADD_LABEL_URGENCY"}
                        asterisk={true}
                        input={
                            <ControllerInput
                                as={RadioInput}
                                options={status}
                                name={"urgency"}

                            />
                        }
                    />
                    <Line />
                    <GroupControllerInput
                        as={AddGroupInput}
                        name="groupCompanies"
                        buttonAdd={"FORM_HEALCHECK_ADD_LABEL_ADD_GROUPCOMPANY"}
                        components={[
                            {
                                label: "FORM_HEALCHECK_ADD_LABEL_GROUPCOMPANY",
                                as: SelectInput,
                                placeholder: "FORM_HEALTH_CHECK_ADD_PLACEHOLDER_CATEGORY",
                                list: groupCompanyList,
                                clear: true,
                                name: "company",

                            },
                        ]}
                    />
                    <GroupControllerInput
                        as={AddGroupInput}
                        name="stakeholders"
                        buttonAdd={"FORM_HEALCHECK_ADD_LABEL_EDIT_PROFILE"}
                        components={[
                            {
                                label: "FORM_HEALCHECK_ADD_LABEL_STAKEHOLDER",
                                as: SelectInput,
                                placeholder: "FORM_HEALTH_CHECK_ADD_PLACEHOLDER_CATEGORY",
                                list: stakeholderList,
                                clear: true,
                                name: "stakeholder",

                            },
                        ]}
                    />
                    <GroupControllerInput
                        as={AddGroupInput}
                        name="documents"
                        buttonAdd={"FORM_HEALCHECK_ADD_LABEL_ADD_DOCUMENT"}
                        components={[
                            {
                                label: "FORM_HEALCHECK_ADD_LABEL_DOCUMENT",
                                as: SelectInput,
                                placeholder: "FORM_HEALTH_CHECK_ADD_PLACEHOLDER_CATEGORY",
                                list: documentsList,
                                clear: true,
                                name: "document",

                            },
                        ]}
                    />
                    <Line />
                    <RowAdd
                        label={"FORM_HEALCHECK_ADD_LABEL_TITLE"}
                        asterisk={true}
                        input={
                            <ControllerInput
                                as={TextInput}
                                name="title"
                                defaultValue=""
                                placeholder={"FORM_HEALCHECK_ADD_PLACEHOLDER_TITLE"}
                                tooltip={tooltips['Issue Name']}
                                onChange={(e) => {

                                }}
                            />
                        }

                    />
                    <RowAdd
                        label={"FORM_HEALCHECK_ADD_LABEL_DESCRIPTION"}
                        asterisk={true}
                        input={
                            <ControllerInput
                                as={TextareaInput}
                                name="description"
                                defaultValue=""
                                placeholder={"FORM_HEALCHECK_ADD_PLACEHOLDER_DESCRIPTION"}
                                tooltip={tooltips['Issue Note']}
                                onChange={(e) => {

                                }}
                            />
                        }

                    />
                    <BotoneraAdd>

                        <SecondaryButton onClick={back}><Text uuid={"GENERIC_BUTTON_CANCEL"} /></SecondaryButton>
                        <SubmitButton><Text uuid={"GENERIC_BUTTON_SUBMIT"} /></SubmitButton>
                    </BotoneraAdd>


                </Form>
            </FormAdd>
        </React.Fragment >
    )
}

export default AddHealthcheckForm;

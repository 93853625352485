import useMutation from "../../../hooks/custom/useMutation"

export const SEND_EMAIL_TO_INFO_CLARA_FORMATION = `mutation sendEmailToInfoClaraFormation($data: RequestFormationInputType!) {
  sendEmailToInfoClaraFormation(data:$data)
}`;

const useSendEmailToInfoClaraFormation = (options) =>{
    const [send,loading] = useMutation(SEND_EMAIL_TO_INFO_CLARA_FORMATION,{...options});
    return  [send,loading] ;
}

export default useSendEmailToInfoClaraFormation;
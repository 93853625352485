

export default {
  LOADING_ZIP_DOWNLOAD: "Your .zip file is being created",
  NOT_DOCUMENTS: "You currently have no documents to download",
  SUCCESS_ZIP_FILE_GENERATED: "Zip file generated successfully",
  NAMING_MINIMUM_LENGTH: "Please use a document name with 3 or more characters",
  NAMING_FORBIDDEN_CHARS: "Please do not use the following symbols in the document name  < > : “ / \ | ? *",
  BUTTON_SAVE: "Save",
  RENAME_MESSAGE: "{{{oldName}}} has been changed to {{{newName}}}",
  RENAME_TITLE: "What’s this document’s new name?",
  RENAME_SUBTITLE: "Rename {{{documentName}}}",
  HTU_TITLE: "How to use this form",
  HTU_LEGAL: "<p>Enter the details about your document and its parties in this form and click 'Generate Document' to review a draft. You can then save your document to your Clara Profile and sign it through Clara's integrated DocuSign experience.</p><p>&nbsp;</p><p><strong>Please note:</strong></p><p>This form will not save data if you exit this screen without generating a document.</p><p>&nbsp;</p><p><strong>Legal Disclaimer</strong></p><p>Clara is not providing any legal advice to you by generating this document. It may not be suitable for your circumstances or jurisdiction. We recommend you obtain legal advice before signing any document. You assume all risk and liability that may result from using this document.</p><p>&nbsp;</p>",
  HTU_LEGAL_LINK_P1: "Click ",
  HTU_LEGAL_LINK_P2: "here ",
  HTU_LEGAL_LINK_P3: " to review the Terms of Use",
  WARNING: "<h5>IMPORTANT NOTE REGARDING CHANGES TO CLARA DOCUMENTS.</h5> We have recently made some changes to the Clara documents. The guidance notes for each document have been updated with information about these changes and we strongly recommend that you read the new guidance notes before generating any new documents. As a reminder, the guidance notes appear on your screen when you select a document and before you click through to the generation form. If you have any questions or would like further details regarding these changes, please contact <a href='mailTo:info@clara.co'>info@clara.co</a> and a member of our team will be in touch."
};


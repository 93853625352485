import classnames from "classnames";
import _ from "lodash";
import React, { FC, useMemo, useState } from "react";
import Scrollbar from "../../../../../../components/generic/Scrollbar/Scrollbar";
import TypographyLocal from "../../../../../../modules/generic/components/Texts/Typography";
import { CircularGraph } from "../../../../../../v1/components";
import GraphsLegends from "../../../../../../v1/components/Dashboard/CapTable/components/GraphsLegends";
import classes from "./classes.module.scss";

/**
 * Create graph component for cap table
 */

export interface CapTableGraphsProps {
  /** Property shareholders */
  shareholders: any,
  /** Property shares */
  shares: any,
  /** Property issuedShares */
  issuedShares: any,
} 

const CapTableGraphs: FC<CapTableGraphsProps> = ({
  shareholders,
  shares,
  issuedShares,
  ...props
}:CapTableGraphsProps) => {

  const defaultColors = ["#bbe4ff", "#0071bc", "#ffdc89", "#ed6d79", "#f5a26f", "#71e096", "#898cff"];
  const [colors, setColors] = useState([]);

  const options = {
    legend: {
      display: false,
      position: 'right'
    },
    maintainAspectRatio: false
  };

  const calculateColors = (cant) => {
    let backgroundColor = [];
    for (let j = 0; j < cant; j++) {
      if (j + 1 > defaultColors.length) {
        const letters = '0123456789ABCDEF'.split('');
        let color = '#';
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        backgroundColor[j] = color;
      } else {
        backgroundColor[j] = defaultColors[j];
      }
    }
    setColors(backgroundColor);
  };

  const { shareholdersData, shareholdersLabels, classData, classLabels } = useMemo(() => {
    calculateColors(1000);
    const shareholdersData = [];
    const shareholdersLabels = [];
    const classData = [];
    const classLabels = [];
    const newIssuedShares = {};
    for (let i = 0; i < shareholders.length; i++) {
      if (shareholders[i] && shareholders[i].equity.length > 0) {
        const shares = shareholders[i].equity.reduce((subTotal, share) => (share.amount || 0) + subTotal, 0);
        shareholdersData.push(shares);
        shareholdersLabels.push(shareholders[i].personalData.fullName);
      }
    }
    _.forEach(issuedShares, (issuedShare) => {
      newIssuedShares[issuedShare.shareName] = (issuedShares[issuedShare.shareName] || 0) + issuedShare.share;
    });
    for (let key in newIssuedShares) {
      classData.push(newIssuedShares[key]);
      classLabels.push(key);
    }
    return { shareholdersData, shareholdersLabels, classData, classLabels }
  }, [
    JSON.stringify(shareholders),
    JSON.stringify(shares),
    JSON.stringify(issuedShares),
  ]);

  return (
    <>
      <div className={classnames(classes.graphsContainer)}>
        <div className={classnames(classes.Class)}>
          <div className={classnames(classes.graphContainer)}>
            <CircularGraph
              options={options} colors={colors}
              data={shareholdersData} labels={shareholdersLabels}
            />
          </div>
          <div>
            <TypographyLocal
              variant="body"
              fontWeight="semibold"
            >
              Shareholders
            </TypographyLocal>
            <Scrollbar className={classnames(classes.legendContainer)}>
              <GraphsLegends labels={shareholdersLabels} colors={colors} />
            </Scrollbar>
          </div>
        </div>
        <div className={classnames(classes.Class)}>
          <div className={classnames(classes.graphContainer)}>
            <CircularGraph
              options={options} colors={colors}
              data={classData} labels={classLabels}
            />
          </div>
          <div>
            <TypographyLocal
              variant="body"
              fontWeight="semibold"
            >
              Class
            </TypographyLocal>
            <Scrollbar className={classnames(classes.legendContainer)}>
              <GraphsLegends labels={classLabels} colors={colors} />
            </Scrollbar>
          </div>
        </div>
      </div>
    </>
  );
};

export default CapTableGraphs;
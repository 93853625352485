import {TasksRequiredFieldFragment} from "../../../map/graphql/typePolicies";
import {DOCUMENT} from "../../Documents/graphql/fragments";
import {FileFragment} from "../../../generic/graphql/fragments";
const ShareIncentivePlanFields=`
${DOCUMENT}
fragment ShareIncentivePlanFields on ShareIncentivePlan{
   id
      name
      totalShares
      issuedShares
      document{
     ...DocumentAdd
      }
      authorizedOptions
      allocatedShares
      documents{
      ...DocumentAdd
      }
      }
`
const EquityFields = `
${TasksRequiredFieldFragment}
${DOCUMENT}
fragment EquityFields on Equity {
    id
    groupCompany{
      id
    }
    type
    amount
    price
    issuedDate
    tasks{
    ...TasksRequiredFieldFragment
    }
    documents{
     ...DocumentAdd
    }
    shareClass{
      id
      name
    }
    currency
}`

const ADD_SHARE_INCENTIVE_PLAN=`
fragment AddShareIncentivePlan on ShareIncentivePlan{
      id
      name
      totalShares
      issuedShares
      document{
      id
      name
      }
      authorizedOptions
      allocatedShares
      documents{
      id
      name
      }
}
`

const ADD_SHARE_CLASS_SIP=`
${ADD_SHARE_INCENTIVE_PLAN}
fragment AddShareClass on ShareClassType{
id
name
shareIncentivePlans{
...AddShareIncentivePlan
}
}
`
const ADD_SHARE_CLASS=`
fragment AddShareClass on ShareClassType{
id
name
}`

const WARRANT=`
${TasksRequiredFieldFragment}
${DOCUMENT}
fragment WarrantAdd on WarrantType{
id
amount { amount currency }
# valuation
issuedDate
documents {
...DocumentAdd
}
tasks{
...TasksRequiredFieldFragment
}
}
`
const OWNER_WARRANT=`
${TasksRequiredFieldFragment}
${WARRANT}
fragment OwnerWarrant on StakeHolder{
id
tasks{
...TasksRequiredFieldFragment
}
warrants{
...WarrantAdd
}
}
`
const CONVERTIBLE=`
${TasksRequiredFieldFragment}
${DOCUMENT}
fragment ConvertibleFragment on Convertible{
id 
amount { amount currency }
# discountRate
# interestRate
# valuationCap
issuedDate
tasks{
...TasksRequiredFieldFragment
}
documents{
...DocumentAdd
}
}
`
const OWNER_CONVERTIBLE=`
${TasksRequiredFieldFragment}
${CONVERTIBLE}
${FileFragment}
fragment OwnerConvertible on StakeHolder{
id
documents{
id
name 
file{
...FileFragment
}
}
tasks{
...TasksRequiredFieldFragment
}
convertibles{
...ConvertibleFragment
}
}`
const OWNER_EQUITY_GROUP_COMPANY=`
${FileFragment}
fragment OwnerEquityGroupCompany on GroupCompany{
                id
                documents{
id
name 
file{
...FileFragment
}
}
                tasks{
                ...TasksRequiredFieldFragment
                }
                equity {...EquityFields}
            }
`
const EquityRequiredFields=`
fragment EquityRequiredFields on Equity{
id
}
`
const OWNER_EQUITY_STAKEHOLDER=`
${EquityFields}
${TasksRequiredFieldFragment}
fragment OwnerEquityStakeholder on StakeHolder{
                id
                tasks{
                ...TasksRequiredFieldFragment
                }
                equity {...EquityFields}
            }
`
export {
ADD_SHARE_CLASS_SIP,
ADD_SHARE_CLASS,
 ADD_SHARE_INCENTIVE_PLAN,
  OWNER_CONVERTIBLE,
  OWNER_WARRANT,
  CONVERTIBLE,
  WARRANT,
    OWNER_EQUITY_GROUP_COMPANY,
    OWNER_EQUITY_STAKEHOLDER,
    EquityFields,
    ShareIncentivePlanFields,
    EquityRequiredFields
}

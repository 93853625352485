import React, { FC } from "react";
import Skeleton from '@material-ui/lab/Skeleton';
import classnames from "classnames";
import classes from "./classes.module.scss";
import TemplateLabel, {TemplateLabelComponent} from "../../../../../../modules/generic/templates/Label/";

/**
 * Overlay Skeleton Card Button
 */

export interface TemplateSkeletonComponentProps<PropTypes = any> {
  Template?: TemplateLabelComponent,
  propsTemplate?: PropTypes,
  count?: number,
}

export type TemplateSkeletonComponent<PropsType = any> = FC<TemplateSkeletonComponentProps<PropsType>>

export interface SkeletonCardButtonProps extends TemplateSkeletonComponentProps {

}

const SkeletonCardButton: TemplateSkeletonComponent = ({
  Template = TemplateLabel,
  count = 2,
}:SkeletonCardButtonProps) => {

  return (
    <React.Fragment>
      <div className={classnames(classes.skeletonForm)}>
        <div className={classnames(classes.skeletonCardsContainer)}>
          {Array(count).fill(1).map((el, i) =>
            <Skeleton key={i} variant="rect" height={"8.6154rem"} width={"11.2308rem"}/>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default SkeletonCardButton;
import React, {FC, useContext, useEffect, useMemo, useRef, useState} from 'react'
import _ from 'lodash'
import {useDispatch} from "react-redux";
import * as  Yup from 'yup'
import useTranslate from "../../../../generic/hooks/useTranslate";
import Form from "../../../../../components/forms/Form/FormL";
import classes from './classes.module.scss'
import ControllerInput from "../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import SubmitButton from "../../../../generic/components/Buttons/SubmitButton";
import TemplateFormDefault from "../../../../generic/templates/Modal";
import FormProps from "../../../../generic/forms/interfaces/FormProps";
import {CheckboxButtonOptions} from "../../../../generic/components/Inputs/CheckboxButton";
import CheckboxButtonSimple from "../../../../generic/components/Inputs/CheckboxButtonSimple";
import {addAssistantText, clearAssistatText, removeAssistantText} from "../../../../generic/store/action";
import FileUploaderInput from "../../../../../components/inputs/FileUploader/FileUploaderInput";
import TemplateLabel from "../../../../generic/components/Inputs/LabelInput";
import TextInput from "../../../../../components/inputs/Text/TextInput";
import classesModule from "../../../onBoarding/company/forms/CompanyForm/classes.module.scss";
import CalendarInputDate from "../../../../../components/inputs/Calendars/CalendarInputDate";
import useCustomMachine from "../../../../generic/hooks/useCustomMachine";
import Typography from "../../../../generic/components/Texts/Typography";
import Currency from "src/components/inputs/Currency/Currency";

const schema=Yup.object().shape({
    valuation:Yup.number().nullable().required('Required').typeError('Must be a number'),
    amount:Yup.number().nullable().required('Required').typeError('Must be a number'),
    issuedDate:Yup.date().nullable().required('Required').typeError('Add a valid date')
})
interface WarrantsFormProps extends FormProps{
    companyName:string
    currency:string
}
const UploadForm: FC<WarrantsFormProps> = ({initialValues,currency, tooltips, onCompleteSubmit, Template = TemplateFormDefault, buttons = {}, buttonSubmitProps, propsTemplate,companyName}:WarrantsFormProps) => {
    const { translate } = useTranslate();
    const refForm=useRef()
    return (
        <React.Fragment>
            <Form
                initialValues={initialValues || {}}
                schema={schema}
                onlySendDirty={false}
                onSubmit={onCompleteSubmit}
                ref={refForm} optionsForm = {{mode:"onChange"}}
            >
                <Template props={propsTemplate} buttons={{submit:<SubmitButton{...buttonSubmitProps}>Next</SubmitButton>,...buttons}}>
                    <Typography component={'h5'} color={'black'}>
                        {companyName}
                    </Typography>
                    <TemplateLabel label={translate('MODULES.EQUITY.FORMS.WARRANT_FORM.AMOUNT')} isRequired={true}>
                         <ControllerInput
                            render={Currency}
                            name="amount"
                            type={'number'}
                            defaultValue={""}
                            currency={
                                currency
                            }
                            onChange={(e) => {}}
                        />
                    </TemplateLabel>
                    <TemplateLabel label={translate('MODULES.EQUITY.FORMS.WARRANT_FORM.VALUATION')} isRequired={true}>
                        <ControllerInput
                            render={Currency}
                            name="valuation"
                            type={'number'}
                            defaultValue={""}
                            currency={
                                currency
                            }
                            onChange={(e) => {}}
                        />
                    </TemplateLabel>
                    <TemplateLabel
                        label={translate('MODULES.EQUITY.FORMS.WARRANT_FORM.ISSUED_DATE')}
                        className={classesModule.LabelTitle}
                        isRequired={true}

                    >
                        <ControllerInput
                            as={CalendarInputDate}
                            name='issuedDate'
                            placeholder={translate('MODULES.EQUITY.FORMS.WARRANT_FORM.PLACEHOLDER_DATE')}
                            defaultlabel={''}
                        />
                    </TemplateLabel>
                </Template>
            </Form>
        </React.Fragment>
    )
}

export default UploadForm

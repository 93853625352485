import React from "react";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ModalStepper from "src/modules/generic/components/ModalStepper";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@mui/material/IconButton';

const TemplateOnboarding2Wizard = ({
  buttons,
  children,
  leftComponent,
  rightComponent,
  actualStep,
  totalSteps,
  previousClick,
  cancel
}) => {

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          height: '100%', 
          width: "100%"
        }}
      >
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={0}
          sx={{height: "40px", width: "100%" }}
        >
          {cancel && (
            <IconButton
              onClick={cancel}
              aria-label="close"
              disableRipple 
              sx={{
                color: "#828c98 !important",
                '&:hover': {
                  backgroundColor: 'transparent',
                }
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Stack>
        <Box 
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Box
            sx={{
              width: "1012px",
              height: "532px",
              marginTop: "-50px"
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateRows: "auto 1fr",
                width: "100%",
                height: "100%"
              }}
            >
              <Box sx={{height: "46px", width: "100%"}}>
                <ModalStepper
                  totalSteps ={totalSteps}
                  actualStep={actualStep}
                  previousClick={() => {
                    if (previousClick) {
                      previousClick();
                    }
                  }}
                ></ModalStepper>
              </Box>
              <Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: rightComponent ? "1fr 285px" : "1fr",
                    width: "100%",
                    columnGap:  rightComponent ? "32px" : "0px",
                    height: "100%"
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateRows: "1fr auto",
                        width: "100%",
                        rowGap: "16px",
                        height: "100%"
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        {leftComponent}
                        {children}
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        {buttons.submit}
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    {rightComponent}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TemplateOnboarding2Wizard;

import useMutation from "src/hooks/custom/useMutation";

export const UPSERT_GROUP_COMPANY = `
mutation upsertGroupCompany(
    $startupId: String
    $groupCompanyId: String
	$groupCompany: GroupCompanyInputType

  ) {
    upsertGroupCompany(
      startupId: $startupId
      groupCompanyId: $groupCompanyId
      groupCompany: $groupCompany
    ) {
      id
      name
      isHoldingCompany
      type
      registeredNumber
      incorporation_date
      transactionCurrency
      authorisedShareCapital
      linkedinURL
      country {
        name
        code
      }
      jurisdiction
      nameBecameEffectiveOn
      previousName
      licenseType
      companyStatus
      managedByClara
      jurisdictionType
  }
}
`;

const useUpdateGroupCompanyDetails = () => {
    const [mutation] = useMutation(UPSERT_GROUP_COMPANY, { showSuccessNotification: true });
    return mutation;
};

export default useUpdateGroupCompanyDetails;

import { useMemo } from 'react';

const useGuards = () => {

  return useMemo(() => ({

    acceptInCayman: (context) => {
      return context?.FormCaymanAgree?.accept;
    },

    discardCayman: (context) => {
      return context?.FormCaymanAgree?.accept === false;
    },

    noSendEmail: (context) => {
      return context?.EngageConciergeCayman?.accept === false;
    },

    sendMail: (context) => {
      return context?.EngageConciergeCayman?.accept;
    },

  }), []);
}

export default useGuards;

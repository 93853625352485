import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from "react-redux";
import _ from 'lodash';
import { Constants } from '../../utils/constants';
import { Field, Formik } from "formik";
import notification from "../../utils/notification";
import ServerConnect from "../../utils/ServerConnect";
import { Loading, Modal, Text, TextAreaInput } from '../../components';
import { LayoutBlueScreen } from '../index';
import Mustache from "mustache";
import classes from "./SteperForm.module.scss";
// import { isValid } from 'date-fns';
import useApproveDocument from '../../../modules/formation/hooks/useApproveDocument';
import useDeclineDocument from '../../../modules/formation/hooks/useDeclineDocument';
import DocumentPreviewFile from "../../../components/generic/Documents/DocumentPreview/DocumentPreviewFile";

const query = `{ 
    getIncorporationDocumentById(matterId: "{{matterId}}", documentId:"{{documentId}}"){
      id
      status
      name
    } 
  }
`;

// const mutationDecline = `
//     mutation declineDocumentIncorporation ($matterId: ID!, $documentId: ID!,$issues: String! ){
//         declineDocumentIncorporation(
//             matterId: $matterId,
//             issues: $issues,
//             documentId: $documentId
//         ) {
//             id,
//             status
//         }
//     }
// `;

const ApproveDocument = (props) => {

  // const time = 1000;
  const dispatch = useDispatch();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState({});
  // const [countReloads, setCountReloads] = useState(0);
  // const [reload, setReload] = useState(false);
  const matterId = props.matterId ? props.matterId : params.matterId;
  const documentId = props.documentId ? props.documentId : params.documentId;
  const fileId = props.fileId ? props.fileId : params.fileId;
  const [showModalDecline, setShowModalDecline] = useState(false);
  const onCompletedUpdate = () => closeForm();
  const canDecline = props.canDecline;
  //const [url, setUrl] = useState(ServerConnect.getGoogleViewUrlFile(fileId));
  // const [url, setUrl] = useState(ServerConnect.getMicrosoftViewUrlFile(fileId));
  const fileName = props.documentName;
  const fileType = fileName ? fileName.split(".").pop() : "";

  const { mutation: mutationApproveDocument, loading: loadingApproveDocument } = useApproveDocument({
    onCompleted: onCompletedUpdate,
    showSuccessNotification: false
  });

  const { mutation: mutationDeclineDocument, loading: loadingDeclineDocument } = useDeclineDocument({
    onCompleted: onCompletedUpdate,
    showSuccessNotification: false
  });

  const closeForm = () => {
    if (props.handleClose) {
      props.handleClose();
    } else {
      dispatch({
        type: 'GO_TO', page: Constants.PAGES.viewMatter,
        params: { id: matterId, tab: "incorporation" }
      });
    }
  };
  
  const declineModal = () => {
    setShowModalDecline(true);
  };

  const closeModal = () => {
    setShowModalDecline(false);
  };

  const approve = async () => {
    setLoading(true);
    await mutationApproveDocument({
      matterId: matterId,
      documentId: documentId
    });
  }

  const decline = async (values) => {
    setLoading(true);
    setShowModalDecline(false);
    // ServerConnect.graphQlMutation(mutationDecline, {
    // 	matterId: matterId,
    // 	issues: values.issues,
    // 	documentId: documentId
    // }).then(res => {
    // 	closeForm()
    //
    // });
    await mutationDeclineDocument({
      matterId: matterId,
      documentId: documentId,
      issues: values.issues,
    });
  };

  const cancel = () => {
    closeForm();
  };

  // const reloadIframe = () => {
  //   if (reload && countReloads < 10) {
  //     setCountReloads(countReloads + 1)
  //     setUrl(null)
  //   }
  // };

  // const handleOnLoad = () => {
  //   if (url) {
  //     setReload(false)
  //   }
  // };

  useEffect(() => {
    const queryExecute = Mustache.render(query, { matterId, documentId });
    setLoading(true)
    ServerConnect.graphQlQuery(queryExecute).then(result => {
      setDocument(result.getIncorporationDocumentById)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      notification.sendNotification("ERROR", "error", {});
    });
  }, []);

  return (
    <React.Fragment>
      <Loading show={loading} showChildren={false}>
        <LayoutBlueScreen
          classes={{
            rightPanel: classes.ApproveDocument
          }}
          leftEducationId={null}
          handleClose={closeForm}>
          <div className={classes.ApproveDocumentPreview}>
            {/* <iframe onLoad={handleOnLoad} src={url} /> */}
            <DocumentPreviewFile
              fileId={fileId}
              fileName={fileName}
              fileType={fileType}
            />
          </div>
          <div className={classes.ApproveDocumentBotonera}>
            {(canDecline || document.status !== "Draft") && <button className={`genericWhite`} onClick={declineModal} > <Text uuid={"BUTTON_DECLINE"} /> </button>}
            {(!canDecline && document.status === "Draft") && <button className={`genericWhite`} onClick={cancel} > <Text uuid={"BUTTON_CANCEL"} /> </button>}
            <button className={`genericBlue`} onClick={() => approve()} > <Text uuid={"BUTTON_APPROVE"} /> </button>
          </div>
        </LayoutBlueScreen>
        <Modal open={showModalDecline} title={document.name} showHeader={false}>
          <div className={classes.ModalBox}>
            <h1>{document.name}</h1>
            <div className={classes.TableDecline}>
              <Text uuid={"LABEL_DOCUMENT_DECLINE_ISSUES"} />
              <Formik
                initialValues={{}}
                // validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  decline(values)
                }}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                  <React.Fragment>
                    <form onSubmit={handleSubmit}>
                      <Field name={"issues"}>
                        {({ field, form }) => (
                          <React.Fragment>
                            <TextAreaInput
                              required={true}
                              field={field}
                              form={form}
                              autoComplete='off'
                              placeholder={"PROPOSAL_DECLINE_PLACEHOLDER"}
                              showRequired={false}
                              className={classes.ChatInputTextarea}
                            />
                          </React.Fragment>
                        )}
                      </Field>
                      <div className={classes.botonera}>
                        <button className={`genericWhite`} type="button" onClick={closeModal}><Text uuid={"BUTTON_CANCEL"} /></button>
                        <button disabled={_.get(values, 'issues.length', 0) < 3} className={`genericBlue`} type={"submit"} onClick={handleSubmit}><Text uuid={"BUTTON_SUBMIT"} /></button>
                      </div>
                    </form>
                  </React.Fragment>
                )}
              </Formik>
            </div>
          </div>
        </Modal>
      </Loading>
    </React.Fragment>
  )
}

export default ApproveDocument;

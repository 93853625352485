import { useMemo } from "react";
import {
  QUERY_UPLOAD_CATEGORIES,
  mapToOldStructure,
} from "src/hooks/common/useCategories";
import useSession from "src/modules/session/hooks/useSession";
import useGetStakeholdersLazy from "src/hooks/services/modules/stakeholders/useGetStakeholdersLazy";
import useGetGroupCompanies from "src/hooks/services/modules/groupCompany/useGetGroupCompanies";
import useUpsertDocument from "src/hooks/services/modules/Document/useUpsertDocument";
import _ from "lodash";
import moment from "moment";
import useAddDocumentFormation from "src/hooks/services/modules/individualStakeholder/useAddDocumentFormation";
import useLazyQuery from "src/hooks/custom/useLazyQuery";
import useUpdateIdentityDocumentPageStakeholder from "src/modules/startup/Documents/graphql/mutations/useUpdateIdentityDocumentPageStakeholder";
import { Constants } from "src/v1/utils";
import {
  FORMATION_DOCUMENTS_EXC_DOCUMENT_TYPES,
  FORMATION_DOCUMENTS_EXP_DOCUMENT_TYPES,
} from "../constants";

const QUERY_GET_FILE_BY_ID = `query getDocument($startupId: ID!, $agreementId: ID!) {
  getDocument(startupId: $startupId, agreementId: $agreementId) {
    id
    file {
      id
      name
      type
    }
  }
}`;

const useServices = ({
  initialValues,
  isCategorize,
  isEdit,
  callback,
  isVisaOrPassportDocument,
}) => {
  const { startupId } = useSession();

  const { manualQuery: getStakeholders } = useGetStakeholdersLazy({
    variables: { startupId },
  });

  const { manualQuery: getGroupCompanies } = useGetGroupCompanies({
    variables: { startupId },
  });

  const {
    mutation: updateIdentitydocuments,
  } = useUpdateIdentityDocumentPageStakeholder({
    showSuccessNotification: false,
  });

  const [upsertDocument] = useUpsertDocument({});
  const [addIdentityDocument] = useAddDocumentFormation();

  const { manualQuery: getCategotiesLazyQuery } = useLazyQuery(
    QUERY_UPLOAD_CATEGORIES,
    {},
    null
  );

  const { manualQuery: getFileByDocumentId } = useLazyQuery(
    QUERY_GET_FILE_BY_ID,
    { startupId, agreementId: initialValues?.document?.id },
    null
  );

  return useMemo(
    () => ({
      initialData: async () => {
        let newInitialValues = {
          ...initialValues,
        };
        if (_.isEmpty(initialValues?.document?.file) && isCategorize) {
          const { data } = await getFileByDocumentId();
          newInitialValues.document.file = data?.getDocument?.file;
        }
        return { ...newInitialValues };
      },

      getCategoriesData: async (context, event) => {
        const { data } = await getCategotiesLazyQuery();
        const categories = mapToOldStructure(
          _.get(data, "categoriesUpload", []),
          null
        );
        return categories;
      },

      getStakeholdersData: async (context, event) => {
        const groupCompanies = await getGroupCompanies();
        const stakeholders = await getStakeholders();
        return {
          stakeholders: _.get(stakeholders, "data.stakeholderList", []),
          groupCompanies: _.orderBy(
            _.get(groupCompanies, "data.getGroupCompanies", [])
          ),
        };
      },

      saveCategoriseDocumentData: async (context, event) => {
        const flowSelected = _.get(context, "categoriseDocumentData.flowToUse");
        const fieldAgreementId = _.get(context, "file.draft")
          ? "draftDocumentId"
          : "agreementId";
        const draftId = _.get(context, "file.id");
        const fileToUse = _.get(
          context,
          "categoriseDocumentData.files.0",
          _.get(context, "file.file")
        );
        const variables = {
          agreement: {
            title: _.get(context, "categoriseDocumentData.title", ""),
            type: _.get(context, "categoriseDocumentData.type", ""),
            subCategories: [
              _.get(
                context,
                "categoriseDocumentData.subCategorySelected.key",
                ""
              ),
            ],
            groupCompanies: _.filter(
              _.get(context, "categoriseDocumentData.selectedParties", []),
              (party) => {
                return party.type === "GroupCompany";
              }
            ).map((gc) => {
              return { groupCompany: gc.id };
            }),
            parties: _.filter(
              _.get(context, "categoriseDocumentData.selectedParties", []),
              (party) => {
                return party.type === "Stakeholder";
              }
            ).map((st) => st.id),
            file: fileToUse,
            status: "Uploaded",
          },
          startupId,
          [fieldAgreementId]: draftId,
        };
        if (flowSelected === 1) {
          if (
            FORMATION_DOCUMENTS_EXC_DOCUMENT_TYPES.includes(
              _.get(context, "categoriseDocumentData.type")
            )
          )
            variables.agreement.executedOnDate = moment(
              _.get(context, "categoriseDocumentData.executedOnDate")
            ).format("YYYY-MM-DD");
          if (
            FORMATION_DOCUMENTS_EXP_DOCUMENT_TYPES.includes(
              _.get(context, "categoriseDocumentData.type")
            )
          )
            variables.agreement.expirationDate = moment(
              _.get(context, "categoriseDocumentData.expirationDate")
            ).format("YYYY-MM-DD");
          const ipProvisionSelected = _.get(context, "categoriseDocumentData.ipProvision");
          const newVariables = {
            ...variables,
          };
          if (ipProvisionSelected) {
            const newSubCategories = [
              _.get(context, "categoriseDocumentData.subCategorySelected.key"),
              "IPAssignment",
            ];
            newVariables.agreement.subCategories = newSubCategories;
          }
          const document = await upsertDocument(newVariables);
          if (callback) callback(document?.data?.upsertDocument);
        }
        if (flowSelected === 2) {
          const ipProvisionSelected = _.get(
            context,
            "categoriseDocumentData.ipProvision"
          );
          if (ipProvisionSelected) {
            const newSubCategories = [
              _.get(context, "categoriseDocumentData.subCategorySelected.key"),
              "IPAssignment",
            ];
            const newVariables = {
              ...variables,
            };
            newVariables.agreement.subCategories = newSubCategories;
            const document = await upsertDocument(newVariables);
            if (callback) callback(document?.data?.upsertDocument);
            return;
          }
          const document = await upsertDocument(variables);
          if (callback) callback(document?.data?.upsertDocument);
        }
        if (flowSelected === 3) {
          const isIdentityDocument = _.includes(
            Constants.DOCUMENT_TYPE_IDENTITY_DOCUMENTS,
            _.get(
              context,
              "categoriseDocumentData.type",
              _.get(context, "file.type.code")
            )
          );
          if (isIdentityDocument && isEdit) {
            let passportData = {
              identityDocumentData: {
                expiryDate: moment(
                  _.get(context, "categoriseDocumentData.expirationDate")
                ).format("YYYY-MM-DD"),
                file: fileToUse,
                name: _.get(context, "categoriseDocumentData.title"),
                nationality: _.get(
                  context,
                  "categoriseDocumentData.nationality"
                ),
                number: _.get(context, "categoriseDocumentData.number"),
                type: _.get(context, "categoriseDocumentData.type"),
              },
              startupId,
              stakeholderId: _.get(context, "categoriseDocumentData.party"),
              pageId: draftId,
            };
            const document = await updateIdentitydocuments(passportData);
            if (callback) callback(document?.data?.updateIdentityDocuments);
          } else {
            let passportData = {
              identityDocumentData: {
                [fieldAgreementId]: draftId,
                expiryDate: moment(
                  _.get(context, "categoriseDocumentData.expirationDate")
                ).format("YYYY-MM-DD"),
                files: [fileToUse],
                nationality: _.get(
                  context,
                  "categoriseDocumentData.nationality"
                ),
                number: _.get(context, "categoriseDocumentData.number"),
                type: _.get(context, "categoriseDocumentData.type"),
              },
              startupId,
              stakeholderId: _.get(context, "categoriseDocumentData.party"),
              pageId: draftId,
            };
            const document = await addIdentityDocument(passportData);
            if (callback) callback(document?.data?.addIdentityDocument);
          }
        }
        if (flowSelected === 4) {
          const isIdentityDocument = _.includes(
            Constants.DOCUMENT_TYPE_IDENTITY_DOCUMENTS,
            _.get(
              context,
              "categoriseDocumentData.type",
              _.get(context, "file.type.code")
            )
          );
          if (!(isIdentityDocument && isEdit)) {
            const visaData = {
              identityDocumentData: {
                [fieldAgreementId]: draftId,
                expiryDate: moment(
                  _.get(context, "categoriseDocumentData.date")
                ).format("YYYY-MM-DD"),
                files: [fileToUse],
                type: _.get(context, "categoriseDocumentData.type"),
              },
              startupId,
              stakeholderId: _.get(context, "categoriseDocumentData.party"),
              pageId: draftId,
            };
            const document = await addIdentityDocument(visaData);
            if (callback) callback(document?.data?.addIdentityDocument);
          } else {
            const visaData = {
              identityDocumentData: {
                expiryDate: moment(
                  _.get(context, "categoriseDocumentData.date")
                ).format("YYYY-MM-DD"),
                file: fileToUse,
                type: _.get(context, "categoriseDocumentData.type"),
              },
              startupId,
              stakeholderId: _.get(context, "file.stakeholders.0.id", null),
              pageId: draftId,
            };
            const document = await updateIdentitydocuments(visaData);
            if (callback) callback(document?.data?.updateIdentitydocuments);
          }
        }
      },
    }),
    [
      addIdentityDocument,
      getCategotiesLazyQuery,
      getFileByDocumentId,
      getGroupCompanies,
      initialValues,
      isCategorize,
      isEdit,
      startupId,
      updateIdentitydocuments,
      upsertDocument,
    ]
  );
};

export default useServices;

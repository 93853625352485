import React, {useEffect, useState} from 'react';
import {Constants} from '../../../../../../utils/constants'
import {useDispatch} from "react-redux";
import _ from "lodash";
import {addQuestionnaireIndividual, geIndividualQuestionnaire} from '../../../../../../graphql/mutations/matters';
import notification from "../../../../../../utils/notification";
import Mustache from 'mustache';
import ServerConnect from "../../../../../../utils/ServerConnect";
import Loading from '../../../../../Loading/Loading';
import FormIndividual from '../../../../../../containers/Forms/KYC/Individual/FormIndividual';
import {LayoutForm} from '../../../../../../containers';
import {stripTypenames} from '../../../../../../utils/graphql-util';


const AddQuestionnaireIndividual = (props) => {
    const dispatch = useDispatch();

    const closeForm = () => {
        dispatch({ type: 'GO_TO', page: Constants.PAGES.viewMatter, params: { id: props.params.matterId, tab: "questionnaire" } })
    }

    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(() => {
        return props.mode === "edit" ? true : false;
    })


    const modifyDataFile = (values) => {
        const file={...values}
        var newValues = _.forOwn(file, (val, key) => {
            if (_.hasIn(val, 'file')) {
                return file[key] = val.file
            }
        })
        console.log('NEWVALS', newValues)
        return newValues



    }
    const submitData = (values) => {

        let formatedValues = values
        let message = props.mode == 'edit' ? 'Individual Updated' : 'Individual Created';
        delete formatedValues.formKey
        delete formatedValues.type
        delete formatedValues.isShareholder
        delete formatedValues.isDirector
        delete formatedValues.isPrimary
        delete formatedValues.jurisdiction;
        delete formatedValues.otherJurisdiction;
        
        const variables = props.mode == 'edit' ?  {
            matterId: props.params.matterId,
            KYCIndividualData: formatedValues,
            KYCIndividualId: props.params.id

        } : {matterId: props.params.matterId,
            KYCIndividualData: formatedValues}

        ServerConnect.graphQlMutation(addQuestionnaireIndividual, stripTypenames(variables))
            .then(result => {
                notification.sendNotification(message, "success", 4990);
                dispatch({ type: 'GO_TO', page: Constants.PAGES.viewMatter, params: { id: props.params.matterId, tab: "questionnaire" } })
            }).catch(err => {
                console.log(err);
                notification.sendNotification("ERROR", "error", 4990);
            });
    }

    const getData = () => {
        return new Promise((resolve, reject) => {
            const query_params = {
                matterId: props.params.matterId
            };
            const queryExecute = Mustache.render(geIndividualQuestionnaire, query_params);
            ServerConnect.graphQlQuery(queryExecute).then(result => {
                let data = {
                    ...result.getMattersList
                }
                resolve(data);
            });
        })
    }

    const filterData = (data) => {
        if (data.questionnaire) {
            return _.find(data.questionnaire.individuals, ind => { return props.params.id == ind.id })
        }
    }

    useEffect(() => {
        if (!data && props.mode == 'edit') {
            setLoading(true)
            Promise.resolve(getData())
                .then((result) => {
                    let individual = filterData(result[0]);
                    setData({ ...individual, passports: _.get(individual, 'passports', []).map(pass => modifyDataFile(pass)) })
                    setLoading(false)
                })
        }
    }, [data, loading]);



    return (
        <React.Fragment>
            <Loading show={loading}>
                <LayoutForm
                    title={"Add Individual"}
                    leftEducationId={Constants.CONTENTFUL.ENTRIES.FORMS.KYC_INDIVIDUAL_FORM[`${props.mode == 'edit' ? "EDIT" : "ADD"}`].LEFT_EDUCATION_ID}
                    handleClose={closeForm}>
                    <FormIndividual initialValues={props.mode == 'edit' ? { ...modifyDataFile(data), formKey: 'asd' } : { formKey: 'asd' }} onSubmit={submitData} />
                </LayoutForm>
            </Loading>
        </React.Fragment>
    )
}

export default AddQuestionnaireIndividual;

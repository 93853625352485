import React from 'react';
import classesModule from './ErrorStep.module.scss';

/**
 * View when there is no step defined
 * @param {*} param0
 * @returns
 */
function ErrorStep() {
  return (
    <div className={`${classesModule.ErrorStep}`}>
      {"ERROR"}
    </div>
  );
}

export default ErrorStep;

import React, { useState, useEffect, useRef } from "react";
import SelectInput from "./../SelectInput/SelectInput";
import classes from "./JuridictionSelector.module.scss";
import useCountryList from "./../../../../hooks/services/generic/useJurisdictionList";
import _ from "lodash";
import { isNilCustom } from "../../../../utils/helpersValidate";

const JuridictionSelector = ({
  children,
  defaultList = {},
  countrySelected,
  handleOtherJurisdiction,
  noOtherJurisdiction,
  filterJurisdictions,
  fakeCombo,
  disabled,
  unmoutClean = true,
  ...props
}) => {


  const { data } = useCountryList({ code: "", name: "", language: "en" });
  const [juridictionsLists, setJuridictionsLists] = useState(defaultList);
  const [disabledSelect, setDisabledSelect] = useState(false);
  const refSelectJusrisdiction = useRef();

  const [selectedJurisdictionList, setSelectedJurisdictionList] = useState(() => {
    return defaultList[_.get(countrySelected, "code", countrySelected)] || [];
  });

  useEffect(() => {
    if (_.isEmpty(defaultList)) {
      const newCountries = _.get(data, "countryList");
      if (newCountries) {
        let jurisdictions = {};
        _.forEach(newCountries, (country) => {
          jurisdictions[country.code] = _.map(
            country.jurisdictions,
            (jurisdiction) => ({
              label: jurisdiction.name,
              value: jurisdiction.name,
            })
          );
        });
        setJuridictionsLists(jurisdictions);
      }
    }
  }, [JSON.stringify(data)]);

  useEffect(() => {
    if(!countrySelected) return refSelectJusrisdiction.current.clearValue(); 
    if (_.isString(countrySelected)) {
      const jurisdictions = juridictionsLists[countrySelected];
      setSelectedJurisdictionList(jurisdictions);
      if (_.size(jurisdictions) === 0) {
        refSelectJusrisdiction.current.handleSelected({
          value: "OTHER",
          label: "Other jurisdiction",
        });
        setDisabledSelect(true);
        return;
      } 
        setDisabledSelect(false);
    }
    return () => {
      if (unmoutClean) refSelectJusrisdiction.current.clearValue();
    };
  }, [countrySelected]);

  useEffect(() => {
    if (!isNilCustom(props.value) && !selectedJurisdictionList.length) {
      handleOtherJurisdiction(props.value);
    }
  }, []);

  return (
    <div className={classes.ClaraSelectContainer}>
      <SelectInput
        {...props}
        list={selectedJurisdictionList}
        innerRef={refSelectJusrisdiction}
        autoClean={false}
        disabled={disabledSelect || disabled}
      >
        {children}
      </SelectInput>
    </div>
  );
};

export default JuridictionSelector;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from "lodash";
import { Loading, LogFilters, TimeLine } from '../../../../../v1/components';
import Text from '../../../../../components/text/Text/Text';
import classes from "./ShareManagementRecentActivity.module.scss"
import Scrollbar from "../../../../../components/generic/Scrollbar/Scrollbar";
/* Import Hooks */
import useShareProfileLogs from '../../../../../v1/Hooks/useShareProfileLogs';

import { Waypoint } from 'react-waypoint';
import useSession from '../../../../../modules/session/hooks/useSession';


const ShareManagementRecentActivity = ({ }) => {

    const [page, setPage] = useState(0);
    const {session} = useSession()
    const [filters, setFilters] = useState({});
    const dispatch = useDispatch();
    let { timeLine, loading, hasNextPage } = useShareProfileLogs(page, filters,);

    const handleInfinitesCroll = () => {
        if (hasNextPage) {
            setPage(page + 1);
        }

    }
    let updateFilters = function (values) {
        const newValue = {
            dateFrom: _.get(values, "dateFrom", null),
            dateTo: _.get(values, "dateTo", null),
        }
        if (!_.isEqual(newValue, filters)) {
            setFilters(newValue)
            setPage(0)
        }

    }

    const handleBack = () => {
        dispatch({ type: 'GO_TO_PREV' })
    }

    useEffect(() => {

    })


    return (
        <React.Fragment>
            {/* <div className={classes.AuditLogs}>
                <div>
                    
                </div>
                <div className={classes.ZoneMenu}>
                    <h1><Text uuid={"USER_HISTORY_TITLE"} /></h1>
                    <h2><Text uuid={"AUDITLOGS_FILTER"} /></h2>
                    <p><Text uuid={"AUDITLOGS_FILTER_INFO"} /></p>
                    <LogFilters filterButtonText={'AUDITLOGS_FILTER_BUTTON'} filterButtonClass={true} onlyDateFilter={true} updateFilters={updateFilters} session={session} />
                </div>
                <div className={classes.ZoneTimeLine}>
                    <h1><Text uuid={"AUDITLOGS_TITLE_TIME_LINE"} /></h1>
                    {timeLine.length < 1 ? <Text uuid={"NO_ACTIVITY_YET"} /> : <div className={`${classes.InfiniteScroll} scrollbar`} >
                        <TimeLine timeLine={timeLine} />
                        {!loading ? (
                            <Waypoint
                                onEnter={() => { handleInfinitesCroll(); }}
                                bottomOffset='0%' />
                        ) : <Loading show={loading} inline={true} label={"LOADING"} />}
                    </div>}

                </div>
            </div> */}
            <div className={classes.AuditLogs2}>
                <div className={classes.arrowContainer}>
                    <a
                        className={classes.icon}
                        variant='contained'
                        onClick={(e) => {
                            handleBack()
                        }}
                    >
                    </a>
                </div>
                <div className={classes.ZoneMenu}>
                    <h1><Text uuid={"USER_HISTORY_TITLE"} /></h1>
                    <h2><Text uuid={"AUDITLOGS_FILTER"} /></h2>
                    <p><Text uuid={"AUDITLOGS_FILTER_INFO"} /></p>
                    <LogFilters filterButtonText={'AUDITLOGS_FILTER_BUTTON'} onlyDateFilter={true} updateFilters={updateFilters} session={session} />
                </div>
                <Scrollbar>
                    <div className={classes.ZoneTimeLine}>
                        <h1><Text uuid={"AUDITLOGS_TITLE_TIME_LINE"} /></h1>
                        {timeLine.length < 1 ? <Text uuid={"NO_ACTIVITY_YET"} /> : <div className={`${classes.InfiniteScroll} scrollbar`} >
                            <TimeLine timeLine={timeLine} isSharing={true}/>
                            {!loading ? (
                                <Waypoint
                                    onEnter={() => { handleInfinitesCroll(); }}
                                    bottomOffset='0%' />
                            ) : <Loading show={loading} inline={true} label={"LOADING"} />}
                        </div>}


                    </div>
                </Scrollbar>
            </div>
        </React.Fragment>
    );
}

export default ShareManagementRecentActivity;
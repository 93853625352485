import React, { FC } from "react";
import Wizard from "../../../../generic/components/Wizard";
import ModalAssistant, {ModalAssistantProps} from "../../../../generic/components/Modal/ModalAssistant";
import MachineDataProtectionContact from "./machine";
import useModal from "../../../../generic/hooks/useModal";

interface ModalDataProtectionContactProps extends ModalAssistantProps {
  paramsMutation: object;
  onClose(): void;
}

const ModalDataProtectionContact: FC<ModalDataProtectionContactProps> = ({
  children,
  open = false,
  paramsMutation,
  ...props
}: ModalDataProtectionContactProps) => {

  const { closeModal: onClose } = useModal();

  const handleClose = (values) => {
    onClose();
  };

  return (
    <ModalAssistant open={open}>
      <Wizard
        machine={MachineDataProtectionContact}
        initialValues={paramsMutation}
        onComplete={handleClose}
      />
    </ModalAssistant>
  );
};

export default ModalDataProtectionContact;

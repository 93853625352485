import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import * as Yup from 'yup';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import classModule from "./classes.module.scss";

function SelectCertificate() {

  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.SelectCertificate ?? {}
  }, [context]);

  const handleCancel = (values) => {
    cancel();
  }

  const handleSubmit = async (values) => {
    next(values)
  }

  useEffect(() => {
    dispatch(addAssistantText(translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.REGISTERS_OR_CERTIFICATE_OF_INCUMBENCY.TOOLTIP_1")))
    return () => {
      dispatch(clearAssistatText())
    }
  }, [dispatch])

  const schema = Yup.object().shape({
    selectType:Yup.string().required()
  })

  const options = [
    {
      value: "register",
      label: translate(
        "MODULES.STAKEHOLDER.ENTITY_STRUCTURES.REGISTERS_OR_CERTIFICATE_OF_INCUMBENCY.OPTION_REGISTER"
      ),
    },
    {
      value: "certificate",
      label: translate(
        "MODULES.STAKEHOLDER.ENTITY_STRUCTURES.REGISTERS_OR_CERTIFICATE_OF_INCUMBENCY.OPTION_CERTIFICATE"
      ),
    },
    {
      value: "extract",
      label: translate(
        "MODULES.STAKEHOLDER.ENTITY_STRUCTURES.REGISTERS_OR_CERTIFICATE_OF_INCUMBENCY.OPTION_REGISTRY_EXTRACT"
      ),
    },
  ];

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.REGISTERS_OR_CERTIFICATE_OF_INCUMBENCY.TITLE"),
          skeleton: null,
          subTitle: translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.REGISTERS_OR_CERTIFICATE_OF_INCUMBENCY.SUBTITLE_4"),
          variant: 'simple'
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('BUTTON_NEXT')}</SubmitButton>,
        }}
      >
        <>
          <ControllerInput
            render={CheckboxButtonSimple}
            className={classModule.CheckboxButton}
            classNameButton={classModule.buttonCheckBox}
            name='selectType'
            options={options}
            onChange={value => {}}
          />
        </>
      </TemplateWizard>
    </Form>
  );
}

export default SelectCertificate;


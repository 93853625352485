import _ from 'lodash'
import React from 'react'
import Scrollbar from '../../../../components/generic/Scrollbar/Scrollbar'
import classesModule from './classes.module.scss'
import Typography from '../../../../modules/generic/components/Texts/Typography'
// import TemplateWizardSkeleton from "./TemplateWizardSkeleton";
import useLoading from '../../../generic/hooks/useLoading'
import {
  TemplateFormComponentProps,
  TemplateComponent
} from './../../../../modules/generic/templates/Form'
import useTranslate from '../../../generic/hooks/useTranslate'
export interface TemplateFormPaymentProps {
  title: string
  subTitle: string
  skeleton: React.ReactNode
}

export interface TemplateFormButtonsProps {
  submit?: React.ReactNode
}

const TemplateForm: TemplateComponent<
  TemplateFormPaymentProps,
  TemplateFormButtonsProps
> = ({
  children,
  buttons = {},
  classNameBotonera,
  className,
  loading,
  props: { title = '', subTitle = '', skeleton = null }
}: TemplateFormComponentProps<
  TemplateFormPaymentProps,
  TemplateFormButtonsProps
>) => {
  const { isLoading } = useLoading()
  const { translate } = useTranslate()
  return (
    <React.Fragment>
      <div className={`${classesModule.TemplateForm} ${className}`}>
        {/* {isLoading && (
          <TemplateWizardSkeleton 
            skeleton ={skeleton}
            subTitle={subTitle}
            title={title}
          />
        )} */}
        <div>
          <div className={`${classesModule.Content}`}>
            <Typography
              component={'h2'}
              color={'black'}
              className={`${classesModule.Spacer}`}
            >
              {title}
            </Typography>
            <Typography component={'label'} variant={'body'} color={'black'}>
              {subTitle}
            </Typography>
            <Scrollbar className={`${classesModule.Scrollbar}`}>
              <div className={`${classesModule.Form}`}>{children}</div>
            </Scrollbar>
          </div>

          <div className={`${classesModule.Botonera} ${classNameBotonera}`}>
            {buttons.submit}
            <Typography component={'span'} variant={'xs'} color={'dark-grey'}>
              {translate('MODULES.CHECKOUT.PAYMENT_ADGM_FOOTER')}
            </Typography>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TemplateForm

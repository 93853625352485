import React from 'react';
import classes from './ModalForm.module.scss';
import Text from "../../../components/text/Text/Text";
import Line from "../../../components/generic/Line/Line";
import _ from 'lodash'
import Title from "../../../components/text/Title/Title"

const ModalForm = ({ title, paramTitle, subTitle, showLine, children, className }) => {
    return (
        <React.Fragment> 
            <div className={classes.ModalForm}>
                {title && <Title><Text uuid={title} defaultKey={""} params={paramTitle}/></Title>}
                {subTitle && <h2 className={_.get(className, 'subTitle')} ><Text uuid={subTitle} defaultKey={""} /></h2>}
                {showLine && <Line />}
                <div className={classes.FormChildren}>
                    {children}
                </div>
            </div >
        </React.Fragment >
    )
}

export default ModalForm;
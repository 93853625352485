import {
    ApolloClient,
    InMemoryCache,
    gql
} from "@apollo/client";
import download from "downloadjs";
import _ from "lodash";
import mustache from "mustache";
import { Constants } from "./constants";
import event from "./event";
import logger from "./logger";
import notification from "./notification";

import { HttpLink, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import axios from "axios";
import { isBadRequest, isInvalidToken } from "../../utils/errorHelper";
import { loadState } from "./../../session";
import mockHelper from "./../../utils/mockHelper";
import { getURL, getURLApi, getURLGQL, getURLWebSocket } from "./../../utils/urlHelper";
import cacheConfig from './cache';

let apiUri = getURLApi();

let graphqlUri = getURLGQL();

let gqlUriSubscription = getURLWebSocket();

let apolloClient = null;

export default class ServerConnect {
    static getParamsToPublicUrls(fileId, usePublic) {
        const session = loadState("session");
        const tokenCookie = _.get(session, "token",null);
        const params = { file_id: fileId, token: tokenCookie, api_url: getURL(usePublic) };
        return params;
    }

    static getUniqueApolloClient(force=false) {
        if (!apolloClient || force) {
            apolloClient = ServerConnect.getApolloClientForProvider({});
        }
        return apolloClient;
    }

    static getPublicUrlFile(fileId) {
        return mustache.render(Constants.CONFIG.PUBLIC_URL_FILE, ServerConnect.getParamsToPublicUrls(fileId));
    }

    static getGoogleViewUrlFile(fileId) {
        return mustache.render(Constants.CONFIG.GOOGLE_DRIVE_URL_FILE_PREVIEW, ServerConnect.getParamsToPublicUrls(fileId, true));
    }

    static getMicrosoftViewUrlFile(fileId) {
        return mustache.render(Constants.CONFIG.MICROSOFT_DRIVE_URL_FILE_PREVIEW, ServerConnect.getParamsToPublicUrls(fileId, true));
    }

    static async getPDFViewUrlFile(fileId) {
        const newUrl = this.getPublicUrlFile(fileId);
        return await ServerConnect.requestPublicUrlApi(newUrl, { responseHeader: "application/pdf" }).then(blob => {
            // const newBlob = blob.map( (res) => {
            // 	return new Blob([res], { type: 'application/pdf', title: 'testpdf' })
            // });

            // const newBlob = new Blob([blob], { type: 'application/pdf', title: 'testpdf' });
            const newBlob = new File(blob, 'Test PDF Name');

            const tempURL = URL.createObjectURL(newBlob);
            //URL.revokeObjectURL(tempURL);
            // setUrl(tempURL);
            return tempURL;
        })
    }

    static getUrlFile(path, useApiUri = true) {
        let api = "";
        if (useApiUri) {
            api = apiUri;
        }
        if (path) {
            return api + "/file/?file_id=" + path;
        } else {
            return api + "/file";
        }
    }

    static getUrlZipFile(startup_id) {
        return apiUri + `/startup/${startup_id}/download/documentsbundle`;
    }

    static getUrlCapTableFile(startupId, groupCompanyId) {
        return apiUri + `/startup/${startupId}/${groupCompanyId}/download/captable`;
    }

    static urlUploadMasiveFiles() {
        return apiUri + "/uploadFile";
    }

    static getServerUrl(path) {
        return apiUri + "/" + path;
    }

    static async requestPublicUrlApi(path, options, validations, config) {
        try {

            const session = loadState("session");
            const tokenCookie = _.get(session, "token",null);

            if (!options) {
                options = {};
            }
            if (!options.headers) {
                options.headers = {};
            }
            if (tokenCookie !== "undefined") {
                options.headers["Authorization"] = tokenCookie;
            }
            if (!options.headers['Accept']) {
                options.headers['Accept'] = 'application/json';
            }
            if (!options.headers['Content-Type']) {
                options.headers['Content-Type'] = 'application/json';
            }
            if (!options.responseHeader) {
                options.responseHeader = 'application/json';
            }

            const response = await fetch(path, options);

            if (options.responseHeader === 'application/json') {
                const json = await response.json();
                if (json.statusCode === 200 && validations) {
                    return (validations(json)) ? json : undefined;
                } else {
                    if (json.statusCode !== 200) {
                        throw new Error(json.message)
                    }
                }

                return json;
            }
            if (options.responseHeader === 'application/pdf') {

                return response.blob();

            }
            if (options.responseHeader === 'application/zip') {
                return await download(await response.blob(), "adgm_bundle.zip", "application/zip");

            }
        } catch (err) {
            console.log("Error executing requestApi: " + err.message)
            const showNotification = config ? config.showNotification : true;
            if (_.get(window, "globalNotification") && showNotification !== false) {
                const errorMessage = "We are experiencing temporary delays, please try again in a few minutes"
                notification.sendNotification(errorMessage, "error", 2500);
            }
            return undefined;
        }
    }

    static async requestApi(path, options, validations, config, logData = true) {
        try {
            const session = loadState("session");
            const tokenCookie = _.get(session, "token",null);
            if (!options) {
                options = {};
            }
            if (!options.headers) {
                options.headers = {};
            }
            if (tokenCookie !== "undefined") {
                options.headers["Authorization"] = tokenCookie;
            }
            if (!options.headers['Accept']) {
                options.headers['Accept'] = 'application/json';
            }
            if (!options.headers['Content-Type']) {
                options.headers['Content-Type'] = 'application/json';
            }
            if (!options.responseHeader) {
                options.responseHeader = 'application/json';
            }
            if (logData) {
                logger.info(Constants.LOGGER.ACTIONS.API, { path, options })
            }
            const response = await fetch(apiUri + path, options);
            console.log("**** response serverconnect",response)
            
            if (options.responseHeader === 'application/json') {
                const json = await response.json();
                
                if ((json?.statusCode ?? 200) === 200) {

                    if (!validations){
                        return json
                    }
                    return (validations(json)) ? json : undefined;
                } else {
                    if (isBadRequest(json)) {
                        let error = new Error(json.message);
                        error.name = Constants.HTTP_ERROR_CODES.BAD_REQUEST.name;
                        throw error;

                    } else if (json.statusCode === 403) {
                        let error = new Error(json.message);
                        error.name = Constants.HTTP_ERROR_CODES.BAD_REQUEST.name;
                        throw error;

                    }else if (json.statusCode !== 200) {
                        throw new Error(json.message);
                    }
                }
                return json;
            }
            if (options.responseHeader === 'application/pdf') {

                return response.blob();

            }
            if (options.responseHeader === 'application/zip') {
                const { fileName } = config;
                return await download(await response.blob(), fileName, "application/zip");

            }
        } catch (err) {
            console.log("**** err",err?.name)
            if (logData) {
                logger.error(Constants.LOGGER.ACTIONS.API, { path, options })
            }
            const showNotification = config ? config.showNotification : true;
            const throwExeptionWithDefault = config ? config.throwExeptionWithDefault : true;
            
            if (_.get(window, "globalNotification") && showNotification !== false) {
                let errorMessage = "";
                switch (err.name){
                    case Constants.HTTP_ERROR_CODES.FORBIDDEN_REQUEST.name:
                    case Constants.HTTP_ERROR_CODES.BAD_REQUEST.name:
                        errorMessage= err.message;
                        break
                    default:
                        errorMessage = "We are experiencing temporary delays, please try again in a few minutes";
                        if (throwExeptionWithDefault!==false){
                            throw new Error(err)
                        }
                        
                        break;
                }
                notification.sendNotification(errorMessage, "error", 2500);
                return undefined;
            }
            return undefined;
        }
    }


    static getApolloClient(options = { cacheDisabled: false }, token) {
        const session = loadState("session");
        const tokenCookie = _.get(session, "token",null);
        

        const link = new HttpLink({
            uri: graphqlUri,
            headers: {
                "Authorization": tokenCookie  
            }
        });
        return new ApolloClient({
            link,
            cache: new InMemoryCache(cacheConfig)
        });

    }

    
    static getApolloClientForProvider(options = { cacheDisabled: false }) {
        const session = loadState("session");
        const tokenCookie = _.get(session, "token",null);
        
        const wsLink = new WebSocketLink({
            uri: gqlUriSubscription,
            options: {
                reconnect: true,
                connectionParams: {
                    Authorization: tokenCookie
                },
                lazy: true
            }
        });


        const authLink = setContext((props, data) => {
            // get the authentication token from local storage if it exists
            const session = loadState("session");
            const token = _.get(session, "token",null);
            // return the headers to the context so httpLink can read them
            return {
                headers: {
                    ...data.headers,
                    Authorization: token ? token : "",
                }
            }
        });

        const authWsLink = setContext(
            (props, data) => {
                // get the authentication token from local storage if it exists
                const session = loadState("session");
                const token = _.get(session, "token",null);
                // return the headers to the context so httpLink can read them
                return {
                    connectionParams: {
                        ...data.connectionParams,
                        Authorization: token ? token : ""
                    }
                }
            })


        const httpLink = new HttpLink({
            uri: graphqlUri,
            headers: {
                "Authorization": tokenCookie
            }
        });

        const link = split(
            ({ query }) => {
                const definition = getMainDefinition(query);
                return (
                    definition.kind === 'OperationDefinition' &&
                    definition.operation === 'subscription'
                );
            },
            authWsLink.concat(wsLink),
            authLink.concat(httpLink),
        );


        return new ApolloClient({
            link,
            cache: new InMemoryCache(cacheConfig)
        });

    }


    static graphQlQuery(query, variables = {}, options = {}, config = {}) {
        return new Promise((resolve, reject) => {
            if (process.env && process.env.NODE_ENV == "development" && _.get(options, "demo.before", false) === true) {
                resolve(mockHelper.getBeforeMock(options.demo))
                return;
            }
            const client = ServerConnect.getUniqueApolloClient();

            logger.info(Constants.LOGGER.ACTIONS.QUERY, { query })

            client.query({
                query: gql`${query}`,
                variables: variables,
                fetchPolicy: 'cache-first',
                ...config
            }).then(result => {
                if (result.data) {
                    if (process.env && process.env.NODE_ENV == "development" && _.get(options, "demo.after", false) === true) {
                        resolve(mockHelper.getAfterMock(options.demo, result.data))
                        return;
                    }
                    resolve(result.data);
                } else {
                    reject({});
                }
                logger.success(Constants.LOGGER.ACTIONS.QUERY, { result, query })
            }).catch(err => {
                if (!isInvalidToken(err)) {
                    logger.error(Constants.LOGGER.ACTIONS.QUERY, { query, error: err })
                    console.log("Error executing query: " + err.message)
                    reject(err);
                } else {
                    event.emmit(Constants.EVENTS.ERROR_401, {})
                }
            })
        });
    }
   
    static graphQlMutation(query, variables = {}, options = { cacheDisabled: false }, config = {}, configMutation = {}) {
        const defaultShouldRefetchQuery = (observer)=>{
            return true;
        }
        const onQueryUpdated = (observableQuery) =>{
    
            const validate = _.get(config,"shouldRefetchQuery",defaultShouldRefetchQuery);
            if (validate(observableQuery)) {
                return observableQuery.refetch();
            }
    
        }
        const updateCache = (cache, { data })=> {
           
            cache.evict({
                id: "ROOT_QUERY",
                broadcast: false
              });
            cache.gc()
        };
        return new Promise((resolve, reject) => {

            const client = ServerConnect.getUniqueApolloClient();
            logger.info(Constants.LOGGER.ACTIONS.MUTATION, { query, variables })
            client.mutate({
                mutation: gql`${query}`,
                awaitRefetchQueries: _.get(config, "awaitRefetchQueries", true), 
                fetchPolicy: "network-only",
                onQueryUpdated: _.get(options,"onQueryUpdated",onQueryUpdated),
                update: _.get(options,"update",updateCache),
                variables: variables,
                ...configMutation
            }).then(result => {
                if (result.data) {
                    resolve(result.data);
                } else {
                    reject()
                }
                logger.success(Constants.LOGGER.ACTIONS.MUTATION, { result, query })
            }).catch(err => {
                if (!isInvalidToken(err)) {
                    logger.error(Constants.LOGGER.ACTIONS.MUTATION, { query, variables, error: err });
                    console.log("Error executing mutation: " + err.message);
                    const showNotification = config ? config.showErrorNotification : true;
                    if (_.get(window, "globalNotification") && showNotification) {
                        const errorMessage = err.message || err.message === "" ? err.message : "Oops, something’s gone wrong. Please check your internet connection and reload the page";
                        notification.sendNotification(errorMessage, "error", 2500);
                    }

                    reject(err.message)
                } else {
                    event.emmit(Constants.EVENTS.ERROR_401, {})
                }

            })
        });
    }

    static downloadFile(url, fileName) {

        const filenameWithoutExtension = fileName.split('.').slice(0, -1).join('.');
        const extension = fileName.split(".").pop();

        if (extension && extension === "html") {
            url = url + "&getAsPdf=true";
            fileName = filenameWithoutExtension + ".pdf"
        }

        const downloadCallback = (x, e) => download(x.response, fileName);
        this.fetchFile(url, fileName, downloadCallback);

    }

    static downloadFileAsDoc(url, fileName) {

        const downloadCallback = (x, e) => download(x.response, fileName, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
        this.fetchFile(url, fileName, downloadCallback);

    }



    static openFile(url, fileName) {
        const viewableFilesTypes = ["pdf", "png", "jpg"];
        const fileType = fileName.split(".").pop();
        const fileIsViewable = viewableFilesTypes.indexOf(fileType) >= 0;

        if (fileIsViewable) {
            const openCallback = (x, e) => {
                const tempURL = URL.createObjectURL(x.response);
                window.open(tempURL, "_blank");

                window.onbeforeunload = () => {
                    URL.revokeObjectURL(tempURL);
                };
            };
            this.fetchFile(url, fileName, openCallback);
        } else {
            this.downloadFile(url, fileName);
        }
    }

    static async fetchFileAxios(url) {
        const session = loadState("session");
        const tokenCookie = _.get(session, "token",null);
    	

        return axios({
            method: "GET", url,
            headers: {
                "Content-type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": tokenCookie
            },
            responseType: "blob"
        });
    }


    static async downloadFileAsXLS(url, fileName) {
        const response = await this.fetchFileAxios(url);
        await download(response.data, fileName, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    }

    static fetchFile(url, fileName, callback) {
        let sender_data = {};
        const session = loadState("session");
        const tokenCookie = _.get(session, "token",null);
        let x = new XMLHttpRequest();
        x.open("GET", url, true);
        x.setRequestHeader("Content-type", "application/json");
        x.setRequestHeader("Access-Control-Allow-Origin", "*");
        x.setRequestHeader("Authorization", tokenCookie);
        x.responseType = 'blob';

        x.onload = function (e) {
            callback(x, e)
        };
        x.onerror = function (e) {
            window.location = "/login"
        };
        x.send(JSON.stringify(sender_data));
    }

    static async refreshToken() {

        let token = null;
        logger.info(Constants.ACTIONS.API, {})
        const tokenResult = await this.requestApi("/auth/token-refresh", {
            method: "POST",
            headers: { "Content-Type": "application/json" }
        })

        if (tokenResult) {
            
            token=  tokenResult.token;
            
        }


        return token

    }


}

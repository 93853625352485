import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import * as Yup from 'yup';
import _ from 'lodash';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import Typography from 'src/modules/generic/components/Texts/Typography';
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import classes from '../classes.module.scss';

/**
* Description Step: You have selected yes in the previous step
* now you  have to decide if you are a seed or emergent startup
*/
function WhatIsYourStartupFormStep() {

  const { next, context, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => { 
    return {
      ...context?.WhatIsYourStartupFormStep ?? {},
      ...context?.GetWhatIsYourStartupData ?? {}
    }
  }, [context]);

  const handleCancel = (values) => {
    cancel();
  }

  const handlePrev = (values) => {
    prev();
  }

  const handleSubmit = async (values) => {
    next(values)
  }

  useEffect(() => {
    dispatch(addAssistantText('For further information on the differences between Seed and Emergent stage Tech Startup Licences, click <a rel=\'noopener noreferrer\' target=\'_blank\' href=\'https://help.clara.co/en/articles/6965962-what-is-the-difference-between-the-seed-and-emergent-stages-for-an-adgm-tech-startup-License\' >here</a>.'))
    dispatch(addAssistantText('The fees quoted include the ADGM licensing fees and Clara’s service fees. They do not include fees relating to the office space or visas. If you select the wrong category, you will have to pay the difference in fees before the application is submitted to ADGM.'))
    return () => {
      dispatch(clearAssistatText())
    }
  }, [dispatch])

  const schema = Yup.object().shape({
    startupStage: Yup.string().nullable().required("Required"),
  })

  const options = [
    {
      value: 'SEED',
      component: ButtonText,
      componentProps: {
        title: "Seed",
        text: (<ul className={classes.wiysList}>
          <li>{`Cost ${_.get(initialValues, 'seed.currency', '')}  ${_.get(initialValues, 'seed.value', '')} + VAT`}</li>
          <li>{"Number of employees: 1-10"}</li>
          <li>{"Years of operation: < 3 years"}</li>
        </ul>)
      },
    },
    {
      value: 'EMERGENT',
      component: ButtonText,
      componentProps: {
        title: "Emergent",
        text: (<ul className={classes.wiysList}>
          <li>{`Cost ${_.get(initialValues, 'emergent.currency', '')}  ${_.get(initialValues, 'emergent.value', '')} + VAT`}</li>
          <li>{"Number of employees: 11-50"}</li>
          <li>{"Years of operation: < 5 years"}</li>
        </ul>)
      },
    }
  ];

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: 'What is your startup stage? ',
          skeleton: null,
          subTitle: 'ADGM eligibility',
          variant: 'simple'
        }}
        buttons={{
          previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('BUTTON_NEXT')}</SubmitButton>,
        }}
      >
        <Typography weight='regular' variant='xbody'>
          There are two types of ADGM Tech Startup Licences available depending on the maturity of the business. Each Licence category has different eligibility requirements and licensing fees. Select which Licence applies to your business:
        </Typography>
        <ControllerInput
          as={CheckboxButtonSimple}
          name="startupStage"
          className={classes.wiysCheckboxButton}
          classNameButton={classes.wiysButtonCheckBoxSize}
          variant="col_1"
          options={options}
        />
      </TemplateWizard>
    </Form>
  );
}

export default WhatIsYourStartupFormStep;

const ButtonText = ({ title, text }) => {
  return (
    <div className={classes.wiysButtonText}>
      <Typography weight="bold" variant="xbody" >
        {title}
      </Typography>
      <Typography variant="body" weight='light'>{text} </Typography>
    </div>
  );
};


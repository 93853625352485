import classnames from "classnames";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import halfCircle from "../../../../images/half_circle_2.svg";
import PaymentSuccessMessage from "../../../../modules/checkout/components/PaymentSuccessMessage/PaymentSuccessMessage";
import useFinish3DSPaymentToUpgradeStartup from "../../../../modules/checkout/hooks/useFinish3DSPaymentToUpgradeStartup";
import useGetProduct from "../../hooks/useGetProduct";
import useGetStartupUpdatePlan from "../../../../modules/checkout/hooks/useGetStartupUpdatePlan";
import useSession from "../../../session/hooks/useSession";
import PaymentTimeOutMessage from "../../components/PaymentTimeOutMessage/PaymentTimeOutMessage";
import PaymentForm from "../../forms/Payment/PaymentForm";
import { PaymentProvider } from "../../hooks/usePayment";
import Scrollbar from "./../../../../components/generic/Scrollbar/Scrollbar";
import PaymentTotal from "./../../../../modules/checkout/components/PaymentTotal/PaymentTotal";
import classesModal from "./PaymentView.module.scss";
import StripeElementKey from "../../components/StripeElementKey";
const PaymentView = ({
  canSelectCard,
  productCode,
  id,
  completeFinish,
  source = "STARTUP",
  isPrevContent = false,
  prevContent,
}) => {
  const STATUS = {
    success: "success",
    error: "error",
    process: "process",
    input: "input",
  };

  const [finishPayment, loadingPayment] = useFinish3DSPaymentToUpgradeStartup({
    showSuccessNotification: false,
    showErrorNotification: false,
    onCompleted: completeFinish,
  });

  const [status, setStatus] = useState(STATUS.input);
  const [url, setUrl] = useState(null);
  const [vat, setVat] = useState(null);
  const [defaultVat, setDefaultVat] = useState({});
  const [freeVat, setFreeVat] = useState(false);
  const [couponCode, setCouponCode] = useState();
  const [errorMessageCard, setErrorMessageCard] = useState(false);
  const { loading, error, data: product } = useGetProduct({
    productCode,
    relatedItemId: id,
  });

  const {
    manualQuery: getStartup,
    loading: loadingGetStartup,
    data: dataGetStartup,
  } = useGetStartupUpdatePlan(
    {},
    { showSuccessNotification: false, showErrorNotification: false }
  );

  const finishWithCard = (value) => {
    getStartup({ variables: { startupId: startup.id } });
  };

  const { startup, updateStartup } = useSession();

  useEffect(() => {
    if (dataGetStartup) {
      updateStartup(dataGetStartup.getStartup);
    }
  }, [dataGetStartup, JSON.stringify(updateStartup)]);

  const handleChangeCoupon = (code) => {
    setCouponCode(code);
  };

  const handleCompleteForm = (value, useCard, status) => {
    if (useCard) {
      setStatus(status);
      if (value) {
        setUrl(value);
      }
    } else {
      setStatus(status);
      if (value) {
        setUrl(value);
      }
    }
  };

  const handleErrorForm = (error) => {
    setStatus(STATUS.error);
    setErrorMessageCard(error);
  };

  const handleChangeVat = (value) => {
    setVat(value);
  };

  const handleChangeFreeVat = (value) => {
    setFreeVat(value);
  };
  // ckoSessionId? paymentInt
  useEffect(() => {
    const handler = async (event) => {
      try {
        const data = JSON.parse(event.data);
        if (_.get(data, "claraPayment") === true) {
          if (_.get(data, "ckoSessionId")) {
            const newStatus = _.get(data, "status", STATUS.input);


            const ckoSessionId = _.get(data, "ckoSessionId", STATUS.input);
            if (source === 'FORMATION_COMPANY') {
              await finishPayment({ ckoSessionId, paymentSource: source, relatedItemId: id, startupId: startup.ID })
            } else {
              await finishPayment({ ckoSessionId, paymentSource: source });

            }
            setStatus(newStatus);
            setUrl(null);
          } else {
            const newStatus = _.get(data, "status", STATUS.input);
            setStatus(newStatus);
            if (completeFinish) completeFinish();
          }
        }
      } catch (e) { }
    };
    window.addEventListener("message", handler);
    return () => window.removeEventListener("message", handler);
  }, []);

  useEffect(() => {
    if (_.get(product, "getProduct.defaultVAT")) {
      if (!vat) {
        setVat(_.get(product, "getProduct.defaultVAT"));
      }
      setDefaultVat(_.get(product, "getProduct.defaultVAT", {}));
    }
  }, [product, vat]);



  const [counter, setCounter] = useState(-1);

  React.useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);
  const handleOnload = (data) => {

    if (status === STATUS.process) {
      setCounter(window?.env?.checkout?.timeToExpiredInSeconds ?? 60)
    }
  }
  const handleRetry = useCallback(() => {
    setCounter(-1)
    setStatus(STATUS.input)
  }, [STATUS.input])
  return (
    <React.Fragment>
      <StripeElementKey source={source}>
        <PaymentProvider>
          <div className={classesModal.PaymentView}>
            <div className={classesModal.blueZone}>
              <img
                className={classesModal.circleImage}
                src={halfCircle}
                alt="Clara"
              />
              <PaymentTotal
                isPaid={status === STATUS.success}
                isProcess={status === STATUS.process}
                product={_.get(product, "getProduct")}
                vat={vat}
                freeVat={freeVat}
                onChangeCoupon={handleChangeCoupon}
              />
            </div>
            <div className={classesModal.paymentZone}>
              <Scrollbar>
                {(status === STATUS.input || status === STATUS.error) &&
                  !isPrevContent && (
                    <PaymentForm
                      onErrorForm={handleErrorForm}
                      onCompleteForm={handleCompleteForm}
                      product={_.get(product, "getProduct")}
                      onChangeVat={handleChangeVat}
                      onChangeFreeVat={handleChangeFreeVat}
                      errorCard={status === STATUS.error}
                      errorMessageCard={errorMessageCard}
                      couponCode={couponCode}
                      source={source}
                      defaultVat={defaultVat}
                      canSelectCard={canSelectCard}
                      id={id}
                    />
                  )}
                {(status === STATUS.input || status === STATUS.error) &&
                  isPrevContent && <React.Fragment>{prevContent}</React.Fragment>}
                {status === STATUS.success && (
                  <PaymentSuccessMessage
                    loading={loadingPayment}
                    product={_.get(product, "getProduct")}
                    id={id}
                    invoiceNumber={""}
                  />
                )}
                {status === STATUS.process && counter === 0 && (
                  <PaymentTimeOutMessage
                    onRetry={handleRetry}
                  />
                )}
              
              </Scrollbar>
            </div>
          </div>
        </PaymentProvider>
      </StripeElementKey>
    </React.Fragment>
  );
};

export default PaymentView;

import React, { useMemo, useState, useEffect, useRef } from 'react'
import * as Yup from 'yup';
import _ from 'lodash';
import Form from '../../../../../../components/forms/Form/Form'
import useTranslate from '../../../../../../modules/generic/hooks/useTranslate'
import ControllerInput from '../../../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput'
import SubmitButton from '../../../../../../modules/generic/components/Buttons/SubmitButton'
import FormProps from '../../../../../../modules/generic/forms/interfaces/FormProps';
import TemplateFormDefault from "../../../../../../modules/generic/templates/Modal";
import { useDispatch } from 'react-redux';
import { addAssistantText, clearAssistatText } from '../../../../../../modules/generic/store/action';
import { HiddenFieldForm, ObserverField } from "../../../../../../components/forms/contents";
import RowAdd from "../../../../../../components/forms/contents/RowAdd/RowAdd";
import AddEntity from "./Components/AddEntity";
import useSession from "../../../../../../modules/session/hooks/useSession";
import useGetIndividualStakeholderPositions from "../../../../../../hooks/services/modules/individualStakeholder/useGetIndividualStakeholderPositions";
import useGetIndividualStakeholderDepartments from "../../../../../../hooks/services/modules/individualStakeholder/useGetIndividualStakeholderDepartments";
import FormAddPosition from "./Components/FormAddPosition";
import FormAddDepartment from "./Components/FormAddDepartment";
import Text from "../../../../../text/Text/Text";
import classes from "./PositionForm.module.scss";
import PositionCheckbox from './Components/PositionCheckbox/PositionCheckbox';
import TextareaInput from 'src/components/inputs/Textarea/TextareaInput';

//TODO getPositions startupId

const schema = Yup.object().shape({
  // position: Yup.string().required('This field is required').nullable(),
  // department: Yup.string().required('This field is required').nullable(),
  // responsibilities: Yup.string().required('This field is required').nullable(),
})



const StepCompanyInfo: React.FC<FormProps> = ({
  initialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate
}: FormProps) => {

  const { translate } = useTranslate()
  const refForm = React.useRef(null);
  const dispatch = useDispatch();
  const { startup, startupId } = useSession();
  const { data: positions } = useGetIndividualStakeholderPositions({ variables: { startupId } });
  const { data: departments } = useGetIndividualStakeholderDepartments({ variables: { startupId } });
  const [messageAddPosition, setMessageAddPosition] = useState({ open: false, text: "", });
  const [positionAdded, setPositionAdded] = useState([])
  const [first, setFirst] = useState(true)
  const [departmentAdded, setDepartmentAdded] = useState([])
  const [messageAddDepartment, setMessageAddDepartment] = useState({ open: false, text: "", });
  const [selectedGC, setSelectedGC] = useState(null);
  const [responsibilities, setResponsibilities] = useState([]);
  const refSelectPosition = useRef();
  const refSelectDepartment = useRef();
  const refSelectResponsibilities = useRef()
  const [selectedPosition, setSelectedPosition] = useState(null);


  //What to do on submit
  const handleSubmit = async values => {
    onCompleteSubmit && onCompleteSubmit(values);
  };


  //Assistant messages
  React.useEffect(() => {
    dispatch(addAssistantText(translate('MODULES.MAP.WIZARD_COMPANY_DETAIL_INIT_TEXT_ASSISTANCE'), "text"));
    return () => {
      dispatch(clearAssistatText());
    }
  }, []);

  const handleChangePosition = (value) => {
    if (first && !value) setFirst(false)
    setSelectedPosition(value)
    if (value) {
      setResponsibilities(_.map(value.responsibilities, option => option.isDefault
          ? { code: option.code, value: option, label: option.name,isDefault:option.isDefault }
          : { code: option.code, value: { ...option, text: null }, label: option.name, isDefault: option.isDefault }))
      const selectedResponsibilities = []
      _.forEach(refForm.current.getValues().responsibilities,(rv) => {
        const found = _.find(value.responsibilities,(vr) => {
          return vr?.code === rv?.code;
        });
        
        if (found) {
          let newRv = {...rv, name: found.name}

          
          selectedResponsibilities.push(newRv)
        }
      })
      refForm.current.setValue("responsibilities",selectedResponsibilities);
      refForm.current.setValue("other",null);
    } else {
      setResponsibilities([])
      refForm.current.setValue("responsibilities",[]);
      refForm.current.setValue("other",null);
    }
  }

  const handleOpenFormPosition = () => {
    setMessageAddPosition({
      open: true,
      text: "MESSAGE_ADD_POSITION_COMPONENT",
    });
  };

  const menuPropsPosition = {
    addList: [
      {
        label: "Add New Position",
        type: "position",
        onClick: handleOpenFormPosition,
      },
    ],
    propsList: [{ type: "position", title: "Set Position" }],
  };

  const handleAddPosition = (value, type) => {
    
    refForm.current.setValue("position", value);
    handleChangePosition(value);
  };

  const handleCloseMessageAddPosition = () => {
    setMessageAddPosition({ open: false, text: "" });
  };

  const formsAddPosition = [
    {
      //Se debe cambiar por el nuevo upsert de positions
      type: "position",
      form: FormAddPosition,
      handleAddValueList: handleAddPosition,
      propsForm: {
        startupId,
        handleCloseMessage: handleCloseMessageAddPosition,
      },
    },
  ];


  const formattedPositions = useMemo(() => {
    const list = [];
    if (!_.isEmpty(positions)) {
      _.forEach(positions, (pos) => {
        list.push({ label: pos.name, value: pos, type: "position" });
      });

    }
    if (!_.isEmpty(positionAdded)) {
      _.forEach(positionAdded, (pos) => {
        list.push({ label: pos.name, value: pos, type: "position" });
      });

    }
    
    return list;
  }, [JSON.stringify(positions),JSON.stringify(positionAdded)]);

  const formattedDepartments = useMemo(() => {
    const list = [];
    if (!_.isEmpty(departments)) {
      _.forEach(departments, (dep) => {
        list.push({ label: dep.name, value: dep, type: 'department' });
      });

    }
    if (!_.isEmpty(departmentAdded)) {
      _.forEach(departmentAdded, (dep) => {
        list.push({ label: dep.name, value: dep, type: 'department' });
      });

    }
    
    return list;
    
  }, [JSON.stringify(departments),JSON.stringify(departmentAdded)]);

  const handleAddDepartment = (value, type) => {
    setDepartmentAdded((v) =>{
      return [...v,value]
    })
    refForm.current.setValue("department", value);
  };

  const handleCloseMessageAddDepartment = () => {
    setMessageAddDepartment({ open: false, text: "" });
  };

  const formsAddDepartment = [
    {
      //Se debe cambiar por el nuevo upsert de departments

      type: "department",
      form: FormAddDepartment,
      handleAddValueList: handleAddDepartment,
      propsForm: {
        startupId,
        handleCloseMessage: handleCloseMessageAddDepartment,
      },
    },
  ];

  const handleOpenFormDepartment = () => {
    setMessageAddDepartment({
      open: true,
      text: "MESSAGE_ADD_DEPARTMENT_COMPONENT",
    });
  };

  const menuPropsDepartment = {
    addList: [
      {
        label: "Add New Department",
        type: "department",
        onClick: handleOpenFormDepartment,
      },
    ],
    propsList: [{ type: "department", title: "Set Department" }],
  };



  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onSubmit={handleSubmit}
      onlySendDirty={false}
      ref={refForm} 
      optionsForm={{ mode: "onChange" }}
    >
      <Template buttons={{
        ...buttons,
        submit: <SubmitButton
          isSubmitting={undefined}
          handleDisabled={undefined}
          children ={"Next"}
          {...buttonSubmitProps}/>
          
      }}
        props={propsTemplate}>
        <AddEntity
          asterisk={false}
          formsAdd={formsAddPosition}
          refSelect={refSelectPosition}
          callbackParams={selectedGC}
          list={formattedPositions}
          callback={handleChangePosition}
          menuProps={menuPropsPosition}
          message={messageAddPosition}
          defaultPathValue={'label'}
          name={`position`}
          label={"POSITION_FIELD_LABEL"}
          compare={"code"}
          aux={true}
          autoClean={false}
        />

        <AddEntity
          asterisk={false}
          list={formattedDepartments}
          formsAdd={formsAddDepartment}
          refSelect={refSelectDepartment}
          menuProps={menuPropsDepartment}
          message={messageAddDepartment}
          defaultPathValue={'label'}
          name={`department`}
          label={"DEPARTMENT_FIELD_LABEL"}
          callback={undefined}
          callbackParams={undefined}
          compare={"id"}
          aux={true}
          autoClean={false}
        />
        <HiddenFieldForm
          fieldsWatch={`position`}
          conditionHideCallback={(value) => {
            return value && _.get(value, `isDefault`);
          }}
        >
          <div className={classes.checkboxPositionContainer}>
            <span className={classes.responsibilityLabel}>
              <Text uuid={"RESPONSIBILITIES_FIELD"} />
            </span>
            <ControllerInput
              render={PositionCheckbox}
              refCheckbox={refSelectResponsibilities}
              name={`responsibilities`}
              placeholder={"Type here..."}
              list={responsibilities}
              nameRoot={`position`}
              nameOther={`other`}
              onChange={(e) => {}}
              isMountComponent={true}
            />
          </div>
        </HiddenFieldForm>
       
        <HiddenFieldForm
          fieldsWatch={`position`}
          conditionHideCallback={(value) => {
            return value && !_.get(value, `isDefault`);
          }}
        >
          <RowAdd
            label={"RESPONSIBILITIES_FIELD"}
            asterisk={false}
            input={
              <ControllerInput
                as={TextareaInput}
                name={`responsibilities[0].text`}
                defaultValue={""}
                placeholder={"Type here..."}
                onChange={(e) => {
                }}
              />
            }
          />
        </HiddenFieldForm>

      </Template>
    </Form>
  )
}

export default StepCompanyInfo

import useLazyQuery from "src/hooks/custom/useLazyQuery";

const GET_STAKEHOLDER = `
    query getStakeholder($startupId: ID!,$stakeholderId:ID!) {
        getStakeholder(startupId: $startupId,stakeholderId:$stakeholderId) {
            id
      fullName
      isFounder
      roles{
        id
        name
        stakeholder{
          id
        }
        entity {
            ... on StakeHolder {
              id
              fullName
            }
            ... on GroupCompany {
                id
                name
            }
        }
      }
        }
    }
`;
const useGetStakeholderById = () => {
  const { manualQuery, loading, refetch, error } = useLazyQuery(GET_STAKEHOLDER);
  return { loading, error, refetch, manualQuery };
}
export default useGetStakeholderById;

import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import _ from "lodash";
import Form from "src/components/forms/Form/Form";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import Button from "src/modules/generic/components/Buttons/Button";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import {
  clearAssistatText,
  addAssistantText,
} from "src/modules/generic/store/action";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import TextInput from "src/components/inputs/Text/TextInput";
import SelectInput from "src/components/inputs/Select/SelectInput/SelectInput";
import classes from "./classes.module.scss";
import { InputLabelWithBanner } from "src/components/inputs/InputWithBanner";
import RadioInput from "src/components/inputs/Radio/RadioInput";
import { ARTICLES_OF_ASSOCIATION_TYPE } from "src/modules/startup/GroupCompany/Forms/FormBusinessActivityCayman";

function ReviewCompanyDetailsStep() {
  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return (
      context?.ReviewCompanyDetailsStep ??
      context?.initialData?.groupCompany ??
      {}
    );
  }, [context]);

  const typeList = [
    {
      value: initialValues?.type,
      label: _.startCase(_.toLower(initialValues?.type)),
    },
  ];

  const handleSubmit = async (values) => {
    next(values);
  };

  const handleCancel = () => {
    cancel();
  };

  useEffect(() => {
    dispatch(
      addAssistantText(
        translate(
          "MODULES.RENEWALS_ADGM.REVIEW_COMPANY_DETAILS.STEP_1.TOOLTIP_1"
        ),
        "alert"
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const licenseTypeList = [
    {
      value: "SPV",
      label: "Special Purpose Vehicles (SPV)",
    },
    {
      value: "TSL",
      label: "Tech Licence",
    },
  ];

  const schema = Yup.object().shape({
    name: Yup.string().required("Required"),
    licenseType: Yup.string().required("Required"),
    type: Yup.string().required("Required"),
    businessActivities: Yup.object().shape({
      activity: Yup.string().nullable().typeError("Required").required(),
      articlesOfAssociationType: Yup.string()
        .nullable()
        .typeError("Required")
        .required(),
      companyArticlesEntrenched: Yup.boolean()
        .nullable()
        .typeError("Required")
        .required(),
    }),
    // accountDetails: Yup.object().shape({
    //   referenceDate: Yup.date().nullable().typeError("Required").required(),
    // }),
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: "Review and confirm the company details",
          skeleton: null,
          subTitle: "Review company details",
          variant: "simple",
        }}
        buttons={{
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("BUTTON_CANCEL")}
            </Button>
          ),
          submit: <SubmitButton>Next</SubmitButton>,
        }}
      >
        <InputLabelWithBanner
          bannerLogicType="touched"
          component={TextInput}
          controllerInputProps={{
            name: "name",
            placeholder: translate("MODULES.CLARA.TYPE_HERE_HIT"),
          }}
          templateLabelProps={{
            bannerTitle: translate("MODULES.RENEWALS_ADGM.COMMON.BANNER_1", {
              currency: "USD",
              price:
                context?.pincasData?.pincasData?.CHANGE_COMPANY_NAME?.value ??
                0,
              button: "Confirm",
            }),
            label: "Company name",
            isRequired: true,
          }}
        />
        <TemplateLabel label="Licence type" isRequired>
          <ControllerInput
            name="licenseType"
            list={licenseTypeList}
            placeholder={translate("SELECT_INPUT_PLACEHOLDER_NORMAL")}
            render={SelectInput}
            disabled
          />
        </TemplateLabel>
        <TemplateLabel label="Company type" isRequired>
          <ControllerInput name="type" render={TextInput} disabled />
        </TemplateLabel>
        <TemplateLabel label="Business activity" isRequired>
          <ControllerInput
            name="businessActivities.activity"
            placeholder={translate("MODULES.CLARA.TYPE_HERE_HIT")}
            render={TextInput}
            disabled
          />
        </TemplateLabel>
        <TemplateLabel label="Articles of Association" isRequired>
          <ControllerInput
            name="businessActivities.articlesOfAssociationType"
            placeholder={translate("SELECT_INPUT_PLACEHOLDER_NORMAL")}
            render={SelectInput}
            list={[
              {
                value: ARTICLES_OF_ASSOCIATION_TYPE.MODEL,
                label: "Model",
              },
              {
                value: ARTICLES_OF_ASSOCIATION_TYPE.MODEL_WITH_AMENDMENTS,
                label: "Model with amendments",
              },
              {
                value: ARTICLES_OF_ASSOCIATION_TYPE.CUSTOM,
                label: "Bespoke",
              },
            ]}
            disabled
          />
        </TemplateLabel>
        <TemplateLabel
          isRequired
          className={classes.radioInput}
          multilineTexts={["Are the company's articles entrenched?"]}
        >
          <ControllerInput
            render={RadioInput}
            name="businessActivities.companyArticlesEntrenched"
            defaultValue={false}
            disabled={true}
          />
        </TemplateLabel>
        {/*<InputLabelWithBanner*/}
        {/*  bannerLogicType="touched"*/}
        {/*  component={CalendarInputDate}*/}
        {/*  controllerInputProps={{*/}
        {/*    name: "accountDetails.referenceDate",*/}
        {/*    placeholder: translate("MODULES.CLARA.ROLE_DATE_INPUT_HIT"),*/}
        {/*    list: typeList,*/}
        {/*  }}*/}
        {/*  templateLabelProps={{*/}
        {/*    bannerTitle: translate("MODULES.RENEWALS_ADGM.COMMON.BANNER_1", {*/}
        {/*      currency: "USD",*/}
        {/*      price:*/}
        {/*        context?.pincasData?.pincasData*/}
        {/*          ?.CHANGE_COMPANY_ACCOUNTING_REFERENCE_DATE?.value ?? 0,*/}
        {/*      button: "Confirm",*/}
        {/*    }),*/}
        {/*    label: "Accounting reference date",*/}
        {/*    isRequired: true,*/}
        {/*  }}*/}
        {/*/>*/}
        <div>&nbsp;</div>
      </TemplateWizard>
    </Form>
  );
}

export default ReviewCompanyDetailsStep;

import React, { useEffect, useMemo } from "react";
import _ from "lodash";
import * as Yup from "yup";
import {
  addAssistantText,
  clearAssistatText,
} from "src/modules/generic/store/action";
import AddressInput from "src/components/forms/modules/startups/individualStakeholder/AddressInput";
import Button from "src/modules/generic/components/Buttons/Button";
import Form from "src/components/forms/Form/Form";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import { useDispatch } from "react-redux";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";

export default function ReviewPersonalDetailAddressesStepForm() {
  const { next, context, cancel, prev } = useMachine();
  const { initialData } = context;
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    let init1 =
      context?.ReviewPersonalDetailShareholderAddressesStepForm ??
      initialData?.address;
    const init2 = _.cloneDeep(init1);
    if (init2?.address?.country?.code) {
      init2.address.country = init2.address.country.code;
    }
    return init2;
  }, [
    context?.ReviewPersonalDetailShareholderAddressesStepForm,
    initialData?.address,
  ]);

  const handleCancel = (values) => {
    cancel();
  };

  const handleSubmit = async (values) => {
    next(values);
  };

  useEffect(() => {
    dispatch(
      addAssistantText(
        translate("MODULES.RENEWALS_ADGM.REVIEW_ADDRESS.SPV_TOOLTIP_1"),
        "alert"
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const schema = Yup.object().shape({
    address: Yup.object().shape({
      country: Yup.string().required("This field is required").nullable(),
      street: Yup.string().required("This field is required").nullable(),
      city: Yup.string().required("This field is required").nullable(),
      state: Yup.string().nullable(),
      zipCode: Yup.string().nullable(),
    }),
  });

  const handlePrev = () => {
    prev();
  };

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: "Review and confirm their addresses",
          subTitle: `Review details for ${initialData.stakeholder.fullName}`,
        }}
        buttons={{
          submit: (
            <SubmitButton>{translate("MODULES.CLARA.BTN_NEXT")}</SubmitButton>
          ),
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("MODULES.CLARA.BTN_CANCEL")}
            </Button>
          ),
          previous: (
            <Button onClick={handlePrev} variant="secondary">
              Previous
            </Button>
          ),
        }}
      >
        <AddressInput
          name="address"
          label={{ country: "Residential address" }}
          stateOptional
          zipCodeOptional
          isRequired
        />
        <div />
        <div>&nbsp;</div>
      </TemplateWizard>
    </Form>
  );
}

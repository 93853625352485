import { useMemo } from 'react';
import useBusinessRules from 'src/modules/clara/hooks/useBusinessRules';
import useSession from 'src/modules/session/hooks/useSession';
import { Constants } from 'src/v1/utils/constants';

const useGuards = () => {
  const { isOwner } = useBusinessRules();
  const { session } = useSession();

  return useMemo(() => ({
    isTechLicence: (context) => {
      return context?.initialData?.company?.licenceType === Constants.MODULES.FORMATION.COMPANIES_TYPE.ADGM_TSL;
    },
    isHoldingCompany: (context) => {
      return context?.initialData?.company?.licenceType === Constants.MODULES.FORMATION.COMPANIES_TYPE.ADGM_SPV;
    },

  }), [isOwner, session]);
}

export default useGuards;

import React, { useRef } from 'react';
import axios, { AxiosRequestConfig } from 'axios'
import Cookies from 'universal-cookie';
import { getURLApi } from '../../../utils/urlHelper';
import useSession from '../../session/hooks/useSession';
import _ from "lodash"
interface PropsFetch<T = any> {
    url: string;
    options?: AxiosRequestConfig;
    defaultResponse?: T;
    onCompleted?(data: T): void;
    cancelAutomaticFetch?: boolean;
}


const defaultHeader = {
    Authorization: "",
    Accept: "application/json",
    'Content-Type': "application/json"
}

const defaultOptionsAxios: AxiosRequestConfig = {
    method: "GET",
    baseURL: getURLApi()
}



export default function useFetch<T extends Object>({ url, options, defaultResponse, onCompleted, cancelAutomaticFetch = false }: PropsFetch<T>) {
    const [response, setResponse] = React.useState(defaultResponse);
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const { session } = useSession()



    const [headers, setHeaders] = React.useState(() => {
        const Authorization = _.get(session, "token");
        return {
            Authorization,
            Accept: "application/json",
            'Content-Type': "application/json",
        }
    });



    const fetchData = React.useCallback(
        async (localOptions) => {

            setLoading(true);
            try {
                const { data } = await axios(url, { headers, ...defaultOptionsAxios, ...options, ...localOptions });

                setResponse(data.response);
                setLoading(false);
                onCompleted && onCompleted(data.response);
                return data
            } catch (error) {

                setResponse(defaultResponse)
                setError(error);
                setLoading(false);
                return null
            }
        }, [url]);
    const post = React.useCallback(
        async (body = {}, localOptions = {}) => {

            setLoading(true);
            try {
                const { data } = await axios.post(url, body, { headers, ...defaultOptionsAxios, ...options, ...localOptions, body });

                setResponse(data.response);
                setLoading(false);
                onCompleted && onCompleted(data.response);
                return data
            } catch (error) {

                setResponse(defaultResponse)
                setError(error);
                setLoading(false);
                return null
            }
        }, [url]);
    React.useEffect(() => {
        if (!cancelAutomaticFetch) fetchData({});
    }, [fetchData, cancelAutomaticFetch]);

    return { response, error, loading: loading, refetch: fetchData,post };
};

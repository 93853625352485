import React, { FC } from "react";
import AssistanceContents from "../../../AssistanceContents";
import TabBubblesContainer from "../../../TabBubblesContainer";
import ProfileTooltips from "../ProfileTooltips";

export interface StartupProfileTasksProps {

}

const StartupProfileTasks: FC<StartupProfileTasksProps> = ({
  ...props
}: StartupProfileTasksProps) => {

  return (
    <AssistanceContents>
      <TabBubblesContainer>
        <ProfileTooltips />        
      </TabBubblesContainer>
    </AssistanceContents>
  );
};

export default StartupProfileTasks;
import { useMemo } from 'react';
import _ from "lodash";
import useSession from 'src/modules/session/hooks/useSession';
import useUpsertFormationCompany from "../../../../GroupCompany/graphql/mutations/useUpsertFormationCompany";
import useUploadBulkDocuments
  from "../../../../../clara/components/Accelerator/ConstitutionalDocuments/hooks/useUploadBulkDocuments";

const useServices = ({initialValues}) => {
  const [upsertFormationCompany] = useUpsertFormationCompany({}, {});
  const { mutation: uploadDocuments } = useUploadBulkDocuments();
  const { startupId } =  useSession();

  return useMemo(() => ({
    initialData: async () => {
      return initialValues;
    },
    SaveStep: async (context) => {
      const groupCompanyId = initialValues?.idGroupCompany;
      const isBusinessPlanPreApproved = _.get(context, "BusinessPlanStep.preApproved", '');
      const uploadNow = _.get(context, "LetsPreApproveFormStep.uploadNow", '');
      const PreApprovedUploadDocumentsFormStepFiles = _.get(context, "PreApprovedUploadDocumentsFormStep", {});
      const AlreadyApprovedUploadDocumentsFormStepFiles = _.get(context, "AlreadyApprovedUploadDocumentsFormStep", {});
      let documentsResult;

      if(!isBusinessPlanPreApproved && uploadNow === 'now') {
        const businessTemplate = PreApprovedUploadDocumentsFormStepFiles.businessTemplate;
        const pitchDeck = PreApprovedUploadDocumentsFormStepFiles.pitchDeck;

        const businessTemplateAgreement = {
          title: businessTemplate.name,
          file: businessTemplate,
          type: 'ADGM_TSL_BUSINESS_PLAN_TEMPLATE_DOCUMENT',
          parties: [],
          status: 'Uploaded',
          subCategories: ['constitutionalDocuments'],
          groupCompanies: [{groupCompany: groupCompanyId}]
        };

        const pitchDeckAgreement = {
          title: pitchDeck.name,
          file: pitchDeck,
          type: 'ADGM_TSL_PITCH_DECK',
          parties: [],
          status: 'Uploaded',
          subCategories: ['constitutionalDocuments'],
          groupCompanies: [{groupCompany: groupCompanyId}]
        };

        const documents = [businessTemplateAgreement, pitchDeckAgreement];
        documentsResult = await uploadDocuments({ variables: { startupId, documents } });
      } else if (isBusinessPlanPreApproved) {
        const businessTemplate = AlreadyApprovedUploadDocumentsFormStepFiles.businessTemplate;
        const confirmationEmail = AlreadyApprovedUploadDocumentsFormStepFiles.confirmationEmail;
        const pitchDeck = AlreadyApprovedUploadDocumentsFormStepFiles.pitchDeck;

        const businessTemplateAgreement = {
          title: businessTemplate.name,
          file: businessTemplate,
          type: 'ADGM_TSL_BUSINESS_PLAN_TEMPLATE_DOCUMENT',
          parties: [],
          status: 'Uploaded',
          subCategories: ['constitutionalDocuments'],
          groupCompanies: [{groupCompany: groupCompanyId}]
        };

        const confirmationEmailAgreement = {
          title: confirmationEmail.name,
          file: confirmationEmail,
          type: 'ADGM_TSL_CONFIRMATION_EMAIL',
          parties: [],
          status: 'Uploaded',
          subCategories: ['constitutionalDocuments'],
          groupCompanies: [{groupCompany: groupCompanyId}]
        };

        const pitchDeckAgreement = {
          title: pitchDeck.name,
          file: pitchDeck,
          type: 'ADGM_TSL_PITCH_DECK',
          parties: [],
          status: 'Uploaded',
          subCategories: ['constitutionalDocuments'],
          groupCompanies: [{groupCompany: groupCompanyId}]
        };

        const documents = [businessTemplateAgreement, confirmationEmailAgreement, pitchDeckAgreement];
        documentsResult = await uploadDocuments({ variables: { startupId, documents } });
      }

      const formattedDocuments = _.map(documentsResult?.data?.uploadBulkDocuments, document => {
        return document.id;
      });

      const formationData = {
        businessPlan: {
          isBusinessPlanPreApproved,
          documents: formattedDocuments
        }
      };

      return await upsertFormationCompany({ variables: { startupId, groupCompanyId, groupCompanyData: { formationData } } });
    },
  }), [initialValues]);
}

export default useServices;

import { useMemo } from 'react';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import ReviewUboAddress from '../steps/ReviewUboAddressFormStep';
import ReviewDetailsUBO from '../steps/ReviewUboDetailsFormStep';
import ReviewUboPEP from '../steps/ReviewUboPepFormStep';
import ReviewUboSof from '../steps/ReviewUboSofFormStep';

const useSteps = () => {
  return useMemo(() => ({
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    ReviewDetailsUBO: {
      render: ReviewDetailsUBO,
    },
    ReviewUboAddress: {
      render: ReviewUboAddress,
    },
    ReviewUboPEP: {
      render: ReviewUboPEP,
    },
    ReviewUboSof: {
      render: ReviewUboSof,
    },
    SaveStep: {
      render: TemplateWizardSkeletonMachine,
    },
  }), []);
}

export default useSteps;
import classnames from "classnames";
import React, { FC } from "react";
import classes from "./classes.module.scss";

/**
 * Assistance Block Expanded Help
 */

export interface AssistanceBlockProps {
  /** Property children */
  children: React.ComponentType,
  /** Property addSpace */
  addSpace: boolean
}

const AssistanceBlock: FC<AssistanceBlockProps> = ({
  children,
  addSpace = true,
  ...props
} : AssistanceBlockProps) => {

  return (
    <React.Fragment>
      <div className={classnames(classes.blockSpace)}>
        <div
          className={classnames(`${classes.assistanceBlock}`, {
            [classes.addPadding]: addSpace,
          })}
        >
          {children}
        </div>
      </div>
    </React.Fragment>
  );

};

export default AssistanceBlock;

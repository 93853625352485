import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import AddressInput from "src/components/forms/modules/startups/individualStakeholder/AddressInput";
import CountrySelector from "src/components/inputs/Select/CountrySelector/CountrySelector";
import SelectMailingAddressTypeInput from 'src/components/inputs/Select/MultiSelectInput';
import SelectUltimateBeneficialOwnershipTypeInput from 'src/components/inputs/Select/SelectUltimateBeneficialOwnershipTypeInput';
import TextInput from 'src/components/inputs/Text/TextInput';
import Button from 'src/modules/generic/components/Buttons/Button';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateLabel from 'src/modules/generic/components/Inputs/LabelInput';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText } from 'src/modules/generic/store/action';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import * as Yup from 'yup';

function EditFormStep() {
  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    console.log("**** initialValues", context?.initialData?.ultimateBeneficialOwner)
    return context?.initialData?.ultimateBeneficialOwner ?? {}
  }, [context]);

  const handleCancel = (values) => {
    cancel();
  }

  const handleSubmit = async (values) => {
    next(values)
  }

  useEffect(() => {
    return () => {
      dispatch(clearAssistatText())
    }
  }, [dispatch])

  const schema = Yup.object().shape({
    name: Yup.string().required('This field is required'),
    type: Yup.string().required('This field is required').typeError('This field is required'),
    mailingAddress: Yup.object().shape({
      country: Yup.string().required('This field is required').typeError('This field is required'),
      street: Yup.string().required('This field is required'),
      city: Yup.string().required('This field is required'),
      state: Yup.string().nullable(),
      zipCode: Yup.string().nullable(),
    }),
    taxRegistrationNumber: Yup.string().nullable().optional(),
    taxJurisdiction: Yup.string().required('This field is required').typeError('This field is required'),
  });



 

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: translate('MODULES.RENEWALS.BENEFICIAL_OWNERS.GENERIC.TITLE3'),
          skeleton: null,
          variant: 'simple'
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('BUTTON_SAVE_CHANGES')}</SubmitButton>,
        }}
      >
        <>
          <TemplateLabel label={translate('MODULES.RENEWALS.BENEFICIAL_OWNERS.ADD_FORM.LABEL1')} isRequired>
            <ControllerInput
              render={TextInput}
              name='name'
              placeholder={'Type here'}
            />
          </TemplateLabel>
          <TemplateLabel label={translate('MODULES.RENEWALS.BENEFICIAL_OWNERS.ADD_FORM.LABEL6')} isRequired>
            <ControllerInput
              render={SelectUltimateBeneficialOwnershipTypeInput}
              name='type'
              placeholder={translate("SELECT_INPUT_PLACEHOLDER")}
              
            />
          </TemplateLabel>
          <TemplateLabel label={translate('MODULES.RENEWALS.BENEFICIAL_OWNERS.ADD_FORM.LABEL2')} isRequired>
            <ControllerInput
              render={SelectMailingAddressTypeInput}
              name="mailingAddress.type"

            />
          </TemplateLabel>
          <AddressInput
            name="mailingAddress"
            label={{ country: translate('MODULES.RENEWALS.BENEFICIAL_OWNERS.ADD_FORM.LABEL3') }}
            zipCodeOptional
            isRequired
            onChange={() => { }}
          />


          <TemplateLabel label={translate('MODULES.RENEWALS.BENEFICIAL_OWNERS.ADD_FORM.LABEL4')} isRequired>
            <ControllerInput
              render={CountrySelector}
              placeholder={translate("SELECT_INPUT_PLACEHOLDER")}
              name="taxJurisdiction"
            />
          </TemplateLabel>
          <TemplateLabel label={translate('MODULES.RENEWALS.BENEFICIAL_OWNERS.ADD_FORM.LABEL5')}>
            <ControllerInput
              render={TextInput}
              name='taxRegistrationNumber'
              placeholder={'Type here'}
            />
          </TemplateLabel>
        </>
        <div>&nbsp;</div>
      </TemplateWizard>
    </Form>
  );
}

export default EditFormStep;


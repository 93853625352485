import React, {Component} from "react";

import classes from "./SearchStartUp.module.scss"

import {Avatar, ClaraSearch, DinamicBotonera, RoleAccess} from "../../index"
import {Constants} from '../../../utils/constants';

/*
    Properties:
        callbacks:{ functions to execute in callback
            close: execute when close modal
            selectStartUp (startup): select new startup
            addStartUp: class this method to open add modal
        }
        startups: startup's list
        startup: current startUp
*/
class SearchStartUp extends Component {
    state = {
        startup:null,
        filter:undefined,
        heightListStartup:undefined
    };
    selectStartUp=(current)=>{
        this.setState({
            startup:current
        })
    }
    openStartUp=(current)=>{
        this.props.callbacks.selectStartUp(this.state.startup);
    }
    closeStartUp=()=>{
        this.props.callbacks.close();
    }
    addStartUp=()=>{
        this.props.callbacks.addStartUp();
    }
    onChange = (value) => {
        this.setState({
            filter:value
        });
    }
    getCustomStyle = () => {
        let {heightListStartup} = this.state;
        if (heightListStartup){
            return {height:heightListStartup+"px"};
        }
    }
    componentJSX_startUps=(startups)=>{
        let {startup,filter} = this.state;
        if (startups){
            let startupsFilter =startups;
            if (filter && filter!==""){
                startupsFilter = startups.filter( (startup) =>{
                    return startup.name.toLowerCase().indexOf(filter.toLowerCase())>=0
                });
            }
            
            return startupsFilter.map((current,i)=>{
                return (
                    <a key={i} onClick={(e)=>{this.selectStartUp(current)}}> 
                        <Avatar avatar={current.avatar} name={current.name}></Avatar>
                        {current.id===startup.id?<div className={classes.selected}/>:null}
                    </a>
                )
            })
        }
    }
    componentWillMount(){
        this.setState({
            startup:this.props.startup
        })
    }
    componentWillUpdate(nextProps){
        if (nextProps.startup!==this.props.startup){
            this.setState({
                startup:nextProps.startup
            })
        }
        
    }
    componentDidMount() {
        const heightListStartup = this.divListStartups.clientHeight;
        this.setState({ heightListStartup });
      }
    render() {
        let {startups,startup} = this.props;
        let buttons=[
            {label: "Open", onClick: this.openStartUp, type:"next"},
            {label: "Cancel", onClick: this.closeStartUp}
        ]
        return (
            <div className={classes.SearchStartUp}>
                <div className={classes.ZoneSearchStartuo}>
                    <ClaraSearch placeHolder={`Search for a Startup...`} onChange={(this.onChange)}></ClaraSearch>
                </div>
                <RoleAccess action={Constants.ACTIONS.ADD_STARTUP}>
                    <div className={classes.AddStartUp}>
                        <a className={`maskBefore`} onClick={this.addStartUp}><span>Add New Startup</span></a>
                    </div>
                </RoleAccess>
                <div className={classes.ListStartUps} style={this.getCustomStyle()} ref={ (divListStartups) => this.divListStartups = divListStartups}>
                    {this.componentJSX_startUps(startups,startup)}
                </div>
                <DinamicBotonera buttons={buttons}></DinamicBotonera>
            </div>
        );

    }
}


export default SearchStartUp

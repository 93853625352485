import Skeleton from '@material-ui/lab/Skeleton';
import React from "react";
import Typography from "../../../components/Texts/Typography";
import classes from './classes.module.scss';

const TemplateSkeletonDelete = ({className}) => {
    return (
        <>
            <div className={`${classes.container} ${className}`}>
                <div className={classes.header}>
                    <Typography component={'h1'} color={'black'}>
                    <Skeleton variant="text" height={"4rem"} width={"50rem"}/>
                    </Typography>
                </div>
                <Skeleton variant="text" height={"2rem"} width={"30%"}/>
            </div>
        </>
    )
}
export default TemplateSkeletonDelete
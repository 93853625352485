
import useLazyQuery from "src/hooks/custom/useLazyQuery";


export const GET_STAKEHOLDER = `
query getStakeholderDetail($startupId: ID!, $stakeholderId: ID!) {
  getStakeholder(startupId: $startupId, stakeholderId: $stakeholderId) {
    fullName
    nationality {
      name
      code
    }
    jurisdiction
    registeredNumber
    taxRegistrationNumber
    previousName
    address {
      street
      city
      state
      country {name code alpha3}
      zipCode
    }
    documents {
      name
		  id
		  kind
		  status
		  type{
        label
        code
		  }
    }
    isSeparateLegalEntity
  }
}`;

export const GET_STAKEHOLDER_TASKCOUNT = `
query getStakeholderDetail($startupId: ID!, $stakeholderId: ID!) {
  stakeholder: getStakeholder(startupId: $startupId, stakeholderId: $stakeholderId) {
    id
    pendingTasksCount
  }
}`;
export const GET_STAKEHOLDER_SOURCEOFFOUND = `
query getStakeholderDetail($startupId: ID!, $stakeholderId: ID!) {
  stakeholder: getStakeholder(startupId: $startupId, stakeholderId: $stakeholderId) {
    id
    sourceOfFunds {
      company
      jurisdictionType
      sourceOfFunds
      sourceOfWealth
      sourceOfFundsYesNo
    }
  }
}`;



// New getStakeholders lazy query, need review is correct
const useGetStakeholderLazy = ({ variables, query = GET_STAKEHOLDER, ...props }, config) => {
  const { manualQuery, loading, error, data, refetch } = useLazyQuery(
    query,
    { ...variables, ...(config?.isADGM ? { filters: { forADGM: true } } : {}) },
    config,
    props
  );
  return {
    loading,
    error,
    data,
    refetch,
    manualQuery
  };
};

export default useGetStakeholderLazy;

import moment from "moment";
import ServerConnect from '../../v1/utils/ServerConnect';

export const actions = {
    SET_MESSAGE: 'SET_MESSAGE',
    SET_REQUESTED_MESSAGE: 'SET_REQUESTED_MESSAGE',
    NEXT_MESSAGE: 'NEXT_MESSAGE',
    SET_CURRENT_STATE: 'SET_CURRENT_STATE',
    SET_ANSWER_BY: 'SET_ANSWER_BY',
    SET_AUTOMATIC: 'SET_AUTOMATIC',
    CLEAR_MESSAGES: "CLEAR_MESSAGES",
    INITIAL_FLOW: "INITIAL_FLOW",
    SET_RESTART_FLOW: "SET_RESTART_FLOW"
};
const errorMessage = "We are experiencing temporary delays, please try again later.";
export const nextMessage = (dispatch, currentState, answer, email,lastQuestion) => {
    dispatch({ type: actions.NEXT_MESSAGE, messageFetcher: () => fetchMessages(dispatch, currentState, answer, email,lastQuestion) })
};

export const setInitialFlow = (dispatch) =>{
    dispatch({ type: actions.INITIAL_FLOW });
}
export const clearMessageChat = (dispatch) =>{
    dispatch({ type: actions.CLEAR_MESSAGES });
}
export const setAutomatic = (dispatch, value) => {
    dispatch({ type: actions.SET_AUTOMATIC, value })
};
export const restartFlow = (dispatch, value) => {
    dispatch({ type: 'SET_RESTART_FLOW', restart:value })
};

var index = 0;
var steps = [
    {
        "data": {
            "nextQuestion": {
                "id": "WelcomeToClara_Step1",
                "bot_message": {
                    "text": "Welcome Fiona. Do you have a company that set up for investment from Techstars",
                    "type": "OPTIONS",
                    "inputConfig": {
                        "options": [
                            { "id": "YES", "text": "YES" },
                            { "id": "NO", "text": "NO" },
                        ]
                    }
                },
                "answerBy": "Step 2"
            }
        }
    },
    {
        "data": {
            "nextQuestion": {
                "id": "Step 2",
                "bot_message": {
                    "id":"company",
                    "text": "What is your company’s name?",
                    "type": "TEXT",
                    "tellme_more": "{{#bold}}What is a “Company’s name”?{{/bold}} This is the legal name of your company. You can find it written in your constitutional documents. {{#bold}}What are constitutional documents?{{/bold}} Certificate of incorporation, commercial licence, memorandum and/or articles of association or similar documents you would have received when you first incorporated your company",
                    "inputConfig": {
                        "regex": "^[0-9a-zA-Z]+(?:[0-9a-zA-Z\\ \\- \\.]+)*$",
                        "errorMessage": "error message",
                        "placeholder": "Type your company’s name",
                        "allowNullResponse":false
                    }
                },
                "answerBy": "Step 3"
            }
        }
    },
    {
        "data": {
            "nextQuestion": {
                "id": "Step 3",
                "bot_message": {
                    "id":"number",
                    "text": "What is your company’s number?",
                    "type": "TEXT",
                    "inputConfig": {
                        "regex": "^[0-9a-zA-Z]+(?:[0-9a-zA-Z\\ \\- \\.]+)*$",
                        "errorMessage": "error message",
                        "placeholder": "Type your company’s number",
                        "allowNullResponse":true
                    }
                },
                "answerBy": "Step 4"
            }
        }
    },
    {
        "data": {
            "nextQuestion": {
                "id": "Step 4",
                "bot_message": {
                    "id":"address",
                    "text": "What is your company’s Mailing Address?",
                    "type": "TEXT",
                    "inputConfig": {
                        "regex": "^[0-9a-zA-Z]+(?:[0-9a-zA-Z\\ \\- \\.]+)*$",
                        "errorMessage": "error message",
                        "placeholder": "Type your company’s mailing address",
                        "allowNullResponse":true
                    }
                },
                "answerBy": "Step 5"
            }
        }
    },
    {
        "data": {
            "nextQuestion": {
                "id": "Step 5",
                "bot_message": {
                    "text": "Excellent. Please review your information below. If everything is correct, click confirm. If there is an error, please click the appropriate “update” button.",
                    "type": "SUMMARY",
                    "inputConfig": {
                        "values": [
                            {
                                "value": "Acme Holdings Limited",
                                "label": "Company Name",
                                "id": "edit_company_name"
                            },
                            {
                                "value": null,
                                "label": "Company number",
                                "id": "edit_company_number"
                            },
                            {
                                "value": "123 Undefined Street, Anywhere, AB, USA",
                                "label": "Company Mailing Address",
                                "id": "edit_company_mailing_address"
                            }
                        ]
                    }
                },
                "answerBy": "Step 6"
            }
        }

    },
    {
        "data": {
            "nextQuestion": {
                "id": "WelcomeToClara_Step1",
                "bot_message": {
                    "text": "Please upload all constitutional documents for this company",
                    "type": "FILE_UPLOAD",
                    "inputConfig": {
                        
                    }
                },
                "answerBy": "Step 2"
            }
        }
    },
    {
        "data": {
            "nextQuestion": {
                "id": "WelcomeToClara_Step1",
                "bot_message": {
                    "text": "Finish the flow",
                    "type": "STOP_FLOW",
                    "inputConfig": {
                        
                    }
                },
                "answerBy": "Step 2"
            }
        }
    },
]
var test = false;
function testFunction(dispatch, currentState, answer, email = '',lastQuestion){
    let queryMutation2 = ` mutation nextQuestion($response: ChatUserResponse!){
        nextQuestion(response:$response){
        id
        bot_message{
            id
            text
            type
        }
        }
        
    }`;
    let variables2 = {
        "response": {
            "state": 'InitialFlow_MandatoryState',
            "answer": { event: 'WelcomeToClara', data: {} },
        }
    }
    console.log("Variables: ",{
        "response": {
            "state": currentState,
            "answer": answer
        }
    })
    ServerConnect.graphQlMutation(queryMutation2, variables2).then((res2) => {
        if (index == 0) {
            dispatch({ type: actions.CLEAR_MESSAGES });
        }
        let res = steps[index].data.nextQuestion;
        
        index++;
        const messages = [{ ...res.bot_message, answerBy: res.answerBy }];
    
        dispatch({ type: actions.SET_CURRENT_STATE, newState: res.id });
        dispatch({ type: actions.SET_ANSWER_BY, answerBy: res.answerBy });
        dispatch({ type: actions.SET_REQUESTED_MESSAGE, requestedMessages: messages })
        
    }).catch((e)=>{
        
        sendMessage({text: errorMessage }, lastQuestion,  answer,currentState, null,false,dispatch);
        if (lastQuestion){
            const messages = [{ ...lastQuestion, answerBy: lastQuestion.answerBy }];
            dispatch({ type: actions.SET_CURRENT_STATE, newState: currentState });
            dispatch({ type: actions.SET_ANSWER_BY, answerBy: lastQuestion.answerBy });
            dispatch({ type: actions.SET_REQUESTED_MESSAGE, requestedMessages: messages })
        }
    });

    return;
}
export const sendMessage = (message, lastQuestion,answer, currentState, email, sent, dispatch) => {

    dispatch({
        type: actions.SET_MESSAGE,
        answer,
        message: { ...message, time: moment(), sent: sent, type: message.type  }
    });
    if (sent) {
        setTimeout(() => {
            nextMessage(dispatch, currentState, answer, email,lastQuestion)
        }, 500);
    }
};

export const fetchMessages = (dispatch, currentState, answer, email = '',lastQuestion) => {
    
    if (test){
        return testFunction(dispatch, currentState, answer, email,lastQuestion );
    }
    
    let queryMutation = `mutation nextQuestion($response: ChatUserResponse!){
        nextQuestion(response:$response){
          id
          bot_message{
            id
            text
            type
            inputConfig
            tellme_more
          }
          answerBy
        }
        
      }`;
    let variables = {
        "response": {
            "state": currentState,
            "answer": answer
        }
    }


    ServerConnect.graphQlMutation(queryMutation, variables).then((response) => {
        let res = response.nextQuestion;
        const messages = [{ ...res.bot_message, answerBy: res.answerBy }];
    
        dispatch({ type: actions.SET_CURRENT_STATE, newState: res.id });
        dispatch({ type: actions.SET_ANSWER_BY, answerBy: res.answerBy });
        dispatch({ type: actions.SET_REQUESTED_MESSAGE, requestedMessages: messages })

    }).catch((e)=>{
        sendMessage({text: errorMessage }, lastQuestion,  answer,currentState, null,false,dispatch);
        if (lastQuestion){
            const messages = [{ ...lastQuestion, answerBy: lastQuestion.answerBy }];
            dispatch({ type: actions.SET_CURRENT_STATE, newState: currentState });
            dispatch({ type: actions.SET_ANSWER_BY, answerBy: lastQuestion.answerBy });
            dispatch({ type: actions.SET_REQUESTED_MESSAGE, requestedMessages: messages })
        }
    });



};

export const generateAnswer = (answer, question, prevAnswer, answerBy) => {
    switch (question.type) {
        case 'OPTIONS':
            return { event: question.inputConfig.options.find(o => o.text === answer.text).id, data:{} };
        case 'RADIOBUTTON':
            let data = {};
            data[question.dataProp] = answer.id;
            return { event: answerBy, data: data };
        case 'TEXT':
        case 'PASSWORD':
            return { event: answerBy, data: { ...prevAnswer.data, [question.id]: answer.value } };
        case 'FILE_UPLOAD':
            return { event: answerBy, data: { fileId: answer.fileId } }
        case 'SUMMARY':
            return { event: answer.id, data: {} }
        default:
            return {};
    }
};

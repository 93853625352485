import {actions as actionTypes} from "../actions/chatbot";
import moment from "moment";
import {Constants} from '../../v1/utils/constants';

const initialState = {
    restartFlow: false,
    messages: [],
    sendedData: {},
    answer: { event: 'CreateAStartupProfile', data: {} },
    requestedMessages: [],
    currentState: 'InitialFlow_MandatoryState',
    automaticResponse: false
};

export default (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.SET_RESTART_FLOW:
            return {
                ...state,
                restartFlow: action.restart
            };
        case actionTypes.INITIAL_FLOW:
            return {
                ...state,
                answer: { event: 'CreateAStartupProfile', data: {} },
                currentState: 'InitialFlow_MandatoryState',
            };
        case actionTypes.CLEAR_MESSAGES:
            return {
                ...state,
                messages: []
            };
        case actionTypes.SET_MESSAGE:
            const answerData = { ...state.answer.data, ...action.answer.data };
            const answer = { ...action.answer, data: answerData };
            return {
                ...state,
                sendedData: { ...state.sendedData, ...answerData },
                answer: { ...state.answer, ...answer },
                messages: [...state.messages, action.message]
            };
        case actionTypes.SET_REQUESTED_MESSAGE:
            const newMessage = action.requestedMessages.shift();
            return {
                ...state,
                lastQuestion: newMessage,
                automaticResponse: newMessage.type === 'AUTOMATIC' || newMessage.type === Constants.CHAT_COMPONENTS_TYPE.LEARN_MORE,
                answer: { ...state.answer, data: {} },
                messages: [...state.messages, { ...newMessage, time: moment() }],
                requestedMessages: [...state.requestedMessages, ...action.requestedMessages]
            };
        case actionTypes.NEXT_MESSAGE:
            if (state.requestedMessages.length === 0) action.messageFetcher();
            else {
                let newMessage = state.requestedMessages.shift();
                return {
                    ...state,
                    automaticResponse: newMessage.type === 'AUTOMATIC' || newMessage.type === Constants.CHAT_COMPONENTS_TYPE.LEARN_MORE,
                    lastQuestion: newMessage,
                    messages: [...state.messages, newMessage]
                }
            }
            return state;
        case actionTypes.SET_CURRENT_STATE:
            return { ...state, currentState: action.newState };
        case actionTypes.SET_ANSWER_BY:
            return { ...state, answerBy: action.answerBy };
        case actionTypes.SET_AUTOMATIC:
            return { ...state, automaticResponse: action.value };
        default:
            return state;
    }
};

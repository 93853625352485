import React from 'react';
import { Step } from '../index';
import classNames from 'classnames';
import cls from './Dots.module.scss'

interface DotsProps {

    actualStep: number | string;

    steps: Step[];

    setStep?(step?: any): void;

    className?: string
}


const Dots: React.FC<DotsProps> = ({ actualStep, steps, className, setStep = () => { } }) => {
    return (
        <div className={`${cls['container-step-dots']} ${className}`}>
            {steps.map((step: Step, index) => {
                // console.log("-----> Steps Dot2",step,actualStep,index)
                return <button type={"button"} onClick={() => setStep(index)} key={step.value} disabled={step.disabled || !step.viewed} className={classNames(cls['step-dot'], { [cls['step-dot-selected']]: actualStep === index })} />
            })}
        </div>
    )
}

export default Dots;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-shadow */
import { Box, CircularProgress, FormControl, FormLabel, InputAdornment, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Checkbox } from "@mui/material";
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Tag from 'src/components/generic/AutocompleteInput/components/AutocompleteTag';
import FormHelperError from "src/components/inputs/FormHelperError";
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

/**
/* The above code defines a React component called AutocompleteInput that provides an autocomplete
input field with various features such as fetching data from a server, handling multiple selections,
debouncing input changes, validation, and error handling.
*/
function AutocompleteInput({
  list: _list = [],
  label,
  errorMessage,
  variant,
  disabled,
  helperText = null,
  lazyQueryService: _lazyQueryService,
  filters,
  onChange,
  onChangeInput,
  autocompleProps = {},
  debounceTime = 1000,
  multiple = false,
  size,
  take = 20,
  value: _formValue,
  limitTags,
  validatorFunction,
  messageError,
  optional,
  filterSearchNameText,
  title,
  sx,
  ListComponent,
  defaultValues: _defaultValues,
  idFieldName,

  ...props
}) {
  const [service, lazyQueryService] = _lazyQueryService || [];
  const [list, setList] = useState(_list);
  const [query, setQuery] = useState(null);
  const [loading, setLoading] = useState(false);
  const [firstSearchComplete, setFirstSearchComplete] = useState(false);
  const [continueSearching, setContinueSearching] = useState(false);
  let defaultValues = _defaultValues;

  if (multiple && !defaultValues) {
    defaultValues = [];
  } else if (!multiple && !defaultValues) {
    defaultValues = null;
  }
  const [autoCompleteValue, setAutoCompleteValue] = useState(defaultValues);
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(false);
  const [selectedElement, setSelectedElement] = useState({});
  /**
   * The `changeHandler` function updates the state with the value from the event target.
   */
  const changeHandler = (e) => {
    const valueEvent = _.get(e, 'target.value');
    setQuery(valueEvent);
  };

  // const debouncedChangeHandler = useCallback(_.debounce(changeHandler, debounceTime), [changeHandler, debounceTime]);
  // const handleChangeInput = useCallback((event, newInputValue) => {
  //   debouncedChangeHandler(event, newInputValue);
  //   setInputValue(newInputValue);
  //   if (onChangeInput) {
  //     onChangeInput(newInputValue);
  //   }
  // },[debouncedChangeHandler, setInputValue, onChangeInput]);

  const handleChangeInput = useCallback((event, newInputValue) => {
    changeHandler(event);
    setInputValue(newInputValue);
    if (onChangeInput) {
      onChangeInput(newInputValue);
    }
  },[setInputValue, onChangeInput]);

  const handleChange = useCallback(
    (e, newValue) => {
      if (!multiple) {
        setAutoCompleteValue(newValue);
        if (onChange) {
          onChange(newValue?.id, selectedElement[newValue?.id] ?? newValue);
        }
      } else {
        const cloneData = _.cloneDeep(newValue);
        setSelectedElement((prevData) => {
          let result = {};
          _.forEach(cloneData, (data) => {
            result = { ...result, [data?.id]: data };
          });
          return { ...prevData, ...result };
        });
        const ids = _.map(newValue, (v) => v?.id ?? v?.value ?? v);
        setAutoCompleteValue(ids);
        if (onChange) {
          onChange(ids);
        }
      }
      if (validatorFunction) {
        const filteredValues = newValue.filter((option) => validatorFunction(option));
        setError(filteredValues.length !== newValue.length);
      }
    },
    [multiple, validatorFunction, onChange]
  );

  /**
   * The setFirstData
   */
  const setFirstData = async (_filters) => {
    // TODO: REFACTOR THIS, IT IS HARD TO UNDERSTAND AND IT NEEDS TO BE SIMPLIFIED
    // AND THE LOGIC NEEDS TO BE EXPLAINED
    let fv = _formValue || defaultValues;
    if (!_.isEmpty(fv) && lazyQueryService && _.isEmpty(selectedElement)) {
      if (typeof fv === 'string') {
        fv = [fv];
      }
      const idFieldValue = fv?.holderId ? fv.holderId : fv;
      const queryParams2 = {
        filters: { [idFieldName]: idFieldValue, ..._filters },
        pagination: { offset: 0, limit: _.size(fv) }
      };
      const result = await service(queryParams2);
      if (!multiple) {
        const finedDefaultValueInList = !firstSearchComplete && fv && !_.isEmpty(result);
        if (finedDefaultValueInList) {
          handleChange(null, result?.data[0]);
        }
      } else {
        handleChange(null, fv);
      }
      setSelectedElement((prevData) => {
        let newValues = {};
        _.forEach(result?.data ?? [], (data) => {
          newValues = { ...newValues, [data?.id]: data };
        });
        return { ...prevData, ...newValues };
      });
    }
  };
  const getData = useCallback(
    async ({ filters: _filters, limit: _limit, offset: _offset }) => {
      setLoading(true);
      setHasMoreData(true);
      if (lazyQueryService) {
        try {
          const queryParams = {
            filters: _filters,
            pagination: { offset: _offset, limit: _limit }
          };
          setFirstData(_filters);
          if (_offset === 0) {
            await service(queryParams);
            if (!firstSearchComplete) {
              setFirstSearchComplete(true);
            }
          } else {
            await lazyQueryService?.fetchMore(queryParams);
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error fetching data', error);
        }
      }
      setLoading(false);
    },
    [lazyQueryService, service]
  );

  useEffect(() => {
    if (lazyQueryService && query !== 0) {
      const newFilters = { ...filters, [filterSearchNameText]: query };
      const variables = {
        filters: newFilters,
        limit: take,
        offset: 0
      };
      getData(variables);
    }
  }, [take, query, _formValue]);

  useEffect(() => {
    if (lazyQueryService) {
      const resultList = lazyQueryService?.data ?? [];
      if (resultList?.data) {
        const finalResult = resultList?.data;
        const searchItems = finalResult || [];
        setList(searchItems);
        setContinueSearching(true);
      }
      const hasMoreData = resultList?.pageInfo?.nextPage !== null;
      setHasMoreData(hasMoreData);
    }
  }, [lazyQueryService]);

  useEffect(() => {
    if (!lazyQueryService) {
      setList(_list);
      setContinueSearching(true);
    }
  }, [_list]);

  const sxError = useMemo(() => {
    if (errorMessage) {
      return {
        fieldset: {
          borderColor: (theme) => `${theme.palette.error.main} !important`
        }
      };
    }
    return {};
  }, [errorMessage]);
  return (
    <>
      {firstSearchComplete && _.isEmpty(list) && !continueSearching ? null : (
        <>
          {title && (
            <Typography color={"#828C98"} sx={{ marginTop: 2 }} variant="body1">
              {title}
            </Typography>
          )}
          <Box sx={{ paddingTop: title ? '4px' : '0px',  width: '100% !important' }}>
            <FormControl
              sx={{
                width: '100%',
                ...sx,
                ...sxError
              }}
              error={!!errorMessage}
            >
              {label && <FormLabel>{label}</FormLabel>}
              <Autocomplete
                sx={{
                  '& .MuiAutocomplete-tag': {
                    marginTop: '1 !important',
                    marginBottom: '1 !important',
                    marginLeft: '0 !important',
                    marginRight: '2px !important'
                  },
                  '& .MuiInputBase-root': {
                    paddingTop: '0px !important',
                    paddingBottom: '0px !important',
                    paddingRight: '65px !important',
                    borderRadius: '0.3846rem !important',
                    paddingLeft: '12px !important',
                  }
                }}
                clearText="Clear"
                openText="Open"
                closeText="Close"
                ListboxComponent={ListComponent}
                PaperComponent={(props) => (
                  <Paper
                    sx={{
                      padding: '6px !important',
                      boxShadow: '0px 2px 9px -3px rgba(0,0,0, 0.398819) !important',
                      border: "1px solid #eaf1fe !important",
                      borderRadius: '6px !important',
                      '& ul': {
                        padding: '0px !important'
                      },
                      '& .MuiAutocomplete-listbox .MuiAutocomplete-option': {
                        backgroundColor: 'transparent !important',
                        '&:hover': {
                          backgroundColor: "#eaf1fe !important",
                          borderRadius: '4px !important',
                          color: "#2c74ff"
                        }
                      }
                    }}
                    {...props}
                  />
                )}
                multiple={multiple}
                isOptionEqualToValue={(option, value) => option.id === value}
                disabled={disabled}
                options={list}
                getOptionLabel={(option) => {
                  const result = option ? option?.description : '';
                  return result;
                }}
                limitTags={limitTags}
                noOptionsText={!firstSearchComplete ? 'No result' : 'No options'}
                {...autocompleProps}
                onChange={handleChange}
                value={autoCompleteValue}
                inputValue={inputValue}
                onInputChange={handleChangeInput}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => {
                    const element = _.find(list, (l) => l.id === option);
                    const prefix = selectedElement[option]?.prefix || element?.prefix;
                    const title = selectedElement[option]?.description || element?.description;
                    return <Tag title={title} {...getTagProps({ index })} variant="filled" prefix={prefix} />;
                  })
                }
                disableCloseOnSelect={multiple}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    {multiple && (
                      <Checkbox
                        checked={selected}
                        sx={{
                          padding: '0px !important',
                          margin: '0px !important',
                          marginRight: '12px !important',
                          marginLeft: '-6px !important',
                          minHeight: '24px !important'
                        }}
                      />
                    )}
                    {option?.prefix && (
                      <Box
                        sx={{
                          paddingRight: '6px !important',
                        }}
                      >
                        {option?.prefix}
                      </Box>
                    )}
                    {option?.description}
                  </li>
                )}
                renderInput={(listRender) => (
                  <TextField
                    {...props}
                    {...listRender}
                    variant={variant}
                    label={null}
                    size={size}
                    sx={{ '& input': { padding: '2px !important', paddingRight: '7px !important' } }}
                    InputProps={{
                      ...listRender.InputProps,
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            marginLeft: 'auto !important',
                            '& .MuiAutocomplete-endAdornment': {
                              display: 'flex !important',
                              alignItems: 'center !important',
                              justifyContent: 'center !important',
                              right: '2px !important',
                              marginTop: '4px !important',
                              '& .MuiIconButton-root': {
                                color: "#828c98 !important",
                                minWidth: '36px !important',
                                '&:hover': {
                                  color: "black !important",
                                  backgroundColor: "transparent !important",
                                },
                                '& .MuiTouchRipple-root': {
                                  display: 'none !important',
                                }
                              }
                            }
                          }}
                        >
                          {loading && <CircularProgress size="1rem" />}
                          {listRender.InputProps.endAdornment}
                        </InputAdornment>
                      )
                    }}
                    error={error}
                    helperText={error ? messageError : ''}
                  />
                )}
              />
              <FormHelperError errorMessage={errorMessage} helperText={helperText} />
              {optional && <FormHelperError errorMessage="Optional" />}
            </FormControl>
          </Box>
        </>
      )}
    </>
  );
}

AutocompleteInput.propTypes = {
  multiple: PropTypes.bool,
  autoLoadService: PropTypes.bool,
  autocompleProps: PropTypes.object,
  debounceTime: PropTypes.number,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  filters: PropTypes.any,
  ListComponent: PropTypes.any,
  helperText: PropTypes.string,
  label: PropTypes.string,
  list: PropTypes.array,
  onChange: PropTypes.func,
  onChangeInput: PropTypes.func,
  title: PropTypes.string,
  lazyQueryService: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({
        service: PropTypes.func,
        data: PropTypes.arrayOf(PropTypes.object),
        loading: PropTypes.bool,
        ...PropTypes.object
      })
    ])
  ),
  size: PropTypes.oneOf(['small', 'medium', 'string']),
  take: PropTypes.number,
  value: PropTypes.any,
  variant: PropTypes.oneOf(['outlined', 'filled', 'standard']),
  limitTags: PropTypes.number,
  validatorFunction: PropTypes.func,
  messageError: PropTypes.string,
  optional: PropTypes.bool,
  filterSearchNameText: PropTypes.string,
  defaultValues: PropTypes.array,
  idFieldName: PropTypes.string,
  sx: PropTypes.object
};

AutocompleteInput.defaultProps = {
  errorMessage: null,
  label: null,
  variant: 'outlined',
  size: 'small',
  helperText: null,
  disabled: false,
  multiple: false,
  limitTags: 3,
  optional: false,
  filterSearchNameText: 'searchText',
  idFieldName: 'ids'
};

export default AutocompleteInput;

import { default as React, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import Form from "src/components/forms/Form/Form";
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import Button from "src/modules/generic/components/Buttons/Button";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import { clearAssistatText } from "src/modules/generic/store/action";
import { HiddenFieldForm } from "src/components/forms/contents";
import TextareaInput from "src/components/inputs/Textarea/TextareaInput";
import classes from "./classes.module.scss";

function HasBeenRenewedStep() {
  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const document = context?.initialData?.document;

  const initialValues = useMemo(() => {
    return context?.HasBeenRenewedStep ?? {};
  }, [context]);

  const [checkboxValue, setCheckboxValue] = useState(
    initialValues?.renewed ?? false
  );

  const handleCancel = () => cancel();

  const handleSubmit = async (values) => next(values);

  useEffect(() => {
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const schema = Yup.object().shape({
    renewed: Yup.boolean().required("This field is required"),
    description: Yup.string().when("renewed", {
      is: false,
      then: Yup.string().required("This field is required"),
    }),
  });

  const options = [
    {
      value: false,
      label: "No, it hasn't",
    },
    {
      value: true,
      label: "Yes, it has",
    },
  ];

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: `Has the expired ${document} been renewed?`,
          skeleton: null,
          subTitle: `Expired ${document}`,
          variant: "simple",
        }}
        buttons={{
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("BUTTON_CANCEL")}
            </Button>
          ),
          submit: (
            <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>
              {!checkboxValue
                ? translate("BUTTON_CONFIRM")
                : translate("BUTTON_NEXT")}
            </SubmitButton>
          ),
        }}
      >
        <ControllerInput
          render={CheckboxButtonSimple}
          name="renewed"
          options={options}
          onChange={(val: boolean) => {
            setCheckboxValue(val);
          }}
          isMultiple={false}
          classNameButton={classes.buttonCheckBox}
        />
        <HiddenFieldForm
          fieldsWatch="renewed"
          conditionHideCallback={(value) => {
            if (typeof value === "boolean") {
              return value === false;
            }
          }}
        >
          <TemplateLabel
            label="Please provide details as to why this document has not been renewed"
            isRequired
            isColumn
          >
            <ControllerInput
              name="description"
              render={TextareaInput}
              placeholder="Type here..."
              rows={6}
            />
          </TemplateLabel>
        </HiddenFieldForm>
        <div>&nbsp;</div>
      </TemplateWizard>
    </Form>
  );
}

export default HasBeenRenewedStep;

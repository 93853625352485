import React, { useEffect } from 'react';
import useQuery from "../../custom/useQuery"
import useLazyQuery from "../../custom/useLazyQuery"
import Cookies from 'universal-cookie';
import useSession from '../../../modules/session/hooks/useSession';


const query = `query getFormDraft ($keys:Object!) {
  getFormDraft(keys:$keys){
    values
  }
}`;

const useGetFormDraft = (variables, exucute = true, config) => {
  const {token} = useSession()
  // const { loading, error, data, refetch } = useQuery(query, variables);
  // useEffect(()=>{

  // },[])
  const { manualQuery, loading, error, data, refetch } = useLazyQuery(query, variables, config);
  useEffect(() => {

    if (exucute !== false && (token && token !== 'undefined')) {
      manualQuery()
    }

  }, []);
  return { loading, error, data, refetch,manualQuery };
}
export default useGetFormDraft;
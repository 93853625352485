import * as Yup from 'yup';
import helper from "../../utils/helper";
import _ from "lodash";
import { Constants } from '../../utils/constants'

Yup.addMethod(Yup.object, 'atLeastOneRole', function (message, mapper = a => a) {
    return this.test('atLeastOneRole', message, function (roles) {

        const list = Object.keys(roles);

        const hasOneRole = list.some(key => {

            const roleTags = Object.keys(roles[key]);

            return roleTags.some(tag => {
                const hasRole = roles[key][tag]
                return hasRole
            })

        });

        return hasOneRole;
    });
});
var getCompanyIdToValidationInvestorRole = (roles) => {
    let companyId = null
    let companyRol = _.find(roles, (companyRoles, companyIdKey) => {
        /*Search if the company has INVESTOR Rol */
        let rolInvestorFinded = _.find(companyRoles, (rol, key) => {
            return key === Constants.ROLES_FROM_BACKEND.INVESTOR.id && rol == true
        })
        /* If hast investor return no error */
        if (!rolInvestorFinded) { return false; }
        /* Search if has  equity rol */
        let rolFinded = _.find(companyRoles, (rol, key) => {
            switch (key) {
                case Constants.ROLES_FROM_BACKEND.OPTIONHOLDER.id:
                case Constants.ROLES_FROM_BACKEND.CONVERTIBLEHOLDER.id:
                case Constants.ROLES_FROM_BACKEND.SHAREHOLDER.id:
                case Constants.ROLES_FROM_BACKEND.WARRANTHOLDER.id:
                    return true
                default:
                    return false
            }
        })
        if (!rolFinded) {
            companyId = companyIdKey;
        }
        return !rolFinded;
    })

    return companyId;
}
Yup.addMethod(Yup.object, 'alInvestorValidationRole', function (message, mapper = a => a) {
    return this.test('alInvestorValidationRole', function (obj) {
        let companyId = getCompanyIdToValidationInvestorRole(obj.value);
        let error = {};
        if (companyId) {
            error[companyId] = message;
        }
        return error;
    }, function (roles) {
        return !getCompanyIdToValidationInvestorRole(roles);
    });
});

const forgotValidations = {
    email: Yup.string().lowercase().required("Required").matches(Constants.REGEXP.EMAIL, { message: "Invalid Email", excludeEmptyString: true }),
};

const loginValidations = {
    email: Yup.string().lowercase().required("Required").matches(Constants.REGEXP.EMAIL, { message: "Invalid Email", excludeEmptyString: true }),
    password: Yup.string().required("Required")
};

const resetValidations = {
    confirmPassword: Yup.string().required(),
    password: Yup.string().required()
};

const documentValidations = {
    documents: Yup.array().compact().of(
        Yup.object().shape({
            type: Yup.string().required('Required'),
            name: Yup.string().required('Required'),
            file: Yup.object().shape({
                name: Yup.string().required(),
                size: Yup.string().required(),
                id: Yup.string().required()
            })
        })
    )
};

const detailValidations = {
    fullName: Yup.string({ message: 'Only Letters allowed', excludeEmptyString: true }).required("Name is required"),
    nationality: Yup.string().notRequired(),
    passportNumber: Yup.string().nullable().optional(),
    address: Yup.object().shape({
        country: Yup.string().nullable().notRequired(),
        street: Yup.string().nullable().notRequired(),
        city: Yup.string().nullable().notRequired(),
        state: Yup.string().nullable().optional(),
        zipCode: Yup.string().nullable().optional()
    }).nullable(),
    email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, { message: "Invalid Email", excludeEmptyString: true }).nullable().optional(),
	recipientNotice: Yup.object().shape({
		fullName: Yup.string()
			.nullable()
			.required("This field is required."),
		email: Yup.string().lowercase()
			.matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
			.nullable()
			.optional(),
		useRegisteredAddress: Yup.boolean(),
		address: Yup.object().when('useRegisteredAddress', {
			is: false,
			then: Yup.object().shape({
				country: Yup.string().required('This field is required').nullable(),
				street: Yup.string().required('This field is required').nullable(),
				city: Yup.string().required('This field is required').nullable(),
				state: Yup.string().nullable(),
				zipCode: Yup.string().required('This field is required').nullable(),
			}),
			otherwise: Yup.object()
		})
	})
};

const roleValidations = {
    validateRoles: Yup.boolean(),
    roles: Yup.object().when('validateRoles', {
        is: true,
        then: Yup.object().atLeastOneRole().alInvestorValidationRole(helper.getTranslateTextByKey("VALIDATION_STAKEHOLDER_ROLES_INVESTOR")),
        otherwise: Yup.object()
    }),

};

const equityValidation = {
    validateEquity: Yup.boolean(),
    equity: Yup.object().when('validateEquity', {
        is: true,
        then: Yup.array().of(Yup.object().shape(
            {
                groupCompany: Yup.string().required('Required'),
                shareClass: Yup.string().required('Required'),
                amount: Yup.number().typeError('This field must be a number').required('Only numbers are allowed.')
            }
        )),
        otherwise: Yup.object()
    })
};

const shareClassValidation = {
    shareClass: Yup.array().of(Yup.object().shape(
        {
            name: Yup.string().required("Name is required"),
            authorizedShares: Yup.number().typeError('This field must be a number').typeError('This field must be a number').positive("Authorized shares must be greater than 0")
        }))
};

const equityValidationForEdition = {
    equity: Yup.array().of(Yup.object().shape(
        {
            groupCompany: Yup.string().required('Required'),
            shareClass: Yup.string().required('Required'),
            amount: Yup.number().typeError('This field must be a number').typeError('This field must be a number').required('Only numbers are allowed.')
        }
    ))
};

const convertiblesValidation = {
    validateConvertibles: Yup.boolean(),
    convertibles: Yup.object().when('validateConvertibles', {
        is: true,
        then: Yup.array()
            .ensure(Yup.object().shape({
                groupCompany: Yup.string().required('Required'),
                valuationCap: Yup.number().typeError('This field must be a number').required('Only numbers are allowed.'),
                interestRate: Yup.number().typeError('This field must be a number').min(0).max(100, 'Only numbers are allowed between 0 and 100.'),
                discountRate: Yup.number().typeError('This field must be a number').min(0).max(100, 'Only numbers are allowed between 0 and 100.'),
                amount: Yup.number().typeError('This field must be a number').required('Only numbers are allowed.')
            }))
            .cast(null),
        otherwise: Yup.object()
    }
    )
};

const convertiblesValidationForEdition = {
    convertibles: Yup.array().of(Yup.object().shape({
        groupCompany: Yup.string().required('Required'),
        valuationCap: Yup.number().typeError('This field must be a number').required('   Only numbers are allowed.'),
        interestRate: Yup.number().typeError('This field must be a number').min(0).max(100, 'Only numbers are allowed between 0 and 100.'),
        discountRate: Yup.number().typeError('This field must be a number').min(0).max(100, 'Only numbers are allowed between 0 and 100.'),
        amount: Yup.number().typeError('This field must be a number').required('Only numbers are allowed.')
    }))
};


const issuesValidations = Yup.object().shape({
    category: Yup.string().nullable().required('Required'),
    subCategory: Yup.string().nullable().required('Required'),
    urgency: Yup.string().required('Required'),
    title: Yup.string().required('Required'),
    description: Yup.string().required('Required')

});

const userValidationsAdd = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, { message: "Invalid Email address", excludeEmptyString: true }).required('Required'),
    password: Yup.string().required('Required').min(6, "Your password needs to have more than 6 characters."),
    role: Yup.string().required("Required"),
    profiles: Yup.array().when(['role'], {
        is: "USER",
        then: Yup.array().of(
            Yup.object().shape({
                startupId: Yup.string().required(),
                role: Yup.string().required()
            })
        ).min(1).required("Required"),
        otherwise: Yup.array().notRequired()
    })
});

const userValidationsEdit = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, { message: "Invalid Email address", excludeEmptyString: true }).required('Required'),
    role: Yup.string().required("Required"),
    profiles: Yup.array().when(['role'], {
        is: "USER",
        then: Yup.array().of(
            Yup.object().shape({
                startupId: Yup.string().required(),
                role: Yup.string().required()
            })
        ).min(1).required("Required"),
        otherwise: Yup.array().notRequired()
    })
});

const isGroupCompany = (value) => {
    return value.type === 'groupCompany';
};

const generateAgreementValidations = {
    documentType: Yup.string().required('Required'),
    agreementName: Yup.string(),
    file: Yup.object().nullable().required('File is required'),
    agreementStatus: Yup.string().required('Required'),
    executionDate: Yup.string().nullable(),
    expirationDate: Yup.string().nullable(),
    parties: Yup.array().of(
        Yup.object().shape({
            party: Yup.object().required('Required'),
            authorizedSignatory: Yup.string()
            /*.when('party', {
            is: isGroupCompany,
            then: Yup.string().required('Required'),
        })*/
        })
    ).required('Required'),
    category: Yup.string().required('Category is required.'),
    subCategory: Yup.string().required('Subcategory is required.'),
};

const uploadedAgreementValidations = {
    documentType: Yup.string().nullable().required('Required'),
    agreementName: Yup.string(),
    file: Yup.object().nullable().required('File is required'),
    agreementStatus: Yup.string().required('Required'),
    executionDate: Yup.string().nullable(),
    expirationDate: Yup.string().nullable(),
    parties: Yup.array().of(
        Yup.object().shape({
            party: Yup.object().nullable().required('Required'),
            /*.when('party', {
            is: isGroupCompany,
            then: Yup.string().required('Required'),
        })*/
        })
    ).required('Required'),
    category: Yup.string().required('Category is required.').nullable(),
    subCategory: Yup.string().nullable().required('Subcategory is required.')
};

const editGroupCompanyValidation = {

    name: Yup.string({ message: 'Only Letters allowed', excludeEmptyString: true }).required("Name is required"),
    country: Yup.string().nullable().optional(),
    jurisdiction: Yup.string().nullable().optional(),
    type: Yup.string().nullable().optional(),
    registeredNumber: Yup.string().nullable().optional(),
    isHoldingCompany: Yup.boolean(),
    address: Yup.object().shape({
        country: Yup.string().nullable().optional(),
        street: Yup.string().nullable().optional(),
        city: Yup.string().nullable().optional(),
        state: Yup.string().nullable().optional(),
        zipCode: Yup.string().nullable().optional()
    }).nullable(),
	recipientNotice: Yup.object().shape({
		fullName: Yup.string()
			.nullable()
			.required("This field is required."),
		email: Yup.string().lowercase()
			.matches(Constants.REGEXP.EMAIL, { message: "This field must be a valid email", excludeEmptyString: true })
			.nullable()
			.optional(),
		useRegisteredAddress: Yup.boolean(),
		address: Yup.object().when('useRegisteredAddress', {
			is: false,
			then: Yup.object().shape({
				country: Yup.string().required('This field is required').nullable(),
				street: Yup.string().required('This field is required').nullable(),
				city: Yup.string().required('This field is required').nullable(),
				state: Yup.string().nullable(),
				zipCode: Yup.string().required('This field is required').nullable(),
			}),
			otherwise: Yup.object()
		})
	}),
    hasSameWorkingAddress: Yup.boolean(),
    workingAddress: Yup.object().when(['hasSameWorkingAddress'], {
        is: false,
        then: Yup.object().shape({
            country: Yup.object().shape({ code: Yup.string().nullable().required('Required') }),
            street: Yup.string().nullable().required('Required'),
            city: Yup.string().nullable().required('Required'),
            state: Yup.string().nullable().optional(),
            zipCode: Yup.string().nullable().optional()
        })
    }).nullable(),
};

const groupCompanyValidation = {
    // ----- Step1 -----
    name: Yup.string().required('Required'),
    country: Yup.string(),
    avatar: Yup.string(),
    jurisdiction: Yup.string(),
    companyType: Yup.string(),
    registeredNumber: Yup.string(),
    "address.street": Yup.string(),
    "address.city": Yup.string(),
    "address.zipCode": Yup.string(),
    "address.country": Yup.string(),
    transactionsCurrency: Yup.string(),
    documents: Yup.array().of(
        Yup.object().shape({
            documentCategory: Yup.string(),
            documentName: Yup.string(),
            file: Yup.object(),
        })
    ).min(0),
    // ----- Step2 -----
    shareClass: Yup.array().of(
        Yup.object().shape({
            name: Yup.string(),
            authorizedShares: Yup.string(),
            shareIncentivePlans: Yup.array().of(
                Yup.object().shape({
                    name: Yup.string(),
                    authorizedOptions: Yup.string(),
                    document: Yup.object()
                })
            ).min(0),
            incentivePlans: Yup.array().of(
                Yup.object().shape({
                    name: Yup.string(),
                    authorizedOptions: Yup.string(),
                    document: Yup.object()
                })
            ).min(0),
        })
    ).min(0),
    // ----- Step3 -----
    grantShares: Yup.array().of(
        Yup.object().shape({
            groupCompany: Yup.string().required('Required'),
            shareClass: Yup.string().required('Required'),
            numberOfShares: Yup.number().typeError('This field must be a number').required('Only numbers are allowed.'),
            grantType: Yup.string(),
        })
    ).min(0),

};

const startupValidations = {
    name: Yup.string().required('Required'),
    description: Yup.string().nullable().optional(),
    website: Yup.string().nullable().matches(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/, "Invalid website format.")
};

const sharesPositionValidations = {
    owner: Yup.string().required('Required').nullable(),
    amount: Yup.number().typeError('This field must be a number').required('Only numbers are allowed.'),
    price: Yup.number().typeError('This field must be a number'),
    shareClass: Yup.string().nullable(),
    associatedDocuments: Yup.string(),
    issuedDate: Yup.string().nullable(),
};

const convertiblesPositionValidations = {
    owner: Yup.string().required('Required').nullable(),
    amount: Yup.number().typeError('This field must be a number').required('Only numbers are allowed.'),
    discountRate: Yup.number().typeError('This field must be a number').min(0).max(100, 'Only numbers are allowed between 0 and 100.'),
    interestRate: Yup.number().typeError('This field must be a number').min(0).max(100, 'Only numbers are allowed between 0 and 100.'),
    valuationCap: Yup.number().typeError('This field must be a number'),
    associatedDocuments: Yup.string(),
    signingDate: Yup.string().nullable(),
};

const optionsPositionValidations = {
    company: Yup.string().required('Required').nullable(),
    owner: Yup.string().required('Required').nullable(),
    amount: Yup.number().typeError('This field must be a number').required('Only numbers are allowed.'),
    price: Yup.number().typeError('This field must be a number'),
    shareClass: Yup.string().nullable(),
    shareIncentivePlan: Yup.string().nullable(),
    associatedDocuments: Yup.string(),
    grantDate: Yup.string().nullable(),
};

const warrantsPositionValidations = {
    company: Yup.string().required('Required').nullable(),
    owner: Yup.string().required('Required').nullable(),
    amount: Yup.number().typeError('This field must be a number').required('Only numbers are allowed.').nullable(),
    valuation: Yup.number().typeError('This field must be a number').nullable(),
    associatedDocuments: Yup.string(),
    grantDate: Yup.string().nullable(),
};
const stakeHolderSelectValidation = {
    fullName: Yup.string().required(helper.getTranslateTextByKey("VALIDATION_STAKEHOLDER_NAME_REQUIRED")),
    roles: Yup.array().of(
        Yup.object().shape({
            groupCompany: Yup.string().required('VALIDATION_STAKEHOLDER_ROLES_REQUIRED')
        })
    ).required(helper.getTranslateTextByKey("VALIDATION_STAKEHOLDER_ROLES_REQUIRED")).nullable(),
};

export {
    equityValidation,
    equityValidationForEdition,
    convertiblesValidation,
    convertiblesValidationForEdition,
    detailValidations,
    roleValidations,
    documentValidations,
    issuesValidations,
    generateAgreementValidations,
    uploadedAgreementValidations,
    editGroupCompanyValidation,
    groupCompanyValidation,
    forgotValidations,
    loginValidations,
    resetValidations,
    shareClassValidation,
    startupValidations,
    sharesPositionValidations,
    convertiblesPositionValidations,
    optionsPositionValidations,
    warrantsPositionValidations,
    userValidationsAdd,
    userValidationsEdit,
    stakeHolderSelectValidation
};

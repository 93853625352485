import React,{FC} from 'react';
import LastStep from "../../../core/wizards/steps/LastStep/LastStep";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import {StepProps} from "../../../../../generic/components/Wizard/Step";

const LastStepConsultant:FC<StepProps>= ({})=>{
    const {translate}=useTranslate()
    return(
        <>
            <LastStep
                role={'CONSULTANT'}
                tooltips={{isNotAnother:translate('MODULES.ONBOARDINGROLES.CONSULTANT.THIRD_STEP_NOT_ANOTHER')}}/>
        </>
    );
}

export default LastStepConsultant;

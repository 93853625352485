import useMutation from "src/hooks/custom/useMutation";
import useSession from "src/modules/session/hooks/useSession";

export const UPSERT_DATA_PROTECTION_DECLARATION = `
mutation upsertGroupCompany(
    $startupId: String
    $groupCompanyId: String
	$groupCompany: GroupCompanyInputType
  ) {
    upsertGroupCompany(
      startupId: $startupId
      groupCompanyId: $groupCompanyId
      groupCompany: $groupCompany
    ) {
      id
  }
}
`;

const useUpdateDataProtectionDeclaration = () => {
    const {startupId} = useSession()
    const [mutation] = useMutation(
        UPSERT_DATA_PROTECTION_DECLARATION,
        { showSuccessNotification: true }
    ); 
    
    const save = async (groupCompanyId,id) =>{
        const data = {
            variables:{
                startupId,
                groupCompanyId,
                groupCompany:{
                    keyPeople:{
                        dataProtectionContact:id 
                    }
                }
            }
        }        
        const submitData = await mutation(data)

        return submitData
    }

    return save;
};

export default useUpdateDataProtectionDeclaration;

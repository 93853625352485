import React, { FC } from "react";
import classesModule from './SquareContent.module.scss';
import Icon from "../../../generic/components/Icon";
import TypographyLocal from "../../../generic/components/Texts/Typography";
import Button from '../../../generic/components/Buttons/Button'

/**
 * SquareContent
 */

 export interface SquareContentProps {
  /** Property icon */
  icon?: string,
  /** Property firstLine */
  firstLine?: string,
  /** Property blackLine */
  blackLine?: string,
  /** Property secondLine */
  secondLine?: string,
  /** Property btnText */
  btnText?: string,
  /** Property btnOnClick */
  btnOnClick?: (event: React.MouseEvent<HTMLInputElement>) => void
  /** Property btnDisable */
  btnDisable?: boolean
  /** Property btnVariant */
  btnVariant?: string,
  /** Property paymentLine */
  paymentLine?: string,
  /** Property image */
  image?: string,
  /** Property payMode */
  payMode?: boolean,
}

const SquareContent: FC<SquareContentProps> = ({
  icon = "Academy",
  firstLine,
  blackLine,
  secondLine,
  btnText,
  btnOnClick,
  btnDisable,
  btnVariant = "secondary",
  paymentLine,
  image,
  payMode,
  ...props
}:SquareContentProps) => {

  return (
    <div className={` ${classesModule.SquareContent} `}>
      <span className={` ${classesModule.iconColor} ${payMode && classesModule.iconRed}`}>
        {!!image ? (
          <div className={` ${classesModule.imageContent} `}>
            <img src={image} alt="" className={` ${classesModule.imageSize} `}/>
          </div>
        ) : (
          <Icon icon={icon} size={"54px"} isClara={true}></Icon>
        )}
      </span>
      {!!firstLine && (
        <TypographyLocal
          className={` ${classesModule.textMargin} ${payMode && classesModule.blackColor}`}
        >
          {firstLine}
        </TypographyLocal>
      )}
      {!!blackLine && (
        <TypographyLocal
          className={` ${classesModule.textNumbers} `}
        >
          {blackLine}
        </TypographyLocal>
      )}
      {!!paymentLine && (
        <TypographyLocal
          className={` ${classesModule.paymentMargin} `}
        >
          <span dangerouslySetInnerHTML={{__html: paymentLine}}></span>
        </TypographyLocal>
      )}
      {!!btnText && (
        <div className={` ${classesModule.btnMargin} `}>
          <Button
            variant={btnVariant}
            children={btnText}
            onClick={btnOnClick}
            disabled={btnDisable}
          ></Button>
        </div>
      )}
      {!!secondLine && (
        <div className={` ${classesModule.btnMargin} `}>
          <TypographyLocal
          >
            {secondLine}
          </TypographyLocal>
        </div>
      )}
    </div>
  );
}

export default SquareContent;
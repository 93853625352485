import ModalAssistant, { ModalAssistantProps } from '../../../generic/components/Modal/ModalAssistant'
import React, { FC } from 'react'

import MachineAuthorisedSignatories from './machine'
import Wizard from '../../../generic/components/Wizard'
import useModal from '../../../generic/hooks/useModal'

interface ModalProofOfAddressProps extends ModalAssistantProps {
  paramsMutation: object,
  onClose(): void,
  initialValues: object
}

const ModalProofOfAddress: FC<ModalProofOfAddressProps> = ({
  children,
  open = false,
  paramsMutation = {},
  initialValues = {},
  ...props
}: ModalProofOfAddressProps) => {

  const { closeModal: onClose } = useModal()

  const handleClose = (values) => {
    onClose()
  }

  const data = {
    ...initialValues,
    ...paramsMutation
  }

  return (
    <>
      <ModalAssistant open={open}>
        <Wizard 
          machine={MachineAuthorisedSignatories} 
          initialValues={data} 
          onComplete={handleClose} 
          />
      </ModalAssistant>
    </>
  )
}

export default ModalProofOfAddress;





import React from 'react';
import ACTIONS from "../../../../constants/actions";
import PAGES from "../../../../constants/pages";
import ClaraLayout from "../../../../layouts/Clara/ClaraLayout";
import Upgrade from "./upgrade/Upgrade";

const moduleRouter = [
  {
    path: PAGES.upgrade.path,
    exact: true,
    action: ACTIONS.MANAGE_SUBSCRIPTIONS,

    component: < Upgrade />,
    layout: ClaraLayout,
    propsLayout: {
      cantBack: true,
      startupMenu: true,
      userMenu: true,
      fullMenu: true,
      showAssistance: false,
      upgradeButton: true,
    },
  },

];
export default moduleRouter;

import React from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";

const AddConvert = ({ go, ...props }) => {
  const params = useParams();

  return (
    <MicroFrontend id="MF_Add_Convert_Content_View" component={"./AddConvert"} mf="capTable" params={{ go, ...props, ...params }} />
  );
};

export default AddConvert;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "../../index";
import classes from "./EditForm.module.scss"
import _ from "lodash";
import { Checkbox } from "@material-ui/core"
import TextLoading from "../../../../components/generic/Loading/TextLoading/TextLoading";

class DeleteForm extends Component {

  state = {
    count: 0,
    next: false,
    isOpenModal: true,
    wasDeleted: false
  }

  closeModal = (value) => {
    this.props.handleClose(value);
  };

  onClickCheck = (value) => {
    let { count, next } = this.state;
    if (value) {
      count++
    } else {
      count--
    }
    next = count === this.props.options.length;
    this.setState({
      next, count
    });
  };

  handleDeleteEntity = () => {
    let { deleteEntity, hookDeleteEntity, paramsDeleteEntity } = this.props;
    if (hookDeleteEntity) {
      hookDeleteEntity(paramsDeleteEntity)
    } else {
      deleteEntity(paramsDeleteEntity).then(result => {
        this.closeModal(true);
      }).catch(err => {
        this.closeModal(false)
      })
    }
  }

  componentDidMount = () => {
    this.setState({
      next: _.get(this, "props.options.length", 0) === 0
    })
  }

  componentWillUpdate = (nextProps) => {
    if (_.get(this, "props.options.length", 0) !== _.get(nextProps, "options.length", 0)) {
      this.setState({
        next: _.get(nextProps, "options.length", 0) === 0
      })
    }
    if (_.get(this, "props.show") === false && _.get(nextProps, "show") === true) {
      this.setState({
        count: 0,
        next: false,
      })
    }
  }

  render() {
    let { nextButtonText, positionWarning = "top", component } = this.props;
    nextButtonText = nextButtonText ? nextButtonText : "Delete"
    return (
      <div>
        {this.props.show !== false ?
          <Modal className={{ modal: classes.ModalDelete }} overlayName={this.props.overlayName}
            open={this.props.show} handleClose={() => this.props.handleClose(false)} showHeader={false}>
            <div className={classes.DeleteForm}>
              <h1>{this.props.title}</h1>
              {!!component ? (
                <div className={classes.zoneComponent}>
                  {component}
                </div>
              ) : (
                <React.Fragment>
                  {positionWarning === "top" && (<h2>{this.props.warning ? this.props.warning : "This action cannot be undone"}</h2>)}
                  <div className={classes.zoneCheckBox}>
                    {this.props.options.map((option, i) => {
                      return (
                        <div className={classes.CheckBox} key={i}>
                          <Checkbox className={classes.checkColor} onChange={(e) => this.onClickCheck(e.target.checked)} />
                          <label>{option.label}</label>
                        </div>
                      )
                    })}
                  </div>
                  {positionWarning === "bottom" && (<h2 className={classes.Warning}>{this.props.warning ? this.props.warning : "This action cannot be undone"}</h2>)}
                </React.Fragment>
              )}
              <div className={classes.botonera}>
                <button onClick={() => this.closeModal(false)}>Cancel</button>
                <button onClick={this.handleDeleteEntity} className={classes.nextButton} disabled={this.props.isLoading || !this.state.next}>{
                  this.props.isLoading ? <TextLoading loading={true} label={'Deleting...'} /> :
                    nextButtonText
                }</button>
              </div>
            </div>
          </Modal>
          : null}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  session: state.session
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteForm)

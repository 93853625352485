import React, {Component} from "react";
import {connect} from "react-redux";
import _ from "lodash";
import {Constants} from '../../../../utils/constants'
import classes from "../ViewForms.module.scss";
import {Avatar, RoleAccess} from '../../../index'
import ServerConnect from "../../../../utils/ServerConnect";

import TableDocument from "../DocumentTable";
import {PrintDate, PrintFormattedNumber} from "../../../../utils/servicePrint"
import {goToPage} from "../../../../../store/actions/page";
import {filterCategoryByDoctype} from "../../../../../utils/categoryHelper";

class Details extends Component {

  constructor(props) {
    super(props);
    this.state = {
        documents:[],
        categories: [],
      formOrder: {
          type: [],
          subCategories: [],
          category: []
      }
      }
    };

  componentWillMount = ( ) => {
      if(this.props.data.edit){
          this.changeMode();
        this.props.data.edit=false;

      }

      this.setState({
        documents: this.mapTableDocuments(this.props.documents)
      })
    
    
  }

  mapTableDocuments(documents){
      const {categories} = this.props;
      let data = _.map(_.compact(documents), d => {

      const avatars = [];
      let aux={...d};
      aux.type = _.mapValues(d, "label").type
      _.map(aux.groupCompanies,gc => avatars.push({ imageUrl: gc.groupCompany.avatar, name: gc.groupCompany.name }))
      _.map(aux.stakeholders,st => avatars.push({ imageUrl: st.avatar, name: st.fullName }))
      aux.avatars = avatars.length > 5 ? { parties: avatars.slice(0, 2), moreParties: avatars.slice(2) } : { parties: avatars };
      const filteredCat = filterCategoryByDoctype(categories,d.docType,d.subCategories[0])
      let {category,subCategories} = this.getByCategory(filteredCat.subCategories.map(sub=>sub.key));
      aux.subCategories = subCategories;
      aux.category= category;
      this.state.formOrder.type.push(aux.type);
      this.state.formOrder.subCategories.push(aux.subCategories);
      this.state.formOrder.category.push(aux.category);
      return aux
  })

    return data;
  }

  getByCategory = (subCategoryData) => {
    let subCategories = [];
    let category = [];
    _.forEach(subCategoryData, (element) => {

        _.forEach(this.props.categories, (cat) => {

            let subcategory = _.find(cat.subCategories, subCategory => subCategory.key === element)

            if (subcategory) {
                subCategories.push(subcategory.title);
                category.push(cat.title)
            }
        })


    })

    return { subCategories, category };

}

  changeMode = () => {

      this.props.goToPage(Constants.PAGES.editEquity, {...this.props.data, type:this.props.type});
  }

  selectView(data) {
    switch (this.props.type) {     
      case "SHARE":
        return (
          <React.Fragment>

            <div className={`${classes.ContainerRow}`}>
              <label>Who owns the equity?</label>
              <span>
                <Avatar
                  avatar={data.owner.avatar}
                  name={data.owner.name || data.owner.fullName}
                  showLabel={true}
                />
              </span>

            </div>

            <hr className={classes.Separator} />

            <div className={`${classes.ContainerRow}`}>
              <label>Number of Shares:</label>
              <span>{PrintFormattedNumber(data.amount,"NUMBER", "")}</span>
            </div>
            <hr className={classes.Separator} />
            <div className={`${classes.ContainerRow}`}>
              <label>Class:</label>
              <span>{data.shareClass.name}</span>
            </div>
            <div className={`${classes.ContainerRow}`}>
              <label>Price per Share:</label>
              <span>{PrintFormattedNumber(data.price,"CURRENCY",data.currency)}</span>
            </div>
            <hr className={classes.Separator} />
            <div className={`${classes.ContainerRow}`}>
              <label>Issued Date:</label>
              <span>{PrintDate(data.issuedDate,"MM/DD/YYYY")}</span>
            </div>

          </React.Fragment>
        )
      case "OPTION":
        return (
          <React.Fragment>

            <div className={`${classes.ContainerRow}`}>
              <label>Who is the options?</label>
              <span>
                <Avatar
                  avatar={data.owner.avatar}
                  name={data.owner.name || data.owner.fullName}
                  showLabel={true}
                />
              </span>

            </div>

            <hr className={classes.Separator} />

            <div className={`${classes.ContainerRow}`}>
              <label>Number of Share Options:</label>
              <span>{data.amount}</span>
            </div>
            <hr className={classes.Separator} />
            <div className={`${classes.ContainerRow}`}>
              <label>Class:</label>
              <span>{data.shareClass.name}</span>
            </div>
            <div className={`${classes.ContainerRow}`}>
              <label>Exercise Price:</label>
              <span>{PrintFormattedNumber(data.price,"CURRENCY",data.currency)}</span>
            </div>
            <hr className={classes.Separator} />
            <div className={`${classes.ContainerRow}`}>
              <label>Share Incentive Plan:</label>
              <span>{_.get(data,"shareIncentivePlan.name", " ")}</span>  {/* provitional*/}
            </div>
            <hr className={classes.Separator} />
            <div className={`${classes.ContainerRow}`}>
              <label>Grant Date:</label>
              <span>{PrintDate(data.issuedDate,"MM/DD/YYYY")}</span>
            </div>
          </React.Fragment>
        )

      case "WARRANT":
        return (
          <React.Fragment>
            <div className={`${classes.ContainerRow}`}>
              <label>Who holds the warrants?</label>
              <span>
                <Avatar
                  avatar={data.owner.avatar}
                  name={data.owner.name || data.owner.fullName}
                  showLabel={true}
                />
              </span>

            </div>

            <hr className={classes.Separator} />

            <div className={`${classes.ContainerRow}`}>
              <label>Investment Amount:</label>
              <span>{ PrintFormattedNumber(data.amount,"CURRENCY",data.currency)}</span>
            </div>
            <div className={`${classes.ContainerRow}`}>
              <label>Valuation:</label>
              <span>{PrintFormattedNumber(data.valuation,"CURRENCY",data.currency)}</span>
            </div>
            <hr className={classes.Separator} />
            <div className={`${classes.ContainerRow}`}>
              <label>Grant Date:</label>
              <span>{PrintDate(data.issuedDate,"MM/DD/YYYY")}</span>
            </div>  

          </React.Fragment>
        )

      case "CONVERTIBLE":
        return (
          <React.Fragment>
            <div className={`${classes.ContainerRow}`}>
              <label>Who is the investor?</label>
              <span>
                <Avatar
                  avatar={data.owner.avatar}
                  name={data.owner.name || data.owner.fullName}
                  showLabel={true}
                />
              </span>

            </div>

            <hr className={classes.Separator} />

            <div className={`${classes.ContainerRow}`}>
              <label>Investment Amount:</label>
              <span>{PrintFormattedNumber(data.amount,"CURRENCY",data.currency)}</span>
            </div>
            <div className={`${classes.ContainerRow}`}>
              <label>Discount:</label>
              <span>{PrintFormattedNumber(data.discountRate,"PERCETAGE")}</span>
            </div>

            <div className={`${classes.ContainerRow}`}>
              <label>Interest Rate:</label>
              <span>{PrintFormattedNumber(data.interestRate,"PERCETAGE")}</span>
            </div>

            <div className={`${classes.ContainerRow}`}>
              <label>Valuation:</label>
              <span>{PrintFormattedNumber(data.valuationCap,"CURRENCY",data.currency)}</span>
            </div>
            <hr className={classes.Separator} />
            <div className={`${classes.ContainerRow}`}>
              <label>Signing Date:</label>
              <span>{PrintDate(data.issuedDate,"MM/DD/YYYY")}</span>
            </div>  
          </React.Fragment>
        )

      default:
        break;
    }
  }
  openFile(file) {
    let url = ServerConnect.getUrlFile(file.id);
    ServerConnect.openFile(url,file.name);
  }

  render() {
    let { data } = this.props;
    let {documents,formOrder} = this.state;

    if (!data || Object.keys(data).length === 0) {
      return null;
    }

    return (
      <div className={classes.ContainerView}>
        <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
          {
            <button className={classes.buttonEditFloat} onClick={(e) => { this.changeMode() }}>
              <span>Edit</span>
            </button>
          }
        </RoleAccess>

        <div className={classes.ContainerData}>
          <div className={`${classes.ContainerRow}`}>

            <label>Company:</label>
            <span>
              <Avatar
                avatar={data.company.avatar}
                name={data.company.name}
                showLabel={true}
              />
            </span>
          </div>
          {this.selectView(data)}

          <hr className={classes.Separator} />
        </div>
        <div className={`${classes.ContainerRow}`}>
          <label>Associated Documents:</label>
        </div>

        <TableDocument
          data={documents}
          filter={""}
          formOrder={formOrder}
        />


      </div>
    )
  }
}

const document = [
]

const mapStateToProps = state => ({
  session: state.session,
   
});
const mapDispatchToProps = (dispatch) => ({

    goToPage: (page, params) => {
        goToPage(dispatch, page, params)
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Details)

import AddBasicInfo from '../steps/AddBasicInfo';
import AddMoreDetails from '../steps/AddMoreDetails';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import { useMemo } from 'react';

const useSteps = () => {

  return useMemo(() => ({
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    AddBasicInfo: {
      render: AddBasicInfo,
    },
    AddMoreDetails: {
      render: AddMoreDetails,
    },
    SaveStep: {
      render: TemplateWizardSkeletonMachine,
    },
  }), []);
}

export default useSteps;

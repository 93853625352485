import { useEffect } from "react";
import useQuery from "../../../../hooks/custom/useQuery";

export const GET_STAKEHOLDER = `
  query getStakeholderByTask($startupId: ID! $stakeholderId: ID!) {
  getStakeholder(startupId: $startupId stakeholderId: $stakeholderId) {
    id
    isLocked
    isFounder
    fullName
    jurisdiction
    isAnEntity
    authorizedSignatory {
      id
      fullName
      stakeholder
    }
    identityDocuments {
      id
      type
      nationality
    }
    directors {
      id
      fullName
    }
    sourcesOfFunds {
      jurisdictionType
      sourceOfFunds
      sourceOfWealth
      sourceOfFundsYesNo
    }
    tasks {
      code
      status
      additionalParams{
        entityId
        entityType
        type
        country {
          code
          name
        }
      }
    }
    warrants{
      id
      amount { amount currency }
      issuedDate
      groupCompany{
        id
        name
      }
      tasks {
        name
        code
        status
        additionalParams{
          entityId
          entityType
        }
      }
    }
    convertibles{
      id
      amount { amount currency }
      issuedDate
      groupCompany{
        id
        name
      }
      tasks {
        name
        code
        status
        additionalParams{
          entityId
          entityType
        }
      }
    }
    roles { 
      id
      name
      isActive
      entity {
          ... on StakeHolder {
              id
              fullName
          }
          ... on GroupCompany {
            id
            name
            kind
            companyGoal
            formationProgress
            jurisdiction
            jurisdictionType
            companyType
          }
      }
      tasks {
        name
        code
        status
        additionalParams{
            entityId
            entityType
          }
        }
      }
    }
  }`;

const useStakeholderTasks = (variables) => {
  const { loading, error, data } = useQuery(GET_STAKEHOLDER, variables);
  useEffect(() => {}, []);

  return { loading, error, data };
};
export default useStakeholderTasks;

import _ from 'lodash'
import useQuery from "../../../../../../../hooks/custom/useQuery";

const GET_GROUP_COMPANY = `
  query getGroupCompanyNotices($startupId: ID!, $groupCompanyId: ID!) {
    getGroupCompany(startupId: $startupId, groupCompanyId: $groupCompanyId) {
      id
      name
      recipientNotice {
        fullName
        email
        useRegisteredAddress
        address {
          street
          city
          state
          country {
            code
            name
            alpha3
          }
          zipCode
        }
      }
    }
  }
`;
const useGroupCompany = ({ variables, ...props }) => {
    const { loading, error, data, refetch, ...propsDesc } = useQuery(GET_GROUP_COMPANY, variables, null, props);
    return { loading, error, data: _.get(data, 'getGroupCompany'), refetch, ...propsDesc };
};

export default useGroupCompany;

import React from 'react';
import classesInputYearsMonths from './ClaraInputYearsMonths.module.scss';
import {TextInput} from '../../../index';
import {Field} from 'formik';


const ClaraInputYearsMonths = ({ label, form, field, tooltips,required }) => {

    const nameField = field.name;


    return (
        <div className={classesInputYearsMonths.containerInputText}>

            <label>{label}{required ?
                <span style={{color: 'red'}}>*</span> : null}</label>

            <Field name={`${nameField}.years`}>
                {({ form, field }) => (
                    <TextInput
                        form={form}
                        field={field}
                        type='text'
                        placeholder={"Years"}
						tooltip={tooltips ?tooltips.years:''}
                        className={classesInputYearsMonths.inputNumber}
                    />

                )}


            </Field>

            <Field name={`${nameField}.months`}>
                {({ form, field }) => (
                    <TextInput
                        form={form}
                        field={field}
                        type='text'
                        placeholder={'Months'}
						tooltip={tooltips ?tooltips.months:''}
                        className={`${classesInputYearsMonths.inputCode}`}
                    />

                )}

            </Field>
        </div>
    )
};


export default ClaraInputYearsMonths;

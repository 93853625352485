import useQuery from "../../../../../../../hooks/custom/useQuery";
import _ from 'lodash'
export const GET_GROUP_COMPANIES = `query getGroupCompanies($startupId: ID!) {
    getGroupCompanies(startupId: $startupId) {
      id
      name
      kind
      isLocked
      jurisdiction
      isHoldingCompany
      formationData {
        nextGoal,
        isEngageConcierge
      }
      companyGoal
    }
  }`;
const useGetGroupCompanies=(variables,config)=>{
    const {data,loading}=useQuery(GET_GROUP_COMPANIES,{...variables},{...config})
    return {data:_.get(data,'getGroupCompanies'),loading}
}
export default useGetGroupCompanies
import * as Yup from 'yup';
import { default as React, useEffect, useMemo } from 'react';
import { clearAssistatText } from 'src/modules/generic/store/action';
import Button from 'src/modules/generic/components/Buttons/Button';
import Form from 'src/components/forms/Form/Form';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateWizardInfo from "src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardInfo";
import Typography from 'src/modules/generic/components/Texts/Typography';
import classModule from "./classes.module.scss"
import reactImage from "../../../../../../../../../../images/renewalCalendar.svg";
import { useDispatch } from 'react-redux';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';

function CloseDownCompanyStepForm() {

  const { next, context, cancel, prev } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  // TODO CHANGE AMOUNT AND CURRENCY
  const Paragraph = () => {
    return <div className={classModule.Paragraph}>
      <Typography component='p' variant='h5' fontWeight="bold">
        <span dangerouslySetInnerHTML={{ __html: translate('MODULES.RENEWALS_ADGM.CLOSE_DOWN_COMPANY.STEP_2.CONTENT_1') }}></span>
      </Typography>
      <Typography component='p' variant='h5'>
        <span dangerouslySetInnerHTML={{ __html: translate('MODULES.RENEWALS_ADGM.CLOSE_DOWN_COMPANY.STEP_2.CONTENT_2', { currency: "USD", price: "550", price2: "225" }) }}></span>
      </Typography>
      <Typography component='p' variant='h5'>
        <span dangerouslySetInnerHTML={{ __html: translate('MODULES.RENEWALS_ADGM.CLOSE_DOWN_COMPANY.STEP_2.CONTENT_3') }}></span>
      </Typography>
    </div>
  }

  const initialValues = useMemo(() => {
    return context?.CloseDownCompanyStepForm ?? {}
  }, [context]);

  const handleCancel = (values) => {
    cancel();
  }

  const handlePrev = (values) => {
    prev();
  }

  const handleSubmit = async (values) => {
    next(values)
  }

  useEffect(() => {
    return () => {
      dispatch(clearAssistatText())
    }
  }, [dispatch])

  const schema = Yup.object().shape({});

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizardInfo
        props={{
          title: translate('MODULES.RENEWALS_ADGM.CLOSE_DOWN_COMPANY.STEP_2.TITLE'),
          subTitle: translate('MODULES.RENEWALS_ADGM.CLOSE_DOWN_COMPANY.STEP_1.SUBTITLE', { companyName: context?.initialData?.company?.name ?? "Company Name"}),
          image: reactImage,
          isRenewalImage: true,
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          previous: <Button onClick={handlePrev}>Previous</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined} >Confirm</SubmitButton>,
        }}
      >
        <>
          <Paragraph />
        </>
      </TemplateWizardInfo>
    </Form>
  );
}

export default CloseDownCompanyStepForm;


import { useMemo } from 'react';
import useUploadBulkDocuments from 'src/modules/startup/Stakeholder/graphql/mutation/useUploadBulkDocuments';
import _ from 'lodash';
import useSession from "src/modules/session/hooks/useSession";
import moment from 'moment';
import { useParams } from "react-router-dom";

const useServices = ({ initialValues }) => {
  const { mutation: uploadDocuments } = useUploadBulkDocuments();
  const { startupId } = useSession();
  const params = useParams();

  return useMemo(() => ({

    initialData: async (context, event) => {
      return initialValues;
    },

    SaveStep: async (context, event) => {
      const documents = [];
      const docType = _.get(context, "UploadDocument.documentType", "");
      const file = _.get(context, "UploadDocument.officialDocument", "");
      documents.push({
        title: file.name,
        file: file,
        parties: [context?.initialData?.stakeholderId ?? params.id],
        status: "Uploaded",
        subCategories: ["constitutionalDocuments"],
      })
      switch (docType) {
        case "GOOD_STANDING":
          documents[0].executedOnDate = moment(_.get(context, 'UploadDocument.dateIssueCertificateOfGoodStanding')).format('YYYY-MM-DD');
          documents[0].type = "CERTIFICATE_OF_GOOD_STANDING";
          break;
        case "COMMERCIAL_LICENSE_DOCUMENT":
          documents[0].expirationDate = moment(_.get(context, 'UploadDocument.dateExpiryComercialLicense')).format('YYYY-MM-DD');
          documents[0].type = "COMMERCIAL_LICENSE_DOCUMENT";
          break;
        case "TRADE_LICENSE":
          documents[0].expirationDate = moment(_.get(context, 'UploadDocument.dateExpiryTradeLicense')).format('YYYY-MM-DD');
          documents[0].type = "TRADE_LICENSE";
          break;
        case "REGISTRY_EXTRACT":
          documents[0].executedOnDate = moment(_.get(context, 'UploadDocument.dateIssueRegistryExtract')).format('YYYY-MM-DD');
          documents[0].type = "REGISTRY_EXTRACT";
          break;
        default:
          break;
      }
      return await uploadDocuments({ variables: {startupId, documents: documents} })
    },

  }), [initialValues]);
}

export default useServices;

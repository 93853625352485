import React, { FC } from "react";
import _ from "lodash";
import DialogItem from "../../../../../modules/assistanceV4/components/DialogItem";
import Card from "../../../../../modules/generic/components/Contents/Card";
import ExpandContent from '../../../../../modules/generic/components/Contents/ExpandContent';
import IconTitle from '../../../../../modules/generic/components/IconTitle';
import AssistanceBlock from "../../../../../modules/generic/components/Contents/AssistanceBlock";
import Typography from "../../../../../modules/generic/components/Texts/Typography";
import CheckList from '../../../../../modules/generic/components/Lists/CheckList';

export interface MapTasksCardProps {
  /** Property open */
  open?: boolean,
  /** Property title */
  title?: string,
  /** Property titleIcon */
  titleIcon?: string,
  /** Property help */
  help?: string,
  /** Property tasks */
  tasks: Array<any>,
} 

const MapTasksCard: FC<MapTasksCardProps> = ({
  open = false,
  title = "",
  titleIcon = "Company",
  help = "",
  tasks = [],
  ...props
}:MapTasksCardProps) => {

  return (
    <DialogItem
      noIcon={true}
    >
      <Card>
        <ExpandContent
          preventIcon={true}
          open={open}
          header={
            <IconTitle
              title={title}
              removeBottomMargin={true}
              iconType={titleIcon}
            />
          }
          children={
            <AssistanceBlock
              addSpace={help ? true: false}
              children={
                <>
                  {help && (
                    <Typography
                      variant={"s"}
                      color={'black'}
                    >
                      {help}
                    </Typography>
                  )}
                  {_.map(tasks, (task, index) => {
                    const { name, status, onClick , params, onDelete} = task;
                    return (
                      <React.Fragment key={index}>
                        <CheckList 
                          variant={status}
                          title={name}
                          key={index}
                          onClick={onClick ? () => onClick(params) : null}
                          deleteClick={onDelete ? () => onDelete(params) : null}
                        />
                      </React.Fragment>
                    );
                  })}
                </>
              }
            />
          }
        ></ExpandContent>
      </Card>
    </DialogItem>
  );
};

export default MapTasksCard;

import GROUP_COMPANY from './../GroupCompany/constants'
const DOCUMENT_TYPE_GENERATE = [
  'IP_ASSIGNMENT',
  'SAFE',
  'CONVERTIBLE_NOTE',
  'ADVISOR_AGREEMENT',
  'CONSULTANT_AGREEMENT',
  'EMPLOYMENT_AGREEMENT_ADGM',
  'EMPLOYMENT_AGREEMENT_DIFC',
  'EMPLOYMENT_AGREEMENT_UK',
  'NDA_MUTUAL',
  'USA_NDA',
  'FOUNDER_SERVICES_AGREEMENT',
  'GRANT_AGREEMENT',
  'BOARD_RESOLUTION',
  'SHARE_INCENTIVE_PLAN_FAQ',
  'SHARE_INCENTIVE_PLAN',
  'WEBSITE_TERMS_OF_USE',
  'WEBSITE_PRIVACY_POLICY',
  'WEBSITE_ACCEPTABLE_USE_POLICY',
  'WEBSITE_COOKIE_NOTICE',
  'RESTRICTIVE_COVENANT_AGREEMENT',
  'FOUNDERS_TERM_SHEET',
  'FOUNDERS_AGREEMENT',
  'BOARD_RESOLUTION_CONVERTIBLE',
  'BOARD_RESOLUTION_CONVERTIBLE_LOAN_NOTE',
  'BOARD_RESOLUTION_CONVERTIBLE_SAFE',
  'BOARD_RESOLUTION_CONVERTIBLE_KISS',
  'BOARD_RESOLUTION_CONVERTIBLE_FIXED_EQUITY',
  'BOARD_RESOLUTION_CONVERTIBLE_OTHER',
  //CP-5321
  'USA_CAL_OFFER_LETTER_SALARY_EMPLOYEE'

]
const COMPANY_FORMATION_GOAL_ENUM = {
  CONCIERGE: 'CONCIERGE',
  BUY: 'BUY',
  DOWNLOAD: 'DOWNLOAD',

}
const COMPANY_FORMATION_GOALS = {
  GOAL_FORMATION_ADGM_BUY_REFER: 'GOAL_FORMATION_ADGM_BUY_REFER',
  GOAL_FORMATION_GENERIC_BUILD: 'GOAL_FORMATION_GENERIC_BUILD',
  GOAL_FORMATION_CAYMAN_REFER: 'GOAL_FORMATION_CAYMAN_REFER',
  GOAL_FORMATION_DELAWARE_BUY_REFER: '"GOAL_FORMATION_DELAWARE_BUY_REFER'
}

const COMPANY_ACCELERATORS_GOALS = {
  GOAL_ACCELERATOR: 'GOAL_ACCELERATOR'
}
const GOAL_GET_READY_FOR_INVESTMENT = {
  GOAL_GET_READY_FOR_INVESTMENT: 'GOAL_GET_READY_FOR_INVESTMENT'
}

export default {
  DOCUMENT_TYPE_GENERATE,
  GROUP_COMPANY,
  COMPANY_FORMATION_GOAL_ENUM,
  COMPANY_FORMATION_GOALS,
  COMPANY_ACCELERATORS_GOALS,
  GOAL_GET_READY_FOR_INVESTMENT
}

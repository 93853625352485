import React from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";
const SharesList = ({ go, ...props }) => {
  const params = useParams();



  return (
    <MicroFrontend id="MF_Share_Content_View" component={"./SharesView"} mf="capTable" params={{ go, ...props, ...params }} />
  );
};

export default SharesList;

import { createMachine } from "xstate";
const machine =
  /** @xstate-layout N4IgpgJg5mDOIC5QFVZgE4AUCuAjANgJYDGASmFIbAC7oCG1hA9gHYB02a6AYk+gLYBiAHIBRABoAVRKAAOTWIUasZIAB6IAjABYAHGwBMAVgBsukye1GjABgDsuzQYA0IAJ5bNATjZGvf3V1tL20TOxt-AF9I11QMHAISckoaemV2AGslXgFBTFJRADVVeUV01Q0EHX1jMwsrWwcnVw8EO01fLxMbG10AZh6m42jYrgSiMgoqWgZmTOy+ITEpEoUlOYrEPr6fTXCvO1s+0J7NFq0DAzYIgKCQsJuRkDisPAnk6bS5thowWT68gVikgQKV1ioQZVtrt9ocbMdujYzu5EHZtNcuj1+oNHMMniwmBA4KoXuMklNUrNWBwuDl+KsyhtIYhtC4UW0fDs7H0DLoutpNDy+iYnqS3uSUjN0mwstQ6QzwSxNggDALDKZdEYdF4Qto+v1zghHGw+dzefzBQZhaKxuLJpKvtTfv8FeVmQg9Vc9hFEQ4ukZdIagmwuTy+ZZLdaYs9bYl7Z8qUqQWC3aBKppA+yALQdbQ2PM2IzaVlWvMWaLRIA */
  createMachine({
    id: 'DeleteStakeholder',
    initial: 'DeleteVerification',
    states: {
      DeleteVerification: {
        invoke: {
          src: 'deleteVerification',
          id: 'deleteVerification',
          onDone: [
            {
              target: 'EvaluateVerification',
              actions: ['setContextAfterInvoke', 'setType']
            }
          ],
          onError: [
            {
              target: 'error',
            }
          ]
        }
      },
      EvaluateVerification: {
        always: [
          {
            cond: 'isManagedByClara',
            target: 'ManagedByClara'
          },
          {
            cond: 'isConnections',
            target: 'GetConnections'
          },
          {
            cond: 'isDelete',
            target: 'Delete'
          },
          {
            target: 'error'
          },
        ]
      },
      GetConnections: {
        invoke: {
          src: 'getConnections',
          id: 'getConnections',
          onDone: [
            {
              target: 'Connections',
              actions: ['setContextAfterInvoke']
            }
          ],
        }
      },
      ManagedByClara: {
        on: {
          NEXT: {
            target: 'finish'
          },
          CANCEL: {
            target: 'finish'
          }
        }
      },
      Connections: {
        on: {
          NEXT: {
            target: 'EvaluateIsInvalidate',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'finish'
          }
        }
      },
      EvaluateIsInvalidate: {
        always: [
          {
            cond: 'isInvalidate',
            target: 'InvalidateStakeholder'
          },
          {
            cond: 'isFinish',
            target: 'finish'
          },
          {
            target: 'error'
          },
        ]
      },
      Delete: {
        on: {
          NEXT: {
            target: 'DeleteStakeholder',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'finish'
          }
        }
      },
      DeleteStakeholder: {
        invoke: {
          src: 'deleteStakeholder',
          id: 'deleteStakeholder',
          onDone: [
            {
              target: 'finish',
              actions: ['setContextAfterInvoke']
            }
          ],
        }
      },
      InvalidateStakeholder: {
        invoke: {
          src: 'invalidateStakeholder',
          id: 'invalidateStakeholder',
          onDone: [
            {
              target: 'finish',
              actions: ['setContextAfterInvoke']
            }
          ],
        }
      },
      error: {
        entry: 'canceMachine',
        type: 'final'
      },
      finish: {
        entry: 'stopMachine',
        type: 'final'
      }
    }
  });
export default machine;

import * as Yup from 'yup';
import { default as React, useEffect, useMemo } from 'react';
import { addAssistantText, clearAssistatText } from 'src/modules/generic/store/action';
import Button from 'src/modules/generic/components/Buttons/Button';
import Form from 'src/components/forms/Form/Form';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateWizardContent from "src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardContent";
import Typography from 'src/modules/generic/components/Texts/Typography';
import classModule from "./classes.module.scss"
import { useDispatch } from 'react-redux';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { Box } from '@mui/material';
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import { HiddenFieldForm } from "src/components/forms/contents";

function CloseDownCompanyStepForm() {

  const { next, context, cancel, prev } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const Paragraph = () => {
    return <div className={classModule.Paragraph}>
      <Typography component='p' variant='h5'>
        <span dangerouslySetInnerHTML={{ __html: translate('MODULES.RENEWALS_ADGM.CLOSE_DOWN_COMPANY.STEP_1.CONTENT_1') }}></span>
      </Typography>
      <Typography component='p' variant='h5'>
        <span dangerouslySetInnerHTML={{ __html: translate('MODULES.RENEWALS_ADGM.CLOSE_DOWN_COMPANY.STEP_1.CONTENT_2') }}></span>
      </Typography>
    </div>
  }

  const initialValues = useMemo(() => {
    return context?.CloseDownCompanyStepForm ?? {}
  }, [context]);

  const handleCancel = (values) => {
    cancel();
  }

  const handlePrev = (values) => {
    prev();
  }

  const handleSubmit = async (values) => {
    next(values)
  }

  useEffect(() => {
    dispatch(addAssistantText(translate('MODULES.RENEWALS_ADGM.CLOSE_DOWN_COMPANY.STEP_1.TOOLTIP_1')))
    return () => {
      dispatch(clearAssistatText())
    }
  }, [dispatch])

  const schema = Yup.object().shape({
    accept: Yup.boolean()
      .oneOf([true], '')
      .required("Required"),
  });  

  const options = [
    {
      value: false,
      label: "No, the company does not meet the above requirements",
      componentProps:{
        variant: "h4"
      }
    },
    {
      value: true,
      label: "Yes, the company meets the above requirements",
      componentProps:{
        variant: "h4"
      }
    },
  ];  

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizardContent
        props={{
          title: translate('MODULES.RENEWALS_ADGM.CLOSE_DOWN_COMPANY.STEP_1.TITLE'),
          subTitle: translate('MODULES.RENEWALS_ADGM.CLOSE_DOWN_COMPANY.STEP_1.SUBTITLE', { companyName: context?.initialData?.company?.name ?? "Company Name"}),
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          previous: <Button onClick={handlePrev}>Previous</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined} >Next</SubmitButton>,
        }}
      >
        <>
          <Box
            sx={{
              paddingRight: "4.9231rem",
            }}
          >
            <Paragraph />
            <ControllerInput
              as={CheckboxButtonSimple}
              className={classModule.CheckboxButton}
              classNameButton={classModule.buttonCheckBox}
              name="accept"
              variant="col_1"
              size={"size2"}
              options={options}
              onChange={(value) => {}}
            />
            <HiddenFieldForm
              fieldsWatch={"accept"}
              conditionHideCallback={(value) => {
                return value === false;
              }}
            >
              <Box
                sx={{
                  paddingTop: "1.5rem",
                }}
              >
                <Typography component='p' variant='h4' fontWeight="semibold">
                  <span dangerouslySetInnerHTML={{ __html: "Please use the chat to contact one of our service agents for further help." }}></span>
                </Typography>
              </Box>
            </HiddenFieldForm>
          </Box>
        </>
        <div>&nbsp;</div>
      </TemplateWizardContent>
    </Form>
  );
}

export default CloseDownCompanyStepForm;


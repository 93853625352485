import { get } from 'lodash';
import { useMemo } from 'react';
import useGetStakeholdersGroupCompaniesLazy from '../../graphql/queries/useGetStakeholdersGroupCompaniesLazy';
const useServices = () => {
    const { data:userData, manualQuery  } = useGetStakeholdersGroupCompaniesLazy();

  return useMemo(() => ({

    getStakeholdersGroupCompanies: async (context, event) => {
      
      const stakeholdersGroupCompanies = await manualQuery()
      const result = {
      stakeholders:get(stakeholdersGroupCompanies.data,'stakeholderList',[]),
      groupCompanies:get(stakeholdersGroupCompanies.data,'getGroupCompanies',[])
      };
      return result;
      
    }
  }),[]);
}
export default useServices;
import React, {FC, useEffect} from 'react'
import IndividualOrCompanyStakeholderFormStep
    from "../../../core/wizards/steps/IndividualOrCompanyStakeholderFormStep/IndividualOrCompanyStakeholderFormStep";
import {StepProps} from "../../../../../generic/components/Wizard/Step";
import {addAssistantText, clearAssistatText} from "../../../../../generic/store/action";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import {useDispatch} from "react-redux";
const IndividualStakeholderFormStep:FC<StepProps>=({})=>{
    const {translate}=useTranslate()
    const dispatch=useDispatch()
    const handleInitModal=()=>{
        dispatch(clearAssistatText());
        dispatch(addAssistantText(translate('MODULES.ONBOARDINGROLES.FOUNDER.FIRST_STEP_1_TOOLTIPS'), "text"));
    }
    useEffect(()=>{
        handleInitModal()
        return () => {
            dispatch(clearAssistatText());
        }
    },[])
    return(<>
        <IndividualOrCompanyStakeholderFormStep isTherePrevious={false} role={'FOUNDER'} isIndividual={true}/>
    </>)
}
export default IndividualStakeholderFormStep
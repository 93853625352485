import React from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";

const EditAmendOptionPlan = ({ go, ...props }) => {
  const params = useParams();

  return (
    <MicroFrontend id="MF_OptionPlan_Amend_Edit" component={"./EditAmendOptionPlan"} mf="capTable" params={{ go, ...props, ...params }} />
  );
};

export default EditAmendOptionPlan;

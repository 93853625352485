import { useMemo } from 'react';
import useCreateManyImmediateParent from './services/useCreateManyImmediateParent';
import useSession from 'src/modules/session/hooks/useSession';
import useGetShareholdersBalance from './services/useGetShareholdersBalance';

const useServices = ({ initialValues }) => {
  const { startupId } = useSession();
  const { manualQuery: getShareholdersBalance } = useGetShareholdersBalance({ groupCompanyId: initialValues?.groupCompanyId, startupId }) as { manualQuery: Function };
  const { createManyImmediateParent } = useCreateManyImmediateParent() as { createManyImmediateParent: Function };

  return useMemo(() => ({
    setInitialData: async (context, event) => {
      const result = await getShareholdersBalance();
      const shareholders = result?.data?.getShareholdersBalance
      const immediateParents = shareholders.map(shareholder => {
        let resultMailingAddress = shareholder.mailingAddress || shareholder.address;
        resultMailingAddress = {
          city: resultMailingAddress?.city || '',
          state: resultMailingAddress?.state || '',
          street: resultMailingAddress?.street || '',
          zipCode: resultMailingAddress?.zipCode || '',
          country: resultMailingAddress?.country?.code || null,
        }
        return {
          immediateParentName: shareholder.fullName || shareholder.name || '',
          mailingAddressType: (shareholder.mailingAddress || shareholder.address)?.type || null,
          mailingAddress: resultMailingAddress,
          //TODO add taxJurisdiction and taxRegistrationNumber
        }
      });

      return { ...context, immediateParents: immediateParents, immediateParentIndex: 0 };
    },
    SaveStep: async (context, event) => {
      const immediateParents = context.immediateParents.map((immediateParent, index) => {

        return {
          name: immediateParent.immediateParentName,
          mailingAddress: {
            street: immediateParent.mailingAddress.street,
            country: immediateParent.mailingAddress.country,
            city: immediateParent.mailingAddress.city,
            zipCode: immediateParent.mailingAddress.zipCode || null,
            state: immediateParent.mailingAddress.state || null,
            type: immediateParent.mailingAddressType || null,
          },
          taxJurisdiction: immediateParent.taxJurisdiction || null,
          taxRegistrationNumber: immediateParent.taxRegistrationNumber || null,
        }
      })
      await createManyImmediateParent({ variables: { groupCompanyId: initialValues?.groupCompanyId, startupId, entitiesData: immediateParents } });
    }

  }), [getShareholdersBalance, createManyImmediateParent, startupId, initialValues?.groupCompanyId]);
}

export default useServices;

import { useEffect } from 'react';
import useMutation from "../../../custom/useMutation";


const mutation = `mutation activeUser(  $userId: String, $status: Boolean) {
    activeUser(
     userId:$userId
     status:$status
   )
 }`
const useActiveUser = (options) => {

  const [send, data] = useMutation(mutation, options);
  const sendForm = (id,status) => {
    return send({ userId: id, status });
  }
  useEffect(() => {

  }, []);

  return [sendForm, data];
}
export default useActiveUser;

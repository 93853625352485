import _ from 'lodash';
import React from 'react';
import Scrollbar from "../../../../../../components/generic/Scrollbar/Scrollbar";
import classesModule from './classes.module.scss';
import { TemplateComponent, TemplateFormComponentProps } from "../../../../../generic/templates/Modal";
import useLoading from "../../../../../generic/hooks/useLoading";
import Content from "./components/Content";
import Skeleton from "./components/Skeleton";

interface TemplateTitle {
  title: string
  subTitle?: string
  content: React.FC
  skeleton?: React.ReactNode
}
export interface TemplateWizardProps {
  forms: TemplateTitle[]
}

export interface TemplateWizardButtonsProps {
  previous?: React.ReactNode
  cancel?: React.ReactNode
  submit?: React.ReactNode
  anotherLeft?: Array<React.ReactNode>
  anotherRight?: Array<React.ReactNode>
}

/**
 - Template to Form
 **/

const TemplateWizard: TemplateComponent<TemplateWizardProps, TemplateWizardButtonsProps> = ({
  children,
  buttons = {},
  classNameBotonera,
  className,
  loading,
  props: { forms = [] }
}: TemplateFormComponentProps<TemplateWizardProps, TemplateWizardButtonsProps>) => {

  const { isLoading } = useLoading();

  return (
    <>
      <div className={`${classesModule.TemplateWizard} ${className}`}>
        {isLoading && (<>
          {_.map(forms, form => (<>
            <Skeleton
              title={form.title}
              subTitle={form.subTitle}
              skeleton={form.skeleton} />
          </>))}
        </>)}
        <div>
          <Scrollbar className={`${classesModule.Scrollbar}`}>
            {_.map(forms, form => (<>
              <Content title={form.title} subTitle={form.subTitle}>
                {React.createElement(form.content)}
              </Content>
            </>))}
          </Scrollbar>
          <div className={`${classesModule.Botonera} ${classNameBotonera}`}>
            <div className={classesModule.LeftButtons}>
              {buttons.previous}
              {_.map(buttons.anotherLeft, button => {
                return <React.Fragment>{button}</React.Fragment>
              })}
            </div>
            <div className={classesModule.RightButtons}>
              {_.map(buttons.anotherRight, button => {
                return <React.Fragment>{button}</React.Fragment>
              })}
              {buttons.cancel}
              {buttons.submit}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TemplateWizard

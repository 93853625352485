import useMutation from '../../../../../../../hooks/custom/useMutation'
import useSession from "src/modules/session/hooks/useSession";


export const UPSERT_SUBMIT_APPLICATION = `
  mutation submitAccelerator($startupId: ID) {
    submitAccelerator(startupId: $startupId) {
      name
    }
  }
`;

const useSubmitApplication
 = (props = {}) => {
  const { startupId } = useSession();
  const [mutation] = useMutation(
    UPSERT_SUBMIT_APPLICATION, {},
    { showSuccessNotification: false, ...props, variables:{startupId} },
  )
  return {mutation}
}

export default useSubmitApplication
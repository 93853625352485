import useGetDelete from './useGetDelete'
import { assign } from 'xstate';
import get from 'lodash/get'

const useActions = ()=>{
    const { getType } = useGetDelete()

    const setType = assign((context, event) => {
        const type = getType(context?.deleteVerification?.ableToDelete)
        return {...context , type};
      });
      const setContextAfterStep = assign((context, event) => {
        const values = get(event, 'payload.values');
        const solution = { ...context, ...values };
        return solution;
      })
    return {
       setType ,
       setContextAfterStep
    }
}
export default useActions
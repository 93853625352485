// import React, { useEffect } from "react";
import React from "react";
import Scrollbar from "src/components/generic/Scrollbar/Scrollbar";
// import useBusinessRules from "src/modules/clara/hooks/useBusinessRules";
import classesModule from "src/layouts/Clara/ClaraLayout.module.scss";
// import useModal from "src/modules/generic/hooks/useModal";
// import PayBlockModal from "src/modules/startup/PayBlockModal";

const LayoutContainer = ({
  scrollbar,
  children,
  className,
  showBreadCrumbs,
  fullMenu
}) => {
  // const { isDowngraded } = useBusinessRules();
  // const { openModal } = useModal();
  const classBread = showBreadCrumbs ? classesModule.contentWithBreadCrumbs : null;


  // useEffect(() => {
  //   if (openModal && fullMenu && isDowngraded && isDowngraded()) {
  //     openModal(
  //       PayBlockModal,
  //       "MODAL_BLOCKED_USER_IS_DOWNGRADED"
  //     );
  //   }
  // }, [fullMenu]);

  return (
    <React.Fragment>
      {scrollbar === false ? (
        <div
          className={`${classesModule.content} ${className} ${classBread}`}
        >
          {children}
        </div>
      ) : (
        <Scrollbar
          className={`${classesModule.content} ${className}  ${classBread}`}
        >
          {children}
        </Scrollbar>
      )}
    </React.Fragment>
  );
};

export default LayoutContainer;
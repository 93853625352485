import subModuleScoping from "./scoping"
import subModuleKyc from "./kyc"
import subModuleQuestionnaire from "./questionnaire"
import subModuleIncorporationDocuments from "./incorporationDocuments"
import subModuleFlows from "./flows";

const routes = [
  ...subModuleFlows,
  ...subModuleScoping,
  ...subModuleKyc,
  ...subModuleQuestionnaire,
  ...subModuleIncorporationDocuments,
];

export default routes;

import { gql, useQuery } from '@apollo/client';

const GET_CURRENCIES = gql`
query countryList($code:String, $name:String, $language:String!){
        countryList(code:$code, name:$name, language:$language){
        name
        code
        currency {currencyCode currencyName currencySymbol}
    }
}
`
function useGetCurrencies(options = {}) {
    return useQuery(GET_CURRENCIES, options);
}

export default useGetCurrencies;

import useMutation from "../../../../../hooks/custom/useMutation";

export const MUTATION_USER_CONTACT = `
mutation userAgreesToBeContacted($startupId: ID!, $jurisdictionType: jurisdictionTypes!) {
  userAgreesToBeContacted( startupId: $startupId, jurisdictionType: $jurisdictionType)
}
`

const useUserAgreesToBeContacted = (config, props) => {
  const [mutation, loading] = useMutation(MUTATION_USER_CONTACT, config, props)
  return { mutation, loading }
}

export default useUserAgreesToBeContacted
import { useMemo } from 'react';

const useGuards = () => {
  return useMemo(() => ({

    isEdit: (context) => {
      return context?.initialData?.isEdit === true
    },

    isNotEdit: (context) => {
      return context?.initialData?.isEdit === false
    },

  }), []);
}

export default useGuards;
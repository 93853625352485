import _ from "lodash";
import React, { useEffect, useState } from "react";
import useCategories from "../../../../../../../hooks/common/useCategories";
import useRoleAccess from "../../../../../../../modules/security/hooks/useRoleAccess";
import useBusinessRules from "../../../../../../../modules/clara/hooks/useBusinessRules";
import { Constants } from "../../../../../../../v1/utils";
import DocumentPreviewModalStyle from "../../../../../../generic/Documents/DocumentPreview/DocumentPreviewModalStyle";
import Search from "../../../../../../generic/Search/Search";
import Table from "../../../../../../Table/Table";
import { useDispatch } from 'react-redux';
import {
  Category,
  CategoryHeader,
  DocumentName,
  Parties,
  Status,
  StatusHeader,
  Subcategory,
  SubcategoryHeader,
  TypeHeader,
} from "./Components";
import classes from "./DocumentTab.module.scss";
import Button from "../../../../../../../modules/generic/components/Buttons/Button";
import EmptyState from "../../../../../../../modules/generic/components/EmptyState";
import useTranslate from "../../../../../../../modules/generic/hooks/useTranslate";

const DocumentTab = ({ data, lock, ...props }) => {
  const { categories } = useCategories("STARTUP");
  const [searchValue, setSearchValue] = useState("");
  const [documents, setDocuments] = useState([]);
  const [filterValue, setFilterValue] = useState(null);
  const [filterType, setFilterType] = useState(null);
  const [typesList, setTypesList] = useState(null);
  const [categoriesList, setCategoriesList] = useState(null);
  const [selectDocument, setSelectDocument] = useState(null);
  const { isStartSubscription } = useBusinessRules();
  const isDownload = useRoleAccess(Constants.ACTIONS.VIEW_PROFILE);
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const { isViewer } = useBusinessRules();

  const subcategoriesList = [
    { id: "constitutionalDocuments", label: "Constitutional Documents" },
    { id: "ownership", label: "Ownership" },
    { id: "jurisdiction", label: "Jurisdiction" },
    { id: "sharePurchaseAgreements", label: "Share Purchase Agreements" },
    { id: "shareOptionsAgreements", label: "Share Options Agreements" },
    { id: "subscriptionAgreements", label: "Subscription Agreements" },
    { id: "convertibleInstruments", label: "Convertible Instruments" },
    { id: "shareHoldersAgreements", label: "Shareholders Agreements" },
    { id: "intra_agreements", label: "Intra-group Agreements" },
    { id: "relatedParty", label: "Related Party Transactions" },
    { id: "founderServicesAgreements", label: "Founder Services Agreements" },
    { id: "employmentAgreements", label: "Employment Agreements" },
    { id: "consultingAgreements", label: "Consulting Agreements" },
    { id: "advisoryAgreements", label: "Advisor Agreements" },
    { id: "registeredIP", label: "Registered IP" },
    { id: "unregisteredIP", label: "Unregistered IP" },
    { id: "IPInfringement", label: "IP Infringement" },
    { id: "IPAssignment", label: "IP Assignment" },
    { id: "litigation", label: "Litigation" },
    { id: "materialAgreements", label: "Material Agreements" },
    { id: "realEstate", label: "Real Estate" },
    { id: "financialDebt", label: "Finance" },
    { id: "investorUpdates", label: "Investor Updates" },
  	{ id: "equitySupportingDocument", label: "Equity Supporting Document" },
    { id: "miscellaneous", label: "Miscellaneous" },
    { id: "miscellaneousIntellectualProperty", label: "Miscellaneous" },
    { id: "miscellaneousTeam", label: "Miscellaneous Team" },
  ];

  const getFilterValue = (val, type) => {
    setFilterValue(val);
    setFilterType(type);
  };

  useEffect(() => {
    setDocuments(data);
  }, [JSON.stringify(data)]);

  const getDocumentsPropsList = () => {
    let typeList = {
      types: [],
      subCats: [],
      status: [],
    };
    _.forEach(data, (doc) => {
      if (
        !_.find(typeList.types, (elem) => {
          return elem.id === _.get(doc, "type.code");
        })
      ) {
        typeList.types.push({
          id: _.get(doc, "type.code"),
          label: _.get(doc, "type.label"),
        });
      }
      if (
        !_.find(typeList.status, (elem) => {
          return elem.id === doc.status;
        })
      ) {
        typeList.status.push({ id: doc.status, label: doc.status });
      }
      _.forEach(subcategoriesList, (subCat) => {
        if (
          subCat.id == doc.subCategories[0] &&
          !_.find(typeList.subCats, (elemSub) => {
            return elemSub.id == subCat.id;
          })
        ) {
          typeList.subCats.push({ id: subCat.id, label: subCat.label });
        }
      });
    });
    setTypesList(typeList);
  };

  const getCategories = () => {
    let cats = [];
    if (categories) {
      _.forEach(data, (doc) => {
        _.forEach(categories, (cat) => {
          _.map(cat.subCategories, (subCat) => {
            if (subCat.key == doc.subCategories[0] && !cats.includes(cat.key)) {
              cats.push(cat.key);
            }
          });
        });
      });
      setCategoriesList(cats);
    }
  };

  useEffect(() => {
    if (data.length) {
      getDocumentsPropsList();
    }
  }, [data]);

  useEffect(() => {
    if (categories.length >= 1 && !categoriesList) {
      getCategories();
    }
  }, [categories]);

  useEffect(() => {
    if (data.length) {
      setDocuments(
        _.filter(data, ({ name }) => {
          if (name) {
            return name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0;
          } else {
            return "";
          }
        })
      );
    }
  }, [searchValue]);

  const handleViewDocumentDetails = (row) => {
    dispatch({ 
      type: 'GO_TO',
      page: Constants.PAGES[`viewDocument${_.upperFirst(_.toLower(row.original.kind))}`], params: { id: row.original.id, status: row.original.status }
    })
  }  

  useEffect(() => {
    if (data.length) {
      let filteredData = [];
      switch (filterType) {
        case "TYPE":
          filteredData = _.orderBy(
            data,
            [
              function (doc) {
                return doc.type.code == filterValue;
              },
            ],
            ["desc", "desc"]
          );
          return setDocuments(filteredData);
        case "CATEGORY":
          let subCategoryData = null;
          _.find(categories, (cat) => {
            if (cat.key == filterValue) {
              subCategoryData = _.map(cat.subCategories, (subCat) => {
                return subCat.key;
              });
            }
          });
          filteredData = _.orderBy(
            data,
            [
              function (doc) {
                return _.includes(subCategoryData, doc.subCategories[0]);
              },
            ],
            ["desc", "desc"]
          );
          return setDocuments(filteredData);
        case "SUBCATEGORY":
          filteredData = _.orderBy(
            data,
            [
              function (doc) {
                return doc.subCategories[0] == filterValue;
              },
            ],
            ["desc", "desc"]
          );
          return setDocuments(filteredData);
        case "STATUS":
          filteredData = _.orderBy(
            data,
            [
              function (doc) {
                return doc.status == filterValue;
              },
            ],
            ["desc", "desc"]
          );
          return setDocuments(filteredData);
      }
    }
  }, [filterValue]);

  const columns = [
    {
      Header: " ",
      columns: [
        {
          Header: () => <div className={classes.headerColumnTable}>Name</div>,
          accessor: "name",
          Cell: ({ row }) => {
            return (
              <div className={classes.coltext}>
                <DocumentName data={row} handleOpenViewer={setSelectDocument} onlyDetails/>
              </div>
            );
          },
        },
        {
          Header: () => (
            <div className={classes.headerColumnTable}>
              <TypeHeader
                typeData={typesList}
                documents={documents}
                handler={getFilterValue}
              />
            </div>
          ),
          accessor: "type",
          Cell: ({ row }) => {
            return (
              <div className={classes.coltext}>
                <span>{_.get(row, "original.type.label")}</span>
              </div>
            );
          },
        },
        {
          Header: () => (
            <div className={classes.headerColumnTable}>
              <CategoryHeader
                categoriesList={categoriesList}
                handler={getFilterValue}
              />
            </div>
          ),
          accessor: "category",
          Cell: ({ row, initialState, ...props }) => {
            return (
              <div className={classes.coltext}>
                <Category data={row} categories={initialState.categories} />
              </div>
            );
          },
        },
        {
          Header: () => (
            <div className={classes.headerColumnTable}>
              <SubcategoryHeader
                typesList={_.get(typesList, "subCats")}
                handler={getFilterValue}
              />
            </div>
          ),
          accessor: "subCategories",
          Cell: ({ row, initialState, ...props }) => {
            return (
              <div className={classes.coltext}>
                <Subcategory
                  data={row}
                  subCategoryKey={_.get(row, "original.subCategories[0]")}
                  categories={initialState.categories}
                />
              </div>
            );
          },
        },
        {
          Header: () => (
            <div className={classes.headerColumnTable}>Parties</div>
          ),
          accessor: "parties",
          Cell: ({ row, initialState, ...props }) => {
            return (
              <Parties
                data={row}
                groupCompanies={_.get(data, "original.groupCompanies")}
                stakeholders={_.get(data, "original.stakeholders")}
              />
            );
          },
        },
        {
          Header: () => (
            <div className={classes.headerColumnTable}>
              <StatusHeader
                typesList={_.get(typesList, "status")}
                handler={getFilterValue}
              />
            </div>
          ),
          accessor: "status",
          Cell: ({ row, initialState, ...props }) => {
            return (
              <div className={classes.statusText}>
                <Status data={row} />
              </div>
            );
          },
        },
        {
          Header: () => <div className={classes.headerColumnTable}></div>,
          accessor: "view",
          Cell: ({ row }) => {
            return (
              <div className={classes.viewContainer}>
                <Button variant={"card"} onClick={() => handleViewDocumentDetails(row)}>View</Button>
              </div>
            );
          },
        },
      ],
    },
  ];


  return (
    <>
      {data.length > 0 ? (
        <div className={`${classes.containerDocumentTab}`}>
          {data.length >= 10 && (
            <div className={classes.containerSearch}>
              <Search onChange={setSearchValue} />
            </div>
          )}
          <div className={classes.containerTable}>
            <Table
              columns={columns}
              data={documents}
              initialState={{ categories }}
            />
            {!!selectDocument && (
              <DocumentPreviewModalStyle
                title={selectDocument.name.split(".")[0]}
                showDownload={!isStartSubscription() && isDownload}
                showDetails={true}
                open={!!selectDocument}
                document={selectDocument}
                handleClose={() => setSelectDocument(null)}
              />
            )}
          </div>
        </div>
      ) : (
        <EmptyState 
          title={isViewer() || lock ? translate("DOCUMENTS_EMPTY_STATE_VIEWER") : translate("DOCUMENTS_EMPTY_STATE_USER")}
          iconType={lock ? "Locked" : "SAFE"}
        />
      )}
    </>
  );
};

export default DocumentTab;

import React, { useState } from "react";
import classesModule from "./Country.module.scss";
import _ from "lodash";
import CountryFlag from "../../../modules/generic/components/CountryFlag";

const Country = ({ children, className, ...props }) => {
  return (
    <React.Fragment>
      <div className={`${classesModule.Country} ${className}`}>
        <CountryFlag alpha3={_.get(props, "alpha3")} />
        <span>{_.get(props, "label")}</span>
      </div>
    </React.Fragment>
  );
};

export default Country;

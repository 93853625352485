import { useMemo } from 'react';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import UploadDocuments from '../steps/UploadDocuments';

const useSteps = () => {

  return useMemo(() => ({
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    UploadDocuments: {
      render: UploadDocuments,
    },
    SaveStep: {
      render: TemplateWizardSkeletonMachine,
    },

  }), []);
}

export default useSteps;


import { DepGraph } from "dependency-graph";
import _ from "lodash";
const orderGroupsCompanies = (gc) => {
    let groupCompanies = _.map(gc,(element)=>{
        return { 
            id: element.id, 
            name: element.name, 
            isHoldingCompany: element.isHoldingCompany,
            owns: _.map(element.childrens, (child)=>{
                return child.id
            })
        }
    })
    
    // let groupCompanies = []
    let holdingCompany = null

    for (let i in groupCompanies) {
        if (groupCompanies[i].isHoldingCompany) {
            holdingCompany = groupCompanies[i].id
        }
    }
    
    



    // STEP 2. Create dependency graph for entire startup
    let graph = new DepGraph({ circular: false })

    for (let i in groupCompanies) {
        graph.addNode(groupCompanies[i].id)
    }

    for (let i in groupCompanies) {
        if (groupCompanies[i].owns.length > 0) {
            for (let j in groupCompanies[i].owns) {
                graph.addDependency(groupCompanies[i].id, groupCompanies[i].owns[j])
            }
        }
    }

    // STEP 3. Cluster the companies based on: 1. Dependents. 2. Dependencies of Dependents. 3. Dependencies. 4. Dependents of Dependencies.
    let companies = {}
    for (const [key, value] of Object.entries(graph.nodes)) {
        companies[key] = {
            order: 0,
            level: 0,
            visited: false
        }
    }



    // CHECK FOR CYCLIC LINKS AND IF THEY EXIST, REMOVE CONNECTION LINES.
    for (const [key, value] of Object.entries(graph.nodes)) {
        try {
            graph.dependentsOf(key)
        } catch (error) {
            for (const [key, value] of Object.entries(graph.nodes)) {
                for (let i in groupCompanies) {
                    if (groupCompanies[i].owns.length > 0) {
                        for (let j in groupCompanies[i].owns) {
                            graph.removeDependency(groupCompanies[i].id, groupCompanies[i].owns[j])
                        }
                        groupCompanies[i].owns = []
                    }
                }
            }
        }
    }

    // CHECK IF A HOLDING COMPANY EXISTS AND IF SO, WHETHER OR NOT IT IS OWNED BY ANOTHER COMPANY. IF SO, REMOVE CONNECITON LINES.


    if (holdingCompany !== null) {
        if (graph.dependentsOf(holdingCompany).length > 0) {
            for (const [key, value] of Object.entries(graph.nodes)) {
                for (let i in groupCompanies) {
                    if (groupCompanies[i].owns.length > 0) {
                        for (let j in groupCompanies[i].owns) {
                            graph.removeDependency(groupCompanies[i].id, groupCompanies[i].owns[j])
                        }
                        groupCompanies[i].owns = []
                    }
                }
            }
        }
    }


    // CREATE CLUSTERS

    let clusters = []
    let n = 0

    for (const [key, value] of Object.entries(graph.nodes)) {
        if (!companies[key].visited) {
            clusters[n] = [key]
            const dependentsOf = graph.dependentsOf(key)
            const dependenciesOf = graph.dependenciesOf(key)

            for (let i in dependentsOf) {
                clusters[n].push(dependentsOf[i])
            }
            for (let i in dependenciesOf) {
                clusters[n].push(dependenciesOf[i])
            }
            n = n + 1
        }
    }
    const findCommonElement = (array1, array2) => {
        for (let i = 0; i < array1.length; i++) {
            for (let j = 0; j < array2.length; j++) {
                if (array1[i] === array2[j]) {
                    return true
                }
            }
        }
        return false
    }
    let arrayCounter = 1
    let newClusters = []
    while (arrayCounter < clusters.length) {
        if (findCommonElement(clusters[0], clusters[arrayCounter])) {
            let newCluster = clusters[0].concat(clusters[arrayCounter]);
            newCluster = [...new Set([...clusters[0], ...clusters[arrayCounter]])]
            clusters[0] = newCluster
            clusters.splice(arrayCounter, 1)
            arrayCounter = 1
        } else {
            if (arrayCounter == clusters.length - 1) {
                newClusters.push(clusters[0])
                clusters.splice(0, 1)
                arrayCounter = 1
            } else {
                arrayCounter++
            }
        }
    }
    newClusters.push(clusters[0])
    clusters = newClusters

    // STEP 5. Order Clusters based on size with exception of holding company being the first cluster.
    clusters.sort((a, b) => { return b.length - a.length })
    var holdCoCluster = []
    for (let i in clusters) {
        if (clusters[i].includes(holdingCompany)) {
            holdCoCluster = clusters[i]
        }
    }
    if (holdCoCluster.length > 0) {
        clusters.sort((x, y) => { return x == holdCoCluster ? -1 : y == holdCoCluster ? 1 : 0; });
    }

    // STEP 6. Create dependency graphs for each cluster and position.
    let finalMapPosition = {}
    // STEP 6.1. Create cluster graph.
    for (let i in clusters) {
        let clusterGroupCompanies = []
        for (let j in clusters[i]) {
            for (let k in groupCompanies) {
                if (clusters[i][j] == groupCompanies[k].id) {
                    clusterGroupCompanies.push(groupCompanies[k])
                }
            }
        }
        let clusterGraph = new DepGraph({ circular: false })
        for (let i in clusterGroupCompanies) {
            clusterGraph.addNode(clusterGroupCompanies[i].id)
        }
        for (let i in clusterGroupCompanies) {
            if (clusterGroupCompanies[i].owns.length > 0) {
                for (let j in clusterGroupCompanies[i].owns) {
                    clusterGraph.addDependency(clusterGroupCompanies[i].id, clusterGroupCompanies[i].owns[j])
                }
            }
        }

        // STEP 6.2 Set order of companies in cluster.
        const order = clusterGraph.overallOrder().reverse()
        for (let i in order) {
            if (order.length > 1) {
                order.sort((x, y) => { return x == holdingCompany ? -1 : y == holdingCompany ? 1 : 0; });
            }
        }
        // STEP 6.3 Set level of Companies.
        let clusterCompanies = {}
        for (const [key, value] of Object.entries(clusterGraph.nodes)) {
            clusterCompanies[key] = {
                level: 0
            }
        }
        for (const [key, value] of Object.entries(clusterGraph.nodes)) {
            if (holdingCompany) {
                if (key == holdingCompany) {
                    clusterCompanies[key] = {
                        level: 1
                    }
                } else {
                    clusterCompanies[key] = {
                        level: 2
                    }
                }
            } else {
                clusterCompanies[key] = {
                    level: 1
                }
            }
        }
        for (const [key, value] of Object.entries(clusterGraph.incomingEdges)) {
            if (value.length > 0) {
                clusterCompanies[key].level = 2
            }
        }
        for (const [key, value] of Object.entries(clusterGraph.incomingEdges)) {
            if (value.length > 0) {
                for (let i in value) {
                    if (clusterCompanies[value[i]].level >= 2) {
                        clusterCompanies[key].level = 3
                    }
                }
            }
        }
        // STEP 6.4 Create map position object for cluster.
        const mapPosition = {}
        for (let i in order) {
            for (const [key, value] of Object.entries(clusterCompanies)) {
                if (order[i] == key) {
                    mapPosition[key] = value
                }
            }
        }
        finalMapPosition = { ...finalMapPosition, ...mapPosition }
    }
   

    let lines = []
    for (const [key, value] of Object.entries(finalMapPosition)) {
      for (let i in groupCompanies) {
        if (groupCompanies[i].name == key) {
          if (groupCompanies[i].owns.length > 0) {
            for (let j in groupCompanies[i].owns) {
              lines.push([groupCompanies[i].name, groupCompanies[i].owns[j]])
            }
          }
        }
      }
    }
    
    
    let finalGC= {}
    _.forEach(finalMapPosition,(value,index)=>{
        
        if (!finalGC[index]){
            finalGC[index]=gc[index]
        }
    });
    
    return finalGC;
}
export default orderGroupsCompanies;
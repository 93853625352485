import { useMemo } from "react";
import TemplateWizardSkeletonMachine from "src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine";
// import TemplateWizardSkeletonMachine from "../../../../generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine";
import { SetPayData, PayInvoiceSuccess, PaySuccessOverdue, GotoTheMap } from "../steps";
import CheckoutStep from "../steps/CheckoutStep";
import PayTimeOverdue from "../steps/PayTimeOverdue";

const useSteps = () => {

  return useMemo(() => ({
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    SetPayData: {
      render: SetPayData,
    },
    CheckoutPayment: {
        render: TemplateWizardSkeletonMachine,
    },
    FinishPayment: {
      render: TemplateWizardSkeletonMachine,
    },
    UpdateStartup: {
      render: TemplateWizardSkeletonMachine,
    },
    CheckoutStep:{
      render: CheckoutStep,
    },
    PayInvoiceSuccess: {
      render: PayInvoiceSuccess,
    },
    PaySuccessOverdue: {
      render: PaySuccessOverdue,
    },
    PayTimeOverdue:{
      render: PayTimeOverdue
    },
    GotoTheMap: {
      render: GotoTheMap,
    },
   
  }), []);
}

export default useSteps;
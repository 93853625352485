import React from 'react';
import ACTIONS from "../../../../constants/actions";
import PAGES from "../../../../constants/pages";
import ClaraLayout from "../../../../layouts/Clara/ClaraLayout";
import Text from "./../../../../components/text/Text/Text";
import SubscriptionView from "../subscriptions/Subscriptions";
import AssistanceSubscriptionsV4 from "../../../../modules/assistanceV4/components/AssistanceFrame2/assistanceSubscriptions";

const moduleRouter = [
  {
    path: PAGES.subscriptions.path,
    exact: true,
    breadCrumbs: {
      reset: true,
      add: false,
      label: <Text uuid={"BREADCRUMS_SUBSCRIPTIONS"} />,
    },
    component: <SubscriptionView />,
    layout: ClaraLayout,
    propsLayout: {
      cantBack: true,
      startupMenu: true,
      userMenu: true,
      fullMenu: true,
      showAssistance: true,
      showBreadCrumbs: false,
      assistance: AssistanceSubscriptionsV4,
    },
    action: ACTIONS.VIEW_PROFILE,
  },
];
export default moduleRouter;


export const actions = {
    CHANGE_DEFAULT_PRODUCT: "CHANGE_DEFAULT_PRODUCT",
    

};

export const setProduct = (dispatch,product) => {
    dispatch({
        type: actions.CHANGE_DEFAULT_PRODUCT,
        product: product
    })
};



import React, {Component} from 'react';
import _ from "lodash";
import {Field} from "formik";
import classes from "../../../SteperForm.module.scss";
import ClaraUpload from "../../../../../components/Form/ClaraUpload/ClaraUpload";
import viewClasses from "../../../../../components/Forms/View/ViewForms.module.scss";
import IndividualStakeholder from "./individual/IndividualStakeholder";
import MultipleStakeholders from "./multiple/MultipleStakeholders";
import IndividualCompany from "./individual/InidividualCompany";

class Step5 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoading: true,
			shAuthorizedSignatoriesId: [],
			gcAuthorizedSignatoriesId: [],
			shAuthorizedSignatoriesEmailDisabled: [],
			gcAuthorizedSignatoriesEmailDisabled: []
		}
	};

	UNSAFE_componentWillMount() {
		const {params} = this.props;
		this.setDataForEmailValidation(params);

	}

	openFile() {
		this.props.openFile(this.props.file);
	}

	edit(step, values) {
		this.props.edit(step, values);
	}

	findStakeholder(id) {
		return this.props.stakeholderList.filter(stakeholder => id === stakeholder.id)[0] || {};
	};

	findGroupCompany(gcId) {
		return this.props.groupCompaniesList.filter(gc => gc.id === gcId)[0] || {};
	};

	renderAuthorizedSignatories(company) {
		const authorizedSignatories = company && company.authorizedSignatories ? company.authorizedSignatories : [];

	}

	findAgreementType(documentTypeCode) {
		return this.props.documentTypesList.filter(agr => agr.id === documentTypeCode)[0] || {}
	}

	setDataForEmailValidation = (params) => {
		const {partiesType} = this.props;

		if (partiesType.partyStakeholder) {
			if (_.get(partiesType, 'partyStakeholder.isIndividual', true)) {
				const stakeholderId = _.get(params, `${partiesType.partyStakeholder.party}.id`);

				this.setState({
					shAuthorizedSignatoriesId: [stakeholderId],
					shAuthorizedSignatoriesEmailDisabled: [this.isSigned(stakeholderId)],
				})
			} else {
				// multiple
				const shAuthorizedSignatories = _.get(params, `${partiesType.partyStakeholder.party}`);

				const stakeholdersId = [];
				const stakeholdersEmailDisabled = [];
				shAuthorizedSignatories.map(as => {
					stakeholdersId.push(as.id);
					stakeholdersEmailDisabled.push(this.isSigned(as.id));
				});

				this.setState({
					shAuthorizedSignatoriesId: stakeholdersId,
					shAuthorizedSignatoriesEmailDisabled: stakeholdersEmailDisabled,
				})

			}
		}

		if (partiesType.partyGroupCompany) {
			const gcAuthorizedSignatories = _.get(params, `${partiesType.partyGroupCompany.party}.authorizedSignatories`, []);

			const stakeholdersId = [];
			const stakeholdersEmailDisabled = [];
			gcAuthorizedSignatories.map(as => {
				stakeholdersId.push(as.id);
				stakeholdersEmailDisabled.push(this.isSigned(as.id));
			});

			this.setState({
				gcAuthorizedSignatoriesId: stakeholdersId,
				gcAuthorizedSignatoriesEmailDisabled: stakeholdersEmailDisabled
			})

		}

	};

	handleOnChangeEmailPartyStakeholder = (form, field, value, index) => {
		const {partiesType} = this.props;
		const {gcAuthorizedSignatoriesId, shAuthorizedSignatoriesId} = this.state;
		value = value.trim();

		const asIndex = gcAuthorizedSignatoriesId.findIndex(asId => asId === shAuthorizedSignatoriesId[index]);

		form.setFieldValue(field.name, value.toLowerCase());
		if (asIndex > -1) {
			const fieldName = `${partiesType.partyGroupCompany.party}.authorizedSignatories[${asIndex}].email`;
			form.setFieldValue(fieldName, value.toLowerCase());
		}
	};

	handleOnChangeEmailPartyGroupCompany = (form, field, value, index) => {
		const {partiesType} = this.props;
		const {gcAuthorizedSignatoriesId, shAuthorizedSignatoriesId} = this.state;
		value = value.trim();

		const asIndex = shAuthorizedSignatoriesId.findIndex(asId => asId === gcAuthorizedSignatoriesId[index]);

		form.setFieldValue(field.name, value);
		if (asIndex > -1) {
			let fieldNameEmail;
			let fieldNameValue;

			if (_.get(partiesType, 'partyStakeholder.isIndividual', true)) {
				// forms with one stakeholder
				fieldNameEmail = `${partiesType.partyStakeholder.party}.email`;
				fieldNameValue = `values.${partiesType.partyStakeholder.party}.isAnEntity`;
				if (_.get(form, fieldNameValue)) {
					const fieldNameAuthorizedSignatoryEmail = `${partiesType.partyStakeholder.party}.authorizedSignatory.email`;
					form.setFieldValue(fieldNameAuthorizedSignatoryEmail, value);
				}

			} else {
				// forms with multiple stakeholders
				fieldNameEmail = `${partiesType.partyStakeholder.party}[${asIndex}].email`;
				fieldNameValue = `values.${partiesType.partyStakeholder.party}[${asIndex}].isAnEntity`;
				if (_.get(form, fieldNameValue)) {
					const fieldNameAuthorizedSignatoryEmail = `${partiesType.partyStakeholder.party}[${asIndex}].authorizedSignatory.email`;
					form.setFieldValue(fieldNameAuthorizedSignatoryEmail, value);
				}

			}
			form.setFieldValue(fieldNameEmail, value);
		}
	};

	isSigned = (id) => {
		const {parties = []} = this.props;
		let isSigned = false;

		parties.some(party => {
			let signer;
			if (party.authorizedSignatory) {
				signer = party.authorizedSignatory.find(as => as.id === id)
			} else {
				if (party.id === id) {
					signer = party
				}
			}

			if (signer) {
				const recipientStatus = party.recipientStatus.find(el => el.email === signer.email);
				if (recipientStatus) {
					isSigned = !!recipientStatus.signed;
					return isSigned;
				}
			}
		});

		return isSigned;
	};

	render() {
		const {shAuthorizedSignatoriesEmailDisabled, gcAuthorizedSignatoriesEmailDisabled} = this.state;
		const {partiesType} = this.props;
		const {partyStakeholder = null, partyGroupCompany = null} = partiesType;
		const {isIndividual: isIndividualStakeholder = true} = partyStakeholder ? partyStakeholder : {};
		const {isIndividual: isIndividualGroupCompany = true} = partyGroupCompany ? partyGroupCompany : {};
		return (
			<React.Fragment>
				<Field>
					{({field, form}) => {
						// const partyGroupCompany = this.findGroupCompany(_.get(form.values,`${this.props.partiesType.partyGroupCompany.party}`) ? _.get(form.values,`${this.props.partiesType.partyGroupCompany.party}.id`): '');
						// const partyStakeholder = this.findStakeholder(_.get(form.values,`${this.props.partiesType.partyStakeholder.party}`)? _.get(form.values,`${this.props.partiesType.partyStakeholder.party}.id`) : '');
						const stakeholder = partyStakeholder ?
							isIndividualStakeholder ?
								this.findStakeholder(_.get(form.values, `${partyStakeholder.party}.id`)) :
								_.map(_.get(form.values, `${partyStakeholder.party}`, []), party => this.findStakeholder(party.id))
							: null;
						const groupCompany = partyGroupCompany ? this.findGroupCompany(_.get(form.values, `${partyGroupCompany.party}.id`)) : null;
						const agrPartyStakeholder = partyStakeholder ? _.get(form.values, `${partyStakeholder.party}`) : null;
						const agrPartyGroupCompany = partyGroupCompany ? _.get(form.values, `${partyGroupCompany.party}`) : null;
						const documentType = this.findAgreementType(form.values.documentType);

						if (agrPartyGroupCompany) {
							_.map(agrPartyGroupCompany.authorizedSignatories, signatory => ({
								email: signatory.email,
								fullName: signatory.fullName
							}));
						}

						return (
							<>
								<div className={classes.ZoneTitle}>
									{this.props.formType === 'uploadToEdit' ?
										<h1>Replace Generated Document with new/edited version</h1> : null}
									{this.props.formType === 'uploadToEdit' ?
										<h2>{documentType.label} between {stakeholder.fullName} and {groupCompany.name}</h2> :
										<h1>{documentType.label}</h1>}
									{this.props.formType === 'uploadToEdit' ?
										<div className={classes.editDocUploaderOnSignature}>
											<Field name={`file`}>
												{({field, form}) => (
													<ClaraUpload
														name={`filepond`}
														label='Document:'
														field={field}
														form={form}
													/>
												)}
											</Field>
										</div> : null}
									{this.props.formType === 'uploadToEdit' ? null : <p>{form.values.agreementName}</p>}
								</div>
								<hr className={classes.Separator}/>
								{agrPartyStakeholder ? (
										<div className={viewClasses.ContainerView}>
											<div className={classes.ZoneTitle}>
												<h1>{`${partyStakeholder.title}:`}</h1>
											</div>

											<div className={viewClasses.ContainerData}>
												{isIndividualStakeholder ?
													<IndividualStakeholder
														fieldName={partyStakeholder.party}
														partyStakeholder={agrPartyStakeholder}
														handleOnChangeEmailPartyStakeholder={this.handleOnChangeEmailPartyStakeholder}
														shAuthorizedSignatoriesEmailDisabled={shAuthorizedSignatoriesEmailDisabled}/>
													:
													<MultipleStakeholders
														fieldName={partyStakeholder.party}
														shAuthorizedSignatoriesEmailDisabled={shAuthorizedSignatoriesEmailDisabled}
														handleOnChangeEmailPartyStakeholder={this.handleOnChangeEmailPartyStakeholder}/>
												}
											</div>
										</div>)
									: null
								}
								<br/>
								<br/>
								{agrPartyGroupCompany && agrPartyGroupCompany.authorizedSignatories ?
									<div className={viewClasses.ContainerView}>
										<div className={classes.ZoneTitle}>
											<h1>{`${partyGroupCompany.title}:`}</h1>
										</div>
										<IndividualCompany
											fieldName={partyGroupCompany.party}
											gcAuthorizedSignatoriesEmailDisabled={gcAuthorizedSignatoriesEmailDisabled}
											handleOnChangeEmailPartyGroupCompany={this.handleOnChangeEmailPartyGroupCompany}
										/>
									</div>
									: null
								}
							</>)
						}
					}
				</Field>
			</React.Fragment>
		)
	}
}

export default Step5;

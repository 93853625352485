import React from "react";
import _ from "lodash";
import TextInput from "src/components/inputs/Text/TextInput";
import TextareaInput from "src/components/inputs/Textarea/TextareaInput";
import CountrySelector from "src/components/inputs/Select/CountrySelector/CountrySelector";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import useTranslate from "src/modules/generic/hooks/useTranslate";

const AddressInput = ({
  name,
  label = {},
  isRequired = false,
  handleChangeCountry = () => {},
  zipCodeOptional = false,
}) => {
  const { translate } = useTranslate();

  return (
    <>
      <TemplateLabel label={_.get(label, "country")} isRequired={isRequired}>
        <ControllerInput
          render={CountrySelector}
          name={`${name}.country`}
          placeholder={translate("MODULES.CLARA.ADDRESS_INPUT_COUNTRY")}
          showStrong={true}
          onChange={handleChangeCountry}
          clear
        />
      </TemplateLabel>
      <TemplateLabel label={_.get(label, "street")}>
        <ControllerInput
          render={TextareaInput}
          name={`${name}.street`}
          placeholder={translate("MODULES.CLARA.ADDRESS_INPUT_ADDRESS")}
        />
      </TemplateLabel>
      <TemplateLabel label={_.get(label, "city")}>
        <ControllerInput
          render={TextInput}
          name={`${name}.city`}
          placeholder={translate("MODULES.CLARA.ADDRESS_INPUT_CITY")}
        />
      </TemplateLabel>
      <TemplateLabel label={_.get(label, "state")}>
        <ControllerInput
          render={TextInput}
          name={`${name}.state`}
          placeholder={translate("MODULES.CLARA.ADDRESS_INPUT_STATE")}
          optional={true}
        />
      </TemplateLabel>
      <TemplateLabel label={_.get(label, "zipCode")}>
        <ControllerInput
          render={TextInput}
          name={`${name}.zipCode`}
          placeholder={translate("MODULES.CLARA.ADDRESS_INPUT_ZIP")}
          optional={zipCodeOptional}
        />
      </TemplateLabel>
    </>
  );
};

export default AddressInput;

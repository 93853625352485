import { useMemo } from 'react';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import SelectCertificate from '../steps/SelectCertificate';
import RegisterDirectorsAndMembers from '../steps/RegisterDirectorsAndMembers';
import CertificateOfIncumbency from '../steps/CertificateOfIncumbency';
import RegistryExtract from '../steps/RegistryExtract';

const useSteps = () => {

  return useMemo(() => ({
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    SelectCertificate: {
      render: SelectCertificate,
    },
    RegisterDirectorsAndMembers: {
      render: RegisterDirectorsAndMembers,
    },
    CertificateOfIncumbency: {
      render: CertificateOfIncumbency,
    },
    RegistryExtract: {
      render: RegistryExtract,
    },
    SaveStep: {
      render: TemplateWizardSkeletonMachine,
    },

  }), []);
}

export default useSteps;

import React, { FC } from "react";
import classesModules from "./classes.module.scss";
import { Stack, Box } from "@mui/material";
import FieldBanner from "src/modules/generic/components/FieldBanner";

export interface TemplateLabelProps {
  /**  Input to show in the rigth panel */
  children: React.ReactNode;
  /**  Label to show*/
  label?: string;
  /** variant label position */
  variant?: "top" | "center" | "banner" | "bannerLabel";
  /** Component  Class */
  className?: string;
  /*To show required fields */
  isRequired?: boolean;
  /*To show multiple lines */
  multilineTexts?: any;
  /** Is label in column */
  isColumn?: boolean;
  /** Show banner */
  showBanner?: boolean;
  /** Banner title */
  bannerTitle?: string;
  /** renderPosition */
  renderPosition?: "column" | "row";
  blackText?: boolean;
}

/**
- Template to show labels with inputs
**/
const TemplateLabel: FC<TemplateLabelProps> = ({
  children,
  label,
  variant = "center",
  className,
  isRequired,
  multilineTexts = [],
  isColumn,
  showBanner = false,
  bannerTitle,
  renderPosition = "column",
  blackText = false,
}: TemplateLabelProps) => {
  return (
    <div
      className={`${
        renderPosition === "column"
          ? classesModules.TemplateLabelColumn
          : classesModules.TemplateLabelRow
      } ${classesModules[variant]} ${classesModules["text-color-primary"]} ${
        isColumn ? classesModules.isColumn : null
      } ${className}`}
    >
      {multilineTexts.length === 0 ? (
        <>
          {variant === "bannerLabel" ? (
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={0}
              width={"100%"}
            >
              <Box>
                <label>
                  {" "}
                  {label}
                  {isRequired && (
                    <span className={classesModules.required}>*</span>
                  )}
                </label>
              </Box>
              {showBanner && <FieldBanner title={bannerTitle ?? ""} />}
            </Stack>
          ) : (
            <label>
              {" "}
              {label}
              {isRequired && (
                <span className={classesModules.required}>*</span>
              )}
            </label>
          )}
        </>
      ) : (
        <>
          {variant === "bannerLabel" ? (
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={0}
              width={"100%"}
            >
              <Box>
                <div className={`${classesModules.multilineLabel}`}>
                  {multilineTexts.map((line, index) => {
                    return (
                      <div key={index}>
                        {" "}
                        {line}
                        {index === multilineTexts.length - 1 &&
                          isRequired && (
                            <span className={classesModules.required}>*</span>
                          )}
                      </div>
                    );
                  })}
                </div>
              </Box>
              {showBanner && variant === "bannerLabel" && (
                <FieldBanner title={bannerTitle ?? ""} />
              )}
            </Stack>
          ) : (
            <>
              {variant === "center" ? (
                <div className={`${classesModules.multilineLabelCenter} ${blackText && classesModules.multilineLabelCenterBlack}`}>
                  {multilineTexts.map((line, index) => {
                    return (
                      <div key={index}>
                        <span dangerouslySetInnerHTML={{ __html: line }}></span>
                        {index === multilineTexts.length - 1 && isRequired && (
                          <span className={classesModules.required}>*</span>
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className={`${classesModules.multilineLabel}`}>
                  {multilineTexts.map((line, index) => {
                    return (
                      <div key={index}>
                        {" "}
                        {line}
                        {index === multilineTexts.length - 1 && isRequired && (
                          <span className={classesModules.required}>*</span>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </>
          )}
        </>
      )}
      <Box
        sx={{
          alignItems: variant === "bannerLabel" ? "flex-start" : "center",
        }}
      >
        {variant === "banner" ? (
          <>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={0}
              width={"100%"}
            >
              {children}
              {showBanner && <FieldBanner title={bannerTitle ?? ""} />}
            </Stack>
          </>
        ) : (
          <>{children}</>
        )}
      </Box>
    </div>
  );
};

export default TemplateLabel;

import _ from "lodash";
import {Constants, helper} from "../../utils";
import * as Yup from "yup";

Yup.addMethod(Yup.object, 'isAgreementValidation', function (message, mapper = a => a) {
  return this.test('isAgreementValidation', message, function (values) {
    const agreementsDirectors= _.get(values,"agreementsDirectors",[]);
    if (agreementsDirectors.length==0){
      return false
    }
    if (agreementsDirectors.length==1){
      return _.get(values,"firstName",false) && _.get(values,"lastName",false) && _.get(values,"email",false);
    }
    return true;
  });
});
Yup.addMethod(Yup.object, 'isSPVIntelectualProperyCheck', function (message, mapper = a => a) {
  return this.test('isSPVIntelectualProperyCheck', message, function (values) {
    if (values.intellectualProperty=="TRUE"){
      return values.isRevenueGenerating || values.isProtection 
    }
    return true;
  });
});
Yup.addMethod(Yup.object, 'isAllCompleate', function (message, mapper = a => a) {
  return this.test('isAllCompleate', message, function (values) {
    return values.isComplete
  });
});
Yup.addMethod(Yup.object, 'isAllApproved', function (message, mapper = a => a) {
  return this.test('isAllApproved', message, function (values) {
   
    return values.isAllApproved
  });
});

Yup.addMethod(Yup.object, 'totalSharesIssuesValidation', function (message, mapper = a => a) {
  return this.test('totalSharesIssuesValidation', message, function (values) {
    const max = values.sharesAuthorized;
    let total = 0;

    _.forEach(values.shareholders, (element) => {
      total += _.get(element, "shares", 0);
    })
    return (max - total) >= 0;
  });
});
Yup.addMethod(Yup.object, 'getScopingHoldingCompanySPV', function (message, mapper = a => a) {
  return this.test('getScopingHoldingCompanySPV', message, function (values) {
    const { alreadyIncorporated, otherAsset, intellectualProperty, toBeIncorporated } = values;
    return alreadyIncorporated || otherAsset || intellectualProperty || toBeIncorporated;
  });
});
Yup.addMethod(Yup.object, 'requiredOption', function (message, mapper = a => a) {
  return this.test('requiredOption', message, function (values) {
    let hasValue = false;
    _.forEach(values, (v, k) => {
      if (v) {
        hasValue = true;
      }
    })
    return hasValue;
  })
});
Yup.addMethod(Yup.object, 'primaryAssetValidation', function (message, mapper = a => a) {
  return this.test('primaryAssetValidation', message, function (values) {
    let hasValue = false;
    _.forEach(values, (v, k) => {
      if (v==k) {
        hasValue = true;
      }
    })
    return hasValue;
  })
});
export default {
  QUESTIONARY_AGREEMENT_DIRECTORS:{
    validation: Yup.object().isAgreementValidation().shape({
      agreementsDirectors: Yup.array(),
      email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, { message: "Invalid Email", excludeEmptyString: true }).optional()
    })
  },
  ADGM_APPROVAL_DOCUMENTS:{
    validation: Yup.object().isAllApproved().shape({
      isAllApproved: Yup.bool(),
    })
  },
  KYC_ENTITY_PEP_INDIVIDUALS:{
    validation: Yup.object().shape({
      aditionalSignatures: Yup.array().required().min(1)
    })
  },
  KYC_ENTITY_EDIT_MEMBERS: {
    validation: Yup.object().isAllCompleate().shape({
      isComplete: Yup.bool(),
      shareholders: Yup.array(),
      directors: Yup.array(),
    })
  },
  SCOPING_HOLDING_COMPANY_SPV: {
    validation: Yup.object().isSPVIntelectualProperyCheck().shape({
      alreadyIncorporated: Yup.string().nullable().typeError(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED")),
      "alreadyIncorporatedText": Yup.string().nullable().when(['alreadyIncorporated'], {
        is: "TRUE",
        then: Yup.string().nullable().required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED"))
      }),
      otherAsset: Yup.string().nullable().typeError(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED")),
      "otherAssetText": Yup.string().nullable().when(['otherAsset'], {
        is: "TRUE",
        then: Yup.string().nullable().required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED"))
      }),
      intellectualProperty: Yup.string().nullable().typeError(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED")),
      "intellectualPropertyText": Yup.string().nullable().when(['intellectualProperty'], {
        is: "TRUE",
        then: Yup.string().nullable().required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED"))
      }),
      toBeIncorporated: Yup.string().nullable().typeError(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED")),
      "toBeIncorporatedText": Yup.string().nullable().when(['toBeIncorporated'], {
        is: "TRUE",
        then: Yup.string().nullable().required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED"))
      }),
    }).getScopingHoldingCompanySPV(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED"))
  },
  QUESTIONARY_SPV_PRIMARY_ASSET: {
    validation: Yup.object().shape({
      primaryAsset: Yup.object().primaryAssetValidation().required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED")).primaryAssetValidation(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED")),
      "countriesList": Yup.string().nullable().when(['primaryAsset.SHARES_COMPANY'], {
        is: "SHARES_COMPANY",
        then: Yup.string().nullable().required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED"))
      }),
      "servicesList": Yup.string().nullable().when(['primaryAsset.SHARES_COMPANY'], {
        is: "SHARES_COMPANY",
        then: Yup.string().nullable().required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED"))
      }),
      "other": Yup.string().nullable().when(['primaryAsset.OTHER'], {
        is: "OTHER",
        then: Yup.string().nullable().required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED"))
      }),
      "adgm": Yup.bool().nullable().when(['primaryAsset.SHARES_COMPANY'], {
        is: "SHARES_COMPANY",
        then: Yup.bool().required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED"))
      }),
      "intellectualPropertyFile": Yup.object().nullable().when(['primaryAsset.INTELLECTUAL_PROPERTY'], {
        is: "INTELLECTUAL_PROPERTY",
        then: Yup.object().required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED"))
      }),
      alreadyCompaniesIds: Yup.array().nullable().when(['primaryAsset.SHARES_ALREADY_COMPANY'], {
        is: "SHARES_ALREADY_COMPANY",
        then: Yup.array().of(Yup.string()).required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED")).min(1),
        otherwaise: Yup.bool().nullable()
      }),
    })
  },
  
  QUESTIONARY_ARTICLES_ASSOCIATION: {
    validation: Yup.object().shape({
      articleAsociation: Yup.bool(),
      "likeUpload": Yup.bool().nullable().when(['articleAsociation'], {
        is: false,
        then: Yup.bool().nullable().oneOf([true, false], helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED")),
        otherwaise: Yup.bool().nullable()
      }),
      "file": Yup.object().nullable().when(['likeUpload'], (value) => {
        if (value === true) {
          return Yup.object().nullable().required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED"));
        }
        return Yup.object().nullable();
      }),
    })
  },
  QUESTIONARY_SHAREHOLDER_DIRECTORS: {
    validation: Yup.object().shape({
      shareholders: Yup.array().of(Yup.string()).required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED")).min(1),
      directors: Yup.array().of(Yup.string()).required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED")).min(1)
    })
  },
  QUESTIONARY_AUTHORIZED_SIGNATORIES: {
    validation: Yup.object().shape({
      UAEOrGCCNationalSelection: Yup.array().of(Yup.string()).required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED")).min(1),
    })
  },
  QUESTIONARY_SHARE_CAPITAL: {
    validation: Yup.object().shape({
      shareCapital: Yup.string().required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED")),
      "sharesAuthorized": Yup.number().when(['shareCapital'], {
        is: "CUSTOM",
        then: Yup.number().typeError(helper.getTranslateTextByKey("VALIDATION_CHAT_NUMBER")).required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED")).min(0, helper.getTranslateTextByKey("VALIDATION_CHAT_MIN_NUMBER", { min: 0 }))
      }),
      "shareNominalPrice": Yup.number().when(['shareCapital'], {
        is: "CUSTOM",
        then: Yup.number().typeError(helper.getTranslateTextByKey("VALIDATION_CHAT_NUMBER")).required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED")).min(0, helper.getTranslateTextByKey("VALIDATION_CHAT_MIN_NUMBER", { min: 0 }))
      }),
    })
  },
  QUESTIONARY_SHARES_ISSUES: {
    validation: Yup.object().shape({
      shareholders: Yup.array().of(
        Yup.object().shape({
          shares: Yup.number().typeError(helper.getTranslateTextByKey("VALIDATION_CHAT_NUMBER")).min(0, helper.getTranslateTextByKey("VALIDATION_CHAT_MIN_NUMBER", { min: 0 })).required('Required'),
          paid: Yup.string().nullable().required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED")),
          entityId: Yup.string().required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED")),
        })
      ).required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED")).min(1)
    })
  },
  QUESTIONARY_DATA_PROTECTION: {
    validation: Yup.object().shape({
      dataProtection: Yup.array().of(Yup.string()).required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED")).min(1),
    })
  },
  QUESTIONARY_ADDITIONAL_SIGNATURE: {
    validation: Yup.object().shape({
      aditionalSignatures: Yup.array().of(Yup.string()).required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED")).min(1),
    })
  },
  QUESTIONARY_BENEFICIAL_OWNERS   : {
    validation: Yup.object().shape({
      beneficialsOwners: Yup.array().of(Yup.string()).required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED")).min(1),
    })
  },
  QUESTIONARY_COMPANY_DETAILS: {
    validation: Yup.object().shape({
      hasSpecialMeaning: Yup.bool().required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED")),
      includeArabicCompanyName: Yup.bool().required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED")),
      hasAffiliated: Yup.bool().required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED")),
      specialMeaning: Yup.string().nullable().when(['hasSpecialMeaning'], {
        is: true,
        then: Yup.string().nullable().required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED"))
      }),
      arabicCompanyNames: Yup.string().nullable().when(['includeArabicCompanyName'], {
        is: true,
        then: Yup.string().nullable().required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED"))
      }),
      affiliatedLegalName: Yup.string().nullable().when(['hasAffiliated'], {
        is: true,
        then: Yup.string().nullable().required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED"))
      }),
      affiliatedFile: Yup.object().nullable().when(['hasAffiliated'], {
        is: true,
        then: Yup.object().nullable().required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED"))
      }),
    })
  },
  COMPANY_NAME: {
    "SPV": {
      actions: [
        {
          key: "holdingName",
          options: [
            { 'label': "QUESTIONARY_FORM_COMPANY_NAME_HOLDING_TEXT", 'key': helper.getTranslateTextByKey("QUESTIONARY_FORM_COMPANY_NAME_HOLDING"), 'value': helper.getTranslateTextByKey("QUESTIONARY_FORM_COMPANY_NAME_HOLDING") },
            { 'label': "QUESTIONARY_FORM_COMPANY_NAME_HOLDINGS_TEXT", 'key': helper.getTranslateTextByKey("QUESTIONARY_FORM_COMPANY_NAME_HOLDINGS"), 'value': helper.getTranslateTextByKey("QUESTIONARY_FORM_COMPANY_NAME_HOLDINGS") },
            { 'label': "QUESTIONARY_FORM_COMPANY_NAME_SPV_TEXT", 'key': helper.getTranslateTextByKey("QUESTIONARY_FORM_COMPANY_NAME_SPV"), 'value': helper.getTranslateTextByKey("QUESTIONARY_FORM_COMPANY_NAME_SPV") },
          ]
        },
        {
          key: "limitedName",
          need: "holdingName",
          options: [
            { 'label': "QUESTIONARY_FORM_COMPANY_NAME_LIMITED_TEXT", 'key': helper.getTranslateTextByKey("QUESTIONARY_FORM_COMPANY_NAME_LIMITED"), 'value': helper.getTranslateTextByKey("QUESTIONARY_FORM_COMPANY_NAME_LIMITED") },
            { 'label': "QUESTIONARY_FORM_COMPANY_NAME_LTD_TEXT", 'key': helper.getTranslateTextByKey("QUESTIONARY_FORM_COMPANY_NAME_LTD"), 'value': helper.getTranslateTextByKey("QUESTIONARY_FORM_COMPANY_NAME_LTD") },
            { 'label': "QUESTIONARY_FORM_COMPANY_NAME_LTD_LOWER_TEXT", 'key': helper.getTranslateTextByKey("QUESTIONARY_FORM_COMPANY_NAME_LTD_LOWER"), 'value': helper.getTranslateTextByKey("QUESTIONARY_FORM_COMPANY_NAME_LTD_LOWER") },
          ]
        }
      ],
      validation: Yup.object().shape({
        companyName: Yup.string().required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED")).max(64, helper.getTranslateTextByKey("VALIDATION_CHAT_MAX_LENGTH", { max: 64 })),
        limitedName: Yup.string().required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED")),
        holdingName: Yup.string().required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED"))
      })
    },
    "TECH": {
      actions: [
        {
          key: "limitedName",
          options: [
            { 'label': "QUESTIONARY_FORM_COMPANY_NAME_LIMITED_TEXT", 'key': helper.getTranslateTextByKey("QUESTIONARY_FORM_COMPANY_NAME_LIMITED"), 'value': helper.getTranslateTextByKey("QUESTIONARY_FORM_COMPANY_NAME_LIMITED") },
            { 'label': "QUESTIONARY_FORM_COMPANY_NAME_LTD_TEXT", 'key': helper.getTranslateTextByKey("QUESTIONARY_FORM_COMPANY_NAME_LTD"), 'value': helper.getTranslateTextByKey("QUESTIONARY_FORM_COMPANY_NAME_LTD") },
            { 'label': "QUESTIONARY_FORM_COMPANY_NAME_LTD_LOWER_TEXT", 'key': helper.getTranslateTextByKey("QUESTIONARY_FORM_COMPANY_NAME_LTD_LOWER"), 'value': helper.getTranslateTextByKey("QUESTIONARY_FORM_COMPANY_NAME_LTD_LOWER") },
          ]
        }
      ],
      validation: Yup.object().shape({
        companyName: Yup.string().required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED")).max(64, helper.getTranslateTextByKey("VALIDATION_CHAT_MAX_LENGTH", { max: 64 })),
        limitedName: Yup.string().required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED")),
      })
    },
    "OPERATING": {
      actions: [
        {
          key: "limitedName",
          options: [
            { 'label': "QUESTIONARY_FORM_COMPANY_NAME_LIMITED_TEXT", 'key': helper.getTranslateTextByKey("QUESTIONARY_FORM_COMPANY_NAME_LIMITED"), 'value': helper.getTranslateTextByKey("QUESTIONARY_FORM_COMPANY_NAME_LIMITED") },
            { 'label': "QUESTIONARY_FORM_COMPANY_NAME_LTD_TEXT", 'key': helper.getTranslateTextByKey("QUESTIONARY_FORM_COMPANY_NAME_LTD"), 'value': helper.getTranslateTextByKey("QUESTIONARY_FORM_COMPANY_NAME_LTD") },
            { 'label': "QUESTIONARY_FORM_COMPANY_NAME_LTD_LOWER_TEXT", 'key': helper.getTranslateTextByKey("QUESTIONARY_FORM_COMPANY_NAME_LTD_LOWER"), 'value': helper.getTranslateTextByKey("QUESTIONARY_FORM_COMPANY_NAME_LTD_LOWER") },
          ]
        }
      ],
      validation: Yup.object().shape({
        companyName: Yup.string().required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED")).max(64, helper.getTranslateTextByKey("VALIDATION_CHAT_MAX_LENGTH", { max: 64 })),
        limitedName: Yup.string().required(helper.getTranslateTextByKey("VALIDATION_CHAT_FIELD_REQUIRED")),
      })
    },

  }
}

import React from "react";
import * as Yup from "yup";
import Form from "../../../../../../components/forms/Form/Form";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import ControllerInput from "../../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import TemplateLabel from "../../../../../generic/components/Inputs/LabelInput"
import SubmitButton from "../../../../../generic/components/Buttons/SubmitButton";
import FormProps from "../../../../../generic/forms/interfaces/FormProps";
import TemplateFormDefault from "../../../../../generic/templates/Modal";
import TextInput from "../../../../../../components/inputs/Text/TextInput";
import CountrySelector from "../../../../../../components/inputs/Select/CountrySelector/CountrySelector";

const schema = Yup.object().shape({
  country: Yup.string().required("This field is required").nullable(),
  street: Yup.string().required("This field is required").nullable(),
  city: Yup.string().required("This field is required").nullable(),
  state: Yup.string().nullable(),
})

interface FormAccountingRecordsProps extends FormProps {

}

const FormAccountingRecords: React.FC<FormAccountingRecordsProps> = ({
  initialValues = {},
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
}) => {

  const { translate } = useTranslate();
  const handleSubmit = async (values) => {
    onCompleteSubmit && (await onCompleteSubmit(values));
  };

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
      onlySendDirty={false}
    >
      <Template
        buttons={{
          ...buttons,
          submit: <SubmitButton {...buttonSubmitProps}>{translate('MODULES.STAKEHOLDER.ADGM.ADD')}</SubmitButton>,
        }}
        props={propsTemplate}
      >
        <TemplateLabel
          label={translate('MODULES.STAKEHOLDER.ADGM.ADDRESS')}

          isRequired
        >
          <ControllerInput
            render={CountrySelector}
            name='country'
            defaultlabel={''}
            placeholder={translate('MODULES.STAKEHOLDER.ADGM.COUNTRY')}
          />
        </TemplateLabel>
        <TemplateLabel
          label={translate('MODULES.STAKEHOLDER.ADGM.STREET')}
          
          isRequired
        >
          <ControllerInput
            render={TextInput}
            name='street'
            defaultlabel={''}
            placeholder={translate('MODULES.STAKEHOLDER.ADGM.STREET')}
          />
        </TemplateLabel>
        <TemplateLabel
          label={translate('MODULES.STAKEHOLDER.ADGM.CITY')}
          
          isRequired
        >
          <ControllerInput
            render={TextInput}
            name='city'
            defaultlabel={''}
            placeholder={translate('MODULES.STAKEHOLDER.ADGM.CITY')}
          />
        </TemplateLabel>
        <TemplateLabel
          label={translate('MODULES.STAKEHOLDER.ADGM.STATE_REGION')}
          
          isRequired
        >
          <ControllerInput
            render={TextInput}
            name='state'
            defaultlabel={''}
            placeholder={translate('MODULES.STAKEHOLDER.ADGM.STATE_REGION')}
          />
        </TemplateLabel>
        <TemplateLabel
          label={translate('MODULES.STAKEHOLDER.ADGM.ZIP_POST')}
        >
          <ControllerInput
            render={TextInput}
            name='zipCode'
            defaultlabel={''}
            placeholder={translate('MODULES.STAKEHOLDER.ADGM.ZIP_POST')}
          />
        </TemplateLabel>
      </Template>
    </Form>
  );
};

export default FormAccountingRecords;

import {Constants} from "../../../../../../../v1/utils";
const useGetTooltipByDocumentType=(documentType)=> {
    // console.log(documentType);
    switch (documentType) {
        case "FOUNDERS_TERM_SHEET":
            return '';
        case "USA_OPTION_GRANT_NOTICE":
            return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.USA_OPTION_GRANT_NOTICE.TOOLTIP    
        case "USA_BOARD_APPROVING_OPTION_GRANT":
            return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.USA_BOARD_APPROVING_OPTION_GRANT.TOOLTIP    
            case "USA_CONSULTING_AGREEMENT":
                return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.USA_CONSULTING_AGREEMENT.TOOLTIP;        
        case "USA_STRATEGIC_ADVISORY_BOARD_LETTER":
            return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.USA_STRATEGIC_ADVISORY_BOARD_LETTER.TOOLTIP;    
        case "IP_ASSIGNMENT":
            return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.IP_ASSIGNMENT.TOOLTIP;
        case "SAFE":
            return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.SAFE.TOOLTIP;
        case "CONVERTIBLE_NOTE":
            return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.CONVERTIBLE_NOTE.TOOLTIP;
		case "EMPLOYMENT_AGREEMENT_ADGM":
			return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.EMPLOYMENT_AGREEMENT_ADGM.TOOLTIP;
        case "EMPLOYMENT_AGREEMENT_DIFC":
            return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.EMPLOYMENT_AGREEMENT_DIFC.TOOLTIP;
		case "EMPLOYMENT_AGREEMENT_UK":
			return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.EMPLOYMENT_AGREEMENT_UK.TOOLTIP;
        case "GRANT_AGREEMENT":
            return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.GRANT_AGREEMENT.TOOLTIP;
        case "FOUNDER_SERVICES_AGREEMENT":
            return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.FOUNDER_SERVICES_AGREEMENT.TOOLTIP;
        case "SHARE_INCENTIVE_PLAN":
        case "BOARD_RESOLUTION":
        case "SHARE_INCENTIVE_PLAN_FAQ":
            return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.SHARE_INCENTIVE_PLAN.TOOLTIP;
        case "ADVISOR_AGREEMENT":
            return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.ADVISOR_AGREEMENT.TOOLTIP;
        case "CONSULTANT_AGREEMENT":
            return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.CONSULTANT_AGREEMENT.TOOLTIP;
        case "KISS":
            return '';
        case "RESTRICTIVE_COVENANT_AGREEMENT":
            return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.RESTRICTIVE_COVENANT_AGREEMENT.TOOLTIP;
        case "NDA_MUTUAL":
            return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.NDA_MUTUAL.TOOLTIP;
        case "FOUNDERS_AGREEMENT":
            return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.FOUNDERS_AGREEMENT.TOOLTIP
        case "WEBSITE_TERMS_OF_USE":
            return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.WEBSITE_TERMS_OF_USE.TOOLTIP
        case "WEBSITE_PRIVACY_POLICY":
            return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.WEBSITE_PRIVACY_POLICY.TOOLTIP
        case "WEBSITE_ACCEPTABLE_USE_POLICY":
            return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.WEBSITE_ACCEPTABLE_USE_POLICY.TOOLTIP
        case "WEBSITE_COOKIE_NOTICE":
            return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.WEBSITE_COOKIE_NOTICE.TOOLTIP
		case "BOARD_RESOLUTION_CONVERTIBLE_LOAN_NOTE":
		case "BOARD_RESOLUTION_CONVERTIBLE_SAFE":
		case "BOARD_RESOLUTION_CONVERTIBLE_KISS":
		case "BOARD_RESOLUTION_CONVERTIBLE_FIXED_EQUITY":
		case "BOARD_RESOLUTION_CONVERTIBLE_OTHER":
			return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.SAFE.TOOLTIP;
            case "USA_EQUITY_INCENTIVE_PLAN":
                case "USA_STAKEHOLDERS_DIRECTORS_EQUITY_INCENTIVE_PLAN":
                    return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.USA_EQUITY_INCENTIVE_PLAN.TOOLTIP;    
            case "USA_BOARD_CONSENT_SAFE_CONVERTIBLE_LOAN_NOTE":
                case "USA_BOARD_CONSENT_SAFE_SAFE":
                case "USA_BOARD_CONSENT_SAFE_KISS":
                case "USA_BOARD_CONSENT_SAFE_FIXED_EQUITY":
                case "USA_BOARD_CONSENT_SAFE_OTHER":
                case "USA_BOARD_CONSENT_SAFE":
                    return  Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.USA_BOARD_CONSENT_SAFE.TOOLTIP
                case "USA_CAL_EMPLOYEE_INFORMATION_AND_INVENTIONS_AGREEMENT":
                    return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.USA_CAL_EMPLOYEE_INFORMATION_AND_INVENTIONS_AGREEMENT.TOOLTIP
                    case "USA_CONSULTANT_INFORMATION_AND_INVENTIONS_AGREEMENT":
                        return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.USA_CONSULTANT_INFORMATION_AND_INVENTIONS_AGREEMENT.TOOLTIP
        case "USA_NDA":
            return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.USA_NDA.TOOLTIP;
        //CP-5321
        case "USA_CAL_OFFER_LETTER_SALARY_EMPLOYEE":
            return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.USA_CAL_OFFER_LETTER_SALARY_EMPLOYEE.TOOLTIP;
		default:
            return
    }
}
export default useGetTooltipByDocumentType

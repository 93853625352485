import React, {useEffect} from 'react';
import _ from "lodash";
import {TextParser} from '../../index';
import classes from "./TimeLine.module.scss"
import moment from "moment";


const getUUIDTranslate = (uuid) => {
    return "AUDITLOGS_TRANSACTIONS_" + uuid;
}
export default function ({ log, isLast, isSharing }) {
    const logClass = isSharing ? classes.SharingLog : null;
    const data = log.data ? log.data : { startupName: _.get(log, "startup.name", "") , ...log.labelValues};
    const date = log.date ? log.date : log.createdAt;
    let nameUser = "";
    let nameFile = "";
    if(!log.user) {
        nameUser = ""
    } else {
        nameUser= log.user.fullName ? log.user.fullName  :`{_.get(log, "user.firstName")} {_.get(log, "user.lastName")}`
    }

    if (!log.filePond){
        nameFile = ""
    } else {
        nameFile= log.filePond.name;
    }
    return (
        <React.Fragment>
            <div className={`${classes.Log} ${logClass}`}>
                <div className={classes.day}>
                    <div className={classes.circle}>
                    </div>
                    <div className={`${isLast ? classes.lastLine : classes.line} `}>
                    </div>
                    <div className={classes.timeDay}>
                        {moment(date).format('MMM DD')}
                    </div>
                </div>
                <div className={classes.action}>
                    <div className={classes.bullet}>
                        <i className="clara-AuditLog"/>
                    </div>
                    <div className={classes.info}>
                        <label className={classes.logLabel}>{nameUser} <b><TextParser unparsedText={log.transaction.label} params={{...data}} />  {nameFile}   </b> at  {moment.utc(date).format('LT')}</label>
                        <br />
                        <p>{log.result}</p>
                    </div>
                </div>

            </div>

        </React.Fragment>
    );
}

import { useLazyQuery ,gql} from '@apollo/client';

import ServerConnect from '../../v1/utils/ServerConnect'
import notification from "../../v1/utils/notification"
import useTextLang from "./useTextLang"
import _ from "lodash"
import { isInvalidToken } from "../../utils/errorHelper";
import logger from "./../../v1/utils/logger"
import { Constants } from "./../../v1/utils/constants"
import { useDispatch } from "react-redux";
import { goToPage } from '../../store/actions/page';

/*
confing:{
    showErrorNotification
    showSuccessNotification
    successMessage
    errorMessage
}
*/


export default (query, variables, config) => {
    const dispatch = useDispatch();
    const messageError = useTextLang(_.get(config, "errorMessage", "GENERIC_NOTIFICATION_ERROR"));
    const messageSuccess = useTextLang(_.get(config, "successMessage", "GENERIC_NOTIFICATION_SUCCESS"));
    const client = ServerConnect.getUniqueApolloClient();
    
    const log = () => {
        logger.info(Constants.LOGGER.ACTIONS.QUERY, { query: query, variables: variables })
    }
    const logError = (error) => {
        logger.error(Constants.LOGGER.ACTIONS.QUERY, { error, query: query })
    }
    const logSuccess = (result) => {
        logger.success(Constants.LOGGER.ACTIONS.QUERY, { result, query: query })
    }

    const onCompleted = (data) => {
        logSuccess(data)
        const showNotification = config && config.showSuccessNotification ? config.showSuccessNotification : false;
        if (_.get(window, "globalNotification") && showNotification !== false) {
            notification.sendNotification(messageSuccess, "success", 2500);
        }
        if (config && config.onCompleted) {
            config.onCompleted(data)
        }
    };

    const onError = (error) => {
        logError(error)
        if (process.env && process.env.NODE_ENV === "development") {
            console.log(error);
        }
        if (!isInvalidToken(error)) {
            const showNotification = config ? config.showErrorNotification : true;
            if (_.get(window, "globalNotification") && showNotification !== false) {
                notification.sendNotification(messageError, "error", 2500);
            }
            return;
        }
            goToPage(dispatch,Constants.PAGES.logout)  ;
            
    };
    const [manualQuery, { loading, error, data, refetch }] = useLazyQuery(gql`${query}`, {
        variables, client, onCompleted,onError, ...config
    });
    const manualQueryCustom = async (props) => {
        log(_.get(props, "variables"));
        return  manualQuery(props)
    }
    return { manualQuery: manualQueryCustom, loading, error, data, refetch };
}


import { useEffect } from 'react';
import useQuery from "../../../custom/useQuery";
import useLazyQuery from "../../../custom/useLazyQuery";

const query = `query getSubscriptionPrices {
  getSubscriptionPrices{
   id
   name
   description
   price
   code
   currency
   savePercentage
   features{
   id
     name
     description
     code
     displayValue
   }
 }
}`;

const useGetSubscriptionPrices = (variables) => {
  const { loading, error, data, refetch } = useQuery(query, variables, {}, { fetchPolicy: 'no-cache' });
  useEffect(() => {

  }, []);
  return { loading, error, data, refetch };
}

export const useGetSubscriptionPricesLazy = (variables) =>{
  const { manualQuery, loading, error, data, refetch } = useLazyQuery(query, variables, {}, { fetchPolicy: 'no-cache' });
  return { manualQuery, loading, error, data, refetch };
}

export default useGetSubscriptionPrices;
import React, {Component} from 'react';
import formData from './claraScoreForm.json'
import ServerConnect from '../../../utils/ServerConnect';
import {DinamicForm} from '../../index';
import classes from './ClaraScoreForm.module.scss';
import {setClaraScore} from "../../../graphql/mutations/setClaraScore";
import _ from "lodash";

import {connect} from "react-redux";
import {Constants} from "../../../utils";

/*
    Properties:s
        data: data form to show
        handleClose: function to execute when close de modal
        
*/
class ClaraScoreForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: [],
            data: {},
        }
    }

    submitForm = (data) => {
        data.startupId = this.props.startupId;
        data.score = parseInt(data.score, 10); // This needs to be an Integer
        ServerConnect.graphQlMutation(setClaraScore, data).then((result) => {
                const score = result.setClaraScore;
                
                this.props.handleClose();
        });
    };

    componentWillMount() {
        let fields = []
        _.forEach(formData, (field, i) => {
            fields.push(field);
        })
        this.setState({
            fields
        })
    }

    componentDidMount() {


    }



    render() {
        console.log('Constants.OPERATIONS.CLARASCORE',Constants.OPERATIONS.CLARASCORE)
        return (
            <div className={classes.ClaraScoreForm}>
                <DinamicForm
                    // disabled={!ManagerRole.hasAccesToOperation(Constants.OPERATIONS.CLARASCORE,this.props.session.user)}
                    formData={this.state.fields}
                    data={this.props.data}
                    submit={this.submitForm}
                    buttonLabel={"Save"}
                    refresh={this.props.refresh}
                    handleClose={this.props.handleClose}
                    botoneraClasses={{
                        container: classes.BotoneraContainer
                    }}
                />
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        session: state.session
    };
};
const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(ClaraScoreForm);

import CONVERTIBLE_FORM_STEP from "../ConvertibleFormStep/langs";
import FIRST_STEP from '../FirstStep/langs'
import IS_THERE_DOCUMENTS_STEP from '../IsThereDocumentsStep/langs'
import UPLOAD_DOCUMENTS_STEP from '../UploadDocumentStep/langs'
import SELECT_DOCUMENT_STEP from '../SelectDocumentStep/langs'
import GenerateDocumentStep from "../GenerateDocumentStep/langs";
export default {
    CONVERTIBLE_FORM_STEP:CONVERTIBLE_FORM_STEP.US,
    FIRST_STEP:FIRST_STEP.US,
    IS_THERE_DOCUMENTS_STEP:IS_THERE_DOCUMENTS_STEP.US,
    UPLOAD_DOCUMENTS_STEP:UPLOAD_DOCUMENTS_STEP.US,
    SELECT_DOCUMENT_STEP:SELECT_DOCUMENT_STEP.US,
    GENERATE_DOCUMENTS: GenerateDocumentStep.US
}
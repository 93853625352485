import _ from "lodash";
import React, { FC, useEffect, useState } from "react";
import GenerateDocumentsTasks from "../../../../modules/assistanceV4/components/AssistanceAreas/GenerateDocuments/Tasks";
import useFormat from "../../../generic/hooks/useFormat";
import useModal from "../../../generic/hooks/useModal";
import { ModalConvertibleDoc, ModalEmployeeDoc, ModalIpAndGenerate } from "../../../startup/Documents";
import useDinamicTasks from "../../hooks/useDinamicTasks";
import AssistanceFrame2 from "./";

/**
 * Assistance Frame for GenerateDocuments
 */

export interface AssistanceGenerateDocumentsProps {

}

const AssistanceGenerateDocuments: FC<AssistanceGenerateDocumentsProps> = ({
  ...props
}: AssistanceGenerateDocumentsProps) => {

  const { openModal } = useModal();
  const { moneyFormat, dateFormat } = useFormat();

  const configCompany = [
    { code: "TASK_ADD_CERTIFICATE_INCORPORATION", modal: ModalIpAndGenerate, type: 'CERTIFICATE_OF_INCORPORATION', onClick: (params) => openModal(ModalIpAndGenerate, { paramsMutation: { ...params, type: 'CERTIFICATE_OF_INCORPORATION' } }) },
    { code: "TASK_ADD_COMMERCIAL_LICENSE", modal: ModalIpAndGenerate, type: 'TRADE_LICENSE', onClick: (params) => openModal(ModalIpAndGenerate, { paramsMutation: { ...params, type: 'TRADE_LICENSE' } }) },
    { code: "TASK_ADD_OTHER_CONSTITUTIONAL_DOCUMENTS", modal: ModalIpAndGenerate, type: 'ADGM_REGISTER_OF_MEMBERS_DOCUMENT', onClick: (params) => openModal(ModalIpAndGenerate, { paramsMutation: { ...params, type: 'ADGM_REGISTER_OF_MEMBERS_DOCUMENT' } }) },
    { code: "TASK_ADD_SHARE_AGREEMENT", onClick: (params) => openModal(ModalIpAndGenerate, { paramsMutation: { ...params, type: 'REGISTER_OF_SHARES' } }) },
  ];

  const configStakeholders = [
    { code: "TASK_ADD_FOUNDERS_AGREEMENT", onClick: (params) => openModal(ModalIpAndGenerate, { paramsMutation: { ...params, type: 'FOUNDER_SERVICES_AGREEMENT' } }) },
    { code: "TASK_ADD_EMPLOYEE_AGREEMENT", onClick: (params) => openModal(ModalEmployeeDoc, { paramsMutation: { ...params, type: 'EMPLOYMENT_AGREEMENT' } }) },
    { code: "TASK_ADD_CONSULTANT_AGREEMENT", onClick: (params) => openModal(ModalIpAndGenerate, { paramsMutation: { ...params, type: 'CONSULTANT_AGREEMENT' } }) },
    { code: "TASK_ADD_ADVISOR_AGREEMENT", onClick: (params) => openModal(ModalIpAndGenerate, { paramsMutation: { ...params, type: 'ADVISOR_AGREEMENT' } }) },
    { code: "TASK_ADD_IP_ASSIGNMENT_AGREEMENT", onClick: (params) => openModal(ModalIpAndGenerate, { paramsMutation: { ...params, type: 'IP_ASSIGNMENT' } }) },

    { code: "TASK_ADD_WARRANT_AGREEMENT", onClick: (params) => openModal(ModalIpAndGenerate, { paramsMutation: { ...params, type: 'WARRANT_AGREEMENT' } }) },
    { code: "TASK_ADD_SHARE_AGREEMENT", onClick: (params) => openModal(ModalIpAndGenerate, { paramsMutation: { ...params, type: 'REGISTER_OF_SHARES' } }) },
    { code: "TASK_ADD_CONVERTIBLE_AGREEMENT", onClick: (params) => openModal(ModalConvertibleDoc, { paramsMutation: { ...params } }) },
    { code: "TASK_ADD_SHARE_OPTION_AGREEMENT", onClick: (params) => openModal(ModalIpAndGenerate, { paramsMutation: { ...params, type: 'GRANT_AGREEMENT' } }) },

    { code: "TASK_ADD_WARRANT_AGREEMENT_FOR_IN_PROGRESS" },
    { code: "TASK_ADD_CONVERTIBLE_AGREEMENT_FOR_IN_PROGRESS" },
    { code: "TASK_ADD_SHARE_OPTION_AGREEMENT_FOR_IN_PROGRESS" },
  ]

  const getParams = (task, entities) => {
    const { company } = entities
    switch (task.code) {
      case "TASK_ADD_WARRANT_AGREEMENT":
        return {
          owner: company ? { type: 'groupCompany', id: _.get(entities, 'info.owner.id') } : { type: 'stakeholder', id: _.get(entities, 'info.owner.id') },
          amount: moneyFormat(_.get(entities, "info.amount")),
          currency: _.get(entities, "info.currency"),
          equityId: _.get(entities, "info.id"),
          issuedDate: dateFormat(_.get(entities, "info.issuedDate")),
          groupCompany: _.get(entities, "info.groupCompany"),
          groupCompanyId: _.get(entities, "company.id", _.get(_.find(task.additionalParams, (params) => params.entityType == "GroupCompany"), "entityId", _.get(entities, "info.groupCompany.id", null))),
          stakeholderId: _.get(entities, "stakeholder.id", _.get(_.find(task.additionalParams, (params) => params.entityType == "Stakeholder"), "entityId", null)),
        }
      case "TASK_ADD_CONVERTIBLE_AGREEMENT":
        return {
          owner: company ? { type: 'groupCompany', id: _.get(entities, 'info.owner.id') } : { type: 'stakeholder', id: _.get(entities, 'info.owner.id') },
          amount: moneyFormat(_.get(entities, "info.amount")),
          currency: _.get(entities, "info.currency"),
          equityId: _.get(entities, "info.id"),
          issuedDate: dateFormat(_.get(entities, "info.issuedDate")),
          groupCompany: _.get(entities, "info.groupCompany"),
          groupCompanyId: _.get(entities, "company.id", _.get(_.find(task.additionalParams, (params) => params.entityType == "GroupCompany"), "entityId", _.get(entities, "info.groupCompany.id", null))),
          stakeholderId: _.get(entities, "stakeholder.id", _.get(_.find(task.additionalParams, (params) => params.entityType == "Stakeholder"), "entityId", null)),
        }
      case "TASK_ADD_SHARE_AGREEMENT":
        const result = {
          owner: company ? { type: 'groupCompany', id: _.get(entities, 'info.owner.id') } : { type: 'stakeholder', id: _.get(entities, 'info.owner.id') },
          amount: moneyFormat(_.get(entities, "info.amount", true)),
          currency: _.get(entities, "info.currency"),
          equityId: _.get(entities, "info.id"),
          issuedDate: dateFormat(_.get(entities, "info.issuedDate")),
          groupCompany: _.get(entities, "info.groupCompany"),
          // groupCompanyId: _.get(entities, "company.id", _.get(_.find(task.additionalParams, (params) => params.entityType == "GroupCompany"), "entityId", _.get(entities, "info.groupCompany.id", null))),
          groupCompanyId: _.get(entities, "info.groupCompany.id"),
          stakeholderId: _.get(entities, "stakeholder.id", _.get(_.find(task.additionalParams, (params) => params.entityType == "Stakeholder"), "entityId", null)),
        }
        return result;
      case "TASK_ADD_SHARE_OPTION_AGREEMENT":
        return {
          owner: company ? { type: 'groupCompany', id: _.get(entities, 'info.owner.id') } : { type: 'stakeholder', id: _.get(entities, 'info.owner.id') },
          amount: moneyFormat(_.get(entities, "info.amount", true)),
          currency: _.get(entities, "info.currency"),
          equityId: _.get(entities, "info.id"),
          issuedDate: dateFormat(_.get(entities, "info.issuedDate")),
          groupCompany: _.get(entities, "info.groupCompany"),
          groupCompanyId: _.get(entities, "company.id", _.get(_.find(task.additionalParams, (params) => params.entityType == "GroupCompany"), "entityId", _.get(entities, "info.groupCompany.id", null))),
          stakeholderId: _.get(entities, "stakeholder.id", _.get(_.find(task.additionalParams, (params) => params.entityType == "Stakeholder"), "entityId", null)),
        }
      default:
        return {
          groupCompanyId: _.get(entities, "company.id", _.get(_.find(task.additionalParams, (params) => params.entityType == "GroupCompany"), "entityId", _.get(entities, "info.groupCompany.id", null))),
          groupCompany: _.get(entities, "company", _.get(entities, "info.groupCompany", null)),
          stakeholderId: _.get(entities, "stakeholder.id", _.get(_.find(task.additionalParams, (params) => params.entityType == "Stakeholder"), "entityId", null)),
        }
    }
  }

  const { dataTasks } = useDinamicTasks(configCompany, configStakeholders, getParams);
  const [taskStatus, setTaskStatus] = useState("completed");

  useEffect(() => {
    if (!_.isEmpty(dataTasks)) {
      let groupWithPending = _.find(dataTasks, (group) => {
        return _.find(group.tasks, (task) => task.status !== "completed");
      });
      if (!(!!groupWithPending)) {
        setTaskStatus("completed");
      } else {
        setTaskStatus("incompleted");
      }
    }
  }, [JSON.stringify(dataTasks)]);


  return (
    <AssistanceFrame2
      tasksComponent={GenerateDocumentsTasks}
      showTask={true}
      tasks={dataTasks}
    ></AssistanceFrame2>
  );
};

export default AssistanceGenerateDocuments;

import PropTypes from "prop-types";
import React, { useState } from "react";
import TextInput from "../../../../components/inputs/Text/TextInput";
import Icon from "src/modules/generic/components/Icon";
import classesModule from "./classes.module.scss";
import FilterMenu from "src/modules/map/components/Header/subcomponents/FilterMenu";

/**
 * Header Map
 *
 */

const Header = ({
  children,
  open: openProps,
  onExpandCard,
  countFilters,
  countGroupCompanies,
  countStakeholders,
  onChangeOrder,
  onChangeActive,
  onChangeFilter,
  defaultValueIndex,
  ...props
}) => {
  const [filterText, setFilterText] = useState("");

  const handleOrder = (e) => {
    onChangeOrder && onChangeOrder(e);
  };

  const handleActive = (e) => {
    onChangeActive && onChangeActive(e);
  };

  const handleChangeFilter = (value) => {
    setFilterText(value);
    onChangeFilter && onChangeFilter(value);
  };

  const options = [
    {
      value: '1',
      label: 'Roles order',
      onClick: () => {
        handleOrder(1);
      }
    },
    {
      value: '2',
      label: 'Newest added',
      onClick: () => {
        handleOrder(2);
      }
    },
    {
      value: '3',
      label: 'Oldest added',
      onClick: () => {
        handleOrder(3);
      }
    },
    {
      value: '4',
      label: 'Name - A to Z',
      onClick: () => {
        handleOrder(4);
      }
    },
    {
      value: '5',
      label: 'Name - Z to A',
      onClick: () => {
        handleOrder(5);
      }
    },
  ];

  const optionsActive = [
    {
      value: '1',
      label: 'Active',
      onClick: () => {
        handleActive(1);
      }
    },
    {
      value: '2',
      label: 'Inactive',
      onClick: () => {
        handleActive(2);
      }
    },
    {
      value: '3',
      label: 'Active & Inactive',
      onClick: () => {
        handleActive(3);
      }
    }
  ];

  return (
    <div
      className={classesModule.Header}
    >
      <FilterMenu
        options={optionsActive}
        defaultValueIndex={defaultValueIndex}
        icon={(
          <Icon isClara icon={"Filter-dropdown"} size={"1.5em"} />
        )}
      ></FilterMenu>
      <FilterMenu
        options={options}
        icon={(
          <span class="clara2023-Sort-Arrow" style={{ fontSize: "1.5em" }}></span>
        )}
      ></FilterMenu>
      <div className={classesModule.Filter}>
        <TextInput
          onChange={handleChangeFilter}
          value={filterText}
          className={classesModule.TextInput}
          placeholder={"MODULES.MAP.SEARCH"}
          leftLabel={<span className={classesModule.arrowColor}><Icon icon="Search" isClara size={"1.5385rem"} /></span>}
        ></TextInput>
      </div>
    </div>
  );
};

Header.propTypes = {
  /**  Open component */
  open: PropTypes.bool,
  /**  Children component */
  children: PropTypes.node,
  /** Count Filters*/
  countFilters: PropTypes.number,
  /** Count Groups Companies*/
  countGroupCompanies: PropTypes.number,
  /** Count Stakeholders */
  countStakeholders: PropTypes.number,
  defaultValueIndex: PropTypes.number,
};

Header.defaultProps = {
  open: false,
  countFilters: 0,
  countGroupCompanies: 0,
  countStakeholders: 0,
  defaultValueIndex: 0,
};

export default Header;

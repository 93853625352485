import React, { FC, useEffect } from "react";
import {
  addAssistantText,
  clearAssistatText,
} from "./../../../../generic/store/action";

import Button from "./../../../../generic/components/Buttons/Button";
import Form from "../forms/FormSelectProvide";
import { StepProps } from "./../../../../generic/components/Wizard/Step";
import TemplateWizard from "./../../../../generic/templates/Modal/TemplateWizard";
import { get } from "lodash";
import useCustomMachine from "./../../../../generic/hooks/useCustomMachine";
import { useDispatch } from "react-redux";
import useTranslate from "./../../../../generic/hooks/useTranslate";

const Step1: FC<StepProps> = () => {
  const { translate } = useTranslate();
  const { next, send, state, ...prop } = useCustomMachine();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearAssistatText());
    dispatch(
      addAssistantText(
        `Clara accepts a utility bill or bank statement dated with the past two months, with this stakeholder's residential address displayed clearly.${get(state, "context.data.jurisdictionType", {}) === "CAYMAN"?' Click <a target="_blank" href="https://help.clara.co/en/articles/7066592-acceptable-proof-of-address-for-cayman">here</a> to learn more.':'' }`
      )
    );
    if (get(state, "context.data.jurisdictionType", {}) !== "CAYMAN") {
      dispatch(
        addAssistantText(
          `No proof of address available? No problem. Complete a third party address form on their behalf instead.`
        )
      );
      dispatch(
        addAssistantText(
          'The utility bill or bank statement MUST be written in English. We can NOT accept tenancy contracts/agreements or title deeds as proof of address.'
          ,
          'alert'
        )
      )
    }
    return () => {
      dispatch(clearAssistatText());
    };
  }, []);

  const handleCompleteSubmit = async (values) => {
    next(values);
  };
  const handleCancel = async (values) => {
    send("CANCEL");
  };

  return (
    <Form
      initialValues={get(state, "context.data.uploadDocument", {})}
      onCompleteSubmit={handleCompleteSubmit}
      Template={TemplateWizard}
      tooltips={{
        UPLOAD_PROOF_ADDRESS_PARTY: translate("If you already have a Clara third party address form, upload the completed form to continue"),
        DOWNLOAD_PROOF_ADDRESS: translate("If you don\'t have this person\'s proof of address available, download a third party address form from Clara. Complete the form, then upload it right here to continue.")
      }}
      propsTemplate={{
        title: "Can you provide this stakeholder's proof of address?",
        subTitle: "Proof of address",
      }}
      buttons={{
        cancel: (
          <Button onClick={handleCancel} variant="secondary">
            Cancel
          </Button>
        ),
      }}
    ></Form>
  );
};

export default Step1;

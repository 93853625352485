import React, {Component} from "react";
import {connect} from "react-redux";
import _ from "lodash";
import classes from "./Documents.module.scss";
import {goToPage, goToPrevPage} from '../../../../store/actions/page'
import {Constants} from '../../../utils/constants'
import {ClaraSearch, RoleAccess} from '../../index';
import DocumentTable from "./DocumentTable";
import {filterCategoryByDoctype} from "../../../../utils/categoryHelper";

class Document extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            title: "",
            filter: "",
            categories: [

            ],
            formOrder: {
                type: [],
                subCategories: [],
                category: []
            }
        }


    };

    onChangeFilter = (value) => {
        this.setState({
            filter: value
        })
    }
    getByCategory = (subCategoryData) => {
        let subCategories = [];
        let category = [];
        _.forEach(subCategoryData, (element) => {

            _.forEach(this.props.categories, (cat) => {

                let subcategory = _.find(cat.subCategories, subCategory => subCategory.key === element)

                if (subcategory) {
                    subCategories.push(subcategory.title);
                    category.push(cat.title)
                }
            })


        })

        return { subCategories, category };

    }
    AddDocument() {
       
        this.props.goToPage(Constants.PAGES.addDocument)
    }
    componentWillMount() {

        const {categories, documents} = this.props


        let data= documents.map(d => {
            const avatars = [];
            let aux={...d};
            aux.type = _.mapValues(d, "label").type
            aux.groupCompanies.map(gc => avatars.push({ imageUrl: gc.groupCompany.avatar, name: gc.groupCompany.name }))
            aux.stakeholders.map(st => avatars.push({ imageUrl: st.avatar, name: st.fullName }))
            aux.avatars = avatars.length > 5 ? { parties: avatars.slice(0, 2), moreParties: avatars.slice(2) } : { parties: avatars };
            const categoryByDocType = filterCategoryByDoctype(categories, d.type.code, d.subCategories[0])
            let {category,subCategories} = this.getByCategory(categoryByDocType.subCategories);

            aux.subCategories=_.join(subCategories,", ");
            aux.category=_.join(category, ", ");
            this.state.formOrder.type.push(aux.type);
            this.state.formOrder.subCategories.push(aux.subCategories);
            this.state.formOrder.category.push(aux.category);
            return aux
        });

        this.setState({
            data: data,
            title: this.props.title,

        })
    }
    changeMode = () => {
        const data = {
            index: this.props.data.documents.length,

        };
        this.props.changeModeForm(Constants.FORMS_MODE.EDIT, data);
    }
    showRol = (rol) => {
        return !_.isEmpty(rol);
    }
    
    
    render() {
        const { data, title, filter,formOrder } = this.state;
        if (!data) {
            return null;
        }

        return (

            <div className={classes.ContainerView}>
                <div className={classes.ContainerSearchAndTitle}>
                    <h2 className={classes.titleTable}>{title}</h2>

                    <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
                    <div className={classes.ContainerAdd} onClick={() => this.AddDocument()}>Add Document</div>
                    </RoleAccess>
                    <div className={classes.containerFilterBox}>
                        <ClaraSearch placeHolder={`Search...`} onChange={(this.onChangeFilter)} classes={{ input: classes.inputSearch }} />
                    </div>
                </div> 
                <div className={`${classes.ContainerData} ${classes.ContainerDataTable}`}>
                    <DocumentTable
                        data={data}
                        filter={filter}
                        formOrder={formOrder}
              
                    />

                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    session: state.session,
     
});
const mapDispatchToProps = (dispatch) => ({
    goToPrevPage: () => {
        goToPrevPage(dispatch)
    },
    goToPage: (page, params) => {
        goToPage(dispatch, page, params)
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Document)

import _ from "lodash";
import { useMemo } from "react";
import Constants from "src/modules/startup/Constants";
import { Constants as CosntantsUtils } from 'src/v1/utils';
import { assign } from 'xstate';

const useActions = () => {
  return useMemo(() => ({
    setContextAfterFirstInvoke: assign((context = {}, event) => {
      const values = _.get(event, 'data.document', []);
      const documentTypeCode = _.get(event, 'data.document.type.code', null);
      let parties = [];
      let party = null;
      const stakeholders = _.get(event, 'data.document.stakeholders', []);
      const groupCompanies = _.get(event, 'data.document.groupCompanies', []);
      _.forEach(stakeholders, st => {
        parties.push({ id: st.id, type: 'stakeholder' });
      })
      _.forEach(groupCompanies, ({ groupCompany }) => {
        parties.push({ id: groupCompany.id, type: 'groupCompany' })
      })
      let newContext = {
        ...context,
        parties: parties,
        file: values,
        ipProvision: _.some(values.subCategories, sub => sub === "IPAssignment")
      }
      if (CosntantsUtils.DOCUMENT_TYPE_IDENTITY_DOCUMENTS.includes(documentTypeCode) && stakeholders.length === 1) {
        newContext["party"] = stakeholders[0].id;
      }
      if (_.includes(CosntantsUtils.VISA_STAMPS_RESIDENCE_ID_DOCUMENTS_TYPES, documentTypeCode)) {
        newContext = { ...newContext, ...(values?.identityDocument ?? {}) }
      }
      if (_.includes(CosntantsUtils.PASSPORT_DOCUMENT_TYPES, documentTypeCode)) {
        newContext = { ...newContext, ...(values?.identityDocument ?? {}) }
      }
      return newContext;
    }),
    setCategoriesAfterInvoke: assign((context, event) => {
      const values = _.get(event, 'data', []);
      const newContext = {
        ...context,
        categories: values,
      }
      return newContext;
    }),
    setStakeholdersAfterInvoke: assign((context, event) => {
      const stk = _.get(event, 'data.stakeholders', []);
      const gc = _.get(event, 'data.groupCompanies', []);
      const newContext = {
        ...context,
        allStakeholders: stk,
        allGroupCompanies: gc,
      }
      return newContext;
    }),
  }), []);
}

export default useActions;
import React from "react";
import CapTableEmptyRouter from "./CapTableEmptyRouter";
import CaptableProvider from "../contexts/CaptableContext/CaptableProvider";

const CapTableView = () => {
  return (
    <CaptableProvider>
      <CapTableEmptyRouter />
    </CaptableProvider>
  )
};

export default CapTableView;

import _ from 'lodash'
import IndividualStakeholderFormStep from "./steps/IndividualStakeholderFormStep";
import LastFounderStep from "./steps/LastStep";
import Machine from "../../../../generic/components/Machine/MachineClass";

const isMoreStakeholder = ({ data }) => data.is_more_stakeholder.another;

const notIsMoreStakeholder = ({ data }) => !data.is_more_stakeholder.another;

const machineDefinition = {
    initial: 'edit_stakeholder',
    states: {
        edit_stakeholder: {
            on: {
                NEXT: { target: 'is_more_stakeholder', actions: 'setContext' },
                CANCEL: 'finish_stakeholder'
            }
        },
        is_more_stakeholder: {
            on: {
                NEXT: { target: 'adding_stakeholder', actions: 'setContext' },
                CANCEL: 'finish_stakeholder'
            }
        },
        adding_stakeholder: {
            exit: 'setGlobal',
            on: {
                '': [
                    { target: 'edit_stakeholder', cond: 'isMoreStakeholder' },
                    { target: 'finish_stakeholder', cond: 'notIsMoreStakeholder' }

                ]
            }
        },
        finish_stakeholder: {
            type: 'final',
            entry: 'final',
        },
        onDone: {
            actions: 'stopMachine'
        }
    }
}

const options = {
    actions: {

    },
    guards: {
        isMoreStakeholder,
        notIsMoreStakeholder
    }
}

export default class MachineFounder extends Machine {
    constructor() {
        const steps = {
            edit_stakeholder: IndividualStakeholderFormStep,
            is_more_stakeholder: LastFounderStep
        }
        _.set(machineDefinition,'id','founder')
        super(machineDefinition, options, steps)
    }
}

import _ from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';
import { useParams } from "react-router-dom";
import useSession from "src/modules/session/hooks/useSession";
import useUploadBulkDocuments from 'src/modules/startup/Stakeholder/graphql/mutation/useUploadBulkDocuments';

const useServices = ({ initialValues }) => {
  const { mutation: uploadDocuments } = useUploadBulkDocuments();
  const { startupId } = useSession();
  const params = useParams();

  return useMemo(() => ({

    initialData: async (context, event) => {
      return initialValues;
    },

    SaveStep: async (context, event) => {
      const fileDocumentDirectors = _.get(context, "UploadDocuments.documentDirectors", "");
      const fileDocumentMembers = _.get(context, "UploadDocuments.documentMembers", "");
      const documents = [];
      const managedParties = initialValues?.stakeholderId ?? params.id;
      documents.push({
        title: fileDocumentDirectors.name,
        file: fileDocumentDirectors,
        type: "REGISTER_OF_DIRECTORS",
        parties: [managedParties],
        status: "Uploaded",
        subCategories: ["constitutionalDocuments"],
        executedOnDate: moment(_.get(context, 'UploadDocuments.dateDirectors')).format('YYYY-MM-DD'),
      });
      documents.push({
        title: fileDocumentMembers.name,
        file: fileDocumentMembers,
        type: "REGISTER_OF_MEMBERS",
        parties: [managedParties],
        status: "Uploaded",
        subCategories: ["constitutionalDocuments"],
        executedOnDate: moment(_.get(context, 'UploadDocuments.dateMembers')).format('YYYY-MM-DD'),
      });
      return await uploadDocuments({ variables: {startupId, documents: documents} })
    },

  }), [initialValues]);
}

export default useServices;

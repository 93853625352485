import React, {useEffect, useState} from 'react';
import {ServerConnect} from "../utils";
import Mustache from 'mustache'
import useQuery from '../../hooks/custom/useQuery';
import _ from 'lodash';

const INCORPORATION = `
	query getDocumentsForApprovalIncorporation($matterId:ID!)
	{
	  getDocumentsForApproval(matterId:$matterId)
	  {
		id
		name
		status
		type
		{
		  code
		  label
		}
		file
		{
		  id
		  name
		  size
		}
	  }
	}
`;

const useDocumentsForApproval = ({ variables, ...props }) => {
	const { loading, error, data, refetch, ...propsDesc } = useQuery(INCORPORATION, variables, null, props);

	return { loading, error, data: _.get(data, 'getDocumentsForApproval', []), refetch, ...propsDesc };
};
export default useDocumentsForApproval;


// export default function useDocumentsForApproval(id, reload = true) {
//   const [documents, setDocuments] = useState([]);
//
//   useEffect(() => {
//     if (reload) {
//       const query = Mustache.render(`{${incorporation}}`, { matterId: id })
//       ServerConnect.graphQlQuery(query).then(({ getDocumentsForApproval }) => {
//         setDocuments(getDocumentsForApproval)
//       })
//     }
//
//   }, [id, reload]);
//
//   return { documents };
// }

import React from "react";
import * as Yup from "yup";
import Form from "../../../../../../components/forms/Form/Form";
import ControllerInput from "../../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import SubmitButton from "../../../../../generic/components/Buttons/SubmitButton";
import FormProps from "../../../../../generic/forms/interfaces/FormProps";
import TemplateFormDefault from "../../../../../generic/templates/Modal";
import cls from "./classes.module.scss";
import Typography from "../../../../../generic/components/Texts/Typography";
import CheckboxButtonSimple from "../../../../../generic/components/Inputs/CheckboxButtonSimple";

const schema = Yup.object().shape({
  haveOldPassposrt: Yup.boolean().required("This Field Required"),
});

interface FormPropsCustom extends FormProps {
  text: string;
}

const FormCurrentSelect: React.FC<FormPropsCustom> = ({
  initialValues = {},
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  text = "",
  propsTemplate,
}) => {

  const handleSubmit = async (values) => {
    onCompleteSubmit && (await onCompleteSubmit(values));
  };

  const options = React.useMemo(() => {
    return [
      { label: "In old passport", value: true },
      { label: "In current passport", value: false },
    ];
  }, []);

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <Template
        buttons={{
          ...buttons,
          submit: <SubmitButton {...buttonSubmitProps}>Next</SubmitButton>,
        }}
        props={propsTemplate}
      >
        <div>
          <Typography variant="body">{text}</Typography>

          <ControllerInput
            render={CheckboxButtonSimple}
            options={options}
            name="haveOldPassposrt"
            defaultlabel={""}
            size={"md"}
            className={cls.CheckboxButton}
            classNameButton={cls.buttonCheckBox}
          />
        </div>
      </Template>
    </Form>
  );
};

export default FormCurrentSelect;

import React, {Component} from "react";

import MissingInformation from "./MissingInformation"

class Missing extends Component {

    render() {
        return (
            <MissingInformation
                icon={<svg
                    width='153'
                    height='133'
                    viewBox='0 0 153 133'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <ellipse
                        opacity='0.1'
                        cx='50.6645'
                        cy='49.8129'
                        rx='50.6645'
                        ry='49.8129'
                        transform='rotate(180 50.6645 49.8129)'
                        fill='url(#paint0_linear)'
                    />
                    <ellipse
                        opacity='0.2'
                        cx='136.5'
                        cy='50'
                        rx='17'
                        ry='16.5'
                        transform='rotate(90 136.5 50)'
                        fill='url(#paint1_linear)'
                    />
                    <ellipse
                        opacity='0.2'
                        cx='106'
                        cy='119'
                        rx='14'
                        ry='14'
                        transform='rotate(90 106 119)'
                        fill='url(#paint2_linear)'
                    />
                    <path
                        d='M110.101 68.3716C110.101 67.5795 109.79 66.82 109.236 66.26L98.2906 55.191C97.7375 54.6298 96.9865 54.3139 96.203 54.3129H56.9527C55.322 54.3129 54 55.6501 54 57.2997V120.022C54 121.671 55.322 123.009 56.9527 123.009H75.5775H110.101V88.6607V68.3716Z'
                        fill='white'
                        stroke='#A9B0BC'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                    />
                    <path
                        d='M97.7536 54.885V67.4792H110.348'
                        stroke='#A9B0BC'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                    />
                    <circle cx='68.8927' cy='89.2331' r='1.71739' fill='#A9B0BC' />
                    <circle cx='96.0364' cy='89.2331' r='1.71739' fill='#A9B0BC' />
                    <path
                        d='M78.722 97.1796C80.3101 96.7541 81.9822 96.7541 83.5704 97.1796C85.1585 97.6051 86.6066 98.4412 87.7692 99.6038'
                        stroke='#A9B0BC'
                        stroke-width='2'
                        stroke-linecap='round'
                    />
                    <defs>
                        <linearGradient
                            id='paint0_linear'
                            x1='50.6645'
                            y1='3.8147e-06'
                            x2='50.6645'
                            y2='99.6259'
                            gradientUnits='userSpaceOnUse'
                        >
                            <stop stop-color='#2C74FF' />
                            <stop offset='1' stop-color='#2C74FF' stop-opacity='0' />
                        </linearGradient>
                        <linearGradient
                            id='paint1_linear'
                            x1='136.5'
                            y1='33.5'
                            x2='136.5'
                            y2='66.5'
                            gradientUnits='userSpaceOnUse'
                        >
                            <stop stop-color='#FFDEC0' />
                            <stop offset='1' stop-color='#FFDEC0' />
                        </linearGradient>
                        <linearGradient
                            id='paint2_linear'
                            x1='106'
                            y1='105'
                            x2='106'
                            y2='133'
                            gradientUnits='userSpaceOnUse'
                        >
                            <stop stop-color='#ED6D79' />
                            <stop offset='1' stop-color='#ED6D79' stop-opacity='0' />
                        </linearGradient>
                    </defs>
                </svg>}
                title={'Oops! 404'}
                subtitle={'We can\'t seem to find the page you\'re looking for'}
            />
        );
    }
}

export default Missing;

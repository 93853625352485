import useMutation from "src/hooks/custom/useMutation";

export const UPSERT_COMPONENT_STAKEHOLDER_POSITION = `
   mutation upsertComponentStakeholderPosition($startupId: ID!, $stakeholderId: ID, $stakeholderPosition: StartupStakeholderPositionInput) {
    postion:upsertComponentStakeholderPosition(startupId: $startupId ,stakeholderId: $stakeholderId ,stakeholderPosition: $stakeholderPosition ) {
      stakeholderId,
      departmentId,
      positionId,
      
    }
  }`;

const useUpsertComponentStakeholderPosition = ({ ...props }) => {
  return useMutation(
    UPSERT_COMPONENT_STAKEHOLDER_POSITION,
    { showSuccessNotification: false },
    props
  );
};

export default useUpsertComponentStakeholderPosition;

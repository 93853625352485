import React from "react";
import classesModule from "./Footer.module.scss";

const Footer = () => {

  return (
    <div className={`${classesModule.Footer}`} >
      <img src="/img/clara_logo.svg" alt="clara"></img>
    </div>
  );
}

export default Footer;
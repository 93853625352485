import React, { useRef, FC } from 'react';
import Form from '../../../../components/forms/Form/Form';
import SubmitButton from '../../../generic/components/Buttons/SubmitButton';
import FormProps from '../../../generic/forms/interfaces/FormProps';
import useTranslate from '../../../generic/hooks/useTranslate';
import TemplateFormDefault from "../../../generic/templates/Modal";
import classes from './Form.module.scss';
import Checkbox from 'src/components/inputs/Checkbox/Checkbox';
import * as Yup from 'yup';
import { InputLabelWithBanner } from 'src/components/inputs/InputWithBanner';

const schema = Yup.object().shape({
  groupCompanies: Yup.array().of(Yup.object()).nullable().required('Required').min(1, 'Required'),
})
interface SelectCompaniesProps extends FormProps {
  companies: any[],
  role: string
  priceList: any[]
}

const SelectCompanies: FC<SelectCompaniesProps> = ({
  initialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
  companies,
  role,
  priceList
}: SelectCompaniesProps) => {

  const { translate } = useTranslate();
  const refForm = useRef(null);
  const refCheckBox = useRef();

  const handleBannerTitle = () => {
    const price = priceList.find(p => p.role === role)?.price
    if (role === 'DIRECTOR') {
      return `Costs to file this change start from USD ${price}. If you select “Save changes”, this items will be added to your cart. To view it and modify it, go to the Dashboard and select “View order & pay”.`
    }
  }

  const handleBannerLogic = (values) => {
    let result = false;
    if (role === 'DIRECTOR' && (values[0] || []).some((company) => company?.kind === 'INCORPORATED' && company?.managedByClara && company?.jurisdictionType !== 'DELAWARE')) {
      result = true;
    }
    return result;
  }  

  return (
    <React.Fragment>
      <Form
        defaultValues={initialValues}
        schema={schema}
        onSubmit={(values) => onCompleteSubmit(values, refForm)}
        ref={refForm}
        onlySendDirty={false}
        optionsForm={{ mode: "onChange" }}
      >
        <Template
          buttons={{
            ...buttons,
            submit: <SubmitButton  {...buttonSubmitProps}>Save</SubmitButton>
          }}
          props={propsTemplate}
        >
          <InputLabelWithBanner
            bannerLogicType='values'
            applyLogic={handleBannerLogic}
            variant={"bannerLabel"}
            templateLabelProps={{
              label: translate("MODULES.ONBOARDING_FORMS.SELECT_COMPANIES.COMPANY"),
              isRequired: true,
              bannerTitle: handleBannerTitle(),
            }}
            controllerInputProps={{
              name: 'groupCompanies',
              options: companies,
              translate: false,
              ref: refCheckBox,
              classCheckboxLine: classes.separation,
            }}
            component={Checkbox}
          />
        </Template>
      </Form>
    </React.Fragment>
  )
}
export default SelectCompanies

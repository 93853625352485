import _ from 'lodash';
import { useMemo } from 'react';

const useGuards = () => {

  const handleRedirect = (context) => {
    let top; // TOPCO
    let jur; // APPROVED JURISDICTION
    let co; // OTHERS COMPANIES
    let cojur; // OTHERS COMPANIES IN APPROVED JURISDICTION
    // TOPCO
    const filterTopco = _.filter(_.get(context, "allCompanies", {}), (item) => {
      return item.isHoldingCompany;
    });
    if (filterTopco.length > 0) {
      top = true;
    } else {
      top = false;
    }
    // APPROVED JURISDICTION
    if (top) {
      jur = filterTopco[0].isJurisdictionApproved;
    } else {
      jur = false;
    }
    // OTHERS COMPANIES
    const filterNOTopco = _.filter(_.get(context, "allCompanies", {}), (item) => {
      return !item.isHoldingCompany;
    });
    if (filterNOTopco.length > 0) {
      co = true;
    } else {
      co = false;
    }
    // OTHERS COMPANIES IN APPROVED JURISDICTION
    if (co) {
      const filterCoJur = _.filter(filterNOTopco, (item) => {
        return item.isJurisdictionApproved;
      });
      if (filterCoJur.length > 0) {
        cojur = true;
      } else {
        cojur = false;
      }
    } else {
      cojur = false;
    }
    // EVALUATE
    if (top) {
      if (jur) {
        return "STEP_1";
      } else {
        if (co) {
          if (cojur) {
            return "STEP_3";
          } else {
            return "STEP_2";
          }
        } else {
          return "STEP_2";
        }
      }
    } else {
      if (co) {
        if (cojur) {
          return "STEP_4";
        } else {
          return "STEP_5";
        }
      }
    }
  }

  return useMemo(() => ({
    toStep1: (context) => {
      const result = handleRedirect(context);
      return result === "STEP_1";
    },
    toStep2: (context) => {
      const result = handleRedirect(context);
      return result === "STEP_2";
    },
    toStep3: (context) => {
      const result = handleRedirect(context);
      return result === "STEP_3";
    },
    toStep4: (context) => {
      const result = handleRedirect(context);
      return result === "STEP_4";
    },
    toStep5: (context) => {
      const result = handleRedirect(context);
      return result === "STEP_5";
    },
    step1Confirm: (context) => {
      return _.get(context, "resultChoice", "") === "CONFIRM";
    },
    step1Add: (context) => {
      return _.get(context, "resultChoice", "") === "ADD";
    },
    step1Form: (context) => {
      return _.get(context, "resultChoice", "") === "FORM";
    },
    step4Select: (context) => {
      return _.get(context, "resultChoice", "") === "SELECT";
    },
  }), []);
}

export default useGuards;
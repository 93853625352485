import React, { FC, useState, useEffect } from 'react'
import Wizard from '../../../generic/components/Wizard'
import ModalAssistant, { ModalAssistantProps } from '../../../generic/components/Modal/ModalAssistant'
import MachineAuthorisedSignatories from './machine'
import { useDispatch } from 'react-redux'
import useTranslate from '../../../generic/hooks/useTranslate'
import useModal from '../../../generic/hooks/useModal'

interface ModalProfileStakeholderProps extends ModalAssistantProps {
    paramsMutation: object,
    onClose(): void;
}

const ModalProfileStakeholder: FC<ModalProfileStakeholderProps> = ({ children, open: openParam = false, paramsMutation, ...props }: ModalProfileStakeholderProps) => {

    const [open, setOpen] = useState(openParam);

    const dispatch = useDispatch();

    const { translate } = useTranslate();

    const { closeModal: onClose } = useModal()

    useEffect(() => {
        setOpen(openParam);
    }, [openParam])
    const handleClose = (values) => {
        onClose()
        setOpen(false);
    }

    return (
        <ModalAssistant open={open}>
            <Wizard machine={MachineAuthorisedSignatories} initialValues={paramsMutation} onComplete={handleClose} />
        </ModalAssistant>

    )
}

export default ModalProfileStakeholder;





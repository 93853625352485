import { default as React, useMemo, useEffect } from 'react';
import { useDispatch } from "react-redux";
import classes from './classes.module.scss';
import Form from "src/components/forms/Form/Form";
import useMachine from '../../../../generic/context/MachineContext/useMachine';
import useModal from "../../../../generic/hooks/useModal";
import useTranslate from '../../../../generic/hooks/useTranslate';
import { addAssistantText, clearAssistatText } from "src/modules/generic/store/action";
import * as Yup from 'yup';
import TemplateWizard from "../../../../generic/templates/Modal/TemplateWizard";
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import Typography from "src/modules//generic/components/Texts/Typography";
import _ from "lodash";
import Button from 'src/modules/generic/components/Buttons/Button';
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import TemplateLabel from 'src/modules/generic/templates/Label';
import FileUploaderInput from 'src/components/inputs/FileUploader/FileUploaderInput';
import SelectInput from 'src/components/inputs/Select/SelectInput/SelectInput';
import useGetGroupCompanies from "src/hooks/services/modules/groupCompany/useGetGroupCompanies";
import TextInput from 'src/components/inputs/Text/TextInput'
import useSession from 'src/modules/session/hooks/useSession';
/**
 * Step to have topco in appove jurisdiction
 * @param {*} param0
 * @returns
 */
function HaveDocumentStep({ openParam }) {

  const { next, context, prev } = useMachine();
  const { translate } = useTranslate()
  const dispatch = useDispatch();
  const { closeModal: onClose } = useModal();
  const { startupId } = useSession();
  const handleCancel = (values) => {
    dispatch(clearAssistatText())
    onClose();
  }

  const handleCompleteSubmit = async (values) => {
    next(values)
  }

  const handlePrev = () => {
    prev()
  }
  const schema = Yup.object().shape({
    name: Yup.string().required(translate('MODULES.GROUP_COMPANY.ENGAGE_CONCIERGE.VALIDATION_REQUIRED'))
      .trim()
      .min(3, translate('MODULES.DOCUMENTS.NAMING_MINIMUM_LENGTH'))
      .matches(/^(?!.*[<>:/\\|?*""]).*/g, translate('MODULES.DOCUMENTS.NAMING_FORBIDDEN_CHARS')),
    file: Yup.object().nullable().required('This field is required'),
    company: Yup.string().nullable().required('Required'),

  })
  const { initialValues } = useMemo(() => {
    return context?.HaveDocumentStep ?? {}
  }, [context?.HaveDocumentStep])
  const {
    data: dataGroupCompanies,
  } = useGetGroupCompanies({ variables: { startupId } });
  const groupCompaniesList = useMemo(() => {
    const result = _.map(
      _.orderBy(dataGroupCompanies, ["isHoldingCompany"], ["desc"]),
      (gc) => ({ value: gc.id, label: gc.name, type: "groupCompany" })
    );
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(dataGroupCompanies)]);
  useEffect(() => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText(translate('MODULES.STARTUP.MODAL_ADD_FOUNDER_AGREEMENT_MODAL_TOOLTIP')));
    return () => {
      dispatch(clearAssistatText());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Form
      defaultValues={initialValues || {}}
      schema={schema}
      onSubmit={handleCompleteSubmit}
      optionsForm={{ mode: "onChange" }}
      onlySendDirty={false}
    >
      <TemplateWizard
        props={{
          title: translate(`MODULES.STARTUP.MODAL_ADD_FOUNDER_AGREEMENT_MODAL_UPLOAD_TITLE`),
          subTitle: translate(`MODULES.STARTUP.MODAL_ADD_FOUNDER_AGREEMENT_MODAL_SUB_TITLE`),
          skeleton: null
        }}
        buttons={{
          previous: <Button onClick={handlePrev} variant="secondary">{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton>{translate('MODULES.STARTUP.MODAL_ADD_BUTTON')}</SubmitButton>,
        }}
      >
        <TemplateLabel
          label={'Document name'}
          isRequired={true}
        >
          <ControllerInput
            render={TextInput}
            name='name'
            placeholder={"Type here"}
            defaultlabel={''}
          />
        </TemplateLabel>
        <TemplateLabel
          label={'Company'}
          isRequired={true}
        >
          <ControllerInput
            as={SelectInput}
            placeholder={"SELECT_INPUT_PLACEHOLDER_NORMAL"}
            list={groupCompaniesList}
            name={"company"}
            clear={true}
            onChange={(value) => {
            }}
          />
        </TemplateLabel>
        <TemplateLabel
          label={'Upload document'}
          isRequired
        >
          <ControllerInput
            render={FileUploaderInput}
            name='file'
            defaultlabel={''}
            className={classes.fileLoad}
          />
        </TemplateLabel>
      </TemplateWizard>
    </Form>
  )
}

export default HaveDocumentStep;

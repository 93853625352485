import React, {useEffect, useState} from 'react';
import {Avatar, DeleteForm, Loading, Text} from '../index';
import classesModal from "./Chat.module.scss"
import _ from "lodash";
import PropTypes from 'prop-types';
import {LayoutForm} from '../../containers';
import event from '../../utils/event';
import ServerConnect from '../../utils/ServerConnect';
import notification from "../../utils/notification";
import FactoryChatForm from './FactoryChatForm';
import FactoryChatFormConfig from './FactoryChatFormConfig';

let renderCount = 0;
const ChatForm = ({ formKey, groupKey, nextStep, previewInfo, draftId, initialKey, initialValueStart, inputConfig, onCancelForm }) => {

  const [showModal, setShowModal] = useState(false);
  const [rows, setRows] = useState([]);
  const [prevData, setPrevData] = useState(null);
  const [prevDataKey, setPrevDataKey] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [row, setRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [option, setOption] = useState(null);
  const [indexDelete, setIndexDelete] = useState(null);
  const [hasEvent, setHasEvent] = useState(false);
  const setInitialData = (previewInfo) => {
    let prevInfo = {};
    let primaryData = [];
    _.forEach(previewInfo, (e) => {
      const fieldName = _.get(FactoryChatFormConfig, e.formKey + ".fieldName", null);
      if (e.key == "PRIMARY") {
        primaryData.push({ ...e.data, formKey: e.formKey, fieldName })
      } else {
        if (!prevInfo[e.key]) {
          prevInfo[e.key] = [];
        }
        prevInfo[e.key].push({ ...e.data, formKey: e.formKey, fieldName })
      }
    })
    setPrevData(prevInfo);
    setRows(primaryData);
  }
  const handleNextStep = () => {
    let values = _.map(rows, (row) => {
      return row.id
    })
    let event = _.get(option, "bot_message.inputConfig.automaticSubmitNextQuestion") || _.get(inputConfig, "automaticSubmitNextQuestion");
    nextStep(values, event, () => {
      setLoading(false);
      setShowModal(false);
      setRow(null);
    });
  }
  const getClassToChatRow = (rows) => {
    if (rows.length <= 3) {
      return classesModal.ChatFormMax3
    } else if (rows.length <= 4) {
      return classesModal.ChatFormMax4
    } else if (rows.length <= 5) {
      return classesModal.ChatFormMax5
    } else if (rows.length <= 6) {
      return classesModal.ChatFormMax6
    }
    return classesModal.ChatFormMax7
  }
  const addRow = (values, formKey) => {
    const fieldName = _.get(FactoryChatFormConfig, formKey + ".fieldName", null);
    const getVariablesMutation = _.get(FactoryChatFormConfig, formKey + ".getVariablesMutation", null);
    const getIdFromMuttation = _.get(FactoryChatFormConfig, formKey + ".getIdFromMuttation", null);

    const muttation = _.get(FactoryChatFormConfig, formKey + ".muttation", null);
    setLoading(true)

    let notificationText = values.formKey === "INDIVIDUAL_FORM" ? "KYC_ADD_INDIVIDUAL_FORM" : "KYC_ADD_COMPANY_FORM";
    ServerConnect.graphQlMutation(muttation, getVariablesMutation(initialValueStart,values)).then(result => {
      notification.sendNotificationTranslate(notificationText, "success", 5000);
      rows.push({ ...values, id: getIdFromMuttation(result), fieldName });
      setRows(rows);
      let event = _.get(option, "bot_message.inputConfig.automaticSubmitNextQuestion") || _.get(inputConfig, "automaticSubmitNextQuestion");
      if (event) {
        handleNextStep();
      } else {
        setLoading(false);
        setShowModal(false);
        setRow(null);
      }
    }).catch(err => {
      notification.sendNotificationTranslate("NOTIFICATION_BAD", "error", 5000);
      console.log("NOTIFICATION_BAD:", err);
      setLoading(false);
    });

  }
  const editRow = (values, formKey) => {

    const fieldName = _.get(FactoryChatFormConfig, formKey + ".fieldName", null);
    const getVariablesMutation = _.get(FactoryChatFormConfig, formKey + ".getVariablesMutation", null);
    const getIdFromMuttation = _.get(FactoryChatFormConfig, formKey + ".getIdFromMuttation", null);

    const muttation = _.get(FactoryChatFormConfig, formKey + ".muttation", null);
    setLoading(true)

    let notificationText = values.formKey === "INDIVIDUAL_FORM" ? "KYC_ADD_INDIVIDUAL_FORM" : "KYC_ADD_COMPANY_FORM";
    ServerConnect.graphQlMutation(muttation, getVariablesMutation(initialValueStart,values)).then(result => {
      notification.sendNotificationTranslate(notificationText, "success", 5000);
      //rows.push({ ...values, id: getIdFromMuttation(result), fieldName });
      if(prevDataKey) {
        let newPrevData = {...prevData}
        newPrevData[prevDataKey] = [values];
        setPrevData(newPrevData)
        setPrevDataKey(null)
      } else{

        let index = _.findIndex(rows, (o) => {
          return o.id == values.id;
        })
        rows[index] = (values);
        setRows(rows);
      }

      setLoading(false);
      setShowModal(false);
      setRow(null)

    }).catch(err => {
      notification.sendNotificationTranslate("NOTIFICATION_BAD", "error", 5000);
      console.log("NOTIFICATION_BAD:", err);
      setLoading(false);
    });

  }
  const deleteRow = (params) => {


    const row = rows[params.index];
    const formConfig = FactoryChatFormConfig[row.formKey];

    // let elementType = currentList[params.index].type;

    const getVariablesMutationRemove = formConfig.getVariablesMutationRemove;
    const paramsMutation = formConfig.paramsMuttaion;
    const mutationRemove = formConfig.muttationRemove;

    const values = {id: row.id, matterId: initialValueStart.matterId, draftId : initialValueStart.draftId};

    const paramsToMutation = getVariablesMutationRemove ? getVariablesMutationRemove({...paramsMutation, ...values}) : { ...values, ...paramsMutation };
    //let val = field.value;
    setLoading(true)

    return new Promise((resolve,reject) => {

        if (mutationRemove) {
          ServerConnect.graphQlMutation(mutationRemove, paramsToMutation).then(result => {
            rows.splice(params.index, 1);
            setRows(rows);
            resolve();
            setLoading(false)
          }).catch(err => {
            setLoading(false);
            reject();
            handleClose();
          });
        } else {
          resolve();
        }
      });
  }

  const handleSubmit = (values, formKey) => {
    const fieldName = FactoryChatFormConfig[formKey].fieldName;
    if (!values.id) {
      addRow(values, formKey);
    } else {
      editRow(values, formKey);
    }
  }
  const handleClose = () => {
    setShowModal(false);
    onCancelForm();
  }
  const handleEdit = (row) => {
    setIsEdit(true);
    setRow(row);
    setShowModal(true);
  }

  const handleEditPrevData = (data,key) => {
    setIsEdit(true)
    setPrevDataKey(key);
    setRow(data);
    setShowModal(true)
  }

  const handleRemove = (row, index) => {
    setIndexDelete(index);
    setRow(row);
  }
  const handleCloseDelete = () => {
    setIndexDelete(null)
    setRow(null);
  }
  const getPropertyByFormKey = (formKey, property) => {
    return _.get(FactoryChatFormConfig, formKey + "." + property, "");
  }
  const getTitle = (row) => {
    const title = getPropertyByFormKey(row.formKey, "titleKey");
    const operation = isEdit ? "_EDIT" : "_ADD";
    return title + operation;
  }
  useEffect(() => {
    renderCount++;
    if (!hasEvent) {
      setHasEvent(true);
      event.on("CHAT_FORM_" + groupKey, (params) => {
        const formKey = _.get(params, "option.bot_message.inputConfig.formKey");
        setIsEdit(false);
        setRow({ type: params.option.id, formKey })
        setOption(params.option)
        setShowModal(true);
      });
      if (_.get(inputConfig, "automaticOpen")) {
        setIsEdit(false);
        setRow({ type: inputConfig.option.id, formKey })
        setOption(inputConfig.option)
        setShowModal(true);
      }
    }
    if (!prevData) {
      setInitialData(previewInfo);
    }
    return () => {
      event.remove("CHAT_FORM_" + groupKey, () => { });
    }
  })

  return (
    <React.Fragment>
      <div className={`${classesModal.ChatForm} ${getClassToChatRow(rows)}`} >
        {rows.map((row, i) => {
          const getAvatarLabel = _.get(FactoryChatFormConfig, row.formKey + ".getAvatarLabel", ()=>{});

          return (
            <div key={i} className={classesModal.Avatar}>
              <Avatar name={getAvatarLabel(row)} showLabel={false} size={50} className={classesModal.Avatar50} divStyle={{marginBottom:'25px', marginRight:'20px'}}/>
              <span>
              <a onClick={() => { handleEdit(row) }}>
                <Text uuid={"TEXT_EDIT"} />
              </a>
              {!row.isPrimary ?
                <>
                  <span> / </span>
                  <a onClick={() => { handleRemove(row, i) }}><Text uuid={"TEXT_REMOVE"} /></a>
                </>
                : null}
              </span>
            </div>
          )
        })}
      </div>
      {prevData ?
        <div className={`${classesModal.SubCategories} ${getClassToChatRow(rows)}`} >
          {_.map(prevData, (data, key) => {
            return (
              <React.Fragment>
                <div className={classesModal.SubCategoriesElement}>
                  <h3><Text uuid={"CHAT_FROM_SUB_CATEGORIES_" + key} /></h3>
                  {_.map(data, (e, i) => {
                    const getAvatarLabel = _.get(FactoryChatFormConfig, e.formKey + ".getAvatarLabel", null);
                    return (
                      <React.Fragment>
                        <div key={i} className={classesModal.AvatarSub}>
                          <Avatar name={getAvatarLabel(e)} showLabel={false} size={50} className={classesModal.Avatar50}> </Avatar>
                          <a onClick={() => { handleEditPrevData(e,key) }}>
                            <Text uuid={"TEXT_EDIT"} />
                          </a>
                        </div>
                      </React.Fragment>
                    )
                  })
                  }
                </div>
              </React.Fragment>
            )
          })}
        </div>
        : null}
      {rows.length > 0 ?
        <button className={`genericBlue ${classesModal.nextStep} `} onClick={handleNextStep}><Text uuid={"TEXT_NEXT"} /></button>
        : null
      }
      {
        showModal ? (
          <div className={`${classesModal.ModalChatForm}`}>
            <LayoutForm title={getTitle(row)} leftEducationId={getPropertyByFormKey(row.formKey, `leftEducationId${isEdit ? "_EDIT" : "_ADD"}`)} handleClose={handleClose}>
              <Loading show={loading} showChildren={false}>
                <FactoryChatForm formKey={row.formKey} handleSubmit={handleSubmit} propsForm={{ isDraft: true, draftId }} initialValues={row}></FactoryChatForm>
              </Loading>
            </LayoutForm>
          </div>
        ) : null
      }
      {
        indexDelete !== null ?
          <DeleteForm
            show={indexDelete !== null}
            deleteEntity={deleteRow}
            paramsDeleteEntity={{ index: indexDelete }}
            title={`Delete ${_.get(FactoryChatFormConfig, row.formKey + ".getAvatarLabel", null)(row)} ...`}
            options={[]}
            closeManager={false}
            handleClose={handleCloseDelete}
          />
          : null
      }

    </React.Fragment >
  );
}
ChatForm.propTypes = {
  formKey: PropTypes.string, /* Key to identify form*/
  groupKey: PropTypes.string.isRequired, /* GroupKey to identify form*/
  nextStep: PropTypes.func.isRequired, /* next button action */
};
export default ChatForm;
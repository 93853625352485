import React from "react";
import classes from './Calendar.module.scss';

import {
    KeyboardDatePicker,
    KeyboardDateTimePicker,
    KeyboardTimePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/date-fns";
import { Constants } from '../../../../utils/constants';
import { helper } from "../../../../utils";
import { TooltipInput } from "../../../../components";
import _ from "lodash";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import claraTheme from "../../../../../styles/themes/clara";
import  "../../../../../styles/themes/ClaraTheme.css";

const materialTheme = createTheme(claraTheme);



function InputCalendarDateTime({ form, prevComponentChange, field, tooltip, type, ...props }) {
    const setFieldValue = form.setFieldValue;
    const errors = form.errors;
    const value = field.value;
    const name = field.name;
    let formatDateTime = helper.getFormatByKey(Constants.TYPE_FORMATS.DATE_TIME);
    let formatDate = helper.getFormatByKey(Constants.TYPE_FORMATS.DATE);
    let formatTime = helper.getFormatByKey(Constants.TYPE_FORMATS.TIME);
    return (
        <TooltipInput label={tooltip}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <ThemeProvider theme={materialTheme}>

                    {type === "dateTime" ? (
                        <span className={"Error"} hasError={_.get(errors, name) ? "true" : "false"}>
                            <KeyboardDateTimePicker
                                autoOk
                                variant="inline"
                                className={`${classes.CalendarInput}`}
                                views={["year", "month", "date", "hours", "minutes"]}
                                inputVariant="outlined"

                                openTo="year"
                                ampm={false}
                                initialFocusedDate={null}
                                clearable
                                format={formatDateTime}
                                {...props}
                                error={_.get(errors, name)}
                                helperText={_.get(errors, `${name}.message`, '')}
                                name={name}
                                value={value ? moment(value, "YYYY-MM-DD HH:mm") : null}
                                InputAdornmentProps={{ position: "start" }}
                                PopoverProps={{
                                    disableEnforceFocus: true
                                }}
                                onChange={value => {

                                    const m = moment(value, formatDateTime);
                                    setFieldValue(name, m.format('YYYY-MM-DD HH:mm'));


                                }}
                            />
                        </span>
                    ) : null}
                    {type === "date" ? (

                        <React.Fragment>
                            <span className={"Error"} hasError={_.get(errors, name) ? "true" : "false"}>
                                <KeyboardDatePicker
                                    autoOk
                                    variant="inline"
                                    className={`${classes.CalendarInput}`}
                                    views={["year", "month", "date"]}
                                    inputVariant="outlined"
                                    openTo="year"
                                    ampm={false}
                                    initialFocusedDate={null}
                                    clearable
                                    format={formatDate}
                                    {...props}
                                    error={_.get(errors, name)}
                                    helperText={_.get(errors, `${name}.message`, '')}
                                    name={name}
                                    value={value ? moment(value, "YYYY-MM-DD") : null}
                                    InputAdornmentProps={{ position: "start" }}
                                    PopoverProps={{
                                        disableEnforceFocus: true
                                    }}
                                    keyboardIcon={<i className={"icon-calendar"}></i>}
                                    onChange={value => {
                                        const m = moment(value, formatDate);
                                        setFieldValue(name, m.format('YYYY-MM-DD'));
                                        if (prevComponentChange) {
                                            prevComponentChange(value, form, field);
                                        }
                                    }}

                                />
                            </span>
                        </React.Fragment>
                    ) : null}
                    {type === "time" ? (

                        <React.Fragment>
                            <span className={"Error"} hasError={_.get(errors, name) ? "true" : "false"}>
                                <KeyboardTimePicker
                                    autoOk
                                    variant="inline"
                                    className={`${classes.CalendarInput}`}
                                    inputVariant="outlined"
                                    ampm={false}
                                    initialFocusedDate={null}
                                    clearable
                                    format={formatTime}
                                    keyboardIcon={<i className={"icon-calendar"}></i>}
                                    {...props}
                                    error={_.get(errors, name)}
                                    helperText={_.get(errors, `${name}.message`, '')}
                                    name={name}
                                    value={value ? moment(value, "HH:mm") : null}
                                    InputAdornmentProps={{ position: "start" }}
                                    PopoverProps={{
                                        disableEnforceFocus: true
                                    }}
                                    onChange={value => {
                                        const m = moment(value, formatTime);
                                        setFieldValue(name, m.format('HH:mm'));
                                    }}
                                />
                            </span>
                        </React.Fragment>
                    ) : null}
                    {type === "dayMonth" ? (

                        <React.Fragment>
                            <span className={"Error"} hasError={_.get(errors, name) ? "true" : "false"}>
                                <KeyboardDatePicker
                                    autoOk
                                    variant="inline"
                                    className={`${classes.CalendarInput}`}
                                    views={["month", "date"]}
                                    inputVariant="outlined"
                                    openTo="month"
                                    ampm={false}
                                    initialFocusedDate={null}
                                    clearable
                                    format="dd/MM"
                                    {...props}
                                    error={_.get(errors, name)}
                                    helperText={_.get(errors, `${name}.message`, '')}
                                    name={name}
                                    value={value ? moment(value, "YYYY-MM-DD") : null}
                                    InputAdornmentProps={{ position: "start" }}
                                    PopoverProps={{
                                        disableEnforceFocus: true
                                    }}
                                    keyboardIcon={<i className={"icon-calendar"}></i>}
                                    onChange={value => {
                                        const m = moment(value, "dd/MM");
                                        setFieldValue(name, m.format('YYYY-MM-DD'));
                                        if (prevComponentChange) {
                                            prevComponentChange(value, form, field);
                                        }
                                    }}

                                />
                            </span>
                        </React.Fragment>
                    ) : null}
                    {type === "year" ? (

<React.Fragment>
    <span className={"Error"} hasError={_.get(errors, name) ? "true" : "false"}>
        <KeyboardDatePicker
            autoOk
            variant="inline"
            className={`${classes.CalendarInput}`}
            views={["year"]}
            inputVariant="outlined"
            openTo="year"
            ampm={false}
            initialFocusedDate={null}
            clearable
            format="yyyy"
            {...props}
            error={_.get(errors, name)}
            helperText={_.get(errors, `${name}.message`, '')}
            name={name}
            value={value ? moment(value, "YYYY") : null}
            InputAdornmentProps={{ position: "start" }}
            PopoverProps={{
                disableEnforceFocus: true
            }}
            keyboardIcon={<i className={"icon-calendar"}></i>}
            onChange={value => {
                const m = moment(value, "YYYY");
                setFieldValue(name, m.format('YYYY'));
                if (prevComponentChange) {
                    prevComponentChange(value, form, field);
                }
            }}

        />
    </span>
</React.Fragment>
) : null}
                </ThemeProvider>
            </MuiPickersUtilsProvider>
        </TooltipInput>

    );
}

export default InputCalendarDateTime;

import { Field } from "formik";
import get from 'lodash/get';
import React, { FC,useEffect } from "react";
import Accordion from "src/components/inputs/Accordion";
import classes from "../styles.module.scss";
import BodyDetailsShare from "./BodyDetailsShare";
import TitleShare from "./TitleShare";
import FormikHelper from "src/v1/utils/formikHelper";
interface Props {
    shares: any[];
    entityLabel:string;
    form:any;
}
const ShareholdingDetails:FC<Props> = ({shares,entityLabel,form}) => {
    function mapShareToForm(sh){
        return {
            ...sh,
            amountOfShares:sh.quantity,
            pricePerShare:sh.sharePrice.amount,
            areTheSharesNumbered:!!sh.shareNumbersIssued,
            numberedShares:sh.shareNumbersIssued
                ?
                {
                    startingShareNumber: sh.shareNumbersIssued?.startingShareNumber,
                    endingShareNumber: sh.shareNumbersIssued?.endingShareNumber
                } : null,
            vestingTerms:sh.vestingSchedule?{
                ...sh.vestingSchedule?.vestingTerm?.standardTerm,
                isThereCliff:!!sh.vestingSchedule?.vestingTerm?.standardTerm?.isThereCliff,
                id:sh.vestingSchedule?.vestingTerm?.id,
                vestingStartDate:sh.vestingSchedule.vestingStartDate
            }:null
        }
    }
    useEffect(()=>{
        FormikHelper.setValueInTheCorrectPosition(`${entityLabel}.share`,form,shares.map(mapShareToForm))
    },[])
    return <>{shares.map((s, index) => (
        <div className={classes.shareholdingTerms}>
            <Accordion id={`${index}`} label={<TitleShare share={s} /> } details={<BodyDetailsShare share={s} />} />
            {!s.vestingSchedule && <>
            <Field name='founder.vesting_schedule_error'>
                                {({ field, form }) => (
                                                                            <span hasError={get(form.errors,`${entityLabel}.share[${index}].vestingTerms` ) ? "true" : "false"}>
        <p style={get(form.errors,`${entityLabel}.share[${index}].vestingTerms` )?{color:'#FF606F'}:null}>This share position is not subject to any vesting terms. To add vesting, please go to “Equity” and edit this share position to include the relevant vesting terms.</p>
                                </span>)}
                                </Field>
        </>
        }
        </div>))}
    </>
}
export default ShareholdingDetails;

import React, { FC, useMemo } from 'react';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import  NomineeServiceWizard from './../../submachines/NomineeServiceModal/wizard';

interface Props {

}
/**
* Description Step: You have decided to for your company in ADGM and for your authorised signatory to be a Clara's nominee. 
* 
*/
const NomineeServiceSubMachineStep: FC<Props> = () => {

  const { next, context, prev, cancel, send } = useMachine();
  const initialValues = useMemo(() => {
    return { ...context?.initialData ?? {} }
  }, [context]);
  
  const handleStopMachine = () => {
    next(context)
  }
  const handleCancelMachine = () => {
    cancel();
  }
  const handlePrevMachine = () => {
    prev();
  }

  const handleGoToThirdParty = () => {
    send({
      type: 'GO_TO_THIRD_PARTY',
      payload: { idState: 'GO_TO_THIRD_PARTY', values: context }
    })
  }
  
  return (
    <NomineeServiceWizard initialValues={initialValues} onFinish={handleStopMachine} onCancel={handleCancelMachine} onPreviousMachine={handlePrevMachine} goToThirdParty={handleGoToThirdParty}/>
  );
};

export default NomineeServiceSubMachineStep;

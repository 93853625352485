import _ from 'lodash';
import { useMemo } from 'react';
import useUpsertDocument from 'src/modules/startup/graphql/document/mutation/useUpsertDocument'
import useSession from "../../../../session/hooks/useSession";
import useGetGroupCompanies from 'src/hooks/services/modules/groupCompany/useGetGroupCompanies';

const useServices = ({ initialValues }) => {

  const { mutation: updateDocument } = useUpsertDocument({});
  const { startupId } = useSession();
  const { manualQuery: getGroupCompanies } = useGetGroupCompanies({ variables: { startupId } });

  return useMemo(() => ({
    initialData: async () => {
      return initialValues;
    },
    getGroupCompaniesData: async (context, event) => {
      const groupCompanies = await getGroupCompanies();
      const gcData = _.get(groupCompanies, 'data.getGroupCompanies', []);
      return { inicialGroupCompanies: gcData}
    },
    uploadBoardResolution: async (context, event) => {
      const responseTitle = _.get(context, "resultName", "");
      const responsefile = _.get(context, "resultFile", {});
      const investor = _.get(event, "payload.values.investor", "");
      const company = _.get(event, "payload.values.company", "");
      const boardType = _.get(event, "payload.values.boardType", "");
      const dataSelectedCompany = _.filter(context.allGroupCompanies, (gc) => {
        return gc.id === company;
      });
      let isInUsa = false;
      if (dataSelectedCompany.length > 0) {
        isInUsa = dataSelectedCompany[0].country.code === "US";
      }
      let boardResult = "";
      if (isInUsa) {
        switch (boardType) {
          case "LOAN":
            boardResult="USA_BOARD_CONSENT_SAFE_CONVERTIBLE_LOAN_NOTE";
            break;
          case "SAFE":
            boardResult="USA_BOARD_CONSENT_SAFE_SAFE";
            break;
          case "KISS":
            boardResult="USA_BOARD_CONSENT_SAFE_KISS";
            break;
          case "FIXED":
            boardResult="USA_BOARD_CONSENT_SAFE_FIXED_EQUITY";
            break;
          case "OTHER":
            boardResult="USA_BOARD_CONSENT_SAFE_OTHER";
            break;
          default:
            break;
        }
      } else {
        switch (boardType) {
          case "LOAN":
            boardResult="BOARD_RESOLUTION_CONVERTIBLE_LOAN_NOTE";
            break;
          case "SAFE":
            boardResult="BOARD_RESOLUTION_CONVERTIBLE_SAFE";
            break;
          case "KISS":
            boardResult="BOARD_RESOLUTION_CONVERTIBLE_KISS";
            break;
          case "FIXED":
            boardResult="BOARD_RESOLUTION_CONVERTIBLE_FIXED_EQUITY";
            break;
          case "OTHER":
            boardResult="BOARD_RESOLUTION_CONVERTIBLE_OTHER";
            break;
          default:
            break;
        }
      }
      const variables = {
        startupId,
        agreementData: {
          title: responseTitle,
          file:responsefile,
          type: boardResult,
          status: "Uploaded",
          parties: [investor],
          subCategories: ["constitutionalDocuments"],
          groupCompanies: [
            {
              groupCompany: company,
            }
          ],
        },
      }
      await updateDocument({ variables });
    }
  }), []);
}

export default useServices;
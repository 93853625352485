import React, { FC } from 'react';
import CategoriesList from "../../../../../generic/components/Lists/CategoriesList";
import Typography from "../../../../../generic/components/Texts/Typography";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import { DueDiligenceBO } from "../../../../core/type/startup";
import { DocumentBO } from "../../../../core/type/startup/document";
import { ConvertibleBO, EquityBO, WarrantBO } from "../../../../core/type/startup/equity";
import { FormationCompanyBO } from "../../../../core/type/startup/groupCompany";
import classes from './classes.module.scss';
import useGetDelete from '../../hooks/useGetDelete'

interface Connection {
  equity: EquityBO[]
  documents: DocumentBO[]
  warrants: WarrantBO[]
  convertibles: ConvertibleBO[]
  dueDiligences: DueDiligenceBO[]
  dataProtectionContact: FormationCompanyBO
  directors: FormationCompanyBO,
  shareholders: FormationCompanyBO,
  authorisedSignatories: FormationCompanyBO,
  authorisedSignatoriesBank: FormationCompanyBO
}

interface ReasonsProps {
  connections: Connection
}

const Reasons: FC<ReasonsProps> = ({ connections }) => {
  const { translate } = useTranslate();
  const { getCategories } = useGetDelete();
  const categories = getCategories(connections);

  return (
    <>
      <div className={classes.reasonsContainer}>
        <Typography component={'p'} color={'black'}>
          {translate('MODULES.STAKEHOLDER.DELETE.REASONS')}
        </Typography>
        <CategoriesList dotColor={'red'} categories={categories} />
      </div>
    </>
  );
}
export default Reasons;
import useCustomMachine from '../../../../generic/hooks/useCustomMachine';
import React, { FC } from 'react';
import Button from '../../../../generic/components/Buttons/Button';
import { StepProps } from "../../../../generic/components/Wizard/Step";
import TemplateWizard from '../../../../generic/templates/Modal/TemplateWizard';
import useTranslate from '../../../../generic/hooks/useTranslate';
import Form from "../../Forms/FormCompanyInfo";
import _ from 'lodash';

const Step1: FC<StepProps> = () => {

  const { translate } = useTranslate();
  const { next, send, state } = useCustomMachine();

  const handleCompleteSubmit = async values => {
    next(values);
  }

  const handleCancel = async values => {
    send("CANCEL");
  }

  return (
    <React.Fragment>
      <Form initialValues={_.get(state, 'context.data.company_info', {})} onCompleteSubmit={handleCompleteSubmit} Template={TemplateWizard} propsTemplate={{
        title: translate(`MODULES.MAP.WIZARD_COMPANY_DETAIL_FORM_TITLE`),
        subTitle: "Company Information"
      }}
        buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }}>
      </Form>
    </React.Fragment>
  )
}

export default Step1;

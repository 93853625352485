import _ from 'lodash';
import React, {useEffect} from 'react';
import {gql, useQuery} from "@apollo/client";
import { useDispatch } from "react-redux";
import Button from "../../../../../generic/components/Buttons/Button";
import useMachine from '../../../../../generic/context/MachineContext/useMachine';
import useModal from "../../../../../generic/hooks/useModal";
import useTranslate from '../../../../../generic/hooks/useTranslate';
import { clearAssistatText } from "../../../../../generic/store/action";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import FormBoardResolutionTypeDocument from '../../../../../../modules/accelerators/modals/boardResolution/forms/formBoardResolutionTypeDocument';
import useSession from '../../../../../../modules/session/hooks/useSession';
import useUpsertStartupOptions from "src/hooks/services/modules/startups/optionsSelected/useUpsertStartupOptions";

const GROUP_COMPANIES_QUERY = gql`
  query getGroupCompanies($startupId: ID!) {
    getGroupCompanies(startupId: $startupId) {
      id
      name
      country { code }
      isHoldingCompany
    }
  }
`;

/**
 * Step to select type board resolution type document
 * @param {*} param0
 * @returns
 */
function BoardResolutionTypeDocument({ openParam }) {
  const { next, context, prev } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const { closeModal: onClose } = useModal();
  const { startup } = useSession();
  const acceleratorName = _.get(startup, "acceleratorDef.name", "");
  const { data: companiesData } = useQuery(GROUP_COMPANIES_QUERY, { variables: { startupId: startup?.id }});
  const optionsSelectedFromState = startup?.optionsSelected;
  const [ mutation ] = useUpsertStartupOptions({ showSuccessNotification: false });

  const topCoCompanyCountry = (
      _.find(companiesData?.getGroupCompanies, { isHoldingCompany: true })?.country?.code ||
      companiesData?.getGroupCompanies[0]?.country?.code
  );

  useEffect(() => {
    if (topCoCompanyCountry === 'US') {
      const regionDocumentType = 'UNITED_STATES_DOCUMENTS_TYPES';
      if (optionsSelectedFromState?.regionFilter === regionDocumentType) return;
      const dispatchStartup = {
        optionsSelected: {
          ...optionsSelectedFromState,
          regionFilter: regionDocumentType
        }
      }
      dispatch({ type: "SESSION_UPDATE_STARTUP", startup: dispatchStartup });
      mutation({ id: startup?.id, startupData: { optionsSelected: { regionFilter: regionDocumentType } }});
    } else {
      const regionDocumentType = 'INTERNATIONAL_DOCUMENTS_TYPES';
      if (optionsSelectedFromState?.regionFilter === regionDocumentType) return;
      const dispatchStartup = {
        optionsSelected: {
          ...optionsSelectedFromState,
          regionFilter: regionDocumentType
        }
      }
      dispatch({ type: "SESSION_UPDATE_STARTUP", startup: dispatchStartup });
      mutation({ id: startup?.id, startupData: { optionsSelected: { regionFilter: regionDocumentType } }});
    }
  }, [startup?.id, topCoCompanyCountry]);

  const handleCancel = () => {
    dispatch(clearAssistatText());
    onClose();
  }

  const handleCompleteSubmit = async (values) => {
    const newValues = { ...values, region: topCoCompanyCountry };
    next(newValues);
  }

  const handlePrev = () => {
    prev();
  }

  return (
    <FormBoardResolutionTypeDocument
      initialValues={{}}
      handlePrev={handlePrev}
      key={_.get(context, 'currentQuestionnarie')}
      buttons={{
        cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
      }}
      onCompleteSubmit={handleCompleteSubmit}
      propsTemplate={{
        title: translate('BOARD_RESOLUTION_STEP1_TITLE', { acceleratorName: acceleratorName}),
        subTitle: "Board Resolution",
      }}
      Template={TemplateWizard}
    />
  );
}

export default BoardResolutionTypeDocument;

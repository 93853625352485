import React from 'react'
import _ from "lodash";
import viewClasses from "../../../../../../components/Forms/View/ViewForms.module.scss";
import {Field, FieldArray} from "formik";
import RowAdd from "../../../../../../../components/forms/contents/RowAdd/RowAdd";
import Classes from "../../../../../../../components/inputs/AuthorizedSignatories/AuthorizedSignatories.module.scss";
import TextInput from "../../../../Inputs/TextInput";
import classes from "../../../../SteperForm.module.scss";
import checkOk from "../../../../../../../images/checkOk.svg";

const MultipleStakeholders=({fieldName, shAuthorizedSignatoriesEmailDisabled, handleOnChangeEmailPartyStakeholder}) => {
    return(<>
        <FieldArray
            name={`${fieldName}`}
            render={(arrayHelpers) => (<div>
                {_.get(arrayHelpers.form.values, `${fieldName}`,[]).map((stakeholder, index) => {
                    const isLast = _.get(arrayHelpers.form.values, `${fieldName}`,[]).length === index + 1;
                    return (
                        <div className={viewClasses.ContainerData}>
                            {
                                stakeholder.isAnEntity && stakeholder.authorizedSignatory ?
                                    <React.Fragment>
                                        <div
                                            className={`${viewClasses.ContainerRow} ${viewClasses.ContainerDataPhoto}`}>
                                            <label>Corporate Signatory:</label>
                                        </div>
                                        <div className={`${viewClasses.ContainerRow}`}>
                                            <Field
                                                name={`${fieldName}[${index}].authorizedSignatory.fullName`}>
                                                {({field, form}) => {
                                                    return (
                                                        <RowAdd
                                                            label={"Name:"}
                                                            asterisk={true}
                                                            classNameInput={Classes.input}
                                                            input={
                                                                <TextInput
                                                                    type={'text'}
                                                                    field={field}
                                                                    form={form}
                                                                    disabled={true}
                                                                    value={field.value}
                                                                    classNameError={Classes.errorForm}
                                                                    placeholder={"Enter corporate signatory name..."}
                                                                />
                                                            }
                                                        />
                                                    )
                                                }}
                                            </Field>
                                            <Field
                                                name={`${fieldName}[${index}].authorizedSignatory.email`}>
                                                {({field, form}) => {
                                                    return (
                                                        <RowAdd
                                                            label={"Email Address:"}
                                                            asterisk={true}
                                                            classNameInput={Classes.input}
                                                            input={
                                                                <TextInput
                                                                    type={'email'}
                                                                    field={field}
                                                                    form={form}
                                                                    disabled={shAuthorizedSignatoriesEmailDisabled[index]}
                                                                    onChange={(e) => handleOnChangeEmailPartyStakeholder(form, field, e.target.value, index)}
                                                                    errorMessage={_.get(form.errors, field.name)}
                                                                    classNameError={Classes.errorForm}
                                                                    placeholder={"Email address..."}
                                                                />
                                                            }
                                                        />
                                                    )
                                                }}
                                            </Field>
                                        </div>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <div className={`${viewClasses.ContainerRow}`}>
                                            <Field
                                                name={`${fieldName}[${index}].fullName`}>
                                                {({field, form}) => {
                                                    return (
                                                        <RowAdd
                                                            label={"Name:"}
                                                            asterisk={true}
                                                            classNameInput={Classes.input}
                                                            input={
                                                                <TextInput
                                                                    type={'text'}
                                                                    field={field}
                                                                    form={form}
                                                                    disabled={true}
                                                                    classNameError={Classes.errorForm}
                                                                    placeholder={"Enter corporate signatory name..."}
                                                                />
                                                            }
                                                        />
                                                    )
                                                }}
                                            </Field>
                                            <Field
                                                name={`${fieldName}[${index}].email`}>
                                                {({field, form}) => {
                                                    return (
                                                        <RowAdd
                                                            label={"Email address:"}
                                                            asterisk={true}
                                                            classNameInput={Classes.input}
                                                            input={
                                                                <TextInput
                                                                    type={'email'}
                                                                    field={field}
                                                                    form={form}
                                                                    disabled={shAuthorizedSignatoriesEmailDisabled[index]}
                                                                    onChange={(e) => handleOnChangeEmailPartyStakeholder(form, field, e.target.value, index)}
                                                                    errorMessage={_.get(form.errors, field.name)}
                                                                    classNameError={Classes.errorForm}
                                                                    placeholder={"Email address..."}
                                                                    rightLabel={shAuthorizedSignatoriesEmailDisabled[index] ? <img style={{'position': 'absolute', 'top': '-8px', 'right': '-10px'}} width={30} height={30} src={checkOk}/> : null}
                                                                />
                                                            }
                                                        />
                                                    )
                                                }}
                                            </Field>
                                        </div>
                                    </React.Fragment>
                            }
                            {!isLast && <hr className={classes.Separator} />}
                        </div>)
                })}
            </div>)}
        />
    </>)
}
export default MultipleStakeholders;

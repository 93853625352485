import useMutation from "../../../../../hooks/custom/useMutation";
import useSession from "../../../../session/hooks/useSession";
import { OWNER_EQUITY_GROUP_COMPANY, OWNER_EQUITY_STAKEHOLDER, ShareIncentivePlanFields } from "../fragments";
export const UPSERT_EQUITY = `
${ShareIncentivePlanFields}
${OWNER_EQUITY_GROUP_COMPANY}
${OWNER_EQUITY_STAKEHOLDER}
mutation upsertEquity(
    $groupCompanyId: String,
    $startupId: String, 
    $owner: OwnerInput, 
    $shareType: String,
    $equityData: EquityInput
     $isThereSIP:Boolean! = false) {
      upsertEquity (
          groupCompanyId: $groupCompanyId,
          startupId: $startupId, 
          owner:$owner,
          shareType: $shareType,
          equityData: $equityData
      ) {
           owner {
             ...on StakeHolder {
              ...OwnerEquityStakeholder
            }
            ...on GroupCompany {
              ...OwnerEquityGroupCompany
          }
          }
          shareIncentivePlan @include(if:$isThereSIP)
          {
          ...ShareIncentivePlanFields 
          }         
      }
}
`
const useUpsertEquity = (config, props) => {
    const { startupId } = useSession();
    const [mutation, loading] = useMutation(UPSERT_EQUITY, config, {  ...props })
    return { mutation, loading }
}

export default useUpsertEquity

export default {
  TITLE: 'Edit {{role}} role',
  START_DATE: 'Start date',
  QUESTION_IS_ACTIVE: 'Are they still active in this role?',
  IS_ACTIVE: 'Yes, they are',
  NOT_ACTIVE: 'No, they aren\'t',
  END_DATE: 'End date',
  ROLE_UPDATED_SUCCESS: 'The role has been updated successfully.',
  ROLE_DELETED_SUCCESS: 'The role has been deleted successfully.',
  DELETE_ROLE_QUESTION: 'Delete this role?',
  DELETE_ROLE_TITLE: 'Please confirm that you want to remove this role from {{{stakeholderName}}}\'s profile',
  DELETE_ROLE_DESCRIPTION: 'This action cannot be undone',
  UNABLE: {
    TITLE: 'It\'s not possible to remove this role right now',
    REASON_1: 'You can not remove a role from a stakeholder when they only have one role.',
    REASON_2: 'A stakeholder must be connected to a company through a role.',
    REASON_3: 'You should add a new role before deleting the last role left.',
  }
}
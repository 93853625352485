import _ from 'lodash';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSession from '../../../modules/session/hooks/useSession';
import ServerConnect from '../../utils/ServerConnect';
import Chat from './Chat';
import classes from './Comments.module.scss';

const upsertNotes = `
mutation upsertMatterNote($matterId: String!,$matterNoteData:MatterNoteInput!) {
    upsertMatterNote(matterId: $matterId,matterNoteData:$matterNoteData) {
      id
    }
  }  
`
const removeNote = `
mutation removeMatterNote($matterId: String!, $matterNoteId: String!) {
    removeMatterNote(matterId: $matterId, matterNoteId: $matterNoteId) {
      id
    }
  }  
`

const susciptionsNotes = `
subscription matterEvent($matterId: ID!) {
    matterEvent(matterId: $matterId) {
      data {
        event
        data
      }
    }
  }
  
`

const NotesOnline = (props) => {

    const [notes, setNotes] = useState(props.notes);
    const todayDate = moment(new Date()).format("YYYY-MM-DD");
    const commentTextRef = useRef(null);
    const [inputValue, setInputValue] = useState("");
    const stateButton = inputValue.length === 0 ? "" : "activeButton";
    const params = useParams();
    const containerChatRef = useRef(null);
    const [selectedComment, setSelectedComment] = useState(null);
    const { user } = useSession();
    const handleInput = (event) => {
        setInputValue(event.target.value)
    }

//    const { data, loading } = useSubscriptionClara(susciptionsNotes, { matterId: params.id });

    const handleSend = () => {

        let note = {
            content: inputValue,
            id: selectedComment,
            createdAt: todayDate,
            createdBy: { ...user }
        }


        console.log("insercion", note)
        insertNote(note);
    }


    const automaticScroll = (scrollTop = null) => {
        if (containerChatRef.current) {
            containerChatRef.current.scrollTop = scrollTop || containerChatRef.current.scrollHeight;
        }
    }



    const insertNote = (note) => {

        ServerConnect.graphQlMutation(upsertNotes, { matterId: params.id, matterNoteData: { note: note.content, id: selectedComment } })
            .then(({ upsertMatterNote }) => {
                const index = _.findIndex(notes, not => not.id === _.get(note, "id"));
                let newNotes = _.cloneDeep(notes);
                if (index >= 0) {
                    newNotes[index] = note;
                    setNotes(newNotes);
                } else {
                    setNotes([...notes, { ...note, id: upsertMatterNote.id }]);
                    setTimeout(() => automaticScroll(), 500)
                }
            })


        setInputValue("");
        setSelectedComment(null);
        commentTextRef.current.blur();

    }

    const handleEdit = ({ id, content }) => {
        setInputValue(content);
        setSelectedComment(id);
        commentTextRef.current.focus();
    }
    const handleRemove = ({ id }) => {

        ServerConnect.graphQlMutation(removeNote, { matterId: params.id, matterNoteId: id })
            .then((result) => { })
        const newNotes = _.cloneDeep(notes);
        _.remove(newNotes, note => note.id === id);
        setNotes(newNotes);
    }

    const options = [];
    options.push({ label: "Edit", action: handleEdit, className: `${classes.EditIcon}` })
    options.push({ label: "Remove", action: handleRemove, className: `${classes.DeleteIcon}` })


    return (
        <div className={classes.containerComments}>
            {_.isEmpty(notes) ?
                props.children :
                <div className={`scrollbar ${classes.notesContainer}`} ref={containerChatRef}>
                    {notes.map((note, index) => {
                        const optionsModal = note.createdBy._id ? options : null;
                        return <Chat key={note.id} index={index} options={optionsModal} {...note} fontSize={14} />
                    })}

                </div>


            }
            <div className={classes.containerInputComments} >
                <textarea placeholder={"Add New Note"}
                    rows="27"
                    cols="47"
                    ref={commentTextRef}
                    className={`${classes.Textarea} scrollbar `}
                    style={{ resize: 'none' }}
                    onChange={handleInput}
                    value={inputValue}
                />

                <button onClick={handleSend} className={`${classes.buttonSendComments} ${classes[stateButton]}`} disabled={_.isEmpty(inputValue)} />
            </div>
        </div>
    )
}


export default NotesOnline;
import React from "react";
import _ from "lodash";
import Typography from "src/modules/generic/components/Texts/Typography";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import classes from "src/components/modules/clara/startups/individualStakeholder/Tabs/SourceFundsTab/SourceFunds.module.scss";
import useBusinessRules from "src/modules/clara/hooks/useBusinessRules";
import EmptyState from "src/modules/generic/components/EmptyState";
import {RoleAccess} from "src/v1/components";
import {Constants} from "src/v1/utils";
import Button from "src/modules/generic/components/Buttons/Button";
import EditSourceOfFundsByJurisdiction from "src/modules/startup/Stakeholder/EditSourceOfFundsByJurisdiction";
import useModal from "src/modules/generic/hooks/useModal";
import DetailsTabTitle from "../../../../../../../../../components/templates/DetailsTabTitle";

const SourceOfFundsPanel = ({ data, lock }) => {
  const { translate } = useTranslate();
  const { openModal } = useModal();
  const { isViewer } = useBusinessRules();

  const actionButton = <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
      <Button variant="card" disabled={lock} onClick={() => { openModal(EditSourceOfFundsByJurisdiction, {}) }} >
        {translate("VIEW_COMPANY_TAB_BUTTON_DETAILS_EDIT")}
      </Button>
    </RoleAccess>;

  return (
    <>
      { isViewer() ?
        <EmptyState
          title={translate("DETAILS_FORVIDEN_STATE_VIEWER")}
          iconType={"Not-Visible"}
        />  :
        <>
          <DetailsTabTitle title="Source of funds" buttons={actionButton} />
          <div className={classes.sourceContainer}>
            {_.map(data, (found, index) => {
              const { jurisdictionType } = found;
              if (jurisdictionType === "ADGM") {
                return (
                  <>
                    <div className={classes.row} key={index}>
                      <div className={classes.titleArea}>
                        <Typography variant={"s"} weight={"bold"} className={classes.jurisdiction}>
                          {translate(
                            "MODULES.ASSISTANCEV4.ASSISTANCE_ADGM"
                          )}
                        </Typography>
                      </div>
                    </div>
                    <div className={classes.row}>
                      <div className={classes.titleArea}>
                        <Typography variant={"s"} weight={"light"} className={classes.title}>
                          {translate(
                            "TAB_SOURCE_SOURCE_OF_FUNDS"
                          )}
                        </Typography>
                      </div>
                      <Typography variant={"s"} weight={"semibold"} className={classes.contain}>
                        {_.get(found, "sourceOfFunds", "")}
                      </Typography>
                    </div>
                    <div className={classes.row}>
                      <div className={classes.titleArea}>
                        <Typography variant={"s"} weight={"light"} className={classes.title}>
                          {translate(
                            "TAB_SOURCE_WEALTH"
                          )}
                        </Typography>
                      </div>
                      <Typography variant={"s"} weight={"semibold"} className={classes.contain}>
                        {_.get(found, "sourceOfWealth", "")}
                      </Typography>
                    </div>
                  </>
                )
              }
              if (jurisdictionType === "DIFC") {
                return (
                  <>
                    <div className={classes.row}>
                      <div className={classes.titleArea}>
                        <Typography variant={"s"} weight={"bold"} className={classes.jurisdiction}>
                          {translate(
                            "MODULES.ASSISTANCEV4.ASSISTANCE_DIFC"
                          )}
                        </Typography>
                      </div>
                    </div>
                    <div className={classes.row} key={index}>
                      <div className={classes.titleArea}>
                        <Typography variant={"s"} weight={"light"} className={classes.title}>
                          {translate(
                            "TAB_SOURCE_SOURCE_OF_FUNDS"
                          )}
                        </Typography>
                      </div>
                      <Typography variant={"s"} weight={"semibold"} className={classes.contain}>
                        {_.get(found, "sourceOfFunds", "")}
                      </Typography>
                    </div>
                    <div className={classes.row}>
                      <div className={classes.titleArea}>
                        <Typography variant={"s"} weight={"light"} className={classes.title}>
                          {translate(
                            "TAB_SOURCE_WEALTH"
                          )}
                        </Typography>
                      </div>
                      <Typography variant={"s"} weight={"semibold"} className={classes.contain}>
                        {_.get(found, "sourceOfWealth", "")}
                      </Typography>
                    </div>
                  </>
                )
              }
              if (jurisdictionType === null) {
                return (
                  <>
                    <div className={classes.row}>
                      <div className={classes.titleArea}>
                        <Typography variant={"s"} weight={"bold"} className={classes.jurisdiction}>
                          {translate(
                            "MODULES.ASSISTANCEV4.ASSISTANCE_GENERIC_BUILD"
                          )}
                        </Typography>
                      </div>
                    </div>
                    <div className={classes.row} key={index}>
                      <div className={classes.titleArea}>
                        <Typography variant={"s"} weight={"light"} className={classes.title}>
                          {translate(
                            "TAB_SOURCE_SOURCE_OF_FUNDS"
                          )}
                        </Typography>
                      </div>
                      <Typography variant={"s"} weight={"semibold"} className={classes.contain}>
                        {_.get(found, "sourceOfFunds", "")}
                      </Typography>
                    </div>
                    <div className={classes.row}>
                      <div className={classes.titleArea}>
                        <Typography variant={"s"} weight={"light"} className={classes.title}>
                          {translate(
                            "TAB_SOURCE_WEALTH"
                          )}
                        </Typography>
                      </div>
                      <Typography variant={"s"} weight={"semibold"} className={classes.contain}>
                        {_.get(found, "sourceOfWealth", "")}
                      </Typography>
                    </div>
                  </>
                )
              }
              if (jurisdictionType === "CAYMAN") {
                return (
                  <>
                    <div className={classes.row}>
                      <div className={classes.titleArea}>
                        <Typography variant={"s"} weight={"bold"} className={classes.jurisdiction}>
                          {translate(
                            "MODULES.ASSISTANCEV4.ASSISTANCE_KY"
                          )}
                        </Typography>
                      </div>
                    </div>
                    <div className={classes.row}>
                      <div className={classes.titleArea}>
                        <Typography variant={"s"} weight={"light"} className={classes.title}>
                          {translate(
                            "TAB_SOURCE_SOURCE_OF_FUNDS"
                          )}
                        </Typography>
                      </div>
                      <Typography variant={"s"} weight={"semibold"} className={classes.contain}>
                        {_.get(found, "sourceOfFunds", "")}
                      </Typography>
                    </div>
                    <div className={classes.row}>
                      <div className={classes.titleArea}>
                        <Typography variant={"s"} weight={"light"} className={classes.title}>
                          {translate(
                            "TAB_SOURCE_CRYPTO"
                          )}
                        </Typography>
                      </div>
                      <Typography variant={"s"} weight={"semibold"} className={classes.contain}>
                        {_.get(found, "sourceOfFundsYesNo", false) ? "Yes" : "No"}
                      </Typography>
                    </div>
                  </>
                )
              }
            })}
          </div>
        </>
      }
    </>
  );
};

export default SourceOfFundsPanel;
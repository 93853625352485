import React, { FC } from "react";
import Skeleton from '@material-ui/lab/Skeleton';
import TemplateLabel, {TemplateLabelComponent} from "../../../../../../modules/generic/templates/Label/";

/**
 * Overlay Skeleton Area
 */

export interface TemplateSkeletonComponentProps<PropTypes = any> {
  Template?: TemplateLabelComponent,
  propsTemplate?: PropTypes,
  count?: number,
  noLabel?: boolean,
}

export type TemplateSkeletonComponent<PropsType = any> = FC<TemplateSkeletonComponentProps<PropsType>>

export interface SkeletonAreaProps extends TemplateSkeletonComponentProps {

}

const SkeletonArea: TemplateSkeletonComponent = ({
  Template = TemplateLabel,
  propsTemplate,
  count = 1,
  noLabel = false,
}:SkeletonAreaProps) => {

  return (
    <React.Fragment>
      {Array(count).fill(1).map((el, i) =>
        <Template 
          props = {propsTemplate}
          label={noLabel ? null : <Skeleton variant="text" height={"1.3846rem"} width={"60%"}/>}
          key={i}
        >
          <Skeleton variant="rect" height={"6.1538rem"} width={"100%"}/>
        </Template>
      )}
    </React.Fragment>
  );
};

export default SkeletonArea;
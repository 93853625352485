import React, { useState } from 'react';
import classes from './Button.module.scss';
import classNames from "classnames";
import TextLoading from "./../../../generic/Loading/TextLoading/TextLoading"

const Button = ({ loading, disabled, classNameLoading, onClick, labelLoading, asyncOnClick, classLoading, ...props }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleLoadingOnClick = async (e) => {
        if (onClick) {
            if (asyncOnClick) {
                setIsLoading(true);
                await onClick(e);
                setIsLoading(false);
                return e
            }
            onClick(e)
        }
    };

    return (
        <button  disabled={disabled || loading || isLoading} onClick={handleLoadingOnClick}
            className={classNames(classes.Button, props.className, {
                [classNameLoading]: loading 
            })} {...props} >
            {isLoading || loading ? (
                <TextLoading loading={true} label={labelLoading} className={classLoading} />
            ) : props.children}
        </button>
    )
}

export default Button

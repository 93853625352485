import useLazyQuery from "src/hooks/custom/useLazyQuery";

const GET_STAKEHOLDER_DETAIL_DETAILS_QUERY = `
query getStakeholder($startupId: ID!, $stakeholderId: ID!) {
  getStakeholder(startupId: $startupId, stakeholderId: $stakeholderId) {
    fullName
    dateOfBirth
    nationality {
      code
    }
    birthAddress {
      country {
        code
      }
      city
    }
    profession
    email
    phoneNumber
    linkedinURL
    curriculumVitae {
      id
      name
      size
      type
    }
    nationalities {
      none
      countries {
        country {
          code
        }
        hasPassport {
          description
          question
        }
      }
    }
    address {
      street
      city
      state
      country {
        code
      }
      zipCode
    }
    curriculumVitae {
      id
      name
      size
      type
  }
}
}`;


export const useGetStakeholder = (variables, config) => {
  const { manualQuery, loading, error, data, refetch } = useLazyQuery(GET_STAKEHOLDER_DETAIL_DETAILS_QUERY, variables, config);
  return { loading, error, data, refetch,manualQuery };
};

export default useGetStakeholder;
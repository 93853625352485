import React, { Component } from "react";
import Category from "./Category";
import ZoneChart from "./ZoneChart";
import classes from "./Overview.module.scss";
import Scrollbar from "../../../../components/generic/Scrollbar/Scrollbar";

class Overview extends Component {
  state = {
    showSubRow: {},
  };

  render() {

    const corporateStructureCategory = this.props.categories.find(
      (cat) => cat.key === "CORPORATE_STRUCTURE"
    );
    const equityCategory = this.props.categories.find(
      (cat) => cat.key === "EQUITY"
    );
    const governanceCategory = this.props.categories.find(
      (cat) => cat.key === "GOVERNANCE"
    );
    const teamCategory = this.props.categories.find(
      (cat) => cat.key === "TEAM"
    );
    const ipCategory = this.props.categories.find(
      (cat) => cat.key === "INTELLECTUAL_PROPERTY"
    );
    const otherCategory = this.props.categories.find(
      (cat) => cat.key === "OTHER"
    );

    return (
      <div className={classes.Overview}>
        <div className={classes.ZoneGraph}>
          <ZoneChart
            data={this.props.data}
            categories={this.props.categories}
          />
        </div>
        <div className={classes.ZoneLink}>
          <Scrollbar>
            <Category
              data={this.props.data[corporateStructureCategory.key]}
              title={corporateStructureCategory.title}
              categoryKey={corporateStructureCategory.key}
              onChange={this.props.onChange}
            ></Category>
            <Category
              data={this.props.data[equityCategory.key]}
              title={equityCategory.title}
              categoryKey={equityCategory.key}
              onChange={this.props.onChange}
            ></Category>
            <Category
              data={this.props.data[governanceCategory.key]}
              title={governanceCategory.title}
              categoryKey={governanceCategory.key}
              onChange={this.props.onChange}
            ></Category>
            <Category
              data={this.props.data[teamCategory.key]}
              title={teamCategory.title}
              categoryKey={teamCategory.key}
              onChange={this.props.onChange}
            ></Category>
            <Category
              data={this.props.data[ipCategory.key]}
              title={ipCategory.title}
              categoryKey={ipCategory.key}
              onChange={this.props.onChange}
            ></Category>
            <Category
              data={this.props.data[otherCategory.key]}
              title={otherCategory.title}
              categoryKey={otherCategory.key}
              onChange={this.props.onChange}
            ></Category>
          </Scrollbar>
        </div>
      </div>
    );
  }
}

export default Overview;

import React, {FC} from 'react'
import MachineEmployee from "../MachineEmployee";
import Wizard from "../../../../../generic/components/Wizard";
import ModalAssistant , {ModalAssistantProps} from "../../../../../generic/components/Modal/ModalAssistant";
import useModal from "../../../../../generic/hooks/useModal";

const ModalEmployee:FC<ModalAssistantProps>=({open = false, ...props})=>{
    const {closeModal} = useModal();
    const handleClose = () => {
        closeModal();
    }
    return(
        <ModalAssistant open={open} onClose={handleClose}>
        <Wizard machine={MachineEmployee} onComplete={handleClose}/>
        </ModalAssistant>
    )
}
export default ModalEmployee;
import { useState } from 'react';
import CaptableContext from './';

function CaptableProvider({ children }) {
  const [update, setUpdate] = useState(Date.now());

  const triggerUpdate = () => {
    setUpdate(Date.now());
  };

  return (
    <CaptableContext.Provider value={{ update, triggerUpdate }}>
      {children}
    </CaptableContext.Provider>
  );
}

export default CaptableProvider;

import React, { useState, useEffect } from 'react';

import classes from './Style.module.scss';
import * as Yup from 'yup';
import _ from 'lodash';
import { useSelector } from 'react-redux'
import {gql} from "@apollo/client";

import Form from '../../../../../../../components/forms/Form/Form';
import TextInput from "../../../../../../../components/inputs/Text/TextInput"
import ControllerInput from "../../../../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput"
import useMutation from '../../../../../../../hooks/custom/useMutation'
import SubmitButtonSubForm from '../../../../../../../components/generic/Buttons/SubmitButtonSubForm/SubmitButtonSubForm';
import {updateCompanyDepartments} from "../../../../../../../modules/generic/graphql/cache";

const UPSERT_DEPARTMENT = `
mutation upsertComponentCustomDepartment(
    $startupId: ID!
    $departmentData: DepartmentInput!
  ) {
    upsertComponentCustomDepartment(startupId: $startupId, departmentData: $departmentData ) {
      id
      name
      isDefault
    }
  }`




const FormAddDepartment = ({ startupId, groupCompanyId, handleCloseForm, handleCloseMessage, handleAddValueList, ...props }) => {

    const handleOnCompletedMutation = ({ upsertComponentCustomDepartment }) => {
        handleCloseMessage()
        handleCloseForm()
        handleAddValueList(upsertComponentCustomDepartment)
    }

    const [upsertDepartment] = useMutation(UPSERT_DEPARTMENT, { onCompleted: handleOnCompletedMutation },{update:updateCompanyDepartments});


    const handleSubmit =async (values) => {
      await  upsertDepartment({
            variables: {
                startupId,
                groupCompanyId,
                departmentData: values
            }
        })
    }


    useEffect(() => {
        return () => {
            handleCloseMessage()
        }
    }, [])

    return (
        <Form initialValues={{ data: 'asdasdasd' }}
            schema={Yup.object().shape({
                name: Yup.string().required('This field is required'),

            })} >
            <div className={classes.containerForm}>
                <div className={classes.conteninerText}>

                    <ControllerInput
                        as={TextInput}
                        name={'name'}
                        classNameError={classes.errorForm}
                        placeholder={"Enter Position Name..."}

                    />


                    <SubmitButtonSubForm onClick={handleSubmit}  >
                        Save
                    </SubmitButtonSubForm>
                </div>
                {/*messageError && <span className={classes.error}>
                        {messageError}
                        </span>*/}



            </div>
        </Form>

    )

}


export default FormAddDepartment

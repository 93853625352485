import classnames from "classnames";
import classes from "./classes.module.scss";
import React from "react";
import PropTypes from "prop-types";
import TypographyLocal from "../../../generic/components/Texts/Typography";
import Icon from "../../../generic/components/Icon";

/**
 * Help Link
 */

const HelpLink = ({
  iconName,
  iconSize,
  iconColor,
  linkText,
  hrefUrl,
  ...props
}) => {
  return (
    <React.Fragment>
      <div className={classnames(classes.claraLink)}>
        <div className={classnames(classes.linkIcon)}>
          <Icon icon={iconName} size={iconSize} color={iconColor} />
        </div>
        <a className={classnames(classes.alink)} target="_blank" rel="noopener noreferrer" href={hrefUrl}>
          <TypographyLocal
            component={"span"}
            className={classnames(classes.flowLink)}
            color={"dark-grey"}
          >
            {linkText}
          </TypographyLocal>
        </a>
      </div>
    </React.Fragment>
  );
};

HelpLink.propTypes = {
  /** Property icon Name */
  item: PropTypes.string,
  /** Property icon Size */
  iconSize: PropTypes.string,
  /** Property icon Color */
  iconColor: PropTypes.string,
  /** Property link Text */
  linkText: PropTypes.string.isRequired,
  /** Property link URL */
  hrefUrl: PropTypes.string.isRequired,
};
HelpLink.defaultProps = {
  iconName: "tooltip",
  iconSize: "0.9231rem",
  iconColor: "#828C98",
};

export default HelpLink;
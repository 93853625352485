import _ from 'lodash';
import { useMemo } from 'react';
import useUpsertFormationCompany from 'src/modules/startup/GroupCompany/graphql/mutations/useUpsertFormationCompany';
import { useParams } from "react-router-dom";
import useSession from 'src/modules/session/hooks/useSession';

const useServices = ({initialValues}) => {
  const [upsertFormationCompany] = useUpsertFormationCompany({ showSuccessNotification: false }, {})
  const params = useParams();
  const { startupId } = useSession();

  return useMemo(() => ({
    serviceSetPlaceOfBusiness: async (context, event) => {     
      
      let placeOfBusiness = {
        state: 'Delaware',
        city: 'Wilmington'
      }
      
      if (_.get(context, 'operationInUs')) {
        placeOfBusiness = {
          state: _.get(context, 'state'),
          city: _.get(context, 'city')
        }
      }

      const variables = { 
        startupId, 
        groupCompanyId: initialValues?.id ?? params?.id,

        groupCompanyData:{
          formationData: {
            placeOfBusiness
          }
      }
       
      }

      const result = await upsertFormationCompany({
        variables
      });
     
      return result;
    }
  }),[]);
}
export default useServices;
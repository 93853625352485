import React, { FC } from 'react'
import { StepProps } from "../../../../../generic/components/Wizard/Step";
import IndividualOrCompanyStakeholderFormStep from "../../../core/wizards/steps/IndividualOrCompanyStakeholderFormStep/IndividualOrCompanyStakeholderFormStep";
import _ from "lodash";
import useCustomMachine from "../../../../../generic/hooks/useCustomMachine";

const IndividualOrCompanyStepFormInvestor: FC<StepProps> = () => {
  const { state } = useCustomMachine()
  return (
    <IndividualOrCompanyStakeholderFormStep 
      isIndividual={!_.get(state, 'context.data.stakeholder_is_individual_or_company.isEntity')}
      role={'INVESTOR'}
    />
  )
}
export default IndividualOrCompanyStepFormInvestor
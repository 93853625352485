import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TextInput from 'src/components/inputs/Text/TextInput';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateLabel from 'src/modules/generic/components/Inputs/LabelInput';
import * as Yup from 'yup';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import Checkbox from 'src/components/inputs/Checkbox/Checkbox';
import _ from 'lodash';

/**
* Description Step: Add a third party stakeholder to be your signatory
* 
* @returns 
*/
function AddThirdPartyFormStep() {

  const { next, context, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return {
      AddThirdPartyFormStep: context?.AddThirdPartyFormStep,
      initialData: context?.initialData
    }
  }, [context]);

  const handleCancel = (values) => {
    cancel();
  }

  const handlePrev = (values) => {
    prev();
  }

  const handleSubmit = async (values) => {
    next(values)
  }

  useEffect(() => {
    dispatch(addAssistantText('This stakeholder will be added to the map and you will have to complete tasks for them which will include providing their personal information and uploading their identification documents. Click <a rel=\'noopener noreferrer\' target=\'_blank\' href=\'https://help.clara.co/en/articles/6412582-individual-kyc\' >here</a> to read more on the information required.'));
    return () => {
      dispatch(clearAssistatText())
    }
  }, [dispatch])

  const schema = Yup.object().shape({
    nameField: Yup.string().required('Required'),
  })

  return (

    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: 'What is their name?',
          skeleton: null,
          subTitle: 'ADGM Authorised Signatory',
          variant: 'simple'
        }}
        buttons={{
          previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{'Add'}</SubmitButton>,
        }}
      >
        <TemplateLabel
          label={'Full legal name'}
          isRequired={true}
        >
          <ControllerInput
            render={TextInput}
            name='nameField'
            placeholder={'Full legal name'}
          />
        </TemplateLabel>
        <TemplateLabel
          label={'Company'}
          isRequired={true}
        >
          <ControllerInput
            render={Checkbox}
            name='company'
            variant='col_1'
            functionCompare={(value1, value2) => value1.id === value2.id}
            options={[{ label: _.get(initialValues, 'initialData.companyData.name'), value: _.get(initialValues, 'initialData.idGroupCompany'), disabled: true }]}
            defaultValue={[_.get(initialValues, 'initialData.idGroupCompany')]}
            value={[_.get(initialValues, 'initialData.idGroupCompany')]}
          />
        </TemplateLabel>
      </TemplateWizard>
    </Form>
  );
}

export default AddThirdPartyFormStep;


import EditGeneralDetails from '../steps/EditGeneralDetails';
import EditGeneralDetailsManagedByClara from '../steps/EditGeneralDetailsManagedByClara';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import { useMemo } from 'react';

const useSteps = () => {
  return useMemo(() => ({
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    pincasData: {
      render: TemplateWizardSkeletonMachine,
    },
    EditGeneralDetailsForm: {
      render: EditGeneralDetails,
    },
    EditGeneralDetailsManagedByClaraForm: {
      render: EditGeneralDetailsManagedByClara,
    },
    SaveGeneralDetails: {
      render: TemplateWizardSkeletonMachine,
    },
  }), []);
}

export default useSteps;

import _ from 'lodash'
import { HOST_NAME_DEPLOY, URL_API, URL_API_SOCKET, CDNHots } from '../config/env_variables';

export const getURL = (usePublic) => {
    const publicAPIUrl = usePublic ? process.env.REACT_APP_PUBLIC_URL : null;
    if (HOST_NAME_DEPLOY.includes(window.location.hostname)) return `${_.get(URL_API, window.location.hostname)}`;
    return publicAPIUrl || process.env.REACT_APP_API_URL || `${window.location.protocol}//${window.location.hostname}`;
};

export const getURLApi = () => {
    return `${getURL()}/api`
};

export const getURLGQL = () => {
    return `${getURL()}/graphql`
};

export const getURLWebSocket = () => {
    if (HOST_NAME_DEPLOY.includes(window.location.hostname)) return `${_.get(URL_API_SOCKET, window.location.hostname)}/graphql`;
    return process.env.REACT_APP_API_URL_SOCKETS || `wss://${window.location.hostname}/graphql`;
};

export const getURLAssets = (urls) => {
    const isLocalhost = window.location.hostname.includes("localhost");
    if (isLocalhost) {
        return process.env.REACT_APP_CDN_URL || CDNHots;
    } else {
        return CDNHots;
    }
};

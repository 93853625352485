import ReviewAddressesHoldingCompanyStepForm from "../steps/ReviewAddressesHoldingCompanyStepForm";
import ReviewAddressesTechLicenseAddressesStepForm from "../steps/ReviewAddressesTechLicenseAddressesStepForm";
import ReviewAddressesTechLicenseStepForm from "../steps/ReviewAddressesTechLicenseStepForm";
import TemplateWizardSkeletonMachine from "src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine";
import { useMemo } from "react";

const useSteps = () => {
  return useMemo(
    () => ({
      initialData: {
        render: TemplateWizardSkeletonMachine,
      },
      pincasData: {
        render: TemplateWizardSkeletonMachine,
      },
      ReviewAddressesTechLicenseStepForm: {
        render: ReviewAddressesTechLicenseStepForm,
      },
      ReviewAddressesHoldingCompanyStepForm: {
        render: ReviewAddressesHoldingCompanyStepForm,
      },
      ReviewAddressesTechLicenseAddressesStepForm: {
        render: ReviewAddressesTechLicenseAddressesStepForm,
      },
      SaveStep: {
        render: TemplateWizardSkeletonMachine,
      },
    }),
    []
  );
};

export default useSteps;

import _ from 'lodash';
import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import ModalTour from '../../../modules/generic/components/Modal/ModalSteps';
import useContenful from "../../../modules/generic/hooks/useContenful";
import useFetch from "../../../modules/generic/hooks/useFetch";
import { LeftContentTour, RightContentTour } from '../../../modules/Onboarding/components';
import { Constants } from "../../utils/constants";
import useFinishTour from "../../../modules/Onboarding/graphql/useFinishTour";

export default function Tutorial() {

  const { response } = useFetch({ url: `/contentful/tour/1VLQ1TEvAg9rrFbUIjSMAF` });
  const { getDataTour } = useContenful();
  const { mutation: introductionFinished } = useFinishTour();
  const dispatch = useDispatch();

  const pages = useMemo(() => {
    return getDataTour(_.get(response, 'content.page', []));
  }, [JSON.stringify(response)]);


  const handleFinish = () => {
    introductionFinished();
    dispatch({ type: "GO_TO", page: Constants.PAGES.MODULES.dashboard });
  }

  return (
    <>
      <ModalTour handleLastStep={handleFinish} open={true} pages={pages} componentLeftContend={LeftContentTour} componentRightContend={RightContentTour} />
    </>
  );
}


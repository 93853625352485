import useMutation from '../../../custom/useMutation'
export const UPSERT_DOCUMENT_FORMATION = `
mutation addIdentityDocumentStakeholder($startupId: ID!, $stakeholderId: ID!, $identityDocumentData: IdentityDocumentInput) {
    addIdentityDocumentStakeholder(
      startupId: $startupId
      stakeholderId: $stakeholderId
      identityDocumentData: $identityDocumentData
    ) {
      id
      number
      nationality
      expiryDate
      current
      documents {
        signedAs
        id
        type {
          code
        }
        status
        file {
          id
          name
        }
      }
    }
  }`

const useAddDocumentFormation = (props={}) => {
    //const { startupId } = useSession();
    const mutation = useMutation(
        UPSERT_DOCUMENT_FORMATION,
        { showSuccessNotification: false ,...props},
       // { refetchQueries: [{ query: gql`${COMPANY_CORPORATE_MAP}`, variables: { startupId: startupId } }], ...props }
    )

    return mutation
}

export default useAddDocumentFormation

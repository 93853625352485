import _ from 'lodash'
import useQuery from "../../../custom/useQuery";
import { RoleFields } from "../../../../modules/stakeholder/graphql/typePolicies";
import {NoticesFragment, AuthorizedSignatoryFragment} from "../../../../modules/startup/Stakeholder/graphql/fragments";

const GET_STAKEHOLDERS = `
${RoleFields}
${NoticesFragment}
${AuthorizedSignatoryFragment}
query stakeholderCompanyView($startupId: ID! ) {
    stakeholderList(startupId:$startupId) {
		email
		passportNumber
		registeredNumber
		nationality {name code}
		jurisdiction
		type
		phoneNumber
		title
		id
		isAnEntity
		address {
			city
			state
			country {name code}
			zipCode 
			street
		}
		fullName
		roles { ...RoleFields }
		isFounder
		startupPosition {
			id
			position {
				id
				code
				name
				isDefault
				responsibilities {
					code
					text
					name
					isDefault
				}
			}
			department {
          		id
          		name
          		isDefault
        	}
		}
		authorizedSignatory {...AuthorizedSignatoryFragment}
		recipientNotice {...NoticesFragment}
	}
  }
`;
const useGetStakeholders = ({ variables, ...props }) => {
    const { loading, error, data, refetch, ...propsDesc } = useQuery(GET_STAKEHOLDERS, variables, null, props);

    return { loading, error, data: _.get(data, 'stakeholderList', []), refetch, ...propsDesc };
};
export default useGetStakeholders;

import ControllerInput, { ControllerInputProps } from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import React, { FC, ReactNode } from "react";
import TemplateLabel, { TemplateLabelProps } from "src/modules/generic/components/Inputs/LabelInput";
import FiledBanner from "src/modules/generic/components/FieldBanner";
import useGetShowBanner from "./useGetShowBanner";

interface InputLabelWithBannerProps {
  component: ReactNode,
  templateLabelProps: Omit<TemplateLabelProps, 'children' | 'showBanner' | 'showBanner' | 'variant'>
  controllerInputProps: Omit<ControllerInputProps, 'render'> & { name: string }
  fieldsToWatch?: string[]
  variant?: 'banner' | 'bannerLabel',
  bannerLogicType: 'touched' | 'values'
  applyLogic?: (values: any | any[]) => boolean
  // THIS IS FOR HIDE THE BANNER NO MATTER WHAT
  show?: boolean
}
export const InputLabelWithBanner: FC<InputLabelWithBannerProps> = ({ 
  component,
  templateLabelProps,
  controllerInputProps,
  fieldsToWatch = [],
  variant = 'banner',
  bannerLogicType,
  applyLogic,
  show = true 
}) => {

  const showBanner = useGetShowBanner(bannerLogicType, controllerInputProps.name, fieldsToWatch);

  return <TemplateLabel
    {...templateLabelProps}
    variant={variant}
    showBanner={showBanner(applyLogic) && show}
  >
    <ControllerInput {...controllerInputProps} render={component} />
  </TemplateLabel>;
}
interface InputWithBannerProps {
  component: ReactNode,
  info: string,
  controllerInputProps: Omit<ControllerInputProps, 'render'> & { name: string },
  fieldsToWatch?: string[],
  bannerLogicType: 'touched' | 'values',
  applyLogic?: (values: any | any[]) => boolean,
  // THIS IS FOR HIDE THE BANNER NO MATTER WHAT
  show?: boolean,
  showTop?: boolean,
  paddingRight?: string,
}
export const InputWithBanner: FC<InputWithBannerProps> = ({
  component,
  info,
  controllerInputProps,
  fieldsToWatch = [],
  bannerLogicType,
  applyLogic,
  show = true,
  showTop = false,
  paddingRight,
}) => {

  const showBanner = useGetShowBanner(bannerLogicType, controllerInputProps.name, fieldsToWatch);

  return (
    <>
      {showTop ? (
        <>
          {showBanner(applyLogic) && show && <FiledBanner title={info} paddingRight={paddingRight} />}
          <ControllerInput {...controllerInputProps} render={component} />
        </>
      ) : (
        <>
          <ControllerInput {...controllerInputProps} render={component} />
          {showBanner(applyLogic) && show && <FiledBanner title={info} paddingRight={paddingRight}/>}
        </>
      )}
    </>
  );
}

import React, { FC } from "react";
import { Fade } from "@material-ui/core";
import classes from "./ErrorInput.module.scss";
import Icon from "../../Icon";

export interface ErrorInputProps {
  /**  Message error  */
  message: string;
  /** className */
  classNameError?: string;
  /** If show error icon */
  showErrorIcon?: boolean;
}
/**
 * Error Input
 */
const ErrorInput: FC<ErrorInputProps> = ({
  message,
  classNameError,
  showErrorIcon = true,
}: ErrorInputProps) => {
  return (
    <Fade in={!!message} unmountOnExit={true}>
      <div className={`${classes.ErrorInput} ${classNameError}`}>
        <span>{message}</span>
        {showErrorIcon ? (
          <Icon
            className={classes.ErrorIcon}
            icon="Exclamation"
            isClara={true}
            size="1rem"
          />
        ) : null}
      </div>
    </Fade>
  );
};
export default ErrorInput;

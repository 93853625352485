import {Field, FieldArray} from "formik";
import {useQuery,gql} from '@apollo/client';
import React, {useEffect, useRef, useState} from 'react';
import classes from "../../../containers/Forms/SteperForm.module.scss";
import {AddInputFull} from "../../index";
import styles from "./Styles.module.scss";
import ServerConnect from '../../../utils/ServerConnect'
import ClaraSelect from "../ClaraSelect/ClaraSelect";
import * as _ from "lodash";

const GET_CURRENCIES = gql`
query countryList($code:String, $name:String, $language:String!){
        countryList(code:$code, name:$name, language:$language){
        name
        code
        currency {currencyCode currencyName currencySymbol}
    }
}
`;
function ClaraCurrencySelector({
	callbackUpdate,
	currencyField,
	transactionCurrencyField = currencyField ? currencyField : 'transactionCurrency',
	label,
	min,
	list,
	tooltips,
    placeholder,
	additionalStyle,
	setFieldValue,
	initialValue,
	type 
}) {
    const client = ServerConnect.getUniqueApolloClient()
    const {loading, error, data} = useQuery(GET_CURRENCIES, {
        variables: {code: "", name: "", language: 'en'}, fetchPolicy: 'no-cache', client
    });
    const currencies=useRef([])
    const [initial]=useState(initialValue)
    const [currencyList,setCurrencyList]=useState(list?list:[])
    useEffect(()=>{
        if(initialValue) {
           const currency=currencyField ? `${currencyField}[0]`: 'currencies[0]id'
            setFieldValue(currency, initialValue)
        }},[initial])
    if (loading || error) {
        return <p>Loading ...</p>;
    } else {
        if (!currencyList.length) {
            const currenciesList = _.uniqBy(_.map(data.countryList, ((item) => (
                {
                    label: item.currency.currencyCode + " " + item.currency.currencySymbol + " " + item.currency.currencyName,
                    id: item.currency.currencyCode
                }
            ))), 'id')
            setCurrencyList(currenciesList)
        }
            const handleCallbacksCurrency = (currency,a,b,c,field) => {
            if(type === 'Add'){
                if(currency === null) {
                   const currencyList=currencies.current.filter(currency=>currency.name!==field.name)
                    currencies.current=currencyList
                    if(currencies.current[0])
                    setFieldValue(transactionCurrencyField, currencies.current[0].value)
                } else {
                if(field.value) {
                    if (!(currencies.current.some(currency => currency.name === field.name && currency.value === field.value))) {
                        if (currencies.current.some(currency => currency.value !== field.value && currency.name === field.name)) {
                            const currencyList = currencies.current.filter(currency => currency.name !== field.name)
                            currencies.current = currencyList
                        }
                        if (currencies.current.some(currency => currency.value === field.value && currency.name !== field.name)) {
                           setFieldValue(field.name, null)

                        } else {
                            currencies.current.push(field)
                            setFieldValue(transactionCurrencyField, currencies.current[0].value)
                            if (callbackUpdate) {
                                callbackUpdate(currencies.current)
                            }
                        }
                    }
                }
                }
                }
            else{
                if (callbackUpdate) {
                    callbackUpdate(currency)
                }
            }
        };

            return (
                    <div className={`${styles.Select} ${styles[additionalStyle]}`}>
						{type === 'Add'
							?
								<FieldArray
									name={`currencies`}
									render={(formikProps) => (
										<AddInputFull
											fieldArrayName={`currencies`}
											formikProps={formikProps}
											label={"Add Currencies"}
											min={min?min:1}
										>
											{({ preFieldKey }) =>{
												return(
													<React.Fragment>
														<div className={classes.ClaraInputSelect}>
															<Field name={currencyField ? currencyField : `${preFieldKey}id`}>
																{({ field, form }) =>{
																	return(
																		<ClaraSelect
																			field={field}
																			form={form}
																			label={label ? label : "Transaction Currencies:"}
																			lists={currencyList}
																			mode={"classic"}
																			callbacks={{updateValues:handleCallbacksCurrency}}
																			placeholder={placeholder?placeholder:"Search Business Currency.."}
																			autocomplete={"off_1"}
																			tooltip={tooltips}
																		/>
																	)}}
															</Field>
														</div>
													</React.Fragment>
												)}}
										</AddInputFull>
									)} />
							:
								// <div className={classes.ClaraInputSelect}>
								<div className={`${styles.Select} ${styles[additionalStyle]}`}>
									<Field name={currencyField}>
										{({ field, form }) =>{
											return(
												<ClaraSelect
													field={field}
													form={form}
													label={label ? label : "Transaction Currencies:"}
													lists={currencyList}
													mode={"classic"}
                                                    required={true}
													callbacks={{updateValues:handleCallbacksCurrency}}
													placeholder={placeholder?placeholder:"Search Business Currency.."}
													autocomplete={"off_1"}
													tooltip={tooltips}
												/>
											)}}
									</Field>
								</div>
						}
                    </div>
            )
}}
export default ClaraCurrencySelector

import React, { FC, useState } from "react";
import Typography from 'src/modules/generic/components/Texts/Typography';
import { CheckboxOptions } from "../../../generic/components/Inputs/Checkbox";
import ModalDelete from "../../../generic/components/Modal/ModalDelete";
import RemoveAsFounderForm from "../../../generic/forms/RemoveAsFounder";
import useModal from "../../../generic/hooks/useModal";
import useTranslate from "../../../generic/hooks/useTranslate";
import TemplateRemove from "../../../generic/templates/Modal/TemplateRemove";
import useSession from "../../../session/hooks/useSession";
import { ModalProps } from './../../../generic/components/Modal/Default';
import Button from "../../../generic/components/Buttons/Button";
import UnableToRemove from './components/UnableToRemove';
import classes from './classes.module.scss';
import useDeleteStakeholderRoles from "../../../../components/modules/clara/startups/individualStakeholder/graphql/mutation/deleteRole";

interface RemoveAsFounderProps extends ModalProps {
    stakeholder: any;
    canRemove: boolean;
}

const RemoveAsFounder: FC<RemoveAsFounderProps> = ({ open: openParam = false, stakeholder, canRemove}) => {
    const { closeModal } = useModal();
    const { translate } = useTranslate();
    const { startupId } = useSession();
    const [open, setOpen] = useState(openParam);
    const { mutation } = useDeleteStakeholderRoles({
        showSuccessNotification: true,
        successMessage: 'MODULES.STAKEHOLDER.EDIT_ROLE.ROLE_DELETED_SUCCESS'
    });

    const handleCancel = ()=> {
        closeModal();
        setOpen(false);
    }

    const onSubmit = async ()=> {
        const variables = {
            startupId,
            stakeholderId: stakeholder?.id,
            stakeholderRolesData: {
                isFounder: false
            }
        }

        await mutation({ variables });
        closeModal();
        setOpen(false);
    }

    const options: Array<CheckboxOptions<string>> = [{ value:'remove', label: translate('MODULES.STAKEHOLDER.REMOVE_AS_FOUNDER.CHECKBOX') }];
    
    return(
        <ModalDelete open={open}>
        {canRemove ?
          <RemoveAsFounderForm
            propsTemplate={{
                title: translate('MODULES.STAKEHOLDER.REMOVE_AS_FOUNDER.TITLE', {stakeholder:stakeholder.fullName}),
                description: <Typography variant='body' weight='regular' color='error'>{translate('MODULES.STAKEHOLDER.REMOVE_AS_FOUNDER.DESCRIPTION')}</Typography>
            }}
            onCompleteSubmit={onSubmit}
            checkBoxProps={{options, isMultiple:false}}
            buttons={{
                cancel: <Button type={'button'} 
                onClick={handleCancel}>Cancel</Button>
            }}
            Template={TemplateRemove} 
          /> : <UnableToRemove
                  propsTemplate={{
                    title: translate('MODULES.STAKEHOLDER.REMOVE_AS_FOUNDER.UNABLE.TITLE', {stakeholder:stakeholder.fullName}),
                    description: <Typography variant='body' weight='regular'>{translate('MODULES.STAKEHOLDER.REMOVE_AS_FOUNDER.UNABLE.DESCRIPTION')}</Typography>,
                  }}
                className= {classes.container}
                onCompleteSubmit={handleCancel}
                buttons={{}}
                Template={TemplateRemove}
          >
            <Typography variant='body' weight='semibold'>
              <li>{translate('MODULES.STAKEHOLDER.REMOVE_AS_FOUNDER.UNABLE.REASON_1')}</li>
            </Typography>
          </UnableToRemove>
        }
        </ModalDelete>
    )
}
export default RemoveAsFounder;
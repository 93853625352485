import React, { useState, useEffect, useRef } from "react";
import classes from "./VerificationCode.module.scss";
import ErrorInput from "../../../modules/generic/components/Inputs/ErrorInput";

const VerificationCode = ({ name, onChange, errorMessage, error, ...props }) => {
  const [firstInput, setFirstInput] = useState(null);
  const [secondInput, setSecondInput] = useState(null);
  const [thirdInput, setThirdInput] = useState(null);
  const [fourthInput, setFourthInput] = useState(null);
  const [fifthInput, setFifthInput] = useState(null);
  const [sixthInput, setSixthInput] = useState(null);
  const ref_input1 = useRef();
  const ref_input2 = useRef();
  const ref_input3 = useRef();
  const ref_input4 = useRef();
  const ref_input5 = useRef();
  const ref_input6 = useRef();

  const handlePaste = (e) => {
    if (e.clipboardData.getData("Text").length === 6) {
      var data = e.clipboardData.getData("Text");
      data = data.split("");
      setFirstInput(data[0]);
      setSecondInput(data[1]);
      setThirdInput(data[2]);
      setFourthInput(data[3]);
      setFifthInput(data[4]);
      setSixthInput(data[5]);
      onChange(parseInt(e.clipboardData.getData("Text")));
    }
    return e.clipboardData.getData("Text");
  };

  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  useEffect(() => {
    if (
      firstInput && firstInput.length === 1 
      && secondInput && secondInput.length === 1 
      && thirdInput && thirdInput.length === 1 
      && fourthInput && fourthInput.length === 1 
      && fifthInput && fifthInput.length === 1 
      && sixthInput && sixthInput.length === 1
    ) {
      onChange (
        firstInput + secondInput + thirdInput + fourthInput + fifthInput + sixthInput
      );
    } else {
      if (firstInput !== null || secondInput !== null || thirdInput !== null || fourthInput !== null || fifthInput !== null || sixthInput !== null) {
        onChange(null);
      }
    }
  }, [firstInput, secondInput, thirdInput, fourthInput, fifthInput, sixthInput]);  

  return (
    <>
      <div className={classes.container}>
        <input
          ref={ref_input1}
          type="number"
          value={firstInput}
          maxLength="1"
          onInput={maxLengthCheck}
          onPaste={(e) => {
            handlePaste(e);
          }}
          onChange={(e) => {
            setFirstInput(e.target.value);
            if (e.target.value.length !== 0) {
              ref_input2.current.focus();
            }
          }}
          size="1"
          min="0"
          max="9"
          pattern="[0-9]{1}"
          autoFocus={true}
          disabled={props.disabled}
        />
        <input
          ref={ref_input2}
          type="number"
          value={secondInput}
          maxLength="1"
          onInput={maxLengthCheck}
          onPaste={(e) => {
            handlePaste(e);
          }}
          onChange={(e) => {
            setSecondInput(e.target.value);
            if (e.target.value.length === 0) {
              ref_input1.current.focus();
            } else {
              ref_input3.current.focus();
            }
          }}
          size="1"
          min="0"
          max="9"
          pattern="[0-9]{1}"
          disabled={props.disabled}
        />
        <input
          ref={ref_input3}
          className={classes.thirdInput}
          value={thirdInput}
          type="number"
          maxLength="1"
          onInput={maxLengthCheck}
          onPaste={(e) => {
            handlePaste(e);
          }}
          onChange={(e) => {
            setThirdInput(e.target.value);
            if (e.target.value.length === 0) {
              ref_input2.current.focus();
            } else {
              ref_input4.current.focus();
            }
          }}
          size="1"
          min="0"
          max="9"
          pattern="[0-9]{1}"
          disabled={props.disabled}
        />
        <input
          ref={ref_input4}
          type="number"
          value={fourthInput}
          maxLength="1"
          onInput={maxLengthCheck}
          onPaste={(e) => {
            handlePaste(e);
          }}
          onChange={(e) => {
            setFourthInput(e.target.value);
            if (e.target.value.length === 0) {
              ref_input3.current.focus();
            } else {
              ref_input5.current.focus();
            }
          }}
          size="1"
          min="0"
          max="9"
          pattern="[0-9]{1}"
          disabled={props.disabled}
        />
        <input
          ref={ref_input5}
          type="number"
          value={fifthInput}
          maxLength="1"
          onInput={maxLengthCheck}
          onPaste={(e) => {
            handlePaste(e);
          }}
          onChange={(e) => {
            setFifthInput(e.target.value);
            if (e.target.value.length === 0) {
              ref_input4.current.focus();
            } else {
              ref_input6.current.focus();
            }
          }}
          size="1"
          min="0"
          max="9"
          pattern="[0-9]{1}"
          disabled={props.disabled}
        />
        <input
          ref={ref_input6}
          type="number"
          value={sixthInput}
          maxLength="1"
          onInput={maxLengthCheck}
          onPaste={(e) => {
            handlePaste(e);
          }}
          onChange={(e) => {
            setSixthInput(e.target.value);
            if (e.target.value.length === 0) {
              ref_input5.current.focus();
            }
          }}
          size="1"
          min="0"
          max="9"
          pattern="[0-9]{1}"
          disabled={props.disabled}
        />
      </div>
      {errorMessage && <ErrorInput message={errorMessage} component={error} />}
    </>
  );
};

export default VerificationCode;

import React, { Component } from "react";
import classes from "./Tag.module.scss";
import { connect } from "react-redux";
import { goToPage, goToPrevPage } from "../../../store/actions/page";
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

class Tag extends Component {

  state = {};

  render() {
    const { type, params, show, id, onClick, customLabel, marginLeft, tooltipText } = this.props;
    let labelType = type === "" || null ? "new" : type;
    let label = "";
    let typeClass = "";

    switch (labelType) {
      case "new":
        label = "New";
        typeClass = classes.newTag;
        break;
      case "replaced":
        label = "Replaced";
        typeClass = classes.replacedTag;
        break;
      case "inactiveShareProfile":
        label = "Inactive";
        typeClass = classes.inactiveShareProfile;
        break;
      case "invitedShareProfile":
        label = "Invited";
        typeClass = classes.invitedShareProfileTag;
        break;
      case "scopingForm":
        label = "Scoping Form";
        typeClass = classes.scopingFormTag;
        break;
      case "reviewScopingForm":
        label = "Review Scoping Form";
        typeClass = classes.scopingFormTag;
        break;
      case "individual":
        label = "Individual";
        typeClass = classes.engagementTag;
        break;
      case "corporateEntity":
        label = "Company";
        typeClass = classes.engagementTag;
        break;
      case "holdingCompany":
        label = "Holding Company SPV";
        typeClass = classes.companyTypeTag;
        break;
      case "foundation":
        label = "Foundation";
        typeClass = classes.companyTypeTag;
        break;
      case "techStartup":
        label = "Tech Startup";
        typeClass = classes.companyTypeTag;
        break;
      case "operatingCompany":
        label = "Operating Company";
        typeClass = classes.companyTypeTag;
        break;
      case "other":
        label = "Other";
        typeClass = classes.companyTypeTag;
        break;
      case "KYCForm":
        label = "KYC Form";
        typeClass = classes.kycTag;
        break;
      case "reviewKYCForm":
        label = "Review KYC Form";
        typeClass = classes.kycTag;
        break;
      case "individualKYCForm":
        label = "Individual KYC Form";
        typeClass = classes.kycTag;
        break;
      case "thirdPartyForm":
        label = "Third Party";
        typeClass = classes.engagementTag;
        break;
      case "corporateKYCForm":
        label = "Company KYC Form";
        typeClass = classes.kycTag;
        break;
      case "questionnaireForm":
        label = "Questionnaire Form";
        typeClass = classes.kycTag;
        break;
      case "reviewQuestionnaireForm":
        label = "Review Questionnaire";
        typeClass = classes.kycTag;
        break;
      case "reviewDocuments":
        label = "Documents in Review";
        typeClass = classes.kycTag;
        break;
      case "awaitingSignatures":
        label = "Awaiting Signatures";
        typeClass = classes.kycTag;
        break;
      case "approvedDocuments":
        label = "Documents Approved";
        typeClass = classes.approvedDocuments;
        break;
      case "signedDocuments":
        label = "Documents Signed";
        typeClass = classes.approvedDocuments;
        break;
      case "applicationSubmitted":
        label = "Application Submitted";
        typeClass = classes.approvedDocuments;
        break;
      case "declinedDocuments":
        label = "Documents Declined";
        typeClass = classes.declinedDocuments;
        break;
      case "incorporated":
        label = "Incorporated";
        typeClass = classes.approvedDocuments;
        break;
      case "proposalSent":
        label = "Proposal Sent";
        typeClass = classes.approvedDocuments;
        break;
      case "proposalDeclined":
        label = "Proposal Declined";
        typeClass = classes.declinedDocuments;
        break;
      case "engagementSent":
        label = "Engagement Sent";
        typeClass = classes.approvedDocuments;
        break;
      case "engagementPaid":
        label = "Engagement Paid";
        typeClass = classes.approvedDocuments;
        break;
      case "engagementDeclined":
        label = "Engagement Declined";
        typeClass = classes.declinedDocuments;
        break;
      case "USER_OWNER":
        typeClass = classes.ownerProfileTag;
        label = "Owner";
        break;
      case "USER_MANAGER":
        typeClass = classes.managerProfileTag;
        label = "Manager";
        break;
      case "USER_EDITOR":
        typeClass = classes.editorProfileTag;
        label = "Editor";
        break;
      case "USER_VIEWER":
        typeClass = classes.viewerProfileTag;
        label = "Viewer";
        break;
      case "USER_INACTIVE":
        label = "Inactive";
        typeClass = classes.inactiveProfileTag;
        break;
      case "GC_DISSOLVED":
        label = "Dissolved";
        typeClass = classes.dissolvedProfile;
        break;
      case "isFounderSquare":
        label = "Founder";
        typeClass = classes.isFounderSquareTag;
        break;
      case "isFounder":
        label = "Founder";
        typeClass = classes.isFounderTag;
        break;
      case "USER_COLLABORATOR":
        typeClass = classes.collaboratorProfileTag;
        label = "Collaborator";
        break;
      case "CUSTOM":
        typeClass = classes.customTag;
        label = customLabel;
        break;
      default:
        break
    }


    const internalComponent = (
      <Box className={`${classes.tagContainer} ${typeClass}`} onClick={onClick} sx={{ marginLeft: marginLeft ? marginLeft : "4px"}}>
        {label}
      </Box>
    );

    if (show) {
      return (
        <>
          {tooltipText ? (
            <Tooltip title={tooltipText}>
              {internalComponent}
            </Tooltip>
          ) : (
            <>
              {internalComponent}
            </>
          )}
        </>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => ({
  session: state.session,
});
const mapDispatchToProps = (dispatch) => ({
  goToPrevPage: () => {
    goToPrevPage(dispatch);
  },
  goToPage: (page, params) => {
    goToPage(dispatch, page, params);
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Tag);

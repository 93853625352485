export default {
  STEP_1: {
    TITLE:'Ensure you’ve added all companies, stakeholders & documents',
    SUBTITLE:'Submit application',
    DESCRIPTION: 'Before submitting your application to {{{acceleratorName}}}, make sure you’ve added all your <b>companies, stakeholders & documents</b> to your profile.',
    TOOLTIP1: 'Make sure you’ve added all your companies, stakeholders & documents before submitting your application.',
    CONFIRMATION_YES: 'Yes, I have added them all',
    CONFIRMATION_NO: 'No, I have to add more',
  },
  STEP_2: {
    TITLE:'Application summary',
    SUBTITLE:'Submit application',
    TOOLTIP:'Your application is now complete. Please review the details and make sure they’re correct before submitting. If you need to make any changes or add any details, you can do it from the Accelerators page from the top right menu.',
  // CONFIRMATION_YES: 'Yes, I have added them all',
  // CONFIRMATION_NO: 'No, I have to add more',
  TEXT_QUESTIONNAIRE: 'This information can be viewed and edited by navigating to the startup profile page.',
  COMPANIES: 'Companies',
  STAKEHOLDERS: 'Stakeholders',
  QUESTIONNAIRE: 'Questionnaire',
  DOCUMENTS:'Documents',
},
}
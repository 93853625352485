import React from 'react'
import * as Yup from 'yup'
import Form from '../../../../components/forms/Form/Form'
import useTranslate from '../../../generic/hooks/useTranslate'
import TextInput from '../../../../components/inputs/Text/TextInput'
import ControllerInput from '../../../generic/components/Inputs/ReactHookForm/ControllerInput'
import TemplateLabel from '../../../generic/templates/Label'
import SubmitButton from '../../../generic/components/Buttons/SubmitButton'
import FormProps from '../../../generic/forms/interfaces/FormProps';
import TemplateFormDefault from "../../../generic/templates/Modal";
import CountrySelector from '../../../../components/inputs/Select/CountrySelector/CountrySelector';
import Checkbox from '../../../../components/inputs/Checkbox/Checkbox';
import cls from './Form.module.scss';
import _ from 'lodash'
import { HiddenFieldForm } from '../../../../components/forms/contents'



const schema = Yup.object().shape({
    hasSameWorkingAddress: Yup.boolean(),
    workingAddress: Yup.object().when('hasSameWorkingAddress', {
        is: false,
        then: Yup.object().shape({
            country: Yup.string().required('This field is required').nullable(),
            street: Yup.string().required('This field is required').nullable(),
            city: Yup.string().required('This field is required').nullable(),
        }),
        otherwise: Yup.object()

    })
})



const StepCompanyInfo: React.FC<FormProps> = ({
    initialValues = {hasSameWorkingAddress:false},
    onCompleteSubmit,
    Template = TemplateFormDefault,
    buttons = {},
    buttonSubmitProps,
    propsTemplate
}: FormProps) => {



    const { translate } = useTranslate()

    const handleSubmit = async values => {
        onCompleteSubmit && await onCompleteSubmit(values);
    }


    return (
        <Form
            defaultValues={initialValues}
            schema={schema}
            onSubmit={handleSubmit}
            onlySendDirty={false}
            optionsForm={{ mode: "onChange" }}
        >

            <Template buttons={{ ...buttons, submit: <SubmitButton  {...buttonSubmitProps}>Next</SubmitButton> }} props={propsTemplate}>

                <TemplateLabel
                    className={cls.TemplateLabel}
                >
                    <ControllerInput
                        render={Checkbox}
                        isMultiple={false}
                        defaultValue={false}
                        label={'The company address is the same as the registered address'}
                        name={'hasSameWorkingAddress'}
                        onChange={(value, setValue) => {
                            const values = {
                                country: null, street: '', city: '', state: '', zipCode: ''
                            };
                            setValue('workingAddress', values, { shouldDirty: true, shouldTouch: true });
                        }}
                    />
                </TemplateLabel>

                <HiddenFieldForm fieldsWatch={'hasSameWorkingAddress'} conditionHideCallback={value => !value}>

                    <TemplateLabel
                        label={translate('MODULES.MAP.WIZARD_COMPANY_DETAIL_FORM_FIELD_COUNTRY')}
                        className={cls.TemplateLabel}
                        isRequired
                    >
                        <ControllerInput
                            render={CountrySelector}
                            name='workingAddress.country'
                            placeholder='Country'

                        />
                    </TemplateLabel>


                    <TemplateLabel
                        label={translate('MODULES.MAP.WIZARD_COMPANY_DETAIL_FORM_FIELD_STREET')}
                        className={cls.TemplateLabel}
                        isRequired
                    >
                        <ControllerInput
                            render={TextInput}
                            name='workingAddress.street'
                            defaultlabel={''}
                            placeholder='Street'


                        />
                    </TemplateLabel>

                    <TemplateLabel
                        label={translate('MODULES.MAP.WIZARD_COMPANY_DETAIL_FORM_FIELD_CITY')}
                        className={cls.TemplateLabel}
                        isRequired
                    >
                        <ControllerInput
                            render={TextInput}
                            name='workingAddress.city'
                            defaultlabel={''}
                            placeholder='City'
                        />
                    </TemplateLabel>

                    <TemplateLabel
                        label={translate('MODULES.MAP.WIZARD_COMPANY_DETAIL_FORM_FIELD_STATE')}
                        className={cls.TemplateLabel}
                    >
                        <ControllerInput
                            render={TextInput}
                            name='workingAddress.state'
                            defaultlabel={''}
                            placeholder='State/Region'
                        />
                    </TemplateLabel>

                    <TemplateLabel
                        label={translate('MODULES.MAP.WIZARD_COMPANY_DETAIL_FORM_FIELD_ZIPCODE')}
                        className={cls.TemplateLabel}
                    >
                        <ControllerInput
                            render={TextInput}
                            name='workingAddress.zipCode'
                            defaultlabel={''}
                            placeholder='Zip Code / Post Code'
                        />
                    </TemplateLabel>
                </HiddenFieldForm>
            </Template>

        </Form>
    )
}

export default StepCompanyInfo

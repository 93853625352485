import React from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";
import useNavigation from "src/modules/generic/hooks/useNavigation";

const DocumentsTab = () => {
  const { goToMF } = useNavigation();
  const params = useParams();
  const groupCompanyId = params.id;

  return (
    <MicroFrontend
      id="MF_Group_Company_Documents_Table"
      component={"./GroupCompanyDocumentsTable"}
      mf="documents"
      params={{ groupCompanyId, go: goToMF }}
    />
  );
};

export default DocumentsTab;

import React, { FC, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import _ from "lodash";
import useCustomMachine from '../../../../../generic/hooks/useCustomMachine';
import Button from '../../../../../generic/components/Buttons/Button';
import { StepProps } from "../../../../../generic/components/Wizard/Step";
import TemplateWizard from '../../../../../generic/templates/Modal/TemplateWizard';
import useTranslate from '../../../../../generic/hooks/useTranslate';
import { clearAssistatText,addAssistantText } from "../../../../../generic/store/action";
import FormShareCapitalDelaware from "../../../Forms/FormShareCapitalDelaware";
import useSession from "../../../../../session/hooks/useSession";
import useUpsertFormationCompany from "../../../graphql/mutations/useUpsertFormationCompany";

const Step2: FC<StepProps> = () => {
  const { translate } = useTranslate();
  const { id: _id } = useParams();
  const { startupId } = useSession();
  const [mutation] = useUpsertFormationCompany({}, {});
  const { next, send, state, stepData } = useCustomMachine();
  const initialValues = { ...stepData, stakeholders:state?.context?.data?.stakeholders };
  const id = state?.context?.data?.id ?? _id;
  
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearAssistatText())
    dispatch(addAssistantText(translate('MODULES.GROUP_COMPANY.SHARE_CAPITAL.SECOND_STEP.TOOLTIP')))
  }, []);

  const parseValuesToEquityData = (values) => _.map(values.stakeholders, sh => ({
    owner: { type: 'stakeholder', id: sh.id },
    shareIssuances: {
      "sharePrice": {
        "currency": "USD",
        "amount": 0.00001
      },
      "quantity": sh.shares
    }
  }));

  const handleCompleteSubmit = async (values) => {
    const variables = {
      startupId,
      groupCompanyId: id,
      groupCompanyData: {
        formationData: {
          sharesData: {
            shareholdersData: parseValuesToEquityData(values)
          }
        }
      }
    }
    await mutation(variables)
    next(values);
  }

  const handleCancel = () => {
    send("CANCEL");
  }

  const handlePrevious = () => send('PREVIOUS');
  
  const buttons = useMemo(() => {
    let buttons ={}
    if (state?.context?.data?.isEdit !== true) {
      buttons["previous"] =  <Button onClick={handlePrevious} variant="secondary">Previous</Button>
    }
    return { ...buttons,  cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }
  },[handleCancel, handlePrevious, state?.context?.data?.isEdit]);

  return (
    <>
      <FormShareCapitalDelaware
        groupCompanyId={id}
        initialValues={initialValues}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: state?.context?.data?.isEdit!==true ? translate('MODULES.GROUP_COMPANY.SHARE_CAPITAL_DELAWARE.SECOND_STEP.TITLE') :  translate('MODULES.GROUP_COMPANY.SHARE_CAPITAL_DELAWARE.EDIT_SECOND_STEP.TITLE'),
          subTitle: translate('MODULES.GROUP_COMPANY.SHARE_CAPITAL_DELAWARE.SECOND_STEP.SUBTITLE')
        }}
        buttons={buttons}
        submitButtonChildren={state?.context?.data?.isEdit && 'Save changes'}
      />
    </>
  );
}

export default Step2;
import * as Yup from 'yup';
import { Constants } from "src/v1/utils";

const getSchema = (zipCodeValidation) => {
	return Yup.object().shape({
		card: Yup.object().nullable().when(['cardSelect'], {
			is: null,
			then: Yup.object().nullable().shape({
				number: Yup.string().required('Required').test('number', 'We only accept Visa, American Express and Mastercard.', (creditCard) => {
					try {
						const cardInstitutionIssued = parseInt(creditCard.substring(0, 4));
						if ((cardInstitutionIssued >= Constants.CARD_RANGES.MASTERCARD.initial_1 && cardInstitutionIssued <= Constants.CARD_RANGES.MASTERCARD.final_1) || (cardInstitutionIssued >= Constants.CARD_RANGES.MASTERCARD.initial_2 && cardInstitutionIssued <= Constants.CARD_RANGES.MASTERCARD.final_2)) {
							return true
						}
						if (cardInstitutionIssued >= Constants.CARD_RANGES.VISA.initial_1 && cardInstitutionIssued <= Constants.CARD_RANGES.VISA.final_1) {
							return true
						}
						if (cardInstitutionIssued >= Constants.CARD_RANGES.AMEX.initial_1 && cardInstitutionIssued <= Constants.CARD_RANGES.AMEX.final_1) {
							return true
						}
					} catch (e) {
						return false
					}
				}),
				date: Yup.string().required('Required'),
				cvc: Yup.string().required('Required'),
			}),
			otherwise: Yup.object().nullable()
		}),
		holderName: Yup.string().nullable().when(['cardSelect'], {
			is: null,
			then: Yup.string().nullable().optional(),
			otherwise: Yup.string().nullable().required('Required'),
		}),
		country: Yup.string().nullable().required('Required'),
		address1: Yup.string().nullable().required('Required'),
		address2: Yup.string().nullable(),
		city: Yup.string().nullable().required('Required'),
		state: Yup.string().nullable(),
		zipCode: Yup.string().test('zipCode', 'Required', zipCodeValidation)
	})
};

export default getSchema

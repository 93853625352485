import React, { Component } from "react";
import Popover from '@mui/material/Popover';
import _ from 'lodash';
import classes from "./ModalMenu.module.scss"
import MaterialAvatarLabel from "../../../../modules/generic/components/Avatars/AvatarLabel";
import { RoleAccess } from "../../index";
import ButtonArrow from "../../../../modules/generic/components/Buttons/ButtonArrow";
import Scrollbar from "./../../../../components/generic/Scrollbar/Scrollbar";

/*
    Properties:
        links:[ links to show
            {
                className
                label
                onClick: function to execute when do click in.
                separator:  true|false (default), show border top
            }
        ] 
        callbacks:{ functions to execute in callback
            close: execute when 
        }
        children: content to render
*/
class ModalMenu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null,
      children: props.children
    };
  }

  openMenu = (e) => {
    this.setState({
      anchorEl: e.currentTarget,
      open: true
    });
  };

  closeMenu = (e) => {
    this.setState({
      open: false
    });
    if (this.props.callbacks && this.props.callbacks.close) {
      this.props.callbacks.close();
    }
  };

  onClick = (link) => {
    this.closeMenu();
    if (link.params) {
      link.onClick(link.params)
    } else { link.onClick() }
  };

  componentJSX_links = (links) => {
    if (links) {
      return links.map((link, i) => {
        const classSeparator = link.separator ? classes.LinkSeparator : null;
        return (
          <RoleAccess action={link.action} key={i}>
            <a className={`maskBefore ${classes.Link} ${link.className} ${classSeparator}`} onClick={(e) => { this.onClick(link) }}>
              <span>{link.label}</span>
            </a>
          </RoleAccess>
        )
      })
    }
  };

  changeSelectedGroupCompany(gc, avatar) {
    this.props.changeSelectedGroupCompany(gc);
    this.closeMenu();
    this.setState({
      children: avatar
    })
  }

  renderCaptableComponents(groupCompanies) {
    return _.orderBy(groupCompanies, ['isHoldingCompany'], ['desc']).map(gc => {
      const avatar = <MaterialAvatarLabel captable={true} avatar={gc.avatar} name={gc.name} labelColor={"#2c74ff"} pointerLabel={true} />;
      return (
        <div key={groupCompanies.indexOf(gc)} onClick={() => this.changeSelectedGroupCompany(gc, avatar)} className={classes.CapTableComponent}>
          {avatar}
        </div>
      );
    });
  }

  render() {

    let { children, callbacks, links } = this.props;
    let { open, anchorEl } = this.state;
    const classOpen = open ? classes.Open : classes.Close;
    const showArrowClass = this.props.scoping ? null : classes.Arrow;

    return (
      <div className={this.props.captable ? `${classes.ModalStyle}` : classes.ModalMenu}>
        <a style={this.props.captable ? this.props.style : null} className={`maskAfter ${showArrowClass} ${classOpen}`} onClick={(e) => { this.openMenu(e) }}>
          <div>{this.props.captable ? this.state.children : children}</div>
          <ButtonArrow open={open}></ButtonArrow>
        </a>
        <Popover
          disableEnforceFocus
          open={open}
          onClose={this.closeMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          anchorEl={anchorEl}
        >
          <div className={classes.Modal}>
            <Scrollbar className={classes.onlyvertical}>
              {this.props.captable ? this.renderCaptableComponents(this.props.groupCompanies) : this.componentJSX_links(links)}
            </Scrollbar>
          </div>
        </Popover>
      </div>
    )
  }
}

export default ModalMenu;

// import { gql } from '@apollo/client';
import useMutation from "src/hooks/custom/useMutation";
import useSession from 'src/modules/session/hooks/useSession';


const UPSERT_COMPONENT_STAKEHOLDER = `mutation upsertComponentStakeholder(
  $startupId: ID!
  $stakeholderId: ID!
  $stakeholder: ComponentStakeholderInputType!
) {
  upsertComponentStakeholder(startupId: $startupId ,stakeholderId: $stakeholderId ,stakeholder: $stakeholder ) {
    id
  }
}`;


const useUpsertComponentStakeholder = (config,props) => {
  const { startupId } = useSession();
  const [mutation,loading] = useMutation(
    UPSERT_COMPONENT_STAKEHOLDER,{ showSuccessNotification:false,...config},{ ...props }
  )

  return [mutation,loading]
};

export default useUpsertComponentStakeholder;
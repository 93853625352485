import React, {FC} from 'react'
import ConvertibleMachine from "./ConvertibleMachine";
import Wizard from "../../../../../../generic/components/Wizard";
import ModalAssistant, {ModalAssistantProps} from "../../../../../../generic/components/Modal/ModalAssistant";
import useModal from "../../../../../../generic/hooks/useModal"
interface ModalConvertibleProps extends ModalAssistantProps{
  initialValues:object
}

const ModalConvertible:FC<ModalConvertibleProps>=({initialValues={}, open = false, ...props })=>{

  const {closeModal} = useModal();

  const handleClose = () => {
    closeModal();
  }

  return(
    <ModalAssistant open={open} >
      <Wizard initialValues={initialValues} machine={ConvertibleMachine} onComplete={handleClose}/>
    </ModalAssistant>)
}

export default ModalConvertible;
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import currency from "currency.js";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import PositivMoney from "src/components/text/Text/PositivMoney";
import useBusinessRules from "src/modules/checkout/hooks/useBusinessRules";
import useTranslate from "../../../../../../../src/modules/generic/hooks/useTranslate";
import Money from "../../../../../../components/text/Text/Money";
import Text from "../../../../../../components/text/Text/Text";
import usePayment from "../../../../hooks/usePayment/index";
import { ItemProductBO, ProductBO } from "../../../../type/products";
import classesModule from "./Item.module.scss";

export interface ItemsProps {
  product: ProductBO;
  variant: "vat" | "description";
  company: any;
  useTotals: boolean;
  totalGovernmentFee?: number;
  feeText: string;
  totalPricingDiscount?: number;
}

const Items: React.FC<ItemsProps> = ({
  product,
  variant = "vat",
  company,
  useTotals = false,
  totalGovernmentFee,
  feeText,
  totalPricingDiscount,
  ...props
}: ItemsProps) => {
  const { translate, translateText } = useTranslate();

  const {
    countryCode,
    setSubTotal,
    setVatAmount,
    coupon,
    freeVat,
  } = usePayment();
  const { isItemEnabled } = useBusinessRules();

  const { vatPerItem, subTotalAmount, vatAmount } = useMemo(() => {

    const vats = {};
    let subTotalAmount = currency(0);
    let vatAmount = 0;
    let pendingDiscount = product?.discount ? product?.discount : 0;

    _.forEach(_.get(product, "items"), (item: ItemProductBO, index: number) => {
      if (isItemEnabled(item, company)) {
        const vat = freeVat
          ? _.find(
            _.get(item, "countryVAT"),
            (vat) => vat.country === countryCode
          )
          : null;
        subTotalAmount = subTotalAmount.add(item.amount);
        if (vat) {
          vats[item.code] = {
            amount: item.amount,
            percent: vat.valueWithDiscount,
          };
        } else {
          vats[item.code] = {
            amount: item.amount,
            percent: item.VAT_per,
          };
        }
        vats[item.code].amount = item.amount;
        if (index === 0 && coupon) {
          const amount = _.get(coupon, "validatePaymentCoupon.discountAmount");
          const percent = _.get(
            coupon,
            "validatePaymentCoupon.discountPercentage"
          );
          let discount = (item.amount * percent) / 100 + amount;
          discount = isNaN(discount) ? 0 : discount;
          discount += isNaN(product?.discount) ? 0 : product?.discount;

          vatAmount +=
            ((item.amount - discount) * vats[item.code].percent) / 100;

        } else if (item?.applyDiscount && pendingDiscount > 0) {
          let discount = 0;
          if (pendingDiscount > item.amount) {
            discount = item.amount;
            pendingDiscount = pendingDiscount - item.amount;
          } else {
            discount = pendingDiscount;
            pendingDiscount = 0;
          }
          vatAmount +=
            ((item.amount - discount) * vats[item.code].percent) / 100;
        } else {
          vatAmount += (item.amount * vats[item.code].percent) / 100;
        }
      }
    });
    if (vatAmount < 0) {
      vatAmount = 0;
    }
    console.log("*** vatAmount", {
      vatPerItem: vats,
      subTotalAmount: subTotalAmount.value,
      vatAmount,
    })

    return {
      vatPerItem: vats,
      subTotalAmount: subTotalAmount.value,
      vatAmount,
    };
  }, [
    countryCode,
    JSON.stringify(product),
    JSON.stringify(coupon),
    freeVat,
    company,
    isItemEnabled,
  ]);

  useEffect(() => {
    setSubTotal(subTotalAmount);
  }, [subTotalAmount]);

  useEffect(() => {
    setVatAmount(vatAmount);
  }, [vatAmount]);

  return (
    <React.Fragment>
      <div className={classesModule.items}>
        {_.map(product.items, (item: ItemProductBO) => {
          if (!isItemEnabled(item, company)) {
            return null;
          }
          return (
            <>
              <div className={classesModule.item} data-type={"item"}>
                <div className={classesModule.product}>
                  {_.get(item, "name")}
                </div>
                {variant === "vat" && (
                  <>
                    {_.get(item, "VAT_per", 0) > 0 && (
                      <div
                        className={classesModule.vat}
                        dangerouslySetInnerHTML={{
                          __html: translate(
                            `MODULES.CHECKOUT.PAYMENT_VAT_INLINE`,
                            { percent: vatPerItem[item.code].percent }
                          ),
                        }}
                      ></div>
                    )}
                    {_.get(item, "VAT_per", 0) === 0 && (
                      <div className={classesModule.vat}></div>
                    )}
                  </>
                )}
                {variant === "description" && (
                  <>
                    <div className={classesModule.vat}>
                      {translateText(item.descriptionLabel, {
                        date: moment().format("DD MMM"),
                      })}
                    </div>
                  </>
                )}
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-end"
                  spacing={0.5}
                >
                  <span>{_.get(product, "currency")}</span>
                  <b>
                    <Money
                      value={vatPerItem[item.code].amount}
                      params={{ prefix: " " }}
                    />{" "}
                  </b>
                </Stack>
              </div>
            </>
          );
        })}
        {totalGovernmentFee > 0 && (
          <>
            <Box mb={2} width={"100%"}>
              <div className={classesModule.item} data-type={"item"}>
                <div className={classesModule.product}>
                  {feeText}
                </div>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-end"
                  spacing={0.5}
                >
                  <span>{_.get(product, "currency")}</span>
                  <b>
                    <Money
                      value={totalGovernmentFee}
                      params={{ prefix: " " }}
                    />{" "}
                  </b>
                </Stack>
              </div>
            </Box>
          </>
        )}
        {(totalPricingDiscount !== undefined && totalPricingDiscount > 0) && (
          <>
            <Box mb={2} width={"100%"}>
              <div className={classesModule.item} data-type={"item"}>
                <div className={classesModule.product} style={{ color: "#FF606F" }}>
                  {translate("MODULES.CHECKOUT.PAYMENT_TRANSACTION_OFFER")}
                </div>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-end"
                  spacing={0.5}
                >
                  <span style={{ color: "#FF606F" }}>- {_.get(product, "currency")}</span>
                  <b className={classesModule.transactionOffer}>
                    <PositivMoney value={totalPricingDiscount ?? 0} params={{ prefix: " " }} showDecimals={true} />
                  </b>
                </Stack>
              </div>
            </Box>
          </>
        )}
        <div className={`${classesModule.item} ${classesModule.borderTop}`}>
          <div className={classesModule.product}>
            <Text uuid={"MODULES.CHECKOUT.PAYMENY_SUBTOTAL"} />
          </div>
          <div className={classesModule.vat}></div>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={0.5}
          >
            <span style={{ fontSize: "10px", marginBottom: "1px" }}>{_.get(product, "currency")}</span>
            <b>
              <Money value={useTotals ? product?.totals?.subTotalAmount : subTotalAmount} params={{ prefix: " " }} />{" "}
            </b>
          </Stack>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Items;

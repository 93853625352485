import classnames from "classnames";
import React, { FC } from "react";
import classes from "./classes.module.scss";
export interface AssistanceContentsProps {
  /** Property children */
  children?: React.ReactNode,
  /** Property link */
  showInput?: boolean,
  /** Property onChange */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
}

/**
  Assistance contents
*/



const AssistanceContents: FC<AssistanceContentsProps> = ({
  showInput = false,
  onChange,
  children,
  ...props
}: AssistanceContentsProps) => {


  return (
    <div className={classnames(`${classes.allContainer}`)}>
      <div
        className={classnames(`${classes.AssistanceBubbleSpace}`, {
          [classes.AssistanceBubbleContainer]: showInput,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default AssistanceContents;

import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { HiddenFieldForm } from "src/components/forms/contents";
import Form from "src/components/forms/Form/Form";
import TextareaInput from "src/components/inputs/Textarea/TextareaInput";
import Button from "src/modules/generic/components/Buttons/Button";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import CheckboxButtonSimple, { CheckboxButtonOptions } from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import { addAssistantText, clearAssistatText } from "src/modules/generic/store/action";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import * as Yup from "yup";
import classes from "./styles.module.scss";

const schema = Yup.object().shape({
    isThereOtherPassport: Yup.boolean().required("This Field Required"),
    reason: Yup.string().when("isThereOtherPassport", {
        is: false,
        then: Yup.string().required("This Field Required"),
    }),
});

function IsTherePassport() {
    const { translate } = useTranslate();
    const dispatch = useDispatch();
    const { next, cancel, context } = useMachine(); // Extract 'prev' from context
    const country = context?.initialData?.country?.name;

    const options: Array<CheckboxButtonOptions<boolean, string>> = [
        {
            value: false,
            label: 'No',
        },
        {
            value: true,
            label: 'Yes',
        },
    ];

    const initialValues = useMemo(() => {
        return context?.IsTherePassport ?? {};
    }, [context]);

    useEffect(() => {
        dispatch(clearAssistatText());
        dispatch(
            addAssistantText(
                `A passport copy is required for the standard compliance check of the stakeholder. Please select Yes if they currently hold or previously held a passport for this nationality, and upload a copy of it even if this is expired.`
            )
        );

        return () => {
            dispatch(clearAssistatText());
        };
    }, [dispatch]);

    const handleCancel = () => {
        cancel();
    };

    const handleSubmit = (values) => {
        next(values);
    };

    return (
        <Form
            defaultValues={initialValues}
            schema={schema}
            onSubmit={handleSubmit}
            optionsForm={{ mode: "onChange" }}
            onlySendDirty={false}
        >
            <TemplateWizard
                buttons={{
                    cancel: (
                        <Button onClick={handleCancel} variant="secondary">
                            {translate("BUTTON_CANCEL")}
                        </Button>
                    ),
                    submit: <SubmitButton>Next</SubmitButton>,
                }}
                props={{
                    title: "Can they provide a copy of the passport for this nationality?",
                    subTitle: `Passport for ${country} nationality`,
                }}
            >
                <ControllerInput
                    render={CheckboxButtonSimple}
                    name="isThereOtherPassport"
                    options={options}
                    className={classes.CheckboxButton}
                    classNameButton={classes.buttonCheckBox}
                />
                <HiddenFieldForm
                    fieldsWatch={"isThereOtherPassport"}
                    conditionHideCallback={(value) => value === false}
                >
                    <div className={classes.describePEPContainer}>
                        <TemplateLabel
                            label={'Please provide details as to why they can’t provide a passport copy for this nationality'}
                            className={classes.TemplateLabelColumn}
                            isRequired
                        >
                            <ControllerInput
                                name={"reason"}
                                render={TextareaInput}
                                className={classes.TextArea}
                                placeholder={'Type here'}
                                defaultValue={""}
                            />
                        </TemplateLabel>
                    </div>
                </HiddenFieldForm>
            </TemplateWizard>
        </Form>
    );
}

export default IsTherePassport;

import { get } from 'lodash';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Button from '../../../../generic/components/Buttons/Button';
import { StepProps } from "../../../../generic/components/Wizard/Step";
import useCustomMachine from '../../../../generic/hooks/useCustomMachine';
import { addAssistantText, clearAssistatText } from '../../../../generic/store/action';
import TemplateWizard from '../../../../generic/templates/Modal/TemplateWizard';
import useSession from '../../../../session/hooks/useSession';
import Form from "../../Forms/FormAssignAuthorisedSignatories";
import useUpdateStakeholder from "src/modules/startup/onBoarding/core/graphql/mutations/useUpdateStakeholder";

const Step1: FC<StepProps> = () => {

  const { next, send, state } = useCustomMachine();
  const { updateStakeholder } = useUpdateStakeholder({}, {});
  const { id:_id } = useParams<any>()
  const id = state?.context?.data?.stakeholderId ?? _id;
  const { startupId } = useSession();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(addAssistantText(`An <a  rel='noopener noreferrer' target='_blank' href="https://academy.clara.co/article/7DxEgRr0DZpJjfyBtJbrCz">authorised signatory</a> is a person who can legally sign agreements and other documents on your company’s behalf.`));
    return () => {
      dispatch(clearAssistatText());
    }
  }, [])

  const handleCompleteSubmit = async values => {
    const variables = {
      stakeholderId: id,
      startupId,
      stakeholderData: {
        stakeholder:{

          authorizedSignatory:{
            fullName: values.authorizedSignatory,
            email: values.email,
            position:values.authorizedSignatoryRol
          }
        }
      }
    }
    await updateStakeholder({ variables });
    next(values);
  }

  const handleCancel = async values => {
    send("CANCEL");
  }

  return (
    <React.Fragment>
      <Form
        initialValues={get(state, 'context.data.personal_information', {})}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: 'Add authorised signatory',
          subTitle: 'Authorised signatory'
        }}
        buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }}>
      </Form>
    </React.Fragment>
  )
}

export default Step1;

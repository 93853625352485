import React from 'react';
import { Modal } from '../../../../../../../v1/components';
import { Constants } from "../../../../../../../v1/utils";
import DeleteGrant from "./DeleteGrant";

const DeleteGrantModal = ({ open, handleCloseModal, data, onComplete, goTo, redirect, ...props }) => {

    return (
        <Modal
            open={open} 
            overlayName={Constants.FORM_TYPES.DOCUMENT_GENERATED.DELETE_GRANT} handleClose={() => handleCloseModal()} showHeader={false}>
            <DeleteGrant  open={true} onComplete={onComplete} handleCloseModal={handleCloseModal} initialValues={data} goTo={goTo} redirect={redirect}/>
        </Modal>
    )
}

export default DeleteGrantModal;


import { useEffect } from 'react';
import useQuery from "../../../custom/useQuery";
import _ from 'lodash'


const GET_DEPARTMENTS = `
query getDepartments($startupId: ID! ){
  getDepartments(startupId:$startupId){
    id
    name
    isDefault
  }
}
`

const useGetIndividualStakeholderDepartments = ({ variables, ...props }) => {
    const { loading, error, data, refetch } = useQuery(GET_DEPARTMENTS, variables, null, props);

    return { loading, error, data: _.get(data, 'getDepartments', []), refetch };
}
export default useGetIndividualStakeholderDepartments;

import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import Loading from "../../../../../components/generic/Loading/Loading"
import DocumentPreview from "../../../../../components/generic/Documents/DocumentPreview/DocumentPreview"
import BotoneraProposal from "../../../../../v1/V2/Components/Clara/matter/proposal/BotoneraProposal/BotoneraProposal"
import useEngagementDocument from "../../../../../v1/V2/Hooks/services/matter/useEngagementDocument";
import _ from 'lodash';
const ScopingProposalDocument = ({ fileId }) => {
    const params =useParams();
    const [loading,setLoading] = useState(false);
    const {matterId} = params;
    const {data: file, loading: loadingHooks} = useEngagementDocument(matterId,"proposal");
    useEffect(()=>{
        setLoading(loadingHooks);
        console.log(_.get(file,'name','').split('.'))
    },[loadingHooks])

    return (
        <React.Fragment>
            <Loading showChildren={file.id} show={loading}>
                <DocumentPreview fileId={file.id} fileType={_.get(file,'name','').split('.').pop()}>
                    <BotoneraProposal matterId={matterId} setLoading={setLoading} />
                </DocumentPreview>
            </Loading>
        </React.Fragment>
    )
}

export default ScopingProposalDocument

import React, { FC, useState, useEffect } from "react";
import classesModules from "./classes.module.scss";
import MuiPhoneNumber from "mui-phone-input-ssr";
import { FormControl } from "@mui/material";
import ErrorInput from "../ErrorInput";

export interface PhoneInputProps {
  defaultCountry?: string;
  disableDropdown?: boolean;
  autoFormat?: boolean;
  enableLongNumbers?: boolean;
  countryCodeEditable?: boolean;
  value?: string;
  /** Onchange method */
  onChange: (Array) => void;
  /** onFocus method */
  onFocus?: (any) => void;
  /** onBlur method */
  onBlur?: (any) => void;
  classNameError?: any;
  showErrorIcon?: boolean;
  error?: boolean;
  errorMessage?: string;
  sx?: any;
}

/**
- Template to show Phone input
**/

const PhoneInput: FC<PhoneInputProps> = ({
  defaultCountry,
  disableDropdown = true,
  autoFormat = true,
  enableLongNumbers = true,
  countryCodeEditable = true,
  onChange,
  onFocus,
  onBlur,
  value,
  classNameError,
  showErrorIcon,
  error,
  errorMessage,
  sx,
  ...props
}: PhoneInputProps) => {
  const [countryCode, setCountryCode] = useState(false);
  const [defaultCountryValue, setDefaultCountryValue] = useState(
    defaultCountry
  );
  const [hasFocus, setHasFocus] = useState(false);

  const handleChange = (value) => {
    onChange && onChange(value);

    if (value.toString().length > 1) {
      setCountryCode(false);
    } else if (value.toString().length == 1) {
      setCountryCode(true);
    } else if (value.toString().length < 1) {
      setCountryCode(false);
    }

    return value;
  };

  const handleOnFocus = (e) => {
    if (onFocus) onFocus(e);

    setHasFocus(true);
  };

  const handleOnBlur = (e) => {
    if (onBlur) onBlur(e);
    setHasFocus(false);
  };

  useEffect(() => {
    setCountryCode(false);
    setDefaultCountryValue(defaultCountry);
  }, [defaultCountry]);

  return (
    <FormControl sx={{ width: "100%", ...sx }} error={!!errorMessage}>
      <div
        className={`${
          disableDropdown
            ? classesModules.containerDropdownOff
            : classesModules.containerDropdownOn
        } ${hasFocus ? classesModules.hasFocus : classesModules.notHasFocus} ${
          errorMessage || error ? classesModules.borderErrorInput : ""
        }`}
      >
        <MuiPhoneNumber
          classes={{
            root: classesModules.aux,
            focused: classesModules.aux,
          }}
          defaultCountry={defaultCountryValue}
          onChange={handleChange}
          inputClass={classesModules.input}
          dropdownClass={classesModules.dropdown}
          disableDropdown={disableDropdown}
          disableAreaCodes={true}
          autoFormat={autoFormat}
          disableCountryCode={countryCode}
          enableLongNumbers={enableLongNumbers}
          countryCodeEditable={countryCodeEditable}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          value={value}
        />
      </div>

      {errorMessage && (
        <ErrorInput
          message={errorMessage}
          classNameError={classNameError}
          showErrorIcon={showErrorIcon}
        />
      )}
    </FormControl>
  );
};

export default PhoneInput;

import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useEffect } from 'react';
import { Modal } from '../../../../v1/components';
import Text from "./../../../text/Text/Text";
import Title from "./../../../text/Title/Title";
import classes from './ModalLoading.module.scss';
const ModalLoading = ({ loading, open, title, label, callback }) => {

    const titleText = title || "TEXT_PLEASE_WAIT";
    const labelText = label || "TEXT_PROCESSING";

    useEffect(() => {
		(loading || open) && callback && callback();
    }, [loading, open]);


    return (
    	<>
		{(loading || open) &&
		<Modal open={loading || open} showHeader={false}>
            <div className={classes.ModalLoading}>
                <CircularProgress />
                <Title ><Text uuid={titleText} /></Title>
                <span><Text uuid={labelText} /></span>
            </div>
        </Modal>}
        </>
    )
};
export default ModalLoading;

import { Accordion as AccordionMaterial,AccordionProps } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import React, {FC,ReactNode} from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
interface Props extends Omit<AccordionProps,'children'> {
    label: ReactNode,
    details:ReactNode,

}
const Accordion: FC<Props> = ({label,details,...props}) => {
    return <>
      <AccordionMaterial {...props}>
        <AccordionSummary
        style={{width:"100%",padding:"1rem"}}
          expandIcon={<ExpandMoreIcon />}
        >
          {label}
        </AccordionSummary>
        <AccordionDetails>
          {details}
        </AccordionDetails>
      </AccordionMaterial>
    </>
}
export default Accordion;


import React, {useState} from 'react';
import {ClaraCheckForm, Text} from '../index';
import _ from "lodash";
import PropTypes from 'prop-types';
import {Field} from "formik";
import classes from "./Chat.module.scss";
import FactoryChatForm from './FactoryChatForm';
import FactoryChatFormConfig from './FactoryChatFormConfig.js';
import useEligibleBeneficialOwners from '../../Hooks/useEligibleBeneficialOwners';

const ChatBeneficialOwners = ({ responseKeyName, isForm, initialValueStart }) => {
  const nameField = responseKeyName ? responseKeyName + "." : "";
  const [openModal, setOpenModal] = useState(false);
  const { data } = useEligibleBeneficialOwners(initialValueStart.matterId,initialValueStart.draftId)
  const [listStateholder, setListStateholder] = useState();
  const handleClose = () => {
    setOpenModal(false)
  }


  return (
    <React.Fragment>
      <div className={`${classes.ChatQuestionnaire} ${classes.ChatAdditionalSignature}`}>
        <div className={classes.contentField}>
          <button className={`${classes.buttonWithAutomatic} ${classes.buttonOption} `} type="button" onClick={() => { setOpenModal(true) }}>
            <Text uuid={`QUESTIONARY_BENEFICIAL_OWNERS_BUTTON`} />
          </button>
        </div>
        <div className={classes.contentField}>
          <Field name={nameField + "beneficialsOwners"}>
            {({ field, form }) => (
              <ClaraCheckForm
                openForm={openModal}
                cbClose={handleClose}
                field={field}
                form={form}
                list={data}
                initialValues={{ formKey: "INDIVIDUAL_FORM" }}
                optionsList={{
                  fieldId: initialValueStart.draftId?"_id":"id",
                  fieldLabel: _.get(FactoryChatFormConfig, "INDIVIDUAL_QUESTIONNAIRE_FORM" + ".fieldName", null),
                }}
                optionsForm={{
                  titleKey: _.get(FactoryChatFormConfig, "INDIVIDUAL_QUESTIONNAIRE_FORM.titleKey", null),
                  educationId: _.get(FactoryChatFormConfig, "INDIVIDUAL_QUESTIONNAIRE_FORM.leftEducationId", null),
                  muttation: _.get(FactoryChatFormConfig, "INDIVIDUAL_QUESTIONNAIRE_FORM.muttation", null),
                  getIdFromMuttation: _.get(FactoryChatFormConfig, "INDIVIDUAL_QUESTIONNAIRE_FORM.getIdFromMuttation", null),
                  paramsMuttaion: initialValueStart,
                  getVariablesMutation: _.get(FactoryChatFormConfig, "INDIVIDUAL_QUESTIONNAIRE_FORM.getVariablesMutation", null),
                  succesNotification: "NOTIFICATION_SUCCESS",
                  errorNotification: "NOTIFICATION_BAD",
                }}
                optionsSelect={{
                  auto: false,
                  canSelect: true
                }}
                optionsOperation={{
                  canAdd: false,
                  canEdit: true,
                  canRemove: true,
                  canRemoveOnlyNews:true,
                }}
                optionsView={{
                  recordsByLine: 7
                }}
                className={{}}
              >
                <FactoryChatForm formKey={"INDIVIDUAL_FORM"} ></FactoryChatForm>
              </ClaraCheckForm>
            )}
          </Field>
        </div>
      </div>
    </React.Fragment >
  );
}
ChatBeneficialOwners.propTypes = {
  responseKeyName: PropTypes.string.isRequired,
  initialValueStart: PropTypes.object.isRequired,

};
export default ChatBeneficialOwners;


import useMutation from '../../../../hooks/custom/useMutation'
const TERMINATE_DOCUMENT = `mutation terminateDocument(
    $startupId: ID!
    $agreementId: ID!
    $reason:String
  ) {
    terminateDocument(
      startupId: $startupId
      agreementId: $agreementId
      reason:$reason
    ) {
      id
      status
    }
  }  
`;


const useTerminateDocument= (props ) => {

    const [mutation,loading] = useMutation(TERMINATE_DOCUMENT,{ ...props })

    return {mutation,loading};
}
export default useTerminateDocument;
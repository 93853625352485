import React from 'react';
import {Field, Formik} from "formik";
import classes from "../SteperForm.module.scss";
import {ClaraSelect,} from "../../../components";
import {Constants} from "../../../utils";
import TextInput from "../Inputs/TextInput";
import _ from "lodash";
import {stakeHolderSelectValidation} from "../validations";
import * as Yup from "yup";

const validationSchema = Yup.object().shape(stakeHolderSelectValidation);

const getListMultipleRoles = (props) => {
    let formProps= props.formProps;
    let list =[];
    let initialRoles= []
    const {mode = "Default"} = props;

    switch (mode){
        case "All" :{
            initialRoles =  _.flatten(Object.values(Constants.ROLES_GROUPS)).map( role => Constants.ROLES_FROM_BACKEND[role]);
            break;
        }
        case "Investor" :{
            initialRoles = Constants.ROLES_GROUPS["EQUITY"].map( role => Constants.ROLES_FROM_BACKEND[role]);
            break;
        }
        case "Default" :{
            initialRoles =  Constants.ROLES_GROUPS["TEAM"].map( role => Constants.ROLES_FROM_BACKEND[role]);
            if(props.formProps.isStakeholderAnEntity)initialRoles = initialRoles.filter(role=>role.id !== Constants.ROLES_FROM_BACKEND.EMPLOYEE.id)
            break;
        }
    }

    initialRoles = _.filter(initialRoles, (role) => role.id!=="FOUNDER");
    //     [
    //     { id: "FOUNDER", label: "Founder" },
    //     { id: "ADVISOR", label: "Advisor" },
    //     { id: "EMPLOYEE", label: "Employee" },
    //     { id: "CONSULTANT", label: "Consultant" },
    //
    // ]

    _.forEach(formProps.groupCompanies,(groupCompany)=>{
        var aux = {
            listName: "groupCompany",
            subListName: "role",
            id: groupCompany.id,
            label: groupCompany.label,
            showTitle: "true",
            list:initialRoles
        }
        list.push(aux)
    })
    return list;
}

const AddStakeHolder = (props) => {

    const isDisabled = (errors, dirty) =>{
        return !(dirty && Object.keys(errors).length === 0 ) ;
    }

    return (
        <Formik
            initialValues={{fullName: "", roles: null}}
            enableReinitialize={false}
            onSubmit={async (values, actions) => {
                _.forEach(_.get(values,"roles",[]),(roleInput)=>{
                    const role = _.map(roleInput.role, (role) => ({type:role}));
                    roleInput.role = role;
                });
                props.submit(values);
            }}
            validationSchema={validationSchema}
            render={({
                values,
                errors,
                dirty,
                handleSubmit,
            }) => {
                return (
                    <React.Fragment>
                        <div className={`${classes.ContainerRow} ${classes.rolesSelector} ${classes.SubFormContainerRow}`}>
                            <Field name='roles'>
                                {({ field, form }) => (
                                    <ClaraSelect
                                        field={field}
                                        form={form}
                                        placeholder={"Select roles from a list"}
                                        lists={getListMultipleRoles(props)}
                                        mode={"multiselect"}
                                    />
                                )}
                            </Field>
                        </div>
                        <div className={`${classes.ContainerRow} ${classes.SubFormContainerRow}`}>
                            <Field name='fullName'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        field={field}
                                        form={form}
                                        placeholder={"Enter stakeholder name"}
                                    />
                                )}
                            </Field>
                        </div>
                        <div className={`${classes.ContainerRow}  ${classes.SubFormContainerButton}`}>
                            <button
                                type={"button"}
                                data-cy="submit"
                                className={`genericBlue ${classes.SubFormButton}`}
                                onClick={handleSubmit}
                                disabled={isDisabled(errors, dirty)}
                            >
                                Save
                                </button>
                        </div>
                    </React.Fragment >
                )
            }

            } />
    )

};
export default AddStakeHolder;

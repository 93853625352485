import React, {useEffect, useState} from 'react';
import {ClaraCheckForm, Text, TextInput} from '../index';
import PropTypes from 'prop-types';
import {Field} from "formik";
import classes from "./Chat.module.scss";
import FactoryChatForm from './FactoryChatForm';
import useQuestionnaireDirectorsDraftFlow from '../../Hooks/useQuestionnaireDirectorsDraftFlow';

const ChatAgreementDirectos = ({ responseKeyName, isForm, initialValueStart }) => {
  const nameField = responseKeyName ? responseKeyName + "." : "";
  const [openModal, setOpenModal] = useState(false);
  const { directors } = useQuestionnaireDirectorsDraftFlow(initialValueStart.matterId,initialValueStart.draftId)
  const [listStateholder, setListStateholder] = useState();
  const handleClose = () => {
    setOpenModal(false)
  }


  useEffect(() => {

  })
  return (
    <React.Fragment>
      <div className={`${classes.ChatQuestionnaire} ${classes.ChatAgreementDirectos}`}>

        <div className={classes.contentField}>
          <Field name={nameField + "agreementsDirectors"}>
            {({ field, form }) => (
              <ClaraCheckForm
                initialValues={{}}
                field={field}
                form={form}
                list={directors}
                optionsList={{
                  fieldId: "id",
                  fieldLabel: "firstName",
                }}
                optionsSelect={{
                  auto: false,
                  canSelect: true,
                  autoLoaderData: directors.length == 1,
                  canValidationDeselected: (element,news,values)=>{
                    return directors.length !== 1;
                  }
                }}
                optionsOperation={{
                  canAdd: false,
                  canEdit: false,
                  canRemove: false,
                }}
                optionsView={{
                  recordsByLine: 7
                }}
                className={{}}
              >
                <FactoryChatForm formKey={"INDIVIDUAL_FORM"} ></FactoryChatForm>
              </ClaraCheckForm>
            )}
          </Field>
        </div>
        {directors.length == 1 &&
          (
            <React.Fragment>
              <div className={classes.contenFieldOneDirector}>
                <h2><Text uuid={"QUESTIONNAIRE_REGISTERED_OFFICE_AND_SERIVICES"} /></h2>

                <div className={classes.contentField}>
                  <Field name={`${nameField}firstName`}>
                    {({ field, form }) => (
                      <TextInput
                        label={"First Names:"}
                        field={field}
                        form={form}
                        autoComplete='off'
                        placeholder={"First Names..."}
                      />
                    )}
                  </Field>
                </div>
                <div className={classes.contentField}>
                  <Field name={`${nameField}lastName`}>
                    {({ field, form }) => (
                      <TextInput
                        label={"Last Name:"}
                        field={field}
                        form={form}
                        autoComplete='off'
                        placeholder={"Last Name..."}
                      />
                    )}
                  </Field>
                </div>
                <div className={classes.contentField}>
                  <Field name={`${nameField}email`}>
                    {({ field, form }) => (
                      <TextInput
                        label={"Email Address:"}
                        field={field}
                        form={form}
                        autoComplete='off'
                        placeholder={"Email Address..."}
                      />
                    )}
                  </Field>
                </div>
              </div>
            </React.Fragment>
          )
        }
      </div>
    </React.Fragment >
  );
}
ChatAgreementDirectos.propTypes = {
  responseKeyName: PropTypes.string.isRequired,
  initialValueStart: PropTypes.object.isRequired,

};
export default ChatAgreementDirectos;

import React, { useRef, useEffect } from 'react';
import _ from "lodash";
import classesModule from "./TooltipInput.module.scss";
import Parser from "html-react-parser";

const TooltipInput = ({ label, children, classes,size,align }) =>{
  const rootClass = _.get(classes, "root", null);
  const tooltipClass=_.get(classes,'tooltip',null);
  const classesSize = _.get(classesModule, size, classesModule.normal);
  const classesAlign= _.get(classesModule, align, classesModule.left);
  let refTooltip = useRef(null);
  let refTooltipContainer = useRef(null);

  const handleOnFocus = () => {
	  if (refTooltip.current) {
		  refTooltip.current.style.display = "flex"
		  refTooltip.current.style.justifyContent='flex-end'
	  }
  }

  const handleOnBlur = () => {
	  if (refTooltip.current) {
		  refTooltip.current.style.display = "none"
	  }
  }

  const addEventListener = (elementInput) => {
    if (elementInput) {
      elementInput.addEventListener('focus', handleOnFocus);
	  elementInput.addEventListener('blur', handleOnBlur);
    }
  }

  const concatElements = (elements, element) => {
    const inputsElements = refTooltipContainer.current.getElementsByTagName(element);
    _.forEach(inputsElements, (element) => {
      elements.push(element)
    })
    
    return elements;
  }
  useEffect(() => {
	let elements = [];
    if (refTooltipContainer && refTooltip) {
      elements = concatElements(elements, "input");
      elements = concatElements(elements, "button");
      elements = concatElements(elements, "textarea");
      _.forEach(elements, (element) => {
        addEventListener(element);
      });
    }

	return () => {
		_.forEach(elements, (element) => {
			element.removeEventListener('focus', handleOnFocus);
			element.removeEventListener('blur', handleOnBlur);
		});
	}

  }, [refTooltip, refTooltipContainer])
  return (
    <React.Fragment>
      <div className={label?`${classesModule.TooltipInput} ${tooltipClass}`:null} ref={refTooltipContainer}>
        {children}
        {label ? (
          <div className={`${classesModule.Tooltip} ${classesSize} ${classesAlign} ${rootClass} `} ref={refTooltip}>
            <div className={classesModule.tooltipBorder}/>
            <div className={classesModule.tooltipBorderArrow}/>
            <i className={'icon-info_symbol'} /> <p> {Parser(label)} </p>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
}
export default TooltipInput;

import { useMemo } from 'react';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import UaEorGccSubMachineStep from '../steps/UaEorGccSubMachineStep';
import NomineeServiceSubMachineStep from '../steps/NomineeServiceSubMachineStep';
import ThirdPartySubMachineStep from '../steps/ThirdPartySubMachineStep';

const useSteps = () => {

  return useMemo(() => ({
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    UaEorGccSubMachineStep: {
      render: UaEorGccSubMachineStep
    },
      NomineeServiceSubMachineStep: {
      render: NomineeServiceSubMachineStep
    },
    ThirdPartySubMachineStep: {
      render: ThirdPartySubMachineStep
    },
    GetStakeholders: {
      render: TemplateWizardSkeletonMachine,
    },
    SaveStep: {
      render: TemplateWizardSkeletonMachine,
    },
    
  }), []);
}

export default useSteps;

import React from 'react';


import {useDispatch} from "react-redux";
import {useParams} from 'react-router-dom';
import IntroSubmitFlow from "../../../../../components/modules/incorporation/InformationFlow/InformationFlow"
import image from '../../../../../images/submitFlowImg.svg';
import PAGES from "../../../../../constants/pages";

const ScopingProposalDeclineView = (props) => {



    const dispatch = useDispatch();
    const params = useParams();

    return (
        <React.Fragment>
            <IntroSubmitFlow
                image={image}
                formTitle={"PROPOSAL_DECLINE_FORM_TITLE"}
                firstTitle={"PROPOSAL_DECLINE_FORM_TITLE_FIRST_TITLE_SUBMITTED"}
                firstText={"PROPOSAL_DECLINE_FIRST_TEXT"}
                secondText={" "}
                buttonText={"SCOPING_SUBMITTED_FORMATION_STATUS_BUTTON"}
                secondButtonText={"SCOPING_SUBMITTED_ACADEMY_BUTTON"}
                handleNext={() => dispatch({ type: 'GO_TO', page: PAGES.switchStartup })}
                handleClose={() => {
                    return window.open("https://academy.clara.co", "_blank");
                }}
            />
        </React.Fragment >
    )
}

export default ScopingProposalDeclineView;

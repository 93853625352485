import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import Typography from "src/modules/generic/components/Texts/Typography";
import classes from "./classes.module.scss";

function BetterFitADGMTL() {
  const { next, context, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.BetterFitADGMTL ?? {}
  }, [context]);

  const handleCancel = () => {
    cancel();
  }

  const handlePrev = () => {
    prev();
  }

  const handleSubmit = async (values) => {
    next(values);
  }

  const options = [
    {
      value: true,
      component: ButtonMultipleList,
      componentProps: {
        title: "Yes, form as an ADGM Tech Licence",
        text1: "The ADGM Tech Startup Licence is a low-cost operating Licence available for innovative tech-driven startups. Depending on the maturity stage of the startup, it may qualify for the initial seed stage Licence. The next level is the emergent stage Licence.",
      }
    },
    {
      value: false,
      component: ButtonText,
      componentProps: {
        title: "No, choose a different location to form a company"
      }
    },
  ];

  useEffect(() => {
    dispatch(addAssistantText('The company hasn\'t met all the requirements for ADGM SPV formation, so Clara recommends forming as an ADGM Tech Licence instead. If you do not wish to form with a Tech Licence, another jurisdiction might be a better fit for your company instead.'));
    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch]);

  const schema = Yup.object().shape({
    selectOrChangeLocation: Yup.boolean().nullable().required("Required"),
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: 'An ADGM Tech Licence is a better fit',
          skeleton: null,
          subTitle: 'ADGM SPV eligibility',
          variant: 'simple'
        }}
        buttons={{
          previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('BUTTON_NEXT')}</SubmitButton>,
        }}
      >
        <Typography component='h4'>
          The new company doesn’t meet all the ADGM SPV requirements, but an ADGM Tech Licence fits your needs. Form with this licence instead?
        </Typography>
        <div>
          <ControllerInput
            as={CheckboxButtonSimple}
            className={classes.FormSPVInADGMCheckboxContainer}
            classNameButton={classes.FormSPVInADGMCheckboxButton}
            name="selectOrChangeLocation"
            variant="col_1"
            options={options}
          />
        </div>
      </TemplateWizard>
    </Form>
  );
}

export default BetterFitADGMTL;

const ButtonText = ({ title, text }) => {
  return (
    <div className={classes.FormSPVInADGMCheckboxButtonText}>
      <Typography weight="semibold" variant="h4" className="mb-2">
        {title}
      </Typography>
      <Typography variant="body">{text}</Typography>
    </div>
  );
};

const ButtonMultipleList = ({ title, text1 }) => {
  return (
    <div className={classes.FormSPVInADGMCheckboxButtonText}>
      <Typography weight="semibold" variant="h4" className="mb-2">
        {title}
      </Typography>
      <div className={classes.FormSPVInADGMCheckboxButtonMultipleListContainer}>
        <Typography className={classes.FormSPVInADGMCheckboxButtonMultipleListTitle} variant="body">{text1}</Typography>
      </div>
    </div>
  );
};

import React, {Component} from "react";
import {connect} from "react-redux";
import {GroupCompanyDeleteForm, Loading} from "../../../components";

import Mustache from 'mustache'
import ServerConnect from '../../../utils/ServerConnect';
import {Constants} from '../../../utils/constants';
import _ from "lodash";
import FormikHelper from "../../../utils/formikHelper";
import EditForm from "../../../components/Form/ClaraForm/EditForm";

import ViewClient from "../../../components/Forms/View/IncorporationCompany/Client"
import EditDetails from "../../../components/Forms/Edit/GroupCompany/Details"
import ViewDocument from "../../../components/Forms/View/IncorporationCompany/Document"
import EditDocument from "../../../components/Forms/Edit/Document"
import ViewScoping from "../../../components/Forms/View/IncorporationCompany/Scoping"
import EditEquity from "../../../components/Forms/Edit/GroupCompany/Equity";
import {mutation, query, queryGetGroupCompanies} from '../../../graphql/mutations/addGroupCompany';
import {getGroupCompaniesWrong} from "../../../graphql/mutations/addStakeholder";


class EditIncorporationCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      refresh: false,
      showLoading: false,
      groupCompanies: props.session.groupCompanies,
      wasUpdated: false,
    }
  }

  refreshGroupCompanies(cb, ) {
    const query_params = {
      startup_id: this.props.session.startup.id
    };
    const queryGC = Mustache.render(queryGetGroupCompanies, query_params);

    ServerConnect.graphQlQuery(queryGC, {}).then((resultGC) => {
      //TODO REDUX: Ver que hacia la line de abajo
      // this.props.setGroupCompanies(resultGC.stakeholderMap.groupCompanies)
      if (cb) {
        cb()
      }

    });
  }

  callServerToGetData = () => {
    return new Promise((resolve, reject) => {
      const query_params = {
        startupId: this.props.session.startup.id,
        groupCompanyId: this.props.params.id
      };
      const queryExecute = Mustache.render(query, query_params);
      ServerConnect.graphQlQuery(queryExecute).then(result => {
        let data = {
          ...result.getGroupCompany
        };
        resolve(data);
      });
    })
  };

  getTitleEditForm = () => {
    return this.state.data ? this.state.data.name + " - 1231312" : "";
  };

  getGraphQlConfig = () => {

  };

  getGroupCompany = (groupCompany_id) => {
    return this.props.session.groupCompanies.find(function (element) {
      return element.id === groupCompany_id;
    });

  };
  transformInitialData = (data) => {
    let newData = _.clone(data);
    newData.address = data.registeredAddress;
    newData.equity = [];
    newData.shares = []
    newData.shareOptions = [];
    newData.convertibles = [];
    newData.warrants = [];
    _.forEach(data.issuedEquity, eq => {
      switch (eq.equity.type) {

        case "SHARE":
          newData.shares.push({
            "id": eq.equity.id,
            "holder": eq.holder,
            "shareClass": _.assign(eq.equity.shareClass, { type: "shareClass" }),//Class
            "amount": eq.equity.amount,//No. of Shares
            "price": eq.equity.price,// Price per Share,
            "currency": eq.equity.currency,
            "issuedDate": eq.equity.issuedDate,
            "title": "Share Grant",
            "type": eq.equity.type,
            "documents": eq.equity.documents,
            "filterField": eq.holder.name || eq.holder.fullName
          });
          break;
        case "OPTION":
          newData.shareOptions.push({
            "id": eq.equity.id,
            "holder": eq.holder,
            "shareIncentivePlan": _.assign(eq.equity.shareIncentivePlan, { type: "shareIncentivePlan" }),
            "amount": eq.equity.amount,//No. of Share Options
            "price": eq.equity.price,//Exercise Price
            "shareClass": _.assign(eq.equity.shareClass, { type: "shareClass" }),
            "currency": eq.equity.currency,
            "title": "Share Options",
            "type": eq.equity.type,
            "issuedDate": eq.equity.issuedDate,
            "documents": eq.equity.documents,
            "filterField": eq.holder.name || eq.holder.fullName
          });
          break;
        default:
          break;

      }

    })

    _.forEach(data.issuedConvertibles, c => {

      let { convertible, holder } = c
      newData.convertibles.push({
        ...convertible,
        holder: _.assign(holder, { type: "stakeholder" }),
        "title": "Convertibles",
        "type": "CONVERTIBLE",
        "filterField": holder.fullName
      })
    })
    _.forEach(data.issuedWarrants, w => {

      let { warrant, holder } = w;
      newData.warrants.push({
        ...warrant,
        holder: _.assign(holder, { type: "stakeholder" }),
        title: "Warrants",
        "type": "WARRANT",
        "filterField": holder.fullName
      })
    })

    return newData;
  };

  transformFinishData = (value) => {
    this.setState({
      showLoading: true
    });

    const startupId = this.props.session.startup.id;
    const structuredResponse = FormikHelper.mapGroupCompanyDataForMutation(value);

    const data = {
      startupId,
      groupCompanyId: this.state.data.id,
      ...structuredResponse
    };
    delete data.groupCompany.documents;
    ServerConnect.graphQlMutation(mutation, data).then((result) => {
      this.callServerToGetData().then(dataResult => {
        const cb = () => {
          this.setState({
            showLoading: false,
            data: this.transformInitialData(dataResult),
            refresh: !this.state.refresh,
            wasUpdated: true,
          });
        }
        this.refreshGroupCompanies(cb);
      })

    });
  };


  callServerToGetGroupCompanies = () => {
    return new Promise((resolve, reject) => {
      const queryParams = { startupId: this.props.session.startup.id };
      //const query = Mustache.render(getGroupCompanies, queryParams);
      ServerConnect.graphQlQuery(getGroupCompaniesWrong,queryParams).then(result => {
        if (result && result.getGroupCompanies) {
          resolve(_.orderBy(result.getGroupCompanies, ['isHoldingCompany'], ['desc']))
        }
      });
    })
  };


  componentDidMount() {
    this.setState({
      showLoading: true
    });
    Promise.all([this.callServerToGetData(), this.callServerToGetGroupCompanies()])
      .then((result) => {
        this.setState({
          data: this.transformInitialData(result[0]),
          refresh: !this.state.refresh,
          showLoading: false,
          groupCompanies: result[1],
        })
      })

    ServerConnect.requestApi(
      `/contentful/tooltips/${Constants.CONTENTFUL.ENTRIES.FORMS.GROUPCOMPANY.TOOLTIP}`,
      {
        method: 'GET', headers: { "Content-Type": "application/json" },
      }).then(res => {
        console.log('response', res.response);
        this.setState({
          tooltips: res.response
        })
      }).catch(err => {
        console.log('Tooltips from contentful error: ', err);
      });
  }

  handleShowLoading = (value) => {
    this.setState({
      showLoading: value
    });
  }

  refresData = (newData) => {
    Promise.all([this.callServerToGetData(), this.callServerToGetGroupCompanies()])
      .then((result) => {
        this.setState({
          data: this.transformInitialData(result[0]),
          refresh: !this.state.refresh,
          showLoading: false,
          groupCompanies: result[1],
        })
      })
  }

  render() {
    let { mode } = this.props;
    let { data, groupCompanies, wasUpdated } = this.state;
    return (
      <Loading show={this.state.showLoading}>
        <EditForm
          formType={Constants.FORMS.GROUP_COMPANIES}
          accessDelete={Constants.ACTIONS.EDIT_PROFILE}
          title={this.getTitleEditForm()} mode={mode} data={data} wasUpdated={wasUpdated}
        >
          <EditForm.Container tab={{ name: "Client", key: "client" }}>
            <EditForm.View isView={true}>
              <ViewClient data={data} user={this.props.session.user} />
            </EditForm.View>
            <EditForm.Edit>
              <EditDetails
                tooltips={this.state.tooltips}
                data={data}
                updateForm={this.transformFinishData}
              />
            </EditForm.Edit>
          </EditForm.Container>

          <EditForm.Container tab={{ name: "Scoping", key: "Scoping" }}>
            <EditForm.View isView={true}>
              <ViewScoping
                isGroupCompany={true}
                data={data}
                refreshData={this.refresData}
                updateForm={this.transformFinishData} goTo={this.props.goTo}
                showLoading={this.handleShowLoading}
              />

            </EditForm.View>
            <EditForm.Edit>
              <EditEquity
                data={data}
                updateForm={this.transformFinishData}
                groupCompanies={groupCompanies}
              />
            </EditForm.Edit>
          </EditForm.Container>
          <EditForm.Container tab={{ name: "Documents", key: "documents" }}>
            <EditForm.View isView={true}>
              <ViewDocument
                data={data}
                title={this.props.session.user.firstName+"'s Documents"}
                updateForm={this.transformFinishData}
                accessToAdd={Constants.ACTIONS.EDIT_PROFILE}
              />
            </EditForm.View>
            <EditForm.Edit>
              <EditDocument
                data={data}
                updateForm={this.transformFinishData}
                documentTypes={Constants.DOCUMENTS_TYPES.GROUPCOMPANY}
              />
            </EditForm.Edit>
          </EditForm.Container>
          <EditForm.Delete isDelete={true}>
            <GroupCompanyDeleteForm data={data} >
            </GroupCompanyDeleteForm>
          </EditForm.Delete>
        </EditForm>
      </Loading>
    )
  }
}

const mapStateToProps = state => ({
  session: state.session,

});
const mapDispatchToProps = (dispatch) => ({

});
export default connect(mapStateToProps, mapDispatchToProps)(EditIncorporationCompany)

import React from 'react';
import {Field, Formik} from 'formik';
import {ClaraInputPhone, OptionsButtonInput} from '../../../../components';
import TextInput from "../../Inputs/TextInput";
import classesForm from '../Form.module.scss';
import * as Yup from 'yup'
import {OnBehalfFormSchema} from '../validations/validationForms';
import {isEmpty} from 'lodash'

const OnBehalfForm = ({ initialValues, onSubmit }) => {

    const validateSchema = Yup.object().shape(OnBehalfFormSchema)
    const handleOptionsButtonClick = (field, form, value) => {
        form.setFieldValue(field.name, value);
    }


    return (
        <React.Fragment>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validate={null}
                onSubmit={onSubmit}
                validationSchema={validateSchema}
                isInitialValid={validateSchema.isValidSync(initialValues)}

                render={({
                    values,
                    errors,
                    dirty,
                    handleSubmit,
                    isSubmitting,
                    handleReset,
                    setFieldValue,
                    isValid
                }) => {
                    if (!isEmpty(values)) {

                        return (
                            <React.Fragment>
                                <div className={classesForm.containerData} >
                                    <Field name={"firstName"}>
                                        {({ field, form }) => (
                                            <TextInput
                                                field={field}
                                                form={form}
                                                label={"First Names:"}
                                                placeholder={"First Names..."}
                                            />

                                        )}

                                    </Field>

                                    <Field name={"lastName"}>
                                        {({ field, form }) => (
                                            <TextInput
                                                field={field}
                                                form={form}
                                                label={"Surname:"}
                                                placeholder={"Surname..."}
                                            />

                                        )}

                                    </Field>

                                    <Field name="contactNumber">
                                        {({ field, form }) => (
                                            <ClaraInputPhone
                                                field={field}
                                                form={form}
                                                label={"Contact Number"}
                                            />
                                        )}

                                    </Field>

                                    <Field name={"pointOfContact"}>
                                        {({ field, form }) => (
                                            <OptionsButtonInput
                                                field={field}
                                                form={form}
                                                className={{ root: classesForm.buttonOptions }}
                                                label={"Will you be the point of contact for this Incorporation?"}
                                                options={[
                                                    {
                                                        "id": true,
                                                        "text": "Yes"
                                                    },
                                                    {
                                                        "id": false,
                                                        "text": "No"
                                                    }
                                                ]}
                                                onClick={handleOptionsButtonClick}
                                            // params={this.props.params}
                                            // functions={this.props.functions}
                                            />

                                        )}
                                    </Field>
                                    <div className={`botoneraButtons`}>
                                        <button disabled={!isValid} className={`genericBlue`} onClick={() => onSubmit(values)} > Add </button>
                                    </div>

                                </div>
                            </React.Fragment>
                        )

                    }
                }

                }
            />



        </React.Fragment>
    )
}

export default OnBehalfForm;
import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch } from 'react-redux';
import { Redirect, Route, useLocation, useParams } from "react-router-dom";
import useSecurity from "../../../modules/security/hooks/useSecurity";
import useSession from "../../../modules/session/hooks/useSession";
import userVersion from "./../../../modules/versions/hooks/useVersion.jsx";
import { saveSessionState } from "./../../../session";
import ManagerRoutePage from "./ManagerRoutePage";

function ManagerRoute({ children, ...rest }) {

  const params = useParams();
  const { session } = useSession();
  const { hasSessionAccess, hasRoleAccess } = useSecurity();
  //*-***---------Revisar
  const location = useLocation()
  const keys = { module: "versions" };
  // const { data = null, loading, error } = useGetFormDraft({ keys }, location.pathname.includes("auth"));
  ///--------------------------revisar
  const dispatch = useDispatch();
  const { getVersion } = userVersion()

  const hasAccess = () => {
    const access = hasSessionAccess(rest.path);
    const accessRol = hasRoleAccess(rest.action, params);
    return (access && accessRol);
  }

  const handleRedirect = (data) => {
    const accessTo = hasAccess();
    if (accessTo) {
      return (
        <>
          <ManagerRoutePage props={data} rest={rest} version={getVersion(rest.versionKey, null)} >
            <>{children}</>
          </ManagerRoutePage>
        </>
      )
    } else {
      if (_.get(rest, 'redirectOnLogin')) {
        saveSessionState('redirect_url', {
          redirectPath: _.get(rest, 'path'),
          redirectUrl: _.get(rest, 'computedMatch.url'),
          paramsUrl: _.get(rest, 'computedMatch.params')
        })
        return (< Redirect to={{ pathname: "/" }} />)
      } else {
        return (< Redirect to={{ pathname: "/" }} />)
      }
    }
  }

  useEffect(() => {
    dispatch({ type: "ASSISTANCE_CLOSE_FORM" })
  }, []);

  return (
    <React.Fragment>
      <Route
        exact={rest.exact} path={rest.path}
        render={props =>
          handleRedirect(props)
        }
      />
    </React.Fragment>
  );
}


export default ManagerRoute

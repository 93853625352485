const useGuards = () => {    

    const isManagedByClara = (context)=>{
        return context.type === 'managedByClara'
    }

    const isDelete = (context)=>{
        return context.type === 'delete'
    }

    const isInvalidate = (context)=>{
        return context.delete[0].includes('invalidate')
    }

    const isFinish = (context)=>{
        return !isInvalidate(context)
    }
    const isConnections = (context)=>{
        return context.type === 'connections'
    }

    return {
        isManagedByClara,
        isDelete,
        isInvalidate,
        isFinish,
        isConnections
    }
}

export default useGuards
import { default as React, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import * as Yup from 'yup';
import Button from 'src/modules/generic/components/Buttons/Button';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import AddressInput from "src/components/forms/modules/startups/groupCompany/AddressInput";
import _ from 'lodash';

function ConfirmForm({
  isManagedByClara = false,
}) {
  const { next, context, cancel, prev } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();


  const schema = Yup.object().shape({
    address: Yup.object().shape({
      country: Yup.string().required("This field is required").nullable(),
      street: Yup.string().required("This field is required").nullable(),
      city: Yup.string().required("This field is required").nullable(),
      state: Yup.string().nullable(),
      zipCode: Yup.string().nullable(),
    }),
  });

  const initialValues = useMemo(() => {
    if (!_.isEmpty(context?.AddressForm?.address)) {
      return context?.AddressForm
    } else {
      return context?.initialData?.stakeholder?.address ? {
        address: context?.initialData?.stakeholder?.address
      } : {}
    }
  }
    , [context])

  const handleCancel = (values) => {
    cancel();
  }

  const formRef = useRef(null);

  const handleSubmit = async (values) => {
    next(values)
  }

  useEffect(() => {
    dispatch(addAssistantText('To change your primary residential address <strong>additional fees will apply</strong>.', "alert"))
    return () => {
      dispatch(clearAssistatText())
    }
  }, [dispatch])



  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
      ref={formRef}
    >
      <TemplateWizard
        props={{
          title: "Review theis address",
          skeleton: null,
          subTitle: `Review details for ${context?.initialData?.stakeholder?.fullName}`,
          variant: 'simple'
        }}

        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>Save</SubmitButton>,
          previous: <Button onClick={prev}>Previous</Button>,
        }}
      >
        <AddressInput
          name="address"
          label={{
            country: translate("MODULES.CLARA.ADDRESS_LABEL_PRIMARY_RESIDENTIAL"),
          }}
          zipCodeOptional
          isRequired
        />
        <div>&nbsp;</div>
      </TemplateWizard>
    </Form>
  );
}

export default ConfirmForm;

import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import Button from 'src/modules/generic/components/Buttons/Button';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import TemplateLabel from 'src/modules/generic/components/Inputs/LabelInput';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import ClaraList from "src/modules/generic/components/Lists/ClaraList";
import Typography from 'src/modules/generic/components/Texts/Typography';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText } from 'src/modules/generic/store/action';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import * as Yup from 'yup';
import classes from './classes.module.scss';

function ReviewShareInIncorporatedCompanies() {

  const { next, context, cancel, prev } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.ReviewShareInIncorporatedCompanies ?? {}
  }, [context]);

  const handleCancel = (values) => {
    cancel();
  }

  const handleSubmit = async (values) => {
    next(values)
  }

  useEffect(() => {
    dispatch(clearAssistatText())
  }, [dispatch])

  const schema = Yup.object().shape({
    accept: Yup.boolean().required("Required"),
    // accept: Yup.mixed().oneOf([true, false, []]).required("Required"),
  });

  const options = [
    {
      value: false,
      label: "No",
    },
    {
      value: true,
      label: "Yes",
    }
  ];

  const groupCompanies = context.initialData.groupCompanies ?? null;

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: 'Company purpose',
          skeleton: null,
          subTitle: 'Review company details',
          variant: 'simple'
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined} >Next</SubmitButton>,
          previous: (
            <Button onClick={prev} variant="secondary">
              Previous
            </Button>
          ),
        }}
      >
        <>
          <Typography component='p' variant='subTitle'>
            <span dangerouslySetInnerHTML={{ __html: "The company has shares in the incorporated companies listed below:" }}></span>
          </Typography>
          {groupCompanies && groupCompanies.length > 0 ? (
            <ClaraList
              items={groupCompanies}
            />
          ) : (
            <Typography component='p' variant='body' fontWeight="semibold">
              None
            </Typography>
          )}
          <TemplateLabel
            label={'Please confirm this is correct'}
            isRequired
            variant='top'
          >
            <ControllerInput
              render={CheckboxButtonSimple}
              name='accept'
              options={options}
              onChange={(value) => {
              }}
              isMultiple={false}
              classNameButton={classes.buttonCheckBox}
            />
          </TemplateLabel>
        </>
        <div>&nbsp;</div>
      </TemplateWizard>
    </Form>
  );
}

export default ReviewShareInIncorporatedCompanies;
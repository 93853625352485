import React, { useEffect, useState } from 'react';

import Money from "src/components/text/Text/Money";
import PropTypes from "prop-types"
import TwoColumnsViewer from "src/modules/generic/components/TwoColumsViewer";
import _ from "lodash";

function QuestionnairePanel({ data }) {
  const [colum1, setColum1] = useState([])
  const [colum2, setColum2] = useState([])

  useEffect(() => {
    if( !_.isEmpty(data)){
      const itemsCol1 = []
      const itemsCol2 = []
      
      itemsCol1.push({
        label: "How many full-time employees did the company start with?",
        value: _.get(data, "employeeAmountStart")
      },)
      itemsCol1.push({
        label: "How many full-time employees does the company have now?",
        value: _.get(data, "employeeAmountNow")
      },)
      itemsCol1.push({
        label: "Are any of the full-time employees a shareholder in the company?",
        value: _.get(data, "isEmployeeShareholder.question") ? "Yes" : "No"
      },)
      itemsCol1.push({
        label: "How many UAE employee visas are issued under the company?",
        value: _.get(data, "uaeVisasIssuedAmount")
      },)
      itemsCol1.push({
        label: "Does any shareholder of the company hold a visa issued by another UAE entity?",
        value: _.get(data, "isThereEmployeeWithOtherVisas.question") ? "Yes" : "No"
      },)

      itemsCol2.push({
        label: "Will you be hiring any additional team members?",
        value: _.get(data, "isThereFutureEmployees.question") ? "Yes" : "No"
      },)
      itemsCol2.push({
        label: "What will be the team member hiring timeline?",
        value: _.get(data, "isThereFutureEmployees.description", "-")
      },)
      itemsCol2.push({
        label: "Is the company operational?",
        value: _.get(data, "isCompanyOperational.question") ? "Yes" : "No"
      },)
      if(_.get(data, "isCompanyOperational.question")){
        itemsCol2.push({
          label: "How many jurisdictions is this business currently operating in?",
          value: _.get(data, "isCompanyOperational.description")
        },)
      }
      itemsCol2.push({
        label: "What is the revenue of the company?",
        value: <Money value={_.get(data, "companyRevenue")} prefix={"USD"} showDecimals={true} />
      },)
      itemsCol2.push({
        label: "Briefly explain the future plans of the company.",
        value: _.get(data, "futurePlans")
      },)

      setColum1(itemsCol1)
      setColum2(itemsCol2)
    }

  }, [data])
  

  return (
    <>
      <TwoColumnsViewer
        itemsCol1={colum1}
        itemsCol2={colum2}
      />
    </>
  );
}

QuestionnairePanel.propTypes = {
  data: PropTypes.any,
  isLock: PropTypes.bool,
  isFormation: PropTypes.bool,
}

export default QuestionnairePanel;
import React from "react";
import classesModule from "./classes.module.scss";
import ClassNames from "classnames";

interface TemplateLabelDefaultProps {
  variant?: "top" | "center" | "bottom";
}

type TemplateLabelProps = TemplateLabelDefaultProps;

export interface TemplateLabelInterface<PropsType = any> {
  children: React.ReactNode;
  label: React.ReactNode;
  className?: string;
  props?: PropsType;
  isRequired?: boolean;
}

export type TemplateLabelComponent<PropsType = any> = React.FC<TemplateLabelInterface<PropsType>>;

const Template: TemplateLabelComponent<TemplateLabelProps> = ({
  children,
  label,
  className,
  isRequired = false,
  props = { variant: "center" },
}: TemplateLabelInterface) => {
  return (
    <React.Fragment>
      <div
        className={ClassNames(`${classesModule.TemplateLabel} ${classesModule[props.variant]} ${classesModule["text-color-primary"]} ${className}`,
          { [classesModule["label-required"]]: isRequired }
        )}
      >
        <label>{label}</label>
        <div>{children}</div>
      </div>
    </React.Fragment>
  );
};

export default Template;

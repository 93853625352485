import { MachineConfig, assign } from "xstate";
import { StepDocumentDetails, StepSelectCreationDocument, StepIpAgreement } from './Steps';
import Machine from "../../../generic/components/Machine/MachineClass";

export type typeFormCreation = 'GENERATE_DOCUMENT' | 'UPLOAD_DOCUMENT' | 'CANCEL_CREATION';

interface DataDocumentContext {
  form_of_document_creation: { typeFormCreation: typeFormCreation }
}
interface ContextDocument {
  [key: string]: any,
  data: DataDocumentContext
}

const isGenerateDocument = ({ data }: ContextDocument) => data.form_of_document_creation.typeFormCreation === 'GENERATE_DOCUMENT';
const isCancelCreationDocument = ({ data }: ContextDocument) => data.form_of_document_creation.typeFormCreation === 'CANCEL_CREATION';
const isUploadDocument = ({ data }: ContextDocument) => data.form_of_document_creation.typeFormCreation === 'UPLOAD_DOCUMENT';
const isTypeIpAgreement = ({ paramsData }) => paramsData.type === "IP_ASSIGNMENT";

const setCompany = assign((context: ContextDocument, { payload }) => {
  const { paramsData } = context;
  const { values } = payload;
  return { ...context, paramsData: { ...paramsData, groupCompanyId: values.company } }
})

const machineDefinition: MachineConfig<ContextDocument, any, any> = {
  id: 'ip_generate',
  initial: 'form_of_document_creation',
  states: {
    form_of_document_creation: {
      on: {
        NEXT: { target: 'loading', actions: 'setContext' },
        CANCEL: 'finish_upload_document',
      },
    },
    loading: {
      always: [
        { target: 'finish_upload_document', cond: 'isCancelCreationDocument' },
        { cond: 'isGenerateDocument', target: 'finish_upload_document' },
        { cond: 'isTypeIpAgreement', target: 'select_company' },
        { target: 'details_document', cond: 'isUploadDocument' },
      ]
    },
    select_company: {
      on: {
        NEXT: { target: 'details_document', actions: 'setCompany' },
        CANCEL: 'finish_upload_document',
      }
    },
    details_document: {
      on: {
        PREVIOUS: { target: 'form_of_document_creation' },
        NEXT: { target: 'finish_upload_document', actions: 'setContext' },
        CANCEL: 'finish_upload_document',
      },
    },
    finish_upload_document: {
      type: 'final',
      entry: 'final',
    },
    onDone: {
      actions: 'stopMachine'
    }
  },
}

const steps = {
  form_of_document_creation: StepSelectCreationDocument,
  details_document: StepDocumentDetails,
  select_company: StepIpAgreement
}

const options = {
  guards: {
    isGenerateDocument,
    isCancelCreationDocument,
    isUploadDocument,
    isTypeIpAgreement
  },
  actions: {
    setCompany
  }
}

export default class MachineCompany extends Machine {
  constructor() {
    super(machineDefinition, options, steps);
  }
  getParamsData({ paramsMutation }) {
    return { ...paramsMutation }
  }
  getInitialValues({ initialValues = {} }) {
    return initialValues
  }
}
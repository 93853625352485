import React from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";

const MyTransationExerciseManual = ({ go, ...props }) => {
  const {startupId, ...params} = useParams();

  return (
    <MicroFrontend id="MF_myEquity_myTransation_exerciseManual" component={"./DetailsExerciseManual"} mf="capTable" params={{ go, ...props, ...params, startup:{id: startupId} }}/>
  );
};

export default MyTransationExerciseManual;

import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import classesModule from "./BreadCrumbs.module.scss";
import { useSelector } from 'react-redux';
import { goToPage} from './../../../../store/actions/page';
import _ from "lodash";
import classnames from 'classnames';

const BreadCrumbs = ({ ...props }) => {
    const [enabledDots, setEnabledDots] = useState(false);
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();
    const breadCrumbsRef = useRef();
    const breadCrumbs = useSelector(state => _.get(state, `page.breadCrumbs`, []));
    const handleClick = (breadCrumb) => {
        goToPage(dispatch, { path: breadCrumb.path }, breadCrumb.params)
    }
    useLayoutEffect(() => {
        if (!enabledDots) {
            const withChildren = breadCrumbsRef.current.clientWidth;
            const withParent = breadCrumbsRef.current.parentElement.clientWidth;
            if (withChildren > withParent) {
                setEnabledDots(true)
            } else {
                setEnabledDots(false)
            }
            setVisible(true)
            return () => { }
        }
    }, [_.get(breadCrumbsRef, "current.clientWidth")]);
    const getLabel = (breadCrumb) => {
        if (typeof breadCrumb.label=="string"){
            return breadCrumb.label
        }
        if (breadCrumb.paramsLabel) {
            return React.cloneElement(breadCrumb.label, { params: breadCrumb.paramsLabel })
        } else {
            return breadCrumb.label;
        }
        
    }
    return (
        <div className={` ${classesModule.BreadCrumbsContent}`}>
            <div
                className={classnames({
                    [classesModule.visible]: visible
                }, classesModule.BreadCrumbs)}
                ref={breadCrumbsRef}
            >   
                
                {breadCrumbs.map((breadCrumb, i) => {
                    if (enabledDots && breadCrumbs.length > 6 && i == 2) {
                        return (
                            <React.Fragment key={i}>
                                <label key={i}> ... </label> <span> &gt; </span>
                            </React.Fragment>
                        )
                    }
                    if (enabledDots && breadCrumbs.length > 6 && i > 2 && i < breadCrumbs.length - 2) {
                        return null
                    }
                    if ((i + 1) < breadCrumbs.length) {
                        return (
                            <React.Fragment key={i}>
                                <a key={i} onClick={() => { handleClick(breadCrumb) }}>
                                    {getLabel(breadCrumb)}
                                </a>
                                <span> &gt; </span>
                            </React.Fragment>
                        )
                    } else {
                        return (
                            <label key={i}>
                                {getLabel(breadCrumb)}
                            </label>
                        )
                    }
                })}
            </div>
        </div>
    );
}

export default (BreadCrumbs);
import React from 'react';
import { Constants } from '../../../../utils/constants'
import { useDispatch } from "react-redux";
import CompanyDetailsView from './CompanyDetailsView';
import IndividualEntityView from './IndividualEntityView';
import BankingServicesView from './BankingServicesView';
import ShareholdersQuestionnaireView from './ShareholdersQuestionnaireView';
import NASView from './NASView';
import CategoryNamesListQuestionnaireView from './categoryNamesListQuestionnaireView';


const FactoryQuestionnaireContent = (props) => {

    const dispatch = useDispatch();
    const { mode, data } = props;




    const generateContent = () => {
        switch (mode) {
            case Constants.QUESTIONNAIRE_KEYS.COMPANY_DETAILS:
                return <CompanyDetailsView data={data} />
            case Constants.QUESTIONNAIRE_KEYS.NAS:
                return <NASView data={data} />
            case Constants.QUESTIONNAIRE_KEYS.SHARESDATA:
                return <ShareholdersQuestionnaireView data={data} matterId={props.matterId}  isDraftData= {props.isDraftData} />
            case Constants.QUESTIONNAIRE_KEYS.BANKING_SERVICES:
                return <BankingServicesView data={data} />
            case Constants.QUESTIONNAIRE_KEYS.DIRECTORS:
            case Constants.QUESTIONNAIRE_KEYS.SHAREHOLDERS:
            case Constants.QUESTIONNAIRE_KEYS.SIGNATORIES:
            case Constants.QUESTIONNAIRE_KEYS.DATA_CONTROLLER_ENTITY:
            case Constants.QUESTIONNAIRE_KEYS.AGREEMENTSDATA_ENTITY:
            case Constants.QUESTIONNAIRE_KEYS.SHAREHOLDEREDITABLE:
            case Constants.QUESTIONNAIRE_KEYS.BENEFICIAL_OWNERS:
                return <IndividualEntityView data={data} mode={mode} />
            case Constants.QUESTIONNAIRE_KEYS.DIRECTORS_CATEGORY:
            case Constants.QUESTIONNAIRE_KEYS.SIGNATORIES_CATEGORY:
            case Constants.QUESTIONNAIRE_KEYS.BENEFICIAL_OWNERS_CATEGORY:
            case Constants.QUESTIONNAIRE_KEYS.DATA_CONTROLLER:
            case Constants.QUESTIONNAIRE_KEYS.AGREEMENTSDATA:
                return <CategoryNamesListQuestionnaireView data={data} mode={mode} />
        }
    }






    return (
        <React.Fragment>
            {generateContent()}
        </React.Fragment >
    )
}

export default FactoryQuestionnaireContent;

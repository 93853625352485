import React, { useMemo, useState } from "react";
import Form from "src/components/forms/Form/Form";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import * as Yup from 'yup';
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import classes from "./classes.module.scss";
import Button from "src/modules/generic/components/Buttons/Button";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";

function MoreToAdd() {
  const { next, cancel, context } = useMachine();
  const { translate } = useTranslate();
  const [defaultBtnText, setDefaultBtnText] = useState("Next");  

  const initialValues = useMemo(() => {
    return context?.moreToAdd ?? {};
  }, [context]);

  const handleSubmit = (values) => {
    next(values);
  }

  const handleCancel = () => {
    cancel();
  };

  const schema = Yup.object().shape({
    another: Yup.boolean().required(),
  });

  return (
    <Form
      defaultValues={initialValues}
        schema={schema}
        onlySendDirty={false}
        onSubmit={handleSubmit}
        optionsForm={{ mode: "onChange" }}
      >
        <TemplateWizard
        props={{
          title: translate('MODULES.RENEWALS.BENEFICIAL_OWNERS.MORE_TO_ADD.TITLE'),
          subTitle: translate('MODULES.RENEWALS.BENEFICIAL_OWNERS.GENERIC.SUBTITLE'),
        }}
        buttons={{
          submit: (
            <SubmitButton>
              {defaultBtnText}
            </SubmitButton>
          ),
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("MODULES.CLARA.BTN_CANCEL")}
            </Button>
          ),
        }}
      >
        <ControllerInput
          render={CheckboxButtonSimple}
          name='another'
          variant='col_1'
          classNameButton={classes.buttonCheckBox}
          onChange={value => {
            if (value) {
              setDefaultBtnText("Next");
            } else {
              setDefaultBtnText("Continue");
            }
          }}
        />
      </TemplateWizard>
    </Form>
  );
}

export default MoreToAdd;

import { useMemo } from 'react';

const useGuards = () => {
  return useMemo(() => ({
    addNew: (context) => {
      const addedStep = context?.DirectorAdded;
      if (addedStep?.addAnother === true) {
        return true;
      }
    },
    doNotAddNew: (context) => {
      const addedStep = context?.DirectorAdded;
      if (addedStep?.addAnother === false) {
        return true;
      }
    }
  }), []);
}

export default useGuards;

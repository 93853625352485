import _ from "lodash";
import React from "react";
import StartupForm from "../../../../../../components/forms/modules/startups/startup/StartupForm";
import useUpsertStartup from "../../../../../../hooks/services/modules/startups/startups/useUpsertStartup";
import useModal from "../../../../../../modules/generic/hooks/useModal";
import useSession from "../../../../../../modules/session/hooks/useSession";
import useGetStartupProfile from "../graphql/useGetStartupProfile";
import ModalAssistant from '../../../../../../modules/generic/components/Modal/ModalAssistant';
import { LoadingProvider } from "../../../../../../modules/generic/hooks/useLoading";
import TemplateWizard from "../../../../../../modules/generic/templates/Modal/TemplateWizard";
import useTranslate from "../../../../../../modules/generic/hooks/useTranslate";
import SkeletonInput from "../../../../../../modules/generic/components/Skeletons/Overlays/SkeletonInput";
import SkeletonArea from "../../../../../../modules/generic/components/Skeletons/Overlays/SkeletonArea";
import Line from "../../../../../generic/Line/Line";

const EditProfile = ({
  callbackUpdateStartup,
  open,
  ...props
}) => {

  const { startup: startupLanding, updateStartup: updateStartupSession } = useSession();
  
  const { translate } = useTranslate();
  const { closeModal } = useModal();
  const overlayName = translate("MY_ACCOUNT_MODAL_EDIT_TITLE");

  const { data: startup } = useGetStartupProfile({
    startupId: startupLanding.id,
  });

  const handleCloseModal = () => {
    closeModal();

  };

  const handleFinishUpdate = (values) => {
    handleCloseModal();
    updateStartupSession(_.get(values, "upsertStartupProfile"))
  };

  const handleSubmit = async (values) => {
    await updateStartup({
      variables: { startupData: { ...values }, id: startup.id },
    });
  };

  const [updateStartup] = useUpsertStartup({
    onCompleted: handleFinishUpdate,
    successMessage: "NOTIFICATION_OVERLAY_SAVE_SUCCESS",
    paramsSuccessMessage: { overlayName },
  });

  return (
    <>
      <ModalAssistant open={open}>
        <LoadingProvider>
          {!_.isEmpty(startup) && <StartupForm
            initialValues={startup}
            handleCloseModal={handleCloseModal}
            handleSubmit={handleSubmit}
            Template={TemplateWizard}
            propsTemplate={{
              title: translate(`MY_ACCOUNT_MODAL_EDIT_TITLE`),
              skeleton: (
                <>
                  <SkeletonInput count={3}></SkeletonInput>
                  <Line />
                  <SkeletonInput></SkeletonInput>
                  <SkeletonArea noLabel={true}></SkeletonArea>
                </>
              ),
            }}
          />}
        </LoadingProvider>
      </ModalAssistant>
    </>
  );
};

export default EditProfile;

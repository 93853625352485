import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import Button from "src/modules/generic/components/Buttons/Button";
import TemplateWizardPreviewFull from "src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardPreviewFull";
import DocumentCategoriseData from "src/components/modules/clara/startups/Document/Modals/CategoriseDocument/forms/FormDocumentCategoriseData";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import { clearAssistatText } from "src/modules/generic/store/action";
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import _ from "lodash";
import useDocumentMapper from '../../hooks/useDocumentMapper'

function ConfigureDocument({ openParam, isCategorize, isEdit, isVisaOrPassportDocument }) {

  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const { next, state, context, send } = useMachine();
  const { getTitleAndSubTitle } = useDocumentMapper({ isCategorize, isEdit })

  const handleCancel = (values) => {
    send('CANCEL')
  }
  
  useEffect(() => {
    return () => {
      dispatch(clearAssistatText());
    };
  }, []);

  const handleCompleteSubmit = (values) => {
    next(values);
  }

  const { subTitle, title } = getTitleAndSubTitle()

  const dataInitialValues = useMemo(() => {
    const result = {
      ...context,
      files: _.get(state, "context.file.file") ? [_.get(state, "context.file.file")] : [],
    }
    return result;
  }, [context, context?.file?.file]);

  return (
    <DocumentCategoriseData
      initialValues={dataInitialValues}
      isCategorize={isCategorize}
      isEdit={isEdit}
      isVisaOrPassportDocument={isVisaOrPassportDocument}
      onCompleteSubmit={handleCompleteSubmit}
      buttons={{
        cancel: (
          <Button onClick={handleCancel} variant="secondary">
            {translate('BUTTON_CANCEL')}
          </Button>
        ),
      }}
      propsTemplate={{
        title: title,
        subTitle: subTitle,
        canEditDocument: !isCategorize,
        fileUploadProps: {
          fileId: _.get(state, "context.file.file.id"),
          fileType: _.get(state, "context.file.file.type"),
          fileName: _.get(state, "context.file.file.name"),
          defaultScale: 0.55,
        }
      }}
      Template={TemplateWizardPreviewFull}
    />
  );
}

export default ConfigureDocument;

import PropTypes from "prop-types";
import React from 'react';
import _ from "lodash";
import TwoColumnsViewer from "src/modules/generic/components/TwoColumsViewer";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import { Stack } from "@mui/material";

function NatureOfBusinessTab({ data }) {

  const { translate } = useTranslate();

  return (
    <Stack sx={{ width: "100%" }}>
      <TwoColumnsViewer
        itemsCol1={[
          {
            label: translate("VIEW_COMPANY_TAB_LABEL_OPERATION_COMPANY"),
            value: _.get(data, 'businessActivity.operatingCompany.name', "-"),
          },
          {
            label: "Country",
            value: _.get(data, 'businessActivity.operatingCompany.country.name', "-"),
          },
          {
            label: "Jurisdiction",
            value: _.get(data, 'businessActivity.operatingCompany.jurisdiction', "-"),
          },
          {
            label: "Website or social media link",
            value: data?.businessActivity?.operatingCompany?.linkedinURL ?? "-",
          },
          {
            label: translate("VIEW_COMPANY_TAB_LABEL_NATURE_OF_BUSINESS"),
            value: _.get(data, 'businessActivity.activity'),
          },
        ]}
      ></TwoColumnsViewer>
    </Stack>
  );
}

NatureOfBusinessTab.propTypes = {
  data: PropTypes.any,
}

export default NatureOfBusinessTab;
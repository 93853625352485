import React, { FC,useEffect } from 'react';
import { useDispatch } from 'react-redux';
import event from "src/v1/utils/event";
import useCustomMachine from '../../../../generic/hooks/useCustomMachine';
import Button from '../../../../generic/components/Buttons/Button';
import { StepProps } from "../../../../generic/components/Wizard/Step";
import TemplateWizard from '../../../../generic/templates/Modal/TemplateWizard';
import Form from "../../Forms/FormSelectCreationDocument";
import { goToNextPage } from '../../../../../store/actions/page';
import { Constants } from '../../../../../v1/utils';
import { clearAssistatText } from "../../../../generic/store/action";

const Step1: FC<StepProps> = () => {
    const { next, send, state } = useCustomMachine();
    const { type } = state.context.paramsData;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(clearAssistatText());
        return () => {
          dispatch(clearAssistatText());
        };
      }, []);

    const handleCompleteSubmit = async values => {
        if (values.typeFormCreation === 'GENERATE_DOCUMENT') {
            event.emmit(Constants.EVENTS.DOCUMENT_SCROLL, { type });
            return dispatch(
                goToNextPage(
                    Constants.PAGES.chooseDocumentType, { previewDocument: type }
                )
            );
        }

        next(values);
    }
    const handleCancel = () => {
        send("CANCEL");
    }

    return (
        <>
            <Form onCompleteSubmit={handleCompleteSubmit} Template={TemplateWizard} propsTemplate={{
                title: `Do they have an agreement for this convertible?`,
                subTitle: 'Add Convertible Document'
            }}
                buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }}>
            </Form>
        </>
    );
}

export default Step1;

import _ from "lodash";
import { useMemo } from "react";
import useQuery from "../../hooks/custom/useQuery";
import us from "../../langs/us";
const { CATEGORIES_TITLES, CATEGORIES_ORDER } = us;

export const QUERY_CATEGORIES = `query categories($kind:String){
  categories(kind: $kind){
    code
    subCategories {
      code
      documentTypes
    }
  }
}`;

export const QUERY_UPLOAD_CATEGORIES = `query getCategoriesForUpload{
  categoriesUpload{
    code
    subCategories {
      code
      documentTypes
    }
  }
}`;

export const mapToOldStructure = (categories, avoidEmptyDocs) => {
  const mappedCategoriesList = categories.map((cat) => ({
    key: cat.code,
    title: CATEGORIES_TITLES[cat.code],
    order: CATEGORIES_ORDER[cat.code],
    subCategories: cat.subCategories
      .map((subCat) => ({
        key: subCat.code,
        title: CATEGORIES_TITLES[subCat.code],
        documentTypes: subCat.documentTypes.map((dt) => ({
          key: dt,
          title: CATEGORIES_TITLES[dt],
        })),
      }))
      .filter((sub) => !(avoidEmptyDocs && !sub.documentTypes.length)),
  }));
  return _.sortBy(mappedCategoriesList, ["order"]);
};

export const useUploadCategories = (avoidEmptyDocs) => {
  const { data, loading } = useQuery(QUERY_UPLOAD_CATEGORIES);
  const categories = useMemo(() => mapToOldStructure(_.get(data,'categoriesUpload',[]), avoidEmptyDocs), [data]);
  return { categories, loading };
};

export default function useCategories(kind, avoidEmptyDocs = false) {
  const variables = { kind };
  const { loading, error, data } = useQuery(`${QUERY_CATEGORIES}`, variables, null);
  const categories = mapToOldStructure(
    _.get(data, "categories", []),
    avoidEmptyDocs
  );
  const findCategoryConstantFromDocType = (docType) => {
    const constantCategories = categories;
    let constant = "";
    for (let key in constantCategories) {
      if (constantCategories[key].subCategories) {
        constantCategories[key].subCategories.forEach((sc) => {
          if (
            sc.documentTypes &&
            sc.documentTypes.some((dt) => dt.key === docType)
          ) {
            constant = key;
          }
        });
      }
    }
    return constant;
  };

  const getCategoryFromDocType = (docType) => {
    if (!docType) return null;
    const categoryConstant = findCategoryConstantFromDocType(docType);
    return categories[categoryConstant];
  };

  const getSubcategoryFromDocType = (docType) => {
    const categoryConstant = findCategoryConstantFromDocType(docType);
    let subCategory = {};
    if (categories[categoryConstant] && categories[categoryConstant].subCategories) {
      categories[categoryConstant].subCategories.forEach((sc) => {
        if (sc.documentTypes && sc.documentTypes.some((dc) => dc.key === docType)) {
          subCategory = sc;
        }
      });
    }
    return subCategory;
  };

  return {
    categories,
    loading,
    error,
    getCategoryFromDocType,
    getSubcategoryFromDocType,
  };
}

import React, { FC, useRef } from "react";
import * as Yup from "yup";
import useTranslate from "../../../generic/hooks/useTranslate";
import Form from "../../../../components/forms/Form/FormL";
import ControllerInput from "../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import SubmitButton from "../../../generic/components/Buttons/SubmitButton";
import TemplateFormDefault from "../../../generic/templates/Modal";
import FormProps from "../../../generic/forms/interfaces/FormProps";
import { CheckboxButtonOptions } from "../../../generic/components/Inputs/CheckboxButton";
import CheckboxButtonSimple from "../../../generic/components/Inputs/CheckboxButtonSimple";
import classes from "./Form.module.scss";
import TextareaInput from "../../../../components/inputs/Textarea/TextareaInput";
import { HiddenFieldForm } from "../../../../components/forms/contents";
import Typography from "src/modules/generic/components/Texts/Typography";
const schema = Yup.object().shape({
  isThereCompaniesFormationsShares: Yup.boolean()
    .nullable()
    .required("Required"),
  formationCompaniesHavesShares: Yup.string().when(
    ["isThereCompaniesFormationsShares"],
    {
      is: true,
      then: Yup.string().nullable().required("Required"),
      otherwise: Yup.string().optional(),
    }
  ),
});

const IsIndividualOrCompany: FC<FormProps & {subTitle?:string}> = ({
  initialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
  subTitle
}: FormProps & {subTitle?:string}) => {
  const { translate } = useTranslate();
  const refForm = useRef();

  const options: Array<CheckboxButtonOptions<boolean, string>> = [
    {
      value: false,
      label: translate(
        "MODULES.GROUP_COMPANY.FORM.IS_THERE_COMPANIES_FORMATION_SHARES.NO"
      ),
    },
    {
      value: true,
      label: translate(
        "MODULES.GROUP_COMPANY.FORM.IS_THERE_COMPANIES_FORMATION_SHARES.YES"
      ),
    },
  ];

  return (
    <>
      <Form
        defaultValues={initialValues || {}}
        schema={schema}
        onlySendDirty={false}
        onSubmit={onCompleteSubmit}
        ref={refForm}
        optionsForm={{ mode: "onChange" }}
      >
        {" "}
        <Template
          buttons={{
            ...buttons,
            submit: <SubmitButton {...buttonSubmitProps}>Confirm</SubmitButton>,
          }}
          props={propsTemplate}
        >
          {subTitle && <Typography component='p' variant='subTitle'>
                <span dangerouslySetInnerHTML={{ __html: subTitle }}></span>
              </Typography>}
          <ControllerInput
            render={CheckboxButtonSimple}
            name="isThereCompaniesFormationsShares"
            options={options}
            className={classes.CheckboxButton}
            classNameButton={classes.buttonCheckBox}
          />
          <HiddenFieldForm
            fieldsWatch={"isThereCompaniesFormationsShares"}
            conditionHideCallback={(value) => value}
          >
            <div className={classes.describeContainer}>
              <ControllerInput
                name="formationCompaniesHavesShares"
                render={TextareaInput}
                className={classes.TextArea}
                placeholder={translate(
                  "MODULES.GROUP_COMPANY.FORM.IS_THERE_COMPANIES_FORMATION_SHARES.PLACEHOLDER"
                )}
              />
            </div>
          </HiddenFieldForm>
        </Template>
      </Form>
    </>
  );
};

export default IsIndividualOrCompany;

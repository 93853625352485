import React, { useEffect, useMemo, useState } from "react";

import { Constants } from "../../../../../../../v1/utils";
import EmptyState from "../../../../../../../modules/generic/components/EmptyState";
import PincasIcon from "src/modules/generic/components/Icon/PincasIcon";
import Source from "./Components/Source";
import Table from "../../../../../../Table/Table";
import _ from "lodash";
import classes from "./RolesTab.module.scss";
import moment from "moment";
import useBusinessRules from '../../../../../../../modules/clara/hooks/useBusinessRules';
import useSession from "../../../../../../../modules/session/hooks/useSession";
import useTranslate from "../../../../../../../modules/generic/hooks/useTranslate";

const RolesTab = ({ data, lock, isManagedByClara, jurisdictions }) => {
  const [groupCompanies, setGroupCompanies] = useState([]);
  const { startup } = useSession();
  const rolesLabels = Constants.ROLES_FROM_BACKEND;
  const { translate } = useTranslate();
  const { isViewer } = useBusinessRules();

  useEffect(() => {
    if (startup.groupCompanies) {
      setGroupCompanies(orderData);
    }
  }, [JSON.stringify(data), JSON.stringify(_.get(startup, "groupCompanies"))]);

  const handleType = (name) => {
    let result = "";
    if (_.includes(Constants.ROLES_GROUPS.GOVERNANCE, name)) {
      result = "Governance Roles";
    } else if (_.includes(Constants.ROLES_GROUPS.EQUITY, name)) {
      result = "Equity";
    } else if (_.includes(Constants.ROLES_GROUPS.IP, name)) {
      result = "IP";
    } else {
      result = "Team";
    }
    return result;
  };

  const orderData = useMemo(() => {
    let rolFounder = [];
    let rolShareholder = [];
    let rolDirector = [];
    let rolEmployee = [];
    let rolConsultant = [];
    let rolAdvisor = [];
    let rolInvestor = [];
    let rolThidParty = [];
    let rolConvertibleHolder = [];
    let rolWarrantHolder = [];
    let rolOptionHolder = [];
    let rolIpAssignor = [];
    let rolAutoriseSignatory = [];
    let rolUBO = [];

    _.forEach(data, (rol, index) => {
      let addData = data[index];
      const pair = {
        label: _.get(rolesLabels[rol.name], "label"),
        type: handleType(rol.name),
      };
      addData = { ...addData, ...pair };
      switch (rol.name) {
        case "FOUNDER": {
          rolFounder.push(addData);
          break;
        }
        case "SHAREHOLDER": {
          rolShareholder.push(addData);
          break;
        }
        case "DIRECTOR": {
          rolDirector.push(addData);
          break;
        }
        case "EMPLOYEE": {
          rolEmployee.push(addData);
          break;
        }
        case "CONSULTANT": {
          rolConsultant.push(addData);
          break;
        }
        case "ADVISOR": {
          rolAdvisor.push(addData);
          break;
        }
        case "INVESTOR": {
          rolInvestor.push(addData);
          break;
        }
        case "THIRDPARTY": {
          rolThidParty.push(addData);
          break;
        }
        case "CONVERTIBLEHOLDER": {
          rolConvertibleHolder.push(addData);
          break;
        }
        case "WARRANTHOLDER": {
          rolWarrantHolder.push(addData);
          break;
        }
        case "OPTIONHOLDER": {
          rolOptionHolder.push(addData);
          break;
        }
        case "IPASSIGNOR": {
          rolIpAssignor.push(addData);
          break;
        }
        case "AUTHORIZEDSIGNATORY": {
          rolAutoriseSignatory.push(addData);
          break;
        }
        case "UBO": {
          rolUBO.push(addData);
          break;
        }
        default:
          break;
      }
    });

    return [
      ...rolFounder,
      ...rolShareholder,
      ...rolDirector,
      ...rolEmployee,
      ...rolConsultant,
      ...rolAdvisor,
      ...rolInvestor,
      ...rolThidParty,
      ...rolConvertibleHolder,
      ...rolWarrantHolder,
      ...rolOptionHolder,
      ...rolIpAssignor,
      ...rolAutoriseSignatory,
      ...rolUBO,
    ];
  }, [data, rolesLabels]);

  const onlyOneRole = useMemo(() => {
    return data.length <= 1;
  }, [JSON.stringify(data)]);

  const isDisabledRow = (row) => {
    return row.original?.inProgress;
  };

  // TODO Review for StakeholderCorporate when rol.groupCompany is null
  // Review column "Company"
  const columns = [
    {
      Header: " ",
      columns: [
        {
          Header: () => <div className={classes.headerColumnTable}>Role</div>,
          accessor: "Role",
          Cell: ({ row }) => {
            const disabled = isDisabledRow(row);
            return (
              <div className={`${classes.coltex} ${classes.colFlexContent} ${disabled ? classes.colDisabled : ''}`}>
                {row.original.label}
                {disabled && <PincasIcon />}
              </div>
            );
          },
        },
        {
          Header: () => <div className={classes.headerColumnTable}>Company</div>,
          accessor: "Company",
          Cell: ({ row }) => {
            const disabled = isDisabledRow(row);
            return (
              <div className={`${classes.coltex}  ${disabled ? classes.colDisabled : ''}`}>
                {_.get(row, 'original.entity.name', '') || _.get(row, 'original.entity.fullName', 'N/A')}
              </div>
            );
          },
        },
        {
          Header: () => <div className={classes.headerColumnTable}>Type</div>,
          accessor: "Type",
          Cell: ({ row }) => {
            const disabled = isDisabledRow(row);
            return (
              <div className={`${classes.coltex} ${disabled ? classes.colDisabled : ''}`}>
                {row.original.type}
              </div>
            );
          },
        },
        {
          Header: () => <div className={classes.headerColumnTable}>Start date</div>,
          accessor: "Start Date",
          Cell: ({ row }) => {
            const disabled = isDisabledRow(row);
            return (
              <div className={`${classes.coltex} ${disabled ? classes.colDisabled : ''}`}>
                {_.isEmpty(row.original.startDate) ? "-" : moment(_.get(row, "original.startDate", "")).format("DD MMM YYYY")}
              </div>
            );
          },
        },
        {
          Header: () => <div className={classes.headerColumnTable}>End date</div>,
          accessor: "End Date",
          Cell: ({ row }) => {
            const disabled = isDisabledRow(row);
            return (
              <div className={`${classes.coltex} ${disabled ? classes.colDisabled : ''}`}>
                {_.isEmpty(row.original.endDate) ? "Present" : moment(_.get(row, "original.endDate", "")).format("DD MMM YYYY")}
              </div>
            );
          },
        },
        {
          Header: () => <div className={classes.headerColumnTable}></div>,
          accessor: "source",
          Cell: ({ row }) => {
            const disabled = isDisabledRow(row);
            if (row.original.name === "WARRANTHOLDER" || row.original.name === "CONVERTIBLEHOLDER" || row.original.name === "OPTIONHOLDER" || row.original.name === "IPASSIGNOR" || row.original.name === "UBO") {
              return null;
            } else {
              return (
                <Source
                  data={row}
                  lock={lock}
                  isManagedByClara={isManagedByClara}
                  onlyOneRole={onlyOneRole}
                  disabled={disabled}
                  jurisdictions={jurisdictions}
                />
              );
            }
          },
        },
      ],
    },
  ];


  return (
    <>
      {groupCompanies.length > 0 ? (
        <>
          <div className={classes.containerRolesTab}>
            <div className={classes.containerTable}>
              <Table
                columns={columns}
                data={groupCompanies}
              />
            </div>
          </div>
        </>
      ) : (
        <EmptyState
          title={isViewer() || lock ? translate("ROLES_EMPTY_STATE_VIEWER") : translate("ROLES_EMPTY_STATE_USER")}
          iconType={lock ? "Locked" : "SAFE"}
        />
      )}
    </>
  );
};

export default RolesTab;

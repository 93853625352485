import { createMachine } from 'xstate';

const machine =
  /** @xstate-layout N4IgpgJg5mDOIC5QFVZgE4AUCuAjANgJYDGASmFIbAC7oCG1hA9gHYB02a6AYk+gLYBiAHIBRABoAVRKAAOTWIUasZIAB6IAjABYAHGwBMAVgBsukye1GjABgDsuzQYA0IAJ5bNATjZGvf3V1tL20TOxt-AF9I11QMHAISckoaemV2AGslXgFBTFJRADVVeUV01Q0EHX1jMwsrWwcnVw8EO01fLxMbG10AZh6m42jYrgSiMgoqWgZmTOy+ITEpEoUlOYrEPr6fTXCvO1s+0J7NFq0DAzYIgKCQsJuRkDisPAnk6bS5thowWT68gVikgQKV1ioQZVtrt9ocbMdujYzu5EHZtNcuj1+oNHMMniwmBA4KoXuMklNUrNWBwuDl+KsyhtIYhtC4UW0fDs7H0DLoutpNDy+iYnqS3uSUjN0mwstQ6QzwSxNggDALDKZdEYdF4Qto+v1zghHGw+dzefzBQZhaKxuLJpKvtTfv8FeVmQg9Vc9hFEQ4ukZdIagmwuTy+ZZLdaYs9bYl7Z8qUqQWC3aBKppA+yALQdbQ2PM2IzaVlWvMWaLRIA */
  createMachine({
    id: 'editRoleWizard',
    initial: 'initialData',
    states: {
      initialData: {
        invoke: {
          src: 'initialData',
          id: 'initialData',
          onDone: [
            {
              target: 'pincasData',
              actions: ['setContextAfterInvoke']
            }
          ]
        }
      },
      pincasData: {
        invoke: {
          src: "pincasData",
          id: "pincasData",
          onDone: [
            {
              target: "editRoleView",
              actions: ["setContextAfterInvoke"],
            },
          ],
        },
      },
      editRoleView: {
        on: {
          NEXT: {
            target: 'upsertRole',
            actions: ['setContextAfterStep']
          }
        }
      },
      upsertRole: {
        invoke: {
          src: 'upsertComponentStakeholderRole',
          id: 'upsertComponentStakeholderRole',
          onDone: [
            {
              target: 'finish',
              actions: ['setContextAfterInvoke']
            }
          ]
        }
      },
      error: {
        on: {
          PREV: {
            target: 'editRoleView'
          }
        }
      },
      finish: {
        entry: 'stopMachine',
        type: 'final'
      }
    }
  });

export default machine;
import { useMemo } from 'react';
import useSession from "src/modules/session/hooks/useSession";
import useUpsertRenewalData from "src/views/modules/startups/renewal/adgm/modals/ReviewAndConfirmCapTableModal/wizard/graphql/useUpsertRenewalData";
import useGetShareCapitalView from "src/views/modules/startups/renewal/adgm/modals/ReviewAndConfirmCapTableModal/wizard/graphql/useGetShareCapitalView";

const useServices = ({ initialValues }) => {

  const submitRenewal = useUpsertRenewalData();
  const { groupCompanyId } = initialValues;
  const { startupId } = useSession();
  const { manualQuery: getCapTableData } = useGetShareCapitalView({ groupCompanyId, startupId }, {});

  return useMemo(() => ({

    initialData: async (context, event) => {
      const allCapTableData = await getCapTableData();
      return {...initialValues, ...allCapTableData?.data?.getShareCapitalView};
    },

    SaveStep: async (context, event) => {
      if (context?.ReviewAndConfirmCapTable?.confirm) {
        const dataRenewal = await submitRenewal(context?.initialData?.groupCompanyId);
        return dataRenewal;
      }
    },

  }), [initialValues]);
}

export default useServices;

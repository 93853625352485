import { FieldArray } from 'formik';
import _ from "lodash";
import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';
import useMutation from "../../../../hooks/custom/useMutation";
import ControllerInput from '../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useSession from '../../../../modules/session/hooks/useSession';
import { RoleFields } from "../../../../modules/stakeholder/graphql/typePolicies";
import classes from "../../../../v1/containers/Forms/SteperForm.module.scss";
import { Constants } from '../../../../v1/utils';
import RowAdd from '../../../forms/contents/RowAdd/RowAdd';
import Form from '../../../forms/Form/Form';
import IconSecondaryButtonContent from "../../../generic/Buttons/IconSecondaryButtonContent/IconSecondaryButtonContent";
import SubmitButtonSubForm from '../../../generic/Buttons/SubmitButtonSubForm/SubmitButtonSubForm';
import SelectAddInput from "../../Select/SelectAddInput/SelectAddInput";
import TextInput from "../../Text/TextInput";
import VerifyDetails from "../../VerifyDetails/VerifyDetails";
import useGetGroupCompany from './graphql/useGetGroupCompany';
import useGetStakeholders from './graphql/useGetStakeholders';
import Classes from './styles.module.scss';

const SelectFounder = ({
	label,
	onChange,
	list,
	tooltips,
	fieldArrayName,
	className,
	groupCompany,
	handleAddListStakeholder,
	handleOnRemoveStakeholder,
	onBlur,
	modal = false ,
	errorMessage}) => {

	const [showAddStakeholder, setAddStakeholder] = useState(false);
    const [showSelect, setShowSelect] = useState(false);
    const [groupCompanyId, setGroupCompanyId] = useState(_.get(groupCompany, 'id'));
    
	const { startupId } = useSession();

	const {
		data: dataGroupCompany,
		loading: loadingGroupCompany,
		refetch: refetchGroupCompany
	} = useGetGroupCompany(
		{ startupId, groupCompanyId },
		{ skip: _.isEmpty(groupCompanyId), showSuccessNotification: false, showErrorNotification: false }
	);

	const {
		data: dataStakeholders,
		loading: loadingStakeholders,
		refetch: refetchStakeholders
	} = useGetStakeholders(
		{ startupId, groupCompanyId },
		{ showSuccessNotification: false, showErrorNotification: false }
	);

	const dataCompany = useMemo(() => {
    	return _.get(dataGroupCompany, 'getGroupCompany') || groupCompany
	}, [JSON.stringify(dataGroupCompany)]);

	const dataStakeholderList = useMemo(() => {
		return _.get(dataStakeholders, 'stakeholderList') || list
	}, [JSON.stringify(dataStakeholders)]);

	const listStakeholders = useMemo(() =>
		_.map(dataStakeholderList, as => ({ value: as, label: as.fullName, type: 'stakeholder' })), [JSON.stringify(dataStakeholderList)]);

	return (
        <FieldArray
            name={fieldArrayName}
            render={(arrayHelpers) => {
                const addList = _.isEmpty(_.get(arrayHelpers.form.values, fieldArrayName)) ? [
                    {
                        label: "Add all founders",
                        onClick: (value) => {
                            setShowSelect(false);
                            _.forEach(listStakeholders, st => {
								if (st.value.isFounder) onChange(st.value, arrayHelpers, groupCompany)
                            })
                        },
                        type: 'stakeholder',
                    },
                    {
                        label: "Add new founder",
                        onClick: (value) => {
                            setAddStakeholder(true)
                        },
                        type: 'stakeholder',

                    },
                ] :  [ {
                        label: "Add new founder",
                        onClick: (value) => {
                            setAddStakeholder(true)
                        },
                        type: 'stakeholder',

                    },
                    ];
                return (<>
                      <div className={Classes.containerForm}>
                           <AddFounder
                               addList={addList}
                               modal={modal}
                               listStakeholders={listStakeholders}
                               onBlur={onBlur}
                               onChange={onChange}
                               label={label}
                               groupCompany={dataCompany}
                               arrayHelpers={arrayHelpers}
                               fieldArrayName={fieldArrayName}
                               errorMessage={errorMessage}
                           />
                        </div>
                    {_.get(arrayHelpers.form.values, `${fieldArrayName}`,[]).map((founder, index) => {
                        const isLast = _.get(arrayHelpers.form.values, `${fieldArrayName}`,[]).length === index + 1;

                        const handleDeleteElement = () => {
                        	const removedElement = arrayHelpers.remove(index);
                        	if (handleOnRemoveStakeholder) {
								handleOnRemoveStakeholder(removedElement);
							}

							const directorsFounders = _.get(arrayHelpers, 'form.values.terms.directors.founders', []);
							directorsFounders.splice(index, 1);

                        	return removedElement;
						};

                        const handleSubmit = async (values, type, updated, initialValues) => {
                            if (type ==='stakeholderDetails') {
                                arrayHelpers.form.setFieldValue(`${fieldArrayName}[${index}]`,{...values.stakeholder});
                                arrayHelpers.form.setFieldValue(`terms.directors.founders[${index}].id`, founder.id);
                                arrayHelpers.form.setFieldValue(`terms.directors.founders[${index}].amount`,_.get(initialValues,`terms.directors.founders[${index}].amount`,''));
                                arrayHelpers.form.setFieldValue(`${fieldArrayName}[${index}].verified1`,true)
                            } else {
								arrayHelpers.form.setFieldValue(`${fieldArrayName}[${index}].position`, {...values.stakeholder.position});
								arrayHelpers.form.setFieldValue(`${fieldArrayName}[${index}].verified2`, true)
							}

                            if (updated) {
                            	refetchStakeholders();
                            	if (groupCompanyId) {
									refetchGroupCompany()
								}
							}
                        };

                        return (
                            <div key={index}>
                                <VerifyFounderDetails
                                    handleDeleteElement={handleDeleteElement}
                                    index={index}
                                    groupCompanyId={groupCompanyId}
                                    groupCompany={dataCompany}
                                    fieldArrayName={fieldArrayName}
                                    showAddAnother={isLast}
                                    handleSubmit={(values, type, updated) => handleSubmit(values, type, updated, arrayHelpers.form.values)}
                                    tooltips={tooltips}
                                    modal={modal}
                                    founder={_.get(arrayHelpers.form.values, `${fieldArrayName}`,[])[index]}
                                />
                                {!isLast && <hr className={classes.Separator} />}
                            </div>
                        )
                    })}
                    <>
                        {
                            showAddStakeholder ? (
                                <AddNewFounder
                                    handleUpdate={(value) => {
                                        arrayHelpers.push({ id: value.id, email: value.email, fullName: value.fullName, equity: [{}] });
                                        if (handleAddListStakeholder)
                                            handleAddListStakeholder(value);
                                    }}
                                    closeForm={() => setAddStakeholder(false)}
                                    idGroupCompany={dataCompany.id}
									refetch={refetchStakeholders}
                                />) : null
                        }
                    </>
                </>)

            }}
        />
	)
};

const AddFounder = ({label, addList, modal, arrayHelpers, fieldArrayName, onBlur, listStakeholders, onChange, errorMessage, groupCompany}) => {
    useEffect(() => {
        if (groupCompany) {
            if(_.isEmpty(_.get(arrayHelpers.form.values,`${fieldArrayName}`,[]))) {
                _.forEach(listStakeholders, st => {
					if (st.value.isFounder) onChange(st.value, arrayHelpers, groupCompany)
                    // if (_.some(st.value.roles, role => role.name === 'FOUNDER' && role.groupCompany.id===groupCompany.id)) onChange(st.value, arrayHelpers, groupCompany)
                })
            }
        }
    },[JSON.stringify(groupCompany)]);

    const ref = useRef();

    return(<>
        <RowAdd
            label={label}
            asterisk={true}
            classNameInput={modal ? Classes.inputModal : Classes.input}
            input={
                <SelectAddInput
                    value={null}
                    list={listStakeholders}
                    mode={'add'}
                    refSelect={ref}
                    handleFilterList={(list) => {
                        return _.filter(list, st => !_.some(_.get(arrayHelpers.form.values, `${fieldArrayName}`), sign => sign.id === st.value.id))
                    }}
                    onBlur = {onBlur}
                    placeholder={"Select from the list"}
                    onChange={(value) => {
                        if (value) {
                            onChange(value,arrayHelpers,groupCompany);
                            ref.current.clearValue()
                        }
                    }}
                    menuProps={{
                        addList: addList,
                        propsList: [{ type: 'stakeholder' }]
                    }}
                    errorMessage={_.get(arrayHelpers.form.errors, "founders") && !_.some(_.get(arrayHelpers.form.errors,"founders",[]),founder=>{
                        return _.get(founder,'verified1',null) || _.get(founder,'verified2',null)
                    }) && _.size(_.get(arrayHelpers.form.values,'founders',[]))<2?errorMessage:null}
                />
            }
        />
</>)
};

const VerifyFounderDetails = ({
	groupCompanyId,
	fieldArrayName,
	index,
	groupCompany,
	handleDeleteElement,
	handleSubmit,
	tooltips,
	founder }) => {

    return (
    	<div className={Classes.containerForm}>
        	<div style={{display:'flex','flex-direction':'row'}}>
        		<h1>{founder.fullName} </h1>
				<div className={Classes.deleteButton} onClick={() => handleDeleteElement(index)}>
					<IconSecondaryButtonContent color={'primary'} icon={'Delete-bin'} />
				</div>
    		</div>
        <VerifyDetails
            groupCompany={groupCompany}
            groupCompanyId={groupCompanyId}
            handleSubmit={handleSubmit}
            initialValues={{stakeholder: {...founder,}}}
            title={'Founder'}
            tooltips={tooltips}
            type={'stakeholder'}
            fieldName={`${fieldArrayName}[${index}]`}
		/>
        </div>
	)
};

const mutation = `
    ${RoleFields}
	mutation upsertComponentStakeholder(
		$startupId: ID!
		$stakeholder: ComponentStakeholderInputType!
	  ) {
		upsertComponentStakeholder(startupId: $startupId, stakeholder: $stakeholder) {
			id
			fullName
			email
			isFounder
			roles { ...RoleFields }
		}
	  }`;

const AddNewFounder = ({ handleUpdate, tooltip, idGroupCompany, closeForm, refetch }) => {
    
    const { startupId } = useSession();
    const [data, setData] = useState(null);
    const handleData = (data) => {
        handleUpdate(data.upsertComponentStakeholder);
        setData(data)
    };
    const [mutationCall, loading] = useMutation(mutation, { onCompleted: handleData, showSuccessNotification: false });

    const handleSubmit = (values) => {
        const variables = {
            startupId,
            stakeholder: {
                ...values,
                "isAnEntity": false,
				"isFounder": true
            }
        };

        mutationCall(variables)
    };

    useEffect(() => {
        if (loading === false && data) {
            closeForm();
			if (refetch) {
				refetch();
			}
        }
    }, [loading, data]);

    return (
        <Form initialValues={{ data: 'asdasdasd' }}
              schema={Yup.object().shape({
                  fullName: Yup.string().required("Name is a required field"),
                  email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, { message: "This email is invalid", excludeEmptyString: true }).required("Email Address is a required field"),
              })}
              loading={loading}
              onSubmit={handleSubmit}
        >
            <div className={Classes.containerForm}>
                <RowAdd
                    label={"Name "}
                    asterisk={true}
                    classNameInput={Classes.input}
                    input={
                        <ControllerInput
                            as={TextInput}
                            name={'fullName'}
                            classNameError={Classes.errorForm}
                            placeholder={"Name"}
                        />
                    }
                />

                <RowAdd
                    label={"Email Address "}
                    asterisk={true}
                    classNameInput={Classes.input}
                    input={
                        <ControllerInput
                            as={TextInput}
                            name={'email'}
                            classNameError={Classes.errorForm}
                            placeholder={"Email address"}
                        />
                    }
                />

                <div className={Classes.containerButtons}>

                    <button className={`genericWhite`} style={{ marginRight: '1rem' }} onClick={closeForm}>
                        Cancel
                    </button>
                    <SubmitButtonSubForm onClick={handleSubmit}>
                        Save
                    </SubmitButtonSubForm>
                </div>
            </div>
        </Form>
    )

};

export default SelectFounder;

import React, { useEffect, useState } from "react";
import * as Yup from 'yup'
import Form from "../../../../components/forms/Form/Form";
import useTranslate from "../../../../modules/generic/hooks/useTranslate";
import SubmitButton from "../../../../modules/generic/components/Buttons/SubmitButton";
import FormProps from "../../../../modules/generic/forms/interfaces/FormProps";
import TemplateFormDefault from "../../../../modules/generic/templates/Modal";
import Typography from "../../../../modules/generic/components/Texts/Typography";
import CheckboxButtonSimple, { CheckboxButtonOptions } from '../../../../modules/generic/components/Inputs/CheckboxButtonSimple';
import ControllerInput from '../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import cls from "./FormYourSubscription.module.scss";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { addAssistantText, clearAssistatText } from "../../../../modules/generic/store/action";

const schema = Yup.object().shape({
  renewal:  Yup.bool(),
  billing:  Yup.bool(),
})

interface FormYourSubscriptionProps extends FormProps {
  defaultData?: any;
}

const FormYourSubscription: React.FC<FormYourSubscriptionProps> = ({
  initialValues = {},
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
  defaultData = {},
}) => {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const [renewalState, setRenewalState] = useState(_.get(defaultData, 'getSubscriptionData.renewal.status'));
  const billingState = _.get(defaultData, 'getSubscriptionData.billing.status');

  const handleSubmit = async (values) => {
    onCompleteSubmit && (await onCompleteSubmit(values));
  };

  const handleTooltips = () => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText(translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_1.TOOLTIP1')));
    dispatch(addAssistantText(translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_1.TOOLTIP2')));
  }

  useEffect(() => {
    handleTooltips();
    return () => {
      dispatch(clearAssistatText());
    }
  }, [renewalState, billingState]);

  const optionsRenewal: Array<CheckboxButtonOptions<boolean, any>> = [
    {
      value: true,
      component: ButtonOptions,
      componentProps: {
        title: translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_1.STEP_ON'),
        text: translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_1.RENEWAL_ON', {
          date: _.get(defaultData, 'getSubscriptionData.renewal.date', "") 
        }),
      },
    },
    {
      value: false,
      component: ButtonOptions,
      componentProps: {
        title: translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_1.STEP_OFF'),
        text: translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_1.RENEWAL_OFF', {
          date: _.get(defaultData, 'getSubscriptionData.renewal.date', "")
        }),
      },
    },
  ];

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
      onlySendDirty={false}
    >
      <Template
        buttons={{
          ...buttons,
          submit: <SubmitButton {...buttonSubmitProps}>{translate('TEXT_NEXT')}</SubmitButton>,
        }}
        props={propsTemplate}
      >
        <Typography variant="h5">
          {translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_1.HELP')}
        </Typography>
        <Typography variant="h4" weight="semibold">
          {translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_1.TITLE_RENEWAL')}
        </Typography>
        <ControllerInput
          render={CheckboxButtonSimple}
          name="renewal"
          variant="col_1"
          size="size2"
          options={optionsRenewal}
          onChange={value => {
            setRenewalState(value);
          }}
          classNameButton={cls.buttonCheckBox}
        />
      </Template>
    </Form>
  );
};

export default FormYourSubscription;

const ButtonOptions = ({ title, text }) => {
  return (
    <div className={cls.buttonContainer}>
      <Typography weight="semibold" variant="h4" className="mb-2">
        {title}
      </Typography>
      <Typography variant="body">
        {text}
      </Typography>
    </div>
  );
};
import { useDispatch } from "react-redux";
import { goToPage, goToPrevPage } from "../../../store/actions/page";
import { useParams } from "react-router-dom";
import { useCallback, useMemo } from "react";

export default function useNavigation() {
  const dispatch = useDispatch();
  const params = useParams();

  const goTo = useCallback((page, params) => {
    goToPage(dispatch, page, params);
  },[]);

  const goToMF = useCallback((page, params) => {
    goToPage(dispatch, { path: page }, params);
  },[]);

  const goToPrev = useCallback(() => {
    goToPrevPage(dispatch);
  },[]);

  const values= useMemo(() => {
    return { goTo, goToMF, goToPrev, params };
  },[goTo, goToMF, goToPrev, params]);

  return values;
}

import React, {Component} from 'react';
import classes from "./AddInput.module.scss";


class AddInput extends Component {

    handleOnClick = () =>{
        if(this.props.arrayHelpers){
            return this.props.onClickAdd(this.props.arrayHelpers);

        }
        return this.props.onClickAdd();
    }
    render() {
        return(
            <div onClick={this.handleOnClick} className={classes.AddButton}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18Z" stroke="#2C74FF" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12 9.37549V14.6247" stroke="#2C74FF" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M14.6246 12.0001H9.37539" stroke="#2C74FF" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <p>{this.props.label}</p>
            </div>
        );
    }
}

export default AddInput;

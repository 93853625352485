import TemplateWizardSkeletonMachine from "src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine";
import { ConfirmShareholdersDirectorsStep,IsDefaultValuesStep,IsUploadArticlesOfAsocciationStep,SetShareCapitalValuesStep,ShareholdersShareStep, UploadArticlesOfAsocciationStep} from "../Steps";

const useSteps = () => {
  return {
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    ShareholdersAndDirectorsConfirmForm: {
      render: ConfirmShareholdersDirectorsStep,
      stepperPosition: 0
    },
    AddArticlesOfAssosiationForm: {
      render: IsUploadArticlesOfAsocciationStep,
      stepperPosition: 1
    },
    UploadArticlesOfAssociationForm: {
      render: UploadArticlesOfAsocciationStep,
      stepperPosition: 1
    },
    UseDefaultValuesForm: {
      render: IsDefaultValuesStep,
      stepperPosition: 1
    },
    CreateShareCapitalForm:{
      render: SetShareCapitalValuesStep,
    },
    SharesToStakeholdersForm:{
      render: ShareholdersShareStep
    },
    SaveStep: {
      render: TemplateWizardSkeletonMachine,
    },
  };
}

export default useSteps;
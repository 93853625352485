import React from "react";
import MicroFrontend from "src/mf";
import useNavigation from "src/modules/generic/hooks/useNavigation";
import ModalCategoriseDocument from "src/components/modules/clara/startups/Document/Modals/CategoriseDocument";
import useModal from "src/modules/generic/hooks/useModal";

const DocumentView = () => {
  const { goToMF } = useNavigation();
  const { openModal } = useModal();

  const categorizeFile = (document, callback) => {
    openModal(
      ModalCategoriseDocument,
      {
        isCategorize: true,
        isEdit: false,
        isVisaOrPassportDocument: false,
        callback,
        initialValues: {
          document: { ...document, draft: true },
        },
      },
      "MODAL_CATEGORISE_DOCUMENT"
    );
  };

  return (
    <>
      <MicroFrontend
        id="MF_GenerateDocument_"
        component={"./DataRoom"}
        mf="documents"
        params={{
          go: goToMF,
          openFile: categorizeFile,
        }}
      />
    </>
  );
};

export default DocumentView;

import { yupResolver } from '@hookform/resolvers/yup';
import _ from "lodash";

window.formInitValue = null;
const getInitialValues = (initialValues, key) => {
  if (process.env && process.env.NODE_ENV === "development" && window.formInitValue) {
    let jsonMock = {};
    if (typeof window.formInitValue == "object") {
      jsonMock = window.formInitValue;
    } else {
      jsonMock = require("./mocks/" + window.formInitValue + ".json");
    }
    if (!jsonMock) {
      jsonMock = {};
    }
    return _.merge(jsonMock, initialValues);
  }
  return initialValues;
}

const getDefaultConfigForm = (initialValues, schema, options = {},context) => {
  return {
    defaultValues: {
      ...initialValues
    },
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    criteriaMode: "firstErrorDetected",
    reValidateMode: 'onChange',
    shouldUnregister: true,
    shouldFocusError: true,
    context:{...context},
    ...options
  }

}
export default {
  getDefaultConfigForm
}
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../../generic/components/Buttons/Button';
import { StepProps } from "../../../../generic/components/Wizard/Step";
import TemplateWizard from '../../../../generic/templates/Modal/TemplateWizard';
import useTranslate from '../../../../generic/hooks/useTranslate';
import { addAssistantText, clearAssistatText } from "../../../../generic/store/action";
import FormShareCapitalValues from "../../Forms/FormShareCapitalValues";
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useJurisdictionNameByJurisdictionType from '../hooks/useGetJurisdictionNameByJurisdictionType';

const SetShareCapitalValuesStep: FC<StepProps> = () => {
  const { translate } = useTranslate();
  const { next, prev, send, context } = useMachine();
  const dispatch = useDispatch();
  const { jurisdictionType } = context?.initialData;
  const jurisdictionName = useJurisdictionNameByJurisdictionType(jurisdictionType);
  const currency = context?.currency;

  useEffect(() => {
    dispatch(clearAssistatText());
    if(jurisdictionType === 'CAYMAN') dispatch(addAssistantText('The Cayman government imposes different fees depending on the amount of a company’s authorised share capital. Where a company’s authorised share capital is greater than <b>USD 50,000</b>, Cayman imposes an increased government fee for formation and subsequent renewals. Click <a href="https://help.clara.co/en/articles/9210833-increased-costs-of-authorising-a-total-share-capital-of-over-50-000" target="_blank" >here</a> for more details.','alert')) 
    dispatch(addAssistantText(translate('MODULES.GROUP_COMPANY.SHARE_CAPITAL_ADGM_CAYMAN.AUTHORISE_SHARES_STEP.TOOLTIP', { jurisdictionName, currency })));
  }, []);

  const handleCompleteSubmit = async (values) => {
    next(values);
  }

  const handleCancel = () => {
    send("CANCEL");
  }

  const handlePrev = () => {
    prev();
  }

  return (
    <>
      <FormShareCapitalValues
        currency={currency}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: translate('MODULES.GROUP_COMPANY.SHARE_CAPITAL_ADGM_CAYMAN.OWN_AMOUNT_STEP.TITLE'),
          subTitle: translate('MODULES.GROUP_COMPANY.SHARE_CAPITAL_ADGM_CAYMAN.GENERIC.SUBTITLE')
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button>,
          previous: <Button onClick={handlePrev} variant="secondary">Previous</Button>
        }}
      />
    </>
  );
}

export default SetShareCapitalValuesStep;

import React from "react";
import TypographyLocal from "../../../../../generic/components/Texts/Typography";
import cls from "./classes.module.scss";
const RightContentBlock = ({ children, title, isRequired }) => {
  return (
    <div className={cls.container}>
      <TypographyLocal color="dark-grey" variant="body">
        {title}
        {isRequired ? <span style={{ color: "red" }}> *</span> : null}
      </TypographyLocal>
      <div className={cls.containerIlustration}>{children}</div>
    </div>
  );
};

export default RightContentBlock;

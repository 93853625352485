import classnames from "classnames";
import React, { FC, useEffect, useState } from "react";
import ReactAvatar from "react-avatar";
import Icon from "../../../../../modules/generic/components/Icon";
import Typography from "../../../../../modules/generic/components/Texts/Typography";
import classes from './classes.module.scss';

/**
 * Clara Avatar
 */

export interface ClaraAvatarProps {
  /** Property avatar */
  avatar?: string,
  /** Property name */
  name?: string,
  /** Property variant */
  variant?: "big" | "xl" | "medium" | "small" | "tiny" | "",
  /** Property type */
  type?: "circle" | "square" | "wait" | "dashboard" | "formCircle" | "formCircleSmall",
  /** Property locked */
  locked?: boolean,
  /** Property add */
  add?: boolean,
  /** Property addClick */
  addClick?: any
  /** Property asClara */
  asClara?: boolean,
  /** Property className */
  className?: any,
  /** Property size */
  size?: any,
} 

const ClaraAvatar: FC<ClaraAvatarProps> = ({
  avatar,
  name,
  variant = "medium",
  type = "circle",
  locked = false,
  add = false,
  addClick,
  asClara,
  className,
  size,
  ...props
}:ClaraAvatarProps) => {

  const [internalSize, setInternalSize] = useState('3.3rem');
  const [text, setText] = useState("");
  const [initials, setInitials] = useState("");

  const calculateInitials = (name) => {
    let newInitials = name.split(" ");
    if (newInitials.length === 1) {
      return newInitials[0].slice(0,1).toUpperCase();
    } else if (newInitials.length === 2) {
      return newInitials[0].slice(0,1).toUpperCase() + newInitials[1].slice(0,1).toUpperCase();
    } else if (newInitials.length <= 3) {
      return newInitials[0].slice(0,1).toUpperCase() + newInitials[newInitials.length - 1].slice(0,1).toUpperCase();
    }
  }

  useEffect(() => {
    if (name) {
      let newText = name.replace(/[^a-zA-Z ]/g, "");
      newText = newText.trim().split(" ").slice(0, 3).join(" ");
      setText(newText);
      setInitials(calculateInitials(newText));
    } else {
      setText("");
    }
  }, [name]);

  useEffect(() => {
    let finalSize = internalSize;
    if (variant === 'big') {
      finalSize = '7.75rem';
    }
    if (variant === 'xl') {
      finalSize = '4.3rem';
    }
    if (variant === 'medium') {
      finalSize = '3.3rem';
    }
    if (variant === 'small') {
      finalSize = '2.4rem';
    }
    if (variant === 'tiny') {
      finalSize = '24px';
    }
    setInternalSize(finalSize);
  }, [variant]);

  const handleTextSize = (size) => {
    let result = classes.claraAvatar
    if (size === '7.75rem') {
      result = classes.bigAvatarText;
    }
    if (size === '2.4rem') {
      result = classes.smallAvatarText;
    }
    if (size === '24px') {
      result = classes.tinyAvatarText;
    }
    return result;
  }

  return (
    <>
      {type === "wait" && (
        <div className={classnames(`${classes.AvatarWait} ${className}`)} >
          {locked && (
            <>
              <div className={classnames(`${classes.lockBadge}`)}>
                <Icon isClara={true} icon={"Lock"} size={"0.9231rem"} className={classes.iconColor}/>
              </div>
            </>
          )}
          <div className={classes.columData}>
            <Icon isClara={true} icon={"Clock"} size={"1.8462rem"} className={classes.iconBlue}/>
            <Typography
              variant={"s"}
              weight={"semibold"}
              color={'blue'}
              className={classes.spaceTop}
            >
              {initials}
            </Typography>
          </div>
        </div>
      )}
      {type === "dashboard" && (
        <div className={classnames(`${classes.AvatarDash} ${className}`)} >
          {locked && (
            <>
              <div className={classnames(`${classes.lockBadge}`)}>
                <Icon isClara={true} icon={"Lock"} size={"0.9231rem"} className={classes.iconColor}/>
              </div>
            </>
          )}
          <div className={classes.columData}>
            <Icon isClara={true} icon={"Clock"} size={"15px"} className={classes.iconBlue}/>
            <Typography
              variant={"s"}
              weight={"semibold"}
              color={'blue'}
              className={classes.spaceTopDash}
            >
              {initials}
            </Typography>
          </div>
        </div>
      )}
      {type === "formCircleSmall" && (
        <div className={classnames(`${classes.AvatarDashCircle} ${className}`)} >
          {locked && (
            <>
              <div className={classnames(`${classes.lockBadge}`)}>
                <Icon isClara={true} icon={"Lock"} size={"0.9231rem"} className={classes.iconColor}/>
              </div>
            </>
          )}
          <div className={classes.columData}>
            <Icon isClara={true} icon={"Clock"} size={"14px"} className={classes.iconBlue}/>
            <Typography
              variant={"s"}
              weight={"semibold"}
              color={'blue'}
              className={classes.spaceTopDash}
            >
              {initials}
            </Typography>
          </div>
        </div>
      )}
      {type === "formCircle" && (
        <div className={classnames(`${classes.formCircle} ${className}`)} >
          {locked && (
            <>
              <div className={classnames(`${classes.lockBadge}`)}>
                <Icon isClara={true} icon={"Lock"} size={"0.9231rem"} className={classes.iconColor}/>
              </div>
            </>
          )}
          <div className={classes.columData}>
            <Icon isClara={true} icon={"Clock"} size={"15px"} className={classes.iconBlue}/>
            <Typography
              variant={"s"}
              weight={"semibold"}
              color={'blue'}
              className={classes.spaceTopDash}
            >
              {initials}
            </Typography>
          </div>
        </div>
      )}
      {(type !== "dashboard" && type !== "wait" && type !== "formCircle" && type !== "formCircleSmall") && (
        <>
          <div className={classnames(`${classes.avatarContainer} ${className}`)}>
            {locked && (
              <>
                {internalSize === '7.75rem' ? (
                  <div className={classnames(`${classes.lockBadgeBig}`)}>
                    <Icon isClara={true} icon={"Lock"} size={"1.3846rem"} className={classes.iconColor}/>
                  </div>
                ) :(
                  <div className={classnames(`${classes.lockBadge}`)}>
                    <Icon isClara={true} icon={"Lock"} size={"0.9231rem"} className={classes.iconColor}/>
                  </div>
                )}
              </>
            )}
            {add && (
              <>
                {internalSize === '7.75rem' ? (
                  <div className={classnames(`${classes.addBadgeBig}`)} onClick={addClick}>
                    <Icon isClara={true} icon={"Add"} size={"1.3846rem"} />
                  </div>
                ) :(
                  <div className={classnames(`${classes.addBadge}`)} onClick={addClick}>
                    <Icon isClara={true} icon={"Add"} size={"0.9231rem"} />
                  </div>
                )}
              </>
            )}
            {asClara && (
              <div className={classnames(`${classes.addClara}`)}>
                <Icon isClara={true} icon={""} size={"0.9231rem"} />
              </div>
            )}
            <ReactAvatar
              className={classnames(`${handleTextSize(internalSize)}`, {
                [classes.square]: type === "square",
              })}
              name={text}
              size={size ? size : internalSize}
              round={type === "circle" ? true : false}
              src={avatar}
              maxInitials={2}
            >
            </ReactAvatar>
          </div>
        </>
      )}
    </>
  );
};

export default ClaraAvatar;
import useCustomMachine, {GlobalStateContext} from "../../../../../../generic/hooks/useCustomMachine";
import React, {FC, useContext, useEffect, useMemo, useRef} from 'react'
import Button from '../../../../../../../modules/generic/components/Buttons/Button'
import {StepProps} from '../../../../../../../modules/generic/components/Wizard/Step'
import TemplateWizard from '../../../../../../../modules/generic/templates/Modal/TemplateWizard'
import useTranslate from '../../../../../../generic/hooks/useTranslate'
import IsThereEntity from "../../../forms/IsThereEntity";
import _ from "lodash";
import {Constants} from "../../../../../../../v1/utils";
import {useDispatch} from "react-redux";
import {clearAssistatText} from "../../../../../../generic/store/action";

type FirstStepSubmit = (values:any) => Promise<any>
interface FirstStepProps {
    role: string,
    entity: string
    tooltips?: object
    onCompleteSubmit?: FirstStepSubmit
}

const FirstStep: FC<FirstStepProps> = ({role, entity, tooltips, onCompleteSubmit}) => {
    const {translate} = useTranslate()
    const {next, send, state, stepData,prev} = useCustomMachine();
    const dispatch = useDispatch()

    const handleCancel = () => {
        send("CANCEL")
    }
    const handleCompleteSubmit = async (values: any) => {
        if (values.exist===false){
            onCompleteSubmit && await onCompleteSubmit(values)
        }
        
        next(values);
    }

    const roleLabel=useMemo(()=>{
        const text=_.words(translate(`MODULES.ONBOARDING_STEPS.ROLES_LABEL_PLURAL.${role}`))
        let label=''
        _.forEach(text,t=>label=`${label} ${_.lowerFirst(t)}`)
        return label
    },[role])

    return (
        <React.Fragment>
            <IsThereEntity
                tooltips={tooltips}
                entity={entity}
                initialValues={stepData}
                propsTemplate={{
                    title: translate('MODULES.ONBOARDING_FORMS.IS_THERE_ENTITY.IS_THERE_ENTITY', {role:roleLabel}),
                    subTitle: translate('MODULES.ONBOARDING_FORMS.IS_THERE_ENTITY.IS_ENTITY', {role: roleLabel})
                }}
                onCompleteSubmit={handleCompleteSubmit}
                Template={TemplateWizard}
                buttons={{cancel: <Button onClick={handleCancel}>Cancel</Button>}}/>
        </React.Fragment>
    )
}

export default FirstStep

import React, { FC } from "react";
import Skeleton from '@material-ui/lab/Skeleton';
import TemplateLabel, {TemplateLabelComponent} from "../../../../../../modules/generic/templates/Label/";

/**
 * Overlay Skeleton Input
 */

export interface TemplateSkeletonComponentProps<PropTypes = any> {
  Template?: TemplateLabelComponent,
  propsTemplate?: PropTypes,
  count?: number,
  noLabel?: boolean,
  specialWidth?: string
}

export type TemplateSkeletonComponent<PropsType = any> = FC<TemplateSkeletonComponentProps<PropsType>>

export interface SkeletonInputProps extends TemplateSkeletonComponentProps {

}

const SkeletonInput: TemplateSkeletonComponent = ({
  Template = TemplateLabel,
  propsTemplate,
  count = 1,
  noLabel = false,
  specialWidth = "100%",
}:SkeletonInputProps) => {

  return (
    <React.Fragment>
      {Array(count).fill(1).map((el, i) =>
        <TemplateLabel
          label={noLabel ? null : <Skeleton variant="text" height={"1.3846rem"} width={"60%"}/>}
          key={i}
        >
          <Skeleton variant="text" height={"3.0769rem"} width={specialWidth}/>
        </TemplateLabel>
      )}
    </React.Fragment>
  );
};

export default SkeletonInput;
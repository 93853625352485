import React, { FC, ReactNode } from 'react';
import classesModule from './classes.module.scss';
import _ from "lodash";

export interface TemplateFormButtonsProps  {
  submit?: React.ReactNode,
}

export interface TemplateFormComponentProps<PropsType = any, ButtonsType = any> {
  children: ReactNode,
  className?: string,
  classNameBotonera?: string,
  buttons: ButtonsType,
  props?: PropsType,
  loading?: boolean,
  loadingComponent?: ReactNode,
}

export type TemplateComponent<PropsType = any, ButtonsType=any> = FC<TemplateFormComponentProps<PropsType, ButtonsType>>

interface TemplateFormDefaultProps {
  className?: string
}

const Template: TemplateComponent<TemplateFormDefaultProps> = ({
  children,
  buttons = {},
  classNameBotonera,
  className,
}: TemplateFormComponentProps<TemplateFormDefaultProps, TemplateFormButtonsProps>)  => {
  return (
    <div className={`${classesModule.Template} ${className}`}>
      <div className={`${classesModule.Form}`}>
        {children}
      </div>
      <div className={`${classesModule.Botonera} ${classNameBotonera}`}>
        {_.map(buttons, button => {
          return <React.Fragment>{button}</React.Fragment>
        })}
      </div>
    </div>
  );
}

export default Template

import PAGES from "../../../../constants/pages";
import BackAndResumeView from "../../../../components/modules/incorporation/BackAndResume/BackAndResume";
import FLOWS from "../../../../constants/flows";
import ClaraLayout from "../../../../layouts/Clara/ClaraLayout";
import {Constants} from "../../../../v1/utils";
import ACTIONS from "../../../../constants/actions";
import React from "react";

const moduleRouter = [
    {
        path: PAGES.incorporationMatter.path,
        exact: true,
        component: <BackAndResumeView flowName={FLOWS.SCOPING.NAME} pageAfterFinished={Constants.PAGES.scopingSubmitted} enabledShowPending={false}/>,
        propsLayout: {},
        action:  ACTIONS.COMPLETE_MATTER_FLOW,
        layout: ClaraLayout
    },
    {
        path: Constants.PAGES.addMatter.path,
        exact: true,
        component: <BackAndResumeView flowName={FLOWS.ADD_MATTER.NAME}  />,
        propsLayout: {},
        action:  ACTIONS.COMPLETE_MATTER_FLOW,
        layout: ClaraLayout
    },
    {
        path: Constants.PAGES.answerTheQuestionnaire.path,
        exact: true,
        component: <BackAndResumeView enabledShowPending={false} flowName={FLOWS.ANSWER_THE_QUESTIONNAIRE.NAME}  pageAfterFinished={Constants.PAGES.questionnaireSubmitted}   />,
        propsLayout: {},
        action:  ACTIONS.COMPLETE_MATTER_FLOW,
        layout: ClaraLayout
    },
    {
        path: Constants.PAGES.ADGMFormationDocumentReview.path,
        exact: true,
        component: <BackAndResumeView flowName={FLOWS.ADGM_FORMATION_DOCUMENT_REVIEW.NAME} />,
        propsLayout: {},
        action:  ACTIONS.COMPLETE_MATTER_FLOW,
        layout: ClaraLayout
    },
  
    {
        path: Constants.PAGES.addKYCToAMatter.path,
        exact: true,
        component: <BackAndResumeView flowName={FLOWS.ADD_KYC_TO_MATTER.NAME} pageAfterFinished={Constants.PAGES.kycSubmitted} enabledShowPending={false}/>,
        propsLayout: {},
        action:  ACTIONS.COMPLETE_MATTER_FLOW,
        layout: ClaraLayout
    },

    
    
]

export default moduleRouter;


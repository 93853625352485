import React from "react";
import FileUploaderInput from "src/components/inputs/FileUploader/FileUploaderInput";
import * as Yup from "yup";
import Form from "../../../../components/forms/Form/Form";
import SubmitButton from "../../../generic/components/Buttons/SubmitButton";
import ControllerInput from "../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import FormProps from "../../../generic/forms/interfaces/FormProps";
import useTranslate from "../../../generic/hooks/useTranslate";
import TemplateLabel from "../../../generic/templates/Label";
import TemplateFormDefault from "../../../generic/templates/Modal";

const schema = Yup.object().shape({
  file: Yup.object().nullable().required("This field is required")
});

const FormUploadArticlesOfAssociation: React.FC<FormProps> = ({
  initialValues = {},
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
}: FormProps) => {
  const refForm = React.useRef(null);


  const handleSubmit = async (values) => {
    onCompleteSubmit && (await onCompleteSubmit(values));
  };

  return (
    <Form
      defaultValues={initialValues || {}}
      schema={schema}
      onSubmit={handleSubmit}
      ref={refForm}
      optionsForm={{ mode: "onChange" }}
    >
      <Template
        buttons={{
          ...buttons,
          submit: <SubmitButton {...buttonSubmitProps}>Next</SubmitButton>,
        }}
        props={propsTemplate}
      >
          <TemplateLabel
            label={"Upload document"}
            isRequired
          >
            <ControllerInput
              render={FileUploaderInput}
              name="file"
              typeFileAllowed={
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              }
              icons={["DOC"]}
            />
          </TemplateLabel>
      </Template>
    </Form>
  );
};

export default FormUploadArticlesOfAssociation;

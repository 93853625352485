import { useMemo } from "react";
import TemplateWizardSkeletonMachine from "../../../generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine";


import UploadDocumentStep from "../steps/UploadDocumentStep";
import HaveDocumentStep from "../steps/HaveDocumentStep";

const useSteps = () => {

  return useMemo(() => ({
    getCompaniesData: {
      render: TemplateWizardSkeletonMachine,
      renderProps:{
        title: "",
        subTitle: "",
      },
    },
    HaveDocumentStep: {
      render: HaveDocumentStep,
    },
    UploadDocumentStep: {
      render: UploadDocumentStep,
    },
    
    
    GetFounderStep: {
      render: TemplateWizardSkeletonMachine,
      renderProps: {
        title: "",
        subTitle: ""
      }
    },
    UpsertDocumentStep: {
      render: TemplateWizardSkeletonMachine,
      renderProps: {
        title: "",
        subTitle: ""
      }
    },
  }), []);
}

export default useSteps;
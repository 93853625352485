import React, { useEffect, useState } from "react";

import Button from "../../../../../generic/components/Buttons/Button";
import EditRoleForm from "./EditRoleForm";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import _ from "lodash";
import { clearAssistatText } from "../../../../../generic/store/action";
import { useDispatch } from "react-redux";
import useMachine from '../../../../../generic/context/MachineContext/useMachine';
import useModal from "../../../../../generic/hooks/useModal";
import useTranslate from "../../../../../generic/hooks/useTranslate";

function EditRoleView({ openParam }) {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const { closeModal: onClose } = useModal();
  const [open, setOpen] = useState(openParam);
  const { context, next } = useMachine();
  const data = {
    ...context.initialData,
    pincas: context?.pincasData?.pincasData
  }

  const handleCancel = (values) => {
    onClose();
    setOpen(false);
    clearAssistatText();
  }

  useEffect(() => {
    setOpen(openParam);
  }, [openParam]);

  const handleInitModal = () => {
    dispatch(clearAssistatText());
  }

  useEffect(() => {
    handleInitModal();
  }, []);

  const handleCompleteSubmit = (values) => {
    next(values);
  }

  return (
    <EditRoleForm
      initialValues={data}
      buttons={{
        cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
      }}
      onCompleteSubmit={handleCompleteSubmit}
      propsTemplate={{
        subTitle: _.get(context, 'initialData.entity.name') || _.get(context, 'initialData.entity.fullName'),
        title: `${translate('MODULES.STAKEHOLDER.EDIT_ROLE.TITLE', { role: context.initialData.label })}`
      }}
      Template={TemplateWizard}
    />
  );
}

export default EditRoleView;

import useUpsertPurposeOfEntity from "../graphql/useUpsertPurposeOfEntity";
import useSession from "src/modules/session/hooks/useSession";

const useServices = ({ initialValues }) => {
  const { mutation: upsertPurpose } = useUpsertPurposeOfEntity();
  const { startupId } = useSession();
  const groupCompanyId = initialValues?.id;

  const initialData = async () => {
    return { ...initialValues };
  };

  const SaveStep = async (context) => {
    const { PurposeOfEntityStep } = context;

    const variables = {
      startupId,
      groupCompanyId,
      groupCompany: {
        purposeOfEntity: {
          code: PurposeOfEntityStep?.purposeOfEntity,
          description: PurposeOfEntityStep?.purposeOfEntityDescription,
        },
      },
    };

    await upsertPurpose({ variables });
    return context;
  };

  return { initialData, SaveStep };
};

export default useServices;
import React, {useEffect, useState} from 'react';
import {Constants} from '../../../../../../utils/constants';
import {useDispatch} from "react-redux";
import _ from "lodash";
import {addQuestionnaireCompany, getQuestionnaireCompanies} from '../../../../../../graphql/mutations/matters';
import notification from "../../../../../../utils/notification";
import Mustache from 'mustache';
import ServerConnect from "../../../../../../utils/ServerConnect";
import Loading from '../../../../../Loading/Loading';
import CompanyForm from '../../../../../../containers/Forms/KYC/Company/CompanyForm';
import {LayoutForm} from '../../../../../../containers';
import {modifyDataFileKYC, stripTypenames} from '../../../../../../utils/graphql-util';


const AddQuestionnaireCompany = (props) => {
    const dispatch = useDispatch();

    const closeForm = () => {
        dispatch({ type: 'GO_TO', page: Constants.PAGES.questionnaireCompany, params: { matterId: props.params.matterId, tab:'questionnaire' } })
    }

    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(() => {
        return props.mode === "edit" ? true : false;
    })

    const getFormKey = () => {
        if (props.type == 'entity') {
            return 'COMPANY_FORM'
        }
        else if (props.params.type === "SHAREHOLDER") {
            return 'INDIVIDUAL_CORPORATE_FORM'
        }
        else {
            return 'INDIVIDUAL_CORPORATE_FORM'
        }
    }

    const modifyDataFile = (values) => {
        var newValues = _.forOwn(values, (val, key) => {
            if (_.hasIn(val, 'file')) {
                return values[key] = val.file
            }
        })
        console.log('NEWVALS', newValues)
        return newValues
    }
    const submitData = (values) => {

        let formatedValues = values
        let message = props.mode == 'edit' ? 'Company Updated' : 'Company Created';
        delete formatedValues.formKey
        delete formatedValues.type
        delete formatedValues.jurisdiction;

        const variables = props.mode == 'edit' ? {
            matterId: props.params.matterId,
            KYCCompanyData: formatedValues,
            KYCCompanyId: props.params.id
        } : {
                matterId: props.params.matterId,
                KYCCompanyData: formatedValues
            }

        ServerConnect.graphQlMutation(addQuestionnaireCompany, stripTypenames(variables))
            .then(result => {
                notification.sendNotification(message, "success", 4990);
                closeForm()
            }).catch(err => {
                console.log(err);
                notification.sendNotification("ERROR", "error", 4990);
            });
    }

    const getData = () => {
        return new Promise((resolve, reject) => {
            const query_params = {
                matterId: props.params.matterId
            };
            const queryExecute = Mustache.render(getQuestionnaireCompanies, query_params);
            ServerConnect.graphQlQuery(queryExecute).then(result => {
                let data = {
                    ...result.getMattersList
                }
                resolve(data);
            });
        })
    }

    const filterData = (data) => {
        if (data.questionnaire) {
            let company = _.find(data.questionnaire.companies, comp => { return props.params.id == comp.id });
            return company;
        }
    }

    useEffect(() => {
        if (!data && props.mode === 'edit') {
            Promise.resolve(getData())
                .then((result) => {
                    setData(filterData(result[0]))
                })
                .then(() => {
                    setLoading(false)
                })
        } else {
            setLoading(false)
        }
    }, [data, loading]);

    console.log(JSON.stringify(data, null, 2))
    return (
        <React.Fragment>
            <Loading show={loading}>
                <LayoutForm
                    title={"Add Company"}
                    leftEducationId={Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM[`${_.eq(props.mode, "edit") ? "EDIT" : "ADD"}`].LEFT_EDUCATION_ID}
                    handleClose={closeForm}>
                    <CompanyForm initialValues={props.mode == 'edit' ? { ...modifyDataFileKYC(data), formKey: getFormKey() } : { formKey: getFormKey() }} onSubmit={submitData} propsForm={{ isFlow: false }} />
                </LayoutForm>
            </Loading>
        </React.Fragment>
    )
}

export default AddQuestionnaireCompany;

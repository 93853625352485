import React from 'react';
import classes from './RowAdd.module.scss';
import Text from "../../../text/Text/Text";

const RowAdd = ({ align = "center", className, label, asterisk = false, input, paramLabel, classNameInput }) => {
    const getClassByAlign = () => {
        switch (align) {
            case "center":
                return classes.Center;
            case "top":
                return classes.Top;
            default:
                return classes.Center;
        }

    }
    return (
        <React.Fragment>
            <div className={`${getClassByAlign()} ${classes.RowAdd} ${className}`}>
                <div className={classes.Label}>
                    {
                        (typeof layout == "function") ? (
                            { label }
                        ) : (
                                <label>
                                    <Text uuid={label} params={paramLabel} />
                                    {asterisk && <span className={classes.Asterisk} >{' '}*</span>}

                                </label>
                            )
                    }
                </div>
                <div className={`${classes.Input} ${classNameInput}`}>
                    {input}
                </div>
            </div >
        </React.Fragment >
    )
}

export default RowAdd;
import React, { useState } from 'react'
import { Field } from 'formik'
import _ from 'lodash'
import EditDetails from "../../modules/clara/startups/Document/Modals/EditDocumentGenerate/EditDetails";
import greenCheck from '../../../images/verifyCorrect.svg'
import redCheck from '../../../images/verifyFailed.svg'
import classes from './styles.module.scss'

const VerifyDetails = ({groupCompanyId, title, type, tooltips, fieldName, initialValues, handleSubmit, groupCompany, ...props}) => {
    const [open, setOpen] = useState(false);
    const [typeModal, setTypeModal] = useState('');

    const handleOpenDetailsModal = () => {
        if (type === 'company'){
            setTypeModal('companyDetails')
        } else {
            setTypeModal('stakeholderDetails')
        }
        setOpen(true)
    };

    const handleOpenOtherDetailsModal = () => {
        if (type === 'company') {
            setTypeModal('recipientNoticeDetails')
        } else {
            // setTypeModal(groupCompanyId ? 'stakeholderRoleAndServices' : 'stakeholderRoleAndServicesNoGroup');
			setTypeModal('stakeholderRoleAndServices')
        }
        setOpen(true)
    };

    const handleClose=() => {
        setOpen(false)
    };

    const onSubmit = async (values, type, updated) => {
        setOpen(false);
        await handleSubmit(values, typeModal, updated, initialValues)
    };

    return(
		<div className={classes.buttonsDetails}>
			<Field name={`${fieldName}.verified1`}>
				 {({ field, form }) => (
					<VerifyButton
						onClick={handleOpenDetailsModal}
						field={field}
						isVerified={field.value}
						showError={_.get(form,`errors.${fieldName}.verified1`)}
						label={`${title} details `}
					/>
				 )}
			</Field>
			<Field name={`${fieldName}.verified2`}>
				{({ field, form }) => (
					<VerifyButton
						onClick={handleOpenOtherDetailsModal}
						showError={_.get(form,`errors.${fieldName}.verified2`)}
						isVerified={field.value}
						form={form}
						label={type === 'company' ? `Recipient notice` : `${title} role `}
					/>
				)}
			</Field>
			<EditDetails
				groupCompany={groupCompany}
				title={title}
				initialValues={initialValues}
				tooltips={tooltips}
				type={typeModal}
				open={open}
				handleSubmit={onSubmit}
				handleClose={handleClose}
                nameHandle={props.nameHandle}
			/>
	 	</div>
	)
};

export const VerifyButton=({label, onClick, showError, isVerified}) => {
    return(<>
        <div className={classes.verifyDetails}>
            <label style={showError && !isVerified?{color:'#FF606F'}:null}>{label}{' '}<span style={{color:'red'}}>*</span></label>
            <div style={{'flex-direction':'row'}}>
				<button
					className={showError && !isVerified ? 'errorButton' : `genericWhite`}
					hasError={showError && !isVerified ? "true" : "false"}
					style={showError && !isVerified ? { marginRight: '1rem'} : { marginRight: '1rem',borderColor:'#2C74FF' }}
					onClick={onClick}>
					Please Verify
				</button>
					{showError && !isVerified?
					<img style={{'margin-top':'2.5px'}} width={35} height={35} src={redCheck}/>:null
					}
					{isVerified?
						<img style={{'margin-top':'2.5px'}} width={35} height={35} src={greenCheck}/>:null
					}
            </div>
		</div>
    </>)
};

export default VerifyDetails;

import useMutationDelete from '../../../../hooks/custom/useMutationDelete'
import {COMPANY_CORPORATE_MAP} from "../../../../modules/map/hooks/services/useCompanyCorporateMap";
import { gql } from '@apollo/client'
import _ from "lodash"
import { useSelector } from 'react-redux'
import {TasksRequiredFieldFragment} from "../../../../modules/map/graphql/typePolicies";
import {RoleFields} from "../../../../modules/stakeholder/graphql/typePolicies";
import useSession from "./../../../../modules/session/hooks/useSession";
import {COMPANY_CORPORATE_MAP_TASKS} from "../../../../modules/assistanceV4/hooks/services/useGetCompanyCorporate";

const DELETE_DOCUMENT = `
  ${TasksRequiredFieldFragment}
  ${RoleFields}
  mutation deleteDocument(
    $startupId: String!
    $agreementId: String!
    $reason:String
  ) {
    deleteDocument(
      startupId: $startupId
      agreementId: $agreementId
      reason:$reason
    ) {
      id
      stakeholders{
        id
        tasks{
          ...TasksRequiredFieldFragment
        }
        roles{
          ...RoleFields
          tasks{
            ...TasksRequiredFieldFragment
          }
        }
      }
    }
  }  
`;

const useDeleteDocument = (props) => {

  const { startupId } = useSession();
    const refetchQueries= [{query:gql`${COMPANY_CORPORATE_MAP_TASKS}`, variables:{startupId:startupId}}]; 

	const shouldRefetchQuery = (observer) => {
		switch (observer.queryName) {
			case "getDocument":
				return false
		}
		return true;
	};

	const [mutation, loading] = useMutationDelete(DELETE_DOCUMENT,{ shouldRefetchQuery, ...props },{refetchQueries})

    return {mutation, loading};
};

export default useDeleteDocument;

import React, { FC, useRef, useState, useEffect } from "react";
import * as Yup from "yup";
import useTranslate from "../../../generic/hooks/useTranslate";
import Form from "../../../../components/forms/Form/FormL";
import ControllerInput from "../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import SubmitButton from "../../../generic/components/Buttons/SubmitButton";
import TemplateFormDefault from "../../../generic/templates/Modal";
import FormProps from "../../../generic/forms/interfaces/FormProps";
import CheckboxButtonSimple from "../../../generic/components/Inputs/CheckboxButtonSimple";
import classes from "./Form.module.scss";
import useMachine from '../../../../modules/generic/context/MachineContext/useMachine';
import _ from 'lodash';

const schema = Yup.object().shape({
  selectedSOF: Yup.string().nullable().required("Required"),
});

const FormSelectSourceOfFunds: FC<FormProps> = ({
  initialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
}: FormProps) => {

  const { translate } = useTranslate();
  const { context } = useMachine();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    let countValids = [];
    _.forEach(_.get(context, 'getJurisdiction.sourcesOfFunds'), (sof) => {
      let sofJurisdiction = _.get(sof, 'jurisdictionType');
      if (sofJurisdiction === 'CAYMAN') {
        sofJurisdiction = 'KY';
      }
      if (sofJurisdiction === null) {
        sofJurisdiction = 'OTHER';
      }
      const addSOFValue = {
        value: sofJurisdiction,
        label: translate("MODULES.ASSISTANCEV4.ASSISTANCE_" + sofJurisdiction),
      };
      countValids.push(addSOFValue);
    });
    setOptions(countValids);
  }, []);  

  const refForm = useRef();
  return (
    <>
      <Form
        defaultValues={initialValues}
        schema={schema}
        onlySendDirty={false}
        onSubmit={onCompleteSubmit}
        ref={refForm}
        optionsForm={{ mode: "onChange" }}
      >
        {" "}
        <Template
          buttons={{
            ...buttons,
            submit: <SubmitButton {...buttonSubmitProps}>{translate('TEXT_NEXT')}</SubmitButton>,
          }}
          props={propsTemplate}
        >
          <ControllerInput
            as={CheckboxButtonSimple}
            name="selectedSOF"
            options={options}
            className={classes.CheckboxButton}
            classNameButton={classes.buttonCheckBox}
          />
        </Template>
      </Form>
    </>
  );
};

export default FormSelectSourceOfFunds;

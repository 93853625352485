import React, {Component} from "react";
import {connect} from "react-redux";
import {Field, Formik} from "formik";
import {Constants} from '../../../../utils/constants'
import TextInput from "../../../../containers/Forms/Inputs/TextInput.js";
import ClaraInputSelect from "../../../Form/ClaraInputSelect/ClaraInputSelect";
import classes from '../EditForm.module.scss';

class Equity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            groupCompanySelected: {},
            shareClassSelected: {},
            lists: {
                groupCompanies: ['GC 1', 'GC 2'],
                shareClass: ['Share Class 1', 'Share Class 2', 'Share Class 3'],
                shareIncentivePlans: [],
                warrantPlans: []
            }
        };
    }

    deleteGrant = (params) => {
        this.props.data[params.type].splice(params.index, 1);
        this.props.updateForm(this.props.data);
        this.setState({
            refresh: !this.state.refresh
        })
    };

    changeToMode = (params) => {
        const data = {
            data: this.props.data[params.type][params.index],
            type: params.type,
            index: params.index
        };
        
        this.props.changeModeForm(Constants.FORMS_MODE.EDIT, data);
    };

    changeMode = (form,data,dataClone) => {
        let i = form.params.index;
        if (data.equity[i]){
            if (!data.equity[i].id) {
                data.equity.splice(i, 1)
            }else{
                data.equity[i]=dataClone;
            }
        }
        
        this.props.changeModeForm(Constants.FORMS_MODE.VIEW, this.props.form.form.params)
    };

    onSubmit = (values, bag) => {
        this.props.updateForm(values);
        this.props.changeModeForm(Constants.FORMS_MODE.VIEW, this.props.form.form.params)
    };

    componentDidMount() {
        const lists = {};

        const {id: groupCompanyId} = this.props.data;

        this.setValues(this.props.data.equity);

        lists.groupCompanies = this.getGroupCompanylist(groupCompanyId);
        lists.shareClass = this.getShareClass(groupCompanyId);

        this.setState({ lists });
    }

    setValues(equity) {
        let groupCompany = {};

        if(equity && equity[0] && equity[0].groupCompany) {
            groupCompany = this.findGroupCompany(equity[0].groupCompany.id);
            this.setState({
                groupCompanySelected: groupCompany,
                groupCompanyId: groupCompany.id
            })
        }

        if(equity && equity[0] && equity[0].shareClass) {
            const shareClass = this.findShareClass(groupCompany, equity[0].shareClass);
            this.setState({
                shareClassSelected: shareClass
            });

            this.handleGrantShareChange(equity[0].grantShare);
        }
    }

    getShareClass(groupCompanyId) {
        const returnArray = [];

        this.props.session.groupCompanies.forEach(gc => {

            gc.shareClass.forEach(shareClass => {
                if(gc.id !== groupCompanyId)
                    { returnArray.push({ id: shareClass.id, label: shareClass.name });}
            });
        });
        return returnArray;
    }

    updateGroupCompany = (groupCompanyId) => {
        this.setState({
            refresh: !this.state.refresh,
            groupCompanyId,
        });

    };

    filterShareClass() {
        const {groupCompanyId} = this.state;
        let returnArray = [];

        this.props.session.groupCompanies.forEach(gc => {
            gc.shareClass.forEach(shareClass => {
                if(gc.id === groupCompanyId) {
                    returnArray.push({
                        id: shareClass.id,
                        label: shareClass.name
                    });
                }
            });
        });

        return returnArray;
    }

    getGroupCompanylist(groupCompanyId) {
        return this.props.session.groupCompanies.filter(gc => gc.id !==groupCompanyId).map(gc => {
            return { id: gc.id, label: gc.name };
        })
    }

    mapWarrantPlans(shareClass) {
        return shareClass.incentivePlans ?
            shareClass.incentivePlans.map(ip => ({id: ip.id, label: ip.name}))
            : [];
    }

    getWarrantPlansList() {
        return this.mapWarrantPlans(this.state.shareClassSelected || {});
    }

    handleGrantShareChange = (grantShare) => {
        if(grantShare === 'Exercised Options') {
            this.setState({
                exercisedOption: true,
                exercisedWarrant: false
            })
        } else {
            if(grantShare === 'Exercised Warrants') {
                this.setState({
                    exercisedOption: false,
                    exercisedWarrant: true
                })
            } else {
                this.setState({
                    exercisedOption: false,
                    exercisedWarrant: false
                })
            }
        }
    }

    getGrantEquityForm(data) {
        const i = this.props.form.form.params.index;

        return (
            <div className={classes.Grant}>
                <div className={classes.CardHeader}>
                    <p>Share Grant</p>
                </div>

                <div className={`${classes.ContainerRow} ${classes.Select}`} >
                    <Field name={`equity[${i}].groupCompany.id`}>
                        {({ field, form }) => (
                            <ClaraInputSelect
                                label={"Group Company: "}
                                field={field}
                                form={form}
                                placeholder={"Select from a list.."}
                                tooltipText={null}
                                updateListFunction={null}
                                callbackUpdate={this.updateGroupCompany}
                                decorator={null}
                                list={this.state.lists.groupCompanies}
                                search={true}
                                modalTitle={null}
                                required={true}
                                onChange={this.selectGroupCompany}
                            />
                        )}
                    </Field>
                </div>
                <div className={`${classes.ContainerRow} ${classes.Select}`} >
                    <Field name={`equity[${i}].shareClass.id`}>
                        {({ field, form }) => (
                            <ClaraInputSelect
                                label={"Share Class:"}
                                field={field}
                                form={form}
                                placeholder={"Select from a list.."}
                                updateListFunction={null}
                                callbackUpdate={null}
                                decorator={null}
                                list={this.filterShareClass()}
                                search={true}
                                modalTitle={null}
                                onChange={this.selectShareClass}
                            />
                        )}
                    </Field>
                </div>
                <div className={`${classes.ContainerRow} `} >
                    <Field name={`equity[${i}].amount`}>
                        {({ field, form }) => (
                            <TextInput
                                label="Number of Shares:"
                                type="number"
                                field={field} form={form}
                                placeholder={"Enter a number..."}
                            />
                        )}
                    </Field>
                </div>
                <div className={`${classes.ContainerRow} ${classes.Select}`} >
                    <Field name={`equity[${i}].grantType`}>
                        {({ field, form }) => (
                            <ClaraInputSelect
                                label={"Grant Type:"}
                                field={field}
                                form={form}
                                placeholder={"Select from a list.."}
                                updateListFunction={null}
                                callbackUpdate={null}
                                decorator={null}
                                list={[
                                    'Founding Shares',
                                    'Share Subscription',
                                    'Share Purchase',
                                    'Exercised Options',
                                    'Exercised Warrants'
                                ]}
                                
                                search={true}
                                modalTitle={null}
                                onChange={this.handleGrantShareChange}
                            />
                        )}
                    </Field>
                </div>
                {
                    this.state.exercisedOption ?
                        <div className={`${classes.ContainerRow} ${classes.Select}`} >
                            <Field name={`equity[${i}].shareIncentivePlan`}>
                                {({ field, form }) => (
                                    <ClaraInputSelect
                                        label={"Share Incentive Plan: "}
                                        field={field}
                                        form={form}
                                        placeholder={"Select from a list.."}
                                        tooltipText={null}
                                        updateListFunction={null}
                                        
                                        callbackUpdate={null}
                                        decorator={null}
                                        search={true}
                                        modalTitle={null}
                                        list={this.getShareIncentivePlansList()}
                                    />
                                )}
                            </Field>
                        </div>
                        : null
                }
                {
                    this.state.exercisedWarrant ?
                        <div className={`${classes.ContainerRow} ${classes.Select}`} >
                            <Field name={`equity[${i}].warrantPlan`}>
                                {({ field, form }) => (
                                    <ClaraInputSelect
                                        label={"Warrant Plan: "}
                                        field={field}
                                        form={form}
                                        placeholder={"Select from a list.."}
                                        tooltipText={null}
                                        updateListFunction={null}
                                        callbackUpdate={null}
                                        decorator={null}
                                        list={this.getWarrantPlansList()}
                                        search={true}
                                        modalTitle={null}
                                    />
                                )}
                            </Field>
                        </div>
                        : null
                }
            </div>
        );
    }

    selectGroupCompany = (gcId, form, extraParams) => {
        const gc = this.findGroupCompany(gcId);

        this.setState(prevState => ({
            groupCompanySelected: gc,
            shareClassSelected: {},
            lists: {
                ...prevState.lists,
                shareIncentivePlans: []
            }
        }));
    };

    findShareClass(gc, id) {
        return gc.shareClass.filter(share => share.id === id)[0] || {};
    }

    findGroupCompany(id) {
        return this.props.groupCompanies.filter(gc => gc.id === id)[0] || {};
    }

    selectShareClass = (scId, form, extraParams) => {
        const gc = this.findGroupCompany(this.state.groupCompanySelected.id);

        const sc = this.findShareClass(gc, scId);

        this.setState(prevState => ({
            shareClassSelected: sc
        }), () => {
            this.setState(prevState => ({
                ...prevState,
                lists: {
                    ...prevState.lists,
                    shareIncentivePlans: this.mapShareIncentivePlans(sc)
                }
            }))
        });
    };

    getShareIncentivePlansList () {
        return this.mapShareIncentivePlans(this.state.shareClassSelected || {});
    }

    mapShareIncentivePlans(shareClass) {
        return shareClass.shareIncentivePlans ?
            shareClass.shareIncentivePlans.map(sip => ({id: sip.id, label: sip.name}))
            : [];
    }
    
    render() {
        const {data} = this.props;
        const i = this.props.form.form.params.index;
        const form = this.props.form.form;
        const dataClone = {...data.equity[i]};
        return (
            <div className={classes.ContainerEdit}>
                <Formik
                    initialValues={data}
                    enableReinitialize={false}
                    onSubmit={this.onSubmit}
                    render={({ values, handleSubmit, isSubmitting, handleReset, setFieldValue }) => {
                        return (
                            <form onSubmit={handleSubmit} style={{ marginBottom: '10px' }} className={classes.Form}>
                                {this.getGrantEquityForm(data)}
                                <div className={classes.Botonera}>
                                    <button data-cy="submit" type="submit" disabled={isSubmitting}>
                                        Save Changes
                                    </button>
                                    <button type="button" onClick={() => { this.changeMode(form,data,dataClone) }}>
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        )
                    }}>
                </Formik>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    session: state.session,
     
});
const mapDispatchToProps = (dispatch) => ({
    
});
export default connect(mapStateToProps, mapDispatchToProps)(Equity)

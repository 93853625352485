import React, { memo, useEffect } from 'react';
import classnames from "classnames";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { Constants } from "src/v1/utils/constants";
import classes from './FirstMenu.module.scss';
import _ from "lodash";

const FirstMenu = memo(({ value, setValue, goTo, linkMenu }) => {

  useEffect(() => {
    const links = [
      { key: "LinkMyEquity" },
      { key: "LinkSwitchStartup" },
    ];
    let index = _.findIndex(links, (link) => link.key === linkMenu);
    if (index < 0) {
      index = false;
    }
    setValue(index);
  }, [linkMenu, setValue]);

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        className={classnames(`${classes.menuItem}`, {
          [classes.selectedTab]: value === 1,
        })}
        onClick={() => {
          goTo(Constants.PAGES["switchStartup"]);
        }}
      >
        My profiles
      </Box>
      <Box
        className={classnames(`${classes.menuItem}`, {
          [classes.selectedTab]: value === 0,
        })}
        onClick={() => {
          goTo(Constants.PAGES["capTable.mf.myEquity.home"]);
        }}
      >
        My equity
      </Box>
    </Stack >
  );
});

export default FirstMenu;

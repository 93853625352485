import React, { FC, useState, useEffect } from 'react';
import Button from 'src/modules/generic/components/Buttons/Button';
import ModalDelete from 'src/modules/generic/components/Modal/ModalDelete';
import TemplateDelete from 'src/modules/generic/templates/Modal/TemplateDelete';
import useSession from 'src/modules/session/hooks/useSession';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import Typography from 'src/modules/generic/components/Texts/Typography';
import useModal from 'src/modules/generic/hooks/useModal';
import classModule from './class.module.scss'
import useDeleteUltimateBeneficialOwner from "src/views/modules/startups/renewal/cayman/modals/DeleteUltimateBeneficialOwner/graphql/useDeleteUltimateBeneficialOwner";

interface ModalDeleteUltimateBeneficialOwnerProps {
  open: boolean
  groupCompanyId: string,
  name: string
  ultimateBeneficialOwnerId: string,
}
const ModalDeleteUltimateBeneficialOwner: FC<ModalDeleteUltimateBeneficialOwnerProps> = ({ open, groupCompanyId, name, ultimateBeneficialOwnerId }) => {

  const [isOpen, setOpen] = useState(open);
  const [isLoading, setIsLoading] = useState(false);
  const { startupId } = useSession();
  const { translate } = useTranslate();
  const { closeModal } = useModal();
  const mutation = useDeleteUltimateBeneficialOwner();

  const handleClose = () => {
    closeModal()
  }

  const handleSubmit = async () => {
    setIsLoading(true);
    const variables = {
      startupId,
      groupCompanyId,
      entityId: ultimateBeneficialOwnerId
    }
    await mutation({variables});
    setIsLoading(false);
    handleClose()
  }

  useEffect(() => {
    setOpen(open)
  }, [open])

  return (
    <ModalDelete open={isOpen}>
      <TemplateDelete
        className={classModule.template}
        props={{
          title: "Delete ultimate beneficial owner",
          description: (
            <Typography
              component='p'
              variant="body"
            >
              Please confirm if you would like to delete {name} as an ultimate beneficial owner of the company. This action cannot be undone.
            </Typography>
          ),
        }}
        buttons={{
          submit: <Button variant='submitDelete' loading={isLoading} onClick={handleSubmit}>{translate('MODULES.MAP.MODAL_MARK_TASK_CAPTABLE.CONFIRM')}</Button>,
          cancel: <Button variant='cancelSubmit' onClick={handleClose}>{translate('MODULES.MAP.MODAL_MARK_TASK_CAPTABLE.CANCEL')}</Button>
        }}
      ></TemplateDelete>
    </ModalDelete>
  )
}
export default ModalDeleteUltimateBeneficialOwner
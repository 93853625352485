import { useMemo, useState } from 'react';
import useSession from 'src/modules/session/hooks/useSession';
import _ from "lodash";
import moment from 'moment';
import useUpsertFormationCompany from 'src/modules/startup/GroupCompany/graphql/mutations/useUpsertFormationCompany';
import useAddComponentRole from 'src/modules/startup/onBoarding/core/graphql/mutations/useAddComponentRole';
import useUpsertComponentStakeholder from './services/useUpsertComponentStakeholder';

const useServices = ({initialValues}) => {
  
  const { startupId } = useSession();
  const [upsertFormationCompany] = useUpsertFormationCompany({}, {});
  const { addComponentRole } = useAddComponentRole({ 
    showSuccessNotification: true,
    successMessage: 'STAKEHOLDER_ADD_SUCCESS'
  }, {});
  const [mutation] = useUpsertComponentStakeholder({ 
    showSuccessNotification: false
  }, {});

  // updates the formation data with the authorised signatories
  const upsertFormation = async (authorisedSignatoriesIds,companyId,useThird) => {
    return await upsertFormationCompany({
      startupId,
      groupCompanyId: companyId,
      groupCompanyData: {
        formationData: {
          authorisedSignatories: authorisedSignatoriesIds,
          isUsingNominee: !useThird,
          isUsingThirdParty: useThird
        }
      }
    })
  }
  // adds a new stakeholder to the company
  const addStakeholder = async (stakeholderValues,companyId) => {
    let values = {
      startupId,
      stakeholder: {
        ...stakeholderValues
      },
      roles: [{
        role: [{type:"THIRDPARTY"}], 
        groupCompany: companyId
      }]
    }

    return await addComponentRole({...values})
  }

  // updates the stakeholder with the enterType and entryDate values
  const updateStakeholder = async (stakeholderId, enterType, entryDate) => {
    const stakeholderToUpdate = {  
      startupId,
      stakeholderId: stakeholderId,
      stakeholder:{
        enterType: enterType,
        entryDate: entryDate,
      }
    }

    return await mutation({
      ...stakeholderToUpdate
    })
  }

  return useMemo(() => ({
    // Get the initial data
    initialData: async (context, event) => {
      return {...initialValues};
    },
    // You have selected to use a Clara nominee service, and selected a second signatory from the list of stakeholders
    SaveHowDidTheyEnterFormStep: async (context, event)=>{
      
      await updateStakeholder(context?.WhoIsSecondAuthorisedSignatoryFormStep.secondAuthorisedSignatory.id,context?.HowDidTheyEnterFormStep?.howDidTheyEnter ?? null,context?.HowDidTheyEnterFormStep?.entryDate ? moment(context?.HowDidTheyEnterFormStep?.entryDate ).format("YYYY-MM-DD") : null)
      
      return await upsertFormation([context?.WhoIsSecondAuthorisedSignatoryFormStep.secondAuthorisedSignatory.id], context?.initialData.idGroupCompany,false); 
    },

    // You have selected to use a Clara nominee service, and selected a third party as your second signatory
    SaveThirdPartyWithNomineeFormStep: async (context, event)=>{
      const st = await addStakeholder(
        {fullName:  context?.ThirdPartyWithNomineeFormStep?.fullName,
          enterType: context?.ThirdPartyWithNomineeFormStep?.howDidTheyEnter ?? null,
          entryDate: context?.ThirdPartyWithNomineeFormStep?.entryDate ? moment(context?.ThirdPartyWithNomineeFormStep?.entryDate ).format("YYYY-MM-DD") : null
        },
        context?.initialData.idGroupCompany
      );

      return await upsertFormation([st?.data.addComponentStakeholderRole?.id], context?.initialData.idGroupCompany,false);  
    },

    // You have selected to don't use a Clara nominee service, and selected a second signatory from the list of stakeholders
    SaveWhoIsGoingToBeYourAuthorisedSignatoryFormStep: async (context, event)=>{
      return await upsertFormation( [context?.WhoIsGoingToBeYourAuthorisedSignatoryFormStep?.authorisedSignatory.id], context?.initialData.idGroupCompany,true); 
    },

    // You have selected to don't use a Clara nominee service, and selected a third party as your second signatory
    SaveAddThirdPartyFormStep: async (context, event)=>{
      const st = await addStakeholder({fullName: context?.AddThirdPartyFormStep?.nameField},context?.initialData.idGroupCompany);

      return await upsertFormation([st?.data.addComponentStakeholderRole?.id], context?.initialData.idGroupCompany,true);  
    },
  }), [initialValues]);
}

export default useServices;

import useMutation from "src/hooks/custom/useMutation";

export const UPSERT_CREATE_BENEFICIAL_OWNER = `
mutation createUltimateBeneficialOwner(
  $startupId: ID!
  $groupCompanyId: ID!
  $entityData: RegulatoryBusinessEntityInput!
) {
  createUltimateBeneficialOwner(
    startupId: $startupId
    groupCompanyId: $groupCompanyId
    entityData: $entityData
  ) {
    id
  }
}
`;

const useCreateUltimateBeneficialOwner = () => {
  const [mutation] = useMutation(
    UPSERT_CREATE_BENEFICIAL_OWNER,
    { showSuccessNotification: true }
  );
  return mutation;
};

export default useCreateUltimateBeneficialOwner;

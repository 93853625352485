import { handleNationalitiesSaveValue } from "src/utils/otherNationalitiesHelper";
import useGetStakeholderDetails from "./graphql/useGetStakeholderDetails";
import useUpsertRenewalData, { Tasks } from "./graphql/useUpsertRenewalData";
import useUpsertStakeholderDetails from "./graphql/useUpsertStakeholderDetails";
import useMapperStakeholderDetails from "./useMapperStakeholderDetails";
import _ from 'lodash';

const useServices = ({ initialValues }) => {
  const updateStakeholderTasks = useUpsertRenewalData();
  const { query } = useGetStakeholderDetails({});
  const { getInitialData } = useMapperStakeholderDetails();
  const updateStakeholder = useUpsertStakeholderDetails({}, {});

  return {

    initialData: async () => {
      const data = await query(initialValues.stakeholderId);
      return getInitialData(data?.stakeholder);
    },

    SaveStep: async (context) => {
      const {
        ReviewDetailsUBO,
        ReviewUboAddress,
        ReviewUboPEP,
        ReviewUboSof,
        initialData,
      } = context;
      
      const {
        title,
        fullName,
        previousName,
        arabicName,
        nationality,
        dateOfBirth,
        profession,
        email,
        phoneNumber,
        nationalities,
        hasNationalities,
        countryOfBirth,
        cityOfBirth
      } = ReviewDetailsUBO;

      const { address } = ReviewUboAddress;
      const { isPEP, descriptionOfPEP } = ReviewUboPEP;
      const {
        sourceOfFunds,
        sourceOfWealth,
        otherSourcesOfWealth,
      } = ReviewUboSof;
      
      const tasks = ["isStakeholderDetailsConfirmed" as Tasks, "areNationalitiesConfirmed" as Tasks];
      
      let newSourceOfWealth = sourceOfWealth;
      if (ReviewUboSof?.sourceOfWealth === "others") {
        newSourceOfWealth = otherSourcesOfWealth;
      }

      const variables = {
        stakeholder: {
          birthAddress: {
            country: countryOfBirth,
            city: cityOfBirth,
          },
          nationalities: handleNationalitiesSaveValue(nationalities, hasNationalities, _.get(initialData, "stakeholder.nationalities.countries", [])),
          title,
          fullName,
          previousName,
          arabicName,
          nationality,
          dateOfBirth,
          profession,
          email,
          phoneNumber,
          address,
          pep: {
            isPEP,
            descriptionOfPEP,
          },
          sourcesOfFunds: [
            {
              sourceOfFunds,
              sourceOfWealth: newSourceOfWealth,
            },
          ],
        },
      };

      const result = await updateStakeholder(
        variables,
        initialValues?.stakeholderId
      );

      await updateStakeholderTasks(
        initialData.groupCompany.id,
        tasks,
        initialValues.stakeholderId
      );
      return result;
    },
  };
};

export default useServices;

import React from "react";
import SubscriptionsTaskComponent from "./SubscriptionsTaskComponent";

const AssistanceSubscriptions = () => {
  return (
    <SubscriptionsTaskComponent />
  );
};

export default AssistanceSubscriptions;

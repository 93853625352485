import React, {Component} from "react";
import classes from "./CircularGraph.module.scss"
import {Doughnut} from 'react-chartjs-2';

class CircularGraph extends Component {
    state = {
        defaultColors: ["#00edff", "#96ab78", "#ffcc7c", "#e7592f", "#f0c000", "#b15cb7", "#1ca23b", "#00c8fe", "#ffeb3b", "#f44336"],
        data: {
            labels: [],
            datasets: [{
                data: [],
            }]
        },
        defaultOptions: {
            legend: {
                display: true
            },
        },
        options: {}
    };

    update() {
        const labels = this.props.labels;
        const data = this.props.data;
        let colorToUser = this.state.defaultColors;
        if (this.props.colors) {
            colorToUser = this.props.colors;
        }

        const backgroundColor = this.props.colors;

        /*data.map((value, key) => {
            if (key + 1 > colorToUser) {

                const letters = '0123456789ABCDEF'.split('');
                let color = '#';
                for (let i = 0; i < 6; i++) {
                    color += letters[Math.floor(Math.random() * 16)];
                }
                return color;
            } else {
                return this.state.defaultColors[key];
            }
        });*/

        const newOptions = { ...this.state.defaultOptions, ...this.props.options };

        this.setState({
            data: {
                labels,
                datasets: [{
                    data,
                    backgroundColor
                }]
            },
            options: {
                ...newOptions,
                cutoutPercentage: 85,
                // TODO: Fix overflow of the tooltip
                tooltips: {
                    enabled: true,
                    backgroundColor: 'white',
                    bodyFontColor: 'black',
                    bodyFontSize: 13,
                    xPadding: 10,
                    yPadding: 10,
                    borderColor: 'lightgrey',
                    borderWidth: 1,
                    cornerRadius: 4,
                    displayColors: true,
                    /*custom: function(tooltipModel) {
                        // Tooltip Element
                        let tooltipEl = document.getElementById('chartjs-tooltip');

                        // Create element on first render
                        if (!tooltipEl) {
                            tooltipEl = document.createElement('div');
                            tooltipEl.id = 'chartjs-tooltip';
                            tooltipEl.innerHTML = '<table></table>';
                            document.body.appendChild(tooltipEl);
                        }

                        // Hide if no tooltip
                        if (tooltipModel.opacity === 0) {
                            tooltipEl.style.opacity = 0;
                            return;
                        }

                        // Set caret Position
                        tooltipEl.classList.remove('above', 'below', 'no-transform');
                        if (tooltipModel.yAlign) {
                            tooltipEl.classList.add(tooltipModel.yAlign);
                        } else {
                            tooltipEl.classList.add('no-transform');
                        }

                        function getBody(bodyItem) {
                            return bodyItem.lines;
                        }

                        // Set Text
                        if (tooltipModel.body) {
                            let titleLines = tooltipModel.title || [];
                            let bodyLines = tooltipModel.body.map(getBody);

                            let innerHtml = '<thead>';

                            titleLines.forEach(function(title) {
                                innerHtml += '<tr><th>' + title + '</th></tr>';
                            });
                            innerHtml += '</thead><tbody>';

                            bodyLines.forEach(function(body, i) {
                                let colors = tooltipModel.labelColors[i];
                                let style = 'background:' + colors.backgroundColor;
                                style += '; border-color:' + colors.borderColor;
                                style += '; border-width: 2px';
                                style += '; color:' + colors.backgroundColor;
                                style += '; margin-right: 4px';
                                style += '; border: 2px solid #F0F0F0';
                                let span = '<span style="' + style + '">aa</span>';
                                innerHtml += '<tr><td>' + span + body + '</td></tr>';
                            });
                            innerHtml += '</tbody>';

                            let tableRoot = tooltipEl.querySelector('table');
                            tableRoot.innerHTML = innerHtml;
                        }

                        // `this` will be the overall tooltip
                        let position = this._chart.canvas.getBoundingClientRect();

                        // Display, position, and set styles for font
                        tooltipEl.style.opacity = 1;
                        tooltipEl.style.position = 'absolute';
                        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                        tooltipEl.style.pointerEvents = 'none';
                        tooltipEl.style.backgroundColor = tooltipModel.backgroundColor;
                        tooltipEl.style.color = tooltipModel.bodyFontColor;
                        tooltipEl.style.border = '1px solid ' + tooltipModel.borderColor ;
                        tooltipEl.style.borderWidth = tooltipModel.borderWidth;
                        tooltipEl.style.borderRadius = tooltipModel.cornerRadius;
                    }*/
                }
            }
        });
    }
    componentWillMount() {
        if (this.props.data &&  this.props.labels){
            this.update();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.data !== this.props.data || prevProps.labels !== this.props.labels) {
            if (this.props.data  &&  this.props.labels){
                this.update();
            }
        }
    }

    render() {
        return (
            <div className={classes.CircularGraph}>
                <Doughnut width={170} ref={(ref) => this.chartReference = ref} data={this.state.data} options={this.state.options} />
            </div>
        );

    }
}


export default CircularGraph

import { createMachine } from 'xstate';

const machine =
  /** @xstate-layout N4IgpgJg5mDOIC5QFVZgE4AUCuAjANgJYDGASmFIbAC7oCG1hA9gHYB02a6AYk+gLYBiAHIBRABoAVRKAAOTWIUasZIAB6IAjABYAHGwBMAVgBsukye1GjABgDsuzQYA0IAJ5bNATjZGvf3V1tL20TOxt-AF9I11QMHAISckoaemV2AGslXgFBTFJRADVVeUV01Q0EHX1jMwsrWwcnVw8EO01fLxMbG10AZh6m42jYrgSiMgoqWgZmTOy+ITEpEoUlOYrEPr6fTXCvO1s+0J7NFq0DAzYIgKCQsJuRkDisPAnk6bS5thowWT68gVikgQKV1ioQZVtrt9ocbMdujYzu5EHZtNcuj1+oNHMMniwmBA4KoXuMklNUrNWBwuDl+KsyhtIYhtC4UW0fDs7H0DLoutpNDy+iYnqS3uSUjN0mwstQ6QzwSxNggDALDKZdEYdF4Qto+v1zghHGw+dzefzBQZhaKxuLJpKvtTfv8FeVmQg9Vc9hFEQ4ukZdIagmwuTy+ZZLdaYs9bYl7Z8qUqQWC3aBKppA+yALQdbQ2PM2IzaVlWvMWaLRIA */
  createMachine({
    id: 'CompleteOtherNationalityInfo',
    initial: 'initialData',
    states: {
      initialData: {
        invoke: {
          src: 'initialData',
          id: 'initialData',
          onDone: [
            {
              target: 'IsTherePassport',
              actions: ['setContextAfterInvoke']
            }
          ],
        }
      },
      IsTherePassport: {
        on: {
          NEXT: {
            target: 'EvalIsTherePassport',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      EvalIsTherePassport: {
        always: [
          {
            cond: 'isTherePassport',
            target: 'UploadPassport',
          },
          {
            cond: 'isNotTherePassport',
            target: 'Save',
          }
        ]
      },
      UploadPassport: {
        on: {
          PREV: {
            target: 'IsTherePassport'
          },
          NEXT: {
            target: 'ConfigurePassport',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      ConfigurePassport: {
        on: {
          PREV: {
            target: 'UploadPassport',
          },
          NEXT: {
            target: 'Save',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
        Save: {
        invoke: {
          src: 'save',
          id: 'save',
          onDone: [
            {
              target: 'finish',
              actions: ['setContextAfterStep']
            }
          ]
        }
      },
      finish: {
        entry: 'stopMachine',
        type: 'final'
      },
      cancel: {
        entry: 'cancelMachine',
        type: 'final'
      },
    }
  });

export default machine;
import useGetTechLisence from "./graphql/useGetTechLisence";
import useUpdateTechLicenceQustionnaire from './graphql/useUpdateTechLicenceQustionnaire'

const useServices = ({ initialValues }) => {
  const { groupCompanyId,isEdit,groupCompanyName } = initialValues;
  const { query } = useGetTechLisence({},{});
  const mutation = useUpdateTechLicenceQustionnaire();

  const initialData = async () => {
    if(isEdit){
    const  data  = await query(groupCompanyId);

    return { 
        techLicense:data.techLicense,
        groupCompanyId,
        groupCompanyName
     };
    }
    return {
      groupCompanyId
    }
  }

  const SaveStep = async (context) => {    
    const { QuestionnaireStep } = context;
    await mutation(groupCompanyId,{...QuestionnaireStep});
    return; 
  }

  return { initialData, SaveStep };
}

export default useServices;

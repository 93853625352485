import React from 'react';
import { Field, Formik } from 'formik';
import { ChatShareCaptial, ChatSharesIssues } from '../../../../components';
import classes from './Shareholders.module.scss'
import classesForm from '../Form.module.scss';
import _ from 'lodash'
import { useParams } from 'react-router-dom';
import SelectorComponentShareholders from '../SelectorComponent/SelectorComponentShareholders';
import { shareholdersAmountSchema, shareholdersSchema } from '../validations/validationForms'


const ShareholdersForm = React.memo(({ initialValues, onSubmit, closeForm }) => {




  const params = useParams();

  const validationSchema = params.type === "shareholders" ? shareholdersSchema : shareholdersAmountSchema;
  return (
    <React.Fragment>

      {
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          validate={null}
          onSubmit={onSubmit}
          isInitialValid
        >

          {({
            values,
            errors,
            dirty,
            handleSubmit,
            isSubmitting,
            handleReset,
            setFieldValue,
            initialValues,
            isValid,
            setFieldError,
            validateForm,
          }) => {
            return (

              <React.Fragment>
                <div className={classesForm.containerData} >
                  {_.isEmpty(params.type) ? <>
                    <SelectorComponentShareholders
                      name={"shareholders"}
                      titleButtons={`QUESTIONARY_SHAREHOLDER_SHAREHOLDER_TITLE_BUTTONS`}
                      titleEntyties={`QUESTIONARY_SHAREHOLDER_TEXT`}
                      initialValueStart={{ matterId: params.matterId }}
                      showButtonAdd={true}
                    />

                    <Field name={"shareType"}>
                      {({ field, form }) => (
                        <ChatShareCaptial
                          isEditForm={true}
                          responseKeyName={""}
                          initialValueStart={{ matterId: params.matterId }}
                          field={field}
                          form={form}
                          isForm
                          className={{ ChatShareCaptial: classes.ChatShareCaptial }}
                        />
                      )}
                    </Field>
                  </>
                    :
                    <Field name="shareholders.shares">
                      {({ field, form }) =>
                        <ChatSharesIssues
                          field={field}
                          form={form}
                          autorizedShares={_.get(form.values, 'sharesAuthorized', '')}
                          stakeholdes={_.get(form, "values.shareholders", [])}
                          isForm
                          className={{
                            containerRow: classes.containerRow,
                            inputContainer: classes.inputContainer,
                            containerTotals: classes.containerTotals,
                            columntitleNumShares: classes.columntitleNumShares,
                            columntitlePaidUnpaid: classes.columntitlePaidUnpaid
                          }}
                        />
                      }

                    </Field>
                  }
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  {/* <pre>{JSON.stringify(errors, null, 2)}</pre>*/}
                  <div className={`botoneraButtons ${classesForm.containerButtons}`}>
                    <button className={`genericBlue`} onClick={closeForm} >Cancel</button>
                    <button className={`genericBlue`} onClick={handleSubmit} disabled={!isValid || _.get(values, 'shareholders', []).length < 1} >Update </button>
                  </div>


                </div>
              </React.Fragment>
            )

          }
          }
        </Formik>
      }
    </React.Fragment>
  )
})

export default ShareholdersForm;



import React, { FC, useState, useEffect } from 'react'
import Wizard from '../../../generic/components/Wizard'
import ModalAssistant, { ModalAssistantProps } from '../../../generic/components/Modal/ModalAssistant'
import MachineCompanyDetail from './machine'
import { clearAssistatText } from '../../../generic/store/action'
import { useDispatch } from 'react-redux'
import useModal from '../../../generic/hooks/useModal'

interface ModalProfileStakeholderProps extends ModalAssistantProps {
  paramsMutation: object,
  onClose(): void;
}

const ModalProfileStakeholder: FC<ModalProfileStakeholderProps> = ({ children, open: openParam = false, paramsMutation, ...props }: ModalProfileStakeholderProps) => {

  const [open, setOpen] = useState(openParam); 
  const { closeModal: onClose } = useModal();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearAssistatText());
    setOpen(openParam);
  }, [openParam]);

  const handleClose = (values) => {
    onClose();
    setOpen(false);
  }

  return (
    <ModalAssistant open={open}>
      <Wizard machine={MachineCompanyDetail} initialValues={paramsMutation} onComplete={handleClose} />
    </ModalAssistant>
  )
}

export default ModalProfileStakeholder;





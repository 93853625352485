import useQuery from "../../../../../../../hooks/custom/useQuery";
import _ from 'lodash'
import {GroupCompanyPartyRequiredFields} from "../../../../../../../modules/startup/Documents/graphql/fragments";
import {RoleFields} from "../../../../../../../modules/stakeholder/graphql/typePolicies";
import {NoticesFragment} from "../../../../../../../modules/startup/Stakeholder/graphql/fragments";

export const TABLE_AGREEMENT_LIST=
    `${GroupCompanyPartyRequiredFields}
     ${RoleFields}
     ${NoticesFragment}
   query TableAgreementListViewQuery($startupId: ID!) {
    getGroupCompanies(startupId: $startupId) {
      authorizedSignatories {
        ...RoleFields
        stakeholder {
          id
          email
          fullName
      }
      }
      id
      avatar
      name
      isHoldingCompany
      hasAgreements
      documents {
        name
        id
        isLocked
        kind
        status
        type {
          label
          code
        }
        groupCompanies {
          groupCompany {
            id
            avatar
            name
          }
          signedBy {
            id
          }
        }
        stakeholders {
          id
          avatar
          fullName
        }
        subCategories
        file {
          id
          name
          size
          type
        }
      } # documents
      recipientNotice {...NoticesFragment}
    }
    documentList(startupId: $startupId) {
      id
      name
      isLocked
      params
      status
      freemiumValid
      isReplaced
      groupCompanies {
        groupCompany {
          name
        }
        ...GroupCompanyPartyRequiredFields
      }
      executedOnDate
      subCategories
      kind
      type {
        code
        label
        allowedActions
      }
      id
      parties
      file {
        id
        name
        size
        type
      }
      avatars {
        name
        type
        imageUrl
      }
      parentDocument {
        id
        name
        type {
          code
        }
        status
      }
      relatedDocuments {
        id
        type {
          code
        }
      }
    }
    draftDocumentList(startupId: $startupId) {
      id
      file {
        id
        name
        type
      }
    }
    getDocumentBundle(startupId: $startupId) {
      file {
        id
        name
        size
      }
      category
      subCategory
    }
  }
`
const useGetTableAgreementList= (variables,config,props) => {
    const {data,refetch,loading}=useQuery(TABLE_AGREEMENT_LIST,variables,config,props)
    return {data:{
            documents: _.get(data,'documentList',[]),
            groupCompanies:_.get(data,'getGroupCompanies',[]),
            documentBundle:_.get(data,'getDocumentBundle',null),
            draftDocuments:_.get(data,'draftDocumentList',[]),
        },refetch,loading}
}
export default useGetTableAgreementList

import _ from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from 'react';
import ModalCategoriseDocument from "src/components/modules/clara/startups/Document/Modals/CategoriseDocument";
import DeleteUploadDocumentModal from "src/components/modules/clara/startups/Document/Modals/Delete/DeleteUploadDocumentModal";
import useBusinessRules from 'src/modules/clara/hooks/useBusinessRules';
import useDocumentBusinessRules from "src/modules/clara/hooks/useDocumentBussinessRules";
import Rename from 'src/modules/documents/modals/Rename';
import Button from 'src/modules/generic/components/Buttons/Button';
import Typography from 'src/modules/generic/components/Texts/Typography';
import useModal from "src/modules/generic/hooks/useModal";
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import useRoleAccess from "src/modules/security/hooks/useRoleAccess";
import { ServerConnect } from "src/v1/utils";
import { Constants } from "src/v1/utils/constants";
import classesModule from './classes.module.scss';

function TitleDocumentUploaded({ document, title, ...props }) {

  const { translate } = useTranslate();
  const { openModal, closeModal } = useModal();
  const { isOwner, isManager, isEditor } = useBusinessRules();
  const { canRenameDocument } = useDocumentBusinessRules(document);

  const isLock = useMemo(() => {
    return _.get(document, "isLocked", false);
  }, [document]);

  const handleEditDocument = () => {
    const isPassport = _.includes(Constants.PASSPORT_DOCUMENT_TYPES, document.type.code);
    const isVisaStampResidence = _.includes(Constants.VISA_STAMPS_RESIDENCE_ID_DOCUMENTS_TYPES, document.type.code);
    openModal(
      ModalCategoriseDocument,
      {
        isEdit: true,
        isCategorized: false,
        isVisaOrPassportDocument: isPassport || isVisaStampResidence,
        initialValues: {
          document
        },
      },
      "MODAL_CATEGORISE_DOCUMENT"
    );
  };

  const handleDownloadDocument = async () => {
    let url = ServerConnect.getUrlFile(document.file.id);
    await ServerConnect.downloadFile(url, document.file.name);
  };

  const handleDeleteDocument = () => {
    openModal(DeleteUploadDocumentModal, { data: document, goTo: Constants.PAGES.documents, handleCloseModal: closeModal });
  };

  const handleDisabledRenameDocument = () => {
    return !((isOwner() || isManager() || isEditor()) && canRenameDocument())
  }

  const handleRenameDocument = () => {
    openModal(Rename, {
      document: {
        documentId: _.get(document, 'file.id'),
        agreementId: _.get(document, 'id'),
        documentName: _.get(document, 'name'),
      },
    })
  }

  const isEdit = useRoleAccess(Constants.ACTIONS.EDIT_PROFILE);

  return (
    <div className={` ${classesModule.TitleDocumentUploaded} `}>
      <Typography variant='h2' color='blue'>{title}</Typography>
      <div>
        {isEdit && (
          <>
            {_.get(document, 'freemiumValid') && (
              <Button
                disabled={isLock}
                variant="card"
                onClick={handleEditDocument}
              >
                {translate(
                  "DETAILS_DOCUMENT_UPLOADED_EDIT_DOCUMENT"
                )}
              </Button>
            )}
            {!handleDisabledRenameDocument() && (
              <Button
                disabled={isLock}
                variant="card"
                onClick={handleRenameDocument}
              >
                {translate("DETAILS_DOCUMENT_UPLOADED_RENAME_DOCUMENT")}
              </Button>
            )}
            {_.get(document, 'freemiumValid') && (
              <>
                <Button
                  variant="card"
                  disabled={isLock}
                  onClick={handleDownloadDocument}
                >
                  {translate("DETAILS_DOCUMENT_UPLOADED_DOWNLOAD_DOCUMENT")}
                </Button>
              </>
            )}
            <Button
              variant="cardWarning"
              disabled={isLock}
              onClick={handleDeleteDocument}
            >
              {translate("DETAILS_DOCUMENT_UPLOADED_DELETE_DOCUMENT")}
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

TitleDocumentUploaded.propTypes = {
  children: PropTypes.any,
  document: PropTypes.any,
  title: PropTypes.any
}

export default TitleDocumentUploaded;
import { createMachine } from 'xstate';

const machine =
  createMachine({
    id: 'AddFormationCompanyInCayman',
    initial: 'FormCaymanAgree',
    states: {
      FormCaymanAgree: {
        on: {
          PREV: {
            target: 'previousMachine',
          },
          NEXT: {
            target: 'Evaluate_FormCaymanAgree',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      Evaluate_FormCaymanAgree: {
        always: [
          {
            cond: 'acceptInCayman',
            target: 'FormInCaymanInfo'
          },
          {
            cond: 'discardCayman',
            target: 'EngageConciergeCayman'
          },
        ]
      },
      FormInCaymanInfo: {
        on: {
          PREV: {
            target: 'FormCaymanAgree',
          },
          NEXT: {
            target: 'AddCaymanCompany',
            actions: ['setContextAfterStep', 'setCaymanJurisdiction']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      AddCaymanCompany: {
        on: {
          PREV: {
            target: 'FormInCaymanInfo',
          },
          NEXT: {
            target: 'SaveStep',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      SaveStep: {
        invoke: {
          src: 'SaveStep',
          id: 'SaveStep',
          onDone: [
            {
              target: 'finish',
            }
          ],
          onError: [
            {
              target: 'AddCaymanCompany'
            }
          ]
        }
      },
      EngageConciergeCayman: {
        on: {
          PREV: {
            target: 'FormCaymanAgree',
          },
          NEXT: {
            target: 'Evaluate_EngageConciergeCayman',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      Evaluate_EngageConciergeCayman: {
        always: [
          {
            cond: 'sendMail',
            target: 'SendMailStep'
          },
          {
            cond: 'noSendEmail',
            target: 'finish'
          },
        ]
      },
      SendMailStep: {
        invoke: {
          src: 'SendMailNow',
          id: 'SendMailNow',
          onDone: [
            {
              target: 'finish',
            }
          ],
          onError: [
            {
              target: 'EngageConciergeCayman'
            }
          ]
        }
      },
      previousMachine: {
        entry: 'previousMachine',
        type: 'final'
      },
      cancel: {
        entry: 'cancelMachine',
        type: 'final'
      },
      finish: {
        entry: 'stopMachine',
        type: 'final'
      }
    }
  });

export default machine;

import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateLabel from 'src/modules/generic/components/Inputs/LabelInput';
import * as Yup from 'yup';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import FileUploaderInput from 'src/components/inputs/FileUploader/FileUploaderInput';
import classes from './classes.module.scss';

function PreApprovedUploadDocumentsFormStep() {
  const { next, context, prev,cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  
  const initialValues = useMemo(() => {
    return context?.PreApprovedUploadDocumentsFormStep ?? {}
  }, [context]);
  
  const handleCancel = () => {
    cancel();
  }

  const handlePrev = () => {
    prev();  
  }

  const handleSubmit = async (values) => {
    next(values);
  }
  
  useEffect(() =>{
    dispatch(addAssistantText('Clara’s business plan template is the preferred template by ADGM and should be followed closely to avoid delays from ADGM requesting further information.'));

    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch]);
  
  const schema = Yup.object().shape({
    businessTemplate: Yup.object().nullable().required('Required'),
    pitchDeck: Yup.object().nullable().required('Required'),
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={ { mode: 'onChange' } }
    >
      <TemplateWizard
        props={{
          title: 'Upload your documents',
          skeleton: null,
          subTitle: 'Business plan',
          variant: 'simple'
        }}
        buttons={{
          previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>{translate('GENERIC_BUTTON_ADD_GROUP')}</SubmitButton>,
        }}
      >
          <TemplateLabel label='Upload your business plan (Clara template)' isRequired={true}>
            <ControllerInput
              className={classes.documentUploaderFullLength}
              render={FileUploaderInput}
              defaultValue=""
              name="businessTemplate"
            />
          </TemplateLabel>

          <TemplateLabel label='Upload your pitch deck' isRequired={true}>
            <ControllerInput
              className={classes.documentUploaderFullLength}
              render={FileUploaderInput}
              defaultValue=""
              name="pitchDeck"
            />
          </TemplateLabel>
      </TemplateWizard>
    </Form>
  );
}

export default PreApprovedUploadDocumentsFormStep;

import React, { FC, useState, useEffect } from 'react'
import Button from 'src/modules/generic/components/Buttons/Button';
import ModalDelete from 'src/modules/generic/components/Modal/ModalDelete';
import TemplateDelete from 'src/modules/generic/templates/Modal/TemplateDelete';
import useSession from 'src/modules/session/hooks/useSession';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import Typography from 'src/modules/generic/components/Texts/Typography';
import useModal from 'src/modules/generic/hooks/useModal';
import classModule from './class.module.scss'
import useRegisterAsCheckedTask from 'src/modules/generic/graphql/mutation/useRegisterAsCheckedTask'
//TODO:Type Role
interface ModalRemoveTaskProps {
  open: boolean
  registeredTaskData:{entityId:string,taskCode:string}
}
const ModalRemoveTask: FC<ModalRemoveTaskProps> = ({ open, registeredTaskData }) => {

  const [isOpen, setOpen] = useState(open);
  const {mutation:removePendingEntity, loading} = useRegisterAsCheckedTask();
  const { startupId } = useSession();
  const { translate } = useTranslate();
  const { closeModal } = useModal();

  const handleClose = () => {
    closeModal()
  }

  const handleSubmit = async () => {
    await removePendingEntity({ startupId, registeredTaskData })
    handleClose()
  }

  useEffect(() => {
    setOpen(open)
  }, [open])
  
  return <ModalDelete open={isOpen}>
    <TemplateDelete
      className={classModule.template}
      props={{
        title: 'Please confirm this stakeholder doesn’t have any equity position.',
        description: <Typography component='p' color='error'>{'Don’t worry you can add equity positions as your startup grows by using the actions button.'}</Typography>,
      }}
      buttons={{
        submit: <Button variant='submitDelete' loading={!!loading} onClick={handleSubmit}>{translate('MODULES.MAP.MODAL_REMOVE_ESSENTIAL.CONFIRM')}</Button>,
        cancel: <Button variant='cancelSubmit' onClick={handleClose}>{translate('MODULES.MAP.MODAL_REMOVE_ESSENTIAL.CANCEL')}</Button>
      }}
    ><></></TemplateDelete>
  </ModalDelete>
}
export default ModalRemoveTask
import React, { FC } from 'react';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

interface Props {
  label: string;
  style?: any;
}

const GeneralLoading: FC<Props> = ({ label, style }) => {

  return (
    <>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          position: 'relative',
          overflow: 'hidden',
          backgroundColor: "white",
          backgroundImage: 'url("/img/loadingBackground.svg")',
          backgroundRepeat: 'repeat-x',
          backgroundPosition: '0 0',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            left: '0px',
            top: '0px',
            right: '0px',
            width: "100%",
          }}
        >
          <LinearProgress />
        </Box>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          sx={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src="/img/clara_logo.svg" alt="Clara" style={{ width: "140px"}}/>
        </Stack>
      </Box>
    </>
  );
}

export default GeneralLoading;

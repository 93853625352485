import React, { useState, useEffect } from 'react';
import _ from 'lodash'


const Category = ({ data, categories, ...props }) => {

    const [labelCategory, setLabelCategory] = useState('');

    const findCategoryLabel = (categories, subCategory) => {
        const category = _.find(categories, cat => _.some(cat.subCategories, subCat => subCat.key === subCategory));
        return _.get(category, 'title');
    }

    useEffect(() => {
        if (categories.length) {
            setLabelCategory(findCategoryLabel(categories, _.get(data, "original.subCategories[0]")))
        }

    }, [JSON.stringify(categories)])

    return (
        <div>
            <span>{labelCategory}</span>
        </div>
    )
}


export default Category;
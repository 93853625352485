import _ from 'lodash'
import useSession from "src/modules/session/hooks/useSession";
import useLazyQuery from "src/hooks/custom/useLazyQuery";
const CONNECTIONS=`
query getGroupCompanyConnections($startupId:ID!, $groupCompanyId:ID!){
getGroupCompany(startupId:$startupId, groupCompanyId:$groupCompanyId){
id
name
kind
managedByClara
documentAsParties{
    id
    name
}
formationDataConnections{
assetsAndRevenue{
id name
}
}
areThereEquityConnections
dueDiligences{
id
title
}
}
}
`
const useGetConnections=(variables,config?,props?)=>{
    const {data,loading,manualQuery}=useLazyQuery(CONNECTIONS,variables,config,props)
    return manualQuery;
}
export default useGetConnections
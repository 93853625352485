import React, {Component} from 'react';
import classes from "./AddInput.module.scss";
import {Field} from "formik";
import _ from "lodash"

class AddInputFull extends Component {
    state = {
        components: [],
        values: [],
        resetForm: false,
        enabledRender:false,
        
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        let newForm = {..._.get(nextProps,"formikProps.form",{})};
        let currentForm = {..._.get(this.props,"formikProps.form",{})};
        delete newForm.values;
        delete currentForm.values;
        delete newForm.errors;
        delete currentForm.errors;
        const newFormString = JSON.stringify(newForm);
        const currentFormString = JSON.stringify(currentForm);
        if (newFormString  !== currentFormString) {
            return true;
        }
        if (nextState.enabledRender){
            this.setState({
                enabledRender:false
            })
            return true;
        }
        return false;

    }
    updateForm = (resetForm, valuesForm, i) => {
        if (!resetForm) {
            let { values } = this.state;
            let currentValue = eval("this.props.formikProps.form.values." + this.props.formikProps.name)
            if (currentValue) {
                if (JSON.stringify(currentValue[i]) !== JSON.stringify(valuesForm)) {
                    values[i] = valuesForm;
                    this.props.formikProps.form.setFieldValue(this.props.formikProps.name, values);
                    this.setState({
                        values
                    })
                }
            }
        }

    }
    removeElement = (i) => {
        let  values  = _.get(this.props,"formikProps.form.values["+this.props.fieldArrayName+"]",[]);
        values.splice(i, 1);

        this.setState({
            values: values,
            resetForm: true,
            enabledRender:true
        }, () => {
            this.props.formikProps.form.setFieldValue(this.props.formikProps.name, values);
        })

    }
    addElement = () => {
        let  values  = _.get(this.props,"formikProps.form.values["+this.props.fieldArrayName+"]",[]);
        values.push({});
        this.props.formikProps.form.setFieldValue(this.props.formikProps.name, values);
        this.setState({
            values,
            enabledRender:true
        })
        

    }
    componentWillMount(nexProps, nextState) {
        let values = [];

        try {
            values = _.get(this.props,"formikProps.form.values["+this.props.fieldArrayName+"]",[]);
        } catch (e) {

        }
        if (!values) {
            values = [];
        }
        this.setState({
            values,
            resetForm: false
        })
    }
    showRemove(values) {

        if (this.props.min) {
            return values.length > this.props.min;
        }
        return true;
    }

    showAdd(values) {

        if (this.props.max) {
            return values.length < this.props.max;
        }
        return true;
    }

    componentWillUpdate() {
        
    }
    componentDidMount() {
        if (this.props.min) {
            let { values } = this.state;
            let long = values ? values.length : 0;
            for (var i = 0; i < (this.props.min - long); i++) {
                this.addElement();
            }
        }
    }
    render() {

        let { resetForm } = this.state;
        let values = _.get(this.props,"formikProps.form.values["+this.props.fieldArrayName+"]",[]);
        
        const {fieldArrayName} = this.props
        const valuesState = values;
        if (this.props.min) {
            let long = valuesState ? valuesState.length : 0;
            for (var i = 0; i < (this.props.min - long); i++) {
                this.addElement();
            }
        }
        return (
            <div>
                {valuesState.map((element, i) => {
                    return (
                        <div className={classes.AddInputFull} key={i}>
                            {this.showRemove(valuesState) ? (
                                <Field>
                                    {({ field, form }) => (
                                        <a onClick={(e) => { this.removeElement(i) }} className={`${classes.Remove}`}>Remove</a>
                                    )}
                                </Field>
                            ) : null}
                            {React.createElement(this.props.children, { "index": i, "values": values, "preFieldKey":`${fieldArrayName}[${i}]` })}
                        </div>
                    )
                })}

                {this.showAdd(valuesState) &&
                    <div onClick={() => this.addElement()} className={classes.AddButtonFull}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18Z" stroke="#2C74FF" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M12 9.37549V14.6247" stroke="#2C74FF" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M14.6246 12.0001H9.37539" stroke="#2C74FF" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <p>{this.props.label}</p>
                    </div>
                }

            </div>
        );
    }
}

export default AddInputFull;

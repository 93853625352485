import React from 'react'
import * as Yup from 'yup'
import Form from '../../../../../../components/forms/Form/Form'
import CalendarInputDate from '../../../../../../components/inputs/Calendars/CalendarInputDate'
import { Constants } from '../../../../../../v1/utils'
import SubmitButton from '../../../../../generic/components/Buttons/SubmitButton'
import FileUpload from "../../../../../generic/components/Inputs/FileUpload"
import ControllerInput from '../../../../../generic/components/Inputs/ReactHookForm/ControllerInput'
import FormProps from '../../../../../generic/forms/interfaces/FormProps'
import useTranslate from '../../../../../generic/hooks/useTranslate'
import TemplateLabel from '../../../../../generic/templates/Label'
import TemplateFormDefault from "../../../../../generic/templates/Modal"
import LeftContentBlock from '../../components/LeftContentBlock'
import RightContentBlock from '../../components/RightContentBlock'
import cls from './classes.module.scss'

const schema = Yup.object().shape({
    files: Yup.array().min(1),
    expiryDate: Yup.string().required("This Field Required").nullable().matches(Constants.REGEXP.DATE, { message: "This field is invalid", excludeEmptyString: true })
})


interface FormPropsCustom extends FormProps {
    text: string,
    image?: any
    limit?:number
}


const FormCurrentSelect: React.FC<FormPropsCustom> = ({
    initialValues = {},
    onCompleteSubmit,
    Template = TemplateFormDefault,
    buttons = {},
    buttonSubmitProps,
    image,
    limit,
    propsTemplate
}) => {

    const { translate } = useTranslate();
    const handleSubmit = async values => {
        onCompleteSubmit && await onCompleteSubmit(values);
    }

    return (
        <Form
            defaultValues={initialValues}
            schema={schema}
            onSubmit={handleSubmit}
            optionsForm={{ mode: "onChange" }}
            onlySendDirty={false}
        >

            <Template buttons={{ submit: <SubmitButton  {...buttonSubmitProps}>Add</SubmitButton>, ...buttons }} props={propsTemplate}>
                <div className={cls.ContainerForm}>


                    <LeftContentBlock >

                        <TemplateLabel
                            label={'Expiry date'}
                            className={cls.TemplateLabel}
                            isRequired
                        >
                            <ControllerInput
                                render={CalendarInputDate}
                                name='expiryDate'
                                placeholder={'dd/mm/yyyy'}
                                defaultValue={null}

                            />
                        </TemplateLabel>


                    </LeftContentBlock>

                    <RightContentBlock title={"Upload"}>
                        <ControllerInput
                            render={FileUpload}
                            name='files'
                            defaultValue={[]}
                            limit={limit}
                        />
                    </RightContentBlock>

                </div>
            </Template>
        </Form>
    )
}

export default FormCurrentSelect




import React, {useEffect, useState} from 'react';
import _ from "lodash";
import {updateParamsBreadCrumbs} from "../../../store/actions/page";
import {Loading, LogFilters, Text, TimeLine} from '../../components';
import classes from "./AuditLogs.module.scss"
/* Import Hooks */
import useAuditLogs from '../../Hooks/useAuditLogs';
import {Waypoint} from 'react-waypoint';
import {useDispatch} from "react-redux";
import useGetMe from "../../../hooks/services/modules/AuditLogs/useGetMe";


export default function ({  }) {
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({});
  let { timeLine, loading, hasNextPage } = useAuditLogs(page, filters);
  const {data:userData,loading:loadingUser}=useGetMe()
  const dispatch=useDispatch()
  const handleInfinitesCroll = ()=>{
    if (hasNextPage){
      setPage(page + 1);
    }
  }
  let updateFilters = function (values) {
    const newValue = {
      groupCompanies:_.get(values, "stakeholdersOrGroupCompanies.0.groupCompanies", []),
      stakeholders:_.get(values, "stakeholdersOrGroupCompanies.0.stakeholders", []),
      startups: _.get(values, "startups.0.startups", []),
      transactions: _.get(values, "transactions.0.transactions", []),
      dateFrom: _.get(values, "dateFrom", null),
      dateTo: _.get(values, "dateTo", null),
    }
    if (!_.isEqual(newValue, filters)) {
      setFilters(newValue)
      setPage(0)
    }

  }

  return (
    <React.Fragment>
      <div className={classes.AuditLogs}>
        <h1 className={classes.header}><Text uuid={'AUDIT_LOG_HEADER'}/></h1>
        <div className={classes.container}>
        <div className={classes.ZoneMenu}>
          <h2><Text uuid={"AUDITLOGS_FILTER"} /></h2>
          <p><Text uuid={"AUDITLOGS_FILTER_INFO"} /></p>
          <LogFilters updateFilters={updateFilters} session={userData} />
        </div>
        <div className={classes.ZoneTimeLine}>
          <h1><Text uuid={"AUDITLOGS_TITLE_TIME_LINE"} /></h1>
          <div className={`${classes.InfiniteScroll} scrollbar`} >
            <TimeLine timeLine={timeLine} />
            {!loading || !loadingUser?(
              <Waypoint
              onEnter={() => { handleInfinitesCroll(); }}
              bottomOffset='0%' />
            ):<Loading show={loading || loadingUser} inline={true} label={"LOADING"} />}
          </div>
        </div>
        </div>
      </div>
    </React.Fragment>
  );
}
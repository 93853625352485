import _ from 'lodash';
import useQuery from '../../../../../hooks/custom/useQuery';

export const GET_POSITIONS = `
query getPositions($startupId: ID!) {
  getPositions(startupId: $startupId) {
    id 
    code
    name
    isDefault
    responsibilities {
        code
        id
        isDefault
        name
        text
    }
  }
}
`;

const useGetPositions = (variables) => {
	const { loading, error, data, refetch } = useQuery(GET_POSITIONS, variables);
	return { loading, error, data: _.get(data, 'getPositions', []), refetch };
};

export default useGetPositions;

import { Field } from "formik";
import _ from "lodash";
import moment from "moment";
import React, { Component } from 'react';
import { ClaraInputDate, ClaraInputYearsMonths, TextAreaInput, TextInput,TextType } from '../../../../../../../v1/components';
import ClaraCountrySelector from "../../../../../../../v1/components/Form/ClaraCountrySelector/ClaraCountrySelector";
import ClaraRadioWithButton from '../../../../../../../v1/components/Form/ClaraRadioWithButton/ClaraRadioWithButton';
import ClaraSelect from "../../../../../../../v1/components/Form/ClaraSelect/ClaraSelect";
import ClaraUpload from "../../../../../../../v1/components/Form/ClaraUpload/ClaraUpload";
import Checkbox from "../../../../../../../v1/containers/Forms/Inputs/Checkbox";
import FormikHelper from "../../../../../../../v1/utils/formikHelper";
import Line from "../../../../../../generic/Line/Line";
import AuthorisedSignatories from "../../../../../../inputs/AuthorizedSignatories/AuthorizedSignatories";
import classes from "../Styles.module.scss";
import AddStakeHolder from '../../../../../../../v1/containers/Forms/subForms/AddStakeHolder';
import SelectVestingTerms from "../../../../../../inputs/Select/SelectVestingTerms";
import {
	groupCompanyRecipientNotice, stakeholderAuthorizedSignatory, stakeholderRecipientNotice
} from '../../../../../../../v1/containers/Forms/generateDocumentForm/FormsGenerate/helper';

class GrantAgreementDoc extends Component {

    constructor(props) {
        super(props);
        this.state = {
            typeSelected: undefined,
            participantSelected: undefined,
            groupCompanySelected: undefined,
            authorizedSelected: undefined,
            authorizedSignatories: [],
            authorizedSignatoriesList: [],
            documentTypes: [],
            documentTypesList: [],
            dynamicParams: [],
            date: undefined,
            responsibilities: [],
            isAdd:false
        }
    };

    componentWillMount() {
        if (this.props.values) {
            const values = this.props.values.values ? this.props.values.values : this.props.values;
            this.setState({
                typeSelected: values.documentType,
                participantSelected: values.participant,
                groupCompanySelected: values.company,
                authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.company).map(as => ({
                    id: as.id,
                    label: as.label
                }))
            });
        }

    }

    selectType = (documentType, extraParams, form) => {
        this.setState({
            typeSelected: documentType,
            dynamicParams: this.getDynamicParamsByType(documentType)
        }, () => {
            FormikHelper.setValueInTheCorrectPosition('terms', form, []);
            FormikHelper.setValueInTheCorrectPosition('documentType', form, documentType);
            this.state.dynamicParams.forEach((dynamicParam, i) => {
                FormikHelper.setValueInTheCorrectPosition(
                    `terms[${i}].key`,
                    form,
                    dynamicParam.key || 'NO_KEY'
                );
            })
        });
    };

    getDynamicParamsByType(documentType) {
        const type = this.props.documentTypes.filter(agrType => agrType.code === documentType)[0] || {};
        return type.fields || [];
    }

    getSips = () => {

        return _.map(this.props.documentList, doc => ({
            id: doc.id,
            label: doc.name
        }))

    };

    selectGroupCompany = (shareIncentivePlanId, extraParams, form) => {
        const groupCompany = this.findGroupCompanyBySip(shareIncentivePlanId);
        const { documentShares} = this.props;
        const sipData = _.find(this.props.documentList, sip => {
            return sip.id === shareIncentivePlanId
         });

        let remainingShares = _.get(sipData, 'params.terms.shareCapital.numberOfSIPShares') - (_.get(sipData, 'params.terms.shareCapital.allocatedShares',0) - parseInt(_.get(this, 'props.documentShares')))

        remainingShares = remainingShares < 0 ? 0 : remainingShares;

        this.setState({
            groupCompanySelected: groupCompany
        });

        if (groupCompany) {
            if (!_.get(groupCompany, 'registeredAddress')) {
                _.set(groupCompany, 'registeredAddress', {})
            }
            let authorizedSignatories = _.get(form, 'values.company.authorizedSignatories', []);
            const newFormValues = {
                ...form.values,
                company: {
                    id: groupCompany.id,
                    name: groupCompany.name || '',
                    country: _.get(groupCompany, 'country.code', '') || _.get(groupCompany, 'country', ''),
                    jurisdiction: _.get(groupCompany, 'jurisdiction', ''),
                    registeredNumber: groupCompany.registeredNumber || '',
                    type: groupCompany.type || '',
                    registeredAddress: {
                        ...groupCompany.registeredAddress, country: _.get(groupCompany, 'registeredAddress.country.code', '') || _.get(groupCompany, 'registeredAddress.country', '')
                    },
                    authorizedSignatories: authorizedSignatories,
					recipientNotice: groupCompanyRecipientNotice(form.values.company, groupCompany)
                },
                terms: {
                    ...(form.values.terms ? {
                        ...form.values.terms,
                        vesting:{
                            ...(form?.values?.terms?.vesting  || {}),
                            id:form?.values?.terms?.vesting?.vestingTermId
                        },
                        shareIncentivePlan: {
                            id: shareIncentivePlanId
                        }, grant: {
                            ..._.get(form, "values.terms.grant", {}),
                            sipValidationData: {remaining: remainingShares, name: sipData.name}
                        }
                        
                    }
                        : {
                            shareIncentivePlan: {
                                id: shareIncentivePlanId
                            }
                        })
                }
            };

            form.setValues(newFormValues);

            this.setState({
                groupCompanySelected: groupCompany,
            })
        } else {
            form.setValues({
                ...form.values,
                company: {},
                // shareIncentivePlan: null,
                terms: {
                    ...form.values.terms,
                    shareIncentivePlan: {}
                }
            });
        }
    };

    findGroupCompanyBySip(shareIncentivePlanId) {
        
            const document = _.find(this.props.documentList, doc => doc.id === shareIncentivePlanId);
            return this.findGroupCompany(_.get(document, 'params.company.id'));
       
    };

    findGroupCompany(gcId) {
        return this.props.groupCompaniesList.find(gc => gc.id === gcId);
    };

    selectParticipant = (participant, extraParams, form, cb) => {
        const stakeholder = this.findStakeholder(participant);
        this.setState({
            participantSelected: stakeholder,
        });

        if (stakeholder) {
            let authorizedSignatory = {};

            if (!stakeholder.address) {
                _.set(stakeholder,'address', {})
            }

            const _participant = !_.isEmpty(form.values.participant) ? form.values.participant : stakeholder;

            if (stakeholder.isAnEntity) {
				authorizedSignatory = stakeholderAuthorizedSignatory(_participant);
                // authorizedSignatory = {
                //     fullName: typeof _participant.authorizedSignatory === 'object'
                //         ? _.get(_participant, 'authorizedSignatory.fullName', '')
                //         : _.get(_participant, 'authorizedSignatory', ''),
                //     email: _participant.email || ''
                // }
            }

            form.setValues({
                ...form.values,
                participant: {
                    id: participant,
                    fullName: _participant.fullName || '',
                    email: _participant.email || '',
                    passportNumber: _participant.passportNumber || '',
                    registeredNumber: _participant.registeredNumber || '',
                    nationality: _.get(_participant, 'nationality.code', '') || _.get(_participant, 'nationality', ''),
                    jurisdiction: _.get(_participant, 'jurisdiction', ''),
                    type: _participant.type || '',
                    address: { ..._participant.address, country: _.get(_participant, 'address.country.code', '') || _.get(_participant, 'address.country', '') },
                    isAnEntity: _participant.isAnEntity,
                    authorizedSignatory: authorizedSignatory,
					recipientNotice: stakeholderRecipientNotice(form.values.participant, stakeholder)
                }
            });
        } else {
            form.setValues({
                ...form.values,
                participant: {}
            });
        }
    };

    findStakeholder(roleId) {
        return this.props.stakeholderList.find(stakeholder => stakeholder.id === roleId);
    };

    findStakeholderAuthorized(roleId) {
        return this.props.stakeholderList.filter(stakeholder => {
            return stakeholder.roles.some(role => role.stakeholder.id === roleId);
        })[0] || null;
    };

    filterAuthorizedByGroupCompany(groupCompany) {
        if (groupCompany) {
            let gc = this.findGroupCompany(groupCompany.id);
            return this.props.authorizedSignatories.filter(authorized => {
                return authorized.groupCompanyId === gc.id
            });
        }
        return [];
    }

    selectAuthorized = (value, form, field, replace) => {
        const stakeholder = this.props.authorizedSignatories.find(e => e.id === value);
        const email = stakeholder ? stakeholder.email : '';
        replace(0, { id: value, email })
    };

    renderParticipantAuthorizedSignatory(field) {
        const { tooltips = {} } = this.props;

        if (this.state.participantSelected.isAnEntity) {
            return (
                <React.Fragment>
                    <Field name='participant.authorizedSignatory.fullName'>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='text'
                                label='Corporate signatory '
                                classNameInput={{ root: classes.ContainerRow }}
                                field={field}
                                form={form}
                                placeholder={"Corporate signatory"}
                                tooltip={tooltips["Authorised Signatory"]}
                            />
                        )}
                    </Field>
                    <Field name='participant.email'>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='email'
                                classNameInput={{ root: classes.ContainerRow }}
                                label="Corporate signatory's email address "
                                field={field}
                                form={form}
                                onChange={(e) => this.updateEmailValue(e, form)}
                                placeholder={"Email address"}
                                tooltip={tooltips['Authorised Signatory Email']}
                            />
                        )}
                    </Field>
                </React.Fragment>
            )
        }
    };

    updateEmailValue(e, form) {
        if (this.state.participantSelected.isAnEntity) {
            form.setFieldValue('participant.authorizedSignatory.email', e.target.value, false)
        }
    }

    getDateForPickerFromData(date) {
        if (!date) return undefined;
        return moment(date).toDate();
    }

    handleChange(e, field, form) {

        const date = e === null ? null : moment(e).format("YYYY-MM-DD");

        FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

        this.setState({
            [field.name]: e
        })
    }

    getAuthorizedSignatoriesList = (index, form) => {
        if (form.values && form.values.company) {
            return this.filterAuthorizedByGroupCompany(form.values.company).map(as => ({
                id: as.id,
                label: as.label
            }))
        } else {
            return [];
        }
    };

    renderVestingTermsFields(isDisabled){
        const { tooltips = {} } = this.props

        return <>
        <Field name='terms.vesting.vestingPeriod'>
        {({ field, form }) => (
            <div className={`${classes.ContainerRow}`}>
                <TextInput
                    field={field}
                    disabled={isDisabled}
                    form={form}
                    label={'Vesting period'}
                    required={true}
                    rightLabel={"Months"}
                    tooltip={tooltips["Vesting Period"]}
                /></div>
        )}
    </Field>
    <Field name='terms.vesting.vestingFrequency'>
        {({ field, form }) => {
            return (
                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                    <ClaraSelect
                        label={'Vesting frequency '}
                        required={true}
                        field={field}
                        disabled={isDisabled}
                        form={form}
                        mode={'classic'}
                        placeholder={'Select from the list'}
                        tooltip={tooltips['Vesting Frequency']}
                        lists={[
                            {
                                id: 'MONTHLY',
                                label: 'Monthly',
                            },
                            {
                                id: 'QUARTERLY',
                                label: 'Quarterly',
                            },
                            {
                                id: 'ANNUALY',
                                label: 'Annually',
                            }
                                                                            ]}
                                            />
                                        </div>
                                    )
                                }}
                            </Field>
                            <div className={classes.alignWithInputDate}>
                                <Field name={'terms.vesting.isThereCliff'}>
                                    {({ field, form }) => (
                                        <ClaraRadioWithButton
                                            required={true}
                                            label={'Is there a cliff period? '}
                                            field={field}
                                            form={form}
                                            disabled={isDisabled}
                                            tooltip={tooltips['Cliff Period']}
                                            changeParams={field.name}
                                            options={[{ value: true, label: 'Yes' }, { value: false, label: 'No' }]}
                                            classes={{ root: classes.alignWithInputDate, input: classes.alignWithInputDateButton }}
                                            onChange={(v) => this.handleOnChangeIsThereCliff(v, form)}
                                        />
                                    )}
                                </Field></div>
                            <Field name='terms.vesting.cliffPeriod'>
                                {({ field, form }) =>
                                    _.get(form, 'values.terms.vesting.isThereCliff') ?
                                        (
                                            <div className={`${classes.ContainerRow}`}>
                                            <TextInput
                                                field={field}
                                                form={form}
                                                disabled={isDisabled}
                                                label={'How long is the cliff period?'}
                                                required={true}
                                                rightLabel={"Months"}
                                            /></div>
                                        )
                                        : null}
                            </Field>
</>

    }

    getLabel = (key) => {
        switch (key) {
            case "fullName":
                if (this.state.participantSelected.isAnEntity) {
                    return "Company name";
                } else {
                    return "Name";
                }
            case "country":
                if (this.state.participantSelected.isAnEntity) {
                    return "Country of incorporation";
                } else {
                    return "Nationality";
                }
            case "address":
                if (this.state.participantSelected.isAnEntity) {
                    return "Registered address";
                } else {
                    return "Residential address";
                }
            case "passportOrRegistered":
                if (this.state.participantSelected.isAnEntity) {
                    return "Company number";
                } else {
                    return "Passport number";
                }
        }
    };

    getField = (key) => {
        switch (key) {
            case "passportOrRegistered":
                if (this.state.participantSelected.isAnEntity) {
                    return "participant.registeredNumber";
                } else {
                    return "participant.passportNumber";
                }
        }
    };

    getTooltip = (key, fieldName) => {
        switch (key) {
            case "fullName":
                if (this.state.participantSelected.isAnEntity) {
                    return "Participant's Company's Name";
                } else {
                    return "Participant's Name";
                }
            case "passportOrRegistered":
                if (this.state.participantSelected.isAnEntity) {
                    return "Company Number";
                } else {
                    return "Passport Number";
                }
            case "country":
                if (this.state.participantSelected.isAnEntity) {
                    return "Country";
                } else {
                    return "Nationality";
                }
            case "addressLine":
                if (this.state.participantSelected.isAnEntity) {
                    return "Registered Address - Address";
                } else {
                    return "Residential Address - Address";
                }
            case "addressCity":
                if (this.state.participantSelected.isAnEntity) {
                    return "Registered Address - City";
                } else {
                    return "Residential Address - City";
                }
            case "addressState":
                if (this.state.participantSelected.isAnEntity) {
                    return "Registered Address - State";
                } else {
                    return "Residential Address - State";
                }
            case "addressCountry":
                if (this.state.participantSelected.isAnEntity) {
                    return "Registered Address - Country";
                } else {
                    return "Residential Address - Country";
                }
            case "addressPostCode":
                if (this.state.participantSelected.isAnEntity) {
                    return "Registered Address - Post Code";
                } else {
                    return "Residential Address - Post Code";
                }
            case "recipientNotice":
                if (fieldName === 'company') {
                    return "Company's";
                } else {
                    return "Participant's";
                }
        }
    };

    getPlaceholder = (key) => {
        switch (key) {
            case "passportOrRegistered":
                if (this.state.participantSelected.isAnEntity) {
                    return "Company number";
                } else {
                    return "Passport number";
                }
        }
    };

    renderRecipientNotice(fieldName, isAnEntity) {
        const { tooltips = {} } = this.props;

        if (isAnEntity) {
            return (
                <React.Fragment>
                    <Field name={`${fieldName}.recipientNotice.fullName`}>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='text'
                                classNameInput={{ root: classes.ContainerRow }}
                                label='Recipient for notices '
                                field={field}
                                form={form}
                                placeholder={"Recipient's name"}
                                tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices"]}
                            />
                        )}
                    </Field>
                    <div className={` ${classes.Select}`}>
                        <Field name={`${fieldName}.recipientNotice.address.country`}>
                            {({ field, form }) => (
                                <ClaraCountrySelector
                                    jurisdictionsEnabled={false}
                                    isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                    countryField={`${fieldName}.recipientNotice.address.country`}
                                    label={"Recipient's address "}
                                    setFieldValue={form.setFieldValue}
                                    initialValues={{ country: _.get(form.values, `${fieldName}.recipientNotice.address.country`, '') }}
                                    returnCountryAsString={_.isEmpty(_.get(form.values, `${fieldName}.recipientNotice.address.country.code`, ''))}
                                    additionalStyle={'ClaraCountrySelectorModal'}
                                    tooltips={{
                                        country: tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices - Country"]
                                    }}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.address.street`}>
                            {({ field, form }) => (
                                <TextAreaInput
                                    type='text'
                                    label={' '}
                                    field={field}
                                    form={form} placeholder={"Address"}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Address"]}
                                />
                            )}
                        </Field>
                    </div>
                    <Field name={`${fieldName}.recipientNotice.address.city`}>
                        {({ field, form }) => (
                            <TextInput
                                type='text'
                                classNameInput={{ root: classes.ContainerRow }}
                                field={field}
                                form={form}
                                placeholder={"City"}
                                label={' '}
                                tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - City"]}
                            />
                        )}
                    </Field>
                    <Field name={`${fieldName}.recipientNotice.address.state`}>
                        {({ field, form }) => (
                            <TextInput
                                type='text'
                                field={field}
                                classNameInput={{ root: classes.ContainerRow }}
                                form={form}
                                placeholder={"State / Region"}
                                label={' '}
                                tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - State"]}
                            />
                        )}
                    </Field>
                    <Field name={`${fieldName}.recipientNotice.address.zipCode`}>
                        {({ field, form }) => (
                            <TextInput
                                type='text'
                                classNameInput={{ root: classes.ContainerRow }}
                                field={field} form={form}
                                label={' '}
                                placeholder={"Post code"}
                                tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Post Code"]}
                            />

                        )}
                    </Field>
                    <Field name={`${fieldName}.recipientNotice.email`}>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='email'
                                classNameInput={{ root: classes.ContainerRow }}
                                label="Recipient's email address "
                                field={field}
                                form={form}
                                placeholder={"Email address"}
                                tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Email Address"]}
                            />
                        )}
                    </Field>
                </React.Fragment>
            )
        }
    };

    renderAgreementDurationFields(form) {
        const { tooltips = {} } = this.props;
        if (!_.get(form, 'values.terms.agreementDuration')) {
            return null
        }

        return (
            <React.Fragment>
                {
                    form.values.terms.agreementDuration === 'otherDuration' ?
                        <div className={classes.ContainerRow}>
                            <Field name='terms.otherDuration'>
                                {({ field, form }) => (
                                    <ClaraInputYearsMonths
                                        required={true}
                                        field={field}
                                        form={form}
                                        label={'Duration '}
                                        tooltips={tooltips}
                                    />
                                )}
                            </Field>

                        </div>
                        : null
                }
            </React.Fragment>
        );

    }

    getStakeholderAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add new corporate stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
                placeholder: "Stakeholder's name",
                startupId: this.props.startup.id,
				form: AddStakeHolder,
				formProps: { groupCompanies: this.props.groupCompanies },
				textToSave: "fullName",
                params: {
                    isAnEntity: true
                },
                paramsReturn: 'isAnEntity'
            },
            {
                type: "stakeholder",
                label: "Add new individual stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add more details later from the stakeholder's profile page.",
                placeholder: "Stakeholder's name",
                startupId: this.props.startup.id,
				form: AddStakeHolder,
				formProps: { groupCompanies: this.props.groupCompanies },
				textToSave: "fullName",
                params: {
                    isAnEntity: false
                },
                paramsReturn: 'isAnEntity'
            },
        ]
    };

    getCompanyAdds = () => {
        return [{
            type: "groupCompany",
            label: "Add new company",
            textWarning: "This will create a new group company for your startup. You can add or amend the details of this group company on its profile page.",
            placeholder: "Company name",
            startupId: this.props.startup.id,
            params: null,
            paramsReturn: `
				name
				authorizedSignatories {
					id
					stakeholder {
						id
						email
						fullName
						roles {   id
        name
        groupCompany { id name}
        stakeholder { id } }
					}
				}
			`
        }]
    };

    getAuthorizedSignatoryAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add new individual stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
                placeholder: "Enter stakeholder's name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: false,
                    roles: [{
                        role: "DIRECTOR",
                        groupCompany: this.state.groupCompanySelected.id,
                    }]
                }
            }
        ]
    };

    handleStakeholderCreate = (value, name, additionalValue, form) => {

        let { stakeholders, stakeholderList } = this.props;
        let newSh = { id: value.id, fullName: name, ...additionalValue };
        stakeholderList.push(newSh);

        stakeholders.push({ id: value.id, label: name });
        // this.setState({ groupCompaniesList, groupCompanies });

    };

    handleGroupCompanyCreate = (value, name, additionalValue, form) => {

        let { groupCompanies, groupCompaniesList } = this.props;
        let newGc = { id: value.id, name, ...additionalValue };
        groupCompaniesList.push(newGc);

        groupCompanies.push({ id: value.id, label: name });
        // this.setState({ groupCompaniesList, groupCompanies });

    };

    handleAuthorizedSignatoryCreate = (value, name, additionalValue, form) => {

        let { authorizedSignatories } = this.props;
        const { groupCompanySelected } = this.state;

        authorizedSignatories.push({
            id: value.id,
            label: name,
            role: 'DIRECTOR',
            groupCompanyId: groupCompanySelected.id
        });

    };

    handleOnChangeIsThereCliff = (v, form) => {
        if (!v) {
            FormikHelper.setValueInTheCorrectPosition('terms.vesting.cliffPeriod', form, null);
            FormikHelper.setValueInTheCorrectPosition('terms.vesting.isThereCliff', form, v);
        }
    };

    render() {
        const isRequired = message => value => (!!value ? undefined : message);
        const { tooltips = {}, formType } = this.props;
        
        return (
            <React.Fragment>
                <h1 className={classes.title}>
                    Edit Document
                </h1>
                {formType == 'uploadToEdit' ? (<div>
                    <Field name={`file`}>
                        {({ field, form }) => (
                            <ClaraUpload
                                required={true}
                                name={`filepond`}
                                label='Upload your document '
                                field={field}
                                form={form}
                            />
                        )}
                    </Field>
                </div>) : null}
                <Field name='agreementName'>
                    {({ field, form }) => (
                        <TextInput
                            required={true}
                            classNameInput={{ root: classes.ContainerRow }}
                            type='text'
                            label='Document name '
                            field={field}
                            form={form}
                            placeholder={"Document name"}
                            tooltip={tooltips['Document Name']}
                        />
                    )}
                </Field>

                <Line className={classes.line} />
                <div className={classes.ZoneTitle}>
                    <h1>{'Participant'}</h1>
                </div>
                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                    <Field name='participant.id'>
                        {({ field, form }) => (
                            <ClaraSelect
                                required={true}
                                field={field}
                                form={form}
                                label={"Select participant "}
                                lists={this.props.stakeholders}
                                adds={this.getStakeholderAdds()}
                                mode={"classic"}
                                callbacks={{
                                    updateValues: this.selectParticipant,
                                    createValues: this.handleStakeholderCreate
                                }}
                                placeholder={"Select from the list"}
                                tooltip={tooltips["Select Participant"]}
                            />

                        )}
                    </Field>
                </div>
                {
                    this.state.participantSelected ?
                        <React.Fragment>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                {
                                    this.state.participantSelected.isAnEntity ?
                                        <>
                                            <h1>Participant Company Details</h1>
                                            <p>Please verify or update the participant's company's information below</p>
                                        </>
                                        :
                                        <>
                                            <h1>Participant Details</h1>
                                            <p>Please verify or update the participant’s information below</p>
                                        </>
                                }
                            </div>
                            <Field name='participant.fullName'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        label={this.getLabel("fullName") + ' '}
                                        classNameInput={{ root: classes.ContainerRow }}
                                        field={field}
                                        form={form}
                                        placeholder={'Name'}
                                        required={true}
                                        tooltip={tooltips[this.getTooltip('fullName')]}
                                    />
                                )}
                            </Field>
                            <div className={`${classes.Select}`}>
                                <Field name='participant.nationality'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={this.state.participantSelected.isAnEntity}
                                            countryField={'participant.nationality'}
                                            label={this.getLabel('country') + ''}
                                            jurisdictionField={'participant.jurisdiction'}
                                            jurisdictionLabel={'Jurisdiction '}
                                            returnCountryAsString={true}
                                            otherJurisdictionField={'participant.otherJurisdiction'}
                                            setFieldValue={form.setFieldValue}
                                            initialValues={{ jurisdiction: _.get(form.values, 'participant.jurisdiction') }}
                                            additionalStyle={'ClaraCountrySelectorModal'}
                                            tooltips={{
                                                country: tooltips[this.getTooltip('country')],
                                                jurisdiction: tooltips['Jurisdiction'],
                                                other: tooltips['Other Jurisdiction']
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            {
                                this.state.participantSelected.isAnEntity ?
                                    <React.Fragment>
                                        <Field name='participant.type'>
                                            {({ field, form }) => (
                                                <TextInput
                                                    required={true}
                                                    type='text'
                                                    label='Company type '
                                                    classNameInput={{ root: classes.ContainerRow }}
                                                    field={field}
                                                    form={form}
                                                    placeholder={'Company type'}
                                                    tooltip={tooltips['Company Type']}
                                                />
                                            )}
                                        </Field>
                                    </React.Fragment>
                                    :
                                    null
                            }
                            <Field name={this.getField("passportOrRegistered")}>
                                {({ field, form }) => (
                                    <TextInput
                                        required={true}
                                        type='text'
                                        classNameInput={{ root: classes.ContainerRow }}
                                        label={this.getLabel("passportOrRegistered") + ' '}
                                        field={field}
                                        form={form}
                                        placeholder={this.getPlaceholder("passportOrRegistered")}
                                        tooltip={tooltips[this.getTooltip("passportOrRegistered")]}
                                    />
                                )}
                            </Field>
                            {
                                !this.state.participantSelected.isAnEntity ?
                                    <React.Fragment>
                                        <Field name='participant.email'>
                                            {({ field, form }) => (
                                                <TextInput
                                                    type='email'
                                                    label='Email address '
                                                    classNameInput={{ root: classes.ContainerRow }}
                                                    field={field}
                                                    form={form}
                                                    placeholder={'Email address'}
                                                    required={true}
                                                    tooltip={tooltips["Participant's Email Address"]}
                                                />
                                            )}
                                        </Field>
                                    </React.Fragment>
                                    :
                                    null
                            }
                            <div className={` ${classes.Select}`}>
                                <Field name='participant.address.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={false}
                                            returnCountryAsString={true}
                                            countryField={'participant.address.country'}
                                            label={this.getLabel('address') + ''}
                                            setFieldValue={form.setFieldValue}
                                            additionalStyle={'ClaraCountrySelectorModal'}
                                            tooltips={{
                                                country: tooltips[this.getTooltip('addressCountry')]
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='participant.address.street'>
                                    {({ field, form }) => (
                                        <TextAreaInput
                                            type='text'
                                            label={' '}
                                            field={field}
                                            form={form}
                                            placeholder={'Address'}
                                            tooltip={tooltips[this.getTooltip('addressLine')]}

                                        />
                                    )}
                                </Field>
                            </div>
                            <Field name='participant.address.city'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        label=' '
                                        classNameInput={{ root: classes.ContainerRow }}
                                        field={field}
                                        form={form}
                                        placeholder={'City'}
                                        tooltip={tooltips[this.getTooltip('addressCity')]}
                                    />
                                )}
                            </Field>
                            <Field name='participant.address.state'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        label=' '
                                        field={field}
                                        classNameInput={{ root: classes.ContainerRow }}
                                        form={form}
                                        placeholder={'State / Region'}
                                        tooltip={tooltips[this.getTooltip('addressState')]}
                                    />
                                )}
                            </Field>
                            <Field name='participant.address.zipCode'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        label=' '
                                        field={field}
                                        classNameInput={{ root: classes.ContainerRow }}
                                        form={form}
                                        placeholder={"Post code"}
                                        tooltip={tooltips[this.getTooltip('addressPostCode')]}
                                    />
                                )}
                            </Field>
                            {this.renderRecipientNotice('participant', this.state.participantSelected.isAnEntity)}
                            {this.renderParticipantAuthorizedSignatory('participant')}
                        </React.Fragment>
                        :
                        null
                }
                <Line className={classes.line} />
                <div className={classes.ZoneTitle}>
                    <h1>{'SIP Agreement'}</h1>
                </div>
                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                    <Field name='terms.shareIncentivePlan.id'>
                        {({ field, form }) => (
                            <ClaraSelect
                                required={true}
                                field={field}
                                form={form}
                                label={"SIP"}
                                lists={this.getSips()}
                                mode={"classic"}
                                callbacks={{
                                    createValues: this.handleGroupCompanyCreate,
                                    updateValues: this.selectGroupCompany
                                }}
                                placeholder={"Select from the list"}
                                tooltip={tooltips['Select Company']}
                            />
                        )}
                    </Field>
                </div>
                {
                    this.state.groupCompanySelected ?
                        <React.Fragment>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Company Details</h1>
                                <p>Please verify or update the company's information below</p>
                            </div>
                            <Field name='company.name'>
                                {({ field, form }) => (
                                    <TextInput
                                        required={true}
                                        type='text'
                                        classNameInput={{ root: classes.ContainerRow }}
                                        label='Company name '
                                        field={field}
                                        form={form}
                                        placeholder={"Company name"}
                                        tooltip={tooltips["Company's Name"]}
                                    />
                                )}
                            </Field>
                            <div className={` ${classes.Select}`}>
                                <Field name='company.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={true}
                                            countryField={'company.country'}
                                            returnCountryAsString={true}
                                            label={'Country of incorporation '}
                                            jurisdictionField={'company.jurisdiction'}
                                            jurisdictionLabel={'Jurisdiction '}
                                            otherJurisdictionField={'company.otherJurisdiction'}
                                            setFieldValue={form.setFieldValue}
                                            initialValues={{
                                                jurisdiction: _.get(form.values, 'company.jurisdiction', '')
                                            }}
                                            additionalStyle={'ClaraCountrySelectorModal'}
                                            tooltips={{
                                                country: tooltips["Company's Country"],
                                                jurisdiction: tooltips["Company's Jurisdiction"],
                                                other: tooltips['Other Jurisdiction']
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <Field name='company.type'>
                                {({ field, form }) => (
                                    <TextType
                                        required={true}
                                        type='text'
                                        groupCompany={this.state.groupCompanySelected}
                                        label='Company type '
                                        field={field}
                                        classNameInput={{ root: classes.ContainerRow }}
                                        form={form}
                                        placeholder={'Company type'}
                                        tooltip={tooltips["Company's Company Type"]}
                                    />
                                )}
                            </Field>
                            <Field name='company.registeredNumber'>
                                {({ field, form }) => (
                                    <TextInput
                                        required={true}
                                        type='text'
                                        label='Company number '
                                        field={field}
                                        classNameInput={{ root: classes.ContainerRow }}
                                        form={form}
                                        placeholder={'Company number'}
                                        tooltip={tooltips["Company's Company Number"]}
                                    />
                                )}
                            </Field>
                            <div className={`${classes.Select}`}>
                                <Field name='company.registeredAddress.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={false}
                                            countryField={'company.registeredAddress.country'}
                                            label={'Registered address '}
                                            returnCountryAsString={true}
                                            setFieldValue={form.setFieldValue}
                                            additionalStyle={'ClaraCountrySelectorModal'}
                                            tooltips={{
                                                country: tooltips["Company's Registered Address - Country"]
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.registeredAddress.street'>
                                    {({ field, form }) => (
                                        <TextAreaInput
                                            type='text'
                                            label={' '}
                                            field={field}
                                            form={form}
                                            placeholder={"Address"}
                                            tooltip={tooltips["Company's Registered Address - Address"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <Field name='company.registeredAddress.city'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        label=' '
                                        classNameInput={{ root: classes.ContainerRow }}
                                        field={field}
                                        form={form}
                                        placeholder={'City'}
                                        tooltip={tooltips["Company's Registered Address - City"]}
                                    />
                                )}
                            </Field>
                            <Field name='company.registeredAddress.state'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        label=' '
                                        field={field}
                                        form={form}
                                        classNameInput={{ root: classes.ContainerRow }}
                                        placeholder={'State / Region'}
                                        tooltip={tooltips["Company's Registered Address - State"]}
                                    />
                                )}
                            </Field>
                            <Field name='company.registeredAddress.zipCode'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        label=' '
                                        field={field}
                                        form={form}
                                        classNameInput={{ root: classes.ContainerRow }}
                                        placeholder={"Post code"}
                                        tooltip={tooltips["Company's Registered Address - Post Code"]}
                                    />
                                )}
                            </Field>
                            {this.renderRecipientNotice('company', true)}
                            <Line className={classes.line} />
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Corporate signatories</h1>
                                <p>Please add all the signatories required to sign this document on behalf of the company.</p>
                            </div>
                            <Field name='company.authorizedSignatories_error'>
                                {({ field, form }) => (
                                    <React.Fragment>
                                        <AuthorisedSignatories
                                            list={this.props.stakeholderList}
                                            fieldArrayName={'company.authorizedSignatories'}
                                            idGroupCompany={this.state.groupCompanySelected.id}
                                            handleAddListStakeholder={this.handleAuthorizedSignatoryUpdate}
                                            modal={true}
                                            errorMessage={_.get(form.errors, "company.authorizedSignatories")}
                                        />
                                    </React.Fragment>
                                )}
                            </Field>
                            <Line className={classes.line} />
                        </React.Fragment>
                        :
                        null
                }

                {
                    this.state.participantSelected && this.state.groupCompanySelected ?
                        <React.Fragment>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Grant</h1>
                                <p>The granting of the options to the participant.</p>
                            </div>
                            <Field name='terms.grant.sharesNumber'>
                                {({ field, form }) => (
                                    <TextInput
                                        classNameInput={{ root: classes.ContainerRow }}
                                        required={true}
                                        type='number'
                                        label='Number of shares '
                                        field={field}
                                        form={form}
                                        placeholder={'e.g. 10, 20'}
                                        tooltip={tooltips['Commencement Date']}
                                    />
                                )}
                            </Field>
                            <Field name='terms.grant.pricePerShare'>
                                {({ field, form }) => (
                                    <TextInput
                                        required={true}
                                        type='share'
                                        classNameInput={{ root: classes.ContainerRow }}
                                        label='Exercise price per share '
                                        field={field}
                                        form={form}
                                        placeholder={'Amount'}
                                        tooltip={tooltips['Price per Share']}
                                    />
                                )}
                            </Field>
                            <Line className={classes.line} />
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Vesting</h1>
                                <p>The vesting of the shares granted to the participant.</p>
                            </div>
                            <Field name='terms.vesting.id'>
                                {({ field, form }) => {
                                    return (
                            <SelectVestingTerms
                            value={field.value}
                            groupCompanyId={this.state.groupCompanySelected.id}
                            onChange={(value)=>{
                                if(value === 'ADD') {
                                    form.setFieldValue(`terms.vesting`,{id:value})
                                    return this.setState({
                                    showVestingTerms:true,
                                    isAdd:true
                                })}
                                form.setFieldValue('terms.vesting',{...value?.standardTerm,isThereCliff:!!value?.standardTerm?.isThereCliff,id:value?.id,vestingStartDate:form?.values?.terms?.vesting?.vestingStartDate})
                                this.setState({
                                    showVestingTerms:!!value,
                                    isAdd:false
                                })
                            }}
                            errorMessage={_.get(form.errors,'terms.vesting.id') || _.get(form.errors,'terms.vesting')}
                            />
                                )}}
                                </Field>
                                { this.state.showVestingTerms &&
                                        this.renderVestingTermsFields(!this.state.isAdd)
                                        }     
                                         <Field name={`terms.vesting.vestingStartDate`}>
                                {({ field, form }) => (
                                    <div className={classes.ContainerRow}>
                                        <TextInput
                                            required={true}
                                            type='date'
                                            label='Vesting start date  '
                                            field={field}
                                            form={form}
                                            placeholder={'dd/mm/yyyy'}
                                            />
                                    </div>
                                )}
                            </Field>                      
                                         <Line className={classes.line} />
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Place of Arbitration</h1>
                                <p>This contract’s dispute resolution mechanism uses arbitration under expedited ICC rules. Please select the place where you would like the arbitration to take place (please enter the name of the city and country and, if applicable, state or freezone), e.g. "London, United Kingdom" or "New York City, New York, United States of America” or "Dubai International Financial Centre, Dubai, United Arab Emirates”. Please note that the place of arbitration that you select can have a material impact on the proceedings and enforcement of the arbitration and arbitral award. Please seek legal advice.</p>
</div>
<Field name={`terms.placeOfArbitration`}>
    {({ field, form }) => (
        <TextInput
            required={true}
            type='text'
            label='Place of arbitration '
            field={field}
            classNameInput={{ root: classes.ContainerRow }}

            form={form}
            placeholder={"Place of Arbitration"}
        />
    )}
</Field>
                           
                        </React.Fragment>
                        : null
                }

                {formType === 'uploadToEdit' ? <div>
                    <Field name='confirmSameData'>
                        {({ field, form }) => (
                            <Checkbox
                                className={classes.sameDataCheckbox}
                                field={field}
                                validate={isRequired('This field is required')}
                                form={form}
                                required={true}
                                label='All document details are verified and correct.'
                            />
                        )}
                    </Field>
                </div> : null}
            </React.Fragment>

        );
    }

}

export default GrantAgreementDoc;

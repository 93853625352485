import React, { FC, ReactNode } from 'react';

import { Box } from '@material-ui/core';
import classes from './styles.module.scss';
interface Props {
  data: { mainItem: ReactNode; subItems: ReactNode[] }[];
  className?: string;
  paddingMainItem?: string;
  paddingSubItems?: string;
  color?: string;
  isFlexMainItem?: boolean
}

const NestedList: FC<Props> = ({
  data,
  className,
  paddingMainItem = '0rem',
  paddingSubItems = '1rem',
  color,
  isFlexMainItem = true
}) => {

  return (
    <>
      {data.map(({ mainItem, subItems }, index) => (
        <div key={index} className={className}>
          <Box padding={paddingMainItem} display={'flex'} width={'100%'} gridGap={'0.5rem'} className={classes[`text-color-${color}`]}>
            {`${String.fromCharCode(97 + index)}.`} {mainItem}
          </Box>
          <ol style={{margin:0}}>
            {subItems?.map((subItem, subIndex) => (
              <li 
                className={classes[`text-color-${color}`]}
                style={{paddingLeft: paddingSubItems, paddingTop: paddingSubItems, paddingBottom: paddingSubItems}}
                key={subIndex}
              >
                {subItem}
              </li>
            ))}
          </ol>
        </div>
      ))}
    </>
  );
};

export default NestedList;

import React from "react";
import { useTable, useExpanded, useRowState } from "react-table";
import classes from "./Table.module.scss";

const Table = ({
  columns: userColumns,
  headerGroups: useHeaderGroups,
  data,
  className = {},
  renderRowSubComponent = null,
  initialState,
  highlightItem = null,
}) => {
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { expanded },
    visibleColumns,
  } = useTable(
    {
      columns: userColumns,
      headerGroups: useHeaderGroups,
      data,
      initialState,
    },
    useExpanded, // Use the useExpanded plugin hook
    useRowState
  );

  return (
    <table
      {...getTableProps()}
      className={`${className.table} ${classes.table}`}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          const rowProps = row.getRowProps();

          return (
            // Use a React.Fragment here so the table markup is still valid
            <React.Fragment key={rowProps.key}>
              <tr
                {...rowProps}
                className={` ${
                  highlightItem && i === highlightItem
                    ? classes.highlightItem
                    : null
                } ${row.isExpanded ? classes.expandedRow : ""}`}
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}><div className={`${classes.dataContainer}`}>{cell.render("Cell")}</div></td>
                  );
                })}
              </tr>
              {/* We could pass anything into this */}
              {renderRowSubComponent && (
                <tr
                  className={`${classes.containerExtraRow} ${
                    row.isExpanded ? classes.openRow : classes.closeRow
                  }`}
                >
                  {row.isExpanded &&
                    renderRowSubComponent({ row, rowProps, visibleColumns })}
                </tr>
              )}
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;

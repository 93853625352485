import useCustomMachine from "../../../../../generic/hooks/useCustomMachine";
import React, { FC } from 'react'
import { StepProps } from '../../../../../generic/components/Wizard/Step'
import { CheckboxButtonOptions } from "../../../../../generic/components/Inputs/CheckboxButtonSimple";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import LastStepForm from "../../../core/wizards/steps/LastStep/LastStep";

const LastStep: FC<StepProps> = ({ }: StepProps) => {

  const { next } = useCustomMachine();
  const { translate } = useTranslate();

  const handleCompleteSubmit = async (values: any) => {
    next(values);
  }

  return (
    <React.Fragment>
      <LastStepForm tooltips={{ isNotAnother: translate('MODULES.ONBOARDINGROLES.EMPLOYEE.THIRD_STEP_NOT_ANOTHER_TOOLTIPS') }} role={'EMPLOYEE'} />
    </React.Fragment>
  )
}

export default LastStep;

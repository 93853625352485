import React from 'react';
import {Field, Formik} from "formik";
import classes from "../SteperForm.module.scss";
import {Constants} from "../../../utils";
import TextInput from "../Inputs/TextInput";
import * as Yup from "yup";


const AddIndividual = (props) => {
    const validationSchemaIndividual = Yup.object().shape({
        lastName: Yup.string().required("This field is required"),
        firstName: Yup.string().required("This field is required"),
        email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, { message:"This field must be a valid email", excludeEmptyString: true }).required("This field is required"),

    });
    const isDisabled = (errors, dirty) => {

        return !(dirty && Object.keys(errors).length === 0);
    }
    return (
        <Formik
            initialValues={{ firstName: "", lastName: "", email: "" }}
            enableReinitialize={false}
            onSubmit={async (values, actions) => {
                props.submit(values);

            }}
            validationSchema={validationSchemaIndividual}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({ values, errors, dirty, handleSubmit, }) => {

                return (
                    <React.Fragment>
                        <div className={`${classes.ContainerRow} ${classes.SubFormContainerRow}`}>
                            <Field name='firstName'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        field={field}
                                        form={form}
                                        data-zuko-name='First Names'
                                        placeholder={"First Names as per passport..."}
                                    />
                                )}
                            </Field>
                        </div>
                        <div className={`${classes.ContainerRow} ${classes.SubFormContainerRow}`}>
                            <Field name='lastName'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        field={field}
                                        form={form}
                                        data-zuko-name='Surname'
                                        placeholder={"Surname as per passport..."}
                                    />
                                )}
                            </Field>
                        </div>

                        <div className={`${classes.ContainerRow} ${classes.SubFormContainerRow}`}>
                            <Field name='email'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        field={field}
                                        form={form}
                                        placeholder={"Email Address..."}
                                    />
                                )}
                            </Field>
                        </div>
                        <div className={`${classes.ContainerRow}  ${classes.SubFormContainerButton}`}>
                            <button
                                type={"button"}
                                data-cy="submit"
                                className={`genericBlue ${classes.SubFormButton}`}
                                onClick={handleSubmit}
                                
                            >
                                Save
                                </button>
                        </div>
                    </React.Fragment >
                )
            }

            }
        </Formik>
    )

};

const AddIndividualCorporate = (props) => {
    const validationSchemaIndividualCorporate = Yup.object().shape({
        companyName: Yup.string().required("This field is required"),
        lastName: Yup.string().required("This field is required"),
        firstName: Yup.string().required("This field is required"),
        email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, { message:"This field must be a valid email", excludeEmptyString: true }).required("This field is required"),

    });

    const isDisabled = (errors, dirty) => {

        return !(dirty && Object.keys(errors).length === 0);
    }
    return (
        <Formik
            initialValues={{ companyName: "", firstName: "", lastName: "", email: "" }}
            enableReinitialize={false}
            onSubmit={async (values, actions) => {
                props.submit(values);

            }}
            validationSchema={validationSchemaIndividualCorporate}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({ values, errors, dirty, handleSubmit, }) => {

                return (
                    <React.Fragment>
                        <div className={`${classes.ContainerRow} ${classes.SubFormContainerRow}`}>
                            <Field name='companyName'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        field={field}
                                        form={form}
                                        data-zuko-name="Company Name"
                                        placeholder={"Company Name..."}
                                        tooltip={"The full legal name of the Company. This is the name under which the Company is registered on the companies registry in its jurisdiction of incorporation."}
                                    />
                                )}
                            </Field>
                        </div>
                        <div className={`${classes.ContainerRow} ${classes.SubFormContainerRow}`}>
                            <Field name='firstName'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        field={field}
                                        form={form}
                                        data-zuko-name="Authorised Signatory First Names"
                                        placeholder={"First Names..."}
                                    />
                                )}
                            </Field>
                        </div>
                        <div className={`${classes.ContainerRow} ${classes.SubFormContainerRow}`}>
                            <Field name='lastName'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        field={field}
                                        form={form}
                                        data-zuko-name="Authorised Signatory Surname"
                                        placeholder={"Surname..."}
                                    />
                                )}
                            </Field>
                        </div>

                        <div className={`${classes.ContainerRow} ${classes.SubFormContainerRow}`}>
                            <Field name='email'>
                                {({ field, form }) => (
                                    <TextInput
                                        type='text'
                                        field={field}
                                        form={form}
                                        data-zuko-name="Authorised Signatory Email"
                                        placeholder={"Email Address..."}
                                    />
                                )}
                            </Field>
                        </div>
                        <div className={`${classes.ContainerRow}  ${classes.SubFormContainerButton}`}>
                            <button
                                type={"button"}
                                data-cy="submit"
                                className={`genericBlue ${classes.SubFormButton}`}
                                onClick={handleSubmit}
                                
                            >
                                Save
                                </button>
                        </div>
                    </React.Fragment >
                )
            }

            }
        </Formik>
    )

};

export { AddIndividual, AddIndividualCorporate };

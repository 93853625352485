import Button from "./../../../../../../../modules/generic/components/Buttons/Button";
import ClaraAvatar from 'src/modules/generic/components/Avatars/ClaraAvatar';
import { Constants } from "../../../../../../../v1/utils/constants";
import Icon from "src/modules/generic/components/Icon"
import React, { useMemo } from "react";
import Text from '../../../../../../../components/text/Text/Text';
import Truncate from "../../../../../../generic/components/Texts/Truncate";
import Typography from "../../../../../../generic/components/Texts/Typography";
import classesModule from "./HeaderCardStakeholder.module.scss";
import { goToPage } from "./../../../../../../../store/actions/page";
import { useDispatch } from "react-redux";
import Tag from "src/v1/components/Tag/Tag";
import Box from '@mui/material/Box';
import _ from "lodash";

const HeaderCardStakeholder = ({
  stakeholderName,
  subtitle,
  avatar,
  lock,
  stakeholder,
  tasks
}) => {

  const dispatch = useDispatch();

  const isInactive = useMemo(() => {
    let result = false;
    if (stakeholder && stakeholder?.isActive === false) {
      result = true;
    }
    return result;
  }, [stakeholder]);

  const handleViewMore = () => {
    if (stakeholder.isAnEntity) {
      goToPage(dispatch, Constants.PAGES.viewStakeholderCompany, {
        id: stakeholder.id,
        tab: "details",
      });
    } else {
      goToPage(dispatch, Constants.PAGES.viewStakeholderIndividual, {
        id: stakeholder.id,
        tab: "details",
      });
    }
  };
  return (
    <React.Fragment>
      <div className={`${classesModule.HeaderCardStakeholder}`}>
        <span class={classesModule.Cursor} onClick={handleViewMore} >
          <ClaraAvatar
            name={stakeholderName}
            avatar={avatar}
            type={"circle"}
            locked={lock}
          >
          </ClaraAvatar>
        </span>
        <div className={classesModule.HeaderTitle}>
          <div>
            <span onClick={handleViewMore} class={classesModule.Cursor} >
              <Typography
                variant={"body"}
                weight={"bold"}
                color={"secondary"}
                component={"h1"}
              >
                <Truncate line={2} text={`${stakeholderName}`} />
              </Typography>
              <Box
                sx={{
                  with: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                  paddingTop: "4px",
                }}
              >
                {stakeholder.isFounder && (
                  <Tag type={"isFounderSquare"} show={true} marginLeft="0px" />
                )}
                {isInactive && (
                  <Tag type={"USER_INACTIVE"} show={true} marginLeft="0px" />
                )}
              </Box>
            </span>
            <Typography variant={"s"} weight={"light"} color={"dark-grey"}>
              <Truncate line={1} text={subtitle} />
            </Typography>
          </div>
          <div>
            <div className={classesModule.Icons}>
              <div className={classesModule.ZoneIcons}>
                <div>
                  <Icon isClara icon={"complete-and-incomplete"} size={"16px"} />
                  <Typography variant={"xs"} weight={"light"} color={"black"}>{tasks}</Typography>
                </div>
              </div>
              <div className={classesModule.ButtonViewProfie}>
                <Button variant={'card'} onClick={handleViewMore} >
                  <Text uuid={'BUTTON_VIEW_PROFILE'} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HeaderCardStakeholder;

import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';
import Icon from "src/modules/generic/components/Icon";
import React from 'react';
import { Box } from '@mui/material';

/**
/* This `Tag` function is a React component that renders a Chip component from the Material-UI library.
Here's a breakdown of what the function is doing:
 */
function Tag({ title, color, variant, generateColor, size, sx, prefix, ...props }) {

  return (
    <>
      <Chip
        sx={{
          backgroundColor: variant !== 'outlined' && "#eaf1fe !important",
          borderRadius: '50px !important',
          paddingLeft: '4px !important',
          paddingRight: '4px !important',
          minHeight: 'auto !important',
          ...sx
        }}
        size={size}
        label={(
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            {prefix && (
              <Box
                sx={{
                  paddingRight: "6px !important",
                }}
              >
                {prefix}
              </Box>
            )}
            {title}
          </Box>
        )}
        variant={variant === 'outlined' ? variant : ''}
        {...props}
        deleteIcon={(
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <Icon
              icon={"clara2023-delete"}
            />
          </Box>
        )}
      />
    </>
  );
}

Tag.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
  variant: PropTypes.oneOf(['outlined', 'filled']),
  generateColor: PropTypes.string,
  sx: PropTypes.object
};

Tag.defaultProps = {
  variant: 'outlined',
  size: 'small',
  sx: {},
  color: 'primary'
};

export default Tag;

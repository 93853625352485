const query = `{
	dueDiligence(startupId:"{{startup_id}}"){
    CORPORATE_STRUCTURE : corporateStructure  {
      ...subCategory
    }
    GOVERNANCE : governance {
      ...subCategory
    }
    EQUITY: equity {
      ...subCategory
    }
    TEAM: team {
      ...subCategory
    }
    INTELLECTUAL_PROPERTY: intellectualProperty {
      ...subCategory
    }
    OTHER: other {
      ...subCategory
    }
  }
}	
  fragment subCategory on DueDiligenceSubCategory {
    key
    name
    issues {
      id
      title
      description
      status
      createdBy {
      _id
        firstName
        lastName
      }
      updatedBy{
        fullName 
        firstName
        lastName
      }
      updatedAt
      createdAt
      countComments
      viewComments
    }
  }

`;
 /*const categories = {
  CORPORATE_STRUCTURE: {
    key: "corporateStructure",
    title: "Corporate Structure"
  },
  GOVERNANCE: {
    key: "governance",
    title: "Governance"
  },
  EQUITY: {
    key: "equity",
    title: "Equity"
  },
  TEAM: {
    key: "team",
    title: "Team"
  },
  INTELLECTUAL_PROPERTY: {
    key: "intellectualProperty",
    title: "Intellectual Property"
  },
  OTHER: {
    key: "other",
    title: "Other"
  }
};
 */
const Config = {
  query: query,
  categories: {}
};
export { Config };

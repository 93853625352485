import React, { useMemo } from "react";
import _ from "lodash";
import AddressInput from "../../../../../components/forms/modules/startups/individualStakeholder/AddressInput";
import Button from "../../../../../modules/generic/components/Buttons/Button";
import Checkbox from "../../../../inputs/Checkbox/Checkbox";
import ControllerInput from "../../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import Form from "../../../Form/Form";
import { HiddenFieldForm } from "../../../contents";
import Line from "../../../../generic/Line/Line";
import SubmitButton from "../../../../generic/Buttons/SubmitButton/SubmitButton";
import TemplateFormDefault from "../../../../../modules/generic/templates/Modal";
import TemplateLabel from "../../../../../modules/generic/templates/Label";
import Text from "../../../../text/Text/Text";
import TextInput from "../../../../inputs/Text/TextInput";
import { editNotice } from "../../../../../forms/schemas/modules/startups/groupCompany/schema";
import useTranslate from "../../../../../modules/generic/hooks/useTranslate";
import classes from "./NoticesForm.module.scss";

const NoticesForm = ({
  initialValues,
  handleSubmit,
  loading,
  handleCloseModal,
  propsTemplate,
  Template = TemplateFormDefault,
}) => {
  const { translate } = useTranslate();

  const newInitialValues = useMemo(() => {
    return {
      ...initialValues,
      useRegisteredAddress:
        _.get(initialValues, "useRegisteredAddress") === null
          ? false
          : _.get(initialValues, "useRegisteredAddress"),
    };
  }, [JSON.stringify(initialValues)]);

  return (
    <Form
      defaultValues={newInitialValues}
      schema={editNotice}
      onSubmit={handleSubmit}
      onlySendDirty={false}
      loading={loading}
    >
      <Template
        buttons={{
          submit: (
            <SubmitButton>
              {translate("MODULES.CLARA.BTN_SAVE_CHANGES")}
            </SubmitButton>
          ),
          cancel: (
            <Button onClick={() => handleCloseModal()} variant="secondary">
              {translate("MODULES.CLARA.BTN_CANCEL")}
            </Button>
          ),
        }}
        props={propsTemplate}
      >
        <TemplateLabel
          label={translate("MODULES.CLARA.FORM_LEGAL_NAME")}
          isRequired={true}
        >
          <ControllerInput
            render={TextInput}
            name="recipientNotice.fullName"
            defaultValue=""
            placeholder={translate("MODULES.CLARA.TYPE_HERE_HIT")}
          />
        </TemplateLabel>
        <TemplateLabel
          label={translate("MODULES.CLARA.FORM_EMAIL")}
          isRequired={true}
        >
          <ControllerInput
            render={TextInput}
            name="recipientNotice.email"
            placeholder={translate("MODULES.CLARA.TYPE_HERE_HIT")}
          />
        </TemplateLabel>
        <Line className="m-0" />
        <div className={classes.containerCheckbox}>
          <ControllerInput
            render={Checkbox}
            name="recipientNotice.useRegisteredAddress"
          />
          <label>
            <Text uuid="FORM_NOTICES_ADDRESS_NOTICES" />{" "}
          </label>
        </div>
        <Line className="m-0" />
        <HiddenFieldForm
          fieldsWatch="recipientNotice.useRegisteredAddress"
          conditionHideCallback={(value) => !value}
        >
          <AddressInput
            name="recipientNotice.address"
            label={{ country: "Recipient's Address" }}
            isRequired={true}
            zipCodeOptional
          />
        </HiddenFieldForm>
        <div>&nbsp;</div>
      </Template>
    </Form>
  );
};

export default NoticesForm;

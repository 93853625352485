import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Button from 'src/modules/generic/components/Buttons/Button';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import { clearAssistatText } from 'src/modules/generic/store/action';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import ConfirmDocumentsForm from '../forms/ConfirmDocumentsForm';

function ConfirmDocumentsStep() {
  const { next, cancel, context } = useMachine();
  const dispatch = useDispatch();

  const handleCancel = () => {
    cancel();
  }

  const handleSubmit = async (values) => {
    next(values);
  }

  useEffect(() => {
    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch]);

  return (
    <ConfirmDocumentsForm
      onCompleteSubmit={handleSubmit}
      Template={TemplateWizard}
      propsTemplate={{
        title: 'Upload required documents',
        subTitle: 'Confirm documents'
      }}
      link={context?.GetLink?.documentsLink}
      buttons={{
        cancel: <Button variant='secondary' onClick={handleCancel}>Cancel</Button>
      }}
    />
  );
}

export default ConfirmDocumentsStep;
import { useMemo } from 'react';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import CompanyDetailsInfo from '../steps/CompanyDetailsInfo';
import RegisteredAddress from '../steps/RegisteredAddress';

const useSteps = () => {
  return useMemo(() => ({
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    CompanyDetailsInfo: {
      render: CompanyDetailsInfo,
    },
    RegisteredAddress: {
      render: RegisteredAddress,
    },
    SaveStep: {
      render: TemplateWizardSkeletonMachine,
    },
  }), []);
}

export default useSteps;

import { FormHelperText } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

function FormHelperError({ helperText = null, errorMessage = null }) {
  return (
    <div style={{ marginLeft: '12px' }}>
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
      {helperText && !errorMessage && <FormHelperText>{helperText}</FormHelperText>}
    </div>
  );
}
export default FormHelperError;

FormHelperError.propTypes = {
  errorMessage: PropTypes.string,
  helperText: PropTypes.string
};

FormHelperError.defaultProps = {
  helperText: null
};

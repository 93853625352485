import React  from 'react';
import { Table } from '../../../../../../../../../v1/components';
import Button from "../../../../../../../../../modules/generic/components/Buttons/Button";
import useTranslate from '../../../../../../../../../modules/generic/hooks/useTranslate';
import useModal from "../../../../../../../../../modules/generic/hooks/useModal";
import TypographyLocal from "../../../../../../../../../modules/generic/components/Texts/Typography";
import ModalEditSingleQuestion from "../../../../../../../../../modules/accelerators/modals/editQuestion";
import useBusinessRules from "../../../../../../../../../modules/clara/hooks/useBusinessRules";
import classes from "./QuestionnaireTab.module.scss";
import _ from "lodash";

const QuestionnaireTab = ({ questionnaire }) => {
  const { translate } = useTranslate();
  const { openModal } = useModal();
  const { isViewer } = useBusinessRules();

  const fields = [
    {
      label: "",
      key: "question",
      order: false,
      "decorator": (props) => {
        const haveOptions = _.get(props, "row.questionDef.fields.yes_no", null) === null ? false : true;
        return (
          <>
            {haveOptions ? (
              <div className={classes.containerQuestion}>
                <TypographyLocal
                  className={classes.fontQuestion}
                >
                  {props.row.questionDef?.fields?.yes_no?.label}
                </TypographyLocal>
              </div>
            ) : (
              <div className={classes.containerQuestion}>
                <TypographyLocal
                  className={classes.fontQuestion}
                >
                  {props.row.questionDef?.fields?.text?.label}
                </TypographyLocal>
              </div>
            )}
          </>
        )
      },
      className: classes.Col2
    },
    {
      label: "",
      key: "response",
      order: false,
      "decorator": (props) => {
        const haveOptions = _.get(props, "row.questionDef.fields.yes_no", null) === null ? false : true;
        return (
          <div className={classes.containerResponse}>
            <TypographyLocal
              className={classes.fontResponse}
            >
              {props.row.explainComment ? (
                <>
                  {haveOptions ? (
                    `${props.row.response ? "Yes" : "No"} - ${props.row.explainComment}`
                  ) : (
                    `${props.row.explainComment}`
                  )}
                </>
              ) : (
                `${props.row.response ? "Yes" : "No"}`
              )}
            </TypographyLocal>
          </div>
        )
      },
    },
    {
      label: "",
      key: "actions",
      order: false,
      "decorator": (props) => {
        return (
          <>
            {!isViewer() && (
              <div className={classes.resendInvite}>
                <Button
                  variant='card'
                  onClick={()=>{openModal(ModalEditSingleQuestion, { 
                    initialValues: {
                      question: props.row.questionDef,
                      response: props.row.response,
                      explaind: props.row.explainComment,
                      id: props.row._id
                    } 
                  })}}
                >
                  {translate("TEXT_EDIT")}
                </Button>
              </div>
            )}
          </>
        )
      }
    },
  ];

  return (
    <div className={classes.contentView}>
      <Table
        fields={fields}
        data={questionnaire}
        className={classes.Table}
      />
    </div>
  )
};

export default QuestionnaireTab;

import { default as React, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import Form from "src/components/forms/Form/Form";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import * as Yup from "yup";
import Button from "src/modules/generic/components/Buttons/Button";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import {
  clearAssistatText,
  addAssistantText,
} from "src/modules/generic/store/action";
import Typography from "src/modules/generic/components/Texts/Typography";
import CheckboxButtonSimple from "../../../../../../../../../../generic/components/Inputs/CheckboxButtonSimple";
import classes from "../../../../../../../EditAuthorisedSignatory/wizard/steps/AssignOtherSignatoriesStep/classes.module.scss";
import ControllerInput from "../../../../../../../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
/**
 * Description Step: You don't have any stakeholders to add as a signatory
 *
 * @returns
 */
function NoEligibleStakeholders() {
  const { next, context, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.NoEligibleStakeholders ?? {};
  }, [context]);

  const stakeholders = [
    {
      value: true,
      label: "Clara’s Nominee Authorised Signatory Service",
    },
    {
      value: false,
      label: "Add third party",
    },
  ];

  const handleCancel = () => {
    cancel();
  };
  const handlePrev = () => {
    prev();
  };
  const handleSubmit = async (values) => {
    next(values);
  };

  useEffect(() => {
    dispatch(
      addAssistantText(
        "To qualify as a GCC national or UAE resident, the person should a hold passport from Bahrain, Kuwait, Oman, Qatar or Saudi Arabia or UAE, or a residence visa for the UAE.​"
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const schema = Yup.object().shape({
    useNominee: Yup.string().nullable().required("Required"),
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: "You don’t have eligible stakeholders",
          skeleton: null,
          subTitle: "ADGM authorised signatories",
          variant: "simple",
        }}
        buttons={{
          previous: (
            <Button variant="secondary" onClick={handlePrev}>
              {translate("BUTTON_PREVIOUS")}
            </Button>
          ),
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("BUTTON_CANCEL")}
            </Button>
          ),

          submit: (
            <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>
              {translate("BUTTON_NEXT")}
            </SubmitButton>
          ),
        }}
      >
        <Typography component="h5">
          You can either use Clara’s Nominee service or add a third party who is
          not a director or shareholder but they must be UAE Resident or GCC
          National.
        </Typography>
        <ControllerInput
          name="useNominee"
          render={CheckboxButtonSimple}
          options={stakeholders}
          classNameButton={classes.buttonCheckBox}
        />
      </TemplateWizard>
    </Form>
  );
}

export default NoEligibleStakeholders;

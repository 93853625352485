import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple/index";
import { default as React, useMemo } from 'react';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import * as Yup from 'yup';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import { useDispatch } from "react-redux";
import classesModule from "../classes.module.scss"

/**
- Select What to Add
**/

function SelectWhatToAdd() {

  const { translate } = useTranslate();
  const { context, send, next } = useMachine();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.SelectWhatToAdd ?? {}
  }, [context]);

  const handleCancel = () => {
    send("CANCEL");
  };

  const options = [
    {
      value: false,
      label: "Form a new company",
    },
    {
      value: true,
      label: "Add an existing company",
    },
  ];

  const handleTooltips = (value) => {
    dispatch(clearAssistatText());
    dispatch(
      addAssistantText(
        translate('MODULES.ONBOARDINGCOMPANY.STEP1_HIT')
      )
    );
    dispatch(
      addAssistantText(
        translate('MODULES.ONBOARDINGCOMPANY.STEP1_HIT2')
      )
    );
    if (value) {
      dispatch(
        addAssistantText(
          translate('MODULES.ONBOARDINGCOMPANY.STEP1_HIT3')
        )
      );
    }
  }

  const schema = Yup.object().shape({
    isIncorporated: Yup.boolean().required("Required"),
  });

  const handleSubmit = async (values) => {
    next(values)
  }

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: "What do you want to do?",
          subTitle: "Add a company",
          skeleton: null,
          variant: 'simple'
        }}

        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('BUTTON_NEXT')}</SubmitButton>,
        }}
      >
        <>
          <ControllerInput
            as={CheckboxButtonSimple}
            className={classesModule.CheckboxButton}
            classNameButton={classesModule.buttonCheckBoxSize}
            name="isIncorporated"
            variant="col_1"
            options={options}
            onChange={(value) => {
              handleTooltips(value);
            }}
          />
        </>
      </TemplateWizard>
    </Form>
  );
};

export default SelectWhatToAdd;

import React, {Component} from "react";
import {connect} from "react-redux";
import {AgreementDeleteForm, ButtonModalOptions, Table, ToolTip} from "../../index";
import {Paper} from "@material-ui/core";
import classes from "./DocumentTable.module.scss";
import ServerConnect from "../../../utils/ServerConnect";
import {Constants} from '../../../utils/constants';
import {goToPage, goToPrevPage} from '../../../../store/actions/page'
import _ from "lodash";

class DocumentTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            showDelete: false,
            deleteIds:[]
        }
  
    }

    requestSignature = async  row => {
        this.props.goToPage(Constants.PAGES.getSignature, { id: row.id });
    };

    downloadDocument = (row) => {
        let url = ServerConnect.getUrlFile(row.file.id);
        ServerConnect.downloadFile(url, row.file.name);
    };

    deleteDocument = (row) => {
        let agreementId = row.id;
        this.setState({
            rowDelete: row,
            showDelete: true
        })
    }
    handleClose = (redirect) => {
        this.setState({
            showDelete: false,
        })
       
    }
    viewDocument = (row) => {

        let agreementId = row.id;
        this.props.goToPage(Constants.PAGES.viewDocument, { id: agreementId });
    }
    deleteEntity = (data) => {
        let deleteIds = this.state.deleteIds
        deleteIds.push(data.agreementId)
        this.setState({
            deleteIds
        })
    }

    render() {

        let options = [];
        options.push({ isSignature: true, label: "Get Signatures", action: this.requestSignature, className: `${classes.SignatureIcon}` , actionAccess: Constants.ACTIONS.EDIT_PROFILE })
        options.push({ label: "View Document", action: this.viewDocument, className: `${classes.EditIcon}` } )
        options.push({ label: "Download Document", action: this.downloadDocument, className: `${classes.DownloadIcon}`, actionAccess: Constants.ACTIONS.DOWNLOAD_DOCUMENT })
        options.push({ label: "Delete Document", action: this.deleteDocument, className: `${classes.DeleteIcon}` , actionAccess: Constants.ACTIONS.EDIT_PROFILE })

        let fields = [
            {
                label: "Name",
                key: "name",
                order: true,
                "decorator": (props) => (

                    <ToolTip
                        className={`${classes.letterCell} ${classes[props.row.kind]} `}
                        data={props.row.name}
                        type={"text"}
                        timeShow={0}
                        limit={21}
                    />
 
                ),
                className: classes.Col1
            },
            {
                label: "Type",
                key: "type",
                order: {
                    title: "Show First",
                    formOrder: _.uniq(this.props.formOrder.type),
                    action: ((a, b, element) => (a === element) ? -1 : (b === element) ? 1 : 0)
                },
                "decorator": (props) => (

                    <ToolTip
                        className={`${classes.letter}`}
                        data={props.row.type}
                        type={"text"}
                        timeShow={0}
                        limit={18}
                    />
                ),
                className: classes.Col2


            },
            {
                label: "Category",
                key: "category",
                order: {
                    title: "Show First",
                    formOrder: _.uniq(this.props.formOrder.category),
                    action: ((a, b, element) => (a === element) ? -1 : (b === element) ? 1 : 0)

                },
                "decorator": (props) => (

                    <ToolTip
                        className={`${classes.category}`}
                        data={props.row.category}
                        type={"text"}
                        timeShow={0}
                        limit={18}
                    />
                ),
                className: classes.Col3

            },
            {
                label: "Subcategory",
                key: "subCategories",
                order: {
                    title: "Show First",
                    formOrder: _.uniq(this.props.formOrder.subCategories),
                    action: ((a, b, element) => (a === element) ? -1 : (b === element) ? 1 : 0)

                },
                "decorator": (props) => (
                    <ToolTip
                        className={`${classes.letter}`}
                        data={props.row.subCategories}
                        type={"text"}
                        timeShow={0}
                        limit={18}
                    />

                ),
                className: classes.Col14

            },
            {
                label: "Parties",
                "decorator": (props) => (
                    <div className={classes.containerParties}>
                        {props.row.avatars.parties.map((avatar, i) => {
                            return <ToolTip key={i} className={`${classes.li}`} index={i} timeShow={0} data={avatar} type={"avatar"} />
                        })}
                        {props.row.avatars.moreParties && <ToolTip data={props.row.avatars.moreParties} direction={"right"} type={"parties"} />}
                    </div>
                ),
                className: classes.Col5
            },
            {
                label: "Status",
                order: {
                    title: "Show First",
                    formOrder: ["Executed", "Pending", "Valid", "Expired", "Draft", "Signed"],
                    action: ((a, b, element) => (a === element) ? -1 : (b === element) ? 1 : 0)
                },
                key: "status",
                "decorator": (props) => (
                    <span className={classes.containerStatues}>
                        <i className={`${classes[props.row.status]} ${classes.circle} `} />
                        <span>{' ' + props.row.status}</span>
                    </span>
                ),
                className: classes.Col6
            },
            {
                label: "",
                "decorator": (props) => (
                    <span className={classes.buttomModal}>
                        <ButtonModalOptions title={null} options={_.filter(options, (option) => {
                            if (option.isSignature) {
                                return props.row.status === "Draft" && props.row.kind !== "UPLOADED"
                            }
                            return true;
                        })} params={props.row} />
                    </span>
                ),
                className: classes.Col7
            }
        ];
        let rows = _.filter(this.props.data,(element)=>{
            return this.state.deleteIds.indexOf(element.id)<0;
        });

        let { filter } = this.props;
        

        return (
            <Paper className={classes.Paper}>

                <Table
                    fields={fields}
                    data={rows}
                    filter={filter}
                    className={classes.DocumentTable}
                />
                {this.state.showDelete ? (
                    <AgreementDeleteForm
                        data={this.state.rowDelete}
                        handleClose={this.handleClose}
                        callbacks={{
                            deleteEntity: this.deleteEntity
                        }}
                    >
                    </AgreementDeleteForm>
                ) : null}
            </Paper>

        )
    };

}
const mapStateToProps = state => ({
    session: state.session,
    form: state.form
});
const mapDispatchToProps = (dispatch) => ({
    goToPage: (page, params) => {
        goToPage(dispatch, page, params)
    },
    goToPrevPage: (reload, defaultPage, params) => {
        goToPrevPage(dispatch, reload, defaultPage, params)
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(DocumentTable)

import FirstStep from "./Steps/FirstStep";
import SecondStep from "./Steps/SecondStep";
import ThirdStep from "./Steps/ThirdStep";
import Machine from "../../../../generic/components/Machine/MachineClass";

const isThereBankingAuthority = ({ data }) => data.is_there_banking_authority.isThereBankingServices

const isNotBankingAuthority = ({ data }) => !data.is_there_banking_authority.isThereBankingServices

const isMultipleBankingAuthorities = ({ data }) => data.banking_authorities.bankingAuthorities.length > 1

const isNotMultipleBankingAuthorities = ({ data }) => data.banking_authorities.bankingAuthorities.length === 1

const machineDefinition = {
  id: 'banking_authority',
  initial: 'is_there_banking_authority',
  states: {
    is_there_banking_authority: {
      on: {
        NEXT: { target: 'set_banking_authorities', actions: 'setContext' },
        CANCEL: 'finish_banking_authority'
      }
    },
    set_banking_authorities: {
      exit: '',
      on: {
        '': [
          { target: 'banking_authorities', cond: 'isThereBankingAuthority' },
          { target: 'finish_banking_authority', cond: 'isNotBankingAuthority' },
        ]
      }
    },
    banking_authorities: {
      on: {
        NEXT: { target: 'is_there_multiple_banking_authorities', actions: 'setContext' },
        PREVIOUS: 'is_there_banking_authority',
        CANCEL: 'finish_banking_authority',
      }
    },
    is_there_multiple_banking_authorities: {
      exit: '',
      on: {
        '': [
          { target: 'is_jointly_or_singly', cond: 'isMultipleBankingAuthorities' },
          { target: 'finish_banking_authority', cond: 'isNotMultipleBankingAuthorities' },
        ]
      }
    },
    is_jointly_or_singly: {
      on: {
        NEXT: { target: 'finish_banking_authority', actions: 'setContext' },
        PREVIOUS: 'banking_authorities',
        CANCEL: 'finish_banking_authority'
      }
    },
    finish_banking_authority: {
      type: 'final',
      entry: 'final',
    },
    onDone: {
      actions: 'stopMachine'
    }
  }
}
const options = {
  guards: {
    isNotBankingAuthority,
    isMultipleBankingAuthorities,
    isNotMultipleBankingAuthorities,
    isThereBankingAuthority
  }
}

const steps = {
  is_there_banking_authority: FirstStep,
  banking_authorities: SecondStep,
  is_jointly_or_singly: ThirdStep,
}

class MachineBankingAuthority extends Machine {
  constructor() {
    super(machineDefinition, options, steps);
  }
}

export default MachineBankingAuthority
import classes from "./classes.module.scss";
import Scrollbar from "../../../../components/generic/Scrollbar/Scrollbar";
import React, { FC } from "react";
import classnames from "classnames";

export interface TabBubblesContainerProps {
  /** Property children */
  children?:React.ReactNode, 
  /** Property removeBottomPadding */
  removeBottomPadding?: boolean,
  /** Property scrollBottom */
  scrollBottom?: boolean,
} 

const TabBubblesContainer: FC<TabBubblesContainerProps> = ({
  children,
  removeBottomPadding = false,
  scrollBottom = false,
  ...props
}:TabBubblesContainerProps) => {

  return (
    <Scrollbar
      className={classnames(`${classes.bubbleArea}`, {
        [classes.bubblePaddingBottom]: !removeBottomPadding,
      })}
      scrollBottom={scrollBottom}
    >
      {children}
    </Scrollbar>
  );
};

export default TabBubblesContainer;

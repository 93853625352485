import { useMemo } from 'react';
import useUploadBulkDocuments from 'src/modules/startup/Stakeholder/graphql/mutation/useUploadBulkDocuments';
import _ from 'lodash';
import useSession from "src/modules/session/hooks/useSession";
import moment from 'moment';
import { useParams } from "react-router-dom";

const useServices = ({ initialValues }) => {
  const { mutation: uploadDocuments } = useUploadBulkDocuments();
  const { startupId } = useSession();
  const params = useParams();

  return useMemo(() => ({

    initialData: async (context, event) => {
      return initialValues;
    },

    SaveStep: async (context, event) => {
      const hoIsSelected = _.get(context, "SelectCertificate.selectType", false);
      const documents = [];
      if (hoIsSelected) {
        const file = _.get(context, "CertificateOfIncumbency.documentIncumbency", "");
        documents.push({
          title: file.name,
          file: file,
          type: "ADGM_CERTIFICATE_OF_INCUMBENCY",
          parties: [context?.initialData?.stakeholderId ?? params.id],
          status: "Uploaded",
          subCategories: ["constitutionalDocuments"],
          executedOnDate: moment(_.get(context, 'CertificateOfIncumbency.dateIncumbency')).format('YYYY-MM-DD'),
        })
      } else {
        const fileDocumentDirectors = _.get(context, "RegisterDirectorsAndMembers.documentDirectors", "");
        const fileDocumentMembers = _.get(context, "RegisterDirectorsAndMembers.documentMembers", "");
        documents.push({
          title: fileDocumentDirectors.name,
          file: fileDocumentDirectors,
          type: "REGISTER_OF_DIRECTORS",
          parties: [context?.initialData?.stakeholderId ?? params.id],
          status: "Uploaded",
          subCategories: ["constitutionalDocuments"],
          executedOnDate: moment(_.get(context, 'RegisterDirectorsAndMembers.dateDirectors')).format('YYYY-MM-DD'),
        });
        documents.push({
          title: fileDocumentMembers.name,
          file: fileDocumentMembers,
          type: "REGISTER_OF_MEMBERS",
          parties: [context?.initialData?.stakeholderId ?? params.id],
          status: "Uploaded",
          subCategories: ["constitutionalDocuments"],
          executedOnDate: moment(_.get(context, 'RegisterDirectorsAndMembers.dateMembers')).format('YYYY-MM-DD'),
        });
      }
      return await uploadDocuments({ variables: {startupId, documents: documents} })
    },

  }), [initialValues]);
}

export default useServices;

import useMutation from "../../../hooks/custom/useMutation";

const FORMATION_APPROVE_DOCUMENT = `
  mutation formationApproveDocument($startupId: ID!, $documentId: ID!) {
    formationApproveDocument(startupId: $startupId, documentId: $documentId) {
      id
      name
      status
    }
  }
`;

const useFormationApproveDocument = (variables) => {
  const [mutation, loading] = useMutation(FORMATION_APPROVE_DOCUMENT, variables);
  return { mutation, loading };
};

export default useFormationApproveDocument;

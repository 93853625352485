export default {
  STEP_EDIT_CARD : {
    TITLE:'Which source of funds would you like to edit?',
    SUBTITLE:'Source of funds',
    TOOLTIP:'Keep an eye on the Clara star for hints and tips.',
    TOOLTIP1:'Here are your <a href="https://clara.co/terms-of-use/" target="_blank">Terms & Conditions</a> and your <a href="https://www.clara.co/privacy-policy/" target="_blank">Privacy policy.</a>',
    TOOLTIP2:'The new payment card will replace your existing card for future payments.',
    TOOLTIP3:'You may see a temporary charge on your payment card when you click on save changes. This is a verification hold that will be removed automatically afterwards.',
  },
  STEP_SUCCESS:{
    TITLE:'Change payment card success ',
    SUB_TITLE:'Payment card',
    MESSAGE: "The payment card has been successfully updated"
  },
  STEP_FAILURE:{
    TITLE:'Change payment card failed ',
    SUB_TITLE:'Payment card',
    MESSAGE1: "It seems there is a problem with your payment card.",
    MESSAGE2: "Please review your payment method or use another card for this transaction."
  }
}
import {AddressFragment} from "../../../generic/graphql/fragments";

const NoticesFragment=`
${AddressFragment}
fragment NoticesFragment on RecipientNoticeType {
    id
    fullName
    email
    useRegisteredAddress
    address {...AddressFragment}
}
`

const AuthorizedSignatoryFragment=`
fragment AuthorizedSignatoryFragment on AuthorizedSignatoryType {
    id
    fullName
    stakeholder
    email
    position
    phoneNumber
    signingAuthority
    stakeholder
}
`

export {
    NoticesFragment,
    // VestingDateFragment,
    // VestingScheduleFragment,
    AuthorizedSignatoryFragment
}

import React, {FC, useContext, useEffect, useMemo, useRef, useState} from 'react'
import _ from 'lodash'
import useTranslate from "../../../../../generic/hooks/useTranslate";
import Form from "../../../../../../components/forms/Form/Form";
import classes from './classes.module.scss'
import ControllerInput from "../../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import SubmitButton from "../../../../../generic/components/Buttons/SubmitButton";
import TemplateFormDefault from "../../../../../generic/templates/Modal";
import FormProps from "../../../../../generic/forms/interfaces/FormProps";
import {CheckboxButtonOptions} from "../../../../../generic/components/Inputs/CheckboxButton";
import schema from './schema'
import CheckboxButtonSimple from "../../../../../generic/components/Inputs/CheckboxButtonSimple";
import {useDispatch} from "react-redux";
import {addAssistantText, clearAssistatText, removeAssistantText} from "../../../../../generic/store/action";
interface isThereEntityProps extends FormProps{
    entity:string,
    tooltips?:object
}
const IsThereEntity: FC<isThereEntityProps> = ({initialValues,entity, tooltips, onCompleteSubmit, Template = TemplateFormDefault, buttons = {}, buttonSubmitProps, propsTemplate}:isThereEntityProps) => {
    const { translate } = useTranslate();
    const dispatch=useDispatch()
    const [first,setFirst]=useState()
    const options: Array<CheckboxButtonOptions<boolean, any>> = [
        {
            value: false,
            label: translate(
                `MODULES.MAP.LABEL_ANOTHER_NO`
            )
        },
        {
            value: true,
            label: translate(
                `MODULES.ONBOARDING_STEPS.ADD_CHECKBOX`, {entity}
            )
        }
    ]
    const refForm=useRef()
    return (
        <React.Fragment>
            <Form
                initialValues={initialValues || {}}
                schema={schema}
                onlySendDirty={false}
                onSubmit={onCompleteSubmit}
                ref={refForm} optionsForm = {{mode:"onChange"}}
            >
                <Template props={propsTemplate} buttons={{submit:<SubmitButton{...buttonSubmitProps}>Next</SubmitButton>,...buttons}}>
                    <ControllerInput
                        as={CheckboxButtonSimple}
                        name='exist'
                        variant='col_1'
                        classNameButton={classes.buttonCheckBox}
                        options={options}
                        onChange={value => {
                            if(tooltips) {
                                if(first) {
                                    dispatch(removeAssistantText());
                                    setFirst(false)
                                }
                                if (value) {
                                    _.get(tooltips,'isExist')  && dispatch(addAssistantText(_.get(tooltips, 'isExist', "text")));
                                    _.get(tooltips,'isExist')  && setFirst(true)
                                } else {
                                    _.get(tooltips,'isNotExist') && dispatch(addAssistantText(_.get(tooltips, 'isNotExist', "text")));
                                    _.get(tooltips,'isNotExist') && setFirst(true)
                                }
                            }
                        }}
                    />
                </Template>
            </Form>
        </React.Fragment>
    )
}

export default IsThereEntity

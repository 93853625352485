import useMutation from "src/hooks/custom/useMutation";
import useSession from "src/modules/session/hooks/useSession";
export const UPSERT_COMPANY_ADDRESSES = `
mutation updateDataProtectionContact(
    $startupId: String
    $groupCompanyId: String
	  $groupCompany: GroupCompanyInputType
  ) {
    upsertGroupCompany(
      startupId: $startupId
      groupCompanyId: $groupCompanyId
      groupCompany: $groupCompany
    ) {
      id
  }
}
`;

const useUpdateGroupCompanyAssetsAndRevenue = () => {
    const [mutation] = useMutation(
      UPSERT_COMPANY_ADDRESSES,
        { showSuccessNotification: true }
    ); 
    const { startupId } = useSession();
    const updateAssetsAndRevenue = (groupCompanyId, assetsAndRevenue) => {
        const groupCompany = {
                assetsAndRevenue
        };
        return mutation({ variables: { startupId, groupCompanyId, groupCompany } });
    }

    return updateAssetsAndRevenue;
};

export default useUpdateGroupCompanyAssetsAndRevenue;

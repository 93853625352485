import { createMachine } from 'xstate';

const machine =
  createMachine({
    id: 'ShareholderDelaware',
    initial: 'initialData',
    states: {
      initialData: {
        invoke: {
          src: 'initialData',
          id: 'initialData',
          onDone: [
            {
              target: 'AddIndividual',
              actions: ['setContextAfterInvoke']
            }
          ],
        }
      },
      AddIndividual: {
        on: {
          PREV: {
            target: 'ShareholderAdded',
          },
          NEXT: {
            target: 'SaveStep',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      ShareholderAdded: {
        on: {
          NEXT: {
            target: 'EvaluateLeave',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      EvaluateLeave: {
        always: [
          {
            cond: 'addNew',
            target: 'AddIndividual'
          },
          {
            cond: 'doNotAddNew',
            target: 'cancel'
          },
        ]
      },
      SaveStep: {
        invoke: {
          src: 'SaveStep',
          id: 'SaveStep',
          onDone: [
            {
              target: 'ShareholderAdded',
              actions: ['addIndividualStakeholder']
            }
          ],
          onError: [
            {
              target: 'finish'
            }
          ]
        }
      },
      cancel: {
        entry: 'cancelMachine',
        type: 'final'
      },
      finish: {
        entry: 'stopMachine',
        type: 'final'
      }
    }
  });

export default machine;

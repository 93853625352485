import { useMutation, gql } from "@apollo/client";

const UPSERT_GROUP_COMPANY = gql`
  mutation upsertGroupCompany(
    $startupId: String
    $groupCompanyId: String
    $groupCompany: GroupCompanyInputType
  ) {
    upsertGroupCompany(
      startupId: $startupId
      groupCompanyId: $groupCompanyId
      groupCompany: $groupCompany
    ) {
      id
      mailingAddress {
        type
        street
        city
        state
        country {
          code
          name
        }
        zipCode
      }
      __typename
    }
  }
`;

const useUpsertMailingAddress = (options = {}) => {
  const [mutation, { ...rest }] = useMutation(UPSERT_GROUP_COMPANY, options);

  return { mutation, ...rest };
};

export default useUpsertMailingAddress;

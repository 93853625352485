const individualType = `{
  id
  type
  isPrimary
  firstName
  isDirector
  isShareholder
  familyMemberOfAPEP
  familyMemberOfAPEPDescription
  closeAssociateOfAPEP
  closeAssociateOfAPEPDescription
  dateOfBirth
  UAEVisaDoc{
    file{
      id
      name
      size
      type
    }
  }
  email
  thirdPartyAddressFormDoc{
    file{
      id
      name
      size
      type
    }
  }
  thirdPartyAddressPassportDoc{
    file{
      id
      name
      size
      type
    }
  }
  lastName 
  thirdPartyAddressFormDoc{
    file{
      id
      name
      size
      type
    }
  }
  thirdPartyAddressPassportDoc{
    file{
      id
      name
      size
      type
    }
  }
  occupation
  address{
    address
    city
    country
    zipCode
    state
  }
  proofOfAddress
  proofOfAddressDoc{
    file{
      id
      name
      size
      type
    }
  }
  addressLetterAndSupportingDoc{
    file{
      id
      name
      size
      type
    }
  }
  contactNumber{
    prefix
    number
    extension
  }
  passports{
    id
    current
    nationality
    passportNumber
    passportExpiryDate
    passportDoc{
      file{
        id
        name
        size
      }
    }
    lastPagePassportDoc{
       file{
        id
        name
        size
      }
    }
    UAEPassportStamp
    UAEPassportStampDoc{
       file{
        id
        name
        size
      }
    }
    UIDDoc{
       file{
        id
        name
        size
      }
    }
    PRCIDDoc{
       file{
        id
        name
        size
      }
    }
    
    
  }
  otherPassports
  holdOrHaveHeldPassportFromAnotherCountry
  passportFromAnotherCountryDoc{
    file{
      id
      name
      size
      type
    }
  }
  politicallyExposedPerson
  politicallyExposedPersonDescription
  sourcesOfWealth
  otherSourcesOfWealth
  sourceOfFunds 
}`

const companyTypeCorporate = `{
  id
  companyName
  type
  isDirector
  isShareholder
  otherTradingName
    signatory {
      firstName
      lastName
      jobTitle
      email
      nationality
      passportNumber
      passportExpiryDate
      signatoryPassportDoc{
        file{
          id
          name
          size
          type
        }
      }
    }
  
    directors {
      individuals {
        id
        email
        lastName
        firstName
      }
      companies {
        id
        companyName
        signatory {
          firstName
          lastName
          email
        }
      }
    }
    shareholders {
      individuals {
        id
        email
        lastName
        firstName
      }
      companies {
        id
        companyName
        signatory {
          firstName
          lastName
          email
        }
      }
    }  
  
  clientKeyContact{
    fullName
    contactNumber{
      prefix
      number
      extension
    }
    email
    contactType
    }
  financeContact{
    fullName
    contactNumber{
      prefix
      number
      extension
    }
    email
    contactType
    }
    sourceOfFunds
    representativeDetails{
      firstName
      lastName
      jobTitle
      email
      representativePassportDoc {
        file{
          id
          name
          size
          type
        }
      }
    }
    showingShareholdersRegistrationDoc{
      file{
        id
        name
        size
        type
      }
    }
    showingDirectorsRegistrationDoc{
      file{
        id
        name
        size
        type
      }
    }
  registrationNumber
  isPrimary
  TRNNumber
  jurisdictionOfIncorporation
  VATRegistrationCertificateDoc{
    file{
      id
      name
      size
      type
      }
  }
  address{
    city
    state
    country
    zipCode
    address
  }
samePrincipalPlaceOfBusinessAndLegal
principalAddress{
  city
  state
  country
  zipCode
  address
} 
certificateOfIncorporationOrCommercialLicenseDoc{
  file{
    id
    name
    size
    type
  }
}
memorandumAndArticlesOfAssociationDoc{
  file{
    id
    name
    size
    type
  }
}
countryOfIncorporation {code name}
proofOfPowerDoc{
  file{
    id
    name
    size
    type
  }
}
}`

const individual = ` 
  id
  type
  isPrimary
  firstName
  isDirector
  isShareholder
  familyMemberOfAPEP
  familyMemberOfAPEPDescription
  closeAssociateOfAPEP
  closeAssociateOfAPEPDescription
  dateOfBirth
  UAEVisaDoc{
    file{
      id
      name
      size
      type
    }
  }
  email
  thirdPartyAddressFormDoc{
    file{
      id
      name
      size
      type
    }
  }
  thirdPartyAddressPassportDoc{
    file{
      id
      name
      size
      type
    }
  }
  lastName 
  thirdPartyAddressFormDoc{
    file{
      id
      name
      size
      type
    }
  }
  thirdPartyAddressPassportDoc{
    file{
      id
      name
      size
      type
    }
  }
  occupation
  address{
    address
    city
    country
    zipCode
    state
  }
  proofOfAddress
  proofOfAddressDoc{
    file{
      id
      name
      size
      type
    }
  }
  addressLetterAndSupportingDoc{
    file{
      id
      name
      size
      type
    }
  }
  contactNumber{
    prefix
    number
    extension
  }
  passports{
    current
    nationality
    passportNumber
    passportExpiryDate
    passportDoc{
      file{
        id
        name
        size
      }
    }
    lastPagePassportDoc{
       file{
        id
        name
        size
      }
    }
    UAEPassportStamp
    UAEPassportStampDoc{
       file{
        id
        name
        size
      }
    }
    UIDDoc{
       file{
        id
        name
        size
      }
    }
    PRCIDDoc{
       file{
        id
        name
        size
      }
    }
    
    
  }
  otherPassports
  holdOrHaveHeldPassportFromAnotherCountry
  passportFromAnotherCountryDoc{
    file{
      id
      name
      size
      type
    }
  }
  politicallyExposedPerson
  politicallyExposedPersonDescription
  sourcesOfWealth
  otherSourcesOfWealth
  sourceOfFunds 
`


const getCompanyDataCompany = `
query getMattersList {
  getMattersList(matterId:"{{matterId}}") {
    questionnaire {
      companyDetails{
        companyName
        companyType
        holdingName
        alreadyCompanies ${companyTypeCorporate}
        alreadyCompaniesIds
        limitedName
        fullLegalName
        haveAcronym
        shortenedName
        haveArabicName
        arabicName
        isRevenueGenerating
        isProtection
        intellectualProperty
        intellectualPropertyDoc{
          ...fileDoc
        }
        alreadyIncorporated
        haveAffiliation
        fullLegalName
        certificateOfIncorporationDoc {
          ...fileDoc
        }
        haveArticlesOfAssociation
        certificateOfIncorporationDoc {
          ...fileDoc
        }
        alreadyCompaniesIds
        assetsHeld {
          primaryAsset
        }
    
      }
    }
    

  }
}

fragment fileDoc on FileType {
  id
  name
  date
  file {
    id
    name
    size
    type
  }
}
`

const upsertCompanyDetails = `
mutation upsertCompanyDetails(
  $matterId: String
  $companyDetailsData: QuestionnaireCompanyDetailsInput!
){
  upsertCompanyDetails(matterId: $matterId, companyDetailsData: $companyDetailsData ){
    companyName
  }
}
`


const upsertQuestionnaireAgreementsData = `
mutation upsertQuestionnaireAgreementsData(
  $matterId: String
  $agreementsData: QuestionnaireAgreementsDataInput!
){
  upsertQuestionnaireAgreementsData(matterId: $matterId, agreementsData: $agreementsData ){
    firstName
    lastName
    email
    agreementsDirectors
  
  }
}
`

const getAgreementData = `
query getMattersList {
  getMattersList(matterId:"{{matterId}}") {
    questionnaire {
      companies${companyTypeCorporate}
      individuals${individualType}
      agreementsData{
        agreementsDirectors
        firstName
        lastName
        email
      }
    }
    

  } 
}
`

const getDirectors = `
{getQuestionnaireDirectors(matterId:"{{matterId}}")}`

const upsertDirectors = `mutation upsertQuestionnaireDirectors(
  $matterId: String!
	$directors: [ID]!
) {
  upsertQuestionnaireDirectors(
    matterId: $matterId
    directors: $directors
  ) {
    companies{
      id
    }
  }
}
`

const getBeneficialOwners = `{
  getMattersList (matterId: "{{matterId}}"){
    questionnaire {
      beneficialOwners {
        id
        label:firstName
        lastName
      }
    }
  }
}`

const getEligibleBeneficialOwners = `{
  getEligibleBeneficialOwners (matterId: "{{matterId}}"){
    ${individual}
  }
}`


const getEligibleSPVAlreadyIncorporatedCompanies = `{
  getEligibleSPVAlreadyIncorporatedCompanies (matterId: "{{matterId}}")
    ${companyTypeCorporate}
}`


const getShareholders = `{
  getMattersList (matterId: "{{matterId}}"){
    questionnaire {
      sharesData {
         shareholders {
          shares
          paid
          id: entityId
          label: fullName
        }
        sharesAssigned
        shareType
        shareNominalPrice
        nomineeShareholders
        sharesAuthorized
      }
    }
  }
}`

const getDataControler = `
{
  getMattersList(matterId: "{{matterId}}") {
    KYC{
      individuals{
        id
        label:firstName
      }
    }

    questionnaire {
     dataProtection{
       ${individual}
      }
    }
  }
}

`

const getBanckInformation = `
{
  getMattersList(matterId: "{{matterId}}") {
    questionnaire{
      bankInformation{
        bankAccountOpening
        authorizedSignatories{
          id
        }
        requireToSignJointly
        bankingAddress{
          city
          state
          country
          zipCode
          address
        }
      }
      }
  }

  
    getEligibleAuthorizedSignatories(matterId: "{{matterId}}"){
      UAEOrGCCNationalSelection {
        id
        firstName
        lastName
      }
      otherAdditionalSelection {
        id
        firstName
        lastName
      }
    }
  
}

`

const getAuthorisedSignatories = `{

  getMattersList(matterId: "{{matterId}}") {
    questionnaire {
      authorizedSignatories{
        ${individual}
      }

    }
  }
  
  getEligibleAuthorizedSignatories(matterId: "{{matterId}}") {
    
    UAEOrGCCNationalSelection {
      ${individual}
    }
    otherAdditionalSelection {
      ${individual}
    }
  }
}

`

const upsertQuestionnaireBeneficialOwners = `
mutation upsertQuestionnaireBeneficialOwners(
  $matterId: String!
  $beneficialOwners: [ID]!
) {
  upsertQuestionnaireBeneficialOwners(
    matterId: $matterId
    beneficialOwners: $beneficialOwners
  ) {
    id
  }
}

`



const upsertQuestionnaire = `
mutation upsertQuestionnaire(
  $matterId: String!
  $questionnaireData: QuestionnaireInput!
) {
  upsertQuestionnaire(
    matterId: $matterId
    questionnaireData: $questionnaireData
  ) {
    id
  }
}
`



export {
  getCompanyDataCompany,
  upsertCompanyDetails,
  getDirectors,
  upsertDirectors,
  getShareholders,
  upsertQuestionnaire,
  getDataControler,
  getBanckInformation,
  getAuthorisedSignatories,
  getBeneficialOwners,
  upsertQuestionnaireBeneficialOwners,
  getEligibleBeneficialOwners,
  upsertQuestionnaireAgreementsData,
  getAgreementData,
  getEligibleSPVAlreadyIncorporatedCompanies
}

import { assign } from 'xstate';
import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import machine from './machine';
import { useDispatch } from 'react-redux';
import TemplateWizardSkeletonMachine from '../../../../../modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import StepApplicationSummay from './Steps/ApplicationSummary';
import useTranslate from "../../../../../modules/generic/hooks/useTranslate";
import useSubmitApplication from './hooks/useSubmitApplication';
import Wizard from '../../../../../modules/generic/components/Wizard/v2';
import ModalAssistant from '../../../../../modules/generic/components/Modal/ModalAssistant'
import useModal from "../../../../../modules/generic/hooks/useModal";
import { Constants } from 'src/v1/utils/constants'
import { clearAssistatText } from "../../../../../modules/generic/store/action";
import event from 'src/v1/utils/event';
import useSession from "src/modules/session/hooks/useSession";
import { useGetStartupAndActionsLazy } from "../../../../../modules/startup/graphql/startup/useGetStartup";

/**
 * Wizard to Complete Stakeholders
 * @returns
 */
const ModalSubmitApplication = ({
  initialValues,
  open: openParam = false
}) => {
  const [open, setOpen] = useState(openParam);
  const { translate } = useTranslate();
  const { closeModal: onClose } = useModal();
  const { mutation } = useSubmitApplication();
  const { startup, updateStartup } = useSession();
  const dispatch = useDispatch();

  const {
    manualQuery: getStartup,
  } = useGetStartupAndActionsLazy();

  const steps = useMemo(() => ({
    serviceSubmitApplication: {
      render: TemplateWizardSkeletonMachine,
      renderProps: {
        title: translate('MODULES.STAKEHOLDER.EDIT_SOURCE_OF_FUNDS.TITLE'),
        subTitle: translate('MODULES.STAKEHOLDER.EDIT_SOURCE_OF_FUNDS.SUBTITLE'),
      },
      stepperPosition: 0
    },
    stepApplicationSummary: {
      render: StepApplicationSummay,
      stepperPosition: 0
    },
  }), []);

  const guards = {};

  const services = {
    submitApplication: async () => {
      try {
        let result = await mutation();
        const startupData = await getStartup({ variables: { startupId: startup.id } });
        console.log('*** refetch del mf', initialValues.callback());

        if (initialValues?.callback) {
          initialValues.callback();
        }

        await updateStartup({
          ...startupData.data.getStartup,
          id: startup.id
        });

        const dispatchStartup = {
          acceleratorData: {
            isSubmitted: true
          }
        };

        dispatch({ type: "SESSION_UPDATE_STARTUP", startup: dispatchStartup });
        event.emmit(Constants.EVENTS.CONGRATS_ACCELERATOR, {});

        return result;
      } catch (error) {
        console.log("**** error on submit: ", error);
      }
    },
  };

  const actions = {
    setContextAfterSelect: assign((context, event) => {
      const values = _.get(event, 'payload');
      const solution = { ...context, ...values };
      return solution;
    }),
  };

  const handleStopMachine = () => {
    onClose();
    setOpen(false);
    clearAssistatText();
  }

  return (
    <ModalAssistant open={open}>
      <Wizard
        guards={guards}
        machine={machine}
        steps={steps}
        services={services}
        actions={actions}
        onStopMachine={handleStopMachine}
      />
    </ModalAssistant>
  );
}

export default ModalSubmitApplication;
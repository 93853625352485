import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import * as Yup from 'yup';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText,addAssistantText } from 'src/modules/generic/store/action';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from "src/modules/generic/components/Texts/Typography";
import classes from "./classes.module.scss";
import Lottie from 'src/modules/generic/components/Animations/Lottie/Lottie';
import animationData from 'src/modules/generic/animations/journey-finish.json'

function ClaraInTouchFormStep() {
  const { cancel, context } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  
  const initialValues = useMemo(() => {
    return context?.ClaraInTouchFormStep ?? {}
  }, [context]);
  
  const handleCancel = (values) => {
    cancel();
  }  
  
  useEffect( ()=>{
    dispatch(addAssistantText('  Our agents will be in touch as soon as they’ve reviewed your information, to advise on the next steps.' ))
    return () => {
      dispatch(clearAssistatText());
    }
  },[dispatch]);
  
  const schema = Yup.object().shape({});

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleCancel}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: "Clara will be in touch​",
          subTitle: "Engage concierge ​​",
          skeleton: null,
          variant: 'simple'
        }}
        buttons={{
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('BUTTON_GO_MAP')}</SubmitButton>,
        }}
      >
        <>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={3}
          >
            <Stack sx={{ width: "100%"}} gap={2}>
              <Typography component='p' variant='xbody'>
                Thank you, your details have been sent to the Clara concierge service. 
              </Typography>
              <Typography component='p' variant='xbody'>
                You’ll be contacted soon by one of your team experts to get advised.
              </Typography>
            </Stack>
            <Box sx={{ width: "80%"}}>
              <Box sx={{width: "22.3077rem", position: "absolute", right: "9.2308rem", marginTop: "1.1rem"}}>
                <Lottie animation={animationData} className={classes.ViewCompanyFormation}/>
              </Box>
            </Box>
          </Stack>
        </>
      </TemplateWizard>
    </Form>
  );
}

export default ClaraInTouchFormStep;
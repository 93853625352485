import { get } from "lodash";
import React, { FC } from "react";
import { useDispatch } from "react-redux";
import Button from "../../../../../generic/components/Buttons/Button";
import { StepProps } from "../../../../../generic/components/Wizard/Step";
import useCustomMachine from "../../../../../generic/hooks/useCustomMachine";
import {
  addAssistantText,
  clearAssistatText,
} from "../../../../../generic/store/action";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import Form from "../Forms/FormEmiratesID";

const Step1: FC<StepProps> = () => {

  const { next, send, state } = useCustomMachine();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(clearAssistatText());
    dispatch(
      addAssistantText(
        `Some regions require additional national identification.`
      )
    );
    dispatch(
      addAssistantText(
        `Upload clear scans of the front and back of this stakeholder's Emirates ID.`
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, []);

  const handleCompleteSubmit = async (values) => {
    next(values);
  };

  const handleCancel = async (values) => {
    send("CANCEL");
  };

  return (
    <React.Fragment>
      <Form
        initialValues={get(state, "context.data.uploadDocument", {})}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: "Add both sides of their Emirates ID",
          subTitle: "Emirates ID",
        }}
        buttons={{
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              Cancel
            </Button>
          ),
        }}
      ></Form>
    </React.Fragment>
  );
};

export default Step1;

import {TypePolicy} from "@apollo/client";

const AvatarType: TypePolicy = {
    keyFields: ["name", "type", "imageUrl"]
}
const CountryType:TypePolicy={
    keyFields:['code']
}

const Address:TypePolicy={
    keyFields:['street','city','country','zipCode']
}

const Currency:TypePolicy={
    keyFields:['currencyCode']
}

const Jurisdiction:TypePolicy={
    keyFields:['name']
}

export {
    AvatarType,
    CountryType,
    Currency,
    Jurisdiction,
    Address
}
import React from "react";
import Button from "src/modules/generic/components/Buttons/Button";
import SubscriptionScale from "../SubscriptionScale";
import classes from "./CardProduct.module.scss";

const CardProductScale = ({
  handleClick,
  subscriptionType,
  ifChangeProduct,
}) => {
  return (
    <SubscriptionScale
      className={classes.containerListProductos}
      subcriptionType={subscriptionType}
      ifChangeProduct={ifChangeProduct}
      saveUpTitle="Save up to USD 20,000"
      saveUpSubtitle="on software & legal fees (est.)"
      detailTitle="Scale"
      detailSubtitle="Grow, manage and share your profile online"
      scaleItemList={[
        {
          icon: "Scenario-modelling",
          title: "Scenario Modelling",
          tags: {
            icon: "Unlocked",
            text: "Unlocked",
            color: "#E96199",
          },
        },
        {
          icon: "Legal",
          title: "Share Incentive Plan",
          tags: {
            icon: "Unlocked",
            text: "Unlocked",
            color: "#E96199",
          },
        },
        {
          icon: "Document",
          title: "Founders´ Agreement",
          tags: {
            icon: "Unlocked",
            text: "Unlocked",
            color: "#E96199",
          },
        },
        {
          icon: "Concierge",
          title: "30 minute onboarding",
          tags: {
            icon: "Unlocked",
            text: "Unlocked",
            color: "#E96199",
          },
        },
        {
          icon: "document-stack",
          title: "Generate documents",
          tags: {
            icon: "Switch-around",
            text: "Unlimited",
            color: "#E96199",
          },
        },
        // {
        //   icon: 'Sharing',
        //   title: 'Profile shares',
        //   tags: {
        //     icon: "Switch-around",
        //     text: 'Unlimited',
        //     color: '#E96199'
        //   }
        // },
        {
          icon: "Document-edit",
          title: "Document uploads",
          tags: {
            icon: "Switch-around",
            text: "Unlimited",
            color: "#E96199",
          },
        },
        {
          icon: "Heart",
          title: "Health Check",
          tags: {
            icon: "Switch-around",
            text: "Unlimited",
            color: "#E96199",
          },
        },
      ]}
    >
      <Button onClick={handleClick} variant="upgrade" children="Upgrade" />
    </SubscriptionScale>
  );
};

export default CardProductScale;

import React from "react";
import classes from "./classes.module.scss";
import classnames from "classnames";
import TypographyLocal from "../../../generic/components/Texts/Typography";
import PropTypes from "prop-types";
import IconTitle from "../../../generic/components/IconTitle"

const BubbleContents = ({
  children,
  className,
  color,
  title,
  iconType
}) => {

  return (
    <div className={classnames(classes.BubbleContentsText)}>
      {title && (
        <IconTitle
          title={title}
          iconType={iconType}
          textColor="secondary"
        >
        </IconTitle>
      )}
      <TypographyLocal
        className={className}
        color={color}
      >
        {children}
      </TypographyLocal>
    </div>
  );
};

BubbleContents.propTypes = {
  /** Property text */
  children: PropTypes.node,
  /** Property color */
  color: PropTypes.string,
  /** Property tile */
  title: PropTypes.string,
  /** Property iconType */
  iconType: PropTypes.string
};

BubbleContents.defaultProps = {
  color: "black",
  className: classnames(classes.bubbleNormal),
};

export default BubbleContents;

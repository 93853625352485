const templates = {
    
    MESSAGE_HEADER_VALUATION_CREATED:
        "{{userName}} {{entityAction}} a  {{entityName}} for {{companyName}}.",
    MESSAGE_SUB_HEADER_VALUATION_CREATED:
        "{{userName}} {{entityAction}} a  {{entityName}} for {{companyName}}.",
    MESSAGE_TEMPLATE_VALUATION_CREATED: `
    <label> {{userName}} {{entityAction}} a  {{entityName}} for {{companyName}}</label> 
    <ul>
        <li>A {{entityName}} has been {{entityAction}} for {{companyName}} by {{userName}} at {{activityTime}} on {{activityDate}}.</li>
    </ul> `,

    MESSAGE_HEADER_VALUATION_EDITED:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_SUB_HEADER_VALUATION_EDITED:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_TEMPLATE_VALUATION_EDITED: `
    <label> {{userName}} {{entityAction}} the details of a {{entityName}} in {{companyName}}</label> 
    <ul>
        <li>The details of a valuation in {{companyName}} have been edited by {{userName}} at {{activityTime}} on {{activityDate}}.</li>
    </ul> `,


    MESSAGE_HEADER_VALUATION_DELETED:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_SUB_HEADER_VALUATION_DELETED:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_TEMPLATE_VALUATION_DELETED: `
    <label> {{userName}} {{entityAction}} a {{entityName}} for {{companyName}}</label> 
    <ul>
        <li>{{equityContainerName}} has been deleted in {{companyName}} by {{userName}} at {{activityTime}} on {{activityDate}}.</li>
    </ul> `,

    MESSAGE_HEADER_VALUATION_PUBLISHED:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_SUB_HEADER_VALUATION_PUBLISHED:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_TEMPLATE_VALUATION_PUBLISHED: `
    <label>{{userName}} {{entityAction}} a  {{entityName}} for {{companyName}}</label> 
    <ul>
        <li>A valuation has been published for {{companyName}} by {{userName}} at {{activityTime}} on {{activityDate}}.</li>
    </ul> `,


    MESSAGE_HEADER_VALUATION_PUBLISH_AND_SHARING:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_SUB_HEADER_VALUATION_PUBLISH_AND_SHARING:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_TEMPLATE_VALUATION_PUBLISH_AND_SHARING: `
    <label> {{userName}} {{entityAction}} a {{entityName}} for {{companyName}}</label> 
    <ul>
        <li>{{entityName}} has been {{entityAction}} for {{companyName}} by {{userName}} at {{activityTime}} on {{activityDate}}.</li>
    </ul> `,


    MESSAGE_BUTTON_REDIRECT_GO_TO_VALUATION: `View equity details`,
}

export default templates

import React, { Component } from "react";
import { connect } from "react-redux";
import { Field } from "formik";
import _ from "lodash";
import ClaraSelect from "../../../../components/Form/ClaraSelect/ClaraSelect";
import classes from "../../SteperForm.module.scss";
import FormikHelper from "../../../../utils/formikHelper";
import ClaraInputHidden from "../../../../components/Form/ClaraInputHiden/ClaraInputHidden";
import { Notifications } from "../../../../components";

//Forms
import { goToPage, goToPrevPage } from "../../../../../store/actions/page";
import AssignmentDoc from "./AssignmentDoc";
import SAFEAgreementDoc from "./SAFEAgreementDoc";
import CLNAgreementDoc from "./CLNAgreementDoc";
import AdvisorAgreementDoc from "./AdvisorAgreementDoc";
import ConsultantAgreementDoc from "./ConsultantAgreementDoc";
import FounderDocs from "./FounderDocs";
import KISSAgreementDoc from "./KISSAgreementDoc";
import EmploymentAgreementDoc from "./EmploymentAgreementDoc";
import GrantAgreementDoc from "./GrantAgreementDoc";
import FounderServicesAgreementDoc from "./FounderServicesAgreement";
import ShareIncentivePlanDoc from "./ShareIncentivePlanDoc";
import RestrictiveCovenantAgreementDoc from "./RestrictiveCovenantAgreeement";
import BRCAgreementDoc from "./BRCAgreementDoc";
import NDAMutual from "./NDAMutual";
import USA_NDADoc from "./USA/NDADoc";
import FoundersAgreement from "./FoundersAgreement";
import WebsiteTermsOfUse from "./WebsiteTermsOfUseDoc";
import WebsitePrivacyPolicy from "./WebsitePrivacyPolicy";
import WebsiteAcceptablePolicy from "./WebstiseAcceptableUsePolicy";
import WebsiteCookieNotice from "./WebsiteCookieNotice";
import BoardResolutionConsentSAFEDoc from './USA/BoardResolutionConsentSAFEDoc'
import EPIIAgreement from './USA/EPIIAgreement'
import CPIIAgreement from './USA/CPIIAgreement'
import OfferLetterSalaryEmployeeDoc from './USA/OfferLetterSalaryEmployeeDoc'
import { getDocumentTypeListByDocumentType } from './helper'
import StrategicAdvisoryBoardLetter from './USA/StrategicAdvisoryBoardLetter'
import ConsultingAgreement from './USA/ConsultingAgreementDoc'
import EquityIncentivePlan from './USA/EquityIncentivePlan'
import BoardApprovingOptionsGrantDoc from './USA/BoardApprovingOptionsGrant'
import OptionGrantNoticeDoc from './USA/OptionGrantNotice'

class FormsSelector extends Component {
  constructor(props) {
    super(props);

	const showDocumentType = this.props.docType === "BOARD_RESOLUTION_CONVERTIBLE" || this.props.docType === "USA_BOARD_CONSENT_SAFE"
  	let docType;
  	if (!showDocumentType) {
		  docType = this.props.docType
	}

    this.state = {
      totalFounders: 0,
      founders: [],
      showFormSelector: true,
      typeSelected: docType,
      showDocumentType: showDocumentType
    };
  }

  setShowFormSelector = (show) => {
    this.setState({
      showFormSelector: show,
    });
  };

  selectType = (documentType, extraParams, form) => {
    // this.setState({
    //     typeSelected: documentType,
    // });
    // })
    this.setState(
      {
        typeSelected: documentType,
        //  dinamicParams: this.getDinamicParamsByType(documentType)
      },
      () => {
        // FormikHelper.setValueInTheCorrectPosition('terms', form, []);
        FormikHelper.setValueInTheCorrectPosition(
          "documentType",
          form,
          documentType
        );
        if(documentType) this.props.setValidationSchema(documentType);

        /* this.state.dinamicParams.forEach((dinamicParam, i) => {
                FormikHelper.setValueInTheCorrectPosition(
                    `terms[${i}].key`,
                    form,
                    dinamicParam.key || 'NO_KEY'
                );
            })*/
      }
    );

    // const docTypeInfo = {
    //     docType: documentType ? documentType : 'SELECT_DOCUMENT'
    // };
    // this.props.goToPage(Constants.PAGES.generateDocument, docTypeInfo)

    // this.props.setLeftEducationId(documentType);
  };

  selectTypeHidden = (name, value, form) => {
	  this.selectType(value, null, form);
  }

  formByTypeDocuments = () => {
    switch (this.state.typeSelected) {
      case "FOUNDERS_TERM_SHEET":
        return (
          <FounderDocs
            startup={this.props.startup}
            fromGenerateDoc={true}
            formType={this.props.formType}
            stakeholders={this.props.stakeholders}
            stakeholderList={this.props.stakeholderList}
            groupCompanies={this.props.groupCompanies}
            groupCompaniesList={this.props.groupCompaniesList}
          />
        );
      case "IP_ASSIGNMENT":
        return (
          <AssignmentDoc
            startup={this.props.startup}
            stakeholders={this.props.stakeholders}
            formType={this.props.formType}
            documentTypes={this.props.documentTypes}
            documentTypesList={this.props.documentTypesList}
            stakeholderList={this.props.stakeholderList}
            groupCompanies={this.props.groupCompanies}
            groupCompaniesList={this.props.groupCompaniesList}
            authorizedSignatories={this.props.authorizedSignatories}
            tooltips={this.props.tooltips}
          />
        );
      case "SAFE":
        return (
          <SAFEAgreementDoc
            startup={this.props.startup}
            stakeholders={this.props.stakeholders}
            formType={this.props.formType}
            documentTypes={this.props.documentTypes}
            documentTypesList={this.props.documentTypesList}
            stakeholderList={this.props.stakeholderList}
            groupCompanies={this.props.groupCompanies}
            groupCompaniesList={this.props.groupCompaniesList}
            authorizedSignatories={this.props.authorizedSignatories}
            tooltips={this.props.tooltips}
            extraValues={this.props.extraValues}
          />
        );
      case "CONVERTIBLE_NOTE":
        return (
          <CLNAgreementDoc
            startup={this.props.startup}
            stakeholders={this.props.stakeholders}
            formType={this.props.formType}
            documentTypes={this.props.documentTypes}
            documentTypesList={this.props.documentTypesList}
            stakeholderList={this.props.stakeholderList}
            groupCompanies={this.props.groupCompanies}
            groupCompaniesList={this.props.groupCompaniesList}
            authorizedSignatories={this.props.authorizedSignatories}
            tooltips={this.props.tooltips}
            extraValues={this.props.extraValues}
          />
        );
      case "ADVISOR_AGREEMENT":
        return (
          <AdvisorAgreementDoc
            startup={this.props.startup}
            formType={this.props.formType}
            stakeholders={this.props.stakeholders}
            documentTypes={this.props.documentTypes}
            documentTypesList={this.props.documentTypesList}
            stakeholderList={this.props.stakeholderList}
            groupCompanies={this.props.groupCompanies}
            groupCompaniesList={this.props.groupCompaniesList}
            authorizedSignatories={this.props.authorizedSignatories}
            tooltips={this.props.tooltips}
          />
        );
      case "CONSULTANT_AGREEMENT":
        return (
          <ConsultantAgreementDoc
            startup={this.props.startup}
            formType={this.props.formType}
            stakeholders={this.props.stakeholders}
            documentTypes={this.props.documentTypes}
            documentTypesList={this.props.documentTypesList}
            stakeholderList={this.props.stakeholderList}
            groupCompanies={this.props.groupCompanies}
            groupCompaniesList={this.props.groupCompaniesList}
            authorizedSignatories={this.props.authorizedSignatories}
            tooltips={this.props.tooltips}
          />
        );
      case "EMPLOYMENT_AGREEMENT_ADGM":
      case "EMPLOYMENT_AGREEMENT_DIFC":
      case "EMPLOYMENT_AGREEMENT_UK":
        return (
          <EmploymentAgreementDoc
            startup={this.props.startup}
            formType={this.props.formType}
            formSelected={this.state.typeSelected}
            stakeholders={this.props.stakeholders}
            documentTypes={this.props.documentTypes}
            documentTypesList={this.props.documentTypesList}
            stakeholderList={this.props.stakeholderList}
            groupCompanies={this.props.groupCompanies}
            groupCompaniesList={this.props.groupCompaniesList}
            authorizedSignatories={this.props.authorizedSignatories}
            tooltips={this.props.tooltips}
          />
        );
      case "FOUNDER_SERVICES_AGREEMENT":
        return (
          <FounderServicesAgreementDoc
            startup={this.props.startup}
            formType={this.props.formType}
            stakeholders={this.props.stakeholders}
            documentTypes={this.props.documentTypes}
            documentTypesList={this.props.documentTypesList}
            stakeholderList={this.props.stakeholderList}
            groupCompanies={this.props.groupCompanies}
            extraValues={this.props.extraValues}
            groupCompaniesList={this.props.groupCompaniesList}
            authorizedSignatories={this.props.authorizedSignatories}
            tooltips={this.props.tooltips}
            setFieldValue={this.props.setFieldValue}
          />
        );
      case "GRANT_AGREEMENT":
        return (
          <GrantAgreementDoc
            startup={this.props.startup}
            formType={this.props.formType}
            extraValues={this.props.extraValues}
            stakeholders={this.props.stakeholders}
            documentTypes={this.props.documentTypes}
            documentTypesList={this.props.documentTypesList}
            stakeholderList={this.props.stakeholderList}
            groupCompanies={this.props.groupCompanies}
            groupCompaniesList={this.props.groupCompaniesList}
            authorizedSignatories={this.props.authorizedSignatories}
            tooltips={this.props.tooltips}
            documentList={this.props.documentList}
          />
        );
        case "USA_BOARD_APPROVING_OPTION_GRANT":
          return (
            <BoardApprovingOptionsGrantDoc
            startup={this.props.startup}
            formType={this.props.formType}
            values={this.props.values}
            extraValues={this.props.extraValues}
            setFieldValue={this.props.setFieldValue}
            stakeholders={this.props.stakeholders}
            documentTypes={this.props.documentTypes}
            documentTypesList={this.props.documentTypesList}
            stakeholderList={this.props.stakeholderList}
            groupCompanies={this.props.groupCompanies}
            groupCompaniesList={this.props.groupCompaniesList}
            authorizedSignatories={this.props.authorizedSignatories}
            tooltips={this.props.tooltips}
            documentList={this.props.documentList}
          />
            );  
            case "USA_OPTION_GRANT_NOTICE":
              return (
                <OptionGrantNoticeDoc
                  startup={this.props.startup}
                  formType={this.props.formType}
                  values={this.props.values}
                  setFieldValue={this.props.setFieldValue}
                  stakeholders={this.props.stakeholders}
                  documentTypes={this.props.documentTypes}
                  documentTypesList={this.props.documentTypesList}
                  stakeholderList={this.props.stakeholderList}
                  groupCompanies={this.props.groupCompanies}
                  groupCompaniesList={this.props.groupCompaniesList}
                  authorizedSignatories={this.props.authorizedSignatories}
                  tooltips={this.props.tooltips}
                  documentList={this.props.documentList}
                />
            );       
      case "BOARD_RESOLUTION":
      case "SHARE_INCENTIVE_PLAN_FAQ":
      case "SHARE_INCENTIVE_PLAN":
        return (
          <ShareIncentivePlanDoc
            startup={this.props.startup}
            formType={this.props.formType}
            stakeholders={this.props.stakeholders}
            documentTypes={this.props.documentTypes}
            documentTypesList={this.props.documentTypesList}
            stakeholderList={this.props.stakeholderList}
            groupCompanies={this.props.groupCompanies}
            groupCompaniesList={this.props.groupCompaniesList}
            authorizedSignatories={this.props.authorizedSignatories}
            tooltips={this.props.tooltips}
          />
        );
      case "KISS":
        return (
          <KISSAgreementDoc
            startup={this.props.startup}
            formType={this.props.formType}
            stakeholders={this.props.stakeholders}
            documentTypes={this.props.documentTypes}
            documentTypesList={this.props.documentTypesList}
            stakeholderList={this.props.stakeholderList}
            groupCompanies={this.props.groupCompanies}
            groupCompaniesList={this.props.groupCompaniesList}
            authorizedSignatories={this.props.authorizedSignatories}
          />
        );
      case "RESTRICTIVE_COVENANT_AGREEMENT":
        return (
          <RestrictiveCovenantAgreementDoc
            startup={this.props.startup}
            formType={this.props.formType}
            stakeholders={this.props.stakeholders}
            documentTypes={this.props.documentTypes}
            documentTypesList={this.props.documentTypesList}
            stakeholderList={this.props.stakeholderList}
            groupCompanies={this.props.groupCompanies}
            groupCompaniesList={this.props.groupCompaniesList}
            authorizedSignatories={this.props.authorizedSignatories}
            tooltips={this.props.tooltips}
          />
        );
      case "NDA_MUTUAL":
        return (
          <NDAMutual
            startup={this.props.startup}
            formType={this.props.formType}
            stakeholders={this.props.stakeholders}
            documentTypes={this.props.documentTypes}
            documentTypesList={this.props.documentTypesList}
            stakeholderList={this.props.stakeholderList}
            groupCompanies={this.props.groupCompanies}
            groupCompaniesList={this.props.groupCompaniesList}
            authorizedSignatories={this.props.authorizedSignatories}
            tooltips={this.props.tooltips}
          />
        );
      case "FOUNDERS_AGREEMENT":
        return (
          <FoundersAgreement
            startup={this.props.startup}
            formType={this.props.formType}
            stakeholders={this.props.stakeholders}
            documentTypes={this.props.documentTypes}
            documentTypesList={this.props.documentTypesList}
            stakeholderList={this.props.stakeholderList}
            groupCompanies={this.props.groupCompanies}
            groupCompaniesList={this.props.groupCompaniesList}
            authorizedSignatories={this.props.authorizedSignatories}
            tooltips={this.props.tooltips}
            generateDocument={this.props.generateDocument}
            values={this.props.values}
            setFieldValue={this.props.setFieldValue}
            setShowButtons={this.props.setShowButtons}
            setShowFormSelector={this.setShowFormSelector}
            initialValues={this.props.initialValues}
          />
        );
      case "WEBSITE_PRIVACY_POLICY":
        return (
          <WebsitePrivacyPolicy
            startup={this.props.startup}
            formType={this.props.formType}
            stakeholders={this.props.stakeholders}
            documentTypes={this.props.documentTypes}
            documentTypesList={this.props.documentTypesList}
            stakeholderList={this.props.stakeholderList}
            groupCompanies={this.props.groupCompanies}
            groupCompaniesList={this.props.groupCompaniesList}
            authorizedSignatories={this.props.authorizedSignatories}
            tooltips={this.props.tooltips}
          />
        );
      case "WEBSITE_TERMS_OF_USE":
        return (
          <WebsiteTermsOfUse
            startup={this.props.startup}
            formType={this.props.formType}
            stakeholders={this.props.stakeholders}
            documentTypes={this.props.documentTypes}
            documentTypesList={this.props.documentTypesList}
            stakeholderList={this.props.stakeholderList}
            groupCompanies={this.props.groupCompanies}
            groupCompaniesList={this.props.groupCompaniesList}
            authorizedSignatories={this.props.authorizedSignatories}
            tooltips={this.props.tooltips}
          />
        );
      case "WEBSITE_ACCEPTABLE_USE_POLICY":
        return (
          <WebsiteAcceptablePolicy
            startup={this.props.startup}
            formType={this.props.formType}
            stakeholders={this.props.stakeholders}
            documentTypes={this.props.documentTypes}
            documentTypesList={this.props.documentTypesList}
            stakeholderList={this.props.stakeholderList}
            groupCompanies={this.props.groupCompanies}
            groupCompaniesList={this.props.groupCompaniesList}
            authorizedSignatories={this.props.authorizedSignatories}
            tooltips={this.props.tooltips}
          />
        );
      case "WEBSITE_COOKIE_NOTICE":
        return (
          <WebsiteCookieNotice
            startup={this.props.startup}
            formType={this.props.formType}
            stakeholders={this.props.stakeholders}
            documentTypes={this.props.documentTypes}
            documentTypesList={this.props.documentTypesList}
            stakeholderList={this.props.stakeholderList}
            groupCompanies={this.props.groupCompanies}
            groupCompaniesList={this.props.groupCompaniesList}
            authorizedSignatories={this.props.authorizedSignatories}
            tooltips={this.props.tooltips}
          />
        );
      case "BOARD_RESOLUTION_CONVERTIBLE_LOAN_NOTE":
      case "BOARD_RESOLUTION_CONVERTIBLE_SAFE":
      case "BOARD_RESOLUTION_CONVERTIBLE_KISS":
      case "BOARD_RESOLUTION_CONVERTIBLE_FIXED_EQUITY":
      case "BOARD_RESOLUTION_CONVERTIBLE_OTHER":
      case "BOARD_RESOLUTION_CONVERTIBLE":
        return (
          <BRCAgreementDoc
            startup={this.props.startup}
            typeSelected={this.state.typeSelected}
            formType={this.props.formType}
            stakeholders={this.props.stakeholders}
            documentTypes={this.props.documentTypes}
            documentTypesList={this.props.documentTypesList}
            stakeholderList={this.props.stakeholderList}
            groupCompanies={this.props.groupCompanies}
            groupCompaniesList={this.props.groupCompaniesList}
            authorizedSignatories={this.props.authorizedSignatories}
            tooltips={this.props.tooltips}
            loadGroupCompanies={() => this.props.loadGroupCompanies(this.props)}
          />
        );
        case "USA_STRATEGIC_ADVISORY_BOARD_LETTER":
          return <StrategicAdvisoryBoardLetter
          startup={this.props.startup}
          stakeholders={this.props.stakeholders}
          formType={this.props.formType}
          documentTypes={this.props.documentTypes}
          documentTypesList={this.props.documentTypesList}
          stakeholderList={this.props.stakeholderList}
          groupCompanies={this.props.groupCompanies}
          groupCompaniesList={this.props.groupCompaniesList}
          authorizedSignatories={this.props.authorizedSignatories}
          tooltips={this.props.tooltips}
        />
        case "USA_CONSULTING_AGREEMENT":
          return <ConsultingAgreement
          startup={this.props.startup}
          stakeholders={this.props.stakeholders}
          formType={this.props.formType}
          documentTypes={this.props.documentTypes}
          documentTypesList={this.props.documentTypesList}
          stakeholderList={this.props.stakeholderList}
          groupCompanies={this.props.groupCompanies}
          values={this.props.values}
          setFieldValue={this.props.setFieldValue}
          groupCompaniesList={this.props.groupCompaniesList}
          authorizedSignatories={this.props.authorizedSignatories}
          tooltips={this.props.tooltips}
        />
        case "USA_CAL_EMPLOYEE_INFORMATION_AND_INVENTIONS_AGREEMENT":
          return <EPIIAgreement
          startup={this.props.startup}
          stakeholders={this.props.stakeholders}
          formType={this.props.formType}
          documentTypes={this.props.documentTypes}
          documentTypesList={this.props.documentTypesList}
          stakeholderList={this.props.stakeholderList}
          groupCompanies={this.props.groupCompanies}
          groupCompaniesList={this.props.groupCompaniesList}
          authorizedSignatories={this.props.authorizedSignatories}
          tooltips={this.props.tooltips}
        />
        case "USA_CONSULTANT_INFORMATION_AND_INVENTIONS_AGREEMENT":
          return <CPIIAgreement
          startup={this.props.startup}
          stakeholders={this.props.stakeholders}
          formType={this.props.formType}
          documentTypes={this.props.documentTypes}
          documentTypesList={this.props.documentTypesList}
          stakeholderList={this.props.stakeholderList}
          groupCompanies={this.props.groupCompanies}
          groupCompaniesList={this.props.groupCompaniesList}
          authorizedSignatories={this.props.authorizedSignatories}
          tooltips={this.props.tooltips}
        />
        case "USA_BOARD_CONSENT_SAFE_CONVERTIBLE_LOAN_NOTE":
          case "USA_BOARD_CONSENT_SAFE_SAFE":
          case "USA_BOARD_CONSENT_SAFE_KISS":
          case "USA_BOARD_CONSENT_SAFE_FIXED_EQUITY":
          case "USA_BOARD_CONSENT_SAFE_OTHER":
          case "USA_BOARD_CONSENT_SAFE":
            return (
              <BoardResolutionConsentSAFEDoc
                startup={this.props.startup}
                typeSelected={this.state.typeSelected}
                formType={this.props.formType}
                stakeholders={this.props.stakeholders}
                documentTypes={this.props.documentTypes}
                documentTypesList={this.props.documentTypesList}
                stakeholderList={this.props.stakeholderList}
                groupCompanies={this.props.groupCompanies}
                groupCompaniesList={this.props.groupCompaniesList}
                authorizedSignatories={this.props.authorizedSignatories}
                tooltips={this.props.tooltips}
                loadGroupCompanies={() => this.props.loadGroupCompanies(this.props)}
              />

            ); 
            case "USA_EQUITY_INCENTIVE_PLAN":
              case "USA_STAKEHOLDERS_DIRECTORS_EQUITY_INCENTIVE_PLAN":
                return (
                  <EquityIncentivePlan
                    startup={this.props.startup}
                    typeSelected={this.state.typeSelected}
                    formType={this.props.formType}
                    stakeholders={this.props.stakeholders}
                    documentTypes={this.props.documentTypes}
                    documentTypesList={this.props.documentTypesList}
                    stakeholderList={this.props.stakeholderList}
                    groupCompanies={this.props.groupCompanies}
                    groupCompaniesList={this.props.groupCompaniesList}
                    authorizedSignatories={this.props.authorizedSignatories}
                    tooltips={this.props.tooltips}
                    loadGroupCompanies={() => this.props.loadGroupCompanies(this.props)}
                  />
    
                );        
      case "USA_NDA":
        return (
          <USA_NDADoc
            startup={this.props.startup}
            formType={this.props.formType}
            stakeholders={this.props.stakeholders}
            documentTypes={this.props.documentTypes}
            documentTypesList={this.props.documentTypesList}
            stakeholderList={this.props.stakeholderList}
            groupCompanies={this.props.groupCompanies}
            groupCompaniesList={this.props.groupCompaniesList}
            authorizedSignatories={this.props.authorizedSignatories}
            tooltips={this.props.tooltips}
          />
        );
      //CP-5321
      case "USA_CAL_OFFER_LETTER_SALARY_EMPLOYEE":
        return (
          <OfferLetterSalaryEmployeeDoc
            startup={this.props.startup}
            formType={this.props.formType}
            stakeholders={this.props.stakeholders}
            documentTypes={this.props.documentTypes}
            documentTypesList={this.props.documentTypesList}
            stakeholderList={this.props.stakeholderList}
            groupCompanies={this.props.groupCompanies}
            groupCompaniesList={this.props.groupCompaniesList}
            authorizedSignatories={this.props.authorizedSignatories}
            tooltips={this.props.tooltips}
          />
        );
      default:
        break;
    }
  };

  getDocumentTypesList = (documentType) => {
    return getDocumentTypeListByDocumentType(documentType,this.props.documentTypesList)
  };

  render() {
    const { tooltips = {}, isEdit } = this.props;
    const documentTypesList = this.getDocumentTypesList(this.props.docType);

    return (
      <div>
        {this.state.showFormSelector ? (
          <>
          <Notifications show={this.props.docType === 'FOUNDERS_AGREEMENT'} type={"warning"}>
          This document sets out the equity and vesting terms of the founders. If the company does not yet exist, these terms will be indicative of what the founders intend to implement when they set up the company (and the document includes an obligation to set up the company within 12 months). If the company does exist, then if (i) a founder already has an equity position, it will be automatically included or (ii) a founder does not yet have an equity position, you can create one through this form.                
          </Notifications>
            <div className={classes.ZoneTitle}>
              <h1>{"Document Details"}</h1>
              <p>Let’s start with the key details for this document:</p>
            </div>
            <hr className={classes.Separator} />
            {this.state.showDocumentType
                ?
                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                  <div className={classes.docTypeSelector}>
                    <Field name="documentType">
                      {({ field, form }) => (
                        <ClaraSelect
                          required={true}
                          field={field}
                          docType={this.props.docType !== "BOARD_RESOLUTION_CONVERTIBLE" && this.props.docType !== "USA_BOARD_CONSENT_SAFE"?this.state.typeSelected:null}
                          fromGenerateDoc={!isEdit}
                          form={form}
                          label={"Document type "}
                          lists={documentTypesList}
                          mode={"classic"}
                          callbacks={{
                            updateValues: this.selectType,
                          }}
                          placeholder={"Select from the list"}
                          tooltip={tooltips["Document Type"]}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                :
				<Field name="documentType">
					{({ field, form }) => (
						<ClaraInputHidden
							field={field}
							form={form}
							value={this.props.docType}
							updateData={this.selectTypeHidden}
						/>
					)}
				</Field>
            }
          </>
        ) : null}
        {this.state.typeSelected && this.formByTypeDocuments()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  session: state.session,
});
const mapDispatchToProps = (dispatch) => ({
  goToPage: (page, params) => {
    goToPage(dispatch, page, params);
  },
  goToPrevPage: (reload, defaultPage, params) => {
    goToPrevPage(dispatch, reload, defaultPage, params);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FormsSelector);

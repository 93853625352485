import React from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";

const MyConvertible = ({ go, ...props }) => {
  const {startupId, ...params} = useParams();

  return (
    <MicroFrontend id="MF_myEquity_myConvertible" component={"./DetailsConvertibles"} mf="capTable" params={{ go, ...props,...params, startup:{id: startupId} }}/>
  );
};

export default MyConvertible;

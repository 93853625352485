import React, { useRef } from 'react'
import * as Yup from 'yup'
import Form from '../../../../components/forms/Form/Form'
import useTranslate from '../../../generic/hooks/useTranslate'
import TextInput from '../../../../components/inputs/Text/TextInput'
import ControllerInput from '../../../generic/components/Inputs/ReactHookForm/ControllerInput'
import TemplateLabel from '../../../generic/templates/Label'
import SubmitButton from '../../../generic/components/Buttons/SubmitButton'
import FormProps from '../../../generic/forms/interfaces/FormProps';
import TemplateFormDefault from "../../../generic/templates/Modal";
import CountrySelector from '../../../../components/inputs/Select/CountrySelector/CountrySelector';
import cls from './Form.module.scss';
import { Constants } from '../../../../v1/utils'
import CheckboxInput from '../../../generic/components/Inputs/Checkbox'
import _ from 'lodash'
import Typography from '../../../generic/components/Texts/Typography'
import useCustomMachine from '../../../generic/hooks/useCustomMachine'
import ClassNames from 'classnames';
import { useDispatch } from 'react-redux'
import { addAssistantText } from '../../../generic/store/action'

const schema = Yup.object().shape({
    roles: Yup.array().min(1, 'This field is required').required('This field is required')
})

const FormMoreDetails: React.FC<FormProps> = ({
    initialValues: defaultValues = {},
    onCompleteSubmit,
    Template = TemplateFormDefault,
    buttons = {},
    buttonSubmitProps,
    propsTemplate }) => {


    const { translate } = useTranslate();

    const handleSubmit = async values => {
        onCompleteSubmit && await onCompleteSubmit(values);
    }

    const rolesOptions = React.useMemo(() => {
        return _.map(Constants.ROLES_FROM_BACKEND, role => ({ label: role.label, value: role.id }))
    }, []);

    const dispatch = useDispatch();

    const refMoreRoles = useRef(false);

    const handleMoreRoles = () => {
        dispatch(addAssistantText("Is there anything they can't do?"));
        refMoreRoles.current = true;
    }


    const { state } = useCustomMachine();

    const { numbersOfIteration } = state.context;


    return (
        <Form
            defaultValues={defaultValues}
            schema={schema}
            onSubmit={handleSubmit}
            onlySendDirty={false}
            optionsForm={{ mode: "onChange" }}
        >

            <Template buttons={{ ...buttons, submit: <SubmitButton  {...buttonSubmitProps}>Next</SubmitButton> }} props={propsTemplate}>

                <Typography color={'black'} weight={'bold'} variant={"body"}>
                    {_.get(state, `context.companies[${numbersOfIteration}].name`, '')}
                </Typography>
                <TemplateLabel
                    label={'Choose all that apply'}

                    className={ClassNames(cls.TemplateLabel, cls.checkboxLabel)}
                    isRequired
                >
                    <ControllerInput
                        render={CheckboxInput}
                        name='roles'
                        variant={'col_2'}
                        options={rolesOptions}
                        defaultlabel={''}
                        placeholder='Include number and letters only'
                        onChange={(value: string[]) => {
                            if (value.length === 3 && !refMoreRoles.current) {
                                handleMoreRoles();
                            }
                        }}

                    />
                </TemplateLabel>


            </Template>

        </Form>
    )

}

export default FormMoreDetails

import useAddNewBankAccount from 'src/components/inputs/BankAccountsSelect/graphql/useAddNewBankAccount'
import useCreateVestingTerms from './graphql/useCreateVestingTerms'
import useCreateShareClass from './graphql/useCreateShareClass'
import get from 'lodash/get'

const FREQUENCY = {
    MONTHLY: 'monthly',
    QUARTERLY: 'quarterly',
    ANNUALLY: 'annually'
}
const useGetSaveByForm = (values) => {
    const {mutation} = useAddNewBankAccount({}, {}, null)
    const {mutation: createVestingTerms} = useCreateVestingTerms({}, {}, values?.params?.company?.id)
    const {mutation: createShareClass} = useCreateShareClass({}, {}, values?.params?.company?.id)
    const save = async (documentType: string, values: any) => {
        switch (documentType) {
            case 'SAFE':
            case 'CONVERTIBLE_NOTE':
                if (!values.params.terms.bankInfo.id) {
                    const data = await mutation({
                        groupCompanyId: values?.params?.company?.id,
                        startupId: values.startupId,
                        bankAccount: values.params.terms.bankInfo
                    })
                    return {
                        ...values,
                        params: {
                            ...values.params,
                            terms: {
                                ...values.params.terms,
                                bankInfo: {
                                    ...values.params.terms.bankInfo,
                                    id: data.data.addNewBankAccount.id
                                }
                            }
                        }
                    }
                }
                return values

        case 'GRANT_AGREEMENT':
            if(values.params?.terms.vesting?.id === 'ADD'){
                const {terms} = values.params
    const vestingTermData ={
      name:`${terms.vesting.vestingPeriod} ${FREQUENCY[terms.vesting.vestingFrequency]}${terms.vesting.isThereCliff?`, ${terms.vesting.cliffPeriod}m cliff`:''}`,
      allocationType:'BACK_LOADED_TO_SINGLE_TRANCHE',
      vestingType:'STANDARD',
      standardTerm: {
        vestingPeriod:+terms.vesting.vestingPeriod,
        vestingFrequency: terms.vesting.vestingFrequency,
        cliffPeriod:+terms.vesting.cliffPeriod,
        isThereCliff:terms.vesting.isThereCliff
      }
    }
                const data = await createVestingTerms({groupCompanyId:values?.params?.company?.id,startupId:values.startupId,vestingTermData})
                return {
                    ...values,
                    params:{
                        ...values.params,
                    terms:{
                        ...values.params.terms,
                        vesting:{
                            ...values.params.terms.vesting,
                            vestingTermId:data.data.createVestingTerm.id
                        }
                    }
                }
            }
        }
        return {
          ...values,
          params:{
              ...values.params,
          terms:{
              ...values.params.terms,
              vesting:{
                  ...values.params.terms.vesting,
                  vestingTermId:values.params.terms.vesting.id
              }
          }
      }
  }
  case 'FOUNDER_SERVICES_AGREEMENT':
      let adds = {};
    if(values.params.founder.share && values.params.founder.share.length > 0){
        if(values.params.founder.share[0].vestingTerms.id === 'ADD'){
          const {founder} = values.params
          const vestingTermData = {
            name:`${founder.share[0].vestingTerms.vestingPeriod} ${FREQUENCY[founder.share[0].vestingTerms.vestingFrequency]}${founder.share[0].vestingTerms.isThereCliff?`, ${founder.share[0].vestingTerms.cliffPeriod}m cliff`:''}`,
            allocationType:'BACK_LOADED_TO_SINGLE_TRANCHE',
            vestingType:'STANDARD',
            standardTerm: {
              vestingPeriod:+founder.share[0].vestingTerms.vestingPeriod,
              vestingFrequency: founder.share[0].vestingTerms.vestingFrequency,
              cliffPeriod:+founder.share[0].vestingTerms.cliffPeriod,
              isThereCliff:founder.share[0].vestingTerms.isThereCliff
            }
          }
          const data =await createVestingTerms({groupCompanyId:values?.params?.company?.id,startupId:values.startupId,vestingTermData})
        adds['vestingTerm'] = data.createVestingTerm
      }
      if(values.params.founder.share[0].shareClass.id === 'ADD'){
        const {founder} = values.params
          const shareClassData = {
            name:founder.share[0].shareClass.name,
            defaultIdPrefix:founder.share[0].shareClass.name.split(' ').map(t=>t.split('')[0]).join(''),
            votesPerShare:1,
            preferred:false,
            status:{state:'DRAFT'}
          }
          const data =await createShareClass({groupCompanyId:values?.params?.company?.id,startupId:values.startupId,shareClassData})
          adds['shareClass'] = {...data.createShareClass,isGenerated:true}
      }
    }
    return {
        ...values,
        params:{
          ...values.params,
        founder:{
            ...values.params.founder,
            share:values.params.founder.isThereVesting?values.params.founder.share.map(sh=>({
                ...sh,
                shareClass:adds.shareClass || sh.shareClass,
                vestingTerms:{
                    ...sh.vestingTerms,
                    vestingTermId:adds.vestingTerm?.id || sh.vestingTerms.id
                }
            })):null
        }
      }
    }
    case 'USA_BOARD_APPROVING_OPTION_GRANT':
        const vestingTerms = []
        for(const grantee of (values?.params?.grantees || [])){
          if(grantee.vesting.id === 'ADD'){
            const {vesting} = values?.params?.grantee
            const vestingTermData ={
              name:`${vesting.vestingPeriod} ${FREQUENCY[vesting.vestingFrequency]}${vesting.isThereCliff?`, ${vesting.cliffPeriod}m cliff`:''}`,
              allocationType:'BACK_LOADED_TO_SINGLE_TRANCHE',
              vestingType:'STANDARD',
              standardTerm: {
                vestingPeriod:+vesting.vestingPeriod,
                vestingFrequency: vesting.vestingFrequency,
                cliffPeriod:+vesting.cliffPeriod,
                isThereCliff:vesting.isThereCliff
              }
            }
          const data =await createVestingTerms({groupCompanyId:values?.params?.company?.id,startupId:values.startupId,vestingTermData})
          vestingTerms.push(data.data.createVestingTerm)
          }
          else vestingTerms.push(grantee.vesting)
        }
        return {
          ...values,
          params:{
              ...values.params,
          grantees:values.params.grantees.map((g,index)=>({...g,vesting:get(vestingTerms[index],`standardTerm`)? {id:get(vestingTerms[index],`id`),...get(vestingTerms[index],`standardTerm`),vestingStartDate:g.vesting.vestingStartDate }: g.vesting})),
      }
  }
  case "FOUNDERS_AGREEEMENT":
    if(!values.params.companyNotEstablished){
      const shareClassVestingTerms = []
    for(const founder of values.params.founders){
      if(founder.share && founder.share.length > 0){
        let adds = {vestingTerm:founder.share[0].vestingTerms,shareClass:founder.share[0].shareClass};
        if(founder.share[0].vestingTerms.id === 'ADD'){
          const vestingTermData = {
            name:`${founder.share[0].vestingTerms.vestingPeriod} ${FREQUENCY[founder.share[0].vestingTerms.vestingFrequency]}${founder.share[0].vestingTerms.isThereCliff?`, ${founder.share[0].vestingTerms.cliffPeriod}m cliff`:''}`,
            allocationType:'BACK_LOADED_TO_SINGLE_TRANCHE',
            vestingType:'STANDARD',
            standardTerm: {
              vestingPeriod:+founder.share[0].vestingTerms.vestingPeriod,
              vestingFrequency: founder.share[0].vestingTerms.vestingFrequency,
              cliffPeriod:+founder.share[0].vestingTerms.cliffPeriod,
              isThereCliff:founder.share[0].vestingTerms.isThereCliff
            }
          }
        const data =await createVestingTerms({groupCompanyId:values?.params?.company?.id,startupId:values.startupId,vestingTermData})
        adds.vestingTerm = data.createVestingTerm
      }
      if(founder.share[0].shareClass.id === 'ADD'){
        if(!shareClassVestingTerms.some(sh=>sh.shareClass?.name === founder.share[0].shareClass.name)){
          const shareClassData = {
            name:founder.share[0].shareClass.name,
            defaultIdPrefix:founder.share[0].shareClass.name.split(' ').map(t=>t.split('')[0]).join(''),
            votesPerShare:1,
            preferred:false,
            status:{state:'DRAFT'}
          }
        const data =await createShareClass({groupCompanyId:values?.params?.company?.id,startupId:values.startupId,shareClassData})
        adds.shareClass = {...data.createShareClass,isGenerated:true}
      } else{
          adds.shareClass = shareClassVestingTerms.find(sh=>sh.shareClass?.name === founder.share[0].shareClass.name)?.shareClass
        }
      }
      shareClassVestingTerms.push({...adds,founderId:founder.id})
        }
      }
      return {
        ...values,
        params:{
            ...values.params,
        founders:values.params.founders.map((f,index)=>{
          const share = f.share.map((sh,index) => {
          if(index === 0){
          const vestingTermsShareClass = shareClassVestingTerms.find(s=>s.founderId===f.id)
          return {...sh,
            vestingTerms:{...sh.vestingTerms,vestingTermId:vestingTermsShareClass?.vestingTerm?.id || sh.vestingTerms?.id}
            ,shareClass:{...sh.shareClass,id:vestingTermsShareClass?.shareClass?.id || sh.shareClass.id}
          }
        }
        return sh
        })
        return {
          ...f,
          share
        }
      }),
    }
  }
    }
    return values
                default:
                return values
        }
    }
    return {save}
}
export default useGetSaveByForm

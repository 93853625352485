import _ from "lodash";
import { ServerConnect } from "../../../../utils";
import { 
  ADD_NEW_BANK_ACCOUNT,
  ADD_NEW_VESTING_TERMS,
  ADD_NEW_SHARE_CLASS,
  BOARD_RESOLUTION_CONVERTIBLE,
  BOARD_RESOLUTION_CONVERTIBLE_DOCUMENT_TYPES_LIST,
  BOARD_RESOLUTION_CONVERTIBLE_LABEL,
  USA_BOARD_CONSENT_SAFE,
  USA_BOARD_CONSENT_SAFE_DOCUMENT_TYPES_LIST,
  USA_BOARD_CONSENT_SAFE_LABEL } from './constants'
import includes from 'lodash/includes'
import map from 'lodash/map'
import * as numeral from "numeral";

export const FREQUENCY ={
  MONTHLY:'monthly',
  QUARTERLY:'quarterly',
  ANNUALLY:'annually'
}
const createBankAccount = async (values)=>{
        if(!values.params.terms.bankInfo.id){
        const data =await ServerConnect.graphQlMutation(ADD_NEW_BANK_ACCOUNT,{groupCompanyId:values?.params?.company?.id,startupId:values.startupId,bankAccount:values.params.terms.bankInfo})
        return data.addNewBankAccount
    }
     return {}
}
const createVestingTerms = async (values)=>{
  if(values.params.terms.vesting.id === 'ADD'){
    const {terms} = values.params
    const vestingTermData ={
      name:`${terms.vesting.vestingPeriod} ${FREQUENCY[terms.vesting.vestingFrequency]}${terms.vesting.isThereCliff?`, ${terms.vesting.cliffPeriod}m cliff`:''}`,
      allocationType:'BACK_LOADED_TO_SINGLE_TRANCHE',
      vestingType:'STANDARD',
      standardTerm: {
        vestingPeriod:+terms.vesting.vestingPeriod,
        vestingFrequency: terms.vesting.vestingFrequency,
        cliffPeriod:+terms.vesting.cliffPeriod,
        isThereCliff:terms.vesting.isThereCliff
      }
    }
  const data =await ServerConnect.graphQlMutation(ADD_NEW_VESTING_TERMS,{groupCompanyId:values?.params?.company?.id,startupId:values.startupId,vestingTermData})
  return data.createVestingTerm
}
return values.params.terms.vesting
}
const createManyVestingTerms =async (values:any)=>{
  const vestingTerms = []
  for(const grantee of (values?.params?.grantees || [])){
    if(grantee.vesting.id === 'ADD'){
      const {vesting} = grantee
      const vestingTermData ={
        name:`${vesting.vestingPeriod} ${FREQUENCY[vesting.vestingFrequency]}${vesting.isThereCliff?`, ${vesting.cliffPeriod}m cliff`:''}`,
        allocationType:'BACK_LOADED_TO_SINGLE_TRANCHE',
        vestingType:'STANDARD',
        standardTerm: {
          vestingPeriod:+vesting.vestingPeriod,
          vestingFrequency: vesting.vestingFrequency,
          cliffPeriod:+vesting.cliffPeriod,
          isThereCliff:vesting.isThereCliff
        }
      }
    const data =await ServerConnect.graphQlMutation(ADD_NEW_VESTING_TERMS,{groupCompanyId:values?.params?.company?.id,startupId:values.startupId,vestingTermData})
    vestingTerms.push(data.createVestingTerm)
    continue;
    }
    vestingTerms.push(grantee.vesting)
  }
  return vestingTerms
}
const createVestingTermsAndShareClass = async (values) =>{
  if(values.params.founder.share && values.params.founder.share.length > 0){
  let adds = {vestingTerm:values.params.founder.share[0].vestingTerms,shareClass:values.params.founder.share[0].shareClass};
  if(values.params.founder.share[0].vestingTerms.id === 'ADD'){
    const {founder} = values.params
    const vestingTermData = {
      name:`${founder.share[0].vestingTerms.vestingPeriod} ${FREQUENCY[founder.share[0].vestingTerms.vestingFrequency]}${founder.share[0].vestingTerms.isThereCliff?`, ${founder.share[0].vestingTerms.cliffPeriod}m cliff`:''}`,
      allocationType:'BACK_LOADED_TO_SINGLE_TRANCHE',
      vestingType:'STANDARD',
      standardTerm: {
        vestingPeriod:+founder.share[0].vestingTerms.vestingPeriod,
        vestingFrequency: founder.share[0].vestingTerms.vestingFrequency,
        cliffPeriod:+founder.share[0].vestingTerms.cliffPeriod,
        isThereCliff:founder.share[0].vestingTerms.isThereCliff
      }
    }
  const data =await ServerConnect.graphQlMutation(ADD_NEW_VESTING_TERMS,{groupCompanyId:values?.params?.company?.id,startupId:values.startupId,vestingTermData})
  adds.vestingTerm = data.createVestingTerm
}
if(values.params.founder.share[0].shareClass.id === 'ADD'){
  const {founder} = values.params
    const shareClassData = {
      name:founder.share[0].shareClass.name,
      defaultIdPrefix:founder.share[0].shareClass.name.split(' ').map(t=>t.split('')[0]).join(''),
      votesPerShare:1,
      preferred:false,
      status:{state:'DRAFT'}
    }
  const data =await ServerConnect.graphQlMutation(ADD_NEW_SHARE_CLASS,{groupCompanyId:values?.params?.company?.id,startupId:values.startupId,shareClassData})
  adds.shareClass = {...data.createShareClass,isGenerated:true}
}
return adds
  }
}
const createManyShareClassAndVestingTerms =async (values)=>{
  if(!values.params.companyNotEstablished){
    const shareClassVestingTerms = []
  for(const founder of values.params.founders){
    if(founder.share && founder.share.length > 0){
      let adds = {vestingTerm:founder.share[0].vestingTerms,shareClass:founder.share[0].shareClass};
      if(founder.share[0].vestingTerms?.id === 'ADD'){
        const vestingTermData = {
          name:`${founder.share[0].vestingTerms.vestingPeriod} ${FREQUENCY[founder.share[0].vestingTerms.vestingFrequency]}${founder.share[0].vestingTerms.isThereCliff?`, ${founder.share[0].vestingTerms.cliffPeriod}m cliff`:''}`,
          allocationType:'BACK_LOADED_TO_SINGLE_TRANCHE',
          vestingType:'STANDARD',
          standardTerm: {
            vestingPeriod:+founder.share[0].vestingTerms.vestingPeriod,
            vestingFrequency: founder.share[0].vestingTerms.vestingFrequency,
            cliffPeriod:+founder.share[0].vestingTerms.cliffPeriod,
            isThereCliff:founder.share[0].vestingTerms.isThereCliff
          }
        }
      const data =await ServerConnect.graphQlMutation(ADD_NEW_VESTING_TERMS,{groupCompanyId:values?.params?.company?.id,startupId:values.startupId,vestingTermData})
      adds.vestingTerm = data.createVestingTerm
    }
    if(founder.share[0].shareClass.id === 'ADD'){
      if(!shareClassVestingTerms.some(sh=>sh.shareClass?.name === founder.share[0].shareClass.name)){
        const shareClassData = {
          name:founder.share[0].shareClass.name,
          defaultIdPrefix:founder.share[0].shareClass.name.split(' ').map(t=>t.split('')[0]).join(''),
          votesPerShare:1,
          preferred:false,
          status:{state:'DRAFT'}
        }
      const data =await ServerConnect.graphQlMutation(ADD_NEW_SHARE_CLASS,{groupCompanyId:values?.params?.company?.id,startupId:values.startupId,shareClassData})
      adds.shareClass = {...data.createShareClass,isGenerated:true}
    }
    else{
      adds.shareClass = shareClassVestingTerms.find(sh=>sh.shareClass?.name === founder.share[0].shareClass.name)?.shareClass
    }
    }
    shareClassVestingTerms.push({...adds,founderId:founder.id})
      }
  }
  return shareClassVestingTerms
}
}
export const SaveByForm = async (documentType:string,values:any) =>{
    const saveByForm ={
        'CONVERTIBLE_NOTE':async ()=>createBankAccount(values),
        'SAFE': async ()=>createBankAccount(values),
        'GRANT_AGREEMENT':async ()=>createVestingTerms(values),
        USA_BOARD_APPROVING_OPTION_GRANT:async ()=>createManyVestingTerms(values),
        'FOUNDER_SERVICES_AGREEMENT':async ()=>createVestingTermsAndShareClass(values),
        'FOUNDERS_AGREEMENT':async()=>createManyShareClassAndVestingTerms(values)
        } 
        if(saveByForm[documentType]){
          return saveByForm[documentType]()
    }
}

export const getDocumentTypeListByDocumentType= (documentType,documentTypeList) =>{
        if(includes(BOARD_RESOLUTION_CONVERTIBLE, documentType)) return map(documentTypeList.filter((d) =>
          BOARD_RESOLUTION_CONVERTIBLE_DOCUMENT_TYPES_LIST.includes(d.id)
            ),docType=>({...docType,label:BOARD_RESOLUTION_CONVERTIBLE_LABEL[docType.id]}))
            if(includes(USA_BOARD_CONSENT_SAFE, documentType)) return map(documentTypeList.filter((d) =>
            USA_BOARD_CONSENT_SAFE_DOCUMENT_TYPES_LIST.includes(d.id)
              ),docType=>({...docType,label:USA_BOARD_CONSENT_SAFE_LABEL[docType.id]}))
          return documentTypeList
}
export const currencyFormat = (object:{currency?:string,amount?:number},_config)=>{
  const defaultConfig = {
    defaultEmpty: '-',
  };

  const config = { ...defaultConfig, ..._config };
  const { defaultEmpty } = config;

  if (!object) return defaultEmpty;

  const { currency, amount } = object;
  if (!currency || !amount) return defaultEmpty;
  return `${currency.toUpperCase()} ${numeral(+amount).format("0,0.00")}`;
}
export const amountFormat = (amount:number,_config?:any)=>{
  const defaultConfig = {
    defaultEmpty: '-',
  };

  const config = { ...defaultConfig, ..._config };
  const { defaultEmpty } = config;

  if (!amount) return defaultEmpty;
  return `${numeral(+amount).format("0,0[.]00")}`;
}
export const isItBoardConsentSafe = (documentType) => includes(USA_BOARD_CONSENT_SAFE, documentType)

export const stakeholderAuthorizedSignatory = (stakeholder) => {
    return {
        fullName: typeof stakeholder.authorizedSignatory === 'object'
            ? _.get(stakeholder, 'authorizedSignatory.fullName', '')
            : _.get(stakeholder, 'authorizedSignatory', ''),
        email: typeof stakeholder.authorizedSignatory === 'object'
            ? _.get(stakeholder, 'authorizedSignatory.email', stakeholder.email)
            : '',
        // stakeholder: _.get(stakeholder, 'authorizedSignatory.stakeholder')
    }
}
export const groupCompanyRecipientNotice = (formGroupCompany, groupCompany) => {
    return {
        ...(formGroupCompany && formGroupCompany.recipientNotice
            ? formGroupCompany.recipientNotice
            : _.get(groupCompany, 'recipientNotice.useRegisteredAddress', false)
                ? {
                    fullName: _.get(groupCompany, 'recipientNotice.fullName', ''),
                    email: _.get(groupCompany, 'recipientNotice.email', ''),
                    address: {
                        country: typeof  _.get(groupCompany, 'registeredAddress.country', null) ==='object'
                            ? _.get(groupCompany, 'registeredAddress.country.code', null)
                            : _.get(groupCompany, 'registeredAddress.country', null),
                        street: _.get(groupCompany, 'registeredAddress.street', ''),
                        city: _.get(groupCompany, 'registeredAddress.city', ''),
                        state: _.get(groupCompany, 'registeredAddress.state', ''),
                        zipCode: _.get(groupCompany, 'registeredAddress.zipCode', '')
                    }
                }
                : {
                    fullName: _.get(groupCompany, 'recipientNotice.fullName', ''),
                    email: _.get(groupCompany, 'recipientNotice.email', ''),
                    address: {
                        country: _.get(groupCompany, 'recipientNotice.address.country.code', null) || _.get(groupCompany, 'recipientNotice.address.country', null),
                        street: _.get(groupCompany, 'recipientNotice.address.street', ''),
                        city: _.get(groupCompany, 'recipientNotice.address.city', ''),
                        state: _.get(groupCompany, 'recipientNotice.address.state', ''),
                        zipCode: _.get(groupCompany, 'recipientNotice.address.zipCode', '')
                    }
                }
        )
    }
}
export const stakeholderRecipientNotice = (formStakeholder, stakeholder) => {
    return {
        ...(formStakeholder && formStakeholder.recipientNotice
            ? formStakeholder.recipientNotice
            : _.get(stakeholder, 'recipientNotice.useRegisteredAddress', false)
                ? {
                    fullName: _.get(stakeholder, 'recipientNotice.fullName', ''),
                    email: _.get(stakeholder, 'recipientNotice.email', ''),
                    address: {
                        country:typeof  _.get(stakeholder, 'address.country', null) ==='object'? _.get(stakeholder, 'address.country.code', null) : _.get(stakeholder, 'address.country', null),
                        street: _.get(stakeholder, 'address.street', ''),
                        city: _.get(stakeholder, 'address.city', ''),
                        state: _.get(stakeholder, 'address.state', ''),
                        zipCode: _.get(stakeholder, 'address.zipCode', '')
                    }
                }
                : {
                    fullName: _.get(stakeholder, 'recipientNotice.fullName', ''),
                    email: _.get(stakeholder, 'recipientNotice.email', ''),
                    address: {
                        country: _.get(stakeholder, 'recipientNotice.address.country.code', null) || _.get(stakeholder, 'recipientNotice.address.country', null),
                        street: _.get(stakeholder, 'recipientNotice.address.street', ''),
                        city: _.get(stakeholder, 'recipientNotice.address.city', ''),
                        state: _.get(stakeholder, 'recipientNotice.address.state', ''),
                        zipCode: _.get(stakeholder, 'recipientNotice.address.zipCode', '')
                    }
                }
        )
    }
}

import Machine from "../../../../generic/components/Machine/MachineClass";
import { Step2, Step1 } from './Steps';

const haveOldPassport = (context) => {
  return context.data.select_current.haveOldPassposrt
}

const machineDefinition = {
  id: 'GCC_Resident_ID',
  initial: 'upload_file',
  states: {
    upload_file: {
      on: {
        NEXT: { target: 'set_file_data', actions: 'setContext' },
        CANCEL: 'finish_stakeholder_assign',
      },
    },
    set_file_data: {
      on: {
        NEXT: { target: 'finish_stakeholder_assign', actions: 'setContext' },
        CANCEL: 'finish_stakeholder_assign',
      },
    },

    finish_stakeholder_assign: {
      type: 'final',
      entry: 'final',
    },
    onDone: {
      actions: 'stopMachine'
    }
  },
}

const steps = {
  upload_file: Step1,
  set_file_data: Step2
}

const options = {
  guards: {
    haveOldPassport,
  }
}



export default class MachineProfileStakeholder extends Machine {
  constructor() {
    super(machineDefinition, options, steps);
  }

  getParamsData(values) {
    return values;
  }
  /*
  
  getParamsData({ paramsMutation }) {
      return { ...paramsMutation }
  }*/
}

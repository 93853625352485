import { createMachine } from 'xstate';

const machine =
  createMachine({
    id: 'AddFormationCompany',
    initial: 'ChooseJurisdiction_data',
    states: {
      ChooseJurisdiction_data: {
        invoke: {
          src: 'ChooseJurisdiction_data',
          id: 'ChooseJurisdiction_data',
          onDone: [
            {
              target: 'ChooseJurisdiction',
              actions: ['setContextAfterInvoke']
            }
          ],
        }
      },
      ChooseJurisdiction: {
        on: {
          NEXT: {
            target: 'Evaluate_Jurisdiction',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      Evaluate_Jurisdiction: {
        always: [
          {
            cond: 'isADGM',
            target: 'GetProducts'
          },
          {
            cond: 'isCayman',
            target: 'AddFormationCompanyInCaymanSubMachineStep'
          },
          {
            cond: 'isDelawere',
            target: 'AddFormationCompanyInDelawareSubMachineStep'
          },
          {
            cond: 'isOther',
            target: 'SendMailCommingSoonJurisdiction'
          },
        ]
      },
      AddFormationCompanyInCaymanSubMachineStep: {
        on: {
          PREV: {
            target: 'ChooseJurisdiction',
          },
          NEXT: {
            target: 'finish',
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      AddFormationCompanyInDelawareSubMachineStep: {
        on: {
          PREV: {
            target: 'ChooseJurisdiction',
          },
          NEXT: {
            target: 'finish',
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      GetProducts: {
        invoke: {
          src: 'GetProducts',
          id: 'GetProducts',
          onDone: [
            {
              target: 'FormADGMType',
              actions: ['setContextAfterInvoke']
            }
          ],
          onError: [
            {
              target: 'ChooseJurisdiction',
            }
          ],
        }
      },
      FormADGMType: {
        on: {
          PREV: {
            target: 'ChooseJurisdiction',
          },
          NEXT: {
            target: 'Evaluate_FormADGMType',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      Evaluate_FormADGMType: {
        always: [
          {
            cond: 'isADGM_SPV',
            target: 'FormAdgnSpvTypeSubMachineStep'
          },
          {
            cond: 'isADGM_Tech',
            target: 'AdgmTechStartupSubMachineStep'
          },
        ]
      },
      AdgmTechStartupSubMachineStep: {
        on: {
          PREV: {
            target: 'GetProducts',
          },
          NEXT: {
            target: 'finish',
            actions: ['setContextAfterStep']
          },
          CHOOSE_JURISDICTION: {
            target: 'ChooseJurisdiction',
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      FormAdgnSpvTypeSubMachineStep: {
        on: {
          PREV: {
            target: 'GetProducts',
          },
          NEXT: {
            target: 'finish',
            actions: ['setContextAfterStep']
          },
          GO_TO_CAYMAN: {
            target: 'AddFormationCompanyInCaymanSubMachineStep',
          },
          GO_TO_ADGM_TS: {
            target: 'AdgmTechStartupSubMachineStep',
          },
          CHOOSE_JURISDICTION: {
            target: 'ChooseJurisdiction',
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      SendMailCommingSoonJurisdiction: {
        invoke: {
          src: 'SendMailCommingSoonJurisdiction',
          id: 'SendMailCommingSoonJurisdiction',
          onDone: [
            {
              target: 'ComingSoonJurisdiction',
              actions: ['setContextAfterInvoke']
            }
          ],
          onError: [
            {
              target: 'ChooseJurisdiction',
            }
          ],
        }
      },
      ComingSoonJurisdiction: {
        on: {
          NEXT: {
            target: 'Evaluate_Jurisdiction',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      previousMachine: {
        entry: 'previousMachine',
        type: 'final'
      },
      cancel: {
        entry: 'cancelMachine',
        type: 'final'
      },
      finish: {
        entry: 'stopMachine',
        type: 'final'
      }
    }
  });

export default machine;
import React from 'react';
import { Badge, BadgeProps } from '@material-ui/core';
import cls from './Badge.module.scss';
import ClassNames from 'classnames'

type verticalPosition = "top" | "center" | "bottom";
type horizontalPosition = "start" | "center" | "end";

type originPosition = "out" | "in" | "middle"

interface BadgeClaraProps extends BadgeProps {
    vertical?: verticalPosition,
    horinzontal?: horizontalPosition,
    originPosition?: originPosition

}

const BadgeClara: React.FC<BadgeClaraProps> = ({ children, classes = {}, vertical = 'top', horinzontal = 'end', originPosition = 'middle', ...props }) => {
    return (
        <Badge
            classes={{
                ...classes,
                badge: ClassNames(cls.badgeClara,
                    cls[`badge-postion-${horinzontal}-${vertical}-${originPosition}`],
                    { [cls.badgeShow]: !props.invisible },
                    classes['badge']
                )
            }} {...props}>
            {children}
        </Badge>
    )
}

export default BadgeClara

import { useMemo } from 'react';
import useBusinessRules from 'src/modules/clara/hooks/useBusinessRules';
import useSession from 'src/modules/session/hooks/useSession';


const useGuards = () => {

  const { isOwner } = useBusinessRules()
  const { session } = useSession()

  return useMemo(() => ({
    isCond_1: (context) => {
      return context?.SelectCertificate?.selectType === "register";
    },
    isCond_2: (context) => {
      return context?.SelectCertificate?.selectType === "certificate";
    },
    isCond_3: (context) => {
      return context?.SelectCertificate?.selectType === "extract";
    },
  }), [isOwner, session]);
}

export default useGuards;

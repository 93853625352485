import _ from 'lodash';
import useMutation from '../../../../hooks/custom/useMutation';
import { helper } from '../../../../v1/utils';

const CANCEL_REQUEST_SIGNATURES = `mutation voidRequestSignatures(
    $startupId: String!
    $agreementId: String!
    $voidReason: String!
  ) {
    voidRequestSignatures(
      startupId: $startupId
      agreementId: $agreementId
      voidReason: $voidReason
    ) {
      id
	  type {
		  code
		  allowedActions
	  }
	  status
	  relatedDocuments {
		  id
		  status
	  }
	  parentDocument {
		  id
		  status
	  }
    }
  }  
`;

const getAllowedActions = (actions, actionKey, defaultValue) => {
	if (_.isEmpty(actions)) {
		return defaultValue !== undefined ? defaultValue : true;
	}
	return helper.getValueFromKey(actions, actionKey, defaultValue);
};


const useCancelRequestSignatures = ({isRefetchQuery, ...props}) => {
	const shouldRefetchQuery = (observer) => {
		switch (observer.queryName) {
			case "getDocument":
				return isRefetchQuery
			default:
				return true;
		}
	};

	const onQueryUpdated = (observableQuery) =>{
		if (shouldRefetchQuery(observableQuery)) {
			return observableQuery.refetch();
		}
	}

	const updateCache = async (cache, { data }) => {
		const mutationName = Object.keys(data)[0];
		const allowedActions = _.get(data[mutationName],'type.allowedActions', []);
		const redirect = getAllowedActions(allowedActions, 'redirectAfterVoidDocument',false);

		if (redirect) {
			const identity = cache.identify(data[mutationName]);

			// Use this callback to execute redirect or destroy all elements data use the objects in the cache.
			if (!!_.get(props,"callbackBeforeEvict")) {
				await props.callbackBeforeEvict()
			}

			cache.evict({ id: identity, broadcast: false })
		}

		cache.gc()
	};

	const [mutation, loading] = useMutation(CANCEL_REQUEST_SIGNATURES, {shouldRefetchQuery}, {update: updateCache, onQueryUpdated, ...props });

    return { mutation, loading };
};

export default useCancelRequestSignatures;

import React from 'react';
import classes from './Line.module.scss';


const Line = ({ title, subTitle, children,className }) => {
    return (
        <React.Fragment>
            <hr className={`${classes.Line} ${className}` } />
        </React.Fragment >
    )
}

export default Line;
import * as Yup from 'yup';

import { default as React, useEffect, useMemo } from 'react';
import { addAssistantText, clearAssistatText } from 'src/modules/generic/store/action';

import Button from 'src/modules/generic/components/Buttons/Button';
import Form from 'src/components/forms/Form/Form';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateWizardInfo from "src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardInfo";
import Typography from 'src/modules/generic/components/Texts/Typography';
import classModule from "./classes.module.scss"
import reactImage from "../../../../../../../../../../images/renewalCalendar.svg";
import { useDispatch } from 'react-redux';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';

function CloseDownCompanyStepForm() {

  const { next, context, cancel, prev } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const strikeOff = useMemo(() => context.pincasData.pincasData.COMPANY_STRIKE_OFF, [context])

  // TODO CHANGE AMOUNT AND CURRENCY
  const Paragraph = () => {
    return <div className={classModule.Paragraph}>
      <Typography component='p' variant='h5'>
        <span dangerouslySetInnerHTML={{ __html: translate('MODULES.RENEWALS.CLOSEDOWNCOMPANY.STEP_1.CONTENT_1', { currency: "USD", amount: strikeOff?.value }) }}></span>
      </Typography>
    </div>
  }

  const initialValues = useMemo(() => {
    return context?.CloseDownCompanyStepForm ?? {}
  }, [context]);

  const handleCancel = (values) => {
    cancel();
  }

  const handlePrev = (values) => {
    prev();
  }

  const handleSubmit = async (values) => {
    next(values)
  }

  useEffect(() => {
    dispatch(addAssistantText(translate('MODULES.RENEWALS.CLOSEDOWNCOMPANY.STEP_1.TOOLTIP_1')))
    return () => {
      dispatch(clearAssistatText())
    }
  }, [dispatch])

  const schema = Yup.object().shape({});

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizardInfo
        props={{
          title: translate('MODULES.RENEWALS.CLOSEDOWNCOMPANY.STEP_1.TITLE'),
          subTitle: translate('MODULES.RENEWALS.CLOSEDOWNCOMPANY.STEP_1.SUBTITLE', { companyName: context?.initialData?.company?.name ?? "Company Name"}),
          image: reactImage,
          isRenewalImage: true,
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          previous: <Button onClick={handlePrev}>Previous</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('MODULES.RENEWALS.CLOSEDOWNCOMPANY.STEP_1.BTN_START')}</SubmitButton>,
        }}
      >
        <>
          <Paragraph />
        </>
      </TemplateWizardInfo>
    </Form>
  );
}

export default CloseDownCompanyStepForm;


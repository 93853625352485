import { Field, FieldArray } from "formik"
import React from "react"
import Line from "src/components/generic/Line/Line"
import { TextInput } from 'src/v1/components'
import AddInput from "src/v1/containers/Forms/Inputs/AddInput"
import classes from '../DocumentUploadForm.module.scss'
const DocumentTypeFieldDefault = ({ parties, addParty,tooltips,handleChange }) => {
    return (<>
        <FieldArray
            name='parties'
            render={(arrayHelpers) => (
                <div>
                    {parties.map(p => (p))}
                    <Line className={`${classes.Separator} ${classes.partiesSelectSeparator}`} />
                    <div className={`${classes.ContainerRow}`}>
                        <AddInput
                            arrayHelpers={arrayHelpers}
                            label={"Add Party"}
                            onClickAdd={addParty}
                        />
                    </div>
                </div>
            )}
        />
        <Line className={classes.Separator} />
        <Field name='executedOnDate'>
            {({ field, form }) => (
                <TextInput
                    type='date'
                    label='Execution Date:'
                    field={field}
                    form={form}
                    messageErrorClassName={classes.MessageErrorDate}
                    classNameInput={{ input: classes.Calendars, root: classes.labelCalendar }}
                    placeholder={"Date"}
                    tooltip={tooltips['Execution Date']}
                    prevComponentChange={e =>
                        handleChange(e, field, form, false)
                    }
                >
                </TextInput>
            )}
        </Field>
        <Field name='expirationDate'>
            {({ field, form }) => (
                <TextInput
                    type='date'
                    label='Expiration Date:'
                    field={field}
                    classNameInput={{ input: classes.Calendars, root: classes.labelCalendar }}
                    form={form}
                    placeholder={"Date"}
                    messageErrorClassName={classes.MessageErrorDate}
                    tooltip={tooltips['Expiration Date']}
                    prevComponentChange={e =>
                        handleChange(e, field, form, true)
                    }
                >
                </TextInput>
            )}
        </Field>
    </>
    )
}
export default DocumentTypeFieldDefault;
import React, { FC } from "react";
import classesModules from './DetailsDocumentGeneratedSkeleton.module.scss';
import classes from "./Styles.module.scss";
import Skeleton from '@material-ui/lab/Skeleton';
import Line from '../../../../../components/generic/Line/Line';

/**
 * Template Details Document Generated Skeleton
 */

export interface DetailsDocumentGeneratedSkeletonProps {

} 

const DetailsDocumentGeneratedSkeleton: FC<DetailsDocumentGeneratedSkeletonProps> = ({

  ...props
}:DetailsDocumentGeneratedSkeletonProps) => {

  return (
    <>
      <div className={classes.containerDetailsUpload}>
        <h1 className={classes.titleDocument}>
          <Skeleton variant="text" height={"2.1538rem"} width={"10rem"} />
        </h1>
        <div className={classes.containerPanel}>
          <div className={classesModules.containerCategories}>
            <div className={classesModules.containerLabelText}>
              <label>
                <Skeleton variant="text" height={"1.0769rem"} width={"6.5rem"} />
              </label>
              <span><Skeleton variant="text" height={"1.0769rem"} width={"6.5rem"} /></span>
            </div>
            <div className={classesModules.containerLabelText}>
              <label>
                <Skeleton variant="text" height={"1.0769rem"} width={"6.5rem"} />
              </label>
              <span><Skeleton variant="text" height={"1.0769rem"} width={"6.5rem"} /></span>
            </div>
            <div className={classesModules.containerLabelText}>
              <label>
                <Skeleton variant="text" height={"1.0769rem"} width={"6.5rem"} />
              </label>
              <span><Skeleton variant="text" height={"1.0769rem"} width={"6.5rem"} /></span>
            </div>
          </div>
          <Line />
          <div className={classesModules.containerDates}>
            {Array(2).fill(1).map((el, i) =>
              <div className={classesModules.IconBlock} key={i}>
                <Skeleton variant="rect" height={"1.6rem"} width={"1.6rem"} />
                <div><Skeleton variant="text" height={"1.0769rem"} width={"5rem"} /></div>
                <div><Skeleton variant="text" height={"1.0769rem"} width={"5rem"} /></div>
              </div>
            )}
          </div>
          <div className={classesModules.containerTabsMenu}>
            <div className={classesModules.TabArea}>
              <div className={`${classesModules.skelTabs}`}>
                <div className={`${classesModules.skelTabsSpace}`}>
                  <Skeleton variant="text" height={"1.0769rem"} width={"4rem"}/>
                </div>
                <div className={`${classesModules.skelTabsSpace}`}>
                  <Skeleton variant="text" height={"1.0769rem"} width={"4rem"}/>
                </div>
                <div className={`${classesModules.skelTabsSpace}`}>
                  <Skeleton variant="text" height={"1.0769rem"} width={"4rem"}/>
                </div>
              </div>
            </div>
            <div className={classesModules.containerTab}>
              <div className={classesModules.containerTabPartiesUploaded}>
                <div className={classes.initialIcon}>
                  <Skeleton variant="text" height={"1.0769rem"} width={"8rem"}/>
                </div>
                <div className={classesModules.containerAvatar}>
                  <Skeleton variant="rect" height={"2.4615rem"} width={"2.4615rem"}/>
                  <div className={classesModules.containerText}>
                    <label><Skeleton variant="text" height={"1.0769rem"} width={"4rem"}/></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.containerPreviewV4}></div>
      </div>
    </>
  );
};

export default DetailsDocumentGeneratedSkeleton;

import React, { FC, useRef } from 'react'
import useTranslate from "../../../../../generic/hooks/useTranslate";
import Form from "../../../../../../components/forms/Form/TestForm";
import ControllerInput from "../../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import Checkbox from 'src/components/inputs/Checkbox/Checkbox';
import SubmitButton from "../../../../../generic/components/Buttons/SubmitButton";
import TemplateFormDefault from "../../../../../generic/templates/Modal";
import FormProps from "../../../../../generic/forms/interfaces/FormProps";
import schema from './schema'
import TemplateLabel from "../../../../../generic/components/Inputs/LabelInput";
import AutocompleteClara from '../../../../../generic/components/Inputs/Autocomplete';
import classes from './classes.module.scss'
interface GroupCompany {
    name: string
    id: string
}
interface IndividualOrCompanyStakeholderFormProps extends FormProps {
    isIndividual?: boolean
    groupCompanies: GroupCompany[]
    stakeholders?: [any]
}
const IndividualOrCompanyStakeholderForm: FC<IndividualOrCompanyStakeholderFormProps> = ({ groupCompanies, stakeholders, isIndividual, initialValues, onCompleteSubmit, Template = TemplateFormDefault, buttons = {}, buttonSubmitProps, propsTemplate }: IndividualOrCompanyStakeholderFormProps) => {
    const { translate } = useTranslate()

    const stakeholdersList = React.useMemo(() => {
        return stakeholders.filter(st => st.isAnEntity !== isIndividual).map(st => ({ value: { id: st.id }, label: st.fullName }));
    }, [JSON.stringify(stakeholders)])
    const refForm = useRef();

    const createStakeholder = (value: string) => {
        return { fullName: value };
    }

    const handleSubmit = async (values) => {
        const { stakeholder } = values;
        await onCompleteSubmit({ ...values, stakeholder: { ...stakeholder, isAnEntity: !isIndividual } });
    }
    return (
        <>

            <Form
                initialValues={initialValues}
                schema={schema}
                onlySendDirty={false}
                onSubmit={handleSubmit}
                ref={refForm} optionsForm={{ mode: "onChange" }}
            >
                <Template
                    buttons={{ ...buttons, submit: <SubmitButton {...buttonSubmitProps}>Add</SubmitButton> }}
                    props={propsTemplate}
                >
                    <TemplateLabel isRequired label={isIndividual ? translate("MODULES.ONBOARDING_FORMS.INDIVIDUAL_OR_COMPANY_FORM.FULLNAME") : translate("MODULES.ONBOARDING_FORMS.INDIVIDUAL_OR_COMPANY_FORM.NAME")}>
                        <ControllerInput
                            render={AutocompleteClara}
                            getCreateValue={createStakeholder}
                            name='stakeholder'
                            options={stakeholdersList}
                            placeholder={isIndividual ? translate("MODULES.ONBOARDING_FORMS.INDIVIDUAL_OR_COMPANY_FORM.FULLNAME_PLACEHOLDER") : translate("MODULES.ONBOARDING_FORMS.INDIVIDUAL_OR_COMPANY_FORM.NAME_PLACEHOLDER")}
                            defaultValue={''}
                        />
                    </TemplateLabel>
                    <TemplateLabel label={translate("MODULES.ONBOARDING_FORMS.INDIVIDUAL_OR_COMPANY_FORM.COMPANY")} isRequired={true}>
                        <ControllerInput
                            render={Checkbox}
                            name='company'
                            variant='col_1'
                            classCheckboxLine={classes.separation}
                            functionCompare={(value1, value2) => value1.id === value2.id}
                            options={groupCompanies}
                        />
                    </TemplateLabel>
                </Template>
            </Form>
        </>
    )
}

export default IndividualOrCompanyStakeholderForm

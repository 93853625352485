
import { useEffect } from 'react';
import useQuery from "../../../custom/useQuery";

const query = `query {
  getMe {
    user_hash
    _id
    devices {
      id
      name
      date
      lastLogin
      reason
      state
      ip
    }
    personalInfo {
      phoneNumber
    }
    fullName
    email
    avatar
    acceptMarketingEmailOn
    createdAt
    updatedAt
    firstName
    lastName
  }
}`;
const useGetMe = (variables) =>{
    
    const {loading, error, data,refetch} = useQuery(query,variables);
    useEffect(() => {
        
    }, []);

    return  {loading,error,data,refetch} ;
}
export default useGetMe;
import React from "react";
import classes from "./classes.module.scss";
import useTranslate from "../../../generic/hooks/useTranslate";
import TypographyLocal from "../../../generic/components/Texts/Typography";
import Text from "../../../../components/text/Text/Text";
import Button from "../../../generic/components/Buttons/Button";
import useNavigation from "../../../generic/hooks/useNavigation";
import { Constants } from '../../../../v1/utils/constants';
import setStatusMatter from "../../../../hooks/services/modules/matter/setStatusMatter.js";

const JurisdictionDone = () => {

  const { translate } = useTranslate();
  const {goTo, params} = useNavigation();
  const {mutation} = setStatusMatter({
    showSuccessNotification: false,
  });

  const handleDone = () => {
    return new Promise( async (resolve, reject) => {
      const variables = {
        matterId: params.matterId,
        state: "CLOSED",
        declineReason: translate("MODULES.FORMATION.SELECT_JURISDICTION_ADGM_DISAGREE_MSG"),
      }
      await mutation(variables);
      goTo(Constants.PAGES.switchStartup, params);
      resolve();
    })
  }

  return (
    <React.Fragment>
      <div className={classes.contentData}>
        <div className={classes.centerFrame}>
          <div className={classes.claraTitle}>
            <TypographyLocal
              component={"span"}
              color={"black"}
              className={classes.flowTitle}
            >
              <Text uuid={"MODULES.FORMATION.SELECT_JURISDICTION_TITLE"} />
            </TypographyLocal>
          </div>
          <div className={classes.claraQuestion}>
            <TypographyLocal
              variant={"h4"}
              color={"black"}
              component={"span"}
            >
              {translate("MODULES.FORMATION.SELECT_JURISDICTION_DONE_QUESTION")}
            </TypographyLocal>
          </div>          
          <div className={classes.claraInfo}>
            <TypographyLocal
              variant={"h4"}
              color={"black"}
              component={"span"}
            >
              {translate("MODULES.FORMATION.SELECT_JURISDICTION_DONE_INFO")}
            </TypographyLocal>
          </div>          
          <div className={classes.buttonsContainer}>
            <Button
              variant={"primary"}
              onClick={handleDone}
            >
              {translate("MODULES.FORMATION.SELECT_JURISDICTION_DONE_BTN")}
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default JurisdictionDone;

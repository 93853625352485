import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TextInput from 'src/components/inputs/Text/TextInput';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateLabel from 'src/modules/generic/components/Inputs/LabelInput';
import * as Yup from 'yup';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText,addAssistantText } from 'src/modules/generic/store/action';
import _ from 'lodash';
import Typography from 'src/modules/generic/components/Texts/Typography';
import classes from '../classes.module.scss';
import CheckboxButtonSimple, {
  CheckboxButtonOptions,
} from 'src/modules/generic/components/Inputs/CheckboxButtonSimple';

/**
* Description Step: description to add 
* 
* ***** Implementation (Delete after to use) *****
* Add to useStep:
* 
* @returns 
*/
function WhoIsGoingToBeYourAuthorisedSignatoryFormStep() {

  const { next, context, prev,cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  
  const {initialValues, stakeholders } = useMemo(() => {

    const initialValues = context?.AddNomineeAuthorisedSignatoryStep ?? {}
    
    const stakeholders = _.map(_.get(context, "initialData.manualQueryUAEorGCCNotRole", []), (st) => {
      return { value: st, label: st.fullName };
    })
    
    stakeholders.push({ value: {value:'ADD_THIRD_PARTY'}, label: 'Add third party' })

    return { initialValues, stakeholders }
  }, [context]);
  
  const handleCancel = (values) => {
    cancel();
  }
  const handlePrev = (values) => {
    prev();
    
  }
  const handleSubmit = async (values) => {
  
    next(values)
  }
  
  useEffect( ()=>{
    dispatch(addAssistantText('If you are adding a new third party, this stakeholder will be added to the map and you will have to complete formation tasks for them too.' ))
    return () => {
      dispatch(clearAssistatText())
    }
  },[dispatch])
  
  const schema = Yup.object().shape({
    authorisedSignatory: Yup.object().nullable().required('Required'),   

  })


  return (
  
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={ { mode: 'onChange' } }
    >
      <TemplateWizard
        props={ {
          title: 'Who is going to be your ADGM Authorised Signatory?',
          skeleton: null,
          subTitle: 'ADGM Authorised Signatory',
          variant: 'simple'
        } }
        
        buttons={ {
          previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('BUTTON_NEXT')}</SubmitButton>,
        } }
      >
        
         
        <Typography weight='regular' variant='xbody'>
          You can appoint someone who is not a director or shareholder but they must be a UAE Resident or GCC National.
        </Typography>

        <ControllerInput
            as={CheckboxButtonSimple}
            isMultiple={false}
            defaultValue={[]}
            name="authorisedSignatory"
            options={stakeholders}
            classNameButton={classes.buttonCheckBox}
            size="lg"
          />
        
        
      </TemplateWizard>
    </Form>
  );
}

export default WhoIsGoingToBeYourAuthorisedSignatoryFormStep;


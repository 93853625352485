import useQuery from "../../../../../hooks/custom/useQuery";
import {FormationDataDirectors} from '../fragments'
import _ from "lodash";
const GET_COMPANY_DIRECTORS_QUERY = `
${FormationDataDirectors}
query getFormationCompanyDirectors($startupId: ID!, $groupCompanyId: ID!) {
  getGroupCompany(startupId: $startupId, groupCompanyId: $groupCompanyId) {
    id
    formationData {
      ...FormationDataDirectors
    }
  }
}`

const useGetFormationCompanyDirectors =(variables) => {
    const { loading, error, data, refetch } = useQuery(GET_COMPANY_DIRECTORS_QUERY, variables);
    return { loading, error, data: _.get(data, "getGroupCompany", []), refetch };
}
export default useGetFormationCompanyDirectors
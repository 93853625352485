import DELETE from "../Delete/langs/index";
import FORM from "../Forms/langs";
import SETUP from "../ADGM/SetUp/langs";
import BANKING_AUTHORITIES from "../ADGM/BankingAuthority/langs";
import ASSETS_AND_REVENUES from "../ADGM/AssetsAndSources/langs";
import SHARE_CAPITAL from "../ADGM/ShareCapital/langs";
import SHARE_CAPITAL_DELAWARE from "../DELAWARE/ShareCapital/langs";
import SHARE_CAPITAL_ADGM_CAYMAN from "../ShareCapital/langs";
import CONFIRM_SHAREHOLDERS_DIRECTORS from "../ADGM/ConfirmShareholdersDirectors/langs";
import NATURE_OF_BUSINESS from "../KY/NatureOfBusiness/langs";
import CONFIRM from "../Confirm/langs";
import UPLOAD_CERTIFICATE_OF_INCORPORATION from '../ADGM/CertificateOfIncorportation/langs'
import EDIT_MANAGE_FUTURE_LINKED_COMPANIES from  '../ADGM/EditManageFutureLinkedCompanies/langs'
import ENGAGE_CONCIERGE from '../ADGM/EngageConcierge/langs'
import SUBMITTED_FORMATION from '../ADGM/SubmittedFormation/langs'
import DOCUMENT_REVIEW from '../DocumentReview/langs'

export default {
  DELETE: DELETE.US,
  FORM: FORM.US,
  SETUP: SETUP.US,
  BANKING_AUTHORITIES: BANKING_AUTHORITIES.US,
  ASSETS_AND_REVENUES: ASSETS_AND_REVENUES.US,
  SHARE_CAPITAL: SHARE_CAPITAL.US,
  SHARE_CAPITAL_DELAWARE: SHARE_CAPITAL_DELAWARE.US,
  SHARE_CAPITAL_ADGM_CAYMAN: SHARE_CAPITAL_ADGM_CAYMAN.US,
  CONFIRM_SHAREHOLDERS_DIRECTORS: CONFIRM_SHAREHOLDERS_DIRECTORS.US,
  NATURE_OF_BUSINESS: NATURE_OF_BUSINESS.US,
  CONFIRM: CONFIRM.US,
  CERTIFICATE_OF_INCORPORATION:UPLOAD_CERTIFICATE_OF_INCORPORATION.US,
  EDIT_MANAGE_FUTURE_LINKED_COMPANIES:EDIT_MANAGE_FUTURE_LINKED_COMPANIES.US,
  ENGAGE_CONCIERGE:ENGAGE_CONCIERGE.US,
  SUBMITTED_FORMATION:SUBMITTED_FORMATION.US,
  DOCUMENT_REVIEW:DOCUMENT_REVIEW.US
};

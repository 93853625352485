import { Field, Formik } from 'formik';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ClaraCheckForm, ClaraRadioWithButton, Text } from '../../../../components';
import FactoryChatForm from '../../../../components/Chat/FactoryChatForm';
import FactoryChatFormConfig from '../../../../components/Chat/FactoryChatFormConfig';
import RegistedAddresInput from "../../KYC/RegisteredAddressInput/RegistedAddressInput";
import classesForm from '../Form.module.scss';
import classes from './BanckService.module.scss';


const BanckServiceForm = React.memo(({ authorizedSignatories, initialValues, onSubmit, closeForm }) => {


    const [openModal, setOpenModal] = useState(false);
    const [authorizedSignatoriesList, setAuthorizedSignatoriesList] = useState([]);
    const params = useParams();
    const [refresh, setRefresh] = useState(1);

    const refreshData = () => {
        setRefresh(refresh + 1)
    }

    useEffect(() => {
        setAuthorizedSignatoriesList(_.map(authorizedSignatories, (e) => {
            return _.pick(e, ["id", "firstName", "lastName"])
        }))
    }, [])

    const handleClose = () => {
        setOpenModal(false)
    }

    useEffect(() => {


    }, [])


    return (
        <React.Fragment>

            {
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validate={null}
                    onSubmit={onSubmit}
                    isInitialValid
                >

                    {({
                        values,
                        errors,
                        dirty,
                        handleSubmit,
                        isSubmitting,
                        handleReset,
                        setFieldValue,
                        initialValues,
                        isValid,
                        setFieldError,
                        validateForm,
                    }) => {

                        return (

                            <React.Fragment>
                                <div className={classesForm.containerData} >
                                    <React.Fragment>
                                        <div className={`${classes.ChatQuestionnaire} ${classes.ChatAdditionalSignature}`}>
                                            <div className={classes.contentField}>
                                                <button className={` ${classes.buttonOption} `} type="button" onClick={() => { setOpenModal(true) }}>
                                                    <Text uuid={`QUESTIONARY_ADDITIONAL_SIGNATURE_BUTTON`} />
                                                </button>
                                            </div>

                                            <Field name={"bankAccountOpening"}>
                                                {({ field, form }) => (

                                                    <ClaraRadioWithButton
                                                        field={field}
                                                        form={form}
                                                        changeParams={field.name}
                                                        label={"Bank account opening and operating authories required:"}
                                                        options={
                                                            [{
                                                                "value": true,
                                                                "label": "Yes"
                                                            },
                                                            {
                                                                "value": false,
                                                                "label": "No"
                                                            }]
                                                        }
                                                        className={{ input: classes.horizontalOptionsButton }}
                                                    />
                                                )}
                                            </Field>
                                            <div className={classes.contentField}>
                                                <label>Signatories</label>
                                                <Field name={"authorizedSignatories"}>
                                                    {({ field, form }) => (
                                                        <ClaraCheckForm
                                                            openForm={openModal}
                                                            cbClose={handleClose}
                                                            cbSaveForm={refreshData}
                                                            field={field}
                                                            form={form}
                                                            list={authorizedSignatoriesList}
                                                            refresh={refresh}
                                                            initialValues={{ formKey: "INDIVIDUAL_FORM" }}
                                                            optionsList={{
                                                                fieldId: _.get(FactoryChatFormConfig, "INDIVIDUAL_BAS_QUESTIONNAIRE_FORM" + ".id", "id"),
                                                                fieldLabel: _.get(FactoryChatFormConfig, "INDIVIDUAL_BAS_QUESTIONNAIRE_FORM" + ".fieldName", null),
                                                            }}
                                                            optionsForm={{
                                                                titleKey: _.get(FactoryChatFormConfig, "INDIVIDUAL_BAS_QUESTIONNAIRE_FORM.titleKey", null),
                                                                educationId: _.get(FactoryChatFormConfig, "INDIVIDUAL_BAS_QUESTIONNAIRE_FORM.leftEducationId", null),
                                                                muttation: _.get(FactoryChatFormConfig, "INDIVIDUAL_BAS_QUESTIONNAIRE_FORM.muttation", null),
                                                                muttationRemove: _.get(FactoryChatFormConfig, "INDIVIDUAL_BAS_QUESTIONNAIRE_FORM.muttationRemove", null),
                                                                getVariablesMutationRemove: _.get(FactoryChatFormConfig, "INDIVIDUAL_BAS_QUESTIONNAIRE_FORM.getVariablesMutationRemove", null),
                                                                getIdFromMuttation: _.get(FactoryChatFormConfig, "INDIVIDUAL_BAS_QUESTIONNAIRE_FORM.getIdFromMuttation", null),
                                                                paramsMuttaion: { matterId: params.matterId },
                                                                getVariablesMutation: _.get(FactoryChatFormConfig, "INDIVIDUAL_BAS_QUESTIONNAIRE_FORM.getVariablesMutation", null),
                                                                succesNotification: "NOTIFICATION_SUCCESS",
                                                                errorNotification: "NOTIFICATION_BAD",
                                                            }}
                                                            optionsSelect={{
                                                                auto: true,
                                                                autoLoaderData: false,
                                                                canSelect: true,

                                                            }}
                                                            optionsOperation={{
                                                                canAdd: false,
                                                                canEdit: true,
                                                                canRemove: true,
                                                                canRemoveOnlyNews: true,
                                                                canEditOnlyNews: true,
                                                            }}
                                                            optionsView={{
                                                                recordsByLine: 5
                                                            }}
                                                            className={{ ContentAvatar: classes.ContentAvatar }}
                                                        >
                                                            <FactoryChatForm formKey={"INDIVIDUAL_FORM"} ></FactoryChatForm>
                                                        </ClaraCheckForm>
                                                    )}
                                                </Field>
                                            </div>
                                        </div>
                                        <Field name={"requireToSignJointly"}>
                                            {({ field, form }) => (

                                                <ClaraRadioWithButton
                                                    field={field}
                                                    form={form}
                                                    changeParams={field.name}
                                                    label={"Jointly or singly signed:"}
                                                    options={
                                                        [{
                                                            "value": true,
                                                            "label": "Jointly"
                                                        },
                                                        {
                                                            "value": false,
                                                            "label": "Singly"
                                                        }]
                                                    }
                                                    className={{ input: classes.horizontalOptionsButton }}
                                                />
                                            )}
                                        </Field>
                                        <RegistedAddresInput name={"bankingAddress"} label={"Address:"} />
                                    </React.Fragment >


                                    <hr></hr>
                                    <div className={`botoneraButtons ${classesForm.containerButtons}`}>
                                        <button className={`genericBlue`} onClick={closeForm} >Cancel</button>
                                        <button className={`genericBlue`} onClick={handleSubmit} disabled={!isValid || (_.get(values, 'bankAccountOpening', null) ? _.get(values, 'authorizedSignatories', []).length < 1 : false)} >Update </button>
                                    </div>

                                </div>

                            </React.Fragment>
                        )

                    }
                    }
                </Formik>
            }
        </React.Fragment>
    )
})

export default BanckServiceForm;



import React, {useEffect} from 'react';
import classesModule from "./IconComment.module.scss"
import _ from "lodash"
// Example : <IconComment cant={12} hasNews={true}/>

export default function ({ cant,hasNews,classes }) {
    const rootClass = _.get(classes,"root",null);
    useEffect(() => {

    })
    return (
        <React.Fragment>
            <div className={`${classesModule.IconComment} ${rootClass}`}>
                <i className={"icon-comment"} />
                {hasNews?(<span></span>):null}
                <label>{cant>99?99:cant}</label>
            </div>
        </React.Fragment>
    );
}
import { Stack } from "@mui/material";
import classnames from "classnames";
import _ from "lodash";
import React, { useMemo } from "react";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import { StepsDots } from "../../../components/Steps";
import cls from "./classes.module.scss";

const TemplateFormTwoColumnsSimple = ({
  steper,
  buttons,
  leftComponent,
  rightComponent,
  contendType,
  variant = "right"
}) => {

  const { stepper } = useMachine();

  const { steps, actualStep, setActualStep } = useMemo(() => {
    let actualStep = null
    let index = 0;
    let disabled = false;
    let viewed = true;
    const steps = _.map(stepper, (step) => {
      if (step.code === steper) {
        actualStep = index;
      }
      if (actualStep && index > actualStep) {
        viewed = false;
        disabled = true;
      }
      index = index + 1
      return {
        viewed: viewed,
        disabled: disabled,
        title: step.label,
        value: step.code
      }
    });
    return {
      steps: steps,
      actualStep: actualStep,
      setActualStep: () => { }
    }
  }, [steper, stepper]);

  return (
    <>
      <div className={`${cls["container-blocks-modal-tour"]} ${cls[variant]}`}>
        <div className={cls["leftContentBlockModalTour"]}>
          {leftComponent}
        </div>
        <div className={classnames(cls["right-content-block-modal-tour"], {
          [cls["container-RightContentOnboarding-card"]]: contendType === "CARD",
          [cls["container-RightContentOnboarding-card-top"]]: contendType === "CARD-TOP",
        })}
        >
          {rightComponent}
        </div>
      </div>
      <div className={`${cls["container-buttons-modal-tour"]}`}>
        <StepsDots
          className={`container-steps-dots ${cls["container-steps-dots-button"]}`}
          steps={steps}
          actualStep={actualStep}
          setStep={setActualStep}
        />
        <Stack direction={"row"} justifyContent={"space-between"} alignItems="center" >
          <Stack direction={"row"} justifyContent={"flex-end"} alignItems="center" gap={3} >
            {buttons.previous}
            {buttons.submit}
          </Stack>
        </Stack>
      </div>
    </>
  );
};

export default TemplateFormTwoColumnsSimple;

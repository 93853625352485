

import { Stack } from "@mui/material";
import { useCallback, useMemo } from 'react';
import Form from "src/components/forms/Form/FormL";
import CountrySelector from "src/components/inputs/Select/CountrySelector/CountrySelector";
import TextInput from "src/components/inputs/Text/TextInput";
import Button from "src/modules/generic/components/Buttons/Button";
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import Typography from "src/modules/generic/components/Texts/Typography";
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from "src/modules/generic/hooks/useTranslate";
import TemplateFormTwoColumnsWizard from 'src/modules/generic/templates/Modal/TemplateFormTwoColumnsWizard';
import FileUploaderInput from "src/components/inputs/FileUploader/FileUploaderInput";
import img from "src/modules/Onboarding/images/tell-us-about-your-startup.svg";
import * as Yup from 'yup';
import { Constants } from "src/v1/utils/constants";

import cls from "./classes.module.scss";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
/**
 * Step to decide the goal of the startup
 * @param {*} param0
 * @returns
 */
function SipStep({ submitButonProps, steper, ...props }) {
  const { next, prev, context,cancel } = useMachine();
  const { translate } = useTranslate();
  const schema = Yup.object().shape({
    name: Yup.string().nullable().required('Required'),
    file: Yup.object().nullable().required('Required')
  });
  const contextValues = context?.TellUsAboutStep ?? {
    location: "",
    website: ""
  };
  const handleNext = useCallback(
    (values) => {
      next(values);
    },
    [next]
  );
  const handleCancel = useCallback(
    (values) => {
      cancel(values);
    },
    [cancel]
  );
  

  return (
    <Form
      onlySendDirty={false}
      defaultValues={contextValues}
      template={TemplateFormTwoColumnsWizard}
      schema={schema}
      onSubmit={handleNext}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard buttons={{
        submit: <SubmitButton {...submitButonProps} >Add</SubmitButton>,
        cancel: <Button onClick={handleCancel}>Cancel</Button>
      }}
        steper={steper}
        props={
          { subTitle: translate("MODULES.ASSISTANCEV4.ASSISTANCE_TASKS_GROUP_COMPANY.TASK_ADD_SHARE_INCENTIVE_PLAN_AGREEMENT",context?.getInitialData ?? {}), title: 'Upload Share Incentive Plan (SIP)' }
        }
      >
        <TemplateLabel label={"Document name"} isRequired={true}>
          <ControllerInput
            as={TextInput}
            defaultValue=""
            name={"name"}
            placeholder={translate('MODULES.EQUITY.FORMS.UPLOAD_DOCUMENT.PLACEHOLDER_NAME')}
            onChange={(e) => {

            }}
          />
        </TemplateLabel>


        <TemplateLabel label={translate('MODULES.EQUITY.FORMS.UPLOAD_DOCUMENT.FILE')} isRequired={true}>
          <ControllerInput
            as={FileUploaderInput}
            defaultValue=""
            name={"file"}
            onChange={(e) => {

            }}
          />
        </TemplateLabel>


      </TemplateWizard>

    </Form>
  );
}
export default SipStep;
import { useEffect } from 'react';
import useQuery from '../../../../../hooks/custom/useQuery';

const GET_GROUP_COMPANY = `query getGroupCompany($startupId: ID!, $groupCompanyId: ID!) {
  getGroupCompany(startupId: $startupId, groupCompanyId: $groupCompanyId) {
    id
    name
  }
}`;

const useGetGroupCompany = (variables, config) => {
  const { loading, error, data, refetch } = useQuery(GET_GROUP_COMPANY, variables, config);
  useEffect(() => {

  }, []);

  return { loading, error, data, refetch };
};

export default useGetGroupCompany;

import { Grid } from '@material-ui/core';
import _ from "lodash";
import PropTypes from "prop-types";
import React from 'react';
import { Date } from '../../../../../../../v1/components';
import Typography from '../../../../../../../modules/generic/components/Texts/Typography';
import useTranslate from "../../../../../../../modules/generic/hooks/useTranslate";
import classesModule from './DetailsTab.module.scss';
import Link from "../../../../../../../components/text/Link/Link";
import useBusinessRules from 'src/modules/clara/hooks/useBusinessRules';
function DetailsTab({ data }) {
    const { translate } = useTranslate();
    const { isReseller } = useBusinessRules();
    return <div className={` ${classesModule.DetailsTab} `}>
        <Grid container spacing={2}>

            <Grid item xs={4}>
                <Typography color='dark-grey' variant='m'>
                    {translate("STARTUP_VIEW_TAB_LABEL_BUSINESS_DESCRIPTION")}
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography color='black' variant='m'>{_.get(data, "description") == null ? null : _.get(data, "description")}
                </Typography>
            </Grid>
        

        
            <Grid item xs={4}>
                <Typography color='dark-grey' variant='m'>{translate("STARTUP_VIEW_TAB_LABEL_PROFILE_CREATED")}</Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography color='black' variant='m'>
                    <Date value={_.get(data, "createdAt") == null ? null : _.get(data, "createdAt")} />
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography color='dark-grey' variant='m'>{translate("STARTUP_VIEW_TAB_LABEL_PRIMARY_LOCATION")}</Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography color='black' variant='m'>
                    {_.get(data, "primaryLocation.name") == null ? null : _.get(data, "primaryLocation.name")}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography color='dark-grey' variant='m'>{translate("FORM_COMAPANY_LABEL_STARTUP_WEBSITE")}</Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography color='black' variant='m'>
                    {_.get(data, 'website') ? <Link target={"_blank"} href={_.get(data, 'website')}>{_.get(data, 'website')} </Link> : null}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography color='dark-grey' variant='m'>{translate("STAKEHOLDER_VIEW_TAB_LABEL_LINKEDIN")}</Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography color='black' variant='m'>
                    {_.get(data, 'linkedInURL') ? <Link target={"_blank"} href={_.get(data, 'linkedInURL')}>{_.get(data, 'linkedInURL')} </Link> : null}
                </Typography>
            </Grid>
            {isReseller() && (<>
                <Grid item xs={4}>
                    <Typography color='dark-grey' variant='m'>Communication preferences</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography color='black' variant='m'>
                        {data?.resellerInfo?.receiveEmailsOnBehalfOwners ? "Yes, I want to receive emails on behalf of the profile owners" : "Do not receive emails on behalf of owners"}
                    </Typography>
                </Grid>
            </>)}



        </Grid>
    </div >;
}

DetailsTab.propTypes = {
    data: PropTypes.any
}
export default DetailsTab;
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useDispatch } from "react-redux";
import Button from "../../../../../generic/components/Buttons/Button";
import useMachine from '../../../../../generic/context/MachineContext/useMachine';
import useModal from "../../../../../generic/hooks/useModal";
import useTranslate from '../../../../../generic/hooks/useTranslate';
import { clearAssistatText } from "../../../../../generic/store/action";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import FormEditQuestion from '../../../../../../modules/accelerators/modals/editQuestion/forms/formEditQuestion';
import FormEditExplaind from '../../../../../../modules/accelerators/modals/editQuestion/forms/formEditExplaind';

/**
 * Step to edit single question
 * @param {*} param0
 * @returns
 */
function StartEditSingleQuestion({ openParam }) {

  const { next, context, prev } = useMachine();
  const { translate } = useTranslate()
  const dispatch = useDispatch();
  const { closeModal: onClose } = useModal();

  const handleCancel = (values) => {
    dispatch(clearAssistatText())
    onClose();
  }

  const handleCompleteSubmit = async (values) => {
    next(values)
  }

  const handlePrev = () => {
    prev()
  }

  const handleInicialValues = useMemo(() => {
    return {
      explainComment: _.get(context, `initialData.explaind`, ""),
      response: _.get(context, `initialData.response`, ""),
    }
  }, [context]);

  const questionData = {
    question: _.get(context, `initialData.question.fields.yes_no.label`, ""),
    defaultExplain: _.get(context, `initialData.question.fields.yes_no.defaultExplain`, null),
    toolTips: _.get(context, `initialData.question.fields.tooltip`, []),
    options: _.get(context, `initialData.question.fields.yes_no.options`, []),
    explaind: _.get(context, `initialData.question.fields.text.label`, ""),
    explaindPlaceholder: _.get(context, `initialData.question.fields.text.placeholder`, ""),
    useOptions: _.get(context, `initialData.question.fields.yes_no.label`, "") === "" ? false : true,
  }

  return (
    <>
      {questionData.useOptions ? (
        <FormEditQuestion
          initialValues={handleInicialValues}
          handlePrev={handlePrev}
          questionData={questionData}
          key={_.get(context, 'currentQuestionnarie')}
          buttons={{
            cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
          }}
          onCompleteSubmit={handleCompleteSubmit}
          propsTemplate={{
            title: _.get(context, `initialData.question.title`, ""),
            subTitle: "Questionnaire"
          }}
          Template={TemplateWizard}
        />
      ) : (
        <FormEditExplaind
          initialValues={handleInicialValues}
          handlePrev={handlePrev}
          questionData={questionData}
          key={_.get(context, 'currentQuestionnarie')}
          buttons={{
            cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
          }}
          onCompleteSubmit={handleCompleteSubmit}
          propsTemplate={{
            title: _.get(context, `initialData.question.title`, ""),
            subTitle: "Questionnaire"
          }}
          Template={TemplateWizard}
        />
      )}
    </>
  );
}

export default StartEditSingleQuestion;

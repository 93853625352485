import useMutation from '../../../../../hooks/custom/useMutation'

export const JOIN_ACCELERATOR_PROGRAM = `mutation joinProgram(
  $id: ID!
  $startupData: StartupInput!        
) {
  joinProgram(
    id: $id
    startupData: $startupData
  ) {
    accelerator
    acceleratorData {
      acceleratorCode
      isSubmitted
    }
    acceleratorDef {
      code
      name
      questionnaire
      startDate
      endDate
    }
    goals {
      code
    }
  }
}`;

const useJoinProgram = ({ ...props }) => {
  return useMutation(JOIN_ACCELERATOR_PROGRAM, {...props});
}
export default useJoinProgram;
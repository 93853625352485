import { gql } from '@apollo/client';
import useMutation from "../../../../../hooks/custom/useMutation";
import useSession from '../../../../../modules/session/hooks/useSession';
import { GET_GROUP_COMPANIES } from '../../../../../modules/startup/onBoarding/core/forms/IndividualOrCompanyStakeholderForm/graphql/useGetGroupCompanies';
import { FormationData } from "../fragments";
import { TasksRequiredFieldFragment } from "../../../../map/graphql/typePolicies";

export const UPSER_SET_PRIMARY_CONTACT =  `
${FormationData}
${TasksRequiredFieldFragment}
mutation setPrimaryContact(
    $startupId: ID!
    $groupCompanyId: ID!
    $primaryContactId: ID!
  ) {
    setPrimaryContact(
      startupId: $startupId
      groupCompanyId: $groupCompanyId
      primaryContactId: $primaryContactId
    ) {
      id
      formationData{
    ...FormationDataFields
      }
      name
      isHoldingCompany
      tasks{
      ...TasksRequiredFieldFragment
      }
    }
  }`;

const useSetPrimaryContact = (config = {}, props = {}) => {

  const { startupId } = useSession();
  const [mutation, loading] = useMutation(
    UPSER_SET_PRIMARY_CONTACT, { showSuccessNotification: false, ...config }, {
      refetchQueries: [
        { query: gql`${GET_GROUP_COMPANIES}`, variables: { startupId: startupId } },
      ], ...props
  }
  )

  return [mutation, loading]
};

export default useSetPrimaryContact;

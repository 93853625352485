import Skeleton from '@material-ui/lab/Skeleton';
import React, { FC } from "react";
import Scrollbar from "src/components/generic/Scrollbar/Scrollbar";
import classesModule from "./Details.module.scss";
import classes from './DetailsSkeleton.module.scss';
import DetailsTabSkeleton from './DetailsTabSkeleton';

/**
 * Template Details Skeleton
 */

export interface DetailsSkeletonProps {
  children?: any,
  
}

const DetailsSkeleton: FC<DetailsSkeletonProps> = ({children}: DetailsSkeletonProps) => {

  return (
    <React.Fragment>
      <div className={`${classesModule.Details}`}>
        <div className={classesModule.Header}>
          <div className={classesModule.Info}>
            <div>
              <Skeleton variant="rect" height={"35px"} width={"35px"} />
            </div>
            <div className={classesModule.HeaderData}>
              <Skeleton variant="text" height={"16px"} width={"154px"} />
              <Skeleton variant="text" height={"10px"} width={"154px"} />
            </div>
          </div>
          <div className={classesModule.Botonera}>
            <Skeleton variant="rect" height={"24px"} width={"96px"} />
            <Skeleton variant="rect" height={"24px"} width={"119px"} />
            <Skeleton variant="rect" height={"24px"} width={"115px"} />
            <Skeleton variant="rect" height={"24px"} width={"82px"} />
          </div>
        </div>
        <div className={classesModule.ContentTabs}>
          <div className={`${classesModule.TabArea}`}>
            <div className={`${classes.skelTabs}`}>
              <div className={`${classes.skelTabsSpace}`}>
                <Skeleton variant="text" height={"1.3846rem"} width={"3.0769rem"} />
              </div>
              <div className={`${classes.skelTabsSpace}`}>
                <Skeleton variant="text" height={"1.3846rem"} width={"3.8462rem"} />
              </div>
              <div className={`${classes.skelTabsSpace}`}>
                <Skeleton variant="text" height={"1.3846rem"} width={"3.6923rem"} />
              </div>
              <div className={`${classes.skelTabsSpace}`}>
                <Skeleton variant="text" height={"1.3846rem"} width={"6.5385rem"} />
              </div>
              <div className={`${classes.skelTabsSpace}`}>
                <Skeleton variant="text" height={"1.3846rem"} width={"7.3846rem"} />
              </div>
              <div className={`${classes.skelTabsSpace}`}>
                <Skeleton variant="text" height={"1.3846rem"} width={"4.9231rem"} />
              </div>
              <div className={`${classes.skelTabsSpace}`}>
                <Skeleton variant="text" height={"1.3846rem"} width={"11.0769rem"} />
              </div>
            </div>
          </div>
          <div className={`${classesModule.ContentTab}`}>
            <div className={` ${classes.DetailsTabTitle} `}>
              <Skeleton variant="text" height={"16px"} width={"154px"} />
              <div className={classes.Botonera}>
                <Skeleton variant="rect" height={"24px"} width={"96px"} />
              </div>
            </div>
            <Scrollbar>
              {children || <DetailsTabSkeleton/>}
            </Scrollbar>
          </div>
        </div>
      </div>

    </React.Fragment >

  );
};

export default DetailsSkeleton;

import _ from "lodash"
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useParams } from "react-router-dom"
import useIndividualStakeholderData from "src/hooks/services/modules/startups/individual/useIndividualStakeholderData"
import useSession from 'src/modules/session/hooks/useSession'
import ModalAssistant, { ModalAssistantProps } from '../../../generic/components/Modal/ModalAssistant'
import Wizard from '../../../generic/components/Wizard'
import useModal from '../../../generic/hooks/useModal'
import MachineCompanyDetail from './machine'

interface ModalAddAddressesProps extends ModalAssistantProps {
  paramsMutation: object,
  onClose(): void;
}

const ModalAddAddresses: FC<ModalAddAddressesProps> = ({
  children,
  open: openParam = false,
  paramsMutation = {},

  ...props
}: ModalAddAddressesProps) => {

  const [open, setOpen] = useState(openParam);
  const { closeModal: onClose } = useModal();
  const { startupId } = useSession();
  const params = useParams();
  const id = paramsMutation?.stakeholderId ?? _.get(params, "id");
  const { data: userData } = useIndividualStakeholderData({
    startupId,
    stakeholderId: id,
  });

  useEffect(() => {
    setOpen(openParam);
  }, [openParam])

  const handleClose = (values) => {
    onClose()
    setOpen(false);
  }

  const initialValues = useMemo(() => {
    if (_.get(userData, "getStakeholder")) {
      return {
        stakeholderId: id,
        personal_information: {
          email: _.get(userData, "getStakeholder.email"),
          address: {
            country: _.get(userData, "getStakeholder.address.country.code", undefined) === "" ? undefined : _.get(userData, "getStakeholder.address.country"),
            street: _.get(userData, "getStakeholder.address.street"),
            city: _.get(userData, "getStakeholder.address.city"),
            state: _.get(userData, "getStakeholder.address.state"),
            zipCode: _.get(userData, "getStakeholder.address.zipCode"),
          }
        },
      }
    }
    return null;
  }, [id, userData])

  return (
    <ModalAssistant open={open}>
      {initialValues && <Wizard machine={MachineCompanyDetail} initialValues={initialValues} onComplete={handleClose} />}
    </ModalAssistant>
  )
}

export default ModalAddAddresses;





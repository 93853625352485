import React, { FC, useRef } from "react";
import * as Yup from "yup";
import Form from "src/components/forms/Form/FormL";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import TemplateFormDefault from "src/modules/generic/templates/Modal";
import FormProps from "src/modules/generic/forms/interfaces/FormProps";
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import classes from "./Form.module.scss";

const schema = Yup.object().shape({
  isThereCompaniesShares: Yup.boolean().nullable().required("Required"),
});

const FormIsThereCompaniesShares: FC<FormProps> = ({
  initialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
}: FormProps) => {
  const refForm = useRef();

  return (
    <Form
      defaultValues={initialValues || {}}
      schema={schema}
      onlySendDirty={false}
      onSubmit={onCompleteSubmit}
      ref={refForm}
      optionsForm={{ mode: "onChange" }}
    >
      <Template
        buttons={{
          ...buttons,
          submit: <SubmitButton {...buttonSubmitProps}>Next</SubmitButton>,
        }}
        props={propsTemplate}
      >
        <ControllerInput
          render={CheckboxButtonSimple}
          name="isThereCompaniesShares"
          className={classes.CheckboxButton}
          classNameButton={classes.buttonCheckBox}
        />
      </Template>
    </Form>
  );
};

export default FormIsThereCompaniesShares;

import { useMemo } from "react";
import { assign } from 'xstate';
import {pick,every,get,isEmpty,uniq} from 'lodash'
import { Constants } from "src/v1/utils";
import { stripTypenames } from "src/v1/utils/graphql-util";
import  useSession  from 'src/modules/session/hooks/useSession';
import useUpsertComponentRole from '../../graphql/mutation/useUpsertRole'
import useUpdateGroupCompany from '../../graphql/mutation/useUpdateGroupCompany'
import { $CODE_CAYMAN_GOAL } from "../../../onBoarding/company/steps/Step8A";
import { useParams } from 'react-router';
import useGetSakeholderByIdLazy from '../../graphql/queries/useGetStakeholderByIdLazy'
const useActions = () => {
    const {startupId} = useSession()
    const {upsertComponentRole} = useUpsertComponentRole({ 
      showSuccessNotification: true,
      successMessage: 'ADD_ROLE_SUCCESS'
    },{}) 
    const {id} = useParams()
    const mutation = useUpdateGroupCompany()
    const {manualQuery} = useGetSakeholderByIdLazy()

    return useMemo(() => ({
        setContextAfterStep: assign((context, event) => {
          const values = get(event, 'payload.values');
          const solution = { ...context, ...values };
          return solution;
        }),
        addIterations: assign((context, event) => {
            const iterations = get(context, 'iterations',0);
            const solution = { ...context, iterations:iterations+1 };
            return solution;
          }),
        resetIterations: assign((context, event) => {
            return {...context,iterations:0}
        }),
        setGroupCompaniesWithoutFormation: assign((context, event) => {
            const solution = { ...context, groupCompanies:(context?.groupCompanies || []).filter(gc=>gc.kind !=='FORMATION')  };
            return solution
          }),
        setRolesByContext: assign((context:any, event) => {
            const roleKeys = Object.keys(Constants.ROLES_FROM_BACKEND)
            const filteredKeys = roleKeys.filter(key=>{
                if(!["FOUNDER", "ADVISOR", "EMPLOYEE", "CONSULTANT", "INVESTOR", "THIRDPARTY","SHAREHOLDER","DIRECTOR"].includes(key)) return false
                if(context.getGroupCompaniesAndStakeholder.stakeholder.isAnEntity && (key === Constants.ROLES_FROM_BACKEND.FOUNDER.id || key === Constants.ROLES_FROM_BACKEND.EMPLOYEE.id)) return false
                if(every(context.getGroupCompaniesAndStakeholder.groupCompanies,{kind:'FORMATION'}) && context.getGroupCompaniesAndStakeholder.groupCompanies.length && (key !== Constants.ROLES_FROM_BACKEND.SHAREHOLDER.id && key !== Constants.ROLES_FROM_BACKEND.DIRECTOR.id && key !== Constants.ROLES_FROM_BACKEND.FOUNDER.id)) return false
                return true
        })
        return {
            ...context,
            roles:isEmpty(context.getGroupCompaniesAndStakeholder.groupCompanies)?
            pick(Constants.ROLES_FROM_BACKEND,[Constants.ROLES_FROM_BACKEND.FOUNDER.id]):
            pick(Constants.ROLES_FROM_BACKEND,filteredKeys)}
    }),
    setRolesAndIsFounder: assign((context, event) => {
        const values = get(event, 'payload.values');
        const solution = { ...context, isFounder:values.roles.includes('FOUNDER'),roles:values.roles.filter(r=>r!=='FOUNDER') };
        return solution;
      }),
    upsertRoles:async (context,event)=>{
        const values = get(event, 'payload.values');
        const roles = get(context, 'roles');
        const st = await manualQuery({variables:{startupId,stakeholderId:id}}) 
        const stakeholder = st.data.getStakeholder
        const role = roles[context.iterations - 1 || 0]
        const groupCompanies = get(context, 'groupCompanies') || get(values, 'groupCompanies')
        const rolesToAdd = []
        if (isEmpty(context.getGroupCompaniesAndStakeholder.groupCompanies) || isEmpty(context.roles)) {
          const stake ={
            isFounder:context.isFounder,
          }
          return await upsertComponentRole({ ...stripTypenames({ stakeholder:stake,startupId,stakeholderId:stakeholder.id }) })
        }
        if(context.getGroupCompaniesAndStakeholder.groupCompanies.length === 1){
          roles.forEach(role => rolesToAdd.push({
            role: [{ type: role }],
            groupCompany: context.getGroupCompaniesAndStakeholder.groupCompanies[0].id,
          }))
          const oldRoles = stakeholder.roles.map(r => ({
            role: [{ type: r.name }],
            groupCompany: r.groupCompany.id
          }))
          const data ={
              startupId,
              stakeholderId: id,
              stakeholder:{
                  ...stakeholder,
                  isFounder:stakeholder.isFounder?stakeholder.isFounder:context.isFounder,
                  roles:uniq([...oldRoles,...rolesToAdd])
              }
          }
          return await upsertComponentRole({...stripTypenames(data)})
        }
        if(!groupCompanies) return 
        groupCompanies.forEach(gc => rolesToAdd.push({
          role: [{ type: role }],
          groupCompany: gc.id,
        }))
        const oldRoles = stakeholder.roles.map(r => ({
          role: [{ type: r.name }],
          groupCompany: r.groupCompany.id
        }))
        const data ={
            startupId,
            stakeholderId: id,
            stakeholder:{
                ...stakeholder,
                isFounder:stakeholder.isFounder?stakeholder.isFounder:context.isFounder,
                roles:uniq([...oldRoles,...rolesToAdd])
            }
        }

     await upsertComponentRole({...stripTypenames(data)})
    },
    addGoal:async (context, event)=>{
        const groupCompanies = (context.groupCompanies || context.getGroupCompaniesAndStakeholder.groupCompanies).filter(gc=>gc.kind ==='FORMATION')
       return Promise.all(groupCompanies.map(gc=>mutation({
        groupCompanyId: gc.id,
        startupId,
        goal: $CODE_CAYMAN_GOAL,
      })))
      }

}), [])
}
export default useActions;

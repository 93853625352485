import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../../generic/components/Buttons/Button';
import { StepProps } from "../../../../generic/components/Wizard/Step";
import TemplateWizard from '../../../../generic/templates/Modal/TemplateWizard';
import useTranslate from '../../../../generic/hooks/useTranslate';
import { addAssistantText, clearAssistatText } from "../../../../generic/store/action";
import FormUploadArticlesOfAsocciation from "../../Forms/FormUploadArticlesOfAsocciation";
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';

const UploadArticlesOfAsocciationStep: FC<StepProps> = () => {
  const { translate } = useTranslate();
  const { next, send, prev } = useMachine();
  const dispatch = useDispatch();
 
  useEffect(() => {
    dispatch(clearAssistatText())
    dispatch(addAssistantText(translate('MODULES.GROUP_COMPANY.SHARE_CAPITAL_ADGM_CAYMAN.SECOND_STEP.TOOLTIP_1')));
  }, []);

  const handleCompleteSubmit = async (values) => {
    next(values);
  }

  const handleCancel = () => {
    send("CANCEL");
  }

  const handlePrev = () => {
    prev();
  }

  return (
    <>
      <FormUploadArticlesOfAsocciation
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: translate('MODULES.GROUP_COMPANY.SHARE_CAPITAL_ADGM_CAYMAN.UPLOAD.TITLE'),
          subTitle: translate('MODULES.GROUP_COMPANY.SHARE_CAPITAL_ADGM_CAYMAN.GENERIC.SUBTITLE')
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button>,
          previous: <Button onClick={handlePrev} variant="secondary">Previous</Button>
        }}
      />
    </>
  );
}

export default UploadArticlesOfAsocciationStep;
import _ from "lodash";
import React, { useState, useEffect } from 'react';
import RadioCheckboxInput from "../../../../../../components/inputs/Radio/RadioCheckboxInput";
import ControllerInput from "../../../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import TextInput from "../../../../../../components/inputs/Text/TextInput";
import Text from "../../../../../../components/text/Text/Text";
import schema from "../../../../../../forms/schemas/modules/user/updateEditSchema";
import { Modal } from '../../../../../../v1/components';
import RowAdd from '../../../../../forms/contents/RowAdd/RowAdd';
import Form from '../../../../../forms/Form/Form';
import SecondaryButton from "../../../../../generic/Buttons/SecondaryButton/SecondaryButton";
import SubmitButton from "../../../../../generic/Buttons/SubmitButton/SubmitButton";
import Line from "../../../../../generic/Line/Line";
import Botononera from "../../../../../templates/Botonera/Botononera";
import ModalForm from '../../../../../templates/ModalForm/ModalForm';
import useTextLang from "./../../../../../../hooks/custom/useTextLang";
import useGetMe from "./../../../../../../hooks/services/modules/user/useGetMe";
import useUpsertMe from "./../../../../../../hooks/services/modules/user/useUpsertMe";
import classes from "./EditProfileModal.module.scss";
import useSession from "./../../../../../../modules/session/hooks/useSession";
import PhoneInput from 'src/modules/generic/components/Inputs/PhoneInput';

const EditProfileModal = ({ showModal, open, handleCloseModal, startupId }) => {

  const titleTranslate = useTextLang("MY_ACCOUNT_MODAL_EDIT_TITLE", null);
  const subscribe = useTextLang("SUBSCRIBE");
  const unsubscribe = useTextLang("UNSUBSCRIBE");
  const { startup, setUser: setUserInSession } = useSession();
  const onCompletedUpdate = async (data) => {
    handleCloseModal();
    setUserInSession(data.upsertMe);
  }
  const [upsertMe, loadingMe] = useUpsertMe({ onCompleted: onCompletedUpdate, successMessage: "MY_ACCOUNTNOTIFICATION_SUCCESS_UPDATE_PROFILE" });
  const { data: userData, loadingUser, errorUser, refetch } = useGetMe();
  const [user, setUser] = useState(null)

  useEffect(() => {
    if (showModal || open) {
      refetch();
    }
  }, [showModal, open])

  useEffect(() => {
    if (userData) {
      let newUser = { ..._.get(userData, "getMe", {}) }
      newUser.acceptMarketingEmail = newUser.acceptMarketingEmailOn ? true : false;
      newUser.phone = newUser.personalInfo?.phoneNumber;
      setUser(newUser);
    }
  }, [userData])

  const handleSubmit = async (values) => {
    const newValues = {
      acceptMarketingEmail: values.acceptMarketingEmail,
      firstName: values.firstName,
      lastName: values.lastName,
      personalInfo: {
        phoneNumber: values.phone
      }
    }
    await upsertMe(newValues);
    handleCloseModal();
  }

  return (
    <React.Fragment>
      {userData &&
        <Modal
          overlayName={titleTranslate}
          open={showModal || open} handleClose={() => handleCloseModal()} showHeader={false}>
          <div className={classes.ContactPartnersModalContainer}>
            <ModalForm title={'MY_ACCOUNT_MODAL_EDIT_TITLE'}>
              <Form
                initialValues={user}
                schema={schema}
                onSubmit={handleSubmit} >
                <RowAdd
                  label={"MY_ACCOUNT_MODAL_EDIT_FIRST_NAME"}
                  input={
                    <ControllerInput
                      as={TextInput}
                      name="firstName"
                      defaultValue={''}
                      placeholder={""}
                      onChange={(e) => {
                      }}
                    />
                  }
                />
                <RowAdd
                  label={"MY_ACCOUNT_MODAL_EDIT_LAST_NAME"}
                  input={
                    <ControllerInput
                      as={TextInput}
                      name="lastName"
                      defaultValue={''}
                      placeholder={""}
                      onChange={(e) => {
                      }}
                    />
                  }
                />
                <RowAdd
                  label={"MY_ACCOUNT_MODAL_EDIT_EMAIL"}
                  input={
                    <ControllerInput
                      as={TextInput}
                      name="email"
                      defaultValue={''}
                      placeholder={""}
                      disabled={true}
                      onChange={(e) => {
                      }}
                    />
                  }
                />
                <RowAdd
                  label={"MY_ACCOUNT_MODAL_EDIT_PHONE"}
                  input={
                    <ControllerInput
                      as={PhoneInput}
                      name="phone"
                      defaultValue={''}
                      onChange={(e) => {
                      }}
                      defaultlabel={''}
                      placeholder='Include numbers'
                      disableDropdown={false}
                    />
                  }
                />
                <Line />
                <RowAdd
                  label={"MY_ACCOUNT_MODAL_EDIT_MARKETING"}
                  align={"top"}
                  input={
                    <ControllerInput
                      as={RadioCheckboxInput}
                      options={[{ id: true, label: subscribe }, { id: false, label: unsubscribe }]}
                      name={"acceptMarketingEmail"}
                      type={"checkbox"}
                      orientation={"vertical"}
                      onChange={(e) => {
                      }}
                    />
                  }
                />
                <Botononera>
                  <SecondaryButton onClick={() => handleCloseModal()}><Text uuid={'BUTTON_CANCEL'} /></SecondaryButton>
                  <SubmitButton><Text uuid={"MY_ACCOUNT_MODAL_EDIT_SAVE"} /></SubmitButton>
                </Botononera>
              </Form>
            </ModalForm>
          </div>
        </Modal>
      }
    </React.Fragment>
  )
};

export default EditProfileModal;

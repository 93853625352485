import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Button from 'src/modules/generic/components/Buttons/Button';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import { clearAssistatText } from 'src/modules/generic/store/action';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import FormGeneralDetailsCayman from 'src/modules/startup/GroupCompany/Forms/FormGeneralDetailsCayman';

function EditGeneralDetails() {
  const { next, cancel, context } = useMachine();
  const dispatch = useDispatch();

  const initialValues = context?.initialData ?? {};

  const handleCancel = () => {
    cancel();
  }

  const handleSubmit = async (values) => {
    next(values);
  }

  useEffect(() => {
    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch]);

  return (
    <FormGeneralDetailsCayman
      initialValues={initialValues}
      onCompleteSubmit={handleSubmit}
      Template={TemplateWizard}
      propsTemplate={{
        title:'Edit general details',
      }}
      buttons={{
        cancel: <Button variant='secondary' onClick={handleCancel}>Cancel</Button>
      }}
    />
  );
}

export default EditGeneralDetails;
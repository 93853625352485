import React from 'react';
import classesModal from "./MatterListView.module.scss"
import Matters from '../../../../v1/components/Forms/View/IncorporationCompany/matters';

const MatterListView =({...props  })=> {

  return (
    <React.Fragment>
      <div className={`${classesModal.MatterListView}`}>
        <Matters/>
      </div>
    </React.Fragment>
  );
}

export default MatterListView;
import React from "react";
import MicroFrontend from "src/mf";
import useSession from "src/modules/session/hooks/useSession";
const LogInLinkedin = (props) => {
  const { initSession } = useSession()

  return (
    <React.Fragment>
      <MicroFrontend id="MF_CallbackLinkeding_" component={"./CallbackLinkeding"} mf="dashboard" params={{
        initSession
      }} loader={<></>}/>
    </React.Fragment>
  );
};

export default LogInLinkedin;

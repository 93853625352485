import React from "react";
import _ from "lodash";
import classesModule from './InvoicesView.module.scss';
import useTranslate from "../../../../../modules/generic/hooks/useTranslate.jsx";
import TypographyLocal from "../../../../../modules/generic/components/Texts/Typography";
import InvoicesList from "../../../../../modules/checkout/components/InvoicesList/InvoicesList";
import MissingInformation from "../../../../../v1/components/MissingInformation";
import { ReactComponent as MissingInformationIcon } from "../../../../../images/MissingInformation.svg";
import useSession from "../../../../../modules/session/hooks/useSession";
import useGetStartup from "../../../../../modules/startup/graphql/startup/useGetStartup";
import InvoicesViewSkeleton from "../../../../../modules/checkout/components/InvoicesViewSkeleton/InvoicesViewSkeleton";

const Invoices = () => {
  const { translate } = useTranslate();
  const { startup } = useSession();

  const {
    loading: loadingStartup,
    data: startupFull,
  } = useGetStartup({ startupId: startup.id });

  return (
    <div className={classesModule.Invoices}>
      <TypographyLocal
        className={classesModule.titleColor}
        variant="h2"
      >
        {translate("MODULES.CHECKOUT.PAGE_TITLE_INVOICE")}
      </TypographyLocal>
      {loadingStartup ? (
        <InvoicesViewSkeleton isLoading={loadingStartup} />
      ) :(
        <>
          {_.get(startupFull, "invoices", []).length > 0 ? (
            <InvoicesList
              invoicesList={_.get(startupFull, "invoices", [])}
            />
          ) : (
            <MissingInformation
              subtitle={translate("MODULES.CHECKOUT.NOT_INVOICES")}
              icon={MissingInformationIcon}
            />
          )}
        </>
      )}
    </div>
  );
}

export default Invoices;
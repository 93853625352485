import useCustomMachine from '../../../../generic/hooks/useCustomMachine'
import React, { FC } from 'react'
import Button from '../../../../generic/components/Buttons/Button'
import { StepProps } from "../../../../generic/components/Wizard/Step"
import TemplateWizard from '../../../../generic/templates/Modal/TemplateWizard'
import Form from "src/modules/startup/Stakeholder/AddAddresses/forms/FormAddresses";
import { get } from 'lodash';
import { useDispatch } from 'react-redux'
import { addAssistantText, clearAssistatText } from '../../../../generic/store/action';
import useSession from '../../../../session/hooks/useSession';
import { useParams } from 'react-router-dom';
import useUpdateStakeholder from "src/modules/startup/onBoarding/core/graphql/mutations/useUpdateStakeholder";

const Step1: FC<StepProps> = () => {

  const dispatch = useDispatch()
  const { next, send, state } = useCustomMachine();
  const { startupId } = useSession();
  const { id:_id } = useParams<any>()
  
  const id = state?.context?.data.stakeholderId ?? _id;

  const { updateStakeholder } = useUpdateStakeholder({ showSuccessNotification: false }, {});

  const getValuesMutation = (values, state) => {
    const { personal_information } = state.context.data;
    return { ...personal_information, ...values };
  }

  const handleCompleteSubmit = async values => {
    const variables = {
      startupId,
      stakeholderId: id,
      stakeholderData: {
        stakeholder: getValuesMutation(values, state)
      }
    }
    await updateStakeholder({ variables });
    next(values);
  }
  const handleCancel = async values => {
    send("CANCEL");
  }

  React.useEffect(() => {
    dispatch(addAssistantText(`Keep an eye on the Clara star for hints and tips.`));
    return () => {
      dispatch(clearAssistatText())
    }
  }, [])

  return (
    <React.Fragment>
      <Form
        initialValues={get(state, 'context.data.personal_information', {})}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          subTitle: 'Addresses',
          title: "Add their addresses"
        }}
        buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }}>
      </Form>
    </React.Fragment>
  )
}

export default Step1;

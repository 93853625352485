import React, {FC} from 'react'
import ShareMachine from "./ShareMachine";
import Wizard from "../../../../../../generic/components/Wizard";
import ModalAssistant, {ModalAssistantProps} from "../../../../../../generic/components/Modal/ModalAssistant";
import useModal from "../../../../../../generic/hooks/useModal"
interface ModalShareProps extends ModalAssistantProps{
    initialValues:object
}
const ModalShare:FC<ModalShareProps>=({initialValues={}, open = false, ...props })=>{

    const {closeModal} = useModal();
    const handleClose = () => {
        closeModal();
    }
    return(
        <ModalAssistant open={open}>
            <Wizard initialValues={initialValues} machine={ShareMachine} onComplete={handleClose}/>
        </ModalAssistant>)
}
export default ModalShare
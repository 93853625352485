import { default as React, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import * as Yup from 'yup';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import CheckboxButtonSimple, {
  CheckboxButtonOptions,
} from 'src/modules/generic/components/Inputs/CheckboxButtonSimple';
import classes from '../classes.module.scss';

/**
* Description Step: You confirm that you have added all the current directors and shareholders of the company.
* @returns 
*/
function AddEntityStep() {

  const { context, prev, cancel, send, state } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.AddEntityStep ?? {}
  }, [context]);
  
  const handleCancel = (values) => {
    cancel();
  }

  const handleSubmit = async (values) => {

    const nextStep = values?.allDirectorsAdded

    send({
      type: nextStep,
      payload: { idState: state.value, values: values }
    });
  }

  useEffect( ()=>{
    dispatch(addAssistantText('Add all current directors and shareholders in order to form this company.'))
    dispatch(addAssistantText('This info is essential to the formation process, so add all your current directors and shareholders before we continue.'))
    return () => {
      dispatch(clearAssistatText())
    }
  },[dispatch])

  const schema = Yup.object().shape({
    allDirectorsAdded: Yup.string().nullable().required('Required'),
  })

  const options: Array<CheckboxButtonOptions<string, any>> = [

    {
      value: 'NO',
      label: 'No, not yet'
    },
    {
      value: 'YES',
      label: 'Yes, all added!'
    },

  ];

  return (

    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={ { mode: 'onChange' } }
    >
      <TemplateWizard
        props={ {
          title: `Have you added all your company's directors and shareholders?`,
          skeleton: null,
          subTitle: 'ADGM authorised signatories',
          variant: 'simple'
        } }

        buttons={ {
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('BUTTON_NEXT')}</SubmitButton>,
        } }
      >
      <div className={classes.Input}>
        <ControllerInput
          as={CheckboxButtonSimple}
          name='allDirectorsAdded'
          variant='col_1'
          options={options}
          className={classes.CheckboxButton}
          classNameButton={classes.buttonCheckBox}
        />
      </div>

      </TemplateWizard>
    </Form>
  );
}

export default AddEntityStep;


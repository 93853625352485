import _ from 'lodash';
import React from 'react';
import SVGCardMapCurveLine from '../SVGZone/SVGCardMapCurveLine';
import SVGZone from '../SVGZone/SVGZone';

export function SVGMap({
  widthMap,
  heightMap,
  svgRef,
  lines,
  isLineVisibleByPlay,
  hasFilter,
  enabledLines,
  onUpdatedLine
}) {

  return (
    <SVGZone ref={svgRef} style={{
      width: widthMap,
      minHeight: heightMap
    }}>
      <>
        {_.map(lines, (line, index) => {
          const visibleByPlay = isLineVisibleByPlay(index, line);
          return (
            <SVGCardMapCurveLine 
              key={index} 
              id={index} 
              {...line}
              useOpacity={line?.status === "INACTIVE" ? true : false ?? false}
              dotter={line.dotter} 
              visible={visibleByPlay && (line.visible || !hasFilter()) && enabledLines} 
              onUpdatedLine={onUpdatedLine}
            />
          );
        })}
      </>
    </SVGZone>
  );
}

import _ from 'lodash';
import React, { FC, useEffect } from 'react';
import { useDispatch } from "react-redux";
import Button from "../../../../../../../../generic/components/Buttons/Button";
import { StepProps } from "../../../../../../../../generic/components/Wizard/Step";
import useRegisterAsCheckedTask from "../../../../../../../../generic/graphql/mutation/useRegisterAsCheckedTask";
import useCustomMachine from "../../../../../../../../generic/hooks/useCustomMachine";
import useTranslate from "../../../../../../../../generic/hooks/useTranslate";
import { addAssistantText, clearAssistatText } from "../../../../../../../../generic/store/action";
import TemplateWizard from "../../../../../../../../generic/templates/Modal/TemplateWizard";
import useSession from '../../../../../../../../session/hooks/useSession';
import IsThereEquity from "../../../../../../forms/IsThereEquityCompany";
import classes from './classes.module.scss';
const FirstShareStep: FC<StepProps> = ({ }) => {
    const dispatch = useDispatch()
    const { send, stepData, state, next } = useCustomMachine()
    const { translate } = useTranslate()
    const handleCancel = () => {
        send('CANCEL')
    }
    const { startupId } = useSession();
    const { mutation } = useRegisterAsCheckedTask({ showSuccessNotification: false })


    useEffect(() => {

        dispatch(clearAssistatText());

        dispatch(addAssistantText(`<strong>Shares</strong> are units of ownership in a company. <a rel='noopener noreferrer' href="https://academy.clara.co/category/sharecapital" target='_blank' >Find out more</a> about share ownership and company control.`));

        return () => {
            dispatch(clearAssistatText())
        }


    }, [])

    const handleCompleteSubmit = async (values) => {
        if (values.equity === 'notAdd') {
            const registeredTaskData = {
                entityId: _.get(state, 'context.paramsData.id'),
                taskCode: 'TASK_ADD_COMPANY_EQUITY_POSITIONS'
            }
            await mutation({ startupId, registeredTaskData })
        }
        dispatch(clearAssistatText())
        next(values)
    };



    return (<>
        <IsThereEquity
            onCompleteSubmit={handleCompleteSubmit}
            type={'SHARE'}
            className={classes.checkBox}
            initialValues={stepData}
            propsTemplate={{
                title: translate('MODULES.EQUITY.STEPS_EQUITY_POSITIONS.FIRST_STEP.TITLE'),
                subTitle: translate('MODULES.EQUITY.STEPS_EQUITY_POSITIONS.FIRST_STEP.SUBTITLE')
            }}
            
            isThereToCreate={false}
            Template={TemplateWizard}
            buttons={{ cancel: <Button onClick={handleCancel}>Cancel</Button> }} />
    </>)
}
export default FirstShareStep

import { useEffect } from 'react';
import useMutation from "../../../hooks/custom/useMutation";


const mutation = `mutation addCreditCardToAStartup(  $startupId: ID!,$creditCardToken: String!,$billingAddress: BillingAddressInput!,$cardHolderName: String){
  addCreditCardToAStartup(
      startupId: $startupId
      creditCardToken: $creditCardToken
      billingAddress: $billingAddress
      cardHolderName: $cardHolderName
      ) 
  } `
const useAddCreditCardToAStartup = (options={}) => {

  const [send, data] = useMutation(mutation, options);
  

  return [send, data];
}
export default useAddCreditCardToAStartup;

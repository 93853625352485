import { useMemo } from 'react';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import AddFormStep from '../steps/AddFormStep';

const useSteps = () => {

  return useMemo(() => ({
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    AddFormStep: {
      render: AddFormStep,
    },
    SaveStep: {
      render: TemplateWizardSkeletonMachine,
    },
    
  }), []);
}

export default useSteps;

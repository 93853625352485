import React, {useEffect, useState} from 'react';
import {ClaraCheckForm, Text} from '../index';
import _ from "lodash";
import PropTypes from 'prop-types';
import {Field} from "formik";
import classes from "./Chat.module.scss";
import FactoryChatForm from './FactoryChatForm';
import FactoryChatFormConfig from './FactoryChatFormConfig.js';
import useEligibleAuthorizedSignatories from '../../Hooks/useEligibleAuthorizedSignatories';
import {modifyDataFileKYC, stripTypenames} from "../../utils/graphql-util";

const ChatAuthorizedSignatories = ({ responseKeyName, isForm, initialValueStart }) => {
  const nameField = responseKeyName ? responseKeyName + "." : "";
  const [refresh, setRefresh] = useState(1);
  const { authorizedSignatories } = useEligibleAuthorizedSignatories(initialValueStart.matterId, initialValueStart.draftId, refresh)
  const [openModal, setOpenModal] = useState(false);
  const [authorizedSignatoriesState, setAuthorizedSignatoriesState] = useState(false);

  const [currentList, setCurrentList] = useState([]);
  const handleClose = (newList) => {
    setOpenModal(false)
    setCurrentList(newList)
  }
  const refreshData = () => {
    setRefresh(refresh + 1)
  }
  useEffect(() => {
    setAuthorizedSignatoriesState(authorizedSignatories);
  }, [authorizedSignatories])
  return (
    <React.Fragment>
      <div className={`${classes.ChatQuestionnaire} ${classes.ChatAuthorizedSignatories}`}>
        <div className={classes.contentField}>
          <button className={`${classes.buttonWithAutomatic} ${classes.buttonOption} `}
            type="button" onClick={() => { setOpenModal(true) }}>
            <Text uuid={`QUESTIONARY_AUTHORIZED_SIGNATORIES_ADDITIONAL_SIGNATORIES`} />
          </button>
        </div>

        <div className={classes.contentField}>
          <h4 className={classes.titleLine} ><Text uuid={"QUESTIONARY_AUTHORIZED_SIGNATORIES_UAE_TITLE"} /></h4>
          <Field name={nameField + "UAEOrGCCNationalSelection"}>
            {({ field, form }) => {
              return (<ClaraCheckForm

                cbClose={handleClose}
                cbSaveForm={refreshData}
                field={field}
                form={form}
                list={_.get(authorizedSignatoriesState, "UAEOrGCCNationalSelection", [])}
                initialValues={{ formKey: "" }}
                optionsList={{
                  fieldId: initialValueStart.draftId ? "_id" : "id",
                  fieldLabel: _.get(FactoryChatFormConfig, "INDIVIDUAL_AS_QUESTIONNAIRE_FORM" + ".fieldName", null),
                }}
                optionsForm={{
                  titleKey: _.get(FactoryChatFormConfig, "INDIVIDUAL_AS_QUESTIONNAIRE_FORM.titleKey", null),
                  educationId: _.get(FactoryChatFormConfig, "INDIVIDUAL_AS_QUESTIONNAIRE_FORM.leftEducationId", null),
                  muttation: `
                  mutation upsertUAEOrGCCNationalKYCIndividualDraft(
                    $draftId: String!
                    $KYCIndividualData: Object!
                    $KYCIndividualId: String
                  ) {
                    upsertUAEOrGCCNationalKYCIndividualDraft(
                      draftId: $draftId
                      KYCIndividualData: $KYCIndividualData
                      KYCIndividualId: $KYCIndividualId
                    ) {
                      id
                      firstName
                    }
                  }    
                  `,
                  getIdFromMuttation: (result) => {
                    return _.get(result, "upsertUAEOrGCCNationalKYCIndividualDraft.id");
                  },
                  paramsMuttaion: initialValueStart,
                  getVariablesMutation: (params, values) => {
                    let KYCIndividualData = { ...values };
                    delete KYCIndividualData.isCompanyFlow;
                    delete KYCIndividualData.type;
                    delete KYCIndividualData.formKey;
                    delete KYCIndividualData.__typename;
                    delete KYCIndividualData.isShareholder;
                    delete KYCIndividualData.isDirector;
                    KYCIndividualData = modifyDataFileKYC(stripTypenames(KYCIndividualData));

                    const draftId = _.get(params, "draftId", "");
                    return {
                      draftId,
                      KYCIndividualData,
                      KYCIndividualId: KYCIndividualData._id
                    }
                  },
                  succesNotification: "NOTIFICATION_SUCCESS",
                  errorNotification: "NOTIFICATION_BAD",
                }}
                optionsSelect={{
                  auto: false,
                  canSelect: true,
                  addToList: false
                }}
                optionsOperation={{
                  canAdd: false,
                  canEdit: true,
                  canRemove: false,
                  canEditOnlyNews: false,
                  canRemoveOnlyNews: false
                }}
                optionsView={{
                  recordsByLine: 7
                }}
                className={{}}
              >
                <FactoryChatForm formKey={"INDIVIDUAL_AS_QUESTIONNAIRE_FORM"} ></FactoryChatForm>
              </ClaraCheckForm>
              )
            }}
          </Field>
        </div>
        <div className={classes.contentField}>
          <h4 className={classes.titleLine} ><Text uuid={"QUESTIONARY_AUTHORIZED_SIGNATORIES_OTHER_TITLE"} /></h4>
          <Field name={nameField + "otherAdditionalSelection"}>
            {({ field, form }) => {
              return (<ClaraCheckForm
                openForm={openModal}
                cbClose={handleClose}
                field={field}
                form={form}
                list={_.get(authorizedSignatoriesState, "otherAdditionalSelection", [])}
                initialValues={{ formKey: "" }}
                cbSaveForm={refreshData}
                optionsList={{
                  fieldId: initialValueStart.draftId ? "_id" : "id",
                  fieldLabel: _.get(FactoryChatFormConfig, "INDIVIDUAL_QUESTIONNAIRE_FORM" + ".fieldName", null),
                }}
                optionsForm={{
                  titleKey: _.get(FactoryChatFormConfig, "INDIVIDUAL_QUESTIONNAIRE_FORM.titleKey", null),
                  educationId: _.get(FactoryChatFormConfig, "INDIVIDUAL_QUESTIONNAIRE_FORM.leftEducationId", null),
                  muttation: `
                  mutation upsertQuestionnaireIndividualDraftFlow(
                    $KYCIndividualData: Object!
                    $KYCIndividualId: String
                    $draftId:ID!
                  ) {
                    upsertQuestionnaireIndividualDraftFlow(
                      KYCIndividualData: $KYCIndividualData
                      KYCIndividualId: $KYCIndividualId
                      draftId: $draftId
                    ) {
                      id
                      firstName
                    }
                  }  
                  `,
                  getIdFromMuttation: (result) => {
                    return _.get(result, "upsertQuestionnaireIndividualDraftFlow.id");
                  },
                  paramsMuttaion: initialValueStart,
                  getVariablesMutation: (params, values) => {
                    let KYCIndividualData = { ...values };
                    const KYCIndividualId = KYCIndividualData._id;
                    delete KYCIndividualData.isCompanyFlow;
                    delete KYCIndividualData.type;
                    delete KYCIndividualData.formKey;
                    delete KYCIndividualData.isDirector
                    delete KYCIndividualData.isShareholder
                    delete KYCIndividualData.isComplete

                    KYCIndividualData = stripTypenames(KYCIndividualData);
                    const draftId = _.get(params, "draftId", params);
                    return {
                      KYCIndividualData,
                      KYCIndividualId,
                      draftId
                    }
                  },
                  succesNotification: "NOTIFICATION_SUCCESS",
                  errorNotification: "NOTIFICATION_BAD",
                }}
                optionsSelect={{
                  auto: false,
                  canSelect: true,
                addToList: false
                }}
                optionsOperation={{
                  canAdd: false,
                  canEdit: true,
                  canRemove: true,
                  canRemoveOnlyNews: true
                }}
                optionsView={{
                  recordsByLine: 7
                }}
                className={{}}
              >
                <FactoryChatForm formKey={"INDIVIDUAL_QUESTIONNAIRE_FORM"} ></FactoryChatForm>
              </ClaraCheckForm>
              )
            }}
          </Field>
        </div>
      </div>
    </React.Fragment >
  );
}
ChatAuthorizedSignatories.propTypes = {
  responseKeyName: PropTypes.string.isRequired,
  initialValueStart: PropTypes.object.isRequired,

};
export default ChatAuthorizedSignatories;

import React from 'react';
import LastStep from "../../../core/wizards/steps/LastStep/LastStep";
import useTranslate from "../../../../../generic/hooks/useTranslate";

const LastStepAdvisor = ({ ...props }) => {
  const { translate } = useTranslate()
  return (
    <>
      <LastStep role={'ADVISOR'} tooltips={{ isNotAnother: translate('MODULES.ONBOARDINGROLES.ADVISOR.THIRD_STEP_NOT_ANOTHER_TOOLTIPS') }} />
    </>
  );
}

export default LastStepAdvisor;

import React, { FC } from "react";
import ModalAssistant from "src/modules/generic/components/Modal/ModalAssistant";
import Wizard from "src/modules/generic/components/Wizard/v2";
import useModal from "src/modules/generic/hooks/useModal";
import { clearAssistatText as clearAssistantText } from "src/modules/generic/store/action";
import useActions from "./hooks/useActions";
import useGuards from "./hooks/useGuards";
import useServices from "./hooks/useServices";
import useSteps from "./hooks/useSteps";
import machine from "./machine";

interface Props {
  open: boolean;
  initialValues: any;
  isCategorize: boolean;
  isEdit: boolean;
  isVisaOrPassportDocument: boolean;
  callback: Function;
}

const ModalCategoriseDocument: FC<Props> = ({
  open = false,
  initialValues,
  isCategorize = false,
  isEdit = false,
  isVisaOrPassportDocument = false,
  callback,
}) => {
  const { closeModal: onClose } = useModal();
  const guards = useGuards();
  const steps = useSteps({ isCategorize, isEdit, isVisaOrPassportDocument });
  const actions = useActions();
  const services = useServices({
    initialValues,
    isCategorize,
    isEdit,
    isVisaOrPassportDocument,
    callback,
  });

  const handleStopMachine = () => {
    onClose();
    clearAssistantText();
  };

  return (
    <ModalAssistant open={open}>
      <Wizard
        guards={guards}
        machine={machine}
        steps={steps}
        services={services}
        actions={actions}
        onStopMachine={handleStopMachine}
      />
    </ModalAssistant>
  );
};

export default ModalCategoriseDocument;

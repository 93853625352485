export default {
   TIME_TO_SHOW_WARNING_MODAL_IN_SECONDS: 240,
   DATE_FORMAT:"MM/dd/yyyy",
   NEW_DATE_FORMAT: "MMMM Do YYYY",
   PROD_HOSTNAME: 'app.clara.co',
   PRE_HOSTNAME: 'pre.clara.co',
   STAGING_HOSTNAME: 'staging.clara.co',
   TEST_HOSTNAME: 'test.clara.co',
   DEMO_HOSTNAME: 'demo.clara.co',
   DEVELOPMENT_HOSTNAME: 'localhost',
   INFINITE_SCROLL_LIMIT_DEFAULT:10,
   AUDIT_INFINITE_SCROLL_LIMIT_DEFAULT:400,
   GOOGLE_DRIVE_URL_FILE_PREVIEW:"https://docs.google.com/gview?url={{{api_url}}}/api/filePreview/{{file_id}}/{{token}}&embedded=true",
   MICROSOFT_DRIVE_URL_FILE_PREVIEW:"https://view.officeapps.live.com/op/embed.aspx?src={{{api_url}}}/api/filePreview/{{file_id}}/{{token}}",
   PUBLIC_URL_FILE:"{{{api_url}}}/api/filePreview/{{file_id}}/{{token}}"
}

import get from 'lodash/get'
import filter from 'lodash/filter';
import uniqBy from 'lodash/uniqBy'
import useTranslate from "src/modules/generic/hooks/useTranslate";

const useGetFieldsByDocumentType = ({ subCategory, category, context, flow, isEdit, isCategorize, parties }) => {

  const { translate } = useTranslate()

  const getTitleAndSubTitle = () => {
    if (isCategorize) {
      return {
        subTitle: translate("MODAL_CATEGORISE_DOCUMENT_SUBTITLE"),
        title: translate("MODAL_CATEGORISE_DOCUMENT_TITLE"),
      }
    } if (isEdit) {
      return {
        subTitle: translate("MODAL_EDIT_DOCUMENT_SUBTITLE"),
        title: translate("MODAL_EDIT_DOCUMENT_TITLE"),
      }
    }
    return {
      subTitle: translate("MODAL_ADD_DOCUMENT_SUBTITLE"),
      title: translate("MODAL_ADD_DOCUMENT_TITLE"),
    }
  }

  const getPartiesList = () => {
    const constantStakeholders = get(context, "allStakeholders", []);
    const constantGroupCompanies = get(context, "allGroupCompanies", []);
    const parties = [];
    constantStakeholders.forEach(st => {
      parties.push({ label: st.fullName, id: st.id, type: "Stakeholder" });
    })
    constantGroupCompanies.forEach(groupCompany => {
      parties.push({ label: groupCompany.name, id: groupCompany.id, type: "GroupCompany" })
    })
    return parties;
  }

  const getPartyList = () => {
    const constantStakeholders = get(context, "allStakeholders", []);
    return filter(constantStakeholders, { isAnEntity: false }).map(st => ({ label: st.fullName, id: st.id }));
  };

  const submitValuesMapper = (values) => {
    const newValues = {
      ...values,
      categorySelected: category,
      subCategorySelected: subCategory,
      flowToUse: flow,
    }
    if (flow === 1) {
      return {
        ...newValues,
        selectedParties: parties,
      }
    }
    if (flow === 2) {
      return {
        ...newValues,
        selectedParties: parties,
      }
    }
    if (flow === 3) {
      return {
        ...newValues,
      }
    }
    if (flow === 4) {
      return {
        ...newValues,
      }
    }
  }

  const getButtonLabel = () => {
    if (isCategorize) return "Categorise";
    if (isEdit) return "Save changes";
    return "Add";
  }

  const getDocTypesForComponent = (subcategory) => {
    if (!subcategory.hide) {
      const documentTypes = subcategory.documentTypes.map(documentType => ({
        id: documentType.key,
        label: documentType.title
      }))
      return documentTypes;
    }
    return []
  }

  const getListDocumentTypes = () => {
    const constantCategories = get(context, "categories", []);
    if (subCategory) {
      return uniqBy(getDocTypesForComponent(subCategory), 'id');
    }
    const documentTypes = constantCategories.flatMap(category => {
      return category.subCategories.flatMap(subCategory => {
        return getDocTypesForComponent(subCategory);
      })
    })
    return uniqBy(documentTypes, 'id');
  }

  const getCustomListParties = (listParties, parties) => {
    const partiesDoc = parties || [];
    if (partiesDoc.length === 0) return listParties;
    return filter(listParties, (party) => {
      return !(parties || []).find((partySelect) => { return partySelect.id === party.id });
    })
  };

  return { getTitleAndSubTitle, getPartiesList, submitValuesMapper, getButtonLabel, getListDocumentTypes, getCustomListParties, getPartyList }
}

export default useGetFieldsByDocumentType

import _ from 'lodash'
import React from 'react';
import ModalScroll from '../../../../../../generic/Modal/ModalScroll/ModalScroll';
import FormSelector from "../../../../../../forms/modules/startups/Document/Generate/EditDetails/FormSelector";

const EditDocumentDetails = ({type, field, open, handleClose, tooltips, title, initialValues, groupCompany,...props }) => {

	const handleDisabled = ()=> {
        return false
    };

    return (
        <ModalScroll open={open} handleClose={handleClose} >
                <FormSelector
                    groupCompany={groupCompany}
                    title={title}
                    tooltips={tooltips}
                    initialValues={initialValues}
                    type={type}
                    handleSubmit={props.handleSubmit}
                    field={field}
                    handleCloseModal={handleClose}
                    handleDisabled={handleDisabled}
                    nameHandle={props.nameHandle}
                />
        </ModalScroll>

    )
};

export default EditDocumentDetails;

import React, { useEffect, useState, useRef } from "react";
import classesModule from "./ImageUpload.module.scss";
import PrimaryButton from "./../../../components/generic/Buttons/PrimaryButton/PrimaryButton";
import Botonera from "./../../../components/forms/contents/Botonera/Botonera";
import ProfilePicture from "../../../components/profile-picture";
import Modal from "./../../../v1/components/Modal/Modal/Modal";
import Text from "./../../../components/text/Text/Text";
import _ from "lodash";
import ClaraAvatar from "../../../modules/generic/components/Avatars/ClaraAvatar";

const ImageUpload = ({
  avatarName,
  size,
  name,
  onChange,
  errorMessage,
  error,
  value,
  lock = false,
  variant,
  ...props
}) => {

  const [stateValue, setStateValue] = useState(value);
  const [openModal, setOpenModal] = useState(false);
  const profilePictureRef = useRef();

  useEffect(() => {
    if (stateValue !== value) {
      setStateValue(value);
    }
  }, [value]);
  
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleUploadImage = () => {
    const PP = profilePictureRef.current;
    const imageData = PP.getData();
    if (!_.get(imageData, "imageHeight")) {
      setStateValue("");
      onChange && onChange("", file);
      handleCloseModal();
      return;
    }
    const file = imageData.file;
    const imageAsDataURL = PP.getImageAsDataUrl();
    setStateValue(imageAsDataURL);
    onChange && onChange(imageAsDataURL, file);
    handleCloseModal();
  };

  return (
    <div className={classesModule.ImageUpload}>
      <ClaraAvatar
        avatar={stateValue}
        name={avatarName}
        variant={variant}
        add={!lock}
        addClick={handleOpenModal}
        locked={lock}
      >
      </ClaraAvatar>      
      <Modal
        className={{
          modal: classesModule.dialogModal,
          dialog: {
            paper: classesModule.dialogPaper,
          },
        }}
        open={openModal}
        handleClose={handleCloseModal}
        showHeader={true}
        title={"TITLE_UPLOAD_IMAGE"}
      >
        <div className={classesModule.ModalUpload}>
          <ProfilePicture
            ref={profilePictureRef}
            useHelper={true}
            debug={true}
            frameFormat={"circle"}
            maxImageSize={5242880}
            cropSize={127}
          />
          <div className={classesModule.DocumentPreviewBotonera}>
            <Botonera>
              <PrimaryButton onClick={handleUploadImage}>
                <Text uuid={"BUTTON_UPDATE"} />
              </PrimaryButton>
            </Botonera>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ImageUpload;

import React from 'react';
import {Constants} from '../../../../utils/constants'
import {useDispatch} from "react-redux";
import _ from "lodash";
import classes from "../ViewForms.module.scss";
import ServerConnect from "../../../../utils/ServerConnect";
import helper from "../../../../utils/helper";


const CompanyDetailsView = (props) => {

    const dispatch = useDispatch();
    const { mode, data } = props;


    const downloadHandler = (file) => {
        let url = ServerConnect.getUrlFile(file.file.id);
        ServerConnect.downloadFileAsDoc(url, file.file.name);
    }


    let renderYesOrNo = (value) => {
        return value == false ? 'No' : 'Yes'
    }

    let getAssetsKey = (value) => {
        switch (value) {
            case Constants.QUESTIONNAIRE_KEYS.SHARES_ALREADY_COMPANY:
                return 'QUESTIONARY_FORM_SPV_PRIMARY_ASSET_SHARES_ALREADY_COMPANY';
            case Constants.QUESTIONNAIRE_KEYS.INTELLECTUAL_PROPERTY:
                return 'QUESTIONARY_FORM_SPV_PRIMARY_ASSET_INTELLECTUAL_PROPERTY';
            case Constants.QUESTIONNAIRE_KEYS.SHARES_COMPANY:
                return 'QUESTIONARY_FORM_SPV_PRIMARY_ASSET_SHARES_COMPANY';
            case Constants.QUESTIONNAIRE_KEYS.OTHER:
                return 'QUESTIONARY_FORM_SPV_PRIMARY_ASSET_OTHER';
        }
    }




    return (

        <React.Fragment>
            <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                <label>{helper.getTranslateTextByKey('QUESTIONNAIRE_TAB_NAME_COMPANYDETAILS')}</label>
            </div>
            <hr className={`${classes.Separator} ${classes.modifiedSeparator}`} />
            <div className={`${classes.ContainerRow}`}>

                <label>{helper.getTranslateTextByKey('QUESTIONNAIRE_FIELD_COMPANY_TYPE')}:</label>
                <span>{_.get(data, 'companyType', '')}</span>
            </div>
            <div className={`${classes.ContainerRow}`}>
                <label>{helper.getTranslateTextByKey('QUESTIONNAIRE_FIELD_COMPANY_SUFFIX')}:</label>
                {_.get(data, 'holdingName', null) ? _.get(data, 'companyName', '') + ' ' + _.get(data, 'holdingName', '') + ' ' + _.get(data, 'limitedName', '') : null}
            </div>

            {/* ACCRONYM */}
            <div className={`${classes.ContainerRow}`}>
                <label>{helper.getTranslateTextByKey('QUESTIONNAIRE_FIELD_COMPANY_ABBREVIATIONS')}:</label>
                <span>{renderYesOrNo(_.get(data, 'haveAcronym'))}</span>
            </div>
            {_.get(data, 'haveAcronym') ? <div className={`${classes.ContainerRow}`}>
                <label>     </label>
                <span>{_.get(data, 'shortenedName', '')}</span>
            </div> : null}

            {/* ARABICNAME */}
            <div className={`${classes.ContainerRow}`}>
                <label>{helper.getTranslateTextByKey('QUESTIONNAIRE_FIELD_ARABIC_NAME')}:</label>
                <span>{renderYesOrNo(_.get(data, 'haveArabicName', ''))}</span>
            </div>
            {_.get(data, 'haveArabicName', null) ? <div className={`${classes.ContainerRow}`}>
                <label>      </label>
                <span>{_.get(data, 'arabicName', '')}</span>
            </div> : null}

            {/* AFFILIATION */}
            <div className={`${classes.ContainerRow}`}>
                <label>{helper.getTranslateTextByKey('QUESTIONNAIRE_FIELD_SIMILAR_COMPANY_NAME_AFFILIATION')}:</label>
                <span>{renderYesOrNo(_.get(data, 'haveAffiliation', ''))}</span>
            </div>
            {_.get(data, 'haveAffiliation', null) ? <div className={`${classes.ContainerRow}`}>
                <label>{'    '}</label>
                <span>{_.get(data, 'fullLegalName', '')}</span>
            </div> : null}

            {/* ASSETS */}

            {/* ASSET INTELLECTUAL PROPERTY */}

            {_.get(data, 'intellectualProperty', '') ? <React.Fragment>
                <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                    <label>{helper.getTranslateTextByKey('QUESTIONARY_FORM_SPV_PRIMARY_ASSET_INTELLECTUAL_PROPERTY')}</label>
                </div>
                {_.get(data, 'intellectualPropertyDoc', null) ? <React.Fragment><div className={`${classes.ContainerRow}`}>
                    <label>{helper.getTranslateTextByKey('QUESTIONNAIRE_FIELD_HOLDING_ASSETS')}:</label>
                    <span className={classes.docButton} onClick={() => { downloadHandler(_.get(data, 'intellectualPropertyDoc', '')) }}>Supporting Documentation</span>
                </div></React.Fragment> : null}

                {_.get(data, 'isRevenueGenerating', null) ? <div className={`${classes.ContainerRow} ${classes.bulletText}`}>
                    <label>Reason for holding Intellectual Property:</label>
                    <span>•Revenue Generation</span>
                </div> : null}
                {_.get(data, 'isProtection', null) ? <div className={`${classes.ContainerRow} ${classes.bulletText}`}>
                    <label>{_.get(data, 'isRevenueGenerating', null) ? '     ' : 'Reason for holding Intellectual Property:'}</label>
                    <span>•Protection</span>
                </div> : null} </React.Fragment> : null}


            {_.get(data, 'alreadyIncorporated', null) ?
                <React.Fragment>
                    <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                        <label>{helper.getTranslateTextByKey('QUESTIONARY_FORM_SPV_PRIMARY_ASSET_SHARES_ALREADY_COMPANY')}</label>
                    </div>
                    {_.get(data, 'alreadyCompanies', []).map((comp, key) => {
                        return (
                            <React.Fragment>
                                <div className={`${classes.ContainerRow}`}>
                                    <label>{key === 0 ? 'Companies:' : '     '}</label>
                                    <span>{_.get(comp, 'companyName', '')}</span>
                                </div>
                            </React.Fragment>
                        )
                    })}
                </React.Fragment> : null}



        </React.Fragment>
    )
}

export default CompanyDetailsView;

import useMutation from '../../../hooks/custom/useMutation';

export const RENAME_DOCUMENT = `
  mutation changeDocumentName(
    $startupId:ID!,
    $agreementId:ID!,
    $agreement:DocumentSignedInput!
  )
  {
    updateSignedAgreement(
      startupId: $startupId
      agreementId: $agreementId
      agreement: $agreement
    ){
      id
      name
      params
      file
      {
        name
      }
    }
  } 
  `;

const useRenameDocument = (config) => {
    const [mutation] = useMutation( RENAME_DOCUMENT, config );
    return [mutation ]
};

export default useRenameDocument;

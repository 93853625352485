import React, { Component } from 'react';
import { Field, Form } from "formik";
import _ from "lodash";
import moment from "moment";
import ClaraSelect from "../../../../../../../v1/components/Form/ClaraSelect/ClaraSelect";
import classes from "../Styles.module.scss";
import { TextAreaInput, TextInput, ClaraInputYearsMonths,TextType } from '../../../../../../../v1/components';
import Checkbox from "../../../../../../../v1/containers/Forms/Inputs/Checkbox";
import FormikHelper from "../../../../../../../v1/utils/formikHelper";
import ClaraUpload from "../../../../../../../v1/components/Form/ClaraUpload/ClaraUpload";
import ClaraCountrySelector from "../../../../../../../v1/components/Form/ClaraCountrySelector/ClaraCountrySelector";
import AuthorisedSignatories from "../../../../../../inputs/AuthorizedSignatories/AuthorizedSignatories";
import Line from "../../../../../../generic/Line/Line";
import AddStakeHolder from '../../../../../../../v1/containers/Forms/subForms/AddStakeHolder';
import {
	groupCompanyRecipientNotice, stakeholderAuthorizedSignatory, stakeholderRecipientNotice
} from '../../../../../../../v1/containers/Forms/generateDocumentForm/FormsGenerate/helper';

class RestrictiveCovenantAgreementDoc extends Component {

    constructor(props) {
        super(props);
        this.state = {
            typeSelected: undefined,
            groupCompanySelected: undefined,
            authorizedSelected: undefined,
            authorizedSignatories: [],
            authorizedSignatoriesList: [],
            documentTypes: [],
            documentTypesList: [],
            dynamicParams: [],
            date: undefined,
        }
    };

    componentWillMount() {
        if (this.props.values) {
            const values = this.props.values.values ? this.props.values.values : this.props.values;
            this.setState({
                typeSelected: values.documentType,
                teamMemberSelected: values.teamMember,
                groupCompanySelected: values.company,
                authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.company).map(as => ({
                    id: as.id,
                    label: as.label
                }))
            });
        }

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.values) {
            const values = nextProps.values.values ? nextProps.values.values : nextProps.values;
            this.setState({
                authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.company)
                    .map(as => ({
                        id: as.id,
                        label: as.label
                    }))
            });
        }
    }

    selectType = (documentType, extraParams, form) => {
        this.setState({
            typeSelected: documentType,
            dynamicParams: this.getDynamicParamsByType(documentType)
        }, () => {
            FormikHelper.setValueInTheCorrectPosition('terms', form, []);
            FormikHelper.setValueInTheCorrectPosition('documentType', form, documentType);
            this.state.dynamicParams.forEach((dynamicParam, i) => {
                FormikHelper.setValueInTheCorrectPosition(
                    `terms[${i}].key`,
                    form,
                    dynamicParam.key || 'NO_KEY'
                );
            })
        });
    };

    getDynamicParamsByType(documentType) {
        const type = this.props.documentTypes.filter(agrType => agrType.code === documentType)[0] || {};
        return type.fields || [];
    }

    getGroupCompanies = () => {
        let authorizedSignatories = this.props.authorizedSignatories;
        let groupCompanies = this.props.groupCompanies;
        let companies = [];
        if (groupCompanies && authorizedSignatories) {
            companies = _.filter(groupCompanies, (groupCompany) => {
                let fined = _.filter(authorizedSignatories, (authorized) => {
                    if (groupCompany.authorizedSignatories) {
                        return groupCompany.authorizedSignatories.some(au => au.stakeholder.id === authorized.id);
                    }
                    return false;
                });
                if (fined) {
                    return true;
                }
                return false;
            });
        }
        return companies;
    };

    selectGroupCompany = (gcId, extraParams, form) => {
        const groupCompany = this.findGroupCompany(gcId);

        // this is for a weird behaviour when hit edit button after preview a document
        if (this.state.groupCompanySelected && groupCompany) {
            return null;
        }

        this.setState({
            groupCompanySelected: groupCompany
        });

        if (groupCompany) {

            if (!groupCompany.registeredAddress) {
                groupCompany.registeredAddress = {}
            }
            let authorizedSignatories = _.get(form, 'values.company.authorizedSignatories', []);
            
            const newFormValues = {
                ...form.values,
                company: {
                    id: gcId,
                    name: groupCompany.name || '',
                    country: _.get(groupCompany, 'country.code', null) || _.get(groupCompany, 'country', null),
                    jurisdiction: _.get(groupCompany, 'jurisdiction'),
                    registeredNumber: groupCompany.registeredNumber || '',
                    type: groupCompany.type || '',
                    registeredAddress: {
                        ...groupCompany.registeredAddress, country: _.get(groupCompany, 'registeredAddress.country.code', null) || _.get(groupCompany, 'registeredAddress.country', null)
                    },
                    authorizedSignatories: authorizedSignatories,
					recipientNotice: groupCompanyRecipientNotice(form.values.company, groupCompany)
                }
            };

            form.setValues(newFormValues);

            this.setState({
                groupCompanySelected: groupCompany,
                // authorizedSignatoriesList: authorizedSignatories.map(as => ({
                // 	id: as.id,
                // 	label: as.label
                // }))
            })
        } else {
            form.setValues({
                ...form.values,
                company: {}
            });
        }
    };

    findGroupCompany(gcId) {
        return this.props.groupCompaniesList.find(gc => gc.id === gcId);
    };

    selectTeamMember = (teamMember, extraParams, form, cb) => {
        const stakeholder = this.findStakeholder(teamMember);

        this.setState({
            teamMemberSelected: stakeholder,
        });

        if (stakeholder) {
            let authorizedSignatory = {};

            if (!stakeholder.address) {
                _.set(stakeholder,'address', {})
            }

            const _teamMember = !_.isEmpty(form.values.teamMember) ? form.values.teamMember : stakeholder;

            if (stakeholder.isAnEntity) {
				authorizedSignatory = stakeholderAuthorizedSignatory(_teamMember);
                // authorizedSignatory = {
                //     fullName: typeof _teamMember.authorizedSignatory === 'object'
                //         ? _.get(_teamMember, 'authorizedSignatory.fullName', '')
                //         : _.get(_teamMember, 'authorizedSignatory', ''),
                //     email: _teamMember.email || ''
                // }
            }

            form.setValues({
                ...form.values,
                teamMember: {
                    id: teamMember,
                    fullName: _teamMember.fullName || '',
                    email: _teamMember.email || '',
                    roles: _teamMember.roles || [],
                    passportNumber: _teamMember.passportNumber || '',
                    registeredNumber: _teamMember.registeredNumber || '',
                    nationality: _.get(_teamMember, 'nationality.code', null) || _.get(_teamMember, 'nationality', null),
                    jurisdiction: _.get(_teamMember, 'jurisdiction'),
                    otherJurisdiction: _.get(_teamMember, 'otherJurisdiction', ''),
                    type: _teamMember.type || '',
                    address: { ..._teamMember.address, country: _.get(_teamMember, 'address.country.code', null) || _.get(_teamMember, 'address.country', null) },
                    isAnEntity: _teamMember.isAnEntity,
                    authorizedSignatory: authorizedSignatory,
					recipientNotice: stakeholderRecipientNotice(form.values.teamMember, stakeholder)
                }
            });
        } else {
            form.setValues({
                ...form.values,
                teamMember: {}
            });
        }
    };

    findStakeholder(roleId) {
        return this.props.stakeholderList.find(stakeholder => stakeholder.id === roleId);
    };

    findStakeholderAuthorized(roleId) {
        return this.props.stakeholderList.filter(stakeholder => {
            return stakeholder.roles.some(role => role.stakeholder.id === roleId);
        })[0] || null;
    };

    filterAuthorizedByGroupCompany(groupCompany) {
        if (groupCompany) {
            let gc = this.findGroupCompany(groupCompany.id);
            return this.props.authorizedSignatories.filter(authorized => {
                return authorized.groupCompanyId === gc.id
            });
        }
        return [];
    }

    selectAuthorized = (value, form, field, replace) => {
        const stakeholder = this.props.authorizedSignatories.find(e => e.id === value);
        const email = stakeholder ? stakeholder.email : '';
        replace(0, { id: value, email })
    };

    renderTeamMemberAuthorizedSignatory(field) {
        const { tooltips = {} } = this.props;

        if (this.state.teamMemberSelected.isAnEntity) {
            return (
                <React.Fragment>
                    <div className={classes.ClaraInputSelect}>
                        <Field name='teamMember.authorizedSignatory.fullName'>
                            {({ field, form }) => (
                                <TextInput
                                    classNameInput={{ root: classes.ContainerRow }}
                                    required={true}
                                    type='text'
                                    label='Corporate signatory '
                                    field={field}
                                    form={form}
                                    placeholder={"Corporate signatory"}
                                    tooltip={tooltips["Authorised Signatory"]}
                                />
                            )}
                        </Field>
                    </div>
                    <Field name='teamMember.email'>
                        {({ field, form }) => (
                            <TextInput
                                classNameInput={{ root: classes.ContainerRow }}
                                required={true}
                                type='text'
                                label="Authorised corporate's email address "
                                field={field}
                                form={form}
                                onChange={(e) => this.updateEmailValue(e, form)}
                                placeholder={"Email address"}
                                tooltip={tooltips['Authorised Signatory Email']}
                            />
                        )}
                    </Field>
                </React.Fragment>
            )
        }
    };

    updateEmailValue(e, form) {
        if (this.state.teamMemberSelected.isAnEntity) {
            form.setFieldValue('teamMember.authorizedSignatory.email', e.target.value, false)
        }
    }

    getDateForPickerFromData(date) {
        if (!date) return undefined;
        return moment(date).toDate();
    }

    handleChange(e, field, form) {

        const date = e === null ? null : moment(e).format("YYYY-MM-DD");

        FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

        this.setState({
            [field.name]: e
        })
    }

    getAuthorizedSignatoriesList = (index, form) => {
        if (form.values && form.values.company) {
            return this.filterAuthorizedByGroupCompany(form.values.company).map(as => ({
                id: as.id,
                label: as.label
            }))
        } else {
            return [];
        }
    };

    getLabel = (key) => {
        switch (key) {
            case "fullName":
                if (this.state.teamMemberSelected.isAnEntity) {
                    return "Company name";
                } else {
                    return "Name";
                }
            case "country":
                if (this.state.teamMemberSelected.isAnEntity) {
                    return "Country of incorporation";
                } else {
                    return "Nationality";
                }
            case "address":
                if (this.state.teamMemberSelected.isAnEntity) {
                    return "Registered address";
                } else {
                    return "Residential address";
                }
            case "passportOrRegistered":
                if (this.state.teamMemberSelected.isAnEntity) {
                    return "Company number";
                } else {
                    return "Passport number";
                }
        }
    };

    getField = (key) => {
        switch (key) {
            case "passportOrRegistered":
                if (this.state.teamMemberSelected.isAnEntity) {
                    return "teamMember.registeredNumber";
                } else {
                    return "teamMember.passportNumber";
                }
        }
    };

    getTooltip = (key, fieldName) => {
        switch (key) {
            case "fullName":
                if (this.state.teamMemberSelected.isAnEntity) {
                    return "teamMember Company's Name";
                } else {
                    return "teamMember's Name";
                }
            case "passportOrRegistered":
                if (this.state.teamMemberSelected.isAnEntity) {
                    return "Company Number";
                } else {
                    return "Passport Number";
                }
            case "country":
                if (this.state.teamMemberSelected.isAnEntity) {
                    return "Country";
                } else {
                    return "Nationality";
                }
            case "addressLine":
                if (this.state.teamMemberSelected.isAnEntity) {
                    return "Registered Address - Address";
                } else {
                    return "Residential Address - Address";
                }
            case "addressCity":
                if (this.state.teamMemberSelected.isAnEntity) {
                    return "Registered Address - City";
                } else {
                    return "Residential Address - City";
                }
            case "addressState":
                if (this.state.teamMemberSelected.isAnEntity) {
                    return "Registered Address - State";
                } else {
                    return "Residential Address - State";
                }
            case "addressCountry":
                if (this.state.teamMemberSelected.isAnEntity) {
                    return "Registered Address - Country";
                } else {
                    return "Residential Address - Country";
                }
            case "addressPostCode":
                if (this.state.teamMemberSelected.isAnEntity) {
                    return "Registered Address - Post Code";
                } else {
                    return "Residential Address - Post Code";
                }
            case "recipientNotice":
                if (fieldName === 'company') {
                    return "Company's";
                } else {
                    return "Team Member's";
                }
        }
    };

    getPlaceholder = (key) => {
        switch (key) {
            case "passportOrRegistered":
                if (this.state.teamMemberSelected.isAnEntity) {
                    return "Company number";
                } else {
                    return "Passport number";
                }
        }
    };

    renderRecipientNotice(fieldName, isAnEntity) {
        const { tooltips = {} } = this.props;

        if (isAnEntity) {
            return (
                <React.Fragment>
                    <Field name={`${fieldName}.recipientNotice.fullName`}>
                        {({ field, form }) => (
                            <TextInput
                                classNameInput={{ root: classes.ContainerRow }}
                                required={true}
                                type='text'
                                label='Recipient for notices '
                                field={field}
                                form={form}
                                placeholder={"Recipient's name"}
                                tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices"]}
                            />
                        )}
                    </Field>
                    <div className={` ${classes.Select}`}>
                        <Field name={`${fieldName}.recipientNotice.address.country`}>
                            {({ field, form }) => (
                                <ClaraCountrySelector
                                    isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                    jurisdictionsEnabled={false}
                                    countryField={`${fieldName}.recipientNotice.address.country`}
                                    label={"Recipient's address "}
                                    setFieldValue={form.setFieldValue}
                                    initialValues={{ country: _.get(form.values, `${fieldName}.recipientNotice.address.country`, null) }}
                                    returnCountryAsString={_.isEmpty(_.get(form.values, `${fieldName}.recipientNotice.address.country.code`, null))}
                                    additionalStyle={'ClaraCountrySelectorModal'}
                                    tooltips={{
                                        country: tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices - Country"]
                                    }}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.address.street`}>
                            {({ field, form }) => (
                                <TextAreaInput
                                    type='text'
                                    label={' '}
                                    field={field}
                                    form={form} placeholder={"Address"}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Address"]}
                                />
                            )}
                        </Field>
                    </div>
                    <Field name={`${fieldName}.recipientNotice.address.city`}>
                        {({ field, form }) => (
                            <TextInput
                                classNameInput={{ root: classes.ContainerRow }}
                                type='text'
                                field={field}
                                form={form}
                                placeholder={"City"}
                                label={' '}
                                tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - City"]}
                            />
                        )}
                    </Field>
                    <Field name={`${fieldName}.recipientNotice.address.state`}>
                        {({ field, form }) => (
                            <TextInput
                                classNameInput={{ root: classes.ContainerRow }}
                                type='text'
                                field={field}
                                form={form}
                                placeholder={"State / Region"}
                                label={' '}
                                tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - State"]}
                            />
                        )}
                    </Field>
                    <Field name={`${fieldName}.recipientNotice.address.zipCode`}>
                        {({ field, form }) => (
                            <TextInput
                                classNameInput={{ root: classes.ContainerRow }}
                                type='text'
                                field={field} form={form}
                                label={' '}
                                placeholder={"Post code"}
                                tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Post Code"]}
                            />

                        )}
                    </Field>
                    <Field name={`${fieldName}.recipientNotice.email`}>
                        {({ field, form }) => (
                            <TextInput
                                classNameInput={{ root: classes.ContainerRow }}
                                required={true}
                                type='text'
                                label="Recipient's email address "
                                field={field}
                                form={form}
                                placeholder={"Email address"}
                                tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Email Address"]}
                            />
                        )}
                    </Field>
                </React.Fragment>
            )
        }
    };

    renderAgreementDurationFields(form) {
        const { tooltips = {} } = this.props;
        if (!_.get(form, 'values.terms.agreementDuration')) {
            return null
        }

        return (
            <React.Fragment>
                {
                    form.values.terms.agreementDuration === 'otherDuration' ?
                        <div className={classes.ContainerRow}>
                            <Field name='terms.otherDuration'>
                                {({ field, form }) => (
                                    <ClaraInputYearsMonths

                                        required={true}
                                        field={field}
                                        form={form}
                                        label={'Duration '}
                                        tooltips={tooltips}

                                    />
                                )}
                            </Field>

                        </div>
                        : null
                }
            </React.Fragment>
        );

    }

    getStakeholderAdds = () => {
        return [
			{
                type: "stakeholder",
                label: "Add new individual stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
                placeholder: "Stakeholder's name",
                startupId: this.props.startup.id,
				form: AddStakeHolder,
				formProps: { groupCompanies: this.props.groupCompanies },
				textToSave: "fullName",
                params: {
                    isAnEntity: false
                },
                paramsReturn: 'isAnEntity'
            },
            {
                type: "stakeholder",
                label: "Add new corporate stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
                placeholder: "Stakeholder's name",
                startupId: this.props.startup.id,
				form: AddStakeHolder,
				formProps: { groupCompanies: this.props.groupCompanies },
				textToSave: "fullName",
                params: {
                    isAnEntity: true
                },
                paramsReturn: 'isAnEntity'
            },
        ]
    };

    getCompanyAdds = () => {
        return [{
            type: "groupCompany",
            label: "Add new company",
            textWarning: "This will create a new group company for your startup. You can add or amend the details of this group company on its profile page.",
            placeholder: "Company name",
            startupId: this.props.startup.id,
            params: null,
            paramsReturn: `
				name
				authorizedSignatories {
					id
					stakeholder {
						id
						email
						fullName
						roles {   
							id
        					name
        					groupCompany { id name}
        					stakeholder { id } }
						}
					}
			`
        }]
    };

    getAuthorizedSignatoryAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add new individual stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
                placeholder: "Enter stakeholder's name",
                startupId: this.props.startup.id,
				textToSave: "fullName",
                params: {
                    isAnEntity: false,
                    roles: [{
                        role: "DIRECTOR",
                        groupCompany: this.state.groupCompanySelected.id,
                    }]
                }
            },
        ]
    };

    handleStakeholderCreate = (value, name, additionalValue, form) => {

        let { stakeholders, stakeholderList } = this.props;
        let newSh = { id: value.id, fullName: name, ...additionalValue };
        stakeholderList.push(newSh);

        stakeholders.push({ id: value.id, label: name });
        // this.setState({ groupCompaniesList, groupCompanies });

    };

    handleGroupCompanyCreate = (value, name, additionalValue, form) => {

        let { groupCompanies, groupCompaniesList } = this.props;
        let newGc = { id: value.id, name, ...additionalValue };
        groupCompaniesList.push(newGc);

        groupCompanies.push({ id: value.id, label: name });
        // this.setState({ groupCompaniesList, groupCompanies });

    };

    handleAuthorizedSignatoryCreate = (value, name, additionalValue, form) => {

        let { authorizedSignatories } = this.props;
        const { groupCompanySelected } = this.state;

        authorizedSignatories.push({
            id: value.id,
            label: name,
            role: 'DIRECTOR',
            groupCompanyId: groupCompanySelected.id
        });

    };

    render() {
        const isRequired = message => value => (!!value ? undefined : message);
        const { tooltips = {}, formType } = this.props;

        return (
            <React.Fragment>
                <h1 className={classes.title}>
                    Edit Document
                </h1>
                {formType === 'uploadToEdit' ? (<div>
                    <Field name={`file`}>
                        {({ field, form }) => (
                            <ClaraUpload
                                required={true}
                                name={`filepond`}
                                label='Upload your document '
                                field={field}
                                form={form}
                            />
                        )}
                    </Field>
                </div>) : null}
                <Field name='agreementName'>
                    {({ field, form }) => (
                        <TextInput
                            classNameInput={{ root: classes.ContainerRow }}
                            required={true}
                            type='text'
                            label='Document name '
                            field={field}
                            form={form}
                            placeholder={"Document name"}
                            tooltip={tooltips['Document Name']}
                        />
                    )}
                </Field>

                <Line className={classes.line} />
                <div className={classes.ZoneTitle}>
                    <h1>{'Team Member'}</h1>
                </div>
                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                    <Field name='teamMember.id'>
                        {({ field, form }) => (
                            <ClaraSelect
                                required={true}
                                field={field}
                                form={form}
                                label={"Team member"}
                                lists={this.props.stakeholders}
                                adds={this.getStakeholderAdds()}
                                mode={"classic"}
                                callbacks={{
                                    updateValues: this.selectTeamMember,
                                    createValues: this.handleStakeholderCreate
                                }}
                                placeholder={"Select from the list"}
                                tooltip={tooltips["Select Team Member"]}
                            />

                        )}
                    </Field>
                </div>
                {
                    this.state.teamMemberSelected ?
                        <React.Fragment>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                {
                                    this.state.teamMemberSelected.isAnEntity ?
                                        <>
                                            <h1>Team Member Company Details</h1>
                                            <p>Please verify or update the team member's company's information below</p>
                                        </>
                                        :
                                        <>
                                            <h1>Team Member Details</h1>
                                            <p>Please verify or update the team member’s information below</p>
                                        </>
                                }
                            </div>
                            <Field name='teamMember.fullName'>
                                {({ field, form }) => (
                                    <TextInput
                                        classNameInput={{ root: classes.ContainerRow }}
                                        type='text'
                                        label={this.getLabel("fullName") + ' '}
                                        field={field}
                                        form={form}
                                        placeholder={'Name'}
                                        required={true}
                                        tooltip={tooltips[this.getTooltip('fullName')]}
                                    />
                                )}
                            </Field>
                            <div className={` ${classes.Select}`}>
                                <Field name='teamMember.nationality'>
                                    {({ field, form }) => {
                                        return (
                                            <ClaraCountrySelector
                                                isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                                jurisdictionsEnabled={this.state.teamMemberSelected.isAnEntity}
                                                countryField={'teamMember.nationality'}
                                                label={this.getLabel('country') + ''}
                                                jurisdictionField={'teamMember.jurisdiction'}
                                                jurisdictionLabel={'Jurisdiction '}
                                                returnCountryAsString={true}
                                                otherJurisdictionField={'teamMember.otherJurisdiction'}
                                                setFieldValue={form.setFieldValue}
                                                initialValues={{ jurisdiction: _.get(form.values, 'teamMember.jurisdiction') }}
                                                additionalStyle={'ClaraCountrySelectorModal'}
                                                tooltips={{
                                                    country: tooltips[this.getTooltip('country')],
                                                    jurisdiction: tooltips['Jurisdiction'],
                                                    other: tooltips['Other Jurisdiction']
                                                }}
                                            />
                                        )
                                    }
                                    }
                                </Field>
                            </div>
                            {
                                this.state.teamMemberSelected.isAnEntity ?
                                    <React.Fragment>
                                        <Field name='teamMember.type'>
                                            {({ field, form }) => (
                                                <TextInput
                                                    classNameInput={{ root: classes.ContainerRow }}
                                                    required={true}
                                                    type='text'
                                                    label='Company type '
                                                    field={field}
                                                    form={form}
                                                    placeholder={'Company type'}
                                                    tooltip={tooltips['Company Type']}
                                                />
                                            )}
                                        </Field>
                                    </React.Fragment>
                                    :
                                    null
                            }
                            <Field name={this.getField("passportOrRegistered")}>
                                {({ field, form }) => (
                                    <TextInput
                                        classNameInput={{ root: classes.ContainerRow }}
                                        required={true}
                                        type='text'
                                        label={this.getLabel("passportOrRegistered") + ' '}
                                        field={field}
                                        form={form}
                                        placeholder={this.getPlaceholder("passportOrRegistered")}
                                        tooltip={tooltips[this.getTooltip("passportOrRegistered")]}
                                    />
                                )}
                            </Field>
                            {
                                !this.state.teamMemberSelected.isAnEntity ?
                                    <React.Fragment>
                                        <Field name='teamMember.email'>
                                            {({ field, form }) => (
                                                <TextInput
                                                    classNameInput={{ root: classes.ContainerRow }}
                                                    type='text'
                                                    label='Email address '
                                                    field={field}
                                                    form={form}
                                                    placeholder={'Email address'}
                                                    required={true}
                                                    tooltip={tooltips["Team Member's Email Address"]}
                                                />
                                            )}
                                        </Field>
                                    </React.Fragment>
                                    :
                                    null
                            }
                            <div className={` ${classes.Select}`}>
                                <Field name='teamMember.address.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={false}
                                            countryField={'teamMember.address.country'}
                                            label={this.getLabel('address') + ''}
                                            setFieldValue={form.setFieldValue}
                                            returnCountryAsString={true}
                                            additionalStyle={'ClaraCountrySelectorModal'}
                                            tooltips={{
                                                country: tooltips[this.getTooltip('addressCountry')]
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='teamMember.address.street'>
                                    {({ field, form }) => (
                                        <TextAreaInput
                                            type='text'
                                            label={' '}
                                            field={field}
                                            form={form}
                                            placeholder={'Address'}
                                            tooltip={tooltips[this.getTooltip('addressLine')]}

                                        />
                                    )}
                                </Field>
                            </div>
                            <Field name='teamMember.address.city'>
                                {({ field, form }) => (
                                    <TextInput
                                        classNameInput={{ root: classes.ContainerRow }}
                                        type='text'
                                        label=' '
                                        field={field}
                                        form={form}
                                        placeholder={'City'}
                                        tooltip={tooltips[this.getTooltip('addressCity')]}
                                    />
                                )}
                            </Field>
                            <Field name='teamMember.address.state'>
                                {({ field, form }) => (
                                    <TextInput
                                        classNameInput={{ root: classes.ContainerRow }}
                                        type='text'
                                        label=' '
                                        field={field}
                                        form={form}
                                        placeholder={'State / Region'}
                                        tooltip={tooltips[this.getTooltip('addressState')]}
                                    />
                                )}
                            </Field>
                            <Field name='teamMember.address.zipCode'>
                                {({ field, form }) => (
                                    <TextInput
                                        classNameInput={{ root: classes.ContainerRow }}
                                        type='text'
                                        label=' '
                                        field={field}
                                        form={form}
                                        placeholder={"Post code"}
                                        tooltip={tooltips[this.getTooltip('addressPostCode')]}
                                    />
                                )}
                            </Field>
                            {this.renderRecipientNotice('teamMember', this.state.teamMemberSelected.isAnEntity)}
                            {this.renderTeamMemberAuthorizedSignatory('teamMember')}
                        </React.Fragment>
                        :
                        null
                }
                <Line className={classes.line} />
                <div className={classes.ZoneTitle}>
                    <h1>{'Company'}</h1>
                </div>
                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                    <Field name='company.id'>
                        {({ field, form }) => (
                            <ClaraSelect
                                required={true}

                                field={field}
                                form={form}
                                label={"Company "}
                                lists={this.getGroupCompanies()}
                                mode={"classic"}
                                callbacks={{
                                    createValues: this.handleGroupCompanyCreate,
                                    updateValues: this.selectGroupCompany
                                }}
                                placeholder={"Select from the list"}
                                tooltip={tooltips['Select Company']}
                            />
                        )}
                    </Field>
                </div>
                {
                    this.state.groupCompanySelected ?
                        <React.Fragment>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Company Details</h1>
                                <p>Please verify or update the company's information below</p>
                            </div>
                            <Field name='company.name'>
                                {({ field, form }) => (
                                    <TextInput
                                        classNameInput={{ root: classes.ContainerRow }}
                                        required={true}
                                        type='text'
                                        label='Company name '
                                        field={field}
                                        form={form}
                                        placeholder={"Company name"}
                                        tooltip={tooltips["Company's Name"]}
                                    />
                                )}
                            </Field>
                            <div className={` ${classes.Select}`}>
                                <Field name='company.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={true}
                                            countryField={'company.country'}
                                            label={'Country of incorporation '}
                                            jurisdictionField={'company.jurisdiction'}
                                            jurisdictionLabel={'Jurisdiction '}
                                            returnCountryAsString={true}
                                            otherJurisdictionField={'company.otherJurisdiction'}
                                            setFieldValue={form.setFieldValue}
                                            initialValues={{
                                                jurisdiction: _.get(form.values, 'company.jurisdiction')
                                            }}
                                            additionalStyle={'ClaraCountrySelectorModal'}
                                            tooltips={{
                                                country: tooltips["Company's Country"],
                                                jurisdiction: tooltips["Company's Jurisdiction"],
                                                other: tooltips['Other Jurisdiction']
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            {/*
                            <div className={classes.ContainerRow}>
                                <Field name='company.jurisdiction'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label='Jurisdiction: '
                                            field={field}
                                            form={form}
                                            placeholder={"Jurisdiction..."}
                                            tooltip={tooltips["Company's Jurisdiction"]}
                                        />
                                    )}
                                </Field>
                            </div>
							*/}
                            <Field name='company.type'>
                                {({ field, form }) => (
                                    <TextType
                                        classNameInput={{ root: classes.ContainerRow }}
                                        required={true}
                                        type='text'
                                        groupCompany={this.state.groupCompanySelected}
                                        label='Company type '
                                        field={field}
                                        form={form}
                                        placeholder={'Company type'}
                                        tooltip={tooltips["Company's Company Type"]}
                                    />
                                )}
                            </Field>
                            <Field name='company.registeredNumber'>
                                {({ field, form }) => (
                                    <TextInput
                                        classNameInput={{ root: classes.ContainerRow }}
                                        required={true}
                                        type='text'
                                        label='Company number '
                                        field={field}
                                        form={form}
                                        placeholder={'Company number'}
                                        tooltip={tooltips["Company's Company Number"]}
                                    />
                                )}
                            </Field>
                            <div className={` ${classes.Select}`}>
                                <Field name='company.registeredAddress.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            jurisdictionsEnabled={false}
                                            countryField={'company.registeredAddress.country'}
                                            label={'Registered address '}
                                            returnCountryAsString={true}
                                            setFieldValue={form.setFieldValue}
                                            additionalStyle={'ClaraCountrySelectorModal'}
                                            tooltips={{
                                                country: tooltips["Company's Registered Address - Country"]
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.registeredAddress.street'>
                                    {({ field, form }) => (
                                        <TextAreaInput
                                            type='text'
                                            label={' '}
                                            field={field}
                                            form={form}
                                            placeholder={"Address"}
                                            tooltip={tooltips["Company's Registered Address - Address"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <Field name='company.registeredAddress.city'>
                                {({ field, form }) => (
                                    <TextInput
                                        classNameInput={{ root: classes.ContainerRow }}
                                        type='text'
                                        label=' '
                                        field={field}
                                        form={form}
                                        placeholder={'City'}
                                        tooltip={tooltips["Company's Registered Address - City"]}
                                    />
                                )}
                            </Field>
                            <Field name='company.registeredAddress.state'>
                                {({ field, form }) => (
                                    <TextInput
                                        classNameInput={{ root: classes.ContainerRow }}
                                        type='text'
                                        label=' '
                                        field={field}
                                        form={form}
                                        placeholder={'State / Region'}
                                        tooltip={tooltips["Company's Registered Address - State"]}
                                    />
                                )}
                            </Field>
                            <Field name='company.registeredAddress.zipCode'>
                                {({ field, form }) => (
                                    <TextInput
                                        classNameInput={{ root: classes.ContainerRow }}
                                        type='text'
                                        label=' '
                                        field={field}
                                        form={form}
                                        placeholder={"Post code"}
                                        tooltip={tooltips["Company's Registered Address - Post Code"]}
                                    />
                                )}
                            </Field>
                            {this.renderRecipientNotice('company', true)}
                            <Line className={classes.line} />
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Authorised Signatory</h1>
                                <p>Please add all the signatories required to sign this document on behalf of the company.</p>
                            </div>
                            <Field name='company.authorizedSignatories_error'>
                                {({ field, form }) => (
                                    <React.Fragment>
										<span hasError={_.get(form.errors, "company.authorizedSignatories") ? "true" : "false"}>
											<AuthorisedSignatories
												modal={true}
                                                list={this.props.stakeholderList}
												fieldArrayName={'company.authorizedSignatories'}
												idGroupCompany={this.state.groupCompanySelected.id}
												errorMessage={_.get(form.errors, "company.authorizedSignatories")}
											/>
										</span>
                                    </React.Fragment>
                                )}
                            </Field>
                            <Line className={classes.line} />
                        </React.Fragment>
                        :
                        null
                }

                {
                    this.state.teamMemberSelected && this.state.groupCompanySelected ?
                        <React.Fragment>
                            <div className={classes.ZoneTitle}>
                                <h1>{'Terms'}</h1>
                            </div>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Restricted Territory</h1>
                                <p>The geographical area which will be protected by the Restrictive Covenant Agreement.</p>
                            </div>
                            <Field name='terms.restrictedTerritory'>
                                {({ field, form }) => (
                                    <TextInput
                                        classNameInput={{ root: classes.ContainerRow }}
                                        required={true}
                                        type='text'
                                        trim={true}
                                        label='Restricted territory '
                                        field={field}
                                        form={form}
                                        placeholder={'Describe the geographical area here'}
                                        tooltip={tooltips['Restricted Territory']}
                                    />
                                )}
                            </Field>
                            <Line className={classes.line} />

                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Place of Arbitration</h1>
                                <p>This contract’s dispute resolution mechanism uses arbitration under expedited ICC rules. Please select the place where you would like the arbitration to take place (please enter the name of the city and country and, if applicable, state or freezone), e.g. "London, United Kingdom" or "New York City, New York, United States of America” or "Dubai International Financial Centre, Dubai, United Arab Emirates”. Please note that the place of arbitration that you select can have a material impact on the proceedings and enforcement of the arbitration and arbitral award. Please seek legal advice.</p>
</div>
<Field name={`terms.placeOfArbitration`}>
    {({ field, form }) => (
        <TextInput
            required={true}
            type='text'
            label='Place of arbitration '
            field={field}
            classNameInput={{ root: classes.ContainerRow }}

            form={form}
            placeholder={"Place of Arbitration"}
        />
    )}
</Field>
                        </React.Fragment>
                        : null
                }


                {formType === 'uploadToEdit' ? <div>
                    <Field name='confirmSameData'>
                        {({ field, form }) => (
                            <Checkbox
                                className={classes.sameDataCheckbox}
                                field={field}
                                validate={isRequired('This field is required')}
                                form={form}
                                required={true}
                                label='All document details are verified and correct.'
                            />
                        )}
                    </Field>
                </div> : null}

            </React.Fragment>

        );
    }

}

export default RestrictiveCovenantAgreementDoc;

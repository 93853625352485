export default {
  ALREADY_FORMED : {
    FIRST_STEP: {
      TITLE: "Has this company already been formed?",
      SUBTITLE: "Formed company",
      NOBTN: "No, it hasn't",
      YESBTN: "Yes, it has",
      TOOLTIP:
        "<p>If this company has already formed, buying a formation through Clara is not necessary.</p>&nbsp;<p></p><p>Simply provide a Certificate of Incorporation and provide some details to use Clara's other features.</p>",
    },
  },
  NEXT: "Next",
};

import React from 'react';
import KycIndividualIntroView from "./KycIndividualIntro/KycIndividualIntroView"
import KycCompanyIntroView from "./KycCompanyIntro/KycCompanyIntroView"
import KycSubmittedView from "./KycSubmitted/KycSubmittedView"
import KycEngagementIntroView from "./KycEngagementIntro/KycEngagementIntroView"
import KycEngagementApproveView from "./KycEngagementApprove/KycEngagementApproveView"
import KycEngagementDeclineView from "./KycEngagementDecline/KycEngagementDeclineView"
import EngagementDocumentView from "./EngagementDocumentView/EngagementDocumentView"
import layout from "../../../../layouts/Clara/ClaraLayout"
import BlueScreenLayout from "../../../../layouts/BlueScreen/BlueScreenLayout"
import ACTIONS from "../../../../constants/actions"
import PAGES from "../../../../constants/pages";

const moduleRouter = [
  {
    path: PAGES.kycIndividualIntro.path,
    exact: true,
    component: <KycIndividualIntroView />,
    layout: layout,
    propsLayout : {},
    action: ACTIONS.COMPLETE_MATTER_FLOW,
  },
  {
    path: PAGES.kycCompanyIntro.path,
    exact: true,
    component: <KycCompanyIntroView />,
    layout: layout,
    propsLayout : {},
    action: ACTIONS.COMPLETE_MATTER_FLOW,
  },
  {
    path: PAGES.kycSubmitted.path,
    exact: true,
    component: <KycSubmittedView />,
    layout: layout,
    propsLayout : {},
    action: ACTIONS.COMPLETE_MATTER_FLOW,
  },
  {
    path: PAGES.kycEngagementIntro.path,
    exact: true,
    component: <KycEngagementIntroView />,
    layout: layout,
    propsLayout : {},
    action: ACTIONS.COMPLETE_MATTER_FLOW,
  },
  {
    path: PAGES.kycEngagementApprove.path,
    exact: true,
    component: <KycEngagementApproveView />,
    layout: layout,
    propsLayout : {},
    action: ACTIONS.COMPLETE_MATTER_FLOW,
  },
  {
    path: PAGES.kycEngagementDecline.path,
    exact: true,
    component: <KycEngagementDeclineView />,
    layout: layout,
    propsLayout : {},
    action: ACTIONS.COMPLETE_MATTER_FLOW,
  },
  {
    path: PAGES.matterEngagementView.path,
    exact: true,
    component: <EngagementDocumentView />,
    layout: BlueScreenLayout,
    propsLayout : {
      showClose:false
    },
    action: ACTIONS.COMPLETE_MATTER_FLOW,
  },
];
export default moduleRouter;

import React,{useMemo} from 'react';
import useAgents from '../../../../../hooks/services/modules/incorporation/useAgents';
import useSession from '../../../../../modules/session/hooks/useSession';
import FixedSelectInput from '../../../FixedSelect/FixedSelectInput';
import _ from "lodash";



const FixedSelectAgentInput = ({ name = 'agent', required, onChange }) => {
    const agents = useAgents();
    
    const { session } = useSession();
    const user = useMemo(() => _.get(session, "user.fullname"), [JSON.stringify(_.get(session, "user.fullname"))]);
    const fixedList = [{
        fixedClass: true, label: 'Show All', id: 'showAll', component: <div>
            <img src="/img/icons/bluePerson.svg" />{'  ' + 'Show All'}
        </div>
    }, {
        fixedClass: true, label: 'Assigned to Me', id: user, component: <div>
            <img src="/img/icons/blueFlag.svg" />{' ' + 'Assigned to me'}
        </div>
    }]


    const clearedValues = {value: 'showAll', filter:'Show All'}





    return (
        <React.Fragment>
            <div>
                <FixedSelectInput
                    list={agents}
                    fixedList={fixedList}
                    name={name}
                    clear={true}
                    onChange={onChange}
                    value={'Show All'}
                    clearedValues={clearedValues}
                />
            </div>
        </React.Fragment >
    )
}

export default FixedSelectAgentInput;
import useMutation from "../../../../../hooks/custom/useMutation";

export const UPSERT_COMPONENT_STAKEHOLDER_ROLE = `
mutation upsertStakeholderRoles($startupId: ID!, $stakeholderId: ID!, $stakeholderRolesData: StakeholderRolesInput!) {
  upsertStakeholderRoles(
    startupId: $startupId
    stakeholderId: $stakeholderId
    stakeholderRolesData: $stakeholderRolesData
  ) {
    id
    fullName
    roles {
      id
      name
      isActive
      entity {
          ... on StakeHolder {
              id
              fullName
          }
          ... on GroupCompany {
              id
              name
          }
      }
    }
  }
}
`
const useUpsertComponentStakeholderRole = (config, props) => {
  const [mutation, loading] = useMutation(UPSERT_COMPONENT_STAKEHOLDER_ROLE, config, { ...props });
  return { mutation, loading };
}

export default useUpsertComponentStakeholderRole;
class Core {
  constructor(data) {
    // Iterating over the properties of the JSON object and adding them to the class instance
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        this[key] = data[key];
      }
    }
  }
}
export default Core;
export default {
    FIRST_STEP:{
        TOOLTIP:'<b>Share options</b> give the option holder the right to buy shares in your company in the future. <a rel="noopener noreferrer" target="_blank"  href="https://academy.clara.co/article/4xMCcNdHhHhD6sTTvU5e75">Find out more</a> about the difference between shares and options. ',
        TITLE:'Do they have share options?',
        SUBTITLE: "Add share options",
        TOOLTIP_CANCEL:'Giving team members and directors options to buy shares in the future is a great way to incentivise and motivate them. '
    },
    SECOND_STEP_A:{
        TOOLTIP:'',
        TITLE:'Select the companies in which they have share options',
        SUBTITLE:"Add share options"
    },
    SECOND_STEP_B:{
      TOOLTIP:'To generate a share options plan on Clara, you need a Scale subscription. To do this, simply choose the Scale Up option and select next.',
      TITLE:'Scale up to generate a Grant Agreement for these share options',
      SUBTITLE:'Add share options'
    },
    THIRD_STEP:{
        TITLE:'Complete the details for this option grant',
        SUBTITLE:"Equity positions - Add option grant",
        TOOLTIP:''
    },
    FOURTH_STEP:{
        SUBTITLE:"Add share options",
        TITLE:'Is there a Grant Agreement for these share options?',
        TOOLTIP:'A Grant Agreement sets out the terms on which share options are granted to a team member.',
        TOOLTIP_IS_YES_UPLOAD:'This will be safely stored in Documents, your Clara data room.'
    },
    FIFTH_STEP_A:{
        TITLE:'Upload Grant',
        SUBTITLE:'Add share options',
        TOOLTIP:'This will be safely stored in Documents, your Clara data room.'
    },
    FIFTH_STEP_B:{
        TITLE:'Select a Grant Agreement',
        SUBTITLE:'Add share options',
        TOOLTIP:''
    }
}
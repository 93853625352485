
import useLazyQuery from 'src/hooks/custom/useLazyQuery';

const query = `query getBankTransfersDetails($productCode: String!, $entityId: ID!, $startupId: ID!, $couponCode: String) {
  getBankTransfersDetails(
    productCode: $productCode
    entityId: $entityId
    startupId: $startupId
    couponCode: $couponCode
  ) {
    reference
    rateConversion {
      vat
      exclusiveOfVAT
      inclusiveOfVAT
      __typename
    }
    product {
      value
      bankInformation {
        wireDetails {
            type

        }
        beneficiaryBank {
            address
            name
            swiftBicCode
        }
        intermediaryBank {
            name
            address
            swiftBicCode
        }
        
         beneficiary {
            name
            address
            routingNumber
            accountNo
            accountType

        }

         receivingBank {
            name
            address
        }
      }
      __typename
    }
    __typename
  }
}
  `;

const useGetBankInformation = (options) => {

  const { manualQuery, ...rest } = useLazyQuery(query, options);

  

  return {manualQuery, ...rest};
}
export default useGetBankInformation;
import React, { useState, useMemo, useRef,useEffect } from 'react';
import classes from './Style.module.scss';
import RowAdd from '../../../../../contents/RowAdd/RowAdd';
import ControllerInput from '../../../../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import SelectAddInput from '../../../../../../inputs/Select/SelectAddInput/SelectAddInput';
import Text from '../../../../../../text/Text/Text';


const AddEntity = ({ label, name, message, callback, callbackParams, formsAdd, menuProps, asterisk, list, ...props }) => {
   
    return (
        <React.Fragment>

            <RowAdd
                label={label}
                asterisk={asterisk}
                input={
                    <ControllerInput
                        {...props}

                        as={SelectAddInput}
                        list={list}
                        mode={'add'}
                        placeholder={"Select From the list..."}
                        formsAdd={formsAdd}
                        onChange={(value, setValueForm) => {
                            if (callback) {
                                callback(value, setValueForm, callbackParams);
                                return value
                            }
                            return value
                        }}
                        menuProps={menuProps}
                        name={name}

                    />

                }
            />

            {message.open && <div className={classes.containerMessage}>
                <Text uuid={message.text} />
            </div>
            }
        </React.Fragment>

    )
}


export default AddEntity

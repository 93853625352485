import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useSession from '../../../../../../modules/session/hooks/useSession';
import { Constants } from '../../../../../../v1/utils/constants';
import classes from "./RadioSelectorRoles.module.scss";


const RadioSelectRoles = ({ options, title, onChange, handleOpenDeactivateSelfRoleModal, shareModal, isPopover, initVal, handleOpenReactivateModal, handleOpenReactiveRoleModal, getRoleName, userEditSelf, singleOwner, userStartupRole, handleOpenDeactivateRoleModal, handleOpenChangeRoleModal, value }) => {

    const [dataModal, setDataModal] = useState({});
    const [valuesWatch, setValuesWatch] = useState({});
    const [selectedValue, setSelectedValue] = useState(initVal);



    const dispatch = useDispatch();
    
    const { user } = useSession();

    const showChecked = (value) => {
        
        if (selectedValue) {
            return value == selectedValue ? classes.buttonRoleSelected : classes.buttonRoleToCheck
        } else {
            
            return classes.buttonRoleToCheck
        }
    }

    const handleClick = (val) => {
        if (shareModal) {
            setSelectedValue(val)
            onChange(val)
        } else if (initVal == val && userEditSelf && isPopover) {
            setSelectedValue('USER_INACTIVE')
            getRoleName(val)
            handleOpenDeactivateSelfRoleModal()
            onChange('USER_INACTIVE')
        } else if (initVal == val && !isPopover) {
            { }
        } else if (initVal == val && isPopover) {
            setSelectedValue('USER_INACTIVE')
            handleOpenDeactivateRoleModal()
            onChange('USER_INACTIVE')
            getRoleName(val)
        } else if (initVal == 'USER_INACTIVE' && isPopover) {
            handleOpenReactiveRoleModal()
            getRoleName(val)
        } else {
            getRoleName(val)
            setSelectedValue(val)
            onChange(val)
            handleOpenChangeRoleModal()
        }

    }

    const checkSingleOwner = (roleToChange) => {
        if (userStartupRole == Constants.USER_ROLES.USER_OWNER && userEditSelf && singleOwner) {
            return true
        } else if (singleOwner && initVal == Constants.USER_ROLES.USER_OWNER) {
            return true
        } else if (userStartupRole == Constants.USER_ROLES.USER_MANAGER && initVal == Constants.USER_ROLES.USER_OWNER) {
            return true
        } return false

    }

    const popoverClass = isPopover ? classes.popoverLabel : null

	useEffect(() => {
		if (!selectedValue) {
			setSelectedValue(value);
		}
	}, [value]);

    return (
        <React.Fragment>
            <div className={classes.titleContainer}>
                {title ? <p>{title}</p> : null}
            </div>
            <div className={classes.SelectorContainer}>
                {options.map( (opt,i) => {
                    return (
                        <React.Fragment key={i}>
                            {opt.id == Constants.USER_ROLES.USER_OWNER && userStartupRole == Constants.USER_ROLES.USER_MANAGER ? null : <div className={`${classes.optionSelectorContainer} ${isPopover?classes.optionSelectorContainerModal:null}`}>
                                
                                <button type='button' disabled={checkSingleOwner()} className={`${showChecked(opt.id)}`} onClick={() => { handleClick(opt.id) }}>
                                    <span className={popoverClass}>{opt.label}</span>
                                    {opt.info ? <React.Fragment>
                                    </React.Fragment> : null}
                                </button>
                                <label className={classes.infoLabel}>{opt.info}</label>
                            </div>}

                        </React.Fragment>)

                })}
            </div>
        </React.Fragment>
    )
};

export default RadioSelectRoles;

export default {
  // General use
  TYPE_HERE_HIT: "Type here",
  EXAMPLE_PROFESSION:'For example, lawyer',
  BTN_CANCEL: "Cancel",
  BTN_SAVE_CHANGES: "Save changes",
  BTN_DELETE: 'Delete',
  BTN_PREVIOUS: 'Previous',
  BTN_NEXT: 'Next',
  BTN_CONFIRM: 'Confirm',
  BTN_ADD: 'Add',

  // General enter
  ENTER_NUMBER: 'Enter a number',
  ENTER_AMOUNT: 'Amount',

  // Address input component
  ADDRESS_LABEL_RESIDENTIAL: 'Residential address',
  ADDRESS_LABEL_PRIMARY_RESIDENTIAL: 'Primary residential address',
  ADDRESS_INPUT_COUNTRY: "Country",
  ADDRESS_INPUT_ADDRESS: "Address",
  ADDRESS_INPUT_CITY: "City",
  ADDRESS_INPUT_STATE: "State / Region",
  ADDRESS_INPUT_ZIP: "Zip Code / Post Code",
  ADDRESS_REGISTERED_LABEL: "Registered address",
  ADDRESS_RECIPIENT_NOTICE: 'Full legal name',
  ADDRESS_RECIPIENT_NOTICE_EMAIL: 'Email',
  COMPANY_ADDRESS:'Company address',
  ACCOUNTING_ADDRESS:'Accounting address',
  SERVICE_ADDRESS_LABEL: "Service address",
  RECIPIENT_ADDRESS: "Recipient address",

  // Company form
  COMPANY_NAME_LABEL: "Company name",
  COMPANY_TYPE_LABEL: "Company type",
  COMPANY_NUMBER_LABEL: "Company number",
  COMPANY_TAX_REGISTRATION_NUMBER_LABEL: "Tax Registration Number (if applicable)",
  COMPANY_CURRENCY: "Currency",
  ENTITY_NAME_LABEL: "Entity name",


  // Role date input componet
  ROLE_DATE_INPUT_HIT: "dd/mm/yyyy",
  ROLE_DATE_INPUT_START_DATE: 'Start date',
  ROLE_DATE_INPUT_END_DATE: 'End date',
  ROLE_DATE_INPUT_CORPORATE_INVESTMENT_DATE: 'Investment date',
  ROLE_DATE_INPUT_CORPORATE_DIVESTMENT_DATE: 'Divestment date',

  // Validations
  VALIDATION_FIELD_REQUIRED: "This field is required",
  VALIDATION_ENTER_EMAIL: "Please enter a valid email address",
  VALIDATION_ENTER_URL: "Please enter a valid LinkedIn URL",
  VALIDATION_ENTER_DATE: "Please enter a valid date",
  VALIDATION_ENTER_PASSPORT: "Please enter a valid passport number",

  //formation data
  SPECIAL_MEANING:'Company name special meaning',
  ARABIC_TRANSLATION:'Arabic translation',
  AFFILIATED:'Affiliated with',

  // Selects
  SELECT_COUNTRY: "Select country",
  SELECT_FROM_LIST: "Select from a list",
  SELECT_CURRENCY: "Select currency",
  SELECT_JURISDICTION: "Select jurisdiction",
  SELECT_ALL_THAT_APPLY: "Select all that apply",

  //Forms
  FORM_TITLE: "Title",
  FORM_LEGAL_NAME: "Full legal name",
  FORM_FORMER_NAMES: "Former names (if applicable)",
  FORM_NATIONALITY: 'Nationality',
  FORM_PROFESSION:'Profession',
  FORM_PASSPORT_NUMBER: 'Passport number',
  FORM_EMAIL: 'Email',
  FORM_TELEPHONE_NUMBER: 'Telephone number',
  FORM_LINKEDIN_URL: 'LinkedIn URL',
  FORM_CURRICULUM_VITAE: 'Curriculum vitae',
  FORM_COMPANY_INCORPORATION_DATE_LABEL: "Incorporation date",
  FORM_COMPANY_DISSOLUTION_DATE_LABEL: "Dissolution date",
  FORM_DATE_OF_BIRTH: "Date of birth",
  FORM_COUNTRY_OF_BIRTH: "Country of birth",
  FORM_CITY_OF_BIRTH: "City of birth",
  FORM_OTHER_NATIONALITIES: "Other nationalities",
  FORM_ARABIC_NAME: "Arabic name",
  FORM_GROUNDS_OF_BENEFICIAL_OWNER_APPOINTMENT: "Grounds of beneficial owner appointment"
};

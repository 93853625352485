import useQuery from '../../../../../hooks/custom/useQuery';
import _ from 'lodash';
import { useMemo } from 'react';
import {RoleFields} from "../../../../../modules/stakeholder/graphql/typePolicies";

export const GET_STAKEHOLDER_ROLES = `
${RoleFields}
query getStakeholder($startupId: ID!,$stakeholderId: ID!) {
    getStakeholder(
      startupId: $startupId, 
      stakeholderId: $stakeholderId
    ) {
      id
      roles {
        ...RoleFields
        name
      }  
    }
  }
`;

const useGetStakeholderRoles = (variables) => {
	const { loading, error, data, refetch } = useQuery(GET_STAKEHOLDER_ROLES, variables);
  const roles = useMemo(() => {
    const rolesData = _.get(data, 'getStakeholder.roles', []);
    const roleByGroupCompany = _.groupBy(rolesData, role => role.groupCompany.id);
    const result = [];
    _.forEach(roleByGroupCompany, (group, key) => {
        result.push({
          groupCompanyId: key,
          roles: _.map(group, g => g.name)
        })
    });
    return result;
  }, [JSON.stringify(data)]);
	return { loading, error, data: roles, refetch };
};

export default useGetStakeholderRoles;

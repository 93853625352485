import typeFormats from "./typeFormats"

export default {
  [typeFormats.SHARE]: {
    thousandSeparator: ",",
    decimalSeparator: ".",
    decimalScale: 5,
    fixedDecimal: true,
  },
  [typeFormats.MONEY]: {
    thousandSeparator: ",",
    decimalSeparator: ".",
    decimalScale: 2,
    fixedDecimalScale: true,
  },
  [typeFormats.PERCENT]: {
    decimalSeparator: ".",
    decimalScale: 2,
    fixedDecimal: true,

  },
  [typeFormats.NUMBER]: {
    thousandSeparator: ",",
    decimalSeparator: ".",
    decimalScale: 2
  },
  [typeFormats.PERCENTAGE]:{
    decimalSeparator: ".",
    decimalScale: 2,
    fixedDecimalScale: true,
    integerAmount:4
  },
  [typeFormats.DATE]: "dd/MM/yyyy",
  [typeFormats.DATE_ASSISTANCE]: "DD/MM/yyyy",
  [typeFormats.DATE_SHOW]: "DD MMM YYYY",
  [typeFormats.DATE_TIME]: "dd/MM/yyyy HH:mm",
  [typeFormats.TIME]: "HH:mm",
  [typeFormats.DATE_DAY_MONTH]: "DD MMMM",
  LAST_BY_COMA: ""

};

import { Box, Stack, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useHistory, useParams } from "react-router";
import _ from 'lodash';
import ClaraLayout from "src/layouts/Clara/ClaraLayout";
import MicroFrontend from "src/mf";
import { Constants } from 'src/v1/utils/constants';
import useNavigation from "./useNavigationCaptable";
//Share class pages
import AddShareClass from './ShareClass/AddShareClass';
import DetailsShareClassView from "./ShareClass/DetailsShareClass";
import EditShareClass from './ShareClass/EditShareClass';
import ShareClassList from "./ShareClass/ShareClassList";
//share class transaction
import AddAmendShareClass from "./ShareClass/Amend/AddAmendShareClass";
import EditAmendShareClass from "./ShareClass/Amend/EditAmendShareClass";
import ViewAmendShareClass from './ShareClass/Amend/ViewAmendShareClass'
//Shares
import AddShares from './Shares/AddShare';
import DetailsShares from './Shares/DetailsShares';
import EditShares from './Shares/EditShares';
import SharesList from './Shares/SharesList';
import AddTransferShare from './Shares/AddTransferShare';
import EditTransferShares from "./Shares/EditTransferShare";
import DetailsSharesTransfer from './Shares/DetailsSharesTransfer';
import AddCancelShares from './Shares/AddCancelShares';
import EditCancelShares from "./Shares/EditCancelShares";
import DetailsSharesCancel from './Shares/DetailsSharesCancel';
import UpdateVestingScheduleShares from "./Shares/UpdateVestingScheduleShares";
//Convertibles
import AddConvertibles from './Convertibles/AddConvertibles';
import ConvertiblesList from './Convertibles/ConvertiblesList';
import DetailsConvertibles from './Convertibles/DetailsConvertibles';
import EditConvertibles from './Convertibles/EditConvertibles';
import AddConvertConvertible from "./Convertibles/AddConvertConvertible";
import AddCancelConvertible from "./Convertibles/AddCancelConvertible";
import ViewConvertConvertibleMF from './Convertibles/ViewConvertConvertibleMF';
import ViewConvertiblesCancel from './Convertibles/ViewConvertiblesCancel';
import EditConvertConvertible from './Convertibles/EditConvertConvertible';
import EditCancelConvertible from './Convertibles/EditCancelConvertible';

//Warrants
import AddWarrant from './Warrants/AddWarrant';
import DetailsWarrants from './Warrants/DetailsWarrants';
import EditWarrant from './Warrants/EditWarrants';
import WarrantsList from './Warrants/WarrantsList';
import AddCancelWarrant from "./Warrants/AddCancelWarrant";
import CancelWarrantView from "./Warrants/CancelWarrantView";
import EditCancelWarrant from "./Warrants/EditCancelWarrant";
import AddExerciseWarrant from "./Warrants/AddExerciseWarrant";
import ExerciseWarrantView from "./Warrants/ExerciseWarrantView";
import EditExerciseWarrant from "./Warrants/EditExerciseWarrant";
//Options
import CaptableReport from "./CaptableReport";
import AddOption from './Options/AddOption';
import DetailsOption from './Options/DetailsOption';
import EditOption from './Options/EditOption';
import OptionsList from './Options/OptionsList';
import AddStopVesting from "./Options/AddStopVesting";
import AddLapse from "./Options/AddLapse";
import AddConvert from "./Options/AddConvert";
import AddExerciseManual from "./Options/AddExerciseManual";
import AddExerciseGenerate from "./Options/AddExerciseGenerate";
import EditStopVesting from "./Options/EditStopVesting";
import EditLapse from "./Options/EditLapse";
import EditConvert from "./Options/EditConvert";
import EditExerciseManual from "./Options/EditExerciseManual";
import EditExerciseGenerate from "./Options/EditExerciseGenerate";
import ViewStopVesting from "./Options/DetailsStopVesting";
import ViewLapse from "./Options/DetailsLapse";
import ViewConvert from "./Options/DetailsConvert";
import ViewExerciseManual from "./Options/DetailsExerciseManual";
import ViewExerciseGenerate from "./Options/DetailsExerciseGenerate";
import AddAmendOptionPlan from './Options/AddAmendOptionPlan';
import EditAmendOptionPlan from "./Options/EditAmendOptionPlan";
import DetailsAmendOptionPlan from './Options/DetailsAmendOptionPlan';
//Modelling
import Modelling from "./Modelling";
//Settings
import useCapTable from "../contexts/CaptableContext/useCaptable";

import MenuCaptable from "./MenuCaptable";
import AddOptionGrant from "./Options/AddOptionGrant";
import DetailsOptionGrant from "./Options/DetailsOptionGrant";
import EditOptionGrant from "./Options/EditOptionGrant";
import Settings from "./Settings";
import AddValuation from "./Settings/Valuation/AddValuation";
import EditValuation from "./Settings/Valuation/EditValuation";
import ViewValuation from "./Settings/Valuation/ViewValuation";
import AddVesting from "./Settings/Vesting/AddVesting";
import EditVesting from "./Settings/Vesting/EditVesting";
import ViewVesting from "./Settings/Vesting/ViewVesting";
import useSession from "../../../../../modules/session/hooks/useSession";

let currentGroupCompany = null;

const CapTableRouter = ({ routers = {} }) => {
  const goTo = useNavigation();
  const page = useSelector(state => state.page);
  const history = useHistory();
  const { triggerUpdate } = useCapTable();
  const { groupCompanyId } = useParams();
  const { startup } = useSession();

  const handleChangeGroupCompany = (v) => {
    goTo("captable", { groupCompanyId: v });
    currentGroupCompany = v;
  }

  const go = (v, params = {}) => {
    console.log('this v, params', { v, params })
    if (currentGroupCompany) {
      goTo(v, { groupCompanyId: currentGroupCompany, ...params })
    } else {
      goTo(v, { groupCompanyId: groupCompanyId, ...params })
    }
  }

  useEffect(() => {
    if (page?.historyPage && page?.historyPage[page.historyPage.length - 1]?.url) {
      history.push(page.historyPage[page.historyPage.length - 1].url);
    }
  }, [history, page]);

  const groupCompanyExists = _.find(startup?.groupCompanies, { id: groupCompanyId });
  const groupCompanyExistsIsIncorporated = groupCompanyExists?.kind === 'INCORPORATED';

  if (!groupCompanyId || groupCompanyExists === 'undefined' || !groupCompanyExistsIsIncorporated) {
    return (
      <ClaraLayout startupMenu userMenu fullMenu showAssistance={false} showBreadCrumbs={false} linkMenu="LinkCapTable">
        <Stack direction={"row"} sx={{ width: "267px", height: "100%", justifyContent: "center", alignItems: "center" }}>
          <MicroFrontend id="MF_ShareClass_Menu_View" component={"./EmptyScreenView"} mf="capTable" params={{ triggerUpdate }} />
        </Stack>
      </ClaraLayout>
    )
  }

  return (
    <ClaraLayout startupMenu userMenu fullMenu showAssistance={null} showBreadCrumbs={false} linkMenu="LinkCapTable" assistance={null} assistanceProps={{ groupCompanyId }} >
      <Grid container spacing={0} sx={{ width: "100%", height: "100%", flexWrap: 'nowrap' }}>
        <Grid item sx={{ width: 267 }}>
          <MenuCaptable onChangeGroupCompany={handleChangeGroupCompany} groupCompanyId={groupCompanyId} go={go} />
        </Grid>
        <Grid item xs sx={{ height: "100%", maxWidth: "calc(100% - 265px)!important" }}>
          <Box sx={{ padding: "20px", height: "100%", width: "100%", display: "flex", background: "#fbfcff" }} >
            <Box sx={{ height: "auto", minHeight: "200px", width: "100%", display: "flex", justifyContent: "center" }} >
              <Box sx={{ height: "auto", width: "100%", maxWidth: "1600px", }} >
                <Switch>
                  <Route path={Constants.PAGES["capTable.mf.modelling"].path} >
                    {groupCompanyId && <Modelling go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                  </Route>
                  <Route path={Constants.PAGES["capTable.mf.settings"].path} >
                    {groupCompanyId && <Settings go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                  </Route>
                  <Route path={Constants.PAGES["capTable.mf.valuation.add"].path} >
                    {groupCompanyId && <AddValuation go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                  </Route>
                  <Route path={Constants.PAGES["capTable.mf.vesting.add"].path} >
                    {groupCompanyId && <AddVesting go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                  </Route>
                  <Route path={Constants.PAGES["capTable.mf.valuation.edit"].path} >
                    {groupCompanyId && <EditValuation go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                  </Route>
                  <Route path={Constants.PAGES["capTable.mf.vesting.edit"].path} >
                    {groupCompanyId && <EditVesting go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                  </Route>
                  <Route path={Constants.PAGES["capTable.mf.valuation.view"].path} >
                    {groupCompanyId && <ViewValuation go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                  </Route>
                  <Route path={Constants.PAGES["capTable.mf.vesting.view"].path} >
                    {groupCompanyId && <ViewVesting go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                  </Route>
                  <Route path={Constants.PAGES["capTable.mf.captable"].path} >
                    {groupCompanyId && <CaptableReport go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                  </Route>
                  <Route path={Constants.PAGES["capTable.mf.warrants"].path} >
                    <Switch>

                      <Route path={Constants.PAGES["capTable.mf.warrants.cancel.add"].path}>
                        {groupCompanyId && <AddCancelWarrant go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>

                      <Route path={Constants.PAGES["capTable.mf.warrants.cancel.edit"].path}>
                        {groupCompanyId && <EditCancelWarrant go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>

                      <Route path={Constants.PAGES["capTable.mf.warrants.cancel.view"].path}>
                        {groupCompanyId && <CancelWarrantView go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>

                      <Route path={Constants.PAGES["capTable.mf.warrants.exercise.add"].path}>
                        {groupCompanyId && <AddExerciseWarrant go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>

                      <Route path={Constants.PAGES["capTable.mf.warrants.exercise.edit"].path}>
                        {groupCompanyId && <EditExerciseWarrant go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>

                      <Route path={Constants.PAGES["capTable.mf.warrants.exercise.view"].path}>
                        {groupCompanyId && <ExerciseWarrantView go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>

                      <Route path={Constants.PAGES["capTable.mf.warrants.add"].path}>
                        {groupCompanyId && <AddWarrant go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.warrants.view"].path}>
                        {groupCompanyId && <DetailsWarrants go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.warrants.edit"].path}>
                        {groupCompanyId && <EditWarrant go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.warrants.list"].path}>
                        {groupCompanyId && <WarrantsList go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                    </Switch>
                  </Route>
                  <Route path={Constants.PAGES["capTable.mf.convertibles"].path} >
                    <Switch>
                      <Route path={Constants.PAGES["capTable.mf.convertibles.add"].path} >
                        {groupCompanyId && <AddConvertibles go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.convertibles.view"].path} >
                        {groupCompanyId && <DetailsConvertibles go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.convertibles.edit"].path} >
                        {groupCompanyId && <EditConvertibles go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.convertibles.list"].path} >
                        {groupCompanyId && <ConvertiblesList go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>

                      <Route path={Constants.PAGES["capTable.mf.convertibles.convert.add"].path} >
                        {groupCompanyId && <AddConvertConvertible go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.convertibles.convert.edit"].path} >
                        {groupCompanyId && <EditConvertConvertible go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.convertibles.convert.view"].path} >
                        {groupCompanyId && <ViewConvertConvertibleMF go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.convertibles.cancel.add"].path} >
                        {groupCompanyId && <AddCancelConvertible go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.convertibles.cancel.edit"].path} >
                        {groupCompanyId && <EditCancelConvertible go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.convertibles.cancel.view"].path} >
                        {groupCompanyId && <ViewConvertiblesCancel go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>

                    </Switch>
                  </Route>
                  <Route path={Constants.PAGES["capTable.mf.options"].path} >
                    <Switch>

                      <Route path={Constants.PAGES["capTable.mf.options.add"].path} >
                        {groupCompanyId && <AddOption go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.options.view"].path} >
                        {groupCompanyId && <DetailsOption go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.options.edit"].path} >
                        {groupCompanyId && <EditOption go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.options.list"].path} >
                        {groupCompanyId && <OptionsList go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.options.amend.add"].path} >
                        {groupCompanyId && <AddAmendOptionPlan go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.options.amend.edit"].path} >
                        {groupCompanyId && <EditAmendOptionPlan go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.options.amend.view"].path} >
                        {groupCompanyId && <DetailsAmendOptionPlan go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.options.grant.add"].path} >
                        {groupCompanyId && <AddOptionGrant go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.options.grant.view"].path} >
                        {groupCompanyId && <DetailsOptionGrant go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.options.grant.edit"].path} >
                        {groupCompanyId && <EditOptionGrant go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.options.grant.addStopVesting"].path} >
                        {groupCompanyId && <AddStopVesting go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.options.grant.addLapse"].path} >
                        {groupCompanyId && <AddLapse go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.options.grant.addConvert"].path} >
                        {groupCompanyId && <AddConvert go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.options.grant.addExerciseManual"].path} >
                        {groupCompanyId && <AddExerciseManual go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.options.grant.addExerciseGenerate"].path} >
                        {groupCompanyId && <AddExerciseGenerate go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.options.grant.editStopVesting"].path} >
                        {groupCompanyId && <EditStopVesting go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.options.grant.editLapse"].path} >
                        {groupCompanyId && <EditLapse go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.options.grant.editConvert"].path} >
                        {groupCompanyId && <EditConvert go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.options.grant.editExerciseManual"].path} >
                        {groupCompanyId && <EditExerciseManual go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.options.grant.editExerciseGenerate"].path} >
                        {groupCompanyId && <EditExerciseGenerate go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.options.grant.viewStopVesting"].path} >
                        {groupCompanyId && <ViewStopVesting go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.options.grant.viewLapse"].path} >
                        {groupCompanyId && <ViewLapse go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.options.grant.viewConvert"].path} >
                        {groupCompanyId && <ViewConvert go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.options.grant.viewExerciseManual"].path} >
                        {groupCompanyId && <ViewExerciseManual go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.options.grant.viewExerciseGenerate"].path} >
                        {groupCompanyId && <ViewExerciseGenerate go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                    </Switch>
                  </Route>
                  <Route path={Constants.PAGES["capTable.mf.shares"].path} >
                    <Switch>
                      <Route path={Constants.PAGES["capTable.mf.shares.add"].path} >
                        {groupCompanyId && <AddShares go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.shares.view"].path} >
                        {groupCompanyId && <DetailsShares go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.shares.edit"].path} >
                        {groupCompanyId && <EditShares go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.shares.list"].path} >
                        {groupCompanyId && <SharesList go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.shares.transfer.add"].path} >
                        {groupCompanyId && <AddTransferShare go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.shares.transfer.edit"].path} >
                        {groupCompanyId && <EditTransferShares go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.shares.transfer.view"].path} >
                        {groupCompanyId && <DetailsSharesTransfer go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.shares.cancel.add"].path} >
                        {groupCompanyId && <AddCancelShares go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.shares.cancel.edit"].path} >
                        {groupCompanyId && <EditCancelShares go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.shares.cancel.view"].path} >
                        {groupCompanyId && <DetailsSharesCancel go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.shares.vestingSchedule.update"].path} >
                        {groupCompanyId && <UpdateVestingScheduleShares go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                      </Route>
                    </Switch>
                  </Route>
                  <Route path={Constants.PAGES["capTable.mf.shareClass"].path} >
                    <Switch>
                      <Route path={Constants.PAGES["capTable.mf.shareClass.amend.add"].path} >
                        <>
                          {groupCompanyId && <AddAmendShareClass go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                        </>
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.shareClass.amend.edit"].path} >
                        <>
                          {groupCompanyId && <EditAmendShareClass go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                        </>
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.shareClass.amend.view"].path} >
                        <>
                          {groupCompanyId && <ViewAmendShareClass go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                        </>
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.shareClass.add"].path} >
                        <>
                          {groupCompanyId && <AddShareClass go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                        </>
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.shareClass.view"].path} >
                        <>
                          {groupCompanyId && <DetailsShareClassView go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                        </>
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.shareClass.edit"].path} >
                        <>
                          {groupCompanyId && <EditShareClass go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                        </>
                      </Route>
                      <Route path={Constants.PAGES["capTable.mf.shareClass.list"].path} >
                        <>
                          {groupCompanyId && <ShareClassList go={go} groupCompanyId={groupCompanyId} triggerUpdate={triggerUpdate} />}
                        </>
                      </Route>
                    </Switch>
                  </Route>
                </Switch>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ClaraLayout>
  )
};

export default CapTableRouter;

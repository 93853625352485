import { createMachine } from "xstate";

const machine = createMachine({
  id: "ReviewAddresses",
  initial: "initialData",
  states: {
    initialData: {
      invoke: {
        src: "initialData",
        id: "initialData",
        onDone: [
          {
            target: "pincasData",
            actions: ["setContextAfterInvoke"],
          },
        ],
      },
    },
    pincasData: {
      invoke: {
        src: "pincasData",
        id: "pincasData",
        onDone: [
          {
            target: "EvaluateCond",
            actions: ["setContextAfterInvoke"],
          },
        ],
      },
    },
    EvaluateCond: {
      always: [
        {
          cond: "isTechLicence",
          target: "ReviewAddressesTechLicenseStepForm",
        },
        {
          cond: "isSPV",
          target: "ReviewAddressesHoldingCompanyStepForm",
        },
      ],
    },
    ReviewAddressesTechLicenseStepForm: {
      on: {
        NEXT: {
          target: "ReviewAddressesTechLicenseAddressesStepForm",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    ReviewAddressesHoldingCompanyStepForm: {
      on: {
        NEXT: {
          target: "SaveStep",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    ReviewAddressesTechLicenseAddressesStepForm: {
      on: {
        NEXT: {
          target: "SaveStep",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    SaveStep: {
      invoke: {
        src: "SaveStep",
        id: "SaveStep",
        onDone: [
          {
            target: "finish",
          },
        ],
        onError: [
          {
            target: "cancel",
          },
        ],
      },
    },
    cancel: {
      entry: "cancelMachine",
      type: "final",
    },
    finish: {
      entry: "stopMachine",
      type: "final",
    },
  },
});

export default machine;

import useMutation from "src/hooks/custom/useMutation";
import useSession from "src/modules/session/hooks/useSession";

export const UPDATE_STAKEHOLDER = `
mutation updateStakeholderGeneralDetails(
    $startupId: ID!
    $stakeholderId: ID!
    $stakeholderData: StakeholderInput!
) {
    updateStakeholder(
        startupId: $startupId
        stakeholderId: $stakeholderId
        stakeholderData: $stakeholderData
    ) {
        id
        fullName
    }
  }
`
const useUpdateStakeholder = (config, props) => {
    const [ updateStakeholder ] = useMutation(UPDATE_STAKEHOLDER, config,{ ...props });
    const {startupId} = useSession()
    const save  = async (values,stakeholderId) => {
        const data = await updateStakeholder({
            startupId,
            stakeholderId,
            stakeholderData:{
                stakeholder:{
                ...values
        }
    }
    })
        return data
    }
    return  save;
};
export default useUpdateStakeholder;
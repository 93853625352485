import React, { FC } from "react";
import DetailsSkeleton from "../../../../../components/templates/Details/DetailsSkeleton";

/**
 * Template Corporate Stakeholder View Skeleton
 */

export interface CorporateStakeholderViewSkeletonProps {
  isLoading?: boolean,
}

const CorporateStakeholderViewSkeleton: FC<CorporateStakeholderViewSkeletonProps> = ({
  isLoading = false,
  ...props
}: CorporateStakeholderViewSkeletonProps) => {

  return (
    <>
      {isLoading && (
        <DetailsSkeleton />
      )}
    </>
  );
};

export default CorporateStakeholderViewSkeleton;

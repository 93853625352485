export default {
    LABEL_NAME: 'Full legal name',
    LABEL_COMPANY: 'Company',
    WIZARD_ADD_MORE_STEP_TITLE: "Do you have employees in your startup?",
    WIZARD_ADD_MORE_STEP_SUBTITLE: "Your employees",
    WIZARD_ADD_MORE_RESET_STEP_TITLE: "Do you have more employees to add?",
    WIZARD_ADD_MORE_RESET_STEP_SUBTITLE: "Employee added!",
    FIRST_STEP_IS_NOT_EXIST_TOOLTIPS: 'As your company grows, add employees here at any time.',
    FIRST_STEP_TOOLTIPS: 'An <b>Employee</b> is a person hired to provide services to an employer on a permanent, temporary or fixed-term basis. ',
    SECOND_STEP_TOOLTIPS: 'Later, I\'ll ask you to add an Employment Agreement. These help you protect yourself and the people who work for you. ',
    THIRD_STEP_NOT_ANOTHER_TOOLTIPS: 'Add more employees to your map at any time. '
}
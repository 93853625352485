import useQuery from "src/hooks/custom/useQuery";
import useSession from "src/modules/session/hooks/useSession";
export const GET_BANK_ACCOUNTS =`
    query getBankAccounts($startupId: ID!,$groupCompanyId: ID!) {
        getBankAccounts(startupId: $startupId,groupCompanyId:$groupCompanyId) {
            id
            code
            number
            additionalInfo
            ownerFullName 
            bankName
            country{
                code
                name
            }
        }
    }
`;
const useGetBankAccounts = (groupCompanyId) => {
    const { startupId } = useSession();
    const { data, loading, error } = useQuery(GET_BANK_ACCOUNTS, { startupId,groupCompanyId });
    return { data:data?.getBankAccounts, loading, error };
}
export default useGetBankAccounts;
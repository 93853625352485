import React from 'react';
import classes from './InformationFlow.module.scss';

import Text from "../../../text/Text/Text";
import Loading from "../../../generic/Loading/Loading";
import PrimaryButton from "../../../generic/Buttons/PrimaryButton/PrimaryButton";
import SecondaryButton from "../../../generic/Buttons/SecondaryButton/SecondaryButton";

const IntroSubmitFlows = ({ componentLeft: ComponentLeft, formTitle, firstTitle, firstText, secondText, isIntro, image, secondButtonText, buttonText, handleClose, handleNext }) => {
    return (
        <React.Fragment>
            <Loading showChildren={false} show={false}>
                <div className={classes.IntroSubmitFlows}>
                    <div className={classes.scopingIntroContainer}>
                        <div>
                        </div>
                        <div className={classes.containerLeft}>
							{ ComponentLeft
								? <ComponentLeft/>
								: <>
									<div className={classes.pretitleContainer}>
										<Text uuid={formTitle} />
									</div>
									<div className={classes.titleContainer}>
										<Text uuid={firstTitle} />
									</div>

									<div className={classes.subTitleContainer}>
										<Text uuid={firstText} />
									</div>
									<div className={classes.subTitleContainer}>
										<Text uuid={secondText} />
									</div>
								</>
							}
                            <div className={classes.botonera}>
                                {isIntro ? null : <SecondaryButton className={`${classes.buttonContainer} ${classes.buttonCloseContainer}`} onClick={handleClose}>
                                    <Text uuid={secondButtonText} />
                                </SecondaryButton>}
                                <PrimaryButton className={`${classes.buttonContainer}`} onClick={handleNext}>
                                    <Text uuid={buttonText} />
                                </PrimaryButton>
                            </div>

                        </div>
                        <div className={classes.containerRight}>
                            <img src={image} className={isIntro ? classes.introImg : null} />
                            {/* <div className={classes.ellipse}></div> */}

                        </div>

                    </div>

                </div>
            </Loading>
        </React.Fragment >
    )
}

export default IntroSubmitFlows;

import { useMemo } from 'react';
import _ from "lodash";
import useUpsertDocument from "src/hooks/services/modules/Document/useUpsertDocument";
import useUpsertFormationLeaseDetails from "../../../../GroupCompany/graphql/mutations/useUpsertFormationLeaseDetails";
import useSession from 'src/modules/session/hooks/useSession';
import moment from "moment";

const useServices = ({ initialValues }) => {
  const { startupId } = useSession()
  const [upsertDocument] = useUpsertDocument({});
  const { mutation: upsertLeaseDetails, loading: loadingLeaseDetails } = useUpsertFormationLeaseDetails();

  return useMemo(() => ({
    initialData: async () => {
      return initialValues;
    },
    SaveStep: async (context) => {
      const groupCompanyId = initialValues?.idGroupCompany;
      const leaseValues = _.get(context, "LeaseDetailsStepForm", {});
      const { deskOrOfficeNum, floorNum, buildingName, leaseStart, leaseEnd, document, streetName } = leaseValues;
      const variables = {
        agreement: {
          title: document.name,
          type: 'ADGM_TSL_LEASE_AGREEMENT',
          subCategories: ['constitutionalDocuments'],
          groupCompanies: [{ groupCompany: groupCompanyId }],
          parties: [],
          executedOnDate: moment(leaseStart).format("YYYY-MM-DD"),
          expirationDate: moment(leaseEnd).format("YYYY-MM-DD"),
          file: document,
          status: 'Uploaded'
        },
        startupId
      };
      const documentResult = await upsertDocument(variables);
      const leaseDetailsData = {
        deskOrOfficeNum,
        floorNum,
        buildingName,
        leaseStart: moment(leaseStart).format("YYYY-MM-DD"),
        leaseEnd: moment(leaseEnd).format("YYYY-MM-DD"),
        address: {
          city: 'Al Maryah Island',
          state: 'Abu Dhabi',
          country: 'AE',
          street: streetName,
        },
        document: _.get(documentResult, "data.upsertDocument.id", ''),
        started: true
      };
      return await upsertLeaseDetails({ variables: { startupId, groupCompanyId, leaseDetailsData } });
    }
  }), [initialValues, startupId]);
}

export default useServices;

import React, {useEffect} from 'react';
import useMutation from "../../../custom/useMutation"

const mutation = `mutation updatePassword($passwordData:ChangePasswordInput) {
    updatePassword(passwordData: $passwordData) 
  }`;
const useUpdatePassword = (options) =>{
    
    const [send,data] = useMutation(mutation,options);
    const sendForm=(values)=>{
      return send({passwordData:values});
    }
    useEffect(() => {
        
    }, []);

    return  [sendForm,data] ;
}
export default useUpdatePassword;
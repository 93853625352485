import {Constants} from '../../../../utils/constants';

const variablesGeneratedByType = (values) => {


    const defVariables = {
        incorporationJurisdiction: values.incorporationJurisdiction,
        companyType: values.companyType,
        shareNumbers: parseInt(values.shareNumbers),
        boardMembersMajorityResident: values.boardMembersMajorityResident,
        bankAccountRequire: values.bankAccountRequire,
        engagementType: values.engagementType,
        whereTheMajorityBoardMembersReside: values.whereTheMajorityBoardMembersReside,
    }

    const holdingVariables = {
        alreadyIncorporatedText: values.alreadyIncorporatedText,
        toBeIncorporatedText: values.toBeIncorporatedText,
        isRevenueGenerating: values.isRevenueGenerating,
        isProtection: values.isProtection,
        onlyRevenueDividendsOrCapital: values.onlyRevenueDividendsOrCapital,
        anotherIncomeForm: values.anotherIncomeForm,
        intellectualPropertyText: values.intellectualPropertyText,
        otherAssetText: values.otherAssetText
    }

    const finTechVariables = {
        foundationReason: values.foundationReason,
        foundationAssetsAndWhereAreLocated: values.foundationAssetsAndWhereAreLocated,
        whereFoundersAreLiving: values.whereFoundersAreLiving
    }

    const techStartupVariables = {
        relevantTechnologyDescription: values.relevantTechnologyDescription
    }

    const nonFinancialVariables = {
        companyActivitiesDescription: values.companyActivitiesDescription,
    }

    const otherVariables = {
        detailsOtherIncorporation: values.detailsOtherIncorporation
    }

    switch (values.companyType) {
        case Constants.COMPANY_TYPES.HOLDING_COMPANY:
            return { ...defVariables, ...holdingVariables }
        case Constants.COMPANY_TYPES.FOUNDATION:
            return { ...defVariables, ...finTechVariables }
        case Constants.COMPANY_TYPES.TECH_STARTUP:
            return { ...defVariables, ...techStartupVariables }
        case Constants.COMPANY_TYPES.OPERATING_COMPANY:
            return { ...defVariables, ...nonFinancialVariables }
        case Constants.COMPANY_TYPES.OTHER:
            return { ...defVariables, ...otherVariables }
        default:
            return null
    }
}

export default variablesGeneratedByType;
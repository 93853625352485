import * as Yup from 'yup';
import { useCallback, useMemo } from 'react';
import Form from "src/components/forms/Form/FormL";
import { Stack } from "@mui/material";
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import Typography from "src/modules/generic/components/Texts/Typography";
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from "src/modules/generic/hooks/useTranslate";
import Assets from 'src/modules/Onboarding/views/Wizard/assets';
import TemplateOnboarding2Wizard from 'src/modules/generic/templates/Modal/TemplateOnboarding2Wizard';

/**
 * Step whats happens next accelerator step
 * @param {*} param0
 * @returns
 */
const schema = Yup.object().shape({});

function WhatHappensNextAcceleratorStep({ submitButonProps, actualStep, totalSteps, isLoading = false }) {
  const { next, prev, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const acceleratorName = context?.verifyCode?.name;

  const handleNext = useCallback(
    (values) => {
      next(values);
    },
    [next]
  );

  const handleCancel = () => {
    cancel();
  }
  
  const leftComponent = useMemo(() => {
    return (
      <Stack spacing={4} pt={11} sx={{width: "100%", height: "100%"}}>
        <Typography color={"black"} variant={"onboarding-titles-bold"}  >
          <span dangerouslySetInnerHTML={{ __html: translate("MODULES.ONBOARDINGCOMPANY.ONBOARDING_WHAT_HAPPENDS_NEXT_STEP_TITLE") }}>
          </span>
        </Typography>
        <Typography variant="onboarding-great" color={"black"} component={"p"}>
          <span dangerouslySetInnerHTML={{ __html: translate("MODULES.ONBOARDINGCOMPANY.ONBOARDING_WHAT_HAPPENDS_NEXT_STEP_TEXT_GOAL_ACCELERATOR_NEW_USER", { accelerator: acceleratorName }) }}></span>
        </Typography>
        <Typography variant="onboarding-great" color={"black"} component={"p"}>
          <span dangerouslySetInnerHTML={{ __html: translate("MODULES.ONBOARDINGCOMPANY.ONBOARDING_WHAT_HAPPENDS_NEXT_STEP_TEXT_GOAL_ACCELERATOR_NEW_USER2") }}></span>
        </Typography>
      </Stack>
    )
  }, [translate]);

  const rightComponent = useMemo(() => {
    return  (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={0}
        sx={{ width: "100%", height: "100%" }}
      >
        <img src={Assets.STEP_3} alt="Clara" style={{ width: "285px", height: "auto", marginTop: "60px" }} />
      </Stack>
    )
  }, []);

  return (
    <Form
      template={TemplateOnboarding2Wizard}
      schema={schema}
      onSubmit={handleNext}
    >
      <TemplateOnboarding2Wizard
        buttons={{
          submit: <SubmitButton {...submitButonProps} isSubmitting={isLoading} >{translate("MODULES.ONBOARDINGCOMPANY.ONBOARDING_LAST_BUTTON")}</SubmitButton>
        }}
        cancel={handleCancel}
        previousClick={prev}
        leftComponent={leftComponent}
        rightComponent={rightComponent}
        actualStep= {actualStep}
        totalSteps={totalSteps}
      >
      </TemplateOnboarding2Wizard>
    </Form>
  );
}
export default WhatHappensNextAcceleratorStep;
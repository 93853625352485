import { default as React,  useMemo, useEffect } from 'react';
import { useDispatch } from "react-redux";

import Form from "src/components/forms/Form/Form";
import useMachine from '../../../../generic/context/MachineContext/useMachine';
import useModal from "../../../../generic/hooks/useModal";
import useTranslate from '../../../../generic/hooks/useTranslate';
import { addAssistantText, clearAssistatText } from "src/modules/generic/store/action";
import * as Yup from 'yup';
import TemplateWizard from "../../../../generic/templates/Modal/TemplateWizard";
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import Typography from "src/modules//generic/components/Texts/Typography";

import Button from 'src/modules/generic/components/Buttons/Button';
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import { CheckboxButtonOptions } from 'src/modules/generic/components/Inputs/CheckboxButton';
import _ from 'lodash';

/**
 * Select the founder step
 * @param {*} param0
 * @returns
 */
function SelectFounderStep({ openParam }) {

  const { next, context, prev } = useMachine();
  const { translate } = useTranslate()
  const dispatch = useDispatch();
  const { closeModal: onClose } = useModal();
  useEffect(()=>{
    dispatch(clearAssistatText());
    dispatch(addAssistantText(translate('MODULES.STARTUP.MODAL_ADD_FOUNDER_AGREEMENT_MODAL_TOOLTIP')));
    return ()=>{
      dispatch(clearAssistatText());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ ])
  const handleCancel = (values) => {
    dispatch(clearAssistatText())
    onClose();
  }

  const handleCompleteSubmit = async (values) => {
    next(values)
  }

  const handlePrev = () => {
    prev()
  }
  const schema = Yup.object().shape({
    founders: Yup.array().required("Required").min(1, "Required"),
  })
  const {initialValues} = useMemo(()=>{
    return context?.HaveDocumentStep??{}
  },[ context?.HaveDocumentStep])
   const options: Array<CheckboxButtonOptions<string, any>> = useMemo(() => {
    const founders = context?.getFoundersData;
    
    return  _.map(founders,(founder)=>{
      return {
            value: founder?.id,
            label: founder?.fullName
        }
    })
}, [context])
  return (
    <Form
        defaultValues={initialValues || {}}
        schema={schema}
        onSubmit={handleCompleteSubmit}
        optionsForm={{ mode: "onChange" }}
      >
        <TemplateWizard 
          props={{
                title: translate(`MODULES.STARTUP.MODAL_ADD_FOUNDER_AGREEMENT_STEP_SELECT_FOUNDER_TITLE`),
                subTitle: translate(`MODULES.STARTUP.MODAL_ADD_FOUNDER_AGREEMENT_MODAL_SUB_TITLE`),
                skeleton: null
          }} 
          buttons={{
            previous: <Button onClick={handlePrev} variant="secondary">{translate('BUTTON_PREVIOUS')}</Button>,
            cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
            submit: <SubmitButton  >{translate('BUTTON_NEXT')}</SubmitButton>,
          }}
          >
          <ControllerInput
            render={CheckboxButtonSimple}
            name='founders'
            
            options={options}
            defaultValue={[]}
            onChange={(value) => {}}
            isMultiple={true}
          />
        </TemplateWizard>
      </Form>
  );
}

export default SelectFounderStep;

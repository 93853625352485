import React, {useEffect, useState} from 'react';
import {ServerConnect} from "../utils";
import _ from "lodash";
import Mustache from 'mustache'

const QUERY_GETMATTER = `query{
  getMattersList(matterId:"{{id}}"){
    questionnaire{
      companies{
        id
        companyName
        type
        isDirector
        isShareholder
        otherTradingName
        directors {
            individuals {
              id
                firstName
                lastName
                email
              
            }
            companies {
              id
              companyName
              signatory {
                firstName
                lastName
                email
              }
            }
        }

        shareholders {
          individuals {
            id
              firstName
              lastName
              email
            
          }
          companies {
            id
            companyName
            signatory {
              firstName
              lastName
              email
            }
          }
      }


          signatory {
            firstName
            lastName
            jobTitle
            email
            nationality
            passportNumber
            passportExpiryDate
            signatoryPassportDoc{
              file{
                id
                name
                size
                type
              }
            }
          }
        clientKeyContact{
          fullName
          contactNumber{
            prefix
            number
            extension
          }
          email
          contactType
          }
        financeContact{
          fullName
          contactNumber{
            prefix
            number
            extension
          }
          email
          contactType
          }
          sourceOfFunds
          representativeDetails{
            firstName
            lastName
            jobTitle
            email
            representativePassportDoc {
              file{
                id
                name
                size
                type
              }
            }
          }
          showingShareholdersRegistrationDoc{
            file{
              id
              name
              size
              type
            }
          }
          showingDirectorsRegistrationDoc{
            file{
              id
              name
              size
              type
            }
          }
        registrationNumber
        isPrimary
        TRNNumber
        jurisdictionOfIncorporation
        VATRegistrationCertificateDoc{
          file{
            id
            name
            size
            type
            }
        }
        address{
          city
          state
          country
          zipCode
          address
        }
      samePrincipalPlaceOfBusinessAndLegal
      principalAddress{
        city
        state
        country
        zipCode
        address
      } 
      certificateOfIncorporationOrCommercialLicenseDoc{
        file{
          id
          name
          size
          type
        }
      }
      memorandumAndArticlesOfAssociationDoc{
        file{
          id
          name
          size
          type
        }
      }
      countryOfIncorporation {code name}
      proofOfPowerDoc{
        file{
          id
          name
          size
          type
        }
      }
      }
      individuals{
          id
          type
          firstName
          dateOfBirth
          lastName
          thirdPartyAddressFormDoc{
            ...matterFile
          }
          thirdPartyAddressPassportDoc{
            ...matterFile
          }
          passports {
            current
            nationality
            passportNumber
            passportExpiryDate
            passportDoc {
              ...matterFile
            }
           
            lastPagePassportDoc {
              ...matterFile
            }
            UAEPassportStamp
            UAEPassportStampDoc {
              ...matterFile
            }
            UIDDoc {
              ...matterFile
            }
            PRCIDDoc {
              ...matterFile
            }
          }
        email
        thirdPartyAddressFormDoc{
          file{
            id
            name
            size
            type
          }
        }
        thirdPartyAddressPassportDoc{
          file{
            id
            name
            size
            type
          }
        }
        lastName 
        thirdPartyAddressFormDoc{
          file{
            id
            name
            size
            type
          }
        }
        thirdPartyAddressPassportDoc{
          file{
            id
            name
            size
            type
          }
        }
        occupation
        address{
          address
          city
          country
          zipCode
          state
        }
        proofOfAddress
        proofOfAddressDoc{
          file{
            id
            name
            size
            type
          }
        }
        addressLetterAndSupportingDoc{
          file{
            id
            name
            size
            type
          }
        }
        contactNumber{
          prefix
          number
          extension
        }
        passports{
          current
          nationality
          passportNumber
          passportExpiryDate
          passportDoc{
            file{
              id
              name
              size
            }
          }
          lastPagePassportDoc{
             file{
              id
              name
              size
            }
          }
          UAEPassportStamp
          UAEPassportStampDoc{
             file{
              id
              name
              size
            }
          }
          UIDDoc{
             file{
              id
              name
              size
            }
          }
          PRCIDDoc{
             file{
              id
              name
              size
            }
          }
        }
        otherPassports
        holdOrHaveHeldPassportFromAnotherCountry
        passportFromAnotherCountryDoc{
          file{
            id
            name
            size
            type
          }
        }
        UAEVisaDoc{
          ...matterFile
        }
        politicallyExposedPerson
        politicallyExposedPersonDescription
        sourcesOfWealth
        otherSourcesOfWealth
        sourceOfFunds 
        familyMemberOfAPEP
        closeAssociateOfAPEP
        familyMemberOfAPEPDescription
        closeAssociateOfAPEPDescription
      }
    }
    
  }
}
fragment matterFile on FileType {
  file {
    id
    name
    size
  }
  }
`;
export default function useMatter(id, queryCustom = null, path = null) {
  const [matter, setMatter] = useState(null);
  useEffect(() => {
    if (!matter) {
      const query_params = {
        id: id,
      };
      const query_getMatters = queryCustom ? queryCustom : QUERY_GETMATTER;
      const query = Mustache.render(query_getMatters, query_params);
      ServerConnect.graphQlQuery(query).then(result => {
        let kyc = _.get(result, `${path ? path : "getMattersList.0.questionnaire"}`, {});
        setMatter(kyc)
      });
    }
  }, [matter]);

  return { matter };
}
import useMutation from "../../../custom/useMutation";

const STATE_MATTER=`
        mutation setStatusMatter($matterId: String!, $state: MatterStatesEnum!, $declineReason:String){
            setStatusMatter(matterId: $matterId, state: $state, declineReason:$declineReason){
              state
              id
            }
        }`
const useStatusMatter =(variables) =>{

    const [mutation, data] = useMutation(STATE_MATTER, variables);


    return { data, mutation };
};

export default useStatusMatter;
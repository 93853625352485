import _ from "lodash";
import { useCallback, useMemo } from 'react';
import Form from "src/components/forms/Form/FormL";
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import CheckboxButtonSimple from 'src/modules/generic/components/Inputs/CheckboxButtonSimple';
import ControllerInputClara from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import Typography from "src/modules/generic/components/Texts/Typography";
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from "src/modules/generic/hooks/useTranslate";
import CardOptionOnboarding from 'src/modules/Onboarding/components/CardOptionOnboarding';
import GOALS, { GOAL_INITIAL_OPTION } from 'src/modules/Onboarding/constants/goals';
import * as Yup from 'yup';
import cls from "./classes.module.scss";
import TemplateOnboarding2Wizard from 'src/modules/generic/templates/Modal/TemplateOnboarding2Wizard';
import { Stack } from "@mui/material";

/**
 * Step to decide the goal of the startup
 * @param {*} param0
 * @returns
 */
function GoalStep({ submitButonProps, actualStep, totalSteps }) {

  const { next, prev, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const contextGoal = context?.GoalStep?.goal ?? "";

  const schema = Yup.object().shape({
    goal: Yup.string().nullable().required('Required'),
  });

  const handleNext = useCallback(
    (values) => {
      next({ ...values, initialOption: GOAL_INITIAL_OPTION[values?.goal] });
    },
    [next]
  );

  const handleCancel = () => {
    cancel();
  }

  const options = _.map(GOALS, (goal) => {
    return {
      value: goal,
      component: CardOptionOnboarding,
      componentProps: {
        title: translate("MODULES.ONBOARDINGCOMPANY.ONBOARDING_GOAL_STEP_TITLE_GOAL_" + goal),
        text: translate("MODULES.ONBOARDINGCOMPANY.ONBOARDING_GOAL_STEP_GOAL_" + goal),
      }
    }
  })

  const leftComponent = useMemo(() => {
    return (
      <>
        <Stack spacing={4} pt={11} sx={{width: "100%", height: "100%"}}>
          <Typography color={"black"} variant={"onboarding-titles-bold"}  >
            <span dangerouslySetInnerHTML={{ __html: translate("MODULES.ONBOARDINGCOMPANY.ONBOARDING_DO_WANT_DO_LABEL") }}>
            </span>
          </Typography>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            sx={{
              width: "100%",
              height: "100%",
              paddingBottom: "40px"
            }}
          >
            <ControllerInputClara
              defaultValue={contextGoal}
              render={CheckboxButtonSimple}
              options={options}
              name={"goal"}
              variant="default"
              direction="row"
              size="auto"
              className={cls["checkbox"]}
            />
          </Stack>
        </Stack>
      </>
    )
  }, []);

  return (
    <Form
      onlySendDirty={false}
      defaultValues={{ goal: contextGoal }}
      template={TemplateOnboarding2Wizard}
      schema={schema}
      onSubmit={handleNext}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateOnboarding2Wizard
        buttons={{
          submit: <SubmitButton {...submitButonProps} />
        }}
        cancel={handleCancel}
        previousClick={prev}
        leftComponent={leftComponent}
        actualStep= {actualStep}
        totalSteps={totalSteps}
      >
      </TemplateOnboarding2Wizard>
    </Form>
  );
}
export default GoalStep;
import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TextInput from 'src/components/inputs/Text/TextInput';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateLabel from 'src/modules/generic/components/Inputs/LabelInput';
import * as Yup from 'yup';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText,addAssistantText } from 'src/modules/generic/store/action';
import _ from 'lodash';
import Typography from 'src/modules/generic/components/Texts/Typography';
import classes from '../classes.module.scss';
import CheckboxButtonSimple, {
  CheckboxButtonOptions,
} from 'src/modules/generic/components/Inputs/CheckboxButtonSimple';

/**
* Description Step: To use Clara’s service, you must appoint a second Authorised Signatory.
* 
* @returns 
*/
function WhoIsSecondAuthorisedSignatoryFormStep() {

  const { next, context, prev,cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  
  const {initialValues, stakeholders } = useMemo(() => {

    const initialValues = context?.AddAnotherAuthorizedSignatoryFormStep ?? {}
    
    const stakeholders = _.map(_.get(context, "initialData.stakeholdersUAEorGCC", []), (st) => {
      return { value: st, label: st.fullName };
    })
    
    _.map(_.get(context, "initialData.stakeholdersNotUAEorGCC", []), (st) => {
      stakeholders.push( { value: st, label: st.fullName });
    })

    stakeholders.push({ value: {value:'ADD_THIRD_PARTY'}, label: 'Add third party' })

    return { initialValues, stakeholders }
  }, [context]);

  const handleCancel = (values) => {
    cancel();
  }
  const handlePrev = (values) => {
    prev();
  }
  const handleSubmit = async (values) => {
  
    next(values)
  }
  
  useEffect( ()=>{
    dispatch(addAssistantText('<b>Why do I need to appoint a secondary Authorised Signatory?</b> Click <a rel=\'noopener noreferrer\' target=\'_blank\' href=\'https://help.clara.co/en/articles/6230840-can-i-use-a-nominee-to-be-my-adgm-authorised-signatory\' >here</a> to read more about the use of Clara’s nominee service. <br><br> <b>Who can be the secondary Authorised Signatory?</b> You can choose anyone for this role, provided they are of good standing and have entered the UAE in the past two years. They do not need to be a director or shareholder. They will need to provide their personal information, identification documents and proof of entry into the UAE in the form of a UAE entry stamp in their passport or confirm the date they entered through e-gates.' ))
    return () => {
      dispatch(clearAssistatText())
    }
  },[dispatch])
  
  const schema = Yup.object().shape({
    secondAuthorisedSignatory: Yup.object().nullable().required('Required'),   
    // secondAuthorisedSignatory:Yup.array().of(Yup.object()).nullable().required('Required').min(1),

  })
  return (
  
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={ { mode: 'onChange' } }
    >
      <TemplateWizard
        props={ {
          title: 'Who is going to be appointed as second Authorised Signatory?',
          skeleton: null,
          subTitle: 'ADGM Authorised Signatory',
          variant: 'simple'
        } }
        
        buttons={ {
          previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('BUTTON_NEXT')}</SubmitButton>,
        } }
      >
        
        <Typography weight='regular' variant='xbody'>
          To use Clara’s service, you must appoint a second Authorised Signatory. This person doesn't need to be a GCC Resident or National, they only need to have entered the UAE in the last two years. Who will you be appointing in this role?
        </Typography>

        <ControllerInput
            as={CheckboxButtonSimple}
            isMultiple={false}
            defaultValue={[]}
            name="secondAuthorisedSignatory"
            options={stakeholders}
            classNameButton={classes.buttonCheckBox}
            size="lg"
          />
        
      </TemplateWizard>
    </Form>
  );
}

export default WhoIsSecondAuthorisedSignatoryFormStep;


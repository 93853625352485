import React, { useCallback, useMemo } from 'react';
import { Stack } from '@mui/material';
import * as Yup from 'yup';
import Form from "src/components/forms/Form/FormL";
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import Typography from "src/modules/generic/components/Texts/Typography";
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from "src/modules/generic/hooks/useTranslate";
import ControllerInput from "../../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import VerificationCode from "../../../../../../components/inputs/VerificationCode/VerificationCode";
import TemplateOnboarding2Wizard from 'src/modules/generic/templates/Modal/TemplateOnboarding2Wizard';
import Assets from 'src/modules/Onboarding/views/Wizard/assets';

/**
 * Step to enter code for accelerator step
 * @param {*} param0
 * @returns
 */

function AcceleratorStep({ submitButonProps, actualStep, totalSteps, isLoading = false }) {
  const { next, prev, context, cancel } = useMachine();
  const contextAccelerator = context?.AcceleratorStep?.verificationCode;
  const { translate } = useTranslate();

  const schema = Yup.object().shape({
    verificationCode: Yup.string()
      .nullable()
      .required("Required")
      .min(6, translate("VERIFY_EMPTY")),
  });

  const handleNext = useCallback(
    (values) => {
      next(values);
    }, [next]
  );

  const handleCancel = () => {
    cancel();
  }

  const leftComponent = useMemo(() => {
    return (
      <Stack spacing={4} pt={11} sx={{width: "100%", height: "100%"}}>
        <Typography color={"black"} variant={"onboarding-titles-bold"}  >
          <span dangerouslySetInnerHTML={{ __html: translate("MODULES.ONBOARDINGCOMPANY.ONBOARDING_NEW_ACCELERATOR_TITLE") }}>
          </span>
        </Typography>
        <Typography variant="onboarding-great" color="black" component="p">
          <span dangerouslySetInnerHTML={{ __html: translate("MODULES.ONBOARDINGCOMPANY.ONBOARDING_NEW_ACCELERATOR_SUBTITLE") }} />
        </Typography>
        <div>
          <ControllerInput
            render={VerificationCode}
            name="verificationCode"
            disabled={isLoading}
          />
        </div>
        <Typography
          color="black"
          component="div"
          variant="body"
          weight="regular"
        >
          {translate("MODULES.ONBOARDINGCOMPANY.ONBOARDING_NEW_ACCELERATOR_TEXT")}
        </Typography>
      </Stack>
    )
  }, [translate]);

  const rightComponent = useMemo(() => {
    return  (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={0}
        sx={{ width: "100%", height: "100%" }}
      >
        <img src={Assets.STEP_4} alt="Clara" style={{ width: "285px", height: "auto", marginTop: "60px" }} />
      </Stack>
    )
  }, []);

  return (
    <Form
      defaultValues={{ verificationCode: contextAccelerator }}
      onlySendDirty={false}
      template={TemplateOnboarding2Wizard}
      schema={schema}
      onSubmit={handleNext}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateOnboarding2Wizard
        buttons={{
          submit: <SubmitButton {...submitButonProps} isSubmitting={isLoading}/>
        }}
        previousClick={() => {
          if (!isLoading) {
            prev();
          }
        }}
        cancel={handleCancel}
        leftComponent={leftComponent}
        rightComponent={rightComponent}
        actualStep= {actualStep}
        totalSteps={totalSteps}
      >
      </TemplateOnboarding2Wizard>
    </Form>
  );
}
export default AcceleratorStep;
import React, { FC, useMemo } from 'react';
import Button from "../../../../../generic/components/Buttons/Button";
import useCustomMachine from "../../../../../generic/hooks/useCustomMachine";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import useSession from '../../../../../session/hooks/useSession';
import SelectCompanies from "../../../forms/SelectCompanies";
import useGetGroupCompanies from "../../../graphql/queries/useGetGroupCompanies";
import  _ from "lodash";
type FirstStepSubmit = () => Promise<any>
interface SelectCompaniesProps {
    type: string
    tooltips?: object
    onCompleteSubmit?: FirstStepSubmit
    isTherePrevious?:boolean
    query?:string
    remove?:object[]
}

const SelectCompaniesStep:FC<SelectCompaniesProps>=({type,remove,query,tooltips,onCompleteSubmit,isTherePrevious})=>{

    const {send,next,stepData}=useCustomMachine();
    const {translate}=useTranslate();
    const { startupId } = useSession();
    const {data}=useGetGroupCompanies({startupId},{query});

    const handlePrev=()=> {
      send("PREVIOUS");
    }

    const handleCancel=()=>{
      send('CANCEL');
    }

    const handleCompleteSubmit = async (values: any) => {
      onCompleteSubmit && await onCompleteSubmit();
      next(values);
    }

    const groupCompanies=useMemo(():object[]=>{
      let filtered = _.filter(data, (company) => company.kind === "INCORPORATED");
      return _.orderBy(filtered,['isHoldingCompany'],['desc']);
    },[JSON.stringify(data)]);

    return (
      <>
        <SelectCompanies
          groupCompaniesList={groupCompanies}
          tooltips={tooltips}
          initialValues={stepData}
          propsTemplate={{
            title: translate("MODULES.EQUITY.STEPS.SELECT_COMPANIES_STEP.TITLE", { 
              type: translate(`MODULES.EQUITY.TYPES_PLURAL.${type}`)
            }),
            subTitle: translate("MODULES.EQUITY.STEPS.SELECT_COMPANIES_STEP.SUBTITLE", {
              type: translate(`MODULES.EQUITY.TYPES_PLURAL.${type}`)
            }),
          }}
          onCompleteSubmit={handleCompleteSubmit}
          Template={TemplateWizard}
          buttons={{
            cancel: <Button onClick={handleCancel}>Cancel</Button>,
            previous: isTherePrevious ? (
              <Button onClick={handlePrev}>Previous</Button>
            ) : null,
          }}
        />
      </>
    );
}
export default SelectCompaniesStep
import React, { FC } from 'react';
import animationData from 'src/modules/generic/animations/pincas-in-progress.json';
import Lottie from 'src/modules/generic/components/Animations/Lottie/Lottie';
import { StepProps } from 'src/modules/generic/components/Wizard/Step';

/**
-  Forming Step
**/
const SubmitingPincasStep: FC<StepProps> = () => {

  return (
    <React.Fragment>
      <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Lottie
          height={403}
          width={602}
          options={{
            loop: true,
            autoplay: true,
          }}
          animation={animationData} />
      </div>
    </React.Fragment>
  )
}

export default SubmitingPincasStep;

import React from 'react'
import _ from "lodash";
import viewClasses from "../../../../../../components/Forms/View/ViewForms.module.scss";
import classes from "../../../../SteperForm.module.scss";
import {Field, FieldArray} from "formik";
import RowAdd from "../../../../../../../components/forms/contents/RowAdd/RowAdd";
import Classes from "../../../../../../../components/inputs/AuthorizedSignatories/AuthorizedSignatories.module.scss";
import TextInput from "../../../../Inputs/TextInput";
import checkOk from "../../../../../../../images/checkOk.svg";

const IndividualCompany=({fieldName, gcAuthorizedSignatoriesEmailDisabled, handleOnChangeEmailPartyGroupCompany})=>{
    return(<>
        <div className={viewClasses.ContainerView}>
                <div className={viewClasses.ContainerData}>
                    <FieldArray
                        name={`${fieldName}.authorizedSignatories`}
                        render={(arrayHelpers) => (<div>
                            {_.get(arrayHelpers.form.values, `${fieldName}.authorizedSignatories`,[]).map((signatory, index) => {
                                const isLast = _.get(arrayHelpers.form.values, `${fieldName}.authorizedSignatories`,[]).length === index + 1;
                                return (
                                    <div className={`${viewClasses.ContainerRow}`}>
                                        <div className={`${viewClasses.ContainerRow} ${viewClasses.ContainerDataPhoto}`}>
                                            <label>Corporate Signatory:</label>
                                        </div>
                                        <Field
                                            name={`${fieldName}.authorizedSignatories[${index}].fullName`}>
                                            {({ field, form }) => {
                                                return(
                                                    <RowAdd
                                                        label={"Name:"}
                                                        asterisk={true}
                                                        classNameInput={Classes.input}
                                                        input={
                                                            <TextInput
                                                                type={'text'}
                                                                field={field}
                                                                form={form}
                                                                disabled={true}
                                                                placeholder={"Name..."}
                                                            />
                                                        }
                                                    />
                                                )}}
                                        </Field>
                                        <Field
                                            name={`${fieldName}.authorizedSignatories[${index}].email`}>
                                            {({ field, form }) =>{
                                                return(
                                                    <RowAdd
                                                        label={"Email address:"}
                                                        asterisk={true}
                                                        classNameInput={Classes.input}
                                                        input={
                                                            <TextInput
                                                                type={'email'}
                                                                field={field}
                                                                form={form}
                                                                disabled={gcAuthorizedSignatoriesEmailDisabled[index]}
                                                                onChange={(e) => handleOnChangeEmailPartyGroupCompany(form, field, e.target.value, index)}
                                                                errorMessage={_.get(form.errors,`${fieldName}.authorizedSignatories[${index}].email`)}
                                                                classNameError={Classes.errorForm}
                                                                placeholder={"Email address..."}
                                                                rightLabel={gcAuthorizedSignatoriesEmailDisabled[index] ? <img style={{'position': 'absolute', 'top': '-8px', 'right': '-10px'}} width={30} height={30} src={checkOk}/> : null}
                                                            />
                                                        }
                                                    />
                                                )}}
                                        </Field>
                                        {!isLast && <hr className={classes.Separator} />}
                                    </div>)
                            })}
                        </div>)}
                    />
                </div>
            </div>
    </>)
}
export default IndividualCompany;

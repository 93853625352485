import React from 'react';
import useLoading from "../../../../../modules/generic/hooks/useLoading";
import CompanyView from "./CompanyView"
import CorporateStakeholderViewSkeleton from "../../stakeholder/corporateStakeholderView/CorporateStakeholderViewSkeleton";

const CompanyPage = ({ ...props }) => {

  const { isLoading } = useLoading();

  return (
    <React.Fragment>
      <CorporateStakeholderViewSkeleton
        isLoading={isLoading}
      >
      </CorporateStakeholderViewSkeleton>
      <CompanyView {...props} />
    </React.Fragment>
  );
}

export default CompanyPage;
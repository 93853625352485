import React from "react";
// import {ApolloClient} from 'apollo-client';
// import gql from "graphql-tag";
import {ApolloProvider, useSubscription,gql,ApolloClient,HttpLink,split,InMemoryCache} from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import Cookies from 'universal-cookie';
// import {WebSocketLink} from 'apollo-link-ws';
// import {HttpLink} from 'apollo-link-http';
// import {split} from 'apollo-link';
import {getMainDefinition} from "@apollo/client/utilities";
import _ from "lodash";
// import {InMemoryCache} from 'apollo-cache-inmemory';
import Mustache from "mustache";
import {loadState} from "./../../../session"
const config = () => {
    const session = loadState("session");
    const tokenCookie = _.get(session, "token",null);

    let graphqLUri;

    if (process.env.REACT_APP_API_URL) {
        graphqLUri = process.env.REACT_APP_API_URL + "/graphql";
    } else {
        graphqLUri = window.location.protocol + "//" + window.location.hostname + "/graphql";
    }

    let gqlUri = _.replace(graphqLUri, 'https', 'wss');
    gqlUri = _.replace(graphqLUri, 'http', 'ws');



    const wsLink = new WebSocketLink({
        uri: gqlUri,
        options: {
            reconnect: true,
            connectionParams: {
                Authorization: tokenCookie

            }
        }
    });

    const httpLink = new HttpLink({
        uri: graphqLUri,
        headers: {
            "Authorization": tokenCookie
        }
    })

    const link = split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription'
            );
        },
        wsLink,
        httpLink
    )

    const client = new ApolloClient({
        link,
        cache: new InMemoryCache()

    });

    return client;

}

const SubscriptionApollo = ({ subscriptions: query, values, callback }) => {

    const subscription = Mustache.render(query, values);

    const data = useSubscription(gql`${subscription}`);

    // console.log("data", data);
    // console.error("error", error);
    if (data) {
        callback(data);
    }

    return null;
}

const useSubscriptionClara = (subscription, variables) => {
    const client = config();
    const { data, loading } = useSubscription(gql`${subscription}`, { variables, client });
    return {data,loading};
}


const Subscription = (props) => {

    const clientApollo = config();

    return (

        <ApolloProvider client={clientApollo}>
            <SubscriptionApollo {...props} />
        </ApolloProvider>

    )

}


export { Subscription, useSubscriptionClara };
import React, { FC, useContext, useMemo, useRef } from 'react'
import _ from 'lodash'
import useTranslate from "../../../../../generic/hooks/useTranslate";
import Form from "../../../../../../components/forms/Form/Form";
import ControllerInput from "../../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import SubmitButton from "../../../../../generic/components/Buttons/SubmitButton";
import TemplateFormDefault from "../../../../../generic/templates/Modal";
import FormProps from "../../../../../generic/forms/interfaces/FormProps";
import { CheckboxButtonOptions } from "../../../../../generic/components/Inputs/CheckboxButton";
import schema from './schema'
import CheckboxButtonSimple from "../../../../../generic/components/Inputs/CheckboxButtonSimple";
import { addAssistantText, clearAssistatText } from "../../../../../generic/store/action";
import { useDispatch } from "react-redux";
import classes from './classes.module.scss'
import Typography from '../../../../../generic/components/Texts/Typography';
const ClaraConciergeIndividual: FC<FormProps> = ({ initialValues, tooltips, onCompleteSubmit,
    Template = TemplateFormDefault, buttons = {}, buttonSubmitProps, propsTemplate }: FormProps) => {
    const { translate } = useTranslate();
    const dispatch = useDispatch()

    const options: Array<CheckboxButtonOptions<boolean, any>> = [
        {
            value: false,
            label: translate('MODULES.ONBOARDING_FORMS.CLARA_CONCIERGE_INDIVIDUAL.OPTION_NO')
        },
        {
            value: true,
            label: translate('MODULES.ONBOARDING_FORMS.CLARA_CONCIERGE_INDIVIDUAL.OPTION_YES')
        }

    ]
    const refForm = useRef()
    return (
        <React.Fragment>
            <Form
                initialValues={initialValues || {}}
                schema={schema}
                onSubmit={onCompleteSubmit}
                ref={refForm} optionsForm={{ mode: "onChange" }}
            >
                <Template props={propsTemplate} buttons={{ submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined} {...buttonSubmitProps}>{translate('MODULES.ONBOARDING_FORMS.CLARA_CONCIERGE_INDIVIDUAL.BUTTON_NEXT')}</SubmitButton>, ...buttons }}>

                    <Typography variant='s'>
                        {translate('MODULES.ONBOARDING_FORMS.CLARA_CONCIERGE_INDIVIDUAL.TEXT_ADD_INDIVIDUAL_DESCRIPTION')}
                    </Typography>

                    <ControllerInput
                        as={CheckboxButtonSimple}
                        name='enable_clara_concierge'
                        variant='col_1'
                        classNameButton={classes.buttonCheckBox}
                        options={options}
                        onChange={value => {
                            if (tooltips) {
                                dispatch(clearAssistatText());
                                if (value) {
                                    _.get(tooltips, 'isAnother') && dispatch(addAssistantText(_.get(tooltips, 'isAnother', "text")));
                                } else {
                                    _.get(tooltips, 'isNotAnother') && dispatch(addAssistantText(_.get(tooltips, 'isNotAnother', "text")));
                                }
                            }
                        }}
                    />

                    <Typography weight={"bold"} variant='body'>
                        {translate('MODULES.ONBOARDING_FORMS.CLARA_CONCIERGE_INDIVIDUAL.TITLE_WHAT_IS_CONCIERGE')}
                    </Typography>

                    <Typography color='dark-grey' variant='body'>
                        {translate('MODULES.ONBOARDING_FORMS.CLARA_CONCIERGE_INDIVIDUAL.TEXT_WHAT_IS_CONCIERGE')}
                    </Typography>


                </Template>
            </Form>
        </React.Fragment>
    )
}

export default ClaraConciergeIndividual

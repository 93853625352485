import React, { FC, useMemo } from 'react';
import cls from './styles.module.scss'
import Typography from '../../Texts/Typography';
import CountryFlag, { FlagProps } from '../../CountryFlag';
import Icon, { IconProps } from 'src/modules/generic/components/Icon/Icon';
import CheboxButtonSimple, { CheckboxProps } from '../CheckboxButtonSimple'
interface Option extends Omit<IconProps, 'size' | 'isClara'>, Omit<FlagProps, 'size'> {
  label: React.ReactNode;
  isFlag?: boolean;
  value: any
  disabled?: boolean
}
interface Props extends Omit<CheckboxProps, 'options'> {
  options: Array<Option>;
}

const CheckboxButtonIcon: FC<Props> = ({ options, ...props }) => {
  const optionButtons = useMemo(() => {
    return options.map(option => ({
      component: ContentButtonCheckbox,
      value: option.value,
      componentProps: {
        ...option
      }
    }))
  }, [options])
  return (
    <CheboxButtonSimple {...props} options={optionButtons} classNameButton={cls.btnDetail}/>
  );
};

export default CheckboxButtonIcon;

export const ContentButtonCheckbox: FC<Omit<Option, 'value'>> = ({ isFlag, label, ...props }) => {
  
  return (
    <div className={cls.CheckboxButtonIcon}>
      {isFlag ?
        <CountryFlag alpha3={props.icon} {...props} size={"3.5385rem"} /> :
        <Icon {...props} size={"3.5385rem"} isClara={true} />}
      <Typography className={cls.SizeTwoLines} weight={props.disabled ? 'regular' : 'light'}>{label}</Typography>
    </div>
  );
}
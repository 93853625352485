import React, { Component } from "react";
import Progress from "./Progress";
import ServerConnect from "../../../utils/ServerConnect"
import { connect } from "react-redux";
import classes from './Upload.module.scss'
import _ from "lodash"
import * as numeral from "numeral";
import PrimaryButton from "../../../../components/generic/Buttons/PrimaryButton/PrimaryButton"

class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false,
      uploadResult: {},
      limitFiles: 30000,
      inProcess: null,
      showLoading: false,
      maxFileSize: 100000000
    };
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
  }

  onFilesAdded(files, reload = true) {
    const { uploadProgress } = this.state;
    const upload = { ...uploadProgress }
    const filesUploaded = { ...files }
    _.forEach(filesUploaded, (file, i) => {
      if (!file.id) {
        file.id = Math.floor(Math.random() * 10000)
        _.set(upload, file.id, { state: null, percentage: 0, file: file })
      }
      else _.set(upload, file.id, { state: "done", percentage: 100, file: file })
    })
    this.setState(prevState => ({
      files: prevState.files.concat(files),
      uploadProgress: upload,
      uploading: true
    }), () => {
      // not clearer code
      // !(_.get(this.props.field, 'value') || _.get(this.props, 'value')) &&
      this.uploadFile()
    });
  }

  uploadFile = () => {
    let { uploadProgress, inProcess, limitFiles } = this.state;
    let uploaded = _.find(uploadProgress, (element) => {
      return element.state == null || element.state === "retry"
    });
    if (uploaded) {
      inProcess = inProcess++;
      this.setState({
        inProcess: inProcess
      }, () => {
        if (inProcess < limitFiles) {
          setTimeout(() => {
            this.uploadFile();
          }, 1000)
        }
        this.sendRequest(uploaded.file).then(result => {
          this.setState({
            inProcess: inProcess--
          }, () => {
            setTimeout(() => {
              this.uploadFile();
            }, 1000)
          })
        }).catch(error => {
          this.setState({
            inProcess: inProcess--
          }, () => {
            setTimeout(() => {
              this.uploadFile();
            }, 1000)
          })
        })
      })
    }
  }

  uploadFiles(files) {
    let uploadProgress = this.state.uploadProgress;
    let uploadResult = {}
    files.forEach(file => {
      uploadProgress[file.id] = { state: null, percentage: 0, file: file }
      uploadResult[file.id] = file;
    });
    this.setState(
      { uploadProgress: uploadProgress, uploading: true },
      () => {
        this.uploadFile()
      }
    );
  }

  validationFile(file) {
    let state = "pending";
    let error = false;
    let message = "";
    if (file.size > this.state.maxFileSize) {
      state = "error";
      message = "Max size " + this.state.maxFileSize;
      error = true;
    }
    return { state, message, error }
  }

  sendRequest(file) {
    return new Promise((resolve, reject) => {
      const copy = { ...this.state.uploadProgress };
      const fileState = this.validationFile(file);
      copy[file.id] = {
        ...copy[file.id],
        state: fileState.state,
        error: fileState.error,
        message: fileState.message,
        percentage: 0
      };
      this.setState({ uploadProgress: copy });
      if (fileState.error) {
        return;
      }
      const req = new XMLHttpRequest();
      const tokenCookie = _.get(this.props.session, "token")
      req.upload.addEventListener("progress", event => {
        if (event.lengthComputable) {
          const copy = { ...this.state.uploadProgress };
          copy[file.id] = {
            ...copy[file.id],
            state: "pending",
            percentage: numeral((event.loaded / event.total) * 100).format("0[.]00")
          };
          this.setState({ uploadProgress: copy });
        }
      }, false);
      req.upload.addEventListener("load", event => {
      });
      req.upload.addEventListener("error", event => {
        const copy = { ...this.state.uploadProgress };
        copy[file.id] = { ...copy[file.id], state: "error", percentage: 0 };
        this.setState({ uploadProgress: copy });
      });
      req.upload.addEventListener("abort", event => {
      });
      req.addEventListener("load", event => {
        const copy = { ...this.state.uploadProgress };
        copy[file.id] = { ...copy[file.id], state: "done", percentage: 100 };
        const uploadResult = { ...this.state.uploadResult };
        if (req.response && JSON.parse(req.response).filePond) {
          uploadResult[file.id] = JSON.parse(req.response).filePond;
        } else {
          uploadResult[file.id] = null;
        }
        this.setState({ uploadResult: uploadResult, uploadProgress: copy }, () => {
          resolve(uploadResult[file.id]);
        });
      });
      const formData = new FormData();
      formData.append("file", file, file.name);
      req.open("POST", ServerConnect.urlUploadMasiveFiles());
      req.setRequestHeader("Access-Control-Allow-Origin", "*");
      req.setRequestHeader("Authorization", tokenCookie);
      req.send(formData);
    });
  }

  componentWillMount() {
    const { files } = this.props;
    const copiedFiles = _.cloneDeep(files);
    this.onFilesAdded(copiedFiles, false)
  }

  componentWillUpdate(nextProps, nextState) {
    const { form, field, files } = this.props;
    if (nextProps.files !== files) {
      this.onFilesAdded(nextProps.files)
    }
    if (nextState.uploadResult !== this.state.uploadResult) {
      if (nextProps.automaticDone) {
        this.props.handleDone(nextState.uploadResult, form, field);
      }
    }
  }

  closeModal = () => {
    this.props.handleClose();
  }

  doneModal = () => {
    const { form, field, handleDone, keepUploadedFilesInState } = this.props;
    const { uploadResult } = this.state;
    this.setState({
      showLoading: true,
    }, () => {
      handleDone(uploadResult, form, field, keepUploadedFilesInState)
        .then(result => {
          this.setState({
            showLoading: false,
          })
        });
    })
  }

  isDisabled = () => {
    let { uploadProgress } = this.state;
    let uplodad = _.find(uploadProgress, (element) => {
      return element.state == null || element.state == "retry" || element.state == "pending"
    })
    if (uplodad) {
      return true;
    }
    return false;
  }

  reload = (file) => {
    let { uploadProgress, inProcess, limitFiles } = this.state;
    const copy = { ...uploadProgress };
    copy[file.id] = { ...copy[file.id], state: null, percentage: 0 };
    this.setState({ uploadProgress: copy }, () => {
      if (inProcess < limitFiles) {
        this.uploadFile();
      }
    });
  }

  remove = (file, id) => {
    let { files, uploadProgress, uploadResult } = this.state;
    const { form, field, automaticDone, handleDone } = this.props
    const index = files.findIndex((element) => {
      return element.id === id
    })
    files.splice(index, 1);
    delete uploadProgress[file.id];
    delete uploadResult[file.id];
    this.setState({
      files, uploadProgress, uploadResult
    });
    if (automaticDone) {
      handleDone(uploadResult, form, field);
    }
  }

  render() {
    let { uploadProgress } = this.state;
    return (
      <div className={`${this.props.noBorder ? classes.UploadContainerTrasnparent : classes.UploadContainer}`}>
        <div className={classes.Files}>
          <div className={classes.Uploads}>
            {this.state.files.map(file => {
              return (
                <div key={file.id} className={classes.addPadding}>
                  <Progress
                    file={file}
                    reload={this.reload}
                    remove={() => { this.remove(file, file.id) }}
                    progress={uploadProgress[file.id] ? uploadProgress[file.id].percentage : 0}
                    state={uploadProgress[file.id] ? uploadProgress[file.id].state : null}
                    message={uploadProgress[file.id] ? uploadProgress[file.id].message : null}
                    error={uploadProgress[file.id] ? uploadProgress[file.id].error : null}
                  />
                </div>
              );
            })}
          </div>
          {!this.props.automaticDone && (
            <React.Fragment>
              <hr />
              <div className={classes.botonera}>
                <button onClick={this.closeModal} className={classes.cancelButton}>Cancel</button>
                <PrimaryButton
                  type={"submit"}
                  loading={this.state.showLoading}
                  disabled={this.isDisabled()}
                  className={classes.nextButton}
                  onClick={this.doneModal}
                >
                  Done
                </PrimaryButton>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    session: state.session
  };
};

const mapDispatchToProps = dispatch => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Upload);


export default {
    ADDRESS_AND_CURRENCY:{
        ADDRESS:'Company Address'
    },
    SPECIAL_MEANING:{
        NO:'No, it doesn\'t',
        YES:'Yes, it does',
        DESCRIBE_PLACEHOLDER:'What\'s the meaning behind your company name? Describe it here in as much detail as possible.',
        EXPLAIN:'This includes shortened versions of words, abbreviations and acronyms.'
    },
    PROVIDE_TRANSLATION:{
        NO:'No, use transliteration ',
        YES:'Yes, add it now',
        DESCRIBE_PLACEHOLDER:'Add the company name in Arabic.',
        EXPLAIN:'Providing a translation is optional.'
    },
    AFFILIATIONS_COMPANIES:{
        NO:'No, it isn’t',
        YES:'Yes, it is',
        DESCRIBE_PLACEHOLDER:'Add the full legal name of the affiliated company.',
        EXPLAIN:'This company could be located anywhere in the world. '
    },
    COMMERCIAL_LICENSE:{
      LABEL:'Upload',
        EXAMPLE:'Example of required layout'
    },
    BANKING_SERVICES:{
        NO:'No, I don’t',
        YES:'Yes, I do'
    },
    SINGLY_OR_JOINTLY:{
    JOINTLY:'Jointly',
    SINGLY:'Singly'
    },
    IS_THERE_COMPANIES_SHARES:{
        NO:'No, it won’t',
        YES:'Yes, it will'
    },
    IS_THERE_MORE_COMPANIES:{
        NO:'No, I don’t',
        YES:'Yes, I do'
    },
    IS_THERE_COMPANIES_FORMATION_SHARES:{
        NO:'No, it won’t',
        YES:'Yes, it will',
        PLACEHOLDER:'List all companies in which it will have shares'
    },
    CONFIRM_DIRECTORS_SHAREHOLDERS:{
        YES:'Yes, all added!',
        NO:'No, not yet',
        NO_ADDED_ALL_DIRECTORS_SHAREHOLDERS:'To complete this task, first add all your company\'s directors and shareholders.',
    },
    SHARE_CAPITAL:{
        PARAGRAPH:"Your company will be formed with the standard setup of <b>10,000,000</b> authorised shares, with <b>1,000,000</b> shares available to be issued at a par value of <b>USD 0.0001</b> during the formation process. All 1,000,000 shares must be issued to your company’s shareholders.",
        REMAINING_SHARES:"Remaining shares to be issued: <b>{{shares}}</b>",
        PERCENTAGE:'Percentage',
        NUMBER_OF_SHARES:"No. of shares",
        STAKEHOLDERS_SHARES:"Enter the number of shares you want to give to each stakeholder below."
    },
    SHARE_CAPITAL_CAYMAN:{
        PARAGRAPH:"Your company will be formed with the standard setup of <b>50,000,000</b> authorised shares, with <b>1,000,000</b> shares available to be issued at a par value of <b>USD 0.001</b> during the formation process. All 1,000,000 shares must be issued to your company’s shareholders.",
        REMAINING_SHARES:"Remaining shares to be issued: <b>{{shares}}</b>",
        PERCENTAGE:'Percentage',
        NUMBER_OF_SHARES:"No. of shares",
        STAKEHOLDERS_SHARES:"Enter the number of shares you want to give to each stakeholder below."
    },
    SHARE_CAPITAL_DELAWARE:{
        PARAGRAPH:"Your company will be formed with a recommended setup of <b>10,000,000</b> authorized shares with a par value of <b>$0.00001</b> each, of which <b>8,000,000</b> shares will be issued at incorporation and <b>2,000,000</b> shares remaining for future issuances (e.g. for ESOP). Please note that these shares will be subject to standard founder vesting provisions over 4 years, with a 1 year cliff and monthly vesting after the cliff (i.e. 25% of the shares will vest after 1 year with the remaining vesting monthly over the subsequent 3 years).",
        REMAINING_SHARES:"Remaining shares to be issued: <b>{{shares}}</b>",
        PERCENTAGE:'Percentage',
        NUMBER_OF_SHARES:"No. of shares",
        STAKEHOLDERS_SHARES:"Enter the number of shares you want to give to each stockholder below."
    },
    CERTIFICATE_OF_INCORPORATION:{
        UPLOAD: 'Upload certificate',
        FILE: 'Example of required layout'
    },
    NATURE_OF_BUSINESS:{
        PARAGRAPH:'Tell us a bit more about the underlying business activities of your operating company. This is the company that will actually conduct operations (e.g. provide goods or services, hire employees, rent office space etc.) and will be held by the topco you will form in the Cayman Islands.',
        ACTIVITY_PLACEHOLDER:'For example, Legal technology',
        ACTIVITY_INPUT:'Nature of business',
        OPERATING_COMPANY_PLACEHOLDER:'For example, Clara Operating Limited',
        OPERATING_COMPANY_INPUT:'Operating company name',
    }
}
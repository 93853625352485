import { useMemo } from 'react';
import useSession from "src/modules/session/hooks/useSession";
import useUpdateStakeholder from "src/modules/startup/onBoarding/core/graphql/mutations/useUpdateStakeholder";

const useServices = ({ initialValues }) => {
  const { startup } = useSession();
  const { updateStakeholder } = useUpdateStakeholder({
    showSuccessNotification: true,
    successMessage: 'STAKEHOLDER_EDIT_SUCCESS'
  }, {});

  return useMemo(() => ({
    initialData: async () => {
      return initialValues;
    },
    SaveStep: async (context) => {
      const values = context?.SourceOfFundsForm;
      const variables = {
        startupId: startup?.id,
        stakeholderId: initialValues?.stakeholderId,
        stakeholderData: {
          stakeholder: {
            sourcesOfFunds: [
              {
                jurisdictionType: initialValues?.jurisdictionType || initialValues?.formValues?.jurisdictionType || 'ADGM',
                sourceOfFunds: values?.sourceOfFunds,
                sourceOfWealth: values?.sourceOfWealth,
              }
            ]
          }
        }
      };
      return await updateStakeholder({ variables });
    },
  }), [initialValues, startup?.id, updateStakeholder]);
}

export default useServices;

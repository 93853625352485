import useMutation from '../../../../../hooks/custom/useMutation';

const UPSERT_STARTUP = `mutation upsertStartupProfile(
  $id: ID
  $startupData: StartupInput!        
) {
  upsertStartupProfile(
    id: $id
    startupData: $startupData
  ) {
    id
    name
    description
    avatar
    website
    primaryLocation {
      name
      code
    }
    linkedInURL
    resellerInfo{
      receiveEmailsOnBehalfOwners
    }
  }
}`;

const useUpsertStartup = ({ ...props }) => {
  const mutation = useMutation(UPSERT_STARTUP, { ...props });
  return mutation;
}

export default useUpsertStartup;
import { useMemo } from 'react';
import useSession from 'src/modules/session/hooks/useSession';
import useUpsertCompanyFormation from 'src/hooks/services/modules/groupCompany/useUpsertCompanyFormation';
import _ from 'lodash';

const useServices = ({initialValues}) => {

  const { startupId, selectStartup } = useSession();
  const mutationAddCompany = useUpsertCompanyFormation();

  return useMemo(() => ({
    initialData: async () => {
      return initialValues;
    },
    SaveStep_Concierge: async (context, event) => {
      const variables = {
        startupId,
        groupCompanyData: {
          country: "AE",
          formationData: {
            forceConcierge: true,
          },
          isHoldingCompany: _.get(context, "AddConciergeCompany.isHoldingCompany", false),
          jurisdiction: "Abu Dhabi Global Market",
          jurisdictionType: "ADGM",
          name:`${_.get(context, "AddConciergeCompany.name", "")} ${_.get(context, "initialData.comesFromTSL", false) ? "Limited" : "Holdings Limited"}`
        }
      };
      const result = await mutationAddCompany(variables)
      await selectStartup(startupId,null);
      return result;
    },

  }), [initialValues]);
}

export default useServices;

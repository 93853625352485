import React from 'react';
import IncorporationDocumentsIntroView from "./IncorporationDocumentsIntro/IncorporationDocumentsIntroView"
import IncorporationDocumentsSubmittedView from "./IncorporationDocumentsSubmitted/IncorporationDocumentsSubmittedView"
import IncorporationDocumentView from "./IncorporationDocument/IncorporationDocumentView"
import layout from "../../../../layouts/Clara/ClaraLayout"
import BlueScreenLayout from "../../../../layouts/BlueScreen/BlueScreenLayout"
import ACTIONS from "../../../../constants/actions"
import PAGES from "../../../../constants/pages";

const moduleRouter = [
  {
    path: PAGES.incorporationIntro.path,
    exact: true,
    component: <IncorporationDocumentsIntroView />,
    layout: layout,
    propsLayout : {},
    action: ACTIONS.COMPLETE_MATTER_FLOW,
  },
  {
    path: PAGES.incorporationSubmitted.path,
    exact: true,
    component: <IncorporationDocumentsSubmittedView />,
    layout: layout,
    propsLayout : {},
    action: ACTIONS.COMPLETE_MATTER_FLOW,
  },
  {
    path: PAGES.viewFileDocx.path,
    exact: true,
    component: <IncorporationDocumentView />,
    layout: BlueScreenLayout,
    propsLayout : {},
    action: ACTIONS.VIEW_FILE,
  },
];
export default moduleRouter;

import React, {Component} from 'react';
import Popover from '@mui/material/Popover';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

/* const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
  },
})); */


class SimpleModal extends Component {
  constructor(props) {
    super(props);
    this.state = {  };
  }
  

  handleOpen = () => {
    this.setState({open:true})
    //setOpen(true);
  };

  handleClose = () => {
    this.setState({open:false})
    this.props.handleClose(false)
    //setOpen(false);
  };
  render() {
    return (
      <div>
      <Popover
        
        open={this.props?this.props.open:false}
        onClose={this.handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorEl={this.props.anchorEl}
        id={this.props.id}
      >
        <div >
          {this.props.children}
        </div>
      </Popover>
    </div>
    );
  }
}

export default SimpleModal;


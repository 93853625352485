const templates = {

  MESSAGE_HEADER_OPTION_PLAN_CREATED:
    "{{userName}} {{entityAction}} a  {{entityName}} for {{companyName}}.",
  MESSAGE_SUB_HEADER_OPTION_PLAN_CREATED:
    "{{userName}} {{entityAction}} a  {{entityName}} for {{companyName}}.",
  MESSAGE_TEMPLATE_OPTION_PLAN_CREATED: `
    <label> {{userName}} {{entityAction}} an {{entityName}} {{equityContainerName}} for {{companyName}}</label> 
 <ul>
   <li>{{equityContainerName}} has been {{entityAction}} for {{companyName}} by {{userName}} at {{activityTime}} on {{activityDate}}.</li>
  </ul> `,

  MESSAGE_HEADER_OPTION_PLAN_EDITED:
    "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
  MESSAGE_SUB_HEADER_OPTION_PLAN_EDITED:
    "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
  MESSAGE_TEMPLATE_OPTION_PLAN_EDITED: `
    <label> {{userName}} {{entityAction}} the details of {{entityName}} {{equityContainerName}} in {{companyName}}</label> 
 <ul>
   <li>The details of {{equityContainerName}} in {{companyName}} has been {{entityAction}} by {{userName}} at {{activityTime}} on {{activityDate}}.</li>
  </ul> `,


  MESSAGE_HEADER_OPTION_PLAN_DELETED:
    "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
  MESSAGE_SUB_HEADER_OPTION_PLAN_DELETED:
    "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
  MESSAGE_TEMPLATE_OPTION_PLAN_DELETED: `
    <label> {{userName}} {{entityAction}} the {{entityName}} {{equityContainerName}} in {{companyName}}</label> 
 <ul>
   <li>{{equityContainerName}} has been {{entityAction}} in {{companyName}} by {{userName}} at {{activityTime}} on {{activityDate}}.</li>
  </ul> `,

  MESSAGE_HEADER_OPTION_PLAN_PUBLISHED:
    "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
  MESSAGE_SUB_HEADER_OPTION_PLAN_PUBLISHED:
    "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
  MESSAGE_TEMPLATE_OPTION_PLAN_PUBLISHED: `
    <label> {{userName}} {{entityAction}} a {{entityName}} for {{companyName}}</label> 
 <ul>
      <li>{{entityName}} has been {{entityAction}} for {{companyName}} by {{userName}} at {{activityTime}} on {{activityDate}}.</li>
  </ul> `,


  MESSAGE_BUTTON_REDIRECT_GO_TO_OPTION_PLAN: `View equity details`,

}

export default templates

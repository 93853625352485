import React, { useEffect, useState, useMemo } from 'react';
import { Constants } from '../../../../utils/constants'
import { useDispatch } from "react-redux";
import classes from "../ViewForms.module.scss";
import { Date, RoleAccess } from '../../../index';
import _ from "lodash";
import helper from "../../../../utils/helper";
import {
    addCompanyAsDirectorToEntity,
    addCompanyAsShareholderToEntity,
    addIndividualAsDirectorToEntity,
    addIndividualAsShareholderToEntity,
    deleteCompany,
    deleteIndividual,
    getPossibleDirectorsAndShareholders,
    sendQuestionnaireEmail,
    setAsPrimary
} from '../../../../graphql/mutations/matters';
import notification from "../../../../utils/notification";
import Mustache from 'mustache';
import ServerConnect from "../../../../utils/ServerConnect";
import Loading from '../../../Loading/Loading';
import moment from "moment";
import DeleteEntityKYC from './DeleteEntityKYC';
import ModalMenu from '../../../Modal/ModalMenu/ModalMenu';
import useGetDraftMatter from '../../../../Hooks/useGetDraftMatter'

const Kyc = (props) => {

    const dispatch = useDispatch();
    const { user, data } = props;
    const [selectedEntity, setSelectedEntity] = useState(null);
    const [entities, setEntities] = useState([])
    const [loading, setLoading] = useState(true)
    const [primary, setPrimary] = useState(null)
    const [showDelete, setShowDelete] = useState(false)
    const [mode, setMode] = useState('ENTITY')
    const [totalCompanies, setTotalCompanies] = useState([])
    const [totalIndividuals, setTotalIndividuals] = useState([])
    const [isDraftData, setIsDraftData] = useState(true)

    const [KYC, setKYC] = useState(null);

    const { data: dataKYC, loading: loadingKYC } = useGetDraftMatter({ matterId: props.data.id, type: "KYC" });



    useEffect(() => {
        if (_.get(props, 'data.KYC.individuals', []).length || _.get(props, 'data.KYC.entitiesData.entities', []).length) {
            setIsDraftData(false)
            setKYC(props.data.KYC);
        }
        if (_.get(dataKYC, 'getMatterDraft.KYC')) {
            setIsDraftData(true)
            setKYC(dataKYC.getMatterDraft.KYC);
            setLoading(false)
        }

    }, [loadingKYC])



    const entitiesData = KYC ? (_.get(KYC, 'KYCType', '') == 'individual' ? [..._.get(KYC, 'individuals', [])] : [..._.get(KYC.entitiesData, 'entities', [])]) : []






    const renderCountryName = (countryCode) => {
        return _.findKey(Constants.COUNTRYCODES, country => { return country == countryCode })
    }


    const getSubEntitiesList = () => {
        const query_params = {
            matterId: props.data.id,
            isDraft: false,
        }
        // TODO: review this query
        if (query_params.matterId === undefined)
            return
        const queryExecute = Mustache.render(getPossibleDirectorsAndShareholders, query_params);
        ServerConnect.graphQlQuery(queryExecute).then(result => {
            setTotalCompanies(result.getPossibleDirectorsAndShareholders.companies)
            setTotalIndividuals(result.getPossibleDirectorsAndShareholders.individuals)
        }).catch(err => {
            console.log(err);
            notification.sendNotification("ERROR", "error", 4990);
        });
    }

    const callServerToGetData = () => {
        setEntities(entitiesData)
        setLoading(false)
        getSubEntitiesList()
    };


    const avoidNullFields = (value) => {
        if (value == null) {
            return ''
        } return value
    }
    const getLeftDirectorsIndividuals = () => {
        if (_.get(selectedEntity, 'directors', null)) {
            let selectedEntityDirectorsIndividuals = selectedEntity.directors.individuals ? [...selectedEntity.directors.individuals] : []
            let leftDirectorsIndividuals = []
            _.forEach(totalIndividuals, ind => {
                if (_.find(selectedEntityDirectorsIndividuals, dirInd => {
                    return dirInd.id !== ind.id
                })) {
                    leftDirectorsIndividuals.push(ind)
                }
            })
            return leftDirectorsIndividuals
        }
    }

    const getLeftSharesIndividuals = () => {
        if (_.get(selectedEntity, 'shareholders', null)) {
            let selectedEntitySharesIndividuals = selectedEntity.shareholders.individuals ? [...selectedEntity.shareholders.individuals] : []
            let leftSharesIndividuals = []
            _.forEach(totalIndividuals, ind => {
                if (_.find(selectedEntitySharesIndividuals, dirInd => {
                    return dirInd.id !== ind.id
                })) {
                    leftSharesIndividuals.push(ind)
                }
            })
            return leftSharesIndividuals
        }

    }

    const getLeftDirectorsCompanies = () => {
        if (_.get(selectedEntity, 'directors', null)) {
            let selectedEntityDirectorsCompanies = selectedEntity.directors.companies ? [...selectedEntity.directors.companies] : []
            let leftDirectorsCompanies = []
            _.forEach(totalCompanies, comp => {
                if (_.find(selectedEntityDirectorsCompanies, dirComp => {
                    return _.get(dirComp, 'id', '') !== _.get(comp, 'id', '')
                })) {
                    leftDirectorsCompanies.push(_.get(comp, ''))
                }
            })
            return leftDirectorsCompanies
        }
    }

    const getLeftSharesCompanies = () => {
        if (_.get(selectedEntity, 'shareholders', null)) {
            let selectedEntitySharesCompanies = selectedEntity.shareholders.companies ? [...selectedEntity.shareholders.companies] : []
            let leftSharesCompanies = []
            _.forEach(totalCompanies, comp => {
                if (_.find(selectedEntitySharesCompanies, dirComp => {
                    return _.get(dirComp, 'id', '') !== _.get(comp, 'id', '')
                })) {
                    leftSharesCompanies.push(comp)
                }
            })
            return leftSharesCompanies
        }
    }

    const getIndividualsLink = (link) => {
        _.forEach(getLeftDirectorsIndividuals(), ind => {
            link.push({
                className: classes.agentKYC,
                label: `Add ${ind.firstName} ${ind.lastName} Individual Director`,
                onClick: addExistentIndividual,
                params: {
                    individualId: ind.id,
                    entityId: selectedEntity.id,
                    type: 'director',
                    msg: `Individual Director ${ind.firstName} ${ind.lastName} has been added `
                },
                separator: true,
            })
        })
        _.forEach(getLeftSharesIndividuals(), ind => {
            link.push({
                className: classes.agentKYC,
                label: `Add ${ind.firstName} ${ind.lastName} Individual Shareholder`,
                onClick: addExistentIndividual,
                params: {
                    individualId: ind.id,
                    entityId: selectedEntity.id,
                    type: 'shareholder',
                    msg: `Individual Shareholder ${ind.firstName} ${ind.lastName} has been added `
                },
                separator: true,
            })
        })
        return link
    }

    const getCompaniesLink = (link) => {
        _.forEach(getLeftDirectorsCompanies(), comp => {
            link.push({
                className: classes.agentKYC,
                label: `Add ${_.get(comp, 'companyName', '')} Corporate Director`,
                onClick: addExistentCompany,
                params: {
                    companyId: _.get(comp, 'id', ''),
                    entityId: selectedEntity.id,
                    type: 'director',
                    msg: `Corporate Director ${_.get(comp, 'companyName', '')} has been added `
                },
                separator: true,
            })
        })
        _.forEach(getLeftSharesCompanies(), comp => {
            link.push({
                className: classes.agentKYC,
                label: `Add ${_.get(comp, 'companyName', '')} Corporate Shareholder`,
                onClick: addExistentCompany,
                params: {
                    companyId: _.get(comp, 'id', ''),
                    entityId: selectedEntity.id,
                    type: 'shareholder',
                    msg: `Corporate Shareholder ${_.get(comp, 'companyName', '')} has been added `
                },
                separator: true,
            })
        })
        return link
    }





    const getQuery = () => {
        if (selectedEntity) {
            return selectedEntity.type == Constants.ENTITY_TYPES.COMPANY ? deleteCompany : deleteIndividual
        }

    }







    const deleteHandler = () => {
        let newState = entities
        if (_.get(KYC, 'KYCType', '') == 'individual' || mode == 'ENTITY') {
            setEntities(_.filter(entities, ent => {
                return ent !== selectedEntity
            }))
            setSelectedEntity(primary)
            notification.sendNotification("Entity Removed", "success", 4990);
        } else {
            if (mode == 'SHAREHOLDER') {
                if (selectedEntity.type == 'individual') {
                    _.forEach(newState, ent => {
                        _.remove(ent.shareholders.individuals, ind => {
                            return ind == selectedEntity
                        })
                    })
                    setEntities(newState)
                    setSelectedEntity(primary)
                    notification.sendNotification("Entity Removed", "success", 4990);
                } else {
                    _.forEach(newState, ent => {
                        _.remove(ent.shareholders.companies, comp => {
                            return comp == selectedEntity
                        })
                    })
                    setEntities(newState)
                    setSelectedEntity(primary)
                    notification.sendNotification("Entity Removed", "success", 4990);
                }
            } else if (mode == 'DIRECTOR') {
                if (selectedEntity.type == 'individual') {
                    _.forEach(newState, ent => {
                        _.remove(ent.directors.individuals, ind => {
                            return ind == selectedEntity
                        })
                    })
                    setEntities(newState)
                    setSelectedEntity(primary)
                    notification.sendNotification("Entity Removed", "success", 4990);
                } else {
                    _.forEach(newState, ent => {
                        _.remove(ent.directors.companies, comp => {
                            return comp == selectedEntity
                        })
                    })
                    setEntities(newState)
                    setSelectedEntity(primary)
                    notification.sendNotification("Entity Removed", "success", 4990);
                }
            }
        }

    }

    const getPrimary = () => {
        let entity = _.find(entities, entity => {
            return entity.isPrimary
        })
        setSelectedEntity(entity)
        setPrimary(entity)
    }

    const downloadHandler = (file) => {
        let url = ServerConnect.getUrlFile(file.file.id);
        ServerConnect.downloadFileAsDoc(url, file.file.name);
    }

    const primaryText = (ent) => {
        if (primary) {
            return _.get(ent, 'id', '') == primary.id ? ' (Primary) ' : null
        }

    }

    const renderSetPrimaryOption = () => {
        if (primary && selectedEntity) {
            if (mode !== 'ENTITY') {
                return null
            }
            else {

                return (
                    <button className={`${showPrimaryCheck()}`} onClick={selectedEntity == primary ? null : () => { selectPrimaryHandler() }}>
                        <span>Set primary contact</span>
                    </button>
                )
            }

        }
    }


    const getNotificationFullName = () => {
        if (selectedEntity.type == Constants.ENTITY_TYPES.COMPANY) {
            return selectedEntity.companyName
        } else {
            return selectedEntity.firstName + " " + selectedEntity.lastName
        }
    }

    const getSignatoryName = (name) => {
        if (name == 'powerOfAttorney') {
            return 'Power Of Attorney'
        }
        else {
            return name
        }
    }

    const renderDirectors = (entity) => {
        switch (_.get(entity, 'type', '')) {
            case 'individual':
                return (
                    <React.Fragment>
                        <hr className={`${classes.Separator} `} />
                        <div className={`${classes.ContainerRow}`}>
                            <label>Full Legal Name:</label>
                            <span>{_.get(entity, 'firstName', '') + ' ' + _.get(entity, 'lastName', '')}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>Email Address:</label>
                            <span className={classes.textExtended}>{_.get(entity, 'email', '')}</span>
                        </div>
                    </React.Fragment>
                );
            case 'company':
                return (
                    <React.Fragment>
                        <hr className={`${classes.Separator} `} />
                        <div className={`${classes.ContainerRow}`}>
                            <label>Company name as on Certificate Of Incorporation:</label>
                            <span>{_.get(entity, 'companyName', '')}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>Signatory Name:</label>
                            <span>{_.get(entity.signatory, 'firstName', '') + ' ' + _.get(entity.signatory, 'lastName', '')}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_ENTITY_EMAIL_ADDRESS_FIELD_VIEW')}</label>
                            <span className={classes.textExtended}>{_.get(entity.signatory, 'email', '')}</span>
                        </div>
                    </React.Fragment>
                )
        }
    }

    const handleQuestionnaireEmail = (params) => {
        const variables = {
            matterId: props.data.id,
            whoIsGoingToDo: params.who,
        }

        ServerConnect.graphQlMutation(sendQuestionnaireEmail, variables)
            .then(result => {
                if (params.shouldGoToFlow) {
                    dispatch({ type: 'GO_TO', page: Constants.PAGES.answerTheQuestionnaire, params: { matterId: props.data.id } })
                } else {
                    notification.sendNotification("Questionnaire has been sent to " + props.data.clientFullName, "success", 4990);
                }
            }).catch(err => {
                console.log(err);
                notification.sendNotification("ERROR", "error", 4990);
            });
    }

    const addExistentIndividual = (params) => {

        const query = params.type == 'director' ? addIndividualAsDirectorToEntity : addIndividualAsShareholderToEntity;
        const variables = {
            matterId: props.data.id,
            individualId: params.individualId,
            entityId: params.entityId
        }

        ServerConnect.graphQlMutation(query, variables)
            .then(result => {
                notification.sendNotification(params.msg, "success", 4990);
            }).catch(err => {
                console.log(err);
                notification.sendNotification("ERROR", "error", 4990);
            });
    }

    const getIdEntityByIDPEP = (idPEP) => {
        let id = _.get(entitiesData.find(entity => _.some(entity.PEPs, ind => ind.id === idPEP)), 'id', null);
        return id ? id : idPEP;
    }

    const handleGoToIndividualFormPEP = () => dispatch({ type: 'GO_TO', page: Constants.PAGES.addIndividual, params: { matterId: props.data.id, entityId: getIdEntityByIDPEP(selectedEntity.id) } })
    const handleGoToIndividualForm = () => dispatch({ type: 'GO_TO', page: Constants.PAGES.addIndividual, params: { matterId: props.data.id } })
    const handleGoToEntityForm = () => dispatch({ type: 'GO_TO', page: Constants.PAGES.addEntity, params: { matterId: props.data.id } })
    const handleGoToCompanyForm = () => dispatch({ type: 'GO_TO', page: Constants.PAGES.addCompanyKYC, params: { matterId: props.data.id } })


    const addExistentCompany = (params) => {

        const query = params.type == 'director' ? addCompanyAsDirectorToEntity : addCompanyAsShareholderToEntity;
        const variables = {
            matterId: props.data.id,
            companyId: params.companyId,
            entityId: params.entityId
        }

        ServerConnect.graphQlMutation(query, variables)
            .then(result => {
                notification.sendNotification(params.msg, "success", 4990);
            }).catch(err => {
                console.log(err);
                notification.sendNotification("ERROR", "error", 4990);
            });
    }


    let links = [{
        className: classes.agentKYC,
        label: 'Send Questionnaire',
        params: { who: 'client' },
        onClick: handleQuestionnaireEmail,
        separator: true,
    },
    {
        className: classes.agentKYC,
        label: 'Agent Questionnaire',
        params: { who: 'serviceAgent', shouldGoToFlow: true },
        onClick: handleQuestionnaireEmail,
        separator: true,
    }
    ]
    let linksIndividual = [{
        className: classes.agentKYC,
        label: 'Add an Individual as a Director',
        onClick: handleGoToIndividualForm,
        separator: true,
    },
    {
        className: classes.agentKYC,
        label: 'Add Shareholders',
        params: { who: 'serviceAgent', shouldGoToFlow: true },
        onClick: handleGoToIndividualForm,
        separator: true,
    }
        ,
    {
        className: classes.agentKYC,
        label: 'Add PEP',
        params: { who: 'serviceAgent', shouldGoToFlow: true },
        onClick: handleGoToIndividualFormPEP,
        separator: true,
    }
    ]
    let linksCompanies = [{
        className: classes.agentKYC,
        label: 'Add Corporate Director',
        onClick: handleGoToCompanyForm,
        separator: true,
    },
    {
        className: classes.agentKYC,
        label: 'Add Corporate Shareholder',
        params: { who: 'serviceAgent', shouldGoToFlow: true },
        onClick: handleGoToCompanyForm,
        separator: true,
    }
    ]
    const openModal = () => {

    }



    const renderEmptyInsteadNull = (value) => {
        return value == null ? '' : value
    }

    const selectedClass = (value) => {
        return value == selectedEntity ? classes.selectedEntityText : null;

    }
    const renderIndividualTitleByType = () => {
        if (selectedEntity.isDirector && selectedEntity.isShareholder) {
            return 'Individual Director & Shareholder'

        } else if (selectedEntity.isDirector) {
            return 'Individual Director'
        }
        else if (selectedEntity.isShareholder) {
            return 'Individual Shareholder'
        } else if (selectedEntity.isPEP) {
            return 'Politically Exposed Person'
        }
        else {
            return 'Individual Details'
        }
    }

    const renderCompaniesTitleByType = () => {
        if (selectedEntity.isDirector && selectedEntity.isShareholder) {
            return 'Corporate Director & Shareholder'

        } else if (selectedEntity.isDirector) {
            return 'Corporate Director'
        }
        else if (selectedEntity.isShareholder) {
            return 'Corporate Shareholder'
        }
    }




    const selectPrimaryHandler = () => {
        const variables = {
            matterId: props.data.id,
            KYCIndividualOrEntityId: selectedEntity.id
        }

        ServerConnect.graphQlMutation(setAsPrimary, variables)
            .then(result => {
                notification.sendNotification(getNotificationFullName() + ' is now the primary contact', "success", 4990);
                setPrimary(selectedEntity)
            }).catch(err => {
                console.log(err);
                notification.sendNotification("DELETE ERROR", "error", 4990);
            });



    }


    const showPrimaryCheck = () => {
        if (primary) {
            return primary == selectedEntity ? classes.buttonSelectedPrimaryKYC : classes.buttonSetPrimaryKYC
        } else {
            return classes.buttonSetPrimaryKYC
        }
    }

    const renderByType = () => {
        if (selectedEntity) {
            if (selectedEntity.type === Constants.ENTITY_TYPES.INDIVIDUAL) {
                return (
                    <React.Fragment>
                        <hr className={`${classes.Separator} `} />
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('USER_TABLE_FIELDS_TITLE_FIRSTNAME')}</label>
                            <span>{_.get(selectedEntity, 'firstName', '')}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_SURNAME_FIELD_VIEW')}</label>
                            <span>{_.get(selectedEntity, 'lastName', '')}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_BIRTH_DATE_FIELD_VIEW')}</label>
                            <span><Date value={selectedEntity.dateOfBirth} /></span>
                        </div>
                        {selectedEntity ? (selectedEntity.passportDoc ? <div className={`${classes.ContainerRow}`}>
                            <label></label>
                            <span className={classes.docButton} onClick={() => { downloadHandler(_.get(selectedEntity, 'passportDoc', '')) }}>Passport</span>
                        </div> : null) : null}

                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_OCCUPATION_FIELD_VIEW')}</label>
                            <span>{_.get(selectedEntity, 'occupation', '')}</span>
                        </div>
                        <hr className={`${classes.Separator} `} />
                        <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                            <label>{helper.getTranslateTextByKey('KYC_RESIDENTIAL_ADDRESS_TITLE')}</label>
                        </div>
                        <hr className={`${classes.Separator} `} />
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_RESIDENTIAL_ADDRESS_TITLE')}</label>
                            <span>{renderCountryName(_.get(selectedEntity, 'address.country', ''))},<br />{_.get(selectedEntity, 'address.state', '')},<br />{_.get(selectedEntity, 'address.city', '')},<br />{_.get(selectedEntity, 'address.address', '')},<br />{_.get(selectedEntity, 'address.zipCode', '')}<br /></span>
                        </div>
                        {_.get(selectedEntity, 'UAEVisaDoc', null) ? <div className={`${classes.ContainerRow}`}>
                            <label></label>
                            <span className={classes.docButton} onClick={() => { downloadHandler(_.get(selectedEntity, 'UAEVisaDoc', '')) }}>UAE Visa</span>
                        </div> : null}
                        {_.get(selectedEntity, 'proofOfAddress', false) ? <div className={`${classes.ContainerRow}`}>
                            <label></label>
                            <span className={classes.docButton} onClick={() => { downloadHandler(_.get(selectedEntity, 'proofOfAddressDoc', '')) }}>Proof of Address</span>
                        </div> : null}
                        {_.get(selectedEntity, 'thirdPartyAddressFormDoc', false) ? <React.Fragment> <div className={`${classes.ContainerRow}`}>
                            <label>         </label>
                            <span className={classes.docButton} onClick={() => { downloadHandler(_.get(selectedEntity, 'thirdPartyAddressFormDoc', '')) }}>Third party address form</span>
                        </div>
                        </React.Fragment> : null}
                        {_.get(selectedEntity, 'thirdPartyAddressPassportDoc', false) ? <React.Fragment>
                            <div className={`${classes.ContainerRow}`}>
                                <label>    </label>

                                <span className={`${classes.docButton} ${classes.textExtended}`} onClick={() => { downloadHandler(_.get(selectedEntity, 'thirdPartyAddressPassportDoc', '')) }}>Third parties address passport copy</span>
                            </div>
                        </React.Fragment> : null}
                        <hr className={`${classes.Separator} `} />
                        <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                            <label>{helper.getTranslateTextByKey('KYC_CONTACT_DETAILS_TITLE')}</label>
                        </div>
                        <hr className={`${classes.Separator} `} />
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_CONTACT_NUMBER_FIELD_VIEW')}</label>
                            <span>{_.get(selectedEntity.contactNumber, 'prefix', '') + '-' + _.get(selectedEntity.contactNumber, 'number', '') + '-' + avoidNullFields(_.get(selectedEntity.contactNumber, 'extension', ''))}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_ENTITY_EMAIL_ADDRESS_FIELD_VIEW')}</label>
                            <span className={classes.textExtended}>{_.get(selectedEntity, 'email', '')}</span>
                        </div>

                        {/* PASSPORTS*/}

                        <hr className={`${classes.Separator} `} />
                        <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                            <label>Current Passport</label>
                        </div>
                        {_.get(selectedEntity, 'passports', []).map(passp => {
                            if (passp.current) {
                                return (<React.Fragment>
                                    <div className={`${classes.ContainerRow}`}>
                                        <label>{helper.getTranslateTextByKey('KYC_NATIONALITY_FIELD_VIEW')}</label>
                                        <span>{renderCountryName(_.get(passp, 'nationality', ''))}</span>
                                    </div>
                                    <div className={`${classes.ContainerRow}`}>
                                        <label>{helper.getTranslateTextByKey('KYC_PASSPORT_NUMBER_FIELD_VIEW')}</label>
                                        <span className={classes.textExtended}>{_.get(passp, 'passportNumber', '')}</span>
                                    </div>
                                    <div className={`${classes.ContainerRow}`}>
                                        <label>{helper.getTranslateTextByKey('KYC_PASSPORT_EXPIRY_DATE_FIELD_VIEW')}:</label>
                                        <span>{_.get(passp, 'passportExpiryDate', '')}</span>
                                    </div>
                                    <div className={`${classes.ContainerRow}`}>
                                        <label>Passport:</label>
                                        <span className={classes.docButton} onClick={() => { downloadHandler(_.get(passp, 'passportDoc', '')) }}>Passport Image</span>
                                    </div>
                                    {_.get(passp, 'lastPagePassportDoc', '') ? <div className={`${classes.ContainerRow}`}>
                                        <label>Last Page of Passport:</label>
                                        <span className={classes.docButton} onClick={() => { downloadHandler(_.get(passp, 'lastPagePassportDoc', '')) }}>Passport Last Page</span>
                                    </div> : null}
                                    {_.get(passp, 'UAEPassportStampDoc', '') ? <div className={`${classes.ContainerRow}`}>
                                        <label>UAE Passport Stamp:</label>
                                        <span className={classes.docButton} onClick={() => { downloadHandler(_.get(passp, 'UAEPassportStampDoc', '')) }}>UAE Stamp</span>
                                    </div> : null}
                                    {_.get(passp, 'UIDDoc', '') ? <div className={`${classes.ContainerRow}`}>
                                        <label>UID:</label>
                                        <span className={classes.docButton} onClick={() => { downloadHandler(_.get(passp, 'UIDDoc', '')) }}>UID</span>
                                    </div> : null}
                                </React.Fragment>)
                            }
                        })}
                        {_.find(_.get(selectedEntity, 'passports', []), passp => {
                            return !passp.current
                        }) ? (<React.Fragment> <hr className={`${classes.Separator} `} />
                            <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                                <label>Other Passports</label>
                            </div> </React.Fragment>) : null}
                        {_.get(selectedEntity, 'passports', []).map(passp => {
                            if (!passp.current) {
                                return (<React.Fragment>
                                    <div className={`${classes.ContainerRow}`}>
                                        <label>{helper.getTranslateTextByKey('KYC_NATIONALITY_FIELD_VIEW')}:</label>
                                        <span>{renderCountryName(_.get(passp, 'nationality', ''))}</span>
                                    </div>
                                    <div className={`${classes.ContainerRow}`}>
                                        <label>{helper.getTranslateTextByKey('KYC_PASSPORT_NUMBER_FIELD_VIEW')}:</label>
                                        <span>{_.get(passp, 'passportNumber', '')}</span>
                                    </div>
                                    <div className={`${classes.ContainerRow}`}>
                                        <label>{helper.getTranslateTextByKey('KYC_PASSPORT_EXPIRY_DATE_FIELD_VIEW')}:</label>
                                        <span>{_.get(passp, 'passportExpiryDate', '')}</span>
                                    </div>
                                    <div className={`${classes.ContainerRow}`}>
                                        <label>Passport:</label>
                                        <span className={classes.docButton} onClick={() => { downloadHandler(_.get(passp, 'passportDoc', '')) }}>Passport Image</span>
                                    </div>
                                    {_.get(passp, 'lastPagePassportDoc', '') ? <div className={`${classes.ContainerRow}`}>
                                        <label>Last Page of Passport:</label>
                                        <span className={classes.docButton} onClick={() => { downloadHandler(_.get(passp, 'lastPagePassportDoc', '')) }}>Passport Last Page</span>
                                    </div> : null}
                                    {_.get(passp, 'UAEPassportStampDoc', '') ? <div className={`${classes.ContainerRow}`}>
                                        <label>UAE Passport Stamp:</label>
                                        <span className={classes.docButton} onClick={() => { downloadHandler(_.get(passp, 'UAEPassportStampDoc', '')) }}>UAE Stamp</span>
                                    </div> : null}
                                    {_.get(passp, 'UIDDoc', '') ? <div className={`${classes.ContainerRow}`}>
                                        <label>UID:</label>
                                        <span className={classes.docButton} onClick={() => { downloadHandler(_.get(passp, 'UIDDoc', '')) }}>UID</span>
                                    </div> : null}
                                </React.Fragment>)
                            }

                        })}
                        {/*CV */}
                        {selectedEntity.curriculumVitaeDoc &&(<>
                            <hr className={`${classes.Separator} `} />
                            <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                            <label>Curriculum Vitae</label>
                            </div>
                            <div className={`${classes.ContainerRow}`}>
                                <label>{helper.getTranslateTextByKey('KYC_CURRICULUM_VITAE_FIELD_VIEW')}:</label>
                                <span className={classes.docButton} onClick={() => { downloadHandler(_.get(selectedEntity, 'curriculumVitaeDoc', '')) }}>CV File</span>
                            </div>
                           </> )
                        }
                        {/* PEP */}

                        <hr className={`${classes.Separator} `} />
                        <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                            <label>{helper.getTranslateTextByKey('KYC_ENTITY_POLITICALLY_EXPOSED__FIELD_VIEW')}</label>
                        </div>
                        <hr className={`${classes.Separator} `} />
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_ENTITY_POLITICALLY_EXPOSED_FIELD_VIEW')}:</label>
                            <span>{_.get(selectedEntity, 'politicallyExposedPerson', '') ? 'Yes' : 'No'}</span>
                        </div>
                        {_.get(selectedEntity, 'politicallyExposedPerson', '') ? <div className={`${classes.ContainerRow}`}>
                            <label>    </label>
                            <span className={classes.textExtended}>{_.get(selectedEntity, 'politicallyExposedPersonDescription', '')}</span>
                        </div> : null}
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_ENTITY_FAMILY_POLITICALLY_EXPOSED_FIELD_VIEW')}:</label>
                            <span>{_.get(selectedEntity, 'familyMemberOfAPEP', '') ? 'Yes' : 'No'}</span>
                        </div>
                        {_.get(selectedEntity, 'familyMemberOfAPEP', '') ? <div className={`${classes.ContainerRow}`}>
                            <label>    </label>
                            <span className={classes.textExtended}>{_.get(selectedEntity, 'familyMemberOfAPEPDescription', '')}</span>
                        </div> : null}
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_ENTITY_ASSOCIATE_POLITICALLY_EXPOSED_FIELD_VIEW')}:</label>
                            <span>{_.get(selectedEntity, 'closeAssociateOfAPEP', '') ? 'Yes' : 'No'}</span>
                        </div>
                        {_.get(selectedEntity, 'closeAssociateOfAPEP', '') ? <div className={`${classes.ContainerRow}`}>
                            <label>    </label>
                            <span className={classes.textExtended}>{_.get(selectedEntity, 'closeAssociateOfAPEPDescription', '')}</span>
                        </div> : null}
                        <hr className={`${classes.Separator} `} />
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_ENTITY_SOURCE_WEALTH_FIELD_VIEW')}:</label>
                            <span>{_.get(selectedEntity, 'sourcesOfWealth', '')}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_ENTITY_SOURCE_FUNDS_FIELD_VIEW')}:</label>
                            <span className={classes.textExtended}>{_.get(selectedEntity, 'sourceOfFunds', '')}</span>
                        </div>
                        <hr className={`${classes.Separator} `} />


                    </React.Fragment>
                )
            } else if (mode == 'ENTITY') {
                return (
                    <React.Fragment>
                        {/* COMPANY DETAILS */}
                        {/*  */}
                        <hr className={`${classes.Separator} `} />
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_COMPANY_NAME_FIELD_VIEW')}:</label>
                            <span>{_.get(selectedEntity, 'companyName', '')}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_COMPANY_ALTERNATIVE_NAME_FIELD_VIEW')}:</label>
                            <span>{_.get(selectedEntity, 'otherTradingName', '')}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_COMPANY_CRN_NUMBER_FIELD_VIEW')}:</label>
                            <span>{_.get(selectedEntity, 'registrationNumber', '')}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_COMPANY_TRN_NUMBER_FIELD_VIEW')}:</label>
                            <span>{_.get(selectedEntity, 'TRNNumber', '')}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_COMPANY_COUNTRY_INCORPORATION_FIELD_VIEW')}:</label>
                            <span>{_.get(selectedEntity, 'countryOfIncorporation.name', '')}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_COMPANY_JURISDICTION_INCORPORATION_FIELD_VIEW')}:</label>
                            <span style={{ "textFormat": "lowercase" }}>{_.get(selectedEntity, 'jurisdictionOfIncorporation', '')} </span>
                        </div>
                        {_.get(selectedEntity, 'VATRegistrationCertificateDoc', null) ? <div className={`${classes.ContainerRow}`}>
                            <label>{'       '}</label>
                            <span className={`${classes.docButton} ${classes.textExtended}`} onClick={() => { downloadHandler(_.get(selectedEntity, 'VATRegistrationCertificateDoc', '')) }}>VAT Registration Certificate</span>
                        </div> : null}
                        {/* REGISTERED ADDRESS */}
                        {/*  */}
                        <hr className={`${classes.Separator} `} />
                        <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                            <label>{helper.getTranslateTextByKey('KYC_REGISTERED_ADDRESS_FIELD_VIEW')}</label>
                        </div>
                        <hr className={`${classes.Separator} `} />
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_REGISTERED_ADDRESS_FIELD_VIEW')}:</label>
                            <span>{renderCountryName(_.get(selectedEntity, 'principalAddress.country', ''))},<br />{_.get(selectedEntity, 'principalAddress.state', '')},<br />{_.get(selectedEntity, 'principalAddress.city', '')},<br />{_.get(selectedEntity, 'principalAddress.address', '')},<br />{_.get(selectedEntity, 'principalAddress.zipCode', '')}<br /></span>
                        </div>
                        {_.get(selectedEntity, 'proofOfAddressDoc', null) ? <div className={`${classes.ContainerRow}`}>
                            <label>{'       '}</label>
                            <span className={`${classes.docButton} ${classes.textExtended}`} onClick={() => { downloadHandler(_.get(selectedEntity, 'proofOfAddressDoc', '')) }}>Proof Of Address</span>
                        </div> : null}
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_COMPANY_PRINCIPAL_BUSINESS_PLACE_FIELD_VIEW')}:</label>
                            <span>{_.get(selectedEntity, 'samePrincipalPlaceOfBusinessAndLegal', '') ? 'Yes' : 'No'}</span>
                        </div>
                        {!_.get(selectedEntity, 'samePrincipalPlaceOfBusinessAndLegal', '') ?
                            <div className={`${classes.ContainerRow}`}>
                                <label>{helper.getTranslateTextByKey('KYC_COMPANY_OTHER_ADDRESS_FIELD_VIEW')}:</label>
                                <span>{renderCountryName(_.get(selectedEntity, 'address.country', ''))},<br />{_.get(selectedEntity, 'address.state', '')},<br />{_.get(selectedEntity, 'address.city', '')},<br />{_.get(selectedEntity, 'address.address', '')},<br />{_.get(selectedEntity, 'address.zipCode', '')}<br /></span>
                            </div> : null}
                        {/* DIRECTORS */}
                        {/*  */}
                        {!_.get(selectedEntity, 'directors', []) ? null : <React.Fragment>
                            <hr className={`${classes.Separator} `} />
                            <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                                <label>{helper.getTranslateTextByKey('KYC_ENTITY_DIRECTORS_MANAGERS_FIELD_VIEW')}</label>
                            </div>
                            {_.get(selectedEntity.directors, 'individuals', []).map(ind => {
                                return renderDirectors(ind)
                            })}
                            {_.get(selectedEntity.directors, 'companies', []).map(comp => {
                                return renderDirectors(comp)
                            })}</React.Fragment>}


                        {/* SHAREHOLDERS */}
                        {/*  */}
                        {mode !== 'ENTITY' ? null : <React.Fragment><hr className={`${classes.Separator} `} />
                            <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                                <label>{helper.getTranslateTextByKey('QUESTIONARY_SHAREHOLDER_DIRECTORS_TEXT_SHAREHOLDERS')}</label>
                            </div>
                            {_.get(selectedEntity.shareholders, 'individuals', []).map(ind => {
                                return renderDirectors(ind)
                            })}
                            {_.get(selectedEntity.shareholders, 'companies', []).map(comp => {
                                return renderDirectors(comp)
                            })}</React.Fragment>}

                        {/* SIGNATORY DETAILS */}
                        {/*  */}
                        <hr className={`${classes.Separator} `} />
                        <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                            <label>{helper.getTranslateTextByKey('KYC_SIGNATORY_DETAILS_TITLE')}</label>
                        </div>
                        <hr className={`${classes.Separator} `} />
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('USER_TABLE_FIELDS_TITLE_FIRSTNAME')}:</label>
                            <span>{_.get(selectedEntity.signatory, 'firstName', '')}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_SURNAME_FIELD_VIEW')}:</label>
                            <span>{_.get(selectedEntity.signatory, 'lastName', '')}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_ENTITY_JOB_TITLE_FIELD_VIEW')}:</label>
                            <span>{_.get(selectedEntity.signatory, 'jobTitle', '')}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_ENTITY_EMAIL_ADDRESS_FIELD_VIEW')}:</label>
                            <span className={classes.textExtended}>{_.get(selectedEntity.signatory, 'email', '')}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_NATIONALITY_FIELD_VIEW')}:</label>
                            <span className={classes.textExtended}>{renderCountryName(_.get(selectedEntity.signatory, 'nationality', ''))}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_PASSPORT_NUMBER_FIELD_VIEW')}:</label>
                            <span className={classes.textExtended}>{_.get(selectedEntity.signatory, 'passportNumber', '')}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_PASSPORT_EXPIRY_DATE_FIELD_VIEW')}:</label>
                            <span>{moment(_.get(selectedEntity.signatory, 'passportExpiryDate', '')).format("DD-MMM-YYYY").toUpperCase().replace(/-/g, ' ')}</span>
                        </div>
                        {_.get(selectedEntity.signatory, 'signatoryPassportDoc', null) ? <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_PASSPORT_DOC_FIELD_VIEW')}:</label>
                            <span className={classes.docButton} onClick={() => { downloadHandler(_.get(selectedEntity.signatory, 'signatoryPassportDoc', '')) }}>Passport Image</span>
                        </div> : null}
                        <hr className={`${classes.Separator} `} />

                        {selectedEntity ? (selectedEntity.clientKeyContact ?
                            (<React.Fragment>
                                <hr className={`${classes.Separator} `} />
                                <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                                    <label>{helper.getTranslateTextByKey('KYC_KEY_CLIENT_CONTRACT_TITLE')}</label>
                                </div>
                                <hr className={`${classes.Separator} `} />
                                <div className={`${classes.ContainerRow}`}>
                                    <label>{helper.getTranslateTextByKey('KYC_CONTACT_FULL_NAME')}:</label>
                                    <span>{selectedEntity.clientKeyContact.fullName}</span>
                                </div>
                                <div className={`${classes.ContainerRow}`}>
                                    <label>{helper.getTranslateTextByKey('KYC_CONTACT_PHONE_NUMBER')}:</label>
                                    <span>{selectedEntity.clientKeyContact.contactNumber.prefix + '-' + selectedEntity.clientKeyContact.contactNumber.number + '-' + renderEmptyInsteadNull(selectedEntity.clientKeyContact.contactNumber.extension)}</span>
                                </div>
                                <div className={`${classes.ContainerRow}`}>
                                    <label>{helper.getTranslateTextByKey('KYC_CONTACT_EMAIL_ADDRESS_FIELD_VIEW')}:</label>
                                    <span className={classes.textExtended}>{selectedEntity.clientKeyContact.email}</span>
                                </div>
                                <hr className={`${classes.Separator} `} />
                            </React.Fragment>)
                            : null) : null}
                        {selectedEntity ? (selectedEntity.financeContact ? (<React.Fragment>
                            <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                                <label>{helper.getTranslateTextByKey('KYC_FINANCE_CLIENT_CONTRACT_TITLE')}</label>
                            </div>
                            <hr className={`${classes.Separator} `} />
                            <div className={`${classes.ContainerRow}`}>
                                <label>{helper.getTranslateTextByKey('KYC_CONTACT_FULL_NAME')}:</label>
                                <span>{selectedEntity.financeContact.fullName}</span>
                            </div>
                            <div className={`${classes.ContainerRow}`}>
                                <label>{helper.getTranslateTextByKey('KYC_CONTACT_PHONE_NUMBER')}:</label>
                                <span>{selectedEntity.financeContact.contactNumber.prefix + '-' + selectedEntity.financeContact.contactNumber.number + '-' + renderEmptyInsteadNull(selectedEntity.financeContact.contactNumber.extension)}</span>
                            </div>
                            <div className={`${classes.ContainerRow}`}>
                                <label>{helper.getTranslateTextByKey('KYC_CONTACT_EMAIL_ADDRESS_FIELD_VIEW')}:</label>
                                <span className={classes.textExtended}>{selectedEntity.financeContact.email}</span>
                            </div>
                            <hr className={`${classes.Separator} `} />
                        </React.Fragment>) : null) : null}
                        {/* KEY COMPANY DOCUMENTS*/}
                        <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                            <label>{helper.getTranslateTextByKey('KYC_KEY_DOCS_TITLE')}</label>
                        </div>
                        <hr className={`${classes.Separator} `} />
                        {
                            _.get(selectedEntity, 'showingDirectorsRegistrationDoc', null) ? <div className={`${classes.ContainerRow}`}>
                                <label>{helper.getTranslateTextByKey('KYC_DOC_SHOW_DIRECTOR_FIELD')}:</label>
                                <span className={classes.docButton} onClick={() => { downloadHandler(_.get(selectedEntity, 'showingDirectorsRegistrationDoc', '')) }}>Directors Register</span>
                            </div> : null
                        }
                        {
                            _.get(selectedEntity, 'showingShareholdersRegistrationDoc', null) ? <div className={`${classes.ContainerRow}`}>
                                <label>{helper.getTranslateTextByKey('KYC_DOC_SHOW_SHARE_FIELD')}:</label>
                                <span className={classes.docButton} onClick={() => { downloadHandler(_.get(selectedEntity, 'showingShareholdersRegistrationDoc', '')) }}>Shareholders Register</span>
                            </div> : null
                        }
                        {
                            _.get(selectedEntity, 'certificateOfIncorporationOrCommercialLicenseDoc', null) ? <div className={`${classes.ContainerRow}`}>
                                <label>{helper.getTranslateTextByKey('KYC_CERTIFICATE_COMERCIAL_LICENSE_FIELD')}:</label>
                                <span className={classes.docButton} onClick={() => { downloadHandler(_.get(selectedEntity, 'certificateOfIncorporationOrCommercialLicenseDoc', '')) }}>Commercial Licence</span>
                            </div> : null
                        }
                        {
                            _.get(selectedEntity, 'memorandumAndArticlesOfAssociationDoc', null) ? <div className={`${classes.ContainerRow}`}>
                                <label>{helper.getTranslateTextByKey('KYC_MEMORANDUM_FIELD')}:</label>
                                <span className={classes.docButton} onClick={() => { downloadHandler(_.get(selectedEntity, 'memorandumAndArticlesOfAssociationDoc', '')) }}>Memorandum</span>
                            </div> : null
                        }
                        {/* SOURCE OF WEALTH */}
                        {/*  */}
                        <hr className={`${classes.Separator} `} />
                        <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                            <label>{helper.getTranslateTextByKey('KYC_ENTITY_SOURCE_WEALTH_FIELD_VIEW')}</label>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_ENTITY_SOURCE_FUNDS_FIELD_VIEW')}:</label>
                            <span>{_.get(selectedEntity, 'sourceOfFunds', '')}</span>
                        </div>


                    </React.Fragment>)
            } else {
                return (
                    <React.Fragment>
                        {/* COMPANY DETAILS */}
                        {/*  */}
                        <hr className={`${classes.Separator} `} />
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_COMPANY_NAME_FIELD_VIEW')}:</label>
                            <span>{_.get(selectedEntity, 'companyName', '')}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_COMPANY_ALTERNATIVE_NAME_FIELD_VIEW')}:</label>
                            <span>{_.get(selectedEntity, 'otherTradingName', '')}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_COMPANY_CRN_NUMBER_FIELD_VIEW')}:</label>
                            <span>{_.get(selectedEntity, 'registrationNumber', '')}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_COMPANY_TRN_NUMBER_FIELD_VIEW')}:</label>
                            <span>{_.get(selectedEntity, 'TRNNumber', '')}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_COMPANY_COUNTRY_INCORPORATION_FIELD_VIEW')}:</label>
                            <span>{_.get(selectedEntity, 'countryOfIncorporation.name', '')}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_COMPANY_JURISDICTION_INCORPORATION_FIELD_VIEW')}:</label>
                            <span style={{ "textFormat": "lowercase" }}>{_.get(selectedEntity, 'jurisdictionOfIncorporation', '')} </span>
                        </div>
                        {_.get(selectedEntity, 'VATRegistrationCertificateDoc', null) ? <div className={`${classes.ContainerRow}`}>
                            <label>{'       '}</label>
                            <span className={`${classes.docButton} ${classes.textExtended}`} onClick={() => { downloadHandler(_.get(selectedEntity, 'VATRegistrationCertificateDoc', '')) }}>VAT Registration Certificate</span>
                        </div> : null}
                        {/* REGISTERED ADDRESS */}
                        {/*  */}
                        <hr className={`${classes.Separator} `} />
                        <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                            <label>{helper.getTranslateTextByKey('KYC_REGISTERED_ADDRESS_FIELD_VIEW')}</label>
                        </div>
                        <hr className={`${classes.Separator} `} />
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_REGISTERED_ADDRESS_FIELD_VIEW')}:</label>
                            <span>{renderCountryName(_.get(selectedEntity, 'principalAddress.country', ''))},<br />{_.get(selectedEntity, 'principalAddress.state', '')},<br />{_.get(selectedEntity, 'principalAddress.city', '')},<br />{_.get(selectedEntity, 'principalAddress.address', '')},<br />{_.get(selectedEntity, 'principalAddress.zipCode', '')}<br /></span>
                        </div>
                        {_.get(selectedEntity, 'proofOfAddressDoc', null) ? <div className={`${classes.ContainerRow}`}>
                            <label>{'       '}</label>
                            <span className={`${classes.docButton} ${classes.textExtended}`} onClick={() => { downloadHandler(_.get(selectedEntity, 'proofOfAddressDoc', '')) }}>Proof Of Address</span>
                        </div> : null}
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_COMPANY_PRINCIPAL_BUSINESS_PLACE_FIELD_VIEW')}:</label>
                            <span>{_.get(selectedEntity, 'samePrincipalPlaceOfBusinessAndLegal', '') ? 'Yes' : 'No'}</span>
                        </div>
                        {!_.get(selectedEntity, 'samePrincipalPlaceOfBusinessAndLegal', '') ?
                            <div className={`${classes.ContainerRow}`}>
                                <label>{helper.getTranslateTextByKey('KYC_COMPANY_OTHER_ADDRESS_FIELD_VIEW')}:</label>
                                <span>{renderCountryName(_.get(selectedEntity, 'address.country', ''))},<br />{_.get(selectedEntity, 'address.state', '')},<br />{_.get(selectedEntity, 'address.city', '')},<br />{_.get(selectedEntity, 'address.address', '')},<br />{_.get(selectedEntity, 'address.zipCode', '')}<br /></span>
                            </div> : null}
                        {_.get(selectedEntity, 'certificateOfIncorporationOrCommercialLicenseDoc', null) ? <div className={`${classes.ContainerRow}`}>
                            <label></label>
                            <span className={classes.docButton} onClick={() => { downloadHandler(_.get(selectedEntity, 'certificateOfIncorporationOrCommercialLicenseDoc', '')) }}>Certificate of Incorporation</span>
                        </div> : null}
                        {_.get(selectedEntity, 'memorandumAndArticlesOfASsociationDoc', null) ? <div className={`${classes.ContainerRow}`}>
                            <label>{'       '}</label>
                            <span className={`${classes.docButton} ${classes.textExtended}`} onClick={() => { downloadHandler(_.get(selectedEntity, 'memorandumAndArticlesOfASsociationDoc', '')) }}>Memorandum and Articles of Association</span>
                        </div> : null}
                        {/* SIGNATORY DETAILS */}
                        {/*  */}
                        <hr className={`${classes.Separator} `} />
                        <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                            <label>{helper.getTranslateTextByKey('KYC_SIGNATORY_DETAILS_TITLE')}</label>
                        </div>
                        <hr className={`${classes.Separator} `} />
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('USER_TABLE_FIELDS_TITLE_FIRSTNAME')}:</label>
                            <span>{_.get(selectedEntity.signatory, 'firstName', '')}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_SURNAME_FIELD_VIEW')}:</label>
                            <span>{_.get(selectedEntity.signatory, 'lastName', '')}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_ENTITY_JOB_TITLE_FIELD_VIEW')}:</label>
                            <span>{_.get(selectedEntity.signatory, 'jobTitle', '')}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_ENTITY_EMAIL_ADDRESS_FIELD_VIEW')}:</label>
                            <span className={classes.textExtended}>{_.get(selectedEntity.signatory, 'email', '')}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_NATIONALITY_FIELD_VIEW')}:</label>
                            <span className={classes.textExtended}>{renderCountryName(_.get(selectedEntity.signatory, 'nationality', ''))}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_PASSPORT_NUMBER_FIELD_VIEW')}:</label>
                            <span className={classes.textExtended}>{_.get(selectedEntity.signatory, 'passportNumber', '')}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_PASSPORT_EXPIRY_DATE_FIELD_VIEW')}:</label>
                            <span>{moment(_.get(selectedEntity.signatory, 'passportExpiryDate', '')).format("DD-MMM-YYYY").toUpperCase().replace(/-/g, ' ')}</span>
                        </div>
                        {_.get(selectedEntity.signatory, 'signatoryPassportDoc', null) ? <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_PASSPORT_DOC_FIELD_VIEW')}:</label>
                            <span className={classes.docButton} onClick={() => { downloadHandler(_.get(selectedEntity.signatory, 'signatoryPassportDoc', '')) }}>Passport Image</span>
                        </div> : null}
                        <hr className={`${classes.Separator} `} />

                        {/* KEY COMPANY DOCUMENTS*/}
                        <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                            <label>{helper.getTranslateTextByKey('KYC_KEY_DOCS_TITLE')}</label>
                        </div>
                        <hr className={`${classes.Separator} `} />
                        {_.get(selectedEntity, 'showingDirectorsRegistrationDoc', null) ? <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_DOC_SHOW_DIRECTOR_FIELD')}:</label>
                            <span className={classes.docButton} onClick={() => { downloadHandler(_.get(selectedEntity, 'showingDirectorsRegistrationDoc', '')) }}>Directors Register</span>
                        </div> : null}
                        {_.get(selectedEntity, 'showingShareholdersRegistrationDoc', null) ? <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_DOC_SHOW_SHARE_FIELD')}:</label>
                            <span className={classes.docButton} onClick={() => { downloadHandler(_.get(selectedEntity, 'showingShareholdersRegistrationDoc', '')) }}>Shareholders Register</span>
                        </div> : null}
                        {_.get(selectedEntity, 'certificateOfIncorporationOrCommercialLicenseDoc', null) ? <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_CERTIFICATE_COMERCIAL_LICENSE_FIELD')}:</label>
                            <span className={classes.docButton} onClick={() => { downloadHandler(_.get(selectedEntity, 'certificateOfIncorporationOrCommercialLicenseDoc', '')) }}>Commercial Licence</span>
                        </div> : null}
                        {_.get(selectedEntity, 'memorandumAndArticlesOfAssociationDoc', null) ? <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_MEMORANDUM_FIELD')}:</label>
                            <span className={classes.docButton} onClick={() => { downloadHandler(_.get(selectedEntity, 'memorandumAndArticlesOfAssociationDoc', '')) }}>Memorandum</span>
                        </div> : null}

                        {selectedEntity ? (selectedEntity.clientKeyContact ?
                            (<React.Fragment>
                                <hr className={`${classes.Separator} `} />
                                <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                                    <label>{helper.getTranslateTextByKey('KYC_KEY_CLIENT_CONTRACT_TITLE')}</label>
                                </div>
                                <hr className={`${classes.Separator} `} />
                                <div className={`${classes.ContainerRow}`}>
                                    <label>{helper.getTranslateTextByKey('KYC_CONTACT_FULL_NAME')}:</label>
                                    <span>{selectedEntity.clientKeyContact.fullName}</span>
                                </div>
                                <div className={`${classes.ContainerRow}`}>
                                    <label>{helper.getTranslateTextByKey('KYC_CONTACT_PHONE_NUMBER')}:</label>
                                    <span>{selectedEntity.clientKeyContact.contactNumber.prefix + '-' + selectedEntity.clientKeyContact.contactNumber.number + '-' + renderEmptyInsteadNull(selectedEntity.clientKeyContact.contactNumber.extension)}</span>
                                </div>
                                <div className={`${classes.ContainerRow}`}>
                                    <label>{helper.getTranslateTextByKey('KYC_CONTACT_EMAIL_ADDRESS_FIELD_VIEW')}:</label>
                                    <span className={classes.textExtended}>{selectedEntity.clientKeyContact.email}</span>
                                </div>
                                <hr className={`${classes.Separator} `} />
                            </React.Fragment>)
                            : null) : null}
                        {selectedEntity ? (selectedEntity.financeContact ? (<React.Fragment>
                            <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                                <label>{helper.getTranslateTextByKey('KYC_FINANCE_CLIENT_CONTRACT_TITLE')}</label>
                            </div>
                            <hr className={`${classes.Separator} `} />
                            <div className={`${classes.ContainerRow}`}>
                                <label>{helper.getTranslateTextByKey('KYC_CONTACT_FULL_NAME')}:</label>
                                <span>{selectedEntity.financeContact.fullName}</span>
                            </div>
                            <div className={`${classes.ContainerRow}`}>
                                <label>{helper.getTranslateTextByKey('KYC_CONTACT_PHONE_NUMBER')}:</label>
                                <span>{selectedEntity.financeContact.contactNumber.prefix + '-' + selectedEntity.financeContact.contactNumber.number + '-' + renderEmptyInsteadNull(selectedEntity.financeContact.contactNumber.extension)}</span>
                            </div>
                            <div className={`${classes.ContainerRow}`}>
                                <label>{helper.getTranslateTextByKey('KYC_CONTACT_EMAIL_ADDRESS_FIELD_VIEW')}:</label>
                                <span className={classes.textExtended}>{selectedEntity.financeContact.email}</span>
                            </div>
                            <hr className={`${classes.Separator} `} />
                        </React.Fragment>) : null) : null}
                        {/* DIRECTORS */}

                        {!_.get(selectedEntity, 'directors', []) ? null : <React.Fragment>
                            <hr className={`${classes.Separator} `} />
                            <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                                <label>{helper.getTranslateTextByKey('KYC_ENTITY_DIRECTORS_MANAGERS_FIELD_VIEW')}</label>
                            </div>
                            {_.get(selectedEntity.directors, 'individuals', []).map(ind => {
                                return renderDirectors(ind)
                            })}
                            {_.get(selectedEntity.directors, 'companies', []).map(comp => {
                                return renderDirectors(comp)
                            })}</React.Fragment>}

                        {/* SOURCE OF WEALTH */}
                        {/*  */}
                        <hr className={`${classes.Separator} `} />
                        <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                            <label>{helper.getTranslateTextByKey('KYC_ENTITY_SOURCE_WEALTH_FIELD_VIEW')}</label>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>{helper.getTranslateTextByKey('KYC_ENTITY_SOURCE_FUNDS_FIELD_VIEW')}:</label>
                            <span>{_.get(selectedEntity, 'sourceOfFunds', '')}</span>
                        </div>

                    </React.Fragment>

                )
            }
        }
    };

    const getEditPath = () => {
        if (selectedEntity) {
            if (selectedEntity.type === Constants.ENTITY_TYPES.COMPANY && mode !== 'ENTITY') {
                return Constants.PAGES.editCompany
            } else if (selectedEntity.type === Constants.ENTITY_TYPES.INDIVIDUAL) {
                return Constants.PAGES.editIndividual
            } else if (mode === 'ENTITY') {
                return Constants.PAGES.editEntity
            }
        }
    };


    let pathToEdit = getEditPath();

    useEffect(() => {
        if (!selectedEntity) {
            getPrimary()
        }
    }, [selectedEntity, primary, mode, totalIndividuals, totalCompanies]);

    useEffect(() => {
        if (_.isEmpty(entities) && KYC && !isDraftData) {
            callServerToGetData();
        }
    }, [entities, JSON.stringify(KYC)]);


    const handleOpen = () => {
        setShowDelete(true)
    };

    const handleEngagement = () => {
        return dispatch({
            type: 'GO_TO', page: Constants.PAGES.proposalsAndEngagements, params: {
                matterId: props.data.id,
                tab: "kyc",
                typeForm: "ENGAGEMENT",
                mode: _.get(props, 'data.KYC.engagement.status') ? "EDIT" : "ADD"
            }
        })
    }

    const mutationIhavePaid = `
    mutation setAsPaidEngagement($matterId: ID!) {
        setAsPaidEngagement(matterId: $matterId) {
            id
            status
        }
    }`

    const handlePaidEngagement = () => {
        setLoading(true);
        ServerConnect.graphQlMutation(mutationIhavePaid, { matterId: props.data.id })
            .then(({ setAsPaidEngagement }) => {
                _.set(props.data, "KYC.engagement", setAsPaidEngagement);
                setKYC(props.data.KYC)
                setLoading(false);
            });
    }

    const handleDisabledMarkAsPaid = (status) => {
        return (status === 'PAID' || isDraftData) || _.get(props, 'data.state') === 'Closed';
    }


    if (!loadingKYC && !KYC) {
        return null

    }

    return (
        <React.Fragment>
            <Loading show={loading || (isDraftData && loadingKYC)} showChildren={true} loadingStyle={{ position: "fixed", zIndex: 1000 }}>
                {KYC ? (
                    <React.Fragment>
                        <div style={{ width: "1000px" }} className={classes.ContainerView}>
                            {/* BOTON */}

                            {user.userRole === Constants.PROFILE_ROLES.SERVICE_AGENT || user.userRole === Constants.PROFILE_ROLES.SERVICE_AGENT_MANAGER || user.userRole === Constants.PROFILE_ROLES.SENIOR_SERVICE_AGENT ?
                                <React.Fragment> <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                                    <label>Actions</label>
                                </div>
                                    <div className={`${classes.ContainerRow}`}>
                                        <div className={`${classes.botoneraActions}`}>
                                            <ModalMenu
												links={links}
												scoping={true}>
												<button
													disabled={(_.get(KYC, 'engagement.status', '') !== 'SENT' &&
														_.get(KYC, 'engagement.status', '') !== 'PAID') || _.get(props, 'data.state') === 'Closed'}
													onClick={() => openModal}
													className={` ${classes.buttonModalOnActions}`}>
													Questionnaire
												</button>
                                            </ModalMenu>

                                            <button
												disabled={(!_.get(data, 'KYC', null) || _.get(props, 'data.state') === 'Closed') || isDraftData}
												onClick={handleEngagement}
											>
												{_.get(props, 'data.KYC.engagement.status') ? "Resend" : "Create"} Engagement
                                            </button>
                                            {<button disabled={handleDisabledMarkAsPaid(_.get(props, 'data.KYC.engagement.status'))} onClick={handlePaidEngagement}>Mark as Paid</button>}
                                            {mode !== 'ENTITY' ? null :
                                                <React.Fragment>
                                                    <button disabled={isDraftData || _.get(props, 'data.state') === 'Closed'} className={classes.botoneraActions} onClick={() => { handleGoToEntityForm() }}>Add Entity</button>
                                                    {_.get(KYC, 'KYCType', '') === 'individual' ? <button disabled={isDraftData || _.get(props, 'data.state') === 'Closed'} className={classes.botoneraActions} onClick={() => { handleGoToIndividualForm() }}>Add Individual</button> : <ModalMenu links={getIndividualsLink(linksIndividual)} scoping={true}><button disabled={isDraftData} onClick={() => openModal} className={` ${classes.buttonModalOnActions}`}>Add Individual</button></ModalMenu>}
                                                    {_.get(KYC, 'KYCType', '') === 'individual' ? null : <ModalMenu links={getCompaniesLink(linksCompanies)} scoping={true}><button disabled={isDraftData || _.get(props, 'data.state') === 'Closed'} onClick={() => openModal} className={` ${classes.buttonModalOnActions}`}>Add Company</button></ModalMenu>}
                                                </React.Fragment>
                                            }
                                        </div>
                                    </div>
                                </React.Fragment> :
                                null}

                            <div className={classes.ContainerDataKYC}>

                                <div className={classes.pushedContainer}>
                                    <div className={`${classes.entitiesContainerKYC}`}>
                                        <hr className={classes.Separator} />
                                        <div className={classes.containerDraft}>
                                            {isDraftData && <span className={classes.draftLabel}>Draft KYC</span>}
                                            <label>Entities</label>
                                        </div>

                                        {!entities ? null : (
                                            entities.map(entity =>
                                                <React.Fragment>
                                                    <span className={`${classes.entityText} ${selectedClass(entity)}`} onClick={() => { setSelectedEntity(entity); setMode('ENTITY'); }}>{entity.type !== Constants.ENTITY_TYPES.COMPANY ? entity.firstName + ' ' + entity.lastName : entity.companyName}{primaryText(entity)}</span>
                                                    {_.get(KYC, 'KYCType', '') == 'individual' ? null : (<React.Fragment>{
                                                        entity.shareholders.individuals.map(ent =>
                                                            _.some(entity.directors.individuals, ent) ? null : <span className={`${classes.entityText} ${classes.subEntityText} ${selectedClass(ent)}`} onClick={() => { setSelectedEntity(ent); setMode('SHAREHOLDER') }}>{_.get(ent, 'type', '') !== Constants.ENTITY_TYPES.COMPANY ? _.get(ent, 'firstName', '') + ' ' + _.get(ent, 'lastName', '') : _.get(ent, 'companyName', '')}{primaryText(ent)}</span>

                                                        )
                                                    }
                                                        {
                                                            entity.shareholders.companies.map((ent, key) =>

                                                                _.some(entity.directors.companies, ent) ? null : <span className={`${classes.entityText} ${classes.subEntityText} ${selectedClass(ent)}`} key={key} onClick={() => { setSelectedEntity(ent); setMode('SHAREHOLDER') }}>{_.get(ent, 'type', '') !== Constants.ENTITY_TYPES.COMPANY ? _.get(ent, 'firstName', '') + ' ' + _.get(ent, 'lastName', '') : _.get(ent, 'companyName', '')}{primaryText(ent)}</span>


                                                            )
                                                        }
                                                        {
                                                            entity.directors.individuals.map(ent =>

                                                                <span className={`${classes.entityText} ${classes.subEntityText} ${selectedClass(ent)}`} onClick={() => { setSelectedEntity(ent); setMode('DIRECTOR') }}>{_.get(ent, 'type', '') !== Constants.ENTITY_TYPES.COMPANY ? _.get(ent, 'firstName', '') + ' ' + _.get(ent, 'lastName', '') : _.get(ent, 'companyName', '')}{primaryText(ent)}</span>

                                                            )
                                                        }
                                                        {
                                                            entity.directors.companies.map(ent =>

                                                                <span className={`${classes.entityText} ${classes.subEntityText} ${selectedClass(ent)}`} onClick={() => { setSelectedEntity(ent); setMode('DIRECTOR') }}>{_.get(ent, 'type', '') !== Constants.ENTITY_TYPES.COMPANY ? _.get(ent, 'firstName', '') + ' ' + _.get(ent, 'lastName', '') : _.get(ent, 'companyName', '')}{primaryText(ent)}</span>

                                                            )
                                                        }
                                                        {
                                                            entity.PEPs.map(ent =>

                                                                <span className={`${classes.entityText} ${classes.subEntityText} ${selectedClass(ent)}`} onClick={() => { setSelectedEntity(ent); setMode('SHAREHOLDER') }}>{_.get(ent, 'firstName', '') + ' ' + _.get(ent, 'lastName', '')}{primaryText(ent)}</span>


                                                            )
                                                        }</React.Fragment>)}

                                                </React.Fragment>
                                            )
                                        )}
                                    </div>

                                </div>

                                <div className={`${classes.containerViewKYC}`} >
                                    <div className={`${classes.containerTitleAndButtons}`}>
                                        {
                                            selectedEntity && selectedEntity.type == Constants.ENTITY_TYPES.INDIVIDUAL ?
                                                <label>{selectedEntity ? renderIndividualTitleByType() : null}</label> :
                                                < label > {selectedEntity ? renderCompaniesTitleByType() : null}</label>
                                        }
                                        < RoleAccess action={Constants.ACTIONS.MANAGE_MATTER}>
                                            {renderSetPrimaryOption()}
                                            {selectedEntity == primary ? null : <React.Fragment><button disabled={isDraftData} className={`${classes.buttonEditFloatKYC} ${classes.buttonRemoveKYC}`} onClick={() => { setShowDelete(true) }}    >
                                                <span>Remove</span></button>
                                                <DeleteEntityKYC
                                                    show={showDelete}
                                                    title={"Delete Entity"}
                                                    handleClose={() => { setShowDelete(false); }}
                                                    data={selectedEntity ? { entityId: selectedEntity.id, matterId: props.data.id, query: getQuery(), type: selectedEntity.type } : null}
                                                    callbacks={deleteHandler}
                                                />
                                            </React.Fragment>}
                                            <button className={`${classes.buttonEditFloatKYC} ${classes.buttonEditOnKYC}`}
                                                disabled={isDraftData}
                                                onClick={() => dispatch({
                                                    type: 'GO_TO', page: pathToEdit, params: {
                                                        id: selectedEntity ? selectedEntity.id : null,
                                                        matterId: selectedEntity ? props.data.id : null, type: mode
                                                    }
                                                })}>
                                                <span>Edit Details</span>
                                            </button>
                                        </RoleAccess>
                                    </div>

                                    {renderByType()}

                                </div>
                            </div>
                        </div >
                    </React.Fragment>) : null}

            </Loading >
        </React.Fragment >
    )
}

export default Kyc;

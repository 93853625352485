import { useEffect, useMemo, useState } from 'react';
import { Constants } from "./../../../v1/utils/constants";
import _ from "lodash";
import hash from "object-hash";
import helperLogicOrderMap from "./helperLogicOrderMap";
import useCompanyCorporateMap from "./services/useCompanyCorporateMap";
import useSession from "../../session/hooks/useSession";
import useTranslate from "./../../../modules/generic/hooks/useTranslate";

const useMapData = ({ maxLevels, filter }) => {

  const { session } = useSession();
  const startupId = _.get(session, "startup.id");
  const { translate } = useTranslate();

  const createVariables = useMemo(() => {
    let result;
    switch (filter) {
      case 1:
        result = "ACTIVE";
        break;
      case 2:
        result = "INACTIVE";
        break;
      case 3:
        result = "ALL";
        break;
      default:
        result = "ACTIVE";
        break;
    }
    return {
      startupId: startupId,
      filters: {
        status: result,
      }
    }
  }, [filter, startupId]);

  const {
    data: dataMap,
    error: errorMap,
    loading: loadingMap,
  } = useCompanyCorporateMap(createVariables);

  const { rolesNeedToShow, rolesNeedToShowButton, rolOrder } = useMemo(() => {
    let rolesNeedToShow = [];
    let rolesNeedToShowButton = [];
    let rolOrder = {};
    rolesNeedToShow = [
      Constants.ROLES_FROM_BACKEND.CONVERTIBLEHOLDER.id,
      Constants.ROLES_FROM_BACKEND.WARRANTHOLDER.id,
      Constants.ROLES_FROM_BACKEND.OPTIONHOLDER.id,
      Constants.ROLES_FROM_BACKEND.IPASSIGNOR.id,
      Constants.ROLES_FROM_BACKEND.AUTHORIZEDSIGNATORY.id,
      Constants.ROLES_FROM_BACKEND.UBO.id
    ];
    rolesNeedToShowButton = [
      Constants.ROLES_FROM_BACKEND.IPASSIGNOR.id,
      Constants.ROLES_FROM_BACKEND.AUTHORIZEDSIGNATORY.id,
      Constants.ROLES_FROM_BACKEND.CONVERTIBLEHOLDER.id,
      Constants.ROLES_FROM_BACKEND.WARRANTHOLDER.id,
      Constants.ROLES_FROM_BACKEND.OPTIONHOLDER.id,
    ];
    rolOrder = {
      [Constants.ROLES_FROM_BACKEND.FOUNDER.id]: 11,
      [Constants.ROLES_FROM_BACKEND.DIRECTOR.id]: 12,
      [Constants.ROLES_FROM_BACKEND.EMPLOYEE.id]: 10,
      [Constants.ROLES_FROM_BACKEND.ADVISOR.id]: 8,
      [Constants.ROLES_FROM_BACKEND.CONSULTANT.id]: 9,
      [Constants.ROLES_FROM_BACKEND.INVESTOR.id]: 7,
      [Constants.ROLES_FROM_BACKEND.SHAREHOLDER.id]: 13,
      [Constants.ROLES_FROM_BACKEND.CONVERTIBLEHOLDER.id]: 5,
      [Constants.ROLES_FROM_BACKEND.WARRANTHOLDER.id]: 4,
      [Constants.ROLES_FROM_BACKEND.OPTIONHOLDER.id]: 3,
      [Constants.ROLES_FROM_BACKEND.IPASSIGNOR.id]: 2,
      [Constants.ROLES_FROM_BACKEND.THIRDPARTY.id]: 6,
      [Constants.ROLES_FROM_BACKEND.AUTHORIZEDSIGNATORY.id]: 1,
      [Constants.ROLES_FROM_BACKEND.UBO.id]: 1,
    };
    return { rolesNeedToShow, rolesNeedToShowButton, rolOrder };
  }, []);

  const [stakeholders, setStakeholders] = useState({});
  const [roles, setRoles] = useState([]);
  const [groupCompanies, setGroupCompanies] = useState({});

  const getTasksPendings = (tasks) => {
    let taskUser = {};
    let count = 0;
    const taskOmit = ["TASK_CONSULTANT_AGREEMENT_GENERATED_SIGNED"];
    _.forEach(tasks, (task) => {
      if (!taskOmit.includes(task.code)) {
        const valueHash = hash(task);
        if (!taskUser[valueHash]) {
          taskUser[valueHash] = true;
          if (task.status === "PENDING" || task.status === "IN_PROGRESS") {
            count++;
          }
        }
      }
    });
    return count;
  };

  useEffect(() => {
    let stakeholders = {};
    let groupCompanies = {};
    let roles = {};
    if (dataMap) {
      let serverData = _.get(dataMap, "companyCorporateMap", []);
      _.forEach(serverData["stakeholders"], (data) => {
        let inactiveRoles = {};
        const listInactiveRoles = _.filter(data.roles, (role) => role.isActive === false);
        if (!_.isEmpty(listInactiveRoles)) {
          _.forEach(listInactiveRoles, (role) => {
            const roleText = role?.name;
            const entityId = role?.entity?.id;
            if (!inactiveRoles[roleText]) {
              inactiveRoles[roleText] = {};
            }
            inactiveRoles[roleText][entityId] = true;
          });
        }
        if (!stakeholders[data.id]) {
          stakeholders[data.id] = {
            id: data.id,
            fullName: data.fullName,
            isAnEntity: data.isAnEntity,
            avatar: data.avatar,
            createdAt: data.createdAt,
            roles: {},
            inactiveRoles: inactiveRoles,
            rolOrder: 0,
            rolesInProgress: data.rolesInProgress,
            tasks: data.pendingTasksCount,
            startDate: data.startDate,
            endDate: data.endDate,
            isLocked: data.isLocked,
            isFounder: data.isFounder,
            isActive: data.isActive,
          };
          if (stakeholders[data.id].tasks > 99) stakeholders[data.id].tasks = 99;
        }
      });
      let rolesOrder = [..._.get(serverData, "roles", [])];
      rolesOrder.sort((a, b) => {
        const aId = _.get(a, "id");
        const bId = _.get(b, "id");
        if (rolOrder[aId] < rolOrder[bId]) return 1;
        if (rolOrder[aId] === rolOrder[bId]) return 0;
        return -1;
      });
      _.forEach(rolesOrder, (rol) => {
        roles[rol.id] = {
          id: rol.id,
          label: translate(`MODULES.MAP.ROLES_LABEL_PLURAL.${rol.id}`, {}, rol.id),
          count: 0,
          showEmpty: !rolesNeedToShow.includes(rol.id),
          showButton: !rolesNeedToShowButton.includes(rol.id),
        };
      });
      _.forEach(serverData["companies"], (data) => {
        if (!groupCompanies[data.id]) {
          groupCompanies[data.id] = {
            id: data.id,
            name: data.name,
            kind: data.kind,
            jurisdiction: data.jurisdiction,
            avatar: data.avatar,
            isHoldingCompany: data.isHoldingCompany,
            level: data.level,
            roles: {},
            childrens: [],
            tasks: data.pendingTasksCount,
            incorporation_date: data.incorporation_date,
            dissolution_date: data.dissolution_date,
            isLocked: data.isLocked,
            managedByClara: data.managedByClara,
            isActive: data.isActive,
          };
          const concatGroups = _.concat(data.equity);
          _.forEach(concatGroups, (group) => {
            const pendingTasks = getTasksPendings(_.get(group, "tasks", []));
            groupCompanies[data.id].tasks = groupCompanies[data.id].tasks + pendingTasks;
          });
          if (groupCompanies[data.id].tasks > 99) groupCompanies[data.id].tasks = 99;
          _.forEach(data.children, (children) => {
            if (!serverData["shortCircuit"]) {
              groupCompanies[data.id].childrens.push({
                id: children.id,
                percent: children.percentage,
              });
            }
          });
          _.forEach(data.stakeholders, (stakeholder) => {
            const rolData = stakeholder.role.id;
            if (!groupCompanies[data.id].roles[rolData]) {
              groupCompanies[data.id].roles[rolData] = {};
            }
            if (!groupCompanies[data.id].roles[rolData][stakeholder.id]) {
              groupCompanies[data.id].roles[rolData][stakeholder.id] = true;
            }
            if (stakeholders[stakeholder.id]) {
              if (!stakeholders[stakeholder.id].roles[rolData]) {
                stakeholders[stakeholder.id].roles[rolData] = {};
              }
              if (!stakeholders[stakeholder.id].roles[rolData][data.id]) {
                stakeholders[stakeholder.id].roles[rolData][data.id] = true;
              }
            }
            if (roles[rolData]) {
              roles[rolData].count++;
            }
            if (stakeholders[stakeholder.id] !== undefined) {
              stakeholders[stakeholder.id].rolOrder = stakeholders[stakeholder.id].rolOrder < rolOrder[rolData] ? rolOrder[rolData] : stakeholders[stakeholder.id].rolOrder;
            }
          });
        }
      });
    }
    let newGroupCompanies = {};
    if (!_.isEmpty(groupCompanies)) {
      const orderGroupsCompanies = helperLogicOrderMap(groupCompanies);
      const addGroupCompanies = (gc, id) => {
        if (!newGroupCompanies[id]) {
          newGroupCompanies[id] = {};
        }
        newGroupCompanies[id] = groupCompanies[id];
      };
      _.forEach(orderGroupsCompanies, (gc, id) => {
        addGroupCompanies(gc, id);
      });
    }
    setGroupCompanies(newGroupCompanies);
    _.forEach(stakeholders, (stakeholder) => {
      if (stakeholder.isFounder) {
        if (!stakeholder.roles["FOUNDER"]) {
          stakeholder.roles["FOUNDER"] = {};
        }
        roles["FOUNDER"].count += 1;
        _.forEach(newGroupCompanies, (gc, id) => {
          stakeholder.roles["FOUNDER"][id] = true;
          if (!newGroupCompanies[id].roles["FOUNDER"]) {
            newGroupCompanies[id].roles["FOUNDER"] = {};
          }
          newGroupCompanies[id].roles["FOUNDER"][stakeholder.id] = true
        });
      }
      if (!_.isEmpty(newGroupCompanies)) {
        _.forEach(stakeholder?.rolesInProgress?.toAdd ?? [], (role) => {
          if (!stakeholder.roles[role?.name]) {
            stakeholder.roles[role?.name] = {};
          }
          if (!newGroupCompanies[role?.entity?.id].roles[role?.name]) {
            newGroupCompanies[role?.entity?.id].roles[role?.name] = {}
          }
          newGroupCompanies[role?.entity?.id].roles[role?.name][stakeholder.id] = true
          stakeholder.roles[role?.name][role?.entity?.id] = true;
          if (roles[role?.name]) {
            roles[role?.name].count += 1;
          }
        });
      }
    });
    setRoles(roles);
    setStakeholders(stakeholders);
  }, [JSON.stringify(dataMap)]);

  return {
    stakeholders,
    roles,
    groupCompanies,
    loading: loadingMap,
  };
};
export default useMapData;

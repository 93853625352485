import useMutation from '../../../../../../../hooks/custom/useMutation'
import useSession from "src/modules/session/hooks/useSession";


export const UPSERT_SET_COMPLETE_STAKEHOLDERS = `
  mutation updateAcceleratorEvent($startupId: ID, $eventType: AcceleratorEventEnum, $groupCompanyId: ID) {
    updateAcceleratorEvent(startupId: $startupId, eventType: $eventType, groupCompanyId: $groupCompanyId) {
      acceleratorData {
        topCoSelected
        allStakeholdersAdded
        allQuestionsComplete
      }
    }
  }
`;

const useCompleteStakeholders
 = (props = {}) => {
  const { startupId } = useSession();
  const [mutation] = useMutation(
    UPSERT_SET_COMPLETE_STAKEHOLDERS, {},
    { showSuccessNotification: false, ...props, variables:{startupId, eventType: "ALL_STAKEHOLDER_ADDED"} },
  )
  return {mutation}
}

export default useCompleteStakeholders
import React from 'react'
import * as Yup from 'yup'
import Form from '../../../../../../components/forms/Form/Form'
import ControllerInput from '../../../../../generic/components/Inputs/ReactHookForm/ControllerInput'
import TemplateLabel from '../../../../../generic/templates/Label'
import SubmitButton from '../../../../../generic/components/Buttons/SubmitButton'
import FormProps from '../../../../../generic/forms/interfaces/FormProps'
import TemplateFormDefault from '../../../../../generic/templates/Modal'
import cls from './classes.module.scss'
import CalendarInputDate from '../../../../../../components/inputs/Calendars/CalendarInputDate'
import TextInput from '../../../../../../components/inputs/Text/TextInput'
import CountrySelector from '../../../../../../components/inputs/Select/CountrySelector/CountrySelector'
import moment from 'moment'
import { Constants } from "../../../../../../v1/utils";

const filesSchema = {
  image_pdf: Yup.object().shape({
    files: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().matches(/(.jpg)|(.png)|(.jpeg)$/, 'Invalid file type').transform((current, original) => {
          return original.replace(/(\.[^.]+)$/, (match) => match.toLowerCase());
        })
      })
    ).min(1),
  }),
  all: Yup.object().shape({
    files: Yup.array().of(
      Yup.object().shape({
        name: Yup.string(),
      })
    ).min(1),
  })
}

const schema = Yup.object().shape({
  nationality: Yup.string().nullable().required("This Field Required"),
  number: Yup.string().required("This Field Required").nullable(),
  expiryDate: Yup.string().test({
      test: (expiryDate) => {
        if (!expiryDate) {
          return true;
        }
        return moment(expiryDate, 'YYYY-MM-DD').isAfter(moment(), 'day');
      },
      message: 'Expiration date must be greater than today',
    }).nullable().required('This Field Required')
})

const schema2 = Yup.object().shape({
  nationality: Yup.string().nullable().required("This Field Required"),
  number: Yup.string().required("This Field Required").nullable(),
  expiryDate: Yup.string().nullable().matches(Constants.REGEXP.DATE, {
    message: 'This field is invalid',
    excludeEmptyString: true,
  }).required('This Field Required')
})
interface FormPropsCustom extends FormProps {
  text?: string
  image?: any
  isOldPassport?:boolean
}

const FormPassportData: React.FC<FormPropsCustom> = ({
  initialValues = {},
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  image,
  propsTemplate,
  isOldPassport
}) => {

  // const { translate } = useTranslate()

  const handleSubmit = async values => {
    onCompleteSubmit && (await onCompleteSubmit(values))
  }
  
  const getSchema = () =>{
    const def = propsTemplate?.fileUploadProps?.accept? filesSchema[propsTemplate?.fileUploadProps?.accept] : Yup.object().shape({})  
    if(isOldPassport) return def.concat(schema2)
    return def.concat(schema)
  }

  return (
    <Form
      defaultValues={initialValues}
      schema={getSchema()}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
      onlySendDirty={false}
    >
      <Template
        buttons={{
          ...buttons,
          submit: <SubmitButton {...buttonSubmitProps}>Add</SubmitButton>
        }}
        props={propsTemplate}
      >
        <TemplateLabel
          label={'Nationality'}
          className={cls.TemplateLabelMin}
          isRequired
        >
          <ControllerInput
            render={CountrySelector}
            placeholder={"Nationality"}
            name='nationality'
            expandDouble={true}
          />
        </TemplateLabel>
        <TemplateLabel
          label={'Passport number'}
          className={cls.TemplateLabelMin}
          isRequired
        >
          <ControllerInput
            placeholder={"Passport number"}
            render={TextInput}
            name='number'
            defaultValue={null}
          />
        </TemplateLabel>
        <TemplateLabel
          label={'Passport expiry'}
          className={cls.TemplateLabelMin}
          isRequired
        >
          <ControllerInput
            render={CalendarInputDate}
            className={cls.CalendarInputDate}
            name='expiryDate'
            placeholder={"dd/mm/yyyy"}
            defaultValue={null}
          />
        </TemplateLabel>
        {/* <ControllerInput
          render={FileUpload}
          name='files'
          limit={1}
          defaultValue={[]}
        /> */}
      </Template>
    </Form>
  )
}

export default FormPassportData

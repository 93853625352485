import { get } from "lodash";
import moment from "moment";
import useGetPINCAPrices from "src/views/modules/startups/renewal/pincasPrice/queries/useGetPINCAPrices";
import { useMemo } from 'react';
import useSession from "src/modules/session/hooks/useSession";
import useUpsertComponentStakeholderRole from "../../graphql/mutation/updateRole";

const useServices = ({ initialValues }) => {
  const { startupId } = useSession();
  const { mutation } = useUpsertComponentStakeholderRole({
    showSuccessNotification: true,
    successMessage: 'MODULES.STAKEHOLDER.EDIT_ROLE.ROLE_UPDATED_SUCCESS'
  });
  const { manualQuery: getPincasPrices } = useGetPINCAPrices({
    variables: {
      filters: {
        jurisdictionTypes: initialValues.jurisdictions,
      },
    },
  });

  return useMemo(() => ({
    initialData: async () => {
      return initialValues;
    },

    pincasData: async () => {
      const pincasData = await getPincasPrices();
      return { pincasData };
    },

    upsertComponentStakeholderRole: async (context, event) => {
      const values = get(event, 'payload.values');
      const { startDate, endDate, isActive } = values;
      const variables = {
        startupId,
        stakeholderId: context?.initialData?.stakeholder?.id,
        stakeholderRolesData: {
          roles:[{
            entityId: context?.initialData?.entity?.id,
            entityType: context?.initialData?.entity?.name ? 'GROUP_COMPANY' : 'STAKEHOLDER',
            role: context?.initialData?.name === 'FOUNDER' ? null : [{
              type: context?.initialData?.name,
              startDate: moment(startDate).format("YYYY-MM-DD"),
              endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
              isActive
          }]
        }]
        }
      }
      await mutation({ variables });
    }
  }), [initialValues]);
}

export default useServices;

import { createMachine } from 'xstate';

const machine =
  createMachine({
    id: 'AddFormationCompanyInDelaware',
    initial: 'FormDelawareAgree',
    states: {
      FormDelawareAgree: {
        on: {
          PREV: {
            target: 'previousMachine',
          },
          NEXT: {
            target: 'Evaluate_FormDelawareAgree',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      Evaluate_FormDelawareAgree: {
        always: [
          {
            cond: 'acceptInDelaware',
            target: 'FormInDelawareInfo'
          },
          {
            cond: 'discardDelaware',
            target: 'EngageConciergeDelaware'
          },
        ]
      },
      FormInDelawareInfo: {
        on: {
          PREV: {
            target: 'FormDelawareAgree',
          },
          NEXT: {
            target: 'AddDelawareCompany',
            actions: ['setContextAfterStep', 'setDelawareJurisdiction']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      AddDelawareCompany: {
        on: {
          PREV: {
            target: 'FormInDelawareInfo',
          },
          NEXT: {
            target: 'SaveStep',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      SaveStep: {
        invoke: {
          src: 'SaveStep',
          id: 'SaveStep',
          onDone: [
            {
              target: 'finish',
            }
          ],
          onError: [
            {
              target: 'AddDelawareCompany'
            }
          ]
        }
      },
      EngageConciergeDelaware: {
        on: {
          PREV: {
            target: 'FormDelawareAgree',
          },
          NEXT: {
            target: 'Evaluate_EngageConciergeDelaware',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      Evaluate_EngageConciergeDelaware: {
        always: [
          {
            cond: 'sendMail',
            target: 'SendMailStep'
          },
          {
            cond: 'noSendEmail',
            target: 'finish'
          },
        ]
      },
      SendMailStep: {
        invoke: {
          src: 'SendMailNow',
          id: 'SendMailNow',
          onDone: [
            {
              target: 'finish',
            }
          ],
          onError: [
            {
              target: 'EngageConciergeDelaware'
            }
          ]
        }
      },
      previousMachine: {
        entry: 'previousMachine',
        type: 'final'
      },
      cancel: {
        entry: 'cancelMachine',
        type: 'final'
      },
      finish: {
        entry: 'stopMachine',
        type: 'final'
      }
    }
  });

export default machine;

import { MachineConfig, assign } from "xstate";
import { StepDocumentDetails, StepSelectCreationDocument } from './Steps';
import Machine from "../../../generic/components/Machine/MachineClass";

export type typeFormCreation = 'GENERATE_DOCUMENT' | 'UPLOAD_DOCUMENT' | 'CANCEL_CREATION';

interface DataDocumentContext {
    form_of_document_creation: { typeFormCreation: typeFormCreation }
}
interface ContextDocument {
    [key: string]: any,
    data: DataDocumentContext
}

const isGenerateDocument = ({ data }: ContextDocument) => data.form_of_document_creation.typeFormCreation === 'GENERATE_DOCUMENT';
const isCancelCreationDocument = ({ data }: ContextDocument) => data.form_of_document_creation.typeFormCreation === 'CANCEL_CREATION';
const isUploadDocument = ({ data }: ContextDocument) => data.form_of_document_creation.typeFormCreation === 'UPLOAD_DOCUMENT';

const setDocumentType = assign((context: ContextDocument, { payload }: any) => {
    const { paramsData } = context;
    return { ...context, paramsData: { ...paramsData, type: payload } };
});

const machineDefinition: MachineConfig<ContextDocument, any, any> = {
    id: 'ip_generate',
    initial: 'form_of_document_creation',
    states: {
        form_of_document_creation: {
            on: {
                NEXT: { target: 'loading', actions: 'setContext' },
                CANCEL: 'finish_upload_document',
            },
        },
        loading: {
            always: [
                { cond: 'isGenerateDocument' },
                { target: 'finish_upload_document', cond: 'isCancelCreationDocument' },
                { target: 'details_document', cond: 'isUploadDocument' },
            ]
        },
        details_document: {
            on: {
                PREVIOUS: { target: 'form_of_document_creation' },
                NEXT: { target: 'finish_upload_document', actions: 'setContext' },
                SET_DOC_TYPE: { actions: 'setDocumentType' },
                CANCEL: 'finish_upload_document',
            },

        },
        finish_upload_document: {
            type: 'final',
            entry: 'final',
        },
        onDone: {
            actions: 'stopMachine'
        }
    },
}
const steps = {
    form_of_document_creation: StepSelectCreationDocument,
    details_document: StepDocumentDetails
}

const options = {
    guards: {
        isGenerateDocument,
        isCancelCreationDocument,
        isUploadDocument
    },
    actions: {
        setDocumentType
    }
}

export default class MachineCompany extends Machine {
    constructor() {
        super(machineDefinition, options, steps);
    }
    getParamsData({ paramsMutation }) {
        return { ...paramsMutation }
    }
    getInitialValues({ initialValues = {} }) {
        return initialValues
    }
}
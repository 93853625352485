import _ from "lodash";
import { useParams } from "react-router-dom";
import useAddDocumentFormation from "src/hooks/services/modules/individualStakeholder/useAddDocumentFormation";
import { JURISDICTION_TYPE } from "src/modules/generic/types";
import useSession from "src/modules/session/hooks/useSession";
import useAddRenewalTasksDoneStakeholder from 'src/modules/startup/Stakeholder/graphql/queries/useAddRenewalTasksDoneStakeholder';
import useGetManagedByClaraConnectionsStakeholder from 'src/modules/startup/Stakeholder/graphql/queries/useGetManagedByClaraConnectionsStakeholder';
import useUpdateStakeholder from "src/modules/startup/onBoarding/core/graphql/mutations/useUpdateStakeholder";

const useServices = ({ initialValues }) => {
  const { startupId } = useSession();
  const [updateDocument] = useAddDocumentFormation({});
  const { id } = useParams<any>();
  const addRenewalTasksDoneStakeholder = useAddRenewalTasksDoneStakeholder();
  const { manualQuery } = useGetManagedByClaraConnectionsStakeholder();

  const { updateStakeholder: upsertIndividualStakeholder } = useUpdateStakeholder({
    isEdit: true,
    showSuccessNotification: false,
  }, {});  

  return {
    initialData: async () => {
      const stakeholderId = initialValues?.idToSave || id;
      const { data } = await manualQuery({ variables: { stakeholderId, startupId } });
      const stakeholder = data?.getStakeholder;
      const groupCompany = (stakeholder.managedByClaraConnections || []).find((item) => item?.statusOnMatters.isRenewal && item?.jurisdictionType === JURISDICTION_TYPE.ADGM);
      return { 
        ...initialValues, 
        groupCompany, 
        nationality: initialValues?.defaultNationality || stakeholder?.nationality
      };
    },
    uploadDocument: async (context) => {
      const passportFile = _.get(context, "configurePassport");
      const idStakeholderToUse = _.get(context, "initialData.idToSave", id);
      const needToUpdateNacionality = _.get(context, "initialData.needToUpdateNacionality", false);
      if (needToUpdateNacionality) {
        const variablesUpdateStakeholder = {
          startupId,
          stakeholderId: idStakeholderToUse,
          stakeholderData: {
            stakeholder: {
              nationality: _.get(context, "configurePassport.nationality"),
            }
          },
        };
        await upsertIndividualStakeholder(
          {
            variables: variablesUpdateStakeholder,
          }
        );
      }
      const variables = {
        stakeholderId: idStakeholderToUse,
        startupId,
        identityDocumentData: {
          ...passportFile,
          nationality: _.get(context, "configurePassport.nationality"),
          number: _.get(context, "configurePassport.number"),
          type: "PASSPORT_DOCUMENT"
        },
      };
      await updateDocument({ variables });
    },
    confirmNationalitiesDone: async (context) => {
      const groupCompanyId = context?.initialData?.groupCompany?.id;
      const stakeholderId = context?.initialData?.idToSave || id;
      await addRenewalTasksDoneStakeholder(groupCompanyId, ['areNationalitiesConfirmed', 'hasNewPassportsBeenUploaded'], stakeholderId);
      return;
    },
    saveStep: async (context) => {
      return;
    }
  }
}

export default useServices;
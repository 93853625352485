import { Tooltip } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import IcoMoon from "react-icomoon";
import classes from "./classes.module.scss";
const iconSet = require("./../../../generic/components/Icon/clara/selection.json");


const PincasIcon = ({ tooltip }) => {

  return (
    <Tooltip title={tooltip} placement="right" classes={{ tooltip:classes.tooltip}}>
      <div>
        <IcoMoon iconSet={iconSet} icon={"In-progress"} size={"16px"} />
      </div>
    </Tooltip>
  );

};
PincasIcon.propTypes = {
  tooltip: PropTypes.string,
};
PincasIcon.defaultProps = {
  tooltip: "A change request is currently pending.",
};


export default PincasIcon;
import React, { FC, useMemo } from 'react';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import AddFormationCompanyInCaymanWizard from './../../submachines/AddFormationCompanyInCaymanModal/wizard';

interface Props {

}
/**
* Description Step: Add formation company in Cayman 
*/
const AddFormationCompanyInCaymanSubMachineStep: FC<Props> = () => {

  const { next, context, prev, cancel } = useMachine();
  const initialValues = useMemo(() => {
    return context?.StepForm ?? {}
  }, [context]);

  const handleStopMachine = () => {
    next(context)
  }

  const handleCancelMachine = () => {
    cancel();
  }
  
  const handlePrevMachine = () => {
    prev();
  }

  return (
    <AddFormationCompanyInCaymanWizard initialValues={initialValues} onFinish={handleStopMachine} onCancel={handleCancelMachine} onPreviousMachine={handlePrevMachine} />
  );
};

export default AddFormationCompanyInCaymanSubMachineStep;

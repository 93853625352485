import React from 'react';
// import {LeftEducation} from './../../../components';
import { useDispatch } from "react-redux";
import classesModal from "./BlueScreenLayout.module.scss";
//import "../../styles2/index.scss";
import Typography from '../../modules/generic/components/Texts/Typography';
import { goToPrevPage } from "./../../store/actions/page";

const BlueScreenLayout = ({ children, leftPanelComponent, showClose = true, handleClose, classes,title }) => {
  const dispatch = useDispatch();
  

  return (
    <React.Fragment>
      <div className={`${classesModal.BlueScreenLayout}`}>
        <div className={classesModal.LeftPanel}>

          <div className={classesModal.titles}>
            {leftPanelComponent}
          </div>

        </div>
        <div className={`${classesModal.RightPanel}`}>
          <header>
            <img src="/img/clara_logo.svg" alt="Clara" />
          </header>
          <div className={classesModal.StepFormZone}>

            {title && <Typography component="h1" className={classesModal.TitleScreen}>{title}</Typography>}
            {children}
          </div>
          </div>
        </div>
    </React.Fragment>
  );
}

export default BlueScreenLayout;
import React, { Component } from "react";
import FormikHelper from "../../../utils/formikHelper";
import { CalendarInputDate, CalendarInputTime, Text, TooltipInput } from "../../../components";
import { helper, Constants } from "../../../utils";
import classes from './TextInput.module.scss';
import _ from "lodash";
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import MaskedInput from 'react-text-mask';

class TextInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showTooltip: false,
            itWasFocused: false,
            mounted: false,
            localValueMask:null,
            localValue: null
        };
    }

    hasError() {
        const { form, field } = this.props;
        return FormikHelper.returnErrorsForValidation(field.name, form.errors);
    }

    getError() {
        const { form, field } = this.props;
        return FormikHelper.returnErrorsForValidation(field.name, form.errors);
    }

    handleClearInput = () => {
        const { form, field } = this.props;
        return FormikHelper.setValueInTheCorrectPosition(field.name, form, "");
    };

    isEmpty = () => {
        return this.props.field.value && this.props.field.value.length > 0;
    };

    setFocused = (itWasFocused) => {
        this.setState({
            itWasFocused
        })
    };

    handleBlur = (e) => {
        this.setFocused(true)
        const { field, form, trim = true, type = 'text' } = this.props;
        // Remove all spaces at start and end of the string
        if ((type === 'text' || type === 'email') && trim && e.target.value) {
            e.target.value = e.target.value.trim();
            if(type === 'email')
                e.target.value = e.target.value.toLowerCase()
            FormikHelper.setValueInTheCorrectPosition(field.name, form, e.target.value);
            field.onChange(e);
        }

        form.setFieldTouched(field.name, true, false);
    };

    componentWillUpdate(nextProps, nexState) {
        if (nextProps.shouldClearValue && !this.props.shouldClearValue) {
            this.handleClearInput();
        }
    }

    componentDidUpdate(prevProps, nexState) {

        if (_.get(this.props, "mounted") == true && _.get(this.props, "field.value")) {
            const { field, form } = this.props;
            this.setState({ mounted: false });
        }
    }

    componentWillMount(nextProps) {
        this.setState({ mounted: true });
    }

    render() {
        const { type, tooltip, showRedStar, className, classNameInput } = this.props;
        let {value = ""} = this.props;
        if (type === 'date') {
            return (
                <div className={`${classes.calendarContainer} ${className}`}>
                    <div className={`${this.props.modal?classes.CalendarInputModal:classes.CalendarInput} ${_.get(classNameInput, 'root', null)} `}>
                        {this.props.label ? (
                            <label>{this.props.label || "" + ' '}{this.props.required && showRedStar !== false ?
                                <span style={{ color: 'red' }}>*</span> : null}</label>
                        ) : null}
                        <div className={_.get(classNameInput, 'input', null)} onBlur={(e) => {
                            this.handleBlur(e);
                        }}>
                            <CalendarInputDate {...this.props} placeholder={this.props.placeholder ? this.props.placeholder : 'dd/mm/yyyy'} label={null} />
                        </div>
                    </div>
                    {this.hasError() ? (
                        <div className={`${classes.MessageErrorDate} ${this.props.messageErrorClassName}`}>
                            {this.getError()}
                        </div>
                    ) : null}

                </div>
            );
        } else if (type === 'time') {
            return (
                <div className={`${classes.calendarContainer} ${className}`} >
                    <div className={`${classes.CalendarInput} ${_.get(classNameInput, 'root', null)} `}>
                        {this.props.label ? (
                            <label>{this.props.label || ""}{this.props.required && showRedStar !== false ?
                                <span style={{ color: 'red' }}>*</span> : null}</label>
                        ) : null}
                        <div className={_.get(classNameInput, 'input', null)} onBlur={(e) => {
                            this.handleBlur(e);
                        }} >
                            <CalendarInputTime {...this.props} label={null} />
                        </div>
                    </div>
                    {this.hasError() ? (
                        <div className={classes.MessageErrorDate}>
                            {this.getError()}
                        </div>
                    ) : null}

                </div>
            );
        } else if (type === 'number' || type === 'percent' || type ==='share' || type ==='percentage') {

            const classInputContainer = _.get(this.props, "className.root") ? _.get(this.props, "className.root") : `${classes.InputContainer} ${className}`;
            const classTextInput = _.get(this.props, "className.input") ? _.get(this.props, "className.input") : classes.TextInput;
            let format = helper.getFormatByKey(Constants.TYPE_FORMATS[type.toUpperCase()], _.get(this.props,"maskParams",{})) || {};
            const maskConfig = {
                    prefix: format.prefix?format.prefix:"",
                    suffix: format.suffix?format.suffix:"",
                    includeThousandsSeparator: !!(format.thousandSeparator),
                    thousandsSeparatorSymbol: format.thousandSeparator,
                    allowDecimal: true,
                    decimalSymbol: format.decimalSeparator,
                    decimalLimit: format.decimalScale, 
                    allowNegative: false,
                    integerLimit:format.integerAmount,
                    allowLeadingZeroes: format.fixedDecimalScale,
            }
            const maskType = format.maskType ? format.maskType : null;
            const localMask = createNumberMask(maskConfig);
            return (
                < div className={classInputContainer} >
                    <div className={`${classTextInput} ${_.get(classNameInput, 'root', null)} `}>
                        {this.props.label ? (
                            <label>
                                <Text uuid={this.props.label || ""}/>
                                {' '}
                                {this.props.required && showRedStar !== false ? <span style={{ color: 'red' }}>*</span> : null}
                            </label>

                        ) : null}
                        <div className={`${classes.contentOnlyInput} ${_.get(classNameInput, 'input', null)} ${this.props.leftLabel?classes.paddingForLeftLabel:classes.paddingForRightLabel}`}>
                            <TooltipInput label={tooltip} link={this.props.link ? this.props.link : null}>
                            {this.props.leftLabel ?
                                (
                                    <div className={`${classes.leftLabel} ${className?.label}`}>{this.props.leftLabel}</div>
                                ) : null
                            }
                                <MaskedInput
                                    mask={localMask}
                                    guide={false}
                                    className={this.hasError() ? classes.Error : null}
                                    type={"text"}
                                    value={this.state.localValue || value}
                                    {...this.props.field}
                                    placeholder={helper.getTranslateTextByKey(this.props.placeholder)}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        this.setState({
                                            localValue:value
                                        })
                                        let localMaskValue = value.replaceAll(maskConfig.thousandsSeparatorSymbol, '').replaceAll(maskConfig.decimalSymbol, '.');
                                       
                                        
                                        const { field, form } = this.props;
                                        
                                        try {
                                            // form.setFieldValue("pepe",localMaskValue)
                                            form.setFieldValue(field.name,localMaskValue)
                                            form.validateField(field.name)
                                        } catch (e) {

                                        }

                                        _.get(this, "props.onChange", () => { })(e)
                                    }}
                                    onBlur={(e) => {
                                        this.handleBlur(e);
                                    }}
                                    disabled={this.props.disabled}
                                    readOnly={this.props.readOnly}
                                    autocomplete={"off_1"}
                                    hasError={this.hasError() ? "true" : "false"}
                                />
                            </TooltipInput>
                            {this.hasError() ? (
                                <div className={classes.MessageError}>
                                    {this.getError()}
                                </div>
                            ) : null}

                            {this.props.rightLabel ?
                                (
                                    <div className={classes.rightLabel}>{this.props.rightLabel}</div>
                                ) : null
                            }
                            {
                                this.props.clear && this.isEmpty() ?
                                    (
                                        <button onClick={() => this.handleClearInput()} className={classes.clearButton} />
                                    ) : null
                            }
                        </div>
                    </div>
                </div>
            )
        } else {
            const classInputContainer = _.get(this.props, "className.root") ? _.get(this.props, "className.root") : `${classes.InputContainer} ${className}`;
            const classTextInput = _.get(this.props, "className.input") ? _.get(this.props, "className.input") : classes.TextInput;

            return (
                 < div className={classInputContainer} >
                    <div className={`${classTextInput} ${_.get(classNameInput, 'root', null)} `}>
                        {this.props.label ? (
                            <label>
                                <Text uuid={this.props.label || ""}/>
                                {' '}
                                {this.props.required && showRedStar !== false ? <span style={{ color: 'red' }}>*</span> : null}
                            </label>

                        ) : null}
                        <div className={`${classes.contentOnlyInput} ${_.get(classNameInput, 'input', null)} ${this.props.leftLabel?classes.paddingForLeftLabel:classes.paddingForRightLabel} `}>
                            <TooltipInput label={tooltip} link={this.props.link ? this.props.link : null}>
                            {this.props.leftLabel ?
                                (
                                    <div className={classes.leftLabel}>{this.props.leftLabel}</div>
                                ) : null
                            }
                                <input
                                    className={this.hasError() ? classes.Error : null}
                                    type={type}
                                    value={value}
                                    {...this.props.field}
                                    placeholder={helper.getTranslateTextByKey(this.props.placeholder)}
                                    onChange={(changeEvent) => {
                                         if (!!this.props.trim) {
                                             changeEvent.target.value = changeEvent.target.value.trimStart();
                                         }

                                         if(this.props.parseInt) {
                                             changeEvent.target.value =  parseInt(changeEvent.target.value);
                                         }
                                        const { field, form } = this.props;

                                        try {
                                            form.validateField(field.name)
                                        } catch (e) {

                                        }
                                        this.props.field.onChange(changeEvent);
                                         _.get(this, "props.onChange", () => { })(changeEvent)
                                        return changeEvent
                                    }}
                                    onBlur={(e) => {
                                        this.handleBlur(e);
                                    }}
                                    disabled={this.props.disabled}
                                    readOnly={this.props.readOnly}
                                    autocomplete={"off_1"}
                                    hasError={this.hasError() ? "true" : "false"}
                                />
                            </TooltipInput>
                            {this.hasError() ? (
                                <div className={classes.MessageError}>
                                    {this.getError()}
                                </div>
                            ) : null}

                            {this.props.rightLabel ?
                                (
                                    <div className={classes.rightLabel}>{this.props.rightLabel}</div>
                                ) : null
                            }
                            {
                                this.props.clear && this.isEmpty() ?
                                    (
                                        <button onClick={() => this.handleClearInput()} className={classes.clearButton} />
                                    ) : null
                            }
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default TextInput;

import useMutation from '../../../../hooks/custom/useMutation';
import { RoleFields } from '../../../../modules/stakeholder/graphql/typePolicies';

export const UPSERT_ROLE_INDIVIDUAL_STAKEHOLDER = `
  {{#if stakeholder.roles}}
    ${RoleFields}
  {{/if }}
  mutation addComponentStakeholderRole($startupId: ID!, $stakeholder:ComponentStakeholderInputType!,  $roles: [RolesArrayInputAddType]) {
        addComponentStakeholderRole(startupId: $startupId ,roles: $roles ,stakeholder: $stakeholder ) {
      id
      fullName
      roles{
        id
        name
        entity{
          ... on StakeHolder {
            id
            fullName
          }
          ... on GroupCompany {
            id
            name
          }
        }
      }
    }
  }`

const useUpsertRoleStakeholder = ({ ...props }) => {
  const mutation = useMutation(
    UPSERT_ROLE_INDIVIDUAL_STAKEHOLDER,
    { showSuccessNotification: true, successMessage: "STAKEHOLDER_ADD_SUCCESS" },
    { refetchQueries: ["companyCorporateMap"], ...props }
  )
  return mutation
}

export default useUpsertRoleStakeholder

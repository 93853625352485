import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateLabel from 'src/modules/generic/components/Inputs/LabelInput';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText } from 'src/modules/generic/store/action';
import Checkbox from 'src/components/inputs/Checkbox/Checkbox';
import AutocompleteClara from "src/modules/generic/components/Inputs/Autocomplete";
import classes from "../../styles/shareholder.module.scss";

function AddIndividual() {
  const { next, context, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const hasPreviousStep = useMemo(() => {
    return !!context?.AddShareholder;
  }, [context]);

  const initialValues = useMemo(() => {
    return context?.initialData ?? {};
  }, [context]);

  const { selectedCompany, groupCompaniesMapped, stakeholders } = initialValues;

  const stakeholdersList = React.useMemo(() => {
    const filterOnlyIndividuals = stakeholders.filter(st => st.isAnEntity === false);
    return filterOnlyIndividuals.map(st => ({ value: st, label: st.fullName }));
  }, [JSON.stringify(stakeholders)]);

  const handleCancel = () => {
    cancel();
  }

  const handlePrev = () => {
    prev();
  }

  const handleSubmit = async (values) => {
    next(values);
  }

  const createStakeholder = (value: string) => {
    return { fullName: value };
  }

  useEffect(() => {
    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch]);

  const schema = Yup.object().shape({
    stakeholder: Yup.object().nullable().required(translate('MODULES.ONBOARDINGROLES.SHAREHOLDER_V2.FIELD_REQUIRED')),
    company: Yup.array().required(translate('MODULES.ONBOARDINGROLES.SHAREHOLDER_V2.FIELD_REQUIRED')).min(1, 'Please select a company')
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: translate("MODULES.ONBOARDINGROLES.SHAREHOLDER_V2.INDIVIDUAL_OR_COMPANY_TITLE"),
          skeleton: null,
          subTitle: translate("MODULES.ONBOARDINGROLES.SHAREHOLDER_V2.INDIVIDUAL_SHAREHOLDER_STEP_SUBTITLE"),
          variant: 'simple'
        }}
        buttons={{
          previous: hasPreviousStep ? <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button> : null,
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>{translate('GENERIC_BUTTON_ADD_GROUP')}</SubmitButton>,
        }}
      >
        <>
          <TemplateLabel label={translate("MODULES.ONBOARDINGROLES.SHAREHOLDER_V2.INDIVIDUAL_NAME_FIELD")} isRequired>
            <ControllerInput
              render={AutocompleteClara}
              getCreateValue={createStakeholder}
              name='stakeholder'
              options={stakeholdersList}
              placeholder={translate("MODULES.ONBOARDINGROLES.SHAREHOLDER_V2.INDIVIDUAL_NAME_PLACEHOLDER")}
            />
          </TemplateLabel>
          <div style={{ display: groupCompaniesMapped.length > 1 ? "block" : "none" }}>
            <TemplateLabel label={translate("MODULES.ONBOARDINGROLES.SHAREHOLDER_V2.INDIVIDUAL_OR_COMPANY_COMPANY_FIELD")} isRequired>
              <ControllerInput
                render={Checkbox}
                name='company'
                variant='col_1'
                defaultValue={[selectedCompany?.id]}
                options={groupCompaniesMapped}
                classCheckboxLine={classes.CompanyCheckboxButton}
              />
            </TemplateLabel>
          </div>
        </>
      </TemplateWizard>
    </Form>
  );
}

export default AddIndividual;
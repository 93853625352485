import useCustomMachine from "../../../../generic/hooks/useCustomMachine";
import React, { FC, useState } from "react";
import Button from "../../../../generic/components/Buttons/Button";
import TemplateFullModal from "../../../../generic/templates/Modal/TemplateFullModal";
import useTranslate from "../../../../generic/hooks/useTranslate";
import DocumentPreviewFile from '../../../../../components/generic/Documents/DocumentPreview/DocumentPreviewFile';
import useFormationApproveDocument from '../../../../../modules/formation/hooks/useFormationApproveDocument';
import _ from "lodash";
import useGetDocument from "../../../../../hooks/services/modules/Document/useGetDocument";
import useSession from "../../../../../modules/session/hooks/useSession";
import classes from '../ModalDocumentReview.module.scss';

export interface Step1Props  {
  style?: string;
}

const Step1: FC<Step1Props> = ({
  style = null,
}) => {

  const { translate } = useTranslate();
  const { next, send, state } = useCustomMachine();
  const { startupId } = useSession();
  const [saveApprove, setSaveApprove] = useState(false);

  const { data, loading, refetch } = useGetDocument({
    agreementId: _.get(state, "context.data.documentId"),
    startupId,
  });

  const fileName = _.get(data, "file.name");
  const fileType = fileName ? fileName.split(".").pop() : "";

	const { mutation: mutationApproveDocument } = useFormationApproveDocument({
		showSuccessNotification: false
	});

  const handleCompleteSubmit = async (values) => {
    setSaveApprove(true);
		await mutationApproveDocument({
			startupId: startupId,
			documentId: _.get(state, "context.data.documentId"),
		});
    send("CANCEL");
  };

  const handleDecline = async (values) => {
    next();
  };

  const handleCancel = async (values) => {
    send("CANCEL");
  };

  return (
    <React.Fragment>
      <TemplateFullModal
        buttons={{
          cancel: (
            <Button onClick={handleDecline} variant="secondary">
              {translate('MODULES.GROUP_COMPANY.DOCUMENT_REVIEW.SECOND_STEP.DECLINE_BTN')}
            </Button>
          ),
          previous: (
            <Button onClick={handleCancel} variant="secondary">
              {translate('MODULES.CLARA.BTN_CANCEL')}
            </Button>
          ),
          submit: (
            <Button onClick={handleCompleteSubmit} variant="primary" loading={saveApprove}>
              {translate('MODULES.GROUP_COMPANY.DOCUMENT_REVIEW.SECOND_STEP.APPROVE_BTN')}
            </Button>
          )
        }}
      >
        <DocumentPreviewFile
          fileId={_.get(data, "file.id")}
          fileType={fileType}
          fileName={fileName}
          style={style}
          className={classes.modalApprovePreviewSpace}
          defaultScale={0.64}
        />
      </TemplateFullModal>
    </React.Fragment>
  );
};

export default Step1;

import React, {useEffect} from 'react';
import IndividualOrCompanyStep from "../../../core/wizards/steps/IndividualOrCompanyStep/IndividualOrCompanyStep";
import {addAssistantText, clearAssistatText} from "../../../../../generic/store/action";
import {useDispatch} from "react-redux";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import _ from "lodash";
import useCustomMachine from "../../../../../generic/hooks/useCustomMachine";

const IndividualOrCompanyStepInvestor = ({...props})=>{
    const dispatch=useDispatch()
    const {translate} = useTranslate();
    const {state}=useCustomMachine()
    useEffect(()=>{
        dispatch(clearAssistatText())
        dispatch(addAssistantText(translate("MODULES.ONBOARDINGROLES.INVESTOR.SECOND_STEP_TOOLTIPS"),'text'))
    },[])

    return(
        <>
            <IndividualOrCompanyStep isTherePrevious={false} role={'INVESTOR'}/>
        </>
    );
}

export default IndividualOrCompanyStepInvestor;

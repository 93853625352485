import Form from "../../../forms/OptionForm";
import get from "lodash/get";
import React, { FC, useMemo } from 'react';
import Button from "../../../../../generic/components/Buttons/Button";
import useCustomMachine from '../../../../../generic/context/MachineContext/useMachine';
import useTranslate from '../../../../../generic/hooks/useTranslate';
import  TemplateWizard  from 'src/modules/generic/templates/Modal/TemplateWizard';
import { useDispatch } from "react-redux";
import _ from "lodash";
const OptionFormStep: FC<{}> = () => {

    const { translate } = useTranslate();
    const dispatch=useDispatch()
    /*Function*/


    const { next, send, context } = useCustomMachine();


    const handleCompleteSubmit = async values => {
        next(values);
    }
    const handleCancel = async values => {
        send("CANCEL");
    }
    
    const shareClass = useMemo((): any[] => {
        const company = context.groupCompanies[_.get(context, 'iterations', 0)]
        return _.get(company, 'shareClass')
    }, [JSON.stringify(context)]);
    const showClass = useMemo(() => {
        return context.documentsSIP === "sip";
    }, [JSON.stringify(context)]);
    const remainShares = useMemo(() => {
        
        if (context.documentsSIP === 'sip'){
            return null
        }
        if (context?.uploadDocumentsOptionSIPLater?.reservedShares) {
            return context?.uploadDocumentsOptionSIPLater?.reservedShares
        }
        if (context?.uploadDocumentsOptionSIP?.reservedShares) {
            return context?.uploadDocumentsOptionSIP?.reservedShares
        }
        if (context?.generatedDocumentOptionSIP?.document) {
            return context?.generatedDocumentOptionSIP?.document?.totalShares - context?.generatedDocumentOptionSIP?.document?.allocatedShares
        }
        return null;
    },[context])
    return (
        <React.Fragment>
            <Form
                remainShares={remainShares}
                showClass={showClass}
                shareClassList={shareClass}
                company={context.groupCompanies[get(context,'iterations',0)]}
                onCompleteSubmit={handleCompleteSubmit}
                Template={TemplateWizard}
                propsTemplate={{
                    title:translate('MODULES.STAKEHOLDER.SHARE_OPTIONS.THIRD_STEP.TITLE'),
                    subTitle: translate('MODULES.STAKEHOLDER.SHARE_OPTIONS.THIRD_STEP.SUBTITLE')
                    
                }}
                buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }}>
            </Form>

        </React.Fragment>
    )
}

export default OptionFormStep;

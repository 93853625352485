import useCustomMachine from "../../../../generic/hooks/useCustomMachine";
import React, { FC } from "react";
import Button from "../../../../generic/components/Buttons/Button";
import { StepProps } from "../../../../generic/components/Wizard/Step";
import TemplateWizard from "../../../../generic/templates/Modal/TemplateWizard";
import useTranslate from "../../../../generic/hooks/useTranslate";
import Form from "../../Forms/FormSelectCompany";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  addAssistantText,
  clearAssistatText,
} from "../../../../generic/store/action";
import useGetStakeholderRoles from "../../../graphql/stakeholder/query/useGetStakeholderRoles";
import { useParams } from "react-router-dom";
import useSession from "../../../../session/hooks/useSession";

const Step1: FC<StepProps> = () => {

  const { translate } = useTranslate();
  const { next, send, state } = useCustomMachine();
  const { startupId } = useSession();
  const { id: stakeholderId } = useParams<any>();
  const dispatch = useDispatch();

  const { data: rolesData } = useGetStakeholderRoles({
    variables: { startupId, stakeholderId },
  });

  const handleCompleteSubmit = async (values) => {
    const company = values.company.map((com) => {
      const roleData = rolesData.find(
        (roleData) => com.id === roleData.groupCompanyId
      );
      return { ...com, roles: get(roleData, "roles", []) };
    });
    next({ company });
  };

  const handleCancel = async (values) => {
    send("CANCEL");
  };

  React.useEffect(() => {
    dispatch(
      addAssistantText(
        "<b>Roles</b> are tasks and responsibilities that a stakeholder has within a startup, often formalised with a legal agreement."
      )
    );
    dispatch(
      addAssistantText(
        "<b>Positions</b> refer to job titles, departments and responsibilities."
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, []);

  return (
    <React.Fragment>
      <Form
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: "Select the relevant companies",
          subTitle: `Roles and Positions`,
        }}
        initialValues={get(state, "context.data.select_company", {})}
        buttons={{
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              Cancel
            </Button>
          ),
        }}
      ></Form>
    </React.Fragment>
  );
};

export default Step1;

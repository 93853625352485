import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import Button from "src/modules/generic/components/Buttons/Button";
import { StepProps } from "src/modules/generic/components/Wizard/Step";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import { clearAssistatText } from "src/modules/generic/store/action";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import FormDataProtectionDeclarationPart1 from "../../forms/FormDataProtectionDeclarationPart1";

const DeclarationPart1: FC<StepProps> = () => {
  const { next, send, context } = useMachine();
  const { isEdit, dataProtectionDeclaration } = context.initialData;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearAssistatText());
    return () => {
      dispatch(clearAssistatText());
    };
  }, []);

  const handleCompleteSubmit = async (values) => {
    next(values);
  };

  const handleCancel = () => {
    send("CANCEL");
  };

  return (
    <>
      <FormDataProtectionDeclarationPart1
        initialValues={dataProtectionDeclaration}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: `${
            isEdit ? "Edit" : "Review and confirm"
          } data protection declaration - Personal data`,
          subTitle: isEdit ? "" : "Review data protection declaration",
        }}
        buttons={{
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              Cancel
            </Button>
          ),
        }}
      />
    </>
  );
};

export default DeclarationPart1;

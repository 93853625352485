import React from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";
const AddAmendShareClassView = ({ go, ...props }) => {
  const params = useParams();

  return (

    <MicroFrontend id="MF_ShareClass_Amend_Add" component={"./AddAmendShareClass"} mf="capTable" params={{ go, ...props, ...params }} />

  );
};

export default AddAmendShareClassView;


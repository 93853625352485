import React from 'react';
import { useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import useSession from '../../../session/hooks/useSession';
import PaymentView from './PaymentView';

const PaymentSubscriptionView = (props) => {
	const dispatch = useDispatch();
    const params = useParams();
    const { productCode } = params;
	const { startup, updateStartup } = useSession();
	const completeFinish = (data) => {
		updateStartup(data.finishNewCreditCardPayment);
	};
	
	return (
        <React.Fragment>
			<PaymentView
				productCode={productCode}
				id={startup.id}
				completeFinish={completeFinish}
			/>
        </React.Fragment >
    )
};

export default PaymentSubscriptionView;

import React, { FC } from "react";
import { Stack, Box } from "@mui/material";
import Avatar from "src/modules/generic/components/Avatars/AvatarLabel";
import Tag from "src/v1/components/Tag/Tag";
import Typography from "src/modules/generic/components/Texts/Typography";
import PincasIcon from "src/modules/generic/components/Icon/PincasIcon";
import CountryFlag from "src/modules/generic/components/CountryFlag";
import claraIcon from "src/images/claraLogo.svg";
import classes from "./classes.module.scss";

/**
 * ClaraList
 */

export interface ClaraListProps {
  items?: any;
  removeNumber?: boolean;
  variant?: "normal" | "flags";
}

const ClaraList: FC<ClaraListProps> = ({
  items = [],
  removeNumber = false,
  variant = "normal",
}: ClaraListProps) => {
  return (
    <>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={0}
      >
        {items.map((item, index) => {
          return (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
              sx={{
                width: "100%",
                height: "46px",
                flexWrap: "nowrap",
                borderBottom: "1px solid #EAF1FE",
              }}
            >
              {!removeNumber && (
                <Box
                  sx={{
                    width: "30px",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant={"body"}
                    className={item.isLocked ? classes.labelLocked : null}
                  >
                    {index + 1}.
                  </Typography>
                </Box>
              )}
              {variant === "flags" ? (
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <CountryFlag alpha3={item.alpha3} size="32px" />
                  <label className={classes.claraLabel}>{item.name}</label>
                </Stack>
              ) : (
                <div className={item.isLocked ? classes.filter : null}>
                  {item.useClaraAvatar && (
                    <>
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                      >
                        <img
                          src={claraIcon}
                          alt="Clara"
                          className={classes.claraIcon}
                        />
                        <label className={classes.claraLabel}>
                          {item.name}
                        </label>
                      </Stack>
                    </>
                  )}
                  {!item.useClaraAvatar && (
                    <>
                      <Avatar
                        name={item.name}
                        avatar={item.avatar}
                        showLabel={true}
                        className={item.isLocked ? null : classes.labelAvatar}
                      />
                    </>
                  )}
                </div>
              )}
              {item.isFounder && (
                <>
                  <Tag type={"isFounderSquare"} show={true} />
                </>
              )}
              {item.isLocked && (
                <>
                  <PincasIcon />
                </>
              )}
              {item.sufix && <>{item.sufix}</>}
            </Stack>
          );
        })}
      </Stack>
    </>
  );
};

export default ClaraList;

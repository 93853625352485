import PropTypes from "prop-types";
import React, { useCallback } from 'react';
import { useDispatch } from "react-redux";
import BusinessRuleAccess from "../../../../../../modules/clara/components/BusinessRuleAccess/BusinessRuleAccess";
import useSession from "../../../../../../modules/session/hooks/useSession";
import useBusinessRules from '../../../../../../modules/clara/hooks/useBusinessRules';
import Button from '../../../../../../modules/generic/components/Buttons/Button';
import Typography from '../../../../../../modules/generic/components/Texts/Typography';
import useTranslate from '../../../../../../modules/generic/hooks/useTranslate';
import { RoleAccess } from '../../../../../../v1/components';
import { Constants } from "../../../../../utils/constants";
import classesModule from './TitleHealthCheck.module.scss';
import useDoAHealthCheck from "../../../../../../hooks/services/modules/subscription/useDoAHealthCheck";
import event from "../../../../../../v1/utils/event";
import BusinessRuleNoAccess from "src/modules/clara/components/BusinessRuleNoAccess/BusinessRuleNoAccess";
function TitleHealthCheck({ children, ...props }) {
    const { translate } = useTranslate();
    const dispatch = useDispatch();
    const { startup } = useSession();
    const { hasSubscriptionAccessHealthCheck } = useBusinessRules()
    const add = useCallback(() => {
        dispatch({
            type: "GO_TO",
            page: Constants.PAGES.addHealthCheckIssue,
        });
    }, [dispatch])
    const compleHealthCheck = () => {
        event.emmit(Constants.EVENTS.HEALTH_CHECK_REFRESH, {});
    };
    const [runHealthCheck, loading] = useDoAHealthCheck({
        successMessage:
            "MODULES.ASSISTANCEV4.ASSISTANCE_HEALTHCHECK_NOTIFICATION_SUCCESS",
        onCompleted: compleHealthCheck,
    });
    const handleRunHealthCheck = () => {
        const variables = {
            startupId: startup.id,
        };
        runHealthCheck(variables);
    };


    return <div className={` ${classesModule.TitleHealthCheck} `}>
        <Typography variant='h2' color='blue'>{translate("HEALTH_CHECK_TITLE")}</Typography>
        <div>
            <BusinessRuleAccess type={'or'} conditions={[Constants.BUSINESS_RULES.IS_START_SUBSCRIPTION, Constants.BUSINESS_RULES.IS_INACTIVE_PLAN]}>
                {hasSubscriptionAccessHealthCheck() && (
                    <Button variant="card" onClick={handleRunHealthCheck}>{translate("HEALTH_CHECK_BUTTON_RUN")}</Button>
                )}
                <RoleAccess action={Constants.ACTIONS.ADD_DUEDILIGENCE}>
                    <RoleAccess action={Constants.ACTIONS.EDIT_FREEMIUM_STARTUP_REMARK}>
                        <Button variant="card" onClick={add}>{translate("HEALTH_CHECK_BUTTON_ADD_REMARK")}</Button>
                    </RoleAccess>
                </RoleAccess>
            </BusinessRuleAccess>
            <BusinessRuleAccess type={'or'} conditions={[Constants.BUSINESS_RULES.IS_SCALE_SUBSCRIPTION]}>
                <RoleAccess action={Constants.ACTIONS.ADD_DUEDILIGENCE}>
                    <Button variant="card" onClick={add}>{translate("HEALTH_CHECK_BUTTON_ADD_REMARK")}</Button>
                </RoleAccess>
            </BusinessRuleAccess>
        </div>
    </div>;
}

TitleHealthCheck.propTypes = {
    children: PropTypes.any,

}
export default TitleHealthCheck;
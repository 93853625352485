import { default as React, useMemo, useEffect } from 'react';
import { useDispatch } from "react-redux";
import Form from "src/components/forms/Form/Form";
import useMachine from '../../../../generic/context/MachineContext/useMachine';
import useModal from "../../../../generic/hooks/useModal";
import useTranslate from '../../../../generic/hooks/useTranslate';
import { addAssistantText, clearAssistatText } from "src/modules/generic/store/action";
import * as Yup from 'yup';
import TemplateWizard from "../../../../generic/templates/Modal/TemplateWizard";
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import Button from 'src/modules/generic/components/Buttons/Button';
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import { CheckboxButtonOptions } from 'src/modules/generic/components/Inputs/CheckboxButton';

function HaveDocumentStep({ openParam }) {

  const { next, context, prev } = useMachine();
  const { translate } = useTranslate()
  const dispatch = useDispatch();
  const { closeModal: onClose } = useModal();

  const handleCancel = (values) => {
    dispatch(clearAssistatText())
    onClose();
  }

  const handleCompleteSubmit = async (values) => {
    next(values)
  }

  const schema = Yup.object().shape({
    confirm: Yup.string().required("Required"),
  })

  const { initialValues } = useMemo(() => {
    return context?.HaveDocumentStep ?? {}
  }, [context?.HaveDocumentStep])

  const options: Array<CheckboxButtonOptions<string, any>> = useMemo(() => {
    return [
      {
        value: 'generate',
        label:
          translate(`MODULES.STARTUP.MODAL_ADD_FOUNDER_SERVICE_AGREEMENT_MODAL_OPTION_GENERATE`),
      },
      {
        value: 'upload',
        label: translate(`MODULES.STARTUP.MODAL_ADD_FOUNDER_SERVICE_AGREEMENT_MODAL_OPTION_UPLOAD`),
      },
      {
        value: 'later',
        label: translate(`MODULES.STARTUP.MODAL_ADD_FOUNDER_SERVICE_AGREEMENT_MODAL_OPTION_LATER`),
      },
    ]
  }, [translate])

  useEffect(() => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText(translate('MODULES.STARTUP.MODAL_ADD_FOUNDER_AGREEMENT_MODAL_TOOLTIP')));
    return () => {
      dispatch(clearAssistatText());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Form
      defaultValues={initialValues || {}}
      schema={schema}
      onSubmit={handleCompleteSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: translate(`MODULES.STARTUP.MODAL_ADD_FOUNDER_SERVICE_AGREEMENT_MODAL_TITLE`),
          subTitle: translate(`MODULES.STARTUP.MODAL_ADD_FOUNDER_SERVICE_AGREEMENT_MODAL_SUB_TITLE`),
          skeleton: null
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton  >{translate('BUTTON_NEXT')}</SubmitButton>,
        }}
      >
        <ControllerInput
          render={CheckboxButtonSimple}
          name='confirm'
          options={options}
          defaultValue={[]}
          onChange={(value) => { }}
          isMultiple={false}
        />
      </TemplateWizard>
    </Form>
  );
}

export default HaveDocumentStep;

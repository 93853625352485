import React, { FC } from 'react';
import Button from 'src/modules/generic/components/Buttons/Button';
import { StepProps } from 'src/modules/generic/components/Wizard/Step';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import FormDataProtectionDeclarationPart3 from '../../forms/FormDataProtectionDeclarationPart3';

const DeclarationPart3: FC<StepProps> = () => {
  const { next, send, prev, context } = useMachine();
  const { isEdit, dataProtectionDeclaration } = context.initialData

  const handleCompleteSubmit = async (values) => {
    next(values);
  }

  const handleCancel = () => {
    send("CANCEL");
  }

  const handlePrev = () => {
    prev()
  }

  return (
    <>
      <FormDataProtectionDeclarationPart3
        initialValues={dataProtectionDeclaration}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: `${isEdit ? 'Edit' : 'Review and confirm'} data protection declaration - Sensitive data`,
          subTitle: isEdit ? '' : "Review data protection declaration"
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button>,
          previous: <Button onClick={handlePrev} variant="secondary">Previous</Button>
        }}
      />
    </>
  );
}

export default DeclarationPart3;

import useCustomMachine from "../../../../../generic/hooks/useCustomMachine";
import React, { FC, useEffect } from "react";
import Button from "../../../../../generic/components/Buttons/Button";
import { StepProps } from "../../../../../generic/components/Wizard/Step";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import FormProvideTranslation from "../../../Forms/FormProvideTranslation";
import { useDispatch } from "react-redux";
import {
  addAssistantText,
  clearAssistatText,
} from "../../../../../generic/store/action";

const Step1: FC<StepProps> = () => {

  const { translate } = useTranslate();
  const { next, send, state, stepData } = useCustomMachine();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearAssistatText());
    dispatch(
      addAssistantText(
        translate("MODULES.GROUP_COMPANY.SETUP.SECOND_STEP.TOOLTIP")
      )
    );
  }, []);

  const handleCompleteSubmit = async (values) => {
    next(values);
  };
  const handleCancel = async (values) => {
    send("CANCEL");
  };

  const handlePrevious = () => send("PREVIOUS");

  return (
    <>
      <FormProvideTranslation
        initialValues={stepData}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: translate("MODULES.GROUP_COMPANY.SETUP.SECOND_STEP.TITLE"),
          subTitle: translate(
            "MODULES.GROUP_COMPANY.SETUP.SECOND_STEP.SUBTITLE"
          ),
        }}
        buttons={{
          previous: (
            <Button onClick={handlePrevious} variant="secondary">
              Previous
            </Button>
          ),
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              Cancel
            </Button>
          ),
        }}
      ></FormProvideTranslation>
    </>
  );
};

export default Step1;

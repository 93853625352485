import React, {useState,useEffect} from 'react';
import _ from 'lodash';
import {useDispatch} from "react-redux";
import { Modal } from '../../../../../../../components';
import classes from "../ModalBankTransfer.module.scss";
import Text from '../../../../../../../../components/text/Text/Text';
import PrimaryButton from "../../../../../Generic/Buttons/PrimaryButton/PrimaryButton";
import SecondaryButton from "../../../../../Generic/Buttons/SecondaryButton/SecondaryButton";
import Line from '../../../../../../../../components/generic/Line/Line';
import ServerConnect from '../../../../../../../utils/ServerConnect';
import {Constants} from '../../../../../../../utils';
import useGetProductBanks from "../../../../../../../../modules/checkout/hooks/useGetProductBanks";
const mutationIHavePaid = `
    mutation setAsPaidEngagement($matterId: ID!) {
        setAsPaidEngagement(matterId: $matterId) {
            id
        }
    }
`;

const ModalBankTransfer = ({ matterId, file, data, show, title, handleClose, handlePaid, setLoading }) => {
	const dispatch = useDispatch();
	
	const { loading, error, data: product } = useGetProductBanks({ productCode:"FORMATION_ENGAGEMENT", relatedItemId:matterId });
	const handleDownloadDocument = (file) => {
		console.log(file);
		let url = ServerConnect.getUrlFile(file.id);
		ServerConnect.downloadFile(url, file.name);
	};

	const setLoadingPrevComponent = (value) => {
		if (setLoading) {
			setLoading(value)
		}
	};

	const handleIHavePaid = () => {
		setLoadingPrevComponent(true);
		ServerConnect.graphQlMutation(mutationIHavePaid, {
			matterId: matterId,
		}).then(res => {
			dispatch({ type: 'GO_TO', page: Constants.PAGES.kycEngagementApprove })

		});
	};
	const [usdTransferColumn,setUsdTransferColumn] = useState([])
	const [aedTransferColumn,setAedTransferColumn] = useState([])
	useEffect(()=>{
		const bankInformation = _.get(product,"getProduct.bankInformation");
		if (bankInformation){
			const usd = _.find(bankInformation,(element)=>element.key=="USD");
			const aed = _.find(bankInformation,(element)=>element.key=="AED");
			if (usd){
				setUsdTransferColumn([
					{label: "Swift/BIC:", value: usd.swift_bic},
					{label: "Account Number:", value: usd.accountNumber},
					{label: "Account Currency:", value: usd.accountCurrency},
					{label: "Name of Account Holder:", value: usd.nameOfAccountHolder},
					{label: "Address:", value: usd.address},
					{label: "IBAN:", value: usd.IBAN}
				])
			}
			if (aed){
				setAedTransferColumn([
					{label: "Swift/BIC:", value: aed.swift_bic},
					{label: "Account Number:", value: aed.accountNumber},
					{label: "Account Currency:", value: aed.accountCurrency},
					{label: "Name of Account Holder:", value: aed.nameOfAccountHolder},
					{label: "Address:", value: aed.address},
					{label: "IBAN:", value: aed.IBAN}
				])
			}
			
		}
		
	},[product])


	return (
        <React.Fragment>
			<Modal open={show} showHeader={false}>
				<div className={classes.modalBankTransfer}>
					<h1 className={classes.modalTitle}>
						<Text uuid={"ENGAGEMENT_MODAL_BANK_TRANSFER_TITLE"} />
					</h1>
					<Text uuid={"ENGAGEMENT_MODAL_BANK_TRANSFER_SUBTITLE"} />

					<div className={classes.containerInvoice}>
						<div className={classes.containerInvoiceText}>
							<Text uuid={"ENGAGEMENT_MODAL_BANK_TRANSFER_INVOICE_TITLE"} />
							<Text uuid={"ENGAGEMENT_MODAL_BANK_TRANSFER_INVOICE_NUMBER"} params={_.get(data, 'KYC.engagement')}/>
						</div>
						<PrimaryButton type="button" onClick={() => handleDownloadDocument(file)}><Text uuid={"BUTTON_DOWNLOAD_INVOICE"} /></PrimaryButton>
					</div>
					<Line />
					<div>
						<Text uuid={"ENGAGEMENT_MODAL_BANK_TRANSFER_OVERVIEW"}/>
					</div>
					<Line />

					<div className={classes.containerAccountInfo}>
						<div className={classes.containerCurrencyPayment}>
							<h4>If you prefer to pay in USD:</h4>
							<div>
								{
									usdTransferColumn.map((item, i) => {
										return (
											<div className={classes.containerTable} key={item + i}>
												<label>{item.label}</label>
												<p>{item.value}</p>
											</div>
										)
									})
								}
							</div>
						</div>

						<div className={classes.containerCurrencyPayment}>
							<h4>If you prefer to pay in AED:</h4>
							<div>
								{
									aedTransferColumn.map((item, i) => {
										return (
											<div className={classes.containerTable} key={item + i}>
												<label>{item.label}</label>
												<p>{item.value}</p>
											</div>
										)
									})
								}
							</div>
						</div>
					</div>

					<div className={classes.botonera}>
						<SecondaryButton type="button" onClick={handleClose}><Text uuid={"BUTTON_BACK"} /></SecondaryButton>
						<PrimaryButton onClick={handleIHavePaid} ><Text uuid={"BUTTON_HAVE_PAID"} /></PrimaryButton>
					</div>

				</div>
			</Modal>

        </React.Fragment>
    )
};

export default ModalBankTransfer;

import React, { FC, useMemo, useRef } from 'react';
import * as Yup from 'yup';
import Form from "../../../../../components/forms/Form/FormL";
import FileUploaderInput from "../../../../../components/inputs/FileUploader/FileUploaderInput";
import TextInput from "../../../../../components/inputs/Text/TextInput";
import SubmitButton from "../../../../generic/components/Buttons/SubmitButton";
import TemplateLabel from "../../../../generic/components/Inputs/LabelInput";
import ControllerInput from "../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import FormProps from "../../../../generic/forms/interfaces/FormProps";
import useTranslate from "../../../../generic/hooks/useTranslate";
import TemplateFormDefault from "../../../../generic/templates/Modal";
import AutocompleteClara from 'src/modules/generic/components/Inputs/Autocomplete';
import Typography from '../../../../generic/components/Texts/Typography';
import _ from "lodash";

interface UploadConvertibleFormProps extends FormProps {
    uploadFile: boolean,
    shareClassList: any[],
    company: any,
}
const UploadConvertibleForm: FC<UploadConvertibleFormProps> = ({ uploadFile = true, shareClassList, initialValues, tooltips, onCompleteSubmit, Template = TemplateFormDefault, buttons = {}, buttonSubmitProps, propsTemplate,company }: UploadConvertibleFormProps) => {
    const { translate } = useTranslate();
    const refForm = useRef()
    const shareClasses = useMemo(() => {
        return _.map(shareClassList, sh => ({ value: { id: sh.id }, label: sh.name }))
    }, [shareClassList]);
    const createShareClass = (value) => {
        return { name: value }
    }
    const schema = useMemo(() => {
        if (uploadFile){
            return  Yup.object().shape({
                    sipName: Yup.string().nullable().required('Required'),
                    name: Yup.string().nullable().required('Required'),
                    file: Yup.object().nullable().required('Required'),
                    reservedShares: Yup.number().typeError('This field must be a number'),
                    class: Yup.object().nullable().required('Required')
                })
        }else{
            return  Yup.object().shape({
                sipName: Yup.string().nullable().required('Required'),
                reservedShares: Yup.number().typeError('This field must be a number'),
                class: Yup.object().nullable().required('Required')
            })
        }
        
    },[uploadFile])
    return (
        <React.Fragment>
            <Form
                initialValues={initialValues || {}}
                schema={schema}
                onlySendDirty={false}
                onSubmit={onCompleteSubmit}
                ref={refForm} optionsForm={{ mode: "onChange" }}
            >
                <Template props={propsTemplate} buttons={{ submit: <SubmitButton{...buttonSubmitProps}>Add</SubmitButton>, ...buttons }}>
                    <Typography color={'black'} weight={'bold'} variant={"body"}>
                        {company?.name}
                    </Typography>
                    <TemplateLabel label={"SIP name"} isRequired={true}>
                        <ControllerInput
                            as={TextInput}
                            defaultValue=""
                            name={"sipName"}
                            placeholder={translate('MODULES.EQUITY.FORMS.UPLOAD_DOCUMENT.PLACEHOLDER_NAME')}
                            onChange={(e) => {

                            }}
                        />
                    </TemplateLabel>
                    {uploadFile && (
                        <>
                            <TemplateLabel label={translate('MODULES.EQUITY.FORMS.UPLOAD_DOCUMENT.NAME')} isRequired={true}>
                                <ControllerInput
                                    as={TextInput}
                                    defaultValue=""
                                    name={"name"}
                                    placeholder={translate('MODULES.EQUITY.FORMS.UPLOAD_DOCUMENT.PLACEHOLDER_NAME')}
                                    onChange={(e) => {

                                    }}
                                />
                            </TemplateLabel>

                            <TemplateLabel label={translate('MODULES.EQUITY.FORMS.UPLOAD_DOCUMENT.FILE')} isRequired={true}>
                                <ControllerInput
                                    as={FileUploaderInput}
                                    defaultValue=""
                                    name={"file"}
                                    onChange={(e) => {

                                    }}
                                />
                            </TemplateLabel>
                        </>
                    )}
                    <TemplateLabel label={translate('MODULES.EQUITY.FORMS.SHARE_FORM.SHARE_CLASS')} isRequired={true}>
                        <ControllerInput
                            render={AutocompleteClara}
                            options={shareClasses}
                            placeholder={translate('MODULES.EQUITY.FORMS.SHARE_FORM.SELECT_PLACEHOLDER')}
                            name={"class"}
                            getCreateValue={createShareClass}
                        />
                    </TemplateLabel>
                    <TemplateLabel
                        label={"Reserverd shares"}
                        isRequired={true}
                    >
                        <ControllerInput
                            as={TextInput}
                            type="number"
                            name="reservedShares"
                            defaultValue={""}
                            placeholder={translate('MODULES.CLARA.ENTER_NUMBER')}
                            onChange={(e) => { }}
                        />
                    </TemplateLabel>
                </Template>
            </Form>
        </React.Fragment>
    )
}

export default UploadConvertibleForm

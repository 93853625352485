import _ from "lodash"
const mapEntityData = (template: any) => {

    const id = _.get(template, "data.entity.customId", "")
    const prefix = _.get(template, "data.entity.prefix", "")
    const companyName = _.get(template, "data.groupCompany.groupCompanyName", "")
    const holderName = _.get(template, "data.holder.holderName", "")
    const entityType = _.get(template, "data.entity.entityType", "")
    const entityId = _.get(template, "data.entity.entityId", "")
    const event = _.get(template, "data.event", "")
    const equityContainerName = _.get(template, "data.entity.entityName", "")

    const customId = prefix ? `${prefix}${id}` : null;

    const entityNameConfig = {
        SHARE_ISSUANCE: "share issuance",
        OPTION_GRANT: "option grant",
        WARRANT: "warrant",
        CONVERTIBLE: "convertible",
        OPTION_PLAN: "option plan",
        SHARE_CLASS: "share class",
        VESTING_TERM: "vesting term",
        VALUATION: "valuation",
        MODELLING: "modelling"
    }

    const eventNameConfig = {
        "AMEND_SHARE_CLASS_EVENT": "amendment",
        "AMEND_OPTION_PLAN_EVENT": "amendment",
        "CANCEL_SHARE_ISSUANCE_EVENT": "cancel transaction",
        "CANCEL_CONVERTIBLE_EVENT": "cancel transaction",
        "CONVERT_CONVERTIBLE_EVENT": "convert transaction",
        "EXERCISE_OPTION_GRANT_EVENT": "exercise transaction",
        "LAPSE_OPTION_GRANT_EVENT": "lapse transaction",
        "CANCEL_OPTION_GRANT_EVENT": "stop vesting transaction",
        "TRANSFER_SHARE_ISSUANCE_EVENT": "transfer transaction",
        "TRANSFER_CONVERTIBLE_EVENT": "transfer transaction",
        "ADD_SCENARIO_MODELLING_EVENT": "modelling",
        "DELETE_SCENARIO_MODELLING_EVENT": "modelling",
    }

    const entityEvent = eventNameConfig[event]
    const entityName = entityNameConfig[entityType]

    return { companyName, holderName, entityName, customId, entityId, entityEvent, equityContainerName };
}
export {
    mapEntityData
}

import React, { useEffect } from "react";
import useModal from "../../../../../generic/hooks/useModal";
import AddFormationCompanyModal from "src/modules/startup/onBoarding/CreateCompanyModal/wizard/submachines/AddFormationCompanyModal";

/**
 * Step to close modal an open form flow
 * @param {*} param0
 * @returns
 */
function StepOpenFormFlow({ openParam }) {

  const { closeModal: onClose, openModal } = useModal();

  const hendleRedirectToAddCompany = () => {
    openModal( AddFormationCompanyModal, {},'MODAL_ADD_FORMATION_COMPANY' );
  };  

  useEffect(() => {
    onClose();
    hendleRedirectToAddCompany();
  }, []);  

  return (
    <></>
  );
}

export default StepOpenFormFlow;

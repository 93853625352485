import _ from "lodash";
import React, { useEffect, useState } from 'react';
import useGetCards from '../../../../hooks/services/modules/subscription/useGetCards';
import Icon from "../../../../modules/generic/components/Icon";
import useSession from '../../../../modules/session/hooks/useSession';
import CardTemplate from "./../../../templates/Card/Card";
import SelectInput from "./../SelectInput/SelectInput";
import classes from './CardSelector.module.scss';

const CardSelector = ({ children, selectCard = false, add = false, handleSetDefault, onChange, value, handleHidden, ...props }) => {
  const [list, setList] = useState([]);
  const { startup } = useSession();
  const { data: cards, loading: loadingStartup, error: errorStartup, refetch } = useGetCards({ startupId: _.get(startup, "id") }, { skip: !_.get(startup, "id"), showSuccessNotification: false, showErrorNotification: false });

  useEffect(() => {
    onChange && onChange(value);
  }, [value])

  useEffect(() => {
    cards && handleHidden && handleHidden(_.get(cards, "getStartup.bankCards.length", 0) === 0);
    if (!_.get(startup, "id")) {
      handleHidden(true);
    }
    const listCards = [];
    if (add) {
      listCards.push(
        {
          id: -1, label: "Add new card", component: <div className={classes.AddCard}>
            <Icon icon={"Add"} size={"1.3077rem"} isClara={true} />
            <span>Add New Card</span>
          </div>
        }
      )
    }
    _.forEach(_.get(cards, "getStartup.bankCards", []), (card, i) => {
      listCards.push({ id: card._id, label: `XXXX XXXX XXXX ${card.last4}`, card });
    });
    setList(listCards);
    // handleSetDefault && handleSetDefault(listCards);
  }, [JSON.stringify(cards), _.get(cards, "getStartup.bankCards.length", 0)]);


  return (
    <div className={classes.ClaraSelectContainer}>
      {list && cards && ((selectCard && list.length > 1) || !selectCard) &&
        <SelectInput {...props} onChange={onChange} value={value} list={list} filterWithValue={false} clear={true} component={<CardTemplate />} >{children}</SelectInput>
      }
    </div >
  )
}


export default CardSelector

import React, { FC, useMemo } from "react";
import { Stack } from "@mui/material";
import { StepProps } from "src/modules/generic/components/Wizard/Step";
import Button from "src/modules/generic/components/Buttons/Button";
import Typography from "src/modules/generic/components/Texts/Typography";
import img from "src/images/Whats-next.svg";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import cls from "./classes.module.scss";

/**
 - Submit ADGM Form Step
 **/
const SubmitADGMFormStep: FC<StepProps> = ({ ...props }: StepProps) => {
  const { next, context, prev } = useMachine();
  const typePayment = context?.UploadDocumentTransfer?.typePayment;

  const handlePrev = () => {
    prev();
  };

  const handleSubmit = () => {
    next();
  };

  const texts = useMemo(
    () =>
      typePayment
        ? {
            title: (
              <>
                Thank you for <br /> your payment!
              </>
            ),
            subTexts: [
              "We will start our review once your application is submitted.",
              "We will let you know when the funds have been received in our account. Only after this point the submission will be filed with the Regulator.",
              "You can find a copy of your invoice on the View Invoices page.",
            ],
          }
        : {
            title: (
              <>
                Great! So,
                <br />
                What’s next?
              </>
            ),
            subTexts: [
              "Once submitted, Clara will reach out to you and your collaborators if we need additional info or have some questions about your formation.",
            ],
          },
    [typePayment]
  );

  return (
    <TemplateWizard
      props={{
        skeleton: null,
        variant: "twoColumns",
      }}
      buttons={{
        submit: (
          <Button onClick={handleSubmit} variant="primary">
            Submit to Clara
          </Button>
        ),
        cancel: (
          <Button onClick={handlePrev} variant="secondary">
            Previous
          </Button>
        ),
      }}
    >
      <Stack spacing={4}>
        <Typography className={cls["cartTitle"]} weight="bold">
          {texts.title}
        </Typography>
        <>
          {texts?.subTexts?.map((text) => (
            <Typography variant="xxl" color="black" component="p">
              {text}
            </Typography>
          ))}
        </>
      </Stack>
      <div className={cls["rightContentImage"]}>
        <img src={img} alt="" style={{ width: "40.5385rem" }} />
      </div>
    </TemplateWizard>
  );
};

export default SubmitADGMFormStep;

import React, { useState, useMemo } from 'react'
import _ from 'lodash'
import classes from './PartiesUploaded.module.scss'
import Avatar from '../../../../../../modules/generic/components/Avatars/AvatarLabel'
import { Constants } from '../../../../../../v1/utils'
import { useDispatch } from 'react-redux'

const Parties = ({ data, ...props }) => {

    const parties = useMemo(() => {

        const parties = [];

        _.forEach(_.get(data, 'groupCompanies', []), ({ groupCompany }) => {
            const { avatar, name, id } = groupCompany;
            parties.push({ avatar, id, name, role: "Group Company" })
        })


        _.forEach(_.get(data, 'stakeholders', []), st => {
            const { avatar, fullName: name, id, isAnEntity } = st;
            parties.push({ avatar, name, isAnEntity, id, role: Constants.ROLES_LABEL[_.get(st, 'roles[0].name')] })
        })

        return parties;
    }, [JSON.stringify(data)]);

    const dispatch = useDispatch();

    const handleViewParty = (party) => {
        if (_.some(data.stakeholders, st => st.id === party.id)) {

            const page = party.isAnEntity ? Constants.PAGES.viewStakeholderCompany : Constants.PAGES.viewStakeholderIndividual;

            dispatch({ type: "GO_TO", page, params: { id: party.id, tab: "view" } })

        } else {
            dispatch({ type: "GO_TO", page: Constants.PAGES.viewGroupCompany, params: { id: party.id } })
        }
    }


    return (
        <div className={classes.containerTabPartiesUploaded}>
            <div className={classes.initialIcon}>
                <li  >
               {_.get(data, 'file.name', '-')}
                    </li></div>
            {parties.map((party, index) => {
                return (
                    <div className={`${classes.containerAvatar} ${(parties.length === index + 1) && classes.lastParty} `}>
                        <i />
                        <Avatar name={party.name} avatar={party.avatar} showLabel={false} />
                        <div className={classes.containerText} onClick={() => handleViewParty(party)}>
                            <label>{party.name}</label>
                            <span>{party.role}</span>
                        </div>

                    </div>

                )
            })}
        </div>
    )
}

export default Parties;

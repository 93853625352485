import React from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";

const MyTransationSharesCancel = ({ go, ...props }) => {
  const {startupId, ...params} = useParams();

  return (
    <MicroFrontend id="MF_myEquity_myTransation_share_cancel" component={"./CancelSharesView"} mf="capTable" params={{ go, ...props, ...params, startup:{id: startupId} }}/>
  );
};

export default MyTransationSharesCancel;

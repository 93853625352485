import _ from 'lodash';
import React from 'react';
import classesModule from './classes.module.scss';

export interface TemplateFullModalProps  {
  className?: string;
  classNameBotonera?: string;
  buttons?: any;
  children?: any;
}

/**
 - Template for document preview
 **/

const TemplateFullModal: React.FC<TemplateFullModalProps> = ({
  className,
  classNameBotonera,
  buttons = {},
  children,
}) => {

  return (
    <React.Fragment>
      <div className={`${classesModule.TemplateWizard} ${className}`}>
        <div>
          <div className={`${classesModule.Scrollbar}`}>
            <div className={`${classesModule.Content}`}>
              <div className={`${classesModule.Form}`}>
                {children}
              </div>
            </div>
          </div>
          <div className={`${classesModule.Botonera} ${classNameBotonera}`}>
            <div className={classesModule.LeftButtons}>
              {buttons.previous}
              {_.map(buttons.anotherLeft, button => {
                return <React.Fragment>{button}</React.Fragment>
              })}
            </div>
            <div className={classesModule.RightButtons}>
              {_.map(buttons.anotherRight, button => {
                return <React.Fragment>{button}</React.Fragment>
              })}
              {buttons.cancel}
              {buttons.submit}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TemplateFullModal
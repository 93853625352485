export default {
    WIZARD_ADD_TITLE: "Do you have consultants in your startup?",
    WIZARD_ADD_SUBTITLE: "Your consultants",
    WIZARD_CONFIRM_ADD: "Yes, add a consultant",
    WIZARD_ADD_INDIVIDUAL_OR_COMPANY_SUBTITLE: "Add a consultant",
    WIZARD_ADDED_SUBTITLE: "Consultant added!",
    FIRST_STEP_TOOLTIPS:'Ask the experts! <b>Consultants</b> are usually taken on to offer expertise in a specific area, such as IT, marketing or design, often on a short term basis.',
    FIRST_STEP_IS_NOT_EXIST_TOOLTIPS:'If and when you take on consultants, add them here at any time. ',
    THIRD_STEP_NOT_ANOTHER:'Add more consultants to your map at any time. '
}

import { default as React, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import * as Yup from 'yup';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText,addAssistantText } from 'src/modules/generic/store/action';
import Typography from "src/modules/generic/components/Texts/Typography";
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import classesModule from "../classes.module.scss";

function EngageConciergeDelaware() {

  const { next, context, prev,cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const [isAccept, setIsAccept] = useState();
  
  const initialValues = useMemo(() => {
    return context?.EngageConciergeDelaware ?? {}
  }, [context]);
  
  const handleCancel = (values) => {
    cancel();
  }
  const handlePrev = (values) => {
    prev();
  }

  const handleSubmit = async (values) => {
    next(values)
  }
  
  useEffect( ()=>{
    dispatch(
      addAssistantText(
        translate('MODULES.ONBOARDINGCOMPANY.TOOLTIP_REFERAL_DELAWARE')
      )
    );
    return () => {
      dispatch(clearAssistatText())
    }
  },[dispatch])

  const options = [
    {
      value: false,
      label: "No, thanks",
      componentProps:{
        variant: "h4"
      }
    },
    {
      value: true,
      label: "Yes, please email me",
      componentProps:{
        variant: "h4"
      }
    },
  ];  
  
  const schema = Yup.object().shape({
    accept: Yup.boolean().required("Required"),
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={ { mode: 'onChange' } }
    >
      <TemplateWizard
        props={{
          title: 'Let’s see how we can help',
          subTitle: "Engage Clara Concierge",
          skeleton: null,
          variant: 'simple'
        }}
        buttons={{
          previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('BUTTON_NEXT')}</SubmitButton>,
        }}
      >
        <>
          <Typography component='p' variant='xbody'>
            Clara’s concierge may still be able to help you form your company.
          </Typography>
          <ControllerInput
            as={CheckboxButtonSimple}
            className={classesModule.CheckboxButton}
            classNameButton={classesModule.buttonCheckBoxSize}
            name="accept"
            variant="col_1"
            size={"size2"}
            options={options}
            onChange={(value) => {
              setIsAccept(value)
            }}
          />
          {isAccept && 
            <Typography component='p' variant='xbody'>
              Great! One of our agents will be in touch by email to explore this further with you.
            </Typography>
          }
        </>
      </TemplateWizard>
    </Form>
  );
}

export default EngageConciergeDelaware;


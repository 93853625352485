

import _ from "lodash";
import { useEffect, useMemo } from "react";
import { IntercomProvider } from 'react-use-intercom';
import useSession from "../../modules/session/hooks/useSession";
function IntercomComponent() {

  const { user } = useSession()
  const { userId, userHash, autoBoot } = useMemo(() => {
    return {
      userId: _.get(user, "id"),
      userHash: _.get(user, "user_hash"),
      autoBoot: user ? true : false
    }
  }, [JSON.stringify(user)])
  useEffect(() => {
    if (window.Intercom) {
      if (user) {
        window.Intercom('boot',
          { app_id: _.get(window, "env.intercom.app_id"), name: user.fullName, email: user.email, userId: userId, userHash: userHash }
        );
      } else {
        window.Intercom('shutdown');
      }
    }
  }, [user, userId, userHash]);
  return (
    <>
      {_.get(window, "env.intercom.enabled") && userId && userHash && (

        <>
          <IntercomProvider appId={_.get(window, "env.intercom.app_id")} autoBoot={autoBoot} autoBootProps={{ name: user.fullName, email: user.email, userId: userId, userHash: userHash }}>

          </IntercomProvider>
        </>
      )}
    </>);
}
export default IntercomComponent;
import _ from "lodash";
import React, { FC, useMemo } from "react";
import { useParams } from 'react-router-dom';
import useGetDocument from "../../../../../src/hooks/services/modules/Document/useGetDocument";
import useSession from "../../../../../src/modules/session/hooks/useSession";
import DocumentGeneratedActions from "../../../../modules/assistanceV4/components/AssistanceAreas/DocumentGenerated/Actions";
import DocumentUploadTasks from "../../../../modules/assistanceV4/components/AssistanceAreas/DocumentGenerated/Tasks";
import AssistanceHelp from "../../../../modules/assistanceV4/components/AssistanceAreas/Help";
import useTabStatus from "../../hooks/useTabStatus";
import AssistanceFrame2 from "./";

/**
 * Assistance Frame for DocumentsGenerated
 */

export interface AssistanceDocumentGeneratedProps {

}

const AssistanceDocumentGenerated: FC<AssistanceDocumentGeneratedProps> = ({
  ...props
}: AssistanceDocumentGeneratedProps) => {

  const params = useParams();
  const { startupId } = useSession();

  const { data } = useGetDocument({
    agreementId: _.get(params, "id"),
    startupId,
  });

  const lockActions = useMemo(() => {
    return _.get(data, "isLocked", false);
  }, [JSON.stringify(data)]);

  const { showTask, showAction, showHelper, activeTab } = useTabStatus("completed");

  return (
    <AssistanceFrame2
      tasksComponent={lockActions ? DocumentUploadTasks : null}
      showTask={showTask}
    />
  );
};

export default AssistanceDocumentGenerated;

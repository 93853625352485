import { useMemo } from "react";
import TemplateWizardSkeletonMachine from "../../../../generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine";
import { Step1 } from "../steps";

const useSteps = () => {

  return useMemo(() => ({
    getFoundersData: {
      render: TemplateWizardSkeletonMachine,
      renderProps:{
        title: "",
        subTitle: "",
      },
    },
    setNoticesFounder: {
      render: Step1,
    },
    saveFounderNotice: {
      render: TemplateWizardSkeletonMachine,
      renderProps: {
        title: "",
        subTitle: ""
      }
    },
  }), []);
}

export default useSteps;
import CorporateStakeholderAddressForm from "src/components/forms/modules/startups/individualStakeholder/CorporateStakeholderAddressForm"
import Line from "../../../../../../generic/Line/Line";
import { LoadingProvider } from "../../../../../../../modules/generic/hooks/useLoading";
import ModalAssistant from "../../../../../../../modules/generic/components/Modal/ModalAssistant";
import React from "react";
import SkeletonArea from "../../../../../../../modules/generic/components/Skeletons/Overlays/SkeletonArea";
import SkeletonInput from "../../../../../../../modules/generic/components/Skeletons/Overlays/SkeletonInput";
import TemplateWizard from "../../../../../../../modules/generic/templates/Modal/TemplateWizard";
import _ from "lodash";
import useIndividualStakeholderData from "../../../../../../../hooks/services/modules/startups/individual/useIndividualStakeholderData";
import { useParams } from "react-router-dom";
import useSession from "../../../../../../../modules/session/hooks/useSession";
import useTranslate from "../../../../../../../modules/generic/hooks/useTranslate";
import useUpdateStakeholder from "src/modules/startup/onBoarding/core/graphql/mutations/useUpdateStakeholder";

const EditProfile = ({
  showModal,
  open,
  refresh,
  handleCloseModal,
  isManagedByClara
}) => {

  const { translate } = useTranslate();
  const { startupId } = useSession();
  const params = useParams();

  const handleCloseForm = () => {
    refresh && refresh();
    handleCloseModal && handleCloseModal();
  };

  const { updateStakeholder: upsertIndividualStakeholder } = useUpdateStakeholder({
    onCompleted: handleCloseForm,
  });

  const isEmptyDeep = (values) => {
    return _.compact(_.values(values)).length ? values : null;
  };

  const handleSubmit = async (values) => {
    const { otherJurisdiction, jurisdiction } = values
    let stakeholder = {
      ...values,
      id: params.id,
      address: isEmptyDeep(values.address),
      jurisdiction: otherJurisdiction || jurisdiction
    };
    jurisdiction === "OTHER" &&
      !otherJurisdiction &&
      delete stakeholder.jurisdiction;
    delete stakeholder.otherJurisdiction;
    await upsertIndividualStakeholder({
      variables: {
        startupId,
        stakeholderId: params.id,
        stakeholderData: {
          stakeholder
        }
      },
    }, {
      paramsSuccessMessage: { overlayName: stakeholder?.fullName ?? "" }
    }
    );
  };

  const { data: userData, loading } = useIndividualStakeholderData({
    startupId,
    stakeholderId: params.id,
  });

  return (
    <>
      <ModalAssistant open={showModal || open}>
        {loading
          ?
          <>
          </>
          :
          <LoadingProvider>
            <CorporateStakeholderAddressForm
              initialValues={_.get(userData, "getStakeholder", {})}
              handleCloseModal={handleCloseModal}
              handleSubmit={handleSubmit}
              Template={TemplateWizard}
              isManagedByClara={isManagedByClara}
              propsTemplate={{
                title: translate("EDIT_ADDRESS"),
                skeleton: (
                  <>
                    <SkeletonInput count={3}></SkeletonInput>
                    <Line />
                    <SkeletonInput></SkeletonInput>
                    <SkeletonArea noLabel={true}></SkeletonArea>
                  </>
                ),
              }}
            />
          </LoadingProvider>
        }
      </ModalAssistant>
    </>
  );
};

export default EditProfile;

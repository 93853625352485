import useCustomMachine from '../../../../generic/hooks/useCustomMachine'
import React, { FC, useEffect } from 'react'
import Button from '../../../../generic/components/Buttons/Button'
import { StepProps } from "../../../../generic/components/Wizard/Step"
import TemplateWizard from '../../../../generic/templates/Modal/TemplateWizard'
import useTranslate from '../../../../generic/hooks/useTranslate'
import Form from "../../Forms/FormNoticesAddress";

import useUpdateGroupCompany from "../../../../../hooks/services/modules/groupCompany/useUpdateGroupCompany";
import _ from 'lodash';
import { useDispatch } from 'react-redux'
import { addAssistantText,  BubbleCustomChildren, clearAssistatText } from '../../../../generic/store/action';
import Handlebars from 'handlebars';


const Step2: FC<StepProps> = () => {

    const { translate } = useTranslate();

    /*Function*/

    const { next, send, state, prev } = useCustomMachine();

    const { groupCompanyId, startupId } = state.context.data;


    const [ updateCompany ] = useUpdateGroupCompany()

    const getValuesForMutation = (state, valuesStep) => {
        const { context } = state;
        return { ...valuesStep, ...context.data.add_notices }
    };




    const handleSubmit = React.useCallback(
        async (values) => {
            const variables = {
                startupId,
                groupCompanyId,
                groupCompany: getValuesForMutation(state, values)
            }

            console.log(JSON.stringify(variables, null, 2))
            await updateCompany({ variables });
        }, [updateCompany, startupId, state, groupCompanyId]);



    const handleCompleteSubmit = async values => {

        await handleSubmit(values);
        next(values);
    }
    const handleCancel = async values => {
        send("CANCEL");
    }

    const handlePrevious = () => {
        prev();
    }

    const dispatch = useDispatch()

    useEffect(() => {
        const bubbleChild: BubbleCustomChildren = {
            component: <>A recipient is the person specified in agreements to receive <a rel='noopener noreferrer' href={'https://academy.clara.co/article/1P18LE6MKdIPMMJKWtEqh7'} target='_blank'>notices</a> on your company`s behalf.</>
        }
        dispatch(addAssistantText(bubbleChild, "custom-child"))

        return () => {
            dispatch(clearAssistatText())
        }
    }, []);

    return (
        <React.Fragment>
            <Form onCompleteSubmit={handleCompleteSubmit} Template={TemplateWizard} propsTemplate={{
                title: translate(`MODULES.MAP.WIZARD_COMPANY_NOTICES_STEP_2_FORM_TITLE`),
                subTitle: translate(`MODULES.MAP.WIZARD_COMPANY_NOTICES_FORM_SUBTITLE`)
            }}
                buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button>, previous: <Button onClick={handlePrevious} variant="secondary">Previous</Button> }}>

            </Form>

        </React.Fragment>
    )
}

export default Step2;

const useAssetsAndRevenueMapper = ()=>{
    const getInitialData = (groupCompany) =>{
        return {
            assetsAndRevenue:groupCompany?.assetsAndRevenue,
            groupCompany
        }
    }
    const getSubmitData = (context) => {
        const { SharesInCompaniesNotFormed,SelectCompanies } = context;
        return {
            sharesInIncorporatedCompanies: (SelectCompanies.companies || []).map(gc=>gc.id),
            isSharesInNotYetFormedCompanies : {
                question:SharesInCompaniesNotFormed?.isThereCompaniesFormationsShares,
                description:SharesInCompaniesNotFormed?.formationCompaniesHavesShares
            }
        }
    }
    
    return {
        getInitialData,
        getSubmitData
    }
}
export default useAssetsAndRevenueMapper
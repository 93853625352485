import * as Yup from 'yup';


const validateUploadedForm = {

  file: Yup.object({}).required("This field is required").nullable(),
  docType: Yup.object().nullable().shape({
    id: Yup.string().required("This field is required"),
    type: Yup.string().notRequired()
  }),
  entity: Yup.mixed().when('docType', {
    is: values => values && values.id === "MISCELLANEOUS_DOCUMENT",
    then: Yup.string().required("This field is required").nullable(),
    otherwise: Yup.object().required("This field is required").nullable()
  })

};

const validateRemplaceForm = {
  file: Yup.object().required("This field is required").nullable()
}


const getDataServer = `
query getDataServer {

  generate: getMatterDocumentTypes(generated: true) {
    ...DocMatters
  }
  company: getMatterDocumentTypes(company: true, individual: false) {
    ...DocMatters
  }
  individual: getMatterDocumentTypes(individual: true, company: false) {
    ...DocMatters
  }
  individualAndCompany: getMatterDocumentTypes(individual: true, company: true) {
    ...DocMatters
  }

     getMattersList(matterId:"{{matterId}}"){
      KYC {
        individuals {
          id
          firstName
          lastName
        }
        entitiesData {
          individuals {
            id
            firstName
            lastName
          }
          companies {
            id
            companyName
          }
          entities {
            id
            companyName
          }
        }
      }
  
    }
  }

  fragment DocMatters on DocumentType {
    code
    label
  }
  
`;

const getMatterDocument = `
query {
  getMatterDocument(matterId:"{{matterId}}",documentId:"{{documentId}}"){
    id
    kind
		name
    type{
      code
      label
    }
    entity
    entityId
  }
}
`

const upsertMatterDocument = `
mutation upsertMatterDocument(
  $matterId: String!
  $documentId: String
  $kind: String!
  $file: FilePondInputType!
  $docName: String!
  $docType: String!
  $entity: String
  $entityId: String
) {
  upsertMatterDocument(
    matterId: $matterId,
    documentId: $documentId
    kind: $kind
    file: $file
    docName: $docName,
    docType: $docType,
    entity: $entity,
    entityId: $entityId
  ){
    matterDocument{
        id
    }
    upsertFailed
  }
}

`



// code of DocumentTypes
const invalidDocReplaceAndUpload = ["SCOPING_DOCUMENT", "KYC_DOCUMENT", "QUESTIONNAIRE_FORM_DOCUMENT", "ENGAGEMENT_LETTER_DOCUMENT", "ADGM_INCORPORATION_DOCUMENT"];

export {
  getDataServer,
  invalidDocReplaceAndUpload,
  upsertMatterDocument,
  getMatterDocument,
  validateUploadedForm,
  validateRemplaceForm
}
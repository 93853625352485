import _ from 'lodash';
import { useMemo } from 'react';
import useAddQuestionnarie from "../../../../../modules/accelerators/modals/questionnaire/hooks/useAddAcceleratorQuestionnaire";
import useSession from "../../../../session/hooks/useSession";
import useGetQuestionnaire from './useGetAcceleratorQuestionnaire';

const useServices = () => {

  const { manualQuery: getQuestionnarie  } = useGetQuestionnaire({},{});
  const [addNewQuestionsResult] = useAddQuestionnarie({});
  const { startupId, startup } = useSession();
  const acceleratorCode = _.get(startup, "acceleratorDef.code", "");

  return useMemo(() => ({
    getQuestionnaire: async (context, event) => {
      const questionsData = await getQuestionnarie({
        variables:{
          code: acceleratorCode,
        }
      })
      const totalsQuestions = _.get(questionsData, "data.getAcceleratorDef.questionnaire", {});
      if (totalsQuestions) {
        const result = JSON.parse(totalsQuestions);
        return result;
      }
    },
    uploadQuestionnaire: async (context, event) => {
      const allResponses = _.get(context, "results");
      const variables = {
        startupId,
        responses: allResponses,
      };
      await addNewQuestionsResult({ variables });
    }
  }), []);
}

export default useServices;
import React from 'react';
import classes from './DocumentPreview.module.scss';
import DocumentPreviewFile from './DocumentPreviewFile';

const DocumentPreview = ({ header, fileId, fileType, fileName = '', children, style = null }) => {

	return (
		<React.Fragment>
			<div className={classes.DocumentPreview} >
				<div className={`${classes.DocumentPreviewFile} ${header && classes.DocumentsPreviewWithHeader}`}>
					<DocumentPreviewFile
						fileId={fileId}
						fileType={fileType}
						fileName={fileName}
						style={style}
					/>
				</div>
				{children && <div className={classes.DocumentPreviewBotonera}>
					{children}
				</div>}
			</div>
		</React.Fragment>
	)
};

export default DocumentPreview

import React, { FC } from "react";
import classesModules from './MessagesSkeleton.module.scss';
import classes from './Messages.module.scss';
import Skeleton from '@material-ui/lab/Skeleton';
import Title from "../../../../../components/text/Title/Title";
import Icon from "../../../../../modules/generic/components/Icon/index";

/**
 * Template Messages Skeleton
 */

export interface MessagesSkeletonProps {
  isLoading?: boolean;
} 

const MessagesSkeleton: FC<MessagesSkeletonProps> = ({
  isLoading = false,
  ...props
}:MessagesSkeletonProps) => {

  return (
    <>
      {isLoading && (
        <div className={classesModules.messagesAreaContainer}>
          <div className={classes.container}>
            <div className={classes.containerMessages}>
              <div className={classes.containerTitle}>
                <Title><Skeleton variant="text" height={"2.3846rem"} width={"10rem"}/></Title>
              </div>
              <div className={classes.containerPanelMessages}>
                <div className={classes.containterPanelMessages}>
                  <div className={classes.containerSearchAndButtonFilter}>
                    <div className={classesModules.divsearch}>
                      <Skeleton variant="rect" height={"3.0769rem"} width={"18.0769rem"}/>
                      <div className={classesModules.iconContainer}>
                        <Skeleton variant="text" height={"2.3077rem"} width={"10rem"}/>
                      </div>
                    </div>
                  </div>
                  <div className={classesModules.TabArea}>
                    <div className={`${classesModules.skelTabs}`}>
                      <div className={`${classesModules.skelTabsSpace}`}>
                        <Skeleton variant="text" height={"1.3077rem"} width={"3.8462rem"}/>
                      </div>
                      <div className={`${classesModules.skelTabsSpace}`}>
                        <Skeleton variant="text" height={"1.3077rem"} width={"6.3846rem"}/>
                      </div>
                      <div className={`${classesModules.skelTabsSpace}`}>
                        <Skeleton variant="text" height={"1.3077rem"} width={"5.9231rem"}/>
                      </div>
                    </div>
                  </div>
                  <div className={classes.containerZonaMessages}>
                    <div className={`${classesModules.containerZoneMessagesPanel}`}>
                      {Array(3).fill(1).map((el, i) =>
                        <div className={classesModules.containerMessage} key={i}>
                          <div className={classesModules.iconMessage}>
                            <Icon color={"#e4e4e4"} icon={"Assistant"} size={"1.8462rem"} isClara />
                          </div>
                          <div className={`${classesModules.containerCardMessage}`}>
                            <Skeleton variant="rect" height={"1.1538rem"} width={"1.1538rem"}/>
                            <label>
                              <Skeleton variant="text" height={"1.0769rem"} width={"20rem"}/>
                            </label>
                            <Skeleton variant="rect" height={"1.1538rem"} width={"1.1538rem"}/>
                            <p className={classesModules.textMessage}>
                              <Skeleton variant="text" height={"0.8462rem"} width={"19rem"}/>
                              <Skeleton variant="text" height={"0.8462rem"} width={"19rem"}/>
                            </p>
                            <label className={classesModules.dateMessage}><Skeleton variant="text" height={"0.8462rem"} width={"11.5385rem"}/></label>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.containerZoneMessages}>
                <div className={classesModules.containerThreadMessage}>
                  {Array(2).fill(1).map((el, i) =>
                    <div className={classesModules.containerMessageTemplateWithIcon} key={i}>
                      <div className={classesModules.containerIcon}>
                        <Icon color={"#e4e4e4"} icon={"Assistant"} size={"1.8462rem"} isClara />
                      </div>
                      <div className={`${classesModules.containerMessageTemplate} ${classesModules.containerStakeholderTemplate}`}>
                        <label>
                          <Skeleton variant="text" height={"1.0769rem"} width={"15.3846rem"}/>
                        </label>
                        <ul>
                          <li>
                            <Skeleton variant="text" height={"0.8462rem"} width={"30.7692rem"}/>
                          </li>
                          <li>
                            <Skeleton variant="text" height={"0.8462rem"} width={"30.7692rem"}/>
                          </li>
                          <li>
                            <Skeleton variant="text" height={"0.8462rem"} width={"30.7692rem"}/>
                          </li>
                        </ul>
                        <label><Skeleton variant="text" height={"0.8462rem"} width={"11.5385rem"}/></label>
                      </div>
                    </div>
                  )}
                  <div className={classesModules.containerButtonEvent}>
                    <div className={classesModules.containerIcon}>
                      <Icon
                        color={"#e4e4e4"}
                        icon={"Assistant"}
                        size={"1.8462rem"}
                        isClara
                      />
                    </div>
                    <Skeleton variant="rect" height={"3.0769rem"} width={"10rem"}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MessagesSkeleton;

import React, {useState} from 'react';
import classes from './PortalDocuments.module.scss';
import {Constants} from '../../../../../utils/constants';
import helper from '../../../../../utils/helper';
import greenCheck from '../../../../../../images/greenCheck.svg';
import {Avatar} from "../../../../index";
import _ from "lodash";
import {useDispatch} from "react-redux";
import Modal from '../../../../Modal/Modal/Modal';
import notification from "../../../../../utils/notification";
import ServerConnect from "../../../../../utils/ServerConnect";
import Loading from '../../../../Loading/Loading';


const SignatoriesView = (props) => {

    const { data } = props;
    const [showModal, setShowModal] = useState(false);
	const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();



    const handleRequestSignatories = () => {

        const mutation = `mutation sendRequestSignaturesClientApproval($matterId: ID!) {
                sendRequestSignaturesClientApproval(matterId: $matterId){
                    incorporation{
                        status
                    }
                }
              }`;
		setLoading(true);
        const matterId = props.id;
        ServerConnect.graphQlMutation(mutation, { matterId })
            .then(result => {
				setLoading(false);
                setShowModal(true);
            }).catch(err => {
				setLoading(false);
                notification.sendNotification("ERROR", 'error', 4990);
            })
    }





    return (
        <React.Fragment>
			<Loading show={loading} showChildren={true} loadingStyle={{ position: "fixed", zIndex: 1000 }}>
				<div className={classes.container}>
					<p className={classes.title}>{helper.getTranslateTextByKey(Constants.TRANSLATES.SIGNATORIES_UPPER_TITLE)}</p>
					<p className={classes.subTitle}>{helper.getTranslateTextByKey(Constants.TRANSLATES.SIGNATORIES_SUBTITLE)}</p>
					<hr className={`${classes.Separator}`} />
				</div>

				<div className={classes.container}>
					{_.get(props.data, 'documents', []).map(doc => {
						return (
							<React.Fragment>
								<div className={classes.containerDocumentName}>
									<img className={classes.greenCheck} src={greenCheck} /> <p className={classes.docName}>{doc.name}</p>
								</div>
							</React.Fragment>
						)
					})}
					<hr className={`${classes.Separator}`} />
				</div>
				<div className={classes.container}>
					<p className={classes.approvalTitle}>{helper.getTranslateTextByKey(Constants.TRANSLATES.SIGNERS_TITLE)}</p>
				</div>
				{_.get(props.data, 'signers', []).map(signer => {
					return (
						<div className={classes.container}>
							<div className={classes.FieldValueContainer}>
								<label>{helper.getTranslateTextByKey(Constants.TRANSLATES.MAIN_CLIENT)}</label><Avatar className={classes.Avatar} name={`${_.get(signer, 'fullName')}`} showLabel={true} />
							</div>

							<div className={classes.FieldValueContainer}>
								<label>{helper.getTranslateTextByKey(Constants.TRANSLATES.KYC_ENTITY_EMAIL_ADDRESS_FIELD_VIEW)}</label><span>{_.get(signer, 'email')}</span>
							</div>
							<div className={classes.containerDocuments}>
								{_.get(signer, 'documents', []).map(doc => {
									return <span className={classes.docNames}>{_.get(doc, 'name')}</span>
								})}
							</div>
							<hr className={`${classes.Separator}`} />
						</div>
					)
				})}

				<div className={`${classes.container} ${classes.botonera}`}>
					<button onClick={() => dispatch({ type: 'GO_TO', page: Constants.PAGES.viewMatter, params: { id: props.id, tab: 'incorporation' } })} className={`${classes.buttonCancel} `}>
						<span>{helper.getTranslateTextByKey(Constants.TRANSLATES.BUTTON_CANCEL)}</span>
					</button>
					<button onClick={handleRequestSignatories} className={`${classes.buttonBlue} `}>
						<span>{helper.getTranslateTextByKey(Constants.TRANSLATES.REQUEST_SIGNATURES)}</span>
					</button>
				</div>
				<Modal open={showModal} showHeader={false} className={{ modal: classes.modal }}>
					<div className={classes.containerModal}>
						<span> <img className={classes.greenCheck} src={greenCheck} />Your {_.get(props.data, 'signers', []).length} DocuSign request has been sent! </span>
						<div className={classes.modalContainerButton}>
							<button className={`${classes.buttonBlue} ${classes.buttonModal}`} onClick={() => dispatch({ type: 'GO_TO', page: Constants.PAGES.viewMatter, params: { id: props.id, tab: 'incorporation' } })} >Return to Incorporation</button>
						</div>
					</div>
				</Modal>
			</Loading>
        </React.Fragment >
    )
}

export default SignatoriesView;

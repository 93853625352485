import useMachine from '../../../../../generic/context/MachineContext/useMachine';
import FormSelectSourceOfFunds from "../../../Forms/FormSelectSourceOfFunds";
import React, { useState } from 'react';
import Button from "../../../../../generic/components/Buttons/Button";
import useTranslate from '../../../../../generic/hooks/useTranslate';
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import useModal from "../../../../../generic/hooks/useModal";

/**
 * Step para consultar los datos de la persona a ver el scoring
 * @param {*} param0
 * @returns
 */

function SelectJurisdiction({ open: openParam = false }) {
  
  const { next, send } = useMachine();
  const { translate } = useTranslate()
  const [open, setOpen] = useState(openParam);
  const { closeModal: onClose } = useModal();

  const handleCancel = (values) => {
    onClose();
    setOpen(false);
  }

  const handleCompleteSubmit = async (values) => {
    next(values)
  }

  return (
    <FormSelectSourceOfFunds
      isEdit={false}
      buttons={{
        cancel: (
          <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>
        ),
      }}
      onCompleteSubmit={handleCompleteSubmit}
      propsTemplate={{
        title: translate('MODULES.STAKEHOLDER.EDIT_SOURCE_OF_FUNDS.TITLE'),
        subTitle: translate('MODULES.STAKEHOLDER.EDIT_SOURCE_OF_FUNDS.SUBTITLE')
      }}
      Template={TemplateWizard}
    />
  );
}
export default SelectJurisdiction;

import useGetStakeholder from './graphql/useGetStakeholder';
import { useMemo } from 'react';
import useSession from 'src/modules/session/hooks/useSession';
import useUpdateStakeholder from './graphql/useUpdateStakeholder';

const useServices = ({ initialValues }) => {
  const { query } = useGetStakeholder({});
  const { startupId } = useSession();
  const stakeholderId = initialValues.stakeholderId
  const mutation = useUpdateStakeholder()

    const initialData = async (context, event) => {
      console.log('initialData', {startupId, stakeholderId})
      const data = await query({startupId, stakeholderId});
      console.log(data)
        return {
          fitAndProperStatement:data?.stakeholder?.fitAndProperStatement
        }
    }

    const SaveStep = async (context, event) => {
      const variables =  { 
        startupId, 
        stakeholderId,
        stakeholderData: {
          stakeholder:{
          fitAndProperStatement:context.FitAndProper
          } 
        }
      }


      return mutation({variables});
    }

    return { initialData, SaveStep }
}

export default useServices;

import React from 'react'
import * as Yup from 'yup'
import Form from '../../../../components/forms/Form/Form'
import useTranslate from '../../../generic/hooks/useTranslate'
import TextInput from '../../../../components/inputs/Text/TextInput'
import ControllerInput from '../../../generic/components/Inputs/ReactHookForm/ControllerInput'
import TemplateLabel from '../../../generic/templates/Label'
import SubmitButton from '../../../generic/components/Buttons/SubmitButton'
import FormProps from '../../../generic/forms/interfaces/FormProps';
import TemplateFormDefault from "../../../generic/templates/Modal";
import cls from './Form.module.scss';
import useGetGroupCompanies from '../../graphql/groupCompany/query/useGetGroupCompanies'
import CheckboxInput from '../../../generic/components/Inputs/Checkbox'
import { useSelector } from 'react-redux'
import _ from 'lodash';
import { GroupCompanyBO } from "../../../../modules/startup/core/type/startup/groupCompany";
import useSession from '../../../session/hooks/useSession'

const schema = Yup.object().shape({
    company: Yup.array().min(1, 'This field is required').required('This field is required')
})

const FormSelectCompany: React.FC<FormProps> = ({
    initialValues: defaultValues = {company:[]},
    onCompleteSubmit,
    Template = TemplateFormDefault,
    buttons = {},
    buttonSubmitProps,
    propsTemplate }) => {


    const { translate } = useTranslate();

    const handleSubmit = async values => {
        onCompleteSubmit && await onCompleteSubmit(values);
    }


    const { startupId } = useSession();



    const { data } = useGetGroupCompanies({ variables: { startupId } })


    const optionsGroupCompanies = React.useMemo(() => {
        let companies = [];
        _.forEach(data,(gc:GroupCompanyBO)=>{
            if (gc.kind === "INCORPORATED"){
                companies.push({value:gc,label:gc.name,disabled: false});
            }
        })
        return companies;
    }, [JSON.stringify(data)])

    const functionCompare = React.useCallback(
        (valueA, valueB) => {
            return valueA.id === valueB.id;
        }, [])


    return (
        <Form
            defaultValues={defaultValues}
            schema={schema}
            onSubmit={handleSubmit}
            onlySendDirty={false}
            optionsForm = {{mode:"onChange"}}
        >

            <Template buttons={{ ...buttons, submit: <SubmitButton  {...buttonSubmitProps}>Next</SubmitButton> }} props={propsTemplate}>
                <TemplateLabel
                    label={'Choose all that apply'}
                    isRequired
                    className={`pt-5  ${cls.TemplateLabel}`}
                >
                    <ControllerInput
                        render={CheckboxInput}
                        options={optionsGroupCompanies}
                        name='company'
                        functionCompare={functionCompare}
                        defaultlabel={''}
                    />
                </TemplateLabel>

            </Template>

        </Form>
    )

}

export default FormSelectCompany

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import event from "../../../v1/utils/event";
import { useGetActiveSubscription } from "../../startup/graphql/startup/useGetStartup";
import { Constants } from "./../../../v1/utils/constants";
import useSession from "./useSession";

function useSessionDataManager() {
  const dispatch = useDispatch();
  const { startupId, updateStartup } = useSession();
  const { manualQuery, data } = useGetActiveSubscription({});

  useEffect(() => {
    if (startupId) {
      manualQuery({ variables: { startupId: startupId } });
    }
  }, [startupId]);

  useEffect(() => {
    //TODO: Remove when implements subscriptions
    event.on(Constants.EVENTS.REFRESH_ACTIVE_SUBSCRIPTION, (params) => {
      manualQuery({ variables: params });
    });
    return () => {
      event.remove(Constants.EVENTS.REFRESH_ACTIVE_SUBSCRIPTION, () => {});
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      if (startupId) {
        updateStartup(data);
      }
    }
  }, [data]);

  return { startupId };
}

export default useSessionDataManager;

import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import * as Yup from "yup";
import {
  addAssistantText,
  clearAssistatText,
} from "src/modules/generic/store/action";
import AddressInput from "src/components/forms/modules/startups/individualStakeholder/AddressInput";
import Button from "src/modules/generic/components/Buttons/Button";
import Form from "src/components/forms/Form/Form";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import Checkbox from 'src/components/inputs/Checkbox/Checkbox';
import GroupCompany from 'src/components/modules/clara/startups/entities/classes/GroupCompany';

export default function ReviewAddressesTechLicenseAddressesStepForm() {
  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    let formattedValues = _.cloneDeep(context?.initialData?.groupCompany);

    if (formattedValues?.mailingAddress?.country?.code) {
      formattedValues.mailingAddress.country =
        formattedValues?.mailingAddress?.country?.code;
    } else {
      delete formattedValues.mailingAddress;
    }

    if (formattedValues?.locationAccountingRecordsAddress?.country?.code) {
      formattedValues.locationAccountingRecordsAddress.country =
        formattedValues?.locationAccountingRecordsAddress?.country?.code;
    } else {
      delete formattedValues.locationAccountingRecordsAddress;
    }

    if (formattedValues?.locationCompanyRecordsAddress?.country?.code) {
      formattedValues.locationCompanyRecordsAddress.country =
        formattedValues?.locationCompanyRecordsAddress?.country?.code;
    } else {
      delete formattedValues.locationCompanyRecordsAddress;
    }
    return formattedValues;
  }, [context]);

  const handleCancel = (values) => {
    cancel();
  };

  const handleSubmit = async (values) => {
    next(values);
  };

  useEffect(() => {
    // TODO ASK THE URL FOR THE LINK
    dispatch(
      addAssistantText(
        translate("MODULES.RENEWALS_ADGM.REVIEW_ADDRESS.SPV_TOOLTIP_1"),
        "alert"
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const schema = Yup.object().shape({
    mailingAddress: Yup.object().shape({
      country: Yup.string().required("This field is required").nullable(),
      street: Yup.string().required("This field is required").nullable(),
      city: Yup.string().required("This field is required").nullable(),
      state: Yup.string().nullable(),
      zipCode: Yup.string().nullable(),
    }),
    locationCompanyRecordsAddress: Yup.object().shape({
      country: Yup.string().required("This field is required").nullable(),
      street: Yup.string().required("This field is required").nullable(),
      city: Yup.string().required("This field is required").nullable(),
      state: Yup.string().nullable(),
      zipCode: Yup.string().nullable(),
    }),
    locationAccountingRecordsAddress: Yup.object().shape({
      country: Yup.string().required("This field is required").nullable(),
      street: Yup.string().required("This field is required").nullable(),
      city: Yup.string().required("This field is required").nullable(),
      state: Yup.string().nullable(),
      zipCode: Yup.string().nullable(),
    }),
  });

  const formRef = React.useRef();
  const [disabledMailAddress, setDisabledMailAddress] = React.useState(false);
  const canCopyRegisterAddress = useMemo(() => {
    const gc = new GroupCompany(context?.initialData?.groupCompany);

    return gc.isManagedByClara() && gc.isTSL();
  }, [context?.initialData?.groupCompany]);
  const handleCheckMailingAddressTheSameAsRegisteredAddress = useCallback((value) => {
    
    setDisabledMailAddress(value);
    if (formRef?.current && value) {
      const registerAddress = context?.ReviewAddressesTechLicenseStepForm?.registeredAddress;
      formRef?.current?.setValue("mailingAddress", {
        ...registerAddress,
        useRegisteredAddress: true,
      });
    }
  }, [context]);
  return (
    <Form
      ref={formRef}
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: "Review and confirm the company addresses",
          subTitle: "Review addresses",
        }}
        buttons={{
          submit: (
            <SubmitButton>
              {translate("MODULES.CLARA.BTN_CONFIRM")}
            </SubmitButton>
          ),
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("MODULES.CLARA.BTN_CANCEL")}
            </Button>
          ),
        }}
      >
        {canCopyRegisterAddress && (
          <div>
            <ControllerInput
              label="The mailing address is the same as the registered address."
              isMultiple={false}
              name="mailingAddress.useRegisteredAddress"
              render={Checkbox}
              onChange={handleCheckMailingAddressTheSameAsRegisteredAddress}
            />
          </div>
        )}
        <AddressInput
          name="mailingAddress"
          label={{ country: "Mailing address" }}
          zipCodeOptional
          isRequired
          disabled={disabledMailAddress}
          banner={true}
          bannerTitle={translate("MODULES.RENEWALS_ADGM.COMMON.BANNER_1", {
            currency: "USD",
            price:
              context?.pincasData?.pincasData?.CHANGE_COMPANY_MAILING_ADDRESS
                ?.value ?? 0,
            button: "Confirm",
          })}
        />
        <div />
        <AddressInput
          name="locationCompanyRecordsAddress"
          label={{ country: "Alternative location of company records" }}
          zipCodeOptional
          isRequired
          disabled
          banner={true}
          bannerTitle={translate("MODULES.RENEWALS_ADGM.COMMON.BANNER_1", {
            currency: "USD",
            price:
              context?.pincasData?.pincasData
                ?.CHANGE_COMPANY_LOCATION_COMPANY_RECORDS_ADDRESS?.value ?? 0,
            button: "Confirm",
          })}
        />
        <div>&nbsp;</div>
        <AddressInput
          name="locationAccountingRecordsAddress"
          label={{ country: "Location of accounting records" }}
          zipCodeOptional
          isRequired
        />
        <div>&nbsp;</div>
      </TemplateWizard>
    </Form>
  );
}

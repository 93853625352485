import useCustomMachine from '../../../../generic/hooks/useCustomMachine'
import React, { FC } from 'react'
import Button from '../../../../generic/components/Buttons/Button'
import { StepProps } from "../../../../generic/components/Wizard/Step"
import TemplateWizard from '../../../../generic/templates/Modal/TemplateWizard'
import useTranslate from '../../../../generic/hooks/useTranslate'
import Form from "../../Forms/FormGrantAgreementSelect";
import _, { get } from 'lodash';
import useUpsertEquity from '../../../graphql/equity/mutation/useUpsertEquity';
import {useDispatch, useSelector} from 'react-redux'
import { useParams } from 'react-router-dom'
import {clearAssistatText} from "../../../../generic/store/action";
import useSession from '../../../../session/hooks/useSession'
import moment from "moment";
const Step2: FC<StepProps> = () => {

    const { translate } = useTranslate();

    /*Function*/

    const { mutation: upsertEquityOption } = useUpsertEquity();

    const { next, send, state, prev } = useCustomMachine();

    const { startupId } = useSession();

    

    const dispatch=useDispatch()

    const { numbersOfIteration, companies } = state.context;

    const groupCompanyId = React.useMemo(() => {
        return get(companies, `[${numbersOfIteration}].id`);
    }, []);

    const parseValuesToEquityData = (value, state) => {
        const { details_share_options } = state.context.data;
        const { uploadedFiles = [], documentsUploaded={} } = value;
        const documents = {
            uploadedFiles,
            documentsUploaded:[documentsUploaded]
        }
        return { ...details_share_options,issuedDate:moment(_.get(details_share_options,'issuedDate')).format('YYYY-MM-DD'), documents };
    }

    const handleCompleteSubmit = async values => {
        const variables = {
            startupId,
            groupCompanyId,
            owner: { type: 'stakeholder', id:get(state,"context.paramsData.stakeholderId") },
            shareType: 'OPTION',
            isThereSIP:false,
            equityData: parseValuesToEquityData(values, state)
        };
        await upsertEquityOption({ variables });
        next(values);
        dispatch(clearAssistatText())
    }
    const handleCancel = async values => {
        send("CANCEL");
    }

    const handlePrev = () => {
        prev();
    }


    return (
        <React.Fragment>
            <Form
                initialValues={get(state, 'context.data.personal_information', {})}
                onCompleteSubmit={handleCompleteSubmit}
                Template={TemplateWizard}
                propsTemplate={{
                    title:translate('MODULES.STAKEHOLDER.SHARE_OPTIONS.FIFTH_STEP_B.TITLE'),
                    subTitle: translate('MODULES.STAKEHOLDER.SHARE_OPTIONS.FIFTH_STEP_B.SUBTITLE')
                }}
                buttons={{
                    cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button>,
                    previous: <Button onClick={handlePrev} variant="secondary">Previous</Button>
                }}>
            </Form>

        </React.Fragment>
    )
}

export default Step2;

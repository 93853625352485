import _ from 'lodash';
import React, {useCallback, useMemo} from 'react';
import { useDispatch } from "react-redux";
import * as Yup from 'yup';
import useDeleteDocument from "../../../../../../../hooks/services/modules/Document/useDeleteDocument";
import ControllerInput from '../../../../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useSession from '../../../../../../../modules/session/hooks/useSession';
import { RowAdd } from '../../../../../../forms/contents';
import Form from '../../../../../../forms/Form/Form';
import SecondaryButton from '../../../../../../generic/Buttons/SecondaryButton/SecondaryButton';
import SubmitButton from '../../../../../../generic/Buttons/SubmitButton/SubmitButton';
import Checkbox from '../../../../../../inputs/Checkbox/Checkbox';
import TextareaInput from '../../../../../../inputs/Textarea/TextareaInput';
import NewModalForm from '../../../../../../templates/NewModalForm/NewModalForm';
import Text from '../../../../../../text/Text/Text';
import Title from '../../../../../../text/Title/Title';
import classes from './DeleteGrant.module.scss';
import {goToPage} from '../../../../../../../store/actions/page';
import {Constants} from '../../../../../../../v1/utils';

const DeleteGrant = ({refresh, open, handleCloseModal, onComplete, initialValues, goTo, redirect, ...props }) => {
    
    const { startupId } = useSession();
    const dispatch = useDispatch();
    const form = useMemo(() => {return initialValues},[])

    const onCompletedUpdate = () => {
      refresh &&  refresh()
        onComplete && onComplete()
    }

	const callbackBeforeEvict = useCallback(() => {
		return new Promise((resolve,reject)=>{
			if (redirect) {
				goTo ? goToPage(dispatch, goTo) : goToPage(dispatch, Constants.PAGES.MODULES.MAP.maps);
			}
			resolve(true)
		})
	},[dispatch, goTo, redirect])

	const {mutation: deleteDocument, loading} = useDeleteDocument({
		onCompleted: onCompletedUpdate,
		successMessage: "NOTIFICATION_SUCCESS_DELETED",
		paramsSuccessMessage: form,
		callbackBeforeEvict: callbackBeforeEvict
	});

    const deleteEntity =async (values) => {
		await deleteDocument({
				startupId,
				agreementId: initialValues.id,
				reason:values.reason
		})
        handleCloseModal()
    }

    return (

        <Form initialValues={{confirmDelete: false}} schema={
            Yup.object().shape({
            reason: Yup.string().required('Required'),
            confirmDelete: Yup.bool()
				.required('You must confirm this action')
				.oneOf([true], 'You must confirm this action')
			})}
            onSubmit={deleteEntity}
        >
            {/*console.log('za:', initialValues)*/}
            <NewModalForm
                header={<Title><Text uuid={"MODAL_DELETE_GRANT"} params={{ grant: _.get(initialValues, 'name') }} /></Title>}
                content={<React.Fragment>
                    <div className={classes.deleteInfo}>
                        <Text uuid={'MODAL_DELETE_GRANT_INFO_TEXT'} params={{ grant: _.get(initialValues, 'name'), sip: _.get(initialValues,'parentDocument.name') }} />
                    </div>
                    <div className={classes.textArea}>
                        <RowAdd
                            asterisk={true}
                            label={"MODAL_DELETE_GRANT_REASON_LABEL"}
                            input={
                                <ControllerInput
                                    as={TextareaInput}
                                    name="reason"
                                    defaultValue={''}
                                    placeholder={"Please let us know the reason why you are deleting this Grant..."}
                                    onChange={(e) => {

                                    }}
                                />
                            } />
                    </div>

                    <div>

                        <ControllerInput
                            render={Checkbox}
                            showErrorMessage={true}
                            name={'confirmDelete'}
							classNameError={classes.errorCheckboxLabel}
                        />
                        <span className={classes.textCheckbox}><Text uuid={"MODAL_DELETE_GRANT_CHECKBOX_LABEL"} params={{ grant: _.get(initialValues, 'name') }} /></span>

                    </div>

                </React.Fragment>}
                botonera={<React.Fragment>
                    <SecondaryButton onClick={() => handleCloseModal()}><Text uuid={'BUTTON_CANCEL'} /></SecondaryButton>
                    <SubmitButton ><Text uuid={"BUTTON_DELETE"} /></SubmitButton>
                </React.Fragment>}
            />

        </Form >

    )
};

export default DeleteGrant;

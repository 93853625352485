import React, { useEffect } from 'react';
import useLazyQuery from "../../../custom/useLazyQuery";
import _ from 'lodash'
import {gql} from "@apollo/client";

export const query =gql`
    query getDocumentList($startupId:ID!){
        documentList(startupId:$startupId) {
            name
            status
            kind
            params
            groupCompanies{
                groupCompany{
                    id
                    country {name code alpha3}
                    registeredNumber
                    type
                    avatar
                    name
                    registeredAddress{
                        city
                        country {name code alpha3}
                        zipCode
                        street
                    }
                }
                signedBy{
                    id
                    fullName
                    avatar
                    email
                }
            }
            stakeholders{
                id
            }
            parties
            type {
                code
                label
                allowedActions
            }
            id
        }
    }
`

const useGetDocumentListLazy = (variables, config) => {

    const {manualQuery, loading, error, data, ...pre } = useLazyQuery(query, variables, config);


    return {manualQuery, loading, error, data: _.get(data, 'documentList'), ...pre };
}
export default useGetDocumentListLazy;

import useMutation from '../../../../../hooks/custom/useMutation'

export const UPSERT_ADD_QUESTIONNARIE = `
  mutation insertAcceleratorResponses($startupId: ID, $responses: [QuestionResponseInput]) {
    insertAcceleratorResponses(startupId: $startupId, responses: $responses) {
      name
      acceleratorData {
        responses {
          _id
          response
          explainComment
        }
      }
    }
  }
`;

const useAddQuestionnarie = (props = {}) => {
  const mutation = useMutation(
    UPSERT_ADD_QUESTIONNARIE,
    { showSuccessNotification: false, ...props },
  )
  return mutation
}

export default useAddQuestionnarie

import { get } from 'lodash';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../../../generic/components/Buttons/Button';
import { StepProps } from "../../../../../generic/components/Wizard/Step";
import useCustomMachine from '../../../../../generic/hooks/useCustomMachine';
import { addAssistantText, clearAssistatText } from '../../../../../generic/store/action';
import TemplateWizard from '../../../../../generic/templates/Modal/TemplateWizard';
import Form from "../../components/Forms/FormVisa";
import Passport from '../../public/Passport.svg';

const Step4: FC<StepProps> = () => {

  const { next, send, state } = useCustomMachine();

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(addAssistantText(`A copy of a UID Stamp is required for all UAE Nationals.`));
    return () => {
      dispatch(clearAssistatText());
    }
  }, [])


  const handleCompleteSubmit = async values => {
    next(values);
  }

  const handleCancel = async values => {
    send("CANCEL");
  }

  return (
    <React.Fragment>
      <Form
        initialValues={get(state, 'context.data.uploadDocument', {})}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: 'Add their old passport',
          subTitle: 'UID stamp'
        }}
        image={<img src={Passport} alt={""}/>}
        buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }}>
      </Form>
    </React.Fragment>
  )
}

export default Step4;

import React from 'react';
import _ from 'lodash';
import { Table } from '../../../../../../../v1/components';
import classes from "./FoundersProfileTable.module.scss";
import Button from "../../../../../../../modules/generic/components/Buttons/Button";
import NameFounderWithTag from '../../components/NameFounderWithTag';
import useTranslate from '../../../../../../../modules/generic/hooks/useTranslate';
import useModal from "../../../../../../../modules/generic/hooks/useModal";
import RemoveAsFounder from "src/modules/startup/Stakeholder/RemoveAsFounder";
import useBusinessRules from 'src/modules/clara/hooks/useBusinessRules';

const FoundersProfileTable = ({ foundersList }) => {
  const { translate } = useTranslate();
  const { openModal, closeModal } = useModal();
  const [hasMoreFounders, setHasMoreFounders] = React.useState(false);
  const { isNotViewer } = useBusinessRules()
  React.useEffect(() => {
    setHasMoreFounders(foundersList.length > 1);
  }, [foundersList]);

  const fields = [
    {
      label: "Name",
      key: "name",
      order: false,
      "decorator": (props) => {
        return (
          <NameFounderWithTag
            fullName={props.row.fullName}
            avatar={props.row.avatar}
            isFounder={props.row.isFounder}
            email={props.row.email}
          />
        )
      },
      className: classes.Col2
    },
    {
      label: "",
      key: "removeFounder",
      order: false,
      "decorator": (props) => (

        <div className={classes.resendInvite}>
          {isNotViewer() && (
            <Button
              variant='cardWarning'
              onClick={() => { openModal(RemoveAsFounder, { stakeholder: props.row, canRemove: hasMoreFounders, handleCloseModal: closeModal }) }}
            >
              {translate("VIEW_ROLES_TAB_BUTTON_REMOVE_AS_FOUNDER")}
            </Button>
          )}
        </div>
      ),
      className: classes.Col2
    },
  ]

  return (
    <React.Fragment>
      <div className={classes.contentView}>
        <React.Fragment>
          <Table
            fields={fields}
            data={foundersList}
            className={classes.Table}
          />
        </React.Fragment>
      </div>
    </React.Fragment>
  )
};

export default FoundersProfileTable;

export default {
//   selectJurisdiction: {
//     path: "/auth/formation/select_jurisdiction",
//   },
  formJurisdictionDetails: {
    path: "/auth/formation/form_jurisdiction_details/:country/:jurisdiction/:matterId?",
  },
  blockAdgm: {
    path: "/auth/formation/block_adgm/:matterId?",
  },
  jurisdictionDone: {
    path: "/auth/formation/jurisdiction_done/:matterId?",
  },
  scoping: {
    path: "/auth/formation/scoping/intro/:matterId?",
  },
  introduction: {
    path: "/auth/formation/introduction",
  },
};

import {useMemo}  from "react"
import useSecurity from './useSecurity';
export default function useRoleAccess(action, params) {
    const {hasRoleAccess} = useSecurity();
    const roleAccess = useMemo(()=>{
        return hasRoleAccess(action, params)
    },[action,JSON.stringify(params)])
    

    return  roleAccess ;
}

import { useMemo } from 'react';
import useSession from '../../../../session/hooks/useSession'
import _ from 'lodash';
import useGetStakeholdersGroupCompaniesLazy from 'src/modules/startup/GroupCompany/RecipientForNotices/hooks/useGetStakeholdersGroupCompaniesLazy';
import { useParams } from 'react-router-dom';
import useUpsertFormationCompany from 'src/modules/startup/GroupCompany/graphql/mutations/useUpsertFormationCompany'

const useServices = ({initialValues}) => {

  const { manualQuery: manualQueryStakeholdersGroupCompanies } = useGetStakeholdersGroupCompaniesLazy();
  const { startupId } = useSession();
  const params = useParams();
  const groupCompanyId= initialValues?.id ?? params?.id;
  const [upsertFormationCompany] = useUpsertFormationCompany({ showSuccessNotification: false }, {})

  return useMemo(() => ({
    getFoundersData: async (context, event) => {
      const stakeholdersGroupCompanies = await manualQueryStakeholdersGroupCompanies({ 
        variables: { startupId, groupCompanyId: groupCompanyId } 
      })
      const result = {
        stakeholders: _.get(stakeholdersGroupCompanies.data, 'stakeholdersWithRecNoticesCompleted', []),
      };
      return result;
    },
    saveFounderNotice: async (context, event) => {
      let newNotices = {
        recipientNotice: {
          fullName: _.get(context, 'resultChoice[0].fullName', ''),
          email: _.get(context, 'resultChoice[0].email', ''),
          address: {
            ..._.get(context, 'resultChoice[0].address', ''),
            country: _.get(context, 'resultChoice[0].address.country.code', ''),
          },
        }
      }
      await upsertFormationCompany({
        variables: { 
          startupId, 
          groupCompanyId: groupCompanyId, 
          groupCompanyData: newNotices,
        },
      });
    }
  }), []);
}

export default useServices;

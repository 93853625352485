import React, { useEffect, useState, useMemo } from 'react';
import { ButtonModalOptions, Loading, PopoverOption } from '../../../../index';
import classes from './IncorporationPortal.module.scss';
import TableIncorporation from './TableIncorporation';
import { MenuListAgent } from './components'
import _ from 'lodash';
import Mustache from 'mustache';
import ServerConnect from "../../../../../utils/ServerConnect";
import { ModalADGMBundle, ModalAssign, ModalChangedNumber, ModalRegenerateADGMDocuments, ModalCancelEnvelope, ModalIncorporationPack } from './FactoryModals';
import { Constants } from '../../../../../utils/constants';
import { useDispatch } from 'react-redux';
import helper from '../../../../../utils/helper';
import { useSelector } from "react-redux";

import notification from "../../../../../utils/notification";
import useSession from '../../../../../../modules/session/hooks/useSession';



const incorporation = `
    getMattersList (matterId: "{{matterId}}"){
      incorporation {
        id
        status
        applicationNumber
        agentManagerId
        documents {
          id
          hideStatus
          hideSignatures
          status
          name
          isReplaced
          createdAt
          updatedAt
          file{
            id
            name
            size
          }
          signatories{
            entity
            entityId
            label
            signed
          }
          assignedTo {
            _id
            fullName
            firstName
          }
        }
      }
    }
  `

const getAgentsQuery = ` 
    getAgents(matterId: "{{matterId}}", roles: {{roles}}){
    _id
     fullName
    email
        }
    `;

const getAgentRoles = (matterStatus) => {

    switch (matterStatus) {
        case 'inReview': {
            return `[SERVICE_AGENT, SENIOR_SERVICE_AGENT, SERVICE_AGENT_MANAGER]`;
            break;
        }
        case 'Draft': {
            return `[SENIOR_SERVICE_AGENT, SERVICE_AGENT_MANAGER]`;
            break;
        }
        default: {
            return `[SERVICE_AGENT, SENIOR_SERVICE_AGENT, SERVICE_AGENT_MANAGER]`
        }

    }
}

const IncorporationPortal = ({ data, ...props }) => {

    const [loading, setLoading] = useState(true);
    const [agents, setAgents] = useState([]);
    const [incorporationData, setIncorporationData] = useState({})
    const [showModalAssign, setShowModalAssign] = useState(false);
    const [dataModal, setDataModal] = useState({});
    const [showModalSIncorporationNumber, setShowModalSIncorporationNumber] = useState(false);
	const [showModalCancelEnvelope, setShowModalCancelEnvelope] = useState(false);
    const [showADGMModal, setShowADGMModal] = useState(false);
    const [showIncorporationPortal, setShowIncorporationPortal] = useState(false);
	const [showModalRegenerateADGMDocuments, setShowModalRegenerateADGMDocuments] = useState(false);
    const { user } = useSession();

    const dispatch = useDispatch();

    useEffect(() => {

        const getIncorporation = () => {

            const getIncorporation = Mustache.render(`{${incorporation}}`, { matterId: data.id })

            ServerConnect.graphQlQuery(getIncorporation)
                .then(({ getMattersList }) => {
                    const incorporation = _.get(getMattersList, "[0].incorporation", {});
                    setLoading(false);
                    setIncorporationData(incorporation);
                    if (!_.isEmpty(incorporation)) {
                        const getAgentsCompiledQuery = Mustache.render(`{${getAgentsQuery}}`, { matterId: data.id, roles: getAgentRoles(incorporation.status) })
                        ServerConnect.graphQlQuery(getAgentsCompiledQuery)
                            .then(({ getAgents }) => {
                                setAgents(getAgents)
                            })
                    }
                })

        };
        getIncorporation();
    }, []);

    const updateDataTable = () => {
        const updateData = Mustache.render(`{${incorporation}}`, { matterId: data.id })

        ServerConnect.graphQlQuery(updateData)
            .then(({ getMattersList, getManagerAgents }) => {
                setIncorporationData(_.get(getMattersList, "[0].incorporation"), {})
            })
    };

    const changedNumberOfIncorporation = () => {
        setDataModal({ ...incorporationData, matterId: data.id });
        setShowModalSIncorporationNumber(true);
    };

	const handleRegenerateADGMDocuments = () => {
		setDataModal({ ...incorporationData, matterId: data.id });
		setShowModalRegenerateADGMDocuments(true);
	};

	const handleCancelEnvelope = () => {
		setDataModal({ ...incorporationData, matterId: data.id });
		setShowModalCancelEnvelope(true);
	};

	const handleRequestClientReview = () => {
        dispatch({ type: 'GO_TO', page: Constants.PAGES.clientReview, params: { matterId: data.id } });
    };

    const handleRequestClientSignature = () => {
        dispatch({ type: 'GO_TO', page: Constants.PAGES.incorporationSignatories, params: { matterId: data.id } });
    };

    const handleADGMBundle = () => {
        setDataModal({ ...incorporationData, matterId: data.id });
        setShowADGMModal(true);
    };
    const handleIncorporationPortal = () => {
        setDataModal({ ...incorporationData, matterId: data.id });
        setShowIncorporationPortal(true);
    };

    const handleConfirmSubmission = () => {
        const confirmSubmission = `
        mutation confirmADGMSubmission($matterId: ID!){
            confirmADGMSubmission(matterId: $matterId){
              incorporation{
                status
              }
            }
          }
        `
        ServerConnect.graphQlMutation(confirmSubmission, { matterId: data.id })
            .then(res => {

                setIncorporationData(preIncorporationData => {
                    return { ...preIncorporationData, status: "Confirmed" }
                });
                notification.sendNotification("Send Email  ADGM Incorporation", "success", 4990);
            }).catch(err => {
                console.log(err);
                notification.sendNotification("ERROR", "error", 4990);
            });
    };

    const handleRetrySendEnvelope = () => {
		const retrySendEnvelope = `
        mutation retrySendRequestSignatures($matterId: ID!){
            retrySendRequestSignatures(matterId: $matterId){
              incorporation{
                status
                documents {
                	id
                	status
                }
              }
            }
          }
        `;

		notification.sendNotification("Resending Request Signatures", "success", 4990);
		setLoading(true);
		ServerConnect.graphQlMutation(retrySendEnvelope, { matterId: data.id })
			.then(res => {

				const { incorporation } = res.retrySendRequestSignatures;
				const documents = incorporationData.documents.map(doc => {
					return {
						...doc,
						status: incorporation.documents.find(d => d.id === doc.id).status
					}
				});

				setIncorporationData(preIncorporationData => {
					return {
						...preIncorporationData,
						status: incorporation.status,
						documents: documents
					}
				});
				setLoading(false);
				notification.sendNotification("Request Signatures was resend", "success", 4990);
			}).catch(err => {
			console.log(err);
			notification.sendNotification("ERROR", "error", 4990);
		});

	};

    const handleActiveConfirmSubmission = () => {
        const isActive = incorporationData && incorporationData.status === 'Signed' && _.every(incorporationData.documents, doc => doc.status === 'Signed');
        return isActive ? { action: handleConfirmSubmission, className: `${classes.ViewICon}` } : { action: () => { }, className: `${classes.ViewICon} ${classes.buttonDisabled}` }
    };

	const handleActiveRegenerateADGMDocuments = () => {
		const isActive = incorporationData && ['Draft', 'inReview', 'Approved', 'ReviewByClient', 'ClientApproved', 'ClientDeclined'].indexOf(incorporationData.status) >= 0;
		return isActive ? { action: handleRegenerateADGMDocuments, className: `${classes.ReplaceIcon}` } : { action: () => { }, className: `${classes.ReplaceIcon} ${classes.buttonDisabled}` }
	};

	const handleActiveRetrySendEnvelope = () => {
		const isActive = incorporationData && incorporationData.status === 'Failed';
		return isActive ? { action: handleRetrySendEnvelope, className: `${classes.ReplaceIcon}` } : { action: () => { }, className: `${classes.ReplaceIcon} ${classes.buttonDisabled}` }
	};

	const handleActiveCancelEnvelope = () => {
		const isActive = incorporationData && incorporationData.status === 'Pending';
		return isActive ? { action: handleCancelEnvelope, className: `${classes.ReplaceIcon}` } : { action: () => { }, className: `${classes.ReplaceIcon} ${classes.buttonDisabled}` }
	};

	const options = [];
    options.push({ label: "Change ADGM Number", action: changedNumberOfIncorporation, className: ` ${classes.ViewICon}` });
	options.push({ label: "Regenerate ADGM Documents", ...handleActiveRegenerateADGMDocuments() });
    options.push({ label: "Reset Agent Review", action: () => { }, className: `${classes.buttonDisabled} ${classes.ReplaceIcon}` });
    options.push({ label: "Reset Senior Agent Review", action: () => { }, className: `${classes.buttonDisabled} ${classes.ReplaceIcon}` });
    options.push({ label: "Reset Client Review", action: () => { }, className: ` ${classes.buttonDisabled} ${classes.ReplaceIcon}` });
	options.push({ label: "Retry Send Envelope", ...handleActiveRetrySendEnvelope() });
	options.push({ label: "Cancel Envelope", ...handleActiveCancelEnvelope()});
    options.push({ label: "Download ADGM Bundle", action: handleADGMBundle, className: `${classes.ReplaceIcon}` });
    options.push({ label: "Confirm ADGM Submission", ...handleActiveConfirmSubmission() });

    const selectAgent = (agent) => {
        setDataModal({ ...agent, matterId: data.id });
        setShowModalAssign(true);
    };


    const disabledAssignToAgent = (data) => {
        if (_.isEmpty(data)) {
            return true
        }
        const { documents } = data;
        return !_.every(documents, doc => doc.status === "Approved") && !_.some(documents, doc => doc.status === "Declined") || !_.some(documents, doc => doc.assignedTo._id === user._id)
            || !["ClientDeclined", "Declined", "inReview", 'Draft'].includes(data.status);
    };


    const disabledClientReview = (data) => {
        if (_.isEmpty(data)) {
            return true
        }
        return !(["ClientDeclined", "Approved"].includes(data.status) && _.every(data.documents, doc => doc.status === "Approved"));
    };

    const disabledClientSignature = (data) => {
        if (_.isEmpty(data)) {
            return true
        }
        return data.status !== "ClientApproved";
    };
    const disabledADGMBundle = (data) => {
        if (_.isEmpty(data)) {
            return true
        }
        return !_.every(data.documents, doc => doc.status === "Signed");
    };
    const disabledIncorporationPack = (data) => {
        if (_.isEmpty(data)) {
            return true
        }
        return (data.status !== "Confirmed");
    };

    const disabledSendWelcomePack = (data) => {
        if (_.isEmpty(data)) {
            return true
        }
		const { documents } = data;
        return !(data.status === "Generated" && _.every(_.filter(documents, doc => !doc.hideSignatures), doc => doc.status === "Approved"));
    };

    const handleSendWelcomePack = ()=>{
        dispatch({ type: 'GO_TO', page: Constants.PAGES.incorporationPack, params: { matterId: data.id } });
    };

    const nameMatter = `${data.clientFullName} - ${data.sequenceNumber}`;
    return (
        <Loading show={loading} showChildren={true} loadingStyle={{ position: "fixed", zIndex: 1000 }}>
            <div className={classes.ContainerView}>

                <React.Fragment> <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                    <label>Actions</label>
                    <hr />
                </div>
                    <div className={`${classes.ContainerRow}`}>
                        <div className={classes.botoneraActions}>
                            <PopoverOption   classicStyle={true}  renderElement={<MenuListAgent onClickAgent={selectAgent} list={agents} />} classPaper={classes.Popover}>
                                <button  className={classes.buttonModalOnActions} disabled={disabledAssignToAgent(incorporationData)}>Assign to Agent</button>
                            </PopoverOption>
                            <button disabled={disabledClientReview(incorporationData)} onClick={handleRequestClientReview}>Send to Client</button>
                            <button disabled={disabledClientSignature(incorporationData)} onClick={handleRequestClientSignature}>Get Signatures</button>
                            <button disabled={disabledADGMBundle(incorporationData)} onClick={handleADGMBundle}>Generate Bundle</button>
                            <button disabled={disabledIncorporationPack(incorporationData)} onClick={handleIncorporationPortal}>Build Welcome Pack</button>
                            <button disabled={disabledSendWelcomePack(incorporationData)} onClick={handleSendWelcomePack}>Send Welcome Pack</button>

                        </div>
                        <div className={classes.buttonModalOptions}>
                            <ButtonModalOptions
                                title={null}
                                options={options}
                                params={{}}
                                disabled={_.isEmpty(incorporationData)}
                            />
                        </div>
                    </div>
                </React.Fragment>
                <TableIncorporation
                    matterId={data.id}
                    data={_.get(incorporationData, "documents", [])}
                    status={_.get(incorporationData, "status")}
                    incorporationId={_.get(incorporationData, "id")}
                    setLoading={setLoading}
					setIncorporationData={setIncorporationData}
                    callbackUpdate={updateDataTable}
                />
                <ModalAssign
                    showModal={showModalAssign}
                    closeModal={() => setShowModalAssign(false)}
                    data={dataModal}
                    title={nameMatter}
                    callbackUpdate={updateDataTable}
                />
                <ModalChangedNumber
                    showModal={showModalSIncorporationNumber}
                    closeModal={() => setShowModalSIncorporationNumber(false)}
                    data={dataModal}
                    title={nameMatter}
                    setLoading={setLoading}
                    callbackUpdate={updateDataTable}
                />
				<ModalRegenerateADGMDocuments
					showModal={showModalRegenerateADGMDocuments}
					closeModal={() => setShowModalRegenerateADGMDocuments(false)}
					data={dataModal}
					title={nameMatter}
					setLoading={setLoading}
					goToTab={props.goToTab}
					callbackUpdate={updateDataTable}
				/>
				<ModalCancelEnvelope
					showModal={showModalCancelEnvelope}
					closeModal={() => setShowModalCancelEnvelope(false)}
					data={dataModal}
					title={nameMatter}
					setLoading={setLoading}
					callbackUpdate={updateDataTable}
				/>
                <ModalADGMBundle
                    showModal={showADGMModal}
                    closeModal={() => setShowADGMModal(false)}
                    data={dataModal}
                    title={nameMatter}
                    setLoading={setLoading}
                />
                <ModalIncorporationPack
                    showModal={showIncorporationPortal}
                    closeModal={() => setShowIncorporationPortal(false)}
                    data={dataModal}
                    title={helper.getTranslateTextByKey("INCORPORATION_PORTAL_MODAL_INCORPORATION_PACK_CREATION_TITLE")}
                    callbackUpdate={updateDataTable}
                    setLoading={setLoading}
                />
            </div >
        </Loading >
    )
}

export default IncorporationPortal;


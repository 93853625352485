import IP_AND_GENERATE from '../Ip&Generate/langs'
import CONVERTIBLE from '../Convertible/langs'
import EMPLOYEE from '../Employee/langs'
import EDIT_IDENTITY_DOCUMENT from '../ADGM/EditDetails/langs'
const CATEGORIES_TITLES= {
    CORPORATE_STRUCTURE: "Corporate Structure",
    corporateStructure: "Corporate Structure",
    constitutionalDocuments: "Constitutional Documents",
    MEMORANDUM_OF_ASSOCIATION: "Memorandum of Association",
    ARTICLES_OF_ASSOCIATION: "Articles of Association",
    CERTIFICATE_OF_INCORPORATION: "Certificate of Incorporation",
    TRADE_LICENSE: "Commercial/Trade License",
    REGISTER_OF_DIRECTORS: "Register of Directors",
    REGISTER_OF_SHARES: "Register of Shares",
    SHARE_CERTIFICATE: "Share Certificate",
    SHARE_RESOLUTION: "Shareholder Resolution",
    BOARD_RESOLUTION: "Board Resolution",
    OTHER_CONSTITUTIONAL_DOC: "Miscellaneous Constitutional Document",
    ownership: "Ownership",
    jurisdiction: "Jurisdiction",
    EQUITY: "Equity",
    equity: "Equity",
    "THIRDPARTY_ADDRESS_PASSPORT_DOCUMENT":"Third Party Passport",
    "PASSPORT_DOCUMENT":"Passport",
    "OLDPASSPORT_DOCUMENT":"Old Passport",
    "UAE_VISA_DOCUMENT":"UAE Visa",
    "UAE_VISA_LASTPAGE_DOCUMENT":"UAE Visa",
    "GCC_RESIDENT_ID_DOCUMENT":"GCC Residence ID",
    "NATIONAL_ID_DOCUMENT":"National ID",
    "UAE_RESIDENCE_VISA_DOCUMENT":"UAE Residence Visa",
    "UAE_ENTRY_STAMP_DOCUMENT":"UAE Entry Stamp",
    "UID_STAMP_DOCUMENT":"UID Entry Stamp",
    "EID_DOCUMENT":"Emirates ID",
    "EID_LASTPAGE_DOCUMENT":"Emirates ID",
    sharePurchaseAgreements: "Share Purchase Agreements",
    SHARE_PURCHASE_AGREEMENTS: "Share Purchase Agreement",
    OTHER_SHARE_PURCHASE_AGREEMENT: "Miscellaneous Share Purchase Document",
    subscriptionAgreements: "Subscription Agreements",
    SHARE_SUBSCRIPTION: "Share Subscription Agreement",
    OTHER_SUBSCRIPTION_AGREEMENT: "Miscellaneous Share Subscription Document",
    shareOptionsAgreements: "Share Option Agreements",
    GRANT_AGREEMENT: "Grant Agreement",
    EXERCISE_NOTICE: "Exercise Notice",
    SHARE_INCENTIVE_PLAN: "Share Incentive Plan",
    SHARE_INCENTIVE_PLAN_FAQ: "Share Incentive Plan (FAQ)",
    OTHER_SHARE_OPTION_AGREEMENT: "Miscellaneous Share Options Document",
    convertibleInstruments: "Convertible Instruments",
    CONVERTIBLE_NOTE: "Convertible Note",
    KISS: "Keep It Simple Security",
    SAFE: "Simple Agreement for Future Equity",
    WARRANT_AGREEMENT: "Warrant Agreement",
    OTHER_CONVERTIBLE_AGREEMENT: "Miscellaneous Convertible Instrument Document",
    GOVERNANCE: "Governance",
    governance: "Governance",
    shareHoldersAgreements: "Shareholders Agreements",
    FOUNDERS_TERM_SHEET: "Founders Term Sheet",
    FOUNDERS_AGREEMENT: "Founders Agreement",
    SHAREHOLDERS_AGREEMENT: "Shareholders Agreement",
    OTHER_SHAREHOLDER_AGREEMENT: "Miscellaneous Shareholders Document",
    intra_agreements: "Intra-group Agreements",
    INTRAGROUP_AGREEMENT: "Intra-Group Agreement",
    GROUP_AGREEMENT: "Intra-Group Agreements: Other",
    relatedParty: "Related Party Transactions",
    RELATED_PARTY_AGREEMENT: "Related Party Agreement",
    OTHER_RELATED_PARTY_AGREEMENT: "Miscellaneous Related Party Document",
    TEAM: "Team",
    team: "Team",
    foundersServicesAgreements: "Founder Services Agreements",
    founderServicesAgreements: "Founder Services Agreements",
    FOUNDER_SERVICES_AGREEMENT: "Founder Services Agreement",
    MANAGEMENT_AGREEMENT: "Management Agreement",
    OTHER_FOUNDER_SERVICE_AGREEMENT: "Miscellaneous Founder Services Document",
    employmentAgreements: "Employment Agreements",
    EMPLOYMENT_AGREEMENT: "Employment Agreement",
    EMPLOYMENT_AGREEMENT_ADGM: "Employment Agreement - ADGM",
    EMPLOYMENT_AGREEMENT_DIFC: "Employment Agreement - DIFC",
    EMPLOYMENT_AGREEMENT_UK: "Employment Agreement - UK",
    OTHER_EMPLOYMENT_AGREEMENT: "Miscellaneous Employment Document",
    CONSULTANT_AGREEMENT: "Consultancy Agreement",
    OTHER_CONSULTING_AGREEMENT: "Miscellaneous Consultancy Document",
    advisoryAgreements: "Advisory Agreements",
    ADVISOR_AGREEMENT: "Advisor Agreement",
    OTHER_ADVISORY_AGREEMENT: "Miscellaneous Advisor Document",
    consultancyAgreements: "Consultancy Agreements",
    INTELLECTUAL_PROPERTY: "Intellectual Property",
    intellectualProperty: "Intellectual Property",
    registeredIP: "Registered IP",
    IP_REGISTRATION_DOCUMENT: "IP Registration Document",
    unregisteredIP: "Unregistered IP",
    UNREGISTERED_IP_DOCUMENT: "Unregistered IP Document",
    IPInfringement: "IP Infringement",
    IP_INFRINGEMENT_DOCUMENT: "IP Infringement Document",
    IPAssignment: "IP Assignment",
    IP_ASSIGNMENT: "IP Assignment Agreement",
    OTHER_IP_ASSIGNMENT: "Miscellaneous IP Assignment Document",
    OTHER_INTRA_GROUP_AGREEMENT: "Miscellaneous Intra-Group Document",
    OTHER: "Miscellaneous",
    other: "Miscellaneous",
    litigation: "Litigation",
    LITIGATION_DOCUMENT: "Litigation Document",
    materialAgreements: "Material Agreements",
    equitySupportingDocument: "Equity Supporting Document",
    KEY_CUSTOMER_AGREEMENT: "Key Customer Agreement",
    KEY_SUPPLIER_AGREEMENT: "Key Supplier Agreement",
    RESTRICTIVE_COVENANT_AGREEMENT: "Restrictive Covenant Agreement",
    realEstate: "Real Estate",
    REAL_ESTATE_DOCUMENT: "Real Estate Document",
    financialDebt: "Finance",
    FINANCIAL_DEBT_DOCUMENT: "Financing/Debt Document",
    investorUpdates: "Investor Updates",
    INVESTOR_UPDATES: "Investor Update",
    miscellaneous: "Other - Miscellaneous",
    miscellaneousTeam: "Team - Miscellaneous",
    miscellaneousIntellectualProperty: "Intellectual Property - Miscellaneous",
    MISCELLANEOS_DOCUMENT: "Miscellaneous Document",
    NDA_MUTUAL: "Non-Disclosure Agreement (Mutual)",
    USA_NDA: "Non-Disclosure Agreement (One way)",
    WEBSITE_TERMS_OF_USE:'Website Terms Of Use',
    WEBSITE_PRIVACY_POLICY:"Website Privacy Policy",
    WEBSITE_COOKIE_NOTICE: "Website Cookie Notice",
    WEBSITE_ACCEPTABLE_USE_POLICY:"Website Acceptable Use Policy",
    //CP-5321
    USA_CAL_OFFER_LETTER_SALARY_EMPLOYEE:"Offer Letter for Exempt Salaried Employee (California)",
}
export default {
CATEGORIES_TITLES,
IP_AND_GENERATE:IP_AND_GENERATE.US,
CONVERTIBLE:CONVERTIBLE.US,
EMPLOYEE:EMPLOYEE.US,
EDIT_IDENTITY_DOCUMENT:EDIT_IDENTITY_DOCUMENT.US
}

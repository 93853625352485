import SHARE_OPTIONS from "../ShareOptions/langs";
import DELETE from "../Delete/langs";
import FORM from "../Forms/langs";
import PEP from "../PEP/langs";
import SOURCE_OF_FUNDS from "../ADGM/SourceOfFunds/langs";
import EDIT_SOURCE_OF_FUNDS from "../../equity/AddEquity/langs"
import PROFESSIONAL_HISTORY from "../ProfessionallHistory/langs";
import ADGM from "../ADGM/langs";
import EDIT_ROLE from "../Roles/langs";
import REMOVE_AS_FOUNDER from "../RemoveAsFounder/langs";
import PLACE_OF_BUSINESS from "src/modules/startup/Stakeholder/PlaceOfBusiness/langs"
import CORPORATE_POSITION from "src/modules/startup/Stakeholder/CorporatePosition/langs"
import ENTITY_STRUCTURES from "../EntityStructures/langs";

export default {
  SHARE_OPTIONS: SHARE_OPTIONS.US,
  DELETE: DELETE.US,
  FORM: FORM.US,
  PEP: PEP.US,
  SOURCE_OF_FUNDS: SOURCE_OF_FUNDS.US,
  EDIT_SOURCE_OF_FUNDS: EDIT_SOURCE_OF_FUNDS.US,
  PROFESSIONAL_HISTORY: PROFESSIONAL_HISTORY.US,
  ADGM: ADGM.US,
  EDIT_ROLE: EDIT_ROLE.US,
  REMOVE_AS_FOUNDER: REMOVE_AS_FOUNDER.US,
  PLACE_OF_BUSINESS: PLACE_OF_BUSINESS.US,
  CORPORATE_POSITION: CORPORATE_POSITION.US,
  ENTITY_STRUCTURES: ENTITY_STRUCTURES.US,
};

import React from 'react';
import mastercardIcon from "../../../components/inputs/card/images/mastercard.svg";
import visaIcon from "../../../components/inputs/card/images/visa.svg";
import amexIcon from "../../../components/inputs/card/images/american_express.svg";
import Text from "../../../components/text/Text/Text";
import { Constants } from "../../../v1/utils";
import _ from "lodash";
import classes from './Card.module.scss';

const Card = ({ card, ...props }) => {
  let showIconVisa = _.get(card, "bankCardType") === Constants.CARD_TYPES.VISA;
  let showIconMastercard = _.get(card, "bankCardType") === Constants.CARD_TYPES.MASTERCARD;
  let showIconAmex = _.get(card, "bankCardType") === Constants.CARD_TYPES.AMEX;

  return (
    <React.Fragment>
      {card && (
        <span className={classes.Card}>
          <span>{`XXXX XXXX XXXX ${card.last4}`}</span>
          {showIconVisa && <img className={classes.afterComponent} src={visaIcon} alt=""/>}
          {showIconMastercard && <img className={classes.afterComponent} src={mastercardIcon} alt=""/>}
          {showIconAmex && <img className={classes.afterComponent} src={amexIcon} alt=""/>}
          {card.inUse && (<p><Text uuid={"CARD_IN_USE"} /></p>)}
        </span>
      )}
    </React.Fragment>
  )
}

export default Card;
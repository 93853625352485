import React, { Component } from "react";
import { connect } from "react-redux";

import { goToPage, goToPrevPage } from '../../../../../store/actions/page'
import { Constants } from '../../../../utils/constants'
import classes from "../ViewForms.module.scss";
import helper from "../../../../utils/helper"
import { Field, Formik } from "formik";
import { upsertScoping } from '../../../../graphql/mutations/matters';
import ServerConnect from "../../../../utils/ServerConnect";
import TextInput from "../../../../containers/Forms/Inputs/TextInput";
import Checkbox from "../../../../containers/Forms/Inputs/Checkbox";
import TextAreaInput from "../../../../containers/Forms/Inputs/TextAreaInput";
import ClaraRadioWithButton from "../../../Form/ClaraRadioWithButton/ClaraRadioWithButton";
import ClaraSelect from "../../../Form/ClaraSelect/ClaraSelect";
import notification from "../../../../utils/notification";
import _ from "lodash";
import validationSchema from './validations';
import getInitialValues from './initialValues';
import variablesGeneratedByType from './values';


//validationSchema on validation.js    -   mutationVariables on values.js  -  initialValues on initialValues.js
//getFieldsByType = depends on companyType will render certain fields
//changeMode = Click handler of cancel button - set View Mode instead Edit
//reinitializeValueOnTypeChange = Set empty values to conditial fields on companyType change
//submitData = take form values as variables on the mutation and launches after click on submit button
//getInitialValues(EXPORTED FROM initialValues.js) = set the initial values depends on companyType
//variablesGeneratedByType(EXPORTED FROM values.js) = set the variables to send on mutation depending on companyType



class editScoping extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    changeMode = () => {
        this.props.goToPage(Constants.PAGES.viewMatter, { id: this.props.data.id, tab: 'scoping' });
    };

    options = [{ label: 'Revenue Generation', id: 'isRevenueGenerating' }]



    getFieldsByType = (values, options) => {

        let incorporatedOptions = [{ 'label': 'Company already Incorporated', 'value': 'companyAlreadyIncorporated' }, { 'label': 'Company to be Incorporated', 'value': 'companyToBeIncorporated' }];

        switch (values.companyType) {
            case Constants.COMPANY_TYPES.HOLDING_COMPANY:
                return (
                    <React.Fragment>
                        <hr className={classes.Separator} />
                        <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                            <label>{helper.getTranslateTextByKey("HOLDING_COMPANY")}</label>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <Field name={"alreadyIncorporatedText"}>
                                {({ field, form }) => (
                                    <TextAreaInput
                                        label="Shares in a company already Incorporated: "
                                        field={field}
                                        form={form}
                                        placeholder={""} />
                                )}
                            </Field>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <Field name={"toBeIncorporatedText"}>
                                {({ field, form }) => (
                                    <TextAreaInput
                                        label="Shares in a company to be Incorporated: "
                                        field={field}
                                        form={form}
                                        placeholder={""} />
                                )}
                            </Field>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <Field name={"intellectualPropertyText"}>
                                {({ field, form }) => (
                                    <TextAreaInput
                                        label="Intellectual Property: "
                                        field={field}
                                        form={form}
                                        placeholder={""} />
                                )}
                            </Field>
                        </div>
                        <div className={`${classes.ContainerRow} ${classes.checkboxContainer}`}>
                            <Field name={"isRevenueGenerating"}>
                                {({ field, form }) => (
                                    <Checkbox
                                        label="Revenue Generation"
                                        field={field}
                                        form={form}
                                        placeholder={""} />
                                )}
                            </Field>
                        </div>
                        <div className={`${classes.ContainerRow} ${classes.checkboxContainer}`}>
                            <Field name={"isProtection"}>
                                {({ field, form }) => (
                                    <Checkbox
                                        label="Protection"
                                        field={field}
                                        form={form}
                                        placeholder={""} />
                                )}
                            </Field>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <Field name={"otherAssetText"}>
                                {({ field, form }) => (
                                    <TextAreaInput
                                        label="Other: "
                                        field={field}
                                        form={form}
                                        placeholder={""} />
                                )}
                            </Field>
                        </div>
                        {/* DE ACA PA ARRIBA UPDATE */}
                        <div className={`${classes.ContainerRow}`}>
                            <Field name={"onlyRevenueDividendsOrCapital"}>
                                {({ field, form }) => (
                                    <ClaraRadioWithButton
                                        label="Will this holding company only receive revenue from dividends or through capital gains:"
                                        field={field}
                                        changeParams={field.name}
                                        form={form}
                                        options={options}
                                        placeholder={""} />
                                )}
                            </Field>
                        </div>
                        {values.onlyRevenueDividendsOrCapital == false ?
                            <div className={`${classes.ContainerRow}`}>
                                <Field name={"anotherIncomeForm"}>
                                    {({ field, form }) => (
                                        <TextAreaInput
                                            label="What other form of income will this holding company receive?: "
                                            field={field}
                                            form={form}
                                            placeholder={""} />
                                    )}
                                </Field>
                            </div> : null}
                    </React.Fragment>
                );
            case Constants.COMPANY_TYPES.FOUNDATION:
                return (
                    <React.Fragment>
                        <hr className={classes.Separator} />
                        <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                            <label>{helper.getTranslateTextByKey("FOUNDATION")}</label>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <Field name={"foundationReason"}>
                                {({ field, form }) => (
                                    <TextAreaInput
                                        label="Reasons set up an ADGM Foundation: "
                                        field={field}
                                        form={form}
                                        placeholder={""} />
                                )}
                            </Field>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <Field name={"foundationAssetsAndWhereAreLocated"}>
                                {({ field, form }) => (
                                    <TextAreaInput
                                        label="Assets the foundation holds and locations: "
                                        field={field}
                                        form={form}
                                        placeholder={""} />
                                )}
                            </Field>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <Field name={"whereFoundersAreLiving"}>
                                {({ field, form }) => (
                                    <TextAreaInput
                                        label="Where are the Founders, Beneficiaries and Councillors living: "
                                        field={field}
                                        form={form}
                                        placeholder={""} />
                                )}
                            </Field>
                        </div>
                    </React.Fragment>
                );
            case Constants.COMPANY_TYPES.TECH_STARTUP:
                return (
                    <React.Fragment>
                        <hr className={classes.Separator} />
                        <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                            <label>{helper.getTranslateTextByKey("TECH_STARTUP")}</label>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <Field name={"relevantTechnologyDescription"}>
                                {({ field, form }) => (
                                    <TextInput
                                        label="Preliminary description of the relevant technology: "
                                        field={field}
                                        form={form}
                                        placeholder={""} />
                                )}
                            </Field>
                        </div>
                    </React.Fragment>
                );
            case Constants.COMPANY_TYPES.OPERATING_COMPANY:
                return (
                    <React.Fragment>
                        <hr className={classes.Separator} />
                        <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                            <label>{helper.getTranslateTextByKey("OPERATING_COMPANY")}</label>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <Field name={"companyActivitiesDescription"}>
                                {({ field, form }) => (
                                    <TextInput
                                        label="Description of the activities of the company: "
                                        field={field}
                                        form={form}
                                        placeholder={""} />
                                )}
                            </Field>
                        </div>
                    </React.Fragment>
                );
            case Constants.COMPANY_TYPES.OTHER:
                return (
                    <React.Fragment>
                        <hr className={classes.Separator} />
                        <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                            <label>{helper.getTranslateTextByKey("OTHER")}</label>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <Field name={"detailsOtherIncorporation"}>
                                {({ field, form }) => (
                                    <TextInput
                                        label="Type of entity and what activities: "
                                        field={field}
                                        form={form}
                                        placeholder={""} />
                                )}
                            </Field>
                        </div>
                    </React.Fragment>
                );
            default:
                return null
        }
    }


    reinitializeValueOnTypeChange = (value, form) => {
        form.setFieldValue('detailsOtherIncorporation', '')
        form.setFieldValue('foundationReason', '')
        form.setFieldValue('foundationAssetsAndWhereAreLocated', '')
        form.setFieldValue('whereFoundersAreLiving', '')
        form.setFieldValue('alreadyIncorporatedText', '')
        form.setFieldValue('toBeIncorporatedText', '')
        form.setFieldValue('anotherIncomeForm', '')
        form.setFieldValue('intellectualPropertyText', '')
        form.setFieldValue('otherAssetText', '')
        form.setFieldValue('companyActivitiesDescription', '')
        form.setFieldValue('relevantTechnologyDescription', '')
        form.setFieldValue('onlyRevenueDividendsOrCapital', null)

    }

    submitData = (values) => {

        const variables = {
            matterId: this.props.data.id,
            scopingData: variablesGeneratedByType(values)
        }

        ServerConnect.graphQlMutation(upsertScoping, variables)
            .then(result => {
                notification.sendNotification("Scoping form has been regenerated successfully", "success", 4990);
                this.props.goToPage(Constants.PAGES.viewMatter, { id: this.props.data.id, tab: 'scoping' });
                this.props.updateData()
            }).catch(err => {
                console.log(err);
            });
    }


    render() {
        let { data } = this.props;
        const options = [{ 'label': 'Yes', 'value': true }, { 'label': 'No', 'value': false }];
        const shareholderOptions = [{ 'label': 'Agreement not needed', 'value': 'inPlace' }, { 'label': 'Agreement needed', 'value': 'beingPrepared' }];
        const engagementOptions = [{ 'label': 'Individual(s)', 'value': 'individual' }, { 'label': 'Corporate Entity', 'value': 'corporateEntity' }];
        const typeList = [{ 'id': Constants.COMPANY_TYPES.HOLDING_COMPANY, 'label': helper.getTranslateTextByKey("HOLDING_COMPANY") }, { 'id': Constants.COMPANY_TYPES.TECH_STARTUP, 'label': helper.getTranslateTextByKey("TECH_STARTUP") }, { 'id': Constants.COMPANY_TYPES.FOUNDATION, 'label': helper.getTranslateTextByKey("FOUNDATION") }, { 'id': Constants.COMPANY_TYPES.OPERATING_COMPANY, 'label': helper.getTranslateTextByKey("OPERATING_COMPANY") }, { 'id': Constants.COMPANY_TYPES.OTHER, 'label': helper.getTranslateTextByKey("OTHER") }]
        const jurisdictionList = [{ 'id': Constants.INCORPORATIONS.ABU_DHABI_MARKET, 'label': helper.getTranslateTextByKey("ABU_DHABI_MARKET") }];

        let initialValues = getInitialValues(_.get(data.scoping, 'companyType', ''), data);


        if (!data) {
            return null;
        }
        return (
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    console.log(JSON.stringify(values, 13));
                    this.submitData(values)
                    return data
                }}
                render={({
                    values,
                    errors,
                    dirty,
                    touched,
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    handleReset,
                }) => {
                    return (
                        <div className={classes.ContainerView}>
                            <form onSubmit={handleSubmit}>
                                <div className={classes.ContainerData}>
                                    <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                                        <label>Company Details</label>
                                    </div>
                                    <div className={`${classes.ContainerRow} ${classes.selectContainer}`}>
                                        <Field name={"incorporationJurisdiction"}>
                                            {({ field, form }) => (
                                                <ClaraSelect
                                                    field={field}
                                                    require={false}
                                                    label={'Incorporation jurisdiction '}
                                                    form={form}
                                                    mode={'classic'}
                                                    placeholder={''}
                                                    lists={jurisdictionList}
                                                    className={classes.FilterWidth}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    <div className={`${classes.ContainerRow} ${classes.selectContainer}`}>
                                        <Field name={"companyType"}>
                                            {({ field, form }) => (
                                                <ClaraSelect
                                                    field={field}
                                                    mode={'classic'}
                                                    require={false}
                                                    onChange={(a, b) => {
                                                        field.onChange(a, b); this.reinitializeValueOnTypeChange(field.value, form);
                                                    }}
                                                    label={'Type of company '}
                                                    form={form}
                                                    placeholder={''}
                                                    lists={typeList}
                                                    className={classes.FilterWidth}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    {this.getFieldsByType(values, options)}
                                    <hr className={classes.Separator} />
                                    <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                                        <label>Shareholders</label>
                                    </div>
                                    <div className={`${classes.ContainerRow}`}>
                                        <Field name={"shareNumbers"}>
                                            {({ field, form }) => (
                                                <TextInput
                                                    label="Number of shareholders "
                                                    field={field}
                                                    form={form}
                                                    placeholder={""} />
                                            )}
                                        </Field>
                                    </div>
                                    <div className={`${classes.ContainerRow}`}>
                                        <Field name={"boardMembersMajorityResident"}>
                                            {({ field, form }) => (
                                                <ClaraRadioWithButton
                                                    label="Majority of firectors are UAE residents "
                                                    field={field}
                                                    changeParams={field.name}
                                                    form={form}
                                                    options={options}
                                                    placeholder={""} />
                                            )}
                                        </Field>
                                    </div>
                                    {values.boardMembersMajorityResident == false ? <div className={`${classes.ContainerRow}`}>
                                        <Field name={"whereTheMajorityBoardMembersReside"}>
                                            {({ field, form }) => (
                                                <TextAreaInput
                                                    label={"Where will the majority of the Directors reside: "}
                                                    field={field}
                                                    form={form}
                                                    placeholder={""} />
                                            )}
                                        </Field>
                                    </div> : null}
                                    <hr className={classes.Separator} />
                                    <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                                        <label>Banking Services</label>
                                    </div>
                                    <div className={`${classes.ContainerRow}`}>
                                        <Field name={"bankAccountRequire"}>
                                            {({ field, form }) => (
                                                <ClaraRadioWithButton
                                                    label="Require a Bank Account: "
                                                    field={field}
                                                    changeParams={field.name}
                                                    form={form}
                                                    options={options}
                                                    placeholder={""} />
                                            )}
                                        </Field>
                                    </div>
                                    <hr className={classes.Separator} />
                                    <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                                        <span>Engagement</span>
                                    </div>
                                    <div className={`${classes.ContainerRow}`}>
                                        <Field name={"engagementType"}>
                                            {({ field, form }) => (
                                                <ClaraRadioWithButton
                                                    label="Engagement Type: "
                                                    field={field}
                                                    changeParams={field.name}
                                                    options={engagementOptions}
                                                    form={form}
                                                    placeholder={""} />
                                            )}
                                        </Field>
                                    </div>

                                </div>
                                {/*<pre>{JSON.stringify(errors)}</pre>*/}
                                <div className={classes.Botonera}>
                                    <button type="submit" data-cy="submit" disabled={isSubmitting || !_.isEmpty(errors)}>
                                        Save Changes
                                    </button>
                                    <button type="button" onClick={() => { this.changeMode() }}>
                                        Cancel
                                    </button>
                                </div>
                            </form >
                        </div >
                    );
                }} />
        )
    }
}

const mapStateToProps = state => ({
    session: state.session,

});
const mapDispatchToProps = (dispatch) => ({
    goToPrevPage: () => {
        goToPrevPage(dispatch)
    },
    goToPage: (page, params) => {
        goToPage(dispatch, page, params)
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(editScoping)

import _ from "lodash";
import { useSelector } from "react-redux";
import { Constants } from "../../../v1/utils";
import GroupCompany from "../../../components/modules/clara/startups/entities/classes/GroupCompany";

export interface UseBusinessRulesReturn {
  hasSelectedStartup: () => boolean;
  isFormation: () => boolean;
  isNotFormation: () => boolean;
  hasMatters: () => boolean;
  hasStartups: () => boolean;
  hasEquity: () => boolean;
  noHasStartups: () => boolean;
  isIntroduction: () => boolean;
  isNotIntroduction: () => boolean;
  isFounderAgreement: () => boolean;
  isNotFounderAgreement: () => boolean;
  isReseller: () => boolean;
  isAdmin: () => boolean;
  isNotAdmin: () => boolean;
  isNotSuperAdmin: () => boolean;
  isSuperAdmin: () => boolean;
  isNotSeniorServiceAgent: () => boolean;
  isSeniorServiceAgent: () => boolean;
  isNotServiceAgentManager: () => boolean;
  isServiceAgentManager: () => boolean;
  isNotServiceAgent: () => boolean;
  isServiceAgent: () => boolean;
  isActivePlan: () => boolean;
  isInactivePlan: () => boolean;
  isNotStartSubscription: () => boolean;
  isStartSubscription: () => boolean;
  isScaleSubscription: () => boolean;
  isPageLoaded: (params: any) => boolean;
  isViewer: () => boolean;
  isNotViewer: () => boolean;
  isOwner: () => boolean;
  isNotOwner: () => boolean;
  isManager: () => boolean;
  isNotManager: () => boolean;
  isEditor: () => boolean;
  isNotEditor: () => boolean;
  hasSubscriptionAccessHealthCheck: (params: any) => boolean;
  hasSubscriptionAccessShareProfile: () => boolean;
  hasSubscriptionAccessGenerateDocuments: () => boolean;
  hasSubscriptionAccessDocuments: (params: any) => boolean;
  canUseClaraReport: (params: any) => boolean;
  isDownloadDocumentToEdit: () => boolean;
  isUploadDocumentNewVersion: () => boolean;
  isGroupCompanyInFormation: (any) => boolean;
  isGroupCompanyInIncorporation: (any) => boolean;
  isFormationGoal: () => boolean;
  isAcceleratorGoal: () => boolean;
  isAcceleratorDataSubmited: () => boolean;
  isAnualPlan: () => boolean;
  isMonthlyPlan: () => boolean;
  hasPendingSubscription: (startup: {}) => boolean;
  isFirstFormationGoal: () => boolean;
  isFirstAcceleratorGoal: () => boolean;
  isFirstGetReadyGoal: () => boolean;
  isDowngraded: () => boolean;
  canShareStartup: () => boolean;
  canUpgradeStartup: () => boolean;
  isAllGroupCompaniesInFormation: () => boolean;
  isGroupCompanyInFormationBySession: (id: string) => boolean;
  isGroupCompanyIncorporatedBySession: (id: string) => boolean;
}

function useBusinessRules(): UseBusinessRulesReturn {
  const session = useSelector((state) => _.get(state, "session"));

  const hasSelectedStartup = () => {
    return !_.isEmpty(_.get(session, "startup", 0));
  };
  const isFormation = () => {
    return hasMatters();
  };
  const isFormationGoal = () => {
    return !!_.find(_.get(session, "startup.goals"), (goal) =>
      Object.values(Constants.MODULES.STARTUP.COMPANY_FORMATION_GOALS).includes(
        goal.code
      )
    );
  };
  const isAcceleratorGoal = () => {
    return (
      _.get(session, "startup.acceleratorData.isSubmitted") !== true &&
      !!_.find(_.get(session, "startup.goals"), (goal) =>
        Object.values(
          Constants.MODULES.STARTUP.COMPANY_ACCELERATORS_GOALS
        ).includes(goal.code)
      )
    );
  };
  const isFirstFormationGoal = () => {
    return Object.values(
      Constants.MODULES.STARTUP.COMPANY_FORMATION_GOALS
    ).includes(_.get(session, "startup.goals.0.code"));
  };
  const isFirstAcceleratorGoal = () => {
    return Object.values(
      Constants.MODULES.STARTUP.COMPANY_ACCELERATORS_GOALS
    ).includes(_.get(session, "startup.goals.0.code"));
  };
  const isFirstGetReadyGoal = () => {
    return Object.values(
      Constants.MODULES.STARTUP.GOAL_GET_READY_FOR_INVESTMENT
    ).includes(_.get(session, "startup.goals.0.code"));
  };
  const isAcceleratorDataSubmited = () => {
    let result = false;
    if (_.get(session, "startup.acceleratorData.isSubmitted") === true) {
      result = true;
    }
    return result;
  };
  const isNotFormation = () => {
    return !isFormation();
  };
  const hasMatters = () => {
    return _.get(session, "user.hasMatters");
  };
  const hasStartups = () => {
    return _.get(session, "user.hasStartup");
  };
  const hasEquity = () => {
    return _.get(session, "user.hasEquities", false);
  };
  const noHasStartups = () => {
    return !hasStartups();
  };
  const isIntroduction = () => {
    const pending = _.get(session, "user.pendingActions", []);
    return pending.indexOf("INTRODUCTION") >= 0;
  };
  const isNotIntroduction = () => {
    return !isIntroduction();
  };
  const isFounderAgreement = () => {
    const pending = _.get(session, "user.pendingActions", []);
    return pending.indexOf("FOUNDER_AGREEMENT") >= 0;
  };
  const isNotFounderAgreement = () => {
    return !isFounderAgreement();
  };
  const isNotServiceAgent = () => {
    return !isServiceAgent();
  };
  const isServiceAgentManager = () => {
    return (
      _.get(session, "user.userRole") ===
      Constants.PROFILE_ROLES.SERVICE_AGENT_MANAGER
    );
  };
  const isNotServiceAgentManager = () => {
    return !isServiceAgentManager();
  };
  const isSeniorServiceAgent = () => {
    return (
      _.get(session, "user.userRole") ===
      Constants.PROFILE_ROLES.SENIOR_SERVICE_AGENT
    );
  };
  const isNotSeniorServiceAgent = () => {
    return !isSeniorServiceAgent();
  };
  const isSuperAdmin = () => {
    return (
      _.get(session, "user.userRole") === Constants.PROFILE_ROLES.SUPERADMIN
    );
  };
  const isNotSuperAdmin = () => {
    return !isSuperAdmin();
  };
  const isAdmin = () => {
    return _.get(session, "user.userRole") === Constants.PROFILE_ROLES.ADMIN;
  };
  const isReseller = () => {
    return _.get(session, "user.userRole") === Constants.PROFILE_ROLES.RESELLER;
  };
  const isNotAdmin = () => {
    return !isAdmin();
  };
  const isServiceAgent = () => {
    return (
      _.get(session, "user.userRole") === Constants.PROFILE_ROLES.SERVICE_AGENT
    );
  };
  const isStartSubscription = () => {
    const plan = _.get(session, `startup.activeSubscriptionPlan.code`, "");
    const isStartSubscriptionResult =
      plan === Constants.PRODUCTS.START_SUBSCRIPTION ||
      plan === Constants.PRODUCTS.START_EXTENDED_SUBSCRIPTION ||
      plan === Constants.PRODUCTS.SCALE_ANNUAL_SUBSCRIPTION_MONTHLY;
    return isStartSubscriptionResult;
  };
  const isScaleSubscription = () => {
    return !isStartSubscription();
  };

  const isNotStartSubscription = () => {
    return !isStartSubscription();
  };
  const isActivePlan = () => {
    let result;
    if (session.startup !== null) {
      const subscriptions = session.startup.subscriptions ?? [];
      result = subscriptions.some(
        (subscription) => subscription.status === Constants.STATUS_PLANS.ACTIVE
      );
    } else {
      result = false;
    }
    return result;
  };
  const isAnualPlan = () => {
    let result = isActivePlan();
    if (result) {
      const subscriptions = session.startup.subscriptions ?? [];
      if (subscriptions.length > 0) {
        const activeSubscription = _.filter(
          subscriptions,
          (subscription) =>
            subscription.status === Constants.STATUS_PLANS.ACTIVE
        );
        if (
          activeSubscription[0].productCode ===
          Constants.SUBSCRIPTIONS_TYPES.ANNUAL
        ) {
          result = true;
        } else {
          result = false;
        }
      }
    }
    return result;
  };
  const isMonthlyPlan = () => {
    let result = isActivePlan();
    if (result) {
      const subscriptions = session.startup.subscriptions ?? [];
      if (subscriptions.length > 0) {
        const activeSubscription = _.filter(
          subscriptions,
          (subscription) =>
            subscription.status === Constants.STATUS_PLANS.ACTIVE
        );
        if (
          activeSubscription[0].productCode ===
          Constants.SUBSCRIPTIONS_TYPES.MONTHLY
        ) {
          result = true;
        } else {
          result = false;
        }
      }
    }
    return result;
  };
  const isInactivePlan = () => {
    const subscriptions = session.startup.subscriptions ?? [];
    const result = !subscriptions.some(
      (subscription) => subscription.status !== Constants.STATUS_PLANS.INACTIVE
    );
    return result;
  };
  const isPageLoaded = (params) => {
    return _.get(session, `pagesLoaded.${_.get(params, "page")}`, false);
  };
  const isOwner = () => {
    return (
      _.get(session, "startup.userRol") === Constants.USER_ROLES.USER_OWNER
    );
  };
  const isNotOwner = () => {
    return !isOwner();
  };
  const isManager = () => {
    return (
      _.get(session, "startup.userRol") === Constants.USER_ROLES.USER_MANAGER
    );
  };
  const isNotManager = () => {
    return !isManager();
  };
  const isEditor = () => {
    return (
      _.get(session, "startup.userRol") === Constants.USER_ROLES.USER_EDITOR
    );
  };
  const isNotEditor = () => {
    return !isEditor();
  };
  const isViewer = () => {
    return (
      _.get(session, "startup.userRol") === Constants.USER_ROLES.USER_VIEWER
    );
  };
  const isNotViewer = () => {
    return !isViewer();
  };
  /* Subscription */
  const isDowngraded = () => {
    return _.get(
      session,
      "startup.activeSubscriptionPlan.activeSubscriptionData.isDowngraded",
      false
    );
  };
  const hasSubscriptionAccessHealthCheck = () => {
    const features = _.get(
      session,
      `startup.activeSubscriptionPlan.features`,
      []
    );
    const feature = _.find(
      features,
      (feature) => feature.code === Constants.PRODUCTS.HEALTH_CHECK
    );
    if (!feature) return false;
    const limit = _.get(feature, "limit.amount");
    const usages = _.get(session, "startup.subscriptionFeaturesUsage");
    const usage = _.find(
      usages,
      (feature) => feature.code === Constants.PRODUCTS.HEALTH_CHECK
    );
    const actual = usage ? usage.amount : 0;
    return !limit || limit > actual;
  };
  const hasSubscriptionAccessShareProfile = () => {
    const features = _.get(
      session,
      `startup.activeSubscriptionPlan.features`,
      []
    );
    const feature = _.find(
      features,
      (feature) => feature.code === Constants.PRODUCTS.SHARE_PROFILE
    );
    if (!feature) return false;
    const limit = _.get(feature, "limit.amount");
    const usages = _.get(session, "startup.subscriptionFeaturesUsage");
    const usage = _.find(
      usages,
      (feature) => feature.code === Constants.PRODUCTS.SHARE_PROFILE
    );
    const actual = usage ? usage.amount : 0;
    return !limit || limit > actual;
  };
  const hasSubscriptionAccessGenerateDocuments = () => {
    const features = _.get(
      session,
      `startup.activeSubscriptionPlan.features`,
      []
    );
    const feature = _.find(
      features,
      (feature) => feature.code === Constants.PRODUCTS.GENERATE_DOCUMENTS
    );
    if (!feature) return false;
    const limit = _.get(feature, "limit.amount");
    const usages = _.get(session, "startup.subscriptionFeaturesUsage");
    const usage = _.find(
      usages,
      (feature) => feature.code === Constants.PRODUCTS.GENERATE_DOCUMENTS
    );
    const actual = usage ? usage.amount : 0;
    return !limit || limit > actual;
  };
  const hasSubscriptionAccessDocuments = (params) => {
    const features = _.get(
      session,
      `startup.activeSubscriptionPlan.features`,
      []
    );
    const feature = _.find(
      features,
      (feature) => feature.code === Constants.PRODUCTS.DOCUMENTS
    );
    if (!feature) return false;
    const limit = _.get(feature, "limit.amount");
    const usages = _.get(session, "startup.subscriptionFeaturesUsage");
    const usage = _.find(
      usages,
      (feature) => feature.code === Constants.PRODUCTS.DOCUMENTS
    );
    const actual = usage ? usage.amount : 0;
    return !limit || limit > actual;
  };
  const canUseClaraReport = (params) => {
    const features = _.get(
      session,
      `startup.activeSubscriptionPlan.features`,
      []
    );
    const feature = _.find(
      features,
      (feature) => feature.code === Constants.PRODUCTS.CLARA_REPORTS
    );
    if (!feature) return false;
    return _.get(feature, "limit.allowed", false);
  };

  const isDownloadDocumentToEdit = () => {
    if (isAdmin() || isSuperAdmin()) {
      return true;
    }
    return (isOwner() || isManager() || isEditor()) && !isStartSubscription();
  };
  const isUploadDocumentNewVersion = () => {
    if (isAdmin() || isSuperAdmin()) {
      return true;
    }
    return (isOwner() || isManager() || isEditor()) && !isStartSubscription();
  };
  const isAllGroupCompaniesInFormation = () => {
    return _.get(session?.startup, "groupCompanies", []).every(
      isGroupCompanyInFormation
    );
  };
  const isGroupCompanyInFormation = (groupCompany) => {
    return (
      _.get(groupCompany, "kind") ===
      Constants.MODULES.STARTUP.GROUP_COMPANY.KIND.FORMATION
    );
  };
  const isGroupCompanyInIncorporation = (groupCompany) => {
    return (
      _.get(groupCompany, "kind") ===
      Constants.MODULES.STARTUP.GROUP_COMPANY.KIND.INCORPORATED
    );
  };

  const isGroupCompanyInFormationBySession = (id) => {
    const groupCompany = _.get(session?.startup, "groupCompanies", []).find(
      (gc) => gc.id === id
    );
    const groupCompanyClass = new GroupCompany(groupCompany);
    return groupCompanyClass.isFormation();
  };
  const isGroupCompanyIncorporatedBySession = (id) => {
    const groupCompany = _.get(session?.startup, "groupCompanies", []).find(
      (gc) => gc.id === id
    );
    const groupCompanyClass = new GroupCompany(groupCompany);
    return groupCompanyClass.isIncorporated();
  };

  const hasPendingSubscription = (startup) => {
    return !!_.get(
      startup,
      `subscriptions[${_.findIndex(startup.subscriptions, (subscription) => {
        return _.get(subscription, "status") === Constants.STATUS_PLANS.PENDING;
      })}]`
    );
  };

  const canShareStartup = () => {
    return isAdmin() || isSuperAdmin() || isOwner() || isManager();
  };
  const canUpgradeStartup = () => {
    return (isAdmin() || isSuperAdmin() || isOwner()) && !isActivePlan();
  };

  return {
    hasSelectedStartup,
    isFormation,
    isNotFormation,
    hasMatters,
    hasStartups,
    hasEquity,
    noHasStartups,
    isIntroduction,
    isNotIntroduction,
    isFounderAgreement,
    isNotFounderAgreement,
    isAdmin,
    isReseller,
    isNotAdmin,
    isNotSuperAdmin,
    isSuperAdmin,
    isNotSeniorServiceAgent,
    isSeniorServiceAgent,
    isNotServiceAgentManager,
    isServiceAgentManager,
    isNotServiceAgent,
    isServiceAgent,
    isActivePlan,
    isInactivePlan,
    isStartSubscription,
    isScaleSubscription,
    isNotStartSubscription,
    isViewer,
    isNotViewer,
    isOwner,
    isNotOwner,
    isManager,
    isNotManager,
    isEditor,
    isNotEditor,
    hasSubscriptionAccessHealthCheck,
    hasSubscriptionAccessShareProfile,
    hasSubscriptionAccessGenerateDocuments,
    hasSubscriptionAccessDocuments,
    canUseClaraReport,
    isDownloadDocumentToEdit,
    isUploadDocumentNewVersion,
    isPageLoaded,
    isGroupCompanyInFormation,
    isGroupCompanyInIncorporation,
    isFormationGoal,
    isAcceleratorGoal,
    isFirstFormationGoal,
    isFirstAcceleratorGoal,
    isFirstGetReadyGoal,
    isAcceleratorDataSubmited,
    isAnualPlan,
    isMonthlyPlan,
    hasPendingSubscription,
    isDowngraded,
    canShareStartup,
    canUpgradeStartup,
    isAllGroupCompaniesInFormation,
    isGroupCompanyInFormationBySession,
    isGroupCompanyIncorporatedBySession,
  };
}

export default useBusinessRules;

import { createMachine } from 'xstate';

const machine =
  createMachine({
    id: 'StartRenewal',
    initial: 'initialData',
    states: {
      initialData: {
        invoke: {
          src: 'initialData',
          id: 'initialData',
          onDone: [
            {
              target: 'getProduct',
              actions: ['setContextAfterInvoke']
            }
          ],
        }
      },
      getProduct: {
        invoke: {
          src: 'getProduct',
          id: 'getProduct',
          onDone: [
            {
              target: 'pincasData',
              actions: ['setContextAfterInvoke']
            }
          ],
        }
      },
      pincasData: {
        invoke: {
          src: 'pincasData',
          id: 'pincasData',
          onDone: [
            {
              target: 'WhatHappensStepForm',
              actions: ['setContextAfterInvoke']
            }
          ],
        }
      },
      WhatHappensStepForm : {
        on: {
          NEXT: {
            target: 'SaveStep',
          },
          CANCEL: {
            target: 'cancel'
          },
          GO_CLOSE_COMPANY: {
            target: 'CloseDownCompanyStepForm',
          }
        }
      },
      CloseDownCompanyStepForm : {
        on: {
          PREV: {
            target: 'WhatHappensStepForm',
          },
          NEXT: {
            target: 'StrikeOff',
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      SaveStep: {
        invoke: {
          src: 'SaveStep',
          id: 'SaveStep',
          onDone: [
            {
              target: 'finish',
            }
          ],
          onError: [
            {
              target: 'SaveStep'
            }
          ]
        }
      },
      StrikeOff: {
        invoke: {
          src: 'StrikeOff',
          id: 'StrikeOff',
          onDone: [
            {
              target: 'finish',
            }
          ],
          onError: [
            {
              target: 'StrikeOff'
            }
          ]
        }
      },
      cancel: {
        entry: 'cancelMachine',
        type: 'final'
      },
      finish: {
        entry: 'stopMachine',
        type: 'final'
      }
    }
  });

export default machine;

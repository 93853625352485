const templates = {
    
    MESSAGE_HEADER_SHARE_CLASS_CREATED:
        "{{userName}} {{entityAction}} a  {{entityName}} for {{companyName}}.",
    MESSAGE_SUB_HEADER_SHARE_CLASS_CREATED:
        "{{userName}} {{entityAction}} a  {{entityName}} for {{companyName}}.",
    MESSAGE_TEMPLATE_SHARE_CLASS_CREATED: `
    <label> {{userName}} {{entityAction}} a  {{entityName}} for {{companyName}}</label> 
 <ul>
    <li>{{entityName}} has been {{entityAction}} for {{companyName}} by {{userName}} at {{activityTime}} on {{activityDate}}.</li>
  </ul> `,

    MESSAGE_HEADER_SHARE_CLASS_EDITED:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_SUB_HEADER_SHARE_CLASS_EDITED:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_TEMPLATE_SHARE_CLASS_EDITED: `
    <label> {{userName}} {{entityAction}} the details of {{equityContainerName}} share class in {{companyName}}</label> 
 <ul>
    <li>The details of {{equityContainerName}} in {{companyName}} has been {{entityAction}} by {{userName}} at {{activityTime}} on {{activityDate}}.</li>
  </ul> `,


    MESSAGE_HEADER_SHARE_CLASS_DELETED:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_SUB_HEADER_SHARE_CLASS_DELETED:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_TEMPLATE_SHARE_CLASS_DELETED: `
    <label> {{userName}} {{entityAction}} the {{entityName}} {{equityContainerName}} in {{companyName}}</label> 
 <ul>
    <li>{{equityContainerName}} has been {{entityAction}} in {{companyName}} by {{userName}} at {{activityTime}} on {{activityDate}}.</li>
  </ul> `,

    MESSAGE_HEADER_SHARE_CLASS_PUBLISHED:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_SUB_HEADER_SHARE_CLASS_PUBLISHED:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_TEMPLATE_SHARE_CLASS_PUBLISHED: `
    <label> {{userName}} {{entityAction}} a {{entityName}} for {{companyName}}</label> 
 <ul>
   <li>{{entityName}} has been {{entityAction}} for {{companyName}} by {{userName}} at {{activityTime}} on {{activityDate}}.</li>
  </ul> `,


    MESSAGE_HEADER_SHARE_CLASS_PUBLISH_AND_SHARING:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_SUB_HEADER_SHARE_CLASS_PUBLISH_AND_SHARING:
        "{{userName}} {{entityAction}} a {{entityName}} for {{companyName}}.",
    MESSAGE_TEMPLATE_SHARE_CLASS_PUBLISH_AND_SHARING: `
    <label> {{userName}} {{entityAction}} the share class {{equityContainerName}} for {{companyName}}</label> 
 <ul>
    <li>{{equityContainerName}} has been {{entityAction}} for {{companyName}} by {{userName}} at {{activityTime}} on {{activityDate}}.</li>
  </ul> `,


    MESSAGE_BUTTON_REDIRECT_GO_TO_SHARE_CLASS: `View equity details`,
}

export default templates

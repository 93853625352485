import React, {useEffect, useState} from 'react';
import {ClaraCheckForm, Text} from '../../../../components';
import _ from "lodash";
import PropTypes from 'prop-types';
import {Field} from "formik";
import classes from "../SelectorComponent/Shareholders.module.scss";
import FactoryChatForm from '../../../../components/Chat/FactoryChatForm';
import FactoryChatFormConfig from '../../../../components/Chat/FactoryChatFormConfig';
import useMatter from '../../../../Hooks/useMatterQuestionnaire';

const AgreementSelect = ({ name, showButtonAdd, initialValueStart, titleButtons, titleEntyties }) => {


  const [openModal, setOpenModal] = useState(false);
  const { matter } = useMatter(initialValueStart.matterId)
  const [formSelect, setFormSelect] = useState("INDIVIDUAL_FORM");

  const [listStateholder, setListStateholder] = useState(null);
  const [reload, setReload] = useState(false);
  const handleClose = () => {
    setOpenModal(false);
    setReload(!reload);
  }
  const setFom = (form) => {
    setFormSelect(form);
    setOpenModal(true);
  }

  const mapDirectorAndShareholders = (elements) => {       
    return _.concat( _.get(elements,"individuals",[]), _.map( _.compact( _.get(elements,"companies",[]) ), com => ({ id: com.id, companyName: com.companyName, ...com.signatory })))
}



  useEffect(() => {
    if (matter && !listStateholder) {

      let listStateholderLocal = [];
      _.forEach(_.get(matter, "individuals", []), (individual) => {
        listStateholderLocal.push({
          type: "DIRECTOR_INDIVIDUAL_FORM",
          data: individual,
        })
      });
      _.forEach(_.get(matter, "companies", []), (company) => {
        listStateholderLocal.push({
          type: "QUESTIONNAIRE_CORPORATE_DIRECTOR_FORM",
          data: {...company,
            directors : mapDirectorAndShareholders(company.directors),
            shareholders: mapDirectorAndShareholders(company.shareholders)
          }
        })
      });
      setListStateholder(listStateholderLocal);
    }
  })


  return (
    <React.Fragment>
      <div className={` ${classes.containerShareholder}`}>
        <div className={classes.contentField}>
          <h4><Text uuid={titleEntyties} /></h4>
          <Field name={name}>
            {({ field, form }) => (
              <ClaraCheckForm
                reload={reload}
                openForm={openModal}
                addForm={formSelect}
                cbClose={handleClose}
                field={field}
                multiForm
                form={form}
                list={listStateholder}
                initialValues={{ formKey: formSelect }}
                optionsList={{
                  "DIRECTOR_INDIVIDUAL_FORM": {
                    fieldId: _.get(FactoryChatFormConfig, "DIRECTOR_INDIVIDUAL_FORM" + ".id", "id"),
                    fieldLabel: _.get(FactoryChatFormConfig, "DIRECTOR_INDIVIDUAL_FORM" + ".fieldName", null),
                  },
                  "QUESTIONNAIRE_CORPORATE_DIRECTOR_FORM": {
                    fieldId: _.get(FactoryChatFormConfig, "QUESTIONNAIRE_CORPORATE_DIRECTOR_FORM" + ".id", "id"),
                    fieldLabel: _.get(FactoryChatFormConfig, "QUESTIONNAIRE_CORPORATE_DIRECTOR_FORM" + ".fieldName", null),
                  }
                }}
                optionsForm={{
                  "DIRECTOR_INDIVIDUAL_FORM": {
                    titleKey: _.get(FactoryChatFormConfig, "DIRECTOR_INDIVIDUAL_FORM.titleKey", null),
                    educationId: _.get(FactoryChatFormConfig, "DIRECTOR_INDIVIDUAL_FORM.leftEducationId", null),
                    muttation: _.get(FactoryChatFormConfig, "DIRECTOR_INDIVIDUAL_FORM.muttation", null),
                    getIdFromMuttation: _.get(FactoryChatFormConfig, "DIRECTOR_INDIVIDUAL_FORM.getIdFromMuttation", null),
                    paramsMuttaion: initialValueStart,
                    getVariablesMutation: _.get(FactoryChatFormConfig, "DIRECTOR_INDIVIDUAL_FORM.getVariablesMutation", null),
                    succesNotification: "NOTIFICATION_SUCCESS",
                    errorNotification: "NOTIFICATION_BAD",
                  },
                  "QUESTIONNAIRE_CORPORATE_DIRECTOR_FORM": {
                    titleKey: _.get(FactoryChatFormConfig, "QUESTIONNAIRE_CORPORATE_DIRECTOR_FORM.titleKey", null),
                    educationId: _.get(FactoryChatFormConfig, "QUESTIONNAIRE_CORPORATE_DIRECTOR_FORM.leftEducationId", null),
                    muttation: _.get(FactoryChatFormConfig, "QUESTIONNAIRE_CORPORATE_DIRECTOR_FORM.muttation", null),
                    getIdFromMuttation: _.get(FactoryChatFormConfig, "QUESTIONNAIRE_CORPORATE_DIRECTOR_FORM.getIdFromMuttation", null),
                    paramsMuttaion: initialValueStart,
                    getVariablesMutation: _.get(FactoryChatFormConfig, "QUESTIONNAIRE_CORPORATE_DIRECTOR_FORM.getVariablesMutation", null),
                    succesNotification: "NOTIFICATION_SUCCESS",
                    errorNotification: "NOTIFICATION_BAD",
                  }
                }}
                optionsSelect={{
                  auto: false,
                  canSelect: true,
                  canValidationSelect: (val, news, vals) => {
                    if (vals.length < 2) {
                      return true
                    } else {
                      return false
                    }

                  }
                }}
                optionsOperation={{
                  canAdd: true,
                  canEdit: true,
                  canRemove: false,
                }}
                optionsView={{
                  recordsByLine: 5
                }}
                className={{}}

              >
                <FactoryChatForm propsForm={{ isFlow: false }} type={"DIRECTOR_INDIVIDUAL_FORM"} formKey={"DIRECTOR_INDIVIDUAL_FORM"} ></FactoryChatForm>
                <FactoryChatForm propsForm={{ isFlow: false }} type={"QUESTIONNAIRE_CORPORATE_DIRECTOR_FORM"} formKey={"QUESTIONNAIRE_CORPORATE_DIRECTOR_FORM"} ></FactoryChatForm>

              </ClaraCheckForm>
            )}
          </Field>
        </div>
        {showButtonAdd && <>
          <h1><Text uuid={titleButtons} /></h1>
          <hr />
          <div className={classes.contentBotonera}>
            <button className={`${classes.buttonWithAutomatic} ${classes.buttonOption} `} type="button" onClick={() => { setFom("QUESTIONNAIRE_CORPORATE_DIRECTOR_FORM") }}>
              <Text uuid={`QUESTIONARY_SHAREHOLDER_BUTTON_COMPANY_EDIT`} />
            </button>
            <button className={`${classes.buttonWithAutomatic} ${classes.buttonOption} `} type="button" onClick={() => { setFom("DIRECTOR_INDIVIDUAL_FORM") }}>
              <Text uuid={`QUESTIONARY_SHAREHOLDER_BUTTON_INDIVIDUAL_EDIT`} />
            </button>

          </div>
        </>}
      </div>
    </React.Fragment >
  );
}
AgreementSelect.propTypes = {
  responseKeyName: PropTypes.string.isRequired,
  initialValueStart: PropTypes.object.isRequired,

};
export default AgreementSelect;
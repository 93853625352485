import React, { FC, useMemo, useRef } from "react";
import * as Yup from "yup";
import _ from "lodash";
import Form from "../../../../components/forms/Form/FormL";
import ControllerInput from "../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import SubmitButton from "../../../generic/components/Buttons/SubmitButton";
import TemplateFormDefault from "../../../generic/templates/Modal";
import FormProps from "../../../generic/forms/interfaces/FormProps";
import CheckboxButtonSimple from "../../../generic/components/Inputs/CheckboxButtonSimple";
import useSession from "../../../session/hooks/useSession";
import { GroupCompanyBO } from "../../core/type/startup/groupCompany";
import useGetGroupCompanies from "../../equity/graphql/queries/useGetGroupCompanies";
import classes from "./Form.module.scss";
import TemplateWizardSkeletonMachine from "../../../generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine";
import Typography from "src/modules/generic/components/Texts/Typography";

interface GroupCompanies {
  value: GroupCompanyBO | string;
  label: string;
}
interface SelectGroupCompaniesProps extends FormProps {
  groupCompanyId?: string;
  subTitle?: string;
}

const schema = Yup.object().shape({});

const FormSelectGroupCompaniesForShares: FC<SelectGroupCompaniesProps> = ({
  initialValues,
  groupCompanyId,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
  subTitle,
}: SelectGroupCompaniesProps) => {
  const { startupId } = useSession();
  const { data, loading } = useGetGroupCompanies({ variables: { startupId } });
  const refForm = useRef();

  const groupCompanies: GroupCompanies[] = useMemo(
    () =>
      _.filter(
        _.map(data, (gc) => ({
          value: gc,
          label: gc.name,
          kind: gc.kind,
        })),
        (gc) =>
          _.get(gc, "value.id") !== groupCompanyId &&
          _.get(gc, "kind") !== "FORMATION"
      ),
    [JSON.stringify(data)]
  );

  if (loading) {
    return <TemplateWizardSkeletonMachine />;
  }

  return (
    <Form
      defaultValues={initialValues || {}}
      schema={schema}
      onlySendDirty={false}
      onSubmit={onCompleteSubmit}
      ref={refForm}
      optionsForm={{ mode: "onChange" }}
    >
      <Template
        buttons={{
          ...buttons,
          submit: <SubmitButton {...buttonSubmitProps}>Next</SubmitButton>,
        }}
        props={propsTemplate}
      >
        {subTitle && (
          <Typography component="p" variant="subTitle">
            <span dangerouslySetInnerHTML={{ __html: subTitle }}></span>
          </Typography>
        )}
        {groupCompanies && groupCompanies.length > 0 ? (
          <ControllerInput
            render={CheckboxButtonSimple}
            isMultiple={true}
            defaultValue={[]}
            name="companies"
            options={groupCompanies}
            className={classes.checkboxMultiple}
            classNameButton={classes.buttonCheckBox}
          />
        ) : (
          <Typography fontWeight="bold" component="h1" variant="label">
            <span dangerouslySetInnerHTML={{ __html: "None" }}></span>
          </Typography>
        )}
      </Template>
    </Form>
  );
};

export default FormSelectGroupCompaniesForShares;

import useLazyQuery from "src/hooks/custom/useLazyQuery";
const GET_GROUP_COMPANY_AND_STAKEHOLDERS = `
query getGroupCompanyDataProtectionContactAndStakeholders($startupId: ID!, $groupCompanyId: ID!) {
  getGroupCompany(startupId: $startupId, groupCompanyId: $groupCompanyId) {
    id
    keyPeople{
      dataProtectionContact{
        id
        fullName
      }
      }
    }
  
  stakeholderList(startupId: $startupId) {
    id
    fullName
    isFounder
  }
}`

const useGetGroupCompanyAndStakeholders =(variables) => {
    const { manualQuery, loading, error, data, refetch } = useLazyQuery(GET_GROUP_COMPANY_AND_STAKEHOLDERS, variables);
    
    const query = async (variables) => {
        const { data } = await manualQuery({variables})
        return {
                groupCompany: data['getGroupCompany'],
                stakeholders: data['stakeholderList']
        }
    }

    return {
        query,
        loading,
        error,
        data,
        refetch
    }
}
export default useGetGroupCompanyAndStakeholders
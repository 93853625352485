import { Field } from "formik";
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ClaraRadioWithButton, ClaraUpload, Text, TextAreaInput, TextInput } from '../index';
import classes from "./Chat.module.scss";

const ChatCompanyDetails = ({ responseKeyName,isForm,initialValueStart,companyName }) => {
  const nameField = responseKeyName ? responseKeyName + "." : "";
  const [hasSpecialMeaning, setHasSpecialMeaning] = useState(false);
  const [includeArabicCompanyName, setIncludeArabicCompanyName] = useState(null);
  const [hasAffiliated, setHasAffiliated] = useState(false);
  
  const [options, setOptions] = useState([
    { 'label': 'TEXT_YES', 'value': true },
    { 'label': 'TEXT_NO', 'value': false }
  ])

  useEffect(() => {
    
  })
  return (
    <React.Fragment>
      <div className={classes.ChatCompanyDetails}>
        <h3><Text uuid={"QUESTIONARY_FORM_COMPANY_DETAILS_QUESTION1"} params={{ startupName: companyName }} /></h3>
        <React.Fragment>
            <p><Text uuid={"QUESTIONARY_FORM_COMPANY_DETAILS_SPECIAL_MEANING_TOOLTIP"} /></p>
          </React.Fragment>
        <Field name={nameField + "hasSpecialMeaning"}>
          {({ field, form }) => (
            <React.Fragment>
              <ClaraRadioWithButton
                label=""
                field={field}
                form={form}
                changeParams={field.name}
                options={options}
                className={{
                  root:classes.ChatClaraRadioButton,
                  input:classes.ChatClaraRadioButtonInput
                }}
                onChange={(v) => { setHasSpecialMeaning(v) }}

              />
            </React.Fragment>
          )}
        </Field>
        {hasSpecialMeaning ? (
          <Field name={nameField + "specialMeaning"}>
            {({ field, form }) => (
              <React.Fragment>
                <TextAreaInput
                  required={true}
                  field={field}
                  form={form}
                  autoComplete='off'
                  placeholder={"QUESTIONARY_FORM_COMPANY_DETAILS_SPECIAL_MEANING_PLACEHOLDER"}
                  showRequired={false}
                  className={classes.ChatInputTextarea}
                />
              </React.Fragment>
            )}
          </Field>
        ) : null}

         <br></br><br></br>       
        <h3><Text uuid={"QUESTIONARY_FORM_COMPANY_DETAILS_QUESTION2"}  params={{ startupName: companyName }} /></h3>
        <React.Fragment>
            <p><Text uuid={"QUESTIONARY_FORM_COMPANY_DETAILS_ARABIC_TOOLTIP"} /></p>
          </React.Fragment>
        <Field name={nameField+ "includeArabicCompanyName"}>
          {({ field, form }) => (
            <React.Fragment>
              <ClaraRadioWithButton
                label=""
                field={field}
                form={form}
                changeParams={field.name}
                options={options}
                className={{
                  root:classes.ChatClaraRadioButton,
                  input:classes.ChatClaraRadioButtonInput
                }}
                onChange={(v) => { setIncludeArabicCompanyName(v) }}
              />
            </React.Fragment>
          )}
        </Field>
        {includeArabicCompanyName===false ? (
          <React.Fragment>
            <p><Text uuid={"QUESTIONARY_FORM_COMPANY_DETAILS_ARABIC_NOTE"} /></p>
          </React.Fragment>
        ):null}
        {includeArabicCompanyName ? (
          <Field name={nameField+ "arabicCompanyNames"}>
            {({ field, form }) => (
              <React.Fragment>
                <TextInput
                  showRedStar={false}
                  required={true}
                  type='text'
                  field={field}
                  label={'    '}
                  form={form}
                  placeholder={"QUESTIONARY_FORM_COMPANY_DETAILS_ARABIC_COMPANY_NAME_PLACEHOLDER"}
                  className={classes.ChatInputText}
                />
              </React.Fragment>
            )}
          </Field>
        ) : null}
        <br></br>  <br></br>  
        <h3><Text uuid={"QUESTIONARY_FORM_COMPANY_DETAILS_QUESTION3"}  params={{ startupName: companyName }} /></h3>
        <React.Fragment>
            <p><Text uuid={"QUESTIONARY_FORM_COMPANY_DETAILS_AFFILIATION_TOOLTIP"} /></p>
          </React.Fragment>
        <Field name={nameField + "hasAffiliated"}>
          {({ field, form }) => (
            <React.Fragment>
              <ClaraRadioWithButton
                label=""
                field={field}
                form={form}
                changeParams={field.name}
                options={options}
                className={{
                  root:classes.ChatClaraRadioButton,
                  input:classes.ChatClaraRadioButtonInput
                }}
                onChange={(v) => { setHasAffiliated(v) }}
              />
            </React.Fragment>
          )}
        </Field>
        {hasAffiliated ? (
          <React.Fragment>
            <p><Text uuid={"QUESTIONARY_FORM_COMPANY_DETAILS_LABEL_AFFILIATED_LEGALNAME"} /></p>
            <Field name={nameField + "affiliatedLegalName"}>
              {({ field, form }) => (
                <React.Fragment>
                  <TextInput
                    showRedStar={false}
                    required={true}
                    type='text'
                    field={field}
                    label={'    '}
                    form={form}
                    placeholder={"QUESTIONARY_FORM_COMPANY_DETAILS_AFFILIATED_PLACEHOLDER"}
                    className={classes.ChatInputText}
                  />
                </React.Fragment>
              )}
            </Field>
            <p><Text uuid={"QUESTIONARY_FORM_COMPANY_DETAILS_LABEL_AFFILIATED_FILE"} /></p>
            <Field name={nameField + "affiliatedFile"}>
              {({ field, form }) => (
                <ClaraUpload
                  showRedStar={false}
                  required={true}
                  name={`filepond`}
                  field={field}
                  form={form}
                  className={classes.ChatClaraUpload}
                />
              )}
            </Field>
          </React.Fragment>

        ) : null}
      </div>
    </React.Fragment >
  );
}
ChatCompanyDetails.propTypes = {
  responseKeyName: PropTypes.string.isRequired,
  initialValueStart: PropTypes.object.isRequired, 
  
};
export default ChatCompanyDetails;
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useModal from 'src/modules/generic/hooks/useModal';
import ModalAssistant from '../../../generic/components/Modal/ModalAssistant';
import { clearAssistatText } from '../../../generic/store/action';
import Wizard from '../../../generic/components/Wizard/v2';
import useGuards from './hooks/useGuards';
import useSteps from './hooks/useSteps';
import useServices from './hooks/useServices';
import useActions from './hooks/useActions';
import machine from './machine';

interface ModalShareCapitalProps {
    paramsMutation: any,
    onClose(): void,
    children: ReactNode,
    open: boolean,
}

const ModalShareCapital: FC<ModalShareCapitalProps> = ({ children, open: openParam = false, paramsMutation, ...props }: ModalShareCapitalProps) => {
    const [open, setOpen] = useState(openParam);
    const { closeModal: onClose } = useModal();
    const dispatch = useDispatch();
    const guards = useGuards();
    const steps = useSteps();
    const actions = useActions();
    const services = useServices(paramsMutation);

    useEffect(() => {
        setOpen(openParam);
    }, [openParam]);

    const handleClose = () => {
        onClose();
        setOpen(false);
    }

    const handleInitModal = () => {
        dispatch(clearAssistatText());
    }

    useEffect(() => {
        handleInitModal();
    }, []);

    return (
        <ModalAssistant open={open}>
            <Wizard
                guards={guards}
                machine={machine}
                steps={steps}
                services={services}
                actions={actions}
                onStopMachine={handleClose}
            />
        </ModalAssistant>
    );
}

export default ModalShareCapital;
import React, { FC } from "react";
import _ from 'lodash'
import Typography from "../../../components/Texts/Typography";
import classes from './classes.module.scss'
import Button, { ButtonProps } from "../../../components/Buttons/Button";
interface TemplateRemoveProps {
    title: string
    className:string
    description?: string
}
interface ButtonDeleteProps {
    submit: FC
    cancel: FC
    buttons: ButtonProps[]
}
const TemplateRemove = ({ children, className,buttons, props: { title = '', description } }) => {
    return (
        <>
            <div className={`${classes.container} ${className}`}>
                <div className={classes.header}>
                    <Typography variant={'h2'} weight='regular' color={'black'}>
                        {title}
                    </Typography>

                </div>
                {description}
                <div className={classes.form}>
                    {children}
                </div>
                <div className={classes.buttons}>
                    {_.map(buttons.buttons, button => (
                        <Button{...button}>{button.children}</Button>
                    ))
                    }
                    {buttons.cancel}
                    {buttons.submit}
                </div>
            </div>
        </>
    )
}
export default TemplateRemove
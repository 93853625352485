import TagManager from 'react-gtm-module';
export default () => {
    const tagManagerArgs = {
        dataLayer: {
            event: "PageView",
            path: "maps/?status=addTeamMembers",
            pageName: "Stakeholder Map - Start Adding Team Members"
        },
        dataLayerName: "PageDataLayer",
    };
    TagManager.dataLayer(tagManagerArgs);
}
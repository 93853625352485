import { useMemo } from 'react';
import _ from "lodash";

const useGuards = () => {
  return useMemo(() => ({
    toGenerate: (context) => {
      let result = _.get(context, 'resultChoice', '');
      return result === false;
    },
    toAdd: (context) => {
      let result = _.get(context, 'resultChoice', '');
      return result;
    },
  }), []);
}

export default useGuards;
import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import Icon from '../../../../../../../../modules/generic/components/Icon'
import { PopoverOption } from '../../../../../../../../v1/components';
import classes from './Styles.module.scss';

const TypeHeader = ({ data, className, documents, typeData, handler, handleOpenViewer, ...props }) => {

  const [typeOpts, setTypeOpts] = useState(null);

  const formatTypes = () => {
    let opts = [];
    if (typeData && typeData.types) {
      opts = _.map(typeData.types, cat => {
        return ({
          label: cat.label,
          onClick: () => handler(cat.id, 'TYPE')
        })
      });
      setTypeOpts(opts)
    }
  };

  useEffect(() => {
    formatTypes()
  }, [JSON.stringify(typeData)]);

  return (
    <div className={`${classes.containerDocumentName} ${typeOpts && typeOpts.length !== 0 ? null : classes.notClickable} ${className} ${classes.documentTabFilterHeader}`}>
      {typeOpts && typeOpts.length !== 0 ? <PopoverOption elevation={0} classicStyle={true} renderElement={TypeOptions} propsRenderElement={{ options: typeOpts }}>
        <span><label>Type</label><Icon isClara icon={'Dropdown'} size={'1rem'} /></span>
      </PopoverOption> : <span><label>Type</label></span>}
    </div>
  )
};

export default TypeHeader

const TypeOptions = ({ options = [], closePopover, ...props }) => {

  const handleClose = ({ onClick }) => {
    onClick();
    closePopover()
  };

  return (
    <div className={classes.containerMenuOptionDocument}>
      {options && options.map((option, index) => {
        return (
          <div key={`${JSON.stringify(option)}-${index}`} className={classes.containerOption} onClick={() => handleClose(option)}>
            {option.label}
          </div>
        )
      })
      }
    </div>
  )
};
import React, { useEffect, useMemo, useRef } from "react";

import { Constants } from "../../../../../../v1/utils/constants";
import EditDocumentUpload from '../../Document/Modals/EditDocumentUpload/EditDocumentUpload';
import Icon from "../../../../../../modules/generic/components/Icon";
import MessageTemplate from "./MessageTemplate";
import PrimaryButton from "../../../../../generic/Buttons/PrimaryButton/PrimaryButton";
import RoleAccess from "../../../../../../modules/security/components/RoleAccess/RoleAccess";
import Scrollbar from "../../../../../../components/generic/Scrollbar/Scrollbar";
import Text from "../../../../../../components/text/Text/Text";
import _ from "lodash";
import classes from "./ZoneMessages.module.scss";
import { goToPage } from "../../../../../../store/actions/page";
import { mapFoTActions } from "../utils/mapFoTActions";
import moment from "moment";
import { useDispatch } from "react-redux";
import useModal from "../../../../../../modules/generic/hooks/useModal";
import useMutation from "../../../../../../hooks/custom/useMutation";
import useSession from "../../../../../../modules/session/hooks/useSession";

const MARK_MESSAGE_AS_READ = `mutation markThreadAsRead($startupId: ID, $threadId: ID) {
    markThreadAsRead(startupId: $startupId, threadId: $threadId)
  }  
`;

const ZoneMessages = ({ thread = {}, ...props }) => {

  const threadId = useMemo(() => _.get(thread, "id"), [thread]);

  const { startupId = "" } = useSession();

  const { openModal,closeModal } = useModal();
  
  const dispatch = useDispatch();
  
  const [markThreadAsRead, { data }] = useMutation(MARK_MESSAGE_AS_READ, {
    showSuccessNotification: false
  });

  useEffect(() => {
    if (_.some(_.get(thread, "messages", []), ["read", false])) {
      markThreadAsRead({ variables: { startupId, threadId } });
    }
  }, [JSON.stringify(thread)]);

  const handleRedirect = (action) => {
    if (_.isEmpty(thread)) {
      return () => {};
    }
    switch (action.type) {
      case "GO_TO_STAKEHOLDER":
        {
          if (_.last(thread.messages).template.isAnEntity) {
            dispatch({
              type: "GO_TO",
              page: Constants.PAGES.viewStakeholderCompany,
              params: { id: action.entityId, tab: "view" },
            });
          } else
            dispatch({
              type: "GO_TO",
              page: Constants.PAGES.viewStakeholderIndividual,
              params: { id: action.entityId, tab: "view" },
            });
        }
        break;
      case "GO_TO_STAKEHOLDER_SHARE_OPTIONS":
        {
          if (_.last(thread.messages).template.isAnEntity) {
            dispatch({
              type: "GO_TO",
              page: Constants.PAGES.viewStakeholderCompany,
              params: { id: action.entityId, tab: "SHARE_OPTIONS" },
            });
          } else
            dispatch({
              type: "GO_TO",
              page: Constants.PAGES.viewStakeholderIndividual,
              params: { id: action.entityId, tab: "SHARE_OPTIONS" },
            });
        }
        break;
      case "GO_TO_STARTUP_PROFILE":
        dispatch({
          type: "GO_TO",
          page: Constants.PAGES.viewStartup,
          params: { id: action.entityId },
        });
        break;
      case "GO_TO_COMPANY":
        dispatch({
          type: "GO_TO",
          page: Constants.PAGES.viewGroupCompany,
          params: { id: action.entityId },
        });
        break;
      case "GO_TO_EQUITY_POSITION":
        {
          let lastMessage = _.get(thread, "messages")[
            _.get(thread, "messages").length - 1
          ];

          if (_.get(lastMessage, "template.equityPosition.owner.isAnEntity")) {
            dispatch({
              type: "GO_TO",
              page: Constants.PAGES.viewStakeholderCompany,
              params: {
                id: _.get(lastMessage, "template.equityPosition.owner._id"),
                tab: "SHARE_OPTIONS",
              },
            });
          } else if (
            _.get(lastMessage, "template.equityPosition.owner.type") ===
            "groupCompany"
          ) {
            dispatch({
              type: "GO_TO",
              page: Constants.PAGES.viewGroupCompany,
              params: {
                id: _.get(
                  lastMessage,
                  "template.equityPosition.equityCompanyId"
                ),
                tab: "SHARE_OPTIONS",
              },
            });
          } else {
            dispatch({
              type: "GO_TO",
              page: Constants.PAGES.viewStakeholderIndividual,
              params: {
                id: _.get(lastMessage, "template.equityPosition.owner._id"),
                tab: "SHARE_OPTIONS",
              },
            });
          }
        }
        break;
      case "GO_TO_UNCATEGORIZED_DOCUMENT":
        {
          const draftDocument = _.last(thread.messages).template.file
          const data = {file:draftDocument.file,draft:true,id:draftDocument._id};
          const handleClose = ()=>{
            closeModal()
            dispatch({
              type:"GO_TO",
              page:Constants.PAGES.documents,
            })
          }
          openModal(EditDocumentUpload,{handleClose,data});
        }
        break;
      case "GO_TO_REMARK":
        {
          const lastMessage = _.last(thread.messages);
          dispatch({
            type: "GO_TO",
            page: Constants.PAGES.viewHealthCheckIssue,
            params: { id: action.entityId, ...lastMessage.template.remark },
          });
        }
        break;
      case "GO_TO_DOCUMENT":
        {
          const { kind = "GENERATED", status = "Draft" } = _.last(
            thread.messages
          ).template.agreement;
          dispatch({
            type: "GO_TO",
            page:
              Constants.PAGES[
                `viewDocument${_.upperFirst(_.toLower(kind || "UPLOADED"))}`
              ],
            params: { id: action.entityId, status },
          });
        }
        break;
      case "GO_TO_USER_PROFILE":
        {
          dispatch({
            type: "GO_TO",
            page: Constants.PAGES.viewStartup,
            params: { id: action.entityId, tab: "sharing" },
          });
        }
        break;
      case "GO_TO_DOCUMENT_LIST":
        dispatch({ type: "GO_TO", page: Constants.PAGES.documents });
        break;

      case "GO_TO_GENERATE_GRANT_DOCUMENT":
        goToPage(dispatch, Constants.PAGES.generateDocument, {
          docType: "GRANT_AGREEMENT",
        });
        break;

      case "GO_TO_CREATE_DOCUMENTS":
        dispatch({ type: "GO_TO", page: Constants.PAGES.chooseDocumentType });
        break;

      case "GO_TO_GENERATE_SIP_DOCUMENT":
        goToPage(dispatch, Constants.PAGES.generateDocument, {
          docType: "SHARE_INCENTIVE_PLAN",
        });
        break;

      case "GO_TO_CONVERTIBLE":
        {
          const template = _.last(
            thread.messages
          ).template;
       
          dispatch({
            type: "GO_TO",
            page: Constants.PAGES["capTable.mf.convertibles.view"],
            params: {
               convertibleId: action.entityId, 
                groupCompanyId: template.data?.groupCompany?.groupCompanyId 
              },
            
          });
         
        }
        break;  
      case "GO_TO_OPTION_GRANT":
        {
          const template = _.last(
            thread.messages
          ).template;
          const params = _.last(template.actions).params;
          dispatch({
            type: "GO_TO",
            page: Constants.PAGES["capTable.mf.options.grant.view"],
            params: { 
              convertibleId: action.entityId, 
              groupCompanyId: template.data?.groupCompany?.groupCompanyId,
              shareClassId: params?.shareClassId,
              optionPlanId: params?.optionPlanId,
              optionGrantId: params?.entityId
            },
          });

        }
        break;  
      case "GO_TO_WARRANT":
        {
          const template = _.last(
            thread.messages
          ).template;
          dispatch({
            type: "GO_TO",
            page: Constants.PAGES["capTable.mf.warrants.view"],
            params: { 
              warrantId: action.entityId,
              groupCompanyId: template.data?.groupCompany?.groupCompanyId,
        
            },

          });

        }
        break;  
      case "GO_TO_SHARE_ISSUANCE":
        {
          const template = _.last(
            thread.messages
          ).template;
          const params = _.last(template.actions).params;
          dispatch({
            type: "GO_TO",
            page: Constants.PAGES["capTable.mf.shares.view"],
            params: {
              shareIssuanceId: action.entityId, 
              groupCompanyId: template.data?.groupCompany?.groupCompanyId,
              shareClassId: params?.shareClassId
            },
          });

        }
        break;  
      case "GO_TO_SHARE_CLASS":
        {
          const template = _.last(
            thread.messages
          ).template;
          dispatch({
            type: "GO_TO",
            page: Constants.PAGES["capTable.mf.shareClass.view"],
            params: {
              shareClassId: action.entityId,
              groupCompanyId: template.data?.groupCompany?.groupCompanyId,
            },
          });

        }
        break;  
      case "GO_TO_OPTION_PLAN":
        {
          const template = _.last(
            thread.messages
          ).template;
          const params = _.last(template.actions).params;
          dispatch({
            type: "GO_TO",
            page: Constants.PAGES["capTable.mf.options.list"],
            params: {
              optionPlanId: action.entityId,
              groupCompanyId: template.data?.groupCompany?.groupCompanyId,
              shareClassId: params?.shareClassId
            },
          });

        }
        break;
      case "GO_TO_MODELLING":
        {
          const template = _.last(
            thread.messages
          ).template;
           
          dispatch({
            type: "GO_TO",
            page: Constants.PAGES["capTable.mf.modelling"],
            params: { 
              modellingId: action.entityId, 
              groupCompanyId: template.data?.groupCompany?.groupCompanyId,
            },
          });

        }
        break;
      case "GO_TO_VESTING_TERM":
        {
          const template = _.last(
            thread.messages
          ).template;
        
          dispatch({
            type: "GO_TO",
            page: Constants.PAGES["capTable.mf.vesting.view"],
            params: { 
              vestingId: action.entityId,
               groupCompanyId: template.data?.groupCompany?.groupCompanyId },
          });

        }
        break;

      case "GO_TO_VALUATION":
        {
          const template = _.last(
            thread.messages
          ).template;
          dispatch({
            type: "GO_TO",
            page: Constants.PAGES["capTable.mf.valuation.view"],
            params: { valuationId: action.entityId, groupCompanyId: template.data?.groupCompany?.groupCompanyId },
          });

        }
        break;
        
      case "GO_TO_FOT":
        { 

          const template = _.last( thread.messages).template;
          const page = mapFoTActions(template)
          dispatch({
            type: "GO_TO",
            page: Constants.PAGES[page.path],
            params: { groupCompanyId: template.data?.groupCompany?.groupCompanyId, ...page.params  },
          });

        }
        break;
      default:
        return () => {};
    }
  };

  const ButtonAction = {
    GO_TO_USER_PROFILE: Constants.ACTIONS.MANAGE_SHARING,
    GO_TO_UNCATEGORIZED_DOCUMENT: Constants.ACTIONS.EDIT_PROFILE,
  };

  return (
    <div className={`${classes.containerZoneMessages}`}>
      <Scrollbar scrollBottom={true}>
        {_.isEmpty(thread) ? (
          <>
            <div className={classes.containerNotMessages}>
              <span>
                <Icon
                  color={"#2C74FF"}
                  icon={"No-Messages"}
                  size={"5rem"}
                  isClara
                />
                <label>Please select an item from the list</label>
              </span>
            </div>
          </>
        ) : (
          <div className={classes.containerThreadMessage}>
            {thread.messages.map((message,index) => {
              const activityDate = moment(message.createdAt).format("DD/MM/YYYY");
              const activityTime = moment(message.createdAt).format("LT");
              return (
                <MessageTemplate
                  key={index}
                  template={message.template}
                  activityDate={activityDate}
                  activityTime={activityTime}
                  createdBy={message.createdBy}
                  actions={thread.actions}
                />
              );
            })}
            {thread.actions && (
              <div className={classes.containerButtonEvent}>
                {_.map(thread.actions, ( action, index ) => {
                  return (
                    <RoleAccess key={index} action={action ? ButtonAction[action.type] : "VIEW_PROFILE"} >
                      {index === 0 && (
                        <div className={classes.containerIcon}>
                          <Icon
                            color={"#2C74FF"}
                            icon={"Assistant"}
                            size={"1.8462rem"}
                            isClara
                          />
                        </div>
                      )}
                      <PrimaryButton
                        onClick={() => handleRedirect(action)}
                        style={{ marginLeft: index !== 0 ? "1rem" : null }}
                      >
                        <Text uuid={`MESSAGE_BUTTON_REDIRECT_${action.type}`} />
                      </PrimaryButton>
                    </RoleAccess>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </Scrollbar>
    </div>
  );
};

export default ZoneMessages;

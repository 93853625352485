import { useMemo } from 'react';
import useBusinessRules from 'src/modules/clara/hooks/useBusinessRules';
import useSession from 'src/modules/session/hooks/useSession';
import _ from "lodash";

const useGuards = () => {
  const {isOwner} = useBusinessRules()
  const { session} = useSession()
  return useMemo(() => ({
    isNomineeThirdParty: (context) => {
      return _.get(context?.WhoIsSecondAuthorisedSignatoryFormStep, 'secondAuthorisedSignatory.value', '') === 'ADD_THIRD_PARTY'
    },
    isThirdParty: (context) => {      
      return _.get(context?.WhoIsGoingToBeYourAuthorisedSignatoryFormStep, 'authorisedSignatory.value', '') === 'ADD_THIRD_PARTY'
    }
    
    
  
  }), [isOwner, session]);
}

export default useGuards;

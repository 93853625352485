import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "src/modules/generic/components/Buttons/Button";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import { clearAssistatText } from "src/modules/generic/store/action";
import FormSelectGroupCompanies from "src/modules/startup/GroupCompany/Forms/FormSelectGroupCompaniesForShares";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";

function WhichCompaniesHaveSharesStep() {
  const { next, prev, context, cancel } = useMachine();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return (
      context?.SelectCompanies ??
      context?.initialData?.assetsAndRevenue.sharesInIncorporatedCompanies ??
      {}
    );
  }, [context]);

  useEffect(() => {
    dispatch(clearAssistatText());
  }, []);

  const handleSubmit = async (values) => next(values);

  const handleCancel = () => cancel();

  const handlePrev = () => prev();

  return (
    <FormSelectGroupCompanies
      initialValues={initialValues}
      groupCompanyId={id}
      onCompleteSubmit={handleSubmit}
      subTitle="Which incorporated companies do they have shares in?"
      Template={TemplateWizard}
      propsTemplate={{
        title: "Edit assets and revenue",
      }}
      buttons={{
        cancel: (
          <Button onClick={handleCancel} variant="secondary">
            Cancel
          </Button>
        ),
      }}
    />
  );
}

export default WhichCompaniesHaveSharesStep;

import { useMemo } from 'react';
import useSession from 'src/modules/session/hooks/useSession';
import useUpsertCompanyFormation from 'src/hooks/services/modules/groupCompany/useUpsertCompanyFormation';
import _ from 'lodash';
import { Constants } from 'src/v1/utils/constants';

const useServices = () => {
  const { startupId, selectStartup } = useSession();
  const mutationAddCompany = useUpsertCompanyFormation();

  return useMemo(() => ({

    SaveStep_ADGM: async (context, event) => {
      const variables = {
        startupId,
        groupCompanyData: {
          country: "AE",
          isHoldingCompany: _.get(context, "AddADGMCompany.isHoldingCompany", false),
          jurisdiction: "Abu Dhabi Global Market",
          jurisdictionType: "ADGM",
          companyType: Constants.MODULES.FORMATION.COMPANIES_TYPE.ADGM_SPV,
          name:`${_.get(context, "AddADGMCompany.name", "")} Holdings Limited`,
        }
      };
      if(_.get(context, "ADGMSPVAuthorisedSignatory.authorisedSignatory", "") === "claras_nominee") {
        Object.assign(variables.groupCompanyData, {
          formationData: {
            isUsingNominee: true
          }
        });
      }
      if(_.get(context, "ADGMSPVAuthorisedSignatory.authorisedSignatory", "") === "third_party") {
        Object.assign(variables.groupCompanyData, {
          formationData: {
            isUsingThirdParty: true
          }
        });
      }
      const result = await mutationAddCompany(variables)
      await selectStartup(startupId,null);
      return result;
    },

  }), []);
}

export default useServices;

import React from 'react';


const EmptyScreenLayout = ({ children }) => {
 

  return (
    <React.Fragment>
      
          {children}
        
    </React.Fragment>
  );
}

export default EmptyScreenLayout;
import React from 'react'
import classes from "../../GetSignatureForm.module.scss";
import {Field, FieldArray} from "formik";
import _ from "lodash";
import RowAdd from "../../../../../../contents/RowAdd/RowAdd";
import {TextInput} from "../../../../../../../../v1/components";
import Line from "../../../../../../../generic/Line/Line";
import checkOk from "../../../../../../../../images/checkOk.svg";

const IndividualCompany=({fieldName,gcAuthorizedSignatoriesEmailDisabled,handleOnChangeEmailPartyGroupCompany})=>{
    
    return(<>
        <div className={classes.ContainerData}>
            <FieldArray
                name={`${fieldName}.authorizedSignatories`}
                render={(arrayHelpers) => (
                    <div>
                        {_.get(arrayHelpers.form.values, `${fieldName}.authorizedSignatories`).map((signatory, index) => {
                            const isLast = _.get(arrayHelpers.form.values, `${fieldName}.authorizedSignatories`).length === index + 1;
                            return (
                                <div className={`${classes.ContainerRow}`}>
                                    <div className={`${classes.ContainerRow} ${classes.ContainerDataPhoto}`}>
                                        <label className={classes.subTitle}>Corporate Signatory:</label>
                                    </div>
                                    <Field
                                        name={`${fieldName}.authorizedSignatories[${index}].fullName`}>
                                        {({ field, form }) => {
                                            return (
                                                <RowAdd
                                                    label={"Name:"}
                                                    asterisk={true}
                                                    classNameInput={classes.input}
                                                    input={
                                                        <TextInput
                                                            type='text'
                                                            field={field}
                                                            form={form}
                                                            disabled={true}
                                                            placeholder={"Name..."}
                                                        />
                                                    }
                                                />
                                            )
                                        }}
                                    </Field>
                                    <Field
                                        name={`${fieldName}.authorizedSignatories[${index}].email`}>
                                        {({ field, form }) => {
                                            return (
                                                <RowAdd
                                                    label={"Email address:"}
                                                    asterisk={true}
                                                    classNameInput={classes.input}
                                                    input={
                                                        <TextInput
                                                            type='email'
                                                            field={field}
                                                            form={form}
                                                            disabled={gcAuthorizedSignatoriesEmailDisabled[index]}
                                                            onChange={(e) => handleOnChangeEmailPartyGroupCompany(form, field, e.target.value, index)}
                                                            placeholder={"Email address..."}
                                                            rightLabel={gcAuthorizedSignatoriesEmailDisabled[index] ? <img style={{'position': 'absolute', 'top': '-8px', 'right': '-10px'}} width={30} height={30} src={checkOk}/> : null}
                                                        />
                                                    }
                                                />
                                            )
                                        }}
                                    </Field>
                                    {!isLast && <Line />}
                                </div>)
                        })}
                    </div>)}
            />
        </div>
    </>)
}
export default IndividualCompany;

import React, { useEffect } from 'react';
import useQuery from "../../../../custom/useQuery"
import _ from 'lodash';

const query = `query documentUploadedList($startupId:ID!) {
    documentUploadedList(startupId: $startupId) {
        id
        name
    }
}`;


const useEquityShareDocuments = (variables) => {

    const { loading, error, data } = useQuery(query, variables);
    useEffect(() => {
        
    }, []);
    return { loading, error, data };
}
export default useEquityShareDocuments;
import React from 'react';
import classes from './IconSecondaryButtonContent.module.scss';
import Icon from "../../../../modules/generic/components/Icon";
import Text from "../../../../components/text/Text/Text";
import _ from 'lodash';
import classNames from 'classnames';

const IconSecondaryButtonContent = ({ icon, text, className,color}) => {
    return (
        <div className={`${classes.IconSecondaryButtonContent} ${className}`}>
            <Icon className={classNames(classes[`icon-color-${color}`])} icon={icon} size={"1.3077rem"} isClara={true} />
            <span className={_.get(className, "text", "")}><Text uuid={text}></Text></span>
        </div>
    )
}

export default IconSecondaryButtonContent

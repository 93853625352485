import React from "react";
import CalendarInput from "./CalendarInput";

function CalendarInputTime({  ...props }) {

    return (

        <CalendarInput {...props} type="time"/>


    );
}

export default CalendarInputTime;
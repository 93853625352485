import { useMemo } from "react";
import { assign } from 'xstate';
import { pick, every, get, isEmpty, uniq } from 'lodash'
import { Constants } from "src/v1/utils";
import { stripTypenames } from "src/v1/utils/graphql-util";
import useSession from 'src/modules/session/hooks/useSession';
import useUpsertComponentRole from '../../graphql/mutation/useUpsertRole'
import useUpdateGroupCompany from '../../graphql/mutation/useUpdateGroupCompany'
import { $CODE_CAYMAN_GOAL } from "../../../onBoarding/company/steps/Step8A";
import { useParams } from 'react-router';
import useGetSakeholderByIdLazy from '../../graphql/queries/useGetStakeholderByIdLazy';
import _ from 'lodash';

const useActions = () => {
  const { startupId } = useSession()
  const { upsertComponentRole } = useUpsertComponentRole({
    showSuccessNotification: true,
    successMessage: 'ADD_ROLE_SUCCESS'
  }, {})
  const { id } = useParams()
  const mutation = useUpdateGroupCompany()
  const { manualQuery } = useGetSakeholderByIdLazy()

  return {

    setContextAfterStep: assign((context, event) => {
      const values = get(event, 'payload.values');
      const solution = { ...context, ...values };
      return solution;
    }),

    addIterations: assign((context, event) => {
      const iterations = get(context, 'iterations', 0);
      const solution = { ...context, iterations: iterations + 1 };
      return solution;
    }),

    resetIterations: assign((context, event) => {
      return { ...context, iterations: 0 }
    }),

    setGroupCompaniesWithoutFormation: assign((context, event) => {
      const solution = { ...context, groupCompanies: (context?.groupCompanies || []).filter(gc => gc.kind !== 'FORMATION') };
      return solution
    }),

    setHasPincas: assign((context, event) => {
      const hasPincas = (context.getGroupCompaniesAndStakeholder.groupCompanies || []).some(gc => gc.managedByClara && gc.kind === 'INCORPORATED')
      return { ...context, hasPincas }
    }),

    setRolesByContext: assign((context: any, event) => {
      const rolesInProgress = context.getGroupCompaniesAndStakeholder?.rolesInProgress ?? []
      const roleKeys = Object.keys(Constants.ROLES_FROM_BACKEND)
      const filteredKeys = roleKeys.filter(key => {
        if (!["FOUNDER", "ADVISOR", "EMPLOYEE", "CONSULTANT", "INVESTOR", "THIRDPARTY", "SHAREHOLDER", "DIRECTOR"].includes(key)) return false
        if (context.getGroupCompaniesAndStakeholder.stakeholder.isAnEntity && (key === Constants.ROLES_FROM_BACKEND.FOUNDER.id || key === Constants.ROLES_FROM_BACKEND.EMPLOYEE.id)) return false
        if (every(context.getGroupCompaniesAndStakeholder.groupCompanies, { kind: 'FORMATION' }) && context.getGroupCompaniesAndStakeholder.groupCompanies.length && (key !== Constants.ROLES_FROM_BACKEND.SHAREHOLDER.id && key !== Constants.ROLES_FROM_BACKEND.DIRECTOR.id && key !== Constants.ROLES_FROM_BACKEND.FOUNDER.id && key !== Constants.ROLES_FROM_BACKEND.THIRDPARTY.id)) return false
        if (every(context.getGroupCompaniesAndStakeholder.groupCompanies, gc => gc.managedByClara && rolesInProgress.some(r => r.entity.id === gc.id)) && context.getGroupCompaniesAndStakeholder.groupCompanies.length && rolesInProgress.some(r => r.name === key)) return false
        return true
      })
      return {
        ...context,
        roles: isEmpty(context.getGroupCompaniesAndStakeholder.groupCompanies) ?
          pick(Constants.ROLES_FROM_BACKEND, [Constants.ROLES_FROM_BACKEND.FOUNDER.id]) :
          pick(Constants.ROLES_FROM_BACKEND, filteredKeys)
      }
    }),

    setRolesAndIsFounder: assign((context, event) => {
      const values = get(event, 'payload.values');
      const solution = { ...context, isFounder: values.roles.includes('FOUNDER'), roles: values.roles.filter(r => r !== 'FOUNDER') };
      return solution;
    }),

    upsertRoles: async (context, event) => {
      const values = get(event, 'payload.values');
      const roles = get(context, 'roles');
      const st = await manualQuery({ variables: { startupId, stakeholderId: id } });
      const stakeholder = st.data.getStakeholder;
      const role = roles[context.iterations - 1 || 0];
      const groupCompanies = get(context, 'groupCompanies') || get(values, 'groupCompanies');
      const rolesToAdd = [];

      const getRolesData = (roles, entity) => {
        const roleData = _.map(roles, (r) => {
          return {
            type: r
          }
        });
        return {
          entityId: entity?.id,
          entityType: entity?.__typename === 'GroupCompany' ? 'GROUP_COMPANY' : 'STAKEHOLDER' || 'GROUP_COMPANY',
          role: roleData || []
        }
      };

      if (isEmpty(context.getGroupCompaniesAndStakeholder.groupCompanies) || isEmpty(context.roles)) {
        const rolesData = {
          isFounder: context.isFounder,
        }
        const dataToPass = { ...stripTypenames({ stakeholderRolesData: rolesData, startupId, stakeholderId: stakeholder.id }) }
        return await upsertComponentRole(dataToPass);
      }

      if (context.getGroupCompaniesAndStakeholder.groupCompanies.length === 1) {
        const newData = {
          startupId,
          stakeholderId: id,
          stakeholderRolesData: {
            isFounder: stakeholder.isFounder ? stakeholder.isFounder : context.isFounder,
            roles: getRolesData(context.roles, context.getGroupCompaniesAndStakeholder.groupCompanies[0])
          }
        }
        return await upsertComponentRole({ ...stripTypenames(newData) })
      }
      if (!groupCompanies) return
      groupCompanies.forEach(gc => rolesToAdd.push({
        entityId: gc.id,
        entityType: "GROUP_COMPANY",
        role: [{ type: role }],
      }))
      const oldRoles = stakeholder.roles.map(r => ({
        entityId: r.entity.id,
        entityType: r.entity.__typename === 'GroupCompany' ? 'GROUP_COMPANY' : 'STAKEHOLDER' || 'GROUP_COMPANY',
        role: [{ type: r.name }],
      }))

      const newData = {
        startupId,
        stakeholderId: id,
        stakeholderRolesData: {
          isFounder: stakeholder.isFounder ? stakeholder.isFounder : context.isFounder,
          roles: uniq([...oldRoles, ...rolesToAdd])
        }
      }
      await upsertComponentRole({ ...stripTypenames(newData) })
    },

    addGoal: async (context, event) => {
      const groupCompanies = (context.groupCompanies || context.getGroupCompaniesAndStakeholder.groupCompanies).filter(gc => gc.kind === 'FORMATION')
      return Promise.all(groupCompanies.map(gc => mutation({
        groupCompanyId: gc.id,
        startupId,
        goal: $CODE_CAYMAN_GOAL,
      })))
    }

  }
}
export default useActions;

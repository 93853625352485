import React, {Component} from 'react';
import {helper} from "../../../utils";
import classesModule from './Text.module.scss';
import Parser from "html-react-parser";
import _ from "lodash";

/*
    Properties:
        uuid: key to get Text
        params: params to parse data
        lang: lang to use (not enabled now)
*/
class Text extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
            title: undefined
        }
    }
    handleClose=()=>{
        if (this.props.handleClose){
            this.props.handleClose();
        }
    }
    componentWillMount(){
        let {title} = this.props;

        this.setState({
            title: title
        })
    }
    componentWillUpdate(nextProps, nextState){
        
    }
    render() {
        const {uuid,params,classes} = this.props;
        let text = helper.getTranslateTextByKey(uuid,params);
        text=text?text:"";

        return (
            <React.Fragment>
                <span className={`${classesModule.spanClass} ${_.get(classes,"root",null)}`}>{Parser(text)}</span>
            </React.Fragment>
        );
    }
}

export default Text;

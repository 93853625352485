import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import { Questionnaire } from '../steps';

const useSteps = () => {
  return {
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    QuestionnaireStep: {
      render: Questionnaire
    },
    SaveStep: {
      render: TemplateWizardSkeletonMachine,
    },
  };
}

export default useSteps;

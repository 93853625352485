import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TextInput from 'src/components/inputs/Text/TextInput';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateLabel from 'src/modules/generic/components/Inputs/LabelInput';
import * as Yup from 'yup';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import classes from "./classes.module.scss";
import _ from 'lodash';

function AddConciergeCompany() {
  
  const { next, context, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.AddConciergeCompany ?? {}
  }, [context]);

  const handleCancel = () => {
    cancel();
  }

  const handlePrev = () => {
    prev();
  }

  const handleSubmit = async (values) => {
    next(values)
  }

  useEffect(() => {
    dispatch(
      addAssistantText(
        translate('MODULES.ONBOARDINGCOMPANY.STEP_CONCIERGE_HIT')
      )
    );
    dispatch(
      addAssistantText(
        translate('MODULES.ONBOARDINGCOMPANY.STEP_POINTER_D_HIT')
      )
    );
    return () => {
      dispatch(clearAssistatText())
    }
  }, [dispatch])

  const schema = Yup.object().shape({
    isHoldingCompany: Yup.boolean().required("Required"),
    name: Yup.string().nullable().required("Add the company name"),
  });

  const options = [
    {
      value: false,
      label: translate(`MODULES.ONBOARDINGCOMPANY.LABEL_ANOTHER_NO_NOTFORMED`),
    },
    {
      value: true,
      label: translate(`MODULES.ONBOARDINGCOMPANY.LABEL_ANOTHER_YES_NOTFORMED`),
    },
  ];

  const labelLines = [
    translate("MODULES.ONBOARDINGCOMPANY.LABEL_TOP_COMPANY_LINE1_NOTFORMED"),
    translate("MODULES.ONBOARDINGCOMPANY.LABEL_TOP_COMPANY_LINE2"),
  ];

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: "Tell us about your new company",
          subTitle: "Clara's Concierge",
          skeleton: null,
          variant: 'simple'
        }}
        buttons={{
          previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('GENERIC_BUTTON_ADD_GROUP')}</SubmitButton>,
        }}
      >
        <>
          <TemplateLabel
            isRequired={true}
            variant={"top"}
            multilineTexts={labelLines}
          >
            <ControllerInput
              render={CheckboxButtonSimple}
              className={classes.CheckboxButton}
              classNameButton={classes.buttonCheckBox2}
              name="isHoldingCompany"
              options={options}
              onChange={(value) => { }}
            />
          </TemplateLabel>
          <TemplateLabel
            label={translate("MODULES.ONBOARDINGCOMPANY.LABEL_NAME")}
            isRequired={true}
          >
            <ControllerInput
              render={TextInput}
              name="name"
              placeholder={"For example, Clara"}
              defaultlabel={""}
              rightLabel={_.get(context, "initialData.comesFromTSL", false) ? "Limited" : "Holdings Limited"}
            />
          </TemplateLabel>
        </>
      </TemplateWizard>
    </Form>
  );
}

export default AddConciergeCompany;


import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import Button from "src/modules/generic/components/Buttons/Button";
import { StepProps } from "src/modules/generic/components/Wizard/Step";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import {
  addAssistantText,
  clearAssistatText,
} from "src/modules/generic/store/action";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import FormGeneralDetailsADGM from "src/modules/startup/GroupCompany/Forms/FormGeneralDetailsADGM";

const SelectNewDataProtectionContact: FC<StepProps> = () => {
  const { next, send, context } = useMachine();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearAssistatText());
    if(context?.initialData?.groupCompany?.managedByClara)
    dispatch(
      addAssistantText(
        "<strong>Additional fees</strong> might apply if you need to update some of the details with the ADGM Registrar. <br><br>Click on the field to update the details and view the corresponding fees (if any). All changes made will be added to your cart on the Dashboard, where you can pay and submit the updates.",
        "alert"
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [context?.initialData]);

  const handleCompleteSubmit = async (values) => {
    next(values);
  };

  const handleCancel = () => {
    send("CANCEL");
  };

  return (
    <FormGeneralDetailsADGM
      context={context}
      onCompleteSubmit={handleCompleteSubmit}
      Template={TemplateWizard}
      propsTemplate={{
        title: "Edit general details",
      }}
      buttons={{
        cancel: (
          <Button onClick={handleCancel} variant="secondary">
            Cancel
          </Button>
        ),
      }}
    />
  );
};

export default SelectNewDataProtectionContact;

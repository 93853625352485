import React, { Component } from "react";
import ServerConnect from "../../utils/ServerConnect";
import { connect } from "react-redux";
import { Header, Loading } from "../index";
import loginClasses from "../../../LayoutLogin.module.scss";
import classes from "../../../LayoutLogin.module.scss";
// import classes from "../../containers/Login/Invite/inviteLogin.module.scss";
import { goToPage, goToPrevPage } from '../../../store/actions/page'
import { Constants } from '../../utils/constants';
import Cookies from 'universal-cookie';



class VerifyEmail extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {

    const {
      params
    } = this.props;
    const token = params.token;
    ServerConnect.requestApi("/user/signup", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token })
    }, null, { showNotification: false })
      .then(result => {
        this.props.goToPage(Constants.PAGES.login);
      })
      .catch(err => {
        console.log("ERROR", err);
        this.setState({ logged: false, error: err });
        alert(err);
      });
  }

  goTo = () => {
    this.props.goToPage(Constants.PAGES.login);
  };

  render() {
    return (
      <div className={`${loginClasses.page}`}>
        <div className={loginClasses.background}>
          <div
            className={`${loginClasses.color1} ${loginClasses.switchColor1}`}
          />
          <div
            className={`${loginClasses.color2} ${loginClasses.switchColor2}`}
          />
          <div
            className={`${loginClasses.triangle} ${loginClasses.switchTriangle}`}
          />
        </div>
        <Header
          imageIcon="../"
          headerTextLogin="Already have an account?"
          isSignUp={true}
          className={classes.HeaderGetStarted}
          goTo={this.goTo}
          type="login"
        />
        <div className={loginClasses.whiteZone}>
          <h2 style={{ textAlign: "center", marginBottom: "80px" }}>
            Verifying email, please wait...
          </h2>
          <Loading
            show={true}
            label={""}
            labelStyle={{
              right: "45%"
            }}
          />
        </div>
        <div className={`${loginClasses.textP}`}>
          Have a question?{" "}
          <a
            onClick={() =>
              window.open("https://www.clara.co/contact-us", "_blank")
            }
          >
            {" "}
            Contact Us{" "}
          </a>{" "}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    session: state.session
  };
};

const mapDispatchToProps = dispatch => ({
 
  goToPage: (page, params) => {
    goToPage(dispatch, page, params)
  },
  goToPrevPage: (reload, defaultPage, params) => {
    goToPrevPage(dispatch, reload, defaultPage, params)
  },
 
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyEmail);

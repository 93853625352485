import { createMachine } from "xstate";
const machine =
  /** @xstate-layout N4IgpgJg5mDOIC5QFVZgE4AUCuAjANgJYDGASmFIbAC7oCG1hA9gHYB02a6AYk+gLYBiAHIBRABoAVRKAAOTWIUasZIAB6IAjABYAHGwBMAVgBsukye1GjABgDsuzQYA0IAJ5bNATjZGvf3V1tL20TOxt-AF9I11QMHAISckoaemV2AGslXgFBTFJRADVVeUV01Q0EHX1jMwsrWwcnVw8EO01fLxMbG10AZh6m42jYrgSiMgoqWgZmTOy+ITEpEoUlOYrEPr6fTXCvO1s+0J7NFq0DAzYIgKCQsJuRkDisPAnk6bS5thowWT68gVikgQKV1ioQZVtrt9ocbMdujYzu5EHZtNcuj1+oNHMMniwmBA4KoXuMklNUrNWBwuDl+KsyhtIYhtC4UW0fDs7H0DLoutpNDy+iYnqS3uSUjN0mwstQ6QzwSxNggDALDKZdEYdF4Qto+v1zghHGw+dzefzBQZhaKxuLJpKvtTfv8FeVmQg9Vc9hFEQ4ukZdIagmwuTy+ZZLdaYs9bYl7Z8qUqQWC3aBKppA+yALQdbQ2PM2IzaVlWvMWaLRIA */
  createMachine({
    id: 'ShareCapital',
    initial: 'initialData',
    states: {
        initialData: {
            invoke: {
                src: 'initialData',
                id: 'initialData',
                onDone: [
                    {
                        target: 'ShareholdersAndDirectorsConfirmForm',
                        actions: ['setContextAfterInvoke']
                    }
                ],
            }
        },
            ShareholdersAndDirectorsConfirmForm:{
                on: {
                    NEXT: {
                      target: 'EvaluateIfShareholdersAndDirectorsConfirmForm',
                      actions: ['setContextAfterStep','setDefault']
                    },
                    CANCEL:{
                      target: 'finish'
                    }
                  }
          },
          EvaluateIfShareholdersAndDirectorsConfirmForm:{
            always: [
                {
                  cond: 'isConfirm',
                  target: 'AddArticlesOfAssosiationForm'
                },
                {
                  cond: 'isNotConfirm',
                  target: 'finish'
                },
                {
                  target: 'error'
                },
              ]  
          },
        AddArticlesOfAssosiationForm: {
        on: {
          NEXT: {
            target: 'EvaluateUploadOrCreate',
            actions: ['setContextAfterStep']
          },
          PREV: {
            target: 'ShareholdersAndDirectorsConfirmForm'
          },
          CANCEL:{
            target: 'finish'
          }
        }
      },
      EvaluateUploadOrCreate: {
        always: [
          {
            cond: 'isUpload',
            target: 'UploadArticlesOfAssociationForm'
          },
          {
            cond: 'isNotUpload',
            target: 'UseDefaultValuesForm'
          },
          {
            target: 'error'
          },
        ]
      },
      EvaluateIsUploadFlowOrCreateFlow:{
        always: [
            {
              cond: 'isUploadFlow',
              target: 'UploadArticlesOfAssociationForm'
            },
            {
              cond: 'isNotUploadFlow',
              target: 'AddArticlesOfAssosiationForm'
            },
            {
              target: 'error'
            },
          ]
      },
      UploadArticlesOfAssociationForm:{
        on: {
            NEXT: {
              target: 'UseDefaultValuesForm',
              actions: ['setContextAfterStep']
            },
            PREV: {
              target: 'AddArticlesOfAssosiationForm'
            },
            CANCEL:{
              target: 'finish'
            }
          }
      },
      UseDefaultValuesForm:{
        on: {
            NEXT: {
              target: 'EvaluateIsDefaultValues',
              actions: ['setContextAfterStep']
            },
            CANCEL:{
              target: 'finish'
            },
            PREV: {
              target: 'EvaluateIsUploadFlowOrCreateFlow'
            }
          }
      },
      EvaluateIsDefaultValues:{
        always: [
            {
              cond: 'isDefaultValues',
              target: 'SharesToStakeholdersForm'
            },
            {
              cond: 'isNotDefaultValues',
              target: 'CreateShareCapitalForm'
            },
            {
              target: 'error'
            },
          ]
      },
      EvaluateIsDefaultValuesFlowOrCreateFlow:{
        always: [
            {
              cond: 'isDefaultValuesFlow',
              target: 'UseDefaultValuesForm'
            },
            {
              cond: 'isNotDefaultValuesFlow',
              target: 'CreateShareCapitalForm'
            },
            {
              target: 'error'
            },
          ]
      },
      CreateShareCapitalForm:{
        on: {
            NEXT: {
              target: 'SharesToStakeholdersForm',
              actions: ['setContextAfterStep']
            },
            PREV: {
              target: 'UseDefaultValuesForm'
            },
            CANCEL:{
              target: 'finish'
            }
          }
      },
      SharesToStakeholdersForm:{
        on: {
            NEXT: {
              target: 'SaveStep',
              actions: ['setContextAfterStep'],
            },
            PREV: {
              target: 'EvaluateIsDefaultValuesFlowOrCreateFlow'
            },
            CANCEL:{
                target: 'finish'
            }
          }
      },
        SaveStep: {
            invoke: {
                src: 'SaveStep',
                id: 'SaveStep',
                onDone: [
                    {
                        target: 'finish'
                    }
                ],
                onError: [
                    {
                        target: 'finish'
                    }
                ]
            }
        },
      error: {
        on: {
          PREV: {
            target: 'EvaluateIfShareholdersAndDirectorsConfirmForm'
          }
        }
      },

      finish: {
        entry: 'stopMachine',
        type: 'final'
      }
    }
  });
export default machine;

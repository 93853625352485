import React, { useState } from "react";

import Popover from '@mui/material/Popover';
import { List, ListItem, ListItemText } from "@material-ui/core";
import classes from "./columnHeader.module.scss";
import { Text } from "../index";

const ColumnHeader = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selector, setSelector] = useState("");

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  async function handleClose() {
    await setAnchorEl(null);
  }

  const handlesort = (element) => {
    props.setOrder(props.field, element);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <div
        aria-describedby={id}
        className={classes.arrow}
        variant="contained"
        onClick={handleClick}
      >
        <span>
          <Text uuid={props.displayName} />
        </span>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        classes={{
          paper: classes.paper,
        }}
      >
        <List style={{ paddingBottom: "20px" }}>
          <ListItem className={classes.listTitle}>{props.list.title}</ListItem>
          {props.list.formOrder.map((element) => {
            return (
              <ListItem
                className={classes.listItem}
                key={element && element.key ? element.key : element}
                onClick={() => handlesort(element)}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={element && element.label ? element.label : element}
                />
              </ListItem>
            );
          })}
        </List>
      </Popover>
    </div>
  );
};
export default ColumnHeader;

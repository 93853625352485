import React, { FC, useState } from "react";
import Icon from "./../../../../../../modules/generic/components/Icon/Icon";
import Typography from "./../../../../../../modules/generic/components/Texts/Typography";
import classes from "./styles.module.scss";
interface Props {
  role: string,
  amount: number,
  onClick: () => void
}
const HeaderRole: FC<Props> = ({ role, amount, onClick }) => {

  const [isOpen, setIsOpen] = useState(false);

  const handleOnClick = () => {
    onClick()
    setIsOpen(!isOpen)
  }

  return (
    <div onClick={handleOnClick} className={classes.Header}>
      <Typography color={'dark-grey'} component={'h5'}>
        {amount ? `${role} (${amount})` : `${role}`}
      </Typography>
      <Icon icon={"Arrow-Up"} className={isOpen ? classes.IsOpen : classes.IsNotOpen} isClara={true} size={'1.5rem'} />
    </div>
  );
}
export default HeaderRole;
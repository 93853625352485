import { useMemo } from 'react';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import AddFormationCompanyInCaymanSubMachineStep from '../steps/AddFormationCompanyInCaymanSubMachineStep';
import AddFormationCompanyInDelawareSubMachineStep from '../steps/AddFormationCompanyInDelawareSubMachineStep';
import ChooseJurisdiction from '../steps/ChooseJurisdiction';
import ComingSoonJurisdiction from '../steps/ComingSoonJurisdiction';
import FormADGMType from '../steps/FormADGMType';
import AdgmTechStartupSubMachineStep from '../steps/AdgmTechStartupSubMachineStep';
import FormAdgnSpvTypeSubMachineStep from '../steps/FormAdgnSpvTypeSubMachineStep';

const useSteps = () => {

  return useMemo(() => ({
    ChooseJurisdiction_data: {
      render: TemplateWizardSkeletonMachine,
    },    
    ChooseJurisdiction: {
      render: ChooseJurisdiction,
    },
    FormADGMType:{
      render: FormADGMType,
    },
    GetProducts: {
      render: TemplateWizardSkeletonMachine,
    },
    SendMailCommingSoonJurisdiction:{
      render: TemplateWizardSkeletonMachine,
    },
    ComingSoonJurisdiction:{
      render: ComingSoonJurisdiction,
    },
    AddFormationCompanyInCaymanSubMachineStep: {
      render: AddFormationCompanyInCaymanSubMachineStep
    },    
    AdgmTechStartupSubMachineStep: {
      render: AdgmTechStartupSubMachineStep
    },
    AddFormationCompanyInDelawareSubMachineStep: {
      render: AddFormationCompanyInDelawareSubMachineStep
    },   
    FormAdgnSpvTypeSubMachineStep: {
      render: FormAdgnSpvTypeSubMachineStep
    },
  }), []);
}

export default useSteps;

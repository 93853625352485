import * as React from "react";
import * as Yup from 'yup';
import {
    validationsAdvisorAgreement,
    validationsCLNAgreement,
    validationsConsultantAgreement,
    validationsEmploymentAgreement,
    validationsEmploymentAgreementUK,
    validationsFoundersAgreement,
    validationsFoundersAgreement2,
	validationsFoundersAgreementRequestSignature,
    validationsFounderServicesAgreement,
    validationsEditFoundersAgreement,
    validationsFounderTerms,
    validationsGrantAgreement,
    validationSharesIncentivePlan,
    validationsIPAssignment,
    validationsKISSAgreement,
    validationsNDAMutual,
    validationsRestrictiveCovenantAgreement,
    validationsSAFEAgreement,
    validationsWebsiteAcceptableUsePolicy,
    validationsWebsiteCookieNotice,
    validationsWebsitePrivacyPolicy,
    validationsWebsiteTermsOfUse,
	validationsBRCAgreement,
    validationsBoardResolutionConsentSAFE,
    validationsUsaNDA,
    validationsEmployeeInformationInventionsAgreement,
    validationsConsultantInformationInventionsAgreement,
    validationsAdvisoryBoardOfLetter,
    validationsConsultingAgreement,
    validationsUsaCalOfferLetterSalaryEmployee,
    validationsEquityIncentivePlan,
    validationsBoardApprovingOptionsGrant,
    validationsOptionGrantNotice
} from "../../../../../../../v1/containers/Forms/generateDocumentForm/validations";

const useSchemaGenerate = (documentType, isRequestSignature) => {
    switch (documentType) {
        case "USA_OPTION_GRANT_NOTICE":
            return validationsOptionGrantNotice
        case "FOUNDERS_TERM_SHEET":
            return validationsFounderTerms;
        case "IP_ASSIGNMENT":
            return validationsIPAssignment;
        case "SAFE":
            return validationsSAFEAgreement;
        case "CONVERTIBLE_NOTE":
            return validationsCLNAgreement;
        case "ADVISOR_AGREEMENT":
            return validationsAdvisorAgreement;
        case "CONSULTANT_AGREEMENT":
            return validationsConsultantAgreement;
        case "EMPLOYMENT_AGREEMENT_ADGM":
        case "EMPLOYMENT_AGREEMENT_DIFC":
            return validationsEmploymentAgreement
		case "EMPLOYMENT_AGREEMENT_UK":
            return validationsEmploymentAgreementUK;
        case "GRANT_AGREEMENT":
            return validationsGrantAgreement
        case "SHARE_INCENTIVE_PLAN":
        case "BOARD_RESOLUTION":
        case "SHARE_INCENTIVE_PLAN_FAQ":
            return validationSharesIncentivePlan
        case "FOUNDER_SERVICES_AGREEMENT":
            return validationsFounderServicesAgreement
        case "RESTRICTIVE_COVENANT_AGREEMENT":
            return validationsRestrictiveCovenantAgreement
        case "KISS":
            return validationsKISSAgreement;
        case "NDA_MUTUAL":
            return validationsNDAMutual;
        case "FOUNDERS_AGREEMENT":
        	if (isRequestSignature) return validationsFoundersAgreementRequestSignature;
            return validationsEditFoundersAgreement;
        case "USA_BOARD_APPROVING_OPTION_GRANT":
            return validationsBoardApprovingOptionsGrant     
        case "WEBSITE_TERMS_OF_USE":
            return validationsWebsiteTermsOfUse;
        case "WEBSITE_PRIVACY_POLICY":
            return validationsWebsitePrivacyPolicy
        case "WEBSITE_ACCEPTABLE_USE_POLICY":
            return validationsWebsiteAcceptableUsePolicy
        case "WEBSITE_COOKIE_NOTICE":
            return validationsWebsiteCookieNotice;
		case "BOARD_RESOLUTION_CONVERTIBLE_LOAN_NOTE":
		case "BOARD_RESOLUTION_CONVERTIBLE_SAFE":
		case "BOARD_RESOLUTION_CONVERTIBLE_KISS":
		case "BOARD_RESOLUTION_CONVERTIBLE_FIXED_EQUITY":
		case "BOARD_RESOLUTION_CONVERTIBLE_OTHER":
			return validationsBRCAgreement;
        case "USA_STRATEGIC_ADVISORY_BOARD_LETTER":
            return validationsAdvisoryBoardOfLetter    
        case "USA_BOARD_CONSENT_SAFE_CONVERTIBLE_LOAN_NOTE":
        case "USA_BOARD_CONSENT_SAFE_SAFE":
        case "USA_BOARD_CONSENT_SAFE_KISS":
        case "USA_BOARD_CONSENT_SAFE_FIXED_EQUITY":
        case "USA_BOARD_CONSENT_SAFE_OTHER":
        case "USA_BOARD_CONSENT_SAFE":
            return validationsBoardResolutionConsentSAFE;
        case "USA_NDA":
            return validationsUsaNDA;
        case "USA_CAL_EMPLOYEE_INFORMATION_AND_INVENTIONS_AGREEMENT":
            return validationsEmployeeInformationInventionsAgreement
        case "USA_CONSULTANT_INFORMATION_AND_INVENTIONS_AGREEMENT":
            return validationsConsultantInformationInventionsAgreement    
        case "USA_CONSULTING_AGREEMENT":
            return validationsConsultingAgreement
        case "USA_EQUITY_INCENTIVE_PLAN":
        case "USA_STAKEHOLDERS_DIRECTORS_EQUITY_INCENTIVE_PLAN":
            return validationsEquityIncentivePlan
        //CP-5321
        case "USA_CAL_OFFER_LETTER_SALARY_EMPLOYEE":
            return validationsUsaCalOfferLetterSalaryEmployee  
        default:
            return Yup.object().shape({})
    }
}

export default useSchemaGenerate

export default {
  FIRST_STEP: {
    TITLE: "Does the proposed company name have a special meaning? ",
    SUBTITLE: "Company setup",
    TOOLTIP: "Choose 'Yes, it does' if your proposed name is not a commonly known English word, or if it's a shortened word, an abbreviation or an acronym.",
  },
  SECOND_STEP: {
    TITLE: "Do you want to provide an Arabic translation for this company name? ",
    SUBTITLE: "Company setup",
    TOOLTIP: "Clara recommends providing your own Arabic translation. Please ensure to translate in full, including the suffix e.g. ‘Holding Limited’. No translation available? The ADGM Registration Authority will use a transliterated version instead.",
  },
  THIRD_STEP: {
    TITLE: "Is the company affiliated with another of the same name?",
    SUBTITLE: "Company setup",
    TOOLTIP: "If the company is affiliated with another company of the same name, you’ll need to add their commercial licence (created at least two months ago). ",
  },
  FORTH_STEP: {
    TITLE: "Add commercial licence",
    SUBTITLE: "Company setup",
    TOOLTIP: "If the company is affiliated with another company of the same name, you’ll need to add their commercial licence (created at least two months ago). ",
  },
};

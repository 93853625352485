import { useMemo } from "react";
import TemplateWizardSkeletonMachine from "src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import { SelectGroupCompanies, SelectRoles } from "../Steps";

const useSteps = () => {
  const { translate } = useTranslate();
  return useMemo(() => ({
    getGroupCompaniesAndStakeholder: {
      render: TemplateWizardSkeletonMachine,
      renderProps: {
        title: translate('MODULES.EQUITY.ADD_EQUITY.TITLE'),
        subTitle: translate('MODULES.EQUITY.ADD_EQUITY.SUBTITLE'),
      },
      stepperPosition: 0
    },
    select: {
      render: SelectRoles,
      stepperPosition: 0
    },
    selectRolesForm: {
      render: SelectRoles,
      stepperPosition: 1
    },
    selectGroupCompanies: {
      render: SelectGroupCompanies,
      stepperPosition: 1
    }
  }), []);
}
export default useSteps;
import { useMemo } from 'react';

const useGuards = () => {
  return useMemo(() => ({

    isPositive: (context) => {
      return context?.SelectorForm?.accept;
    },

    isNegative: (context) => {
      return !context?.SelectorForm?.accept;
    },

  }), []);
}

export default useGuards;

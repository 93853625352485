import React, { FC, useEffect, useRef } from 'react';
import { useDispatch } from "react-redux";
import * as Yup from 'yup';
import Form from "../../../../../components/forms/Form/Form";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import TextareaInput from "../../../../../components/inputs/Textarea/TextareaInput";
import Button from "../../../../../modules/generic/components/Buttons/Button";
import Typography from "../../../../../modules/generic/components/Texts/Typography";
import FormProps from "../../../../../modules/generic/forms/interfaces/FormProps";
import { addAssistantText, clearAssistatText } from "../../../../../modules/generic/store/action";
import TemplateFormDefault from "../../../../../modules/generic/templates/Modal";
import CheckboxButtonSimple from "../../../../generic/components/Inputs/CheckboxButtonSimple";
import TemplateLabel from "../../../../generic/components/Inputs/LabelInput";
import ControllerInput from "../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import classes from './classes.module.scss';
import {HiddenFieldForm } from "../../../../../components/forms/contents";
import _ from 'lodash';



interface Props extends FormProps {
  initialValues: any,
  onCompleteSubmit: any,
  key: string,
  handlePrev: any,
  questionData: any,
}

const ResponseQuestion: FC<Props> = ({
  initialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
  handlePrev,
  questionData,
  key,
}) => {

  const refForm = useRef();
  const dispatch = useDispatch();

  const schemaQuestion = Yup.object().shape({
    response: Yup.boolean().nullable().required("Required"),
    explainComment: Yup.string().when(["response"], {
      is: questionData.defaultExplain,
      then: Yup.string().nullable().required("Required"),
      otherwise: Yup.string().optional(),
    }),
  });

  useEffect(() => {
    handeAddTooltips();
    return () => {
      dispatch(clearAssistatText());
    }
  }, [questionData.toolTip]);

  const handeAddTooltips = () => {
    dispatch(clearAssistatText());
    if (questionData.toolTips.length > 0) {
      _.map(questionData.toolTips, (tooltip) => {
        return dispatch(addAssistantText(tooltip.label));
      });
    }
  }

  const labelLines = [
    questionData.explaind,
  ];

  return (
    <>
      <Form
        key={key}
        defaultValues={initialValues || {}}
        schema={schemaQuestion}
        onSubmit={onCompleteSubmit}
        ref={refForm} optionsForm={{ mode: "onChange" }}
        onlySendDirty={false}
      >
        <Template 
          props={propsTemplate} 
          buttons={{
            ...buttons,
            submit: <SubmitButton {...buttonSubmitProps}>{questionData.isLast ? "Done" : "Next"}</SubmitButton>,
            previous: questionData.handlePrevious && <Button onClick={handlePrev}>Previous</Button>,
          }}
          >
          <Typography
            className={classes.questionFont}
          >
            {questionData.question}
          </Typography>
          <ControllerInput
            render={CheckboxButtonSimple}
            name='response'
            classNameButton={classes.buttonCheckBox}
            options={questionData.options}
            defaultValue={[]}
            onChange={(value) => {}}
            isMultiple={false}
          />
          <HiddenFieldForm
            fieldsWatch={"response"}
            conditionHideCallback={(value) => {
              if (value === questionData.defaultExplain) {
                return true;
              } else {
                return false;
              }
            }}
          >
            <TemplateLabel
              isColumn
              multilineTexts={labelLines}
              isRequired>
              <ControllerInput
                name={'explainComment'}
                render={TextareaInput}
                className={classes.TextArea}
                placeholder={questionData.explaindPlaceholder}
                defaultValue={''}
              />
            </TemplateLabel>
          </HiddenFieldForm>
        </Template>
      </Form>
    </>
  )
}

export default ResponseQuestion
import React, {Component} from 'react';
import ClaraUpload from "../../../../components/Form/ClaraUpload/ClaraUpload";
import classes from "../../SteperForm.module.scss";
import {Field} from "formik";

class StepToUploadEditedDoc extends Component {
    state = {};

    componentDidMount() { }

    render() {
        const isRequired = message => value => (!!value ? undefined : message);
        const { tooltip } = this.props;
        return (
            <React.Fragment>
            <div className={classes.editDocUploader}>
            <Field name={`file`}>
                {({ field, form }) => (
                    <ClaraUpload
                        required={true}
                        name={`filepond`}
                        label='Upload your document: '
                        field={field}
                        form={form}
                        validate={isRequired('field is required')}
                    />
                )}
            </Field>
            </div>
            </React.Fragment>
        )
    }
}

export default StepToUploadEditedDoc;

import React, {useRef, useState} from 'react';
import classes from './Comments.module.scss';
import _ from "lodash";
import ReactAvatar from 'react-avatar';
import {ButtonModalOptions} from '../index';
import moment from 'moment';

const Chat = ({ options, id, content, createdBy: author, index, createdAt, fontSize }) => {
    const [activeChat, setActiveChat] = useState('');
    const fullname = author.fullName ? author.fullName : [author.firstName, author.lastName].join(" ");

    const buttonRef = useRef(null)

    const handleOnClik = (e) => {
        if (_.isEmpty(options)) {
            e.preventDefault();
            e.stopPropagation();

        } else {

            const modal = buttonRef.current;
            modal.state.open ? modal.handleClose() : modal.handleClick(e);
            modal.state.open ? setActiveChat("") : setActiveChat("containerChatActive");

        }
    }


    return (
        <div className={`${classes.containerChat} ${classes[activeChat]}`} onClick={handleOnClik}>

            <div className={classes.avatar} >
                <ReactAvatar size={"42"} name={fullname} round={true} />

            </div>
            <div className={classes.headerChat}>
                <label>{fullname}</label>
                <span >{moment(createdAt).format('L')}</span>
                <div className={classes.buttonModal}>
                    <ButtonModalOptions params={{ id, content }} ref={buttonRef} title={null} options={options} />
                </div>
            </div>

            <span className={classes.description} dangerouslySetInnerHTML={{ __html: content.replace(/\n/g, "<br />") }} style={{ fontSize }} />
        </div>
    )

}

export default Chat;
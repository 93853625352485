import React, {FC} from "react";

import AssistanceFrame2 from "./";
import _ from "lodash";

export interface AssistanceAcceleratorsProps {}

const AssistanceAccelerators: FC<AssistanceAcceleratorsProps> = ({...props}: AssistanceAcceleratorsProps) => {
  return (
    <AssistanceFrame2 
      showTask={true}
      variant={"ACCELERATOR"} 
    />
  );
};

export default AssistanceAccelerators;

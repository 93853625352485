import {Field} from "formik";
import _ from "lodash";
import moment from "moment";
import React, {Component} from 'react';
import {ClaraInputDate, TextAreaInput, TextInput} from '../../../../../../../v1/components';
import ClaraCountrySelector from "../../../../../../../v1/components/Form/ClaraCountrySelector/ClaraCountrySelector";
import ClaraInputSelect from "../../../../../../../v1/components/Form/ClaraInputSelect/ClaraInputSelect";
import ClaraRadioWithButton from '../../../../../../../v1/components/Form/ClaraRadioWithButton/ClaraRadioWithButton';
import ClaraSelect from "../../../../../../../v1/components/Form/ClaraSelect/ClaraSelect";
import ClaraUpload from "../../../../../../../v1/components/Form/ClaraUpload/ClaraUpload";
import Checkbox from "../../../../../../../v1/containers/Forms/Inputs/Checkbox";
import {Constants} from "../../../../../../../v1/utils";
import FormikHelper from "../../../../../../../v1/utils/formikHelper";
import Line from "../../../../../../generic/Line/Line";
import AuthorisedSignatories from "../../../../../../inputs/AuthorizedSignatories/AuthorizedSignatories";
import CheckBox from '../../../../../../inputs/Checkbox/Checkbox';
import SelectStateCity from "../../../../../../inputs/Select/SelectStateCity";
import classes from "../Styles.module.scss";
import HiddenField from '../../../../../../../v1/components/Form/ClaraHiddenField/ClaraHiddenField';
import {
	groupCompanyRecipientNotice, stakeholderRecipientNotice
} from '../../../../../../../v1/containers/Forms/generateDocumentForm/FormsGenerate/helper';

class ConsultingAgreementDoc extends Component {

	constructor(props) {
		super(props);
		this.state = {
			typeSelected: undefined,
			consultantSelected: undefined,
			groupCompanySelected: undefined,
			authorizedSelected: undefined,
			authorizedSignatories: [],
			authorizedSignatoriesList: [],
			documentTypes: [],
			documentTypesList: [],
			dynamicParams: [],
			date: undefined,
			expirationDate: undefined,
			compensationMethod: null,
			firstSetCity: true,
			stateValue: null
		}
	};

	componentWillMount() {
		if (this.props.initialValues) {
			this.setState({
				compensationMethod: this.props.initialValues.terms.compensation.compensationMethod
			})
		}
		if (this.props.values) {
			const values = this.props.values.values ? this.props.values.values : this.props.values;
			const expirationDate = this.getDateForPickerFromData(values.expirationDate);
			this.setState({
				typeSelected: values.documentType,
				consultantSelected: values.assignor,
				groupCompanySelected: values.company,
				expirationDate,
				authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.company).map(as => ({
					id: as.id,
					label: as.label
				}))
			});


		}

	}

	componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps.values) {
			const values = nextProps.values.values ? nextProps.values.values : nextProps.values;
			const expirationDate = this.getDateForPickerFromData(values.expirationDate);
			this.setState({
				expirationDate,
				authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.company)
					.map(as => ({
						id: as.id,
						label: as.label
					}))
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.values) {
			const values = prevProps.values.values ? prevProps.values.values : prevProps.values;
		}
	}

	selectType = (documentType, extraParams, form) => {
		this.setState({
			typeSelected: documentType,
			dynamicParams: this.getDynamicParamsByType(documentType)
		}, () => {
			FormikHelper.setValueInTheCorrectPosition('terms', form, []);
			FormikHelper.setValueInTheCorrectPosition('documentType', form, documentType);
			this.state.dynamicParams.forEach((dynamicParam, i) => {
				FormikHelper.setValueInTheCorrectPosition(
					`terms[${i}].key`,
					form,
					dynamicParam.key || 'NO_KEY'
				);
			})
		});
	};

	getDynamicParamsByType(documentType) {
		const type = this.props.documentTypes.filter(agrType => agrType.code === documentType)[0] || {};
		return type.fields || [];
	}

	getGroupCompaniesToAssignee = () => {
		let authorizedSignatories = this.props.authorizedSignatories;
		let groupCompanies = this.props.groupCompanies;
		let companies = [];
		if (groupCompanies && authorizedSignatories) {
			companies = _.filter(groupCompanies, (groupCompany) => {
				let fined = _.filter(authorizedSignatories, (authorized) => {
					if (groupCompany.authorizedSignatories) {
						return groupCompany.authorizedSignatories.some(au => au.stakeholder.id === authorized.id);
					}
					return false;
				});
				if (fined) {
					return true;
				}
				return false;
			});
		}
		return companies;
	};

	selectGroupCompany = (gcId, extraParams, form) => {
		const groupCompany = this.findGroupCompany(gcId);

		// this is for a weird behaviour when hit edit button after preview a document
		if (this.state.groupCompanySelected && groupCompany) {
			return null;
		}

		this.setState({
			groupCompanySelected: groupCompany,
			firstSetCity: true,
			stateValue: null
		});

		if (groupCompany) {

			if (!groupCompany.registeredAddress) {
				_.set(groupCompany, "registerAddress", {})
			}

			let authorizedSignatories = _.get(form, 'values.company.authorizedSignatories', []);


			form.setValues({
				...form.values,
				company: {
					id: gcId,
					name: groupCompany.name || '',
					country: _.get(groupCompany, 'country.code') || _.get(groupCompany, 'country', null),
					placeOfBusiness: groupCompany.placeOfBusiness,
					registeredAddress: {
						...groupCompany.registeredAddress,
						country: _.get(groupCompany, 'registeredAddress.country.code', null) || _.get(groupCompany, 'registeredAddress.country', null)
					},
					recipientNotice: groupCompanyRecipientNotice(form.values.company, groupCompany)
				}
			});
			this.setState({
				groupCompanySelected: {...groupCompany},
			})
		} else {
			form.setValues({
				...form.values,
				company: {}
			});
		}
	};

	findGroupCompany(gcId) {
		const gc = _.find(this.props.groupCompaniesList, {id: gcId});
		return gc ? {...gc} : null
	};

	selectStakeholder = (assignor, extraParams, form, cb) => {

		const stakeholder = this.findStakeholder(assignor);
		this.setState({
			consultantSelected: stakeholder,
		});

		if (stakeholder) {

			if (!stakeholder.address) {
				_.set(stakeholder, 'address', {})
			}

			const _stakeholder = !_.isEmpty(form.values.consultant) ? form.values.consultant : stakeholder;
			let authorizedSignatory = {};

			if (stakeholder.isAnEntity) {
				authorizedSignatory = {
					fullName: typeof _stakeholder.authorizedSignatory === 'object'
						? _.get(_stakeholder, 'authorizedSignatory.fullName', '')
						: _.get(_stakeholder, 'authorizedSignatory', ''),
					email: _stakeholder.email || ''
				}
			}
			form.setValues({
				...form.values,
				consultant: {
					id: assignor,
					isAnEntity: stakeholder.isAnEntity,
					fullName: stakeholder.fullName || '',
					email: stakeholder.email || '',
					jurisdiction: _stakeholder.jurisdiction,
					nationality: _.get(stakeholder, 'nationality.code') || _.get(stakeholder, 'nationality', null),
					authorizedSignatory: authorizedSignatory,
					title: stakeholder.title || '',
					type: stakeholder.type || '',
					address: {
						...stakeholder.address,
						country: _.get(_stakeholder, 'address.country.code', null) || _.get(_stakeholder, 'address.country', null)
					},
					recipientNotice: stakeholderRecipientNotice(form.values.consultant, stakeholder)
				},

			});
		} else {
			form.setValues({
				...form.values,
				consultant: {}
			});
		}

	};

	findStakeholder(roleId) {
		return this.props.stakeholderList.find(stakeholder => stakeholder.id === roleId)
	};

	findStakeholderAuthorized(roleId) {
		return this.props.stakeholderList.filter(stakeholder => {
			return stakeholder.roles.some(role => role.stakeholder.id === roleId);
		})[0] || null;
	};

	filterAuthorizedByGroupCompany(groupCompany) {
		if (groupCompany) {
			let gc = this.findGroupCompany(groupCompany.id);
			return this.props.authorizedSignatories.filter(authorized => {
				return authorized.groupCompanyId === gc.id
			});
		}
		return [];
	}

	// selectAuthorized = (value, form, field, replace) => {
	//     // const stakeholder = this.findStakeholder(value);
	//     const stakeholder = this.props.authorizedSignatories.find(e => e.id === value);
	//     const email = stakeholder ? stakeholder.email : '';
	//     replace(0, { id: value, email })
	// };

	handleDateChange = (e, field, form) => {
		const date = moment(e).format("YYYY-MM-DD");

		FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

		this.setState({
			date: e
		})
	};


	handleCountryChange(value, form) {


		this.setState({
			countrySelected: value
		})

		if (value != 'US') {
			form.setValues({
				...form.values,
				company: {
					...form.values.company,
					country: value,
					placeOfBusiness: {
						...form.values.company.placeOfBusiness,
						state: 'Delaware',
						// city: null
					}
				}
			});
		}
	}

	updateEmailValue(e, form) {
		if (this.state.consultantSelected.isAnEntity) {
			form.setFieldValue('assignor.authorizedSignatory.email', e.target.value, false)
		}
	}

	getDateForPickerFromData(date) {
		if (!date) return undefined;
		return moment(date).toDate();
	}

	handleChange(e, field, form) {

		const date = e === null ? null : moment(e).format("YYYY-MM-DD");

		FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

		this.setState({
			[field.name]: e
		})
	}

	getAuthorizedSignatoriesList = (index, form) => {
		if (form.values && form.values.assignee) {
			return this.filterAuthorizedByGroupCompany(form.values.assignee).map(as => ({
				id: as.id,
				label: as.label
			}))
		} else {
			return [];
		}
	};

	renderConsultantDetails(tooltips) {
		if (this.state.consultantSelected.isAnEntity) {
			return (
				<React.Fragment>
					<div className={`${classes.Select}`}>
						<Field name='consultant.nationality'>
							{({field, form}) => (
								<ClaraCountrySelector
									isRequired={{country: true, jurisdiction: true, otherJurisdiction: true}}
									jurisdictionsEnabled={this.state.consultantSelected.isAnEntity}
									countryField={'consultant.nationality'}
									label={this.getLabel('country') + ''}
									jurisdictionField={'consultant.jurisdiction'}
									jurisdictionLabel={'Jurisdiction '}
									otherJurisdictionField={'consultant.otherJurisdiction'}
									setFieldValue={form.setFieldValue}
									initialValues={{
										jurisdiction: _.get(form.values, 'consultant.jurisdiction'),
										// country:_.get(form.values,'employee.nationality')
									}}
									returnCountryAsString={true}
									additionalStyle={'ClaraCountrySelectorModal'}
									tooltips={{
										country: tooltips[this.getTooltip('country')],
										jurisdiction: tooltips['Jurisdiction'],
										other: tooltips['Other Jurisdiction']
									}}
								/>
							)}
						</Field>
					</div>
					<div className={`${classes.Select}`}>
						<Field name='consultant.address.country'>
							{({field, form}) => {
								return (
									<ClaraCountrySelector
										isRequired={{country: true}}
										jurisdictionsEnabled={false}
										countryField={'consultant.address.country'}
										label={'Address '}
										returnCountryAsString={true}
										setFieldValue={form.setFieldValue}
										additionalStyle={'ClaraCountrySelectorModal'}
										tooltips={{
											country: tooltips["Other Party's Country"]
										}}
									/>
								)
							}}
						</Field>
					</div>

					<div className={classes.ContainerRow}>
						<Field name='consultant.address.street'>
							{({field, form}) => (
								<TextAreaInput
									type='text'
									label={' '}
									field={field}
									form={form}
									placeholder={"Address"}
									tooltip={tooltips["Other Party's Address"]}
								/>
							)}
						</Field>
					</div>

					<Field name='consultant.address.city'>
						{({field, form}) => (
							<TextInput
								type='text'
								label=' '
								field={field}
								form={form}
								classNameInput={{root: classes.ContainerRow}}
								placeholder={'City'}
								tooltip={tooltips["Other Party's City"]}
							/>
						)}
					</Field>
					<Field name='consultant.address.state'>
						{({field, form}) => (
							<TextInput
								type='text'
								label=' '
								classNameInput={{root: classes.ContainerRow}}
								field={field}
								form={form}
								placeholder={'State / Region'}
								tooltip={tooltips["Other Party's State"]}
							/>
						)}
					</Field>


					<Field name='consultant.address.zipCode'>
						{({field, form}) => (
							<TextInput
								type='text'
								label=' '
								classNameInput={{root: classes.ContainerRow}}
								field={field}
								form={form}
								placeholder={"Post code"}
								tooltip={tooltips["Other Party's Postal Code"]}
							/>
						)}
					</Field>


					<Field name='consultant.authorizedSignatory.fullName'>
						{({field, form}) => (
							<TextInput
								required={true}
								type='text'
								label='Corporate signatory '
								field={field}
								form={form}
								classNameInput={{root: classes.ContainerRow}}
								placeholder={"Corporate signatory"}
								tooltip={tooltips["Authorised Signatory"]}
							/>
						)}
					</Field>


					<Field name='consultant.authorizedSignatory.email'>
						{({field, form}) => (
							<TextInput
								required={true}
								type='text'
								label="Corporate signatory's email address "
								field={field}
								form={form}
								classNameInput={{root: classes.ContainerRow}}
								onChange={(e) => this.updateEmailValue(e, form)}
								placeholder={"Email address"}
								tooltip={tooltips['Authorised Signatory Email']}
							/>
						)}
					</Field>

					<div className={` ${classes.Select}`}>
						<Field name='consultant.recipientNotice.address.country'>
							{({field, form}) => (
								<ClaraCountrySelector
									isRequired={{country: true, jurisdiction: true, otherJurisdiction: true}}
									jurisdictionsEnabled={false}
									countryField={'consultant.recipientNotice.address.country'}
									label={"Corporate signatory's address"}
									returnCountryAsString={true}
									setFieldValue={form.setFieldValue}
									additionalStyle={'ClaraCountrySelectorModal'}
									tooltips={{
										country: tooltips["Company's Registered Address - Country"]
									}}
								/>
							)}
						</Field>
					</div>
					<div className={classes.ContainerRow}>
						<Field name='consultant.recipientNotice.address.street'>
							{({field, form}) => (
								<TextAreaInput
									type='text'
									label={' '}
									field={field}
									form={form}
									placeholder={"Address"}
									tooltip={tooltips["Company's Registered Address - Address"]}
								/>
							)}
						</Field>
					</div>

					<Field name='consultant.recipientNotice.address.city'>
						{({field, form}) => (
							<TextInput
								type='text'
								label=' '
								classNameInput={{root: classes.ContainerRow}}
								field={field}
								form={form}
								placeholder={'City'}
								tooltip={tooltips["Company's Registered Address - City"]}
							/>
						)}
					</Field>


					<Field name='consultant.recipientNotice.address.state'>
						{({field, form}) => (
							<TextInput
								type='text'
								label=' '
								classNameInput={{root: classes.ContainerRow}}
								field={field}
								form={form}
								placeholder={'State / Region'}
								tooltip={tooltips["Company's Registered Address - State"]}
							/>
						)}
					</Field>


					<Field name='consultant.recipientNotice.address.zipCode'>
						{({field, form}) => (
							<TextInput
								type='text'
								label=' '
								field={field}
								form={form}
								classNameInput={{root: classes.ContainerRow}}
								placeholder={"Post code"}
								tooltip={tooltips["Company's Registered Address - Post Code"]}
							/>
						)}
					</Field>

				</React.Fragment>
			)
		}
		return (<>

			<Field name='consultant.title'>
				{({field, form}) => (
					<TextInput
						required={true}
						type='text'
						label='Title'
						field={field}
						form={form}
						classNameInput={{root: classes.ContainerRow}}
						placeholder={"Title"}
						tooltip={tooltips["Consultant's Title"]}
					/>
				)}
			</Field>

			<Field name='consultant.email'>
				{({field, form}) => (
					<TextInput
						required={true}
						type='email'
						label='Email address'
						field={field}
						form={form}
						classNameInput={{root: classes.ContainerRow}}
						placeholder={"Email address"}
						tooltip={tooltips["Other Party's Email Address"]}
					/>
				)}
			</Field>
			<div className={` ${classes.Select}`}>
				<Field name='consultant.address.country'>
					{({field, form}) => {
						return (
							<ClaraCountrySelector
								isRequired={{country: true}}
								jurisdictionsEnabled={false}
								countryField={'consultant.address.country'}
								label={'Address '}
								returnCountryAsString={true}
								setFieldValue={form.setFieldValue}
								additionalStyle={'ClaraCountrySelectorModal'}
								tooltips={{
									country: tooltips["Other Party's Country"]
								}}
							/>
						)
					}}
				</Field>
			</div>

			<div className={classes.ContainerRow}>
				<Field name='consultant.address.street'>
					{({field, form}) => (
						<TextAreaInput
							type='text'
							label={' '}
							field={field}
							form={form}
							placeholder={"Address"}
							tooltip={tooltips["Other Party's Address"]}
						/>
					)}
				</Field>
			</div>

			<Field name='consultant.address.city'>
				{({field, form}) => (
					<TextInput
						type='text'
						label=' '
						field={field}
						form={form}
						classNameInput={{root: classes.ContainerRow}}
						placeholder={'City'}
						tooltip={tooltips["Other Party's City"]}
					/>
				)}
			</Field>


			<Field name='consultant.address.state'>
				{({field, form}) => (
					<TextInput
						type='text'
						label=' '
						classNameInput={{root: classes.ContainerRow}}
						field={field}
						form={form}
						placeholder={'State / Region'}
						tooltip={tooltips["Other Party's State"]}
					/>
				)}
			</Field>


			<Field name='consultant.address.zipCode'>
				{({field, form}) => (
					<TextInput
						type='text'
						label=' '
						field={field}
						form={form}
						classNameInput={{root: classes.ContainerRow}}
						placeholder={"Post code"}
						tooltip={tooltips["Other Party's Postal Code"]}
					/>
				)}
			</Field>

		</>)

	};

	getLabel = (key) => {
		switch (key) {
			case "fullName":
				if (this.state.consultantSelected.isAnEntity) {
					return "Company name";
				} else {
					return "Name";
				}
			case "country":
				if (this.state.consultantSelected.isAnEntity) {
					return "Country of incorporation";
				} else {
					return "Nationality";
				}
			case "address":
				if (this.state.consultantSelected.isAnEntity) {
					return "Registered address";
				} else {
					return "Residential address";
				}
			case "passportOrRegistered":
				if (this.state.consultantSelected.isAnEntity) {
					return "Company number";
				} else {
					return "Passport number";
				}
		}
	};

	getField = (key) => {
		switch (key) {
			case "passportOrRegistered":
				if (this.state.consultantSelected.isAnEntity) {
					return "assignor.registeredNumber";
				} else {
					return "assignor.passportNumber";
				}
		}
	};

	getTooltip = (key, fieldName) => {
		switch (key) {
			case "fullName":
				if (this.state.consultantSelected.isAnEntity) {
					return "Company Name";
				} else {
					return "IP Assignor's Name";
				}
			case "passportOrRegistered":
				if (this.state.consultantSelected.isAnEntity) {
					return "Company Number";
				} else {
					return "Passport Number";
				}
			case "country":
				if (this.state.consultantSelected.isAnEntity) {
					return "Country";
				} else {
					return "Nationality";
				}
			case "addressLine1":
				if (this.state.consultantSelected.isAnEntity) {
					return "Registered Address - Address Line 1";
				} else {
					return "Residential Address - Address Line 1";
				}
			case "addressCity":
				if (this.state.consultantSelected.isAnEntity) {
					return "Registered Address - City";
				} else {
					return "Residential Address - City";
				}
			case "addressState":
				if (this.state.consultantSelected.isAnEntity) {
					return "Registered Address - State";
				} else {
					return "Residential Address - State";
				}
			case "addressCountry":
				if (this.state.consultantSelected.isAnEntity) {
					return "Registered Address - Country";
				} else {
					return "Residential Address - Country";
				}
			case "addressPostCode":
				if (this.state.consultantSelected.isAnEntity) {
					return "Registered Address - Post Code";
				} else {
					return "Residential Address - Post Code";
				}
			case "recipientNotice":
				if (fieldName === 'assignee') {
					return "IP Assignee's";
				} else {
					return "IP Assignor's";
				}
		}
	};

	getPlaceholder = (key) => {
		switch (key) {
			case "passportOrRegistered":
				if (this.state.consultantSelected.isAnEntity) {
					return "Company number";
				} else {
					return "Passport number";
				}
		}
	};

	getStakeholderAdds = () => {
		return [
			{
				type: "stakeholder",
				label: "Add new corporate stakeholder",
				textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
				placeholder: "Stakeholder's name",
				startupId: this.props.startup.id,
				textToSave: "fullName",
				params: {
					isAnEntity: true,
				},
				paramsReturn: 'isAnEntity'
			},
			{
				type: "stakeholder",
				label: "Add new individual stakeholder",
				textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
				placeholder: "Stakeholder's name",
				startupId: this.props.startup.id,
				textToSave: "fullName",
				params: {
					isAnEntity: false,
				},
				paramsReturn: 'isAnEntity'
			},
		]
	};

	getCompanyAdds = () => {
		return [{
			type: "groupCompany",
			label: "Add new company",
			textWarning: "This will create a new group company for your startup. You can add or amend the details of this group company on its profile page.",
			placeholder: "Company name",
			startupId: this.props.startup.id,
			params: null,
			paramsReturn: `
				name
				authorizedSignatories {
					id
					stakeholder {
						id
						email
						fullName
						roles {   id
        name
        groupCompany { id name}
        stakeholder { id } }
					}
				}
			`
		}]
	};

	getAuthorizedSignatoryAdds = () => {
		return [
			{
				type: "stakeholder",
				label: "Add new individual stakeholder",
				textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
				placeholder: "Enter stakeholder's name",
				startupId: this.props.startup.id,
				textToSave: "fullName",
				params: {
					isAnEntity: false,
					roles: [{
						role: "DIRECTOR",
						groupCompany: this.state.groupCompanySelected.id,
					}]
				}
			},
		]
	};

	handleStakeholderCreate = (value, name, additionalValue, form) => {

		let {stakeholders, stakeholderList} = this.props;
		let newSh = {id: value.id, fullName: name, ...additionalValue};
		stakeholderList.push(newSh);

		stakeholders.push({id: value.id, label: name});

	};

	handleGroupCompanyCreate = (value, name, additionalValue, form) => {

		let {groupCompanies, groupCompaniesList} = this.props;

		let newGc = {id: value.id, name, ...additionalValue};
		groupCompaniesList.push(newGc);

		groupCompanies.push({id: value.id, label: name});

	};

	handleAuthorizedSignatoryCreate = (value, name, additionalValue, form) => {

		let {authorizedSignatories} = this.props;
		const {groupCompanySelected} = this.state;

		authorizedSignatories.push({
			id: value.id,
			label: name,
			role: 'DIRECTOR',
			groupCompanyId: groupCompanySelected.id
		});

	};

	handleAuthorizedSignatoryUpdate = (value) => {
		const {authorizedSignatories, groupCompanySelected} = this.state;
		const groupCompany = this.findGroupCompany(groupCompanySelected.id);
		_.set(groupCompany, 'authorizedSignatories', _.map(groupCompany.authorizedSignatories, auth => ({...auth})))
		groupCompany.authorizedSignatories.push({stakeholder: value});
		authorizedSignatories.push(value);
	};

	handleChangeState(value, form) {
		form.setValues({
			...form.values,
			company: {
				...form.values.company,
				placeOfBusiness: {
					...form.values.company.placeOfBusiness,
					state: value,
					city: null
				}
			}
		});
	}

	handleChangeCity(value, form) {
		form.setValues({
			...form.values,
			company: {
				...form.values.company,
				placeOfBusiness: {
					...form.values.company.placeOfBusiness,
					city: value
				}
			}
		});
	}

	fieldHasError(form, field) {
		return FormikHelper.returnErrorsForValidation(field.name, form.errors);
	}

	handleChange(a, b, c, d) {
		alert(a)

	}

	render() {
		const isRequired = message => value => (!!value ? undefined : message);
		const {tooltips = {}, formType} = this.props;

		return (
			<div>
				{formType === 'uploadToEdit' ? (
					<Field name={`file`}>
						{({field, form}) => (
							<ClaraUpload
								required={true}
								name={`filepond`}
								label='Upload your document '
								field={field}
								form={form}
							/>
						)}
					</Field>
				) : null}
				<Field name='agreementName'>
					{({field, form}) => (
						<TextInput
							required={true}
							type='text'
							classNameInput={{root: classes.ContainerRow}}
							label='Document name '
							field={field}
							form={form}
							placeholder={"Document name"}
							tooltip={tooltips['Document Name']}
						/>
					)}
				</Field>

				<Line className={classes.Line}/>
				<div className={classes.ZoneTitle}>
					<h1>{'Company'}</h1>
				</div>
				<div className={`${classes.ContainerRow} ${classes.Select}`}>
					<Field name='company.id'>
						{({field, form}) => (
							<ClaraSelect
								required={true}
								field={field}
								form={form}
								label={"Select company "}
								lists={this.getGroupCompaniesToAssignee()}
								mode={"classic"}
								callbacks={{
									createValues: this.handleGroupCompanyCreate,
									updateValues: this.selectGroupCompany
								}}
								placeholder={"Select from the list"}
								tooltip={tooltips['Select Company']}
							/>
						)}
					</Field>
				</div>
				{
					this.state.groupCompanySelected ?
						<React.Fragment>
							<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
								<h1>Company details</h1>
								<p>Please verify or update the company's information below</p>
							</div>
							<Field name='company.name'>
								{({field, form}) => (
									<TextInput
										classNameInput={{root: classes.ContainerRow}}
										required={true}
										type='text'
										label='Company name '
										field={field}
										form={form}
										placeholder={"Company name"}
										tooltip={tooltips["Company's Name"]}
									/>
								)}
							</Field>

							<div className={` ${classes.Select}`}>
								<Field name='company.country'>
									{({field, form}) => (
										<ClaraCountrySelector
											isRequired={{country: true, jurisdiction: false, otherJurisdiction: false}}
											jurisdictionsEnabled={false}
											countryField={'company.country'}
											label={'Country of incorporation '}
											returnCountryAsString={true}
											setFieldValue={form.setFieldValue}
											additionalStyle={'ClaraCountrySelectorModal'}
											tooltips={{
												country: tooltips["Country of incorporation"],
											}}
										/>
									)}
								</Field>
							</div>
							<Field name='company.placeOfBusiness_error'>
								{({field, form}) => (
									<React.Fragment>
                                            <span
												hasError={_.get(form.errors, "company.placeOfBusiness.city") || _.get(form.errors, "company.placeOfBusiness.state") ? "true" : "false"}>
                                                <SelectStateCity
													selectStateLabel={'State of principal office'}
													selectCityLabel={'City of principal office'}
													showState={true}
													modal={true}
													defaultState={form?.values?.company?.placeOfBusiness?.state}
													tooltips={{
														city: tooltips["Company's City Of Principal Office"],
														state: tooltips["Company's State Of Principal Office"]
													}}
													defaultCity={form?.values?.company?.placeOfBusiness?.city}
													onChangeState={(value) => {
														form.setFieldValue('company.placeOfBusiness.state', value)
													}}
													onChangeCity={(value) => {
														form.setFieldValue('company.placeOfBusiness.city', value)
													}}
													errorMessage={_.get(form.errors, "company.placeOfBusiness.city") || _.get(form.errors, "company.placeOfBusiness.state")}
												/>
                                            </span>
									</React.Fragment>
								)}
							</Field>
							<div className={` ${classes.Select}`}>
								<Field name='company.registeredAddress.country'>
									{({field, form}) => (
										<ClaraCountrySelector
											isRequired={{country: true, jurisdiction: true, otherJurisdiction: true}}
											jurisdictionsEnabled={false}
											countryField={'company.registeredAddress.country'}
											label={'Registered address '}
											returnCountryAsString={true}
											setFieldValue={form.setFieldValue}
											additionalStyle={'ClaraCountrySelectorModal'}
											tooltips={{
												country: tooltips["Company's Registered Address - Country"]
											}}
										/>
									)}
								</Field>
							</div>
							<div className={classes.ContainerRow}>
								<Field name='company.registeredAddress.street'>
									{({field, form}) => (
										<TextAreaInput
											type='text'
											label={' '}
											field={field}
											form={form}
											placeholder={"Address"}
											tooltip={tooltips["Company's Registered Address - Address"]}
										/>
									)}
								</Field>
							</div>
							<Field name='company.registeredAddress.city'>
								{({field, form}) => (
									<TextInput
										type='text'
										label=' '
										field={field}
										classNameInput={{root: classes.ContainerRow}}
										form={form}
										placeholder={'City'}
										tooltip={tooltips["Company's Registered Address - City"]}
									/>
								)}
							</Field>
							<Field name='company.registeredAddress.state'>
								{({field, form}) => (
									<TextInput
										type='text'
										label=' '
										field={field}
										form={form}
										placeholder={'State / Region'}
										classNameInput={{root: classes.ContainerRow}}
										tooltip={tooltips["Company's Registered Address - State"]}
									/>
								)}
							</Field>
							<Field name='company.registeredAddress.zipCode'>
								{({field, form}) => (
									<TextInput
										type='text'
										label=' '
										field={field}
										form={form}
										placeholder={"Post code"}
										classNameInput={{root: classes.ContainerRow}}
										tooltip={tooltips["Company's Registered Address - Post Code"]}
									/>
								)}
							</Field>
							<div className={`${classes.Select}`}>
								<Field name='company.recipientNotice.address.country'>
									{({field, form}) => (
										<ClaraCountrySelector
											isRequired={{country: true, jurisdiction: true, otherJurisdiction: true}}
											jurisdictionsEnabled={false}
											countryField={'company.recipientNotice.address.country'}
											label={"Recipient notice's registered address "}
											returnCountryAsString={true}
											setFieldValue={form.setFieldValue}
											additionalStyle={'ClaraCountrySelectorModal'}
											tooltips={{
												country: tooltips["Company's Registered Address - Country"]
											}}
										/>
									)}
								</Field>
							</div>
							<div className={classes.ContainerRow}>
								<Field name='company.recipientNotice.address.street'>
									{({field, form}) => (
										<TextAreaInput
											type='text'
											label={' '}
											field={field}
											form={form}
											placeholder={"Address"}
											tooltip={tooltips["Company's Registered Address - Address"]}
										/>
									)}
								</Field>
							</div>

							<Field name='company.recipientNotice.address.city'>
								{({field, form}) => (
									<TextInput
										type='text'
										label=' '
										field={field}
										classNameInput={{root: classes.ContainerRow}}
										form={form}
										placeholder={'City'}
										tooltip={tooltips["Company's Registered Address - City"]}
									/>
								)}
							</Field>


							<Field name='company.recipientNotice.address.state'>
								{({field, form}) => (
									<TextInput
										type='text'
										classNameInput={{root: classes.ContainerRow}}
										label=' '
										field={field}
										form={form}
										placeholder={'State / Region'}
										tooltip={tooltips["Company's Registered Address - State"]}
									/>
								)}
							</Field>


							<Field name='company.recipientNotice.address.zipCode'>
								{({field, form}) => (
									<TextInput
										type='text'
										label=' '
										field={field}
										form={form}
										classNameInput={{root: classes.ContainerRow}}
										placeholder={"Post code"}
										tooltip={tooltips["Company's Registered Address - Post Code"]}
									/>
								)}
							</Field>


							<Line className={classes.Line}/>
							<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
								<h1>Corporate Signatory</h1>
								<p>Please add the corporate signatory required to sign this document on behalf of the
									company.</p>
							</div>
							<Field name='company.authorizedSignatories'>
								{({field, form}) => (
									<React.Fragment>
                                        <span
											hasError={_.get(form.errors, "company.authorizedSignatories") ? "true" : "false"}>
                                            <AuthorisedSignatories
												showTitle={true}
												list={this.props.stakeholderList}
												limit={1}
												modal={true}
												fieldArrayName={'company.authorizedSignatories'}
												handleAddListStakeholder={this.handleAuthorizedSignatoryUpdate}
												idGroupCompany={this.state.groupCompanySelected.id}
												errorMessage={_.get(form.errors, "company.authorizedSignatories")}
											/>
                                        </span>
									</React.Fragment>
								)}


							</Field>
						</React.Fragment>
						:
						null
				}

				<Line className={classes.Line}/>
				<div className={classes.ZoneTitle}>
					<h1>{'Consultant'}</h1>
				</div>
				<React.Fragment>
					<Field name='consultant.id'>
						{({field, form}) => (
							<div className={`${classes.ContainerRow} ${classes.Select}`}>

								<ClaraSelect
									required={true}
									field={field}
									form={form}
									label={"Select consultant"}
									adds={this.getStakeholderAdds()}
									lists={this.props.stakeholders}
									mode={"classic"}
									callbacks={{
										updateValues: this.selectStakeholder,
										createValues: this.handleStakeholderCreate,
									}}
									placeholder={"Select from the list"}
									tooltip={tooltips["Other Party Select"]}
								/>
							</div>
						)}
					</Field>
					{this.state.consultantSelected && <>
						<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
							{this.state.consultantSelected.isAnEntity ?
								<>
									<h1>Consultant Company Details</h1>
									<p>Please verify or update the consultant's company's information below</p>
								</>
								:
								<>
									<h1>Consultant Details</h1>
									<p>Please verify or update the consultant’s information below</p>
								</>}
						</div>

						<Field name='consultant.fullName'>
							{({field, form}) => (
								<TextInput
									type='text'
									classNameInput={{root: classes.ContainerRow}}
									label={this.getLabel("fullName") + ' '}
									field={field}
									form={form}
									placeholder={'Name'}
									required={true}
									tooltip={tooltips[this.getTooltip('fullName')]}
								/>
							)}
						</Field>


						{this.renderConsultantDetails(tooltips)}            </>}
					{this.state.consultantSelected && this.state.groupCompanySelected &&
					<>


						<Line className={classes.Line}/>
						<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
							<h1>Services</h1>
							<p>The services the consultant will provide to the company</p>
						</div>
						<Field name={'terms.services'}>
							{({field, form}) => (
								<TextAreaInput
									type='text'
									trim={true}
									field={field}
									className={{
										root: classes.InputContainer,
										input: classes.TextInput
									}}
									form={form}
									label={'The consultant shall provide the following services '}
									tooltip={tooltips['List of Services']}
									placeholder={"List of services"}
								/>
							)}
						</Field>

						<Line className={classes.Line}/>
						<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
							<h1>Compensation</h1>
							<p>The fees the consultant will be paid as compensation for the services.</p>
						</div>
						<Field name='terms.compensation.compensationMethod'>
							{({field, form}) => (
								<div>
									<div className={`${classes.labelGroup}`}>
										<label>Compensation <span style={{color: 'red'}}>*</span></label>
									</div>
									<CheckBox
										label={' '}
										values={field.value}
										errorMessage={this.fieldHasError(form, field)}
										onChange={(value) => {
											console.log('aca estoy', value)
											form.setFieldValue(field.name, value)
											form.validateField(field.name)
											this.setState({
												compensationMethod: value
											})
										}}
										isMultiple={true}
										options={[
											{
												value: `fees`,
												label: 'Fees',
											},
											{
												value: `equity`,
												label: 'Equity',
											}
										]}
										className={{
											root: `${classes.groupCheckBoxWizard} ${classes.groupCheckboxContainer}`,
											checkbox: classes.checkBoxWizard
										}}
									/>
								</div>)}
						</Field>
						{this.state.compensationMethod ? <>
							{_.includes(this.state.compensationMethod, 'fees') &&
							<Field name='terms.compensation.compensationFrequency'>
								{({field, form}) => (
									<div style={{marginTop: '1rem'}}>
										<div className={`${classes.labelGroup} `}>
											<label>Fees frequency <span style={{color: 'red'}}>*</span></label>
										</div>
										<CheckBox
											label={' '}
											values={field.value}
											errorMessage={this.fieldHasError(form, field)}
											onChange={(value) => {
												form.setFieldValue(field.name, value);
												form.validateField(field.name)
											}}
											isMultiple={false}
											options={[
												{
													value: `week`,
													label: 'Weekly',
													renderElement: () => (<>
														<Field name='terms.compensation.amount'>
															{({field, form}) => (
																<div className={classes.InputContainer}>
																	<label style={{marginTop: '0.5rem'}}>Amount <span
																		style={{color: 'red'}}>*</span></label>
																	<TextInput
																		required={true}
																		type='number'
																		field={field}
																		form={form}
																		placeholder={'Amount'}
																	/>
																</div>)}
														</Field>
													</>)
												},
												{
													value: `month`,
													label: 'Monthly',
													renderElement: () => (<>
														<Field name='terms.compensation.amount'>
															{({field, form}) => (
																<div className={classes.InputContainer}>
																	<label style={{marginTop: '0.5rem'}}>Amount <span
																		style={{color: 'red'}}>*</span></label>
																	<TextInput
																		required={true}
																		type='number'
																		field={field}
																		form={form}
																		placeholder={'Amount'}
																	/>
																</div>)}
														</Field>
													</>)
												},
												{
													value: `year`,
													label: 'Yearly',
													renderElement: () => (<>
														<Field name='terms.compensation.amount'>
															{({field, form}) => (
																<div className={classes.InputContainer}>
																	<label style={{marginTop: '0.5rem'}}>Amount <span
																		style={{color: 'red'}}>*</span></label>
																	<TextInput
																		required={true}
																		type='number'
																		field={field}
																		form={form}
																		placeholder={'Amount'}
																	/>
																</div>)}
														</Field>
													</>)
												}
											]}
											className={{
												root: `${classes.groupCheckBoxWizard} ${classes.groupCheckboxContainer}`,
												checkbox: classes.checkBoxWizard
											}}
										/>
									</div>)}</Field>}
							{_.includes(this.state.compensationMethod, 'equity') && <>

								<Field name='terms.compensation.sipYear'>
									{({field, form}) => (
										<TextInput
											classNameInput={{root: classes.ContainerRow}}
											required={true}
											field={field}
											form={form}
											tooltip={tooltips["Company's Equity Incentive Plan Year"]}
											type={'date'}
											placeholder={'YYYY'}
											label={"Company's equity incentive plan year"}
											calendarInputProps={{
												type: 'year'
											}}
										/>
									)}
								</Field>


								<Field name='terms.compensation.percentage'>
									{({field, form}) => (
										<TextInput
											type='percentage'
											label='Option percentage'
											field={field}
											form={form}
											placeholder={'Option percentage'}
											required={true}
											tooltip={tooltips['Email Address']}
										/>
									)}
								</Field>
							</>}</> : null
						}


						<Line className={classes.Line}/>
						<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
							<h1>Previous inventions</h1>
							<p>Does the consultant have any previous inventions prior to the commencement of their
								emplyoment that they wish to exclude from this agreement?</p>
						</div>
						<Field name='terms.previousInventions.isTherePreviousInventions'>
							{({field, form}) => (
								<div className={classes.ContainerRow}>
									<ClaraRadioWithButton
										required={true}
										label={'Do you have previous inventions? '}
										field={field}
										form={form}
										// tooltip={tooltips['Cliff Period']}
										changeParams={field.name}
										options={[{value: true, label: 'Yes'}, {value: false, label: 'No'}]}
									/>
								</div>)}
						</Field>
						<HiddenField
							fieldName={'terms.previousInventions.description'}
							hiddenCondition={'values.terms.previousInventions.isTherePreviousInventions'}
							className={classes.ContainerRow}
						>
							<TextAreaInput
								type='text'
								trim={true}
								className={{
									root: classes.InputContainer,
									input: classes.TextInput
								}}
								tooltip={tooltips['Previous Inventions']}
								placeholder={"List of consultant's previous inventions"}
							/>
						</HiddenField>

{/*						<Field name='terms.previousInventions.isTherePreviousInventions'>
							{({field, form}) => (
								<div>
									<div className={` ${classes.labelGroup}`}>
										<label>Previous inventions <span style={{color: 'red'}}>*</span></label>
									</div>
									<CheckBox
										label={' '}
										values={field.value}
										errorMessage={this.fieldHasError(form, field)}
										onChange={(value) => {
											form.setFieldValue(field.name, value);
											form.validateField(field.name)
										}}
										isMultiple={false}
										options={[
											{
												value: 'no',
												label: 'No',
											},
											{
												value: 'yes',
												label: 'Yes',
												renderElement: () => (<>
													<Field name='terms.previousInventions.description'>
														{({field, form}) => (
															<TextAreaInput
																type='text'
																trim={true}
																field={field}
																className={{
																	root: classes.InputContainer,
																	input: classes.TextInput
																}}
																form={form}
																tooltip={tooltips['Previous Inventions']}
																placeholder={"List of consultant's previous inventions"}
															/>
														)}
													</Field>
												</>)
											}
										]}
										className={{
											root: `${classes.groupCheckBoxWizard} ${classes.groupCheckboxContainer}`,
											checkbox: classes.checkBoxWizard
										}}
									/>
								</div>)}
						</Field>*/}
					</>

					}
				</React.Fragment>
			</div>

		);
	}

}

export default ConsultingAgreementDoc;

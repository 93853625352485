import React from 'react'
import * as Yup from 'yup'
import Form from '../../../../components/forms/Form/Form'
import useTranslate from '../../../generic/hooks/useTranslate'
import ControllerInput from '../../../generic/components/Inputs/ReactHookForm/ControllerInput'
import TemplateLabel from "../../../generic/components/Inputs/LabelInput"
import SubmitButton from '../../../generic/components/Buttons/SubmitButton'
import FormProps from '../../../generic/forms/interfaces/FormProps';
import TemplateFormDefault from "../../../generic/templates/Modal";
import cls from './Form.module.scss';
import FormDevTools from '../../../devTools/Form';
import TextInput from "../../../../components/inputs/Text/TextInput";
import {Constants} from "../../../../v1/utils";


const schema = Yup.object().shape({
    linkedinURL: Yup.string().matches(Constants.REGEXP.URL_LINKEDIN,{message:"Please enter a valid LinkedIn URL",excludeEmptyString:true}).nullable().required('Required')
})

const StepCompanyInfo: React.FC<FormProps> = ({
                                                  initialValues = {},
                                                  onCompleteSubmit,
                                                  Template = TemplateFormDefault,
                                                  buttons = {},
                                                  buttonSubmitProps,
                                                  propsTemplate
                                              }: FormProps) => {

    const { translate } = useTranslate()
    const refForm = React.useRef(null);

    const handleSubmit = async values => {
        onCompleteSubmit && await onCompleteSubmit(values);
    }


    return (
        <Form
            defaultValues={initialValues}
            schema={schema}
            onSubmit={handleSubmit}
            ref={refForm} optionsForm = {{mode:"onChange"}}
        >
            <FormDevTools />
            <Template buttons={{ ...buttons, submit: <SubmitButton  {...buttonSubmitProps}>Add</SubmitButton> }} props={propsTemplate}>

                <TemplateLabel
                    label={translate('MODULES.STAKEHOLDER.FORM.LINKEDIN_URL.LABEL')}
                    className={cls.TemplateLabel}
                >
                    <ControllerInput
                        render={TextInput}
                        name='linkedinURL'
                        defaultlabel={''}
                        placeholder={translate('MODULES.STAKEHOLDER.FORM.LINKEDIN_URL.PLACEHOLDER')}
                    />
                </TemplateLabel>
            </Template>
        </Form>
    )
}

export default StepCompanyInfo

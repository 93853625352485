import PropTypes from "prop-types";
import React from "react";
import Divider from "@mui/material/Divider";
import DetailsTabTitle from "src/components/templates/DetailsTabTitle";
import { RoleAccess } from "src/v1/components";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import Button from "src/modules/generic/components/Buttons/Button";
import useModal from "src/modules/generic/hooks/useModal";
import { Constants } from "src/v1/utils/constants";
import TwoColumnsViewer from "src/modules/generic/components/TwoColumsViewer";
import EditDataProtectionDeclaration from "src/modules/startup/GroupCompany/ADGM/renewals/ReviewDataProtectionDeclaration";
import classes from "../../styles.module.scss";
import moment from "moment";

function DataProtectionPanel({ data, isLock }) {
  const { translate } = useTranslate();
  const { openModal, closeModal } = useModal();

  const buttonsManager = (
    <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
      <Button
        variant="card"
        disabled={isLock}
        children={translate("VIEW_COMPANY_TAB_BUTTON_DETAILS_EDIT")}
        onClick={() => {
          openModal(EditDataProtectionDeclaration, {
            handleCloseModal: closeModal,
            initialValues: { groupCompanyId: data?.id, isEdit: true },
          });
        }}
      />
    </RoleAccess>
  );

  return (
    <>
      <Divider className={classes.divider} />
      <DetailsTabTitle title="Data protection" buttons={buttonsManager} />
      <TwoColumnsViewer
        itemsCol1={[
          {
            label: "Data Protection Declaration",
            value: "Completed (Click Edit to view)",
          },
        ]}
        itemsCol2={[
          {
            label: "Data protection registration expiry date",
            value:
              data?.dataProtectionDeclaration?.registrationExpiryDate
                ? moment(data.dataProtectionDeclaration.registrationExpiryDate).format("D MMM YYYY")
                : "-",
          },
        ]}
      />
    </>
  );
}

DataProtectionPanel.propTypes = {
  data: PropTypes.any,
  isLock: PropTypes.bool,
};

export default DataProtectionPanel;

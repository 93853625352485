import React, {useEffect, useState} from 'react';
import {ClaraCheckForm, Text} from '../../../../components';
import _ from "lodash";
import PropTypes from 'prop-types';
import {Field} from "formik";
import classes from "./Shareholders.module.scss";
import FactoryChatForm from '../../../../components/Chat/FactoryChatForm';
import useMatterQuestionnaire from '../../../../Hooks/useMatterQuestionnaire';
import {Constants} from '../../../../utils'
import CompanyForm from '../../KYC/Company/CompanyForm';
import {modifyDataFileKYC, stripTypenames} from "../../../../utils/graphql-util";
import FormIndiviual from '../../KYC/Individual/FormIndividual';

const SelectorComponentShareholders = ({ name, showButtonAdd, initialValueStart, titleButtons, titleEntyties }) => {


    const [openModal, setOpenModal] = useState(false);
    const { matter } = useMatterQuestionnaire(initialValueStart.matterId)
    const [formSelect, setFormSelect] = useState("INDIVIDUAL_FORM");

    const [listStateholder, setListStateholder] = useState(null);
    const [reload, setReload] = useState(false);
    const handleClose = () => {
        setOpenModal(false);
        setReload(!reload);
    }
    const setFom = (form) => {
        setFormSelect(form);
        setOpenModal(true);
    }

    const mapDirectorAndShareholders = (elements) => {
        return _.concat(_.get(elements, "individuals", []), _.map(_.compact(_.get(elements, "companies", [])), com => ({ id: com.id, companyName: com.companyName, ...com.signatory })))
    }



    useEffect(() => {
        if (matter && !listStateholder) {

            let listStateholderLocal = [];
            _.forEach(_.get(matter, "individuals", []), (individual) => {
                listStateholderLocal.push({
                    type: "SHAREHOLDERS_INDIVIDUAL_FORM",
                    data: individual,
                })
            });
            _.forEach(_.get(matter, "companies", []), (company) => {
                if (company.isShareholder) {
                    listStateholderLocal.push({
                        type: "QUESTIONNAIRE_CORPORATE_SHAREHOLDERS_FORM",
                        data: { ...company, directors: mapDirectorAndShareholders(company.directors) }
                    })
                } else {
                    listStateholderLocal.push({
                        type: "QUESTIONNAIRE_COMPANY_FORM",
                        data: {
                            ...company,
                            directors: mapDirectorAndShareholders(company.directors),
                            shareholders: mapDirectorAndShareholders(company.shareholders)
                        }
                    })

                }
            });

            setListStateholder(listStateholderLocal);
        }
    })


    return (
        <React.Fragment>
            <div className={` ${classes.containerShareholder}`}>
                <div className={classes.contentField}>
                    <h4><Text uuid={titleEntyties} /></h4>
                    <Field name={name}>
                        {({ field, form }) => (
                            <ClaraCheckForm
                                reload={reload}
                                openForm={openModal}
                                addForm={formSelect}
                                cbClose={handleClose}
                                field={field}
                                multiForm
                                form={form}
                                list={listStateholder}
                                initialValues={{
                                    "SHAREHOLDERS_INDIVIDUAL_FORM":{ formKey: "SHAREHOLDERS_INDIVIDUAL_FORM" },
                                    "QUESTIONNAIRE_COMPANY_FORM":{ formKey: "QUESTIONNAIRE_COMPANY_FORM" },
                                    "QUESTIONNAIRE_CORPORATE_SHAREHOLDERS_FORM":{ formKey: "QUESTIONNAIRE_CORPORATE_SHAREHOLDERS_FORM" }

                                 }}
                                optionsList={{
                                    "SHAREHOLDERS_INDIVIDUAL_FORM": {
                                        fieldId: _.get(FormConfig, "SHAREHOLDERS_INDIVIDUAL_FORM" + ".id", "id"),
                                        fieldLabel: _.get(FormConfig, "SHAREHOLDERS_INDIVIDUAL_FORM" + ".fieldName", null),
                                    },
                                    "QUESTIONNAIRE_COMPANY_FORM": {
                                        fieldId: _.get(FormConfig, "QUESTIONNAIRE_COMPANY_FORM" + ".id", "id"),
                                        fieldLabel: _.get(FormConfig, "QUESTIONNAIRE_COMPANY_FORM" + ".fieldName", null),
                                    },
                                    "QUESTIONNAIRE_CORPORATE_SHAREHOLDERS_FORM": {
                                        fieldId: _.get(FormConfig, "QUESTIONNAIRE_CORPORATE_SHAREHOLDERS_FORM" + ".id", "id"),
                                        fieldLabel: _.get(FormConfig, "QUESTIONNAIRE_CORPORATE_SHAREHOLDERS_FORM" + ".fieldName", null),
                                    }
                                }}
                                optionsForm={{
                                    "SHAREHOLDERS_INDIVIDUAL_FORM": {
                                        titleKey: _.get(FormConfig, "SHAREHOLDERS_INDIVIDUAL_FORM.titleKey", null),
                                        educationId: _.get(FormConfig, "SHAREHOLDERS_INDIVIDUAL_FORM.leftEducationId", null),
                                        muttation: _.get(FormConfig, "SHAREHOLDERS_INDIVIDUAL_FORM.muttation", null),
                                        getIdFromMuttation: _.get(FormConfig, "SHAREHOLDERS_INDIVIDUAL_FORM.getIdFromMuttation", null),
                                        paramsMuttaion: initialValueStart,
                                        getVariablesMutation: _.get(FormConfig, "SHAREHOLDERS_INDIVIDUAL_FORM.getVariablesMutation", null),
                                        succesNotification: "NOTIFICATION_SUCCESS",
                                        errorNotification: "NOTIFICATION_BAD",
                                    },
                                    "QUESTIONNAIRE_CORPORATE_SHAREHOLDERS_FORM": {
                                        titleKey: _.get(FormConfig, "QUESTIONNAIRE_CORPORATE_SHAREHOLDERS_FORM.titleKey", null),
                                        educationId: _.get(FormConfig, "QUESTIONNAIRE_CORPORATE_SHAREHOLDERS_FORM.leftEducationId", null),
                                        muttation: _.get(FormConfig, "QUESTIONNAIRE_CORPORATE_SHAREHOLDERS_FORM.muttation", null),
                                        getIdFromMuttation: _.get(FormConfig, "QUESTIONNAIRE_CORPORATE_SHAREHOLDERS_FORM.getIdFromMuttation", null),
                                        paramsMuttaion: initialValueStart,
                                        getVariablesMutation: _.get(FormConfig, "QUESTIONNAIRE_CORPORATE_SHAREHOLDERS_FORM.getVariablesMutation", null),
                                        succesNotification: "NOTIFICATION_SUCCESS",
                                        errorNotification: "NOTIFICATION_BAD",
                                    },
                                    "QUESTIONNAIRE_COMPANY_FORM": {
                                        titleKey: _.get(FormConfig, "QUESTIONNAIRE_COMPANY_FORM.titleKey", null),
                                        educationId: _.get(FormConfig, "QUESTIONNAIRE_COMPANY_FORM.leftEducationId", null),
                                        muttation: _.get(FormConfig, "QUESTIONNAIRE_COMPANY_FORM.muttation", null),
                                        getIdFromMuttation: _.get(FormConfig, "QUESTIONNAIRE_COMPANY_FORM.getIdFromMuttation", null),
                                        paramsMuttaion: initialValueStart,
                                        getVariablesMutation: _.get(FormConfig, "QUESTIONNAIRE_COMPANY_FORM.getVariablesMutation", null),
                                        succesNotification: "NOTIFICATION_SUCCESS",
                                        errorNotification: "NOTIFICATION_BAD",
                                    }
                                }}
                                optionsSelect={{
                                    auto: false,
                                    canSelect: true
                                }}
                                optionsOperation={{
                                    canAdd: true,
                                    canEdit: true,
                                    canRemove: false,
                                }}
                                optionsView={{
                                    recordsByLine: 5
                                }}
                                className={{}}

                            >
                                <FactoryChatForm configForm={FormConfig} type={"SHAREHOLDERS_INDIVIDUAL_FORM"} propsForm={{ isFlow: false }} formKey={"SHAREHOLDERS_INDIVIDUAL_FORM"} ></FactoryChatForm>
                                <FactoryChatForm configForm={FormConfig} type={"QUESTIONNAIRE_CORPORATE_SHAREHOLDERS_FORM"} propsForm={{ isFlow: false }} formKey={"QUESTIONNAIRE_CORPORATE_SHAREHOLDERS_FORM"} ></FactoryChatForm>
                                
                                <FactoryChatForm configForm={FormConfig} type={"QUESTIONNAIRE_COMPANY_FORM"} propsForm={{ isFlow: false }} formKey={"QUESTIONNAIRE_COMPANY_FORM"} ></FactoryChatForm>

                            </ClaraCheckForm>
                        )}
                    </Field>
                </div>
                {showButtonAdd && <>
                    <h1><Text uuid={titleButtons} /></h1>
                    <hr />
                    <div className={classes.contentBotonera}>
                        <button className={`${classes.buttonWithAutomatic} ${classes.buttonOption} `} type="button" onClick={() => { setFom("QUESTIONNAIRE_CORPORATE_SHAREHOLDERS_FORM") }}>
                            <Text uuid={`QUESTIONARY_SHAREHOLDER_BUTTON_COMPANY_EDIT`} />
                        </button>
                        <button className={`${classes.buttonWithAutomatic} ${classes.buttonOption} `} type="button" onClick={() => { setFom("SHAREHOLDERS_INDIVIDUAL_FORM") }}>
                            <Text uuid={`QUESTIONARY_SHAREHOLDER_BUTTON_INDIVIDUAL_EDIT`} />
                        </button>

                    </div>
                </>}
            </div>
        </React.Fragment >
    );
}

SelectorComponentShareholders.propTypes = {
    responseKeyName: PropTypes.string.isRequired,
    initialValueStart: PropTypes.object.isRequired,

};
export default SelectorComponentShareholders;

const  FormConfig= {
    "QUESTIONNAIRE_COMPANY_FORM": {
      fieldName: "companyName",
      titleKey: "QUESTIONNAIRE_COMPANY_FORM_TITLE",
      muttation: `mutation upsertQuestionnaireCompany(
        $matterId: String!
        $KYCCompanyData: KYCCompanyInput! 
        $KYCCompanyId: String
      ) {
        upsertQuestionnaireCompany(matterId: $matterId, KYCCompanyData: $KYCCompanyData, KYCCompanyId: $KYCCompanyId) {
          id
          companyName
        }
      }
      `,
      leftEducationId: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.ADD.LEFT_EDUCATION_ID,
      leftEducationId_ADD: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.ADD.LEFT_EDUCATION_ID,
      leftEducationId_EDIT: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.EDIT.LEFT_EDUCATION_ID,
      getAvatarLabel: (obj) => {
        return _.get(obj, "companyName", "");
      },
      getForm: (props) => {
        return <CompanyForm {...props} isFlow={false} />
      },
      getVariablesMutation: (paramsMutattion, values) => {
        let KYCCompanyData = { ...values };
        delete KYCCompanyData.type;
        delete KYCCompanyData.formKey;
        delete KYCCompanyData.isShareholder
        delete KYCCompanyData.isDirector
        delete KYCCompanyData.isComplete
        delete KYCCompanyData.__typename;
        KYCCompanyData = modifyDataFileKYC(stripTypenames(KYCCompanyData));
        KYCCompanyData.signatory = modifyDataFileKYC(stripTypenames(KYCCompanyData.signatory));
        return {
          ...paramsMutattion,
          KYCCompanyData,
          KYCCompanyId: KYCCompanyData.id
        }
      },
      getIdFromMuttation: (result) => {
        return _.get(result, "upsertQuestionnaireCompany.id");
      }
    },
    "SHAREHOLDERS_INDIVIDUAL_FORM": {
        id: "id",
        fieldName: (data) => {
          return _.get(data, "firstName", "") + " " + _.get(data, "lastName", "");
        },
        muttation: `mutation upsertQuestionnaireIndividual(
            $matterId: String!
            $KYCIndividualData: KYCIndividualInput!
          ){
            upsertQuestionnaireIndividual(matterId: $matterId,
            KYCIndividualData: $KYCIndividualData){id
                type
                isPrimary
                firstName
                familyMemberOfAPEP
                familyMemberOfAPEPDescription
                closeAssociateOfAPEP
                closeAssociateOfAPEPDescription
                email
                thirdPartyAddressFormDoc{
                  file{
                    id
                    name
                    size
                    type
                  }
                }
                thirdPartyAddressPassportDoc{
                  file{
                    id
                    name
                    size
                    type
                  }
                }
                lastName
                dateOfBirth
                occupation
                address{address
                city
                country
                zipCode
                }proofOfAddress
                proofOfAddressDoc{
                  file{
                    id
                    name
                    size
                    type
                  }
                }
                addressLetterAndSupportingDoc{
                  file{
                    id
                    name
                    size
                    type
                  }
                }
                contactNumber{
                  prefix
                  number
                  extension
                }
                holdOrHaveHeldPassportFromAnotherCountry
                passportFromAnotherCountryDoc{
                  file{
                    id
                    name
                    size
                    type
                  }
                }
                politicallyExposedPerson
                politicallyExposedPersonDescription
                sourcesOfWealth
                otherSourcesOfWealth
                sourceOfFunds
                }
          }`,
        titleKey: "QUESTIONNAIRE_INDIVIDUAL_FORM_TITLE",
        leftEducationId: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_INDIVIDUAL_FORM.ADD.LEFT_EDUCATION_ID,
        getAvatarLabel: (obj) => {
          return _.get(obj, "firstName", "") + " " + _.get(obj, "lastName", "");
        },
        getForm: (props) => {
          return <FormIndiviual {...props} initialValues={{ ...props.initialValues, isCompanyFlow: false }} />
        },
        getVariablesMutation: (params, values) => {
          let KYCIndividualData = { ...values };
          delete KYCIndividualData.type;
          delete KYCIndividualData.formKey;
          delete KYCIndividualData.isShareholder
          delete KYCIndividualData.isDirector
          delete KYCIndividualData.isComplete
          delete KYCIndividualData.isCompanyFlow;
          KYCIndividualData = modifyDataFileKYC(stripTypenames(KYCIndividualData));
          const matterId = _.get(params, "matterId", params);
          return {
            matterId,
            KYCIndividualData,
            KYCIndividualId: KYCIndividualData.id
          }
        },
        getIdFromMuttation: (result) => {
          return _.get(result, "upsertQuestionnaireIndividual.id");
        }
      },
    "QUESTIONNAIRE_CORPORATE_SHAREHOLDERS_FORM": {
      fieldName: "companyName",
      titleKey: "QUESTIONNAIRE_COMPANY_FORM_TITLE",
      muttation: `mutation upsertQuestionnaireCompany(
        $matterId: String!
        $KYCCompanyData: KYCCompanyInput! 
        $KYCCompanyId: String
      ) {
        upsertQuestionnaireCompany(matterId: $matterId, KYCCompanyData: $KYCCompanyData, KYCCompanyId: $KYCCompanyId) {
          id
          companyName
        }
      }
      `,
      leftEducationId: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.ADD.LEFT_EDUCATION_ID,
      leftEducationId_ADD: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.ADD.LEFT_EDUCATION_ID,
      leftEducationId_EDIT: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.EDIT.LEFT_EDUCATION_ID,
      getAvatarLabel: (obj) => {
        return _.get(obj, "companyName", "");
      },
      getForm: (props) => {
        return <CompanyForm {...props} isFlow={true} />
      },
      getVariablesMutation: (paramsMutattion, values) => {
        let KYCCompanyData = { ...values };
        delete KYCCompanyData.type;
        delete KYCCompanyData.formKey;
        delete KYCCompanyData.isShareholder
        delete KYCCompanyData.isDirector
        delete KYCCompanyData.isComplete
        delete KYCCompanyData.__typename;
        KYCCompanyData = modifyDataFileKYC(stripTypenames(KYCCompanyData));
        KYCCompanyData.signatory = modifyDataFileKYC(stripTypenames(KYCCompanyData.signatory));
        return {
          ...paramsMutattion,
          KYCCompanyData,
          KYCCompanyId: KYCCompanyData.id
        }
      },
      getIdFromMuttation: (result) => {
        return _.get(result, "upsertQuestionnaireCompany.id");
      }
    }
  }
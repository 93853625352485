import _, { toInteger } from 'lodash';
import moment from "moment";
import React, { FC, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { StepProps } from "../../../../../../../../generic/components/Wizard/Step";
import useCustomMachine from "../../../../../../../../generic/hooks/useCustomMachine";
import useTranslate from "../../../../../../../../generic/hooks/useTranslate";
import { addAssistantText, clearAssistatText } from "../../../../../../../../generic/store/action";
import useSession from '../../../../../../../../session/hooks/useSession';
import useUpsertWarrant from "../../../../../../graphql/mutation/upsertWarrant";
import IsThereDocumentStep from "../../../../../../wizard/steps/IsThereDocumentsStep";
const IsThereDocumentWarrantStep:FC<StepProps>=({})=>{
    const {state}=useCustomMachine()
    const {upsertWarrant}=useUpsertWarrant({showSuccessNotification:false})
    const { startupId } = useSession();
    const dispatch=useDispatch()
    const {translate}=useTranslate()
    useEffect(()=>{
        dispatch(clearAssistatText())
        dispatch(addAssistantText(translate('MODULES.EQUITY.STEPS_WARRANT.IS_THERE_DOCUMENTS_STEP.TOOLTIP')))
    },[])
    const getCurrency=():string=>{
       if(_.isEmpty(_.get(state,'context.globalData',[]))) return  _.get(state,'context.data.select_companies.companies[0].transactionCurrency','USD')? _.get(state,'context.data.select_companies.companies[0].transactionCurrency','USD'):'USD'
            else return _.get(state,`context.globalData[0].select_companies.companies[${_.get(state,'context.numbersOfIteration',0)}].transactionCurrency`,'USD')?_.get(state,`context.globalData[0].select_companies.companies[${_.get(state,'context.numbersOfIteration',0)}].transactionCurrency`,'USD'):'USD'
    }
    const handleCompleteSubmit=async (values)=> {
        if (values.documents==='later') {
            const warrantData = {
                issuedDate:moment(_.get(state,'context.data.add_equity_form.issuedDate')).format('YYYY-MM-DD'),
               amount:toInteger(_.get(state,'context.data.add_equity_form.amount')),
                valuation:toInteger(_.get(state,'context.data.add_equity_form.valuation')),
                owner:_.get(state, 'context.paramsData'),
                currency:getCurrency(),
                groupCompany:_.isEmpty(_.get(state,'context.globalData',[]))?_.get(state,'context.data.select_companies.companies[0].id'):_.get(state,`context.globalData[0].select_companies.companies[${_.get(state,'context.numbersOfIteration',0)}].id`)
            }
            await upsertWarrant({startupId, owner: _.get(state, 'context.paramsData'), warrantData})
        }
    }
    return(<>
        <IsThereDocumentStep isTherePrevious={false} onCompleteSubmit={handleCompleteSubmit} type={'WARRANT'}/>
    </>)
}
export default IsThereDocumentWarrantStep

import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import ModalAssistant from '../../../generic/components/Modal/ModalAssistant';
import Wizard from '../../../generic/components/Wizard/v2';
import useModal from "../../../generic/hooks/useModal";
import { clearAssistatText } from "../../../generic/store/action";
import useActions from './hooks/useActions';
import useGuards from './hooks/useGuards';
import useServices from './hooks/useServices';
import useSteps from './hooks/useSteps';
import machine from './machine';
import {Constants} from "src/v1/utils";
import event from "src/v1/utils/event";

/**
 * Wizard to edit source of founds
 * addded with the new state machine 
 * @returns
 */
interface Props {
  open:boolean
  ownerId:string
}
const AddStakeholderModal:FC<Props> = ({
  open: openParam = false,
  ownerId
}) => {
  const [open, setOpen] = useState(openParam);
  const guards = useGuards()
  const steps = useSteps();
  const { closeModal: onClose } = useModal();
  const actions = useActions()
  const services = useServices()
  const dispatch = useDispatch();

  const handleStopMachine = () => {
    event.emmit(Constants.EVENTS.REFRESH_DASHBOARD, {});
    event.emmit(Constants.EVENTS.REFRESH_CARDS, {});
    onClose();
    setOpen(false);
    dispatch(clearAssistatText());
  }

  return (
    <ModalAssistant open={open}>
      <Wizard
        guards={guards}
        machine={machine}
        steps={steps}
        services={services}
        actions={actions}
        onStopMachine={handleStopMachine}
      />
    </ModalAssistant>
  );
}

export default AddStakeholderModal;

import React from 'react';

const CONSULTANT_AGREEMENT = {
    responseTime: {
        '24Hours': '24 hours',
        'twoBusinessDays': 'Two Business Days',
        'fiveBusinessDays': 'Five Business Days',
        'otherResponseTime': 'Other'
    },
    agreementDuration: {
        'perDay': 'Daily meetings',
        'perWeek': 'Weekly meetings',
        'perMonth': 'Monthly meetings',
        'perQuarter': 'Quarterly meetings',
        'other': 'Other'
    },
	feesFrequency: {
		'perWeek': 'Weekly',
		'perMonth': 'Monthly',
		'perInvoice':'Payment against invoices'
	}
};

const GRANT_AGREEMENT = {
	vestingFrequency: {
		'perQuarter': 'Quarterly',
		'perMonth': 'Monthly',
		'perAnnual': 'Annual'
	}
};

const ADVISOR_AGREEMENT = {
	commitmentFrequency: {
		'perWeek': 'Per week',
		'perMonth': 'Per month'
	},
	meetingAttendance: {
		'weekly': 'Weekly',
		'monthly': 'Monthly'
	},
	areasOfAdvise:{
		'option_a':'Business operations',
		'option_b':'Strategic planning',
		'option_c': 'Networking opportunities',
		'option_d': 'Business development',
		'option_e': 'Market environment',
		'option_f': 'Solutions to innovation challenges',
		'option_g':'Overall business performance',
	},
    otherServices: {
        "option_a": {
            "message": "Promoting the Company"
        },
        "option_b": {
            "message": "Introductions:"
        },
        "option_c": {
            "message": "Fundraising:"
        },
        "option_d": {
            "message": "Strategic projects:"
        },
        "option_e": {
            "message": "Other:"
        }
    },
	repurchasePriceOption: {
		'fairMarketValue': 'Fair Market Value',
		'parValue': 'Par Value'
	},
	advisorExpenses: {
		'companyReimburse': 'Company will reimburse expenses',
		'advisorResponsible': 'Advisor responsible for their own expenses'
	}
};

const FOUNDER_SERVICES_AGREEMENT = {
	servicesCommitment: {
		'commitmentFull': 'Devote the whole of their working time, attention and skill',
		'otherCommitment': 'Other'
	},
	serviceMinWorkHours: {
		'hours48': '48 Hours',
		'otherHours': ' Hours'
	},
	founderRole: {
		'COO': 'Chief Operating Officer (COO)',
		'CEO': 'Chief Executive Officer (CEO)',
		'CFO': 'Chief Financial Officer (CFO)',
		'CTO': 'Chief Technology Officer (CTO)',
		'CIO': 'Chief Information Officer (CIO)',
		'otherRole': 'Other'
	},
	responsibilitiesCOO: {
		'option_a': 'Responsibility for day to day business operations and product development',
		'option_b': 'Implementing the Company\'s business plan',
		'option_c': 'Managing the team',
		'option_d': 'Setting Company policies',
		'option_e': 'Measuring Company performance',
		'option_f': 'Internal communications',
		'option_g': 'Compliance',
		'option_z': 'Other',
	},
	responsibilitiesCEO: {
		'option_a': 'Leading the management team',
		'option_b': 'Setting strategy, direction and culture',
		'option_c': 'Developing the Company\'s business plan',
		'option_d': 'External communications',
		'option_e': 'Building the team (employees, consultants and advisors)',
		'option_f': 'Financing',
		'option_g': 'Financial decision-making',
		'option_h': 'Business development',
		'option_z': 'Other'
	},
	responsibilitiesCFO: {
		'option_a': 'Supervising the financial performance of the Company',
		'option_b': 'Managing the Company\'s capital, liquidity and major expenditure',
		'option_c': 'Financial reporting',
		'option_d': 'Financial planning and analysis',
		'option_z': 'Other'
	},
	responsibilitiesCTO: {
		'option_a': 'Leading the Company\'s technology team',
		'option_b': 'Responsibility for the Company\'s technology, technology research and development and the use of technology to develop products and services',
		'option_c': 'Setting and implementing the Company\'s technical strategy',
		'option_d': 'Responsibility for the oversight of the Company\'s technology-related intellectual property rights',
		'option_e': 'Oversight of the Company\'s computer systems, data, security, maintenance and network',
		'option_z': 'Other'
	},
	responsibilitiesCIO: {
		'option_a': 'Day to day responsibility for the Company\'s computer systems, data, security, maintenance and network',
		'option_b': 'Implementing the Company\'s technical strategy',
		'option_z': 'Other'
	},
	reportTo: {
		'CEO': 'Chief Executive Officer',
		'directors': 'Board of Directors'
	}
};
const NDA_MUTUAL={
	transaction:{
		'option_a': 'The possible investment in the Discloser by the Recipient (or one of its Group Companies)',
		'option_b': 'The possible acquisition of the issued share capital of the Discloser to the Recipient (or one of its Group Companies)',
		'option_c': 'the possible joint venture between the Discloser and the Recipient (or their Group Companies)',
		'option_z': 'Other'
	}
}
const WEBSITE_COOKIE_NOTICE={
	cookies:{
		'option_a': 'Until deleted by user',
		'option_b': 'End of browser session',
	},
	functional:{
		'option_a': '30 days',
		'option_b': 'Until deleted by user',
		'option_c':'End of browser session'
	},
	thirdPartyPerformance:{
		'option_a': '2 years',
		'option_b': '1 minute',
		'option_c':'24 hours',
		'option_d':'Until deleted by user'
	}
}
const SAFE = {
	maturityDateType: {
		'months': 'Months from Agreement date',
		'specificDate': 'A specific date'
	}
};

const EMPLOYMENT_AGREEMENT = {
	variationsToAgreement: {
		'noticeEmployerToEmployee': 'Notice from the Employer to the Employee',
		'agreementBetweenParties': 'Agreement between the Parties in writing'
	},
	medicalCare: {
		'family': 'Family',
		'dependents': 'Dependents',
		"true": "Yes",
		"false": "No"
	}
};

const USA_NDA={
	transaction:{
		// 'option_a': 'The possible investment in the Discloser by the Recipient (or one of its Group Companies)',
		// 'option_b': 'The possible acquisition of the issued share capital of the Discloser to the Recipient (or one of its Group Companies)',
		// 'option_c': 'the possible joint venture between the Discloser and the Recipient (or their Group Companies)',
		'option_z': 'Other'
	}
}
//CP-5321
const USA_CAL_OFFER_LETTER_SALARY_EMPLOYEE={
	transaction:{
		// 'option_a': 'The possible investment in the Discloser by the Recipient (or one of its Group Companies)',
		// 'option_b': 'The possible acquisition of the issued share capital of the Discloser to the Recipient (or one of its Group Companies)',
		// 'option_c': 'the possible joint venture between the Discloser and the Recipient (or their Group Companies)',
		'option_z': 'Other'
	}
}

export default {
	WEBSITE_COOKIE_NOTICE,
	NDA_MUTUAL,
	CONSULTANT_AGREEMENT,
	GRANT_AGREEMENT,
	ADVISOR_AGREEMENT,
	FOUNDER_SERVICES_AGREEMENT,
	SAFE,
	EMPLOYMENT_AGREEMENT,
	USA_NDA,
	USA_CAL_OFFER_LETTER_SALARY_EMPLOYEE
}

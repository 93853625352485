import React from 'react'
import useCategories from '../../hooks/common/useCategories'

const GenericHOCHook = ({withCategories = {enabled: false, kind: "STARTUP", avoidEmptyDocs : false}, children}) => {


   const {categories, loading} = useCategories(withCategories.kind, withCategories.avoidEmptyDocs)

    if (!loading) {
        return <React.Fragment>{React.cloneElement(children, {categories})}</React.Fragment>
    } else {
        return <React.Fragment></React.Fragment>
    }

}

export default GenericHOCHook
import * as React from "react";
import _ from "lodash";
import useGetPartiesByType from './useGetPartiesByType'

const useGetDataByDocumentType = (values, startup,groupCompanies) => {
	const getPartiesType = useGetPartiesByType()
	const params = values.file ? _.omit(values, ['file']) : values;
	const groupCompany = getPartiesType(values.documentType)?.partyGroupCompany?.party
		//FIX: CP 9648 
		if(values[groupCompany]){
			const gc = groupCompanies.find(gc=>gc.id===values[groupCompany].id)
			 _.set(values[groupCompany],'type',gc?.managedByClara?gc?.type:values[groupCompany].type)
		}
	let stakeholderOtherJurisdiction;
	let companyOtherJurisdiction;

	switch (values.documentType) {
		case "FOUNDERS_TERM_SHEET":
			return {
				documentType: values.documentType,
				agreementName: values.agreementName,
				expirationDate: values.expirationDate,
				params: values.file ? _.omit(values, ['file']) : values
			};
		case "IP_ASSIGNMENT":
			stakeholderOtherJurisdiction = values.assignor.otherJurisdiction;
			delete values.assignor.otherJurisdiction;

			companyOtherJurisdiction = values.assignee.otherJurisdiction;
			delete values.assignee.otherJurisdiction;

			return {
				startupId: startup,
				documentType: values.documentType,
				agreementName: values.agreementName,
				params: {
					...params,
					assignor: {
						...values.assignor,
						email: values.assignor.isAnEntity
							? values.assignor.authorizedSignatory.email
							: values.assignor.email,
						nationality: !_.isEmpty(values.assignor.nationality.code)
							? values.assignor.nationality.code
							: values.assignor.nationality,
						jurisdiction: values.assignor.jurisdiction === "OTHER"
							? stakeholderOtherJurisdiction
							: values.assignor.jurisdiction,
						address: {
							...values.assignor.address,
							country: !_.isEmpty(values.assignor.address.country.code)
								? values.assignor.address.country.code
								: values.assignor.address.country
						},
						recipientNotice: values.assignor.recipientNotice ? {
							...values.assignor.recipientNotice,
							address: {
								...values.assignor.recipientNotice.address,
								country: !_.isEmpty(_.get(values, 'assignor.recipientNotice.address.country.code', ''))
									? values.assignor.recipientNotice.address.country.code
									: values.assignor.recipientNotice.address.country
							}
						} : null
					},
					assignee: {
						...values.assignee,
						authorizedSignatories: values.assignee.authorizedSignatories
							.map(as => ({id: as.id, email: as.email, fullName: as.fullName})),
						country: !_.isEmpty(values.assignee.country.code)
							? values.assignee.country.code
							: values.assignee.country,
						jurisdiction: values.assignee.jurisdiction === "OTHER"
							? companyOtherJurisdiction
							: values.assignee.jurisdiction,
						registeredAddress: {
							...values.assignee.registeredAddress,
							country: !_.isEmpty(values.assignee.registeredAddress.country.code)
								? values.assignee.registeredAddress.country.code
								: values.assignee.registeredAddress.country
						},
						recipientNotice: values.assignee.recipientNotice ? {
							...values.assignee.recipientNotice,
							address: {
								...values.assignee.recipientNotice.address,
								country: !_.isEmpty(values.assignee.recipientNotice.address.country.code)
									? values.assignee.recipientNotice.address.country.code
									: values.assignee.recipientNotice.address.country
							}
						} : null
					}
				}
			};
		case "SAFE":
		case "CONVERTIBLE_NOTE":
			stakeholderOtherJurisdiction = values.investor.otherJurisdiction;
			delete values.investor.otherJurisdiction;

			companyOtherJurisdiction = values.company.otherJurisdiction;
			delete values.company.otherJurisdiction;

			return {
				startupId: startup,
				documentType: values.documentType,
				agreementName: values.agreementName,
				params: {
					...params,
					investor: {
						...values.investor,
						nationality: !_.isEmpty(values.investor.nationality.code)
							? values.investor.nationality.code
							: values.investor.nationality,
						jurisdiction: values.investor.jurisdiction === "OTHER"
							? stakeholderOtherJurisdiction
							: values.investor.jurisdiction,
						address: {
							...values.investor.address,
							country: !_.isEmpty(values.investor.address.country.code)
								? values.investor.address.country.code
								: values.investor.address.country
						},
						recipientNotice: values.investor.recipientNotice ? {
							...values.investor.recipientNotice,
							address: {
								...values.investor.recipientNotice.address,
								country: !_.isEmpty(_.get(values, 'investor.recipientNotice.address.country.code', ''))
									? values.investor.recipientNotice.address.country.code
									: _.get(values, 'investor.recipientNotice.address.country', '')
							}
						} : null
					},
					company: {
						...values.company,
						authorizedSignatories: values.company.authorizedSignatories
							.map(as => ({id: as.id, email: as.email, fullName: as.fullName})),
						country: !_.isEmpty(values.company.country.code)
							? values.company.country.code
							: values.company.country,
						jurisdiction: values.company.jurisdiction === "OTHER"
							? companyOtherJurisdiction
							: values.company.jurisdiction,
						registeredAddress: {
							...values.company.registeredAddress,
							country: !_.isEmpty(values.company.registeredAddress.country.code)
								? values.company.registeredAddress.country.code
								: values.company.registeredAddress.country
						},
						recipientNotice: {
							...values.company.recipientNotice,
							address: {
								...values.company.recipientNotice.address,
								country: !_.isEmpty(values.company.recipientNotice.address.country.code)
									? values.company.recipientNotice.address.country.code
									: values.company.recipientNotice.address.country
							}
						}
					},
					// terms: {
					// 	...values.terms,
					// bankInfo: {
					// 	...values.terms.bankInfo,
					// 	id:values.terms.bankInfo.id || data.id
					// }
					// }
				}
			};
		case "ADVISOR_AGREEMENT":
			stakeholderOtherJurisdiction = values.advisor.otherJurisdiction;
			delete values.advisor.otherJurisdiction;

			companyOtherJurisdiction = values.company.otherJurisdiction;
			delete values.company.otherJurisdiction;

			return {
				startupId: startup,
				documentType: values.documentType,
				agreementName: values.agreementName,
				params: {
					...params,
					advisor: {
						...values.advisor,
						nationality: !_.isEmpty(values.advisor.nationality.code)
							? values.advisor.nationality.code
							: values.advisor.nationality,
						jurisdiction: values.advisor.jurisdiction === "OTHER"
							? stakeholderOtherJurisdiction
							: values.advisor.jurisdiction,
						address: {
							...values.advisor.address,
							country: !_.isEmpty(values.advisor.address.country.code)
								? values.advisor.address.country.code
								: values.advisor.address.country
						},
						recipientNotice: values.advisor.recipientNotice ? {
							...values.advisor.recipientNotice,
							address: {
								...values.advisor.recipientNotice.address,
								country: !_.isEmpty(_.get(values, 'advisor.recipientNotice.address.country.code', ''))
									? values.advisor.recipientNotice.address.country.code
									: _.get(values, 'advisor.recipientNotice.address.country', '')
							}
						} : null
					},
					company: {
						...values.company,
						authorizedSignatories: values.company.authorizedSignatories
							.map(as => ({id: as.id, email: as.email, fullName: as.fullName})),
						country: !_.isEmpty(values.company.country.code)
							? values.company.country.code
							: values.company.country,
						jurisdiction: values.company.jurisdiction === "OTHER"
							? companyOtherJurisdiction
							: values.company.jurisdiction,

					}
				}
			};
		case "CONSULTANT_AGREEMENT":
			stakeholderOtherJurisdiction = values.consultant.otherJurisdiction;
			delete values.consultant.otherJurisdiction;

			companyOtherJurisdiction = values.company.otherJurisdiction;
			delete values.company.otherJurisdiction;

			return {
				startupId: startup,
				documentType: values.documentType,
				agreementName: values.agreementName,
				params: {
					...params,
					consultant: {
						...values.consultant,
						nationality: !_.isEmpty(values.consultant.nationality.code)
							? values.consultant.nationality.code
							: values.consultant.nationality,
						nationalityName: !_.isEmpty(values.consultant.nationality.name)
							? values.consultant.nationality.name
							: values.consultant.nationality,
						jurisdiction: values.consultant.jurisdiction === "OTHER"
							? stakeholderOtherJurisdiction
							: values.consultant.jurisdiction,
						address: {
							...values.consultant.address,
							country: !_.isEmpty(values.consultant.address.country.code)
								? values.consultant.address.country.code
								: values.consultant.address.country
						},
						recipientNotice: values.consultant.recipientNotice ? {
							...values.consultant.recipientNotice,
							address: {
								...values.consultant.recipientNotice.address,
								country: !_.isEmpty(_.get(values, 'consultant.recipientNotice.address.country.code', ''))
									? values.consultant.recipientNotice.address.country.code
									: _.get(values, 'consultant.recipientNotice.address.country', '')
							}
						} : null
					},
					company: {
						...values.company,
						authorizedSignatories: values.company.authorizedSignatories
							.map(as => ({id: as.id, email: as.email, fullName: as.fullName})),
						country: !_.isEmpty(values.company.country.code)
							? values.company.country.code
							: values.company.country,
						jurisdiction: values.company.jurisdiction === "OTHER"
							? companyOtherJurisdiction
							: values.company.jurisdiction,
						registeredAddress: {
							...values.company.registeredAddress,
							country: !_.isEmpty(values.company.registeredAddress.country.code)
								? values.company.registeredAddress.country.code
								: values.company.registeredAddress.country
						},
						recipientNotice: {
							...values.company.recipientNotice,
							address: {
								...values.company.recipientNotice.address,
								country: !_.isEmpty(values.company.recipientNotice.address.country.code)
									? values.company.recipientNotice.address.country.code
									: values.company.recipientNotice.address.country
							}
						}
					}
				}
			};
		case "FOUNDER_SERVICES_AGREEMENT":
			stakeholderOtherJurisdiction = values.founder.otherJurisdiction;
			delete values.founder.otherJurisdiction;

			companyOtherJurisdiction = values.company.otherJurisdiction;
			delete values.company.otherJurisdiction;

			const otherResponsibility = values.founder.position.otherResponsibility;
			delete values.founder.position.otherResponsibility

			const otherResponsibilitiesCode = values.founder.position.otherResponsibilitiesCode;
			delete values.founder.position.otherResponsibilitiesCode

			const otherResponsibilities = values.founder.position.otherResponsibilities;
			delete values.founder.position.otherResponsibilities

			return {
				startupId: startup,
				documentType: values.documentType,
				agreementName: values.agreementName,
				params: {
					...params,
					founder: {
						...values.founder,
						nationality: !_.isEmpty(values.founder.nationality.code)
							? values.founder.nationality.code
							: values.founder.nationality,
						nationalityName: !_.isEmpty(values.founder.nationality.name)
							? values.founder.nationality.name
							: values.founder.nationality,
						jurisdiction: values.founder.jurisdiction === "OTHER"
							? stakeholderOtherJurisdiction
							: values.founder.jurisdiction,
						address: {
							...values.founder.address,
							country: !_.isEmpty(values.founder.address.country.code)
								? values.founder.address.country.code
								: values.founder.address.country
						},
						position: {
							...values.founder.position,
							responsibilities: values.founder.position.isDefault
								? _.map(values.founder.position.responsibilities, responsibility => {
									return responsibility.isDefault
										? {...responsibility}
										: {...responsibility, text: otherResponsibility, name: otherResponsibility}
								})
								: [{
									code: otherResponsibilitiesCode,
									name: otherResponsibilities,
									text: otherResponsibilities,
									isDefault: false
								}]
						},
						recipientNotice: values.founder.recipientNotice ? {
							...values.founder.recipientNotice,
							address: {
								...values.founder.recipientNotice.address,
								country: !_.isEmpty(_.get(values, 'founder.recipientNotice.address.country.code', ''))
									? values.founder.recipientNotice.address.country.code
									: _.get(values, 'founder.recipientNotice.address.country', '')
							}
						} : null
					},
					company: {
						...values.company,
						authorizedSignatories: values.company.authorizedSignatories
							.map(as => ({id: as.id, email: as.email, fullName: as.fullName})),
						country: !_.isEmpty(values.company.country.code)
							? values.company.country.code
							: values.company.country,
						jurisdiction: values.company.jurisdiction === "OTHER"
							? companyOtherJurisdiction
							: values.company.jurisdiction,
						registeredAddress: {
							...values.company.registeredAddress,
							country: !_.isEmpty(values.company.registeredAddress.country.code)
								? values.company.registeredAddress.country.code
								: values.company.registeredAddress.country
						},
						recipientNotice: {
							...values.company.recipientNotice,
							address: {
								...values.company.recipientNotice.address,
								country: !_.isEmpty(values.company.recipientNotice.address.country.code)
									? values.company.recipientNotice.address.country.code
									: values.company.recipientNotice.address.country
							}
						}
					}
				}
			};
		case "EMPLOYMENT_AGREEMENT_ADGM":
		case "EMPLOYMENT_AGREEMENT_DIFC":
		case "EMPLOYMENT_AGREEMENT_UK":
			stakeholderOtherJurisdiction = values.employee.otherJurisdiction;
			delete values.employee.otherJurisdiction;

			companyOtherJurisdiction = values.company.otherJurisdiction;
			delete values.company.otherJurisdiction;
			return {
				startupId: startup,
				documentType: values.documentType,
				agreementName: values.agreementName,
				params: {
					...params,
					employee: {
						...values.employee,
						nationality: !_.isEmpty(values.employee.nationality.code)
							? values.employee.nationality.code
							: values.employee.nationality,
						nationalityName: !_.isEmpty(values.employee.nationality.name)
							? values.employee.nationality.name
							: values.employee.nationality,
						jurisdiction: values.employee.jurisdiction === "OTHER"
							? stakeholderOtherJurisdiction
							: values.employee.jurisdiction,
						address: {
							...values.employee.address,
							country: !_.isEmpty(values.employee.address.country.code)
								? values.employee.address.country.code
								: values.employee.address.country
						},
						recipientNotice: values.employee.recipientNotice ? {
							...values.employee.recipientNotice,
							address: {
								...values.employee.recipientNotice.address,
								country: !_.isEmpty(_.get(values, 'employee.recipientNotice.address.country.code', null))
									? values.employee.recipientNotice.address.country.code
									: _.get(values, 'employee.recipientNotice.address.country', null)
							}
						} : null
					},
					company: {
						...values.company,
						authorizedSignatories: values.company.authorizedSignatories
							.map(as => ({id: as.id, email: as.email, fullName: as.fullName})),
						country: !_.isEmpty(values.company.country.code)
							? values.company.country.code
							: values.company.country,
						jurisdiction: values.company.jurisdiction === "OTHER"
							? companyOtherJurisdiction
							: values.company.jurisdiction,
						registeredAddress: {
							...values.company.registeredAddress,
							country: !_.isEmpty(values.company.registeredAddress.country.code)
								? values.company.registeredAddress.country.code
								: values.company.registeredAddress.country
						},
						recipientNotice: {
							...values.company.recipientNotice,
							address: {
								...values.company.recipientNotice.address,
								country: !_.isEmpty(values.company.recipientNotice.address.country.code)
									? values.company.recipientNotice.address.country.code
									: values.company.recipientNotice.address.country
							}
						}
					}
				}
			};
		case "GRANT_AGREEMENT":
			stakeholderOtherJurisdiction = values.participant.otherJurisdiction;
			delete values.participant.otherJurisdiction;

			companyOtherJurisdiction = values.company.otherJurisdiction;
			delete values.company.otherJurisdiction;
			return {
				startupId: startup,
				documentType: values.documentType,
				agreementName: values.agreementName,
				params: {
					...params,
					participant: {
						...values.participant,
						nationality: !_.isEmpty(values.participant.nationality.code)
							? values.participant.nationality.code
							: values.participant.nationality,
						nationalityName: !_.isEmpty(values.participant.nationality.name)
							? values.participant.nationality.name
							: values.participant.nationality,
						jurisdiction: values.participant.jurisdiction === "OTHER"
							? stakeholderOtherJurisdiction
							: values.participant.jurisdiction,
						address: {
							...values.participant.address,
							country: !_.isEmpty(values.participant.address.country.code)
								? values.participant.address.country.code
								: values.participant.address.country
						},
						recipientNotice: values.participant.recipientNotice ? {
							...values.participant.recipientNotice,
							address: {
								...values.participant.recipientNotice.address,
								country: !_.isEmpty(_.get(values, 'participant.recipientNotice.address.country.code', ''))
									? values.participant.recipientNotice.address.country.code
									: _.get(values, 'participant.recipientNotice.address.country', '')
							}
						} : null
					},
					company: {
						...values.company,
						authorizedSignatories: values.company.authorizedSignatories
							.map(as => ({id: as.id, email: as.email, fullName: as.fullName})),
						country: !_.isEmpty(values.company.country.code)
							? values.company.country.code
							: values.company.country,
						jurisdiction: values.company.jurisdiction === "OTHER"
							? companyOtherJurisdiction
							: values.company.jurisdiction,
						registeredAddress: {
							...values.company.registeredAddress,
							country: !_.isEmpty(values.company.registeredAddress.country.code)
								? values.company.registeredAddress.country.code
								: values.company.registeredAddress.country
						},
						recipientNotice: {
							...values.company.recipientNotice,
							address: {
								...values.company.recipientNotice.address,
								country: !_.isEmpty(values.company.recipientNotice.address.country.code)
									? values.company.recipientNotice.address.country.code
									: values.company.recipientNotice.address.country
							}
						}
					}
				}
			};
		case "SHARE_INCENTIVE_PLAN":
		case "BOARD_RESOLUTION":
		case "SHARE_INCENTIVE_PLAN_FAQ":
			companyOtherJurisdiction = values.company.otherJurisdiction;
			delete values.company.otherJurisdiction;
			return {
				startupId: startup,
				documentType: values.documentType,
				agreementName: values.agreementName,
				params: {
					...params,
					company: {
						...values.company,
						authorizedSignatories: values.company.authorizedSignatories
							.map(as => ({id: as.id, email: as.email, fullName: as.fullName})),
						country: !_.isEmpty(values.company.country.code)
							? values.company.country.code
							: values.company.country,
						jurisdiction: values.company.jurisdiction === "OTHER"
							? companyOtherJurisdiction
							: values.company.jurisdiction,
						registeredAddress: {
							...values.company.registeredAddress,
							country: !_.isEmpty(values.company.registeredAddress.country.code)
								? values.company.registeredAddress.country.code
								: values.company.registeredAddress.country
						},
						recipientNotice: {
							...values.company.recipientNotice,
							address: {
								...values.company.recipientNotice.address,
								country: !_.isEmpty(values.company.recipientNotice.address.country.code)
									? values.company.recipientNotice.address.country.code
									: values.company.recipientNotice.address.country
							}
						}
					}
				}
			};
		case "RESTRICTIVE_COVENANT_AGREEMENT":
			stakeholderOtherJurisdiction = values.teamMember.otherJurisdiction;
			delete values.teamMember.otherJurisdiction;

			companyOtherJurisdiction = values.company.otherJurisdiction;
			delete values.company.otherJurisdiction;
			return {
				startupId: startup,
				documentType: values.documentType,
				agreementName: values.agreementName,
				params: {
					...params,
					teamMember: {
						...values.teamMember,
						nationality: !_.isEmpty(values.teamMember.nationality.code)
							? values.teamMember.nationality.code
							: values.teamMember.nationality,
						nationalityName: !_.isEmpty(values.teamMember.nationality.name)
							? values.teamMember.nationality.name
							: values.teamMember.nationality,
						jurisdiction: values.teamMember.jurisdiction === "OTHER"
							? stakeholderOtherJurisdiction
							: values.teamMember.jurisdiction,
						address: {
							...values.teamMember.address,
							country: !_.isEmpty(values.teamMember.address.country.code)
								? values.teamMember.address.country.code
								: values.teamMember.address.country
						},
						recipientNotice: values.teamMember.recipientNotice ? {
							...values.teamMember.recipientNotice,
							address: {
								...values.teamMember.recipientNotice.address,
								country: !_.isEmpty(_.get(values, 'teamMember.recipientNotice.address.country.code', ''))
									? values.teamMember.recipientNotice.address.country.code
									: _.get(values, 'teamMember.recipientNotice.address.country', '')
							}
						} : null
					},
					company: {
						...values.company,
						authorizedSignatories: values.company.authorizedSignatories
							.map(as => ({id: as.id, email: as.email, fullName: as.fullName})),
						country: !_.isEmpty(values.company.country.code)
							? values.company.country.code
							: values.company.country,
						jurisdiction: values.company.jurisdiction === "OTHER"
							? companyOtherJurisdiction
							: values.company.jurisdiction,
						registeredAddress: {
							...values.company.registeredAddress,
							country: !_.isEmpty(values.company.registeredAddress.country.code)
								? values.company.registeredAddress.country.code
								: values.company.registeredAddress.country
						},
						recipientNotice: {
							...values.company.recipientNotice,
							address: {
								...values.company.recipientNotice.address,
								country: !_.isEmpty(values.company.recipientNotice.address.country.code)
									? values.company.recipientNotice.address.country.code
									: values.company.recipientNotice.address.country
							}
						}
					}
				}
			};
		case "KISS":
			return {
				startupId: startup.id,
				documentType: values.documentType,
				agreementName: values.agreementName,
				expirationDate: values.expirationDate,
				params: values.file ? _.omit(values, ['file']) : values
			};
		case "EXERCISE_NOTICE":
			return {
				startupId: startup.id,
				documentType: values.documentType,
				agreementName: values.agreementName,
				params: {
					...params
				}
			};
		case "NDA_MUTUAL":
			stakeholderOtherJurisdiction = values.stakeholder.otherJurisdiction;
			delete values.stakeholder.otherJurisdiction;

			companyOtherJurisdiction = values.company.otherJurisdiction;
			delete values.company.otherJurisdiction;
			return {
				startupId: startup.id,
				documentType: values.documentType,
				agreementName: values.agreementName,
				params: {
					...params,
					stakeholder: {
						...values.stakeholder,
						email: values.stakeholder.isAnEntity
							? values.stakeholder.authorizedSignatory.email
							: values.stakeholder.email,
						nationality: !_.isEmpty(values.stakeholder.nationality.code)
							? values.stakeholder.nationality.code
							: values.stakeholder.nationality,
						nationalityName: !_.isEmpty(values.stakeholder.nationality.name)
							? values.stakeholder.nationality.name
							: values.stakeholder.nationality,
						jurisdiction: values.stakeholder.jurisdiction === "OTHER"
							? stakeholderOtherJurisdiction
							: values.stakeholder.jurisdiction,
						address: {
							...values.stakeholder.address,
							country: !_.isEmpty(values.stakeholder.address.country.code)
								? values.stakeholder.address.country.code
								: values.stakeholder.address.country
						},
						recipientNotice: values.stakeholder.recipientNotice ? {
							...values.stakeholder.recipientNotice,
							address: {
								...values.stakeholder.recipientNotice.address,
								country: !_.isEmpty(_.get(values, 'stakeholder.recipientNotice.address.country.code', null))
									? values.stakeholder.recipientNotice.address.country.code
									: _.get(values, 'stakeholder.recipientNotice.address.country', null)
							}
						} : null
					},
					company: {
						...values.company,
						authorizedSignatories: values.company.authorizedSignatories
							.map(as => ({id: as.id, email: as.email, fullName: as.fullName})),
						country: !_.isEmpty(values.company.country.code)
							? values.company.country.code
							: values.company.country,
						jurisdiction: values.company.jurisdiction === "OTHER"
							? companyOtherJurisdiction
							: values.company.jurisdiction,
						registeredAddress: {
							...values.company.registeredAddress,
							country: !_.isEmpty(values.company.registeredAddress.country.code)
								? values.company.registeredAddress.country.code
								: values.company.registeredAddress.country
						},
						recipientNotice: {
							...values.company.recipientNotice,
							address: {
								...values.company.recipientNotice.address,
								country: !_.isEmpty(values.company.recipientNotice.address.country.code)
									? values.company.recipientNotice.address.country.code
									: values.company.recipientNotice.address.country
							}
						}
					}
				}
			};
		case "FOUNDERS_AGREEMENT":
			companyOtherJurisdiction = _.get(values, 'company.otherJurisdiction');

			if (values.company) {
				delete values.company.otherJurisdiction;
				delete values.company.verified1;
				delete values.company.verified2;
				_.get(params, 'terms.transferShares.transferSharesGroupCompanies') &&
				_.forEach(_.get(params, 'terms.transferShares.companies', []), (company) => {
					delete company.verified;
					delete company.showTopLevelOption;
				});
			}

			return {
				startupId: startup.id,
				documentType: values.documentType,
				agreementName: values.agreementName,
				params: {
					...params,
					founders: _.map(values.founders, founder => {
						const otherJurisdiction = founder.otherJurisdiction;
						delete founder.otherJurisdiction;
						delete founder.verified1;
						delete founder.verified2;
						delete founder.startupPosition;

						const otherResponsibility = founder.position.otherResponsibility;
						delete founder.position.otherResponsibility

						const otherResponsibilitiesCode = founder.position.otherResponsibilitiesCode;
						delete founder.position.otherResponsibilitiesCode

						const otherResponsibilities = founder.position.otherResponsibilities;
						delete founder.position.otherResponsibilities

						return {
							...founder,
							email: founder.isAnEntity
								? founder.authorizedSignatory.email
								: founder.email,
							nationality: !_.isEmpty(founder.nationality.code)
								? founder.nationality.code
								: founder.nationality,
							nationalityName: !_.isEmpty(founder.nationality.name)
								? founder.nationality.name
								: founder.nationality,
							jurisdiction:
								founder.jurisdiction === "OTHER"
									? otherJurisdiction
									: founder.jurisdiction,
							address: {
								...founder.address,
								country: !_.isEmpty(founder.address.country.code)
									? founder.address.country.code
									: founder.address.country
							},
							position: {
								...founder.position,
								responsibilities: founder.position.isDefault
									? _.map(founder.position.responsibilities, responsibility => {
										return responsibility.isDefault
											? {...responsibility}
											: {...responsibility, text: otherResponsibility, name: otherResponsibility}
									})
									: [{
										code: otherResponsibilitiesCode,
										name: otherResponsibilities,
										text: otherResponsibilities,
										isDefault: false
									}]
							}
						}
					}),
					company: values.company && values.company.id !== 'groupCompanyNotSelected' ? {
						...values.company,
						authorizedSignatories: values.company.authorizedSignatories
							.map(as => ({id: as.id, email: as.email, fullName: as.fullName})),
						country: !_.isEmpty(values.company.country.code)
							? values.company.country.code
							: values.company.country,
						jurisdiction: values.company.jurisdiction === "OTHER"
							? companyOtherJurisdiction
							: values.company.jurisdiction,
						registeredAddress: {
							...values.company.registeredAddress,
							country: !_.isEmpty(values.company.registeredAddress.country.code)
								? values.company.registeredAddress.country.code
								: values.company.registeredAddress.country
						},
						recipientNotice: {
							...values.company.recipientNotice,
							address: {
								...values.company.recipientNotice.address,
								country: !_.isEmpty(values.company.recipientNotice.address.country.code)
									? values.company.recipientNotice.address.country.code
									: values.company.recipientNotice.address.country
							}
						}
					} : null
				}
			};
		case "WEBSITE_PRIVACY_POLICY":
		case "WEBSITE_ACCEPTABLE_USE_POLICY":
		case "WEBSITE_TERMS_OF_USE":
		case "WEBSITE_COOKIE_NOTICE":
			companyOtherJurisdiction = values.company.otherJurisdiction;
			delete values.company.otherJurisdiction;
			return {
				startupId: startup.id,
				documentType: values.documentType,
				agreementName: values.agreementName,
				params: {
					...params,
					company: {
						...values.company,
						country: !_.isEmpty(values.company.country.code)
							? values.company.country.code
							: values.company.country,
						jurisdiction: values.company.jurisdiction === "OTHER"
							? companyOtherJurisdiction
							: values.company.jurisdiction,
						registeredAddress: {
							...values.company.registeredAddress,
							country: !_.isEmpty(values.company.registeredAddress.country.code)
								? values.company.registeredAddress.country.code
								: values.company.registeredAddress.country
						},
					}
				}
			};
		case "BOARD_RESOLUTION_CONVERTIBLE_LOAN_NOTE":
		case "BOARD_RESOLUTION_CONVERTIBLE_SAFE":
		case "BOARD_RESOLUTION_CONVERTIBLE_KISS":
		case "BOARD_RESOLUTION_CONVERTIBLE_FIXED_EQUITY":
		case "BOARD_RESOLUTION_CONVERTIBLE_OTHER":
			companyOtherJurisdiction = values.company.otherJurisdiction;
			delete values.company.otherJurisdiction;
			return {
				startupId: startup,
				documentType: values.documentType,
				agreementName: values.agreementName,
				params: {
					...params,
					company: {
						...values.company,
						authorizedSignatories: values.company.authorizedSignatories
							.map(as => ({id: as.id, email: as.email, fullName: as.fullName})),
						country: !_.isEmpty(values.company.country.code)
							? values.company.country.code
							: values.company.country,
						jurisdiction: values.company.jurisdiction === "OTHER"
							? companyOtherJurisdiction
							: values.company.jurisdiction,
						registeredAddress: {
							...values.company.registeredAddress,
							country: !_.isEmpty(values.company.registeredAddress.country.code)
								? values.company.registeredAddress.country.code
								: values.company.registeredAddress.country
						}
					}
				}
			};
		case "USA_CAL_EMPLOYEE_INFORMATION_AND_INVENTIONS_AGREEMENT":
			stakeholderOtherJurisdiction = values.employee.otherJurisdiction;
			delete values.employee.otherJurisdiction;

			companyOtherJurisdiction = values.company.otherJurisdiction;
			delete values.company.otherJurisdiction;

			return {
				startupId: startup,
				documentType: values.documentType,
				agreementName: values.agreementName,
				params: {
					...params,
					employee: {
						...values.employee,
						email: values.employee.isAnEntity
							? values.employee.authorizedSignatory.email
							: values.employee.email,
					},
					company: {
						...values.company,
						authorizedSignatories: values.company.authorizedSignatories
							.map(as => ({id: as.id, email: as.email, fullName: as.fullName, title: as.title})),
						country: !_.isEmpty(values.company.country.code)
							? values.company.country.code
							: values.company.country,
						jurisdiction: values.company.jurisdiction === "OTHER"
							? companyOtherJurisdiction
							: values.company.jurisdiction,
					}
				}
			};
		case "USA_CONSULTING_AGREEMENT":
			stakeholderOtherJurisdiction = values.consultant.otherJurisdiction;
			delete values.consultant.otherJurisdiction;

			companyOtherJurisdiction = values.company.otherJurisdiction;
			delete values.company.otherJurisdiction;

			stakeholderOtherJurisdiction = values.consultant.otherJurisdiction;
			delete values.consultant.otherJurisdiction;

			companyOtherJurisdiction = values.company.otherJurisdiction;
			delete values.company.otherJurisdiction;
			return {
				startupId: startup,
				documentType: values.documentType,
				agreementName: values.agreementName,

				params: {
					agreementName: values.agreementName,
					...params,
					company: {
						...(values?.company ?? {}),
						jurisdiction: values.company.jurisdiction === "OTHER"
							? companyOtherJurisdiction
							: values.company.jurisdiction,
						authorizedSignatories: values.company.authorizedSignatories.map(auth => ({
							...auth,
							address: {...auth.address, country: auth.address?.country?.code || auth.address?.country}
						})),
						country: !_.isEmpty(values.company.country.code)
							? values.company.country.code
							: values.company.country,
						placeOfBusiness: {
							state: values.company.placeOfBusiness.state,
							city: values?.company?.placeOfBusiness?.city

						},
						recipientNotice: {
							...values.company.recipientNotice,
							address: {
								...values.company.recipientNotice.address,
								country: !_.isEmpty(values.company.recipientNotice.address.country.code)
									? values.company.recipientNotice.address.country.code
									: values.company.recipientNotice.address.country
							}
						}
					},
					consultant: {
						...(values?.consultant ?? {}),
						title: values.consultant.title,
						recipientNotice: {
							...values.consultant.recipientNotice,
							address: {
								...values.consultant.recipientNotice.address,
								country: !_.isEmpty(values.consultant.recipientNotice.address.country?.code)
									? values.consultant.recipientNotice.address.country.code
									: values.consultant.recipientNotice.address.country
							}
						},
						address: {
							...values.consultant.address,
							country: !_.isEmpty(values.consultant.address.country.code)
								? values.consultant.address.country.code
								: values.consultant.address.country
						},
						jurisdiction: values.consultant.jurisdiction === "OTHER"
							? stakeholderOtherJurisdiction
							: values.consultant.jurisdiction,
					}
				}
			};
		case "USA_CONSULTANT_INFORMATION_AND_INVENTIONS_AGREEMENT":
			stakeholderOtherJurisdiction = values.consultant.otherJurisdiction;
			delete values.consultant.otherJurisdiction;

			companyOtherJurisdiction = values.company.otherJurisdiction;
			delete values.company.otherJurisdiction;

			return {
				startupId: startup,
				documentType: values.documentType,
				agreementName: values.agreementName,
				params: {
					...params,
					consultant: {
						...values.consultant,
						email: values.consultant.isAnEntity
							? values.consultant.authorizedSignatory.email
							: values.consultant.email,
					},
					company: {
						...values.company,
						authorizedSignatories: values.company.authorizedSignatories
							.map(as => ({id: as.id, email: as.email, fullName: as.fullName, title: as.title})),
						country: !_.isEmpty(values.company.country.code)
							? values.company.country.code
							: values.company.country,
						jurisdiction: values.company.jurisdiction === "OTHER"
							? companyOtherJurisdiction
							: values.company.jurisdiction,
					}
				}
			};
		case "USA_STRATEGIC_ADVISORY_BOARD_LETTER":
			stakeholderOtherJurisdiction = values.advisor.otherJurisdiction;
			delete values.advisor.otherJurisdiction;

			companyOtherJurisdiction = values.company.otherJurisdiction;
			delete values.company.otherJurisdiction;

			return {
				startupId: startup,
				documentType: values.documentType,
				agreementName: values.agreementName,
				params: {
					...params,
					advisor: {
						...values.advisor,
						email: values.advisor.isAnEntity
							? values.advisor.authorizedSignatory.email
							: values.advisor.email,
					},
					company: {
						...values.company,
						authorizedSignatories: values.company.authorizedSignatories
							.map(as => ({id: as.id, email: as.email, fullName: as.fullName, title: as.title})),
						country: !_.isEmpty(values.company.country.code)
							? values.company.country.code
							: values.company.country,
						registeredAddress: {
							...values.company.registeredAddress,
							country: !_.isEmpty(values.company.registeredAddress.country.code)
								? values.company.registeredAddress.country.code
								: values.company.registeredAddress.country
						},
						jurisdiction: values.company.jurisdiction === "OTHER"
							? companyOtherJurisdiction
							: values.company.jurisdiction,
					}
				}
			};
		case "USA_BOARD_CONSENT_SAFE_CONVERTIBLE_LOAN_NOTE":
		case "USA_BOARD_CONSENT_SAFE_SAFE":
		case "USA_BOARD_CONSENT_SAFE_KISS":
		case "USA_BOARD_CONSENT_SAFE_FIXED_EQUITY":
		case "USA_BOARD_CONSENT_SAFE_OTHER":
		case "USA_BOARD_CONSENT_SAFE":
			companyOtherJurisdiction = values.company.otherJurisdiction;
			delete values.company.otherJurisdiction;
			return {
				startupId: startup,
				documentType: values.documentType,
				agreementName: values.agreementName,
				params: {
					...params,
					company: {
						...values.company,
						authorizedSignatories: values.company.authorizedSignatories
							.map(as => ({id: as.id, email: as.email, fullName: as.fullName})),
						country: !_.isEmpty(values.company.country.code)
							? values.company.country.code
							: values.company.country,
						jurisdiction: values.company.jurisdiction === "OTHER"
							? companyOtherJurisdiction
							: values.company.jurisdiction,
						registeredAddress: {
							...values.company.registeredAddress,
							country: !_.isEmpty(values.company.registeredAddress.country.code)
								? values.company.registeredAddress.country.code
								: values.company.registeredAddress.country
						}
					}
				}
			};
		case "USA_OPTION_GRANT_NOTICE":
			companyOtherJurisdiction = values.company.otherJurisdiction;
			delete values.company.otherJurisdiction;
			return {
				startupId: startup,
				documentType: values.documentType,
				agreementName: values.agreementName,
				params: {
					...params,
					participant: {
						...values.participant,
					},
					company: {
						...values.company,
						authorizedSignatories: values.company.authorizedSignatories
							.map(as => ({
								id: as.id,
								email: as.email,
								fullName: as.fullName,
								title: as.title
							})),
						country: !_.isEmpty(values.company.country.code)
							? values.company.country.code
							: values.company.country,
						jurisdiction: values.company.jurisdiction === "OTHER"
							? companyOtherJurisdiction
							: values.company.jurisdiction,
						registeredAddress: {
							...values.company.registeredAddress,
							country: !_.isEmpty(values.company.registeredAddress.country.code)
								? values.company.registeredAddress.country.code
								: values.company.registeredAddress.country
						},

					}
				}
			};
		case "USA_BOARD_APPROVING_OPTION_GRANT":
			if (params.terms.A409Valuation.isItApproved) {
				delete params.terms.A409Valuation.isItByBoardOrFirstApproved
			} else {
				if (!params.terms.A409Valuation.isItByBoardOrFirstApproved) {
					delete params.terms.A409Valuation.valuationFirmName
					delete params.terms.A409Valuation.date
				}
			}

			return {
				startupId: startup,
				documentType: values.documentType,
				agreementName: values.agreementName,
				params: {
					...params,
					grantees: values.grantees,
					company: {
						...values.company,
						authorizedSignatories: values.company.authorizedSignatories
							.map(as => ({id: as.id, email: as.email, fullName: as.fullName})),
					}
				}

			}
		case "USA_EQUITY_INCENTIVE_PLAN":
		case "USA_STAKEHOLDERS_DIRECTORS_EQUITY_INCENTIVE_PLAN":
			companyOtherJurisdiction = values.company.otherJurisdiction;
			delete values.company.otherJurisdiction;
			return {
				startupId: startup,
				documentType: values.documentType,
				agreementName: values.agreementName,
				params: {
					...params,
					company: {
						...values.company,
						authorizedSignatories: values.company.authorizedSignatories
							.map(as => ({id: as.id, email: as.email, fullName: as.fullName})),
						country: !_.isEmpty(values.company.country.code)
							? values.company.country.code
							: values.company.country,
						jurisdiction: values.company.jurisdiction === "OTHER"
							? companyOtherJurisdiction
							: values.company.jurisdiction,

					},
					stakeholders: values.stakeholders
				}
			}
		case "USA_NDA":

			return {
				startupId: startup,
				documentType: values.documentType,
				agreementName: values.agreementName,

				params: {
					agreementName: values.agreementName,

					company: {
						...(values?.company ?? {}),
						authorizedSignatories: values.company.authorizedSignatories,
						country: !_.isEmpty(values.company.country.code)
							? values.company.country.code
							: values.company.country,
							placeOfBusiness: {
								state: values.company.placeOfBusiness.state,
								city: values?.company?.placeOfBusiness?.city
							}
					},
					stakeholder: {
						...(values?.stakeholder ?? {}),
						// title: values.stakeholder.title,
						address: {
							...values.stakeholder.address,
							country: !_.isEmpty(values.stakeholder.address.country.code)
								? values.stakeholder.address.country.code
								: values.stakeholder.address.country
						},
					},


				}
			};
		//CP-5321
		case "USA_CAL_OFFER_LETTER_SALARY_EMPLOYEE":

			return {
				startupId: startup,
				documentType: values.documentType,
				agreementName: values.agreementName,

				params: {
					agreementName: values.agreementName,
					company: {
						...(values?.company ?? {}),
						authorizedSignatories: values.company.authorizedSignatories,
						country: !_.isEmpty(values.company.country.code)
							? values.company.country.code
							: values.company.country,
						placeOfBusiness: {
							state: values.company.placeOfBusiness.state,
							city: values?.company?.placeOfBusiness?.city

						}
					},
					stakeholder: {
						...(values?.stakeholder ?? {}),
						title: values.stakeholder.title,
						address: {
							...values.stakeholder.address,
							country: !_.isEmpty(values.stakeholder.address.country.code)
								? values.stakeholder.address.country.code
								: values.stakeholder.address.country
						},
					},
					terms: {
						...values.terms,
					}
				}
			};

		default:
			break;
	}
};

export default useGetDataByDocumentType

import _ from "lodash";
import React, { useMemo, useState } from 'react';
import RowAdd from '../../../forms/contents/RowAdd/RowAdd';
import SelectAddInput from "../../Select/SelectAddInput/SelectAddInput";
import Classes from './styles.module.scss';
import useGetShareClasses from './graphql/useGetShareClasses'
const ShareClassesSelect = (
	{
		onBlur,
		modal = false ,
		selectInputLabel = 'Share class',
        onChange,
        value,
        groupCompanyId,
		errorMessage
	}) => {
    const {data,loading} = useGetShareClasses(groupCompanyId,{skip:!groupCompanyId})
    const [showShareClasses, setShareClasses] = useState(true);
    const listShareClasses = useMemo(() => _.map(data, as => ({ value: as, label: `${as.name}` ,type:'shareClasses'})), [JSON.stringify(data)])
    const addList = [
        {
            label: "Add new share class",
            onClick: (value) => {
                setShareClasses(false)
                onChange && onChange('ADD') 
            },
        },
    ];
    if(loading){
        return null
    }

                return (<>

                        {showShareClasses &&
                            (<div className={Classes.containerForm}>
                                <RowAdd
                                    label={`${selectInputLabel} `}
                                    asterisk={true}
                                    classNameInput={modal ? Classes.inputModal : Classes.input}
                                    input={
                                        <SelectAddInput
                                            list={listShareClasses}
                                            mode={'add'}
                                            onBlur = {onBlur}
                                            value={{id:value}}
                                            compare={'id'}
                                            handleFilterList={(value) => {return value}}
                                            placeholder={"Select from the list"}
                                            onChange={(value) => {
                                                onChange && onChange(value) 
                                            }}
                                            menuProps={{
                                                addList: addList,
                                                propsList: [{ type: 'shareClasses' }]
                                            }}
                                            errorMessage={typeof errorMessage === 'string' ?errorMessage:''}
                                        />
                                    }
                                    />
                            </div>)}
                        
                </>)

};
export default ShareClassesSelect

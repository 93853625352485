import get from 'lodash/get';
import React, { FC } from 'react';
import useCustomMachine from 'src/modules/generic/context/MachineContext/useMachine';
import Button from "../../../../../generic/components/Buttons/Button";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import SelectDocument from "../../../forms/SelectDocument";
import upperFirst from 'lodash/upperFirst'
const SelectDocumentSIPStep: FC<{}> = ({ }) => {
  const { send, next, context } = useCustomMachine()
  const { translate } = useTranslate()
  const handleCancel = () => {
    send('CANCEL')
  }
  const handleCompleteSubmit = async (values: object) => {
    next(values);
  }
  const handlePrev = (): void => {
    send("PREVIOUS")
  }
  return (
    <>
      <SelectDocument
        variant="option"
        company={context.groupCompanies[_.get(context,'iterations',0)]}
        documents={get(context, `getDocumentListOptionSIP.manualSips`)}
        propsTemplate={{
          title: translate(
            `MODULES.EQUITY.STEPS.SELECT_DOCUMENTS_STEP.TITLE_${context.type}_SIP`,
            {
              agreementType: translate(
                `MODULES.EQUITY.AGREEMENT_TYPE.${context.type}`
              ),
            }
          ),
          subTitle: translate(
            `MODULES.EQUITY.STEPS.SELECT_DOCUMENTS_STEP.SUBTITLE_${context.type}`,
            { type: translate(`MODULES.EQUITY.TYPES_PLURAL.${context.type}`) }
          ),
        }}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        buttons={{
          cancel: <Button onClick={handleCancel}>Cancel</Button>, 
          previous: <Button onClick={handlePrev}>Previous</Button>
        }}
      />
    </>
  );
}
export default SelectDocumentSIPStep
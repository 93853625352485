import React from 'react';
import { Box, Divider } from '@mui/material';
import Typography from 'src/modules/generic/components/Texts/Typography';
import TwoColumnsData from "src/modules/generic/components/TwoColumnsData";
import useFormat from 'src/modules/generic/hooks/useFormat';

const InternationalTransfer = ({ defineJurisdiction, bankInformation }) => {
  const { moneyFormat } = useFormat();
  const international = bankInformation?.product?.bankInformation?.find(info => info?.wireDetails?.type === "INTERNATIONAL");
  
  return (
    <>
      <Box mr={2} sx={{ width: '100%' }} pr={2}>
        {defineJurisdiction === "ADGM" && (
          <>
            <Box mb={1}>
              <Typography variant={'h5'} color={'black'} >
                <b>Payment amount USD {moneyFormat(bankInformation?.product?.value)} / AED {moneyFormat(bankInformation?.rateConversion?.inclusiveOfVAT)} </b>
              </Typography>
            </Box>
            <Box mb={1}>
              <Typography variant={'h5'} color={'black'} >
                <b>Reference number {bankInformation.reference}</b>
              </Typography>
            </Box>
          </>
        )}
        {defineJurisdiction === "CAYMAN" && (
          <>
            <Box mb={1}>
              <Typography variant={'h5'} color={'black'} >
                <b>Payment amount USD {moneyFormat(bankInformation?.product?.value)}</b>
              </Typography>
            </Box>
          </>
        )}
        <Divider />
      </Box>
      <TwoColumnsData
        title="Beneficiary bank information"
        variant='simple'
        items={[
          {
            label: 'SWIFT/BIC Code',
            value: international?.beneficiaryBank?.swiftBicCode
          },
          {
            label: 'Name',
            value: international?.beneficiaryBank?.name
          },
          {
            label: 'Address',
            value: international?.beneficiaryBank?.address
          }
        ]}
      />
      <TwoColumnsData
        title="Intermediary bank information"
        variant='simple'
        items={[
          {
            label: 'SWIFT/BIC Code',
            value: international?.intermediaryBank?.swiftBicCode
          },
          {
            label: 'Name',
            value: international?.intermediaryBank?.name
          },
          {
            label: 'Address',
            value: international?.intermediaryBank?.address
          }
        ]}
      />
      <TwoColumnsData
        variant='simple'
        title="Beneficiary information"
        items={[
          {
            label: 'Name',
            value: international?.beneficiary?.name
          },
          {
            label: 'Account no.',
            value: international?.beneficiary?.accountNo
          },
          {
            label: 'Address',
            value: international?.beneficiary?.address
          }
        ]}
      />
    </>
  )
}

export default InternationalTransfer;

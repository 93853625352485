import PropTypes from "prop-types";
import React from 'react';
import classes from 'src/views/modules/startups/company/companyView/Tabs/KeyPeopleTab/panels/styles.module.scss';
import ReactAvatar from "react-avatar";
import PincasIcon from "src/modules/generic/components/Icon/PincasIcon";

function UserItem ({fullName, avatar, onClick, inPincas = false, useNominee = false, isPrimarySignatory = false}) {

  return (
    <>
      <div
        className={classes.containerDirector}
        onClick={inPincas ? null : onClick}
      >
        <div className={inPincas ? classes.filter : classes.noFilter}>
          <ReactAvatar
            name={fullName}
            round={true}
            size={"3.5rem"}
            src={avatar}
          />
          <p>{fullName}</p>
        </div>
        {inPincas && (
          <div className={classes.inProgressIcon}>
            <PincasIcon />
          </div>
        )}
        {isPrimarySignatory && useNominee && (
          <div className={classes.secondarySignatoryContainer}>
            <span className={classes.secondarySignatoryText}>
              Secondary Signatory
            </span>
          </div>
        )}
      </div>
    </>
  );
}

UserItem.propTypes = {
  fullName: PropTypes.string,
  avatar: PropTypes.string,
  onClick: PropTypes.func,
  inPincas: PropTypes.bool,
  useNominee: PropTypes.bool,
  isPrimarySignatory: PropTypes.bool,
}

export default UserItem;
import React, {FC, useMemo} from 'react'
import {StepProps} from "../../../../../../../../generic/components/Wizard/Step";
import ShareForm from "../../forms/ShareForm";
import TemplateWizard from "../../../../../../../../generic/templates/Modal/TemplateWizard";
import Button from "../../../../../../../../generic/components/Buttons/Button";
import useCustomMachine from "../../../../../../../../generic/hooks/useCustomMachine";
import _ from "lodash";
import useTranslate from "../../../../../../../../generic/hooks/useTranslate";

const ShareFormStep: FC<StepProps> = ({}) => {
    const {stepData, next, send, state} = useCustomMachine()
    const {translate} = useTranslate()
    const handleCompleteSubmit = (values): void => {
        next(values)
    }
    const handleCancel = (): void => {
        send("CANCEL")
    }

    const handlePrev = (): void => {
        send("PREVIOUS")
    }
    const getCompany = (): object => {
        if (_.isEmpty(_.get(state, 'context.globalData', []))) return _.get(state, 'context.data.select_companies.companies[0]')
        else return _.get(state, `context.globalData[0].select_companies.companies[${_.get(state, 'context.numbersOfIteration', 0)}]`)
    }
    const companyName = useMemo((): string => {
        const company = getCompany()
        return _.get(company, 'name', '')
    }, [JSON.stringify(state)])
    const currency = useMemo((): string => {
        const company = getCompany()
        return _.get(company, 'transactionCurrency', '') ? _.get(company, 'transactionCurrency', '') : ''
    }, [JSON.stringify(state)])
    const shareClass = useMemo((): any[] => {
        const company = getCompany()
        return _.get(company, 'shareClass')
    }, [JSON.stringify(state)])
    return (<>
        <ShareForm
            initialValues={stepData}
            currency={currency}
            shareClassList={shareClass}
            propsTemplate={{
                subTitle: "Company equity",
                title: "Complete the details for these shares"
            }}
            companyName={companyName}
            onCompleteSubmit={handleCompleteSubmit}
            Template={TemplateWizard}
            buttons={{cancel: <Button onClick={handleCancel}>Cancel</Button>,
                previous: _.isEmpty(_.get(state, 'context.globalData')) ?
                    <Button onClick={handlePrev}>Previous</Button> : null
            }}/>
    </>)
}
export default ShareFormStep
import React from 'react';
import {useDispatch} from "react-redux";
import classes from "../../../../containers/Contents/StartupsView/SwitchStartup.module.scss";
import {Avatar} from "../../../index";
import _ from "lodash";
import {Constants} from '../../../../utils/constants';
import Tag from "../../../Tag/Tag";
import TooltipBox from '../../../TooltipBox/TooltipBox';


const MatterBlock = (props) => {

    const { matter, index } = props
    const dispatch = useDispatch();

    const selectRedirect = () => {
        
      
        if(matter.tags.includes("declinedDocuments")){

           return  dispatch({ type: 'GO_TO', page: Constants.PAGES.viewMatter, params:{ id: matter.id , tab: "notes"} })
        }


        return  dispatch({ type: 'GO_TO', page: Constants.PAGES.viewMatter, params:{ id: matter.id } })
    }

    return (

        <React.Fragment>

            <div key={index} className={classes.containerMatters} onClick={selectRedirect}>
                <div className={classes.matterAvatar}>
                    <Avatar name={_.get(matter, 'clientFullName')} showLabel={false} />
                </div>

                <div className={classes.matterTextContainer}>
                    <TooltipBox label={_.get(matter,'questionnaire.companyDetails.companyName')?` ${_.get(matter,'questionnaire.companyDetails.companyName')}  (${_.get(matter, 'clientFullName')})`:_.get(matter, 'clientFullName')}><h3>{_.get(matter,'questionnaire.companyDetails.companyName')?` ${_.get(matter,'questionnaire.companyDetails.companyName')}  (${_.get(matter, 'clientFullName')})`:_.get(matter, 'clientFullName')}</h3></TooltipBox>
                    <p className={classes.matterAddress}>{_.get(matter, 'sequenceNumber')} - {_.get(matter, 'type')}</p>
                </div>

                <div className={classes.tagsContainer}>
                    {matter.tags ? matter.tags.map((tag) => {

                        return (<Tag type={tag} show={true} />)
                    }) : null}
                </div>
                <div className={classes.selectMatter}></div>
            </div>

        </React.Fragment>
    );
}

export default MatterBlock;
import { useMemo } from 'react';

const useGuards = () => {


  return useMemo(() => ({
    isSelectedGenerateDocument : (context) => {
      const confirm = context?.HaveDocumentStep?.confirm;
      return confirm ==="generate";
    },
    isSelectedUpload : (context) => {
      const confirm = context?.HaveDocumentStep?.confirm;
      return confirm ==="upload";
    },
    isSelectedUploadLater : (context) => {
      const confirm = context?.HaveDocumentStep?.confirm;
      return confirm ==="later";
    }
}),[]);
}
export default useGuards;
import React, {FC, useEffect} from 'react';
import FirstStep from "../../../core/wizards/steps/FirstStep";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import useCustomMachine from "../../../../../generic/hooks/useCustomMachine";
import {StepProps} from "../../../../../generic/components/Wizard/Step";
import Button from "../../../../../generic/components/Buttons/Button";
import {addAssistantText, clearAssistatText} from "../../../../../generic/store/action";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import useRemovePendingEntity from "../../../../../../hooks/services/modules/assistance/useRemovePendingEntity";
import useSession from '../../../../../session/hooks/useSession';

const FirstStepInvestor: FC<StepProps> = ({}: StepProps)=>{
    const {translate} = useTranslate();
    const { startup } = useSession();
    const [removePendingEntity] = useRemovePendingEntity({showSuccessNotification:false});
    const dispatch=useDispatch()

    const handleCompleteSubmit = async (values: any) => {
        await removePendingEntity({entityType: 'INVESTOR', startupId: startup.id})
        dispatch(clearAssistatText())
    }
    useEffect(()=>{
        dispatch(clearAssistatText())
        dispatch(addAssistantText(translate("MODULES.ONBOARDINGROLES.INVESTOR.FIRST_STEP_TOOLTIPS"),'text'))
    },[])
    return (
        <>
            <FirstStep
                onCompleteSubmit={handleCompleteSubmit}
                role={'INVESTOR'}
                entity='an investor'
            />
        </>
    );
}

export default FirstStepInvestor;

import React, { FC } from "react";
import Modal, { ModalProps } from "../Default";
import classes from "./classes.module.scss";
interface ModalDeleteProps extends ModalProps {

}
const ModalDelete: FC<ModalDeleteProps> = ({ children, open, onClose }) => {
  return (
    <Modal
      open={open}
      classes={{
        paper: classes.MuiDialogPaper
      }}
      onClose={onClose}
    >
      <div className={classes.containerModal}>
        {children}
      </div>
    </Modal>
  )
}
export default ModalDelete
import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import Checkbox from "src/components/inputs/Checkbox/Checkbox";
import * as Yup from 'yup';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText } from 'src/modules/generic/store/action';
import Typography from "src/modules/generic/components/Texts/Typography";
import classes from "./classes.module.scss";

const schema = Yup.object().shape({
  confirmation: Yup.boolean()
    .oneOf([true], 'You must accept the terms to continue')
    .required('This field is required')
});

function ConfirmForm() {
  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.ConfirmForm ?? {}
  }, [context]);

  const handleCancel = (values) => {
    cancel();
  }

  const handleSubmit = async (values) => {
    next(values)
  }

  useEffect(() => {
    return () => {
      dispatch(clearAssistatText())
    }
  }, [dispatch])

  const Paragraph = () => {
    return (
      <div className={classes.Paragraph}>
        <Typography component="p" variant="m">
          {translate(
            "MODULES.RENEWALS.CONFIRM_INFORMATION.CONFIRM_FORM.TEXT1"
          )}
        </Typography>
      </div>
    );
  }; 

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: translate('MODULES.RENEWALS.CONFIRM_INFORMATION.GENERIC.TITLE'),
          skeleton: null,
          subTitle: translate('MODULES.RENEWALS.CONFIRM_INFORMATION.GENERIC.SUBTITLE'),
          variant: 'simple'
        }}

        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>Confirm</SubmitButton>,
        }}
      >
        <div>
          <Paragraph />
          <ControllerInput
            label={translate('MODULES.RENEWALS.CONFIRM_INFORMATION.CONFIRM_FORM.TEXT2')}
            isMultiple={false}
            render={Checkbox}
            name="confirmation"
            showErrorMessage={true}
          />
        </div>
      </TemplateWizard>
    </Form>
  );
}

export default ConfirmForm;


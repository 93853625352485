import React, {useEffect, useState} from 'react';
import {ClaraRadioWithButton, TextInput} from '../index';
import _ from "lodash";
import PropTypes from 'prop-types';
import {Field} from "formik";
import classes from "./Chat.module.scss";
import ConfigChatQuestionary from "./ConfigChatQuestionary";
import {helper} from "../../utils";

const ChatCompanyName = ({ responseKeyName, type, isForm, firstLabel, secondLabel, className, text,form }) => {
  const nameField = responseKeyName ? responseKeyName + "." : "";
  const [actions, setActions] = useState([]);

  useEffect(() => {
    setLabels(form);
  }, [form])
  const setLabels = (form)=>{
    let params = {
      companyName: _.get(form.values, nameField + "companyName", "")
    }
    const actionConfig = _.get(ConfigChatQuestionary, "COMPANY_NAME." + type + ".actions", [])
    _.forEach(actionConfig, (action) => {
      params[action.key] = _.get(form.values, nameField + action.key, "");
    })
    setActions(
      _.map(actionConfig, (action) => {
        return {
          key: action.key,
          need: action.need,
          options: _.map(action.options, (option) => {
            return { 'label': helper.getTranslateTextByKey(option.label, params), 'key': option.key, 'value': option.key  }
          })
        }
      })
    )
  }
  const isDisabled = (action,form)=>{
    if (!action.need){
      return !_.get(form.values,"companyName");
    }
    return !_.get(form.values,"companyName") || !_.get(form.values,nameField+action.need,false);
  }
  const onChangeValue = (e, form) => {
    setLabels(form);
  }
  return (
    <React.Fragment>
      <div className={classes.ChatCompanyName}>
        <Field name={isForm ? "companyName" : nameField + "companyName"}>
          {({ field, form }) => (
            <React.Fragment>
              <TextInput
                showRedStar={false}
                required={true}
                type='text'
                field={field}
                label={isForm ? firstLabel : " "}
                form={form}
                placeholder={"QUESTIONARY_FORM_COMPANY_NAME_HOLDING_PLACEHOLDER"}
                tooltip={"<p>What words can't be used?<br/>(a) name of a town, city or country<br/>(b) “ADGM” or any name associated with the UAE Government<br/>(c) ones suggesting an operational, financial or insurance activity<br/>(d) well-known brand names unless you have proof of consent to use."}
                className={classes.ChatInputText}
                onChange={e => {
                  onChangeValue(e, form);
                }}
              />
            </React.Fragment>
          )}
        </Field>
        {isForm && <label className={className.labelOptionButton}>{secondLabel}</label>}
        {actions.map((action,i) => {
          return (
            <div key={i} className={`${isForm ? className.OptionButtonZone : classes.OptionButtonZone}`}>
              <Field name={nameField + action.key}>
                {({ field, form }) => (
                  <React.Fragment>
                    <ClaraRadioWithButton
                      field={field}
                      form={form}
                      changeParams={field.name}
                      options={action.options}
                      disabled = {isDisabled(action,form)}
                      className={{
                        root: classes.ChatClaraRadioButton,
                        input: classes.ChatClaraRadioButtonInput
                      }}
                      placeholder={""} />
                  </React.Fragment>
                )}
              </Field>
            </div>
          )
        })}

      </div>
    </React.Fragment >
  );
}
ChatCompanyName.propTypes = {
  responseKeyName: PropTypes.string.isRequired,
  // initialValueStart: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
};
export default ChatCompanyName;
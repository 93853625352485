import * as jwt from "jsonwebtoken";
import _ from "lodash";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import { goToPage } from "../../../store/actions/page";
import { useGetStartupAndActionsLazy } from "../../startup/graphql/startup/useGetStartup";
import useGetMe from "./../graphql/useGetMe";

import {
  clearSession as clearSessionAction,
  setMoreOneStartup as setMoreOneStartupAction,
  login as loginAction,
  setLoading as setLoadingAction,
  setPageLoaded as setPageLoadedAction,
  setStartup as setStartupAction,
  setToken as setTokenAction,
  setUser as setUserAction,
  updateUser as updateUserAction,
  updateStartup as updateStartupAction,
  setExpandedHeader as setExpandedHeaderAction,
  updatePaymentHasOverduePayment as updatePaymentHasOverduePayment
} from "./../store/action";
import { UserBO } from "../../startup/core/type/core";
import { StartupBO } from "../../startup/core/type/startup";
import { ServerConnect } from "./../../../../src/v1/utils";


interface Session {
  expandedHeader?: boolean,
  isLoading?: boolean,
  lastLogin?: any,
  pagesLoaded?: {},
  startup?: any,
  token?: string,
  user?: any,
  [key: string]: any
}



export interface UseSessionReturn {
  session: Session;
  user: any;
  startup: any;
  payment: any;
  startupId: string;
  isLoading: boolean;
  expandedHeader: boolean;
  setExpandedHeader: (expandedValue: boolean) => void;
  initSession: (token: string, lastLogin: Date) => void;
  selectStartup: (startupId: string, page: any) => void;
  setToken: (token: string) => void;
  clearSession: () => void;
  setMoreOneStartup: (moreOneStartup: boolean) => void;
  setUser: (user: any) => void;
  setPageLoaded: (page: string) => void;
  setStartup: (startup: any) => void;
  updateUser: (user: any) => void;
  updateStartup: (startup: any) => void;
  clearCache: () => void;
  updateUserData: () => void;
  updateHasOverduePayment: (hasOverduePayment: boolean) => void;
  getGroupCompany: (groupCompanyID: string) => any;
}

function useSession(): UseSessionReturn {

  const dispatch = useDispatch();
  const [redirectAfterLoading, setRedirectAfterLoading] = useState(null);

  interface GetMe {
    getMe: UserBO;
  }

  const handleCompleteGetMe = (data: GetMe) => {
    setUser(_.get(data, "getMe", {}));
  };
  interface GetStartup {
    getStartup: StartupBO;
  }

  const handleCompleteGetStartup = (data: GetStartup) => {
    const startup = _.get(session, "startup", null);
    let newStartup = _.get(data, "getStartup");
    let allowedActions = _.get(data, "getUserActionsByStartup.allowedActions");
    let role = _.get(data, "getUserActionsByStartup.profile.role");
    let startupToSet = { ...startup, ...newStartup };
    _.set(startupToSet, "allowedActions", allowedActions);
    _.set(startupToSet, "userRol", role);
    setStartup(startupToSet);
    if (redirectAfterLoading) {
      goToPage(dispatch, redirectAfterLoading);
      setRedirectAfterLoading(null);
    }
  };

  const hasOverduePayment = (startup) => {
    return _.get(startup,  "activeSubscriptionPlan.activeSubscriptionData.mustPayInvoice")
  }

  const { manualQuery: getMe, loading: loadingGetMe } = useGetMe(null, {
    onCompleted: handleCompleteGetMe,
  });

  const {
    manualQuery: getStartup,
    loading: loadingStartup,
  } = useGetStartupAndActionsLazy(null, {
    onCompleted: handleCompleteGetStartup,
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true

  });

  const session = useSelector((state) => _.get(state, "session"));
 
  const initSession = async (token: string, lastLogin: Date) => {
    await loginAction(dispatch, token, lastLogin);
  };

  const selectStartup = async (startupId: string, page: any) => {
    setRedirectAfterLoading(page);
    setLoadingAction(dispatch);
    await getStartup({ variables: { startupId } });
  };

  const setToken = (token: string) => {
    setTokenAction(dispatch, token);
  };

  const clearSession = () => {
    clearSessionAction(dispatch);
  };
  const setMoreOneStartup = (moreOneStartup: boolean) => {
    setMoreOneStartupAction(dispatch, moreOneStartup);
  };
  const setUser = (user: any) => {
    setUserAction(dispatch, user);
  };

  const setStartup = (startup: any) => {
    setStartupAction(dispatch, startup, hasOverduePayment(startup));
  };

  const updateHasOverduePayment = (hasOverduePayment: boolean) => {
    updatePaymentHasOverduePayment(dispatch, hasOverduePayment)
  }

  const updateUser = (user: any) => {
    updateUserAction(dispatch, user);
  };

  const updateStartup = (startup: any) => {
    updateStartupAction(dispatch, startup);
  };

  const setPageLoaded = (page: string) => {
    setPageLoadedAction(dispatch, page);
  };

  const setExpandedHeader = () => {
    setExpandedHeaderAction(dispatch);
  };

  const updateUserData = () => {
    getMe();
  };

  const clearCache = () => {
    const client = ServerConnect.getUniqueApolloClient();
    if (client) {
      client.clearStore();
    }
  }
  const getGroupCompany = (groupCompanyId) => {
    const startup= _.get(session, "startup", null);
    const groupCompany = _.get(startup, "groupCompanies", []).find(
      (groupCompany) => groupCompany.id === groupCompanyId
    );
    return groupCompany;
  }
  return {
    session,
    user: _.get(session, "user", null),
    payment: _.get(session, "payment", null),
    startupId: _.get(session, "startup.id", null),
    startup: _.get(session, "startup", null),
    isLoading: loadingStartup || loadingGetMe,
    selectStartup,
    setToken,
    clearSession,
    initSession,
    setUser,
    updateUser,
    setPageLoaded,
    setStartup,
    updateStartup,
    expandedHeader: _.get(session, "expandedHeader", false),
    setExpandedHeader,
    clearCache,
    setMoreOneStartup,
    updateHasOverduePayment,
    getGroupCompany,
    updateUserData
  };
}

export default useSession;

import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TextInput from 'src/components/inputs/Text/TextInput';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateLabel from 'src/modules/generic/components/Inputs/LabelInput';
import * as Yup from 'yup';
import CountrySelector from "src/components/inputs/Select/CountrySelector/CountrySelector";
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText } from 'src/modules/generic/store/action';
import SelectInput from 'src/components/inputs/Select/SelectInput/SelectInput';
import AddressInput from "src/components/forms/modules/startups/individualStakeholder/AddressInput";

function AddFormStep() {
  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.AddFormStep ?? {}
  }, [context]);

  const handleCancel = (values) => {
    cancel();
  }

  const handleSubmit = async (values) => {
    next(values)
  }

  useEffect(() => {
    return () => {
      dispatch(clearAssistatText())
    }
  }, [dispatch])

  const schema = Yup.object().shape({
    ultimateBeneficialOwnerName: Yup.string().required('This field is required'),
    mailingAddressType: Yup.string().required('This field is required').typeError('This field is required'),
    mailingAddress: Yup.object().shape({
      country: Yup.string().required('This field is required').typeError('This field is required'),
      street: Yup.string().required('This field is required'),
      city: Yup.string().required('This field is required'),
      state: Yup.string().nullable(),
      zipCode: Yup.string().nullable(),
    }),
    tin: Yup.string().nullable().optional(),
    taxJurisdiction: Yup.string().required('This field is required').typeError('This field is required'),
  });

  const mailingAddressTypeOptions = useMemo(() => {
    return [
      { value: "BUSINESS", label: 'Business' },
      { value: "RESIDENTIAL", label: 'Residential' }
    ];
  }, []);  

  const ultimateBeneficialOwnershipType = useMemo(() => {
    return [
      { value: 'LP_OWNERSHIP', label: 'Legal person (ownership)' },
      { value: 'LP_SENIOR_MANAGING_OFFICIAL', label: 'Legal person – senior managing official' },
      { value: 'LP_OTHER_MEANS', label: 'Legal person (other means)' }
    ];
  }, []);

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: translate('MODULES.RENEWALS.BENEFICIAL_OWNERS.GENERIC.TITLE2'),
          skeleton: null,
          variant: 'simple'
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('GENERIC_BUTTON_ADD_GROUP')}</SubmitButton>,
        }}
      >
        <>
          <TemplateLabel label={translate('MODULES.RENEWALS.BENEFICIAL_OWNERS.ADD_FORM.LABEL1')} isRequired>
            <ControllerInput
              render={TextInput}
              name='ultimateBeneficialOwnerName'
              placeholder={'Type here'}
            />
          </TemplateLabel>
          <TemplateLabel label={translate('MODULES.RENEWALS.BENEFICIAL_OWNERS.ADD_FORM.LABEL6')} isRequired>
            <ControllerInput
              render={SelectInput}
              name='ultimateBeneficialOwnerType'
              placeholder={translate("SELECT_INPUT_PLACEHOLDER")}
              list={ultimateBeneficialOwnershipType}
              clear={true}
            />
          </TemplateLabel>
          <TemplateLabel label={translate('MODULES.RENEWALS.BENEFICIAL_OWNERS.ADD_FORM.LABEL2')} isRequired>
            <ControllerInput
              render={SelectInput}
              placeholder={translate("SELECT_INPUT_PLACEHOLDER")}
              list={mailingAddressTypeOptions}
              name="mailingAddressType"
              clear={true}
            />
          </TemplateLabel>
          <AddressInput
            name="mailingAddress"
            label={{ country: translate('MODULES.RENEWALS.BENEFICIAL_OWNERS.ADD_FORM.LABEL3') }}
            zipCodeOptional
            isRequired
            onChange={() => { }}
          />
          <TemplateLabel label={translate('MODULES.RENEWALS.BENEFICIAL_OWNERS.ADD_FORM.LABEL4')} isRequired>
            <ControllerInput
              render={CountrySelector}
              placeholder={translate("SELECT_INPUT_PLACEHOLDER")}
              name="taxJurisdiction"
            />
          </TemplateLabel>
          <TemplateLabel label={translate('MODULES.RENEWALS.BENEFICIAL_OWNERS.ADD_FORM.LABEL5')}>
            <ControllerInput
              render={TextInput}
              name='tin'
              placeholder={'Type here'}
            />
          </TemplateLabel>
        </>
        <div>&nbsp;</div>
      </TemplateWizard>
    </Form>
  );
}

export default AddFormStep;


import _ from "lodash";
import React, { FC, useMemo } from "react";
import * as Yup from "yup";
import Form from "../../../../components/forms/Form/Form";
import useGetStakeholders from "../../../../hooks/services/modules/stakeholders/useGetStakeholders";
import SubmitButton from "../../../../modules/generic/components/Buttons/SubmitButton";
import CheckboxButtonSimple from "../../../../modules/generic/components/Inputs/CheckboxButtonSimple";
import ControllerInput from "../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import FormProps from "../../../../modules/generic/forms/interfaces/FormProps";
import useTranslate from "../../../../modules/generic/hooks/useTranslate";
import TemplateForm from "./TemplateForm";
import useSession from "../../../session/hooks/useSession";
import classModule from "./classes.module.scss";
export class StakeholderOptions {
  public value: string;
  public label: string;
}
interface FormPrimaryContactProps extends FormProps {
  groupCompanyId: String;
}

const FormPrimaryContact: FC<FormPrimaryContactProps> = ({
  initialValues,
  groupCompanyId,
  onCompleteSubmit,
  Template = TemplateForm,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
}: FormPrimaryContactProps) => {

  const { translate } = useTranslate();
  const { startupId } = useSession();

  const schema = Yup.object().shape({
    authorisedSignatories: Yup.string().required("Required"),
  });

  const handleSubmit = async (values) => {
    onCompleteSubmit && (await onCompleteSubmit(values));
  };
  
  const { data } = useGetStakeholders({
    variables: { startupId, groupCompanyId, filters: {"role": ["DIRECTOR","SHAREHOLDER"]} },
  });

  const stakeholders = useMemo(
    () =>
      _.map(data, (st) => {
        return {
          value: st.id,
          label: st.fullName,
          componentProps: {
            component: "h4",
            weight: "semibold",
          },
        };
      }),
    [JSON.stringify(data)]
  );

  return (
    <React.Fragment>
      <Form
        initialValues={initialValues}
        schema={schema}
        onSubmit={handleSubmit}
        optionsForm={{ mode: "onChange" }}
      >
        <Template
          buttons={{
            ...buttons,
            submit: (
              <SubmitButton {...buttonSubmitProps}>
                {translate(`MODULES.MAP.WIZARD_CONTINUE_BUTTON`)}
              </SubmitButton>
            ),
          }}
          props={propsTemplate}
        >
          <ControllerInput
            as={CheckboxButtonSimple}
            className={classModule.CheckboxButtonSimple}
            classNameButton={classModule.CheckboxButtonSimpleButton}
            isMultiple={false}
            name="authorisedSignatories"
            direction="column"
            options={stakeholders}
          />
        </Template>
      </Form>
    </React.Fragment>
  );
};

export default FormPrimaryContact;

import React from 'react';
import { Modal } from '../../../../../../../v1/components';
import { Constants } from "../../../../../../../v1/utils";
import Delete from "./Delete";

const DeleteUploadDocumentModal = ({ open, handleCloseModal, data, onComplete, goTo, redirect, ...props }) => {

    return (
        <Modal
            open={open} 
            overlayName={Constants.FORM_TYPES.GROUP_COMPANY.COMPANY_CONNECTED_GRANTS} handleClose={() => handleCloseModal()} showHeader={false}>
            <Delete open={open} handleCloseModal={handleCloseModal} onComplete={onComplete} redirect={redirect} goTo={goTo} data={data} {...props}/>
        </Modal>
    )
}

export default DeleteUploadDocumentModal;

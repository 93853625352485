import React, {useEffect, useState} from 'react';
import {ServerConnect} from "../utils";
import {Constants} from "../utils/constants";
import helper from "../utils/helper";
import mustache from "mustache";
import _ from "lodash";
import {useParams} from 'react-router-dom';

const query = `{
    getMatterHistory({{{filters}}} ) {
      createdAt
      data
      user {
        fullName
        firstName
        userRole
      }
      transaction{
        constant
        label
      }
      labelValues
    }
  }`
const useMatterLogs = (newPage, newFilters) => {
  const [timeLine, setTimeLine] = useState([]);
  const [limit, setLimit] = useState(Constants.CONFIG.INFINITE_SCROLL_LIMIT_DEFAULT);
  const [cursorId, setCursorId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState(null);
  const [page, setPage] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(null);

  const params = useParams();

  useEffect(() => {
    let reload = false;
    let currentCursorId = cursorId;
    if (page !== newPage) {
      reload = true;
    }
    if (!_.isEqual(newFilters, filters)) {
      reload = true;
      currentCursorId = null;
      setTimeLine([]);
      setCursorId(null);
    }
    if (reload) {
      setFilters(newFilters);
      setPage(newPage);
      setLoading(true);
      const filtersQuery = {
        transactions: _.get(newFilters, "transactions.length", 0) > 0 ? newFilters.transactions : null,
        dateFrom: newFilters.dateFrom,
        dateTo: newFilters.dateTo,
        limit,
        cursorId: currentCursorId,
        matterId: params.id
      }

      const queryParams = mustache.render(query, { filters: helper.jsonToStringParameters(filtersQuery) });
      ServerConnect.graphQlQuery(queryParams).then(({ getMatterHistory }) => {
        let timeLinesServerLocal = newPage === 0 ? [] : timeLine;
        timeLinesServerLocal = timeLinesServerLocal.concat(getMatterHistory);
        setTimeLine(_.reverse(timeLinesServerLocal));
       // setHasNextPage(result.getTimeline.length > 0);
        setLoading(false);
    //    if (result.getTimeline.length > 0) {
      //    setCursorId(result.getTimeline[result.getTimeline.length - 1]._id)
       // }

      });
    }

  }, [newPage, newFilters]);

  return { timeLine, loading, hasNextPage };
}


export default useMatterLogs;
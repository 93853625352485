import _ from "lodash";
import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import useGetMe from "./../../../modules/session/graphql/useGetMe";
import useSession from "./../../../modules/session/hooks/useSession";
import { loadState, saveState } from "../../../session";
import { Constants } from "../../utils";

const SessionDaraManagerWithToken: FC = () => {
  const { setUser } = useSession();
  const handleCompleteGetMe = (data) => {
    setUser(_.get(data, "getMe", {}));
  };
  const { manualQuery: getMe, loading: loadingGetMe } = useGetMe(null, {
    onCompleted: handleCompleteGetMe,
  });

  const session = useSelector((state) => _.get(state, "session"));

  useEffect(() => {
    if (_.get(session, "token") && !_.get(session, "user")) {
      if (!loadingGetMe) {
        getMe().then((data) => {
          const user = _.get(data, "data.getMe");

          if (user && window.heap) {
            window.heap.identify(user.email);
          }
        });
      }
    }
  }, [_.get(session, "token"), JSON.stringify(_.get(session, "user"))]);
  const compareLocalSotarageVersion = async () => {
    const releaseVersion = loadState("releaseVersion");
    const session = loadState("session");

    if (releaseVersion !== _.get(window?.env, "releaseVersion")) {
      saveState("releaseVersion", _.get(window?.env, "releaseVersion"));
      if (_.get(session, "user")) {
        window.location = Constants.PAGES.logout.path;
      }
    }
  };
  useEffect(() => {
    if (window?.env?.releaseVersion) {
      compareLocalSotarageVersion();
    }
  }, [window?.env?.releaseVersion]);
  return null;
};

export default SessionDaraManagerWithToken;

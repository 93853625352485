import React, { useEffect } from 'react';
import useMutation from "../../../custom/useMutation"

const mutation = `mutation removePendingEntity($startupId : ID!,$entityType : EntityType){
    removePendingEntity(startupId: $startupId,entityType: $entityType)
}`;

const useRemovePendingEntity = (options, props) => {

    const refetchQueries = ['companyCorporateMap']

    const [send, loading] = useMutation(mutation, options, {...props, refetchQueries });

    return [send, loading];
}
export default useRemovePendingEntity;

import { CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import _ from "lodash";
import useAddCreditCardToAStartup from "src/modules/checkout/hooks/useAddCreditCardToAStartup";
import useFinish3DSPaymentToUpgradeStartup from "src/modules/checkout/hooks/useFinish3DSPaymentToUpgradeStartup";
import useInit3DSPaymentToUpgradeStartup from "src/modules/checkout/hooks/useInit3DSPaymentToUpgradeStartup";
import usePayWithExistingCreditCard from "src/modules/checkout/hooks/usePayWithExistingCreditCard";
import useUpdateCreditCardToAStartup from "src/modules/checkout/hooks/useUpdateCreditCardToAStartup";
import useSession from "src/modules/session/hooks/useSession";

export const PAYMENT_STATUS = {
  success: "success",
  error: "error",
  process: "process",
  input: "input",
};

const usePaymentMethodStripe = ({ shouldRefetchQuery = true }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { startup } = useSession();

  const [paymentSave] = useInit3DSPaymentToUpgradeStartup({
    showSuccessNotification: false,
    showErrorNotification: false,
    shouldRefetchQuery: () => false,
  });

  const [paymentCardSave] = usePayWithExistingCreditCard({
    showSuccessNotification: false,
    showErrorNotification: false,
    shouldRefetchQuery: () => false,
  });

  const [finishPayment] = useFinish3DSPaymentToUpgradeStartup({
    showSuccessNotification: false,
    showErrorNotification: false,
  });

  const [addCard] = useAddCreditCardToAStartup({
    showSuccessNotification: false,
    showErrorNotification: false,
    shouldRefetchQuery: () => false
  });

  const [updateCard] = useUpdateCreditCardToAStartup({
    showSuccessNotification: false,
    showErrorNotification: false,
    shouldRefetchQuery: () => false
  });

  const addUpdateCreditCard = async (values, { cardId }) => {
    try {
      const paymentValues = {
        creditCardToken: "stripe",
        "billingAddress": {
          "country": _.get(values, "country"),
          "addressLine1": _.get(values, "address1"),
          "addressLine2": _.get(values, "address2"),
          "city": _.get(values, "city"),
          "state": _.get(values, "state"),
          "zipCode": _.get(values, "zipCode")
        },
        cardHolderName: _.get(values, "holderName"),
        productCode: _.get(startup, "activeSubscriptionPlan.code"),
        startupId: _.get(startup, "id"),
      };
      if (_.get(values, "onBehalfOfAVATRegisteredCompany")) {
        paymentValues.billingAddress.onBehalfOfAVATRegisteredCompany = _.get(values, "onBehalfOfAVATRegisteredCompany")
      }
      if (cardId) {
        await updateCard({
          ...paymentValues,
          creditCardId: cardId,
        });
      } else {
        const result = await addCard(paymentValues);
        const clientSecret = result?.data?.addCreditCardToAStartup;
        const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
          payment_method: {
            card: elements.getElement(CardNumberElement),
          },
        });
        console.log("**** setupIntent", { error, setupIntent })
        if (error) {
          console.log("**** Error", error)
          throw new Error(error.message,)
        }
        await finishPayment({ ckoSessionId: setupIntent?.id, paymentSource: "STARTUP" });
      }
      return { url: null, status: PAYMENT_STATUS.success };
    } catch (e) {
      throw new Error(e)
    }
  };

  const paymentFull = async (values, { cardId, source, couponCode, product, id }) => {
    try {
      const paymentValues = {
        creditCardToken: "stripe",
        "billingAddress": {
          "country": _.get(values, "country"),
          "addressLine1": _.get(values, "address1"),
          "addressLine2": _.get(values, "address2"),
          "city": _.get(values, "city"),
          "state": _.get(values, "state"),
          "zipCode": _.get(values, "zipCode")
        },
        "couponCode": couponCode,
        productCode: _.get(product, "code"),
        relatedItemId: id,
        source: source
      };
      if (cardId) {
        paymentValues.creditCardId = cardId;
      } else {
        paymentValues.cardHolderName = _.get(values, "holderName");
      }
      if (_.get(values, "onBehalfOfAVATRegisteredCompany")) {
        paymentValues.billingAddress.onBehalfOfAVATRegisteredCompany = _.get(values, "onBehalfOfAVATRegisteredCompany")
      }
      let result = null;
      let clientSecret = result?.data?.initNewCreditCardPayment;
      if (cardId) {
        result = await paymentCardSave(paymentValues);
        return { url: null, status: PAYMENT_STATUS.success };
      } else {
        result = await paymentSave(paymentValues);
        clientSecret = result?.data?.initNewCreditCardPayment;
        if (clientSecret) {
          try {
            let error, intent;
            const isNoPay = clientSecret.indexOf("seti_") === 0;
            if (isNoPay) {
              const { error: _error, setupIntent: _setupIntent } = await stripe.confirmCardSetup(clientSecret, {
                payment_method: {
                  card: elements.getElement(CardNumberElement),
                },
              });
              error = _error;
              intent = _setupIntent;
              if (error) {
                console.log("**** Error", error)
                throw new Error(error.message,)
              }
              await finishPayment({ ckoSessionId: intent?.id, paymentSource: source });
            } else {
              const { error: _error, paymentIntent: _paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                  card: elements.getElement(CardNumberElement),
                },
              });
              error = _error;
              intent = _paymentIntent;
              if (error) {
                console.log("**** Error", error)
                throw new Error(error.message,)
              }
              await finishPayment({ ckoSessionId: intent?.id, paymentSource: source });
            }
            return { url: null, status: PAYMENT_STATUS.success, id: intent?.id };
          } catch (e) {
            console.log("**** Error", e)
            throw new Error(e.message,)
          }
        } else {
          throw new Error("No payment result")
        }
      }
    } catch (e) {
      throw new Error(e)
    }
  };
  
  return {
    paymentFull,
    addUpdateCreditCard,
  }
}

export default usePaymentMethodStripe;
import { default as React, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import Form from "src/components/forms/Form/Form";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import Button from "src/modules/generic/components/Buttons/Button";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import {
  clearAssistatText,
  addAssistantText,
} from "src/modules/generic/store/action";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import ClaraList from "src/modules/generic/components/Lists/ClaraList";
import Typography from "src/modules/generic/components/Texts/Typography";
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import cls from "src/views/modules/startups/renewal/adgm/modals/ReviewAuthorisedSignatory/wizard/steps/ReviewAndConfirmStep/classes.module.scss";

function ReviewCorporateStakeholderDirectorsStep() {
  const { next, context, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const [showAddDirectorsMessage, setShowAddDirectorsMessage] = useState(false);

  const stakeholderName: string = context?.initialData?.stakeholder?.fullName;
  const addDirectorsMessage: string =
    "Please use the blue plus button on the header to add new directors or go to their details page to remove them. Fees apply.";

  const initialValues = useMemo(() => {
    return context?.ReviewDirectors ?? {};
  }, [context]);

  const directorsList = context?.initialData?.stakeholder?.directors.map( director => {
      return { name: director?.fullName, avatar: director?.avatar ?? director?.fullName, isFounder: director?.isFounder };
    });

  const handleCancel = () => {
    cancel();
  };

  const handlePrev = () => {
    prev();
  };

  const handleSubmit = async (values) => {
    next(values);
  };

  useEffect(() => {
    dispatch(
      addAssistantText(
        translate(
          "MODULES.RENEWALS_ADGM.REVIEW_STAKEHOLDER_DETAILS.CORPORATE.COMMON.TOOLTIP"
        ),
        "alert"
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const schema = Yup.object().shape({
    confirm: Yup.boolean().required("Required"),
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: translate(
            "MODULES.RENEWALS_ADGM.REVIEW_STAKEHOLDER_DETAILS.CORPORATE.DIRECTORS.TITLE"
          ),
          subTitle: translate(
            "MODULES.RENEWALS_ADGM.REVIEW_STAKEHOLDER_DETAILS.CORPORATE.COMMON.SUBTITLE",
            { stakeholderName }
          ),
          skeleton: null,
          variant: "simple",
        }}
        buttons={{
          previous: (
            <Button variant="secondary" onClick={handlePrev}>
              {translate("BUTTON_PREVIOUS")}
            </Button>
          ),
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("BUTTON_CANCEL")}
            </Button>
          ),
          submit: <SubmitButton>{translate("BUTTON_NEXT")}</SubmitButton>,
        }}
      >
        <Typography component="p" weight="regular" variant="body">
          {translate(
            "MODULES.RENEWALS_ADGM.REVIEW_STAKEHOLDER_DETAILS.CORPORATE.DIRECTORS.DESCRIPTION"
          )}
        </Typography>
        <ClaraList variant="normal" items={directorsList} />
        <TemplateLabel
          label="Please confirm this is correct"
          isRequired
          className={cls.labelOnTop}
        >
          <ControllerInput
            name="confirm"
            render={CheckboxButtonSimple}
            onChange={(val) => setShowAddDirectorsMessage(!val)}
            className={cls.CheckboxButton}
            classNameButton={cls.buttonCheckBox}
          />
        </TemplateLabel>
        <Typography component="p" weight="bold" variant="body">
          {showAddDirectorsMessage && (
            <span dangerouslySetInnerHTML={{ __html: addDirectorsMessage }} />
          )}
        </Typography>
      </TemplateWizard>
    </Form>
  );
}

export default ReviewCorporateStakeholderDirectorsStep;

import _ from 'lodash';
import useMachine from '../../../../../generic/context/MachineContext/useMachine';
import SelectEquityForm from '../../../forms/SelectEquityForm';
import React, { useEffect, useState } from 'react';
import Button from "../../../../../generic/components/Buttons/Button";
import useTranslate from '../../../../../generic/hooks/useTranslate';
import { useDispatch } from "react-redux";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import { addAssistantText, clearAssistatText } from "../../../../../generic/store/action";
import useModal from "../../../../../generic/hooks/useModal";
import IsThereMoreEquity from '../../../forms/IsThereMoreEquityForm';

function SelectEquityType({ openParam }) {

  const { next, send, context, prev } = useMachine();
  const { translate } = useTranslate()
  const dispatch = useDispatch();
  const { closeModal: onClose } = useModal();
  const [open, setOpen] = useState(openParam);

  const handleCancel = (values) => {
    onClose();
    setOpen(false);
    clearAssistatText()
  }

  useEffect(() => {
    setOpen(openParam);
  }, [openParam]);

  const handlePrevious = () => {
    prev()
  }

  const handleInitModal = () => {
    dispatch(clearAssistatText());    
    dispatch(addAssistantText(translate('MODAL_EQUITY_POSITIONS_ADD_MORE_TOOLTIP')));
  }

  useEffect(() => {
    handleInitModal()
  }, []);

  const handleCompleteSubmit = async (values) => {
    next(values)
  }

  return (
    <IsThereMoreEquity
      initialValues={{}}
      buttons={{
        cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
      }}
      onCompleteSubmit={handleCompleteSubmit}
      propsTemplate={{
        title: translate('MODULES.EQUITY.ADD_EQUITY_TASK.STEP_TO_CONTINUE.TITLE'),
        subTitle: translate('MODULES.EQUITY.ADD_EQUITY_TASK.STEP_TO_CONTINUE.SUBTITLE')
      }} 
      Template={TemplateWizard}
    />
  );
}
export default SelectEquityType;
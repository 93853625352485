import _ from 'lodash';

const useGuards = (initialValues) => {
  const isTherePassport = (context) => context?.IsTherePassport?.isThereOtherPassport;
  return {
    isTherePassport,
    isNotTherePassport: (context) => !isTherePassport(context),
  }
}

export default useGuards;
import INDIVIDUAL_EQUITY_FORM from '../IndividualEquityForm/langs/index'
import GENERATE_OR_CREATE_EQUITY from '../GenerateOrCreateEquity/langs/index'
import SELECT_COMPANIES from '../SelectCompanies/langs/index'
import UPLOAD_DOCUMENTS from '../UploadDocuments/langs/index'
import UPLOAD_DOCUMENTS_FORM from '../UploadDocumentsForm/langs/index'
import INDIVIDUAL_OR_COMPANY_FORM from '../IndividualOrCompanyStakeholderForm/langs/index'
import INDIVIDUAL_OR_COMPANY from '../IsIndividualOrCompany/langs/index'
import IS_THERE_ENTITY from '../IsThereEntity/langs/index'
import ARE_THERE_MORE_ENTITY from '../AreThereMoreEntity/langs/index'
import CLARA_CONCIERGE_INDIVIDUAL from '../ClaraConciergeIndividual/langs/index'


export default {
    INDIVIDUAL_EQUITY_FORM:INDIVIDUAL_EQUITY_FORM.US,
    GENERATE_OR_CREATE_EQUITY:GENERATE_OR_CREATE_EQUITY.US,
    SELECT_COMPANIES:SELECT_COMPANIES.US,
    UPLOAD_DOCUMENTS:UPLOAD_DOCUMENTS.US,
    UPLOAD_DOCUMENTS_FORM:UPLOAD_DOCUMENTS_FORM.US,
    INDIVIDUAL_OR_COMPANY_FORM:INDIVIDUAL_OR_COMPANY_FORM.US,
    INDIVIDUAL_OR_COMPANY:INDIVIDUAL_OR_COMPANY.US,
    ARE_THERE_MORE_ENTITY:ARE_THERE_MORE_ENTITY.US,
    IS_THERE_ENTITY:IS_THERE_ENTITY.US,
    CLARA_CONCIERGE_INDIVIDUAL:CLARA_CONCIERGE_INDIVIDUAL.US,
}
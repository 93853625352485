import { useEffect, useState } from "react";
import _ from "lodash";
import useCountryList from "../../../../hooks/services/generic/useCountryList";
import { CountryType, Jurisdiction } from "@interfaces/country";
//import { COUNTRY_WITH_MORE_JURISDICTIONS } from "@constants/country";

const COUNTRY_WITH_MORE_JURISDICTIONS = ["AE", "GB", "US"];


interface CountryDefault {
  label: string;
  value: string;
  alpha3: string;
}

interface JurisdictionDefault {
  label: string;
  value: string;
}

const parseCountryDefault = ({
  code,
  name,
  alpha3,
}: CountryType): CountryDefault => ({ label: name, value: code, alpha3 });

const parseJurisdictionDefault = ({
  name,
}: Jurisdiction): JurisdictionDefault => ({ label: name, value: name });

function useGetCountryAndJurisdiction() {
  // review in no-cache
  const { loading, data } = useCountryList(
    {
      code: "",
      name: "",
      language: "en",
      orderBy: "FORMATION",
    },
    { fetchPolicy: "no-cache" }
  );

  const [countriesList, setCountriesList] = useState([]);
  const [jurisdictionsList, setJurisdioctionsList] = useState({});
  const [loadingList, setLoadingList] = useState(true);

  const mapCountries = (countries: CountryType[]) => {
    return _.map(countries, (country) => parseCountryDefault(country));
  };

  const mapJurisdictions = (countries: CountryType[]) => {
    let jurisdictions = {};
    _.forEach(countries, (country: CountryType) => {
      jurisdictions[country.code] = country.jurisdictions.map(
        parseJurisdictionDefault
      );
      if (COUNTRY_WITH_MORE_JURISDICTIONS.includes(country.code)) {
        jurisdictions[country.code].push({
          label: "Other jurisdiction",
          value: "OTHER",
        });
      }
    });
    return jurisdictions;
  };

  useEffect(() => {
    if (!loading) {
      const countries: CountryType[] = _.get(data, "countryList", []);

      setCountriesList(mapCountries(countries));
      setJurisdioctionsList(mapJurisdictions(countries));
      setLoadingList(false);
    }
  }, [loading]);

  return { countriesList, jurisdictionsList, loading: loadingList };
}

export default useGetCountryAndJurisdiction;

import Button from "./../../../../../../../modules/generic/components/Buttons/Button";
import ClaraAvatar from 'src/modules/generic/components/Avatars/ClaraAvatar';
import { Constants } from "../../../../../../../v1/utils/constants";
import Icon from '../../../../../../../modules/generic/components/Icon';
import React from 'react';
import Text from '../../../../../../../components/text/Text/Text';
import Truncate from "../../../../../../generic/components/Texts/Truncate";
import Typography from "../../../../../../generic/components/Texts/Typography";
import classesModule from "./HeaderCardCompany.module.scss";
import { goToPage } from "./../../../../../../../store/actions/page";
import { useDispatch } from "react-redux";
import Tag from "src/v1/components/Tag/Tag";
import Box from '@mui/material/Box';

const HeaderCardCompany = ({
  companyName,
  subtitle,
  avatar,
  current,
  totals,
  level = 1,
  isHoldingCompany,
  lock,
  id,
  statusFormation = false,
  isInactive = false,
  tasks
}) => {

  const classHoldingCompany = isHoldingCompany ? classesModule.HoldingCompany : null;
  const classLevel = classesModule[`Level_${level}`];
  const dispatch = useDispatch();

  const handleIcon = () => {
    if (isHoldingCompany) {
      if (statusFormation) {
        return "wait";
      } else {
        return "square";
      }
    } else {
      if (statusFormation) {
        return "formCircle";
      } else {
        return "circle";
      }
    }
  }

  const handleIconVariant = () => {
    let solution = "medium";
    if (isHoldingCompany) {
      solution =  "xl";
    } else {
      if (level === 3) {
        return "small";
      }
    }
    return solution;
  }

  const handleViewMore = (event) => {
    goToPage(dispatch, Constants.PAGES.viewGroupCompany, { id: id, tab: "details" });
    event.preventDefault()
  }

  return (
    <React.Fragment>
      <div
        className={`${classesModule.HeaderCardCompany} ${classHoldingCompany} ${classLevel}`}
      >
        <span class={classesModule.Cursor} onClick={handleViewMore} >
          <ClaraAvatar
            name={companyName}
            avatar={avatar}
            type={handleIcon()}
            locked={lock}
            variant={handleIconVariant()}
          >
          </ClaraAvatar>
        </span>
        <div className={classesModule.HeaderTitle}>
          <div>
            <span onClick={handleViewMore} class={classesModule.Cursor} >
              <Typography
                variant={"body"}
                weight={"bold"}
                color={"secondary"}
                component={"h1"}
              >
                <Truncate line={2} text={companyName} />
              </Typography>
            </span>
            <Typography variant={"s"} weight={"light"} color={"dark-grey"}>
              <Truncate line={1} text={subtitle} />
            </Typography>



            <Box
              sx={{
                with: "100%",
                display: "flex",
                alignItems: "center",
                gap: "4px",
                paddingTop: "4px",
              }}
            >
              {isInactive && (
                <Tag type={"USER_INACTIVE"} show={true} marginLeft="0px" />
              )}
            </Box>




          </div>
          <div>
            <div className={classesModule.Icons}>
              <div className={classesModule.ZoneIcons}>
                <div>
                <Icon
                    isClara
                    icon={"complete-and-incomplete"}
                    size={"16px"}
                  />
                  <Typography variant={"xs"} weight={"light"} color={"black"}>{tasks}</Typography>
                </div>
                <div>
                  <Icon isClara icon={"Stakeholder-icon"} size={"16px"} />
                  <span>
                    <Typography variant={"xs"} weight={"bold"} color={"black"}>{current}/{totals}</Typography>
                  </span>
                </div>
              </div>
              <div className={classesModule.ButtonViewProfie}>
                <Button variant={'card'} onClick={handleViewMore} >
                  <Text uuid={'BUTTON_VIEW_PROFILE'} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment >
  );
};

export default HeaderCardCompany;

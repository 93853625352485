

import { get, omit, find, some } from 'lodash'
import { stripTypenames } from "src/v1/utils/graphql-util";
import moment from "moment";
import { useMemo } from "react";
import { useParams } from "react-router";
import useRegisterAsCheckedTask from "src/modules/generic/graphql/mutation/useRegisterAsCheckedTask";
import useSession from 'src/modules/session/hooks/useSession';
import useUpsertConvertible from "src/modules/startup/equity/graphql/mutation/upsertConvertible";
import useUpsertEquity from "src/modules/startup/equity/graphql/mutation/upsertEquity";
import useGetDocumentsListLazy from '../../graphql/queries/useGetDocumentsListLazy';
import useGetStakeholdersGroupCompaniesLazy from '../../graphql/queries/useGetStakeholdersGroupCompaniesLazy';
import useUpsertSipDocument from '../../graphql/mutation/upsertSipDocument';
import _ from "lodash"
import {useCompanyDetailsLazy} from 'src/hooks/services/modules/groupCompany/useGetCompanyDetails';
const useServices = () => {
  const { manualQuery: manualQueryStakeholdersGroupCompanies } = useGetStakeholdersGroupCompaniesLazy();
  const { manualQuery: manualQueryDocuments } = useGetDocumentsListLazy();
  const { mutation } = useUpsertEquity({ showSuccessNotification: false });
  const { manualQuery: getCompanyDetails } = useCompanyDetailsLazy();
  const { mutation: registerAsCheckedTask } = useRegisterAsCheckedTask({
    showSuccessNotification: false
  })

  const { upsertConvertible } = useUpsertConvertible({ showSuccessNotification: false });
  const { upsertSipDocument } = useUpsertSipDocument({ showSuccessNotification: false });
  const { startupId } = useSession();

  const { id } = useParams<{ id: string }>();
  const getCurrency = (id, groupCompanies) => {
    const company = find(groupCompanies, { id })
    return company.transactionCurrency || 'USD'
  }
  const getType = (id, stakeholdersGroupCompanies) => {
    return some(stakeholdersGroupCompanies.stakeholders, { id }) ? 'stakeholder' : 'groupCompany'
  }
  const getDocuments = (values) => {
    if (values) {
      if (values.file || values.document) {
        if (values.file) return { uploadedFiles: [{ ...values.file, title: values.name, documentType: values.documentType }] }
        return { documentsUploaded: [{ ...values.document }] }
      }
    }
    return {}
  }
  return useMemo(() => ({

    getStakeholdersGroupCompanies: async (context, event) => {

      const stakeholdersGroupCompanies = await manualQueryStakeholdersGroupCompanies()
      const result = {
        stakeholders: get(stakeholdersGroupCompanies.data, 'stakeholderList', []),
        groupCompanies: get(stakeholdersGroupCompanies.data, 'getGroupCompanies', [])
      };
      return result;
    },
    getDocumentList: async (context, event) => {
      const documents = await manualQueryDocuments()
      const result = {
        documents: get(documents, 'data.documentList', [])
      };
      
      return result;
    },
    getDocumentListOptionSIP: async (context, event) => {

      const { groupCompanies } = context 
      let index = get(context, 'iterations', 1) - 1;
      if (index < 0) index = 0;
      const groupCompany = groupCompanies[index]
      const companyData = await getCompanyDetails({
        variables:{ startupId, groupCompanyId: groupCompany.id }
      })
      let shareClassList = _.get(companyData, 'data.getGroupCompany.shareClass', []);
      let manualSips = [];
      _.forEach(shareClassList, sc => {
        _.forEach(sc.shareIncentivePlans, sip => {
          if (!_.get(sip, 'document.id', null)) {
            manualSips.push({ ...sip, shareClass: sc, isManual: true })
          }
        })
      });
      return {manualSips};
    },
    upsertConvertible: async (context, event) => {
      try{
      const values = get(event, 'payload.values');
      const { equity } = context;
      const { groupCompanies } = context
      let index = get(context, 'iterations', 1) - 1;
      if (index < 0) index = 0;
      const groupCompany = groupCompanies[index]
      const documents = getDocuments(values)
      
      const variables = {
        startupId,

        owner: { id, type: "stakeholder" },
        convertibleData: {
          ...equity,
          issuedDate: equity.issuedDate ? moment(equity.issuedDate).format('YYYY-MM-DD') : null,
          currency: getCurrency(groupCompany.id, context.getStakeholdersGroupCompanies.groupCompanies),
          groupCompany: groupCompany.id,
          documents,
        },
        groupCompanyId: groupCompany.id,
      };
      if (context.documents === "agreement") {
        variables.convertibleData.isThereAgreement = true
      }
      const resultConvertible = await upsertConvertible({ variables });
      if (context.documents === "agreement") {
        await registerAsCheckedTask({
          startupId,
          registeredTaskData: {
            entityId: resultConvertible.data.upsertConvertible.id,
            taskCode: 'TASK_ADD_CONVERTIBLE_AGREEMENT'
          }
        })
      }
    }catch(e){
      console.log(e)
    }

    },
    upsertOption: async (context, event) => {

      const { groupCompanies } = context
      let index = get(context, 'iterations', 1) - 1;
      if (index < 0) index = 0;
      const groupCompany = groupCompanies[index]
      const documents = getDocuments(context?.uploadDocumentsOption ? context?.uploadDocumentsOption : context?.generatedDocumentOption)
      let shareIncentivePlan = null
      let newShareIncentivePlan = null;
      let shareClass = null
      let newShareClass = {};
      if (context.documentsSIP === 'generated' && context?.generatedDocumentOptionSIP?.document?.id){
        shareIncentivePlan = context?.generatedDocumentOptionSIP?.document?.id;
        shareClass = context?.generatedDocumentOptionSIP?.document?.shareClass?.id;
      }
      if ( context.documentsSIP === 'later' && context?.uploadDocumentsOptionSIPLater){
        shareIncentivePlan = context?.upsertSipDocument?.data?.upsertShareIncentivePlan?.id;
        shareClass = context?.uploadDocumentsOptionSIPLater?.class?.id;
      }
      if (context?.documentsSIP === 'upload' && context?.uploadDocumentsOptionSIP){
        shareIncentivePlan = context?.upsertSipDocument?.data?.upsertShareIncentivePlan?.id;
        shareClass = context?.uploadDocumentsOptionSIP?.class?.id;
      }
      if (context?.addOptionForm?.class){
        if (context?.addOptionForm?.class?.id){
          shareClass = context?.addOptionForm?.class?.id;
        }else{
          newShareClass = {name: context?.addOptionForm?.class?.name}
        }
        
      }
      const variables = {
        startupId,
        shareType: context.type,
        groupCompanyId: groupCompany.id,
        equityData: {
          shareClass: shareClass, //id del share class?
          newShareClass: newShareClass,
          issuedDate: context?.addOptionForm?.issuedDate ? moment(context?.addOptionForm?.issuedDate).format('YYYY-MM-DD') : null,
          currency: getCurrency(groupCompany.id, context.getStakeholdersGroupCompanies.groupCompanies),
          amount: context?.addOptionForm?.amount,
          price: context?.addOptionForm?.price,
          shareIncentivePlan: shareIncentivePlan,
          newShareIncentivePlan: newShareIncentivePlan,
          documents, //DocumentEquityInput


        },
        owner: { id, type: getType(id, context.getStakeholdersGroupCompanies) },
      };
      // if (context?.equityData?.uploadDocumentsOptionSIP) {
      //   variables.newShareIncentivePlan = {
      //     name: context?.uploadDocumentsOptionSIP?.name,
      //     shareClassId: context?.uploadDocumentsOptionSIP?.class?.id,
      //     totalShares: context?.uploadDocumentsOptionSIP?.reservedShares ?? 0,
      //     documents: getDocuments(context?.uploadDocumentsOptionSIP ? context?.uploadDocumentsOptionSIP : context?.uploadDocumentsOption)

      //   }
      // }
      if (context.documents === "agreement") {
        variables.equityData.isThereAgreement = true
      }
      if (context.documentsSIP === "sip") {
        variables.equityData.isThereSIP = true
      }
      const resultOption = await mutation({ ...stripTypenames(omit(variables, 'equityData.type')) });
      if (context.documents === "agreement") {
        await registerAsCheckedTask({
          startupId,
          registeredTaskData: {
            entityId: resultOption.data.upsertEquity.id,
            taskCode: 'TASK_ADD_SHARE_OPTION_AGREEMENT'
          }
        })
      }
      return {}
    },
    upsertSipDocument: async (context, event) => {
      const { groupCompanies } = context
      let index = get(context, 'iterations', 1) - 1;
      if (index < 0) index = 0;
      const groupCompany = groupCompanies[index]
      let variables ={};
      if (context?.uploadDocumentsOptionSIP){
        variables = {
          startupId,
          shareIncentivePlan: {
            name: context?.uploadDocumentsOptionSIP?.sipName,
            groupCompanyId: groupCompany.id,
            shareClassId: context?.uploadDocumentsOptionSIP?.class?.id,
            totalShares: context?.uploadDocumentsOptionSIP?.reservedShares ?? 0,
            documents: getDocuments(context?.uploadDocumentsOptionSIP ? context?.uploadDocumentsOptionSIP : context?.uploadDocumentsOption)
          },
        }
      }
      if (context?.uploadDocumentsOptionSIPLater){
        variables = {
          startupId,
          shareIncentivePlan: {
            name: context?.uploadDocumentsOptionSIPLater?.sipName,
            groupCompanyId: groupCompany.id,
            shareClassId: context?.uploadDocumentsOptionSIPLater?.class?.id,
            totalShares: context?.uploadDocumentsOptionSIPLater?.reservedShares ?? 0,
            

          },
        }
      }
      
      
      const sip = await upsertSipDocument(variables);
      return sip;
    }
  }), []);

}
export default useServices;
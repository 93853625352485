import React from "react";
import _ from 'lodash';
import Typography from "../../../components/Texts/Typography";
import Button  from "../../../components/Buttons/Button";
import classes from './classes.module.scss';

const TemplateDeleteSmall = ({ children, className,buttons, props: { title = '', description } }) => {
    return (
        <>
            <div className={`${classes.container} ${className}`}>
                <div className={classes.header}>
                    <Typography component={'h2'} color={'black'}>
                        {title}
                    </Typography>
                </div>
                {description}
                <div className={classes.form}>
                    {children}
                </div>
                <div className={classes.buttons}>
                    {_.map(buttons.buttons, button => (
                        <Button{...button}>{button.children}</Button>
                    ))
                    }
                    {buttons.cancel}
                    {buttons.submit}
                </div>
            </div>
        </>
    );
}

export default TemplateDeleteSmall;
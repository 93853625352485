import React, {Component} from 'react';
import classes from './DinamicBotonera.module.scss'

/*
    Properties:
        styleClass:{  
            container: class to container the component
        } 
        buttons:[ array of buttons
            {
                label:
                className:
                type
                onClick:
            }
        ]
        
*/
class DinamicBotonera extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
  
    onClick=(current)=>{
        if (current.onClick){
            current.onClick();
        }
    }
    componentJSX_buttons=(buttons)=>{
        if (buttons){
            return buttons.map((current,i)=>{
                return (
                    <button key={i} onClick={(e)=>{this.onClick(current)}} className={`${classes[current.type || "common"]}`}> 
                        {current.label}
                    </button>
                )
            })
        }
    }
    render() {
        let {buttons} = this.props;
        return (
            <div className={`${classes.DinamicBotonera}`}>
                    <span>{this.componentJSX_buttons(buttons)}</span>
            </div>
        );
    }
}

export default DinamicBotonera;


import get from 'lodash/get'
import  isEmpty  from 'lodash/isEmpty';
import set from 'lodash/set'
import useTranslate from "src/modules/generic/hooks/useTranslate";
const useGetDelete = () => {
    const { translate } = useTranslate();

    const updateCache = (cache, { data })=> {
        const mutationName = Object.keys(data)[0];
        const identity = cache.identify(data[mutationName]);
        const stakeholder = cache.read({ id: identity });
        cache.evict({ id: identity });
    
        (stakeholder.roles || []).forEach( role => {
            const identityMap = cache.identify({ __typename: "RoleItem", id:role.name});
            cache.evict({ id: identityMap });
        });
    
        cache.gc();
    };

    const mapConnections = (data)=>{
        const keyRoles = [
            'DIRECTOR',
            'SHAREHOLDER',
            'AUTHORIZEDSIGNATORY',
        ]
        return {
            ...get(data,'getStakeholder'),
            connections:{
                equity:get(data,'getStakeholder.areThereEquityConnections',false)?[{}]:[],
                roles:get(data,'getStakeholder.roles',[]).filter(role=>keyRoles.includes(role.name) && role.entity.managedByClara && role.entity.kind === 'INCORPORATED'),
                dataProtectionContact:[...get(data,'getStakeholder.formationDataConnections.dataProtection',[]),...get(data,'getStakeholder.keyPeopleConnections.dataProtectionContact',[])],
                directors:[...get(data,'getStakeholder.formationDataConnections.directors',[]),...get(data,'getStakeholder.keyPeopleConnections.directors',[])],
                productItems:get(data,'getStakeholder.isThereProductItems',false)?[{}]:[],
                authorisedSignatories:[...get(data,'getStakeholder.formationDataConnections.authorisedSignatories',[]),...get(data,'getStakeholder.keyPeopleConnections.authorisedSignatories',[])] ,
                authorisedSignatoriesBank:[...get(data,'getStakeholder.formationDataConnections.bankInformation',[]),...get(data,'getStakeholder.keyPeopleConnections.bankingAuthority',[])],
                shareholders:get(data,'getStakeholder.formationDataConnections.shareholders',[]),
                primaryContact:get(data,'getStakeholder.formationDataConnections.primaryContact',[]),
                warrants:get(data,'getStakeholder.warrants',[]),
                documents:get(data,'getStakeholder.documents',[]),
                convertibles:get(data,'getStakeholder.convertibles',[]),
                shareIssuances:get(data,'getStakeholder.shareIssuances',[]),
                optionGrants:get(data,'getStakeholder.optionGrants',[]),
                dueDiligences:[]/*filter(get(data,'getStakeholder.dueDiligences',[]),dd=>dd.status!=='GREEN' || dd.status!=='UNKNOWN')*/,
                authorizedSignatoryDocuments:get(data,'getStakeholder.authorizedSignatoryDocuments',[])
        }
    }
}
    const getType = (allowedActionDelete) => {
        if(allowedActionDelete?.reasonCode === 'STAKEHOLDER_ON_DELETE_MANAGED_BY_CLARA') return 'managedByClara'
        if(allowedActionDelete?.reasonCode === 'STAKEHOLDER_ON_DELETE_THERE_ARE_CONNECTIONS') return 'connections'
        if(allowedActionDelete?.value)return 'delete'
    }

    const getCategories = (connections) => {
        const categories= [];
        Object.keys(connections).forEach(connectionKey=> {
            if(!isEmpty(get(connections,connectionKey,[]))) {
                categories.push({
                    title: translate(`MODULES.STAKEHOLDER.DELETE.CONNECTIONS.${connectionKey.toUpperCase()}_TITLE`),
                    items: connections[connectionKey].map(item => {
                        return translate(`MODULES.STAKEHOLDER.DELETE.CONNECTIONS.${connectionKey.toUpperCase()}`, {[connectionKey]:item});
                    })
                });
            }
        });
        return categories;
    }

    return { getType,mapConnections,getCategories,updateCache }
}
export default useGetDelete

import PropTypes from "prop-types";
import React from 'react';
import Typography from "../../../modules/generic/components/Texts/Typography";
import PincasIcon from "src/modules/generic/components/Icon/PincasIcon";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

function DetailsTabTitle({ title, buttons, sx, inPincas = false }) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      spacing={0}
      sx={{ 
        width: "100%", 
        paddingBottom: "19px",
        ...sx
      }}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Typography color="black" component="h5">{title}</Typography>
        {inPincas && (
          <PincasIcon />
        )}
      </Stack>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "12px",
        }}
      >
        {buttons}
      </Box>
    </Stack>
  );
}

DetailsTabTitle.propTypes = {
  buttons: PropTypes.any,
  title: PropTypes.any,
  inPincas: PropTypes.bool,
  sx: PropTypes.object
}
export default DetailsTabTitle;
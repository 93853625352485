import _ from 'lodash';
import useQuery from '../../../../../hooks/custom/useQuery';

export const GET_DEPARTMENTS = `
query Query($startupId: ID!, $groupCompanyId: ID!) {
  getGroupCompany(startupId: $startupId, groupCompanyId: $groupCompanyId) {
    id
    departments {
      id
      isDefault
      name
    }
  }
}
`;

const useGetDepartments = (variables) => {
	const { loading, error, data, refetch } = useQuery(GET_DEPARTMENTS, variables);
	return { loading, error, data: _.get(data, 'getGroupCompany.departments', []), refetch };
};

export default useGetDepartments;

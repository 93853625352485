import React from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";
const WarrantsList = ({go,...props}) => {
  const params = useParams();
  

  return (

    <MicroFrontend id="MF_Warrants_Content_List" component={"./WarrantsView"} mf="capTable" params={{ go, ...props,...params }} />
  );
};

export default WarrantsList;

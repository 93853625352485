import useMutation from "../../../../hooks/custom/useMutation";

export const UPSERT_COMPONENT_STAKEHOLDER = `
  mutation upsertComponentStakeholder(
    $startupId: ID!
    $stakeholderId: ID!
    $stakeholder: ComponentStakeholderInputType!
  ) {
    upsertComponentStakeholder(
      startupId: $startupId
      stakeholderId: $stakeholderId
      stakeholder: $stakeholder
    ) {
      id
    }
  }
`;

const useUpsertComponentStakeholder = () => {
  const mutation = useMutation(
    UPSERT_COMPONENT_STAKEHOLDER,
    { showSuccessNotification: true }
  );
  return mutation;
};

export default useUpsertComponentStakeholder;

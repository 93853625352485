import {Field, FieldArray} from "formik";
import {ClaraInputPhone, OptionsButtonInput} from "../../../../../components";
import TextInput from "../../../Inputs/TextInput";
import {PassportData} from "../../Components";
import classesForm from "../../Form.module.scss";
import _ from "lodash";
import React from "react";

const ContactDetails = ({ values, setFieldValue }) => {

    return (
        <>
            <h1>Contact Details</h1>

            <Field name={`contactNumber`}>
                {({ field, form }) => (
                    <ClaraInputPhone

                        field={field}
                        form={form}
                        label={"Contact Number:"}
                        data-zuko-name='Contact Nubmer'

                    />
                )}
            </Field>

            <Field name={`email`}>
                {({ field, form }) => (
                    <TextInput
                        type='email'
                        label='Email address'
                        field={field}
                        form={form}
                        placeholder={"Email address"}
                        autoComplete='off'
                        data-zuko-name='Email Address'
                    >

                    </TextInput>
                )}
            </Field>

            <hr></hr>



            <FieldArray
                name={"passports"}
                render={arrayHelpers => {

                    const handleOptionsButtonClick = (field, form, value) => {
                        form.setFieldValue(field.name, value);
                        if (value && field.value !== value) {
                            arrayHelpers.push({})
                        } else {
                            const currentPassportIndex = 0; // index = 0
                            const currentPassport = values[arrayHelpers.name][currentPassportIndex];
                            if (values[arrayHelpers.name].length !== 1) {
                                arrayHelpers.pop()
                            }

                            if (arrayHelpers && arrayHelpers.name && (values[arrayHelpers.name].length > 1) ) {
                                currentPassport.current = true;
                                values[arrayHelpers.name] = [currentPassport];
                            }
                        }

                    };

                    return (
                        <>
                            <h1>Current Passport</h1>
                            <PassportData name={"passports[0]"} setFieldValue={setFieldValue} values={values} current={true} />
                            <hr></hr>

                            <h1>Other Passports</h1>
                            <Field name={"otherPassports"} >
                                {({ field, form }) => (
                                    <OptionsButtonInput chatform={false}
                                                        field={field}
                                                        form={form}
                                                        className={{ root: classesForm.buttonOptions }}
                                                        label={"Individual holds, or has held, another passport:"}
                                                        data-zuko-name='Add another Passports'
                                                        data-zuko-id='OptionsButtonInput'
                                                        options={[
                                                            {
                                                                "id": true,
                                                                "text": "Yes"
                                                            },
                                                            {
                                                                "id": false,
                                                                "text": "No"
                                                            }
                                                        ]}
                                                        onClick={handleOptionsButtonClick}
                                        // params={this.props.params}
                                        // functions={this.props.functions}
                                    />

                                )}
                            </Field>

                            {_.get(values, "otherPassports") && values.passports.map((passport, index) => {

                                const isLast = values.passports.length === index + 1;
                                const handleAddElement = isLast ? arrayHelpers.handlePush({}) : null;
                                let handleDeleteElement = () => arrayHelpers.remove(index);

                                if (passport.current) {
                                    return null;
                                }

                                return (
                                    <> {index>0 && <PassportData name={`passports[${index}]`} values={values} setFieldValue={setFieldValue} />}
                                        <div className={classesForm.containerButtons}>
                                            <button style={{ display: index > 1 ? null : "none" }} onClick={handleDeleteElement}>Remove</button>
                                            {handleAddElement && <button className={`genericBlue`} onClick={handleAddElement}>Add Another</button>}
                                        </div>
                                        {!isLast && <hr />}
                                    </>)
                            })
                            }
                        </>
                    )

                }}
            />
        </>

    )

}
export default ContactDetails;
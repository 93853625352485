import { createMachine } from 'xstate';

const machine =
  /** @xstate-layout N4IgpgJg5mDOIC5QFVZgE4AUCuAjANgJYDGASmFIbAC7oCG1hA9gHYB02a6AYk+gLYBiAHIBRABoAVRKAAOTWIUasZIAB6IAjABYAHGwBMAVgBsukye1GjABgDsuzQYA0IAJ5bNATjZGvf3V1tL20TOxt-AF9I11QMHAISckoaemV2AGslXgFBTFJRADVVeUV01Q0EHX1jMwsrWwcnVw8EO01fLxMbG10AZh6m42jYrgSiMgoqWgZmTOy+ITEpEoUlOYrEPr6fTXCvO1s+0J7NFq0DAzYIgKCQsJuRkDisPAnk6bS5thowWT68gVikgQKV1ioQZVtrt9ocbMdujYzu5EHZtNcuj1+oNHMMniwmBA4KoXuMklNUrNWBwuDl+KsyhtIYhtC4UW0fDs7H0DLoutpNDy+iYnqS3uSUjN0mwstQ6QzwSxNggDALDKZdEYdF4Qto+v1zghHGw+dzefzBQZhaKxuLJpKvtTfv8FeVmQg9Vc9hFEQ4ukZdIagmwuTy+ZZLdaYs9bYl7Z8qUqQWC3aBKppA+yALQdbQ2PM2IzaVlWvMWaLRIA */
  createMachine({
    id: 'addEquityWizard',
    initial: 'getStakeholdersGroupCompanies',
    states: {
      select: {
        on: {
          NEXT: {
            target: 'getStakeholdersGroupCompanies',
            actions: ['setContextAfterStep']
          }
        }
      },
      getStakeholdersGroupCompanies: {
        invoke: {
          src: 'getStakeholdersGroupCompanies',
          id: 'getStakeholdersGroupCompanies',
          onDone: [
            {
              target: 'selectEquityType',
              actions: ['setContextAfterInvoke']
            }
          ],
          onError: [
            {
              target: 'select'
            }
          ]
        }
      },
      selectEquityType: {
        on: {
          NEXT: {
            target: 'evaluateEquity',
            actions: ['setContextAfterStep']
          }
        }
      },
      evaluateEquity: {
        always: [
          {
            cond: 'needSelectEquity',
            target: 'selectEquityType',
          },
          {
            cond: 'isShare',
            target: 'addShare'
          },
          {
            cond: 'isOption',
            target: 'addOption'
          },
          {
            cond: 'isWarrant',
            target: 'addWarrant'
          },
          {
            cond: 'isConvertible',
            target: 'addConvertible'
          },
          {
            target: 'error'
          },
        ]
      },
      addShare: {
        on: {
          NEXT: {
            target: 'finish',
            actions: ['upsertEquity']
          },
          PREV: {
            target: 'selectEquityType'
          },
          REFRESH:{target:'refreshData',actions:['setSelectParty']}
        }
      },
      refreshData: {
        invoke: {
          src: 'getStakeholdersGroupCompanies',
          id: 'getStakeholdersGroupCompanies',
          onDone: [
            {
              target: 'evaluateEquity',
              actions: ['setContextAfterInvoke']
            }
          ],
          onError: [
            {
              target: 'select'
            }
          ]
        }
      },
      addOption: {
        on: {
          NEXT: {
            target: 'finish',
            actions: ['upsertEquity']
          },
          PREV: {
            target: 'selectEquityType'
          },
          REFRESH:{target:'refreshData',actions:['setSelectParty']}
        }
      },
      addWarrant: {
        on: {
          NEXT: {
            target: 'finish',
            actions: ['upsertWarrant']
          },
          PREV: {
            target: 'selectEquityType'
          },
          REFRESH:{target:'refreshData',actions:['setSelectParty']}
        }
      },
      addConvertible: {
        on: {
          NEXT: {
            target: 'finish',
            actions: ['upsertConvertible']
          },
          PREV: {
            target: 'selectEquityType'
          },
          REFRESH:{target:'refreshData',actions:['setSelectParty']}
        }
      },
      error: {
        on: {
          PREV: {
            target: 'select'
          }
        }
      },
      result: {
        on: {
          PREV: {
            target: 'select'
          }
        }
      },
      finish: {
        entry: 'stopMachine',
        type: 'final'
      }
    }
  });
export default machine;

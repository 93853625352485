import React, { FC } from 'react';
import Button from "../../../../../generic/components/Buttons/Button";
import useCustomMachine from "../../../../../generic/hooks/useCustomMachine";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import useSession from '../../../../../session/hooks/useSession';
import SelectDocument from "../../../forms/SelectDocument";
import useGetDocumentList from "../../../forms/SelectDocument/graphql/useGetDocumentList";
type FirstStepSubmit = (values:object) => Promise<void>
interface FirstStepProps {
    type: string
    tooltips?: object
    onCompleteSubmit?: FirstStepSubmit
    isTherePrevious?:boolean
}
const SelectDocumentStep:FC<FirstStepProps>=({type,tooltips,onCompleteSubmit,isTherePrevious=true})=>{
    const {send,next,stepData}=useCustomMachine()
    const {translate}=useTranslate()
    const { startupId } = useSession();
    const {data}=useGetDocumentList({startupId})
    const handlePrev=()=>{
        send("PREVIOUS")
    }
    const handleCancel=()=>{
        send('CANCEL')
    }
    const handleCompleteSubmit = async (values: object) => {
        onCompleteSubmit && await onCompleteSubmit(values)
        next(values);
    }
    return (
      <>
        <SelectDocument
          tooltips={tooltips}
          documents={data}
          initialValues={stepData}
          propsTemplate={{
            title: translate(
              "MODULES.EQUITY.STEPS.SELECT_DOCUMENTS_STEP.TITLE",
              {
                agreementType: translate(
                  `MODULES.EQUITY.AGREEMENT_TYPE.${type}`
                ),
              }
            ),
            subTitle: translate(
              "MODULES.EQUITY.STEPS.SELECT_DOCUMENTS_STEP.SUBTITLE",
              { type: translate(`MODULES.EQUITY.TYPES_PLURAL.${type}`) }
            ),
          }}
          onCompleteSubmit={handleCompleteSubmit}
          Template={TemplateWizard}
          buttons={{
            cancel: <Button onClick={handleCancel}>Cancel</Button>,
            previous: isTherePrevious && (
              <Button onClick={handlePrev}>Previous</Button>
            ),
          }}
        />
      </>
    );
}
export default SelectDocumentStep
import { useCallback, useMemo } from "react";
import useGetStakeholders from "src/hooks/services/modules/stakeholders/useGetStakeholders";
import { useGetProductLazy } from "src/modules/checkout/hooks/useGetProduct";
import useSession from "src/modules/session/hooks/useSession";
import { Constants } from "src/v1/utils/constants";
import useSubmittedFormation from "../../GroupCompany/ADGM/SubmittedFormation/mutations/useSubmittedFormation";
import useSubmitRequestBasketMatter from "../../GroupCompany/ADGM/SubmittedFormation/mutations/useSubmitRequestBasketMatter";
import useGroupCompany from "../services/useGroupCompany";
import useGroupCompanyData from "../services/useGroupCompanyData";
import useGetBankInformation from "src/modules/checkout/hooks/useGetBankInformation";

const useServices = ({ initialValues }) => {
  const { startup, startupId } = useSession();
  const [submittedFormation] = useSubmittedFormation({}, {});
  const [submitRequestBasketMatter] = useSubmitRequestBasketMatter({}, {});
  const { manualQuery: getGroupCompany } = useGroupCompany();
  const { manualQuery: getGroupCompanyData } = useGroupCompanyData();
  const {manualQuery: getBankInformation } = useGetBankInformation({})
  const { groupCompanyId } = initialValues;
  const { manualQuery: getProduct } = useGetProductLazy();

  const { manualQuery: getStakeholders } = useGetStakeholders({
    variables: {
      startupId,
      groupCompanyId: initialValues.groupCompanyId,
      filters: {
        kind: "STAKEHOLDER_INDIVIDUAL",
        role: ["DIRECTOR", "SHAREHOLDER"],
      },
    },
  });

  const getFormationProductCodeByCompanyType = useCallback((context) => {
    switch (context?.initialData?.companyType) {
      case Constants.MODULES.FORMATION.COMPANIES_TYPE.ADGM_SPV:
        return Constants.MODULES.CHECKOUT.PRODUCTS.FORMATION_ADGM_SPV_PAYMENT;
      case Constants.MODULES.FORMATION.COMPANIES_TYPE.ADGM_TSL:
        if (
          context?.initialData?.formationData?.tslType ===
          Constants.MODULES.FORMATION.TLS_TYPE.SEED
        ) {
          return Constants.MODULES.CHECKOUT.PRODUCTS
            .FORMATION_ADGM_TSL_SEED_PAYMENT;
        }
        return Constants.MODULES.CHECKOUT.PRODUCTS
          .FORMATION_ADGM_TSL_EMERGENT_PAYMENT;
      case Constants.MODULES.FORMATION.COMPANIES_TYPE.CAYMAN_EXEMPTED_COMPANY:
        return Constants.MODULES.CHECKOUT.PRODUCTS.FORMATION_CAYMAN_PAYMENT;
      case Constants.MODULES.FORMATION.COMPANIES_TYPE.DELAWARE_C_CORP:
        return Constants.MODULES.CHECKOUT.PRODUCTS.FORMATION_DELAWARE_PAYMENT;
      default:
        return null;
    }
  }, []);

  const getProductCode = useCallback(
    (context) => {
      if (context?.initialData?.productCode) {
        return context?.initialData?.productCode;
      }
      if (context?.SaveWithScaleADGM_SPVFormStep?.product)
        return context?.SaveWithScaleADGM_SPVFormStep?.product;
      if (context?.SaveWithScaleCaymanFormStep?.product)
        return context?.SaveWithScaleCaymanFormStep?.product;
      if (context?.SaveWithScaleDelawareFormStep?.product)
        return context?.SaveWithScaleDelawareFormStep?.product;
      return getFormationProductCodeByCompanyType(context);
    },
    [getFormationProductCodeByCompanyType]
  );

  const getFormationBundleProductCodeByCompanyType = useCallback((context) => {
    switch (context?.initialData?.companyType) {
      case Constants.MODULES.FORMATION.COMPANIES_TYPE.ADGM_SPV:
        return Constants.MODULES.CHECKOUT.PRODUCTS.SCALE_FORMATION_ADGM_BUNDLE;
      case Constants.MODULES.FORMATION.COMPANIES_TYPE.CAYMAN_EXEMPTED_COMPANY:
        return Constants.MODULES.CHECKOUT.PRODUCTS
          .SCALE_FORMATION_CAYMAN_BUNDLE;
      case Constants.MODULES.FORMATION.COMPANIES_TYPE.DELAWARE_C_CORP:
        return Constants.MODULES.CHECKOUT.PRODUCTS
          .SCALE_FORMATION_DELAWARE_BUNDLE;
      default:
        return null;
    }
  }, []);

  return useMemo(
    () => ({
      
      initialData: async () => {
        const getCompanyData = await getGroupCompanyData({
          variables: {
            startupId,
            groupCompanyId,
          },
        });
        const groupCompanyData = getCompanyData?.data?.getGroupCompany;
        const companyTypes = [
          Constants.MODULES.FORMATION.COMPANIES_TYPE.ADGM_SPV,
          Constants.MODULES.FORMATION.COMPANIES_TYPE.ADGM_TSL,
        ];
        let needFirstContact = companyTypes.includes(
          initialValues?.company?.companyType
        );
        if (
          initialValues?.needFirstContact !== undefined &&
          initialValues?.needFirstContact !== null
        ) {
          needFirstContact = initialValues?.needFirstContact;
        }
        return {
          ...initialValues,
          companyType:
            initialValues?.company?.companyType ||
            groupCompanyData?.companyType,
          formationData:
            initialValues?.company?.formationData ||
            groupCompanyData?.formationData,
          needFirstContact: initialValues,
          productCode:
            initialValues?.productCode || groupCompanyData?.productCode,
          disabledPayCart: initialValues?.disabledPayCart ?? false,
          company: groupCompanyData,
        };
      },

      getProductByProductCode: async (context, event) => {
        const product = await getProduct({
          variables: {
            productCode: context?.initialData?.productCode,
            relatedItemId: initialValues?.groupCompanyId,
          },
        });
        return { product: product?.data?.getProduct };
      },

      getProducts: async (context, event) => {
        const formationProductCode = getFormationProductCodeByCompanyType(
          context
        );
        const formationScaleProductCode = getFormationBundleProductCodeByCompanyType(
          context
        );
        let formationScale = null;
        const formationProduct = await getProduct({
          variables: {
            productCode: formationProductCode,
            relatedItemId: initialValues?.groupCompanyId,
          },
        });
        if (formationScaleProductCode) {
          formationScale = await getProduct({
            variables: {
              productCode: formationScaleProductCode,
              relatedItemId: initialValues?.groupCompanyId,
            },
          });
        }
        return {
          products: {
            [formationProductCode]: formationProduct?.data?.getProduct,
            [formationScaleProductCode]: formationScale?.data?.getProduct,
          },
        };
      },

      getProduct: async (context, event) => {
        //If the productCode is definied, we get the product by productCode
        if (initialValues.productCode) {
          const product = await getProduct({
            variables: {
              productCode: context?.initialData?.productCode,
              relatedItemId: initialValues?.groupCompanyId,
            },
          });
          return { product: product?.data?.getProduct };
        }
        if (startup?.hasScaleSubscription) {
          const formationProductCode = getFormationProductCodeByCompanyType(
            context
          );
          return {
            product: context?.getProducts?.products[formationProductCode],
          };
        }
        return {
          product: context?.getProducts?.products[getProductCode(context)],
        };
      },

      getSource: async (context, event) => {
        let source = "FORMATION_COMPANY";
        let productCode = getProductCode(context);
        switch (productCode) {
          case Constants.MODULES.CHECKOUT.PRODUCTS.FORMATION_ADGM_SPV_PAYMENT:
          case Constants.MODULES.CHECKOUT.PRODUCTS
            .FORMATION_ADGM_TSL_SEED_PAYMENT:
          case Constants.MODULES.CHECKOUT.PRODUCTS
            .FORMATION_ADGM_TSL_EMERGENT_PAYMENT:
            source = "FORMATION_COMPANY";
            break;
          case Constants.MODULES.CHECKOUT.PRODUCTS.FORMATION_CAYMAN_PAYMENT:
            source = "FORMATION_CAYMAN";
            break;
          case Constants.MODULES.CHECKOUT.PRODUCTS.FORMATION_DELAWARE_PAYMENT:
            source = "FORMATION_DELAWARE";
            break;
          case Constants.MODULES.CHECKOUT.PRODUCTS.SCALE_FORMATION_ADGM_BUNDLE:
            source = "SCALE_FORMATION_ADGM_BUNDLE";
            break;
          case Constants.MODULES.CHECKOUT.PRODUCTS
            .SCALE_FORMATION_CAYMAN_BUNDLE:
            source = "SCALE_FORMATION_CAYMAN_BUNDLE";
            break;
          case Constants.MODULES.CHECKOUT.PRODUCTS
            .SCALE_FORMATION_DELAWARE_BUNDLE:
            source = "SCALE_FORMATION_DELAWARE_BUNDLE";
            break;
          case Constants.MODULES.CHECKOUT.PRODUCTS.CAYMAN_SHOPPING_CART:
          case Constants.MODULES.CHECKOUT.PRODUCTS.ADGM_SHOPPING_CART:
          case Constants.MODULES.CHECKOUT.PRODUCTS.DELAWARE_SHOPPING_CART:
            source = productCode;
            break;
          default:
            source = "FORMATION_COMPANY";
        }
        return { source };
      },

      getStakeholders: async (context, event) => {
        const stakeholders = await getStakeholders({
          variables: {
            startupId,
            groupCompanyId: initialValues?.groupCompanyId,
            filters: {
              kind: "STAKEHOLDER_INDIVIDUAL",
              role: ["DIRECTOR", "SHAREHOLDER"],
            },
          },
        });
        return { stakeholders: stakeholders?.data?.stakeholderList };
      },

      SubmitingPincasStep: async (context, event) => {
        await submitRequestBasketMatter({
          variables: {
            startupId: startupId,
            groupCompanyId: initialValues.groupCompanyId,
          },
        });
        if (initialValues?.callback) {
          initialValues.callback();
        }
        return context;
      },

      SubmitingADGMStep: async (context, event) => {
        await submittedFormation({
          variables: {
            startupId: startupId,
            groupCompanyId: initialValues.groupCompanyId,
          },
        });
        if (initialValues?.callback) {
          initialValues.callback();
        }
        return context;
      },

      getCart: async (context, event) => {
        const result = await getGroupCompany({
          variables: {
            startupId,
            groupCompanyId: initialValues.groupCompanyId,
          },
        });
        return result?.data?.getGroupCompany;
      },

      getBankInformation: async (context, event) => {
        let productCode = getProductCode(context);
        const bankInformation = await getBankInformation({
          variables: {
            productCode: productCode,
            entityId: initialValues?.groupCompanyId,
            startupId: startupId,
            couponCode: context?.ChooseAPayeeFormStep?.coupon,
          },
        }); 
        return bankInformation?.data?.getBankTransfersDetails 
      }
    }),
    [
      getGroupCompanyData, 
      startupId, 
      groupCompanyId, 
      initialValues, 
      getProduct, 
      getFormationProductCodeByCompanyType, 
      getFormationBundleProductCodeByCompanyType, 
      startup?.hasScaleSubscription, 
      getProductCode, 
      getStakeholders, 
      submitRequestBasketMatter, 
      submittedFormation, 
      getGroupCompany, 
      getBankInformation
    ]
  );
};

export default useServices;

import React, { FC, useMemo, useRef } from 'react'
import _ from 'lodash'
import * as  Yup from 'yup'
import useTranslate from "../../../../generic/hooks/useTranslate";
import Form from "../../../../../components/forms/Form/FormL";
import ControllerInput from "../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import SubmitButton from "../../../../generic/components/Buttons/SubmitButton";
import TemplateFormDefault from "../../../../generic/templates/Modal";
import FormProps from "../../../../generic/forms/interfaces/FormProps";
import TemplateLabel from "../../../../generic/components/Inputs/LabelInput";
import SelectInput from "../../../../../components/inputs/Select/SelectInput/SelectInput";
import CheckboxButtonSimple from "../../../../generic/components/Inputs/CheckboxButtonSimple";
import Typography from '../../../../generic/components/Texts/Typography';
const schema = Yup.object().shape({
    document: Yup.object().nullable().required('Required')
})
interface SelectDocumentProps extends FormProps {
    documents: object[],
    company: any
}
const SelectDocument: FC<SelectDocumentProps> = ({ variant = "select", initialValues, documents, onCompleteSubmit, Template = TemplateFormDefault, buttons = {}, buttonSubmitProps, propsTemplate, company }: SelectDocumentProps) => {
    const { translate } = useTranslate();
    const documentList = useMemo(() => {
        return _.map(documents, doc => ({ value: doc, label: _.get(doc, 'name') }))
    }, [JSON.stringify(documents)])
    const refForm = useRef()
    return (
        <React.Fragment>
            <Form
                initialValues={initialValues || {}}
                schema={schema}
                onlySendDirty={false}
                onSubmit={onCompleteSubmit}
                ref={refForm} optionsForm={{ mode: "onChange" }}
            >
                <Template props={propsTemplate} buttons={{ submit: <SubmitButton{...buttonSubmitProps}>Add</SubmitButton>, ...buttons }}>
                    {company?.name && (
                        <Typography color={'black'} weight={'bold'} variant={"body"}>
                            {company?.name}
                        </Typography>
                    )}
                    {variant === "select" && (
                        <TemplateLabel label={translate('MODULES.EQUITY.FORMS.SELECT_DOCUMENT.DOCUMENT')} isRequired={true}>
                            <ControllerInput
                                as={SelectInput}
                                list={documentList}
                                placeholder={translate('MODULES.EQUITY.FORMS.SELECT_DOCUMENT.SELECT_PLACEHOLDER')}
                                name={"document"}
                                onChange={(e) => {

                                }}
                            />
                        </TemplateLabel>
                    )}

                    {variant === "option" && (
                        <ControllerInput
                            render={CheckboxButtonSimple}
                            name='document'
                            // className={classes.CheckboxButton}
                            // classNameButton={classes.buttonCheckBox}
                            options={documentList}
                            defaultValue={[]}
                            onChange={(value) => {

                            }}
                            isMultiple={false}
                        />)}

                </Template>
            </Form>
        </React.Fragment>
    )
}

export default SelectDocument

export enum RELEVANT_ACTIVITIES{
	"ACTING_AS_HOLDING_COMPANY" = "ACTING_AS_HOLDING_COMPANY",
	"BANKING" = "BANKING",
	"DISTRIBUTION_AND_SERVICE" = "DISTRIBUTION_AND_SERVICE",
	"FINANCING_AND_LEASING" = "FINANCING_AND_LEASING",
	INSURANCE = "INSURANCE",
	INTELLECTUAL_PROPERTY = "INTELLECTUAL_PROPERTY",
    SHIPPING = "SHIPPING",
    FUND_MANAGEMENT = "FUND_MANAGEMENT",
    ACTING_AS_HEADQUARTERS = "ACTING_AS_HEADQUARTERS",
	NONE_OF_ABOVE = "NONE_OF_ABOVE",
}
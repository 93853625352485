import Mustache from "mustache";
import Parser from "html-react-parser";
import TRANSLATES from "../../../langs/us";
import _ from "lodash";

export default function useTranslate() {
  const translate = (key, params, defaultKey) => {
    if (!key) {
      return null;
    }
    const label = _.get(
      TRANSLATES,
      key.toUpperCase(),
      translate(defaultKey, params)
    );
    if (!label) {
      return key;
    } else if (label && params) {
      return Mustache.render(label, params);
    } else {
      return label;
    }
  };
  const translateText = (label, params = {}) => {
    if (label){
      return Mustache.render(label, params);
    }
  };
  return { translate,translateText };
}

import download from 'downloadjs'
import { Constants, ServerConnect } from '../../../../../v1/utils';

function useDownloadDocProofAddress() {


    const downloadTemplateProofAddress = async () => {
        const query = `{ getCDNAccess(assetId:"${Constants.CONTENTFUL.ASSETS.RESIDENTIAL_ADDRESS_TEMPLATE}") }`
        let { getCDNAccess } = await ServerConnect.graphQlQuery(query);
        let contentfulJson = await (await fetch(getCDNAccess)).json();
        let file = await (await fetch(`https:${contentfulJson.fields.file.url}`)).blob();
        download(file, contentfulJson.fields.file.fileName, contentfulJson.fields.file.contentType);
    }



    return ({ downloadTemplateProofAddress })
}

export default useDownloadDocProofAddress

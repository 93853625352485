import useQuery from '../../../../../hooks/custom/useQuery';

const GET_GROUP_COMPANIES = `
query getGroupCompanies($startupId:ID!) {
    getGroupCompanies(startupId: $startupId) {
	    id
    	name
    	type
    	jurisdiction
    	country { name code alpha3}
    	registeredNumber
		managedByClara
    	transactionCurrency
    	isHoldingCompany
		registeredAddress {
		  city
		  state
		  street
		  zipCode
		  country {name code alpha3}
		}
  	}
  }
`;

const useGetGroupCompanies = (variables, config) => {
	const { loading, error, data, refetch } = useQuery(GET_GROUP_COMPANIES, variables, config);

	return { loading, error, data, refetch };
};
export default useGetGroupCompanies;

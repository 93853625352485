export const actions = {
    RESET_AUTOCOMPLETES: "RESET_AUTOCOMPLETES",
    CREATE_AUTOCOMPLETE: "CREATE_AUTOCOMPLETE",
    AUTOCOMPLETE_UPDATE_VALUE: "AUTOCOMPLETE_UPDATE_VALUE",
    
};

export const resetAutcompletes = (dispatch) => {
    dispatch({
        type: actions.RESET_AUTOCOMPLETES
    })
};


export const createAutocomplre = (dispatch,structure,groups,destination) => {

    dispatch({
        type: actions.CREATE_AUTOCOMPLETE,
        structure,
        groups,
        destination
    })
};
export const updateAutocomplete = (dispatch,key,rowKey,value,fieldName) => {
    dispatch({
        type: actions.AUTOCOMPLETE_UPDATE_VALUE,
        key,
        value,
        fieldName,
        rowKey
    })
};



import React, { FC, useEffect } from 'react';
import { useDispatch } from "react-redux";
import useRemovePendingEntity from "../../../../../../hooks/services/modules/assistance/useRemovePendingEntity";
import { StepProps } from "../../../../../generic/components/Wizard/Step";
import useCustomMachine from "../../../../../generic/hooks/useCustomMachine";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import { addAssistantText, clearAssistatText } from "../../../../../generic/store/action";
import useSession from '../../../../../session/hooks/useSession';
import FirstStep from "../../../core/wizards/steps/FirstStep";

const FirstStepAdvisor: FC<StepProps> = ({ }: StepProps) => {

  const { translate } = useTranslate();
  const { startup } = useSession();
  const [removePendingEntity] = useRemovePendingEntity({ showSuccessNotification: false });
  const dispatch = useDispatch()
  const { next, send } = useCustomMachine();

  const handleCompleteSubmit = async (values: any) => {
    await removePendingEntity({ entityType: 'ADVISOR', startupId: startup.id })
    dispatch(clearAssistatText())
  }

  useEffect(() => {
    dispatch(clearAssistatText())
    dispatch(addAssistantText(translate("MODULES.ONBOARDINGROLES.ADVISOR.FIRST_STEP_TOOLTIPS"), 'text'))
  }, [])

  return (
    <>
      <FirstStep
        role={'ADVISOR'}
        entity={'an advisor'}
        tooltips={{ isNotExist: translate("MODULES.ONBOARDINGROLES.ADVISOR.FIRST_STEP_IS_NOT_EXIST_TOOLTIPS") }}
        onCompleteSubmit={handleCompleteSubmit}
      />
    </>
  );
}

export default FirstStepAdvisor;

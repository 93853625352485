import { default as React, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import Form from "src/components/forms/Form/Form";
import TextInput from "src/components/inputs/Text/TextInput";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import * as Yup from "yup";
import Button from "src/modules/generic/components/Buttons/Button";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import {
  clearAssistatText,
  addAssistantText,
} from "src/modules/generic/store/action";
import PhoneInput from "src/modules/generic/components/Inputs/PhoneInput";
import TextareaInput from "src/components/inputs/Textarea/TextareaInput";
import Typography from "src/modules/generic/components/Texts/Typography";
import classes from "../classes.module.scss";
import useSession from "src/modules/session/hooks/useSession";

/**
 * Description Step: You don&#x27;t know if you are qualified
 */

function CallTeamExpertsFormStep() {
  const { next, context, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const { startup } = useSession();

  const initialValues = useMemo(() => {
    return context?.CallTeamExpertsFormStep ?? {};
  }, [context]);

  const handleCancel = (values) => {
    cancel();
  };

  const handlePrev = (values) => {
    prev();
  };
  const handleSubmit = async (values) => {
    next(values);
  };

  useEffect(() => {
    dispatch(
      addAssistantText(
        "Provide your details and any important information about the company you want to form. Clara will be in touch to discuss your options."
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const schema = Yup.object().shape({
    name: Yup.string().nullable().required("This field is required"),
    email: Yup.string().email().required("This field is required").nullable(),
    telephoneNumber: Yup.string()
      .required("This field is required")
      .typeError(translate("MODULES.CLARA.VALIDATION_FIELD_REQUIRED")),
    profileName: Yup.string().required("This field is required"),
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: "Get advice from out team of experts",
          skeleton: null,
          subTitle: "Engage concierge",
          variant: "simple",
        }}
        buttons={{
          previous: (
            <Button variant="secondary" onClick={handlePrev}>
              {translate("BUTTON_PREVIOUS")}
            </Button>
          ),
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("BUTTON_CANCEL")}
            </Button>
          ),
          submit: (
            <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>
              Engage concierge
            </SubmitButton>
          ),
        }}
      >
        <Typography weight="regular" variant="xbody">
          Complete the details below and our team will get in touch to let you
          know if you can form a Tech Startup Licence or advise on other
          options.
        </Typography>
        <TemplateLabel
          label={translate("MODULES.GROUP_COMPANY.ENGAGE_CONCIERGE.LABEL_NAME")}
          isRequired={true}
        >
          <ControllerInput
            render={TextInput}
            name="name"
            placeholder={translate(
              "MODULES.GROUP_COMPANY.ENGAGE_CONCIERGE.PLACEHOLDER_NAME"
            )}
            defaultlabel={""}
          />
        </TemplateLabel>
        <TemplateLabel label={translate("EMAIL_FIELD")} isRequired={true}>
          <ControllerInput
            render={TextInput}
            name="email"
            placeholder={translate(
              "MODULES.GROUP_COMPANY.ENGAGE_CONCIERGE.PLACEHOLDER_EMAIL"
            )}
            defaultlabel={""}
          />
        </TemplateLabel>
        <TemplateLabel
          label={translate(
            "MODULES.GROUP_COMPANY.ENGAGE_CONCIERGE.LABEL_TELEPHONE_NUMBER"
          )}
          isRequired={true}
        >
          <ControllerInput
            render={PhoneInput}
            disableDropdown={false}
            name="telephoneNumber"
            placeholder={translate(
              "MODULES.GROUP_COMPANY.ENGAGE_CONCIERGE.PLACEHOLDER_TELEPHONE"
            )}
            defaultlabel={""}
          />
        </TemplateLabel>
        <TemplateLabel
          label={translate(
            "MODULES.GROUP_COMPANY.ENGAGE_CONCIERGE.LABEL_PROFILE_NAME"
          )}
          isRequired={true}
        >
          <ControllerInput
            render={TextInput}
            name="profileName"
            placeholder={translate(
              "MODULES.GROUP_COMPANY.ENGAGE_CONCIERGE.PLACEHOLDER_PROFILE_NAME"
            )}
            defaultlabel={""}
            disabled={true}
            defaultValue={startup.name}
          />
        </TemplateLabel>
        <TemplateLabel
          label={translate(
            "MODULES.GROUP_COMPANY.ENGAGE_CONCIERGE.LABEL_ADDITIONAL_INFO"
          )}
        >
          <ControllerInput
            render={TextareaInput}
            name="additionalInfo"
            placeholder={translate(
              "MODULES.GROUP_COMPANY.ENGAGE_CONCIERGE.PLACEHOLDER_ADDITIONAL_INFO"
            )}
            defaultlabel={""}
          />
        </TemplateLabel>
        <div className={classes.bottomSpace} />
      </TemplateWizard>
    </Form>
  );
}

export default CallTeamExpertsFormStep;

import useLazyQuery from "../../../custom/useLazyQuery";
import _ from "lodash";

const GET_GROUP_COMPANIES = `
query getGroupCompanies($startupId:ID!) {
    getGroupCompanies(startupId: $startupId) {
    id
    managedByClara
    documents {
      name
      id
      kind
      status
      type{
        label
        code
      }
      groupCompanies{
        groupCompany{
        id
        }
        signedBy{
        id
        }
      }
      stakeholders{
      id
      }
      subCategories
      file {
        id
        name
      }
    }
  }
  }
`;

const useGetGroupCompaniesLazy = (variables) => {
    const {manualQuery, loading, error, data, ...pre } = useLazyQuery(GET_GROUP_COMPANIES, variables);
    return {manualQuery, loading, error, data: _.get(data, 'getGroupCompanies'), ...pre };
};

export default useGetGroupCompaniesLazy

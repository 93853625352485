import React, {useEffect, useState} from 'react';
import {ServerConnect} from "../utils";
import _ from "lodash";
import Mustache from 'mustache'
import {companyType} from "../graphql/mutations/matters";

const QUERY_MATTER = `query{
  getEligibleSPVAlreadyIncorporatedCompanies(matterId:"{{matterId}}")
  ${companyType}
}
`;

const QUERY_DRAFT = `query{
  getEligibleSPVAlreadyIncorporatedCompaniesDraft(draftId:"{{draftId}}")
}
`;

export default function useQuestionnaireSPVAlreadyIncorporatedCompanies(matterId,draftId) {
  const [data, setData] = useState(null);
  useEffect(() => {
    const query_params = {
      matterId,
      draftId
    };
    const query = Mustache.render(draftId ? QUERY_DRAFT : QUERY_MATTER, query_params);
    ServerConnect.graphQlQuery(query).then(result => {
      let newData = _.get(result, draftId ? "getEligibleSPVAlreadyIncorporatedCompaniesDraft" : "getEligibleSPVAlreadyIncorporatedCompanies", {});
      setData(newData)
    });
  }, [matterId,draftId]);

  return { data };
}
import React from "react";
import classes from "./classes.module.scss";
import useTranslate from "../../../generic/hooks/useTranslate";
import TypographyLocal from "../../../generic/components/Texts/Typography";
import FormJurisdictionDetails from "../../forms/JurisdictionDetails";
import Button from "../../../generic/components/Buttons/Button";
import useNavigation from "../../../generic/hooks/useNavigation";
import { Constants } from '../../../../v1/utils/constants';
import setStatusMatter from "../../../../hooks/services/modules/matter/setStatusMatter.js";
import HelpLink from "../../../generic/components/HelpLink";
import constantsFilters from "../../constants";

const FormJurisdictionDetailsView = () => {

  const { translate } = useTranslate();
  const {goTo, goToPrev, params} = useNavigation();
  const linkJurisdiction = constantsFilters.JURISDICTION_LINKS[0];
  const {mutation} = setStatusMatter({
    showSuccessNotification: false,
  });

  const handleOnComplete = (values) => {
    goTo(Constants.PAGES.MODULES.FORMATION.jurisdictionDone, params);
  };

  const handlePrevious = () => {
    goToPrev();
  };

  const handleCancel = () => {
    return new Promise( async (resolve, reject) => {
      const variables = {
        matterId: params.matterId,
        state: "CLOSED",
        declineReason: translate("MODULES.FORMATION.SELECT_JURISDICTION_ADGM_DISAGREE_MSG"),
      }
      await mutation(variables);
      goTo(Constants.PAGES.scopingDisagree, params);
      resolve();
    })
  }

  return (
    <React.Fragment>
      <div className={classes.contentData}>
        <div className={classes.centerFrame}>
          <div className={classes.claraTitle}>
            <TypographyLocal
              component={"span"}
              color={"black"}
              className={classes.flowTitle}
            >
              {translate("MODULES.FORMATION.SELECT_JURISDICTION_TITLE")}
            </TypographyLocal>
          </div>
          <div className={classes.claraQuestion}>
            <TypographyLocal
              variant={"h4"}
              color={"black"}
              component={"span"}
            >
              {translate(
                "MODULES.FORMATION.SELECT_FORM_JURISDICTION_DETAILS_QUESTION"
              )}
            </TypographyLocal>
          </div>
          <HelpLink 
            linkText={translate("MODULES.FORMATION.SELECT_JURISDICTION_LINK")}
            hrefUrl={linkJurisdiction}
          />
          <FormJurisdictionDetails 
            onCompleteSubmit={handleOnComplete}
            params={params}
          >
            <Button onClick={handlePrevious}>
              {translate("MODULES.FORMATION.FORM_JURISDICTION_BTN_PREVIOUS")}
            </Button>
            <Button onClick={handleCancel}>
              {translate("MODULES.FORMATION.FORM_JURISDICTION_BTN_CANCEL")}
            </Button>
          </FormJurisdictionDetails>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FormJurisdictionDetailsView;

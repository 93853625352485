import React from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";
const EditValuation = ({ go, ...props }) => {
  const params = useParams()
  return (
    <MicroFrontend id="MF_EditValuation" component={"./EditValuation"} mf="capTable" params={{ go, ...props, ...params }} />
  );
};

export default EditValuation;

import * as _ from 'lodash';
import React, { useState } from 'react';
import useGenerateDocument from "../../../../../../../hooks/services/modules/Document/useGenerateDocument";
import useRequestSignatures from "../../../../../../../hooks/services/modules/Document/useRequestSignatures";
import useResendRequestSignatures from "../../../../../../../hooks/services/modules/Document/useResendRequestSignatures";
import useSession from '../../../../../../../modules/session/hooks/useSession';
import { stripTypenames } from "../../../../../../../v1/utils/graphql-util";
import GetSignatureForm from '../../../../../../forms/modules/startups/Document/Generate/GetSignatureForm/GetSignatureForm';
import getDataByDocumentType from "../../../../../../forms/modules/startups/Document/Generate/Hooks/useGetDataByDocumentType";
import ModalScroll from '../../../../../../generic/Modal/ModalScroll/ModalScroll';

const GetSignatures = ({ configMutation, saveAndClose = true, data, open, handleClose, refresh, ...props }) => {

    const onCompleted = () => {
        handleClose();
        refresh && refresh();
        setSubmitting(false);
    };

    const { mutation, loading: loadingGenerateDocument } = useGenerateDocument({ onCompleted });
    const { mutation: requestSignatures } = useRequestSignatures({
        ...configMutation,
        successMessage: configMutation && configMutation.successMessage
			? configMutation.successMessage
			: "NOTIFICATION_SUCCESS_GETSIGNATURES_DOCUMENT",
        paramsSuccessMessage: configMutation && configMutation.paramsSuccessMessage
			? configMutation.paramsSuccessMessage
			: {name:_.get(data,"name")},
        onCompleted
    });
    const { mutation: resendRequestSignatures } = useResendRequestSignatures({
        ...configMutation,
        successMessage: configMutation && configMutation.successMessage
			? configMutation.successMessage
			: "NOTIFICATION_SUCCESS_RESEND_DOCUMENT",
        paramsSuccessMessage: configMutation && configMutation.paramsSuccessMessage
			? configMutation.paramsSuccessMessage
			: {name:_.get(data,"name")},
        onCompleted
    });
    
    const { startupId } = useSession();

    const [stakeholderList, setStakeholderList] = useState([]);

    const [groupCompaniesList, setGroupCompaniesList] = useState([]);

    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = async (values) => {
        const queryParams = {
            startupId,
            agreementId: _.get(data, "id", "").toString(),
            agreement: {
                ...stripTypenames(getDataByDocumentType(values, startupId,[])),
                file: stripTypenames(data.file),
                parentDocumentId: _.get(data, 'parentDocument.id', null),
                documentReplaced: _.get(data, 'isReplaced', false)
            }
        };
		setSubmitting(true);

        if (_.get(data, 'status') === 'Pending') {
            await resendRequestSignatures(queryParams);
        } else {
            await requestSignatures(queryParams);
        }

    };

    const handleSaveAndClose = async (values) => {
        const dataByDocumentType = {
            agreementId: _.get(data, "id", "").toString(),
            agreement: {
                ...stripTypenames(getDataByDocumentType(values, startupId,[])),
                file: stripTypenames(data.file),
                parentDocumentId: _.get(data, 'parentDocument.id', null),
                documentReplaced: _.get(data, 'isReplaced', false)
            },
            startupId
        };
        await mutation(dataByDocumentType)
    };

    return (
        <ModalScroll open={open} handleClose={handleClose} >
                {open && <GetSignatureForm
                    handleSubmit={handleSubmit}
                    initialValues={data}
                    handleCloseModal={handleClose}
                    handleSaveAndClose={handleSaveAndClose}
                    isSubmitting={submitting}
                    showSaveAndClose={saveAndClose}

                />}
        </ModalScroll>
    )
};

export default GetSignatures;

import React from 'react';
import ACTIONS from "../../../../constants/actions";
import PAGES from "../../../../constants/pages";
import ClaraLayout from "../../../../layouts/Clara/ClaraLayout";
import Text from "../../../../components/text/Text/Text";
import AcceleratorsView from "../accelerators/Accelerators";
import AssistanceAccelerators from 'src/modules/assistanceV4/components/AssistanceFrame2/assistanceAccelerators';

const moduleRouter = [
  {
    path: PAGES.accelerators.path,
    exact: true,
    breadCrumbs: {
      reset: true,
      add: false,
      label: <Text uuid={"BREADCRUMS_ACCELERATORS"} />,
    },
    component: <AcceleratorsView />,
    layout: ClaraLayout,
    propsLayout: {
      cantBack: true,
      startupMenu: true,
      userMenu: true,
      fullMenu: true,
      showAssistance: true,
      showBreadCrumbs: false,
      assistance: AssistanceAccelerators,
    },
    action: ACTIONS.VIEW_PROFILE,
  },
];

export default moduleRouter;
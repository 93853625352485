import React, { useMemo } from 'react';
import SelectInput from 'src/components/inputs/Select/SelectInput/SelectInput';
import useTranslate from "src/modules/generic/hooks/useTranslate";
import { Constants } from 'src/v1/utils/constants';


/**
 * Component to select mailing address type
 * @param {*} param0 
 * @returns 
 */
const SelectMailingAddressTypeInput = ({ ...props }) => {
    const { translate } = useTranslate();
    const mailingAddressTypeOptions = useMemo(() => {
        return [
            { value: Constants.COMPANIES.MAILING_ADDRESS_TYPE.BUSINESS, label: Constants.COMPANIES.MAILING_ADDRESS_TYPE_LABEL.BUSINESS },
            { value: Constants.COMPANIES.MAILING_ADDRESS_TYPE.RESIDENTIAL, label: Constants.COMPANIES.MAILING_ADDRESS_TYPE_LABEL.RESIDENTIAL }
        ];
    }, []);



    return (
        <SelectInput placeholder={translate("SELECT_INPUT_PLACEHOLDER")}
            clear={true} list={mailingAddressTypeOptions} {...props} />
    )
}

export default SelectMailingAddressTypeInput;
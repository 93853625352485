import React from 'react';
import classesInputPhone from './ClaraInputPhone.module.scss';
import {TextInput} from '../../../index';
import {Field} from 'formik';
import _ from "lodash"

const ClaraInputPhone = ({ label, form, field,onChange }) => {

    const nameField = field.name;


    return (
        <div className={classesInputPhone.containerInputText}>

            <label>{label}</label>


            <Field name={`${nameField}.prefix`}>
                {({ form, field }) => (
                    <TextInput
                        form={form}
                        field={field}
                        type='text'
                        placeholder={"+00"}
                        className={classesInputPhone.inputCode}
                        onChange= {(e)=>{
                            
                            if (onChange){
                                onChange(e.target.value,_.get(form,`values.${nameField}.number`),_.get(form,`values.${nameField}.extension`))
                            }
                        }}
                    />

                )}


            </Field>

            <Field name={`${nameField}.number`}>
                {({ form, field }) => (
                    <TextInput
                        form={form}
                        field={field}
                        type='text'
                        placeholder={"Number..."}
                        className={classesInputPhone.inputNumber}
                        onChange= {(e)=>{
                            if (onChange){
                                onChange(_.get(form,`values.${nameField}.prefix`),e.target.value,_.get(form,`values.${nameField}.extension`))
                            }
                        }}
                    />

                )}


            </Field>

            <Field name={`${nameField}.extension`}>
                {({ form, field }) => (
                    <TextInput
                        form={form}
                        field={field}
                        type='text'
                        placeholder={"Ext..."}
                        className={`${classesInputPhone.inputCode} ${classesInputPhone.inputExt}`}
                        onChange= {(e)=>{
                            if (onChange){
                                onChange(_.get(form,`values.${nameField}.prefix`),_.get(form,`values.${nameField}.number`),e.target.value)
                            }
                            
                        }}
                    />

                )}


            </Field>
        </div>
    )
}


export default ClaraInputPhone;

import React from 'react';
import _ from 'lodash';
import PropTypes from "prop-types";
import NoticesPanel from 'src/views/modules/startups/company/companyView/Tabs/KeyPeopleTab/panels/NoticesPanel';
import CorporateSignatoryPanel from './CorporateSignatoryPanel';
import DirectorsPanel from './DirectorsPanel';

function KeyPeopleTab({
  data,
  jurisdictions,
  isLock
}) {

  return (
    <>
      <DirectorsPanel data={data} isLock={isLock} />
      <CorporateSignatoryPanel data={data} isLock={isLock} />
      <NoticesPanel isStakeholder={true} data={data} isLock={isLock} />
    </>
  );
}

KeyPeopleTab.propTypes = {
  data: PropTypes.any,
  isLock: PropTypes.bool,
  jurisdictions: PropTypes.array
}

export default KeyPeopleTab;
import React, {useEffect, useState} from 'react';
import {Text} from '../index';
import _ from "lodash";
import classes from "./Chat.module.scss";
import useDocumentsForApproval from '../../Hooks/useDocumentsForApproval';
import {ApproveDocument} from "../../containers"

const ChatADGMApproveDocuments = ({ form, field, responseKeyName, initialValueStart, submitQuestion }) => {
  const nameField = responseKeyName ? responseKeyName + "." : "";
  
  const { data: documents,loading } = useDocumentsForApproval({variables: {matterId: initialValueStart.matterId} });
  const [showModal, setShowModal] = useState(false);
  const [parametersApprove, setParametersApprove] = useState({});

  useEffect(() => {
    let documentNoApproved = _.find(documents, (document) => {
      return document.status !== "Approved" &&  document.status !== "Declined" 
    });
    form.setFieldValue(nameField + "isAllApproved", !documentNoApproved);
    
  }, [JSON.stringify(documents)]);

  return (
    <React.Fragment>
      <div className={`${classes.ChatQuestionnaire} ${classes.ChatADGMApproveDocumentsView} ${classes.ChatADGMApproveDocuments}`}>
        {documents.map((document, i) => {
          return (
            <React.Fragment key={i}>
              <div className={classes.Document}>
                <label className={`${document.status}`}>
                  {document.status === "Approved" && <img src="/img/icons/document_approve.svg"/> }
                  {document.status === "Declined" && <img src="/img/icons/document_decline.svg"/> }
                  <span>{document.name}</span>
                </label>
                <button className={"genericWhiteBorder"} onClick={() => {
                  setParametersApprove({
                    matterId: initialValueStart.matterId,
                    fileId: document.file.id,
                    documentId: document.id,
                    documentName: document.file.name,
                  });
                  setShowModal(true)
                }}><Text uuid={"BUTTON_VIEW"} /></button>
              </div>
            </React.Fragment>
          )
        })}
      </div>
      {showModal && (
        <div className={classes.FormLayout}>
          <ApproveDocument canDecline={true} handleClose={() => {
            setShowModal(false);
            setParametersApprove({})
          }} {...parametersApprove} />
        </div>
      )}
    </React.Fragment >
  );
};

export default ChatADGMApproveDocuments;

import { useMemo } from 'react';
import useGetStakeholders from 'src/hooks/services/modules/stakeholders/useGetStakeholders'
import useSession from 'src/modules/session/hooks/useSession'
import _ from "lodash";
import useGetCompanyFormationData from './services/useGetCompanyFormationData';

const useServices = ({initialValues}) => {
  const { startupId } = useSession()
  const {manualQuery: getFormationData} = useGetCompanyFormationData({ variables: { 
    startupId,groupCompanyId: _.get(initialValues,"idGroupCompany"), 
  }},{});

  //Query for UAE or GCC Stakeholders
  const {
    manualQuery: manualQueryUAEorGCC
  } = useGetStakeholders({
    variables: { 
      startupId,groupCompanyId: _.get(initialValues,"idGroupCompany"), 
      filters: { kind: "STAKEHOLDER_INDIVIDUAL", nationalityGroup: ['UAE', 'GCC'] }
    }
  })
  //Query for Not GCC nor UAE Stakeholders
  const {
    manualQuery: manualQueryNotUAEorGCC
  } = useGetStakeholders({
    variables: {
      startupId,
      groupCompanyId: _.get(initialValues,"idGroupCompany"),
      filters: { kind: "STAKEHOLDER_INDIVIDUAL", nationalityGroup: ['NOT_GCC', 'NOT_UAE'] }
    }
  })
  
  //Query for UAE or GCC Stakeholders without role DIRECTOR or SHAREHOLDER
  const {
    manualQuery: manualQueryUAEorGCCNotRole
  } = useGetStakeholders({
    variables: { 
      startupId,groupCompanyId: _.get(initialValues,"idGroupCompany"), 
      filters: { 
        nationalityGroup: ['UAE', 'GCC'],
        role: ['FOUNDER', 'ADVISOR', 'EMPLOYEE', 'NOREMOVE', 'CONSULTANT', 'INVESTOR', 'CONVERTIBLEHOLDER', 'WARRANTHOLDER', 'OPTIONHOLDER', 'IPASSIGNOR', 'AUTHORIZEDSIGNATORY', 'THIRDPARTY'] 
      } 
    }
  })


  return useMemo(() => ({
  
    //Set the initial data that is going to be used on the flows
    initialData: async (context, event) => {
      let typeOfAuthorisedSignatory = 'VALID_STAKEHOLDER';
      const companyData = await getFormationData();

      if (_.get(companyData, 'data.getGroupCompany.formationData.isUsingNominee')) typeOfAuthorisedSignatory = 'NOMINEE';
      if (_.get(companyData, 'data.getGroupCompany.formationData.isUsingThirdParty')) typeOfAuthorisedSignatory = 'THIRD_PARTY';

      let st1 = await manualQueryUAEorGCC()
      let st2 = await manualQueryNotUAEorGCC()
      let st3 = await manualQueryUAEorGCCNotRole()
      
      return {
        ...initialValues,
        startupId,
        typeOfAuthorisedSignatory,
        companyData: _.get(companyData, "data.getGroupCompany", {}),
        stakeholdersUAEorGCC: _.get(st1, 'data.stakeholderList', []),
        stakeholdersNotUAEorGCC: _.get(st2, 'data.stakeholderList', []),
        manualQueryUAEorGCCNotRole: _.get(st3, 'data.stakeholderList', []),
      };
    },
    
  }), [initialValues]);
}

export default useServices;

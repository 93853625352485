import { Constants, helper } from "../../../v1/utils";
import React, { Component } from "react";

import NumberFormat from "react-number-format";

/*
    Properties:
        value: key to get config 
        lang: lang to use (not enabled now)
    
    reference: https://www.npmjs.com/package/react-number-format
*/
class Money extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: undefined,
    };
  }
  handleClose = () => {
    if (this.props.handleClose) {
      this.props.handleClose();
    }
  };
  componentWillMount() {
    let { title } = this.props;

    this.setState({
      title: title,
    });
  }

  handleFormat = (value) => {
    let count = 2;
    if (value) {
      if (value.toString().indexOf(".") >= 0) {
        count = value.toString().split(".")[1].length || 0;
        if (count < 2) {
          count = 2;
        }
      }
    }
    return count;
  };

  render() {
    const { value, params, showDecimals = true, suffix, prefix, noShowPrefix } = this.props;
    let format =
      helper.getFormatByKey(Constants.TYPE_FORMATS.MONEY, params) || {};

    if (params?.decimalScale) {
      format.decimalScale = params.decimalScale;
    } else {
      if (showDecimals) {
        format.decimalScale = this.handleFormat(value);
      } else {
        format.decimalScale = 0;
      }
    }
    if (suffix) {
      format.suffix = ` ${suffix}`;
    }
    
    if (prefix) {
      format.prefix = `${prefix} `;
    }

    if(noShowPrefix) {
      delete format.prefix
    }

    return (
      <>
        {value && <NumberFormat value={value} displayType="text" {...format} />}
      </>
    );
  }
}

export default Money;

import { Constants } from "src/v1/utils";
import Core from "./Core";
import Rol from "./Rol";
import _ from "lodash";

class Stakeholder extends Core {

  statusOnMatters: { onFields?: string[]; isRenewal?: boolean } = {};
  rolesInProgress: {
    toAdd: Rol[];
    toDelete: Rol[];
  };
  managedByClaraOnJurisdictionType: string[] = [];
  roles: any = {};
  isManagedByClara: boolean = false;
  isAnEntity: boolean = false;
  isAuthorizedSignatory: boolean = false;

  constructor(data: any) {
    super(data);
    this.statusOnMatters = data?.statusOnMatters;
    this.roles = data?.roles;
    this.isManagedByClara = data?.isManagedByClara;
    this.isAnEntity = data?.isAnEntity;
    this.isAuthorizedSignatory = data?.isAuthorizedSignatory;
    this.managedByClaraOnJurisdictionType =
      data?.managedByClaraOnJurisdictionType;
    this.rolesInProgress = {
      toAdd: [],
      toDelete: [],
    };
    _.forEach(data?.rolesInProgress?.toAdd, (value) => {
      this.rolesInProgress.toAdd.push(new Rol(value));
    });
    _.forEach(data?.rolesInProgress?.toDelete, (value) => {
      this.rolesInProgress.toDelete.push(new Rol(value));
    });
  }

  getStatusOnMatters = () => {
    return this.statusOnMatters;
  };

  getRoles = () => {
    return this.roles;
  };

  getManagedByClara = () => {
    return this.isManagedByClara;
  };

  /**
   *  This function search in the stakeholder statusOnMatter.onFields array if the field is present
   *  with this logic you can detect pincas in fields like "name", "email", "phone", "address", "country", "city", "postalCode"
   */
  getFieldInMatter = (field: string): null | boolean => {
    if (!this?.getStatusOnMatters()?.onFields) {
      return null;
    }
    return this.statusOnMatters?.onFields?.indexOf(field) > -1;
  };

  getJurisdictions = () => {
    const roles = this.getRoles();
    return _.reduce(
      roles,
      (types, role) => {
        const jurisdictionType = _.get(role, "groupCompany.jurisdictionType");
        if (jurisdictionType && !types.includes(jurisdictionType)) {
          types.push(jurisdictionType);
        }
        return types;
      },
      []
    );
  };

  getJurisdictionsUsingRolesAndRolesInProgress = () => {
    const basicRoles = this.getRoles();
    const rolesInProgress = this.rolesInProgress?.toAdd ?? [];
    const roles = _.concat(basicRoles, rolesInProgress);
    return _.reduce(
      roles,
      (types, role) => {
        const jurisdictionType = _.get(role, "groupCompany.jurisdictionType");
        if (jurisdictionType && !types.includes(jurisdictionType)) {
          types.push(jurisdictionType);
        }
        return types;
      },
      []
    );
  };

  isInADGM = () => {
    const jurisdictions = this.getJurisdictions();
    return jurisdictions.includes("ADGM");
  };

  isInCayman = () => {
    const jurisdictions = this.getJurisdictions();
    return jurisdictions.includes("Cayman");
  };

  isInDelaware = () => {
    const jurisdictions = this.getJurisdictions();
    return jurisdictions.includes("Delaware");
  };

  getIsRenewal = (): boolean => {
    if (!this?.getStatusOnMatters()?.isRenewal) {
      return false;
    }
    return this.statusOnMatters?.isRenewal;
  };

  getIsManagedByClara = (): boolean => {
    if (!this?.getManagedByClara()) {
      return false;
    }
    return this.isManagedByClara;
  };

  getIsManagedByClaraInSomeJurisdiction = (
    jurisdictions: string[]
  ): boolean => {
    return this.managedByClaraOnJurisdictionType.some((jurisdiction) => {
      return jurisdictions.includes(jurisdiction);
    });
  };

  isRolInPincas = (rol: string) => {
    const isPincasAdd = _.find(
      this.rolesInProgress?.toAdd ?? [],
      (role) => role.getName() === rol
    );
    if (isPincasAdd) {
      return true;
    }
    const isPincasRemove = _.find(
      this.rolesInProgress?.toDelete ?? [],
      (role) => role.getName() === rol
    );
    if (isPincasRemove) {
      return true;
    }
    return false;
  };

  /**
   *  This function search in roles array if the role is present
   */
  hasRole = (role: string) => {
    const roles = this.getRoles();
    return _.some(roles, { name: role });
  };

  /**
   *  This function detect if the stakeholder is a corporate entity
   */
  getIsCorporate = () => {
    return this.isAnEntity;
  };

  /**
   *  This function is used to detect if the stakeholder has pincas
   */
  hasPincas = () => {
    return (this?.getStatusOnMatters()?.onFields?.length ?? 0) > 0;
  };

  isUBO = () => {
    return this.hasRole(Constants.ROLES_CODE.UBO);
  };

}
export default Stakeholder;

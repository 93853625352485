import React, { Component } from "react";
import { connect } from "react-redux";
import classes from "./Issues.module.scss";
import { Date } from "../../../components";
import Category from "../../../components/DueDiligence/Overview/Category";
import SimpleModal from "../../../components/DueDiligence/ModalFilters/Modal";
import { goToPage, goToPrevPage } from "../../../../store/actions/page";
import { Constants } from "../../../utils/constants";
import Title from "./../../../../components/text/Title/Title";
import _ from "lodash";
import IconComment from "../../../components/Comments/IconComment";
import Scrollbar from "../../../../components/generic/Scrollbar/Scrollbar";

class Issues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalAll: 0,
      open: false,
      show: {
        RED: true,
        AMBER: true,
        GREEN: true,
        UNKNOWN: true,
      },
    };
  }

  update() {
    let totalAll = 0;
    _.forEach(this.props.data, (category, key) => {
      _.forEach(category, (subCategory, keysubCategory) => {
        _.forEach(subCategory.issues, (issue, keyIssue) => {
          totalAll++;
        });
      });
    });
    this.setState({
      totalAll: totalAll,
    });
  }

  getCountIssues(category) {
    let count = 0;
    if (category) {
      for (let i = 0; i < category.length; i++) {
        count = count + category[i].issues.length;
      }
    }
    return count;
  }

  componentWillMount() {
    this.update();
    //this.props.refresh()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.data !== this.props.data) {
      this.update();
    }
  }

  handleClose = (x) => {
    this.setState({ open: false });
  };

  handleClick = (e) => {
    this.anchorEl = e.currentTarget;
    this.setState({ open: true });
  };

  changeShow = (color) => {
    let { show } = this.state;
    show[color] = !show[color];
    this.setState({
      show,
    });
  };

  getContentFilter = () => {
    const checkRedClass = this.state.show.RED ? classes.CheckedRed : null;
    const checkYellowClass = this.state.show.AMBER
      ? classes.CheckedYellow
      : null;
    const checkGreenClass = this.state.show.GREEN ? classes.CheckedGreen : null;
    return (
      <div className={classes.ShowModal}>
        <div className={classes.TitleModal}>
          Show
          <a onClick={this.handleClose}></a>
        </div>
        <div className={classes.BodyModal}>
          <div className={classes.RowModal}>
            <a
              className={`${classes.Check} ${checkRedClass}`}
              onClick={(e) => this.changeShow("RED")}
            ></a>
            <label>Red</label>
          </div>
          <div className={classes.RowModal}>
            <a
              className={`${classes.Check} ${checkYellowClass}`}
              onClick={(e) => this.changeShow("AMBER")}
            ></a>
            <label>Ambar</label>
          </div>
          <div className={classes.RowModal}>
            <a
              className={`${classes.Check} ${checkGreenClass}`}
              onClick={(e) => this.changeShow("GREEN")}
            ></a>
            <label>Green</label>
          </div>
        </div>
      </div>
    );
  };

  handleUpdate = (issue, subCategory, category) => {
    const issueToModify = {
      ...issue,
      category,
      subCategory,
      urgency: issue.status,
    };
    this.props.goToPage(Constants.PAGES.viewHealthCheckIssue, issueToModify);
  };

  getCountIssuesBySubCategory = (issues) => {
    let count = 0;
    for (let i = 0; i < issues.length; i++) {
      const issue = issues[i];
      if (this.state.show[issue.status]) {
        count++;
      }
    }
    return count;
  };

  getTotalIssues = (subatecories) => {
    var count = 0;
    _.forEach(subatecories, (subCagtegory, i) => {
      count = count + this.getCountIssuesBySubCategory(subCagtegory.issues);
    });
    return count;
  };

  myHandleClose = () => {
    //console.log(this.props.handleClose,'handleClose')
    //this.props.handleClose('issue')
    this.setState({ openUpdate: false });
  };

  onChange = (categoryKey, subCategoryKey, title) => {
    this.props.onChange(categoryKey, subCategoryKey, title);
  };

  render() {
    const { open, issueToModify } = this.state;
    const { data, category, subCategory } = this.props;
    const id = open ? "simple-popper" : null;
    const corporateStructureCategory = this.props.categories.find(
      (cat) => cat.key === "CORPORATE_STRUCTURE"
    );
    const equityCategory = this.props.categories.find(
      (cat) => cat.key === "EQUITY"
    );
    const governanceCategory = this.props.categories.find(
      (cat) => cat.key === "GOVERNANCE"
    );
    const teamCategory = this.props.categories.find(
      (cat) => cat.key === "TEAM"
    );
    const ipCategory = this.props.categories.find(
      (cat) => cat.key === "INTELLECTUAL_PROPERTY"
    );
    const otherCategory = this.props.categories.find(
      (cat) => cat.key === "OTHER"
    );

    return (
      <div className={classes.Issues}>
        <div className={classes.ZoneMenu}>
          <Scrollbar className={classes.noHorizontalScroll}>
            <div className={classes.ZoneChartTitle}>
              <h2>
                {this.state.totalAll}{" "}
                {this.state.totalAll === 1 ? `remark` : `remarks`} in total
              </h2>
            </div>
            <Category
              data={this.props.data[corporateStructureCategory.key]}
              title={corporateStructureCategory.title}
              categoryKey={corporateStructureCategory.key}
              onChange={this.onChange}
              className={classes.CategoryIssue}
              categorySelected={category}
              subCategorySelected={subCategory}
            ></Category>
            <Category
              data={this.props.data[equityCategory.key]}
              title={equityCategory.title}
              categoryKey={equityCategory.key}
              onChange={this.onChange}
              className={classes.CategoryIssue}
              categorySelected={category}
              subCategorySelected={subCategory}
            ></Category>
            <Category
              data={this.props.data[governanceCategory.key]}
              title={governanceCategory.title}
              categoryKey={governanceCategory.key}
              onChange={this.onChange}
              className={classes.CategoryIssue}
              categorySelected={category}
              subCategorySelected={subCategory}
            ></Category>
            <Category
              data={this.props.data[teamCategory.key]}
              title={teamCategory.title}
              categoryKey={teamCategory.key}
              onChange={this.onChange}
              className={classes.CategoryIssue}
              categorySelected={category}
              subCategorySelected={subCategory}
            ></Category>
            <Category
              data={this.props.data[ipCategory.key]}
              title={ipCategory.title}
              categoryKey={ipCategory.key}
              onChange={this.onChange}
              className={classes.CategoryIssue}
              categorySelected={category}
              subCategorySelected={subCategory}
            ></Category>
            <Category
              data={this.props.data[otherCategory.key]}
              title={otherCategory.title}
              categoryKey={otherCategory.key}
              onChange={this.onChange}
              className={classes.CategoryIssue}
              categorySelected={category}
              subCategorySelected={subCategory}
            ></Category>
          </Scrollbar>
        </div>
        <div className={classes.ZoneIssues} id={`zoneIssues`}>
          <Scrollbar>
            <div className={classes.headerIssues}>
              {/* <pre>{JSON.stringify(data,null,4)}</pre> */}
              <div className={`${classes.title} `}>
                <h5 className={classes.h5}>{this.props.title}</h5>
                <span className={classes.issuesTotal}>
                  {this.getTotalIssues(data[this.props.category])}{" "}
                  {this.getTotalIssues(data[this.props.category]) === 1
                    ? `Remark`
                    : `Remarks`}
                </span>
                {/* <a
                  className={classes.filterIcon}
                  aria-describedby={id}
                  variant="contained"
                  onClick={this.handleClick}
                >
                  <i></i>
                </a> */}
                <SimpleModal
                  open={open}
                  id={id}
                  handleClose={this.handleClose}
                  anchorEl={this.anchorEl}
                >
                  {this.getContentFilter()}
                </SimpleModal>
              </div>
              <div className={classes.contentIssues}>
                {data[this.props.category] &&
                  data[this.props.category].map((element, key) => {
                    return (
                      <div key={key} id={`div_issue_key_${element.key}`}>
                        <div key={key} className={classes.nameIssue}>
                          <h5 key={key} className={classes.h7}>
                            {element.name}
                          </h5>
                          {/* <span className={classes.issuesItem}>
                            {this.getCountIssuesBySubCategory(element.issues)}{" "}
                            Remarks
                          </span> */}
                        </div>
                        <div>
                          {element.issues.map((issue, key) => {
                            if (this.state.show[issue.status]) {
                              let fullName = `${_.get(
                                issue.createdBy,
                                "firstName",
                                ""
                              )} ${_.get(issue.createdBy, "lastName", "")}`;

                              let user = !_.eq(fullName, " ")
                                ? fullName
                                : "Clara Platform";

                              return (
                                <div
                                  key={key}
                                  onClick={() =>
                                    this.handleUpdate(
                                      issue,
                                      element.key,
                                      this.props.category
                                    )
                                  }
                                >
                                  {/* <pre>{JSON.stringify(`${classes[issue.status]}`,null,2)}</pre> */}
                                  <div
                                    className={`${classes[issue.status]} ${
                                      classes.issueMargin
                                    }`}
                                  >
                                    <div>
                                      <p className={classes.cardTitle}>
                                        {issue.title}
                                      </p>
                                      <IconComment
                                        classes={{ root: classes.positionIcon }}
                                        cant={issue.countComments}
                                        hasNews={issue.viewComments}
                                      />
                                    </div>
                                    <p className={classes.cardText}>
                                      {issue.description}
                                    </p>
                                    <div className={classes.contanierUser}>
                                      <label>
                                        Remark created: <span>{user}</span>
                                      </label>
                                      <label>
                                        Last updated:{" "}
                                        <span>
                                          {issue.updatedAt ? (
                                            <Date value={issue.updatedAt} />
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </Scrollbar>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) => ({
  goToPrevPage: () => {
    goToPrevPage(dispatch);
  },
  goToPage: (page, params) => {
    goToPage(dispatch, page, params);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Issues);

import useQuery from "../../../../../hooks/custom/useQuery";
import _ from 'lodash'
import {ADD_SHARE_CLASS,ADD_SHARE_CLASS_SIP} from "../fragments";

export const GET_GROUP_COMPANIES = `query getGroupCompanies($startupId: ID!) {
    getGroupCompanies(startupId: $startupId) {
      id
      name
      kind
      isLocked
      isHoldingCompany
      transactionCurrency
    }
  }`;
export const GET_GROUP_COMPANIES_SHARE_CLASS=`
query getGroupCompanies($startupId: ID!) {
    getGroupCompanies(startupId: $startupId) {
      id
      name
      kind
      isLocked
      isHoldingCompany
      transactionCurrency
    }
  }
`
const GET_GROUP_COMPANIES_SHARE_CLASS_SIP=`
query getGroupCompanies($startupId: ID!) {
    getGroupCompanies(startupId: $startupId) {
      id
      kind
      name
      isHoldingCompany
      isLocked
      transactionCurrency
    }
  }
`

const getQuery=(query)=>{
    switch (query){
        case 'SIP':
            return GET_GROUP_COMPANIES_SHARE_CLASS_SIP
        case 'SHARE':
            return GET_GROUP_COMPANIES_SHARE_CLASS
        default:
            return GET_GROUP_COMPANIES
    }
}
const useGetGroupCompanies=(variables,config)=>{
    const {data,loading}=useQuery(getQuery(_.get(config,'query')),{...variables},{...config})
    return {data:_.get(data,'getGroupCompanies'),loading}
}
export default useGetGroupCompanies

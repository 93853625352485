import React, { FC } from "react";
import classesModule from './InvoicesViewSkeleton.module.scss';
import Skeleton from '@material-ui/lab/Skeleton';

/**
 * Template Invoices View Skeleton
 */

export interface InvoicesViewSkeletonProps {
  isLoading?: boolean,
} 

const InvoicesViewSkeleton: FC<InvoicesViewSkeletonProps> = ({
  isLoading = false,
  ...props
}:InvoicesViewSkeletonProps) => {

  return (
    <>
      {isLoading && (
        <div>
          <table className={`${classesModule.table}`}>
            <thead>
              <tr>
                <th className={classesModule.t1}><Skeleton variant="text" height={"0.9231rem"} width={"3rem"} /></th>
                <th className={classesModule.t2}><Skeleton variant="text" height={"0.9231rem"} width={"3rem"} /></th>
                <th className={classesModule.t3}><Skeleton variant="text" height={"0.9231rem"} width={"3rem"} /></th>
                <th className={classesModule.t4}><Skeleton variant="text" height={"0.9231rem"} width={"3rem"} /></th>
                <th className={classesModule.t5}><Skeleton variant="text" height={"0.9231rem"} width={"6rem"} /></th>
              </tr>
            </thead>
            <tbody>
              {Array(3).fill(1).map((el, i) =>
                <tr>
                  <td><Skeleton variant="text" height={"0.9231rem"} width={"4rem"} /></td>
                  <td><Skeleton variant="text" height={"0.9231rem"} width={"4rem"} /></td>
                  <td><Skeleton variant="text" height={"0.9231rem"} width={"4rem"} /></td>
                  <td><Skeleton variant="text" height={"0.9231rem"} width={"4rem"} /></td>
                  <td><Skeleton variant="text" height={"0.9231rem"} width={"15rem"} /></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default InvoicesViewSkeleton;

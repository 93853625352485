import useQuery from '../../../../../hooks/custom/useQuery';
import {RoleFields} from "../../../../../modules/stakeholder/graphql/typePolicies";
import {NoticesFragment} from "../../../../../modules/startup/Stakeholder/graphql/fragments";
import {AuthorizedSignatoryFragment} from "../../../../../modules/startup/Stakeholder/graphql/fragments";

const GET_STAKEHOLDERS = `
${RoleFields}
${NoticesFragment}
${AuthorizedSignatoryFragment}
query stakeholderList($startupId:ID! ) {
    stakeholderList(startupId:$startupId) {
		fullName
		email
		isFounder
		passportNumber
		registeredNumber
		nationality {name code}
		jurisdiction
		type
		phoneNumber
		id
		isAnEntity
		address {
			city
			state
			country {name code}
			zipCode 
			street
		}
		roles { ...RoleFields }
		startupPosition {
			id
			department {id}
			position {
				id
				code
				name
				isDefault
				responsibilities {
					code
					text
					name
					isDefault
				}
			}
		}
		authorizedSignatory {...AuthorizedSignatoryFragment}
		recipientNotice {...NoticesFragment}
	}
}`;

const useGetStakeholders = (variables, config) => {
	const { loading, error, data, refetch } = useQuery(GET_STAKEHOLDERS, variables, config);

	return { loading, error, data, refetch };
};

export default useGetStakeholders;

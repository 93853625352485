
import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import ModalAssistant, { ModalAssistantProps } from '../../../../generic/components/Modal/ModalAssistant'
import Wizard from '../../../../generic/components/Wizard'
import useModal from "../../../../generic/hooks/useModal"
import { clearAssistatText } from '../../../../generic/store/action'
import Machine from './machine'

interface ModalConfirmShareholdersDirectorsProps extends ModalAssistantProps {
  paramsMutation: any,
  onClose(): void
}

const ModalConfirmShareholdersDirectors: FC<ModalConfirmShareholdersDirectorsProps> = ({ 
  children,
  open: openParam = false,
  paramsMutation,
  ...props
}: ModalConfirmShareholdersDirectorsProps) => {

  const [open, setOpen] = useState(openParam);
  const { closeModal: onClose } = useModal();
  const dispatch = useDispatch();

  useEffect(() => {
    setOpen(openParam);
  }, [openParam])

  const handleClose = (values) => {
    onClose();
    setOpen(false);
  }

  const handleInitModal = () => {
    dispatch(clearAssistatText());
  }

  useEffect(() => {
    handleInitModal()
    return () => {
      dispatch(clearAssistatText());
    }
  }, []);

  return (
    <ModalAssistant open={open}>
      <Wizard machine={Machine} initialValues={{ paramsMutation }} onComplete={handleClose} />
    </ModalAssistant>
  )
}

export default ModalConfirmShareholdersDirectors;
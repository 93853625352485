import _ from 'lodash'
import FirstStep from "./Steps/FirstStep";
import SecondStep from "./Steps/SecondStep";
import ThirdStep from "./Steps/ThirdStep";
import ForthStep from "./Steps/ForthStep";
import Machine from "../../../../generic/components/Machine/MachineClass";
import FifthStep from "./Steps/FifthStep";

const isThereSharesInCompanies = ({ data }) => data.is_there_shares_in_companies.isThereCompaniesShares
const isNotSharesInCompanies = ({ data }) => !data.is_there_shares_in_companies.isThereCompaniesShares
const addNewCompany = ({ data }) => _.some(data.select_companies.companies, gc => gc === 'ADD')
const notAddNewCompany = ({ data }) => !_.some(data.select_companies.companies, gc => gc === 'ADD')
const isThereCompanies = ({ paramsData }) => !_.isEmpty(_.get(paramsData, 'startup.groupCompanies', []))
const isNotThereCompanies = ({ paramsData }) => _.isEmpty(_.get(paramsData, 'startup.groupCompanies', []))
const isThereMoreCompanies1 = ({ data }) => _.get(data, 'is_there_more_companies_1.isThereMoreCompanies')
const isThereMoreCompanies2 = ({ data }) => _.get(data, 'is_there_more_companies_2.isThereMoreCompanies')
const isThereMoreCompanies3 = ({ data }) => _.get(data, 'is_there_more_companies_3.isThereMoreCompanies')
const isNotThereMoreCompanies1 = ({ data }) => !_.get(data, 'is_there_more_companies_1.isThereMoreCompanies')
const isNotThereMoreCompanies2 = ({ data }) => !_.get(data, 'is_there_more_companies_2.isThereMoreCompanies')
const isNotThereMoreCompanies3 = ({ data }) => !_.get(data, 'is_there_more_companies_3.isThereMoreCompanies')

//TODO:Use a manager to state previous state depending of step and context
const previousManager = (step, context) => {
  switch (true) {
  }
}

const machineDefinition = {
  id: 'assets_and_revenues',
  initial: 'is_there_shares_in_companies',
  states: {
    is_there_shares_in_companies: {
      on: {
        NEXT: { target: 'set_shares_in_companies', actions: 'setContext' },
        CANCEL: 'finish_assets_and_revenues'
      }
    },
    set_shares_in_companies: {
      exit: '',
      on: {
        '': [
          { target: 'is_there_companies', cond: 'isThereSharesInCompanies' },
          { target: 'is_company_have_shares_formation_company_1', cond: 'isNotSharesInCompanies' },
        ]
      }
    },
    is_company_have_shares_formation_company_1: {
      on: {
        NEXT: { target: 'finish_assets_and_revenues', actions: 'setContext' },
        PREVIOUS: 'is_there_shares_in_companies',
        CANCEL: 'finish_assets_and_revenues'
      }
    },
    is_there_companies: {
      exit: '',
      on: {
        '': [
          { target: 'select_companies', cond: 'isThereCompanies' },
          { target: 'create_company_1', cond: 'isNotThereCompanies' },
        ]
      }
    },
    create_company_1: {
      on: {
        NEXT: { target: 'is_there_more_companies_1', actions: ['setContext', 'setGlobalValues'] },
        PREVIOUS: 'is_there_shares_in_companies',
        CANCEL: 'finish_assets_and_revenues'
      }
    },
    is_there_more_companies_1: {
      on: {
        NEXT: { target: 'set_is_there_more_companies_1', actions: 'setContext' },
        PREVIOUS: 'create_company_1',
        CANCEL: 'finish_assets_and_revenues'
      }
    },
    set_is_there_more_companies_1: {
      exit: '',
      on: {
        '': [
          { target: 'create_company_1', cond: 'isThereMoreCompanies1' },
          { target: 'is_company_have_shares_formation_company_2', cond: 'isNotThereMoreCompanies1' },
        ]
      }
    },
    is_company_have_shares_formation_company_2: {
      on: {
        NEXT: { target: 'finish_assets_and_revenues', actions: 'setContext' },
        PREVIOUS: 'is_there_more_companies_1',
        CANCEL: 'finish_assets_and_revenues'
      }
    },
    select_companies: {
      on: {
        NEXT: { target: 'add_new_company', actions: 'setContext' },
        PREVIOUS: 'is_there_shares_in_companies',
        CANCEL: 'finish_assets_and_revenues'
      }
    },
    add_new_company: {
      exit: '',
      on: {
        '': [
          { target: 'create_company_2', cond: 'addNewCompany' },
          { target: 'is_there_more_companies_3', cond: 'notAddNewCompany' },
        ]
      }
    },
    create_company_2: {
      on: {
        NEXT: { target: 'is_there_more_companies_2', actions: ['setContext', 'setGlobalValues'] },
        PREVIOUS: 'select_companies',
        CANCEL: 'finish_assets_and_revenues'
      }
    },
    is_there_more_companies_2: {
      on: {
        NEXT: { target: 'set_is_there_more_companies_2', actions: 'setContext' },
        PREVIOUS: 'create_company_2',
        CANCEL: 'finish_assets_and_revenues'
      }
    },
    set_is_there_more_companies_2: {
      exit: '',
      on: {
        '': [
          { target: 'create_company_3', cond: 'isThereMoreCompanies2' },
          { target: 'is_company_have_shares_formation_company_3', cond: 'isNotThereMoreCompanies2' },
        ]
      }
    },
    is_there_more_companies_3: {
      on: {
        NEXT: { target: 'set_is_there_more_companies_3', actions: 'setContext' },
        PREVIOUS: 'select_companies',
        CANCEL: 'finish_assets_and_revenues'
      }
    },
    create_company_3: {
      on: {
        NEXT: { target: 'is_there_more_companies_3', actions: ['setContext', 'setGlobalValues'] },
        PREVIOUS: 'is_there_more_companies_2',
        CANCEL: 'finish_assets_and_revenues'
      }
    },
    set_is_there_more_companies_3: {
      exit: '',
      on: {
        '': [
          { target: 'create_company_3', cond: 'isThereMoreCompanies3' },
          { target: 'is_company_have_shares_formation_company_4', cond: 'isNotThereMoreCompanies3' },
        ]
      }
    },
    is_company_have_shares_formation_company_4: {
      on: {
        NEXT: { target: 'finish_assets_and_revenues', actions: 'setContext' },
        PREVIOUS: 'is_there_more_companies_3',
        CANCEL: 'finish_assets_and_revenues'
      }
    },
    is_company_have_shares_formation_company_3: {
      on: {
        NEXT: { target: 'finish_assets_and_revenues', actions: 'setContext' },
        PREVIOUS: 'is_there_more_companies_2',
        CANCEL: 'finish_assets_and_revenues'
      }
    },
    finish_assets_and_revenues: {
      type: 'final',
      entry: 'final',
    },
    onDone: {
      actions: 'stopMachine'
    }
  }
}
const options = {
  guards: {
    isThereSharesInCompanies,
    isNotSharesInCompanies,
    isThereCompanies,
    isNotThereCompanies,
    addNewCompany,
    notAddNewCompany,
    isThereMoreCompanies1,
    isThereMoreCompanies2,
    isThereMoreCompanies3,
    isNotThereMoreCompanies1,
    isNotThereMoreCompanies2,
    isNotThereMoreCompanies3,
  }
}

const steps = {
  is_there_shares_in_companies: FirstStep,
  select_companies: SecondStep,
  is_there_more_companies_1: ThirdStep,
  is_there_more_companies_2: ThirdStep,
  is_there_more_companies_3: ThirdStep,
  create_company_1: ForthStep,
  create_company_2: ForthStep,
  create_company_3: ForthStep,
  is_company_have_shares_formation_company_1: FifthStep,
  is_company_have_shares_formation_company_2: FifthStep,
  is_company_have_shares_formation_company_3: FifthStep,
  is_company_have_shares_formation_company_4: FifthStep,
}
class MachineAssetsAndRevenues extends Machine {
  constructor() {
    super(machineDefinition, options, steps);
  }
  getParamsData(initialData: object) {
    return initialData
  }
  getInitialValues(initialData: object): object {
    return {}
  }
}
export default MachineAssetsAndRevenues
import classnames from "classnames";
import classes from "./classes.module.scss";
import React, { FC } from "react";
import TypographyLocal from "../../../generic/components/Texts/Typography";
import Icon from "../../../generic/components/Icon";
import useTranslate from "../../../../modules/generic/hooks/useTranslate";

/**
 * Empty State component
 */

export interface EmptyStateProps {
  /** Property iconType */
  iconType?: string,
  /** Property title */
  title?: string,
} 

const EmptyState: FC<EmptyStateProps> = ({
  iconType = "SAFE",
  title,
  ...props
}:EmptyStateProps) => {

  const { translate } = useTranslate();
  const defaultTitle = translate("GENERIC_EMPTY_STATE");

  return (
    <React.Fragment>
      <div
        className={classnames(`${classes.contentsState}`, {})} 
      >
        <div className={classnames(`${classes.blueIcon}`, {})}>
          <Icon icon={iconType} size={"7.3846rem"} isClara={true}></Icon>
        </div>
        <TypographyLocal
          variant={"h6"}
          color={"dark-grey"}
          className={classnames(classes.titleFontStyle)}
        >
          <span className={classnames(`${classes.textTitle}`, {})} dangerouslySetInnerHTML={{__html: !!title ? title : defaultTitle}}></span>
        </TypographyLocal>
      </div>
    </React.Fragment>
  );
};

export default EmptyState;
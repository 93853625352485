
import equity from "./../langs/equity/us"
import fot from "./../langs/fot/us"
import optionPlan from "./../langs/optionPlan/us"
import shareClass from "./../langs/shareClass/us"
import modelling from "./../langs/modelling/us"
import valuation from "./../langs/valuation/us"
import vestingTerms from "./../langs/vestingTerms/us"
import legacy from "./../langs/legacy/us" 

const  lang = {
    US: {
        ...equity,
        ...optionPlan,
        ...fot,
        ...shareClass,
        ...modelling,
        ...valuation,
        ...vestingTerms,
        ...legacy, // old messages templates
    }
}
export default lang
import _ from 'lodash'
import useMachine from '../../../../../generic/context/MachineContext/useMachine';
import React, { useState, useEffect } from 'react';
import Button from "../../../../../generic/components/Buttons/Button";
import useTranslate from '../../../../../generic/hooks/useTranslate';
import { useDispatch } from "react-redux";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import FormSourceOfFunds from "../../../Cayman/Forms/SourceOfFundsForm";
import { addAssistantText, clearAssistatText } from "../../../../../generic/store/action";
import useModal from "../../../../../generic/hooks/useModal";

/**
 * Step para consultar los datos de la persona a ver el scoring
 * @param {*} param0
 * @returns
 */
function SelectJurisdiction({paramsMutation, open: openParam = false,
}) {
  const { next, send, context, prev } = useMachine();

  const { translate } = useTranslate()
  const [open, setOpen] = useState(openParam);
  const { closeModal: onClose } = useModal();
  const dispatch = useDispatch();
  const defaultData = _.find(context.getJurisdiction.sourcesOfFunds, (o)=>{return o.jurisdictionType=='CAYMAN'})
  const [changed, setChanged] = useState(false);
  const [hasPrev, setHasPrev] = useState(false);

  useEffect(() => {
    let countValids = 0;
    _.forEach(_.get(context, 'getJurisdiction.jurisdiction'), (jurisdiction) => {
      if (jurisdiction) {
        countValids++;
      }
    });
    const result = countValids >= 2;
    setHasPrev(result);
  }, []);

  const handleCancel = (values) => {
    onClose();
    setOpen(false);
    clearAssistatText()
  }

  const handlePrevious = () => {
    prev()
  }

  const handleInitModal = () => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText('Clara needs this confirmation as assurance that no proceeds of crime are being used to fund the new company, or being transferred through it. Click <a href="https://help.clara.co/en/articles/8391040-what-does-source-of-wealth-mean-in-cayman" target="_blank">here</a> to learn more.'))
  }

  const handleCompleteSubmit = async (values) => {
    values.jurisdictionType='CAYMAN';
    values.sourceOfFundsYesNo= values.sourceOfFundsYesNo==='true';
    next(values)
  }

  useEffect(() => {
    handleInitModal()
    return () => {
        dispatch(clearAssistatText());
    }
  }, []);

  return (
    <FormSourceOfFunds
      buttonSubmitProps={{handleDisabled:()=>{return !changed}}}
      onlySendDirty = {true}
      initialValues={defaultData}
      isEdit={true}
      buttons={{
        previous: (hasPrev && <Button onClick={handlePrevious} variant="secondary">{translate('GENERIC_BUTTON_PREVIOUS')}</Button>),
        cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
      }}
      onCompleteSubmit={handleCompleteSubmit}
      propsTemplate={{
        title: 'Confirm source of wealth',
        subTitle: 'Source of wealth'
      }}
      Template={TemplateWizard}
      hasChanged={()=>{setChanged(true)}}

    />
  );
}
export default SelectJurisdiction;

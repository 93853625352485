import React, { FC, useState, useEffect } from 'react'
import Wizard from '../../../generic/components/Wizard'
import ModalAssistant, { ModalAssistantProps } from '../../../generic/components/Modal/ModalAssistant'
import MachineShareOptions from './machine'
import { addAssistantText, clearAssistatText } from '../../../generic/store/action'
import { useDispatch } from 'react-redux'
import useTranslate from '../../../generic/hooks/useTranslate'
import useModal from '../../../generic/hooks/useModal'

interface ModalProfileStakeholderProps extends ModalAssistantProps {
    initialValues: object,
    onClose(): void;
}

const ModalShareOptions: FC<ModalProfileStakeholderProps> = ({ open: openParam = false, initialValues, ...props }: ModalProfileStakeholderProps) => {

    const [open, setOpen] = useState(openParam);

    const dispatch = useDispatch();

    const { translate } = useTranslate();

    const { closeModal: onClose } = useModal()

    useEffect(() => {
        setOpen(openParam);
    }, [openParam])
    const handleClose = (values) => {
        onClose()
        setOpen(false);
    }

    const handleInitModal = () => {
        dispatch(clearAssistatText());
        dispatch(addAssistantText(translate('MODULES.MAP.WIZARD_COMPANY_DETAIL_INIT_TEXT_ASSISTANCE'), "text"));
    }


    useEffect(() => {
        handleInitModal();
        return () => {
            dispatch(clearAssistatText());
        }
    }, []);


    return (
        <ModalAssistant open={open}>
            <Wizard machine={MachineShareOptions} initialValues={initialValues} onComplete={handleClose} />
        </ModalAssistant>

    )
}

export default ModalShareOptions;





import _, { get } from 'lodash'
import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux"
import Button from '../../../../generic/components/Buttons/Button'
import { StepProps } from "../../../../generic/components/Wizard/Step"
import useRegisterAsCheckedTask from "../../../../generic/graphql/mutation/useRegisterAsCheckedTask"
import useCustomMachine from '../../../../generic/hooks/useCustomMachine'
import useTranslate from '../../../../generic/hooks/useTranslate'
import { addAssistantText, clearAssistatText, removeAssistantText } from "../../../../generic/store/action"
import TemplateWizard from '../../../../generic/templates/Modal/TemplateWizard'
import useSession from '../../../../session/hooks/useSession'
import Form from "../../Forms/FormSelectShareOptions"
const Step1: FC<StepProps> = () => {

    const { translate } = useTranslate();
    const dispatch=useDispatch()
    const { startupId } = useSession();
    const {mutation}=useRegisterAsCheckedTask({showSuccessNotification:false})
    const assistantText=useSelector(state=>get(state,'generic.assistantTexts'))
    /*Function*/


    const { next, send, state } = useCustomMachine();

    useEffect(()=>{
        dispatch(clearAssistatText())
        dispatch(addAssistantText(translate('MODULES.STAKEHOLDER.SHARE_OPTIONS.FIRST_STEP.TOOLTIP'),'text'))
    },[])

    const handleCompleteSubmit = async values => {
        if(values.mode==='CANCEL') {

            const {paramsData={}}=state.context

            const {stakeholderId}=paramsData;

            const registeredTaskData = {
                entityId:stakeholderId,
                taskCode: "TASK_ADD_SHARES_OPTIONS"
            }

            await mutation({startupId, registeredTaskData})
        }
        next(values);
        dispatch(clearAssistatText())
    }
    const handleCancel = async values => {
        send("CANCEL");
    }
    const onChange=(values)=>{
        if(values==='CANCEL') dispatch(addAssistantText(translate('MODULES.STAKEHOLDER.SHARE_OPTIONS.FIRST_STEP.TOOLTIP_CANCEL')))
        else if(_.size(assistantText)===2) dispatch(removeAssistantText())
    }
    return (
        <React.Fragment>
            <Form
                initialValues={get(state, 'context.data.personal_information', {})}
                onCompleteSubmit={handleCompleteSubmit}
                Template={TemplateWizard}
                propsTemplate={{
                    title:translate('MODULES.STAKEHOLDER.SHARE_OPTIONS.FIRST_STEP.TITLE'),
                    subTitle: translate('MODULES.STAKEHOLDER.SHARE_OPTIONS.FIRST_STEP.SUBTITLE')
                }}
                onChange={onChange}
                buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }}>
            </Form>

        </React.Fragment>
    )
}

export default Step1;

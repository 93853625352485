import React, { useEffect, useMemo, useRef, useState } from "react";
import ControllerInput from "../../../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import SelectAddInput from "../../../../../inputs/Select/SelectAddInput/SelectAddInput";
import FormAddShareIncentivePlan from "./FormAddShareIncentivePlan";
import { useFormContext } from "react-hook-form";
import _ from "lodash";
import useSession from "../../../../../../modules/session/hooks/useSession";
import TemplateLabel from '../../../../../../modules/generic/templates/Label';
import useTranslate from "../../../../../../modules/generic/hooks/useTranslate";

const SelectShareincentivePlan = ({
  name,
  formsAdd,
  observerField,
  menuProps,
  list,
  refreshGC,
  ...props
}) => {

  const { translate } = useTranslate();
  const { watch } = useFormContext();
  const valueObserver = watch(observerField);
  const value = watch(name);
  const refSelect = useRef();
  const { startupId } = useSession();
  const [disabled, setDisabled] = useState(false);

  const getShareIncentivePlans = () => {
    if (_.isEmpty(valueObserver)) {
      return [];
    }
    const findShareClassList = _.find(list, (gc) =>
      _.some(gc.shareClass, (sc) => valueObserver.id === sc.id)
    );
    if (!findShareClassList) return [];
    const shareClass = _.find(
      findShareClassList.shareClass,
      (sc) => valueObserver.id === sc.id
    );
    return _.map(
      _.get(shareClass, "shareIncentivePlans", []),
      ({ name, ...props }) => ({
        label: name,
        value: { ...props, name },
        type: "shareIncentivePlan",
      })
    );
  };

  const groupCompanyId = useMemo(() => {
    if (_.isEmpty(valueObserver)) {
      setDisabled(true);
      return "";
    }
    setDisabled(false);
    const findShareClassList = _.find(list, (gc) =>
      _.some(gc.shareClass, (sc) => valueObserver.id === sc.id)
    );
    return _.get(findShareClassList, "id", null);
  }, [JSON.stringify(valueObserver)]);

  const [listShareIncentivePlans, setListShareIncentivePlans] = useState(
    getShareIncentivePlans()
  );

  const handleAddShareClass = (value) => {
    setListShareIncentivePlans((pre) => [
      ...pre,
      { value: value, label: value.name },
    ]);
    refSelect.current.handleSelected({ value: value, label: value.name });
  };

  useEffect(() => {
    setListShareIncentivePlans(getShareIncentivePlans());
  }, [JSON.stringify(valueObserver)]);

  useEffect(() => {
    if (_.isEmpty(value)) {
    }
  }, [JSON.stringify(valueObserver)]);

  useEffect(() => {
    if (value && _.isEmpty(valueObserver)) {
      refSelect.current.clearValue();
    }
  }, [JSON.stringify(valueObserver)]);

  const formsAddShareClass = [
    {
      type: "shareIncentivePlan",
      form: FormAddShareIncentivePlan,
      handleAddValueList: handleAddShareClass,
      propsForm: {
        startupId,
        shareClassId: _.get(valueObserver, "id"),
        groupCompanyId,
      },
    },
  ];

  const menuPropsShareClass = {
    addList: [
      {
        label: "Add New Share Incentive Plan",
        type: "shareIncentivePlan",
      },
    ],
    propsList: [{ type: "shareIncentivePlan" }],
  };

  return (
    <React.Fragment>
      <TemplateLabel
        label={translate('SHARE_INCENTIVE_PLAN_FIELD')}
      >
        <ControllerInput
          as={SelectAddInput}
          list={listShareIncentivePlans}
          disabled={disabled}
          refSelect={refSelect}
          mode={"add"}
          placeholder={translate('MODULES.CLARA.SELECT_FROM_LIST')}
          formsAdd={formsAddShareClass}
          compare="id"
          onChange={(value) => {}}
          menuProps={menuPropsShareClass}
          name={name}
        />
      </TemplateLabel>
    </React.Fragment>
  );
};

export default SelectShareincentivePlan;

import useMutationDelete from '../../../../hooks/custom/useMutationDelete';
import useSession from '../../../session/hooks/useSession';
const DELETE_MUTATION = `
mutation deleteRequestBasketItems($startupId: ID!, $groupCompanyId: ID!, $itemId: ID!, $type: String!, $entity: RequestEntityInput!) {
  deleteRequestBasketItems(startupId: $startupId, groupCompanyId: $groupCompanyId, itemId: $itemId, type: $type, entity: $entity) {
      id
      type
      entity {
          ...on StakeHolder {
              id
              fullName
          }
      }
      category
      status
      isPaid
  }
}
`;


const useDeleteRequestBasketItems = ({ ...config }, { ...props }) => {
  
  const refetchQueries = ['companyCorporateMap']

  const [mutation,rest] = useMutationDelete(DELETE_MUTATION, {showSuccessNotification:false,...config}, { ...props ,refetchQueries })

  return  [mutation,rest];
}
export default useDeleteRequestBasketItems;

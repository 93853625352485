import React from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";
const DetailsOptionGrant = ({ go, ...props }) => {
  const params = useParams();



  return (
    <>
      <MicroFrontend id="MF_Options_Grant_Content_View" component={"./DetailsOptionGrant"} mf="capTable" params={{ go, ...props, ...params }} />
    </>
  );
};

export default DetailsOptionGrant;

import _ from "lodash";
import useLazyQuery from "src/hooks/custom/useLazyQuery";
import useQuery from "../../../custom/useQuery";

const GET_GROUP_COMPANIES = `
  query getGroupCompanies($startupId: ID! , $filters: GroupCompanyFilterInput){
    getGroupCompanies(startupId:$startupId, filters:$filters){
      id
      name
      kind
      jurisdiction
      isHoldingCompany
      avatar
      country {
        code
      }
    }
  }
`;
const useGetGroupCompanies = ({ variables, ...props }) => {
  const { loading, error, data, refetch, ...proesDes } = useQuery(
    GET_GROUP_COMPANIES,
    variables,
    null,
    props
  );

  const { manualQuery } = useLazyQuery(
    GET_GROUP_COMPANIES,
    variables,
    null,
    props
  );

  return {
    manualQuery,
    loading,
    error,
    data: _.orderBy(
      _.get(data, "getGroupCompanies", []),
      ["isHoldingCompany"],
      ["desc"]
    ),
    refetch,
    ...proesDes,
  };
};
export default useGetGroupCompanies;

import React, { useCallback, useEffect } from "react";
import TagManager from 'react-gtm-module';
import MyEquityRouter from "./MyEquityRouter";
import useSession from "src/modules/session/hooks/useSession";
import { Constants, helper } from "src/v1/utils";
import logger from "src/v1/utils/logger";

const MyEquityView = () => {
  const { user } = useSession();

  const callTagManager = useCallback(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: "PageView",
        path: window.location.pathname,
        userid: user._id,
        firstName: user.firstName,
        pageName: document.title,
        userType: helper.getTypeUserByEmail(user.email),
      },
      dataLayerName: "PageDataLayer",
    };
    TagManager.dataLayer(tagManagerArgs);
  }, [user]);

  const log = () => {
    logger.info(Constants.LOGGER.ACTIONS.PAGE, { path: window.location.pathname })
  }

  useEffect(() => {
    callTagManager()
    log()
  }, [window.location.pathname])

  return (
    <MyEquityRouter />
  )
};

export default MyEquityView;

import React, { FC, useState } from 'react';
//import Modal, { ModalProps } from '../../../../../../modules/generic/components/Modal/Default';
import useModal from "../../../../../../modules/generic/hooks/useModal";
import { Modal } from '../../../../../components/index';
import classes from './ModalDownload.module.scss';
import Button from "../../../../../../modules/generic/components/Buttons/Button";
import { useSelector } from "react-redux";
import _ from "lodash";
import ServerConnect from "../../../../../utils/ServerConnect";
import useSession from '../../../../../../modules/session/hooks/useSession';

export interface ModalDownloadProps {
    open: boolean,
    title: string,
    content: string
}

const ModalDownload: FC<ModalDownloadProps> = ({ open, title, content }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { closeModal } = useModal();
    const {startupId}  = useSession();
    
    const selectedCompany = useSelector((store) => _.get(store, "capTable.selectedCompany"));

    const handleClose = () => {
        closeModal()
    };

    const handleDownload = async () => {
        const { id: groupCompanyId, name: groupCompanyName } = selectedCompany;
        setIsLoading(true);
        let url = ServerConnect.getUrlCapTableFile(startupId, groupCompanyId);
        const fileName = `CapTable-${groupCompanyName.replace(/ /g, '-')}-${new Date().toJSON().slice(0, 10)}.xlsx`;
        
        await ServerConnect.downloadFileAsXLS(url, fileName);
        
        closeModal();
    };

    return (
        <Modal open={open} onClose={handleClose} showHeader={false}>
            <div className={`${classes.containerModal}`}>
                <h2>
                    Download Cap Table
                </h2>
                <div>
                    Your Cap Table for the company <strong>{selectedCompany.name}</strong> will be downloaded as an .xlsx file.
                </div>

                <div className={classes.containerButtons}>
                    <Button
                        variant="secondary"
                        onClick={closeModal}
                    >Cancel</Button>
                    <Button
                        variant="primary"
                        onClick={handleDownload}
                        loading={isLoading}
                        labelLoading={'Downloading'}
                    >Download</Button>
                </div>

            </div>
        </Modal>
    )
};

export default ModalDownload;

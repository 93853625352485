import React, { FC, useState, useEffect, useMemo } from 'react'
import Modal from '../Default'
import ChatAssistant from './ChatAssistant'
import cls from './ModalAssistant.module.scss'
import { ModalProps } from "./../Default"
export interface ModalAssistantProps extends ModalProps {

}

const ModalAssistant: FC<ModalAssistantProps> = ({
  children,
  open: openParam,
  onClose,
  variant = "assistance",
  ...props
}: ModalAssistantProps) => {

  const [open, setOpen] = useState(openParam);

  useEffect(() => {
    setOpen(openParam);
  }, [openParam])
  const handleClose = (e, r) => {
    if (r === "backdropClick") return false;
    setOpen(false)
    onClose && onClose(e, r);
  }

  const paperWidthMd = useMemo(() => {
    if (variant === "assistance") return cls['MuiDialog-paperWidthMd']
    if (variant === "full") return cls['MuiDialog-paperWidthMdFull']
    if (variant === "fullPay") return cls['MuiDialog-paperWidthMdFull']
    if (variant === "fullLg") return cls['MuiDialog-paperWidthLgFull']
    return null;
  }, [variant]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown={true}
      classes={{
        paperWidthMd: paperWidthMd,
        paper: cls['MuiDialog-paper']
      }}
    >
      {variant === "assistance" && (
        <>
          <div className={cls['container-modal']}>
            {children}
          </div>
          <div className={cls['Container-assistant']}>
            <ChatAssistant />
          </div>
        </>
      )}
      {(variant === "full" || variant === "fullLg") && (
        <>
          <div className={cls['container-modal']}>
            {children}
          </div>
        </>
      )}
      {variant === "fullPay" && (
        <>
          <div className={cls['container-modal-pay']}>
            {children}
          </div>
        </>
      )}
    </Modal>
  )
}

export default ModalAssistant

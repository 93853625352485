import React, { useState, useEffect, useMemo } from 'react';
import classes from './SelectAddInput.module.scss';
import ClaraSelect from '../SelectInput/SelectInput';
import { useFormContext } from 'react-hook-form';
import _ from 'lodash'

const SelectAddInput = ({ list, name, onChange, disabled, formsAdd = [], menuProps, refSelect, errorMessage, ...props }) => {
  
  const handleCallback = (newValue) => {
    onChange(newValue)
  }

  const [loadingForm, setLoadingForm] = useState(false);
  const [typeForm, setTypeForm] = useState(null);
  const [formSelected, setFormSelected] = useState({})
  const [loading, setLoading] = useState(false);

  const Form = useMemo(() => {
    const formFind = _.find(formsAdd, f => f.type === typeForm);
    setFormSelected(formFind)
    return formFind && formFind.form;
  }, [typeForm])

  return (
    <React.Fragment>
      <div className={classes.SelectAddInput}>
        <ClaraSelect
          {...props}
          list={list}
          disabled={disabled}
          onChange={onChange}
          innerRef={refSelect}
          clear={true}
          ComponentMenu={Menu}
          menuProps={{ setTypeForm, ...menuProps }}
          errorMessage={errorMessage}
        />
        {
          !disabled && formSelected && !_.isEmpty(formsAdd) && typeForm &&
          <Form
            handleAddValueList={_.get(formSelected, 'handleAddValueList', () => { })}
            handleCloseForm={() => setTypeForm(null)}
            {..._.get(formSelected, 'propsForm', {})}
          />
        }
      </div>
    </React.Fragment >
  )
}

export default SelectAddInput;


const Menu = ({ list, addList, propsList, handleClearValue, handleSelectedItem, openMenu, setTypeForm, handleShowMenu, ...props }) => {

  const [renderLists, setRenderLists] = useState([])

  useEffect(() => {
    setRenderLists(mapList(list));
  }, [JSON.stringify(list)])

  useEffect(() => {
    if (openMenu) {
      setTypeForm(null);
    }
  }, [openMenu])

  const mapList = (list) => {
    return _.map(propsList, (subListProps, index) => ({ ...subListProps, list: _.filter(list, item => item.type === subListProps.type) }))
  }

  const selectItem = (element) => {
    handleSelectedItem(element)
  }

  return (
    <React.Fragment>
      {addList.length > 0 ? (
        <React.Fragment>
          <div className={classes.zoneAdd}>
            {addList.map((element, i) => {
              const handleopenForm = (e) => {
                setTypeForm(element.type);
                handleShowMenu(false);
                element.onClick && element.onClick(element)
              }
              return (
                <a key={JSON.stringify(element) + i} className={`linkPlus `} onClick={handleopenForm}>{element.label}</a>
              )
            })}
          </div>
        </React.Fragment>
      ) : null}
      {!_.isEmpty(list) && <hr className={classes.Separator} />}
      {!_.isEmpty(list) && renderLists.map((subList, index) => {
        return (
          <div key={subList.type} className={classes.zoneList} >
            <label className={classes.titleList}>{subList.title}</label>
            {subList.list.map((element, j) => {
              return (
                <a key={JSON.stringify(element.value)} onClick={() => selectItem(element)}  >{element.label}</a>
              )
            })}
          </div>
        )
      })}
    </React.Fragment>
  )
}
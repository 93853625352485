import { useEffect } from 'react';
import useMutationDelete from '../../../custom/useMutationDelete';


const mutation = `mutation deauthorizeDevice(  $id: ID!, $reason: String) {
    deauthorizeDevice(
     id:$id
     reason:$reason
   ) {
       id
       errorCode
   }
 }`
const useDeauthoriseDevice = (options) => {

    const [send, data] = useMutationDelete(mutation, options);
    const sendForm = (id) => {
        return send(id);
    }
    useEffect(() => {

    }, []);

    return [sendForm, data];
}
export default useDeauthoriseDevice;

import React, { FC, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { StepProps } from '../../../../../generic/components/Wizard/Step';
import useCustomMachine from "../../../../../generic/hooks/useCustomMachine";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import { addAssistantText } from "../../../../../generic/store/action";
import IndividualOrCompanyStakeholderFormStep from "../../../core/wizards/steps/IndividualOrCompanyStakeholderFormStep/IndividualOrCompanyStakeholderFormStep";

const EmployeeFormStep: FC<StepProps> = ({ }: StepProps) => {

  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const { state } = useCustomMachine();

  useEffect(() => {
    dispatch(addAssistantText(translate('MODULES.ONBOARDINGROLES.EMPLOYEE.SECOND_STEP_TOOLTIPS')));
  }, [])

  return (
    <React.Fragment>
      <IndividualOrCompanyStakeholderFormStep isTherePrevious={false} role={'EMPLOYEE'} isIndividual={true} />
    </React.Fragment>
  )
}

export default EmployeeFormStep;

import React, {Component} from "react";
import {connect} from "react-redux";
import {Loading} from "../../index";

import ServerConnect from '../../../utils/ServerConnect';
import {Constants} from '../../../utils/constants';
import EditForm from "./EditForm";


import EditDetails from "../../Forms/Edit/Startup/Details"
import {upserStartup as mutation} from '../../../graphql/mutations/createStartup';
import {goToPage, goToPrevPage} from '../../../../store/actions/page'
import moment from "moment";

class StartupForm extends Component {
    state = {
        data: {},
        refresh: false,
        showLoading: false,
        groupCompanies: {},
        wasUpdated:false,
    }

    callServerToGetData = () => {
        return new Promise((resolve, reject) => {
            resolve(this.props.data);
        })
    };

    getTitleEditForm = () => {
        return this.state.data ? this.state.data.name : "";
    };

  
    transformInitialData = (data) => {

        let newData = {
            ...data,
            documents:data.constitutionalDocuments
        }
        delete newData.constitutionalDocuments
        return newData;
    };

    transformFinishData = (data) => {
        this.setState({
            showLoading: true
        });
        let newData = {
            ...data,
            avatar : data.avatar && (typeof data.avatar === 'object' ) ? data.avatar[0]: data.avatar,
            constitutionalDocuments: data.documents ? data.documents.map(doc => {
                delete doc.__typename;
                if (doc.file){
                    delete doc.file.__typename;
                    delete doc.file.type;
                }
                return { ...doc, date: moment().format('YYYY-MM-DD') };
            }) : undefined
        }

        delete newData.score;
        delete newData.__typename;
        const {id, ...startupData} = newData;
        ServerConnect.graphQlMutation(mutation, {id, startupData}).then((result) => {

                let updatedData = result.upsertStartupProfile;

                
                this.setState({
                    showLoading: false,
                    data: updatedData,
                    refresh: !this.state.refresh,
                    wasUpdated:true,
                });
                this.props.goToPage(Constants.PAGES.viewStartup,updatedData);

        });
    };

    componentDidMount() {
        this.setState({
            showLoading: true
        });
        Promise.all([this.callServerToGetData()]).then( (result) =>{
            this.setState({
                data: this.transformInitialData(result[0]),
                refresh: !this.state.refresh,
                showLoading: false
            })
            
        })

        ServerConnect.requestApi(
            `/contentful/tooltips/${Constants.CONTENTFUL.ENTRIES.FORMS.STARTUP.TOOLTIP}`,
            { method: 'GET', headers: { "Content-Type": "application/json" },
            }).then(res => {
            console.log('response', res.response);
            this.setState({
                tooltips: res.response
            })
        }).catch(err => {
            console.log('Tooltips from contentful error: ', err);
        });
    }

    render() {
        
        let { mode } = this.props;
        let { data, groupCompanies,wasUpdated } = this.state;
        
        return (
            <Loading show={this.state.showLoading}>
                <EditForm formType={Constants.FORMS.STARTUP} accessDelete={Constants.ACTIONS.EDIT_PROFILE} title={this.getTitleEditForm()} mode={mode} data={data}  wasUpdated={wasUpdated}>
                    <EditForm.Container tab={{ name: "Details", key: "details" }}>
                        <EditForm.View isView={true} >
                            {/* <ViewDetails data={data} /> */}
                        </EditForm.View>
                        <EditForm.Edit>
                            <EditDetails data={data} updateForm={this.transformFinishData} tooltips={this.state.tooltips}/>
                        </EditForm.Edit>
                    </EditForm.Container>
                </EditForm>
                {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
            </Loading>
        )
    }
}

const mapStateToProps = state => ({
    session: state.session,
    form:state.form
});
const mapDispatchToProps = (dispatch) => ({
   
  
    goToPage: (page,params) => {
        goToPage(dispatch, page,params)
    },
    goToPrevPage: (reload,defaultPage,params) => {
        goToPrevPage(dispatch, reload,defaultPage,params)
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(StartupForm)

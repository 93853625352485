import React from "react";
import { Typography } from '@mui/material'
import BubbleContents from '../BubbleContents';
import Bubble from '../Bubble'

/**
  Assistance bubbles
*/
const BubbleNoPendingTask = ({ isEntity = false }) => {
  const text = isEntity ? 'There are no tasks to complete for this group company.' : 'There are no tasks to complete for this stakeholder.';

  return (
    <Bubble >
      <BubbleContents>
        <Typography variant="body1" sx={{ marginBottom: '10px', fontSize: "12px"}}>
          {text}
        </Typography>
      </BubbleContents>
    </Bubble>
  );
};

export default BubbleNoPendingTask;

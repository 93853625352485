import { useMemo } from 'react';
import useBusinessRules from 'src/modules/clara/hooks/useBusinessRules';
import useSession from 'src/modules/session/hooks/useSession';
import _ from 'lodash';

const useGuards = () => {
  const {isOwner} = useBusinessRules()
  const { session} = useSession()
  return useMemo(() => ({

    isThirdParty: (context) => {
      const isThirdParty = _.get(context.SelectSecondAuthorisedSignatoryFormStep, 'secondAuthorisedSignatory.value', '') || _.get(context.WhoIsGoingToBeYourAuthorisedSignatoryFormStep, 'authorisedSignatory.value', '')     
      return isThirdParty === 'ADD_THIRD_PARTY'
  },
  isThirdPartySecondary: (context) => {
    return _.get(context.SelectSecondAuthorisedSignatoryFormStep, 'secondAuthorisedSignatory.value', '') === 'ADD_THIRD_PARTY'
  },
  isThirdPartyFirst: (context) => {
    return _.get(context.WhoIsGoingToBeYourAuthorisedSignatoryFormStep, 'authorisedSignatory.value', '') === 'ADD_THIRD_PARTY'
  },
  isNominee: (context) => {     
    return _.get(context.WhoIsGoingToBeYourAuthorisedSignatoryFormStep, 'authorisedSignatory.value', '') === 'USE_NOMINEE'
},
  
  }), [isOwner, session]);
}

export default useGuards;

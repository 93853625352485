import _ from "lodash";
import React, { useMemo } from 'react';
import Text from '../../../../../text/Text/Text';
import Icon from '../../../../../../modules/generic/components/Icon';
import classes from './DocumentTypeCard.module.scss';
import useGetDocumentTypesLogic from '../../../../../../views/modules/startups/documents/hooks/useDocumentTypesLogic';
interface DocumentTypeCardProps {
    [key: string]: any;
}

const DocumentTypeCard: React.FC<DocumentTypeCardProps> = (props) => {
    const {isOnlyForNewDocumentGeneration} = useGetDocumentTypesLogic()
    let isSelected = useMemo(() => {
        return props.docTypeClicked.key === props.key && props.docTypeClicked.code === _.get(props, 'card.code', '') && props.docTypeClicked.categoryName === props.tabDataName;
    }, [JSON.stringify(props)])
    const handleOnClickCard = () => {
        props.checkHandler(
            _.get(props, 'card.code', ''),
            props.key, props.card.entryId,
            _.get(props, 'card.label', ''),
            _.get(props, 'card.premium', false),
            _.get(props, 'tabDataName'),
            props.card,
            props?.card?.documentType
        );
    }

    return (

        <div className={classes.cardParentContainer}>
            <div className={`${classes.cardContainer} ${isSelected ? classes.cardSelected : null}`} onClick={handleOnClickCard}>
                <div className={classes.headerContainer}>
                    <span className={`${classes.checkContainer} ${isSelected ? classes.checkSelected : null}`}>
                        <div className={classes.checkCircle}>
                            {isSelected ? <span className={classes.tickIcon}><Icon icon='minitick' isClara={true} size={'0.6rem'} /></span> : null}
                        </div>

                    </span>
                    <span className={classes.nameContainer}>
                        {_.get(props, 'card.label', '')}

                    </span>
                    
                        {props?.card?.template && !isOnlyForNewDocumentGeneration(props?.card?.code) && (
                            <span className={`${classes.betaStyle}`}>
                                Beta
                            </span>
                        )}
                        
                    
                    <span className={`${classes.premiumInfoContainer} ${isSelected ? classes.premiumContainerSelected : null} ${_.get(props, 'card.premium', false) ? classes.premiumStyle : null}`}>
                            <Text uuid={_.get(props, 'card.premium', false) ? "SUBCATEGORY_INVOICE_SCALE" : "BUTTON_START"} />
                        </span>
                </div>



            </div>
        </div>


    )
}

export default DocumentTypeCard;

import React, { Component } from "react";
import { connect } from "react-redux";
import { DeleteForm, Loading } from "../../index";
import Mustache from "mustache";
import ServerConnect from '../../../utils/ServerConnect';
import { eliminate, query } from '../../../graphql/mutations/addStakeholder';

class StakeHolderDeleteForm extends Component {
    state = {
        data: {},
        showLoading: false,
        options: [],
    }
    /* Particular data */
    getTitle() {
        const data = this.state.data;
        return "Delete " + data.fullName + " Stakeholder";
    }
    setOptions() {
        let options = [];
        const { data } = this.state;

        options.push({ label: "Delete this stakeholder?" });
        // if (data.hasAgreements) {
        //     options.push({ label: "Delete all agreements?" });
        // }
        if (data.equity && data.equity.length > 0) {
            options.push({ label: "Delete all shares, options and warrants?" });
        }
        this.setState({
            options
        })
    }
    hasAllData() {
        const data = this.props.data;
        return true;
    }
    /*  Call Server*/
    callServerToGetData = () => {
        this.showLoading(true);
        var query_params = {
            startupId: this.props.session.startup.id,
            stakeholderId: this.props.data.id
        };
        const queryExecute = Mustache.render(query, query_params);
        ServerConnect.graphQlQuery(queryExecute).then(result => {
            let data = {
                ...result.getStakeholder
            };
            this.setState({
                data
            }, () => {
                this.setOptions(data);
                this.showLoading(false);
            })


        });
    };
    deleteEntity = () => {
        return new Promise((resolve, reject) => {
            var data = {
                startupId: this.props.session.startup.id,
                stakeholderId: this.state.data.id,
            };
            this.showLoading(true);
            ServerConnect.graphQlMutation(eliminate, data).then((result) => {
                this.props.handleRemove && this.props.handleRemove(this.state.data.id);
                this.showLoading(false);
                resolve();
            }).catch(err => {
                reject(err)
            });
        });
    }
    /* Change status */
    showLoading = (status) => {
        this.setState({
            showLoading: status
        });
    }
    /* REac functions */
    componentWillMount() {
        if (this.hasAllData()) {
            this.setState({
                data: this.props.data
            }, () => {
                this.setOptions();
            })


        } else {
            this.callServerToGetData();
        }
    }


    render() {

        return (
            <Loading show={this.state.showLoading}>
                <DeleteForm handleClose={this.props.handleClose} show={true} deleteEntity={this.deleteEntity} showLoading={this.showLoading} title={this.getTitle()} options={this.state.options}></DeleteForm>
            </Loading>
        )
    }
}

const mapStateToProps = state => ({
    session: state.session,
    form: state.form
});
const mapDispatchToProps = (dispatch) => ({

});
export default connect(mapStateToProps, mapDispatchToProps)(StakeHolderDeleteForm)

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useSession from '../../../modules/session/hooks/useSession';
import classes from './HiddenField.module.scss';

const HiddenField = ({ onChange, defValue }) => {

    const [valuesWatch, setValuesWatch] = useState({});
    const [val, setVal] = useState(null);
    const dispatch = useDispatch();
    
    const { user } = useSession();

    useEffect(() => {
        onChange(defValue)
    }, [defValue]);

    return (
        <React.Fragment>
            <div className={classes.hiddenField}>
            </div>
        </React.Fragment>
    )
};





export default HiddenField;

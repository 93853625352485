import React from "react";
import _ from "lodash";
import schema from "../../../../../../forms/schemas/modules/user/deauthoriseDevice";
import RowAdd from "../../../../../forms/contents/RowAdd/RowAdd";
import ControllerInput from "../../../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import SubmitButton from "../../../../../generic/Buttons/SubmitButton/SubmitButton";
import Form from "../../../../../forms/Form/FormL";
import TemplateFormDefault from "../../../../../../modules/generic/templates/Modal";
import useTranslate from "../../../../../../modules/generic/hooks/useTranslate";
import classes from "./DeauthoriseDeviceModal.module.scss";
import TextareaInput from "../../../../../inputs/Textarea/TextareaInput";
import Checkbox from "../../../../../inputs/Checkbox/Checkbox";
import Text from "../../../../../text/Text/Text";

const DeauthoriseDeviceForm = ({Template = TemplateFormDefault, buttons = {}, buttonSubmitProps, propsTemplate, handleSubmit, initialValues}) => {
    const { translate } = useTranslate();

    return(
        <Form schema={schema} onSubmit={handleSubmit}>
            <Template
                buttons={{ ...buttons, submit: <SubmitButton {...buttonSubmitProps}>Save</SubmitButton> }}
                props={propsTemplate}
            >
                <div className={classes.reasonContainer}>
                    <RowAdd
                        label={"MODAL_DELETE_GRANT_REASON_LABEL"}
                        align="top"
                        asterisk={true}
                        input={
                            <ControllerInput
                                as={TextareaInput}
                                name="reason"
                                defaultValue={""}
                                placeholder={translate("MODAL_DEAUTHORISE_DEVICE_REASON_HIT")}
                                onChange={(e) => {}}
                            />
                        }
                    />
                </div>
                <div className={classes.checkboxContainer}>
                    <ControllerInput
                        as={Checkbox}
                        showErrorMessage={true}
                        name={"confirmDeauthorise"}
                        classNameError={classes.errorCheckboxLabel}
                    />
                    <span className={classes.textCheckbox}>
                        <Text
                            uuid={"MY_ACCOUNT_MODAL_DEAUTHORISE_DEVICE_CHECKBOX_TEXT"}
                            params={{ device_name: _.get(initialValues, "name") }}
                        />
                    </span>
                </div>
            </Template>
        </Form>
    );
}

export default DeauthoriseDeviceForm;
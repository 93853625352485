import React from 'react';
import classes from './BotoneraAdd.module.scss';

const BotoneraAdd = ({ children, style }) => {
    return (
        <React.Fragment>
            <div style={style} className={classes.BotoneraAdd}>
                {children}
            </div >
        </React.Fragment >
    )
}

export default BotoneraAdd;
import React, { FC, useMemo, useRef, useState } from "react";
import _ from "lodash";
import { Constants } from "../../../../../../v1/utils";
import FormProps from "../../../../../../modules/generic/forms/interfaces/FormProps";
import TemplateFormDefault from "../../../../../../modules/generic/templates/Modal";
import Form from "../../../../../forms/Form/Form";
import SubmitButton from "../../../../../../modules/generic/components/Buttons/SubmitButton";
import ControllerInput from "../../../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import HiddenField from "../../../../../inputs/HiddenField/HiddenField";
import TemplateLabel from "../../../../../../modules/generic/components/Inputs/LabelInput";
import FixedSelectInput from "../../../../../inputs/FixedSelect/FixedSelectInput";
import TextInput from "../../../../../inputs/Text/TextInput";
import CheckboxButtonSimple from "../../../../../../modules/generic/components/Inputs/CheckboxButtonSimple";
import useTranslate from "../../../../../../modules/generic/hooks/useTranslate";
import share from "../../../../../../images/share.svg";
import { CheckboxButtonOptions } from "../../../../../../modules/generic/components/Inputs/CheckboxButton";
import classes from "./ModalShareProfile.module.scss";

interface Props extends FormProps {
  schema: any,
  initialValues: any,
  showNameField: any,
  dataPossibleSharing: any,
  alreadySharingProfiles: any,
  setShowNameField: any,
  setFullNameValue: any,
  setEmail: any,
  changed: any,
  setChanged: any
}

const ShareProfileForm: FC<Props> = ({
  schema,
  initialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
  showNameField,
  dataPossibleSharing,
  alreadySharingProfiles,
  setShowNameField,
  setFullNameValue,
  setEmail,
  changed,
  setChanged
}) => {

  const { translate } = useTranslate();
  const [selected, setSelected] = useState(_.get(initialValues, 'role'));

  const possibleSharingProfiles = useMemo(() => {
    const list = [];
    _.forEach(dataPossibleSharing, sp => {
      list.push({
        id: sp.email, label: sp.fullName + ' - ' + sp.email,
        component:
          <div>
            <img src={share} /> {sp.fullName + ' - ' + sp.email}
          </div>
      });
    });
    return list;
  }, [JSON.stringify(dataPossibleSharing)]);

  const handleSelectRoles: any = useRef();

  const checkRenderFullNameField = (val, list) => {
    if (!val && handleSelectRoles && handleSelectRoles.current) {
      handleSelectRoles.current.setValue(null);
    }
    if (list) {
      const data = _.filter(list, elem => {
        const valLower = val ? val.toLowerCase() : val;
        const idLower = elem.id ? elem.id.toLowerCase() : elem.id;
        return idLower.indexOf(valLower) >= 0;
      });
      if (data.length >= 1 || val === '' || !val) {
        setShowNameField(false);
      } else {
        setShowNameField(true);
      }
    }
  };

  const getFullNameValue = (val, list) => {
    if (list) {
      let name = '';
      if (!initialValues && _.find(list, elem => {
        name = elem.fullName;
        return elem.id === val;
      })) {
        setFullNameValue(name);
      }
    }
  };

  const checkMailAlreadyShared = (val, list) => {
    if (list) {
      if (!initialValues && _.find(list, elem => {
        return elem.id === val;
      })) {
        return null;
      } else {
        return 'validate';
      }
    }
  };

  const rolesOptions: Array<CheckboxButtonOptions<string, any>> = [
    { value: Constants.USER_ROLES.USER_OWNER, label: Constants.TRANSLATES.ROL_USER_USER_OWNER },
    { value: Constants.USER_ROLES.USER_MANAGER, label: Constants.TRANSLATES.ROL_USER_USER_MANAGER },
    { value: Constants.USER_ROLES.USER_EDITOR, label: Constants.TRANSLATES.ROL_USER_USER_EDITOR },
    { value: Constants.USER_ROLES.USER_VIEWER, label: Constants.TRANSLATES.ROL_USER_USER_VIEWER }
  ];

  return (
    <Form
      registerValues={['email']}
      defaultValues={initialValues}
      onSubmit={onCompleteSubmit}
      schema={schema}
      optionsForm={{ mode: "onChange" }}
      onlySendDirty={false}
    >
      <Template
        buttons={{
          ...buttons,
          submit: (
            <SubmitButton {...buttonSubmitProps}>
              {translate("SEND_INVITE")}
            </SubmitButton>
          )
        }}
        props={propsTemplate}
      >
        <ControllerInput
          render={HiddenField}
          name={'hiddenNameField'}
          placeholder={'KYC_ENTITY_EMAIL_ADDRESS_FIELD_VIEW'}
          defValue={showNameField}
        />
        <TemplateLabel
          label={translate('KYC_ENTITY_EMAIL_ADDRESS_FIELD_VIEW')}
          isRequired={true}
        >
          <ControllerInput
            render={FixedSelectInput}
            fixedNotSelectable={true}
            canCreate={true}
            fixedListAtTop={true}
            fixedFilterable={true}
            fixedTitle={true}
            fixedTitleContent={'Already shared with this Profile'}
            list={possibleSharingProfiles}
            fixedList={alreadySharingProfiles}
            name={'email'}
            placeholder={'KYC_ENTITY_EMAIL_ADDRESS_FIELD_VIEW'}
            clear={true}
            value={_.get(initialValues, 'email', '')}
            onChange={(e) => {
              setEmail(e);
              checkRenderFullNameField(e, possibleSharingProfiles);
              checkMailAlreadyShared(e, alreadySharingProfiles);
              getFullNameValue(e, possibleSharingProfiles);
              if (!changed) {
                setChanged(true);
              }
            }}
          />
        </TemplateLabel>
        {showNameField ?
          <TemplateLabel
            label={translate('NAME')}
            isRequired={true}
          >
            <ControllerInput
              render={TextInput}
              name="fullName"
              defaultValue={_.get(initialValues, 'fullName', '')}
              placeholder={"FULL_NAME"}
            />
          </TemplateLabel>
          :
          null
        }
        <TemplateLabel
          label={translate('USER_ROLE')}
          isRequired={true}
          variant={'top'}
        >
          <ControllerInput
            render={CheckboxButtonSimple}
            className={classes.CheckboxButton}
            classNameButton={classes.buttonCheckBox}
            defaultValue={_.get(initialValues, 'role', '')}
            name='role'
            options={rolesOptions}
            value={selected}
          />
        </TemplateLabel>
      </Template>
    </Form>
  );
}

export default ShareProfileForm;
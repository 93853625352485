import React, { Component } from "react";
import { connect } from "react-redux";
import classes from "./Legends.module.scss"

class Legends extends Component {
    state = {

    };

    calculateColors = () => {
        const backgroundColor = this.state.data.map((value, key) => {
            if (key + 1 > this.state.defaultColors.length) {

                var letters = '0123456789ABCDEF'.split('');
                var color = '#';
                for (var i = 0; i < 6; i++) {
                    color += letters[Math.floor(Math.random() * 16)];
                }
                return color;
            } else {
                return this.state.defaultColors[key];
            }
        });
        this.setState({
            colors: backgroundColor
        })
    };

    renderLegends() {

        const labels = [];
        const cantLabels = this.props.labels.length;

        if (cantLabels === 1) {
            labels.push(
                <div key={0} className={classes.FiveLegendsGroup}>
                    {this.props.labels[0] &&
                        <div className={classes.LegendDiv}>
                            <label><span
                                style={{
                                    background: this.props.colors[0],
                                }}
                            /></label>
                            <label>{this.props.labels[0]}</label>
                        </div>
                    }
                </div>
            );
        } else {

            for (let i = 0; i < cantLabels; i++) {
                labels.push(
                    <div key={i} className={classes.FiveLegendsGroup}>
                        {this.props.labels[i] &&
                            <div className={classes.LegendDiv}>
                                <label><span style={{
                                    background: this.props.colors[i],
                                }} /></label>
                                <label>{this.props.labels[i]}</label>
                            </div>
                        }
                        {this.props.labels[i + 1] &&
                            <div key={i + 1} className={classes.LegendDiv}>
                                <label><span
                                    style={{
                                        background: this.props.colors[i + 1],
                                    }}
                                /></label>
                                <label>{this.props.labels[i + 1]}</label>
                            </div>
                        }
                        {this.props.labels[i + 2] &&
                            <div key={i + 2} className={classes.LegendDiv}>
                                <label><span
                                    style={{
                                        background: this.props.colors[i + 2],
                                    }}
                                /></label>
                                <label>{this.props.labels[i + 2]}</label>
                            </div>
                        }
                        {this.props.labels[i + 3] &&
                            <div key={i + 3} className={classes.LegendDiv}>
                                <label><span
                                    style={{
                                        background: this.props.colors[i + 3],
                                    }}
                                /></label>
                                <label>{this.props.labels[i + 3]}</label>
                            </div>
                        }
                        {this.props.labels[i + 4] &&
                            <div key={i + 4} className={classes.LegendDiv}>
                                <label><span
                                    style={{
                                        background: this.props.colors[i + 4],
                                    }}
                                /></label>
                                <label>{this.props.labels[i + 4]}</label>
                            </div>
                        }
                    </div>
                );
                i += 4;
            }
        }

        return labels;
    }

    render() {
        return (
            <div id={'legendsDiv'} className={classes.Legends} ref={this.props?.innerRef} >
                {this.renderLegends()}
            </div>
        );

    }
}

const mapStateToProps = state => ({
    session: state.session
});
const mapDispatchToProps = (dispatch) => ({


});
export default connect(mapStateToProps, mapDispatchToProps)(Legends)

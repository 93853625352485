import { createMachine } from "xstate";
const machine =
  /** @xstate-layout N4IgpgJg5mDOIC5QFVZgE4AUCuAjANgJYDGASmFIbAC7oCG1hA9gHYB02a6AYk+gLYBiAHIBRABoAVRKAAOTWIUasZIAB6IAjABYAHGwBMAVgBsukye1GjABgDsuzQYA0IAJ5bNATjZGvf3V1tL20TOxt-AF9I11QMHAISckoaemV2AGslXgFBTFJRADVVeUV01Q0EHX1jMwsrWwcnVw8EO01fLxMbG10AZh6m42jYrgSiMgoqWgZmTOy+ITEpEoUlOYrEPr6fTXCvO1s+0J7NFq0DAzYIgKCQsJuRkDisPAnk6bS5thowWT68gVikgQKV1ioQZVtrt9ocbMdujYzu5EHZtNcuj1+oNHMMniwmBA4KoXuMklNUrNWBwuDl+KsyhtIYhtC4UW0fDs7H0DLoutpNDy+iYnqS3uSUjN0mwstQ6QzwSxNggDALDKZdEYdF4Qto+v1zghHGw+dzefzBQZhaKxuLJpKvtTfv8FeVmQg9Vc9hFEQ4ukZdIagmwuTy+ZZLdaYs9bYl7Z8qUqQWC3aBKppA+yALQdbQ2PM2IzaVlWvMWaLRIA */
  createMachine({
    id: 'ReviewDataProtectionContact',
    initial: 'InitialData',
    states: {
      InitialData: {
        invoke: {
          src: 'initialData',
          id: 'initialData',
          onDone: [
            {
              target: 'pincasData',
              actions: ['setContextAfterInvoke']
            }
          ],
          onError: [
            {
              target: 'cancel'
            }
          ]
        }
      },
      pincasData: {
        invoke: {
          src: 'pincasData',
          id: 'pincasData',
          onDone: [
            {
              target: 'ReviewDataProtectionContact',
              actions: ['setContextAfterInvoke']
            }
          ],
        }
      },
      ReviewDataProtectionContact: {
        on: {
          NEXT: {
            target: 'EvaluateChangeDataProtectionContact',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'finish'
          }
        }
      },
      EvaluateChangeDataProtectionContact: {
        always: [
          {
            cond: 'isChange',
            target: 'SelectDataProtectionContact'
          },
          {
            cond: 'isNotChange',
            target: 'SaveNoNewDataProtectionContact'
          },
          {
            target: 'cancel'
          },
        ]
      },
      SelectDataProtectionContact: {
        on: {
          NEXT: {
            target: 'SaveNewDataProtectionContact',
            actions: ['setContextAfterStep']
          },
          PREV: {
            target: 'ReviewDataProtectionContact'
          },
          CANCEL: {
            target: 'finish'
          }
        }
      },
      SaveNoNewDataProtectionContact: {
        invoke: {
          src: 'saveNoNewDataProtectionContact',
          id: 'saveNoNewDataProtectionContact',
          onDone: [
            {
              target: 'finish'
            }
          ],
          onError: [
            {
              target: 'finish'
            }
          ]
        }
      },
      error: {
        on: {
          PREV: {
            target: 'ReviewDataProtectionContact'
          }
        }
      },
      SaveNewDataProtectionContact: {
        invoke: {
          src: 'saveNewDataProtectionContact',
          id: 'saveNewDataProtectionContact',
          onDone: [
            {
              target: 'finish'
            }
          ],
          onError: [
            {
              target: 'finish'
            }
          ]
        }
      },
      cancel: {
        entry: 'cancelMachine',
        type: 'final'
      },
      finish: {
        entry: 'stopMachine',
        type: 'final'
      }
    }
  });
export default machine;

import React from "react";
import Button from "src/modules/generic/components/Buttons/Button";
import ModalAssistant from "src/modules/generic/components/Modal/ModalAssistant";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardContent";
import useModal from "src/modules/generic/hooks/useModal";
import classes from "./styles.module.scss";
import cdnImages from "./cdnImages";

const JoinProgramModal = ({ open, program, handleSubmit }) => {
  const { closeModal } = useModal();
  const myTasks = cdnImages["MYTASKS"];

  const handleCloseModal = () => {
    closeModal();
    handleSubmit();
  };

  return (
    <ModalAssistant open={open} variant="full">
      <TemplateWizard
        props={{}}
        buttons={{
          submit: (
            <Button variant="primary" onClick={handleCloseModal}>
              Get started
            </Button>
          ),
        }}
        className={classes.ScrollableWizard}
      >
        <div className={classes.ModalContent}>
          <div className={classes.ContentText}>
            <h1 className={classes.ModalTitle}>Welcome to {program.name}</h1>
            <p>
              Your profile will be <strong> automatically shared </strong> with{" "}
              <strong>{program.name}.</strong> Follow the tasks on the{" "}
              <strong>Dashboard</strong> and complete them all to proceed.
              <br /> <br />
              Please make sure you’ve added all companies and stakeholders in
              your startup before submitting your application. You can return to
              the <strong>Accelerators</strong> page to review it anytime.
            </p>
          </div>
          <div className={classes.RightContent}>
            <img src={myTasks} />
          </div>
        </div>
      </TemplateWizard>
    </ModalAssistant>
  );
};

export default JoinProgramModal;

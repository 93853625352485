import React, { useState, useRef, useEffect } from "react";
import Popover from '@mui/material/Popover';
import classes from "../ModalMenu/ModalMenu.module.scss"
import classesPopover from './PopoverOption.module.scss'
import Portal from '@material-ui/core/Portal';

/*
    Properties:
        links:[ links to show
            {
                className
                label
                onClick: function to execute when do click in.
                separator:  true|false (default), show border top
            }
        ] 
        callbacks:{ functions to execute in callback
            close: execute when 
        }
        children: content to render
*/

const PopoverOption = ({ children, classicStyle, className, style, renderElement, callbacks, propsRenderElement = {}, elevation=8 }) => {

    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    
    const openMenu = (e) => {
        setAnchorEl(e.currentTarget);
        setOpen(true);
    }

    




    const refPaper = useRef()

    const closePopover = (e) => {
        setOpen(false)
        if (callbacks && callbacks.close) {
            callbacks.close();
        }
    };

    const classOpen = open ? classes.Open : classes.Close;

    const renderNewElement = typeof renderElement === 'function' ? React.createElement(renderElement, { closePopover, ...propsRenderElement })
        : React.cloneElement(renderElement, { closePopover })

    return (
        <div id={'container'} className={className ? `${classes.ModalMenu} ${classes.ModalStyle}` : classes.ModalMenu}>
            <div style={style} className={`maskAfter  ${classOpen}`} onClick={(e) => { openMenu(e) }}>
                <>{children}</>
            </div>
            <Popover
                open={open}
                onClose={closePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}

                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}

                classes={{
                    paper: classicStyle ? classesPopover.classicPapper : classesPopover.papper
                }}

                anchorEl={anchorEl}
                elevation={elevation}
            >
                <div className={classesPopover.containerElement} >

                    {renderNewElement}

                </div>
            </Popover>
        </div >
    )

}





export default PopoverOption;

import { useMemo } from "react";
import TemplateWizardSkeletonMachine from "../../../../generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine";
import { Step1, Step2, Step3, Step4, Step5, Step1AddInfo, Step4SelectTopco, StepOpenFormFlow } from "../steps";

const useSteps = () => {

  return useMemo(() => ({
    getCompaniesData: {
      render: TemplateWizardSkeletonMachine,
      renderProps:{
        title: "",
        subTitle: "",
      },
    },
    topOK_jurOK: {
      render: Step1,
    },
    topOK_jurNO_coOK_cojurNO: {
      render: Step2,
    },
    topOK_jurNO_coOK_cojurOK: {
      render: Step3,
    },
    topNO_jurNO_coOK_cojurOK: {
      render: Step4,
    },
    topNO_jurNO_coOK_conjurNO: {
      render: Step5,
    },
    step1AddInfo: {
      render: Step1AddInfo,
    },
    step4SelectTopco: {
      render: Step4SelectTopco,
    },
    openFormFlow: {
      render: StepOpenFormFlow,
    },
    uploadTopCo: {
      render: TemplateWizardSkeletonMachine,
      renderProps: {
        title: "",
        subTitle: ""
      }
    },
  }), []);
}

export default useSteps;
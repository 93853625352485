import React, {Component} from "react";
import {connect} from "react-redux";
import {Constants} from "../../../utils";

class ClaraInputHidden extends Component {
    state = {
        tooltipText: null,
        placeHolder: null,
        validation: {},
        label: null,
        defaultValue: null,
        errorMessage: null,
        showError: false,
        value: null,
        index: null
    };
    /* React Methods */
    componentWillUpdate(prevProps, prevState, snapshot) {
        if (prevProps.session !== this.props.session) {

        }
    }
    componentWillMount() {

		const { tooltipText, placeHolder, validation, label, defaultValue, name, value,index } = this.props;

        this.setState({
            tooltipText,
            placeHolder,
            validation,
            label,
            defaultValue,
            name,
            value: value ? value :"",
            index
        })
    }
    componentDidMount() {
        this.managerValidation(this.state.value);
    }
    /* Logic */
    getError(type, validation) {
        validation = this.state.validation[type]
        if (validation.errorMessage) {
            return validation.errorMessage
        }
        return Constants.DEFAULT_MESSAGES.VALIDATION[type];
    }
    /* Functionality */
    validate(input) {
        if (this.state.validation && this.state.validation.required) {
            if (this.state.validation.required.value === true && (input===null || input===undefined || input === "")) {
                return this.getError("required")
            }

        }
        if (this.state.validation && this.state.validation.pattern && input) {
            const pattern = new RegExp(this.state.validation.pattern.value);
            if (pattern.test(input) === false) {
                return this.getError("pattern")
            }
        }
        return null;
    }
    managerValidation(value) {
		if (this.props.form) {
			this.props.updateData(this.state.name, this.props.value, this.props.form)
			return
		}
        const error = this.validate(value);
        if (error) {
            this.setState({
                errorMessage: error,
                value:value
            })
            this.props.updateData(this.state.name, null, false,this.props.index);
        } else {
            this.setState({
                errorMessage: null,
                value:value
            })
            if (this.props.type==="number"){
                value = parseFloat(value);
            }
            this.props.updateData(this.state.name, value, true,this.props.index);
        }
    }
    /* Manager Events*/
    handleChange = (e) => {
        this.managerValidation(e.target.value);
        
    }
    handleBlur = (e) => {
        this.setState({
            showError: true
        })
        this.managerValidation(this.state.value);
        
    }
    /* Section to get dynamic components*/
    componentJSX_isRequired(){
        if (this.state.validation && this.state.validation.required && this.state.validation.required.value === true){
            return <span className={`required`}>*</span>
        }
    }

    render() {
        return null;
    }
}

const mapStateToProps = state => ({
    session: state.session
});
const mapDispatchToProps = (dispatch) => ({

});
export default connect(mapStateToProps, mapDispatchToProps)(ClaraInputHidden)

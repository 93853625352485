import { Stack } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import useSession from 'src/modules/session/hooks/useSession';
import { LeftEducation } from 'src/v1/components';
import cls from "./class.module.scss";
import { Constants } from "src/v1/utils";
import useNavigation from 'src/modules/generic/hooks/useNavigation';
import MicroFrontend from "src/mf";
const GenerateDocumentsTemplateView = () => {
  const { docType, documentDraftId, templateId, customTemplateId } = useParams() || {}
  const { startupId } = useSession();
  const { goTo } = useNavigation()
  const leftEducationId = useMemo(() => {
    switch (docType) {
      case "USA_STRATEGIC_ADVISORY_BOARD_LETTER":
        return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.USA_STRATEGIC_ADVISORY_BOARD_LETTER.LEFT_EDUCATION_ID
      case "USA_CAL_EMPLOYEE_INFORMATION_AND_INVENTIONS_AGREEMENT":
        return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.USA_CAL_EMPLOYEE_INFORMATION_AND_INVENTIONS_AGREEMENT.LEFT_EDUCATION_ID;
      case "USA_CONSULTANT_INFORMATION_AND_INVENTIONS_AGREEMENT":
        return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.USA_CONSULTANT_INFORMATION_AND_INVENTIONS_AGREEMENT.LEFT_EDUCATION_ID;
      case "FOUNDERS_TERM_SHEET":
        return '';
      case "IP_ASSIGNMENT":
        return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.IP_ASSIGNMENT.LEFT_EDUCATION_ID;
      case "SAFE":
        return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.SAFE.LEFT_EDUCATION_ID;
      case "CONVERTIBLE_NOTE":
        return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.CONVERTIBLE_NOTE.LEFT_EDUCATION_ID;
      case "ADVISOR_AGREEMENT":
        return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.ADVISOR_AGREEMENT.LEFT_EDUCATION_ID;
      case "CONSULTANT_AGREEMENT":
        return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.CONSULTANT_AGREEMENT.LEFT_EDUCATION_ID;
      case "EMPLOYMENT_AGREEMENT_ADGM":
        return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.EMPLOYMENT_AGREEMENT_ADGM.LEFT_EDUCATION_ID;
      case "EMPLOYMENT_AGREEMENT_DIFC":
        return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.EMPLOYMENT_AGREEMENT_DIFC.LEFT_EDUCATION_ID;
      case "EMPLOYMENT_AGREEMENT_UK":
        return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.EMPLOYMENT_AGREEMENT_UK.LEFT_EDUCATION_ID;
      case "GRANT_AGREEMENT":
        return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.GRANT_AGREEMENT.LEFT_EDUCATION_ID;
      case "BOARD_RESOLUTION":
      case "SHARE_INCENTIVE_PLAN_FAQ":
      case "SHARE_INCENTIVE_PLAN":
        return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.SHARE_INCENTIVE_PLAN.LEFT_EDUCATION_ID;
      case "FOUNDER_SERVICES_AGREEMENT":
        return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.FOUNDER_SERVICES_AGREEMENT.LEFT_EDUCATION_ID;
      case "FOUNDERS_AGREEMENT":
        return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.FOUNDERS_AGREEMENT.LEFT_EDUCATION_ID;
      case "RESTRICTIVE_COVENANT_AGREEMENT":
        return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.RESTRICTIVE_COVENANT_AGREEMENT.LEFT_EDUCATION_ID;
      case "NDA_MUTUAL":
        return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.NDA_MUTUAL.LEFT_EDUCATION_ID;
      case "WEBSITE_COOKIE_NOTICE":
        return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.WEBSITE_COOKIE_NOTICE.LEFT_EDUCATION_ID;
      case "WEBSITE_TERMS_OF_USE":
        return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.WEBSITE_TERMS_OF_USE.LEFT_EDUCATION_ID;
      case "WEBSITE_PRIVACY_POLICY":
        return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.WEBSITE_PRIVACY_POLICY.LEFT_EDUCATION_ID;
      case "WEBSITE_ACCEPTABLE_USE_POLICY":
        return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.WEBSITE_ACCEPTABLE_USE_POLICY.LEFT_EDUCATION_ID;
      case "KISS":
        return '';
      case "BOARD_RESOLUTION_CONVERTIBLE_LOAN_NOTE":
      case "BOARD_RESOLUTION_CONVERTIBLE_SAFE":
      case "BOARD_RESOLUTION_CONVERTIBLE_KISS":
      case "BOARD_RESOLUTION_CONVERTIBLE_FIXED_EQUITY":
      case "BOARD_RESOLUTION_CONVERTIBLE_OTHER":
      case "BOARD_RESOLUTION_CONVERTIBLE":
        return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.BOARD_RESOLUTION_CONVERTIBLE.LEFT_EDUCATION_ID;
      case "USA_BOARD_CONSENT_SAFE_CONVERTIBLE_LOAN_NOTE":
      case "USA_BOARD_CONSENT_SAFE_SAFE":
      case "USA_BOARD_CONSENT_SAFE_KISS":
      case "USA_BOARD_CONSENT_SAFE_FIXED_EQUITY":
      case "USA_BOARD_CONSENT_SAFE_OTHER":
      case "USA_BOARD_CONSENT_SAFE":
        return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.USA_BOARD_CONSENT_SAFE.LEFT_EDUCATION_ID;
      case "USA_NDA":
        return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.USA_NDA.LEFT_EDUCATION_ID;
      case "USA_CONSULTING_AGREEMENT":
        return Constants.CONTENTFUL.ENTRIES.FORMS.AGREEMENT.GENERATE.USA_CONSULTING_AGREEMENT.LEFT_EDUCATION_ID;
      default:
        return
    }
  }, [docType]);
  


  return (
    <Stack direction={"row"} >
      <div id="GenerateDocumentsTemplateView_MF" className={cls.GeneratorDocuments}>
        <MicroFrontend id="MF_GenerateDocument_" component={"./ModeUser"} mf="documents" params={{
          docType,
          templateId,
          startupId,
          customTemplateId,
          documentDraftId,
          onCancel: () => {
            goTo(Constants.PAGES.chooseDocumentType);
          },
          goToDocuments: () => {
            goTo(Constants.PAGES.documents);
          }
        }} />

      </div>
    </Stack>

  );
};

export default GenerateDocumentsTemplateView;
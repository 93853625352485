import React, { useMemo } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import DetailsTabTitle from 'src/components/templates/DetailsTabTitle';
import TwoColumnsViewer from 'src/modules/generic/components/TwoColumsViewer';
import Button from 'src/modules/generic/components/Buttons/Button';
import EditBusinessActivityModal from 'src/modules/startup/GroupCompany/KY/Renewals/EditBusinessActivity';
import useModal from 'src/modules/generic/hooks/useModal';
import { RELEVANT_ACTIVITIES } from 'src/modules/startup/GroupCompany/Forms/FormBusinessActivityCayman';
import classes from '../../styles.module.scss';
import GroupCompany from 'src/components/modules/clara/startups/entities/classes/GroupCompany';
import PincasIcon from "src/modules/generic/components/Icon/PincasIcon";

function BusinessActivityPanel({ data, isLock }) {
  const { openModal } = useModal();

  const requestChangeButton = (
    <Button
      variant="card"
      children="Edit"
      disabled={isLock}
      onClick={() => {
        openModal(EditBusinessActivityModal, { initialValues: { groupCompanyId: data?.id } });
      }}
    />
  );

  const options = [
    {
      value: RELEVANT_ACTIVITIES.ACTING_AS_HOLDING_COMPANY,
      label: 'Acting as Holding Company',
    },
    {
      value: RELEVANT_ACTIVITIES.BANKING,
      label: 'Banking',
    },
    {
      value: RELEVANT_ACTIVITIES.DISTRIBUTION_AND_SERVICE,
      label: 'Distribution and Service Centre',
    },
    {
      value: RELEVANT_ACTIVITIES.FINANCING_AND_LEASING,
      label: 'Financing and Leasing Business',
    },
    {
      value: RELEVANT_ACTIVITIES.SHIPPING,
      label: 'Shipping',
    },
    {
      value: RELEVANT_ACTIVITIES.FUND_MANAGEMENT,
      label: 'Fund Management',
    },
    {
      value: RELEVANT_ACTIVITIES.ACTING_AS_HEADQUARTERS,
      label: 'Acting as Headquarters',
    },
    {
      value: RELEVANT_ACTIVITIES.INSURANCE,
      label: 'Insurance',
    },
    {
      value: RELEVANT_ACTIVITIES.INTELLECTUAL_PROPERTY,
      label: 'Intellectual Property',
    },
    {
      value: RELEVANT_ACTIVITIES.NONE_OF_ABOVE,
      label: 'None',
    },
  ];

  const mapArticlesOfAssociationType = (type) => {
    return type === 'CUSTOM' ? _.startCase(_.toLower('BESPOKE')) : _.startCase(_.toLower(type));
  };

  const groupCompanyClass = useMemo(() => {
    if (!data) return null;
    return new GroupCompany(data);
  }, [data]);

  return (
    <>
      <Divider className={classes.divider} />
      <DetailsTabTitle title="Business activity" buttons={requestChangeButton} />
      <TwoColumnsViewer
        itemsCol1={[
          {
            label: 'Relevant activities',
            value: (
              (data?.businessActivities?.relevantActivities || []).map((item, index) => (
                <div key={index}>
                  {_.find(options, (option) => option?.value === item)?.label}
                </div>
              ))
            ),
            valuePrefix: groupCompanyClass?.getFieldInMatter("businessActivities.relevantActivities") ? <PincasIcon/> : null,
            disabledField: groupCompanyClass?.getFieldInMatter("businessActivities.relevantActivities")
          },
        ]}
        itemsCol2={[
          {
            label: 'Articles of Association',
            value: mapArticlesOfAssociationType(_.get(data, 'businessActivities.articlesOfAssociationType', '')),
            valuePrefix: groupCompanyClass?.getFieldInMatter("businessActivities.articlesOfAssociationType") ? <PincasIcon/> : null,
            disabledField: groupCompanyClass?.getFieldInMatter("businessActivities.articlesOfAssociationType")
          },
        ]}
      />
    </>
  );
}

BusinessActivityPanel.propTypes = {
  data: PropTypes.any,
  isLock: PropTypes.bool
};

export default BusinessActivityPanel;
import _ from 'lodash';
import { JURISDICTION_TYPE } from 'src/modules/generic/types';

const useGuards = (initialValues) => {
  return {
    isCayman: () => {
      return _.get(initialValues, 'jurisdictionType') === JURISDICTION_TYPE.CAYMAN;
    },
    isNotCayman: () => {
      const jurisdictionType = _.get(initialValues, 'jurisdictionType');
      return jurisdictionType !== JURISDICTION_TYPE.CAYMAN || !jurisdictionType;
    },
    needMore: (context) => {
      return _.get(context, 'moreToAdd.another');
    },
    noNeedMore: (context) => {
      return _.get(context, 'moreToAdd.another') === false;
    },
    isRenewal: () => {
      return initialValues?.isRenewal;
    },
    isNotRenewal: () => {
      return !initialValues?.isRenewal;
    }
  }
}

export default useGuards;
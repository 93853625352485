import React, {FC, useMemo} from 'react'
import {StepProps} from "../../../../../../../../generic/components/Wizard/Step";
import SelectCompaniesStep from "../../../../../../wizard/steps/SelectCompaniesStepForm";
import WarrantForm from "../../../../../../forms/WarrantsForm";
import TemplateWizard from "../../../../../../../../generic/templates/Modal/TemplateWizard";
import Button from "../../../../../../../../generic/components/Buttons/Button";
import useCustomMachine from "../../../../../../../../generic/hooks/useCustomMachine";
import _ from "lodash";
import useTranslate from "../../../../../../../../generic/hooks/useTranslate";
const WarrantFormStep:FC<StepProps>=({})=>{
    const {stepData,next,send,state}=useCustomMachine()
    const {translate}=useTranslate()
    const handleCompleteSubmit=(values)=>{
        next(values)
    }
    const handleCancel=()=>{
        send("CANCEL")
    }

    const handlePrev=()=>{
        send("PREVIOUS")
    }
    const companyName=useMemo(()=>{
        if(_.isEmpty(_.get(state,'context.globalData',[]))) return  _.get(state,'context.data.select_companies.companies[0].name','')
        else return _.get(state,`context.globalData[0].select_companies.companies[${_.get(state,'context.numbersOfIteration',0)}].name`,'')
    },[JSON.stringify(state)])
    const currency=useMemo( ()=>{
        if(_.isEmpty(_.get(state,'context.globalData',[]))) return  _.get(state,'context.data.select_companies.companies[0].transactionCurrency','USD')? _.get(state,'context.data.select_companies.companies[0].transactionCurrency','USD'):'USD'
        else return _.get(state,`context.globalData[0].select_companies.companies[${_.get(state,'context.numbersOfIteration',0)}].transactionCurrency`,'USD')?_.get(state,`context.globalData[0].select_companies.companies[${_.get(state,'context.numbersOfIteration',0)}].transactionCurrency`,'USD'):'USD'
    },[JSON.stringify(state)])
    return(<>
        <WarrantForm
            initialValues={stepData}
            currency={currency}
            propsTemplate={{
                title: translate('MODULES.EQUITY.STEPS_WARRANT.WARRANT_FORM_STEP.TITLE'),
                subTitle: translate('MODULES.EQUITY.STEPS_WARRANT.WARRANT_FORM_STEP.SUBTITLE',{type:translate(`MODULES.EQUITY.TYPES_PLURAL.WARRANT`)})
            }}
            companyName={companyName}
            onCompleteSubmit={handleCompleteSubmit}
            Template={TemplateWizard}
            buttons={{cancel: <Button onClick={handleCancel}>Cancel</Button>,previous:_.isEmpty(_.get(state,'context.globalData'))?<Button onClick={handlePrev}>Previous</Button>:null}}/>
    </>)
}
export default WarrantFormStep
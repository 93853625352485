import { useMemo } from "react";
import TemplateWizardSkeletonMachine from "src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import { PlaceOfBusiness, PlaceSelect } from "../Steps";
const useSteps = ({isEdit}) => {
  const { translate } = useTranslate();
  return useMemo(() => ({
    getGroupCompaniesAndStakeholder: {
      render: TemplateWizardSkeletonMachine,
      renderProps: {
        title: translate('MODULES.EQUITY.ADD_EQUITY.TITLE'),
        subTitle: translate('MODULES.EQUITY.ADD_EQUITY.SUBTITLE'),
      },
      stepperPosition: 0
    },
    selectPlaceOfBusiness: {
      render: PlaceOfBusiness,
      stepperPosition: 0,
      renderProps: {
        isEdit: isEdit
      }
    },
    selectPlace: {
      render: PlaceSelect,
      stepperPosition: 0,
      renderProps: {
        isEdit: isEdit
      }
    },
    setPlaceOfBusiness: {
      render: TemplateWizardSkeletonMachine,
      renderProps: {
        title: translate('MODULES.EQUITY.ADD_EQUITY.TITLE'),
        subTitle: translate('MODULES.EQUITY.ADD_EQUITY.SUBTITLE'),
      },
      stepperPosition: 0
    },
  }), []);
}
export default useSteps;
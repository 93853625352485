import { useMemo } from 'react';
import useCreateUltimateParent from "../graphql/useCreateUltimateParent";
import useUpsertRenewalData from "../graphql/useUpsertRenewalData";
import useSession from "src/modules/session/hooks/useSession";

const useServices = ({ initialValues }) => {
  const { groupCompanyId } = initialValues;
  const { startupId } = useSession();
  const mutation = useCreateUltimateParent();
  const mutationRenewalData = useUpsertRenewalData();

  return useMemo(() => ({

    initialData: async (context, event) => {
      return initialValues;
    },

    SaveStep: async (context, event) => {
      let result = null;
      if (context?.SelectorForm?.accept) {
        const variables = {
          startupId,
          groupCompanyId: groupCompanyId,
          entityData: {
            name: context?.AddForm?.immediateParentName,
            taxJurisdiction: context?.AddForm?.taxJurisdiction,
            taxRegistrationNumber: context?.AddForm?.tin,
            mailingAddress: {
              street: context?.AddForm?.mailingAddress?.street,
              city: context?.AddForm?.mailingAddress?.city,
              state: context?.AddForm?.mailingAddress?.state,
              country: context?.AddForm?.mailingAddress?.country,
              zipCode: context?.AddForm?.mailingAddress?.zipCode,
              type: context?.AddForm?.mailingAddressType?.toUpperCase()
            }
          },
        };
        result = await mutation({ variables });
      } else {
        const variables = {
          startupId,
          groupCompanyId: groupCompanyId,
          renewalData: {
            hasUltimateParentControl: context?.SelectorForm?.accept
          }
        };
        result = await mutationRenewalData({ variables })
      }
      return result;
    },

  }), [initialValues]);
}

export default useServices;

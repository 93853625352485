import _ from "lodash";
import React, { FC, useMemo, useEffect } from 'react';
import Button from "../../../../../generic/components/Buttons/Button";
import useCustomMachine from '../../../../../generic/context/MachineContext/useMachine';
import useTranslate from '../../../../../generic/hooks/useTranslate';
import SelectCompanies from "../../../forms/SelectCompanies";
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import { GroupCompanyBO } from "src/modules/startup/core/type/startup/groupCompany";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { clearAssistatText } from "../../../../../generic/store/action";

const SelectCompaniesShareStep: FC<{}> = () => {

  const { send, next, stepData, state, context } = useCustomMachine();
  const { translate } = useTranslate()
  const { id } = useParams();
  const dispatch = useDispatch();

  const handlePrev = () => {
    send("PREVIOUS")
  }

  const handleCancel = () => {
    send('CANCEL')
  }

  const handleCompleteSubmit = async (values: any) => {
    next(values);
  }

  const handleInitModal = () => {
    dispatch(clearAssistatText());
    return () => {
      dispatch(clearAssistatText());
    }
  }  

  useEffect(() => {
    handleInitModal()
  }, []);

  const groupCompanies = useMemo((): GroupCompanyBO[] => {
    return _.orderBy(
      _.filter(context.getStakeholdersGroupCompanies.groupCompanies, gc => _.get(gc, 'id') !== id && gc.kind === "INCORPORATED")
      , ['isHoldingCompany'], ['desc']) as GroupCompanyBO[]
  }, [])

  useEffect(() => {
    if (groupCompanies && groupCompanies.length === 1) {
      next({
        companies: groupCompanies
      });
    }
  }, [groupCompanies])

  return (
    <>
      <SelectCompanies
        groupCompaniesList={groupCompanies}
        initialValues={stepData}
        propsTemplate={{
          title: translate(`MODULES.EQUITY.STEPS_EQUITY_POSITIONS.SELECT_COMPANIES_STEP.TITLE_${context.type.toUpperCase()}`),
          subTitle: translate(`MODULES.EQUITY.STEPS_EQUITY_POSITIONS.SELECT_COMPANIES_STEP.SUBTITLE_${context.type.toUpperCase()}`,)
        }}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        buttons={{ cancel: <Button onClick={handleCancel}>Cancel</Button>, previous: <Button onClick={handlePrev}>Previous</Button> }}
      />
    </>
  )
}
export default SelectCompaniesShareStep
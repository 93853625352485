import React, {Component} from "react";
import {connect} from "react-redux";
import classes from "./Chat.module.scss";
import {clearMessageChat, setInitialFlow} from '../../../store/actions/chatbot';
import {goToPage, goToPrevPage} from '../../../store/actions/page';
import AddStakeHolder from "../../containers/Forms/subForms/AddStakeHolder";
import {Field, Formik} from "formik";
import moment from "moment";
import FormikHelper from "../../utils/formikHelper";
import Loading from "../Loading/Loading";
import Mustache from "mustache";
import {getGroupCompaniesWrong} from "../../graphql/mutations/addStakeholder";
import ServerConnect from "../../utils/ServerConnect";
import {TooltipInput} from "../index";
import _ from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import validation from "./validation";
import FactoryChat from "./FactoryChat";
import TagManager from 'react-gtm-module';
import {RoleFields} from "../../../modules/stakeholder/graphql/typePolicies";

const Handlebars = require("handlebars");
const NumeralHelper = require("handlebars.numeral");
NumeralHelper.registerHelpers(Handlebars);

const dropzoneRef = React.createRef();


class Chat extends Component {

    constructor(props) {
        super(props);
        this.state = {
            date: undefined,
            groupCompanies: [],
            stakeholders: [],
            countryList: [],
            jurisdictions: [],
            shareClass: [],
            users: [],
            openModalUpload: false,
            acceptedFiles: [],
            uploadedFiles: [],
            checkbox: {},
            areCountries: false
        }
    }
    submitPrevQuestion= (values, form, event) => {
        const {activeQuestion} = this.props;

        const key = _.get(activeQuestion,"previousAnswer");
        const data = _.get(activeQuestion,"data");
        if (key) {
            this.props.submitPrevQuestion({...key,data});
        }
        
    }
    submitQuestion = (values, form, event, keepUploadedFilesInState = true) => {
        let category = "Unknown Chat Flow";
        if(window.location.href.indexOf("addMatter") > -1) {
            category = "Add Matter";
        } else if(window.location.href.indexOf("incorporationMatter") > -1) {
            category = "Scoping";
        }
        const tagManagerArgs = {
            dataLayer: {
                event: "GAEvent",
                category: category,
                action: "submitQuestion",
                label: JSON.stringify(Object.keys(values)),
                value: JSON.stringify(Object.values(values)),
                userid: this.props.session.user._id,
                firstName: this.props.session.user.firstName,
            },
            dataLayerName: "PageDataLayer",
        };
        TagManager.dataLayer(tagManagerArgs);
        const key = this.props.activeQuestion.bot_message.responseKeyName;
        const state = this.props.activeQuestion.id;
        event = event || this.getEventByInputType(values, key);
        const valuesToSend = key === 'country' ? values: values[key];
        this.props.submitQuestion(state, event, valuesToSend, key);

        if(!keepUploadedFilesInState){
            this.setState({
                checkbox: [],
                acceptedFiles: [],
                uploadedFiles: [],
            });
        } else {
            this.setState({
                checkbox: [],
                acceptedFiles: []
            });


        }

        if (form){
            form.setValues({});
        }
        
    }
    getEventByInputType(values, key) {
        const bot_message = this.props.activeQuestion.bot_message || {};
        const type = bot_message.type;
        const inputConfig = bot_message.inputConfig;
        switch (type) {
            case 'OPTIONS':
                if (_.get(bot_message, "inputConfig.answerBy")) {
                    return _.get(bot_message, "inputConfig.answerBy");
                }
                if (!inputConfig.isSelect && !inputConfig.isSelectWithEntity) {
                    return _.get(values[key], "key") || values[key] || "IDontKnow";
                } else {
                    return this.props.activeQuestion.answerBy;
                }
            default:
                return this.props.activeQuestion.answerBy;
        }
    }
    handleDateChange = (e, field, form) => {
        const date = e === null ? null : moment(e).format("YYYY-MM-DD");

        FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

        this.setState({
            date: e
        })
    }
    handleOptionsButtonClick = async (field, form, value) => {
        const formValues = await FormikHelper.setValueInTheCorrectPosition(field.name,form,value)
        this.submitQuestion(formValues, form);
    };
    getListsFromBackend = (entities) => {


        if (this.hasEntity('groupCompany', entities)) {
            this.callServerToGetGroupCompanies();
        }
        if (this.hasEntity('stakeholder', entities)) {
            return this.state.stakeholders || this.callServerToGetStakeholders();
        }
       

        if (this.hasEntity('countries', entities) && !this.state.areCountries) {
            this.setState({ areCountries: true })
        }

        if (this.hasEntity('jurisdictions', entities)) {

            this.setState({ jurisdictions: this.props.jurisdictions })
        }

    }
    hasEntity = (entity, entities) => {
        const nullCheckedEntities = entities || [];
        return nullCheckedEntities.includes(entity)
    }
    callServerToGetStakeholders = () => {

        const {startupId} = this.props;

        if(startupId) {
            const stakeholderList = `${RoleFields}{
                stakeholderList(startupId: "{{startupId}}") {
                    id
                    fullName
                    avatar
                    roles { ...RoleFields }
            }
        }`;

            const queryParams = {startupId};
            const query = Mustache.render(stakeholderList, queryParams);

            if (!this.state.stakeholders.length) {
                ServerConnect.graphQlQuery(query).then(result => {

                    if (result && result.stakeholderList) {
                        this.setState({
                            stakeholders: result.stakeholderList.map(sh => ({...sh, label: sh.fullName}))
                        });
                        this.props.setStakeholders(result.stakeholderList);

                    }
                });
            }
        }

    };
  
    componentWillMount() {
        this.callServerToGetGroupCompanies();
        this.callServerToGetStakeholders();

    }
    callServerToGetGroupCompanies = (refresh) => {

        const {startupId} = this.props;

        if(startupId){


            const queryParams = { startupId };

            if (!this.state.groupCompanies.length || refresh) {

            //const query = Mustache.render(getGroupCompanies, queryParams);

            ServerConnect.graphQlQuery(getGroupCompaniesWrong,queryParams).then(result => {

                if (result && result.getGroupCompanies) {
                    
                    this.setState({
                        groupCompanies: _.orderBy(result.getGroupCompanies, ['isHoldingCompany'], ['desc']).map(gc => ({ ...gc, label: gc.name })),
                        shareClass: _.flatten(result.getGroupCompanies.map(gc => {
                            return _.map(gc.shareClass, sc => ({ groupCompany: gc.id, ...sc }))
                        }))
                    });

                    this.props.setGroupCompanies(result.getGroupCompanies);

                    this.props.setShareClass(_.flatten(result.getGroupCompanies.map(gc => {
                        return _.map(gc.shareClass, sc => ({ groupCompany: gc.id, ...sc }))
                    })))
                }
            });
        }
        }
    };
    getEntitiesForAddInSelector = (inputConfig, startupId, chatData) => {

        let mode = "Default";

        if (inputConfig.entities.includes("investor")) {
            mode = "Investor"
        }

        const addsByEntity = [
            {
                type: "groupCompany",
                label: "Add New Company",
                textWarning: "This will create a new company for your startup. You can add more details later from the company's profile page.",
                placeholder: "Enter Company Name",
                startupId,
                params: null,
                paramsToReturn : "shareClass { id name }",
                paramsToCallback: "id name shareClass {id name}"
            },
            {
                type: "stakeholder",
                label: "Add New Corporate Stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add more details later from the stakeholder's profile page.",
                placeholder: "Enter Stakeholder's Name",
                form: !inputConfig.createStakeholderWithAutomaticRole ? (props) => (AddStakeHolder({ ...props, mode })) : undefined,
                formProps: !inputConfig.createStakeholderWithAutomaticRole ? { groupCompanies: this.state.groupCompanies } : undefined,
                startupId,
                textToSave: 'fullName',
                params: {
                    groupCompanyId: this.state.groupCompanyId,
                    isAnEntity: true,
                }

            },
            {
                type: "stakeholder",
                label: "Add New Individual Stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add more details later from the stakeholder's profile page.",
                placeholder: "Enter Stakeholder's Name",
                startupId,
                form: !inputConfig.createStakeholderWithAutomaticRole ? (props) => (AddStakeHolder({ ...props, mode })) : undefined,
                formProps: !inputConfig.createStakeholderWithAutomaticRole ? { groupCompanies: this.state.groupCompanies } : undefined,
                params: {
                    groupCompanyId: this.state.groupCompanyId,
                    isAnEntity: false,
                },
                textToSave: 'fullName'
            },
            {
                type: "shareClass",
                label: "Add New ShareClass",
                textWarning: "This will create a new share class  for your Company. You can add more details later from the 'Grow your startup'  menu.",
                placeholder: "Enter ShareClass Name",
                startupId,
                params: {
                    groupCompanyId: _.get(inputConfig, "filterEntityBy.includeOnlyGroupCompanyId")
                }
                },
            {
                type: "shareIncentivePlan",
                label: "Add New SIP",
                textWarning: "This will create a new SIP for your Share class. You can add more details later from the 'Grow your startup'  menu.",
                placeholder: "Enter SIP Name",
                startupId,
                params: {
                    groupCompanyId: _.get(inputConfig, "filterEntityBy.includeOnlyGroupCompanyId") || _.get(chatData, "companies.0", null),
                    shareClassId: _.get(chatData, "shareClass.id", null) || _.get(chatData, "optionClass.id", null)
                }
            }
        ];

        const filteredArrayOfAddForm = addsByEntity.filter(addByEntity => (
            inputConfig.entities.filter(entity => {

                const entityMapper = entity === "investor" ? "stakeholder" : entity;

                return entityMapper === addByEntity.type
            }
            ).length > 0
        )
        );

        return filteredArrayOfAddForm;
    }
    excludeCompany(companyId, inputConfig) {

        const companyIdFromBE = _.get(inputConfig, "filterEntityBy.excludeGroupCompanyId");
        return !(companyIdFromBE && companyIdFromBE === companyId);
    }
    includeOnlyCompany(companyId, inputConfig) {

        const companyIdFromBE = _.get(inputConfig, "filterEntityBy.includeOnlyGroupCompanyId");
        return !companyIdFromBE || companyIdFromBE === companyId;
    }
    applyAllFilter(companyId, inputConfig) {

        return this.excludeCompany(companyId, inputConfig, companyId) && this.includeOnlyCompany(companyId, inputConfig)

    }
    getEntityListForSelector = (inputConfig, chatData) => {

        const selectedShare = _.get(chatData, "shareClass.id", _.get(chatData,"optionClass.id"));

        const {shareClass, stakeholders} = this.state;

        const list = _.compact([
            inputConfig.entities.find(entity => entity === "groupCompany") && {
                type: "groupCompany",
                list: this.state.groupCompanies.filter(gc => this.applyAllFilter(gc.id, inputConfig)).map(gc => ({ id: gc.id, label: gc.name, isHoldingCompany: gc.isHoldingCompany}))
            },
            inputConfig.entities.find(entity => entity === "stakeholder") && {
                type: "stakeholder",
                list: stakeholders.map(st => ({ id: st.id, label: st.fullName }))
            },
            inputConfig.entities.find(entity => entity === "investor") && {
                type: "stakeholder",
                list: stakeholders.map(st => ({ id: st.id, label: st.fullName }))
            },
            inputConfig.entities.find(entity => entity === "user") && {
                type: "user",
                list: this.state.users.map(st => ({ id: st.id, label: st.fullName+" - "+st.email }))
            },
            inputConfig.entities.find(entity => entity === "shareClass") && {
                type: "shareClass",
                list: _.flatten(shareClass
                    .filter(sc => this.applyAllFilter(sc.groupCompany, inputConfig)))
                    .map(sc => ({ ...sc, label: sc.name }))
            },
            inputConfig.entities.find(entity => entity === "matterTypes") && {
                type: "matterTypes",
                list: inputConfig.options.map(option => ({ id: option.id, label: option.text }))
            },
            inputConfig.entities.find(entity => entity === "shareIncentivePlan") && {
                type: "shareIncentivePlan",
                list: _.flatten(
                    this.state.shareClass
                        .filter(sc => sc.id === selectedShare)
                        .map(sc => (sc.shareIncentivePlans))).map(sip => ({ ...sip, label: sip.name }))
            }

        ]);

        return list;
    };
    handleClose = () => {
        this.setState({
            openModalUpload: false
        })
    }
    getTitleUploadModal = (cantFiles) => {
        if (!cantFiles || cantFiles >= 1) {
            return "Uploading documents";
        }
        return `Uploading ${cantFiles} documents...`;
    }
    handleDone = (result = [], form, field, keepUploadedFilesInState = true ) => {

        return new Promise((resolve, reject) => {
            const uploadedFiles = this.state.uploadedFiles.concat(_.map(result, (element) => {
                return element
            }));

            form.setValues({
                [field.name]: uploadedFiles
            });

            this.setState(prevState => ({
                refresh: !prevState.refresh,
                uploadedFiles,
                keepUploadedFilesInState
            }));
            this.handleClose();
            resolve();
        });
    };
    deleteFile(file, form, field) {

        let formikFiles = form.values[field.name];
        let stateFiles = this.state.uploadedFiles;

        formikFiles = this.deleteFileFromArray(formikFiles, file);
        stateFiles = this.deleteFileFromArray(stateFiles, file);

        form.setValues({
            [field.name]: formikFiles
        });

        this.setState({
            uploadedFiles: stateFiles
        });

    };
    deleteFileFromArray(array, file) {
        return _.filter(array, elem => elem.id !== file.id);
    }
    handleDropFiles = (acceptedFiles) => {
        this.setState({
            openModalUpload: true,
            acceptedFiles: acceptedFiles,
            cantFiles: acceptedFiles.length
        })
    }
    handleSelectorChanged = (value, labelValue, form, paramsToCallbacks) => {

        const { groupCompanies, stakeholders } = this.state;
        const {data} = this.props;
        const {activeQuestion} = data;
        const {index} = paramsToCallbacks;
        const {inputConfig} = activeQuestion.bot_message;

        let selectedGroupCompany, selectedShareClass, selectedSIP;

        if (value) {

            const type = value.type;
            const id = value.id;

            switch (type) {

                case "groupCompany": {

                    const groupCompany =  groupCompanies.find(gc => gc.id === id);


                    if (groupCompany) {
                        this.setState(prevState => ({
                            ...prevState,
                            authorizedSignatories: {
                                ...prevState.authorizedSignatories,
                                [index]: groupCompany.authorizedSignatories ? groupCompany.authorizedSignatories.map(as => ({
                                    id: as.stakeholder.id,
                                    email: as.stakeholder.email,
                                    label: as.stakeholder.fullName
                                })) : []
                            }
                        }), () => this.props.refresh());

                    } else {
                        // new element in list
                        const gc = { ...form, name: labelValue };
                        delete gc.__typename;
                        groupCompanies.push(gc)
                        this.props.setGroupCompanies(groupCompanies);
                        this.callServerToGetGroupCompanies(true)
                    }

                };
                break;
                case "stakeholder": {

                    const stakeHolderSelected = _.find(stakeholders, (element) => {
                        return element.id === value.id
                    })
                    if (!stakeHolderSelected) { // new stakeholder
                        stakeholders.push({ id: value.id, fullName: labelValue })
                        this.props.setStakeholders(stakeholders);
                    }
                    this.setState(prevState => ({
                        ...prevState,
                        stakeholders
                    }))
                };
                break;
                case "shareClass": {

                    let  mappedShareClass = []
                    selectedGroupCompany = _
                        .find(groupCompanies, gc => gc.id === (_.get(inputConfig, "filterEntityBy.includeOnlyGroupCompanyId")
                            || _.get(data, "companies.0")))

                    selectedShareClass = _.find(selectedGroupCompany.shareClass, (element) => {
                        return element.id === value.id
                    })

                    if (!selectedShareClass) { // new element add to selector
                        const shareClass = { id: value.id, name: labelValue, shareIncentivePlans: [] , groupCompany: selectedGroupCompany.id};
                        if(selectedGroupCompany.shareClass){
                            selectedGroupCompany.shareClass.push(shareClass);
                        } else {
                            selectedGroupCompany.shareClass = [shareClass];
                        }

                        this.props.setGroupCompanies(groupCompanies);
                        mappedShareClass = _.flatten(groupCompanies.map(gc => {
                            return _.map(gc.shareClass, sc => ({ ...sc, groupCompany: gc.id }))
                        }))
                        this.props.setShareClass(mappedShareClass)
                    }

                    this.setState(prevState => ({
                        ...prevState,
                        groupCompanies,
                        shareClass : !selectedShareClass ?  mappedShareClass : prevState.shareClass
                    }));
                };
                    break;
                case "shareIncentivePlan": {
                    let selectedShareClass = {};

                     _.find(groupCompanies, company => {
                       return _.find(company.shareClass, sc => {
                            if (sc.id === (_.get(data, "shareClass.id") || _.get(data, "optionClass.id")))
                                return selectedShareClass = sc
                        });
                        
                    });

                    selectedSIP = _
                        .find(selectedShareClass.shareIncentivePlans, (element) => {
                            return element.id === value.id
                        });
                    if (!selectedSIP) { // new element add to selector
                        if (selectedShareClass.shareIncentivePlans) {
                            selectedShareClass.shareIncentivePlans.push({ id: value.id, name: labelValue });
                        } else {
                            selectedShareClass.shareIncentivePlans = [{ id: value.id, name: labelValue }];
                        }

                        this.props.setGroupCompanies(groupCompanies);
                    }
                    this.setState(prevState => ({
                        ...prevState,
                        groupCompanies
                    }));
                }

            }
        }

    }
    renderAcceptedFiles = (uploadedFiles, bot_message) => {

        return (
            <div className={classes.FilesContainer}>
                {
                    uploadedFiles.map(file => (
                        <div key={file.id} className={classes.FieldFile}>
                            <Field name={bot_message.responseKeyName}>
                                {({ field, form }) => (
                                    <div
                                        className={classes.File}
                                    >
                                        <div><img src="/img/icons/upload_done.svg" /></div>
                                        <p>{file.name}</p>
                                        <div className={classes.DeleteFileButton} onClick={() => this.deleteFile(file, form, field)} >
                                            X
                                        </div>
                                    </div>
                                )}
                            </Field>
                        </div>
                    ))
                }
            </div>
        )
    }
    handleCheckboxChange = (gc, i, form, field) => {
        

        this.setState(prevState => ({
            checkbox: {
                ...prevState.checkbox,
                [i]: !prevState.checkbox[i]
            }
        }), () => {
            let gcs = form.values[field.name] || [];
            if (gcs.filter(gcElement => gcElement === gc.id).length > 0) {
                _.remove(gcs, function (gcElement) {
                    return gcElement === gc.id;
                });
            } else {
                gcs.push(gc.id);
            }

            form.setValues({
                ...form.values,
                [field.name]: gcs
            })
        });
    }
    renderTexts() {

        const bot_message = _.get(this.props, "activeQuestion.bot_message", {})
        let  question_b = _.get(bot_message,"question","");
        if (!question_b){ question_b=""; }
        let  title_b = _.get(bot_message,"title","");
        if (!title_b){ title_b=""; }
        const questionTemplate = Handlebars.compile(question_b);
        const titleTemplate =    Handlebars.compile(title_b);
        
        const questionWithData = questionTemplate({ ...this.props.session, ...this.props.data });
        const titleWithData = titleTemplate({ ...this.props.session, ...this.props.data });
        return (
            <React.Fragment>
                <div className={classes.TitleText} dangerouslySetInnerHTML={{ __html: titleWithData }} />
                <div className={classes.QuestionText} dangerouslySetInnerHTML={{ __html: questionWithData }} />
            </React.Fragment>
        );
    }
    isDisabled = (errors, form,isValid) => {
        const bot_message = _.get(this.props, "activeQuestion.bot_message", {});
        if (bot_message.type == 'SPECIFIC_STEP_COMPONENT' && _.get(bot_message,"inputConfig.specificStepComponentName")=="SUBMIT_LAST_STEP"){
            return false;
        }
        
        return bot_message.type !== 'NO_INPUT' &&  (!isValid || (Object.keys(form.values).length === 0 || Object.keys(errors).length > 0));
    }

    render() {

        const {activeQuestion} = this.props

        const bot_message = _.get(activeQuestion, 'bot_message', {})

        const tellme_more = _.get( bot_message, "tellme_more", {});

        const tagManagerArgs = _.get(bot_message, "tagManagerArgs");

        // const initialValues = _.get( bot_message, "initialValues", {});

        const initialValues = _.get(activeQuestion, "data",{})

        const validationSchema = validation.generateSchema(_.get(this, "props.activeQuestion.bot_message", {}));

        if (this.props.loading) {
            return (
                <div className={classes.ChatContainer}>
                    <Loading
                        show={true}
                        labelStyle={{
                            width: '100%',
                            "text-align": 'center',
                            display: 'block',
                            position: "relative",
                            left: 0
                        }}
                        loadingStyle={{
                            background: 'none',
                        }}
                    />
                </div>
            );
        } else {
            if (tagManagerArgs) {
                const tagManagerArguments = {dataLayer: {...tagManagerArgs.dataLayer, userid: this.props.session.user._id, firstName: this.props.session.user.firstName}, dataLayerName: tagManagerArgs.dataLayerName};
                TagManager.dataLayer(tagManagerArguments);
            }
            return (
                <React.Fragment>
                    {this.props.onCloseChat && <div className={classes.CloseChat} onClick={() => this.props.onCloseChat()}>
                        <i className={"icon-x_symbol"}></i>
                    </div>}
                    <div className={classes.ChatContainer}>

                        <div className={classes.Chat}>
                            <div className={classes.IframesDiv}>
                                <div>
                                    {this.renderTexts()}
                                </div>
                                {tellme_more && tellme_more.label ?

                                    <div className={classes.tellmeMoreDiv}>
                                        <div className={classes.tellmeMoreDivContent}>
                                            <TooltipInput label={tellme_more.tooltip} size={"big"} align={"left"}>
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M9.57764 10.2555C9.57764 9.00984 10.5875 8 11.8332 8C13.0789 8 14.0887 9.00984 14.0887 10.2555C14.0887 11.5012 13.0789 12.5111 11.8332 12.5111V13.205"
                                                        stroke="#BEC9D7"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M11.8159 14.7666C11.9596 14.7666 12.0762 14.8831 12.0762 15.0269C12.0762 15.1706 11.9596 15.2871 11.8159 15.2871C11.6722 15.2871 11.5557 15.1706 11.5557 15.0269C11.5557 14.8831 11.6722 14.7666 11.8159 14.7666"
                                                        stroke="#BEC9D7"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M12.0039 20C16.4222 20 20.0039 16.4183 20.0039 12C20.0039 7.58172 16.4222 4 12.0039 4C7.58563 4 4.00391 7.58172 4.00391 12C4.00391 16.4183 7.58563 20 12.0039 20Z"
                                                        stroke="#BEC9D7"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                                <a target="_blank" rel="noopener noreferrer" href={tellme_more.link}>
                                                    {tellme_more.label}
                                                </a>
                                            </TooltipInput>
                                        </div>
                                    </div>
                                    : null
                                }
                            </div>

                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                isInitialValid= {props => validationSchema.isValidSync(initialValues)}
                                render={({
                                    values,
                                    errors,
                                    dirty,
                                    touched,
                                    handleSubmit,
                                    isSubmitting,
                                    handleReset,
                                    setFieldValue,
                                    isValidating,
                                    isValid,
                                    validateForm
                                }) => {
                                    {/*console.log(values)*/}
                                    {/*console.log(errors)*/}
                                    const factoryData = {
                                        params: {
                                            data: this.props.data,
                                            uploadedFiles: this.state.uploadedFiles,
                                            activeQuestion: activeQuestion,
                                            checkbox: this.state.checkbox,
                                            date: this.state.date,
                                            openModalUpload: this.state.openModalUpload,
                                            acceptedFiles: this.state.acceptedFiles,
                                            cantFiles: this.state.cantFiles,
                                            uploadFiles: this.state.uploadFiles,
                                            finished: this.props.finished,
                                            keepUploadedFilesInState: this.state.keepUploadedFilesInState,
                                            values,
                                            errors,
                                            isValid,
                                        },
                                        functions: {
                                            getListsFromBackend: this.getListsFromBackend,
                                            submitQuestion: this.submitQuestion,
                                            submitPrevQuestion: this.submitPrevQuestion,
                                            renderAcceptedFiles: this.renderAcceptedFiles,
                                            getTitleUploadModal: this.getTitleUploadModal,
                                            handleClose: this.handleClose,
                                            hasEntity: this.hasEntity,
                                            handleDateChange: this.handleDateChange,
                                            handleDropFiles: this.handleDropFiles,
                                            handleDone: this.handleDone,
                                            getEntitiesForAddInSelector: this.getEntitiesForAddInSelector,
                                            getEntityListForSelector: this.getEntityListForSelector,
                                            handleSelectorChanged: this.handleSelectorChanged,
                                            submitLastStep: this.props.submitLastStep,
                                            handleOptionsButtonClick: this.handleOptionsButtonClick,
                                            handleCheckboxChange: this.handleCheckboxChange,
                                            isDisabled: this.isDisabled,
                                            onCloseChat: _.get(this, "props.onCloseChat", () => { })
                                        }
                                    }
                                    return (
                                        <React.Fragment>
                                            <FactoryChat
                                                bot_message={bot_message}
                                                {...factoryData}
                                                initialValueStart={this.props.initialValueStart}
                                                initialKey={this.props.initialKey}
                                                setFieldValue = {setFieldValue}
                                            />
                                            {/* <pre>{JSON.stringify(values)}</pre>
                                            <pre>{JSON.stringify(errors)}</pre>
                                            <pre>{isValid?"TRUE":"FALSE"}</pre> */}
                                            
                                        </React.Fragment>
                                    );
                                }} />
                        </div>
                    </div>


                </React.Fragment>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        session: state.session
    };
};
const mapDispatchToProps = (dispatch) => ({
   
   
    setInitialFlow: () => setInitialFlow(dispatch),
    clearMessageChat: () => clearMessageChat(dispatch),
    goToPage: (page, params) => {
        goToPage(dispatch, page, params)
    },
    goToPrevPage: (reload, defaultPage, params) => {
        goToPrevPage(dispatch, reload, defaultPage, params)
    },

});

export default connect(mapStateToProps, mapDispatchToProps)(Chat);

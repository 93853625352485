import _ from "lodash";
import React, {Component} from 'react';
import moment from "moment";
import ClassNames from 'classnames';
import Handlebars from 'handlebars';
import {Field, FieldArray} from "formik";
import ClaraSelect from "../../../../components/Form/ClaraSelect/ClaraSelect";
import classes from "../../SteperForm.module.scss";
import TextInput from "../../Inputs/TextInput";
import Checkbox from '../../Inputs/Checkbox'
import FormikHelper from "../../../../utils/formikHelper";
import ClaraUpload from "../../../../components/Form/ClaraUpload/ClaraUpload";
import {ClaraRadioWithButton, TextAreaInput, ClaraHiddenField} from "../../../../components";
import ClaraCountrySelector from "../../../../components/Form/ClaraCountrySelector/ClaraCountrySelector";
import AuthorisedSignatories from "../../../../../components/inputs/AuthorizedSignatories/AuthorizedSignatories";
import SelectCompany from "../../../../../components/inputs/Select/SelectCompany/SelectCompany";
import SelectFounder from '../../../../../components/inputs/Select/SelectFounder/SelectFounder';
import VerifyDetails from "../../../../../components/inputs/VerifyDetails/VerifyDetails";
import ClaraAddVestingEquities from "../../../../components/Form/ClaraAddVestingEquities/ClaraAddVestingEquities";
import {validationsFoundersAgreement, validationsFoundersAgreement2} from "../validations";
import {groupCompanyRecipientNotice, stakeholderAuthorizedSignatory} from './helper';

class FoundersAgreement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            typeSelected: undefined,
            groupCompanySelected: undefined,
            authorizedSelected: undefined,
            authorizedSignatories: [],
            authorizedSignatoriesList: [],
            documentTypes: [],
            documentTypesList: [],
            dynamicParams: [],
            date: undefined,
            firstPage:true,
            valuesStated:false
        }
    };

    firstPage = (e) => {

        this.setState({
            firstPage:true
        })
        if(this.props.formType!=='uploadToEdit') {
            this.props.setShowFormSelector(true)
            const button = {
                nextButton: {
                    button: (<button style={{'width': '190px', 'height': '40px'}} data-cy="submit"
                                     className={`genericBlue`}>Next</button>),
                    onClick: this.secondPage,
                    hasNextPage: false
                },
            }
            this.props.setShowButtons(button, validationsFoundersAgreement);
        }
    }

    secondPage = (e) => {
       this.setState({
            firstPage: false
        });

         if (this.props.formType !== 'uploadToEdit') {
             this.props.setShowFormSelector(false);
             const button = {
                 nextButton: {
                     button: (<>
                         <button style={{'width': '190px', 'height': '40px'}} data-cy="submit"
                                 className={`genericBlue`}>Generate Document
                         </button>
                     </>),
                     onClick: this.props.generateDocument
                 },
                 extraButton: {
                     button: (
                     	<button
							onClick={this.firstPage} style={{
							 'marginRight': 'auto',
							 'marginTop': '1rem',
                            'marginLeft':'5.5rem'
							}}
							className={`genericWhite`}>Back
                     	</button>
					 )
                 }
             };
             this.props.setShowButtons(button, validationsFoundersAgreement2);
         }
    };

    componentDidMount() {
        /*
        if (this.props.values) {
            const values = this.props.values.values ? this.props.values.values : this.props.values;
            this.setState({
                typeSelected: values.documentType,
                groupCompanySelected: values.company,
                authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.company).map(as => ({
                    id: as.id,
                    label: as.label
                }))
            });
        }
         */
        setTimeout(()=> {
            if (this.props.formType === 'uploadToEdit') {
                if (this.props.initialValues.companyNotEstablished) {
                    console.log(this.props.initialValues.companyNotEstablished.jurisdiction)
                    this.props.setFieldValue('company', {
                        ...this.props.initialValues.companyNotEstablished,

                    })
                    this.setState({
                        groupCompanySelected: this.props.initialValues.company ? this.props.initialValues.company : {}
                    })
                } else {
                    this.props.setFieldValue('company', {
                        ...this.props.initialValues.company,
                        verified1: true,
                        verified2: true
                    })
                }
                _.forEach(this.props.initialValues.founders, (founder, index) => {
                    this.props.setFieldValue(`founders[${index}]`, {
                        ...founder,
                        verified1: true,
                        verified2: true,
                        startupPosition: _.get(_.find(this.props.stakeholderList, {id: founder.id}), 'startupPosition', [])
                    })
                })
                if (this.props.initialValues.terms.transferShares.transferSharesGroupCompanies) {
                    _.forEach(this.props.initialValues.terms.transferShares.companies, (company, index) => {
                        this.props.setFieldValue(`terms.transferShares.companies[${index}]`, {
                            ...company,
                            verified: true
                        })
                    })
                }
            }
        },1)
        if (this.props.formType !== 'uploadToEdit') {
            const button = {
                nextButton: {
                    button: (<button style={{'width': '190px', 'height': '40px'}} data-cy="submit"
                                     className={`genericBlue`}>Next</button>),
                    onClick: this.secondPage,
                    hasNextPage: false
                },
            };
            this.props.setShowButtons(button);
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {

    }

    selectType = (documentType, extraParams, form) => {
        this.setState({
            typeSelected: documentType,
            dynamicParams: this.getDynamicParamsByType(documentType)
        }, () => {
            FormikHelper.setValueInTheCorrectPosition('terms', form, []);
            FormikHelper.setValueInTheCorrectPosition('documentType', form, documentType);
            this.state.dynamicParams.forEach((dynamicParam, i) => {
                FormikHelper.setValueInTheCorrectPosition(
                    `terms[${i}].key`,
                    form,
                    dynamicParam.key || 'NO_KEY'
                );
            }) 
        });
    };

    fieldHasError(form,field) {
        return FormikHelper.returnErrorsForValidation(field.name, form.errors);
    }

    getDynamicParamsByType(documentType) {
        const type = this.props.documentTypes.filter(agrType => agrType.code === documentType)[0] || {};
        return type.fields || [];
    }

    getGroupCompanies = () => {
        let authorizedSignatories = this.props.authorizedSignatories;
        let groupCompanies = this.props.groupCompanies;
        let companies = [];
        if (groupCompanies && authorizedSignatories) {
            companies = _.filter(groupCompanies, (groupCompany) => {
                let fined = _.filter(authorizedSignatories, (authorized) => {
                    if (groupCompany.authorizedSignatories) {
                        return groupCompany.authorizedSignatories.some(au => au.stakeholder.id === authorized.id);
                    }
                    return false;
                });
                return !!fined;

            });
        }
        return companies;
    };

    selectGroupCompany = (gcId, extraParams, form) => {
        const groupCompany = this.findGroupCompany(gcId);
        if (groupCompany) {
			// this is for a weird behaviour when hit edit button after preview a document
			if (this.state.groupCompanySelected && groupCompany) {
				return null;
			}

            if (!groupCompany.registeredAddress) {
                groupCompany.registeredAddress = {}
            }
            let authorizedSignatories = _.get(form, 'values.company.authorizedSignatories', []);
    
            const newFormValues = {
                ...form.values,
                company: {
                    id: gcId,
					name: groupCompany.name || '',
					country: typeof  _.get(groupCompany, 'country', null) ==='object'?_.get(groupCompany, 'country.code', null) : _.get(groupCompany, 'country', null),
					jurisdiction: _.get(groupCompany, 'jurisdiction'),
					registeredNumber: groupCompany.registeredNumber || '',
					type: groupCompany.type || '',
					isHoldingCompany:_.get(groupCompany,'isHoldingCompany',false),
					registeredAddress: {
						...groupCompany.registeredAddress,
						country: typeof  _.get(groupCompany, 'registeredAddress.country', null) ==='object'? _.get(groupCompany, 'registeredAddress.country.code', null) : _.get(groupCompany, 'registeredAddress.country', null)
					},
					authorizedSignatories: authorizedSignatories,
					recipientNotice: groupCompanyRecipientNotice(form.values.company, groupCompany)
                }
            };
            this.setState({
                groupCompanySelected: groupCompany,
                // authorizedSignatoriesList: authorizedSignatories.map(as => ({
                // 	id: as.id,
                // 	label: as.label
                // }))
            });

            form.setValues(newFormValues);

        } else if (gcId === 'groupCompanyNotSelected') {
            this.setState({
                groupCompanySelected: { tooltipName: "holding company"}
            })
        } else {
            form.setValues({
                ...form.values,
                company: {},
                founders: []
            });
            this.setState({
                groupCompanySelected: null
            })
        }
    };

    findGroupCompany(gcId) {
        const gc = _.find(this.props.groupCompaniesList,{id:gcId});
        return gc?{...gc}:null
    };

    selectAddCompany = (company, arrayHelpers) => {
        if (company) {
            if (!company.registeredAddress) {
                company.registeredAddress = {}
            }
            arrayHelpers.push({
				id: company.id,
				name: company.name || '',
				type:company.type,
				managedByClara:company.managedByClara,
				country:typeof  _.get(company, 'country', null) ==='object'? _.get(company, 'country.code', null) : _.get(company, 'country', null),
				jurisdiction: _.get(company, 'jurisdiction'),
				registeredNumber: company.registeredNumber || '',
				type: company.type || '',
				isHoldingCompany: _.get(company, 'isHoldingCompany', false),
				registeredAddress: {
					...company.registeredAddress,
					country:typeof  _.get(company, 'registeredAddress.country', null) ==='object'? _.get(company, 'registeredAddress.country.code', null) : _.get(company, 'registeredAddress.country', null)
				},
				showTopLevelOption: false
            })
        } else {
            arrayHelpers.push({});
        }
    };

    selectFounder = async (founder, arrayHelpers, groupCompany) => {
        if (founder) {
            let authorizedSignatory = {};

            if (!founder.address) {
                _.set(founder,'address',{})
            }

            if (founder.isAnEntity) {
				authorizedSignatory = stakeholderAuthorizedSignatory(founder);
                // authorizedSignatory = {
                //     fullName: typeof founder.authorizedSignatory === 'object'
                //         ? _.get(founder, 'authorizedSignatory.fullName', '')
                //         : _.get(founder, 'authorizedSignatory', ''),
                //     email: founder.email || ''
                // }
            }

            const { startupPosition } = founder;

			const position = this.getFounderPositionMapped(founder, _.get(startupPosition, 'position'));

            const groupCompany = this.findGroupCompany(this.state.groupCompanySelected.id);
            const shares = _.flatMap((groupCompany?.advanceCapTable?.shareClasses || []),sh=>sh.shareIssuances)
            const sharesFiltered =(shares || []).filter(si=>si.holder.id===founder.id && si.status.state !== 'CANCELED' && si.status.state !== 'TRANSFERRED')

            arrayHelpers.push({
				id: founder.id,
				fullName: founder.fullName || null,
				email: founder.email || null,
				passportNumber: founder.passportNumber || null,
				phoneNumber:_.get(founder,'phoneNumber', null),
				registeredNumber: founder.registeredNumber || null,
				nationality: _.get(founder, 'nationality.code', null) || _.get(founder, 'nationality', null),
				jurisdiction: _.get(founder, 'jurisdiction'),
				otherJurisdiction: _.get(founder, 'otherJurisdiction', null),
				type: founder.type || null,
				share: sharesFiltered,
				address: {
					...founder.address,
					country:typeof  _.get(founder, 'address.country', null) ==='object'? _.get(founder, 'address.country.code', null) : _.get(founder, 'address.country', null)
				},
				isAnEntity: founder.isAnEntity,
				authorizedSignatory: authorizedSignatory,
                position: position,
				startupPosition:_.get(founder,'startupPosition',[])
			})
        } else {
            arrayHelpers.push({});
        }
    };

    handleAuthorizedSignatoryUpdate = (value) => {

        const { authorizedSignatories, groupCompanySelected } = this.state;
        const groupCompany = this.findGroupCompany(groupCompanySelected.id);
        _.set(groupCompany,'authorizedSignatories',_.map(groupCompany.authorizedSignatories,auth=>({...auth})))

        groupCompany.authorizedSignatories.push({ stakeholder: value });
        authorizedSignatories.push(value);

    };

	handleStakeholderCreate = (value) => {
		let { stakeholders, stakeholderList } = this.props;
		stakeholderList.push({ id: value.id, fullName: value.fullName, ...value });

		stakeholders.push({ id: value.id, label: value.fullName });
	};

	// handleStakeholderUpdate = (value) => {
	// 	let { stakeholders, stakeholderList } = this.props;
	//
	// 	const index = stakeholders.findIndex(sh => sh.id === value.id);
	//
	// 	if (index !== -1) {
	// 		const stakeholder = stakeholderList[index];
	//
	// 		stakeholder.fullName = value.fullName;
	// 		stakeholder.nationality = value.nationality;
	// 		stakeholder.passportNumber = value.passportNumber;
	// 		stakeholder.email = value.email;
	// 		stakeholder.phoneNumber = value.phoneNumber;
	// 		_.set(stakeholder, 'address.country', _.get(value, 'address.country', ''));
	// 		_.set(stakeholder, 'address.state', _.get(value, 'address.state', ''));
	// 		_.set(stakeholder, 'address.city', _.get(value, 'address.city', ''));
	// 		_.set(stakeholder, 'address.zipCode', _.get(value, 'address.zipCode', ''));
	// 		_.set(stakeholder, 'address.street', _.get(value, 'address.street', ''));
	//
	// 		stakeholders[index] = {id: value.id, label: value.fullName};
	// 		stakeholderList[index] = stakeholder;
	// 	}
	// };

	findStakeholder(roleId) {
        return this.props.stakeholderList.find(stakeholder => stakeholder.id === roleId);
    };

    findStakeholderAuthorized(roleId) {
        return this.props.stakeholderList.filter(stakeholder => {
            return stakeholder.roles.some(role => role.stakeholder.id === roleId);
        })[0] || null;
    };

    filterAuthorizedByGroupCompany(groupCompany) {
        if (groupCompany) {
            let gc = this.findGroupCompany(groupCompany.id);
            return this.props.authorizedSignatories.filter(authorized => {
                return authorized.groupCompanyId === gc.id
            });
        }
        return [];
    }

    selectAuthorized = (value, form, field, replace) => {
        const stakeholder = this.props.authorizedSignatories.find(e => e.id === value);
        const email = stakeholder ? stakeholder.email : '';
        replace(0, { id: value, email })
    };

    getDateForPickerFromData(date) {
        if (!date) return undefined;
        return moment(date).toDate();
    }

    handleChange(e, field, form) {

        const date = e === null ? null : moment(e).format("YYYY-MM-DD");

        FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

        this.setState({
            [field.name]: e
        })
    }

    getAuthorizedSignatoriesList = (index, form) => {
        if (form.values && form.values.company) {
            return this.filterAuthorizedByGroupCompany(form.values.company).map(as => ({
                id: as.id,
                label: as.label
            }))
        } else {
            return [];
        }
    };
    
    getStakeholderAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add new corporate stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add more details later on the stakeholder's profile page.",
                placeholder: "Stakeholder's name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: true,
                    // roles : [{
                    // 	role: "SHAREHOLDER",
                    // 	groupCompany: this.state.groupCompanySelected.id,
                    // }],
                },
                paramsReturn: 'isAnEntity'
            },
            {
                type: "stakeholder",
                label: "Add new individual stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add more details later on the stakeholder's profile page.",
                placeholder: "Stakeholder's name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: false,
                    // roles : [{
                    // 	role:"SHAREHOLDER",
                    // 	groupCompany: this.state.groupCompanySelected.id,
                    // }]
                },
                paramsReturn: 'isAnEntity'
            },
        ]
    };

    handleValidateCompany = (form, values, type) => {
        if (type === 'companyDetails') {
            _.get(values,'company.recipientNotice.useRegisteredAddress')
				? form.setFieldValue('company', {
					...values.company,
					recipientNotice: {
						...values.company.recipientNotice,
						useRegisteredAddress: true,
						address: {...values.company.registeredAddress}
					}
				})
				: form.setFieldValue('company', {
					...values.company,
					recipientNotice: {
						useRegisteredAddress: false,
						...values.company.recipientNotice
					}
				});
            form.setFieldValue('company.verified1', true)
        } else {
            _.get(values,'company.useRegisteredAddress')
				? form.setFieldValue('company', {
					...values.company,
					recipientNotice: {
						...values.company.recipientNotice,
						useRegisteredAddress: true,
						address: {
							...values.company.registeredAddress
						}
					}
				})
				: form.setFieldValue('company', {
					...values.company,
					recipientNotice: {
						...values.company.recipientNotice,
						useRegisteredAddress: false
					}
				});
            form.setFieldValue('company.verified2',true)
        }
    };

    getCompanyAdds = () => {
        return [
            {
                notAdd: true,
                type: 'no-comp',
                icon: 'no-comp',
                label: 'The holding company has not been established',
                id: 'groupCompanyNotSelected',
            }
		]
    };

    getAuthorizedSignatoryAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add New Individual Stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add more details later from the stakeholder's profile page.",
                placeholder: "Enter Stakeholder's Name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: false,
                    roles: [{
                        role: "DIRECTOR",
                        groupCompany: this.state.groupCompanySelected.id,
                    }]
                }
            },
        ]
    };

    handleGroupCompanyCreate = (value, name, additionalValue, form) => {

        let { groupCompanies, groupCompaniesList } = this.props;
        let newGc = { id: value.id, name, ...additionalValue };
        groupCompaniesList.push(newGc);

        groupCompanies.push({ id: value.id, label: name });
        // this.setState({ groupCompaniesList, groupCompanies });

    };

    handleAuthorizedSignatoryCreate = (value, name, additionalValue, form) => {

        let { authorizedSignatories } = this.props;
        const { groupCompanySelected } = this.state;

        authorizedSignatories.push({
            id: value.id,
            label: name,
            role: 'DIRECTOR',
            groupCompanyId: groupCompanySelected.id
        });

    };

	getFounderPositionMapped = (founder, founderPosition) => {
		return founderPosition ? {
			...founderPosition,
			..._.get(founder,'position',{}),
			responsibilities: founderPosition.isDefault
				? _.get(founderPosition,'responsibilities', [])
				: _.get(founderPosition,'responsibilities',null),
			otherResponsibilities: founderPosition.isDefault
				? null
				: _.get(founderPosition,'responsibilities[0].text',''),
			otherResponsibilitiesCode: founderPosition.isDefault
				? null
				: _.get(founderPosition,'responsibilities[0].code',null),
			otherResponsibility: founderPosition.isDefault
				? _.get(_.find(founderPosition.responsibilities,responsibility => !responsibility.isDefault), 'text', null)
				: null
		}:{};
	}

    render() {
        const isRequired = message => value => (!!value ? undefined : message);
        const { tooltips = {}, formType } = this.props;

        return (
            <div>
                <div className={classes.ContainerRow}>
                    {formType === 'uploadToEdit' ? (<div>
                        <Field name={`file`}>
                            {({field, form}) => (
                                <ClaraUpload
                                    required={true}
                                    name={`filepond`}
                                    label='Upload your document '
                                    field={field}
                                    form={form}
                                />
                            )}
                        </Field>
                    </div>) : null}
                </div>
                {this.state.firstPage?(<>
                    <div className={classes.ContainerRow}>
                        <Field name='agreementName'>
                            {({field, form}) => (
                                <TextInput
                                    required={true}
                                    type='text'
                                    label='Document name '
                                    field={field}
                                    form={form}
                                    placeholder={"Document name"}
                                    tooltip={tooltips['Document Name']}
                                />
                            )}
                        </Field>
                    </div>
                    <hr className={classes.Separator} />
                    <div className={`${classes.ContainerRow} ${classes.Select}`}>
						<Field name='company.id'>
							{({field, form}) => (
								<ClaraSelect
									required={true}
									field={field}
									form={form}
									label={"Select company "}
									lists={this.getGroupCompanies()}
									adds={this.getCompanyAdds()}
									mode={"classic"}
									callbacks={{
										createValues: this.handleGroupCompanyCreate,
										updateValues: this.selectGroupCompany
									}}
									placeholder={"Select from the list"}
									tooltip={tooltips['Select Company']}
								/>
							)}
						</Field>
                    </div>
                {
                    this.state.groupCompanySelected ?
                    <>
                        {this.state.groupCompanySelected.id ?
                            (<>
                                <hr className={classes.Separator} />
                                <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Holding Company</h1>
                                <p>Please ensure the Holding Company details are correct and complete by clicking on the “Please Verify” buttons below.</p>
                            </div>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>{this.state.groupCompanySelected.name}</h1>
                            </div>
                            <Field name={"company.verified_error"}>
							{({field, form}) => (
                                <div className={classes.ContainerRow}>
									<VerifyDetails
										nameHandle={'topco'}
										initialValues={{company: {
											..._.get(form.values,'company',{}),
										}}}
										groupCompany={this.state.groupCompanySelected}
										handleSubmit={(values, type) => this.handleValidateCompany(form, values, type)}
										title={'Company'}
										tooltips={tooltips}
										type={'company'}
										fieldName={'company'}
									/>
								</div>
							)}
							</Field></>):
							(<>
								<hr className={classes.Separator} />
								<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
									<h1>Holding Company not established</h1>
									<p>Please choose the country and jurisdiction your future holding company is going to be established in.</p>
								</div>
								<div className={`${classes.ContainerRow} ${classes.Select}`}>
									<Field name='company.country'>
										{({ field, form }) => (
											<ClaraCountrySelector
												isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
												jurisdictionsEnabled={true}
												initialValues={{ jurisdiction:_.get(form.values, 'company.otherJurisdiction') || _.get(form.values, 'company.jurisdiction') }}
												countryField={'company.country'}
												label={'Country of incorporation '}
												jurisdictionField={'company.jurisdiction'}
												jurisdictionLabel={'Jurisdiction '}
												returnCountryAsString={true}
												otherJurisdictionField={'company.otherJurisdiction'}
												setFieldValue={form.setFieldValue}
												additionalStyle={'ClaraCountrySelectorWizard'}
												tooltips={{
													country: Handlebars.compile(tooltips["Company's Country"])({company: this.state.groupCompanySelected.tooltipName}),
													jurisdiction: Handlebars.compile(tooltips["Company's Jurisdiction"])({company: this.state.groupCompanySelected.tooltipName}),
													other: tooltips['Other Jurisdiction']
												}}
											/>
										)}
									</Field>
								</div>
							</>)
						}
                        <hr className={classes.Separator} />
                        <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                            <h1>Founders</h1>
                            <p>Please add all Founders and verify their details. You can add Founders or create new Founders using the dropdown menu.</p>
                        </div>
                        <Field name='founders.error'>
                            {({field, form}) => (
                                <React.Fragment>
									<span hasError={_.get(form.errors, "founders") &&
									!_.some(_.get(form.errors,"founders",[]),founder => {
										return _.get(founder,'verified1',null) || _.get(founder,'verified2',null)
									}) && _.size(_.get(form.values,'founders',[])) < 2 ? "true" : "false"}>
                                        <div className={classes.ContainerRow}>
											<SelectFounder
												label={'Add founder '}
												list={this.props.stakeholderList}
												tooltips={tooltips}
												groupCompany={this.state.groupCompanySelected}
												onChange={this.selectFounder}
												fieldArrayName={'founders'}
												errorMessage={_.get(form.errors, "founders[0].share.vestingTerm") || _.get(form.errors, "founders[0].share") || _.get(form.errors, "founders")}
											/>
                                        </div>
									</span>
								</React.Fragment>
								)}
                        </Field>
                        { this.state.groupCompanySelected.id?(<>
                            <hr className={classes.Separator}/>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Corporate Signatories</h1>
                                <p>Please add all the signatories required to sign this document on behalf of the
                                    company.</p>
                            </div>
                            <Field name='company.authorizedSignatories_error'>
								{({field, form}) => (
									<React.Fragment>
										<span hasError={_.get(form.errors, "company.authorizedSignatories") ? "true" : "false"}>
											<AuthorisedSignatories
                                                list={this.props.stakeholderList}
												fieldArrayName={'company.authorizedSignatories'}
											idGroupCompany={this.state.groupCompanySelected.id}
											handleAddListStakeholder={this.handleAuthorizedSignatoryUpdate}
											errorMessage={_.get(form.errors, "company.authorizedSignatories")}
											/>
										</span>
									</React.Fragment>
									)}
							</Field></>):null
						}
                    	<hr className={classes.Separator}/>
                        <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                            <h1>Transfer Shares</h1>
                            <p>Please add any companies where Founders have shares that need to be transferred to the Holding Company (e.g. an operating company held in a Founder's name that needs to be brought under the Holding Company).</p>
                        </div>
                        <div className={classes.ContainerRow}>
                            <Field name={'terms.transferShares.transferSharesGroupCompanies'}>
                                {({field, form}) => (
                                    <ClaraRadioWithButton
                                        label={'Are there any companies whose shares need to be transferred to the Holding Company? '}
                                        field={field}
                                        required={true}
                                        form={form}
                                        changeParams={field.name}
                                        options={[{value: true, label: 'Yes'}, {value: false, label: 'No'}]}
                                        // onChange={(v) => { onChange(v,form) }}
                                    />
                                )}
                            </Field>
                        </div>
                        <Field name={'terms.transferShares.companies'}>
                            {({field, form}) =>_.get(form,'values.terms.transferShares.transferSharesGroupCompanies')? (<>
								<span hasError={_.get(form.errors, "terms.transferShares.companies")&& _.isEmpty(_.get(form.values,'terms.transferShares.companies'))? "true" : "false"}>
                                    <div className={classes.ContainerRow}>
										<SelectCompany
											label={'Add company '}
											list={this.props.groupCompaniesList}
											tooltips={tooltips}
											onChange={this.selectAddCompany}
											fieldArrayName={'terms.transferShares.companies'}
											groupCompanySelected={this.state.groupCompanySelected}
											errorMessage={_.get(form.error,'terms.transferShares.companies')}
										/>
                                    </div>
								</span>
							</>):null}
                        </Field>
                        <hr className={classes.Separator} />
                        <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                            <h1>Business Description</h1>
                        </div>
                        <div className={classes.ContainerRow}>
                            <Field name='terms.business.description'>
                                {({field, form}) => (
                                    <TextAreaInput
                                        required={true}
                                        type='text'
                                        trim={true}
                                        label='The business of the group is '
                                        field={field}
                                        form={form}
                                        placeholder={"Complete the sentence 'The business of the group is...'"}
                                        tooltip={tooltips['Bussiness Description']}
                                    />
                                )}
                            </Field>
                        </div>
                        <hr className={classes.Separator} />
                        <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                            <h1>Board of Directors</h1>
                            <p>Let's select the requirements for appointing Directors.</p>
                        </div>
                        <div className={classes.ContainerRow}>
                        <Field name='terms.directors.amount'>
                            {({field, form}) => (
                                <TextInput
                                    required={true}
                                    type='text'
                                    label='Number of directors '
                                    field={field}
                                    form={form}
                                    placeholder={'e.g. 1,2,5'}
                                    tooltip={tooltips['Response Time Other']}
                                />
                            )}
                        </Field>
                        </div>
                        <FieldArray
                            name={'terms.directors.founders'}
                            render={(arrayHelpers) => {
                                return (
                                	<>
										{!_.isEmpty(_.get(arrayHelpers.form.values, `founders`,[])) ? (<>
                                    	<div className={`${classes.labelToStakeholders}`} style={{paddingBottom:'3rem',paddingTop:'3rem'}}>
                                        	<label>How many directors will each founder appoint?<span style={{color:'red'}}>{' '}*</span> </label>
                                    	</div>
                                    	{_.get(arrayHelpers.form.values, `founders`,[]).map((founder, index) => {
                                        return (
                                        	<>
                                            	<div className={`${classes.ContainerRow } ${classes.StakeholderName}`}>
													<Field name={`terms.directors.founders[${index}].amount`}>
														{({field, form}) => (
															<TextInput
																type='text'
																label={`${founder.fullName} `}
																field={field}
																form={form}
																placeholder={'e.g. 1,2,5'}
																tooltip={tooltips['Response Time Other']}
															/>
														)}
													</Field>
	                                        	</div>
												<Field name={`terms.directors.founders[${index}].id`}>
													{({field, form}) => (<></>
													)}
												</Field>
                                            </>
										)})
                                    	}
                                	</>)
									:null}</>
								)
                            }}/>
							<div className={classes.ContainerRow}>
                            <Field name={'terms.directors.hasCastingVoteCP'}>
                                {({field, form}) => (
                                    <ClaraRadioWithButton
                                        label={'Will the Chairperson have a casting vote? '}
                                        field={field}
                                        required={true}
                                        form={form}
                                        changeParams={field.name}
                                        options={[{value: true, label: 'Yes'}, {value: false, label: 'No'}]}
                                        // onChange={(v) => { onChange(v,form) }}
                                    />
                                )}
                            </Field>
                        </div>
                        <hr className={classes.Separator}/>
                        <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                            <h1>Lead Founder</h1>
                                <p>A Lead Founder is a Founder who will be given preferential treatment over other Founders (e.g. appointing the Board chairperson, Lead Founder must be present for Shareholder meetings to proceed).</p>
                        </div>
                        <div className={classes.ContainerRow}>
                            <Field name={'terms.leadFounder.havesLeadFounder'}>
                                {({field, form}) => (
                                    <ClaraRadioWithButton
                                        label={'Is there a lead founder? '}
                                        field={field}
                                        required={true}
                                        form={form}
                                        tooltip={tooltips['Lead Founder']}
                                        changeParams={field.name}
                                        options={[{value: true, label: 'Yes'}, {value: false, label: 'No'}]}
                                        // onChange={(v) => { onChange(v,form) }}
                                    />
                                )}
                            </Field>
                        </div>
                        <Field>
                        {({form}) =>_.get(form,'values.terms.leadFounder.havesLeadFounder')? (<>
                            <div className={`${classes.ContainerRow} ${classes.Select}`}>
                            <Field name='terms.leadFounder.id'>
                                {({field, form}) =>(
									<ClaraSelect
										required={true}
										field={field}
										form={form}
										label={"Lead founder "}
										lists={_.map(_.get(form,'values.founders',[]),founder=>{return {label:founder.fullName,id:founder.id}})}
										mode={"classic"}
										placeholder={"Select from the list"}
									/>)}
                            </Field>
                            </div>
                            <div className={`${classes.ContainerRow}`}>
                                <Field name={`terms.leadFounder.minimumToAppointCP`}>
                                    {({field, form}) => (
                                        <TextInput
                                            required={true}
                                            type='percent'
                                            label='Minimum shareholding the lead founder must hold to appoint chairperson '
                                            field={field}
                                            form={form}
                                            rightLabel={'%'}
                                            placeholder={'e.g. 10,20'}
                                            tooltip={tooltips['Response Time Other']}
                                        />
                                    )}
                                </Field>
                            </div>
                        </>):null}
						</Field>
						<hr className={classes.Separator}/>
						<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
							<h1>Shareholders’ Meetings</h1>
							<p>The minimum number of Shareholders who must be present (quorum) for a Shareholder meeting to proceed. If you have selected a Lead Founder, then the Lead Founder will also be required to be one of the Shareholders present at the meeting.</p>
						</div>
						<div className={`${classes.ContainerRow}`}>
							<Field name={`terms.meetings.quorum`}>
								{({field, form}) => (
									<TextInput
										required={true}
										type='text'
										label='Quorum '
										field={field}
										form={form}
										placeholder={'e.g. 2,3,4'}
										tooltip={tooltips['Quorum']}
									/>
								)}
							</Field>
						</div>
						<hr className={classes.Separator}/>
						<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
							<h1>Matters requiring prior written approval of all founders</h1>
						</div>
						<div className={ClassNames(classes.ContainerRowAgreement, classes.ContainerRowAgreementLabel)}>
							<div className={classes.componentCheckBox}>
								<div className={ClassNames(classes.groupCheckbox, classes.groupCheckBoxWizard, classes.groupCheckBoxWizardLabel)}>
									<Field name='terms.matterList.option_a'>
										{({ field, form }) => (
											<CheckboxInput
												id='terms.matterList.option_a'
												label={'Any amendment or revision to the Articles or the articles of association of any Group Company.'}
												field={field}
												form={form}
												className={classes.checkBoxWizard}
											/>
										)}
									</Field>
									<Field name='terms.matterList.option_b'>
										{({ field, form }) => (
											<CheckboxInput
												id='terms.matterList.option_b'
												label={'Any variation of the rights attached to the Shares.'}
												field={field}
												form={form}
												className={classes.checkBoxWizard}
											/>
										)}
									</Field>
									<Field name='terms.matterList.option_c'>
										{({ field, form }) => (
											<CheckboxInput
												id='terms.matterList.option_c'
												label={'Any authorisation for or creation of (by reclassification or otherwise) any new class of shares.'}
												field={field}
												form={form}
												className={classes.checkBoxWizard}
											/>
										)}
									</Field>
									<Field name='terms.matterList.option_d'>
										{({ field, form }) => (
											<CheckboxInput
												id='terms.matterList.option_d'
												label={'Raising any equity capital or other additional investment (including by way of loan) in any group company in any amount from any person (including a founder).'}
												field={field}
												form={form}
												className={classes.checkBoxWizard}
											/>
										)}
									</Field>
									<Field name='terms.matterList.option_e'>
										{({ field, form }) => (
											<CheckboxInput
												id='terms.matterList.option_e'
												label={'The establishment of, or amendment to, any share incentive plan adopted by any group company.'}
												field={field}
												form={form}
												className={classes.checkBoxWizard}
											/>
										)}
									</Field>
									<Field name='terms.matterList.option_f'>
										{({ field, form }) => (
											<CheckboxInput
												id='terms.matterList.option_f'
												label={'The making or declaring of any dividend or distribution of by any group company.'}
												field={field}
												form={form}
												className={classes.checkBoxWizard}
											/>
										)}
									</Field>
									<Field name='terms.matterList.option_g'>
										{({ field, form }) => (
											<CheckboxInput
												id='terms.matterList.option_g'
												label={'Any reduction of the share capital, restructuring of the share capital of by any group company or the establishment of any subsidiary of the company.'}
												field={field}
												form={form}
												className={classes.checkBoxWizard}
											/>
										)}
									</Field>
									<Field name='terms.matterList.option_h'>
										{({ field, form }) => (
											<CheckboxInput
												id='terms.matterList.option_h'
												label={'Any IPO, sale, merger, acquisition, consolidation, or reorganisation by the group.'}
												field={field}
												form={form}
												className={classes.checkBoxWizard}
											/>
										)}
									</Field>
									<Field name='terms.matterList.option_i'>
										{({ field, form }) => (
											<CheckboxInput
												id='terms.matterList.option_i'
												label={'The adoption of, or material change to, any budget or business plan by any group company.'}
												field={field}
												form={form}
												className={classes.checkBoxWizard}
											/>
										)}
									</Field>

									<Field name='terms.matterList.option_j'>
										{({ field, form }) => (
											<CheckboxInput
												id='terms.matterList.option_j'
												label={'The making of any loan or advance, or the giving of any guarantee or indemnity, by any group company to any person.'}
												field={field}
												form={form}
												className={classes.checkBoxWizard}
											/>
										)}
									</Field>
									<Field name='terms.matterList.option_k'>
										{({ field, form }) => (
											<CheckboxInput
												id='terms.matterList.option_k'
												label={'The incurrence of any indebtedness or other financial obligation or the issuance of any guarantee for borrowed money by any group company, unless such liability is incurred pursuant to the then current business plan or the budget.'}
												field={field}
												form={form}
												className={classes.checkBoxWizard}
											/>
										)}
									</Field>
									<Field name='terms.matterList.option_l'>
										{({ field, form }) => (
											<CheckboxInput
												id='terms.matterList.option_l'
												label={'Any transaction, commitment or other expenditure of a capital nature (whether as part of a single transaction or series of transactions) by any group company in excess of USD 25,000 (unless such expenditure is made pursuant to the then current business plan or the budget or otherwise approved by the board).'}
												field={field}
												form={form}
												className={classes.checkBoxWizard}
											/>
										)}
									</Field>
									<Field name='terms.matterList.option_m'>
										{({ field, form }) => (
											<CheckboxInput
												id='terms.matterList.option_m'
												label={'The entry by any Group Company into any transaction with an affiliate.'}
												field={field}
												form={form}
												className={classes.checkBoxWizard}
											/>
										)}
									</Field>
									<Field name='terms.matterList.option_n'>
										{({ field, form }) => (
											<CheckboxInput
												id='terms.matterList.option_n'
												label={'Any sale, mortgage, pledge, lease, transfer or other disposal of any of the assets of any group company.'}
												field={field}
												form={form}
												className={classes.checkBoxWizard}
											/>
										)}
									</Field>
									<Field name='terms.matterList.option_o'>
										{({ field, form }) => (
											<CheckboxInput
												id='terms.matterList.option_o'
												label={'The entry by any group company into any licence, transfer, or assignment of intellectual property rights used in the business.'}
												field={field}
												form={form}
												className={classes.checkBoxWizard}
											/>
										)}
									</Field>
									<Field name='terms.matterList.option_p'>
										{({ field, form }) => (
											<CheckboxInput
												id='terms.matterList.option_p'
												label={'Any amendment to or termination of any relevant agreement or any increase in the remuneration or benefits of any founder.'}
												field={field}
												form={form}
												className={classes.checkBoxWizard}
											/>
										)}
									</Field>
									<Field name='terms.matterList.option_q'>
										{({ field, form }) => (
											<CheckboxInput
												id='terms.matterList.option_q'
												label={'The passing of a resolution for the winding up of any group company.'}
												field={field}
												form={form}
												className={classes.checkBoxWizard}
											/>
										)}
									</Field>
								</div>
							</div>
						</div>
						<hr className={classes.Separator}/>
						<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
							<h1>Place of Arbitration</h1>
							<p>This contract’s dispute resolution mechanism uses arbitration under expedited ICC rules. Please select the place where you would like the arbitration to take place (please enter the name of the city and country and, if applicable, state or freezone), e.g. "London, United Kingdom" or "New York City, New York, United States of America” or "Dubai International Financial Centre, Dubai, United Arab Emirates”. Please note that the place of arbitration that you select can have a material impact on the proceedings and enforcement of the arbitration and arbitral award. Please seek legal advice.</p>
						</div>
						<Field name={`terms.placeOfArbitration`}>
							{({ field, form }) => (
								<TextInput
									required={true}
									type='text'
									label='Place of arbitration '
									field={field}
									form={form}
									placeholder={"Place of arbitration"}
								/>
							)}
						</Field>
						{this.props.formType==='uploadToEdit' &&
							<div className={classes.buttonsContainers}>
							<button onClick={this.secondPage} className={'genericBlue'}>Next</button>
							</div>
						}
					</>
                    :null
                }
				</> ):(<>
                       <React.Fragment>
                           <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                               <h1>Shareholding</h1>
                               <p>{this.state.groupCompanySelected?.id?
							   "Please review the share position details below (and any applicable vesting terms) before generating the document. If you need to add, edit or delete any share positions listed below, please navigate to “Equity” and then “Shares” and select the relevant share position to do so. ":
							   "Let’s allocate the equity and lay out the vesting arrangements between the founders. Fill in the details below and ensure it reflects the intended share position for each founder."}</p>
                           </div>

						   <ClaraAddVestingEquities
							  groupCompany={this.findGroupCompany(this.state.groupCompanySelected.id)}
							  fieldArrayName={'founders'}
							  tooltips={tooltips}
						   />
							   {this.props.formType==='uploadToEdit' &&
								   <div className={classes.buttonsContainers}>
							   			<button onClick={this.firstPage} className={'genericWhite'}>Back</button>
								   </div>
							   }
					   </React.Fragment>
                </>)}
                {formType === 'uploadToEdit' ? <div>
                    <Field name='confirmSameData'>
                        {({field, form}) => (
                            <Checkbox
                                className={classes.sameDataCheckbox}
                                field={field}
                                validate={isRequired('This field is required')}
                                form={form}
                                required={true}
                                label='All document details are verified and correct '
                            />
                        )}
                    </Field>
                </div> : null}
            </div>
        );
    }
}

const CheckboxInput = (props) => {

	const { label, options, required = false, form, field, tooltips, input, hasError } = props;
	const { value } = field;
	const [selection, setSelection] = React.useState(value ? value.id : 0);

	const handleInput = () => {
		if (value && value.id !== selection) {
			setSelection(value.id);
			form.setFieldValue(`${field.name}.message`, '');
			if (!input) form.setFieldValue(`${field.name}.message`, label);
		}
	};

	if (value && value.id !== selection) {
		form.setFieldValue(`${field.name}.message`, label);
		setSelection(value.id);
	}

	handleInput();

	return (

		<>

			<Field name={`${field.name}.id`}>
				{({ field, form }) => (
					<Checkbox
						id={`${field.name}.id`}
						label={label}
						field={field}
						form={form}
						className={classes.checkBoxWizard}
						hasError={hasError}
					/>
				)}
			</Field>

			{
				input && value && value.id &&
				<>
					<Field name={`${field.name}.message`}>

						{({ field, form }) => (
							<TextInput
								type='text'
								field={field}
								form={form}
								className={{
									root: classes.InputContainer,
									input: classes.TextInput
								}}
								{...props.TextInput}
							/>
						)}
					</Field>
					<hr className={classes.Separator}></hr>
				</>
			}
		</>
	)

};

export default FoundersAgreement;

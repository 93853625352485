import React, {Component} from "react";
import {connect} from "react-redux";
import classes from "./DropDownMenu.module.scss";
import Avatar from "../../../../modules/generic/components/Avatars/AvatarLabel";

import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import {RoleAccess, Text} from "../../index";
import {Constants} from "../../../utils/constants";
import {goToPage} from "../../../../store/actions/page";

class DropDownMenu extends Component {
  state = {
    open: false,
    showName: true,
    show: true
  };

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };
  handleLogOut = event => {
    this.props.logout();
    this.handleClose(event);
  };

  handleAuditLogs = (event) => {
    this.props.goToPage(Constants.PAGES.auditLogs);
    this.handleClose(event);
  }
  handleReportBug = event => {
    window.open(
      "https://form.asana.com?hash=5b549b1c1155fae1cdd4c30fed5d46acb2d533c11ee90f347c0a104dd0d87b2e&id=1135628691006892"
    );
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.session !== this.props.session) {
    }
  }

  componentWillMount() {
    let { showName, show } = this.props;

    showName = showName !== false;
    show = show !== false;

    this.setState({
      showName: showName,
      show: show
    });
  }

  render() {
    const { open, show } = this.state;
    if (show && this.props.session.user) {

      return (
        <div className={classes.DropDownMenu}>
          <Button
            buttonRef={node => {
              this.anchorEl = node;
            }}
            aria-owns={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={this.handleToggle}
            id="first-step"
          >
            <Avatar
              avatar={this.props.session.user.avatar}
              name={this.props.session.user.fullName}
              showLabel={false}
            />
            {this.state.showName ? (
              <span>{this.props.session.user.fullName}</span>
            ) : null}
            {this.props.session.user.userRole !== Constants.PROFILE_ROLES.SERVICE_AGENT && this.props.session.user.userRole !== Constants.PROFILE_ROLES.FORMATION ?
              <span>
                {this.state.open ? (
                  <img src="/img/icons/arrow_drop_up.svg" />
                ) : (
                    <img src="/img/icons/arrow_drop_down.svg" />
                  )}
              </span>
              : null}
          </Button>
          <Popper
            open={open}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            placement={"bottom-end"}
            className={classes.menuList}
          >
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps} id="menu-list-grow">
                <Paper>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList>
                   
                      <RoleAccess action={Constants.ACTIONS.VIEW_AUDIT_LOGS} >
                        <MenuItem onClick={this.handleAuditLogs}>
                          <i className="icon-laptop_help"></i>
                          <label><Text uuid="MENU_VIEW_AUDIT_LOGS" /></label>
                        </MenuItem>
                      </RoleAccess>
                      <MenuItem onClick={this.handleLogOut}>
                        <img src="/img/icons/logout.svg" />
                        <label>Logout</label>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      );

    } else {
      return null;
    }
  }
}

const mapStateToProps = state => ({
  session: state.session
});
const mapDispatchToProps = dispatch => ({
  goToPage: (page, params) => {
    goToPage(dispatch, page, params)
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DropDownMenu);

import { useEffect } from "react";
import useQuery from "../../../../hooks/custom/useQuery";

export const GET_STAKEHOLDER = `
  query getStakeholderByTask($startupId: ID! $stakeholderId: ID!) {
    stakeholder: getStakeholder(startupId: $startupId stakeholderId: $stakeholderId) {
      id
      
      directors {
        id
        fullName
      }
    
    }
  }`;

const useGetStakeholderDirectors = (variables) => {
  const { loading, error, data } = useQuery(GET_STAKEHOLDER, variables);
  useEffect(() => {}, []);

  return { loading, error, data };
};
export default useGetStakeholderDirectors;

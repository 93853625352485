import useMutation from "src/hooks/custom/useMutation";

export const UPSERT_DELETE_ULTIMATE_BENEFICIAL_OWNER = `
mutation deleteUltimateBeneficialOwner(
  $startupId: ID!
  $groupCompanyId: ID!
  $entityId: ID!
) {
  deleteUltimateBeneficialOwner(
    startupId: $startupId
    groupCompanyId: $groupCompanyId
    entityId: $entityId
  ) {
    id
  }
}
`;

const useDeleteUltimateBeneficialOwner = () => {
  const [mutation] = useMutation(
    UPSERT_DELETE_ULTIMATE_BENEFICIAL_OWNER,
    { showSuccessNotification: true }
  );
  return mutation;
};

export default useDeleteUltimateBeneficialOwner;


import React, { FC, useEffect, useRef } from 'react';
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import _ from 'lodash';
import TagManager from 'react-gtm-module';
import './Modal.scss';
// import { useSelector } from 'react-redux';
import { Constants } from '../../../../../v1/utils';
import logger from '../../../../../v1/utils/logger';
import useSession from '../../../../session/hooks/useSession';

export interface ModalProps extends DialogProps {
  className?: string,
  overlayName?: string,
  variant?: string,
}

const Modal: FC<ModalProps> = ({ 
  open,
  children,
  title = '',
  className,
  maxWidth = "md",
  onClose = () => { },
  overlayName,
  variant,
  ...props
}) => {

  const session = useSession();
  const dialogRef = useRef();

  const userId = React.useMemo(() => {
    return _.get(session, 'user.id', '');
  }, []);

  const firstName = React.useMemo(() => {
    return _.get(session, 'user.firstName', '');
  }, []);

  const callTagManager = React.useCallback(() => {
    if (overlayName && userId) {
      const tagManagerArgs = {
        dataLayer: {
          event: "OverlayView",
          userId,
          firstName,
          path: window.location.pathname,
          pageName: document.title,
          overlayName,
        },
        dataLayerName: "PageDataLayer",
      };
      TagManager.dataLayer(tagManagerArgs);
    }
  },[userId, firstName, overlayName]);

  const log = React.useCallback(() => {
    logger.info(Constants.LOGGER.ACTIONS.MODAL, { overlayName, path: _.get(window, "location.href"), title })
  },[overlayName, title]);

  const registeInformation = React.useCallback(() => {
    callTagManager();
    log();
  },[log, callTagManager]);

  useEffect(() => {
    open && registeInformation();
  }, [open, registeInformation]);


  useEffect(() => {
    if (dialogRef && _.get(dialogRef, "current.tabIndex")) {
      if (dialogRef.current.getElementsByClassName("MuiDialog-container")) {
        dialogRef.current.getElementsByClassName("MuiDialog-container")[0].setAttribute("tabindex", null)
      }
    }
  }, [_.get(dialogRef, "current.tabIndex")]);

  return (
    <Dialog
      innerRef={dialogRef}
      {...{ maxWidth, open, onClose }}
      className={`${'Modal-clara-generic'} ${className}`}
      {...props}
    >
      {children}
    </Dialog>
  )
}

export default Modal;

import React from 'react';
import classes from './Checkbox.module.scss';

const InputFeedback = ({ error }) =>
    error ? <div style={{color:'#FF606F'}}>{error}</div> : null;

//TODO: PROPTYPES
const Checkbox = ({
    field: { name, value, onChange, onBlur },
    form: { errors, touched, setFieldValue },
    id,
    label,
    title,
    subTitle,
    isChat,
    required,
    className,
    divForChatClassName,
    handleChange,
    hasError,
    errorMessage,
    ...props
}) => {
    return (
        <React.Fragment>
            <div className={className ? className : classes.containerCheckbox}>
                {isChat ?
                    <div className={divForChatClassName} onClick={handleChange} />
                    :
                    <input
                        name={name}
                        id={id}
                        type="checkbox"
                        value={value}
                        checked={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        className={classes.radioButton}
                        hasError={hasError || errorMessage}
                    />
                }
                {label && <label htmlFor={id}>{label}</label>}
                {required ? <span style={{color: 'red'}}>*</span> : null}
                <div>
                    {title && <label>{title}</label>}
                    {subTitle && <span>{subTitle}</span>}
                </div>
                {(touched[name] || errorMessage) && <InputFeedback error={errorMessage} />}
            </div>
        </React.Fragment>
    );
};
export default Checkbox

import React from 'react';
import classes from './LinkButton.module.scss';
import Button from '../Button/Button'

const LinkButton = (props) => {
    return (
        <Button {...props} type={"button"} className={`${props.className} ${classes.LinkButton} `} > {props.children} </Button>
    )
}

export default LinkButton

import React, { FC } from "react";
import classes from "./classes.module.scss";
import ClaraAvatar from "../../../generic/components/Avatars/ClaraAvatar";

export interface AssistanceHelpAskProps {
  /** Property text */
  text: string,
  /** Property userName */
  userName: string,
  /** Property avatar */
  avatar: string,
} 

const AssistanceHelpAsk: FC<AssistanceHelpAskProps> = ({
  text,
  userName,
  avatar=null,
  ...props
}:AssistanceHelpAskProps) => {

  return (
    <div className={classes.askContainer}>
      <div className={classes.askText}>
        {text}
      </div>
      <div className={classes.askAvatar}>
        <ClaraAvatar
          name={userName}
          avatar={avatar}
          variant="small"
        ></ClaraAvatar>
      </div>
    </div>
  );

};

export default AssistanceHelpAsk;

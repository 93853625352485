import React, { Component } from "react";
import classes from "./ZoneTables.module.scss";
import TableConvertibles from "./TableConvertibles/TableConvertibles";
import TableWarrants from "./TableWarrants/TableWarrants";
import TableShares from "./TableShares/TableShares";
import TableIssuedShares from "./TableIssuedShares/TableIssuedShares";
import TableOptions from "./TableOptions/TableOptions";
import { Tabs } from "../../../index";
import tabsClasses from "../../../Tabs/Tabs.module.scss";
import { ModalMenu } from "../../../index";
import MaterialAvatarLabel from "../../../../../modules/generic/components/Avatars/AvatarLabel";
import _ from 'lodash';
import CapTableGraphs from "../../../../../v1/components/Dashboard/CapTable/components/CapTableGraphs";

// const getGroupCompaniesLiteQuery = `{getGroupCompanies(startupId: "{{startupId}}"){
//     id
//     avatar
//     name
//     type
//     jurisdiction
//     country { name code alpha3}
//     registeredNumber
//     transactionCurrency
//     isHoldingCompany
//   }
// }`;

class ZoneTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        { name: "Fully Diluted", key: "fullyDiluted" },
        { name: "Issued Shares", key: "issuedShares" },
        { name: "Share Options", key: "options" },
        { name: "Convertibles", key: "convertibles" },
        { name: "Warrants", key: "warrants" },
      ],
      tab: "fullyDiluted",
      isShares: true,
      shares: undefined,
      selectedGroupCompany: props.selectedGroupCompany,
      convertibles: undefined,
      groupCompanies: [],
      issuedShares: [],
      issuedSharesRows: undefined,
      optionsRows: undefined,
      unallocatedShares: [],
      buttons: {
        left: {
          id: "leftButtonTable",
          onClick: () =>
            this.scroll("sharesTable", this, "left", "ButtonTable")
        },
        right: {
          id: "rightButtonTable",
          onClick: () =>
            this.scroll("sharesTable", this, "right", "ButtonTable")
        }
      }
    }
  }

  goTo = tab => {
    this.setState({
      isShares: tab === "shares",
      tab
    });
  };

  componentDidMount() {
    // const queryParams = { startupId: this.props.startup.id };
    const { shareholders, shares, sipUnallocatedShares, selectedGroupCompany } = this.props;
    const warrantsRows = this.createWarrantsRows(shareholders);
    const convertiblesRows = this.createConvertiblesRows(shareholders);
    let sharesRows = this.createSharesRows(shareholders, shares);
    let issuedSharesRows = _.uniqBy(this.createIssuedSharesRows(shareholders, shares), "id");
    let optionsRows = _.uniqBy(this.createOptionsRows(shareholders, shares), "id");
    let unallocatedShares = this.createUnallocateShares(sipUnallocatedShares, shares, selectedGroupCompany);
    sharesRows = sharesRows.filter(sr => Object.keys(sr).length > 0);
    issuedSharesRows = issuedSharesRows.filter(sr => Object.keys(sr).length > 0);
    optionsRows = optionsRows.filter(sr => Object.keys(sr).length > 0);
    this.setState({
      selectedGroupCompany,
      sharesRows,
      convertiblesRows,
      warrantsRows,
      issuedSharesRows,
      optionsRows,
      unallocatedShares
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      const { shareholders, shares, sipUnallocatedShares, selectedGroupCompany } = nextProps;
      const warrantsRows = this.createWarrantsRows(shareholders);
      const convertiblesRows = this.createConvertiblesRows(shareholders);
      let sharesRows = this.createSharesRows(shareholders, shares)
      let issuedSharesRows = _.uniqBy(this.createIssuedSharesRows(shareholders, shares), "id");
      let optionsRows = _.uniqBy(this.createOptionsRows(shareholders, shares), "id");
      let unallocatedShares = this.createUnallocateShares(sipUnallocatedShares, shares, selectedGroupCompany);
      sharesRows = sharesRows.filter(sr => Object.keys(sr).length > 0);
      issuedSharesRows = issuedSharesRows.filter(sr => Object.keys(sr).length > 0);
      optionsRows = optionsRows.filter(sr => Object.keys(sr).length > 0);
      this.setState({
        sharesRows,
        issuedSharesRows,
        convertiblesRows,
        warrantsRows,
        optionsRows,
        selectedGroupCompany,
        unallocatedShares
      });
    }
  }

  createWarrantsRows(shareholders) {
    const warrantsRows = [];
    shareholders.forEach(sh => {
      const warrants = [];
      if (sh.warrants) {
        sh.warrants.forEach(warrant => {
          warrants.push(warrant);
        });
      }
      warrants.forEach(warrant => {
        warrantsRows.push({
          label: sh.personalData ? sh.personalData.fullName : '',
          investment: warrant.amount,
          valuation: warrant.valuation,
          grantDate: warrant.issuedDate,
          idWarrant: warrant.id,
          id: sh.personalData ? sh.personalData.id : '',
          avatar: sh.personalData ? sh.personalData.avatar : '',
          isAnEntity: sh.isAnEntity,
          isGroupCompany: sh.isGroupCompany
        });
      });
    });
    return warrantsRows;
  }

  createConvertiblesRows(shareholders) {
    const convertibleRows = [];
    shareholders.forEach(sh => {
      const convertibles = [];
      sh.convertible.forEach(c => {
        convertibles.push({
          id: c.id,
          investment: c.amount,
          discountRate: c.discountRate,
          valuationCap: c.valuationCap,
          interestRate: c.interestRate
        });
      });
      convertibles.forEach(convertible => {
        convertibleRows.push({
          label: sh.personalData ? sh.personalData.fullName : '',
          investment: convertible.investment,
          discountRate: convertible.discountRate,
          valuationCap: convertible.valuationCap,
          interestRate: convertible.interestRate,
          id: sh.personalData ? sh.personalData.id : '',
          avatar: sh.personalData ? sh.personalData.avatar : '',
          isAnEntity: sh.isAnEntity,
          isGroupCompany: sh.isGroupCompany,
          idConvertible: convertible.id
        });
      });
    });
    return convertibleRows;
  }

  createIssuedSharesRows(shareholders, propsShares) {
    const shares = [];
    shareholders.forEach(sh => {
      const issuedShares = {};
      if (sh.equity.length === 0) {
        return {}
      } // filtering those stakeholders with no equity
      sh.equity.forEach(eq => {
        const share = this.findShare(eq.shareClass.id, propsShares);
        const type = this.getShareTypeFromConstant(eq.type);
        if (type === 'Shares') {
          issuedShares[`${share.name} ${type}`] = (issuedShares[`${share.name} ${type}`] || 0) + eq.amount;
          shares.push({
            label: sh.personalData ? sh.personalData.fullName : '',
            fd: sh.fullyDiluted,
            id: sh.personalData ? sh.personalData.id : '',
            avatar: sh.personalData ? sh.personalData.avatar : '',
            shares: issuedShares,
            role: sh.type,
            isAnEntity: sh.isAnEntity,
            isGroupCompany: sh.isGroupCompany
          })
        }
      });
    });
    return shares;
  }

  createOptionsRows(shareholders, propsShares) {
    const optionsShares = [];
    shareholders.forEach(sh => {
      const optionsRows = {};
      if (sh.equity.length === 0 && sh.options.length === 0) {
        return {}
      } // filtering those stakeholders with no equity and no options
      const options = sh.equity.concat(sh.options);
      options.forEach(eq => {
        const share = this.findShare(eq.shareClass.id, propsShares);
        const type = this.getShareTypeFromConstant(eq.type);
        if (type === 'Options') {
          optionsRows[`${share.name} ${type}`] = (optionsRows[`${share.name} ${type}`] || 0) + eq.amount;
          optionsShares.push({
            label: sh.personalData ? sh.personalData.fullName : '',
            fd: sh.fullyDiluted,
            id: sh.personalData ? sh.personalData.id : '',
            avatar: sh.personalData ? sh.personalData.avatar : '',
            shares: optionsRows,
            role: sh.type,
            isAnEntity: sh.isAnEntity,
            isGroupCompany: sh.isGroupCompany
          })
        }
      });
    });
    return optionsShares;
  }

  createUnallocateShares(unallocatedShares, propsShares, selectedGroupCompany) {
    return unallocatedShares.map(us => {
      let shares = {}
      propsShares.forEach(sh => {
        const share = this.findShareByShareIncentivePlanId(us.id, propsShares);
        shares[`${share.name} Shares`] = us.unallocatedShares;
      })
      return {
        label: us.name,
        fd: us.unallocatedFullyDiluted,
        id: _.get(selectedGroupCompany, 'id', ''),
        avatar: '',
        shares,
        role: '',
        isAnEntity: false,
        isGroupCompany: false,
        isSIP: true,
        document: us.document?.id
      }
    })
  }

  createSharesRows(shareholders, propsShares) {
    return shareholders.map(sh => {
      const shares = {};
      if (sh.equity.length === 0 && sh.options.length === 0) {
        return {}
      } // filtering those stakeholders with no equity and no options
      const options = sh.equity.concat(sh.options);
      options.forEach(eq => {
        const share = this.findShare(eq.shareClass.id, propsShares);
        const type = this.getShareTypeFromConstant(eq.type);
        shares[`${share.name} ${type}`] = (shares[`${share.name} ${type}`] || 0) + eq.amount;
      });
      return {
        label: sh.personalData ? sh.personalData.fullName : '',
        fd: sh.fullyDiluted,
        id: sh.personalData ? sh.personalData.id : '',
        avatar: sh.personalData ? sh.personalData.avatar : '',
        shares,
        role: sh.type,
        isAnEntity: sh.isAnEntity,
        isGroupCompany: sh.isGroupCompany
      };
    });
  }

  getShareTypeFromConstant(shareConstantType) {
    if (shareConstantType) {
      const firstLetter = shareConstantType.charAt(0);
      const rest = shareConstantType.substring(1, shareConstantType.length).toLowerCase();
      return firstLetter + rest + 's';
    }
    return '';
  }

  findShare(shareId, shares) {
    return shares.filter(share => (share.id === shareId))[0] || {};
  }

  findShareByShareIncentivePlanId(shareIncentivePlanId, shares) {
    return shares.filter(share => _.some(share.shareIncentivePlans, sip => sip.id === shareIncentivePlanId))[0] || {};
  }

  scroll(elementId, component, direction, buttonId, speed = 5, distance = 500, step = 10) {
    const element = document.getElementById(elementId);
    if (element) {
      let scrollAmount = 0;
      const slideTimer = setInterval(function () {
        if (direction === "left") {
          element.scrollLeft -= step;
        } else {
          element.scrollLeft += step;
        }
        scrollAmount += step;
        if (scrollAmount >= distance) {
          window.clearInterval(slideTimer);
        }
        component.verifyAndChangeButtonsStates(element, component, buttonId);
      }, speed);
    }
  }

  verifyAndChangeButtonsStates(element, component, buttonId) {
    if (element.scrollLeft === 0) {
      component.changeButtonState("left" + buttonId, "Left", "Deactive");
    } else {
      component.changeButtonState("left" + buttonId, "Left", "Active");
    }
    if (element.offsetWidth + element.scrollLeft >= element.scrollWidth) {
      component.changeButtonState("right" + buttonId, "Right", "Deactive");
    } else {
      component.changeButtonState("right" + buttonId, "Right", "Active");
    }
    // console.log(buttonId, element.scrollLeft + element.offsetWidth, element.scrollWidth);
  }

  changeButtonState(buttonId, buttonPosition, state) {
    const button = document.getElementById(buttonId);
    // console.log(buttonId, buttonPosition, state);
    if (button) {
      button.className = `${tabsClasses.Button} ${tabsClasses[buttonPosition]} ${tabsClasses[state]}`;
    }
  }

  setIssuedShares = (issuedShares) => {
    this.setState({
      issuedShares,
    })
  };

  setRowsCount = (rowsCount) => {
    this.setState({
      rowsCount
    })
  }

  render() {
    return (
      <div className={classes.ZoneTable}>
        <div className={classes.ZoneTableHeader}>
          <div className={classes.ZoneTableTabs}>
            <Tabs
              classesTabs={classes.Tabs}
              leftId={"leftButtonGraph"}
              rightId={"rightButtonGraph"}
              onClickRight={() => this.scroll(
                "legendsDiv",
                this,
                "right",
                "ButtonGraph"
              )}
              onClickLeft={() => this.scroll(
                "legendsDiv",
                this,
                "left",
                "ButtonGraph"
              )}
              tabs={this.state.tabs}
              goTo={this.goTo}
              showHr={true}
              selected={this.state.tab}
            />
          </div>
          {this.props.companiesData.hasCompanies && (
            <div
              className={`${classes.CapTableDivSelect} ${classes.DivAvatar}`}
            >
              <ModalMenu
                captable={true}
                groupCompanies={this.props.companiesData.groupCompanies}
                changeSelectedGroupCompany={(gc) =>
                  this.props.changeSelectedGroupCompany(gc)
                }
              >
                <MaterialAvatarLabel
                  captable={true}
                  avatar={this.props.companiesData.groupCompany.avatar}
                  name={this.props.companiesData.groupCompany.name}
                  pointerLabel={true}
                  labelColor={'#192a3e'}
                />
              </ModalMenu>
            </div>
          )}
        </div>
        {!this.state.loading && <React.Fragment>
          {this.state.tab === 'fullyDiluted' && this.state.sharesRows ? (
            <div>
              <CapTableGraphs
                shareholders={this.props.shareholders}
                shares={this.props.shares}
                issuedShares={this.state.issuedShares}
              />
              <TableShares
                data={this.state.sharesRows}
                showButtons={this.state.rowsCount > 4}
                buttons={this.state.buttons}
                setIssuedShares={this.setIssuedShares}
                setRowsCount={this.setRowsCount}
                unallocatedShares={this.state.unallocatedShares}
              />
            </div>
          ) : null}
          {this.state.tab === "issuedShares" && this.state.convertiblesRows ? (
            <TableIssuedShares
              data={this.state.issuedSharesRows}
              showButtons={this.state.rowsCount > 4}
              setIssuedShares={this.setIssuedShares}
              buttons={this.state.buttons}
            />
          ) : null}
          {this.state.tab === "options" && this.state.warrantsRows ? (
            <TableOptions
              data={this.state.optionsRows}
              showButtons={this.state.rowsCount > 4}
              setIssuedShares={this.setIssuedShares}
              buttons={this.state.buttons}
            />
          ) : null}
          {this.state.tab === "convertibles" && this.state.convertiblesRows ? (
            <TableConvertibles data={this.state.convertiblesRows}
              showButtons={this.state.rowsCount > 4}
              buttons={this.state.buttons}
            />
          ) : null}
          {this.state.tab === "warrants" && this.state.warrantsRows ? (
            <TableWarrants data={this.state.warrantsRows} />
          ) : null}
        </React.Fragment>
        }
      </div>
    );
  }
}

export default ZoneTables;

import { createMachine } from 'xstate';
//TODO: Refactor so that each equity is a generic flow based on type
const machine =
  /** @xstate-layout N4IgpgJg5mDOIC5QFVZgE4AUCuAjANgJYDGASmFIbAC7oCG1hA9gHYB02a6AYk+gLYBiAHIBRABoAVRKAAOTWIUasZIAB6IAjABYAHGwBMAVgBsukye1GjABgDsuzQYA0IAJ5bNATjZGvf3V1tL20TOxt-AF9I11QMHAISckoaemV2AGslXgFBTFJRADVVeUV01Q0EHX1jMwsrWwcnVw8EO01fLxMbG10AZh6m42jYrgSiMgoqWgZmTOy+ITEpEoUlOYrEPr6fTXCvO1s+0J7NFq0DAzYIgKCQsJuRkDisPAnk6bS5thowWT68gVikgQKV1ioQZVtrt9ocbMdujYzu5EHZtNcuj1+oNHMMniwmBA4KoXuMklNUrNWBwuDl+KsyhtIYhtC4UW0fDs7H0DLoutpNDy+iYnqS3uSUjN0mwstQ6QzwSxNggDALDKZdEYdF4Qto+v1zghHGw+dzefzBQZhaKxuLJpKvtTfv8FeVmQg9Vc9hFEQ4ukZdIagmwuTy+ZZLdaYs9bYl7Z8qUqQWC3aBKppA+yALQdbQ2PM2IzaVlWvMWaLRIA */
  createMachine({
    id: 'addEquityWizard',
    initial: 'getStakeholdersGroupCompanies',
    states: {
      select: {
        on: {
          NEXT: {
            target: 'getStakeholdersGroupCompanies',
            actions: ['setContextAfterStep']
          }
        }
      },
      getStakeholdersGroupCompanies: {
        invoke: {
          src: 'getStakeholdersGroupCompanies',
          id: 'getStakeholdersGroupCompanies',
          onDone: [
            {
              target: 'selectEquityType',
              actions: ['setContextAfterInvoke']
            }
          ],
          onError: [
            {
              target: 'select'
            }
          ]
        }
      },
      selectEquityType: {
        on: {
          NEXT: {
            target: 'evaluateEquity',
            actions: ['setContextAfterStep']
          }
        }
      },
      evaluateEquity: {
        always: [
          //To Continue with option line 190
          {
            cond: 'isShare',
            target: 'selectCompaniesShare'
          },
          //To Continue with option line 153
          {
            cond: 'isOption',
            target: 'selectCompaniesOption'
          },
          //To Continue with warrant line 244
          {
            cond: 'isWarrant',
            target: 'selectCompaniesWarrant'
          },
          //To Continue with convertible line 90
          {
            cond: 'isConvertible',
            target: 'selectCompaniesConvertible'
          },
        ]
      },
      // Convertible flow
     
     

      selectCompaniesConvertible: {
        on: {
          NEXT: { target: 'addConvertibleForm', actions: ['setGroupCompanies'] },
          CANCEL: 'finish',
          PREVIOUS: 'selectEquityType',
        }
      },
      addConvertibleForm: {
        on: {
          NEXT: { target: 'addDocumentsConvertible', actions: 'setEquity' },
          CANCEL: 'finish',
          PREVIOUS: 'selectCompaniesConvertible'
        }
      },
      addDocumentsConvertible: {
        on: {
          NEXT: { target: 'isThereDocumentsConvertible', actions: 'setContextAfterStep' },
          CANCEL: 'finish',
          PREVIOUS: 'addConvertibleForm',
        }
      },
      isThereDocumentsConvertible: {
        always: [
          { target: 'uploadDocumentsConvertible', cond: 'uploadDocumentsConvertible' },
          { target: 'upsertConvertible', cond: 'noDocumentsConvertible', actions: [ 'setContextAfterStep'] },
          { target: 'getDocumentListConvertible', cond: 'generatedDocumentsConvertible' },
          { target: 'upsertConvertible', cond: 'isThereIsNoAgreement' , actions: ['setContextAfterStep'] }
        ]
      },
      uploadDocumentsConvertible: {
        on: {
          NEXT: { target: 'upsertConvertible', actions: [ 'setContextAfterStep'] },
          CANCEL: 'finish',
          PREVIOUS: 'addDocumentsConvertible'
        }
      },
      upsertConvertible: {
        invoke: {
          src: 'upsertConvertible',
          id: 'upsertConvertible',
          onDone: [
            {
              target: 'isThereMoreConvertibles',
              actions: ['setContextAfterInvoke','setIterations']
            }
          ],
          onError: [
            {
              target: 'error'
            }
          ]
        }
      },
      getDocumentListConvertible: {
        invoke: {
          src: 'getDocumentList',
          id: 'getDocumentListConvertible',
          onDone: [
            {
              target: 'generatedDocumentsConvertible',
              actions: ['setContextAfterInvoke']
            }
          ],
          onError: [
            {
              target: 'select'
            }
          ]
        }
      },
      generatedDocumentsConvertible: {
        on: {
          NEXT: { target: 'upsertConvertible', actions: [ 'setContextAfterStep'] },
          CANCEL: 'finish',
          PREVIOUS: 'addDocumentsConvertible'
        }
      },
      isThereMoreConvertibles: {
        always: [
          { target: 'addConvertibleForm', cond: 'isThereMoreConvertibles'},
          { target: 'selectToContinue', cond: 'noMoreConvertibles' },
        ]
      },
     
      generateDocumentOption: {
        on: {
          CANCEL: 'finish',
          PREVIOUS: 'selectCompaniesOption',
          NEXT:{target:'finish',actions:['goTo']}
        }
      },
      selectCompaniesOption: {
        on: {
          NEXT: { target: 'getDocumentListOptionSIP', actions: ['setGroupCompanies'] },
          CANCEL: 'finish',
          PREVIOUS: 'selectCompaniesOption',
        }
      },

      addDocumentsOptionSIP: {
        on: {
          NEXT: { target: 'isThereDocumentsOptionSIP', actions: 'setContextAfterStep' },
          CANCEL: 'finish',
          PREVIOUS: 'selectCompaniesOption',
        }
      },
      addOptionFormSIP: {
        on: {
          NEXT: { target: 'addDocumentsOption',  actions: ['setEquity','setContextAfterStep']  },
          CANCEL: 'finish',
          PREVIOUS: 'addDocumentsOptionSIP'
        }
      },
    
      isThereDocumentsOptionSIP: {
        always: [
          { target: 'uploadDocumentsOptionSIP', cond: 'uploadDocumentsOptionSIP' },
          { target: 'uploadDocumentsOptionSIPLater', cond: 'noDocumentsOptionSIP' , actions: [ 'setContextAfterStep'] },
          { target: 'generatedDocumentOptionSIP', cond: 'generatedDocumentsOptionSIP' },
          { target: 'addOptionForm', cond: 'isSip' }
        ]
      },
      upsertSipDocument: {
        invoke: {
          src: 'upsertSipDocument',
          id: 'upsertSipDocument',
          onDone: [
            {
              target: 'addOptionForm',
              actions: ['setContextAfterInvoke']
            }
          ],
          onError: [
            {
              target: 'error'
            }
          ]
        }
      },
      uploadDocumentsOptionSIP: {
        on: {
          NEXT: { target: 'upsertSipDocument', actions: ['setContextAfterStepOriginal'] },
          CANCEL: 'finish',
          PREVIOUS: 'addDocumentsOptionSIP'
        }
      },
      uploadDocumentsOptionSIPLater:{
        on: {
          NEXT: { target: 'upsertSipDocument', actions: ['setContextAfterStepOriginal'] },
          CANCEL: 'finish',
          PREVIOUS: 'addDocumentsOptionSIP'
        }
      },
      getDocumentListOptionSIP: {
        invoke: {
          src: 'getDocumentListOptionSIP',
          id: 'getDocumentListOptionSIP',
          onDone: [
            {
              target: 'addDocumentsOptionSIP',
              actions: ['setContextAfterInvoke']
            }
          ],
          onError: [
            {
              target: 'select'
            }
          ]
        }
      },
      generatedDocumentOptionSIP: {
        on: {
          NEXT: { target: 'addOptionForm', actions: ['setContextAfterStepOriginal'] },
          CANCEL: 'finish',
          PREVIOUS: 'addDocumentsOptionSIP'
        }
      },

      //Agrement Doc
     
      addOptionForm: {
        on: {
          NEXT: { target: 'addDocumentsOption',  actions: ['setContextAfterStepOriginal']  },
          CANCEL: 'finish',
          PREVIOUS: 'addDocumentsOption'
        }
      },
      addDocumentsOption: {
        on: {
          NEXT: { target: 'isThereDocumentsOption', actions: 'setContextAfterStep' },
          CANCEL: 'finish',
          PREVIOUS: 'addOptionForm',
        }
      },
     
    
      isThereDocumentsOption: {
        always: [
          { target: 'uploadDocumentsOption', cond: 'uploadDocumentsOption' },
          { target: 'upsertOption', cond: 'noDocumentsOption', actions: [ 'setContextAfterStepOriginal',] },
          { target: 'getDocumentListOption', cond: 'generatedDocumentsOption' },
          { target: 'upsertOption', cond: 'isThereIsNoAgreement',actions: [ 'setContextAfterStepOriginal'] }
        ]
      },
      uploadDocumentsOption: {
        on: {
          NEXT: { target: 'upsertOption', actions: ['setContextAfterStepOriginal' ] },
          CANCEL: 'finish',
          PREVIOUS: 'addDocumentsOption'
        }
      },
      upsertOption: {
        invoke: {
          src: 'upsertOption',
          id: 'upsertOption',
          onDone: [
            {
              target: 'isThereMoreOption',
              actions: ['setContextAfterInvoke','setIterations']
            }
          ],
          onError: [
            {
              target: 'error'
            }
          ]
        }
      },
      getDocumentListOption: {
        invoke: {
          src: 'getDocumentList',
          id: 'getDocumentListOption',
          onDone: [
            {
              target: 'generatedDocumentOption',
              actions: ['setContextAfterInvoke']
            }
          ],
          onError: [
            {
              target: 'select'
            }
          ]
        }
      },
      generatedDocumentOption: {
        on: {
          NEXT: { target: 'upsertOption', actions: ['setContextAfterStepOriginal'] },
          CANCEL: 'finish',
          PREVIOUS: 'addDocumentsOption'
        }
      },
      isThereMoreOption: {
        always: [
          { target: 'addOptionForm', cond: 'isThereMoreOptions'},
          { target: 'selectToContinue', cond: 'noMoreOptions' },
        ]
      },



      selectCompaniesWarrant: {
        on: {
          NEXT: { target: 'addWarrantForm', actions: ['setGroupCompanies'] },
          CANCEL: 'finish',
          PREVIOUS: 'selectEquityType',
        }
      },
      addWarrantForm: {
        on: {
          NEXT: { target: 'addDocumentsWarrant', actions: 'setEquity' },
          CANCEL: 'finish',
          PREVIOUS: 'selectCompaniesWarrant'
        }
      },
      addDocumentsWarrant: {
        on: {
          NEXT: { target: 'isThereDocumentsWarrant', actions: 'setContextAfterStep' },
          CANCEL: 'finish',
          PREVIOUS: 'addWarrantForm',
        }
      },
      isThereDocumentsWarrant: {
        always: [
          { target: 'uploadDocumentsWarrant', cond: 'uploadDocuments' },
          { target: 'isThereMoreWarrants', cond: 'noDocuments', actions: ['upsertWarrant', 'setContextAfterStep','setIterations'] },
          { target: 'getDocumentListWarrant', cond: 'generatedDocuments' }
        ]
      },
      uploadDocumentsWarrant: {
        on: {
          NEXT: { target: 'isThereMoreWarrants', actions: ['upsertWarrant', 'setContextAfterStep','setIterations'] },
          CANCEL: 'finish',
          PREVIOUS: 'addDocumentsWarrant'
        }
      },
      getDocumentListWarrant: {
        invoke: {
          src: 'getDocumentList',
          id: 'getDocumentListWarrant',
          onDone: [
            {
              target: 'generatedDocumentsWarrant',
              actions: ['setContextAfterInvoke']
            }
          ],
          onError: [
            {
              target: 'select'
            }
          ]
        }
      },
      generatedDocumentsWarrant: {
        on: {
          NEXT: { target: 'isThereMoreWarrants', actions: ['upsertWarrant', 'setContextAfterStep','setIterations'] },
          CANCEL: 'finish',
          PREVIOUS: 'addDocumentsWarrant'
        }
      },
      isThereMoreWarrants: {
        always: [
          { target: 'addWarrantForm', cond: 'isThereMoreWarrant' },
          { target: 'selectToContinue', cond: 'noMoreWarrants' },
        ]
      },
      selectCompaniesShare: {
        on: {
          NEXT: { target: 'addShareForm', actions: ['setGroupCompanies'] },
          CANCEL: 'finish',
          PREVIOUS: 'selectEquityType',
        }
      },
      addShareForm: {
        on: {
          NEXT: { target: 'addDocumentsShare', actions: 'setEquity' },
          CANCEL: 'finish',
          PREVIOUS: 'selectCompaniesShare'
        }
      },
      addDocumentsShare: {
        on: {
          NEXT: { target: 'isThereDocumentsShare', actions: 'setContextAfterStep' },
          CANCEL: 'finish',
          PREVIOUS: 'addShareForm',
        }
      },
      isThereDocumentsShare: {
        always: [
          { target: 'uploadDocumentsShare', cond: 'uploadDocuments' },
          { target: 'isThereMoreShares', cond: 'noDocuments', actions: ['upsertEquity', 'setContextAfterStep','setIterations'] },
          { target: 'getDocumentListShare', cond: 'generatedDocuments' }
        ]
      },
      uploadDocumentsShare: {
        on: {
          NEXT: { target: 'isThereMoreShares', actions: ['upsertEquity', 'setContextAfterStep','setIterations'] },
          CANCEL: 'finish',
          PREVIOUS: 'addDocumentsShare'
        }
      },
      getDocumentListShare: {
        invoke: {
          src: 'getDocumentList',
          id: 'getDocumentListShare',
          onDone: [
            {
              target: 'generatedDocumentsShare',
              actions: ['setContextAfterInvoke']
            }
          ],
          onError: [
            {
              target: 'select'
            }
          ]
        }
      },
      generatedDocumentsShare: {
        on: {
          NEXT: { target: 'isThereMoreShares', actions: ['upsertEquity', 'setContextAfterStep','setIterations'] },
          CANCEL: 'finish',
          PREVIOUS: 'addDocumentsShare'
        }
      },
      isThereMoreShares: {
        always: [
          { target: 'addShareForm', cond: 'isThereMoreShares' },
          { target: 'selectToContinue', cond: 'noMoreShares' },
        ]
      },
      selectToContinue: {
        on: {
          NEXT: {
            target: 'evaluateToContinue',
            actions: ['setContextAfterStep']
          }
        }
      },
      evaluateToContinue: {
        always: [
          {
            target: 'finish',
            cond: 'notContinue',
          },
          {
            target: 'getStakeholdersGroupCompanies',
            cond: 'continue',
            actions: ["resetIterations"]
          },
        ]
      },
      error: {
        on: {
          PREV: {
            target: 'select'
          }
        }
      },
      result: {
        on: {
          PREV: {
            target: 'select'
          }
        }
      },
      finish: {
        entry: 'stopMachine',
        type: 'final'
      }
    }
  });
export default machine;

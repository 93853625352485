import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import * as Yup from 'yup';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import Typography from 'src/modules/generic/components/Texts/Typography';
import CheckboxButtonSimple, {
  CheckboxButtonOptions,
} from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import classes from '../classes.module.scss';

/**
* Description Step: You have not qualify for a tech startup 
*/
function TechStartupConciergeFormStep() {

  const { send, state, context, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.TechStartupConciergeFormStep ?? {}
  }, [context]);

  const handleCancel = (values) => {
    cancel();
  }

  const handlePrev = (values) => {
    prev();

  }
  const handleSubmit = async (values) => {
    const nextStep = values?.checkYourStartup
    send({
      type: nextStep,
      payload: { idState: state.value, values: values }
    });
  }

  useEffect(() => {
    dispatch(addAssistantText('Clara concierge service provides personal, tailored service to help create a formation suited to your company’s needs.   Form anywhere in the world with Clara – simply choose another jurisdiction. '))
    return () => {
      dispatch(clearAssistatText())
    }
  }, [dispatch])

  const schema = Yup.object().shape({
    checkYourStartup: Yup.string().nullable().required("Required"),
  })

  const options: Array<CheckboxButtonOptions<string, any>> = [
    {
      value: 'CONCIERGE',
      component: ButtonText,
      componentProps: {
        title: "Use Clara concierge​",
      },
    },
    {
      value: 'CHANGE_JURISDICTION',
      component: ButtonText,
      componentProps: {
        title: "Choose a different jurisdiction",
      },
    },
  ];

  return (

    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: 'We can still help you',
          skeleton: null,
          subTitle: 'Form a company',
          variant: 'simple'
        }}
        buttons={{
          previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('BUTTON_NEXT')}</SubmitButton>,
        }}
      >
        <Typography weight='regular' variant='xbody'>
          Even if you can't form a Tech Startup Licence due to not satisfying ADGM requirements, we have alternative solutions for you to choose from.
        </Typography>
        <ControllerInput
          as={CheckboxButtonSimple}
          className={classes.tscfsCheckboxButton}
          classNameButton={classes.tscfsButtonCheckBoxSize}
          name="checkYourStartup"
          variant="col_1"
          options={options}
        />
      </TemplateWizard>
    </Form>
  );
}

export default TechStartupConciergeFormStep;

const ButtonText = ({ title, text }) => {
  return (
    <div>
      <Typography weight="bold" variant="xbody" className="mb-2">
        {title}
      </Typography>
    </div>
  );
};

import React, { useEffect, useState, useMemo } from "react";
import * as Yup from 'yup'
import cls from "./FormCompleteStakeholders.module.scss";
import _ from "lodash";
import { useDispatch } from "react-redux";
import Form from "../../../../../../../components/forms/Form/Form";
import useTranslate from "../../../../../../generic/hooks/useTranslate";
import SubmitButton from "../../../../../../generic/components/Buttons/SubmitButton";
import FormProps from "../../../../../../generic/forms/interfaces/FormProps";
import TemplateFormDefault from "../../../../../../generic/templates/Modal";
import Typography from "../../../../../../generic/components/Texts/Typography";
import CheckboxButtonSimple, { CheckboxButtonOptions } from '../../../../../../generic/components/Inputs/CheckboxButtonSimple';
import ControllerInput from '../../../../../../generic/components/Inputs/ReactHookForm/ControllerInput';
import { addAssistantText, clearAssistatText } from "../../../../../../generic/store/action";
import useMachine from '../../../../../../generic/context/MachineContext/useMachine';
import Tag from "../../../../../../../v1/components/Tag/Tag";
import useSession from '../../../../../../../modules/session/hooks/useSession';


const schema = Yup.object().shape({
  confirm: Yup.bool(),
})

interface FormCompleteStakeholdersProps extends FormProps {
  defaultData?: any;
}

const FormCompleteStakeholders: React.FC<FormCompleteStakeholdersProps> = ({
  initialValues = {},
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
  defaultData = {},
}) => {

  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const [confirmation, setConfirmation] = useState();
  const { next, context } = useMachine();
  const [hasStakeholders, setHasStakeholders] = useState(false);
  const { startup } = useSession();
  const acceleratorName = _.get(startup, "acceleratorDef.name", "");

  const handleTooltips = () => {
    dispatch(clearAssistatText());
    if (!hasStakeholders) {
      dispatch(addAssistantText(translate('MODULES.COMPLETE_STAKEHOLDERS.STEP_1.TOOLTIP3'), "alert"));
    }
    dispatch(addAssistantText(translate('MODULES.COMPLETE_STAKEHOLDERS.STEP_1.TOOLTIP1')));
    dispatch(addAssistantText(translate('MODULES.COMPLETE_STAKEHOLDERS.STEP_1.TOOLTIP2')));

  }

  useEffect(() => {
    handleTooltips();
  }, [hasStakeholders]);


  const stakeholdersData = useMemo(() => {

    let stakeholdersRoles = {}
    let companyList = []
    let stakeholderList = {}

    const roles = _.get(context, 'RetrieveStakeholders', null);

    if (roles) {

      _.forEach(_.get(roles, 'getGroupCompanies'), (company) => {
        companyList.push(_.get(company, 'name'))

      })

      _.forEach(_.get(roles, 'stakeholderList'), (stakeholder) => {
        if (_.get(stakeholder, 'isFounder')) {
          stakeholderList[_.get(stakeholder, 'fullName')] = { isFounder: _.get(stakeholder, 'isFounder') }
          _.forEach(companyList, (company) => {
            Object.assign(stakeholderList[_.get(stakeholder, 'fullName')], { [company]: ['NO_ROLE'] })
          })
        }
      })

      _.forEach(roles, (companies) => {
        _.forEach(companies, (company) => {
          _.forEach(_.get(company, 'roles'), (role) => {
            if (stakeholdersRoles[_.get(role, 'stakeholder.fullName')]) {
              //The stakeholder is already in the list

              if (_.get(stakeholdersRoles[_.get(role, 'stakeholder.fullName')], [_.get(company, 'name')], '') !== '') {
                //The stakeholder has already in this company, and have more roles to add

                // obtain the roles of the stakeholder in this company
                let stakeholderRoles = _.get(_.get(stakeholdersRoles, [_.get(role, 'stakeholder.fullName')]), [_.get(company, 'name')])
                // add the new role to the list
                stakeholderRoles.push(_.get(role, 'name'))

                // update the stakeholder roles in the list
                Object.assign(stakeholdersRoles[_.get(role, 'stakeholder.fullName')], { [_.get(company, 'name')]: stakeholderRoles })

              } else {
                //the stakeholder has to be assigned to this company

                Object.assign(stakeholdersRoles[_.get(role, 'stakeholder.fullName')], { [_.get(company, 'name')]: [_.get(role, 'name')] })

                // As we already have a role for this founder, we can remove it from the list of stakeholders to merge.
                _.unset(stakeholderList[_.get(role, 'stakeholder.fullName')], _.get(company, 'name'))
              }
            } else {
              //The stakeholder is not in the list
              let roleToAdd = [_.get(role, 'name')]

              Object.assign(stakeholdersRoles, { [_.get(role, 'stakeholder.fullName')]: { [_.get(company, 'name')]: roleToAdd, isFounder: _.get(role, 'stakeholder.isFounder') } })

              // As we already have a role for this founder, we can remove it from the list of stakeholders to merge.
              _.unset(stakeholderList[_.get(role, 'stakeholder.fullName')], _.get(company, 'name'))

            }
          })
        })
      })
    }

    // merge the list of stakeholders with the list of founders
    _.merge(stakeholdersRoles, stakeholderList)

    if (!_.isEmpty(stakeholdersRoles)) {
      setHasStakeholders(true)
      return stakeholdersRoles
    }

  }, []);

  const handleSubmit = async (values) => {
    next(values)
  };

  const stakeholdersConfirmation: Array<CheckboxButtonOptions<boolean, any>> = [
    {
      value: false,
      component: ButtonOptions,
      componentProps: {
        text: <Typography variant={"s"} weight={'regular'}>{translate(`MODULES.COMPLETE_STAKEHOLDERS.STEP_1.CONFIRMATION_NO`)} </Typography>
      },
    },
    {
      value: true,
      component: ButtonOptions,
      componentProps: {
        text: <Typography variant={"s"} weight={'regular'}>{translate(`MODULES.COMPLETE_STAKEHOLDERS.STEP_1.CONFIRMATION_YES`)} </Typography>
      },
    },
  ];

  const getRoles = (roles) => {
    if (roles.lenght <= 0) {
      return <Typography variant={"body"} >{translate(`MODULES.COMPLETE_STAKEHOLDERS.STEP_1.NO_ROLE`)} </Typography>
    } else {

      let rolesString = '';

      _.map(roles, (role) => {
        rolesString = rolesString + translate(`MODULES.MAP.ROLES_LABEL.${role}`) + ', ';
      })

      rolesString = rolesString.substring(0, rolesString.length - 2);

      return (
        <Typography variant={"body"} >{rolesString}</Typography>
      )

    }
  }


  const getStakeholderData = (values) => {

    let table = [];
    _.map(values, (roles, company) => {
      if (company !== 'isFounder') {

        table.push(
          <React.Fragment>
            <div><Typography variant={"body"} >{company}</Typography></div>
            <div>{getRoles(roles)}</div>
          </React.Fragment>
        )
      }
    })

    return table
  }

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
      onlySendDirty={false}
    >
      <Template
        buttons={{
          ...buttons,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={() => !confirmation} {...buttonSubmitProps}>{translate('TEXT_CONFIRM')}</SubmitButton>,
        }}
        props={propsTemplate}
      >
        <div >
          <span className={cls.description} dangerouslySetInnerHTML={{ __html: translate(`MODULES.COMPLETE_STAKEHOLDERS.STEP_1.DESCRIPTION`, { acceleratorName: acceleratorName}) }}></span>
        </div>

        <div className={cls.form}>
          <div className={cls.stakeholderTitles}>
            <div><Typography variant={"body"} weight={'semibold'}>Name </Typography></div>
            <div><Typography variant={"body"} weight={'semibold'}>Company </Typography></div>
            <div><Typography variant={"body"} weight={'semibold'}>Roles </Typography></div>
          </div>

          <React.Fragment>

            {hasStakeholders &&
              _.map(stakeholdersData, (values, stakeholder) => {

                return (
                  <div className={cls.stakeholdersRoles} >
                    <div className={cls.stakeholder} style={{ gridRow: `1/${_.keys(values).length + 1}` }}>
                      <div>
                        <Typography variant={"body"} weight={'semibold'}> {stakeholder}</Typography>
                      </div>
                      {_.get(values, 'isFounder') ? <div className={cls.founderTag}> <Tag type={'isFounderSquare'} show={"isFounder"} /> </div> : null}
                    </div>

                    {getStakeholderData(values)}

                  </div>
                )
              })
            }
          </React.Fragment>


        </div>

        {hasStakeholders &&
          <div className={cls.confirmation} >
            <div className={cls.confirmationText} >
              <Typography variant={'body'} weight={'regular'}>
                {translate('MODULES.COMPLETE_STAKEHOLDERS.STEP_1.CONFIRMATION')}
              </Typography>
            </div>
            <div className={cls.confirmationOptions} >
              <ControllerInput
                as={CheckboxButtonSimple}
                name="confirm"
                variant="col_1"
                size={"auto"}
                options={stakeholdersConfirmation}
                onChange={(value) => {
                  setConfirmation(value);
                }}
                classNameButton={`${cls.buttonCheckBox}`}
              />
            {confirmation === false &&
              <div style={{paddingTop:'1rem'}}><Typography variant={"p"} weight={'bold'}>Please add all stakeholders and their roles to proceed.</Typography></div>
            }
            </div>
          </div>
        }

      </Template>
    </Form>
  );
};

export default FormCompleteStakeholders;

const ButtonOptions = ({ title, text }) => {
  return (
    <div className={cls.buttonContainer}>
      <Typography weight="semibold" variant="h4" className="mb-2">
        {title}
      </Typography>
      <Typography variant="body">{text}</Typography>
    </div>
  );
};

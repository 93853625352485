import React, {Component} from 'react';
import {Constants} from '../../../v1/utils/constants';
import {helper} from "../../../v1/utils";
import moment from "moment";

/*
    Properties:
        value: key to get config 
        lang: lang to use (not enabled now)
    
    reference: https://www.npmjs.com/package/react-number-format
*/
class Date extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
            title: undefined
        }
    }
    handleClose=()=>{
        if (this.props.handleClose){
            this.props.handleClose();
        }
    }
    componentWillMount(){
        let {title} = this.props;

        this.setState({
            title: title
        })
    }
    componentWillUpdate(nextProps, nextState){
        
    }
    render() {
        const {value, params } = this.props;

        const format = params && params.format
			? params.format
			: helper.getFormatByKey(Constants.TYPE_FORMATS.DATE_SHOW, params) || "";

        return (
            <React.Fragment>
                {/* <span className={`${classesModule.spanClass} ${_.get(classes,"root",null)}`}>{Parser(text)}</span> */}
                {value ? moment(value).format(format):"-"}
            </React.Fragment>
        );
    }
}

export default Date;

import _ from "lodash";
import moment from "moment";
import { useMemo } from "react";
import useGetGroupCompany from "./graphql/useGetGroupCompany";
import useGetPINCAPrices from "src/views/modules/startups/renewal/pincasPrice/queries/useGetPINCAPrices";
import useSession from "src/modules/session/hooks/useSession";
import useUpdateGroupCompany from "./graphql/useUpsertGroupCompany";
import useUploadBulkDocuments from "src/modules/startup/Stakeholder/graphql/mutation/useUploadBulkDocuments";
import useUpsertRenewalData from "./graphql/useUpsertRenewalData";

const useServices = ({ initialValues }) => {
  const { groupCompanyId } = initialValues;
  const { startupId } = useSession();
  const { query } = useGetGroupCompany({});
  const mutation = useUpdateGroupCompany();
  const { mutation: uploadDocuments } = useUploadBulkDocuments();
  const submitRenewal = useUpsertRenewalData();

  const { manualQuery: getPincasPrices } = useGetPINCAPrices({
    variables: {
      filters: {
        jurisdictionTypes: ["ADGM"],
      },
    },
  });

  return useMemo(
    () => ({
      initialData: async () => {
        const groupCompany = await query({ startupId, groupCompanyId });
        return { initialValues, groupCompany: groupCompany.groupCompany };
      },

      pincasData: async () => {
        const pincasData = await getPincasPrices();
        return { pincasData };
      },

      SaveStep: async (context) => {
        let groupCompanyData = {};

        if (context?.ReviewAddressesHoldingCompanyStepForm) {
          groupCompanyData = {
            mailingAddress:
              context?.ReviewAddressesHoldingCompanyStepForm.mailingAddress,
            locationAccountingRecordsAddress:
              context?.ReviewAddressesHoldingCompanyStepForm
                .locationAccountingRecordsAddress,
          };
        }

        if (
          context?.ReviewAddressesTechLicenseAddressesStepForm &&
          context?.ReviewAddressesTechLicenseStepForm
        ) {
          const documents = [];
          documents.push({
            title: context.ReviewAddressesTechLicenseStepForm.document.name,
            file: context.ReviewAddressesTechLicenseStepForm.document,
            type: "ADGM_TSL_LEASE_AGREEMENT",
            parties: [],
            groupCompanies: [{ groupCompany: groupCompanyId }],
            status: "Uploaded",
            subCategories: ["constitutionalDocuments"],
            executedOnDate: moment(
              _.get(
                context,
                "ReviewAddressesTechLicenseStepForm.document.leaseStart"
              )
            ).format("YYYY-MM-DD"),
            expirationDate: moment(
              _.get(
                context,
                "ReviewAddressesTechLicenseStepForm.document.leaseEnd"
              )
            ).format("YYYY-MM-DD"),
          });
          const uploadedDocument = await uploadDocuments({
            variables: { startupId, documents: documents },
          });

          if (uploadedDocument?.data) {
            groupCompanyData = {
              ...context.ReviewAddressesTechLicenseAddressesStepForm,
              registeredAddress:
                context.ReviewAddressesTechLicenseStepForm.registeredAddress,
              leaseDetails: {
                leaseStart:moment(context.ReviewAddressesTechLicenseStepForm.leaseStart).format("YYYY-MM-DD"),
                leaseEnd: moment(context.ReviewAddressesTechLicenseStepForm.leaseEnd).format("YYYY-MM-DD"),
                document: uploadedDocument.data.uploadBulkDocuments[0].id,
              },
            };
          }
        }

        const variables = {
          startupId,
          groupCompanyId,
          groupCompany: groupCompanyData,
        };

        await mutation(variables);
        return await submitRenewal(groupCompanyId);
      },
    }),
    [initialValues]
  );
};

export default useServices;

import { useMemo } from 'react';
import _ from 'lodash';

const useGuards = () => {
  return useMemo(() => ({
    hasDirectors: (context) => {
      const company = context?.initialData?.selectedCompany;
      return _.some(company.stakeholders, ['role.id', 'DIRECTOR']);
    },
    doesNotHaveDirectors: (context) => {
      const company = context?.initialData?.selectedCompany;
      return !_.some(company.stakeholders, ['role.id', 'DIRECTOR']);
    },
    addNew: (context) => {
      const addedStep = context?.DirectorAdded;
      if (addedStep?.addAnother === true) {
        return true;
      }
    },
    doNotAddNew: (context) => {
      const addedStep = context?.DirectorAdded;
      if (addedStep?.addAnother === false) {
        return true;
      }
    },
    isIndividual: (context) => {
      const addNewStep = context?.AddDirector;
      if (addNewStep?.stakeholderType === 'individual') {
        return true;
      }
    },
    isCorporate: (context) => {
      const addNewStep = context?.AddDirector;
      if (addNewStep?.stakeholderType === 'corporate') {
        return true;
      }
    },
    isFormationADGM: (context) => {
      const company = context?.AddCorporate?.company;
      const groupCompanies = context?.initialData?.groupCompanies;
      return _.some(groupCompanies, (companyObj) => {
        return _.includes(company, companyObj.id) && (companyObj.jurisdictionType === 'ADGM' || companyObj.jurisdictionType === 'CAYMAN');
      });
    },
    isNotFormationADGM: (context) => {
      const company = context?.AddCorporate?.company;
      const groupCompanies = context?.initialData?.groupCompanies;
      return !_.some(groupCompanies, (companyObj) => {
        return _.includes(company, companyObj.id) && (companyObj.jurisdictionType === 'ADGM' || companyObj.jurisdictionType === 'CAYMAN');
      });
    },
  }), []);
}

export default useGuards;

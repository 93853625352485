import React, {Component} from 'react';
import {connect} from "react-redux";
import {Constants, ServerConnect} from "../../utils";
import classes from "./Stakeholder.module.scss";
import SelectForm from './SelectForm';
import {goToPage, goToPrevPage} from '../../../store/actions/page'


class EquityPosition extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: undefined,
            buttons: {
                [Constants.EQUITY_POSITIONS.SHARES]: false,
                [Constants.EQUITY_POSITIONS.CONVERTIBLES]: false,
                [Constants.EQUITY_POSITIONS.OPTIONS]: false,
                [Constants.EQUITY_POSITIONS.WARRANTS]: false,
            },
            tooltips: {
                visibility: {
                    [Constants.EQUITY_POSITIONS.SHARES]: false,
                    [Constants.EQUITY_POSITIONS.CONVERTIBLES]: false,
                    [Constants.EQUITY_POSITIONS.OPTIONS]: false,
                    [Constants.EQUITY_POSITIONS.WARRANTS]: false,
                },
                data: {}
            }
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            page: undefined
        })
    }

    componentWillMount() {
        ServerConnect.requestApi(
            `/contentful/tooltips/${Constants.CONTENTFUL.ENTRIES.FORMS.EQUITY_POSITIONS.CARD_TOOLTIPS}`,
            {
                method: 'GET', headers: { "Content-Type": "application/json" },
            }).then(res => {
                // console.log('response', res.response);
                this.setState(prevState => ({
                    ...prevState,
                    tooltips: {
                        ...prevState.tooltips,
                        data: res.response
                    }
                }))
            }).catch(err => {
                console.log('Tooltips from contentful error: ', err);
            });
    }

    handleClick(page) {
        this.props.goToPage(page)
    }

    handleClose=()=>{
        this.props.goToPrevPage();
    };


    changeButtonVisibility(key, visibility) {
        this.setState(prevState => ({
            ...prevState,
            buttons: {
                ...prevState.buttons,
                [key]: visibility
            }
        }));
    }

    changeTooltipVisibility(key) {
        this.setState(prevState => ({
            ...prevState,
            tooltips: {
                ...prevState.tooltips,
                visibility: {
                    ...prevState.tooltips.visibility,
                    [key]: !prevState.tooltips.visibility[key]
                }
            }
        }));
    }

    renderEquityPositionCard(icon, title, subtitle, key, page) {

        return (
            <div
                style={{ padding: '50px 26px' }}
                className={classes.card}
                onMouseEnter={() => this.changeButtonVisibility(key, true)}
                onMouseLeave={() => this.changeButtonVisibility(key, false)}
            >
                { /*
                    <div
                        onClick={() => this.changeTooltipVisibility(key)}
                        className={classes.EquityTooltipContainer}
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9.57776 10.2555C9.57776 9.00984 10.5876 8 11.8333 8C13.079 8 14.0888 9.00984 14.0888 10.2555C14.0888 11.5012 13.079 12.5111 11.8333 12.5111V13.205"
                                stroke="#2C74FF" stroke-linecap="round" stroke-linejoin="round"/>
                            <path
                                d="M11.816 14.7666C11.9598 14.7666 12.0763 14.8831 12.0763 15.0269C12.0763 15.1706 11.9598 15.2871 11.816 15.2871C11.6723 15.2871 11.5558 15.1706 11.5558 15.0269C11.5558 14.8831 11.6723 14.7666 11.816 14.7666"
                                stroke="#2C74FF" stroke-linecap="round" stroke-linejoin="round"/>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M12.0039 20C16.4222 20 20.0039 16.4183 20.0039 12C20.0039 7.58172 16.4222 4 12.0039 4C7.58563 4 4.00391 7.58172 4.00391 12C4.00391 16.4183 7.58563 20 12.0039 20Z"
                                  stroke="#2C74FF" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        {
                            this.state.tooltips.visibility[key] && this.state.tooltips.data &&
                            <div className={classes.EquityTooltip}>
                                {this.state.tooltips.data[this.formatKey(key)]}
                            </div>
                        }
                    </div>
                */}
                <div>{icon}</div>
                <h2>{title}</h2>
                <p>{subtitle}</p>
                {
                    this.state.buttons[key] &&
                    <button
                        onClick={() => {
                            this.handleClick(page)
                        }}
                    >
                        <p style={{ lineHeight: '0' }}>
                            Select
                        </p>
                    </button>
                }
            </div>
        );
    }

    formatKey(key) {
        const firstLetter = key.charAt(0);
        const rest = key.substring(1, key.length).toLowerCase();

        return firstLetter + rest;
    }

    render() {

        return (
        <SelectForm handleClose={this.handleClose} title={"Select type of Equity Position:"}>
            {this.renderEquityPositionCard(
                    <svg width="137" height="137" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <ellipse cx="68.5" cy="68.5" rx="68.5" ry="68.5" transform="rotate(-90 68.5 68.5)" fill="#EAF1FE" />
                        <path d="M37 46V25H68.5H84.25L100 41.9714V113H37V93.5" stroke="#2C74FF" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M81.4705 81.7044C80.2129 86.398 77.6668 90.6459 74.1203 93.9675C70.5738 97.2892 66.1685 99.552 61.4027 100.5C56.637 101.448 51.7011 101.043 47.1534 99.3315C42.6057 97.6199 38.6279 94.6697 35.6698 90.8147C32.7118 86.9598 30.8917 82.3539 30.4154 77.5181C29.9391 72.6824 30.8257 67.8099 32.9748 63.4519C35.124 59.0939 38.4498 55.4244 42.5762 52.8585C46.7026 50.2925 51.4647 48.9326 56.3238 48.9326L56.3238 74.9664L81.4705 81.7044Z" stroke="#2C74FF" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M63.9664 43.2188C67.9596 43.2188 71.8994 44.1374 75.4808 45.9035C79.0623 47.6697 82.1894 50.2361 84.6204 53.4042C87.0513 56.5722 88.7209 60.2571 89.4999 64.1736C90.279 68.0901 90.1466 72.1334 89.1131 75.9906L63.9664 69.2525L63.9664 43.2188Z" stroke="#2C74FF" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>,
                    'Shares',
                    'Equity ownership issued by a Company.',
                    Constants.EQUITY_POSITIONS.SHARES,
                    Constants.PAGES.addEquityPositionShares
                )}
                {this.renderEquityPositionCard(
                    <svg width="137" height="137" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <ellipse cx="68.5" cy="68.5" rx="68.5" ry="68.5" transform="rotate(-90 68.5 68.5)" fill="#EAF1FE" />
                        <ellipse cx="68.5" cy="68.5" rx="68.5" ry="68.5" transform="rotate(-90 68.5 68.5)" fill="#EAF1FE" />
                        <path d="M37 113H100V41.9714L84.25 25H68.5H37L37 75.5" stroke="#2C74FF" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <rect x="29" y="86" width="18" height="27" stroke="#2C74FF" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <rect x="47" y="77" width="19" height="36" stroke="#2C74FF" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <rect x="66" y="53" width="18" height="60" stroke="#2C74FF" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>,
                    'Convertibles',
                    'Instruments under which an Investor invests in, or loans money to, a Company which converts into shares in the Company at a future date.',
                    Constants.EQUITY_POSITIONS.CONVERTIBLES,
                    Constants.PAGES.addEquityPositionConvertibles
                )}
                {this.renderEquityPositionCard(
                    <svg width="144" height="137" viewBox="0 0 144 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <ellipse cx="68.5" cy="68.5" rx="68.5" ry="68.5" transform="rotate(-90 68.5 68.5)" fill="#EAF1FE" />
                        <path d="M100 68.3714V113H37V83.4571C37 67.7469 37 25 37 25H68.5H84.25L100 41.9714V54.5429" stroke="#2C74FF" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M122.633 43.4623C123.372 43.2454 124.068 43.9094 123.887 44.6575L120.435 58.8841C120.254 59.6322 119.331 59.9033 118.774 59.3721L108.179 49.27C107.621 48.7388 107.848 47.8036 108.587 47.5868L122.633 43.4623Z" fill="#2C74FF" />
                        <path d="M20 95.5138L52.6857 71.6281L78.4571 87.971L117.561 50.2432" stroke="#2C74FF" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>,
                    'Options',
                    'Rights granted by a Company to purchase Shares in the Company in the future at a pre-agreed price.',
                    Constants.EQUITY_POSITIONS.OPTIONS,
                    Constants.PAGES.addEquityPositionOptions
                )}
                {this.renderEquityPositionCard(
                    <svg width="138" height="138" viewBox="0 0 138 138" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <ellipse cx="69.1017" cy="68.5507" rx="68.5509" ry="68.5509" transform="rotate(-90 69.1017 68.5507)" fill="#EAF1FE" />
                        <ellipse cx="69.5" cy="68.5" rx="68.5" ry="68.5" transform="rotate(-90 69.5 68.5)" fill="#EAF1FE" />
                        <path d="M38 35.5V25H69.5H85.25L101 41.9714V113H38V93.5" stroke="#2C74FF" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M50 66V82L43.5 78.7277L37 82V66" stroke="#2C74FF" stroke-width="4.01521" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M39.2951 36.7608C41.3322 34.693 44.6678 34.693 46.7049 36.7608V36.7608C47.6919 37.7627 49.0425 38.3221 50.4488 38.3116V38.3116C53.3515 38.2899 55.7101 40.6485 55.6884 43.5512V43.5512C55.6779 44.9575 56.2373 46.3081 57.2392 47.2951V47.2951C59.307 49.3322 59.307 52.6678 57.2392 54.7049V54.7049C56.2373 55.6919 55.6779 57.0425 55.6884 58.4488V58.4488C55.7101 61.3515 53.3515 63.7101 50.4488 63.6884V63.6884C49.0425 63.6779 47.6919 64.2373 46.7049 65.2392V65.2392C44.6678 67.307 41.3322 67.307 39.2951 65.2392V65.2392C38.3081 64.2373 36.9575 63.6779 35.5512 63.6884V63.6884C32.6485 63.7101 30.2899 61.3515 30.3116 58.4488V58.4488C30.3221 57.0425 29.7627 55.6919 28.7608 54.7049V54.7049C26.693 52.6678 26.693 49.3322 28.7608 47.2951V47.2951C29.7627 46.3081 30.3221 44.9575 30.3116 43.5512V43.5512C30.2899 40.6485 32.6485 38.2899 35.5512 38.3116V38.3116C36.9575 38.3221 38.3081 37.7627 39.2951 36.7608V36.7608Z" stroke="#2C74FF" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>,
                    'Warrants',
                    'Rights for an Investor to purchase a specific number of Shares in a Company within a specified period and at a pre-agreed price.',
                    Constants.EQUITY_POSITIONS.WARRANTS
                    
                )}
        </SelectForm>

        )



    }

}
const mapStateToProps = state => ({
    
});
const mapDispatchToProps = (dispatch) => ({
    
    goToPage: (page,params) => {
        goToPage(dispatch, page,params)
    },
    goToPrevPage: (reload,defaultPage,params) => {
        goToPrevPage(dispatch, reload,defaultPage,params)
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(EquityPosition)


import { gql } from '@apollo/client';
import useSession from "src/modules/session/hooks/useSession";
import useMutation from "src/hooks/custom/useMutation";

export const UPSERT_FORMATION_COMPANY = `
mutation upsertFormationCompany(
    $startupId: ID!
    $groupCompanyId: ID
    $groupCompanyData: FormationCompanyInputType
  ) {
    upsertFormationCompany(
      startupId: $startupId
      groupCompanyId: $groupCompanyId
      groupCompanyData: $groupCompanyData
    ) {
      id
      name
    }
  }`;

const useUpdateGroupCompany = (config = {}, props = {}) => {

  const { startupId } = useSession();
  const [mutation, loading] = useMutation(
    UPSERT_FORMATION_COMPANY, { showSuccessNotification: false, ...config }, { ...props
  }
  )

  return [mutation, loading]
};

export default useUpdateGroupCompany;

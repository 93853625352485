import useCustomMachine, {
  GlobalStateContext,
} from "../../../../../../generic/hooks/useCustomMachine";
import React, { FC, useContext, useMemo, useRef } from "react";
import _ from "lodash";
import Button from "../../../../../../../modules/generic/components/Buttons/Button";
import { StepProps } from "../../../../../../../modules/generic/components/Wizard/Step";
import TemplateWizard from "../../../../../../../modules/generic/templates/Modal/TemplateWizard";
import useTranslate from "../../../../../../generic/hooks/useTranslate";
import IsIndividualOrCompany from "../../../forms/IsIndividualOrCompany";
import useIsAn from '../../../hooks/useIsAn';
interface IndividualOrCompanyStepProps extends StepProps {
  role: string;
  isTherePrevious?: boolean;
}
const IndividualOrCompanyStep: FC<IndividualOrCompanyStepProps> = ({
  role,
  isTherePrevious = false,
}: IndividualOrCompanyStepProps) => {

  const { translate } = useTranslate();
  const { prev, next, send, stepData } = useCustomMachine();

  const handleCancel = async (values) => {
    send("CANCEL");
  };

  const handlePrevious = () => {
    send("PREVIOUS");
  };
  
  const handleNext = (values) => {
    next(values);
  };

  const isAn = useIsAn(role);
  return (
    <>
      <IsIndividualOrCompany
        onCompleteSubmit={handleNext}
        Template={TemplateWizard}
        propsTemplate={{
          title: translate(
            "MODULES.ONBOARDING_FORMS.INDIVIDUAL_OR_COMPANY.INDIVIDUAL_OR_COMPANY"
          ),
          subTitle: translate("MODULES.ONBOARDING_STEPS.ADD_ROLE", {
            role: translate(`MODULES.ONBOARDING_STEPS.ROLES_LABEL.${role}`),
            isAn
          }),
        }}
        initialValues={stepData}
        buttons={{
          cancel: <Button onClick={handleCancel}>Cancel</Button>,
          previous: isTherePrevious ? (
            <Button onClick={handlePrevious}>Previous</Button>
          ) : null,
        }}
      />
    </>
  );
};

export default IndividualOrCompanyStep;

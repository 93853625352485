import React from 'react'
import * as Yup from 'yup'
import Form from '../../../../components/forms/Form/Form'
import SubmitButton from '../../../generic/components/Buttons/SubmitButton'
import CheckboxButtonSimple from '../../../generic/components/Inputs/CheckboxButtonSimple'
import ControllerInput from '../../../generic/components/Inputs/ReactHookForm/ControllerInput'
import FormProps from '../../../generic/forms/interfaces/FormProps'
import useTranslate from '../../../generic/hooks/useTranslate'
import TemplateFormDefault from "../../../generic/templates/Modal"
import useSession from '../../../session/hooks/useSession'
import useGetGroupCompanies from '../../graphql/groupCompany/query/useGetGroupCompanies'
import cls from './Form.module.scss'

type OnChange = (values: any) => void | Promise<void>
interface FormSelectShareOptionsProps extends FormProps {
  onChange?: OnChange
}
const schema = Yup.object().shape({
  mode: Yup.string().required('This field is required').nullable()
})

const FormSelectCompany: React.FC<FormSelectShareOptionsProps> = ({
  initialValues: defaultValues = { company: [] },
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  onChange,
  buttonSubmitProps,
  propsTemplate }) => {

  const { translate } = useTranslate();
  const { startupId } = useSession();
  const { data } = useGetGroupCompanies({ variables: { startupId } })

  const handleSubmit = async values => {
    onCompleteSubmit && await onCompleteSubmit(values);
  }

  const options = React.useMemo(() => {
    return [{ label: "No, they don't", value: 'CANCEL' },
    { label: "Yes, they do", value: 'YES_CREATED' },
    { label: "No, let's give them share options now", value: 'GENERATE_GRANT_AGREEMENT' }]
  }, [])


  return (
    <Form
      defaultValues={defaultValues}
      schema={schema}
      onSubmit={handleSubmit}
      onlySendDirty={false}
      optionsForm={{ mode: "onChange" }}
    >
      <Template buttons={{ ...buttons, submit: <SubmitButton  {...buttonSubmitProps}>Next</SubmitButton> }} props={propsTemplate}>
        <ControllerInput
          render={CheckboxButtonSimple}
          options={options}
          name='mode'
          onChange={onChange}
          defaultlabel={''}
          size={"md"}
          className={cls.CheckboxButton}
          classNameButton={cls.buttonCheckBox}
        />
      </Template>
    </Form>
  )
}

export default FormSelectCompany

import React, {useEffect, useState} from 'react';
import {ClaraSearch} from '../../../../index';
import classes from './IncorporationPortal.module.scss';
import _ from 'lodash'


const MenuListAgent = ({ list, onClickAgent, closePopover }) => {

    const [searchValue, setSearchValue] = useState(null);

    const [listAgent, setListAgent] = useState(list);

    useEffect(() => {
        const handleSearch = (data, filter) => {

            if (searchValue && searchValue !== "") {
                let dataFilter = _.filter(data, (element) => {
                    return element.fullName.toString().toLowerCase().indexOf(filter.toLowerCase()) >= 0;
                })

                return dataFilter;
            } else {
                return data
            }
        }
        setListAgent(handleSearch(list, searchValue))

    }, [searchValue]);

    const handleOnClick = (agent) => {
        onClickAgent(agent);
        closePopover()

    }
    return (
        <div className={classes.containerMenuList}>
            <ClaraSearch onChange={setSearchValue} value={searchValue} placeHolder={"Search"} autofocus={true} />
            {
                listAgent.map(agent => {
                    return <p onClick={() => handleOnClick(agent)}>{agent.fullName}</p>
                })
            }



        </div>
    )
}


export{
    MenuListAgent
}
import { gql } from '@apollo/client';
import { GET_GROUP_COMPANIES } from 'src/modules/startup/onBoarding/core/forms/IndividualOrCompanyStakeholderForm/graphql/useGetGroupCompanies';
import useMutation from "src/hooks/custom/useMutation";
import useSession from 'src/modules/session/hooks/useSession';

export const UPSERT_FORMATION_COMPANY = `
mutation upsertFormationCompany($startupId: ID!, $groupCompanyId: ID, $groupCompanyData: FormationCompanyInputType) {
  upsertFormationCompany(startupId: $startupId, groupCompanyId: $groupCompanyId, groupCompanyData: $groupCompanyData) {
    id
  }
}`;

const useUpsertFormationCompanyShareCapital = (config, props) => {
  const { startupId } = useSession();
  const [upsertFormationCompany] = useMutation(UPSERT_FORMATION_COMPANY, config, { refetchQueries: [{ query: gql`${GET_GROUP_COMPANIES}`, variables: { startupId: startupId } }], ...props });
  return { upsertFormationCompany };
};
export default useUpsertFormationCompanyShareCapital;
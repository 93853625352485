import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import Typography from "src/modules/generic/components/Texts/Typography";
import classes from "./classes.module.scss";
import _ from "lodash";

function FormSPVInADGM() {
  const { next, context, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.FormSPVInADGM ?? {}
  }, [context]);

  const handleCancel = () => {
    cancel();
  }

  const handlePrev = () => {
    prev();
  }

  const handleSubmit = async (values) => {
    next(values);
  }

  const options = [
    {
      value: true,
      component: ButtonMultipleList,
      componentProps: {
        title: "I agree",
        text1: "The ADGM SPV being formed will not:",
        list1: [
          'Be used for active commercial purposes', 
          'Have employees and issue visas', 
          'Have a physical office space',
        ],
      }
    },
    {
      value: false,
      component: ButtonText,
      componentProps: {
        title: "I'm forming a company that will be unable to meet these rules"
      }
    },
  ];

  useEffect(() => {
    dispatch(addAssistantText('In ADGM, companies that act as your holding company or topco (like the one you\'ll form with Clara) usually take the form of a special purpose vehicle (SPV for short) and must follow specific rules.<br/><br/>SPVs are passive holding companies that only receive revenue from dividends and/or capital gains following the sale of an asset. They cannot have employees, issue visas or have a physical office space. If your company will need to employ people, issue visas or maintain a physical office space, a Tech Licence company might be a better fit for you.<br/><br/>Select "I agree" once you confirm that these parameters meet your requirements.'));
    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch]);

  const schema = Yup.object().shape({
    agreeSPVRules: Yup.boolean().nullable().required("Required"),
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: 'You can form a SPV in ADGM!',
          skeleton: null,
          subTitle: 'ADGM SPV eligibility',
          variant: 'simple'
        }}
        buttons={{
          previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('BUTTON_NEXT')}</SubmitButton>,
        }}
      >
        <Typography component='h4'>
          Before proceeding, you’ll need to agree to a few ground rules.
        </Typography>
        <div>
          <ControllerInput
            as={CheckboxButtonSimple}
            className={classes.FormSPVInADGMCheckboxContainer}
            classNameButton={classes.FormSPVInADGMCheckboxButton}
            name="agreeSPVRules"
            variant="col_1"
            options={options}
          />
        </div>
      </TemplateWizard>
    </Form>
  );
}

export default FormSPVInADGM;

const ButtonText = ({ title, text }) => {
  return (
    <div className={classes.FormSPVInADGMCheckboxButtonText}>
      <Typography weight="semibold" variant="h4" className="mb-2">
        {title}
      </Typography>
      <Typography variant="body">{text}</Typography>
    </div>
  );
};

const ButtonMultipleList = ({ title, text1, list1, text2, list2 }) => {
  return (
    <div className={classes.FormSPVInADGMCheckboxButtonText}>
      <Typography weight="semibold" variant="h4" className="mb-2">
        {title}
      </Typography>
      <div className={classes.FormSPVInADGMCheckboxButtonMultipleListContainer}>
        <div className={classes.FormSPVInADGMCheckboxButtonMultipleListItem}>
          <Typography className={classes.FormSPVInADGMCheckboxButtonMultipleListTitle} weight="semibold" variant="body">{text1}</Typography>
          <Typography variant="body" className={classes.FormSPVInADGMCheckboxCheckboxButtonList}>
            {_.map(list1, option => {
              return (
                <div className={classes.FormSPVInADGMCheckboxCheckboxButtonListOption}>
                  <div>•</div>
                  <span>{option}</span>
                </div>
              );
            })}
          </Typography>
        </div>
        <div className={classes.FormSPVInADGMCheckboxButtonMultipleListItem}>
          <Typography weight="semibold" variant="body">{text2}</Typography>
          <Typography variant="body" className={classes.FormSPVInADGMCheckboxCheckboxButtonList}>
            {_.map(list2, option => {
              return (
                <div className={classes.FormSPVInADGMCheckboxCheckboxButtonListOption}>
                  <div>•</div>
                  <span>{option}</span>
                </div>
              );
            })}
          </Typography>
        </div>
      </div>
    </div>
  );
};

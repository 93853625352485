export default {
    WIZARD_ADD_TITLE: "Does your startup have shareholders?",
    WIZARD_ADD_SUBTITLE: "Your shareholders",
    WIZARD_CONFIRM_ADD: "Yes, add a shareholder",
    WIZARD_ADD_INDIVIDUAL_OR_COMPANY_SUBTITLE: "Add a shareholder",
    WIZARD_ADDED_SUBTITLE: "Shareholder added!",
    FIRST_STEP_TOOLTIP: 'A shareholder is an individual or company (a corporate shareholder) holding shares in a company.',
    SECOND_STEP_TOOLTIP: 'A shareholder is an individual or company (a corporate shareholder) holding shares in a company.',
    THIRD_STEP_TOOLTIP: 'A shareholder is an individual or company (a corporate shareholder) holding shares in a company.',
    FORTH_STEP_TOOLTIP: 'A shareholder is an individual or company (a corporate shareholder) holding shares in a company.',
    WIZARD_FORMATION_TITLE_CORPORATE: 'Add a corporate stakeholder?',
    WIZARD_FORMATION_SUBTITLE_CORPORATE: 'Add a corporate shareholder',
    WIZARD_FORMATION_TITLE_INDIVIDUAL: 'Add another shareholder?',
    WIZARD_FORMATION_SUBTITLE_INDIVIDUAL: 'Add a shareholder',
}

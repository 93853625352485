import React, { FC, useState, useEffect } from 'react';
import Wizard from '../../../generic/components/Wizard';
import ModalAssistant, { ModalAssistantProps } from '../../../generic/components/Modal/ModalAssistant';
import MachineCompanyDetail from './machine';
import useModal from '../../../generic/hooks/useModal';

interface ModalDetailCompanyProps extends ModalAssistantProps {
  paramsMutation: object,
  onClose(): void;
}

const ModalDetailCompany: FC<ModalDetailCompanyProps> = ({ children, open: openParam = false, paramsMutation, ...props }: ModalDetailCompanyProps) => {

  const [open, setOpen] = useState(openParam);
  const { closeModal: onClose } = useModal();

  useEffect(() => {
    setOpen(openParam);
  }, [openParam])

  const handleClose = (values) => {
    onClose()
    setOpen(false);
  }

  return (
    <ModalAssistant open={open}>
      <Wizard machine={MachineCompanyDetail} initialValues={paramsMutation} onComplete={handleClose} />
    </ModalAssistant>
  )
}

export default ModalDetailCompany;





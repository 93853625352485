import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import _ from "lodash";
import moment from "moment";
import {
  addAssistantText,
  clearAssistatText,
} from "src/modules/generic/store/action";
import AddressInput from "src/components/forms/modules/startups/individualStakeholder/AddressInput";
import Button from "src/modules/generic/components/Buttons/Button";
import CalendarInputDate from "src/components/inputs/Calendars/CalendarInputDate";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import FileUploaderInput from "src/components/inputs/FileUploader/FileUploaderInput";
import Form from "src/components/forms/Form/Form";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import Typography from "src/modules/generic/components/Texts/Typography";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";

export default function ReviewAddressesLicenseStepForm() {
  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  // Check if lease end date is 30 days later than today
  const isLeaseEndDateValid = (endDate) => {
    const today = moment();
    return moment(endDate).isAfter(today.add(30, "days"));
  };

  const initialValues = useMemo(() => {
    let formattedValues = _.cloneDeep(context?.initialData?.groupCompany);

    if (formattedValues?.registeredAddress?.country?.code) {
      formattedValues.registeredAddress.country =
        formattedValues?.registeredAddress?.country?.code;
    }

    formattedValues.leaseStart = formattedValues.leaseDetails.leaseStart;
    formattedValues.leaseEnd = formattedValues.leaseDetails.leaseEnd;
    formattedValues.document = formattedValues.leaseDetails.document;

    if (!isLeaseEndDateValid(formattedValues.leaseEnd)) {
      formattedValues.leaseEnd = null;
      formattedValues.leaseStart = null;
      formattedValues.document = null;
    }

    return formattedValues;
  }, [context]);

  const handleCancel = () => cancel();

  const handleSubmit = async (values) => next(values);

  useEffect(() => {
    // TODO ASK THE URL FOR THE LINK
    dispatch(
      addAssistantText(
        translate("MODULES.RENEWALS_ADGM.REVIEW_ADDRESS.TL_TOOLTIP_1")
      )
    );
    dispatch(
      addAssistantText(
        translate("MODULES.RENEWALS_ADGM.REVIEW_ADDRESS.TL_TOOLTIP_2")
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const schema = Yup.object().shape({
    registeredAddress: Yup.object().shape({
      country: Yup.string().required("This field is required").nullable(),
      street: Yup.string().required("This field is required").nullable(),
      city: Yup.string().required("This field is required").nullable(),
      state: Yup.string().nullable(),
      zipCode: Yup.string().nullable(),
    }),
    leaseStart: Yup.date()
      .required("This field is required")
      .typeError("Please enter a valid date")
      .nullable(),
    leaseEnd: Yup.date()
      .required("This field is required")
      .min(Yup.ref("leaseStart"), "End date must be after start date")
      .test(
        "is-after-30-days",
        "End date must be at least 30 days after today",
        function (value) {
          const todayPlus30Days = moment().add(30, "days");
          return moment(value).isAfter(todayPlus30Days);
        }
      )
      .typeError("Please enter a valid date")
      .nullable(),
    document: Yup.object().nullable().required("This field is required"),
  });

  const text = `Please confirm the lease details below are up to date. If you have a new lease or your current lease expires soon, you must provide the latest details and upload a copy of the new lease.`;

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: "Evidence of occupancy in ADGM",
          subTitle: "Confirm company addresses",
        }}
        buttons={{
          submit: (
            <SubmitButton>{translate("MODULES.CLARA.BTN_NEXT")}</SubmitButton>
          ),
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("MODULES.CLARA.BTN_CANCEL")}
            </Button>
          ),
        }}
      >
        <Typography component="p" weight="regular" variant="bodyModal">
          {text}
        </Typography>
        <AddressInput
          name="registeredAddress"
          label={{
            country: translate("MODULES.CLARA.ADDRESS_REGISTERED_LABEL"),
          }}
          banner={true}
          bannerTitle={translate("MODULES.RENEWALS_ADGM.COMMON.BANNER_1", {
            currency: "USD",
            price:
              context?.pincasData?.pincasData?.CHANGE_COMPANY_REGISTERED_ADDRESS
                ?.value ?? 0,
            button: "Confirm",
          })}
          zipCodeOptional
          stateOptional
          isRequired
        />
        <div />
        <TemplateLabel label="Lease start date" isRequired={true}>
          <ControllerInput
            name="leaseStart"
            placeholder="dd/mm/yyyy"
            render={CalendarInputDate}
          />
        </TemplateLabel>
        <TemplateLabel label="Lease end date" isRequired={true}>
          <ControllerInput
            name="leaseEnd"
            placeholder="dd/mm/yyyy"
            render={CalendarInputDate}
          />
        </TemplateLabel>
        <div />
        <TemplateLabel
          isRequired
          multilineTexts={["Upload signed lease agreement copy"]}
        >
          <ControllerInput
            render={FileUploaderInput}
            name="document"
            typeFileAllowed={["application/pdf", "image/jpeg", "image/png"]}
            icons={["JPG", "PNG", "PDF"]}
          />
        </TemplateLabel>
        <div>&nbsp;</div>
      </TemplateWizard>
    </Form>
  );
}

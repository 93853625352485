import React from "react";
import * as Yup from "yup";
import Form from "../../../../../../components/forms/Form/Form";
import ControllerInput from "../../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import SubmitButton from "../../../../../generic/components/Buttons/SubmitButton";
import FormProps from "../../../../../generic/forms/interfaces/FormProps";
import TemplateFormDefault from "../../../../../generic/templates/Modal";
import cls from "./classes.module.scss";
import FileUpload from "../../../../../generic/components/Inputs/FileUpload";
import LeftContentBlock from "../../components/LeftContentBlock";
import BlackID from "../../public/Back_of_ID_card.svg";
import EmiratesID from "../../public/Emirates_ID.svg";
import RightContentBlock from "../../components/RightContentBlock";

const schema = Yup.object().shape({
  files: Yup.array().min(1),
});

const FormEmiratesID: React.FC<FormProps> = ({
  initialValues = {},
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
}: FormProps) => {
  
  const handleSubmit = async (values) => {
    onCompleteSubmit && (await onCompleteSubmit(values));
  };

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <Template
        buttons={{
          ...buttons,
          submit: <SubmitButton {...buttonSubmitProps}>Next</SubmitButton>,
        }}
        props={propsTemplate}
      >
        <div className={cls.ContainerForm}>
          <LeftContentBlock title={"Example"}>
            <>
              <div className={cls.containerImages}>
                <img src={EmiratesID} style={{ height: "12.6154rem" }} alt={""}/>
                &nbsp;
                <img src={BlackID} style={{ height: "12.6154rem" }} alt={""} />
              </div>
            </>
          </LeftContentBlock>
          <RightContentBlock title={"Upload"}>
            <ControllerInput
              render={FileUpload}
              name="files"
              defaultValue={[]}
            />
          </RightContentBlock>
        </div>
      </Template>
    </Form>
  );
};

export default FormEmiratesID;

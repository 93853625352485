import {useMemo} from 'react';
import useSession from 'src/modules/session/hooks/useSession';
import useUserAgreesToBeContacted from 'src/modules/startup/graphql/groupCompany/mutations/useUserAgreesToBeContacted';
import _ from 'lodash';
import useUpsertCompanyFormation from 'src/hooks/services/modules/groupCompany/useUpsertCompanyFormation';
import {Constants} from 'src/v1/utils/constants';

const useServices = () => {

  const { startup, startupId } = useSession();
  const { mutation } = useUserAgreesToBeContacted({ showSuccessNotification: true, successMessage: "NOTIFICATION_SUCCESS_SEND_MAIL"}, {});
  const mutationAddCompany = useUpsertCompanyFormation();

  return useMemo(() => ({

    SaveStep: async (context, event) => {
      const variables = {
        startupId,
        groupCompanyData: {
          country: _.get(context, "AddDelawareCompany.country", ""),
          isHoldingCompany: true,
          jurisdiction: _.get(context, "AddDelawareCompany.jurisdiction", ""),
          jurisdictionType: "DELAWARE",
          companyType: Constants.MODULES.FORMATION.COMPANIES_TYPE.DELAWARE_C_CORP,
          name:`${_.get(context, "AddDelawareCompany.name", "")} Inc.`,
        }
      }
      return await mutationAddCompany(variables);
    },

    SendMailNow: async (context, event) => {
      const variables = {
        startupId: startup.id,
        jurisdictionType: "DELAWARE",
      }
      mutation({variables});
      return null;
    },

  }), []);
}

export default useServices;

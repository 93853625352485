const DOCUMENT=`
fragment DocumentAdd on Document{
 id
    name
    kind
    status
    type {
      label
      code
    }
    groupCompanies {
      groupCompany {
        id
        name
      }
      signedBy{
      id
      }
    }
    stakeholders {
      id
      fullName
    }
    subCategories
    file {
      name
      size
      type
      id
    }
}`
const GroupCompanyPartyRequiredFields=`
fragment GroupCompanyPartyRequiredFields on GroupCompanyParty{
groupCompany{
id
}
signedBy{
id
}
}
`
export {
    DOCUMENT,
    GroupCompanyPartyRequiredFields
}
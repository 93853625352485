import React from 'react';
import useTranslate from "../../../generic/hooks/useTranslate";
import Form from "../../../../components/forms/Form/Form";
import * as Yup from "yup";
import Botonera from "../../../../components/forms/contents/Botonera/Botonera";
import SubmitButton from "../../../../components/generic/Buttons/SubmitButton/SubmitButton";
import classes from "./classes.module.scss";
import FormSelectJurisdictionFields from "./fields";

const FormSelectJurisdiction = ({onCompleteSubmit}) => {

  const { translate } = useTranslate();

  const selectJurisdictionSchema = Yup.object().shape({
    country: Yup.string().required(
      translate("MODULES.FORMATION.FORM_JURISDICTION_VALIDATION")
    ).typeError(translate("MODULES.FORMATION.FORM_JURISDICTION_VALIDATION")),
    jurisdiction: Yup.string().required(
      translate("MODULES.FORMATION.FORM_JURISDICTION_VALIDATION")
    ).typeError(translate("MODULES.FORMATION.FORM_JURISDICTION_VALIDATION")),
  });

  const handleOnSubmit = (values) => {
    onCompleteSubmit(values);
  };

  return (
    <div>
      <Form
        initialValues={{}}
        schema={selectJurisdictionSchema}
        onSubmit={handleOnSubmit}
      >
        <FormSelectJurisdictionFields />
        <Botonera
          className={classes.buttonsContainer}
        >
          <SubmitButton>
            {translate("MODULES.FORMATION.FORM_SELECT_JURISDICTION_BTN_NEXT")}
          </SubmitButton>
        </Botonera>
      </Form>
    </div>
  );
};

export default FormSelectJurisdiction;

import React, { useEffect } from 'react';
import useQuery from "../../custom/useQuery"

const query = `query countryList($code: String, $name: String, $language: String!,  $orderBy: OrderFlagsType) {
  countryList(code: $code, name: $name, language: $language, orderBy: $orderBy) {
  code
  jurisdictions{
    name
    }
  }
}`;

const useCountryList = (variables) => {


    const { loading, error, data, refetch } = useQuery(query, {...variables, "orderBy": "FORMATION"}, null, { fetchPolicy: "no-cache" });


    return { loading, error, data, refetch };
}
export default useCountryList;
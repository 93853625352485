import { assign } from 'xstate';
import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import machine from './machine';
import Wizard from '../../../generic/components/Wizard/v2';
import StepYourSubscription from './Steps/YourSubscription';
import StepConfirmRenewalAndBilling from './Steps/ConfirmRenewalAndBilling';
import useTranslate from "../../../../modules/generic/hooks/useTranslate";
import { useSelector } from 'react-redux';
import { Constants } from '../../../../v1/utils/constants';
import { useGetSubscriptionPricesLazy } from "../../../../hooks/services/modules/subscription/useGetSubscriptionPrices";
import moment from "moment";
import ModalAssistant from '../../../generic/components/Modal/ModalAssistant'
import useModal from "../../../generic/hooks/useModal";
import { clearAssistatText } from "../../../generic/store/action";
import TemplateWizardSkeletonMachine from '../../../../modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import useSetSubscriptionsOptions from "../../../../modules/checkout/hooks/useSetSubscriptionsOptions";
import useSession from "../../../../modules/session/hooks/useSession";

/**
 * Wizard to Renewal and Billing
 * addded with the new state machine 
 * @returns
 */
function ModalRenewalAndBilling({ initialValues, open: openParam = false }) {
  const [open, setOpen] = useState(openParam);
  const { translate } = useTranslate();
  const session = useSelector(state => _.get(state, 'session'));
  const activeSubscription = _.get(session, "startup.activeSubscriptionPlan.activeSubscriptionData", {});
  const { closeModal: onClose } = useModal();
  const { manualQuery } = useGetSubscriptionPricesLazy();
  const [mutation] = useSetSubscriptionsOptions({}, {});
  const { startupId,startup } = useSession();

  const steps = useMemo(() => ({
    getSubscriptionData: {
      render: TemplateWizardSkeletonMachine,
      renderProps:{
        title:  translate('MODULES.STAKEHOLDER.EDIT_SOURCE_OF_FUNDS.TITLE'),
        subTitle: translate('MODULES.STAKEHOLDER.EDIT_SOURCE_OF_FUNDS.SUBTITLE'),
      },
      stepperPosition: 0
    },
    initialStatus: {
      render: StepYourSubscription,
      stepperPosition: 0
    },
    confirm_renewal_and_billing: {
      render: StepConfirmRenewalAndBilling,
      stepperPosition: 0
    },
    saveSubscriptionData: {
      render: TemplateWizardSkeletonMachine,
      renderProps:{
        title:  translate('MODULES.STAKEHOLDER.EDIT_SOURCE_OF_FUNDS.TITLE'),
        subTitle: translate('MODULES.STAKEHOLDER.EDIT_SOURCE_OF_FUNDS.SUBTITLE'),
      },
      stepperPosition: 0
    },
  }), [translate]);

  const subscriptionModified = (context) => context?.values?.renewal !== _.get(context, 'getSubscriptionData.renewal.status');
  
  const subscriptionNotModified = (context) =>  context?.values?.renewal === _.get(context, 'getSubscriptionData.renewal.status');
  
  const guards = {
    subscriptionModified,
    subscriptionNotModified,
  };

  const services = {
    getSubscriptionData: async () => {
      const productsData = await manualQuery();  
      const monthlyScaleSubscription = _.find(productsData.data.getSubscriptionPrices, { code: 'SCALE_INSTALLMENT_SUBSCRIPTION' })
      const anualyScaleSubscription = _.find(productsData.data.getSubscriptionPrices, { code: 'SCALE_SUBSCRIPTION' })      
      const billingStatusData = () => {
        let result;

        let index = _.findIndex(startup.subscriptions, (subscription) => {return _.get(subscription, 'status') === Constants.STATUS_PLANS.PENDING})
        const nextPendingSubscription = _.get(startup, `subscriptions[${index}]`)

        if (_.get(nextPendingSubscription, "productCode", initialValues.getProduct.code ) === Constants.SUBSCRIPTIONS_TYPES.MONTHLY){
          result = false;
        } else {
          result = true;
        }

        return result;
      }
      const result = {
        renewal: {
          status: _.get(activeSubscription, 'cancelAutoRenewal', "") === false ? true : false,
          date: moment(activeSubscription.renewalDate).format("DD MMM YYYY"),
        },
        billing: {
          status: billingStatusData(),
          monthlyMoney: _.get(monthlyScaleSubscription, 'currency', ""),
          monthlyAmount: _.get(monthlyScaleSubscription, 'price', ""),
          monthlyVat: translate("MODULES.CHECKOUT.USE_VAT"),
          annualyMoney: _.get(anualyScaleSubscription, 'currency', ""),
          annualyAmount: _.get(anualyScaleSubscription, 'price', ""),
          annualyVat: translate("MODULES.CHECKOUT.USE_VAT"),
          annualySave: translate("MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_1.ANUAL_SAVE", {
            percent: _.get(anualyScaleSubscription, 'savePercentage', ""),
          }),
        }
      }
      return result;
    },
    saveSubscriptionData: async (context) => {
      const renewalState = _.get(context, "values.renewal");
      const billingStatus = _.get(context, 'getSubscriptionData.billing.status');
      
      let variables;
      if (renewalState) {
        let billingSave;
        if (billingStatus) {
          billingSave = Constants.SUBSCRIPTIONS_TYPES.ANNUAL;
        } else {
          billingSave = Constants.SUBSCRIPTIONS_TYPES.MONTHLY;
        }
        variables = {
          startupId,
          autoRenewal: true,
          billingCycle: billingSave,
        }
      } else {
        variables = {
          startupId,
          autoRenewal: false,
        }
      }

      await mutation(variables);
    }
  };

  const actions = {
    setContextAfterSelect: assign((context, event) => {
      const values = _.get(event, 'payload');
      const solution = { ...context, ...values };
      return solution;
    }),
  };

  const handleStopMachine = () => {
    onClose();
    setOpen(false);
    clearAssistatText();
  }

  return (
    <ModalAssistant open={open}>
      <Wizard
        guards={guards}
        machine={machine}
        steps={steps}
        services={services}
        actions={actions}
        onStopMachine={handleStopMachine}
      />
    </ModalAssistant>
  );
}

export default ModalRenewalAndBilling;

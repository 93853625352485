import { FieldPolicy } from '@apollo/client';
import _ from 'lodash';

const GetMatterNotifications: FieldPolicy = {
    read(existing, {
        args: {
            offset = 0,
            limit = existing.length,
        } = {},
    }) {
        return existing && existing.slice(offset, offset + limit);
    },
    merge(existing = [], incoming) {
        const valuesMerge = _.uniqBy([...existing, ...incoming], '__ref')
        return valuesMerge;
    }
};

const GetLastNotificationByMatter: FieldPolicy = {
    read(existing, {
        args: {
            offset = 0,
            limit = existing.length,
        } = {},
    }) {
        return existing && existing.slice(offset, offset + limit);
    },
    merge(existing = [], incoming) {
        const valuesMerge = _.uniqBy([...existing, ...incoming], '__ref')
        return valuesMerge;
    }
};


const GetMattersList: FieldPolicy = {
    read(existing, {
        args: {
            offset = 0,
            limit = existing.length,
        } = {},
    }) {
        return existing && existing.slice(offset, offset + limit);
    },
    merge(existing = [], incoming) {
        const valuesMerge = _.uniqBy([...existing, ...incoming], '__ref')
        return valuesMerge;
    }
};
 
const getStartupsByUserProfile: FieldPolicy = {
    read(existing,{variables}) {
        return existing && existing.slice(0, variables.pagination.skip + variables.pagination.length);
    },
    merge(existing = [], incoming) {
        return _.uniqBy([...existing, ...incoming], '__ref')
    }
};

const fields = {
    GetLastNotificationByMatter,
    GetMatterNotifications,
    GetMattersList,
    getStartupsByUserProfile
}

export default fields;

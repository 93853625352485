export default {
  FIRST_STEP: {
    TITLE: 'Have you added all of your directors and shareholders? ',
    SUBTITLE: 'Share capital',
    TOOLTIP: 'If you require your directors to be shareholders, make sure that you have added them as shareholders.'
  },
  SECOND_STEP: {
    TITLE: 'Share issue amount',
    SUBTITLE: 'Share capital',
  },
  EDIT_SECOND_STEP: {
    TITLE: 'Share issue amount',
    SUBTITLE: 'Share capital',
  }
}
import React, {Component} from "react";
import {connect} from "react-redux";
import {DeleteForm, Loading} from "../../index";

class ShareDeleteForm extends Component {
    state = {
        data:{},
        showLoading: false,
        options:[],
    }
    /* Particular data */
    getTitle(){
        const data =this.state.data ;
        return this.props.title;
    }
    setOptions(data){
        let options=[];
        options.push({label:`${this.props.label}?`});
        this.setState({
            options
        })
    }
    hasAllData(){
        return true;
    }
    /*  Call Server*/
    callServerToGetData = () => {
        this.showLoading(true);
        //Get data if exist this posibility
    };
    deleteEntity=()=>{
        return new Promise((resolve, reject) => {
            this.props.handleSubmit();
        });
    }
    /* Change status */
    showLoading =(status)=>{
        this.setState({
            showLoading: status
        });
    }
    /* REac functions */
    componentWillMount(){
        if (this.hasAllData()){
            this.setState({
                data:this.props.data
            },()=>{
                this.setOptions();
            })
            
            
        }else{
            this.callServerToGetData();
        }
    }
   
   
    render() {
        return (
            <Loading show={this.state.showLoading}>
                <DeleteForm  
                handleClose={this.props.handleClose}
                show={true} 
                deleteEntity={this.deleteEntity}
                showLoading={this.showLoading}
                title={this.getTitle()}
                options={this.state.options}
                />
            </Loading>
        )
    }
}

const mapStateToProps = state => ({
    session: state.session,
    form:state.form
});
const mapDispatchToProps = (dispatch) => ({
    
});
export default connect(mapStateToProps, mapDispatchToProps)(ShareDeleteForm)

import _ from 'lodash';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import StripeElementKey from "src/modules/checkout/components/StripeElementKey";
import usePaymentMethodStripe from "src/modules/checkout/forms/Payment/usePaymentMethods/usePaymentMethodStripe";
import Form from "../../../../../../components/forms/Form/Form";
import SubmitButton from "../../../../../../components/generic/Buttons/SubmitButton/SubmitButton";
import PaymentFormContent from "../../../../../../modules/checkout/forms/Payment/PaymentFormContent";
import useModal from "../../../../../../modules/generic/hooks/useModal";
import useTranslate from "../../../../../../modules/generic/hooks/useTranslate";
import TemplateWizard from "../../../../../../modules/generic/templates/Modal/TemplateWizard";
import Button from '../../../../../generic/components/Buttons/Button';
import useMachine from '../../../../../generic/context/MachineContext/useMachine';
import { addAssistantText, clearAssistatText } from "../../../../../generic/store/action";
import schema from "../../../../schemas/payment";

const EditCardChildren = () => {
  const { translate } = useTranslate();
  const { closeModal } = useModal();
  const { next, context } = useMachine();
  const dispatch = useDispatch();
  const [card, setCard] = useState(false);
  const [isZipCodeRequired, setIsZipCodeRequired] = useState(false);

  const STATUS = {
    success: "success",
    error: "error",
    process: "process",
    input: "input",
  };

  const handleCancel = () => {
    closeModal()
  }

  const handleSetCard = (element) => {
    setCard(element);
  };

  const [errorMessageCard, setErrorMessageCard] = useState(null);
  const { addUpdateCreditCard } = usePaymentMethodStripe({});

  const handleSubmit = async (_values) => {
    try {
      const { cardSelect, ...values } = _values;
      const cardId = cardSelect === -1 ? null : cardSelect;
      const newCard = { ...card, ...values?.card ?? {} };
      await addUpdateCreditCard(values, { cardId });
      next({ ...values, card: newCard })
    } catch (e) {
      setErrorMessageCard(e.message)
    }
  }

  const handleInitModal = () => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText(translate('MODULES.CHECKOUT.MODALS.CHANGE_CARD.STEP_EDIT_CARD.TOOLTIP1')));
    dispatch(addAssistantText(translate('MODULES.CHECKOUT.MODALS.CHANGE_CARD.STEP_EDIT_CARD.TOOLTIP2')));
    dispatch(addAssistantText(translate('MODULES.CHECKOUT.MODALS.CHANGE_CARD.STEP_EDIT_CARD.TOOLTIP3')));
  }

  useEffect(() => {
    handleInitModal()
    return () => {
      dispatch(clearAssistatText());
    }
  }, []);

  const validateZipCode = (zipCode) => {
    if (isZipCodeRequired) {
      return zipCode && zipCode !== ""
    }
    return true;
  };

  return (
    <Form
      schema={schema(validateZipCode)}
      onSubmit={handleSubmit}
      onlySendDirty={false}
    >
      <TemplateWizard
        props={{ 
          title: _.get(context, "initialData.changeCardTitle", 'Change payment card'), 
          subTitle: _.get(context, "initialData.changeCardSubTitle", 'Payment card')
        }}
        buttons={{
          submit:
            <SubmitButton>
              {translate('MY_ACCOUNT_MODAL_UPDATEPASSWORD_SAVE')}
            </SubmitButton>,
          cancel:
            <Button variant="secondary" onClick={() => handleCancel()}>
              {translate('BUTTON_CANCEL')}
            </Button>
        }}
      >
        <React.Fragment>
          <PaymentFormContent
            defaultVat={{}}
            selectCard={false}
            useCard={true}
            errorCard={errorMessageCard ? "error" : null}
            errorMessageCard={errorMessageCard}
            setIsZipCodeRequired={setIsZipCodeRequired}
            setCard={handleSetCard}
            enabledVat={false}
          />
        </React.Fragment>
        <div>&nbsp;</div>
      </TemplateWizard>
    </Form>
  );
};

function EditCard() {
  const { context } = useMachine();
  const source = context?.getSource?.source;

  return (
    <StripeElementKey source={source}>
      <EditCardChildren />
    </StripeElementKey>
  )
}
export default EditCard;
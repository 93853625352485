import React from "react";
import PAGES from "../../../constants/pages";
import ClaraLayout from "../../../layouts/Clara/ClaraLayout";
import AssistanceMyAccountV4 from "../../../modules/assistanceV4/components/AssistanceFrame2/assistanceMyAccount";
import Text from "./../../../components/text/Text/Text";
import MyAccountView from "./MyAccount/MyAccountView";

const routes = [
  {
    path: PAGES.myAccount.path,
    head: {
      title: "META_HEAD_TITLE_MY_ACCOUNT",
    },
    breadCrumbs: {
      reset: true,
      add: true,
      label: <Text uuid={"BREADCRUMS_MY_ACCOUNT"} params={{ fullName: "" }} />,
    },
    exact: true,

    component: <MyAccountView />,
    layout: ClaraLayout,
    propsLayout: (businessRules)=>{
      const hasSelectedStartup = businessRules?.hasSelectedStartup() ?? false
      if (hasSelectedStartup){
        return {
            startupMenu: true,
            userMenu: true,
            fullMenu: true,
            showAssistance: true,
            cantBack: true,
            showBreadCrumbs: true,
            assistance: AssistanceMyAccountV4,
      
        }
      }else{
        return {
          startupMenu: false,
          userMenu: true,
          fullMenu: false,
          firstMenu: true,
        }
      }
    },
  },
];

export default routes;

export default {
    WIZARD_ADD_TITLE: "Does your startup have directors?",
    WIZARD_ADD_SUBTITLE: "Your directors",
    WIZARD_CONFIRM_ADD: "Yes, add a director",
    WIZARD_ADD_INDIVIDUAL_OR_COMPANY_SUBTITLE: "Add a director",
    WIZARD_ADDED_SUBTITLE: "Director added!",
    FIRST_STEP_1_TOOLTIPS: 'A <a href="https://academy.clara.co/article/2fvaE5tVF7ywn7izaON0PR" target="_blank">director</a> is a member of the company\'s board, and can be responsible for the day-to-day management of the company and for setting the company\'s strategy. ',
    FIRST_STEP_2_TOOLTIPS: 'Directors added here should have no other roles in the company. If this changes, edit their profile at any time.',
    THIRD_STEP_TOOLTIP: 'To complete your company setup, first add all your company\'s directors.',
    THIRD_STEP_NOT_ANOTHER: 'Add more directors to your map at any time.',
    WIZARD_FORMATION_TITLE_CORPORATE: 'Add a corporate stakeholder?',
    WIZARD_FORMATION_SUBTITLE_CORPORATE: 'Add a corporate director',
    WIZARD_FORMATION_TITLE_INDIVIDUAL: 'Add another director?',
    WIZARD_FORMATION_SUBTITLE_INDIVIDUAL: 'Add a director',
}

import _ from "lodash";

export default {
    CONNECTIONS:{
        EQUITY_TITLE:'Equity:',
        SHARESININCORPORATEDCOMPANIES:'Have\'s shares in {{{sharesInIncorporatedCompanies.name}}}',
        SHARESININCORPORATEDCOMPANIES_TITLE:'Shares In Incorporated Companies:',
        DOCUMENTS_TITLE:'Documents:',
        DUEDILIGENCES_TITLE: 'Remarks:',
        ASSETSANDREVENUES_TITLE:'Assets And Revenues:',
        ASSETSANDREVENUES:'Haves assets and revenues in {{assetsAndRevenues.name}}',
        DOCUMENTS:'{{{documents.name}}} ',
        EQUITY:'This group company has equity in the startup that must be deleted first.',
        DUEDILIGENCES:'Issue {{dueDiligences.title}}'
    },
    RESOLVE:'Solve these issues and return here to remove',
    TITLE_NO_CONNECTIONS:'Are you sure you want to remove {{{groupCompany.name}}}? ',
    TITLE_CONNECTIONS:'It’s not possible to remove {{{groupCompany.name}}} right now',
    DESCRIPTION:'You won’t be able to undo this',
    DELETE:'Permanently remove this company',
    REASONS:'Here\'s why',
    CHECKBOX:{
        SET_INACTIVE:'inactivate',
        DELETE_GROUP_COMPANY:'delete'
    }
}
import React, {useState, useMemo, useEffect} from 'react';
import { useDispatch } from "react-redux";
import _ from "lodash";
import BusinessRuleAccess from "../../clara/components/BusinessRuleAccess/BusinessRuleAccess";
import RoleAccess from "../../security/components/RoleAccess/RoleAccess";
import Text from "../../../components/text/Text/Text";
import { Constants } from '../../../v1/utils/constants';
import useSession from "../../session/hooks/useSession";
import useSetAsSeenSubscriptionGatePopup from "./../../../hooks/services/modules/subscription/useSetAsSeenSubscriptionGatePopup";
import classesModule from "./Subscriptions.module.scss";
import Button from '../../../modules/generic/components/Buttons/Button';
import ModalAssistant from "../../generic/components/Modal/ModalAssistant";
import TemplateWizard from "../../generic/templates/Modal/TemplateWizard/TemplateWizardContent";
import useTranslate from "../../generic/hooks/useTranslate";
import {addAssistantText, clearAssistatText} from "../../generic/store/action";

const Subscriptions = ({ title, content, button, gateField, popupName, image, subtitle= "", assistantText = ""}) => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const [setSubscription, loadingSuscription] = useSetAsSeenSubscriptionGatePopup({ showSuccessNotification: false, showErrorNotification: false });
  const { startup, updateStartup } = useSession();

  useEffect(()=>{
    if(assistantText){
      dispatch(clearAssistatText())
      dispatch(addAssistantText(translate(assistantText)))
    }
  },[]);

  const hasBeenSeen = useMemo(() => {
    return _.get(startup, `gates.${gateField}`, false);
  }, [JSON.stringify(startup)]);

  const [open, setOpen] = useState(!hasBeenSeen);
  
  const handleCloseModal = () => {
    if (!hasBeenSeen) {
      const variables = {
        startupId: startup.id,
        popupName: popupName
      }
      setSubscription({ variables });
      let newStartup = { ...startup, gates: { ..._.get(startup, "gates", {}) } };
      _.set(newStartup, `gates.${gateField}`, true);
      updateStartup(newStartup);
    }
    setOpen(false);
  }

  return (
    <>
      <RoleAccess action={Constants.ACTIONS.SEE_FREEMIUM_POPUP}>
        <BusinessRuleAccess conditions={[Constants.BUSINESS_RULES.IS_START_SUBSCRIPTION]}>
          <ModalAssistant open={open} handleClose={() => handleCloseModal()}>
            <TemplateWizard
              props={{
                title: translate(title),
                subTitle: translate(subtitle)
              }}
              buttons={{
                anotherRight: [ <Button
                  variant='upgrade'
                  onClick={() => {
                    handleCloseModal();
                    dispatch({ type: 'GO_TO', page: Constants.PAGES.upgrade })
                  }}
                >
                  <Text uuid={"BUTTON_UPGRADE"} />
                </Button>],
                submit: <Button variant='primary' onClick={handleCloseModal}><Text uuid={button} /></Button>
              }}
              contentClass={classesModule.NoPadding}
              formClass={classesModule.NoGap}
            >
              <div className={classesModule.ModalContent}>
                <div>
                  <div className={classesModule.ContentText}>
                    <Text uuid={content} />
                  </div>
                </div>
                <div className={classesModule.RightContent}>
                  <img src={image} />
                </div>
              </div>
            </TemplateWizard>
          </ModalAssistant>
        </BusinessRuleAccess>
      </RoleAccess>
    </>
  )
}

export default Subscriptions;

import useMutation from "../../../custom/useMutation";

const query = `mutation requestSignatures($startupId: String!, $agreement: GenerateAgreementInputType!, $agreementId : String) {
  requestSignatures(startupId: $startupId, agreement: $agreement, agreementId: $agreementId) {
    id
  }
}`;

const useRequestSignatures = (variables) => {

  const [mutation, loading] = useMutation(query, variables);

  return { mutation, loading };
};
export default useRequestSignatures;



import React, { FC, useMemo } from 'react';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import FormAdgnSpvTypeWizard from './../../submachines/FormAdgmSPVTypeModal/wizard';

interface Props {

}
/**
* Description Step: Machine to add spv  
*/
const FormAdgnSpvTypeSubMachineStep: FC<Props> = () => {

  const { next, context, prev, cancel, send } = useMachine();
  const initialValues = useMemo(() => {
    return { ...(context?.FormAdgnSpvTypeSubMachineStep ?? {}) }
  }, [context]);

  const handleStopMachine = () => {
    next(context)
  }

  const handleCancelMachine = () => {
    cancel();
  }

  const handlePrevMachine = () => {
    prev();
  }

  const handleChooseJusisdiction = () => {
    send({ type: 'CHOOSE_JURISDICTION', payload: {} });
  }

  const handleGoToCayman = () => {
    send({ type: 'GO_TO_CAYMAN', payload: {} });
  }

  const handleGoToADGMTS = () => {
    send({ type: 'GO_TO_ADGM_TS', payload: {} });
  }

  return (
    <FormAdgnSpvTypeWizard
      initialValues={initialValues}
      onFinish={handleStopMachine}
      onCancel={handleCancelMachine}
      onPreviousMachine={handlePrevMachine}
      goToCayman={handleGoToCayman}
      goToChooseJurisdiction={handleChooseJusisdiction}
      goToADGMTS={handleGoToADGMTS}
    />
  );
};

export default FormAdgnSpvTypeSubMachineStep;

import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import * as Yup from 'yup';
import Form from '../../../../components/forms/Form/Form';
import TextInput from '../../../../components/inputs/Text/TextInput';
import SubmitButton from "../../../generic/components/Buttons/SubmitButton";
import ControllerInput from '../../../generic/components/Inputs/ReactHookForm/ControllerInput';
import Typography from '../../../generic/components/Texts/Typography';
import TemplateLabel from '../../../generic/templates/Label';
import TemplateFormDefault from "./../../../generic/templates/Modal";
import useFormat from 'src/modules/generic/hooks/useFormat';
import classes from './Form.module.scss'

const AuthorisedShareCapital = ({ currency }) => {
  const { getValues } = useFormContext();
  const { moneyFormat } = useFormat();
  const shares = getValues('shares') || 0
  const parValue = getValues('parValue') || 0
  return <TemplateLabel
  label={"Authorised share capital"}
>
  <Typography component="p" color="black" weight="bold">
    {`${currency} ${moneyFormat(shares * parValue)}`}
  </Typography>
</TemplateLabel>
};

const FormShareCapitalValues = ({
  defaultValues,
  buttons,
  Template = TemplateFormDefault,
  buttonSubmitProps,
  propsTemplate,
  onCompleteSubmit,
  currency
}) => {
  const schema = Yup.object().shape({
    shares: Yup.number().required('This field is required').typeError('Please enter a valid amount').min(1),
    parValue: Yup.number().required('This field is required').typeError('Please enter a valid amount').min(0.00001, 'Par value must be greater than or equal to 0.00001'),
  });

  const refForm = useRef(null);

  const handleSubmit = async (values) => {
    onCompleteSubmit && await onCompleteSubmit(values);
  }
  
  return (
    <>
      <Form
        onlySendDirty={false}
        defaultValues={defaultValues}
        schema={schema}
        onSubmit={handleSubmit}
        ref={refForm}
        optionsForm = {{mode:"onChange"}}
      >
          <Template 
            buttons={{
              ...buttons,
              submit: <SubmitButton {...buttonSubmitProps}>
                Next
            </SubmitButton> 

            }}
            props={propsTemplate}
          >
          <TemplateLabel
            label="Number of authorised shares"
            isRequired={true}
          >
            <ControllerInput
              render={TextInput}
              name='shares'
              type="number"
              placeholder="Enter an amount"
            />
          </TemplateLabel>
          <TemplateLabel
            label=""
          >
          <Typography component="p" color="black">
          Authorised shares are all of the shares which a company is allowed to issue to its shareholders. This can be increased by shareholder resolution.
        </Typography>
        </TemplateLabel>
          <TemplateLabel
            label={`Par value of shares (${currency})`}
            isRequired={true}
          >
            <ControllerInput
              render={TextInput}
              name='parValue'
              type="share"
              placeholder="Enter an amount"
            />
          </TemplateLabel>
          <TemplateLabel
            label=""
          >
          <Typography component="p" color="black">
            The par value is the face value of the shares, not the market value. It is the lowest value that the shares may be issued at and is often set at a nominal rate as low as USD 0.0001.
          </Typography>
        </TemplateLabel>
            <AuthorisedShareCapital currency={currency} />
            <TemplateLabel
            label=""
          >
            <Typography component="p" color="black">
            The required share capital is the number of authorised shares multiplied by the par value of those shares.         
             </Typography>
        </TemplateLabel>
        </Template>
      </Form>
    </>
  );
}

export default FormShareCapitalValues;
import React, {useEffect} from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form';
import classNames from 'classnames'

interface FieldArrayComponentProps<Values> {
    name: string,
    component: React.ElementType,
    defaultValues?: Values[],
    buttonsContainer?: React.ElementType,
    innerRef?: React.Ref<any>,
    keyName?: string,
    hideButtonAdd?: boolean,
    propsComponent?: any,
    hiddenButtonRemoveDefault?: boolean
}

const FieldArrayComponent = <ValuesInput extends object>({
    name,
    keyName = "key_id",
    defaultValues,
    component: Component,
    buttonsContainer: ButtonsContainer,
    hideButtonAdd,
    hiddenButtonRemoveDefault = false,
    propsComponent = {},
    innerRef }: FieldArrayComponentProps<ValuesInput>) => {

    const { control, setValue, watch, } = useFormContext();

    const { fields, append, remove, ...restMethod } = useFieldArray({
        control,
        name,
        keyName
    });

    const update = (index: number, value: ValuesInput, config: object) => {
        watch(`${name}[${index}]`) && setValue(`${name}[${index}]`, value, config);
    };

    React.useImperativeHandle(
        innerRef,
        () => ({
            fields,
            append,
            remove,
            update,
            ...restMethod
        }),
        []
    );



    const watchFieldArray = watch(name);
    const controlledFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchFieldArray[index]
        };
    });

    return (
        <div>
            {controlledFields.map((field, index) => {
                return (
                    <React.Fragment key={field[keyName]}>

                        <Component {...{ append, remove, update, index, ...restMethod }} value={field} name={`${name}[${index}]`} defaultValue={field} {...propsComponent} />
                        {!hiddenButtonRemoveDefault &&
                            <button
                                className={classNames('mr-2 btn-icon', {
                                    'float-none mb-2': index !== fields.length - 1,
                                    'float-left': index == fields.length - 1,
                                })}
                                onClick={() => remove(index)}
                                color={'danger'}
                            >
                                <React.Fragment>
                                    { /*typeof buttonRemove == 'function' ? (
                                    buttonRemove
                                ) : (
                                        <React.Fragment>
                                            <span className="btn-inner--icon">
                                                <i className="fas fat-remove"></i>
                                            </span>
                                            <span>{buttonRemove}</span>
                                        </React.Fragment>
                                )*/}
                                    Remove
                                </React.Fragment>
                            </button>
                        }
                        {fields.length - 1 !== index && <hr />}
                    </React.Fragment>
                )
            })}

            {!hideButtonAdd && <>
                {ButtonsContainer ? <ButtonsContainer {...{ append, remove, ...restMethod }} /> :
                    <>
                        <button
                            color={'primary'}
                            onClick={() => append(defaultValues)}
                        >
                            <React.Fragment>
                                { /*typeof buttonAdd == 'function' ? (
                                buttonAdd
                            ) : (
                                    <React.Fragment>
                                        <i className="fas fat-add"></i> <span>{buttonAdd}</span>
                                    </React.Fragment>
                                )
                            */}
                                ADD
                            </React.Fragment>
                        </button>
                    </>
                }
            </>
            }
        </div>
    )
};
export default FieldArrayComponent;

import { useMemo } from 'react';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import AddConciergeCompany from '../steps/AddConciergeCompany';
import FormWithConciergeSuccess from '../steps/FormWithConciergeSuccess';

const useSteps = () => {

  return useMemo(() => ({
    AddConciergeCompany: {
      render: AddConciergeCompany,
    },
    FormWithConciergeSuccess: {
      render: FormWithConciergeSuccess,
    },
    SaveStep_Concierge: {
      render: TemplateWizardSkeletonMachine,
    },
    
  }), []);
}

export default useSteps;

import React from 'react';
import classesModule from "./Botonera.module.scss";
const Botonera = ({ children,className, ...props }) => {

    return (

        <div className={`${classesModule.Botonera} ${className}`}>
            {children}
        </div>

    )
}

export default Botonera;

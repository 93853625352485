import MachineStakeholder from "../../core/wizards/machine/MachineStakeholder";
import FirstStep from './steps/FirstStep';
import LastStep from './steps/LastStep';
import IndividualOrCompanyStep from './steps/IndividualOrCompanyStep';
import IndividualOrCompanyStepFormAdvisor from "./steps/IndividualOrCompanyStepForm";

export default class MachineAdvisor extends MachineStakeholder {

  constructor() {
    super('advisor', FirstStep, LastStep, IndividualOrCompanyStep, IndividualOrCompanyStepFormAdvisor);
  }
  
}

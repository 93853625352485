import { useMemo } from 'react';
import { assign } from 'xstate';

const useActions = ({ onPreviousMachine }) => {

  return useMemo(() => {

    const setCaymanJurisdiction = assign((context: any = {}, event: any = null) => {
      const filterData = {
        ...context,
        AddCaymanCompany :  {
          country: "KY",
          jurisdiction: "Cayman Islands",
        }
      };
      return filterData;
    });

    /** this action is online used to return of chidlren machine to parent machine. If yo need other type of comunication you can add more actions here */
    const previousMachine = assign((context: any = {}, event: any = null) => {
      onPreviousMachine && onPreviousMachine();
      return context;
    });

    return {
      setCaymanJurisdiction,
      previousMachine
    }
  }, [onPreviousMachine]);

}

export default useActions;

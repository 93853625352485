import React, { FC, useMemo } from 'react';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import AddWithClaraConciergeWizard from '../../../../AddWithClaraConciergeModal/wizard';

interface Props {

}
/**
* Description Step: Add with Clara Concierge 
*/
const AddWithClaraConciergeSubMachineStep: FC<Props> = () => {

  const { next, context, prev, cancel } = useMachine();
  const initialValues = useMemo(() => {
    return context?.AddWithClaraConciergeSubMachineStep ?? {}
  }, [context]);
  
  const handleStopMachine = () => {
    next(context)
  }
  const handleCancelMachine = () => {
    cancel();
  }
  const handlePrevMachine = () => {
      prev();
  }
  
  return (
    <AddWithClaraConciergeWizard initialValues={initialValues} onFinish={handleStopMachine} onCancel={handleCancelMachine} onPreviousMachine={handlePrevMachine}/>
  );
};

export default AddWithClaraConciergeSubMachineStep;

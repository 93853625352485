import React from 'react';
import {Field, Formik} from "formik";

import {Modal, Text, TextAreaInput} from '../../../../../../components';
import classes from "./ModalDecline.module.scss";
import PrimaryButton from "../../../../Generic/Buttons/PrimaryButton/PrimaryButton"
import SecondaryButton from "../../../../Generic/Buttons/SecondaryButton/SecondaryButton"
import *as Yup from 'yup';

const ModalDecline = ({ show, title, handleClose, handleDecline }) => {
    const handleCancel = () => {
        handleClose(null)
    }
    const handleSubmit = (values) => {
        if (handleDecline) {
            handleDecline(values)
        } else {
            handleClose(values)
        }
    }
    return (
        <React.Fragment>
            <Modal open={show} title={title} showHeader={false}>
                <div className={classes.ModalDecline}>
                    <h1>{title}</h1>
                    <div className={classes.TableDecline}>
                        <Text uuid={"LABEL_DOCUMENT_DECLINE_ISSUES"} />
                        <Formik
                            initialValues={{}}
                            validationSchema={Yup.object().shape({
                                issues: Yup.string().required("This field is required").min(3, 'It is minimum three characters'),
                            })}
                            onSubmit={handleSubmit}
                        >

                            {({ values, errors, touched, handleChange, handleBlur, isValid, handleSubmit, isSubmitting, setFieldValue }) => (
                                <React.Fragment>
                                    <form onSubmit={handleSubmit}>
                                        <Field name={"issues"}>
                                            {({ field, form }) => (
                                                <React.Fragment>
                                                    <TextAreaInput
                                                        required={true}
                                                        field={field}
                                                        form={form}
                                                        autoComplete='off'
                                                        placeholder={"PROPOSAL_DECLINE_PLACEHOLDER"}
                                                        showRequired={false}
                                                        className={classes.ChatInputTextarea}
                                                    />
                                                </React.Fragment>
                                            )}
                                        </Field>
                                        <div className={classes.botonera}>
                                            <SecondaryButton type="button" onClick={handleCancel}><Text uuid={"BUTTON_CANCEL"} /></SecondaryButton>
                                            <PrimaryButton type="button" disabled={!isValid || !values.issues} onClick={handleSubmit}><Text uuid={"BUTTON_SUBMIT"} /></PrimaryButton>
                                        </div>
                                    </form>
                                </React.Fragment>
                            )}
                        </Formik>
                    </div>

                </div>
            </Modal>
        </React.Fragment>
    )
}

export default ModalDecline;

import { RoleFields } from "../../../stakeholder/graphql/typePolicies";
import { useEffect } from "react";
import useQuery from "../../../../hooks/custom/useQuery";

export const COMPANY_CORPORATE_MAP = `
  ${RoleFields}
  query companyCorporateMap($startupId: ID!, $filters: CorporateMapFilter) {
  companyCorporateMap(startupId: $startupId, filters: $filters) {
    id
    shortCircuit
    companies {
      id
      kind
      name
      avatar
      level
      isActive
      size
      managedByClara
      companyType
      formationData{
        tslType
        isUsingNominee
        isAwaitingFunds
      }
      pendingTasksCount
      jurisdiction
      isHoldingCompany
      incorporation_date
      dissolution_date
      isLocked
      children {
        id
        percentage
      }
      stakeholders {
        id
        role {
          id
          pendingTasksCount
        }
        
      }
    }
    roles {  id }
    stakeholders{
      pendingTasksCount
      id
      rolesInProgress{
        toAdd{
          ...RoleFields
        }
        toDelete{
          ...RoleFields
        }
      }
      isAnEntity
      isFounder
      isActive
      avatar
      fullName
      createdAt
      startDate
      endDate
      isLocked
      roles {
        ...RoleFields
      }
    }
  }
}`;

const useCompanyCorporateMap = (variables) => {
  const { loading, error, data } = useQuery(
    COMPANY_CORPORATE_MAP,
    variables,
    {},
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );
  useEffect(() => {}, []);

  return { loading, error, data };
};
export default useCompanyCorporateMap;

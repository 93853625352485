import useLazyQuery from "src/hooks/custom/useLazyQuery";
export const GET_GROUP_COMPANY = `
query getFitAndProper($startupId: ID!, $stakeholderId: ID!) {
  getStakeholder(startupId: $startupId, stakeholderId: $stakeholderId) {
    id
    fitAndProperStatement{haveBeenConvicted { question description}
			haveEverBeenInPrison { question description}
			haveFinancialCharges { question description}
			haveEverBeenBanned { question description}
			haveBeenDisciplined { question description}
			haveEverLostCourtCase { question description}
			haveBeenDisqualified { question description}
			haveBeenInBankruptCompany { question description}
			havePendingProceedings { question description}
			haveBeenInStatutoryManagement { question description}    }}
}`

const useGetGroupCompany =(variables) => {
    const { manualQuery, loading, error, data, refetch } = useLazyQuery(GET_GROUP_COMPANY, variables);
    
    const query = async (variables) => {
        const { data } = await manualQuery({variables})
        return {
                stakeholder: data['getStakeholder'],
        }
    }

    return {
        query,
        loading,
        error,
        data,
        refetch
    }
}
export default useGetGroupCompany

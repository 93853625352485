import React, { useState } from "react";
import classes from "./search.module.scss";
import Icon from '../../../modules/generic/components/Icon';

const Search = ({ value = "", onChange, className, placeholder = "Search" }) => {

  const [filter, setFilter] = useState(value);

  const handleOnChange = (event) => {
    setFilter(event.target.value);
    onChange(event.target.value)
  }

  return (
    <div className={`${classes.contentInput} ${className}`}>
      <i>
        <Icon color={"#2c74ff"} icon={"Search"} size={"1.3077rem"} isClara />
      </i>
      <input
        placeholder={placeholder}
        value={filter}
        className={`${classes.input}`}
        onChange={handleOnChange}
        type='search'
      />
    </div>
  )
}

export default Search


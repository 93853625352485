import * as Yup from "yup";
import React, { FC } from "react";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import Form from "src/components/forms/Form/Form";
import VerificationCode from "src/components/inputs/VerificationCode/VerificationCode";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import useSession from "src/modules/session/hooks/useSession";
import classes from '../../AcceleratorsView.module.scss';
import useVerifyAcceleratorCode from "../../hook/useVerifyAcceleratorCode";
import Typography from "src/modules/generic/components/Texts/Typography";
import useJoinProgram from 'src/hooks/services/modules/startups/accelerators/useJoinProgram';
import Icon from "src/modules/generic/components/Icon";
import useModal from 'src/modules/generic/hooks/useModal'
import AcceleratorAcceptedModal from '../AcceleratorAcceptedModal'
import useNavigation from 'src/modules/generic/hooks/useNavigation'
import { Constants } from 'src/v1/utils'
import notification from 'src/v1/utils/notification'
export interface AcceleratorsProps { }


const Accelerators: FC<AcceleratorsProps> = ({ }: AcceleratorsProps) => {
  const { translate } = useTranslate();
  const [mutation] = useJoinProgram({});
  const { startupId, updateStartup } = useSession();
  const { openModal } = useModal()
  const { goTo } = useNavigation()

  const schema = Yup.object().shape({
    verificationCode: Yup.string()
      .nullable()
      .required("Required")
      .min(6, translate("VERIFY_EMPTY")),
  });

  const { manualQuery: getAccelerator } = useVerifyAcceleratorCode();

  const handleSubmit = async (values) => {
    try {
      const variables = {
        code: values.verificationCode
      }
      //TODO: change the message coming from the backend
      const { data } = await getAccelerator({ variables })
      if (data?.verifyAcceleratorCode?.code) {
        const startupData = {
          acceleratorCode: data?.verifyAcceleratorCode?.code,
        }
        const resultAcelertator = await mutation({ variables: { startupData, id: startupId } });
        updateStartup({
          acceleratorDef: data?.verifyAcceleratorCode,
          acceleratorData: resultAcelertator?.data?.joinProgram?.acceleratorData,
          goals: resultAcelertator?.data?.joinProgram?.goals
        })
        openModal(AcceleratorAcceptedModal, { handleSubmit: () => goTo(Constants.PAGES.dashboard), program: data?.verifyAcceleratorCode })
        return;
      }
      throw new Error('Verification failed. Check the code and try again.')
    } catch (e) {
      notification.sendNotification(e.message, "error", 2500);
    }
  }


  return (
    <>
      <div className={classes.container}>
        <Form
          defaultValues={{}}
          schema={schema}
          onSubmit={handleSubmit}
          className={classes.form}
          optionsForm={{ mode: "onChange" }}
        >
          <div className={classes.acceleratorVerificaction}>
            <Icon color={'#2C74FF'} size={'68px'} isClara={true} icon={'Locked'} isClara2023={true} />
            <Typography variant="h3" weight="semibold">
              {translate("MODULES.ACCELERATORS.ACCELERATOR_PROGRAM_VERIFICATION")}
            </Typography>
            <Typography variant="body" >
              {translate("MODULES.ACCELERATORS.SECTION_RESTRICTED")}
            </Typography>
            <div className={classes.fieldContainer}>
              <ControllerInput
                render={VerificationCode}
                name={"verificationCode"}
                defaultValue={false}
              />
              <SubmitButton labelLoading={'Verifing'}>
                {'Verify'}
              </SubmitButton>
            </div>
          </div>
        </Form>
      </div >
    </>
  );
}

export default Accelerators;
import React, {useMemo} from "react";
import {useDispatch} from "react-redux";
import ReactAvatar from "react-avatar";
import _ from "lodash";
import {gql, useQuery} from "@apollo/client";
import {Constants} from "src/v1/utils";
import useSession from "src/modules/session/hooks/useSession";
import DocumentTabSkeleton from "src/components/modules/clara/startups/groupCompany/Tabs/DocumentTab/DocumentTabSkeleton";
import DocumentTab from "src/components/modules/clara/startups/groupCompany/Tabs/DocumentTab/DocumentTab";
import useGetAcceleratorQuestionnarieResponses from "src/modules/startup/graphql/startup/useGetQuestionnarie";
import classes from "./AcceleratorTabs.module.scss";
import QuestionnaireResponsesTab from "./components/QuestionnaireTab/QuestionnaireTab";

const STAKEHOLDERS_GROUP_COMPANIES_QUERY = gql`
    query getStakeholdersGroupCompanies($startupId: ID!) {
        stakeholderList(startupId: $startupId) {
            id
            avatar
            fullName
            isAnEntity
        }
        getGroupCompanies(startupId: $startupId) {
            id
            avatar
            name
            kind
        }
    }
`;

const DOCUMENT_LIST_QUERY = gql`
    query getDocumentList($startupId: ID!) {
        documentList(startupId: $startupId) {
            id
            name
            kind
            type {
                code
                label
            }
            status
            freemiumValid
            subCategories
            file {
                id
                name
                size
                type
            }
            groupCompanies {
                groupCompany {
                    id
                    avatar
                    name
                }
                signedBy{
                    id
                    fullName
                    avatar
                    email
                }
            }
            stakeholders {
                id
                avatar
                fullName
            }
        }
    }
`;

const AcceleratorTabs = ({currentTab}) => {
    const { startup } = useSession();
    const { data: stakeholderCompaniesData } = useQuery(STAKEHOLDERS_GROUP_COMPANIES_QUERY, { variables: { startupId: startup.id }});
    const { data: documentList, loading: documentsLoading } = useQuery(DOCUMENT_LIST_QUERY, { variables: { startupId: startup.id }});
    const { data: questionnaireResponses } = useGetAcceleratorQuestionnarieResponses({ startupId: startup.id });

    const stakeholders = useMemo(() => {
        return stakeholderCompaniesData?.stakeholderList;
    }, [stakeholderCompaniesData]);

    const groupCompanies = useMemo(() => {
        return stakeholderCompaniesData?.getGroupCompanies;
    }, [stakeholderCompaniesData]);

    const documents = useMemo(() => {
        return documentList?.documentList;
    }, [documentList]);

    const questionnaire = useMemo(() => {
        return questionnaireResponses?.acceleratorQuestionnaireResponses;
    }, [questionnaireResponses]);

    const renderCurrentTab = () => {
        switch (currentTab) {
            case 'stakeholders':
                return <StakeholdersTab stakeholders={stakeholders} groupCompanies={groupCompanies} />;
            case 'documents':
                return <DocumentsTab documents={documents} documentsLoading={documentsLoading} />;
            case 'questionnaire':
                return <QuestionnaireTab questionnaire={questionnaire}/>;
            default:
                return <></>;
        }
    }

    return(
        <>
            {renderCurrentTab()}
        </>
    );
}

const StakeholdersTab = ({stakeholders, groupCompanies}) => {
    const dispatch = useDispatch();

    const goTo = (value) => {
        dispatch({
            type: "GO_TO",
            page: value.fullName ? Constants.PAGES.viewStakeholderIndividual : Constants.PAGES.viewGroupCompany,
            params: { id: value.id },
        });
    };

    return (
        <>
            <div className={classes.containerTab}>
                <h5 className={classes.tabTitle}>Companies</h5>
                {_.isEmpty(groupCompanies) && <div style={{marginLeft: '16px'}}>- There are no Group Companies</div>}
                <div className={classes.stakeholdersContainer}>
                    {_.map(groupCompanies, gc => {
                        return (
                            <div className={classes.stakeholder} onClick={() => goTo(gc)}>
                                <ReactAvatar
                                    name={gc.name}
                                    round={true}
                                    size={"2.462rem"}
                                    src={gc.avatar}
                                />
                                <p>{gc.name}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className={classes.containerTab}>
                <h5 className={classes.tabTitle}>Stakeholders</h5>
                {_.isEmpty(stakeholders) && <div style={{marginLeft: '16px'}}>- There are no Stakeholders</div>}
                <div className={classes.stakeholdersContainer}>
                    {_.map(stakeholders, sh => {
                        return (
                            <div className={classes.stakeholder} onClick={() => goTo(sh)}>
                                <ReactAvatar
                                    name={sh.fullName}
                                    round={true}
                                    size={"2.462rem"}
                                    src={sh.avatar}
                                />
                                <p>{sh.fullName}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}

const DocumentsTab = ({documents, documentsLoading}) => {
    const lock = false;

    return (
        <div className={`${classes.containerTab} ${classes.documentTabMargin}`}>
            <DocumentTabSkeleton isLoading={documentsLoading} />
            {!documentsLoading &&
                <>
                    <h5 className={classes.tabTitle}>Documents</h5>
                    <DocumentTab data={documents} lock={lock} />
                </>
            }
        </div>
    );
}

const QuestionnaireTab = ({questionnaire}) => {
    return (
        <div className={`${classes.containerTab} ${classes.questionnaireTab}`}>
            <h5 className={classes.tabTitle}>Questionnaire</h5>
            {_.isEmpty(questionnaire)
                ?
                <div style={{marginLeft: '16px'}}>- There are no questionnaire responses</div>
                :
                <QuestionnaireResponsesTab questionnaire={questionnaire} />
            }
        </div>
    );
}

export default AcceleratorTabs;
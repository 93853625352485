import React from "react";
import IcoMoon from "react-icomoon";

const iconSet = require("./icons/selection.json");
const iconClaraSet = require("./clara/selection.json");
export interface IconProps {
    icon: string;
    color?: string;
    size?: string | number;
    className?: string;
    style?: React.CSSProperties;
    isClara?: boolean;
    disableFill?: boolean;
    removeInlineStyle?: boolean;
    title?: string;
}


const Icon: React.FC<IconProps> = ({ isClara, ...props }) => {
    const icoset = isClara ? iconClaraSet : iconSet;
    return <IcoMoon iconSet={icoset} disableFill={true}   {...props}
    />;
};


export default Icon;
import React, { useEffect, useState } from "react";
import classesModule from "./classes.module.scss";
import Icon from "../../../../../modules/generic/components/Icon";
import classnames from "classnames";
import PropTypes from "prop-types";

const ButtonArrow = ({ onClick, className, open: openProps = false, variant = "bottom", alternativeArrow }) => {

  const [open, setOpen] = useState(openProps);

  useEffect(() => {
    setOpen(openProps);
  }, [openProps]);

  const handleClick = (e) => {
    if (onClick) onClick(e, !open);
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <button
        className={classnames(className, `${classesModule.ButtonArrow} ${classesModule[variant]}`, {
          [classesModule.close]: !open,
        })}
        onClick={handleClick}
      >
        {alternativeArrow ? (
          <Icon isClara icon={"Expand-card"} size={"1.8462rem"} />
        ) : (
          <Icon isClara icon={"Arrow-Up"} size={"1rem"} />
        )}
      </button>
    </React.Fragment>
  );
};

ButtonArrow.propTypes = {
  /**  If true show the content of the component */
  open: PropTypes.bool.isRequired,
  alternativeArrow : PropTypes.bool,
  variant: PropTypes.oneOf(["bottom", "top"])
};

ButtonArrow.defaultProps = {
  open: false,
  alternativeArrow: false,
  variant: "bottom"
};

export default ButtonArrow;

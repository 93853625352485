import {FieldArray} from 'formik';
import _ from "lodash";
import React, {useMemo, useState, ReactNode} from 'react';
import classes from "../../../../v1/containers/Forms/SteperForm.module.scss";
import RowAdd from '../../../forms/contents/RowAdd/RowAdd';
import SelectAddInput from "../../Select/SelectAddInput/SelectAddInput";
import Classes from './styles.module.scss';

import {
    AddNewStakeholderCorporate,
    StakeholderCorporate,
    StakeholderIndividual,
    AddNewStakeholderIndividual
} from './components'

interface Props {
    list: any[]
    fieldArrayName: string
    idGroupCompany: string
    limit: number
    onBlur?: boolean
    modal: boolean
    showEmailInput: boolean
    selectInputLabel: boolean
    errorMessage: string | object
    children?: ReactNode
}

const SelectStakeholders = (
    {
        list,
        fieldArrayName,
        tooltips,
        idGroupCompany,
        handleAddListStakeholder,
        onBlur,
        modal = false,
        selectInputLabel = 'Select stakeholder',
        handleUpdateValue,
        showEmailInput = true,
        errorMessage,
        showCorporate,
        showIndividual,
        children,
        addButtonText,
        limit,
    }) => {
    const [showAddStakeholder, setAddStakeholder] = useState(false);
    const [showSelect, setShowSelect] = useState(false);
    const [isAnEntity, setIsAnEntity] = useState()

    const stakeholderList = useMemo(() => _.map(list, as => ({
        value: as,
        label: as.fullName,
        type: 'stakeholder'
    })), [JSON.stringify(list)])
    const stakeholderAdd = (value) => {
        if (value.isAnEntity) {
            const stakeholder = {
                id: value.id,
                fullName: value.fullName,
                isAnEntity: value.isAnEntity,
                authorizedSignatory: {
                    fullName: _.get(value, 'authorizedSignatory.fullName', ''),
                    email: value.email
                }
            }
            if (showCorporate?.title) _.set(stakeholder, 'authorizedSignatory.title', value.title)
            return handleUpdateValue ? handleUpdateValue(stakeholder, value) : stakeholder
        }
        const stakeholder = {
            id: value.id,
            email: value.email,
            isAnEntity: value.isAnEntity,
            fullName: value.fullName,
        }
        if (showIndividual?.title) _.set(stakeholder, 'title', value.title)
        if (showIndividual?.registeredAddress) _.set(stakeholder, 'address', {
            ...value.address,
            country: value.address?.country?.code || value.address?.country
        })
        return handleUpdateValue ? handleUpdateValue(stakeholder, value) : stakeholder
    }
    const onChangeSelect = (value, arrayHelpers) => {
        if (value) {
            setShowSelect(false)
            const stakeholder = stakeholderAdd(value)
            arrayHelpers.push(stakeholder)
        }
    }

    return (
        <FieldArray
            name={fieldArrayName}
            render={(arrayHelpers) => {
                const addList = !limit && _.isEmpty(_.get(arrayHelpers.form.values, fieldArrayName)) && !_.isEmpty(stakeholderList) ? [
                    {
                        label: "Add all stakeholders",
                        onClick: (value) => {
                            setShowSelect(false)

                            arrayHelpers.form.setFieldValue(arrayHelpers.name, stakeholderList.map(st => stakeholderAdd(st.value)))
                        },
                        type: 'stakeholder',
                    },
                ] : [];

                return (<>
                    {_.get(arrayHelpers.form.values, `${fieldArrayName}`, []).map((signatory, index) => {
                        const reachLimit = _.get(arrayHelpers.form.values, `${fieldArrayName}`).length === limit
                        const isLast = _.get(arrayHelpers.form.values, `${fieldArrayName}`).length === index + 1;
                        const showAddAnother = limit ? !(isLast && reachLimit) : isLast
                        const handleAddElement = () => setShowSelect(true)
                        const handleDeleteElement = () => arrayHelpers.remove(index);
                        return (
                            <div className={modal ? Classes.ContainerRow : classes.ContainerRow}>
                                {_.get(arrayHelpers.form.values, `${fieldArrayName}[${index}].isAnEntity`) ?
                                    <StakeholderCorporate
                                        handleDeleteElement={handleDeleteElement}
                                        handleAddElement={handleAddElement}
                                        index={index}
                                        tooltips={tooltips?.corporate}
                                        fieldArrayName={fieldArrayName}
                                        showEmailInput={showCorporate?.emailInput}
                                        modal={modal}
                                        showTitle={showCorporate?.title}
                                    /> : <StakeholderIndividual
                                        handleDeleteElement={handleDeleteElement}
                                        handleAddElement={handleAddElement}
                                        index={index}
                                        fieldArrayName={fieldArrayName}
                                        tooltips={tooltips?.individual}
                                        showEmailInput={showIndividual?.emailInput}
                                        modal={modal}
                                        showTitle={showIndividual?.title}
                                        showRegisteredAddress={showIndividual?.registeredAddress}
                                    />}
                                {children ? children[index] : null}
                                <div className={Classes.containerButtons}>
                                    <button
                                        className={`genericWhite`} style={{marginRight: `1rem`}}
                                        onClick={handleDeleteElement}>Remove
                                    </button>
                                    {handleAddElement && showAddAnother && !showSelect &&
										<button className={`genericBlue`}
												onClick={handleAddElement}>{addButtonText || "Add Another"}
										</button>}
                                </div>
                                {!isLast && <hr className={classes.Separator}/>}
                            </div>
                        )
                    })}

                    {!showAddStakeholder && (showSelect || _.isEmpty(_.get(arrayHelpers.form.values, `${fieldArrayName}`))) ?
                        (<div className={Classes.containerForm}>
                            <RowAdd
                                label={`${selectInputLabel} `}
                                asterisk={true}
                                classNameInput={modal ? Classes.inputModal : Classes.input}
                                input={
                                    <SelectAddInput
                                        value={null}
                                        list={stakeholderList}
                                        mode={'add'}
                                        handleFilterList={(list) => {
                                            return _.filter(list, st => !_.some(_.get(arrayHelpers.form.values, `${fieldArrayName}`), sign => sign.id === st.value.id))
                                        }}
                                        onBlur={onBlur}
                                        placeholder={"Select from the list"}
                                        onChange={(value) => onChangeSelect(value, arrayHelpers)}
                                        menuProps={{
                                            addList: addList,
                                            propsList: [{type: 'stakeholder'}]
                                        }}
                                        errorMessage={typeof errorMessage === 'string' ? errorMessage : ''}
                                    />
                                }
                            />

                        </div>) : null}
                    <div>
                        {
                            showAddStakeholder ? (<>
                                {isAnEntity ? <AddNewStakeholderCorporate
                                    showTitle={showCorporate?.title}
                                    handleUpdate={(value) => {
                                        arrayHelpers.push(stakeholderAdd(value));
                                        if (handleAddListStakeholder)
                                            handleAddListStakeholder(value);
                                    }}
                                    tooltips={tooltips?.corporate}
                                    closeForm={() => setAddStakeholder(false)}
                                    idGroupCompany={idGroupCompany}
                                /> : <AddNewStakeholderIndividual
                                    showTitle={showIndividual?.title}
                                    showRegisteredAddress={showIndividual?.registeredAddress}
                                    handleUpdate={(value) => {
                                        arrayHelpers.push(stakeholderAdd(value));
                                        if (handleAddListStakeholder)
                                            handleAddListStakeholder(value);
                                    }}
                                    tooltips={tooltips?.individual}
                                    closeForm={() => setAddStakeholder(false)}
                                    idGroupCompany={idGroupCompany}
                                />}
                            </>) : null
                        }
                    </div>
                </>)

            }}
        />)
};
export default SelectStakeholders

import React, {FC, useEffect, useRef, useState} from 'react';
import * as Yup from 'yup';
import CalendarInputDate from "src/components/inputs/Calendars/CalendarInputDate";
import CheckboxButtonIcon from "src/modules/generic/components/Inputs/CheckboxButtonIcon";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import FiledBanner from "src/modules/generic/components/FieldBanner";
import Form from "src/components/forms/Form/FormL";
import FormProps from "src/modules/generic/forms/interfaces/FormProps";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import TemplateFormDefault from "src/modules/generic/templates/Modal";
import TemplateLabel from 'src/modules/generic/components/Inputs/LabelInput';
import classes from "./styles.module.scss";
import useTranslate from "src/modules/generic/hooks/useTranslate";

interface EditRoleFormProps extends FormProps {
  initialValues: any
}

const EditRoleForm: FC<EditRoleFormProps> = ({
  initialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate
}: EditRoleFormProps) => {
  const refForm = useRef();
  const { translate } = useTranslate();

  const { isManagedByClara, name, isActive } = initialValues || {
    isManagedByClara: false,
    endDate: false,
    name: '',
    isActive: true
  };

  const [roleIsActive, setRoleIsActive] = useState(isActive);
  const [showBanner, setShowBanner] = useState(false);

  const pincasPrice = initialValues?.pincas?.CHANGE_STAKEHOLDER_REMOVE_ROLE_DIRECTOR?.value
  const bannerCondition: boolean = name === 'DIRECTOR' && isManagedByClara && initialValues?.groupCompany?.jurisdictionType !== "DELAWARE";
  const bannerText: string = `Costs to file this change start from <strong>USD ${pincasPrice}</strong>. Select \"Save changes\" to add this item to your cart. Then go to the Dashboard and select \"Pay and submit\" to proceed.`

  useEffect(() => {
    setShowBanner(bannerCondition);
  }, []);

  const schema = Yup.object().shape({
    startDate: Yup.date().required('This field is required').typeError('Invalid date').nullable(),
    isActive: Yup.boolean().nullable(),
    endDate: Yup.date()
      .when(['isActive'], {
        is: (isActive) => !isActive ,
        then: Yup.date().required('Required').typeError('Invalid date').nullable(),
        otherwise: Yup.date().typeError('Invalid date').nullable()
      })
  });

  const options = [
    {
      value: false,
      label: translate('MODULES.STAKEHOLDER.EDIT_ROLE.NOT_ACTIVE'),
      color: '#2c74ff'
    },
    {
      value: true,
      label: translate('MODULES.STAKEHOLDER.EDIT_ROLE.IS_ACTIVE'),
      color: '#2c74ff'
    }
  ];

  return (
    <Form
      defaultValues={initialValues || {}}
      schema={schema}
      onlySendDirty={false}
      onSubmit={onCompleteSubmit}
      ref={refForm} optionsForm={{ mode: "onChange" }}
    >
      <Template
        buttons={{ ...buttons, submit: <SubmitButton {...buttonSubmitProps}>Save changes</SubmitButton> }}
        props={propsTemplate}
      >
        <TemplateLabel
          label={translate('MODULES.STAKEHOLDER.EDIT_ROLE.START_DATE')}
          isRequired
        >
          <ControllerInput
            render={CalendarInputDate}
            placeholder={translate('MODULES.CLARA.ROLE_DATE_INPUT_HIT')}
            name="startDate"
          />
        </TemplateLabel>
        <TemplateLabel
          label={translate('MODULES.STAKEHOLDER.EDIT_ROLE.QUESTION_IS_ACTIVE')}
          isRequired
        >
          <ControllerInput
            render={CheckboxButtonIcon}
            className={classes.checkboxMultiple}
            name='isActive'
            options={options}
            onChange={(v: boolean) => {
              setRoleIsActive(v);
            }}
            value={roleIsActive}
          />
        </TemplateLabel>
        {showBanner && isManagedByClara && <FiledBanner title={bannerText} />}
        {!roleIsActive &&
          <TemplateLabel
            label={translate('MODULES.STAKEHOLDER.EDIT_ROLE.END_DATE')}
            isRequired
          >
            <div className={classes.spaceData}>
              <ControllerInput
                render={CalendarInputDate}
                placeholder={translate("MODULES.CLARA.ROLE_DATE_INPUT_HIT")}
                name="endDate"
              />
            </div>
          </TemplateLabel>
        }
      </Template>
    </Form>
  );
}

export default EditRoleForm;

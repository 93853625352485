import React, {Component} from 'react';
import {Field, FieldArray} from "formik";

import TextInput from "../../Inputs/TextInput";
import ClaraUpload from "../../../../components/Form/ClaraUpload/ClaraUpload";
import {Constants} from "../../../../utils/constants"
import classes from "../../SteperForm.module.scss";
import AddInput from "../../Inputs/AddInput";
import "react-datepicker/dist/react-datepicker.css";
import FormikHelper from "../../../../utils/formikHelper";
import moment from "moment";
import ClaraSelect from "../../../../components/Form/ClaraSelect/ClaraSelect";
import {Checkbox} from "@material-ui/core";
import AddStakeHolder from "../../subForms/AddStakeHolder";

class Step1 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            parties: [],
            executionDate: undefined,
            expirationDate: undefined,
            categories: this.getAllCategories() || [],
            subcategories: this.getAllSubcategories() || [],
            documentTypes: this.getAllDocumentTypes() || [],
            agreementStatus: Constants.AGREEMENT_STATUS.UPLOADED,
            authorizedSignatories: {},
            isTeam: false,
            shouldClearDocTypeValue: false,
            shouldClearSubCategoryValue: false
        }

    }

    componentDidMount() {

        if (this.props.c === 0) {
            this.addEmptyParties(1);
        }
    }

    componentWillUpdate(nextProps, nextState, snapshot) {
        if (nextProps.tooltips && !this.props.tooltips && this.state.parties.length === 1) {
            this.setState({
                parties: []
            }, () => {
                this.addEmptyParties(1)
            })
        }
    }
    // Get all

    getAllCategories() {
        const constantCategories =  this.props.categories
        const categories = [];
        
        for (let key in constantCategories) {
            const category = constantCategories[key];
            categories.push({
                id: category.key,
                label: category.title
            })
        }

        return categories;
    }

    getAllSubcategories() {
        const constantCategories = this.props.categories;
        let subcategories = [];

        for (let key in constantCategories) {
            const category = constantCategories[key];
            if (category) {
                category.subCategories.forEach(subcategory => {
                    subcategories = this.getSubcategoriesForComponent(subcategory, subcategories);
                });
            }
        }

        return subcategories;
    }

    getAllDocumentTypes() {
        const constantCategories = this.props.categories;
        let documentTypes = [];

        for (let key in constantCategories) {
            const category = constantCategories[key];
            if (category) {
                category.subCategories.forEach(subcategory => {
                    documentTypes = this.getDocTypesForComponent(subcategory, documentTypes);
                });
            }
        }

        return documentTypes;
    }

    // Find Category Constant

    findCategoryConstant(category) {
        const constantCategories = this.props.categories
        let constant = '';

        for (let key in constantCategories) {
            if (constantCategories[key].key === category) {
                constant = key;
            }
        }

        return constant;
    }

    findCategoryConstantFromSubcategory(subcategory) {
        const constantCategories = this.props.categories;
        let constant = '';

        for (let key in constantCategories) {
            if (constantCategories[key].subCategories && constantCategories[key].subCategories.some(sc => sc.key === subcategory)) {
                constant = key;
            }
        }
        
        return constant;
    }

    findCategoryConstantFromDocType(docType) {
        const constantCategories = this.props.categories;
        let constant = '';

        for (let key in constantCategories) {
            if (constantCategories[key].subCategories) {
                constantCategories[key].subCategories.forEach(sc => {
                    if (sc.documentTypes && sc.documentTypes.some(dt => dt.key === docType)) {
                        constant = key;
                    }
                })
            }
        }

        return constant;
    }

    // Find Category - Subcategory

    getCategoryFromSubcategory(subcategory) {
        const categories = this.props.categories;
        const categoryIndex = this.findCategoryConstantFromSubcategory(subcategory);

        return categories[categoryIndex];
    }

    getCategoryFromDocType(docType) {
        const categories = this.props.categories;
        const categoryIndex = this.findCategoryConstantFromDocType(docType);

        return categories[categoryIndex];
    }

    getSubcategoryFromDocType(docType) {
        const categories = this.props.categories;
        const categoryIndex = this.findCategoryConstantFromDocType(docType);
        let subCategory = {};

        if (categories[categoryIndex] && categories[categoryIndex].subCategories) {
            categories[categoryIndex].subCategories.forEach(sc => {
                if (sc.documentTypes && sc.documentTypes.some(dc => dc.key === docType)) {
                    subCategory = sc;
                }
            });
        }

        return subCategory;
    }

    getCategoryFromConstant(categoryConstant) {
        const categories = this.props.categories;
        let category;

        for (let key in categories) {
            if (categories[key] && categories[key].key === categoryConstant) {
                category = categories[key];
            }
        }

        return category;

    }

    // Get Lists for Components

    getDocTypesForComponent(subcategory, documentTypes) {

        if (!subcategory.hide) {

            subcategory.documentTypes.forEach(documentType => {
                documentTypes.push({
                    id: documentType.key,
                    label: documentType.title
                })
            });
        }

        return documentTypes;
    }

    getSubcategoriesForComponent(subcategory, subcategories) {

        if (!subcategory.hide) {
            subcategories.push({
                id: subcategory.key,
                label: subcategory.title
            })
        }

        return subcategories;
    }

    // Find Lists By category

    getSubcategoriesFromCategory(category) {
        const categories = this.props.categories;
        const categoryIndex =  this.findCategoryConstant(category.key ? category.key : category);
        let subcategories = [];

        if (categories[categoryIndex] && categories[categoryIndex].subCategories) {
            categories[categoryIndex].subCategories.forEach(subcategory => {
                subcategories = this.getSubcategoriesForComponent(subcategory, subcategories);
            });
        }

        return subcategories;
    }

    getDocumentTypesFromCategory(category, subcategoryKey, docType) {
        const categories = this.props.categories;
        const categoryIndex = this.findCategoryConstant(category.key ? category.key : category);
        let documentTypes = [];

        if (categories[categoryIndex] && categories[categoryIndex].subCategories) {
            if (subcategoryKey) {
                const subcategory = categories[categoryIndex].subCategories.filter(sc => sc.key === subcategoryKey)[0];
                documentTypes = this.getDocTypesForComponent(subcategory, documentTypes);
            } else {
                if (docType) {
                    const subcategory = categories[categoryIndex].subCategories.filter(sc =>
                        sc.documentTypes.filter(dt => dt.key === docType).length > 0
                    )[0];
                    documentTypes = this.getDocTypesForComponent(subcategory, documentTypes);
                } else {
                    categories[categoryIndex].subCategories.forEach(subcategory => {
                        documentTypes = this.getDocTypesForComponent(subcategory, documentTypes);
                    });
                }
            }
        }

        return documentTypes;
    }

    // Parties

    addParty = () => {
        const newParties = this.state.parties;
        const i = newParties.length;

        newParties.push(
            this.getNewPartyStructure(i)
        );

        this.setState({
            parties: newParties
        }, () => {
            this.props.refresh();
        })
    };

    removeParty = (i, form, field) => {
        let newParties = this.state.parties;
        newParties.splice(i, 1);

        if (form.values.parties) {
            form.values.parties.splice(i, 1);
            const errors = form.errors;
            delete errors.parties;
            form.setErrors({
                ...errors
            });
        }

        this.addPartiesFromData(newParties)
    };

    addPartiesFromData(parties) {
        const newParties = [];
        parties.forEach((party, i) => {
            newParties.push(
                this.getNewPartyStructure(i, party)
            );
        });

        this.setState({
            parties: newParties
        }, () => {
            this.props.refresh();
        })
    }

    getNewPartyStructure(i, party) {
        const { tooltips = {} } = this.props;
        return (
            <React.Fragment>
                {i > 0 && <Field>
                    {({ field, form }) => (
                        <div className={classes.RemoveParty} onClick={() => this.removeParty(i, form, field)}>Remove</div>
                    )}
                </Field>}
                <div className={`${classes.ContainerRowAgreement} ${classes.Select}`}>
                    <Field name={`parties[${i}].party`}>
                        {({ field, form }) => (
                            <ClaraSelect
                                label={'Party ' + (i + 1)}
                                field={field}
                                form={form}
                                placeholder={"Search or select from a list..."}
                                adds={this.getPartyAdds()}
                                lists={this.getPartyLists()}
                                tooltip={tooltips['Party']}
                                callbacks={{ updateValues: this.handlePartyChange }}
                                paramsToCallbacks={{ index: i }}
                            />
                        )}
                    </Field>
                </div>
                {/*
                <div className={`${classes.ContainerRowAgreement} ${classes.Select} `}>
                    <Field name={`parties[${i}].authorizedSignatory`}>
                        {({field, form}) => (
                            <ClaraInputSelect
                                label={"Authorised Signatory: "}
                                field={field}
                                form={form}
                                placeholder={"Select from a list.."}
                                tooltipText={"The country of the Group company"}
                                list={this.getAuthorizedSignatoriesList(i)}
                                search={true}
                                tooltip={tooltips['Authorised Signatory']}
                                show={this.state.authorizedSignatories[i] || false}
                            />
                        )}
                    </Field>
                </div>
                */}
            </React.Fragment>
        );
    }

    getPartyLists() {
        return [
            {
                type: "groupCompany",
                list: this.props.groupCompanies
            },
            {
                type: "stakeholder",
                list: this.props.stakeholders
            }
        ]
    }

    getPartyAdds() {
        return [
            {
                type: "groupCompany",
                label: "Add New Company",
                textWarning: "This will create a new company for your startup. You can add more details later from the company's profile page.",
                placeholder: "Enter Company Name",
                startupId: this.props.startupId,
                params: null,
            },
            {
                type: "stakeholder",
                label: "Add New Corporate Stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add more details later from the stakeholder's profile page.",
                placeholder: "Enter Stakeholder's Name",
                startupId: this.props.startupId,
                form:AddStakeHolder,
                formProps: {groupCompanies:this.props.groupCompanies},
                params: {
                    groupCompanyId: this.state.groupCompanyId,
                    isAnEntity: true
                }
            },
            {
                type: "stakeholder",
                label: "Add New Individual Stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add more details later from the stakeholder's profile page.",
                placeholder: "Enter Stakeholder's Name",
                startupId: this.props.startupId,
                form:AddStakeHolder,
                formProps: {groupCompanies:this.props.groupCompanies},
                textToSave: 'fullName',
                params: {
                    groupCompanyId: this.state.groupCompanyId,
                    isAnEntity: false
                }
            },
        ]
    }

    addEmptyParties(cant) {
        const newParties = this.state.parties;

        for (let i = 0; i < cant; i++) {
            newParties.push(
                this.getNewPartyStructure(i)
            );

            this.setState({
                parties: newParties
            })
        }
    }

    // Handle changes

    handlePartyChange = (value, _, form, paramsToCallbacks) => {
        if (value) {
            const type = value.type;
            const id = value.id;
            const index = paramsToCallbacks.index; // TODO: See how to pass the index here.

            if (type === 'groupCompany') {

                const groupCompany = this.props.groupCompanies.find(gc => gc.id === id);

                if (groupCompany) {
                    this.setState(prevState => ({
                        ...prevState,
                        authorizedSignatories: {
                            ...prevState.authorizedSignatories,
                            [index]: groupCompany.authorizedSignatories ? groupCompany.authorizedSignatories.map(as => ({
                                id: as.stakeholder.id,
                                email: as.stakeholder.email,
                                label: as.stakeholder.fullName
                            })) : []
                        }
                    }), () => this.props.refresh());

                }
            } else {
                this.setState(prevState => ({
                    ...prevState,
                    authorizedSignatories: {
                        ...prevState.authorizedSignatories,
                        [index]: null
                    }
                }))
            }
        }
    };

    handleChange(e, field, form, agreementStatus) {

        const date = e === null ? null : moment(e).format("YYYY-MM-DD");

        if (agreementStatus) {

            let newAgreementStatus = '';

            const today = moment(new Date());
            const eventDate = moment(date);

            if (agreementStatus && date && today && today > eventDate) {
                newAgreementStatus = Constants.AGREEMENT_STATUS.EXPIRED;
            } else {
                newAgreementStatus = Constants.AGREEMENT_STATUS.UPLOADED;
            }


            FormikHelper.setValueInTheCorrectPosition('agreementStatus', form, newAgreementStatus);

            this.setState({
                agreementStatus: newAgreementStatus
            });
        }

        FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

        this.setState({
            [field.name]: e
        });
    }

    handleCategoryChange = (value, extraParams, form, cb) => {
        const category = value;
        let shouldClear = false
        
        let subcategories = [];
        let documentTypes = [];

        if (category) {
            subcategories = this.getSubcategoriesFromCategory(category);
            documentTypes = this.getDocumentTypesFromCategory(category, null, null);
            if (category === "team") {
                this.setState({
                    isTeam: true,
                    haveIPAssignment: false
                }, () => {
                    form.setFieldValue('IPAssignmentProvisions', false);
                });

            } else {
                this.setState({
                    isTeam: false,
                    haveIPAssignment: false
                }, () => {
                    form.setFieldValue('IPAssignmentProvisions', false);
                });
            }
        } else {
            shouldClear = true
            subcategories = this.getAllSubcategories();
            documentTypes = this.getAllDocumentTypes();
        }
        this.setState({
            subcategories,
            documentTypes,
            shouldClearDocTypeValue: shouldClear,
            shouldClearSubCategoryValue: shouldClear
        });

        form.setValues({
            ...form.values,
            category: category,
            subCategory: null,
            documentType: null
        });
    };

    handleSubCategoryChange = (value, extraParams, form, cb) => {
        const subcategory = value;
        let shouldClear = false

        let category = {};
        let subcategories = [];
        let documentTypes = [];

        if (subcategory) {
            category = this.getCategoryFromSubcategory(subcategory);
            subcategories = this.getSubcategoriesFromCategory(category);
            documentTypes = this.getDocumentTypesFromCategory(category, subcategory);
        } else {
            shouldClear = true
            if (form.values.category) {
                category = this.getCategoryFromConstant(form.values.category);
                subcategories = this.getSubcategoriesFromCategory(category);
                documentTypes = this.getDocumentTypesFromCategory(category);
            } else {
                subcategories = this.getAllSubcategories();
                documentTypes = this.getAllDocumentTypes();
            }
        }
        this.setState({
            documentTypes,
            subcategories,
            shouldClearDocTypeValue: shouldClear
        });

        if (category.key === "team") {
            this.setState({
                isTeam: true,
                haveIPAssignment: false
            }, () => {
                form.setFieldValue('IPAssignmentProvisions', false);
            });

        } else {
            this.setState({
                isTeam: false,
                haveIPAssignment: false
            }, () => {
                form.setFieldValue('IPAssignmentProvisions', false);
            });
        }

        form.setValues({
            ...form.values,
            category: category.key,
            subCategory: subcategory,
            documentType: null
        });
    };

    handleDocTypeChange = (value, extraParams, form, cb) => {
        const docType = value;

        let category = {};
        let subcategory = {};
        let documentTypes = [];
        let subcategories = [];

        if (docType) {
            category = this.getCategoryFromDocType(docType);
            subcategory = this.getSubcategoryFromDocType(docType);

            documentTypes = this.getDocumentTypesFromCategory(category, subcategory.key, value);
            subcategories = this.getSubcategoriesFromCategory(category);
        } else {
            if (form.values.subCategory) {
                subcategory = { key: form.values.subCategory };
                category = { key: form.values.category };
                subcategories = this.getSubcategoriesFromCategory(category);
                documentTypes = this.getDocumentTypesFromCategory(category, subcategory.key)

            } else {
                subcategories = this.getAllSubcategories();
                documentTypes = this.getAllDocumentTypes();
            }
        }

        if (category.key === "team") {
            this.setState({
                isTeam: true,
                haveIPAssignment: false
            }, () => {
                form.setFieldValue('IPAssignmentProvisions', false);
            });

        } else {
            this.setState({
                isTeam: false,
                haveIPAssignment: false
            }, () => {
                form.setFieldValue('IPAssignmentProvisions', false);
            });
        }

        this.setState({
            documentTypes,
            subcategories
        });

        form.setValues({
            ...form.values,
            category: category.key,
            subCategory: subcategory.key,
            documentType: docType
        });

    };

    handleCheckboxChange = (value, name, form) => {

        this.setState({
            haveIPAssignment: value
        }, () => {
            const subcategories = this.getSubcategoriesFromCategory(this.props.categories.TEAM);
            const documentTypes = this.getDocumentTypesFromCategory(this.props.categories.TEAM);

            this.setState({
                subcategories,
                documentTypes
            })
        });

        form.setFieldValue(name, value);
    };

    // Get Lists

    getCategoriesList() {
        return this.state.categories;
    }

    getSubcategoriesList() {
        return this.state.subcategories;
    }

    getDocTypesList() {
        return this.state.documentTypes;
    }

    getAuthorizedSignatoriesList(i) {
        return this.state.authorizedSignatories[i] || [];
    }

    render() {
        const { tooltips = {}, c } = this.props;

        return (
            <form className={classes.Form}>
                <div>
                    <div className={classes.ZoneTitle}>
                        <h1>{'Add Document'}</h1>
                        <p>{"Let’s add a Document to your Group Company."}</p>
                    </div>
                    <hr className={classes.Separator} />
                    <div className={classes.ContainerRowAgreement}>
                        <Field name={`file`}>
                            {({ field, form }) => (
                                <ClaraUpload
                                    required={true}
                                    name={`filepond`}
                                    label='Upload your Document: '
                                    field={field}
                                    form={form}
                                    tooltip={tooltips['Upload your Document']}
                                />
                            )}
                        </Field>
                    </div>
                    <hr className={classes.Separator} />
                    <div className={classes.ContainerRowAgreement}>
                        <Field name='agreementName'>
                            {({ field, form }) => (
                                <TextInput
                                    type='text'
                                    label='Document Name (optional): '
                                    field={field}
                                    form={form}
                                    placeholder={"Type here..."}
                                    tooltip={tooltips['Document Name']}
                                />
                            )}
                        </Field>
                    </div>
                    <hr className={classes.Separator} />
                    <div className={`${classes.ContainerRow} ${classes.Select}`}>
                        <Field name={`category`}>
                            {({ field, form }) => (
                                <ClaraSelect
                                    required={true}
                                    field={field}
                                    form={form}
                                    label={"Category: "}
                                    placeholder={"Search or select from a list..."}
                                    tooltip={tooltips['Category']}
                                    lists={this.getCategoriesList()}
                                    mode={"classic"}
                                    callbacks={{
                                        updateValues: this.handleCategoryChange
                                    }}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={`${classes.ContainerRow} ${classes.Select}`}>
                        <Field name={`subCategory`}>
                            {({ field, form }) => (
                                <ClaraSelect
                                    required={true}
                                    field={field}
                                    form={form}
                                    label={"Sub-Category: "}
                                    placeholder={"Search or select from a list..."}
                                    tooltip={tooltips['Sub-Category']}
                                    lists={this.getSubcategoriesList()}
                                    mode={"classic"}
                                    shouldClearValue= {this.state.shouldClearSubCategoryValue}
                                    callbacks={{
                                        updateValues: this.handleSubCategoryChange
                                    }}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={`${classes.ContainerRow} ${classes.Select}`}>
                        <Field name='documentType'>
                            {({ field, form }) => (
                                <ClaraSelect
                                    required={true}
                                    field={field}
                                    form={form}
                                    label={"Document Type: "}
                                    placeholder={"Search or select from a list..."}
                                    tooltip={tooltips['Document Type']}
                                    lists={this.getDocTypesList()}
                                    mode={"classic"}
                                    shouldClearValue={this.state.shouldClearDocTypeValue}
                                    callbacks={{
                                        updateValues: this.handleDocTypeChange
                                    }}
                                />
                            )}
                        </Field>
                    </div>
                    {this.state.isTeam ?
                        <React.Fragment>
                            <hr className={classes.Separator} />
                            <Field name='IPAssignmentProvisions'>
                                {({ field, form }) => (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Checkbox
                                            style={{ color: "blue" }}
                                            onChange={(e) => this.handleCheckboxChange(e.target.checked, field.name, form)}
                                        />
                                    <label>This agreement has an IP provision</label>
                                    </div>
                                )}
                            </Field>
                            <hr className={classes.Separator} />
                        </React.Fragment>
                        : null
                    }
                    <FieldArray
                        name='parties'
                        render={() => (
                            <div>
                                {this.state.parties.map(p => (p))}
                            </div>
                        )}
                    />
                    <hr className={classes.Separator} />
                    <div className={`${classes.ContainerRow}`}>
                        <Field name='party'>
                            {({ field, form }) => (
                                <AddInput
                                    label={"Add Party"}
                                    onClickAdd={this.addParty}
                                />
                            )}
                        </Field>
                    </div>
                    <hr className={classes.Separator} />
                    <div className={classes.ContainerRowAgreement}>
                        <Field name='executionDate'>
                            {({ field, form }) => (
                                <TextInput
                                    type='date'
                                    label='Execution Date (Optional):'
                                    field={field}
                                    form={form}
                                    placeholder={"Date"}
                                    tooltip={tooltips['Execution Date']}
                                    prevComponentChange = {e =>
                                        this.handleChange(e, field, form,false)
                                    }
                                >
                                </TextInput>
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRowAgreement}>
                        <Field name='expirationDate'>
                            {({ field, form }) => (
                                <TextInput
                                    type='date'
                                    label='Expiration Date (Optional):'
                                    field={field}
                                    form={form}
                                    placeholder={"Date"}
                                    tooltip={tooltips['Expiration Date']}
                                    prevComponentChange = {e =>
                                        this.handleChange(e, field, form,true)
                                    }
                                >
                                </TextInput>
                            )}
                        </Field>
                    </div>
                    <div className={`${classes.ContainerRow} ${classes.Select}`}>
                        <Field name='agreementStatus'>
                            {({ field, form }) => (
                                <input type={'hidden'} value={this.state.agreementStatus} />
                            )}
                        </Field>
                    </div>
                </div>
            </form>
        );
    }
}

export default Step1;

import React, {Component} from "react";
import {connect} from "react-redux";
import classes from "./ClaraSwitchButton.module.scss"

import Tooltip from '@material-ui/core/Tooltip';
import {Constants} from "../../../utils/constants";

class ClaraSwitchButton extends Component {
    state = {
        validation: {},
        label: null,
        errorMessage: null,
        showError: false,
        value: null,
        options: []
    };

    componentWillMount() {
        const {  validation, label,  name,value } = this.props;
        this.setState({
            validation,
            label,
            name,
            value,
        },function(){
            this.managerValidation(value);
        });

        
    }
  
    

    getError(type, validation) {
        validation = this.state.validation[type];
        if (validation.errorMessage) {
            return validation.errorMessage
        }
        return Constants.DEFAULT_MESSAGES.VALIDATION[type];
    }

    validate(value) {
        if (this.state.validation && this.state.validation.required) {
            if (this.state.validation.required.value === true && value!==true) {
                return this.getError("required")
            }
        }
        return null;
    }

    managerValidation(newValue) {
        
        let {  errorMessage } = this.state;
        
        errorMessage = this.validate(newValue);
    
        this.setState({
            value: newValue,
            errorMessage: errorMessage
        });
        this.props.updateData(this.state.name, newValue, errorMessage===null,this.props.index);
    }

    handleChange = () => {
        let {  value } = this.state;
        this.managerValidation(!value);
        
    };


    handleBlur = (e) => {
       
    };

    componentJSX_getTooltip() {
        if (this.state.tooltipText
        ) {
            return (
                <Tooltip title={this.state.tooltipText} aria-label={this.state.tooltipText}
                    placement="right"
                    classes={{ 
                        tooltip: classes.tooltip,
                        tooltipPlacementRight: classes.tooltipPlacementRight 
                    }}
                > 
                    <img src="/img/icons/tooltip.svg" alt="help" />
                </Tooltip>
            )
        }
        return null;
    }

    componentJSX_getErrorMessage() {
        if (this.state.errorMessage && this.state.showError) {
            return (
                <div className='errorClaraInput'>
                    {this.state.errorMessage}
                </div>
            )
        }
        return null;
    }
    componentJSX_getCheckbox() {
        let classSelected=null;
        if (this.state.value===true){
            classSelected = classes.SelectedBLUE;
        }
        return (
            <div className={`${classes.SwitchButton} `} >
                <a disabled={this.props.disabled} className={`${classSelected}`} onClick={(e)=>this.handleChange()}></a><label>{this.props.label}</label>
            </div>
        )
    }
    render() {
        return (
            <div className={classes.ClaraSwitchButton}>
                <div className={classes.contentSwitchButton}>
                    {this.componentJSX_getCheckbox()}
                </div>
                {this.componentJSX_getErrorMessage()}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    session: state.session
});
const mapDispatchToProps = (dispatch) => ({

});
export default connect(mapStateToProps, mapDispatchToProps)(ClaraSwitchButton)

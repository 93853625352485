import React, {Component} from "react";
import {connect} from "react-redux";

import FormikHelper from "../../../utils/formikHelper";
import FormControl from "@material-ui/core/es/FormControl/FormControl";
import Select from "@material-ui/core/es/Select/Select";

import classes from './ClaraInputSelect.module.scss';
import _ from "lodash"
import {ClaraSearch, TooltipInput} from "../../index"
import {Constants} from "../../../utils/constants";
// import Flag from 'react-world-flags';
import InputLabel from '@material-ui/core/InputLabel';

class ClaraInputSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            filter: null,
            inputSearch: null,
            isFocused: false,
            showTooltip: false,
            showError: false
        };
    }
    activeShowErrors = () => {
        this.setState({
            showError: true
        })
    }
    handleFocus = () => {
        this.setTooltipVisibility(true);
        this.setState({
            showError: true
        })
    }
    handleBlur = () => {
        this.setTooltipVisibility(false);
        this.setState({
            showError: true
        })
    }
    showError() {
        const { showError } = this.state;
        return showError
    }
    getError() {
        const { form, field } = this.props;
        return FormikHelper.returnErrorsForValidation(field.name, form.errors);

    }
    componentWillMount() {

        const { tooltipText, placeHolder, validation, label, defaultValue, name, value, modalTitle, search } = this.props;

        this.setState({
            tooltipText,
            placeHolder,
            validation,
            label,
            defaultValue,
            name,
            value: value ? value : {},
            search: search === true,
            modalTitle
        })
    }

    componentJSX_isRequired() {
        if (this.state.validation && this.state.validation.required && this.state.validation.required.value === true) {
            return <span className={`required`}>*</span>
        }
    }

    getList() {

        let list = null;
        let { filter } = this.state;
        if (this.props.updateListFunction) {
            list = this.props.updateListFunction(this.props.data);
        }
        else {
            list = this.props.list

        }
        if (filter && filter !== "") {
            return list.filter((item) => {
                let value = item;
                if (item.label) {
                    value = item.label;
                }
                return !this.isNull(value) && value.toLowerCase().indexOf(this.state.filter.toLowerCase()) >= 0
            });
        }
        return list;
    }

    isNull(value) {
        // check if some properties in the object are null
        if (typeof value === 'object' && value !== null) {
            return Object.values(value).some(x => (x === null));
        }

        return value === null;
    };

    renderItems() {
        return this.getList().map(item => {
            let label = item;
            let index = this.props.list.indexOf(item);
            let id = item;
            let code = Constants.COUNTRYCODES[item] || 'NSA';

            if (item.id) {
                id = item.id;
                label = item.label;
                index = id;
            }

            if (!this.isNull(id) && !this.isNull(label)) {
                if (this.props.areCountries) {
                    return (
                        <div  style={{paddingLeft:"1rem"}} key={index + label} value={id}>
                            {/*<Flag className={classes.flagsOnList} code={code} height='24' width='24' fallback={<span />} /><span className={classes.countryName}>{label}</span>*/}
                            <img className={classes.flag} src={`https://www.countryflags.io/${code}/shiny/32.png`} alt="Flag" />
                            <span className={classes.countryName} >{label}</span>
                        </div>
                    )
                } return (
                    <div classes={{ root: classes.menuItem }} className={classes.menuItem} key={index + label} value={id} >
                        {this.props.decorator ? this.props.decorator(item) : label}
                    </div>
                )
            } else {
                return null
            }
        });
    }

    handleChange = (e) => {
        if (this.props.callbackUpdate) {
            this.props.callbackUpdate(e.target.value);
        }
        const { form } = this.props;
        if (this.props.onChange) {
            this.props.onChange(e.target.value, form, this.props.extraParamsForChange);
        }
    };

    componentWillUpdate(nextProps, prevState, snapshot) {

        if (nextProps.value !== this.props.value) {

            this.setState({ value: nextProps.value })
        }

    }
    onChangeFilter = (value) => {
        this.setState({
            filter: value
        });
    }
    onEnteredMenu = (value) => {
        this.setState({
            filter: null
        }, () => {
            if (this.state.inputSearch) {
                this.state.inputSearch.focus();
            }
        })

    }
    setInput = (input) => {
        if (!this.state.inputSearch) {
            this.setState({
                inputSearch: input
            })
        }

    }

    hasError() {
        const { form, field } = this.props;
        return FormikHelper.returnErrorsForValidation(field.name, form.errors);
    }


    setTooltipVisibility = (isFocused) => {
        this.setState({
            isFocused,
        })
    };

    getMarginTop = () => {
        if (this.props.isEdit) {
            return '13px'
        }

        return '0px';
    };

    render() {
        const { value, open, modalTitle, search, } = this.state;
        const { tooltip, className, form, field } = this.props;
        const classStatusData = value === "" || _.isEmpty(value) ? 'inputEmpty' : 'inputFill';
        if (this.props.show === false) {
            return null;
        } else {
            return (
                <div className={`${classes.InputContainer} ${className}`} >
                    <div className={classes.ClaraInputList}>
                        <FormControl style={this.props.formControlStyle} className={classes.formControl}>
                            <label>{this.props.label || ""}{this.props.required && this.props.showRedStar !== false ?
                                <span style={{ color: 'red' }}>*</span> : null}</label>
                            <div>
                                <TooltipInput label={tooltip}>
                                    {!_.get(form.values, field.name) && <InputLabel style={{ position: "absolute", marginTop: this.getMarginTop(), marginLeft: "20px", fontSize: "13px" }} id="comboSelect">{this.props.placeholder || ""}</InputLabel>}
                                    <Select
                                        id={"comboSelect"}
                                        value={value}
                                        className={this.showError() && this.hasError() ? `${classes.select} ${classStatusData} ${classes.Error}` : `${classes.select} ${classStatusData}`}
                                        autoWidth={true}
                                        classes={{
                                            select: ` ${classes.selectInput}`
                                        }}
                                        MenuProps={{
                                            onEntered: this.onEnteredMenu
                                        }}
                                        onFocus={this.handleFocus}
                                        onBlur={this.handleBlur}
                                        {...this.props.field}

                                        onChange={(e) => {
                                            this.handleChange(e);
                                            this.props.field.onChange(e);
                                            this.activeShowErrors();
                                        }}
                                        onOpen={(e) => {
                                            this.activeShowErrors();
                                        }}
                                    >

                                        <div className={classes.SelecMenu} value="">
                                            {modalTitle ? <h2>{modalTitle}</h2> : null}
                                            {search ?
                                                <ClaraSearch setInput={this.setInput} placeHolder={`Search...`}
                                                    onChange={(this.onChangeFilter)} />
                                                : null
                                            }
                                        </div>
                                        {this.renderItems()}
                                    </Select>
                                    {this.showError() && this.hasError() ? (
                                        <div className={classes.MessageError}>
                                            {this.getError()}
                                        </div>
                                    ) : null}

                                </TooltipInput>
                            </div>
                        </FormControl>
                    </div>

                </div>
            );
        }
    }
}

const mapStateToProps = state => ({
    session: state.session
});
const mapDispatchToProps = (dispatch) => ({

});
export default connect(mapStateToProps, mapDispatchToProps)(ClaraInputSelect)

import Tab from "@material-ui/core/Tab";
import React from "react";

const TabMenu = ({
  children,
  classes,
  ...props
}) => {

  return (
    <Tab
      label={children}
      classes={classes}
      disableRipple={true}
      disableFocusRipple={true}
      {...props}
    />
  );
};

export default TabMenu;

import useLazyQuery from "src/hooks/custom/useLazyQuery";

const GET_SHARE_CAPITAL_VIEW = `
query getShareCapitalView($startupId: ID!, $groupCompanyId: ID!) {
  getShareCapitalView(startupId: $startupId, groupCompanyId: $groupCompanyId) {
    shareCapitalRow {
      shareClass {
        id
        name
        parValue {
          amount
          currency
        }
      }
      shareholders {
        issuedShares
        status
        isAddedToCart
        holder {
          ... on GroupCompany {
              id
              name
              avatar
          }
          ... on StakeHolder {
              id
              fullName
              avatar
          }
        }
      }
      total
      amountPaid {
        amount
        currency
      }
      amountUnpaid {
        amount
        currency
      }
    }
  }
}`;

export const useGetShareCapitalView = (variables, config) => {
  const { manualQuery, loading, error, data, refetch } = useLazyQuery(GET_SHARE_CAPITAL_VIEW, variables, config);
  return { loading, error, data, refetch, manualQuery };
};

export default useGetShareCapitalView;

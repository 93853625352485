import MicroFrontend from "../../../../../../../mf";
import React, { useCallback } from "react";
import { useParams } from "react-router";
import useNavigationCaptable from "../../../../../../../views/modules/startups/capTable/CapTable/useNavigationCaptable";
import Table from "src/components/Table/Table"
import _ from "lodash"
import classes from "./classes.module.scss";
import useFormat from "src/modules/generic/hooks/useFormat";
import FORMATS from "src/v1/utils/constants/formats";
import { Constants } from "../../../../../../../v1/utils";
import Link from "src/components/text/Link/Link"
import useNavigation from "src/modules/generic/hooks/useNavigation";
import EmptyState from "src/modules/generic/components/EmptyState";
import useTranslate from "src/modules/generic/hooks/useTranslate";
const EquityTab = ({ data, allData }) => {
  const params = useParams();
  const { translate } = useTranslate();
  const goTo = useNavigationCaptable();
  const { goTo: goToUi } = useNavigation()
  const { moneyFormat, numberFormat ,percentFormat} = useFormat()
  const groupCompanyId = params.id;

  const go = useCallback((v, params = {}) => {
    goTo(v, params);
  }, [goTo]);

  const isFormation = data === 'FORMATION';
  if (isFormation) {
    const shareholders = allData?.formationData?.sharesData?.shareholders ?? [];
    const sharesAuthorised = allData?.formationData?.sharesData?.sharesAuthorised ?? 0;
    let values = _.map(shareholders, (shareholder) => {
      const shareIssuances = shareholder?.shareIssuances ?? [];
      const shareIssuance = shareIssuances[0] ?? {};
      
      
      if (shareIssuance?.quantity) {
        return {
          id: shareholder?.shareholder?.id,
          name: shareholder?.shareholder?.fullName,
          isAnEntity: shareholder?.shareholder?.isAnEntity,
          percentage:  shareIssuance?.percentage,
          shares: shareIssuance?.quantity,
          amount: shareIssuance?.sharePrice,
        }
      }
    })

    const columns = [
      {
        Header: " ",
        columns: [
          {
            Header: () => <div className={classes.headerColumnTable} style={{ "width": "30%" }}>Stakeholder</div>,
            accessor: "name",
            Cell: ({ row }) => {

              return (
                <div className={classes.coltextLink} onClick={() => {
                  goToUi(Constants.PAGES.viewStakeholderIndividual, { id: row?.original?.id, tab: "details" })
                }}>
                  {row?.original?.name}

                </div>
              );
            },
          },
          {
            Header: () => <div className={classes.headerColumnTable} style={{ textAlign: "right" }}>Percentage</div>,
            accessor: "percentage",
            Cell: ({ row }) => {
              return (
                <div className={classes.coltext} style={{ textAlign: "right", width: "100%" }}>
                  {percentFormat(row?.original?.percentage, false, {decimalScale:6})}
                </div>
              );
            },
          },
          {
            Header: () => <div className={classes.headerColumnTable} style={{ textAlign: "right" }}>No. of shares</div>,
            accessor: "shares",
            Cell: ({ row }) => {
              return (
                <div className={classes.coltext} style={{ textAlign: "right", width: "100%" }}>
                  {numberFormat(row?.original?.shares, true)}
                </div>
              );
            },
          },
          {
            Header: () => <div className={classes.headerColumnTable} style={{ textAlign: "right" }}>Par value</div>,
            accessor: "amount",
            Cell: ({ row }) => {
              return (
                <div className={classes.coltext} style={{ textAlign: "right", width: "100%" }}>
                  {row?.original?.amount?.currency} {moneyFormat(row?.original?.amount?.amount, false, FORMATS.SHARE)}
                </div>
              );
            },
          }
        ],
      },
    ];
    if (_.isEmpty(values)) {
      return (<EmptyState
        title={"After you have added all your directors and shareholders, click on the <br/> Share capital task in the assistant to issue shares to your shareholders."}
        iconType={"SAFE"}
      />)
    }
    return (
      <div className={classes.containerTable}>
        <Table
          columns={columns}
          data={values}

        />
      </div>
    );
  }
  return (
    <MicroFrontend id="MF_Group_Company_Equity_Details" component={"./GroupCompanyEquity"} mf="capTable" params={{ go, groupCompanyId, isFormation }} />
  );
}

export default EquityTab;
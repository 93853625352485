import React, { FC, ReactNode } from "react";
import { FieldConfig } from "src/components/forms/modules/startups/hooks/useStakeholderFieldsAnalizer";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import ControllerInput, { ControllerInputProps } from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import { InputLabelWithBanner } from "../InputWithBanner";

interface AnalizerInputWithBannerProps {
  component: ReactNode,
  field: FieldConfig
  controllerInputProps: Omit<ControllerInputProps, 'render'> & { name: string }
}

export const AnalizerInputWithBanner: FC<AnalizerInputWithBannerProps> = ({ field, component, controllerInputProps }: AnalizerInputWithBannerProps) => {
  return <>
    {field?.variant === 'noTemplateLabel' ? (
      <TemplateLabel>
        <ControllerInput
          render={component}
          label={field.label}
          {...controllerInputProps}
          onChange={field.onChange}
          disabled={field.disabled}
        />
      </TemplateLabel>
    ) : 
    (
      <>
        {field?.show && field.hasPincas && (
          <InputLabelWithBanner
            bannerLogicType={field.bannerLogicType}
            component={component}
            controllerInputProps={{ ...controllerInputProps, onChange: field.onChange, disabled: field.disabled }}
            templateLabelProps={{
              bannerTitle: field.bannerTitle,
              label: field.label,
              isRequired: field.isRequired,
            }}
          />
        )}
        {field?.show && !field.hasPincas && (
          <TemplateLabel
            label={field.label}
            isRequired={field.isRequired}
          >
            <ControllerInput
              render={component}
              {...controllerInputProps}
              onChange={field.onChange}
              disabled={field.disabled}
            />
          </TemplateLabel>
        )}
      </>
    )}
  </>;
}
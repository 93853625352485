import Rol from './classes/Rol';


/* eslint-disable require-jsdoc */
const { useCallback } = require('react');


const useRolEntity = ({ rol }) => {
    
    const isRolWithCompanyInManagerByClaraWitRolDirectorOrShareholder = useCallback((_rol) => {
        const rolClass = new Rol(_rol || rol || {});
        return rolClass.isRolWithCompanyInManagerByClaraWitRolDirectorOrShareholder()
    }, [rol]);
    return { isRolWithCompanyInManagerByClaraWitRolDirectorOrShareholder };
};
export default useRolEntity;

import _ from "lodash";
import React, { useCallback } from 'react';
import { useDispatch } from "react-redux";
import useTextLang from "../../../../../../../hooks/custom/useTextLang";
import useDeleteDocument from "../../../../../../../hooks/services/modules/Document/useDeleteDocument";
import useSession from '../../../../../../../modules/session/hooks/useSession';
import { goToPage } from "../../../../../../../store/actions/page";
import { DeleteForm } from "../../../../../../../v1/components";
import { Constants } from "../../../../../../../v1/utils";

const DeleteDocumentModal = ({ goTo, redirect= true, open,onComplete, handleCloseModal, data,isUploaded=false, ...props }) => {
    
    const { startupId } = useSession();
    const title = useTextLang("DOCUMENT_UPLOAD_MODAL_DELETE_TITLE", { companyName: _.get(data, "name", '') }, '');
    const dispatch = useDispatch();
    

	const callbackBeforeEvict = useCallback(() => {
		return new Promise((resolve,reject)=>{
			if (redirect) {
				goTo ? goToPage(dispatch, goTo) : goToPage(dispatch, Constants.PAGES.MODULES.MAP.maps);
			}
			resolve(true)
		})
	},[dispatch, goTo, redirect])

	const { mutation: deleteGroupCompany, loading: deletingDocument } = useDeleteDocument({
		onCompleted: onComplete,
		successMessage: "MODAL_DELETE_FILE",
		paramsSuccessMessage: data,
		callbackBeforeEvict: callbackBeforeEvict
	});

    const deleteEntity = async () => {

        await deleteGroupCompany({
			startupId,
			agreementId: data.id
		});

    };

    return (

        <DeleteForm
            handleClose={handleCloseModal}
            show={open}
            deleteEntity={deleteEntity}
            showLoading={false}
            isLoading={deletingDocument}
            title={title}
            options={[
                { label: "Delete this document?" }
            ]
            }
            overlayName={title}
        />

    )
};

export default DeleteDocumentModal;

import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import ClaraAvatar from "./../ClaraAvatar";
import classes from "./Avatar.module.scss";
import classNames from 'classnames';

/**
 * Component to show avatars
 * 
 * Depletated: No use this component. Use Avatar
 * TODO:
 * 
 *  - View captable properties, and remove
 *  - remove showLabel, pointerLabel, label
 */

const AvatarLabel = ({ avatar, captable, name, showLabel, className, divStyle, label, pointerLabel = false, size, link,opacity }) => {

  const [styleLabel, setStyleLabel] = useState({});
  const [showLabelState, setShowLabelState] = useState(showLabel);

  useEffect(() => {
    setStyleLabel(pointerLabel ? { cursor: 'pointer' } : {});
  }, [pointerLabel]);

  useEffect(() => {
    let auxShowLabel = showLabel;
    if (auxShowLabel === undefined || auxShowLabel === null) {
      auxShowLabel = true;
    }
    setShowLabelState(auxShowLabel);
  }, [showLabel]);

  if (showLabel === undefined || showLabel === null) {
    showLabel = true;
  }

  return (
    <div
      className={`${className} ${classes.Avatar}`}
      style={divStyle}
    >
      <div className={`${className} ${classes.avatarPos}`}>
        <ClaraAvatar
          name={name}
          avatar={avatar}
          className={classNames(className,{[classes.opacity]:opacity})}
          size={size}
        />
      </div>
      {showLabelState ? <label className={classNames(classes.labelAvatar, { [classes.link]: link,[classes.opacity]:opacity })} style={captable ? { fontWeight: 'normal', ...styleLabel } : styleLabel}>{label ? label : name}</label> : null
      }
    </div >
  );
}

AvatarLabel.propTypes = {
  /**  Avatar img */
  avatar: PropTypes.string,
  /** Name to avatar */
  name: PropTypes.string,
  /** Show the name after avatar. If this property is falsse, use the new component Avatar (without label) */
  showLabel: PropTypes.bool,
  /** Size avatar */
  size: PropTypes.string,
  /** color text */
  link: PropTypes.bool
};

AvatarLabel.defaultProps = {
  showLabel: true,
  size: "2.46rem",
  link: false
};

export default AvatarLabel;

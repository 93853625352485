import { useMemo } from 'react';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import HasOperatingCompany from '../steps/HasOperatingCompany';
import TellUsAboutYourOperatingCompany from '../steps/TellUsAboutYourOperatingCompany';
import WhatDoesYourCompanyDo from '../steps/WhatDoesYourCompanyDo';

const useSteps = () => {
  return useMemo(() => ({
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    HasOperatingCompany: {
      render: HasOperatingCompany,
    },
    TellUsAboutYourOperatingCompany: {
      render: TellUsAboutYourOperatingCompany,
    },
    WhatDoesYourCompanyDo: {
      render: WhatDoesYourCompanyDo,
    },
    SaveStep: {
      render: TemplateWizardSkeletonMachine,
    },
  }), []);
}

export default useSteps;

import useMutation from "src/hooks/custom/useMutation";

export const UPSERT_RENEWAL_DATA = `
mutation upsertRenewalData(
  $startupId: ID!
  $upsertStartupId: String
  $groupCompanyId: ID!
  $groupCompanyUpsert: String
  $renewalData: RenewalDataInput
  $groupCompany: GroupCompanyInputType
) {
  upsertRenewalData(
    startupId: $startupId
    groupCompanyId: $groupCompanyId
    renewalData: $renewalData
  ) {
    id
  }
  upsertGroupCompany(
    startupId: $upsertStartupId
    groupCompanyId: $groupCompanyUpsert
    groupCompany: $groupCompany
  ) {
    id
  }
}
`;

const useUpdateEconomicSubstanceInformation = () => {
  const [mutation] = useMutation(
    UPSERT_RENEWAL_DATA,
    { showSuccessNotification: true }
  );
  return mutation;
};

export default useUpdateEconomicSubstanceInformation;

import {
  PEPTab,
  SourceOfFundsTab,
} from "src/components/modules/clara/startups/corporateStakeholder/Tabs";
import Button from "src/modules/generic/components/Buttons/Button";
import { Constants } from "src/v1/utils";
import DetailsTabTitle from "src/components/templates/DetailsTabTitle";
import Divider from "@mui/material/Divider";
import PEPModal from "src/modules/startup/Stakeholder/EntityStructures/ADGM/LimitedByShares/PEPModal";
import React from "react";
import { RoleAccess } from "src/v1/components";
import SourceOfFundsModal from "src/modules/startup/Stakeholder/EntityStructures/ADGM/LimitedByShares/SourceOfFundsModal";
import _ from "lodash";
import classes from "./styles.module.scss";
import useModal from "src/modules/generic/hooks/useModal";

const ComplianceTab = ({ data, isLock }) => {
  const { openModal, closeModal } = useModal();

  const pepButton = (
    <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
      <Button
        variant="card"
        disabled={isLock}
        onClick={() => {
          openModal(
            PEPModal,
            {
              handleCloseModal: closeModal,
              initialValues: {
                stakeholderId: data?.id,
                formValues: {
                  isPEP: _.get(data, "pep.isPEP", null),
                  descriptionOfPEP: _.get(data, "pep.descriptionOfPEP", ""),
                },
              },
            },
            "MODAL_PEP"
          );
        }}
      >
        Edit
      </Button>
    </RoleAccess>
  );

  const sofButton = (
    <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
      <Button
        variant="card"
        disabled={isLock}
        onClick={() => {
          openModal(
            SourceOfFundsModal,
            {
              initialValues: {
                stakeholderName: data?.fullName ?? "",
                stakeholderId: data?.id,
                formValues:
                  _.get(data, "sourcesOfFunds", []).length > 0
                    ? _.get(data, "sourcesOfFunds", [])[0]
                    : {},
              },
            },
            "MODAL_SOURCE_OF_FUNDS"
          );
        }}
      >
        Edit
      </Button>
    </RoleAccess>
  );

  return (
    <>
      <DetailsTabTitle title="PEP" buttons={pepButton} />
      {_.get(data, "pep.isPEP", null) !== null && (
        <PEPTab
          data={{
            isPEP: _.get(data, "pep.isPEP", null),
            descriptionOfPEP: _.get(data, "pep.descriptionOfPEP", ""),
          }}
        />
      )}
      {data?.sourcesOfFunds?.length > 0 && (
        <>
          {_.get(data, "pep.isPEP", null) !== null && (
            <Divider className={classes.divider} />
          )}
          <DetailsTabTitle title="Source of Funds" buttons={sofButton} />
          <SourceOfFundsTab data={_.get(data, "sourcesOfFunds", [])} />
        </>
      )}
    </>
  );
};

export default ComplianceTab;

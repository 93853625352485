import useBusinessActivityMapper from './useBusinessActivityMapper';
import useGetGroupCompanyBusinessActivities from "./graphql/useGetGroupCompany";
import useGetPINCAPrices from 'src/views/modules/startups/renewal/pincasPrice/queries/useGetPINCAPrices';
import useSession from "src/modules/session/hooks/useSession";
import useUpdateBusinessActivity from "./graphql/useUpdateBusinessActivity";
import useUploadDocument from './graphql/useUploadDocument';

const useServices = ({ initialValues }) => {
  const { groupCompanyId } = initialValues;
  const { startupId } = useSession();
  const { manualQuery: getGroupCompany } = useGetGroupCompanyBusinessActivities({ groupCompanyId, startupId }, {});
  const { getInitialValues, economicSubstanceMapper, getDocument } = useBusinessActivityMapper();
  const uploadDocuments = useUploadDocument();
  const mutation = useUpdateBusinessActivity();
  const { manualQuery: getPINCAPrices } = useGetPINCAPrices(
    {
      variables: {
        filters: {
          jurisdictionTypes: ['CAYMAN']
        }
      }
    }
  );

  const initialData = async () => {
    const { data } = await getGroupCompany();
    return { initialValues, ...getInitialValues(data?.getGroupCompany) };
  }

  const pincasData = async () => {
    const pincasData = await getPINCAPrices();
    return { pincasData };
  }

  const SaveBusinessActivity = async (context) => {
    const document = getDocument(context);
    if (document) {
      const documents = [document];
      const result = await uploadDocuments({ variables: { startupId, documents } });

      const variables = {
        groupCompanyId,
        startupId,
        groupCompany:{
        ...economicSubstanceMapper(context, result?.data?.uploadBulkDocuments[0])
        }
      };

      return await mutation({ variables });
    }

    const variables = {
      groupCompany: {
        ...economicSubstanceMapper(context)
      },
      groupCompanyId,
      startupId,
    };

    await mutation({ variables });
  }

  return { initialData, pincasData, SaveBusinessActivity };
}

export default useServices;

import React, { FC, useState } from 'react';
import ModalAssistant from 'src/modules/generic/components/Modal/ModalAssistant';
import useModal from 'src/modules/generic/hooks/useModal';
import { clearAssistatText as clearAssistantText } from 'src/modules/generic/store/action';
import Wizard from './wizard';

interface Props {
  open: boolean
  initialValues: any
}

/* To Call this modal execute: openModal( TechLicenceModal, {},'MODAL_TECH_LICENCE' ); */
const TechLicenceModal: FC<Props> = ({
  open: openParam = false,
  initialValues = {}
}) => {
  const [open, setOpen] = useState(openParam);
  const { closeModal: onClose } = useModal();

  const handleStopMachine = () => {
    onClose();
    setOpen(false);
    clearAssistantText();
  }

  const handleCancelMachine = () => {
    onClose();
    setOpen(false);
    clearAssistantText();
  }

  return (
    <ModalAssistant open={open} variant='assistance'>
      <Wizard initialValues={initialValues} onFinish={handleStopMachine} onCancel={handleCancelMachine} />
    </ModalAssistant>
  );
};

export default TechLicenceModal;

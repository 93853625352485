import useMutation from "../../../hooks/custom/useMutation";

const UPDATE_OPTION_SELECTED = `
mutation updateOptionSelected($id: ID!, $optionSelected: OptionSelectedInput!){
    updateOptionSelected(id: $id, optionSelected: $optionSelected){
      optionsSelected {
        startupId
        option
        value
      }
    }
  }
`

const useUpdateOptionSelected = (variables) => {

    const [mutation, loading] = useMutation(UPDATE_OPTION_SELECTED, {variables, showSuccessNotification: false});

    return { mutation, loading };
};
export default useUpdateOptionSelected;
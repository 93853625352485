const FileFragment=`
fragment FileFragment on FilePondType{
        id
        name
        size
}
`
const CountryFragment=`
fragment CountryFragment on CountryType{
      code
      alpha3
      name
}
`
const AddressFragment=`
${CountryFragment}
fragment AddressFragment on StartupAddress {
      street
      city
      state
      country{
      ...CountryFragment
      }
      zipCode
}
`
export {
    AddressFragment,
    CountryFragment,
    FileFragment
}

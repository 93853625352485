import useLazyQuery from "src/hooks/custom/useLazyQuery";

const GET_STAKEHOLDER = `
    query getStakeholder($startupId: ID!,$stakeholderId:ID!) {
        getStakeholder(startupId: $startupId,stakeholderId:$stakeholderId) {
            id
      fullName
      nationality {
        code
        }
      managedByClaraConnections{
        id
        name
        jurisdictionType
        statusOnMatters{
            isRenewal
            }
      }
        }
    }
`;
const useGetManagedByClaraConnectionsStakeholder = () => {
  const { manualQuery, loading, refetch, error } = useLazyQuery(GET_STAKEHOLDER);
  return { loading, error, refetch, manualQuery };
}
export default useGetManagedByClaraConnectionsStakeholder;

import Collapse from '@material-ui/core/Collapse';
import Zoom from '@material-ui/core/Zoom';
import classnames from 'classnames';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import classesModule from "./classes.module.scss";
import PropTypes from 'prop-types';
/**
 * Card content
 * Sketch status: https://www.sketch.com/s/84259da8-b2da-42b4-80a5-9446ee58353d/v/2xWRpl/a/kaLwAlP
 */
export const  STATUS_CARDS = {
    DEFAULT: "default",
    SUGGESTED: "suggested",
    SELECTED: "selected",
    INNACTIVE: "innactive"
}
const Card = forwardRef(({
    children, className, tabIndex, visible = true, onClick, showHoverEffect, status, id, onFinishAnimation }, ref) => {
    const [openCollapse, setOpenCollapse] = useState(false)
    const [openVisible, setOpenVisible] = useState(false)
    
    const inputRef = useRef();
    useImperativeHandle(ref, () => ({
        current: inputRef.current,
        focus: () => {
            inputRef.current.focus();
        }
    }));

    useEffect(() => {
        if (visible) {
            setOpenCollapse(true);
            setOpenVisible(true)
        } else {
            setOpenVisible(false)
            setOpenCollapse(false)
        }
    }, [visible]);
    const handleOnEnteredCollapse = () => {
        setOpenVisible(true)
    }
    const handleOnExitedVisible = () => {
        setOpenCollapse(false);
    }
    const handleClick = () => {
        onClick && onClick(status !== STATUS_CARDS.SELECTED);
    }
    const handledExited = () => {
        onFinishAnimation && onFinishAnimation(true);
    }
    const handledEntered = () => {
        onFinishAnimation && onFinishAnimation(false);
    }
    return (
        <React.Fragment>

            <div className={`${classesModule.CardContent}`}>
                <Collapse in={openCollapse} onEnter={handleOnEnteredCollapse} onExited={handledExited} onEntered={handledEntered}>
                    <Zoom in={openVisible} onExited={handleOnExitedVisible}>
                        <div ref={inputRef} id={id} tabIndex={tabIndex}
                            data-hidden={!openCollapse}
                            data-show_hover_effect={showHoverEffect}
                            data-suggested={status == STATUS_CARDS.SUGGESTED}
                            data-selected={status == STATUS_CARDS.SELECTED}
                            data-innactive={status == STATUS_CARDS.INNACTIVE}
                            className={classnames(`${classesModule.Card} ${className}`)}
                            onClick={handleClick}
                        >
                            {children}
                        </div>
                    </Zoom>
                </Collapse>
            </div>
        </React.Fragment >
    )
});
Card.propTypes = {
    /**  Id or Key to the component */
    id: PropTypes.string,
    /** Tab index */
    tabIndex: PropTypes.number,
    /** Visible Card */
    visible: PropTypes.bool,
    /** Show hover effect  */
    showHoverEffect: PropTypes.bool,
    /** Status Card */
    status: PropTypes.oneOf(Object.values(STATUS_CARDS))
};
Card.defaultProps = {
    tabIndex: -1,
    status: "default",
    showHoverEffect: false
};
export default Card;

import React, {Component} from "react";
import {connect} from "react-redux";
import classes from "./Guide.module.scss";

import {clearMessageChat, setInitialFlow} from '../../../store/actions/chatbot';
import {goToPage, goToPrevPage} from '../../../store/actions/page';
import _ from "lodash";

class Guide extends Component {

    constructor(props) {
        super(props);
        this.state = { activeStep: {}, activeQuestion: {} }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            activeStep: nextProps.activeStep,
            activeQuestion: nextProps.activeQuestion
        })

    }

    getStepClassName(step, i) {
        const { activeQuestion, activeStep: { code } } = this.state;

        if (step.code === code) {
            if (this.props.finished) {
                return `${classes.step} ${classes.completed}`
            } else {
                return `${classes.step} ${classes.active}`
            }
        } else if (this.isCompleted(i)) {
            return `${classes.step} ${classes.completed}`
        } else {
            return `${classes.step}`
        }
    }

    isCompleted(i) { // i+1 because the step's order starts at 1 :)
        return this.props.finished || (i + 1 < this.state.activeStep.order);
    }

    goBack(step, i) {
        if (this.isCompleted(i)) {
            this.props.goBack(step);
        }
    }
    submitPrevQuestion = (values, form, event) => {
        const { activeQuestion } = this.props;

        const key = _.get(activeQuestion, "previousAnswer");
        const data = _.get(activeQuestion, "data");
        if (key) {
            this.props.submitPrevQuestion({ ...key, data });
        }

    }
    showPrevButton() {
        return  !!(_.get(this.props, "activeQuestion.previousAnswer", false))
    }
    render() {

        return (
            <div className={classes.Guide}>
                <div className={classes.container}>
                    {this.showPrevButton(this.props.bot_message) &&
                        <React.Fragment>
                            <div className={classes.backButtonContent}>
                                <a
                                    disabled={!this.props.showChat || this.props.loading}
                                    className={classes.icon}
                                    variant='contained'
                                    onClick={this.submitPrevQuestion}
                                >
                                    <i className={"icon-right_arrow_nobody1"} />
                                </a>
                                <div>
                                    <p>Previous</p>
                                    <label>Use instead of browser back button</label>
                                </div>
                            </div>
                            <div className={classes.linePrevGuide} />
                        </React.Fragment>
                    }
                    {this.props.steps.map((step, i) => (
                        <div key={i} className={this.getStepClassName(step, i)} onClick={() => this.goBack(step, i)}>
                            <div className={classes.stepper}>
                                {this.isCompleted(i) ?
                                    <div className={classes.checked}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15 9L11.5 14L9 11.933" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                    :
                                    <div className={classes.circle} />}
                                <div className={classes.line} />
                            </div>
                            <div className={classes.content}>
                                {step.name}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        session: state.session
    };
};
const mapDispatchToProps = (dispatch) => ({
   
    setInitialFlow: () => setInitialFlow(dispatch),
    clearMessageChat: () => clearMessageChat(dispatch),
    goToPage: (page, params) => {
        goToPage(dispatch, page, params)
    },
    goToPrevPage: (reload, defaultPage, params) => {
        goToPrevPage(dispatch, reload, defaultPage, params)
    },

});

export default connect(mapStateToProps, mapDispatchToProps)(Guide);

import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { DeleteForm } from "../../../../../../v1/components";
import useTextLang from "../../../../../../hooks/custom/useTextLang";
import useActiveUser from "../../../../../../hooks/services/modules/user/useActiveUser";
import { goToPage } from "../../../../../../store/actions/page";
import { Constants } from "../../../../../../v1/utils/constants";
import useSession from '../../../../../../modules/session/hooks/useSession';
const DeleteProfileModal = ({ showModal,open, handleCloseModal }) => {
    
    const { user } = useSession();
    const title = useTextLang("MODAL_DELETE_PROFILE_TITLE", null, null);
    const option = useTextLang("MODAL_DELETE_PROFILE_OPTION_DELETE", {userName:user.fullName}, null);
    const warning = useTextLang("MODAL_DELETE_PROFILE_WARNING", {userName:user.fullName}, null);
    const dispatch = useDispatch();
    const onCompletedUpdate = ()=>{
        goToPage(dispatch, Constants.PAGES.logout);
    }
    const [desactiveUser, loadingDesactiveUser] = useActiveUser({ onCompleted: onCompletedUpdate });

    const deleteEntity = () => {
        return new Promise((resolve,reject) => {
            desactiveUser(user.id,false)
            resolve();
        });
        
    }

    return (

        <DeleteForm
            handleClose={handleCloseModal}
            show={showModal || open}
            deleteEntity={deleteEntity}
            showLoading={false}
            positionWarning={"bottom"}
            warning={warning}
            title={title}
            options={[{ label: option }]}
            overlayName={title}
        />

    )
};





export default DeleteProfileModal;

import React, { FC } from "react";
import classesModule from './SubscriptionFree.module.scss';
import TypographyLocal from "../../../generic/components/Texts/Typography";
import ScaleItemFree from "../ScaleItemFree";

/**
 * SubscriptionFree
 */

export interface SubscriptionFreeProps {
  /** Property saveUpTitle */
  saveUpTitle?: string,
  /** Property detailTitle */
  detailTitle?: string,
  /** Property detailSubtitle */
  detailSubtitle?: string,
  /** Property scaleItemList */
  scaleItemList?: any,
  /** Property columButtons */
  columTags?: any,
}

const SubscriptionFree: FC<SubscriptionFreeProps> = ({
  saveUpTitle,
  detailTitle,
  detailSubtitle,
  scaleItemList,
  columTags
}: SubscriptionFreeProps) => {

  return (
    <div className={` ${classesModule.SubscriptionFree} `}>
      {!!saveUpTitle && (
        <div className={` ${classesModule.cornerContainer} `}>
          <div className={` ${classesModule.cornerRounder} `}>
            <div className={` ${classesModule.cornerAngle} `}></div>
          </div>
          <div className={` ${classesModule.cornerDetail} `}>
            <TypographyLocal
              className={` ${classesModule.textCorner} `}
              color={"white"}
            >
              {saveUpTitle}
            </TypographyLocal>
          </div>
        </div>
      )}
      <div className={` ${classesModule.dataSpace} `}>
        <div className={` ${classesModule.titleSpace} `}>
          <TypographyLocal
            className={` ${classesModule.textTitle} `}
            color={"black"}
          >
            {detailTitle}
          </TypographyLocal>
          <TypographyLocal
            className={` ${classesModule.textSubTitle} `}
            color={"black"}
          >
            {detailSubtitle}
          </TypographyLocal>
        </div>
        <div className={` ${classesModule.bodyCard} `} >
          <div className={` ${classesModule.containerItem}`}>
            {scaleItemList.map((element, i) => {
              return <ScaleItemFree
                icon={element.icon}
                title={element.title}
                key={i}
              ></ScaleItemFree>
            })}
          </div>
          <div className={classesModule.tagsContainer} >
            {columTags.map((element, i) => {
              return <div className={classesModule.tagOnly} >
                <span
                  key={i}
                >
                  {element}
                </span>
              </div>
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionFree;
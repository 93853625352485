import React from 'react';
import useLoading from "../../../../../modules/generic/hooks/useLoading";
import CorporateStakeholderView from "./CorporateStakeholderView"
import CorporateStakeholderViewSkeleton from "./CorporateStakeholderViewSkeleton";

const CorporateStakeholderPage =({...props  })=> {

  const {isLoading} = useLoading();

  return (
    <React.Fragment>
      <CorporateStakeholderViewSkeleton
        isLoading={isLoading}
      >
      </CorporateStakeholderViewSkeleton>
      <CorporateStakeholderView {...props}/>
    </React.Fragment>
  );
}

export default CorporateStakeholderPage;
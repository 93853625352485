import React from "react";
import PAGES from "../../../constants/pages";
import ClaraLayout from "../../../layouts/Clara/ClaraLayout";
import AssistanceStartupProfileV4 from "../../../modules/assistanceV4/components/AssistanceFrame2/assistanceStartupProfile";
import Text from "./../../../components/text/Text/Text";
import subModuleAccelerators from "./accelerators";
// import subModuleCapTable from "./capTable";
import subModuleCheckout from "./checkout";
import subModuleCompany from "./company";
import subModuleDocuments from "./documents";
import subModuleHealthcheck from "./healthcheck";
import subModuleMessages from "./messages";
import subModuleSubscriptions from "./subscriptions";
import subModuleInvoices from "./invoices";
import subModuleDashboard from "./dashboard";
import subModuleMyTasks from "./myTasks";
import subModuleShareManagement from "./shareManagement";
import subModuleStakeholder from "./stakeholder";
import StartupView from "./startup/startupView/StartupView";
// import StartupListView from "./StartupList/StartupListView";
import myProfilesRouter from "src/views/modules/startups/myProfiles";

const routes = [
  ...myProfilesRouter,
  {
    path: PAGES.viewStartup.path,
    exact: true,
    breadCrumbs: {
      reset: true,
      add: true,
      label: (
        <Text uuid={"BREADCRUMS_VIEW_STARTUP"} params={{ fullName: "" }} />
      ),
    },
    component: <StartupView />,
    layout: ClaraLayout,
    propsLayout: {
      startupMenu: true,
      userMenu: true,
      fullMenu: true,
      showAssistance: true,
      cantBack: true,
      showBreadCrumbs: true,
      disableClosedAssistance: true,
      assistance: AssistanceStartupProfileV4,
    },

  },
  ...subModuleAccelerators,
  ...subModuleCompany,
  ...subModuleHealthcheck,
  ...subModuleDocuments,
  ...subModuleStakeholder,
  // ...subModuleCapTable,
  ...subModuleShareManagement,
  ...subModuleMessages,
  ...subModuleSubscriptions,
  ...subModuleInvoices,
  ...subModuleDashboard,
  ...subModuleMyTasks,  
  ...subModuleCheckout,
];

export default routes;

export default {
  FIRST_STEP:{
    TOOLTIP: "<p>Check that all details are correct before selecting 'Approve'.</p><p>&nbsp;</p><p>Spotted an issue? Select 'Decline'. Clara will ask you to give details next.</p>",
  },
  SECOND_STEP: {
    TITLE: "Decline this document?",
    SUBTITLE: "Document review",
    DECLINE_HIT: "Add your reason for declining this document. Clara will be in touch to help solve the issue.",
    DECLINE_BTN: "Decline",
    APPROVE_BTN: "Approve",
  }
}
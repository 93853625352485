import React, {Component} from "react";
import {connect} from "react-redux";
import {DeleteForm, Loading} from "../../index";
import ServerConnect from '../../../utils/ServerConnect';
import { Constants } from './../../../utils';
import {deleteIssueQuery as eliminate} from '../../../graphql/mutations/addIssues';
import { goToPage } from '../../../../store/actions/page';
class DueDiligenceDeleteForm extends Component {
    state = {
        data:{},
        showLoading: false,
        options:[],
    }
    /* Particular data */
    getTitle(){
        const data =this.state.data ;
        return "Delete Remark";
    }
    setOptions(data){
        let options=[];
        options.push({label:"Delete this remark?"});
        this.setState({
            options
        })
    }
    hasAllData(){
        return true;
    }
    /*  Call Server*/
    callServerToGetData = () => {
        this.showLoading(true);
        //Get data if exist this posibility
    };
    deleteEntity=()=>{
        return new Promise((resolve, reject) => {
            const startup = this.props.session.startup;
            var  data={
                startupId: this.props.session.startup.id,
                issueId:this.state.data.id
            };
            this.showLoading(true);
            ServerConnect.graphQlMutation(eliminate, data).then((result) => {
                // if (this.props.data.urgency !== "UNKNOWN") {
                //     startup.statistic.issues[this.props.data.urgency]--;
                // }
                this.showLoading(false);
                this.props.goToPage(Constants.PAGES.healthCheck)
                resolve();
            }).catch(err=>{
                reject(err)
            });
        });
    }
    /* Change status */
    showLoading =(status)=>{
        this.setState({
            showLoading: status
        });
    }
    /* REac functions */
    componentWillMount(){
        if (this.hasAllData()){
            this.setState({
                data:this.props.data
            },()=>{
                this.setOptions();
            })
            
            
        }else{
            this.callServerToGetData();
        }
    }
   
   
    render() {
        
        return (
            <Loading show={this.state.showLoading}>
                <DeleteForm show={this.props.show} handleClose={this.props.handleClose}  deleteEntity={this.deleteEntity} showLoading={this.showLoading}  title={this.getTitle()} options={this.state.options}></DeleteForm>
            </Loading>
        )
    }
}

const mapStateToProps = state => ({
    session: state.session,
    form:state.form
});

const mapDispatchToProps = (dispatch) => ({
  
    goToPage: (page, params) => {
        goToPage(dispatch, page, params)
    },
   
});
export default connect(mapStateToProps, mapDispatchToProps)(DueDiligenceDeleteForm)

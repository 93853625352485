import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from "react";
import ButtonIcon from "./../../../../../modules/generic/components/Buttons/ButtonIcon";
import Card, { STATUS_CARDS } from "../../../../generic/components/Contents/Card"
import classesModule from "./classes.module.scss";
import PropTypes from 'prop-types';
import classnames from "classnames";
import HeaderCardStakeholder from "./subcomponents/HeaderCardStakeholder/HeaderCardStakeholder";
import HeaderCardStakeholderSkeletor from "./subcomponents/HeaderCardStakeholder/HeaderCardStakeholderSkeletor";
import Stakeholder from "src/components/modules/clara/startups/entities/classes/Stakeholder";
/** 
* Stakeholder Card
*
*  - Sketch Padding: https://www.sketch.com/s/84259da8-b2da-42b4-80a5-9446ee58353d/a/DP8nwwk
*  - Sketch Fonts: https://www.sketch.com/s/84259da8-b2da-42b4-80a5-9446ee58353d/a/ZO4pdvG
*  - New Sketch: https://www.sketch.com/s/84259da8-b2da-42b4-80a5-9446ee58353d/a/kamln9D
*/

const StakeholderCard = forwardRef(({ id, tabIndex, onClick, status, onExpandCard, stakeholder, visible, lock, ...props }, ref) => {

  const inputRef = useRef();
  useImperativeHandle(ref, () => ({
    current: inputRef.current,
    focus: () => {
      inputRef.current.focus();
    }
  }));
  const handleFilters = useCallback(() => {
    if (onClick) {
      onClick(onClick && onClick(status !== STATUS_CARDS.SELECTED))
    }
  }, [onClick, status])

  const isDotter = useMemo(() => {
    const shClass = new Stakeholder(stakeholder)
    return shClass.isRolInPincas("DIRECTOR")
  }, [stakeholder])

  if (!stakeholder) {
    return (
      <Card className={classnames(classesModule.StakeholderCard)}>
        <HeaderCardStakeholderSkeletor />
      </Card>
    )
  }

  return (
    <Card
      key={id}
      id={id}
      ref={inputRef}
      tabIndex={0}
      className={classnames(classesModule.StakeholderCard,
        { [classesModule.borderDash]: isDotter }
      )}
      status={status}
      showHoverEffect={true}
      visible={visible}
      onFinishAnimation={onExpandCard}
    >
      <ButtonIcon className={classesModule.iconFilter} variant={'secondary'} icon={"Map-lines"} onClick={handleFilters} />
      <HeaderCardStakeholder
        stakeholderName={stakeholder.fullName}
        subtitle={stakeholder.subtitle}
        avatar={stakeholder.avatar}
        tasks={stakeholder.tasks}
        peoples={stakeholder.peoples}
        people={stakeholder.people}
        level={stakeholder.level}
        lock={lock}
        stakeholder={stakeholder}
      />
    </Card>
  )
});

StakeholderCard.propTypes = {
  /**  Id or Key to the component */
  id: PropTypes.string,
  /** Tab index */
  tabIndex: PropTypes.number,
  /** Status Card */
  status: PropTypes.oneOf(["default", "suggested", "selected", "innactive"]),
  /** stakeholder Data */
  stakeholder: PropTypes.object,
  /** Visible Card */
  visible: PropTypes.bool,
  /** Lock Card */
  lock: PropTypes.bool,
};

StakeholderCard.defaultProps = {
  tabIndex: 0,
  status: "default",
  visible: true,
  lock: false,
};

export default StakeholderCard;
import React, { useEffect, useMemo, useState } from 'react';
import Button from "src/modules/generic/components/Buttons/Button";
import { Constants } from "src/v1/utils/constants";
import DetailsTabTitle from "src/components/templates/DetailsTabTitle";
import Divider from "@mui/material/Divider";
import EditAddressesCayman from "src/modules/startup/GroupCompany/KY/EditAddressesModal";
import GroupCompany from 'src/components/modules/clara/startups/entities/classes/GroupCompany';
import PincasIcon from "src/modules/generic/components/Icon/PincasIcon";
import PropTypes from "prop-types"
import { RoleAccess } from "src/v1/components";
import TwoColumnsViewer from "src/modules/generic/components/TwoColumsViewer";
import _ from "lodash";
import classes from "../../styles.module.scss";
import useModal from "src/modules/generic/hooks/useModal";
import useTranslate from "src/modules/generic/hooks/useTranslate";

function AddressesPanelCayman({ data, isLock }) {
  const { translate } = useTranslate();
  const { openModal, closeModal } = useModal();
  const [itemsColumn1, setItemsColumn1] = useState([]);
  const [itemsColumn2, setItemsColumn2] = useState([]);

  const buttonsEditAddresses = <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
    <Button
      variant="card"
      disabled={isLock}
      children={translate("VIEW_COMPANY_TAB_BUTTON_DETAILS_EDIT")}
      onClick={() => {
        openModal(EditAddressesCayman, {
          handleCloseModal: closeModal,
          initialValues: { groupCompanyId: data?.id },
        });
      }}
    />
  </RoleAccess>;

  const groupCompanyClass = useMemo(() => {
    if (!data) return null;
    return new GroupCompany(data);
  }, [data]);

  useEffect(() => {
    const column1 = [];
    const column2 = [];

    column1.push({
      label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_REGISTERED_ADDRESS"),
      value: <>
        <div>{_.get(data, "registeredAddress.street")}</div>
        <div>{_.get(data, "registeredAddress.city")}</div>
        <div>{_.get(data, "registeredAddress.state")}</div>
        <div>{_.get(data, "registeredAddress.zipCode")}</div>
        <div>{_.get(data, "registeredAddress.country.name")}</div>
      </>,
      valuePrefix: groupCompanyClass?.getFieldInMatter("registeredAddress") ? <PincasIcon /> : null,
      disabledField: groupCompanyClass?.getFieldInMatter("registeredAddress")
    });

    column2.push({
      label: "Mailing address type",
      value: data?.mailingAddress?.type ? Constants.COMPANIES.MAILING_ADDRESS_TYPE_LABEL[data?.mailingAddress?.type] : null

    });
    column2.push({
      label: "Mailing address",
      value: <>
        <div>{_.get(data, "mailingAddress.street")}</div>
        <div>{_.get(data, "mailingAddress.city")}</div>
        <div>{_.get(data, "mailingAddress.state")}</div>
        <div>{_.get(data, "mailingAddress.zipCode")}</div>
        <div>{_.get(data, "mailingAddress.country.name")}</div>
      </>
    });

    setItemsColumn1(column1);
    setItemsColumn2(column2);
  }, [data]);

  return (
    <>
      <Divider className={classes.divider} />
      <DetailsTabTitle title="Addresses" buttons={buttonsEditAddresses} />
      <TwoColumnsViewer
        itemsCol1={itemsColumn1}
        itemsCol2={itemsColumn2}
      />
    </>
  );
}

AddressesPanelCayman.propTypes = {
  data: PropTypes.any,
  isLock: PropTypes.bool,
  isFormation: PropTypes.bool,
};

export default AddressesPanelCayman;
import EventEmitter from 'events';

window.myEmitter = new EventEmitter();
/*
  Function to manager events in all aplplication
  web: https://nodejs.org/api/events.html
  Example: 
    Emmit: <button onClick={(e)=>{event.emmit("TEST",{param1:1})}}>Button</button>
    Listener:  event.on("TEST",(params)=>{
            alert("TEST")
        })
*/

/*
  Create listener event 
  params: 
    event_key : event name
    callback : function to excute after event (this function has only a parameter (JSON Type))

  Note: please, no call more one time the event in the same component (use componentWillMount)
*/
const on = (event_key, callback) => {
  window.myEmitter.on(event_key, (params) => {
    callback(params);
  })
}
/*
  Emmit event 
  params: 
    event_key : event name
    params : params to use in the callback function
*/
const emmit = (event_key, params) => {
  window.myEmitter.emit(event_key, params);
}
/*
  Remove listener
   params: 
    event_key : event name
    callback : function to excute after destroy event )

  Note: call allways this method in the componentUnmount, if the component is unmounted the event continue with life
*/
const remove = (event_key, callback) => {
  const listeners = window?.myEmitter?.listeners(event_key);
  
  if (listeners?.length > 0) {
    delete window.myEmitter._events[event_key]
  }
  
}
export default {
  on,
  emmit,
  remove
}

import React,{FC} from 'react'
import IsThereEquity from "../../../forms/IsThereEquity";
import Button from "../../../../../generic/components/Buttons/Button";
import useCustomMachine from "../../../../../generic/hooks/useCustomMachine";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import useTranslate from "../../../../../generic/hooks/useTranslate";
type FirstStepSubmit = (values:object) => Promise<void> | void
interface FirstStepProps {
    type: string
    tooltips?: object
    onCompleteSubmit?: FirstStepSubmit
}
const FirstStep:FC<FirstStepProps>=({type,tooltips,onCompleteSubmit})=>{
    const {send,next,stepData}=useCustomMachine()
    const {translate}=useTranslate()
    const handleCancel=()=>{
        send('CANCEL')
    }
    const handleCompleteSubmit = async (values: object) => {
        onCompleteSubmit && await onCompleteSubmit(values)
        next(values);
    }
    return(<>
        <IsThereEquity
                tooltips={tooltips}
                initialValues={stepData}
                propsTemplate={{
                title: translate('MODULES.EQUITY.STEPS.FIRST_STEP.TITLE',{type:translate(`MODULES.EQUITY.TYPES_PLURAL.${type}`)}),
                subTitle: translate('MODULES.EQUITY.STEPS.FIRST_STEP.SUBTITLE',{type:translate(`MODULES.EQUITY.TYPES_PLURAL.${type}`)})
            }}
                type={type}
                onCompleteSubmit={handleCompleteSubmit}
                Template={TemplateWizard}
                buttons={{cancel: <Button   onClick={handleCancel}>Cancel</Button>}}/>
    </>)
}
export default FirstStep
import React from "react";
import TypographyLocal from "../../../../../generic/components/Texts/Typography";
import cls from "./classes.module.scss";

const LeftContentBlock = ({ children, title = "" }) => {
  return (
    <div className={cls.container}>
      <TypographyLocal color="dark-grey" variant="body">
        {title}
      </TypographyLocal>
      <div className={cls.containerIlustration}>{children}</div>
    </div>
  );
};

export default LeftContentBlock;

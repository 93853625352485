import React, { useEffect, useMemo, useState } from "react";
import {
  EquityTab,
  RolesTab,
} from "../../../../../components/modules/clara/startups/individualStakeholder/Tabs";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ModalCategoriseDocument from "src/components/modules/clara/startups/Document/Modals/CategoriseDocument";
import Stakeholder from "src/components/modules/clara/startups/entities/classes/Stakeholder";
import PincasIcon from "src/modules/generic/components/Icon/PincasIcon";
import AddRole from "src/modules/startup/Stakeholder/AddRole";
import useUpdateStakeholder from "src/modules/startup/onBoarding/core/graphql/mutations/useUpdateStakeholder";
import { RoleAccess } from "src/v1/components";
import DetailsTab from "src/views/modules/startups/stakeholder/corporateStakeholderView/components/DetailsTab";
import EditNoticesModal from "../../../../../components/modules/clara/startups/individualStakeholder/Modals/EditNotices/EditNotices";
import Details from "../../../../../components/templates/Details/Details";
import Text from "../../../../../components/text/Text/Text";
import useTextLang from "../../../../../hooks/custom/useTextLang";
import useIndividualStakeholderData from "../../../../../hooks/services/modules/startups/individual/useIndividualStakeholderData";
import Button from "../../../../../modules/generic/components/Buttons/Button";
import useModal from "../../../../../modules/generic/hooks/useModal";
import useTranslate from "../../../../../modules/generic/hooks/useTranslate";
import useSession from "../../../../../modules/session/hooks/useSession";
import DeleteStakeholder from "../../../../../modules/startup/Stakeholder/Delete/index.tsx";
import CompanyDirectorsModal from "../../../../../modules/startup/Stakeholder/EntityStructures/ADGM/LimitedByShares/CompanyDirectorsModal";
import { updateParamsBreadCrumbs } from "../../../../../store/actions/page";
import { Constants, ServerConnect } from "../../../../../v1/utils";
import ComplianceTab from "./components/Tabs/ADGM/ComplianceTab/ComplianceTab";
import KeyPeopleTab from "./components/Tabs/KeyPeople";
import useLoading from "src/modules/generic/hooks/useLoading";
import DocumentsTab from "src/components/modules/clara/startups/corporateStakeholder/Tabs/DocumentsTab/DocumentsTab";

const CorporateStakeholderView = () => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const { openModal, closeModal } = useModal();
  const { session } = useSession();
  const params = useParams();
  const { data: userData } = useIndividualStakeholderData({
    startupId: session.startup.id,
    stakeholderId: params.id,
  });
  const [user, setUser] = useState(null);
  const [investor, setInvestor] = useState(null);
  const [posLabel, setPosLabel] = useState(null);
  const [teamRoleAndInvestor, setTeamRoleAndInvestor] = useState(null);
  const [mainCompany, setMainCompany] = useState(null);
  const [avatar, setAvatar] = useState();
  const isLock = useMemo(() => _.get(user, "isLocked", false), [user]);
  const handleCloseForm = () => dispatch({ type: "ASSISTANCE_CLOSE_FORM" });
  const { isLoading } = useLoading();

  const {
    updateStakeholder: upsertIndividualStakeholder,
  } = useUpdateStakeholder({ onCompleted: handleCloseForm });

  const stakeholderClass = useMemo(() => {
    if (!user) return null;
    return new Stakeholder(user);
  }, [user]);

  const jurisdictions = useMemo(() => {
    if (!user) return null;
    return stakeholderClass.getJurisdictions();
  }, [user]);

  const isManagedByClara = useMemo(() => {
    if (!user) return false;
    return stakeholderClass.getIsManagedByClara();
  }, [user]);

  const handleChangeAvatar = async (value) => {
    const response = await ServerConnect.requestApi(
      `/avatars/startups/${session.startup.id}/stakeholders/${params.id}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          avatarData: value
        })
      },
      null,
      { showNotification: false }
    )
    if (response) {
      const newAvatar = response?.url ?? value;
      await setAvatar(newAvatar);
    }
  };

  const getMainCompany = () => {
    let companies = [];
    _.forEach(session.groupCompanies, (comp) => {
      if (
        _.find(_.get(userData, "getStakeholder.roles"), (rol) => {
          return comp.id === rol.groupCompany.id;
        })
      ) {
        companies.push(comp);
      }
    });
    _.find(companies, (comp) => {
      if (comp.isHoldingCompany) {
        setMainCompany(comp);
      } else {
        setMainCompany(companies[0]);
      }
    });
  };

  useEffect(() => {
    setUser(_.get(userData, "getStakeholder"));
    getMainCompany();
    getPositionLabel();
    checkIfInvestor(_.get(userData, "getStakeholder.roles"));
  }, [JSON.stringify(userData)]);

  useEffect(() => {
    checkIfInvestor(_.get(userData, "getStakeholder.roles"));
  }, [mainCompany]);

  useEffect(() => {
    updateParamsBreadCrumbs(dispatch, { fullName: _.get(user, "fullName") });
  }, [_.get(user, "fullName")]);

  useEffect(() => {
    getPositionLabel();
  }, [_.get(user, "groupCompanyPositions")]);

  const dataRoles = useMemo(() => {
    let roles = _.map(_.get(user, "roles", []), (role) => {
      return {
        ...role,
        inProgress: false,
      };
    });
    let rolesInProgress = _.map(
      _.get(user, "rolesInProgress.toAdd", []),
      (role) => {
        return {
          ...role,
          inProgress: true,
        };
      }
    );

    _.forEach(_.get(user, "rolesInProgress.toDelete", []), (role) => {
      _.remove(roles, (r) => r.name === role.name && r.entity.id === role.entity.id);
    });

    _.forEach(_.get(user, "rolesInProgress.toDelete", []), (role) => {
      _.remove(roles, (r) => r.name === role.name && r.entity.id === role.entity.id);
      rolesInProgress.push({
        ...role,
        inProgress: true,
      });
    });

    return _.concat(roles, rolesInProgress);
  }, [user]);

  let contents = [
    {
      show: true,
      component: (
        <DetailsTab
          data={user}
          isLock={isLock}
          jurisdictions={jurisdictions}
          isManagedByClara={isManagedByClara}
        />
      ),
    },
    {
      show: true,
      component: <RolesTab data={dataRoles} lock={isLock} />,
    },
    {
      show: true,
      component: <DocumentsTab />,
    },
    {
      show: true,
      component: <EquityTab />,
    },
    {
      show: true,
      component: (
        <KeyPeopleTab
          data={user}
          isLock={isLock}
          jurisdictions={jurisdictions}
        />
      ),
    },
    {
      show: true,
      component: (
        <ComplianceTab
          data={user}
          isLock={isLock}
          jurisdictions={jurisdictions}
        />
      ),
    },
  ];

  const tabs = [
    {
      show: true,
      disabled: false,
      tabName: "Details",
      tabKey: "DETAILS",
      key: "details",
    },
    {
      show: true,
      disabled: false,
      component: (
        <label>
          <Text uuid={"ROLES"} />
        </label>
      ),
      tabName: useTextLang("ROLES"),
      tabKey: "ROLES",
      key: "roles",
    },
    {
      show: true,
      disabled: false,
      component: (
        <label>
          <Text uuid={"DOCUMENTS"} />
        </label>
      ),
      tabName: useTextLang("DOCUMENTS"),
      tabKey: "DOCUMENTS",
    },
    {
      show: true,
      disabled: false,
      component: (
        <label>
          <Text uuid={"EQUITY"} />
        </label>
      ),
      tabName: useTextLang("EQUITY"),
      tabKey: "EQUITY",
      key: "EQUITY",
    },
    {
      show: true,
      disabled: false,
      component: (
        <label>
          <Text uuid={"KEYPEOPLE"} />
        </label>
      ),
      tabName: useTextLang("KEYPEOPLE"),
      tabKey: "KEYPEOPLE",
      key: "keypeople",
    },
    {
      show:
        (_.includes(jurisdictions, "ADGM") &&
          _.get(user, "pep.isPEP", null) !== null) ||
        _.get(user, "sourcesOfFunds", []).length > 0,
      disabled: false,
      component: "",
      tabName: useTextLang("Compliance"),
      tabKey: "COMPLIANCE",
      key: "Compliance",
    },
  ];

  const getTabs = () => {
    let filteredTabs = [];
    _.forEach(tabs, (tab) => {
      if (tab.show) {
        filteredTabs.push(tab);
      }
    });
    return filteredTabs;
  };

  const getPositionLabel = () => {
    if (_.get(user, "groupCompanyPositions.length") >= 1) {
      let posName = "";
      _.find(_.get(user, "groupCompanyPositions"), (pos) => {
        if (pos.position) {
          posName = _.get(pos, "position.name");
          setPosLabel(posName);
          return posName;
        } else {
          setPosLabel(null);
        }
      });
    }
  };

  const getTabContents = () => {
    let filteredContents = [];
    _.forEach(contents, (content) => {
      if (content.show) {
        filteredContents.push(content.component);
      }
    });
    return filteredContents;
  };

  const checkIfInvestor = (roles) => {
    // TODO Review for Stakeholder Corporate
    let isInvestor = _.some(
      roles,
      (role) =>
        role.name === "INVESTOR" && role.entity.id === _.get(mainCompany, "id")
    );
    let anotherRoles = null;
    _.forEach(roles, (role) => {
      if (_.get(role, "entity.id") === _.get(mainCompany, "id")) {
        if (
          _.includes(Constants.ROLES_GROUPS.TEAM, role.name) &&
          role.name !== "INVESTOR"
        ) {
          anotherRoles = true;
        } else if (
          _.includes(Constants.ROLES_GROUPS.GOVERNANCE, role.name) &&
          role.name !== "SHAREHOLDER"
        ) {
          anotherRoles = true;
        } else if (_.includes(Constants.ROLES_GROUPS.IP, role.name)) {
          anotherRoles = true;
        }
      }
      setTeamRoleAndInvestor(isInvestor && anotherRoles);
      return setInvestor(isInvestor && !anotherRoles);
    });
  };

  const categorizeFile = () => {
    openModal(
      ModalCategoriseDocument,
      {
        isCategorize: false,
        isEdit: false,
        isVisaOrPassportDocument: false,
      },
      "MODAL_CATEGORISE_DOCUMENT"
    );
  };

  const showInactiveTag = useMemo(() => {
    let result = false;
    if (!_.isEmpty(user)) {
      const allRoles = _.get(user, "roles", []);
      const isInactive = _.every(allRoles, {'isActive': false});
      if (user?.isFounder === false) {
        result = isInactive;
      }
    }
    return result;
  }, [user]);

  return (
    <>
      {!isLoading && (
        <Details
          showIsInactive={showInactiveTag}
          lastUpdated={_.get(user, "updatedAt")}
          lock={_.get(user, "isLocked", false)}
          collapseButton={true}
          title={_.get(user, "fullName")}
          avatarName={_.get(user, "fullName")}
          refresh={JSON.stringify(investor)}
          refreshProp={JSON.stringify(posLabel)}
          avatarImg={_.get(user, "avatar")}
          onChangeAvatar={handleChangeAvatar}
          additionalTitle={
            <>
              {stakeholderClass?.getFieldInMatter("fullName") ? (
                <PincasIcon></PincasIcon>
              ) : (
                ""
              )}
            </>
          }
          buttonsTabs={{
            notices: [
              <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
                <Button
                  variant="card"
                  disabled={isLock}
                  onClick={() => {
                    openModal(EditNoticesModal, { handleCloseModal: closeModal });
                  }}
                >
                  {translate("VIEW_COMPANY_TAB_BUTTON_DETAILS_EDIT")}
                </Button>
              </RoleAccess>,
            ],
            directors: [
              <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
                <Button
                  variant="card"
                  disabled={isLock}
                  onClick={() => {
                    openModal(
                      CompanyDirectorsModal,
                      {
                        initialValues: {
                          stakeholderId: user?.id || params?.id,
                          stakeholderName: user?.fullName ?? "",
                          directors: _.get(user, "directors", []),
                        },
                      },
                      "MODAL_COMPANY_DIRECTORS"
                    );
                  }}
                >
                  Edit
                </Button>
              </RoleAccess>,
            ],
          }}
          buttons={[
            <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
              <Button
                variant="card"
                disabled={isLock}
                onClick={() => {
                  openModal(AddRole, { handleCloseModal: closeModal });
                }}
              >
                {translate("VIEW_ROLES_DEATILS_BUTTON_ADD_ROLE")}
              </Button>
            </RoleAccess>,
            <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
              <Button variant="card" disabled={isLock} onClick={categorizeFile}>
                {translate("STAKEHOLDER_VIEW_BUTTON_ADD_DOCUMENT")}
              </Button>
            </RoleAccess>,
            <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
              <Button
                variant="cardWarning"
                disabled={isLock}
                onClick={() => {
                  openModal(DeleteStakeholder, { handleCloseModal: closeModal });
                }}
              >
                {translate("STAKEHOLDER_VIEW_BUTTON_DELETE")}
              </Button>
            </RoleAccess>,
          ]}
          tabContents={getTabContents()}
          tabs={getTabs()}
        />
      )}
    </>
  );
};

export default CorporateStakeholderView;

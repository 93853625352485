import React, { useMemo } from 'react';
import TemplateWizard from "src/modules//generic/templates/Modal/TemplateWizard";
import Typography from "src/modules/generic/components/Texts/Typography";
import Button from "../../../../generic/components/Buttons/Button";
import useMachine from '../../../../generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import useGetBankInformation from 'src/modules/checkout/hooks/useGetBankInformation';
import CardTransferDetails, { JURISDICTION_TYPE } from '../../components/CardTransferDetails'
import useSession from 'src/modules/session/hooks/useSession';
import Icon from '../../../../../modules/generic/components/Icon';
import cls from './cls.module.scss'


/**
 * Step to set pay data for manual invoice
 * @param {*} param0
 * @returns
 */


function PaymentFormStep({ openParam }) {

    const { next, context, prev, cancel } = useMachine();
    const { translate } = useTranslate();
    const productSelected = context?.getProduct?.product;
    const coupon = context?.ChooseAPayeeFormStep.coupon;
    const { code: productCode } = productSelected;
    const entityId = context?.initialData?.groupCompanyId;
    const { jurisdictionType } = context?.initialData?.company;

    const { startupId } = useSession();

    const { data: bankInformation } = useGetBankInformation({ productCode, entityId, startupId, couponCode: coupon })
    const handlePrev = () => {
        prev();
    }
    const handleCancel = (values) => {
        cancel();
    }

    const subText = useMemo(() => ({
        [JURISDICTION_TYPE.ADGM]: 'You can pay the amount either in USD or AED, to the specific account below. Please quote the reference number as your payment reference and make sure the payee of this transaction is the one you previously selected. Once you’ve done the transfer, please upload a proof of payment and submit your application. Payment must be received in full. Any fees or charges for the transfer must be paid by you. ',
        [JURISDICTION_TYPE.CAYMAN]: 'Please pay the amount to the bank account below and quote the reference number as your payment reference so we can track the transfer. Once completed, please upload a proof of payment and submit your application. Payment must be received in full. You must pay any fees or charges for the transfer.',
        [JURISDICTION_TYPE.DELAWARE]: 'You can pay the amount either in USD or AED, to the specific account below. Please quote the reference number as your payment reference and make sure the payee of this transaction is the one you previously selected. Once you’ve done the transfer, please upload a proof of payment and submit your application. Payment must be received in full. Any fees or charges for the transfer must be paid by you.'
    }[jurisdictionType]), [jurisdictionType])

    const addressBank = useMemo(() => ({
        [JURISDICTION_TYPE.ADGM]: 'Emirates NBD, Ground Floor, Almas Tower, Jumeirah Lake Towers, Dubai, United Arab Emirates',
        [JURISDICTION_TYPE.CAYMAN]: 'Silicon Valley Bank, 3003 Tasman Drive, Santa Clara, CA, 95054, USA',
        [JURISDICTION_TYPE.DELAWARE]: 'Emirates NBD, Ground Floor, Almas Tower, Jumeirah Lake Towers, Dubai, United Arab Emirates'
    }[jurisdictionType]), [jurisdictionType])


    return (

        <TemplateWizard
            props={{
                title: "Payment",
                skeleton: null
            }}
            buttons={{
                previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
                cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
                submit: <Button variant='primary' onClick={next}>{'Upload proof of payment'}</Button>,
            }}
        >
            <div style={{ gridTemplateColumns: "1fr 1fr", gap: "3rem", display: "grid" }}>
                <Typography variant="h2" color={"black"} component={"p"} >
                    Bank transfer
                </Typography>
            </div>
            <div style={{ gridTemplateColumns: "100%", gap: "3rem", display: "grid" }}>
                <div className={cls.subText} dangerouslySetInnerHTML={{ __html: subText }} />
            </div>
            <div style={{ gridTemplateColumns: "1fr 1fr", gap: "24px", display: "grid" }}>
                {bankInformation?.product?.bankInformation?.map(account =>
                    <CardTransferDetails rateConversion={bankInformation?.rateConversion} jurisdictionType={jurisdictionType} referenceNumber={bankInformation?.reference} bankInformation={account} product={{ value: bankInformation.product.value }} />)}
            </div>
            <div style={{ display: 'flex', gap: '1rem', alignItems: 'center', position: 'absolute', bottom: '1rem' }}>
                <Icon color={"#2C74FF"} icon={"Location-pin"} size={"1.5rem"} isClara />
                <Typography variant="body" color={"text-grey"} component={"p"} >
                    Address
                </Typography>
                <Typography variant="body" color={"black"} component={"p"} >
                    {addressBank}
                </Typography>
            </div>
        </TemplateWizard>


    );
}

export default PaymentFormStep;

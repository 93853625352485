import { useParams } from "react-router";
import _ from "lodash";
import moment from "moment/moment";
import useSession from "src/modules/session/hooks/useSession";
import useGetStakeholdersShareCapital from "./graphql/useGetStakeholders";
import { useGetGroupCompanyShareCapital } from "./graphql/useGetFormationCompany";
import useUpsertFormationCompany from "./graphql/useUpdateShareCapital";
import useUploadBulkDocuments from "../../../Stakeholder/graphql/mutation/useUploadBulkDocuments";

const useServices = (initialValues) => {
  const { id: _id } = useParams();
  const groupCompanyId = initialValues?.id ?? _id;
  const { startupId } = useSession();
  const { manualQuery: getStakeholders } = useGetStakeholdersShareCapital({
    groupCompanyId,
    startupId,
  });
  const { manualQuery: getFormationCompany } = useGetGroupCompanyShareCapital({
    groupCompanyId,
    startupId,
  });

  const { mutation: uploadDocuments } = useUploadBulkDocuments();

  const { upsertFormationCompany } = useUpsertFormationCompany(
    {
      showSuccessNotification: true,
      successMessage: "STAKEHOLDER_ADD_SUCCESS",
    },
    {}
  );

  const initialData = async (context) => {
    const jurisdictionType = initialValues?.jurisdictionType;
    const { data: stakeholderData } = await getStakeholders();
    const { data: groupCompanyData } = await getFormationCompany();
    return {
      ...context,
      startupId,
      groupCompanyId,
      jurisdictionType,
      stakeholders: stakeholderData?.stakeholderList,
      groupCompanyData: groupCompanyData?.getFormationCompany,
      shareholdersInitialValues: initialValues?.shareholdersInitialValues || [],
    };
  };

  const parseSharesData = (context, document) => {
    const stakeholders = context?.stakeholders;
    const isDefaultValues = context?.isDefaultValues;
    const authorisedShares = isDefaultValues
      ? context?.defaultShares
      : context?.shares;
    const shareNominalPrice = isDefaultValues
      ? context?.defaultParValue
      : context?.parValue;
    const currency = context?.currency;
    const useArticlesOfAssociationAmended = context?.isUpload;
    const useDefaultAuthorizedShares = context?.isDefaultValues;
    const articlesOfAssociationAmended =
      context?.articlesOfAssociationAmended?.id || document?.id;

    const sharesAuthorised: number = _.sumBy(
      stakeholders,
      (stakeholder) => stakeholder?.shares
    );
    const remainingShares: number = authorisedShares - sharesAuthorised;

    const shareholdersData = _.map(stakeholders, (stakeholder) => {
      const isPaid: boolean = stakeholder?.isPaid === "true";

      return {
        owner: {
          id: stakeholder?.id,
          type: "stakeholder",
        },
        shareIssuances: {
          sharePrice: {
            amount: shareNominalPrice,
            currency: currency,
          },
          quantity: stakeholder?.shares,
          isPaid,
          shareClass: stakeholder?.shareClass,
        },
      };
    });

    return {
      useDefaultAuthorizedShares,
      useArticlesOfAssociationAmended,
      shareNominalPrice,
      authorisedShares,
      sharesAuthorised,
      remainingShares,
      articlesOfAssociationAmended,
      shareholdersData,
    };
  };

  const SaveStep = async (context) => {
    const file = _.get(context, "file", {});
    let document;

    if (!_.isEmpty(file)) {
      const documents = [];
      documents.push({
        title: file.name,
        file: file,
        type: "ARTICLES_OF_ASSOCIATION",
        groupCompanies: [{ groupCompany: groupCompanyId }],
        status: "Uploaded",
        subCategories: ["constitutionalDocuments"],
        executedOnDate: moment(_.get(context, "UploadFile.date")).format(
          "YYYY-MM-DD"
        ),
      });
      const result = await uploadDocuments({
        variables: { startupId, documents: documents },
      });
      document = result?.data?.uploadBulkDocuments[0];
    }

    const variables = {
      startupId,
      groupCompanyId,
      groupCompanyData: {
        formationData: {
          sharesData: parseSharesData(context, document),
        },
      },
    };
    return await upsertFormationCompany({ variables });
  };

  return {
    initialData,
    SaveStep,
  };
};
export default useServices;

import React, { FC, useMemo } from 'react';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import AddFormationCompanyMachineWizard from '../../submachines/AddFormationCompanyModal/wizard';
interface Props {

}
/**
* Description Step: Step to create a new formation company 
*/
const AddFormationCompanySubMachineStep: FC<Props> = () => {

  const { next, context, prev, cancel } = useMachine();
  const initialValues = useMemo(() => {
    return context?.AddFormationCompanySubMachineStep ?? {}
  }, [context]);

  const handleStopMachine = () => {
    next(context)
  }
  const handleCancelMachine = () => {
    cancel();
  }
  const handlePrevMachine = () => {
    prev();
  }

  return (
    <AddFormationCompanyMachineWizard initialValues={initialValues} onFinish={handleStopMachine} onCancel={handleCancelMachine} onPreviousMachine={handlePrevMachine} />
  );
};

export default AddFormationCompanySubMachineStep;

import React, { FC } from 'react'
import FormProps from "../interfaces/FormProps";
import Form from "../../../../components/forms/Form/FormL";
import ControllerInput from "../../components/Inputs/ReactHookForm/ControllerInput";
import CheckboxInput, { CheckboxProps } from "../../components/Inputs/Checkbox";
import * as Yup from 'yup'
import SubmitButton from "../../../../components/generic/Buttons/SubmitButton/SubmitButton";
import TemplateFormDefault from "../../templates/Modal";
import { DeleteButtonForm } from "../../../../components/generic/Buttons/DeleteButton";
interface RemoveAsFounderFormProps extends FormProps {
  checkBoxProps: CheckboxProps
  // isThereConnections:boolean
}
const RemoveAsFounderForm: FC<RemoveAsFounderFormProps> = ({ onCompleteSubmit, Template = TemplateFormDefault, buttons = {}, buttonSubmitProps, propsTemplate, checkBoxProps }) => {

  return (
    <Form
      schema={Yup.object().shape({
        remove: Yup.array().required('Required')
      })}
      onSubmit={onCompleteSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <Template
        buttons={{ ...buttons, submit: <DeleteButtonForm isSubmitting={undefined} handleDisabled={undefined} labelLoading={'Removing...'} {...buttonSubmitProps}>{'Remove'}</DeleteButtonForm> }}
        props={propsTemplate}>
        <ControllerInput
          name={'remove'}
          as={CheckboxInput}
          isMultiple={false}
          buttonType={'Delete'}
          {...checkBoxProps}
        />
      </Template>
    </Form>
  )
}
export default RemoveAsFounderForm

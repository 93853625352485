import _, { get } from "lodash"
import React, { FC, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { goToNextPage } from '../../../../../store/actions/page'
import { Constants } from '../../../../../v1/utils'
import Button from '../../../../generic/components/Buttons/Button'
import { StepProps } from "../../../../generic/components/Wizard/Step"
import useCustomMachine from '../../../../generic/hooks/useCustomMachine'
import useTranslate from '../../../../generic/hooks/useTranslate'
import { addAssistantText, clearAssistatText } from "../../../../generic/store/action"
import TemplateWizard from '../../../../generic/templates/Modal/TemplateWizard'
import useSession from '../../../../session/hooks/useSession'
import Form from "../../Forms/FormShareOptionsScale"
const Step2B: FC<StepProps> = () => {

    const { startup } = useSession();
    const suscriptionPlan = useMemo(() => _.get(startup, "activeSubscriptionPlan"), [JSON.stringify(startup)]);
    
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (suscriptionPlan.code !== "START_SUBSCRIPTION") {
            dispatch(goToNextPage(Constants.PAGES.chooseDocumentType, { previewDocument: 'GRANT_AGREEMENT' }));
        }
    }, [])


    const { translate } = useTranslate();

    /*Function*/

    useEffect(()=>{
        dispatch(clearAssistatText())
        dispatch(addAssistantText(translate('MODULES.STAKEHOLDER.SHARE_OPTIONS.SECOND_STEP_B.TOOLTIP')))
    },[])

    const { next, send, state } = useCustomMachine();

    const handleCompleteSubmit = async values => {

        if(values.mode  === "SCALE_SUSCRIPTION_PAYMENT"){
            return dispatch(goToNextPage(Constants.PAGES.upgrade));
        }
        next(values);
        dispatch(clearAssistatText())
    }
    const handleCancel = async values => {
        send("CANCEL");
    }

    return (
        <React.Fragment>
            <Form
                initialValues={get(state, 'context.data.personal_information', {})}
                onCompleteSubmit={handleCompleteSubmit}
                Template={TemplateWizard}
                propsTemplate={{
                    title: translate('MODULES.STAKEHOLDER.SHARE_OPTIONS.SECOND_STEP_B.TITLE'),
                    subTitle: translate('MODULES.STAKEHOLDER.SHARE_OPTIONS.SECOND_STEP_B.SUBTITLE')
                }}
                buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }}>
            </Form>

        </React.Fragment>
    )
}

export default Step2B;

import { RoleFields } from "../../../../modules/stakeholder/graphql/typePolicies";
import useMutation from "../../../../hooks/custom/useMutation";
const mutation = `  ${RoleFields}
                    mutation upsertComponentStakeholder(
                        $startupId: ID!
                        $stakeholder: ComponentStakeholderInputType!
                      ) {
                        upsertComponentStakeholder(startupId: $startupId, stakeholder: $stakeholder) {
                            id
                            fullName
                            email
                            roles { ...RoleFields }
                        }
                      }`
const useUpsertStakeholder = () => {
    const [mutationCall, loading] = useMutation(mutation, { showSuccessNotification:false })
    return [mutationCall, loading] 
}
export default useUpsertStakeholder
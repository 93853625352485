import React from 'react';
import RadioInput from "src/components/inputs/Radio/RadioInput";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import TextInput from 'src/components/inputs/Text/TextInput';
import classes from './styles.module.scss';

export const FieldRadioInput = ({ name, label, notRequired, ...props }) => {
  return (
    <TemplateLabel isRequired={!notRequired} isColumn multilineTexts={[label]} blackText>
      <ControllerInput
        render={RadioInput}
        name={name}
        classNameOption={classes.radio}
        className={classes.radioContainer}
        defaultValue={true}
        {...props}
      />
    </TemplateLabel>
  );
}

export const FieldTextInput = ({ name, label, notRequired, ...props }) => {
  return (
    <TemplateLabel isRequired={!notRequired} isColumn multilineTexts={[label]} blackText>
      <ControllerInput
        render={TextInput}
        name={name}
        variant="medium"
        {...props}
      />
    </TemplateLabel>
  );
}
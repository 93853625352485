import useCustomMachine from "../../../../generic/hooks/useCustomMachine";
import React, { FC, useMemo } from "react";
import { StepProps } from "../../../../generic/components/Wizard/Step";
import TemplateWizard from "../../../../generic/templates/Modal/TemplateWizard";
import useTranslate from "../../../../generic/hooks/useTranslate";
import Form from "../forms/FormCreationCompanyFormation";
import Button from "../../../../generic/components/Buttons/Button";
import {
  addAssistantText,
  clearAssistatText,
} from "../../../../generic/store/action";
import { useDispatch } from "react-redux";
import _ from "lodash";
import useUpsertCompanyFormation from "../../../../../hooks/services/modules/groupCompany/useUpsertCompanyFormation";
import useSession from "../../../../session/hooks/useSession";

/**
 - BUILD FORMATION OVERLAY
 **/
 const RULE_TYPE_OTHER = "OTHER";
const $ALPHA3_CODE_CAYMAN = "CYM";
export const $CODE_CAYMAN_GOAL = "GOAL_FORMATION_CAYMAN_REFER";

const CompanyFormStep: FC<StepProps> = ({ ...props }: StepProps) => {

  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const { next, state, stepData, send } = useCustomMachine();
  const { startupId,selectStartup } = useSession();
  const upsertCompanyFormation = useUpsertCompanyFormation();

  const handleCompleteSubmit = async (values: any) => {
    const variables = {
      startupId,
      groupCompany: values,
    };
    if(state.context.data.addToName) {
      let newName = values.name + state.context.data.addToName;
      Object.assign(variables.groupCompany, { name: newName });
    }
    if (_.get(state, "context.data.select_location_company.location.alpha3", "") === $ALPHA3_CODE_CAYMAN) {
      Object.assign(variables, { goal: $CODE_CAYMAN_GOAL });
    }
    if ( 
        (_.get(state,"context.data.formation_type_company.rulesType") === RULE_TYPE_OTHER) ||
        (_.get(state,"context.data.choose_alternative_solution.alternativeSolution") === "CLARA_CONCIERGE" )
    ){
      _.set(variables, "groupCompany.formationData.forceConcierge",true)
    }
    await upsertCompanyFormation(variables);
    await selectStartup(startupId,null);
    next(values);
  };

  const handlePrevious = () => {
    send("PREVIOUS");
  };

  const handleCancel = () => {
    send("CANCEL");
  };

  React.useEffect(() => {
    dispatch(
      addAssistantText(
        translate('MODULES.ONBOARDINGCOMPANY.STEP8A1_HIT')
      )
    );
    dispatch(
      addAssistantText(
        translate('MODULES.ONBOARDINGCOMPANY.STEP_POINTER_D_HIT')
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, []);

  const hasTopCompany = useMemo(() => {
    return (
      _.get(state, "context.paramsData.isThereHoldingCompany") ||
      (!_.isEmpty(_.get(state, "context.globalData", [])) &&
        _.some(_.get(state, "context.globalData", []), (data) => {
          return data.company_info.isTop;
        }))
    );
  }, [JSON.stringify(state)]);

  const initialValues = React.useMemo(() => {
    const {
      choose_jurisdiction,
      select_jurisdiction_company,
      addToName,
    } = state.context.data;
    let passingValues = {
      isHoldingCompany: true,
      country: _.get(choose_jurisdiction, "jurisdiction.code", ""),
      jurisdiction: _.get(select_jurisdiction_company, "jurisdiction", ""),
      addToName: addToName,
      jurisdictionType: choose_jurisdiction.jurisdiction.jurisdictionType,
    };
    return passingValues;
  }, []);

  return (
    <React.Fragment>
      <Form
        initialValues={initialValues}
        hasTopCompany={hasTopCompany}
        onCompleteSubmit={handleCompleteSubmit}
        buttons={{
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              Cancel
            </Button>
          ),
          previous: (
            <Button
              type={"button"}
              onClick={handlePrevious}
              variant="secondary"
            >
              Previous
            </Button>
          ),
        }}
        Template={TemplateWizard}
        tooltips={{
          isNotTop: translate(
            "MODULES.ONBOARDINGCOMPANY.SECOND_STEP_IS_NOT_TOP_TOOLTIPS"
          ),
        }}
        propsTemplate={{
          title: "Add this company's basic info",
          subTitle: "Add a company",
        }}
      />
    </React.Fragment>
  );
};

export default CompanyFormStep;

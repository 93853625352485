import React, {Component} from "react";
import {connect} from "react-redux";
import {Field, Formik} from "formik";
import {Constants} from "../../../../utils/constants";

import TextInput from "../../../../containers/Forms/Inputs/TextInput.js";
import classes from "../EditForm.module.scss";
import PhotoInput from "../../../../containers/Forms/Inputs/PhotoInput";
import * as Yup from "yup";
import {startupValidations} from "../../../../containers/Forms/validations";
import {goToPage, goToPrevPage} from "../../../../../store/actions/page";

const StartupSchema = Yup.object().shape({ ...startupValidations });
class Details extends Component {
  state = {};

  changeMode = () => {
    this.props.goToPage(Constants.PAGES.viewStartup, this.props.data);
  };

  onSubmit = (values, bag) => {
    this.props.updateForm(values);
  };

  render() {
    const { data, tooltips = {} } = this.props;

    return (
      <div className={classes.ContainerEdit}>
        <Formik
          initialValues={data}
          enableReinitialize={false}
          validationSchema={StartupSchema}
          onSubmit={this.onSubmit}
          render={({
            values,
            handleSubmit,
            isSubmitting,
            handleReset,
            setFieldValue
          }) => {
            return (
              <form onSubmit={handleSubmit} className={classes.Form}>
                <div
                  className={`${classes.ContainerRow} ${classes.ContainerRowAvatar}`}
                >
                  <Field name="avatar">
                    {({ field, form }) => (
                      <PhotoInput
                        label="Company Logo: "
                        field={field}
                        form={form}
                        tooltip={tooltips["Company Logo"]}
                      />
                    )}
                  </Field>
                </div>
                <div className={classes.ContainerRow}>
                  <Field name="name">
                    {({ field, form }) => (
                      <TextInput
                        required={true}
                        tooltip={tooltips["Company Name"]}
                        type="text"
                        label="Company Name: "
                        field={field}
                        form={form}
                        placeholder={"Type here..."}
                      />
                    )}
                  </Field>
                </div>
                <div className={classes.ContainerRow}>
                  <Field name="description">
                    {({ field, form }) => (
                      <TextInput
                        required={false}
                        type="text"
                        label="Business Description: "
                        field={field}
                        form={form}
                        placeholder={"Type here..."}
                        tooltip={tooltips["Business Description"]}
                      />
                    )}
                  </Field>
                </div>
                <div className={classes.ContainerRow}>
                  <Field name="website">
                    {({ field, form }) => (
                      <TextInput
                        required={false}
                        type="text"
                        label="Website Page: "
                        field={field}
                        form={form}
                        placeholder={"Type here..."}
                        tooltip={tooltips["Website Page"]}
                      />
                    )}
                  </Field>
                </div>
                <div className={classes.ContainerRow}>
                  <Field name="accelerator">
                    {({ field, form }) => (
                      <TextInput
                        required={false}
                        label="Accelerator/VC:"
                        field={field}
                        form={form}
                        placeholder={"Accelerator/VC..."}
                        tooltip={tooltips["Issue Note"]}
                      />
                    )}
                  </Field>
                </div>

                <div className={classes.Botonera}>
                  <button type="submit" data-cy="submit" disabled={isSubmitting}>
                    Save Changes
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      this.changeMode();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            );
          }}
        ></Formik>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  session: state.session
});
const mapDispatchToProps = dispatch => ({
  goToPage: (page, params) => {
    goToPage(dispatch, page, params);
  },
  goToPrevPage: (reload, defaultPage, params) => {
    goToPrevPage(dispatch, reload, defaultPage, params);
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Details);

import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useSession from "../../../../../../../modules/session/hooks/useSession";
import { Constants } from "../../../../../../../v1/utils/constants";
import classes from "./PositionTab.module.scss";
import EmptyState from "../../../../../../../modules/generic/components/EmptyState";
import useTranslate from "../../../../../../../modules/generic/hooks/useTranslate";
import useBusinessRules from '../../../../../../../modules/clara/hooks/useBusinessRules';
import { Grid } from "@material-ui/core";
import Typography from "src/modules/generic/components/Texts/Typography";

const PositionTab = ({ data, lock, ...props }) => {

  const [groupCompanies, setGroupCompanies] = useState([]);
  const dispatch = useDispatch();
  const { startup } = useSession();
  const { translate } = useTranslate();
  const { isViewer } = useBusinessRules();

  // useEffect(() => {

  return (
    <>
      {!_.isEmpty(data) ? (
        <div className={classes.containerRolesTab}>
          <Grid container spacing={2}>
            <Grid item spacing={2} xs={2}>
              <Typography variant="body2" color="dark-grey">
                {translate("POSITION_TITLE_DEPARTMENT")}
              </Typography>
            </Grid>
            <Grid item spacing={2} xs={3}>
              <Typography variant="body2" color="dark-grey">
                {translate("POSITION_TITLE_POSITION")}
              </Typography>
            </Grid>
            <Grid item spacing={2} xs={7}>
              <Typography variant="body2" color="dark-grey">
                {translate("POSITION_TITLE_RESPONSABILITIES")}
              </Typography>
            </Grid>
            <Grid item spacing={2} xs={2}>
              <Typography variant="body2" color="black" >  {data?.department?.name}</Typography>
            </Grid>
            <Grid item spacing={2} xs={3}>
              <Typography variant="body2" color="black" >{data?.position?.name}</Typography>
            </Grid>
            
            
            <Grid item spacing={2} xs={7}>
              {_.map(_.get(data, "position.responsibilities", []), (responsibility) => {
                return <Typography variant="body2" color="black" component={"p"} className={classes.responsibility}>
                  {_.get(responsibility, "name", "")}
                </Typography>
              })}
            </Grid>
          </Grid>
        </div>
      ) : (
        <EmptyState
          title={isViewer() || lock ? translate("POSITION_EMPTY_STATE_VIEWER") : translate("POSITION_EMPTY_STATE_USER")}
          iconType={lock ? "Locked" : "SAFE"}
        />
      )}
    </>
  );
};

export default PositionTab;

import { default as React, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import Form from "src/components/forms/Form/Form";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import * as Yup from "yup";
import Button from "src/modules/generic/components/Buttons/Button";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import {
  clearAssistatText,
  addAssistantText,
} from "src/modules/generic/store/action";
import Typography from "src/modules/generic/components/Texts/Typography";
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import classes from "../classes.module.scss";

/**
 * Description Step: Select ADGM authorised signatory form
 */

function AdgmTechStartupAuthorisedSignatoryFormStep() {
  const { next, context, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return {
      ...(context?.AdgmTechStartupAuthorisedSignatoryFormStep ?? {}),
      ...(context?.GetAdgmTechStartupAuthorisedSignatoryData ?? {}),
    };
  }, [context]);

  const handleCancel = (values) => {
    cancel();
  };

  const handlePrev = (values) => {
    prev();
  };

  const handleSubmit = async (values) => {
    next(values);
  };

  useEffect(() => {
    dispatch(
      addAssistantText(
        "<strong>Why do I need this?</strong><br/>Every ADGM company must have at least one UAE resident or GCC National appointed as the Authorised Signatory. This person will be named on the commercial Licence of the company but they do not have the authority to sign commercial documents or company resolutions without the company issuing them a POA or other specific authority. <br/><br/>For further information about the role of an ADGM Authorised Signatory read more <a rel='noopener noreferrer' target='_blank' href='https://help.clara.co/en/articles/6385804-what-is-an-adgm-authorised-signatory' >here</a>."
      )
    );
    dispatch(
      addAssistantText(
        "<strong>What if I don't have one?</strong><br/>If any of your directors or shareholders cannot fulfil this role, then you can only proceed with ADGM if you have a third party who can be appointed or if you use Clara's nominee service. <br/> <br/> For more information about our nominee service, click <a rel='noopener noreferrer' target='_blank' href='https://help.clara.co/en/articles/6230840-can-i-use-a-nominee-to-be-my-adgm-authorised-signatory' >here</a>."
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const schema = Yup.object().shape({
    authorisedSignatory: Yup.string().nullable().required("Required"),
  });

  const options = [
    {
      value: "DirectorOrShareholder",
      component: ButtonText,
      componentProps: {
        title: (
          <div className={classes.adgmtsasText}>
            {"A director or shareholder is a UAE resident or GCC National"}
          </div>
        ),
        text: (
          <div className={classes.adgmtsasText}>
            {
              "This individual will be appointed as the company’s ADGM Authorised Signatory."
            }
          </div>
        ),
      },
    },
    {
      value: "ThirdParty",
      component: ButtonText,
      componentProps: {
        title: <div className={classes.adgmtsasText}>{"A Third Party"}</div>,
        text: (
          <div className={classes.adgmtsasText}>
            {
              "You will appoint someone who is not a director or shareholder but who is a UAE resident or GCC National."
            }
          </div>
        ),
      },
    },
    {
      value: "ClarasNominee",
      component: ButtonText,
      componentProps: {
        title: (
          <div className={classes.adgmtsasText}>
            {"Clara’s Nominee Authorised Signatory"}
          </div>
        ),
        text: (
          <div className={classes.adgmtsasText}>
            {`To use this service, you will need to appoint a second Authorised Signatory, but this person only needs to have entered the UAE in the past two years. The cost is ${initialValues?.product?.currency} ${initialValues?.product?.value} + VAT annually.`}
          </div>
        ),
      },
    },
  ];
  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: "ADGM Authorised Signatory",
          skeleton: null,
          subTitle: "ADGM eligibility",
          variant: "simple",
        }}
        buttons={{
          previous: (
            <Button variant="secondary" onClick={handlePrev}>
              {translate("BUTTON_PREVIOUS")}
            </Button>
          ),
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("BUTTON_CANCEL")}
            </Button>
          ),
          submit: (
            <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>
              {translate("BUTTON_NEXT")}
            </SubmitButton>
          ),
        }}
      >
        <Typography weight="regular" variant="xbody">
          To form a company in ADGM, you need to have at least one GCC National
          or UAE resident who will be appointed as your ADGM Qualifying
          Authorised Signatory. Select from one of the options below:
        </Typography>
        <ControllerInput
          as={CheckboxButtonSimple}
          name="authorisedSignatory"
          size="lg"
          options={options}
          className={classes.adgmtsasCheckboxButton}
          classNameButton={classes.adgmtsasButtonCheckBoxSize}
        />
      </TemplateWizard>
    </Form>
  );
}

export default AdgmTechStartupAuthorisedSignatoryFormStep;

const ButtonText = ({ title, text }) => {
  return (
    <div>
      <Typography weight="semibold" variant="h4" className="mb-2">
        {title}
      </Typography>
      <Typography variant="body">{text}</Typography>
    </div>
  );
};

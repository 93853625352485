
import _ from 'lodash';
import { ReactNode, ReactElement } from 'react';

type TypeAssistanceBubble = "text" | "academy" | "question" | "custom-child" | 'alert' | "custom-object" | "custom-bubble";
type BubbleText = string;

export interface BubbleCustomChildren {
  component: ReactNode | ReactElement
}
export interface BubbleCustom {
  component: ReactNode | ReactElement
}

export interface BubbleAcademy {
  description?: string,
  linkAcademy?: string,
}

interface QuestionAcademy {
  user: {
    avatar: string,
    fullName: string
  },
  description: string
}

export type AssistantText = BubbleText | BubbleAcademy | QuestionAcademy | BubbleCustomChildren;

export const actions = {
  ADD_TOOLTIP: "ADD_TOOLTIP",
  ADD_TEXT_ASSISTANT: "ADD_TEXT_ASSISTANT",
  CLEAR_ASSISTANTS_TEXT: "CLEAR_ASSISTANTS_TEXT",
  REMOVE_ASSISTANT_TEXT: "REMOVE_LAST_ASSISTANT_TEXT"
};

export const addTooltip = (tooltip) => ({
  type: actions.ADD_TOOLTIP,
  payload: tooltip
});

export const setTooltip = (tooltip) => {
  return (dispatch) => {
    dispatch(addTooltip(tooltip))
  }
}

export const removeAssistantText = () => ({
  type: actions.REMOVE_ASSISTANT_TEXT
});

export const clearAssistatText = () => ({
  type: actions.CLEAR_ASSISTANTS_TEXT
});


export const addAssistantText = (value: AssistantText | AssistantText[], type: TypeAssistanceBubble = "text") => {
  if (_.isArray(value)) {
    return {
      type: actions.ADD_TEXT_ASSISTANT,
      payload: _.map(value, v => ({ type, value: v, id: _.uniqueId(type) }))
    };
  };
  return {
    type: actions.ADD_TEXT_ASSISTANT,
    payload: { value, type, id: _.uniqueId(type) }
  };
}

import _ from 'lodash';
import { useMemo } from 'react';
import useUpsertFormationCompany from 'src/modules/startup/GroupCompany/graphql/mutations/useUpsertFormationCompany';
import { useParams } from "react-router-dom";
import useSession from 'src/modules/session/hooks/useSession';
import useGetStakeholdersLazy from './useGetStakeholdersLazy';

const useServices = ({initialValues}) => {

  const [upsertFormationCompany] = useUpsertFormationCompany({ showSuccessNotification: false }, {})
  const params = useParams();
  const { startupId } = useSession();
  const { data: dataStakeholders, loading, manualQuery } = useGetStakeholdersLazy()
  const groupCopmpanyId =initialValues?.id ?? params?.id;
  return useMemo(() => ({
    serviceSetCorporatePositions: async (context, event) => {
      const variables = {
        startupId,
        groupCompanyId: groupCopmpanyId,
        groupCompanyData: {
          formationData: {
            corporatePositions: _.get(context, 'stakeholder')
          }
        }
      }
      const result = await upsertFormationCompany({
        variables
      });
      return true;
    },

    serviceGetStakeholders: async (context, event) => {
      const stakeholders = await manualQuery({
        variables: { startupId, groupCompanyId: groupCopmpanyId }
      })
      const result = {
        stakeholders: _.get(stakeholders, 'data.stakeholderList', []),
      };
      return result;
    }
  }), []);

}
export default useServices;
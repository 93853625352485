
const RoleFields = `fragment RoleFields on Role {
        id
        name
        startDate
        endDate
        isActive
        groupCompany { id name kind managedByClara jurisdictionType }
        stakeholder { id fullName }   
        entity {
            ... on StakeHolder {
                id
                fullName
            }
            ... on GroupCompany {
                id
                name
                kind
                managedByClara
            }
        }
}`
const EquityFields = `fragment EquityFields on Equity {
    id
    groupCompany{
      name  
      id
      avatar
      transactionCurrency      
    }
    type
    amount
    price
    issuedDate
    documents{
      id
      name
      status
       file {
                name
                size
                id
              }
    }
    shareClass{
      id
      name
      defaultShare
    }
    shareIncentivePlan{
      id
      name 
      totalShares
      allocatedShares
    }
    currency
}`
const WarrantFields = `fragment WarrantFields on Warrant {
    id
    amount { amount currency }
    # valuation  
    issuedDate
    documents {
      name
      type{
         code
      }
      file {
        name
        size
        id
      }
      id
      status
     }
     groupCompany{
       id
       name
       avatar
       transactionCurrency
     }
}`

export {
    RoleFields,
    EquityFields,
    WarrantFields
}

import React from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";

const ViewConvertiblesCancel = ({ go, ...props }) => {
  const params = useParams()
  return (
    <MicroFrontend id="MF_Convertibles_View_Cancel" component={"./ViewCancelConvertible"} mf="capTable" params={{ go, ...props, ...params }} />
  );
};

export default ViewConvertiblesCancel;

import { get } from 'lodash';
import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../../../generic/components/Buttons/Button';
import { StepProps } from "../../../../../generic/components/Wizard/Step";
import useCustomMachine from '../../../../../generic/hooks/useCustomMachine';
import { addAssistantText, clearAssistatText } from '../../../../../generic/store/action';
import TemplateWizard from '../../../../../generic/templates/Modal/TemplateWizard';
import Form from "../../components/Forms/FormVisa";
import UAEVIsa from '../../public/UAE_Visa.svg';

const Step2: FC<StepProps> = () => {

  const { next, send, state } = useCustomMachine();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(addAssistantText(`Some regions require additional national identification`));
    return () => {
      dispatch(clearAssistatText());
    }
  }, [])

  const handleCompleteSubmit = async values => {
    next(values);
  }

  const handleCancel = async values => {
    send("CANCEL");
  }

  const handlePrevious = useCallback(() => {
    send('PREVIOUS')
  }, [])

  return (
    <React.Fragment>
      <Form
        initialValues={get(state, 'context.data.uploadDocument', {})}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: 'Add their UAE residence visa',
          subTitle: 'UAE residence visa'
        }}
        image={<img src={UAEVIsa} alt="" />}
        buttons={{ previous: <Button onClick={handlePrevious}>Previous</Button>, cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }}>
      </Form>
    </React.Fragment>
  )
}

export default Step2;

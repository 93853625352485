import STEPS from '../wizard/steps/langs/index'
import FORMS from '../forms/langs/index'
import STEPS_WARRANT from '../stakeholder/warrants/modals/AddWarrantModal/steps/langs'
import STEPS_CONVERTIBLE from '../stakeholder/convertible/modals/AddConvertibleModal/steps/langs'
import FORMS_CONVERTIBLE from '../stakeholder/convertible/modals/AddConvertibleModal/forms/langs'
import STEPS_SHARES from '../stakeholder/share/modals/AddSharesModal/steps/langs'
import STEPS_EQUITY_POSITIONS from '../company/share/modals/AddSharesModal/steps/langs'
import ADD_EQUITY_MODAL  from '../AddEquity/langs'
import ADD_EQUITY_TASK_MODAL from '../AddEquityTask/langs'
const TYPES_PLURAL={
    WARRANT:'warrants',
    CONVERTIBLE:'convertibles',
    OPTION:'share options',
    SHARE:'shares'
}
const TYPES={
    WARRANT:'warrant',
    CONVERTIBLE:'convertible',
    OPTION:'share option',
    SHARE:'share'
}
const AGREEMENT_TYPE={
    WARRANT:'Warrant Agreement',
    CONVERTIBLE:'agreement',
    OPTION:'share option',
    SHARE:'Shareholder Register'
}
export default {
    TYPES,
    TYPES_PLURAL,
    AGREEMENT_TYPE,
    STEPS:STEPS.US,
    FORMS:FORMS.US,
    STEPS_WARRANT:STEPS_WARRANT.US,
    STEPS_CONVERTIBLE:STEPS_CONVERTIBLE.US,
    FORMS_CONVERTIBLE:FORMS_CONVERTIBLE.US,
    STEPS_SHARES:STEPS_SHARES.US,
    STEPS_EQUITY_POSITIONS:STEPS_EQUITY_POSITIONS.US,
    ADD_EQUITY:ADD_EQUITY_MODAL.US,
    ADD_EQUITY_TASK:ADD_EQUITY_TASK_MODAL.US
}
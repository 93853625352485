import { gql } from '@apollo/client';
import useMutation from "../../../../../hooks/custom/useMutation";
import useSession from '../../../../../modules/session/hooks/useSession';
import { GET_GROUP_COMPANIES } from '../../../../../modules/startup/onBoarding/core/forms/IndividualOrCompanyStakeholderForm/graphql/useGetGroupCompanies';
import { FormationData } from "../fragments";
import { TasksRequiredFieldFragment } from "../../../../map/graphql/typePolicies";

export const UPSERT_FORMATION_COMPANY = `
${FormationData}
${TasksRequiredFieldFragment}
mutation upsertFormationCompany(
    $startupId: ID!
    $groupCompanyId: ID
    $groupCompanyData: FormationCompanyInputType
  ) {
    upsertFormationCompany(
      startupId: $startupId
      groupCompanyId: $groupCompanyId
      groupCompanyData: $groupCompanyData
    ) {
      id
      formationData{
    ...FormationDataFields
      }
      name
      isHoldingCompany
      tasks{
      ...TasksRequiredFieldFragment
      }
    }
  }`;

const useUpdateGroupCompany = (config = {}, props = {}) => {

  const { startupId } = useSession();
  const [mutation, loading] = useMutation(
    UPSERT_FORMATION_COMPANY, { showSuccessNotification: false, ...config }, {
      refetchQueries: [
        { query: gql`${GET_GROUP_COMPANIES}`, variables: { startupId: startupId } },
      ], ...props
  }
  )

  return [mutation, loading]
};

export default useUpdateGroupCompany;

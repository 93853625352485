import React from 'react';
import classes from './PrimaryButton.module.scss';
import Button from '../Button/Button'

const PrimaryButton = ({loading, ...props}) => {
    return (
        <Button {...props} loading={loading} className={`${classes.PrimaryButton} ${props.className}`} classNameLoading={classes.loading}> {props.children} </Button>
    )
};

export default PrimaryButton

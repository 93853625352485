import React from 'react'
import _ from 'lodash'
import useTranslate from "src/modules/generic/hooks/useTranslate";
import classes from "./PEPTab.module.scss";

const PEPTab = ({ data }) => {
  const { translate } = useTranslate();

  return (
    <>
      <div className={classes.container}>
        <span className={classes.label}>{translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.PEP.DESCRIPTION", { stakeholderName: data?.stakeholderName })}</span>
        <span className={classes.text}>
          {_.get(data, "isPEP", false) ? "Yes, they are" : "No, they're not"}
        </span>
      </div>
      {data?.isPEP && (
        <div className={classes.container}>
          <span className={classes.label}>{translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.PEP.WHO_IS_PEP")}</span>
          <span className={classes.text}>{_.get(data, "descriptionOfPEP", '-')}</span>
        </div>
      )}
    </>
  );
}

export default PEPTab;
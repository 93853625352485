

import PropTypes from "prop-types";
import React, { memo } from "react";
import MicroFrontend from "src/mf";
import useSession from 'src/modules/session/hooks/useSession';

/* `MyProfilesViewMf` is a React functional component that serves as a view component for displaying
tasks. It utilizes React hooks such as `useState`, `useEffect`, and `useDispatch` from
`react-redux`. The component also uses the `memo` function from React to optimize performance
by memoizing the component. */

const MyProfilesViewMf = ({ goToMF, handleOpenProfile, handleOpenModal }) => {

  const { updateUserData } = useSession();

  return (
    <React.Fragment>
      <MicroFrontend id="MF_MyProfiles_" component={"./MyProfiles"} mf="dashboard" params={{
        go: goToMF,
        handleOpenProfile,
        handleOpenModal,
        updateUserData
      }} loader={<></>} />
    </React.Fragment>
  );
};

MyProfilesViewMf.propTypes = {
  goToMF: PropTypes.func,
  handleOpenProfile: PropTypes.func,
  handleOpenModal: PropTypes.func,
};

export default memo(MyProfilesViewMf);

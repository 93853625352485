import useQuery from "../../../custom/useQuery";
import _ from 'lodash'
import {RoleFields} from "../../../../modules/stakeholder/graphql/typePolicies";
import {NoticesFragment} from "../../../../modules/startup/Stakeholder/graphql/fragments";

const GET_GROUP_COMPANIES = `
${RoleFields}
${NoticesFragment}
query getGroupCompaniesForEditGenerate($startupId: ID!) {
    getGroupCompanies(startupId: $startupId) {
		id
		managedByClara
		  authorizedSignatories {
		  stakeholder {
			id
			title
			email
			fullName
			roles { ...RoleFields }
		  }
		  ...RoleFields
		}
		name
		type
		placeOfBusiness{
			city 
			state
		}
		jurisdiction
		country { name code alpha3}
		registeredNumber
		transactionCurrency
		isHoldingCompany
		advanceCapTable{
			shareClasses{
			  id 
			  name
			  shareIssuances{
				id
				  issuedDate
				  customId
				  quantity
				  sharePrice{
			  amount
			  currency
			}
			shareClass{
			  id
			  defaultIdPrefix
			  name
			}
				  boardApprovalDate
				  holder{
			  ... on StakeHolder{
				id
				fullName
			  }
			  ... on GroupCompany{
				id
			  }
			}
				  vestingSchedule{
			  vestingTerm{
				id
				  name
				  description
				  vestingType
				  allocationType
				  standardTerm{
			  vestingPeriod
				  vestingFrequency
						isThereCliff
				  cliffPeriod
			}
			  }
				  vestingStartDate
			}
				  shareNumbersIssued{
			  startingShareNumber
			  endingShareNumber
			}
				  status{
			  state 
			  generated
			}
			  }
			}
		  }
		documents {
		  name
		  id
		  kind
		  status
		  type{
			label
			code
		  }
		  groupCompanies {
			groupCompany {
			id
			  avatar
			  name
			}
			signedBy {
			id
			}
		  }
		  stakeholders {
		  id
			avatar
			fullName
		  }
		  subCategories
		  file {
			id
			name
			size
			type
		  }
		} # documents
		registeredAddress {
		  city
		  state
		  street
		  zipCode
		  country {name code alpha3}
		}
		recipientNotice {...NoticesFragment}
	  }
  }
`;
const useGetGroupCompaniesForEditGenerate = ({ variables, ...props }) => {
    const { loading, error, data, refetch, ...propsDesc } = useQuery(GET_GROUP_COMPANIES, variables, null, props);

    return { loading, error, data: _.get(data, 'getGroupCompanies', []), refetch, ...propsDesc };
};
export default useGetGroupCompaniesForEditGenerate;

import Machine from "../../../generic/components/Machine/MachineClass";
import { StepAddress, StepCurrency } from './Steps';


const machineDefinition = {
    id: 'address_and_currency',
    initial: 'add_address',
    states: {
        add_address: {
            on: {
                NEXT: { target: 'add_currencies', actions: 'setContext' },
                CANCEL: 'finish_address_and_currencies',
            },

        },
        add_currencies: {
            on: {
                NEXT: { target: 'finish_address_and_currencies', actions: 'setContext' },
                CANCEL: 'finish_address_and_currencies',
            },

        },
        finish_address_and_currencies: {
            type: 'final',
            entry: 'final',
        },
        onDone: {
            actions: 'stopMachine'
        }
    },
}
const steps = {
    add_address: StepAddress,
    add_currencies: StepCurrency
}

const options = {}



export default class MachineCompany extends Machine {
    constructor() {
        super(machineDefinition, options, steps);
    }
}

import useQuery from "../../../../custom/useQuery";
import useLazyQuery from "../../../../custom/useLazyQuery";

const GET_STAKEHOLDER_DETAIL_DETAILS_QUERY = `
query getStakeholderDetail($startupId: ID!, $stakeholderId: ID!) {
  getStakeholder(startupId: $startupId, stakeholderId: $stakeholderId) {
    fullName
    nationality {
      name
      code
    }
    jurisdiction
    registeredNumber
    taxRegistrationNumber
    previousName
    address {
      street
      city
      state
      country {name code alpha3}
      zipCode
    }
    documents {
      name
		  id
		  kind
		  status
		  type{
        label
        code
		  }
    }
    isSeparateLegalEntity
  }
}`;

const useIndividualStakeholderDetailsData = (variables,config) => {
  const { loading, error, data, refetch } = useQuery(GET_STAKEHOLDER_DETAIL_DETAILS_QUERY,{variables},config);
  return { loading, error, data, refetch };
};

export const useIndividualStakeholderDetailsLazy = (variables, config) => {
  const { manualQuery, loading, error, data, refetch } = useLazyQuery(GET_STAKEHOLDER_DETAIL_DETAILS_QUERY, variables, config);
  return { loading, error, data, refetch,manualQuery };
};

export default useIndividualStakeholderDetailsData;
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import classesDefault from './ModalScroll.module.scss';

const ModalScroll = ({ open, handleClose,fullWidth=false, maxWidth="md" ,children, ...props }) => {

    const handleCloseModal = () => {
        handleClose()
    };

    return (
        <Dialog 
            maxWidth={maxWidth}
            fullWidth={fullWidth} 
            {...props}
            open={open}
            onClose={handleCloseModal}
           
        >
            {children}
        </Dialog >

    );
}

export default ModalScroll;
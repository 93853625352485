
const ACTIONS={
    "GETDDREPORT": "GETDDREPORT",
    "GQL_MUTATION_DELETECOMMENT": "GQL_MUTATION_DELETECOMMENT",
    "GETFILEBYID":"GETFIELDBYID",
    "LOGIN":"LOGIN",
    "GENERATEAGREEMENT":"GENERATEAGREEMENT",
    "REQUESTSIGNATURES ": "REQUESTSIGNATURES",
    "GETPREVIEWAGREEMENTDOCUMENT": "GETPREVIEWAGREEMENTDOCUMENT",
    "LOGOUT": "LOGOUT",
    GQL_MUTATION_DELETECOMMENT: "GQL_MUTATION_DELETECOMMENT",
    GQL_MUTATION_GENERATEAGREEMENT: "GQL_MUTATION_GENERATEAGREEMENT",
    GQL_MUTATION_REQUESTSIGNATURES: "GQL_MUTATION_REQUESTSIGNATURES",
    GQL_MUTATION_GETPREVIEWAGREEMENTDOCUMENT: "GQL_MUTATION_GETPREVIEWAGREEMENTDOCUMENT",
    GQL_MUTATION_DELETEDRAFTDOCUMENT: "GQL_MUTATION_DELETEDRAFTDOCUMENT",
    GQL_MUTATION_CREATEDOCUMENTS: "GQL_MUTATION_CREATEDOCUMENTS",
    GQL_MUTATION_DELETEDOCUMENT: "GQL_MUTATION_DELETEDOCUMENT",
    UPLOADNEWFILE: "UPLOADNEWFILE",
    GQL_MUTATION_UPSERTSCOPING: "GQL_MUTATION_UPSERTSCOPING",
    GQL_MUTATION_SENDHEALTHCHECKREQUEST: "GQL_MUTATION_SENDHEALTHCHECKREQUEST",
    GQL_MUTATION_UPSERTDOCUMENT: "GQL_MUTATION_UPSERTDOCUMENT",
    GQL_MUTATION_UPSERTCOMPONENTSTAKEHOLDER: "GQL_MUTATION_UPSERTCOMPONENTSTAKEHOLDER",
    CHANGEPASSWORD: "CHANGEPASSWORD",
    GQL_MUTATION_UPSERTISSUE: "GQL_MUTATION_UPSERTISSUE",
    GQL_MUTATION_DELETEISSUE: "GQL_MUTATION_DELETEISSUE",
    FORGOTPASS: "FORGOTPASS",
    SIGNUP: "SIGNUP",
    GQL_MUTATION_DELETEGROUPCOMPANY: "Remove Group Company",
}
const RANGES={
    "TODAY": "TODAY",
    "YESTERDAY": "YESTERDAY",
    "LASTWEEK": "LASTWEEK",
    "LASTMONTH": "LASTMONTH",
    "OLDEST": "OLDEST",
}
export default  {
    ACTIONS,
    RANGES
}

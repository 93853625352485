import _ from "lodash";
import Mustache from 'mustache';
import React, { useEffect, useState } from 'react';
import { ServerConnect } from "../../../../v1/utils";
import useSession from "./../../../../modules/session/hooks/useSession";
export default function useAgents() { 

    const [agents, setAgents] = useState([]);
    const { user } = useSession();
    useEffect(() => {
        if (agents.length < 1) {
            const getAgents = () => {
                let agentsList = []
                const query = ` {
                                getAgents(matterId: "{{matterId}}", roles: [SERVICE_AGENT, SENIOR_SERVICE_AGENT, SERVICE_AGENT_MANAGER]){
                                _id
                                 fullName
                                email
                                    }
                                }`
                const getAgentsQuery = Mustache.render(query, { roles: ['SERVICE_AGENT', 'SENIOR_SERVICE_AGENT', 'SERVICE_AGENT_MANAGER'] })
                
                ServerConnect.graphQlQuery(getAgentsQuery)
                    .then(({ getAgents }) => _.forEach(getAgents, agent => {
                        if (agent.fullName !== user) {
                            
                            agentsList.push({ id: agent.fullName, label: agent.fullName, component:<div>
                                   <img src="/img/icons/greyPerson.svg" />{'  ' + agent.fullName}
                            </div> })
                            return agent
                        }
    
                    })).then(() => {
                        setAgents(agentsList)
    
    
                    })
            }
            getAgents();
        }
    
    
    
    }, []);
    
    return agents
}

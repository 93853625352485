import { fieldsPart1, fieldsPart2, fieldsPart3 } from "../../constants";

const useMapperDataProtectionContactDeclaration = () => {
  const fields = [...fieldsPart1, ...fieldsPart2, ...fieldsPart3];

  const getSubfieldValueInitialValue = (field, subField, initialData) => {
    const initValue = initialData[field.title.name];

    if (subField.component === "radioInput")
      return initValue.includes(subField.name);
    return initialData[subField.name];
  };

  const getInitialData = (initialData) => {
    let initialValues = {};

    if (!initialData) return {};

    fields.forEach((field) => {
      if (field.subItems.length > 0) {
        field.subItems.forEach((subField) => {
          initialValues[subField.name] = getSubfieldValueInitialValue(
            field,
            subField,
            initialData
          );
        });
      } else {
        if (field.title.component === "radioInput")
          initialValues[field.title.name] =
            initialData[field.title.name].question;
        else initialValues[field.title.name] = initialData[field.title.name];
      }
    });

    initialValues = {
      ...initialValues,
      registrationExpiryDate: initialData.registrationExpiryDate,
    };

    return initialValues;
  };

  const getSubfieldValueSubmit = (field, submit) => {
    if (!submit[field.name]) return;
    if (field.component === "radioInput") return field.name;
    return submit[field.name];
  };

  const getSubmitData = (submit, initialData) => {
    let data = {};

    fields.forEach((field) => {
      if (field.subItems.length > 0) {
        const values = [];

        field.subItems.forEach((subField) => {
          const value = getSubfieldValueSubmit(subField, submit);

          if (value) {
            if (subField.component === "textInput") {
              data[subField.name] = value;
            } else {
              values.push(value);
            }
          }
        });

        data[field.title.name] = values;
      } else {
        if (field.title.component === "radioInput") {
          data[field.title.name] = { question: submit[field.title.name] };
        } else {
          data[field.title.name] = submit[field.title.name];
        }
      }
    });

    data = {
      ...data,
      registrationExpiryDate: initialData.registrationExpiryDate,
    };

    return data;
  };

  return {
    getInitialData,
    getSubmitData,
  };
};

export default useMapperDataProtectionContactDeclaration;

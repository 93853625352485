import useSession from "src/modules/session/hooks/useSession";
import useGetGroupCompanyEconomicSubstance from "./graphql/useGetGroupCompany";
import useUpdateEconomicSubstanceInformation from "./graphql/useUpdateEconomicSubstanceInformation";

const useServices = ({ initialValues }) => {
  const { groupCompanyId } = initialValues;
  const { startupId } = useSession();
  const { manualQuery: getGroupCompany } = useGetGroupCompanyEconomicSubstance({ groupCompanyId, startupId });
  const mutation = useUpdateEconomicSubstanceInformation();
  const initialData = async () => {
    const { data: groupCompanyData } = await getGroupCompany();
    return { initialValues, groupCompany: groupCompanyData?.getGroupCompany };
  }

  const SaveStep = async (context) => {
    
    const variables = {
      startupId,
      groupCompanyId,
      renewalData: {
        taxInformation: context?.TaxInformationStep
      }
    }
    
    return await mutation({variables});
  }

  return { initialData, SaveStep };
}

export default useServices;
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PaymentView from "./PaymentView";
import FormPrimaryContact from "./../../../../modules/checkout/forms/FormPrimaryContact";
import useTranslate from "../../../../modules/generic/hooks/useTranslate";
import _ from "lodash";
import useSession from "./../../../../modules/session/hooks/useSession";
import useUpsertFormationCompany from "../../../../modules/startup/GroupCompany/graphql/mutations/useUpsertFormationCompany";

const PaymentFormationView = (props) => {
  const params = useParams();
  const [isPrevContent, setIsPrevisPrevContent] = useState(true);
  const { productCode, groupCompanyId } = params;
  const { translate } = useTranslate();
  const { startup, startupId } = useSession();

  const complePrevContet = () => {
    setIsPrevisPrevContent(false);
  };
  const shouldRefetchQuery = (observer) => {
    return false;
  };
  const [upsertFormationCompany] = useUpsertFormationCompany(
    { showSuccessNotification: false, onCompleted: complePrevContet, shouldRefetchQuery },
    {}
  );

  const completeFinish = async (data) => { };

  const onCompleteSubmit = async (data) => {
    const formationData = {
      primaryContact: data.authorisedSignatories,
    };
    await upsertFormationCompany({
      variables: {
        startupId,
        groupCompanyId: params.groupCompanyId,
        groupCompanyData: { formationData },
      },
    });
  };
  
  return (
    <React.Fragment>
      <PaymentView
        productCode={productCode}
        source={"FORMATION_COMPANY"}
        id={groupCompanyId}
        canSelectCard={false}
        completeFinish={completeFinish}
        isPrevContent={isPrevContent}
        prevContent={
          <React.Fragment>
            <FormPrimaryContact
              onCompleteSubmit={onCompleteSubmit}
              groupCompanyId={_.get(params, "groupCompanyId")}
              propsTemplate={{
                title: translate(
                  `MODULES.CHECKOUT.PAYMENT_ADGM_PRIMARY_CONTACT_TITLE`
                ),
                subTitle: translate(
                  `MODULES.CHECKOUT.PAYMENT_ADGM_PRIMARY_CONTACT_SUBTITLE`,
                  { startup }
                ),
              }}
            ></FormPrimaryContact>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
};

export default PaymentFormationView;

import React, {useMemo} from 'react';
import { Redirect } from "react-router-dom";
import PAGES from "../../../constants/pages";
import useBusinessRule from "./../../../modules/clara/hooks/useBusinessRules";
import useSession from "./../../../modules/session/hooks/useSession";

const RedirectView = ({ ...props }) => {

  const {session} = useSession()
  const {
    // hasSelectedStartup,
    // isIntroduction,
    isFormation,
    isSuperAdmin,
    // isNotSuperAdmin,
    hasStartups,
    hasMatters,
    isFounderAgreement,
    isServiceAgent,
    isServiceAgentManager,
    hasEquity,
    isSeniorServiceAgent
  } = useBusinessRule();

  const pageToRedirect =useMemo(() => {
    if (!session.user) {
      return PAGES.login.path;
    }
    let pageToRedirect = PAGES.switchStartup;
    // pageToRedirect = hasSelectedStartup() ? PAGES.MODULES.dashboard : pageToRedirect;
    // pageToRedirect = isIntroduction() ? PAGES.introduction : pageToRedirect;
    // pageToRedirect = !hasStartups() && !isFormation()  && isNotSuperAdmin() ? PAGES.onboarding : pageToRedirect;
    pageToRedirect = !hasStartups() && hasEquity() ? PAGES["capTable.mf.myEquity.home"] : pageToRedirect;
    pageToRedirect = hasStartups() ||  hasMatters() || isSuperAdmin() ? PAGES.switchStartup : pageToRedirect; 
    pageToRedirect = isFormation() ? PAGES.switchStartup : pageToRedirect;
    pageToRedirect = (isServiceAgent() || isServiceAgentManager() || isSeniorServiceAgent()) ? PAGES.switchMatters : pageToRedirect;
    pageToRedirect = isFounderAgreement() && !hasStartups() ? PAGES.createStartup : pageToRedirect;
    //TODO REDUX: // let redirectUrl = JSON.parse(sessionStorage.getItem('redirect_url'))
    // pageToRedirect = redirectUrl ? { path: redirectUrl.redirectUrl } : pageToRedirect;
    return pageToRedirect.path;
  },[JSON.stringify(session)]);

  return (
    <React.Fragment>
      {(session && ( (session.token===null)  || (session.token ) ) )  && <Redirect to={pageToRedirect}/>}
    </React.Fragment>
  );
}

export default RedirectView;

import React, {useEffect, useState} from 'react';
import {ClaraCheckForm, Text} from '../../../../components';
import _ from "lodash";
import {Field} from "formik";
import classes from "../SelectorComponent/Shareholders.module.scss";
import FactoryChatForm from '../../../../components/Chat/FactoryChatForm';
import FactoryChatFormConfig from '../../../../components/Chat/FactoryChatFormConfig';
import useMatter from '../../../../Hooks/useMatter';

const BeneficialOwnerSelector = ({ name, eligibleData, showButtonAdd, initialValueStart, titleButtons, titleEntyties }) => {


    const [openModal, setOpenModal] = useState(false);
    const { matter } = useMatter(initialValueStart.matterId)
    const [formSelect, setFormSelect] = useState("INDIVIDUAL_FORM");

    const [listStateholder, setListStateholder] = useState(null);
    const [reload, setReload] = useState(false);
    const handleClose = () => {
        setOpenModal(false);
        setReload(!reload);
    }
    const setFom = (form) => {
        setFormSelect(form);
        setOpenModal(true);
    }
    useEffect(() => {
        if (matter && !listStateholder) {
            let listStateholderLocal = [];
            _.forEach(_.get(eligibleData, "beneficialOwners", []), (individual) => {
                listStateholderLocal.push({
                    type: "DIRECTOR_INDIVIDUAL_FORM",
                    data: individual,
                })
            });
            setListStateholder(listStateholderLocal);
        }
    })


    return (
        <React.Fragment>
            <div className={` ${classes.containerShareholder}`}>
                <div className={classes.contentField}>
                    <h4><Text uuid={titleEntyties} /></h4>
                    <Field name={name}>
                        {({ field, form }) => (
                            <ClaraCheckForm
                                reload={reload}
                                openForm={openModal}
                                addForm={formSelect}
                                cbClose={handleClose}
                                field={field}
                                multiForm
                                form={form}
                                list={listStateholder}
                                initialValues={{ formKey: formSelect }}
                                optionsList={{
                                    "DIRECTOR_INDIVIDUAL_FORM": {
                                        fieldId: _.get(FactoryChatFormConfig, "DIRECTOR_INDIVIDUAL_FORM" + ".id", "id"),
                                        fieldLabel: _.get(FactoryChatFormConfig, "DIRECTOR_INDIVIDUAL_FORM" + ".fieldName", null),
                                    }
                                }}
                                optionsForm={{
                                    "DIRECTOR_INDIVIDUAL_FORM": {
                                        titleKey: _.get(FactoryChatFormConfig, "DIRECTOR_INDIVIDUAL_FORM.titleKey", null),
                                        educationId: _.get(FactoryChatFormConfig, "DIRECTOR_INDIVIDUAL_FORM.leftEducationId", null),
                                        muttation: _.get(FactoryChatFormConfig, "DIRECTOR_INDIVIDUAL_FORM.muttation", null),
                                        getIdFromMuttation: _.get(FactoryChatFormConfig, "DIRECTOR_INDIVIDUAL_FORM.getIdFromMuttation", null),
                                        paramsMuttaion: initialValueStart,
                                        getVariablesMutation: _.get(FactoryChatFormConfig, "DIRECTOR_INDIVIDUAL_FORM.getVariablesMutation", null),
                                        succesNotification: "NOTIFICATION_SUCCESS",
                                        errorNotification: "NOTIFICATION_BAD",
                                    }
                                }}
                                optionsSelect={{
                                    auto: false,
                                    canSelect: true
                                }}
                                optionsOperation={{
                                    canAdd: true,
                                    canEdit: true,
                                    canRemove: false,
                                }}
                                optionsView={{
                                    recordsByLine: 5
                                }}
                                className={{}}

                            >
                                <FactoryChatForm type={"DIRECTOR_INDIVIDUAL_FORM"} formKey={"DIRECTOR_INDIVIDUAL_FORM"} ></FactoryChatForm>

                            </ClaraCheckForm>
                        )}
                    </Field>
                </div>
                {showButtonAdd && <>
                    <h1><Text uuid={titleButtons} /></h1>
                    <hr />
                    <div className={classes.contentBotonera}>
                        <button className={`${classes.buttonWithAutomatic} ${classes.buttonOption} `} type="button" onClick={() => { setFom("DIRECTOR_INDIVIDUAL_FORM") }}>
                            <Text uuid={`QUESTIONARY_SHAREHOLDER_BUTTON_INDIVIDUAL_EDIT`} />
                        </button>
                    </div>
                </>}
            </div>
        </React.Fragment >
    );
}
export default BeneficialOwnerSelector;
import { useMemo } from 'react';
import Button from "../../../../generic/components/Buttons/Button";
import useModal from "../../../../generic/hooks/useModal";
import useTranslate from '../../../../generic/hooks/useTranslate';
import TemplateFormTwoColumnsSimple from 'src/modules/generic/templates/Modal/TemplateFormTwoColumnsSimple';
import { Stack } from "@mui/material";
import Typography from "src/modules/generic/components/Texts/Typography";
import cls from "./classes.module.scss";
import img from "src/modules/startup/PayManualInvoiceModal/steps/PaySuccessOverdue/imgs/scale-subscription-paid.svg";

/**
 * Step success pay overdue
 * @param {*} param0
 * @returns
 */
function PaySuccessOverdue({ 
  openParam,
}) {

  const { translate } = useTranslate();
  const { closeModal: onClose } = useModal();

  const handleCancel = (values) => {
    onClose();
  }

  const leftComponent = useMemo(() => {
    return (
      <Stack spacing={3}>
        <Typography className={cls["cartTitle"]} weight={"bold"}>
          {translate('MODAL_MANUAL_INVOICE_SUCCESS_TEXT1')}
        </Typography>
        <Typography variant="h2" color={"black"} component={"p"} >
          {translate('MODAL_PAY_OVERDUE_SUCCESS_TEXT1')}
        </Typography>
        <Typography variant="h2" color={"black"} component={"p"} >
          {translate('MODAL_PAY_OVERDUE_SUCCESS_TEXT2')}
        </Typography>
      </Stack>
    )
  }, [])

  const rightComponent = useMemo(() => {
    return (
      <div className={cls["rightContentImage"]}>
        <img src={img} alt="" />
      </div>)
  }, [])

  return (
    <TemplateFormTwoColumnsSimple
      leftComponent={leftComponent}
      rightComponent={rightComponent}
      buttons={{
        submit: <Button variant="primary" onClick={handleCancel}>{translate('BUTTON_GO_MAP')}</Button>,
      }}
    >
    </TemplateFormTwoColumnsSimple>
  );
}

export default PaySuccessOverdue;

import Bubble from '../Bubble'
import BubbleContents from '../BubbleContents';
import Button from 'src/modules/generic/components/Buttons/Button'
import PAGES from 'src/constants/pages';
import React from "react";
import { Typography } from '@mui/material'
import useNavigation from "src/modules/generic/hooks/useNavigation";

/**
  Assistance bubbles
*/
const BubblePendingTask = ({ variant, isEntity = false}) => {
  const {goTo} = useNavigation();
  const goToMyTasks = () => {
    goTo(PAGES.myTasks);
  }
  const text = isEntity ? 
  "There are pending tasks for this company on the My Tasks page. Please complete them all to proceed.":
  "There are pending tasks for this stakeholder on the My Tasks page. Please complete them all to proceed."
  const textAccelerator = "Follow the task list on My Tasks to complete the accelerator onboarding application."
  return (
    <Bubble >
      <BubbleContents>
          <Typography variant="body1" sx={{ marginBottom: '10px', fontSize: "12px"}}>
            { variant === 'ACCELERATOR' ? textAccelerator : text}
          </Typography>
        <Button variant="primary" size="expanded" onClick={goToMyTasks}>
        Go to My Tasks
        </Button>
      </BubbleContents>
    </Bubble>
  );
};


export default BubblePendingTask;

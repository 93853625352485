import React from 'react'
import DialogContent from '@material-ui/core/DialogContent';
import _ from 'lodash'
import classes from './ContainerModal.module.scss'

const ContainerModal = ({ children, dividers = true, className = {}, ...props }) => {

    return (
        <DialogContent
            classes={{
                root: _.get(className, 'root', `${classes.rootDialogContent} scrollbar`)
            }}
            dividers={dividers}>
            {children}

        </DialogContent>
    )
}

export default ContainerModal

import _ from 'lodash';
import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import Button from 'src/modules/generic/components/Buttons/Button';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import CheckboxButtonSimple from 'src/modules/generic/components/Inputs/CheckboxButtonSimple';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import Typography from 'src/modules/generic/components/Texts/Typography';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { addAssistantText, clearAssistatText } from 'src/modules/generic/store/action';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import * as Yup from 'yup';
import classes from '../classes.module.scss';

/**
* Description Step: select the stakeholder o add a third party to be you authorised signatory.
* @returns 
*/
function WhoIsGoingToBeYourAuthorisedSignatoryFormStep() {

  const { next, context, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const { initialValues, stakeholders } = useMemo(() => {

    const initialValues = context?.AddNomineeAuthorisedSignatoryStep ?? {}

    const stakeholders = _.map(_.get(context, "initialData.manualQueryUAEorGCCNotRole", []), (st) => {
      return { value: st, label: st.fullName };
    })

    stakeholders.push({ value: { value: 'ADD_THIRD_PARTY' }, label: 'Add third party' })
    stakeholders.push({ value: { value: 'USE_NOMINEE'}, label: "Clara’s Nominee Authorised Signatory Service" })

    return { initialValues, stakeholders }
  }, [context]);

  const handleCancel = (values) => {
    cancel();
  }
  const handlePrev = (values) => {
    prev();

  }
  const handleSubmit = async (values) => {

    next(values)
  }

  useEffect(() => {
    dispatch(addAssistantText('If you are adding a new third party, this stakeholder will be added to the map and you will have to complete formation tasks for them too.'))
    return () => {
      dispatch(clearAssistatText())
    }
  }, [dispatch])

  const schema = Yup.object().shape({
    authorisedSignatory: Yup.object().nullable().required('Required'),

  })


  return (

    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: 'Who is going to be your ADGM Authorised Signatory?',
          skeleton: null,
          subTitle: 'ADGM Authorised Signatory',
          variant: 'simple'
        }}

        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('BUTTON_NEXT')}</SubmitButton>,
        }}
      >


        <Typography weight='regular' variant='xbody'>
          You can appoint someone who is not a director or shareholder but they must be a UAE Resident or GCC National.
        </Typography>

        <ControllerInput
          render={CheckboxButtonSimple}
          isMultiple={false}
          defaultValue={[]}
          name="authorisedSignatory"
          options={stakeholders}
          classNameButton={classes.buttonCheckBox}
          size="lg"
        />


      </TemplateWizard>
    </Form>
  );
}

export default WhoIsGoingToBeYourAuthorisedSignatoryFormStep;


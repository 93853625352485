import React, { FC, useEffect } from 'react';
import { addAssistantText, clearAssistatText } from 'src/modules/generic/store/action';

import Button from 'src/modules/generic/components/Buttons/Button';
import FormSelectDataProtectionContact from 'src/modules/startup/GroupCompany/Forms/FormSelectDataProtectionContact';
import { StepProps } from 'src/modules/generic/components/Wizard/Step';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import { useDispatch } from 'react-redux';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';

const SelectNewDataProtectionContact: FC<StepProps> = () => {
  const { next, send, context } = useMachine();
  const dispatch = useDispatch();
  const  dataProtectionContact = context?.initialData?.dataProtectionContact;
  const stakeholders = context?.initialData?.stakeholders;
  useEffect(() => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText('<strong>Additional fees</strong> might apply if you need to update some of the details with the ADGM Registrar. </br></br>Click on the field to update to view the corresponding fees. To make a change, just edit the content and proceed. The change request will be added to your cart.','alert'));
    dispatch(addAssistantText('The data protection contact for an ADGM entity is the individual appointed to be the point of contact with ADGM with respect to data protection matters. There are no nationality or residency requirements for this role.'));
    return () => {
        dispatch(clearAssistatText());
        }
  }, []);

  const handleCompleteSubmit = async (values) => {
    next(values);
  }

  const handleCancel = () => {
    send("CANCEL");
  }


  return (
    <>
      <FormSelectDataProtectionContact
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        showDataProtectionContact={true}
        dataProtectionContact={dataProtectionContact}
        stakeholders={stakeholders}
        propsTemplate={{
          title: "Edit data protection contact"
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button>,
        }}
      />
    </>
  );
}

export default SelectNewDataProtectionContact;

import React, {FC, useRef} from 'react'
import * as Yup from 'yup'
import useTranslate from "../../../generic/hooks/useTranslate";
import Form from "../../../../components/forms/Form/FormL";
import ControllerInput from "../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import SubmitButton from "../../../generic/components/Buttons/SubmitButton";
import TemplateFormDefault from "../../../generic/templates/Modal";
import FormProps from "../../../generic/forms/interfaces/FormProps";
import {CheckboxButtonOptions} from "../../../generic/components/Inputs/CheckboxButton";
import CheckboxButtonSimple from "../../../generic/components/Inputs/CheckboxButtonSimple";
import {HiddenFieldForm} from "../../../../components/forms/contents";
import TextareaInput from "../../../../components/inputs/Textarea/TextareaInput";
import classes from './Form.module.scss'
import Typography from "../../../generic/components/Texts/Typography";
import cls from "./Form.module.scss";

const schema=Yup.object().shape({
    isThereSpecialMeaning:Yup.boolean().nullable().required('Required'),
    descriptionOfSpecialMeaning:Yup.string().when(['isThereSpecialMeaning'],{
        is:true,
        then:Yup.string().nullable().required('Required'),
        otherwise:Yup.string().optional()
    })
})
const IsIndividualOrCompany: FC<FormProps> = ({initialValues, onCompleteSubmit, Template = TemplateFormDefault, buttons = {}, buttonSubmitProps, propsTemplate}: FormProps) => {
    const { translate } = useTranslate()

    const options: Array<CheckboxButtonOptions<boolean, string>> = [
        {
            value: false,
            label: translate('MODULES.GROUP_COMPANY.FORM.SPECIAL_MEANING.NO')
        },
        {
            value: true,
            label: translate('MODULES.GROUP_COMPANY.FORM.SPECIAL_MEANING.YES')
        }

    ]

    const refForm=useRef()
    return (
        <>

            <Form
                initialValues={initialValues || {}}
                schema={schema}
                onlySendDirty={false}
                onSubmit={onCompleteSubmit}
                ref={refForm} optionsForm = {{mode:"onChange"}}
            >
                <Template
                buttons={{ ...buttons, submit: <SubmitButton {...buttonSubmitProps}>Next</SubmitButton>}}
                props={propsTemplate}
            >
                <Typography color={'black'} component={'p'} variant={'body'}>
                    {translate('MODULES.GROUP_COMPANY.FORM.SPECIAL_MEANING.EXPLAIN')}
                </Typography>

                <ControllerInput
                    as={CheckboxButtonSimple}
                    name='isThereSpecialMeaning'
                    options={options}
                    className={cls.CheckboxButton}
                    classNameButton={cls.buttonCheckBox}
                />
                <HiddenFieldForm fieldsWatch={'isThereSpecialMeaning'} conditionHideCallback={value=>value}>
                    <div className={classes.describeContainer}>
                            <ControllerInput
                                name={'descriptionOfSpecialMeaning'}
                                render={TextareaInput}
                                className={classes.TextArea}
                                placeholder={translate('MODULES.GROUP_COMPANY.FORM.SPECIAL_MEANING.DESCRIBE_PLACEHOLDER')}
                                defaultValue={''}
                            />
                        </div>
                </HiddenFieldForm>
            </Template>
            </Form>
        </>
    )
}

export default IsIndividualOrCompany

import React, { FC, useEffect, useState } from "react";
import Wizard from "../../../../generic/components/Wizard";
import ModalAssistant from "../../../../generic/components/Modal/ModalAssistant";
import MachineAddressAndCurrency from "./machine";
import { clearAssistatText } from "../../../../generic/store/action";
import { useDispatch } from "react-redux";
import useTranslate from "../../../../generic/hooks/useTranslate";
import useModal from "../../../../generic/hooks/useModal";
import useGetGroupCompanies from "../../../graphql/groupCompany/query/useGetGroupCompanies";
import useSession from "../../../../session/hooks/useSession";

interface ModalAssetsAndSourcesProps {
  paramsMutation: any;
  onClose(): void;
}

const ModalAssetsAndSources: FC<ModalAssetsAndSourcesProps> = ({
  children,
  open: openParam = false,
  paramsMutation,
  ...props
}: ModalAssetsAndSourcesProps) => {

  const [open, setOpen] = useState(openParam);
  const { closeModal: onClose } = useModal();
  const dispatch = useDispatch();
  const { translate } = useTranslate();

  useEffect(() => {
    setOpen(openParam);
  }, [openParam]);
  const handleClose = (values) => {
    onClose();
    setOpen(false);
  };

  const handleInitModal = () => {
    dispatch(clearAssistatText());
  };

  useEffect(() => {
    handleInitModal();
    return () => {
      dispatch(clearAssistatText());
  }
  }, []);

  return (
    <ModalAssistant open={open}>
      <Wizard
        machine={MachineAddressAndCurrency}
        initialValues={{ startup: { groupCompanies: paramsMutation } }}
        onComplete={handleClose}
      />
    </ModalAssistant>
  );
};

export default ModalAssetsAndSources;

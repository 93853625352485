import { getURLAssets } from "src/utils/urlHelper"

const host = getURLAssets();

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  STEP_1: `${host}/onboarding/step1.svg`,
  STEP_2: `${host}/onboarding/step2.svg`,
  STEP_3: `${host}/onboarding/step3.svg`,
  STEP_4: `${host}/onboarding/step4.svg`
};

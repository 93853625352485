import React, { Component } from "react";
import { connect } from "react-redux";
import { goToPage, goToPrevPage } from '../../../../../store/actions/page'
import { Constants } from '../../../../utils/constants'
import classes from "../ViewForms.module.scss";
import { Field, Formik } from "formik";
import TextInput from "../../../../containers/Forms/Inputs/TextInput";
import TextAreaInput from "../../../../containers/Forms/Inputs/TextAreaInput";
import ClaraInputSelect from "../../../Form/ClaraInputSelect/ClaraInputSelect";
import ClaraSelect from "../../../Form/ClaraSelect/ClaraSelect";
import { Text, Date, RoleAccess, Modal } from '../../../index';
import Checkbox from '../../../../../components/inputs/Checkbox/Checkbox'
import ServerConnect from "../../../../utils/ServerConnect";
import notification from "../../../../utils/notification";
import { upsertMatter } from '../../../../graphql/mutations/matters';
import _ from "lodash";

import * as  Yup from 'yup';
import server_helper from "../../../../utils/server_helper";

class Client extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: 'view',
            modalOpen: false,
            confirmSubmitModal: false,
        };
    }

    changeMode = () => {
        if (this.state.mode == 'view') {
            this.setState({ mode: 'edit' })
        } else {
            this.setState({ mode: 'view' })
        }

    };

    renderEmptyInsteadNull = (value) => {
        return value == null ? '' : value
    }

    renderThirdParty = (data) => {
        if (data.KYC) {
            if (data.KYC.thirdParty) {
                if (data.KYC.thirdParty.firstName) {
                    return (<React.Fragment>
                        <hr className={classes.Separator} />
                        <div className={`${classes.ContainerRow}`}>
                            <label>On Behalf Name:</label>
                            <span>{_.get(data.KYC.thirdParty, 'firstName', '') + ' ' + _.get(data.KYC.thirdParty, 'lastName', '')}</span>
                        </div>
                        <div className={`${classes.ContainerRow}`}>
                            <label>On Behalf Number:</label>
                            <span>{_.get(data.KYC.thirdParty.contactNumber, 'prefix', '') + '-' + _.get(data.KYC.thirdParty.contactNumber, 'number', '') + '-' + this.renderEmptyInsteadNull(_.get(data.KYC.thirdParty.contactNumber, 'extension', ''))}</span>
                        </div>
                        {_.get(data.KYC.thirdParty, 'entityName', null) ? <div className={`${classes.ContainerRow}`}>
                            <label>Company:</label>
                            <span>{_.get(data.KYC.thirdParty, 'entityName', '')}</span>
                        </div> : null}
                        <div className={`${classes.ContainerRow}`}>
                            <label>Primary Contact:</label>
                            <span>{this.renderYesOrNo(_.get(data.KYC.thirdParty, 'pointOfContact', ''))}</span>
                        </div>
                    </React.Fragment>)


                } return null
            }
        }

    }

    formatValues = (values) => {
        return {
            createdBy: values.createdBy,
            clientFullName: values.clientFullName,
            address: {
                address: values.address,
                city: values.cityAddress,
                state: values.stateAddress,
                country: values.nationality,
                zipCode: values.zipCode

            },
            contactNumber: values.contactNumber
        }
    }

    isAgent = () => {
        return this.props.session.user.userRole.includes("AGENT");
    }

    submitData = (values) => {

        const variables = {
            matterId: this.props.data.id,
            matterData: this.formatValues(values)
        }
        ServerConnect.graphQlMutation(upsertMatter, variables)
            .then(result => {
                notification.sendNotification("Client form has been regenerated successfully", "success", 4990);
                this.changeMode()
                this.props.updateData()
                this.props.goToPage(Constants.PAGES.viewMatter, { id: this.props.data.id, tab: 'client' });
            }).catch(err => {
                console.log(err);
            });
    }

    getInitialValues = (data) => {
        return {
            fullName: _.get(data.createdBy, 'fullName', ''),
            clientFullName: _.get(data, 'clientFullName', ''),
            nationality: _.get(data.address, 'country', ''),
            cityAddress: _.get(data.address, 'city', ''),
            stateAddress: _.get(data.address, 'state', ''),
            address: _.get(data.address, 'address', ''),
            contactNumber: _.get(data, 'contactNumber', ''),
            zipCode: _.get(data.address, 'zipCode', ''),
            createdBy: _.get(data.createdBy, '_id', '')
        }
    }

    renderYesOrNo = (data) => {
        if (data) {
            return 'Yes'
        } else if (data == false) {
            return 'No'
        }
    }

    handleByStateMatter = ({ id, state: stateMatter }) => {


        const state = stateMatter === 'Closed' ? 'OPEN' : 'CLOSED';

        const mutation = `
        mutation setStatusMatter($matterId: String!, $state: MatterStatesEnum! ){
            setStatusMatter(matterId: $matterId, state: $state){
              state
              id
            }
        }`
        ServerConnect.graphQlMutation(mutation, { state, matterId: id })
            .then(({ setStatusMatter }) => {
                this.props.updateData({ state: setStatusMatter.state });
                this.setState({
                    modalOpen: false,
                    confirmSubmitModal: false
                })
            })
    }

    handleCloseModal = () => this.setState({ modalOpen: false })



    renderViewOrEdit = (data) => {
        const jurisdictionList = [{ 'id': 'PEPE', 'label': 'pepe' }];


        const schemaValidate = Yup.object().shape({
            "clientFullName": Yup.string().required("This field is required").nullable(),
            "nationality": Yup.string().nullable(),
            "cityAddress": Yup.string().nullable(),
            "stateAddress": Yup.string().nullable(),
            "address": Yup.string().nullable(),
            "contactNumber": Yup.string().nullable(),
            "zipCode": Yup.string().nullable(),
            "createdBy": Yup.string().required("This field is required").nullable()
        })

        const nameMatter = `${data.clientFullName} - ${data.sequenceNumber}`
        const stateMatter = !(data.state === 'Closed') ? `Close` : `Reopen`;
        const color = !(data.state === 'Closed') ? '#ff606f' : '';
        switch (this.state.mode) {
            case 'view':
                return (
                    <div className={classes.ContainerView}>
                        {
                            this.isAgent() && <>
                                <span>Action</span>
                                <div style={{ width: "100%", position: 'relative' }} className={classes.botoneraActions}>
                                    <button onClick={() => this.setState({ modalOpen: true })}>{!(data.state === 'Closed') ? 'Close Matter' : 'Reopen Matter'}</button>
                                </div>
                                <hr className={classes.Separator} />

                            </>
                        }
                        <div style={{ width: "100%", position: 'relative' }}>
                            <RoleAccess action={Constants.ACTIONS.MANAGE_MATTER}>
                                <button className={classes.buttonEditFloat} onClick={(e) => { this.changeMode() }}>
                                    <span>Edit Details</span>
                                </button>
                            </RoleAccess>

                            <div className={classes.ContainerData}>
                                <div className={`${classes.ContainerRow}`}>
                                    <label>Service agent</label>
                                    <span>{_.get(data.createdBy, 'fullName')}</span>
                                </div>
                                <div className={`${classes.ContainerRow}`}>
                                    <label>Client name</label>
                                    <span>{_.get(data, 'clientFullName')}</span>
                                </div>
                                <div className={`${classes.ContainerRow}`}>
                                    <label>Email</label>
                                    <span>{_.get(data, 'email')}</span>
                                </div>
                                <div className={`${classes.ContainerRow}`}>
                                    <label>Phone number</label>
                                    <span>{_.get(data, 'contactNumber')}</span>
                                </div>
                                <hr className={classes.Separator} />

                                <div className={`${classes.ContainerRow}`}>
                                    <label>Address</label>
                                    <span>
                                        {data.address ?
                                            <ul>
                                                <li>{data.address.address}</li>
                                                <li>{data.address.city}</li>
                                                <li>{data.address.state}</li>
                                                <li>{data.address.country}</li>
                                                <li>{data.address.zipCode}</li>
                                            </ul>
                                            :
                                            null}
                                    </span>
                                </div>

                                {this.renderThirdParty(data)}
                                <hr className={classes.Separator} />
                                <div className={`${classes.ContainerRow}`}>
                                    <label>Created on</label>
                                    <span><Date value={data.createdAt} /></span>
                                </div>
                                <div className={`${classes.ContainerRow}`}>
                                    <label>Created by</label>
                                    <span>{_.get(data.createdBy, 'fullName')}</span>
                                </div>
                                <div className={`${classes.ContainerRow}`}>
                                    <label>Modified on</label>
                                    <span><Date value={data.updatedAt} /></span>
                                </div>
                                <div className={`${classes.ContainerRow}`}>
                                    <label>Modified by</label>
                                    <span>{_.get(data.createdBy, 'fullName')}</span>
                                </div>
                            </div>

                        </div>

                        {this.state.modalOpen && <Modal showHeader={false} extraStyle={{ borderColor: color }} open={true} handleClose={this.handleCloseModal}>
                            <div className={`${classes.modalClient}  `}>
                                <div>
                                    <h2>{`${stateMatter} ${nameMatter}`}</h2>
                                    <label style={{ color }}>Please confirm by checking the box below.</label>
                                    <Checkbox label={nameMatter} onChange={(value) => this.setState({ confirmSubmitModal: value })} />
                                </div>
                                <div className={classes.containerButtons}>
                                    <button onClick={() => this.setState({ modalOpen: false, confirmSubmitModal: false })} style={{ color: "#828C98" }} className={`genericWhite`} >Cancel</button>
                                    <button style={{ background: color }} onClick={() => this.handleByStateMatter(data)} disabled={!this.state.confirmSubmitModal} className={`genericBlue`}>{`${stateMatter}`}</button>
                                </div>

                            </div>
                        </Modal>}

                    </div>
                );
            case 'edit':
                return (
                    <Formik
                        initialValues={this.getInitialValues(data)}
                        validationSchema={schemaValidate}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            console.log(JSON.stringify(values, 13));
                            this.submitData(values)
                            return data
                        }}
                        isInitialValid={schemaValidate.isValidSync(this.getInitialValues(data))}
                        render={({
                            values,
                            errors,
                            dirty,
                            touched,
                            handleSubmit,
                            isSubmitting,
                            isValid,
                            handleReset,
                        }) => {
                            return (
                                <div className={classes.ContainerView}>
                                    <form onSubmit={handleSubmit}>
                                        <div className={classes.ContainerData}>
                                            <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                                                <label>Client Details</label>
                                            </div>
                                            <div className={`${classes.ContainerRow}`}>
                                                <Field name='createdBy'>
                                                    {({ field, form }) => (
                                                        <ClaraSelect
                                                            className={classes.agentSelector}
                                                            field={field}
                                                            form={form}
                                                            label={'Service Agent'}
                                                            lists={this.props.agents}
                                                            mode={"classic"}
                                                            placeholder={values.fullName}
                                                        />

                                                    )}
                                                </Field>
                                            </div>
                                            <div className={`${classes.ContainerRow}`}>
                                                <Field name={"clientFullName"}>
                                                    {({ field, form }) => (
                                                        <TextInput
                                                            label="Client Name: "
                                                            field={field}
                                                            form={form}
                                                            placeholder={""} />
                                                    )}
                                                </Field>
                                            </div>
                                            <div className={`${classes.ContainerRow} ${classes.ContainerNotEditable}`}>
                                                <label>Email:</label>
                                                <span>{_.get(data, 'email')}</span>
                                            </div>
                                            <div className={`${classes.ContainerRow}`}>
                                                <Field name={"contactNumber"}>
                                                    {({ field, form }) => (
                                                        <TextInput
                                                            label="Number: "
                                                            field={field}
                                                            form={form}
                                                            placeholder={"Contact Number "} />
                                                    )}
                                                </Field>
                                            </div>
                                            <div className={`${classes.ContainerRow}`}>
                                                <hr className={classes.Separator} />
                                                <Field name='nationality'>
                                                    {({ field, form }) => (
                                                        <ClaraInputSelect
                                                            label={'Address: '}
                                                            field={field}
                                                            form={form}
                                                            value={field.value}
                                                            placeholder={"Country"}
                                                            tooltipText={"The nationality of the stakeholder"}
                                                            updateListFunction={null}
                                                            decorator={null}
                                                            list={Constants.COUNTRIES}
                                                            areCountries={true}
                                                            search={true}
                                                            modalTitle={null}
                                                            isEdit
                                                        //tooltip={tooltips[this.getLabel("country")]}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                            <div className={`${classes.ContainerRow}`}>
                                                <Field name={"address"}>
                                                    {({ field, form }) => (
                                                        <TextAreaInput
                                                            label="     "
                                                            field={field}
                                                            form={form}
                                                            placeholder={"Address"} />
                                                    )}
                                                </Field>
                                            </div>
                                            <div className={`${classes.ContainerRow}`}>
                                                <Field name={"cityAddress"}>
                                                    {({ field, form }) => (
                                                        <TextInput
                                                            label="      "
                                                            field={field}
                                                            form={form}
                                                            placeholder={"City"} />
                                                    )}
                                                </Field>
                                            </div>
                                            <div className={`${classes.ContainerRow}`}>
                                                <Field name={"stateAddress"}>
                                                    {({ field, form }) => (
                                                        <TextInput
                                                            label="     "
                                                            field={field}
                                                            form={form}
                                                            placeholder={"State/Region"} />
                                                    )}
                                                </Field>
                                            </div>
                                            <div className={`${classes.ContainerRow}`}>
                                                <Field name={"zipCode"}>
                                                    {({ field, form }) => (
                                                        <TextInput
                                                            label="     "
                                                            field={field}
                                                            form={form}
                                                            placeholder={"Zip Code/Post Code"} />
                                                    )}
                                                </Field>
                                            </div>

                                        </div>
                                        <div className={classes.Botonera}>
                                            <button type="submit" data-cy="submit" disabled={!isValid}>
                                                Save Changes
                                    </button>
                                            <button type="button" onClick={(e) => { this.changeMode() }}>
                                                Cancel
                                    </button>
                                        </div>
                                    </form >
                                </div >
                            );
                        }} />
                )
        }
    }

    render() {
        let { data, user } = this.props;


        if (!data) {
            return null;
        } else {
            return this.renderViewOrEdit(data)
        }


    }
}

const mapStateToProps = state => ({
    session: state.session,

});
const mapDispatchToProps = (dispatch) => ({
    goToPrevPage: () => {
        goToPrevPage(dispatch)
    },
    goToPage: (page, params) => {
        goToPage(dispatch, page, params)
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Client)

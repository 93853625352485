const TEXTS = {
    TITLE_COMPANY: 'Is there a company Shareholder Register listing this share position?',
    TITLE_SHARE: 'Select the companies in which they have a share position',
    TITLE_CONVERTIBLE: 'Select the companies in which they have a convertible',
    TITLE_OPTION: 'Select the companies in which they have an option grant',
    TITLE_WARRANT: 'Select the companies in which they have a warrant',
    SUBTITLE: 'Add share position',
    SUBTITLE_SHARE: 'Equity positions - Add share position',
    SUBTITLE_CONVERTIBLE: 'Equity positions - Add convertible',
    SUBTITLE_OPTION: 'Equity positions - Add option grant',
    SUBTITLE_WARRANT: 'Equity positions - Add warrant'
}
export default TEXTS
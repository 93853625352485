import useLazyQuery from 'src/hooks/custom/useLazyQuery';

const GET_SHAREHOLDERS_BALANCE = `
query getShareholdersBalance($startupId: ID!, $groupCompanyId: ID!) {
  getShareholdersBalance(startupId: $startupId, groupCompanyId: $groupCompanyId) {
    ...on StakeHolder {
            id
            fullName
            address {
              street
              city
              state
              country {
                code
              }
              zipCode
            }
        }
    ...on GroupCompany {
            id
            name
            mailingAddress {
              street
              city
              state
              country {
                code
              }
              zipCode
              type
            }
        }
  }
}`;

export const useGetShareholdersBalance = (variables: any, config?: any) => {
  const { manualQuery, loading, error, data, refetch } = useLazyQuery(GET_SHAREHOLDERS_BALANCE, variables, config);
  return { loading, error, data, refetch, manualQuery };

}

export default useGetShareholdersBalance;

import React, { FC } from "react";
import Wizard from "src/modules/generic/components/Wizard/v2";
import useActions from "./hooks/useActions";
import useGuards from "./hooks/useGuards";
import useServices from "./hooks/useServices";
import useSteps from "./hooks/useSteps";
import machine from "./machine";

interface Props {
  initialValues: any;
  onFinish: () => void;
  onCancel: () => void;
}

const ReviewAuthorisedSignatoryWizard: FC<Props> = ({
  initialValues = {},
  onFinish,
  onCancel,
}) => {
  const guards = useGuards();
  const actions = useActions();
  const steps = useSteps();
  const services = useServices({ initialValues });

  return (
    <Wizard
      guards={guards}
      machine={machine}
      steps={steps}
      services={services}
      actions={actions}
      onStopMachine={onFinish}
      onCancelMachine={onCancel}
    />
  );
};

export default ReviewAuthorisedSignatoryWizard;

import useCustomMachine from "../../../../generic/hooks/useCustomMachine";
import React, { FC } from "react";
import Button from "../../../../generic/components/Buttons/Button";
import { StepProps } from "../../../../generic/components/Wizard/Step";
import TemplateWizard from "../../../../generic/templates/Modal/TemplateWizard";
import useTranslate from "../../../../generic/hooks/useTranslate";
import Form from "../../Forms/FormDeclineDocument";
import useFormationDeclineDocument from '../../../../../modules/formation/hooks/useFormationDeclineDocument';
import useSession from "../../../../../modules/session/hooks/useSession";
import _ from "lodash";

export interface Step1Props  {
  documentId?: string,
}

const Step2: FC<StepProps> = () => {

  const { translate } = useTranslate();
  const { next, send, state } = useCustomMachine();
  const { startupId } = useSession();

	const { mutation: mutationDeclineDocument } = useFormationDeclineDocument({
		showSuccessNotification: false
	});

  const handleCompleteSubmit = async (values) => {
		await mutationDeclineDocument({
			startupId: startupId,
			documentId: _.get(state, "context.data.documentId"),
			issues: values.declineReason,
		});
    next(values);
  };
  
  const handleCancel = async (values) => {
    send("CANCEL");
  };

  const handleBack = async (values) => {
    send("PREVIOUS");
  };

  return (
    <React.Fragment>
      <Form
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: translate('MODULES.GROUP_COMPANY.DOCUMENT_REVIEW.SECOND_STEP.TITLE'),
          subTitle: translate('MODULES.GROUP_COMPANY.DOCUMENT_REVIEW.SECOND_STEP.SUBTITLE')
        }}
        buttons={{
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate('MODULES.CLARA.BTN_CANCEL')}
            </Button>
          ),
          previous: (
            <Button onClick={handleBack} variant="secondary">
              {translate('MODULES.CLARA.BTN_PREVIOUS')}
            </Button>
          ),
        }}
      ></Form>
    </React.Fragment>
  );
};

export default Step2;

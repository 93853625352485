import { default as React, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import Button from "src/modules/generic/components/Buttons/Button";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import { clearAssistatText } from "src/modules/generic/store/action";
import { ICONS } from "src/modules/generic/components/Inputs/FileUpload/NoFilesUploaded";
import TemplateWizardPreview from "src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardPreview";
import FormExpiredDocument from "src/modules/startup/Stakeholder/ADGM/components/Forms/FormExpiredDocument";

function DocumentDetailsStep() {
  const { next, context, cancel, prev } = useMachine();
  const dispatch = useDispatch();
  const document = context?.initialData?.document;
  const documentType = context?.initialData?.documentType;

  const initialValues = useMemo(() => {
    return (
      {
        ...context?.DocumentDetailsStep,
        nationality: context?.initialData?.country?.code,
        files: context?.UploadDocumentStep?.files,
      } ?? {}
    );
  }, [context]);

  const handleCancel = () => cancel();

  const handleSubmit = async (values) => next(values);

  const handlePrev = () => prev();

  useEffect(() => {
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  return (
    <FormExpiredDocument
      initialValues={initialValues}
      onCompleteSubmit={handleSubmit}
      buttons={{
        cancel: (
          <Button onClick={handleCancel} variant="secondary">
            Cancel
          </Button>
        ),
        previous: (
          <Button onClick={handlePrev} variant="secondary">
            Previous
          </Button>
        ),
      }}
      documentType={documentType}
      propsTemplate={{
        title: "Add document details",
        subTitle: `Expired ${document}`,
        fileUploadProps: {
          accept: "image_pdf",
          icons: [ICONS.JPG, ICONS.PNG],
          name: "files",
          limit: 1,
          defaultValue: [],
        },
      }}
      Template={TemplateWizardPreview}
    />
  );
}

export default DocumentDetailsStep;

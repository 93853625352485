import useMutation from "src/hooks/custom/useMutation";

export const UPSERT_CONCIEGE_MAIL = `
  mutation engageConciergeEmailWithoutCompany($startupId: ID!, $emailData: FormationEmailDataInputType) {
    engageConciergeEmailWithoutCompany(
      startupId: $startupId
      emailData: $emailData
    ){
      id
    }
  }
`;

const useUpsertConciergeMail = () => {
    const [mutation, loaging] = useMutation(
        UPSERT_CONCIEGE_MAIL,
        { showSuccessNotification: false }
    ); 
    return mutation;
};

export default useUpsertConciergeMail;

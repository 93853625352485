import { Constants, FORMIK_CONSTANTS } from "./constants";
import classes from "../containers/Forms/SteperForm.module.scss";
import { Field } from "formik";
import React from "react";
import ClaraInputSelect from "../components/Form/ClaraInputSelect/ClaraInputSelect";
import TextInput from "../containers/Forms/Inputs/TextInput";
import DocumentInput from "../containers/Forms/Inputs/DocumentInput";
import * as _ from "lodash";
import { stripTypenames } from "./graphql-util";

const getArrayName = (name) => {
    return name.split('[')[0]
};

const getIndex = (name) => {
    return Number(name.split('[')[1].split(']')[0]);
};

const getFieldName = (name) => {
    return name.split('[')[1].split(']')[1].split('.')[1];
};

const getIndirections = (name) => {
    return name.split('.');
};

//TODO: Refactor
const setValueInTheCorrectPosition = async (name, form, fieldValue) => {
    const newValues={...form.values}
    _.set(newValues,name,fieldValue)
    await form.setValues({
        ...form.values,
        ...newValues
    });
    return {
        ...form.values,
        ...newValues
    }
    /*
    if (name.split('[')[1]) {
        const arrayName = getArrayName(name);
        const index = getIndex(name);
        const fieldName = getFieldName(name);

        const newArray = form.values[arrayName] || [{}];

        if (!newArray[index]) {
            newArray[index] = {};
        }

        newArray[index][fieldName] = fieldValue;

        form.setValues({
            ...form.values,
            [arrayName]: newArray
        })
    } else {
        form.setValues({
            ...form.values,
            [name]: fieldValue
        })
    }*/
};

const returnErrorsForValidation = (name, errors) => {
    if(errors && _.size(errors)) {
        if (name.split('[')[1]) {

            return _.get(errors,name,null);
        } else {

            return flattenObject(errors)[name];
        }
    }
};

function flattenObject(ob) {
    var toReturn = {};

    for (var i in ob) {
        if (!ob.hasOwnProperty(i)) continue;

        if ((typeof ob[i]) == 'object' && ob[i] !== null) {
            var flatObject = flattenObject(ob[i]);
            for (var x in flatObject) {
                if (!flatObject.hasOwnProperty(x)) continue;

                toReturn[i + '.' + x] = flatObject[x];
            }
        } else {
            toReturn[i] = ob[i];
        }
    }
    return toReturn;
}

/*
Clean empty REQUIRED fields in arrays:
    const deletedString = Constants.FORMIK.DELETED_FIELDS.STRING;
    const deletedNumber = Constants.FORMIK.DELETED_FIELDS.NUMBER;

    -In order to clean equity grants (shares, options, convertibles and warrants)
        you have to remove the grants with groupCompany === deletedString
    -In order to clean the documents, you have to remove the docs with no elements
        (The documents doesn't have required fields)
*/

const cleanEmptyDocuments = (documents) => {
    const array = [];

    if (documents) {
        documents.forEach(doc => {
            if (Object.keys(doc).length > 0) {
                array.push(doc);
            }
        });
    }

    return array;
};

const mapStakeholderDataForMutation = (cleanData, type) => {

    const {
        fullName,
        email,
        address,
        avatar = [],
        passportNumber,
        nationality,
        jurisdiction,
        otherJurisdiction,
        roles = {},
        positions = {},
    } = cleanData;

    const mutationRoles = [];
    const mutationPositions = [];

    delete roles.dummyKey;

    const rolesKey = Object.keys(roles);


    let auxMutationRoles = {};

    rolesKey.forEach(gc => {

        const types = Object.keys(roles[gc])

        types.forEach(type => {
            if (roles[gc][type] === true) {
                if (!auxMutationRoles[gc]) {
                    auxMutationRoles[gc] = [];
                }
                auxMutationRoles[gc].push(type)

            }
        });


    });
    _.forEach(auxMutationRoles, (role, key) => {
        mutationRoles.push({ groupCompany: key, role: role });
    });

    _.forEach(positions, (positionData, key) => {
        let newPosition = {};
        if (positionData.position) {
            const position = _.pick(positionData.position, ['id', 'name']);
            newPosition = { position };
        }
        if (positionData.department) {
            const { department } = positionData;
            newPosition = { ...newPosition, departmentId: department.id }
        }
        if (positionData.responsibilities) {
            const { responsibilities: responsibilitiesData } = positionData;
            const responsibilities = _.map(responsibilitiesData, ({ id, text, isDefault }) => ({ id, text, isDefault }));
            newPosition = { ...newPosition, responsibilities }

        }

        mutationPositions.push({
            position: null,
            departmentId: null,
            responsibilities: [],
            groupCompanyId: key,
            ...newPosition
        });

    });


    let stakeholder = {
        stakeholder: {
            avatar: avatar && Array.isArray(avatar) ? avatar[0] : avatar,
            fullName,
            passportNumber : passportNumber ? passportNumber : "",
            nationality: (nationality && !_.isEmpty(nationality.code)) ? _.get(nationality, "code", "") : "",
            email,
            roles: !_.isEmpty(mutationRoles) ? mutationRoles : undefined,
            address: { ...address, country: _.get(address, "country.code", "") },
            positions: !_.isEmpty(mutationPositions) ? mutationPositions : undefined,
        }
    };
    stakeholder.stakeholder.isAnEntity = false;

    if (type === Constants.FORMS.STAKEHOLDER_COMPANY) {
		stakeholder.stakeholder.isAnEntity = true;
		stakeholder.stakeholder.authorizedSignatory = cleanData.authorizedSignatory;
        stakeholder.stakeholder.jurisdiction = jurisdiction === "OTHER" ? otherJurisdiction : jurisdiction;
        stakeholder.stakeholder.type = cleanData.type;
        stakeholder.stakeholder.registeredNumber = cleanData.registeredNumber;
		stakeholder.stakeholder.recipientNotice = {
			...cleanData.recipientNotice,
			useRegisteredAddress: cleanData.recipientNotice.useRegisteredAddress,
			address: {
				...cleanData.recipientNotice.address,
				country: !_.isEmpty(_.get(cleanData, 'recipientNotice.address.country.code'))
					? _.get(cleanData, 'recipientNotice.address.country.code')
					: _.get(cleanData, 'recipientNotice.address.country.name')
			}
		}
    } else {
        stakeholder.stakeholder.passportNumber = cleanData.passportNumber;
    }
    return stakeholder
};

const getFileName = (file) => {
    return file.name.split('.')[0];
};

const mapAgreementDataForMutation = (cleanData) => {

    

    let {
        documentType: type,
        agreementName: title,
        file,
        filepond,
        agreementStatus: status,
        parties,
        executionDate: executedOnDate,
        category,
        subCategory,
        healthChecks,
        expirationDate,
        verified,
        IPAssignmentProvisions
    } = cleanData;
    //TODO: fix this patch
    type = type.code ? type.code : type;


    let agreement = {
        type,
        title: title ? title : getFileName(file ? file : filepond),
        executedOnDate,
        expirationDate,
        file: file ? file : filepond,
        status,
        subCategories: getSubCategories(subCategory, healthChecks, IPAssignmentProvisions),
        verified,
        parties: parties.filter(p => p.party.type === 'stakeholder').map(p => (p.party.id) ? p.party.id : p.party),
        groupCompanies: parties.filter(p => p.party.type === 'groupCompany')
            .map(p => ({
                groupCompany: (p.party.id) ? p.party.id : p.party,
                authorizedSignatories: p.authorizedSignatory && p.authorizedSignatory[0] && p.authorizedSignatory[0].id ? [p.authorizedSignatory[0].id] : [p.authorizedSignatory]
            }))
    };
    agreement = stripTypenames(agreement);
    return { agreement };
};

const getSubCategories = (subCategory, healthChecks, IPAssignmentProvisions) => {
    if (subCategory) {
        return IPAssignmentProvisions ? [subCategory, 'IPAssignment'] : [subCategory];
    } else {
        return IPAssignmentProvisions ? [healthChecks[0].subCategory, 'IPAssignment'] : [healthChecks[0].subCategory];
    }
};

const mapGroupCompanyDataForMutation = (cleanData) => {

    const {
        name,
        address: registeredAddress,
        avatar = [],
        country,
        jurisdiction,
        otherJurisdiction,
        registeredNumber,
        transactionCurrency,
        type,
        roles = [],
        isHoldingCompany,
        incorporation_date,
        workingAddress,
        hasSameWorkingAddress,
        extraCurrencies,
		recipientNotice
    } = cleanData;

    const mutationRoles = [];

    roles.forEach(role => {

        const gcs = Object.keys(role);

        gcs.forEach(gc => {

            const types = Object.keys(role[gc]);

            types.forEach(type => mutationRoles.push({ groupCompany: gc, role: type }))

        });

    });
    const groupCompany = {
        groupCompany: {
            name,
            avatar: avatar && Array.isArray(avatar) ? avatar[0] : avatar,
            type,
            country: _.get(country, "code" ) ? _.get(country, "code" )  : '',
            registeredNumber,
            registeredAddress: { ...registeredAddress, country: _.get(registeredAddress, "country.code" ,null) },
            jurisdiction: jurisdiction === "OTHER" ? otherJurisdiction : jurisdiction,
            transactionCurrency,
            isHoldingCompany,
            incorporation_date: incorporation_date,
            recipientNotice: {
				...recipientNotice,
				address: { ...recipientNotice.address, country: _.get(recipientNotice.address, "country.code") },
			},
            workingAddress: { ...workingAddress, country: _.get(workingAddress, "country.code") },
            hasSameWorkingAddress,
            extraCurrencies: _.compact(extraCurrencies)
        }
    };


    return stripTypenames(groupCompany)


};

const cleanEmptyGrants = (grants) => {
    const array = [];

    if (grants) {
        grants.forEach(grant => {
            if (grant.groupCompany !== FORMIK_CONSTANTS.DELETED_FIELDS.STRING) {
                array.push(grant);
            }
        });
    }

    return array;
};

const addDocument = (component, list) => {
    const newDocs = component.state.docs;
    const i = newDocs.length;

    newDocs.push(
        <div key={i} className={classes.ContainerAdd}>
            <div className={classes.CardHeader}>
                <h3>Document</h3>

                <Field>
                    {({ field, form }) => (
                        <a onClick={(e) => {
                            component.removeDocument(i, form)
                        }} className={classes.Remove}>Remove</a>
                    )}
                </Field>

            </div>
            <div className={`${classes.ContainerRow} ${classes.Select}`}>
                <Field name={`documents[${i}].type`}>
                    {({ field, form }) => (
                        <ClaraInputSelect
                            label={"Document Category: "}
                            field={field}
                            form={form}
                            value={field.value}
                            placeholder={"Select from a list.."}
                            tooltipText={"The document category"}
                            updateListFunction={null}
                            callbackUpdate={null}
                            decorator={null}
                            list={list}
                            search={true}
                            modalTitle={null}
                        />
                    )}
                </Field>
            </div>
            <div className={classes.ContainerRow}>
                <Field name={`documents[${i}].name`}>
                    {({ field, form }) => (
                        <TextInput label="Document Name: " field={field} form={form} placeholder={"Type here..."} />
                    )}
                </Field>
            </div>
            <div className={classes.ContainerRow}>
                <Field name={`documents[${i}].file`}>
                    {({ field, form }) => (
                        <DocumentInput name={`filepond`} label="Upload your document: " field={field} form={form} />
                    )}
                </Field>
            </div>
            <hr className={classes.Separator}></hr>
        </div>
    );

    return newDocs;
};

const removeDocument = (component, index, form) => {
    let newDocs = component.state.docs;

    const deletedString = FORMIK_CONSTANTS.DELETED_FIELDS.STRING;
    const deletedNumber = FORMIK_CONSTANTS.DELETED_FIELDS.NUMBER;
    const deletedDocument = undefined;

    if (newDocs) {
        newDocs.splice(index, 1, undefined);

        component.setState({
            docs: newDocs
        });
    }

    let formDocuments = form.values.documents;

    if (formDocuments) {
        formDocuments.splice(index, 1, deletedDocument);

        form.setValues({
            ...form.values,
            documents: formDocuments
        })
    }
};

const mapEquityPositionDataForMutation = (data, type) => {


    const {
        id,
        owner,
        amount,
        price,
        shareClass,
        currency,
        associatedDocuments,
        issuedDate,
        shareIncentivePlan,
        defaultShareClass
    } = data;

    const equityData = {
        id,
        amount: parseFloat(amount),
        price: parseFloat(price),
        shareClass: shareClass ? shareClass.id : defaultShareClass.id,
        currency: !currency ? "USD" : currency,
        documents: associatedDocuments ? associatedDocuments : {},
        issuedDate,
        shareIncentivePlan: (type === 'OPTION' && shareIncentivePlan) ? shareIncentivePlan.id : null

    };

    return {
        owner,
        equityData
    };
};

const mapConvertibleDataForMutation = (data) => {

    const {
        company,
        owner,
        amount,
        price,
        shareClass,
        currency,
        associatedDocuments,
        issuedDate,
        discountRate,
        interestRate,
        valuationCap,
        shareIncentivePlan,
        valuation,
        transactionCurrency,
        id
    } = data;

    const convertibleData = {
        // company,
        id,
        amount: parseFloat(amount),
        price: parseFloat(price),
        currency: !currency ? "USD" : currency,
        documents: associatedDocuments ? associatedDocuments : {},
        issuedDate,
        discountRate: parseFloat(discountRate),
        interestRate: parseFloat(interestRate),
        valuationCap: parseFloat(valuationCap),
        groupCompany: company.id
    };


    return {
        owner,
        convertibleData
    };
};

const mapWarrantDataForMutation = (data) => {

    const {
        company,
        owner,
        amount,
        price,
        currency,
        associatedDocuments,
        issuedDate,
        valuation,
        id
    } = data;

    const warrantData = {
        // company,
        amount: parseFloat(amount),
        currency: !currency ? "USD" : currency,
        documents: associatedDocuments ? associatedDocuments : {},
        issuedDate,
        valuation: parseFloat(valuation),
        groupCompany: company.id,
        id
    };


    return {
        owner,
        warrantData
    };
};

export default {
    setValueInTheCorrectPosition,
    returnErrorsForValidation,
    cleanEmptyDocuments,
    cleanEmptyGrants,
    mapStakeholderDataForMutation,
    mapAgreementDataForMutation,
    mapGroupCompanyDataForMutation,
    mapEquityPositionDataForMutation,
    mapConvertibleDataForMutation,
    mapWarrantDataForMutation,
    addDocument,
    removeDocument
}

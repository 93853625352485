import useMutation from "../../../../../../../hooks/custom/useMutation";

export const DELETE_STAKEHOLDER_ROLES = `
mutation deleteStakeholderRoles($startupId: ID!, $stakeholderId: ID!, $stakeholderRolesData: StakeholderRolesInput!) {
    deleteStakeholderRoles(startupId: $startupId, stakeholderId: $stakeholderId, stakeholderRolesData: $stakeholderRolesData)
    {
        id
    }
}
`;


const useDeleteStakeholderRoles = (config, props) => {
    const [ mutation, loading] = useMutation(DELETE_STAKEHOLDER_ROLES, config, {  ...props });
    return { mutation, loading };
}

export default useDeleteStakeholderRoles;
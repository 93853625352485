import _ from "lodash";
import { Field } from "formik";
import moment from "moment";
import React, { Component } from 'react';
import AuthorisedSignatories from "../../../../../components/inputs/AuthorizedSignatories/AuthorizedSignatories";
import CheckBox from "../../../../../components/inputs/Checkbox/Checkbox";
import { ClaraInputYearsMonths, TextAreaInput,ClaraRadioWithButton,TextType } from "../../../../components";
import ClaraCountrySelector from "../../../../components/Form/ClaraCountrySelector/ClaraCountrySelector";
import ClaraCurrencySelector from "../../../../components/Form/ClaraCurrencySelector/ClaraCurrencySelector";
import ClaraSelect from "../../../../components/Form/ClaraSelect/ClaraSelect";
import ClaraUpload from "../../../../components/Form/ClaraUpload/ClaraUpload";
import FormikHelper from "../../../../utils/formikHelper";
import Checkbox from '../../Inputs/Checkbox';
import TextInput from "../../Inputs/TextInput";
import classes from "../../SteperForm.module.scss";
import {ServerConnect} from '../../../../utils';
import { GET_POSITIONS } from '../../../../../modules/startup/graphql/stakeholder/query/useGetPositions';
import SelectVestingTerms from "../../../../../components/inputs/Select/SelectVestingTerms";
import Tag from "../../../../../components/inputs/Tag";
import Accordion from '../../../../../components/inputs/Accordion'
import {FREQUENCY} from './helper'
import {
	currencyFormat,
	amountFormat,
	groupCompanyRecipientNotice,
	stakeholderAuthorizedSignatory,
	stakeholderRecipientNotice
} from "./helper";
import SelectShareClasses from "../../../../../components/inputs/Select/SelectShareClass";
import { Notifications } from "../../../../components";

class FounderServicesAgreementDoc extends Component {

    constructor(props) {
        super(props);
        this.state = {
            typeSelected: undefined,
            founderSelected: undefined,
            groupCompanySelected: undefined,
            authorizedSelected: undefined,
            authorizedSignatories: [],
            authorizedSignatoriesList: [],
            documentTypes: [],
            documentTypesList: [],
            dynamicParams: [],
            date: undefined,
            responsibilities: [],
            companyOrFounderUpdated:false,
			positions: [],
			positionsList: [],
            isAdd:false,
            isThereVesting:false,
            founder:null,
            showVestingTerms:false,
            showShareClass:false,
            isAddVestingTerms:false,
            isAddSelectShareClass:false
        }
    };
    mapShareToForm(sh){
        return {
            ...sh,
            isThereVestingTerms:!!sh.vestingSchedule,
            amountOfShares:sh.quantity,
            pricePerShare:sh.sharePrice.amount,
            areTheSharesNumbered:!!sh.shareNumbersIssued,
            numberedShares:sh.shareNumbersIssued?{
            startingShareNumber:sh.shareNumbersIssued?.startingShareNumber,
        endingShareNumber:sh.shareNumbersIssued?.endingShareNumber
            }:null,
            vestingTerms:sh.vestingSchedule?{
                ...sh.vestingSchedule?.vestingTerm?.standardTerm,
                isThereCliff:!!sh.vestingSchedule?.vestingTerm?.isThereCliff,
                id:sh.vestingSchedule?.vestingTerm?.id,
                vestingStartDate:sh.vestingSchedule.vestingStartDate
            }:null
        }
    }
    onChangeIsThereVesting = (v, form) => {
        if(v){
            const groupCompany = this.findGroupCompany(form.values.company.id);
            const shares = _.flatMap(groupCompany.advanceCapTable.shareClasses,sh=>sh.shareIssuances)
            const sharesFiltered = (shares || []).filter(si=>si.holder.id===form.values.founder.id && si.status.state !== 'CANCELLED' && si.status.state !== 'TRANSFERRED')
            if(!(form.values.founder.share && form.values.founder.share.length >0)){
            FormikHelper.setValueInTheCorrectPosition('founder.share',form,sharesFiltered.map(this.mapShareToForm))
            FormikHelper.setValueInTheCorrectPosition('founder.isThereVesting',form,true)
            }
        this.setState({
            founder:{...(form.values.founder || {}),share:sharesFiltered},
            isThereVesting:v
        })
        return;
    }
    this.setState({
        founder:null,
        isThereVesting:v
    })
    };

    handleOnChangeIsThereCliff = (v, form) => {
        if (!v) {
            FormikHelper.setValueInTheCorrectPosition('founder.share[0].vestingTerms.cliffPeriod', form, null);
            FormikHelper.setValueInTheCorrectPosition('founder.share[0].vestingTerms.isThereCliff', form, v);
        }
    };
    renderShareClassFields(){
        return (<>
        <Field name='founder.share[0].shareClass.name'>
        {({ field, form }) => (
            <div className={`${classes.ContainerRow}`}>
                <TextInput
                    field={field}
                    form={form}
                    disabled={this.state.isAddSelectShareClass}
                    label={'Share class name'}
                    required={true}
                    tooltip={'Share class name'}
                /></div>
        )}
    </Field>
                <div style={{marginTop:'1rem'}}>
                <Notifications show={true} type={"success"}>
                This will create and publish a new share class for {this.state.groupCompanySelected.name}. The share class created will be non-preferred and will carry 1 vote per share. You can add or amend the details of this share class from inside Equity.
                </Notifications>
                </div>
                </>)
    }

    componentWillMount() {
        if (this.props.values) {
            const values = this.props.values.values ? this.props.values.values : this.props.values;
            this.setState({
                typeSelected: values.documentType,
                founderSelected: values.founder,
                groupCompanySelected: values.company,
                authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.company).map(as => ({
                    id: as.id,
                    label: as.label
                }))
            });
        }

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.values) {
            const values = nextProps.values.values ? nextProps.values.values : nextProps.values;
            this.setState({
                authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.company)
                    .map(as => ({
                        id: as.id,
                        label: as.label
                    }))
            });
        }
    }

	componentDidMount() {
		this.loadPositions(this.props);
	}

    titleShare(sh){
        return <div className={classes.titleShare}>
            <h1>
            {sh.shareClass.defaultIdPrefix}-{sh.customId}
            </h1>
            <Tag label={sh.status.state}/>
        </div>
    }

    detailsShare(s){
        const detailsKeys = [
            'issuedDate',
        'shareClass',
        'numberOfShares',
        'pricePerShare',
        'startingShareNumber',
        'endingShareNumber',
        'vestingTerms',
        'vestingStartDate',
        'vestingFrequency',
        'vestingPeriod',
        'cliffPeriod']
        const getValueByKey = (d,s)=>{
            const keyValue = {
                issuedDate:()=>s.issuedDate?moment(s.issuedDate).format('DD MMM YYYY'): '-',
                shareClass:()=>s.shareClass.name,
                numberOfShares:()=>amountFormat(s.quantity),
                pricePerShare:()=>currencyFormat(s.sharePrice, { defaultEmpty: 0 }),
                startingShareNumber:()=>amountFormat(s.shareNumbersIssued?.startingShareNumber),
                endingShareNumber:()=>amountFormat(s.shareNumbersIssued?.endingShareNumber),
                vestingStartDate:()=>s.vestingSchedule?.vestingStartDate?moment(s.vestingSchedule?.vestingStartDate).format('DD MMM YYYY'):'-',
                vestingTerms:()=>s.vestingSchedule?.vestingTerm?.name || 'None',
                vestingPeriod:()=>s.vestingSchedule?.vestingTerm?.standardTerm?.vestingPeriod || '-',
                cliffPeriod:()=>s.vestingSchedule?.vestingTerm?.standardTerm?.cliffPeriod || '-',
                vestingFrequency:()=>FREQUENCY[s.vestingSchedule?.vestingTerm?.standardTerm?.vestingFrequency]
            }
            return keyValue[d]?keyValue[d]():s[d]
        }
        return <div className={classes.detailsShare}>
            {detailsKeys.map((d,index)=>!s.vestingSchedule &&  (d === 'vestingPeriod' ||  d === 'cliffPeriod' || d === "vestingStartDate" || d === "vestingFrequency")?null:(
            <div className={classes.detailsColumn} id={index}>
                <div className={classes.shareLabel}>
                    {d==='cliffPeriod'?'Cliff period (months)':_.capitalize(_.startCase(d))}
                </div>
                <div className={classes.shareDetails}>
                    {getValueByKey(d,s)}
                </div>
            </div>
            ))
            }
        </div>
    }

    shareholdingDetails(){
        const isRequired = message => value => (!!value ? undefined : message);
        return <>{this.state.founder.share.map((s,index)=> (
        <div className={classes.shareholdingTerms}>
        <Accordion id={index} label={this.titleShare(s)} details={this.detailsShare(s)} />
        {!s.vestingSchedule && <>
            <Field name='founder.vesting_schedule_error'>
                                {({ field, form }) => (
                                                                            <span hasError={_.get(form.errors,`founder.share[${index}].vestingTerms` ) ? "true" : "false"}>
        <p style={_.get(form.errors,`founder.share[${index}].vestingTerms` )?{color:'#FF606F'}:null}>This share position is not subject to any vesting terms. To add vesting, please go to “Equity” and edit this share position to include the relevant vesting terms.</p>
                                </span>)}
                                </Field>
        </>
        }
        </div>))}
        {this.state.founder.share.some(s=>s.vestingSchedule) &&
            <Field name='founder.confirmShares'>
                        {({ field, form }) => (
                            <Checkbox
                                className={classes.sameDataCheckbox}
                                field={field}
                                validate={isRequired('This field is required')}
                                form={form}
                                required={true}
                                label='I confirm the share position(s) listed above reflect the shareholding of the founder.'
                                errorMessage={this.fieldHasError(form, field)}
                                onChange={(value) => { form.validateField(field.name) }}
                            />
                        )}
                    </Field>}
        </>
    }
    renderVestingTermsFields(isDisabled){
        const { tooltips = {} } = this.props

        return (<>
        <Field name='founder.share[0].vestingTerms.vestingPeriod'>
        {({ field, form }) => (
            <div className={`${classes.ContainerRow}`}>
                <TextInput
                    field={field}
                    form={form}
                    disabled={isDisabled}
                    label={'Vesting period'}
                    required={true}
                    rightLabel={"Months"}
                    tooltip={tooltips["Vesting Period"]}
                /></div>
        )}
    </Field>
    <Field name='founder.share[0].vestingTerms.vestingFrequency'>
        {({ field, form }) => {
            return (
                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                    <ClaraSelect
                        label={'Vesting frequency '}
                        required={true}
                        field={field}
                        form={form}
                        disabled={isDisabled}
                        mode={'classic'}
                        placeholder={'Select from the list'}
                        tooltip={tooltips['Vesting Frequency']}
                        lists={[
                            {
                                id: 'MONTHLY',
                                label: 'Monthly',
                            },
                            {
                                id: 'QUARTERLY',
                                label: 'Quarterly',
                            },
                            {
                                id: 'ANNUALLY',
                                label: 'Annually',
                            }
                                                                            ]}
                                            />
                                        </div>
                                    )
                                }}
                            </Field> 
                            <div className={classes.alignWithInputDate}>
                                <Field name={'founder.share[0].vestingTerms.isThereCliff'}>
                                    {({ field, form }) => (
                                        <ClaraRadioWithButton
                                            required={true}
                                            label={'Is there a cliff period? '}
                                            field={field}
                                            disabled={isDisabled}
                                            form={form}
                                            tooltip={tooltips['Cliff Period']}
                                            changeParams={field.name}
                                            options={[{ value: true, label: 'Yes' }, { value: false, label: 'No' }]}
                                            classes={{ root: classes.alignWithInputDate, input: classes.alignWithInputDateButton }}
                                            onChange={(v) => this.handleOnChangeIsThereCliff(v, form)}
                                        />
                                    )}
                                </Field></div>
                            <Field name='founder.share[0].vestingTerms.cliffPeriod'>
                                {({ field, form }) =>
                                    _.get(form, 'values.founder.share[0].vestingTerms.isThereCliff') ?
                                        (
                                            <div className={`${classes.ContainerRow}`}>
                                            <TextInput
                                                field={field}
                                                form={form}
                                                disabled={isDisabled}
                                                label={'How long is the cliff period?'}
                                                required={true}
                                                rightLabel={"Months"}
                                            /></div>
                                        )
                                        : null}
                            </Field>
</>)

    }
    handleOnChangeAreTheSharesNumbered(v,form){
        if (!v) {
            FormikHelper.setValueInTheCorrectPosition('founder.share[0].numberedShares', form, null);
            FormikHelper.setValueInTheCorrectPosition('founder.share[0].areTheSharesNumbered', form, v);
        }
    }
    sharesForm(tooltips){
         return (<>
           <Field name={`founder.share[0].issuedDate`}>
     {({ field, form }) => (
         <div className={classes.ContainerRow}>
             <TextInput
                 required={true}
                 type='date'
                 label='Issue date  '
                 field={field}
                 form={form}
                 placeholder={'dd/mm/yyyy'}
                 />
         </div>
     )}
 </Field>
    <Field name='founder.share[0].shareClass.id'>
     {({ field, form }) => (
 <SelectShareClasses
 value={field.value}
 groupCompanyId={this.state.groupCompanySelected.id}
 onChange={(value)=>{
     if(value === 'ADD'){
         form.setFieldValue('founder.share[0].shareClass',{id:value})
          return this.setState({
         showShareClasses:true,
         isAddShareClass:true
     })}
     form.setFieldValue('founder.share[0].shareClass',{...value})
 }}
 errorMessage={_.get(form.errors,'founder.share[0].shareClass.id') || _.get(form.errors,'founder.share[0].shareClass')}
 />
     )}
     </Field>
     { this.state.isAddShareClass &&
             this.renderShareClassFields()
             }
         <div className={classes.ContainerRow}>
    <Field name='founder.share[0].amountOfShares'>
        {({ field, form }) => (
            <TextInput
                required={true}
                type='number'
                label='Number of shares '
                field={field}
                form={form}
                placeholder={'e.g. 100.000'}
            />
        )}
    </Field>
    </div>
    <div className={classes.ContainerRow}>
    <Field name='founder.share[0].pricePerShare'>
        {({ field, form }) => (
            <TextInput
                required={true}
                type='share'
                label='Price paid per share'
                field={field}
                form={form}
                leftLabel={this.state.groupCompanySelected.transactionCurrency || 'USD'}
                placeholder={'e.g. 0.01'}
            />
        )}
    </Field>
    </div>
    <div className={classes.alignWithInputDate}>
                                <Field name={'founder.share[0].areTheSharesNumbered'}>
                                    {({ field, form }) => (
                                        <ClaraRadioWithButton
                                            required={true}
                                            label={'Are the shares numbered?'}
                                            field={field}
                                            form={form}
                                            changeParams={field.name}
                                            options={[{ value: true, label: 'Yes' }, { value: false, label: 'No' }]}
                                            classes={{ root: classes.alignWithInputDate, input: classes.alignWithInputDateButton }}
                                            onChange={(v) => this.handleOnChangeAreTheSharesNumbered(v, form)}
                                        />
                                    )}
                                </Field></div>
                            <Field name='founder.share[0].numberedShares.startingShareNumber'>
                                {({ field, form }) =>
                                    _.get(form, 'values.founder.share[0].areTheSharesNumbered') ?
                                        (
                                            <div className={`${classes.ContainerRow}`}>
                                            <TextInput
                                                field={field}
                                                form={form}
                                                type={'number'}
                                                placeholder={'E.g. 1'}
                                                label={'Starting share number'}
                                                required={true}
                                            /></div>
                                        )
                                        : null}
                            </Field>
                            <Field name='founder.share[0].numberedShares.endingShareNumber'>
                                {({ field, form }) =>
                                    _.get(form, 'values.founder.share[0].areTheSharesNumbered') ?
                                        (
                                            <div className={`${classes.ContainerRow}`}>
                                            <TextInput
                                                field={field}
                                                form={form}
                                                type={'number'}
                                                placeholder={'E.g. 100,000'}
                                                label={'Ending share number'}
                                                required={true}
                                            /></div>
                                        )
                                        : null}
                            </Field>
     <Field name='founder.share[0].vestingTerms.id'>
     {({ field, form }) => (
 <SelectVestingTerms
 value={field.value}
 groupCompanyId={this.state.groupCompanySelected.id}
 onChange={(value)=>{
     if(value === 'ADD'){
         form.setFieldValue('founder.share[0].vestingTerms',{id:value})
          return this.setState({
         showVestingTerms:true,
         isAddVestingTerms:true
     })}
     form.setFieldValue('founder.share[0].vestingTerms',{...value?.standardTerm,isThereCliff:!!value?.standardTerm?.isThereCliff,id:value?.id,vestingStartDate:form?.values?.founder.share[0]?.vestingTerms?.vestingStartDate})
      form.setFieldValue('founder.share[0].isThereVestingTerms', !!value)
     this.setState({
         showVestingTerms:!!value
     })
 }}
 errorMessage={_.get(form.errors,'founder.share[0].vestingTerms.id') || _.get(form.errors,'founder.share[0].vestingTerms')}
 />
     )}
     </Field>
     { this.state.showVestingTerms &&
             this.renderVestingTermsFields(!this.state.isAddVestingTerms)
             }
              <Field name={`founder.share[0].vestingTerms.vestingStartDate`}>
     {({ field, form }) => (
         <div className={classes.ContainerRow}>
             <TextInput
                 required={true}
                 type='date'
                 label='Vesting start date  '
                 field={field}
                 form={form}
                 placeholder={'dd/mm/yyyy'}
                 />
         </div>
     )}
 </Field>
    </>)
    }
    shareholdingSection(tooltips){
        if(this.state.founder.share && this.state.founder.share.length > 0) return this.shareholdingDetails()
        return this.sharesForm(tooltips)
    }

	loadPositions = (props) => {
		this.setState({
			loadingPositions: true
		})
		const queryParams = { startupId: props.startup.id };

		ServerConnect.graphQlQuery(GET_POSITIONS, queryParams).then(result => {
			if (result && result.getPositions) {
				const positions = _.get(result, 'getPositions', []);
				const positionsList = positions.map(pos => ({id: pos.id, label: pos.name}));
				this.setState({
					positions: positions,
					positionsList: positionsList,
					loadingPositions: false
				});
			}
		});
	};

    handleSelectPosition = (id, extraParams, form) => {
        if (id) {
            const position = _.find(this.state.positions, position => position.id === id);
			let founderPosition = {
				...form.values.founder.position,
				..._.omit(position, 'responsibilities')
			}

            // form.setFieldValue('founder.position', {
            //     ...form.values.founder.position,
            //     ..._.omit(position, 'responsibilities'),
            // });

            if (position.isDefault) {
				const otherResponsibility = _.get(form, 'values.founder.position.responsibilities', []).find(e => !e.isDefault);
				founderPosition = {
					...founderPosition,
					otherResponsibility: _.get(otherResponsibility, 'text', null)
				}

				const mappedResponsibilities = _.map(_.get(position, 'responsibilities', []), (responsibility,index) => {
                    return {
                        value: responsibility,
                        label: _.get(responsibility, 'name'),
                        renderElement: () => responsibility.isDefault ? null : (
                            <Field name={`founder.position.otherResponsibility`}>
                                {({field, form}) => (
                                    <div className={classes.InputContainer}>
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={'Describe other responsibilities here...'}
                                        />
                                    </div>
                                )}
                            </Field>
                        )
                    }
                });
                this.setState({
                    responsibilities: mappedResponsibilities
                })
            } else {
				const defaultOtherResponsibilities = _.head(_.get(position, 'responsibilities', []));
				const otherResponsibilities = _.head(_.get(form, 'values.founder.position.responsibilities', []));
				founderPosition = {
					...founderPosition,
					otherResponsibilities: otherResponsibilities
						? _.get(otherResponsibilities, 'text', '')
						: _.get(defaultOtherResponsibilities, 'text', ''),
					otherResponsibilitiesCode: otherResponsibilities
						? _.get(otherResponsibilities, 'code', '')
						: _.get(defaultOtherResponsibilities, 'code', '')
				}
			}

			form.setFieldValue('founder.position', founderPosition);

        } else {
            form.setFieldValue('founder.position', {id: null})
            //form.setFieldValue('founder.position.responsibilities', [])
        }
    };

    selectType = (documentType, extraParams, form) => {
        this.setState({
            typeSelected: documentType,
            dynamicParams: this.getDynamicParamsByType(documentType)
        }, () => {
            FormikHelper.setValueInTheCorrectPosition('terms', form, []);
            FormikHelper.setValueInTheCorrectPosition('documentType', form, documentType);
            this.state.dynamicParams.forEach((dynamicParam, i) => {
                FormikHelper.setValueInTheCorrectPosition(
                    `terms[${i}].key`,
                    form,
                    dynamicParam.key || 'NO_KEY'
                );
            })
        });
    };

    getDynamicParamsByType(documentType) {
        const type = this.props.documentTypes.filter(agrType => agrType.code === documentType)[0] || {};
        return type.fields || [];
    }

    getGroupCompanies = () => {
        let authorizedSignatories = this.props.authorizedSignatories;
        let groupCompanies = this.props.groupCompanies;
        let companies = [];
        if (groupCompanies && authorizedSignatories) {
            companies = _.filter(groupCompanies, (groupCompany) => {
                let fined = _.filter(authorizedSignatories, (authorized) => {
                    if (groupCompany.authorizedSignatories) {
                        return groupCompany.authorizedSignatories.some(au => au.stakeholder.id === authorized.id);
                    }
                    return false;
                });
                return !!fined;
            });
        }
        return companies;
    };

    selectGroupCompany = (gcId, extraParams, form) => {
        const groupCompany = this.findGroupCompany(gcId);

        // this is for a weird behaviour when hit edit button after preview a document
        if (this.state.groupCompanySelected && groupCompany) {
            return null;
        }

        this.setState({
            groupCompanySelected: groupCompany,
            companyOrFounderUpdated:false
        });

        if (groupCompany) {

            if (!groupCompany.registeredAddress) {
                groupCompany.registeredAddress = {}
            }
            let authorizedSignatories = _.get(form, 'values.company.authorizedSignatories', []);
            
            const newFormValues = {
                ...form.values,
                founders:(form.values.founders || []).map(f=>({...f,share:(f.share || []).map(sh=>({...sh,vestingTerms:{...(sh.vestingTerms || {}),id:sh?.vestingTerms?.id || 'ADD'}}))})),
                company: {
                    id: gcId,
                    name: groupCompany.name || '',
                    country:typeof  _.get(groupCompany, 'country', null) ==='object'? _.get(groupCompany, 'country.code', null) : _.get(groupCompany, 'country', null),
                    jurisdiction: _.get(groupCompany, 'jurisdiction'),
                    registeredNumber: groupCompany.registeredNumber || '',
                    type: groupCompany.type || '',
                    registeredAddress: {
                        ...groupCompany.registeredAddress, country:typeof  _.get(groupCompany, 'registeredAddress.country', null) ==='object'? _.get(groupCompany, 'registeredAddress.country.code', null) : _.get(groupCompany, 'registeredAddress.country', null)
                    },
                    authorizedSignatories: authorizedSignatories,
					recipientNotice: groupCompanyRecipientNotice(form.values.company, groupCompany)
                },
                terms: {
                    ...(form.values.terms ? {
                        ...form.values.terms,
                        compensation: {
                            ...form.values.terms.compensation,
                        }
                    }
                        : {
                            compensation: {
                                currency: _.get(groupCompany, 'transactionCurrency')
                            }
                        })
                }
            };

            form.setValues(newFormValues);
            this.setState({
                groupCompanySelected: groupCompany,
                // authorizedSignatoriesList: authorizedSignatories.map(as => ({
                // 	id: as.id,
                // 	label: as.label
                // }))
            })
        } else {
            form.setValues({
                ...form.values,
                company: {}
            });
        }
    };

    findGroupCompany(gcId) {
        const gc = _.find(this.props.groupCompaniesList,{id:gcId});
        return gc?{...gc}:null
    };

    handleAuthorizedSignatoryUpdate = (value) => {

        const { authorizedSignatories, groupCompanySelected } = this.state;
        const groupCompany = this.findGroupCompany(groupCompanySelected.id);
        _.set(groupCompany,'authorizedSignatories',_.map(groupCompany.authorizedSignatories,auth=>({...auth})))
        groupCompany.authorizedSignatories.push({ stakeholder: value });
        authorizedSignatories.push(value);

    };

    selectFounder = (founder, extraParams, form, cb) => {
        const stakeholder = this.findStakeholder(founder);
        this.setState({
            founderSelected: stakeholder,
            companyOrFounderUpdated:false
        });

        if (stakeholder) {
            let authorizedSignatory = {};

            if (!stakeholder.address) {
                _.set(stakeholder,'address', {})
            }

            const _founder = !_.isEmpty(form.values.founder) ? form.values.founder : stakeholder;

            if (stakeholder.isAnEntity) {
				authorizedSignatory = stakeholderAuthorizedSignatory(_founder);
                // authorizedSignatory = {
                //     fullName: typeof _founder.authorizedSignatory === 'object'
                //         ? _.get(_founder, 'authorizedSignatory.fullName', '')
                //         : _.get(_founder, 'authorizedSignatory', ''),
                //     email: _founder.email || ''
                // }
            }
            const { startupPosition } = stakeholder;

            const position = startupPosition && startupPosition.position ? {
                ...startupPosition.position,
                ..._.get(_founder,'position',{}),
                responsibilities: startupPosition.position.isDefault
                    ? _.get(startupPosition,'position.responsibilities', [])
                    : _.get(startupPosition,'position.responsibilities[0]',null),
                otherResponsibilities: startupPosition.position.isDefault
                    ? null
                    : _.get(startupPosition,'position.responsibilities[0].text',''),
                otherResponsibility: startupPosition.position.isDefault
                    ? _.get(_.find(startupPosition.position.responsibilities,responsibility => !responsibility.isDefault), 'text', null)
                    : null
            } : null;
            form.setValues({
                ...form.values,
                founder: {
                    id: founder,
                    fullName: _founder.fullName || '',
                    email: _founder.email || '',
                    passportNumber: _founder.passportNumber || '',
                    registeredNumber: _founder.registeredNumber || '',
                    nationality: _.get(_founder, 'nationality.code', null) || _.get(_founder, 'nationality', null),
                    jurisdiction: _.get(_founder, 'jurisdiction'),
                    otherJurisdiction: _.get(_founder, 'otherJurisdiction', ''),
                    type: _founder.type || '',
                    address: { ..._founder.address, country:typeof  _.get(_founder, 'address.country', null) ==='object'? _.get(_founder, 'address.country.code', null) : _.get(_founder, 'address.country', null) },
                    isAnEntity: _founder.isAnEntity,
                    authorizedSignatory: authorizedSignatory,
                    position: form.values.founder && form.values.founder.position
						? {...form.values.founder.position}
						: position
							? {...position}
							:null,
                    isThereVesting:form.values.founder?.isThereVesting,
                    confirmShares:!!form.values.founder?.confirmShares,
                    share:form.values.founder?.share && form.values.founder?.share.length > 0?form.values.founder.share.map(sh=>{
                        return {
                        ...sh,
                        vestingTerms:{
                            ...sh.vestingTerms,
                            id:this.props.extraValues?.vestingTerm?.id || sh.vestingTerms?.id || 'ADD'
                        },
                        shareClass:{
                            ...sh.shareClass,
                            id:this.props.extraValues?.shareClass?.id || sh.shareClass?.id 
                        }
                    }}):null,
					recipientNotice: stakeholderRecipientNotice(form.values.founder, stakeholder)
                }
            });
        } else {
            form.setValues({
                ...form.values,
                founder: {}
            });
        }
    };

    findStakeholder(roleId) {
        return this.props.stakeholderList.find(stakeholder => stakeholder.id === roleId);
    };

    findStakeholderAuthorized(roleId) {
        return this.props.stakeholderList.filter(stakeholder => {
            return stakeholder.roles.some(role => role.stakeholder.id === roleId);
        })[0] || null;
    };

    filterAuthorizedByGroupCompany(groupCompany) {
        if (groupCompany) {
            let gc = this.findGroupCompany(groupCompany.id);
            return this.props.authorizedSignatories.filter(authorized => {
                return authorized.groupCompanyId === gc.id
            });
        }
        return [];
    }

    selectAuthorized = (value, form, field, replace) => {
        const stakeholder = this.props.authorizedSignatories.find(e => e.id === value);
        const email = stakeholder ? stakeholder.email : '';
        replace(0, { id: value, email })
    };

    renderFounderAuthorizedSignatory(field) {
        const { tooltips = {} } = this.props;

        if (this.state.founderSelected.isAnEntity) {
            return (
                <React.Fragment>
                    <div className={classes.ClaraInputSelect}>
                        <Field name='founder.authorizedSignatory.fullName'>
                            {({ field, form }) => (
                                <TextInput
                                    required={true}
                                    type='text'
                                    label='Corporate signatory '
                                    field={field}
                                    form={form}
                                    placeholder={"Corporate signatory"}
                                    tooltip={tooltips["Authorised Signatory"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name='founder.authorizedSignatory.email'>
                            {({ field, form }) => (
                                <TextInput
                                    required={true}
                                    type='text'
                                    label="Corporate signatory's email address "
                                    field={field}
                                    form={form}
                                    onChange={(e) => this.updateEmailValue(e, form)}
                                    placeholder={"Email address"}
                                    tooltip={tooltips['Authorised Signatory Email']}
                                />
                            )}
                        </Field>
                    </div>
                </React.Fragment>
            )
        }
    };

    updateEmailValue(e, form) {
        if (this.state.founderSelected.isAnEntity) {
            form.setFieldValue('founder.authorizedSignatory.email', e.target.value, false)
        }
    }

    getDateForPickerFromData(date) {
        if (!date) return undefined;
        return moment(date).toDate();
    }

    handleChange(e, field, form) {

        const date = e === null ? null : moment(e).format("YYYY-MM-DD");

        FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

        this.setState({
            [field.name]: e
        })
    }

    getAuthorizedSignatoriesList = (index, form) => {
        if (form.values && form.values.company) {
            return this.filterAuthorizedByGroupCompany(form.values.company).map(as => ({
                id: as.id,
                label: as.label
            }))
        } else {
            return [];
        }
    };

    getLabel = (key) => {
        switch (key) {
            case "fullName":
                if (this.state.founderSelected.isAnEntity) {
                    return "Company name";
                } else {
                    return "Name";
                }
            case "country":
                if (this.state.founderSelected.isAnEntity) {
                    return "Country of incorporation";
                } else {
                    return "Nationality";
                }
            case "address":
                if (this.state.founderSelected.isAnEntity) {
                    return "Registered address";
                } else {
                    return "Residential address";
                }
            case "passportOrRegistered":
                if (this.state.founderSelected.isAnEntity) {
                    return "Company number";
                } else {
                    return "Passport number";
                }
        }
    };

    getField = (key) => {
        switch (key) {
            case "passportOrRegistered":
                if (this.state.founderSelected.isAnEntity) {
                    return "founder.registeredNumber";
                } else {
                    return "founder.passportNumber";
                }
        }
    };

    getTooltip = (key, fieldName) => {
        switch (key) {
            case "fullName":
                if (this.state.founderSelected.isAnEntity) {
                    return "Founder's Company's Name";
                } else {
                    return "Founder's Name";
                }
            case "passportOrRegistered":
                if (this.state.founderSelected.isAnEntity) {
                    return "Company Number";
                } else {
                    return "Passport Number";
                }
            case "country":
                if (this.state.founderSelected.isAnEntity) {
                    return "Country";
                } else {
                    return "Nationality";
                }
            case "addressLine":
                if (this.state.founderSelected.isAnEntity) {
                    return "Registered Address - Address";
                } else {
                    return "Residential Address - Address";
                }
            case "addressCity":
                if (this.state.founderSelected.isAnEntity) {
                    return "Registered Address - City";
                } else {
                    return "Residential Address - City";
                }
            case "addressState":
                if (this.state.founderSelected.isAnEntity) {
                    return "Registered Address - State";
                } else {
                    return "Residential Address - State";
                }
            case "addressCountry":
                if (this.state.founderSelected.isAnEntity) {
                    return "Registered Address - Country";
                } else {
                    return "Residential Address - Country";
                }
            case "addressPostCode":
                if (this.state.founderSelected.isAnEntity) {
                    return "Registered Address - Post Code";
                } else {
                    return "Residential Address - Post Code";
                }
            case "recipientNotice":
                if (fieldName === 'company') {
                    return "Company's";
                } else {
                    return "Founder's";
                }
        }
    };

    getPlaceholder = (key) => {
        switch (key) {
            case "passportOrRegistered":
                if (this.state.founderSelected.isAnEntity) {
                    return "Company number";
                } else {
                    return "Passport number";
                }
        }
    };

    renderRecipientNotice(fieldName, isAnEntity) {
        const { tooltips = {} } = this.props;

        if (isAnEntity) {
            return (
                <React.Fragment>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.fullName`}>
                            {({ field, form }) => (
                                <TextInput
                                    required={true}
                                    type='text'
                                    label='Recipient for notices '
                                    field={field}
                                    form={form}
                                    placeholder={"Recipient's name"}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={`${classes.ContainerRow} ${classes.Select}`}>
                        <Field name={`${fieldName}.recipientNotice.address.country`}>
                            {({ field, form }) => (
                                <ClaraCountrySelector
                                    jurisdictionsEnabled={false}
                                    isRequired={{ country: true }}
                                    countryField={`${fieldName}.recipientNotice.address.country`}
                                    label={"Recipient's address "}
                                    setFieldValue={form.setFieldValue}
                                    initialValues={{ country: _.get(form.values, `${fieldName}.recipientNotice.address.country`, null) }}
                                    returnCountryAsString={_.isEmpty(_.get(form.values, `${fieldName}.recipientNotice.address.country.code`, null))}
                                    additionalStyle={'ClaraCountrySelectorWizard'}
                                    tooltips={{
                                        country: tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Country"]
                                    }}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.address.street`}>
                            {({ field, form }) => (
                                <TextAreaInput
                                    type='text'
                                    label={''}
                                    field={field}
                                    form={form} placeholder={"Address"}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Address"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.address.city`}>
                            {({ field, form }) => (
                                <TextInput
                                    type='text'
                                    field={field}
                                    form={form}
                                    placeholder={"City"}
                                    label={' '}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - City"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.address.state`}>
                            {({ field, form }) => (
                                <TextInput
                                    type='text'
                                    field={field}
                                    form={form}
                                    placeholder={"State / Region"}
                                    label={' '}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - State"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.address.zipCode`}>
                            {({ field, form }) => (
                                <TextInput
                                    type='text'
                                    field={field} form={form}
                                    label={' '}
                                    placeholder={"Post code"}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Notices Address - Post Code"]}
                                />

                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name={`${fieldName}.recipientNotice.email`}>
                            {({ field, form }) => (
                                <TextInput
                                    required={true}
                                    type='text'
                                    label="Recipient's email address "
                                    field={field}
                                    form={form}
                                    placeholder={"Email address"}
                                    tooltip={tooltips[this.getTooltip('recipientNotice', fieldName) + " Recipient Email Address"]}
                                />
                            )}
                        </Field>
                    </div>
                </React.Fragment>
            )
        }
    };

    renderAgreementDurationFields(form) {
        const { tooltips = {} } = this.props;
        if (!_.get(form, 'values.terms.agreementDuration')) {
            return null
        }

        return (
            <React.Fragment>
                {
                    form.values.terms.agreementDuration === 'otherDuration' ?
                        <div className={classes.ContainerRow}>
                            <Field name='terms.otherDuration'>
                                {({ field, form }) => (
                                    <ClaraInputYearsMonths

                                        required={true}
                                        field={field}
                                        form={form}
                                        label={'Duration '}
                                        tooltips={tooltips}

                                    />
                                )}
                            </Field>

                        </div>
                        : null
                }
            </React.Fragment>
        );

    }

    getStakeholderAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add new individual stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
                placeholder: "Stakeholder's name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: false,
                    // roles : [{
                    // 	role:"SHAREHOLDER",
                    // 	groupCompany: this.state.groupCompanySelected.id,
                    // }]
                },
                paramsReturn: 'isAnEntity'
            },
        ]
    };
    getCompanyAdds = () => {
        return [{
            type: "groupCompany",
            label: "Add new company",
            textWarning: "This will create a new group company for your startup. You can add or amend the details of this group company on its profile page.",
            placeholder: "Company Name",
            startupId: this.props.startup.id,
            params: null,
            paramsReturn: `
				name
				authorizedSignatories {
					id
					stakeholder {
						id
						email
						fullName
						roles { 
							id
        					name
        					groupCompany { id name}
        					stakeholder { id }
						}
					}
				}
			`
        }]
    };

    getAuthorizedSignatoryAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add new individual stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add more details later from the stakeholder's profile page.",
                placeholder: "Enter Stakeholder's Name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: false,
                    roles: [{
                        role: "DIRECTOR",
                        groupCompany: this.state.groupCompanySelected.id,
                    }]
                }
            }
        ]
    };

	getPositionsAdds = () => {
		return [
			{
				type: "position",
				label: "Add new position",
				textWarning: "This will create a new Position for your startup. You can add more details later on responsibilities field.",
				placeholder: "Enter Position Name",
				startupId: this.props.startup.id,
				textToSave: "name",
				params: {},
				paramsReturn: `
    				id 
    				code
    				name
    				isDefault
    				responsibilities {
						code
						id
						isDefault
						name
						text
				    }
  				`
			},
		]
	};

    handleStakeholderCreate = (value, name, additionalValue, form) => {

        let { stakeholders, stakeholderList } = this.props;
        let newSh = { id: value.id, fullName: name, ...additionalValue };
        stakeholderList.push(newSh);

        stakeholders.push({ id: value.id, label: name });
        // this.setState({ groupCompaniesList, groupCompanies });

    };

    handleGroupCompanyCreate = (value, name, additionalValue, form) => {

        let { groupCompanies, groupCompaniesList } = this.props;
        let newGc = { id: value.id, name, ...additionalValue };
        groupCompaniesList.push(newGc);

        groupCompanies.push({ id: value.id, label: name });
        // this.setState({ groupCompaniesList, groupCompanies });

    };

    handleAuthorizedSignatoryCreate = (value, name, additionalValue, form) => {

        let { authorizedSignatories } = this.props;
        const { groupCompanySelected } = this.state;

        authorizedSignatories.push({
            id: value.id,
            label: name,
            role: 'DIRECTOR',
            groupCompanyId: groupCompanySelected.id
        });

    };

	handlePositionCreate = (value, name, additionalValue, form) => {
		this.setState(prevState => ({
			positions: [...prevState.positions, { id: value.id, name, ...additionalValue }],
			positionsList: [...prevState.positionsList, { id: value.id, label: name }]
		}))
	};


	fieldHasError = (form, field) => {
        return FormikHelper.returnErrorsForValidation(field.name, form.errors);
    }

    render() {
        const isRequired = message => value => (!!value ? undefined : message);
        const { tooltips = {}, formType } = this.props;

        return (
            <div>
                <div className={classes.ContainerRow}>
                    {formType === 'uploadToEdit' ? (<div>
                        <Field name={`file`}>
                            {({ field, form }) => (
                                <ClaraUpload
                                    required={true}
                                    name={`filepond`}
                                    label='Upload your document '
                                    field={field}
                                    form={form}
                                />
                            )}
                        </Field>
                    </div>) : null}
                    <Field name='agreementName'>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='text'
                                label='Document name '
                                field={field}
                                form={form}
                                placeholder={"Document name"}
                                tooltip={tooltips['Document Name']}
                            />
                        )}
                    </Field>
                </div>

                <hr className={classes.Separator} />
                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                    <Field name='founder.id'>
                        {({ field, form }) => (
                            <ClaraSelect
                                required={true}
                                field={field}
                                form={form}
                                label={"Founder "}
                                lists={this.props.stakeholders}
                                adds={this.getStakeholderAdds()}
                                mode={"classic"}
                                callbacks={{
                                    updateValues: this.selectFounder,
                                    createValues: this.handleStakeholderCreate
                                }}
                                placeholder={"Select from the list"}
                                tooltip={tooltips["Select Founder"]}
                            />

                        )}
                    </Field>
                </div>
                {
                    this.state.founderSelected ?
                        <React.Fragment>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                {
                                    this.state.founderSelected.isAnEntity ?
                                        <>
                                            <h1>Founder Company Details</h1>
                                            <p>Please verify or update the founder's company's information below</p>
                                        </>
                                        :
                                        <>
                                            <h1>Founder's Details</h1>
                                            <p>Please verify or update the founder’s information below</p>
                                        </>
                                }
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='founder.fullName'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label={this.getLabel("fullName") + ' '}
                                            field={field}
                                            form={form}
                                            placeholder={'Name'}
                                            required={true}
                                            tooltip={tooltips[this.getTooltip('fullName')]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={`${classes.ContainerRow} ${classes.Select}`}>
                                <Field name='founder.nationality'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            jurisdictionsEnabled={this.state.founderSelected.isAnEntity}
                                            countryField={'founder.nationality'}
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            label={this.getLabel('country') + ''}
                                            jurisdictionField={'founder.jurisdiction'}
                                            jurisdictionLabel={'Jurisdiction '}
                                            returnCountryAsString={true}
                                            otherJurisdictionField={'founder.otherJurisdiction'}
                                            setFieldValue={form.setFieldValue}
                                            initialValues={{ jurisdiction: _.get(form.values, 'founder.jurisdiction') }}
                                            additionalStyle={'ClaraCountrySelectorWizard'}
                                            tooltips={{
                                                country: tooltips[this.getTooltip('country')],
                                                jurisdiction: tooltips['Jurisdiction'],
                                                other: tooltips['Other Jurisdiction']
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            {
                                this.state.founderSelected.isAnEntity ?
                                    <React.Fragment>
                                        <div className={classes.ContainerRow}>
                                            <Field name='founder.type'>
                                                {({ field, form }) => (
                                                    <TextInput
                                                        required={true}
                                                        type='text'
                                                        label='Company type '
                                                        field={field}
                                                        form={form}
                                                        placeholder={'Company type'}
                                                        tooltip={tooltips['Company Type']}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                    </React.Fragment>
                                    :
                                    null
                            }
                            <div className={classes.ContainerRow}>
                                <Field name={this.getField("passportOrRegistered")}>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label={this.getLabel("passportOrRegistered") + ' '}
                                            field={field}
                                            form={form}
                                            placeholder={this.getPlaceholder("passportOrRegistered")}
                                            tooltip={tooltips[this.getTooltip("passportOrRegistered")]}
                                        />
                                    )}
                                </Field>
                            </div>
                            {
                                !this.state.founderSelected.isAnEntity ?
                                    <React.Fragment>
                                        <div className={classes.ContainerRow}>
                                            <Field name='founder.email'>
                                                {({ field, form }) => (
                                                    <TextInput
                                                        type='text'
                                                        label='Email address '
                                                        field={field}
                                                        form={form}
                                                        placeholder={'Email address'}
                                                        required={true}
                                                        tooltip={tooltips["Founder's Email Address"]}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                    </React.Fragment>
                                    :
                                    null
                            }
                            <div className={`${classes.ContainerRow} ${classes.Select}`}>
                                <Field name='founder.address.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            jurisdictionsEnabled={false}
                                            isRequired={{ country: true }}
                                            returnCountryAsString={true}
                                            countryField={'founder.address.country'}
                                            label={this.getLabel('address') + ''}
                                            setFieldValue={form.setFieldValue}
                                            additionalStyle={'ClaraCountrySelectorWizard'}
                                            tooltips={{
                                                country: tooltips[this.getTooltip('addressCountry')]
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='founder.address.street'>
                                    {({ field, form }) => (
                                        <TextAreaInput
                                            type='text'
                                            label={' '}
                                            field={field}
                                            form={form}
                                            placeholder={'Address'}
                                            tooltip={tooltips[this.getTooltip('addressLine')]}

                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='founder.address.city'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={'City'}
                                            tooltip={tooltips[this.getTooltip('addressCity')]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='founder.address.state'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={'State / Region'}
                                            tooltip={tooltips[this.getTooltip('addressState')]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='founder.address.zipCode'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={"Post code"}
                                            tooltip={tooltips[this.getTooltip('addressPostCode')]}
                                        />
                                    )}
                                </Field>
                            </div>
                            {this.renderRecipientNotice('founder', this.state.founderSelected.isAnEntity)}
                            {this.renderFounderAuthorizedSignatory('founder')}
                        </React.Fragment>
                        :
                        null
                }
                <hr className={classes.Separator} />
                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                    <Field name='company.id'>
                        {({ field, form }) => (
                            <ClaraSelect
                                required={true}
                                field={field}
                                form={form}
								label={"Company "}
                                lists={this.getGroupCompanies()}
                                mode={"classic"}
                                callbacks={{
                                    createValues: this.handleGroupCompanyCreate,
                                    updateValues: this.selectGroupCompany
                                }}
                                placeholder={"Select from the list"}
                                tooltip={tooltips['Select Company']}
                            />
                        )}
                    </Field>
                </div>
                {
                    this.state.groupCompanySelected ?
                        <React.Fragment>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Company Details</h1>
                                <p>Please verify or update the company's information below</p>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.name'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label='Company name '
                                            field={field}
                                            form={form}
                                            placeholder={"Company name"}
                                            tooltip={tooltips["Company's Name"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={`${classes.ContainerRow} ${classes.Select}`}>
                                <Field name='company.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            jurisdictionsEnabled={true}
                                            isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
                                            countryField={'company.country'}
                                            returnCountryAsString={true}
                                            label={'Country of incorporation '}
                                            jurisdictionField={'company.jurisdiction'}
                                            jurisdictionLabel={'Jurisdiction '}
                                            otherJurisdictionField={'company.otherJurisdiction'}
                                            setFieldValue={form.setFieldValue}
                                            initialValues={{ jurisdiction:_.get(form.values, 'company.otherJurisdiction') || _.get(form.values, 'company.jurisdiction') }}

                                            additionalStyle={'ClaraCountrySelectorWizard'}
                                            tooltips={{
                                                country: tooltips["Company's Country"],
                                                jurisdiction: tooltips["Company's Jurisdiction"],
                                                other: tooltips['Other Jurisdiction']
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            {/*
                            <div className={classes.ContainerRow}>
                                <Field name='company.jurisdiction'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label='Jurisdiction: '
                                            field={field}
                                            form={form}
                                            placeholder={"Jurisdiction"}
                                            tooltip={tooltips["Company's Jurisdiction"]}
                                        />
                                    )}
                                </Field>
                            </div>
							*/}
                            <div className={classes.ContainerRow}>
                                <Field name='company.type'>
                                    {({ field, form }) => (
                                        <TextType
                                            required={true}
                                            type='text'
                                            label='Company type '
                                            field={field}
                                            groupCompany={this.state.groupCompanySelected}
                                            form={form}
                                            placeholder={'Company type'}
                                            tooltip={tooltips["Company's Company Type"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.registeredNumber'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label='Company number '
                                            field={field}
                                            form={form}
                                            placeholder={'Company number'}
                                            tooltip={tooltips["Company's Company Number"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={`${classes.ContainerRow} ${classes.Select}`}>
                                <Field name='company.registeredAddress.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true }}
                                            jurisdictionsEnabled={false}
                                            countryField={'company.registeredAddress.country'}
                                            label={'Registered address '}
                                            returnCountryAsString={true}
                                            setFieldValue={form.setFieldValue}
                                            additionalStyle={'ClaraCountrySelectorWizard'}
                                            tooltips={{
                                                country: tooltips["Company's Registered Address - Country"]
                                            }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.registeredAddress.street'>
                                    {({ field, form }) => (
                                        <TextAreaInput
                                            type='text'
                                            label={' '}
                                            field={field}
                                            form={form}
                                            placeholder={"Address"}
                                            tooltip={tooltips["Company's Registered Address - Address"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.registeredAddress.city'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={'City'}
                                            tooltip={tooltips["Company's Registered Address - City"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.registeredAddress.state'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={'State / Region'}
                                            tooltip={tooltips["Company's Registered Address - State"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.registeredAddress.zipCode'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label=' '
                                            field={field}
                                            form={form}
                                            placeholder={"Post code"}
                                            tooltip={tooltips["Company's Registered Address - Post Code"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            {this.renderRecipientNotice('company', true)}
                            <hr className={classes.Separator}></hr>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Corporate Signatory</h1>
                                <p>Please add all the signatories required to sign this document on behalf of the company.</p>
                            </div>
                            <Field name='company.authorizedSignatories_error'>
                                {({ field, form }) => (
                                    <React.Fragment>
                                        <span hasError={_.get(form.errors, "company.authorizedSignatories") ? "true" : "false"}>
                                            <AuthorisedSignatories
                                                list={this.props.stakeholderList}
                                                fieldArrayName={'company.authorizedSignatories'}
                                                idGroupCompany={this.state.groupCompanySelected.id}
                                                handleAddListStakeholder={this.handleAuthorizedSignatoryUpdate}
                                                errorMessage={_.get(form.errors, "company.authorizedSignatories")}
                                            />
                                        </span>
                                    </React.Fragment>
                                )}
                            </Field>
                            <hr className={classes.Separator}></hr>
                        </React.Fragment>
                        :
                        null
                }

                {
                    this.state.founderSelected && this.state.groupCompanySelected ?
                        <React.Fragment>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Services</h1>
                                <p>The services the founder will provide for your startup.</p>
                            </div>
                            <div className={`${classes.labelGroup} ${classes.labelGroupWizard}`}>
                                <label>Commitment{' '}<span style={{ color: 'red' }}>*</span></label>
                            </div>
                            <Field name='terms.services.commitment'>
                                {({ field, form }) => (
                                    <CheckBox
                                    errorMessage={this.fieldHasError(form, field)}
                                    onChange={(value) => { form.setFieldValue(field.name, value); form.validateField(field.name) }}
                                        className={{ root: classes.groupCheckBoxWizard, checkbox: classes.checkBoxWizard }}
                                        values={field.value}
                                        isMultiple={false}
                                        options={[
                                            {
                                                value: 'commitmentFull',
                                                label: 'Devote the whole of their working time, attention and skill',
                                                tooltip: tooltips['Other Amount']
                                            },
                                            {
                                                value: 'otherCommitment',
                                                label: 'Other',
                                                tooltip:tooltips['Other Amount'],
                                                renderElement: () => (
                                                    <Field name='terms.services.otherCommitment'>
                                                        {({ field, form }) => (
                                                            <div style={{'marginLeft':'2rem'}}>
                                                        <CheckBox
                                                                errorMessage={this.fieldHasError(form, field)}
                                                                onChange={(value) => { form.setFieldValue(field.name, value); form.validateField(field.name) }}
                                                                className={{ root: classes.groupCheckBoxWizard, checkbox: classes.checkBoxWizard }}
                                                                values={field.value}
                                                                isMultiple={false}
                                                                options={[
                                                                    {
                                                                        value: '10',
                                                                        label: 'HOURS10',
                                                                    },
                                                                    {
                                                                        value: '20',
                                                                        label: 'HOURS20',
                                                                    },
                                                                    {
                                                                        value: '30',
                                                                        label: 'HOURS30',
                                                                    },
                                                                    {
                                                                        value: '40',
                                                                        label: 'HOURS40',
                                                                    },
                                                                    {
                                                                        value: 'otherHours',
                                                                        label: 'Other',
                                                                        renderElement: () => (
                                                                            <Field name='terms.services.otherHours'>
                                                                                {({ field, form }) => (
                                                                                    <TextInput
                                                                                        type='text'
                                                                                        required={true}
                                                                                        label='Hours per week'
                                                                                        className={{
                                                                                            root: classes.InputContainer,
                                                                                            input: classes.TextInput
                                                                                        }}
                                                                                        field={field}
                                                                                        form={form}
                                                                                        rightLabel={'Hours'}
                                                                                        placeholder={'Number'}
                                                                                    />
                                                                                )}
                                                                            </Field>
                                                                        )
                                                                    }
                                                                ]}

                                                            /></div>
                                                        )}
                                                    </Field>
                                                )
                                            }
                                        ]}

                                    />
                                )}
                            </Field>
                            <hr className={classes.Separator}></hr>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Location</h1>
                                <p>The primary location where the founder will work.</p>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='terms.location.workingAddress.city'>
                                    {({ field, form }) => (
                                        <TextInput
                                            type='text'
                                            label='City'
                                            field={field}
                                            form={form}
                                            placeholder={'City'}
                                            required={true}
                                            tooltip={tooltips["Work Location - City"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={`${classes.ContainerRow} ${classes.Select}`}>
                                <Field name='terms.location.workingAddress.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            jurisdictionsEnabled={false}
                                            isRequired={{ country: true }}
                                            returnCountryAsString={true}
                                            countryField={'terms.location.workingAddress.country'}
                                            label={'Country '}
                                            setFieldValue={form.setFieldValue}
                                            additionalStyle={'ClaraCountrySelectorWizard'}
                                            tooltips={{ country: tooltips["Work Location - Country"] }}
                                        />
                                    )}
                                </Field>
                            </div>
                            <hr className={classes.Separator}></hr>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Compensation</h1>
                                <p>The fees paid to the founder for providing the services.</p>
                            </div>
                            <div className={`${classes.ContainerRow} ${classes.Select}`}>
                                <div className={classes.ContainerRow}>
                                    <Field name={'terms.compensation.currency'}>
                                        {({ field, form }) => (
                                            <ClaraCurrencySelector
                                                required={true}
                                                currencyField={'terms.compensation.currency'}
                                                setFieldValue={field.setFieldValue}
                                                type={'One'}
                                                label={"Currency "}
                                                placeholder={"Select from the list"}
                                                tooltips={tooltips["Currency"]}
                                            />
                                        )}
                                    </Field>
                                </div>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='terms.compensation.salary'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='number'
                                            label='Salary per year '
                                            tooltip={tooltips['Salary']}
                                            field={field}
                                            form={form}
                                            placeholder={'Amount'}
                                        />
                                    )}
                                </Field>
                            </div>
                            <hr className={classes.Separator} />
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Founder Role</h1>
                                <p>The role the founder will perform in your startup.</p>
                            </div>
                            <div className={`${classes.ContainerRow} ${classes.Select}`}>
                                <div className={classes.ContainerRow}>
                                    <Field name={'founder.position.id'}>
                                        {({ field, form }) => (
                                            <ClaraSelect
                                                required={true}
                                                field={field}
                                                form={form}
                                                label={"Role "}
												adds={this.getPositionsAdds()}
												lists={this.state.positionsList}
                                                callbacks={{
													createValues: this.handlePositionCreate,
													updateValues: this.handleSelectPosition
												}}
                                                mode={"classic"}
                                                placeholder={"Select from the list"}
                                                tooltip={tooltips["Select Role"]}
                                            />
                                        )}
                                    </Field>
                                </div>
                            </div>
                            <Field>
                                {({ field, form }) => (
                                    _.get(form, `values.founder.position.id`) && _.get(form, 'values.founder.position.isDefault') === false ?
                                            <div className={classes.ContainerRow}>
                                                <Field name='founder.position.otherResponsibilities'>
                                                    {({ field, form }) => (
                                                        <TextAreaInput
                                                            required={true}
                                                            type='text'
                                                            label='Responsibilities '
                                                            field={field}
                                                            form={form}
                                                            placeholder={'Describe the responsibilities here'}
                                                            tooltip={tooltips['Other Responsibilities']}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                        : null
                                )}
                            </Field>
                            <Field name={'founder.position.responsibilities'}>
                                {({ field, form }) => {
                                    return (
                                       _.get(form.values,'founder.position.id',null) && _.get(form,'values.founder.position.isDefault')?
                                            <div>
                                                <div className={`${classes.labelGroup} ${classes.labelGroupWizard}`}>
                                                    <label>Responsibilities{' '}<span style={{ color: 'red' }}>*</span></label>
                                                </div>
                                                <CheckBox
                                                    errorMessage={this.fieldHasError(form, field)}
                                                    onChange={(value) => { form.setFieldValue(field.name, value); form.validateField(field.name) }}
                                                    options={this.state.responsibilities}
                                                    values={field.value}
                                                    compare={'code'}
                                                    className={{ root: classes.groupCheckBoxWizard, checkbox: classes.checkBoxWizard }}
                                                />
                                            </div> : null
                                    )
                                }}
                            </Field>
                            <div className={`${classes.labelGroup} ${classes.labelGroupWizard}`}>
                                <label></label>
                            </div>
                            <Field name='founder.position.reports'>
                                {({ field, form }) => (
                                    _.get(form, 'values.founder.position.id') && _.get(form, 'values.founder.position.code') !== 'CEO' ?
                                        <div>
                                            <div className={`${classes.labelGroup} ${classes.labelGroupWizard}`}>
                                                <label>Reports to{' '}<span style={{ color: 'red' }}>*</span></label>
                                            </div>
                                            <div className={`${classes.groupCheckbox} ${classes.groupCheckBoxWizard}`}>
                                                <CheckBox
                                                    isMultiple={false}
                                                    className={{ root: classes.groupCheckBoxWizard, checkbox: classes.checkBoxWizard }}
                                                    errorMessage={this.fieldHasError(form, field)}
                                                onChange={(value) => { form.setFieldValue(field.name, value); form.validateField(field.name) }}
                                                    values={field.value}
                                                    options={[
                                                        {
                                                            value: 'CEO',
                                                            label: 'Chief Executive Officer',
                                                            tooltip: tooltips['Reports To']

                                                        },
                                                        {
                                                            value: 'directors',
                                                            label: 'Board of Directors',
                                                            tooltip: tooltips['Reports To']
                                                        }

                                                    ]}
                                                />
                                            </div></div> : null
                                )}
                            </Field>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Vesting</h1>
                                <p>Founder vesting is typically covered in the founders agreement (or shareholders' agreement), but you may wish to add it here in the case of solo founders. Select No if the founder shares are not subject to vesting or if vesting terms are addressed elsewhere.</p>
                            </div>
                            <div className={classes.alignWithInputDate}>
                                <Field name={'founder.isThereVesting'}>
                                    {({ field, form }) => (
                                        <ClaraRadioWithButton
                                            required={true}
                                            label={"Would you like to include vesting terms for this founder's shares in this document?"}
                                            field={field}
                                            form={form}
                                            changeParams={field.name}
                                            classes={{ root: classes.alignWithInputDate, input: classes.alignWithInputDateButton }}
                                            options={[{ value: true, label: 'Yes' }, { value: false, label: 'No' }]}
                                            onChange={(v) => this.onChangeIsThereVesting(v, form)}
                                        />
                                    )}
                                </Field></div>
                                {this.state.isThereVesting && <>
                                <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Shareholding</h1>
                                <p>{this.state.founder?.share && this.state.founder.share?.length >0?
                                "Please review the share position details below (and any applicable vesting terms) before generating the document. If you need to add, edit or delete any share positions listed below, please navigate to “Equity” and then “Shares” and select the relevant share position to do so. ":
                                "The below share position will be created in “Draft” once you generate this document and will be updated to “Published” after all parties have signed the document."}</p>
                            </div>
                                {this.shareholdingSection(tooltips)}
                                </>
                                }
                            
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Place of Arbitration</h1>
                                <p>This contract’s dispute resolution mechanism uses arbitration under expedited ICC rules. Please select the place where you would like the arbitration to take place (please enter the name of the city and country and, if applicable, state or freezone), e.g. "London, United Kingdom" or "New York City, New York, United States of America” or "Dubai International Financial Centre, Dubai, United Arab Emirates”. Please note that the place of arbitration that you select can have a material impact on the proceedings and enforcement of the arbitration and arbitral award. Please seek legal advice.</p>
                                </div>
                                <div className={classes.ContainerRow}>

<Field name={`terms.placeOfArbitration`}>
    {({ field, form }) => (
        <TextInput
            required={true}
            type='text'
            label='Place of arbitration '
            field={field}
            form={form}
            placeholder={"Place of arbitration"}
        />
    )}
</Field>
</div>
                        </React.Fragment>
                        : null
                }

                {formType === 'uploadToEdit' ? <div>
                    <Field name='confirmSameData'>
                        {({ field, form }) => (
                            <Checkbox
                                className={classes.sameDataCheckbox}
                                field={field}
                                validate={isRequired('This field is required')}
                                form={form}
                                required={true}
                                label='All document details are verified and correct.'
                                errorMessage={this.fieldHasError(form, field)}
                                onChange={(value) => { form.validateField(field.name) }}
                            />
                        )}
                    </Field>
                </div> : null}
            </div>

        );
    }

}

export default FounderServicesAgreementDoc;

const TEXTS = {
    TITLE: 'Is there a company Shareholder Register listing this share position?',
    TITLE_COMPANY: 'Upload a company Shareholder Register for these shares',
    TITLE_SHARE: 'Upload a company Shareholder Register for this share position',
    TITLE_CONVERTIBLE: 'Upload an agreement for this convertible',
    TITLE_OPTION: 'Upload a Grant Agreement for this option grant',
    TITLE_OPTION_SIP: 'Upload a Share Incentive Plan (SIP) connected to this option grant',
    TITLE_WARRANT: 'Upload a Warrant Agreement for this warrant',
    SUBTITLE: 'Equity positions - Add share position',
    SUBTITLE_COMPANY: 'Company equity',
    SUBTITLE_SHARE: 'Equity positions - Add share position',
    SUBTITLE_CONVERTIBLE: 'Equity positions - Add convertible',
    SUBTITLE_OPTION: 'Equity positions - Add option grant',
    SUBTITLE_WARRANT: 'Equity positions - Add warrant',
    TOOLTIP: 'A <b>shareholder register</b> (or register of members) is a record of all the active and former owners of a company’s shares. ',
    TOOLTIP2: 'This will be safely stored in Documents, your Clara data room.'
}
export default  TEXTS
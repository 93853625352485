import AddressesPanel from "./panels/AddressPanel";
import GeneralDetailsPanel from "./panels/GeneralDetailPanel";
import PropTypes from "prop-types";
import React from "react";

function DetailsTab({ 
  data, 
  isLock, 
  isManagedByClara, 
  jurisdictions 
}) {
  return (
    <>
      <GeneralDetailsPanel data={data} isLock={isLock} isManagedByClara={isManagedByClara} jurisdictions={jurisdictions}/>
      <AddressesPanel data={data} isLock={isLock} isManagedByClara={isManagedByClara} jurisdictions={jurisdictions}/>
    </>
  );
}

DetailsTab.propTypes = {
  data: PropTypes.any,
  isLock: PropTypes.bool,
  isManagedByClara: PropTypes.bool,
  jurisdictions: PropTypes.array
}

export default DetailsTab;
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useCustomMachine from '../../../../../generic/hooks/useCustomMachine';
import Button from '../../../../../generic/components/Buttons/Button';
import { StepProps } from "../../../../../generic/components/Wizard/Step";
import TemplateWizard from '../../../../../generic/templates/Modal/TemplateWizard';
import useTranslate from '../../../../../generic/hooks/useTranslate';
import { addAssistantText, clearAssistatText } from "../../../../../generic/store/action";
import FormConfirmShareholdersDirectors from "../../../Forms/FormConfirmShareholdersDirectors";

const Step1: FC<StepProps> = () => {
  const { translate } = useTranslate();
  const { next, send, stepData } = useCustomMachine();
  const dispatch = useDispatch()


  useEffect(() => {
    dispatch(clearAssistatText())
    dispatch(addAssistantText(translate('MODULES.GROUP_COMPANY.SHARE_CAPITAL_DELAWARE.FIRST_STEP.TOOLTIP')))
  }, [])

  const handleCompleteSubmit = async (values) => {
    next(values);
  }

  const handleCancel = () => {
    send("CANCEL");
  }

  return (
    <>
      <FormConfirmShareholdersDirectors
        initialValues={stepData}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: translate('MODULES.GROUP_COMPANY.SHARE_CAPITAL_DELAWARE.FIRST_STEP.TITLE'),
          subTitle: translate('MODULES.GROUP_COMPANY.SHARE_CAPITAL_DELAWARE.FIRST_STEP.SUBTITLE')
        }}
        buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }}
        addNoText={true}
      />
    </>
  )
}

export default Step1;

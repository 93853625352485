import _ from "lodash";

// map task for GC
const mapGroupCompanyTaskWithParam = (task, idGroupCompany,additonalInfomation, paramsDecorator) =>{
    switch (task.code){
        case "TASK_ADD_SHARE_AGREEMENT" : {
            const{amount, issuedDate, ...info} = additonalInfomation;
            return {
                ...task,
                params:{
                    ...info,
                    issuedDate: issuedDate?paramsDecorator.dateFormat(issuedDate):null,
                    amount: amount?paramsDecorator.moneyFormat(amount):null,               
                    owner: {type: "groupCompany", id: idGroupCompany},
                    equityId:_.get(additonalInfomation,'id'),
                    groupCompanyId:_.get(additonalInfomation,'groupCompany.id')
                }
            }
        }
        default: return {...task, params:{}}
    }
}

export {mapGroupCompanyTaskWithParam}
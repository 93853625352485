import React, { Component } from "react";
import classes from "./OptionsButtonInput.module.scss";
import FactoryChat from "../../../components/Chat/FactoryChat"
import { ChatForm, Text } from "../../../components"
import event from '../../../utils/event';
import _ from "lodash";
import ErrorInput from "../../../../modules/generic/components/Inputs/ErrorInput";
import FormikHelper from "../../../utils/formikHelper";
class OptionsButtonInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            childrenComponent: null,
            option: null,
            formKey: null
        };
    }
    componentWillUpdate = (nextProps, nextState, snapshot) => {
        const { field, form } = this.props;
        if (nextProps.field.value !== field.value) {
            
            const { field, form } = nextProps;

            if (_.has(form.values, field.name) || _.get(form, `values${field.name}`) === false) {

                this.setState({
                    option: { id: field.value },
                    childrenComponent: null
                })

            }
            
        }

    }
    componentWillMount = () => {
        const { field, form } = this.props;

        if (_.has(form.values, field.name) || _.get(form, `values${field.name}`) === false) {

            this.setState({
                option: { id: this.props.field.value },
                childrenComponent: null
            })

        }
    }


    handleClick = (field, form, option) => {
        switch (_.get(option, "bot_message.type")) {
            case "CHAT_FORM":
                this.setState({
                    formKey: _.get(option, "bot_message.inputConfig.formKey")
                }, () => {
                    event.emmit("CHAT_FORM_" + field.name, {
                        option: option
                    })
                })

                break;
            case "STOP_FLOW_WITH_OPTIONS":
                this.props.submitLastStep();
                break;
            default:
                if (!option.bot_message) {
                    this.props.onClick(field, form, option.id)
                    this.setState({
                        option,
                        childrenComponent: null
                    });
                    return
                } else {
                    this.setState({
                        childrenComponent: option.bot_message,
                        option: option
                    })
                }
        }
    }
    renderOptions() {
        const { options, form, field, widthAuto } = this.props;

        const { option } = this.state;
        const classWith = widthAuto === true && (options && options.length > 1) ? classes.withAuto : classes.with160;
        return options.map((optionMap, i) => {

            const classButton = optionMap.id === _.get(option, "id") ? classes.selected : null

            return (
                <button key={i} hasError={this.getError() ? "true" : "false"} className={`${classes.Option} ${classes.OptionButton} ${classWith} ${classButton}`} onClick={() => { this.handleClick(field, form, optionMap) }}>
                    {optionMap.text}
                </button>
            )
        });
    }
    handleSubmitQuestion = (values, form) => {
        let newValue = {}
        const { option, childrenComponent } = this.state;
        const { field } = this.props;
        const key = childrenComponent.responseKeyName;
        const valueOption = values[key];
        newValue[field.name] = {
            key: option.id,
            value: { key, value: valueOption }
        }
        this.props.functions.submitQuestion(newValue, form)
    }
    handleNextStepForm = (values, event) => {
        const { field, form } = this.props;
        let newValue = {}
        newValue[field.name] = values;
        this.props.functions.submitQuestion(newValue, form, event)
    }
     getError = () => {
        const { form, field } = this.props;
        return FormikHelper.returnErrorsForValidation(field.name, form.errors);
    }
    render() {
        const { childrenComponent, option, formKey } = this.state;
        const { field, disclaimer, chatform = true,classNameError } = this.props;
        const functionsChildren = { ...this.props.functions, submitQuestion: this.handleSubmitQuestion }
        const classesRoot = this.props.className ? _.get(this.props.className, "root", "") : "";
        return (
            <div className={classes.OptionsButtonInput}>

                <div className={`${classes.OptionsBotonera} ${classesRoot}`}>
                    {this.props.label && <label>{this.props.label}</label>}
                    {this.renderOptions()}
                    {_.get(childrenComponent, "question") ? <p className={classes.question}><Text uuid={childrenComponent.question} /></p> : null}
                    {childrenComponent ? <FactoryChat bot_message={childrenComponent} option={option} params={this.props.params} functions={functionsChildren} /> : null}
                    {disclaimer ? <div className={classes.Disclaimer}><Text uuid={disclaimer} /></div> : null}
                    {chatform && <ChatForm
                        formKey={formKey} groupKey={field.name} nextStep={this.handleNextStepForm} previewInfo={this.props.previewInfo}
                        initialValueStart={this.props.initialValueStart}
                        onCancelForm={() => { }}
                        initialKey={this.props.initialKey}
                    />}
                    
                    {this.getError() && <ErrorInput message={this.getError()} classNameError={`${classes.classNameError} ${classNameError}`} />}
                </div>
            </div>
        );
    }
}

export default OptionsButtonInput;

import React, { useState, useMemo } from 'react'
import _ from 'lodash'
import classes from './PartiesGenerated.module.scss'
import Avatar from '../../../../../../modules/generic/components/Avatars/AvatarLabel'
import { Constants } from '../../../../../../v1/utils'
import moment from 'moment'
import { useDispatch } from 'react-redux'

const Parties = ({ data, ...props }) => {

    const mapRecipientStatus = (values) => {
        const recipient = {};
        _.forEach(values, rc => {
			const isSent = rc.sent && moment(rc.sent).isValid();
			const isDelivered = rc.delivered && moment(rc.delivered).isValid();
			const isSigned = rc.signed && moment(rc.signed).isValid();

			recipient[rc.email] = {
                userName: rc.userName,
                email: rc.email,
                status: rc.status,
                sent: isSent ? moment.utc(rc.sent).format('DD MMM YYYY').toUpperCase() : '-',
                delivered: isDelivered ? moment.utc(rc.delivered).format('DD MMM YYYY').toUpperCase() : '-',
                signed: isSigned ? moment.utc(rc.signed).format('DD MMM YYYY').toUpperCase() : '-',
                sentStatus: isSent ? 'Completed' : 'Pending',
                deliveredStatus: isDelivered ? 'Completed' : 'Pending',
                signedStatus: isSigned ? 'Completed' : 'Pending'
            };
        })

        return recipient;
    }

    const parties = useMemo(() => {
        const parties = [];

        _.forEach(_.get(data, 'parties', []), (party) => {
            const { avatar, email, name, fullName, recipientStatus = [], authorizedSignatory = [], isAnEntity, id } = party;

            parties.push({
                id,
                avatar,
                email,
                name: name || fullName,
                authorizedSignatory: _.map(authorizedSignatory, as => ({ userName: as?.fullName, email: as?.email })),
                isAnEntity,
                recipientStatus: mapRecipientStatus(recipientStatus)
            })
        })
        return parties;
    }, [JSON.stringify(data)])


    const authorizedSignatory = useMemo(() => {
        return _.map(_.get(data, 'parties', []), (party) => {

        });
    }, [JSON.stringify(data)])




    const dispatch = useDispatch();

    const handleViewParty = (party) => {
        if (_.some(data.stakeholders, st => st.id === party.id)) {

            const page = party.isAnEntity ? Constants.PAGES.viewStakeholderCompany : Constants.PAGES.viewStakeholderIndividual;

            dispatch({ type: "GO_TO", page, params: { id: party.id, tab: "view" } })

        } else {
            dispatch({ type: "GO_TO", page: Constants.PAGES.viewGroupCompany, params: { id: party.id } })
        }
    }

    return (
        <div className={classes.containerTabPartiesUploaded}>
                <div className={classes.initialIcon}>
                    <li  >
                        {_.get(data, 'file.name', '-')}
                    </li></div>
            {parties.map((party, index) => {
                return (
                    <div className={`${classes.containerAvatar} ${(parties.length === index + 1) && classes.lastParty} `}>
                        <i />
                        <Avatar name={party.name} avatar={party.avatar} showLabel={false} />
                        <div className={classes.containerText} onClick={() => handleViewParty(party)}  >
                            <label>{party.name}</label>
                        </div>

                        <div className={classes.containerAuthorized}>
                            {!_.isEmpty(party.recipientStatus) && !party.isAnEntity && <>
                                <div className={`${classes.AuthorizedSignatoriesContainer}`}>
                                    <div className={`${classes.AuthorizedSignatoriesItem}`}>
                                        <span>
                                            <i
                                                className={`${classes[party.recipientStatus[party.email].sentStatus]} 
                                                        ${classes.circle} ${classes.letter}`}
                                            />
                                        </span>
                                        <label className={`${classes.StatusTitle}`}>Sent:</label>
                                        <label className={`${classes.StatusValue}`}>{party.recipientStatus[party.email].sent}</label>
                                    </div>
                                    <div className={`${classes.AuthorizedSignatoriesItem}`}>
                                        <span>
                                            <i
                                                className={`${classes[party.recipientStatus[party.email].deliveredStatus]} ${classes.circle} ${
                                                    classes.letter}`}
                                            />
                                        </span>
                                        <label className={`${classes.StatusTitle}`}>Delivered:</label>
                                        <label className={`${classes.StatusValue}`}>{party.recipientStatus[party.email].delivered}</label>

                                    </div>

                                    <div className={`${classes.AuthorizedSignatoriesItem}`}>
                                        {party.recipientStatus[party.email].status === 'Sent' && <span className={classes.docuSign} >DocuSign</span>}
                                        <span>
                                            <i
                                                className={`${classes[party.recipientStatus[party.email].signedStatus]} ${classes.circle} ${
                                                    classes.letter}`}
                                            />
                                        </span>
                                        <label className={`${classes.StatusTitle}`}>Signed:</label>
                                        <label className={`${classes.StatusValue}`}>{party.recipientStatus[party.email].signed}</label>
                                    </div>
                                </div>
                            </>}


                            {party.authorizedSignatory.map(as => {
                                const recipientStatus = _.get(party.recipientStatus, `${as.email}`, {});
                                return (
                                    <React.Fragment key={JSON.stringify(as)}>
                                        <p  className={classes.userNameRecipientNotice}>{as.userName}</p>
                                        {!_.isEmpty(recipientStatus) && <>
                                            <div className={`${classes.AuthorizedSignatoriesContainer}`}>
                                                <div className={`${classes.AuthorizedSignatoriesItem}`}>
                                                    <span>
                                                        <i
                                                            className={`${classes[recipientStatus.sentStatus]} 
                                                        ${classes.circle} ${classes.letter}`}
                                                        />
                                                    </span>
                                                    <label className={`${classes.StatusTitle}`}>Sent:</label>
                                                    <label className={`${classes.StatusValue}`}>{recipientStatus.sent}</label>

                                                </div>
                                                <div className={`${classes.AuthorizedSignatoriesItem}`}>
                                                    <span>
                                                        <i
                                                            className={`${classes[recipientStatus.deliveredStatus]} ${classes.circle} ${
                                                                classes.letter}`}
                                                        />
                                                    </span>
                                                    <label className={`${classes.StatusTitle}`}>Delivered:</label>
                                                    <label className={`${classes.StatusValue}`}>{recipientStatus.delivered}</label>

                                                </div>

                                                <div className={`${classes.AuthorizedSignatoriesItem}`}>
                                                    {recipientStatus.status === 'Sent' && <span className={classes.docuSign} >DocuSign</span>}
                                                    <span>
                                                        <i
                                                            className={`${classes[recipientStatus.signedStatus]} ${classes.circle} ${
                                                                classes.letter}`}
                                                        />
                                                    </span>
                                                    <label className={`${classes.StatusTitle}   `}>Signed:</label>
                                                    <label className={`${classes.StatusValue}`}>{recipientStatus.signed}</label>
                                                </div>
                                            </div>
                                        </>}
                                    </React.Fragment>
                                )

                            })}

                        </div>
                    </div>

                )
            })}
        </div>
    )
}

export default Parties;

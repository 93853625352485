export default {
    EMAIL: /\b[\w\.-]+@[\w\.-]+\.\w{2,}\b/, 
    PASSWORD: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
    DATE: /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/,
    URL: /((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    URL_PROTOCOL: /^((https|http):\/\/)(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    URL_LINKEDIN: /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile|company)\/[\w-]+\/?/gm,
    POSITIVE: /^[0-9]*$/,
    DOMAIN:/^((https?):\/\/)?(www.)?([^-][A-Z-a-z-0-9]*)[.]([A-Z-a-z-0-9]{0,6})([.]([A-Z-a-z-0-9]{0,6}))*[^-]$/,
    PHONE:/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
}
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import _ from "lodash";
import { Constants } from 'src/v1/utils/constants';
import useBusinessRules from "src/modules/clara/hooks/useBusinessRules";
import { goToPage } from 'src/store/actions/page';
import Text from "src/components/text/Text/Text";
import Avatar from "src/modules/generic/components/Avatars/AvatarLabel";
import useSession from "src/modules/session/hooks/useSession";
import Button from 'src/modules/generic/components/Buttons/Button';
import ModalShareProfile from "src/components/modules/clara/startups/shareProfile/modalShareProfile/ModalShareProfile";
import useModal from "src/modules/generic/hooks/useModal";
import DropDownMenuUser from "./components/DropDownMenuUser/DropDownMenuUser";
import LinkMenu from "./components/LinkMenu/LinkMenu";
import classesModule from "./Header.module.scss";
import FirstMenu from "src/layouts/Clara/components/Header/components/FirstMenu/FirstMenu"

const Header = React.memo(({
  fullMenu = false,
  upgradeButton = false,
  startupMenu = false,
  userMenu = true,
  firstMenu = false,
  ...props
}) => {
  
  const { startup } = useSession();
  const dispatch = useDispatch();
  const { openModal, closeModal } = useModal();
  const productCode = useSelector(state => _.get(state, "checkout.product", Constants.MODULES.CHECKOUT.PRODUCTS.SCALE_ANNUAL_SUBSCRIPTION));
  const { canShareStartup, canUpgradeStartup,  isNotFormation, hasStartups,hasSelectedStartup } = useBusinessRules();
  const [value, setValue] = useState(null);

  const goTo = (page,params) => {
    goToPage(dispatch, page,params);
  };

  const rolesOptions = [
    {
      id: Constants.USER_ROLES.USER_OWNER,
      label: Constants.TRANSLATES.ROL_USER_USER_OWNER,
      info: Constants.TRANSLATES.OWNER_ROL_EXAMPLE,
    },
    {
      id: Constants.USER_ROLES.USER_MANAGER,
      label: Constants.TRANSLATES.ROL_USER_USER_MANAGER,
      info: Constants.TRANSLATES.MANAGER_ROL_EXAMPLE,
    },
    {
      id: Constants.USER_ROLES.USER_EDITOR,
      label: Constants.TRANSLATES.ROL_USER_USER_EDITOR,
      info: Constants.TRANSLATES.EDITOR_ROL_EXAMPLE,
    },
    {
      id: Constants.USER_ROLES.USER_VIEWER,
      label: Constants.TRANSLATES.ROL_USER_USER_VIEWER,
      info: Constants.TRANSLATES.VIEWER_ROL_EXAMPLE,
    },
  ];

  return (
    <div className={classesModule.Header}>
      <>
        {(isNotFormation() || hasStartups()) &&
          <>
            {startupMenu && (
              <div className={classesModule.startup}>
                <div
                  className={classnames(`${classesModule.avatarSpace}`)}
                  
                >
                  <Avatar
                    avatar={
                      _.get(startup, "avatar") && _.get(startup, "avatar") !== ""
                        ? _.get(startup, "avatar")
                        : null
                    }
                    name={_.get(startup, "name")}
                    size="32px"
                  />
                </div>
              </div>
            )}
            {fullMenu && (
              <nav className={classesModule.menu}>
                <LinkMenu value={value} setValue={setValue} goTo={goTo} {...props} />
                <div className={classesModule.separator} />
                <div className={classnames('', {
                  [classesModule.update]: canUpgradeStartup(),
                  [classesModule.updateScale]: !canUpgradeStartup()
                })}>
                  {canUpgradeStartup() &&
                    <>
                      {upgradeButton ? (
                        <Button
                          variant='upgradeOutlined'
                          onClick={() => {
                            dispatch({
                              type: 'GO_TO',
                              page: Constants.PAGES.MODULES.CHECKOUT.payment,
                              params: { productCode: productCode ? productCode : Constants.MODULES.CHECKOUT.PRODUCTS.SCALE_ANNUAL_SUBSCRIPTION }
                            })
                          }}
                          className={classesModule.buttonSize}
                        >
                          <Text uuid={"BUTTON_UPGRADE"} />
                        </Button>
                      ) : (
                        <Button
                          variant='upgradeOutlined'
                          onClick={() => {
                            dispatch({
                              type: 'GO_TO',
                              page: Constants.PAGES.upgrade
                            })
                          }}
                          className={classesModule.buttonSize}
                        >
                          <Text uuid={"BUTTON_UPGRADE"} />
                        </Button>
                      )}
                    </>
                  }
                  <Button
                    variant='secondary'
                    onClick={() => {
                      openModal(ModalShareProfile, {
                        handleCloseModal: closeModal,
                        loadingModal: false,
                        useStartupRole: "asd",
                        fromAssistance: true,
                        rolesOptions: rolesOptions,
                        list: [],
                        startupId: startup.id,
                        fixedList: [],
                      });
                    }}
                    disabled={!canShareStartup()}
                    className={classesModule.buttonSize}
                  >
                    <Text uuid={"SHARE"} />
                  </Button>
                </div>
              </nav>
            )}
            {firstMenu && (
              <nav className={classesModule.menu}>
                <FirstMenu value={value} setValue={setValue} goTo={goTo} {...props} />
              </nav>
            )}
          </>
        }
      </>
      <DropDownMenuUser firstMenu={firstMenu} fullMenu={fullMenu}/>
    </div>
  );
})

export default Header;

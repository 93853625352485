import React, {FC, useMemo} from 'react'
import {StepProps} from "../../../../../../../../generic/components/Wizard/Step";
import SelectCompaniesStep from "../../../../../../wizard/steps/SelectCompaniesStepForm";
const SelectCompaniesShareStep:FC<StepProps>=({})=>{
    return(<>
        <SelectCompaniesStep
            query={'SHARE'}
            type={'SHARE'}/>
    </>)
}
export default SelectCompaniesShareStep
import React from "react";
import { useParams } from "react-router-dom";
import ModalAssistant from "src/modules/generic/components/Modal/ModalAssistant";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import { LoadingProvider } from "src/modules/generic/hooks/useLoading";
import useSession from "src/modules/session/hooks/useSession";
import SkeletonInput from "src/modules/generic/components/Skeletons/Overlays/SkeletonInput";
import SkeletonArea from "src/modules/generic/components/Skeletons/Overlays/SkeletonArea";
import Line from "src/components/generic/Line/Line";
import AddressesForm from "src/components/forms/modules/startups/groupCompany/AddressesForm";
import {useUpsertCompany} from "src/modules/startup/graphql";

const EditCompanyAddresses = ({
  showModal,
  open,
  refresh,
  handleCloseModal,
  data
}) => {
  const { startupId } = useSession();
  const params = useParams();

  const handleCloseForm = () => {
    refresh && refresh();
    handleCloseModal();
  };

  const { mutation: upsertCompany } = useUpsertCompany({
    isEdit: true,
    onCompleted: handleCloseForm,
    successMessage: "EDIT_COMPANY_NOTIFICATION_SUCCESS",
    showSuccessNotification: true
  });

  const handleSubmit = async (values) => {
    const { registeredAddress } = values;
    let groupCompany = {
      registeredAddress: {
        ...registeredAddress
      }
    };
    await upsertCompany({ variables: { startupId, groupCompanyId: params.id, groupCompany } });
  };

  return (
    <>
      <ModalAssistant open={showModal || open}>
        <LoadingProvider>
          <AddressesForm
            initialValues={data}
            handleCloseModal={handleCloseModal}
            handleSubmit={handleSubmit}
            Template={TemplateWizard}
            isRenewal={false}
            propsTemplate={{
              title: "Edit address",
              skeleton: (
                <>
                  <SkeletonInput count={3} />
                  <Line />
                  <SkeletonInput />
                  <SkeletonArea noLabel={true} />
                </>
              ),
            }}
          />
        </LoadingProvider>
      </ModalAssistant>
    </>
  );
};

export default EditCompanyAddresses;
import _ from "lodash";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import useCleanFormDraft from "../../../hooks/services/generic/useCleanFormDraft";
import useGetFormDraft from "../../../hooks/services/generic/useGetFormDraft";
import useSetFormDraft from "../../../hooks/services/generic/useSetFormDraft";

window.AutoSaveComponentValue = {}
window.AutoSaveComponentEnabled= false;
const AutoSaveComponent = forwardRef(({ step = "default", keys, onLoading, autoSaveTime = 5, values = {}, updateValues, children, onSubmit, ...props }, ref) => {
    const { data: autoSaveData, loading: loadingAutoSave, error } = useGetFormDraft({ keys });
    const [setFormDraft, loadingSetFormData] = useSetFormDraft({ showSuccessNotification: false });
    const [cleanFormDraft, loadingCleanFormDraft] = useCleanFormDraft({ showSuccessNotification: false });
    
    const [defaultValues, setDefaultValues] = useState(undefined);
    const [autsaveRecord, setAutosaveRecord] = useState({});
    const [time, setTime] = useState();
    useImperativeHandle(ref, () => ({

        clear(value) {

            window.AutoSaveComponentEnabled = false
            cleanFormDraft({ keys })
            window.AutoSaveComponentValue = {};
        }

    }));
    useEffect(() => {
        window.AutoSaveComponentValue = {}
        window.AutoSaveComponentEnabled = true;
        return () => {
            if (time) {
                clearTimeout(time);
                setTime(undefined)
            }
            if (window.AutoSaveComponentEnabled) {
                setFormDraft({ formDraft: { values: window.AutoSaveComponentValue, keys, step } });
            }

        }
    }, [])
    useEffect(() => {
        if (onLoading) {
            onLoading(loadingAutoSave)
        }
    }, [loadingAutoSave])
    useEffect(() => {

        if (autoSaveData) {
            let newAutoDataSave = _.get(autoSaveData, "getFormDraft.values");
            if (!newAutoDataSave) {
                newAutoDataSave = values;
            }
            updateValues(newAutoDataSave);
        }


    }, [JSON.stringify(autoSaveData)])
    useEffect(() => {
        return () => {
            if (time) {
                clearTimeout(time);
                setTime(undefined)
            }

        }
    }, [time])
    useEffect(() => {

        handleAutoSave(values)
    }, [JSON.stringify(values)])

    const handleAutoSave = (values2) => {
        setAutosaveRecord(values2);
        window.AutoSaveComponentValue = values2
        if (!time) {
            const newTime = setTimeout(() => {
                if (window.AutoSaveComponentEnabled) {
                    setFormDraft({ formDraft: { values: window.AutoSaveComponentValue, keys, step } });
                }
                clearTimeout(time);
                setTime(undefined)
            }, autoSaveTime * 1000)

            setTime(newTime)
        }

    }




    return (
        <React.Fragment>

        </React.Fragment>

    )
})

export default AutoSaveComponent;

import useLazyQuery from "src/hooks/custom/useLazyQuery";
import useSession from "src/modules/session/hooks/useSession";
const GET_STAKEHOLDER = `
query getStakeholderCorpADGMDetails($startupId: ID!, $stakeholderId: ID!) {
    getStakeholder(startupId: $startupId, stakeholderId: $stakeholderId) {
        id
        fullName
        arabicName
        title
        nationality{
            code
        }
        jurisdiction
        registeredNumber
        previousName
        email
        directors{
            id
            fullName
            avatar
        }
        recipientNotice{
            id
			fullName
			email
			phoneNumber
			address{
                street
                city
                state
                country{
                    code
                }
                zipCode
            }
			useRegisteredAddress
        }
        managedByClaraConnections{
            id
            jurisdictionType
            statusOnMatters{
                isRenewal
            }
        }
        sourcesOfFunds{
            company
			jurisdictionType
			sourceOfFunds
			sourceOfWealth
        }
        pep {
            isPEP
            descriptionOfPEP
            isFamilyMemberOfAPEP
            familyMemberOfAPEPDescription
            isCloseAssociateOfAPEP
            closeAssociateOfAPEP
        }
        address{
            street
			city
			state
			country{
                code
            }
			zipCode
        }
        directors{
            id
            fullName
        }
        authorizedSignatory{
            id
			fullName
			email
			position
			phoneNumber
			signingAuthority
			stakeholder
        }
        roles{
            id
            name
            entity{
                ...on StakeHolder{
                    id
                }
                ... on GroupCompany{
                id
                }
            }
        }
    }}
`;
const useGetStakeholderDetails = (v) =>{
const { manualQuery, loading, error, data, refetch } = useLazyQuery(GET_STAKEHOLDER, v);
    const { startupId } = useSession()
    const query = async (stakeholderId) => {
        const { data } = await manualQuery({variables:{startupId,stakeholderId}})
        return {
                stakeholder: data['getStakeholder'],
        }
    }

    return {
        query,
        loading,
        error,
        data,
        refetch
    }
}
export default useGetStakeholderDetails

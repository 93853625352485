import React, {Component} from 'react';
import {Constants} from '../../../utils/constants';
import {helper} from "../../../utils";
import NumberFormat from 'react-number-format';

/*
    Properties:
        value: key to get config 
        lang: lang to use (not enabled now)
    
    reference: https://www.npmjs.com/package/react-number-format
*/
class Money extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
            title: undefined
        }
    }
    handleClose=()=>{
        if (this.props.handleClose){
            this.props.handleClose();
        }
    }
    componentWillMount(){
        let {title} = this.props;

        this.setState({
            title: title
        })
    }
    componentWillUpdate(nextProps, nextState){
        
    }
    render() {
        const {value,params,classes} = this.props;
        let format = helper.getFormatByKey(Constants.TYPE_FORMATS.NUMBER,params) || {};

        return (
            <React.Fragment>
                {/* <span className={`${classesModule.spanClass} ${_.get(classes,"root",null)}`}>{Parser(text)}</span> */}
                <NumberFormat value={value} displayType={'text'} {...format} />
            </React.Fragment>
        );
    }
}

export default Money;

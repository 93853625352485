import useBusinessRules from 'src/modules/clara/hooks/useBusinessRules';
import { useMemo } from 'react';
import useSession from 'src/modules/session/hooks/useSession';

const useGuards = () => {
  const { isOwner } = useBusinessRules();
  const { session } = useSession();

  return useMemo(() => ({
    addresses: (context) => {
      return false;
      // return true;
    },

  }), [isOwner, session]);
}

export default useGuards;

import React from "react";
import * as Yup from "yup";
import Form from "src/components/forms/Form/Form";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import FormProps from "src/modules/generic/forms/interfaces/FormProps";
import TemplateFormDefault from "src/modules/generic/templates/Modal";
import CalendarInputDate from "src/components/inputs/Calendars/CalendarInputDate";
import TextInput from "src/components/inputs/Text/TextInput";
import CountrySelector from "src/components/inputs/Select/CountrySelector/CountrySelector";
import { Constants } from "src/v1/utils";
import useTranslate from "src/modules/generic/hooks/useTranslate";

interface FormPropsCustom extends FormProps {
  text?: string;
  image?: any;
  isOldPassport?: boolean;
  documentType: string;
}

const FormExpiredDocument: React.FC<FormPropsCustom> = ({
  initialValues = {},
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  documentType = "",
  propsTemplate,
}) => {
  const { translate } = useTranslate();

  const handleSubmit = async (values) => {
    onCompleteSubmit && onCompleteSubmit(values);
  };

  const nationalityCondition =
    documentType === "PASSPORT_DOCUMENT" ||
    documentType === "UAE_RESIDENCE_VISA_DOCUMENT" ||
    documentType === "NATIONAL_ID_DOCUMENT";

  const passportNumberCondition = documentType === "PASSPORT_DOCUMENT";

  const filesSchema = {
    image_pdf: Yup.object().shape({
      files: Yup.array()
        .of(
          Yup.object().shape({
            name: Yup.string()
              .matches(/(.jpg)|(.png)|(.jpeg)$/, "Invalid file type")
              .transform((current, original) => {
                return original.replace(/(\.[^.]+)$/, (match) =>
                  match.toLowerCase()
                );
              }),
          })
        )
        .min(1),
    }),
    all: Yup.object().shape({
      files: Yup.array()
        .of(
          Yup.object().shape({
            name: Yup.string(),
          })
        )
        .min(1),
    }),
  };

  const schema = Yup.object().shape({
    passportNumber: Yup.lazy(() => {
      if (passportNumberCondition) {
        return Yup.string()
          .required("This field is required")
          .typeError("This field is required");
      }
      return Yup.string().nullable();
    }),
    expiryDate: Yup.string()
      .required("This field is required")
      .nullable()
      .matches(Constants.REGEXP.DATE, {
        message: "The date is invalid",
        excludeEmptyString: true,
      }),
  });

  const getSchema = () => {
    const def = propsTemplate?.fileUploadProps?.accept
      ? filesSchema[propsTemplate?.fileUploadProps?.accept]
      : Yup.object().shape({});
    return def.concat(schema);
  };

  return (
    <Form
      defaultValues={initialValues}
      schema={getSchema()}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
      onlySendDirty={false}
    >
      <Template
        buttons={{
          ...buttons,
          submit: <SubmitButton {...buttonSubmitProps}>Add</SubmitButton>,
        }}
        props={propsTemplate}
      >
        <>
          {nationalityCondition && (
            <TemplateLabel label="Nationality" isRequired isColumn>
              <ControllerInput
                render={CountrySelector}
                name="nationality"
                placeholder={translate("MODULES.CLARA.SELECT_COUNTRY")}
                showStrong={true}
                disabled
                clear
              />
            </TemplateLabel>
          )}

          {passportNumberCondition && (
            <TemplateLabel label="Passport number" isRequired isColumn>
              <ControllerInput
                render={TextInput}
                name="passportNumber"
                placeholder="Passport number"
              />
            </TemplateLabel>
          )}

          <TemplateLabel label="Expiry date" isRequired isColumn>
            <ControllerInput
              render={CalendarInputDate}
              name="expiryDate"
              placeholder="dd/mm/yyyy"
              defaultValue={null}
            />
          </TemplateLabel>
        </>
      </Template>
    </Form>
  );
};

export default FormExpiredDocument;

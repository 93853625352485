import useLazyQuery from "../../../../../../hooks/custom/useLazyQuery";
import {RoleFields} from "../../../../../stakeholder/graphql/typePolicies";
/*
type Stakeholder -> formationDataConnections -> :
primaryContact: [FormationCompany]
dataprotection: [FormationCompany]
directors: [FormationCompany]
sharesholders: [FormationCompany]
authorisedSignatories: [FormationCompany]
bankInformation: [FormationCompany]
 */
const CONNECTIONS=`
${RoleFields}
query getStakeholderConnections($startupId:ID!, $stakeholderId:ID!){
getStakeholder(startupId:$startupId, stakeholderId:$stakeholderId){
id 
isThereProductItems
stakeholderCorporateConnections{
    roles{
        ...RoleFields
    }
    authorizedSignatories{
        id
        fullName
    }
}
fullName
authorizedSignatoryDocuments{
id
name
}
roles{
...RoleFields
}
documents{
id
name
}
areThereEquityConnections
keyPeopleConnections{
    dataProtectionContact{
        id
        name
    }
    authorisedSignatory{
        id
        name
    }
    directors{
        id
        name
    }
    bankingAuthority{
        id 
        name
    }
}
formationDataConnections{
primaryContact{
id name
}
dataProtection{
id name
}
directors{
id name
}
shareholders{
id name
}
authorisedSignatories{
id name
}
bankInformation{
id name
}
primaryContact{
id name
}
}
dueDiligences{
id
title
status
}
shareIssuances{
id
sharePrice {currency amount}
groupCompany { id name }
holder {
    ... on GroupCompany {
        id
        name
    }
    ... on StakeHolder {
        id
        fullName
    }
}
}
}
}
`
const useGetConnections=(variables,config?,props?)=>{
    const {data,loading,manualQuery}=useLazyQuery(CONNECTIONS,variables)
    return manualQuery
}
export default useGetConnections


import useMutation from '../../../../hooks/custom/useMutation'
import useMutationDelete from '../../../../hooks/custom/useMutationDelete'
import { COMPANY_CORPORATE_MAP } from './../../../../modules/map/hooks/services/useCompanyCorporateMap'
import { useSelector } from 'react-redux'
import { gql } from '@apollo/client';
import _ from "lodash";
import {RoleFields} from "../../../../modules/stakeholder/graphql/typePolicies";
import {useCallback, useEffect, useMemo} from "react";

const DELETE_STAKEHOLDER = `
${RoleFields}
mutation deleteStakeholder(
    $startupId: String!
    $stakeholderId: String!
    $setInactive:Boolean = false
){
    deleteStakeholder(
        startupId: $startupId  
        stakeholderId: $stakeholderId
        setInactive:$setInactive
    ){
        id
        fullName
          roles  @include(if:$setInactive){ 
      ...RoleFields
    }
    }
}
`;


const useDeleteStakeholderIndividual = ( config ,props) => {
    const shouldRefetchQuery = (observer) => {
        return false;
    }
    const mutation = useMutationDelete(DELETE_STAKEHOLDER, { shouldRefetchQuery, ...config },{...props})
    return mutation;
}
export default useDeleteStakeholderIndividual;
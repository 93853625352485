import React from "react";
import * as Yup from "yup";
import { Constants } from "../../../../../../../v1/utils";
import _ from "lodash";

function emptyStringToNull(value, originalValue) {
  if (typeof originalValue === "string" && originalValue === "") {
    return null;
  }
  return value;
}

const useGetSchemaDetails = (type, schemaToConcat, field, title, ...props) => {
  switch (type) {
    case "stakeholderDetails":
      return Yup.object().shape({
        stakeholder: Yup.object().shape({
          id: Yup.string().nullable().required("Required"),
          fullName: Yup.string().nullable().required("Required"),
          email: Yup.string()
            .lowercase()
            .matches(Constants.REGEXP.EMAIL, {
              message: "This field must be a valid email",
              excludeEmptyString: true,
            })
            .nullable()
            .required("Required"),
          nationality: Yup.string().nullable().required("Required"),
          address: Yup.object().shape({
            street: Yup.string().nullable().required("Required"),
            city: Yup.string().nullable().required("Required"),
            country: Yup.string().nullable().required("Required"),
            zipCode: Yup.string().nullable().optional(),
          }),
          isAnEntity: Yup.boolean(),
          passportNumber: Yup.string().when(["isAnEntity"], {
            is: true,
            then: Yup.string().nullable().optional(),
            otherwise: Yup.string().nullable().required("Required"),
          }),
          phoneNumber: Yup.string()
            .nullable()
            .required("Required")
            .typeError("This field is required"),
        }),
      });
    case "companyDetails":
      return Yup.object().shape({
        company: Yup.object().shape({
          name: Yup.string().nullable().required("Required"),
          country: Yup.string().nullable().required("Required"),
          jurisdiction: Yup.string().nullable().required("Required"),
          type: Yup.string().nullable().required("Required"),
          registeredNumber: Yup.string().nullable().required("Required"),
          registeredAddress: Yup.object().shape({
            country: Yup.string().nullable().required("Required"),
            city: Yup.string().nullable().required("Required"),
            zipCode: Yup.string().nullable().optional(),
            street: Yup.string().nullable().required("Required"),
          }),
        }),
      });
    case "stakeholderRoleAndServicesNoGroup":
      return Yup.object().shape({
        stakeholder: Yup.object().shape({
          position: Yup.object()
            .shape({
              role: Yup.string().nullable().required("Please select an option"),
              otherRole: Yup.string().when(["role"], {
                is: "otherRole",
                then: Yup.string().required("Required"),
                otherwise: Yup.string().nullable().optional(),
              }),
              otherResponsibilities: Yup.string().when(["role"], {
                is: "otherRole",
                then: Yup.string().required("Required"),
                otherwise: Yup.string().nullable().optional(),
              }),
              responsibilitiesCIO: Yup.array()
                .of(Yup.string())
                .when(["role"], {
                  is: "CIO",
                  then: Yup.array()
                    .of(Yup.string())
                    .required("At least one must be selected?"),
                  otherwise: Yup.array().of(Yup.string()).nullable().optional(),
                }),
              responsibilitiesCEO: Yup.array()
                .of(Yup.string())
                .when(["role"], {
                  is: "CEO",
                  then: Yup.array()
                    .of(Yup.string())
                    .required("At least one must be selected"),
                  otherwise: Yup.array().of(Yup.string()).nullable().optional(),
                }),
              responsibilitiesCFO: Yup.array()
                .of(Yup.string())
                .when(["role"], {
                  is: "CFO",
                  then: Yup.array()
                    .of(Yup.string())
                    .required("At least one must be selected?"),
                  otherwise: Yup.array().of(Yup.string()).nullable().optional(),
                }),
              responsibilitiesCTO: Yup.array()
                .of(Yup.string())
                .when(["role"], {
                  is: "CTO",
                  then: Yup.array()
                    .of(Yup.string())
                    .required("At least one must be selected"),
                  otherwise: Yup.array().of(Yup.string()).nullable().optional(),
                }),
              responsibilitiesCOO: Yup.array()
                .of(Yup.string())
                .when(["role"], {
                  is: "COO",
                  then: Yup.array()
                    .of(Yup.string())
                    .required("At least one must be selected"),
                  otherwise: Yup.array().of(Yup.string()).nullable().optional(),
                }),
              reports: Yup.string().when(["role"], {
                is: (value) => value !== "CEO",
                then: Yup.string()
                  .nullable()
                  .required("At least one must be selected"),
                otherwise: Yup.string().nullable().optional(),
              }),
              otherResponsibility: Yup.string().nullable().optional(),
              services: Yup.lazy((values) => {
                if (
                  !values ||
                  _.get(values, "commitment") !== "otherCommitment"
                ) {
                  return Yup.object().shape({
                    commitment: Yup.string()
                      .nullable()
                      .required("At least one must be selected"),
                    otherCommitment: Yup.string().when(["commitment"], {
                      is: "otherCommitment",
                      then: Yup.string()
                        .nullable()
                        .required("At least one must be selected"),
                      otherwise: Yup.string().nullable().optional(),
                    }),
                  });
                } else if (
                  values.commitment &&
                  values.commitment === "otherCommitment"
                ) {
                  return Yup.object().shape({
                    commitment: Yup.string().nullable().required("Required"),
                    otherCommitment: Yup.string().when(["commitment"], {
                      is: "otherCommitment",
                      then: Yup.string()
                        .nullable()
                        .required("At least one must be selected"),
                      otherwise: Yup.string().nullable().optional(),
                    }),
                    otherHours: Yup.number().when(["otherCommitment"], {
                      is: "otherHours",
                      then: Yup.number()
                        .min(1, "Min value 1")
                        .max(168, "Max value 168")
                        .transform(emptyStringToNull)
                        .required("Required")
                        .typeError("Must be a number"),
                      otherwise: Yup.number().nullable(),
                    }),
                  });
                }
              }),
            })
            .test(
              "double conditional",
              null, // we'll return error message our self if needed
              (position) => {
                if (position) {
                  const responsibilitiesElement = Object.keys(position).filter(
                    (p) => p.substring(0, 16) === "responsibilities"
                  );
                  const otherResponsibility = responsibilitiesElement.some(
                    (element) =>
                      position[element] &&
                      position[element].some(
                        (responsibility) => responsibility === "option_z"
                      )
                  );

                  if (otherResponsibility && !position.otherResponsibility) {
                    return new Yup.ValidationError(
                      "Required", // your custom error message
                      null,
                      "stakeholder.position.otherResponsibility"
                    );
                  }
                  return true;
                }
                return false;
              }
            ),
        }),
      });
    case "stakeholderRoleAndServices":
      return Yup.object().shape({
        stakeholder: Yup.object().shape({
          position: Yup.object().shape({
            id: Yup.string().nullable().required("Required"),
            responsibilities: Yup.array()
              .of(Yup.object())
              .when(["isDefault"], {
                is: true,
                then: Yup.array()
                  .of(Yup.object())
                  .nullable()
                  .required("At least one must be selected"),
                otherwise: Yup.array().of(Yup.object()).nullable().optional(),
              }),
            otherResponsibilities: Yup.string().when(["isDefault"], {
              is: false,
              then: Yup.string().nullable().required("Required"),
              otherwise: Yup.string().nullable().optional(),
            }),
            reports: Yup.string().when(["code"], {
              is: (value) => value !== "CEO",
              then: Yup.string()
                .nullable()
                .required("At least one must be selected"),
              otherwise: Yup.string().nullable().optional(),
            }),
            services: Yup.lazy((values) => {
              if (
                !values ||
                _.get(values, "commitment") !== "otherCommitment"
              ) {
                return Yup.object().shape({
                  commitment: Yup.string()
                    .nullable()
                    .required("At least one must be selected"),
                  otherCommitment: Yup.string().when(["commitment"], {
                    is: "otherCommitment",
                    then: Yup.string().required("Required"),
                    otherwise: Yup.string().nullable().optional(),
                  }),
                });
              } else if (
                values.commitment &&
                values.commitment === "otherCommitment"
              ) {
                return Yup.object().shape({
                  commitment: Yup.string().nullable().required("Required"),
                  otherCommitment: Yup.string().when(["commitment"], {
                    is: "otherCommitment",
                    then: Yup.string().required("Required"),
                    otherwise: Yup.string().nullable().optional(),
                  }),
                  otherHours: Yup.number().when(["otherCommitment"], {
                    is: "otherHours",
                    then: Yup.number()
                      .min(1, "Min value 1")
                      .max(168, "Max value 168")
                      .transform(emptyStringToNull)
                      .required("Required")
                      .typeError("Must be a number"),
                    otherwise: Yup.number().nullable(),
                  }),
                });
              }
            }),
          }),
        }),
      });
    case "recipientNoticeDetails":
      return Yup.object().shape({
        company: Yup.lazy((value) => {
          if (value) {
            // console.log('company -> value', value);

            if (value && !value.useRegisteredAddress) {
              return Yup.object().shape({
                recipientNotice: Yup.object()
                  .shape({
                    fullName: Yup.string().nullable().required("Required"),
                    email: Yup.string()
                      .lowercase()
                      .matches(Constants.REGEXP.EMAIL, {
                        message: "This field must be a valid email",
                        excludeEmptyString: true,
                      })
                      .nullable()
                      .required("Required"),
                    address: Yup.object().shape({
                      country: Yup.string().nullable().required("Required"),
                      street: Yup.string().nullable().required("Required"),
                      city: Yup.string().nullable().required("Required"),
                      state: Yup.string().nullable().optional(),
                      zipCode: Yup.string().nullable().optional(),
                    }),
                  })
                  .nullable(),
              });
            } else if (value && value.useRegisteredAddress) {
              return Yup.object().shape({
                recipientNotice: Yup.object()
                  .shape({
                    fullName: Yup.string().nullable().required("Required"),
                    email: Yup.string()
                      .lowercase()
                      .matches(Constants.REGEXP.EMAIL, {
                        message: "This field must be a valid email",
                        excludeEmptyString: true,
                      })
                      .nullable()
                      .required("Required"),
                  })
                  .nullable(),
              });
            }
          }
        }),
      });
  }
};

export default useGetSchemaDetails;

import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TextInput from 'src/components/inputs/Text/TextInput';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateLabel from 'src/modules/generic/components/Inputs/LabelInput';
import * as Yup from 'yup';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText,addAssistantText } from 'src/modules/generic/store/action';
import CountryJurisdiction from "src/modules/generic/components/CountryJurisdictionSelector";

function AddDelawareCompany() {

  const { next, context, prev,cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  
  const initialValues = useMemo(() => {
    return context?.AddDelawareCompany ?? {}
  }, [context]);
  
  const handleCancel = (values) => {
    cancel();
  }

  const handlePrev = (values) => {
    prev();
  }

  const handleSubmit = async (values) => {
    next(values)
  }
  
  useEffect( ()=>{
    dispatch(
      addAssistantText(
        translate('MODULES.ONBOARDINGCOMPANY.TOOLTIP_CREATE_DELAWARE_1')
      )
    );
    dispatch(
      addAssistantText(
        translate('MODULES.ONBOARDINGCOMPANY.TOOLTIP_CREATE_DELAWARE_2')
      )
    );
    return () => {
      dispatch(clearAssistatText())
    }
  },[dispatch])
  
  const schema = Yup.object().shape({
    name: Yup.string().nullable().required("Add the company name"),
    country: Yup.string().nullable().required("Required"),
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={ { mode: 'onChange' } }
    >
      <TemplateWizard
        props={{
          title: 'Choose a company name',
          subTitle: 'Add a company',
          skeleton: null,
          variant: 'simple'
        }}
        buttons={{
          previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('GENERIC_BUTTON_ADD_GROUP')}</SubmitButton>,
        }}
      >
        <>
          <TemplateLabel
            label={"Company name"}
            isRequired={true}
          >
            <ControllerInput
              render={TextInput}
              name="name"
              rightLabel={'Inc.'}
              placeholder={"For example, Clara Technologies"}
              defaultlabel={""}
            />
          </TemplateLabel>
          <CountryJurisdiction
            showJurisdiction={true}
            disabled={true}
            unmounClearJurisdiction={false}
            hideLoading={true}
            countryLabel={"Country of Incorporation"}
          />
        </>
      </TemplateWizard>
    </Form>
  );
}

export default AddDelawareCompany;


import _ from "lodash";
import React, { useState,useEffect } from 'react';
import { LayoutProvider } from './LayoutContext';

const ClaraLayout = ({ children, layout, ...props }) => {
  const [layoutProps, setLayoutProps] = useState(props);
  const updatePropsLayout = (values) => {
    setLayoutProps(pre=>({...pre,...values}));
  }
 useEffect(()=>{
  setLayoutProps(props)
 },[JSON.stringify(props)])
  return (
 
    <LayoutProvider value={{ update: updatePropsLayout, ...layoutProps }}>
      <React.Fragment>
        {React.createElement(
          layout,
          { ...layoutProps },
          children
        )}
      </React.Fragment>
    </LayoutProvider>

  );
}

export default ClaraLayout;
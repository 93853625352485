// import classnames from "classnames";
import _ from "lodash";
// import React, { FC, useEffect, useRef, useState } from "react";
import React, { FC, useEffect, useState } from "react";
import ErrorInput from "../ErrorInput";
// import Icon from "../../Icon";
import Typography from "../../Texts/Typography";
import classesModules from "./classes.module.scss";
import InputInterface from "./../Input/InputInterface";
import ButtonCard from "../../Buttons/ButtonCard";

export class CheckboxButtonOptions<Value = any, ComponentProps = any> {
  public value: Value;
  public label?: string;
  public component?: React.ComponentType;
  public componentProps?: ComponentProps;
  public disabled?: boolean;
  public visible?: boolean;
}

export interface CheckboxProps extends InputInterface {
  /**  Option's List */
  options: Array<CheckboxButtonOptions>;
  /**  Array value component */
  value: Array<any>;
  /**  Default component */
  defaultValue?: Array<any>;
  /** Function to manae */
  handleSelect?: (option: CheckboxButtonOptions, isSelected: boolean) => void;
  /**To style all buttons **/
  classNameButton?: string;
  variant?: string;
  direction?: string;
  classContainer?: string;
}

/**
- Checkbox component to show labels with inputs
**/

const CheckboxButton: FC<CheckboxProps> = ({
  errors,
  errorMessage,
  defaultValue = [],
  disabled,
  onChange,
  onFocus,
  onBlur,
  value,
  classNameButton,
  classContainer,
  options = [{ value: false, label: 'No' }, { value: true, label: 'Yes' }],
  handleSelect = null,
  className,
  variant = "overlays",
  direction = "row",
}: CheckboxProps) => {

  const [localValues, setLocalValues] = useState(value || defaultValue || []);

  useEffect(() => {
    if (!_.isEqual(value, localValues) && value) {
      setLocalValues(value);
    }
  }, [JSON.stringify(value)]);

  const isSelected = (option: CheckboxButtonOptions) => {
    return localValues.indexOf(option.value) >= 0;
  };

  const handleClick = (option: CheckboxButtonOptions, isSelected: boolean) => {
    if (handleSelect) {
      return handleSelect(option, isSelected);
    }
    var auxLocalValues: Array<string> = [...localValues];
    if (isSelected) {
      auxLocalValues = _.filter(
        auxLocalValues,
        (value) => value !== option.value
      );
    } else {
      auxLocalValues.push(option.value);
    }
    setLocalValues([...auxLocalValues]);
    onChange && onChange(auxLocalValues);
  };

  return (
    <React.Fragment>
      <div className={`${classesModules.CheckboxButton}  ${className}`}>
        <div
          className={`${classesModules.Columns} ${classesModules[direction]}`}
        >
          {_.map(options, (option, index) => {
            const selected = isSelected(option);

            return (
              <div className={`${classesModules.CheckboxOption} ${classContainer}`} key={index}  >
                <ButtonCard
                  variant={variant}
                  selected={selected}
                  className={classNameButton}
                  onClick={() => handleClick(option, selected)}
                  onFocus={() => onFocus && onFocus(option)}
                  onBlur={() => onBlur && onBlur(option)}
                  customContendElement={
                    option.component ? option.component : Typography
                  }
                  disabled={disabled || option.disabled}
                  propsCustomElement={
                    option.component
                      ? { ...option.componentProps }
                      : {
                        children: option.label,
                        component: _.get(
                          option,
                          "componentProps.component",
                          "span"
                        ),
                        variant: _.get(option, "componentProps.variant", "s"),
                        color: _.get(option, "componentProps.color", "black"),
                        weight: _.get(
                          option,
                          "componentProps.weight",
                          "semibold"
                        ),
                      }
                  }
                />
              </div>
            );
          })}
        </div>
        <ErrorInput
          message={errorMessage}
          classNameError={classesModules.error}
        />
      </div>
    </React.Fragment>
  );
};

export default CheckboxButton;

import _ from 'lodash';
import React, { useEffect, useState, useMemo } from 'react';
import useMachine from '../../../../../../../modules/generic/context/MachineContext/useMachine';
import Button from "../../../../../../../modules/generic/components/Buttons/Button";
import useTranslate from '../../../../../../../modules/generic/hooks/useTranslate';
import TemplateWizard from "../../../../../../../modules/generic/templates/Modal/TemplateWizard";
import { clearAssistatText } from "../../../../../../../modules/generic/store/action";
import useModal from "../../../../../../../modules/generic/hooks/useModal";
import FormYourSubcription from "../../../../../../../modules/checkout/forms/FormYourSubscription/FormYourSubscription";
import FormConstitutionalDocuments from "../../forms/FormConstitutionalDocuments/FormConstitutionalDocuments";

/**
 * Step edit subscriptions
 * @param {*} param0
 * @returns
 */

function ConstitutionalDocuments({ openParam }) {

  const { next, send, context, prev } = useMachine();
  const { translate } = useTranslate()
  const { closeModal: onClose } = useModal();
  const [open, setOpen] = useState(openParam);
  const [changed, setChanged] = useState(false);

  const handleCancel = (values) => {
    onClose();
    setOpen(false);
    clearAssistatText()
  }

  useEffect(() => {
    setOpen(openParam);
  }, [openParam]);


  const handleCompleteSubmit = async (values) => {
    next(values)
  }

  const { renewalStatus, billingStatus } = useMemo(() => {
    return {
      renewalStatus: _.get(context, 'getSubscriptionData.renewal.status'),
      billingStatus: _.get(context, 'getSubscriptionData.billing.status')
    }
  }, [JSON.stringify(context)]);

  return (
    <FormConstitutionalDocuments
      buttons={{
        cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
      }}
      onCompleteSubmit={handleCompleteSubmit}
      propsTemplate={{
        title: translate('MODULES.CONSTITUTIONAL_DOCUMENTS.STEP_1.TITLE'),
        subTitle: translate('MODULES.CONSTITUTIONAL_DOCUMENTS.STEP_1.SUBTITLE')
      }}
      Template={TemplateWizard}
      hasChanged={() => { setChanged(true) }}
    />
  );
}

export default ConstitutionalDocuments;

import _ from 'lodash';
import useMachine from '../../../../../generic/context/MachineContext/useMachine';
import FormYourSubcription from "../../../../../../modules/checkout/forms/FormYourSubscription/FormYourSubscription";
import React, { useMemo } from 'react';
import Button from "../../../../../generic/components/Buttons/Button";
import useTranslate from '../../../../../generic/hooks/useTranslate';
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import { clearAssistatText } from "../../../../../generic/store/action";
import useModal from "../../../../../generic/hooks/useModal";

/**
 * Step edit subscriptions
 * @param {*} param0
 * @returns
 */

function EditYourSubscription() {
  const { next, context } = useMachine();
  const { translate } = useTranslate();
  const { closeModal: onClose } = useModal();

  const handleCancel = () => {
    onClose();
    clearAssistatText();
  }

  const handleCompleteSubmit = async (values) => {
    next(values);
  }

  const {renewalStatus, billingStatus} = useMemo(() => {
    return {
      renewalStatus: _.get(context, 'getSubscriptionData.renewal.status'),
      billingStatus: _.get(context, 'getSubscriptionData.billing.status')
    } 
  }, [JSON.stringify(context)]);

  return (
    <FormYourSubcription
      initialValues={{
        renewal: renewalStatus,
        billing: billingStatus,
      }}
      isEdit
      buttons={{
        cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
      }}
      defaultData={context}
      onCompleteSubmit={handleCompleteSubmit}
      propsTemplate={{
        title: translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_1.SUBTITLE'),
        subTitle: translate('MODULES.CHECKOUT.MODALS.RENEWAL_AND_BILLING.STEP_1.TITLE')
      }} 
      Template={TemplateWizard}
    />
  );
}

export default EditYourSubscription;
import React, {Component} from 'react';
import {Field, FieldArray} from "formik";
import classes from "../../SteperForm.module.scss";
import TextInput from "../../Inputs/TextInput";
import TextAreaInput from "../../Inputs/TextAreaInput";
import ClaraInputRadioButton from "../../../../components/Form/ClaraInputRadioButton/ClaraInputRadioButton";

import FormikHelper from "../../../../utils/formikHelper";
import moment from "moment";
import _ from "lodash";
import ClaraUpload from "../../../../components/Form/ClaraUpload/ClaraUpload";
import Checkbox from '../../Inputs/Checkbox'


class FounderDocs extends Component {

    constructor(props) {
        super(props)
        this.state = {
            totalFounders: 0,
            foundersList: [],
            founders: [],
            arrayHelpers: null,
            expirationDate: undefined,
            cashContributionDate: undefined
        }
    }



    componentWillMount() {

        let { stakeholderList } = this.props;
        this.setState({
            foundersList: _.filter(stakeholderList, st => _.find(st.roles, ["name", "FOUNDER"])).map(founder => {
                return { ...founder, label: founder.fullName }
            })
        }, this.addFounder)


    }

    addFoundersFromData(founders) {
        const newFounders = [];
        founders.forEach((founder, i) => {
            newFounders.push(
                this.getNewFounderStructure(i, founder)
            );

            this.setState({
                founders: newFounders
            })
        });
    }

    getDateForPickerFromData(date) {
        if (date === undefined) return null;
        return moment(date).toDate();
    }

    findStakeholder = (id) => {
        return _.find(this.state.foundersList, founder => founder.id === id);
    }

    selectFounder = (founder, extraparams, form, cb) => {
        const stakeholder = this.findStakeholder(founder);
        if (stakeholder) {
            _.set(cb, "selectFounder", founder);
            if (!stakeholder.address) {
                stakeholder.address = {}
            }
            _.set(form.values.founders[cb.index], "founder", {
                id: founder,
                email: stakeholder.email || '',
                passportNumber: stakeholder.passportNumber || '',
                nationality: stakeholder.nationality || '',
                address: stakeholder.address

            });
        }
    };

    addFounder = (arrayHelpers) => {
        let newFounders = this.state.founders;
        const i = newFounders.length;
        if (i === 0) this.setState({ arrayHelpers })


        newFounders.push(this.getNewFounderStructure(i, arrayHelpers));

        this.setState({
            founders: newFounders
        })
    }

    foundersRender = (arrayHelpers) => {
        if (arrayHelpers.form.values.totalFounders) {
            return _.range(_.toNumber(arrayHelpers.form.values.totalFounders)).map(i => this.getNewFounderStructure(i));
        }
        return null;
    };

    getNewFounderStructure = (index) => {
        const { tooltips = {} } = this.props;
        return (
            <div>
                <hr className={classes.Separator} />
                <div className={`${classes.ContainerRowAgreement}`}>
                    <Field name={`founders[${index}].founder.fullName`}>
                        {({ field, form }) => (
                            <TextInput
                                type='text'
                                label={`Founder# ${index + 1}:`}
                                field={field}
                                form={form}
                                placeholder={"Full Name..."}
                                required={true}
                                tooltip={tooltips['Email Address']}
                            />
                        )}
                    </Field>
                </div>
                <div className={classes.ContainerRow}>
                    <Field name={`founders[${index}].founder.email`}>
                        {({ field, form }) => (
                            <TextInput
                                type='text'
                                label='Email Address: '
                                field={field}
                                form={form}
                                placeholder={"email..."}
                                required={true}
                                tooltip={tooltips['Email Address']}
                            />
                        )}
                    </Field>
                </div>
                <div className={classes.ContainerRow}>
                    <Field name={`founders[${index}].founder.nationality`}>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='text'
                                label='Nationality: '
                                field={field}
                                form={form}
                                placeholder={"nationality..."}
                                tooltip={tooltips['Nationality']}
                            />
                        )}
                    </Field>
                </div>
                <div className={classes.ContainerRow}>
                    <Field name={`founders[${index}].founder.passportNumber`}>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='text'
                                label='Passport Number: '
                                field={field}
                                form={form}
                                placeholder={"passport..."}
                                tooltip={tooltips['Passport Number']}
                            />
                        )}
                    </Field>
                </div>
                <div className={classes.ContainerRow}>
                    <Field name={`founders[${index}].founder.address`}>
                        {({ field, form }) => (
                            <TextAreaInput
                                required={false}
                                label='Address'
                                field={field}
                                form={form}
                                autoComplete='off'
                                placeholder={"write your address..."}
                                tooltip={tooltips['This Address']}
                            />
                        )}
                    </Field>
                </div>

                <div className={classes.ContainerRow}>
                    <Field name={`founders[${index}].founder.shortName`}>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                label='Short Name'
                                field={field}
                                form={form}
                                placeholder={"write your Short Name..."}
                                tooltip={tooltips['This Short Name']}
                            />
                        )}
                    </Field>
                </div>
                <div className={`${classes.ContainerRowAgreement}`}>
                    <Field name={`founders[${index}].rolesAndResponsibilities`}>
                        {({ field, form }) => (
                            <TextInput
                                type='text'
                                label={`Role and Responsibility`}
                                field={field}
                                form={form}
                                placeholder={`Please describe the role and responsibility...`}
                                required={true}
                                tooltip={tooltips['Email Address']}
                            />
                        )}
                    </Field>
                </div>
                <div className={`${classes.ContainerRowAgreement}`}>
                    <Field name={`founders[${index}].shareOwnership`}>
                        {({ field, form }) => (
                            <TextInput
                                type='text'
                                label={`What percentage of shares in [Holding Company] will own?`}
                                field={field}
                                form={form}
                                placeholder={`Insert percentage in numbers only...`}
                                required={true}
                                tooltip={tooltips['Email Address']}
                            />
                        )}
                    </Field>
                </div>

                <div className={`${classes.ContainerRowAgreement}`}>
                    <Field name={`founders[${index}].initialCapitalContributions`}>
                        {({ field, form }) => (
                            <TextInput
                                type='number'
                                label={`What is the amount of the cash contribution each Founder will make?`}
                                field={field}
                                form={form}
                                placeholder={`Insert amount in USD only...`}
                                required={true}
                                tooltip={tooltips['Email Address']}
                            />
                        )}
                    </Field>
                </div>

                <div className={`${classes.ContainerRowAgreement}`}>
                    <Field name={`founders[${index}].shares`}>
                        {({ field, form }) => (
                            <TextInput
                                type='text'
                                label={`What is the total number of issued shares in [Holding Company?]?`}
                                field={field}
                                form={form}
                                placeholder={`Insert percentage in numbers only...`}
                                required={true}
                                tooltip={tooltips['Email Address']}
                            />
                        )}
                    </Field>
                    <br />
                </div>
            </div>)
    };

    getNewShareholderStructure = (index) => {
        const { tooltips = {} } = this.props;

        return (
            <>
                <hr className={classes.Separator} />
                <div className={`${classes.ContainerRowAgreement}`}>
                    <Field name={`founders[${index}].shareOwnership`}>
                        {({ field, form }) => (
                            <TextInput
                                type='text'
                                label={`What percentage of shares in [Holding Company] will each Founder own?`}
                                field={field}
                                form={form}
                                placeholder={`Founder#${index + 1} ...`}
                                required={true}
                                tooltip={tooltips['Email Address']}
                            />
                        )}
                    </Field>
                </div>

                <div className={`${classes.ContainerRowAgreement}`}>
                    <Field name={`founders[${index}].initialCapitalContributions`}>
                        {({ field, form }) => (
                            <TextInput
                                type='text'
                                label={`What is the amount of the cash contribution each Founder will make?`}
                                field={field}
                                form={form}
                                placeholder={`Founder#${index + 1} ...`}
                                required={true}
                                tooltip={tooltips['Email Address']}
                            />
                        )}
                    </Field>
                </div>

                <div className={`${classes.ContainerRowAgreement}`}>
                    <Field name={`founders[${index}].initialCapitalContributions`}>
                        {({ field, form }) => (
                            <TextInput
                                type='number'
                                label={`What is the amount of the cash contribution each Founder will make?`}
                                field={field}
                                form={form}
                                placeholder={`Founder#${index + 1} ...`}
                                required={true}
                                tooltip={tooltips['Email Address']}
                            />
                        )}
                    </Field>
                </div>

            </>
        )
    }
    selectTotalFounders = (totalFounder, extraparams, form, cb) => {

        this.setState({
            totalFounder
        })
    }



    shareholderStructureRender = (arrayHelpers) => {

        if (arrayHelpers.form.values.totalFounders) {
            return _.range(_.toNumber(arrayHelpers.form.values.totalFounders)).map(i => this.getNewShareholderStructure(i));
        }
        return null;
    }


    handleChange(e, field, form) {

        const date = e === null ? null : moment(e).format("YYYY-MM-DD");

        FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

        this.setState({
            [field.name]: e
        })
    }



    render() {
        const isRequired = message => value => (!!value ? undefined : message);
        const { tooltips = {}, fromGenerateDoc, formType } = this.props;
        return (
            <>
                {fromGenerateDoc ? null : <hr className={classes.Separator} />}
                {formType == 'uploadToEdit' ? (<div className={classes.uploaderOnFounder}>
                    <Field name={`file`}>
                        {({ field, form }) => (
                            <ClaraUpload
                                required={true}
                                name={`filepond`}
                                label='Upload your document: '
                                field={field}
                                form={form}
                            />
                        )}
                    </Field>
                </div>) : null}
                <hr className={classes.Separator} />
                <div className={classes.ContainerRow}>
                    <Field name={`totalFounders`}>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='text'
                                label='How many Founders are there?'
                                field={field}
                                form={form}
                                placeholder={"Number of Founders..."}
                                tooltip={tooltips['']}
                            />
                        )}
                    </Field>
                </div>
                <div className={classes.ContainerRow}>
                    <Field name='agreementName'>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='text'
                                label='Document Name: '
                                field={field}
                                form={form}
                                placeholder={"Type here..."}
                                tooltip={tooltips['Document Name']}
                            />
                        )}
                    </Field>
                </div>
                <hr className={classes.Separator} />
                <div className={classes.ContainerRow}>
                    <Field name={`holdingCompany`}>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='text'
                                label='What will be the name of your Holding Company?'
                                field={field}
                                form={form}
                                placeholder={"Description Holding..."}
                                tooltip={tooltips['Holding Company']}
                            />
                        )}
                    </Field>
                    <Field name={`jurisdictionCompany`}>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='text'
                                label='Jurisdiction of Incorporation of Holding Company'
                                field={field}
                                form={form}
                                placeholder={"Jurisdiction Holding Company..."}
                                tooltip={tooltips['Jurisdiction of the Company']}
                            />
                        )}
                    </Field>
                    <Field name={`descriptionBusiness`}>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='text'
                                label='Description of Business'
                                field={field}
                                form={form}
                                placeholder={"Description Business..."}
                                tooltip={tooltips['Description Business']}
                            />
                        )}
                    </Field>
                </div>

                <FieldArray
                    name="founders"
                    render={(arrayHelpers) => (
                        <div>
                            {this.foundersRender(arrayHelpers)}
                        </div>
                    )}
                />

                <div>On what date will the Founders make the cash contributions?</div>
                <div className={classes.ContainerRowAgreement}>
                    <Field name='cashContributionDate'>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='date'
                                label='Cash Contribution Date:'
                                field={field}
                                form={form}
                                placeholder={"Date"}
                                tooltip={tooltips['Cash Contribution Date']}
                                prevComponentChange = {e =>
                                    this.handleChange(e, field, form)
                                }
                            >
                            </TextInput>
                        )}
                    </Field>
                </div>

                <hr className={classes.Separator} />
                <div>What is the amount above which the approval of all the Founders is needed for the Company to incur any debt or offer credit on behalf of a Group Company?</div>
                <div className={classes.ContainerRow}>
                    <Field name={`reservedMatters.individual`}>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='number'
                                label={'Individual'}
                                field={field}
                                form={form}
                                placeholder={"Insert amount in USD only..."}
                                tooltip={tooltips['Individual']}
                            />
                        )}
                    </Field>

                </div>
                <div className={classes.ContainerRow}>
                    <Field name={`reservedMatters.aggregate`}>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='number'
                                label={'Aggregate'}
                                field={field}
                                form={form}
                                placeholder={"Insert amount in USD only..."}
                                tooltip={tooltips['Aggregate']}
                            />
                        )}
                    </Field>

                </div>
                <div>What is the amount above which the approval of all the Founders is needed for the Company to enter into a transaction or commitment or incure a capital expenditure on behalf of a Group Company?</div>
                <div className={classes.ContainerRow}>
                    <Field name={`reservedMatters.limitedTransaction`}>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='number'
                                label={'Limited Transaction'}
                                field={field}
                                form={form}
                                placeholder={"Insert amount in USD only..."}
                                tooltip={tooltips['Limited Transaction']}
                            />
                        )}
                    </Field>

                </div>

                <div className={classes.ContainerRow}>
                    <Field name={`dividends`}>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='text'
                                label={'For how long will the Company not distribute any profits for after the Founders sign the Founders Agreement?'}
                                field={field}
                                form={form}
                                placeholder={""}
                                tooltip={tooltips['Dividends']}
                            />
                        )}
                    </Field>

                </div>


                <div className={classes.ContainerRow}>
                    <Field name={`vesting`}>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='text'
                                label={'Over how long will the shares vest to the Founders?'}
                                field={field}
                                form={form}
                                placeholder={""}
                                tooltip={tooltips['Passport Number']}
                            />
                        )}
                    </Field>

                </div>

                <hr className={classes.Separator} />


                <Field name='badLeaver.price'>
                    {({ field, form }) => (
                        <RadioButton
                            label={"What will the Purchase Price be for a Bad Leaver's shares?"}
                            tooltip={tooltips['Choose Issue Status']}
                            field={field}
                            form={form}
                            value={field.value}
                            other={false}
                            options={[
                                {
                                    "id": 1,
                                    "label": "The lower of Cost and the Fair Price of the unvested shares"
                                },
                                {
                                    "id": 2,
                                    "label": "75% of the Fair Price of their unvested shares"
                                },

                            ]}
                        />
                    )}
                </Field>

                <Field name='badLeaver.date'>
                    {({ field, form }) => (
                        <RadioButton
                            label={"If a Founder resigns from the [Group], when will they be considered a Bad Leaver - if they resign prior to:"}
                            tooltip={tooltips['Choose Issue Status']}
                            field={field}
                            form={form}
                            value={field.value}
                            required={true}
                            options={[
                                {
                                    "id": 1,
                                    "label": "The end of the Term"
                                },
                                {
                                    "id": 2,
                                    "label": "Other"
                                },

                            ]}
                        />
                    )}
                </Field>


                <Field name='goodLeaver.price'>
                    {({ field, form }) => (
                        <RadioButton
                            label={"What will the Purchase Price be for a Good Leaver's shares?"}
                            tooltip={tooltips['Choose Issue Status']}
                            field={field}
                            form={form}
                            value={field.value}
                            other={false}
                            options={[
                                {
                                    "id": 1,
                                    "label": "the higher of Cost and the Fair Price of the unvested shares"
                                },
                                {
                                    "id": 2,
                                    "label": "100% of the Fair Price of their unvested shares"
                                },

                            ]}
                        />
                    )}
                </Field>


                <Field name='goodLeaver.date'>
                    {({ field, form }) => (
                        <RadioButton
                            label={"For how long after they leave the Company must a Founder not compete with the Group's business?"}
                            tooltip={tooltips['Choose Issue Status']}
                            field={field}
                            form={form}
                            value={field.value}
                            required={true}
                            options={[
                                {
                                    "id": 1,
                                    "label": "12 months"
                                },
                                {
                                    "id": 2,
                                    "label": "24 months"
                                },
                                {
                                    "id": 3,
                                    "label": "Other"
                                }

                            ]}
                            disabled={false}
                        />
                    )}
                </Field>


                <div className={classes.ContainerRow}>
                    <Field name={`goodLeaver.descriptionBusinesses`}>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='text'
                                label={`Please describe the businesses which you consider would compete with the Group's business:`}
                                field={field}
                                form={form}
                                placeholder={""}
                                tooltip={tooltips['Passport Number']}
                            />
                        )}
                    </Field>

                </div>


                <Field name='goodLeaver.territory'>
                    {({ field, form }) => (
                        <RadioButton
                            label={"What is the territory in which the non-compete should apply?"}
                            tooltip={tooltips['Choose Issue Status']}
                            field={field}
                            form={form}
                            value={field.value}
                            options={[
                                {
                                    "id": 1,
                                    "label": "any of the jurisdictions in which the Business operates"
                                },
                                {
                                    "id": 2,
                                    "label": "Other"
                                },

                            ]}
                        />
                    )}
                </Field>

                <Field name='shareIncentivePlan.price'>
                    {({ field, form }) => (
                        <RadioButton
                            label={"What percentage of shares will be set aside for the Company's Share Incentive Plan?"}
                            tooltip={tooltips['Choose Issue Status']}
                            field={field}
                            form={form}
                            value={field.value}
                            required={true}
                            options={[
                                {
                                    "id": 1,
                                    "label": "10% of the issued share capital"
                                },
                                {
                                    "id": 2,
                                    "label": "Other"
                                },

                            ]}
                            disabled={false}
                        />
                    )}
                </Field>


                <Field name='shareIncentivePlan.date'>
                    {({ field, form }) => (
                        <RadioButton
                            label={"What will be the minimum vesting period for the Share Incentive Plan?"}
                            tooltip={tooltips['Choose Issue Status']}
                            field={field}
                            form={form}
                            value={field.value}
                            required={true}
                            options={[
                                {
                                    "id": 1,
                                    "label": "36 months"
                                },
                                {
                                    "id": 2,
                                    "label": "Other"
                                },

                            ]}
                            disabled={false}
                        />
                    )}
                </Field>


                <div className={classes.ContainerRowAgreement}>
                    <Field name='expirationDate'>
                        {({ field, form }) => (
                            <TextInput
                                type='date'
                                label='Expiration date:'
                                field={field}
                                form={form}
                                placeholder={"Date"}
                                tooltip={tooltips['Expiration date']}
                                prevComponentChange = {e =>
                                    this.handleChange(e, field, form)
                                }
                            >
                            </TextInput>
                        )}
                    </Field>
                </div>
                <hr className={classes.Separator} />
                {formType == 'uploadToEdit' ? <div>
                    <Field name='confirmSameData'>
                        {({ field, form }) => (
                            <Checkbox
                                className={classes.sameDataCheckbox}
                                field={field}
                                validate={isRequired('This field is required')}
                                form={form}
                                required={true}
                                label='All document details are verified and correct.'
                            />
                        )}
                    </Field>
                </div> : null}



            </>
        )
    }
}

const RadioButton = (props) => {

    const { label, options, required = false, form, field, tooltips, value, other = true } = props;
    const [selection, setSelection] = React.useState(value ? value.id : 0);

    const handleOther = () => {
        if (value && value.id === options.length && value.id !== selection) {
            setSelection(value.id);
            form.setFieldValue(`${field.name}.message`, "");
            if (!other) form.setFieldValue(`${field.name}.message`, options[value.id - 1].label);
        }
    };

    if (value && value.id !== options.length && value.id !== selection) {
        form.setFieldValue(`${field.name}.message`, options[value.id - 1].label);
        setSelection(value.id);
    }

    handleOther();

    return (

        <>

            <Field name={`${field.name}.id`}>
                {({ field, form }) => (
                    <ClaraInputRadioButton
                        label={label}
                        tooltip={tooltips}
                        field={field}
                        form={form}
                        value={selection}
                        required={required}
                        options={options}
                        disabled={false}
                    />
                )}
            </Field>

            {
                other && selection === options.length &&
                <>
                    <Field name={`${field.name}.message`}>

                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='text'
                                label='Other'
                                field={field}
                                form={form}
                                placeholder={""}
                            />
                        )}
                    </Field>
                    <br />
                </>
            }
        </>
    )

}




export default FounderDocs;

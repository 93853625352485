import React, {Component} from "react";
import {connect} from "react-redux";
import classes from "./ClaraInputText.module.scss"
import Tooltip from '@material-ui/core/Tooltip';
import {Constants} from "../../../utils/constants";

class ClaraInputText extends Component {
    state = {
        tooltipText
            : null,
        placeHolder: null,
        validation: {},
        label: null,
        defaultValue: null,
        errorMessage: null,
        showError: false,
        value: null,
        index:null
    };
    /* React Methods */
    componentWillUpdate(prevProps, prevState, snapshot) {
        if (prevProps.session !== this.props.session) {

        }
    }
    componentWillMount() {

        const { tooltipText, placeHolder, validation, label, defaultValue, name, value,index } = this.props;
        //console.log(value);
        this.setState({
            tooltipText,
            placeHolder,
            validation,
            label,
            defaultValue,
            name,
            value: value ? value :"",
            index
        })
    }
    componentDidMount() {
        this.managerValidation(this.state.value);
    }
    /* Logic */
    getError(type, validation) {
        validation = this.state.validation[type]
        if (validation.errorMessage) {
            return validation.errorMessage
        }
        return Constants.DEFAULT_MESSAGES.VALIDATION[type];
    }
    /* Functionality */
    validate(input) {
        if (this.state.validation && this.state.validation.required) {
            if (this.state.validation.required.value === true && (input===null || input===undefined || input === "")) {
                return this.getError("required")
            }

        }
        if (this.state.validation && this.state.validation.pattern && input) {
            const pattern = new RegExp(this.state.validation.pattern.value);
            if (pattern.test(input) === false) {
                return this.getError("pattern")
            }
        }
        return null;
    }
    managerValidation(value){
        const error = this.validate(value);
        if (error) {
            this.setState({
                errorMessage: error,
                value:value
            })
            this.props.updateData(this.state.name, null, false,this.props.index);
        } else {
            this.setState({
                errorMessage: null,
                value:value
            })
            if (this.props.type==="number"){
                value = parseFloat(value);
            }
            this.props.updateData(this.state.name, value, true,this.props.index);
        }
    }
    /* Managaer Events*/
    handleChange = (e) => {
        this.managerValidation(e.target.value);
        
    }
    handleBlur = (e) => {
        this.setState({
            showError: true
        })
        this.managerValidation(this.state.value);
        
    }
    /* Section to get dinamic components*/
    componentJSX_isRequired(){
        if (this.state.label && this.state.validation && this.state.validation.required && this.state.validation.required.value === true){
            return <span className={`required`}>*</span>
        }
    }
    componentJSX_getTooltip() {
        if (this.state.tooltipText
        ) {
            return (
                <Tooltip title={this.state.tooltipText} aria-label={this.state.tooltipText}
                    placement="right"
                    classes={{ 
                        tooltip: classes.tooltip,
                        tooltipPlacementRight: classes.tooltipPlacementRight 
                    }}
                > 
                    <img src="/img/icons/tooltip.svg"></img>
                </Tooltip>
            )
        }
        return null;
    }
    componentJSX_getErrorMessage() {
        if (this.state.errorMessage && this.state.showError) {
            return (
                <div className='errorClaraInput'>
                    {this.state.errorMessage}
                </div>
            )
        }
        return null;
    }
    render() {
        const {value} = this.state
        const classStatusData = value==="" || value===null || value===undefined ? 'inputEmpty':'inputFill';
        return (
            <div className={classes.ClaraInputText}>

                <label className={classes.label}>
                    {this.state.label}
                    {this.componentJSX_isRequired()}
                </label>
                <input
                    value={value}
                    type={this.props.type || "text"}
                    //defaultValue={value[this.state.name]}
                    disabled={this.props.disabled}
                    placeholder={this.state.placeHolder}
                    className={`${classes.input} ${classStatusData}`}
                    onChange={(e) => this.handleChange(e)}
                    onBlur={(e) => this.handleBlur(e)}

                />
                {this.componentJSX_getErrorMessage()}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    session: state.session
});
const mapDispatchToProps = (dispatch) => ({

});
export default connect(mapStateToProps, mapDispatchToProps)(ClaraInputText)

import React from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";

const EditCancelShares = ({go,...props}) => {
 const params = useParams();

  return (
    <MicroFrontend id="MF_Share_Edit_Cancel" component={"./EditCancelShares"} mf="capTable" params={{go,...props,...params}} />
  );
};

export default EditCancelShares;

import useMutation from "src/hooks/custom/useMutation";

export const UPSERT_CREATE_ULTIMATE_PARENT = `
mutation createUltimateParent(
  $startupId: ID!
  $groupCompanyId: ID!
  $entityData: RegulatoryBusinessEntityInput!
) {
  createUltimateParent(
    startupId: $startupId
    groupCompanyId: $groupCompanyId
    entityData: $entityData
  ) {
    id
  }
}
`;

const useCreateUltimateParent = () => {
  const [mutation] = useMutation(
    UPSERT_CREATE_ULTIMATE_PARENT,
    { showSuccessNotification: true }
  );
  return mutation;
};

export default useCreateUltimateParent;

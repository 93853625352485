import Machine from "../../../generic/components/Machine/MachineClass";
import FirstStep from "./Steps/FirstStep";
import SecondStep from "./Steps/SecondStep";
import ThirdStep from "./Steps/ThirdStep";

const isPEP = ({ data }) => data?.pep?.isPEP ?? false
const isNotPEP = ({ data }) => !data?.pep?.isPEP
const isFamilyMemberPEP = ({ data }) => data.pep_family_member.isFamilyMemberOfAPEP
const isNotFamilyMemberPEP = ({ data }) => !data.pep_family_member.isFamilyMemberOfAPEP
const isCloseAssociateOfAPEP = ({ data }) => data.pep_close_associate.isCloseAssociateOfAPEP
const isNotCloseAssociateOfAPEP = ({ data }) => !data.pep_close_associate.isCloseAssociateOfAPEP

const machineDefinition = {
  id: 'PEP',
  initial: 'pep',
  states: {
    pep: {
      on: {
        NEXT: { target: 'is_pep', actions: 'setContext' },
        CANCEL: 'finish_pep'
      }
    },
    is_pep: {
      exit: '',
      on: {
        '': [
          { target: 'finish_pep', cond: 'isPEP' },
          { target: 'pep_family_member', cond: 'isNotPEP' },
        ]
      }
    },
    pep_family_member: {
      on: {
        NEXT: { target: 'is_pep_family_member', actions: 'setContext' },
        PREVIOUS: 'pep',
        CANCEL: 'finish_pep'
      }
    },
    is_pep_family_member: {
      exit: '',
      on: {
        '': [
          { target: 'finish_pep', cond: 'isFamilyMemberPEP' },
          { target: 'pep_close_associate', cond: 'isNotFamilyMemberPEP' },
        ]
      }
    },
    pep_close_associate: {
      on: {
        NEXT: { target: 'finish_pep', actions: 'setContext' },
        PREVIOUS: 'pep_family_member',
        CANCEL: 'finish_pep'
      }
    },
    finish_pep: {
      type: 'final',
      entry: 'final',
    },
    onDone: {
      actions: 'stopMachine'
    }
  }
}

const steps = {
  pep: FirstStep,
  pep_family_member: SecondStep,
  pep_close_associate: ThirdStep
}

const options = {
  guards: {
    isPEP,
    isNotPEP,
    isFamilyMemberPEP,
    isNotFamilyMemberPEP,
    isCloseAssociateOfAPEP,
    isNotCloseAssociateOfAPEP
  },
}

class MachinePep extends Machine {
  constructor() {
    super(machineDefinition, options, steps);
  }
}
export default MachinePep

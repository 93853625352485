import useCustomMachine from '../../../../../generic/hooks/useCustomMachine';
import React, { FC, useEffect } from 'react';
import Button from '../../../../../generic/components/Buttons/Button';
import { StepProps } from "../../../../../generic/components/Wizard/Step";
import TemplateWizard from '../../../../../generic/templates/Modal/TemplateWizard';
import useTranslate from '../../../../../generic/hooks/useTranslate';
import { useDispatch } from 'react-redux';
import { addAssistantText, clearAssistatText } from "../../../../../generic/store/action";
import FormIsThereBankingServices from "../../../Forms/FormIsThereBankingServices";
import useRegisterAsCheckedTask from '../../../../../generic/graphql/mutation/useRegisterAsCheckedTask';
import _ from "lodash";
import useSession from '../../../../../session/hooks/useSession';

const Step1: FC<StepProps> = () => {

  const { translate } = useTranslate();
  const { startupId } = useSession();
  const { next, send, stepData, state } = useCustomMachine();
  const dispatch = useDispatch()
  const { mutation } = useRegisterAsCheckedTask({ showSuccessNotification: false })

  useEffect(() => {
    dispatch(clearAssistatText())
    dispatch(addAssistantText(translate('MODULES.GROUP_COMPANY.BANKING_AUTHORITIES.FIRST_STEP.TOOLTIP')))
  }, [])

  const handleCompleteSubmit = async values => {
    if (!_.get(values, "isThereBankingServices")) {
      const registeredTaskData = {
        entityId: _.get(state, 'context.paramsData.id'),
        taskCode: 'TASK_BANKING_AUTHORITY'
      }
      await mutation({ startupId, registeredTaskData })
    }
    next(values);
  }
  const handleCancel = async values => {
    send("CANCEL");
  }

  return (
    <>
      <FormIsThereBankingServices
        initialValues={stepData}
        onCompleteSubmit={handleCompleteSubmit}
        Template={TemplateWizard}
        propsTemplate={{
          title: translate('MODULES.GROUP_COMPANY.BANKING_AUTHORITIES.FIRST_STEP.TITLE'),
          subTitle: translate('MODULES.GROUP_COMPANY.BANKING_AUTHORITIES.FIRST_STEP.SUBTITLE')
        }}
        buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }} />
    </>
  )
}

export default Step1;

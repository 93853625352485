import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import classesModule from './classes.module.scss';
import Typography from '../../../modules/generic/components/Texts/Typography';
import { StepsDots } from '../../../modules/generic/components/Steps';
// import { DialogProps } from "@material-ui/core/Dialog";

interface ModalTourTemplateProps  {
    title: string;
    subtitle: string;
    image: React.ReactNode;
    buttons: any;
    className?: string;
    steps?: [];
    actualStep?: any;
    setActualStep?: any ;
}

const ModalTourTemplate: React.FC<ModalTourTemplateProps> = ({
    title,
    subtitle,
    image,
    buttons,
    className,
    steps = [], 
    actualStep, 
    setActualStep
   }) => {
    return (
        <React.Fragment >
            <div className={classesModule.containerTour} >
                <div className={classesModule.containerBlocksModalTour} >
                  <div className={classesModule.leftContentBlockModalTour}>
                    <div>
                      <Typography
                        component={'label'}
                        variant={'xxxl'}
                        weight={'bold'}
                        color={'black'}>
                        {title}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        component={'label'}
                        variant={'xxl'}
                        color={'black'}>
                        {subtitle}
                      </Typography>
                    </div>
                  </div>
                  <div className={classesModule.rightContentBlockModalTour}>
                    {image}
                  </div>
                </div>
                <div className={classesModule.containerButtonsModalTour}>
                  <div className={classesModule.containerStepsDots} >
                    <StepsDots className={classesModule.containerStepsDots} steps={steps} actualStep={actualStep} setStep={setActualStep} />
                  </div>
                  <div className={classesModule.containerButtons}>
                    {_.map(buttons, button => {
                      return (
                        <div>{button}</div>
                      )
                     } )} 
                  </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ModalTourTemplate;

import React from 'react';


import {useDispatch} from "react-redux";
import {useParams} from 'react-router-dom';
import IntroSubmitFlow from "../../../../../components/modules/incorporation/InformationFlow/InformationFlow"
import image from '../../../../../images/introFlowImg.svg';
import PAGES from "../../../../../constants/pages";

const KycEngagementIntroView = (props) => {

    

    const dispatch = useDispatch();
    const params = useParams();

    return (
        <React.Fragment>
            <IntroSubmitFlow
                image={image}
                isIntro={true}
                formTitle={"ENGAGEMENT_FORM_TITLE"}
                firstTitle={"ENGAGEMENT_FORM_TITLE_FIRST_TITLE_SUBMITTED"}
                firstText={"ENGAGEMENT_FIRST_TEXT"}
                secondText={"ENGAGEMENT_SECOND_TEXT"}
                buttonText={"KYC_FINALIZE_ENGAGEMENT_BUTTON"}
                secondButtonText={"BUTTON_REMIND_LATER"}
                handleNext={() => dispatch({ type: 'GO_TO', page: PAGES.matterEngagementView, params: { matterId: params.matterId }  })}
                handleClose  ={() => dispatch({ type: 'GO_TO', page: PAGES.switchStartup })}
            />
        </React.Fragment >
    )
}

export default KycEngagementIntroView;

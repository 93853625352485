import React from 'react';
import cls from './CardOptionOnboarding.module.scss';
import classNames from 'classnames'
import Icon from '../../../generic/components/Icon';
import Typography from 'src/modules/generic/components/Texts/Typography';


type direction_content_box = "row" | "column";
export interface CardOptionOnboardingProps {
    text: string;
    title: string;
    icon: any;
    direction?: direction_content_box
    classNameTitle?: string,
    classNameText?: string,
}


const CardOptionOnboarding: React.FC<CardOptionOnboardingProps> = ({ text, title, icon, classNameTitle = '', classNameText = '', direction = "row" }) => {

    return (
        <div className={`d-flex flex-${direction} container-card-clara `} >
            {icon && <Icon isClara icon={icon} size={"6rem"} color={"#2c74ff"} />}
            <div className={classNames(cls['container-card-option-onbording'], { 'box-only-text': !icon })}>
                <Typography variant='xbody' weight='bold' component='h1'>{title}</Typography>
                <Typography variant='body' component='p'>{text}</Typography>
            </div>
        </div>
    )

}




export default CardOptionOnboarding;

import _ from 'lodash';
import React, {useEffect} from 'react';
import {ClaraUpload, Modal, TextInput} from '../../../components/index';
import * as Yup from 'yup';
import ServerConnect from '../../../utils/ServerConnect';
import classes from './DocumentUploadNewVersionForm.module.scss';
import {Field, Formik} from 'formik';
import {Constants, helper} from '../../../utils';
import Botonera from '../../../../components/forms/contents/Botonera/Botonera';
import SecondaryButton from '../../../../components/generic/Buttons/SecondaryButton/SecondaryButton';
import Text from '../../../../components/text/Text/Text';
import PrimaryButton from '../../../../components/generic/Buttons/PrimaryButton/PrimaryButton';
import Title from '../../../../components/text/Title/Title';
import {goToPage} from "../../../../store/actions/page";
import {useDispatch} from "react-redux";


export const ModalDocumentUploadNewVersion = ({open, title, data, callbackUpdate, handleCloseModal,  setLoading}) => {
	const dispatch=useDispatch()
	const handleUploadNewVersion = () => {
		goToPage(dispatch,Constants.PAGES.uploadNewDocumentVersion,{id:data?.document?.id,docType:_.get(data.document,'type.code')?data?.document?.type?.code:data?.document?.docType, templateId:_.get(data?.document?.template,'id','')})
	}

	return (
		<Modal open={open} showHeader={false}>
			<div className={`${classes.containerForm}`}>
				<Title className={`${classes.title}`}>
					<Text uuid={'MODAL_UPLOAD_DOCUMENT_NEW_VERSION_TITLE'}/>
				</Title>

				<div className={classes.contentForm}>
					<p><Text uuid={'MODAL_UPLOAD_DOCUMENT_NEW_VERSION_PARAGRAPH_FIRST'}/></p>
					<p><Text uuid={'MODAL_UPLOAD_DOCUMENT_NEW_VERSION_PARAGRAPH_SECOND'}/></p>
				</div>

				<Botonera>
					<SecondaryButton onClick={() => handleCloseModal()}><Text
						uuid={'BUTTON_CANCEL'} /></SecondaryButton>
					<PrimaryButton
						onClick={handleUploadNewVersion}><Text
						uuid={'MODAL_UPLOAD_DOCUMENT_NEW_VERSION_PROCEED_BUTTON'}
					/></PrimaryButton>
				</Botonera>

			</div>
		</Modal>
	)
};

import { default as React, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import Form from "src/components/forms/Form/Form";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import Button from "src/modules/generic/components/Buttons/Button";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import {
  clearAssistatText,
  addAssistantText,
} from "src/modules/generic/store/action";
import AddressInput from "src/components/forms/modules/startups/individualStakeholder/AddressInput";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import TextInput from "src/components/inputs/Text/TextInput";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import { Constants } from "src/v1/utils";

function ReviewCorporateStakeholderAddressStep() {
  const { next, context, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const stakeholderName = context.initialData.stakeholder.fullName;

  const initialValues =
    context?.ReviewAddress ?? context.initialData.stakeholder;

  const handleCancel = () => cancel();

  const handlePrev = () => prev();

  const handleSubmit = async (values) => next(values);

  useEffect(() => {
    dispatch(
      addAssistantText(
        translate(
          "MODULES.RENEWALS_ADGM.REVIEW_STAKEHOLDER_DETAILS.CORPORATE.COMMON.TOOLTIP"
        ),
        "alert"
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const schema = Yup.object().shape({
    address: Yup.object().shape({
      country: Yup.string().required("This field is required").nullable(),
      street: Yup.string().required("This field is required").nullable(),
      city: Yup.string().required("This field is required").nullable(),
      state: Yup.string().nullable(),
      zipCode: Yup.string().nullable(),
    }),
    recipientNotice: Yup.object().shape({
      fullName: Yup.string().required("This field is required").nullable(),
      email: Yup.string()
        .lowercase()
        .matches(Constants.REGEXP.EMAIL, {
          message: "This field must be a valid email",
          excludeEmptyString: true,
        })
        .required("This field is required")
        .typeError("This field must be a valid email"),
      address: Yup.object().shape({
        country: Yup.string().required("This field is required").nullable(),
        street: Yup.string().required("This field is required").nullable(),
        city: Yup.string().required("This field is required").nullable(),
        state: Yup.string().nullable(),
        zipCode: Yup.string().nullable(),
      }),
    }),
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: translate(
            "MODULES.RENEWALS_ADGM.REVIEW_STAKEHOLDER_DETAILS.CORPORATE.ADDRESS.TITLE"
          ),
          subTitle: translate(
            "MODULES.RENEWALS_ADGM.REVIEW_STAKEHOLDER_DETAILS.CORPORATE.COMMON.SUBTITLE",
            { stakeholderName }
          ),
          skeleton: null,
          variant: "simple",
        }}
        buttons={{
          previous: (
            <Button variant="secondary" onClick={handlePrev}>
              {translate("BUTTON_PREVIOUS")}
            </Button>
          ),
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("BUTTON_CANCEL")}
            </Button>
          ),
          submit: <SubmitButton>{translate("BUTTON_NEXT")}</SubmitButton>,
        }}
      >
        <AddressInput
          name="address"
          label={{
            country: translate("MODULES.CLARA.ADDRESS_REGISTERED_LABEL"),
          }}
          stateOptional
          zipCodeOptional
          isRequired
        />
        <TemplateLabel label="Recipient for notices name" isRequired>
          <ControllerInput
            render={TextInput}
            name="recipientNotice.fullName"
            placeholder={translate("MODULES.CLARA.TYPE_HERE_HIT")}
          />
        </TemplateLabel>
        <TemplateLabel label="Recipient for notices email" isRequired>
          <ControllerInput
            render={TextInput}
            name="recipientNotice.email"
            placeholder={translate("MODULES.CLARA.TYPE_HERE_HIT")}
          />
        </TemplateLabel>
        <AddressInput
          name="recipientNotice.address"
          label={{ country: translate("MODULES.CLARA.RECIPIENT_ADDRESS") }}
          stateOptional
          zipCodeOptional
          isRequired
        />
        <div>&nbsp;</div>
      </TemplateWizard>
    </Form>
  );
}

export default ReviewCorporateStakeholderAddressStep;



import React, { useEffect } from "react";
import useNavigation from "src/modules/generic/hooks/useNavigation";
import { Constants } from "src/v1/utils";
import event from "src/v1/utils/event";
import MyTasksViewMf from "./MyTasksViewMf";
import useFunctionsTask from "./hooks/useFunctionsTask";

const MyTasksView = (props) => {
  const { goToMF } = useNavigation();
  
  
  const [open, setOpen] = React.useState(false);
  const [idMf, setIdMf] = React.useState(null);
  const [mfComponent, setMfComponent] = React.useState(null);
  const [dataModal, setDataModal] = React.useState({});

  const openPublish = (value, id, mfComponent) => {
    setOpen(false);
    setIdMf(id);
    setMfComponent(mfComponent);
    setDataModal(value);
    setOpen(true);
  }

  const taskActions = useFunctionsTask({openPublish});
  
  useEffect(() => {
    return () => {
      event.remove(Constants.EVENTS.REFRESH_MY_TASKS, () => {});
    };  
  }, []);

  return (
    <React.Fragment>
      <MyTasksViewMf goToMF={goToMF} taskActions={taskActions} open={open} setOpen={setOpen} dataModal={dataModal} idMf={idMf} mfComponent={mfComponent}/>
    </React.Fragment>
  );
};

export default MyTasksView;

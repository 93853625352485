import React, { useEffect, useMemo, useState } from "react";
import { Box, Divider } from "@mui/material";
import PropTypes from "prop-types";
import _ from "lodash";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import EmptyState from "src/modules/generic/components/EmptyState";
import useBusinessRules from "src/modules/clara/hooks/useBusinessRules";
import FitAndProperPanel from "./ADGM/FitAndProperPanel";
import AddressesPanel from "./AddressesPanel";
import GeneralDetailsPanel from "./GeneralDetailsPanel";
import classes from "./DetailsTab.module.scss";

function DetailsTab({ data, lock, isManagedByClara, jurisdictions }) {
  const { translate } = useTranslate();
  const [isEmptyState, setIsEmptyState] = useState(true);
  const { isViewer } = useBusinessRules();

  useEffect(() => {
    if (!_.isEmpty(data)) {
      getRightData();
    }
  }, [JSON.stringify(data)]);

  const getRightData = () => {
    let newData = [];
    let result = true;
    newData.push(_.get(data, "profession"));
    newData.push(_.get(data, "nationality.name"));
    newData.push(_.get(data, "email"));
    newData.push(_.get(data, "phoneNumber"));
    newData.push(_.get(data, "startDate"));
    newData.push(_.get(data, "linkedinURL"));
    newData.push(_.get(data, "curriculumVitae.name"));
    newData.push(_.get(data, "passportNumber"));
    newData.push(_.get(data, "dateOfBirth"));
    _.forEach(newData, (rightData) => {
      if (!_.isEmpty(rightData)) {
        result = false;
      }
    });
    if (_.get(data, "enterType") === "E_GATE") {
      result = false;
    }
    setIsEmptyState(result);
  };

  const showFitAndProper = useMemo(() => {
    let result = false;

    const directors = _.filter(data?.roles, (stakeHolder) => {
      return stakeHolder?.name === "DIRECTOR";
    });

    const isAuthorisedSignatory = data?.isAuthorizedSignatory ?? false;

    if (directors.length > 0 || isAuthorisedSignatory) {
      result = true;
    }

    return result;
  }, [data]);

  return (
    <>
      {isViewer() ? (
        <EmptyState
          title={translate("DETAILS_FORVIDEN_STATE_VIEWER")}
          iconType="Not-Visible"
        />
      ) : (
        <Box sx={{ paddingRight: "0.4615rem" }}>
          <GeneralDetailsPanel
            data={data}
            lock={lock}
            jurisdictions={jurisdictions}
            isManagedByClara={isManagedByClara}
            isEmptyState={isEmptyState}
          />
          <Divider className={classes.divider} />
          <AddressesPanel
            data={data}
            lock={lock}
            isManagedByClara={isManagedByClara}
            jurisdictions={jurisdictions}
          />
          {_.includes(jurisdictions, "ADGM") && (
            <>
              <Divider className={classes.divider} />
              {showFitAndProper && (
                <>
                  <FitAndProperPanel data={data} lock={lock} />
                </>
              )}
            </>
          )}
        </Box>
      )}
    </>
  );
}

DetailsTab.propTypes = {
  data: PropTypes.any,
  lock: PropTypes.bool,
  isManagedByClara: PropTypes.bool,
  jurisdictions: PropTypes.array,
};

export default DetailsTab;

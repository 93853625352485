import { Delete,Connections,StakeConnectManagedByClara } from "../steps";
import { TemplateSkeletonDelete } from "src/modules/generic/templates/Modal/TemplateDelete";
const useSteps = () => {
  return {
    DeleteVerification: {
      render: TemplateSkeletonDelete,
    },
    GetConnections: {
      render: TemplateSkeletonDelete,
    },
    InvalidateStakeholder: {
      render: TemplateSkeletonDelete,
    },
    DeleteStakeholder: {
      render: TemplateSkeletonDelete,
    },
    Delete: {
      render: Delete,
    },
    Connections: {
      render: Connections,
    },
    ManagedByClara: {
      render: StakeConnectManagedByClara,
    },
  };
}

export default useSteps;
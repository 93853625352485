import React, {Component} from 'react';
import {Field} from "formik";
import _, { values } from "lodash";
import moment from "moment";
import ClaraSelect from "../../../../../components/Form/ClaraSelect/ClaraSelect";
import classes from "../../../SteperForm.module.scss";
import {TextAreaInput, TextInput} from '../../../../../components';
import FormikHelper from "../../../../../utils/formikHelper";
import DinamicInput from "../../../Inputs/DinamicInput";
import ClaraUpload from "../../../../../components/Form/ClaraUpload/ClaraUpload";
import ClaraCountrySelector from "../../../../../components/Form/ClaraCountrySelector/ClaraCountrySelector";
import AuthorisedSignatories from "../../../../../../components/inputs/AuthorizedSignatories/AuthorizedSignatories";
import SelectStateCity from 'src/components/inputs/Select/SelectStateCity'
import Checkbox from '../../../Inputs/Checkbox'

// USA Non disclosure agreement: Generate

class UsaNDADoc extends Component {

    constructor(props) {
        super(props);
        this.state = {
            typeSelected: undefined,
            assignorSelected: undefined,
            groupCompanySelected: undefined,
            authorizedSelected: undefined,
            authorizedSignatories: [],
            authorizedSignatoriesList: [],
            documentTypes: [],
            documentTypesList: [],
            dynamicParams: [],
            date: undefined,
            expirationDate: undefined,
            firstSetCity:true,
            stateValue:null

        }
    };

    componentWillMount() {
        if (this.props.values) {
            const values = this.props.values.values ? this.props.values.values : this.props.values;
            const expirationDate = this.getDateForPickerFromData(values.expirationDate);
            this.setState({
                typeSelected: values.documentType,
                assignorSelected: values.assignor,
                groupCompanySelected: values.company,
                expirationDate,
                authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.company).map(as => ({
                    id: as.id,
                    label: as.label
                }))
            });


        }

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.values) {
            const values = nextProps.values.values ? nextProps.values.values : nextProps.values;
            const expirationDate = this.getDateForPickerFromData(values.expirationDate);
            this.setState({
                expirationDate,
                authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.company)
                    .map(as => ({
                        id: as.id,
                        label: as.label
                    }))
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.values) {
            const values = prevProps.values.values ? prevProps.values.values : prevProps.values;
        }
    }

    selectType = (documentType, extraParams, form) => {
        this.setState({
            typeSelected: documentType,
            dynamicParams: this.getDynamicParamsByType(documentType)
        }, () => {
            FormikHelper.setValueInTheCorrectPosition('terms', form, []);
            FormikHelper.setValueInTheCorrectPosition('documentType', form, documentType);
            this.state.dynamicParams.forEach((dynamicParam, i) => {
                FormikHelper.setValueInTheCorrectPosition(
                    `terms[${i}].key`,
                    form,
                    dynamicParam.key || 'NO_KEY'
                );
            })
        });
    };

    getDynamicParamsByType(documentType) {
        const type = this.props.documentTypes.filter(agrType => agrType.code === documentType)[0] || {};
        return type.fields || [];
    }

    getGroupCompaniesToAssignee = () => {
        let authorizedSignatories = this.props.authorizedSignatories;
        let groupCompanies = this.props.groupCompanies;
        let companies = [];
        if (groupCompanies && authorizedSignatories) {
            companies = _.filter(groupCompanies, (groupCompany) => {
                let fined = _.filter(authorizedSignatories, (authorized) => {
                    if (groupCompany.authorizedSignatories) {
                        return groupCompany.authorizedSignatories.some(au => au.stakeholder.id === authorized.id);
                    }
                    return false;
                });
                if (fined) {
                    return true;
                }
                return false;
            });
        }
        return companies;
    };

    selectGroupCompany = (gcId, extraParams, form) => {
        const groupCompany = this.findGroupCompany(gcId);

        // this is for a weird behaviour when hit edit button after preview a document
        if (this.state.groupCompanySelected && groupCompany) {
            return null;
        }

        this.setState({
            groupCompanySelected: groupCompany,
            stateValue:null
        });

        if (groupCompany) {

            if (!groupCompany.registeredAddress) {
                _.set(groupCompany, "registerAddress", {})
            }

            let authorizedSignatories = _.get(form, 'values.company.authorizedSignatories', []);

			form.setValues({
				...form.values,
				company: {
					id: gcId,
					name: groupCompany.name || '',
					country: _.get(groupCompany, 'country.code') || _.get(groupCompany, 'country', null),
                    placeOfBusiness:groupCompany.placeOfBusiness
                }
            });
            this.setState({
                groupCompanySelected: {...groupCompany},
            })
        } else {
            form.setValues({
                ...form.values,
                company: {}
            });
        }
    };

    findGroupCompany(gcId) {
        const gc = _.find(this.props.groupCompaniesList,{id:gcId});
        return gc?{...gc}:null
    };

    selectStakeholder = (assignor, extraParams, form, cb) => {
        
        const stakeholder = this.findStakeholder(assignor);
        this.setState({
            assignorSelected: stakeholder,
            firstSetCity:true
        });

        if (stakeholder) {

            if (!stakeholder.address) {
                _.set(stakeholder,'address', {})
            }

            const _stakeholder = !_.isEmpty(form.values.stakeholder) ? form.values.stakeholder : stakeholder;
            form.setValues({
                ...form.values,
                
                stakeholder: {
                    id: assignor,
                    fullName: stakeholder.fullName || '',
                    email: stakeholder.email || '',
                    nationality: _.get(stakeholder, 'nationality.code', null) || _.get(stakeholder, 'nationality', null),
                    title: stakeholder.title || '',
                    type: stakeholder.type || '',
                    address: {
                        ...stakeholder.address,
                        country: _.get(_stakeholder, 'address.country.code', null) || _.get(_stakeholder, 'address.country', null)
                    },
                    isAnEntity: stakeholder.isAnEntity,
                    },
               
            });
        } else {
            form.setValues({
                ...form.values,
                stakeholder: {}
            });
        }
        
    };

    findStakeholder(roleId) {
        return this.props.stakeholderList.find(stakeholder => stakeholder.id === roleId)
    };

    findStakeholderAuthorized(roleId) {
        return this.props.stakeholderList.filter(stakeholder => {
            return stakeholder.roles.some(role => role.stakeholder.id === roleId);
        })[0] || null;
    };

    filterAuthorizedByGroupCompany(groupCompany) {
        if (groupCompany) {
            let gc = this.findGroupCompany(groupCompany.id);
            return this.props.authorizedSignatories.filter(authorized => {
                return authorized.groupCompanyId === gc.id
            });
        }
        return [];
    }

    // selectAuthorized = (value, form, field, replace) => {
    //     // const stakeholder = this.findStakeholder(value);
    //     const stakeholder = this.props.authorizedSignatories.find(e => e.id === value);
    //     const email = stakeholder ? stakeholder.email : '';
    //     replace(0, { id: value, email })
    // };

    handleDateChange = (e, field, form) => {
        const date = moment(e).format("YYYY-MM-DD");

        FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

        this.setState({
            date: e
        })
    };

    renderSpecialAgreementFields(form) {
        return this.state.dynamicParams.map((dynamicParam, i) => {
            return (
                <div className={classes.ContainerRow}>
                    <Field name={`terms[${i}].value`}>
                        {({ field, form }) => (
                            <DinamicInput
                                config={dynamicParam}
                                field={field}
                                form={form}
                                handleDateChange={this.handleDateChange}
                                date={this.state.date}
                            />
                        )}
                    </Field>
                </div>
            );
        });
    };


    handleCountryChange(value, form) {


        this.setState({
            countrySelected: value
        })

        if(value != 'US'){
            form.setValues({
                ...form.values,
                company: {
                    ...form.values.company,
                    country: value,
                    placeOfBusiness:{
                        ...form.values.company.placeOfBusiness,
                        state: 'Delaware',
                        // city: null
                    }
                }
            });    
        }
    }

    updateEmailValue(e, form) {
        if (this.state.assignorSelected.isAnEntity) {
            form.setFieldValue('assignor.authorizedSignatory.email', e.target.value, false)
        }
    }

    getDateForPickerFromData(date) {
        if (!date) return undefined;
        return moment(date).toDate();
    }

    handleChange(e, field, form) {

        const date = e === null ? null : moment(e).format("YYYY-MM-DD");

        FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

        this.setState({
            [field.name]: e
        })
    }

    getAuthorizedSignatoriesList = (index, form) => {
        if (form.values && form.values.assignee) {
            return this.filterAuthorizedByGroupCompany(form.values.assignee).map(as => ({
                id: as.id,
                label: as.label
            }))
        } else {
            return [];
        }
    };

    getLabel = (key) => {
        switch (key) {
            case "fullName":
                if (this.state.assignorSelected.isAnEntity) {
                    return "Company name";
                } else {
                    return "Name";
                }
            case "country":
                if (this.state.assignorSelected.isAnEntity) {
                    return "Country of incorporation";
                } else {
                    return "Nationality";
                }
            case "address":
                if (this.state.assignorSelected.isAnEntity) {
                    return "Registered address";
                } else {
                    return "Residential address";
                }
            case "passportOrRegistered":
                if (this.state.assignorSelected.isAnEntity) {
                    return "Company number";
                } else {
                    return "Passport number";
                }
        }
    };

    getField = (key) => {
        switch (key) {
            case "passportOrRegistered":
                if (this.state.assignorSelected.isAnEntity) {
                    return "assignor.registeredNumber";
                } else {
                    return "assignor.passportNumber";
                }
        }
    };

    getTooltip = (key, fieldName) => {
        switch (key) {
            case "fullName":
                if (this.state.assignorSelected.isAnEntity) {
                    return "Company Name";
                } else {
                    return "IP Assignor's Name";
                }
            case "passportOrRegistered":
                if (this.state.assignorSelected.isAnEntity) {
                    return "Company Number";
                } else {
                    return "Passport Number";
                }
            case "country":
                if (this.state.assignorSelected.isAnEntity) {
                    return "Country";
                } else {
                    return "Nationality";
                }
            case "addressLine1":
                if (this.state.assignorSelected.isAnEntity) {
                    return "Registered Address - Address Line 1";
                } else {
                    return "Residential Address - Address Line 1";
                }
            case "addressCity":
                if (this.state.assignorSelected.isAnEntity) {
                    return "Registered Address - City";
                } else {
                    return "Residential Address - City";
                }
            case "addressState":
                if (this.state.assignorSelected.isAnEntity) {
                    return "Registered Address - State";
                } else {
                    return "Residential Address - State";
                }
            case "addressCountry":
                if (this.state.assignorSelected.isAnEntity) {
                    return "Registered Address - Country";
                } else {
                    return "Residential Address - Country";
                }
            case "addressPostCode":
                if (this.state.assignorSelected.isAnEntity) {
                    return "Registered Address - Post Code";
                } else {
                    return "Residential Address - Post Code";
                }
            case "recipientNotice":
                if (fieldName === 'assignee') {
                    return "IP Assignee's";
                } else {
                    return "IP Assignor's";
                }
        }
    };

    getPlaceholder = (key) => {
        switch (key) {
            case "passportOrRegistered":
                if (this.state.assignorSelected.isAnEntity) {
                    return "Company number";
                } else {
                    return "Passport number";
                }
        }
    };

    getStakeholderAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add new corporate stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
                placeholder: "Stakeholder's name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: true,
                },
                paramsReturn: 'isAnEntity'
            },
            {
                type: "stakeholder",
                label: "Add new individual stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
                placeholder: "Stakeholder's name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: false,
                },
                paramsReturn: 'isAnEntity'
            },
        ]
    };

    getCompanyAdds = () => {
        return [{
            type: "groupCompany",
            label: "Add new company",
            textWarning: "This will create a new group company for your startup. You can add or amend the details of this group company on its profile page.",
            placeholder: "Company name",
            startupId: this.props.startup.id,
            params: null,
            paramsReturn: `
				name
				authorizedSignatories {
					id
					stakeholder {
						id
						email
						fullName
						roles {   id
        name
        groupCompany { id name}
        stakeholder { id } }
					}
				}
			`
        }]
    };

    getAuthorizedSignatoryAdds = () => {
        return [
            {
                type: "stakeholder",
                label: "Add new individual stakeholder",
                textWarning: "This will create a new stakeholder for your startup. You can add or amend the details of this stakeholder on their profile page.",
                placeholder: "Enter stakeholder's name",
                startupId: this.props.startup.id,
                textToSave: "fullName",
                params: {
                    isAnEntity: false,
                    roles: [{
                        role: "DIRECTOR",
                        groupCompany: this.state.groupCompanySelected.id,
                    }]
                }
            },
        ]
    };

    handleStakeholderCreate = (value, name, additionalValue, form) => {

        let { stakeholders, stakeholderList } = this.props;
        let newSh = { id: value.id, fullName: name, ...additionalValue };
        stakeholderList.push(newSh);

        stakeholders.push({ id: value.id, label: name });

    };

    handleGroupCompanyCreate = (value, name, additionalValue, form) => {

        let { groupCompanies, groupCompaniesList } = this.props;

        let newGc = { id: value.id, name, ...additionalValue };
        groupCompaniesList.push(newGc);

        groupCompanies.push({ id: value.id, label: name });

    };

    handleAuthorizedSignatoryCreate = (value, name, additionalValue, form) => {

        let { authorizedSignatories } = this.props;
        const { groupCompanySelected } = this.state;

        authorizedSignatories.push({
            id: value.id,
            label: name,
            role: 'DIRECTOR',
            groupCompanyId: groupCompanySelected.id
        });

    };

    handleAuthorizedSignatoryUpdate = (value) => {
        const { authorizedSignatories, groupCompanySelected } = this.state;
        const groupCompany = this.findGroupCompany(groupCompanySelected.id);
       _.set(groupCompany,'authorizedSignatories',_.map(groupCompany.authorizedSignatories,auth=>({...auth})))
        groupCompany.authorizedSignatories.push({ stakeholder: value });
        authorizedSignatories.push(value);
    };


    handleChangeState(value, form){
        form.setValues({
            ...form.values,
            company: {
                ...form.values.company,
                placeOfBusiness:{
                    ...form.values.company.placeOfBusiness,
                    state: value,
                    city:null
                }
            }
        });    
    }

    handleChangeCity(value, form){
        form.setValues({
            ...form.values,
            company: {
                ...form.values.company,
                placeOfBusiness:{
                    ...form.values.company.placeOfBusiness,
                    city: value
                }
            }
        });    
    }


    handleChange(a,b,c,d){
        alert(a)
        
    }

    render() {
        const isRequired = message => value => (!!value ? undefined : message);
        const { tooltips = {}, formType } = this.props;

        return (
            <div>

                
                <div className={classes.ContainerRow}>
                    {formType === 'uploadToEdit' ? (<div>
                        <Field name={`file`}>
                            {({ field, form }) => (
                                <ClaraUpload
                                    required={true}
                                    name={`filepond`}
                                    label='Upload your document '
                                    field={field}
                                    form={form}
                                />
                            )}
                        </Field>
                    </div>) : null}
                    <Field name='agreementName'>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='text'
                                label='Document name '
                                field={field}
                                form={form}
                                placeholder={"Document name"}
                                tooltip={tooltips['Document Name']}
                            />
                        )}
                    </Field>
                </div>
                <hr className={classes.Separator} />
               
                <div className={`${classes.ContainerRow} ${classes.Select}`}>
                    <Field name='company.id'>
                        {({ field, form }) => (
                            <ClaraSelect
                                required={true}
                                field={field}
                                form={form}
                                label={"Select company "}
                                adds={this.getCompanyAdds()}
                                lists={this.getGroupCompaniesToAssignee()}
                                mode={"classic"}
                                callbacks={{
                                    createValues: this.handleGroupCompanyCreate,
                                    updateValues: this.selectGroupCompany
                                }}
                                placeholder={"Select from the list"}
                                tooltip={tooltips['Select Company']}
                            />
                        )}
                    </Field>
                </div>
                {
                    this.state.groupCompanySelected ?
                        <React.Fragment>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Company details</h1>
                                <p>Please verify or update the company's information below</p>
                            </div>
                            <div className={classes.ContainerRow}>
                                <Field name='company.name'>
                                    {({ field, form }) => (
                                        <TextInput

                                            required={true}
                                            type='text'
                                            label='Company name '
                                            field={field}
                                            form={form}
                                            placeholder={"Company name"}
                                            tooltip={tooltips["Company's Name"]}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={`${classes.ContainerRow} ${classes.Select}`}>
                                <Field name='company.country'>
                                    {({ field, form }) => (
                                        <ClaraCountrySelector
                                            isRequired={{ country: true, jurisdiction: false, otherJurisdiction: false }}
                                            jurisdictionsEnabled={false}
                                            countryField={'company.country'}
                                            label={'Country of incorporation '}
                                            returnCountryAsString={true}
                                            setFieldValue={form.setFieldValue}
                                            additionalStyle={'ClaraCountrySelectorWizard'}
                                            tooltips={{
                                                country: tooltips["Country of incorporation"],
                                            }}
                                        />
                                    )}
                                </Field>
                                
                            </div>
                            <>
                            {/* {this.state.countrySelected === 'US' ? */}
                                    <Field name='company.placeOfBusiness'>
                                        {({ field, form }) => {                                            
                                            return (
                                                <SelectStateCity
                                                    selectStateLabel={'State of principal office'}
                                                    selectCityLabel={'City of principal office'}
                                                    showState={true}
                                                    defaultState={form?.values?.company?.placeOfBusiness?.state}
                                                    tooltips={{city:tooltips["Company's City Of Principal Office"],state:tooltips["Company's State Of Principal Office"]}}
                                                    defaultCity={form?.values?.company?.placeOfBusiness?.city}
                                                    onChangeState={(value) => {
                                                        form.setFieldValue('company.placeOfBusiness.state', value)
                                                    }}
                                                    onChangeCity={(value) => {
                                                        form.setFieldValue('company.placeOfBusiness.city', value)
                                                    }}
                                                    errorMessage={_.get(form.errors, "company.placeOfBusiness.city") || _.get(form.errors, "company.placeOfBusiness.state") || _.get(form.errors, "company.placeOfBusiness")}
                                                />
                                            )}
                                        }
                                    </Field>                                
                                {/* : */}
                                {/* <div className={`${classes.ContainerRow} ${classes.Select}`}> */}
                                    {/* <Field name='company.placeOfBusiness'> */}
                                        {/* {({ field, form }) => {
                                        
                                        return(
                                        <>
                                            <TextInput
                                                required={true}
                                                type='text'
                                                label='State of principal office'
                                                // field={field.value.state}
                                                form={form}
                                                value="Delaware"
                                                disabled={true}
                                                placeholder={"Delaware"}
                                            />
                                        </>

                                        )}} */}
                                    {/* </Field>                                 */}
                                {/* </div>
                            } */}
                            </>
                            

                            <hr className={classes.Separator}></hr>
                            <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                                <h1>Corporate Signatory</h1>
                                <p>Please add the corporate signatory required to sign this document on behalf of the company.</p>
                            </div>
                            <Field name='company.authorizedSignatories'>
                                {({ field, form }) => (
                                    <React.Fragment>
                                        <span hasError={_.get(form.errors, "company.authorizedSignatories") ? "true" : "false"} >
                                            <AuthorisedSignatories
                                                showTitle={true}
                                                showRegisteredAddress={true}
                                                list={this.props.stakeholderList}
                                                limit={1}
                                                fieldArrayName={'company.authorizedSignatories'}
                                                handleAddListStakeholder={this.handleAuthorizedSignatoryUpdate}
                                                idGroupCompany={this.state.groupCompanySelected.id}
                                                errorMessage={_.get(form.errors, "company.authorizedSignatories")}
                                                isSingleSignatory={true}
                                               />
                                        </span>
                                    </React.Fragment>
                                )}

                           
                        </Field>
                        </React.Fragment>
                        :
                        null
                }
                
                <hr className={classes.Separator}></hr>

                 <React.Fragment>
                    <Field name='stakeholder.id'>
                        {({ field, form }) => (
                            <div className={classes.ContainerRow}>

                                <ClaraSelect
                                    required={true}
                                    field={field}
                                    form={form}
                                    label={"Other Party"}
                                    adds={this.getStakeholderAdds()}
                                    lists={this.props.stakeholders}
                                    mode={"classic"}
                                    callbacks={{
                                        updateValues: this.selectStakeholder,
                                        createValues: this.handleStakeholderCreate,
                                    }}
                                    placeholder={"Select from the list"}
                                    tooltip={tooltips["Other Party Select"]}
                                />
                            </div>
                        )}
                    </Field>
                    { this.state.assignorSelected && <>
                    <div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
                        <h1>Other Party details</h1>
                        <p>Please verify or update the consultant's information below</p>
                    </div>
                  
                    <Field name='stakeholder.fullName'>
                        {({ field, form }) => (
                            
                            <TextInput
                                required={true}
                                type='text'
                                label='Name'
                                field={field}
                                form={form}
                                placeholder={"Name"}
                                tooltip={tooltips["Other Party's Name"]}
                            />
                        )}
                    </Field>

                    <Field name='stakeholder.email'>
                        {({ field, form }) => (
                            <TextInput
                                required={true}
                                type='email'
                                label='Email address'
                                field={field}
                                form={form}
                                placeholder={"Email address"}
                                tooltip={tooltips["Other Party's Email Address"]}
                            />
                        )}
                    </Field>
                    <div className={classes.ContainerRow}>
                        <Field name='stakeholder.title'>
                            {({ field, form }) => (
                                <TextInput
                                    required={true}
                                    type='text'
                                    label='Title'
                                    field={field}
                                    form={form}
                                    placeholder={"Title"}
                                    tooltip={tooltips["Other Party's Title"]}
                                />
                            )}
                        </Field>
                    </div> 
                    <div className={`${classes.ContainerRow} ${classes.Select}`}>
                        <Field name='stakeholder.address.country'>
                            {({field, form}) =>{
                                return (
                                    <ClaraCountrySelector
                                        isRequired={{ country: true }}
                                        jurisdictionsEnabled={false}
                                        countryField={'stakeholder.address.country'}
                                        label={'Address '}
                                        returnCountryAsString={true}
                                        setFieldValue={form.setFieldValue}
                                        additionalStyle={'ClaraCountrySelectorWizard'}
                                        tooltips={{
                                            country: tooltips["Other Party's Country"]
                                        }}
                                    />
                                )
                            }}
                        </Field>
                    </div>

                    <div className={classes.ContainerRow}>
                        <Field name='stakeholder.address.street'>
                            {({ field, form }) => (
                                <TextAreaInput
                                    type='text'
                                    label={' '}
                                    field={field}
                                    form={form}
                                    placeholder={"Address"}
                                    tooltip={tooltips["Other Party's Address"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name='stakeholder.address.city'>
                            {({ field, form }) => (
                                <TextInput
                                    type='text'
                                    label=' '
                                    field={field}
                                    form={form}
                                    placeholder={'City'}
                                    tooltip={tooltips["Other Party's City"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name='stakeholder.address.state'>
                            {({ field, form }) => (
                                <TextInput
                                    type='text'
                                    label=' '
                                    field={field}
                                    form={form}
                                    placeholder={'State / Region'}
                                    tooltip={tooltips["Other Party's State"]}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classes.ContainerRow}>
                        <Field name='stakeholder.address.zipCode'>
                            {({ field, form }) => (
                                <TextInput
                                    type='text'
                                    label=' '
                                    field={field}
                                    form={form}
                                    placeholder={"Post code"}
                                    tooltip={tooltips["Other Party's Postal Code"]}
                                />
                            )}
                        </Field>
                    </div>
            </>}
            {formType === 'uploadToEdit' ? <div>
                    <Field name='confirmSameData'>
                        {({ field, form }) => (
                            <Checkbox
                                className={classes.sameDataCheckbox}
                                field={field}
                                validate={isRequired('This field is required')}
                                form={form}
                                required={true}
                                label='All document details are verified and correct.'
                            />
                        )}
                    </Field>
                </div> : null}
             </React.Fragment>
            </div>

        );
    }

}

export default UsaNDADoc;

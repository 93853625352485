import { inspect } from '@xstate/inspect'
import _ from 'lodash'
import React, { FC } from 'react'
import useCustomMachine, {
  GlobalStateProvider
} from '../../../generic/hooks/useCustomMachine';
import {LoadingProvider} from "../../../../modules/generic/hooks/useLoading";
export interface Actions {
  key: string
  action: ()=>void
}
export interface WizardComponentProps<Values = any> {
  machine: any
  initialValues?: Values
  onComplete: (e?: any) => void
  onClose?: (e?: any) => void
  actions?: Array<Actions> 
}

const Wizard: FC<WizardComponentProps> = ({ machine: MachineClass, initialValues, onComplete,onClose,actions }: WizardComponentProps) => {
  React.useEffect(() => {
    if (_.get(window, 'env.devTools.xstate', false)) {
      inspect({ iframe: false });
    }
  }, [])

  const handleCompleteWizard = (context) => {
    onComplete && onComplete(context);
  }
  const handleCloseWizard = (context) => {
    onClose && onClose(context);
  }
 
  const { machine, machineClassInstance } = React.useMemo(() => {
    const machineClassInstance = new MachineClass();
    machineClassInstance.generateMachine(initialValues, handleCompleteWizard,handleCloseWizard,actions);
    return { machine: machineClassInstance.getMachine(), machineClassInstance }
  }, [JSON.stringify(initialValues)]);

  return (
    <>
      <LoadingProvider  keyValue={undefined}
      >
        <GlobalStateProvider {...{ machine }}>
          <RenderStep machine={machineClassInstance} />
        </GlobalStateProvider>
      </LoadingProvider>
    </>
  )
}

export default Wizard

const RenderStep = ({ machine }) => {
  const { state } = useCustomMachine();
  // console.log("**** State",state.value, {context: state?.context?.data ?? {}})
  // console.log("**** element",machine.getStep(state.value))
  return React.createElement(machine.getStep(state.value))
}

import * as Yup from "yup";
import React, { useEffect, useMemo } from "react";
import { addAssistantText, clearAssistatText } from "src/modules/generic/store/action";
import Button from "src/modules/generic/components/Buttons/Button";
import Form from "src/components/forms/Form/Form";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import { useDispatch } from "react-redux";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import TemplateLabel from 'src/modules/generic/components/Inputs/LabelInput';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import CountrySelector from "src/components/inputs/Select/CountrySelector/CountrySelector";
import TextInput from "src/components/inputs/Text/TextInput";
import CalendarInputDate from "src/components/inputs/Calendars/CalendarInputDate";
import PhoneInput from "src/modules/generic/components/Inputs/PhoneInput";
import { Constants } from "src/v1/utils";
import _ from "lodash";

function AddMoreDetails() {
  const { next, prev, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    let formattedValues = _.cloneDeep(context?.initialData?.stakeholder);

    if (formattedValues?.birthAddress?.country?.code) {
      formattedValues.countryOfBirth = formattedValues?.birthAddress?.country?.code;
    } else {
      delete formattedValues.countryOfBirth;
    }

    if (formattedValues?.birthAddress?.city) {
      formattedValues.cityOfBirth = formattedValues?.birthAddress?.city;
    } else {
      delete formattedValues.cityOfBirth;
    }

    return context?.AddMoreDetails ?? formattedValues ?? {};
  }, [context]);

  const handlePrev = () => {
    prev();
  }

  const handleCancel = () => {
    cancel();
  };

  const handleSubmit = async (values) => {
    next(values);
  };

  useEffect(() => {
    dispatch(addAssistantText("Some stakeholders have an additional profession which forms part of their role within the company. If this is the case, add it here. If not, just add their role e.g. ‘director’."));
    dispatch(addAssistantText("Provide your profession or economic activities, regardless of your anticipated role in this venture."));

    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const schema = Yup.object().shape({
    dateOfBirth: Yup.date().required("This field is required").typeError("Invalid date").nullable(),
    countryOfBirth: Yup.string().required("This field is required").typeError("This field is required").nullable(),
    cityOfBirth: Yup.string().required("This field is required").nullable(),
    profession: Yup.string().nullable().required("This field is required"),
    email: Yup.string().required("This field is required")
      .matches(Constants.REGEXP.EMAIL, {
        message: "Invalid Email",
        excludeEmptyString: true,
      })
      .typeError("This field must be a valid email"),
    phoneNumber: Yup.string().nullable().required("This field is required").typeError("This field is required"),
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: "Now for a few more details...",
          subTitle: "Personal information"
        }}
        buttons={{
          previous: (
            <Button onClick={handlePrev} variant="secondary">
              {translate("MODULES.CLARA.BTN_PREVIOUS")}
            </Button>
          ),
          submit: (
            <SubmitButton>
              {translate("MODULES.CLARA.BTN_ADD")}
            </SubmitButton>
          ),
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("MODULES.CLARA.BTN_CANCEL")}
            </Button>
          ),
        }}
      >
        <TemplateLabel label="Email" isRequired>
          <ControllerInput
            render={TextInput}
            name="email"
            type="email"
            placeholder="name@example.com"
          />
        </TemplateLabel>
        <TemplateLabel label="Telephone number" isRequired>
          <ControllerInput
            render={PhoneInput}
            disableDropdown={false}
            name="phoneNumber"
            placeholder="Include numbers"
          />
        </TemplateLabel>
        <TemplateLabel label="Date of birth" isRequired>
          <ControllerInput
            render={CalendarInputDate}
            name="dateOfBirth"
            placeholder="dd/mm/yyyy"
          />
        </TemplateLabel>
        <TemplateLabel label="Country of birth" isRequired>
          <ControllerInput
            placeholder="Select country"
            render={CountrySelector}
            name='countryOfBirth'
          />
        </TemplateLabel>
        <TemplateLabel label="City of birth" isRequired>
          <ControllerInput
            render={TextInput}
            name="cityOfBirth"
            placeholder="Type here"
          />
        </TemplateLabel>
        <TemplateLabel label={translate("MODULES.CLARA.FORM_PROFESSION")} isRequired>
          <ControllerInput
            render={TextInput}
            name="profession"
            placeholder={translate("MODULES.CLARA.EXAMPLE_PROFESSION")}
          />
        </TemplateLabel>
        <div>&nbsp;</div>
      </TemplateWizard>
    </Form>
  );
}

export default AddMoreDetails;
import React from 'react';
import useTextLang from "../../../hooks/custom/useTextLang";
import Parser from "html-react-parser";
import PropTypes from 'prop-types'
/*
    Properties:
        uuid: key to get Text
        params: params to parse data
        lang: lang to use (not enabled now)
*/
const Text = ({ uuid, params, defaultKey }) => {
    const text = useTextLang(uuid, params, defaultKey);
    return (
        <React.Fragment>
            {text && Parser(text)}
        </React.Fragment>
    );
}



export default Text;

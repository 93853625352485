import _ from 'lodash';
import React from 'react';
import { useDispatch } from "react-redux";
import * as Yup from 'yup';
import useTextLang from "../../../../../../../hooks/custom/useTextLang";
import useTerminateDocument from "../../../../../../../hooks/services/modules/Document/useTerminateDocument";
import ControllerInput from '../../../../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useSession from '../../../../../../../modules/session/hooks/useSession';
import { Modal } from '../../../../../../../v1/components/index';
import { RowAdd } from '../../../../../../forms/contents';
import Form from '../../../../../../forms/Form/Form';
import SecondaryButton from '../../../../../../generic/Buttons/SecondaryButton/SecondaryButton';
import SubmitButton from '../../../../../../generic/Buttons/SubmitButton/SubmitButton';
import Checkbox from '../../../../../../inputs/Checkbox/Checkbox';
import TextareaInput from '../../../../../../inputs/Textarea/TextareaInput';
import NewModalForm from '../../../../../../templates/NewModalForm/NewModalForm';
import Text from '../../../../../../text/Text/Text';
import Title from '../../../../../../text/Title/Title';
import classes from './Terminate.module.scss';

const TerminateGrant = ({refresh, handleCloseModal, data, open, ...props }) => {
    
    const { startupId } = useSession();
    const dispatch = useDispatch();
    const {mutation: terminateDocument, loading} = useTerminateDocument({ successMessage: "NOTIFICATION_SUCCESS_TERMINATED", paramsSuccessMessage: data });

    const terminateEntity =async (values) => {
        await terminateDocument({
            startupId,
            agreementId: data.id,
            reason:values.reason
        })
		handleCloseModal();
		refresh &&  refresh()
    }

    return (
        <Modal open={open} overlayName={'Terminate'} handleClose={() => handleCloseModal()} showHeader={false}>
        <Form initialValues={{confirmTerminate: false}} schema={
            Yup.object().shape({
                reason: Yup.string().required('Required'),
                confirmTerminate: Yup.bool()
                    .required('You must confirm this action')
                    .oneOf([true], 'You must confirm this action')
            })}
              onSubmit={terminateEntity}
        >
            {/*console.log('za:', initialValues)*/}
            <NewModalForm
                header={<Title><Text uuid={"MODAL_TERMINATE_GRANT"} params={{ grant: _.get(data, 'name') }} /></Title>}
                content={<React.Fragment>
                    <div className={classes.terminateInfo}>
                        <Text uuid={'MODAL_TERMINATE_GRANT_REASON'}  />
                    </div>
                    <div className={classes.textArea}>
                        <RowAdd
                            asterisk={true}
                            label={"Reason:"}
                            input={
                                <ControllerInput
                                    as={TextareaInput}
                                    name="reason"
                                    defaultValue={''}
                                    placeholder={"Please let us know the reason why you are terminating this Grant..."}
                                    onChange={(e) => {

                                    }}
                                />
                            } />
                    </div>

                    <div>

                        <ControllerInput
                            as={Checkbox}
                            showErrorMessage={true}
                            name={'confirmTerminate'}
                            classNameError={classes.errorCheckboxLabel}
                        />
                        <span className={classes.textCheckbox}><Text uuid={"MODAL_TERMINATE_GRANT_CHECKBOX"} params={{ grant: _.get(data ,'name') }} /></span>

                    </div>

                </React.Fragment>}
                botonera={<React.Fragment>
                    <SecondaryButton onClick={() => handleCloseModal()}><Text uuid={'BUTTON_CANCEL'} /></SecondaryButton>
                    <SubmitButton ><Text uuid={"BUTTON_TERMINATE"} labelLoading={useTextLang('BUTTON_TERMINATING')} /></SubmitButton>
                </React.Fragment>}
            />

        </Form >
        </Modal>

    )
};

export default TerminateGrant;

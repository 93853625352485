import { useEffect } from 'react';
import useLazyQuery from "../../../hooks/custom/useLazyQuery";
import _ from "lodash";
const query = `query {
 getMe {
    id
    user_hash
    email
    optionsSelected {
      value
      option
      startupId
    }
    allowedActions
    pendingActions
    hasMatters
    hasStartup
    hasEquities
    fullName
    firstName
    avatar
    register
    userRole
  }
}`;
const useGetMe = (variables,config) => {

  const { manualQuery, loading, error, data, refetch } = useLazyQuery(query, variables,config);
  
  return { manualQuery, loading, error, data: _.get(data, "getMe"), refetch };
}
export default useGetMe;
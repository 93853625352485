import _ from 'lodash'
import useQuery from "../../../custom/useQuery";
import { RoleFields } from "../../../../modules/stakeholder/graphql/typePolicies";
import {
  AdditionalAddressFragment,
  CompanyDocumentsFragment,
  CompanyProfileFragment,
  GET_COMPANY_DETAILS_QUERY,
  GET_COMPANY_DETAILS_NAME
} from "./queries";
import {FormationData} from "../../../../modules/startup/GroupCompany/graphql/fragments";
import useLazyQuery from 'src/hooks/custom/useLazyQuery';

export const GET_COMPANY_DETAILS = `
  ${CompanyProfileFragment}
  ${AdditionalAddressFragment}
  ${CompanyDocumentsFragment}
  ${RoleFields}
  ${FormationData}
  ${GET_COMPANY_DETAILS_QUERY}`;

export const GET_COMPANY_DETAILS_QUERY_NAME = `
  
  ${GET_COMPANY_DETAILS_NAME}`;

export const useCompanyDetailsName = (variables) => {
  const { loading, error, data, refetch } = useQuery(GET_COMPANY_DETAILS_QUERY_NAME, variables);
  return { loading, error, data: _.get(data, "getGroupCompany", []), refetch };
};
const useCompanyDetails = (variables) => {
  const { loading, error, data, refetch } = useQuery(GET_COMPANY_DETAILS, variables);
  return { loading, error, data: {..._.get(data, "getGroupCompany")}, refetch };
};
export const useCompanyDetailsLazy = (variables) => {
  const { manualQuery,loading,refetch,error } = useLazyQuery(GET_COMPANY_DETAILS, variables);
  return { loading, error,refetch,manualQuery };
}

export default useCompanyDetails;

import React, { FC, useRef } from "react";
import * as Yup from "yup";
import useTranslate from "../../../generic/hooks/useTranslate";
import Form from "../../../../components/forms/Form/FormL";
import ControllerInput from "../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import SubmitButton from "../../../generic/components/Buttons/SubmitButton";
import TemplateFormDefault from "../../../generic/templates/Modal";
import FormProps from "../../../generic/forms/interfaces/FormProps";
import { CheckboxButtonOptions } from "../../../generic/components/Inputs/CheckboxButton";
import CheckboxButtonSimple from "../../../generic/components/Inputs/CheckboxButtonSimple";
import { HiddenFieldForm } from "../../../../components/forms/contents";
import TemplateLabel from "../../../generic/components/Inputs/LabelInput";
import TextareaInput from "../../../../components/inputs/Textarea/TextareaInput";
import classes from "./Form.module.scss";

const schema = Yup.object().shape({
  isPEP: Yup.boolean().nullable().required("Required"),
  descriptionOfPEP: Yup.string().when(["isPEP"], {
    is: true,
    then: Yup.string().nullable().required("Required"),
    otherwise: Yup.string().optional(),
  }),
});

const IsIndividualOrCompany: FC<FormProps> = ({
  initialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
}: FormProps) => {
  const { translate } = useTranslate();

  const options: Array<CheckboxButtonOptions<boolean, string>> = [
    {
      value: false,
      label: translate("MODULES.STAKEHOLDER.FORM.IS_PEP.NO"),
    },
    {
      value: true,
      label: translate("MODULES.STAKEHOLDER.FORM.IS_PEP.YES"),
    },
  ];

  const refForm = useRef();

  return (
    <>
      <Form
        defaultValues={initialValues}
        schema={schema}
        onlySendDirty={false}
        onSubmit={onCompleteSubmit}
        ref={refForm}
        optionsForm={{ mode: "onChange" }}
      >
        {" "}
        <Template
          buttons={{
            ...buttons,
            submit: <SubmitButton {...buttonSubmitProps}>Next</SubmitButton>,
          }}
          props={propsTemplate}
        >
          <ControllerInput
            as={CheckboxButtonSimple}
            name="isPEP"
            options={options}
            className={classes.CheckboxButton}
            classNameButton={classes.buttonCheckBox}
          />
          <HiddenFieldForm
            fieldsWatch={"isPEP"}
            conditionHideCallback={(value) => value}
          >
            <div className={classes.describePEPContainer}>
              <TemplateLabel
                label={translate(
                  "MODULES.STAKEHOLDER.FORM.IS_PEP.DESCRIBE_PEP"
                )}
                className={classes.TemplateLabelColumn}
                isRequired
              >
                <ControllerInput
                  name={"descriptionOfPEP"}
                  render={TextareaInput}
                  className={classes.TextArea}
                  placeholder={translate(
                    "MODULES.STAKEHOLDER.FORM.IS_PEP.DESCRIBE_PEP_PLACEHOLDER"
                  )}
                  defaultValue={""}
                />
              </TemplateLabel>
            </div>
          </HiddenFieldForm>
        </Template>
      </Form>
    </>
  );
};

export default IsIndividualOrCompany;

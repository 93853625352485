import React from "react";
import * as Yup from "yup";
import Form from "../../../../components/forms/Form/Form";
import ControllerInput from "../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import TemplateLabel from "../../../generic/templates/Label";
import SubmitButton from "../../../generic/components/Buttons/SubmitButton";
import FormProps from "../../../generic/forms/interfaces/FormProps";
import TemplateFormDefault from "../../../generic/templates/Modal";
import cls from "./Form.module.scss";
import _ from "lodash";
import Typography from "../../../generic/components/Texts/Typography";
import useCustomMachine from "../../../generic/hooks/useCustomMachine";
import ClassNames from "classnames";
import SelectInput from "../../../../components/inputs/Select/SelectInput/SelectInput";
import useGetPositions from "../../graphql/stakeholder/query/useGetPositions";
import useGetDepartments from "../../graphql/stakeholder/query/useGetDepartments";
import { useSelector } from "react-redux";
import useSession from "../../../session/hooks/useSession";

const schema = Yup.object().shape({
  position: Yup.object().nullable(),
  department: Yup.object().nullable(),
});

const FormMoreDetails: React.FC<FormProps> = ({
  initialValues: defaultValues = {},
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
}) => {

  const handleSubmit = async (values) => {
    onCompleteSubmit && (await onCompleteSubmit(values));
  };
  const { startupId } = useSession();
  const { state } = useCustomMachine();

  const { numbersOfIteration, companies } = state.context;

  const { data: positions } = useGetPositions({
    variables: {
      startupId,
      groupCompanyId: _.get(companies, `[${numbersOfIteration}].id`, ""),
    },
  });

  const { data: departments } = useGetDepartments({
    variables: {
      startupId,
      groupCompanyId: _.get(companies, `[${numbersOfIteration}].id`, ""),
    },
  });

  const listPositions = React.useMemo(() => {
    return positions.map((pos) => ({ value: pos, label: pos.name }));
  }, [JSON.stringify(positions)]);

  const listDepartments = React.useMemo(() => {
    return departments.map((department) => ({
      value: department,
      label: department.name,
    }));
  }, [JSON.stringify(departments)]);

  return (
    <Form
      defaultValues={defaultValues}
      schema={schema}
      onSubmit={handleSubmit}
      onlySendDirty={false}
      optionsForm={{ mode: "onChange" }}
    >
      <Template
        buttons={{
          ...buttons,
          submit: <SubmitButton {...buttonSubmitProps}>Next</SubmitButton>,
        }}
        props={propsTemplate}
      >
        <Typography color={"black"} weight={"bold"} variant={"body"}>
          {_.get(state, `context.companies[${numbersOfIteration}].name`, "")}
        </Typography>
        <TemplateLabel
          label={"Position"}
          className={ClassNames(cls.TemplateLabel)}
        >
          <ControllerInput
            render={SelectInput}
            name="position"
            list={listPositions}
            defaultlabel={""}
            placeholder="Search or select from list"
            clear
          />
        </TemplateLabel>
        <TemplateLabel
          label={"Department"}
          className={ClassNames(cls.TemplateLabel)}
        >
          <ControllerInput
            render={SelectInput}
            name="department"
            list={listDepartments}
            compare={"id"}
            defaultlabel={""}
            placeholder="Search or select from list"
            clear
          />
        </TemplateLabel>
      </Template>
    </Form>
  );
};

export default FormMoreDetails;

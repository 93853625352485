import React, { FC } from "react";
import classNames from "classnames";
import { ButtonProps } from "@material-ui/core";
import cls from "./ButtonCard.module.scss";
import Icon from "../../Icon";

interface ButtonCardProps extends ButtonProps {
  /**
  * Render Component of button content
  */
  customContendElement?: React.ComponentType;
  /**
  * Props Render Component of button content
  * control  null
  */
  propsCustomElement?: any;
  /**
  *  Button is Selected
  */
  selected: boolean;
  /**
  *  The display content of the button
  */
  text?: string;
  onClick: () => void;
  className: string;
}

const ButtonCard: FC<ButtonCardProps> = ({
  customContendElement: CustomContendElement,
  propsCustomElement,
  text = "Text default",
  selected = false,
  variant = "Default",
  className,
  disabled,
  ...props
}) => {

  return (
    <button
      type={"button"}
      data-active={selected ? "SELECTED": "UNSELECTED"}
      className={classNames(`${cls.container_button_card} ${className}`, {
        [cls.container_button_card_select]: selected,
        [cls.specialWith]: variant === "overlays",
      },
      //  cls[variant]
      )}
      disabled={disabled}
      {...props}
    >
      <span
        className={classNames(cls.icon_card, {
          [cls.icon_card_selected]: selected,
        })}
      >
        <span className={cls.container_icon_card}>
          {selected && <Icon isClara icon={"minitick"} size={"0.65rem"} />}
        </span>
      </span>
      {CustomContendElement ? (
        <CustomContendElement {...propsCustomElement} />
      ) : (
        <span className={cls.button_card_text}>{text}</span>
      )}
    </button>
  );
};

export default ButtonCard;

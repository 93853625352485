import Skeleton from '@material-ui/lab/Skeleton';
import { Grid } from "@mui/material";
import React from "react";



const DetailsTabSkeleton = () => {

  return (

    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Skeleton variant="text" height={"16px"} width={"6rem"} />
          </Grid>
          <Grid item xs={8}>
            <Skeleton variant="text" height={"16px"} width={"7rem"} />

          </Grid>
          <Grid item xs={4}>
            <Skeleton variant="text" height={"16px"} width={"5rem"} />
          </Grid>
          <Grid item xs={8}>
            <Skeleton variant="text" height={"16px"} width={"7.6923rem"} />
          </Grid>

          <Grid item xs={4}>
            <Skeleton variant="text" height={"16px"} width={"7.6923rem"} />
          </Grid>
          <Grid item xs={8}>
            <Skeleton variant="text" height={"16px"} width={"8.6923rem"} />
          </Grid>
          <Grid item xs={4}>
            <Skeleton variant="text" height={"16px"} width={"5.6923rem"} />
          </Grid>
          <Grid item xs={8}>
            <Skeleton variant="text" height={"16px"} width={"7.6923rem"} />
          </Grid>
          <Grid item xs={4}>
            <Skeleton variant="text" height={"16px"} width={"4.6923rem"} />
          </Grid>
          <Grid item xs={8}>
            <Skeleton variant="text" height={"16px"} width={"7.6923rem"} />
          </Grid>
          <Grid item xs={4}>
            <Skeleton variant="text" height={"16px"} width={"6.6923rem"} />
          </Grid>
          <Grid item xs={8}>
            <Skeleton variant="text" height={"16px"} width={"7.6923rem"} />
          </Grid>
          <Grid item xs={4}>
            <Skeleton variant="text" height={"16px"} width={"7.6923rem"} />
          </Grid>
          <Grid item xs={8}>
            <Skeleton variant="text" height={"16px"} width={"7.6923rem"} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Skeleton variant="text" height={"16px"} width={"7.6923rem"} />
          </Grid>
          <Grid item xs={8}>
            <Skeleton variant="text" height={"16px"} width={"7.6923rem"} />
            <Skeleton variant="text" height={"16px"} width={"7.6923rem"} />
            <Skeleton variant="text" height={"16px"} width={"7.6923rem"} />
            <Skeleton variant="text" height={"16px"} width={"7.6923rem"} />
          </Grid>
          <Grid item xs={4}>
            <Skeleton variant="text" height={"16px"} width={"7.6923rem"} />
          </Grid>
          <Grid item xs={8}>
            <Skeleton variant="text" height={"16px"} width={"7.6923rem"} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DetailsTabSkeleton;

import React, { useEffect } from 'react';
import classesModule from "./ContentCardRol.module.scss";
import Skeleton from '@material-ui/lab/Skeleton';
const ContentCardRolSkeletor = ({ rolName }) => {

   

    return (
        <React.Fragment>
            <div className={classesModule.ContentCardRol}>
                <div className={classesModule.HeaderTitle}>
                    <span><Skeleton variant="text" width={"3rem"} height={"1.2rem"}/></span>
                </div>
            </div>

        </React.Fragment >
    )
};

export default ContentCardRolSkeletor;

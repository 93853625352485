const Color = {
  Clara_Dark_Grey: "#828C98",
  Clara_Pink: "#E96199",
};
const listPrice = [
  {
    id: "5fbf1a300000000000000001",
    title: "Cap table scenario modelling",
    subTitle:
      "Predict your company's future with Scenario modelling from Clara. Map out various funding scenarios and use them to engage investors and motivate employees.",
    amount: 3,
    displayValue: "3 Checks",
    displayValuePresentation: "3 Health Checks",
    code: "001",
    orderProduct: [
      {
        color: Color.Clara_Dark_Grey,
        icon: "Lock",
        text: "Locked",
        amount: 0,
      },
      {
        color: Color.Clara_Pink,
        icon: "Unlocked",
        text: "Unlocked",
        amount: 0,
      },
    ],
    iconTable: "Scenario-modelling",
  },
  {
    id: "5fbf1a300000000000000001",
    title: "Share Incentive Plan",
    subTitle:
      "Incentivise your team by adopting a plan and allocating share options. Sign, store, then manage options and vesting directly from Clara.​",
    amount: 3,
    displayValue: "3 Checks",
    displayValuePresentation: "3 Health Checks",
    code: "001",
    orderProduct: [
      {
        color: Color.Clara_Dark_Grey,
        icon: "Lock",
        text: "Locked",
        amount: 0,
      },
      {
        color: Color.Clara_Pink,
        icon: "Unlocked",
        text: "Unlocked",
        amount: 0,
      },
    ],
    iconTable: "Legal",
  },
  {
    id: "5fbf1a300000000000000001",
    title: "Founders’ Agreement",
    subTitle:
      "Safeguard your future by setting out your founders’ commitments to the profile: their roles, contributions and any vesting arrangements.",
    amount: 3,
    displayValue: "3 Checks",
    displayValuePresentation: "3 Health Checks",
    code: "001",
    orderProduct: [
      {
        color: Color.Clara_Dark_Grey,
        icon: "Lock",
        text: "Locked",
        amount: 0,
      },
      {
        color: Color.Clara_Pink,
        icon: "Unlocked",
        text: "Unlocked",
        amount: 0,
      },
    ],
    iconTable: "Document",
  },
  {
    id: "5fbf1a300000000000000001",
    title: "30 Minute Onboarding",
    subTitle:
      "A session to guide you through the Platform’s features and functionalities",
    amount: 3,
    displayValue: "3 Checks",
    displayValuePresentation: "3 Health Checks",
    code: "001",
    orderProduct: [
      {
        color: Color.Clara_Dark_Grey,
        icon: "Lock",
        text: "Locked",
        amount: 0,
      },
      {
        color: Color.Clara_Pink,
        icon: "Unlocked",
        text: "Unlocked",
        amount: 0,
      },
    ],
    iconTable: "Concierge",
  },
  {
    id: "5fbf1a300000000000000001",
    title: "Generate documents",
    subTitle:
      "Generate key documents for your profile and get electronic signatures all in one place.",
    amount: 3,
    displayValue: "3 Checks",
    displayValuePresentation: "3 Health Checks",
    code: "001",
    orderProduct: [
      {
        color: "#828C98",
        icon: "",
        text: "3 documents",
        amount: 3,
      },
      {
        color: "#E96199",
        icon: "Switch-around",
        text: "Unlimited",
        amount: 0,
      },
    ],
    iconTable: "Document",
  },
  {
    id: "5fbf1a300000000000000001",
    title: "Document uploads",
    subTitle:
      "Upload, store, view and share your documents with Clara’s virtual data room.",
    amount: 3,
    displayValue: "3 Checks",
    displayValuePresentation: "3 Health Checks",
    code: "001",
    orderProduct: [
      {
        color: "#828C98",
        icon: "",
        text: "50 uploads",
        amount: 3,
      },
      {
        color: "#E96199",
        icon: "Switch-around",
        text: "Unlimited",
        amount: 0,
      },
    ],
    iconTable: "Document-edit",
  },
  {
    id: "5fbf1a300000000000000001",
    title: "Health Check",
    subTitle:
      "Automatically review details, documents and structure for investment-readiness. Get your profile into its best shape yet.",
    amount: 3,
    displayValue: "3 Checks",
    displayValuePresentation: "3 Health Checks",
    code: "001",
    orderProduct: [
      {
        color: "#828C98",
        icon: "",
        text: "3 checks",
        amount: 3,
      },
      {
        color: "#E96199",
        icon: "Switch-around",
        text: "Unlimited",
        amount: 0,
      },
    ],
    iconTable: "Heart",
  },
];
export default listPrice;

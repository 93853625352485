import _ from 'lodash';
import React, { FC, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from "react-redux";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import * as Yup from 'yup';
import Form from "../../../../../components/forms/Form/Form";
import FormProps from "../../../../generic/forms/interfaces/FormProps";
import useTranslate from "../../../../generic/hooks/useTranslate";
import { addAssistantText, clearAssistatText } from "../../../../generic/store/action";
import TemplateFormDefault from "../../../../generic/templates/Modal";
import CheckboxButtonSimple from "../../../../generic/components/Inputs/CheckboxButtonSimple";
import ControllerInput from "../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import classes from './classes.module.scss';

const schema = Yup.object().shape({
  confirm: Yup.string().required("Required"),
})

interface Props extends FormProps {
  initialValues: any,
  onCompleteSubmit: any,
  key: string,
  handlePrev: any,
}

const FormStep1: FC<Props> = ({
  initialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
  handlePrev,
  key,
}) => {

  const refForm = useRef();
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const allStakeholders = _.get(initialValues, "stakeholders", []);

  useEffect(() => {
    handeAddTooltips();
    return () => {
      dispatch(clearAssistatText());
    }
  }, []);

  const handeAddTooltips = () => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText(translate("MODAL_ADD_RECIPIENT_FOR_NOTICES_TOOLTIP1")));
    dispatch(addAssistantText(translate("MODAL_ADD_RECIPIENT_FOR_NOTICES_TOOLTIP2")));
  }

	const {optionsToShow, defaulValue} = useMemo(() => {
    const defaulValue = _.size(allStakeholders) === 1 ? allStakeholders[0].id : "";
    const optionsToShow = allStakeholders.map(stks => {
      return {
        value: stks.id,
        label: _.get(stks, "fullName", ""),
      }
    })
    return {optionsToShow, defaulValue};
	},[JSON.stringify(initialValues)]);

  const handleOnSubmit = (values) => {
    if (_.size(allStakeholders) === 1) {
      onCompleteSubmit({
        ...values,
        confirm: defaulValue
      });
      
    } else {
      onCompleteSubmit({
        ...values,
      });
    }
  }

  return (
    <>
      <Form
        key={key}
        defaultValues={initialValues || {}}
        schema={schema}
        onSubmit={handleOnSubmit}
        ref={refForm} optionsForm={{ mode: "onChange" }}
      >
        <Template 
          props={propsTemplate} 
          buttons={{
            ...buttons,
            submit: <SubmitButton {...buttonSubmitProps}>Confirm</SubmitButton>,
          }}
          >
          <ControllerInput
            render={CheckboxButtonSimple}
            name='confirm'
            classNameButton={classes.buttonCheckBox}
            options={optionsToShow}
            defaultValue={defaulValue}
            onChange={(value) => {}}
            isMultiple={false}
          />
        </Template>
      </Form>
    </>
  )
}

export default FormStep1
import { actions as actionTypes } from "./action";

const initialState = {
	selectedCompany: {
		id: null,
		name: '',
		enabled: false
	}
};

export default (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.SET_SELECTED_COMPANY:
            return {
                ...state,
				selectedCompany: action.selectedCompany
            };
        default:
            return state;
    }
};

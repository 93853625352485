import React, { useState } from 'react';
import classes from './PasswordInput.module.scss';
import ErrorInput from "../../../modules/generic/components/Inputs/ErrorInput";
import TooltipInput from "../../generic/Tooltips/TooltipIput/TooltipInput";
import useTextLang from "../../../hooks/custom/useTextLang";
import classnames from 'classnames';
import { Line as PasswordStrength } from 'rc-progress';
import owasp from 'owasp-password-strength-test';
import _ from "lodash";
import Icon from "./../../../modules/generic/components/Icon";

// const PasswordInput = ({
const PasswordInput = React.forwardRef(({
  showStrong = false,
  type = "text",
  className,
  showErrorIcon,
  placeholder = "",
  tooltip,
  error,
  errorMessage,
  isForm,
  name,
  currency,
  decorator,
  onChange,
  classNameError,
  formState,
  fieldState,
  innerRef,
  ...props 
}, ref) => {

  const placeholderTranslate = useTextLang(placeholder);
  const [percent, setPercent] = useState(props.value);
  const [strong, setStrong] = useState();
  const [isSecret, setIsSecret] = useState(true);

  const handleChange = (e) => {
    const value = _.get(e, "target.value", "")
    handleStrongPassword(value);
    onChange && onChange(value);
  }

  const getClassStrong = () => {
    switch (strong) {
      case "bad":
        return classes.StrongBad;
      case "warning":
        return classes.StrongWarning;
      case "strong":
        return classes.StrongStrong;
      default:
        return classes.StrongBad;
    }
  }

  const handleChangeType = () => {
    setIsSecret(!isSecret);
  }

  const handleStrongPassword = (value) => {
    let { failedTests, passedTests, strong } = owasp.test(value);
    const totalTest = failedTests.length + passedTests.length;
    let percent = value.length === 0 ? 0 : (passedTests.length * 100) / totalTest;
    setPercent(percent)
    if (strong) {
      setStrong("strong")
    } else if (failedTests.length < passedTests.length) {
      setStrong("warning")
    } else {
      setStrong("bad")
    }
  }

  return (
    <div className={`${classes.PasswordInput} ${className}`} >
      <TooltipInput label={tooltip}>
        <input
          autoComplete={`autoComplete-input-${name}`}
          type={isSecret ? "password" : "text"}
          name={name}
          placeholder={placeholderTranslate}
          onChange={handleChange}
          className={classnames({
            [classes.borderErrorInput]: errorMessage
          }, classes.input)}
          {...props}
        />
      </TooltipInput>
      <Icon onClick={handleChangeType} className={classes.iconEye} icon={isSecret ? "Eye-Closed" : "Eye-Open"} isClara={true} size={"1.5rem"}></Icon>
      {showStrong && <PasswordStrength percent={percent} strokeWidth="1" className={`${classes.PasswordStrength} ${getClassStrong()}`} />}
      {errorMessage && <div className={classes.errorContainer}><ErrorInput message={errorMessage} showErrorIcon={showErrorIcon} classNameError={classNameError} component={error} /></div>}
    </div>
    )
// }
});


export default PasswordInput
import { useMemo } from 'react';

const useGuards = () => {

  return useMemo(() => ({

    acceptInDelaware: (context) => {
      return context?.FormDelawareAgree?.accept;
    },

    discardDelaware: (context) => {
      return context?.FormDelawareAgree?.accept === false;
    },

    noSendEmail: (context) => {
      return context?.EngageConciergeDelaware?.accept === false;
    },

    sendMail: (context) => {
      return context?.EngageConciergeDelaware?.accept;
    },

  }), []);
}

export default useGuards;

import React, { FC, useRef, useState } from 'react';
import * as Yup from 'yup';
import Form from "../../../../../../components/forms/Form/Form";
import SubmitButton from "../../../../../generic/components/Buttons/SubmitButton";
import AutocompleteClara from '../../../../../generic/components/Inputs/Autocomplete';
import TemplateLabel from "../../../../../generic/components/Inputs/LabelInput";
import ControllerInput from "../../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import FormProps from "../../../../../generic/forms/interfaces/FormProps";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import TemplateFormDefault from "../../../../../generic/templates/Modal";
import { useDispatch } from "react-redux";
import { addAssistantText, clearAssistatText, BubbleCustom } from "../../../../../generic/store/action";
import _ from 'lodash';
import DialogItem from "../../../../../../modules/assistanceV4/components/DialogItem";
import Button from "../../../../../../modules/generic/components/Buttons/Button";
import { Constants } from "../../../../../../v1/utils/constants";
import useNavigation from "../../../../../../modules/generic/hooks/useNavigation";

interface StakeholderNameFormProps extends FormProps {
  isIndividual?: boolean
  stakeholders: any[]
}

const StakeholderNameForm: FC<StakeholderNameFormProps> = ({ stakeholders, isIndividual, initialValues, onCompleteSubmit, Template = TemplateFormDefault, buttons = {}, buttonSubmitProps, propsTemplate }: StakeholderNameFormProps) => {
  
  const { translate } = useTranslate();
  const refForm = useRef();
  const dispatch = useDispatch();
  const { goTo } = useNavigation();
  const [showWarning, setShowWarning] = useState(false);

  const stakeholdersList = React.useMemo(() => {
    return stakeholders.map(st => ({ value: st, label: st.fullName }));
  }, [JSON.stringify(stakeholders)])
  
  const schema = Yup.object().shape({
    stakeholder: Yup.object().nullable().required('Required')
  }).test('Remaining Shares must be less amount',
    null,
    (obj) => {
      if (obj === undefined) {
        return true;
      }
      if (stakeholders.some(st => st.id === obj?.stakeholder?.id)) {
        handeAddTooltips(obj);
        setShowWarning(true);
        return new Yup.ValidationError(
          `Stakeholder already added`,
          null,
          `stakeholder`
        );
      } else {
        if (showWarning) {
          setShowWarning(false);
          dispatch(clearAssistatText());
        }
      }
      return true;
    }
  )

  const createStakeholder = (value: string) => {
    return { fullName: value };
  }

  const handeAddTooltips = (obj) => {
    const stkId = obj?.stakeholder?.id;
    dispatch(clearAssistatText());
    dispatch(
      addAssistantText(
        translate("MODAL_ADD_SHAREHOLDER_ADD_NAME_EXISTING", { name: _.get(obj, "stakeholder.fullName", "") }),
        "alert"
      )
    );
    const bubbleButton: BubbleCustom = {
      component: (
        <>
          <DialogItem
            noIcon={true}
            noTopMargin={true}
          >
            <Button
              variant="pay"
              onClick={() => {
                const skType = _.filter(stakeholders, (stk) => stk.id === stkId);
                if (skType[0].isAnEntity) {
                  goTo(Constants.PAGES.viewStakeholderCompany, { id: stkId })
                } else {
                  goTo(Constants.PAGES.viewStakeholderIndividual, { id: stkId })
                }
              }}
              children={translate("MODAL_ADD_SHAREHOLDER_ADD_NAME_EXISTING_BUTTON")}
            ></Button>
          </DialogItem>
        </>
      ),
    }
    dispatch(addAssistantText(bubbleButton, "custom-bubble"))
  }

  const handleSubmit = async (values) => {
    const { stakeholder } = values;
    await onCompleteSubmit({ ...values, stakeholder: { ...stakeholder, isAnEntity: !isIndividual } });
  };

  return (
    <>
      <Form
        defaultValues={initialValues}
        schema={schema}
        onlySendDirty={false}
        onSubmit={handleSubmit}
        ref={refForm} optionsForm={{ mode: "onChange" }}
      >
        <Template
          buttons={{ ...buttons, submit: <SubmitButton {...buttonSubmitProps}>Next</SubmitButton> }}
          props={propsTemplate}
        >
          <TemplateLabel isRequired label={isIndividual ? translate("MODULES.ONBOARDING_FORMS.INDIVIDUAL_OR_COMPANY_FORM.FULLNAME") : translate("MODULES.ONBOARDING_FORMS.INDIVIDUAL_OR_COMPANY_FORM.NAME")}>
            <ControllerInput
              render={AutocompleteClara}
              getCreateValue={createStakeholder}
              name='stakeholder'
              options={stakeholdersList}
              placeholder={isIndividual ? translate("MODULES.ONBOARDING_FORMS.INDIVIDUAL_OR_COMPANY_FORM.FULLNAME_PLACEHOLDER") : translate("MODULES.ONBOARDING_FORMS.INDIVIDUAL_OR_COMPANY_FORM.NAME_PLACEHOLDER")}
              defaultValue={''}
            />
          </TemplateLabel>
        </Template>
      </Form>
    </>
  )
}

export default StakeholderNameForm
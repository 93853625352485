import React, {useEffect, useState} from 'react';
import {Constants} from '../../../../utils/constants'
import {useDispatch} from "react-redux";
import _ from "lodash";
import {addIndividual, getIndicidualKYC} from '../../../../graphql/mutations/matters';
import notification from "../../../../utils/notification";
import Mustache from 'mustache';
import ServerConnect from "../../../../utils/ServerConnect";
import Loading from '../../../Loading/Loading';
import FormIndividual from '../../../../containers/Forms/KYC/Individual/FormIndividual';
import {LayoutForm} from '../../../../containers';
import {stripTypenames} from '../../../../utils/graphql-util';

const AddKYCForm = (props) => {
    const dispatch = useDispatch();

    const closeForm = () => {
        dispatch({ type: 'GO_TO', page: Constants.PAGES.viewMatter, params: { id: props.params.matterId, tab: "Kyc" } })
    };

    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(() => {
        return props.mode === "edit";
    });


    const modifyDataFile = (values) => {
        const file={...values}
        return _.forOwn(file, (val, key) => {
            if (_.hasIn(val, 'file')) {
                return file[key] = val.file
            }
        });
    };

    const submitData = (values) => {

        let formattedValues = values;
        let message = props.mode === 'edit' ? 'Individual Updated' : 'Individual Created';
        delete formattedValues.formKey;
        delete formattedValues.type;
        delete formattedValues.isShareholder;
        delete formattedValues.isDirector;
        delete formattedValues.isCompanyFlow;
        delete formattedValues.isPrimary;
        delete formattedValues.isPEP;
        delete formattedValues.jurisdiction;
        delete formattedValues.otherJurisdiction;

        const variables = {
            matterId: props.params.matterId,
            KYCIndividualData: formattedValues,
            entityId: props.params.entityId
        };

        ServerConnect.graphQlMutation(addIndividual, stripTypenames(variables))
            .then(result => {
                notification.sendNotification(message, "success", 4990);
                dispatch({ type: 'GO_TO', page: Constants.PAGES.viewMatter, params: { id: props.params.matterId, tab: "Kyc" } })
            }).catch(err => {
                console.log(err);
                notification.sendNotification("ERROR", "error", 4990);
            });
    };

    const getData = () => {
        return new Promise((resolve, reject) => {
            const query_params = {
                matterId: props.params.matterId
            };
            const queryExecute = Mustache.render(getIndicidualKYC, query_params);
            ServerConnect.graphQlQuery(queryExecute).then(result => {
                let data = {
                    ...result.getMattersList
                };
                resolve(data);
            });
        })
    };

    const filterData = (data) => {
        if (data.KYC) {
            if (data.KYC.KYCType === "corporateEntity") {
                let individual = _.find(data.KYC.entitiesData.individuals, ele => { return props.params.id === ele.id });
                if (!individual) {
                    individual = _.find(data.KYC.entitiesData.entities[0].PEPs, ele => { return props.params.id === ele.id });
                }
                return individual;
            } else {
                return _.find(data.KYC.individuals, ele => { return props.params.id === ele.id })
            }
        }
    };

    useEffect(() => {
        if (!data && props.mode === 'edit') {
            setLoading(true)
            Promise.resolve(getData())
                .then((result) => {
                    let  individual = filterData(result[0]);  
                    setData({...individual, passports: _.get(individual, 'passports', []).map(pass=> modifyDataFile(pass))})
                    setLoading(false)
                })
        }
    }, [data, loading]);



    return (
        <React.Fragment>
            <Loading show={loading}>
                <LayoutForm
                    title={"Add Individual"}
                    leftEducationId={Constants.CONTENTFUL.ENTRIES.FORMS.KYC_INDIVIDUAL_FORM[`${props.mode === 'edit' ? "EDIT" : "ADD"}`].LEFT_EDUCATION_ID}
                    handleClose={closeForm}>
                    <FormIndividual initialValues={props.mode === 'edit' ? { ...modifyDataFile(data), formKey: 'asd' } : { formKey: 'asd' }} onSubmit={submitData} />
                </LayoutForm>
            </Loading>
        </React.Fragment>
    )
};

export default AddKYCForm;

import React, { FC } from "react";
import Skeleton from '@material-ui/lab/Skeleton';
import TemplateLabel, {TemplateLabelComponent} from "../../../../../../modules/generic/templates/Label/";
import classnames from "classnames";
import classes from "./classes.module.scss";

/**
 * Overlay Skeleton Input
 */

export interface TemplateSkeletonComponentProps<PropTypes = any> {
  Template?: TemplateLabelComponent,
  propsTemplate?: PropTypes,
  count?: number,
  noLabel?: boolean
}

export type TemplateSkeletonComponent<PropsType = any> = FC<TemplateSkeletonComponentProps<PropsType>>

export interface SkeletonCheckProps extends TemplateSkeletonComponentProps {
  countOptions: number,
}

const SkeletonCheck: TemplateSkeletonComponent = ({
  Template = TemplateLabel,
  propsTemplate,
  count = 1,
  countOptions = 4,
  noLabel = false,
}:SkeletonCheckProps) => {

  return (
    <React.Fragment>
      {Array(count).fill(1).map((el, index) => 
        <React.Fragment>
          {noLabel ? (
            <div className={classnames(classes.skeletonCheckContainer)} key={index}>
              {Array(noLabel ? 1 : countOptions).fill(1).map((el, i) =>
                <div className={classnames(classes.skeletonCheck)} key={i}>
                  <Skeleton variant="rect" height={"1.2308rem"} width={"1.2308rem"}/>
                  <Skeleton variant="text" height={"1.3846rem"} width={"50%"}/>
                </div>
              )}
            </div>
          ) : (
            <TemplateLabel
              label={<Skeleton variant="text" height={"1.3846rem"} width={"60%"}/>}
              key={index}
            >
              <div className={classnames(classes.skeletonCheckContainer)}>
                {Array(countOptions).fill(1).map((el, i) =>
                  <div className={classnames(classes.skeletonCheck)} key={i}>
                    <Skeleton variant="rect" height={"1.2308rem"} width={"1.2308rem"}/>
                    <Skeleton variant="text" height={"1.3846rem"} width={"50%"}/>
                  </div>
                )}
              </div>
            </TemplateLabel>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default SkeletonCheck;
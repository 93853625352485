import React, {Component} from "react";
import {connect} from "react-redux";
import * as numeral from "numeral";

import {Constants} from "../../../../../utils/constants";
//import classes from "../../ViewForms.module.scss";
import classes from "./Equity.module.scss";
import {ClaraSearch, EquityDeleteForm, RoleAccess, Tabs} from "../../../../index";

import ModalShare from '../../../../Modal/ModalShare/ModalShare';

import {upsertShareClass, upsertShareClassIncentivePlan} from '../../../../../graphql/mutations/equity';

import Table from "./EquityTable";
import ShareClassesTable from './ShareClassesTable';
import ShareIncentivePlansTable from './ShareIncentivePlansTable'
import _ from "lodash";
import {goToPage, goToPrevPage} from "../../../../../../store/actions/page";

import ServerConnect from "../../../../../utils/ServerConnect";

class Equity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs:[
        { name: "Equity List", key:"equityPosition", add: "Add Equity Position"},
        { name: "Share Classes", key :"shareClasses", add: "New Share Class" },
        { name: "Share Incentive Plans", key: "shareIncentivePlans", add: "New Share Incentive Plan"}
      ],
      tab:"equityPosition",
      options: [],
      optionsToDelete: [],
      refresh: false,
      indexDelete: null,
      equityBelong: {},
      filter: "",
      totalsByType: [
        {
          name: "Shares",
          type: "SHARE"
        },
        {
          name: "Convertibles",
          type: "CONVERTIBLE"
        },
        {
          name: "Share Options",
          type: "OPTION"
        },
        {
          name: "Warrants",
          type: "WARRANT"
        }
      ],
      data: [],
      rowDelete: {},
      shareClasses:[],
      shareIncentivePlans:[],
      openModal:false

    };
  }


  //tabs

  goTo = tab =>{
    this.setState({
      tab
    })
  }

  //

  onChangeFilter = value => {
    this.setState({
      filter: value
    });
  };

  mapShareClass= () =>{
    if(this.props.isGroupCompany){
      this.setState({
        shareClasses:this.props.data.shareClass,
        shareIncentivePlans: _.map(this.props.data.shareClass, sh => sh.shareIncentivePlans)
      })
    }

  }
  componentWillMount = () => {
    let typeEquityBelong = this.props.isGroupCompany ? "name" : "fullName";

    let viewData = _.pick(
      this.props.data,
      "id",
      typeEquityBelong,
      "avatar",
      "__typename"
    );
    let data = _.pick(
      this.props.data,
      "shares",
      "shareOptions",
      "warrants",
      "convertibles"
    );
    let tableTotals = this.totalShare(data);
    this.setState({
      equityBelong: viewData,
      data,
      tableTotals
    },  this.mapShareClass());
  };

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.data, this.props.data)) {
      
      let data = _.pick(
        this.props.data,
        "shares",
        "shareOptions",
        "warrants",
        "convertibles"
      );
      this.setState({
        data,
        showDelete: false,
        tableTotals: this.totalShare(data)
      });
    }
  }
  totalShare(data) {
    let total = [];
    _.forEach(_.keys(data), e => {
      let groups = _.groupBy(data[e], "currency");
      _.forEach(_.keys(groups), (currency, i) => {
        total.push({
          total: _.sumBy(groups[currency], o => {
            return o.amount;
          }),
          currency: currency,
          type: groups[currency][i].type
        });
      });
    });

    return _.groupBy(total, "type");
  }
  viewCapTable = () => {
    this.props.goToPage(Constants.PAGES.capTable);
  };

  // revisar esta funcionalidad
  viewEquity = row => {
    let fromView = this.state.equityBelong;
    if (
      row.holder.__typename === "StakeHolder" ||
      fromView.__typename === "StakeHolder"
    ) {
      row.ownerUrl = "stakeholder";
    } else {
      row.ownerUrl = "groupCompany";
    }

    this.props.goToPage(Constants.PAGES.viewEquity, { ...row, fromView });
  };
  editEquity = row => {
    let fromView = this.state.equityBelong;
    if (
      row.holder.__typename === "StakeHolder" ||
      fromView.__typename === "StakeHolder"
    ) {
      row.ownerUrl = "stakeholder";
    } else {
      row.ownerUrl = "groupCompany";
    }

    this.props.goToPage(Constants.PAGES.editEquity, { ...row, fromView });
  };
  viewHolder = row => {
    if (row.holder.__typename === "GroupCompany") {
      this.props.goToPage(Constants.PAGES.viewGroupCompany, {
        id: row.holder.id
      });
    } else {
      this.props.goToPage(Constants.PAGES.viewStakeholderIndividual, {
        id: row.holder.id
      });
    }
  };

  handleAdd = () => {

      if(this.state.tab ==="equityPosition"){
        this.props.goToPage(Constants.PAGES.addEquityPosition);
      }
      else {

        this.setState({
          openModal:true
        }) 
      }
    }
    

  deleteEquity = row => {
    let data = _.pick(
      row,
      "type",
      "title",
      "id",
      "holder[id]",
      "holder[__typename]"
    );
    if (!this.props.isGroupCompany) {
      data.holder = _.pick(this.state.equityBelong, "id", "__typename");
    }
    this.setState({
      showDelete: true,
      rowDelete: data
    });
  };

  handleCloseDeleteModal = redirect => {
    this.setState({
      showDelete: false
    });
  };
  handleCloseModal= (props) =>{
    this.setState({
      openModal: false
    })
  }

  handleModalSubmit = (values) =>{
    let mutation = "";
    let variables=  {};
    this.handleCloseModal();
    this.props.showLoading(true);
      if(this.state.tab === "shareClasses"){

        mutation =upsertShareClass;
        variables = {
          startupId:this.props.session.startup.id,
          shareClass: {
            name: values.name,
            groupCompanyId: this.state.equityBelong.id
          }
        }
      }
      else{
        mutation= upsertShareClassIncentivePlan;

        variables = {
          startupId:this.props.session.startup.id,
          shareIncentivePlan: {
            name: values.name,
            shareClassId: values.shareClass            
          }
        }

      }

      ServerConnect.graphQlMutation(mutation,variables)
      .then(result=>{
          this.props.refreshData();

      })

  }

  getTitleModal = () =>{
    return this.state.tab === "shareIncentivePlans" ? "Share Incentive Plan Name" :"Class Name" ; 
  }
  amounts = type => {
    if (this.state.tableTotals[type]) {
      return this.state.tableTotals[type].map((sh, i) => {
        let currency =
          _.eq(sh.type, "OPTION") || _.eq(sh.type, "SHARE") ? "" : sh.currency;
        return (
          <p key={i}>{numeral(sh.total).format("0,0.00") + " " + currency}</p>
        );
      });
    }
    return <p>0</p>;
  };
  deleteEntity=()=>{
    if(this.props.reloadParent){
      this.props.reloadParent();
    }
    
  }
  render() {
    let { data, tabs, tab } = this.state;
    const { filter } = this.state;
    if (!data) {
      return null;
    }

    return (
      <div className={classes.ContainerView}>
        <div className={classes.containerSharesType}>
          {this.state.totalsByType.map((typeEquity, i) => {
            return (
              <div key={i} className={classes.containerDataShares}>
                {this.amounts(typeEquity.type)}
                <i className={`${classes.shares}`}> {typeEquity.name}</i>
              </div>
            );
          })}
          <button
            className={classes.buttonCapTable}
            onClick={this.viewCapTable}
          >
            <span>Go to Cap Table</span>
          </button>
        </div>

        <div className={classes.ContainerSearchAndTitle}>
          { this.props.isGroupCompany ?
          <Tabs
            className={classes.Tabs}
            tabs={this.state.tabs}
            goTo={this.goTo}
            selected={this.state.tab}
            classLetter= {classes.letterTabs}
            classesTabs= {classes.Tabs}
          />
          : <div className={classes.titleTable}>Equity List</div>
          } 
          <div className={classes.containerFilterBox}>
            <ClaraSearch
              placeHolder={`Search...`}
              onChange={this.onChangeFilter}
              classes={{ input: classes.inputSearch }}
            />
          </div>
          <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE}>
            <span
              className={classes.ContainerAdd}
              onClick={this.handleAdd}
            >
              { _.find(tabs,t =>t.key === tab).add }
            </span>
          </RoleAccess>
        </div>
        {this.state.tab === this.state.tabs[0].key &&
          <div>
            <Table
              data={data}
              viewEquity={this.viewEquity}
              viewHolder={this.viewHolder}
              editEquity={this.editEquity}
              isGroupCompany={this.props.isGroupCompany}
              filter={filter}
              deleteEquity={this.deleteEquity}
            />
          </div>
        }
        {this.state.tab === this.state.tabs[1].key &&
        
          <ShareClassesTable
            data={data}
            equityBelong={this.state.equityBelong}
            shareClass={this.state.shareClasses}
            filter={filter}
            startupId={this.props.session.startup.id}
            showLoading = {this.props.showLoading}
            refreshData = {this.props.refreshData}

          />

        }
        {this.state.tab === this.state.tabs[2].key && 
            <ShareIncentivePlansTable
            shareIncentivePlans={this.state.shareIncentivePlans}
            equityBelong={this.state.equityBelong}
            data={data}
            filter={filter}
            startupId={this.props.session.startup.id}
            shareClass={this.state.shareClasses}
            showLoading = {this.props.showLoading}
            refreshData = {this.props.refreshData}
            />
            
        }
         {this.state.showDelete && (
          <EquityDeleteForm
            data={this.state.rowDelete}
            handleClose={this.handleCloseDeleteModal}
            dataView={this.state.equityBelong}
            equityElements={this.props.data}
            showDelete={this.state.showDelete}
            setData={this.props.refreshData}
            callbacks={{
              deleteEntity: this.deleteEntity
            }}
          />)     
        }
        {this.state.openModal &&
        <ModalShare
        handleClose={this.handleCloseModal}
        handleSubmit= {this.handleModalSubmit}
        show={this.state.openModal}
        type={"add"}
        labelButton={"Submit"}
        title={"Create "+_.find(tabs,t => t.key===tab).add}
        placeholder={this.getTitleModal()}
        list={_.map(this.state.shareClasses, sc=>{return {...sc, label: sc.name }})}
        isShareIncentivePlan={tab === "shareIncentivePlans"}
        />
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  session: state.session
});
const mapDispatchToProps = dispatch => ({
  goToPrevPage: () => {
    goToPrevPage(dispatch);
  },
  goToPage: (page, params) => {
    goToPage(dispatch, page, params);
  }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Equity);

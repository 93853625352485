import React from 'react';
import InformationPanel from './InformationPanel';
import RelevantIncomePanel from './RelevantIncomePanel';
import ImmediateParentsPanel from './ImmediateParentsPanel';
import UltimateParentPanel from './UltimateParentPanel';
import UltimateBeneficialOwnerPanel from './UltimateBeneficialOwnerPanel';
import Box from "@mui/material/Box";
import _ from "lodash";

function EconomicSubstanceTab({ data, isLock, renewalData }) {
  const hasTotalIncome = (_.get(renewalData, 'getRenewalData')?.companyIncome?.total ?? 0 ) > 0 ;
  const hasCompanyIncome = !!_.get(renewalData, 'getRenewalData')?.companyIncome;

  return (
    <>
      <Box sx={{ paddingBottom: "1rem" }}>
        <InformationPanel data={data} isLock={isLock} renewalData={renewalData}/>
        {hasCompanyIncome && <RelevantIncomePanel data={data} isLock={isLock} renewalData={renewalData} />}
        {hasTotalIncome && <ImmediateParentsPanel data={data} isLock={isLock} renewalData={renewalData} />}
        {hasTotalIncome && <UltimateParentPanel data={data} isLock={isLock} renewalData={renewalData} />}
        {hasTotalIncome && <UltimateBeneficialOwnerPanel data={data} isLock={isLock} renewalData={renewalData} />}
      </Box>
    </>
  );
};

export default EconomicSubstanceTab;
import React from "react";
import classes from "../styles.module.scss";
import TwoColumnsData from "src/modules/generic/components/TwoColumnsData";
import DetailsTabTitle from "src/components/templates/DetailsTabTitle";
import { Divider } from "@mui/material";
import useModal from "src/modules/generic/hooks/useModal";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "src/modules/generic/components/Texts/Typography";
import Button from "src/modules/generic/components/Buttons/Button";
import _ from "lodash";
import EditImmediateParentModal from "src/views/modules/startups/renewal/cayman/modals/EditImmediateParentModal";


const ImmediateParentsPanel = ({ data, isLock, renewalData }) => {
  const { openModal, closeModal } = useModal();

  const handleEdit = (entityId) => {
    openModal(EditImmediateParentModal,  {
      handleCloseModal: closeModal,
      initialValues: { groupCompanyId: data?.id, entityId },
    }, 'MODAL_EDIT_IMMEDIATE_PARENT');
  }

  const immediateParents = _.get(renewalData, "getRenewalData")
    ?.immediateParents;

  const itemsColumn1 = [
    {
      label: "Name",
      custom: (
        <Stack
          direction="column"
          spacing={2}
          sx={{
            width: "100%",
          }}
        >
          {_.isEmpty(immediateParents) ? (
            <>
              <Stack
                direction="row"
                spacing={0}
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography variant="m">-</Typography>
              </Stack>
            </>
          ) : (
            <>
              {immediateParents?.map((parent) => (
                <Stack
                  direction="row"
                  spacing={0}
                  sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography variant="m">{parent?.name}</Typography>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Button variant="card" onClick={() =>{
                      handleEdit(parent?.id)
                    }} disabled={isLock}>
                      Edit
                    </Button>
                  </Stack>
                </Stack>
              ))}
            </>
          )}
        </Stack>
      ),
    },
  ];

  return (
    <>
      <Box sx={{ paddingRight: "0.4615rem" }}>
        <Divider className={classes.divider} />
        <DetailsTabTitle title="Immediate parents" />
        <TwoColumnsData
          items={itemsColumn1}
          customValueStyle={{
            marginRight: "0px !important",
            width: "100% !important",
          }}
        />
      </Box>
    </>
  );
};

export default ImmediateParentsPanel;

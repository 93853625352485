import { useMemo } from 'react';
import useGetImmediateParent from '../graphql/useGetImmediateParent';
import useUpdateImmediateParent from '../graphql/useUpdateImmediateParent';
import useSession from 'src/modules/session/hooks/useSession';
const useServices = ({ initialValues }) => {
  const { startupId } = useSession();


  const { manualQuery: getImmediateParent } = useGetImmediateParent({ startupId, groupCompanyId: initialValues?.groupCompanyId, entityId: initialValues?.entityId });
  const { updateImmediateParent } = useUpdateImmediateParent() as { updateImmediateParent: Function };
  return useMemo(() => ({

    initialData: async (context, event) => {
      const result = await getImmediateParent();
      const immediateParent = result?.data?.getImmediateParent
      return {
        ...initialValues,
        immediateParent: {
          immediateParentName: immediateParent?.name,
          mailingAddressType: immediateParent?.mailingAddress?.type,
          mailingAddress: {
            country: immediateParent?.mailingAddress?.country?.code,
            street: immediateParent?.mailingAddress?.street,
            city: immediateParent?.mailingAddress?.city,
            state: immediateParent?.mailingAddress?.state,
            zipCode: immediateParent?.mailingAddress?.zipCode,
          },
          taxRegistrationNumber: immediateParent?.taxRegistrationNumber,
          taxJurisdiction: immediateParent?.taxJurisdiction
        }
      };
    },

    SaveStep: async (context, event) => {
      const payload = {
        startupId,
        groupCompanyId: initialValues?.groupCompanyId,
        entityId: initialValues?.entityId,
        entityData: {
          name: context?.EditFormStep?.immediateParentName,
          mailingAddress: {
            type: context?.EditFormStep?.mailingAddressType,
            country: context?.EditFormStep?.mailingAddress?.country,
            street: context?.EditFormStep?.mailingAddress?.street,
            city: context?.EditFormStep?.mailingAddress?.city,
            state: context?.EditFormStep?.mailingAddress?.state,
            zipCode: context?.EditFormStep?.mailingAddress?.zipCode,
          },
          taxRegistrationNumber: context?.EditFormStep?.taxRegistrationNumber,
          taxJurisdiction: context?.EditFormStep?.taxJurisdiction,
        }
      }
      const result = await updateImmediateParent({variables:payload});
      return result;

    },

  }), [initialValues, getImmediateParent, updateImmediateParent, startupId]);
}

export default useServices;

import React, {Component} from "react";
import {connect} from "react-redux";
import {DeleteForm, Loading} from "../../index";
import Mustache from "mustache";
import ServerConnect from '../../../utils/ServerConnect';
import {eliminate, queryGetGroupCompanies} from '../../../graphql/mutations/addGroupCompany';

class GroupCompanyDeleteForm extends Component {
    state = {
        data:{},
        showLoading: false,
        options:[],
    }
    /* Particular data */
    getTitle(){
        const data =this.state.data ;
        return "Delete "+data.name+" Group company";
    }
    setOptions(){
        let options=[];
        const {data} = this.state;
        options.push({label:"Delete this group company?"});
        if (data.roles && data.roles.length>0){
            options.push({label:"Delete all stakeholder?"});
        }
        if (data.hasAgreement){
            options.push({label:"Delete all agreements?"});
        }
        if ( (data.equity && data.equity.length>0 ) || (data.shareClass && data.shareClass.length>0) ){
            options.push({label:"Delete all shares, options, warrants and equity  connected to this Group company?"});
        }
        
        this.setState({
            options
        })
    }
    hasAllData(){
        const data =this.props.data ;

        return true;
    }
    /*  Call Server*/
    callServerToGetData = () => {
        this.showLoading(true);
        // Add call to server if exis this posibility
    };
    deleteEntity=()=>{
        return new Promise((resolve, reject) => {
            var  data={
                startupId: this.props.session.startup.id,
                groupCompanyId: this.state.data.id,
            };
            this.showLoading(true);
            ServerConnect.graphQlMutation(eliminate, data).then((result) => {
                
                const query_params = {
                    startup_id: this.props.session.startup.id
                };
                const queryGC = Mustache.render(queryGetGroupCompanies, query_params);
                ServerConnect.graphQlQuery(queryGC, {}).then((resultGC) => {
                    //TODO REDUX
                    // this.props.setGroupCompanies(resultGC.stakeholderMap.groupCompanies)
                    this.showLoading(false);
                    resolve();
                });
                
            }).catch(err=>{
                reject(err)
            });
        });
    }
    /* Change status */
    showLoading =(status)=>{
        this.setState({
            showLoading: status
        });
    }
    /* REac functions */
    componentWillMount(){
        if (this.hasAllData()){
            this.setState({
                data:this.props.data
            },()=>{
                this.setOptions();
            })
            
            
        }else{
            this.callServerToGetData();
        }
    }
   
   
    render() {
        
        return (
            <Loading show={this.state.showLoading}>
                <DeleteForm  handleClose={this.props.handleClose} show={true} deleteEntity={this.deleteEntity} showLoading={this.showLoading}  title={this.getTitle()} options={this.state.options}></DeleteForm>
            </Loading>
        )
    }
}

const mapStateToProps = state => ({
    session: state.session,
    form:state.form
});
const mapDispatchToProps = (dispatch) => ({
  
});
export default connect(mapStateToProps, mapDispatchToProps)(GroupCompanyDeleteForm)

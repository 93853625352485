import AddressInput from "../../../../../components/forms/modules/startups/individualStakeholder/AddressInput";
import Button from "../../../../../modules/generic/components/Buttons/Button";
import Checkbox from "../../../../inputs/Checkbox/Checkbox";
import ControllerInput from "../../../../../modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import Form from "../../../Form/Form";
import { HiddenFieldForm } from "../../../contents";
import Line from "../../../../generic/Line/Line";
import React from "react";
import SubmitButton from "../../../../generic/Buttons/SubmitButton/SubmitButton";
import TemplateFormDefault from "../../../../../modules/generic/templates/Modal";
import TemplateLabel from '../../../../../modules/generic/templates/Label';
import Text from "../../../../text/Text/Text";
import TextInput from "../../../../inputs/Text/TextInput";
import classes from "./NoticesForm.module.scss";
import { editNotice } from "../../../../../forms/schemas/modules/startups/groupCompany/schema";
import useTranslate from "../../../../../modules/generic/hooks/useTranslate";

const NoticesForm = ({
  initialValues,
  handleSubmit,
  handleCloseModal,
  propsTemplate,
  Template = TemplateFormDefault,
}) => {
  const { translate } = useTranslate();

  return (
    <Form
      defaultValues={initialValues}
      schema={editNotice}
      onSubmit={handleSubmit}
      onlySendDirty={false}
    >
      <Template
        buttons={
          {
            submit: (
              <SubmitButton>
                {translate("MODULES.CLARA.BTN_SAVE_CHANGES")}
              </SubmitButton>
            ),
            cancel: (
              <Button onClick={() => handleCloseModal()} variant="secondary">
                {translate("MODULES.CLARA.BTN_CANCEL")}
              </Button>
            ),
          }
        }
        props={propsTemplate}
      >
        <TemplateLabel
          label={translate('MODULES.CLARA.ADDRESS_RECIPIENT_NOTICE')}
          isRequired
        >
          <ControllerInput
            render={TextInput}
            name="recipientNotice.fullName"
            placeholder={translate('MODULES.CLARA.TYPE_HERE_HIT')}
          />
        </TemplateLabel>
        <TemplateLabel
          label={translate('MODULES.CLARA.ADDRESS_RECIPIENT_NOTICE_EMAIL')}
          isRequired
        >
          <ControllerInput
            render={TextInput}
            name="recipientNotice.email"
            placeholder={translate('MODULES.CLARA.TYPE_HERE_HIT')}
          />
        </TemplateLabel>
        <Line />
          <div className={classes.containerCheckbox}>
            <ControllerInput
              render={Checkbox}
              name={"recipientNotice.useRegisteredAddress"}
            />
            <label>
              <Text uuid={"FORM_NOTICES_ADDRESS_NOTICES"} />{" "}
            </label>
          </div>
        <Line />
        <HiddenFieldForm
          fieldsWatch={"recipientNotice.useRegisteredAddress"}
          conditionHideCallback={(value) => !value}
        >
          <AddressInput
            name={"recipientNotice.address"}
            label={{ country: translate('MODULES.CLARA.RECIPIENT_ADDRESS') }}
            isRequired
            zipCodeOptional
          />
        </HiddenFieldForm>
        <div>&nbsp;</div>
      </Template>
    </Form>
  );
};

export default NoticesForm;

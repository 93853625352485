import { InMemoryCacheConfig } from "@apollo/client";
import typePolicies from './typePolicies'



const cacheConfig: InMemoryCacheConfig = {
    typePolicies
}



export default cacheConfig;
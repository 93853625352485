import _ from 'lodash'
import {gql, makeVar} from "@apollo/client";
import event from "../../../v1/utils/event";
import {Constants} from "../../../v1/utils";

export const stakeholderConnections=makeVar([])
export const groupCompanyConnections=makeVar([])

export const invalidateFields=(cache,data,fields)=>{
    const mutationName=Object.keys(data)[0]
    _.forEach(fields,field=>
      invalidateObject(cache,_.get(data[mutationName],'field'))
    )
}

export const invalidateObject=(cache,object)=>{
    cache.modify({
        id:cache.identify({id:object}),
        fields(_,{INVALIDATE}){
            return INVALIDATE
        }
    })
}

export const updateCompanyDepartments = (cache, { data }) => {
    const mutationName = Object.keys(data)[0];
    cache.modify({
        id: cache.identify(data[mutationName].groupCompany),
        fields: {
            departments(existingDepartmentsRefs = [], { readField }) {
                const newDepartmentRef = cache.writeFragment({
                    data:data[mutationName],
                    fragment: gql`
                      fragment NewDepartment on Department{
                        id
                      }
                   `});
                if (existingDepartmentsRefs.some(
                    ref => readField('id', ref) === data[mutationName].id
                )) {
                    return existingDepartmentsRefs;
                }

                return [...existingDepartmentsRefs, newDepartmentRef];
            }
        }
    });
}
export const updateCompanyPositions = (cache, { data }) => {
    const mutationName = Object.keys(data)[0];
    cache.modify({
        id: cache.identify(data[mutationName].groupCompany),
        fields: {
            positions(existingPositionsRefs = [], { readField }) {
                const newPositionRef = cache.writeFragment({
                    data:data[mutationName],
                    fragment: gql`
                      fragment NewPosition on Position{
                        code
                      }
                   `});
                if (existingPositionsRefs.some(
                    ref => readField('code', ref) === data[mutationName].code
                )) {
                    return existingPositionsRefs;
                }

                return [...existingPositionsRefs, newPositionRef];
            }
        }
    });
}

export const updateCompanyParties = (cache, { data }) => {
    //Cuando se pueda migrar esto a retech, que no se utilza por version vieja de grapql
    event.emmit(Constants.EVENTS.DOCUMENTS_REFRESH, {})
    const mutationName = Object.keys(data)[0];
    data[mutationName].groupCompanies.map(gcp => {
        cache.modify({
            id: cache.identify(gcp.groupCompany),
            fields: {
                documents(existingDocumentRefs = [], { readField }) {
                    const newDocumentRef = cache.writeFragment({
                        data:data[mutationName],
                        fragment: gql`
                      fragment NewDocument on Document{
                        id
                      }
                   `});
                    if (existingDocumentRefs.some(
                        ref => readField('id', ref) === data[mutationName].id
                    )) {
                        return existingDocumentRefs;
                    }

                    return [...existingDocumentRefs, newDocumentRef];
                }
            }
        });

    })
    data[mutationName].stakeholders.map(sh => {
        cache.modify({
            id: cache.identify(sh),
            fields: {
                documents(existingDocumentRefs = [], { readField }) {
                    const newDocumentRef = cache.writeFragment({
                        data: data[mutationName],
                        fragment: gql`
                    fragment NewDocument on Document{
                      id
                    }
                 `});

                    if (existingDocumentRefs.some(
                        ref => readField('id', ref) === data[mutationName].id
                    )) {
                        return existingDocumentRefs;
                    }

                    return [...existingDocumentRefs, newDocumentRef];
                }
            }
        });

    })
    cache.evict({
        id: "ROOT_QUERY",
        broadcast: false
      });
    cache.gc()


}

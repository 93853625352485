import * as Yup from 'yup'


const schemaProposal = Yup.object().shape({
	engagementItems: Yup.array().of(Yup.object().shape({
		amount: Yup.number()
			.nullable()
			.typeError('Is a number')
			.min(0, 'Is value a positive')
			.required('Required'),
		description: Yup.string()
			.nullable()
			.required('Required'),
		comments: Yup.string()
			.nullable()
			.required('Required')
	})).nullable().min(1).required('Required')
});

const schemaEngagement = Yup.object().shape({
	engagementItems: Yup.array().of(Yup.object().shape({
		amount: Yup.number()
			.nullable()
			.typeError('Is a number')
			.min(0, 'Is value a positive')
			.required('Required'),
		description: Yup.string()
			.nullable()
			.required('Required'),
		comments: Yup.string()
			.nullable()
			.required('Required')
	})).nullable().min(1).required('Required')
});

export {
    schemaProposal,
    schemaEngagement
}

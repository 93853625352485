import React from 'react';
import { AuditLogs } from '../../../../v1/containers';
const AuditLogsView = (props) => {
   
    return (
        <React.Fragment>
            <AuditLogs />
        </React.Fragment >
    )
}

export default AuditLogsView;

import React, {FC} from 'react'
import WarrantMachine from "./WarrantMachine";
import Wizard from "../../../../../../generic/components/Wizard";
import ModalAssistant, {ModalAssistantProps} from "../../../../../../generic/components/Modal/ModalAssistant";
import useModal from "../../../../../../generic/hooks/useModal"
interface ModalWarrantProps extends ModalAssistantProps{
    initialValues:object
}
const ModalWarrant:FC<ModalWarrantProps>=({initialValues={}, open = false, ...props })=>{

    const {closeModal} = useModal();
    const handleClose = () => {
        closeModal();
    }
    return(
        <ModalAssistant open={open}>
            <Wizard initialValues={initialValues} machine={WarrantMachine} onComplete={handleClose}/>
        </ModalAssistant>)
}
export default ModalWarrant
import React, { FC, useState } from 'react';
import ModalAssistant from '../../../generic/components/Modal/ModalAssistant';
import Wizard from '../../../generic/components/Wizard/v2';
import useModal from "../../../generic/hooks/useModal";
import { clearAssistatText as clearAssistantText } from "../../../generic/store/action";
import useActions from './hooks/useActions';
import useGuards from './hooks/useGuards';
import useServices from './hooks/useServices';
import useSteps from './hooks/useSteps';
import machine from './machine';

interface Props {
  open: boolean
  initialValues: any
}

const ModalEditQuestion:FC<Props> = ({
  open: openParam = false,
  initialValues
}) => {

  const [open, setOpen] = useState(openParam);
  const { closeModal: onClose } = useModal();
  const guards = useGuards();
  const steps = useSteps();
  const actions = useActions();
  const services = useServices({initialValues});

  const handleStopMachine = () => {
    onClose();
    setOpen(false);
    clearAssistantText();
  }

  return (
    <ModalAssistant open={open}>
      <Wizard
        guards={guards}
        machine={machine}
        steps={steps}
        services={services}
        actions={actions}
        onStopMachine={handleStopMachine}
      />
    </ModalAssistant>
  );
};

export default ModalEditQuestion;
import React from 'react';
import classes from './FilterMenuMessages.module.scss';
import _ from 'lodash'
const FilterMenuMessages = ({ itemsMenu = [], handleSelectFilter, filterSelect, closePopover, ...props }) => {


    const handleSelectItem = (item) => {

        item.value ? handleSelectFilter(item) : handleSelectFilter({ key: "dateNewest", label: "Date (Newest)", value: "NEWEST" });
        closePopover();
    }


    return (
        <div className={classes.containerFilterMenuMessages}>
            <label className={classes.titleFilterMenuMessages} >Filter results by:</label>
            {itemsMenu.map((item, index) => {
                return (
                    <React.Fragment key={item.key}>
                        {_.has(item, 'RenderElement') ?
                            item.RenderElement({ handleSelectItem, filterSelect, handleChangeType: handleSelectFilter }) :
                            <div className={classes.itemFilterMenuMessages} onClick={() => handleSelectItem(item)}>
                                {item.label}
                            </div>

                        }
                    </React.Fragment>

                )
            })}

        </div>
    )
}

export default FilterMenuMessages;
import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import * as Yup from 'yup';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText,addAssistantText } from 'src/modules/generic/store/action';
import Typography from 'src/modules/generic/components/Texts/Typography';
import CheckboxButtonSimple from 'src/modules/generic/components/Inputs/CheckboxButtonSimple';
import classes from './classes.module.scss';

function BusinessPlanStep() {
  const { context, cancel, next } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.BusinessPlanStep ?? {}
  }, [context]);
  
  const handleCancel = () => {
    cancel();
  }

  const handleSubmit = async (values) => {
    next(values);
  }

  useEffect( ()=>{
    dispatch(addAssistantText('An application to incorporate an ADGM Tech Startup License requires a detailed business plan that includes information about the company’s business concept, financials, projected milestones and the intended UAE operations. Click <a rel=\'noopener noreferrer\' target=\'_blank\' href=\'https://help.clara.co/en/articles/6419600-what-do-i-need-to-include-in-my-tech-startup-business-plan\'>here</a> for the full outline of the business plan. <br/> <br/> If you have already been in contact with ADGM and had your business plan pre-approved, then we can skip this step. If you haven’t, then our first step will be to share your pitch deck and business plan with ADGM for pre-approval.'));

    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch]);

  const schema = Yup.object().shape({
    preApproved: Yup.boolean().nullable().required('Required'),
  })

  const options = [
    {
      value: false,
      component: ButtonText,
      componentProps: {
        title: 'No',
      },
    },
    {
      value: true,
      component: ButtonText,
      componentProps: {
        title: 'Yes',
      },
    },
  ];

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: 'Has your business plan been pre-approved by ADGM?',
          skeleton: null,
          subTitle: 'Business plan',
          variant: 'simple'
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>{translate('BUTTON_NEXT')}</SubmitButton>,
        }}
      >
        <ControllerInput
          render={CheckboxButtonSimple}
          classNameButton={classes.BusinessPlanCheckboxButton}
          name='preApproved'
          variant='col_1'
          options={options}
        />
      </TemplateWizard>
    </Form>
  );
}

export default BusinessPlanStep;

const ButtonText = ({ title }) => {
  return (
    <div>
      <Typography variant='body'>
        {title}
      </Typography>
    </div>
  );
};


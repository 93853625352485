
import _ from "lodash";
import { useEffect, useState } from "react";
import useFetch from '../../generic/hooks/useFetch';

const useCheckoutApi = (source) => {
    const [keys, setKeys] = useState({});
    

    const onCompleted = (response) => {

        setKeys(response);
    }

    const { refetch, loading } = useFetch({
        url: `/checkout/keys/${source}`, onCompleted, cancelAutimaticFetch: true,
      
    });

    useEffect(() => {
        if (source){
            refetch();
        }
    }, [refetch, source])
    
    return { keys,loading: loading || keys == null};
}

export default useCheckoutApi;
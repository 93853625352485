import React, {Component} from "react";
import {connect} from "react-redux";
import classes from "./ClaraInputCheckBoxGroup.module.scss"
import {Constants} from "../../../utils/constants";
import _ from "lodash";

class ClaraInputCheckBoxGroup extends Component {
    state = {
        tooltipText: null,
        placeHolder: null,
        validation: {},
        label: null,
        defaultValue: null,
        errorMessage: null,
        showError: false,
        value: [],
        options: []
    };
    /* React Methods */
    componentWillUpdate(prevProps, prevState, snapshot) {
        if (prevProps.session !== this.props.session) {

        }
    }
    componentWillMount() {
        const { tooltipText, placeHolder, validation, label, defaultValue, name, options, valueAccessKey, value } = this.props;
        console.log("Value", value);
        this.setState({
            tooltipText,
            placeHolder,
            validation,
            label,
            defaultValue,
            name,
            options,
            value: (!value) ? [] : value
        }, () => {
            this.managerValidation();
        });




    }


    /* Logic */
    getError(type, validation) {
        validation = this.state.validation[type];
        if (validation.errorMessage) {
            return validation.errorMessage
        }
        return Constants.DEFAULT_MESSAGES.VALIDATION[type];
    }

    /* Functionality */
    validate(vec) {
        if (this.state.validation && this.state.validation.required) {
            if (this.state.validation.required.value === true && (vec.length === 0)) {
                return this.getError("required")
            }
        }
        return null;
    }

    managerValidation() {
        let { value, errorMessage } = this.state;
        errorMessage = this.validate(value);
        this.setState({
            errorMessage: errorMessage,
        });

        this.props.updateData(this.state.name, value, errorMessage === null, this.props.index);
    }

    /* Managaer Events*/
    handleChange = (e) => {
        let { value } = this.state;
        const { checkingObject } = this.state;
        const valueSelect = e.target.value;
        let index = _.findIndex(value, function (o) { return o == valueSelect; });
        if (index > -1) {
            value.splice(index, 1);
        }else{
            value.push(valueSelect)
        }
        this.setState({
            value
        },()=>{
            this.managerValidation();
        });
        

    };



    /* Section to get dinamic components*/
    componentJSX_isRequired() {
        if (this.state.validation && this.state.validation.required && this.state.validation.required.value === true) {
            return <span className={`required`}>*</span>
        }
    }
    componentJSX_getErrorMessage() {
        if (this.state.errorMessage && this.state.showError) {
            return (
                <div className='errorClaraInput'>
                    {this.state.errorMessage}
                </div>
            )
        }
        return null;
    }

    isCheckedComponent(id) {
        let { value } = this.state;
        let index = _.findIndex(value, function (o) { return o == id; });
        return index >= 0;
    }
    componentJSX_getCheckbox() {
        const { checkingObject = {} } = this.state;
        if (this.state.options && this.state.options) {
            return this.state.options.map((option, i) => {

                return (

                    <div className={classes.CheckBox} key={i}>
                        <input
                            type='checkbox'
                            checked={this.isCheckedComponent(option.id)}
                            value={option.id}
                            key={i}
                            onChange={(e) => this.handleChange(e)}
                            disabled={this.props.disabled}
                        />
                        <label>{option.label}</label>
                    </div>

                )
            })
        }
        return null;
    }
    render() {

        return (
            <div className={classes.ClaraInputCheckBox}>

                <label className={classes.label}>
                    {this.state.label}
                    {this.componentJSX_isRequired()}
                </label>
                <div className={classes.contentCheckBox}>
                    {this.componentJSX_getCheckbox()}
                </div>
                {this.componentJSX_getErrorMessage()}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    session: state.session
});
const mapDispatchToProps = (dispatch) => ({

});
export default connect(mapStateToProps, mapDispatchToProps)(ClaraInputCheckBoxGroup)

import _ from "lodash";
import ServerConnect from "./ServerConnect";


const renameKey = (a, map) => {
    var b = {};
    _.each(a, function (value, key) {
        key = map[key] || key;
        b[key] = value;
    })
    return b;
};

const stripTypenames = (value) => {
    if (Array.isArray(value)) {
        return value.map(stripTypenames)
    } else if (value !== null && typeof (value) === "object") {
        const newObject = {}
        for (const property in value) {
            if (property !== '__typename') {
                newObject[property] = stripTypenames(value[property])
            }
        }
        return newObject
    } else {
        return value
    }
}
const stripField = (value,field) => {
    if (Array.isArray(value)) {
        return value.map(element=> stripField(element,field));  
    } else if (value !== null && typeof (value) === "object") {
        const newObject = {}
        for (const property in value) {
            if (property !== field) {
                newObject[property] = stripField(value[property],field)
            }
        }
        return newObject
    } else {
        return value
    }
}


const getCountries = () => {

    const query = `{countryList(code:"", name:"", language:"en"){
            name
            code
            alpha3
            jurisdictions {name}

        }}`

    return ServerConnect.graphQlQuery(query)
        .then(result => {

            return result.countryList.map(country => ({label: country.name, id: country.code, jurisdictions : country.jurisdictions}))

        })
        .catch(err => {
            console.log("Error getting countries from service");
        });

}

const modifyDataFileKYC = (values) => {
    var newValues = _.forOwn(values, (val, key) => {
        if (Array.isArray(val)) {
            return val.map(modifyDataFileKYC)
        }else{
            if (_.hasIn(val, 'file')) {
                return values[key] = val.file
            }
        }
        
    })
    return newValues

}

export {
    stripTypenames,
    stripField,
    renameKey,
    getCountries,
    modifyDataFileKYC
}
import { useMemo } from "react";
import TemplateWizardSkeletonMachine from "../../../../generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine";
import { BoardResolutionTypeDocument, AddBoardResolutionDocument, GotoGenerateDocumentFlow } from "../steps";

const useSteps = () => {

  return useMemo(() => ({
    initialData: {
      render: TemplateWizardSkeletonMachine,
      renderProps: {
        title: "",
        subTitle: ""
      }
    },
    getGroupCompaniesData: {
      render: TemplateWizardSkeletonMachine,
      renderProps: {
        title: "",
        subTitle: ""
      }
    },
    selectBoardResolutionTypeDocument: {
      render: BoardResolutionTypeDocument,
    },
    addBoardResolutionDocument: {
      render: AddBoardResolutionDocument,
    },
    goToGenerateDocumentFlow: {
      render: GotoGenerateDocumentFlow,
    },
    uploadDocumentBoardResolution: {
      render: TemplateWizardSkeletonMachine,
      renderProps: {
        title: "",
        subTitle: ""
      }
    },
  }), []);
}

export default useSteps;
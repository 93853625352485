import React, {useEffect, useState} from 'react';
import {ClaraCheckForm, ClaraUpload, Text, TextAreaInput} from '../index';
import _ from "lodash";
import PropTypes from 'prop-types';
import {Field} from "formik";
import classes from "./Chat.module.scss";
import FactoryChatForm from './FactoryChatForm';
import FactoryChatFormConfig from './FactoryChatFormConfig.js';
import useQuestionnaireSPVAlreadyIncorporatedCompanies
  from '../../Hooks/useQuestionnaireSPVAlreadyIncorporatedCompanies';

const OPTION1 = 1;
const OPTION2 = 1;
const OPTION3 = 1;
const ChatPrimaryAsset = ({ responseKeyName, isForm, config, initialValueStart, className }) => {
  const nameField = responseKeyName ? responseKeyName + "." : "";
  const [ADGM, setADGM] = useState(false);
  const { data } = useQuestionnaireSPVAlreadyIncorporatedCompanies(initialValueStart.matterId, initialValueStart.draftId)
  const [optionsButtons, setOptionsButtons] = useState([
    { 'label': 'TEXT_YES', 'value': true },
    { 'label': 'TEXT_NO', 'value': false }
  ])

  const options = {
    INTELLECTUAL_PROPERTY: { keySubmit: "intellectualProperty", key: "INTELLECTUAL_PROPERTY", need: "showIntellectualProperty" },
    SHARES_ALREADY_COMPANY: { keySubmit: "sharesAlreadyCompany", key: "SHARES_ALREADY_COMPANY", need: "showSharesAlreadyCompany" },
  }
  const publicValues = (values, field, form) => {
    form.setFieldValue(field.name, values);
  }
  const selectButton = (op, field, form) => {
    if (field.value) {
      
      let primaryAsset = {...form.values.newSPVPrimaryAsset.primaryAsset}
      delete primaryAsset[op.key];
      
      switch (op.key) {
        case "INTELLECTUAL_PROPERTY":
          form.setFieldValue(nameField + "intellectualPropertyFile", undefined);
          form.setFieldValue(nameField + "intellectualPropertyText", "");
          break;
        case "SHARES_ALREADY_COMPANY":
          form.setFieldValue(nameField + "alreadyCompaniesIds", undefined);

          break;

      }
      form.setFieldValue(nameField + "primaryAsset", primaryAsset,true);
    } else {
      publicValues(op.key, field, form);
    }


  }
  const getContentOption = (key, show) => {

    switch (key) {
      case options.INTELLECTUAL_PROPERTY.key:

        return (
          <div className={show ? `${classes.show} ${_.get(className, "showOptionAssetsHeld")} ` : classes.hidden}>
            {isForm ?
              <Field name={nameField + "intellectualPropertyText"}>
                {({ field, form }) => (
                  <TextAreaInput
                    required={true}
                    field={field}
                    label={"IP holding explanation:"}
                    form={form}
                    autoComplete='off'
                    placeholder={"QUESTIONARY_FORM_SPV_PRIMARY_ASSET_INTELLECTUAL_PROPERTY_STATE_WHY_PLACEHOLDER"}
                    showRequired={false}
                    className={className.ChatInputTextarea}
                  />
                )}
              </Field> : <>
                <p><Text uuid={"QUESTIONARY_FORM_SPV_PRIMARY_ASSET_INTELLECTUAL_PROPERTY_NOTE"} /></p>


                <div className={classes.contentField}>
                  <p><Text uuid={"QUESTIONARY_FORM_SPV_PRIMARY_ASSET_INTELLECTUAL_PROPERTY_FILE_LABEL"} /></p>
                  <Field name={nameField + "intellectualPropertyFile"}>
                    {({ field, form }) => (
                      <ClaraUpload
                        showRedStar={false}
                        required={true}
                        name={`filepond`}
                        field={field}
                        form={form}
                        className={classes.ChatClaraUpload}
                      />
                    )}
                  </Field>
                </div>
              </>
            }
          </div>
        );
      case options.SHARES_ALREADY_COMPANY.key:
        return (
          <div className={show ? `${classes.show} ${_.get(className, "showOptionAssetsHeld")} ` : classes.hidden}>
            <div className={classes.contentField}>
              <Field name={nameField + "alreadyCompaniesIds"}>
                {({ field, form }) => (
                  <ClaraCheckForm
                    field={field}
                    form={form}
                    list={data}
                    optionsList={{
                      fieldId: "_id",
                      fieldLabel: _.get(FactoryChatFormConfig, "COMPANY_QUESTIONNAIRE_FORM_CORPORATE.getAvatarLabel", null),
                    }}
                    optionsForm={{
                      titleKey: _.get(FactoryChatFormConfig, "COMPANY_QUESTIONNAIRE_FORM_CORPORATE.titleKey", null),
                      educationId: _.get(FactoryChatFormConfig, "COMPANY_QUESTIONNAIRE_FORM_CORPORATE.leftEducationId", null),
                      muttation: `mutation upsertQuestionnaireAlreadyIncorporatedDraftFlow(
                        $matterId: String!
                        $KYCCompanyData: Object! 
                        $KYCCompanyId: String,
                        $draftId: ID!
                      ) {
                        upsertQuestionnaireAlreadyIncorporatedDraftFlow(matterId: $matterId, KYCCompanyData: $KYCCompanyData, KYCCompanyId: $KYCCompanyId, draftId: $draftId) {
                          id
                          companyName
                        }
                      }
                      `,
                      getIdFromMuttation: (result) => {
                        return _.get(result, "upsertQuestionnaireAlreadyIncorporatedDraftFlow.id");
                      },
                      paramsMuttaion: initialValueStart,
                      getVariablesMutation: _.get(FactoryChatFormConfig, "COMPANY_QUESTIONNAIRE_FORM_CORPORATE.getVariablesMutation", null),
                      succesNotification: "NOTIFICATION_SUCCESS",
                      errorNotification: "NOTIFICATION_BAD",
                    }}
                    optionsSelect={{
                      auto: true,
                      canSelect: true,
                    }}
                    optionsOperation={{
                      canAdd: true,
                      canEdit: false,
                      canRemove: false,

                    }}
                    optionsView={{
                      recordsByLine: 4
                    }}
                    className={{
                      root: classes.root
                    }}
                    initialValues={{ formKey: "COMPANY_QUESTIONNAIRE_FORM_CORPORATE" }}
                  >
                    <FactoryChatForm formKey={"COMPANY_QUESTIONNAIRE_FORM_CORPORATE"} propsForm={{ isFlow: true, draftId: initialValueStart.draftId }}></FactoryChatForm>
                  </ClaraCheckForm>
                )}
              </Field>
            </div>
          </div>
        );

    }
  }
  useEffect(() => {

  })
  return (
    <React.Fragment>
      <div className={`${classes.ChatQuestionnaire} ${isForm ? _.get(className, "ChatPrimaryAsset") : classes.ChatPrimaryAsset}`}>

        {_.map(options, (option, key) => (
          <Field name={`${nameField}primaryAsset.${option.key}`}>
            {({ field, form }) => {
              const classSelected = option.key === field.value ? classes.buttonOptionSelected : null;
              const showContent = option.key === field.value;
              const showElement = _.get(config, option.need, false);
              if (!showElement) {
                return null;
              }
              return (
                <div className={`${_.get(className, "containerButtonsOptions")}`} key={key}>
                  {isForm && key === field.value && <label>Assets held:</label>}
                  <button className={`${classes.buttonOption} ${classSelected}`} type="button" onClick={() => { selectButton(option, field, form) }}>
                    <Text uuid={`QUESTIONARY_FORM_SPV_PRIMARY_ASSET_${option.key}`} />
                  </button>
                  {getContentOption(option.key, showContent)}
                </div>
              )
            }}
          </Field>
        ))}
      </div>
    </React.Fragment >
  );
}
ChatPrimaryAsset.propTypes = {
  responseKeyName: PropTypes.string.isRequired,
  initialValueStart: PropTypes.object.isRequired,

};
export default ChatPrimaryAsset;

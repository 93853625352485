import get from 'lodash/get';

const useGuards = () => {
  const isConfirm = (context) => {
    return get(context, 'isAllShareholdersAndDirectorsConfirmed', false);
  }

  const isNotConfirm = (context) => {
    return !isConfirm(context);
  }

  const isUpload = (context) => {
    return get(context, 'isUpload', false);
  }

  const isNotUpload = (context) => {
    return !isUpload(context);
  }

  const isDefaultValues = (context) => {
    return get(context, 'isDefaultValues', false);
  }

  const isNotDefaultValues = (context) => {
    return !isDefaultValues(context);
  }

  const isUploadFlow = (context) => {
    return get(context, 'isUpload', false);
  }

  const isNotUploadFlow = (context) => {
    return !isUploadFlow(context);
  }

  const isDefaultValuesFlow = (context) => {
    return get(context, 'isDefaultValues', false);
  }

  const isNotDefaultValuesFlow = (context) => {
    return !isDefaultValuesFlow(context);
  }

  return {
    isConfirm,
    isNotConfirm,
    isUpload,
    isNotUpload,
    isDefaultValues,
    isNotDefaultValues,
    isUploadFlow,
    isNotUploadFlow,
    isDefaultValuesFlow,
    isNotDefaultValuesFlow
  }
}
export default useGuards;
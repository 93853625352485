import _ from "lodash";
import React, { Component, useState, useEffect } from "react";
import FormikHelper from "../../../utils/formikHelper";
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { Notifications, TooltipInput } from "../../index";
import CircularProgress from '@material-ui/core/CircularProgress';
import ServerConnect from "../../../utils/ServerConnect";
import helper from "../../../utils/helper";
import classes from './ClaraSelect.module.scss';
import onClickOutside from 'react-onclickoutside';
import Icon from "./../../../../modules/generic/components/Icon";
import ClaraFlag from '../../../../images/Clara-flag.png'
import {isNilCustom} from '../../../../utils/helpersValidate';

/*
# Classic example

    <ClaraSelect
        required={true}
        field={field formik}
        form={form formik}
        label={"Label: "}
        placeholder={"placeholder"}
        lists={_.times(4, Number).map(element => {
            return { id: element, label: element }
        })}
        mode={"classic"}
        disabled={this.state.claraSelect.disabled}
        callbacks={this.state.claraSelect.callbacks}
        tooltip={this.state.claraSelect.tooltip}
    />
                                                    
*/

class ClaraSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showTooltip: false,
            isFocused: false,
            open: false,
            openAdd: false,
            formToAdd: null,
            filter: "",
            labelSelect: "",
            textToSave: "",
            placeholderTextToSave: "",
            hasFocus: false,
            hasMouse: false,
            lostFocus: false,
            value: null,
            inputAdd: null,
            elementToSave: null,
            link: null,
            itWasFocused: false,
            positionLink: -1,
            textWarning: null,
            showLoading: false,
            lists: [],
            mappers: {
                id: "id",
                label: "label"
            },
            firstLoad: true,
            save: "name",
            mounted: false
        };
    }
    updateMapLists(lists, mode) {
        switch (mode) {
            case "multiselect":
                let mapLists = {};
                _.forEach(lists, (list) => {
                    if (!mapLists[list.id]) {
                        mapLists[list.id] = {
                            label: list.label,
                            subList: {}
                        }
                    }

                    _.forEach(list.list, (subList) => {
                        mapLists[list.id].subList[subList.id] = subList.label;
                    });
                })
                this.setState({
                    mapLists
                })
                break;
        }
    }


    /* Componets REat */
    componentDidMount() {
        let { lists, mappers, mode } = this.props;
        let mappersDefault = this.state.mappers;
        mappers = _.merge(mappersDefault, mappers);
        lists = this.getListByMode(lists, mode);
        this.setState({
            lists,
            mappers
        })
        if (_.get(this.props, "mounted") == true && _.get(this.props, "field.value")) {
            const { field, form } = this.props;
            this.setState({ mounted: false });
            try {
                form.validateField(field.name)
            } catch (e) {

            }
        }
    }
    componentWillUpdate(nextProps, nexState) {
        let newState = {
            firstLoad: false
        };

        if (!_.isEqual(this.props.lists, nextProps.lists)) {
            this.updateMapLists(nextProps.lists, nextProps.mode);
        }
        if (nextProps.clearSelectAndFilter && !this.props.clearSelectAndFilter || (!nextProps.field.value && this.props.field.value )) {
            this.setState({
                value: null,
                link: null,
                filter: "",
                labelSelect: "",
                positionLink: -1
            })
        }

        if (nextProps.shouldClearValue && !this.props.shouldClearValue) {
            this.clearValueAndFilter(nextProps);

        }


        if (this.state.firstLoad || nextProps.lists !== this.props.lists || _.get(nextProps, "field.value") !== _.get(this.props, "field.value")) {
            newState.lists = this.getListByMode(nextProps.lists, nextProps.mode);
            let value = _.get(nextProps, "field.value");
            if (value !== null && value !== undefined) {
                let elementFined = null;
                let listFind = _.find(newState.lists, (element) => {
                    switch (nextProps.mode) {
                        case "classic":
                            elementFined = _.find(_.concat(element.list, this.props.adds), (l2) => {
                                return _.get(l2, 'id') === value;
                            })
                            return elementFined;
                        case "multiselect":
                            elementFined = {}
                            const elements = []
                            _.forEach(value, val => {
                                const fined = _.find(element.list, (list) => {
                                    return val && val[list.listName] === list.listId && val[list.subListName] && val[list.subListName].indexOf(list.id) >= 0;
                                })
                                elements.push(fined)
                            })
                            let { mapLists } = this.state;
                            let labelSelect = '';
                            if (elements && mapLists) {
                                _.forEach(value, (list, index) => {
                                    if (_.get(mapLists[list[elements[index].listName]], 'label', '')) {
                                        labelSelect = labelSelect.concat(_.get(mapLists[list[elements[index].listName]], 'label', ''), " [ ");
                                        _.forEach(list[elements[index].subListName], (subList, i) => {
                                            if (i > 0) {
                                                labelSelect = labelSelect.concat(", ");
                                            }
                                            labelSelect = labelSelect.concat(mapLists[list[elements[index].listName]]["subList"][subList]);
                                        })
                                        labelSelect = labelSelect.concat(" ] ");
                                    }
                                })
                                _.set(elementFined, 'labelSelect', labelSelect)
                                _.set(elementFined, 'label', '')
                            }
                            return elementFined;
                        default:
                            if (element.type === value.type) {
                                elementFined = _.find(element.list, (l2) => {
                                    return l2.id === value.id;
                                })
                                return elementFined;
                            }
                            return false;

                    }

                })
                if (!listFind) {
                    // this.clearValue(nextProps);
                    newState.filter = "";
                } else {
                    if (value !== this.state.value) {
                        newState.value = value;
                        if (elementFined.labelSelect) newState.labelSelect = elementFined.labelSelect
                        newState.filter = elementFined.label ? elementFined.label : "";
                        let callback = _.get(this.props, "callbacks.updateValues", (e) => { });
                        if (callback) {
                            callback(value, newState.filter, nextProps.form, this.props.paramsToCallbacks, this.props.field, this.props.form.setFieldValue);
                        }
                    }
                }
            }

            this.setState(newState);
        }

    }



    componentWillMount() {
        const docType = this.props.docType

        if (this.props.fromGenerateDoc && docType !== null) {
            this.props.form.setFieldValue("documentType", String(docType));
        }
        this.updateMapLists(this.props.lists, this.props.mode);
        this.setState({ mounted: true });
    }

    /* Tooltip */


    setFocused = (itWasFocused) => {
        this.setState({
            itWasFocused
        })
    }

    setTooltipVisibility = (isFocused) => {
        this.setState({
            isFocused
        })
    };
    /* Set Data */
    setInput = (input) => {

        if (!this.input) {
            this.input = input;
        }

    }
    setInputAdd = (inputAdd) => {

        if (!this.inputAdd) {
            this.inputAdd = inputAdd;
        }

    }
    setRefModal = (modal) => {
        if (modal) {
            this.modal = modal;
        }

    }
    showLoading = (status) => {
        this.setState({
            showLoading: status
        });
    }

    /* Get Status */
    hasErrorForm() {
        const { form, field } = this.props;
        // if (field && form) {
        //     if (this.state.itWasFocused && !this.state.hasFocus && !this.state.open) {
        //         return FormikHelper.returnErrorsForValidation(field.name, form.errors);
        //     } else {
        //         return null
        //     }
        // }

        if (field && form) {
            return FormikHelper.returnErrorsForValidation(field.name, form.errors) || this.props.hasError;
        } else {
            return null
        }
    }
    /*hasErrorComponent() {
        const { hasFocus, lostFocus, value, filter, openAdd } = this.state;
        return !openAdd && lostFocus && !hasFocus && value == null && (filter !== "")
    }*/
    getError() {
        const { form, field } = this.props;
        /* if (this.hasErrorComponent()) {
             return "Record not found";
         }*/
        return FormikHelper.returnErrorsForValidation(field.name, form.errors);

    }
    getId = (type, i) => {
        return `${type}_${i}`;
    }
    getClassLink = (type, i) => {
        let { link } = this.state;
        return link === this.getId(type, i) ? classes.Selected : null;
    }

    /* Handlers */
    handleClickOutside = () => {
        this.setState({ open: false })
    }
    handleKeyPress = (key, e) => {
        let { positionLink } = this.state;
        let links = this.modal ? this.modal.querySelectorAll("a") : [];
        switch (key) {
            case "up":
                positionLink--;
                if (positionLink < 0) {
                    positionLink = links.length - 1;
                }
                this.setState({
                    positionLink,
                    link: this.modal.querySelectorAll("a")[positionLink].id
                });
                break;
            case "down":
                positionLink++;
                if (positionLink >= links.length) {
                    positionLink = 0
                }
                this.setState({
                    positionLink,
                    link: this.modal.querySelectorAll("a")[positionLink].id
                });
                break;
            case "enter":
                if (positionLink >= 0) {
                    this.modal.querySelectorAll("a")[positionLink].click();
                }
                break;
            case "esc":
                this.input.blur();
                break;
            case "tab":
            case "shift+tab":
                this.setState({ open: false })
                break;
        }
    }
    handleFocus = (e) => {

        if (!_.get(this.props, "field.value")) {
            this.setState({
                open: true,
                openAdd: false,
                formToAdd: null,
                hasFocus: true,
            })
        }
        this.setTooltipVisibility(true);
    }
    handleBlur = (e) => {
        const { hasMouse, hasFocus } = this.state;
        let newSate = {
            hasFocus: false,
            lostFocus: true,
            itWasFocused: true
        }
        if (!hasMouse) {
            newSate.open = false;
            newSate.openAdd = false;
        }
        this.setState(newSate)
    }
    handleClose = () => {
        this.setState({ open: false })
    };
    handleChange = (e) => {

        this.setState({
            open: true,
            filter: e.target.value,
            link: null,
            positionLink: -1
        })
        this.clearValue(this.props);
    }
    handleChangeInputSave = (e) => {
        this.setState({
            textToSave: e.target.value
        })
    }

    handleBlurInputSave = (e) => {
        this.setFocused(true);
        // Remove all spaces at start and end of the string
        e.target.value = e.target.value.trim();
        this.handleChangeInputSave(e);
    };

    handleMouseOverModal = (e) => {
        const { hasMouse, hasFocus } = this.state;
        if (!hasMouse) {
            this.setState({
                hasMouse: true
            })
        }
    }
    handleMouseOutModal = (e) => {
        const { hasMouse, hasFocus } = this.state;
        if (hasMouse) {
            let newState = {
                hasMouse: false
            };
            this.setState(newState)
        }
    }
    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.submit();
        }
    }
    /* Actions */
    clearValue = async (props) => {
        let { form, field } = props;
        this.setValues(form, field, null, null, {});
        this.setState({
            value: null,
            link: null,
            positionLink: -1
        })
    }

    clearValueAndFilter = async (props) => {
        let { form, field } = props;
        this.setValues(form, field, null, null, {});
        this.setState({
            value: null,
            link: null,
            filter: "",
            labelSelect: "",
            positionLink: -1
        })
    }

    selectAdd = () => {};

    selectItem = async (type, element) => {
        let { form, field } = this.props;

        let value = {
            type,
            id: element.id
        };

        this.setValues(form, field, value, null, element);
        this.handleClose();
        this.setState({
            value,
            filter: element.label,
            link: null
        });
        element.onClick && element.onClick()
    };

    addItem = (element) => {
        const { filter } = this.state;
        this.clearValue(this.props);
        this.setState({
            open: false,
            openAdd: true,
            formToAdd: element.form ? element.form({
                "submit": this.submit,
                "submitAfterQuery": this.submitAfterQuery,
                "submitBeforeQuery": this.submitBeforeQuery,
                "formProps": element.formProps,
            }) : null,
            placeholderTextToSave: element && element.placeholder ? element.placeholder : "",
            elementToSave: element,
            textToSave: filter,
            filter: "",
            save: element.textToSave ? element.textToSave : "name",
            textWarning: element.textWarning ? element.textWarning : null
        }, () => {
            if (this.inputAdd) {
                this.inputAdd.focus();
            }
        })
    }

    submitDefault = (e) => {
        this.submit();
    }

    submit = (elementsForms) => {
        let { elementToSave, textToSave, lists, save } = this.state;
        const { form, field, adds } = this.props;

        let type = elementToSave.type;
        let typeUpper = type.charAt(0).toUpperCase() + type.slice(1);
        let additionalParams = elementToSave.additionalParams ? elementToSave.additionalParams.map(param => `,$${param.paramName}:${param.paramType}`).join(" ") : "";
        let additionalParamsMutation = elementToSave.additionalParams ? elementToSave.additionalParams.map(param => `,${param.paramName}:$${param.paramName}`).join(" ") : "";
        let additionalParamsData = {};

        if (elementToSave.additionalParams) {
            elementToSave.additionalParams.forEach(param => {
                _.assign(additionalParamsData, { [param.paramName]: param.paramValue })
            });
        }
        const stateApplication = _.get(elementToSave, "stateApplication", "startupId");
        let data = {
            [stateApplication]: elementToSave[stateApplication],
            [type]: null,
            ...additionalParamsData
        };
        let typeVar = "String";
        if (elementToSave.params || elementsForms) {
            data[type] = {
                ...elementToSave.params,
                ...elementsForms
            };
            if (!_.get(elementsForms, `${save}`, null)) {
                _.set(data[type], `${save}`, textToSave)
            }
            typeVar = `Component${typeUpper}InputType`;
            textToSave = data[type][`${save}`];
            if (!textToSave) delete data[type][`${save}`];
        } else {
            data[type] = textToSave;
        }

        const paramsToReturn = elementToSave.paramsToReturn ? elementToSave.paramsToReturn : (elementToSave.paramsReturn || "");

        let query = `
        mutation upsertComponent${typeUpper}($${stateApplication}: ID!, $${type}: ${typeVar}! ${additionalParams} ) {
              upsertComponent${typeUpper}(${stateApplication}: $${stateApplication}, ${type}: $${type} ${additionalParamsMutation}) {
                  id ${paramsToReturn}
              }
          }`;

        this.setState({
            openAdd: false,
            formToAdd: null,
            showLoading: true
        });
        this.submitBeforeQuery();

        let options = {
            cacheDisabled: false,
            onQueryUpdated: (() => { }),
            update: (() => { })
        };

        ServerConnect.graphQlMutation(query, data, options).then((result) => {

            let id = result[`upsertComponent${typeUpper}`].id;
            let newElementList = { id, label: textToSave };

            this.submitAfterQuery(newElementList, result[`upsertComponent${typeUpper}`], textToSave);

        })
    }
    submitBeforeQuery = () => {
        this.setState({
            openAdd: false,
            formToAdd: null,
            showLoading: true
        })
    }
    submitAfterQuery = (newElementList, result, textToSave) => {

        const { form, field } = this.props;
        const { elementToSave, lists } = this.state;

        let value = {
            type: elementToSave.type,
            id: newElementList.id
        }

        if (!lists.length) {
            lists.push(newElementList)
        } else {

            _.forEach(lists, (elementList, i) => {

                if (elementList.type === elementToSave.type) {
                    elementList.list.push(newElementList)
                }
            })

        }

        this.setState({
            textToSave: "",
            filter: textToSave,
            lists,
            value: value,
            openAdd: false,
            formToAdd: null,
            showLoading: false
        }, () => {

            let callback = _.get(this.props, "callbacks.createValues", (e) => { });
            callback(value, textToSave, result, this.props.form);
            this.setValues(form, field, value, result, elementToSave);
        })
    };
    setValues = async (form, field, newValue, additionalData, element) => {

        if (this.props.mode === "classic" && newValue) {
            newValue = newValue.id;
        }
        /* Set multiselect */
        if (form) {

            try {
                await form.setFieldValue(field.name, newValue);
                form.validateField(field.name)
            } catch (e) {

            }

        }

        let callback = _.get(this.props, "callbacks.updateValues", (e) => { });
        callback(newValue, additionalData, form, this.props.paramsToCallbacks, field, element);
    };
    getFilterList = (list) => {
        if (!list) {
            list = [];
        }
        const { filter } = this.state;
        if (!filter || filter === "") {
            return list;
        }
        return _.filter(list, (element) => {
            return !element.id || element.label.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
        })
    };
    getListByMode = (list, mode) => {
        if (!mode) {
            return list;
        }

        switch (mode) {
            case "classic":
                if (list[0] && !_.isObject(list[0])) {

                    list = list.map((value, i) => {

                        return { id: value, label: value }
                    })
                }
                return [
                    {
                        type: "classic",
                        list
                    },

                ]
                break;
            case "multiselect":
                let newList = [];
                list = _.forEach(list, (sublist, i) => {
                    if (sublist.showTitle) {
                        newList.push({ id: sublist.id, label: sublist.label, isTitle: true })
                    }
                    _.forEach(sublist.list, (element, j) => {
                        newList.push({ id: element.id, label: element.label, isTitle: false, listName: sublist.listName, subListName: sublist.subListName, listId: sublist.id });
                    })
                });
                return [
                    {
                        type: "multiselect",
                        list: newList
                    },

                ];
            default:
                return list;

        }
    };
    handleChangeMultiselect = async (listId, element, e) => {
        let { form, field } = this.props;
        let { value, valueLabels } = this.state;
        if (!value) {
            value = [];
        }
        let index = _.findIndex(value, (currentValue) => {
            return currentValue[element.listName] === element.listId;
        });
        if (index < 0) {
            let newValue = {};
            newValue[element.listName] = element.listId;
            newValue[element.subListName] = [];
            value.push(newValue);
            index = value.length - 1;
        }
        if (e.target.checked) {
            value[index][element.subListName].push(element.id)
        } else {
            var indexSubList = value[index][element.subListName].indexOf(element.id);
            if (indexSubList > -1) {
                value[index][element.subListName].splice(indexSubList, 1);
            }
            if (value[index][element.subListName].length === 0) {
                value.splice(index, 1);
            }
        }

        let { mapLists } = this.state;
        let labelSelect = '';
        const elements = []
        const lists = this.getListByMode(this.props.lists, this.props.mode);
        _.forEach(lists, elementList => {
            _.forEach(value, val => {
                const fined = _.find(elementList.list, (list) => {
                    return val && val[list.listName] === list.listId && val[list.subListName] && val[list.subListName].indexOf(list.id) >= 0;
                })
                if (fined) elements.push(fined)
            })
            _.forEach(value, (list, index) => {
                if (_.get(mapLists[list[elements[index].listName]], 'label', '')) {
                    labelSelect = labelSelect.concat(_.get(mapLists[list[elements[index].listName]], 'label', ''), " [ ");
                    _.forEach(list[elements[index].subListName], (subList, i) => {
                        if (i > 0) {
                            labelSelect = labelSelect.concat(", ");
                        }
                        labelSelect = labelSelect.concat(mapLists[list[elements[index].listName]]["subList"][subList]);
                    })
                    labelSelect = labelSelect.concat(" ] ");
                }
            })
        })
        this.setValues(form, field, value, {}, element);
        this.setState({
            value,
            filter: "",
            labelSelect,
            link: null
        });
        return true;
    };
    isElementChecked = (element) => {
        let { value } = this.state;
        return _.find(value, (list) => {
            return list && list[element.listName] === element.listId && list[element.subListName] && list[element.subListName].indexOf(element.id) >= 0;
        })
    };
    getRenderElementList = (listElement, element, i) => {

        const mode = this.props.countryReady ? "country" : this.props.mode;
        switch (mode) {
            case "multiselect":
                if (!element.isTitle) {

                    return (
                        <a key={element.id} id={this.getId(listElement.type, i)} className={`${classes.multiselectLink} ${this.getClassLink(listElement.type, i)}`} >
                            <input
                                id={this.getId(listElement.type, i)}
                                type="checkbox"
                                value={this.isElementChecked(element)}
                                checked={this.isElementChecked(element)}
                                onChange={(e) => { this.handleChangeMultiselect(listElement.type, element, e) }}
                            />
                            {element.label}
                        </a>
                    )


                } else {
                    return (
                        <h4 key={i} className={`${classes.multiselectTitle}`}>{element.label}</h4>
                    );
                }

            case "classic":
                return (
                    <a key={i} id={this.getId(listElement.type, i)} className={`${this.getClassLink(listElement.type, i)}`} onClick={(e) => { this.selectItem(listElement.type, element) }}>{element.label}</a>
                )
            case "country":
                {

                    return (
                        <div className={classes.flag}>
                            <Flag url={`https://flagcdn.com/${_.toLower(element.code)}.svg`} />
                            <a key={i} id={this.getId(listElement.type, i)} className={`${this.getClassLink(listElement.type, i)}`} onClick={(e) => { this.selectItem(listElement.type, element) }}>{element.label}</a>
                        </div>
                    )
                }

            default:
                return (
                    <a key={i} id={this.getId(listElement.type, i)} className={`${this.getClassLink(listElement.type, i)}`} onClick={(e) => { this.selectItem(listElement.type, element) }}>{element.label}</a>
                )
        }

    };

    render() {
        let { type, tooltip, adds, mode, field, className, placeholder, limitedModalSize, countryReady: showFlags, autoComplete, modalClassName } = this.props;
        const { open, openAdd, formToAdd, filter, labelSelect, textToSave, textWarning, lists, value, hasFocus, showLoading } = this.state;
        const addList = adds ? adds : [];
        const placeHolderTranslate = helper.getTranslateTextByKey(placeholder);
        autoComplete = autoComplete === 'off' ? 'new-password' : 'on';

        return (
            <div className={`${classes.ClaraSelectContainer} ${className}`} >
                {this.props.label ? (
                    <label>{this.props.label || "" }{' '}{this.props.required &&
                        <span style={{ color: 'red' }}>*</span>}</label>
                ) : null}

                <span>
                    <TooltipInput label={tooltip}>
                        <KeyboardEventHandler
                            handleKeys={['esc', 'up', 'down', 'enter', 'tab', 'shift+tab']}
                            onKeyEvent={this.handleKeyPress} >
                            {showFlags && field && field.value ?
                                <div >
                                    <Flag className={classes.ImageFlag} url={`https://flagcdn.com/${_.toLower(field.value)}.svg`} />
                                    <input
                                        className={` ${classes.claraCountryInputSelected} ${value ? classes.claraSelectSelected : null} ${this.hasErrorForm() /*|| this.hasErrorComponent() */ ? classes.Error : null}`}
                                        type={type}
                                        disabled={this.props.disabled || showLoading ? true : false}
                                        value={filter + labelSelect}
                                        onChange={this.handleChange}
                                        placeholder={placeHolderTranslate}
                                        onFocus={this.handleFocus}
                                        onBlur={this.handleBlur}
                                        ref={(input) => {
                                            this.setInput(input)
                                        }}
                                        autoComplete={autoComplete}
                                        hasError={this.hasErrorForm() ? "true" : "false"}
                                    />
                                </div> :
                                <input
                                    className={` ${classes.claraSelectInput} ${value !== null ? classes.claraSelectSelected : null} ${this.hasErrorForm() /*|| this.hasErrorComponent()*/ ? classes.Error : null}`}
                                    type={type}
                                    disabled={this.props.disabled || showLoading ? true : false}
                                    value={filter + labelSelect}
                                    onChange={this.handleChange}
                                    placeholder={placeHolderTranslate}
                                    onFocus={this.handleFocus}
                                    onBlur={this.handleBlur}
                                    ref={(input) => {
                                        this.setInput(input)
                                    }}
                                    autoComplete={autoComplete}
                                    hasError={this.hasErrorForm() ? "true" : "false"}
                                />
                            }

                            {showLoading ? (
                                <div className={classes.Loading}>
                                    <CircularProgress />
                                </div>
                            ) : null}
                            <div className={`${classes.claraSelectArrow}`} >
                            </div>
                            { !isNilCustom(_.get(this.props, "field.value"))  && !this.props.disabled ? (
                                <div onClick={() => { this.clearValueAndFilter(this.props) }} className={`${classes.claraButtonClearValue}`}>
                                    <Icon icon={"x_symbol"} size={"0.75rem"} />
                                </div>
                            ) : (
                                <div className={`${classes.claraSelectArrow}`} >
                                </div>
                            )
                            }


                            {(this.hasErrorForm() /*|| this.hasErrorComponent()*/) ? (
                                <div className={classes.MessageError}>
                                    {this.getError()}
                                </div>
                            ) : null}


                            {open ? (
                                <div className={`${limitedModalSize ? classes.limitedModalSize : null} ${classes.Modal} scrollbar ${modalClassName}`}
                                    onMouseOver={this.handleMouseOverModal}
                                    onMouseOut={this.handleMouseOutModal}
                                    ref={(modal) => { this.setRefModal(modal) }}
                                >
                                    <React.Fragment>
                                        {addList.length > 0 ? (
                                            <React.Fragment>
                                                <div className={classes.zoneAdd}>

                                                    {addList.map((element, i) => {
                                                        return (<>
                                                            {
                                                                _.get(element, 'notAdd', false) ?
                                                                    <a key={i} style={{ paddingLeft: '22px' }} id={this.getId(element.type, i)} className={`${classes.Select}`} onClick={() => this.selectItem(type, element)}> <Icon icon={element.icon} size={"1.3077rem"} isClara={true} />{element.label}</a> :
                                                                    <a key={i} id={this.getId("add", i)} className={`linkPlus ${this.getClassLink("add", i)}`} onClick={(e) => { this.addItem(element) }}>{element.label}</a>
                                                            }
                                                        </>)
                                                    })}
                                                </div>

                                                <hr className={classes.Separator} />
                                            </React.Fragment>
                                        ) : null}
                                        {lists.map((listElement, j) => {
                                            const listFilter = this.getFilterList(listElement.list);
                                            return (
                                                <React.Fragment key={j}>
                                                    {listFilter.length > 0 ? (
                                                        <React.Fragment>
                                                            <div className={classes.zoneList}>
                                                                {listFilter.map((element, i) => {

                                                                    return (
                                                                        <React.Fragment key={JSON.stringify(element)}>
                                                                            {this.getRenderElementList(listElement, element, i)}
                                                                        </React.Fragment>
                                                                    )
                                                                })}
                                                            </div>

                                                            <hr className={classes.Separator} />
                                                        </React.Fragment>
                                                    ) : null}
                                                </React.Fragment>
                                            )
                                        })}
                                    </React.Fragment>
                                </div>
                            ) : null}
                        </KeyboardEventHandler>
                        <div className={`${classes.zoneNewData} ${openAdd ? classes.Show : classes.Hide}`}>
                            {formToAdd ? (
                                <React.Fragment>
                                    {formToAdd}
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <input
                                        className={` ${classes.claraSelectInput} ${classes.inputLabel} `}
                                        type={type}
                                        disabled={!!this.props.disabled}
                                        value={textToSave}
                                        onChange={this.handleChangeInputSave}
                                        placeholder={this.state.placeholderTextToSave}
                                        ref={(inputAdd) => { this.setInputAdd(inputAdd) }}
                                        onKeyDown={this.handleKeyDown}
                                        onBlur={(e) => this.handleBlurInputSave(e)}
                                        autoComplete={autoComplete}
                                    />
                                    <button type={"button"} disabled={textToSave === ""} className={`genericBlue ${classes.buttonInputLabel} `} onClick={this.submitDefault}>Save</button>
                                </React.Fragment>
                            )}


                        </div>
                    </TooltipInput>
                </span>
                <div style={{marginTop:'1rem'}}>
                <Notifications show={openAdd && textWarning} type={"success"}>
                    {textWarning}
                </Notifications>
                </div>
            </div >


        )
    }
}

export default onClickOutside(ClaraSelect);


const Flag = ({ url, className, ...props }) => {

    const [style, setStyle] = useState({ display: '', width: "32px", height: "24px" })

    const [flag, setFlag] = useState(url);

    const handleOnError = () => {
        setFlag(ClaraFlag);
        setStyle({ display: "" })
    }


    return <img style={style} className={className} src={flag} onError={handleOnError} />

}


const TYPES={
    "ERROR": "ERROR",
    "WARGING":"WARNING",
    "SUCCESS":"SUCCESS",
    "INFO":"INFO"
}
const ACTIONS={
    "RELOAD":"RELOAD",
    "REACT":"REACT",
    "API": "API",
    "MODAL": "MODAL",
    "TAB":"TAB",
    "PAGE":"PAGE",
    "QUERY":"QUERY",
    "MUTATION":"MUTATION",
}
const SESSION_STORAGE={
    INFO: "LOGGER_INFO",
    HASH_UUID: "HASH_UUID",
}
export default {
    TYPES,
    ACTIONS,
    SESSION_STORAGE,
    DEFAULT_LOGGER_UNITS_TO_SAVE_STORE:20,
    DEFAULT_LOGGER_UNITS_TO_CLEAR_STORE:300,
    DEFAULT_LOGGER_SEND_TO_SERVER:true,
    DEFAULT_LIMIT_CHART_SUCCESS:200
};
import React from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";

const MyShareIssuance = ({ go, ...props }) => {
  const {startupId, ...params} = useParams();

  return (
    <MicroFrontend id="MF_myEquity_mySharesIssuance" component={"./DetailsShares"} mf="capTable" params={{ go, ...props,...params, startup: {id: startupId, userRol: null}  }}/>
  );
};

export default MyShareIssuance;

import React, {useEffect, useState} from 'react';
import _ from "lodash";
import {Loading, Text, TimeLine} from '../../../components';
import classes from "./MattersLogs.module.scss"
/* Import Hooks */
import useMatterLogs from '../../../Hooks/useMatterLogs';

import {Waypoint} from 'react-waypoint';


const MatterLogs = (props) => {

    const [loading, setLoading] = useState(false);

    return (
        <div className={classes.ContainerView}>
            <Loading show={loading} showChildren={false} loadingStyle={{ marginTop: "4.5rem" }} >
                <Logs/>
            </Loading>
        </div>)

};

export default MatterLogs;

const Logs = ({ session }) => {
    const [page, setPage] = useState(0);
    const [filters, setFilters] = useState({});
    let { timeLine, loading, hasNextPage } = useMatterLogs(page, filters);

    const handleInfiniteScroll = () => {
        if (hasNextPage) {
            setPage(page + 1);
        }
    };

    let updateFilters = function (values) {
        const newValue = {
            transactions: _.get(values, "transactions.0.transactions", []),
            dateFrom: _.get(values, "dateFrom", null),
            dateTo: _.get(values, "dateTo", null),
        };
        if (!_.isEqual(newValue, filters)) {
            setFilters(newValue);
            setPage(0)
        }
    };

    return (
        <React.Fragment>
            <div className={classes.AuditLogs}>
                {/*
                <div className={classes.ZoneMenu}>
                    <h1><Text uuid={"AUDITLOGS_TITLE"} /></h1>
                    <h2><Text uuid={"AUDITLOGS_FILTER"} /></h2>
                    <p><Text uuid={"AUDITLOGS_FILTER_INFO"} /></p>
                    <LogFilters updateFilters={updateFilters} session={session} />
                </div>
*/}
                <div className={classes.ZoneTimeLine}>
                    <h1><Text uuid={"AUDITLOGS_TITLE_TIME_MATTER_LINE"} /></h1>
                    <div className={`${classes.InfiniteScroll} scrollbar`} >
                        <TimeLine timeLine={timeLine} />
                        {!loading ? (
                            <Waypoint
                                onEnter={() => { handleInfiniteScroll(); }}
                                bottomOffset='0%' />
                        ) : <Loading show={loading} inline={true} label={"LOADING"} />}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

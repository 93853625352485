import useCreateRenewalProcess  from "src/views/modules/startups/renewal/mutations/useCreateRenewalProcess";
import useCreateStrikeOffProcess  from "src/views/modules/startups/renewal/mutations/useCreateStrikeOffProcess";
import { useMemo } from 'react';
import useSession from "src/modules/session/hooks/useSession";

const useServices = ({ initialValues }) => {

  const { startupId } = useSession();
  const [upsertCreateStrikeOffProcess] = useCreateStrikeOffProcess({}, {});
  const [upsertCreateRenewalProcess] = useCreateRenewalProcess({}, {});

  return useMemo(() => ({

    initialData: async (context, event) => {
      return initialValues;
    },

    SaveStep: async (context, event) => {
      const variables = {
        startupId,
        groupCompanyId: context?.initialData?.company?.id ?? ""
      };
      await upsertCreateRenewalProcess({ variables });
      
      if(initialValues?.callback){
        initialValues?.callback()
      }
      
      return context
    },

    StrikeOff: async (context, event) => {
      const variables = {
        startupId,
        groupCompanyId: context?.initialData?.company?.id ?? ""
      };
      await upsertCreateStrikeOffProcess({ variables });
      
      if(initialValues?.callback){
        initialValues?.callback()
      }

      return context
    },

  }), [initialValues]);
}

export default useServices;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ServerConnect } from "../utils";
import { Constants } from "../utils/constants";
import helper from "../utils/helper";
import mustache from "mustache";
import _ from "lodash"

const query = `{
    getProfileTimeline ( {{{filters}}})   {
   _id		
   date
    user {
      fullName
      firstName
      lastName
    }
    startup{
      name
    }
    transaction{
        label
    }
    labelValues
  }
}`
export default function useUsers(newPage, newFilters) {
    const [timeLine, setTimeLine] = useState([]);
    const [limit, setLimit] = useState(Constants.CONFIG.INFINITE_SCROLL_LIMIT_DEFAULT);
    const [cursorId, setCursorId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState(null);
    const [page, setPage] = useState(null);
    const [hasNextPage, setHasNextPage] = useState(null);
    const params = useParams();

    useEffect(() => {
        let reload = false;
        let currentCursorId = cursorId;
        if (page !== newPage) {
            reload = true;
        }
        if (!_.isEqual(newFilters, filters)) {
            reload = true;
            currentCursorId = null;
            setTimeLine([]);
            setCursorId(null);
        }
        if (reload) {
            setFilters(newFilters);
            setPage(newPage);
            setLoading(true);
            const filtersQuery = {
                startupId: params.startupId,
                user: params.userId,
                dateFrom: newFilters.dateFrom,
                dateTo: newFilters.dateTo,
                limit,
                cursorId: currentCursorId
            }
            const queryParams = mustache.render(query, { filters: helper.jsonToStringParameters(filtersQuery) });
            ServerConnect.graphQlQuery(queryParams).then(result => {
                
                    let timeLinesServerLocal = newPage === 0 ? [] : timeLine;
                    timeLinesServerLocal = timeLinesServerLocal.concat(result.getProfileTimeline);
                    setTimeLine(timeLinesServerLocal);
                    setHasNextPage(result.getProfileTimeline.length > 0);
                    setLoading(false);
                    if (result.getProfileTimeline.length > 0) {
                        setCursorId(result.getProfileTimeline[result.getProfileTimeline.length - 1]._id)
                    }
            });
        }

    });

    return { timeLine, loading, hasNextPage };
}
import useLazyQuery from "src/hooks/custom/useLazyQuery";

const GET_GROUP_COMPANY = `
query getGroupCompany($startupId: ID!, $groupCompanyId: ID!) {
  getGroupCompany(startupId: $startupId, groupCompanyId: $groupCompanyId) {
    id
    name
    keyPeople {
      useClaraNominee
      authorisedSignatories {
        id
        avatar
        fullName
        email
        isFounder
      }
    }
  }
}`;

export const useGetGroupCompanyReviewAuthorisedSignatory = (
  variables,
  config
) => {
  const { manualQuery, loading, error, data, refetch } = useLazyQuery(
    GET_GROUP_COMPANY,
    variables,
    config
  );
  return { loading, error, data, refetch, manualQuery };
};

export default useGetGroupCompanyReviewAuthorisedSignatory;

import _ from "lodash";
import { string } from "prop-types";
import { ReactNode, ReactElement } from "react";

export const actions = {
  LOGIN: "SESSION_LOGIN",
  SET_TOKEN: "SESSION_SET_TOKEN",
  CLEAR_SESSION: "SESSION_CLEAR_SESSION",
  SET_USER: "SESSION_SET_USER",
  SET_JUST_LOGGED: "SESSION_SET_JUST_LOGGED",
  SET_STARTUP: "SESSION_SET_STARTUP",
  SET_LOADING: "SESSION_SET_LOADING",
  UPDATE_STARTUP: "SESSION_UPDATE_STARTUP",
  UPDATE_USER: "SESSION_UPDATE_USER",
  SET_EXPANDED_HEADER: "SESSION_SET_EXPANDED_HEADER",
  UPDATE_HASPAYMENT_OVERDUE: "SESSION_UPDATE_HASPAYMENT_OVERDUE",
  SET_MORE_ONE_STARTUP: "SET_MORE_ONE_STARTUP",
};

export const setMoreOneStartup = (
  dispatch: Function,
  moreOneStartup: boolean
) => {
  dispatch({
    type: actions.SET_MORE_ONE_STARTUP,
    moreOneStartup,
  });
};
export const login = (dispatch: Function, token: string, lastLogin: Date) => {
  dispatch({
    type: actions.LOGIN,
    token,
    lastLogin,
  });
};

export const setToken = (dispatch: Function, token: string) => {
  dispatch({
    type: actions.SET_TOKEN,
    token,
  });
};

export const clearSession = (dispatch: Function) => {
  dispatch({
    type: actions.CLEAR_SESSION,
  });
};

export const setLoading = (dispatch: Function) => {
  dispatch({
    type: actions.SET_LOADING,
  });
};

export const setExpandedHeader = (dispatch: Function) => {
  dispatch({
    type: actions.SET_EXPANDED_HEADER,
  });
};

export const setUser = (dispatch: Function, user: any) => {
  dispatch({
    type: actions.SET_USER,
    user,
  });
};

export const updateUser = (dispatch: Function, user: any) => {
  dispatch({
    type: actions.UPDATE_USER,
    user,
  });
};

export const setStartup = (
  dispatch: Function,
  startup: any,
  hasOverduePayment: boolean
) => {
  dispatch({
    type: actions.SET_STARTUP,
    hasOverduePayment,
    startup,
  });
};

export const updatePaymentHasOverduePayment = (
  dispatch: Function,
  hasOverduePayment: boolean
) => {
  dispatch({
    type: actions.UPDATE_HASPAYMENT_OVERDUE,
    hasOverduePayment,
  });
};

export const updateStartup = (dispatch: Function, startup: any) => {
  dispatch({
    type: actions.UPDATE_STARTUP,
    startup,
  });
};

export const setPageLoaded = (dispatch: Function, pageLoaded: string) => {
  dispatch({
    type: actions.SET_JUST_LOGGED,
    pageLoaded,
  });
};

import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { Stack } from "@mui/material";
import ClaraLayout from "src/layouts/Clara/ClaraLayout";
import MicroFrontend from "src/mf";
import useCapTable from "../contexts/CaptableContext/useCaptable";
import useSession from "src/modules/session/hooks/useSession";
import useNavigation from "src/modules/generic/hooks/useNavigation";
import { Constants } from "src/v1/utils/constants";
import _ from "lodash";
import Loading from "src/components/generic/Loading/Loading";
const CapTableEmptyRouter = ({ routers = {} }) => {
  const page = useSelector(state => state.page);
  const { triggerUpdate  } = useCapTable();
  const history = useHistory();
  const {startup} = useSession();
  const {goTo} = useNavigation();
  useEffect(() => {
    if (page?.historyPage && page?.historyPage[page.historyPage.length - 1]?.url) {
      history.push(page.historyPage[page.historyPage.length - 1].url);
    }
  }, [history, page]);
  console.log(startup?.groupCompanies,startup,'startup')
  const companyTopCoAndIncorporated = _.find(startup?.groupCompanies ?? [], (groupCompany) => {
    return groupCompany.isHoldingCompany === true && groupCompany.kind === Constants.MODULES.STARTUP.GROUP_COMPANY.KIND.INCORPORATED;
  });

  const companyIncorporated = _.find(startup?.groupCompanies ?? [], (groupCompany) => {
    return groupCompany.kind === Constants.MODULES.STARTUP.GROUP_COMPANY.KIND.INCORPORATED;
  });

  const groupCompanyId = companyTopCoAndIncorporated?.id || companyIncorporated?.id;

  useEffect(()=>{
   if (groupCompanyId) goTo(Constants.PAGES["capTable.mf.captable"], { groupCompanyId });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[JSON.stringify(goTo), groupCompanyId])

  return (
    <ClaraLayout startupMenu userMenu fullMenu showAssistance={false} showBreadCrumbs={false} linkMenu="LinkCapTable">
      <Stack direction={"row"} sx={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }}>
        { startup?.groupCompanies?
          <MicroFrontend id="MF_ShareClass_Menu_View" component={"./EmptyScreenView"} mf="capTable" params={{ triggerUpdate }}/>
          :
          <Loading loading={true}/>
          }
      </Stack>
    </ClaraLayout>
  )
};

export default CapTableEmptyRouter;

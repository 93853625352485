import { useMemo } from 'react';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import BusinessPlanStep from '../steps/BusinessPlanStep';
import AlreadyApprovedUploadDocumentsFormStep from '../steps/AlreadyApprovedUploadDocumentsFormStep';
import LetsPreApproveFormStep from '../steps/LetsPreApproveFormStep';
import PreApprovedUploadDocumentsFormStep from '../steps/PreApprovedUploadDocumentsFormStep';

const useSteps = () => {
  return useMemo(() => ({
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    BusinessPlanStep: {
      render: BusinessPlanStep,
    },
    LetsPreApproveFormStep: {
      render: LetsPreApproveFormStep
    },
    PreApprovedUploadDocumentsFormStep: {
      render: PreApprovedUploadDocumentsFormStep,
    },
    AlreadyApprovedUploadDocumentsFormStep: {
      render: AlreadyApprovedUploadDocumentsFormStep,
    },
    SaveStep: {
      render: TemplateWizardSkeletonMachine,
    },
  }), []);
}

export default useSteps;

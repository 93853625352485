import { default as React, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TextInput from 'src/components/inputs/Text/TextInput';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateLabel from 'src/modules/generic/components/Inputs/LabelInput';
import * as Yup from 'yup';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText,addAssistantText } from 'src/modules/generic/store/action';
import Typography from 'src/modules/generic/components/Texts/Typography';
import CheckboxButtonSimple, {
  CheckboxButtonOptions,
} from 'src/modules/generic/components/Inputs/CheckboxButtonSimple';
import classes from '../classes.module.scss';

/**
* Description Step: You choose if you want another stakeholder to add as a authorised signatory.
* @returns 
*/
function SelectAddAnotherStakeholderStep() {
  const { context, prev,cancel,send, state } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.SelectAddAnotherStakeholderStep ?? {}
  }, [context]);

  const handleCancel = (values) => {
    cancel();
  }
  const handlePrev = (values) => {
    prev();

  }
  const handleSubmit = async (values) => {

    const nextStep = values?.addAnotherStakeholder

    send({
      type: nextStep,
      payload: { idState: state.value, values: values }
    });
  }

  useEffect( ()=>{
    dispatch(addAssistantText('An ADGM authorised signatory is a person who can legally sign ADGM documents on the company\'s behalf.'))
    return () => {
      dispatch(clearAssistatText())
    }
  },[dispatch])

  const schema = Yup.object().shape({
    addAnotherStakeholder: Yup.string().nullable().required('Required'),
        authorisedSignatories: Yup.array().min(1,'Required'),

  })

  const options: Array<CheckboxButtonOptions<string, any>> = [

    {
      value: 'YES',
      label: 'Yes, add another'
    },
    {
      value: 'NO',
      label: 'No, not for now'
    },

  ];

  return (

    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={ { mode: 'onChange' } }
    >
      <TemplateWizard
        props={ {
          title: 'Add another ADGM authorised signatory?',
          skeleton: null,
          subTitle: 'ADGM authorised signatories',
          variant: 'simple'
        } }

        buttons={ {
          previous: <Button variant='secondary' onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('BUTTON_NEXT')}</SubmitButton>,
        } }
      >
        {/* <Typography weight='regular' variant='xbody'>
        description
        </Typography> */}

        <ControllerInput
          render={CheckboxButtonSimple}
          name='addAnotherStakeholder'
          variant='col_1'
          options={options}
          size='md'
          className={classes.CheckboxButton}
          classNameButton={classes.buttonCheckBox}
        />

      </TemplateWizard>
    </Form>
  );
}

export default SelectAddAnotherStakeholderStep;

// const ButtonText = ({ title, text }) => {
//   return (
//     <div>
//       <Typography weight='bold' variant='xbody' className='mb-2'>
//         {title}
//       </Typography>
//     </div>
//   );
// };


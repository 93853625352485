import React, {useEffect} from 'react';
import {Constants} from '../../../../utils/constants'
import classes from "../ViewForms.module.scss";
import {useDispatch} from "react-redux";
import _ from "lodash";


const CategoryNamesListQuestionnaireView = (props) => {

    const dispatch = useDispatch();
    const { mode, data } = props;

    const getTitle = () => {
        switch (mode) {
            case Constants.QUESTIONNAIRE_KEYS.DIRECTORS_CATEGORY:
                return <label>Directors</label>;
            case Constants.QUESTIONNAIRE_KEYS.SIGNATORIES_CATEGORY:
                return <label>Signatories</label>;
            case Constants.QUESTIONNAIRE_KEYS.BENEFICIAL_OWNERS_CATEGORY:
                return <label>Beneficial Owners</label>;
            case Constants.QUESTIONNAIRE_KEYS.AGREEMENTSDATA:
                return <label>Signatories for Registered Office Agreement</label>;
        }
    }

    const getContent = () => {
        switch (mode) {
            case Constants.QUESTIONNAIRE_KEYS.DIRECTORS_CATEGORY:
            case Constants.QUESTIONNAIRE_KEYS.SIGNATORIES_CATEGORY:
                case Constants.QUESTIONNAIRE_KEYS.DATA_CONTROLLER:
            case Constants.QUESTIONNAIRE_KEYS.BENEFICIAL_OWNERS_CATEGORY:
                return (<React.Fragment>
                    {data.map(ent => {
                        return (<React.Fragment >
                            <div className={classes.nameList}>
                                <span>{ent.firstName ? ent.firstName + ' ' + ent.lastName : ent.companyName}</span>
                            </div>

                        </React.Fragment>
                        )
                    })}
                </React.Fragment>)
            case Constants.QUESTIONNAIRE_KEYS.AGREEMENTSDATA:
                return (<React.Fragment>
                    {_.get(data, 'agreementsDirectors', []).map(ent => {
                        return (<React.Fragment >
                            <div className={classes.nameList}>
                                <span>{_.get(ent, 'firstName', '') ? _.get(ent, 'firstName', '') + ' ' + _.get(ent, 'lastName', '') : _.get(ent, 'companyName', '')}</span>
                            </div>

                        </React.Fragment>
                        )
                    })}
                </React.Fragment>)

        }
    }



    useEffect(() => {
    }, [,]);


    return (
        <React.Fragment>
            <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle}`}>
                {getTitle()}
            </div>
            <hr className={`${classes.Separator} ${classes.modifiedSeparator}`} />
            <div>
                {getContent()}
            </div>
            {mode == Constants.QUESTIONNAIRE_KEYS.AGREEMENTSDATA && data.firstName ? (<React.Fragment>
                <div className={`${classes.ContainerRow} ${classes.ContainerRowTitle} ${classes.SubContainerTitle}`}>
                    Witness for Registered Office Agreement
                </div>
                <hr className={`${classes.Separator} ${classes.modifiedSeparator}`} />
                <div className={classes.nameList}>
                    <span>{data.firstName + ' ' + data.lastName}</span>
                </div>
            </React.Fragment>) : null}
        </React.Fragment>
    )
}

export default CategoryNamesListQuestionnaireView;

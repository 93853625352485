import { useMemo } from "react";
import useBusinessRules from "src/modules/clara/hooks/useBusinessRules";
import useSession from "src/modules/session/hooks/useSession";
import _ from "lodash";

const useGuards = () => {
  const { isOwner } = useBusinessRules();
  const { session } = useSession();
  return useMemo(
    () => ({
      isNomineeThirdParty: (context) => {
        return (
          _.get(
            context?.WhoIsSecondAuthorisedSignatoryFormStep,
            "secondAuthorisedSignatory.value",
            ""
          ) === "ADD_THIRD_PARTY"
        );
      },
      useNominee: (context) => {
        return (
          _.get(context?.NoEligibleStakeholders, "useNominee", "") === "true"
        );
      },
      hasUAEorGCCStakeholder: (context) => {
        return (
          _.get(context, "initialData.stakeholdersUAEorGCC", []).length >= 1
        );
      },
      hasNotUAEorGCCStakeholder: (context) => {
        return (
          _.get(context, "initialData.stakeholdersNotUAEorGCC", []).length >= 1
        );
      },
    }),
    [isOwner, session]
  );
};

export default useGuards;

import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import event from 'src/v1/utils/event';
import useCustomMachine from '../../../../generic/hooks/useCustomMachine';
import Button from '../../../../generic/components/Buttons/Button';
import { StepProps } from "../../../../generic/components/Wizard/Step";
import TemplateWizard from '../../../../generic/templates/Modal/TemplateWizard';
import useTranslate from '../../../../generic/hooks/useTranslate';
import Form from "../../Forms/FormEmployee";
import { goToNextPage } from '../../../../../store/actions/page';
import { Constants } from '../../../../../v1/utils';
import { addAssistantText, clearAssistatText } from '../../../../generic/store/action';

const Step1: FC<StepProps> = () => {
  
  const { translate } = useTranslate();
  const { next, send } = useCustomMachine();
  const dispatch = useDispatch();

  const handleCompleteSubmit = async values => {
    const type = values.type;
    event.emmit(Constants.EVENTS.DOCUMENT_SCROLL, { type });
    dispatch(
      goToNextPage(
        Constants.PAGES.chooseDocumentType, { previewDocument: values.type }
      )
    );
    next(values);
  }

  const handleCancel = () => {
    send("CANCEL");
  }

  useEffect(() => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText("This document will be safely stored in Documents, your Clara data room."));
    return () => {
      dispatch(clearAssistatText());
    }
  }, []);

  return (
    <>
      <Form onCompleteSubmit={handleCompleteSubmit} Template={TemplateWizard} propsTemplate={{
        title: `Please choose the correct employment agreement`,
        subTitle: translate(`MODULES.MAP.WIZARD_COMPANY_DETAIL_FORM_SUBTITLE`)
      }}
        buttons={{ cancel: <Button onClick={handleCancel} variant="secondary">Cancel</Button> }}>
      </Form>
    </>
  );
}

export default Step1;

import { createMachine } from 'xstate';

const machine =
  /** @xstate-layout N4IgpgJg5mDOIC5QFVZgE4AUCuAjANgJYDGASmFIbAC7oCG1hA9gHYB02a6AYk+gLYBiAHIBRABoAVRKAAOTWIUasZIAB6IAjABYAHGwBMAVgBsukye1GjABgDsuzQYA0IAJ5bNATjZGvf3V1tL20TOxt-AF9I11QMHAISckoaemV2AGslXgFBTFJRADVVeUV01Q0EHX1jMwsrWwcnVw8EO01fLxMbG10AZh6m42jYrgSiMgoqWgZmTOy+ITEpEoUlOYrEPr6fTXCvO1s+0J7NFq0DAzYIgKCQsJuRkDisPAnk6bS5thowWT68gVikgQKV1ioQZVtrt9ocbMdujYzu5EHZtNcuj1+oNHMMniwmBA4KoXuMklNUrNWBwuDl+KsyhtIYhtC4UW0fDs7H0DLoutpNDy+iYnqS3uSUjN0mwstQ6QzwSxNggDALDKZdEYdF4Qto+v1zghHGw+dzefzBQZhaKxuLJpKvtTfv8FeVmQg9Vc9hFEQ4ukZdIagmwuTy+ZZLdaYs9bYl7Z8qUqQWC3aBKppA+yALQdbQ2PM2IzaVlWvMWaLRIA */
  createMachine({
    id: 'addStakeholder',
    initial: 'getStakeholdersGroupCompanies',
    states: {
      select: {
        on: {
          NEXT: {
            target: 'getStakeholdersGroupCompanies',
            actions: ['setContextAfterStep']
          }
        }
      },
      getStakeholdersGroupCompanies: {
        invoke: {
          src: 'getStakeholdersGroupCompanies',
          id: 'getStakeholdersGroupCompanies',
          onDone: [
            {
              target: 'evaluateToStakeholderEntityForm',
              actions: ['setContextAfterInvoke','setHasPincas']
            }
          ],
          onError: [
            {
              target: 'select'
            }
          ]
        }
      },
      evaluateToStakeholderEntityForm: {
        always: [
          {
            cond: 'noGroupCompanies',
            target: 'stakeholderNameForm',
            actions: ['setEntity']
          },
          {
            cond: 'havesGroupCompanies',
            target: 'stakeholderEntityForm',
          }
        ]
      },
      stakeholderEntityForm: {
        on: {
          NEXT: {
            target: 'stakeholderNameForm',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'finish'
          }
        }
      },
      stakeholderNameForm: {
        on: {
          NEXT: {
            target: 'selectRolesForm',
            actions: ['setContextAfterStep', 'setRolesByContext']
          },
          PREV: 'stakeholderEntityForm',
          CANCEL: {
            target: 'finish'
          }
        }
      },
      selectRolesForm: {
        on: {
          NEXT: {
            target: 'evaluateToSelectGroupCompanies',
            actions: ['setRolesAndIsFounder']
          },
          PREV: {
            target: 'stakeholderNameForm'
          },
          CANCEL: {
            target: 'finish'
          }
        }
      },
      evaluateToSelectGroupCompanies: {
        always: [
          {
            cond: 'noGroupCompanies',
            target: 'SaveStep',
          },
          {
            cond: 'is1GroupCompany',
            target: 'EvaluateIsManagedByClaraADGM',
          },
          {
            cond: 'isNot1GroupCompany',
            target: 'selectGroupCompanies',
          },
          {
            target: 'error'
          },
        ]
      },
      selectGroupCompanies: {
        on: {
          NEXT: {
            target: 'EvaluateIsManagedByClaraADGM',
            actions: ['setContextAfterStep']
          },
          PREV: {
            target: 'selectRolesForm',
            actions: ['setRolesByContext', 'resetIterations']
          },
          CANCEL: {
            target: 'finish'
          }
        }
      },
      EvaluateIsManagedByClaraADGM: {
        always: [
          {
            cond: 'isManagedByClaraADGM',
            target: 'LegalEntityStructure',
            actions: ['hasLegalEntityStructure']
          },
          {
            cond: 'isNotManagedByClaraADGM',
            target: 'SaveStep',
            actions: ['setContextAfterStep', 'addIterations']
          },
        ]
      },
      LegalEntityStructure: {
        on: {
          PREV: {
            target: 'selectGroupCompanies',
          },
          NEXT: {
            target: 'SaveStep',
            actions: ['setContextAfterStep', 'addIterations']
          },
          CANCEL:{
            target: 'finish'
          }
        }
      },
      evaluateGoal: {
        always: [
          {
            cond: 'isAddGoal',
            actions: ['addGoal', 'addIterations'],
            target: 'SaveStep'
          },
          {
            cond: 'isNotAddGoal',
            target: 'evaluateToContinueWithRolesFromFormationCorporate',
            actions: ['setGroupCompaniesWithoutFormation', 'addIterations']
          }
        ]
      },
      evaluateToContinueWithRolesFromFormationCorporate: {
        always: [
          {
            cond: 'isFinishRoles',
            target: 'addMoreStakeholdersForm',
          },
          {
            cond: 'isFinishNotRoles',
            target: 'selectGroupCompanies',
          },
          {
            target: 'error'
          },
        ]
      },
      SaveStep: {
        invoke: {
          src: 'SaveStep',
          id: 'SaveStep',
          onDone: [
            {
              target: 'evaluateToContinueWithRoles',
              actions: ['setStakeholder']
            }
          ],
        }
      },
      evaluateToContinueWithRoles: {
        always: [
          {
            cond: 'isFinishRoles',
            target: 'addMoreStakeholdersForm',
          },
          {
            cond: 'isFinishNotRoles',
            target: 'selectGroupCompanies',
          },
          {
            target: 'error'
          },
        ]
      },
      addMoreStakeholdersForm: {
        on: {
          NEXT: {
            target: 'evaluateToContinueWithStakeholders',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'finish'
          }
        },
      },
      evaluateToContinueWithStakeholders: {
        always: [
          {
            cond: 'isFinish',
            target: 'finish',
          },
          {
            cond: 'isNotFinish',
            target: 'getStakeholdersGroupCompanies',
            actions: ['resetIterations']
          },
          {
            target: 'error'
          },
        ]
      },
      error: {
        on: {
          PREV: {
            target: 'finish'
          }
        }
      },
      result: {
        on: {
          PREV: {
            target: 'select'
          }
        }
      },
      finish: {
        entry: 'stopMachine',
        type: 'final'
      }
    }
  });

export default machine;
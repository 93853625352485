import useMachine from '../../../../../generic/context/MachineContext/useMachine';
import Form from './../../forms/FormCorporatePosition';
import React, { useEffect } from 'react';
import Button from "../../../../../generic/components/Buttons/Button";
import useTranslate from '../../../../../generic/hooks/useTranslate';
import { useDispatch } from "react-redux";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import { addAssistantText, clearAssistatText } from "../../../../../generic/store/action";
import useModal from "../../../../../generic/hooks/useModal";
import useSession from 'src/modules/session/hooks/useSession';
import _ from 'lodash';
// import { Constants } from "src/v1/utils";
// import useGetStakeholders from 'src/hooks/services/modules/stakeholders/useGetStakeholders';
// import { useParams } from "react-router-dom";


/**
 * Step para editar los datos de SOF OTHER
 * @param {*} param0
 * @returns
 */
function CorporatePosition({ openParam ,edit}) {

  const { next, send, context, prev } = useMachine();
  const { translate } = useTranslate()
  const dispatch = useDispatch();
  const { closeModal: onClose } = useModal();
  const { startup, startupId } = useSession();
  const [initialValues, setInitialValues] = React.useState(null);

  const handleCancel = (values) => {
    dispatch(clearAssistatText())
    onClose();
  }

  const handlePrevious = () => {
    prev()
  }

  const handleInitModal = () => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText(translate('MODULES.STAKEHOLDER.CORPORATE_POSITION.STEP_1.TOOLTIP')))
  }

  useEffect(() => {
    handleInitModal()
  }, []);

  const handleCompleteSubmit = async (values) => {
    next(values)
  }

  useEffect(() => {
    if (_.get(context, 'serviceGetStakeholders.stakeholders').length > 0) {
      setInitialValues(_.get(context, 'serviceGetStakeholders.stakeholders'))
    }
  }, [context]);

  return (
    <>
      <Form
        buttons={{
          cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
        }}
        onCompleteSubmit={handleCompleteSubmit}
        initialValues={initialValues}
        propsTemplate={{
          title: translate('MODULES.STAKEHOLDER.CORPORATE_POSITION.STEP_1.TITLE'),
          subTitle: edit?translate('MODULES.STAKEHOLDER.CORPORATE_POSITION.STEP_1.SUBTITLE'):translate('MODULES.STAKEHOLDER.CORPORATE_POSITION.STEP_1.SUBTITLE_ADD'),
        }}
        Template={TemplateWizard}
      />
    </>
  );
}
export default CorporatePosition;

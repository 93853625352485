import { SelectCompanies, SharesInCompaniesNotFormed } from "../steps";
import TemplateWizardSkeletonMachine from "src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine";

const useSteps = () => {
  return {
    InitialData: {
      render: TemplateWizardSkeletonMachine,
    },
    pincasData: {
      render: TemplateWizardSkeletonMachine,
    },
    Save: {
      render: TemplateWizardSkeletonMachine,
    },
    SharesInCompaniesNotFormed: {
      render: SharesInCompaniesNotFormed,
    },
    SelectCompanies: {
      render: SelectCompanies,
    },
  };
};

export default useSteps;

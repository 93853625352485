import React from 'react';
import classes from './Toolbar.module.scss';
import Botonera from './../../../../../modules/generic/components/Buttons/Botonera';
import ButtonIcon from "./../../../../../modules/generic/components/Buttons/ButtonIcon";
import Typography from '../../../../../modules/generic/components/Texts/Typography/index';
import TextInput from '../../../../../components/inputs/Text/TextInput';

const ToolbarTop = ({ pageNumber, numPages, setPageNumber }) => {

  const isFirstPage = pageNumber === 1;
  const isLastPage = pageNumber === numPages;
  const firstPageClass = isFirstPage ? classes.disabled : classes.clickable;
  const lastPageClass = isLastPage ? classes.disabled : classes.clickable;

  const goToFirstPage = () => {
    if (!isFirstPage) setPageNumber(1);
  }

  const goToPreviousPage = () => {
    if (!isFirstPage) setPageNumber(pageNumber - 1);
  }

  const goToNextPage = () => {
    if (!isLastPage) setPageNumber(pageNumber + 1);
  }

  const goToLastPage = () => {
    if (!isLastPage) setPageNumber(numPages);
  }

  const onPageChange = (e) => {
    let value = e;
    if (value) {
      if (Number(value) <= Number(isFirstPage)) {
        value = 1
      } else if (Number(value) >= Number(numPages)) {
        value = numPages
      }
      setPageNumber(Number(value));
    }
  }

  return (
    <React.Fragment>
      <div className={`${classes.ToolbarTop}`}>
        <Botonera
          buttons={[
            <ButtonIcon key={1} size={"55%"} icon="Previous-circle" disabled={isFirstPage} variant="transparent" onClick={goToFirstPage} />,
            <ButtonIcon key={2} size={"55%"} icon="Arrow-Left" disabled={isFirstPage} variant="transparent" onClick={goToPreviousPage} />,
          ]}
          className={undefined}
          orientation={'horizontal'}
        />
        <div className={`${classes.Pages}`}>
          <div className={`${classes.centerContent}`}>
            <Typography>Page</Typography>
          </div>
          <div>
            <TextInput
              type='number'
              value={pageNumber}
              onChange={onPageChange}
              name="pageNumber"
              variant='small'
            />
          </div>
          <div className={`${classes.centerContent}`}>
            <Typography>of {numPages}</Typography>
          </div>
        </div>
        <Botonera
          buttons={[
            <ButtonIcon key={2} size={"55%"} icon="Arrow-Right" disabled={isLastPage} variant="transparent" onClick={goToNextPage} />,
            <ButtonIcon key={1} size={"55%"} icon="Next-circle" disabled={isLastPage} variant="transparent" onClick={goToLastPage} />,
          ]}
          className={undefined}
          orientation={'horizontal'}
        />
      </div>
    </React.Fragment>
  )
}

export default ToolbarTop

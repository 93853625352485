import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import * as Yup from 'yup';
import useGetPossibleAndSharedProfiles from "../../../../../../hooks/services/modules/startups/shareManagement/useGetPossibleAndSharedProfiles";
import useSetUserAccessToAStartup from "../../../../../../hooks/services/modules/startups/shareManagement/useSetUserAccessToAStartup";
import share from "../../../../../../images/share.svg";
// import Subscriptions from "../../../../../../modules/checkout/modals/Subscriptions";
import BusinessRuleAccess from '../../../../../../modules/clara/components/BusinessRuleAccess/BusinessRuleAccess';
import useSession from '../../../../../../modules/session/hooks/useSession';
import { Constants } from '../../../../../../v1/utils';
// import SubscriptionGatesShare from "../../../../../../images/SubscriptionGatesShare.svg";
import useBusinessRules from '../../../../../../modules/clara/hooks/useBusinessRules';
import ModalAssistant from "../../../../../../modules/generic/components/Modal/ModalAssistant";
import { addAssistantText, clearAssistatText } from "../../../../../../modules/generic/store/action";
import useTranslate from "../../../../../../modules/generic/hooks/useTranslate";
import Button from "../../../../../../modules/generic/components/Buttons/Button";
import SkeletonInput from "../../../../../../modules/generic/components/Skeletons/Overlays/SkeletonInput";
import Line from "../../../../../generic/Line/Line";
import SkeletonArea from "../../../../../../modules/generic/components/Skeletons/Overlays/SkeletonArea";
import TemplateWizard from "../../../../../../modules/generic/templates/Modal/TemplateWizard";
import ShareProfileForm from "./ModalShareProfileForm";

const ModalShareProfile = ({ showModal, open, fromAssistance, handleCloseModal, initVals, updateTableData, tableData, userStartupRole, startupId, ...props }) => {

  const { isOwner } = useBusinessRules();
  const { translate } = useTranslate();
  const [isResend, setIsResend] = useState(false);
  const [showNameField, setShowNameField] = useState(false);
  const [email, setEmail] = useState('');
  const [fullNameValue, setFullNameValue] = useState(null);
  const [changed, setChanged] = useState(false);
  const params = useParams();
  const id = startupId ? startupId : _.get(params, 'id');
  const { mutation: setUserAccess } = useSetUserAccessToAStartup({ successMessage: 'NOTIFICATION_SUCCESS_SHARING_PROFILE', paramsSuccessMessage: { sentType: initVals ? 'resent' : 'sent', email: _.get(initVals, 'email') && !changed ? _.get(initVals, 'email') : email } }, {}, id)
  const { dataPossibleSharing, dataAlreadyShared, loading: loadingModal } = useGetPossibleAndSharedProfiles({ startupId: id });

  const dispatch = useDispatch();
  const { startup } = useSession();

  const submit = async (values) => {
    delete values.hiddenNameField;
    setIsResend(_.get(initVals, 'resend', false));
    if (_.has(values, 'resend')) {
      delete values.resend;
    }
    if (_.has(initVals, 'resend')) {
      delete initVals.resend;
    }
    const variables = initVals ? {
      "startupId": id,
      "sharingProfile": { ...initVals, ...values, _id: initVals._id }
    } : {
      "startupId": id,
      "sharingProfile": { ...values, fullName: values.fullName ? values.fullName : fullNameValue }
    };
    await setUserAccess(variables);
    dispatch(clearAssistatText());
    handleCloseModal();
  }

  const alreadySharingProfiles = useMemo(() => {
    const list = [];
    _.forEach(dataAlreadyShared, sp => {
      list.push({
        id: sp.email, label: sp.fullName + ' - ' + sp.email, component: <div>
          <img src={share} alt="" /> {sp.fullName + ' - ' + sp.email}
        </div>
      });
    });
    return list;
  }, [JSON.stringify(dataAlreadyShared)]);

  const schema = Yup.object().shape({
    email: Yup.string().lowercase().matches(Constants.REGEXP.EMAIL, { message: "Invalid Email", excludeEmptyString: true })
      .required('Required')
      .test('email',
        null,
        (value) => {
          let result = _.findIndex((alreadySharingProfiles), (mail) => {
            let trimedValue = value ? value.trim() : '';
            return mail.id === trimedValue;
          });
          return (_.get(initVals, 'resend') || isResend) || ((result === -1) || new Yup.ValidationError(`Mail is already in use`, null, `email`));
        }
      )
    ,
    role: Yup.string().nullable().required('Required'),
    fullName: Yup.string().nullable().when(['hiddenNameField'], {
      is: true,
      then: Yup.string().required('Required').min(5, 'At least 5 characters required').nullable().max(255, 'Required'),
      otherwise: Yup.string().nullable().optional()
    }),
  });

  const handleSubmit = async (values) => {
    values.email = values.email.trim();
    if ((initVals && initVals.email === values.email) || _.some(dataPossibleSharing, sp => sp.email === values.email) || (values.email && values.fullName)) await submit(values);
    else setShowNameField(true);
  };

  useEffect(() => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText(Constants.TRANSLATES.OWNER_ROL_EXAMPLE));
    dispatch(addAssistantText(Constants.TRANSLATES.MANAGER_ROL_EXAMPLE));
    dispatch(addAssistantText(Constants.TRANSLATES.EDITOR_ROL_EXAMPLE));
    dispatch(addAssistantText(Constants.TRANSLATES.VIEWER_ROL_EXAMPLE));
    return () => {
      dispatch(clearAssistatText());
    }
  }, []);

  return (
    <>
      <ModalAssistant open={showModal || open} >
        {isOwner() && (
          <BusinessRuleAccess conditions={[Constants.BUSINESS_RULES.HAS_SUBSCRIPTION_ACCESS_SHARE_PROFILE]}>
            {/* <Subscriptions
                subtitle={"SUSCRIPTION_MODAL_SHARE_PROFILE_SUBTITLE"}
                title={"SUSCRIPTION_MODAL_SHARE_PROFILE_TITLE"}
                content={"SUSCRIPTION_MODAL_SHARE_PROFILE_CONTENT"}
                image={SubscriptionGatesShare}
                button={"SUSCRIPTION_MODAL_CONTINUE_BUTTON"}
                gateField={"profilePopupHasBeenSeen"}
                popupName={"ProfilePopup"}
              /> */}
          </BusinessRuleAccess>
        )}
        <ShareProfileForm
          Template={TemplateWizard}
          onCompleteSubmit={handleSubmit}
          propsTemplate={{
            title: translate('SHARE_STARTUP_PROFILE_TITLE', { startupName: startup.name }),
            subTitle: translate('SHARE_PROFILE'),
            skeleton: (
              <>
                <SkeletonInput count={3}></SkeletonInput>
                <Line title={undefined} subTitle={undefined} children={undefined} className={undefined} />
                <SkeletonInput></SkeletonInput>
                <SkeletonArea noLabel={true}></SkeletonArea>
              </>
            )
          }}
          buttons={{
            cancel: (
              <Button onClick={() => handleCloseModal()} variant="secondary">
                {translate("MODULES.CLARA.BTN_CANCEL")}
              </Button>
            )
          }}
          schema={schema}
          initialValues={initVals}
          showNameField={showNameField}
          setShowNameField={setShowNameField}
          setFullNameValue={setFullNameValue}
          dataPossibleSharing={dataPossibleSharing}
          alreadySharingProfiles={alreadySharingProfiles}
          setEmail={setEmail}
          changed={changed}
          setChanged={setChanged}
        />
      </ModalAssistant>
    </>
  );
};

export default ModalShareProfile;
import React from 'react'
import * as Yup from 'yup'
import Form from '../../../../components/forms/Form/Form'
import useTranslate from '../../../generic/hooks/useTranslate'
import TextInput from '../../../../components/inputs/Text/TextInput'
import ControllerInput from '../../../generic/components/Inputs/ReactHookForm/ControllerInput'
import TemplateLabel from '../../../generic/templates/Label'
import SubmitButton from '../../../generic/components/Buttons/SubmitButton'
import FormProps from '../../../generic/forms/interfaces/FormProps';
import TemplateFormDefault from "../../../generic/templates/Modal";
import cls from './Form.module.scss';
import CalendarInputDate from '../../../../components/inputs/Calendars/CalendarInputDate'
import FileUploaderInput from '../../../../components/inputs/FileUploader/FileUploaderInput'
import useCustomMachine from '../../../generic/hooks/useCustomMachine'
import _ from 'lodash'
import useCategories from '../../../../hooks/common/useCategories'
import CheckboxInput from '../../../generic/components/Inputs/Checkbox'
import { useDispatch } from 'react-redux'
import { addAssistantText, clearAssistatText } from '../../../generic/store/action'
import useToggle from '../../../generic/hooks/useToggle'

const schema = Yup.object().shape({
    title: Yup.string().required("This field is required").nullable(),
    file: Yup.object().required("This field is required").nullable()
})

const FormProfessionalHistory: React.FC<FormProps> = ({
    initialValues: defaultValues = {},
    onCompleteSubmit,
    Template = TemplateFormDefault,
    buttons = {},
    buttonSubmitProps,
    propsTemplate }) => {


    const { translate } = useTranslate();

    const [isInputFirstFocus, toggleIsInput] = useToggle(false)

    const handleSubmit = async values => {
        onCompleteSubmit && await onCompleteSubmit(values);
    }

    const { state } = useCustomMachine();

    const { getCategoryFromDocType, loading } = useCategories();


    const category = React.useMemo(() => {
        return getCategoryFromDocType(_.get(state, 'context.paramsData.type', ''));
    }, [loading]);

    const dispatch = useDispatch();

    React.useEffect(() => {
        if (category && category.key === "TEAM") {
            dispatch(addAssistantText(translate('MODULES.DOCUMENTS_STARTUP.IP_AND_GENERATE.FIRST_STEP.TOOLTIP_IP')));
        }


        return () => {
            dispatch(clearAssistatText())
        }

    }, [JSON.stringify(category)])



    return (
        <Form
            defaultValues={defaultValues}
            schema={schema}
            onSubmit={handleSubmit}
            onlySendDirty={false}
            optionsForm={{ mode: "onChange" }}
        >

            <Template buttons={{ ...buttons, submit: <SubmitButton  {...buttonSubmitProps}>Add</SubmitButton> }} props={propsTemplate}>


                {category && category.key === "TEAM" &&
                    <>

                        <TemplateLabel
                            label={''}
                            className={cls.TemplateLabel}
                        >
                            <ControllerInput
                                render={CheckboxInput}
                                name='subCategories'
                                defaultlabel={''}
                                options={[{
                                    label: 'This agreement has an IP provision',
                                    value: 'IPAssignment'
                                }]}

                            />
                        </TemplateLabel>
                    </>
                }


                <TemplateLabel
                    label={'Document name'}
                    isRequired
                    className={cls.TemplateLabel}
                >
                    <ControllerInput
                        render={TextInput}
                        name='title'
                        defaultlabel={''}
                        placeholder='Give this document a name'
                        onFocus={()=>{
                            if(!isInputFirstFocus){
                                dispatch(addAssistantText(translate('MODULES.DOCUMENTS_STARTUP.IP_AND_GENERATE.SECOND_STEP.TOOLTIP')));
                                toggleIsInput();
                            }
                        }}

                    />
                </TemplateLabel>


                <TemplateLabel
                    label={'Upload'}
                    isRequired
                    className={cls.TemplateLabel}
                >
                    <ControllerInput
                        render={FileUploaderInput}
                        name='file'
                        defaultlabel={''}
                        placeholder='Include numbers only'

                    />
                </TemplateLabel>


            </Template>

        </Form>
    )

}

export default FormProfessionalHistory;

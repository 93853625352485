import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import _ from "lodash";
import Form from 'src/components/forms/Form/Form';
import TextInput from 'src/components/inputs/Text/TextInput';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateLabel from 'src/modules/generic/components/Inputs/LabelInput';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import CountryJurisdiction from "src/modules/generic/components/CountryJurisdictionSelector";
import FileUploaderInput from 'src/components/inputs/FileUploader/FileUploaderInput';
import classes from './classes.module.scss';
import { HiddenFieldForm } from "src/components/forms/contents";

function LimitedPartnershipInfo() {
  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    if (!_.isEmpty(context?.LimitedPartnershipInfo)) {
      return context?.LimitedPartnershipInfo;
    } else {
      return context?.initialData?.stakeholder;
    }
  }, [context]);

  const handleCancel = () => {
    cancel();
  }

  const handleSubmit = async (values) => {
    next(values);
  }

  useEffect(() => {
    dispatch(addAssistantText(translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.LIMITED_PARTNERSHIP_DETAILS.STEP_1.TOOLTIP")));
    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch]);

  const schema = Yup.object().shape({
    registeredNumber: Yup.string().required(translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.LIMITED_PARTNERSHIP_DETAILS.REQUIRED")),
    previousName: Yup.string().nullable(),
    documentCertificate: Yup.object().when('previousName', {
      is: (previousName) => previousName !== null && previousName !== undefined && previousName !== '',
      then: Yup.object().nullable().required('Document Certificate is required when Previous Name is provided'),
      otherwise: Yup.object().nullable()
    }),
    nationality: Yup.string()
      .nullable()
      .required(translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.LIMITED_PARTNERSHIP_DETAILS.REQUIRED")),
    jurisdiction: Yup.string()
      .nullable()
      .required(translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.LIMITED_PARTNERSHIP_DETAILS.REQUIRED")),
    otherJurisdiction: Yup.string().when(['jurisdiction'], {
      is: 'OTHER',
      then: Yup.string()
        .nullable()
        .required(translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.LIMITED_PARTNERSHIP_DETAILS.REQUIRED")),
      otherwise: Yup.string()
        .nullable()
        .optional()
    }),
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.LIMITED_PARTNERSHIP_DETAILS.STEP_1.TITLE"),
          skeleton: null,
          subTitle: translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.LIMITED_PARTNERSHIP_DETAILS.SUBTITLE"),
          variant: 'simple'
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>{translate('BUTTON_NEXT')}</SubmitButton>,
        }}
      >
        <>
          <CountryJurisdiction
            showJurisdiction={true}
            unmounClearJurisdiction={false}
            hideLoading={true}
            name="nationality"
            countryLabel={translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.LIMITED_PARTNERSHIP_DETAILS.STEP_1.FIELD_COUNTRY")}
          />
          <TemplateLabel
            label={translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.LIMITED_PARTNERSHIP_DETAILS.STEP_1.FIELD_COMPANY_NUMBER")}
            isRequired={true}
          >
            <ControllerInput
              render={TextInput}
              name="registeredNumber"
              placeholder={translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.LIMITED_PARTNERSHIP_DETAILS.STEP_1.FIELD_COMPANY_NUMBER_PLACEHOLDER")}
            />
          </TemplateLabel>
          <TemplateLabel
            label={translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.LIMITED_PARTNERSHIP_DETAILS.STEP_1.FIELD_TAX_NUMBER")}
          >
            <ControllerInput
              render={TextInput}
              name="taxRegistrationNumber"
              placeholder={translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.LIMITED_PARTNERSHIP_DETAILS.STEP_1.FIELD_TAX_NUMBER_PLACEHOLDER")}
            />
          </TemplateLabel>
          <TemplateLabel
            label={translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.LIMITED_PARTNERSHIP_DETAILS.STEP_1.FIELD_PREVIOUS_NAME")}
          >
            <ControllerInput
              render={TextInput}
              name="previousName"
              placeholder={translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.LIMITED_PARTNERSHIP_DETAILS.STEP_1.FIELD_PREVIOUS_NAME_PLACEHOLDER")}
            />
          </TemplateLabel>
          <HiddenFieldForm
            fieldsWatch={"previousName"}
            conditionHideCallback={(value) => {
              return value !== "" && value !== null && value !== undefined;
            }}
          >
            <TemplateLabel
              label={translate("MODULES.STAKEHOLDER.ENTITY_STRUCTURES.LIMITED_PARTNERSHIP_DETAILS.STEP_1.FIELD_DOCUMENT_PLACEHOLDER")}
              variant="top"
            >
              <ControllerInput
                render={FileUploaderInput}
                name="documentCertificate"
                className={classes.fileLoad}
              />
            </TemplateLabel>
          </HiddenFieldForm>
        </>
        <div>&nbsp;</div>
      </TemplateWizard>
    </Form>
  );
}

export default LimitedPartnershipInfo;


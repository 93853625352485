import { useMemo } from 'react';
import TemplateWizardSkeletonMachine from 'src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardSkeletonMachine';
import AddOtherDirector from "../steps/AddOtherDirector";
import AddDirector from "../steps/AddDirector";
import LegalEntityStructure from "../steps/LegalEntityStructure";

const useSteps = () => {
  return useMemo(() => ({
    initialData: {
      render: TemplateWizardSkeletonMachine,
    },
    AddDirector: {
      render: AddDirector,
    },
    LegalEntityStructure: {
      render: LegalEntityStructure,
    },
    AddOtherDirector: {
      render: AddOtherDirector,
    },
    SaveStep: {
      render: TemplateWizardSkeletonMachine,
    },
  }), []);
}

export default useSteps;

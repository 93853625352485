import { Stack } from "@mui/material";
import { default as React } from 'react';
import img from 'src/images/Whats-next.svg';
import Button from 'src/modules/generic/components/Buttons/Button';
import Typography from "src/modules/generic/components/Texts/Typography";
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import cls from "./classes.module.scss";

/**
* Description Step: Step show when payment is completed in Delaware
*/

function ThanksForYourPaymentDelawareFormStep() {

  const { next } = useMachine();

  const handleSubmit = () => {
    next();
  }

  return (
    <TemplateWizard
      props={{
        skeleton: null,
        variant: 'twoColumns'
      }}
      buttons={{
        submit: <Button onClick={handleSubmit} variant='primary'>Submit to Clara</Button>,
      }}
    >
      <Stack spacing={4}>
        <Typography className={cls["cartTitle"]} weight={"bold"}>
          Great! <br />What’s next?
        </Typography>
        <Typography variant="h2" color={"black"} component={"p"} >
          Thanks for your payment, the receipt for this transaction can be found on the View Invoices page.
        </Typography>
        <Typography variant="h2" color={"black"} component={"p"} >
          Select “Submit to Clara” to send your application to Clara for review.
        </Typography>
      </Stack>
      <div className={cls["rightContentImage"]}>
        <img src={img} alt="" style={{ width: "40.5385rem" }} />
      </div>
    </TemplateWizard>
  );
}

export default ThanksForYourPaymentDelawareFormStep;

import useMutation from "src/hooks/custom/useMutation";
import useSession from "src/modules/session/hooks/useSession";

export const UPSERT_DATA_PROTECTION_DECLARATION = `
mutation upsertRenewalData(
    $startupId: ID!
    $groupCompanyId: ID!
	$renewalData: RenewalDataInput
  ) {
    upsertRenewalData(
      startupId: $startupId
      groupCompanyId: $groupCompanyId
      renewalData: $renewalData
    ) {
      id
  }
}
`;

const useUpdateDataProtectionDeclaration = () => {
    const {startupId} = useSession()
    const [mutation] = useMutation(
        UPSERT_DATA_PROTECTION_DECLARATION,
        { showSuccessNotification: true }
    ); 

    const save = async (groupCompanyId) =>{
        const data = {
            variables:{
                startupId,
                groupCompanyId,
                renewalData:{
                    companyTasksDetails:{
                        isDataProtectionContactConfirmed:true
                    }
                }
            }
        }        
        const submitData = await mutation(data)

        return submitData
    }

    return save;
};

export default useUpdateDataProtectionDeclaration;

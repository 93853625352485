import { createMachine } from "xstate";

const machine = createMachine({
  id: "UaEorGcc",
  initial: "initialData",
  states: {
    initialData: {
      invoke: {
        src: "initialData",
        id: "initialData",
        onDone: [
          {
            target: "AddEntityStep",
            actions: ["setContextAfterInvoke"],
          },
        ],
      },
    },
    AddEntityStep: {
      on: {
        YES: {
          target: "EvaluateStakeholders",
          actions: ["setContextAfterStep"],
        },
        NO: {
          target: "cancel",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    EvaluateStakeholders: {
      always: [
        {
          cond: "hasUAEorGCCStakeholder",
          target: "EligibleStakeholders",
        },
        {
          cond: "hasNotUAEorGCCStakeholder",
          target: "NoEligibleStakeholders",
        },
        {
          target: "NoEligibleStakeholders",
        },
      ],
    },
    EligibleStakeholders: {
      on: {
        PREV: {
          target: "AddEntityStep",
          actions: ["setContextAfterStep"],
        },
        NEXT: {
          target: "EvaluateHasMoreStakeholders",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    NoEligibleStakeholders: {
      on: {
        PREV: {
          target: "AddEntityStep",
          actions: ["setContextAfterStep"],
        },
        NEXT: {
          target: "EvaluateNominee",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    EvaluateNominee: {
      always: [
        {
          cond: "useNominee",
          target: "WhoIsSecondAuthorisedSignatoryFormStep",
        },
        {
          target: "ThirdPartyWithoutNomineeFormStep",
        },
      ],
    },
    EvaluateHasMoreStakeholders: {
      always: [
        {
          cond: "hasNotUAEorGCCStakeholder",
          target: "SelectAddAnotherStakeholderStep",
        },
        {
          target: "SaveStep",
        },
      ],
    },
    SelectAddAnotherStakeholderStep: {
      on: {
        PREV: {
          target: "EligibleStakeholders",
          actions: ["setContextAfterStep"],
        },
        YES: {
          target: "AddAnotherAuthorizedSignatoryFormStep",
          actions: ["setContextAfterStep"],
        },
        NO: {
          target: "SaveStep",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    AddAnotherAuthorizedSignatoryFormStep: {
      on: {
        PREV: {
          target: "SelectAddAnotherStakeholderStep",
          actions: ["setContextAfterStep"],
        },
        NEXT: {
          target: "SaveStep",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    WhoIsSecondAuthorisedSignatoryFormStep: {
      on: {
        PREV: {
          target: "NoEligibleStakeholders",
          actions: ["setContextAfterStep"],
        },
        NEXT: {
          target: "EvaluateWhoIsSecondAuthorisedSignatoryCond",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    EvaluateWhoIsSecondAuthorisedSignatoryCond: {
      always: [
        {
          cond: "isNomineeThirdParty",
          target: "ThirdPartyWithNomineeFormStep",
        },
        {
          target: "HowDidTheyEnterFormStep",
        },
      ],
    },
    HowDidTheyEnterFormStep: {
      on: {
        PREV: {
          target: "WhoIsSecondAuthorisedSignatoryFormStep",
          actions: ["setContextAfterStep"],
        },
        NEXT: {
          target: "SaveHowDidTheyEnterFormStep",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    ThirdPartyWithNomineeFormStep: {
      on: {
        PREV: {
          target: "WhoIsSecondAuthorisedSignatoryFormStep",
          actions: ["setContextAfterStep"],
        },
        NEXT: {
          target: "SaveThirdPartyWithNomineeFormStep",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    ThirdPartyWithoutNomineeFormStep: {
      on: {
        PREV: {
          target: "NoEligibleStakeholders",
          actions: ["setContextAfterStep"],
        },
        NEXT: {
          target: "SaveThirdPartyWithoutNomineeFormStep",
          actions: ["setContextAfterStep"],
        },
        CANCEL: {
          target: "cancel",
        },
      },
    },
    SaveHowDidTheyEnterFormStep: {
      invoke: {
        src: "SaveHowDidTheyEnterFormStep",
        id: "SaveHowDidTheyEnterFormStep",
        onDone: [
          {
            target: "finish",
          },
        ],
      },
    },
    SaveThirdPartyWithNomineeFormStep: {
      invoke: {
        src: "SaveThirdPartyWithNomineeFormStep",
        id: "SaveThirdPartyWithNomineeFormStep",
        onDone: [
          {
            target: "finish",
          },
        ],
      },
    },
    SaveThirdPartyWithoutNomineeFormStep: {
      invoke: {
        src: "SaveThirdPartyWithoutNomineeFormStep",
        id: "SaveThirdPartyWithoutNomineeFormStep",
        onDone: [
          {
            target: "finish",
          },
        ],
      },
    },
    SaveStep: {
      invoke: {
        src: "SaveStep",
        id: "SaveStep",
        onDone: [
          {
            target: "finish",
          },
        ],
      },
    },
    previousMachine: {
      entry: "previousMachine",
      type: "final",
    },
    cancel: {
      entry: "cancelMachine",
      type: "final",
    },
    finish: {
      entry: "stopMachine",
      type: "final",
    },
  },
});

export default machine;

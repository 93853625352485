import { default as React, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import Form from "src/components/forms/Form/Form";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import Button from "src/modules/generic/components/Buttons/Button";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import {
  clearAssistatText,
  addAssistantText,
} from "src/modules/generic/store/action";
import TextInput from "src/components/inputs/Text/TextInput";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import Checkbox from "src/components/inputs/Checkbox/Checkbox";
import classes from "src/modules/startup/onBoarding/roles/DirectorV2Modal/wizard/styles/director.module.scss";

function CreateStakeholderStep() {
  const { context, cancel, next, prev } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.CreateStakeholderStep ?? {};
  }, [context]);

  const { groupCompanyId, groupCompaniesMapped } = context?.initialData;

  const handleCancel = () => {
    cancel();
  };

  const handleSubmit = async (values) => {
    next(values);
  };

  const handlePrev = () => prev();

  useEffect(() => {
    dispatch(
      addAssistantText(
        'This stakeholder will be added to the map and you will have to complete tasks for them which will include providing their personal information and uploading their identification documents. Click here to read more on the information required.'
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const schema = Yup.object().shape({
    fullName: Yup.string().required("This field is required"),
    company: Yup.array()
      .required("This field is required")
      .min(1, "Please select a company"),
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: 'What is their name?',
          skeleton: null,
          subTitle: null,
          variant: "simple",
        }}
        buttons={{
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("BUTTON_CANCEL")}
            </Button>
          ),
          submit: <SubmitButton>Next</SubmitButton>,
          previous: (
            <Button onClick={handlePrev} variant="secondary">
              Previous
            </Button>
          ),
        }}
      >
        <TemplateLabel label="Full legal name" isRequired>
          <ControllerInput name="fullName" render={TextInput} />
        </TemplateLabel> 
          <TemplateLabel label="Company" isRequired>
            <ControllerInput
              render={Checkbox}
              name="company"
              variant="col_1"
              defaultValue={[groupCompanyId]}
              options={groupCompaniesMapped}
              classCheckboxLine={classes.CompanyCheckboxButton}
            />
          </TemplateLabel>
      </TemplateWizard>
    </Form>
  );
}

export default CreateStakeholderStep;

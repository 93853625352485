import moment from "moment";
import FormikHelper from "../../../../../utils/formikHelper";
import {Field} from "formik";
import TextInput from "../../../Inputs/TextInput";
import React from "react";

const PersonalInformation = ({ values, isEditTab, setFieldValue }) => {


    const handleDateChange = (e, field, form) => {

        const date = e === null ? null : moment(e).format("YYYY-MM-DD");
        FormikHelper.setValueInTheCorrectPosition(field.name, form, date);


    }




    return (<>

            <h1>Personal information</h1>


            <Field name='firstName'>
                {({ field, form }) => (
                    <TextInput
                        type='text'
                        label='First names'
                        field={field}
                        form={form}
                        placeholder={"As written in your passport"}
                        autoComplete='off'
                        data-zuko-name='First Names'
                    />
                )}
            </Field>


            <Field name='lastName'>
                {({ field, form }) => (
                    <TextInput
                        type='text'
                        label='Surname'
                        field={field}
                        form={form} isValid
                        placeholder={"As written in their passport"}
                        autoComplete='off'
                        data-zuko-name='Surname'

                    />
                )}
            </Field>

            <Field name='dateOfBirth'>
                {({ field, form }) => (
                    <TextInput
                        type='date'
                        label='Date of birth'
                        field={field}
                        form={form}
                        placeholder={"dd / mm / yyyy"}
                        prevComponentChange={e =>
                            handleDateChange(e, field, form)
                        }
                        autoComplete='off'
                        data-zuko-name='Date of Birth'

                    >

                    </TextInput>
                )}
            </Field>

            <Field name='occupation'>
                {({ field, form }) => (
                    <TextInput
                        type='text'
                        label='Occupation'
                        field={field}
                        form={form}
                        placeholder={"For example, lawyer or businessperson"}
                        autoComplete='off'
                        data-zuko-name='Occupation'

                    />
                )}
            </Field>

        </>
    )


}
export default PersonalInformation
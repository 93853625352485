import React, { useState, useEffect } from "react";
import classes from "./classes.module.scss";
import classnames from "classnames";
import DialogItem from "../../components/DialogItem";
import ReadMore from "../../components/ReadMore";
import PropTypes from "prop-types";
import Animations from "../../../generic/components/Animations/Lottie/Lottie"
import animationWelcome from "../../animations/welcome-02.json";
import animationLets1 from "../../animations/letsgetstarted-01.json";
import animationLets2 from "../../animations/letsgetstarted-02.json";
import animationCongrats from "../../animations/congrats-01.json";
import animationGoodJob from "../../animations/goodjob-01.json";
import inProgress from "../../animations/Formation-in-progress.json";
import isLocked from "../../animations/locked-animation.json";
import imageForming from "../../../../images/FormationsProgress.svg";

/**
  Assistance bubbles, migrate to TS
*/

const Bubble = ({
  iconType,
  color,
  variant,
  disabledLink,
  link,
  className,
  animation,
  children,
  percent = null,
  isNext = false
}) => {

  const [useAnimation, setUseAnimation] = useState(null);
  let aniLets = [animationLets1, animationLets2];

  const handleRandomAnimation = (aniArray) => {
    let min = Math.ceil(1);
    let max = Math.floor(aniArray.length);
    return (Math.floor(Math.random() * (max - min + 1)) + min) - 1;
  }

  useEffect(() => {
    if (animation === "welcome") {
      setUseAnimation(animationWelcome);
    }
    if (animation === "lets") {
      let value = handleRandomAnimation(aniLets);
      setUseAnimation(aniLets[value]);
    }
    if (animation === "congrats") {
      setUseAnimation(animationCongrats);
    }
    if (animation === "goodjob") {
      setUseAnimation(animationGoodJob);
    }
    if (animation === "formation") {
      setUseAnimation(inProgress);
    }
    if (animation === "locked") {
      setUseAnimation(isLocked);
    }
  }, [animation])

  return (
    <DialogItem
      disabled={variant === "disabled" || variant === "error"}
      iconType={iconType}
      color={color}
      alert={variant === 'alert'}
    >
      <div
        className={classnames(`${classes.Bubble} ${classes[color]} ${className}`, {
          [classes.disabled]: variant === "disabled",
          [classes.success]: variant === "success",
          [classes.error]: variant === "error" || variant === "alert",
          [classes.containImage]: animation !== null || percent !== null,
          [classes.isNext]: isNext,
        })}
      >

        {animation !== null &&
          <div className={classes.headerAnimation}>
            <Animations
              animation={useAnimation}
              width={"100%"}
              height={"12.4rem"}
            />
          </div>
        }
        {percent !== null &&
          <div className={classes.headerPercent}>
            <div className={classes.singleChart}>
              <svg viewBox="0 0 36 36" className={classes.circularChart}>
                <path className={classes.circleBg}
                  d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <path className={classes.circle}
                  stroke-dasharray={`${percent}, 100`}
                  d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <text x="18" y="20.35" className={classes.percentage}>{percent}%</text>
              </svg>
            </div>
            <img className={classes.imgForming} src={imageForming} alt={"Forming"} />
          </div>
        }
        <div
          className={classnames(`${classes.Content}`, {
            [classes.imgText]: animation !== null || percent !== null,
          })}
        >
          {children}
          {!disabledLink && link && (
            <div className={classes.linkSpace}>
              <ReadMore
                link={link}
              />
            </div>
          )}
        </div>
      </div>
    </DialogItem>
  );
};

Bubble.propTypes = {
  /** Property iconType */
  iconType: PropTypes.string,
  /** Property color */
  color: PropTypes.oneOf(["academy", "blue", "success", "pay", "upgrade", "chat"]),
  /** Property variant */
  variant: PropTypes.oneOf(["disabled", "success", "error", 'alert']),
  /** Property is link */
  link: PropTypes.string,
  /** Property link is disabled */
  disabledLink: PropTypes.bool,
  /** Property animation */
  animation: PropTypes.string,
  /** Property children */
  children: PropTypes.node,
  /** Property percent */
  percent: PropTypes.string,
};

Bubble.defaultProps = {
  disabledLink: false,
  iconType: "Assistant",
  color: "blue",
  animation: null,
  className: "",
};

export default Bubble;

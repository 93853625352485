import useNavigation from "src/modules/generic/hooks/useNavigation";
import { Constants } from "src/v1/utils/constants";
export enum Navigation {
    CAP_TABLE = 'captable',
    SHARE_CLASS = 'shareClass',
    CONVERTIBLES = 'convertibles',
    SHARES = 'shares',
    WARRANTS = 'warrants',
    ADD_OPTION = 'addOption',
    AMEND_OPTION_PLAN = 'amendOptionPlan',
    EDIT_AMEND_OPTION_PLAN = 'editAmendOptionPlan',
    VIEW_AMEND_OPTION_PLAN = 'viewAmendOptionPlan',
    ADD_SHARE_CLASS = 'addShareClass',
    ADD_CONVERTIBLE = 'addConvertible',
    ADD_SHARE = 'addShare',
    ADD_WARRANT = 'addWarrant',
    CANCEL_WARRANT = 'cancelWarrant',
    EDIT_CANCEL_WARRANT = 'editCancelWarrant',
    VIEW_CANCEL_WARRANT = 'viewCancelWarrant',
    EXERCISE_WARRANT = 'exerciseWarrant',
    EDIT_EXERCISE_WARRANT = 'editExerciseWarrant',
    VIEW_EXERCISE_WARRANT = 'viewExerciseWarrant',
    ADD_SHARE_CLASS_TRANSACTIONS = 'addShareClassTransaction',
    EDIT_SHARE_CLASS_TRANSACTIONS = 'editShareClassTransaction',
    VIEW_SHARE_CLASS_TRANSACTIONS = 'shareClassDetailTransaction',
    EDIT_OPTION = 'editOption',
    EDIT_SHARE_CLASS = 'editShareClass',
    EDIT_CONVERTIBLE = 'editConvertible',
    EDIT_SHARE = 'editShare',
    EDIT_WARRANT = 'editWarrant',
    ADD_CONVERT_CONVERTIBLE = 'addConvertConvertible',
    EDIT_CONVERT_CONVERTIBLE = 'editConvertConvertible',
    VIEW_CONVERT_CONVERTIBLE = 'viewConvertConvertible',
    ADD_CANCEL_CONVERTIBLE = 'addCancelConvertible',
    EDIT_CANCEL_CONVERTIBLE = 'editCancelConvertible',
    VIEW_CANCEL_CONVERTIBLE = 'viewCancelConvertible',
    TRANSFER_SHARE = 'transferShare',
    EDIT_TRANSFER_SHARE = 'editTransferShare',
    VIEW_SHARE_TRANSFER = 'viewShareTransfer',
    CANCEL_SHARE = 'cancelShare',
    UPDATE_VESTING_SCHEDULE_SHARE = 'updateVestingScheduleShare',
    EDIT_CANCEL_SHARE = 'editCancelShare',
    VIEW_SHARE_CANCEL = 'viewShareCancel',
    VIEW_SHARE_CLASS = 'viewShareClass',

    VIEW_SHARE = 'viewShare',
    VIEW_OPTIONS = 'viewOptions',
    VIEW_CONVERTIBLE = 'viewConvertible',
    CAP_TABLE_OVERVIEW = 'capTableOverview',
    SHARE_CLASS_LIST = 'shareClassList',
    CONVERTIBLES_LIST = 'convertiblesList',
    SHARES_LIST = 'sharesList',
    WARRANTS_LIST = 'warrantsList',
    OPTION_LIST = 'optionList',
    GENERATE_CONVERTIBLES = 'generateConvertibles',
    GENERATE_OPTIONS = 'generateOptions',
    MODELLING = "modelling",
    SETTINGS = 'settings',
    ADD_VALUATION = 'addValuation',
    ADD_VESTING = 'addVesting',
    EDIT_VALUATION = 'editValuation',
    EDIT_VESTING = 'editVesting',
    VIEW_VALUATION = 'viewValuation',
    VIEW_VESTING = 'viewVesting',
    ADD_OPTION_GRANT = 'addOptionGrant',
    EDIT_OPTION_GRANT = 'editOptionGrant',
    ADD_STOP_VESTING = 'addStopVesting',
    ADD_EXERCISE_MANUAL = 'addExerciseManual',
    ADD_EXERCISE_GENERATE = 'addExerciseGenerate',
    ADD_LAPSE = 'addLapse',
    ADD_CONVERT = 'addConvert',
    EDIT_STOP_VESTING = 'editStopVesting',
    EDIT_EXERCISE_MANUAL = 'editExerciseManual',
    EDIT_EXERCISE_GENERATE = 'editExerciseGenerate',
    EDIT_LAPSE = 'editLapse',
    EDIT_CONVERT = 'editConvert',
    VIEW_STOP_VESTING = 'viewStopVesting',
    VIEW_EXERCISE_MANUAL = 'viewExerciseManual',
    VIEW_EXERCISE_GENERATE = 'viewExerciseGenerate',
    VIEW_LAPSE = 'viewLapse',
    VIEW_CONVERT = 'viewConvert',
    VIEW_OPTION_GRANT = 'viewOptionGrant',
    GENERATE_GRANT = 'generateGrant',
    GROUP_COMPANY = 'groupCompany',
    STAKEHOLDER = 'stakeholder',
    UPGRADE = 'upgrade',
    VIEW_DOCUMENT_UPLOADED = 'viewDocumentUploaded',
    VIEW_DOCUMENT_GENERATED = 'viewDocumentGenerated',
    ERROR = 'error',

    MYEQUITY_SHARE_ISSUANCE = 'myequityShareIssuance',
    MYEQUITY_CONVERTIBLE = 'myequityConvertible',
    MYEQUITY_WARRANT = 'myequityWarrant',
    MYEQUITY_OPTION_GRANT = 'myequityOptionGrant',
    MYEQUITY_OPTION_GRANT_TRANSACTION_CONVERT = "myequityTransactionConvert",
    MYEQUITY_OPTION_GRANT_TRANSACTION_LAPSE = "myequityTransactionLapse",
    MYEQUITY_OPTION_GRANT_TRANSACTION_STOP_VESTING = "myequityTransactionStopVesting",
    MYEQUITY_OPTION_GRANT_TRANSACTION_EXERCISE_MANUAL = "myequityTransactionExerciseManual",
    MYEQUITY_OPTION_GRANT_TRANSACTION_EXERCISE_GENERATE = "myequityTransactionExerciseGenerate",
    MYEQUITY_SHARES_TRANSACTION_CANCEL = "myequityTransactionCancel",
    MYEQUITY_SHARES_TRANSACTION_TRANSFER = "myequityTransactionTransfer",
    MYEQUITY_CONVERTIBLES_TRANSACTION_CANCEL = "myequityConvertTransactionCancel",
    MYEQUITY_CONVERTIBLES_TRANSACTION_CONVERT = "myequityConvertTransactionConvert",
    MYEQUITY_WARRANTS_TRANSACTION_CANCEL = "myequityWarrantsTransactionCancel",
    MYEQUITY_WARRANTS_TRANSACTION_EXERCISE = "myequityWarrantsTransactionExercise",
}


const navigationTo = {

    [Navigation.UPGRADE]: Constants.PAGES['upgrade'],
    [Navigation.SETTINGS]: Constants.PAGES['capTable.mf.settings'],
    [Navigation.ADD_VALUATION]: Constants.PAGES['capTable.mf.valuation.add'],
    [Navigation.ADD_VESTING]: Constants.PAGES['capTable.mf.vesting.add'],
    [Navigation.EDIT_VALUATION]: Constants.PAGES['capTable.mf.valuation.edit'],
    [Navigation.EDIT_VESTING]: Constants.PAGES['capTable.mf.vesting.edit'],
    [Navigation.VIEW_VALUATION]: Constants.PAGES['capTable.mf.valuation.view'],
    [Navigation.VIEW_VESTING]: Constants.PAGES['capTable.mf.vesting.view'],
    [Navigation.MODELLING]: Constants.PAGES['capTable.mf.modelling'],
    [Navigation.CAP_TABLE]: Constants.PAGES['capTable.mf.captable'],
    [Navigation.SHARE_CLASS_LIST]: Constants.PAGES['capTable.mf.shareClass.list'],
    [Navigation.SHARE_CLASS]: Constants.PAGES['capTable.mf.shareClass.list'],
    [Navigation.ADD_SHARE_CLASS]: Constants.PAGES['capTable.mf.shareClass.add'],
    [Navigation.ADD_SHARE_CLASS_TRANSACTIONS]: Constants.PAGES['capTable.mf.shareClass.amend.add'],
    [Navigation.EDIT_SHARE_CLASS_TRANSACTIONS]: Constants.PAGES['capTable.mf.shareClass.amend.edit'],
    [Navigation.EDIT_SHARE_CLASS]: Constants.PAGES['capTable.mf.shareClass.edit'],
    [Navigation.VIEW_SHARE_CLASS]: Constants.PAGES['capTable.mf.shareClass.view'],
    [Navigation.SHARES]: Constants.PAGES['capTable.mf.shares'],
    [Navigation.ADD_SHARE]: Constants.PAGES['capTable.mf.shares.add'],
    [Navigation.EDIT_SHARE]: Constants.PAGES['capTable.mf.shares.edit'],
    [Navigation.VIEW_SHARE]: Constants.PAGES['capTable.mf.shares.view'],
    [Navigation.TRANSFER_SHARE]: Constants.PAGES['capTable.mf.shares.transfer.add'],
    [Navigation.EDIT_TRANSFER_SHARE]: Constants.PAGES['capTable.mf.shares.transfer.edit'],
    [Navigation.VIEW_SHARE_TRANSFER]: Constants.PAGES['capTable.mf.shares.transfer.view'],
    [Navigation.CANCEL_SHARE]: Constants.PAGES['capTable.mf.shares.cancel.add'],
    [Navigation.UPDATE_VESTING_SCHEDULE_SHARE]: Constants.PAGES['capTable.mf.shares.vestingSchedule.update'],
    [Navigation.EDIT_CANCEL_SHARE]: Constants.PAGES['capTable.mf.shares.cancel.edit'],
    [Navigation.VIEW_SHARE_CANCEL]: Constants.PAGES['capTable.mf.shares.cancel.view'],
    [Navigation.VIEW_SHARE_CLASS_TRANSACTIONS]: Constants.PAGES['capTable.mf.shareClass.amend.view'],
    [Navigation.SHARES_LIST]: Constants.PAGES['capTable.mf.shares.list'],
    [Navigation.WARRANTS_LIST]: Constants.PAGES['capTable.mf.warrants.list'],
    [Navigation.VIEW_OPTIONS]: Constants.PAGES['capTable.mf.options.view'],
    [Navigation.ADD_WARRANT]: Constants.PAGES['capTable.mf.warrants.add'],
    [Navigation.CANCEL_WARRANT]: Constants.PAGES['capTable.mf.warrants.cancel.add'],
    [Navigation.EDIT_CANCEL_WARRANT]: Constants.PAGES['capTable.mf.warrants.cancel.edit'],
    [Navigation.VIEW_CANCEL_WARRANT]: Constants.PAGES['capTable.mf.warrants.cancel.view'],
    [Navigation.EXERCISE_WARRANT]: Constants.PAGES['capTable.mf.warrants.exercise.add'],
    [Navigation.EDIT_EXERCISE_WARRANT]: Constants.PAGES['capTable.mf.warrants.exercise.edit'],
    [Navigation.VIEW_EXERCISE_WARRANT]: Constants.PAGES['capTable.mf.warrants.exercise.view'],
    [Navigation.ADD_OPTION]: Constants.PAGES['capTable.mf.options.add'],
    [Navigation.AMEND_OPTION_PLAN]: Constants.PAGES['capTable.mf.options.amend.add'],
    [Navigation.EDIT_AMEND_OPTION_PLAN]: Constants.PAGES['capTable.mf.options.amend.edit'],
    [Navigation.VIEW_AMEND_OPTION_PLAN]: Constants.PAGES['capTable.mf.options.amend.view'],
    [Navigation.EDIT_OPTION]: Constants.PAGES['capTable.mf.options.edit'],
    [Navigation.WARRANTS]: Constants.PAGES['capTable.mf.warrants.view'],
    [Navigation.EDIT_WARRANT]: Constants.PAGES['capTable.mf.warrants.edit'],
    [Navigation.OPTION_LIST]: Constants.PAGES['capTable.mf.options.list'],
    [Navigation.GENERATE_OPTIONS]: Constants.PAGES.chooseDocumentType,
    [Navigation.VIEW_CONVERTIBLE]: Constants.PAGES['capTable.mf.convertibles.view'],
    [Navigation.ADD_CONVERTIBLE]: Constants.PAGES['capTable.mf.convertibles.add'],
    [Navigation.EDIT_CONVERTIBLE]: Constants.PAGES['capTable.mf.convertibles.edit'],
    [Navigation.CONVERTIBLES_LIST]: Constants.PAGES['capTable.mf.convertibles.list'],
    [Navigation.ADD_CONVERT_CONVERTIBLE] : Constants.PAGES['capTable.mf.convertibles.convert.add'],
    [Navigation.EDIT_CONVERT_CONVERTIBLE] : Constants.PAGES['capTable.mf.convertibles.convert.edit'],
    [Navigation.VIEW_CONVERT_CONVERTIBLE] : Constants.PAGES['capTable.mf.convertibles.convert.view'],
    [Navigation.ADD_CANCEL_CONVERTIBLE] : Constants.PAGES['capTable.mf.convertibles.cancel.add'],
    [Navigation.EDIT_CANCEL_CONVERTIBLE] : Constants.PAGES['capTable.mf.convertibles.cancel.edit'],
    [Navigation.VIEW_CANCEL_CONVERTIBLE] : Constants.PAGES['capTable.mf.convertibles.cancel.view'],
    [Navigation.ADD_OPTION_GRANT]: Constants.PAGES['capTable.mf.options.grant.add'],
    [Navigation.EDIT_OPTION_GRANT]: Constants.PAGES['capTable.mf.options.grant.edit'],
    [Navigation.ADD_STOP_VESTING]: Constants.PAGES['capTable.mf.options.grant.addStopVesting'],
    [Navigation.ADD_EXERCISE_MANUAL]: Constants.PAGES['capTable.mf.options.grant.addExerciseManual'],
    [Navigation.ADD_EXERCISE_GENERATE]: Constants.PAGES['capTable.mf.options.grant.addExerciseGenerate'],
    [Navigation.ADD_LAPSE]: Constants.PAGES['capTable.mf.options.grant.addLapse'],
    [Navigation.ADD_CONVERT]: Constants.PAGES['capTable.mf.options.grant.addConvert'],
    [Navigation.EDIT_STOP_VESTING]: Constants.PAGES['capTable.mf.options.grant.editStopVesting'],
    [Navigation.EDIT_EXERCISE_MANUAL]: Constants.PAGES['capTable.mf.options.grant.editExerciseManual'],
    [Navigation.EDIT_EXERCISE_GENERATE]: Constants.PAGES['capTable.mf.options.grant.editExerciseGenerate'],
    [Navigation.EDIT_LAPSE]: Constants.PAGES['capTable.mf.options.grant.editLapse'],
    [Navigation.EDIT_CONVERT]: Constants.PAGES['capTable.mf.options.grant.editConvert'],
    [Navigation.VIEW_STOP_VESTING]: Constants.PAGES['capTable.mf.options.grant.viewStopVesting'],
    [Navigation.VIEW_EXERCISE_MANUAL]: Constants.PAGES['capTable.mf.options.grant.viewExerciseManual'],
    [Navigation.VIEW_EXERCISE_GENERATE]: Constants.PAGES['capTable.mf.options.grant.viewExerciseGenerate'],
    [Navigation.VIEW_LAPSE]: Constants.PAGES['capTable.mf.options.grant.viewLapse'],
    [Navigation.VIEW_CONVERT]: Constants.PAGES['capTable.mf.options.grant.viewConvert'],
    [Navigation.VIEW_OPTION_GRANT]: Constants.PAGES['capTable.mf.options.grant.view'],
    [Navigation.GENERATE_CONVERTIBLES]: Constants.PAGES.chooseDocumentType,
    [Navigation.GROUP_COMPANY]: Constants.PAGES.viewGroupCompany,
    [Navigation.STAKEHOLDER]: Constants.PAGES.viewStakeholderIndividual,
    [Navigation.VIEW_DOCUMENT_UPLOADED]: Constants.PAGES.viewDocumentUploaded,
    [Navigation.VIEW_DOCUMENT_GENERATED]: Constants.PAGES.viewDocumentGenerated,
    [Navigation.ERROR]: Constants.PAGES.error,
    [Navigation.GENERATE_GRANT]: Constants.PAGES.chooseDocumentType,

    [Navigation.MYEQUITY_SHARE_ISSUANCE]: Constants.PAGES['capTable.mf.myEquity.mySharesIssuance'],
    [Navigation.MYEQUITY_CONVERTIBLE]: Constants.PAGES['capTable.mf.myEquity.myConvertible'],
    [Navigation.MYEQUITY_WARRANT]: Constants.PAGES['capTable.mf.myEquity.myWarrant'],
    [Navigation.MYEQUITY_OPTION_GRANT]: Constants.PAGES['capTable.mf.myEquity.myOptionGrant'],
    [Navigation.MYEQUITY_OPTION_GRANT_TRANSACTION_CONVERT]: Constants.PAGES['capTable.mf.myEquity.grant.myTransaction.convert'],
    [Navigation.MYEQUITY_OPTION_GRANT_TRANSACTION_LAPSE]: Constants.PAGES['capTable.mf.myEquity.grant.myTransaction.lapse'],
    [Navigation.MYEQUITY_OPTION_GRANT_TRANSACTION_STOP_VESTING]: Constants.PAGES['capTable.mf.myEquity.grant.myTransaction.stopVesting'],
    [Navigation.MYEQUITY_OPTION_GRANT_TRANSACTION_EXERCISE_MANUAL]: Constants.PAGES['capTable.mf.myEquity.grant.myTransaction.exerciseManual'],
    [Navigation.MYEQUITY_OPTION_GRANT_TRANSACTION_EXERCISE_GENERATE]: Constants.PAGES['capTable.mf.myEquity.grant.myTransaction.exerciseGenerate'],
    [Navigation.MYEQUITY_SHARES_TRANSACTION_CANCEL]: Constants.PAGES['capTable.mf.myEquity.shares.myTransaction.cancel'],
    [Navigation.MYEQUITY_SHARES_TRANSACTION_TRANSFER]: Constants.PAGES['capTable.mf.myEquity.shares.myTransaction.transfer'],
    [Navigation.MYEQUITY_CONVERTIBLES_TRANSACTION_CANCEL]: Constants.PAGES['capTable.mf.myEquity.convertibles.myTransaction.cancel'],
    [Navigation.MYEQUITY_CONVERTIBLES_TRANSACTION_CONVERT]: Constants.PAGES['capTable.mf.myEquity.convertibles.myTransaction.convert'],
    [Navigation.MYEQUITY_WARRANTS_TRANSACTION_CANCEL]: Constants.PAGES['capTable.mf.myEquity.warrants.myTransaction.cancel'],
    [Navigation.MYEQUITY_WARRANTS_TRANSACTION_EXERCISE]: Constants.PAGES['capTable.mf.myEquity.warrants.myTransaction.exercise']
}
const paramsDefault = {
    [Navigation.GENERATE_CONVERTIBLES]: { type: 'CONVERTIBLE_NOTE' },
    [Navigation.GENERATE_OPTIONS]: { type: 'OPTION_PLAN' },
    [Navigation.GENERATE_GRANT]: { type: 'OPTION_GRANT' },
}
const useNavigationCaptable = () => {

    const { goTo } = useNavigation()

    const navigate = (to: Navigation, params: object = {}) => {
        let defaultParams = {}
        if (params[to]) {
            defaultParams = paramsDefault[to];
        }
        const url = navigationTo[to] || Constants.PAGES['capTable.mf.shareClass'];
        goTo(url, { ...defaultParams, ...params })
    }
    return navigate
}
export default useNavigationCaptable

import React, {Component} from "react";

import classes from "./SimpleButton.module.scss"


class SimpleButton extends Component {
    state = {
      
    };
    /* React Methods */
    componentWillUpdate(prevProps, prevState, snapshot) {
        if (prevProps.session !== this.props.session) {

        }
    }
    componentWillMount() {
       
    }
    /* Logic */
   
    /* Functionality */
    getValue = (prop)=>{
        if (!prop){
            return null;
        }
        if (typeof prop =='function'){
            return prop();
        }else{
            return prop;
        }
    }
    isDisabled=()=>{
        let value = this.getValue(this.props.disabled);
        if (!value){
            return false
        }
        return value;
    }
    /* Managaer Events*/
    handleClick = (e) => {
        if(this.props.onClick){
            this.props.onClick();
        }
      
    }
    handleBlur = (e) => {
        this.setState({
            showError: true
        })
    }
    /* Section to get dinamic components*/
   
    render() {

        return (
            <button
            onClick={this.handleClick}
            className={classes.SimpleButton}
            disabled={this.isDisabled()}
            >
                {this.props.children}
            </button>
        );
    }
}

export default (SimpleButton)

import React, {useEffect, useState} from 'react';
import {ServerConnect} from "../../v1/utils";
import Mustache from 'mustache'
import QUERY from "../../graphql/query/modules/incorporation/hasDraft";

export default function useHasDraftPending(flowName, id, kind) {
  const [isPending, setIsPending] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (flowName) {
      setLoading(true);
      const query = Mustache.render(QUERY, { flowName, id, kind });
      ServerConnect.graphQlQuery(query).then(result => {
        setIsPending(result.hasDraft);
        setLoading(false)
      });
      
    }
  }, [flowName, id, kind]);

  return { isPending, loading };
}

import {Reference, TypePolicy} from "@apollo/client";
import _ from 'lodash'
import {stakeholderConnections} from "../../../generic/graphql/cache";
const VestingSchedule:TypePolicy={
}
const StakeHolder:TypePolicy={
    fields: {
        vestingSchedules(existingVestingSchedules: Reference[], {canRead,cache,readField}){
            _.forEach(existingVestingSchedules,vs=>{
                if(canRead(vs)) {
                    const vestingSchedule = readField(vs)
                    if (!canRead(_.get(vestingSchedule,'relatedGrant'))) {
                        cache.evict({id: cache.identify({id: vestingSchedule})})
                        _.forEach(_.get(vestingSchedule,'vestingDates'), vd => {
                            const vestingDate = readField(vd)
                            if (canRead(_.get(vestingDate,'relatedEquity'))) {
                                cache.evict({id: cache.identify({id: vestingDate.relatedEquity})})
                            }
                            cache.evict({id: cache.identify({id: vestingDate})})
                        })
                    }
                }
            })
                cache.gc()
            return existingVestingSchedules?existingVestingSchedules.filter(canRead)
                :[]
        },
        connections:{
          read(){
              return stakeholderConnections()
          }
        },
        documents(existingDocument: Reference[], {canRead,cache}) {
            return existingDocument
                ? existingDocument.filter(canRead)
                : [];
        },
        equity(existingEquity: Reference[], {canRead,cache,readField}) {
            return existingEquity
                ? existingEquity.filter(canRead)
                : [];
        },
        warrants(existingEquity: Reference[], {canRead,cache}) {
            return existingEquity
                ? existingEquity.filter(canRead)
                : [];
        },
        convertibles(existingEquity: Reference[], {canRead}) {
            return existingEquity
                ? existingEquity.filter(canRead)
                : [];
        }
    }
}
const StakeholderPosition:TypePolicy={
    keyFields:['code'],
    fields: {
        responsibilities: {
            merge: false
        }
    }
}


const Role: TypePolicy = {
    keyFields: ["id", "entity",["id"]]
}
export {
    StakeHolder,
    StakeholderPosition,
    VestingSchedule,
    Role
}
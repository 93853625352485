import React, {useEffect, useState} from 'react';
import {Constants} from '../../../../utils/constants'
import {useDispatch} from "react-redux";
import classes from "../ViewForms.module.scss";
import {Modal} from '../../../index';
import _ from "lodash";
import Mustache from 'mustache';
import ServerConnect from "../../../../utils/ServerConnect";
import Loading from '../../../../../components/generic/Loading/Loading';
import ProposalAndEngagementsForm from '../../../../V2/Forms/ProposalAndEngagementsForm/V2/ProposalAndEngagementsForm';
import {LayoutForm} from '../../../../containers';
import {useParams} from 'react-router-dom';
import {stripTypenames} from '../../../../utils/graphql-util';

const sendEmailEngagement = `
mutation sendEmailEngagement(
    $matterId: ID!
    $engagementData: EngagementInput!
    ) {
    sendEmailEngagement(
    matterId:  $matterId 
    engagementData: $engagementData) {
		engagementType
		paymentLink
		engagementItems {
			 id
			 type
			 description
			 comments
			 amount
			 currency
		}
    }
}
`;

const getListPrice = `
getEngagementPriceList {
	id
    type
    description
    comments
    amount
    currency
  }`;

const getProposal = `
getMattersList(matterId: "{{matterId}}") {
  scoping {
      engagement {
        id
        status
        engagementType
        engagementItems {
            id
            engagementPriceId
            type
            description
            comments
            amount
            currency
          }
      }
      engagementDraft {
        id
        status
        engagementType
        engagementItems {
            id
            engagementPriceId
            type
            description
            comments
            amount
            currency
          }
      }
    }
  }
`;

const getEngagement = `
getMattersList(matterId: "{{matterId}}") {
    KYC {
       engagement {
         status
         paymentLink
         id
      	 engagementItems {
           id
           engagementPriceId
           type
           description
           comments
           amount
           currency
          }
       }
       engagementDraft {
         status
         id
      	 engagementItems{
           id
           engagementPriceId
           type
           description
           comments
           amount
           currency
          }
       }
    }
}`;

const getClientMain = `
  getMattersList(matterId:"{{matterId}}"){
    email
    clientFullName   
 }`;

const AddProposalAndEngagements = (props) => {

    const dispatch = useDispatch();
    const params = useParams();
    const [showModal, setShowModal] = useState(false);
    const [showCloseIcon,setShowCloseIcon] = useState(true);

    const closeForm = () => {
        const {tab} = params;
        dispatch({ type: 'GO_TO', page: Constants.PAGES.viewMatter, params: { id: props.params.matterId, tab } })
    };

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const submitData = (values) => {
        setLoading(true);
        ServerConnect.graphQlMutation(sendEmailEngagement, stripTypenames(mapValuesMutation(values)))
            .then(result => {
                setLoading(false);
                setShowModal(true);
            })
    };

    const getQuery = () => {
        const { mode, typeForm, matterId } = params;
        if (mode === 'ADD' && typeForm === 'PROPOSAL') {

            return Mustache.render(`{ ${getClientMain} ${getListPrice} ${getProposal} }`, { matterId });

        } else if (mode === 'EDIT' && typeForm === 'ENGAGEMENT') {

            return Mustache.render(`{ ${getClientMain} ${getListPrice} ${getEngagement} }`, { matterId });

        } else {
            return Mustache.render(`{ ${getClientMain} ${getListPrice} ${getProposal} ${getEngagement} }`, { matterId })
        }

    };

	const getInitialData = ({ email, clientFullName, scoping, KYC, getEngagementPriceList }) => {

		const { mode, typeForm } = params;
		let engagementItems = [], id = null, paymentLink = null;

		if (mode === 'ADD') {
			if (typeForm === 'PROPOSAL') {
				const engagementSent = _.get(scoping, 'engagement', null);
				const engagementDraft = _.get(scoping, 'engagementDraft', null);
				const engagement = engagementSent || engagementDraft || {};

				engagementItems = _.get(engagement, 'engagementItems', []);

				if (_.isEmpty(engagementItems)) {
					engagementItems = getEngagementPriceList
						.filter(service => service.type === 'default')
						.map(service => {
							return {
								engagementPriceId: service.id,
								...service
							}
						});
				}
				id = _.get(scoping, 'engagement.id');
			} else {
				// ENGAGEMENT
				engagementItems = _.get(scoping, 'engagement.engagementItems');
				id = _.get(KYC, 'engagement.id');
			}
		} else {
			// EDIT
			if (typeForm === 'PROPOSAL') {
				engagementItems = _.get(scoping, 'engagement.engagementItems') ||
					_.get(scoping, 'engagementDraft.engagementItems');

				id = _.get(scoping, 'engagement.id');
			} else {
				engagementItems = _.get(KYC, 'engagement.engagementItems') ||
					_.get(KYC, 'engagementDraft.engagementItems');

				id = _.get(KYC, 'engagement.id');
				paymentLink = _.get(KYC, 'engagement.paymentLink');

			}
		}

		return { email, clientFullName, id, paymentLink, engagementItems, servicesList: getEngagementPriceList }

	};

    const getDataServer = () => {
        ServerConnect.graphQlQuery(getQuery())
            .then(({ getEngagementPriceList, getMattersList }) => {
                setData(getInitialData({ getEngagementPriceList, ...getMattersList[0] }));
                setLoading(false);
            })
    };

	const mapValuesMutation = (values) => {
		const { engagementItems = [] } = values;

		return {
			matterId: params.matterId,
			engagementData: {
				engagementItems: _.map(engagementItems, (service) => {
					const { id, engagementPriceId, amount, ...rest } = service;
					return {
						engagementPriceId: _.isEmpty(engagementPriceId) ? id : engagementPriceId,
						amount: Number(amount),
						...rest
					};
				}),
				engagementType: params.typeForm
			},
		};
	};

	const handleOnClickModal = () => {
        const {tab} = params;
        dispatch({ type: 'GO_TO', page: Constants.PAGES.viewMatter, params: { id: props.params.matterId, tab } });
    };

    useEffect(() => {
        if (!data) {
            getDataServer();
        }
    }, [data]);

    return (
        <React.Fragment>
            <Loading loading={loading || !data} loadingDataComplete={data} showChildren={true} >
                <LayoutForm
                    title={""}
                    leftEducationId={Constants.CONTENTFUL.ENTRIES.FORMS[`${params.typeForm}_FORM`][`${params.mode}`].LEFT_EDUCATION_ID}
                    showCloseIcon={showCloseIcon}
                    handleClose={closeForm}>
					<ProposalAndEngagementsForm
						initialValues={data}
						onSubmit={submitData}
						closeForm={closeForm}
						showCloseIcon={(value)=>{setShowCloseIcon(value)}}
					/>
                </LayoutForm>
                <Modal open={showModal} showHeader={false} >
                    <div className={classes.ModalProposalAndEngagement}>
                        <h2>{_.capitalize(params.typeForm)} has been sent to {_.get(data, "clientFullName", "")}</h2>
                        <button onClick={handleOnClickModal} className={`genericBlue`}>Close</button>
                    </div>
                </Modal>
            </Loading>

        </React.Fragment>
    )
};

export default AddProposalAndEngagements;

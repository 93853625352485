import useMutation from "src/hooks/custom/useMutation";
import useSession from "src/modules/session/hooks/useSession";
import {GET_BANK_ACCOUNTS} from './useGetBankAccounts'
import { gql } from '@apollo/client';
const ADD_NEW_BANK_ACCOUNT =`
mutation addNewBankAccount($startupId: ID!,$groupCompanyId:ID!, $bankAccount: BankAccountInput) {
    addNewBankAccount(startupId: $startupId,groupCompanyId:$groupCompanyId, bankAccount: $bankAccount) {
        id
            code
            number
            additionalInfo
            ownerFullName 
            bankName
            country{
                code
                name
            }
    }
}
`
const useAddNewBankAccount=(config,props,groupCompanyId)=>{
    const {startupId} = useSession()
      const [mutation,loading] = useMutation(ADD_NEW_BANK_ACCOUNT, config,{...props, refetchQueries:groupCompanyId?[{query:gql`${GET_BANK_ACCOUNTS}`, variables:{startupId,groupCompanyId}}]:[] })
      return {mutation,loading}
  }
  export default useAddNewBankAccount
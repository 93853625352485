import React,{FC} from 'react'
import Button from "../../../../../generic/components/Buttons/Button";
import useCustomMachine from "../../../../../generic/hooks/useCustomMachine";
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import _ from "lodash";
import IsThereDocument from "../../../forms/IsThereDocuments";
import useTranslate from "../../../../../generic/hooks/useTranslate";
type FirstStepSubmit = (values:object) => Promise<any>
interface IsThereDocumentsProps {
    type: string
    tooltips?: object
    onCompleteSubmit?: FirstStepSubmit
    isTherePrevious?:boolean
}
const IsThereDocumentStep:FC<IsThereDocumentsProps>=({type,tooltips,onCompleteSubmit,isTherePrevious=true})=>{
    const {send,next,stepData}=useCustomMachine()
    const {translate}=useTranslate()
    const handlePrev=()=>{
        send("PREVIOUS")
    }
    const handleCancel=()=>{
        send('CANCEL')
    }
    const handleCompleteSubmit = async (values: any) => {
        onCompleteSubmit && await onCompleteSubmit(values)
        next(values);
    }
    return(<>
        <IsThereDocument
            tooltips={tooltips}
            initialValues={stepData}
            propsTemplate={{
                title: translate('MODULES.EQUITY.STEPS.IS_THERE_DOCUMENTS_STEP.TITLE',{type:translate(`MODULES.EQUITY.TYPES_PLURAL.${type}`),agreementType:translate(`MODULES.EQUITY.AGREEMENT_TYPE.${type}`)}),
                subTitle: translate('MODULES.EQUITY.STEPS.IS_THERE_DOCUMENTS_STEP.SUBTITLE',{type:translate(`MODULES.EQUITY.TYPES_PLURAL.${type}`)}),
            }}
            onCompleteSubmit={handleCompleteSubmit}
            Template={TemplateWizard}
            buttons={{cancel: <Button onClick={handleCancel}>Cancel</Button>,previous:isTherePrevious?<Button onClick={handlePrev}>Previous</Button>:null}}/>
    </>)
}
export default IsThereDocumentStep
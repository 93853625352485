import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import Form from "src/components/forms/Form/Form";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import Button from "src/modules/generic/components/Buttons/Button";
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import useMachine from "src/modules/generic/context/MachineContext/useMachine";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import {
  clearAssistatText,
  addAssistantText,
} from "src/modules/generic/store/action";
import TextareaInput from "src/components/inputs/Textarea/TextareaInput";

function ReviewCorporateStakeholderSofFormStep() {
  const { next, context, prev, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues =
    context?.ReviewSourceOfFunds ?? context.initialData.sourceOfFunds;

  const handleCancel = () => cancel();

  const handlePrev = () => prev();

  const handleSubmit = async (values) => next(values);

  useEffect(() => {
    dispatch(
      addAssistantText(
        translate(
          "MODULES.RENEWALS_ADGM.REVIEW_STAKEHOLDER_DETAILS.CORPORATE.COMMON.TOOLTIP"
        ),
        "alert"
      )
    );
    dispatch(
      addAssistantText(
        translate(
          "MODULES.RENEWALS_ADGM.REVIEW_STAKEHOLDER_DETAILS.CORPORATE.SOURCE_OF_FUNDS.TOOLTIP"
        ),
        "alert"
      )
    );
    return () => {
      dispatch(clearAssistatText());
    };
  }, [dispatch]);

  const schema = Yup.object().shape({
    sourceOfFunds: Yup.string().nullable().required("This field is required"),
    sourceOfWealth: Yup.string().nullable().required("This field is required"),
  });

  const stakeholderName = context?.initialData?.stakeholder?.fullName;

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: "onChange" }}
    >
      <TemplateWizard
        props={{
          title: translate(
            "MODULES.RENEWALS_ADGM.REVIEW_STAKEHOLDER_DETAILS.CORPORATE.SOURCE_OF_FUNDS.TITLE"
          ),
          subTitle: translate(
            "MODULES.RENEWALS_ADGM.REVIEW_STAKEHOLDER_DETAILS.CORPORATE.COMMON.SUBTITLE",
            { stakeholderName }
          ),
          skeleton: null,
          variant: "simple",
        }}
        buttons={{
          previous: (
            <Button variant="secondary" onClick={handlePrev}>
              {translate("BUTTON_PREVIOUS")}
            </Button>
          ),
          cancel: (
            <Button onClick={handleCancel} variant="secondary">
              {translate("BUTTON_CANCEL")}
            </Button>
          ),
          submit: <SubmitButton>{translate("BUTTON_NEXT")}</SubmitButton>,
        }}
      >
        <TemplateLabel
          variant="top"
          label={translate("MODULES.STAKEHOLDER.FORM.SOURCE_OF_FUNDS.FUNDS")}
          isRequired
        >
          <ControllerInput
            name="sourceOfFunds"
            render={TextareaInput}
            placeholder="Please specify where payments by this Corporate Stakeholder come from (e.g., a UAE bank account in the name of the Corporate Stakeholder)."
            rows={6}
          />
        </TemplateLabel>
        <TemplateLabel
          variant="top"
          label={translate("MODULES.STAKEHOLDER.FORM.SOURCE_OF_FUNDS.WEALTH")}
          isRequired
        >
          <ControllerInput
            name="sourceOfWealth"
            render={TextareaInput}
            placeholder="Please specify how this company generates its revenue."
            rows={8}
          />
        </TemplateLabel>
      </TemplateWizard>
    </Form>
  );
}

export default ReviewCorporateStakeholderSofFormStep;

import React, { FC, useEffect, useRef } from 'react';
import { useDispatch } from "react-redux";
import * as Yup from 'yup';
import Form from "../../../../../components/forms/Form/Form";
import SubmitButton from "src/modules/generic/components/Buttons/SubmitButton";
import TextareaInput from "../../../../../components/inputs/Textarea/TextareaInput";
import Button from "../../../../../modules/generic/components/Buttons/Button";
import FormProps from "../../../../../modules/generic/forms/interfaces/FormProps";
import { addAssistantText, clearAssistatText } from "../../../../../modules/generic/store/action";
import TemplateFormDefault from "../../../../../modules/generic/templates/Modal";
import ControllerInput from "../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import classes from './classes.module.scss';
import _ from 'lodash';
import Typography from "../../../../../modules/generic/components/Texts/Typography";

const schema = Yup.object().shape({
  explainComment: Yup.string().nullable().required("Required"),
});

interface Props extends FormProps {
  initialValues: any,
  onCompleteSubmit: any,
  key: string,
  handlePrev: any,
  questionData: any,
}

const EditQuestion: FC<Props> = ({
  initialValues,
  onCompleteSubmit,
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
  handlePrev,
  questionData,
  key,
}) => {

  const refForm = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    handeAddTooltips();
    return () => {
      dispatch(clearAssistatText());
    }
  }, [questionData.toolTip]);

  const handeAddTooltips = () => {
    dispatch(clearAssistatText());
    if (questionData.toolTips.length > 0) {
      _.map(questionData.toolTips, (tooltip) => {
        return dispatch(addAssistantText(tooltip.label));
      });
    }
  }

  return (
    <>
      <Form
        key={key}
        defaultValues={initialValues || {}}
        schema={schema}
        onSubmit={onCompleteSubmit}
        ref={refForm} optionsForm={{ mode: "onChange" }}
        onlySendDirty={false}
      >
        <Template 
          props={propsTemplate} 
          buttons={{
            ...buttons,
            submit: <SubmitButton {...buttonSubmitProps}>Save changes</SubmitButton>,
            previous: questionData.handlePrevious && <Button onClick={handlePrev}>Previous</Button>,
          }}
          >
            <Typography
              className={classes.questionFont}
            >
              {questionData.explaind}
            </Typography>
            <ControllerInput
              name={'explainComment'}
              render={TextareaInput}
              className={classes.TextArea}
              placeholder={questionData.explaindPlaceholder}
              defaultValue={""}
            />
        </Template>
      </Form>
    </>
  )
}

export default EditQuestion
import React from 'react';
import _ from "lodash";
import PropTypes from "prop-types"
import Divider from '@mui/material/Divider';
import DetailsTabTitle from "src/components/templates/DetailsTabTitle";
import { RoleAccess } from "src/v1/components";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import Button from "src/modules/generic/components/Buttons/Button";
import useModal from "src/modules/generic/hooks/useModal";
import { Constants } from 'src/v1/utils/constants';
import TwoColumnsViewer from "src/modules/generic/components/TwoColumsViewer";
import ModalPlacesOfBusiness from  'src/modules/startup/Stakeholder/PlaceOfBusiness';
import classes from '../../styles.module.scss'

function PlaceOfBusinessPanel({ data, isLock }) {
  const { translate } = useTranslate();
  const { openModal, closeModal } = useModal();

  const actionButton = <RoleAccess action={Constants.ACTIONS.EDIT_PROFILE} noAccess={undefined} actions={undefined} params={undefined}>
    <Button variant="card" onClick={() => { openModal(ModalPlacesOfBusiness, { handleCloseModal: closeModal, isEdit:true }) }} disabled={isLock}>
      {translate("VIEW_COMPANY_TAB_BUTTON_DETAILS_EDIT")}
    </Button>
  </RoleAccess>;

  return (
    <>
      <Divider className={classes.divider} />
      <DetailsTabTitle title={translate("VIEW_COMPANY_TAB_LABEL_PLACE_OF_BUSINESS")} buttons={actionButton} />
      <TwoColumnsViewer
        itemsCol1={[
          {
            label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_STATE"),
            value: _.get(data, "formationData.placeOfBusiness.state"),
          },
          {
            label: translate("VIEW_COMPANY_TAB_LABEL_DETAILS_CITY"),
            value: _.get(data, "formationData.placeOfBusiness.city"),
          },
        ]}
      />
    </>
  );
}

PlaceOfBusinessPanel.propTypes = {
  data: PropTypes.any,
  isLock: PropTypes.bool,
  isEdit: PropTypes.bool,
}

export default PlaceOfBusinessPanel;
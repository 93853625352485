import { createMachine } from 'xstate';

const machine =
  createMachine({
    id: 'AdgmTectStartup',
    initial: 'TechStartupEligibilityFormStep',
    states: {
      TechStartupEligibilityFormStep: {
        on: {
          PREV: {
            target: 'previousMachine',
          },
          NEXT: {
            target: 'EvaluateEligibility',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      EvaluateEligibility: {
        always: [
          {
            cond: 'isQualified',
            target: 'GetWhatIsYourStartupData'
          },
          {
            cond: 'isNotQualified',
            target: 'TechStartupConciergeFormStep'
          },
          {
            cond: 'isUnsure',
            target: 'CallTeamExpertsFormStep'
          },
        ]
      },
      GetWhatIsYourStartupData: {
        invoke: {
          src: 'GetWhatIsYourStartupData',
          id: 'GetWhatIsYourStartupData',
          onDone: [
            {
              target: 'WhatIsYourStartupFormStep',
              actions: ['setContextAfterInvoke']
            }
          ],
          onError: [
            {
              target: 'TechStartupEligibilityFormStep'
            }
          ]
        }
      },
      WhatIsYourStartupFormStep: {
        on: {
          PREV: {
            target: 'TechStartupEligibilityFormStep',
            actions: ['setContextAfterStep']
          },
          NEXT: {
            target: 'GetAdgmTechStartupAuthorisedSignatoryData',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      TechStartupConciergeFormStep: {
        on: {
          PREV: {
            target: 'TechStartupEligibilityFormStep',
            actions: ['setContextAfterStep']
          },
          CONCIERGE: {
            // target: 'EvaluateUseConciergeOrChangeJurisdiction',
            target: 'AddWithClaraConciergeSubMachineStep',
            actions: ['setContextAfterStep','savePreviousStep']
          },
          CHANGE_JURISDICTION: {
            target: 'chooseJurisdiction',
            actions: ['setContextAfterStep','savePreviousStep']
          },
          CANCEL:{
            target: 'cancel'
          }
        }
      },
      AddWithClaraConciergeSubMachineStep: {
        on: {
          PREV: {
            target: 'EvaluateAdgmWhereToBackAfterFormWithConciergeInfo',
          },
          NEXT: {
            target: 'finish',
          },
          CANCEL:{
            target: 'cancel'
          }
        }
      },
      CallTeamExpertsFormStep: {
        on: {
          PREV: {
            target: 'TechStartupEligibilityFormStep',
            actions: ['setContextAfterStep']
          },
          NEXT: {
            target: 'SaveStep_teamExperts',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      SaveStep_teamExperts: {
        invoke: {
          src: 'SaveStep_teamExperts',
          id: 'SaveStep_teamExperts',
          onDone: [
            {
              target: 'ClaraInTouchFormStep',
            }
          ],
          onError: [
            {
              target: 'CallTeamExpertsFormStep'
            }
          ]
        }
      },
      ClaraInTouchFormStep: {
        on: {
          CANCEL: {
            target: 'finish'
          }
        }
      },
      GetAdgmTechStartupAuthorisedSignatoryData: {
        invoke: {
          src: 'GetAdgmTechStartupAuthorisedSignatoryData',
          id: 'GetAdgmTechStartupAuthorisedSignatoryData',
          onDone: [
            {
              target: 'AdgmTechStartupAuthorisedSignatoryFormStep',
              actions: ['setContextAfterInvoke']
            }
          ],
          onError: [
            {
              target: 'TechStartupEligibilityFormStep'
            }
          ]
        }
      },
      AdgmTechStartupAuthorisedSignatoryFormStep: {
        on: {
          PREV: {
            target: 'WhatIsYourStartupFormStep',
            actions: ['setContextAfterStep']
          },
          NEXT: {
            target: 'EvaluateAdgemAuthorisedSignatory',
            actions: ['setContextAfterStep','savePreviousStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      EvaluateAdgemAuthorisedSignatory: {
        always: [
          {
            cond: 'isADirectorOrShareholder',
            target: 'AddADGMCompany'
          },
          {
            cond: 'isAThirdParty',
            target: 'AddADGMCompany'
          },
          {
            cond: 'isAClarasNominee',
            target: 'AddADGMCompany'
          },
          {
            cond: 'isAClaraConcierge',
            target: 'AddWithClaraConciergeSubMachineStep'
          },
        ]
      },
      EvaluateAdgmWhereToBackAfterFormWithConciergeInfo: {
        always: [
          {
            cond: 'isAdgmTechStartupAuthorisedSignatoryFormStep',
            target: 'AdgmTechStartupAuthorisedSignatoryFormStep'
          },
          {
            cond: 'isTechStartupConciergeFormStep',
            target: 'TechStartupConciergeFormStep'
          },
          {
            target: 'TechStartupEligibilityFormStep'
          }
        ]
      },
      AddADGMCompany: {
        on: {
          PREV: {
            target: 'AdgmTechStartupAuthorisedSignatoryFormStep',
            actions: ['setContextAfterStep']
          },
          NEXT: {
            target: 'SaveStep_ADGM',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'cancel'
          }
        }
      },
      SaveStep_ADGM: {
        invoke: {
          src: 'SaveStep_ADGM',
          id: 'SaveStep_ADGM',
          onDone: [
            {
              target: 'FormInADGMSuccess',
            }
          ],
          onError: [
            {
              target: 'AddADGMCompany'
            }
          ]
        }
      },
      FormInADGMSuccess: {
        on: {
          NEXT: {
            target: 'finish'
          },
        }
      },
      chooseJurisdiction: {
        entry: 'chooseJurisdiction',
        type: 'final'
      },
      cancel: {
        entry: 'cancelMachine',
        type: 'final'
      },
      finish: {
        entry: 'stopMachine',
        type: 'final'
      },
      previousMachine: {
        entry: 'previousMachine',
        type: 'final'
      },
    }
  });

export default machine;

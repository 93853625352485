const TEXTS = {
    TITLE_COMPANY: 'Is there a company Shareholder Register listing this share position?',
    TITLE_SHARE: 'Select a Shareholder Register',
    TITLE_CONVERTIBLE: 'Select an agreement',
    TITLE_OPTION: 'Select an agreement',
    TITLE_OPTION_SIP: 'Select a Share Incentive Plan (SIP)',
    TITLE_WARRANT: 'Select a Warrant Agreement',
    SUBTITLE: 'Company equity',
    TITLE: 'Select Shareholder Register',
    SUBTITLE_SHARE: 'Equity positions - Add share position',
    SUBTITLE_CONVERTIBLE: 'Equity positions - Add convertible',
    SUBTITLE_OPTION: 'Equity positions - Add option grant',
    SUBTITLE_WARRANT: 'Equity positions - Add warrant',
}
export default TEXTS
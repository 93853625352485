import React, {useState} from 'react';
import {ClaraCheckForm, Text} from '../index';
import _ from "lodash";
import {Field} from "formik";
import classes from "./Chat.module.scss";
import FactoryChatForm from './FactoryChatForm';
import FactoryChatFormConfig from './FactoryChatFormConfig.js';
import usePeps from '../../Hooks/usePeps';

const ChatKYCEntityPepIndividuals = ({ responseKeyName,initialValueStart, submitQuestion }) => {
  const nameField = responseKeyName ? responseKeyName + "." : "";
  const [openModal, setOpenModal] = useState(false);
  const { peps } = usePeps(initialValueStart.draftId)
  const [listStateholder, setListStateholder] = useState();
  const handleClose = () => {
    setOpenModal(false)
  }


  return (
    <React.Fragment>
      <div className={`${classes.ChatQuestionnaire} ${classes.ChatAdditionalSignature}`}>
        <div className={classes.contentField}>
          <button className={`${classes.buttonWithAutomatic} ${classes.buttonOption} `} type="button" onClick={() => { setOpenModal(true) }}>
            <Text uuid={`KYC_ENTITY_PEP_INDIVIDUALS_BUTTON`} />
          </button>
        </div>
        <div className={classes.contentField}>
        <h4><Text uuid={"KYC_ENTITY_PEP_INDIVIDUALS_TEXT_PEP"} /></h4>
          <Field name={nameField + "aditionalSignatures"}>
            {({ field, form }) => (
              <ClaraCheckForm
                openForm={openModal}
                cbClose={handleClose}
                field={field}
                form={form}
                list={peps}
                initialValues={{ formKey: "INDIVIDUAL_FORM" }}
                optionsList={{
                  fieldId: _.get(FactoryChatFormConfig, "INDIVIDUAL_FORM_PEPS" + ".id", "id"),
                  fieldLabel: _.get(FactoryChatFormConfig, "INDIVIDUAL_FORM_PEPS" + ".fieldName", null),
                }}
                optionsForm={{
                  titleKey: _.get(FactoryChatFormConfig, "INDIVIDUAL_FORM_PEPS.titleKey", null),
                  educationId: _.get(FactoryChatFormConfig, "INDIVIDUAL_FORM_PEPS.leftEducationId", null),
                  muttation: _.get(FactoryChatFormConfig, "INDIVIDUAL_FORM_PEPS.muttation", null),
                  getIdFromMuttation: _.get(FactoryChatFormConfig, "INDIVIDUAL_FORM_PEPS.getIdFromMuttation", null),
                  paramsMuttaion: initialValueStart,
                  getVariablesMutation: _.get(FactoryChatFormConfig, "INDIVIDUAL_FORM_PEPS.getVariablesMutation", null),
                  succesNotification: "NOTIFICATION_SUCCESS",
                  errorNotification: "NOTIFICATION_BAD",
                  muttationRemove: _.get(FactoryChatFormConfig, `INDIVIDUAL_FORM_PEPS.muttationRemove`, null),
                  getVariablesMutationRemove: _.get(FactoryChatFormConfig, "INDIVIDUAL_FORM_PEPS.getVariablesMutationRemove", null),
                
                }}
                optionsSelect={{
                  auto: true,
                  canSelect: false,
                }}
                optionsOperation={{
                  canAdd: false,
                  canEdit: true,
                  canRemove: true,
                }}
                optionsView={{
                  recordsByLine: 7
                }}
                className={{}}
              >
                <FactoryChatForm formKey={"INDIVIDUAL_FORM"} ></FactoryChatForm>
              </ClaraCheckForm>
            )}
          </Field>
        </div>
      </div>
    </React.Fragment >
  );
}
export default ChatKYCEntityPepIndividuals;

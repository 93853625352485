import React from 'react';
import _ from "lodash";
import FormIndiviual from '../../containers/Forms/KYC/Individual/FormIndividual';
import OnBehalfForm from '../../containers/Forms/KYC/OnBehalf/OnBehalfForm';
import CompanyForm from '../../containers/Forms/KYC/Company/CompanyForm';
import { Constants } from "../../utils";
import { modifyDataFileKYC, stripField, stripTypenames } from "../../utils/graphql-util";
import { addIndividual } from '../../graphql/mutations/matters';

export default {
  "INDIVIDUAL_FORM_PEPS": {
    id: "id",
    fieldName: (data) => {
      return _.get(data, "firstName", "") + " " + _.get(data, "lastName", "");
    },
    muttation: `
    mutation upsertPEPDraft(
      $individual: KYCIndividualInput!
      $individualId: String
      $draftId: ID
   
    ) {
      upsertPEPDraft(
        individual: $individual
        individualId: $individualId
        draftId: $draftId
      ) 
    }    
    `,

    titleKey: "KYC_TITLE_FORM_INDIVIDUAL_FORM",
    leftEducationId: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_INDIVIDUAL_FORM.EDIT.LEFT_EDUCATION_ID,
    leftEducationId_ADD: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_INDIVIDUAL_FORM.ADD.LEFT_EDUCATION_ID,
    leftEducationId_EDIT: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_INDIVIDUAL_FORM.EDIT.LEFT_EDUCATION_ID,
    getAvatarLabel: (obj) => {
      return _.get(obj, "firstName", "") + " " + _.get(obj, "lastName", "");
    },
    getForm: (props) => {
      return <FormIndiviual {...props} initialValues={{ ...props.initialValues, isCompanyFlow: false }} />
    },
    getVariablesMutation: (params, values) => {
      let KYCIndividualData = { ...values };
      delete KYCIndividualData.isCompanyFlow;
      delete KYCIndividualData.type;
      delete KYCIndividualData.formKey;
      delete KYCIndividualData.isDirector
      delete KYCIndividualData.isShareholder
      delete KYCIndividualData.isComplete
      delete KYCIndividualData.jurisdiction;
      delete KYCIndividualData.otherJurisdiction;
      KYCIndividualData = stripTypenames(KYCIndividualData);

      const draftId = _.get(params, "draftId", "");

      return {
        individual: KYCIndividualData,
        individualId: KYCIndividualData.id,
        draftId
      }
    },
    muttationRemove: `
      mutation removePEPDraft( $individualId: ID!,  $draftId: ID!) {
      removePEPDraft( individualId: $individualId,  draftId: $draftId)
    }`,
    getVariablesMutationRemove: (params, values) => {
      const draftId = _.get(params, "draftId", "");
      const individualId = _.get(params, "id", null);

      return {
        draftId,
        individualId
      }
    },
    getIdFromMuttation: (result) => {
      return _.get(result, "upsertPEPDraft");
    },
    succesNotification: "Individual updated successfully"
  },
  "INDIVIDUAL_CORPORATE_FORM": {
    fieldName: "companyName",
    titleKey: "KYC_TITLE_FORM_COMPANY_FORM",
    muttation: `mutation upsertKYCCompanyDraftFlow(
      $matterId: String!
      $KYCCompanyData: KYCCompanyInput!
      $KYCCompanyId: String
      $draftId: ID!
    ) {
      upsertKYCCompanyDraftFlow(
        matterId:$matterId
        KYCCompanyData: $KYCCompanyData
        KYCCompanyId: $KYCCompanyId
        draftId: $draftId
      ) 
    }
    `,
    muttationRemove: `
    mutation removeComponentDirectorOrShareholder(matterId: String, $draftId: String, individualOrCompanyId: String!) {
      removeComponentDirectorOrShareholder(matterId: $matterId, draftId: $draftId, individualOrCompanyId: $individualOrCompanyId)
    } 
    `,
    getVariablesMutationRemove: (params, values) => {
      const matterId = _.get(params, "matterId", params);
      const draftId = _.get(params, "draftId", "");
      const individualOrCompanyId = _.get(params, "id", null);

      return {
        matterId,
        draftId,
        individualOrCompanyId
      }
    },
    leftEducationId_ADD: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.ADD.LEFT_EDUCATION_ID,
    leftEducationId: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.EDIT.LEFT_EDUCATION_ID,
    leftEducationId_EDIT: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.EDIT.LEFT_EDUCATION_ID,
    getAvatarLabel: (obj) => {
      return _.get(obj, "companyName", "");
    },
    getForm: (props) => {
      return <CompanyForm {...props} />
    },
    getVariablesMutation: (params, values) => {
      let KYCCompanyData = { ...values };
      delete KYCCompanyData.type;
      delete KYCCompanyData.formKey;
      delete KYCCompanyData.isShareholder
      delete KYCCompanyData.isDirector
      delete KYCCompanyData.isComplete
      delete KYCCompanyData.jurisdiction

      KYCCompanyData = stripTypenames(KYCCompanyData);
      const matterId = _.get(params, "matterId", params);
      const draftId = _.get(params, "draftId", "")
      return {
        draftId,
        matterId,
        KYCCompanyData,
        KYCCompanyId: KYCCompanyData.id
      }
    },
    getIdFromMuttation: (result) => {
      return _.get(result, "upsertKYCCompanyDraftFlow");
    }
  },
  "DIRECTOR_CORPORATE_FORM": {
    fieldName: "companyName",
    titleKey: "KYC_TITLE_FORM_COMPANY_FORM",
    muttation: `mutation upsertKYCCompanyDraftFlow(
      $matterId: String!
      $KYCCompanyData: KYCCompanyInput!
      $KYCCompanyId: String
      $draftId: ID!
    ) {
      upsertKYCCompanyDraftFlow(
        matterId:$matterId
        KYCCompanyData: $KYCCompanyData
        KYCCompanyId: $KYCCompanyId
        draftId: $draftId
      ) 
    }
    `,
    muttationRemove: `
    mutation removeComponentDirectorOrShareholder($matterId: String, $draftId: String, $individualOrCompanyId: String!) {
      removeComponentDirectorOrShareholder(matterId: $matterId, draftId: $draftId, individualOrCompanyId: $individualOrCompanyId)
    } 
    `,
    getVariablesMutationRemove: (params, values) => {
      const matterId = _.get(params, "matterId", params);
      const draftId = _.get(params, "draftId", "");
      const individualOrCompanyId = _.get(params, "id", null);

      return {
        matterId,
        draftId,
        individualOrCompanyId
      }
    },
    leftEducationId_ADD: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.ADD.LEFT_EDUCATION_ID,
    leftEducationId: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.EDIT.LEFT_EDUCATION_ID,
    leftEducationId_EDIT: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.EDIT.LEFT_EDUCATION_ID,
    getAvatarLabel: (obj) => {
      return _.get(obj, "companyName", "");
    },
    getForm: (props) => {
      return <CompanyForm {...props} />
    },
    getVariablesMutation: (params, values) => {
      let KYCCompanyData = { ...values };
      delete KYCCompanyData.type;
      delete KYCCompanyData.formKey;
      delete KYCCompanyData.isShareholder
      delete KYCCompanyData.isDirector
      delete KYCCompanyData.isComplete
      delete KYCCompanyData.jurisdiction

      KYCCompanyData = stripTypenames(KYCCompanyData);
      const matterId = _.get(params, "matterId", params);
      const draftId = _.get(params, "draftId", "")
      return {
        draftId,
        matterId,
        KYCCompanyData,
        KYCCompanyId: KYCCompanyData.id
      }
    },
    getIdFromMuttation: (result) => {
      return _.get(result, "upsertKYCCompanyDraftFlow");
    }
  },
  "QUEST_CORPORATE_DIRECTOR_FORM": {
    fieldName: "companyName",
    titleKey: "QUESTIONNAIRE_COMPANY_FORM_TITLE",
    muttation: `mutation upsertQuestionnaireCompany(
      $matterId: String!
      $KYCCompanyData: KYCCompanyInput! 
      $KYCCompanyId: String
    ) {
      upsertQuestionnaireCompany(matterId: $matterId, KYCCompanyData: $KYCCompanyData, KYCCompanyId: $KYCCompanyId) {
        id
        companyName
      }
    }
    `,
    leftEducationId: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.ADD.LEFT_EDUCATION_ID,
    leftEducationId_ADD: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.ADD.LEFT_EDUCATION_ID,
    leftEducationId_EDIT: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.EDIT.LEFT_EDUCATION_ID,
    getAvatarLabel: (obj) => {
      return _.get(obj, "companyName", "");
    },
    getForm: (props) => {
      return <CompanyForm {...props} isFlow={true} />
    },
    getVariablesMutation: (paramsMutattion, values) => {
      let KYCCompanyData = { ...values };
      delete KYCCompanyData.type;
      delete KYCCompanyData.formKey;
      delete KYCCompanyData.isShareholder
      delete KYCCompanyData.isDirector
      delete KYCCompanyData.isComplete
      delete KYCCompanyData.jurisdiction

      delete KYCCompanyData.__typename;
      KYCCompanyData = modifyDataFileKYC(stripTypenames(KYCCompanyData));
      KYCCompanyData.signatory = modifyDataFileKYC(stripTypenames(KYCCompanyData.signatory));
      return {
        ...paramsMutattion,
        KYCCompanyData,
        KYCCompanyId: KYCCompanyData.id
      }
    },
    getIdFromMuttation: (result) => {
      return _.get(result, "upsertQuestionnaireCompany.id");
    }
  },
  "QUESTIONNAIRE_CORPORATE_DIRECTOR_FORM": {
    fieldName: "companyName",
    titleKey: "QUESTIONNAIRE_COMPANY_FORM_TITLE",
    muttation: `mutation upsertQuestionnaireCompany(
      $matterId: String!
      $KYCCompanyData: KYCCompanyInput! 
      $KYCCompanyId: String
    ) {
      upsertQuestionnaireCompany(matterId: $matterId, KYCCompanyData: $KYCCompanyData, KYCCompanyId: $KYCCompanyId) {
        id
        companyName
      }
    }
    `,
    leftEducationId: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.ADD.LEFT_EDUCATION_ID,
    leftEducationId_ADD: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.ADD.LEFT_EDUCATION_ID,
    leftEducationId_EDIT: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.EDIT.LEFT_EDUCATION_ID,
    getAvatarLabel: (obj) => {
      return _.get(obj, "companyName", "");
    },
    getForm: (props) => {
      return <CompanyForm {...props} isFlow={true} />
    },
    getVariablesMutation: (paramsMutattion, values) => {
      let KYCCompanyData = { ...values };
      delete KYCCompanyData.type;
      delete KYCCompanyData.formKey;
      delete KYCCompanyData.isShareholder
      delete KYCCompanyData.isDirector
      delete KYCCompanyData.isComplete
      delete KYCCompanyData.jurisdiction
      delete KYCCompanyData.__typename;
      KYCCompanyData = modifyDataFileKYC(stripTypenames(KYCCompanyData));
      KYCCompanyData.signatory = modifyDataFileKYC(stripTypenames(KYCCompanyData.signatory));
      return {
        ...paramsMutattion,
        KYCCompanyData,
        KYCCompanyId: KYCCompanyData.id
      }
    },
    getIdFromMuttation: (result) => {
      return _.get(result, "upsertQuestionnaireCompany.id");
    }
  }, "QUESTIONNAIRE_COMPANY_FORM": {
    fieldName: "companyName",
    titleKey: "QUESTIONNAIRE_COMPANY_FORM_TITLE",
    muttation: `mutation upsertQuestionnaireCompany(
      $matterId: String!
      $KYCCompanyData: KYCCompanyInput! 
      $KYCCompanyId: String
    ) {
      upsertQuestionnaireCompany(matterId: $matterId, KYCCompanyData: $KYCCompanyData, KYCCompanyId: $KYCCompanyId) {
        id
        companyName
      }
    }
    `,
    leftEducationId: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.ADD.LEFT_EDUCATION_ID,
    leftEducationId_ADD: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.ADD.LEFT_EDUCATION_ID,
    leftEducationId_EDIT: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.EDIT.LEFT_EDUCATION_ID,
    getAvatarLabel: (obj) => {
      return _.get(obj, "companyName", "");
    },
    getForm: (props) => {
      return <CompanyForm {...props} isFlow={false} />
    },
    getVariablesMutation: (paramsMutattion, values) => {
      let KYCCompanyData = { ...values };
      delete KYCCompanyData.type;
      delete KYCCompanyData.formKey;
      delete KYCCompanyData.isShareholder
      delete KYCCompanyData.isDirector
      delete KYCCompanyData.isComplete
      delete KYCCompanyData.__typename;
      delete KYCCompanyData.jurisdiction

      KYCCompanyData = modifyDataFileKYC(stripTypenames(KYCCompanyData));
      KYCCompanyData.signatory = modifyDataFileKYC(stripTypenames(KYCCompanyData.signatory));
      return {
        ...paramsMutattion,
        KYCCompanyData,
        KYCCompanyId: KYCCompanyData.id
      }
    },
    getIdFromMuttation: (result) => {
      return _.get(result, "upsertQuestionnaireCompany.id");
    }
  },
  "CORPORATE_DIRECTOR_FORM": {
    fieldName: "companyName",
    titleKey: "KYC_TITLE_FORM_COMPANY_FORM",
    muttation: `mutation upsertKYCCompanyDraftFlow(
      $matterId: String!
      $KYCCompanyData: KYCCompanyInput!
      $KYCCompanyId: String
      $draftId: ID!
    ) {
      upsertKYCCompanyDraftFlow(
        matterId:$matterId
        KYCCompanyData: $KYCCompanyData
        KYCCompanyId: $KYCCompanyId
        draftId: $draftId
      ) 
    }
    `,
    muttationRemove: `
    mutation removeComponentDirectorOrShareholder($matterId: String, $draftId: String, $individualOrCompanyId: String!) {
      removeComponentDirectorOrShareholder(matterId: $matterId, draftId: $draftId, individualOrCompanyId: $individualOrCompanyId) 
    } 
    `,
    getVariablesMutationRemove: (params, values) => {
      const matterId = _.get(params, "matterId", params);
      const draftId = _.get(params, "draftId", "");
      const individualOrCompanyId = _.get(params, "id", null);

      return {
        matterId,
        draftId,
        individualOrCompanyId
      }
    },
    leftEducationId_ADD: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.ADD.LEFT_EDUCATION_ID,
    leftEducationId: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.EDIT.LEFT_EDUCATION_ID,
    leftEducationId_EDIT: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.EDIT.LEFT_EDUCATION_ID,
    getAvatarLabel: (obj) => {
      return _.get(obj, "companyName", "");
    },
    getForm: (props) => {
      return <CompanyForm {...props} />
    },
    getVariablesMutation: (params, values) => {

      let KYCCompanyData = { ...values };
      delete KYCCompanyData.type;
      delete KYCCompanyData.formKey;
      delete KYCCompanyData.isShareholder
      delete KYCCompanyData.isDirector
      delete KYCCompanyData.isComplete
      delete KYCCompanyData.jurisdiction

      KYCCompanyData = stripTypenames(KYCCompanyData);
      const matterId = _.get(params, "matterId", params);
      const draftId = _.get(params, "draftId", "")
      return {
        draftId,
        matterId,
        KYCCompanyData,
        KYCCompanyId: KYCCompanyData.id
      }
    },
    getIdFromMuttation: (result) => {
      return _.get(result, "upsertKYCCompanyDraftFlow");
    }
  },
  "INDIVIDUAL_BAS_QUESTIONNAIRE_FORM": {
    id: "id",
    fieldName: (obj) => {
      return _.get(obj, "firstName", "") + " " + _.get(obj, "lastName", "");
    },
    muttation: `
    mutation upsertQuestionnaireIndividual(
      $matterId: String!
      $KYCIndividualData: KYCIndividualInput!
      $KYCIndividualId: String
    ) {
      upsertQuestionnaireIndividual(
        matterId: $matterId
        KYCIndividualData: $KYCIndividualData
        KYCIndividualId: $KYCIndividualId
      ) {
        id
        firstName
      }
    }    
    `,
    muttationRemove: `
    mutation removeQuestionnaireIndividual(
      $matterId: String!
      $KYCIndividualId: String) {
      removeQuestionnaireIndividual(
        matterId: $matterId
        KYCIndividualId: $KYCIndividualId) {
        id
        firstName
        lastName
      }
    } 
    `,
    titleKey: "QUESTIONNAIRE_INDIVIDUAL_FORM_TITLE",
    leftEducationId: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_INDIVIDUAL_FORM.ADD.LEFT_EDUCATION_ID,
    getAvatarLabel: (obj) => {
      return _.get(obj, "firstName", "") + " " + _.get(obj, "lastName", "");
    },
    getForm: (props) => {

      return <FormIndiviual {...props} initialValues={{ ...props.initialValues, isCompanyFlow: false }} />
    },
    getVariablesMutation: (params, values) => {
      let KYCIndividualData = { ...values };
      delete KYCIndividualData.isCompanyFlow;
      delete KYCIndividualData.type;
      delete KYCIndividualData.formKey;
      delete KYCIndividualData.__typename;
      delete KYCIndividualData.isShareholder;
      delete KYCIndividualData.isDirector;
      delete KYCIndividualData.jurisdiction;
      delete KYCIndividualData.otherJurisdiction;
      // KYCIndividualData._id= KYCIndividualData.id;
      // delete KYCIndividualData.id;
      KYCIndividualData = modifyDataFileKYC(stripTypenames(KYCIndividualData));
      const matterId = _.get(params, "matterId", params);
      return {
        matterId,
        KYCIndividualData,
        KYCIndividualId: KYCIndividualData.id
      }
    },
    getVariablesMutationRemove: (params, values) => {
      const matterId = _.get(params, "matterId", params);
      const KYCIndividualId = _.get(params, "id",)
      return {
        matterId,
        KYCIndividualId
      }
    },
    getIdFromMuttation: (result) => {
      return _.get(result, "upsertQuestionnaireIndividual.id");
    }
  },
  "INDIVIDUAL_AS_QUESTIONNAIRE_FORM": {
    id: "id",
    fieldName: (obj) => {
      return _.get(obj, "firstName", "") + " " + _.get(obj, "lastName", "");
    },
    muttation: `
    mutation upsertUAEOrGCCNationalKYCIndividual(
      $matterId: String!
      $KYCIndividualData: KYCIndividualInput!
      $KYCIndividualId: String
    ) {
      upsertUAEOrGCCNationalKYCIndividual(
        matterId: $matterId
        KYCIndividualData: $KYCIndividualData
        KYCIndividualId: $KYCIndividualId
      ) {
        id
        firstName
      }
    }    
    `,
    titleKey: "QUESTIONNAIRE_INDIVIDUAL_FORM_TITLE",
    leftEducationId: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_INDIVIDUAL_FORM.ADD.LEFT_EDUCATION_ID,
    getAvatarLabel: (obj) => {
      return _.get(obj, "firstName", "") + " " + _.get(obj, "lastName", "");
    },
    getForm: (props) => {

      return <FormIndiviual {...props} initialValues={{ ...props.initialValues, isCompanyFlow: false }} />
    },
    getVariablesMutation: (params, values) => {
      let KYCIndividualData = { ...values };
      delete KYCIndividualData.isCompanyFlow;
      delete KYCIndividualData.type;
      delete KYCIndividualData.formKey;
      delete KYCIndividualData.__typename;
      delete KYCIndividualData.isShareholder;
      delete KYCIndividualData.isDirector;
      delete KYCIndividualData.jurisdiction;
      delete KYCIndividualData.otherJurisdiction;
      // KYCIndividualData._id= KYCIndividualData.id;
      // delete KYCIndividualData.id;
      KYCIndividualData = modifyDataFileKYC(stripTypenames(KYCIndividualData));
      const matterId = _.get(params, "matterId", params);
      return {
        matterId,
        KYCIndividualData,
        KYCIndividualId: KYCIndividualData.id
      }
    },
    getIdFromMuttation: (result) => {
      return _.get(result, "upsertUAEOrGCCNationalKYCIndividual.id");
    }
  },
  "THIRD_PARTY_INDIVIDUAL_QUESTIONNAIRE_FORM": {
    id: "id",
    fieldName: (obj) => {
      return _.get(obj, "firstName", "") + " " + _.get(obj, "lastName", "");
    },
    muttation: `
    mutation upsertQuestionnaireThirdPersonDraftFlow($matterId: String!, $QuestionnaireThirdPersonData: KYCIndividualInput!, $draftId:ID!) {
      upsertQuestionnaireThirdPersonDraftFlow(matterId: $matterId, QuestionnaireThirdPersonData: $QuestionnaireThirdPersonData, draftId: $draftId) {
        id
        firstName
        lastName
      }
    }  
    `,
    muttationRemove: `
    mutation removeQuestionnaireThirdPersonDraftFlow($matterId: String!, $draftId: ID!) {
      removeQuestionnaireThirdPersonDraftFlow(matterId: $matterId, draftId: $draftId) {
        id
        firstName
        lastName
      }
    } 
    `,
    titleKey: "QUESTIONNAIRE_THIRD_PARTY_INDIVIDUAL_QUESTIONNAIRE_FORM_TITEL",
    leftEducationId: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_INDIVIDUAL_FORM.ADD.LEFT_EDUCATION_ID,
    getAvatarLabel: (obj) => {
      return _.get(obj, "firstName", "") + " " + _.get(obj, "lastName", "");
    },
    getForm: (props) => {
      return <FormIndiviual {...props} initialValues={{ ...props.initialValues, isCompanyFlow: false }} />
    },
    getVariablesMutation: (params, values) => {
      let QuestionnaireThirdPersonData = { ...values };
      delete QuestionnaireThirdPersonData.isCompanyFlow;
      delete QuestionnaireThirdPersonData.type;
      delete QuestionnaireThirdPersonData.formKey;
      delete QuestionnaireThirdPersonData.__typename;
      QuestionnaireThirdPersonData = stripTypenames(QuestionnaireThirdPersonData);
      const matterId = _.get(params, "matterId", params);
      const draftId = _.get(params, "draftId", params);
      return {
        matterId,
        draftId,
        QuestionnaireThirdPersonData,
      }
    },
    getVariablesMutationRemove: (params, values) => {
      const matterId = _.get(params, "matterId", params);
      const draftId = _.get(params, "draftId", params);
      return {
        matterId,
        draftId
      }
    },

    getIdFromMuttation: (result) => {
      return _.get(result, "upsertQuestionnaireThirdPersonDraftFlow.id");
    }
  },
  "THIRD_PARTY_INDIVIDUAL_QUESTIONNAIRE_FORM_EDIT": {
    id: "id",
    fieldName: (obj) => {
      return _.get(obj, "firstName", "") + " " + _.get(obj, "lastName", "");
    },
    muttation: `
    mutation upsertQuestionnaireThirdPerson(
      $matterId: String!
      $QuestionnaireThirdPersonData: KYCIndividualInput!
    ) {
      upsertQuestionnaireThirdPerson(
        matterId: $matterId
        QuestionnaireThirdPersonData: $QuestionnaireThirdPersonData
      ) {
        id
        firstName
        lastName
      
      }
    }
    `,
    muttationRemove: `
    mutation  removeQuestionnaireThirdPerson(
      $matterId: String!
    ){
      removeQuestionnaireThirdPerson(matterId: $matterId){
        id
        firstName
        lastName
      }
    }
    `,
    titleKey: "QUESTIONNAIRE_THIRD_PARTY_INDIVIDUAL_QUESTIONNAIRE_FORM_TITEL",
    leftEducationId: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_INDIVIDUAL_FORM.ADD.LEFT_EDUCATION_ID,
    getAvatarLabel: (obj) => {
      return _.get(obj, "firstName", "") + " " + _.get(obj, "lastName", "");
    },
    getForm: (props) => {
      return <FormIndiviual {...props} initialValues={{ ...props.initialValues, isCompanyFlow: false }} />
    },
    getVariablesMutation: (params, values) => {

      let QuestionnaireThirdPersonData = { ...values };
      delete QuestionnaireThirdPersonData.isCompanyFlow;
      delete QuestionnaireThirdPersonData.type;
      delete QuestionnaireThirdPersonData.formKey;
      delete QuestionnaireThirdPersonData.__typename;
      const matterId = _.get(params, "matterId", params);
      return {
        matterId,
        QuestionnaireThirdPersonData,
      }
    },
    getVariablesMutationRemove: (params, values) => {
      const matterId = _.get(params, "matterId", params);
      return {
        matterId,
      }
    },

    getIdFromMuttation: (result) => {
      return _.get(result, "upsertQuestionnaireThirdPerson.id");
    }
  },
  "INDIVIDUAL_QUESTIONNAIRE_FORM": {
    id: "id",
    fieldName: (data) => {
      return _.get(data, "firstName", "") + " " + _.get(data, "lastName", "");
    },
    muttation: `
    mutation upsertQuestionnaireIndividualDraftFlow(
      $KYCIndividualData: Object!
      $KYCIndividualId: String
      $draftId:ID!
    ) {
      upsertQuestionnaireIndividualDraftFlow(
        KYCIndividualData: $KYCIndividualData
        KYCIndividualId: $KYCIndividualId
        draftId: $draftId
      ) {
        id
        firstName
      }
    }    
    `,
    titleKey: "QUESTIONNAIRE_INDIVIDUAL_FORM_TITLE",
    leftEducationId: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_INDIVIDUAL_FORM.ADD.LEFT_EDUCATION_ID,
    getAvatarLabel: (obj) => {
      return _.get(obj, "firstName", "") + " " + _.get(obj, "lastName", "");
    },
    getForm: (props) => {

      return <FormIndiviual {...props} initialValues={{ ...props.initialValues, isCompanyFlow: false }} />
    },
    getVariablesMutation: (params, values) => {
      let KYCIndividualData = { ...values };
      delete KYCIndividualData.isCompanyFlow;
      delete KYCIndividualData.type;
      delete KYCIndividualData.formKey;
      delete KYCIndividualData.__typename;
      delete KYCIndividualData.isShareholder;
      delete KYCIndividualData.isDirector;
      delete KYCIndividualData.jurisdiction;
      delete KYCIndividualData.otherJurisdiction;
      KYCIndividualData = modifyDataFileKYC(stripTypenames(KYCIndividualData));

      const matterId = _.get(params, "matterId", params);
      const draftId = _.get(params, "draftId", "");
      return {
        draftId,
        KYCIndividualData,
        KYCIndividualId: KYCIndividualData._id
      }
    },
    getIdFromMuttation: (result) => {
      return _.get(result, "upsertQuestionnaireIndividualDraftFlow.id");
    }
  },
  "DIRECTOR_INDIVIDUAL_FORM": {
    id: "id",
    fieldName: (data) => {
      return _.get(data, "firstName", "") + " " + _.get(data, "lastName", "");
    },
    muttation: `
    mutation upsertQuestionnaireIndividual(
      $matterId: String!
      $KYCIndividualData: KYCIndividualInput!
      $KYCIndividualId: String
    ) {
      upsertQuestionnaireIndividual(
        matterId: $matterId
        KYCIndividualData: $KYCIndividualData
        KYCIndividualId: $KYCIndividualId
      ) {
        id
        firstName
      }
    }    
    `,
    titleKey: "QUESTIONNAIRE_INDIVIDUAL_FORM_TITLE",
    leftEducationId: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_INDIVIDUAL_FORM.ADD.LEFT_EDUCATION_ID,
    getAvatarLabel: (obj) => {
      return _.get(obj, "firstName", "") + " " + _.get(obj, "lastName", "");
    },
    getForm: (props) => {

      return <FormIndiviual {...props} initialValues={{ ...props.initialValues, isCompanyFlow: false }} />
    },
    getVariablesMutation: (params, values) => {
      let KYCIndividualData = { ...values };
      delete KYCIndividualData.isCompanyFlow;
      delete KYCIndividualData.type;
      delete KYCIndividualData.formKey;
      delete KYCIndividualData.__typename;
      delete KYCIndividualData.isShareholder;
      delete KYCIndividualData.isDirector;
      delete KYCIndividualData.jurisdiction;
      delete KYCIndividualData.otherJurisdiction;
      KYCIndividualData = modifyDataFileKYC(stripTypenames(KYCIndividualData));

      const matterId = _.get(params, "matterId", params);
      return {
        matterId,
        KYCIndividualData,
        KYCIndividualId: KYCIndividualData.id
      }
    },
    getIdFromMuttation: (result) => {
      return _.get(result, "upsertQuestionnaireIndividual.id");
    }
  },
  "INDIVIDUAL_QUESTIONNAIRE_FORM_EDIT": {
    id: "id",
    fieldName: (data) => {
      return _.get(data, "firstName", "") + " " + _.get(data, "lastName", "");
    },
    muttation: addIndividual,
    titleKey: "QUESTIONNAIRE_INDIVIDUAL_FORM_TITLE",
    leftEducationId: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_INDIVIDUAL_FORM.ADD.LEFT_EDUCATION_ID,
    getAvatarLabel: (obj) => {
      return _.get(obj, "firstName", "") + " " + _.get(obj, "lastName", "");
    },
    getForm: (props) => {

      return <FormIndiviual {...props} initialValues={{ ...props.initialValues, isCompanyFlow: false }} />
    },
    getVariablesMutation: (params, values) => {
      let KYCIndividualData = { ...values };
      delete KYCIndividualData.type;
      delete KYCIndividualData.formKey;
      delete KYCIndividualData.isShareholder
      delete KYCIndividualData.isDirector
      delete KYCIndividualData.isComplete
      delete KYCIndividualData.isCompanyFlow;
      delete KYCIndividualData.jurisdiction;
      delete KYCIndividualData.otherJurisdiction;


      KYCIndividualData = modifyDataFileKYC(stripTypenames(KYCIndividualData));

      const matterId = _.get(params, "matterId", params);
      return {
        matterId,
        KYCIndividualData,
        KYCIndividualId: KYCIndividualData.id
      }
    },
    getIdFromMuttation: (result) => {
      return _.get(result, "upsertQuestionnaireIndividualDraftFlow.id");
    }
  },
  "COMPANY_QUESTIONNAIRE_FORM_CORPORATE": {
    fieldName: "companyName",
    titleKey: "QUESTIONNAIRE",
    muttation: `mutation upsertQuestionnaireCompanyDraftFlow(
      $matterId: String!
      $KYCCompanyData: Object! 
      $KYCCompanyId: String,
      $draftId: ID!
    ) {
      upsertQuestionnaireCompanyDraftFlow(matterId: $matterId, KYCCompanyData: $KYCCompanyData, KYCCompanyId: $KYCCompanyId, draftId: $draftId) {
        id
        companyName
      }
    }
    `,
    leftEducationId: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.ADD.LEFT_EDUCATION_ID,
    leftEducationId_ADD: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.ADD.LEFT_EDUCATION_ID,
    leftEducationId_EDIT: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.EDIT.LEFT_EDUCATION_ID,
    getAvatarLabel: (obj) => {
      return _.get(obj, "companyName", "");
    },
    getForm: (props) => {
      return <CompanyForm {...props} />
    },
    getVariablesMutation: (paramsMutattion, values) => {
      let KYCCompanyData = { ...values };
      delete KYCCompanyData.type;
      delete KYCCompanyData.formKey;
      delete KYCCompanyData.__typename;
      KYCCompanyData = stripTypenames(KYCCompanyData);
      return {
        ...paramsMutattion,
        KYCCompanyData,
        KYCCompanyId: KYCCompanyData.id
      }
    },
    getIdFromMuttation: (result) => {
      return _.get(result, "upsertQuestionnaireCompanyDraftFlow.id");
    }
  },
  "ON_BEHALF_FORM": {
    fieldName: "firstName",
    muttation: `
    mutation updateKYCThirdPartyDraftFlow(
      $matterId: String!
      $KYCThirdPartyData: KYCThirdPartyInput!
      $draftId: ID!
    ) {
      updateKYCThirdPartyDraftFlow(
        matterId:$matterId
        KYCThirdPartyData: $KYCThirdPartyData
        draftId: $draftId
      ) {
        id
        firstName
        
      }
    }
     `,
    titleKey: "KYC_TITLE_FORM_THIRD_PARTY_FORM",
    leftEducationId_ADD: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_ON_BEHALF_FORM.ADD.LEFT_EDUCATION_ID,
    leftEducationId_EDIT: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_ON_BEHALF_FORM.EDIT.LEFT_EDUCATION_ID,
    getAvatarLabel: (obj) => {
      return _.get(obj, "firstName", "") + " " + _.get(obj, "lastName", "");
    },
    getForm: (props) => {
      return <OnBehalfForm {...props} />
    },
    getVariablesMutation: (params, values) => {
      let KYCThirdPartyData = { ...values };
      delete KYCThirdPartyData.type;
      delete KYCThirdPartyData.formKey;
      delete KYCThirdPartyData.jurisdiction

      KYCThirdPartyData = stripTypenames(KYCThirdPartyData);
      const matterId = _.get(params, "matterId", params);
      const draftId = _.get(params, "draftId", "");
      return {
        matterId,
        draftId,
        KYCThirdPartyData
      }
    },
    getIdFromMuttation: (result) => {
      return 1;
    }
  },
  "INDIVIDUAL_CORPORATE_FORM": {
    fieldName: "companyName",
    titleKey: "QUESTIONNAIRE_COMPANY_FORM_TITLE",
    muttation: `mutation upsertQuestionnaireCompany(
      $matterId: String!
      $KYCCompanyData: KYCCompanyInput! 
      $KYCCompanyId: String
    ) {
      upsertQuestionnaireCompany(matterId: $matterId, KYCCompanyData: $KYCCompanyData, KYCCompanyId: $KYCCompanyId) {
        id
        companyName
      }
    }
    `,
    leftEducationId: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.ADD.LEFT_EDUCATION_ID,
    leftEducationId_ADD: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.ADD.LEFT_EDUCATION_ID,
    leftEducationId_EDIT: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.EDIT.LEFT_EDUCATION_ID,
    getAvatarLabel: (obj) => {
      return _.get(obj, "companyName", "");
    },
    getForm: (props) => {
      return <CompanyForm {...props} isFlow={true} />
    },
    getVariablesMutation: (paramsMutattion, values) => {
      let KYCCompanyData = { ...values };
      delete KYCCompanyData.type;
      delete KYCCompanyData.formKey;
      delete KYCCompanyData.__typename;
      delete KYCCompanyData.jurisdiction

      KYCCompanyData = stripTypenames(KYCCompanyData);
      return {
        ...paramsMutattion,
        KYCCompanyData,
        KYCCompanyId: KYCCompanyData.id
      }
    },
    getIdFromMuttation: (result) => {
      return _.get(result, "upsertQuestionnaireCompany.id");
    }
  },
  "INDIVIDUAL_FORM": {
    id: "id",
    fieldName: (data) => {
      return _.get(data, "firstName", "") + " " + _.get(data, "lastName", "");
    },
    muttation: `
    mutation upsertKYCIndividualDraftFlow(
      $matterId: String!
      $KYCIndividualData: KYCIndividualInput!
      $KYCIndividualId: String
      $draftId: ID
    ) {
      upsertKYCIndividualDraftFlow(
        matterId: $matterId
        KYCIndividualData: $KYCIndividualData
        KYCIndividualId: $KYCIndividualId
        draftId: $draftId
      ) 
    }    
    `,
    muttationRemove: `
    mutation removeComponentDirectorOrShareholder($matterId: String, $draftId: String, $individualOrCompanyId: String!) {
      removeComponentDirectorOrShareholder(matterId: $matterId, draftId: $draftId, individualOrCompanyId: $individualOrCompanyId) 
    } 
    `,
    getVariablesMutationRemove: (params, values) => {
      const matterId = _.get(params, "matterId", params);
      const draftId = _.get(params, "draftId", "");
      const individualOrCompanyId = _.get(params, "id", null);

      return {
        matterId,
        draftId,
        individualOrCompanyId
      }
    },
    titleKey: "KYC_TITLE_FORM_INDIVIDUAL_FORM",
    leftEducationId: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_INDIVIDUAL_FORM.ADD.LEFT_EDUCATION_ID,
    leftEducationId_ADD: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_INDIVIDUAL_FORM.ADD.LEFT_EDUCATION_ID,
    leftEducationId_EDIT: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_INDIVIDUAL_FORM.EDIT.LEFT_EDUCATION_ID,
    getAvatarLabel: (obj) => {
      return _.get(obj, "firstName", "") + " " + _.get(obj, "lastName", "");
    },
    getForm: (props) => {

      return <FormIndiviual {...props} initialValues={{ ...props.initialValues, isCompanyFlow: false }} />
    },
    getVariablesMutation: (params, values) => {
      let KYCIndividualData = { ...values };
      delete KYCIndividualData.isCompanyFlow;
      delete KYCIndividualData.type;
      delete KYCIndividualData.formKey;
      delete KYCIndividualData.isDirector
      delete KYCIndividualData.isShareholder
      delete KYCIndividualData.isComplete

      delete KYCIndividualData.jurisdiction;
      delete KYCIndividualData.otherJurisdiction;

      KYCIndividualData = stripTypenames(KYCIndividualData);
      const matterId = _.get(params, "matterId", params);
      const draftId = _.get(params, "draftId", params);
      return {
        matterId,
        KYCIndividualData,
        KYCIndividualId: KYCIndividualData.id,
        draftId
      }
    },

    getIdFromMuttation: (result) => {
      return _.get(result, "upsertKYCIndividualDraftFlow");
    },
    succesNotification: "Individual updated successfully"
  },
  "INDIVIDUAL_FORM_COMPANY": {
    fieldName: (data) => {
      return _.get(data, "firstName", "") + " " + _.get(data, "lastName", "");
    },
    muttation: `
    mutation upsertKYCIndividualDraftFlow(
      $matterId: String!
      $KYCIndividualData: KYCIndividualInput!
      $KYCIndividualId: String
      $draftId: ID
    ) {
      upsertKYCIndividualDraftFlow(
        matterId:$matterId
        KYCIndividualData: $KYCIndividualData
        KYCIndividualId: $KYCIndividualId
        draftId: $draftId
      ) 
    }
    `,
    titleKey: "KYC_TITLE_FORM_INDIVIDUAL_FORM",
    leftEducationId_ADD: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_INDIVIDUAL_FORM.ADD.LEFT_EDUCATION_ID,
    leftEducationId_EDIT: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_INDIVIDUAL_FORM.EDIT.LEFT_EDUCATION_ID,
    getAvatarLabel: (obj) => {
      return _.get(obj, "firstName", "") + " " + _.get(obj, "lastName", "");
    },
    getForm: (props) => {
      return <FormIndiviual {...props} initialValues={{ ...props.initialValues, isCompanyFlow: true }} />
    },
    getVariablesMutation: (params, values) => {
      let KYCIndividualData = { ...values };
      delete KYCIndividualData.isCompanyFlow;
      delete KYCIndividualData.type;
      delete KYCIndividualData.formKey;

      delete KYCIndividualData.jurisdiction;
      delete KYCIndividualData.otherJurisdiction;


      KYCIndividualData = stripTypenames(KYCIndividualData);
      let matterId = _.get(params, "matterId", params);
      const draftId = _.get(params, "draftId", params);
      return {
        matterId,
        KYCIndividualData,
        KYCIndividualId: KYCIndividualData.id,
        draftId
      }
    },
    getIdFromMuttation: (result) => {
      return _.get(result, "upsertKYCIndividualDraftFlow");
    },
    succesNotification: "Individual updated successfully"
  },
  "COMPANY_FORM": {
    fieldName: "companyName",
    titleKey: "KYC_TITLE_FORM_COMPANY_FORM",
    muttation: `mutation upsertKYCEntityDraftFlow(
      $matterId: String!
      $KYCEntityData: KYCCompanyInput!
      $KYCEntityId: String
      $draftId: ID
    ) {
      upsertKYCEntityDraftFlow(
        matterId:$matterId
        KYCEntityData: $KYCEntityData
        KYCEntityId: $KYCEntityId
        draftId:$draftId
      ) 
    }
    `,
    leftEducationId: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.EDIT.LEFT_EDUCATION_ID,
    leftEducationId_ADD: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.ADD.LEFT_EDUCATION_ID,
    leftEducationId_EDIT: Constants.CONTENTFUL.ENTRIES.FORMS.KYC_COMPANY_FORM.EDIT.LEFT_EDUCATION_ID,
    getAvatarLabel: (obj) => {
      return _.get(obj, "companyName", "");
    },
    getForm: (props) => {
      return <CompanyForm {...props} />
    },
    getVariablesMutation: (params, values) => {
      let KYCEntityData = { ...values, shareholders: _.get(values, 'shareholders', []) };
      delete KYCEntityData.type;
      delete KYCEntityData.formKey;
      delete KYCEntityData.isPrimary;
      delete KYCEntityData.isComplete;
      delete KYCEntityData.jurisdiction

      KYCEntityData = stripTypenames(KYCEntityData);
      KYCEntityData = stripField(KYCEntityData, "isComplete");
      let matterId = _.get(params, "matterId", params);
      const draftId = _.get(params, "draftId", "");

      return {
        matterId,
        KYCEntityData,
        KYCEntityId: KYCEntityData.id,
        draftId
      }
    },
    getIdFromMuttation: (result) => {
      return _.get(result, "upsertKYCEntityDraftFlow");
    }
  }
}

import FileUploaderAndSelector from '../FileUploaderAndSelector/FileUploaderAndSelector';
import _ from "lodash";
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const ChatFileUploaderAndSelector = ({ field, form, name, documents, multipleFiles}) => {

    const onChange = (val) => {
        form.setFieldValue(name, val)
    }

    return (
        <FileUploaderAndSelector multipleFiles={multipleFiles} documents={documents ? documents : null} onChange={onChange}>
            
        </FileUploaderAndSelector>
    )
}

export default ChatFileUploaderAndSelector;

import * as Yup from 'yup';

import { default as React } from 'react';

import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from "src/modules/generic/components/Inputs/ReactHookForm/ControllerInput";
import Form from 'src/components/forms/Form/Form';
import NestedList from "src/modules/generic/components/Lists/NestedList";
import RadioInput from "src/components/inputs/Radio/RadioInput";
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import TemplateLabel from "src/modules/generic/components/Inputs/LabelInput";
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import TextInput from 'src/components/inputs/Text/TextInput';
import Typography from 'src/modules/generic/components/Texts/Typography'
import _ from 'lodash';
import classes from '../classes.module.scss';
import { useDispatch } from 'react-redux';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { HiddenFieldForm } from 'src/components/forms/contents';

const schemaQuestion = Yup.object().shape({question: Yup.boolean()
  .required("This field is required")
  .nullable(),
  description: Yup.string().when('question', {
    is: true,
    then: Yup.string().nullable().required('This field is required'),
  })})

const schema = Yup.object().shape({
  haveEverBeenInPrison:schemaQuestion,
  haveFinancialCharges:schemaQuestion,
  haveEverBeenBanned:schemaQuestion,
  haveBeenDisciplined:schemaQuestion,
  haveEverLostCourtCase:schemaQuestion,
  haveBeenDisqualified:schemaQuestion,
  haveBeenInBankruptCompany:schemaQuestion,
  havePendingProceedings:schemaQuestion,
  haveBeenConvicted:schemaQuestion
})

export default function ReviewPersonalDetailFitAndProperStatementStepForm() {
  const { next, context ,cancel,prev } = useMachine();
  const { translate } = useTranslate();
  const { initialData } = context;
  const initialValues = initialData?.fitAndProperStatement
  
  const handleCancel = (values) => {
    cancel();
  }
  
  const handleSubmit = async (values) => {
    next(values)
  }

  const questions = [
    {
      label: 'Have you ever been convicted of a crime involving dishonesty?',
      name: 'haveBeenConvicted' 
    },
    {
      label: 'Have you ever served or been sentenced to a term of imprisonment?',
      name: 'haveEverBeenInPrison'
    },
    {
      label: 'Have you ever been convicted of any offence under any provision of the financial markets legislation or any offence under any provision of any foreign act, financial markets or financial services, corporations, financial reporting, or requirements for preventing money laundering?',
      name: 'haveFinancialCharges' 
    },
    {
      label: 'Have you ever been banned from acting as a director of a company or other incorporated body, or from being involved in the management of any class of incorporated or unincorporated entity?',
      name: 'haveEverBeenBanned' 
    },
    {
      label: 'Have you ever been subject to disciplinary action by any professional body or disciplinary tribuna l, where those actions resulted in penalties being imposed or censure?',
      name: 'haveBeenDisciplined'
    },
    {
      label: 'Have you ever had a court ruling against you in respect of a civil case, or have you ever reached an out of court settlement, relating to your profession?',
      name: 'haveEverLostCourtCase',
    },
    {
      label: 'Have you ever been dismissed, or asked to resign, from a position of trust, fiduciary appointment or similar position?',
      name: 'haveBeenDisqualified'
    },
    {
      label: 'Have you ever been placed into statutory management, or has been a director of a company which has been placed into statutory management?',
      name: 'haveBeenInStatutoryManagement'
    },
    {
      label: 'Have you ever, in the last 10 years, been a director or senior manager of a company, or other incorporated or unincorporated entity, which has been placed into liquidation, administration or receivership, entered into any compromise agreement, moratorium or other restructuring to avoid liquidation, administration or receivership?',
      name: 'haveBeenInBankruptCompany' 
    },
    {
      label: 'Are you subject to pending proceedings which, if any adverse finding is reached, will result in one or more of the matters set out in the paragraphs above applying to you?',
      name: 'havePendingProceedings'
    },
  ]
  const questionsItems = questions.map(question=> ({
    mainItem: (<div className={classes.list}>
        <TemplateLabel 
              label={question.label}
              isRequired
              isColumn={true}>
             <ControllerInput
                  render={RadioInput}
                  classNameOption={classes.radio}
                  className={classes.radioContainer}
                  name={`${question?.name}.question`}
                  defaultValue={false}
              />
            </TemplateLabel>
            <HiddenFieldForm
                fieldsWatch={`${question?.name}.question`}
                conditionHideCallback={(value) => value}
            >
              <TemplateLabel label="Please explain" isRequired className={classes.listDescriptionLabel}>
                <ControllerInput
                    name={`${question?.name}.description`} 
                    placeholder="Type here" 
                    render={TextInput} 
                />
              </TemplateLabel>
            </HiddenFieldForm>
            </div>),
            subItems:[]
          }))
          const handlePrev = (values) => {
            prev();
          }

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
            className={classes.gapItems}

        props={{
          title: 'Edit fit and proper'
        }}
        buttons={
          {
            submit: (
              <SubmitButton>
                Submit
              </SubmitButton>
            ),
            cancel: (
              <Button onClick={handleCancel} variant="secondary">
                {translate("MODULES.CLARA.BTN_CANCEL")}
              </Button>
            ),
          }
        }
      >
        <Typography component='p' weight='regular' variant='bodyModal'>
          This statement was signed when the company was incorporated and the document can be found in your data room.
        </Typography>

        <NestedList  color={'dark-grey'} data={questionsItems} />

      </TemplateWizard>
    </Form>
  );
}



import React, {FC} from 'react';
import { StepProps } from '../../../../../../../generic/components/Wizard/Step';
import useCustomMachine from '../../../../../../../generic/hooks/useCustomMachine';
import Button from '../../../../../../../generic/components/Buttons/Button';
import useTranslate from '../../../../../../../generic/hooks/useTranslate';
import ModalTourTemplate from '../../../../../../../../components/templates/ModalTourTemplate';
import image from '../../../../../../../../images/Whats-next.svg'

/**
 - Engage Concierge Done Form
 **/
const WhatsNextStep: FC<StepProps> = () => {
  const { translate } = useTranslate()
  const { next, state, prev } = useCustomMachine();
  const { steps, actualStep } = state.context.paramsData;

  const handlePrevious = () => {
    prev();
  }

  const handleNext = () => {
    next();
  }

  return (
    <>
      <ModalTourTemplate
        title={
          translate('MODULES.GROUP_COMPANY.SUBMITTED_FORMATION.WHATS_NEXT_STEP.TITLE')
        }
        subtitle={
          translate('MODULES.GROUP_COMPANY.SUBMITTED_FORMATION.WHATS_NEXT_STEP.SUBTITLE')

        }
        image={<img src={image} />}
        buttons={[
          <Button onClick={handlePrevious} variant="secondary">
            {translate('MODULES.GROUP_COMPANY.SUBMITTED_FORMATION.WHATS_NEXT_STEP.BUTTON_PREVIOUS')}
          </Button>,
          <Button onClick={handleNext} variant="primary">
            {translate('MODULES.GROUP_COMPANY.SUBMITTED_FORMATION.WHATS_NEXT_STEP.BUTTON_NEXT')}
          </Button>]}
        actualStep={actualStep}
        steps={steps}
      />
    </>
  )
}

export default WhatsNextStep;

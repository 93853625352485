import { useMemo } from 'react';
import Button from "../../../../generic/components/Buttons/Button";
import useModal from "../../../../generic/hooks/useModal";
import useTranslate from '../../../../generic/hooks/useTranslate';
import TemplateFormTwoColumnsSimple from 'src/modules/generic/templates/Modal/TemplateFormTwoColumnsSimple';
import { Stack } from "@mui/material";
import Typography from "src/modules/generic/components/Texts/Typography";
import cls from "./classes.module.scss";
import img from "src/modules/startup/PayOverdueModal/steps/PayTimeOverdue/imgs/scale-subscription-paid.svg";
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';

/**
 * Step success pay overdue
 * @param {*} param0
 * @returns
 */
function PayTimeOverdue({ 
  openParam,
}) {

  const { translate } = useTranslate();
  
  const { next, prev} = useMachine()
  const handleNext = () => {
    next();
  }
  const { closeModal } = useModal();
  const leftComponent = useMemo(() => {
    return (
      <Stack spacing={3}>
        <Typography className={cls["cartTitle"]} weight={"bold"}>
          {translate('MODULES.CHECKOUT.PAYMENT_TIMEOUT_TITLE')}
        </Typography>
        <Typography variant="h2" color={"black"} component={"p"} >
          {translate('MODULES.CHECKOUT.PAYMENT_TIMEOUT_TEXT')}
        </Typography>
        {/* <Typography variant="h2" color={"black"} component={"p"} >
          {translate('MODAL_PAY_OVERDUE_SUCCESS_TEXT2')}
        </Typography> */}
      </Stack>
    )
  }, [])

  const rightComponent = useMemo(() => {
    return (
      <div className={cls["rightContentImage"]}>
        <img src={img} alt="" />
      </div>)
  }, [])
  const handlePrev = (values) => {
    
    prev();
  }
  return (
    <TemplateFormTwoColumnsSimple
      leftComponent={leftComponent}
      rightComponent={rightComponent}
      buttons={{
        previous: <Button variant="secondary" onClick={handlePrev}>{translate('BUTTON_PREVIOUS')}</Button>,
        submit: <Button variant="primary" onClick={handleNext}>{translate('MODULES.CHECKOUT.PAYMENT_TIMEOUT_BUTTON')}</Button>,
      }}
    >
    </TemplateFormTwoColumnsSimple>
  );
}

export default PayTimeOverdue;

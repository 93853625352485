import React, {Component} from 'react';
import {FilePond} from 'react-filepond';

import {ButtonModalOptions} from "../../../components";
import ServerConnect from "../../../utils/ServerConnect";
import FormikHelper from "../../../utils/formikHelper";
// Filepond styles
import 'filepond/dist/filepond.css';
import classes from './DocumentInput.module.scss';
import "./Filepond.css";

class DocumentInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: 'pepe',
            server: {
                url: ServerConnect.getUrlFile(),
                process: {
                    method: "POST",
                    withCredentials: true,
                    onload: this.onLoad,
                    onerror: this.onError,
                }
            },
            instantUpload: true,
            acceptedFileTypes: null,
            maxFileSize: "51200KB",
            labelFileTypeNotAllowed: 'File of invalid type',
            labelMaxFileSizeExceeded: 'Max file size is 10MB',
            labelAbortFile: "File Aborted",
            files: [],
            maxFiles: 1,
            allowMultiple: false,
            optionsFile: [],
            showTooltip: false,
            isFocused: false
        }
    }

    preview = () => {
        let { callbacks } = this.props;
        if (this.props.callbacks && this.props.callbacks.updateFilePreview) {
            this.props.callbacks.updateFilePreview(this.state.files[0].id)
        }
    };

    dowload = () => {
        let { files } = this.state;
        let url = ServerConnect.getUrlFile(this.state.files[0].id)
        ServerConnect.openFile(url,this.state.files[0].name);
    };

    delete = () => {
        this.setState({
            files: []
        })
    };

    componentDidMount () {
        const files = this.state.files;
        const {file, form, name} = this.props;

        if(file) {
            files.push({
                source: file.id,
                options: {
                    type: "local",
                    file: {
                        name: file.name,
                        size: file.size,
                        type: file.type,
                        id: file.id
                    }
                }
            });

            this.setState({
                files
            });

            FormikHelper.setValueInTheCorrectPosition(name, form, file);
        }
    }

    componentWillMount() {

        let { value, form, field , name, file} = this.props;
        let files = [];
        let optionsFile = [];
        if (form && field){
            value=form.values[field.name];
        }
        if (value) {
            files.push({
                source: value.id,
                options: {
                    type: "local",
                    file: {
                        name: value.name,
                        size: value.size,
                        type: value.type,
                        id: value.id
                    }
                }
            })

        }
        this.setState({ files });
        if(file) {
            FormikHelper.setValueInTheCorrectPosition('file', form, file);
        }
    }

    onError = () => {
        alert('Upload error.');
    };

    onLoad = async (response) => {
        const filePond = JSON.parse(response).filePond;
        const {form,field} = this.props;
        const name = field.name;


        FormikHelper.setValueInTheCorrectPosition(name, form, filePond);
    };

    renderLabel() { // This needs to be a string.
        return (
            '<div class="labelFile">' +
                '<div class="labelFileImg">' +
                    '<svg width="48" height="40" viewBox="0 0 48 40" fill="none" xmlns="http://www.w3.org/2000/svg">' +
                        '<path fill-rule="evenodd" clip-rule="evenodd" d="M36 35.999H30C29.447 35.999 29 35.552 29 34.999C29 34.446 29.447 33.999 30 33.999H36C42.565 33.999 46 28.282 46 22.636C46 16.371 40.946 11.273 34.733 11.273C34.512 11.273 34.289 11.286 34.068 11.311C33.606 11.373 33.162 11.09 33.008 10.646C31.209 5.474 26.356 2 20.933 2C13.876 2 8.134 7.79 8.134 14.908C8.134 15.509 8.185 16.119 8.291 16.775C8.369 17.259 8.085 17.728 7.619 17.883C4.258 19 2 22.153 2 25.727C2 30.288 5.679 33.999 10.2 33.999H18C18.553 33.999 19 34.446 19 34.999C19 35.552 18.553 35.999 18 35.999H10.2C4.576 35.999 0 31.391 0 25.727C0 21.571 2.462 17.875 6.207 16.28C6.158 15.814 6.134 15.36 6.134 14.908C6.134 6.688 12.773 0 20.933 0C26.953 0 32.363 3.696 34.627 9.274C34.662 9.273 34.698 9.273 34.733 9.273C42.049 9.273 48 15.268 48 22.636C48 29.276 43.878 35.999 36 35.999ZM17.293 27.706C16.902 27.316 16.902 26.683 17.293 26.292L23.292 20.293C23.384 20.201 23.495 20.127 23.618 20.077C23.862 19.975 24.138 19.975 24.382 20.077C24.505 20.127 24.615 20.201 24.708 20.293L30.707 26.292C31.098 26.683 31.098 27.316 30.707 27.706C30.512 27.901 30.256 27.999 30 27.999C29.744 27.999 29.488 27.901 29.293 27.706L25 23.413V39C25 39.552 24.553 40 24 40C23.447 40 23 39.552 23 39V23.413L18.707 27.706C18.316 28.097 17.684 28.097 17.293 27.706Z" fill="#2C74FF"/>' +
                    '</svg>' +
                '</div>' +
                '<div>' +
                    '<p>Drag and drop file here to upload, or</p>' +
                '</div>' +
                '<div>' +
                    '<span class="filepond--label-action"> Browse </span>' +
                '</div>' +
            '</div>'
        );
    }

    removeFile = () => {
        const {form} = this.props;
        const name = 'file';

        this.setState({
            files: []
        });

        FormikHelper.setValueInTheCorrectPosition(name, form, null);
    };

    changeTooltipVisibility = () => {
        this.setState(prevState => ({
            showTooltip: !prevState.showTooltip
        }));
    };

    setTooltipVisibility = (isFocused) => {
        this.setState({
            isFocused
        })
    };

    render() {
        const { tooltip } = this.props;

        return (
            <div className={classes.InputContainer}>
                <div className={classes.DocumentInput}
                     onMouseOver={this.changeTooltipVisibility}
                     onMouseOut={this.changeTooltipVisibility}
                     onFocus={() => this.setTooltipVisibility(true)}
                     onBlur={() => this.setTooltipVisibility(false)}
                >
                    <label>{this.props.label || ""}{this.props.required && <span style={{color: 'red'}}>*</span>}</label>
                    <FilePond
                        disabled={this.props.disabled}
                        ref={ref => this.pond = ref}
                        files={this.state.files}
                        allowMultiple={this.state.allowMultiple}
                        maxFiles={this.state.maxFiles}
                        server={this.state.server}
                        acceptedFileTypes={this.state.acceptedFileTypes}
                        maxFileSize={this.state.maxFileSize}
                        instantUpload={this.state.instantUpload}
                        labelFileTypeNotAllowed={this.state.labelFileTypeNotAllowed}
                        labelMaxFileSizeExceeded={this.state.labelMaxFileSizeExceeded}
                        onremovefile={this.removeFile}
                        onupdatefiles={(fileItems) => {
                            this.setState({
                                files: fileItems.map(fileItem => fileItem.file)
                            });
                        }}
                        labelIdle={this.props.renderLabel ? this.props.renderLabel() : this.renderLabel()}
                        {...this.props.field}
                        name = {this.props.name?this.props.name:"filepond"}
                    />
                    {this.state.optionsFile.length>0?
                        <ButtonModalOptions title={`Document`} options={this.state.optionsFile} />:null}
                </div>
                pepepeps
                    {
                        tooltip && (this.state.showTooltip || this.state.isFocused) ?
                            <div className={classes.TooltipContainer}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z" stroke="#BEC9D7" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M13.8182 15.6395H12.7273C12.3256 15.6395 12 15.3139 12 14.9122V10.1849C12 9.98409 11.8372 9.82129 11.6364 9.82129H10.5455" stroke="#BEC9D7" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M11.8145 7.99902C11.7141 7.99902 11.6327 8.08043 11.6327 8.18084C11.6327 8.28126 11.7141 8.36266 11.8145 8.36266C11.915 8.36266 11.9964 8.28126 11.9964 8.18084C11.9964 8.08043 11.915 7.99902 11.8145 7.99902" stroke="#BEC9D7" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    <div className={classes.Tooltip}> {tooltip} </div>
                                </div>
                            :
                            <div className={classes.TooltipContainer} />
                    }
                </div>
        );
    }
}

export default DocumentInput;

import { Field } from "formik";
import _ from "lodash";
import React from "react";
import { TooltipInput } from "src/v1/components";
import RowAdd from "../../../../forms/contents/RowAdd/RowAdd";
import TextInput from "../../../Text/TextInput";
import Classes from "../styles.module.scss";
export const StakeholderCorporate = (
	{
		fieldArrayName,
        tooltips,
		index,
		modal,
		showEmailInput = true,
		showTitle,
	}) => {
    return (<div className={Classes.containerForm}>
        <Field
            name={`${fieldArrayName}[${index}].fullName`}>
            {({ field, form }) => (

                <RowAdd
                    label={"Name"}
                    asterisk={true}
                    classNameInput={modal ? Classes.inputModal : Classes.input}
                    input={
                        <TextInput
                            name={`fullName`}
                            disabled={true}
                            value={field.value}
                            classNameError={Classes.errorForm}
                            errorMessage={_.get(form.errors, `${fieldArrayName}[${index}].fullName`)}
                            placeholder={"Enter corporate signatory name"}
                        />
                    }
                />
            )}
        </Field>
        <Field
            name={`${fieldArrayName}[${index}].authorizedSignatory.fullName`}>
            {({ field, form }) => (

                <RowAdd
                    label={"Corporate signatory"}
                    asterisk={true}
                    classNameInput={modal ? Classes.inputModal : Classes.input}
                    input={
                        <TextInput
                            name={`fullName`}
                            value={field.value}
                            classNameError={Classes.errorForm}
                            onChange={(value) => form.setFieldValue(field.name, value)}
                            errorMessage={_.get(form.errors, `${fieldArrayName}[${index}].authorizedSignatory.fullName`)}
                            placeholder={"Enter corporate signatory name"}
                        />
                    }
                />
            )}
        </Field>
		{showEmailInput &&
		<Field
			name={`${fieldArrayName}[${index}].authorizedSignatory.email`}>
			{({ field, form }) => (

				<RowAdd
					label={"Corporate signatory's email address"}
					asterisk={true}
					classNameInput={modal ? Classes.inputModal : Classes.input}
					input={
						<TextInput
							name={`email`}
							type={`email`}
							value={field.value}
							onChange={(value) => form.setFieldValue(field.name, value)}
							classNameError={Classes.errorForm}
							errorMessage={_.get(form.errors, `${fieldArrayName}[${index}].authorizedSignatory.email`)}
							placeholder={"Email address"}
						/>
					}
				/>
			)}
		</Field>

		}
        {showTitle && 
                    <TooltipInput label={tooltips?.title}>
            <Field
                name={`${fieldArrayName}[${index}].authorizedSignatory.title`}>
                {({ field, form }) => (
                    <RowAdd
                    label={"Title"}
                    asterisk={true}
                    classNameInput={modal ? Classes.inputModal : Classes.input}
                    input={
                        <TextInput
                        name={`title`}
                        value={field.value}
                        onChange={(value) => form.setFieldValue(field.name, value)}
                        classNameError={Classes.errorForm}
                        errorMessage={_.get(form.errors, `${fieldArrayName}[${index}].authorizedSignatory.title`)}
                        placeholder={"Title"}
                        />
                    }
                    />
                    )}
            </Field>
                    </TooltipInput>
    
            }
                   
    </div>)
};
import React from 'react';
import { Constants } from '../../../v1/utils/constants';
import ClaraLayout from '../../../../src/layouts/Clara/ClaraLayout'
import SelectJurisdictionView from './SelectJurisdiction'
import FormJurisdictionDetailsView from './FormJurisdictionDetails'
import BlockAdgmView from './BlockAdgm'
import JurisdictionDone from './JurisdictionDone';
import TourView  from "./Tour"
const moduleRouter = [
  {
    path: Constants.PAGES.MODULES.FORMATION.introduction.path, // Url 
    exact: true, // If use exact url
    component: <TourView />, //View component
    layout: ClaraLayout, // Component to layout
    propsLayout: { 
      startupMenu: false, //show startup menu 
      userMenu: true, // show right user menu
      fullMenu: true, // show menu navigation
      showAssistance: false, // show assistance
      showBreadCrumbs: false, //show breadCrumbs
      disableClosedAssistance: false, // Can close assistance
      cantBack: true, // can back to this pages 
    },
  },
  {
    path: Constants.PAGES.MODULES.FORMATION.scoping.path, // Url 
    exact: true, // If use exact url
    component: <SelectJurisdictionView />, //View component
    layout: ClaraLayout, // Component to layout
    propsLayout: { 
      startupMenu: false, //show startup menu 
      userMenu: true, // show right user menu
      fullMenu: false, // show menu navigation
      showAssistance: false, // show assistance
      showBreadCrumbs: false, //show breadCrumbs
      disableClosedAssistance: false, // Can close assistance
      cantBack: true, // can back to this pages 
    },
    action: Constants.ACTIONS.COMPLETE_MATTER_FLOW, // Action to access
  },
  {
    path: Constants.PAGES.MODULES.FORMATION.formJurisdictionDetails.path, // Url 
    exact: true, // If use exact url
    component: <FormJurisdictionDetailsView />, //View component
    layout: ClaraLayout, // Component to layout
    propsLayout: { 
      startupMenu: false, //show startup menu 
      userMenu: true, // show right user menu
      fullMenu: false, // show menu navigation
      showAssistance: false, // show assistance
      showBreadCrumbs: false, //show breadCrumbs
      disableClosedAssistance: false, // Can close assistance
      cantBack: true, // can back to this pages 
    },
    action: Constants.ACTIONS.COMPLETE_MATTER_FLOW, // Action to access
  },
  {
    path: Constants.PAGES.MODULES.FORMATION.blockAdgm.path, // Url 
    exact: true, // If use exact url
    component: <BlockAdgmView />, //View component
    layout: ClaraLayout, // Component to layout
    propsLayout: { 
      startupMenu: false, //show startup menu 
      userMenu: true, // show right user menu
      fullMenu: false, // show menu navigation
      showAssistance: false, // show assistance
      showBreadCrumbs: false, //show breadCrumbs
      disableClosedAssistance: false, // Can close assistance
      cantBack: true, // can back to this pages 
    },
    action: Constants.ACTIONS.COMPLETE_MATTER_FLOW, // Action to access
  },
  {
    path: Constants.PAGES.MODULES.FORMATION.jurisdictionDone.path, // Url 
    exact: true, // If use exact url
    component: <JurisdictionDone />, //View component
    layout: ClaraLayout, // Component to layout
    propsLayout: { 
      startupMenu: false, //show startup menu 
      userMenu: true, // show right user menu
      fullMenu: false, // show menu navigation
      showAssistance: false, // show assistance
      showBreadCrumbs: false, //show breadCrumbs
      disableClosedAssistance: false, // Can close assistance
      cantBack: true, // can back to this pages 
    },
    action: Constants.ACTIONS.COMPLETE_MATTER_FLOW, // Action to access
  }
];
export default moduleRouter;
import PropTypes from "prop-types";
import React from 'react';
import DetailsTabTitle from "src/components/templates/DetailsTabTitle";
import Divider from '@mui/material/Divider';
import classes from '../styles.module.scss';
import { useDispatch } from "react-redux";
import { Constants } from "src/v1/utils";
import UserItem from "src/views/modules/startups/company/companyView/Tabs/KeyPeopleTab/components/UserItem";

function DirectorsPanel ({ data, havePincas = false }) {
  const dispatch = useDispatch();

  const goTo = (value) => {
    dispatch({
      type: "GO_TO",
      page: value.isAnEntity?Constants.PAGES.viewStakeholderCompany:Constants.PAGES.viewStakeholderIndividual,
      params: { id: value.id },
    });
  };

  return (
    <>
      <DetailsTabTitle title="Key people" />
      <Divider className={classes.divider} />
      <DetailsTabTitle title="Directors" inPincas={havePincas}/>
      <div className={classes.containerDirectorsTab}>
        {data.map((director) => {
          return (
            <UserItem
              fullName={director.fullName}
              avatar={director.avatar}
              onClick={() => goTo(director)}
            ></UserItem>
          );
        })}
      </div>
    </>
  );
}

DirectorsPanel.propTypes = {
  data: PropTypes.any,
  havePincas: PropTypes.bool,
}

export default DirectorsPanel;
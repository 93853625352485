import { default as React, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import Typography from "src/modules/generic/components/Texts/Typography";
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import * as Yup from 'yup';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import classesModule from "./classes.module.scss";

function SelectorForm() {
  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.SelectorForm ?? {}
  }, [context]);

  const handleCancel = (values) => {
    cancel();
  }

  const handleSubmit = async (values) => {
    next(values)
  }

  useEffect(() => {
    dispatch(addAssistantText(translate('MODULES.RENEWALS.ULTIMATE_PARENTS.SELECTOR_FORM.TOOLTIP')));
    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch])

  const options = [
    {
      value: false,
      label: "No",
      componentProps:{
        weight: "bold",
      }
    },
    {
      value: true,
      label: "Yes",
      componentProps:{
        weight: "bold",
      }
    },
  ];
  
  const schema = Yup.object().shape({
    accept: Yup.boolean().required("Required"),
  });

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: translate('MODULES.RENEWALS.ULTIMATE_PARENTS.GENERIC.TITLE'),
          skeleton: null,
          subTitle: translate('MODULES.RENEWALS.ULTIMATE_PARENTS.GENERIC.SUBTITLE'),
          variant: 'simple'
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}  >{translate('BUTTON_NEXT')}</SubmitButton>,
        }}
      >
        <>
          <Typography component='p' variant='xbody' weight="bold">
            {translate('MODULES.RENEWALS.ULTIMATE_PARENTS.SELECTOR_FORM.TEXT1')}
          </Typography>
          <ControllerInput
            render={CheckboxButtonSimple}
            className={classesModule.CheckboxButton}
            classNameButton={classesModule.buttonCheckBox2}
            name="accept"
            options={options}
            onChange={(value) => { }}
          />
        </>
      </TemplateWizard>
    </Form>
  );
}

export default SelectorForm;


import useMachine from '../../../../../generic/context/MachineContext/useMachine';
import SelectRoles from '../../../core/forms/SelectRoles';
import React from 'react';
import Button from "../../../../../generic/components/Buttons/Button";
import useTranslate from '../../../../../generic/hooks/useTranslate';
import TemplateWizard from "../../../../../generic/templates/Modal/TemplateWizard";
import { clearAssistatText } from "../../../../../generic/store/action";
import useModal from "../../../../../generic/hooks/useModal";
import useSession from 'src/modules/session/hooks/useSession';
import { Constants } from "src/v1/utils";
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import useGetPriceByAddRole from '../../hooks/useGetPriceByAddRole'

/**
 * Step para editar los datos de SOF OTHER
 * @param {*} param0
 * @returns
 */
function SelectRolesStep({ openParam }) {

  const { next, context, prev } = useMachine();
  const { translate } = useTranslate();
  const { closeModal: onClose } = useModal();
  const { startup } = useSession();
  const dispatch = useDispatch();
  const getPriceByRole = useGetPriceByAddRole(context.getStakeholdersGroupCompanies.requestItems);

  const handleCancel = (values) => {
    onClose();
    dispatch(clearAssistatText());
  }

  const handlePrevious = () => {
    prev()
  }

  const handleCompleteSubmit = async (values) => {
    next(values)
  };

  const getRoles = () => {
    const roleKeys = Object.keys(context.roles)
    return [
      "SHAREHOLDER",
      "DIRECTOR",
      "FOUNDER",
      "EMPLOYEE",
      "CONSULTANT",
      "ADVISOR",
      "INVESTOR",
      "THIRDPARTY",
    ].map(key => {
      const value = Constants.ROLES_FROM_BACKEND[key]
      return { value: value.id, label: value.label, disabled: !roleKeys.includes(key) }
    })
  };

  return (
    <SelectRoles
      isEntity={_.get(context, "isEntity", false)}
      roles={getRoles()}
      inFormation={_.get(context, "isInFormation", false)}
      priceList={getPriceByRole(['DIRECTOR','SHAREHOLDER'],'CAYMAN')}
      hasPincas={context?.hasPincas}
      groupCompanies={context?.getStakeholdersGroupCompanies?.groupCompanies}
      buttons={{
        previous: (
          <Button onClick={handlePrevious}>Previous</Button>
        ),
        cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
      }}
      onCompleteSubmit={handleCompleteSubmit}
      propsTemplate={{
        title: `Select all the roles they have in ${startup.name}`,
        subTitle: context.stakeholder.fullName
      }}
      Template={TemplateWizard}
    />
  );
}
export default SelectRolesStep;

import { useMemo } from 'react';
import { assign } from 'xstate';

const useActions = () => {
  return useMemo(() => {
    const addStakeholder = assign((context: any = {}, event: any = null) => {
      const newContext = { ...context };
      const newData = event?.data?.data?.createStakeholder;
      const oldStakeholders = [...newContext?.initialData?.stakeholders];
      oldStakeholders.push(newData);
      newContext.initialData.stakeholders = oldStakeholders;
      const oldDirectors = [...newContext?.initialData?.directors];
      oldDirectors.push(newData);
      newContext.initialData.directors = oldDirectors;
      return newContext;
    });
    return { addStakeholder };
  }, []);
}

export default useActions;

import React, { FC, useRef } from "react";
import * as Yup from "yup";
import Form from "../../../../../components/forms/Form/TestForm";
import TextInput from "../../../../../components/inputs/Text/TextInput";
import CheckboxButtonSimple, {
  CheckboxButtonOptions,
} from "../../../../generic/components/Inputs/CheckboxButtonSimple";
import TemplateLabel from "../../../../generic/components/Inputs/LabelInput";
import ControllerInput from "../../../../generic/components/Inputs/ReactHookForm/ControllerInput";
import FormProps from "../../../../generic/forms/interfaces/FormProps";
import useTranslate from "../../../../generic/hooks/useTranslate";
import TemplateFormDefault from "../../../../generic/templates/Modal";
import CountryJurisdiction from "../../../../generic/components/CountryJurisdictionSelector";
import SubmitButton from "./../../../../generic/components/Buttons/SubmitButton";
import classesModule from "../../../core/forms/YesOrNoForm/classes.module.scss";
import _ from "lodash";

const schema = Yup.object().shape({
  isHoldingCompany: Yup.boolean().required("Required"),
  name: Yup.string().nullable().required("Add the company name"),
  country: Yup.string().nullable().required("Required"),
  jurisdiction: Yup.string().nullable().required("Required"),
  otherJurisdiction: Yup.string().when(["jurisdiction"], {
    is: "OTHER",
    then: Yup.string().nullable().required("Add the jurisdiction name"),
    otherwise: Yup.string().nullable().optional(),
  }),
});

type CompanyFormSubmit = (values: object) => Promise<any>;
interface CompanyFormProps extends FormProps {
  hasTopCompany: boolean;
  onCompleteSubmit?: CompanyFormSubmit;
}
const CompanyForm: FC<CompanyFormProps> = ({
  Template = TemplateFormDefault,
  buttons = {},
  buttonSubmitProps,
  propsTemplate,
  initialValues,
  onCompleteSubmit,
  tooltips,
  hasTopCompany,
  ...props
}) => {

  const refForm = useRef();
  const { translate } = useTranslate();

  const options: Array<CheckboxButtonOptions<boolean, any>> = [
    {
      value: false,
      label: translate(`MODULES.ONBOARDINGCOMPANY.LABEL_ANOTHER_NO_NOTFORMED`),
      // disabled: true,
    },
    {
      value: true,
      label: translate(`MODULES.ONBOARDINGCOMPANY.LABEL_ANOTHER_YES_NOTFORMED`),
      // disabled: true,
    },
  ];

  const labelLines = [
    translate("MODULES.ONBOARDINGCOMPANY.LABEL_TOP_COMPANY_LINE1_NOTFORMED"),
    translate("MODULES.ONBOARDINGCOMPANY.LABEL_TOP_COMPANY_LINE2"),
  ];

  const handleCompleteSubmit = async (values) => {
    const {
      isHoldingCompany,
      name,
      jurisdiction,
      otherJurisdiction,
      country,
    } = values;
    const groupCompany = {
      name,
      isHoldingCompany,
      jurisdiction: jurisdiction === "OTHER" ? otherJurisdiction : jurisdiction,
      country,
      jurisdictionType: _.get(initialValues, "jurisdictionType"),
    };
    await onCompleteSubmit(groupCompany);
  };

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleCompleteSubmit}
      ref={refForm}
      optionsForm={{ mode: "onChange", shouldUnregister: false }}
    >
      <Template
        buttons={{
          ...buttons,
          submit: <SubmitButton {...buttonSubmitProps}>Add</SubmitButton>,
        }}
        props={propsTemplate}
      >
        <TemplateLabel
          isRequired={true}
          variant={"top"}
          multilineTexts={labelLines}
        >
          <ControllerInput
            render={CheckboxButtonSimple}
            className={classesModule.CheckboxButton}
            classNameButton={classesModule.buttonCheckBox2}
            name="isHoldingCompany"
            options={options}
            onChange={(value) => {}}
          />
        </TemplateLabel>
        <TemplateLabel
          label={translate("MODULES.ONBOARDINGCOMPANY.LABEL_NAME")}
          className={classesModule.LabelTitle}
          isRequired={true}
        >
          <ControllerInput
            render={TextInput}
            name="name"
            placeholder={"For example, Clara"}
            defaultlabel={""}
            rightLabel={initialValues.addToName !== undefined ? initialValues.addToName.trim() : ""}
          />
        </TemplateLabel>
        <CountryJurisdiction disabled={true} countryLabel={translate("FORM_COMAPANY_LABEL_COUNTRY_INCORPORATION")} jurisdictionLabel={translate("FORM_COMAPANY_LABEL_JURISDICTION_BIS")}/>
      </Template>
    </Form>
  );
};
export default CompanyForm;

import useBusinessRules from "./../../../../modules/clara/hooks/useBusinessRules"
import useSecurity from "./../../../../modules/security/hooks/useSecurity"
import EditDueDiligenceForm from "./EditDueDiligenceForm"
const EditDueDiligenceFormHook = (props) => {

    const businessRules= useBusinessRules()
    const {hasRoleAccess} = useSecurity()
    return(
        <EditDueDiligenceForm {...props} hasRoleAccess={hasRoleAccess} businessRules={businessRules}></EditDueDiligenceForm>
    )
}  
export default EditDueDiligenceFormHook;
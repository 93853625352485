import React,{useEffect} from "react";
import {Helmet} from "react-helmet";
import useTextLang from "../../../hooks/custom/useTextLang"

const ManagerHeadMetadata = ({ title ="META_HEAD_TITLE_DEFAULT",custom}) => {
    const titleTranslate =useTextLang(title,null,"META_HEAD_TITLE_DEFAULT");
   
    
    return (
        <Helmet>
            {titleTranslate && <title>{titleTranslate}</title>}
            {custom}
        </Helmet>
    );
}

export default ManagerHeadMetadata;
import React from "react";
import { useParams } from "react-router";
import MicroFrontend from "src/mf";

const AddCancelShares = ({go,...props}) => {
 const params = useParams();

  return (
    <MicroFrontend id="MF_Share_Add_Cancel" component={"./AddCancelShares"} mf="capTable" params={{go,...props,...params}} />
  );
};

export default AddCancelShares;

import useMutation from "src/hooks/custom/useMutation";
import useSession from "src/modules/session/hooks/useSession";
import { gql } from '@apollo/client';
export const ADD_NEW_SHARE_CLASS = `
mutation createShareClass ($startupId: ID!, $groupCompanyId: ID!, $shareClassData: ShareClassInput!) {
    createShareClass (startupId: $startupId, groupCompanyId: $groupCompanyId,  shareClassData: $shareClassData) {
        id
        name
    }
}`;
const useAddNewShareClass=(config,props,groupCompanyId)=>{
    const {startupId} = useSession()
      const [mutation,loading] = useMutation(ADD_NEW_SHARE_CLASS, config,{...props, fetchPolicy:'no-cache' })
      return {mutation,loading}
  }
  export default useAddNewShareClass

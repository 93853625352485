
import React from 'react';
import classesModule from "./classes.module.scss";
import PropTypes from 'prop-types';
import Card from "./../../Contents/Card";
import _ from "lodash";

/**
 * Botonera of Icons
 * Migrate to Temaplate form
 */
const ORIENTATION = {
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical',
}
const VARIANTS = {
    ICONS: 'icons',
}
const Botonera = ({ orientation, buttons,className, variant }) => {
    return (
        <React.Fragment>
            <div className={`${classesModule.BotoneraIconsContent} ${classesModule[orientation]} ${classesModule[variant]} ${className}`}>
                <Card className={`${classesModule.BotoneraIcons}`}>
                    {_.map(buttons, (button, index) => {
                        return (
                            <React.Fragment key={index}>
                                {button}
                                {((index + 1) < buttons.length) ? (

                                    <div className={classesModule.line} />

                                ) : null}
                            </React.Fragment>
                        )
                    })}
                </Card>
            </div>
        </React.Fragment >
    )
};
Botonera.propTypes = {
    orientation: PropTypes.oneOf(Object.values(ORIENTATION)),
    variant: PropTypes.oneOf(Object.values(VARIANTS))
};
Botonera.defaultProps = {
    orientation: ORIENTATION.VERTICAL,
    variant: VARIANTS.ICONS,
    buttons: []
};
export default Botonera;

import React, { Component } from 'react';
import { Field } from "formik";
import _ from 'lodash';
import moment from "moment";

import classes from "../Styles.module.scss";
import ClaraSelect from "../../../../../../../v1/components/Form/ClaraSelect/ClaraSelect";
import { TextType, TextAreaInput, TextInput } from '../../../../../../../v1/components';
import Checkbox from "../../../../../../../v1/containers/Forms/Inputs/Checkbox";
import FormikHelper from "../../../../../../../v1/utils/formikHelper";
import ClaraUpload from "../../../../../../../v1/components/Form/ClaraUpload/ClaraUpload";
import ClaraCountrySelector from "../../../../../../../v1/components/Form/ClaraCountrySelector/ClaraCountrySelector";
import ClaraCurrencySelector from "../../../../../../../v1/components/Form/ClaraCurrencySelector/ClaraCurrencySelector";
import AuthorisedSignatories from "../../../../../../inputs/AuthorizedSignatories/AuthorizedSignatories";
import Line from "../../../../../../generic/Line/Line";

class BRCAgreementDoc extends Component {

	constructor(props) {
		super(props);
		this.state = {
			typeSelected: undefined,
			stakeholderSelected: undefined,
			groupCompanySelected: undefined,
			authorizedSelected: undefined,
			authorizedSignatories: [],
			authorizedSignatoriesList: [],
			documentTypes: [],
			documentTypesList: [],
			dynamicParams: [],
			date: undefined,
			expirationDate: undefined,

		}
	};

	componentWillMount() {
		if (this.props.values) {
			const values = this.props.values.values ? this.props.values.values : this.props.values;
			const expirationDate = this.getDateForPickerFromData(values.expirationDate);
			this.setState({
				typeSelected: values.documentType,
				stakeholderSelected: values.stakeholder,
				groupCompanySelected: values.company,
				expirationDate,
				authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.company).map(as => ({
					id: as.id,
					label: as.label
				}))
			});


		}

	}

	componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps.values) {
			const values = nextProps.values.values ? nextProps.values.values : nextProps.values;
			const expirationDate = this.getDateForPickerFromData(values.expirationDate);
			this.setState({
				expirationDate,
				authorizedSignatoriesList: this.filterAuthorizedByGroupCompany(values.company)
					.map(as => ({
						id: as.id,
						label: as.label
					}))
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.values) {
			const values = prevProps.values.values ? prevProps.values.values : prevProps.values;
			console.log('componentDidUpdate');
			console.log(values);
		}
	}

	getDynamicParamsByType(documentType) {
		const type = this.props.documentTypes.filter(agrType => agrType.code === documentType)[0] || {};
		return type.fields || [];
	}

	getGroupCompanies = () => {
		let authorizedSignatories = this.props.authorizedSignatories;
		let groupCompanies = this.props.groupCompanies;
		let companies = [];
		if (groupCompanies && authorizedSignatories) {
			companies = _.filter(groupCompanies, (groupCompany) => {
				let fined = _.filter(authorizedSignatories, (authorized) => {
					if (groupCompany.authorizedSignatories) {
						return groupCompany.authorizedSignatories.some(au => au.stakeholder.id === authorized.id);
					}
					return false;
				});
				if (fined) {
					return true;
				}
				return false;
			});
		}
		return companies;
	};

	selectGroupCompany = (gcId, extraParams, form) => {
		const groupCompany = this.findGroupCompany(gcId);

		// this is for a weird behaviour when hit edit button after preview a document
		if (this.state.groupCompanySelected && groupCompany) {
			return null;
		}

		this.setState({
			groupCompanySelected: groupCompany
		});

		if (groupCompany) {

			if (!groupCompany.registeredAddress) {
				groupCompany.registeredAddress = {}
			}

			let authorizedSignatories = _.get(form, 'values.company.authorizedSignatories', []);

			form.setValues({
				...form.values,
				company: {
					id: gcId,
					name: groupCompany.name || '',
					country: _.get(groupCompany, 'country.code', null) || _.get(groupCompany, 'country', null),
					jurisdiction: groupCompany.jurisdiction,
					registeredNumber: groupCompany.registeredNumber || '',
					type: groupCompany.type || '',
					registeredAddress: {
						...groupCompany.registeredAddress, country: _.get(groupCompany.registeredAddress, "country.code", null) || _.get(groupCompany.registeredAddress, "country", null)
					},
					authorizedSignatories: authorizedSignatories
				},
				terms: {
					...form.values.terms,
					currency: _.get(form, 'values.terms.currency', groupCompany.transactionCurrency)
				}
			});

			this.setState({
				groupCompanySelected: groupCompany,
			})
		} else {
			form.setValues({
				...form.values,
				company: {}
			});
		}
	};

	findGroupCompany(gcId) {
		return this.props.groupCompaniesList.find(gc => gc.id === gcId);
	};

	selectStakeholder = (stakeholderId, extraParams, form, cb) => {
		const stakeholder = this.findStakeholder(stakeholderId);

		this.setState({
			stakeholderSelected: stakeholder,
		});

		if (stakeholder) {
			const _stakeholder = !_.isEmpty(form.values.stakeholder) ? form.values.stakeholder : stakeholder;

			form.setValues({
				...form.values,
				stakeholder: {
					id: stakeholderId,
					fullName: _stakeholder.fullName || ''
				}
			});
		} else {
			form.setValues({
				...form.values,
				stakeholder: {}
			});
		}
	};

	findStakeholder(roleId) {
		return this.props.stakeholderList.find(stakeholder => stakeholder.id === roleId)
	};

	findStakeholderAuthorized(roleId) {
		return this.props.stakeholderList.filter(stakeholder => {
			return stakeholder.roles.some(role => role.stakeholder.id === roleId);
		})[0] || null;
	};

	filterAuthorizedByGroupCompany(groupCompany) {
		if (groupCompany) {
			let gc = this.findGroupCompany(groupCompany.id);
			return this.props.authorizedSignatories.filter(authorized => {
				return authorized.groupCompanyId === gc.id
			});
		}
		return [];
	}

	selectAuthorized = (value, form, field, replace) => {
		const stakeholder = this.props.authorizedSignatories.find(e => e.id === value);
		const email = stakeholder ? stakeholder.email : '';
		replace(0, { id: value, email })
	};

	handleDateChange = (e, field, form) => {
		const date = moment(e).format("YYYY-MM-DD");

		FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

		this.setState({
			date: e
		})
	};

	updateEmailValue(e, form) {
		if (this.state.stakeholderSelected.isAnEntity) {
			form.setFieldValue('stakeholder.authorizedSignatory.email', e.target.value, false)
		}
	}

	getDateForPickerFromData(date) {
		if (!date) return undefined;
		return moment(date).toDate();
	}

	handleChange(e, field, form) {

		const date = e === null ? null : moment(e).format("YYYY-MM-DD");

		FormikHelper.setValueInTheCorrectPosition(field.name, form, date);

		this.setState({
			[field.name]: e
		})
	}

	getAuthorizedSignatoriesList = (index, form) => {
		if (form.values && form.values.company) {
			return this.filterAuthorizedByGroupCompany(form.values.company).map(as => ({
				id: as.id,
				label: as.label
			}))
		} else {
			return [];
		}
	};

	getLabel = (key) => {
		switch (key) {
			case "fullName":
				if (this.state.stakeholderSelected.isAnEntity) {
					return "Company Name";
				} else {
					return "Name";
				}
			case "country":
				if (this.state.stakeholderSelected.isAnEntity) {
					return "Country of Incorporation";
				} else {
					return "Nationality";
				}
			case "address":
				if (this.state.stakeholderSelected.isAnEntity) {
					return "Registered Address";
				} else {
					return "Residential Address";
				}
			case "passportOrRegistered":
				if (this.state.stakeholderSelected.isAnEntity) {
					return "Company Number";
				} else {
					return "Passport Number";
				}
		}
	};

	getField = (key) => {
		switch (key) {
			case "passportOrRegistered":
				if (this.state.stakeholderSelected.isAnEntity) {
					return "stakeholder.registeredNumber";
				} else {
					return "stakeholder.passportNumber";
				}
		}
	};

	getTooltip = (key, fieldName) => {
		switch (key) {
			case "fullName":
				if (this.state.stakeholderSelected.isAnEntity) {
					return "Investor Company's Name";
				} else {
					return "Investor's Name";
				}
			case "passportOrRegistered":
				if (this.state.stakeholderSelected.isAnEntity) {
					return "Company Number";
				} else {
					return "Passport Number";
				}
			case "country":
				if (this.state.stakeholderSelected.isAnEntity) {
					return "Country";
				} else {
					return "Nationality";
				}
			case "addressLine":
				if (this.state.stakeholderSelected.isAnEntity) {
					return "Registered Address - Address";
				} else {
					return "Residential Address - Address";
				}
			case "addressCity":
				if (this.state.stakeholderSelected.isAnEntity) {
					return "Registered Address - City";
				} else {
					return "Residential Address - City";
				}
			case "addressState":
				if (this.state.stakeholderSelected.isAnEntity) {
					return "Registered Address - State";
				} else {
					return "Residential Address - State";
				}
			case "addressCountry":
				if (this.state.stakeholderSelected.isAnEntity) {
					return "Registered Address - Country";
				} else {
					return "Residential Address - Country";
				}
			case "addressPostCode":
				if (this.state.stakeholderSelected.isAnEntity) {
					return "Registered Address - Post Code";
				} else {
					return "Residential Address - Post Code";
				}
		}
	};

	getPlaceholder = (key) => {
		switch (key) {
			case "passportOrRegistered":
				if (this.state.stakeholderSelected.isAnEntity) {
					return "Company Number...";
				} else {
					return "Passport Number...";
				}
		}
	};

	getCurrencyLabel = (form) => {
		return _.get(form, 'values.terms.currency', '');
	};

	getStakeholderAdds = () => {
		return [
			{
				type: "stakeholder",
				label: "Add New Individual Stakeholder",
				textWarning: "This will create a new stakeholder for your startup. You can add more details later on the stakeholder's profile page.",
				placeholder: "Stakeholder's Name...",
				startupId: this.props.startup.id,
				textToSave: "fullName",
				params: {
					isAnEntity: false,
					// roles : [{
					// 	role:"SHAREHOLDER",
					// 	groupCompany: this.state.groupCompanySelected.id,
					// }]
				},
				paramsReturn: 'isAnEntity'
			},
			{
				type: "stakeholder",
				label: "Add New Corporate Stakeholder",
				textWarning: "This will create a new stakeholder for your startup. You can add more details later on the stakeholder's profile page.",
				placeholder: "Stakeholder's Name...",
				startupId: this.props.startup.id,
				textToSave: "fullName",
				params: {
					isAnEntity: true,
					// roles : [{
					// 	role: "SHAREHOLDER",
					// 	groupCompany: this.state.groupCompanySelected.id,
					// }],
				},
				paramsReturn: 'isAnEntity'
			},
		]
	};

	getCompanyAdds = () => {
		return [{
			type: "groupCompany",
			label: "Add New Company",
			textWarning: "This will create a new company for your startup. You can add more details later on the company's profile page.",
			placeholder: "Company Name...",
			startupId: this.props.startup.id,
			params: null,
			paramsReturn: `
				name
				authorizedSignatories {
					id
					stakeholder {
						id
						email
						fullName
						roles {  id
        name
        groupCompany { id name}
        stakeholder { id } }
					}
				}
			`
		}]
	};

	getAuthorizedSignatoryAdds = () => {
		return [
			{
				type: "stakeholder",
				label: "Add New Individual Stakeholder",
				textWarning: "This will create a new stakeholder for your startup. You can add more details later from the stakeholder's profile page.",
				placeholder: "Enter Stakeholder's Name",
				startupId: this.props.startup.id,
				textToSave: "fullName",
				params: {
					isAnEntity: false,
					roles: [{
						role: "DIRECTOR",
						groupCompany: this.state.groupCompanySelected.id,
					}]
				}
			},
		]
	};

	handleStakeholderCreate = (value, name, additionalValue, form) => {

		let { stakeholders, stakeholderList } = this.props;
		let newSh = { id: value.id, fullName: name, ...additionalValue };
		stakeholderList = _.map(stakeholderList,st=>({...st})).push(newSh);

		stakeholders.push({ id: value.id, label: name });
		// this.setState({ groupCompaniesList, groupCompanies });

	};

	handleGroupCompanyCreate = (value, name, additionalValue, form) => {

		let { groupCompanies, groupCompaniesList } = this.props;
		let newGc = { id: value.id, name, ...additionalValue };
		groupCompaniesList.push(newGc);

		groupCompanies.push({ id: value.id, label: name });

	};

	handleAuthorizedSignatoryUpdate = (value) => {

		const { authorizedSignatories, groupCompanySelected } = this.state;
		const groupCompany = this.findGroupCompany(groupCompanySelected.id);
		_.set(groupCompany,'authorizedSignatories',_.map(groupCompany.authorizedSignatories,auth=>({...auth})).push({ stakeholder: value }))
		authorizedSignatories.push(value);

		this.handleStakeholderCreate(
			{id: value.id, type: 'stakeholder'},
			value.fullName,
			{id: value.id, isAnEntity: false}
		)

	};

	render() {
		const isRequired = message => value => (!!value ? undefined : message);
		const { tooltips = {}, formType } = this.props;

		return (
			<React.Fragment>
				{formType === 'uploadToEdit' ? (<div>
					<Field name={`file`}>
						{({ field, form }) => (
							<ClaraUpload
								required={true}
								name={`filepond`}
								label='Upload your document: '
								field={field}
								form={form}
							/>
						)}
					</Field>
				</div>) : null}
				<h1 className={classes.title}>
					Edit Document
				</h1>
				<Field name='agreementName'>
					{({ field, form }) => (
						<TextInput
							classNameInput={{ root: classes.ContainerRow }}
							required={true}
							type='text'
							label='Document Name: '
							field={field}
							form={form}
							placeholder={"Document Name..."}
							tooltip={tooltips['Document Name']}
						/>
					)}
				</Field>
				<Line className={classes.line} />
				<div className={classes.ZoneTitle}>
					<h1>{'Company Name'}</h1>
				</div>
				<div className={`${classes.ContainerRow} ${classes.Select}`}>
					<Field name='company.id'>
						{({ field, form }) => (
							<ClaraSelect
								required={true}

								field={field}
								form={form}

								label={"Select Company: "}
								lists={this.getGroupCompanies()}
								mode={"classic"}
								callbacks={{
									createValues: this.handleGroupCompanyCreate,
									updateValues: this.selectGroupCompany
								}}
								placeholder={"Select from the list..."}
								tooltip={tooltips['Select Company']}
							/>
						)}
					</Field>
				</div>
				{
					this.state.groupCompanySelected ?
						<React.Fragment>
							<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
								<h1>Company Details</h1>
								<p>Please verify or update the company's information below:</p>
							</div>
							<Field name='company.name'>
								{({ field, form }) => (
									<TextInput
										classNameInput={{ root: classes.ContainerRow }}
										required={true}
										type='text'
										label='Company Name: '
										field={field}
										form={form}
										placeholder={"Company Name..."}
										tooltip={tooltips["Company's Name"]}
									/>
								)}
							</Field>
							<div className={`${classes.Select}`}>
								<Field name='company.country'>
									{({ field, form }) => (
										<ClaraCountrySelector
											jurisdictionsEnabled={true}
											isRequired={{ country: true, jurisdiction: true, otherJurisdiction: true }}
											countryField={'company.country'}
											label={'Country of Incorporation: '}
											jurisdictionField={'company.jurisdiction'}
											jurisdictionLabel={'Jurisdiction: '}
											returnCountryAsString={true}
											otherJurisdictionField={'company.otherJurisdiction'}
											setFieldValue={form.setFieldValue}
											initialValues={{ jurisdiction: _.get(form.values, 'company.jurisdiction') }}
											additionalStyle={'ClaraCountrySelectorModal'}
											tooltips={{
												country: tooltips["Company's Country"],
												jurisdiction: tooltips["Company's Jurisdiction"],
												other: tooltips['Other Jurisdiction']
											}}
										/>
									)}
								</Field>
							</div>
							{/*
                            <div className={classes.ContainerRow}>
                                <Field name='company.jurisdiction'>
                                    {({ field, form }) => (
                                        <TextInput
                                            required={true}
                                            type='text'
                                            label='Jurisdiction: '
                                            field={field}
                                            form={form}
                                            placeholder={"Jurisdiction..."}
                                            tooltip={tooltips["Company's Jurisdiction"]}
                                        />
                                    )}
                                </Field>
                            </div>
							*/}
							<Field name='company.type'>
								{({ field, form }) => (
									<TextType
										classNameInput={{ root: classes.ContainerRow }}
										required={true}
										type='text'
										groupCompany={this.state.groupCompanySelected}
										label='Company Type: '
										field={field}
										form={form}
										placeholder={'Company Type...'}
										tooltip={tooltips["Company's Company Type"]}
									/>
								)}
							</Field>
							<Field name='company.registeredNumber'>
								{({ field, form }) => (
									<TextInput
										classNameInput={{ root: classes.ContainerRow }}
										required={true}
										type='text'
										label='Company Number: '
										field={field}
										form={form}
										placeholder={'Company Number...'}
										tooltip={tooltips["Company's Company Number"]}
									/>
								)}
							</Field>
							<div className={`${classes.Select}`}>
								<Field name='company.registeredAddress.country'>
									{({ field, form }) => (
										<ClaraCountrySelector
											jurisdictionsEnabled={false}
											isRequired={{ country: true }}
											countryField={'company.registeredAddress.country'}
											returnCountryAsString={true}
											label={'Registered Address: '}
											setFieldValue={form.setFieldValue}
											additionalStyle={'ClaraCountrySelectorModal'}
											tooltips={{
												country: tooltips["Company's Registered Address - Country"]
											}}
										/>
									)}
								</Field>
							</div>
							<div className={classes.ContainerRow}>
								<Field name='company.registeredAddress.street'>
									{({ field, form }) => (
										<TextAreaInput
											type='text'
											label={' '}
											field={field}
											form={form}
											placeholder={"Address..."}
											tooltip={tooltips["Company's Registered Address - Address"]}
										/>
									)}
								</Field>
							</div>
							<Field name='company.registeredAddress.city'>
								{({ field, form }) => (
									<TextInput
										classNameInput={{ root: classes.ContainerRow }}
										type='text'
										label=' '
										field={field}
										form={form}
										placeholder={'City...'}
										tooltip={tooltips["Company's Registered Address - City"]}
									/>
								)}
							</Field>
							<Field name='company.registeredAddress.state'>
								{({ field, form }) => (
									<TextInput
										classNameInput={{ root: classes.ContainerRow }}
										type='text'
										label=' '
										field={field}
										form={form}
										placeholder={'State / Region...'}
										tooltip={tooltips["Company's Registered Address - State"]}
									/>
								)}
							</Field>
							<Field name='company.registeredAddress.zipCode'>
								{({ field, form }) => (
									<TextInput
										classNameInput={{ root: classes.ContainerRow }}
										type='text'
										label=' '
										field={field}
										form={form}
										placeholder={"Post Code..."}
										tooltip={tooltips["Company's Registered Address - Post Code"]}
									/>
								)}
							</Field>
							<Line className={classes.line} />
							<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
								<h1>Directors</h1>
								<p>Select the directors that will sign this board resolution. You can add them individually, or all together, or add new directors.</p>
							</div>
							<Field name='company.authorizedSignatories_error'>
								{({ field, form }) => (
									<React.Fragment>
                                        <span hasError={_.get(form.errors, "company.authorizedSignatories") ? "true" : "false"}>
                                            <AuthorisedSignatories
												selectInputLabel={'Select Director'}
												list={this.props.stakeholderList}
												fieldArrayName={'company.authorizedSignatories'}
												idGroupCompany={this.state.groupCompanySelected.id}
												handleAddListStakeholder={this.handleAuthorizedSignatoryUpdate}
												errorMessage={_.get(form.errors, "company.authorizedSignatories")}
											/>
                                        </span>
									</React.Fragment>
								)}
							</Field>

							<Line className={classes.line} />
							<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
								<h1>Authorised Signatory</h1>
								<p>Add all the signatories that will sign the convertible on behalf of the company.</p>
							</div>
							<Field name='terms.authorizedSignatories_error'>
								{({ field, form }) => (
									<React.Fragment>
										<span hasError={_.get(form.errors, "terms.authorizedSignatories") ? "true" : "false"}>
											<AuthorisedSignatories
												modal={true}
												showEmailInput={false}
												list={this.state.authorizedSignatories}
												fieldArrayName={'terms.authorizedSignatories'}
												idGroupCompany={this.state.groupCompanySelected.id}
												handleAddListStakeholder={this.handleAuthorizedSignatoryUpdate}
												errorMessage={_.get(form.errors, "terms.authorizedSignatories")}
											/>
										</span>
									</React.Fragment>
								)}
							</Field>
							<Line className={classes.line} />
						</React.Fragment>
						:
						null
				}

				<div className={classes.ZoneTitle}>
					<h1>{'Investor Name'}</h1>
				</div>
				<div className={`${classes.ContainerRow} ${classes.Select}`}>
					<Field name='terms.stakeholder.id'>
						{({ field, form }) => (
							<ClaraSelect
								required={true}
								field={field}
								form={form}
								label={"Select Investor: "}
								adds={this.getStakeholderAdds()}
								lists={this.props.stakeholders}
								mode={"classic"}
								callbacks={{
									createValues: this.handleStakeholderCreate,
									updateValues: this.selectStakeholder
								}}
								placeholder={"Select from the list..."}
								tooltip={tooltips["Select Investor"]}
							/>

						)}
					</Field>
				</div>
				<Line className={classes.line} />
				{
					this.state.stakeholderSelected && this.state.groupCompanySelected ?
						<React.Fragment>
							{
								this.props.formType === 'BOARD_RESOLUTION_CONVERTIBLE_OTHER' &&
									<>
										<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
											<h1>Convertible Type</h1>
											<p>The type of convertible being approved by the directors.</p>
										</div>
										<Field name='terms.convertibleType'>
											{({ field, form }) => (
												<TextInput
													classNameInput={{ root: classes.ContainerRow }}
													required={true}
													type='text'
													label='Convertible Type: '
													field={field}
													form={form}
													placeholder={"Type here..."}
													tooltip={tooltips["Convertible Type"]}
												/>
											)}
										</Field>
										<Line className={classes.line} />
									</>
							}
							<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
								<h1>Currency</h1>
								<p>The currency in which the loan amount, valuation cap and equity financing amount will be quoted in the convertible.</p>
							</div>
							<div className={`${classes.ContainerRow} ${classes.Select}`}>
								<Field name='terms.currency'>
									{({ field, form }) => (
										<ClaraCurrencySelector
											currencyField={'terms.currency'}
											setFieldValue={form.setFieldValue}
											type={'List'}
											label={'Currency:'}
											placeholder={'Select from the list...'}
											// initialValue={_.get(data,'transactionCurrency')}
											additionalStyle={'ClaraCurrencySelectorModal'}
											tooltips={tooltips['Currency']}
										/>
									)}
								</Field>
							</div>
							<Line className={classes.line} />
							<div className={`${classes.ZoneTitle} ${classes.VerifyStakeholder}`}>
								<h1>Amount</h1>
								<p>The amount the investor is providing to the holding company.</p>
							</div>
							<Field name='terms.investmentAmount'>
								{({ field, form }) => (
									<TextInput
										classNameInput={{ root: classes.ContainerRow }}
										required={true}
										type='number'
										label='Amount: '
										field={field}
										form={form}
										placeholder={"Amount..."}
										rightLabel={this.getCurrencyLabel(form)}
										tooltip={tooltips["Investment Amount"]}
									/>
								)}
							</Field>
							<Line className={classes.line} />
						</React.Fragment>
						: null
				}
				{formType === 'uploadToEdit' ? <div>
					<Field name='confirmSameData'>
						{({ field, form }) => (
							<Checkbox
								className={classes.sameDataCheckbox}
								field={field}
								validate={isRequired('This field is required')}
								form={form}
								required={true}
								label='All document details are verified and correct.'
							/>
						)}
					</Field>
				</div> : null}
			</React.Fragment>
		);
	}
}

export default BRCAgreementDoc;

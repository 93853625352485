import React, { Fragment, useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Text from '../../../text/Text/Text';
import classes from '../PendingDraft/PendingDraft.module.scss';
import image from '../../../../images/pending.svg'
import { useParams } from 'react-router-dom';
import useHasDraftPending from '../../../../hooks/common/useHasDraftPending';
import _ from "lodash";
import { Loading } from "../../../../v1/components";
import { Constants } from "../../../../v1/utils";
import ChatContainer from "../../../../v1/containers/Chat/ChatContainer";
import serverHelper from '../../../../v1/utils/server_helper'
import LayoutContext from "../../../../layouts/LayoutContext";

const BackAndResumeFlow = ({ flowName, enabledShowPending=true,setLastStartup = false, pageAfterFinished = Constants.PAGES.switchMatters, ...props }) => {
    const dispatch = useDispatch();
    const layoutState = useContext(LayoutContext);
    const [showPending, setShowPending] = useState(true);
    const params = useParams();
    const { matterId } = params;
    const { isPending, loading } = useHasDraftPending(flowName, matterId, "MATTER_KIND");

    const handleClose = (restart) => {
        dispatch({ type: 'SET_RESTART_FLOW', restart })
        _.get(layoutState, "update", () => { })({
            "showHeader": true,
            "showFooter": true
        })
        setShowPending(false)
    }
    useEffect(() => {
        setShowPending(isPending)
    }, [isPending])
    const handleActionsPostFlow = async () => {
        dispatch({ type: 'GO_TO', page: pageAfterFinished })
    }

    return (
        <Loading showChildren={false} show={loading}>
            {showPending && enabledShowPending?
                <Fragment>
                    <div className={classes.PendingDraft}>
                        <div className={classes.PendingDraftContent}>
                            <h2>{props.title}</h2>
                            <h1>{<Text uuid={"TEXT_STARTUP_DRAFT_CONTINUE_TITLE"} />}</h1>
                            <p><Text uuid={"TEXT_STARTUP_DRAFT_CONTINUE_TEXT"} /></p>
                            <div className={classes.Botonera}>
                                <button className={`genericWhiteBorder`} onClick={() => handleClose(true)}>
                                    <Text uuid={"BUTTON_RESTART_OPERATION"}
                                        params={{ operation: props.operationButton }} />
                                </button>
                                <button className={`genericBlue`} onClick={() => handleClose(false)}>
                                    <Text uuid={"BUTTON_CONTINUE_OPERATION"}
                                        params={{ operation: props.operationButton }} />
                                </button>
                            </div>
                        </div>
                        <div className={classes.PendingDraftImg}>
                            <img src={image} />
                        </div>

                    </div>
                </Fragment> :
                <Fragment>
                    <ChatContainer
                        initialFlowName={flowName}
                        initialStateName='InitialFlow_MandatoryState'
                        initialEvent={flowName}
                        initialKey=''
                        initialValue=''
                        initialValueStart={matterId}
                        actionAfterFinished={() => handleActionsPostFlow()}
                        params={params}
                        onCloseChat={() => dispatch({ type: 'GO_TO', page: Constants.PAGES.switchMatters })}
                    />
                </Fragment>
            }
        </Loading>
    )
}

export default BackAndResumeFlow
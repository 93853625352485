import useMutation from '../../../../../hooks/custom/useMutation'
import {
  EquityFields,
  OWNER_EQUITY_GROUP_COMPANY,
  OWNER_EQUITY_STAKEHOLDER,
  ShareIncentivePlanFields
} from "../../../equity/graphql/fragments";

export const UPSERT_EQUITY = `
${OWNER_EQUITY_GROUP_COMPANY}
${OWNER_EQUITY_STAKEHOLDER}
${EquityFields}
${ShareIncentivePlanFields}
mutation upsertEquity(
  $groupCompanyId: String
  $startupId: String
  $owner: OwnerInput
  $shareType: String
  $equityData: EquityInput
  $isThereSIP:Boolean! = false
) {
  upsertEquity(
    groupCompanyId: $groupCompanyId
    startupId: $startupId
    owner: $owner
    shareType: $shareType
    equityData: $equityData
  ) {
   ...EquityFields
      owner {
             ...on StakeHolder {
              ...OwnerEquityStakeholder
            }
            ...on GroupCompany {
              ...OwnerEquityGroupCompany
          }
          }
   shareIncentivePlan @include(if:$isThereSIP)
   {
   ...ShareIncentivePlanFields
  }
  }
}
`;

const useUpsertEquity = (options = {}) => {

    const [mutation] = useMutation(UPSERT_EQUITY, {},{  ...options });

    return { mutation};
};

export default useUpsertEquity;

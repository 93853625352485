import React, { FC, useEffect } from 'react';
import { useDispatch } from "react-redux";
import useRemovePendingEntity from "../../../../../../hooks/services/modules/assistance/useRemovePendingEntity";
import { StepProps } from "../../../../../generic/components/Wizard/Step";
import useCustomMachine from "../../../../../generic/hooks/useCustomMachine";
import useTranslate from "../../../../../generic/hooks/useTranslate";
import { addAssistantText, clearAssistatText } from "../../../../../generic/store/action";
import useSession from '../../../../../session/hooks/useSession';
import FirstStep from "../../../core/wizards/steps/FirstStep";

const FirstStepConsultant: FC<StepProps> = ({}: StepProps) => {
    const {translate} = useTranslate();
    const {next, send} = useCustomMachine();
    const dispatch=useDispatch()
    const { startup } = useSession();
    const [removePendingEntity] = useRemovePendingEntity({showSuccessNotification:false});
    const handleCompleteSubmit = async () => {
        await removePendingEntity({entityType: 'CONSULTANT', startupId: startup.id})
        dispatch(clearAssistatText())
    }

    useEffect(()=>{
        dispatch(clearAssistatText())
        dispatch(addAssistantText(translate("MODULES.ONBOARDINGROLES.CONSULTANT.FIRST_STEP_TOOLTIPS"),'text'))
    },[])

    return (
        <>
            <FirstStep
                role={'CONSULTANT'}
                onCompleteSubmit={handleCompleteSubmit}
                entity='a consultant'
                tooltips={{isNotExist:translate("MODULES.ONBOARDINGROLES.CONSULTANT.FIRST_STEP_IS_NOT_EXIST_TOOLTIPS")}}
            />
        </>
    );
}

export default FirstStepConsultant;

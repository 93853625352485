// import useQuery from "src/hooks/custom/useQuery";
import _ from 'lodash'
import useLazyQuery from "src/hooks/custom/useLazyQuery";

const GET_STATES_BY_COUNTRY=`
query getStates ($language: String!, $code: String, $state:String ) {
     
  citiesByStateList(code:$code, language: $language, state:$state) 

}
`
const useGetCityByState=(variables,config)=>{
    const {data,loading,manualQuery}=useLazyQuery(GET_STATES_BY_COUNTRY,variables,config)
    return{data:_.get(data,'citiesByStateList'),loading,manualQuery}
}
export default useGetCityByState

import get from 'lodash/get';
import includes from "lodash/includes";
import moment from "moment";
import React, { ReactNode } from "react";
import * as Yup from 'yup';
import { Date, ExecutionDate, ExpiryDate, IpProvision, Nationality, Parties, Party, PassportNumber } from "../Fields";
import { DOCUMENT_TYPE_IDENTITY_DOCUMENTS, FORMATION_DOCUMENTS_EXC_DOCUMENT_TYPES, FORMATION_DOCUMENTS_EXP_DOCUMENT_TYPES, PASSPORT_DOCUMENT_TYPES, VISA_STAMPS_RESIDENCE_ID_DOCUMENTS_TYPES } from "../constants";

type FieldsType = 'party' | 'parties' | 'expirationDate' | 'ipProvision' | 'executedOnDate' | 'nationality' | 'number' | 'date'

const useGetFieldsByDocumentType = (documentType, category, context, { listParty, isDisabled, listParties, handleAddToParties, parties, handleRemoveParty }) => {

  const fields: { [x in FieldsType]: ReactNode } = {
    party: <Party defaultValue={get(context, "party", "")} listParty={listParty} isDisabled={isDisabled} />,
    parties: <Parties listParty={listParties} isDisabled={listParties.length === 0} handleAddToParties={handleAddToParties} parties={parties} handleRemoveParty={handleRemoveParty} />,
    expirationDate: <ExpiryDate defaultValue={get(context, "expiryDate", "") || get(context, "file.expirationDate", "")} />,
    executedOnDate: <ExecutionDate defaultValue={get(context, "executedOnDate", "") || get(context, "file.executedOnDate", "")} />,
    ipProvision: <IpProvision defaultValue={get(context, "ipProvision", false)} />,
    nationality: <Nationality defaultValue={get(context, "nationality", false)} />,
    number: <PassportNumber defaultValue={get(context, "number", false)} />,
    date: <Date defaultValue={get(context, 'expiryDate', '')} />
  }
  
  const schemas = {
    party: Yup.string().required("This field is required.").nullable(),
    parties: Yup.array().nullable().required('Thist field is required').min(1, 'Must have one at least'),
    date: Yup.date().nullable().typeError('Must be a date').test({
      test: (value) => {
        return moment(moment(value, 'YYYY-MM-DD')).isAfter(moment(), "day");
      },
      message: 'Expiration date must be greater than today'
    }),
    executedOnDate: Yup.date().nullable().required('Thist field is required').typeError('Must be a date'),
    expirationDate: Yup.date().nullable().required('Thist field is required').typeError('Must be a date'),
    number: Yup.string().required("This field is required.").nullable(),
    nationality: Yup.string().required("This field is required.").nullable(),
  }
  
  const getFieldsByDocumentType = () => {
    if (!category || !documentType) return []
    if (includes(PASSPORT_DOCUMENT_TYPES, documentType)) return ['party', 'number', 'nationality', 'expirationDate']
    if (includes(VISA_STAMPS_RESIDENCE_ID_DOCUMENTS_TYPES, documentType)) return ['party', 'date']
    if (includes(DOCUMENT_TYPE_IDENTITY_DOCUMENTS, documentType)) return ['party']
    if (includes(FORMATION_DOCUMENTS_EXC_DOCUMENT_TYPES, documentType)) return ['parties', 'executedOnDate']
    if (includes(FORMATION_DOCUMENTS_EXP_DOCUMENT_TYPES, documentType)) return ['parties', 'expirationDate']
    if (category && category?.key === "TEAM" && !includes(DOCUMENT_TYPE_IDENTITY_DOCUMENTS, documentType)) return ['ipProvision', 'parties']
    return ['parties']
  }

  const getSchema = () => {
    let schema = Yup.object().shape({})

    
    getFieldsByDocumentType().forEach(f => {
      schema = schema.concat(Yup.object().shape({ [f]: schemas[f as FieldsType] }))
    })
    return schema
  }

  const getFields = () => {
    return getFieldsByDocumentType().map(f => fields[f as FieldsType])
  }

  return { getFields, getSchema }
}

export default useGetFieldsByDocumentType

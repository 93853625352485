import * as _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import useGenerateDocument from "../../../../../../../hooks/services/modules/Document/useGenerateDocument";
import useGetDocumentListLazy from "../../../../../../../hooks/services/modules/Document/useGetDocumentListLazy";
import useGetGroupCompanies from "../../../../../../../hooks/services/modules/generate/useGetGroupCompanies";
import useGetStakeholders from "../../../../../../../hooks/services/modules/generate/useGetStakeholders";
import useSession from '../../../../../../../modules/session/hooks/useSession';
import { ServerConnect } from "../../../../../../../v1/utils";
import { stripTypenames } from "../../../../../../../v1/utils/graphql-util";
import FormSelector from '../../../../../../forms/modules/startups/Document/Generate/FormSelector';
import getDataByDocumentType from "../../../../../../forms/modules/startups/Document/Generate/Hooks/useGetDataByDocumentType";
import useGetFilterByDocumentType from "../../../../../../forms/modules/startups/Document/Generate/Hooks/useGetFilterByDocumentType";
import useGetTooltipByDocumentType from "../../../../../../forms/modules/startups/Document/Generate/Hooks/useGetTooltipByDocumentType";
import ModalScroll from '../../../../../../generic/Modal/ModalScroll/ModalScroll';
import useGetSaveByForm from 'src/components/forms/modules/startups/Document/Generate/Hooks/useGetSaveByForm';

const EditDocumentGenerate = ({ getDocumentsSIP, data, open, handleClose, refresh,refetch,getDocumentsEIP,getDocumentsBAOG, ...props }) => {

    const { startupId } = useSession();
    const { mutation: updateGenerateDocument, loading: loadingGenerateDocument } = useGenerateDocument({  showSuccessNotification: false }, { variables: {
        startupId: startupId,
        agreementId: _.get(data, "id", "").toString(),
    }});
    const form = useSelector(state => state.form);
    const [documentList, setDocumentList] = useState(null);
	const [tooltips, setTooltips] = useState(null);
	const [stakeholderList, setStakeholderList] = useState([]);
	const [groupCompaniesList, setGroupCompaniesList] = useState([]);
	const [authorizedSignatories, setAuthorizedSignatories] = useState(null);
	const [submitting, setSubmitting] = useState(false);
    const { save } = useGetSaveByForm()
    const { loading: loadingGroupCompanies, data: groupCompanies, refetch: refreshGroupCompanies } = useGetGroupCompanies({ variables: { startupId } });
    const { loading: loadingStakeholderList, data: stakeholders, refetch: refreshStakeHolders } = useGetStakeholders({ variables: { startupId } });
    const { loading: loadingDocumentList, data: dataDocumentList, manualQuery} = useGetDocumentListLazy();

    const tooltipId = useGetTooltipByDocumentType(_.get(data, 'type.code', ''));
    const filter = useGetFilterByDocumentType(_.get(data, 'type.code', ''));

    const handleSubmit = async (values,validateForm) => {
        const errors = await validateForm()
        //TODO: Add in useGetSaveByForm
        if (!_.size(errors)) {
            let docType = _.get(data, 'type.code');
            if (docType === "SHARE_INCENTIVE_PLAN") {
                values.terms['shareCapital'] = {
                    numberOfSIPShares: _.get(values, 'terms.shareCapital.numberOfSIPShares'),
                    currency: _.get(values, 'terms.shareCapital.currency'),
                    parValue: _.get(values, 'terms.shareCapital.parValue')
                }
            }
            if (docType === "GRANT_AGREEMENT") {
                delete values.terms.grant.sipValidationData
            }
            setSubmitting(true);
            const agreementData = stripTypenames(getDataByDocumentType(values, startupId,groupCompanies)) 
            const dataToSave = await save(docType,agreementData)

            const variables = {
                agreementId: _.get(data, "id", "").toString(),
                agreement: {
                    ...dataToSave,
                    file: stripTypenames(data.file),
                    parentDocumentId: _.get(data, 'parentDocument.id', null),
                    documentReplaced: false,
                    documentRegenerated: true
                },
                startupId
            };

            await updateGenerateDocument({variables})
            if(refetch){
                refetch({
                    agreementId: _.get(data, "id", "").toString(),
                    startupId
                })
            }
            handleClose();
            setSubmitting(false)
        }
    };

    useEffect(() => {
        if(getDocumentsSIP || getDocumentsEIP || getDocumentsBAOG) {
            manualQuery({variables:{ startupId }})
        }
    },[getDocumentsSIP,getDocumentsEIP,getDocumentsBAOG]);

    useEffect(() => {
    if(data && groupCompanies && getDocumentsSIP &&
		
		_.some(groupCompanies,
				gc => _.some(gc.shareClass,
						shareClass => _.some(shareClass.shareIncentivePlans,
								sh => sh.document &&  sh.document.id === _.get(data,'params.terms.shareIncentivePlan.id',''))))){

    		const groupCompanySIP = _.filter(groupCompanies,
                gc => _.some(gc.shareClass,
                    sh => _.some(sh.shareIncentivePlans,sh => sh.document !== null)));

            let shareClass=[];
            _.forEach(groupCompanySIP,gc => {
                shareClass=_.concat(shareClass,gc.shareClass)
            });

            let shareIncentivePlans=[];
            _.forEach(shareClass,sh => {
                
                shareIncentivePlans = _.concat(shareIncentivePlans, sh.shareIncentivePlans)
            });

            const shareIncentivePlansDocuments =_.filter(shareIncentivePlans,sh => sh.document);

            const sip=_.find(shareIncentivePlansDocuments,
					sip => sip.document.id === _.get(data,'params.terms.shareIncentivePlan.id'));
            _.set(data,'params.terms.shareIncentivePlan.id', sip.id)
        }
    },[JSON.stringify(data), JSON.stringify(groupCompanies),getDocumentsSIP]);

    useEffect(() => {
        if(dataDocumentList && data && groupCompanies && !_.isEmpty(groupCompanies) && getDocumentsSIP){
            const documentListFiltered=_.filter(dataDocumentList,doc=>_.get(doc,'type.code','')==='SHARE_INCENTIVE_PLAN')

			if(_.some(groupCompanies, gc => _.some(gc.shareClass, shareClass => _.some(shareClass.shareIncentivePlans, sh => sh.document &&  sh.id===_.get(data,'params.terms.shareIncentivePlan.id',''))))){

				const groupCompanySIP = _.filter(groupCompanies,
                    gc => _.some(gc.shareClass,
                        sh => _.some(sh.shareIncentivePlans,sh => sh.document !== null)));

                let shareClass = [];
                _.forEach(groupCompanySIP,gc => {
                    shareClass = _.concat(shareClass,gc.shareClass)
                });

                let shareIncentivePlans=[];
                _.forEach(shareClass,sh => {
                    shareIncentivePlans = _.concat(shareIncentivePlans, sh.shareIncentivePlans)
                });

                const shareIncentivePlansDocuments = _.filter(shareIncentivePlans,sh => sh.document);

                const sip = _.find(shareIncentivePlansDocuments,
						sip => sip.id === _.get(data,'params.terms.shareIncentivePlan.id'));

                _.set(data,'params.terms.shareIncentivePlan.id', sip.document.id)
            }
            setDocumentList(documentListFiltered)
        }
    },[JSON.stringify(dataDocumentList), JSON.stringify(data), JSON.stringify(groupCompanies),getDocumentsSIP]);



    useEffect(() => {
        if(dataDocumentList && getDocumentsEIP){
            const documentListFiltered=_.filter(dataDocumentList,doc=>_.get(doc,'type.code','')==='USA_EQUITY_INCENTIVE_PLAN' && doc.kind === 'GENERATED')
            setDocumentList(documentListFiltered)
        }
    },[JSON.stringify(dataDocumentList),getDocumentsEIP]);
    useEffect(() => {
        if(dataDocumentList && getDocumentsBAOG){
            const documentListFiltered=_.filter(dataDocumentList,doc=>_.get(doc,'type.code','')==='USA_BOARD_APPROVING_OPTION_GRANT' && doc.kind === 'GENERATED' && doc?.params?.company?.id === data?.params?.company?.id)
            setDocumentList(documentListFiltered)
        }
    },[JSON.stringify(dataDocumentList),getDocumentsBAOG,JSON.stringify(data)]);

    useEffect(() => {
        if(stakeholders){
            if(filter){
                const stakeholderListFiltered=_.filter(stakeholders,stakeholder=>filter(stakeholder));
                const stakeholderListNew=_.map(stakeholderListFiltered,stakeholder=>{return{label:stakeholder.fullName,id:stakeholder.id}});
                setStakeholderList(stakeholderListNew)
            }else {
                const stakeholderListNew = _.map(stakeholders, stakeholder => {
                    return {label: stakeholder.fullName, id: stakeholder.id}
                });
                setStakeholderList(stakeholderListNew)
            }
        }
    },[loadingStakeholderList]);

    useEffect(() => {
        if(groupCompanies && !_.isEmpty(groupCompanies)){
            const groupCompaniesListNew=_.map(groupCompanies,groupCompany=>{return{label:groupCompany.name,id:groupCompany.id}})
            setGroupCompaniesList(groupCompaniesListNew)
        }
    },[loadingGroupCompanies]);

    useEffect(() => {
        if(groupCompaniesList){
            const authorizedSignatoriesList=_.flatten(
                _.orderBy(groupCompanies, ['isHoldingCompany'], ['desc']).map(gc =>
                    gc.authorizedSignatories.map(role => ({
                        id: role.stakeholder.id,
                        label: role.stakeholder.fullName,
                        email: role.stakeholder.email,
                        groupCompanyId: gc.id
                    }))
                ));
            setAuthorizedSignatories(authorizedSignatoriesList)
        }
    },[groupCompaniesList]);

    useEffect(() => {
    	if (tooltipId) {
			ServerConnect.requestApi(
				`/contentful/tooltips/${tooltipId}`,
				{ method: "GET", headers: { "Content-Type": "application/json" } }
			)
				.then(res => {
					setTooltips(res.response);
				})
				.catch(err => {
					console.log("Tooltips from contentful error: ", err);
				});
		}
    }, [tooltipId]);
    const handleLoading = () => {
        if (getDocumentsEIP || getDocumentsBAOG || getDocumentsSIP) {
            return !tooltips || loadingStakeholderList || loadingGroupCompanies || !documentList;
        } 
            return !tooltips || loadingStakeholderList || loadingGroupCompanies
        
	};

    return (
        <ModalScroll open={open} handleClose={handleClose} >
                {data &&
                    <FormSelector
                    isSubmitting={submitting}
                    tooltips={tooltips}
                    handleSubmit={handleSubmit}
                    initialValues={data}
                    loading={handleLoading()}
                    handleCloseModal={handleClose}
                    groupCompanies={groupCompaniesList}
                    stakeholderList={stakeholders}
                    groupCompaniesList={groupCompanies}
                    stakeholders={stakeholderList}
                    authorizedSignatories={authorizedSignatories}
                    documentList={documentList || []}
                />
                }
        </ModalScroll>
    )
};

export default EditDocumentGenerate;

import React, { createContext, useState } from 'react';
export const PaymentContext = createContext({});

export type PaymentContextType = {
  countryCode: string,
  setCountryCode: (countryCode: string) => void,
  subTotal: number,
  setSubTotal: (subTotal: number) => void,
  vatAmount: number,
  setVatAmount: (vatAmount: number) => void,
  coupon: number,
  setCoupon: (coupon: any) => void,
  freeVat: boolean,
  setFreeVat: (freeVat: boolean) => void,
}

export const PaymentProvider = ({ children }) => {

  const [countryCode, setCountryCode] = useState(null);
  const [subTotal, setSubTotal] = useState(null);
  const [vatAmount, setVatAmount] = useState(null);
  const [coupon, setCoupon] = useState(null);
  const [freeVat, setFreeVat] = useState(false);
  
  return (
    <PaymentContext.Provider value={{ countryCode, setCountryCode, subTotal, setSubTotal, vatAmount, setVatAmount, coupon, setCoupon, freeVat, setFreeVat }}>
      {children}
    </PaymentContext.Provider>
  );
};

interface UsePaymentReturn {
  countryCode: string,
  setCountryCode: (countryCode: string) => void,
  subTotal: number,
  setSubTotal: (subTotal: number) => void,
  vatAmount: number,
  setVatAmount: (vatAmount: number) => void,
  coupon: number,
  setCoupon: (coupon: any) => void,
  freeVat: boolean,
  setFreeVat: (freeVat: boolean) => void,
}

function usePayment(): UsePaymentReturn {
  const { countryCode, setCountryCode, subTotal, setSubTotal, vatAmount, setVatAmount, coupon, setCoupon, freeVat, setFreeVat } = React.useContext(PaymentContext) as PaymentContextType;
  return { countryCode, setCountryCode, subTotal, setSubTotal, vatAmount, setVatAmount, coupon, setCoupon, freeVat, setFreeVat }
}

export default usePayment;
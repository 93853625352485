export default {
  GENERIC_TOOLTIP: "A shareholder is an individual holding shares in a company.",
  INDIVIDUAL_TOOLTIP: "You should only add individual stakeholders to a Delaware formation.",
  INDIVIDUAL_TITLE: "Add their basic info",
  INDIVIDUAL_SHAREHOLDER_STEP_SUBTITLE: "Add shareholder",
  INDIVIDUAL_NAME_FIELD: "Shareholder name",
  INDIVIDUAL_NAME_PLACEHOLDER: "As it appears in their passport",
  INDIVIDUAL_COMPANY_FIELD: "Company",
  DIRECTOR_ADDED_TITLE: "Do you have more shareholders to add?",
  DIRECTOR_ADDED_SUBTITLE: "Shareholder added!",
  FIELD_REQUIRED: "This field is required",
}

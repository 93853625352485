import React, { FC } from "react";
import classesModule from './ScaleItemFree.module.scss';
import Icon from '../../../generic/components/Icon';
import TypographyLocal from "../../../generic/components/Texts/Typography";

/**
 * Scale item free
 */

export interface ScaleItemFreeProps {
  /** Property icon */
  icon?: string,
  /** Property title */
  title?: string,
  /** Property key */
  key?: number,
  /** Property size */
  size?: string
}

const ScaleItemFree: FC<ScaleItemFreeProps> = ({
  icon = "Academy",
  title,
  key
}:ScaleItemFreeProps) => {


  return (
    <div className={` ${classesModule.ScaleItem} `} key={key}>
      <Icon isClara icon={icon} size={ '1.8462rem'} className={` ${classesModule.iconColor} `}/>
      <TypographyLocal
        className={`${classesModule.textItem} `}
      >
        {title}
      </TypographyLocal>
    </div>
  );
}

export default ScaleItemFree;
import React from 'react';
import Icon from '../../../../modules/generic/components/Icon';
import PrimaryButton from '../../Buttons/PrimaryButton/PrimaryButton';
import classes from './DocumentNotPreview.module.scss';
import ServerConnect from '../../../../v1/utils/ServerConnect';

const DocumentNotPreview = ({fileId, fileName}) => {

	const handleDownloadDocument = (fileId, fileName) => {
		let url = ServerConnect.getUrlFile(fileId);
		ServerConnect.downloadFile(url, fileName);
	};

	return (
		<React.Fragment>
			<div className={classes.notPreviewCard}>
				<Icon isClara icon={'Generic'} size={'4.5rem'} color={'#2C74FF'}/>
				<p>We are unable to preview this file.</p>
				<div className={classes.Button}>
					<PrimaryButton onClick={() => handleDownloadDocument(fileId, fileName)}>
						Download file
					</PrimaryButton>
				</div>
			</div>
		</React.Fragment>
	)
};

export default DocumentNotPreview;

import useMutation from "../../../../../hooks/custom/useMutation";


const mutation = `mutation uploadProofOfPayment($startupId: ID!, $entityId: ID!, $fileId: ID!) {
    uploadProofOfPayment(
      startupId: $startupId
      entityId: $entityId
      fileId: $fileId
    ) {
      id
      name
    }
  }`
const useUploadProofOfPayment = (options={}) => {
    const [send, data] = useMutation(mutation, options);
    return [send, data];
}
export default useUploadProofOfPayment;
import { useEffect } from 'react';
import useMutation from "../../../hooks/custom/useMutation";


const mutation = `mutation cancelSubscription(  $startupId: ID!){
  cancelSubscription(
      startupId: $startupId
      ) 
  } `
const useCancelSubscription = (options={}) => {

  const [send, data] = useMutation(mutation, options);
  

  return [send, data];
}
export default useCancelSubscription;

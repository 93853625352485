
import { gql, useQuery } from '@apollo/client';
const GET_DOCUMENT_TYPES = gql`query {
    getDocumentGroupTypes  {
      code
      name
      contentConfig
      documentTypes {
        code
        template {
          status
          version
          id 
        }
        contentConfig
        label
        premium
        entryId
        region {
          code
          name
        }
      }
    }
  }`;
  const useGetDocumentTypes = (config)=>{
    const {data,loading}= useQuery(GET_DOCUMENT_TYPES,config)
    return {data,loading}
  }
  export default useGetDocumentTypes;
import { useEffect } from 'react';
import useMutation from "../../../hooks/custom/useMutation";


const mutation = `mutation editSubscription(  $startupId: ID!,$subscriptionData: PlanSubscriptionInput! ){
  editSubscription(
      startupId: $startupId
      subscriptionData: $subscriptionData
      ) 
  } `
const useEditSubscription = (options={}) => {

  const [send, data] = useMutation(mutation, options);
  

  return [send, data];
}
export default useEditSubscription;

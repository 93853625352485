import React, { useEffect } from 'react';
import Icon from "../../../../../../../modules/generic/components/Icon";
import classesModule from "./HeaderCardStakeholder.module.scss";
import Skeleton from '@material-ui/lab/Skeleton';
const HeaderCardStakeholderSkeletor = ({ companyName, subtitle, avatar, tasks, peoples, people }) => {

 
    return (
        <React.Fragment>
            <div className={classesModule.HeaderCardStakeholder}>
                <Skeleton variant="circle" width={"3.3rem"} height={"3.3rem"} />
                <div className={classesModule.HeaderTitle}>
                    <span><Skeleton variant="text" height={"1.2rem"}/></span>
                    <span><Skeleton variant="text"  height={"1.2rem"}/></span>
                </div>
                <div></div>
                <div className={classesModule.Icons}>
                    <Skeleton variant="rect" width={"3rem"}  height={"1.2rem"} />
                    <div>
                        <span><Skeleton variant="rect" width={"3rem"}  height={"1.2rem"} /></span>
                    </div>
                </div>
            </div>

        </React.Fragment >
    )
};

export default HeaderCardStakeholderSkeletor;

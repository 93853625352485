import React, { FC } from "react";
import classesModules from './DueDiligenceSkeleton.module.scss';
import classes from './DueDiligence.module.scss';
import Skeleton from '@material-ui/lab/Skeleton';
import Title from "../../../../components/text/Title/Title";
import Scrollbar from "../../../../components/generic/Scrollbar/Scrollbar";

/**
 * Template DueDiligence Skeleton
 */

export interface DueDiligenceSkeletonProps {
  isLoading?: boolean;
} 

const DueDiligenceSkeleton: FC<DueDiligenceSkeletonProps> = ({
  isLoading = false,
  ...props
}:DueDiligenceSkeletonProps) => {

  return (
    <>
      {isLoading && (
        <div className={classesModules.healthCheckAreaContainer}>
          <div className={classes.DueDiligence}>
            <div className={classes.SubHeader}>
              <div className={classes.TabsContainer}>
                <div className={classesModules.TabArea}>
                  <div className={`${classesModules.skelTabs}`}>
                    <div className={`${classesModules.skelTabsSpace}`}>
                      <Skeleton variant="text" height={"1.3077rem"} width={"6.4615rem"}/>
                    </div>
                    <div className={`${classesModules.skelTabsSpace}`}>
                      <Skeleton variant="text" height={"1.3077rem"} width={"7.2308rem"}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.ClaraScore}></div>
            </div>
            <div className={classesModules.Overview}>
              <div className={classesModules.ZoneGraph}>
                <div className={classesModules.ZoneChartContent}>
                  <div className={classesModules.ZoneChartTitle}>
                    <Title><Skeleton variant="text" height={"2.1538rem"} width={"13rem"}/></Title>
                    <h2><Skeleton variant="text" height={"1.1538rem"} width={"10rem"}/></h2>
                  </div>
                  <div className={classesModules.ZoneChartCircles}>
                    <Skeleton variant="rect" height={"3.6154rem"} width={"3.6154rem"}/>
                    <Skeleton variant="rect" height={"3.6154rem"} width={"3.6154rem"}/>
                    <Skeleton variant="rect" height={"3.6154rem"} width={"3.6154rem"}/>
                    <Skeleton variant="rect" height={"3.6154rem"} width={"3.6154rem"}/>
                  </div>
                  <div className={classesModules.Botonera} >
                    <div className={classesModules.TextLastRun}>
                      <Skeleton variant="text" height={"1.1538rem"} width={"10rem"}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classesModules.ZoneLink}>
                <Scrollbar>
                  {Array(6).fill(1).map((el, i) =>
                    <div className={`${classesModules.Category}`} key={i}>
                      <div className={classesModules.title}>
                        <Skeleton variant="text" height={"1.1538rem"} width={"8rem"}/>
                        <Skeleton variant="text" height={"1.1538rem"} width={"5rem"}/>
                      </div>
                      <div className={classesModules.SubCategories}>
                        <div className={classesModules.Row}>
                          <div>
                            <Skeleton variant="text" height={"1.1538rem"} width={"18rem"}/>
                          </div>
                        </div>
                        <div className={classesModules.Row}>
                          <div>
                            <Skeleton variant="text" height={"1.1538rem"} width={"15rem"}/>
                          </div>
                        </div>
                        <div className={classesModules.Row}>
                          <div>
                            <Skeleton variant="text" height={"1.1538rem"} width={"12rem"}/>
                          </div>
                        </div>
                        <div className={classesModules.Row}>
                          <div>
                            <Skeleton variant="text" height={"1.1538rem"} width={"9rem"}/>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Scrollbar>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DueDiligenceSkeleton;

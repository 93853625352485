import _ from "lodash";
import useMutation from "src/hooks/custom/useMutation";

const UPDATE_IMMEDIATE_PARENT_MUTATION = `
mutation updateImmediateParent($startupId: ID!, $groupCompanyId: ID!, $entityId: ID! $entityData: RegulatoryBusinessEntityInput!) {
  updateImmediateParent(startupId: $startupId, groupCompanyId: $groupCompanyId, entityId: $entityId, entityData: $entityData) {
    name
  }
}`

const useUpdateImmediateParent =() => {
  const [updateImmediateParent] = useMutation(
    UPDATE_IMMEDIATE_PARENT_MUTATION
  );
  return {
   updateImmediateParent
  };
}
export default useUpdateImmediateParent
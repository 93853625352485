import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import Form from 'src/components/forms/Form/Form';
import TemplateWizard from 'src/modules/generic/templates/Modal/TemplateWizard';
import SubmitButton from 'src/modules/generic/components/Buttons/SubmitButton';
import Button from 'src/modules/generic/components/Buttons/Button';
import ControllerInput from 'src/modules/generic/components/Inputs/ReactHookForm/ControllerInput';
import useMachine from 'src/modules/generic/context/MachineContext/useMachine';
import useTranslate from 'src/modules/generic/hooks/useTranslate';
import { clearAssistatText, addAssistantText } from 'src/modules/generic/store/action';
import CheckboxButtonSimple from "src/modules/generic/components/Inputs/CheckboxButtonSimple";
import classes from "../../styles/director.module.scss";

function AddDirector() {
  const { next, context, cancel } = useMachine();
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return context?.initialData ?? {}
  }, [context]);

  const handleCancel = () => {
    cancel();
  }

  const handleSubmit = async (values) => {
    next(values);
  }

  const handleOnChange = (value) => {
    dispatch(clearAssistatText());
    dispatch(addAssistantText(translate('MODULES.ONBOARDINGROLES.DIRECTOR_V2.GENERIC_TOOLTIP')));
    if (value === 'corporate') {
      dispatch(addAssistantText(translate('MODULES.ONBOARDINGROLES.DIRECTOR_V2.CORP_SELECTED_TOOLTIP'), "alert"));
    }
  }

  useEffect(() => {
    dispatch(addAssistantText(translate('MODULES.ONBOARDINGROLES.DIRECTOR_V2.GENERIC_TOOLTIP')));
    return () => {
      dispatch(clearAssistatText());
    }
  }, [dispatch]);

  const schema = Yup.object().shape({
    stakeholderType: Yup.string().required(translate('MODULES.ONBOARDINGROLES.DIRECTOR_V2.FIELD_REQUIRED'))
  });

  const options = [
    {
      value: 'individual',
      label: "Individual",
    },
    {
      value: 'corporate',
      label: "Corporate",
    },
  ];

  return (
    <Form
      defaultValues={initialValues}
      schema={schema}
      onlySendDirty={false}
      onSubmit={handleSubmit}
      optionsForm={{ mode: 'onChange' }}
    >
      <TemplateWizard
        props={{
          title: translate("MODULES.ONBOARDINGROLES.DIRECTOR_V2.ADD_DIRECTOR_TITLE"),
          skeleton: null,
          subTitle: translate("MODULES.ONBOARDINGROLES.DIRECTOR_V2.ADD_DIRECTOR_SUBTITLE"),
          variant: 'simple'
        }}
        buttons={{
          cancel: <Button onClick={handleCancel} variant='secondary'>{translate('BUTTON_CANCEL')}</Button>,
          submit: <SubmitButton isSubmitting={undefined} handleDisabled={undefined}>{translate('BUTTON_NEXT')}</SubmitButton>,
        }}
      >
        <ControllerInput
          render={CheckboxButtonSimple}
          className={classes.CheckboxButtonSimple}
          classNameButton={classes.ButtonCheckbox}
          name="stakeholderType"
          options={options}
          onChange={(val: string) => {
            handleOnChange(val);
          }}
        />
      </TemplateWizard>
    </Form>
  );
}

export default AddDirector;


export default {
    WIZARD_ADD_TITLE: "Does your startup have investors?",
    WIZARD_ADD_SUBTITLE: "Your investors",
    WIZARD_CONFIRM_ADD: "Yes, add an investor",
    WIZARD_ADD_INDIVIDUAL_OR_COMPANY_SUBTITLE: "Add an investor",
    WIZARD_ADDED_SUBTITLE: "Investor added!",
    FIRST_STEP_TOOLTIPS:"<b>Third parties can include:</b><br/> -Companies providing goods or services<br/> - Subcontractors <br/>- Accountants and auditors <br/>- Companies providing access to markets, products or services",
    FIRST_STEP_IS_NOT_EXIST_TOOLTIPS:'If and when you take on third parties, add them here.',
    THIRD_STEP_NOT_ANOTHER_TOOLTIPS:'Add more third parties to your map at any time. '
}

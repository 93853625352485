import React, {useEffect, useState} from "react";
import useTranslate from "src/modules/generic/hooks/useTranslate";
import ModalAssistant from "src/modules/generic/components/Modal/ModalAssistant";
import TemplateWizard from "src/modules/generic/templates/Modal/TemplateWizard/TemplateWizardContent";
import Button from "src/modules/generic/components/Buttons/Button";
import ModalImage from "src/images/End_Program_Modal.png";
import classes from "./EndProgramModal.module.scss";
import useModal from 'src/modules/generic/hooks/useModal'

const EndProgramModal = ({ open, program, handleSubmit }) => {
    const {translate} = useTranslate();
    const { closeModal } = useModal()

    const handleCloseModal = () => {
        handleSubmit();
        closeModal()
    }

    const handleCancel = () => {
        closeModal()
    }

    return (
        <ModalAssistant open={open} variant='full'>
            <TemplateWizard
                props={{}}
                buttons={{
                    submit: <Button variant='primary' onClick={handleCloseModal}>{translate('MODULES.ACCELERATORS.PROGRAM_END')}</Button>,
                    cancel: <Button onClick={handleCancel} variant="secondary">{translate('BUTTON_CANCEL')}</Button>,
                }}
                className={classes.ScrollableWizard}
            >
                <div className={classes.ModalContent}>
                    <div className={classes.ContentText}>
                        <h1 className={classes.ModalTitle}>
                            {translate("MODULES.ACCELERATORS.PROGRAM_MODAL_END_TITLE")}
                        </h1>
                        <p>
                            By ending this application you confirm that your startup will no longer be active participant of <strong>{program?.name}.</strong>
                            <br /><br />
                            You will be able to join other accelerator programs.
                        </p>
                    </div>
                    <div className={classes.RightContent}>
                        <img src={ModalImage} className={classes.ImageMargin} />
                    </div>
                </div>
            </TemplateWizard>
        </ModalAssistant>
    );
}

export default EndProgramModal;
import TextInput from './TextInput'
import React, { useEffect } from 'react'
import startCase from 'lodash/startCase'
const TextType = ({field,form,groupCompany,...props})=>{
    useEffect(()=>{
        if(groupCompany?.managedByClara) form.setFieldValue(field.name,startCase(groupCompany?.type.toLowerCase()))
    },[groupCompany])
    return (
        <TextInput
            required={true}
            type='text'
            disabled={groupCompany?.managedByClara}
            field={field}
            form={form}
            {...props}
            />
    )
}
export default TextType
import React, {
  forwardRef,
  useEffect,
  useState,
  useImperativeHandle,
  useRef,
} from "react";
import { FormProvider, useForm } from "react-hook-form";
import formHelper from "../../../utils/formHelper";
import classes from "./Form.module.scss";
import { FormProvider as FormCustomProvider } from "./FormContext";
import DevToolsForm from "../../../modules/devTools/Form";
import _, { method } from "lodash";
export interface FormProps {
  [key: string]: any;
  /**@deprecated: use defaultValues, to initialize the form and use ref with ref.current.reset(newValue) to asign new values to the form.  */
  initialValues?: any;
  defaultValues?: any;
  loading?: boolean;
  loadingComponent?: React.ReactElement;
  onlySendDirty?: boolean;
  className?: string;
}

const Form = forwardRef(
  (
    {
      registerValues = [],
      className = "",
      onlySendDirty = true,
      loading,
      children,
      defaultValues,
      initialValues,
      onSubmit,
      onCancel,
      schema = {},
      watch,
      observerWatch,
      onAutoSave,
      optionsForm = {},
    }: FormProps,
    ref
  ) => {

    const methods = useForm(
      formHelper.getDefaultConfigForm(
        defaultValues ? defaultValues : initialValues,
        schema,
        optionsForm,
        optionsForm
      )
    );

    const [focusedValues,setFocusedValues] = useState([])

    const watchFields = methods.watch(watch);
    const [prevWatchFields, setPrevWatchFields] = useState({});
    const formRef = useRef(null);

    const dirtyValues = (dirtyFields, allValues) => {
      if (dirtyFields === true || Array.isArray(dirtyFields)) {
        return allValues;
      }
      return Object.fromEntries(
        Object.keys(dirtyFields).map((key) => [
          key,
          dirtyValues(dirtyFields[key], allValues[key]),
        ])
      );
    };

    const handleSubmit = async (data) => {
      const newValues = onlySendDirty
        ? dirtyValues(methods.formState.dirtyFields, data)
        : _.cloneDeep(data);
      if (onSubmit) {
        await onSubmit(newValues);
      }
    };

    const onFocus = (name) => {
      setFocusedValues(pre=>[...pre,name])
    }

    const handleCancel = (data) => {
      onCancel && onCancel(data);
    };

    const handleAutoSave = (name, value) => {
      onAutoSave && onAutoSave(methods.getValues());
    };

    /*  review why cause much renders */
    useEffect(() => {
      if ((!_.isEmpty(initialValues) || !_.isEmpty(defaultValues)) && !_.isEmpty(registerValues)) {
        methods.reset(initialValues || defaultValues);
        _.forEach(registerValues, (key) => {
          methods.register(key);
        });
        methods.trigger();
      }
    }, [
      JSON.stringify(registerValues),
      JSON.stringify(initialValues),
      JSON.stringify(defaultValues),
    ]);

    useImperativeHandle(ref, () => ({
      ...methods,
      async submit() {
        // formRef.current.submit()
      },
      async reset(values, options) {
        return methods.reset(values,options);
      },
      async setValue(name, value, options) {
        methods.setValue(name, value, options);
      },
      async trigger(value:string) {
        return methods.trigger(value)
      }
      
    }));

    useEffect(() => {
      if (
        observerWatch &&
        JSON.stringify(prevWatchFields) !== JSON.stringify(watchFields)
      ) {
        setPrevWatchFields(watchFields);
        observerWatch(watchFields);
      }
    }, [JSON.stringify(watchFields)]);
    
    const onBlur = (name) => {
      setFocusedValues(pre=>pre.filter(f=>f!==name))
    }

    return (
      <React.Fragment>
        <FormProvider {...methods} onBlur={onBlur} onAutoSave={handleAutoSave} onFocus={onFocus} focusedValues={focusedValues}>
          <FormCustomProvider value={methods.formState}>
            <form
              ref={formRef}
              autocomplete={"off_1"}
              className={`${classes.Form} ${className}`}
              onSubmit={methods.handleSubmit(handleSubmit)}
              noValidate={true}
            >
              {children}
            </form>
            <DevToolsForm />
          </FormCustomProvider>
        </FormProvider>
      </React.Fragment>
    );
  }
);

export default Form;

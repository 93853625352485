import useQuery from "../../../../custom/useQuery";
import useLazyQuery from "../../../../custom/useLazyQuery";

import { RoleFields } from "../../../../../modules/stakeholder/graphql/typePolicies";
import {
  GET_STAKEHOLDER_DETAIL_QUERY,
  ProfileFragment, StakeholderDocumentsFragment,
  StakeholderPositionsFragment
} from "../../../../../modules/stakeholder/graphql/queries";
import {
    AuthorizedSignatoryFragment, NoticesFragment
} from "../../../../../modules/startup/Stakeholder/graphql/fragments";

export const query = `
    ${AuthorizedSignatoryFragment}
    ${NoticesFragment}
    ${RoleFields}
    ${ProfileFragment}
    ${StakeholderPositionsFragment} 
    ${StakeholderDocumentsFragment}
    ${GET_STAKEHOLDER_DETAIL_QUERY}`;

const useIndividualStakeholderData = (variables,config) => {
  const { loading, error, data, refetch } = useQuery(query,{variables},config);
  return { loading, error, data, refetch };
};

export const useStakeholderDetailLazyQuery = (variables, config) => {
  const { manualQuery, loading, error, data, refetch } = useLazyQuery(query, variables, config);
  return { loading, error, data, refetch,manualQuery };
};

export default useIndividualStakeholderData;
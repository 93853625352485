import React, {useMemo, useState} from 'react'
import { Field } from "formik";
import _ from "lodash";
import ClaraSelect from "../../../../../../../v1/components/Form/ClaraSelect/ClaraSelect";
import {TextAreaInput, TextInput} from "../../../../../../../v1/components";
import CheckBox from "../../../../../../inputs/Checkbox/Checkbox";
import classes from "../Styles.module.scss";
import FormikHelper from '../../../../../../../v1/utils/formikHelper';
import useSession from '../../../../../../../modules/session/hooks/useSession';
import useGetPositions from '../../../../../../../modules/startup/graphql/stakeholder/query/useGetPositions';
import Loading from '../../../../../../generic/Loading/Loading'

const EditRolesAndServicesDetails = ({stakeholderField, tooltips, groupCompany, ...props}) => {
	const { startupId } = useSession();
	const [responsibilities, setResponsibilities] = useState([]);

	const {
		data: startupPositions,
		loading: loadingPositions,
		refetch: refetchPositions
	} = useGetPositions(
		{ startupId },
		{ showSuccessNotification: false, showErrorNotification: false }
	);

	const listPositions = useMemo(() => {
		return _.map(startupPositions, position => {
			return {
				label: position.name,
				id: position.id
			}
		})
	},[JSON.stringify(startupPositions)]);

	const listResponsibilities = useMemo(() => {
		return _.map(startupPositions, position => {
			return {
				positionId: position.id,
				responsibilities: _.get(position,'responsibilities',[])
			}
		})
	},[JSON.stringify(startupPositions)]);

	const handleSelectPosition = (id, extraParams, form) => {
        if (id) {
            const responsibilities = _.find(listResponsibilities, responsibility => responsibility.positionId === id);
            const position = _.find(startupPositions, position => position.id === id);

			let founderPosition = {
				...form.values.stakeholder.position,
				..._.omit(position, 'responsibilities')
			}

            if (position.isDefault) {
				const otherResponsibility = _.get(form, 'values.stakeholder.position.responsibilities', []).find(e => !e.isDefault);
				founderPosition = {
					...founderPosition,
					otherResponsibility: _.get(form, 'values.stakeholder.position.otherResponsibility') ||
						_.get(otherResponsibility, 'text', null)
				}

				const mappedResponsibilities = _.map(_.get(responsibilities, 'responsibilities', []), responsibility => {
					return {
						value: responsibility,
						label: _.get(responsibility, 'name'),
						renderElement: () => responsibility.isDefault ? null : (
							<Field name={`stakeholder.position.otherResponsibility`}>
								{({field, form}) => (
									<div className={classes.InputContainer}>
										<TextInput
											type='text'
											label=' '
											field={field}
											form={form}
											placeholder={'Describe other responsibilities here'}
										/>
									</div>
								)}
							</Field>
						)
					}
				});
				setResponsibilities(mappedResponsibilities)
			} else {
				const defaultOtherResponsibilities = _.head(_.get(position, 'responsibilities', []));
				const otherResponsibilities = _.head(_.get(form, 'values.stakeholder.position.responsibilities', []));

				const otherResponsibilitiesText = _.get(form, 'values.stakeholder.position.otherResponsibilities');
				const otherResponsibilitiesCode = _.get(form, 'values.stakeholder.position.otherResponsibilitiesCode');

				founderPosition = {
					...founderPosition,
					otherResponsibilities: otherResponsibilitiesText || otherResponsibilities
						? otherResponsibilitiesText || _.get(otherResponsibilities, 'text', '')
						: _.get(defaultOtherResponsibilities, 'text', ''),
					otherResponsibilitiesCode: otherResponsibilitiesCode || otherResponsibilities
						? otherResponsibilitiesCode || _.get(otherResponsibilities, 'code', '')
						: _.get(defaultOtherResponsibilities, 'code', '')
				}

			}

			form.setFieldValue('stakeholder.position', founderPosition);

        } else {
        	form.setFieldValue('stakeholder.position.responsibilities', [])
		}
    };

	const getPositionsAdds = () => {
		return [
			{
				type: "position",
				label: "Add New Position",
				textWarning: "This will create a new Position for your startup. You can add more details later on responsibilities field.",
				placeholder: "Enter Position Name...",
				startupId: startupId,
				textToSave: "name",
				params: {},
				paramsReturn: `
    				id 
    				code
    				name
    				isDefault
    				responsibilities {
						code
						id
						isDefault
						name
						text
				    }
  				`
			},
		]
	};

	const handlePositionCreate = (value, name, additionalValue, form) => {
		startupPositions.push(additionalValue);
		listPositions.push({ id: value.id, label: name});
		listResponsibilities.push({ positionId: value.id, responsibilities: additionalValue.responsibilities });
	};

	const fieldHasError = (form, field) => {
		return FormikHelper.returnErrorsForValidation(field.name, form.errors);
	};

	return(<>
		<Loading loading={loadingPositions}>
			<h1 className={classes.title}>
				Role and Services
			</h1>
			<div className={`${classes.ContainerRow} ${classes.Select}`}>
				<Field name={`stakeholder.position.id`}>
					{({ field, form }) => (
						<ClaraSelect
							required={true}
							field={field}
							form={form}
							modalClassName={classes.limitSize}
							label={"Role "}
							mode={"classic"}
							adds={getPositionsAdds()}
							lists={listPositions}
							callbacks={{
								createValues: handlePositionCreate,
								updateValues: handleSelectPosition
							}}
							placeholder={"Select from the list"}
							tooltip={tooltips["Select Role"]}
						/>
					)}
				</Field>
			</div>
			<Field name={`stakeholder.position.responsibilities`}>
				{({ field, form }) => {
					return (
						_.get(form, `values.stakeholder.position.id`) && _.get(form,'values.stakeholder.position.isDefault')?
							<div className={`${classes.labelGroup}`}>
								<label>Responsibilities <span style={{ color: 'red' }}>*</span></label>
								<CheckBox
									errorMessage={fieldHasError(form, field)}
									onChange={(value) => form.setFieldValue(field.name, value)}
									options={responsibilities}
									values={field.value}
									compare={'code'}
									className={{ root: classes.groupCheckBoxWizard, checkbox: classes.checkBoxWizard }}
								/>
							</div>: null
					)
				}}
			</Field>
			<Field name={`stakeholder.position.otherResponsibilities`}>
				{({ field, form }) => {
					return (
						_.get(form, `values.stakeholder.position.id`) && _.get(form, 'values.stakeholder.position.isDefault') === false ?
							<div className={classes.ContainerRow}>
								<TextAreaInput
									required={true}
									type='text'
									label={'Responsibilities '}
									field={field}
									form={form}
									placeholder={"Describe responsibilities"}
								/>
							</div> : null)
				}}
			</Field>
			<Field name='stakeholder.position.reports'>
				{({ field, form }) => (
					_.get(form, 'values.stakeholder.position.id') && _.get(form, 'values.stakeholder.position.code') !== 'CEO' ?
						<>
							<div className={`${classes.labelGroup}`}>
								<label>Reports to <span style={{ color: 'red' }}>*</span></label>
								<CheckBox
									isMultiple={false}
									errorMessage={fieldHasError(form, field)}
									onChange={(value) =>{ form.setFieldValue(field.name, value); form.validateField(field.name) }}
									values={field.value}
									options={[
										{
											value: 'CEO',
											label: 'Chief Executive Officer',
										},
										{
											value: 'directors',
											label: 'Board of Directors',
										}
									]}
									className={{ root: classes.groupCheckBoxWizard, checkbox: classes.checkBoxWizard }}
								/>
							</div>
						</>
						: null
				)}
			</Field>
			<div className={`${classes.labelGroup}`}>
				<label style={{ width: '12rem !important' }}>Commitment <span style={{ color: 'red' }}>*</span></label>
				<Field name='stakeholder.position.services.commitment'>
					{({ field, form }) => (
						<CheckBox
							errorMessage={fieldHasError(form, field)}
							onChange={(value) => { form.setFieldValue(field.name, value); form.validateField(field.name) }}
							className={{ root: classes.groupCheckBoxWizard, checkbox: classes.checkBoxWizard }}
							values={field.value}
							isMultiple={false}
							tooltip={tooltips['Commitment']}
							options={[
								{
									value: 'commitmentFull',
									label: 'Devote the whole of their working time, attention and skill',
									tooltip:tooltips['Other Amount'],
								},
								{
									value: 'otherCommitment',
									label: 'Other',
									tooltip:tooltips['Other Amount'],
									renderElement: () => (
										<Field name='stakeholder.position.services.otherCommitment'>
											{({ field, form }) => (
												<div style={{'marginLeft':'2rem'}}>
													<CheckBox
														onChange={(value) => { form.setFieldValue(field.name, value); form.validateField(field.name) }}
														className={{ root: classes.groupCheckBoxWizard, checkbox: classes.checkBoxWizard }}
														values={field.value}
														isMultiple={false}
														options={[
															{
																value: '10',
																label: 'HOURS10',
															},
															{
																value: '20',
																label: 'HOURS20',
															},
															{
																value: '30',
																label: 'HOURS30',
															},
															{
																value: '40',
																label: 'HOURS40',
															},
															{
																value: 'otherHours',
																label: 'Other',
																renderElement: () => (
																	<Field name='stakeholder.position.services.otherHours'>
																		{({ field, form }) => (
																			<div className={classes.InputContainer}>
																				<label style={{ marginTop: '0.5rem' }}>Hours per week <span style={{ color: 'red' }}>*</span></label>
																				<TextInput
																					type='text'
																					field={field}
																					form={form}
																					rightLabel={'Hours'}
																					placeholder={'Number'}
																				/>
																			</div>
																		)}
																	</Field>
																)
															}
														]}

													/></div>
											)}
										</Field>
									)
								}
							]}
						/>
					)}
				</Field>
			</div>
		</Loading>
    </>)
};

export default EditRolesAndServicesDetails;

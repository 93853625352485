
const US={
    PAGE_TITLE: "Accelerators",
    PROGRAM_TITLE: "Program enrolment",
    PROGRAM_SUBTITLE: "Here are all the accelerator programs available to your startup. Select one from the list below to get started.",
    PROGRAM_JOIN: "Join program",
    PROGRAM_END: "End program",
    PROGRAM_END_MESSAGE: "You have left the program sucessfully.",
    PROGRAM_MODAL_JOIN_TITLE: "Join {{programName}}",
    PROGRAM_MODAL_JOIN_BUTTON: "Join and return to map",
    PROGRAM_MODAL_END_TITLE: "Your participation in the program will be terminated.",
    PROGRAM_SUBMITTED_APPLICATION: "You have submitted an application to",
    PROGRAM_CURRENTLY_ENROLED: "You are currently enroled in",
    PROGRAM_IS_SUBMITTED: 'Submitted',
    PROGRAM_IS_NOT_SUBMITTED: 'In Progress',
    ACCELERATOR_PROGRAM_VERIFICATION: 'Accelerator program verification',
    SECTION_RESTRICTED: 'This section is restricted to the accepted applicants of an accelerator program. If you have already been accepted, please use the verification code from your acceptance email to access this page.'
}

export default US;
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classesnames from "./classes.module.scss";
import classnames from "classnames";
import _ from "lodash";

const TabsMenu = ({
  initValue,
  classes,
  className = null,
  tabs,
  tabContents = [],
  classNameContents = null,
  ...props
}) => {
  const [value, setValue] = useState(initValue);
  const [contents, setContents] = useState([]);
  const handleChange = (event, newValue) => {
    if (!_.isEmpty(contents)) {
      var aux = [...contents];
      if (aux[newValue]){
        aux[newValue].show = true;
        setContents(aux);
      }
      
    }
    setValue(newValue);
    props.onChange && props.onChange(event, newValue);
  };

  useEffect(() => {
    handleChange({}, initValue);
    // setValue(initValue);
  }, [initValue]);

  useEffect(() => {
    const newCotnents = _.map(tabContents, (content, i) => {
      return {
        show: i === initValue,
        component: content.component,
        conditionalShow: content.conditionalShow,
        props: content.props,
      };
    });
    setContents(newCotnents);
  }, [JSON.stringify(tabContents)]);

  let componentTab = (
    <Paper square>
      {value !== null && tabs && (
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          classes={classes}
          {...props}
          onChange={handleChange}
        >
          {tabs.map((tab, i) => {
            return React.cloneElement(tab, { key: tab.key ? tab.key : i });
          })}
        </Tabs>
      )}
    </Paper>
  );

  return (
    <React.Fragment>
      {!_.isEmpty(contents) ? (
        <React.Fragment>
          <div
            className={classnames(`${className} ${classesnames.tabArea}`, {
              [classesnames.tabAreaHeight]: !!!tabContents,
            })}
          >
            {componentTab}
            <div className={classnames(`${classNameContents} ${classesnames.tabContents}`)}>
              {_.map(contents, (component, i) => {
                const {
                  component: Component,
                  show,
                  props,
                  conditionalShow,
                } = component;
                if (conditionalShow && i !== value) {
                  return (
                    <React.Fragment key={`content_${i}`}>
                      <div className={classesnames.hidden}> </div>
                    </React.Fragment>
                  );
                }
                return (
                  <React.Fragment key={`content_${i}`}>
                    {show && (
                      <div
                        className={classnames({
                          [classesnames.hidden]: i !== value,
                        })}
                      >
                        <Component {...props} />
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {componentTab}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

TabsMenu.propTypes = {
  /** Property initValue firt Tab selected*/
  initValue: PropTypes.number,
};

TabsMenu.defaultProps = {
  initValue: 0,
};

export default TabsMenu;

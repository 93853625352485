//Identity documents that aren't PASSPORT_DOCUMENT_TYPES and are not VISA_STAMPS_RESIDENCE_ID_DOCUMENT_TYPES don't need additional info 
//Require Exp date, nationality and passport number
export const PASSPORT_DOCUMENT_TYPES =[
    "THIRDPARTY_ADDRESS_PASSPORT_DOCUMENT",
    "PASSPORT_DOCUMENT",
    "OLDPASSPORT_DOCUMENT",
  ];
  //Require only Exp date 
export const VISA_STAMPS_RESIDENCE_ID_DOCUMENTS_TYPES = [
    "UAE_VISA_DOCUMENT",
    "UAE_VISA_LASTPAGE_DOCUMENT",
    "GCC_RESIDENT_ID_DOCUMENT",
    "NATIONAL_ID_DOCUMENT",
    "UAE_RESIDENCE_VISA_DOCUMENT",
    "EID_DOCUMENT",
    "EID_LASTPAGE_DOCUMENT",
  ]
    //Require only Exp date
export const FORMATION_DOCUMENTS_EXP_DOCUMENT_TYPES = [
        "COMMERCIAL_LICENSE_DOCUMENT",
        "TRADE_LICENSE"
    ]
    //Require only Exc date
export const FORMATION_DOCUMENTS_EXC_DOCUMENT_TYPES = [
        "CERTIFICATE_OF_INCORPORATION",
        "ARTICLES_OF_ASSOCIATION",
        "REGISTER_OF_DIRECTORS",
        "REGISTER_OF_MEMBERS",
        "ADGM_CERTIFICATE_OF_INCUMBENCY",
        "CERTIFICATE_OF_GOOD_STANDING",
        "REGISTRY_EXTRACT",
        "REGISTER_OF_PARTNERS",
        "LIMITED_PARTNERSHIP_AGREEMENT",
        "CERTIFICATE_OF_REGISTRATION",
        "TRUST_DEED"
    ]